import React from 'react';
import { Space, Typography } from 'antd';
import { friendlyFormatIBAN } from 'ibantools';
import { Link } from 'react-router-dom';
import GenerierlaufEntryStatus from '../../../shared/GenerierlaufEntryStatus';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import {
  IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute,
  IncomingInvoiceBookingCreationGenerierlaufListEntry,
} from '../../../../../types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import MitarbeiterTooltip from '../../../../../components/Card/MitarbeiterTooltip';
import LinkToBelegFile from '../../../../../components/Link/LinkToBelegFile/LinkToBelegFile';
import { EuroAmount } from '../../../../../components/Number';
import DocumentStatusTag from '../../../../../components/Status/DocumentStatus/DocumentStatusTag';
import StatusBadge from '../../../../../components/Status/StatusBadge';
import TableActions from './TableActions';
import { generatePathToRechtstraegerDetailsPage } from '../../../../Rechtstraeger/rechtstraegerHelper';
import { generatePathToObjektDetailsPage } from '../../../../Objekt/objektUriPaths';

const entryTableColumns = (): TableWithColSelectorColumnProps<IncomingInvoiceBookingCreationGenerierlaufListEntry>[] => [
  {
    title: 'Status',
    defaultSelected: true,
    dataIndex: IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute.Status,
    sorter: true,
    render: (text, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },
  {
    title: 'Verrechnungsart',
    defaultSelected: true,
    dataIndex: IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute.BillingType,
    sorter: true,
    render: (text, record) => <Typography.Text>{record.billingType.text}</Typography.Text>,
  },
  {
    title: 'Teilbetrag',
    defaultSelected: true,
    render: (text, record) =>
      record.partialAmountName ? <Typography.Text>{record.partialAmountName}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Lieferant',
    defaultSelected: true,
    dataIndex: IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute.Issuer,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={15} text={record.issuerName}>
        {(shortenedText) => (
          <Link to={generatePathToRechtstraegerDetailsPage(record.issuerRechtstraegerId)} target="_blank">
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Empfänger',
    defaultSelected: true,
    dataIndex: IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute.Payee,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={15} text={record.payeeName}>
        {(shortenedText) => (
          <Link to={generatePathToRechtstraegerDetailsPage(record.payeeRechtstraegerId)} target="_blank">
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Buchungsdatum',
    defaultSelected: true,
    dataIndex: IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute.BuchungsDatum,
    sorter: true,
    render: (text, record) => <CustomFormattedDate value={record.buchungsdatum} />,
  },
  {
    title: 'Objekt',
    defaultSelected: true,
    dataIndex: IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute.Objekt,
    sorter: true,
    render: (text, record) =>
      record.objektId && record.objektBezeichnung ? (
        <DataWithShortenedText text={record.objektBezeichnung} maxTextLength={15}>
          {(shortenedText) => (
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
            <Link to={generatePathToObjektDetailsPage(record.objektId!)} target="_blank">
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Rechnungsnummer',
    defaultSelected: true,
    dataIndex: IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute.InvoiceNumber,
    sorter: true,
    render: (text, record) => <LinkToBelegFile fileId={record.fileId} belegnummer={record.rechnungsNummer} />,
  },
  {
    title: 'Rechnungsdatum',
    defaultSelected: true,
    dataIndex: IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute.InvoiceDate,
    sorter: true,
    render: (text, record) => <CustomFormattedDate value={record.rechnungsDatum} />,
  },
  {
    title: 'Fällig am',
    defaultSelected: true,
    dataIndex: IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute.DueDate,
    sorter: true,
    render: (text, record) => <CustomFormattedDate value={record.dueDate} />,
  },
  {
    title: 'Kundennummer',
    defaultSelected: true,
    dataIndex: IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute.KundenNummer,
    sorter: true,
    render: (text, record) =>
      record.kundenNummer ? <DataWithShortenedText maxTextLength={20} text={record.kundenNummer} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Netto',
    defaultSelected: true,
    dataIndex: IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute.Netto,
    sorter: true,
    render: (text, record) => <EuroAmount value={record.netto} />,
  },
  {
    title: 'USt.',
    defaultSelected: true,
    dataIndex: IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute.Vat,
    sorter: true,
    render: (text, record) => <EuroAmount value={record.vat} />,
  },
  {
    title: 'Brutto',
    defaultSelected: true,
    dataIndex: IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute.Brutto,
    sorter: true,
    render: (text, record) => <EuroAmount value={record.brutto} />,
  },
  {
    title: 'Zahlungsart',
    defaultSelected: true,
    dataIndex: IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute.PaymentMethod,
    sorter: true,
    render: (text, record) => <Typography.Text>{record.paymentMethod.text}</Typography.Text>,
  },
  {
    title: 'Bankverbindung Rechnungsempfänger',
    defaultSelected: true,
    dataIndex: IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute.PayeeBankDetailsIban,
    sorter: true,
    render: (text, record) => <DataWithShortenedText maxTextLength={15} text={`${friendlyFormatIBAN(record.recipientBankDetailsIban)}`} />,
  },
  {
    title: 'Bankverbindung Lieferant',
    defaultSelected: true,
    dataIndex: IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute.IssuerBankDetailsIban,
    sorter: true,
    render: (text, record) => <DataWithShortenedText maxTextLength={15} text={`${friendlyFormatIBAN(record.issuerBankDetailsIban)}`} />,
  },
  {
    title: 'Verwendungszweck',
    defaultSelected: true,
    render: (text, record) =>
      record.purposeOfUseText ? <Typography.Text>{record.purposeOfUseText}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Zahlungsreferenz',
    defaultSelected: true,
    render: (text, record) =>
      record.paymentReferenceText ? <Typography.Text>{record.paymentReferenceText}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'SEPA Mandatsreferenz',
    defaultSelected: true,
    dataIndex: IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute.SepaMandateReference,
    sorter: true,
    render: (text, record) =>
      record.sepaMandatReference ? <Typography.Text>{record.sepaMandatReference}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Status',
    width: 200,
    dataIndex: IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute.InvoiceStatus,
    sorter: true,
    defaultSelected: true,
    render: (text, record) => (
      <Space style={{ width: '100%' }}>
        <DocumentStatusTag documentStatus={record.status} />
        {record.buchungsanweisungStatus && <StatusBadge entityStatus={record.buchungsanweisungStatus} />}
      </Space>
    ),
  },
  {
    title: 'Erstellt am',
    dataIndex: IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute.CreateTs,
    sorter: true,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    dataIndex: IncomingInvoiceBookingCreationGenerierlaufEntryOrderAttribute.CreatedBy,
    sorter: true,
    render: (text, record) => <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />,
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => {
      return <TableActions record={record} />;
    },
  },
];

export default entryTableColumns;
