import { generatePath } from 'react-router';
import { mapToQueryString, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { FiltersFormValues } from './filtersFormMapper';
import { uriToDataCarrierIbanWithFibuKonto } from '../../../uriDataCarrierPaths';

export enum QueryParamKey {
  ACCOUNT_HOLDER = 'accountHolder',
  BANKNAME = 'bankname',
  BIC = 'bic',
  FIBU_ACCOUNT_NUMBER = 'fibuAccountNumber',
  IBAN = 'iban',
  LAST_BANK_STATEMENT_FROM = 'lastBankStatementFrom',
  LAST_BANK_STATEMENT_TO = 'lastBankStatementTo',
  PAYMENT_TRANSACTION_TYPE = 'paymentTransactionType',
}

export type TIbanQueryParams = QueryParams<FiltersFormValues>;

export const updateQueryParams = (navigate: (path: string) => void, filters: TIbanQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  navigate(generatePathToIbanListingPage(filters, paginationParams));

export const generatePathToIbanListingPage = (filters: TIbanQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  `${generatePath(uriToDataCarrierIbanWithFibuKonto)}?${mapToQueryString({
    [QueryParamKey.ACCOUNT_HOLDER]: filters?.accountHolder,
    [QueryParamKey.BANKNAME]: filters?.bankname,
    [QueryParamKey.BIC]: filters?.bic,
    [QueryParamKey.FIBU_ACCOUNT_NUMBER]: filters?.fibuAccountNumber,
    [QueryParamKey.IBAN]: filters?.iban,
    [QueryParamKey.LAST_BANK_STATEMENT_FROM]: filters?.lastBankStatementFrom,
    [QueryParamKey.LAST_BANK_STATEMENT_TO]: filters?.lastBankStatementTo,
    [QueryParamKey.PAYMENT_TRANSACTION_TYPE]: filters?.paymentTransactionType,
    ...paginationParams,
  })}`;
