import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetMahnsperreForRechtstraegerMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  request: Types.SetMahnsperreForRechtstraegerInput;
}>;

export type SetMahnsperreForRechtstraegerMutation = {
  actionSetMahnsperreForRechtstraeger: {
    data: { mahnsperre: boolean };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const SetMahnsperreForRechtstraegerDocument = gql`
  mutation SetMahnsperreForRechtstraeger($rechtstraegerId: ID!, $request: SetMahnsperreForRechtstraegerInput!) {
    actionSetMahnsperreForRechtstraeger(rechtstraegerId: $rechtstraegerId, request: $request) {
      data {
        mahnsperre
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useSetMahnsperreForRechtstraegerMutation(
  baseOptions?: Apollo.MutationHookOptions<SetMahnsperreForRechtstraegerMutation, SetMahnsperreForRechtstraegerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetMahnsperreForRechtstraegerMutation, SetMahnsperreForRechtstraegerMutationVariables>(
    SetMahnsperreForRechtstraegerDocument,
    options
  );
}
export type SetMahnsperreForRechtstraegerMutationHookResult = ReturnType<typeof useSetMahnsperreForRechtstraegerMutation>;
export type SetMahnsperreForRechtstraegerMutationResult = Apollo.MutationResult<SetMahnsperreForRechtstraegerMutation>;
export type SetMahnsperreForRechtstraegerMutationOptions = Apollo.BaseMutationOptions<
  SetMahnsperreForRechtstraegerMutation,
  SetMahnsperreForRechtstraegerMutationVariables
>;
