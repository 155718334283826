import React, { FC } from 'react';
import { Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../hooks/useToggle';
import LinkageForm from './Form/LinkageForm';
import { BudgetingVPosAuftschluesselLinkage } from '../../types';

type Props = {
  linkageEntryList: BudgetingVPosAuftschluesselLinkage[];
  onAction: () => void;
};

const LinkageDrawerAndButtons: FC<Props> = ({ onAction, linkageEntryList }) => {
  const [isOpen, toggleIsOpen] = useToggle();

  return (
    <>
      <Button type="primary" onClick={toggleIsOpen}>
        <PlusOutlined />
        Verknüpfung anlegen
      </Button>
      <Drawer width={720} title="Verknüpfung anlegen" open={isOpen} onClose={toggleIsOpen} destroyOnClose>
        <LinkageForm
          onCancel={toggleIsOpen}
          linkageEntryList={linkageEntryList}
          onSuccess={() => {
            toggleIsOpen();
            onAction();
          }}
        />
      </Drawer>
    </>
  );
};

export default LinkageDrawerAndButtons;
