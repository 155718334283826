import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { HistoryListFieldsFragmentDoc } from '../../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SepaMandatChangeHistoryListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  bankDetailsId: Types.Scalars['ID']['input'];
}>;

export type SepaMandatChangeHistoryListQuery = {
  getSepaMandatChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const SepaMandatChangeHistoryListDocument = gql`
  query SepaMandatChangeHistoryList($rechtstraegerId: ID!, $bankDetailsId: ID!) {
    getSepaMandatChangeHistoryList(rechtstraegerId: $rechtstraegerId, bankDetailsId: $bankDetailsId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useSepaMandatChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<SepaMandatChangeHistoryListQuery, SepaMandatChangeHistoryListQueryVariables> &
    ({ variables: SepaMandatChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SepaMandatChangeHistoryListQuery, SepaMandatChangeHistoryListQueryVariables>(SepaMandatChangeHistoryListDocument, options);
}
export function useSepaMandatChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SepaMandatChangeHistoryListQuery, SepaMandatChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SepaMandatChangeHistoryListQuery, SepaMandatChangeHistoryListQueryVariables>(
    SepaMandatChangeHistoryListDocument,
    options
  );
}
export function useSepaMandatChangeHistoryListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SepaMandatChangeHistoryListQuery, SepaMandatChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SepaMandatChangeHistoryListQuery, SepaMandatChangeHistoryListQueryVariables>(
    SepaMandatChangeHistoryListDocument,
    options
  );
}
export type SepaMandatChangeHistoryListQueryHookResult = ReturnType<typeof useSepaMandatChangeHistoryListQuery>;
export type SepaMandatChangeHistoryListLazyQueryHookResult = ReturnType<typeof useSepaMandatChangeHistoryListLazyQuery>;
export type SepaMandatChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useSepaMandatChangeHistoryListSuspenseQuery>;
export type SepaMandatChangeHistoryListQueryResult = Apollo.QueryResult<SepaMandatChangeHistoryListQuery, SepaMandatChangeHistoryListQueryVariables>;
