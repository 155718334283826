import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { ColumnProps } from 'antd/es/table';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../Button/ButtonsAligned';
import { useToggle } from '../../../hooks/useToggle';
import ColumnSelector from './ColumnSelector';
import { TableWithColSelectorProps } from './TableWithColSelectorProps';
import {
  ColumnSelectorSetting,
  getColumnTitle,
  getColumnTitles,
  getSelectedColumnTitles,
  TableWithColSelectorType,
} from './tableWithColSelectorHelpers';
import useUserColumnSettings from './useUserColumnSettings';
import TableWithPagination from '../TableWithPagination/TableWithPagination';
import { useDeleteColumnSettingsMutation } from '../../../features/Usability/ColumnSettings/gql/ColumnSettingsMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';

/**
 * <h2>Usage</h2>
 * To display data in a table with the option to show/hide specific columns
 * There are two states in the col selector - 'Default' and 'System'
 *
 * Default
 *  - Saves the configuration of the columns from the User
 *  - User can change it anytime, changes will be saved onChange
 * System
 *  - Uses the default selected columns
 *  - Can be changed but when the page is refreshed etc, defaults are set to the initials again
 *
 * Pagination
 *  - When changing the page, queryParams are added to url so when a user refreshes, he stays on the same page
 *
 */
function TableWithColSelector<T extends Record<string, unknown>>({
  columns,
  filterIdentifier,
  onSelectionChange,
  columnArgs,
  ...restProps
}: TableWithColSelectorProps<T>) {
  const [isColumnSelectorOpen, toggleColumnSelector] = useToggle();

  const defaultSelectedColumns = columns.filter((col) => !!col.defaultSelected || !col.title);

  const [selectedColumnSelectorType, setSelectedColumnSelectorType] = useState<TableWithColSelectorType>('user');

  const [selectedSystemColumns, setSelectedSystemColumns] = useState<ColumnProps<T>[]>(defaultSelectedColumns);
  const [selectedUserColumns, setSelectedUserColumns] = useState<ColumnProps<T>[]>(defaultSelectedColumns);
  const selectedColumnsToShowInTable = selectedColumnSelectorType === 'user' ? selectedUserColumns : selectedSystemColumns;

  const [runDelete] = useDeleteColumnSettingsMutation({
    variables: { filterIdentifier },
    onCompleted: () => refetchColumnSettings(),
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  // ------ START - Code block responsible for updating a column, with e.g. link, to update columns after the filter passed within columnArgs changed
  const [currentColumnArgs, setCurrentColumnArgs] = useState();

  useEffect(() => {
    const runColumnsUpdate = () => {
      setCurrentColumnArgs(columnArgs);
      setSelectedSystemColumns(columns.filter((col) => !!col.defaultSelected || !col.title));
      setSelectedUserColumns(columns.filter((col) => !!col.defaultSelected || !col.title));
    };

    if (columnArgs) {
      if (typeof columnArgs === 'object') {
        if (!isEqual(currentColumnArgs, columnArgs)) {
          runColumnsUpdate();
        }
      } else if (currentColumnArgs !== columnArgs) {
        runColumnsUpdate();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnArgs]);
  // ------ END - Code block responsible for updating a column, with e.g. link, to update columns after the filter passed within columnArgs changed

  const { userColumnSettings, addOrUpdateUserColumnSettings, refetchColumnSettings } = useUserColumnSettings(filterIdentifier);
  useEffect(() => {
    if (userColumnSettings?.length) {
      // INFO: Don't filter columns that don't contain a title i.e -> bestandseinheitTableColumns otherwise expand column won't be shown when it positioned somewhere else
      const filteredColumns = columns.filter((col) => getSelectedColumnTitles(userColumnSettings).includes(getColumnTitle(col)) || !col.title);
      setSelectedUserColumns(filteredColumns);
    }
  }, [userColumnSettings, columns]);

  useEffect(() => {
    onSelectionChange?.(selectedColumnsToShowInTable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColumnsToShowInTable]);

  const handleColumnSelectionChange = (columnSelectorOptions: ColumnSelectorSetting[]) => {
    const newSelectedColumnTitles = columnSelectorOptions.filter((title) => title.selected).map((title) => title.title);
    const filteredColumns = columns.filter((column) => newSelectedColumnTitles.some((title) => title === getColumnTitle(column)) || !column.title);

    if (selectedColumnSelectorType === 'user') {
      addOrUpdateUserColumnSettings(JSON.stringify(columnSelectorOptions));
      setSelectedUserColumns(filteredColumns);
    } else {
      setSelectedSystemColumns(filteredColumns);
    }
  };

  const onRestoreDefault = () => {
    if (selectedColumnSelectorType === 'user') {
      runDelete();
      setSelectedUserColumns(defaultSelectedColumns);
    } else {
      setSelectedSystemColumns(defaultSelectedColumns);
    }
  };

  return (
    <div>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <ColumnSelector
          isOpen={isColumnSelectorOpen}
          onOpenChange={toggleColumnSelector}
          selectedColumnSelectorType={selectedColumnSelectorType}
          // INFO: Just show columns with title in Selector
          allColumnTitles={getColumnTitles(columns).filter((col) => !!col)}
          selectedUserColumnTitles={getColumnTitles(selectedUserColumns).filter((col) => !!col)}
          defaultUserColumnsTitles={getColumnTitles(defaultSelectedColumns).filter((col) => !!col)}
          selectedSystemColumnTitles={getColumnTitles(selectedSystemColumns).filter((col) => !!col)}
          defaultSystemColumnsTitles={getColumnTitles(defaultSelectedColumns).filter((col) => !!col)}
          onChangeSelectorType={setSelectedColumnSelectorType}
          onSelectionChange={handleColumnSelectionChange}
          onRestoreDefault={onRestoreDefault}
        />
      </ButtonsAligned>
      <TableWithPagination<T> columns={selectedColumnsToShowInTable} {...restProps} />
    </div>
  );
}

export default TableWithColSelector;
