import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateReceivedBankTransactionVerbuchenGenerierlaufMutationVariables = Types.Exact<{
  amountFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  amountTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  bookingDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  bookingDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  chargesFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  chargesInSeparateEntry?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  chargesTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  createTsFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createdByMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  creditor?: Types.InputMaybe<Types.Scalars['String']['input']>;
  creditorIban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dataCarrierName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  debtor?: Types.InputMaybe<Types.Scalars['String']['input']>;
  debtorIban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  endToEndId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  noted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  paymentReferenceText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  purposeOfUseText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  reversal?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  statementNumber?: Types.InputMaybe<Types.Scalars['String']['input']>;
  status?: Types.InputMaybe<Types.ReceivedBankTransactionStatus>;
  transactionType?: Types.InputMaybe<Types.TransactionType>;
  valueDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  valueDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  input: Types.ReceivedBankTransactionVerbuchenGenerierlaufCreateInput;
}>;

export type CreateReceivedBankTransactionVerbuchenGenerierlaufMutation = {
  createReceivedBankTransactionVerbuchenGenerierlauf: {
    data: {
      countFehler: number;
      countNichtVerarbeitet: number;
      countVerarbeitet: number;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      generierDatum: string;
      generierlaufId: string;
      errorList: Array<{ message: string; type: string }>;
      status: { description?: string | null; text: string; value: Types.GenerierlaufStatus };
      warningList: Array<{ message: string; type: string }>;
    };
    errorList: Array<{ type: string; message: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type MarkReceivedBankTransactionAsNotedMutationVariables = Types.Exact<{
  receivedBankTransactionId: Types.Scalars['ID']['input'];
}>;

export type MarkReceivedBankTransactionAsNotedMutation = {
  actionMarkReceivedBankTransactionAsNoted: {
    data: { receivedBankTransactionId: string };
    errorList: Array<{ type: string; message: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type AssignReceivedBankTransactionMutationVariables = Types.Exact<{
  receivedBankTransactionId: Types.Scalars['ID']['input'];
  paymentId: Types.Scalars['ID']['input'];
}>;

export type AssignReceivedBankTransactionMutation = {
  actionAssignReceivedBankTransaction: {
    data: { receivedBankTransactionId: string };
    errorList: Array<{ type: string; message: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type RemoveAssignmentReceivedBankTransactionMutationVariables = Types.Exact<{
  receivedBankTransactionId: Types.Scalars['ID']['input'];
}>;

export type RemoveAssignmentReceivedBankTransactionMutation = {
  actionRemoveAssignmentReceivedBankTransaction: {
    data: { receivedBankTransactionId: string };
    errorList: Array<{ type: string; message: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type ArchiveReceivedBankTransactionMutationVariables = Types.Exact<{
  receivedBankTransactionId: Types.Scalars['ID']['input'];
}>;

export type ArchiveReceivedBankTransactionMutation = {
  actionArchiveReceivedBankTransaction: {
    data: { receivedBankTransactionId: string };
    errorList: Array<{ type: string; message: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveReceivedBankTransactionMutationVariables = Types.Exact<{
  receivedBankTransactionId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveReceivedBankTransactionMutation = {
  actionUnarchiveReceivedBankTransaction: {
    data: { receivedBankTransactionId: string };
    errorList: Array<{ type: string; message: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type BookReceivedBankTransactionMutationVariables = Types.Exact<{
  receivedBankTransactionId: Types.Scalars['ID']['input'];
  buchungsdatum: Types.Scalars['String']['input'];
}>;

export type BookReceivedBankTransactionMutation = {
  actionBookReceivedBankTransaction: {
    data: { receivedBankTransactionId: string };
    errorList: Array<{ type: string; message: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type MarkAsNotedReceivedBankTransactionBulkActionMutationVariables = Types.Exact<{
  input: Types.PaymentAssignmentBulkActionInput;
}>;

export type MarkAsNotedReceivedBankTransactionBulkActionMutation = {
  markAsNotedReceivedBankTransactionBulkAction: {
    errorList: Array<{ type: string; message: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type RemoveReceivedBankTransactionPaymentAssignmentMutationVariables = Types.Exact<{
  input: Types.PaymentAssignmentBulkActionInput;
}>;

export type RemoveReceivedBankTransactionPaymentAssignmentMutation = {
  removeReceivedBankTransactionPaymentAssignment: {
    errorList: Array<{ type: string; message: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const CreateReceivedBankTransactionVerbuchenGenerierlaufDocument = gql`
  mutation CreateReceivedBankTransactionVerbuchenGenerierlauf(
    $amountFrom: Float
    $amountTo: Float
    $bookingDateFrom: String
    $bookingDateTo: String
    $chargesFrom: Float
    $chargesInSeparateEntry: Boolean
    $chargesTo: Float
    $createTsFrom: String
    $createTsTo: String
    $createdByMitarbeiterIdList: [String!]
    $creditor: String
    $creditorIban: String
    $dataCarrierName: String
    $debtor: String
    $debtorIban: String
    $endToEndId: String
    $noted: Boolean
    $paymentReferenceText: String
    $purposeOfUseText: String
    $reversal: Boolean
    $statementNumber: String
    $status: ReceivedBankTransactionStatus
    $transactionType: TransactionType
    $valueDateFrom: String
    $valueDateTo: String
    $input: ReceivedBankTransactionVerbuchenGenerierlaufCreateInput!
  ) {
    createReceivedBankTransactionVerbuchenGenerierlauf(
      amountFrom: $amountFrom
      amountTo: $amountTo
      bookingDateFrom: $bookingDateFrom
      bookingDateTo: $bookingDateTo
      chargesFrom: $chargesFrom
      chargesInSeparateEntry: $chargesInSeparateEntry
      chargesTo: $chargesTo
      createTsFrom: $createTsFrom
      createTsTo: $createTsTo
      createdByMitarbeiterIdList: $createdByMitarbeiterIdList
      creditor: $creditor
      creditorIban: $creditorIban
      dataCarrierName: $dataCarrierName
      debtor: $debtor
      debtorIban: $debtorIban
      endToEndId: $endToEndId
      noted: $noted
      paymentReferenceText: $paymentReferenceText
      purposeOfUseText: $purposeOfUseText
      reversal: $reversal
      statementNumber: $statementNumber
      status: $status
      transactionType: $transactionType
      valueDateFrom: $valueDateFrom
      valueDateTo: $valueDateTo
      input: $input
    ) {
      data {
        countFehler
        countNichtVerarbeitet
        countVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        generierDatum
        generierlaufId
        status {
          description
          text
          value
        }
        warningList {
          message
          type
        }
      }
      errorList {
        type
        message
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreateReceivedBankTransactionVerbuchenGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReceivedBankTransactionVerbuchenGenerierlaufMutation,
    CreateReceivedBankTransactionVerbuchenGenerierlaufMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateReceivedBankTransactionVerbuchenGenerierlaufMutation,
    CreateReceivedBankTransactionVerbuchenGenerierlaufMutationVariables
  >(CreateReceivedBankTransactionVerbuchenGenerierlaufDocument, options);
}
export type CreateReceivedBankTransactionVerbuchenGenerierlaufMutationHookResult = ReturnType<
  typeof useCreateReceivedBankTransactionVerbuchenGenerierlaufMutation
>;
export type CreateReceivedBankTransactionVerbuchenGenerierlaufMutationResult =
  Apollo.MutationResult<CreateReceivedBankTransactionVerbuchenGenerierlaufMutation>;
export type CreateReceivedBankTransactionVerbuchenGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  CreateReceivedBankTransactionVerbuchenGenerierlaufMutation,
  CreateReceivedBankTransactionVerbuchenGenerierlaufMutationVariables
>;
export const MarkReceivedBankTransactionAsNotedDocument = gql`
  mutation MarkReceivedBankTransactionAsNoted($receivedBankTransactionId: ID!) {
    actionMarkReceivedBankTransactionAsNoted(receivedBankTransactionId: $receivedBankTransactionId) {
      data {
        receivedBankTransactionId
      }
      errorList {
        type
        message
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useMarkReceivedBankTransactionAsNotedMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkReceivedBankTransactionAsNotedMutation, MarkReceivedBankTransactionAsNotedMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkReceivedBankTransactionAsNotedMutation, MarkReceivedBankTransactionAsNotedMutationVariables>(
    MarkReceivedBankTransactionAsNotedDocument,
    options
  );
}
export type MarkReceivedBankTransactionAsNotedMutationHookResult = ReturnType<typeof useMarkReceivedBankTransactionAsNotedMutation>;
export type MarkReceivedBankTransactionAsNotedMutationResult = Apollo.MutationResult<MarkReceivedBankTransactionAsNotedMutation>;
export type MarkReceivedBankTransactionAsNotedMutationOptions = Apollo.BaseMutationOptions<
  MarkReceivedBankTransactionAsNotedMutation,
  MarkReceivedBankTransactionAsNotedMutationVariables
>;
export const AssignReceivedBankTransactionDocument = gql`
  mutation AssignReceivedBankTransaction($receivedBankTransactionId: ID!, $paymentId: ID!) {
    actionAssignReceivedBankTransaction(receivedBankTransactionId: $receivedBankTransactionId, paymentId: $paymentId) {
      data {
        receivedBankTransactionId
      }
      errorList {
        type
        message
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useAssignReceivedBankTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignReceivedBankTransactionMutation, AssignReceivedBankTransactionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignReceivedBankTransactionMutation, AssignReceivedBankTransactionMutationVariables>(
    AssignReceivedBankTransactionDocument,
    options
  );
}
export type AssignReceivedBankTransactionMutationHookResult = ReturnType<typeof useAssignReceivedBankTransactionMutation>;
export type AssignReceivedBankTransactionMutationResult = Apollo.MutationResult<AssignReceivedBankTransactionMutation>;
export type AssignReceivedBankTransactionMutationOptions = Apollo.BaseMutationOptions<
  AssignReceivedBankTransactionMutation,
  AssignReceivedBankTransactionMutationVariables
>;
export const RemoveAssignmentReceivedBankTransactionDocument = gql`
  mutation RemoveAssignmentReceivedBankTransaction($receivedBankTransactionId: ID!) {
    actionRemoveAssignmentReceivedBankTransaction(receivedBankTransactionId: $receivedBankTransactionId) {
      data {
        receivedBankTransactionId
      }
      errorList {
        type
        message
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useRemoveAssignmentReceivedBankTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveAssignmentReceivedBankTransactionMutation, RemoveAssignmentReceivedBankTransactionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveAssignmentReceivedBankTransactionMutation, RemoveAssignmentReceivedBankTransactionMutationVariables>(
    RemoveAssignmentReceivedBankTransactionDocument,
    options
  );
}
export type RemoveAssignmentReceivedBankTransactionMutationHookResult = ReturnType<typeof useRemoveAssignmentReceivedBankTransactionMutation>;
export type RemoveAssignmentReceivedBankTransactionMutationResult = Apollo.MutationResult<RemoveAssignmentReceivedBankTransactionMutation>;
export type RemoveAssignmentReceivedBankTransactionMutationOptions = Apollo.BaseMutationOptions<
  RemoveAssignmentReceivedBankTransactionMutation,
  RemoveAssignmentReceivedBankTransactionMutationVariables
>;
export const ArchiveReceivedBankTransactionDocument = gql`
  mutation ArchiveReceivedBankTransaction($receivedBankTransactionId: ID!) {
    actionArchiveReceivedBankTransaction(receivedBankTransactionId: $receivedBankTransactionId) {
      data {
        receivedBankTransactionId
      }
      errorList {
        type
        message
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useArchiveReceivedBankTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveReceivedBankTransactionMutation, ArchiveReceivedBankTransactionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveReceivedBankTransactionMutation, ArchiveReceivedBankTransactionMutationVariables>(
    ArchiveReceivedBankTransactionDocument,
    options
  );
}
export type ArchiveReceivedBankTransactionMutationHookResult = ReturnType<typeof useArchiveReceivedBankTransactionMutation>;
export type ArchiveReceivedBankTransactionMutationResult = Apollo.MutationResult<ArchiveReceivedBankTransactionMutation>;
export type ArchiveReceivedBankTransactionMutationOptions = Apollo.BaseMutationOptions<
  ArchiveReceivedBankTransactionMutation,
  ArchiveReceivedBankTransactionMutationVariables
>;
export const UnarchiveReceivedBankTransactionDocument = gql`
  mutation UnarchiveReceivedBankTransaction($receivedBankTransactionId: ID!) {
    actionUnarchiveReceivedBankTransaction(receivedBankTransactionId: $receivedBankTransactionId) {
      data {
        receivedBankTransactionId
      }
      errorList {
        type
        message
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveReceivedBankTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveReceivedBankTransactionMutation, UnarchiveReceivedBankTransactionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveReceivedBankTransactionMutation, UnarchiveReceivedBankTransactionMutationVariables>(
    UnarchiveReceivedBankTransactionDocument,
    options
  );
}
export type UnarchiveReceivedBankTransactionMutationHookResult = ReturnType<typeof useUnarchiveReceivedBankTransactionMutation>;
export type UnarchiveReceivedBankTransactionMutationResult = Apollo.MutationResult<UnarchiveReceivedBankTransactionMutation>;
export type UnarchiveReceivedBankTransactionMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveReceivedBankTransactionMutation,
  UnarchiveReceivedBankTransactionMutationVariables
>;
export const BookReceivedBankTransactionDocument = gql`
  mutation BookReceivedBankTransaction($receivedBankTransactionId: ID!, $buchungsdatum: String!) {
    actionBookReceivedBankTransaction(receivedBankTransactionId: $receivedBankTransactionId, buchungsdatum: $buchungsdatum) {
      data {
        receivedBankTransactionId
      }
      errorList {
        type
        message
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useBookReceivedBankTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<BookReceivedBankTransactionMutation, BookReceivedBankTransactionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BookReceivedBankTransactionMutation, BookReceivedBankTransactionMutationVariables>(
    BookReceivedBankTransactionDocument,
    options
  );
}
export type BookReceivedBankTransactionMutationHookResult = ReturnType<typeof useBookReceivedBankTransactionMutation>;
export type BookReceivedBankTransactionMutationResult = Apollo.MutationResult<BookReceivedBankTransactionMutation>;
export type BookReceivedBankTransactionMutationOptions = Apollo.BaseMutationOptions<
  BookReceivedBankTransactionMutation,
  BookReceivedBankTransactionMutationVariables
>;
export const MarkAsNotedReceivedBankTransactionBulkActionDocument = gql`
  mutation MarkAsNotedReceivedBankTransactionBulkAction($input: PaymentAssignmentBulkActionInput!) {
    markAsNotedReceivedBankTransactionBulkAction(input: $input) {
      errorList {
        type
        message
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useMarkAsNotedReceivedBankTransactionBulkActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkAsNotedReceivedBankTransactionBulkActionMutation,
    MarkAsNotedReceivedBankTransactionBulkActionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkAsNotedReceivedBankTransactionBulkActionMutation, MarkAsNotedReceivedBankTransactionBulkActionMutationVariables>(
    MarkAsNotedReceivedBankTransactionBulkActionDocument,
    options
  );
}
export type MarkAsNotedReceivedBankTransactionBulkActionMutationHookResult = ReturnType<
  typeof useMarkAsNotedReceivedBankTransactionBulkActionMutation
>;
export type MarkAsNotedReceivedBankTransactionBulkActionMutationResult = Apollo.MutationResult<MarkAsNotedReceivedBankTransactionBulkActionMutation>;
export type MarkAsNotedReceivedBankTransactionBulkActionMutationOptions = Apollo.BaseMutationOptions<
  MarkAsNotedReceivedBankTransactionBulkActionMutation,
  MarkAsNotedReceivedBankTransactionBulkActionMutationVariables
>;
export const RemoveReceivedBankTransactionPaymentAssignmentDocument = gql`
  mutation RemoveReceivedBankTransactionPaymentAssignment($input: PaymentAssignmentBulkActionInput!) {
    removeReceivedBankTransactionPaymentAssignment(input: $input) {
      errorList {
        type
        message
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useRemoveReceivedBankTransactionPaymentAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveReceivedBankTransactionPaymentAssignmentMutation,
    RemoveReceivedBankTransactionPaymentAssignmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveReceivedBankTransactionPaymentAssignmentMutation, RemoveReceivedBankTransactionPaymentAssignmentMutationVariables>(
    RemoveReceivedBankTransactionPaymentAssignmentDocument,
    options
  );
}
export type RemoveReceivedBankTransactionPaymentAssignmentMutationHookResult = ReturnType<
  typeof useRemoveReceivedBankTransactionPaymentAssignmentMutation
>;
export type RemoveReceivedBankTransactionPaymentAssignmentMutationResult =
  Apollo.MutationResult<RemoveReceivedBankTransactionPaymentAssignmentMutation>;
export type RemoveReceivedBankTransactionPaymentAssignmentMutationOptions = Apollo.BaseMutationOptions<
  RemoveReceivedBankTransactionPaymentAssignmentMutation,
  RemoveReceivedBankTransactionPaymentAssignmentMutationVariables
>;
