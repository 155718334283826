import { Empty } from 'antd';
import React, { FC } from 'react';
import classnames from 'classnames';
import { TABLE_ALIGNED_COLS_CLASSNAMES } from './tableWithAlignedColsClassnames';

type EmptyTableRowProps = {
  colSpan: number;
  entity: string;
};

const EmptyTableRow: FC<EmptyTableRowProps> = ({ colSpan, entity }) => (
  <tr>
    <td />
    <td colSpan={colSpan} className={classnames(TABLE_ALIGNED_COLS_CLASSNAMES.cell, TABLE_ALIGNED_COLS_CLASSNAMES.emptyTable)}>
      <Empty description={<span>Keine {entity} vorhanden</span>} />
    </td>
  </tr>
);

export default EmptyTableRow;
