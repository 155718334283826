import { generatePath } from 'react-router-dom';
import { FibuBuchungType, OffenePostenKontoType, Vertragsart } from '../../types';
import { mapToQueryString, QueryParams } from '../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../hooks/usePaginationQueryParams';

export enum OffenePostenQueryParamKey {
  BELEGNUMMER = 'belegnummer',
  BELEG_DATUM_FROM = 'belegDatumFrom',
  BELEG_DATUM_TO = 'belegDatumTo',
  BUCHUNGSDATUM_FROM = 'buchungsdatumFrom',
  BUCHUNGSDATUM_TO = 'buchungsdatumTo',
  BUCHUNGSKREIS_ID = 'buchungskreisId',
  BUCHUNGS_TYPE = 'buchungsType',
  CREATED_BY_MITARBEITER_ID_LIST = 'createdByMitarbeiterIdList',
  CREATE_TS_FROM = 'createTsFrom',
  CREATE_TS_TO = 'createTsTo',
  DUE_DATE_FROM = 'dueDateFrom',
  DUE_DATE_TO = 'dueDateTo',
  FIBU_KONTO_ID = 'fibuKontoId',
  KONTO_TYPE = 'kontoType',
  LETZTE_MAHNSTUFE = 'letzteMahnstufe',
  OBJEKT_ID = 'objektId',
  OFFENER_BETRAG_FROM = 'offenerBetragFrom',
  OFFENER_BETRAG_TO = 'offenerBetragTo',
  VERTRAGSART_LIST = 'vertragsartList',
  VERTRAGSPARTNER_ID = 'vertragspartnerId',
}

export type TOffenePostenQueryParams = QueryParams<{
  belegDatumFrom: string | null;
  belegDatumTo: string | null;
  belegnummer: string | null;
  buchungsType: FibuBuchungType | null;
  buchungsdatumFrom: string | null;
  buchungsdatumTo: string | null;
  buchungskreisId: string | null;
  createTsFrom: string | null;
  createTsTo: string | null;
  createdByMitarbeiterIdList: string[] | null;
  dueDateFrom: string | null;
  dueDateTo: string | null;
  fibuKontoId: string | null;
  kontoType: OffenePostenKontoType[];
  letzteMahnstufe: number | null;
  objektId: string | null;
  offenerBetragFrom: number | null;
  offenerBetragTo: number | null;
  vertragsartList: Vertragsart[] | null;
  vertragspartnerId: string | null;
}>;

export const URI_OFFENE_POSTEN_PAGE = '/dashboard/offenePosten';

export const updateQueryParams = (
  navigate: (path: string) => void,
  filters: TOffenePostenQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToOffenePostenPage(filters, paginationParams));

export const generatePathToOffenePostenPage = (filters: TOffenePostenQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  `${generatePath(URI_OFFENE_POSTEN_PAGE)}?${mapToQueryString({
    [OffenePostenQueryParamKey.BELEGNUMMER]: filters?.belegnummer,
    [OffenePostenQueryParamKey.BELEG_DATUM_FROM]: filters?.belegDatumFrom,
    [OffenePostenQueryParamKey.BELEG_DATUM_TO]: filters?.belegDatumTo,
    [OffenePostenQueryParamKey.BUCHUNGSDATUM_FROM]: filters?.buchungsdatumFrom,
    [OffenePostenQueryParamKey.BUCHUNGSDATUM_TO]: filters?.buchungsdatumTo,
    [OffenePostenQueryParamKey.BUCHUNGSKREIS_ID]: filters?.buchungskreisId,
    [OffenePostenQueryParamKey.BUCHUNGS_TYPE]: filters?.buchungsType,
    [OffenePostenQueryParamKey.CREATED_BY_MITARBEITER_ID_LIST]: filters?.createdByMitarbeiterIdList,
    [OffenePostenQueryParamKey.CREATE_TS_FROM]: filters?.createTsFrom,
    [OffenePostenQueryParamKey.CREATE_TS_TO]: filters?.createTsTo,
    [OffenePostenQueryParamKey.DUE_DATE_FROM]: filters?.dueDateFrom,
    [OffenePostenQueryParamKey.DUE_DATE_TO]: filters?.dueDateTo,
    [OffenePostenQueryParamKey.FIBU_KONTO_ID]: filters?.fibuKontoId,
    [OffenePostenQueryParamKey.KONTO_TYPE]: filters?.kontoType,
    [OffenePostenQueryParamKey.LETZTE_MAHNSTUFE]: filters?.letzteMahnstufe,
    [OffenePostenQueryParamKey.OBJEKT_ID]: filters?.objektId,
    [OffenePostenQueryParamKey.OFFENER_BETRAG_FROM]: filters?.offenerBetragFrom,
    [OffenePostenQueryParamKey.OFFENER_BETRAG_TO]: filters?.offenerBetragTo,
    [OffenePostenQueryParamKey.VERTRAGSART_LIST]: filters?.vertragsartList,
    [OffenePostenQueryParamKey.VERTRAGSPARTNER_ID]: filters?.vertragspartnerId,
    ...paginationParams,
  })}`;
