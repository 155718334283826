import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { AbrechnungskreisDetailsAbrechnungskreis } from '../../../../topAbrechnung-types';
import TAAbrKreisRepFonds from './AbrKreisRepFonds/TAAbrKreisRepFonds';
import TAAbrKreisNonRepFonds from './AbrKreisNonRepFonds/TAAbrKreisNonRepFonds';

const TAAbrechnungskreis: FC<{ abrechKreis: AbrechnungskreisDetailsAbrechnungskreis }> = ({ abrechKreis }) => {
  const isRepFonds = abrechKreis.type === 'REPARATUR_FONDS';

  return (
    <View key={abrechKreis.index}>
      {isRepFonds ? <TAAbrKreisRepFonds abrechKreis={abrechKreis} /> : <TAAbrKreisNonRepFonds abrechKreis={abrechKreis} />}
    </View>
  );
};

export default TAAbrechnungskreis;
