import { FormFields } from '../../../../../helpers/formikHelper';
import { ObjektVorschreibungsposition, Vertragsart } from '../../../../../types';

export interface AssignVertragsartListFormValues {
  vertragsartList: Vertragsart[];
}

export const assignVertragsartListFormFields: FormFields<AssignVertragsartListFormValues> = {
  vertragsartList: 'vertragsartList',
};

export const mapObjektVorschreibungspositionToFormValues = (
  objektVorschreibungsposition: ObjektVorschreibungsposition
): AssignVertragsartListFormValues => ({
  vertragsartList: objektVorschreibungsposition.vertragsartList.map((vertragsart) => vertragsart.value),
});
