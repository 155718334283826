import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Space, Spin } from 'antd';
import { useBookingSuggestionWithCurrentBookingDataQuery } from '../../../BookingSuggestion/gql/BookingSuggestionQueries.types';
import { generatePathToBookingDetailsWithBookingSuggestionPage } from '../../bookingDetailsPaths';

type Props = { bookingSuggestionId: string };

const BookingCorrectionWarningWithLinkToNewBooking: FC<Props> = ({ bookingSuggestionId }) => {
  const { data } = useBookingSuggestionWithCurrentBookingDataQuery({ variables: { bookingSuggestionId } });

  const currentBookingData = data?.getBookingSuggestion.data;

  return (
    <Space direction="vertical" size="small" align="end">
      <Alert message="Die Buchung wurde korrigiert" type="warning" showIcon />
      {currentBookingData ? (
        <Link
          to={generatePathToBookingDetailsWithBookingSuggestionPage(bookingSuggestionId, currentBookingData.fibuBuchungIdList)}
          target="_self"
          reloadDocument // use it to reload the page and avoid getting date from the browser cache
        >
          Zur korrigierten Buchung
        </Link>
      ) : (
        <Spin />
      )}
    </Space>
  );
};

export default BookingCorrectionWarningWithLinkToNewBooking;
