import { ROOT_URL_FIRMENDATEN_VALIDATION, ROOT_URL_VALIDATION } from './ApiPaths';
import { processAndReturnError, processResponse, returnData, returnDataAndWarnings } from '../../helpers/APIUtils';
import Fetcher from '../../helpers/fetcher';

class ValidationApi {
  static validateEmail(email) {
    return Fetcher.getInstance()
      .fetch(`${ROOT_URL_VALIDATION}/email`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
          data: {
            email,
          },
        }),
      })
      .then(processResponse)
      .then(returnData)
      .catch(processAndReturnError);
  }

  static validatePhoneNumber(phoneNumber) {
    return Fetcher.getInstance()
      .fetch(`${ROOT_URL_VALIDATION}/phone`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
          data: {
            telefonnummer: phoneNumber,
          },
        }),
      })
      .then(processResponse)
      .then(returnData)
      .catch(processAndReturnError);
  }

  static validatePassword(password) {
    return Fetcher.getInstance()
      .fetch(`${ROOT_URL_VALIDATION}/password`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
          data: {
            password,
          },
        }),
      })
      .then(processResponse)
      .then(returnData)
      .catch(processAndReturnError);
  }

  static validateVatIdNumber(vatIdentificationNumber) {
    return Fetcher.getInstance()
      .fetch(`${ROOT_URL_FIRMENDATEN_VALIDATION}/vat-identification-number`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
          data: {
            vatIdentificationNumber,
          },
        }),
      })
      .then(processResponse)
      .then(returnDataAndWarnings)
      .catch(processAndReturnError);
  }
}

export default ValidationApi;
