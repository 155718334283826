import React, { FC } from 'react';
import { Space } from 'antd';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import { paymentListingFiltersFormFields, paymentListingLabelList } from './paymentListingFiltersFormMapper';
import RechtstraegerSelect from '../../../../shared/Rechtstraeger/RechtstraegerSelect';
import PaymentPayeeIbanSelect from './PaymentPayeeIbanSelect';

type Props = {
  showBankDetails: boolean;
  onChange: () => void;
  onChangeIban: () => void;
  empfaengerId?: string | null;
};

const PaymentPayeeFilter: FC<Props> = ({ showBankDetails, onChange, onChangeIban, empfaengerId }) => {
  if (showBankDetails) {
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <FormItemWithoutColon name={paymentListingFiltersFormFields.payeeId} label={paymentListingLabelList.payeeId}>
          <RechtstraegerSelect
            name={paymentListingFiltersFormFields.payeeId}
            placeholder="Zahlenden/Zahlungsempfänger auswählen"
            onChange={onChange}
          />
        </FormItemWithoutColon>
        <FormItemWithoutColon name={paymentListingFiltersFormFields.payeeIban} label={paymentListingLabelList.payeeIban}>
          <PaymentPayeeIbanSelect name={paymentListingFiltersFormFields.payeeIban} onChange={onChangeIban} rechtstraegerId={empfaengerId} />
        </FormItemWithoutColon>
      </Space>
    );
  }

  return (
    <FormItemWithoutColon name={paymentListingFiltersFormFields.payeeId} label={paymentListingLabelList.payeeId}>
      <RechtstraegerSelect name={paymentListingFiltersFormFields.payeeId} placeholder="Zahlenden/Zahlungsempfänger auswählen" onChange={onChange} />
    </FormItemWithoutColon>
  );
};

export default PaymentPayeeFilter;
