import { SelectProps } from 'formik-antd';
import React, { FC } from 'react';
import { useAbrechnungsdefinitionListQuery } from '../Abrechnungsdefinition/gql/AbrDefQueries.types';
import { EntityStatusFormSelect } from '../../components/Status/EntityStatus';
import { useFDAuthorized } from '../../shared/Auth/useAuthorized';
import { AbrechnungsdefinitionType } from '../../types';

type Props = {
  onAbrDefSelect: (type: AbrechnungsdefinitionType) => void;
  excludeTypes?: AbrechnungsdefinitionType[];
} & SelectProps;

const AbrechnungsdefinitionSelect: FC<Props> = ({ onAbrDefSelect, excludeTypes, ...restProps }) => {
  // INFO: For some reason restProps contains onChange even though it is not passed via props
  const { onChange: onChangeNotToUse, ...propsWithoutOnChange } = restProps;

  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();

  const { loading, data } = useAbrechnungsdefinitionListQuery({
    variables: { kundenSystemId, includeArchiviert: true },
  });
  const abrechnungsdefinitionList = data?.getAbrechnungsdefinitionListByKundenSystemId.data.contentList || [];

  const filteredList = abrechnungsdefinitionList.filter((abrDef) => (excludeTypes ? !excludeTypes.includes(abrDef.type.value) : true));

  return (
    <EntityStatusFormSelect
      placeholder="Abrechnungsdefinition auswählen"
      {...propsWithoutOnChange}
      list={filteredList}
      getSelectItemId={(item) => item.abrechnungsdefinitionId}
      getSelectItemContent={(item) => item.bezeichnung}
      getSelectItemTooltipTitle={(item) => item.bezeichnung}
      loading={loading || !filteredList}
      onChange={(value: string) => {
        if (filteredList.length > 0) {
          const selectedAbrDef = filteredList.find((abrDef) => value === abrDef.abrechnungsdefinitionId);
          selectedAbrDef && onAbrDefSelect(selectedAbrDef.type.value);
        }
      }}
    />
  );
};

export default AbrechnungsdefinitionSelect;
