import { FC } from 'react';
// import HistoryListingTable from '../../History/HistoryListingTable';
// import { HistoryType } from '../../History/shared/HistoryModal';

type Props = {
  buchungsanweisungId: string;
};

const BookingInstructionChangeHistoryListingTable: FC<Props> = ({ buchungsanweisungId }) => {
  // const { data, loading } = useBuchungsanweisungChangeHistoryListQuery({ variables: { buchungsanweisungId } });
  // const historyList = data?.getBuchungsanweisungChangeHistoryList.data;
  //
  // return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="buchungsanweisung" />;
  return <>TODO: HistoryListingTable</>;
};

export default BookingInstructionChangeHistoryListingTable;
