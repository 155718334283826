import React from 'react';
import { Link } from 'react-router-dom';
import { compareTwoStringsForSorting } from '../../../../../helpers/stringHelper';
import { compareTwoDatesForSorting } from '../../../../../helpers/dateHelper';
import { compareTwoNumbersForSorting } from '../../../../../helpers/numberHelper';
import { Percent } from '../../../../../components/Number';
import StatusBadge from '../../../../../components/Status/StatusBadge';
import { MitarbeiterTooltip } from '../../../../../components/Card';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { generatePathToHeVertragDetailsPage } from '../../../../Vertrag/HeVertrag/heVertragUriPaths';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { generatePathToHeAbrechnungDetailsHeVertragDetailsPage } from '../../heAbrechnungUriPaths';
import { HeAbrechnungHeVertragListEntry } from '../../../../../types';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';
import RechtstraegerWithContactsTooltip from '../../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';
import { Gesamtergebnis } from '../../../shared/Gesamtergebnis';

const heAbrHeVertragTableColumns = (objektId: string, heAbrechnungId: string): TableWithColSelectorColumnProps<HeAbrechnungHeVertragListEntry>[] => [
  {
    title: 'Hauseigentümer',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.hauseigentuemer.kurzBezeichnung, b.hauseigentuemer.kurzBezeichnung),
    render: (text, record) => (
      <RechtstraegerWithContactsTooltip
        rechtstraegerId={record.hauseigentuemer.rechtstraegerId}
        rechtstraegerName={record.hauseigentuemer.kurzBezeichnung}
      />
    ),
  },
  {
    title: 'Anteile',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.anteile, b.anteile),
    render: (text, record) => (
      <Link to={generatePathToHeVertragDetailsPage(objektId, record.heAbrechnungHeVertragId)}>
        <DataWithShortenedText maxTextLength={40} text={record.anteile} />
      </Link>
    ),
  },
  {
    title: 'Anteile %',
    defaultSelected: true,
    sorter: (a, b) => compareTwoNumbersForSorting(a.anteilePercent, b.anteilePercent),
    render: (text, record) => <Percent value={record.anteilePercent / 100} />,
  },
  {
    title: 'Zeitraum',
    defaultSelected: true,
    sorter: (a, b) => compareTwoDatesForSorting(a.abrechnungszeitraumVon, b.abrechnungszeitraumVon),
    render: (text, record) => (
      <>
        <CustomFormattedDate value={record.abrechnungszeitraumVon} /> – <CustomFormattedDate value={record.abrechnungszeitraumBis} />
      </>
    ),
  },
  {
    title: 'Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.status.text, b.status.text),
    render: (text, record) => <StatusBadge entityStatus={record.status} showBadgeText />,
  },
  {
    title: 'Abrechnungsergebnis',
    defaultSelected: true,
    width: '150px',
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.saldo, b.saldo),
    render: (text, record) => <Gesamtergebnis guthaben={record.guthaben} saldo={record.saldo} />,
  },
  {
    title: 'Erstellt am',
    sorter: (a, b) => compareTwoDatesForSorting(a.createTs, b.createTs),
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => (
      <Link to={generatePathToHeAbrechnungDetailsHeVertragDetailsPage(objektId, heAbrechnungId, record.heAbrechnungHeVertragId)}>Details</Link>
    ),
  },
];

export default heAbrHeVertragTableColumns;
