import React, { FC, useEffect } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useField } from 'formik';
import { useFDAuthorized } from '../../shared/Auth/useAuthorized';
import {
  useKontierungstabelleListForSelectByFirmendatenIdQuery,
  useKontierungstabelleListForSelectByKundenSystemIdQuery,
} from '../Kontierungstabelle/gql/KontierungstabelleQueries.types';
import { belongsToKundenSystem, belongsToSelectedFirmendaten } from '../../helpers/selectHelper';

type Props = {
  firmendatenId?: string | null;
} & SelectProps;

const KontierungstabelleSelect: FC<Props> = ({ firmendatenId, ...restProps }) => {
  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();
  const [{ value: selectedId }, , { setValue: setSelectedId }] = useField<string>(restProps.name);

  const { loading: loadingByKS, data: maybeListByKS } = useKontierungstabelleListForSelectByKundenSystemIdQuery({
    variables: { kundenSystemId },
    skip: !!firmendatenId,
  });

  const { loading: loadingByFD, data: maybeListByFD } = useKontierungstabelleListForSelectByFirmendatenIdQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { firmendatenId: firmendatenId! },
    skip: !firmendatenId,
  });

  const loading = loadingByKS || loadingByFD;
  const listByKS = maybeListByKS?.getKontierungstabelleListByKundenSystemId.data ?? [];
  const listByFD = maybeListByFD?.getKontierungstabelleListByFirmendatenId.data ?? [];

  const list =
    listByKS.length > 0 ? listByKS.filter(belongsToKundenSystem) : listByFD.filter((item) => belongsToSelectedFirmendaten(item, firmendatenId));

  // f_id changes => list changes => if there was already a selected element it may not be available in the scope of newly selected f_id => clear element
  useEffect(() => {
    if (!loading && selectedId && !list.map((item) => item.kontierungstabelleId).includes(selectedId)) {
      setSelectedId('');
    }
  }, [loading, list, selectedId, setSelectedId]);

  return (
    <Select
      size="small"
      id={restProps.name}
      loading={loadingByFD || loadingByKS}
      placeholder="Kontierungstabelle auswählen"
      allowClear
      showSearch
      optionFilterProp="children"
      {...restProps}
    >
      {list.map((kontierungstabelle) => (
        <Select.Option key={kontierungstabelle.kontierungstabelleId} value={kontierungstabelle.kontierungstabelleId}>
          {kontierungstabelle.bezeichnung}
        </Select.Option>
      ))}
    </Select>
  );
};

export default KontierungstabelleSelect;
