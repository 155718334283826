import { FormFields, TFormattedDecimal } from '../../../../../../helpers/formikHelper';
import { InfoFeldFormValues } from '../../../../../shared/InfoFeld/infoFeldFormMapper';

export interface GrundstueckdsdatenCreateFormValues extends InfoFeldFormValues {
  anzahlGeschosseUeberNiveau?: number;
  anzahlGeschosseUnterNiveau?: number;
  bauflaeche?: TFormattedDecimal;
  baujahr?: number;
  bebauteFlaeche?: TFormattedDecimal;
  benuetzungsbewilligung?: string;
  einlagezahl?: string;
  grundstuecksnummer: string;
  katastralgemeinde?: string;
}

export const grundstuecksdatenCreateFormFields: FormFields<GrundstueckdsdatenCreateFormValues> = {
  anzahlGeschosseUeberNiveau: 'anzahlGeschosseUeberNiveau',
  anzahlGeschosseUnterNiveau: 'anzahlGeschosseUnterNiveau',
  bauflaeche: 'bauflaeche',
  baujahr: 'baujahr',
  bebauteFlaeche: 'bebauteFlaeche',
  benuetzungsbewilligung: 'benuetzungsbewilligung',
  einlagezahl: 'einlagezahl',
  grundstuecksnummer: 'grundstuecksnummer',
  katastralgemeinde: 'katastralgemeinde',
  documentList: 'documentList',
};

export const grundstuecksdatenCreateFormInitialValues: GrundstueckdsdatenCreateFormValues = {
  documentList: [],
  grundstuecksnummer: '',
};
