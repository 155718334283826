import React, { FC } from 'react';
import { Empty } from 'antd';
import { SubAbrechnungEigentuemer, SubAbrOffenePostenList } from '../../../../../../../types';
import NestedTableWithColSelector from '../../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import NestedTableWithColSelectorWrapper from '../../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelectorWrapper';
import subAbrTabOffenePostenTableColumns from './subAbrTabOffenePostenTableColumns';
import SubAbrTabOffenePostenDetailsTable from '../Level2/SubAbrTabOffenePostenDetailsTable';

type Props = {
  subAbrechnungEigentuemer: SubAbrechnungEigentuemer;
  objektId: string;
};

const SubAbrTabOffenePostenTable: FC<Props> = ({ subAbrechnungEigentuemer, objektId }) => {
  const offenePostenList = subAbrechnungEigentuemer.offenePosten ? [subAbrechnungEigentuemer.offenePosten] : [];

  return offenePostenList ? (
    <NestedTableWithColSelectorWrapper filterIdentifier="sub-abr-tab-be">
      <NestedTableWithColSelector<SubAbrOffenePostenList>
        colWidth={150}
        level={1}
        locale={{
          emptyText: <Empty description={<span>Keine Daten vorhanden</span>} />,
        }}
        columns={subAbrTabOffenePostenTableColumns}
        dataSource={offenePostenList}
        rowKey={getRowKey}
        expandable={{
          rowExpandable: (record) => !!record.summe,
          expandedRowRender: (record, index, indent, expanded) => (
            <SubAbrTabOffenePostenDetailsTable
              parentRowKeys={[getRowKey(record)]}
              visible={expanded}
              offenePosten={record.offenePostenList}
              objektId={objektId}
              abrechnungszeitraumVon={subAbrechnungEigentuemer.abrechnungszeitraumVon}
              abrechnungszeitraumBis={subAbrechnungEigentuemer.abrechnungszeitraumBis}
            />
          ),
        }}
      />
    </NestedTableWithColSelectorWrapper>
  ) : undefined;
};

const getRowKey = (record: SubAbrOffenePostenList) => `${record.summe}`;

export default SubAbrTabOffenePostenTable;
