import { FC } from 'react';
import { Input } from 'formik-antd';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';

type Props = {
  name: string;
  fieldHelp?: string | null;
};

const SepaCreditorIdFormItem: FC<Props> = ({ name, fieldHelp }) => {
  return (
    <FormItemWithFieldHelp name={name} label="SEPA Creditor ID" fieldHelp={fieldHelp}>
      <Input id={name} name={name} type="text" placeholder="z.B. AT31ZZZ00000004811" allowClear />
    </FormItemWithFieldHelp>
  );
};

export default SepaCreditorIdFormItem;
