import { Empty } from 'antd';
import React, { FC } from 'react';
import rechnungsAusstellerAbrDefTableColumns from './RechnungsAusstellerAbrDefTableColumns';
import TableWithColSelector from '../../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { AbrechnungsdefinitionType } from '../../../../../../types';
import { RechnungsAusstellerFieldsFragment } from '../../gql/RechnungsAusstellerFragments.types';
import { WeAbrechnungsdefinitionFieldsFragment } from '../../../../../Abrechnungsdefinition/gql/WeAbrDef/WeAbrDefFragments.types';
import { HeAbrechnungsdefinitionFieldsFragment } from '../../../../../Abrechnungsdefinition/gql/HeAbrDef/HeAbrDefFragments.types';
import { SubAdministrationAbrechnungsdefinitionFieldsFragment } from '../../../../../Abrechnungsdefinition/gql/SubAdministrationAbrDef/SubAdministrationAbrDefFragments.types';
import { BkAbrechnungsdefinitionFieldsFragment } from '../../../../../Abrechnungsdefinition/gql/BkAbrDef/BkAbrDefFragments.types';

type RechnungsAusstellerAbrechnungsdefinition =
  | WeAbrechnungsdefinitionFieldsFragment
  | HeAbrechnungsdefinitionFieldsFragment
  | BkAbrechnungsdefinitionFieldsFragment
  | SubAdministrationAbrechnungsdefinitionFieldsFragment;

export type TRechnAusstellerAbrechnungsdefinition = {
  type: AbrechnungsdefinitionType;
} & RechnungsAusstellerAbrechnungsdefinition;

type Props = {
  rechnungsAussteller: RechnungsAusstellerFieldsFragment;
  onSuccess: () => void;
  rechtstraegerId: string;
};

const RechnungsAusstellerAbrDefTable: FC<Props> = ({ rechnungsAussteller, onSuccess, rechtstraegerId }) => {
  const weAbrechnungsdefinitionList = rechnungsAussteller?.weAbrechnungsdefinition
    ? [{ ...rechnungsAussteller.weAbrechnungsdefinition, type: AbrechnungsdefinitionType.WeAbrechnung }]
    : [];
  const heAbrechnungsdefinitionList = rechnungsAussteller?.heAbrechnungsdefinition
    ? [{ ...rechnungsAussteller.heAbrechnungsdefinition, type: AbrechnungsdefinitionType.HeAbrechnung }]
    : [];

  const bkAbrechnungsdefinitionList = rechnungsAussteller?.bkAbrechnungsdefinition
    ? [{ ...rechnungsAussteller.bkAbrechnungsdefinition, type: AbrechnungsdefinitionType.BkAbrechnung }]
    : [];

  const subAbrechnungsdefinitionList = rechnungsAussteller?.subAdministrationAbrechnungsdefinition
    ? [{ ...rechnungsAussteller.subAdministrationAbrechnungsdefinition, type: AbrechnungsdefinitionType.SubAbrechnung }]
    : [];

  const abrechnungsdefinitionList = [
    ...weAbrechnungsdefinitionList,
    ...heAbrechnungsdefinitionList,
    ...bkAbrechnungsdefinitionList,
    ...subAbrechnungsdefinitionList,
  ];

  return (
    <TableWithColSelector<TRechnAusstellerAbrechnungsdefinition>
      locale={{
        emptyText: <Empty description={<span>Keine Abrechnungsdefinition zugewiesen</span>} />,
      }}
      pagination={false}
      dataSource={abrechnungsdefinitionList}
      columns={rechnungsAusstellerAbrDefTableColumns(onSuccess, rechtstraegerId)}
      rowKey={(record) => record.abrechnungsdefinitionId}
      filterIdentifier="rechnungsaussteller-abrechnungdefinition"
    />
  );
};

export default RechnungsAusstellerAbrDefTable;
