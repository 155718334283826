import { TextbausteinType } from '../../../../types';
import { TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';
import { Vorschreibung } from '../../../../pages/PDFTemplates/templates/vorschreibung/vorschreibung-types';
import { findTextbausteinByType } from '../../../../components/Template/PDFTemplates/templateHelpers';
import { mockVorschreibungDauerRg } from '../../../../pages/PDFTemplates/mocks/vorschreibung/vorschreibungDauerRgMocks';
import { BelegTexteFormValues } from '../belegTexteFormMapper';
import { BelegTextePlatzhalter } from '../belegTexteFormTypes';
import { VorschreibungSelectableSection } from '../../../../pages/PDFTemplates/templates/vorschreibung/VorschreibungTemplate';
import { createURLForBelegTexteFile, getBelegTexteValue, getURLToBelegTexteBildFileId } from '../../../shared/Belegtext/Template/belegTexteHelpers';
import { getBelegTexteForTypeTextOrBild } from '../../../shared/Belegtext/Template/belegTexteTemplateHelpers';
import { DocumentHeaderAndFooterImageValue } from '../../../../pages/PDFTemplates/templates/shared/types';

export const createBelegTextePDFTemplate = (
  textbausteinList: TextbausteinFormValues[],
  belegTextePlatzhalter: BelegTextePlatzhalter,
  formikValues: BelegTexteFormValues,
  firmendatenId: string,
  rechtstraegerId?: string
): Vorschreibung => {
  const belegKopfTextbaustein = findTextbausteinByType(textbausteinList, TextbausteinType.BelegKopf);
  const belegFussTextbaustein = findTextbausteinByType(textbausteinList, TextbausteinType.BelegFuss);

  const belegKopfValue: DocumentHeaderAndFooterImageValue | string | undefined = getBelegTexteValue(
    formikValues.belegKopfTextAsHtml,
    getURLToBelegTexteBildFileId(firmendatenId, formikValues.belegKopfBildFileId, formikValues.belegKopfBildAlignment),
    createURLForBelegTexteFile(firmendatenId, formikValues.belegKopf, formikValues.belegKopfBildAlignment)
  );

  const belegFussValue: DocumentHeaderAndFooterImageValue | string | undefined = getBelegTexteValue(
    formikValues.belegFussTextAsHtml,
    getURLToBelegTexteBildFileId(firmendatenId, formikValues.belegFussBildFileId, formikValues.belegFussBildAlignment),
    createURLForBelegTexteFile(firmendatenId, formikValues.belegFuss, formikValues.belegFussBildAlignment)
  );

  const isBelegTextKopfEmpty = !(formikValues.belegKopf || formikValues.belegKopfBildFileId || formikValues.belegKopfText);

  // FIXME: mockVorschreibungDauerRg should come from backend
  return {
    ...mockVorschreibungDauerRg,
    header: getBelegTexteForTypeTextOrBild(belegKopfValue, belegTextePlatzhalter, belegKopfTextbaustein),
    footer:
      // Rules for rendering belegFuss based on http://jira.andromeda.intern/browse/EC-9150
      //  Rechnungsaussteller context view: Show BelegFuss only if BelegKopf is not empty => assured by check !isBelegTextKopfEmpty
      //  Hausverwaltung context view (Firmendaten on Verwaltung page): Show BelegFuss regardless if BelegKopf exists => assured by check !rechtstraegerId
      !isBelegTextKopfEmpty || !rechtstraegerId
        ? getBelegTexteForTypeTextOrBild(belegFussValue, belegTextePlatzhalter, belegFussTextbaustein)
        : undefined,
  };
};

export const mapTextbausteinToBelegTexteSelectableSection = (type?: TextbausteinType): VorschreibungSelectableSection | undefined => {
  if (!type) {
    return undefined;
  }

  switch (type) {
    case TextbausteinType.BelegFuss:
      return 'FOOTER';
    case TextbausteinType.BelegKopf:
      return 'HEADER';
    default:
      return undefined;
  }
};
