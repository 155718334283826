import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { FibuRechtstraegerFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import { FibuKontoFieldsFragmentDoc } from '../../Kontenplan/gql/KontenplanFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PaymentClearingListQueryVariables = Types.Exact<{
  availableAmountFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  availableAmountTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  buchungskreisId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  kontoId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  kontoVerwendung?: Types.InputMaybe<Types.KontoVerwendung>;
  outstandingAmountFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  outstandingAmountTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  vertragspartnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  order?: Types.InputMaybe<Types.PaymentClearingOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type PaymentClearingListQuery = {
  getPaymentClearingList: {
    data: {
      contentList: Array<{
        availableAmount?: number | null;
        buchungskreisId: string;
        buchungskreisRechtstraegerId: string;
        buchungskreisRechtstraegerName: string;
        kontoBezeichnung: string;
        kontoId: string;
        kontoNummer: string;
        outstandingAmount: number;
        vertragspartnerId: string;
        vertragspartnerName: string;
        kontoVerwendung: { text: string; value: Types.KontoVerwendung };
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type PaymentClearingListSummaryQueryVariables = Types.Exact<{
  availableAmountFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  availableAmountTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  buchungskreisId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  kontoId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  kontoVerwendung?: Types.InputMaybe<Types.KontoVerwendung>;
  outstandingAmountFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  outstandingAmountTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  vertragspartnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type PaymentClearingListSummaryQuery = {
  getPaymentClearingListSummary: {
    data: { sumAvailableAmount: number; sumOutstandingAmount: number };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type PaymentClearingBookingListQueryVariables = Types.Exact<{
  buchungskreisId: Types.Scalars['String']['input'];
  vertragspartnerId: Types.Scalars['String']['input'];
  kontoId: Types.Scalars['String']['input'];
}>;

export type PaymentClearingBookingListQuery = {
  getPaymentClearingBookingList: {
    data: Array<{
      amount: number;
      availableAmount: number;
      belegDatum: string;
      belegFileId?: string | null;
      belegnummer: string;
      buchungId: string;
      buchungsdatum: string;
      clearedAmount: number;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      dataCarrierBelegId?: string | null;
      iban?: string | null;
      statementNumber?: string | null;
      updatedByMitarbeiterId?: string | null;
      buchungsType: { text: string; value: Types.FibuBuchungType };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterPaymentClearingBuchungskreisListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FilterPaymentClearingBuchungskreisListQuery = {
  getFilterPaymentClearingBuchungskreisList: {
    data: Array<{
      buchungskreisId: string;
      rechtstraeger: {
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
      };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterPaymentClearingKontoListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FilterPaymentClearingKontoListQuery = {
  getFilterPaymentClearingKontoList: {
    data: Array<{
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      haupt: boolean;
      kontoId: string;
      kontoTemplateId?: string | null;
      nummer: string;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      klasse: { text: string; value: Types.KontoKlasse };
      status: { description?: string | null; text: string; value: Types.KontoStatus };
      type: { text: string; value: Types.KontoType };
      verwendung?: { text: string; value: Types.KontoVerwendung } | null;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterPaymentClearingKontoVerwendungListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FilterPaymentClearingKontoVerwendungListQuery = {
  getFilterPaymentClearingKontoVerwendungList: {
    data: Array<{ text: string; value: Types.KontoVerwendung }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterPaymentClearingVertragspartnerListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FilterPaymentClearingVertragspartnerListQuery = {
  getFilterPaymentClearingVertragspartnerList: {
    data: Array<{
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const PaymentClearingListDocument = gql`
  query PaymentClearingList(
    $availableAmountFrom: Float
    $availableAmountTo: Float
    $buchungskreisId: String
    $kontoId: String
    $kontoVerwendung: KontoVerwendung
    $outstandingAmountFrom: Float
    $outstandingAmountTo: Float
    $vertragspartnerId: String
    $order: PaymentClearingOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getPaymentClearingList(
      availableAmountFrom: $availableAmountFrom
      availableAmountTo: $availableAmountTo
      buchungskreisId: $buchungskreisId
      kontoId: $kontoId
      kontoVerwendung: $kontoVerwendung
      outstandingAmountFrom: $outstandingAmountFrom
      outstandingAmountTo: $outstandingAmountTo
      vertragspartnerId: $vertragspartnerId
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          availableAmount
          buchungskreisId
          buchungskreisRechtstraegerId
          buchungskreisRechtstraegerName
          kontoBezeichnung
          kontoId
          kontoNummer
          kontoVerwendung {
            text
            value
          }
          outstandingAmount
          vertragspartnerId
          vertragspartnerName
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function usePaymentClearingListQuery(baseOptions?: Apollo.QueryHookOptions<PaymentClearingListQuery, PaymentClearingListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentClearingListQuery, PaymentClearingListQueryVariables>(PaymentClearingListDocument, options);
}
export function usePaymentClearingListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentClearingListQuery, PaymentClearingListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentClearingListQuery, PaymentClearingListQueryVariables>(PaymentClearingListDocument, options);
}
export function usePaymentClearingListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PaymentClearingListQuery, PaymentClearingListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaymentClearingListQuery, PaymentClearingListQueryVariables>(PaymentClearingListDocument, options);
}
export type PaymentClearingListQueryHookResult = ReturnType<typeof usePaymentClearingListQuery>;
export type PaymentClearingListLazyQueryHookResult = ReturnType<typeof usePaymentClearingListLazyQuery>;
export type PaymentClearingListSuspenseQueryHookResult = ReturnType<typeof usePaymentClearingListSuspenseQuery>;
export type PaymentClearingListQueryResult = Apollo.QueryResult<PaymentClearingListQuery, PaymentClearingListQueryVariables>;
export const PaymentClearingListSummaryDocument = gql`
  query PaymentClearingListSummary(
    $availableAmountFrom: Float
    $availableAmountTo: Float
    $buchungskreisId: String
    $kontoId: String
    $kontoVerwendung: KontoVerwendung
    $outstandingAmountFrom: Float
    $outstandingAmountTo: Float
    $vertragspartnerId: String
  ) {
    getPaymentClearingListSummary(
      availableAmountFrom: $availableAmountFrom
      availableAmountTo: $availableAmountTo
      buchungskreisId: $buchungskreisId
      kontoId: $kontoId
      kontoVerwendung: $kontoVerwendung
      outstandingAmountFrom: $outstandingAmountFrom
      outstandingAmountTo: $outstandingAmountTo
      vertragspartnerId: $vertragspartnerId
    ) {
      data {
        sumAvailableAmount
        sumOutstandingAmount
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function usePaymentClearingListSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<PaymentClearingListSummaryQuery, PaymentClearingListSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentClearingListSummaryQuery, PaymentClearingListSummaryQueryVariables>(PaymentClearingListSummaryDocument, options);
}
export function usePaymentClearingListSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentClearingListSummaryQuery, PaymentClearingListSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentClearingListSummaryQuery, PaymentClearingListSummaryQueryVariables>(PaymentClearingListSummaryDocument, options);
}
export function usePaymentClearingListSummarySuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PaymentClearingListSummaryQuery, PaymentClearingListSummaryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaymentClearingListSummaryQuery, PaymentClearingListSummaryQueryVariables>(
    PaymentClearingListSummaryDocument,
    options
  );
}
export type PaymentClearingListSummaryQueryHookResult = ReturnType<typeof usePaymentClearingListSummaryQuery>;
export type PaymentClearingListSummaryLazyQueryHookResult = ReturnType<typeof usePaymentClearingListSummaryLazyQuery>;
export type PaymentClearingListSummarySuspenseQueryHookResult = ReturnType<typeof usePaymentClearingListSummarySuspenseQuery>;
export type PaymentClearingListSummaryQueryResult = Apollo.QueryResult<PaymentClearingListSummaryQuery, PaymentClearingListSummaryQueryVariables>;
export const PaymentClearingBookingListDocument = gql`
  query PaymentClearingBookingList($buchungskreisId: String!, $vertragspartnerId: String!, $kontoId: String!) {
    getPaymentClearingBookingList(buchungskreisId: $buchungskreisId, vertragspartnerId: $vertragspartnerId, kontoId: $kontoId) {
      data {
        amount
        availableAmount
        belegDatum
        belegFileId
        belegnummer
        buchungId
        buchungsType {
          text
          value
        }
        buchungsdatum
        clearedAmount
        createTs
        createdBy
        createdByMitarbeiterId
        dataCarrierBelegId
        iban
        statementNumber
        updatedByMitarbeiterId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function usePaymentClearingBookingListQuery(
  baseOptions: Apollo.QueryHookOptions<PaymentClearingBookingListQuery, PaymentClearingBookingListQueryVariables> &
    ({ variables: PaymentClearingBookingListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentClearingBookingListQuery, PaymentClearingBookingListQueryVariables>(PaymentClearingBookingListDocument, options);
}
export function usePaymentClearingBookingListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentClearingBookingListQuery, PaymentClearingBookingListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentClearingBookingListQuery, PaymentClearingBookingListQueryVariables>(PaymentClearingBookingListDocument, options);
}
export function usePaymentClearingBookingListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PaymentClearingBookingListQuery, PaymentClearingBookingListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaymentClearingBookingListQuery, PaymentClearingBookingListQueryVariables>(
    PaymentClearingBookingListDocument,
    options
  );
}
export type PaymentClearingBookingListQueryHookResult = ReturnType<typeof usePaymentClearingBookingListQuery>;
export type PaymentClearingBookingListLazyQueryHookResult = ReturnType<typeof usePaymentClearingBookingListLazyQuery>;
export type PaymentClearingBookingListSuspenseQueryHookResult = ReturnType<typeof usePaymentClearingBookingListSuspenseQuery>;
export type PaymentClearingBookingListQueryResult = Apollo.QueryResult<PaymentClearingBookingListQuery, PaymentClearingBookingListQueryVariables>;
export const FilterPaymentClearingBuchungskreisListDocument = gql`
  query FilterPaymentClearingBuchungskreisList {
    getFilterPaymentClearingBuchungskreisList {
      data {
        buchungskreisId
        rechtstraeger {
          ...FibuRechtstraeger
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${FibuRechtstraegerFragmentDoc}
`;
export function useFilterPaymentClearingBuchungskreisListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterPaymentClearingBuchungskreisListQuery, FilterPaymentClearingBuchungskreisListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterPaymentClearingBuchungskreisListQuery, FilterPaymentClearingBuchungskreisListQueryVariables>(
    FilterPaymentClearingBuchungskreisListDocument,
    options
  );
}
export function useFilterPaymentClearingBuchungskreisListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterPaymentClearingBuchungskreisListQuery, FilterPaymentClearingBuchungskreisListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterPaymentClearingBuchungskreisListQuery, FilterPaymentClearingBuchungskreisListQueryVariables>(
    FilterPaymentClearingBuchungskreisListDocument,
    options
  );
}
export function useFilterPaymentClearingBuchungskreisListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterPaymentClearingBuchungskreisListQuery, FilterPaymentClearingBuchungskreisListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterPaymentClearingBuchungskreisListQuery, FilterPaymentClearingBuchungskreisListQueryVariables>(
    FilterPaymentClearingBuchungskreisListDocument,
    options
  );
}
export type FilterPaymentClearingBuchungskreisListQueryHookResult = ReturnType<typeof useFilterPaymentClearingBuchungskreisListQuery>;
export type FilterPaymentClearingBuchungskreisListLazyQueryHookResult = ReturnType<typeof useFilterPaymentClearingBuchungskreisListLazyQuery>;
export type FilterPaymentClearingBuchungskreisListSuspenseQueryHookResult = ReturnType<typeof useFilterPaymentClearingBuchungskreisListSuspenseQuery>;
export type FilterPaymentClearingBuchungskreisListQueryResult = Apollo.QueryResult<
  FilterPaymentClearingBuchungskreisListQuery,
  FilterPaymentClearingBuchungskreisListQueryVariables
>;
export const FilterPaymentClearingKontoListDocument = gql`
  query FilterPaymentClearingKontoList {
    getFilterPaymentClearingKontoList {
      data {
        ...FibuKontoFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${FibuKontoFieldsFragmentDoc}
`;
export function useFilterPaymentClearingKontoListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterPaymentClearingKontoListQuery, FilterPaymentClearingKontoListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterPaymentClearingKontoListQuery, FilterPaymentClearingKontoListQueryVariables>(
    FilterPaymentClearingKontoListDocument,
    options
  );
}
export function useFilterPaymentClearingKontoListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterPaymentClearingKontoListQuery, FilterPaymentClearingKontoListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterPaymentClearingKontoListQuery, FilterPaymentClearingKontoListQueryVariables>(
    FilterPaymentClearingKontoListDocument,
    options
  );
}
export function useFilterPaymentClearingKontoListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterPaymentClearingKontoListQuery, FilterPaymentClearingKontoListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterPaymentClearingKontoListQuery, FilterPaymentClearingKontoListQueryVariables>(
    FilterPaymentClearingKontoListDocument,
    options
  );
}
export type FilterPaymentClearingKontoListQueryHookResult = ReturnType<typeof useFilterPaymentClearingKontoListQuery>;
export type FilterPaymentClearingKontoListLazyQueryHookResult = ReturnType<typeof useFilterPaymentClearingKontoListLazyQuery>;
export type FilterPaymentClearingKontoListSuspenseQueryHookResult = ReturnType<typeof useFilterPaymentClearingKontoListSuspenseQuery>;
export type FilterPaymentClearingKontoListQueryResult = Apollo.QueryResult<
  FilterPaymentClearingKontoListQuery,
  FilterPaymentClearingKontoListQueryVariables
>;
export const FilterPaymentClearingKontoVerwendungListDocument = gql`
  query FilterPaymentClearingKontoVerwendungList {
    getFilterPaymentClearingKontoVerwendungList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterPaymentClearingKontoVerwendungListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterPaymentClearingKontoVerwendungListQuery, FilterPaymentClearingKontoVerwendungListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterPaymentClearingKontoVerwendungListQuery, FilterPaymentClearingKontoVerwendungListQueryVariables>(
    FilterPaymentClearingKontoVerwendungListDocument,
    options
  );
}
export function useFilterPaymentClearingKontoVerwendungListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterPaymentClearingKontoVerwendungListQuery, FilterPaymentClearingKontoVerwendungListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterPaymentClearingKontoVerwendungListQuery, FilterPaymentClearingKontoVerwendungListQueryVariables>(
    FilterPaymentClearingKontoVerwendungListDocument,
    options
  );
}
export function useFilterPaymentClearingKontoVerwendungListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterPaymentClearingKontoVerwendungListQuery, FilterPaymentClearingKontoVerwendungListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterPaymentClearingKontoVerwendungListQuery, FilterPaymentClearingKontoVerwendungListQueryVariables>(
    FilterPaymentClearingKontoVerwendungListDocument,
    options
  );
}
export type FilterPaymentClearingKontoVerwendungListQueryHookResult = ReturnType<typeof useFilterPaymentClearingKontoVerwendungListQuery>;
export type FilterPaymentClearingKontoVerwendungListLazyQueryHookResult = ReturnType<typeof useFilterPaymentClearingKontoVerwendungListLazyQuery>;
export type FilterPaymentClearingKontoVerwendungListSuspenseQueryHookResult = ReturnType<
  typeof useFilterPaymentClearingKontoVerwendungListSuspenseQuery
>;
export type FilterPaymentClearingKontoVerwendungListQueryResult = Apollo.QueryResult<
  FilterPaymentClearingKontoVerwendungListQuery,
  FilterPaymentClearingKontoVerwendungListQueryVariables
>;
export const FilterPaymentClearingVertragspartnerListDocument = gql`
  query FilterPaymentClearingVertragspartnerList {
    getFilterPaymentClearingVertragspartnerList {
      data {
        ...FibuRechtstraeger
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${FibuRechtstraegerFragmentDoc}
`;
export function useFilterPaymentClearingVertragspartnerListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterPaymentClearingVertragspartnerListQuery, FilterPaymentClearingVertragspartnerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterPaymentClearingVertragspartnerListQuery, FilterPaymentClearingVertragspartnerListQueryVariables>(
    FilterPaymentClearingVertragspartnerListDocument,
    options
  );
}
export function useFilterPaymentClearingVertragspartnerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterPaymentClearingVertragspartnerListQuery, FilterPaymentClearingVertragspartnerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterPaymentClearingVertragspartnerListQuery, FilterPaymentClearingVertragspartnerListQueryVariables>(
    FilterPaymentClearingVertragspartnerListDocument,
    options
  );
}
export function useFilterPaymentClearingVertragspartnerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterPaymentClearingVertragspartnerListQuery, FilterPaymentClearingVertragspartnerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterPaymentClearingVertragspartnerListQuery, FilterPaymentClearingVertragspartnerListQueryVariables>(
    FilterPaymentClearingVertragspartnerListDocument,
    options
  );
}
export type FilterPaymentClearingVertragspartnerListQueryHookResult = ReturnType<typeof useFilterPaymentClearingVertragspartnerListQuery>;
export type FilterPaymentClearingVertragspartnerListLazyQueryHookResult = ReturnType<typeof useFilterPaymentClearingVertragspartnerListLazyQuery>;
export type FilterPaymentClearingVertragspartnerListSuspenseQueryHookResult = ReturnType<
  typeof useFilterPaymentClearingVertragspartnerListSuspenseQuery
>;
export type FilterPaymentClearingVertragspartnerListQueryResult = Apollo.QueryResult<
  FilterPaymentClearingVertragspartnerListQuery,
  FilterPaymentClearingVertragspartnerListQueryVariables
>;
