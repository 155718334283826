import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../helpers/selectHelper';
import DataWithShortenedText from '../../components/Helpers/DataWithShortenedText';
import { useRechnungsAusstellerListQuery } from './gql/RechnungsausstellerQueries.types';

type Props = SelectProps;

const RechnungsausstellerSelect: FC<Props> = ({ ...restProps }) => {
  const { data, loading } = useRechnungsAusstellerListQuery();
  const rechnungsAusstellerList = data?.getRechnungsAusstellerList.data || [];

  return (
    <Select size="middle" placeholder="" id={restProps.name} loading={loading} allowClear showSearch filterOption={selectFilterOption} {...restProps}>
      {rechnungsAusstellerList.map((rechtstrager) => (
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        <Select.Option key={rechtstrager.rechtstraegerId!} value={rechtstrager.rechtstraegerId!}>
          <DataWithShortenedText maxTextLength={25} text={rechtstrager.kurzBezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default RechnungsausstellerSelect;
