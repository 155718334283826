import React, { FC } from 'react';
import FirmendatenDeletionHistoryDropdown from './FirmendatenDeletionHistoryDropdown';
import RechtstraegerDeletionHistoryDropdown from '../../../../features/Rechtstraeger/DeletionHistory/RechtstraegerDeletionHistoryDropdown';
import { RechtstraegerType } from '../../../../types';

type Props = {
  firmendatenId?: string;
  firmaId?: string;
};

const DeletionHistoryDropdown: FC<Props> = ({ firmendatenId, firmaId }) => {
  if (firmendatenId) {
    return <FirmendatenDeletionHistoryDropdown firmendatenId={firmendatenId} />;
  } else if (firmaId) {
    return <RechtstraegerDeletionHistoryDropdown rechtstraegerId={firmaId} rechtstraegerType={RechtstraegerType.Firma} />;
  }
  return null;
};

export default DeletionHistoryDropdown;
