import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { OrderGenerierlaufType } from '../../../../../types';
import { capitalizeFirstLetter } from '../../../../../helpers/stringHelper';
import { useFilterGenLaufOrderGenerierlaufEntryStatusListQuery } from '../../../gql/GenerierlaufFilter/OrderGenerierlaufFilterQueries.types';

type Props = {
  name: string;
  generierlaufId: string;
  generierlaufType: OrderGenerierlaufType;
} & SelectProps;

const GenerierlaufEntryExitCodeListFormSelect: FC<Props> = ({ name, generierlaufId, generierlaufType, ...restProps }) => {
  const { data, loading } = useFilterGenLaufOrderGenerierlaufEntryStatusListQuery({
    variables: {
      generierlaufId,
      generierlaufType,
    },
  });

  const list = data?.getFilterGenLaufOrderGenerierlaufEntryStatusList.data ?? [];

  return (
    <Select
      size="small"
      loading={loading}
      id={name}
      name={name}
      placeholder="Exitcode auswählen"
      mode="multiple"
      maxTagCount="responsive"
      {...restProps}
    >
      {list.map((status) => (
        <Select.Option key={status.value} value={status.value}>
          {capitalizeFirstLetter(status.text) + status.text.slice(1)}
        </Select.Option>
      ))}
    </Select>
  );
};

export default GenerierlaufEntryExitCodeListFormSelect;
