import { List } from 'antd';
import { FC } from 'react';
import InfoFeldDocumentItem from './InfoFeldDocumentItem';
import { TObjektInfoFeldData } from '../../../Objekt/InfoFeld/List/objektInfoFeldListHelpers';
import { TVertragInfoFeldData } from '../../../Vertrag/BeVertrag/InfoFeld/List/vertragInfoFeldListHelpers';
import { TRechtstraegerInfoFeldData } from '../../../Rechtstraeger/InfoFeld/List/rechtstraegerInfoFeldListHelpers';
import { BeInfoFeldFragment } from '../../../Bestandseinheit/InfoFeld/gql/BeInfoFeldFragments.types';

type InfoFeldDocumentTabProps = {
  infoFeld: TObjektInfoFeldData | BeInfoFeldFragment | TVertragInfoFeldData | TRechtstraegerInfoFeldData;
  deleteDocumentFileIdList: string[];
  onDocumentDelete: (value: string[]) => void;
  firmendatenId: string;
};

const InfoFeldDocumentTab: FC<InfoFeldDocumentTabProps> = ({ infoFeld, deleteDocumentFileIdList, onDocumentDelete, firmendatenId }) => {
  const filteredList = infoFeld.fileIdList.filter((fileId) => !deleteDocumentFileIdList.includes(fileId));

  return (
    <List
      dataSource={filteredList}
      renderItem={(fileId) => (
        <InfoFeldDocumentItem
          fileId={fileId}
          deleteDocumentFileIdList={deleteDocumentFileIdList}
          onDocumentDelete={onDocumentDelete}
          firmendatenId={firmendatenId}
        />
      )}
    />
  );
};

export default InfoFeldDocumentTab;
