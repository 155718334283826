import { Table } from 'antd';
import { FC } from 'react';
import { bestandseinheitVertragTableColumns } from './bestandseinheitVertragTableColumns';
import { BeVertragListEntry } from '../../../../types';

type BestandseinheitVertragTableProps = {
  bestandseinheitId: string;
  objektId: string;
  vertragList?: BeVertragListEntry[];
};

const BestandseinheitVertragTable: FC<BestandseinheitVertragTableProps> = ({ bestandseinheitId, objektId, vertragList }) => (
  <Table<BeVertragListEntry>
    size="small"
    dataSource={vertragList}
    columns={bestandseinheitVertragTableColumns(objektId, bestandseinheitId)}
    rowKey={(record) => record.vertragId}
    pagination={false}
  />
);

export default BestandseinheitVertragTable;
