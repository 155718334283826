import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { ContactFieldsFragmentDoc, ContactPersonFieldsFragmentDoc } from '../../Contact/gql/ContactFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserMeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UserMeQuery = {
  getMe: {
    data: {
      createdTs: string;
      email?: string | null;
      emailUnverified?: string | null;
      lastname?: string | null;
      role: Types.UserRole;
      username: string;
      firstname?: string | null;
      contactList?: Array<{
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      status: { description?: string | null; text: string; value: Types.UserStatus };
    };
  };
};

export const UserMeDocument = gql`
  query UserMe {
    getMe {
      data {
        createdTs
        email
        emailUnverified
        contactList {
          ...ContactFields
        }
        lastname
        role
        status {
          description
          text
          value
        }
        username
        firstname
      }
    }
  }
  ${ContactFieldsFragmentDoc}
`;
export function useUserMeQuery(baseOptions?: Apollo.QueryHookOptions<UserMeQuery, UserMeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserMeQuery, UserMeQueryVariables>(UserMeDocument, options);
}
export function useUserMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserMeQuery, UserMeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserMeQuery, UserMeQueryVariables>(UserMeDocument, options);
}
export function useUserMeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserMeQuery, UserMeQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserMeQuery, UserMeQueryVariables>(UserMeDocument, options);
}
export type UserMeQueryHookResult = ReturnType<typeof useUserMeQuery>;
export type UserMeLazyQueryHookResult = ReturnType<typeof useUserMeLazyQuery>;
export type UserMeSuspenseQueryHookResult = ReturnType<typeof useUserMeSuspenseQuery>;
export type UserMeQueryResult = Apollo.QueryResult<UserMeQuery, UserMeQueryVariables>;
