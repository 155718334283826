import { FC } from 'react';
import { useRechtstraegerPostofficeBoxAddressDeletionHistoryListQuery } from '../gql/RechtsTraegerQueries.types';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';

type Props = {
  rechtstraegerId: string;
};

const RechstragerPostofficeBoxAddressDeletionHistoryListingTable: FC<Props> = ({ rechtstraegerId }) => {
  const { data, loading } = useRechtstraegerPostofficeBoxAddressDeletionHistoryListQuery({ variables: { rechtstraegerId } });
  const historyList = data?.getRechtstraegerPostofficeBoxAddressDeletionHistoryList.data;

  return (
    <HistoryListingTable
      historyType={HistoryType.Deletion}
      historyList={historyList}
      loading={loading}
      filterIdPrefix="rechtstraeger-postoffice-box-address"
    />
  );
};

export default RechstragerPostofficeBoxAddressDeletionHistoryListingTable;
