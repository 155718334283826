import React, { FC } from 'react';
import { Empty, PaginationProps } from 'antd';
import verarbeitungFormTableColumns from './verarbeitungFormTableColumns';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { Generierlauf } from '../../../../types';
import { HandleTableSorting } from '../../../../shared/typeHelpers';

type Props = {
  generierlaufList?: Generierlauf[];
  handleTableSorting: HandleTableSorting;
  pagination: PaginationProps;
};

const VerarbeitungTable: FC<Props> = ({ handleTableSorting, pagination, generierlaufList }) => {
  return (
    <TableWithColSelector<Generierlauf>
      size="small"
      locale={{
        emptyText: <Empty description={<span>Keine Verarbeitung vorhanden</span>} />,
      }}
      loading={!generierlaufList}
      pagination={pagination}
      columns={verarbeitungFormTableColumns}
      dataSource={generierlaufList}
      onChange={handleTableSorting}
      rowKey={(record) => record.generierlaufId}
      filterIdentifier="main-verarbeitung"
    />
  );
};

export default VerarbeitungTable;
