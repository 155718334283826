import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { FormikProps } from 'formik';
import IndexSeriesSelect from '../../../../../../../../IndexSeries/shared/IndexSeriesSelect';
import { IndexSeriesSourceType, IndexType, Month } from '../../../../../../../../../types';
import DrawerRow from '../../../../../../../../../components/Drawer/DrawerRow';
import { indAgreeIndexAnnualFormFields, IndexationAgreementIndexAnnualFormValues } from './indexAnnualFormMapper';
import MonthSelect from '../../../../../../../../shared/Month/MonthSelect';
import AnnualAverageFormItem from './AnnualAverageFormItem';
import { isIndexSeriesSourceTypeImport } from '../shared/indexationAgreementFormHelpers';
import MonthYearFormItem from '../shared/MonthYearFormItem';
import FormItemWithFieldHelp from '../../../../../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  formikProps: FormikProps<IndexationAgreementIndexAnnualFormValues>;
  onIndexSeriesChange: (indexSeriesId: string) => void;
  indexSourceType?: IndexSeriesSourceType;
  fieldHelpNames?: {
    validFrom?: string | null;
    indexSeriesId?: string | null;
    annualAverage?: string | null;
    monthOfIndexPoint?: string | null;
    effectiveWithMonth?: string | null;
  };
};

const IndexBlock: FC<Props> = ({ formikProps, onIndexSeriesChange, indexSourceType, fieldHelpNames }) => {
  const [showAnnualAverage, setShowAnnualAverage] = React.useState<boolean>(isIndexSeriesSourceTypeImport(indexSourceType));

  const onAnnualAverageChange = (value: boolean) => {
    formikProps.setFieldValue(indAgreeIndexAnnualFormFields.effectiveWithMonth, null);
    !value
      ? formikProps.setFieldValue(indAgreeIndexAnnualFormFields.monthOfIndexPoint, Month.January)
      : formikProps.setFieldValue(indAgreeIndexAnnualFormFields.monthOfIndexPoint, Month.December);
  };

  // console.log('formikProps.values.annualAverage', formikProps.values.annualAverage, formikProps.values. )
  const content = (
    <>
      <Row gutter={8}>
        <Col span={24}>
          <MonthYearFormItem
            name={indAgreeIndexAnnualFormFields.validFrom}
            label="Gültig ab"
            placeholder="Gültig ab auswählen"
            fieldHelp={fieldHelpNames?.validFrom}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItemWithFieldHelp name={indAgreeIndexAnnualFormFields.indexSeriesId} label="Indexreihe" fieldHelp={fieldHelpNames?.indexSeriesId}>
            <IndexSeriesSelect
              name={indAgreeIndexAnnualFormFields.indexSeriesId}
              indexType={IndexType.Index}
              onSelectChange={(indexSeriesId, sourceType) => {
                onIndexSeriesChange(indexSeriesId);
                setShowAnnualAverage(isIndexSeriesSourceTypeImport(sourceType));
                isIndexSeriesSourceTypeImport(sourceType) &&
                  formikProps.values.annualAverage &&
                  formikProps.setFieldValue(indAgreeIndexAnnualFormFields.monthOfIndexPoint, Month.December);
              }}
            />
          </FormItemWithFieldHelp>
        </Col>
      </Row>

      {showAnnualAverage && (
        <Row>
          <Col span={12}>
            <AnnualAverageFormItem formikProps={formikProps} onChange={onAnnualAverageChange} fieldHelp={fieldHelpNames?.annualAverage} />
          </Col>
        </Row>
      )}

      <Row gutter={8}>
        <Col span={12}>
          <FormItemWithFieldHelp
            name={indAgreeIndexAnnualFormFields.monthOfIndexPoint}
            label="Indexwert aus Monat"
            fieldHelp={fieldHelpNames?.monthOfIndexPoint}
          >
            <MonthSelect name={indAgreeIndexAnnualFormFields.monthOfIndexPoint} disabled={showAnnualAverage && formikProps.values.annualAverage} />
          </FormItemWithFieldHelp>
        </Col>
        <Col span={12}>
          <FormItemWithFieldHelp
            name={indAgreeIndexAnnualFormFields.effectiveWithMonth}
            label="Wirksam mit Monat"
            fieldHelp={fieldHelpNames?.effectiveWithMonth}
          >
            <MonthSelect name={indAgreeIndexAnnualFormFields.effectiveWithMonth} />
          </FormItemWithFieldHelp>
        </Col>
      </Row>
    </>
  );

  return <DrawerRow rowTitle="Index" content={content} />;
};

export default IndexBlock;
