import React from 'react';
import { Link } from 'react-router-dom';

import { Space, Typography } from 'antd';
import StatusBadge from '../../../../components/Status/StatusBadge';
import DataWithStatus from '../../../../components/Helpers/DataWithStatus';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { AbrechnungListEntry, AbrechnungOrderBy, AbrechnungType } from '../../../../types';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import {
  isAbrechnungTypeBkAbrechnung,
  isAbrechnungTypeBkTopAbrechnung,
  isAbrechnungTypeHeAbrechnung,
  isAbrechnungTypeSubAbrechnung,
  isAbrechnungTypeWeTopAbrechnung,
} from '../../shared/abrechnungHelpers';
import { pathsToBkAbrechnungDetails } from '../../BkOrWe/bkAbrechnungUriPaths';
import { pathsToHeAbrechnungDetails } from '../../He/heAbrechnungUriPaths';
import { pathsToWeAbrechnungDetails } from '../../BkOrWe/weAbrechnungUriPaths';
import { generatePathToVerarbeitungWeObjAbrDetailsPage } from '../../../Verarbeitung/verarbeitungApiPaths';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import { generatePathToObjektDetailsPage } from '../../../Objekt/objektUriPaths';
import AuftragCreationStatusTag from '../../shared/AuftragCreationStatusTag';
import TruncatedTextWithTooltip from '../../../../components/Helpers/TruncatedTextWithTooltip';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import { pathsToSubAbrechnungDetails } from '../../Sub/subAbrechnungUriPaths';

const abrechnungTableColumns: TableWithColSelectorColumnProps<AbrechnungListEntry>[] = [
  {
    title: 'Abrechnung',
    defaultSelected: true,
    dataIndex: AbrechnungOrderBy.Bezeichnung,
    sorter: true,
    render: (text, record) => <TruncatedTextWithTooltip text={record.bezeichnung} />,
  },
  {
    title: 'Abrechnungstyp',
    dataIndex: AbrechnungOrderBy.Type,
    defaultSelected: true,
    sorter: true,
    render: (text, record) => <Typography.Text>{record.type.text}</Typography.Text>,
  },
  {
    title: 'Objekt',
    defaultSelected: true,
    dataIndex: AbrechnungOrderBy.ObjektKurzBezeichnung,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText text={record.objektKurzBezeichnung} maxTextLength={35}>
        {(shortenedText) => <Link to={generatePathToObjektDetailsPage(record.objektId)}>{shortenedText}</Link>}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Zeitraum',
    defaultSelected: true,
    dataIndex: AbrechnungOrderBy.Zeitraum,
    sorter: true,
    render: (text, record) => (
      <Space>
        <CustomFormattedDate value={record.fromInclusive} /> / <CustomFormattedDate value={record.toInclusive} />
      </Space>
    ),
  },
  {
    title: 'Vorschreibungen',
    defaultSelected: true,
    render: (text, record) => (
      <AuftragCreationStatusTag
        auftragCreationStatus={record.auftragCreationStatus}
        countAuftragCreated={record.countAuftragCreated}
        countAuftragToCreateTotal={record.countAuftragToCreateTotal}
      />
    ),
  },
  {
    title: 'Verarbeitung',
    defaultSelected: true,
    render: (text, record) => <Link to={generatePathToVerarbeitungWeObjAbrDetailsPage(record.generierlaufId)}>Verarbeitung</Link>,
  },
  {
    title: 'Status',
    defaultSelected: true,
    dataIndex: AbrechnungOrderBy.Status,
    sorter: true,
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },
  {
    title: 'Erstellt am',
    dataIndex: AbrechnungOrderBy.CreateTs,
    sorter: true,
    render: (text, record) => (
      <DataWithStatus status={record.status} text="" showBadgeDot={false} showStatusDescription={false}>
        {() => <FormattedDateTime createdTs={record.createTs} />}
      </DataWithStatus>
    ),
  },
  {
    title: 'Ersteller',
    dataIndex: AbrechnungOrderBy.CreatedBy,
    sorter: true,
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    key: 'action',
    render: (text, record) => {
      const path = (abrechnungType: AbrechnungType) => {
        if (isAbrechnungTypeHeAbrechnung(abrechnungType)) {
          return pathsToHeAbrechnungDetails(record.objektId, record.abrechnungId).abrechnungDetails;
        }
        if (isAbrechnungTypeBkAbrechnung(abrechnungType)) {
          return pathsToBkAbrechnungDetails(record.objektId, record.abrechnungId).abrechnungDetails;
        }
        if (isAbrechnungTypeBkTopAbrechnung(abrechnungType)) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return pathsToBkAbrechnungDetails(record.objektId, record.objektAbrechnungId!).topAbrTab;
        }
        if (isAbrechnungTypeWeTopAbrechnung(abrechnungType)) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return pathsToWeAbrechnungDetails(record.objektId, record.objektAbrechnungId!).topAbrTab;
        }
        if (isAbrechnungTypeSubAbrechnung(abrechnungType)) {
          return pathsToSubAbrechnungDetails(record.objektId, record.abrechnungId).subAbrTab;
        }

        return pathsToWeAbrechnungDetails(record.objektId, record.abrechnungId).abrechnungDetails;
      };

      return (
        <Link to={path(record.type.value)} target="_blank">
          Details
        </Link>
      );
    },
  },
];

export default abrechnungTableColumns;
