import React, { FC } from 'react';
import { Empty } from 'antd';
import vstKuerzungTableColumns from './VstKuerzungTableColumns';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { VorsteuerkuerzungListEntry } from '../../../../types';

type Props = {
  vstKuerzungList: VorsteuerkuerzungListEntry[];
  objektId: string;
  loading: boolean;
};

const VstKuerzungTable: FC<Props> = ({ vstKuerzungList, objektId, loading }) => {
  return (
    <TableWithColSelector<VorsteuerkuerzungListEntry>
      size="small"
      locale={{
        emptyText: <Empty description={<span>Keine Vorsteuerkürzungen vorhanden</span>} />,
      }}
      loading={loading}
      dataSource={vstKuerzungList}
      columns={vstKuerzungTableColumns(objektId)}
      pagination={{ showSizeChanger: true, defaultPageSize: 20 }}
      rowKey={(record) => record.vorsteuerkuerzungId}
      filterIdentifier="objekt-vorsteuerkuerzug"
    />
  );
};

export default VstKuerzungTable;
