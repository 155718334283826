import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import UstVomAufwandDetailsPage from './UstVomAufwandDetailsPage';
import { URI_UST_VOM_AUFWAND_DETAILS_PAGE } from '../../features/UstVomAufwand/ustVomAufwandUriPaths';

export type UstVomAufwandDetailsPageRouteParams = {
  objektId: string;
  ustVomAufwandId: string;
};

const ustVomAufwandDetailsPageRoute = (
  <Route
    path={`${URI_UST_VOM_AUFWAND_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<UstVomAufwandDetailsPage />} />}
  />
);

export default ustVomAufwandDetailsPageRoute;
