import React, { FC, PropsWithChildren } from 'react';
import { Space, Typography } from 'antd';
import { pathsToTicketDetailsPage } from '../../ticketUriPaths';
import MitarbeiterTooltip from '../../../../components/Card/MitarbeiterTooltip';
import TicketPriorityIcon from '../TicketPriorityIcon';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { TicketPreviewProps } from './TicketPreview';
import TicketPreviewLink from './TicketPreviewLink';
import { TicketBase } from '../../../../types';

const TicketPreviewTitle: FC<TicketPreviewProps> = ({ ticket, openLinkInNewTab, showAssignee }) => {
  const ticketUri = pathsToTicketDetailsPage(ticket).overviewTab;
  const ticketParentUri = ticket.parent ? pathsToTicketDetailsPage(ticket.parent).overviewTab : '';

  return !ticket.parent ? (
    <TicketPreviewLink to={ticketUri} openLinkInNewTab={openLinkInNewTab}>
      <TitleWithExtra ticket={ticket} showAssignee={showAssignee}>
        {ticket.title}
      </TitleWithExtra>
    </TicketPreviewLink>
  ) : (
    <Space size={2} align="start">
      <TitleWithExtra ticket={ticket} showAssignee={showAssignee}>
        <DataWithShortenedText maxTextLength={35} text={ticket.parent.title} useInTooltip>
          {(shortenedText) => (
            <TicketPreviewLink to={ticketParentUri} color="rgba(0,0,0,0.45)" openLinkInNewTab={openLinkInNewTab}>
              {shortenedText}
            </TicketPreviewLink>
          )}
        </DataWithShortenedText>
        <Typography.Text type="secondary"> / </Typography.Text>
        <TicketPreviewLink to={ticketUri} openLinkInNewTab={openLinkInNewTab}>
          {ticket.title}
        </TicketPreviewLink>
      </TitleWithExtra>
    </Space>
  );
};

const TitleWithExtra: FC<PropsWithChildren<{ ticket: TicketBase; showAssignee?: boolean }>> = ({ ticket, showAssignee, children }) => (
  <Space size={2} align="start">
    {showAssignee && (
      <div style={{ marginLeft: -7, marginRight: 7 }}>
        <MitarbeiterTooltip mitarbeiterId={ticket.assignee.mitarbeiterId} showsInitials />
      </div>
    )}
    <TicketPriorityIcon priority={ticket.priority} style={{ marginLeft: -4, marginRight: 4 }} />
    <div>{children}</div>
  </Space>
);

export default TicketPreviewTitle;
