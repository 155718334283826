import React, { FC } from 'react';
import { Formik } from 'formik';
import { showSuccessMsgUpdate } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { showSuccessMsgCreateWithPath } from '../../../components/message/message';
import { useCreateKontoTemplateMutation, useUpdateKontoTemplateMutation } from '../gql/KontenrahmenMutations.types';
import { Konto } from '../../../types';
import { KontoFormValues, mapFormValuesKonto, mapKontoToFormValues } from '../../Kontenplan/Form/kontoFormMapper';
import { kontoFormValidationSchema } from '../../Kontenplan/Form/kontoFormValidationSchema';
import { generatePathToKontoTemplateDetailsPage } from '../kontoTemplateUriPaths';
import KontoFormContent from '../../Kontenplan/shared/KontoFormContent';

type Props = {
  konto?: Konto;
  onSuccess: () => void;
  onCancel: () => void;
};

const KontoTemplateForm: FC<Props> = ({ konto, onSuccess, onCancel }) => {
  const isUpdate = !!konto;
  const entity = 'Konto';
  const isEditable = (konto && konto.deletable) || !konto;

  const [createKonto, { loading: loadingForm }] = useCreateKontoTemplateMutation({
    onCompleted: (data) => {
      const { kontoId, bezeichnung } = data.createKontoTemplate.data;
      showSuccessMsgCreateWithPath(entity, bezeichnung, generatePathToKontoTemplateDetailsPage(kontoId));
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [updateKonto, { loading: loadingUpdate }] = useUpdateKontoTemplateMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<KontoFormValues>
      initialValues={mapKontoToFormValues(konto)}
      validationSchema={kontoFormValidationSchema}
      onSubmit={(formValues, formikHelpers) => {
        const input = mapFormValuesKonto(formValues);
        if (!konto) {
          createKonto({ variables: { input } }).finally(() => formikHelpers.setSubmitting(false));
        } else {
          updateKonto({
            variables: { kontoId: konto.kontoId, input },
          }).finally(() => formikHelpers.setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <KontoFormContent
          formikProps={formikProps}
          isEditable={isEditable}
          onCancel={onCancel}
          isUpdate={isUpdate}
          isLoading={loadingForm || loadingUpdate}
        />
      )}
    </Formik>
  );
};

export default KontoTemplateForm;
