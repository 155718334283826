import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateVorschreibungspositionVersionMutationVariables = Types.Exact<{
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  input: Types.VorschreibungspositionVersionCreateInput;
}>;

export type CreateVorschreibungspositionVersionMutation = {
  createVorschreibungspositionVersion: {
    data: { vorschreibungspositionVersionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateVorschreibungspositionVersionMutationVariables = Types.Exact<{
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  vorschreibungspositionVersionId: Types.Scalars['ID']['input'];
  input: Types.VorschreibungspositionVersionUpdateInput;
}>;

export type UpdateVorschreibungspositionVersionMutation = {
  updateVorschreibungspositionVersion: {
    data: { vorschreibungspositionVersionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteVorschreibungspositionVersionMutationVariables = Types.Exact<{
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  vorschreibungspositionVersionId: Types.Scalars['ID']['input'];
}>;

export type DeleteVorschreibungspositionVersionMutation = {
  deleteVorschreibungspositionVersion: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateVorschreibungspositionVersionDocument = gql`
  mutation CreateVorschreibungspositionVersion($vorschreibungspositionId: ID!, $input: VorschreibungspositionVersionCreateInput!) {
    createVorschreibungspositionVersion(vorschreibungspositionId: $vorschreibungspositionId, input: $input) {
      data {
        vorschreibungspositionVersionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateVorschreibungspositionVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateVorschreibungspositionVersionMutation, CreateVorschreibungspositionVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVorschreibungspositionVersionMutation, CreateVorschreibungspositionVersionMutationVariables>(
    CreateVorschreibungspositionVersionDocument,
    options
  );
}
export type CreateVorschreibungspositionVersionMutationHookResult = ReturnType<typeof useCreateVorschreibungspositionVersionMutation>;
export type CreateVorschreibungspositionVersionMutationResult = Apollo.MutationResult<CreateVorschreibungspositionVersionMutation>;
export type CreateVorschreibungspositionVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateVorschreibungspositionVersionMutation,
  CreateVorschreibungspositionVersionMutationVariables
>;
export const UpdateVorschreibungspositionVersionDocument = gql`
  mutation UpdateVorschreibungspositionVersion(
    $vorschreibungspositionId: ID!
    $vorschreibungspositionVersionId: ID!
    $input: VorschreibungspositionVersionUpdateInput!
  ) {
    updateVorschreibungspositionVersion(
      vorschreibungspositionId: $vorschreibungspositionId
      vorschreibungspositionVersionId: $vorschreibungspositionVersionId
      input: $input
    ) {
      data {
        vorschreibungspositionVersionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateVorschreibungspositionVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateVorschreibungspositionVersionMutation, UpdateVorschreibungspositionVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateVorschreibungspositionVersionMutation, UpdateVorschreibungspositionVersionMutationVariables>(
    UpdateVorschreibungspositionVersionDocument,
    options
  );
}
export type UpdateVorschreibungspositionVersionMutationHookResult = ReturnType<typeof useUpdateVorschreibungspositionVersionMutation>;
export type UpdateVorschreibungspositionVersionMutationResult = Apollo.MutationResult<UpdateVorschreibungspositionVersionMutation>;
export type UpdateVorschreibungspositionVersionMutationOptions = Apollo.BaseMutationOptions<
  UpdateVorschreibungspositionVersionMutation,
  UpdateVorschreibungspositionVersionMutationVariables
>;
export const DeleteVorschreibungspositionVersionDocument = gql`
  mutation DeleteVorschreibungspositionVersion($vorschreibungspositionId: ID!, $vorschreibungspositionVersionId: ID!) {
    deleteVorschreibungspositionVersion(
      vorschreibungspositionId: $vorschreibungspositionId
      vorschreibungspositionVersionId: $vorschreibungspositionVersionId
    ) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteVorschreibungspositionVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteVorschreibungspositionVersionMutation, DeleteVorschreibungspositionVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVorschreibungspositionVersionMutation, DeleteVorschreibungspositionVersionMutationVariables>(
    DeleteVorschreibungspositionVersionDocument,
    options
  );
}
export type DeleteVorschreibungspositionVersionMutationHookResult = ReturnType<typeof useDeleteVorschreibungspositionVersionMutation>;
export type DeleteVorschreibungspositionVersionMutationResult = Apollo.MutationResult<DeleteVorschreibungspositionVersionMutation>;
export type DeleteVorschreibungspositionVersionMutationOptions = Apollo.BaseMutationOptions<
  DeleteVorschreibungspositionVersionMutation,
  DeleteVorschreibungspositionVersionMutationVariables
>;
