import React from 'react';
import { Space } from 'antd';
import { FormikProps } from 'formik';
import InfoFeldFileUpload from './InfoFeldFileUpload';
import { UploadedFileView } from '../UploadedFileView';
import { InfoFeldFormFileReference } from './infoFeldFormMapper';

type InfoFeldUploadProps<T> = {
  formikProps: FormikProps<T>;
  documentList: InfoFeldFormFileReference[];
  name: string;
};

function InfoFeldUpload<T>({ name, documentList, formikProps }: InfoFeldUploadProps<T>) {
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <InfoFeldFileUpload name={name} formikProps={formikProps} documentList={documentList} />
      <Space direction="vertical">
        {documentList?.map((document: InfoFeldFormFileReference, index: number) => {
          const onDelete = () =>
            formikProps.setFieldValue(
              name,
              documentList?.filter((doc: InfoFeldFormFileReference) => document.filename !== doc.filename)
            );
          return <UploadedFileView key={index} filename={document.filename} onDelete={onDelete} isNewFile />;
        })}
      </Space>
    </Space>
  );
}

export default InfoFeldUpload;
