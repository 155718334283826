import React, { FC, useState } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import useDebounce from '../../../../hooks/useDebounce';
import SelectNotFoundContent from '../../../shared/SelectNotFoundContent';
import { useIncomingInvoiceSepaMandateReferenceListQuery } from '../../gql/IncomingInvoiceFilterQueries.types';
import { isTextEqualOrLongerThanThreeCharacters } from '../../../../helpers/stringHelper';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = {
  sepaMandateReference?: string | null;
} & Omit<SelectProps, 'id' | 'placeholder' | 'size' | 'allowClear' | 'showSearch' | 'filterOption'>;

const SepaMandateReferenceListFormSelect: FC<Props> = ({ sepaMandateReference, ...restProps }) => {
  const [text, setText] = useState<string>(sepaMandateReference ? sepaMandateReference.toString() : '');
  const debouncedText = useDebounce(text);

  const { loading, data } = useIncomingInvoiceSepaMandateReferenceListQuery({
    variables: {
      sepaMandateReference: debouncedText as string,
    },
    skip: debouncedText ? !isTextEqualOrLongerThanThreeCharacters(debouncedText as string) : true,
  });

  const paymentReferenceList = data?.getIncomingInvoiceSepaMandateReferenceList.data ?? [];

  return (
    <>
      <Select
        {...restProps}
        id={restProps.name}
        loading={loading}
        size="small"
        allowClear
        showSearch
        placeholder="SEPA-Mandatsreferenz auswählen"
        filterOption={selectFilterOption}
        onSelect={setText}
        onSearch={(value) => {
          text.length === 1 && !value && setText('');
          value && setText(value);
        }}
        onClear={() => setText('')}
        searchValue={text}
        notFoundContent={<SelectNotFoundContent loading={loading} text={debouncedText as string} />}
      >
        {paymentReferenceList?.map((paymentReference) => (
          <Select.Option key={paymentReference} value={paymentReference}>
            <DataWithShortenedText text={paymentReference} maxTextLength={40} />
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default SepaMandateReferenceListFormSelect;
