import React, { FC, useEffect, useState } from 'react';
import { Space, Typography } from 'antd';
import FormattedDecimalFormInput from '../../../../../components/Input-antd/FormattedDecimalFormInput';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import useDebounce from '../../../../../hooks/useDebounce';
import { EuroAmount } from '../../../../../components/Number';
import { useCalculateHeVertragZahlungsregelSumVposNettoMutation } from '../gql/HeVertragZahlungsregelMutations.types';
import { HeVertragZahlungsregelSumVposNetto } from '../../../../../types';

type Props = {
  name: string;
  objektId: string;
  prozentsatz?: number | null;
};

const ZahlungsregelProzentsatzInput: FC<Props> = ({ name, objektId, prozentsatz }) => {
  const [sumVposNetto, setSumVposNetto] = useState<HeVertragZahlungsregelSumVposNetto>();
  const debouncedText = useDebounce(prozentsatz ?? 0);

  const [calculateAndUpdateSumVposNetto] = useCalculateHeVertragZahlungsregelSumVposNettoMutation({
    onCompleted: (value) => {
      setSumVposNetto(value.calculateHeVertragZahlungsregelSumVposNetto.data);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  useEffect(() => {
    if (prozentsatz) {
      calculateAndUpdateSumVposNetto({ variables: { objektId, input: { percentage: prozentsatz } } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedText]);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <FormattedDecimalFormInput id={name} name={name} placeholder="z.B. 10,00" size="small" min={0} style={{ width: '100%' }} isPercentage />
      {sumVposNetto ? (
        <Typography.Text>
          Bei einer Gesamt-Vorschreibung: <EuroAmount value={sumVposNetto.vorschreibungSumme} /> ergeben die {prozentsatz}% derzeit einen Betrag von{' '}
          <EuroAmount value={sumVposNetto.betrag} />
        </Typography.Text>
      ) : (
        <Typography.Text>Bei einer Gesamt-Vorschreibung von ... ergeben die ... derzeit einen Betrag von ...</Typography.Text>
      )}
    </Space>
  );
};

export default ZahlungsregelProzentsatzInput;
