import { FC } from 'react';
import { useRechtstraegerBankDetailsChangeHistoryListQuery } from '../gql/BankDetailsQueries.types';
import { HistoryType } from '../../History/shared/HistoryModal';
import HistoryListingTable from '../../History/HistoryListingTable';

type Props = {
  rechtstraegerId: string;
  bankDetailsId: string;
};

const RechtstraegerBankDetailsChangeHistoryListingTable: FC<Props> = ({ rechtstraegerId, bankDetailsId }) => {
  const { data, loading } = useRechtstraegerBankDetailsChangeHistoryListQuery({ variables: { rechtstraegerId, bankDetailsId } });
  const historyList = data?.getRechtstraegerBankDetailsChangeHistoryList.data;

  return (
    <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="rechtstrager-bank-details" />
  );
};

export default RechtstraegerBankDetailsChangeHistoryListingTable;
