import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { useFirmendatenFileInfoHomeownerListQuery } from '../../gql/FirmendatenFileInfoFilterQueries.types';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = SelectProps;

const DocumentHomeownerListFormSelect: FC<Props> = ({ ...restProps }) => {
  const { loading, data } = useFirmendatenFileInfoHomeownerListQuery();
  const list = data?.getFirmendatenFileInfoHomeownerList.data ?? [];

  return (
    <Select
      {...restProps}
      size="small"
      id={restProps.name}
      loading={loading}
      placeholder="Hauseigentümer auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {list.map((homeowner) => (
        <Select.Option key={homeowner.rechtstraegerId} value={homeowner.rechtstraegerId}>
          <DataWithShortenedText maxTextLength={35} text={homeowner.kurzBezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default DocumentHomeownerListFormSelect;
