import React from 'react';
import { Space } from 'antd';
import { Budgeting, BudgetingKonto } from '../../../../../types';
import { EuroAmount } from '../../../../../components/Number';
import DistributionKeyList from '../../../shared/DistributionKeyList';
import { generatePathToKontoblattPage } from '../../../../Kontoblatt/kontoblattUriPaths';
import TableColumnHeadingWithPeriod from '../../../shared/TableColumnHeadingWithPeriod';
import BudgetingKontoAnnualBudgetInput from './BudgetingKontoAnnualBudgetInput';
import BudgetingKontoIncreaseInput from './BudgetingKontoIncreaseInput';
import BudgetingKontoEstimateRestOfYear from './BudgetingKontoEstimateRestOfYear';
import BudgetingKontoResetKonto from './BudgetingKontoResetKonto';
import { mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import { isStatusArchived, IStatus } from '../../../../../helpers/statusHelper';
import DataWithStatus from '../../../../../components/Helpers/DataWithStatus';
import LinkWithStatus from '../../../../../components/Link/LinkWithStatus';
import { NestedTableWithColSelectorColProps } from '../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';

const budgetingKontoTableColumns = (
  budgeting: Budgeting,
  isLoading: boolean,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  refetch: () => Promise<unknown>,
  isUpdating: boolean,
  updatingRowKey: string | null,
  setUpdatingRowKey: React.Dispatch<React.SetStateAction<string | null>>,
  budgetingVPosStatus: IStatus
): NestedTableWithColSelectorColProps<BudgetingKonto>[] => [
  {
    title: 'Ausgaben',
    width: 200,
    defaultSelected: true,
    key: 'vpos',
    render: (text, record) => (
      <LinkWithStatus
        status={budgetingVPosStatus}
        text={record.konto.bezeichnung}
        to={generatePathToKontoblattPage({
          kontoId: record.konto.kontoId,
          objektId: budgeting.objekt.objektId,
        })}
        openInNewTab
        maxTextLength={30}
        showBadgeDot={false}
      />
    ),
  },
  {
    title: <TableColumnHeadingWithPeriod text="Gebucht Vorjahr" date={budgeting.prevYear.toString()} />,
    colSelectorTitle: `Gebucht Vorjahr`,
    defaultSelected: true,
    key: 'prev-year',
    width: 170,
    align: 'right',
    render: (text, record) => {
      const date = dayjsCustom(budgeting.prevYear.toString());
      const fromDate = mapFormDateValueToDateString(date.startOf('year'));
      const toDate = mapFormDateValueToDateString(date.endOf('year'));

      return (
        <LinkWithStatus
          status={budgetingVPosStatus}
          text=""
          to={generatePathToKontoblattPage({
            kontoId: record.konto.kontoId,
            objektId: budgeting.objekt.objektId,
            buchungsdatumFrom: fromDate,
            buchungsdatumTo: toDate,
          })}
          openInNewTab
          showBadgeDot={false}
        >
          <EuroAmount value={record.gebuchtPrevYear} />
        </LinkWithStatus>
      );
    },
  },
  {
    title: <TableColumnHeadingWithPeriod text="Gebucht aktuelles Jahr" date={budgeting.considerationPeriodUntil} endOf="month" />,
    colSelectorTitle: `Gebucht aktuelles Jahr`,
    defaultSelected: true,
    key: 'current-year',
    width: 170,
    align: 'right',
    render: (text, record) => {
      const date = dayjsCustom(budgeting.considerationPeriodUntil);
      const fromDate = mapFormDateValueToDateString(date.startOf('year'));
      const toDate = mapFormDateValueToDateString(date.endOf('month'));

      return (
        <LinkWithStatus
          status={budgetingVPosStatus}
          text=""
          to={generatePathToKontoblattPage({
            kontoId: record.konto.kontoId,
            objektId: budgeting.objekt.objektId,
            buchungsdatumFrom: fromDate,
            buchungsdatumTo: toDate,
          })}
          openInNewTab
          showBadgeDot={false}
        >
          <EuroAmount value={record.gebuchtCurrentYear} />
        </LinkWithStatus>
      );
    },
  },
  {
    title: 'Aufteilungsschlüssel',
    width: 200,
    align: 'right',
    render: (text, record) => (
      <DistributionKeyList disabled={isStatusArchived(budgetingVPosStatus)} aufteilungsschluesselList={record.budgetingAufteilungsschluesselList} />
    ),
  },
  {
    title: 'Hochrechnung Restjahr',
    align: 'right',
    render: (text, record) => (
      <Space direction="horizontal" size={0}>
        <BudgetingKontoResetKonto
          budgeting={budgeting}
          budgetingKonto={record}
          refetch={refetch}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          isUpdating={isUpdating}
          updatingRowKey={updatingRowKey}
          setUpdatingRowKey={setUpdatingRowKey}
          disabled={isStatusArchived(budgetingVPosStatus)}
        />
        <BudgetingKontoEstimateRestOfYear
          budgeting={budgeting}
          budgetingKonto={record}
          refetch={refetch}
          isUpdating={isUpdating}
          updatingRowKey={updatingRowKey}
          setUpdatingRowKey={setUpdatingRowKey}
          disabled={isStatusArchived(budgetingVPosStatus)}
        />
      </Space>
    ),
  },
  {
    title: 'Basisbetrag',
    align: 'right',
    render: (text, record) => (
      <DataWithStatus status={budgetingVPosStatus} text="" showBadgeDot={false} showStatusDescription={false}>
        {() => <EuroAmount value={record.estimateTotalCurrentYear} />}
      </DataWithStatus>
    ),
  },
  {
    title: 'Erhöhung %',
    key: 'erhoehung',
    align: 'right',
    render: (text, record) => (
      <BudgetingKontoIncreaseInput
        budgeting={budgeting}
        budgetingKonto={record}
        refetch={refetch}
        isUpdating={isUpdating}
        updatingRowKey={updatingRowKey}
        setUpdatingRowKey={setUpdatingRowKey}
        disabled={isStatusArchived(budgetingVPosStatus)}
      />
    ),
  },
  {
    title: 'Jahresbudget Summe',
    key: 'jahresbudget-summe',
    align: 'right',
    render: (text, record) => (
      <BudgetingKontoAnnualBudgetInput
        budgeting={budgeting}
        budgetingKonto={record}
        refetch={refetch}
        isUpdating={isUpdating}
        updatingRowKey={updatingRowKey}
        setUpdatingRowKey={setUpdatingRowKey}
        disabled={isStatusArchived(budgetingVPosStatus)}
      />
    ),
  },
  {
    title: '',
    width: 100,
    key: 'action',
  },
];

export default budgetingKontoTableColumns;
