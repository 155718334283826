import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { Tooltip } from 'antd';
import { useVerrechnungUstVomAufwandListQuery } from '../gql/VerrechnungUstVomAufwandQueries.types';

const VerrechnungsUstVomAufwandSelect: FC<SelectProps> = (props) => {
  const { data, loading } = useVerrechnungUstVomAufwandListQuery();
  const verrechnungUstVomAufwandList = data?.getVerrechnungUstVomAufwandList.data ?? [];

  return (
    <Select loading={loading} placeholder="Verrechnung Umsatzsteuer vom Aufwand auswählen" {...props}>
      {verrechnungUstVomAufwandList.map((verrechnungUstVomAufwand) => (
        <Select.Option key={verrechnungUstVomAufwand.value} value={verrechnungUstVomAufwand.value}>
          <Tooltip title={verrechnungUstVomAufwand.description}>{verrechnungUstVomAufwand.text}</Tooltip>
        </Select.Option>
      ))}
    </Select>
  );
};

export default VerrechnungsUstVomAufwandSelect;
