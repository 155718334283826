import React, { FC } from 'react';
import { Text } from '@react-pdf/renderer';
import { SelectableSelection } from './types';
import pdfStyles, { selectableStyles } from '../styles/pdfStyles';

type Props = {
  text?: string | null;
  isInEditMode?: boolean;
  selectedTextbaustein?: SelectableSelection;
};
const Schlusstext: FC<Props> = ({ text, isInEditMode, selectedTextbaustein }) => {
  // if in edit mode then we need to render the platzhalter also if it is an empty string so that the user see where the textbaustein will occur in the PDF
  if (isInEditMode) {
    return (
      <Text style={[pdfStyles.textNormal, { marginTop: '10mm' }, selectableStyles(isInEditMode, selectedTextbaustein === 'SCHLUSSTEXT')]}>
        {text}
      </Text>
    );
  }

  return text === '' ? null : <Text style={[pdfStyles.textNormal, { marginTop: '10mm' }]}>{text}</Text>;
};
export default Schlusstext;
