import React from 'react';

import { Link } from 'react-router-dom';
import { Space, Typography } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { friendlyFormatIBAN } from 'ibantools';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { EuroAmount } from '../../../../../components/Number';
import { MitarbeiterTooltip } from '../../../../../components/Card';
import { generatePathToRechtstraegerDetailsPage } from '../../../../Rechtstraeger/rechtstraegerHelper';
import ZahlungsverlaufActions from './ZahlungsverlaufActions';
import EntityWarningList from '../../../../../components/message/EntityWarningList';
import { compareTwoDatesForSorting } from '../../../../../helpers/dateHelper';
import { compareTwoStringsForSorting } from '../../../../../helpers/stringHelper';
import { compareTwoNumbersForSorting } from '../../../../../helpers/numberHelper';
import { isNotNil } from '../../../../../helpers/assertionHelper';
import ZahlungsverlaufStatusTag from './ZahlungsverlaufStatusTag';
import { HeVertragZahlungsverlaufListEntry } from '../../../../../types';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';

const zahlungsverlaufTableColumns = (
  objektId: string,
  heVertragId: string,
  onSuccess: () => void,
  rechnungsAusstellerId: string
): TableWithColSelectorColumnProps<HeVertragZahlungsverlaufListEntry>[] => {
  return [
    {
      title: 'Zahlungsdatum',
      defaultSelected: true,
      sorter: (a, b) => compareTwoDatesForSorting(a.zahlungsdatum, b.zahlungsdatum),
      render: (text, record) => <CustomFormattedDate value={record.zahlungsdatum} />,
    },
    {
      title: 'Durchführungsdatum',
      sorter: (a, b) => compareTwoDatesForSorting(a.durchfuehrungsdatum, b.durchfuehrungsdatum),
      render: (text, record) =>
        record.durchfuehrungsdatum ? <CustomFormattedDate value={record.durchfuehrungsdatum} /> : <TextForEmptyValue textToShow="minus" />,
    },
    {
      title: 'Erstellt aus',
      defaultSelected: true,
      sorter: (a, b) => compareTwoStringsForSorting(a.heVertragZahlungsregel?.bezeichnung, b.heVertragZahlungsregel?.bezeichnung),
      render: (text, record) =>
        record.heVertragZahlungsregel ? (
          <DataWithShortenedText maxTextLength={25} text={record.heVertragZahlungsregel.bezeichnung} />
        ) : (
          <Typography.Text>Einmalzahlung</Typography.Text>
        ),
    },
    {
      title: 'Betrag',
      defaultSelected: true,
      align: 'right',
      sorter: (a, b) => compareTwoNumbersForSorting(a.betrag, b.betrag),
      render: (text, record) => (isNotNil(record.betrag) ? <EuroAmount value={record.betrag} /> : <TextForEmptyValue textToShow="NV" />),
    },
    {
      title: 'Verwendungszweck',
      defaultSelected: true,
      sorter: (a, b) => compareTwoStringsForSorting(a.verwendungszweck, b.verwendungszweck),
      render: (text, record) =>
        record.verwendungszweck ? (
          <DataWithShortenedText maxTextLength={25} text={record.verwendungszweck} />
        ) : (
          <TextForEmptyValue textToShow="minus" />
        ),
    },
    {
      title: 'Zahlungsreferenz',
      sorter: (a, b) => compareTwoStringsForSorting(a.zahlungsreferenz, b.zahlungsreferenz),
      render: (text, record) =>
        record.zahlungsreferenz ? (
          <DataWithShortenedText maxTextLength={25} text={record.zahlungsreferenz} />
        ) : (
          <TextForEmptyValue textToShow="minus" />
        ),
    },
    {
      title: 'Empfänger',
      defaultSelected: true,
      sorter: (a, b) => compareTwoStringsForSorting(a.zahlungsempfaenger.kurzBezeichnung, b.zahlungsempfaenger.kurzBezeichnung),
      render: (text, record) => (
        <DataWithShortenedText maxTextLength={25} text={record.zahlungsempfaenger.kurzBezeichnung}>
          {(shortenedText) => (
            <Link target="_blank" to={generatePathToRechtstraegerDetailsPage(record.zahlungsempfaenger.rechtstraegerId)}>
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ),
    },
    {
      title: 'Bankverbindung',
      defaultSelected: true,
      sorter: (a, b) => compareTwoStringsForSorting(a.zahlungsempfaengerBankDetails, b.zahlungsempfaengerBankDetails),
      render: (text, record) => {
        return (
          <DataWithShortenedText maxTextLength={20} text={`${record.accountHolder} - ${friendlyFormatIBAN(record.zahlungsempfaengerBankDetails)}`} />
        );
      },
    },
    {
      title: 'Erstellt am',
      render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
    },
    {
      title: 'Ersteller',
      render: (text, record) => (
        <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
      ),
    },
    {
      title: 'Status',
      defaultSelected: true,
      sorter: (a, b) => compareTwoStringsForSorting(a.status.value, b.status.value),
      render: (text, record) => {
        return (
          <Space size={2}>
            <ZahlungsverlaufStatusTag status={record.status} />
            <EntityWarningList warningList={record.warningList} icon={<InfoCircleTwoTone style={{ cursor: 'help' }} />} />
          </Space>
        );
      },
    },
    {
      title: 'Aktion',
      defaultSelected: true,
      render: (text, record) => (
        <ZahlungsverlaufActions
          onSuccess={onSuccess}
          objektId={objektId}
          heVertragId={heVertragId}
          zahlungsverlauf={record}
          rechnungsAusstellerId={rechnungsAusstellerId}
        />
      ),
    },
  ];
};

export default zahlungsverlaufTableColumns;
