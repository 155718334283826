import { FC } from 'react';
import HistoryListingTable from '../History/HistoryListingTable';
import { HistoryType } from '../History/shared/HistoryModal';
import { useIndexValueCreationCronJobSettingsChangeHistoryListQuery } from './gql/IndexValueCreationCronJobSettingsQueries.types';

const IndexValueCreationCronJobSettingsChangeHistoryListingTable: FC = () => {
  const { data, loading } = useIndexValueCreationCronJobSettingsChangeHistoryListQuery();
  const historyList = data?.getIndexValueCreationCronJobSettingsChangeHistoryList.data;

  return (
    <HistoryListingTable
      historyType={HistoryType.Change}
      historyList={historyList}
      loading={loading}
      filterIdPrefix="index-value-creation-cron-job-settings"
    />
  );
};

export default IndexValueCreationCronJobSettingsChangeHistoryListingTable;
