import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { VERARBEITUNG_LIST_FOR_SELECT } from './listingFiltersFormMapper';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { getVerarbeitungTypeTitles } from '../../verarbeitungHelpers';

type Props = SelectProps;

const VerarbeitungTypeListFormSelect: FC<Props> = ({ name, ...restProps }) => {
  return (
    <Select
      name={name}
      allowClear
      showSearch
      filterOption={selectFilterOption}
      mode="multiple"
      size="middle"
      placeholder=""
      style={{ minWidth: '220px' }}
      {...restProps}
    >
      {VERARBEITUNG_LIST_FOR_SELECT.map((verarbeitung, index) => {
        return (
          <Select.Option key={index} value={verarbeitung}>
            {getVerarbeitungTypeTitles(verarbeitung)}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default VerarbeitungTypeListFormSelect;
