import * as Yup from 'yup';
import { ustRegelsetFormFields } from './ustRegelsetTemplateFormMapper';
import { entityIsRequired } from '../../../../components/message/validationMsg';

export const ustRegelsetTemplateFormValidationSchema = (isUpdate: boolean) =>
  Yup.object().shape({
    [ustRegelsetFormFields.bezeichnung]: Yup.string().required(entityIsRequired('Bezeichnung')),
    [ustRegelsetFormFields.countryCodeIso2]: Yup.string().required(entityIsRequired('Land')),
    [ustRegelsetFormFields.nutzungsArt]: Yup.string().required(entityIsRequired('Nutzungsart')),
    [ustRegelsetFormFields.steuerpflicht]: Yup.string().required(entityIsRequired('Steuerpflicht')),
    ...(!isUpdate && {
      [ustRegelsetFormFields.validFrom]: Yup.string().required(entityIsRequired('Gültig ab')),
      [ustRegelsetFormFields.ustKategorieId]: Yup.string().required(entityIsRequired('USt.-Kategorie')),
    }),
  });
