import { FC } from 'react';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';
import { useMahnungTemplateTemplateChangeHistoryListQuery } from '../gql/MahndefinitionTemplateQueries.types';

type Props = {
  mahndefinitionId: string;
  mahnstufeIndex: number;
};

const MahnungTemplateTemplateHistoryListingTable: FC<Props> = ({ mahndefinitionId, mahnstufeIndex }) => {
  const { data, loading } = useMahnungTemplateTemplateChangeHistoryListQuery({ variables: { mahndefinitionId, mahnstufeIndex } });
  const historyList = data?.getMahnungTemplateTemplateChangeHistoryList.data;

  return (
    <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="mahnung-template-template" />
  );
};

export default MahnungTemplateTemplateHistoryListingTable;
