import React, { FC, useState } from 'react';
import { Pagination, Tabs, TabsProps } from 'antd';
import { Flex } from 'rebass';
import { mapZinslisteVPosListToTable } from './mapZinslisteVPosListToTable';
import VPosFilterAndExportBtn from './VPosFilterAndExportBtn';
import VPosTable from './VPosTable';
import TabPaneContent from '../../../../components/Tabs/TabPaneContent';
import { Zinsliste } from '../../../../types';

type Props = {
  objektId: string;
  zinsliste: Zinsliste;
};

const VPosListTab: FC<Props> = ({ zinsliste, objektId }) => {
  const { vorschreibungspositionList, rows } = mapZinslisteVPosListToTable(zinsliste);

  const [selectedVPosIdList, setSelectedVPosIdList] = useState<string[]>([...vorschreibungspositionList.map((item) => item.bezeichnung)]);
  const selectedVPosList = vorschreibungspositionList.filter((vPos) => selectedVPosIdList.includes(vPos.bezeichnung));

  const [paginationForVPosList, setPaginationForVPosList] = useState({
    page: 1,
    pageSize: 20,
  });

  const totalListSize = zinsliste.bestandseinheitList.length;

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Informationen',
      children: (
        <TabPaneContent distanceToTopBorder="large">
          <VPosFilterAndExportBtn
            selectedVPosIdList={selectedVPosIdList}
            onChange={(list) => setSelectedVPosIdList(list)}
            vorschreibungspositionList={vorschreibungspositionList}
            zinsliste={zinsliste}
          />
          {selectedVPosList.length > 0 ? (
            <Flex flexDirection="column">
              <VPosTable
                objektId={objektId}
                rows={rows}
                vorschreibungspositionList={selectedVPosList}
                zinsliste={zinsliste}
                paginationForVPosList={paginationForVPosList}
              />
              <Pagination
                size="small"
                total={totalListSize}
                current={paginationForVPosList.page}
                pageSize={paginationForVPosList.pageSize}
                showSizeChanger
                onChange={(page, pageSize) => setPaginationForVPosList({ page, pageSize })}
                style={{ width: '1ßß%', alignSelf: 'flex-end', margin: '16px 0' }}
              />
            </Flex>
          ) : null}
        </TabPaneContent>
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default VPosListTab;
