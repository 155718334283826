import { generatePath } from 'react-router';
import { UstKategorie, UstKategorieStatus } from '../../../types';
import { URI_SYS_SETTINGS } from '../../../constants/configurationUriPaths';
import { mapToQueryString, QUERY_PARAM_KEY } from '../../../hooks/useStringQueryParams';

export type UstKategorieWithStatus = UstKategorie & { status: NonNullable<UstKategorie['status']> };

export const generatePathToUstKategoriePage = (
  ustKategorieStatus: UstKategorieStatus,
  highlightedRowKey?: string,
  expandedRowKeys?: Record<string, string | string[]>
): string =>
  `${generatePath(URI_SYS_SETTINGS.ustKategoriePage)}?${mapToQueryString({
    [QUERY_PARAM_KEY.SHOW_ARCHIVED]: ustKategorieStatus === UstKategorieStatus.Archiviert,
    [QUERY_PARAM_KEY.HIGHLIGHTED_ROW_KEY]: highlightedRowKey,
    [QUERY_PARAM_KEY.EXPANDED_ROW_KEYS]: expandedRowKeys ? JSON.stringify(expandedRowKeys) : undefined,
  })}`;
