import * as Yup from 'yup';
import { ticketFormFields } from '../ticketFormMapper';

export const ticketParticipantsUpdateFormValidationSchema = Yup.object().shape({
  [ticketFormFields.participantList]: Yup.array().of(
    Yup.object().shape({
      contactId: Yup.string().nullable(),
      emailAddress: Yup.string().nullable().email('E-Mail-Adresse muss gültig sein'),
    })
  ),
});
