import React from 'react';
import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../components/Template/DetailsPageTemplate';
import menuListHeAbrHeVertrag from './menuListHeAbrHeVertrag';
import { HeAbrDetailsHeVertragDetailsPageRouteParams } from './routes';
import { useOnHeAbrechnungHeVertragDetailsDataChangedEvents } from '../../features/Abrechnung/He/useOnHeAbrechnungDataChangeEvent';
import { useHeAbrechnungHeVertragQuery } from '../../features/Abrechnung/He/gql/HeAbrechnungQueries.types';
import HeAbrechnungHeVertragCard from '../../features/Abrechnung/He/Card/HeAbrechnungHeVertrag/HeAbrechnungHeVertragCard';
import { HeAbrechnungHeVertrag } from '../../types';

const HeAbrechnungDetailsHeVertragDetailsPage = () => {
  const { objektId, heAbrechnungId, heAbrechnungHeVertragId } = useParams() as HeAbrDetailsHeVertragDetailsPageRouteParams;

  const { data, refetch } = useHeAbrechnungHeVertragQuery({
    variables: {
      objektId,
      heAbrechnungId,
      heAbrechnungHeVertragId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });
  const heAbrechnungHeVertrag = data?.getHeAbrechnungHeVertrag.data;
  const loading = !heAbrechnungHeVertrag;

  useOnHeAbrechnungHeVertragDetailsDataChangedEvents('HauseigentuemerAbrechnungHeVertrag', refetch);

  return (
    <DetailsPageTemplate<HeAbrechnungHeVertrag>
      data={heAbrechnungHeVertrag}
      pageTitle={(abrechnung) => abrechnung.bezeichnung}
      card={(abrechnung) => <HeAbrechnungHeVertragCard loading={loading} abrechnung={abrechnung} />}
      sidebarMenuList={(abrechnung) => menuListHeAbrHeVertrag(abrechnung, heAbrechnungId, refetch, loading)}
    />
  );
};

export default HeAbrechnungDetailsHeVertragDetailsPage;
