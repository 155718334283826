import React, { FC } from 'react';
import { LabeledValue } from '../../../shared/types';
import LabelValue from '../../../shared/LabelValue';

type Props = {
  repFondsExtra: LabeledValue;
  width?: string;
};

const FPRepFondsExtraBlock: FC<Props> = ({ repFondsExtra, width }) => (
  <LabelValue valueTuple={repFondsExtra} viewStyle={{ marginTop: '5mm', width, fontWeight: 'bold' }} />
);

export default FPRepFondsExtraBlock;
