import { hasVerwendungNutzflaeche, hasVerwendungNutzwert } from './objektAufteilungsschluesselHelper';
import { ObjektAufteilungsschluessel, ObjektAufteilungsschluesselUpdateInput, ObjektAufteilungsschluesselVerwendung } from '../../../../types';
import { FormFields } from '../../../../helpers/formikHelper';

export type ObjektAufteilungsschluesselFormValues = {
  bezeichnung: string;
  isNutzflaeche: boolean;
  isNutzwert: boolean;
};

export const objektAufteilungsschluesselFormFields: FormFields<ObjektAufteilungsschluesselFormValues> = {
  bezeichnung: 'bezeichnung',
  isNutzflaeche: 'isNutzflaeche',
  isNutzwert: 'isNutzwert',
};

export const mapObjektAufteilungsschluesselToFormValues = (
  objektAufteilungsschluessel: ObjektAufteilungsschluessel
): ObjektAufteilungsschluesselFormValues => ({
  bezeichnung: objektAufteilungsschluessel.bezeichnung,
  isNutzflaeche: hasVerwendungNutzflaeche(objektAufteilungsschluessel),
  isNutzwert: hasVerwendungNutzwert(objektAufteilungsschluessel),
});

export const mapFormValuesToObjektAufteilungsschluessel = (
  formValues: ObjektAufteilungsschluesselFormValues
): ObjektAufteilungsschluesselUpdateInput => ({
  bezeichnung: formValues.bezeichnung,
  verwendung: mapFormValuesToObjektAufteilungsschluesselVerwendung(formValues),
});

const mapFormValuesToObjektAufteilungsschluesselVerwendung = (values: ObjektAufteilungsschluesselFormValues) => {
  if (values.isNutzflaeche) {
    return ObjektAufteilungsschluesselVerwendung.Nutzflaeche;
  } else if (values.isNutzwert) {
    return ObjektAufteilungsschluesselVerwendung.Nutzwert;
  }
  return null;
};
