import { FormFields } from '../../../../helpers/formikHelper';
import { OperatingSiteInput } from '../../../../types';
import { TOperatingSiteList } from '../List/OperatingSiteList';

export type OperatingSiteFormValues = {
  bezeichnung: string;
};

export const operatingSiteFormFields: FormFields<OperatingSiteFormValues> = {
  bezeichnung: 'bezeichnung',
};

export const operatingSiteFormInitialValues: OperatingSiteFormValues = {
  bezeichnung: '',
};

export const mapOperatingSiteToFormValues = (values: TOperatingSiteList): OperatingSiteFormValues => ({
  bezeichnung: values.bezeichnung,
});

export const mapFormValuesToOperatingSite = (values: OperatingSiteFormValues): OperatingSiteInput => ({
  bezeichnung: values.bezeichnung,
});
