import React, { FC } from 'react';
import { Box } from 'rebass';
import { useToggle } from '../../../../hooks/useToggle';
import { AbrechdefAbrechkreis, AbrechnungsdefinitionType, Konto, KontoAbrechnungskreis } from '../../../../types';
import ZuordnungColumnHeader from '../../../AbrKontenzuordnung/shared/ZuordnungColumn/ZuordnungColumnHeader';
import ZuordnungListLoading from '../../../AbrKontenzuordnung/shared/ZuordnungListLoading';

import {
  ZuordnungColumnMode,
  ZuordnungKontoKlasseType,
  zuordnungListHeight,
} from '../../../AbrKontenzuordnung/shared/AbrKreisKontenzuordnungHelpers';
import { AbrKontenzuordnungFiltersFormValues } from '../../../AbrKontenzuordnung/Listing/Filters/abrKontenzuordnungFiltersMapper';
import { mapToZuordnungViewModelList } from '../../../AbrKontenzuordnung/shared/zuordnungColumnHelpers';
import { ZuordnungKontoListingFormValues } from '../../../AbrKontenzuordnung/Form/zuordnungKontoListingFormMapper';
import ZuordnungKontoListingForm from '../../Form/ZuordnungKontoListingForm';
import ZuordnungKontoListingViewOrEdit from '../../../AbrKontenzuordnung/shared/ZuordnungKontoListingViewOrEdit';

type Props = {
  title: string;
  mode: ZuordnungColumnMode;
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersionId: string;
  abrDefVersionAbrechkreisList: AbrechdefAbrechkreis[];
  abrDefinitionType: AbrechnungsdefinitionType;
  einnahmenKontoList: Konto[];
  ausgabenKontoList: Konto[];
  verrechnungList: Konto[];
  isEinnahmenListOpen: boolean;
  isAusgabenListOpen: boolean;
  isVerrechnungListOpen: boolean;
  zuordnungList?: KontoAbrechnungskreis[];
  onValuesChange?: (isDirty: boolean) => void;
  withHeader?: boolean;
  shouldSubmitOnChange?: boolean;
  onSubmit?: (formValues: ZuordnungKontoListingFormValues) => Promise<unknown>;
  isSubmitting?: boolean;
  filters?: AbrKontenzuordnungFiltersFormValues | null;
  loading?: boolean;
  editable: boolean;
};

const ZuordnungColumnViewAndEdit: FC<Props> = ({
  loading = false,
  editable,
  title,
  mode,
  abrechnungsdefinitionId,
  abrechnungsdefinitionVersionId,
  abrDefVersionAbrechkreisList,
  abrDefinitionType,
  einnahmenKontoList,
  ausgabenKontoList,
  verrechnungList,
  isEinnahmenListOpen,
  isAusgabenListOpen,
  isVerrechnungListOpen,
  zuordnungList,
  onValuesChange,
  withHeader = true,
  shouldSubmitOnChange,
  onSubmit,
  isSubmitting,
  filters,
}) => {
  const [isInEditMode, toggleEditMode] = useToggle(mode === 'onlyEdit');

  const toggleEditModeIfAllowed = () => {
    if (mode === 'viewAndEdit') toggleEditMode();
  };

  return (
    <Box>
      {/* eslint-disable-next-line no-nested-ternary */}
      {!zuordnungList ? (
        <Box width={zuordnungListHeight.right}>
          <ZuordnungColumnHeader
            isEditDisabled={!editable}
            title={title}
            onClickEdit={toggleEditModeIfAllowed}
            isSaveDisabled={!isInEditMode}
            isListOpen={isEinnahmenListOpen || isAusgabenListOpen}
            withHeader={withHeader}
          />
          <ZuordnungListLoading title={ZuordnungKontoKlasseType.EINNAHMEN} kontoList={einnahmenKontoList} isCollapsed={isEinnahmenListOpen} />
          <Box height="16px" />
          <ZuordnungListLoading title={ZuordnungKontoKlasseType.AUSGABEN} kontoList={ausgabenKontoList} isCollapsed={isAusgabenListOpen} />
          <Box height="16px" />
          <ZuordnungListLoading title={ZuordnungKontoKlasseType.VERRECHNUNG} kontoList={verrechnungList} isCollapsed={isVerrechnungListOpen} />
        </Box>
      ) : !isInEditMode ? (
        <Box width={zuordnungListHeight.right}>
          <ZuordnungColumnHeader
            isEditDisabled={!editable}
            title={title}
            onClickEdit={toggleEditModeIfAllowed}
            isSaveDisabled={!isInEditMode}
            isListOpen={isEinnahmenListOpen || isAusgabenListOpen || isVerrechnungListOpen}
            withHeader={withHeader}
          />
          <ZuordnungKontoListingViewOrEdit
            kontenAbrechnungskreisList={mapToZuordnungViewModelList(abrechnungsdefinitionId, einnahmenKontoList, zuordnungList)}
            zuordnungKontoKlasseType={ZuordnungKontoKlasseType.EINNAHMEN}
            abrDefinitionType={abrDefinitionType}
            isListOpen={isEinnahmenListOpen}
            keyForCollapse="1"
            mode={mode}
            filters={filters}
            loading={loading}
          />
          <Box height="16px" />
          <ZuordnungKontoListingViewOrEdit
            kontenAbrechnungskreisList={mapToZuordnungViewModelList(abrechnungsdefinitionId, ausgabenKontoList, zuordnungList)}
            zuordnungKontoKlasseType={ZuordnungKontoKlasseType.AUSGABEN}
            abrDefinitionType={abrDefinitionType}
            isListOpen={isAusgabenListOpen}
            keyForCollapse="2"
            mode={mode}
            filters={filters}
            loading={loading}
          />
          <Box height="16px" />
          <ZuordnungKontoListingViewOrEdit
            kontenAbrechnungskreisList={mapToZuordnungViewModelList(abrechnungsdefinitionId, verrechnungList, zuordnungList)}
            zuordnungKontoKlasseType={ZuordnungKontoKlasseType.VERRECHNUNG}
            abrDefinitionType={abrDefinitionType}
            isListOpen={isVerrechnungListOpen}
            keyForCollapse="3"
            mode={mode}
            filters={filters}
            loading={loading}
          />
        </Box>
      ) : (
        <ZuordnungKontoListingForm
          abrechnungsdefinitionId={abrechnungsdefinitionId}
          abrechnungsdefinitionVersionId={abrechnungsdefinitionVersionId}
          abrDefVersionAbrechkreisList={abrDefVersionAbrechkreisList}
          abrDefinitionType={abrDefinitionType}
          erloesKontoList={einnahmenKontoList}
          aufwandKontoList={ausgabenKontoList}
          verrechnungList={verrechnungList}
          zuordnungList={zuordnungList}
          title={title}
          onClickEdit={toggleEditModeIfAllowed}
          isErloesKontoListOpen={isEinnahmenListOpen}
          isAufwandKontoListOpen={isAusgabenListOpen}
          isVerrechnungListOpen={isVerrechnungListOpen}
          onValuesChange={onValuesChange}
          withHeader={withHeader}
          shouldSubmitOnChange={shouldSubmitOnChange}
          onSubmit={(formValues) => {
            const onSuccess = () => {
              toggleEditModeIfAllowed();
              return Promise.resolve();
            };
            return onSubmit ? onSubmit(formValues).then(onSuccess) : onSuccess();
          }}
          isSubmitting={isSubmitting}
          filters={filters}
        />
      )}
    </Box>
  );
};

export default ZuordnungColumnViewAndEdit;
