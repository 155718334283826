import * as Yup from 'yup';
import { validateGeneralBezeichnung } from '../../../shared/components/KurzBezeichnung/form/kurzBezeichnungFormPartValidationSchema';
import { entityHasToBeAnInt, entityHasToBeANumber, entityIsRequired } from '../../../components/message/validationMsg';
import { kontierungstabelleFormFields } from './kontierungstabelleFormMapper';

/* eslint-disable no-template-curly-in-string */
export const kontierungstabelleFormValidationSchema = Yup.object({
  [kontierungstabelleFormFields.bezeichnung]: validateGeneralBezeichnung(50, true),
  [kontierungstabelleFormFields.basiskonto]: Yup.number()
    .typeError(entityHasToBeANumber('Basiskonto'))
    .integer(entityHasToBeAnInt('Basiskonto'))
    .required(entityIsRequired('Basiskonto')),
});
/* eslint-enable no-template-curly-in-string */
