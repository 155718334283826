import { stringOrEmpty } from '../../../../helpers/stringHelper';
import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { Bestandseinheit, BestandseinheitUpdateInput } from '../../../../types';

export type BestandseinheitUpdateFormValues = {
  addressId: string;
  aktivSeit: string;
  inaktivSeit?: string;
  bezeichnung: string;
  geschoss: string;
  stiege: string;
  bauteil: string;
  tagList?: string[];
};

export const bestandseinheitUpdateFormFields: FormFields<BestandseinheitUpdateFormValues> = {
  addressId: 'addressId',
  aktivSeit: 'aktivSeit',
  inaktivSeit: 'inaktivSeit',
  bauteil: 'bauteil',
  bezeichnung: 'bezeichnung',
  geschoss: 'geschoss',
  stiege: 'stiege',
  tagList: 'tagList',
};

export const mapBestandseinheitToFormValues = (selectedAddress: string, bestandseinheit: Bestandseinheit): BestandseinheitUpdateFormValues => {
  return {
    addressId: selectedAddress ?? bestandseinheit.address.addressId,
    aktivSeit: bestandseinheit.aktivSeit ?? '',
    inaktivSeit: bestandseinheit?.inaktivSeit ?? '',
    bezeichnung: bestandseinheit.bezeichnung,
    geschoss: stringOrEmpty(bestandseinheit.geschoss),
    stiege: stringOrEmpty(bestandseinheit.stiege),
    bauteil: stringOrEmpty(bestandseinheit.bauteil),
    tagList: bestandseinheit.tagList,
  };
};

export const mapFormValuesToBestandseinheitUpdate = (formValues: BestandseinheitUpdateFormValues): BestandseinheitUpdateInput => {
  return {
    addressId: formValues.addressId,
    aktivSeit: formValues.aktivSeit ? mapFormDateValueToDateString(formValues.aktivSeit) : undefined,
    bauteil: formValues.bauteil,
    bezeichnung: formValues.bezeichnung,
    geschoss: formValues.geschoss,
    inaktivSeit: formValues.inaktivSeit ? mapFormDateValueToDateString(formValues.inaktivSeit) : undefined,
    stiege: formValues.stiege,
    tagList: formValues.tagList,
  };
};
