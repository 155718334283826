import React, { FC } from 'react';
import { FormItemProps } from 'formik-antd';
import { Tooltip } from 'antd';
import { useField } from 'formik';
import { StyledFormItem } from './styled/FormItemWithTooltip.style';

type FormItemWithTooltipProps = Omit<FormItemProps, 'htmlFor' | 'colon'>;

/**
 *
 * <h2>Usage</h2>
 * In Table Cells instead of Form.Item (formik-antd) to keep the code consistent.
 * The validation message will appear as tooltip above the children component.
 *
 * <h2>Component Info</h2>
 *  <ul>
 *    <li>Props htmlFor and colon are omitted</li>
 *    <li>Use name value as id in the children component as htmlFor value is set to name prop value</li>
 *  </ul>
 *
 */
const FormItemWithTooltip: FC<FormItemWithTooltipProps> = ({ children, ...restProps }) => {
  const [, meta] = useField(restProps.name);

  return (
    <StyledFormItem {...restProps} colon={false} htmlFor={restProps.name} help={false}>
      {meta.touched && meta.error ? (
        <Tooltip title={meta.error} open>
          {children}
        </Tooltip>
      ) : (
        children
      )}
    </StyledFormItem>
  );
};

export default FormItemWithTooltip;
