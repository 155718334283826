import React from 'react';
import { Skeleton } from 'antd';
import { useParams } from 'react-router-dom';
import { useMahndefinitionQuery } from '../../../../../features/Mahndefinition/gql/MahndefinitionQueries.types';
import MahndefinitionUpdateForm from '../../../../../features/Mahndefinition/Form/Update/MahndefinitionUpdateForm';
import { MahndefinitionUpdatePageRouteParams } from './routes';

const SysSettingsMahndefinitionUpdatePage = () => {
  const { mahndefinitionId } = useParams() as MahndefinitionUpdatePageRouteParams;

  const { data, loading: loadingMahndefinition } = useMahndefinitionQuery({
    variables: {
      mahndefinitionId,
    },
  });
  const mahndefinition = data?.getMahndefinition.data;

  if (loadingMahndefinition) {
    return <Skeleton active />;
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return <MahndefinitionUpdateForm mahndefinition={mahndefinition!} />;
};

export default SysSettingsMahndefinitionUpdatePage;
