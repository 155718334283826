import React, { FC, useState } from 'react';
import { Col, message, Row, Typography } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';
import FormButtons from '../../../components/Button/FormButtons';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';
import ParticipantSelect from './ParticipantSelect/ParticipantSelect';
import { TicketAndParentId } from '../ticketUriPaths';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { Ticket } from '../../../types';
import Fetcher from '../../../helpers/fetcher';
import { processAndReturnError, processResponse } from '../../../helpers/APIUtils';
import { showSuccessMsgOther } from '../../../components/message';
import { mapErrorToErrorMessage } from '../../../helpers/errorAndWarningHelper';
import {
  mapFormValuesToTicketSendEmailInput,
  ticketSendEmailFormFields,
  ticketSendEmailFormInitialValues,
  TicketSendEmailFormValues,
  TicketSendEmailInput,
} from './ticketSendEmailFormMapper';
import { ticketSendEmailFormValidationSchema } from './ticketSendEmailFormValidationSchema';

type Props = {
  ticket: Ticket;
  loadingCreate?: boolean;
  onSuccess: (ticket?: TicketAndParentId) => void;
  onCancel: () => void;
  isSubticket?: boolean;
  parentId?: string;
};

const TicketSendEmailForm: FC<Props> = ({ ticket, loadingCreate, onSuccess, onCancel, isSubticket = false, parentId }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'TicketSendEmailRequest'>('TicketSendEmailRequest');

  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  const entity = `Aufgabe`;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const isLoading = loadingCreate || isSubmitting;

  const subjectPrefix = `[TICKET-NR: ${ticket.number}]`;

  return (
    <Formik<TicketSendEmailFormValues>
      initialValues={ticketSendEmailFormInitialValues}
      validationSchema={ticketSendEmailFormValidationSchema}
      onSubmit={(values, formikHelpers) => {
        setIsSubmitting(true);
        handleSubmit(firmendatenId, ticket.ticketId, values, mapFormValuesToTicketSendEmailInput(values, subjectPrefix), setIsSubmitting, onSuccess);
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Empfänger</Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithFieldHelp
                name={ticketSendEmailFormFields.toList}
                label="An"
                fieldHelp={getFieldHelpText('TicketSendEmailRequest.toList')}
                help={formikProps.errors.toList && <>E-Mail-Adresse muss gültig sein</>}
                validateStatus={formikProps.errors.toList ? 'error' : undefined}
              >
                <ParticipantSelect id={ticketSendEmailFormFields.toList} name={ticketSendEmailFormFields.toList} />
              </FormItemWithFieldHelp>

              <FormItemWithFieldHelp
                name={ticketSendEmailFormFields.ccList}
                label="CC"
                fieldHelp={getFieldHelpText('TicketSendEmailRequest.ccList')}
                help={formikProps.errors.ccList && <>E-Mail-Adresse muss gültig sein</>}
                validateStatus={formikProps.errors.ccList ? 'error' : undefined}
              >
                <ParticipantSelect id={ticketSendEmailFormFields.ccList} name={ticketSendEmailFormFields.ccList} />
              </FormItemWithFieldHelp>

              <FormItemWithFieldHelp
                name={ticketSendEmailFormFields.bccList}
                label="BCC"
                fieldHelp={getFieldHelpText('TicketSendEmailRequest.bccList')}
                help={formikProps.errors.bccList && <>E-Mail-Adresse muss gültig sein</>}
                validateStatus={formikProps.errors.bccList ? 'error' : undefined}
              >
                <ParticipantSelect id={ticketSendEmailFormFields.bccList} name={ticketSendEmailFormFields.bccList} />
              </FormItemWithFieldHelp>
            </Col>
          </Row>

          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Details</Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithFieldHelp
                name={ticketSendEmailFormFields.subject}
                label="Betreff"
                fieldHelp={getFieldHelpText('TicketSendEmailRequest.subject')}
              >
                <Input
                  id={ticketSendEmailFormFields.subject}
                  name={ticketSendEmailFormFields.subject}
                  placeholder="Betreff"
                  prefix={<span />}
                  addonBefore={subjectPrefix}
                />
              </FormItemWithFieldHelp>
              <FormItemWithFieldHelp
                name={ticketSendEmailFormFields.body}
                label="Textkörper"
                fieldHelp={getFieldHelpText('TicketSendEmailRequest.body')}
              >
                <Input.TextArea id={ticketSendEmailFormFields.body} name={ticketSendEmailFormFields.body} placeholder="Textkörper" />
              </FormItemWithFieldHelp>
            </Col>
          </Row>

          <FormButtons updateMode={false} onCancel={onCancel} isOkDisabled={isSubmitting} isOkLoading={isLoading} />
        </Form>
      )}
    </Formik>
  );
};

export const handleSubmit = (
  firmendatenId: string,
  ticketId: string,
  values: TicketSendEmailFormValues,
  input: TicketSendEmailInput,
  setSubmitting: (isSubmitting: boolean) => void,
  onSuccess: () => void
) => {
  const formData = new FormData();
  formData.append(
    'request',
    new Blob([JSON.stringify({ data: { ...input } })], {
      type: 'application/json',
    })
  );

  if (values.newAttachmentList.length > 0) {
    values.newAttachmentList.forEach((attachment) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      formData.append('newAttachmentList', attachment.file!, attachment.filename);
    });
  }

  setSubmitting(true);
  return Fetcher.getInstance()
    .fetch(`/api/firmendaten/${firmendatenId}/tickets/${ticketId}/send-email`, {
      method: 'POST',
      body: formData,
    })
    .then(processResponse)
    .then(() => {
      showSuccessMsgOther(`E-Mail wird versandt`);
      onSuccess();
    })
    .catch(processAndReturnError)
    .catch((error) => {
      return message.error({
        content: `E-Mail konnte nicht versandt werden: ${mapErrorToErrorMessage(error)}`,
      });
    })
    .finally(() => {
      setSubmitting(false);
    });
};

export default TicketSendEmailForm;
