import React, { FC } from 'react';
import { FormItem, FormItemProps } from 'formik-antd';
import styled from 'styled-components';
import { Space } from 'antd';

type FormItemForFilterProps = { extraInfo?: React.ReactNode } & Omit<FormItemProps, 'label'>;

/**
 *
 * <h2>Usage</h2>
 * Used for Filters that don't use a label.
 *
 * <h2>Component Info</h2>
 *  <ul>
 *    <li>Props label are omitted</li>
 *  </ul>
 *
 */
const FormItemForFilter: FC<FormItemForFilterProps> = ({ extraInfo, children, ...restProps }) => (
  <Space>
    <StyledFormItem hasFeedback={false} {...restProps}>
      {children}
    </StyledFormItem>
    {extraInfo}
  </Space>
);

export const StyledFormItem = styled(FormItem)`
  margin-bottom: 0 !important;
`;

export default FormItemForFilter;
