import React, { FC } from 'react';
import { Typography } from 'antd';
import { Spacer } from '../../../../../components/Grid';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import FormButtons from '../../../../../components/Button/FormButtons';
import AuftragsartTemplate from '../../../../Auftragsart/Form/shared/Template/AuftragsartTemplate';

type AuftragsartCreateTemplateFormProps = {
  auftragsartBezeichnung: string;
  auftragsartTemplateValues: TextbausteinFormValues[];
  setAuftragsartTemplateValues: React.Dispatch<React.SetStateAction<TextbausteinFormValues[] | undefined>>;
  showUstBlock: boolean;
  onBack: () => void;
  onSubmit: () => void;
};

const AuftragsartTemplateCreateTemplateForm: FC<AuftragsartCreateTemplateFormProps> = ({
  auftragsartBezeichnung,
  auftragsartTemplateValues,
  setAuftragsartTemplateValues,
  showUstBlock,
  onBack,
  onSubmit,
}) => {
  return (
    <>
      <Spacer height={16} />
      <Typography.Title level={5} style={{ textAlign: 'center' }}>
        {auftragsartBezeichnung}
      </Typography.Title>
      <Spacer height={16} />
      <AuftragsartTemplate
        templateFormValues={auftragsartTemplateValues}
        onTemplateChange={setAuftragsartTemplateValues}
        showUstBlock={showUstBlock}
      />
      <FormButtons onCancel={onBack} onOk={onSubmit} updateMode={false} cancelText="Zurück" />
    </>
  );
};

export default AuftragsartTemplateCreateTemplateForm;
