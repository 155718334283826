import * as Types from '../../../../../../types';

import { gql } from '@apollo/client';
import { UstKategorieFieldsFragmentDoc, UstKategorieEntryFieldsFragmentDoc } from '../../../../../Ust/UstKategorie/gql/UstKategorieFragments.types';
export type VPosWertChangeReasonFields_VPosWertChangeReasonBudgeting_Fragment = {
  __typename: 'VPosWertChangeReasonBudgeting';
  budgetingId: string;
  bestandseinheitId: string;
  objektId: string;
  vertragId: string;
  vorschreibungspositionId: string;
  text: string;
  type: Types.ChangeReason;
};

export type VPosWertChangeReasonFields_VPosWertChangeReasonIndexedValue_Fragment = {
  __typename: 'VPosWertChangeReasonIndexedValue';
  bestandseinheitId: string;
  indexationAgreementId: string;
  objektId: string;
  vertragId: string;
  vertragVposIndexedValueId: string;
  vorschreibungspositionId: string;
  text: string;
  type: Types.ChangeReason;
};

export type VPosWertChangeReasonFields_VPosWertChangeReasonUst_Fragment = {
  __typename: 'VPosWertChangeReasonUst';
  text: string;
  type: Types.ChangeReason;
};

export type VPosWertChangeReasonFieldsFragment =
  | VPosWertChangeReasonFields_VPosWertChangeReasonBudgeting_Fragment
  | VPosWertChangeReasonFields_VPosWertChangeReasonIndexedValue_Fragment
  | VPosWertChangeReasonFields_VPosWertChangeReasonUst_Fragment;

export type BeVertragVorschreibungspositionBaseFieldsFragment = {
  bezeichnung: string;
  brutto?: number | null;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  validFrom?: string | null;
  netto?: number | null;
  steuersatz: number;
  ust?: number | null;
  vorschreibungspositionId: string;
  art: { text: string; value: Types.VorschreibungspositionArt };
  basis?: { text: string; value: Types.VorschreibungspositionBasis } | null;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type BeVertragVorschreibungspositionFieldsFragment = {
  bezeichnung: string;
  brutto?: number | null;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  validFrom?: string | null;
  netto?: number | null;
  steuersatz: number;
  ust?: number | null;
  vorschreibungspositionId: string;
  art: { text: string; value: Types.VorschreibungspositionArt };
  basis?: { text: string; value: Types.VorschreibungspositionBasis } | null;
  objektVorschreibungspositionStatus: { description?: string | null; text: string; value: Types.ObjektVorschreibungspositionStatus };
  ustKategorie?: {
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    firmendatenId?: string | null;
    kurzBezeichnung: string;
    ustKategorieId: string;
    ustVomAufwand?: number | null;
    vstKuerzung?: boolean | null;
    status?: { text: string; value: Types.UstKategorieStatus } | null;
    ustKategorieEntryList: Array<{
      additionKonto: number;
      buchungsKZ?: string | null;
      createTs?: string | null;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      rechnungskennzeichen: string;
      steuersatz: number;
      ustKategorieEntryId?: string | null;
      ustKz: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kuerzel: string;
        umsatzsteuerkennzeichenId: string;
        status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  ustKategorieEntry?: {
    additionKonto: number;
    buchungsKZ?: string | null;
    createTs?: string | null;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    rechnungskennzeichen: string;
    steuersatz: number;
    ustKategorieEntryId?: string | null;
    ustKz: {
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      kuerzel: string;
      umsatzsteuerkennzeichenId: string;
      status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type BeVertragVorschreibungsPositionWertFieldsFragment = {
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  createTs: string;
  brutto: number;
  current: boolean;
  validFrom: string;
  netto: number;
  steuersatz: number;
  ust: number;
  deletable: boolean;
  vertragVorschreibungspositionWertId: string;
  basis: { text: string; value: Types.VorschreibungspositionBasis };
  ustKategorie?: {
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    firmendatenId?: string | null;
    kurzBezeichnung: string;
    ustKategorieId: string;
    ustVomAufwand?: number | null;
    vstKuerzung?: boolean | null;
    status?: { text: string; value: Types.UstKategorieStatus } | null;
    ustKategorieEntryList: Array<{
      additionKonto: number;
      buchungsKZ?: string | null;
      createTs?: string | null;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      rechnungskennzeichen: string;
      steuersatz: number;
      ustKategorieEntryId?: string | null;
      ustKz: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kuerzel: string;
        umsatzsteuerkennzeichenId: string;
        status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  ustKategorieEntry?: { ustKategorieEntryId?: string | null } | null;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  changeReason?:
    | {
        __typename: 'VPosWertChangeReasonBudgeting';
        budgetingId: string;
        bestandseinheitId: string;
        objektId: string;
        vertragId: string;
        vorschreibungspositionId: string;
        text: string;
        type: Types.ChangeReason;
      }
    | {
        __typename: 'VPosWertChangeReasonIndexedValue';
        bestandseinheitId: string;
        indexationAgreementId: string;
        objektId: string;
        vertragId: string;
        vertragVposIndexedValueId: string;
        vorschreibungspositionId: string;
        text: string;
        type: Types.ChangeReason;
      }
    | { __typename: 'VPosWertChangeReasonUst'; text: string; type: Types.ChangeReason }
    | null;
};

export const BeVertragVorschreibungspositionBaseFieldsFragmentDoc = gql`
  fragment BeVertragVorschreibungspositionBaseFields on BeVertragVorschreibunspositionBase {
    art {
      text
      value
    }
    basis {
      text
      value
    }
    bezeichnung
    brutto
    createTs
    createdBy
    createdByMitarbeiterId
    validFrom
    netto
    steuersatz
    ust
    vorschreibungspositionId
    warningList {
      attribute
      message
      type
    }
  }
`;
export const BeVertragVorschreibungspositionFieldsFragmentDoc = gql`
  fragment BeVertragVorschreibungspositionFields on BeVertragVorschreibunsposition {
    art {
      text
      value
    }
    basis {
      text
      value
    }
    bezeichnung
    brutto
    createTs
    createdBy
    createdByMitarbeiterId
    validFrom
    netto
    objektVorschreibungspositionStatus {
      description
      text
      value
    }
    steuersatz
    ust
    ustKategorie {
      ...UstKategorieFields
    }
    ustKategorieEntry {
      ...UstKategorieEntryFields
    }
    vorschreibungspositionId
    warningList {
      attribute
      message
      type
    }
  }
  ${UstKategorieFieldsFragmentDoc}
  ${UstKategorieEntryFieldsFragmentDoc}
`;
export const VPosWertChangeReasonFieldsFragmentDoc = gql`
  fragment VPosWertChangeReasonFields on AbstractVPosWertChangeReason {
    __typename
    text
    type
    ... on VPosWertChangeReasonBudgeting {
      budgetingId
      bestandseinheitId
      objektId
      vertragId
      vorschreibungspositionId
    }
    ... on VPosWertChangeReasonIndexedValue {
      bestandseinheitId
      indexationAgreementId
      objektId
      vertragId
      vertragVposIndexedValueId
      vorschreibungspositionId
    }
  }
`;
export const BeVertragVorschreibungsPositionWertFieldsFragmentDoc = gql`
  fragment BeVertragVorschreibungsPositionWertFields on BeVertragVorschreibunspositionWert {
    createdBy
    createdByMitarbeiterId
    createTs
    basis {
      text
      value
    }
    brutto
    current
    validFrom
    netto
    steuersatz
    ust
    deletable
    ustKategorie {
      ...UstKategorieFields
    }
    ustKategorieEntry {
      ustKategorieEntryId
    }
    vertragVorschreibungspositionWertId
    warningList {
      attribute
      message
      type
    }
    changeReason {
      ...VPosWertChangeReasonFields
    }
  }
  ${UstKategorieFieldsFragmentDoc}
  ${VPosWertChangeReasonFieldsFragmentDoc}
`;
