import { TextbausteinInput } from '../../../../../types';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';

export const mapFormValuesToTextbaustein = (textbausteinList: TextbausteinFormValues[]): TextbausteinInput[] =>
  textbausteinList
    .flatMap((item) => (!item.textbausteinList ? item : item.textbausteinList))
    .map((item) => ({
      type: item.type.value,
      value: item.value,
    }));
