import React, { FC } from 'react';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import { useFDAuthorized } from '../../../../../../../shared/Auth/useAuthorized';
import FormButtons from '../../../../../../../components/Button/FormButtons';
import InfoFeldUpload from '../../../../../../shared/InfoFeld/InfoFeldUpload';
import { weiterePersonCreateFormFields, weiterePersonCreateFormInitialValues, WeiterePersonCreateFormValues } from './weiterePersonCreateFormMapper';
import WeiterePersonDatenTab from '../Tabs/WeiterePersonDatenTab';
import { handleCreateSubmit } from '../../shared/handleSubmit';
import { VertragInfoFeldType } from '../../../../../../../types';
import { weiterPersonValidationSchema } from '../shared/weiterePersonValidationSchema';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

const WeiterePersonCreateForm: FC<Props> = ({ objektId, bestandseinheitId, vertragId, onSuccess, onCancel }) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  return (
    <Formik<WeiterePersonCreateFormValues>
      initialValues={weiterePersonCreateFormInitialValues}
      onSubmit={(values, { setSubmitting }) => {
        handleCreateSubmit(
          firmendatenId,
          objektId,
          bestandseinheitId,
          vertragId,
          VertragInfoFeldType.WeiterePerson,
          values,
          setSubmitting,
          onSuccess
        );
      }}
      validationSchema={weiterPersonValidationSchema}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <WeiterePersonDatenTab formFields={weiterePersonCreateFormFields} formikProps={formikProps} />
          <InfoFeldUpload
            name={weiterePersonCreateFormFields.documentList}
            formikProps={formikProps}
            documentList={formikProps.values.documentList}
          />
          <FormButtons updateMode={false} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default WeiterePersonCreateForm;
