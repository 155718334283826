import { FormFields } from '../../../../helpers/formikHelper';
import { BuchungszeilenAnzeigenTuple, VerwendungVonRepFondsTuple } from '../../shared/AbrKreisKontenzuordnungHelpers';

export type AbrKontenzuordnungFiltersFormValues = {
  verwendungVonRepFonds?: VerwendungVonRepFondsTuple | null;
  buchungszeilenAnzeigen?: BuchungszeilenAnzeigenTuple | null;
  displayAbweichenderAufteilungsschluesselId?: string | null;
  displayExpenseReducing?: boolean | null;
};

export const abrKontenzuordnungFiltersFields: FormFields<AbrKontenzuordnungFiltersFormValues> = {
  verwendungVonRepFonds: 'verwendungVonRepFonds',
  buchungszeilenAnzeigen: 'buchungszeilenAnzeigen',
  displayAbweichenderAufteilungsschluesselId: 'displayAbweichenderAufteilungsschluesselId',
  displayExpenseReducing: 'displayExpenseReducing',
};

export const abrKontenzuordnungLabelList = {
  [abrKontenzuordnungFiltersFields.verwendungVonRepFonds]: 'Verwendung v. Rep Fonds',
  [abrKontenzuordnungFiltersFields.buchungszeilenAnzeigen]: 'Buchungszeilen anzeigen',
  [abrKontenzuordnungFiltersFields.displayAbweichenderAufteilungsschluesselId]: 'Abweichender Aufteilungsschlüssel',
  [abrKontenzuordnungFiltersFields.displayExpenseReducing]: 'Aufwandsmindernd',
};
