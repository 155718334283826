import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { useAuth } from '../../../shared/Auth/authContext';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../../shared/dataChangedSubscription';

const useOnObjAbrDataChangeEvent = (key: string, callback: () => Promise<unknown>, objektId?: string, objektAbrechnungId?: string[]) => {
  const { user } = useFDAuthorized();
  const { mitarbeiter } = useAuth();

  useOnDataChangedEvent([
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        //changeType: DataChangedChangeType.Update,
        type: dataChangedSubscriptionTypes.objektAbrechnung,
        params: {
          objektId,
          objektAbrechnungId,
        },
      },
      notificationDescription: 'Objektabrechnung wurde gerade verändert',
      key: `${key}-objektAbr`,
      callback,
    },
  ]);
};

export { useOnObjAbrDataChangeEvent };
