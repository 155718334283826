import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../../shared/dataChangedSubscription';
import { DataChangedChangeType } from '../../../types';

const useOnSubAdministrationDataChangeEvent = (key: string, callback: () => Promise<unknown>) => {
  useOnDataChangedEvent([
    {
      filter: {
        //userId: user.username,
        //mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.vertrag,
        params: {},
        changeType: DataChangedChangeType.Update,
      },
      notificationDescription: 'Vertrag wurde gerade verändert',
      key: `${key}`,
      callback,
    },
    {
      filter: {
        //userId: user.username,
        //mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.vertrag,
        params: {},
        changeType: DataChangedChangeType.Create,
      },
      notificationDescription: 'Vertag wurde gerade erstellt',
      key: `${key}`,
      callback,
    },
  ]);
};

export { useOnSubAdministrationDataChangeEvent };
