import { FC, useEffect } from 'react';
import { FormikProps } from 'formik';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../helpers/selectHelper';
import { usePaymentBuchungTypeListQuery } from './gql/PaymentBuchungTypeQueries.types';
import { BookingSuggestionFormValues } from '../../BookingSuggestion/shared/Form/buchungsmaskeFormMapper';

type Props = {
  formikProps: FormikProps<BookingSuggestionFormValues>;
} & Omit<SelectProps, 'id' | 'placeholder' | 'filterOption'>;

const PaymentBuchungTypeSelect: FC<Props> = ({ formikProps, name, ...restProps }) => {
  const belegSymbol = formikProps.values.buchungsanweisung.belegSymbol;

  const { data, loading, refetch } = usePaymentBuchungTypeListQuery({ variables: { belegSymbol } });
  const buchungTypeList = data?.getPaymentBuchungTypeList.data ?? [];

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [belegSymbol]);

  // Responsible for setting the select value upon the options list refetch - only if the options list had been changed
  useEffect(() => {
    buchungTypeList.length && formikProps.setFieldValue(name, buchungTypeList[0].value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buchungTypeList]);

  return (
    <Select
      {...restProps}
      name={name}
      id={name}
      loading={loading}
      placeholder="Buchungstyp auswählen"
      filterOption={selectFilterOption}
      style={{ width: '100%' }}
      size="small"
    >
      {buchungTypeList.map((buchungType) => (
        <Select.Option key={buchungType.value} value={buchungType.value}>
          {buchungType.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default PaymentBuchungTypeSelect;
