import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../styles/pdfStyles';
import { TFPRepFondsExpensesTable } from '../financialPlan-types';

type Props = {
  expenses: TFPRepFondsExpensesTable;
  width?: string;
};

const FPRepFondsExpensesTable: FC<Props> = ({ expenses, width }) => (
  <View style={{ width, marginTop: '5mm' }}>
    {/* table header */}
    <View style={[pdfStyles.row, pdfStyles.borderBottomSmall]}>
      <Text style={[pdfStyles.column, pdfStyles.textNormal, { width: '50%' }]}>{expenses.headerText.nameTitle}</Text>
      <Text style={[pdfStyles.column, pdfStyles.textNormal, { width: '50%', textAlign: 'right' }]}>{expenses.headerText.amountTitle}</Text>
    </View>

    {/* table rows */}
    {expenses.expenseList.map((expense, index) => (
      <View key={index} style={[pdfStyles.row]}>
        <Text style={[pdfStyles.column, pdfStyles.textBulletin, { width: '50%' }]}>{expense.name}</Text>
        <Text style={[pdfStyles.column, pdfStyles.textBulletin, { width: '50%', textAlign: 'right' }]}>{expense.amount}</Text>
      </View>
    ))}

    {/* table summary */}
    <View style={[pdfStyles.row, pdfStyles.borderTopSmall, { fontWeight: 'bold' }]}>
      <Text style={[pdfStyles.column, pdfStyles.textNormal, { width: '50%' }]}>{expenses.expensesSummary.name}</Text>
      <Text style={[pdfStyles.column, pdfStyles.textNormal, { width: '50%', textAlign: 'right' }]}>{expenses.expensesSummary.amount}</Text>
    </View>
  </View>
);

export default FPRepFondsExpensesTable;
