import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import AbrechnungPage from './AbrechnungPage';
import { URI_ABRECHNUNG_PAGE } from '../../features/Abrechnung/abrechnungUriPaths';

const abrechnungPageRoute = (
  <Route path={URI_ABRECHNUNG_PAGE} element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<AbrechnungPage />} />} />
);

export default abrechnungPageRoute;
