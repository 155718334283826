import { FC, PropsWithChildren } from 'react';
import { isColumnSorted, SortOrder } from './sortingHelpers';

type Props = {
  index: number;
  align?: 'left' | 'center' | 'right';
  sortedColumnIndex: number;
  sortOrder: SortOrder;
  colSpan?: number;
};

const TableCellContentWithSorting: FC<Props & PropsWithChildren> = ({ index, align = 'left', sortedColumnIndex, colSpan, sortOrder, children }) => (
  <td
    style={{ textAlign: align }}
    className={`${isColumnSorted(index, sortedColumnIndex, sortOrder) ? 'ant-table-column-sort' : ''}`}
    colSpan={colSpan}
  >
    {children}
  </td>
);
export default TableCellContentWithSorting;
