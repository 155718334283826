import styled from 'styled-components';
import { Space } from 'antd';

export const StyledSpaceFullWidthInFilter = styled(Space)`
  width: 100%;
  align-items: center;
  .ant-space-item:not(:nth-child(2n)) {
    flex: 1;
  }
`;

export const StyledSpaceFullWidthSecondElement = styled(Space)`
  width: 100%;
  align-items: center;
  .ant-space-item:nth-child(2n) {
    flex: 1;
  }

  margin-bottom: 16px;
`;
