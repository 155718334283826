export const userList = [
  {
    userId: '9b2e7a8c-7fdd-4ee0-be4d-fc405b075a9a',
    title: 'Dr.',
    titleTrailing: 'MSc',
    firstname: 'Thanos',
    lastname: 'Hypocorism',
    username: 'thanos123',
    email: 'thanos123@aol.com',
    status: {
      value: 'AKTIV',
      text: 'aktiv',
    },
    firmen: ['Avengers', 'Teamn Yellow', 'FFXV Nerds'],
  },
  {
    userId: '9b2e7a8c-7fdd-4ee0-be4d-fc405b075a9c',
    title: 'Dr.',
    titleTrailing: 'MSc',
    firstname: 'Captain',
    lastname: 'America',
    username: 'captain',
    email: 'captain@aol.com',
    status: {
      value: 'INAKTIV',
      text: 'aktiv',
    },
    firmen: ['Avengers'],
  },
  {
    userId: '9b2e7a8c-7fdd-4ee0-be4d-fc405b075a9d',
    title: 'Dr.',
    titleTrailing: 'MSc',
    firstname: 'Spider',
    lastname: 'Man',
    username: 'spidey123',
    email: 'spidey@aol.com',
    status: {
      value: 'GELOESCHT',
      text: 'aktiv',
    },
    firmen: ['FFXV Nerds'],
  },
];
