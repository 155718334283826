import { ObjektBestandseinheitVertrag } from '../../../types';

export type ObjektVertrag = {
  topName: string;
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
};
export const getObjektVertragList = (vertragList: ObjektBestandseinheitVertrag[] | undefined): ObjektVertrag[] => {
  return (vertragList || []).map((vertrag) => ({
    topName: `${vertrag.objekt.kurzBezeichnung} - ${vertrag.bestandseinheit.bezeichnung}`,
    objektId: vertrag.objekt.objektId,
    bestandseinheitId: vertrag.bestandseinheit.bestandseinheitId,
    vertragId: vertrag.vertragId,
  }));
};
