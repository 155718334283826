import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import BkOrWeObjektAbrechnungDeviationTab from './BkOrWeObjektAbrechnungDeviationTab';
import { AbrechnungStatusTuple, AbrechnungType, TopAbrechnungStatusTuple } from '../../../../types';
import BkOrWeTopAbrechnungDeviationTab from './BkOrWeTopAbrechnungDeviationTab';
import { isAbrechnungTypeWeAbrechnung } from '../../shared/abrechnungHelpers';

type Props = {
  objektId: string;
  objektAbrechnungId: string;
  onAction: () => Promise<any>;
  objektAbrechnungStatus: AbrechnungStatusTuple;
  topAbrechnungStatus: TopAbrechnungStatusTuple;
  abrechnungType: AbrechnungType;
};

const BkOrWeAbrechnungDeviationList: FC<Props> = ({
  objektId,
  objektAbrechnungId,
  onAction,
  objektAbrechnungStatus,
  topAbrechnungStatus,
  abrechnungType,
}) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: isAbrechnungTypeWeAbrechnung(abrechnungType) ? 'WE-Objektabrechnung' : 'BK-Objektabrechnung',
      children: (
        <BkOrWeObjektAbrechnungDeviationTab
          objektId={objektId}
          objektAbrechnungId={objektAbrechnungId}
          onAction={onAction}
          status={objektAbrechnungStatus}
        />
      ),
    },
    {
      key: '2',
      label: isAbrechnungTypeWeAbrechnung(abrechnungType) ? 'WE-Topabrechnungen' : 'BK-Topabrechnungen',
      children: (
        <BkOrWeTopAbrechnungDeviationTab
          objektId={objektId}
          objektAbrechnungId={objektAbrechnungId}
          onAction={onAction}
          status={topAbrechnungStatus}
        />
      ),
    },
  ];

  return <Tabs destroyInactiveTabPane items={items} />;
};

export default BkOrWeAbrechnungDeviationList;
