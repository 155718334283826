import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../../security/AuthRoute';
import AndromedaSysSettingsAuftragsartUpdatePage from './AndromedaSysSettingsAuftragsartUpdatePage';
import { isAdmin } from '../../../../../security/permissionChecks';
import URI_ANDROMEDA_SYS_SETTINGS_AUFTRAGSART_PAGE from '../../../../../features/AugtragsartTemplate/auftragsartTemplateUriPaths';

const andromedaSysSettingsAuftragsartUpdatePageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS_AUFTRAGSART_PAGE.updatePage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsAuftragsartUpdatePage />} />}
  />
);

export default andromedaSysSettingsAuftragsartUpdatePageRoute;
