import React from 'react';
import { Space } from 'antd';
import RechnungsAusstellerRepFondsTableActions from './RechnungsAusstellerRepFondsTableActions';
import KontoLinkWithNonRepFondsVerwendungBadge from './KontoLinkWithNonRepFondsVerwendungBadge';
import MitarbeiterTooltip from '../../../../../../components/Card/MitarbeiterTooltip';
import { TableWithColSelectorColumnProps } from '../../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { RepFondsErloesKontenZuweisung } from '../../../../../../types';
import KontoLinkToKontoblatt from '../../../../../../shared/components/Konto/KontoLinkToKontoblatt';
import FormattedDateTime from '../../../../../../components/FormattedDateTime/FormattedDateTime';

const rechnungsAusstellerRepFondsTableColumns = (
  rechtstraegerId: string,
  onSuccess: () => void,
  repFondsErloesKontenZuweisungList?: RepFondsErloesKontenZuweisung[]
): TableWithColSelectorColumnProps<RepFondsErloesKontenZuweisung>[] => [
  {
    title: 'Erlöskonto',
    defaultSelected: true,
    render: (text, record) => (
      <Space direction="vertical">
        {record.erloesKontoList.map((konto) => (
          <KontoLinkToKontoblatt key={konto.kontoId} konto={konto} />
        ))}
      </Space>
    ),
  },
  {
    title: 'Fibu-Bankkonto',
    defaultSelected: true,
    render: (text, record) => {
      const { fibuBankAccount } = record;
      const konto = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        kontoId: fibuBankAccount.fibuKontoId!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        nummer: fibuBankAccount.fibuKontonummer!,
        bezeichnung: fibuBankAccount.bankname,
        verwendung: fibuBankAccount.fibuVerwendung,
      };
      return <KontoLinkWithNonRepFondsVerwendungBadge konto={konto} maxBankNameLength={40} />;
    },
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    width: 80,
    render: (text, record) => (
      <RechnungsAusstellerRepFondsTableActions
        record={record}
        rechtstraegerId={rechtstraegerId}
        onSuccess={onSuccess}
        repFondsErloesKontenZuweisungList={repFondsErloesKontenZuweisungList}
      />
    ),
  },
];

export default rechnungsAusstellerRepFondsTableColumns;
