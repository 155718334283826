import { FC } from 'react';
import { Typography } from 'antd';
import { verarbeitungPageAndMenuListTitles } from '../verarbeitungHelpers';
import { Spacer } from '../../../components/Grid';
import VstKuerzungVerarbeitungEntryListing from './EntryListing/VstKuerzungVerarbeitungEntryListing';
import { VorsteuerkuerzungGenerierlauf } from '../../../types';

type VerarbeitungVorsteuerkuerzungProps = {
  generierlauf: VorsteuerkuerzungGenerierlauf;
};

const VerarbeitungVorsteuerkuerzung: FC<VerarbeitungVorsteuerkuerzungProps> = ({ generierlauf }) => (
  <>
    <Typography.Title level={4}>{verarbeitungPageAndMenuListTitles.VorsteuerkuerzungErstellung}</Typography.Title>
    <Spacer />
    <VstKuerzungVerarbeitungEntryListing generierlauf={generierlauf} />
  </>
);

export default VerarbeitungVorsteuerkuerzung;
