import React from 'react';
import { Link } from 'react-router-dom';
import { Tag, Tooltip } from 'antd';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { EuroAmount } from '../../../../../components/Number';
import { generatePathToMahnungDetailsPage } from '../../../../Mahnung/mahnungUriPaths';
import MultipleTexts from '../../../../../components/Text/MultipleTexts';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import GenerierlaufEntryStatus from '../../../shared/GenerierlaufEntryStatus';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { MitarbeiterTooltip } from '../../../../../components/Card';
import { MahnungErzeugenGenerierlaufEntry, MahnungGenerierlaufOrderBy } from '../../../../../types';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import RechtstraegerWithContactsTooltip from '../../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

const mahnungErstellenVerarbEntryTableColumns: TableWithColSelectorColumnProps<MahnungErzeugenGenerierlaufEntry>[] = [
  {
    title: 'Status',
    defaultSelected: true,
    dataIndex: MahnungGenerierlaufOrderBy.Status,
    sorter: true,
    render: (_, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },
  {
    title: 'Rechnungsaussteller',
    defaultSelected: true,
    dataIndex: MahnungGenerierlaufOrderBy.Rechnungsaussteller,
    sorter: true,
    render: (text, record) => <DataWithShortenedText maxTextLength={16} text={record.rechnungsAussteller.kurzBezeichnung} />,
  },
  {
    title: 'Objekt',
    defaultSelected: true,
    width: '90px',
    render(_, record) {
      const texts = record.mahnung?.objektList.map((objekt) => objekt.kurzBezeichnung).filter(Boolean) ?? [];
      return texts.length > 0 ? <MultipleTexts texts={texts as string[]} maxFirstTextLength={10} /> : <TextForEmptyValue textToShow="minus" />;
    },
  },
  {
    title: 'Vertragspartner',
    defaultSelected: true,
    dataIndex: MahnungGenerierlaufOrderBy.Vertragspartner,
    sorter: true,
    render: (text, record) => (
      <RechtstraegerWithContactsTooltip
        rechtstraegerId={record.vertragspartner.rechtstraegerId}
        rechtstraegerName={record.vertragspartner.kurzBezeichnung}
        status={record.vertragspartner.status}
        maxTextLength={25}
      />
    ),
  },
  {
    title: 'Rechnungsbetrag-Summe',
    defaultSelected: true,
    align: 'right',
    dataIndex: MahnungGenerierlaufOrderBy.RechnungsbetragSumme,
    sorter: true,
    render: (text, record) =>
      record.mahnung?.sumRechnungsbetrag ? <EuroAmount value={record.mahnung.sumRechnungsbetrag} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Mahnbetrag-Summe',
    defaultSelected: true,
    align: 'right',
    dataIndex: MahnungGenerierlaufOrderBy.MahnbetragSumme,
    sorter: true,
    render: (text, record) =>
      record.mahnung?.sumMahnbetrag ? <EuroAmount value={record.mahnung.sumMahnbetrag} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Mahnung-Status',
    defaultSelected: true,
    dataIndex: MahnungGenerierlaufOrderBy.MahnungStatus,
    sorter: true,
    render: (text, record) => {
      if (record.mahnungDeleted) {
        const date = dayjsCustom(record.mahnungDeletedTs).format('DD.MM.YYYY');
        const time = dayjsCustom(record.mahnungDeletedTs).format('HH:MM');
        return (
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          <Tooltip title={`Mahnung wurde am ${date} - ${time} von ${record.mahnungDeletedBy!} gelöscht`}>
            <Tag color="red">GELÖSCHT</Tag>
          </Tooltip>
        );
      }

      return record.mahnung ? <Tag>{record.mahnung.status.value}</Tag> : null;
    },
  },
  {
    title: 'Erstellt am',
    render: (text, record) => record.mahnung && <FormattedDateTime createdTs={record.mahnung.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) =>
      record.mahnung && (
        <MitarbeiterTooltip mitarbeiterId={record.mahnung.createdByMitarbeiterId} userId={record.mahnung.createdBy} alignment="left" showsInitials />
      ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) =>
      record.mahnung && record.mahnung.mahnungId ? (
        <Link to={generatePathToMahnungDetailsPage(record.mahnung.mahnungId)} target="_blank">
          Details
        </Link>
      ) : null,
  },
];

export default mahnungErstellenVerarbEntryTableColumns;
