import { v4 as uuid4 } from 'uuid';
import {
  AuszifferungListCreateInput,
  BookingSuggestion,
  BookingSuggestionBuchungszeile,
  BookingSuggestionUpdateInput,
  BuchungszeileInput,
  ClearingSuggestion,
  ClearingSuggestionInput,
  FibuBelegSymbolTuple,
  OffenePosten,
  PaymentBelegSymbol,
  PaymentBuchungsanweisung,
  PaymentBuchungsanweisungInput,
  PaymentBuchungType,
  SollHaben,
  SollHabenTuple,
} from '../../../../types';
import { FormFields } from '../../../../helpers/formikHelper';
import { ValuesDescriptions } from '../../../../components/Timeline/versionTimelineHelper';
import { isBuchungszeileKontoTypeSoll } from '../bookingSuggestionHelpers';
import { AuszifferungFormValues, mapOffenePostenAuszifferungListToFormValues } from '../AuszifferungBlock/auszifferungFormMapper';
import { mapFormattedDecimalOrThrowIfEmpty } from '../../../../components/Input-antd/formattedDecimalHelper';
import { calculateInitialAvailableAmount, isExistingAuszifferung } from '../AuszifferungBlock/bookingSuggestionAuszifferungBlockHelpers';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';

// form values
export type BookingSuggestionFormValues = {
  availableAmount: number; // = Verfügbarer Betrag
  buchungsanweisung: BSBuchungsanweisungFormValues;
  offenePostenFilter?: BSOffenePostenFilterFormValues;
  offenePostenForClearingSuggestionList: OffenePostenForClearingSuggestion[];
  offenePostenForPersonenkontoList: OffenePostenForClearingSuggestion[];
  // only for booking suggestion from booked buchung (on BookingDetails page)
  buchungsdatum?: string | null;
};

export type BSBuchungsanweisungFormValues = {
  belegSymbol: PaymentBelegSymbol;
  buchungType: PaymentBuchungType;
  buchungskreisId: string; // if buchungskreisRechtstraegerId is provided then buchungskreisId is also provided
  buchungskreisRechtstraegerId: string;
  buchungszeileList: BSBuchungszeileForTable[];
  // only for booking suggestion from booked buchung (on BookingDetails page)
  fibuBuchungId?: string | null;
};

export type OffenePostenForClearingSuggestion = {
  // # fields for clearing Suggestion table
  auszifferungList: AuszifferungFormValues[]; // values from clearingSuggestion and from added Auszifferung in Offene Posten table for Personenkonto
  belegDatum: string;
  belegFileId?: string | null;
  belegSymbol: FibuBelegSymbolTuple;
  belegnummer: string;
  buchungsdatum: string;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  dataCarrierBelegId?: string | null;
  dueDate: string;
  gesamtBetrag: number;
  iban?: string | null;
  offenePostenId: string;
  offenerBetrag: number;
  statementNumber?: string | null;
};

export type BSBuchungszeileFormValues = {
  amount: number;
  buchungszeileId: string;
  kontoId: string;
  text: string;
  deletable: boolean;
  editable: boolean;
};

export type BSBuchungszeileForTable = Omit<BookingSuggestionBuchungszeile, 'betrag' | 'buchungszeileId' | 'text' | 'deletable' | 'editable'> &
  BSBuchungszeileFormValues;

export type BSOffenePostenFilterFormValues = {
  belegnummer?: string | null;
  totalAmountFrom?: number | null;
  totalAmountTo?: number | null;
};

// form fields
export const bookingSuggestionFormFields: FormFields<BookingSuggestionFormValues> = {
  availableAmount: 'availableAmount',
  buchungsanweisung: 'buchungsanweisung',
  offenePostenFilter: 'offenePostenFilter',
  offenePostenForClearingSuggestionList: 'offenePostenForClearingSuggestionList',
  offenePostenForPersonenkontoList: 'offenePostenForPersonenkontoList',
  // only for booking suggestion from booked buchung (on BookingDetails page)
  buchungsdatum: 'buchungsdatum',
};

export const bSBuchungsanweisungFormFields: FormFields<BSBuchungsanweisungFormValues> = {
  belegSymbol: 'belegSymbol',
  buchungType: 'buchungType',
  buchungskreisId: 'buchungskreisId',
  buchungskreisRechtstraegerId: 'buchungskreisRechtstraegerId',
  buchungszeileList: 'buchungszeileList',
  // only for booking suggestion from booked buchung (on BookingDetails page)
  fibuBuchungId: 'fibuBuchungId',
};

export const bSBuchungszeileFormFields: FormFields<BSBuchungszeileFormValues> = {
  amount: 'amount',
  buchungszeileId: 'buchungszeileId',
  kontoId: 'kontoId',
  text: 'text',
  deletable: 'deletable',
  editable: 'editable',
};

export const bsOffenePostenForClearingSuggestionFormFields: FormFields<OffenePostenForClearingSuggestion> = {
  auszifferungList: 'auszifferungList',
  belegDatum: 'belegDatum',
  belegFileId: 'belegFileId',
  belegSymbol: 'belegSymbol',
  belegnummer: 'belegnummer',
  buchungsdatum: 'buchungsdatum',
  createTs: 'createTs',
  createdBy: 'createdBy',
  createdByMitarbeiterId: 'createdByMitarbeiterId',
  dataCarrierBelegId: 'dataCarrierBelegId',
  dueDate: 'dueDate',
  gesamtBetrag: 'gesamtBetrag',
  iban: 'iban',
  offenePostenId: 'offenePostenId',
  offenerBetrag: 'offenerBetrag',
  statementNumber: 'statementNumber',
};

export const bsOffenePostenForPersonenkontoFormFields: FormFields<OffenePostenForClearingSuggestion> = {
  auszifferungList: 'auszifferungList',
  belegDatum: 'belegDatum',
  belegFileId: 'belegFileId',
  belegSymbol: 'belegSymbol',
  belegnummer: 'belegnummer',
  buchungsdatum: 'buchungsdatum',
  createTs: 'createTs',
  createdBy: 'createdBy',
  createdByMitarbeiterId: 'createdByMitarbeiterId',
  dataCarrierBelegId: 'dataCarrierBelegId',
  dueDate: 'dueDate',
  gesamtBetrag: 'gesamtBetrag',
  iban: 'iban',
  offenePostenId: 'offenePostenId',
  offenerBetrag: 'offenerBetrag',
  statementNumber: 'statementNumber',
};

export const bSOffenePostenFilterFormFields: FormFields<BSOffenePostenFilterFormValues> = {
  belegnummer: 'belegnummer',
  totalAmountFrom: 'totalAmountFrom',
  totalAmountTo: 'totalAmountTo',
};

// initial values
export const mapBookingSuggestionToInitialFormValues = (
  bookingSuggestion: BookingSuggestion,
  offenePostenListForPersonenkonto: OffenePosten[]
): BookingSuggestionFormValues => ({
  availableAmount: calculateInitialAvailableAmount(bookingSuggestion),
  buchungsanweisung: mapBSBuchungsanweisungToFormValues(bookingSuggestion.buchungsanweisung),
  offenePostenForClearingSuggestionList: bookingSuggestion.clearingSuggestionList.map((clearingSuggestion) =>
    mapBSClearingSuggestionToOPForClearingSuggestion(clearingSuggestion)
  ),
  offenePostenForPersonenkontoList: mapOffenePostenAuszifferungListToFormValues(offenePostenListForPersonenkonto),
  buchungsdatum: dayjsCustom().format('YYYY-MM-DD'),
});

export const mapBSBuchungsanweisungToFormValues = (buchungsanweisung: PaymentBuchungsanweisung): BSBuchungsanweisungFormValues => ({
  belegSymbol: buchungsanweisung.belegSymbol.value,
  buchungskreisRechtstraegerId: buchungsanweisung.buchungskreisRechtstraeger?.rechtstraegerId ?? '',
  buchungskreisId: buchungsanweisung.buchungskreisRechtstraeger?.buchungskreisId ?? '',
  buchungszeileList: buchungsanweisung.buchungszeileList.map((buchungszeile) => mapBSBuchungszeileToFormValues(buchungszeile)),
  buchungType: buchungsanweisung.buchungType.value,
  // only for booking suggestion from booked buchung (on BookingDetails page)
  fibuBuchungId: buchungsanweisung.fibuBuchungId,
});

const mapBSBuchungszeileToFormValues = (buchungszeile: BookingSuggestionBuchungszeile): BSBuchungszeileForTable => ({
  ...buchungszeile,
  amount: buchungszeile.betrag,
  kontoId: buchungszeile.konto.kontoId,
});

export const mapBSClearingSuggestionToOPForClearingSuggestion = (clearingSuggestion: ClearingSuggestion): OffenePostenForClearingSuggestion => ({
  auszifferungList: [
    ...mapOffenePostenFromClearingSuggestionToAuszifferungFormValues(clearingSuggestion.offenePosten),
    mapClearingSuggestionToAuszifferungFormValues(clearingSuggestion),
  ],
  belegDatum: clearingSuggestion.offenePosten.belegDatum,
  belegFileId: clearingSuggestion.offenePosten.belegFileId ?? '',
  belegSymbol: clearingSuggestion.offenePosten.belegSymbol,
  belegnummer: clearingSuggestion.offenePosten.belegnummer,
  buchungsdatum: clearingSuggestion.offenePosten.buchungsdatum,
  createTs: clearingSuggestion.createTs,
  createdBy: clearingSuggestion.createdBy,
  createdByMitarbeiterId: clearingSuggestion.createdByMitarbeiterId,
  dataCarrierBelegId: clearingSuggestion.offenePosten.dataCarrierBelegId ?? '',
  dueDate: clearingSuggestion.dueDate,
  gesamtBetrag: clearingSuggestion.offenePosten.gesamtBetrag,
  iban: clearingSuggestion.offenePosten.iban ?? '',
  offenePostenId: clearingSuggestion.offenePostenId,
  offenerBetrag: clearingSuggestion.offenePosten.offenerBetrag,
  statementNumber: clearingSuggestion.offenePosten.statementNumber ?? '',
});

export const mapOPForPersonenkontoToOPForClearingSuggestion = (offenePosten: OffenePosten): OffenePostenForClearingSuggestion => ({
  auszifferungList: [...mapOffenePostenFromClearingSuggestionToAuszifferungFormValues(offenePosten)],
  belegDatum: offenePosten.belegDatum,
  belegFileId: offenePosten.belegFileId ?? '',
  belegSymbol: offenePosten.belegSymbol,
  belegnummer: offenePosten.belegnummer,
  buchungsdatum: offenePosten.buchungsdatum,
  createTs: offenePosten.createTs,
  createdBy: offenePosten.createdBy,
  createdByMitarbeiterId: offenePosten.createdByMitarbeiterId,
  dataCarrierBelegId: offenePosten.dataCarrierBelegId ?? '',
  dueDate: offenePosten.dueDate,
  gesamtBetrag: offenePosten.gesamtBetrag,
  iban: offenePosten.iban ?? '',
  offenePostenId: offenePosten.offenePostenId,
  offenerBetrag: offenePosten.offenerBetrag,
  statementNumber: offenePosten.statementNumber ?? '',
});

export const mapClearingSuggestionToAuszifferungFormValues = (clearingSuggestion: ClearingSuggestion): AuszifferungFormValues => {
  return {
    amount: clearingSuggestion.amount,
    auszifferungsDatum: undefined,
    auszifferungId: uuid4(),
    clearingSuggestionId: clearingSuggestion.clearingSuggestionId,
    createTs: clearingSuggestion.createTs,
    createdBy: clearingSuggestion.createdBy,
    createdByMitarbeiterId: clearingSuggestion.createdByMitarbeiterId,
    offenePostenId: clearingSuggestion.offenePostenId,
    storniert: false,
  };
};

export const mapOffenePostenFromClearingSuggestionToAuszifferungFormValues = (
  offenePosten: OffenePosten,
  clearingSuggestionId?: string
): AuszifferungFormValues[] => {
  return offenePosten.auszifferungList.map((auszifferung) => ({
    amount: auszifferung.betrag,
    auszifferungId: auszifferung.auszifferungId,
    auszifferungsDatum: auszifferung.auszifferungsDatum,
    buchungId: auszifferung.buchungId,
    createTs: auszifferung.createTs,
    createdBy: auszifferung.createdBy,
    createdByMitarbeiterId: auszifferung.createdByMitarbeiterId,
    offenePostenId: offenePosten.offenePostenId,
    clearingSuggestionId,
    source: auszifferung.source,
    storniert: auszifferung.storniert,
    storniertByMitarbeiterId: auszifferung.storniertByMitarbeiterId,
    storniertTs: auszifferung.storniertTs,
    zahlungId: auszifferung.zahlungId,
  }));
};

// map form values to booking suggestion update input
export const mapFormValuesToBookingSuggestionUpdateInput = (values: BookingSuggestionFormValues): BookingSuggestionUpdateInput => ({
  buchungsanweisung: mapBSBuchungsanweisungFormValuesToBuchungsanweisungInput(values.buchungsanweisung),
  clearingSuggestionList:
    values.offenePostenForClearingSuggestionList
      .map((op) =>
        op.auszifferungList
          .filter((auszifferung) => !isExistingAuszifferung(auszifferung) && auszifferung.amount !== 0)
          .map(mapBSClearingSuggestionFormValuesToClearingSuggestionInput)
      )
      .flat() ?? [],
  // only for booking suggestion from booked buchung (on BookingDetails page)
  buchungsdatum: values.buchungsdatum,
});

export const mapClearingSuggestionListFormValuesToAuszifferungListCreateInput = (
  clearingSuggestionList: ClearingSuggestionInput[]
): AuszifferungListCreateInput => ({
  auszifferungList: clearingSuggestionList.map((values) => ({
    betrag: mapFormattedDecimalOrThrowIfEmpty(values.amount),
    offenePostenId: values.offenePostenId,
  })),
});

export const mapBSBuchungsanweisungFormValuesToBuchungsanweisungInput = (values: BSBuchungsanweisungFormValues): PaymentBuchungsanweisungInput => ({
  belegSymbol: values.belegSymbol,
  buchungskreisRechtstraegerId: values.buchungskreisRechtstraegerId,
  buchungszeileList: values.buchungszeileList?.filter(isBankBuchungszeilEditable).map(mapBSBuchungszeileFormValuesToBuchungszeileInput) ?? [],
  // only for booking suggestion from booked buchung (on BookingDetails page)
  fibuBuchungId: values.fibuBuchungId,
});

const isBankBuchungszeilEditable = (buchungszeile: BSBuchungszeileForTable) => buchungszeile.editable;

export const mapBSBuchungszeileFormValuesToBuchungszeileInput = (values: BSBuchungszeileFormValues): BuchungszeileInput => ({
  amount: values.amount,
  buchungszeileId: values.buchungszeileId ?? null,
  kontoId: values.kontoId,
  text: values.text,
});

const mapBSClearingSuggestionFormValuesToClearingSuggestionInput = (values: AuszifferungFormValues): ClearingSuggestionInput => ({
  amount: mapFormattedDecimalOrThrowIfEmpty(values.amount),
  clearingSuggestionId: values.clearingSuggestionId,
  offenePostenId: values.offenePostenId,
});

// add new buchungszeile
export const createNewBSBuchungszeileFormValues = (
  buchungszeileOriginal: BookingSuggestionBuchungszeile,
  hasBuchungszeileListOnlyOneElement: boolean
): BSBuchungszeileFormValues => ({
  ...{
    ...buchungszeileOriginal,
    konto: null,
    betrag: 0,
    // sollHaben for the new row should always be the opposite of the original row
    sollHaben: getSollHabenForNewRow(buchungszeileOriginal.sollHaben.value),
  },
  amount: hasBuchungszeileListOnlyOneElement ? buchungszeileOriginal.betrag : 0,
  buchungszeileId: '',
  kontoId: '',
  text: buchungszeileOriginal.text,
  deletable: true,
  editable: true,
});

const getSollHabenForNewRow = (sollHaben: SollHaben): SollHabenTuple => {
  return isBuchungszeileKontoTypeSoll(sollHaben)
    ? {
        text: 'Haben',
        value: SollHaben.Haben,
      }
    : {
        text: 'Soll',
        value: SollHaben.Soll,
      };
};

// labels for form fields
export const BSBuchungsanweisungFormValuesDescriptions: ValuesDescriptions<
  Omit<BSBuchungsanweisungFormValues, 'buchungskreisRechtstraegerId' | 'fibuBuchungId'>
> = {
  belegSymbol: 'Belegsymbol',
  buchungskreisId: 'Buchungskreis',
  buchungszeileList: 'Buchungszeilen',
  buchungType: 'Buchungstyp',
};
