import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import TabPaneContent from '../../../components/Tabs/TabPaneContent';
import { Objekt } from '../../../types';
import ObjektTicketListing from './ObjektTicketListing';

type Props = {
  objekt: Objekt;
};

const ObjektTicketTab: FC<Props> = ({ objekt }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Aufgaben',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <ObjektTicketListing objektId={objekt.objektId} />
        </TabPaneContent>
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default ObjektTicketTab;
