import styled from 'styled-components';
import { Space } from 'antd';

export const StyledOperatingSiteSpace = styled(Space)`
  width: 100%;
  align-items: baseline;
  > .ant-space-item {
    width: 100%;
  }
`;
