import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Row, Typography } from 'antd';
import { MahnungMailDelivery } from '../../../types';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { generatePathToEmailVersandDetailsPage } from '../../EmailDelivery/emailDeliveryUriPaths';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';

type Props = {
  email: string;
  mailDelivery: MahnungMailDelivery | null | undefined;
};

const MahnungMailDeliveryInfoRow: FC<Props> = ({ email, mailDelivery }) => {
  return (
    <CardDetailsInfoRow infoRowTitle="Zustell-E-Mail-Adresse">
      <Row>
        <DataWithShortenedText maxTextLength={25} text={email}>
          {(shortenedText) => <Typography.Text>{shortenedText}</Typography.Text>}
        </DataWithShortenedText>
      </Row>
      {mailDelivery && (
        <Row>
          {mailDelivery.mailId ? (
            <Link style={{ fontStyle: 'italic' }} to={generatePathToEmailVersandDetailsPage(mailDelivery.mailId)}>
              ({mailDelivery.status.text})
            </Link>
          ) : (
            <Typography.Text type="secondary" style={{ fontStyle: 'italic' }}>
              ({mailDelivery.status.text})
            </Typography.Text>
          )}
        </Row>
      )}
    </CardDetailsInfoRow>
  );
};

export default MahnungMailDeliveryInfoRow;
