import React, { FC } from 'react';
import { Formik } from 'formik';
import { DatePicker, Form } from 'formik-antd';
import FormButtons from '../../../../../components/Button/FormButtons';
import {
  sepaMandatActivateFormInitialValues,
  sepaMandatActivateOrUpdateFormFields,
  SepaMandatActivateOrUpdateFormValues,
  sepaMandatUpdateFormInitialValues,
} from './sepaMandatActivateOrUpdateFormMapper';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../../components/DatePicker/DatePicker_formikAntD';
import { useFDAuthorized } from '../../../../../shared/Auth/useAuthorized';
import { handleActivateSubmit, handleUpdateSubmit } from './handleSubmit';
import { sepaMandatActivateFormValidationSchema, sepaMandatUpdateFormValidationSchema } from './sepaMandatActivateOrUpdateFormValidationSchema';
import FileUploadView from '../../../../../components/FileUpload/FileUploadView';
import { SepaMandat } from '../../../../../types';
import SepaMandatFileSelect from './SepaMandatFileSelect';
import { useGetAppFieldHelpText } from '../../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  isUpdate: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  rechtstraegerId: string;
  bankDetailsId: string;
  sepaMandat: SepaMandat;
};

const SepaMandatActivateOrUpdateForm: FC<Props> = ({ isUpdate, onCancel, onSuccess, rechtstraegerId, bankDetailsId, sepaMandat }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'SepaMandat'>('SepaMandat');

  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  return (
    <Formik<SepaMandatActivateOrUpdateFormValues>
      initialValues={isUpdate ? sepaMandatUpdateFormInitialValues(sepaMandat) : sepaMandatActivateFormInitialValues}
      onSubmit={(values, { setSubmitting }) => {
        if (isUpdate) {
          handleUpdateSubmit(firmendatenId, rechtstraegerId, bankDetailsId, sepaMandat.sepaMandatId, values, setSubmitting, onSuccess);
        } else {
          handleActivateSubmit(firmendatenId, rechtstraegerId, bankDetailsId, sepaMandat.sepaMandatId, values, setSubmitting, onSuccess);
        }
      }}
      validationSchema={isUpdate ? sepaMandatUpdateFormValidationSchema : sepaMandatActivateFormValidationSchema}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithFieldHelp
            name={sepaMandatActivateOrUpdateFormFields.signatureDate}
            label="SEPA Mandat-Unterschriftsdatum"
            fieldHelp={getFieldHelpText('SepaMandat.signatureDate')}
          >
            <DatePicker
              size="small"
              id={sepaMandatActivateOrUpdateFormFields.signatureDate}
              name={sepaMandatActivateOrUpdateFormFields.signatureDate}
              style={{ width: '100%' }}
              format={DATE_DISPLAY_FORMAT_DEFAULT}
              placeholder="Datum auswählen"
            />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={sepaMandatActivateOrUpdateFormFields.signatureFile}
            label="Unterschriebener SEPA Mandat"
            fieldHelp={getFieldHelpText('SepaMandat.signatureFileId')}
          >
            {formikProps.values.signatureFile ? (
              <FileUploadView
                filename={formikProps.values.signatureFile.filename}
                onDelete={() => formikProps.setFieldValue(sepaMandatActivateOrUpdateFormFields.signatureFile, null)}
              />
            ) : (
              <SepaMandatFileSelect formikProps={formikProps} />
            )}
          </FormItemWithFieldHelp>
          <FormButtons
            onCancel={onCancel}
            isOkLoading={formikProps.isSubmitting}
            okText={isUpdate ? 'Aktualisieren' : 'Aktivieren'}
            updateMode={false}
          />
        </Form>
      )}
    </Formik>
  );
};

export default SepaMandatActivateOrUpdateForm;
