import React, { FC } from 'react';
import { StyledListSummarySpace } from '../../shared/ListSummary/styled/StyledListSummarySpace';
import { SummaryText } from '../../shared/ListSummary/SummaryText';
import { LoadingCol } from '../../../components/Loading';
import { IncomingInvoiceListSummary } from '../../../types';

type Props = {
  summary?: IncomingInvoiceListSummary;
};

const ListSummary: FC<Props> = ({ summary }) => {
  return (
    <>
      {summary ? (
        <StyledListSummarySpace>
          <SummaryText text="Netto" value={summary.netto} />
          <SummaryText text="USt." value={summary.vat} />
          <SummaryText text="Brutto" value={summary.brutto} />
        </StyledListSummarySpace>
      ) : (
        <LoadingCol isLoading />
      )}
    </>
  );
};

export default ListSummary;
