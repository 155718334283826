import React, { FC } from 'react';
import { Drawer, MenuProps, Modal } from 'antd';
import { ArchiveIcon } from '@radix-ui/react-icons';
import { EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { isStatusActive } from '../../../../helpers/statusHelper';
import { useToggle } from '../../../../hooks/useToggle';
import ObjektAufteilungsschluesselForm from '../Form/ObjektAufteilungsschluesselForm';
import { showSuccessMsgArchived, showSuccessMsgUnarchived } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import {
  useArchiveObjektAufteilungsschluesselMutation,
  useUnarchiveObjektAufteilungsschluesselMutation,
} from '../gql/ObjektAufteilungsschluesselMutations.types';
import { radixActionStyles } from '../../../../helpers/radixIconsStyles';
import { ObjektAufteilungsschluessel } from '../../../../types';
import HistoryModal, { HistoryType } from '../../../History/shared/HistoryModal';
import ObjektAufteliungsschluesselChangeHistoryListingTable from './ObjektAufteliungsschluesselChangeHistoryListingTable';
import ActionDropdown from '../../../../components/Dropdown/ActionDropdown';

type ObjektAufteilungsschluesselActionsProps = {
  objektId: string;
  aufteilungsschluessel: ObjektAufteilungsschluessel;
  onAction: () => void;
  isWegObjekt: boolean;
};

const ObjektAufteilungsschluesselTableActions: FC<ObjektAufteilungsschluesselActionsProps> = ({
  objektId,
  aufteilungsschluessel,
  onAction,
  isWegObjekt,
}) => {
  const [isCollapsed, onCollapse] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const entity = `ObjektAufteilungsschluessel`;

  const [runArchivieren] = useArchiveObjektAufteilungsschluesselMutation({
    onCompleted: () => {
      showSuccessMsgArchived(entity);
      onAction();
    },
    variables: {
      objektId,
      objektAufteilungsschluesselId: aufteilungsschluessel.objektAufteilungsschluesselId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runReaktivieren] = useUnarchiveObjektAufteilungsschluesselMutation({
    onCompleted: () => {
      showSuccessMsgUnarchived(entity);
      onAction();
    },
    variables: {
      objektId,
      objektAufteilungsschluesselId: aufteilungsschluessel.objektAufteilungsschluesselId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Kurzbezeichnung bearbeiten',
      onClick: onCollapse,
      icon: <EditOutlined />,
      disabled: !isStatusActive(aufteilungsschluessel.objektAufteilungsschluesselStatus),
    },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '3',
      label: !isStatusActive(aufteilungsschluessel.objektAufteilungsschluesselStatus) ? 'Reaktivieren' : 'Archivieren',
      onClick: showConfirmArchivierenReaktivieren(runReaktivieren, runArchivieren, aufteilungsschluessel),
      icon: <ArchiveIcon style={radixActionStyles} />,
    },
  ];

  return (
    <>
      <ActionDropdown items={items} />
      <Drawer title="Aufteilungsschlüssel bearbeiten" width={720} open={isCollapsed} onClose={onCollapse} destroyOnClose>
        <ObjektAufteilungsschluesselForm
          objektId={objektId}
          aufteilungsschluesselId={aufteilungsschluessel.aufteilungsschluesselId}
          onSubmit={() => {
            onCollapse();
            onAction();
          }}
          onCancel={onCollapse}
          isWegObjekt={isWegObjekt}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <ObjektAufteliungsschluesselChangeHistoryListingTable
          objektId={objektId}
          objektAufteilungsschluesselId={aufteilungsschluessel.objektAufteilungsschluesselId}
        />
      </HistoryModal>
    </>
  );
};

const showConfirmArchivierenReaktivieren =
  (runReaktivieren: () => void, runArchivieren: () => void, vorschreibungsPosition: ObjektAufteilungsschluessel) => () => {
    Modal.confirm({
      title: !isStatusActive(vorschreibungsPosition.objektAufteilungsschluesselStatus)
        ? `Möchten Sie die ObjektAufteilungschluessel reaktivieren?`
        : `Möchten Sie die ObjektAufteilungschluessel archivieren?`,
      content: `${vorschreibungsPosition.bezeichnung}`,
      okText: !isStatusActive(vorschreibungsPosition.objektAufteilungsschluesselStatus) ? 'Reaktivieren' : 'Archivieren',
      cancelText: 'Nein',
      onOk() {
        if (!isStatusActive(vorschreibungsPosition.objektAufteilungsschluesselStatus)) {
          return runReaktivieren();
        } else {
          return runArchivieren();
        }
      },
    });
  };

export default ObjektAufteilungsschluesselTableActions;
