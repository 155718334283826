import React, { FC } from 'react';
import { showSuccessMsgDelete } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import VersionTimeline from '../../../../components/Timeline/VersionTimeline';
import { mapToVersionTimelineDataSourceWithAlwaysDeletableEntries } from '../../../../components/Timeline/versionTimelineHelper';
import { useLeerstehungAufwandskontoListQuery } from '../../gql/FirmendatenBuchhaltungEinstellungQueries.types';
import { useDeleteLeerstehungAufwandskontoMutation } from '../../gql/FirmendatenBuchhaltungEinstellungMutations.types';
import LeerstehungAufwandskontoVersionCardContent from './LeerstehungAufwandskontoVersionCardContent';
import LeerstehungAufwandskontoVersionForm from './Form/LeerstehungAufwandskontoVersionForm';
import { FirmendatenBuchhaltungEinstellung, FirmendatenLeerstehungAufwandskonto } from '../../../../types';

type Props = {
  onSuccess: () => void;
  leerstehungAufwandskonto?: FirmendatenBuchhaltungEinstellung;
};

const LeerstehungAufwandskontoVersionTimeline: FC<Props> = ({ onSuccess }) => {
  const { data, loading, refetch } = useLeerstehungAufwandskontoListQuery();

  const onActionSuccess = () => {
    onSuccess();
    refetch();
  };

  const [runDelete] = useDeleteLeerstehungAufwandskontoMutation({
    onCompleted: () => {
      showSuccessMsgDelete(`Leerstehung Aufwandskonto`);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const leerstehungAufwandskontoList = data?.getLeerstehungAufwandskontoList.data ?? [];

  return (
    <VersionTimeline<FirmendatenLeerstehungAufwandskonto>
      dataSource={mapToVersionTimelineDataSourceWithAlwaysDeletableEntries(leerstehungAufwandskontoList)}
      isAllowedToDeleteInitialTimeblock
      loading={loading}
      versionIdKey="firmendatenLeerstehungAufwandskontoId"
      defaultNewTimeblock={{
        firmendatenLeerstehungAufwandskontoId: '',
        leerstehungAufwandskontoId: '',
      }}
      renderForm={(timeblock) => <LeerstehungAufwandskontoVersionForm timeblock={timeblock} onSubmitSuccess={onActionSuccess} />}
      renderCardContent={(timeblock) => <LeerstehungAufwandskontoVersionCardContent timeblock={timeblock} />}
      kommentarForCardTitle={(timeblock) => timeblock.kommentar}
      onDelete={(timeblock) => {
        return runDelete({
          variables: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            firmendatenLeerstehungAufwandskontoId: timeblock.firmendatenLeerstehungAufwandskontoId,
          },
        });
      }}
    />
  );
};

export default LeerstehungAufwandskontoVersionTimeline;
