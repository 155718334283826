import React, { FC } from 'react';
import { Empty, Table } from 'antd';
import { Verwaltungsart, VorsteuerkuerzungEinstellung, VorsteuerkuerzungKontenZuweisung } from '../../../../../types';
import vstKuerzungKontenZuweisungMieteTableColumns from './vstKuerzungKontenZuweisungMieteTableColumns';
import vstKuerzungKontenZuweisungWeTableColumns from './vstKuerzungKontenZuweisungWeTableColumns';
import { isWegObjekt } from '../../../../Objekt/objektHelper';

type Props = {
  verwaltungsart: Verwaltungsart;
  vorsteuerkuerzung: VorsteuerkuerzungEinstellung;
  isInCardContent?: boolean;
};

const VstKuerzungKontenZuweisungTable: FC<Props> = ({ verwaltungsart, vorsteuerkuerzung, isInCardContent = false }) => (
  <Table<VorsteuerkuerzungKontenZuweisung>
    dataSource={vorsteuerkuerzung.kontenZuweisungList}
    columns={
      isWegObjekt(verwaltungsart)
        ? vstKuerzungKontenZuweisungWeTableColumns(isInCardContent, verwaltungsart)
        : vstKuerzungKontenZuweisungMieteTableColumns(isInCardContent, verwaltungsart)
    }
    locale={{
      emptyText: <Empty description={<span>Keine Vorsteuerkürzungen vorhanden</span>} />,
    }}
    pagination={false}
    size={isInCardContent ? 'small' : 'large'}
  />
);

export default VstKuerzungKontenZuweisungTable;
