export const calculateUstBasedOnNetto = (netto: number, steuersatz: number) => {
  assertNumberIsFinite(netto, 'netto');
  assertNumberIsFiniteAndGreaterEqual0(steuersatz, 'steuersatz');
  const nettoRounded = Math.round(netto * 100) / 100;
  const steuersatzPercent = steuersatz / 100;
  const ust = nettoRounded * steuersatzPercent;
  return Math.round(ust * 100) / 100;
};

export const calculateUstBasedOnBrutto = (brutto: number, steuersatz: number) => {
  assertNumberIsFinite(brutto, 'brutto');
  assertNumberIsFiniteAndGreaterEqual0(steuersatz, 'steuersatz');
  const bruttoRounded = Math.round(brutto * 100) / 100;
  const bruttoPercent = 100 + steuersatz;
  const ust = (bruttoRounded * steuersatz) / bruttoPercent;
  return Math.round(ust * 100) / 100;
};

export const calculateBrutto = (netto: number, ust: number) => {
  assertNumberIsFinite(netto, 'netto');
  assertNumberIsFinite(ust, 'ust');
  return Math.round((netto + ust) * 100) / 100;
};

export const calculateNetto = (brutto: number, ust: number) => {
  assertNumberIsFinite(brutto, 'brutto');
  assertNumberIsFinite(ust, 'ust');
  return Math.round((brutto - ust) * 100) / 100;
};

const assertNumberIsFiniteAndGreaterEqual0 = (number: number, numberParamName: string) => {
  if (!isFiniteAndGreaterEqual0(number)) {
    throw new Error(`${numberParamName} must be a not negative number: ${number}`);
  }
};

const assertNumberIsFinite = (number: number, numberParamName: string) => {
  if (!isFinite(number)) {
    throw new Error(`${numberParamName} must be a finite number: ${number}`);
  }
};
const isFiniteAndGreaterEqual0 = (number: any) => isFinite(number) && number >= 0;
