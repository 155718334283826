import React from 'react';
import { Route } from 'react-router';
import URI_SYS_SETTINGS_MAHNDEFINITION_PAGE from '../../../../../features/Mahndefinition/mahndefinitonUriPaths';
import SysSettingsMahndefinitionUpdatePage from './SysSettingsMahndefinitionUpdatePage';
import AuthRoute from '../../../../../security/AuthRoute';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../../security/permissionChecks';

export type MahndefinitionUpdatePageRouteParams = { mahndefinitionId: string };

const sysSettingsMahndefinitionUpdatePageRoute = (
  <Route
    path={URI_SYS_SETTINGS_MAHNDEFINITION_PAGE.updatePage}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsMahndefinitionUpdatePage />} />}
  />
);

export default sysSettingsMahndefinitionUpdatePageRoute;
