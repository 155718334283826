import React, { FC, useState } from 'react';
import { Col, Radio, Row, Typography } from 'antd';
import StreetAddressCreateForm from './StreetAddress/Create/StreetAddressCreateForm';
import PostofficeBoxAddressCreateForm from './PostofficeBoxAddress/Create/PostofficeBoxAddressCreateForm';
import EmailContactCreateForm from './EmailContact/Create/EmailContactCreateForm';
import PhoneContactCreateForm from './PhoneContact/Create/PhoneContactCreateForm';
import { RechtstraegerType } from '../../../../types';
import { Spacer } from '../../../../components/Grid';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  rechtstraegerType: RechtstraegerType;
  rechtstraegerId?: string;
  operatingSiteId?: string;
};

enum AddressesAndContactsType {
  StreetAddress = 'STREET_ADDRESS',
  PostofficeBoxAddress = 'POSTOFFICE_BOX_ADDRESS',
  Email = 'EMAIL',
  Telefon = 'TELEFON',
}

const AddressesAndContactsCreateForm: FC<Props> = ({ rechtstraegerId, onCancel, onSuccess, rechtstraegerType, operatingSiteId }) => {
  const [type, setType] = useState<AddressesAndContactsType>();

  const typesMap = {
    [AddressesAndContactsType.StreetAddress]: StreetAddressCreateForm,
    [AddressesAndContactsType.PostofficeBoxAddress]: PostofficeBoxAddressCreateForm,
    [AddressesAndContactsType.Email]: EmailContactCreateForm,
    [AddressesAndContactsType.Telefon]: PhoneContactCreateForm,
  };

  const TypeForm = type && typesMap[type];

  const content = TypeForm ? (
    <TypeForm
      rechtstraegerId={rechtstraegerId}
      onSuccess={onSuccess}
      onCancel={onCancel}
      rechtstraegerType={rechtstraegerType}
      operatingSiteId={operatingSiteId}
    />
  ) : null;

  return (
    <>
      <Row align="top">
        <Col span={6} />
        <Col span={18}>
          <Radio.Group buttonStyle="solid" onChange={(event) => setType(event.target.value)} block>
            <Radio.Button value={AddressesAndContactsType.StreetAddress}>Strassenadresse</Radio.Button>
            <Radio.Button value={AddressesAndContactsType.Email}>E-Mail</Radio.Button>
            <Radio.Button value={AddressesAndContactsType.Telefon}>Telefon</Radio.Button>
            <Radio.Button value={AddressesAndContactsType.PostofficeBoxAddress}>Postfachadresse</Radio.Button>
          </Radio.Group>
          <Spacer height={16} />
          {!type && <Typography.Title level={5}>Bitte wählen Sie eine Adress- oder Kontaktart aus.</Typography.Title>}
        </Col>
      </Row>
      <Spacer height={16} />
      {content}
    </>
  );
};

export default AddressesAndContactsCreateForm;
