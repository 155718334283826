import { generatePath } from 'react-router-dom';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../constants/andromedaSystemSettingsUriPaths';
import { IndexType } from '../../types';
import { mapToQueryString } from '../../hooks/useStringQueryParams';

export const URI_ANDROMEDA_SYS_SETTINGS_INDEX_SERIES_DETAILS_PAGE = `${URI_ANDROMEDA_SYS_SETTINGS.indexSeries}/:indexSeriesId/`;

export const generatePathToAndromedaSystemSettingsIndexSeriesDetailPage = (indexSeriesId: string): string =>
  generatePath(URI_ANDROMEDA_SYS_SETTINGS_INDEX_SERIES_DETAILS_PAGE, {
    indexSeriesId,
  });

export enum IndexSeriesQueryParams {
  TYP = 'typ',
}

export type TIndexSeriesQueryParams = {
  typ?: IndexType;
};

export const updateAndromedaSystemSettingsIndexSeriesQueryParams = (navigate: (path: string) => void, filters: TIndexSeriesQueryParams) =>
  navigate(generatePathToAndromedaSystemSettingsIndexSeriesPage(filters.typ));

export const generatePathToAndromedaSystemSettingsIndexSeriesPage = (typ?: IndexType) =>
  `${generatePath(URI_ANDROMEDA_SYS_SETTINGS.indexSeries)}?${mapToQueryString({
    [IndexSeriesQueryParams.TYP]: typ,
  })}`;
