import React from 'react';
import { Skeleton } from 'antd';
import AbrDefCreateForm from '../../../../../features/Abrechnungsdefinition/Form/Create/AbrDefCreateForm';
import { useAbrechnungsdefinitionTemplateQuery } from '../../../../../features/Abrechnungsdefinition/gql/AbrDefQueries.types';
import { AbrechnungsdefinitionTemplateType } from '../../../../../types';

const SysSettingsAbrechnungsdefinitionCreatePage = () => {
  const { data: templateObjektData, loading: loadingTemplateObjektAbr } = useAbrechnungsdefinitionTemplateQuery({
    variables: { type: AbrechnungsdefinitionTemplateType.ObjektAbrechnung },
  });
  const objektAbrechnungTextbausteinList = templateObjektData?.getAbrechnungsdefinitionTemplate.data.textbausteinList ?? [];

  const { data: templateTopData, loading: loadingTemplateTopAbr } = useAbrechnungsdefinitionTemplateQuery({
    variables: { type: AbrechnungsdefinitionTemplateType.TopAbrechnung },
  });
  const topAbrechnungTextbausteinList = templateTopData?.getAbrechnungsdefinitionTemplate.data.textbausteinList ?? [];

  const { data: templateHeData, loading: loadingTemplateHeAbr } = useAbrechnungsdefinitionTemplateQuery({
    variables: { type: AbrechnungsdefinitionTemplateType.HeAbrechnung },
  });
  const heAbrechnungTextbausteinList = templateHeData?.getAbrechnungsdefinitionTemplate.data.textbausteinList ?? [];

  const { data: templateSubData, loading: loadingTemplateSubAbr } = useAbrechnungsdefinitionTemplateQuery({
    variables: { type: AbrechnungsdefinitionTemplateType.SubAbrechnung },
  });
  const subAbrechnungTextbausteinList = templateSubData?.getAbrechnungsdefinitionTemplate.data.textbausteinList ?? [];

  if (loadingTemplateObjektAbr || loadingTemplateTopAbr || loadingTemplateHeAbr || loadingTemplateSubAbr) return <Skeleton active />;

  return (
    <AbrDefCreateForm
      objektAbrechnungTextbausteinList={objektAbrechnungTextbausteinList}
      topAbrechnungTextbausteinList={topAbrechnungTextbausteinList}
      heAbrechnungTextbausteinList={heAbrechnungTextbausteinList}
      subAbrechnungTextbausteinList={subAbrechnungTextbausteinList}
    />
  );
};

export default SysSettingsAbrechnungsdefinitionCreatePage;
