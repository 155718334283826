import { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  mapFormValuesToTopAbrVerarbeitungFilters,
  TopAbrVerarbeitungEntryTableFormValues,
  topAbrVerarbEntryListingFiltersFormInitialValues,
} from './Filters/ListingFiltersFormMapper';
import ListingFilters from './Filters/ListingFilters';
import TopAbrVerarbeitungEntryTable from './TopAbrVerarbeitungEntryTable';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { TTopAbrVerarbeitungQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import { AppGenerierlaufType, TopAbrechnungGenerierlauf } from '../../../../types';

type Props = {
  generierlauf: TopAbrechnungGenerierlauf;
  type: string;
  generierlaufType: AppGenerierlaufType;
};

const TopAbrVerarbeitungEntryListing: FC<Props> = ({ generierlauf, type, generierlaufType }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TTopAbrVerarbeitungQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(type, generierlauf.generierlaufId, navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: TopAbrVerarbeitungEntryTableFormValues) => {
    updateQueryParams(type, generierlauf.generierlaufId, navigate, mapFormValuesToTopAbrVerarbeitungFilters(values), paginationParams);
  };

  return (
    <>
      <Formik<TopAbrVerarbeitungEntryTableFormValues>
        initialValues={topAbrVerarbEntryListingFiltersFormInitialValues(queryParams)}
        onSubmit={onChange}
      >
        {(formikProps) => (
          <ListingFilters formikProps={formikProps} generierlaufId={generierlauf.generierlaufId} generierlaufType={generierlaufType} />
        )}
      </Formik>
      <TopAbrVerarbeitungEntryTable queryParams={queryParams} generierlauf={generierlauf} type={type} />
    </>
  );
};

export default TopAbrVerarbeitungEntryListing;
