import React, { FC } from 'react';
import { Skeleton } from 'antd';
import HeAbrDefTemplateVersionUpdateForm from '../../../../../../features/AbrechnungsdefinitionTemplate/Version/Form/Update/HeAbrDefTemplateVersionUpdateForm';
import {
  useHeAbrechnungsdefinitionTemplateVersionListQuery,
  useHeAbrechnungsdefinitionTemplateVersionQuery,
} from '../../../../../../features/AbrechnungsdefinitionTemplate/gql/HeAbrDef/HeAbrDefTemplateVersionQueries.types';

type Props = {
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersionId: string;
};

const AndromedaSysSettingsHeAbrechnungsdefinitionVersionUpdatePage: FC<Props> = ({ abrechnungsdefinitionId, abrechnungsdefinitionVersionId }) => {
  const { data: heAbrDefVersionListData } = useHeAbrechnungsdefinitionTemplateVersionListQuery({
    variables: { abrechnungsdefinitionId },
  });
  const abrechnungsdefinitionVersionList = heAbrDefVersionListData?.getHeAbrechnungsdefinitionTemplateVersionList.data;

  const { data: heAbrDefVersion, refetch } = useHeAbrechnungsdefinitionTemplateVersionQuery({
    variables: { abrechnungsdefinitionId, abrechnungsdefinitionVersionId },
  });
  const abrechnungsdefinitionVersion = heAbrDefVersion?.getHeAbrechnungsdefinitionTemplateVersion.data;

  if (!abrechnungsdefinitionVersion || !abrechnungsdefinitionVersionList) return <Skeleton active />;

  return (
    <HeAbrDefTemplateVersionUpdateForm
      abrechnungsdefinitionVersionList={abrechnungsdefinitionVersionList}
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      abrechnungsdefinitionVersion={abrechnungsdefinitionVersion}
      validFrom={abrechnungsdefinitionVersion.validFrom}
      kommentar={abrechnungsdefinitionVersion.kommentar ?? ''}
      onSuccess={refetch}
    />
  );
};

export default AndromedaSysSettingsHeAbrechnungsdefinitionVersionUpdatePage;
