import { Select, SelectProps } from 'formik-antd';
import React, { FC } from 'react';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = {
  rechtstraegerList: VerarbeitungRechtstraegerSelectProps[];
  width?: string;
} & SelectProps;

export interface VerarbeitungRechtstraegerSelectProps {
  rechtstraegerId: string;
  kurzBezeichnung: string;
}

const VerarbeitungRechtstraegerFormSelect: FC<Props> = ({ rechtstraegerList, name, width, ...restProps }) => {
  return (
    <Select
      size="small"
      placeholder="Firma/Person auswählen"
      name={name}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      style={{ width: width || '100%' }}
      {...restProps}
    >
      {rechtstraegerList.map((rechtstraeger) => (
        <Select.Option key={rechtstraeger.rechtstraegerId} value={rechtstraeger.rechtstraegerId}>
          <DataWithShortenedText maxTextLength={25} text={rechtstraeger.kurzBezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default VerarbeitungRechtstraegerFormSelect;
