import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestartInfoMailDeliveryDetermineRecipientListGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartInfoMailDeliveryDetermineRecipientListGenerierlaufMutation = {
  actionRestartInfoMailDeliveryDetermineRecipientListGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RestartInfoMailDeliveryDetermineRecipientListGenerierlaufDocument = gql`
  mutation RestartInfoMailDeliveryDetermineRecipientListGenerierlauf($generierlaufId: ID!) {
    actionRestartInfoMailDeliveryDetermineRecipientListGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartInfoMailDeliveryDetermineRecipientListGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestartInfoMailDeliveryDetermineRecipientListGenerierlaufMutation,
    RestartInfoMailDeliveryDetermineRecipientListGenerierlaufMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestartInfoMailDeliveryDetermineRecipientListGenerierlaufMutation,
    RestartInfoMailDeliveryDetermineRecipientListGenerierlaufMutationVariables
  >(RestartInfoMailDeliveryDetermineRecipientListGenerierlaufDocument, options);
}
export type RestartInfoMailDeliveryDetermineRecipientListGenerierlaufMutationHookResult = ReturnType<
  typeof useRestartInfoMailDeliveryDetermineRecipientListGenerierlaufMutation
>;
export type RestartInfoMailDeliveryDetermineRecipientListGenerierlaufMutationResult =
  Apollo.MutationResult<RestartInfoMailDeliveryDetermineRecipientListGenerierlaufMutation>;
export type RestartInfoMailDeliveryDetermineRecipientListGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartInfoMailDeliveryDetermineRecipientListGenerierlaufMutation,
  RestartInfoMailDeliveryDetermineRecipientListGenerierlaufMutationVariables
>;
