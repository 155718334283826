import React from 'react';
import { Route } from 'react-router';
import { isAdmin } from '../../../../../../security/permissionChecks';
import AuthRoute from '../../../../../../security/AuthRoute';
import AndromedaSysSettingsAbrechnungsdefinitionVersionViewPage from './AndromedaSysSettingsAbrechnungsdefinitionVersionViewPage';
import { URI_ANDROMEDA_SYS_SETTINGS_ABRECHNUNGSDEFINITION } from '../../../../../../features/AbrechnungsdefinitionTemplate/abrDefTemplateUriPaths';

const andromedaSysSettingsAbrechnungsdefinitionVersionViewPageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS_ABRECHNUNGSDEFINITION.version.viewPage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsAbrechnungsdefinitionVersionViewPage />} />}
  />
);

export default andromedaSysSettingsAbrechnungsdefinitionVersionViewPageRoute;
