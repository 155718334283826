import { isEmpty } from 'lodash';
import ProfileCardAdmin from '../../features/Profile/ProfileCardAdmin';
import ProfileCardMitarbeiter from '../../features/Profile/ProfileCardMitarbeiter';
import ProfileCardUserNoMitarbeiter from '../../features/Profile/ProfileCardUserNoMitarbeiter';
import usePermission from '../../shared/Auth/usePermission';
import { useAuth } from '../../shared/Auth/authContext';
import DetailsPageTemplate from '../../components/Template/DetailsPageTemplate';
import menuListProfile from './menuListProfile';

const ProfilePage = () => {
  const { isAdmin } = usePermission();
  const { activeForFirmendaten } = useAuth();

  let profileCard: React.ReactNode;
  if (isAdmin) {
    // ANDROADMIN
    profileCard = <ProfileCardAdmin />;
  } else if (isEmpty(activeForFirmendaten)) {
    // user and NOT mitarbeiter
    profileCard = <ProfileCardUserNoMitarbeiter />;
  } else if (activeForFirmendaten.kundenSystemId) {
    // user AND mitarbeiter
    profileCard = <ProfileCardMitarbeiter kundenSystemId={activeForFirmendaten.kundenSystemId} />;
  }

  return <DetailsPageTemplate<any> data={[]} pageTitle={() => 'Mein Profil'} card={() => profileCard} sidebarMenuList={menuListProfile} />;
};

export default ProfilePage;
