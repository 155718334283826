import React, { FC } from 'react';
import { Formik } from 'formik';
import { showSuccessMsgUpdate } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { KontoFormValues, mapFormValuesKonto, mapKontoToFormValues } from './kontoFormMapper';
import { useCreateKontoMutation, useUpdateKontoMutation } from '../gql/KontenplanMutations.types';
import { kontoFormValidationSchema } from './kontoFormValidationSchema';
import { showSuccessMsgCreateWithPath } from '../../../components/message/message';
import { generatePathToKontoDetailsPage } from '../kontoUriPaths';
import { Konto } from '../../../types';
import KontoFormContent from '../shared/KontoFormContent';

type Props = {
  konto?: Konto;
  onSuccess: () => void;
  onCancel: () => void;
};

const KontoForm: FC<Props> = ({ konto, onSuccess, onCancel }) => {
  const initialValues = mapKontoToFormValues(konto);

  const isUpdate = !!konto;
  const entity = 'Konto';
  const isEditable = (konto && konto.deletable) || !konto;

  const [createKonto, { loading: loadingForm }] = useCreateKontoMutation({
    onCompleted: (data) => {
      const { kontoId, bezeichnung } = data.createKonto.data;
      showSuccessMsgCreateWithPath(entity, bezeichnung, generatePathToKontoDetailsPage(kontoId));
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [updateKonto, { loading: loadingUpdate }] = useUpdateKontoMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<KontoFormValues>
      initialValues={initialValues}
      validationSchema={kontoFormValidationSchema}
      onSubmit={(formValues, formikHelpers) => {
        const input = mapFormValuesKonto(formValues);
        if (!konto) {
          createKonto({ variables: { input } }).finally(() => formikHelpers.setSubmitting(false));
        } else {
          updateKonto({
            variables: { kontoId: konto.kontoId, input },
          }).finally(() => formikHelpers.setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <KontoFormContent
          formikProps={formikProps}
          isEditable={isEditable}
          onCancel={onCancel}
          isUpdate={isUpdate}
          isLoading={loadingForm || loadingUpdate}
        />
      )}
    </Formik>
  );
};

export default KontoForm;
