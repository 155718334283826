import { FC } from 'react';
import { Skeleton } from 'antd';
import styled from 'styled-components';
import { useBestandseinheitNutzungQuery, useBeVertragListQuery } from '../../gql/BestandseinheitQueries.types';
import { StyledExpandedSingleLineTable } from '../../styled/Table.style';
import { useOnVertragDataChangedEvents } from '../../../Vertrag/BeVertrag/useOnVertragDataChangedEvents';
import { bestandseinheitSumsTableColumns } from './bestandseinheitSumsTableColumns';
import BestandseinheitVertragTable from './BestandseinheitVertragTable';
import { BestandseinheitListEntry, BestandseinheitNutzung, BeVertragListEntry } from '../../../../types';

type BestandseinheitVertragDetailsProps = {
  bestandseinheit: BestandseinheitListEntry;
  objektId: string;
  onActionSuccess: () => Promise<unknown>;
  isWegObjekt: boolean;
};

const BestandseinheitVertragDetails: FC<BestandseinheitVertragDetailsProps> = ({ bestandseinheit, objektId, onActionSuccess, isWegObjekt }) => {
  const { data: beNutzungData } = useBestandseinheitNutzungQuery({
    variables: {
      objektId,
      bestandseinheitId: bestandseinheit.bestandseinheitId,
    },
  });
  const bestandseinheitNutzung = beNutzungData?.getBestandseinheitNutzung.data ?? [];

  const { data: vertragListData, refetch: refetchVertragList } = useBeVertragListQuery({
    variables: {
      objektId,
      bestandseinheitId: bestandseinheit.bestandseinheitId,
    },
  });
  const vertragList = vertragListData?.getBeVertragList.data;

  const callbackVertragChanged = () => onActionSuccess().then(() => refetchVertragList());
  useOnVertragDataChangedEvents(`vertrag.${bestandseinheit.bestandseinheitId}`, callbackVertragChanged, collectVertragIds(vertragList ?? []));

  return (
    <>
      {!beNutzungData ? (
        <SkeletonInputs numberOfSkeletons={2} width={BE_SKELETON_INPUT_WIDTH} />
      ) : (
        <StyledExpandedSingleLineTable<BestandseinheitNutzung>
          bordered
          showHeader={false}
          size="small"
          columns={bestandseinheitSumsTableColumns(isWegObjekt)}
          dataSource={Array.isArray(bestandseinheitNutzung) ? bestandseinheitNutzung : [bestandseinheitNutzung]}
          rowKey={() => `${bestandseinheit.bestandseinheitId}-extra-row`}
          pagination={false}
        />
      )}
      {!vertragListData ? (
        <SkeletonInputs numberOfSkeletons={8} width={BE_VERTRAG_SKELETON_INPUT_WIDTH} />
      ) : (
        <BestandseinheitVertragTable bestandseinheitId={bestandseinheit.bestandseinheitId} objektId={objektId} vertragList={vertragList} />
      )}
    </>
  );
};

const collectVertragIds = (beVertragList: BeVertragListEntry[]) => beVertragList.map((vertrag) => vertrag.vertragId);

const BE_SKELETON_INPUT_WIDTH = 50;
const BE_VERTRAG_SKELETON_INPUT_WIDTH = 12.5;

type SkeletonInputsProps = {
  numberOfSkeletons: number;
  width: number;
};

const SkeletonInputs: FC<SkeletonInputsProps> = ({ numberOfSkeletons, width }) => {
  return (
    <>
      {new Array(numberOfSkeletons).fill('dummy element on purpose for filling array (do not delete)').map((_, index) => {
        return <StyledSkeletonInput key={index} active width={width} />;
      })}
    </>
  );
};

interface StyledSkeletonInputProps {
  width?: number;
}

const StyledSkeletonInput = styled(Skeleton.Input)<StyledSkeletonInputProps>`
  width: ${(props) => (props.width ? `${props.width}%` : '100%')}!important;
  padding: 2px;
  .ant-skeleton-input {
    width: 100% !important;
    min-width: 100% !important;
  }
`;

export default BestandseinheitVertragDetails;
