import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { generatePathToVerarbeitungCamtOnlineBankingManuallyDetailsPage } from '../../../verarbeitungApiPaths';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { DataCarrierCamt } from '../../../../../types';
import DataCarrierIbanListColumn from '../../../../DataCarrier/Listing/shared/DataCarrierIbanListColumn';
import XmlDataCarrierTableColumn from '../../../../DataCarrier/Listing/shared/XmlDataCarrierTableColumn';
import { generatePathToBankstatementListingPage } from '../../../../DataCarrier/Listing/Bankstatement/Filters/filtersQueryParams';

const ebicsDataCarrierDetailsTableColumns = (firmendatenId: string): TableWithColSelectorColumnProps<DataCarrierCamt>[] => [
  {
    title: 'Bezeichnung (des Datenträgers)',
    render: (text, record) => {
      return (
        <DataWithShortenedText text={record.name} maxTextLength={15}>
          {(shortenedText) => (
            <Link to={generatePathToBankstatementListingPage({ name: record.name })} target="_blank">
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      );
    },
  },
  {
    title: 'Importierte IBAN',
    render: (text, record) => <DataCarrierIbanListColumn ibanList={record.ibanImportedList} maxTextLength={20} />,
  },
  {
    title: 'Nicht importierte IBAN',
    render: (text, record) => <DataCarrierIbanListColumn ibanList={record.ibanNotImportedList} maxTextLength={20} />,
  },
  {
    title: 'XML Datenträger',
    render: (text, record) => (
      <XmlDataCarrierTableColumn dataCarrierId={record.dataCarrierId} xmlFilename={record.xmlFilename} firmendatenId={firmendatenId} />
    ),
  },
  {
    title: 'Verarbeitet',
    render: (_, record) => {
      const firstDataCarrierDetails = record.dataCarrierDetailsList[0];
      if (firstDataCarrierDetails?.createdByGenerierlaufId) {
        return (
          <Link to={generatePathToVerarbeitungCamtOnlineBankingManuallyDetailsPage(firstDataCarrierDetails.createdByGenerierlaufId)} target="_blank">
            Importiert durch Generierlauf
          </Link>
        );
      }

      return <Typography.Text>Verarbeitet</Typography.Text>;
    },
  },
];

export default ebicsDataCarrierDetailsTableColumns;
