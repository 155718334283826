import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Tabs, TabsProps } from 'antd';
import { handleUpdateSubmit } from '../../shared/handleSubmit';
import { ObjektInfoFeldType } from '../../../../../../types';
import InfoFeldUpload from '../../../../../shared/InfoFeld/InfoFeldUpload';
import FormButtons from '../../../../../../components/Button/FormButtons';
import { useFDAuthorized } from '../../../../../../shared/Auth/useAuthorized';
import { personValidationSchema } from '../shared/personValidationSchema';
import PersonenDatenTab from '../Tabs/PersonDatenTab';
import { mapToUpdateFormValues, personUpdateFormFields, PersonUpdateFormValues } from './personUpdateFormMapper';
import InfoFeldDocumentTab from '../../../../../shared/InfoFeld/Tabs/InfoFeldDocumentTab';

import { ObjektInfoFeld_ObjektInfoFeldPerson_Fragment } from '../../../gql/objektInfoFeldFragments.types';
import ObjektInfoFeldTicketListing from '../../Auflage/Ticket/ObjektInfoFeldTicketListing';
import FormButtonClose from '../../../../../../components/Button/FormButtonClose';
import { InfoFeldUpdateFormTabKey } from '../../../../../InfoFeld/infoFeldTypes';

type Props = {
  objektId: string;
  onCancel: () => void;
  onInfoFeldAction: () => void;
  onTicketAction: () => void;
  infoFeld: ObjektInfoFeld_ObjektInfoFeldPerson_Fragment;
  activeKeyTab: InfoFeldUpdateFormTabKey;
};

const PersonUpdateForm: FC<Props> = ({ objektId, onCancel, onInfoFeldAction, onTicketAction, infoFeld, activeKeyTab }) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  return (
    <Formik<PersonUpdateFormValues>
      initialValues={mapToUpdateFormValues(infoFeld)}
      validationSchema={personValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleUpdateSubmit(firmendatenId, objektId, infoFeld.objektInfoFeldId, ObjektInfoFeldType.Person, values, setSubmitting, onInfoFeldAction);
      }}
    >
      {(formikProps) => {
        const items: TabsProps['items'] = [
          {
            key: InfoFeldUpdateFormTabKey.DATA,
            label: 'Daten',
            children: (
              <Form layout="vertical">
                <PersonenDatenTab formFields={personUpdateFormFields} formikProps={formikProps} />
                <InfoFeldUpload name={personUpdateFormFields.documentList} formikProps={formikProps} documentList={formikProps.values.documentList} />
                <FormButtons updateMode onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
              </Form>
            ),
          },
          {
            key: InfoFeldUpdateFormTabKey.TICKET,
            label: 'Fristen/Aufgaben',
            children: (
              <>
                <ObjektInfoFeldTicketListing objektId={objektId} infoFeld={infoFeld} onSuccess={onTicketAction} />
                <FormButtonClose onCancel={onCancel} />
              </>
            ),
          },
          {
            key: InfoFeldUpdateFormTabKey.DOCUMENT,
            label: 'Dokumente',
            children: (
              <Form layout="vertical">
                <InfoFeldDocumentTab
                  infoFeld={infoFeld}
                  deleteDocumentFileIdList={formikProps.values.deleteDocumentFileIdList}
                  onDocumentDelete={(value) => formikProps.setFieldValue(personUpdateFormFields.deleteDocumentFileIdList, value)}
                  firmendatenId={firmendatenId}
                />
                <InfoFeldUpload name={personUpdateFormFields.documentList} formikProps={formikProps} documentList={formikProps.values.documentList} />
                <FormButtons updateMode onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
              </Form>
            ),
          },
        ];

        return <Tabs defaultActiveKey={activeKeyTab} style={{ minHeight: '220px' }} items={items} />;
      }}
    </Formik>
  );
};

export default PersonUpdateForm;
