import React, { FC, RefObject } from 'react';
import { FormikProps } from 'formik';
import { filtersFormFields, FiltersFormValues, listingLabelList } from './filtersFormMapper';
import { TBookingJournalQueryParams } from './filtersQueryParams';
import { getDefaultFilterList, onFilterSelectChange } from '../../../../components/Filters/FiltersHelpers';
import FromToFormDatePicker from '../../../shared/FromToForm/FromToFormDatePicker';
import MitarbeiterSelect from '../../../../shared/components/MitarbeiterSelect/MitarbeiterSelect';
import YesOrNoFormSelect from '../../../shared/YesOrNoFormSelect';
import SourceTypeListFormSelect from '../../shared/SourceTypeListFormSelect';
import RechnungsAusstellerRechtstraegerSelect from '../../../../shared/components/RechnungsAusstellerSelect/RechnungsAusstellerRechtstraegerSelect';
import FibuBuchungVertragspartnerListFormSelect from '../../shared/FibuBuchungVertragspartnerListFormSelect';
import FibuBelegSymbolFormSelect from '../../../BelegSymbol/Fibu/FibuBelegSymbolFormSelect';
import FibuBuchungTypeFormSelect from '../../../BuchungType/Fibu/FibuBuchungTypeFormSelect';
import StornoBuchungListFormSelect from './StornoBuchungListFormSelect';
import FibuBuchungBelegnummerListFormSelect from '../../shared/FibuBuchungBelegnummerListFormSelect';
import FibuBzObjektListFormSelect from '../../shared/FibuBzObjektListFormSelect';
import FibuBzBestandseinheitListFormSelect from '../../shared/FibuBzBestandseinheitListFormSelect';
import FibuBzKontoListFormSelect from '../../shared/FibuBzKontoListFormSelect';
import FibuBzGegenKontoListFormSelect from '../../shared/FibuBzGegenKontoListFormSelect';
import CurrencyListFormSelect from '../../../../shared/components/Currency/CurrencyListFormSelect';
import SollHabenFormSelect from '../../../../shared/components/SollHaben/SollHabenFormSelect';
import FibuBzBuchungKZListFormSelect from '../../shared/FibuBzBuchungKZListFormSelect';
import FibuBzTextListFormSelect from '../../shared/FibuBzTextListFormSelect';
import { StyledStickyHeaderDivForFilterOrSummary } from '../../../../components/StickyHeader/styled/StickyHeader.styled';
import { LISTING_FILTER_HAS_CHANGED } from '../../../../components/StickyHeader/useOnEventsSetStickyFilterSummary';
import ActiveOrDeactivatedFormSelect from '../../../shared/ActiveOrDeactivatedFormSelect';
import useFilterWithQueryParamsNew from '../../../../components/Filters/useFilterWithQueryParamsNew';
import FiltersWithSelectorWrapper from '../../../../components/Filters/FiltersWithSelector/FiltersWithSelectorWrapper';
import FormItemForFilter from '../../../../components/Form/FormItemForFilter';
import FromToFormInputNumberForFilter from '../../../shared/FromToForm/FromToFormInputNumberForFilter';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
  queryParams: TBookingJournalQueryParams;
  filterRef: RefObject<HTMLDivElement>;
};

const ListingFilters: FC<Props> = ({ formikProps, filterRef, queryParams }) => {
  const defaultFilterList = getDefaultFilterList(filtersFormFields, formikProps);

  const mapFilterLabelToQueryFilterList = (filter: string) => {
    if (filter === filtersFormFields.buchungsdatumFrom || filter === filtersFormFields.buchungsdatumTo) {
      return filtersFormFields.buchungsdatumFrom;
    }
    if (filter === filtersFormFields.belegdatumFrom || filter === filtersFormFields.belegdatumTo) {
      return filtersFormFields.belegdatumFrom;
    }
    if (filter === filtersFormFields.dueDateFrom || filter === filtersFormFields.dueDateTo) {
      return filtersFormFields.dueDateFrom;
    }
    if (filter === filtersFormFields.betragFrom || filter === filtersFormFields.betragTo) {
      return filtersFormFields.betragFrom;
    }
    if (filter === filtersFormFields.steuersatzFrom || filter === filtersFormFields.steuersatzTo) {
      return filtersFormFields.steuersatzFrom;
    }
    if (filter === filtersFormFields.steuerFrom || filter === filtersFormFields.steuerTo) {
      return filtersFormFields.steuerFrom;
    }
    if (filter === filtersFormFields.ustVomAufwandSteuersatzFrom || filter === filtersFormFields.ustVomAufwandSteuersatzTo) {
      return filtersFormFields.ustVomAufwandSteuersatzFrom;
    }
    if (filter === filtersFormFields.createTsFrom || filter === filtersFormFields.createTsTo) {
      return filtersFormFields.createTsFrom;
    }
    return filter;
  };

  const filters = (filter: string) => {
    if (filter === filtersFormFields.belegSymbolList) {
      return (
        <FormItemForFilter name={filtersFormFields.belegSymbolList} key={filter}>
          <FibuBelegSymbolFormSelect
            name={filtersFormFields.belegSymbolList}
            onChange={formikProps.submitForm}
            mode="multiple"
            prefix={listingLabelList.belegSymbolList}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.belegdatumFrom) {
      return (
        <FormItemForFilter name="belegdatum" key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={filtersFormFields.belegdatumFrom}
            toName={filtersFormFields.belegdatumTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
            fromPrefix="Belegdatum - von"
            toPrefix="Belegdatum - bis"
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.belegnummer) {
      return (
        <FormItemForFilter name={filtersFormFields.belegnummer} key={filter}>
          <FibuBuchungBelegnummerListFormSelect
            name={filtersFormFields.belegnummer}
            belegnummer={formikProps.values.belegnummer}
            onChange={formikProps.submitForm}
            rechtstraegerIdList={formikProps.values.rechtstraegerIdList}
            prefix={listingLabelList.belegnummer}
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.bestandseinheitIdList) {
      return (
        <FormItemForFilter name={filtersFormFields.bestandseinheitIdList} key={filter}>
          <FibuBzBestandseinheitListFormSelect
            name={filtersFormFields.bestandseinheitIdList}
            onChange={formikProps.submitForm}
            rechtstraegerIdList={formikProps.values.rechtstraegerIdList}
            prefix={listingLabelList.bestandseinheitIdList}
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.betragFrom) {
      return (
        <FromToFormInputNumberForFilter
          key={filter}
          name="Betrag"
          fromName={filtersFormFields.betragFrom}
          fromPrefix={`${listingLabelList.betragFrom} - von`}
          toName={filtersFormFields.betragTo}
          toPrefix={`${listingLabelList.betragFrom} - bis`}
          onChange={formikProps.submitForm}
        />
      );
    }
    if (filter === filtersFormFields.buchungsKZ) {
      return (
        <FormItemForFilter name={filtersFormFields.buchungsKZ} key={filter}>
          <FibuBzBuchungKZListFormSelect
            name={filtersFormFields.buchungsKZ}
            onChange={formikProps.submitForm}
            rechtstraegerIdList={formikProps.values.rechtstraegerIdList}
            prefix={listingLabelList.buchungsKZ}
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.buchungsTypeList) {
      return (
        <FormItemForFilter name={filtersFormFields.buchungsTypeList} key={filter}>
          <FibuBuchungTypeFormSelect
            name={filtersFormFields.buchungsTypeList}
            onChange={formikProps.submitForm}
            prefix={listingLabelList.buchungsTypeList}
            mode="multiple"
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.buchungsdatumFrom) {
      return (
        <FormItemForFilter name="buchungsdatum" key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={filtersFormFields.buchungsdatumFrom}
            toName={filtersFormFields.buchungsdatumTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
            fromPrefix={`${listingLabelList.buchungsdatumFrom} - von`}
            toPrefix={`${listingLabelList.buchungsdatumFrom} - bis`}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.createTsFrom) {
      return (
        <FormItemForFilter name="erstelltAm" key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={filtersFormFields.createTsFrom}
            toName={filtersFormFields.createTsTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
            fromPrefix={`${listingLabelList.createTsFrom} - von`}
            toPrefix={`${listingLabelList.createTsFrom} - bis`}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.dueDateFrom) {
      return (
        <FormItemForFilter name="faelligkeit" key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={filtersFormFields.dueDateFrom}
            toName={filtersFormFields.dueDateTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
            fromPrefix={`${listingLabelList.dueDateFrom} - von`}
            toPrefix={`${listingLabelList.dueDateFrom} - bis`}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.erstellerMitarbeiterIdList) {
      return (
        <FormItemForFilter name={filtersFormFields.erstellerMitarbeiterIdList} key={filter}>
          <MitarbeiterSelect
            mode="multiple"
            name={filtersFormFields.erstellerMitarbeiterIdList}
            onChange={formikProps.submitForm}
            prefix={listingLabelList.erstellerMitarbeiterIdList}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.fachlichePruefung) {
      return (
        <FormItemForFilter name={filtersFormFields.fachlichePruefung} key={filter}>
          <ActiveOrDeactivatedFormSelect
            name={filtersFormFields.fachlichePruefung}
            onChange={formikProps.submitForm}
            prefix={listingLabelList.fachlichePruefung}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.gegenKontoId) {
      return (
        <FormItemForFilter name={filtersFormFields.gegenKontoId} key={filter}>
          <FibuBzGegenKontoListFormSelect
            name={filtersFormFields.gegenKontoId}
            onChange={formikProps.submitForm}
            rechtstraegerIdList={formikProps.values.rechtstraegerIdList}
            prefix={listingLabelList.gegenKontoId}
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.kontoId) {
      return (
        <FormItemForFilter name={filtersFormFields.kontoId} key={filter}>
          <FibuBzKontoListFormSelect
            name={filtersFormFields.kontoId}
            onChange={formikProps.submitForm}
            rechtstraegerIdList={formikProps.values.rechtstraegerIdList}
            prefix={listingLabelList.kontoId}
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.objektId) {
      return (
        <FormItemForFilter name={filtersFormFields.objektId} key={filter}>
          <FibuBzObjektListFormSelect
            name={filtersFormFields.objektId}
            onChange={formikProps.submitForm}
            rechtstraegerIdList={formikProps.values.rechtstraegerIdList}
            prefix={listingLabelList.objektId}
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.rechtstraegerIdList) {
      return (
        <FormItemForFilter name={filtersFormFields.rechtstraegerIdList} key={filter}>
          <RechnungsAusstellerRechtstraegerSelect
            name={filtersFormFields.rechtstraegerIdList}
            onChange={formikProps.submitForm}
            prefix={listingLabelList.rechtstraegerIdList}
            placeholder=""
            mode="multiple"
            size="middle"
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.sollHaben) {
      return (
        <FormItemForFilter name={filtersFormFields.sollHaben} key={filter}>
          <SollHabenFormSelect
            name={filtersFormFields.sollHaben}
            onChange={formikProps.submitForm}
            prefix={listingLabelList.sollHaben}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.source) {
      return (
        <FormItemForFilter name={filtersFormFields.source} key={filter}>
          <SourceTypeListFormSelect
            name={filtersFormFields.source}
            onChange={formikProps.submitForm}
            prefix={listingLabelList.source}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.steuerFrom) {
      return (
        <FromToFormInputNumberForFilter
          key={filter}
          name={listingLabelList.steuerFrom}
          fromName={filtersFormFields.steuerFrom}
          fromPrefix={`${listingLabelList.steuerFrom} - von`}
          toName={filtersFormFields.steuerTo}
          toPrefix={`${listingLabelList.steuerFrom} - bis`}
          onChange={formikProps.submitForm}
          fractionDigits={0}
        />
      );
    }
    if (filter === filtersFormFields.steuersatzFrom) {
      return (
        <FromToFormInputNumberForFilter
          key={filter}
          name={listingLabelList.steuersatzFrom}
          fromName={filtersFormFields.steuersatzFrom}
          fromPrefix={`${listingLabelList.steuersatzFrom} - von`}
          toName={filtersFormFields.steuersatzTo}
          toPrefix={`${listingLabelList.steuersatzFrom} - bis`}
          onChange={formikProps.submitForm}
          fractionDigits={0}
        />
      );
    }
    if (filter === filtersFormFields.stornierteBuchungBezeichnung) {
      return (
        <FormItemForFilter name={filtersFormFields.stornierteBuchungBezeichnung} key={filter}>
          <StornoBuchungListFormSelect
            name={filtersFormFields.stornierteBuchungBezeichnung}
            rechtstraegerIdList={formikProps.values.rechtstraegerIdList}
            formikProps={formikProps}
            onChange={formikProps.submitForm}
            prefix={listingLabelList.stornierteBuchungBezeichnung}
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.storno) {
      return (
        <FormItemForFilter name={filtersFormFields.storno} key={filter}>
          <YesOrNoFormSelect name={filtersFormFields.storno} onChange={formikProps.submitForm} prefix={listingLabelList.storno} placeholder="" />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.text) {
      return (
        <FormItemForFilter name={filtersFormFields.text} key={filter}>
          <FibuBzTextListFormSelect
            name={filtersFormFields.text}
            rechtstraegerIdList={formikProps.values.rechtstraegerIdList}
            onChange={formikProps.submitForm}
            text={formikProps.values.text}
            prefix={listingLabelList.text}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.umbuchung) {
      return (
        <FormItemForFilter name={filtersFormFields.umbuchung} key={filter}>
          <YesOrNoFormSelect
            name={filtersFormFields.umbuchung}
            onChange={formikProps.submitForm}
            prefix={listingLabelList.umbuchung}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.ustVomAufwandSteuersatzFrom) {
      return (
        <FromToFormInputNumberForFilter
          key={filter}
          name={listingLabelList.ustVomAufwandSteuersatzFrom}
          fromName={filtersFormFields.ustVomAufwandSteuersatzFrom}
          fromPrefix={`${listingLabelList.ustVomAufwandSteuersatzFrom} - von`}
          toName={filtersFormFields.ustVomAufwandSteuersatzTo}
          toPrefix={`${listingLabelList.ustVomAufwandSteuersatzFrom} - bis`}
          onChange={formikProps.submitForm}
          fractionDigits={0}
        />
      );
    }
    if (filter === filtersFormFields.vertragspartnerId) {
      return (
        <FormItemForFilter name={filtersFormFields.vertragspartnerId} key={filter}>
          <FibuBuchungVertragspartnerListFormSelect
            name={filtersFormFields.vertragspartnerId}
            onChange={formikProps.submitForm}
            rechtstraegerIdList={formikProps.values.rechtstraegerIdList}
            prefix={listingLabelList.vertragspartnerId}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.waehrung) {
      return (
        <FormItemForFilter name={filtersFormFields.waehrung} key={filter}>
          <CurrencyListFormSelect
            name={filtersFormFields.waehrung}
            onChange={formikProps.submitForm}
            prefix={listingLabelList.waehrung}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    return undefined;
  };

  const updateFilterValues = (selectedFilterList: string[]) => {
    if (!selectedFilterList.includes(filtersFormFields.createTsFrom) && formikProps.values.createTsTo) {
      formikProps.setFieldValue(filtersFormFields.createTsTo, undefined);
    }
    formikProps.submitForm();
    document.dispatchEvent(new CustomEvent(LISTING_FILTER_HAS_CHANGED));
  };

  const { selectedFilterList, setSelectedFilterList, filterList } = useFilterWithQueryParamsNew({
    defaultFilterList,
    updateFilterValues,
    filters,
    labelList: listingLabelList,
    mapFilterLabelToQueryFilterList,
    queryParams,
  });

  return (
    <StyledStickyHeaderDivForFilterOrSummary ref={filterRef}>
      <FiltersWithSelectorWrapper
        selectedFilterList={selectedFilterList}
        onSelectedFilterTitleList={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
        allFilterTitles={listingLabelList}
        filters={<>{filterList}</>}
      />
    </StyledStickyHeaderDivForFilterOrSummary>
  );
};

export default ListingFilters;
