import React, { FC, useState } from 'react';
import { Divider, Space, Typography } from 'antd';
import { ObjektInfoFeldType } from '../../../../types';
import AuflageCreateForm from './Auflage/Create/AuflageCreateForm';
import GrundstuecksdatenCreateForm from './Grundstuecksdaten/Create/GrundstuecksdatenCreateForm';
import ObjektInfoFeldTypeSelect from './ObjektInfoFeldTypeSelect';
import HausbetreuungCreateForm from './Hausbetreuung/Create/HausbetreuungCreateForm';
import VersicherungCreateForm from './Versicherung/Create/VersicherungCreateForm';
import PersonCreateForm from './Person/Create/PersonCreateForm';

type Props = {
  objektId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

const ObjektInfoFeldCreateForm: FC<Props> = ({ objektId, onCancel, onSuccess }) => {
  const [type, setType] = useState<ObjektInfoFeldType>();

  let content;

  if (type === ObjektInfoFeldType.Auflage) {
    content = <AuflageCreateForm objektId={objektId} onCancel={onCancel} onSuccess={onSuccess} />;
  }
  if (type === ObjektInfoFeldType.Grundstuecksdaten) {
    content = <GrundstuecksdatenCreateForm objektId={objektId} onCancel={onCancel} onSuccess={onSuccess} />;
  }
  if (type === ObjektInfoFeldType.Hausbetreuung) {
    content = <HausbetreuungCreateForm objektId={objektId} onCancel={onCancel} onSuccess={onSuccess} />;
  }
  if (type === ObjektInfoFeldType.Versicherung) {
    content = <VersicherungCreateForm objektId={objektId} onCancel={onCancel} onSuccess={onSuccess} />;
  }
  if (type === ObjektInfoFeldType.Person) {
    content = <PersonCreateForm objektId={objektId} onCancel={onCancel} onSuccess={onSuccess} />;
  }

  return (
    <>
      <Space style={{ marginBottom: '16px' }}>
        <Typography.Text>Art der Information</Typography.Text>
        <ObjektInfoFeldTypeSelect onChange={setType} />
      </Space>
      <Divider />
      {content}
    </>
  );
};

export default ObjektInfoFeldCreateForm;
