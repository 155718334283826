import React, { FC } from 'react';
import { Modal, Space, Typography } from 'antd';
import styled from 'styled-components';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import BookingPreview from '../Preview/StornoBookingPreview/StandingInvoice/BookingPreview';
import { IncomingInvoiceFormValues } from '../incomingInvoiceFormMapper';
import theme from '../../../../theme';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  belegId: string;
  values: IncomingInvoiceFormValues;
  loading: boolean;
};

const StandingInvoiceConfirmModal: FC<Props> = ({ isOpen, onCancel, onSubmit, loading, belegId, values }) => {
  return (
    <StyledModal
      title={
        <Space>
          <ExclamationCircleTwoTone twoToneColor={theme.colors.warning} />
          <Typography.Text>Möchten Sie diese Eingangsrechnung bearbeiten?</Typography.Text>
        </Space>
      }
      open={isOpen}
      onCancel={onCancel}
      destroyOnClose
      closable={false}
      width={1024}
      onOk={onSubmit}
      confirmLoading={loading}
      okText="Speichern"
    >
      <BookingPreview values={values} belegId={belegId} />
    </StyledModal>
  );
};

export const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none !important;
  }
  .ant-modal-footer {
    border-top: none !important;
  }
`;

export default StandingInvoiceConfirmModal;
