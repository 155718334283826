import React, { FC } from 'react';
import { Card, Drawer, Space } from 'antd';
import { FirmendatenBuchhaltungEinstellung } from '../../../types';
import theme from '../../../theme';
import AllgemeineEinstellungKonto from '../shared/AllgemeineEinstellungKonto';
import { useToggle } from '../../../hooks/useToggle';
import AllgemeineEinstellungenCardActions from '../shared/AllgemeineEinstellungenCardActions';
import BillingAccountForm from './Form/BillingAccountForm';
import HistoryModal, { HistoryType } from '../../History/shared/HistoryModal';
import BillingAccountChangeHistoryListingTable from './BillingAccountChangeHistoryListingTable';

type Props = {
  firmendatenBuchhaltungEinstellung: FirmendatenBuchhaltungEinstellung;
  onSuccess: () => void;
};

const BillingAccountCard: FC<Props> = ({ firmendatenBuchhaltungEinstellung, onSuccess }) => {
  const [isOpenDrawer, toggleDrawer] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const onActionSuccess = () => {
    onSuccess();
    toggleDrawer();
  };

  return (
    <>
      <Card
        style={{ width: '450px', border: '1px solid #d9d9d9' }}
        headStyle={{ backgroundColor: theme.cardBackgroundColors.grey }}
        size="default"
        bordered
        title="Eigentümer Verrechnung"
        extra={<AllgemeineEinstellungenCardActions onClickEdit={toggleDrawer} onClickChangeHistoryOpen={toggleChangeHistoryOpen} />}
      >
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <AllgemeineEinstellungKonto
            kontoId={firmendatenBuchhaltungEinstellung?.heAbrechnungVerrechnungskontoId}
            text="Verrechnungskonto Hauseigentümer"
          />
          <AllgemeineEinstellungKonto
            kontoId={firmendatenBuchhaltungEinstellung?.subAdministrationBillingAccountId}
            text="Verrechnungskonto Subverwaltung"
          />
        </Space>
      </Card>
      <Drawer title="Verrechnungskonto bearbeiten" width={720} open={isOpenDrawer} onClose={toggleDrawer} destroyOnClose>
        <BillingAccountForm
          onSuccess={onActionSuccess}
          onCancel={toggleDrawer}
          heAbrechnungVerrechnungskontoId={firmendatenBuchhaltungEinstellung.heAbrechnungVerrechnungskontoId}
          subAdministrationBillingAccountId={firmendatenBuchhaltungEinstellung.subAdministrationBillingAccountId}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <BillingAccountChangeHistoryListingTable />
      </HistoryModal>
    </>
  );
};

export default BillingAccountCard;
