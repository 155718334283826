import { FC } from 'react';
import HistoryListingTable from '../../../../History/HistoryListingTable';
import { HistoryType } from '../../../../History/shared/HistoryModal';
import { useUstKategorieTemplateChangeHistoryListQuery } from '../../gql/UstKategorieTemplateQueries.types';

type Props = {
  ustKategorieId: string;
};

const UstKategorieTemplateChangeHistoryListingTable: FC<Props> = ({ ustKategorieId }) => {
  const { data, loading } = useUstKategorieTemplateChangeHistoryListQuery({ variables: { ustKategorieId } });
  const historyList = data?.getUstKategorieTemplateChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="ust-kategorie-template" />;
};

export default UstKategorieTemplateChangeHistoryListingTable;
