import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import EmailVersandPage from './EmailVersandPage';
import { URI_EMAIL_VERSAND_PAGE } from '../../features/EmailDelivery/emailDeliveryUriPaths';

const emailVersandPageRoute = (
  <Route path={URI_EMAIL_VERSAND_PAGE} element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<EmailVersandPage />} />} />
);

export default emailVersandPageRoute;
