import * as Yup from 'yup';
import { entityIsRequired } from '../../../../components/message/validationMsg';
import { vorschreibungspositionVersionFormFields } from './vorschreibungspositionVersionFormMapper';
import { kommentarValidationSchema } from '../../../../components/Timeline/Form/timelineFormKommentarValidationSchema';

export const vorschreibungspositionVersionFormValidationSchema = Yup.object().shape({
  [vorschreibungspositionVersionFormFields.kontierungstabelleId]: Yup.string().required(entityIsRequired('Kontierungstabelle')),
  [vorschreibungspositionVersionFormFields.umsatzsteuerkennzeichenId]: Yup.string().required(entityIsRequired('USt.-Kennzeichen')),
  [vorschreibungspositionVersionFormFields.kommentar]: kommentarValidationSchema,
});
