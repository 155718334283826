import { mapToQueryString, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { GenerierlaufEntryExitCode } from '../../../../../types';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../../verarbeitungApiPaths';

enum filtersQueryParamKey {
  OBJEKT_ID = 'objektId',
  EXIT_CODE_LIST = 'exitCodeList',
}

export type filtersQueryParams = QueryParams<{
  objektId: string;
  exitCodeList: GenerierlaufEntryExitCode[];
}>;

export const updateQueryParams = (
  verarbeitungType: string,
  generierlaufId: string,
  navigate: (path: string) => void,
  filters: filtersQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => {
  navigate(generatePathToDetailsPage(verarbeitungType, generierlaufId, filters.objektId, filters.exitCodeList, paginationParams));
};

const generatePathToDetailsPage = (
  verarbeitungType: string,
  generierlaufId: string,
  objektId?: string,
  exitCodeList?: GenerierlaufEntryExitCode[],
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [filtersQueryParamKey.OBJEKT_ID]: objektId,
    [filtersQueryParamKey.EXIT_CODE_LIST]: exitCodeList,
    ...paginationParams,
  })}`;
