import React, { FC, useState } from 'react';
import { Select, SelectProps } from 'formik-antd';
import useDebounce from '../../../../hooks/useDebounce';
import { isTextEqualOrLongerThanThreeCharacters } from '../../../../helpers/stringHelper';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import SelectNotFoundContent from '../../../shared/SelectNotFoundContent';
import { useFilterFibuBuchungsanweisungBelegnummerListQuery } from '../../gql/BuchungsanweisungQueries.types';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = {
  rechtstraegerIdList?: string[] | null;
  belegnummer?: string | null;
} & Omit<SelectProps, 'id' | 'allowClear' | 'filterOption' | 'loading' | 'placeholder' | 'size' | 'showSearch'>;

const FibuBaBelegnummerSelect: FC<Props> = ({ rechtstraegerIdList, belegnummer, ...restProps }) => {
  const [belegnummerText, setBelegnummerText] = useState<string>(belegnummer ? belegnummer.toString() : '');
  const debouncedText = useDebounce(belegnummerText);

  const { loading, data } = useFilterFibuBuchungsanweisungBelegnummerListQuery({
    variables: {
      belegnummer: debouncedText as string,
      rechtstraegerIdList,
    },
    skip: debouncedText ? !isTextEqualOrLongerThanThreeCharacters(debouncedText as string) : true,
  });

  const belegnummerList = data?.getFilterFibuBuchungsanweisungBelegnummerList.data ?? [];

  return (
    <>
      <Select
        {...restProps}
        id={restProps.name}
        loading={loading}
        size="small"
        allowClear
        showSearch
        placeholder="Belegnummer auswählen"
        filterOption={selectFilterOption}
        onSelect={setBelegnummerText}
        onSearch={(value) => {
          belegnummerText.length === 1 && !value && setBelegnummerText(''); // Checking the length of the belegnummerText makes the search component editable
          value && setBelegnummerText(value);
        }}
        onClear={() => setBelegnummerText('')}
        searchValue={belegnummerText}
        notFoundContent={<SelectNotFoundContent loading={loading} text={debouncedText as string} />}
      >
        {belegnummerList?.map((belegnummer) => (
          <Select.Option key={belegnummer} value={belegnummer}>
            <DataWithShortenedText text={belegnummer} maxTextLength={40} />
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default FibuBaBelegnummerSelect;
