import { FormFields } from '../../../helpers/formikHelper';
import { BankSettings } from '../../../types';

export interface BankSettingsUpdateFormValues {
  sepaCreditorId?: string | null;
}

export const bankSettingsUpdateFormFields: FormFields<BankSettingsUpdateFormValues> = {
  sepaCreditorId: 'sepaCreditorId',
};

export const mapBankSettingsToFormValues = (values?: BankSettings | null): BankSettingsUpdateFormValues => ({
  sepaCreditorId: values ? values.sepaCreditorId : null,
});
