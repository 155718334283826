import { Dayjs } from 'dayjs';
import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { EigenbelegErstellenGenerierlaufInput } from '../../../../types';

export type EigenbelegFreigebenModalValues = {
  belegdatum: Dayjs;
  generierDatum: Dayjs;
};

export const eigenbelegFreigebenModalFormFields: FormFields<EigenbelegFreigebenModalValues> = {
  belegdatum: 'belegdatum',
  generierDatum: 'generierDatum',
};

export const mapFormValuesToEigenbelegFreigeben = (values: EigenbelegFreigebenModalValues): EigenbelegErstellenGenerierlaufInput => ({
  generierDatum: mapFormDateValueToDateString(values.generierDatum),
  belegdatum: mapFormDateValueToDateString(values.belegdatum),
});
