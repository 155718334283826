import { mapToQueryString, QueryParams } from '../../../../../../hooks/useStringQueryParams';
import { GenerierlaufEntryExitCode } from '../../../../../../types';
import { PaginationQueryParamsProps } from '../../../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../../../verarbeitungApiPaths';

enum VorschreibungErstellenVerarbeitungQueryParamKey {
  VORSCHREIBUNGSPERIODE = 'vorschreibungsperiode',
  AUSSTELLER_ID = 'ausstellerId',
  EMPFAENGER_ID = 'empfaengerId',
  OBJEKT_ID = 'objektId',
  EXIT_CODE_LIST = 'exitCodeList',
}

export type TVorschreibungErstellenVerarbeitungQueryParams = QueryParams<{
  vorschreibungsperiode: string;
  ausstellerId: string;
  empfaengerId: string;
  objektId: string;
  exitCodeList: GenerierlaufEntryExitCode[];
}>;

export const updateQueryParams = (
  verarbeitungType: string,
  generierlaufId: string,
  navigate: (path: string) => void,
  filters: TVorschreibungErstellenVerarbeitungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) =>
  navigate(
    generatePathToVorschreibungErstellenVerarbeitungPage(
      verarbeitungType,
      generierlaufId,
      filters.vorschreibungsperiode,
      filters.ausstellerId,
      filters.empfaengerId,
      filters.objektId,
      filters.exitCodeList,
      paginationParams
    )
  );

export const generatePathToVorschreibungErstellenVerarbeitungPage = (
  verarbeitungType: string,
  generierlaufId: string,
  vorschreibungsperiode?: string,
  ausstellerId?: string,
  empfaengerId?: string,
  objektId?: string,
  exitCodeList?: GenerierlaufEntryExitCode[],
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [VorschreibungErstellenVerarbeitungQueryParamKey.VORSCHREIBUNGSPERIODE]: vorschreibungsperiode,
    [VorschreibungErstellenVerarbeitungQueryParamKey.OBJEKT_ID]: objektId,
    [VorschreibungErstellenVerarbeitungQueryParamKey.AUSSTELLER_ID]: ausstellerId,
    [VorschreibungErstellenVerarbeitungQueryParamKey.EMPFAENGER_ID]: empfaengerId,
    [VorschreibungErstellenVerarbeitungQueryParamKey.EXIT_CODE_LIST]: exitCodeList,
    ...paginationParams,
  })}`;
