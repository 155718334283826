import { FC } from 'react';
import { useRechnungsAusstellerMahndefinitionChangeHistoryListQuery } from '../gql/RechnungsAusstellerQueries.types';
import { HistoryType } from '../../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../../History/HistoryListingTable';

type Props = {
  rechtstraegerId: string;
};

const MahndefinitionChangeHistoryListingTable: FC<Props> = ({ rechtstraegerId }) => {
  const { data, loading } = useRechnungsAusstellerMahndefinitionChangeHistoryListQuery({ variables: { rechtstraegerId } });
  const historyList = data?.getRechnungsAusstellerMahndefinitionChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="mahndefinition" />;
};

export default MahndefinitionChangeHistoryListingTable;
