import React, { FC } from 'react';
import { Button, Dropdown, MenuProps } from 'antd';
import { EditOutlined, EllipsisOutlined, HistoryOutlined } from '@ant-design/icons';
import { radixActionStyles } from '../../../helpers/radixIconsStyles';

type Props = {
  onClickEdit: () => void;
  onClickChangeHistoryOpen: () => void;
};

const AllgemeineEinstellungenCardActions: FC<Props> = ({ onClickEdit, onClickChangeHistoryOpen }) => {
  const items: MenuProps['items'] = [
    { label: 'Bearbeiten', key: '1', onClick: onClickEdit, icon: <EditOutlined /> },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: onClickChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
  ];

  return (
    <Dropdown menu={{ items }}>
      <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
    </Dropdown>
  );
};

export default AllgemeineEinstellungenCardActions;
