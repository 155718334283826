import React, { FC } from 'react';
import { Col } from 'antd';
import { Input } from 'formik-antd';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import { ebicsBankConfigFormFields } from './ebicsBankConfigFormMapper';

const EbicsVersionH005FormInputs: FC = () => {
  return (
    <>
      <Col span={24}>
        <FormItemWithoutColon name={ebicsBankConfigFormFields.creditTransferBtf} label="EBICS 3.0 BTF - Pain001">
          <Input size="small" name={ebicsBankConfigFormFields.creditTransferBtf} placeholder={placeholder} />
        </FormItemWithoutColon>
      </Col>
      <Col span={24}>
        <FormItemWithoutColon name={ebicsBankConfigFormFields.directDebitBtf} label="EBICS 3.0 BTF - Pain008">
          <Input size="small" name={ebicsBankConfigFormFields.directDebitBtf} placeholder={placeholder} />
        </FormItemWithoutColon>
      </Col>
      <Col span={24}>
        <FormItemWithoutColon name={ebicsBankConfigFormFields.directDebitStatusBtf} label="EBICS 3.0 BTF - REP mit Pain002 für Lastschrift">
          <Input size="small" name={ebicsBankConfigFormFields.directDebitStatusBtf} placeholder={placeholder} />
        </FormItemWithoutColon>
      </Col>
      <Col span={24}>
        <FormItemWithoutColon name={ebicsBankConfigFormFields.creditTransferStatusBtf} label="EBICS 3.0 BTF - REP mit Pain002 für Überweisung">
          <Input size="small" name={ebicsBankConfigFormFields.creditTransferStatusBtf} placeholder={placeholder} />
        </FormItemWithoutColon>
      </Col>
      <Col span={24}>
        <FormItemWithoutColon name={ebicsBankConfigFormFields.preApprovalBtf} label="EBICS 3.0 BTF - Camt52">
          <Input size="small" name={ebicsBankConfigFormFields.preApprovalBtf} placeholder={placeholder} />
        </FormItemWithoutColon>
      </Col>
      <Col span={24}>
        <FormItemWithoutColon name={ebicsBankConfigFormFields.bankStatementBtf} label="EBICS 3.0 BTF - Camt53">
          <Input size="small" name={ebicsBankConfigFormFields.bankStatementBtf} placeholder={placeholder} />
        </FormItemWithoutColon>
      </Col>
    </>
  );
};

const placeholder = 'z.B.: ServiceName(:Scope)(:ServiceOption)_MessageName(_Containertyp)';

export default EbicsVersionH005FormInputs;
