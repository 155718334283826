import { TPaymentQueryParams } from '../Filters/filtersQueryParams';
import { EmployeeApproval, PaymentBulkActionInput } from '../../../../types';
import { isPaymentEmployeeApprovalAll } from '../../paymentHelpers';

export const mapQueryParamsToApproveOrMarkAsNotedPaymentBulkAction = (
  queryParams: TPaymentQueryParams,
  excludeList: string[]
): PaymentBulkActionInput => ({
  ...queryParams,
  belegnummer: queryParams.belegnummer?.toString(),
  employeeApproval:
    isPaymentEmployeeApprovalAll(queryParams.employeeApproval) || !queryParams.employeeApproval
      ? undefined
      : (queryParams.employeeApproval as EmployeeApproval),
  excludeList,
});
