import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { DebitorData } from '../../op-list-debitor-types';
import pdfStyles from '../../../../styles/pdfStyles';
import OPDebitorBelegListing from './OPDebitorBelegListing';
import OPDebitorSummary from './OPDebitorSummary';

type Props = {
  debitorList: DebitorData[];
};

const OPDebitorTable: FC<Props> = ({ debitorList }) => (
  <View>
    {debitorList?.map((debitor, index) => (
      <View key={index} style={[index !== 0 ? pdfStyles.borderTopSmall : {}]}>
        <Text
          style={[
            pdfStyles.row,
            pdfStyles.textBulletin,
            {
              paddingLeft: '9.7%',
              fontWeight: 'bold',
            },
          ]}
        >
          {debitor.vertragspartnerBezeichnung}
        </Text>
        <OPDebitorBelegListing belegList={debitor.belegList} />
        <OPDebitorSummary summary={debitor.summary} />
      </View>
    ))}
  </View>
);

export default OPDebitorTable;
