import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { ObjektAbrechnungFieldsFragmentDoc } from './ObjektAbrechnungFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ObjektAbrechnungQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  objektAbrechnungId: Types.Scalars['ID']['input'];
}>;

export type ObjektAbrechnungQuery = {
  getObjektAbrechnung: {
    data: {
      bezeichnung: string;
      buchungskreisId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      fileId?: string | null;
      fromInclusive: string;
      generierlaufId: string;
      objektAbrechnungId: string;
      rechnungsausstellerId: string;
      toInclusive: string;
      abrechnungsdefinitionType: { text: string; value: Types.AbrechnungsdefinitionType };
      abrechnungskreisList: Array<{
        abrechnungsdefinitionId: string;
        abrechnungsdefinitionVersionId: string;
        abrechnungskreisId: string;
        auftSchluesselBezeichnung?: string | null;
        bezeichnung: string;
        saldo: number;
        sumAufwand: number;
        abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
        aufwand: {
          summe: number;
          kontoList: Array<{
            abweichenderAufteilungsschluesselBezeichnung?: string | null;
            aufteilungsschluesselList: Array<string>;
            bezeichnung: string;
            expenseReducing: boolean;
            kontoId: string;
            summe: number;
            buchungszeileList: Array<{
              auftragId?: string | null;
              belegDatum?: string | null;
              belegFileId?: string | null;
              belegId: string;
              belegnummer?: string | null;
              betrag: number;
              buchungIdList: Array<string>;
              dueDate?: string | null;
              standingInvoicePartialAmountId?: string | null;
              text: string;
              ustVomAufwandSteuersatz?: number | null;
              buchungskreisRechtstraeger: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              sollHaben: { text: string; value: Types.SollHaben };
              vertragspartner?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
            }>;
          }>;
        };
        erloes: {
          summe: number;
          kontoList: Array<{
            abweichenderAufteilungsschluesselBezeichnung?: string | null;
            aufteilungsschluesselList: Array<string>;
            bezeichnung: string;
            expenseReducing: boolean;
            kontoId: string;
            summe: number;
            buchungszeileList: Array<{
              auftragId?: string | null;
              belegDatum?: string | null;
              belegFileId?: string | null;
              belegId: string;
              belegnummer?: string | null;
              betrag: number;
              buchungIdList: Array<string>;
              dueDate?: string | null;
              standingInvoicePartialAmountId?: string | null;
              text: string;
              ustVomAufwandSteuersatz?: number | null;
              buchungskreisRechtstraeger: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              sollHaben: { text: string; value: Types.SollHaben };
              vertragspartner?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
            }>;
          }>;
        };
        ustVomAufwand?: {
          summe: number;
          kontoList: Array<{
            abweichenderAufteilungsschluesselBezeichnung?: string | null;
            aufteilungsschluesselList: Array<string>;
            bezeichnung: string;
            expenseReducing: boolean;
            kontoId: string;
            summe: number;
            buchungszeileList: Array<{
              auftragId?: string | null;
              belegDatum?: string | null;
              belegFileId?: string | null;
              belegId: string;
              belegnummer?: string | null;
              betrag: number;
              buchungIdList: Array<string>;
              dueDate?: string | null;
              standingInvoicePartialAmountId?: string | null;
              text: string;
              ustVomAufwandSteuersatz?: number | null;
              buchungskreisRechtstraeger: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              sollHaben: { text: string; value: Types.SollHaben };
              vertragspartner?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
            }>;
          }>;
        } | null;
      }>;
      errorList: Array<{ message: string; type: string }>;
      file?: {
        budgetingYear?: number | null;
        contentType: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        dunningReferenceDate?: string | null;
        fileId: string;
        geloescht: boolean;
        homeownerId?: string | null;
        invoiceDate?: string | null;
        invoiceIssuerId?: string | null;
        invoiceNumber?: string | null;
        invoiceRecipientId?: string | null;
        name: string;
        size: string;
        bestandseinheitList: Array<{ bestandseinheitId: string; bezeichnung: string }>;
        labelList: Array<{ label: string; value: string }>;
        objektList: Array<{ objektId: string; kurzBezeichnung: string }>;
        type: { text: string; value: Types.FileInfoType };
        dimensions?: { height: number; width: number } | null;
      } | null;
      infoAbrechnungskreisList: Array<{
        abrechnungsdefinitionId: string;
        abrechnungsdefinitionVersionId: string;
        abrechnungskreisId: string;
        bezeichnung: string;
        saldo: number;
        sumAufwand: number;
        vorsaldo: number;
        aufwand: {
          summe: number;
          kontoList: Array<{
            bezeichnung: string;
            kontoId: string;
            summe: number;
            buchungszeileList: Array<{
              auftragId?: string | null;
              belegDatum?: string | null;
              belegFileId?: string | null;
              belegId: string;
              belegnummer?: string | null;
              betrag: number;
              buchungIdList: Array<string>;
              dueDate?: string | null;
              standingInvoicePartialAmountId?: string | null;
              text: string;
              umbuchung?: boolean | null;
              umgebucht?: boolean | null;
              buchungskreisRechtstraeger: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              sollHaben: { text: string; value: Types.SollHaben };
              vertragspartner?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
            }>;
          }>;
        };
        erloes: {
          summe: number;
          kontoList: Array<{
            bezeichnung: string;
            kontoId: string;
            summe: number;
            buchungszeileList: Array<{
              auftragId?: string | null;
              belegDatum?: string | null;
              belegFileId?: string | null;
              belegId: string;
              belegnummer?: string | null;
              betrag: number;
              buchungIdList: Array<string>;
              dueDate?: string | null;
              standingInvoicePartialAmountId?: string | null;
              text: string;
              umbuchung?: boolean | null;
              umgebucht?: boolean | null;
              buchungskreisRechtstraeger: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              sollHaben: { text: string; value: Types.SollHaben };
              vertragspartner?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
            }>;
          }>;
        };
      }>;
      objekt: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        objektId: string;
        updatedByMitarbeiterId?: string | null;
        rechnungsAussteller: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        status: { text: string; value: Types.ObjektStatus; description?: string | null };
        verwaltungsart: { text: string; value: Types.Verwaltungsart };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      status: { description?: string | null; text: string; value: Types.AbrechnungStatus };
      topAbrechnungOverview?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        fileId?: string | null;
        fromInclusive: string;
        objektId: string;
        objektKurzBezeichnung: string;
        toInclusive: string;
        errorList: Array<{ message: string; type: string }>;
        topAbrechnungList: Array<{
          aufwand: number;
          bestandseinheitBezeichnung?: string | null;
          bestandseinheitId: string;
          bezeichnung: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          dueDate?: string | null;
          einleitungsText: string;
          einnahmen: number;
          ergebnisText: string;
          fileId?: string | null;
          fromInclusive: string;
          guthaben: boolean;
          objektId: string;
          objektKurzBezeichnung: string;
          rueckstandBestandnehmer: number;
          saldo: number;
          schlussText: string;
          toInclusive: string;
          topAbrechnungId: string;
          abrechnungskreisList: Array<{
            abrechnungskreisId: string;
            aufteilungsschluesselBezeichnung?: string | null;
            aufwand: number;
            aufwandGesamt: number;
            aufwandProzent?: number | null;
            bezeichnung: string;
            brutto: number;
            einnahmen: number;
            netto: number;
            steuersatz: number;
            ust: number;
            ustKategorieEntryId: string;
            ustKategorieId: string;
            ustKategorieVersionId: string;
            abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
            aufwandAufteilungList: Array<{
              aufteilungsschluesselBezeichnung: string;
              summe: number;
              kontoList: Array<{ bezeichnung: string; kontoId: string; summe: number }>;
              zeitscheibeAufwandList: Array<{
                anteilAufwand: number;
                aufwand: number;
                beAufteilungsschluesselWert?: number | null;
                bisInklusive: string;
                prozentAufteilungsschluesselAnteil: number;
                sumAufteilungsschluesselWert: number;
                vonInklusive: string;
              }>;
            }>;
            einnahmenBuchungszeileList: Array<{
              auftragId?: string | null;
              belegDatum: string;
              belegFileId?: string | null;
              belegId?: string | null;
              belegnummer: string;
              betrag: number;
              buchungId: string;
              buchungskreisId: string;
              dueDate: string;
              kontoBezeichnung: string;
              kontoId: string;
              kontoNummer: string;
              offenerBetrag: number;
              text: string;
              buchungskreisRechtstraeger: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              sollHaben: { text: string; value: Types.SollHaben };
              vertragspartner: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
            }>;
            ustKategorieStatus: { description?: string | null; text: string; value: Types.UstKategorieStatus };
            ustVomAufwandAnteilList: Array<{
              anteilNetto: number;
              basis: number;
              belastung: number;
              summe: number;
              ustVomAufwand: number;
              ustVomAufwandSteuersatz: number;
              grundlage: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand };
            }>;
          }>;
          dueDateDisplayOption?: { month?: number | null; type: { text: string; value: Types.DueDateDisplayOptionType } } | null;
          dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
          infoAbrechnungskreisList: Array<{
            abrechnungsdefinitionId: string;
            abrechnungsdefinitionVersionId: string;
            abrechnungskreisId: string;
            bezeichnung: string;
            saldo: number;
            sumAufwand: number;
            vorsaldo: number;
            aufwand: {
              summe: number;
              kontoList: Array<{
                bezeichnung: string;
                kontoId: string;
                summe: number;
                buchungszeileList: Array<{
                  auftragId?: string | null;
                  belegDatum?: string | null;
                  belegFileId?: string | null;
                  belegId: string;
                  belegnummer?: string | null;
                  betrag: number;
                  buchungIdList: Array<string>;
                  dueDate?: string | null;
                  standingInvoicePartialAmountId?: string | null;
                  text: string;
                  umbuchung?: boolean | null;
                  umgebucht?: boolean | null;
                  buchungskreisRechtstraeger: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  };
                  sollHaben: { text: string; value: Types.SollHaben };
                  vertragspartner?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                }>;
              }>;
            };
            erloes: {
              summe: number;
              kontoList: Array<{
                bezeichnung: string;
                kontoId: string;
                summe: number;
                buchungszeileList: Array<{
                  auftragId?: string | null;
                  belegDatum?: string | null;
                  belegFileId?: string | null;
                  belegId: string;
                  belegnummer?: string | null;
                  betrag: number;
                  buchungIdList: Array<string>;
                  dueDate?: string | null;
                  standingInvoicePartialAmountId?: string | null;
                  text: string;
                  umbuchung?: boolean | null;
                  umgebucht?: boolean | null;
                  buchungskreisRechtstraeger: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  };
                  sollHaben: { text: string; value: Types.SollHaben };
                  vertragspartner?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                }>;
              }>;
            };
          }>;
          paymentMethod: { text: string; value: Types.PaymentMethod };
          rechnungsAussteller: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          status: { description?: string | null; text: string; value: Types.TopAbrechnungStatus };
          type: { text: string; value: Types.AbrechnungType };
          verrechnungsart: { text: string; value: Types.Verrechnungsart };
          vertragspartner: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        zahlungsrueckstandList?: Array<{
          offenerBetrag: number;
          letzteBuchungAm?: string | null;
          bestandseinheit: {
            bestandseinheitId: string;
            bezeichnung: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            objektId: string;
            status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          rechtstraeger: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
        }> | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      type: { description?: string | null; text: string; value: Types.AbrechnungType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const ObjektAbrechnungDocument = gql`
  query ObjektAbrechnung($objektId: ID!, $objektAbrechnungId: ID!) {
    getObjektAbrechnung(objektId: $objektId, objektAbrechnungId: $objektAbrechnungId) {
      data {
        ...ObjektAbrechnungFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${ObjektAbrechnungFieldsFragmentDoc}
`;
export function useObjektAbrechnungQuery(
  baseOptions: Apollo.QueryHookOptions<ObjektAbrechnungQuery, ObjektAbrechnungQueryVariables> &
    ({ variables: ObjektAbrechnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ObjektAbrechnungQuery, ObjektAbrechnungQueryVariables>(ObjektAbrechnungDocument, options);
}
export function useObjektAbrechnungLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjektAbrechnungQuery, ObjektAbrechnungQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ObjektAbrechnungQuery, ObjektAbrechnungQueryVariables>(ObjektAbrechnungDocument, options);
}
export function useObjektAbrechnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ObjektAbrechnungQuery, ObjektAbrechnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ObjektAbrechnungQuery, ObjektAbrechnungQueryVariables>(ObjektAbrechnungDocument, options);
}
export type ObjektAbrechnungQueryHookResult = ReturnType<typeof useObjektAbrechnungQuery>;
export type ObjektAbrechnungLazyQueryHookResult = ReturnType<typeof useObjektAbrechnungLazyQuery>;
export type ObjektAbrechnungSuspenseQueryHookResult = ReturnType<typeof useObjektAbrechnungSuspenseQuery>;
export type ObjektAbrechnungQueryResult = Apollo.QueryResult<ObjektAbrechnungQuery, ObjektAbrechnungQueryVariables>;
