import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { FirmendatenInitializerDetailsPageRouteParams } from './routes';
import menuListInfoFirmendatenInitializer from './menuListInfoFirmendatenInitializer';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import { FirmendatenInitializerGenerierlauf } from '../../../types';
import { useFirmendatenInitializerGenerierlaufQuery } from '../../../features/Verarbeitung/gql/FirmendatenInitializer/FirmendatenInitializerGenerierlaufQueries.types';
import { useRestartFirmendatenInitializerGenerierlaufMutation } from '../../../features/Verarbeitung/gql/FirmendatenInitializer/FirmendatenInitializerGenerierlaufMutations.types';

const FirmendatenInitializerDetailsPage = () => {
  const { generierlaufId } = useParams() as FirmendatenInitializerDetailsPageRouteParams;

  const { data, refetch } = useFirmendatenInitializerGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getFirmendatenInitializerGenerierlauf.data;

  const [runRestart] = useRestartFirmendatenInitializerGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<FirmendatenInitializerGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.FirmendatenInitializer}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.FirmendatenInitializer}
          onGenerierlaufRestart={runRestart}
        />
      )}
      isLoading={false}
      sidebarMenuList={menuListInfoFirmendatenInitializer}
    />
  );
};

export default FirmendatenInitializerDetailsPage;
