import { FC } from 'react';
import { Typography } from 'antd';
import theme from '../../theme';
import { isNotNil } from '../../helpers/assertionHelper';

export const EMPTY_VALUE = '';
export const EMPTY_VALUE_WITH_MINUS = '-';
export const EMPTY_VALUE_WITH_N_V = 'N.V.';

type TextForEmptyValueProps = {
  textToShow: 'NV' | 'minus' | 'none';
  /** Use inherit if component should be rendered e.g. in a Tooltip */
  color?: 'inherit';
  fontWeight?: 'normal' | 'bold';
  isCurrent?: boolean;
};

/**
 *
 * <h2>Usage</h2>
 * Wherever we have an empty value or no specific data and need to render '', '-' or 'N.A'
 *
 */

const TextForEmptyValue: FC<TextForEmptyValueProps> = ({ textToShow, color, isCurrent, fontWeight = 'normal' }) => {
  const emptyValueText = () => {
    switch (textToShow) {
      case 'NV':
        return EMPTY_VALUE_WITH_N_V;
      case 'minus':
        return EMPTY_VALUE_WITH_MINUS;
      default:
        return EMPTY_VALUE;
    }
  };

  let colorToUse;

  if (color) {
    colorToUse = color;
  }

  if (isNotNil(isCurrent) && !isCurrent) {
    colorToUse = theme.textColor.disabled;
  }

  return <Typography.Text style={{ color: colorToUse ?? undefined, fontWeight }}>{emptyValueText()}</Typography.Text>;
};

export default TextForEmptyValue;
