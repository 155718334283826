import React, { FC } from 'react';
import { useBeVertragVersionListQuery } from './gql/BeVertragVersionQueries.types';
import VersionTimeline from '../../../../components/Timeline/VersionTimeline';
import VertragVersionForm from './Form/VertragVersionForm';
import { useDeleteBeVertragVersionMutation } from './gql/BeVertragVersionMutations.types';
import { showSuccessMsgDelete } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import VertragVersionCardContent from './VertragVersionCardContent';
import { BeVertragVersion, DueDateVorschreibungArt, PaymentMethod, Verrechnungsart } from '../../../../types';
import { BeVertragFragment } from '../gql/BeVertragFragments.types';

type VertragVersionTimelineProps = {
  objektId: string;
  bestandseinheitId: string;
  vertrag: BeVertragFragment;
  reloadBeVertrag: () => void;
};

const VertragVersionTimeline: FC<VertragVersionTimelineProps> = ({ objektId, bestandseinheitId, vertrag, reloadBeVertrag }) => {
  const {
    loading,
    data: maybeVertragVersionListData,
    refetch: reloadVertragVersionList,
  } = useBeVertragVersionListQuery({
    variables: {
      objektId,
      bestandseinheitId,
      vertragId: vertrag.vertragId,
      withDetails: true,
    },
  });
  const vertragVersionList = maybeVertragVersionListData?.getBeVertragVersionList.data ?? [];

  const onActionSuccess = () => {
    reloadBeVertrag();
    reloadVertragVersionList();
  };

  const [runDelete] = useDeleteBeVertragVersionMutation({
    onCompleted: () => {
      showSuccessMsgDelete(`Vertragsversion`);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <VersionTimeline<BeVertragVersion>
      dataSource={vertragVersionList}
      versionIdKey="vertragVersionId"
      loading={loading}
      defaultNewTimeblock={{
        vertragVersionId: '',
        dueDateVorschreibung: { text: '', value: DueDateVorschreibungArt.MonatsFuenfter },
        verrechnungsart: { text: '', value: Verrechnungsart.Einzelrechnung },
        paymentMethod: { text: '', value: PaymentMethod.Ueberweisung },
        warningList: [],
      }}
      renderForm={(timeblock) => (
        <VertragVersionForm
          timeblock={timeblock}
          objektId={objektId}
          bestandseinheitId={bestandseinheitId}
          vertragId={vertrag.vertragId}
          onSubmitSuccess={onActionSuccess}
        />
      )}
      isAllowedToDeleteInitialTimeblock={false}
      onDelete={(timeblock) =>
        runDelete({
          variables: {
            objektId,
            bestandseinheitId,
            vertragId: vertrag.vertragId,
            vertragVersionId: timeblock.vertragVersionId,
          },
        })
      }
      kommentarForCardTitle={(timeblock) => timeblock.kommentar}
      renderCardContent={(timeblock) => <VertragVersionCardContent timeblock={timeblock} />}
    />
  );
};

export default VertragVersionTimeline;
