import React, { FC } from 'react';
import { Input } from 'formik-antd';
import { FormikProps } from 'formik';
import { FormFields } from '../../../../../../../helpers/formikHelper';
import { WeiterePersonCreateFormValues } from '../Create/weiterePersonCreateFormMapper';
import RechtstraegerSelect from '../../../../../../../shared/Rechtstraeger/RechtstraegerSelect';
import { RechtstraegerType } from '../../../../../../../types';
import DatenTabColumnsLayout from '../../../../../../Objekt/InfoFeld/Form/shared/DatenTabColumnsLayout';
import { WeiterePersonUpdateFormValues } from '../Update/weiterePersonUpdateFormMapper';
import FormItemWithFieldHelp from '../../../../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../../../../FieldHelp/useGetFieldHelpText';

type Props = {
  formFields: FormFields<WeiterePersonCreateFormValues> | FormFields<WeiterePersonUpdateFormValues>;
  formikProps: FormikProps<WeiterePersonCreateFormValues> | FormikProps<WeiterePersonUpdateFormValues>;
};

const WeiterePersonDatenTab: FC<Props> = ({ formFields, formikProps }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'VertragInfoFeldWeiterePerson'>('VertragInfoFeldWeiterePerson');

  return (
    <DatenTabColumnsLayout
      leftColumn={
        <FormItemWithFieldHelp
          name={formFields.natuerlichePersonId}
          label="Natürliche Person"
          fieldHelp={getFieldHelpText('VertragInfoFeldWeiterePerson.natuerlichePersonId')}
        >
          <RechtstraegerSelect
            name={formFields.natuerlichePersonId}
            typeList={[RechtstraegerType.NatuerlichePerson]}
            onNewItemCreated={(rechtstraegerId) => formikProps.setFieldValue(formFields.natuerlichePersonId, rechtstraegerId)}
            onNewItemCreateSelected={() => formikProps.setFieldValue(formFields.natuerlichePersonId, '')}
            onClear={() => {
              formikProps.setFieldTouched(formFields.natuerlichePersonId, true);
            }}
          />
        </FormItemWithFieldHelp>
      }
      rightColumn={
        <FormItemWithFieldHelp name={formFields.text} label="Text" fieldHelp={getFieldHelpText('VertragInfoFeldWeiterePerson.text')}>
          <Input id={formFields.text} name={formFields.text} size="small" />
        </FormItemWithFieldHelp>
      }
    />
  );
};

export default WeiterePersonDatenTab;
