import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { BudgetingAbrDefVPosLinkageFieldsFragmentDoc } from '../../BudgetingAbrDefVPosLinkage/gql/budgetingAbrDefVPosLinkageFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BudgetingAbrDefVPosLinkageTemplateListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type BudgetingAbrDefVPosLinkageTemplateListQuery = {
  getBudgetingAbrDefVPosLinkageTemplateList: {
    data: Array<{
      budgetingAbrDefVPosLinkageId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      firmendatenId?: string | null;
      kundenSystemId?: string | null;
      updatedByMitarbeiterId?: string | null;
      abrKreisVPosList: Array<{
        abrechnungskreis: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        vorschreibungsposition: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          vorschreibungspositionId: string;
          art: { text: string; value: Types.VorschreibungspositionArt };
          kontierungstabelle?: {
            basiskonto: number;
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kontierungstabelleId: string;
            updatedByMitarbeiterId?: string | null;
            status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
            subAdministrationExpenseAccount?: {
              bezeichnung: string;
              firmendatenId?: string | null;
              kontoId: string;
              kontoKlasse: string;
              nummer: string;
            } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
          umsatzsteuerkennzeichen?: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ message: string; type: string; attribute?: string | null }>;
          } | null;
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        };
      }>;
      abrechnungsdefinition: {
        abrechnungsdefinitionId: string;
        bezeichnung: string;
        status?: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus } | null;
        type: { text: string; value: Types.AbrechnungsdefinitionType };
      };
      warningList: Array<{ attribute?: string | null; type: string; message: string }>;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingAbrDefVPosLinkageTemplateQueryVariables = Types.Exact<{
  budgetingAbrDefVPosLinkageId: Types.Scalars['ID']['input'];
}>;

export type BudgetingAbrDefVPosLinkageTemplateQuery = {
  getBudgetingAbrDefVPosLinkageTemplate: {
    data: {
      budgetingAbrDefVPosLinkageId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      firmendatenId?: string | null;
      kundenSystemId?: string | null;
      updatedByMitarbeiterId?: string | null;
      abrKreisVPosList: Array<{
        abrechnungskreis: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        vorschreibungsposition: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          vorschreibungspositionId: string;
          art: { text: string; value: Types.VorschreibungspositionArt };
          kontierungstabelle?: {
            basiskonto: number;
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kontierungstabelleId: string;
            updatedByMitarbeiterId?: string | null;
            status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
            subAdministrationExpenseAccount?: {
              bezeichnung: string;
              firmendatenId?: string | null;
              kontoId: string;
              kontoKlasse: string;
              nummer: string;
            } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
          umsatzsteuerkennzeichen?: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ message: string; type: string; attribute?: string | null }>;
          } | null;
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        };
      }>;
      abrechnungsdefinition: {
        abrechnungsdefinitionId: string;
        bezeichnung: string;
        status?: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus } | null;
        type: { text: string; value: Types.AbrechnungsdefinitionType };
      };
      warningList: Array<{ attribute?: string | null; type: string; message: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingAbrDefVPosLinkageTemplateChangeHistoryListQueryVariables = Types.Exact<{
  budgetingAbrDefVPosLinkageId: Types.Scalars['ID']['input'];
}>;

export type BudgetingAbrDefVPosLinkageTemplateChangeHistoryListQuery = {
  getBudgetingAbrDefVPosLinkageTemplateChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const BudgetingAbrDefVPosLinkageTemplateListDocument = gql`
  query BudgetingAbrDefVPosLinkageTemplateList {
    getBudgetingAbrDefVPosLinkageTemplateList {
      data {
        ...BudgetingAbrDefVPosLinkageFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${BudgetingAbrDefVPosLinkageFieldsFragmentDoc}
`;
export function useBudgetingAbrDefVPosLinkageTemplateListQuery(
  baseOptions?: Apollo.QueryHookOptions<BudgetingAbrDefVPosLinkageTemplateListQuery, BudgetingAbrDefVPosLinkageTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BudgetingAbrDefVPosLinkageTemplateListQuery, BudgetingAbrDefVPosLinkageTemplateListQueryVariables>(
    BudgetingAbrDefVPosLinkageTemplateListDocument,
    options
  );
}
export function useBudgetingAbrDefVPosLinkageTemplateListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BudgetingAbrDefVPosLinkageTemplateListQuery, BudgetingAbrDefVPosLinkageTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BudgetingAbrDefVPosLinkageTemplateListQuery, BudgetingAbrDefVPosLinkageTemplateListQueryVariables>(
    BudgetingAbrDefVPosLinkageTemplateListDocument,
    options
  );
}
export function useBudgetingAbrDefVPosLinkageTemplateListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BudgetingAbrDefVPosLinkageTemplateListQuery, BudgetingAbrDefVPosLinkageTemplateListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BudgetingAbrDefVPosLinkageTemplateListQuery, BudgetingAbrDefVPosLinkageTemplateListQueryVariables>(
    BudgetingAbrDefVPosLinkageTemplateListDocument,
    options
  );
}
export type BudgetingAbrDefVPosLinkageTemplateListQueryHookResult = ReturnType<typeof useBudgetingAbrDefVPosLinkageTemplateListQuery>;
export type BudgetingAbrDefVPosLinkageTemplateListLazyQueryHookResult = ReturnType<typeof useBudgetingAbrDefVPosLinkageTemplateListLazyQuery>;
export type BudgetingAbrDefVPosLinkageTemplateListSuspenseQueryHookResult = ReturnType<typeof useBudgetingAbrDefVPosLinkageTemplateListSuspenseQuery>;
export type BudgetingAbrDefVPosLinkageTemplateListQueryResult = Apollo.QueryResult<
  BudgetingAbrDefVPosLinkageTemplateListQuery,
  BudgetingAbrDefVPosLinkageTemplateListQueryVariables
>;
export const BudgetingAbrDefVPosLinkageTemplateDocument = gql`
  query BudgetingAbrDefVPosLinkageTemplate($budgetingAbrDefVPosLinkageId: ID!) {
    getBudgetingAbrDefVPosLinkageTemplate(budgetingAbrDefVPosLinkageId: $budgetingAbrDefVPosLinkageId) {
      data {
        ...BudgetingAbrDefVPosLinkageFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${BudgetingAbrDefVPosLinkageFieldsFragmentDoc}
`;
export function useBudgetingAbrDefVPosLinkageTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<BudgetingAbrDefVPosLinkageTemplateQuery, BudgetingAbrDefVPosLinkageTemplateQueryVariables> &
    ({ variables: BudgetingAbrDefVPosLinkageTemplateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BudgetingAbrDefVPosLinkageTemplateQuery, BudgetingAbrDefVPosLinkageTemplateQueryVariables>(
    BudgetingAbrDefVPosLinkageTemplateDocument,
    options
  );
}
export function useBudgetingAbrDefVPosLinkageTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BudgetingAbrDefVPosLinkageTemplateQuery, BudgetingAbrDefVPosLinkageTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BudgetingAbrDefVPosLinkageTemplateQuery, BudgetingAbrDefVPosLinkageTemplateQueryVariables>(
    BudgetingAbrDefVPosLinkageTemplateDocument,
    options
  );
}
export function useBudgetingAbrDefVPosLinkageTemplateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BudgetingAbrDefVPosLinkageTemplateQuery, BudgetingAbrDefVPosLinkageTemplateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BudgetingAbrDefVPosLinkageTemplateQuery, BudgetingAbrDefVPosLinkageTemplateQueryVariables>(
    BudgetingAbrDefVPosLinkageTemplateDocument,
    options
  );
}
export type BudgetingAbrDefVPosLinkageTemplateQueryHookResult = ReturnType<typeof useBudgetingAbrDefVPosLinkageTemplateQuery>;
export type BudgetingAbrDefVPosLinkageTemplateLazyQueryHookResult = ReturnType<typeof useBudgetingAbrDefVPosLinkageTemplateLazyQuery>;
export type BudgetingAbrDefVPosLinkageTemplateSuspenseQueryHookResult = ReturnType<typeof useBudgetingAbrDefVPosLinkageTemplateSuspenseQuery>;
export type BudgetingAbrDefVPosLinkageTemplateQueryResult = Apollo.QueryResult<
  BudgetingAbrDefVPosLinkageTemplateQuery,
  BudgetingAbrDefVPosLinkageTemplateQueryVariables
>;
export const BudgetingAbrDefVPosLinkageTemplateChangeHistoryListDocument = gql`
  query BudgetingAbrDefVPosLinkageTemplateChangeHistoryList($budgetingAbrDefVPosLinkageId: ID!) {
    getBudgetingAbrDefVPosLinkageTemplateChangeHistoryList(budgetingAbrDefVPosLinkageId: $budgetingAbrDefVPosLinkageId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useBudgetingAbrDefVPosLinkageTemplateChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    BudgetingAbrDefVPosLinkageTemplateChangeHistoryListQuery,
    BudgetingAbrDefVPosLinkageTemplateChangeHistoryListQueryVariables
  > &
    ({ variables: BudgetingAbrDefVPosLinkageTemplateChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BudgetingAbrDefVPosLinkageTemplateChangeHistoryListQuery, BudgetingAbrDefVPosLinkageTemplateChangeHistoryListQueryVariables>(
    BudgetingAbrDefVPosLinkageTemplateChangeHistoryListDocument,
    options
  );
}
export function useBudgetingAbrDefVPosLinkageTemplateChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BudgetingAbrDefVPosLinkageTemplateChangeHistoryListQuery,
    BudgetingAbrDefVPosLinkageTemplateChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BudgetingAbrDefVPosLinkageTemplateChangeHistoryListQuery,
    BudgetingAbrDefVPosLinkageTemplateChangeHistoryListQueryVariables
  >(BudgetingAbrDefVPosLinkageTemplateChangeHistoryListDocument, options);
}
export function useBudgetingAbrDefVPosLinkageTemplateChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BudgetingAbrDefVPosLinkageTemplateChangeHistoryListQuery,
        BudgetingAbrDefVPosLinkageTemplateChangeHistoryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BudgetingAbrDefVPosLinkageTemplateChangeHistoryListQuery,
    BudgetingAbrDefVPosLinkageTemplateChangeHistoryListQueryVariables
  >(BudgetingAbrDefVPosLinkageTemplateChangeHistoryListDocument, options);
}
export type BudgetingAbrDefVPosLinkageTemplateChangeHistoryListQueryHookResult = ReturnType<
  typeof useBudgetingAbrDefVPosLinkageTemplateChangeHistoryListQuery
>;
export type BudgetingAbrDefVPosLinkageTemplateChangeHistoryListLazyQueryHookResult = ReturnType<
  typeof useBudgetingAbrDefVPosLinkageTemplateChangeHistoryListLazyQuery
>;
export type BudgetingAbrDefVPosLinkageTemplateChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useBudgetingAbrDefVPosLinkageTemplateChangeHistoryListSuspenseQuery
>;
export type BudgetingAbrDefVPosLinkageTemplateChangeHistoryListQueryResult = Apollo.QueryResult<
  BudgetingAbrDefVPosLinkageTemplateChangeHistoryListQuery,
  BudgetingAbrDefVPosLinkageTemplateChangeHistoryListQueryVariables
>;
