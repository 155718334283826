import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../styles/pdfStyles';
import { columnWidthsAndAlignment } from './styles/zahlungsverlauf-table-styles';
import { ZahlungsverlaufListHeader } from '../../topAbrechnung-types';

type ColumnTitlesProps = {
  titles: ZahlungsverlaufListHeader;
};
const ColumnTitles: FC<ColumnTitlesProps> = ({ titles }) => (
  <View
    style={[
      pdfStyles.row,
      pdfStyles.textNormal,
      pdfStyles.borderBottomSmall,
      {
        paddingTop: '3mm',
        fontWeight: 'bold',
      },
    ]}
  >
    <Text style={[pdfStyles.textNormal, { ...columnWidthsAndAlignment.dueDate }]}>{titles.dueDateText}</Text>
    <Text style={[pdfStyles.textNormal, { ...columnWidthsAndAlignment.belegnummer }]}>{titles.belegnummerText}</Text>
    <Text style={[pdfStyles.textNormal, { ...columnWidthsAndAlignment.belegdatum }]}>{titles.belegdatumText}</Text>
    <Text style={[pdfStyles.textNormal, { ...columnWidthsAndAlignment.emptyAuszifferung }]} />
    <Text style={[pdfStyles.textNormal, { ...columnWidthsAndAlignment.vorschreibung }]}>{titles.vorschreibungText}</Text>
    <Text style={[pdfStyles.textNormal, { ...columnWidthsAndAlignment.offenerBetrag }]}>{titles.offenerBetragText}</Text>
  </View>
);

export default ColumnTitles;
