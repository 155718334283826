import React, { FC } from 'react';
import { Drawer, Tabs, TabsProps } from 'antd';
import { EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { Box } from 'rebass';
import { ITimelineState } from '../../../components/Timeline/useTimelineOpen';
import ForderungskontoForm from './Form/ForderungskontoForm';
import AufwandskontoVersionTimeline from './Version/AufwandskontoVersionTimeline';

type Props = {
  timelineDrawer: ITimelineState;
  closeTimelineDrawer: () => void;
  onSuccess: () => void;
  pauschalmieteForderungskontoId?: string | null;
};

const PaulschalmieteDrawer: FC<Props> = ({ timelineDrawer, closeTimelineDrawer, onSuccess, pauschalmieteForderungskontoId }) => {
  const onActionSuccess = () => {
    onSuccess();
    closeTimelineDrawer();
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span>
          <HistoryOutlined />
          Verlauf
        </span>
      ),
      children: <AufwandskontoVersionTimeline onSuccess={onSuccess} />,
    },
    {
      key: '2',
      label: (
        <span>
          <EditOutlined />
          Bearbeiten
        </span>
      ),
      children: (
        <Box p={16}>
          <ForderungskontoForm
            pauschalmieteForderungskontoId={pauschalmieteForderungskontoId}
            onSuccess={onActionSuccess}
            onCancel={closeTimelineDrawer}
          />
        </Box>
      ),
    },
  ];

  return (
    <Drawer title="Paulschalmiete" width={720} open={timelineDrawer.visible} onClose={closeTimelineDrawer} destroyOnClose>
      <Tabs defaultActiveKey="2" items={items} />
    </Drawer>
  );
};

export default PaulschalmieteDrawer;
