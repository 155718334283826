import React from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import AuftragsartUpdateForm from '../../../../../features/Auftragsart/Form/Update/AuftragsartUpdateForm';
import { AuftragsartUpdatePageRouteParams } from './routes';
import { useAuftragsartQuery } from '../../../../../features/Auftragsart/gql/AuftragsartQueries.types';

const SysSettingsAuftragsartUpdatePage = () => {
  const { auftragsartId } = useParams() as AuftragsartUpdatePageRouteParams;

  const { data, refetch } = useAuftragsartQuery({
    variables: {
      auftragsartId,
    },
  });

  const auftragsart = data?.getAuftragsart.data;

  if (!auftragsart) {
    return <Skeleton active />;
  }

  return <AuftragsartUpdateForm auftragsart={auftragsart} onSuccess={refetch} />;
};

export default SysSettingsAuftragsartUpdatePage;
