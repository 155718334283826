import React, { FC } from 'react';
import styled from 'styled-components';

import { Typography } from 'antd';
import { EuroAmount, Percent } from '../../../../../../components/Number';
import { TABLE_ALIGNED_COLS_CLASSNAMES } from '../../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsClassnames';
import { TopAbrKreisZeitscheibeAufwand } from '../../../../../../types';
import CustomFormattedDate from '../../../../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  zeitscheibe: TopAbrKreisZeitscheibeAufwand;
};

const AufwandZeitscheibeDetails: FC<Props> = ({ zeitscheibe }) => (
  <tr>
    <td colSpan={5} style={{ padding: 0 }}>
      <StyledTable style={{ width: '100%' }}>
        <thead className={TABLE_ALIGNED_COLS_CLASSNAMES.thead}>
          <tr>
            <th className={TABLE_ALIGNED_COLS_CLASSNAMES.cell}>
              <Typography.Text>Aufwandsaufteilung</Typography.Text>
            </th>
            <th className={TABLE_ALIGNED_COLS_CLASSNAMES.cell}>
              <Typography.Text>Anteil von Aufwand</Typography.Text>
            </th>
          </tr>
        </thead>
        <tbody className={TABLE_ALIGNED_COLS_CLASSNAMES.tbody}>
          <tr>
            <td style={{ paddingLeft: '30px' }}>
              <CustomFormattedDate value={zeitscheibe.vonInklusive} /> bis zum <CustomFormattedDate value={zeitscheibe.bisInklusive} />
            </td>
          </tr>
          <tr>
            <td style={{ paddingLeft: '54px' }}>
              {zeitscheibe.beAufteilungsschluesselWert} von {roundAufwand(zeitscheibe.sumAufteilungsschluesselWert)} entspricht{' '}
              <Percent value={zeitscheibe.prozentAufteilungsschluesselAnteil / 100} />
            </td>
          </tr>
          <tr>
            <td style={{ paddingLeft: '54px' }}>
              vom anteiligen Aufwand <EuroAmount value={roundAufwand(zeitscheibe.anteilAufwand)} />
            </td>
            <td>
              <EuroAmount value={zeitscheibe.aufwand} />
            </td>
          </tr>
        </tbody>
      </StyledTable>
    </td>
  </tr>
);

const StyledTable = styled('table')`
  thead {
    &:first-child th {
      &:first-child {
        padding-left: 30px;
      }

      &:last-child {
        text-align: right;
        width: 2%;
      }
    }
  }
  tbody {
    tr {
      td {
        border: 0;
      }
      &:last-child td {
        &:last-child {
          text-align: right;
        }
      }
    }
  }
`;

const roundAufwand = (num: number) => Math.round((num + Number.EPSILON) * 100) / 100;

export default AufwandZeitscheibeDetails;
