import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BookingDetailsPageQueryParams } from '../../features/BookingDetails/bookingDetailsPaths';
import { PageLayout } from '../../components/LayoutAndromeda';
import { useQueryParams } from '../../hooks/useStringQueryParams';
import BookingDetails from '../../features/BookingDetails/View/WithoutBookingSuggestion/BookingDetails';
import BookingDetailsEigenbelegPage from './BookingDetailsEigenbelegPage/BookingDetailsEigenbelegPage';
import BookingDetailsIncomingInvoicePage from './BookingDetailsIncomingInvoicePage/BookingDetailsIncomingInvoicePage';

const BookingDetailsPage = () => {
  const { buchungIdList, belegId, auftragId } = useQueryParams<BookingDetailsPageQueryParams>();

  let pageContent;
  if (auftragId) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    pageContent = <BookingDetailsEigenbelegPage auftragId={auftragId} buchungIdList={buchungIdList!} />;
  } else if (belegId) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    pageContent = <BookingDetailsIncomingInvoicePage belegId={belegId} buchungIdList={buchungIdList!} />;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    pageContent = <BookingDetails buchungIdList={buchungIdList!} belegId={belegId} />;
  }

  return (
    <>
      <Helmet>
        <title>Buchungsdetails</title>
      </Helmet>
      <PageLayout>{pageContent}</PageLayout>
    </>
  );
};

export default BookingDetailsPage;
