import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import FormButtons from '../../../../components/Button/FormButtons';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { UstKategorie } from '../../../../types';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import { useCreateUstKategorieTemplateMutation, useUpdateUstKategorieTemplateMutation } from '../gql/UstKategorieTemplateMutations.types';
import { ustKategorieFormValidationSchema } from '../../UstKategorie/shared/ustKategorieFormValidationSchema';
import {
  mapFormValuesToUstKategorie,
  mapUstKategorieToFormValues,
  ustKategorieFormFields,
  UstKategorieFormValues,
} from '../../UstKategorie/Form/ustKategorieFormMapper';

type Props = {
  ustKategorie?: UstKategorie;
  onSuccess: () => void;
  onCancel: () => void;
};

const UstKategorieTemplateForm: FC<Props> = ({ ustKategorie, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'UstKategorie'>('UstKategorie');
  const isUpdate = !!ustKategorie;
  const entity = 'USt-Kategorie';

  const [runCreate, { loading: loadingCreate }] = useCreateUstKategorieTemplateMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateUstKategorieTemplateMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<UstKategorieFormValues>
      initialValues={mapUstKategorieToFormValues(ustKategorie)}
      validationSchema={ustKategorieFormValidationSchema}
      onSubmit={(formValues, formikHelpers) => {
        const input = mapFormValuesToUstKategorie(formValues);
        if (!isUpdate) {
          runCreate({ variables: { input } }).finally(() => formikHelpers.setSubmitting(false));
        } else {
          runUpdate({ variables: { ustKategorieId: ustKategorie.ustKategorieId, input } }).finally(() => formikHelpers.setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithFieldHelp
            name={ustKategorieFormFields.kurzBezeichnung}
            label="Kurzbezeichnung"
            fieldHelp={getFieldHelpText('UstKategorie.kurzBezeichnung')}
          >
            <Input id="Kurzbezeichnung" name={ustKategorieFormFields.kurzBezeichnung} placeholder='z.B. USt-Kat "leicht zu merken"' />
          </FormItemWithFieldHelp>

          <FormButtons
            updateMode={isUpdate}
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default UstKategorieTemplateForm;
