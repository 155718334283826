import React, { FC } from 'react';
import { WizardFormWrapper } from '../../../../components/Wizard';
import FirmendatenBaseWizardStepForm from './FirmendatenBaseWizardStepForm';
import FirmensitzWizardStepForm from './FirmensitzWizardStepForm';
import { mapFirmendatenWizardValuesToFirmendaten } from './firmendatenCreateWizardMapper';
import { CreateKsFirmendatenInput } from '../../../../types';

const WIZARD_STEP_ID_FIRMENDATEN = 'Firmendaten';
const WIZARD_STEP_ID_MAIN_ADDRESS = 'Firmensitz';

type FirmendatenCreateWizardProps = {
  kundenSystemId: string;
  onCreate: (values: CreateKsFirmendatenInput) => void;
  onCancel: () => void;
  error?: Error;
};

const FirmendatenCreateWizard: FC<FirmendatenCreateWizardProps> = ({ kundenSystemId, onCreate, onCancel, error }) => (
  <WizardFormWrapper
    onWizardSubmit={(wizardValues) => {
      const firmendatenToCreate = mapWizardValuesToFirmendaten(wizardValues);
      onCreate(firmendatenToCreate);
    }}
    onWizardCancel={onCancel}
    error={error}
  >
    <FirmendatenBaseWizardStepForm stepId={WIZARD_STEP_ID_FIRMENDATEN} fieldHelpSelectorEntityName="Firmendaten" />
    <FirmensitzWizardStepForm kundenSystemId={kundenSystemId} stepId={WIZARD_STEP_ID_MAIN_ADDRESS} fieldHelpSelectorEntityName="Firmendaten" />
  </WizardFormWrapper>
);

const mapWizardValuesToFirmendaten = (wizardValues: Record<string, Record<string, unknown>>) => {
  const firmendatenBaseValues = wizardValues[WIZARD_STEP_ID_FIRMENDATEN];
  const firmensitzValues = wizardValues[WIZARD_STEP_ID_MAIN_ADDRESS];
  return mapFirmendatenWizardValuesToFirmendaten(firmendatenBaseValues, firmensitzValues);
};

export default FirmendatenCreateWizard;
