import React from 'react';
import { Navigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Location } from 'history';
import { Box } from 'rebass';
import { useAsync } from 'react-async';
import { Helmet } from 'react-helmet-async';
import { uri } from '../../constants/appUriPaths';
import { LoginPageLayout as Layout } from '../../components/LayoutAndromeda';
import { LoginForm } from '../../features/Login';
import { useAuth } from '../../shared/Auth/authContext';
import { callLogin } from '../../shared/Auth/loginApi';
import { showErrorNotification } from '../../components/message';
import { URI_LOGIN_PAGE_CHOOSE_FIRMA, URI_LOGIN_PAGE_USER_IS_NOT_MITARBEITER_ERROR } from '../../constants/loginUriPaths';

const LoginPage = () => {
  const { reloadUser, authState } = useAuth();
  const location = useLocation();
  const errorMsg = 'Login war fehlerhaft';

  const { run: runLogin, isLoading } = useAsync({
    deferFn: callLogin,
    onResolve: reloadUser,
    onReject: () => showErrorNotification(errorMsg),
  });

  if (authState === 'AUTHORIZED') {
    return <Navigate to={getOriginalFromPathOrDefault(location)} />;
  }
  if (authState === 'AUTH_CHOOSE_FIRMENDATEN') {
    return <Navigate to={{ pathname: URI_LOGIN_PAGE_CHOOSE_FIRMA }} state={{ from: location, to: getOriginalFromPathOrDefault(location) }} />;
  }
  if (authState === 'AUTH_NO_MITARBEITER_INFO') {
    return (
      <Navigate
        to={{
          pathname: URI_LOGIN_PAGE_USER_IS_NOT_MITARBEITER_ERROR,
        }}
        state={{ from: location, to: getOriginalFromPathOrDefault(location) }}
      />
    );
  }
  return (
    <>
      <Helmet>
        <title>Easy.Cloud – Anmelden oder Registrieren</title>
      </Helmet>
      <Layout>
        <Box width={360}>
          <LoginForm
            onSubmit={(values, onCompleted) => runLogin(values.email, values.password, onCompleted)}
            isLoading={isLoading || authState === 'PENDING'}
          />
        </Box>
      </Layout>
    </>
  );
};

const getOriginalFromPathOrDefault = (location: Location<LoginLocationState>) => {
  const fromPath = location.state ? location.state.from : null;
  return fromPath || uri.dashboard;
};

type LoginLocationState = { from: string } | null | undefined;

export default LoginPage;
