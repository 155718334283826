import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { PaymentRechtstraegerFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerFragments.types';
export type ReceivedBankTransactionFieldsFragment = {
  amount: number;
  booked: boolean;
  bookingDate?: string | null;
  bookingSuggestionIdList: Array<string>;
  charges?: number | null;
  chargesInSeparateEntry?: boolean | null;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  creditor?: string | null;
  creditorIban?: string | null;
  dataCarrierId: string;
  dataCarrierName: string;
  debtor?: string | null;
  debtorIban?: string | null;
  endToEndId?: string | null;
  entryReference?: string | null;
  noted: boolean;
  paymentReferenceText?: string | null;
  purposeOfUseText?: string | null;
  receivedBankTransactionId: string;
  reversal: boolean;
  requestedExecutionDate?: string | null;
  statementNumber: string;
  valid?: boolean | null;
  valueDate?: string | null;
  buchungskreisRechtstraeger?: { buchungskreisId?: string | null; kurzBezeichnung: string; rechtstraegerId: string } | null;
  payee?: { buchungskreisId?: string | null; kurzBezeichnung: string; rechtstraegerId: string } | null;
  paymentType?: { text: string; value: Types.PaymentType } | null;
  status: { text: string; value: Types.ReceivedBankTransactionStatus };
  transactionType: { text: string; value: Types.TransactionType };
};

export const ReceivedBankTransactionFieldsFragmentDoc = gql`
  fragment ReceivedBankTransactionFields on ReceivedBankTransaction {
    amount
    booked
    bookingDate
    bookingSuggestionIdList
    buchungskreisRechtstraeger {
      ...PaymentRechtstraeger
    }
    charges
    chargesInSeparateEntry
    createTs
    createdBy
    createdByMitarbeiterId
    creditor
    creditorIban
    dataCarrierId
    dataCarrierName
    debtor
    debtorIban
    endToEndId
    entryReference
    noted
    paymentReferenceText
    payee {
      ...PaymentRechtstraeger
    }
    paymentType {
      text
      value
    }
    purposeOfUseText
    receivedBankTransactionId
    reversal
    requestedExecutionDate
    statementNumber
    status {
      text
      value
    }
    transactionType {
      text
      value
    }
    valid
    valueDate
  }
  ${PaymentRechtstraegerFragmentDoc}
`;
