import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../styles/pdfStyles';

type Props = {
  ermaechtigungTextLine1: string;
  ermaechtigungTextLine2: string;
};

const SLMErmaechtigungText: FC<Props> = ({ ermaechtigungTextLine1, ermaechtigungTextLine2 }) => (
  <View style={pdfStyles.column}>
    <Text style={[pdfStyles.textNormal, { marginTop: '10mm' }]}>{ermaechtigungTextLine1}</Text>
    <Text style={[pdfStyles.textNormal, { marginTop: '5mm' }]}>{ermaechtigungTextLine2}</Text>
  </View>
);

export default SLMErmaechtigungText;
