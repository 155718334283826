import React, { FC } from 'react';
import { Divider, Empty, Table } from 'antd';
import { Spacer } from '../../../../../components/Grid';
import errorLogTableColumns from '../../../shared/errorLogTableColumns';
import { ErrorData } from '../../../../../types';

type Props = {
  heAbrHeVertragErrorList: ErrorData[];
};

const ErrorListing: FC<Props> = ({ heAbrHeVertragErrorList }) => {
  return (
    <>
      {heAbrHeVertragErrorList.length > 0 && (
        <>
          <Spacer height={16} />
          <Table<ErrorData>
            size="small"
            pagination={false}
            dataSource={heAbrHeVertragErrorList}
            rowKey={(record) => record.type}
            columns={errorLogTableColumns}
            locale={{
              emptyText: <Empty description={<span>Keine Fehler vorhanden</span>} />,
            }}
          />
          <Divider />
        </>
      )}
    </>
  );
};

export default ErrorListing;
