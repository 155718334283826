import React, { FC } from 'react';
import { SelectProps } from 'formik-antd';
import { useObjektVorschreibungspositionListQuery } from '../Objekt/VorschreibungPosition/gql/ObjektVorschreibungPositionQueries.types';
import { excludeArts, selectFilterOption } from '../../helpers/selectHelper';
import { EntityStatusFormSelect } from '../../components/Status/EntityStatus';
import { ISelectItemWithStatus } from '../../components/Status/EntityStatus/MainTypes/EntityStatusProps';
import { ObjektVorschreibungsposition, VorschreibungspositionArt } from '../../types';

type Props = {
  objektId: string;
  includeArchiviert: boolean;
  exludeArts?: VorschreibungspositionArt[] | undefined;
} & SelectProps;

const ObjektVPosSelect: FC<Props> = ({ objektId, includeArchiviert, exludeArts, ...restProps }) => {
  const { data, loading } = useObjektVorschreibungspositionListQuery({
    variables: {
      objektId,
      includeArchiviert,
    },
  });

  const objektVorschreibungspositionList = data?.getObjektVorschreibungspositionList.data ?? [];
  const exludeArtList = exludeArts ?? [];
  const listWithoutExcludedArts = objektVorschreibungspositionList.filter((item) => excludeArts(item, exludeArtList));

  const objektVPosListWithStatus = listWithoutExcludedArts.map((vPos) => {
    return { status: vPos.objektVorschreibungspositionStatus, ...vPos };
  });

  return (
    <EntityStatusFormSelect<ObjektVorschreibungsposition & ISelectItemWithStatus>
      list={objektVPosListWithStatus}
      getSelectItemId={(objektVPos) => objektVPos.objektVorschreibungspositionId}
      getSelectItemContent={(objektVPos) => objektVPos.bezeichnung}
      getSelectItemTooltipTitle={(objektVPos) => objektVPos.bezeichnung}
      {...restProps}
      id={restProps.name}
      loading={loading}
      size="small"
      placeholder="Vorschreibung auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
      style={{ width: '100%' }}
    />
  );
};

export default ObjektVPosSelect;
