import React, { FC } from 'react';
import { Space, Typography } from 'antd';

import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import { FibuAddress } from '../../../types';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';

type Props = {
  title: string;
  name?: string | null;
  address?: FibuAddress | null;
};

const AddressInfoRow: FC<Props> = ({ title, name, address }) => {
  return (
    <CardDetailsInfoRow infoRowTitle={title}>
      {address ? (
        <Space direction="vertical" size={0}>
          {name && (
            <Typography.Text>
              <DataWithShortenedText maxTextLength={25} text={name}>
                {(shortenedText) => <Typography.Text>{shortenedText}</Typography.Text>}
              </DataWithShortenedText>
            </Typography.Text>
          )}
          <Typography.Text>
            {address?.street} {address?.houseEntranceApartmentNumber}
          </Typography.Text>
          <Typography.Text>
            {address?.zipCode} {address?.city}
          </Typography.Text>
        </Space>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      )}
    </CardDetailsInfoRow>
  );
};

export default AddressInfoRow;
