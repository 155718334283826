import React, { FC, useEffect } from 'react';
import AbrErstellenBtnAndModal from '../../shared/AbrErstellenBtnAndModal';
import MietAbrechnungTable from './MietAbrechnungTable';
import { useAbrechnungListQuery } from '../../gql/AbrechnungQueries.types';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../hooks/useQueryWithPagingAndSorting';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { AbrechnungType, NotificationStatus, NotificationType, Objekt } from '../../../../types';
import { useGetNotificationSubscription } from '../../../Notification/gql/NotificationSubscription.types';
import { useAuth } from '../../../../shared/Auth/authContext';
import { useOnObjAbrDataChangeEvent } from '../useOnObjAbrDataChangeEvents';
import { useOnHeAbrDataChangeEvent } from '../useOnHeAbrDataChangeEvent';

type Props = {
  objekt: Objekt;
  isRefetchingObjekt: boolean;
};

// This listing will include Hauseigentuemerabrechnung, Betriebskostenabrechnung and Heizkostenabrechnung
const MietObjektAbrechnungListing: FC<Props> = ({ objekt, isRefetchingObjekt }) => {
  const { user, mitarbeiter } = useAuth();
  const username = user?.username;
  const mitarbeiterId = mitarbeiter?.mitarbeiterId;

  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, refetch, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useAbrechnungListQuery,
    { variables: { objektIdList: [objekt.objektId], typeList: [AbrechnungType.HeAbrechnung, AbrechnungType.BkAbrechnung] } },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  useGetNotificationSubscription({
    onData: (options) => {
      const notification = options.data.data?.getNotification;
      if (!notification) return;
      const { notificationType, status } = notification;
      if (
        !(notificationType === NotificationType.HeAbrechnungErstellung || notificationType === NotificationType.BkObjektAbrechnungErstellung) &&
        status !== NotificationStatus.Finished
      )
        return;
      refetch();
    },
    variables: {
      userId: username,
      mitarbeiterId,
    },
  });

  const abrechnungList = data?.getAbrechnungList.data.contentList;

  useOnObjAbrDataChangeEvent('bkAbrObjektAbrechnungListing', refetch, objekt.objektId);
  useOnHeAbrDataChangeEvent('heAbrObjAbrListing', refetch, objekt.objektId);

  useEffect(() => {
    if (isRefetchingObjekt) refetch();
  }, [isRefetchingObjekt, refetch]);

  return (
    <>
      <AbrErstellenBtnAndModal objekt={objekt} refetch={refetch} />
      <MietAbrechnungTable
        abrechnungList={abrechnungList}
        objekt={objekt}
        isRefetchingObjekt={isRefetchingObjekt}
        refetch={refetch}
        pagination={pagination}
        handleTableSorting={handleTableSorting}
      />
    </>
  );
};

export default MietObjektAbrechnungListing;
