import * as Yup from 'yup';
import { emailContactCreateFormFields } from './emailContactCreateFormMapper';
import { entityIsRequired } from '../../../../../../components/message/validationMsg';

/* eslint-disable no-template-curly-in-string */
export const emailContactValidationSchema = Yup.object().shape({
  [emailContactCreateFormFields.wertList]: Yup.array().of(
    Yup.string().email('E-Mail-Adresse muss gültig sein').required(entityIsRequired('E-Mail-Adresse'))
  ),
});
/* eslint-enable no-template-curly-in-string */
