import React from 'react';
import { Link } from 'react-router-dom';
import { Space, Typography } from 'antd';
import { SubAbrechnungGenerierlaufListEntry, SubAbrechnungGenerierlaufOrderBy } from '../../../../types';
import { NestedTableWithColSelectorColProps } from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import { compareTwoStringsForSorting } from '../../../../helpers/stringHelper';
import GenerierlaufEntryStatus from '../../shared/GenerierlaufEntryStatus';
import GenerierlaufEntryStep from '../../shared/GenerierlaufEntryStep';
import { generatePathToObjektDetailsPage } from '../../../Objekt/objektUriPaths';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { generatePathToRechtstraegerDetailsPage } from '../../../Rechtstraeger/rechtstraegerHelper';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { MitarbeiterTooltip } from '../../../../components/Card';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import StatusBadge from '../../../../components/Status/StatusBadge';
import { generatePathToSubAbrechnungEigentuemerDetailsPage } from '../../../Abrechnung/Sub/subAbrechnungEigentumerUriPaths';

const subAbrechnungEntryTableColumns: NestedTableWithColSelectorColProps<SubAbrechnungGenerierlaufListEntry>[] = [
  {
    title: 'Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.exitCode?.text, b.exitCode?.text),
    render: (text, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },
  {
    title: 'Schritte',
    render: (text, record) => <GenerierlaufEntryStep stepList={record.stepList} currentStep={record.currentStep} stepCount={record.stepCount} />,
  },
  {
    title: 'Objekt',
    defaultSelected: true,
    dataIndex: SubAbrechnungGenerierlaufOrderBy.Objekt,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={20} text={record.objektKurzBezeichnung}>
        {(shortenedText) => (
          <Link to={generatePathToObjektDetailsPage(record.objektId)} target="_blank">
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Eigentümer',
    defaultSelected: true,
    dataIndex: SubAbrechnungGenerierlaufOrderBy.Eigentuemer,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={20} text={record.eigentuemerKurzBezeichnung}>
        {(shortenedText) => (
          <Link to={generatePathToRechtstraegerDetailsPage(record.eigentuemerId)} target="_blank">
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Zeitraum',
    defaultSelected: true,
    dataIndex: SubAbrechnungGenerierlaufOrderBy.Zeitraum,
    sorter: true,
    render: (text, record) => (
      <Space>
        {record.fromInclusive && <CustomFormattedDate value={record.fromInclusive} />}
        {record.fromInclusive && record.toInclusive && <Typography.Text>-</Typography.Text>}
        {record.toInclusive && <CustomFormattedDate value={record.toInclusive} />}
      </Space>
    ),
  },
  {
    title: 'Erstellt am',
    render: (text, record) =>
      record.subAbrechnungDeletedTs ? <FormattedDateTime createdTs={record.subAbrechnungDeletedTs} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip
        mitarbeiterId={record.subAbrechnungDeletedByMitarbeiterId}
        userId={record.subAbrechnungDeletedBy}
        alignment="left"
        showsInitials
      />
    ),
  },
  {
    title: 'Status',
    defaultSelected: true,
    dataIndex: SubAbrechnungGenerierlaufOrderBy.Status,
    sorter: true,
    render: (text, record) => (record.status ? <StatusBadge entityStatus={record.status} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) =>
      record.subAbrechnungId && record.subAbrechnungEigentuemerId ? (
        <Link
          to={generatePathToSubAbrechnungEigentuemerDetailsPage(record.objektId, record.subAbrechnungId, record.subAbrechnungEigentuemerId)}
          target="_blank"
        >
          Details
        </Link>
      ) : (
        <TextForEmptyValue textToShow="none" />
      ),
  },
];

export default subAbrechnungEntryTableColumns;
