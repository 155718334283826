import React from 'react';
import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../components/Template/DetailsPageTemplate';
import { SubAbrechnungEigentuemer } from '../../types';
import { useSubAbrechnungEigentuemerQuery } from '../../features/Abrechnung/Sub/gql/SubAbrechnungEigentuemerQueries.types';
import { SubAbrechnungEigentuemerDetailsPageRouteParams } from './routes';
import menuListSubAbrechnungEigentuemer from './menuListSubAbrechnungEigentuemer';
import SubAbrechnungEigentuemerCard from '../../features/Abrechnung/Sub/Card/Eigentuemer/SubAbrechnungEigentuemerCard';

const SubAbrechnungEigentuemerDetailsPage = () => {
  const { objektId, subAbrechnungId, subAbrechnungEigentuemerId } = useParams() as SubAbrechnungEigentuemerDetailsPageRouteParams;
  const { data, loading } = useSubAbrechnungEigentuemerQuery({ variables: { objektId, subAbrechnungId, subAbrechnungEigentuemerId } });
  const subAbrechnungEigentuemer = data?.getSubAbrechnungEigentuemer.data;

  return (
    <DetailsPageTemplate<SubAbrechnungEigentuemer>
      pageTitle={(subAbrechung) => `Subverwaltungsabrechnung - ${subAbrechung}`}
      data={subAbrechnungEigentuemer}
      card={(subAbrechnungEigentuemer) => (
        <SubAbrechnungEigentuemerCard subAbrechnungEigentuemer={subAbrechnungEigentuemer} objektId={objektId} loading={loading} />
      )}
      sidebarMenuList={(subAbrechnungEigentuemer) => menuListSubAbrechnungEigentuemer(objektId, subAbrechnungId, subAbrechnungEigentuemer)}
    />
  );
};

export default SubAbrechnungEigentuemerDetailsPage;
