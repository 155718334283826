import React from 'react';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { generatePathToAufteilunsschluesselPage } from '../../../../Aufteilungsschluessel/aufteilungsschluesselUriPaths';
import { Verwaltungsart, VorsteuerkuerzungKontenZuweisung } from '../../../../../types';
import { vstKuerzungKontenZuweisungFormValuesDescriptions } from '../../Version/Form/vstKuerzungKontenZuweisungVersionFormMapper';
import VstKuerzungKonto from './VstKuerzungKonto';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';

// TODO: generatePathToKontoDetails - change Kontoplan Table to highlighted Table

const vstKuerzungKontenZuweisungWeTableColumns = (
  isInCardContent: boolean,
  verwaltungsart: Verwaltungsart
): ColumnProps<VorsteuerkuerzungKontenZuweisung>[] => [
  {
    title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).aufwandsKontoIdList,
    width: isInCardContent ? 200 : undefined,
    render: (text, record) => (
      <Space direction="vertical" size={2}>
        {record.aufwandsKontoList.map((konto) => (
          <VstKuerzungKonto isInCardContent={isInCardContent} konto={konto} />
        ))}
      </Space>
    ),
  },
  {
    title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).aufteilungsschluesselId,
    width: isInCardContent ? 180 : undefined,
    render: (text, record) => (
      <Link to={generatePathToAufteilunsschluesselPage(record.aufteilungsschluessel.aufteilungsschluesselId, record.aufteilungsschluessel.status)}>
        {record.aufteilungsschluessel.bezeichnung}
      </Link>
    ),
  },
  {
    title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).vorsteuerkuerzungKontoId,
    width: isInCardContent ? 180 : undefined,
    render: (text, record) => <VstKuerzungKonto isInCardContent={isInCardContent} konto={record.vorsteuerkuerzungKonto} />,
  },
  {
    title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).vorsteuerkuerzungWeMieterKontoId,
    width: isInCardContent ? 180 : undefined,
    render: (text, record) =>
      record.vorsteuerkuerzungWeMieterKonto ? (
        <VstKuerzungKonto isInCardContent={isInCardContent} konto={record.vorsteuerkuerzungWeMieterKonto} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).vorsteuerkuerzungPauschalmieterKontoId,
    width: isInCardContent ? 180 : undefined,
    render: (text, record) =>
      record.vorsteuerkuerzungPauschalmieterKonto ? (
        <VstKuerzungKonto isInCardContent={isInCardContent} konto={record.vorsteuerkuerzungPauschalmieterKonto} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
];

export default vstKuerzungKontenZuweisungWeTableColumns;
