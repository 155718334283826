import * as Yup from 'yup';
import { entityIsRequired, entityMustBeSelected } from '../../../../../components/message/validationMsg';
import { vstKuerzungKontenZuordnungVersionFormFields, vstKuerzungKontenZuweisungFormFields } from './vstKuerzungKontenZuweisungVersionFormMapper';

/* eslint-disable no-template-curly-in-string */
export const vstKuerzungKontenZuweisungVersionWeObjektFormValidationSchema = Yup.object().shape({
  [vstKuerzungKontenZuordnungVersionFormFields.validFrom]: Yup.string().nullable().required(entityIsRequired('Gültig ab')),
  [vstKuerzungKontenZuordnungVersionFormFields.kontenZuweisungList]: Yup.array()
    .of(
      Yup.object().shape({
        [vstKuerzungKontenZuweisungFormFields.aufwandsKontoIdList]: Yup.array().min(1, 'Mindestens ein Aufwandskonto muss hinzugefügt werden'),
        [vstKuerzungKontenZuweisungFormFields.aufteilungsschluesselId]: Yup.string().required(entityMustBeSelected('Aufteilungsschlüssel')),
        [vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungKontoId]: Yup.string().required(entityMustBeSelected('Vorsteuerkuerzung-Konto')),
        [vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungPauschalmieterKontoId]: Yup.string().required(
          entityMustBeSelected('Vorsteuerkuerzung-Pauschalmieter')
        ),
        [vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungWeMieterKontoId]: Yup.string().required(
          entityMustBeSelected('Vorsteuerkuerzung-Mieter')
        ),
      })
    )
    .min(1, 'Mindestens eine Zuweisung muss hinzugefügt werden'),
});

export const vstKuerzungKontenZuweisungVersionMietObjektFormValidationSchema = Yup.object().shape({
  [vstKuerzungKontenZuordnungVersionFormFields.validFrom]: Yup.string().nullable().required(entityIsRequired('Gültig ab')),
  [vstKuerzungKontenZuordnungVersionFormFields.kontenZuweisungList]: Yup.array()
    .of(
      Yup.object().shape({
        [vstKuerzungKontenZuweisungFormFields.aufwandsKontoIdList]: Yup.array().min(1, 'Mindestens ein Aufwandskonto muss hinzugefügt werden'),
        [vstKuerzungKontenZuweisungFormFields.aufteilungsschluesselId]: Yup.string().required(entityMustBeSelected('Aufteilungsschlüssel')),
        [vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungKontoId]: Yup.string().required(entityMustBeSelected('Vorsteuerkuerzung-Konto')),
        [vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungEigennutzungKontoId]: Yup.string().required(
          entityMustBeSelected('Vorsteuerkuerzung-Eigennutzung')
        ),
        [vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungPauschalmieterKontoId]: Yup.string().required(
          entityMustBeSelected('Vorsteuerkuerzung-Pauschalmieter')
        ),
      })
    )
    .min(1, 'Mindestens eine Zuweisung muss hinzugefügt werden'),
});
/* eslint-enable no-template-curly-in-string */
