import { FC } from 'react';

import PersonenbezugListItem from './ListItem/PersonenbezugListItem';
import { StyledPersonenbezugList } from './ListItem/styled/PersonenbezugStyledElements.style';
import { Personenbezug } from '../../../../types';

type Props = {
  rechtstraegerId: string;
  personenbezugList: Personenbezug[];
  onSuccess: () => void;
};

const PersonenbezugList: FC<Props> = ({ rechtstraegerId, personenbezugList, onSuccess }) => {
  return (
    <StyledPersonenbezugList
      dataSource={personenbezugList}
      renderItem={(item) => <PersonenbezugListItem item={item} rechtstraegerId={rechtstraegerId} onSuccess={onSuccess} />}
    />
  );
};

export default PersonenbezugList;
