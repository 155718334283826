import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { BeVertragUpdateInput, Vertragsart } from '../../../../types';
import { BeVertragFragment } from '../gql/BeVertragFragments.types';

export type VertragNutzungDetailsFormValues = {
  vertragsart: Vertragsart;
  vertragsBeginn: string;
  vertragsEnde?: string | null;
  vertragspartnerId: string;
  applyIndexedValues: boolean;
  supportsExplicitVertragsEnde: boolean | null;
  tagList?: string[] | null;
};

export const vertragNutzungDetailsFormFields: FormFields<VertragNutzungDetailsFormValues> = {
  vertragsart: 'vertragsart',
  vertragsBeginn: 'vertragsBeginn',
  vertragsEnde: 'vertragsEnde',
  vertragspartnerId: 'vertragspartnerId',
  applyIndexedValues: 'applyIndexedValues',
  supportsExplicitVertragsEnde: 'supportsExplicitVertragsEnde',
  tagList: 'tagList',
};

export const mapVertragToVertragNutzungDetailsFormValues = (vertrag: BeVertragFragment): VertragNutzungDetailsFormValues => ({
  vertragsart: vertrag.vertragsart.value,
  vertragsBeginn: vertrag.vertragsBeginn,
  vertragsEnde: vertrag.vertragsEnde,
  vertragspartnerId: vertrag.vertragspartner.rechtstraegerId,
  applyIndexedValues: vertrag.applyIndexedValues,
  supportsExplicitVertragsEnde: vertrag.vertragsart.supportsExplicitVertragsEnde ?? false,
  tagList: vertrag.tagList,
});

export const mapVertragNutzungDetailsFormValuesToVertragUpdate = (
  formValues: VertragNutzungDetailsFormValues,
  originalVertrag: BeVertragFragment
): BeVertragUpdateInput => ({
  bankDetailsId:
    originalVertrag.vertragspartner.rechtstraegerId === formValues.vertragspartnerId ? originalVertrag.bankDetails?.bankDetailsId : undefined,
  vertragsBeginn: mapFormDateValueToDateString(formValues.vertragsBeginn),
  vertragsEnde: formValues.vertragsEnde ? mapFormDateValueToDateString(formValues.vertragsEnde) : null,
  vertragspartnerId: formValues.vertragspartnerId,
  vertragsart: formValues.vertragsart,
  applyIndexedValues: formValues.applyIndexedValues,
  tagList: formValues.tagList,
});
