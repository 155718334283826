import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateFirmendatenBankDetailsMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  input: Types.FirmendatenBankDetailsCreateInput;
}>;

export type CreateFirmendatenBankDetailsMutation = {
  createFirmendatenBankDetails: {
    data: { bankDetailsId: string; allowObjektZuweisung?: boolean | null };
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UpdateFirmendatenBankDetailsMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  bankDetailsId: Types.Scalars['ID']['input'];
  input: Types.FirmendatenBankDetailsUpdateInput;
}>;

export type UpdateFirmendatenBankDetailsMutation = {
  updateFirmendatenBankDetails: { data: { bankDetailsId: string }; warningList: Array<{ message: string; type: string }> };
};

export type DeleteFirmendatenBankDetailsMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  bankDetailsId: Types.Scalars['ID']['input'];
}>;

export type DeleteFirmendatenBankDetailsMutation = {
  deleteFirmendatenBankDetails: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type ArchiveFirmendatenBankDetailsMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  bankDetailsId: Types.Scalars['ID']['input'];
}>;

export type ArchiveFirmendatenBankDetailsMutation = {
  actionArchiveFirmendatenBankDetails: {
    data: { bankDetailsId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveFirmendatenBankDetailsMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  bankDetailsId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveFirmendatenBankDetailsMutation = {
  actionUnarchiveFirmendatenBankDetails: {
    data: { bankDetailsId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateRechtstraegerBankDetailsMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  input: Types.RechtstraegerBankDetailsCreateInput;
}>;

export type CreateRechtstraegerBankDetailsMutation = {
  createRechtstraegerBankDetails: {
    data: { bankDetailsId: string; iban: string; bic: string; accountHolder: string; bankname: string };
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UpdateRechtstraegerBankDetailsMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['String']['input'];
  bankDetailsId: Types.Scalars['ID']['input'];
  input: Types.RechtstraegerBankDetailsUpdateInput;
}>;

export type UpdateRechtstraegerBankDetailsMutation = {
  updateRechtstraegerBankDetails: {
    data: { bankDetailsId: string; iban: string; bic: string; accountHolder: string; bankname: string };
    warningList: Array<{ message: string; type: string }>;
  };
};

export type DeleteRechtstraegerBankDetailsMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['String']['input'];
  bankDetailsId: Types.Scalars['ID']['input'];
}>;

export type DeleteRechtstraegerBankDetailsMutation = {
  deleteRechtstraegerBankDetails: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type ArchiveRechtstraegerBankDetailsMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['String']['input'];
  bankDetailsId: Types.Scalars['ID']['input'];
}>;

export type ArchiveRechtstraegerBankDetailsMutation = {
  actionArchiveRechtstraegerBankDetails: {
    data: { bankDetailsId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveRechtstraegerBankDetailsMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['String']['input'];
  bankDetailsId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveRechtstraegerBankDetailsMutation = {
  actionUnarchiveRechtstraegerBankDetails: {
    data: { bankDetailsId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AddKontoForFirmendatenBankDetailsMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  bankDetailsId: Types.Scalars['ID']['input'];
}>;

export type AddKontoForFirmendatenBankDetailsMutation = {
  actionAddKontoForFirmendatenBankDetails: {
    data: {
      bankname: string;
      bankDetailsId: string;
      bic: string;
      fibuKontoId?: string | null;
      fibuKontonummer?: string | null;
      iban: string;
      accountHolder: string;
      fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type ChangeAccountLimitForFirmendatenBankDetailsMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  bankDetailsId: Types.Scalars['ID']['input'];
  input: Types.BankDetailsChangeAccountLimitInput;
}>;

export type ChangeAccountLimitForFirmendatenBankDetailsMutation = {
  actionChangeAccountLimitForFirmendatenBankDetails: {
    data: { bankDetailsId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type ChangeAccountLimitForRechtstraegerBankDetailsMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['String']['input'];
  bankDetailsId: Types.Scalars['ID']['input'];
  input: Types.BankDetailsChangeAccountLimitInput;
}>;

export type ChangeAccountLimitForRechtstraegerBankDetailsMutation = {
  actionChangeAccountLimitForRechtstraegerBankDetails: {
    data: { bankDetailsId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type ValidateIbanMutationVariables = Types.Exact<{
  iban: Types.Scalars['String']['input'];
}>;

export type ValidateIbanMutation = {
  validateIban: {
    data?: { valid: boolean; bic?: string | null; bankname?: string | null } | null;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const CreateFirmendatenBankDetailsDocument = gql`
  mutation CreateFirmendatenBankDetails($firmendatenId: ID!, $input: FirmendatenBankDetailsCreateInput!) {
    createFirmendatenBankDetails(firmendatenId: $firmendatenId, input: $input) {
      data {
        bankDetailsId
        allowObjektZuweisung
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreateFirmendatenBankDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFirmendatenBankDetailsMutation, CreateFirmendatenBankDetailsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFirmendatenBankDetailsMutation, CreateFirmendatenBankDetailsMutationVariables>(
    CreateFirmendatenBankDetailsDocument,
    options
  );
}
export type CreateFirmendatenBankDetailsMutationHookResult = ReturnType<typeof useCreateFirmendatenBankDetailsMutation>;
export type CreateFirmendatenBankDetailsMutationResult = Apollo.MutationResult<CreateFirmendatenBankDetailsMutation>;
export type CreateFirmendatenBankDetailsMutationOptions = Apollo.BaseMutationOptions<
  CreateFirmendatenBankDetailsMutation,
  CreateFirmendatenBankDetailsMutationVariables
>;
export const UpdateFirmendatenBankDetailsDocument = gql`
  mutation UpdateFirmendatenBankDetails($firmendatenId: String!, $bankDetailsId: ID!, $input: FirmendatenBankDetailsUpdateInput!) {
    updateFirmendatenBankDetails(firmendatenId: $firmendatenId, bankDetailsId: $bankDetailsId, input: $input) {
      data {
        bankDetailsId
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUpdateFirmendatenBankDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFirmendatenBankDetailsMutation, UpdateFirmendatenBankDetailsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFirmendatenBankDetailsMutation, UpdateFirmendatenBankDetailsMutationVariables>(
    UpdateFirmendatenBankDetailsDocument,
    options
  );
}
export type UpdateFirmendatenBankDetailsMutationHookResult = ReturnType<typeof useUpdateFirmendatenBankDetailsMutation>;
export type UpdateFirmendatenBankDetailsMutationResult = Apollo.MutationResult<UpdateFirmendatenBankDetailsMutation>;
export type UpdateFirmendatenBankDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateFirmendatenBankDetailsMutation,
  UpdateFirmendatenBankDetailsMutationVariables
>;
export const DeleteFirmendatenBankDetailsDocument = gql`
  mutation DeleteFirmendatenBankDetails($firmendatenId: String!, $bankDetailsId: ID!) {
    deleteFirmendatenBankDetails(firmendatenId: $firmendatenId, bankDetailsId: $bankDetailsId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteFirmendatenBankDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFirmendatenBankDetailsMutation, DeleteFirmendatenBankDetailsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFirmendatenBankDetailsMutation, DeleteFirmendatenBankDetailsMutationVariables>(
    DeleteFirmendatenBankDetailsDocument,
    options
  );
}
export type DeleteFirmendatenBankDetailsMutationHookResult = ReturnType<typeof useDeleteFirmendatenBankDetailsMutation>;
export type DeleteFirmendatenBankDetailsMutationResult = Apollo.MutationResult<DeleteFirmendatenBankDetailsMutation>;
export type DeleteFirmendatenBankDetailsMutationOptions = Apollo.BaseMutationOptions<
  DeleteFirmendatenBankDetailsMutation,
  DeleteFirmendatenBankDetailsMutationVariables
>;
export const ArchiveFirmendatenBankDetailsDocument = gql`
  mutation ArchiveFirmendatenBankDetails($firmendatenId: String!, $bankDetailsId: ID!) {
    actionArchiveFirmendatenBankDetails(firmendatenId: $firmendatenId, bankDetailsId: $bankDetailsId) {
      data {
        bankDetailsId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveFirmendatenBankDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveFirmendatenBankDetailsMutation, ArchiveFirmendatenBankDetailsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveFirmendatenBankDetailsMutation, ArchiveFirmendatenBankDetailsMutationVariables>(
    ArchiveFirmendatenBankDetailsDocument,
    options
  );
}
export type ArchiveFirmendatenBankDetailsMutationHookResult = ReturnType<typeof useArchiveFirmendatenBankDetailsMutation>;
export type ArchiveFirmendatenBankDetailsMutationResult = Apollo.MutationResult<ArchiveFirmendatenBankDetailsMutation>;
export type ArchiveFirmendatenBankDetailsMutationOptions = Apollo.BaseMutationOptions<
  ArchiveFirmendatenBankDetailsMutation,
  ArchiveFirmendatenBankDetailsMutationVariables
>;
export const UnarchiveFirmendatenBankDetailsDocument = gql`
  mutation UnarchiveFirmendatenBankDetails($firmendatenId: String!, $bankDetailsId: ID!) {
    actionUnarchiveFirmendatenBankDetails(firmendatenId: $firmendatenId, bankDetailsId: $bankDetailsId) {
      data {
        bankDetailsId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveFirmendatenBankDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveFirmendatenBankDetailsMutation, UnarchiveFirmendatenBankDetailsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveFirmendatenBankDetailsMutation, UnarchiveFirmendatenBankDetailsMutationVariables>(
    UnarchiveFirmendatenBankDetailsDocument,
    options
  );
}
export type UnarchiveFirmendatenBankDetailsMutationHookResult = ReturnType<typeof useUnarchiveFirmendatenBankDetailsMutation>;
export type UnarchiveFirmendatenBankDetailsMutationResult = Apollo.MutationResult<UnarchiveFirmendatenBankDetailsMutation>;
export type UnarchiveFirmendatenBankDetailsMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveFirmendatenBankDetailsMutation,
  UnarchiveFirmendatenBankDetailsMutationVariables
>;
export const CreateRechtstraegerBankDetailsDocument = gql`
  mutation CreateRechtstraegerBankDetails($rechtstraegerId: ID!, $input: RechtstraegerBankDetailsCreateInput!) {
    createRechtstraegerBankDetails(rechtstraegerId: $rechtstraegerId, input: $input) {
      data {
        bankDetailsId
        iban
        bic
        accountHolder
        bankname
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreateRechtstraegerBankDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRechtstraegerBankDetailsMutation, CreateRechtstraegerBankDetailsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRechtstraegerBankDetailsMutation, CreateRechtstraegerBankDetailsMutationVariables>(
    CreateRechtstraegerBankDetailsDocument,
    options
  );
}
export type CreateRechtstraegerBankDetailsMutationHookResult = ReturnType<typeof useCreateRechtstraegerBankDetailsMutation>;
export type CreateRechtstraegerBankDetailsMutationResult = Apollo.MutationResult<CreateRechtstraegerBankDetailsMutation>;
export type CreateRechtstraegerBankDetailsMutationOptions = Apollo.BaseMutationOptions<
  CreateRechtstraegerBankDetailsMutation,
  CreateRechtstraegerBankDetailsMutationVariables
>;
export const UpdateRechtstraegerBankDetailsDocument = gql`
  mutation updateRechtstraegerBankDetails($rechtstraegerId: String!, $bankDetailsId: ID!, $input: RechtstraegerBankDetailsUpdateInput!) {
    updateRechtstraegerBankDetails(rechtstraegerId: $rechtstraegerId, bankDetailsId: $bankDetailsId, input: $input) {
      data {
        bankDetailsId
        iban
        bic
        accountHolder
        bankname
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUpdateRechtstraegerBankDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRechtstraegerBankDetailsMutation, UpdateRechtstraegerBankDetailsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRechtstraegerBankDetailsMutation, UpdateRechtstraegerBankDetailsMutationVariables>(
    UpdateRechtstraegerBankDetailsDocument,
    options
  );
}
export type UpdateRechtstraegerBankDetailsMutationHookResult = ReturnType<typeof useUpdateRechtstraegerBankDetailsMutation>;
export type UpdateRechtstraegerBankDetailsMutationResult = Apollo.MutationResult<UpdateRechtstraegerBankDetailsMutation>;
export type UpdateRechtstraegerBankDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateRechtstraegerBankDetailsMutation,
  UpdateRechtstraegerBankDetailsMutationVariables
>;
export const DeleteRechtstraegerBankDetailsDocument = gql`
  mutation DeleteRechtstraegerBankDetails($rechtstraegerId: String!, $bankDetailsId: ID!) {
    deleteRechtstraegerBankDetails(rechtstraegerId: $rechtstraegerId, bankDetailsId: $bankDetailsId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteRechtstraegerBankDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRechtstraegerBankDetailsMutation, DeleteRechtstraegerBankDetailsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRechtstraegerBankDetailsMutation, DeleteRechtstraegerBankDetailsMutationVariables>(
    DeleteRechtstraegerBankDetailsDocument,
    options
  );
}
export type DeleteRechtstraegerBankDetailsMutationHookResult = ReturnType<typeof useDeleteRechtstraegerBankDetailsMutation>;
export type DeleteRechtstraegerBankDetailsMutationResult = Apollo.MutationResult<DeleteRechtstraegerBankDetailsMutation>;
export type DeleteRechtstraegerBankDetailsMutationOptions = Apollo.BaseMutationOptions<
  DeleteRechtstraegerBankDetailsMutation,
  DeleteRechtstraegerBankDetailsMutationVariables
>;
export const ArchiveRechtstraegerBankDetailsDocument = gql`
  mutation ArchiveRechtstraegerBankDetails($rechtstraegerId: String!, $bankDetailsId: ID!) {
    actionArchiveRechtstraegerBankDetails(rechtstraegerId: $rechtstraegerId, bankDetailsId: $bankDetailsId) {
      data {
        bankDetailsId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveRechtstraegerBankDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveRechtstraegerBankDetailsMutation, ArchiveRechtstraegerBankDetailsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveRechtstraegerBankDetailsMutation, ArchiveRechtstraegerBankDetailsMutationVariables>(
    ArchiveRechtstraegerBankDetailsDocument,
    options
  );
}
export type ArchiveRechtstraegerBankDetailsMutationHookResult = ReturnType<typeof useArchiveRechtstraegerBankDetailsMutation>;
export type ArchiveRechtstraegerBankDetailsMutationResult = Apollo.MutationResult<ArchiveRechtstraegerBankDetailsMutation>;
export type ArchiveRechtstraegerBankDetailsMutationOptions = Apollo.BaseMutationOptions<
  ArchiveRechtstraegerBankDetailsMutation,
  ArchiveRechtstraegerBankDetailsMutationVariables
>;
export const UnarchiveRechtstraegerBankDetailsDocument = gql`
  mutation UnarchiveRechtstraegerBankDetails($rechtstraegerId: String!, $bankDetailsId: ID!) {
    actionUnarchiveRechtstraegerBankDetails(rechtstraegerId: $rechtstraegerId, bankDetailsId: $bankDetailsId) {
      data {
        bankDetailsId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveRechtstraegerBankDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveRechtstraegerBankDetailsMutation, UnarchiveRechtstraegerBankDetailsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveRechtstraegerBankDetailsMutation, UnarchiveRechtstraegerBankDetailsMutationVariables>(
    UnarchiveRechtstraegerBankDetailsDocument,
    options
  );
}
export type UnarchiveRechtstraegerBankDetailsMutationHookResult = ReturnType<typeof useUnarchiveRechtstraegerBankDetailsMutation>;
export type UnarchiveRechtstraegerBankDetailsMutationResult = Apollo.MutationResult<UnarchiveRechtstraegerBankDetailsMutation>;
export type UnarchiveRechtstraegerBankDetailsMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveRechtstraegerBankDetailsMutation,
  UnarchiveRechtstraegerBankDetailsMutationVariables
>;
export const AddKontoForFirmendatenBankDetailsDocument = gql`
  mutation AddKontoForFirmendatenBankDetails($firmendatenId: String!, $bankDetailsId: ID!) {
    actionAddKontoForFirmendatenBankDetails(firmendatenId: $firmendatenId, bankDetailsId: $bankDetailsId) {
      data {
        bankname
        bankDetailsId
        bic
        fibuKontoId
        fibuKontonummer
        fibuStatus {
          text
          value
        }
        iban
        accountHolder
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useAddKontoForFirmendatenBankDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<AddKontoForFirmendatenBankDetailsMutation, AddKontoForFirmendatenBankDetailsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddKontoForFirmendatenBankDetailsMutation, AddKontoForFirmendatenBankDetailsMutationVariables>(
    AddKontoForFirmendatenBankDetailsDocument,
    options
  );
}
export type AddKontoForFirmendatenBankDetailsMutationHookResult = ReturnType<typeof useAddKontoForFirmendatenBankDetailsMutation>;
export type AddKontoForFirmendatenBankDetailsMutationResult = Apollo.MutationResult<AddKontoForFirmendatenBankDetailsMutation>;
export type AddKontoForFirmendatenBankDetailsMutationOptions = Apollo.BaseMutationOptions<
  AddKontoForFirmendatenBankDetailsMutation,
  AddKontoForFirmendatenBankDetailsMutationVariables
>;
export const ChangeAccountLimitForFirmendatenBankDetailsDocument = gql`
  mutation ChangeAccountLimitForFirmendatenBankDetails($firmendatenId: String!, $bankDetailsId: ID!, $input: BankDetailsChangeAccountLimitInput!) {
    actionChangeAccountLimitForFirmendatenBankDetails(firmendatenId: $firmendatenId, bankDetailsId: $bankDetailsId, input: $input) {
      data {
        bankDetailsId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useChangeAccountLimitForFirmendatenBankDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeAccountLimitForFirmendatenBankDetailsMutation,
    ChangeAccountLimitForFirmendatenBankDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeAccountLimitForFirmendatenBankDetailsMutation, ChangeAccountLimitForFirmendatenBankDetailsMutationVariables>(
    ChangeAccountLimitForFirmendatenBankDetailsDocument,
    options
  );
}
export type ChangeAccountLimitForFirmendatenBankDetailsMutationHookResult = ReturnType<typeof useChangeAccountLimitForFirmendatenBankDetailsMutation>;
export type ChangeAccountLimitForFirmendatenBankDetailsMutationResult = Apollo.MutationResult<ChangeAccountLimitForFirmendatenBankDetailsMutation>;
export type ChangeAccountLimitForFirmendatenBankDetailsMutationOptions = Apollo.BaseMutationOptions<
  ChangeAccountLimitForFirmendatenBankDetailsMutation,
  ChangeAccountLimitForFirmendatenBankDetailsMutationVariables
>;
export const ChangeAccountLimitForRechtstraegerBankDetailsDocument = gql`
  mutation ChangeAccountLimitForRechtstraegerBankDetails(
    $rechtstraegerId: String!
    $bankDetailsId: ID!
    $input: BankDetailsChangeAccountLimitInput!
  ) {
    actionChangeAccountLimitForRechtstraegerBankDetails(rechtstraegerId: $rechtstraegerId, bankDetailsId: $bankDetailsId, input: $input) {
      data {
        bankDetailsId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useChangeAccountLimitForRechtstraegerBankDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeAccountLimitForRechtstraegerBankDetailsMutation,
    ChangeAccountLimitForRechtstraegerBankDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeAccountLimitForRechtstraegerBankDetailsMutation, ChangeAccountLimitForRechtstraegerBankDetailsMutationVariables>(
    ChangeAccountLimitForRechtstraegerBankDetailsDocument,
    options
  );
}
export type ChangeAccountLimitForRechtstraegerBankDetailsMutationHookResult = ReturnType<
  typeof useChangeAccountLimitForRechtstraegerBankDetailsMutation
>;
export type ChangeAccountLimitForRechtstraegerBankDetailsMutationResult =
  Apollo.MutationResult<ChangeAccountLimitForRechtstraegerBankDetailsMutation>;
export type ChangeAccountLimitForRechtstraegerBankDetailsMutationOptions = Apollo.BaseMutationOptions<
  ChangeAccountLimitForRechtstraegerBankDetailsMutation,
  ChangeAccountLimitForRechtstraegerBankDetailsMutationVariables
>;
export const ValidateIbanDocument = gql`
  mutation ValidateIban($iban: String!) {
    validateIban(iban: $iban) {
      data {
        valid
        bic
        bankname
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useValidateIbanMutation(baseOptions?: Apollo.MutationHookOptions<ValidateIbanMutation, ValidateIbanMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ValidateIbanMutation, ValidateIbanMutationVariables>(ValidateIbanDocument, options);
}
export type ValidateIbanMutationHookResult = ReturnType<typeof useValidateIbanMutation>;
export type ValidateIbanMutationResult = Apollo.MutationResult<ValidateIbanMutation>;
export type ValidateIbanMutationOptions = Apollo.BaseMutationOptions<ValidateIbanMutation, ValidateIbanMutationVariables>;
