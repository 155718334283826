import React, { FC } from 'react';
import { Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../hooks/useToggle';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../components/Button/ButtonsAligned';
import AbrechnungskreisTemplateForm from './Form/AbrechnungskreisTemplateForm';

type Props = {
  refetch: () => void;
  showArchived: boolean;
  toggleShowArchived: () => void;
};

const AbrechnungskreisButtonsAndDrawer: FC<Props> = ({ refetch, showArchived, toggleShowArchived }) => {
  const [isCollapsed, onCollapse] = useToggle();

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <Button onClick={toggleShowArchived}>Archivierte Einträge {showArchived ? 'ausblenden' : 'anzeigen'}</Button>
        <Button icon={<PlusOutlined />} type="primary" onClick={onCollapse}>
          Abrechnungskreis anlegen
        </Button>
      </ButtonsAligned>
      <Drawer title="Abrechnungskreis anlegen" width={400} onClose={onCollapse} open={isCollapsed} destroyOnClose>
        <AbrechnungskreisTemplateForm
          onSuccess={() => {
            refetch();
            onCollapse();
          }}
          onCancel={onCollapse}
        />
      </Drawer>
    </>
  );
};

export default AbrechnungskreisButtonsAndDrawer;
