import React, { FC, useEffect } from 'react';
import { Space, Spin, Typography } from 'antd';
import { useSingleInvoiceBuchungPreviewMutation } from '../../../gql/SingleInvoiceMutations.types';
import { SingleInvoiceCreateInput } from '../../../../../types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import BookingPreviewTable from '../shared/SingleInvoice/BookingPreviewTable';

type Props = {
  input: SingleInvoiceCreateInput;
};

const SingleInvoiceDirectBookingPreviewDetails: FC<Props> = ({ input }) => {
  const [runGetBookingPreview, { loading, data }] = useSingleInvoiceBuchungPreviewMutation({
    variables: { input },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const buchungPreview = data?.singleInvoiceBuchungPreview.data;

  useEffect(() => {
    runGetBookingPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  return !buchungPreview ? (
    <Spin />
  ) : (
    <Space direction="vertical" size={30} style={{ width: '100%' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Typography.Title level={5}>Buchung</Typography.Title>
        <BookingPreviewTable key={JSON.stringify(buchungPreview.buchung)} data={buchungPreview.buchung} loading={loading} />
      </Space>
    </Space>
  );
};

export default SingleInvoiceDirectBookingPreviewDetails;
