import React, { FC } from 'react';
import { Tooltip } from 'antd';
import { ObjektBestandseinheitId } from '../../../types';
import ObjektTopBezeichnung from './ObjektTopBezeichnung';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';

type ObjektTopBezeichnungListingProps = {
  objektTopIdList: ObjektBestandseinheitId[];
  maxTextLengthForSingleObjektTop?: number;
};

const ObjektTopBezeichnungListing: FC<ObjektTopBezeichnungListingProps> = ({ objektTopIdList, maxTextLengthForSingleObjektTop }) => {
  if (objektTopIdList.length === 0) {
    return <TextForEmptyValue textToShow="NV" />;
  } else if (objektTopIdList.length === 1) {
    return (
      <ObjektTopBezeichnung objektBestandseinheit={objektTopIdList[0]} maxTextLength={maxTextLengthForSingleObjektTop} useInTooltipTitle={false} />
    );
  } else {
    const objektTopList = (
      <>
        {objektTopIdList.map((objektTopId, index) => (
          <ObjektTopBezeichnung key={index} objektBestandseinheit={objektTopId} showCompleteText />
        ))}
      </>
    );

    return (
      <Tooltip title={objektTopList}>
        <>
          <ObjektTopBezeichnung objektBestandseinheit={objektTopIdList[0]} maxTextLength={maxTextLengthForSingleObjektTop} useInTooltipTitle />
        </>
      </Tooltip>
    );
  }
};

export default ObjektTopBezeichnungListing;
