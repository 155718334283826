import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { FiltersFormValues, listingFiltersFormFields, listingLabelList } from './listingFiltersFormMapper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { AppGenerierlaufType } from '../../../../../types';
import FiltersWithMax3Fields from '../../../../../components/Filters/FiltersWithMax3Fields';
import GenerierlaufObjektFormSelect from '../../../shared/Filters/App/GenerierlaufObjektFormSelect';
import GenerierlaufEntryExitCodeListFormSelect from '../../../shared/Filters/App/GenerierlaufEntryExitCodeListFormSelect';
import GenerierlaufEigentuemerFormSelect from '../../../shared/Filters/App/GenerierlaufEigentuemerFormSelect';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
  generierlaufId: string;
};

const ListingFilters: FC<Props> = ({ formikProps, generierlaufId }) => {
  return (
    <FiltersWithMax3Fields
      filters={
        <>
          <FormItemWithoutColon name={listingFiltersFormFields.objektId} label={listingLabelList.objektId}>
            <GenerierlaufObjektFormSelect
              name={listingFiltersFormFields.objektId}
              generierlaufId={generierlaufId}
              generierlaufType={AppGenerierlaufType.SubAbrechnungErstellung}
              onChange={formikProps.submitForm}
              style={{ minWidth: '200px' }}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={listingFiltersFormFields.eigentuemerId} label={listingLabelList.eigentuemerId}>
            <GenerierlaufEigentuemerFormSelect
              name={listingFiltersFormFields.eigentuemerId}
              generierlaufId={generierlaufId}
              generierlaufType={AppGenerierlaufType.SubAbrechnungErstellung}
              onChange={formikProps.submitForm}
              style={{ minWidth: '200px' }}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={listingFiltersFormFields.exitCodeList} label={listingLabelList.exitCodeList}>
            <GenerierlaufEntryExitCodeListFormSelect
              name={listingFiltersFormFields.exitCodeList}
              generierlaufId={generierlaufId}
              generierlaufType={AppGenerierlaufType.SubAbrechnungErstellung}
              onChange={formikProps.submitForm}
              style={{ minWidth: '200px' }}
            />
          </FormItemWithoutColon>
        </>
      }
      hideTitle
    />
  );
};

export default ListingFilters;
