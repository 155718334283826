import { FormEvent, useRef } from 'react';
import useEffectAfterMount from './useEffectAfterMount';

/*
 * useUpdateFocus is a custom React hook that manages the focus state of inputs after they are enabled or disabled.
 * This hook addresses an issue with Ant Design, where the focus outline persists on an input after it is
 * disabled and then re-enabled, making it appear as if it still has focus.
 *
 * The hook also can ensure that (when keepFocus parameter is true) if no other specific element gains focus
 * while the input is disabled, the input regains focus upon re-enabling otherwise removes the incorrect focus-like style.
 *
 * Note: In the absence of a ref, the onBefore function is used to access the HTML element.
 *
 * Params:
 *    - disabled: A flag that indicates whether the input is currently disabled.
 *    - keepFocus: A flag to determine whether the input should regain focus after being re-enabled.
 */
function useUpdateFocus(disabled?: boolean, keepFocus?: boolean) {
  const element = useRef<HTMLElement | null>(null);

  const onBeforeInput = (event: FormEvent) => {
    if (element.current) return;
    element.current = event.target as HTMLElement;
  };

  const onBlur = () => {
    element.current = null;
  };

  useEffectAfterMount(() => {
    if (disabled) return;
    const current = document.activeElement as HTMLElement;
    if (current?.tagName.toLowerCase() !== 'body') {
      element.current?.focus();
      element.current?.blur();
      current?.focus();
    } else {
      element.current?.focus();
      if (!keepFocus) {
        element.current?.blur();
      }
    }
  }, [disabled]);

  return { onBeforeInput, onBlur };
}

export default useUpdateFocus;
