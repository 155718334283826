import React, { FC } from 'react';
import { Input, InputNumber } from 'formik-antd';
import ParamFormItem from '../../../components/Form/ParamFormItem';
import { mahndefinitionParamFormFields } from '../mahndefinitionFormMapper';
import FormattedDecimalFormInput from '../../../components/Input-antd/FormattedDecimalFormInput';

const MahndefinitionParamFormAllgemein: FC = () => (
  <>
    <ParamFormItem label="Bezeichnung" name={mahndefinitionParamFormFields.bezeichnung} marginBottom="16px">
      <Input
        style={{ width: '100%' }}
        size="small"
        placeholder="Bezeichnung"
        name={mahndefinitionParamFormFields.bezeichnung}
        prefix={<span />}
        maxLength={100}
      />
    </ParamFormItem>
    <ParamFormItem name={mahndefinitionParamFormFields.verzugszinsen} label="Verzugszinsen" textAfter="% p.a.">
      <FormattedDecimalFormInput
        name={mahndefinitionParamFormFields.verzugszinsen}
        placeholder="Verzugszinsen"
        size="small"
        style={{ width: '100%' }}
        min={0}
        controls={false}
      />
    </ParamFormItem>
    <ParamFormItem name={mahndefinitionParamFormFields.mahnfreieTage} label="Mahnfreie Tage" textAfter="Tage">
      <InputNumber
        name={mahndefinitionParamFormFields.mahnfreieTage}
        placeholder="Mahnfreie Tage"
        size="small"
        style={{ width: '100%' }}
        precision={0}
        prefix=""
        min={0}
        controls={false}
      />
    </ParamFormItem>
  </>
);

export default MahndefinitionParamFormAllgemein;
