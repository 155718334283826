import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { TextbausteinFieldsFragmentDoc } from '../../../components/Template/PDFTemplates/gql/TemplateFragments.types';
export type AuftragsartFieldsFragment = {
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  auftragsartId: string;
  bezeichnung: string;
  deletable?: boolean | null;
  firmendatenId?: string | null;
  showUstBlock: boolean;
  updatedByMitarbeiterId?: string | null;
  status: { text: string; value: Types.AuftragsartStatus };
  stornoAuftragsart?: {
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    auftragsartId: string;
    bezeichnung: string;
    showUstBlock: boolean;
    status: { text: string; value: Types.AuftragsartStatus };
    textbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      textbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    type: { text: string; value: Types.AuftragsartType };
  } | null;
  textbausteinList: Array<{
    index: number;
    value?: string | null;
    platzhalterList: Array<{ example: string; platzhalter: string }>;
    textbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    type: { text: string; value: Types.TextbausteinType };
  }>;
  type: { text: string; value: Types.AuftragsartType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type AuftragsartBaseFieldsFragment = {
  auftragsartId: string;
  bezeichnung: string;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  firmendatenId?: string | null;
  showUstBlock: boolean;
  updatedByMitarbeiterId?: string | null;
  status: { text: string; value: Types.AuftragsartStatus };
  type: { text: string; value: Types.AuftragsartType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const AuftragsartFieldsFragmentDoc = gql`
  fragment AuftragsartFields on Auftragsart {
    createTs
    createdBy
    createdByMitarbeiterId
    auftragsartId
    bezeichnung
    deletable
    firmendatenId
    showUstBlock
    status {
      text
      value
    }
    stornoAuftragsart {
      createTs
      createdBy
      createdByMitarbeiterId
      auftragsartId
      bezeichnung
      showUstBlock
      status {
        text
        value
      }
      textbausteinList {
        ...TextbausteinFields
      }
      type {
        text
        value
      }
    }
    textbausteinList {
      ...TextbausteinFields
    }
    type {
      text
      value
    }
    updatedByMitarbeiterId
    warningList {
      attribute
      message
      type
    }
  }
  ${TextbausteinFieldsFragmentDoc}
`;
export const AuftragsartBaseFieldsFragmentDoc = gql`
  fragment AuftragsartBaseFields on AuftragsartBase {
    auftragsartId
    bezeichnung
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    firmendatenId
    showUstBlock
    status {
      text
      value
    }
    type {
      text
      value
    }
    updatedByMitarbeiterId
    warningList {
      attribute
      message
      type
    }
  }
`;
