import React from 'react';
import { FormikProps } from 'formik';
import { DeleteOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/es/table';
import FormItemWithTooltip from '../../../../../components/Form/FormItemWithTooltip';
import AufteilungsschluesselSelectColumn from './columns/AufteilungsschluesselSelectColumn';
import AufwandskontenSelect from '../../../../shared/AufwandskontenSelect';
import { KontoKlasse, Verwaltungsart } from '../../../../../types';
import {
  VorsteuerkuerzungKontenZuweisungFormValues,
  VorsteuerkuerzungKontenZuweisungVersionFormValues,
  vstKuerzungKontenZuordnungVersionFormFields,
  vstKuerzungKontenZuweisungFormFields,
  vstKuerzungKontenZuweisungFormValuesDescriptions,
} from '../../Version/Form/vstKuerzungKontenZuweisungVersionFormMapper';
import { isMietObjekt } from '../../../../Objekt/objektHelper';

const vstKuerzungKontenZuweisungTableColumns = (
  formikProps: FormikProps<VorsteuerkuerzungKontenZuweisungVersionFormValues>,
  onDelete: (index: number) => void,
  verwaltungsart: Verwaltungsart
): ColumnProps<VorsteuerkuerzungKontenZuweisungFormValues>[] => {
  const columns: ColumnProps<VorsteuerkuerzungKontenZuweisungFormValues>[] = [
    {
      title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).aufwandsKontoIdList,
      key: vstKuerzungKontenZuweisungFormFields.aufwandsKontoIdList,
      width: 160,
      render: (text, record, index) => {
        const name = `${vstKuerzungKontenZuordnungVersionFormFields.kontenZuweisungList}.${index}.${vstKuerzungKontenZuweisungFormFields.aufwandsKontoIdList}`;

        const aufwandskontenIds = formikProps.values.kontenZuweisungList.map((konten) => konten.aufwandsKontoIdList).flat();
        const excludedAufwandsKontenIds = aufwandskontenIds.filter((kontoId) => !record.aufwandsKontoIdList.includes(kontoId));

        const vorsteuerkuerzungKontoId = formikProps.values.kontenZuweisungList?.map((konto) => konto.vorsteuerkuerzungKontoId).flat();
        const vorsteuerkuerzungEigennutzungKontoId = formikProps.values.kontenZuweisungList
          ?.map((konto) => konto.vorsteuerkuerzungEigennutzungKontoId)
          .flat()
          .filter((konto) => konto) as string[];

        const vorsteuerkuerzungPauschalmieterKontoId = formikProps.values.kontenZuweisungList
          ?.map((konto) => konto.vorsteuerkuerzungPauschalmieterKontoId)
          .flat()
          .filter((konto) => konto) as string[];

        const vorsteuerkuerzungWeMieterKontoId = formikProps.values.kontenZuweisungList
          ?.map((konto) => konto.vorsteuerkuerzungWeMieterKontoId)
          .flat()
          .filter((konto) => konto) as string[];

        const excludedKontenIds = [
          ...excludedAufwandsKontenIds,
          ...vorsteuerkuerzungKontoId,
          ...vorsteuerkuerzungEigennutzungKontoId,
          ...vorsteuerkuerzungPauschalmieterKontoId,
          ...vorsteuerkuerzungWeMieterKontoId,
        ];

        return (
          <FormItemWithTooltip name={name}>
            <AufwandskontenSelect
              key={index}
              name={name}
              mode="multiple"
              excludedKontenIds={excludedKontenIds}
              showKontoNummerAsTag={false}
              allowClear={false}
              klasseList={[KontoKlasse.HandelswarenEinsatz, KontoKlasse.PersonalAufwand, KontoKlasse.BetrieblicherAufwand, KontoKlasse.FinanzAufwand]}
            />
          </FormItemWithTooltip>
        );
      },
    },
    {
      title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).aufteilungsschluesselId,
      width: 160,
      key: vstKuerzungKontenZuweisungFormFields.aufteilungsschluesselId,
      render: (text, record, index) => <AufteilungsschluesselSelectColumn index={index} formikProps={formikProps} />,
    },
    {
      title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).vorsteuerkuerzungKontoId,
      width: 160,
      key: vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungKontoId,
      render: (text, record, index) => {
        const name = `${vstKuerzungKontenZuordnungVersionFormFields.kontenZuweisungList}.${index}.${vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungKontoId}`;

        const current = formikProps.values.kontenZuweisungList[index];
        const excludedKontenIds = [
          ...current.aufwandsKontoIdList,
          current.vorsteuerkuerzungEigennutzungKontoId,
          current.vorsteuerkuerzungPauschalmieterKontoId,
          current.vorsteuerkuerzungWeMieterKontoId,
        ].filter((kontoId) => !!kontoId) as string[];

        return (
          <FormItemWithTooltip name={name}>
            <AufwandskontenSelect
              key={index}
              name={name}
              excludedKontenIds={excludedKontenIds}
              showKontoNummerAsTag={false}
              klasseList={[KontoKlasse.HandelswarenEinsatz, KontoKlasse.PersonalAufwand, KontoKlasse.BetrieblicherAufwand, KontoKlasse.FinanzAufwand]}
              allowClear={false}
            />
          </FormItemWithTooltip>
        );
      },
    },
    {
      title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).vorsteuerkuerzungPauschalmieterKontoId,
      width: 160,
      key: vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungPauschalmieterKontoId,
      render: (text, record, index) => {
        const name = `${vstKuerzungKontenZuordnungVersionFormFields.kontenZuweisungList}.${index}.${vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungPauschalmieterKontoId}`;

        const current = formikProps.values.kontenZuweisungList[index];
        const excludedKontenIds = [
          ...current.aufwandsKontoIdList,
          current.vorsteuerkuerzungEigennutzungKontoId,
          current.vorsteuerkuerzungKontoId,
          current.vorsteuerkuerzungWeMieterKontoId,
        ].filter((kontoId) => !!kontoId) as string[];

        return (
          <FormItemWithTooltip name={name}>
            <AufwandskontenSelect
              key={index}
              name={name}
              excludedKontenIds={excludedKontenIds}
              showKontoNummerAsTag={false}
              klasseList={[KontoKlasse.HandelswarenEinsatz, KontoKlasse.PersonalAufwand, KontoKlasse.BetrieblicherAufwand, KontoKlasse.FinanzAufwand]}
              allowClear={false}
            />
          </FormItemWithTooltip>
        );
      },
    },
    {
      title: 'Aktion',
      width: 70,
      align: 'center',
      render: (text, record, index) => <DeleteOutlined onClick={() => onDelete(index)} />,
    },
  ];

  if (isMietObjekt(verwaltungsart)) {
    columns.splice(INDEX_POSITION_OF_EIGENNUTZUNG, 0, {
      title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).vorsteuerkuerzungEigennutzungKontoId,
      width: 160,
      key: vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungEigennutzungKontoId,
      render: (text, record, index) => {
        const name = `${vstKuerzungKontenZuordnungVersionFormFields.kontenZuweisungList}.${index}.${vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungEigennutzungKontoId}`;

        const current = formikProps.values.kontenZuweisungList[index];
        const excludedKontenIds = [
          ...current.aufwandsKontoIdList,
          current.vorsteuerkuerzungPauschalmieterKontoId,
          current.vorsteuerkuerzungKontoId,
          current.vorsteuerkuerzungWeMieterKontoId,
        ].filter((kontoId) => !!kontoId) as string[];

        return (
          <FormItemWithTooltip name={name}>
            <AufwandskontenSelect
              key={index}
              name={name}
              excludedKontenIds={excludedKontenIds}
              showKontoNummerAsTag={false}
              klasseList={[KontoKlasse.HandelswarenEinsatz, KontoKlasse.PersonalAufwand, KontoKlasse.BetrieblicherAufwand, KontoKlasse.FinanzAufwand]}
              allowClear={false}
            />
          </FormItemWithTooltip>
        );
      },
    });
  } else {
    columns.splice(INDEX_POSITION_OF_WE_MIETER, 0, {
      title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).vorsteuerkuerzungWeMieterKontoId,
      width: 160,
      key: vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungWeMieterKontoId,
      render: (text, record, index) => {
        const name = `${vstKuerzungKontenZuordnungVersionFormFields.kontenZuweisungList}.${index}.${vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungWeMieterKontoId}`;

        const current = formikProps.values.kontenZuweisungList[index];
        const excludedKontenIds = [
          ...current.aufwandsKontoIdList,
          current.vorsteuerkuerzungPauschalmieterKontoId,
          current.vorsteuerkuerzungKontoId,
          current.vorsteuerkuerzungEigennutzungKontoId,
        ].filter((kontoId) => !!kontoId) as string[];

        return (
          <FormItemWithTooltip name={name}>
            <AufwandskontenSelect
              key={index}
              name={name}
              excludedKontenIds={excludedKontenIds}
              showKontoNummerAsTag={false}
              klasseList={[KontoKlasse.HandelswarenEinsatz, KontoKlasse.PersonalAufwand, KontoKlasse.BetrieblicherAufwand, KontoKlasse.FinanzAufwand]}
              allowClear={false}
            />
          </FormItemWithTooltip>
        );
      },
    });
  }

  return columns;
};

const INDEX_POSITION_OF_EIGENNUTZUNG = 3;
const INDEX_POSITION_OF_WE_MIETER = 3;

export default vstKuerzungKontenZuweisungTableColumns;
