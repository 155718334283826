import { TDeviation, TDeviationCell, TDeviationColumn } from './deviation.model';
import { DEVIATION_CELL_EMPTY_VALUE } from './deviationHelpers';
import { DeviationType } from '../../types';

type Props = {
  DeviationType: DeviationType;
  columns: TDeviationColumn[];
};

const allColumnsForDeviationType = (deviationList: TDeviation[]) =>
  deviationList.reduce((acc, currentDeviation) => {
    if (acc.length === 0) {
      return [createDeviationWithColumnsByDeviationType(currentDeviation)];
    }

    if (acc.find((deviation) => deviation.DeviationType === currentDeviation.DeviationType)) {
      return findDeviationTypeAndAddMissingColumns(acc, currentDeviation);
    }

    return [...acc, { ...createDeviationWithColumnsByDeviationType(currentDeviation) }];
  }, [] as Props[]);

const createDeviationWithColumnsByDeviationType = (deviation: TDeviation) => {
  return {
    DeviationType: deviation.DeviationType,
    columns: deviation.columns.map((column) => createColumnsWithEmptyValues(column)),
  };
};

const findDeviationTypeAndAddMissingColumns = (acc: Props[], deviation: TDeviation) => {
  const allDeviationTypesWithColumns = [...acc];
  const accIndex = allDeviationTypesWithColumns.findIndex((accElement) => accElement.DeviationType === deviation.DeviationType);
  const columnsForDeviationType = allDeviationTypesWithColumns[accIndex].columns;

  deviation.columns.forEach((deviationColumn) => {
    const foundColumn = columnsForDeviationType.find((column) => column.columnId === deviationColumn.columnId);
    if (!foundColumn) {
      columnsForDeviationType.splice(deviationColumn.columnId - 1, 0, createColumnsWithEmptyValues(deviationColumn));
    }
  });

  return allDeviationTypesWithColumns;
};

const createColumnsWithEmptyValues = (deviationColumn: TDeviationColumn) => {
  return { ...deviationColumn, cells: createNewCellsWithEmptyValues(deviationColumn.cells) };
};

const createNewCellsWithEmptyValues = (cells: TDeviationCell[]) =>
  cells.map((cell) => {
    return { ...cell, value: DEVIATION_CELL_EMPTY_VALUE };
  });

export { allColumnsForDeviationType };
