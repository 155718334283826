import { Dayjs } from 'dayjs';
import { VertragVposIndexedValue, VertragVposIndexedValueUpdateInput } from '../../../../../../../types';
import { mapFormDateValueToDateString } from '../../../../../../../helpers/formikHelper';
import { dayjsCustom } from '../../../../../../../helpers/dayjsCustom';

// Define the form values
export type VertragVposIndexedValueFormValues = {
  vertragVposIndexedValueId: string;
  amount: number;
  factorPercent: number;
  validFrom: Dayjs | string;
};

// Define the form fields
export const vertragVposIndexedValueFormFields = {
  vertragVposIndexedValueId: 'vertragVposIndexedValueId',
  amount: 'amount',
  factorPercent: 'factorPercent',
  validFrom: 'validFrom',
};

// Define the mapper functions
export const mapVertragVposIndexedValueToFormValues = (indexedValue: VertragVposIndexedValue): VertragVposIndexedValueFormValues => ({
  vertragVposIndexedValueId: indexedValue.vertragVposIndexedValueId,
  amount: indexedValue.amountNew,
  factorPercent: indexedValue.factorNewPercent,
  validFrom: indexedValue.validFrom,
});

export const mapFormValuesToVertragVposIndexedValue = (values: VertragVposIndexedValueFormValues): VertragVposIndexedValueUpdateInput => ({
  amount: values.amount,
  factorPercent: values.factorPercent,
  validFrom: mapFormDateValueToDateString(dayjsCustom(values.validFrom).startOf('month')),
});
