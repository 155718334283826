import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { useEmailDeliveryDefinitionQuery } from '../EmailDeliveryDefinition/gql/EmailDeliveryDefinitionQueries.types';
import { generatePathToEmailDeliveryDefinitionPage } from '../EmailDeliveryDefinition/emailDeliveryDefinitionUriPaths';

type Props = {
  bezeichnung: string;
  emailDeliveryDefinitionId: string;
};

const EmailDeliveryDefinitionTextOrLink: FC<Props> = ({ bezeichnung, emailDeliveryDefinitionId }) => {
  const { data } = useEmailDeliveryDefinitionQuery({
    variables: { emailDeliveryDefinitionId },
  });

  if (!data) return <Typography.Text>{bezeichnung}</Typography.Text>;

  return (
    <Link target="_blank" to={generatePathToEmailDeliveryDefinitionPage(emailDeliveryDefinitionId)}>
      {bezeichnung}
    </Link>
  );
};
export default EmailDeliveryDefinitionTextOrLink;
