import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AbrechnungsdefinitionListEntryFieldsFragmentDoc, KontoAbrechnungskreisFieldsFragmentDoc } from './AbrDefFragments.types';
import { TextbausteinFieldsFragmentDoc } from '../../../components/Template/PDFTemplates/gql/TemplateFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AbrechnungsdefinitionListQueryVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['String']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type AbrechnungsdefinitionListQuery = {
  getAbrechnungsdefinitionListByKundenSystemId: {
    data: {
      contentList: Array<{
        abrechnungsdefinitionId: string;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        textBausteineBefuellt: boolean;
        abrechnungsdefinitionVersion?: {
          abrechnungsdefinitionVersionId: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          abrechdefAbrechkreisList: Array<{
            abrechnungskreis: {
              abrechnungskreisId: string;
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              updatedByMitarbeiterId?: string | null;
              status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
              type: { text: string; value: Types.AbrechnungskreisType };
              verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            aufteilungsschluessel?: {
              aufteilungsschluesselId: string;
              bezeichnung: string;
              bezugsbasisEditable?: boolean | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              kurzBezeichnung: string;
              updatedByMitarbeiterId?: string | null;
              bezugsbasis: { text: string; value: Types.Bezugsbasis };
              masseinheit: { text: string; value: Types.Masseinheit };
              status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            vorschreibungsposition?: {
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              kurzBezeichnung: string;
              tagList: Array<string>;
              updatedByMitarbeiterId?: string | null;
              vorschreibungspositionId: string;
              art: { text: string; value: Types.VorschreibungspositionArt };
              kontierungstabelle?: {
                basiskonto: number;
                bezeichnung: string;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                kontierungstabelleId: string;
                updatedByMitarbeiterId?: string | null;
                status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
                subAdministrationExpenseAccount?: {
                  bezeichnung: string;
                  firmendatenId?: string | null;
                  kontoId: string;
                  kontoKlasse: string;
                  nummer: string;
                } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
              umsatzsteuerkennzeichen?: {
                bezeichnung: string;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                kuerzel: string;
                umsatzsteuerkennzeichenId: string;
                status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
                warningList: Array<{ message: string; type: string; attribute?: string | null }>;
              } | null;
              warningList: Array<{ message: string; type: string; attribute?: string | null }>;
            } | null;
          }>;
          kontoAbrechnungskreisList: Array<{
            abrechnen: boolean;
            buchungszeilenAnzeigen: boolean;
            expenseReducing: boolean;
            ustVomAufwand: boolean;
            abrechnungskreis?: {
              abrechnungskreisId: string;
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              updatedByMitarbeiterId?: string | null;
              status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
              type: { text: string; value: Types.AbrechnungskreisType };
              verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            aufteilungsschluessel?: {
              aufteilungsschluesselId: string;
              bezeichnung: string;
              bezugsbasisEditable?: boolean | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              kurzBezeichnung: string;
              updatedByMitarbeiterId?: string | null;
              bezugsbasis: { text: string; value: Types.Bezugsbasis };
              masseinheit: { text: string; value: Types.Masseinheit };
              status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
          }>;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status?: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus } | null;
        type: { text: string; value: Types.AbrechnungsdefinitionType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type AbrechnungsdefinitionTemplateQueryVariables = Types.Exact<{
  type: Types.AbrechnungsdefinitionTemplateType;
}>;

export type AbrechnungsdefinitionTemplateQuery = {
  getAbrechnungsdefinitionTemplate: {
    data: {
      textbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type AbrechnungsdefinitionTypeQueryVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
}>;

export type AbrechnungsdefinitionTypeQuery = { getAbrechnungsdefinitionType: { data: { text: string; value: Types.AbrechnungsdefinitionType } } };

export type AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQueryVariables = Types.Exact<{
  abrechnungsdefinitionVersionId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  kontoId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery = {
  getAbrechnungsdefinitionKontoAbrechnungskreisZuordnungList: {
    data: Array<{
      abrechnungsdefinitionVersionId: string;
      abrechnungsdefinition: {
        abrechnungsdefinitionId: string;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        textBausteineBefuellt: boolean;
        abrechnungsdefinitionVersion?: {
          abrechnungsdefinitionVersionId: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          abrechdefAbrechkreisList: Array<{
            abrechnungskreis: {
              abrechnungskreisId: string;
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              updatedByMitarbeiterId?: string | null;
              status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
              type: { text: string; value: Types.AbrechnungskreisType };
              verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            aufteilungsschluessel?: {
              aufteilungsschluesselId: string;
              bezeichnung: string;
              bezugsbasisEditable?: boolean | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              kurzBezeichnung: string;
              updatedByMitarbeiterId?: string | null;
              bezugsbasis: { text: string; value: Types.Bezugsbasis };
              masseinheit: { text: string; value: Types.Masseinheit };
              status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            vorschreibungsposition?: {
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              kurzBezeichnung: string;
              tagList: Array<string>;
              updatedByMitarbeiterId?: string | null;
              vorschreibungspositionId: string;
              art: { text: string; value: Types.VorschreibungspositionArt };
              kontierungstabelle?: {
                basiskonto: number;
                bezeichnung: string;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                kontierungstabelleId: string;
                updatedByMitarbeiterId?: string | null;
                status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
                subAdministrationExpenseAccount?: {
                  bezeichnung: string;
                  firmendatenId?: string | null;
                  kontoId: string;
                  kontoKlasse: string;
                  nummer: string;
                } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
              umsatzsteuerkennzeichen?: {
                bezeichnung: string;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                kuerzel: string;
                umsatzsteuerkennzeichenId: string;
                status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
                warningList: Array<{ message: string; type: string; attribute?: string | null }>;
              } | null;
              warningList: Array<{ message: string; type: string; attribute?: string | null }>;
            } | null;
          }>;
          kontoAbrechnungskreisList: Array<{
            abrechnen: boolean;
            buchungszeilenAnzeigen: boolean;
            expenseReducing: boolean;
            ustVomAufwand: boolean;
            abrechnungskreis?: {
              abrechnungskreisId: string;
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              updatedByMitarbeiterId?: string | null;
              status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
              type: { text: string; value: Types.AbrechnungskreisType };
              verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            aufteilungsschluessel?: {
              aufteilungsschluesselId: string;
              bezeichnung: string;
              bezugsbasisEditable?: boolean | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              kurzBezeichnung: string;
              updatedByMitarbeiterId?: string | null;
              bezugsbasis: { text: string; value: Types.Bezugsbasis };
              masseinheit: { text: string; value: Types.Masseinheit };
              status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
          }>;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status?: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus } | null;
        type: { text: string; value: Types.AbrechnungsdefinitionType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      kontoAbrechnungskreisList: Array<{
        abrechnen: boolean;
        buchungszeilenAnzeigen: boolean;
        expenseReducing: boolean;
        ustVomAufwand: boolean;
        abrechnungskreis?: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const AbrechnungsdefinitionListDocument = gql`
  query AbrechnungsdefinitionList($kundenSystemId: String!, $withDetails: Boolean, $includeArchiviert: Boolean) {
    getAbrechnungsdefinitionListByKundenSystemId(kundenSystemId: $kundenSystemId, withDetails: $withDetails, includeArchiviert: $includeArchiviert) {
      data {
        contentList {
          ...AbrechnungsdefinitionListEntryFields
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${AbrechnungsdefinitionListEntryFieldsFragmentDoc}
`;
export function useAbrechnungsdefinitionListQuery(
  baseOptions: Apollo.QueryHookOptions<AbrechnungsdefinitionListQuery, AbrechnungsdefinitionListQueryVariables> &
    ({ variables: AbrechnungsdefinitionListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbrechnungsdefinitionListQuery, AbrechnungsdefinitionListQueryVariables>(AbrechnungsdefinitionListDocument, options);
}
export function useAbrechnungsdefinitionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AbrechnungsdefinitionListQuery, AbrechnungsdefinitionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbrechnungsdefinitionListQuery, AbrechnungsdefinitionListQueryVariables>(AbrechnungsdefinitionListDocument, options);
}
export function useAbrechnungsdefinitionListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AbrechnungsdefinitionListQuery, AbrechnungsdefinitionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AbrechnungsdefinitionListQuery, AbrechnungsdefinitionListQueryVariables>(AbrechnungsdefinitionListDocument, options);
}
export type AbrechnungsdefinitionListQueryHookResult = ReturnType<typeof useAbrechnungsdefinitionListQuery>;
export type AbrechnungsdefinitionListLazyQueryHookResult = ReturnType<typeof useAbrechnungsdefinitionListLazyQuery>;
export type AbrechnungsdefinitionListSuspenseQueryHookResult = ReturnType<typeof useAbrechnungsdefinitionListSuspenseQuery>;
export type AbrechnungsdefinitionListQueryResult = Apollo.QueryResult<AbrechnungsdefinitionListQuery, AbrechnungsdefinitionListQueryVariables>;
export const AbrechnungsdefinitionTemplateDocument = gql`
  query AbrechnungsdefinitionTemplate($type: AbrechnungsdefinitionTemplateType!) {
    getAbrechnungsdefinitionTemplate(type: $type) {
      data {
        textbausteinList {
          ...TextbausteinFields
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${TextbausteinFieldsFragmentDoc}
`;
export function useAbrechnungsdefinitionTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<AbrechnungsdefinitionTemplateQuery, AbrechnungsdefinitionTemplateQueryVariables> &
    ({ variables: AbrechnungsdefinitionTemplateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbrechnungsdefinitionTemplateQuery, AbrechnungsdefinitionTemplateQueryVariables>(
    AbrechnungsdefinitionTemplateDocument,
    options
  );
}
export function useAbrechnungsdefinitionTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AbrechnungsdefinitionTemplateQuery, AbrechnungsdefinitionTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbrechnungsdefinitionTemplateQuery, AbrechnungsdefinitionTemplateQueryVariables>(
    AbrechnungsdefinitionTemplateDocument,
    options
  );
}
export function useAbrechnungsdefinitionTemplateSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AbrechnungsdefinitionTemplateQuery, AbrechnungsdefinitionTemplateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AbrechnungsdefinitionTemplateQuery, AbrechnungsdefinitionTemplateQueryVariables>(
    AbrechnungsdefinitionTemplateDocument,
    options
  );
}
export type AbrechnungsdefinitionTemplateQueryHookResult = ReturnType<typeof useAbrechnungsdefinitionTemplateQuery>;
export type AbrechnungsdefinitionTemplateLazyQueryHookResult = ReturnType<typeof useAbrechnungsdefinitionTemplateLazyQuery>;
export type AbrechnungsdefinitionTemplateSuspenseQueryHookResult = ReturnType<typeof useAbrechnungsdefinitionTemplateSuspenseQuery>;
export type AbrechnungsdefinitionTemplateQueryResult = Apollo.QueryResult<
  AbrechnungsdefinitionTemplateQuery,
  AbrechnungsdefinitionTemplateQueryVariables
>;
export const AbrechnungsdefinitionTypeDocument = gql`
  query AbrechnungsdefinitionType($abrechnungsdefinitionId: ID!) {
    getAbrechnungsdefinitionType(abrechnungsdefinitionId: $abrechnungsdefinitionId) {
      data {
        text
        value
      }
    }
  }
`;
export function useAbrechnungsdefinitionTypeQuery(
  baseOptions: Apollo.QueryHookOptions<AbrechnungsdefinitionTypeQuery, AbrechnungsdefinitionTypeQueryVariables> &
    ({ variables: AbrechnungsdefinitionTypeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbrechnungsdefinitionTypeQuery, AbrechnungsdefinitionTypeQueryVariables>(AbrechnungsdefinitionTypeDocument, options);
}
export function useAbrechnungsdefinitionTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AbrechnungsdefinitionTypeQuery, AbrechnungsdefinitionTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbrechnungsdefinitionTypeQuery, AbrechnungsdefinitionTypeQueryVariables>(AbrechnungsdefinitionTypeDocument, options);
}
export function useAbrechnungsdefinitionTypeSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AbrechnungsdefinitionTypeQuery, AbrechnungsdefinitionTypeQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AbrechnungsdefinitionTypeQuery, AbrechnungsdefinitionTypeQueryVariables>(AbrechnungsdefinitionTypeDocument, options);
}
export type AbrechnungsdefinitionTypeQueryHookResult = ReturnType<typeof useAbrechnungsdefinitionTypeQuery>;
export type AbrechnungsdefinitionTypeLazyQueryHookResult = ReturnType<typeof useAbrechnungsdefinitionTypeLazyQuery>;
export type AbrechnungsdefinitionTypeSuspenseQueryHookResult = ReturnType<typeof useAbrechnungsdefinitionTypeSuspenseQuery>;
export type AbrechnungsdefinitionTypeQueryResult = Apollo.QueryResult<AbrechnungsdefinitionTypeQuery, AbrechnungsdefinitionTypeQueryVariables>;
export const AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListDocument = gql`
  query AbrechnungsdefinitionKontoAbrechnungskreisZuordnungList($abrechnungsdefinitionVersionId: String, $kontoId: String) {
    getAbrechnungsdefinitionKontoAbrechnungskreisZuordnungList(abrechnungsdefinitionVersionId: $abrechnungsdefinitionVersionId, kontoId: $kontoId) {
      data {
        abrechnungsdefinition {
          ...AbrechnungsdefinitionListEntryFields
        }
        abrechnungsdefinitionVersionId
        kontoAbrechnungskreisList {
          ...KontoAbrechnungskreisFields
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AbrechnungsdefinitionListEntryFieldsFragmentDoc}
  ${KontoAbrechnungskreisFieldsFragmentDoc}
`;
export function useAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery,
    AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery,
    AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQueryVariables
  >(AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListDocument, options);
}
export function useAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery,
    AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery,
    AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQueryVariables
  >(AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListDocument, options);
}
export function useAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery,
        AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery,
    AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQueryVariables
  >(AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListDocument, options);
}
export type AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery
>;
export type AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListLazyQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListLazyQuery
>;
export type AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListSuspenseQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListSuspenseQuery
>;
export type AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQueryResult = Apollo.QueryResult<
  AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery,
  AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQueryVariables
>;
