import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../helpers/selectHelper';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { useEbicsBankConfigListQuery } from '../Ebics/gql/EbicsBankConfigQueries.types';

type Props = { name: string } & SelectProps;

const EbicsBankConfigSelectForm: FC<Props> = ({ name, ...restProps }) => {
  const { data, loading } = useEbicsBankConfigListQuery();

  const ebicsBankConfigList = data?.getEbicsBankConfigList.data ?? [];

  return (
    <Select
      size="small"
      name={name}
      loading={loading}
      placeholder="Bank auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
      {...restProps}
    >
      {ebicsBankConfigList.map((ebicsBankConfig) => {
        const text = `${ebicsBankConfig.name} - ${ebicsBankConfig.url}`;
        return (
          <Select.Option key={ebicsBankConfig.ebicsBankConfigId} value={ebicsBankConfig.ebicsBankConfigId}>
            <DataWithShortenedText maxTextLength={35} text={text} />
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default EbicsBankConfigSelectForm;
