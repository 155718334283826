import React, { FC } from 'react';
import { FormikProps } from 'formik';
import RechtstraegerSelect from '../../../../../shared/Rechtstraeger/RechtstraegerSelect';
import { BookingInstructionFormValues } from '../../bookingInstructionFormMapper';
import { isBelegSymbolChosen, isBuchungskreisChosen, isBuchungTypeChosen } from '../../../shared/fibuBuchungsanweisungHelpers';
import {
  isFibuBuchungBelegSymbolEinzelrechnung,
  isFibuBuchungBelegSymbolSpesenRuecklastschrift,
  isFibuBuchungBelegSymbolVorschreibung,
  isFibuBuchungTypeAusgangsRechnung,
  isFibuBuchungTypeSpesen,
} from '../../../../BookingDetails/View/shared/bookingHelpers';
import { FibuBelegSymbol, FibuBuchungType } from '../../../../../types';

type Props = {
  name: string;
  formikProps: FormikProps<BookingInstructionFormValues>;
};

const BookingInstructionVertragspartnerSelect: FC<Props> = ({ name, formikProps }) => {
  const isSelectEnabledBasedOnBelegSymbolAndBuchungType = (belegSymbol: FibuBelegSymbol, buchungsType: FibuBuchungType) => {
    const isVorschreibungAndAR = isFibuBuchungBelegSymbolVorschreibung(belegSymbol) && isFibuBuchungTypeAusgangsRechnung(buchungsType);
    const isEinzelrechnungAndAR = isFibuBuchungBelegSymbolEinzelrechnung(belegSymbol) && isFibuBuchungTypeAusgangsRechnung(buchungsType);
    const isSpesenRuecklastschriftAndSpesen = isFibuBuchungBelegSymbolSpesenRuecklastschrift(belegSymbol) && isFibuBuchungTypeSpesen(buchungsType);

    return isVorschreibungAndAR || isEinzelrechnungAndAR || isSpesenRuecklastschriftAndSpesen;
  };

  const isSelectEnabled = (formikValues: BookingInstructionFormValues) => {
    return (
      isBuchungskreisChosen(formikValues) &&
      formikValues.buchungsKopf &&
      isBelegSymbolChosen(formikValues) &&
      isBuchungTypeChosen(formikValues) &&
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      isSelectEnabledBasedOnBelegSymbolAndBuchungType(formikValues.buchungsKopf.belegSymbol!, formikValues.buchungsKopf.buchungsType!)
    );
  };

  return <RechtstraegerSelect name={name} id={name} disabled={!isSelectEnabled(formikProps.values)} placeholder="Vertragspartner auswählen" />;
};

export default BookingInstructionVertragspartnerSelect;
