import React, { FC, ReactNode } from 'react';
import { Divider, Space, Typography } from 'antd';
import { StyledFiltersFormWithMoreThan4Fields } from './styled/Filters.style';
import FiltersColumn from './FiltersColumn';
import FiltersWithoutTitleAndActions from './FiltersWithoutTitleAndActions';
import TextForEmptyValue from '../Text/TextForEmptyValue';

type FiltersWith4OrMoreFieldsProps = {
  /** So user can decide which Filters should be shown when there are too many on a page - BuchungszeileListingFilters */
  filtersSelect?: ReactNode;
  /** For Action Buttons like 'Mahnungen erstellen', 'Ausgezifferte OPs anzeigen' */
  actionButtons?: ReactNode;
  /** For action or bulk actions that should be done on the filtered list, e.g. 'Auf Liste anwenden' in Mahnungen listing */
  filterActions?: ReactNode;
  /** If true, 'Filter' title will not be rendered */
  hideTitle?: boolean;
  leftColumn: ReactNode;
  rightColumn: ReactNode;
};

const FiltersWith4OrMoreFields: FC<FiltersWith4OrMoreFieldsProps> = ({
  filtersSelect,
  actionButtons,
  filterActions,
  hideTitle = false,
  leftColumn,
  rightColumn,
}) => {
  const columns = (
    <Space size="large" align="start">
      <FiltersColumn>{leftColumn}</FiltersColumn>
      <FiltersColumn>{rightColumn}</FiltersColumn>
    </Space>
  );

  const columnsWithFiltersSelect = (
    <Space direction="vertical" size={12}>
      {filtersSelect}
      {columns}
    </Space>
  );

  const styledFilters = (
    <>
      <StyledFiltersFormWithMoreThan4Fields size="small" layout="vertical" labelCol={{ span: 64 }} wrapperCol={{ span: 64 }}>
        {filtersSelect ? columnsWithFiltersSelect : columns}
      </StyledFiltersFormWithMoreThan4Fields>
    </>
  );

  return hideTitle && !actionButtons && !filterActions ? (
    <FiltersWithoutTitleAndActions styledFilters={styledFilters} />
  ) : (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space align="baseline" style={{ width: '100%', justifyContent: 'space-between' }}>
        {/* If Filters are it a tab on a DetailsPage, 'Filter' should not render
        EMPTY_VALUE needed for proper style in case 'Filter' is not rendered' */}
        {!hideTitle ? <Typography.Title level={4}>Filter</Typography.Title> : <TextForEmptyValue textToShow="none" />}
        <Space direction="vertical" align="end">
          <Space>{actionButtons}</Space>
          <Space>{filterActions}</Space>
        </Space>
      </Space>

      {styledFilters}
      <Divider style={{ marginTop: '8px', marginBottom: '24px' }} />
    </Space>
  );
};

export default FiltersWith4OrMoreFields;
