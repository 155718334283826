import * as Yup from 'yup';
import {
  firstnameValidationSchema,
  lastnameValidationSchema,
  salutationValidationSchema,
  titleTrailingValidationSchema,
  titleValidationSchema,
} from '../../../../shared/components/Person/personFormValidationSchema';
import { streetAddressValidationSchema } from '../../../../shared/components/Address/form/addressFieldsValidationSchema';
import { entityIsRequired } from '../../../../components/message/validationMsg';

/* eslint-disable no-template-curly-in-string */

export const tempContactPersonValidationSchemaOptional = Yup.lazy((value) => {
  if (value !== undefined) {
    return tempContactPersonValidationSchema;
  }
  return Yup.mixed().notRequired();
});

export const tempContactPersonValidationSchema = Yup.object().shape({
  salutation: salutationValidationSchema,
  title: titleValidationSchema,
  firstname: firstnameValidationSchema,
  lastname: lastnameValidationSchema,
  titleTrailing: titleTrailingValidationSchema,
});

// FIXME: should this function know about firmendaten? (it is in its name)
export const firmendatenFirmensitzValidationSchema = Yup.object().shape({
  address: streetAddressValidationSchema,
  //emailList: firmensitzEmailsValidationSchema,
  emailList: Yup.array().of(
    Yup.object().shape({
      wert: Yup.string().required(entityIsRequired('E-Mail Adresse')),
    })
  ),
  tempContactPerson: tempContactPersonValidationSchemaOptional,
});

/* eslint-enable no-template-curly-in-string */
