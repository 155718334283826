import * as Yup from 'yup';
import { validateGeneralBezeichnung } from '../../../shared/components/KurzBezeichnung/form/kurzBezeichnungFormPartValidationSchema';
import { entityIsRequired } from '../../../components/message/validationMsg';
import { kontoFormFields } from './kontoFormMapper';
import { isKontoKlasseUmlaufVermoegen, isKontoTypeSachkonto } from './kontoFormHelpers';
import { validateRequiredStringWithMaxLength } from '../../../helpers/validationHelper';

/* eslint-disable no-template-curly-in-string */
export const kontoFormValidationSchema = Yup.object().shape({
  [kontoFormFields.klasse]: Yup.string().required(entityIsRequired('Kontoklasse')),
  [kontoFormFields.type]: Yup.string().required(entityIsRequired('Kontotyp')),
  [kontoFormFields.verwendung]: Yup.string().when([kontoFormFields.klasse, kontoFormFields.type], ([klasse, type], schema) =>
    isKontoTypeSachkonto(type) && isKontoKlasseUmlaufVermoegen(klasse) ? schema.required(entityIsRequired('Kontoverwendung')) : schema
  ),
  [kontoFormFields.bezeichnung]: validateGeneralBezeichnung(100, true),
  [kontoFormFields.nummer]: validateRequiredStringWithMaxLength('Kontonummer', 10).matches(
    /^([0-9]+)$/,
    'Kontonummer darf nur eine Nummer enthalten'
  ),
});
/* eslint-enable no-template-curly-in-string */
