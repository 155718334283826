import { isNil } from '../helpers/assertionHelper';

export const buildUri = (path: string, queryParamsJson?: Record<string, any>, labelQueryParamsJson?: Record<string, any>): string => {
  const uri = encodeURI(path);
  const uriPartQueryParams = queryParamsJson ? mapJsonToQueryParams(queryParamsJson) : '';
  const uriPartLabelQueryParams = labelQueryParamsJson ? mapJsonToLabelQueryParams(labelQueryParamsJson) : '';
  if (uriPartQueryParams && uriPartLabelQueryParams) {
    return `${uri}?${uriPartQueryParams}&${uriPartLabelQueryParams}`;
  }
  if (uriPartQueryParams || uriPartLabelQueryParams) {
    return `${uri}?${uriPartQueryParams}${uriPartLabelQueryParams}`;
  }
  return uri;
};

export const mapJsonToQueryParams = (json: Record<string, any>) =>
  Object.keys(json)
    .filter((key) => !isNil(json[key]) && json[key] !== '')
    .map((i) => `${encodeURIComponent(i)}=${encodeURIComponent(json[i])}`)
    .join('&');

export const mapJsonToLabelQueryParams = (json: Record<string, any>): string =>
  Object.keys(json)
    .filter((key) => !isNil(json[key]) && json[key] !== '')
    .map((key, index) => {
      const labelKeyKey = encodeURIComponent(`label[${index}]`);
      const labelKeyValue = encodeURIComponent(key);
      const labelValueKey = encodeURIComponent(`labelValue[${index}]`);
      const labelValueValue = encodeURIComponent(json[key]);
      return `${labelKeyKey}=${labelKeyValue}&${labelValueKey}=${labelValueValue}`;
    })
    .join('&');
