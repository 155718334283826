import styled from 'styled-components';

export const StyledTableVorschreibungRows = styled('table')`
  tbody {
    tr {
      td {
        text-align: left;
        justify-content: flex-end;
        &:hover {
          background: #fafafa;
        }
        &:nth-last-child(2) {
          width: 26.7%;
        }
        &:last-child {
          width: 10.6%;
        }
      }
      &:last-child td {
        border: 0;
      }
    }
  }
  thead {
    background-color: #fafafa;
    tr {
      th {
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        &:nth-last-child(2) {
          width: 26.7%;
        }
        &:last-child {
          width: 10.6%;
        }
      }
      &:hover {
        background: #fafafa;
      }
    }
  }
`;
