import React, { FC } from 'react';
import { Button, Dropdown, MenuProps, Modal } from 'antd';
import { DeleteOutlined, EllipsisOutlined, FileSearchOutlined, ReadOutlined } from '@ant-design/icons';
import { useDeleteVorsteuerkuerzungMutation, useVorsteuerkuerzungMarkUnderReviewMutation } from '../gql/VorsteuerkuerzungMutations.types';
import { showSuccessMsgDelete, showSuccessMsgOther } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { useGoBack } from '../../../shared/GoBack/GoBackContext';
import { URI_DASHBOARD_WORKSPACE_PAGE } from '../../../constants/appUriPaths';
import { isStatusValid, isVstKuerzungBuchungStatusVerbucht, isVstKuerzungBuchungStatusWirdVerbucht } from '../../../helpers/statusHelper';
import { useToggle } from '../../../hooks/useToggle';
import VstKrzFreigebenModal from './FreigebenModal/VstKrzFreigebenModal';
import { Vorsteuerkuerzung } from '../../../types';

type Props = {
  vstKuerzung: Vorsteuerkuerzung;
  objektId: string;
  onAction: () => void;
};

const VorsteuerkuerzungActions: FC<Props> = ({ vstKuerzung, objektId, onAction }) => {
  const goBack = useGoBack(URI_DASHBOARD_WORKSPACE_PAGE);

  const entity = `Vorsteuerkürzung`;

  const [runDelete] = useDeleteVorsteuerkuerzungMutation({
    variables: { vorsteuerkuerzungId: vstKuerzung.vorsteuerkuerzungId, objektId },
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      goBack();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runMarkUnderReview] = useVorsteuerkuerzungMarkUnderReviewMutation({
    variables: { vorsteuerkuerzungId: vstKuerzung.vorsteuerkuerzungId, objektId },
    onCompleted: () => {
      showSuccessMsgOther(`${entity} wird überprüft`);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [isCollapsedModal, onCollapseModal] = useToggle();

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Verbuchen',
      onClick: onCollapseModal,
      icon: <ReadOutlined />,
      disabled:
        isVstKuerzungBuchungStatusVerbucht(vstKuerzung.buchungStatus) ||
        isVstKuerzungBuchungStatusWirdVerbucht(vstKuerzung.buchungStatus) ||
        !isStatusValid(vstKuerzung.status),
    },
    {
      key: '2',
      label: 'Prüfen',
      onClick: () => showConfirmMarkUnderReview(runMarkUnderReview),
      icon: <FileSearchOutlined />,
    },
    {
      key: '3',
      label: 'Löschen',
      onClick: () => showConfirmDelete(runDelete),
      icon: <DeleteOutlined />,
      danger: true,
      disabled: isVstKuerzungBuchungStatusVerbucht(vstKuerzung.buchungStatus) || isVstKuerzungBuchungStatusWirdVerbucht(vstKuerzung.buchungStatus),
    },
  ];

  const onActionSuccess = () => {
    onAction();
    onCollapseModal();
  };

  return (
    <>
      <Dropdown menu={{ items }}>
        <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
      </Dropdown>
      <VstKrzFreigebenModal vstKuerzung={vstKuerzung} onAction={onActionSuccess} visible={isCollapsedModal} objektId={objektId} />
    </>
  );
};

const showConfirmDelete = (runDelete: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie dieses Vorsteuerkürzung löschen?',
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

const showConfirmMarkUnderReview = (runMarkUnderReview: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie dieses Vorsteuerkürzung prüfen?',
    okText: 'Prüfen',
    cancelText: 'Abbrechen',
    onOk() {
      return runMarkUnderReview();
    },
  });
};

export default VorsteuerkuerzungActions;
