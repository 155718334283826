import { URI_SYS_SETTINGS } from '../../constants/configurationUriPaths';
import { VorschreibungspositionStatus } from '../../types';
import { IStatus } from '../../helpers/statusHelper';
import { mapToQueryString, QUERY_PARAM_KEY } from '../../hooks/useStringQueryParams';

export const generatePathToVorschreibungspositionenPage = (vorschreibungspositionId: string, status?: IStatus) =>
  `${URI_SYS_SETTINGS.vorschreibungspositionPage}?${mapToQueryString({
    [QUERY_PARAM_KEY.SHOW_ARCHIVED]: status?.value === VorschreibungspositionStatus.Archiviert,
    [QUERY_PARAM_KEY.HIGHLIGHTED_ROW_KEY]: vorschreibungspositionId,
  })}`;
