import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../../styles/pdfStyles';
import { AbrechnungskreisDetailsAbrechnungskreis } from '../../../../../topAbrechnung-types';
import TAAbrKreisNonRepFondsKontoList from './TAAbrKreisNonRepFondsKontoList';
import TAAbrKreisAufwandssumme from './TAAbrKreisAufwandssumme';
import TAAbrKreisTitle from '../TAAbrKreisTitle';
import TAAbrKreisAufteilungTopBlock from './TAAbrKreisAufteilungTopBlock/TAAbrKreisAufteilungTopBlock';
import TAAbrKreisAufwandAufteilung from './TAAbrKreisAufteilungTopBlock/TAAbrKreisAufwandAufteilung';
import TATAufwandZeitscheibe from '../../../../TATAufwandZeitscheibe';

const TAAbrKreisNonRepFonds: FC<{ abrechKreis: AbrechnungskreisDetailsAbrechnungskreis }> = ({ abrechKreis }) => (
  <View style={{ marginBottom: '10mm' }}>
    <View style={[pdfStyles.column, { marginTop: '3mm' }]}>
      {/* Abrechnungskreis title */}
      <TAAbrKreisTitle abrechKreis={abrechKreis} />

      {/* aufteilungAufwandNonRepFondsList is always delivered if abrechKreis is not RepFonds, This check is only to avoid non-null assertion check by esLint and errors while generating pdfs */}
      {abrechKreis.aufteilungAufwandNonRepFondsList &&
        abrechKreis.aufteilungAufwandNonRepFondsList.map((aufteilungAufwand, index) => (
          <View key={index}>
            <View style={{ width: '60%' }}>
              {/* Konten */}
              <TAAbrKreisNonRepFondsKontoList aufteilungAufwand={aufteilungAufwand} />
              {/* Aufwandssumme */}
              {aufteilungAufwand.aufwandSumme ? <TAAbrKreisAufwandssumme aufwandSumme={aufteilungAufwand.aufwandSumme} /> : null}
              {/* Aufwandaufteilung with Aufteilungschluessel name and Zeitscheibe listing */}
              {aufteilungAufwand.aufwandAufteilung ? <TAAbrKreisAufwandAufteilung aufwandAufteilung={aufteilungAufwand.aufwandAufteilung} /> : null}
            </View>

            {/* Aufwandaufteilung Summe */}
            {aufteilungAufwand.aufwandAufteilung.summe ? (
              <View style={[pdfStyles.borderBottomSmall, { marginBottom: '5mm' }]}>
                <TATAufwandZeitscheibe zeitscheibe={aufteilungAufwand.aufwandAufteilung.summe} />
              </View>
            ) : null}
          </View>
        ))}
    </View>

    {/* Aufteilungtop block */}
    {abrechKreis.aufteilungTop ? <TAAbrKreisAufteilungTopBlock aufteilungTop={abrechKreis.aufteilungTop} /> : null}
  </View>
);

export default TAAbrKreisNonRepFonds;
