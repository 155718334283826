import React, { FC } from 'react';
import FieldArrayInput from '../../../components/FieldArrayInput';
import { validateNotRequiredPhoneNumber } from './phoneNumberValidation';

type PhoneNumberFieldArrayInputProps = {
  arrayName: string;
  arrayObjectAttribute: string;
  newFieldValue: Record<string, any>;
  validateOnChange: boolean;
};

const PhoneNumberFieldArrayInput: FC<PhoneNumberFieldArrayInputProps> = ({ arrayName, arrayObjectAttribute, newFieldValue, validateOnChange }) => (
  <FieldArrayInput
    type="tel"
    placeholder="Telefonnummer"
    label="Telefonnummer"
    fieldValidation={validateNotRequiredPhoneNumber}
    arrayName={arrayName}
    arrayObjectAttribute={arrayObjectAttribute}
    newFieldValue={newFieldValue}
    validateOnChange={validateOnChange}
  />
);

export default PhoneNumberFieldArrayInput;
