import React from 'react';
import { useParams } from 'react-router-dom';
import { useTopAbrechnungQuery } from '../../features/Abrechnung/BkOrWe/Top/gql/TopAbrechnungQueries.types';
import menuListTopAbr from './menuListTopAbr';
import DetailsPageTemplate from '../../components/Template/DetailsPageTemplate';
import TopAbrechnungCard from '../../features/Abrechnung/BkOrWe/Top/Details/Card/TopAbrechnungCard';
import { BkTopAbrechnungDetailsPageRouteParams } from './routes';
import { useOnBkOrWeTopAbrechnungDataChangedEvents } from '../../features/Abrechnung/BkOrWe/Top/useOnBkOrWeTopAbrechnungDataChangeEvent';
import { TopAbrechnung } from '../../types';

const BkTopAbrechnungDetailsPage = () => {
  const { objektId, objektAbrechnungId, topAbrechnungId } = useParams() as BkTopAbrechnungDetailsPageRouteParams;

  const { data, refetch } = useTopAbrechnungQuery({
    variables: {
      objektId,
      objektAbrechnungId,
      topAbrechnungId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });
  const topAbrechnung = data?.getTopAbrechnung.data;
  const topAbrechnungLoading = !topAbrechnung;

  useOnBkOrWeTopAbrechnungDataChangedEvents('topAbrechnung', refetch);

  return (
    <DetailsPageTemplate<TopAbrechnung>
      data={topAbrechnung}
      pageTitle={(topAbrechnung) => topAbrechnung.bezeichnung}
      card={(topAbrechnung) => <TopAbrechnungCard loading={topAbrechnungLoading} topAbrechnung={topAbrechnung} objektId={objektId} />}
      sidebarMenuList={(topAbrechnung) => menuListTopAbr(topAbrechnung, objektId, objektAbrechnungId)}
    />
  );
};

export default BkTopAbrechnungDetailsPage;
