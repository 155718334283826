import { FC } from 'react';
import { FieldArrayRenderProps, FormikProps } from 'formik';
import { Typography } from 'antd';
import { BookingSuggestionFormValues, OffenePostenForClearingSuggestion } from '../../../Form/buchungsmaskeFormMapper';
import { getAuszifferungListFormFieldNameFromPersonenkontoOPList } from '../../auszifferungBlockHelpers';
import { AuszifferungFormValues } from '../../auszifferungFormMapper';
import { getAuszifferungIndex, isNewAuszifferung } from '../../bookingSuggestionAuszifferungBlockHelpers';

type Props = {
  arrayHelpers: FieldArrayRenderProps;
  formikProps: FormikProps<BookingSuggestionFormValues>;
  offenePosten: OffenePostenForClearingSuggestion;
  offenePostenIndex: number;
  record: AuszifferungFormValues;
};

const DeleteAuszifferungForClearingSuggTableColumn: FC<Props> = ({ arrayHelpers, formikProps, offenePosten, offenePostenIndex, record }) => {
  const removeAuszifferung = () => {
    // For the main OP list with booking suggestions -  if an Auszifferung suggestion is deleted, the entire Offene Posten entry should be deleted from the list
    arrayHelpers.remove(offenePostenIndex);

    // Actions for the Personenkonto OP list: remove the deleted Auszifferung, but keep the rest of the Auszifferung entries in the OP list
    const auszifferungIndex = getAuszifferungIndex(record, offenePosten);
    const newOffenePostenAzList = offenePosten.auszifferungList.toSpliced(auszifferungIndex, 1);
    const auszifferungListFieldNameFromPersonenkontoOPList = getAuszifferungListFormFieldNameFromPersonenkontoOPList(
      formikProps.values,
      offenePosten
    );
    formikProps.setFieldValue(auszifferungListFieldNameFromPersonenkontoOPList, newOffenePostenAzList);
  };

  return isNewAuszifferung(record) ? (
    <Typography.Text style={{ color: '#1677ff', cursor: 'pointer' }} onClick={removeAuszifferung}>
      Entfernen
    </Typography.Text>
  ) : null;
};

export default DeleteAuszifferungForClearingSuggTableColumn;
