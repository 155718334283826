import { FormFields } from '../../../../../helpers/formikHelper';
import { FormFileReference } from '../../../../../components/FileUpload/formHelpers';
import { SepaMandat } from '../../../../../types';

export interface SepaMandatActivateOrUpdateFormValues {
  signatureDate?: string | null;
  signatureFile?: FormFileReference | null;
}

export const sepaMandatActivateFormInitialValues: SepaMandatActivateOrUpdateFormValues = {
  signatureDate: null,
  signatureFile: null, //required so that Formik validates the field when the file was not attached when activating the mandate
};

export const sepaMandatUpdateFormInitialValues = (sepaMandat: SepaMandat): SepaMandatActivateOrUpdateFormValues => {
  return {
    signatureDate: sepaMandat.signatureDate,
    signatureFile:
      sepaMandat.fileId && sepaMandat.fileName
        ? {
            fileId: sepaMandat.fileId,
            filename: sepaMandat.fileName,
            isUpload: false,
          }
        : null,
  };
};

export const sepaMandatActivateOrUpdateFormFields: FormFields<SepaMandatActivateOrUpdateFormValues> = {
  signatureDate: 'signatureDate',
  signatureFile: 'signatureFile',
};
