import React, { FC, useState } from 'react';
import { Flex } from 'rebass';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, Space, Tabs, TabsProps, Typography } from 'antd';
import { Spacer } from '../../../../components/Grid';
import { mapTextbausteinListToFormValues, TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';
import { AbrechnungsdefinitionType, BkAbrechnungsdefinitionVersion } from '../../../../types';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';
import AbrDefTemplateKontenzuordnung from '../../shared/AbrDefTemplateKontenzuordnung';
import { WeAbrechnungsdefinitionVersionFieldsFragment } from '../../../Abrechnungsdefinition/gql/WeAbrDef/WeAbrDefVersionFragments.types';
import {
  mapObjAbrDefOutputOptionsToFormValues,
  ObjAbrDefGeneralSettingsFormValues,
} from '../../../Abrechnungsdefinition/shared/Templates/shared/BkOrWe/ObjAbrDefGeneralSettingsForm/objAbrDefGeneralSettingsFormMapper';
import {
  mapTopAbrDefGeneralSettingsToInitialValues,
  TopAbrDefGeneralSettingsFormValues,
} from '../../../Abrechnungsdefinition/shared/Templates/shared/BkOrWe/TopAbrDefGeneralSettingsForm/topAbrDefGeneralSettingsFormMapper';
import { isAbrDefTypeWeAbrechnung } from '../../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import { generatePathToAbrechnungsdefinitionTemplateVersionUpdatePage } from '../../abrDefTemplateUriPaths';
import AbrDefTemplateVersionViewAbrKreisList from './AbrDefTemplateVersionViewAbrKreisList';
import { createObjektAbrechnungTemplatePDFData } from '../../../Abrechnungsdefinition/shared/Templates/WeTemplates/weAbrDefTemplateFormObjAbrMapper';
import BkOrWeObjAbrDefVersionView from '../../../Abrechnungsdefinition/Version/View/BkOrWeObjAbrDefVersionView';
import BkOrWeTopAbrDefVersionView from '../../../Abrechnungsdefinition/Version/View/BkOrWeTopAbrDefVersionView';

type Props = {
  abrDefType: AbrechnungsdefinitionType;
  abrechnungsdefinitionId: string;
  // @ts-ignore
  abrechnungsdefinitionVersion: WeAbrechnungsdefinitionVersionFieldsFragment | BkAbrechnungsdefinitionVersion;
  isHistoricized: boolean;
};

const BkOrWeAbrDefTemplateVersionView: FC<Props> = ({ abrDefType, abrechnungsdefinitionId, abrechnungsdefinitionVersion, isHistoricized }) => {
  const navigate = useNavigate();
  const [objAbrDefTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(abrechnungsdefinitionVersion.objektAbrechnungTextbausteinList)
  );
  const [topAbrDefTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(abrechnungsdefinitionVersion.topAbrechnungTextbausteinList)
  );
  const [objAbrDefGeneralSettingsValues] = useState<ObjAbrDefGeneralSettingsFormValues>(
    mapObjAbrDefOutputOptionsToFormValues(abrechnungsdefinitionVersion.objektAbrechnungOutputOptions)
  );

  const [topAbrDefGeneralSettingsValues] = useState<TopAbrDefGeneralSettingsFormValues>(
    mapTopAbrDefGeneralSettingsToInitialValues(abrDefType, abrechnungsdefinitionVersion.topAbrechnungOutputOptions)
  );

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Abrechnungskreise',
      children: (
        <>
          <Spacer height={16} />
          <AbrDefTemplateVersionViewAbrKreisList
            abrechdefAbrechkreisList={abrechnungsdefinitionVersion.abrechdefAbrechkreisList}
            abrDefType={abrDefType}
          />
        </>
      ),
    },
    // TODO: Add allgemeine einstellungen tab
    {
      key: '2',
      label: isAbrDefTypeWeAbrechnung(abrDefType) ? 'WE-Objektabrechnung' : 'BK-Objektabrechnung',
      children: (
        <BkOrWeObjAbrDefVersionView
          objAbrDefTemplateValues={objAbrDefTemplateValues}
          objAbrData={createObjektAbrechnungTemplatePDFData(objAbrDefTemplateValues, objAbrDefGeneralSettingsValues)}
          objAbrDefGeneralSettingsValues={objAbrDefGeneralSettingsValues}
        />
      ),
    },
    {
      key: '3',
      label: isAbrDefTypeWeAbrechnung(abrDefType) ? 'WE-Topabrechnung' : 'BK-Topabrechnung',
      children: (
        <BkOrWeTopAbrDefVersionView
          abrDefType={abrDefType}
          topAbrDefTemplateValues={topAbrDefTemplateValues}
          topAbrDefGeneralSettingsValues={topAbrDefGeneralSettingsValues}
        />
      ),
    },
    {
      key: '4',
      label: 'Kontenzuordnung',
      children: (
        <AbrDefTemplateKontenzuordnung
          abrechnungsdefinitionVersion={abrechnungsdefinitionVersion}
          abrechnungsdefinitionId={abrechnungsdefinitionId}
          abrDefinitionType={abrDefType}
          mode="onlyView"
        />
      ),
    },
  ];

  return (
    <>
      <Flex>
        <Space>
          <Typography.Text strong>Gültig ab</Typography.Text>
          <Typography.Text>
            <CustomFormattedDate value={abrechnungsdefinitionVersion.validFrom} />
          </Typography.Text>
        </Space>
        <Space style={{ marginLeft: '10px' }}>
          <Typography.Text strong>Kommentar</Typography.Text>
          <Typography.Text>{abrechnungsdefinitionVersion.kommentar}</Typography.Text>
        </Space>
      </Flex>
      <Divider />
      <Tabs items={items} />
      <Spacer height={16} />
      <Space style={{ width: '100%', justifyContent: 'end' }}>
        <Button onClick={() => navigate(URI_ANDROMEDA_SYS_SETTINGS.abrechnungsdefinitionPage)}>Schließen</Button>
        <Button
          type="primary"
          disabled={isHistoricized}
          onClick={() =>
            navigate(
              generatePathToAbrechnungsdefinitionTemplateVersionUpdatePage(
                abrechnungsdefinitionId,
                abrechnungsdefinitionVersion.abrechnungsdefinitionVersionId
              )
            )
          }
        >
          Bearbeiten
        </Button>
      </Space>
    </>
  );
};

export default BkOrWeAbrDefTemplateVersionView;
