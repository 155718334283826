import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { HistoryListFieldsFragmentDoc } from '../../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PauschalmieteAufwandskontoListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type PauschalmieteAufwandskontoListQuery = {
  getPauschalmieteAufwandskontoList: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      firmendatenPauschalmieteAufwandskontoId: string;
      validFrom: string;
      kommentar?: string | null;
      lastUpdateTs: string;
      pauschalmieteAufwandskontoId: string;
      historicizedList?: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        firmendatenPauschalmieteAufwandskontoId: string;
        validFrom: string;
        kommentar?: string | null;
        lastUpdateTs: string;
        pauschalmieteAufwandskontoId: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type PauschalmieteAufwandskontoQueryVariables = Types.Exact<{
  firmendatenPauschalmieteAufwandskontoId: Types.Scalars['ID']['input'];
}>;

export type PauschalmieteAufwandskontoQuery = {
  getPauschalmieteAufwandskonto: {
    data: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      firmendatenPauschalmieteAufwandskontoId: string;
      validFrom: string;
      kommentar?: string | null;
      lastUpdateTs: string;
      pauschalmieteAufwandskontoId: string;
      historicizedList?: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        firmendatenPauschalmieteAufwandskontoId: string;
        validFrom: string;
        kommentar?: string | null;
        lastUpdateTs: string;
        pauschalmieteAufwandskontoId: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type PauschalmieteKontenplanQueryVariables = Types.Exact<{
  date: Types.Scalars['String']['input'];
}>;

export type PauschalmieteKontenplanQuery = {
  getPauschalmieteKontenplan: {
    data: {
      pauschalmieteForderungskontoId: string;
      pauschalmieteAufwandskonto: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        firmendatenPauschalmieteAufwandskontoId: string;
        validFrom: string;
        kommentar?: string | null;
        lastUpdateTs: string;
        pauschalmieteAufwandskontoId: string;
        historicizedList?: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          firmendatenPauschalmieteAufwandskontoId: string;
          validFrom: string;
          kommentar?: string | null;
          lastUpdateTs: string;
          pauschalmieteAufwandskontoId: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }> | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type PauschalmieteForderungsKontoChangeHistoryListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type PauschalmieteForderungsKontoChangeHistoryListQuery = {
  getPauschalmieteForderungsKontoChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const PauschalmieteAufwandskontoListDocument = gql`
  query PauschalmieteAufwandskontoList {
    getPauschalmieteAufwandskontoList {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
        current
        firmendatenPauschalmieteAufwandskontoId
        validFrom
        historicizedList {
          createTs
          createdBy
          createdByMitarbeiterId
          current
          firmendatenPauschalmieteAufwandskontoId
          validFrom
          kommentar
          lastUpdateTs
          pauschalmieteAufwandskontoId
          warningList {
            attribute
            message
            type
          }
        }
        kommentar
        lastUpdateTs
        pauschalmieteAufwandskontoId
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function usePauschalmieteAufwandskontoListQuery(
  baseOptions?: Apollo.QueryHookOptions<PauschalmieteAufwandskontoListQuery, PauschalmieteAufwandskontoListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PauschalmieteAufwandskontoListQuery, PauschalmieteAufwandskontoListQueryVariables>(
    PauschalmieteAufwandskontoListDocument,
    options
  );
}
export function usePauschalmieteAufwandskontoListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PauschalmieteAufwandskontoListQuery, PauschalmieteAufwandskontoListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PauschalmieteAufwandskontoListQuery, PauschalmieteAufwandskontoListQueryVariables>(
    PauschalmieteAufwandskontoListDocument,
    options
  );
}
export function usePauschalmieteAufwandskontoListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PauschalmieteAufwandskontoListQuery, PauschalmieteAufwandskontoListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PauschalmieteAufwandskontoListQuery, PauschalmieteAufwandskontoListQueryVariables>(
    PauschalmieteAufwandskontoListDocument,
    options
  );
}
export type PauschalmieteAufwandskontoListQueryHookResult = ReturnType<typeof usePauschalmieteAufwandskontoListQuery>;
export type PauschalmieteAufwandskontoListLazyQueryHookResult = ReturnType<typeof usePauschalmieteAufwandskontoListLazyQuery>;
export type PauschalmieteAufwandskontoListSuspenseQueryHookResult = ReturnType<typeof usePauschalmieteAufwandskontoListSuspenseQuery>;
export type PauschalmieteAufwandskontoListQueryResult = Apollo.QueryResult<
  PauschalmieteAufwandskontoListQuery,
  PauschalmieteAufwandskontoListQueryVariables
>;
export const PauschalmieteAufwandskontoDocument = gql`
  query PauschalmieteAufwandskonto($firmendatenPauschalmieteAufwandskontoId: ID!) {
    getPauschalmieteAufwandskonto(firmendatenPauschalmieteAufwandskontoId: $firmendatenPauschalmieteAufwandskontoId) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
        current
        firmendatenPauschalmieteAufwandskontoId
        validFrom
        historicizedList {
          createTs
          createdBy
          createdByMitarbeiterId
          current
          firmendatenPauschalmieteAufwandskontoId
          validFrom
          kommentar
          lastUpdateTs
          pauschalmieteAufwandskontoId
          warningList {
            attribute
            message
            type
          }
        }
        kommentar
        lastUpdateTs
        pauschalmieteAufwandskontoId
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function usePauschalmieteAufwandskontoQuery(
  baseOptions: Apollo.QueryHookOptions<PauschalmieteAufwandskontoQuery, PauschalmieteAufwandskontoQueryVariables> &
    ({ variables: PauschalmieteAufwandskontoQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PauschalmieteAufwandskontoQuery, PauschalmieteAufwandskontoQueryVariables>(PauschalmieteAufwandskontoDocument, options);
}
export function usePauschalmieteAufwandskontoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PauschalmieteAufwandskontoQuery, PauschalmieteAufwandskontoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PauschalmieteAufwandskontoQuery, PauschalmieteAufwandskontoQueryVariables>(PauschalmieteAufwandskontoDocument, options);
}
export function usePauschalmieteAufwandskontoSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PauschalmieteAufwandskontoQuery, PauschalmieteAufwandskontoQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PauschalmieteAufwandskontoQuery, PauschalmieteAufwandskontoQueryVariables>(
    PauschalmieteAufwandskontoDocument,
    options
  );
}
export type PauschalmieteAufwandskontoQueryHookResult = ReturnType<typeof usePauschalmieteAufwandskontoQuery>;
export type PauschalmieteAufwandskontoLazyQueryHookResult = ReturnType<typeof usePauschalmieteAufwandskontoLazyQuery>;
export type PauschalmieteAufwandskontoSuspenseQueryHookResult = ReturnType<typeof usePauschalmieteAufwandskontoSuspenseQuery>;
export type PauschalmieteAufwandskontoQueryResult = Apollo.QueryResult<PauschalmieteAufwandskontoQuery, PauschalmieteAufwandskontoQueryVariables>;
export const PauschalmieteKontenplanDocument = gql`
  query PauschalmieteKontenplan($date: String!) {
    getPauschalmieteKontenplan(date: $date) {
      data {
        pauschalmieteAufwandskonto {
          createTs
          createdBy
          createdByMitarbeiterId
          current
          firmendatenPauschalmieteAufwandskontoId
          validFrom
          historicizedList {
            createTs
            createdBy
            createdByMitarbeiterId
            current
            firmendatenPauschalmieteAufwandskontoId
            validFrom
            kommentar
            lastUpdateTs
            pauschalmieteAufwandskontoId
            warningList {
              attribute
              message
              type
            }
          }
          kommentar
          lastUpdateTs
          pauschalmieteAufwandskontoId
          warningList {
            attribute
            message
            type
          }
        }
        pauschalmieteForderungskontoId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function usePauschalmieteKontenplanQuery(
  baseOptions: Apollo.QueryHookOptions<PauschalmieteKontenplanQuery, PauschalmieteKontenplanQueryVariables> &
    ({ variables: PauschalmieteKontenplanQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PauschalmieteKontenplanQuery, PauschalmieteKontenplanQueryVariables>(PauschalmieteKontenplanDocument, options);
}
export function usePauschalmieteKontenplanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PauschalmieteKontenplanQuery, PauschalmieteKontenplanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PauschalmieteKontenplanQuery, PauschalmieteKontenplanQueryVariables>(PauschalmieteKontenplanDocument, options);
}
export function usePauschalmieteKontenplanSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PauschalmieteKontenplanQuery, PauschalmieteKontenplanQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PauschalmieteKontenplanQuery, PauschalmieteKontenplanQueryVariables>(PauschalmieteKontenplanDocument, options);
}
export type PauschalmieteKontenplanQueryHookResult = ReturnType<typeof usePauschalmieteKontenplanQuery>;
export type PauschalmieteKontenplanLazyQueryHookResult = ReturnType<typeof usePauschalmieteKontenplanLazyQuery>;
export type PauschalmieteKontenplanSuspenseQueryHookResult = ReturnType<typeof usePauschalmieteKontenplanSuspenseQuery>;
export type PauschalmieteKontenplanQueryResult = Apollo.QueryResult<PauschalmieteKontenplanQuery, PauschalmieteKontenplanQueryVariables>;
export const PauschalmieteForderungsKontoChangeHistoryListDocument = gql`
  query PauschalmieteForderungsKontoChangeHistoryList {
    getPauschalmieteForderungsKontoChangeHistoryList {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function usePauschalmieteForderungsKontoChangeHistoryListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PauschalmieteForderungsKontoChangeHistoryListQuery,
    PauschalmieteForderungsKontoChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PauschalmieteForderungsKontoChangeHistoryListQuery, PauschalmieteForderungsKontoChangeHistoryListQueryVariables>(
    PauschalmieteForderungsKontoChangeHistoryListDocument,
    options
  );
}
export function usePauschalmieteForderungsKontoChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PauschalmieteForderungsKontoChangeHistoryListQuery,
    PauschalmieteForderungsKontoChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PauschalmieteForderungsKontoChangeHistoryListQuery, PauschalmieteForderungsKontoChangeHistoryListQueryVariables>(
    PauschalmieteForderungsKontoChangeHistoryListDocument,
    options
  );
}
export function usePauschalmieteForderungsKontoChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<PauschalmieteForderungsKontoChangeHistoryListQuery, PauschalmieteForderungsKontoChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PauschalmieteForderungsKontoChangeHistoryListQuery, PauschalmieteForderungsKontoChangeHistoryListQueryVariables>(
    PauschalmieteForderungsKontoChangeHistoryListDocument,
    options
  );
}
export type PauschalmieteForderungsKontoChangeHistoryListQueryHookResult = ReturnType<typeof usePauschalmieteForderungsKontoChangeHistoryListQuery>;
export type PauschalmieteForderungsKontoChangeHistoryListLazyQueryHookResult = ReturnType<
  typeof usePauschalmieteForderungsKontoChangeHistoryListLazyQuery
>;
export type PauschalmieteForderungsKontoChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof usePauschalmieteForderungsKontoChangeHistoryListSuspenseQuery
>;
export type PauschalmieteForderungsKontoChangeHistoryListQueryResult = Apollo.QueryResult<
  PauschalmieteForderungsKontoChangeHistoryListQuery,
  PauschalmieteForderungsKontoChangeHistoryListQueryVariables
>;
