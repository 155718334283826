import { Form, Typography } from 'antd';
import React from 'react';
import { Dayjs } from 'dayjs';
import { dayjsCustom } from '../../../helpers/dayjsCustom';
import DatePickerDayjs from '../../../components/DatePicker/Datepicker_Dayjs';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../components/DatePicker/DatePicker_formikAntD';
import { entityIsRequired } from '../../../components/message/validationMsg';
import theme from '../../../theme';

type Props = {
  isValid?: boolean;
  onChange: (date: Dayjs | null) => void;
  initialValue?: Dayjs;
  disabledDates?: Dayjs[];
};

export const abrechnungsdefinitionValidFromFormattedDate = 'YYYY-MM-DD';

// NOTE: This component is an exception and will not use FormItemWithoutColon!
//       The reason behind it is: Due to the specific usage of this component in the parent component it is not allowed to wrap this component with the formik used in the parent component.
const AbrDefFormValidFrom = ({ isValid, onChange, initialValue, disabledDates }: Props) => {
  const isDateInUse = (current: Dayjs) =>
    disabledDates ? disabledDates.map((item) => item).some((disabledDate) => disabledDate.isSame(current)) : false;

  return (
    <Form>
      <Form.Item name="validFrom" label={<Typography.Text strong>Gültig ab</Typography.Text>}>
        <DatePickerDayjs disabledDate={isDateInUse} onChange={onChange} defaultValue={initialValue} format={DATE_DISPLAY_FORMAT_DEFAULT} />
        {/* NOTE: isValid has to be compared only with 'false' as the error message should only appear if the user:
        - entered a date and then deleted it from the date picker
        - did not choose any date and wants to save the form
         If isValid is checked by being falsy or not, the error message will appear right after the form is open */}
        {isValid === false ? (
          <Form.ErrorList errors={[<Typography.Text style={{ color: theme.colors.error }}>{entityIsRequired('Gültig ab')}</Typography.Text>]} />
        ) : null}
      </Form.Item>
    </Form>
  );
};

export const formatAbrechnungsdefinitionValidFromValue = (date: Dayjs) =>
  dayjsCustom(date.format()).format(abrechnungsdefinitionValidFromFormattedDate);

export default AbrDefFormValidFrom;
