import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { useAuth } from '../../../shared/Auth/authContext';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../../shared/dataChangedSubscription';
import { DataChangedChangeType } from '../../../types';

const useOnFirmendatenBankDetailsDataChangeEvent = (key: string, callback: () => Promise<unknown>, rechtstraegerId: string) => {
  const {
    user,
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();
  const { mitarbeiter } = useAuth();

  useOnDataChangedEvent([
    {
      filter: {
        userId: user.username,
        type: dataChangedSubscriptionTypes.rechtstraegerBankDetails,
        params: {
          kundenSystemId,
          rechtstraegerId,
        },
        changeType: DataChangedChangeType.Update,
      },
      notificationDescription: `Bankverbindung wurde gerade verändert`,
      key: `${key}-update`,
      callback,
    },

    {
      filter: {
        userId: user.username,
        type: dataChangedSubscriptionTypes.rechtstraegerBankDetails,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        params: {
          kundenSystemId,
          rechtstraegerId,
        },
        changeType: DataChangedChangeType.Delete,
      },
      notificationDescription: `Bankverbindung wurde gerade gelöscht`,
      key: `${key}-delete`,
      callback,
    },
  ]);
};

export { useOnFirmendatenBankDetailsDataChangeEvent };
