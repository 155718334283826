import React, { FC } from 'react';
import HistoryModal, { HistoryType } from '../../History/shared/HistoryModal';
import SepaMandatChangeHistoryListingTable from '../SepaMandat/SepaMandatChangeHistoryListingTable';
import FirmendatenBankDetailsChangeHistoryListingTable from '../FirmendatenBankDetails/FirmendatenBankDetailsChangeHistoryListingTable';

type Props = {
  bankDetailsId: string;
  isBankDetailsChangeHistoryOpen: boolean;
  isSepaMandatChangeHistoryOpen: boolean;
  toggleBankDetailsChangeHistoryOpen: () => void;
  toggleSepaMandatChangeHistoryOpen: () => void;
  firmendatenId: string;
};

const FirmendatenBankDetailsHistoryModals: FC<Props> = ({
  bankDetailsId,
  isBankDetailsChangeHistoryOpen,
  isSepaMandatChangeHistoryOpen,
  toggleBankDetailsChangeHistoryOpen,
  toggleSepaMandatChangeHistoryOpen,
  firmendatenId,
}) => {
  return (
    <>
      <HistoryModal historyType={HistoryType.Change} open={isBankDetailsChangeHistoryOpen} onCancel={toggleBankDetailsChangeHistoryOpen}>
        <FirmendatenBankDetailsChangeHistoryListingTable firmendatenId={firmendatenId} bankDetailsId={bankDetailsId} />
      </HistoryModal>
      <HistoryModal historyType={HistoryType.Change} open={isSepaMandatChangeHistoryOpen} onCancel={toggleSepaMandatChangeHistoryOpen}>
        <SepaMandatChangeHistoryListingTable rechtstraegerId={firmendatenId} bankDetailsId={bankDetailsId} />
      </HistoryModal>
    </>
  );
};

export default FirmendatenBankDetailsHistoryModals;
