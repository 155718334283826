import React, { FC, PropsWithChildren } from 'react';
import createCtx from '../../helpers/createCtx';
import { TDataChangedSubscriptionContext } from './dataChangedSubscriptionContextProps';
import { useOnDataChangedWithCallbacks } from './useOnDataChangedWithCallbacks';

const [useOnDataChangedEventContext, DataChangedEventContextProvider] = createCtx<TDataChangedSubscriptionContext>();

const DataChangedEventProvider: FC<PropsWithChildren> = ({ children }) => {
  const { addConsumer, removeConsumer } = useOnDataChangedWithCallbacks();

  return <DataChangedEventContextProvider value={{ addConsumer, removeConsumer }}>{children}</DataChangedEventContextProvider>;
};

export { DataChangedEventProvider, useOnDataChangedEventContext };
