import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Space, Tag } from 'antd';
import { generatePathToKontoblattPage, KontoblattPageQueryParams } from '../../../features/Kontoblatt/kontoblattUriPaths';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { isNotNull } from '../../../helpers/assertionHelper';

type KontoLinkToKontoblattProps = {
  konto: {
    kontoId: string;
    nummer: string;
    bezeichnung: string;
  };
  maxBezeichnungLength?: number | null;
  withBezeichnung?: boolean;
  width?: string;
  fontWeight?: 'normal' | 'bold';
} & Omit<KontoblattPageQueryParams, 'kontoId'>;

const KontoLinkToKontoblatt: FC<KontoLinkToKontoblattProps> = ({
  konto,
  width,
  maxBezeichnungLength = 16,
  withBezeichnung = true,
  fontWeight = 'normal',
  ...kontoblattQueryParamProps
}) => {
  return (
    <Space>
      <Space style={{ width: width ?? 48, fontWeight }}>
        <Link to={generatePathToKontoblattPage({ ...kontoblattQueryParamProps, kontoId: konto.kontoId })} target="_blank">
          {konto.nummer}
        </Link>
      </Space>
      {withBezeichnung && (
        <DataWithShortenedText
          maxTextLength={isNotNull(maxBezeichnungLength) ? maxBezeichnungLength : konto.bezeichnung.length}
          text={konto.bezeichnung}
        >
          {(shortenedText) => <Tag style={{ fontWeight }}>{shortenedText}</Tag>}
        </DataWithShortenedText>
      )}
    </Space>
  );
};

export default KontoLinkToKontoblatt;
