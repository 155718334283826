import React, { FC } from 'react';
import { Button, Drawer, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import KontoForm from '../Form/KontoForm';
import { useToggle } from '../../../hooks/useToggle';

type Props = {
  showArchived: boolean;
  toggleShowArchived: () => void;
  refetch: () => Promise<any>;
};

const KontenplanActionButtonsAndDrawer: FC<Props> = ({ showArchived, toggleShowArchived, refetch }) => {
  const [isCollapsed, onCollapse] = useToggle();

  return (
    <Space>
      <Button onClick={toggleShowArchived} size="small">
        Archivierte Einträge {showArchived ? 'ausblenden' : 'anzeigen'}
      </Button>
      <Button icon={<PlusOutlined />} type="primary" onClick={onCollapse} size="small">
        Konto anlegen
      </Button>
      <Drawer title="Konto anlegen" width={720} onClose={onCollapse} open={isCollapsed} destroyOnClose>
        <KontoForm
          onSuccess={() => {
            refetch();
            onCollapse();
          }}
          onCancel={onCollapse}
        />
      </Drawer>
    </Space>
  );
};

export default KontenplanActionButtonsAndDrawer;
