import { $getRoot } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React, { useLayoutEffect } from 'react';

type onChangeFunction = (editorStateJson: string, editorText: string) => void;

export const OnChangeDebouncePlugin: React.FC<{
  ignoreInitialChange?: boolean;
  ignoreSelectionChange?: boolean;
  onChange: onChangeFunction;
  wait?: number;
}> = ({ ignoreInitialChange = true, ignoreSelectionChange = false, onChange, wait = 167 }) => {
  const [editor] = useLexicalComposerContext();
  let timerId: NodeJS.Timeout | null = null;

  useLayoutEffect(() => {
    return editor.registerUpdateListener(({ editorState, dirtyElements, dirtyLeaves, prevEditorState }) => {
      if (ignoreSelectionChange && dirtyElements.size === 0 && dirtyLeaves.size === 0) {
        return;
      }

      if (ignoreInitialChange && prevEditorState.isEmpty()) {
        return;
      }
      if (timerId === null) {
        timerId = setTimeout(() => {
          editorState.read(() => {
            const root = $getRoot();
            onChange(JSON.stringify(editorState), root.getTextContent());
          });
        }, wait);
      } else {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
          editorState.read(() => {
            const root = $getRoot();
            onChange(JSON.stringify(editorState), root.getTextContent());
          });
        }, wait);
      }
    });
  }, [editor, ignoreInitialChange, ignoreSelectionChange, onChange]);

  return null;
};
