export default {
  auftrag: 'AUFTRAG',
  bestandseinheit: 'BESTANDSEINHEIT',
  bankStatement: 'BANK_STATEMENT',
  bookingSuggestionFibuBuchungen: 'BOOKING_SUGGESTION_FIBU_BUCHUNGEN',
  camt: 'DATA_CARRIER_CAMT',
  fibuEinstellung: 'FIBU_EINSTELLUNG',
  firmendatenFile: 'FIRMENDATEN_FILE',
  heAbrechnung: 'HE_ABRECHNUNG',
  heAbrechnungHeVertrag: 'HE_ABRECHNUNG_HE_VERTRAG',
  incomingInvoice: 'INCOMING_INVOICE',
  konto: 'KONTO',
  mahnung: 'MAHNUNG',
  mail: 'MAIL',
  offenePosten: 'OFFENE_POSTEN',
  objekt: 'OBJEKT',
  objektAbrechnung: 'OBJEKT_ABRECHNUNG',
  pain: 'DATA_CARRIER_PAIN',
  payment: 'PAYMENT',
  paymentProposal: 'PAYMENT_PROPOSAL',
  receivedBankTransaction: 'RECEIVED_BANK_TRANSACTION',
  rechtstraeger: 'RECHTSTRAEGER',
  rechtstraegerBankDetails: 'RECHTSTRAEGER_BANK_DETAILS',
  rechtstraegerHauptTagAddress: 'RECHTSTRAEGER_HAUPT_TAG_ADDRESS',
  rechtstraegerHauptTagEmail: 'RECHTSTRAEGER_HAUPT_TAG_EMAIL',
  ticket: 'TICKET',
  topAbrechnung: 'TOP_ABRECHNUNGEN',
  ustVomAufwand: 'UST_VOM_AUFWAND',
  vertrag: 'VERTRAG',
  vertragVposIndexedValue: 'VERTRAG_VPOS_INDEXED_VALUE',
  vstKuerzung: 'VORSTEUERKUERZUNG',
  wizard: 'WIZARD',
  zinsliste: 'ZINSLISTE',
};
