import React from 'react';
import { FireOutlined, HomeOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../../../components/ContextSidebar/ContentWithSidebar';
import { generatePathToVerarbeitungVorschreibungErstellenDetailsPage } from '../../../../features/Verarbeitung/verarbeitungApiPaths';
import VerarbeitungVorschreibungErstellen from '../../../../features/Verarbeitung/Vorschreibung/Erstellen/VerarbeitungVorschreibungErstellen';
import { verarbeitungPageAndMenuListTitles } from '../../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungErrorAndWarningListing from '../../../../features/Verarbeitung/shared/ErrorAndWarningListing/VerarbeitungErrorAndWarningListing';
import { AuftragGenerierlauf } from '../../../../types';

const menuListVorschreibungErstellen = (generierlauf: AuftragGenerierlauf): GroupedMenueListInterface => {
  return {
    topMenuList: [
      {
        title: verarbeitungPageAndMenuListTitles.Auftragserstellung,
        icon: <HomeOutlined />,
        uri: `${generatePathToVerarbeitungVorschreibungErstellenDetailsPage(generierlauf.generierlaufId)}/entries`,
        content: () => <VerarbeitungVorschreibungErstellen generierlauf={generierlauf} />,
      },
      {
        state: generierlauf.errorList.length > 0 || generierlauf.warningList.length > 0 ? 'error' : undefined,
        title: verarbeitungPageAndMenuListTitles.ErrorsAndWarnings,
        icon: <FireOutlined />,
        uri: `${generatePathToVerarbeitungVorschreibungErstellenDetailsPage(generierlauf.generierlaufId)}/errors-and-warnings`,
        content: () => <VerarbeitungErrorAndWarningListing errorList={generierlauf.errorList} warningList={generierlauf.warningList} />,
      },
    ],
  };
};

export default menuListVorschreibungErstellen;
