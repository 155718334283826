import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AuftragsartBaseFieldsFragmentDoc } from '../../Auftragsart/gql/AuftragsartFragments.types';
import { OrderRechtstraegerFragmentDoc, RechtstraegerFieldsFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import { OrderBankDetailsFieldsFragmentDoc, BankDetailsFieldsFragmentDoc } from '../../BankDetails/gql/BankDetailsFragments.types';
import { OrderAddressFragmentDoc } from '../../Address/gql/AddressFragments.types';
import { OrderObjektFragmentDoc, ObjektFragmentDoc } from '../../Objekt/gql/ObjektFragments.types';
import { OrderContactFieldsFragmentDoc } from '../../Contact/gql/ContactFragments.types';
import { BestandseinheitFieldsFragmentDoc, BestandseinheitBaseFieldsFragmentDoc } from '../../Bestandseinheit/gql/BestandseinheitFragments.types';
import { VorschreibungMailDeliveryFieldsFragmentDoc } from '../../EmailDelivery/VorschreibungMailDelivery/gql/VorschreibungMailDeliveryFragments.types';
import { EigenbelegFragmentDoc } from '../../../pages/BookingDetailsPage/BookingDetailsEigenbelegPage/gql/EigenbelegFragments.types';
export type AuftragListEntryFragment = {
  auftragId: string;
  ausstellerRechtstraegerId: string;
  belegFileId?: string | null;
  belegId?: string | null;
  belegnummer?: string | null;
  bestandseinheitWithObjektBezeichnungList: Array<string>;
  buchungskreisId?: string | null;
  buchungIdList?: Array<string> | null;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  dueDate?: string | null;
  empfaengerRechtstraegerId: string;
  generierlaufId?: string | null;
  offenerBetrag?: number | null;
  paymentCreatable: boolean;
  rechnungsDatum?: string | null;
  sepaCreditorId?: string | null;
  sepaMandatReference?: string | null;
  storniert: boolean;
  sumBrutto: number;
  sumNetto: number;
  sumUst: number;
  auftragsart: {
    auftragsartId: string;
    bezeichnung: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    firmendatenId?: string | null;
    showUstBlock: boolean;
    updatedByMitarbeiterId?: string | null;
    status: { text: string; value: Types.AuftragsartStatus };
    type: { text: string; value: Types.AuftragsartType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  aussteller: { kurzBezeichnung: string; kundennummer: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
  ausstellerBankDetails: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string };
  buchungsanweisungStatus?: { text: string; value: Types.OrderBuchungsanweisungStatus } | null;
  empfaenger: { kurzBezeichnung: string; kundennummer: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
  empfaengerBankDetails?: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string } | null;
  fakturierungsperiode: { jahr: number; monat: number };
  objektBestandseinheitIdDataList: Array<{ bestandseinheitId: string; objektId: string }>;
  paymentMethod: { text: string; value: Types.PaymentMethod };
  status: { text: string; value: Types.AuftragStatus; description?: string | null };
  vertragsart: { text: string; value: Types.Vertragsart };
};

export type AuftragFragment = {
  auftragId: string;
  buchungIdList?: Array<string> | null;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  dueDate: string;
  generierDatum?: string | null;
  generierlaufId?: string | null;
  hasAuftragskette: boolean;
  stornierbar?: boolean | null;
  storniert: boolean;
  sumBrutto: number;
  sumNetto: number;
  sumUst: number;
  auftragsKopf: {
    consolidationCriteria?: string | null;
    rechnungsAusstellerBezeichnung: string;
    sepaCreditorId?: string | null;
    sepaMandatReference?: string | null;
    auftragsartType: { text: string; value: Types.AuftragsartType };
    aussteller: { kurzBezeichnung: string; kundennummer: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
    ausstellerBankDetails: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string };
    belegform: { text: string; value: Types.BelegFormArt };
    deliveryAddress: {
      addressId: string;
      city: string;
      cityAdditionalInformation?: string | null;
      countryCodeIso2: string;
      houseEntranceApartmentNumber?: string | null;
      postofficeBoxNumber?: string | null;
      postofficeBoxZipCode?: string | null;
      street?: string | null;
      type: Types.AddressType;
      zipCode?: string | null;
    };
    dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
    empfaenger: { kurzBezeichnung: string; kundennummer: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
    empfaengerBankDetails?: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string } | null;
    fakturierungsperiode: { jahr: number; monat: number };
    objekt: { kurzBezeichnung: string; objektId: string };
    paymentMethod: { text: string; value: Types.PaymentMethod };
    recipientEmailContact: { contactId: string; type: string; wert: string };
    verrechnungsart: { text: string; value: Types.Verrechnungsart };
    vertragsart: { text: string; value: Types.Vertragsart };
    zustellRechtstraeger: {
      kurzBezeichnung: string;
      kundennummer: string;
      rechtstraegerId: string;
      type: { text: string; value: Types.RechtstraegerType };
    };
  };
  auftragsPositionList: Array<{
    auftragId: string;
    auftragsPositionId: string;
    bestandseinheitId: string;
    bezeichnung: string;
    brutto: number;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    generierDatum?: string | null;
    generierlaufId?: string | null;
    netto: number;
    objektId: string;
    originId: string;
    performancePeriodFromInclusive: string;
    performancePeriodToInclusive: string;
    rechnungskennzeichen: string;
    steuersatz: number;
    ust: number;
    ustKategorieEntryId: string;
    ustKategorieId: string;
    ustKategorieVersionId: string;
    vertragBezeichnung: string;
    vertragId: string;
    vorschreibungspositionBezeichnung: string;
    vorschreibungspositionId: string;
    bestandseinheit: {
      aktivSeit?: string | null;
      bauteil?: string | null;
      bestandseinheitId: string;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      geschoss?: string | null;
      inaktivSeit?: string | null;
      nutzflaeche?: number | null;
      nutzwert?: number | null;
      stiege?: string | null;
      tagList: Array<string>;
      address: {
        addressId: string;
        city: string;
        cityAdditionalInformation?: string | null;
        countryCodeIso2: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        houseEntranceApartmentNumber?: string | null;
        postofficeBoxNumber?: string | null;
        postofficeBoxZipCode?: string | null;
        street?: string | null;
        tagList: Array<string>;
        type: Types.AddressType;
        zipCode?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      nutzungsArt?: { text: string; value: Types.NutzungsArt } | null;
      optionSteuerpflicht?: {
        beOptionSteuerpflichtId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        optionSteuerpflicht: boolean;
        validFrom: string;
        historicizedList?: Array<{
          beOptionSteuerpflichtId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          optionSteuerpflicht: boolean;
          validFrom: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }> | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      objektVerwaltungsart: { text: string; value: Types.Verwaltungsart };
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    fibuStatus?: { text: string; value: Types.AuftragFibuStatus; description?: string | null } | null;
    labelList: Array<{ label: string; value: string }>;
    objekt: {
      countryCodeIso2: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      fotoFileId?: string | null;
      fotoType?: Types.FotoType | null;
      kurzBezeichnung: string;
      objektId: string;
      rechnungsAusstellerBezeichnung?: string | null;
      rechnungsAusstellerChangeable?: boolean | null;
      subAdministration: boolean;
      sumNutzflaeche: number;
      sumNutzwert: number;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      verwaltungBis?: string | null;
      verwaltungSeit: string;
      verwaltungsartChangeable?: boolean | null;
      addressList: Array<{
        addressId: string;
        city: string;
        cityAdditionalInformation?: string | null;
        countryCodeIso2: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        houseEntranceApartmentNumber?: string | null;
        postofficeBoxNumber?: string | null;
        postofficeBoxZipCode?: string | null;
        street?: string | null;
        tagList: Array<string>;
        type: Types.AddressType;
        zipCode?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      objektSummeAnteile?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        kommentar?: string | null;
        lastUpdateTs: string;
        objektSummeAnteileId: string;
        summeAnteile: number;
        validFrom: string;
        historicizedList?: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          kommentar?: string | null;
          lastUpdateTs: string;
          objektSummeAnteileId: string;
          summeAnteile: number;
          validFrom: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }> | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechnungsAussteller: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fibuKontoCreatable: boolean;
        kundennummer: string;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        bankDetailsList: Array<{
          accountHolder: string;
          accountLimit?: number | null;
          assignedHeVertragZahlungsregelMap: any;
          assignedObjektMap: any;
          bankDetailsId: string;
          bankname: string;
          bic: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          ebicsCreditTransferUserList: Array<string>;
          ebicsDirectDebitUserList: Array<string>;
          fibuKontoId?: string | null;
          fibuKontonummer?: string | null;
          iban: string;
          updatedByMitarbeiterId?: string | null;
          fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
          fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
          sepaMandat?: {
            fileId?: string | null;
            fileName?: string | null;
            sepaMandatId: string;
            sepaMandatReference: string;
            signatureDate?: string | null;
            firmendaten?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firmendatenId: string;
              kundenSystemId: string;
              kurzBezeichnung: string;
              name1: string;
              name2?: string | null;
              name3?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechnungsAussteller?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
            warningList: Array<{ message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        personenbezugList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          personenbezugId: string;
          tagList: Array<string>;
          person: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechtstraegerSteuerpflicht?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          historicizedList?: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ message: string; type: string }>;
          }> | null;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
        sprache: { text: string; value: Types.Sprache };
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { value: Types.RechtstraegerType; text: string };
        vertragSummary?: {
          heVertragCount: number;
          heVertragVerwaltungCount: number;
          mietVertragCount: number;
          mietVertragVerwaltungCount: number;
          weSubverwaltungCount: number;
          weVertragCount: number;
          weVertragVerwaltungCount: number;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      sachbearbeiter?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        fileStorageInitialized: boolean;
        firmendatenId: string;
        firstname: string;
        funktionsModulList: Array<string>;
        lastname: string;
        mitarbeiterId: string;
        title?: string | null;
        titleTrailing?: string | null;
        username: string;
        contactPerson?: {
          contactPersonId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firstname: string;
          lastname: string;
          tagList: Array<string>;
          title?: string | null;
          titleTrailing?: string | null;
          contactList: Array<{
            contactId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            haupt?: boolean | null;
            tagList: Array<string>;
            type: string;
            wert: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          rechtstraeger?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rolleList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          name: string;
          rolleId: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
        status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
        user?: {
          email?: string | null;
          emailUnverified?: string | null;
          firstname?: string | null;
          lastname?: string | null;
          role: string;
          username: string;
          status: { text: string; value: string };
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { text: string; value: Types.ObjektStatus; description?: string | null };
      verwaltungsart: { text: string; value: Types.Verwaltungsart };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
  }>;
  auftragsart: {
    auftragsartId: string;
    bezeichnung: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    firmendatenId?: string | null;
    showUstBlock: boolean;
    updatedByMitarbeiterId?: string | null;
    status: { text: string; value: Types.AuftragsartStatus };
    type: { text: string; value: Types.AuftragsartType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  billingAddress: {
    addressId: string;
    city: string;
    cityAdditionalInformation?: string | null;
    countryCodeIso2: string;
    houseEntranceApartmentNumber?: string | null;
    postofficeBoxNumber?: string | null;
    postofficeBoxZipCode?: string | null;
    street?: string | null;
    type: Types.AddressType;
    zipCode?: string | null;
  };
  buchungErrorList: Array<{ message: string; type: string }>;
  copyEmailRecipientList?: Array<{
    contact: { contactId: string; type: string; wert: string };
    rechtstraeger: { kurzBezeichnung: string; kundennummer: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
  }> | null;
  copyMailDeliveryList: Array<{
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    emailAddress: string;
    emailDeliveryDefinitionBezeichnung: string;
    emailDeliveryDefinitionId: string;
    mailId?: string | null;
    original: boolean;
    sender?: string | null;
    sentTs?: string | null;
    shouldNeverSend: boolean;
    shouldSend: boolean;
    updatedByMitarbeiterId?: string | null;
    vorschreibungMailDeliveryId: string;
    resentDeliveryList?: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      emailAddress: string;
      emailDeliveryDefinitionBezeichnung: string;
      emailDeliveryDefinitionId: string;
      mailId?: string | null;
      original: boolean;
      sender?: string | null;
      sentTs?: string | null;
      shouldNeverSend: boolean;
      shouldSend: boolean;
      updatedByMitarbeiterId?: string | null;
      vorschreibungMailDeliveryId: string;
      emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
      fakturierungsperiode: { jahr: number; monat: number };
      objektBestandseinheitVertragList: Array<{
        vertragId: string;
        bestandseinheit: {
          bestandseinheitId: string;
          bezeichnung: string;
          address: {
            addressId: string;
            city: string;
            cityAdditionalInformation?: string | null;
            countryCodeIso2: string;
            houseEntranceApartmentNumber?: string | null;
            postofficeBoxNumber?: string | null;
            postofficeBoxZipCode?: string | null;
            street?: string | null;
            type: Types.AddressType;
            zipCode?: string | null;
          };
        };
        objekt: { kurzBezeichnung: string; objektId: string };
      }>;
      recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
      vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }> | null;
    sourceDelivery?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      emailAddress: string;
      emailDeliveryDefinitionBezeichnung: string;
      emailDeliveryDefinitionId: string;
      mailId?: string | null;
      original: boolean;
      sender?: string | null;
      sentTs?: string | null;
      shouldNeverSend: boolean;
      shouldSend: boolean;
      updatedByMitarbeiterId?: string | null;
      vorschreibungMailDeliveryId: string;
      emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
      fakturierungsperiode: { jahr: number; monat: number };
      objektBestandseinheitVertragList: Array<{
        vertragId: string;
        bestandseinheit: {
          bestandseinheitId: string;
          bezeichnung: string;
          address: {
            addressId: string;
            city: string;
            cityAdditionalInformation?: string | null;
            countryCodeIso2: string;
            houseEntranceApartmentNumber?: string | null;
            postofficeBoxNumber?: string | null;
            postofficeBoxZipCode?: string | null;
            street?: string | null;
            type: Types.AddressType;
            zipCode?: string | null;
          };
        };
        objekt: { kurzBezeichnung: string; objektId: string };
      }>;
      recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
      vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
    fakturierungsperiode: { jahr: number; monat: number };
    objektBestandseinheitVertragList: Array<{
      vertragId: string;
      bestandseinheit: {
        bestandseinheitId: string;
        bezeichnung: string;
        address: {
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          type: Types.AddressType;
          zipCode?: string | null;
        };
      };
      objekt: { kurzBezeichnung: string; objektId: string };
    }>;
    recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
    vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }>;
  eigenbeleg?: {
    auftragIdList: Array<string>;
    ausstellerRechtstraegerId: string;
    belegId: string;
    belegdatum: string;
    belegnummer: string;
    bezeichnung: string;
    buchungIdList: Array<string>;
    buchungsanweisungIdList: Array<string>;
    buchungskreisId?: string | null;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    empfaengerRechtstraegerId: string;
    mailId?: string | null;
    paid: boolean;
    sumBrutto?: number | null;
    sumNetto?: number | null;
    sumUst?: number | null;
    auftragStatusDetailsList: Array<{
      auftragId: string;
      offenerBetrag?: number | null;
      paymentCreatable: boolean;
      paymentIdList: Array<string>;
      buchungsanweisungStatus?: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus } | null;
      eigenbelegStatus: { description?: string | null; text: string; value: Types.EigenbelegStatus };
      fakturierungsperiode: { jahr: number; monat: number };
    }>;
    ausstellerBankDetails: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string };
    ausstellerRechtstraeger: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      fibuKontoCreatable: boolean;
      kundennummer: string;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      bankDetailsList: Array<{
        accountHolder: string;
        accountLimit?: number | null;
        assignedHeVertragZahlungsregelMap: any;
        assignedObjektMap: any;
        bankDetailsId: string;
        bankname: string;
        bic: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        ebicsCreditTransferUserList: Array<string>;
        ebicsDirectDebitUserList: Array<string>;
        fibuKontoId?: string | null;
        fibuKontonummer?: string | null;
        iban: string;
        updatedByMitarbeiterId?: string | null;
        fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
        fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
        sepaMandat?: {
          fileId?: string | null;
          fileName?: string | null;
          sepaMandatId: string;
          sepaMandatReference: string;
          signatureDate?: string | null;
          firmendaten?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firmendatenId: string;
            kundenSystemId: string;
            kurzBezeichnung: string;
            name1: string;
            name2?: string | null;
            name3?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechnungsAussteller?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
          warningList: Array<{ message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      personenbezugList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        personenbezugId: string;
        tagList: Array<string>;
        person: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechtstraegerSteuerpflicht?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        rechtstraegerSteuerpflichtId: string;
        validFrom: string;
        historicizedList?: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ message: string; type: string }>;
        }> | null;
        steuerpflicht: { text: string; value: Types.Steuerpflicht };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
      sprache: { text: string; value: Types.Sprache };
      status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
      type: { value: Types.RechtstraegerType; text: string };
      vertragSummary?: {
        heVertragCount: number;
        heVertragVerwaltungCount: number;
        mietVertragCount: number;
        mietVertragVerwaltungCount: number;
        weSubverwaltungCount: number;
        weVertragCount: number;
        weVertragVerwaltungCount: number;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    belegform: { text: string; value: Types.BelegFormArt };
    buchungErrorList: Array<{ message: string; type: string }>;
    buchungsanweisungListEntryDataList: Array<{
      buchungsanweisungId: string;
      status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
      fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
    }>;
    buchungsanweisungStatus?: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus } | null;
    deviationDetails?: {
      umbuchungInfoList: Array<{
        fakturierungsperiode: { jahr: number; monat: number };
        kontoKeyIst: { nummer: string; klasse: { text: string; value: Types.KontoKlasse } };
        kontoKeySoll: { nummer: string; klasse: { text: string; value: Types.KontoKlasse } };
      }>;
    } | null;
    dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
    eigenbelegPositionList: Array<{
      bestandseinheitId: string;
      bezeichnung: string;
      brutto: number;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      eigenbelegsPositionId: string;
      generierDatum?: string | null;
      generierlaufId?: string | null;
      netto: number;
      objektId: string;
      performancePeriodFromInclusive: string;
      performancePeriodToInclusive: string;
      steuersatz: number;
      ust: number;
      ustKategorieEntryId: string;
      ustKategorieId: string;
      vertragId: string;
      vorschreibungspositionBezeichnung: string;
      vorschreibungspositionId: string;
      bestandseinheit?: {
        aktivSeit?: string | null;
        bauteil?: string | null;
        bestandseinheitId: string;
        bezeichnung: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        geschoss?: string | null;
        inaktivSeit?: string | null;
        nutzflaeche?: number | null;
        nutzwert?: number | null;
        stiege?: string | null;
        tagList: Array<string>;
        address: {
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        nutzungsArt?: { text: string; value: Types.NutzungsArt } | null;
        optionSteuerpflicht?: {
          beOptionSteuerpflichtId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          optionSteuerpflicht: boolean;
          validFrom: string;
          historicizedList?: Array<{
            beOptionSteuerpflichtId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            optionSteuerpflicht: boolean;
            validFrom: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }> | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        objektVerwaltungsart: { text: string; value: Types.Verwaltungsart };
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      labelList: Array<{ label: string; value: string }>;
      objekt?: {
        countryCodeIso2: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fotoFileId?: string | null;
        fotoType?: Types.FotoType | null;
        kurzBezeichnung: string;
        objektId: string;
        rechnungsAusstellerBezeichnung?: string | null;
        rechnungsAusstellerChangeable?: boolean | null;
        subAdministration: boolean;
        sumNutzflaeche: number;
        sumNutzwert: number;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        verwaltungBis?: string | null;
        verwaltungSeit: string;
        verwaltungsartChangeable?: boolean | null;
        addressList: Array<{
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        objektSummeAnteile?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          kommentar?: string | null;
          lastUpdateTs: string;
          objektSummeAnteileId: string;
          summeAnteile: number;
          validFrom: string;
          historicizedList?: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            kommentar?: string | null;
            lastUpdateTs: string;
            objektSummeAnteileId: string;
            summeAnteile: number;
            validFrom: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }> | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechnungsAussteller: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fibuKontoCreatable: boolean;
          kundennummer: string;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          bankDetailsList: Array<{
            accountHolder: string;
            accountLimit?: number | null;
            assignedHeVertragZahlungsregelMap: any;
            assignedObjektMap: any;
            bankDetailsId: string;
            bankname: string;
            bic: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            ebicsCreditTransferUserList: Array<string>;
            ebicsDirectDebitUserList: Array<string>;
            fibuKontoId?: string | null;
            fibuKontonummer?: string | null;
            iban: string;
            updatedByMitarbeiterId?: string | null;
            fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
            fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
            sepaMandat?: {
              fileId?: string | null;
              fileName?: string | null;
              sepaMandatId: string;
              sepaMandatReference: string;
              signatureDate?: string | null;
              firmendaten?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                firmendatenId: string;
                kundenSystemId: string;
                kurzBezeichnung: string;
                name1: string;
                name2?: string | null;
                name3?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rechnungsAussteller?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
              warningList: Array<{ message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          personenbezugList: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            personenbezugId: string;
            tagList: Array<string>;
            person: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechtstraegerSteuerpflicht?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            historicizedList?: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              rechtstraegerSteuerpflichtId: string;
              validFrom: string;
              steuerpflicht: { text: string; value: Types.Steuerpflicht };
              warningList: Array<{ message: string; type: string }>;
            }> | null;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
          sprache: { text: string; value: Types.Sprache };
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { value: Types.RechtstraegerType; text: string };
          vertragSummary?: {
            heVertragCount: number;
            heVertragVerwaltungCount: number;
            mietVertragCount: number;
            mietVertragVerwaltungCount: number;
            weSubverwaltungCount: number;
            weVertragCount: number;
            weVertragVerwaltungCount: number;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        sachbearbeiter?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          fileStorageInitialized: boolean;
          firmendatenId: string;
          firstname: string;
          funktionsModulList: Array<string>;
          lastname: string;
          mitarbeiterId: string;
          title?: string | null;
          titleTrailing?: string | null;
          username: string;
          contactPerson?: {
            contactPersonId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firstname: string;
            lastname: string;
            tagList: Array<string>;
            title?: string | null;
            titleTrailing?: string | null;
            contactList: Array<{
              contactId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              haupt?: boolean | null;
              tagList: Array<string>;
              type: string;
              wert: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            rechtstraeger?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rolleList: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            name: string;
            rolleId: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
          status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
          user?: {
            email?: string | null;
            emailUnverified?: string | null;
            firstname?: string | null;
            lastname?: string | null;
            role: string;
            username: string;
            status: { text: string; value: string };
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.ObjektStatus; description?: string | null };
        verwaltungsart: { text: string; value: Types.Verwaltungsart };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
    }>;
    empfaengerBankDetails?: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string } | null;
    empfaengerRechtstraeger: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      fibuKontoCreatable: boolean;
      kundennummer: string;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      bankDetailsList: Array<{
        accountHolder: string;
        accountLimit?: number | null;
        assignedHeVertragZahlungsregelMap: any;
        assignedObjektMap: any;
        bankDetailsId: string;
        bankname: string;
        bic: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        ebicsCreditTransferUserList: Array<string>;
        ebicsDirectDebitUserList: Array<string>;
        fibuKontoId?: string | null;
        fibuKontonummer?: string | null;
        iban: string;
        updatedByMitarbeiterId?: string | null;
        fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
        fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
        sepaMandat?: {
          fileId?: string | null;
          fileName?: string | null;
          sepaMandatId: string;
          sepaMandatReference: string;
          signatureDate?: string | null;
          firmendaten?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firmendatenId: string;
            kundenSystemId: string;
            kurzBezeichnung: string;
            name1: string;
            name2?: string | null;
            name3?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechnungsAussteller?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
          warningList: Array<{ message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      personenbezugList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        personenbezugId: string;
        tagList: Array<string>;
        person: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechtstraegerSteuerpflicht?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        rechtstraegerSteuerpflichtId: string;
        validFrom: string;
        historicizedList?: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ message: string; type: string }>;
        }> | null;
        steuerpflicht: { text: string; value: Types.Steuerpflicht };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
      sprache: { text: string; value: Types.Sprache };
      status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
      type: { value: Types.RechtstraegerType; text: string };
      vertragSummary?: {
        heVertragCount: number;
        heVertragVerwaltungCount: number;
        mietVertragCount: number;
        mietVertragVerwaltungCount: number;
        weSubverwaltungCount: number;
        weVertragCount: number;
        weVertragVerwaltungCount: number;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    fakturierungsperiode: { jahr: number; monat: number };
    fileInfo: { fileId: string; name: string; size: number };
    paymentMethod: { text: string; value: Types.PaymentMethod };
    status: { description?: string | null; text: string; value: Types.EigenbelegStatus };
    type: { text: string; value: Types.EigenbelegType };
    verrechnungsart: { text: string; value: Types.Verrechnungsart };
  } | null;
  empfaengerBankDetailsList: Array<{
    accountHolder: string;
    accountLimit?: number | null;
    assignedHeVertragZahlungsregelMap: any;
    assignedObjektMap: any;
    bankDetailsId: string;
    bankname: string;
    bic: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    ebicsCreditTransferUserList: Array<string>;
    ebicsDirectDebitUserList: Array<string>;
    fibuKontoId?: string | null;
    fibuKontonummer?: string | null;
    iban: string;
    updatedByMitarbeiterId?: string | null;
    fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
    fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
    sepaMandat?: {
      fileId?: string | null;
      fileName?: string | null;
      sepaMandatId: string;
      sepaMandatReference: string;
      signatureDate?: string | null;
      firmendaten?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firmendatenId: string;
        kundenSystemId: string;
        kurzBezeichnung: string;
        name1: string;
        name2?: string | null;
        name3?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechnungsAussteller?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
      warningList: Array<{ message: string; type: string }>;
    } | null;
    status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null>;
  fibuStatus?: { description?: string | null; text: string; value: Types.AuftragFibuStatus } | null;
  objektBestandseinheitIdDataList: Array<{ bestandseinheitId: string; objektId: string }>;
  originalMailDelivery?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    emailAddress: string;
    emailDeliveryDefinitionBezeichnung: string;
    emailDeliveryDefinitionId: string;
    mailId?: string | null;
    original: boolean;
    sender?: string | null;
    sentTs?: string | null;
    shouldNeverSend: boolean;
    shouldSend: boolean;
    updatedByMitarbeiterId?: string | null;
    vorschreibungMailDeliveryId: string;
    resentDeliveryList?: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      emailAddress: string;
      emailDeliveryDefinitionBezeichnung: string;
      emailDeliveryDefinitionId: string;
      mailId?: string | null;
      original: boolean;
      sender?: string | null;
      sentTs?: string | null;
      shouldNeverSend: boolean;
      shouldSend: boolean;
      updatedByMitarbeiterId?: string | null;
      vorschreibungMailDeliveryId: string;
      emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
      fakturierungsperiode: { jahr: number; monat: number };
      objektBestandseinheitVertragList: Array<{
        vertragId: string;
        bestandseinheit: {
          bestandseinheitId: string;
          bezeichnung: string;
          address: {
            addressId: string;
            city: string;
            cityAdditionalInformation?: string | null;
            countryCodeIso2: string;
            houseEntranceApartmentNumber?: string | null;
            postofficeBoxNumber?: string | null;
            postofficeBoxZipCode?: string | null;
            street?: string | null;
            type: Types.AddressType;
            zipCode?: string | null;
          };
        };
        objekt: { kurzBezeichnung: string; objektId: string };
      }>;
      recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
      vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }> | null;
    sourceDelivery?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      emailAddress: string;
      emailDeliveryDefinitionBezeichnung: string;
      emailDeliveryDefinitionId: string;
      mailId?: string | null;
      original: boolean;
      sender?: string | null;
      sentTs?: string | null;
      shouldNeverSend: boolean;
      shouldSend: boolean;
      updatedByMitarbeiterId?: string | null;
      vorschreibungMailDeliveryId: string;
      emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
      fakturierungsperiode: { jahr: number; monat: number };
      objektBestandseinheitVertragList: Array<{
        vertragId: string;
        bestandseinheit: {
          bestandseinheitId: string;
          bezeichnung: string;
          address: {
            addressId: string;
            city: string;
            cityAdditionalInformation?: string | null;
            countryCodeIso2: string;
            houseEntranceApartmentNumber?: string | null;
            postofficeBoxNumber?: string | null;
            postofficeBoxZipCode?: string | null;
            street?: string | null;
            type: Types.AddressType;
            zipCode?: string | null;
          };
        };
        objekt: { kurzBezeichnung: string; objektId: string };
      }>;
      recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
      vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
    fakturierungsperiode: { jahr: number; monat: number };
    objektBestandseinheitVertragList: Array<{
      vertragId: string;
      bestandseinheit: {
        bestandseinheitId: string;
        bezeichnung: string;
        address: {
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          type: Types.AddressType;
          zipCode?: string | null;
        };
      };
      objekt: { kurzBezeichnung: string; objektId: string };
    }>;
    recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
    vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  status: { description?: string | null; text: string; value: Types.AuftragStatus };
};

export type AppAuftragFieldsFragment = {
  auftragIdList: Array<string>;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  sumBrutto?: number | null;
  sumNetto?: number | null;
  sumUst?: number | null;
  vertragId: string;
  auftragsKopf: {
    auftragsartType: { text: string; value: Types.AuftragsartType };
    aussteller: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      fibuKontoCreatable: boolean;
      kundennummer: string;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      bankDetailsList: Array<{
        accountHolder: string;
        accountLimit?: number | null;
        assignedHeVertragZahlungsregelMap: any;
        assignedObjektMap: any;
        bankDetailsId: string;
        bankname: string;
        bic: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        ebicsCreditTransferUserList: Array<string>;
        ebicsDirectDebitUserList: Array<string>;
        fibuKontoId?: string | null;
        fibuKontonummer?: string | null;
        iban: string;
        updatedByMitarbeiterId?: string | null;
        fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
        fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
        sepaMandat?: {
          fileId?: string | null;
          fileName?: string | null;
          sepaMandatId: string;
          sepaMandatReference: string;
          signatureDate?: string | null;
          firmendaten?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firmendatenId: string;
            kundenSystemId: string;
            kurzBezeichnung: string;
            name1: string;
            name2?: string | null;
            name3?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechnungsAussteller?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
          warningList: Array<{ message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      personenbezugList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        personenbezugId: string;
        tagList: Array<string>;
        person: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechtstraegerSteuerpflicht?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        rechtstraegerSteuerpflichtId: string;
        validFrom: string;
        historicizedList?: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ message: string; type: string }>;
        }> | null;
        steuerpflicht: { text: string; value: Types.Steuerpflicht };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
      sprache: { text: string; value: Types.Sprache };
      status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
      type: { value: Types.RechtstraegerType; text: string };
      vertragSummary?: {
        heVertragCount: number;
        heVertragVerwaltungCount: number;
        mietVertragCount: number;
        mietVertragVerwaltungCount: number;
        weSubverwaltungCount: number;
        weVertragCount: number;
        weVertragVerwaltungCount: number;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    empfaenger: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      fibuKontoCreatable: boolean;
      kundennummer: string;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      bankDetailsList: Array<{
        accountHolder: string;
        accountLimit?: number | null;
        assignedHeVertragZahlungsregelMap: any;
        assignedObjektMap: any;
        bankDetailsId: string;
        bankname: string;
        bic: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        ebicsCreditTransferUserList: Array<string>;
        ebicsDirectDebitUserList: Array<string>;
        fibuKontoId?: string | null;
        fibuKontonummer?: string | null;
        iban: string;
        updatedByMitarbeiterId?: string | null;
        fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
        fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
        sepaMandat?: {
          fileId?: string | null;
          fileName?: string | null;
          sepaMandatId: string;
          sepaMandatReference: string;
          signatureDate?: string | null;
          firmendaten?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firmendatenId: string;
            kundenSystemId: string;
            kurzBezeichnung: string;
            name1: string;
            name2?: string | null;
            name3?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechnungsAussteller?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
          warningList: Array<{ message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      personenbezugList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        personenbezugId: string;
        tagList: Array<string>;
        person: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechtstraegerSteuerpflicht?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        rechtstraegerSteuerpflichtId: string;
        validFrom: string;
        historicizedList?: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ message: string; type: string }>;
        }> | null;
        steuerpflicht: { text: string; value: Types.Steuerpflicht };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
      sprache: { text: string; value: Types.Sprache };
      status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
      type: { value: Types.RechtstraegerType; text: string };
      vertragSummary?: {
        heVertragCount: number;
        heVertragVerwaltungCount: number;
        mietVertragCount: number;
        mietVertragVerwaltungCount: number;
        weSubverwaltungCount: number;
        weVertragCount: number;
        weVertragVerwaltungCount: number;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    fakturierungsperiode: { jahr: number; monat: number };
  };
  bestandseinheit: {
    aktivSeit?: string | null;
    bauteil?: string | null;
    bestandseinheitId: string;
    bezeichnung: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    geschoss?: string | null;
    inaktivSeit?: string | null;
    nutzflaeche?: number | null;
    nutzwert?: number | null;
    stiege?: string | null;
    tagList: Array<string>;
    address: {
      addressId: string;
      city: string;
      cityAdditionalInformation?: string | null;
      countryCodeIso2: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      haupt?: boolean | null;
      houseEntranceApartmentNumber?: string | null;
      postofficeBoxNumber?: string | null;
      postofficeBoxZipCode?: string | null;
      street?: string | null;
      tagList: Array<string>;
      type: Types.AddressType;
      zipCode?: string | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    nutzungsArt?: { text: string; value: Types.NutzungsArt } | null;
    optionSteuerpflicht?: {
      beOptionSteuerpflichtId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      kommentar?: string | null;
      lastUpdateTs: string;
      optionSteuerpflicht: boolean;
      validFrom: string;
      historicizedList?: Array<{
        beOptionSteuerpflichtId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        optionSteuerpflicht: boolean;
        validFrom: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    objektVerwaltungsart: { text: string; value: Types.Verwaltungsart };
    postIt?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      immerAnzeigen: boolean;
      postItId: string;
      text: string;
      titel: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  objekt: {
    countryCodeIso2: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    fotoFileId?: string | null;
    fotoType?: Types.FotoType | null;
    kurzBezeichnung: string;
    objektId: string;
    rechnungsAusstellerBezeichnung?: string | null;
    rechnungsAusstellerChangeable?: boolean | null;
    subAdministration: boolean;
    sumNutzflaeche: number;
    sumNutzwert: number;
    tagList: Array<string>;
    updatedByMitarbeiterId?: string | null;
    verwaltungBis?: string | null;
    verwaltungSeit: string;
    verwaltungsartChangeable?: boolean | null;
    addressList: Array<{
      addressId: string;
      city: string;
      cityAdditionalInformation?: string | null;
      countryCodeIso2: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      haupt?: boolean | null;
      houseEntranceApartmentNumber?: string | null;
      postofficeBoxNumber?: string | null;
      postofficeBoxZipCode?: string | null;
      street?: string | null;
      tagList: Array<string>;
      type: Types.AddressType;
      zipCode?: string | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    objektSummeAnteile?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      kommentar?: string | null;
      lastUpdateTs: string;
      objektSummeAnteileId: string;
      summeAnteile: number;
      validFrom: string;
      historicizedList?: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        kommentar?: string | null;
        lastUpdateTs: string;
        objektSummeAnteileId: string;
        summeAnteile: number;
        validFrom: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    postIt?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      immerAnzeigen: boolean;
      postItId: string;
      text: string;
      titel: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    rechnungsAussteller: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      fibuKontoCreatable: boolean;
      kundennummer: string;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      bankDetailsList: Array<{
        accountHolder: string;
        accountLimit?: number | null;
        assignedHeVertragZahlungsregelMap: any;
        assignedObjektMap: any;
        bankDetailsId: string;
        bankname: string;
        bic: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        ebicsCreditTransferUserList: Array<string>;
        ebicsDirectDebitUserList: Array<string>;
        fibuKontoId?: string | null;
        fibuKontonummer?: string | null;
        iban: string;
        updatedByMitarbeiterId?: string | null;
        fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
        fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
        sepaMandat?: {
          fileId?: string | null;
          fileName?: string | null;
          sepaMandatId: string;
          sepaMandatReference: string;
          signatureDate?: string | null;
          firmendaten?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firmendatenId: string;
            kundenSystemId: string;
            kurzBezeichnung: string;
            name1: string;
            name2?: string | null;
            name3?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechnungsAussteller?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
          warningList: Array<{ message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      personenbezugList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        personenbezugId: string;
        tagList: Array<string>;
        person: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechtstraegerSteuerpflicht?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        rechtstraegerSteuerpflichtId: string;
        validFrom: string;
        historicizedList?: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ message: string; type: string }>;
        }> | null;
        steuerpflicht: { text: string; value: Types.Steuerpflicht };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
      sprache: { text: string; value: Types.Sprache };
      status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
      type: { value: Types.RechtstraegerType; text: string };
      vertragSummary?: {
        heVertragCount: number;
        heVertragVerwaltungCount: number;
        mietVertragCount: number;
        mietVertragVerwaltungCount: number;
        weSubverwaltungCount: number;
        weVertragCount: number;
        weVertragVerwaltungCount: number;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    sachbearbeiter?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      fileStorageInitialized: boolean;
      firmendatenId: string;
      firstname: string;
      funktionsModulList: Array<string>;
      lastname: string;
      mitarbeiterId: string;
      title?: string | null;
      titleTrailing?: string | null;
      username: string;
      contactPerson?: {
        contactPersonId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firstname: string;
        lastname: string;
        tagList: Array<string>;
        title?: string | null;
        titleTrailing?: string | null;
        contactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        rechtstraeger?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rolleList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        name: string;
        rolleId: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
      status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
      user?: {
        email?: string | null;
        emailUnverified?: string | null;
        firstname?: string | null;
        lastname?: string | null;
        role: string;
        username: string;
        status: { text: string; value: string };
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    status: { text: string; value: Types.ObjektStatus; description?: string | null };
    verwaltungsart: { text: string; value: Types.Verwaltungsart };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const AuftragListEntryFragmentDoc = gql`
  fragment AuftragListEntry on AuftragListEntry {
    auftragId
    auftragsart {
      ...AuftragsartBaseFields
    }
    aussteller {
      ...OrderRechtstraeger
    }
    ausstellerBankDetails {
      ...OrderBankDetailsFields
    }
    ausstellerRechtstraegerId
    belegFileId
    belegId
    belegnummer
    bestandseinheitWithObjektBezeichnungList
    buchungskreisId
    buchungIdList
    buchungsanweisungStatus {
      text
      value
    }
    createTs
    createdBy
    createdByMitarbeiterId
    dueDate
    empfaenger {
      ...OrderRechtstraeger
    }
    empfaengerRechtstraegerId
    empfaengerBankDetails {
      ...OrderBankDetailsFields
    }
    fakturierungsperiode {
      jahr
      monat
    }
    generierlaufId
    objektBestandseinheitIdDataList {
      bestandseinheitId
      objektId
    }
    offenerBetrag
    paymentCreatable
    paymentMethod {
      text
      value
    }
    rechnungsDatum
    sepaCreditorId
    sepaMandatReference
    status {
      text
      value
      description
    }
    storniert
    sumBrutto
    sumNetto
    sumUst
    vertragsart {
      text
      value
    }
  }
  ${AuftragsartBaseFieldsFragmentDoc}
  ${OrderRechtstraegerFragmentDoc}
  ${OrderBankDetailsFieldsFragmentDoc}
`;
export const AuftragFragmentDoc = gql`
  fragment Auftrag on Auftrag {
    auftragId
    auftragsKopf {
      auftragsartType {
        text
        value
      }
      aussteller {
        ...OrderRechtstraeger
      }
      ausstellerBankDetails {
        ...OrderBankDetailsFields
      }
      belegform {
        text
        value
      }
      consolidationCriteria
      deliveryAddress {
        ...OrderAddress
      }
      dueDateVorschreibung {
        text
        value
      }
      empfaenger {
        ...OrderRechtstraeger
      }
      empfaengerBankDetails {
        ...OrderBankDetailsFields
      }
      fakturierungsperiode {
        jahr
        monat
      }
      objekt {
        ...OrderObjekt
      }
      paymentMethod {
        text
        value
      }
      rechnungsAusstellerBezeichnung
      recipientEmailContact {
        ...OrderContactFields
      }
      sepaCreditorId
      sepaMandatReference
      verrechnungsart {
        text
        value
      }
      vertragsart {
        text
        value
      }
      zustellRechtstraeger {
        ...OrderRechtstraeger
      }
    }
    auftragsPositionList {
      auftragId
      auftragsPositionId
      bestandseinheit {
        ...BestandseinheitFields
      }
      bestandseinheitId
      bezeichnung
      brutto
      createTs
      createdBy
      createdByMitarbeiterId
      fibuStatus {
        text
        value
        description
      }
      generierDatum
      generierlaufId
      labelList {
        label
        value
      }
      netto
      objekt {
        ...Objekt
      }
      objektId
      originId
      performancePeriodFromInclusive
      performancePeriodToInclusive
      rechnungskennzeichen
      steuersatz
      ust
      ustKategorieEntryId
      ustKategorieId
      ustKategorieVersionId
      vertragBezeichnung
      vertragId
      vertragsart {
        supportsExplicitVertragsEnde
        text
        value
      }
      vorschreibungspositionBezeichnung
      vorschreibungspositionId
    }
    auftragsart {
      ...AuftragsartBaseFields
    }
    billingAddress {
      ...OrderAddress
    }
    buchungErrorList {
      message
      type
    }
    buchungIdList
    copyEmailRecipientList {
      contact {
        ...OrderContactFields
      }
      rechtstraeger {
        ...OrderRechtstraeger
      }
    }
    copyMailDeliveryList {
      ...VorschreibungMailDeliveryFields
      resentDeliveryList {
        ...VorschreibungMailDeliveryFields
      }
      sourceDelivery {
        ...VorschreibungMailDeliveryFields
      }
    }
    createTs
    createdBy
    createdByMitarbeiterId
    dueDate
    eigenbeleg {
      ...Eigenbeleg
    }
    empfaengerBankDetailsList {
      ...BankDetailsFields
    }
    fibuStatus {
      description
      text
      value
    }
    generierDatum
    generierlaufId
    hasAuftragskette
    objektBestandseinheitIdDataList {
      bestandseinheitId
      objektId
    }
    originalMailDelivery {
      ...VorschreibungMailDeliveryFields
      resentDeliveryList {
        ...VorschreibungMailDeliveryFields
      }
      sourceDelivery {
        ...VorschreibungMailDeliveryFields
      }
    }
    status {
      description
      text
      value
    }
    stornierbar
    storniert
    sumBrutto
    sumNetto
    sumUst
  }
  ${OrderRechtstraegerFragmentDoc}
  ${OrderBankDetailsFieldsFragmentDoc}
  ${OrderAddressFragmentDoc}
  ${OrderObjektFragmentDoc}
  ${OrderContactFieldsFragmentDoc}
  ${BestandseinheitFieldsFragmentDoc}
  ${ObjektFragmentDoc}
  ${AuftragsartBaseFieldsFragmentDoc}
  ${VorschreibungMailDeliveryFieldsFragmentDoc}
  ${EigenbelegFragmentDoc}
  ${BankDetailsFieldsFragmentDoc}
`;
export const AppAuftragFieldsFragmentDoc = gql`
  fragment AppAuftragFields on AppAuftrag {
    auftragIdList
    auftragsKopf {
      auftragsartType {
        text
        value
      }
      aussteller {
        ...RechtstraegerFields
      }
      empfaenger {
        ...RechtstraegerFields
      }
      fakturierungsperiode {
        jahr
        monat
      }
    }
    bestandseinheit {
      ...BestandseinheitFields
    }
    createTs
    createdBy
    createdByMitarbeiterId
    objekt {
      ...Objekt
    }
    sumBrutto
    sumNetto
    sumUst
    vertragId
    warningList {
      attribute
      message
      type
    }
  }
  ${RechtstraegerFieldsFragmentDoc}
  ${BestandseinheitFieldsFragmentDoc}
  ${ObjektFragmentDoc}
`;
