import { FC } from 'react';
import { HistoryType } from '../../../../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../../../../History/HistoryListingTable';
import { useBeVertragVorschreibungspositionWertChangeHistoryListQuery } from '../../gql/BeVertragVorschreibungspositionQueries.types';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  vorschreibungspositionId: string;
  vorschreibungspositionWertId: string;
};

const VertragVorschreibungsPositionWertChangeHistoryListingTable: FC<Props> = ({
  objektId,
  bestandseinheitId,
  vertragId,
  vorschreibungspositionId,
  vorschreibungspositionWertId,
}) => {
  const { data, loading } = useBeVertragVorschreibungspositionWertChangeHistoryListQuery({
    variables: { objektId, bestandseinheitId, vertragId, vorschreibungspositionId, vorschreibungspositionWertId },
  });
  const historyList = data?.getBeVertragVorschreibungspositionWertChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="be-vertrag-vpos-wert" />;
};

export default VertragVorschreibungsPositionWertChangeHistoryListingTable;
