import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Col, Row, Space, Typography } from 'antd';
import FormButtons from '../../../../../../components/Button/FormButtons';
import { showSuccessMsgCreate } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import {
  useCreateFirmaContactMutation,
  useCreateFirmendatenContactMutation,
  useCreateNatuerlichePersonContactMutation,
  useCreatePersonenGemeinschaftContactMutation,
} from '../../../gql/AddressesAndContactsMutations.types';
import { phoneContactCreateFormFields, phoneContactCreateFormInitialValues, PhoneContactCreateFormValues } from './phoneContactCreateFormMapper';
import { RechtstraegerType } from '../../../../../../types';
import Errors from '../../../../../shared/Errors';
import { phoneContactValidationSchema } from './phoneContactValidationSchema';
import ContactFormPart from '../../../../../../shared/components/Contact/ContactFormPart';
import { ContactType } from '../../../../../../shared/components/Contact/contactHelper';
import { useGetAppFieldHelpText } from '../../../../../FieldHelp/useGetFieldHelpText';
import FieldHelpIconWithTooltip from '../../../../../../components/FieldHelpIcon/FieldHelpIconWithTooltip';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
  rechtstraegerType: RechtstraegerType;
  rechtstraegerId?: string;
  operatingSiteId?: string;
};

const PhoneContactCreateForm: FC<Props> = ({ rechtstraegerId, onSuccess, onCancel, rechtstraegerType, operatingSiteId }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'ContactPhone'>('ContactPhone');

  const entity = 'Kontakt';

  const [createNatuerlichePersonContact, { loading: loadingNatuerlichePersonContact }] = useCreateNatuerlichePersonContactMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [createPersonenGemeinschaftContact, { loading: loadingPersonenGemeinschaftContact }] = useCreatePersonenGemeinschaftContactMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [createFirmaContact, { loading: loadingFirmaContact }] = useCreateFirmaContactMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [createFirmendatenContact, { loading: loadingFirmendatenContact }] = useCreateFirmendatenContactMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<PhoneContactCreateFormValues>
      initialValues={phoneContactCreateFormInitialValues}
      validationSchema={phoneContactValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (rechtstraegerId) {
          if (rechtstraegerType === RechtstraegerType.PersonenGemeinschaft) {
            createPersonenGemeinschaftContact({
              variables: { personenGemeinschaftId: rechtstraegerId, input: values },
            }).finally(() => setSubmitting(false));
          } else if (rechtstraegerType === RechtstraegerType.NatuerlichePerson) {
            createNatuerlichePersonContact({
              variables: { natuerlichePersonId: rechtstraegerId, input: values },
            }).finally(() => setSubmitting(false));
          } else if (rechtstraegerType === RechtstraegerType.Firma) {
            createFirmaContact({
              variables: { firmaId: rechtstraegerId, input: { ...values, operatingSiteId } },
            }).finally(() => setSubmitting(false));
          }
        } else {
          createFirmendatenContact({
            variables: { input: { ...values, operatingSiteId } },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form>
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>
                Telefonnummer <FieldHelpIconWithTooltip fieldHelp={getFieldHelpText('ContactPhone.wert')} />
              </Typography.Title>
            </Col>
            <Col span={18}>
              <Space direction="vertical">
                <ContactFormPart
                  name={phoneContactCreateFormFields.wertList}
                  type={ContactType.PHONE}
                  wertList={formikProps.values.wertList}
                  errors={formikProps.errors.wertList as string[]}
                  onRemove={(name) => formikProps.setFieldValue(name, '')}
                />
                <Errors errors={formikProps.errors.wertList as string[]} values={[]} />
              </Space>
            </Col>
          </Row>
          <FormButtons
            updateMode={false}
            onCancel={onCancel}
            isOkLoading={loadingNatuerlichePersonContact || loadingPersonenGemeinschaftContact || loadingFirmaContact || loadingFirmendatenContact}
            isOkDisabled={formikProps.isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default PhoneContactCreateForm;
