import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { KontoFieldsFragmentDoc } from '../../Kontenplan/gql/KontenplanFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KontoSelectListQueryVariables = Types.Exact<{
  klasseList?: Types.InputMaybe<Array<Types.KontoKlasse> | Types.KontoKlasse>;
  verwendung?: Types.InputMaybe<Types.KontoVerwendung>;
  type?: Types.InputMaybe<Types.KontoType>;
}>;

export type KontoSelectListQuery = {
  getKontoList: {
    data: {
      contentList: Array<{
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        haupt: boolean;
        kontoId: string;
        kontoTemplateId?: string | null;
        nummer: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        klasse: { text: string; value: Types.KontoKlasse };
        status: { description?: string | null; text: string; value: Types.KontoStatus };
        type: { text: string; value: Types.KontoType };
        verwendung?: { text: string; value: Types.KontoVerwendung } | null;
      }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const KontoSelectListDocument = gql`
  query KontoSelectList($klasseList: [KontoKlasse!], $verwendung: KontoVerwendung, $type: KontoType) {
    getKontoList(klasseList: $klasseList, verwendung: $verwendung, type: $type, includeArchiviert: true) {
      data {
        contentList {
          ...KontoFields
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KontoFieldsFragmentDoc}
`;
export function useKontoSelectListQuery(baseOptions?: Apollo.QueryHookOptions<KontoSelectListQuery, KontoSelectListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontoSelectListQuery, KontoSelectListQueryVariables>(KontoSelectListDocument, options);
}
export function useKontoSelectListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KontoSelectListQuery, KontoSelectListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontoSelectListQuery, KontoSelectListQueryVariables>(KontoSelectListDocument, options);
}
export function useKontoSelectListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KontoSelectListQuery, KontoSelectListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontoSelectListQuery, KontoSelectListQueryVariables>(KontoSelectListDocument, options);
}
export type KontoSelectListQueryHookResult = ReturnType<typeof useKontoSelectListQuery>;
export type KontoSelectListLazyQueryHookResult = ReturnType<typeof useKontoSelectListLazyQuery>;
export type KontoSelectListSuspenseQueryHookResult = ReturnType<typeof useKontoSelectListSuspenseQuery>;
export type KontoSelectListQueryResult = Apollo.QueryResult<KontoSelectListQuery, KontoSelectListQueryVariables>;
