import * as Yup from 'yup';
import { indAgreeReferenceValueFormFields } from './referenceValueFormMapper';
import { entityIsRequired } from '../../../../../../../../../components/message/validationMsg';

export const referenceValueFormValidationSchema = Yup.object().shape({
  [indAgreeReferenceValueFormFields.indexOld]: Yup.number().nullable(),
  [indAgreeReferenceValueFormFields.indexSeriesId]: Yup.string().required(entityIsRequired('Indexreihe')),
  [indAgreeReferenceValueFormFields.monthYearOld]: Yup.string().required(entityIsRequired('Monat und Jahr', true)),
  [indAgreeReferenceValueFormFields.validFrom]: Yup.string().required(entityIsRequired('Monat und Jahr', true)),
});
