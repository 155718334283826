import { FormattedNumber } from 'react-intl';
import React, { FC } from 'react';
import { NumberFormatOptions } from '@formatjs/ecma402-abstract';
import { CustomFormatConfig } from '@formatjs/intl';

type FormattedNumberProps = FC<
  NumberFormatOptions &
    CustomFormatConfig & {
      value: number;
    }
>;

const EuroAmount: FormattedNumberProps = (props) => (
  // eslint-disable-next-line react/style-prop-object
  <FormattedNumber minimumFractionDigits={2} maximumFractionDigits={2} {...props} style="currency" currency="EUR" />
);

const Percent: FormattedNumberProps = (props) => (
  // eslint-disable-next-line react/style-prop-object
  <FormattedNumber maximumFractionDigits={2} {...props} style="percent" />
);

export { EuroAmount, Percent };
