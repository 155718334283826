import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRepFondsEinstellungMutationVariables = Types.Exact<{
  input: Types.RepFondsEinstellungCreateInput;
}>;

export type CreateRepFondsEinstellungMutation = {
  createRepFondsEinstellung: {
    data: { validFrom: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateRepFondsEinstellungMutationVariables = Types.Exact<{
  repFondsEinstellungId: Types.Scalars['ID']['input'];
  input: Types.RepFondsEinstellungUpdateInput;
}>;

export type UpdateRepFondsEinstellungMutation = {
  updateRepFondsEinstellung: {
    data: { validFrom: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteRepFondsEinstellungMutationVariables = Types.Exact<{
  repFondsEinstellungId: Types.Scalars['ID']['input'];
}>;

export type DeleteRepFondsEinstellungMutation = {
  deleteRepFondsEinstellung: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateRepFondsEinstellungDocument = gql`
  mutation CreateRepFondsEinstellung($input: RepFondsEinstellungCreateInput!) {
    createRepFondsEinstellung(input: $input) {
      data {
        validFrom
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateRepFondsEinstellungMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRepFondsEinstellungMutation, CreateRepFondsEinstellungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRepFondsEinstellungMutation, CreateRepFondsEinstellungMutationVariables>(
    CreateRepFondsEinstellungDocument,
    options
  );
}
export type CreateRepFondsEinstellungMutationHookResult = ReturnType<typeof useCreateRepFondsEinstellungMutation>;
export type CreateRepFondsEinstellungMutationResult = Apollo.MutationResult<CreateRepFondsEinstellungMutation>;
export type CreateRepFondsEinstellungMutationOptions = Apollo.BaseMutationOptions<
  CreateRepFondsEinstellungMutation,
  CreateRepFondsEinstellungMutationVariables
>;
export const UpdateRepFondsEinstellungDocument = gql`
  mutation UpdateRepFondsEinstellung($repFondsEinstellungId: ID!, $input: RepFondsEinstellungUpdateInput!) {
    updateRepFondsEinstellung(repFondsEinstellungId: $repFondsEinstellungId, input: $input) {
      data {
        validFrom
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateRepFondsEinstellungMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRepFondsEinstellungMutation, UpdateRepFondsEinstellungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRepFondsEinstellungMutation, UpdateRepFondsEinstellungMutationVariables>(
    UpdateRepFondsEinstellungDocument,
    options
  );
}
export type UpdateRepFondsEinstellungMutationHookResult = ReturnType<typeof useUpdateRepFondsEinstellungMutation>;
export type UpdateRepFondsEinstellungMutationResult = Apollo.MutationResult<UpdateRepFondsEinstellungMutation>;
export type UpdateRepFondsEinstellungMutationOptions = Apollo.BaseMutationOptions<
  UpdateRepFondsEinstellungMutation,
  UpdateRepFondsEinstellungMutationVariables
>;
export const DeleteRepFondsEinstellungDocument = gql`
  mutation DeleteRepFondsEinstellung($repFondsEinstellungId: ID!) {
    deleteRepFondsEinstellung(repFondsEinstellungId: $repFondsEinstellungId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteRepFondsEinstellungMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRepFondsEinstellungMutation, DeleteRepFondsEinstellungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRepFondsEinstellungMutation, DeleteRepFondsEinstellungMutationVariables>(
    DeleteRepFondsEinstellungDocument,
    options
  );
}
export type DeleteRepFondsEinstellungMutationHookResult = ReturnType<typeof useDeleteRepFondsEinstellungMutation>;
export type DeleteRepFondsEinstellungMutationResult = Apollo.MutationResult<DeleteRepFondsEinstellungMutation>;
export type DeleteRepFondsEinstellungMutationOptions = Apollo.BaseMutationOptions<
  DeleteRepFondsEinstellungMutation,
  DeleteRepFondsEinstellungMutationVariables
>;
