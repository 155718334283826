import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { IndexSeries } from '../../../../types';
import IndexSeriesDetailsBtnAndDrawer from './IndexSeriesDetailsBtnAndDrawer';
import { Spacer } from '../../../../components/Grid';
import IndexSeriesDetailsTable from './Table/IndexSeriesDetailsTable';
import { useToggle } from '../../../../hooks/useToggle';

type Props = {
  indexSeries: IndexSeries;
  onAction: () => void;
  isAndromedaAdmin: boolean;
};

const IndexSeriesDetailsListing: FC<Props> = ({ indexSeries, onAction, isAndromedaAdmin }) => {
  const [showPreliminary, toggleShowPreliminary] = useToggle();

  return (
    <>
      <IndexSeriesDetailsBtnAndDrawer
        onSuccess={onAction}
        indexSeries={indexSeries}
        showPreliminary={showPreliminary}
        toggleShowPreliminary={toggleShowPreliminary}
      />
      <Space size={24}>
        <Space>
          <Typography.Text type="secondary">Wertsicherung</Typography.Text>
          <Typography.Text>{indexSeries.name}</Typography.Text>
        </Space>
        <Space>
          <Typography.Text type="secondary">Typ</Typography.Text>
          <Typography.Text>{indexSeries.indexType.text}</Typography.Text>
        </Space>
        <Space>
          <Typography.Text type="secondary">Quelle</Typography.Text>
          <Typography.Text>{indexSeries.sourceType.text}</Typography.Text>
        </Space>
      </Space>
      <Spacer height={24} />
      <IndexSeriesDetailsTable indexSeries={indexSeries} onAction={onAction} showPreliminary={showPreliminary} isAndromedaAdmin={isAndromedaAdmin} />
    </>
  );
};

export default IndexSeriesDetailsListing;
