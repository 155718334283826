import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateFirmaMutationVariables = Types.Exact<{
  request: Types.FirmaCreateInput;
}>;

export type CreateFirmaMutation = {
  createFirma: {
    data: { rechtstraegerId: string; kurzBezeichnung: string; type: { value: Types.RechtstraegerType } };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateFirmaMutationVariables = Types.Exact<{
  firmaId: Types.Scalars['ID']['input'];
  request: Types.FirmaUpdateInput;
}>;

export type UpdateFirmaMutation = {
  updateFirma: {
    data: { rechtstraegerId: string; kurzBezeichnung: string; type: { value: Types.RechtstraegerType } };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteFirmaMutationVariables = Types.Exact<{
  firmaId: Types.Scalars['ID']['input'];
}>;

export type DeleteFirmaMutation = {
  deleteFirma: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type SperrenFirmaMutationVariables = Types.Exact<{
  firmaId: Types.Scalars['ID']['input'];
}>;

export type SperrenFirmaMutation = {
  actionSperrenFirma: {
    data: { rechtstraegerId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type EntsperrenFirmaMutationVariables = Types.Exact<{
  firmaId: Types.Scalars['ID']['input'];
}>;

export type EntsperrenFirmaMutation = {
  actionEntsperrenFirma: {
    data: { rechtstraegerId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ArchiveFirmaMutationVariables = Types.Exact<{
  firmaId: Types.Scalars['ID']['input'];
}>;

export type ArchiveFirmaMutation = {
  actionArchiveFirma: {
    data: { rechtstraegerId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveFirmaMutationVariables = Types.Exact<{
  firmaId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveFirmaMutation = {
  actionUnarchiveFirma: {
    data: { rechtstraegerId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateFirmaOperatingSiteMutationVariables = Types.Exact<{
  firmaId: Types.Scalars['String']['input'];
  request: Types.OperatingSiteInput;
}>;

export type CreateFirmaOperatingSiteMutation = {
  createFirmaOperatingSite: {
    data: { operatingSiteId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateFirmaOperatingSiteMutationVariables = Types.Exact<{
  firmaId: Types.Scalars['String']['input'];
  operatingSiteId: Types.Scalars['ID']['input'];
  request: Types.OperatingSiteInput;
}>;

export type UpdateFirmaOperatingSiteMutation = {
  updateFirmaOperatingSite: {
    data: { operatingSiteId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type DeleteFirmaOperatingSiteMutationVariables = Types.Exact<{
  firmaId: Types.Scalars['String']['input'];
  operatingSiteId: Types.Scalars['ID']['input'];
}>;

export type DeleteFirmaOperatingSiteMutation = {
  deleteFirmaOperatingSite: { errorList: Array<{ message: string; type: string }>; warningList: Array<{ message: string; type: string }> };
};

export const CreateFirmaDocument = gql`
  mutation CreateFirma($request: FirmaCreateInput!) {
    createFirma(request: $request) {
      data {
        rechtstraegerId
        kurzBezeichnung
        type {
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateFirmaMutation(baseOptions?: Apollo.MutationHookOptions<CreateFirmaMutation, CreateFirmaMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFirmaMutation, CreateFirmaMutationVariables>(CreateFirmaDocument, options);
}
export type CreateFirmaMutationHookResult = ReturnType<typeof useCreateFirmaMutation>;
export type CreateFirmaMutationResult = Apollo.MutationResult<CreateFirmaMutation>;
export type CreateFirmaMutationOptions = Apollo.BaseMutationOptions<CreateFirmaMutation, CreateFirmaMutationVariables>;
export const UpdateFirmaDocument = gql`
  mutation UpdateFirma($firmaId: ID!, $request: FirmaUpdateInput!) {
    updateFirma(firmaId: $firmaId, request: $request) {
      data {
        rechtstraegerId
        kurzBezeichnung
        type {
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateFirmaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFirmaMutation, UpdateFirmaMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFirmaMutation, UpdateFirmaMutationVariables>(UpdateFirmaDocument, options);
}
export type UpdateFirmaMutationHookResult = ReturnType<typeof useUpdateFirmaMutation>;
export type UpdateFirmaMutationResult = Apollo.MutationResult<UpdateFirmaMutation>;
export type UpdateFirmaMutationOptions = Apollo.BaseMutationOptions<UpdateFirmaMutation, UpdateFirmaMutationVariables>;
export const DeleteFirmaDocument = gql`
  mutation DeleteFirma($firmaId: ID!) {
    deleteFirma(firmaId: $firmaId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteFirmaMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFirmaMutation, DeleteFirmaMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFirmaMutation, DeleteFirmaMutationVariables>(DeleteFirmaDocument, options);
}
export type DeleteFirmaMutationHookResult = ReturnType<typeof useDeleteFirmaMutation>;
export type DeleteFirmaMutationResult = Apollo.MutationResult<DeleteFirmaMutation>;
export type DeleteFirmaMutationOptions = Apollo.BaseMutationOptions<DeleteFirmaMutation, DeleteFirmaMutationVariables>;
export const SperrenFirmaDocument = gql`
  mutation SperrenFirma($firmaId: ID!) {
    actionSperrenFirma(firmaId: $firmaId) {
      data {
        rechtstraegerId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSperrenFirmaMutation(baseOptions?: Apollo.MutationHookOptions<SperrenFirmaMutation, SperrenFirmaMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SperrenFirmaMutation, SperrenFirmaMutationVariables>(SperrenFirmaDocument, options);
}
export type SperrenFirmaMutationHookResult = ReturnType<typeof useSperrenFirmaMutation>;
export type SperrenFirmaMutationResult = Apollo.MutationResult<SperrenFirmaMutation>;
export type SperrenFirmaMutationOptions = Apollo.BaseMutationOptions<SperrenFirmaMutation, SperrenFirmaMutationVariables>;
export const EntsperrenFirmaDocument = gql`
  mutation EntsperrenFirma($firmaId: ID!) {
    actionEntsperrenFirma(firmaId: $firmaId) {
      data {
        rechtstraegerId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useEntsperrenFirmaMutation(baseOptions?: Apollo.MutationHookOptions<EntsperrenFirmaMutation, EntsperrenFirmaMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EntsperrenFirmaMutation, EntsperrenFirmaMutationVariables>(EntsperrenFirmaDocument, options);
}
export type EntsperrenFirmaMutationHookResult = ReturnType<typeof useEntsperrenFirmaMutation>;
export type EntsperrenFirmaMutationResult = Apollo.MutationResult<EntsperrenFirmaMutation>;
export type EntsperrenFirmaMutationOptions = Apollo.BaseMutationOptions<EntsperrenFirmaMutation, EntsperrenFirmaMutationVariables>;
export const ArchiveFirmaDocument = gql`
  mutation ArchiveFirma($firmaId: ID!) {
    actionArchiveFirma(firmaId: $firmaId) {
      data {
        rechtstraegerId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveFirmaMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveFirmaMutation, ArchiveFirmaMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveFirmaMutation, ArchiveFirmaMutationVariables>(ArchiveFirmaDocument, options);
}
export type ArchiveFirmaMutationHookResult = ReturnType<typeof useArchiveFirmaMutation>;
export type ArchiveFirmaMutationResult = Apollo.MutationResult<ArchiveFirmaMutation>;
export type ArchiveFirmaMutationOptions = Apollo.BaseMutationOptions<ArchiveFirmaMutation, ArchiveFirmaMutationVariables>;
export const UnarchiveFirmaDocument = gql`
  mutation UnarchiveFirma($firmaId: ID!) {
    actionUnarchiveFirma(firmaId: $firmaId) {
      data {
        rechtstraegerId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveFirmaMutation(baseOptions?: Apollo.MutationHookOptions<UnarchiveFirmaMutation, UnarchiveFirmaMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveFirmaMutation, UnarchiveFirmaMutationVariables>(UnarchiveFirmaDocument, options);
}
export type UnarchiveFirmaMutationHookResult = ReturnType<typeof useUnarchiveFirmaMutation>;
export type UnarchiveFirmaMutationResult = Apollo.MutationResult<UnarchiveFirmaMutation>;
export type UnarchiveFirmaMutationOptions = Apollo.BaseMutationOptions<UnarchiveFirmaMutation, UnarchiveFirmaMutationVariables>;
export const CreateFirmaOperatingSiteDocument = gql`
  mutation CreateFirmaOperatingSite($firmaId: String!, $request: OperatingSiteInput!) {
    createFirmaOperatingSite(firmaId: $firmaId, request: $request) {
      data {
        operatingSiteId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateFirmaOperatingSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFirmaOperatingSiteMutation, CreateFirmaOperatingSiteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFirmaOperatingSiteMutation, CreateFirmaOperatingSiteMutationVariables>(CreateFirmaOperatingSiteDocument, options);
}
export type CreateFirmaOperatingSiteMutationHookResult = ReturnType<typeof useCreateFirmaOperatingSiteMutation>;
export type CreateFirmaOperatingSiteMutationResult = Apollo.MutationResult<CreateFirmaOperatingSiteMutation>;
export type CreateFirmaOperatingSiteMutationOptions = Apollo.BaseMutationOptions<
  CreateFirmaOperatingSiteMutation,
  CreateFirmaOperatingSiteMutationVariables
>;
export const UpdateFirmaOperatingSiteDocument = gql`
  mutation UpdateFirmaOperatingSite($firmaId: String!, $operatingSiteId: ID!, $request: OperatingSiteInput!) {
    updateFirmaOperatingSite(firmaId: $firmaId, operatingSiteId: $operatingSiteId, request: $request) {
      data {
        operatingSiteId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUpdateFirmaOperatingSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFirmaOperatingSiteMutation, UpdateFirmaOperatingSiteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFirmaOperatingSiteMutation, UpdateFirmaOperatingSiteMutationVariables>(UpdateFirmaOperatingSiteDocument, options);
}
export type UpdateFirmaOperatingSiteMutationHookResult = ReturnType<typeof useUpdateFirmaOperatingSiteMutation>;
export type UpdateFirmaOperatingSiteMutationResult = Apollo.MutationResult<UpdateFirmaOperatingSiteMutation>;
export type UpdateFirmaOperatingSiteMutationOptions = Apollo.BaseMutationOptions<
  UpdateFirmaOperatingSiteMutation,
  UpdateFirmaOperatingSiteMutationVariables
>;
export const DeleteFirmaOperatingSiteDocument = gql`
  mutation DeleteFirmaOperatingSite($firmaId: String!, $operatingSiteId: ID!) {
    deleteFirmaOperatingSite(firmaId: $firmaId, operatingSiteId: $operatingSiteId) {
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeleteFirmaOperatingSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFirmaOperatingSiteMutation, DeleteFirmaOperatingSiteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFirmaOperatingSiteMutation, DeleteFirmaOperatingSiteMutationVariables>(DeleteFirmaOperatingSiteDocument, options);
}
export type DeleteFirmaOperatingSiteMutationHookResult = ReturnType<typeof useDeleteFirmaOperatingSiteMutation>;
export type DeleteFirmaOperatingSiteMutationResult = Apollo.MutationResult<DeleteFirmaOperatingSiteMutation>;
export type DeleteFirmaOperatingSiteMutationOptions = Apollo.BaseMutationOptions<
  DeleteFirmaOperatingSiteMutation,
  DeleteFirmaOperatingSiteMutationVariables
>;
