import React, { FC } from 'react';
import { SelectProps } from 'formik-antd';
import { EntityStatusFormSelect } from '../../components/Status/EntityStatus';
import { Konto, KontoKlasse, KontoType, KontoVerwendung } from '../../types';
import { useKontoSelectListQuery } from './gql/KontoSelectQueries.types';
import TextWithTag from '../../components/Text/TextWithTag';

type Props = {
  kontoKlasse?: KontoKlasse[];
  kontoVerwendung?: KontoVerwendung;
  kontoType?: KontoType;
} & SelectProps;

const KontoFormSelect: FC<Props> = ({ disabled, name, kontoKlasse, kontoVerwendung, kontoType, ...restProps }) => {
  const { loading, data } = useKontoSelectListQuery({ variables: { klasseList: kontoKlasse, verwendung: kontoVerwendung, type: kontoType } });
  const kontoList = data?.getKontoList?.data.contentList ?? [];

  return (
    <EntityStatusFormSelect<Konto>
      list={kontoList}
      getSelectItemId={(konto) => konto.kontoId}
      getSelectItemContent={(konto) => <TextWithTag textContent={konto.nummer} tagContent={konto.bezeichnung} maxTextLength={30} />}
      getSelectItemTooltipTitle={(konto) => `${konto.nummer} - ${konto.bezeichnung}`}
      disabled={disabled}
      placeholder="Konto auswählen"
      name={name}
      loading={loading}
      {...restProps}
    />
  );
};

export default KontoFormSelect;
