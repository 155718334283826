import React, { FC } from 'react';
import { Avatar, Button, Card, Col, Divider, Drawer, Dropdown, MenuProps, Modal, Row, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { useToggle } from '../../hooks/useToggle';
import { concatFirstNameLetters } from '../../helpers/nameHelper';
import ContactList from '../../shared/components/Contact/ContactList';
import EmailVerified from '../../shared/components/Email/EmailVerified';
import { useDeleteAdminMutation } from './gql/AdminMutations.types';
import { showSuccessMsgDelete, showSuccessMsgEntsperrt, showSuccessMsgGesperrt, showSuccessMsgOther } from '../../components/message';
import { isStatusDeleted, isStatusGesperrt } from '../../helpers/statusHelper';
import { useGoBack } from '../../shared/GoBack/GoBackContext';
import EmailUpdateForm from '../../shared/components/Email/EmailUpdateForm';
import { useEntsperrenUserMutation, usePasswordResetUserMutation, useSperrenUserMutation } from '../../graphql/Authentication/UserMutation.types';
import StatusBadge from '../../components/Status/StatusBadge';
import AdminUpdateForm from './Form/Update/AdminUpdateForm';
import { userToString } from '../../helpers/stringHelper';
import { User } from '../../types';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../constants/andromedaSystemSettingsUriPaths';

type AdminCardProps = {
  admin: User;
  onAction: () => void;
};

const AdminCard: FC<AdminCardProps> = ({ admin, onAction, ...restProps }) => {
  const [isCollapsed, onCollapse] = useToggle();

  const goBack = useGoBack(URI_ANDROMEDA_SYS_SETTINGS.adminPage);

  const entity = 'Admin';

  const [runDelete] = useDeleteAdminMutation({
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onAction();
      goBack();
    },
  });

  const [runPasswordReset] = usePasswordResetUserMutation({
    onCompleted: () => {
      showSuccessMsgOther(`Dein Passwort wurde zurückgesetzt`);
      onAction();
      onCollapse();
    },
  });

  const [runSperren] = useSperrenUserMutation({
    onCompleted: () => {
      showSuccessMsgGesperrt(entity);
      onAction();
    },
  });

  const [runEntsperren] = useEntsperrenUserMutation({
    onCompleted: () => {
      showSuccessMsgEntsperrt(entity);
      onAction();
    },
  });

  return (
    <>
      <Card
        title={userToString(admin)}
        extra={
          <Dropdown menu={{ items: menuItems(onCollapse, runPasswordReset, runSperren, runEntsperren, runDelete, admin) }}>
            <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
          </Dropdown>
        }
        {...restProps}
      >
        <Card.Meta
          avatar={<Avatar>{concatFirstNameLetters(admin?.firstname, admin?.lastname)}</Avatar>}
          title={admin.username}
          description={<StatusBadge entityStatus={admin.status} showBadgeText={false} />}
        />

        <Divider dashed />
        <EmailVerified email={admin.email} emailUnverified={admin.emailUnverified} />
        <br />
        <Divider dashed />
        <Typography.Title level={5}>Kontakt</Typography.Title>
        <ContactList isMore contactList={admin.contactList ?? []} />
      </Card>
      <Drawer
        width={720}
        title="Profil bearbeiten"
        open={isCollapsed}
        onClose={onCollapse}
        destroyOnClose
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={onCollapse} type="primary">
              Schließen
            </Button>
          </div>
        }
      >
        <Row align="top">
          <Col span={6}>
            <Typography.Title level={5}>Zur Person</Typography.Title>
          </Col>
          <Col span={18}>
            <AdminUpdateForm admin={admin} onSuccess={onAction} onCancel={onCollapse} />
          </Col>
        </Row>
        <Divider />
        <Row align="top">
          <Col span={6}>
            <Typography.Title level={5}>Passwort</Typography.Title>
          </Col>
          <Col span={18}>
            <EmailUpdateForm user={admin} mitarbeiter={null} onCancel={onCollapse} onSuccess={onAction} />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

const menuItems = (
  onCollapse: () => void,
  runPasswordReset: (admin: { variables: { username: string } }) => void,
  runSperren: (admin: { variables: { username: string } }) => void,
  runEntsperren: (admin: { variables: { username: string } }) => void,
  runDelete: (admin: { variables: { username: string } }) => void,
  admin: User
): MenuProps['items'] => {
  return [
    { key: '1', label: 'Bearbeiten', onClick: onCollapse, icon: <EditOutlined /> },
    { key: '2', label: isStatusGesperrt(admin.status) ? 'Entsperren' : 'Sperren', onClick: () => showConfirmLock(admin, runSperren, runEntsperren) },
    {
      key: '3',
      label: 'Löschen',
      onClick: () => showConfirmDelete(admin, runDelete),
      disabled: isStatusDeleted(admin.status) || admin.username === 'admin',
      icon: <DeleteOutlined />,
      danger: true,
    },
    { key: '4', label: 'Passwort zurücksetzen', onClick: () => runPasswordReset({ variables: { username: admin.username } }) },
  ];
};

const showConfirmLock = (
  admin: User,
  runSperren: (admin: { variables: { username: string } }) => void,
  runEntsperren: (admin: { variables: { username: string } }) => void
) => {
  Modal.confirm({
    title: isStatusGesperrt(admin.status) ? `Möchten sie den Admin entsperren?` : `Möchten sie den Admin sperren?`,
    content: `${admin.username}`,
    okText: isStatusGesperrt(admin.status) ? 'Entsperren' : 'Sperren',
    cancelText: 'Nein',
    onOk() {
      if (isStatusGesperrt(admin.status)) {
        return runEntsperren({ variables: { username: admin.username } });
      } else {
        return runSperren({ variables: { username: admin.username } });
      }
    },
  });
};

const showConfirmDelete = (admin: User, runDelete: (admin: { variables: { username: string } }) => void) => {
  Modal.confirm({
    title: `Möchten Sie den Admin löschen?`,
    content: `${admin.username}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete({ variables: { username: admin.username } });
    },
  });
};

export default AdminCard;
