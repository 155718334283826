import React, { FC, useEffect, useState } from 'react';
import { Tooltip, TooltipProps } from 'antd';
import TruncatedText, { TruncatedTextProps } from './TruncatedText';
import StyledTooltipInner from './styled/StyledTooltipInner.style';

type Props = {
  text: React.ReactNode;
  onTruncationChange?: (isTruncated: boolean) => void;
  tooltipProps?: TooltipProps;
} & TruncatedTextProps;

const TruncatedTextWithTooltip: FC<Props> = ({ text, onTruncationChange, tooltipProps, ...restProps }) => {
  const [isTruncacted, setIsTruncated] = useState<boolean>(false);

  const [tooltipText, setTooltipText] = useState<React.ReactNode>('');

  useEffect(() => {
    setTooltipText(isTruncacted ? text : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTruncacted]);

  return (
    <Tooltip
      {...tooltipProps}
      title={tooltipText ? () => <StyledTooltipInner>{tooltipText}</StyledTooltipInner> : ''}
      styles={{ root: { maxWidth: '400px' } }}
    >
      <TruncatedText text={text} onTruncationChange={(isTruncated) => setIsTruncated(isTruncated)} {...restProps} />
    </Tooltip>
  );
};

export default TruncatedTextWithTooltip;
