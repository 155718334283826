import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CountryCodeQueryVariables = Types.Exact<{
  alpha2: Types.Scalars['String']['input'];
}>;

export type CountryCodeQuery = {
  getCountryCode: {
    data: { bezeichnung: string; alpha2: string; alpha3: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CountryCodeListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CountryCodeListQuery = {
  getCountryCodeList: {
    data: Array<{ bezeichnung: string; alpha2: string; alpha3: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CountryCodeDocument = gql`
  query CountryCode($alpha2: String!) {
    getCountryCode(alpha2: $alpha2) {
      data {
        bezeichnung
        alpha2
        alpha3
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCountryCodeQuery(
  baseOptions: Apollo.QueryHookOptions<CountryCodeQuery, CountryCodeQueryVariables> &
    ({ variables: CountryCodeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountryCodeQuery, CountryCodeQueryVariables>(CountryCodeDocument, options);
}
export function useCountryCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountryCodeQuery, CountryCodeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountryCodeQuery, CountryCodeQueryVariables>(CountryCodeDocument, options);
}
export function useCountryCodeSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CountryCodeQuery, CountryCodeQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CountryCodeQuery, CountryCodeQueryVariables>(CountryCodeDocument, options);
}
export type CountryCodeQueryHookResult = ReturnType<typeof useCountryCodeQuery>;
export type CountryCodeLazyQueryHookResult = ReturnType<typeof useCountryCodeLazyQuery>;
export type CountryCodeSuspenseQueryHookResult = ReturnType<typeof useCountryCodeSuspenseQuery>;
export type CountryCodeQueryResult = Apollo.QueryResult<CountryCodeQuery, CountryCodeQueryVariables>;
export const CountryCodeListDocument = gql`
  query CountryCodeList {
    getCountryCodeList {
      data {
        bezeichnung
        alpha2
        alpha3
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCountryCodeListQuery(baseOptions?: Apollo.QueryHookOptions<CountryCodeListQuery, CountryCodeListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountryCodeListQuery, CountryCodeListQueryVariables>(CountryCodeListDocument, options);
}
export function useCountryCodeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountryCodeListQuery, CountryCodeListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountryCodeListQuery, CountryCodeListQueryVariables>(CountryCodeListDocument, options);
}
export function useCountryCodeListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CountryCodeListQuery, CountryCodeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CountryCodeListQuery, CountryCodeListQueryVariables>(CountryCodeListDocument, options);
}
export type CountryCodeListQueryHookResult = ReturnType<typeof useCountryCodeListQuery>;
export type CountryCodeListLazyQueryHookResult = ReturnType<typeof useCountryCodeListLazyQuery>;
export type CountryCodeListSuspenseQueryHookResult = ReturnType<typeof useCountryCodeListSuspenseQuery>;
export type CountryCodeListQueryResult = Apollo.QueryResult<CountryCodeListQuery, CountryCodeListQueryVariables>;
