import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AddressesAndContactsFragmentDoc } from '../../../Rechtstraeger/AddressesAndContacts/gql/AddressesAndContactsFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateFirmendatenOperatingSiteMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  request: Types.OperatingSiteInput;
}>;

export type CreateFirmendatenOperatingSiteMutation = {
  createFirmendatenOperatingSite: {
    data: {
      operatingSiteId: string;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      addressesAndContacts: {
        contactPersonList: Array<{
          contactPersonId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firstname: string;
          lastname: string;
          tagList: Array<string>;
          title?: string | null;
          titleTrailing?: string | null;
          contactList: Array<{
            contactId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            haupt?: boolean | null;
            tagList: Array<string>;
            type: string;
            wert: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          rechtstraeger?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        emailContactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        hauptAddress?: {
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        hauptEmailContact?: {
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        hauptPhoneContact?: {
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        postofficeBoxAddressList: Array<{
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        streetAddressList: Array<{
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        phoneContactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateFirmendatenOperatingSiteMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  operatingSiteId: Types.Scalars['ID']['input'];
  request: Types.OperatingSiteInput;
}>;

export type UpdateFirmendatenOperatingSiteMutation = {
  updateFirmendatenOperatingSite: {
    data: {
      operatingSiteId: string;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      addressesAndContacts: {
        contactPersonList: Array<{
          contactPersonId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firstname: string;
          lastname: string;
          tagList: Array<string>;
          title?: string | null;
          titleTrailing?: string | null;
          contactList: Array<{
            contactId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            haupt?: boolean | null;
            tagList: Array<string>;
            type: string;
            wert: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          rechtstraeger?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        emailContactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        hauptAddress?: {
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        hauptEmailContact?: {
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        hauptPhoneContact?: {
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        postofficeBoxAddressList: Array<{
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        streetAddressList: Array<{
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        phoneContactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteFirmendatenOperatingSiteMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  operatingSiteId: Types.Scalars['ID']['input'];
}>;

export type DeleteFirmendatenOperatingSiteMutation = {
  deleteFirmendatenOperatingSite: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateFirmendatenOperatingSiteDocument = gql`
  mutation CreateFirmendatenOperatingSite($firmendatenId: ID!, $request: OperatingSiteInput!) {
    createFirmendatenOperatingSite(firmendatenId: $firmendatenId, request: $request) {
      data {
        addressesAndContacts {
          ...AddressesAndContacts
        }
        operatingSiteId
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AddressesAndContactsFragmentDoc}
`;
export function useCreateFirmendatenOperatingSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFirmendatenOperatingSiteMutation, CreateFirmendatenOperatingSiteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFirmendatenOperatingSiteMutation, CreateFirmendatenOperatingSiteMutationVariables>(
    CreateFirmendatenOperatingSiteDocument,
    options
  );
}
export type CreateFirmendatenOperatingSiteMutationHookResult = ReturnType<typeof useCreateFirmendatenOperatingSiteMutation>;
export type CreateFirmendatenOperatingSiteMutationResult = Apollo.MutationResult<CreateFirmendatenOperatingSiteMutation>;
export type CreateFirmendatenOperatingSiteMutationOptions = Apollo.BaseMutationOptions<
  CreateFirmendatenOperatingSiteMutation,
  CreateFirmendatenOperatingSiteMutationVariables
>;
export const UpdateFirmendatenOperatingSiteDocument = gql`
  mutation UpdateFirmendatenOperatingSite($firmendatenId: ID!, $operatingSiteId: ID!, $request: OperatingSiteInput!) {
    updateFirmendatenOperatingSite(firmendatenId: $firmendatenId, operatingSiteId: $operatingSiteId, request: $request) {
      data {
        addressesAndContacts {
          ...AddressesAndContacts
        }
        operatingSiteId
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AddressesAndContactsFragmentDoc}
`;
export function useUpdateFirmendatenOperatingSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFirmendatenOperatingSiteMutation, UpdateFirmendatenOperatingSiteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFirmendatenOperatingSiteMutation, UpdateFirmendatenOperatingSiteMutationVariables>(
    UpdateFirmendatenOperatingSiteDocument,
    options
  );
}
export type UpdateFirmendatenOperatingSiteMutationHookResult = ReturnType<typeof useUpdateFirmendatenOperatingSiteMutation>;
export type UpdateFirmendatenOperatingSiteMutationResult = Apollo.MutationResult<UpdateFirmendatenOperatingSiteMutation>;
export type UpdateFirmendatenOperatingSiteMutationOptions = Apollo.BaseMutationOptions<
  UpdateFirmendatenOperatingSiteMutation,
  UpdateFirmendatenOperatingSiteMutationVariables
>;
export const DeleteFirmendatenOperatingSiteDocument = gql`
  mutation DeleteFirmendatenOperatingSite($firmendatenId: ID!, $operatingSiteId: ID!) {
    deleteFirmendatenOperatingSite(firmendatenId: $firmendatenId, operatingSiteId: $operatingSiteId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteFirmendatenOperatingSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFirmendatenOperatingSiteMutation, DeleteFirmendatenOperatingSiteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFirmendatenOperatingSiteMutation, DeleteFirmendatenOperatingSiteMutationVariables>(
    DeleteFirmendatenOperatingSiteDocument,
    options
  );
}
export type DeleteFirmendatenOperatingSiteMutationHookResult = ReturnType<typeof useDeleteFirmendatenOperatingSiteMutation>;
export type DeleteFirmendatenOperatingSiteMutationResult = Apollo.MutationResult<DeleteFirmendatenOperatingSiteMutation>;
export type DeleteFirmendatenOperatingSiteMutationOptions = Apollo.BaseMutationOptions<
  DeleteFirmendatenOperatingSiteMutation,
  DeleteFirmendatenOperatingSiteMutationVariables
>;
