import React, { FC } from 'react';
import {
  createDefaultNewEntryForVorschreibungsPositionWert,
  mapFormValuesToVorschreibungsPositionWert,
  mapVorschreibungsPositionWertToFormValues,
  VorschreibungspositionWertFormValues,
} from './vertragVorschreibungsPositionWertMapper';
import { vertragVorschreibungsPositionWertTableColumns } from './vertragVorschreibungsPositionWertTableColumns';
import { vertragVorschreibungsPositionWertSchema } from './vertragVorschreibungsPositionWertSchema';
import { showSuccessMsgCreate, showSuccessMsgUpdate, showWarningMessage } from '../../../../../../../components/message';
import { useOnBeVertragDataChangedEvent } from '../../../../useOnBeVertragDataChangedEvent';
import { useBeVertragVorschreibungspositionWertListQuery } from '../../gql/BeVertragVorschreibungspositionQueries.types';
import { BeVertragVorschreibungsPositionWertFieldsFragment } from '../../gql/BeVertragVorschreibungspositionFragments.types';
import {
  useCreateBeVertragVorschreibungspositionWertMutation,
  useUpdateBeVertragVorschreibungspositionWertMutation,
} from '../../gql/BeVertragVorschreibungspositionMutations.types';
import EditableTable from '../../../../../../../components/Table/EditableTableV2/EditableTable';

type Props = {
  expanded: boolean;
  bestandseinheitId: string;
  objektId: string;
  vertragId: string;
  vertragsBeginn: string;
  vorschreibungspositionId: string;
  onListUpdated: () => void;
};

const VertragVorschreibungsPositionWertTable: FC<Props> = ({
  expanded,
  bestandseinheitId,
  objektId,
  vertragId,
  vertragsBeginn,
  vorschreibungspositionId,
  onListUpdated,
}) => {
  const { data, loading, refetch } = useBeVertragVorschreibungspositionWertListQuery({
    variables: { objektId, bestandseinheitId, vertragId, vorschreibungspositionId },
  });
  const wertList = data?.getBeVertragVorschreibungspositionWertList.data ?? [];

  useOnBeVertragDataChangedEvent('WertList', refetch);

  const onListUpdateSuccess = () => {
    refetch();
    onListUpdated();
  };

  const [createWert] = useCreateBeVertragVorschreibungspositionWertMutation();
  const [updateWert] = useUpdateBeVertragVorschreibungspositionWertMutation();

  return (
    <EditableTable<BeVertragVorschreibungsPositionWertFieldsFragment, VorschreibungspositionWertFormValues>
      level={2}
      colWidth={120}
      expanded={expanded}
      getRowKey={(record) => record.vertragVorschreibungspositionWertId}
      columns={vertragVorschreibungsPositionWertTableColumns(
        objektId,
        bestandseinheitId,
        vertragId,
        vorschreibungspositionId,
        onListUpdateSuccess,
        vertragsBeginn,
        wertList
      )}
      useHighlighting
      loading={loading}
      dataSource={wertList}
      newEntryRowToBeAdded={{
        addButtonText: 'Wert hinzufügen',
        defaultNewEntry: createDefaultNewEntryForVorschreibungsPositionWert(wertList, vertragsBeginn),
      }}
      mapToFormValues={mapVorschreibungsPositionWertToFormValues}
      validationSchema={vertragVorschreibungsPositionWertSchema}
      onSubmit={(formValues) => {
        const wert = mapFormValuesToVorschreibungsPositionWert(wertList, formValues, vertragsBeginn);
        if (!formValues.vertragVorschreibungspositionWertId) {
          return createWert({ variables: { objektId, bestandseinheitId, vertragId, vorschreibungspositionId, vorschreibungspositionWert: wert } })
            .then((response) => {
              showSuccessMsgCreate(`Wert`);
              onListUpdateSuccess();
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              return response.data!.createBeVertragVorschreibungspositionWert.data;
            })
            .catch((error) => {
              showWarningMessage(error, 'Wert wurde nicht angelegt');
              // error has to be thrown to reject promise
              throw error;
            });
        } else {
          return updateWert({
            variables: {
              objektId,
              bestandseinheitId,
              vertragId,
              vorschreibungspositionId,
              vorschreibungspositionWertId: formValues.vertragVorschreibungspositionWertId,
              vorschreibungspositionWert: wert,
            },
          })
            .then((response) => {
              showSuccessMsgUpdate(`Wert`);
              onListUpdateSuccess();
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              return response.data!.updateBeVertragVorschreibungspositionWert.data;
            })
            .catch((error) => {
              showWarningMessage(error, 'Wert wurde nicht aktualisiert');
              // error has to be thrown to reject promise
              throw error;
            });
        }
      }}
    />
  );
};

export default VertragVorschreibungsPositionWertTable;
