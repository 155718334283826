import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { AppGenerierlaufType } from '../../../../../types';
import { useFilterGenLaufAppVorschreibungsperiodeListQuery } from '../../../gql/GenerierlaufFilter/AppGenerierlaufFilterQueries.types';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';

type Props = {
  name: string;
  generierlaufId: string;
  generierlaufType: AppGenerierlaufType;
} & SelectProps;

const GenerierlaufVorschreibungperiodeFormSelect: FC<Props> = ({ name, generierlaufId, generierlaufType, ...restProps }) => {
  const { data, loading } = useFilterGenLaufAppVorschreibungsperiodeListQuery({
    variables: {
      generierlaufId,
      generierlaufType,
    },
  });

  const list = data?.getFilterGenLaufAppVorschreibungsperiodeList.data ?? [];

  return (
    <Select
      size="small"
      loading={loading}
      id={name}
      name={name}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      placeholder="Vorschreibungsperiode auswählen"
      {...restProps}
    >
      {list.map((vorschreibungperiode) => {
        const value = dayjsCustom()
          .month(vorschreibungperiode.monat - 1) // month is zero based, january = 0 in dayjs
          .year(vorschreibungperiode.jahr)
          .format('YYYY-MM');
        const text = `${vorschreibungperiode.monat}/${vorschreibungperiode.jahr}`;
        return (
          <Select.Option key={value} value={value}>
            {text}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default GenerierlaufVorschreibungperiodeFormSelect;
