import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { VerarbeitungVorschreibungMailDeliveryDetermineDetailsPageRouteParams } from './routes';
import menuListVorschreibungMailDeliveryDetermineRecipientList from './menuListVorschreibungMailDeliveryDetermineRecipientList';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import { useVorschreibungMailDeliveryDetermineRecipientListGenerierlaufQuery } from '../../../features/Verarbeitung/gql/VorschreibungMailDeliveryDetermineRecipientListGenerierLauf/VorschreibungMailDeliveryDetermineRecipientListGenerierlaufQueries.types';
import { VorschreibungMailDeliveryDetermineRecipientListGenerierlauf } from '../../../types';
import { useRestartVorschreibungMailDeliveryDetermineRecipientListGenerierlaufMutation } from '../../../features/Verarbeitung/gql/VorschreibungMailDeliveryDetermineRecipientListGenerierLauf/VorschreibungMailDeliveryDetermineRecipientListGenerierlaufMutations.types';

const VorschreibungMailDeliveryDetermineRecipientListDetailsPage = () => {
  const { generierlaufId } = useParams() as VerarbeitungVorschreibungMailDeliveryDetermineDetailsPageRouteParams;

  const { data, refetch } = useVorschreibungMailDeliveryDetermineRecipientListGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getVorschreibungMailDeliveryDetermineRecipientListGenerierlauf.data;

  const [runRestart] = useRestartVorschreibungMailDeliveryDetermineRecipientListGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<VorschreibungMailDeliveryDetermineRecipientListGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.VorschreibungMailDeliveryDetermineRecipientList}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.VorschreibungMailDeliveryDetermineRecipientList}
          onGenerierlaufRestart={runRestart}
        />
      )}
      isLoading={false}
      sidebarMenuList={menuListVorschreibungMailDeliveryDetermineRecipientList}
    />
  );
};

export default VorschreibungMailDeliveryDetermineRecipientListDetailsPage;
