import React, { FC, useEffect, useState } from 'react';
import { Empty } from 'antd';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { TicketTemplate } from '../../../types';
import TableWithColSelector from '../../../components/Table/TableWithColSelector/TableWithColSelector';
import ticketTemplateTemplateSubTicketTableColumns from './ticketTemplateTemplateSubTicketTableColumns';
import TableDragAndDropSortableRow from '../../../components/Table/TableDragAndDropSortableRow';
import { useMutationHookResultsProvider } from '../TicketTemplateTemplateMutationHookResultProvider';

type Props = {
  ticketTemplateList: TicketTemplate[];
  parentTicketTemplate?: TicketTemplate;
  style?: React.CSSProperties;
};

const TicketTemplateTemplateSubTicketTable: FC<Props> = ({ ticketTemplateList, parentTicketTemplate, style }) => {
  const [subTicketTemplates, setSubTicketTemplates] = useState(ticketTemplateList);

  const {
    runChangePosition: [runChangePosition, { loading: loadingChangePosition }],
  } = useMutationHookResultsProvider();

  useEffect(() => {
    setSubTicketTemplates(ticketTemplateList);
  }, [ticketTemplateList]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setSubTicketTemplates((previous) => {
        const current = subTicketTemplates.find((i) => i.ticketTemplateId === active.id);
        const activeIndex = previous.findIndex((i) => i.ticketTemplateId === active.id);
        const overIndex = previous.findIndex((i) => i.ticketTemplateId === over?.id);

        if (overIndex >= 0) {
          runChangePosition({
            variables: {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              ticketTemplateId: current!.ticketTemplateId,
              input: {
                position: overIndex,
              },
            },
          });
          return arrayMove(previous, activeIndex, overIndex);
        }
        return previous;
      });
    }
  };

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        items={subTicketTemplates.map((i) => i.ticketTemplateId)}
        strategy={verticalListSortingStrategy}
        disabled={loadingChangePosition || subTicketTemplates.length < 2}
      >
        <TableWithColSelector<TicketTemplate>
          size="small"
          locale={{
            emptyText: <Empty description={<span>Keine Unteraufgabenart vorhanden</span>} />,
          }}
          style={style}
          loading={loadingChangePosition}
          dataSource={subTicketTemplates}
          columns={ticketTemplateTemplateSubTicketTableColumns(subTicketTemplates, parentTicketTemplate)}
          rowKey={(record) => record.ticketTemplateId}
          filterIdentifier="ticket-template-template-sub-ticket"
          scroll={{ x: 680 }}
          components={{
            body: {
              row: TableDragAndDropSortableRow,
            },
          }}
        />
      </SortableContext>
    </DndContext>
  );
};

export default TicketTemplateTemplateSubTicketTable;
