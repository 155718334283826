import { RechtstraegerRolle } from '../../../types';

export enum RechtsTraegerRolleMenuItemTitle {
  Rechnungsaussteller = 'Rechnungsaussteller',
  Vertragspartner = 'Vertragspartner',
}

export const hasRechtstraegerRolleWeRechnungsAussteller = (rolleList?: RechtstraegerRolle[]) =>
  rolleList?.includes(RechtstraegerRolle.WeRechnungsAussteller);
export const hasRechtstraegerRolleObjektRechnungsAussteller = (rolleList?: RechtstraegerRolle[]) =>
  rolleList?.includes(RechtstraegerRolle.ObjektRechnungsAussteller);
export const hasRechstraegerRolleVertragspartner = (rolleList?: RechtstraegerRolle[]) => rolleList?.includes(RechtstraegerRolle.Vertragspartner);
