import React from 'react';
import { TableColumnProps, Typography } from 'antd';
import { friendlyFormatIBAN } from 'ibantools';
import { EbicsUserIban } from '../../../../../../types';
import FormattedDateTime from '../../../../../../components/FormattedDateTime/FormattedDateTime';
import DataWithShortenedText from '../../../../../../components/Helpers/DataWithShortenedText';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';
import StatusBadge from '../../../../../../components/Status/StatusBadge';
import EbicsUserIbanActions from './EbicsUserIbanActions';
import { MitarbeiterTooltip } from '../../../../../../components/Card';
import { compareTwoStringsForSorting } from '../../../../../../helpers/stringHelper';
import { compareTwoDatesForSorting } from '../../../../../../helpers/dateHelper';
import { compareTwoBooleansForSorting } from '../../../../../../helpers/booleanHelper';

const ebicsUserIbanTableColumns = (ebicsUserId: string, onAction: () => void): TableColumnProps<EbicsUserIban>[] => [
  {
    title: 'IBAN',
    sorter: (a, b) => compareTwoStringsForSorting(a.iban, b.iban),
    width: 200,
    render: (text, record) => <DataWithShortenedText text={friendlyFormatIBAN(record.iban) as string} maxTextLength={20} />,
  },
  {
    title: 'Berechtigung Lastschrift Elektronische Unterschrift',
    sorter: (a, b) => compareTwoStringsForSorting(a.directDebitPermission?.text, b.directDebitPermission?.text),
    render: (text, record) =>
      record.directDebitPermission ? (
        <Typography.Text>{record.directDebitPermission.text}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Berechtigung Überweisung Elektronische Unterschrift',
    sorter: (a, b) => compareTwoStringsForSorting(a.creditTransferPermission?.text, b.creditTransferPermission?.text),
    render: (text, record) =>
      record.creditTransferPermission ? (
        <Typography.Text>{record.creditTransferPermission.text}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Kontoauszug abholen',
    sorter: (a, b) => compareTwoBooleansForSorting(a.retrieveBankStatement, b.retrieveBankStatement),
    render: (text, record) => <Typography.Text>{record.retrieveBankStatement ? 'Ja' : 'Nein'}</Typography.Text>,
  },
  {
    title: 'Kontonummer',
    sorter: (a, b) => compareTwoStringsForSorting(a.accountNumber, b.accountNumber),
    render: (text, record) =>
      record.accountNumber ? <DataWithShortenedText text={record.accountNumber} maxTextLength={25} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Kontoinhaber',
    sorter: (a, b) => compareTwoStringsForSorting(a.accountHolder, b.accountHolder),
    render: (text, record) => <DataWithShortenedText text={record.accountHolder} maxTextLength={25} />,
  },
  {
    title: 'EBICS Letzter Kontoauszug',
    sorter: (a, b) => compareTwoDatesForSorting(a.lastBankStatementFetchDate, b.lastBankStatementFetchDate),
    render: (text, record) =>
      record.lastBankStatementFetchDate ? (
        <FormattedDateTime createdTs={record.lastBankStatementFetchDate} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'EBICS aktiviert am',
    sorter: (a, b) => compareTwoDatesForSorting(a.activationTs, b.activationTs),
    render: (text, record) => {
      return record.activationTs ? <FormattedDateTime createdTs={record.activationTs} /> : <TextForEmptyValue textToShow="minus" />;
    },
  },
  {
    title: 'IBAN Status',
    sorter: (a, b) => compareTwoStringsForSorting(a.status.text, b.status.text),
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },
  {
    title: 'Erstellt am',
    width: 80,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    width: 80,
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    width: 80,
    render: (text, record) => {
      return <EbicsUserIbanActions ebicsUserIban={record} onAction={onAction} ebicsUserId={ebicsUserId} />;
    },
  },
];

export default ebicsUserIbanTableColumns;
