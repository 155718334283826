import React, { FC } from 'react';
import { Empty } from 'antd';
import { FormikProps } from 'formik';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { BookingSuggestion } from '../../../../../types';
import buchungszeileTableColumns from './buchungszeileTableColumns';
import { BookingSuggestionFormValues, BSBuchungszeileForTable } from '../../Form/buchungsmaskeFormMapper';

type Props = {
  bookingSuggestion: BookingSuggestion;
  buchungszeileList: BSBuchungszeileForTable[];
  formikProps: FormikProps<BookingSuggestionFormValues>;
  onRowDelete: (index: number) => void;
  selectedRow: BSBuchungszeileForTable | undefined;
  setSelectedRow: React.Dispatch<React.SetStateAction<BSBuchungszeileForTable | undefined>>;
  fieldNamePrefix: string;
};

const BuchungszeileTable: FC<Props> = ({
  bookingSuggestion,
  buchungszeileList,
  formikProps,
  onRowDelete,
  selectedRow,
  setSelectedRow,
  fieldNamePrefix,
}) => {
  return (
    <TableWithColSelector<BSBuchungszeileForTable>
      size="small"
      dataSource={buchungszeileList}
      columnArgs={formikProps.values.buchungsanweisung}
      rowKey={(record) => record.buchungszeileId}
      columns={buchungszeileTableColumns(fieldNamePrefix, bookingSuggestion, formikProps, onRowDelete)}
      filterIdentifier="buchungsmaske-buchungszeile"
      rowSelection={{
        type: 'radio',
        selectedRowKeys: selectedRow ? [selectedRow.buchungszeileId] : [],
        onSelect: (record) => setSelectedRow(record),
      }}
      locale={{
        emptyText: <Empty description={<span>Keine Buchungen vorhanden</span>} />,
      }}
      pagination={{
        showSizeChanger: true,
        defaultPageSize: 30,
        pageSizeOptions: [10, 20, 30, 50, 100],
        syncWithUri: false, // syncWithUri is set to false in order to avoid page re-rendering and setting all the formik values to default values upon changing the pagination
      }}
    />
  );
};

export default BuchungszeileTable;
