import { Select, SelectProps } from 'formik-antd';
import { FC } from 'react';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { useFilterReceivedBankTransactionCreditorListQuery } from '../../gql/ReceivedBankTransactionQueries.types';

type Props = Omit<SelectProps, 'id' | 'placeholder' | 'size' | 'allowClear' | 'showSearch' | 'filterOption'>;

const ReceivedBankTransactionCreditorSelect: FC<Props> = ({ name, ...restProps }) => {
  const { data, loading } = useFilterReceivedBankTransactionCreditorListQuery();
  const creditorList = data?.getFilterReceivedBankTransactionCreditorList.data ?? [];

  return (
    <Select
      name={name}
      id={name}
      {...restProps}
      size="small"
      loading={loading}
      placeholder="Kreditor auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {creditorList.map((creditor) => (
        <Select.Option key={creditor} value={creditor}>
          {creditor}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ReceivedBankTransactionCreditorSelect;
