import React, { FC } from 'react';
import { MenuProps, Modal } from 'antd';
import { ContainerOutlined, EyeOutlined, FileDoneOutlined, ScheduleOutlined } from '@ant-design/icons';
import { showConfirmMarkAsNoted, showConfirmReleaseAll } from '../Table/Level 1/paymentBulkActionsConfirmMessages';
import { useToggle } from '../../../../hooks/useToggle';
import CreateForm from '../../PaymentProposal/Form/Create/CreateForm';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { useApprovePaymentBulkActionMutation, useMarkAsNotedPaymentBulkActionMutation } from '../../gql/Order/PaymentMutations.types';
import { showSuccessMsgFreigegeben, showSuccessMsgOther } from '../../../../components/message/message';
import { TPaymentQueryParams } from '../Filters/filtersQueryParams';
import { mapQueryParamsToApproveOrMarkAsNotedPaymentBulkAction } from './paymentTableBulkActionsMapper';
import ActionDropdown from '../../../../components/Dropdown/ActionDropdown';
import ChangePaymentDateForm from '../../Form/ChangeDate/ChangePaymentDateForm';

type Props = {
  onSuccess: () => void;
  queryParams: TPaymentQueryParams;
  excludeList: string[];
};

const PaymentTableBulkActions: FC<Props> = ({ onSuccess, queryParams, excludeList }) => {
  const [isCreatePaymentProposalModalOpen, toggleCreatePaymentProposalModal] = useToggle();
  const [isChangePaymentDateModalOpen, toggleChangePaymentDateModalModal] = useToggle();

  const [runApprovePaymentBulkAction] = useApprovePaymentBulkActionMutation({
    onCompleted: () => {
      showSuccessMsgFreigegeben('Zahlungen');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const handleApproveAll = () => {
    runApprovePaymentBulkAction({
      variables: {
        input: { ...mapQueryParamsToApproveOrMarkAsNotedPaymentBulkAction(queryParams, excludeList) },
      },
    });
  };

  const [runMarkAsNoted] = useMarkAsNotedPaymentBulkActionMutation({
    onCompleted: () => {
      showSuccessMsgOther('Zahlungen als gesehen markiert');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const markAsNoted = () => {
    runMarkAsNoted({
      variables: {
        input: { ...mapQueryParamsToApproveOrMarkAsNotedPaymentBulkAction(queryParams, excludeList) },
      },
    });
    onSuccess();
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Zahlungsvorschlag erstellen',
      onClick: toggleCreatePaymentProposalModal,
      icon: <ContainerOutlined />,
    },
    {
      key: '2',
      label: 'Alle als gesehen markieren',
      onClick: () => showConfirmMarkAsNoted(markAsNoted),
      icon: <EyeOutlined />,
    },
    {
      key: '3',
      label: 'Alle freigeben',
      onClick: () => showConfirmReleaseAll(handleApproveAll),
      icon: <FileDoneOutlined />,
    },
    {
      key: '4',
      label: 'Zahlungsdatum ändern',
      onClick: toggleChangePaymentDateModalModal,
      icon: <ScheduleOutlined />,
    },
  ];

  const onCreatePaymentProposalActionSuccess = () => {
    toggleCreatePaymentProposalModal();
    onSuccess();
  };

  const onChangePaymentDateActionSuccess = () => {
    toggleChangePaymentDateModalModal();
    onSuccess();
  };

  return (
    <>
      <ActionDropdown items={items} title="Auf Liste anwenden" />
      <Modal
        title="Zahlungsvorschlag erstellen"
        onCancel={toggleCreatePaymentProposalModal}
        open={isCreatePaymentProposalModalOpen}
        destroyOnClose
        maskClosable={false}
        footer={false}
      >
        <CreateForm
          onCancel={toggleCreatePaymentProposalModal}
          onSuccess={onCreatePaymentProposalActionSuccess}
          queryParams={queryParams}
          excludeList={excludeList}
        />
      </Modal>
      <Modal
        title="Zahlungsdatum ändern"
        onCancel={toggleChangePaymentDateModalModal}
        open={isChangePaymentDateModalOpen}
        destroyOnClose
        maskClosable={false}
        footer={false}
      >
        <ChangePaymentDateForm
          onCancel={toggleChangePaymentDateModalModal}
          onSuccess={onChangePaymentDateActionSuccess}
          queryParams={queryParams}
          excludeList={excludeList}
        />
      </Modal>
    </>
  );
};

export default PaymentTableBulkActions;
