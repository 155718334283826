import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EigenbelegMarkUnderReviewMutationVariables = Types.Exact<{
  belegId: Types.Scalars['ID']['input'];
}>;

export type EigenbelegMarkUnderReviewMutation = {
  actionEigenbelegMarkUnderReview: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const EigenbelegMarkUnderReviewDocument = gql`
  mutation EigenbelegMarkUnderReview($belegId: ID!) {
    actionEigenbelegMarkUnderReview(belegId: $belegId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useEigenbelegMarkUnderReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<EigenbelegMarkUnderReviewMutation, EigenbelegMarkUnderReviewMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EigenbelegMarkUnderReviewMutation, EigenbelegMarkUnderReviewMutationVariables>(
    EigenbelegMarkUnderReviewDocument,
    options
  );
}
export type EigenbelegMarkUnderReviewMutationHookResult = ReturnType<typeof useEigenbelegMarkUnderReviewMutation>;
export type EigenbelegMarkUnderReviewMutationResult = Apollo.MutationResult<EigenbelegMarkUnderReviewMutation>;
export type EigenbelegMarkUnderReviewMutationOptions = Apollo.BaseMutationOptions<
  EigenbelegMarkUnderReviewMutation,
  EigenbelegMarkUnderReviewMutationVariables
>;
