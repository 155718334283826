import React from 'react';
import createCtx from '../../../../helpers/createCtx';

export type DocumentInfo = {
  column1: React.ReactNode;
  column2: React.ReactNode;
  column3: React.ReactNode;
  column4: React.ReactNode;
  column5: React.ReactNode;
  column6: React.ReactNode;
  column7: React.ReactNode;
  column8: React.ReactNode;
};

export interface DocumentRowContextProps {
  isLoading: boolean;
  documentInfo?: DocumentInfo;
}

export const [useDocumentRow, DocumentRowContextProvider] = createCtx<DocumentRowContextProps>();
