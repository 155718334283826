import React, { FC } from 'react';
import { Button, Modal } from 'antd';
import ListingTitleWithButtons from '../../../../../components/Listing/ListingTitleWithButtons';
import VorschreibungPositionTransfer from './Transfer/VorschreibungPositionTransfer';

type ObjektVorschreibungPositionListingBtnAndModalProps = {
  objektId: string;
  isCollapsed: boolean;
  onCollapse: () => void;
  showArchived: boolean;
  toggleShowArchived: () => void;
  showZuweisenBtn: boolean;
  refetch: () => void;
};

const ObjektVorschreibungPositionListingBtnAndModal: FC<ObjektVorschreibungPositionListingBtnAndModalProps> = ({
  objektId,
  isCollapsed,
  onCollapse,
  showArchived,
  toggleShowArchived,
  showZuweisenBtn,
  refetch,
}) => {
  return (
    <>
      <ListingTitleWithButtons
        title="Vorschreibungsposition"
        buttons={
          <>
            <Button size="small" onClick={toggleShowArchived}>
              Archivierte Einträge {showArchived ? 'ausblenden' : 'anzeigen'}
            </Button>
            {showZuweisenBtn ? (
              <Button type="primary" size="small" onClick={onCollapse}>
                Vorschreibungsposition zuweisen
              </Button>
            ) : null}
          </>
        }
      />
      <Modal
        title="Vorschreibungsposition zuweisen"
        open={isCollapsed}
        onCancel={onCollapse}
        footer={null}
        destroyOnClose
        maskClosable={false}
        width={720}
      >
        <VorschreibungPositionTransfer
          objektId={objektId}
          onOk={() => {
            refetch();
            onCollapse();
          }}
          onCancel={onCollapse}
        />
      </Modal>
    </>
  );
};

export default ObjektVorschreibungPositionListingBtnAndModal;
