import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { InfoMailDeliveryFieldsFragmentDoc } from '../../../Wizard/InfoMailDelivery/gql/InfoMailDeliveryWizardFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InfoMailDeliveryQueryVariables = Types.Exact<{
  infoMailDeliveryId: Types.Scalars['ID']['input'];
}>;

export type InfoMailDeliveryQuery = {
  getInfoMailDelivery: {
    data: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      emailAddress: string;
      emailDeliveryDefinitionBezeichnung: string;
      emailDeliveryDefinitionId: string;
      infoMailDeliveryId: string;
      mailId?: string | null;
      original: boolean;
      sender?: string | null;
      sentTs?: string | null;
      shouldSend: boolean;
      resentDeliveryList?: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        emailAddress: string;
        emailDeliveryDefinitionBezeichnung: string;
        emailDeliveryDefinitionId: string;
        infoMailDeliveryId: string;
        mailId?: string | null;
        original: boolean;
        sender?: string | null;
        sentTs?: string | null;
        shouldSend: boolean;
        objektTopVertrag: {
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          createTs: string;
          name: string;
          bestandseinheit: {
            bestandseinheitId: string;
            bezeichnung: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            createTs: string;
            objektId: string;
            status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          objekt: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            erstelltAm: string;
            fotoFileId?: string | null;
            fotoType?: Types.FotoType | null;
            kurzBezeichnung: string;
            objektId: string;
            rechnungsAusstellerId: string;
            verwaltungBis?: string | null;
            status: { text: string; value: Types.ObjektStatus; description?: string | null };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          vertrag: {
            bestandseinheitId: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            createTs: string;
            kurzBezeichnung: string;
            objektId: string;
            vertragsBeginn: string;
            vertragId: string;
            vertragspartner: {
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              createTs: string;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              type: { text: string; value: Types.RechtstraegerType };
              status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
            status: { description?: string | null; text: string; value: Types.VertragStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        recipient: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        vertragspartner: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      sourceDelivery?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        emailAddress: string;
        emailDeliveryDefinitionBezeichnung: string;
        emailDeliveryDefinitionId: string;
        infoMailDeliveryId: string;
        mailId?: string | null;
        original: boolean;
        sender?: string | null;
        sentTs?: string | null;
        shouldSend: boolean;
        objektTopVertrag: {
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          createTs: string;
          name: string;
          bestandseinheit: {
            bestandseinheitId: string;
            bezeichnung: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            createTs: string;
            objektId: string;
            status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          objekt: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            erstelltAm: string;
            fotoFileId?: string | null;
            fotoType?: Types.FotoType | null;
            kurzBezeichnung: string;
            objektId: string;
            rechnungsAusstellerId: string;
            verwaltungBis?: string | null;
            status: { text: string; value: Types.ObjektStatus; description?: string | null };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          vertrag: {
            bestandseinheitId: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            createTs: string;
            kurzBezeichnung: string;
            objektId: string;
            vertragsBeginn: string;
            vertragId: string;
            vertragspartner: {
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              createTs: string;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              type: { text: string; value: Types.RechtstraegerType };
              status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
            status: { description?: string | null; text: string; value: Types.VertragStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        recipient: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        vertragspartner: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      objektTopVertrag: {
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        createTs: string;
        name: string;
        bestandseinheit: {
          bestandseinheitId: string;
          bezeichnung: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          createTs: string;
          objektId: string;
          status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        objekt: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          erstelltAm: string;
          fotoFileId?: string | null;
          fotoType?: Types.FotoType | null;
          kurzBezeichnung: string;
          objektId: string;
          rechnungsAusstellerId: string;
          verwaltungBis?: string | null;
          status: { text: string; value: Types.ObjektStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        vertrag: {
          bestandseinheitId: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          createTs: string;
          kurzBezeichnung: string;
          objektId: string;
          vertragsBeginn: string;
          vertragId: string;
          vertragspartner: {
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            createTs: string;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            type: { text: string; value: Types.RechtstraegerType };
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
          status: { description?: string | null; text: string; value: Types.VertragStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      recipient: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      vertragspartner: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
  };
};

export const InfoMailDeliveryDocument = gql`
  query InfoMailDelivery($infoMailDeliveryId: ID!) {
    getInfoMailDelivery(infoMailDeliveryId: $infoMailDeliveryId) {
      data {
        ...InfoMailDeliveryFields
        resentDeliveryList {
          ...InfoMailDeliveryFields
        }
        sourceDelivery {
          ...InfoMailDeliveryFields
        }
      }
    }
  }
  ${InfoMailDeliveryFieldsFragmentDoc}
`;
export function useInfoMailDeliveryQuery(
  baseOptions: Apollo.QueryHookOptions<InfoMailDeliveryQuery, InfoMailDeliveryQueryVariables> &
    ({ variables: InfoMailDeliveryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InfoMailDeliveryQuery, InfoMailDeliveryQueryVariables>(InfoMailDeliveryDocument, options);
}
export function useInfoMailDeliveryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InfoMailDeliveryQuery, InfoMailDeliveryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InfoMailDeliveryQuery, InfoMailDeliveryQueryVariables>(InfoMailDeliveryDocument, options);
}
export function useInfoMailDeliverySuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InfoMailDeliveryQuery, InfoMailDeliveryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InfoMailDeliveryQuery, InfoMailDeliveryQueryVariables>(InfoMailDeliveryDocument, options);
}
export type InfoMailDeliveryQueryHookResult = ReturnType<typeof useInfoMailDeliveryQuery>;
export type InfoMailDeliveryLazyQueryHookResult = ReturnType<typeof useInfoMailDeliveryLazyQuery>;
export type InfoMailDeliverySuspenseQueryHookResult = ReturnType<typeof useInfoMailDeliverySuspenseQuery>;
export type InfoMailDeliveryQueryResult = Apollo.QueryResult<InfoMailDeliveryQuery, InfoMailDeliveryQueryVariables>;
