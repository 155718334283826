import { get } from 'lodash';
import React, { FC } from 'react';
import { FieldArray, FieldArrayRenderProps, FormikProps } from 'formik';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ButtonProps } from 'antd/lib/button';
import { Spacer } from '../../../components/Grid';
import { validateObjectSyncWithSchemaAndReturnErrorsIfAny } from '../../../helpers/validationHelper';
import { Address } from '../../../types';
import { mapAddressToFormValues, STREET_ADDRESS_FORM_INITIAL_VALUES_DEFAULT } from '../../../shared/components/Address/form/addressMapper';
import { PostofficeBoxAddressFormValues, StreetAddressFormValues } from '../../../shared/components/Address/AddressSharedModel';
import AddressWithActionsCardList from './AddressWithActionsCardList';
import AddressFormAddEditItem from '../../../shared/components/Address/form/AddressDynamicFormItem/AddressFormAddEditItem';
import { streetAddressValidationSchema } from '../../../shared/components/Address/form/addressFieldsValidationSchema';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';

type Props = {
  formikProps: FormikProps<any>;
  fieldNamePrefix?: string;
  fieldHelp?: string | null;
};

const AddressListFormPart: FC<Props> = ({ formikProps, fieldHelp, fieldNamePrefix = '' }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'AddressStreet'>('AddressStreet');

  return (
    <FormItemWithFieldHelp label="Adresse" name={`${fieldNamePrefix}addressList`} fieldHelp={fieldHelp}>
      <FieldArray
        name={`${fieldNamePrefix}addressList`}
        render={(arrayHelpers) => {
          const tempShowAddressFormPartItem = get(formikProps.values, tempShowAddressFormPartItemValuesPath(fieldNamePrefix));
          const tempSelectedIndex = get(formikProps.values, tempSelectedIndexValuesPath(fieldNamePrefix));
          const tempAddress = get(formikProps.values, tempAddressValuesPath(fieldNamePrefix));
          const addressList = get(formikProps.values, addressListValuesPath(fieldNamePrefix));
          return (
            <>
              <AddressWithActionsCardList
                addressList={addressList}
                removeAddress={removeAddress(arrayHelpers)}
                getEditFocusOnAddress={selectAddress(fieldNamePrefix, formikProps)}
              />

              <AddAddressButton
                isVisible={isAddAddressButtonVisible(tempShowAddressFormPartItem)}
                addressList={addressList}
                onClick={openAddressFormPartAddEditItem(fieldNamePrefix, formikProps)}
              />
              <AddressFormAddEditItem
                fieldNamePrefix={`${tempAddressValuesPath(fieldNamePrefix)}.`}
                isVisible={tempShowAddressFormPartItem}
                saveAddress={
                  tempSelectedIndex >= 0
                    ? updateAddress(arrayHelpers, fieldNamePrefix, formikProps, tempSelectedIndex, tempAddress)
                    : addAddress(arrayHelpers, addressList, fieldNamePrefix, formikProps, tempAddress)
                }
                resetAddress={resetAddressAndSetListTouched(fieldNamePrefix, formikProps)}
                fieldHelpNames={{
                  city: getFieldHelpText('AddressStreet.city'),
                  cityAdditionalInformation: getFieldHelpText('AddressStreet.cityAdditionalInformation'),
                  countryCodeIso2: getFieldHelpText('AddressStreet.countryCodeIso2'),
                  houseEntranceApartmentNumber: getFieldHelpText('AddressStreet.houseEntranceApartmentNumber'),
                  street: getFieldHelpText('AddressStreet.street'),
                  zipCode: getFieldHelpText('AddressStreet.zipCode'),
                }}
              />
            </>
          );
        }}
      />
    </FormItemWithFieldHelp>
  );
};

export const isAddAddressButtonVisible = (tempShowAddressFormPartItem: Address) => !tempShowAddressFormPartItem;

type AddAddressButtonProps = {
  isVisible: boolean;
  addressList: Address[];
};

const AddAddressButton: FC<AddAddressButtonProps & ButtonProps> = ({ isVisible, addressList, ...restProps }) =>
  isVisible ? (
    <>
      <Spacer />
      <Button icon={<PlusOutlined />} type="dashed" htmlType="button" block {...restProps}>
        {addressList && addressList.length > 0 ? 'Weitere Adresse hinzufügen' : 'Adresse hinzufügen'}
      </Button>
    </>
  ) : null;

const removeAddress = (arrayHelpers: FieldArrayRenderProps) => (index: number) => () => {
  arrayHelpers.remove(index);
};

const selectAddress = (fieldNamePrefix: string, formikProps: FormikProps<any>) => (address: Address, index: number) => () => {
  const { setFieldValue } = formikProps;
  resetAddressAndSetListTouched(fieldNamePrefix, formikProps)();
  initAddress(fieldNamePrefix, formikProps, address);

  setFieldValue(tempSelectedIndexValuesPath(fieldNamePrefix), index);
  setFieldValue(tempShowAddressFormPartItemValuesPath(fieldNamePrefix), true);
};

const resetAddressAndSetListTouched = (fieldNamePrefix: string, formikProps: FormikProps<any>) => () => {
  const { setFieldTouched, setFieldError, setFieldValue } = formikProps;

  setFieldValue(tempAddressValuesPath(fieldNamePrefix), undefined);
  setFieldTouched(tempAddressValuesPath(fieldNamePrefix), false);
  setFieldError(tempAddressValuesPath(fieldNamePrefix), '');

  setFieldValue(tempSelectedIndexValuesPath(fieldNamePrefix), undefined);
  setFieldValue(tempShowAddressFormPartItemValuesPath(fieldNamePrefix), undefined);

  // setFieldTouched as [] on purpose. formik needs it that way, otherwise app explodes when adding second element to list
  // @ts-ignore
  setFieldTouched(addressListValuesPath(fieldNamePrefix), []);
};

// selectedAddress: AddressData
const initAddress = (fieldNamePrefix: string, formikProps: FormikProps<any>, selectedAddress: Address) => {
  const { setFieldValue } = formikProps;

  const address = selectedAddress ? mapAddressToFormValues(selectedAddress) : { ...STREET_ADDRESS_FORM_INITIAL_VALUES_DEFAULT };

  setFieldValue(tempAddressValuesPath(fieldNamePrefix), { ...address });
};

const openAddressFormPartAddEditItem = (fieldNamePrefix: string, formikProps: FormikProps<any>) => () => {
  const { setFieldValue } = formikProps;
  setFieldValue(tempShowAddressFormPartItemValuesPath(fieldNamePrefix), true);
  // @ts-ignore
  initAddress(fieldNamePrefix, formikProps);
};

const addAddress =
  (
    arrayHelpers: FieldArrayRenderProps,
    addressList: Address[],
    fieldNamePrefix: string,
    formikProps: FormikProps<any>,
    tempAddress: StreetAddressFormValues | PostofficeBoxAddressFormValues
  ) =>
  () => {
    setFieldTouchedForAddressFormSelected(`${tempAddressValuesPath(fieldNamePrefix)}.`, formikProps);

    if (isAddressValid(tempAddress)) {
      resetListFieldError(fieldNamePrefix, formikProps);
      arrayHelpers.insert(addressList.length, {
        ...tempAddress,
      });
      resetAddressAndSetListTouched(fieldNamePrefix, formikProps)();
    }
  };

// workaround due to https://github.com/jaredpalmer/formik/issues/1158
const resetListFieldError = (fieldNamePrefix: string, formikProps: FormikProps<any>) => {
  // @ts-ignore
  formikProps.setFieldError(addressListValuesPath(fieldNamePrefix), []);
};

const setFieldTouchedForAddressFormSelected = (fieldNamePrefix: string, formikProps: FormikProps<any>) => {
  const { setFieldTouched } = formikProps;
  setFieldTouched(`${fieldNamePrefix}countryCodeIso2`);
  setFieldTouched(`${fieldNamePrefix}city`);
  setFieldTouched(`${fieldNamePrefix}cityAdditionalInformation`);
  setFieldTouched(`${fieldNamePrefix}zipCode`);
  setFieldTouched(`${fieldNamePrefix}street`);
  setFieldTouched(`${fieldNamePrefix}houseEntranceApartmentNumber`);
};

export const isAddressValid = (address: any) =>
  !validateObjectSyncWithSchemaAndReturnErrorsIfAny(
    // FIXME:
    // @ts-ignore
    { validationSchema: streetAddressValidationSchema, valueToValidate: address }
  );

const updateAddress =
  (
    arrayHelpers: FieldArrayRenderProps,
    fieldNamePrefix: string,
    formikProps: FormikProps<any>,
    selectedIndex: number,
    tempAddress: StreetAddressFormValues | PostofficeBoxAddressFormValues
  ) =>
  () => {
    if (isAddressValid(tempAddress)) {
      arrayHelpers.replace(selectedIndex, { ...tempAddress });
      resetAddressAndSetListTouched(fieldNamePrefix, formikProps)();
    }
  };

const tempAddressValuesPath = (fieldNamePrefix: string) => `${fieldNamePrefix}tempAddress`;
const tempSelectedIndexValuesPath = (fieldNamePrefix: string) => `${fieldNamePrefix}tempSelectedIndex`;
const tempShowAddressFormPartItemValuesPath = (fieldNamePrefix: string) => `${fieldNamePrefix}tempShowAddressFormPartItem`;
const addressListValuesPath = (fieldNamePrefix: string) => `${fieldNamePrefix}addressList`;

export default AddressListFormPart;
