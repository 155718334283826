import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import ObjektBankDetailsActions from './ObjektBankDetailsActions';
import { ObjektBankDetailsVerwendung } from '../../../types';

type Props = {
  objektId: string;
  onSuccess: () => void;
  verwendungListTypeInUse: ObjektBankDetailsVerwendung[];
  rechtstraegerId: string;
  rechtstraegerFibuKontoCreatable?: boolean;
  weRechnungsAusstellerId?: string;
  weRechnungsAusstellerFibuKontoCreatable?: boolean;
};

const ObjektBankDetailsButtonAndDrawer: FC<Props> = ({
  objektId,
  rechtstraegerId,
  onSuccess,
  verwendungListTypeInUse,
  rechtstraegerFibuKontoCreatable,
  weRechnungsAusstellerId,
  weRechnungsAusstellerFibuKontoCreatable,
}) => {
  return (
    <Space style={{ width: '100%', justifyContent: 'space-between' }}>
      <Typography.Title level={5} style={{ marginBottom: 0 }}>
        Bankverbindung
      </Typography.Title>
      <ObjektBankDetailsActions
        objektId={objektId}
        onSuccess={onSuccess}
        rechtstraegerId={rechtstraegerId}
        weRechnungsAusstellerId={weRechnungsAusstellerId}
        verwendungListTypeInUse={verwendungListTypeInUse}
        rechtstraegerFibuKontoCreatable={rechtstraegerFibuKontoCreatable}
        weRechnungsAusstellerFibuKontoCreatable={weRechnungsAusstellerFibuKontoCreatable}
      />
    </Space>
  );
};

export default ObjektBankDetailsButtonAndDrawer;
