import * as Yup from 'yup';
import { entityIsRequired, entityMustBeSelected } from '../../../components/message/validationMsg';
import { indexSeriesFormFields } from './indexSeriesFormMapper';
import { IndexSeriesSourceType } from '../../../types';

export const indexSeriesValidationSchema = Yup.object().shape({
  [indexSeriesFormFields.chainingFactor]: Yup.number().when([indexSeriesFormFields.sourceType], ([sourceType], schema) =>
    sourceType === IndexSeriesSourceType.Import ? schema.required(entityIsRequired('Verkettungsfaktor')) : schema.nullable()
  ),
  [indexSeriesFormFields.csvColumnImportId]: Yup.string().when([indexSeriesFormFields.sourceType], ([sourceType], schema) =>
    sourceType === IndexSeriesSourceType.Import ? schema.required(entityIsRequired('CSV-Spalte Importidentifikator')) : schema.nullable()
  ),
  [indexSeriesFormFields.csvColumnIndexPoint]: Yup.string().when([indexSeriesFormFields.sourceType], ([sourceType], schema) =>
    sourceType === IndexSeriesSourceType.Import ? schema.required(entityIsRequired('CSV-Spalte Index-Wert')) : schema.nullable()
  ),
  [indexSeriesFormFields.csvColumnMonthYear]: Yup.string().when([indexSeriesFormFields.sourceType], ([sourceType], schema) =>
    sourceType === IndexSeriesSourceType.Import ? schema.required(entityIsRequired('CSV-Spalte Monat/Jahr')) : schema.nullable()
  ),
  [indexSeriesFormFields.csvRowImportId]: Yup.string().when([indexSeriesFormFields.sourceType], ([sourceType], schema) =>
    sourceType === IndexSeriesSourceType.Import ? schema.required(entityIsRequired('CSV-Zeileninhalt Importidentifikator')) : schema.nullable()
  ),
  [indexSeriesFormFields.importUrl]: Yup.string().when([indexSeriesFormFields.sourceType], ([sourceType], schema) =>
    sourceType === IndexSeriesSourceType.Import ? schema.required(entityIsRequired('Import Http-Link')) : schema.nullable()
  ),
  [indexSeriesFormFields.indexType]: Yup.string().required(entityMustBeSelected('Typ')),
  [indexSeriesFormFields.name]: Yup.string().required(entityIsRequired('Bezeichnung')),
  [indexSeriesFormFields.sourceType]: Yup.string().required(entityMustBeSelected('Datenquelle')),
});
