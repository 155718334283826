import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../dataChangedSubscription';
import { DataChangedChangeType } from '../../types';

const useOnFirmendatenSettingsDataChangedEvents = (callback: () => Promise<unknown>) => {
  useOnDataChangedEvent([
    {
      filter: {
        type: dataChangedSubscriptionTypes.fibuEinstellung,
        changeType: DataChangedChangeType.Update,
      },
      notificationDescription: 'Fibu Einstellungen wurden gerade verändert',
      key: `fibuEinstellung`,
      callback,
    },
  ]);
};

export { useOnFirmendatenSettingsDataChangedEvents };
