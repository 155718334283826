import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAbrechnungskreisTemplateMutationVariables = Types.Exact<{
  input: Types.AbrechnungskreisCreateInput;
}>;

export type CreateAbrechnungskreisTemplateMutation = {
  createAbrechnungskreisTemplate: {
    data: { abrechnungskreisId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateAbrechnungskreisTemplateMutationVariables = Types.Exact<{
  abrechnungskreisId: Types.Scalars['ID']['input'];
  input: Types.AbrechnungskreisUpdateInput;
}>;

export type UpdateAbrechnungskreisTemplateMutation = {
  updateAbrechnungskreisTemplate: {
    data: { abrechnungskreisId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteAbrechnungskreisTemplateMutationVariables = Types.Exact<{
  abrechnungskreisId: Types.Scalars['ID']['input'];
}>;

export type DeleteAbrechnungskreisTemplateMutation = {
  deleteAbrechnungskreisTemplate: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type ArchiveAbrechnungskreisTemplateMutationVariables = Types.Exact<{
  abrechnungskreisId: Types.Scalars['ID']['input'];
}>;

export type ArchiveAbrechnungskreisTemplateMutation = {
  actionArchiveAbrechnungskreisTemplate: {
    data: { abrechnungskreisId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveAbrechnungskreisTemplateMutationVariables = Types.Exact<{
  abrechnungskreisId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveAbrechnungskreisTemplateMutation = {
  actionUnarchiveAbrechnungskreisTemplate: {
    data: { abrechnungskreisId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const CreateAbrechnungskreisTemplateDocument = gql`
  mutation CreateAbrechnungskreisTemplate($input: AbrechnungskreisCreateInput!) {
    createAbrechnungskreisTemplate(input: $input) {
      data {
        abrechnungskreisId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateAbrechnungskreisTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAbrechnungskreisTemplateMutation, CreateAbrechnungskreisTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAbrechnungskreisTemplateMutation, CreateAbrechnungskreisTemplateMutationVariables>(
    CreateAbrechnungskreisTemplateDocument,
    options
  );
}
export type CreateAbrechnungskreisTemplateMutationHookResult = ReturnType<typeof useCreateAbrechnungskreisTemplateMutation>;
export type CreateAbrechnungskreisTemplateMutationResult = Apollo.MutationResult<CreateAbrechnungskreisTemplateMutation>;
export type CreateAbrechnungskreisTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateAbrechnungskreisTemplateMutation,
  CreateAbrechnungskreisTemplateMutationVariables
>;
export const UpdateAbrechnungskreisTemplateDocument = gql`
  mutation UpdateAbrechnungskreisTemplate($abrechnungskreisId: ID!, $input: AbrechnungskreisUpdateInput!) {
    updateAbrechnungskreisTemplate(abrechnungskreisId: $abrechnungskreisId, input: $input) {
      data {
        abrechnungskreisId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateAbrechnungskreisTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAbrechnungskreisTemplateMutation, UpdateAbrechnungskreisTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAbrechnungskreisTemplateMutation, UpdateAbrechnungskreisTemplateMutationVariables>(
    UpdateAbrechnungskreisTemplateDocument,
    options
  );
}
export type UpdateAbrechnungskreisTemplateMutationHookResult = ReturnType<typeof useUpdateAbrechnungskreisTemplateMutation>;
export type UpdateAbrechnungskreisTemplateMutationResult = Apollo.MutationResult<UpdateAbrechnungskreisTemplateMutation>;
export type UpdateAbrechnungskreisTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateAbrechnungskreisTemplateMutation,
  UpdateAbrechnungskreisTemplateMutationVariables
>;
export const DeleteAbrechnungskreisTemplateDocument = gql`
  mutation DeleteAbrechnungskreisTemplate($abrechnungskreisId: ID!) {
    deleteAbrechnungskreisTemplate(abrechnungskreisId: $abrechnungskreisId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteAbrechnungskreisTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAbrechnungskreisTemplateMutation, DeleteAbrechnungskreisTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAbrechnungskreisTemplateMutation, DeleteAbrechnungskreisTemplateMutationVariables>(
    DeleteAbrechnungskreisTemplateDocument,
    options
  );
}
export type DeleteAbrechnungskreisTemplateMutationHookResult = ReturnType<typeof useDeleteAbrechnungskreisTemplateMutation>;
export type DeleteAbrechnungskreisTemplateMutationResult = Apollo.MutationResult<DeleteAbrechnungskreisTemplateMutation>;
export type DeleteAbrechnungskreisTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteAbrechnungskreisTemplateMutation,
  DeleteAbrechnungskreisTemplateMutationVariables
>;
export const ArchiveAbrechnungskreisTemplateDocument = gql`
  mutation ArchiveAbrechnungskreisTemplate($abrechnungskreisId: ID!) {
    actionArchiveAbrechnungskreisTemplate(abrechnungskreisId: $abrechnungskreisId) {
      data {
        abrechnungskreisId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useArchiveAbrechnungskreisTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveAbrechnungskreisTemplateMutation, ArchiveAbrechnungskreisTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveAbrechnungskreisTemplateMutation, ArchiveAbrechnungskreisTemplateMutationVariables>(
    ArchiveAbrechnungskreisTemplateDocument,
    options
  );
}
export type ArchiveAbrechnungskreisTemplateMutationHookResult = ReturnType<typeof useArchiveAbrechnungskreisTemplateMutation>;
export type ArchiveAbrechnungskreisTemplateMutationResult = Apollo.MutationResult<ArchiveAbrechnungskreisTemplateMutation>;
export type ArchiveAbrechnungskreisTemplateMutationOptions = Apollo.BaseMutationOptions<
  ArchiveAbrechnungskreisTemplateMutation,
  ArchiveAbrechnungskreisTemplateMutationVariables
>;
export const UnarchiveAbrechnungskreisTemplateDocument = gql`
  mutation UnarchiveAbrechnungskreisTemplate($abrechnungskreisId: ID!) {
    actionUnarchiveAbrechnungskreisTemplate(abrechnungskreisId: $abrechnungskreisId) {
      data {
        abrechnungskreisId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveAbrechnungskreisTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveAbrechnungskreisTemplateMutation, UnarchiveAbrechnungskreisTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveAbrechnungskreisTemplateMutation, UnarchiveAbrechnungskreisTemplateMutationVariables>(
    UnarchiveAbrechnungskreisTemplateDocument,
    options
  );
}
export type UnarchiveAbrechnungskreisTemplateMutationHookResult = ReturnType<typeof useUnarchiveAbrechnungskreisTemplateMutation>;
export type UnarchiveAbrechnungskreisTemplateMutationResult = Apollo.MutationResult<UnarchiveAbrechnungskreisTemplateMutation>;
export type UnarchiveAbrechnungskreisTemplateMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveAbrechnungskreisTemplateMutation,
  UnarchiveAbrechnungskreisTemplateMutationVariables
>;
