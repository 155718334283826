import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from '../../../hooks/useToggle';
import { useQueryParams } from '../../../hooks/useStringQueryParams';
import { TKontenplanQueryParams, updateKontenplanQueryParams } from '../../../pages/SysSettingsPage/KundenSystem/KontenplanPage/routes';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../hooks/useQueryWithPagingAndSorting';
import { useKontoListQuery } from '../gql/KontenplanQueries.types';
import KontoListingFilters from './KontoListingFilters';
import KontenplanActionButtonsAndDrawer from './KontenplanActionButtonsAndDrawer';
import KontoTable from './Table/KontoTable';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';

const KontoListing = () => {
  const [showArchived, toggleShowArchived] = useToggle();
  const navigate = useNavigate();
  const queryParams = useQueryParams<TKontenplanQueryParams>();
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, refetch, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useKontoListQuery,
    {
      variables: {
        includeArchiviert: showArchived,
        klasseList: queryParams.klasse ? [queryParams.klasse] : undefined,
        type: queryParams.type?.length === 1 ? queryParams.type[0] : undefined,
        verwendung: queryParams.verwendung,
      },
    },
    {
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );
  const kontoList = data?.getKontoList.data.contentList;

  return (
    <>
      <KontoListingFilters
        actionButtons={<KontenplanActionButtonsAndDrawer refetch={refetch} showArchived={showArchived} toggleShowArchived={toggleShowArchived} />}
        filters={queryParams}
        onChange={(filters) => {
          updateKontenplanQueryParams(navigate, filters);
        }}
      />
      <KontoTable kontoList={kontoList} handleTableSorting={handleTableSorting} loading={loading} pagination={pagination} refetch={refetch} />
    </>
  );
};

export default KontoListing;
