import { Dispatch, PropsWithChildren, useReducer } from 'react';
import createCtx from '../../../helpers/createCtx';

import {
  NestedTableWithColSelectorStateAction,
  nestedTableWithColSelectorState,
  nestedTableWithColSelectorReducer,
  NestedTableWithColSelectorState,
} from './nestedTableWithColSelectorReducer';

const [useNestedTableWithColSelector, NestedTableWithColSelectorContextProvider] = createCtx<{
  state: NestedTableWithColSelectorState;
  dispatch: Dispatch<NestedTableWithColSelectorStateAction>;
}>();
const NestedTableWithColSelectorProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(nestedTableWithColSelectorReducer, nestedTableWithColSelectorState);

  return <NestedTableWithColSelectorContextProvider value={{ state, dispatch }}>{children}</NestedTableWithColSelectorContextProvider>;
};

export { useNestedTableWithColSelector, NestedTableWithColSelectorProvider };
