import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useMahnungStatusListQuery } from '../../gql/MahnungQueries.types';

const MahnungStatusSelect: FC<SelectProps> = ({ name, ...restProps }) => {
  const { data, loading } = useMahnungStatusListQuery();
  const mahnungStatusList = data?.getMahnungStatusList.data;

  return (
    <Select
      size="small"
      id={name}
      name={name}
      loading={loading}
      placeholder="Status auswählen"
      mode="multiple"
      style={{ minWidth: '200px' }}
      allowClear
      {...restProps}
    >
      {mahnungStatusList?.map((status) => (
        <Select.Option key={status.value} value={status.value}>
          {status.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default MahnungStatusSelect;
