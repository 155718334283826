import React, { FC, useEffect, useState } from 'react';
import { Collapse, Space, Spin, Typography } from 'antd';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { StandingInvoiceUpdateInput } from '../../../../../../types';
import { useToggle } from '../../../../../../hooks/useToggle';
import { useStandingInvoiceStornoBuchungPreviewMutation } from '../../../../gql/StandingInvoiceMutations.types';
import BookingPreviewTable from '../../shared/StandingInvoice/BookingPreviewTable';

type Props = {
  belegId: string;
  input: StandingInvoiceUpdateInput;
  disabled?: boolean;
};

const StornoBookingPreviewDetails: FC<Props> = ({ belegId, input }) => {
  const [open, setOpen] = useToggle(false);
  const [key, setKey] = useState<number>(-1);

  const [runGetBookingPreview, { loading, data }] = useStandingInvoiceStornoBuchungPreviewMutation({
    variables: { belegId, input },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  useEffect(() => {
    if (!open) return;
    runGetBookingPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, open]);

  const previewData = data?.standingInvoiceStornoBuchungPreview.data;

  return (
    <Collapse
      ghost
      activeKey={key}
      accordion
      onChange={(key) => {
        setOpen(!!key);
        setKey(Number(key));
      }}
    >
      <Collapse.Panel key={1} header="Buchungen Vorschau">
        {!previewData ? (
          <Spin />
        ) : (
          <>
            <Space direction="vertical" size={30} style={{ width: '100%' }}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Typography.Title level={5}>Originalbuchung</Typography.Title>
                <Space direction="vertical" size={20} style={{ width: '100%' }}>
                  <BookingPreviewTable data={previewData.originalBuchungList} loading={loading} />
                </Space>
              </Space>

              <Space direction="vertical" style={{ width: '100%' }}>
                <Typography.Title level={5}>Stornobuchung</Typography.Title>
                <Space direction="vertical" size={20} style={{ width: '100%' }}>
                  <BookingPreviewTable data={previewData.stornoBuchungList} loading={loading} />
                </Space>
              </Space>

              {previewData.korrekturBuchungList && previewData.korrekturBuchungList.length > 0 && (
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Typography.Title level={5}>Korrekturbuchung</Typography.Title>
                  <BookingPreviewTable data={previewData.korrekturBuchungList} loading={loading} />
                </Space>
              )}
            </Space>
          </>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};

export default StornoBookingPreviewDetails;
