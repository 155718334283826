import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { BuchungEinnahme } from '../../../objektAbrechnung/objektAbrechnung-types';

const InfoAbrKreisEinnahmeList: FC<{ buchungEinnahmenList: BuchungEinnahme[] }> = ({ buchungEinnahmenList }) => (
  <View style={[pdfStyles.row, pdfStyles.tablePaddingLeftRight, { marginBottom: '2mm' }]}>
    <View style={[pdfStyles.column, { width: '55%' }]} />

    <View style={[pdfStyles.borderTopSmall, { width: '45%', borderTopWidth: 2 }]}>
      {buchungEinnahmenList.map((buchungEinnahme, index) => (
        <View style={[pdfStyles.row, { paddingTop: '1mm' }]} key={index}>
          <Text style={[pdfStyles.textBulletinSmall, { width: '80%' }]}>{buchungEinnahme.bezeichnung}</Text>
          <Text style={[pdfStyles.textBulletinSmall, { width: '20%', alignItems: 'flex-end', textAlign: 'right' }]}>
            {buchungEinnahme.betragNetto}
          </Text>
        </View>
      ))}
    </View>
  </View>
);

export default InfoAbrKreisEinnahmeList;
