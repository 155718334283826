import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { FibuKontoFieldsFragmentDoc } from '../../Kontenplan/gql/KontenplanFragments.types';
import { FibuObjektFragmentDoc } from '../../Objekt/gql/ObjektFragments.types';
export type FibuBuchungszeileFieldsFragment = {
  ausgezifferterBetrag?: number | null;
  betrag: number;
  buchungId: string;
  buchungsKZ?: string | null;
  buchungsanweisungId: string;
  buchungskreisBezeichnung: string;
  buchungskreisId: string;
  buchungszeileId: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  fachlichePruefung?: boolean | null;
  heVertragId?: string | null;
  offenePostenId?: string | null;
  offenerBetrag?: string | null;
  offenerOpBetrag?: number | null;
  steuer?: number | null;
  steuersatz?: number | null;
  storniert: boolean;
  stornierteBuchungBezeichnung?: string | null;
  stornierteBuchungId?: string | null;
  text: string;
  umbuchung?: boolean | null;
  umgebucht?: boolean | null;
  updatedByMitarbeiterId?: string | null;
  ustVomAufwandSteuersatz?: number | null;
  vertragId?: string | null;
  vertragKurzbezeichnung?: string | null;
  heVertragKurzbezeichnung?: string | null;
  bestandseinheit?: { bestandseinheitId: string; bezeichnung?: string | null } | null;
  buchungsKopf: {
    auftragId?: string | null;
    belegDatum?: string | null;
    belegFileId?: string | null;
    belegId?: string | null;
    belegnummer?: string | null;
    buchungsdatum: string;
    buchungskreisRechtstraegerId: string;
    dataCarrierBelegId?: string | null;
    dueDate?: string | null;
    iban?: string | null;
    standingInvoicePartialAmountId?: string | null;
    statementNumber?: string | null;
    vertragspartnerId?: string | null;
    belegSymbol: { abbreviation: string; text: string; value: Types.FibuBelegSymbol };
    buchungsType: { text: string; value: Types.FibuBuchungType };
    waehrung: { text: string; value: Types.Waehrung };
  };
  gegenKonto?: {
    bezeichnung: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    haupt: boolean;
    kontoId: string;
    kontoTemplateId?: string | null;
    nummer: string;
    tagList: Array<string>;
    updatedByMitarbeiterId?: string | null;
    klasse: { text: string; value: Types.KontoKlasse };
    status: { description?: string | null; text: string; value: Types.KontoStatus };
    type: { text: string; value: Types.KontoType };
    verwendung?: { text: string; value: Types.KontoVerwendung } | null;
  } | null;
  konto: {
    bezeichnung: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    haupt: boolean;
    kontoId: string;
    kontoTemplateId?: string | null;
    nummer: string;
    tagList: Array<string>;
    updatedByMitarbeiterId?: string | null;
    klasse: { text: string; value: Types.KontoKlasse };
    status: { description?: string | null; text: string; value: Types.KontoStatus };
    type: { text: string; value: Types.KontoType };
    verwendung?: { text: string; value: Types.KontoVerwendung } | null;
  };
  objekt?: { kurzBezeichnung: string; objektId: string } | null;
  sollHaben: { text: string; value: Types.SollHaben };
  source: { text: string; value: Types.BuchungSource };
};

export const FibuBuchungszeileFieldsFragmentDoc = gql`
  fragment FibuBuchungszeileFields on FibuBuchungszeile {
    ausgezifferterBetrag
    bestandseinheit {
      bestandseinheitId
      bezeichnung
    }
    betrag
    buchungId
    buchungsKZ
    buchungsKopf {
      auftragId
      belegDatum
      belegFileId
      belegId
      belegSymbol {
        abbreviation
        text
        value
      }
      belegnummer
      buchungsType {
        text
        value
      }
      buchungsdatum
      buchungskreisRechtstraegerId
      dataCarrierBelegId
      dueDate
      iban
      standingInvoicePartialAmountId
      statementNumber
      vertragspartnerId
      waehrung {
        text
        value
      }
    }
    buchungsanweisungId
    buchungskreisBezeichnung
    buchungskreisId
    buchungszeileId
    createTs
    createdBy
    createdByMitarbeiterId
    fachlichePruefung
    gegenKonto {
      ...FibuKontoFields
    }
    heVertragId
    konto {
      ...FibuKontoFields
    }
    objekt {
      ...FibuObjekt
    }
    offenePostenId
    offenerBetrag
    offenerOpBetrag
    sollHaben {
      text
      value
    }
    source {
      text
      value
    }
    steuer
    steuersatz
    storniert
    stornierteBuchungBezeichnung
    stornierteBuchungId
    text
    umbuchung
    umgebucht
    updatedByMitarbeiterId
    ustVomAufwandSteuersatz
    vertragId
    vertragKurzbezeichnung
    heVertragKurzbezeichnung
  }
  ${FibuKontoFieldsFragmentDoc}
  ${FibuObjektFragmentDoc}
`;
