import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Radio } from 'formik-antd';
import { objAbrDefGeneralSettingsFormFields, ObjAbrDefGeneralSettingsFormValues } from './objAbrDefGeneralSettingsFormMapper';
import ParamFormItem from '../../../../../../../components/Form/ParamFormItem';
import { BuchungsdetailsType } from '../../../../../../../types';

type Props = {
  formikProps: FormikProps<ObjAbrDefGeneralSettingsFormValues>;
  isDisabled: boolean;
  onChange?: (formValues: ObjAbrDefGeneralSettingsFormValues) => void;
  fieldHelp?: string | null;
};

const BuchungsdetailsFormItem: FC<Props> = ({ formikProps, isDisabled, onChange, fieldHelp }) => (
  <ParamFormItem name={objAbrDefGeneralSettingsFormFields.buchungsdetails} label="Buchungsdetails" marginBottom="16px" fieldHelp={fieldHelp}>
    <Radio.Group
      name={objAbrDefGeneralSettingsFormFields.buchungsdetails}
      onChange={(value) => onChange?.({ ...formikProps.values, buchungsdetails: value.target.value })}
      disabled={isDisabled}
      block
    >
      <Radio name={objAbrDefGeneralSettingsFormFields.buchungsdetails} value={BuchungsdetailsType.DisplayBuchungszeilen}>
        Buchungszeilen anzeigen
      </Radio>
      <Radio name={objAbrDefGeneralSettingsFormFields.buchungsdetails} value={BuchungsdetailsType.BuchungSumPerAccount}>
        Buchungssumme je Konto
      </Radio>
    </Radio.Group>
  </ParamFormItem>
);

export default BuchungsdetailsFormItem;
