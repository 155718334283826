import React, { FC } from 'react';
import { Button, Drawer, Tabs, TabsProps } from 'antd';
import { EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { Box } from 'rebass';
import VorschreibungpositionTemplateForm from '../../Form/VorschreibungpositionTemplateForm';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../../components/Button/ButtonsAligned';
import { Vorschreibungsposition } from '../../../../types';
import VorschreibungspositionTemplateVersionTimeline from '../../Version/VorschreibungspositionTemplateVersionTimeline';

type Props = {
  isCollapsed: boolean;
  onCollapse: () => void;
  onAction: () => void;
  vorschreibungsposition: Vorschreibungsposition;
};

const VorschreibungspositionTemplateEditDrawer: FC<Props> = ({ isCollapsed, onCollapse, onAction, vorschreibungsposition }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span>
          <HistoryOutlined />
          Verlauf
        </span>
      ),
      children: (
        <Box p={16}>
          <VorschreibungspositionTemplateVersionTimeline
            vorschreibungsposition={vorschreibungsposition}
            reloadVorschreibungsPositionList={onAction}
          />
        </Box>
      ),
    },
    {
      key: '2',
      label: (
        <span>
          <EditOutlined />
          Bearbeiten
        </span>
      ),
      children: (
        <Box p={16}>
          <VorschreibungpositionTemplateForm
            vorschreibungsposition={vorschreibungsposition}
            onSuccess={() => {
              onCollapse();
              onAction();
            }}
            onCancel={onCollapse}
          />
        </Box>
      ),
    },
  ];

  return (
    <Drawer
      title="Vorschreibungsposition bearbeiten"
      width={720}
      onClose={onCollapse}
      open={isCollapsed}
      destroyOnClose
      footer={
        <ButtonsAligned usage={ButtonsAlignedUsage.drawerFooter}>
          <Button onClick={onCollapse} type="primary">
            Schließen
          </Button>
        </ButtonsAligned>
      }
    >
      <Tabs defaultActiveKey="2" items={items} />
    </Drawer>
  );
};

export default VorschreibungspositionTemplateEditDrawer;
