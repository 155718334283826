import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { SepaMandatFieldsFragmentDoc } from './SepaMandatFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSepaMandatMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  bankDetailsId: Types.Scalars['ID']['input'];
  input: Types.SepaMandatCreateInput;
}>;

export type CreateSepaMandatMutation = {
  createSepaMandat: {
    data: {
      fileId?: string | null;
      fileName?: string | null;
      sepaMandatId: string;
      sepaMandatReference: string;
      signatureDate?: string | null;
      firmendaten?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firmendatenId: string;
        kundenSystemId: string;
        kurzBezeichnung: string;
        name1: string;
        name2?: string | null;
        name3?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechnungsAussteller?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
      warningList: Array<{ message: string; type: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const CreateSepaMandatDocument = gql`
  mutation createSepaMandat($rechtstraegerId: ID!, $bankDetailsId: ID!, $input: SepaMandatCreateInput!) {
    createSepaMandat(rechtstraegerId: $rechtstraegerId, bankDetailsId: $bankDetailsId, input: $input) {
      data {
        ...SepaMandatFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${SepaMandatFieldsFragmentDoc}
`;
export function useCreateSepaMandatMutation(baseOptions?: Apollo.MutationHookOptions<CreateSepaMandatMutation, CreateSepaMandatMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSepaMandatMutation, CreateSepaMandatMutationVariables>(CreateSepaMandatDocument, options);
}
export type CreateSepaMandatMutationHookResult = ReturnType<typeof useCreateSepaMandatMutation>;
export type CreateSepaMandatMutationResult = Apollo.MutationResult<CreateSepaMandatMutation>;
export type CreateSepaMandatMutationOptions = Apollo.BaseMutationOptions<CreateSepaMandatMutation, CreateSepaMandatMutationVariables>;
