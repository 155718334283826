export const concatFirstNameLetters = (firstname?: string | null, lastname?: string | null): string =>
  (firstname ? firstname.charAt(0) : '') + (lastname ? lastname.charAt(0) : '');

export const concatFullName = (firstname?: string | null, lastname?: string | null): string =>
  firstname && lastname ? `${firstname} ${lastname}` : '';

/* makes first letter in every words of phrase uppercase */
export const toTitleCase = (phrase: string): string => {
  const match = phrase.match(/(?<=(\s|^))[a-z]/gi);
  return match ? match.join('').toUpperCase() : '';
};
