import React, { FC } from 'react';
import VersionTimeline from '../../../../components/Timeline/VersionTimeline';
import { showSuccessMsgDelete } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { UstKategorieStatus, UstRegelset } from '../../../../types';
import { mapToVersionTimelineDataSourceWithAlwaysDeletableEntries } from '../../../../components/Timeline/versionTimelineHelper';
import { useUstRegelsetTemplateUstKategorieZuweisungListQuery } from './gql/UstRegelsetTemplateUstKategorieQueries.types';
import { useDeleteUstRegelsetTemplateUstKategorieZuweisungMutation } from './gql/UstRegelsetTemplateUstKategorieMutations.types';
import { UstRegelsetUstKategorieTimelineDataSourceEntry } from '../../UstRegelset/Version/ustRegelsetVersionTimelineMapper';
import UstRegelsetTemplateVersionForm from './Form/UstRegelsetTemplateVersionForm';
import UstRegelsetTemplateVersionCardContent from './UstRegelsetTemplateVersionCardContent';

type Props = {
  ustRegelset: UstRegelset;
  reloadUstRegelset: () => void;
};

const UstRegelsetTemplateVersionTimeline: FC<Props> = ({ ustRegelset, reloadUstRegelset }) => {
  const { data, refetch: reloadUstRegelsetUstKategorieVersionList } = useUstRegelsetTemplateUstKategorieZuweisungListQuery({
    variables: {
      ustRegelsetId: ustRegelset.ustRegelsetId,
    },
  });
  const ustRegelsetVersionListData = data?.getUstRegelsetTemplateUstKategorieZuweisungList.data;
  const ustRegelsetVersionList = ustRegelsetVersionListData ?? [];
  const loading = !ustRegelsetVersionListData;

  const onActionSuccess = () => {
    reloadUstRegelsetUstKategorieVersionList();
    reloadUstRegelset();
  };

  const [runDelete] = useDeleteUstRegelsetTemplateUstKategorieZuweisungMutation({
    onCompleted: () => {
      showSuccessMsgDelete(`USt.-Kategoriezwuweisung`);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <VersionTimeline<UstRegelsetUstKategorieTimelineDataSourceEntry>
      dataSource={mapToVersionTimelineDataSourceWithAlwaysDeletableEntries(ustRegelsetVersionList)}
      versionIdKey="ustRegelsetKategorieZuweisungId"
      loading={loading}
      defaultNewTimeblock={{
        ustRegelsetKategorieZuweisungId: '',
        ustKategorie: {
          createTs: '',
          createdBy: '',
          createdByMitarbeiterId: null,
          status: {
            text: '',
            value: UstKategorieStatus.Aktiv,
          },
          ustKategorieId: '',
          kurzBezeichnung: '',
          ustKategorieEntryList: [],
          warningList: [],
        },
        warningList: [],
      }}
      renderForm={(timeblock) => (
        <UstRegelsetTemplateVersionForm ustRegelsetId={ustRegelset.ustRegelsetId} timeblock={timeblock} onSubmitSuccess={onActionSuccess} />
      )}
      isAllowedToDeleteInitialTimeblock
      onDelete={(timeblock) =>
        runDelete({
          variables: {
            ustRegelsetId: ustRegelset.ustRegelsetId,
            ustRegelsetUstKategorieZuweisungId: timeblock.ustRegelsetKategorieZuweisungId,
          },
        })
      }
      renderCardContent={(timeblock) => <UstRegelsetTemplateVersionCardContent timeblock={timeblock} />}
    />
  );
};

export default UstRegelsetTemplateVersionTimeline;
