import React from 'react';
import { useParams } from 'react-router-dom';
import VorsteuerkuerzungCard from '../../features/Vorsteuerkuerzung/Card/VorsteuerkuerzungCard';
import DetailsPageTemplate from '../../components/Template/DetailsPageTemplate';
import menuListVorsteuerkuerzung from './menuListVorsteuerkuerzung';
import { useVorsteuerkuerzungQuery } from '../../features/Vorsteuerkuerzung/gql/VorsteuerkuerzungQueries.types';
import { VstKuerzungDetailsPageRouteParams } from './routes';
import { useOnVstKuerzungDataChangedEvent } from '../../features/Vorsteuerkuerzung/useOnVstKuerzungDataChangedEvent';
import { Vorsteuerkuerzung } from '../../types';

const VorsteuerkuerzungDetailsPage = () => {
  const { objektId, vorsteuerkuerzungId } = useParams() as VstKuerzungDetailsPageRouteParams;
  const { data, refetch } = useVorsteuerkuerzungQuery({ variables: { objektId, vorsteuerkuerzungId } });
  const vstKuerzung = data?.getVorsteuerkuerzung.data;

  useOnVstKuerzungDataChangedEvent('vorsteuerkuerzungDetails', refetch);

  return (
    <DetailsPageTemplate<Vorsteuerkuerzung>
      pageTitle={() => 'Vorsteuerkürzung'}
      data={vstKuerzung}
      card={(vstKuerzung) => <VorsteuerkuerzungCard vstKuerzung={vstKuerzung} objektId={objektId} onAction={refetch} />}
      sidebarMenuList={(vstKuerzung) => menuListVorsteuerkuerzung(objektId, vstKuerzung, refetch)}
    />
  );
};

export default VorsteuerkuerzungDetailsPage;
