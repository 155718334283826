import { Tag, TagProps } from 'antd';
import React, { FC } from 'react';
import theme from '../../../../../theme';
import { HeVertragAuszahlungStatus, HeVertragAuszahlungStatusTuple } from '../../../../../types';

type Props = {
  status: HeVertragAuszahlungStatusTuple;
} & TagProps;

const ZahlungsverlaufStatusTag: FC<Props> = ({ status }) => {
  return (
    <Tag color={getStatusTagColor(status.value)} style={{ color: getFontColorForTag(status.value) }}>
      {status.text}
    </Tag>
  );
};

const getFontColorForTag = (status: HeVertragAuszahlungStatus) => {
  switch (status) {
    case HeVertragAuszahlungStatus.Zahlungsvorschlag:
    case HeVertragAuszahlungStatus.Erstellt:
      return theme.colors.black;
    case HeVertragAuszahlungStatus.Durchgefuehrt:
    case HeVertragAuszahlungStatus.NichtDurchgefuehrt:
    case HeVertragAuszahlungStatus.InPlanung:
    default:
      return theme.colors.white;
  }
};

const getStatusTagColor = (status: HeVertragAuszahlungStatus) => {
  switch (status) {
    case HeVertragAuszahlungStatus.Durchgefuehrt:
      return theme.colors.success;
    case HeVertragAuszahlungStatus.NichtDurchgefuehrt:
      return theme.colors.danger;
    case HeVertragAuszahlungStatus.InPlanung:
      return theme.colors.blue;
    case HeVertragAuszahlungStatus.Zahlungsvorschlag:
    case HeVertragAuszahlungStatus.Erstellt:
    default:
      return theme.colors.grey;
  }
};

export default ZahlungsverlaufStatusTag;
