import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { VRechnungEmpfaenger } from '../../vorschreibung-types';
import { ausstellerEmpfaengerBlockColumnWidthsAndAlignment } from './rechnung-info-styles';

const VTRechnungEmpfaengerBlock: FC<{ rechnungEmpfaenger: VRechnungEmpfaenger }> = ({ rechnungEmpfaenger }) => (
  <View style={[pdfStyles.row, { marginTop: '10mm' }]}>
    <Text style={[pdfStyles.column, pdfStyles.textNormal, { width: ausstellerEmpfaengerBlockColumnWidthsAndAlignment.column1.width }]}>
      {rechnungEmpfaenger.bezeichnung.label}
    </Text>
    <View style={[pdfStyles.column, { width: ausstellerEmpfaengerBlockColumnWidthsAndAlignment.column2.width }]}>
      <Text style={pdfStyles.textNormal}>{rechnungEmpfaenger.bezeichnung.value}</Text>
      <Text style={pdfStyles.textNormal}>{rechnungEmpfaenger.addressLine}</Text>
      <Text style={pdfStyles.textNormal}>{rechnungEmpfaenger.zipCodeAndCity}</Text>
    </View>

    <View
      style={[
        pdfStyles.column,
        {
          width: ausstellerEmpfaengerBlockColumnWidthsAndAlignment.column3.width,
          textAlign: ausstellerEmpfaengerBlockColumnWidthsAndAlignment.column3.textAlign,
        },
      ]}
    >
      <Text style={pdfStyles.textNormal}>
        {rechnungEmpfaenger.kundennummer.label} {rechnungEmpfaenger.kundennummer.value}
      </Text>
      <Text style={pdfStyles.textNormal}>
        {rechnungEmpfaenger.vatIdentificationNumber?.label} {rechnungEmpfaenger.vatIdentificationNumber?.value}
      </Text>
    </View>
  </View>
);

export default VTRechnungEmpfaengerBlock;
