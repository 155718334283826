import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useRechtstraegerAddressesAndContactsQuery } from '../../../features/Rechtstraeger/AddressesAndContacts/gql/AddressesAndContactsQueries.types';

type Props = { rechtstraegerId?: string } & SelectProps;

const FirmaContactPersonSelect: FC<Props> = ({ rechtstraegerId, name, ...restProps }) => {
  const { data, loading } = useRechtstraegerAddressesAndContactsQuery({
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      rechtstraegerId: rechtstraegerId!,
    },
    skip: !rechtstraegerId,
  });

  const firma = data?.getRechtstraegerAddressesAndContacts.data;

  return (
    <Select name={name} loading={loading} id={name} allowClear size="small" style={{ minWidth: '160px' }} {...restProps}>
      {firma?.contactPersonList.map((contactPerson) => (
        <Select.Option value={contactPerson.contactPersonId} key={contactPerson.contactPersonId}>
          {contactPerson.firstname} {contactPerson.lastname && `, ${contactPerson.lastname}`}
        </Select.Option>
      ))}
    </Select>
  );
};

export default FirmaContactPersonSelect;
