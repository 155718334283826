import React from 'react';
import { DeleteOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Modal, Tooltip } from 'antd';
import { isStatusGesperrt } from '../../helpers/statusHelper';

const lockText = <span>Benutzer sperren</span>;
const deleteText = <span>Benutzer löschen</span>;

const showConfirmLock = (user) => {
  Modal.confirm({
    title: isStatusGesperrt(user.status) ? `Möchten Sie den Benutzer entsperren?` : `Möchten Sie den Benutzer sperren?`,
    content: `${user.firstname} ${user.lastname}`,
    okText: isStatusGesperrt(user.status) ? 'Entsperren' : 'Sperren',
    cancelText: 'Nein',
  });
};

const showConfirmDelete = (user) => {
  Modal.confirm({
    title: `Möchten Sie den Benutzer löschen?`,
    content: `${user.firstname} ${user.lastname}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
  });
};

const UserStateActionsAntd = ({ user }) => (
  <Button.Group>
    <Tooltip placement="top" title={lockText}>
      <Button size="default" icon={<LockOutlined />} onClick={() => showConfirmLock(user)}>
        Sperren
      </Button>
    </Tooltip>
    <Tooltip placement="top" title={deleteText}>
      <Button size="default" icon={<DeleteOutlined />} onClick={() => showConfirmDelete(user)}>
        Löschen
      </Button>
    </Tooltip>
  </Button.Group>
);

export default UserStateActionsAntd;
