import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import { HeOrSubAbrDefOutputOptionsFieldHelpNames } from '../../../../Abrechnungsdefinition/abrDefFieldHelpHelpers/abrDefFieldHelpMapper';
import { HeOrSubAbrDefGeneralSettingsFormValues } from '../../../../Abrechnungsdefinition/shared/Templates/shared/HeOrSub/GeneralSettingForm/heOrSubAbrDefGeneralSettingsFormMapper';
import HeOrSubGeneralSettingsForm from '../../../../Abrechnungsdefinition/shared/Templates/shared/HeOrSub/GeneralSettingForm/HeOrSubGeneralSettingsForm';
import TemplateSubAbrechnung from './TemplateSubAbrechnung';

type Props = {
  subAbrDefGeneralSettingsValues: HeOrSubAbrDefGeneralSettingsFormValues;
  setSubAbrDefGeneralSettingsValues: (values: HeOrSubAbrDefGeneralSettingsFormValues) => void;
  subAbrDefTemplateValues: TextbausteinFormValues[];
  onTemplateChange: (templateValues: TextbausteinFormValues[]) => void;
  fieldHelpNames?: HeOrSubAbrDefOutputOptionsFieldHelpNames;
};

const SubAbrDefTemplateVersionUpdateTabWithTemplate: FC<Props> = ({
  subAbrDefGeneralSettingsValues,
  setSubAbrDefGeneralSettingsValues,
  subAbrDefTemplateValues,
  onTemplateChange,
  fieldHelpNames,
}) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Allgemeine Einstellungen',
      children: (
        <HeOrSubGeneralSettingsForm
          values={subAbrDefGeneralSettingsValues}
          onSubmit={setSubAbrDefGeneralSettingsValues}
          fieldHelpNames={fieldHelpNames}
        />
      ),
    },
    {
      key: '2',
      label: 'Texte',
      children: (
        <TemplateSubAbrechnung
          values={subAbrDefTemplateValues}
          onTemplateChange={onTemplateChange}
          abrDefGeneralSettingsValues={subAbrDefGeneralSettingsValues}
        />
      ),
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey="Allgemeine Einstellungen" items={items} />
    </>
  );
};

export default SubAbrDefTemplateVersionUpdateTabWithTemplate;
