import React, { FC } from 'react';
import { Typography } from 'antd';
import VatIdentificationNumber from '../../../../shared/components/VatIdentificationNumber/VatIdentificationNumber';
import CompanyRegistrationNumber from '../../../../shared/components/CompanyRegistrationNumber/CompanyRegistrationNumber';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import { Firma } from '../../../../types';

type FirmaDetailedInfoProps = {
  firma: Firma;
};

const FirmaDetailedInfo: FC<FirmaDetailedInfoProps> = ({ firma }) => (
  <>
    <Typography.Title level={5}>Information</Typography.Title>
    <CardDetailsInfoRow infoRowTitle="Kundennummer">
      <Typography.Text>{firma.kundennummer}</Typography.Text>
    </CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle="Rechtsform">
      <Typography.Text>{firma.legalForm.text}</Typography.Text>
    </CardDetailsInfoRow>
    <VatIdentificationNumber vatIdentificationNumber={firma.vatIdentificationNumber} />
    <CompanyRegistrationNumber companyRegistrationNumber={firma.companyRegistrationNumber} />
    <CardDetailsInfoRow infoRowTitle="Sprache">
      <Typography.Text>{firma.sprache.text}</Typography.Text>
    </CardDetailsInfoRow>
  </>
);

export default FirmaDetailedInfo;
