import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Modal } from 'antd';
import FiltersWith4OrMoreFields from '../../../../components/Filters/FiltersWith4OrMoreFields';
import FiltersSelect from '../../../../components/Filters/FiltersSelect';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import FromToFormDatePicker from '../../../shared/FromToForm/FromToFormDatePicker';
import { paymentListingFiltersFormFields, PaymentListingFiltersFormValues, paymentListingLabelList } from './paymentListingFiltersFormMapper';
import ObjektSelect from '../../../ObjektSelect/ObjektSelect';
import KontoFormSelect from '../../../KontoSelect/KontoFormSelect';
import { KontoKlasse, KontoVerwendung } from '../../../../types';
import MitarbeiterSelect from '../../../../shared/components/MitarbeiterSelect/MitarbeiterSelect';
import PaymentBuchungskreisFilter from './PaymentBuchungskreisFilter';
import PaymentPayeeFilter from './PaymentPayeeFilter';
import { TPaymentQueryParams } from './filtersQueryParams';
import { onFilterSelectChange } from '../../../../components/Filters/FiltersHelpers';
import PaymentProposalActionButtons from '../../PaymentProposal/PaymentProposalActionButtons';
import PaymentTableBulkActions from '../ListingBulkActions/PaymentTableBulkActions';
import EmployeeApprovalFilter from './EmployeeApprovalFilter';
import PaymentApprovalStatusSelect from './PaymentApprovalStatusSelect';
import SepaCreditorIdListFormSelect from '../../shared/SepaCreditorIdListFormSelect';
import SepaMandateReferenceListFormSelect from '../../shared/SepaMandateReferenceListFormSelect';
import PaymentStatusFilter from './PaymentStatusFilter';
import PaymentTransactionTypeFilter from './PaymentTransactionTypeFilter';
import PaymentTypeSelect from './PaymentTypeSelect';
import PaymentBelegnummerSelect from './PaymentBelegnummerSelect';
import PaymentProposalFilter from './PaymentProposalFilter';
import PaymentFromToFormInputNumber from './PaymentFromToFormInputNumber';
import ApprovedByMitarbeiterSelectFilter from './ApprovedByMitarbeiterSelectFilter';
import useFilterWithQueryParams from '../../../shared/useFilterWithQueryParams';
import DataCarrierListSelect from '../../../DataCarrier/Listing/shared/DataCarrierListSelect';
import PaymentPayeeIbanSelect from './PaymentPayeeIbanSelect';
import PaymentInvoiceIssuerIbanSelect from './PaymentInvoiceIssuerIbanSelect';
import NotedFormSelect from '../../../shared/NotedFormSelect';
import PaymentReferenceTextSelect from './PaymentReferenceTextSelect';
import PaymentPurposeOfUseTextSelect from './PaymentPurposeOfUseTextSelect';

type Props = {
  formikProps: FormikProps<PaymentListingFiltersFormValues>;
  queryParams: TPaymentQueryParams;
  onActionSuccess: () => void;
  excludeList: string[];
  onResetFilter: () => void;
};

const PaymentListingFilters: FC<Props> = ({ formikProps, queryParams, onActionSuccess, excludeList, onResetFilter }) => {
  const defaultSelectedFilterList = [
    paymentListingFiltersFormFields.paymentDateFrom,
    paymentListingFiltersFormFields.dueDateFrom,
    paymentListingFiltersFormFields.belegnummer,
    paymentListingFiltersFormFields.paymentProposalId,
    paymentListingFiltersFormFields.employeeApproval,
    paymentListingFiltersFormFields.objektIdList,
  ];

  const mapFilterLabelToQueryFilterList = (filter: string) => {
    // map from-to filter so that the filter will be rendered if from or to is set in the query params (e.g. by a link redirecting to the payment listing page)
    if (filter === paymentListingFiltersFormFields.paymentDateFrom || filter === paymentListingFiltersFormFields.paymentDateTo) {
      return paymentListingFiltersFormFields.paymentDateFrom;
    }
    if (filter === paymentListingFiltersFormFields.invoiceDateFrom || filter === paymentListingFiltersFormFields.invoiceDateTo) {
      return paymentListingFiltersFormFields.invoiceDateFrom;
    }
    if (filter === paymentListingFiltersFormFields.buchungsdatumVon || filter === paymentListingFiltersFormFields.buchungsdatumBis) {
      return paymentListingFiltersFormFields.buchungsdatumVon;
    }
    if (filter === paymentListingFiltersFormFields.amountFrom || filter === paymentListingFiltersFormFields.amountTo) {
      return paymentListingFiltersFormFields.amountFrom;
    }
    if (filter === paymentListingFiltersFormFields.dueDateFrom || filter === paymentListingFiltersFormFields.dueDateTo) {
      return paymentListingFiltersFormFields.dueDateFrom;
    }
    return filter;
  };

  const updateFilterValues = (selectedFilterList: string[]) => {
    if (!selectedFilterList.includes(paymentListingFiltersFormFields.invoiceDateFrom) && formikProps.values.invoiceDateTo) {
      formikProps.setFieldValue(paymentListingFiltersFormFields.invoiceDateTo, undefined);
    }
    if (!selectedFilterList.includes(paymentListingFiltersFormFields.paymentDateFrom) && formikProps.values.paymentDateTo) {
      formikProps.setFieldValue(paymentListingFiltersFormFields.paymentDateTo, undefined);
    }
    if (!selectedFilterList.includes(paymentListingFiltersFormFields.buchungsdatumVon) && formikProps.values.buchungsdatumBis) {
      formikProps.setFieldValue(paymentListingFiltersFormFields.buchungsdatumBis, undefined);
    }
    if (!selectedFilterList.includes(paymentListingFiltersFormFields.amountFrom) && formikProps.values.amountTo) {
      formikProps.setFieldValue(paymentListingFiltersFormFields.amountTo, undefined);
    }
    if (!selectedFilterList.includes(paymentListingFiltersFormFields.dueDateFrom) && formikProps.values.dueDateTo) {
      formikProps.setFieldValue(paymentListingFiltersFormFields.dueDateTo, undefined);
    }
    formikProps.submitForm();
  };

  const onCancelResetFieldValue = (name: string, value: unknown) => {
    formikProps.setFieldValue(name, value);
  };

  const onOk = () => {
    onResetFilter();
    formikProps.submitForm();
  };

  const handleOnChange = (name: string, value: unknown) => {
    if (excludeList.length > 0) {
      showConfirmChangeFilter(onOk, () => onCancelResetFieldValue(name, value));
    } else {
      // Needs to be done so init select works otherwise:
      // i.e. object changes, not all objects are selected because first page has now different objects and only the ones that match current selectedRowKeys objects are selected
      onResetFilter();
      formikProps.submitForm();
    }
  };

  const filters = (filter: string, selectedFilterList: string[]) => {
    if (filter === paymentListingFiltersFormFields.noted) {
      return (
        <FormItemWithoutColon name={paymentListingFiltersFormFields.noted} label="Gesehen" key={filter}>
          <NotedFormSelect
            name={paymentListingFiltersFormFields.noted}
            onChange={() => {
              const currentValue = formikProps.values.noted;
              handleOnChange(paymentListingFiltersFormFields.noted, currentValue);
            }}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === paymentListingFiltersFormFields.objektIdList) {
      return (
        <FormItemWithoutColon key={filter} name={paymentListingFiltersFormFields.objektIdList} label={paymentListingLabelList.objektIdList}>
          <ObjektSelect
            name={paymentListingFiltersFormFields.objektIdList}
            rechnungsAusstellerId={formikProps.values.rechnungsAusstellerId}
            onChange={() => {
              const currentValue = formikProps.values.objektIdList;
              handleOnChange(paymentListingFiltersFormFields.objektIdList, currentValue);
            }}
            mode="multiple"
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === paymentListingFiltersFormFields.paymentTypeList) {
      return (
        <FormItemWithoutColon name={paymentListingFiltersFormFields.paymentTypeList} label={paymentListingLabelList.paymentTypeList}>
          <PaymentTypeSelect
            key={filter}
            name={paymentListingFiltersFormFields.paymentTypeList}
            onChange={() => {
              const currentValue = formikProps.values.paymentTypeList;
              handleOnChange(paymentListingFiltersFormFields.paymentTypeList, currentValue);
            }}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === paymentListingFiltersFormFields.paymentTransactionType) {
      return (
        <PaymentTransactionTypeFilter
          key={filter}
          name={paymentListingFiltersFormFields.paymentTransactionType}
          onChange={() => {
            const currentValue = formikProps.values.paymentTransactionType;
            handleOnChange(paymentListingFiltersFormFields.paymentTransactionType, currentValue);
          }}
        />
      );
    }
    if (filter === paymentListingFiltersFormFields.paymentDateFrom) {
      return (
        <FormItemWithoutColon key={filter} name="paymentDate" label={paymentListingLabelList.paymentDateFrom}>
          <FromToFormDatePicker
            format="day"
            fromName={paymentListingFiltersFormFields.paymentDateFrom}
            toName={paymentListingFiltersFormFields.paymentDateTo}
            fromOnChange={() => {
              const currentValue = formikProps.values.paymentDateFrom;
              handleOnChange(paymentListingFiltersFormFields.paymentDateFrom, currentValue);
            }}
            toOnChange={() => {
              const currentValue = formikProps.values.paymentDateTo;
              handleOnChange(paymentListingFiltersFormFields.paymentDateTo, currentValue);
            }}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === paymentListingFiltersFormFields.paymentProposalId) {
      return (
        <PaymentProposalFilter
          key={filter}
          name={paymentListingFiltersFormFields.paymentProposalId}
          fieldLabel={paymentListingLabelList.paymentProposalId}
          onFilterChange={() => {
            const currentValue = formikProps.values.paymentProposalId;
            handleOnChange(paymentListingFiltersFormFields.paymentProposalId, currentValue);
          }}
          onShowOwnPaymentProposalChange={() => formikProps.setFieldValue(paymentListingFiltersFormFields.paymentProposalId, undefined)}
          showNotAssignedOption
        />
      );
    }
    if (filter === paymentListingFiltersFormFields.belegnummer) {
      return (
        <FormItemWithoutColon key={filter} name={paymentListingFiltersFormFields.belegnummer} label={paymentListingLabelList.belegnummer}>
          <PaymentBelegnummerSelect
            name={paymentListingFiltersFormFields.belegnummer}
            onChange={() => {
              const currentValue = formikProps.values.belegnummer;
              handleOnChange(paymentListingFiltersFormFields.belegnummer, currentValue);
            }}
            belegnummer={formikProps.values.belegnummer}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === paymentListingFiltersFormFields.invoiceDateFrom) {
      return (
        <FormItemWithoutColon key={filter} label={paymentListingLabelList.invoiceDateFrom} name="invoiceDate">
          <FromToFormDatePicker
            format="day"
            fromName={paymentListingFiltersFormFields.invoiceDateFrom}
            toName={paymentListingFiltersFormFields.invoiceDateTo}
            fromOnChange={() => {
              const currentValue = formikProps.values.invoiceDateFrom;
              handleOnChange(paymentListingFiltersFormFields.invoiceDateFrom, currentValue);
            }}
            toOnChange={() => {
              const currentValue = formikProps.values.invoiceDateTo;
              handleOnChange(paymentListingFiltersFormFields.invoiceDateTo, currentValue);
            }}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === paymentListingFiltersFormFields.dueDateFrom) {
      return (
        <FormItemWithoutColon key={filter} label={paymentListingLabelList.dueDateFrom} name="dueDate">
          <FromToFormDatePicker
            format="day"
            fromName={paymentListingFiltersFormFields.dueDateFrom}
            toName={paymentListingFiltersFormFields.dueDateTo}
            fromOnChange={() => {
              const currentValue = formikProps.values.dueDateFrom;
              handleOnChange(paymentListingFiltersFormFields.dueDateFrom, currentValue);
            }}
            toOnChange={() => {
              const currentValue = formikProps.values.dueDateTo;
              handleOnChange(paymentListingFiltersFormFields.dueDateTo, currentValue);
            }}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === paymentListingFiltersFormFields.rechnungsAusstellerId) {
      return (
        <PaymentBuchungskreisFilter
          key={filter}
          rechnungsausstellerId={formikProps.values.rechnungsAusstellerId}
          onChange={() => {
            const currentValue = formikProps.values.rechnungsAusstellerId;
            handleOnChange(paymentListingFiltersFormFields.rechnungsAusstellerId, currentValue);
          }}
          onChangeIban={() => {
            const currentValue = formikProps.values.rechnungsAusstellerIban;
            handleOnChange(paymentListingFiltersFormFields.rechnungsAusstellerIban, currentValue);
          }}
          showBankDetails={selectedFilterList.includes(paymentListingFiltersFormFields.rechnungsAusstellerIban)}
        />
      );
    }
    if (filter === paymentListingFiltersFormFields.payeeId) {
      return (
        <PaymentPayeeFilter
          key={filter}
          empfaengerId={formikProps.values.payeeId}
          showBankDetails={selectedFilterList.includes(paymentListingFiltersFormFields.payeeIban)}
          onChange={() => {
            const currentValue = formikProps.values.payeeId;
            handleOnChange(paymentListingFiltersFormFields.payeeId, currentValue);
          }}
          onChangeIban={() => {
            const currentValue = formikProps.values.payeeIban;
            handleOnChange(paymentListingFiltersFormFields.payeeIban, currentValue);
          }}
        />
      );
    }
    if (filter === paymentListingFiltersFormFields.rechnungsAusstellerFibuKontoId) {
      return (
        <FormItemWithoutColon
          key={filter}
          name={paymentListingFiltersFormFields.rechnungsAusstellerFibuKontoId}
          label={paymentListingLabelList.rechnungsAusstellerFibuKontoId}
        >
          <KontoFormSelect
            name={paymentListingFiltersFormFields.rechnungsAusstellerFibuKontoId}
            kontoKlasse={[KontoKlasse.UmlaufVermoegen]}
            kontoVerwendung={KontoVerwendung.Bank}
            placeholder="Fibu Bankkonto auswählen"
            onChange={() => {
              const currentValue = formikProps.values.rechnungsAusstellerFibuKontoId;
              handleOnChange(paymentListingFiltersFormFields.rechnungsAusstellerFibuKontoId, currentValue);
            }}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === paymentListingFiltersFormFields.buchungsdatumVon) {
      return (
        <FormItemWithoutColon key={filter} name="buchungsdatum" label={paymentListingLabelList.buchungsdatumVon}>
          <FromToFormDatePicker
            format="day"
            fromName={paymentListingFiltersFormFields.buchungsdatumVon}
            toName={paymentListingFiltersFormFields.buchungsdatumBis}
            fromOnChange={() => {
              const currentValue = formikProps.values.buchungsdatumVon;
              handleOnChange(paymentListingFiltersFormFields.buchungsdatumVon, currentValue);
            }}
            toOnChange={() => {
              const currentValue = formikProps.values.buchungsdatumBis;
              handleOnChange(paymentListingFiltersFormFields.buchungsdatumBis, currentValue);
            }}
          />
        </FormItemWithoutColon>
      );
    }
    if (
      filter === paymentListingFiltersFormFields.rechnungsAusstellerIban &&
      !selectedFilterList.includes(paymentListingFiltersFormFields.rechnungsAusstellerId)
    ) {
      return (
        <FormItemWithoutColon
          key={filter}
          name={paymentListingFiltersFormFields.rechnungsAusstellerIban}
          label={paymentListingLabelList.rechnungsAusstellerIban}
        >
          <PaymentInvoiceIssuerIbanSelect
            name={paymentListingFiltersFormFields.rechnungsAusstellerIban}
            onChange={() => {
              const currentValue = formikProps.values.rechnungsAusstellerIban;
              handleOnChange(paymentListingFiltersFormFields.rechnungsAusstellerIban, currentValue);
            }}
            rechtstraegerId={formikProps.values.rechnungsAusstellerId}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === paymentListingFiltersFormFields.payeeIban && !selectedFilterList.includes(paymentListingFiltersFormFields.payeeId)) {
      return (
        <FormItemWithoutColon key={filter} name={paymentListingFiltersFormFields.payeeIban} label={paymentListingLabelList.payeeIban}>
          <PaymentPayeeIbanSelect
            name={paymentListingFiltersFormFields.payeeIban}
            onChange={() => {
              const currentValue = formikProps.values.payeeIban;
              handleOnChange(paymentListingFiltersFormFields.payeeIban, currentValue);
            }}
            rechtstraegerId={formikProps.values.payeeId}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === paymentListingFiltersFormFields.amountFrom) {
      return (
        <PaymentFromToFormInputNumber
          key={filter}
          name="Betrag"
          fromName={paymentListingFiltersFormFields.amountFrom}
          toName={paymentListingFiltersFormFields.amountTo}
          formikProps={formikProps}
          excludeList={excludeList}
          onResetFilter={onResetFilter}
        />
      );
    }
    if (filter === paymentListingFiltersFormFields.paymentApprovalStatusList) {
      return (
        <FormItemWithoutColon
          key={filter}
          name={paymentListingFiltersFormFields.paymentApprovalStatusList}
          label={paymentListingLabelList.paymentApprovalStatusList}
        >
          <PaymentApprovalStatusSelect
            name={paymentListingFiltersFormFields.paymentApprovalStatusList}
            onChange={() => {
              const currentValue = formikProps.values.paymentApprovalStatusList;
              handleOnChange(paymentListingFiltersFormFields.paymentApprovalStatusList, currentValue);
            }}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === paymentListingFiltersFormFields.statusList) {
      return (
        <PaymentStatusFilter
          key={filter}
          name={paymentListingFiltersFormFields.statusList}
          onChange={() => {
            const currentValue = formikProps.values.statusList;
            handleOnChange(paymentListingFiltersFormFields.statusList, currentValue);
          }}
        />
      );
    }
    if (filter === paymentListingFiltersFormFields.erstellerMitarbeiterIdList) {
      return (
        <FormItemWithoutColon
          key={filter}
          name={paymentListingFiltersFormFields.erstellerMitarbeiterIdList}
          label={paymentListingLabelList.erstellerMitarbeiterIdList}
        >
          <MitarbeiterSelect
            mode="multiple"
            name={paymentListingFiltersFormFields.erstellerMitarbeiterIdList}
            onChange={() => {
              const currentValue = formikProps.values.erstellerMitarbeiterIdList;
              handleOnChange(paymentListingFiltersFormFields.erstellerMitarbeiterIdList, currentValue);
            }}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === paymentListingFiltersFormFields.approvedByMitarbeiterIdList) {
      return (
        <ApprovedByMitarbeiterSelectFilter
          key={filter}
          name={paymentListingFiltersFormFields.approvedByMitarbeiterIdList}
          onChange={() => {
            const currentValue = formikProps.values.approvedByMitarbeiterIdList;
            handleOnChange(paymentListingFiltersFormFields.approvedByMitarbeiterIdList, currentValue);
          }}
        />
      );
    }
    if (filter === paymentListingFiltersFormFields.employeeApproval) {
      return (
        <EmployeeApprovalFilter
          key={filter}
          name={paymentListingFiltersFormFields.employeeApproval}
          onChange={() => {
            const currentValue = formikProps.values.employeeApproval;
            handleOnChange(paymentListingFiltersFormFields.employeeApproval, currentValue);
          }}
        />
      );
    }
    if (filter === paymentListingFiltersFormFields.sepaCreditorId) {
      return (
        <FormItemWithoutColon key={filter} name={paymentListingFiltersFormFields.sepaCreditorId} label={paymentListingLabelList.sepaCreditorId}>
          <SepaCreditorIdListFormSelect
            name={paymentListingFiltersFormFields.sepaCreditorId}
            onChange={() => {
              const currentValue = formikProps.values.sepaCreditorId;
              handleOnChange(paymentListingFiltersFormFields.sepaCreditorId, currentValue);
            }}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === paymentListingFiltersFormFields.sepaMandateReference) {
      return (
        <FormItemWithoutColon
          key={filter}
          name={paymentListingFiltersFormFields.sepaMandateReference}
          label={paymentListingLabelList.sepaMandateReference}
        >
          <SepaMandateReferenceListFormSelect
            name={paymentListingFiltersFormFields.sepaMandateReference}
            onChange={() => {
              const currentValue = formikProps.values.sepaMandateReference;
              handleOnChange(paymentListingFiltersFormFields.sepaMandateReference, currentValue);
            }}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === paymentListingFiltersFormFields.dataCarrierIdList) {
      return (
        <FormItemWithoutColon key={filter} name={paymentListingFiltersFormFields.dataCarrierIdList} label={paymentListingLabelList.dataCarrierIdList}>
          <DataCarrierListSelect
            name={paymentListingFiltersFormFields.dataCarrierIdList}
            onChange={() => {
              const currentValue = formikProps.values.dataCarrierIdList;
              handleOnChange(paymentListingFiltersFormFields.dataCarrierIdList, currentValue);
            }}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === paymentListingFiltersFormFields.paymentReferenceText) {
      return (
        <FormItemWithoutColon
          key={filter}
          name={paymentListingFiltersFormFields.paymentReferenceText}
          label={paymentListingLabelList.paymentReferenceText}
        >
          <PaymentReferenceTextSelect
            name={paymentListingFiltersFormFields.paymentReferenceText}
            onChange={() => {
              const currentValue = formikProps.values.paymentReferenceText;
              handleOnChange(paymentListingFiltersFormFields.paymentReferenceText, currentValue);
            }}
            paymentReferenceText={formikProps.values.paymentReferenceText}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === paymentListingFiltersFormFields.purposeOfUseText) {
      return (
        <FormItemWithoutColon key={filter} name={paymentListingFiltersFormFields.purposeOfUseText} label={paymentListingLabelList.purposeOfUseText}>
          <PaymentPurposeOfUseTextSelect
            name={paymentListingFiltersFormFields.purposeOfUseText}
            onChange={() => {
              const currentValue = formikProps.values.purposeOfUseText;
              handleOnChange(paymentListingFiltersFormFields.purposeOfUseText, currentValue);
            }}
            purposeOfUseText={formikProps.values.purposeOfUseText}
          />
        </FormItemWithoutColon>
      );
    }

    return undefined;
  };

  const { selectedFilterList, setSelectedFilterList, leftColumn, rightColumn } = useFilterWithQueryParams({
    defaultSelectedFilterList,
    updateFilterValues,
    filters,
    labelList: paymentListingLabelList,
    mapFilterLabelToQueryFilterList,
    queryParams,
  });

  return (
    <>
      <FiltersWith4OrMoreFields
        actionButtons={<PaymentProposalActionButtons label="Zahlungsvorschlag" />}
        filterActions={<PaymentTableBulkActions onSuccess={onActionSuccess} queryParams={queryParams} excludeList={excludeList} />}
        filtersSelect={
          <FiltersSelect
            defaultSelectedFilterList={defaultSelectedFilterList}
            selectedFilterList={selectedFilterList}
            onChange={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
            labelList={paymentListingLabelList}
          />
        }
        hideTitle
        leftColumn={<>{leftColumn}</>}
        rightColumn={<>{rightColumn}</>}
      />
    </>
  );
};

export const showConfirmChangeFilter = (runOk: () => void, runCancel: () => void) => {
  Modal.confirm({
    title: 'Filter Änderung anwenden?',
    content: 'Durch die Änderung der Filter, wird die Auswahl zurückgesetzt.',
    okText: 'Anwenden',
    cancelText: 'Abbrechen',
    onOk() {
      return runOk();
    },
    onCancel() {
      return runCancel();
    },
  });
};

export default PaymentListingFilters;
