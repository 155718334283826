import { round } from 'lodash';
import {
  AuszifferungBlockFormValues,
  auszifferungFormFields,
  AuszifferungFormValues,
  OffenePostenForBooking,
  offenePostenListFromBookingFormFields,
} from './auszifferungFormMapper';
import { FibuBuchungType } from '../../../../../types';
import { isFibuBuchungTypeRuecklastschrift, isFibuBuchungTypeZahlung } from '../bookingHelpers';
import { belongsAuszifferungToAnotherZahlung, isAuszifferungSourceStorno } from '../../../shared/auszifferungHelpers';

export const disableAddButtonBasedOnAvailableAmount = (availableAmount: number, buchungType: FibuBuchungType) =>
  isFibuBuchungTypeZahlung(buchungType) ? availableAmount <= 0 : availableAmount >= 0;

export const isNewAuszifferung = (auszifferung: AuszifferungFormValues) => !auszifferung.auszifferungsDatum;

export const isExistingAuszifferung = (auszifferung: AuszifferungFormValues) => !!auszifferung.buchungsdatum;

export const isAuszifferungStorniert = (auszifferung: AuszifferungFormValues) => auszifferung.storniert;

export const hasOPListNewAuszifferung = (offenePostenListFromBooking: OffenePostenForBooking[]): boolean => {
  for (const offenePosten of offenePostenListFromBooking) {
    const newAuszifferung = offenePosten.auszifferungList.some((auszifferung) => isNewAuszifferung(auszifferung));
    if (newAuszifferung) {
      return true;
    }
  }
  return false;
};

export const hasOPListExistingNotStorniertAuszifferung = (bookingZahlungId: string, offenePostenList: OffenePostenForBooking[]): boolean => {
  for (const offenePosten of offenePostenList) {
    const filteredAuszifferungList = filterOutStornoSourceAndAzBelongingToAnotherZahlungFromAuszifferungList(
      bookingZahlungId,
      offenePosten.auszifferungList
    );
    const existingNotStorniertAuszifferung = findExistingNotStorniertAuszifferungInAuszifferungList(filteredAuszifferungList);
    if (existingNotStorniertAuszifferung) {
      return true;
    }
  }
  return false;
};

export const filterOutStornoSourceAndAzBelongingToAnotherZahlungFromAuszifferungList = (
  bookingZahlungId: string,
  auszifferungList: AuszifferungFormValues[]
) =>
  auszifferungList.filter(
    (auszifferung) =>
      !isAuszifferungSourceStorno(auszifferung.source?.value) && !belongsAuszifferungToAnotherZahlung(bookingZahlungId, auszifferung.zahlungId)
  );

const findExistingNotStorniertAuszifferungInAuszifferungList = (auszifferungList: AuszifferungFormValues[]): AuszifferungFormValues | undefined => {
  return auszifferungList.find((auszifferung) => isExistingAuszifferung(auszifferung) && !isAuszifferungStorniert(auszifferung));
};

/** get index  */
export const getOffenePostenIndexFromMainOPList = (formikValues: AuszifferungBlockFormValues, offenePosten: OffenePostenForBooking) =>
  formikValues.offenePostenListFromBooking.findIndex((op) => op.offenePostenId === offenePosten.offenePostenId);

export const getAuszifferungIndex = (auszifferung: AuszifferungFormValues, offenePosten: OffenePostenForBooking) =>
  offenePosten.auszifferungList.findIndex((az) => az.auszifferungId === auszifferung.auszifferungId);

export const getOffenePostenIndexFromPersonenkontoOPList = (formikValues: AuszifferungBlockFormValues, offenePosten: OffenePostenForBooking) =>
  formikValues.offenePostenForPersonenkontoList.findIndex((op) => op.offenePostenId === offenePosten.offenePostenId);

/** get FormFieldName for AuszifferungList */
export const getAuszifferungListFormFieldNameFromMainOPListBasedOnIndex = (opIndex: number) =>
  `${auszifferungFormFields.offenePostenListFromBooking}[${opIndex}].${offenePostenListFromBookingFormFields.auszifferungList}`;
export const getAuszifferungListFormFieldNameFromPersonenkontoOPListBasedOnIndex = (opIndex: number) =>
  `${auszifferungFormFields.offenePostenForPersonenkontoList}[${opIndex}].${offenePostenListFromBookingFormFields.auszifferungList}`;

export const getAuszifferungListFormFieldNameFromMainOPList = (formikValues: AuszifferungBlockFormValues, offenePosten: OffenePostenForBooking) => {
  const opIndex = getOffenePostenIndexFromMainOPList(formikValues, offenePosten);
  return getAuszifferungListFormFieldNameFromMainOPListBasedOnIndex(opIndex);
};

/** calculate initial amount for new Auszifferung */
export const calculateInitialBetragForNewAuszifferung = (
  formikValues: AuszifferungBlockFormValues,
  offenePosten: OffenePostenForBooking,
  buchungType: FibuBuchungType
) => {
  const availableAmount = formikValues.availableAmount;

  if (isFibuBuchungTypeZahlung(buchungType)) {
    const oPSaldoOffenerBetrag = offenePosten.offenerBetrag;
    const result = availableAmount > oPSaldoOffenerBetrag ? oPSaldoOffenerBetrag : availableAmount;
    return round(result, 2);
  }

  if (isFibuBuchungTypeRuecklastschrift(buchungType)) {
    const possibleBetragForOP = offenePosten.offenerBetrag - offenePosten.gesamtBetrag;

    const result = availableAmount < possibleBetragForOP ? possibleBetragForOP : availableAmount;
    return round(result, 2);
  }

  return 0;
};
