import { get, isEmpty } from 'lodash';
import React, { FC } from 'react';
import { Formik, FormikProps } from 'formik';
import { Form, Switch } from 'formik-antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row, Space, Spin, Tooltip, Typography } from 'antd';
import FormButtons from '../../../../components/Button/FormButtons';
import { showSuccessMsgUpdate } from '../../../../components/message';
import { objektAufteilungsschluesselFormValidationSchema } from './objektAufteilungsschluesselFormValidationSchema';
import {
  mapFormValuesToObjektAufteilungsschluessel,
  mapObjektAufteilungsschluesselToFormValues,
  objektAufteilungsschluesselFormFields,
  ObjektAufteilungsschluesselFormValues,
} from './objektAufteilungsschluesselFormMapper';
import { hasVerwendungNutzflaeche, hasVerwendungNutzwert } from './objektAufteilungsschluesselHelper';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { useUpdateObjektAufteilungsschluesselMutation } from '../gql/ObjektAufteilungsschluesselMutations.types';
import { useObjektAufteilungsschluesselListQuery } from '../gql/ObjektAufteilungsschluesselQueries.types';
import UniqueBezeichnungInput from '../../../shared/UniqueBezeichnungInput';
import { Spacer } from '../../../../components/Grid';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import { ObjektAufteilungsschluessel } from '../../../../types';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import FieldHelpIconWithTooltip from '../../../../components/FieldHelpIcon/FieldHelpIconWithTooltip';

type ObjektAufteilungsschluesselFormProps = {
  objektId: string;
  aufteilungsschluesselId: string;
  onSubmit: () => void;
  onCancel: () => void;
  isWegObjekt: boolean;
};

const ObjektAufteilungsschluesselForm: FC<ObjektAufteilungsschluesselFormProps> = ({
  objektId,
  aufteilungsschluesselId,
  onSubmit,
  onCancel,
  isWegObjekt,
}) => {
  const getFieldHelpText = useGetAppFieldHelpText<'ObjektAufteilungsschluessel'>('ObjektAufteilungsschluessel');

  const { loading, data: maybeAufteilungsschluesselList } = useObjektAufteilungsschluesselListQuery({
    variables: {
      objektId,
    },
  });
  const aufteilungsschluesselList = maybeAufteilungsschluesselList?.getObjektAufteilungsschluesselList.data ?? [];

  const [runUpdate, { loading: loadingUpdate }] = useUpdateObjektAufteilungsschluesselMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`Aufteilungsschlüssel`);
      onSubmit();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  if (loading) return <Spin />;

  const selectedAufteilungsschluessel = findSelectedAufteilungsschluessel(aufteilungsschluesselList, aufteilungsschluesselId);
  const allNotSelectedAufteilungsschluessel = findAllNotSelectedAufteilungsschluessel(aufteilungsschluesselList, aufteilungsschluesselId);
  const isNutzflaecheDisabled = allNotSelectedAufteilungsschluessel.some((objektAufteilungsschluessel) =>
    hasVerwendungNutzflaeche(objektAufteilungsschluessel)
  );
  const isNutzwertDisabled = allNotSelectedAufteilungsschluessel.some((objektAufteilungsschluessel) =>
    hasVerwendungNutzwert(objektAufteilungsschluessel)
  );

  return (
    <Formik<ObjektAufteilungsschluesselFormValues>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialValues={mapObjektAufteilungsschluesselToFormValues(selectedAufteilungsschluessel!)}
      validationSchema={objektAufteilungsschluesselFormValidationSchema}
      validateOnChange
      onSubmit={(values, formikHelpers) =>
        runUpdate({
          variables: {
            objektId,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            objektAufteilungsschluesselId: selectedAufteilungsschluessel!.objektAufteilungsschluesselId,
            input: mapFormValuesToObjektAufteilungsschluessel(values),
          },
        }).finally(() => formikHelpers.setSubmitting(false))
      }
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Bezeichnung</Typography.Title>
            </Col>
            <Col span={18}>
              <UniqueBezeichnungInput
                name={objektAufteilungsschluesselFormFields.bezeichnung}
                existingBezeichnungList={aufteilungsschluesselList.map((item) => item.bezeichnung)}
                placeholder="z.B. Aufteilungsschlüssel XY"
                fieldHelp={getFieldHelpText('ObjektAufteilungsschluessel.bezeichnung')}
              />
            </Col>
          </Row>
          <Spacer />
          <Row>
            <Col span={6}>
              <Typography.Title level={5}>
                Verwendung
                <FieldHelpIconWithTooltip fieldHelp={getFieldHelpText('ObjektAufteilungsschluessel.verwendung')} />
              </Typography.Title>
            </Col>
            <Col span={18}>
              <Space direction="horizontal" size="large">
                <FormItemWithoutColon name={objektAufteilungsschluesselFormFields.isNutzflaeche} label="Nutzfläche">
                  <Switch
                    // @ts-ignore
                    id={objektAufteilungsschluesselFormFields.isNutzflaeche}
                    name={objektAufteilungsschluesselFormFields.isNutzflaeche}
                    disabled={isNutzflaecheDisabled}
                    onChange={toggleOtherSwitchFieldIfBothWouldBeChecked(
                      objektAufteilungsschluesselFormFields.isNutzwert,
                      isNutzwertDisabled,
                      formikProps
                    )}
                  />
                  {isNutzflaecheDisabled && (
                    <Tooltip placement="top" title={infoText('Nutzfläche')}>
                      <InfoCircleOutlined style={{ marginLeft: '8px' }} />
                    </Tooltip>
                  )}
                </FormItemWithoutColon>
                {isWegObjekt && (
                  <FormItemWithoutColon name={objektAufteilungsschluesselFormFields.isNutzwert} label="Nutzwert">
                    <Switch
                      // @ts-ignore
                      id={objektAufteilungsschluesselFormFields.isNutzwert}
                      name={objektAufteilungsschluesselFormFields.isNutzwert}
                      disabled={isNutzwertDisabled}
                      onChange={toggleOtherSwitchFieldIfBothWouldBeChecked(
                        objektAufteilungsschluesselFormFields.isNutzflaeche,
                        isNutzflaecheDisabled,
                        formikProps
                      )}
                    />
                    {isNutzwertDisabled && (
                      <Tooltip placement="top" title={infoText('Nutzwert')}>
                        <InfoCircleOutlined style={{ marginLeft: '8px' }} />
                      </Tooltip>
                    )}
                  </FormItemWithoutColon>
                )}
              </Space>
            </Col>
          </Row>
          <FormButtons
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting || !isEmpty(formikProps.errors)}
            updateMode
            isOkLoading={loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

const findSelectedAufteilungsschluessel = (aufteilungsschluesselList: ObjektAufteilungsschluessel[], aufteilungsschluesselId: string) =>
  aufteilungsschluesselList.find((schluessel) => schluessel.aufteilungsschluesselId === aufteilungsschluesselId);

const findAllNotSelectedAufteilungsschluessel = (aufteilungsschluesselList: ObjektAufteilungsschluessel[], aufteilungsschluesselId: string) =>
  aufteilungsschluesselList.filter((schluessel) => schluessel.aufteilungsschluesselId !== aufteilungsschluesselId);

const toggleOtherSwitchFieldIfBothWouldBeChecked =
  (otherSwitchFieldName: string, isOtherSwitchDisabled: boolean, formikProps: FormikProps<ObjektAufteilungsschluesselFormValues>) =>
  (isCurrentSwitchChecked: boolean) => {
    const isOtherSwitchChecked = get(formikProps.values, otherSwitchFieldName);
    if (isCurrentSwitchChecked && isOtherSwitchChecked && !isOtherSwitchDisabled) {
      formikProps.setFieldValue(otherSwitchFieldName, false);
    }
  };

const infoText = (aufteilungsschluesselType: string) => `Aufteilungschlüssel für ${aufteilungsschluesselType} bereits vergeben`;

export default ObjektAufteilungsschluesselForm;
