import { FC } from 'react';
import { Spin } from 'antd';
import RechnungsAusstellerRepFondsListingBtnAndModal from './RechnungsAusstellerRepFondsListingBtnAndModal';
import RechnungsAusstellerRepFondsTable from './Table/RechnungsAusstellerRepFondsTable';
import { useRepFondsErloesKontenZuweisungListQuery } from '../../../../ReparaturFonds/gql/RepFondsErloesKontenZuweisungQueries.types';
import { RechnungsAusstellerFieldsFragment } from '../gql/RechnungsAusstellerFragments.types';

type RechnungsAusstellerRepFondsListingProps = {
  rechnungsAussteller: RechnungsAusstellerFieldsFragment;
  rechtstraegerId: string;
};

const RechnungsAusstellerRepFondsListing: FC<RechnungsAusstellerRepFondsListingProps> = ({ rechtstraegerId }) => {
  const { data, loading, refetch } = useRepFondsErloesKontenZuweisungListQuery({ variables: { rechtstraegerId } });

  const repFondsErloesKontenZuweisungList = data?.getRepFondsErloesKontenZuweisungList.data;

  if (!repFondsErloesKontenZuweisungList) return <Spin />;

  return (
    <>
      <RechnungsAusstellerRepFondsListingBtnAndModal
        rechtstraegerId={rechtstraegerId}
        onSuccess={refetch}
        repFondsErloesKontenZuweisungList={repFondsErloesKontenZuweisungList}
      />
      <RechnungsAusstellerRepFondsTable
        loading={loading}
        rechtstraegerId={rechtstraegerId}
        repFondsErloesKontenZuweisungList={repFondsErloesKontenZuweisungList}
        onSuccess={refetch}
      />
    </>
  );
};

export default RechnungsAusstellerRepFondsListing;
