import React, { FC } from 'react';
import { Divider, Space } from 'antd';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import EmailDelivery from './EmailDelivery';
import { useInfoMailDeliveryQuery } from '../gql/InfoMailDeliveryQueries.types';

type Props = {
  infoMailDeliveryId: string;
};

const ResentDeliveryInfoRow: FC<Props> = ({ infoMailDeliveryId }) => {
  const { data } = useInfoMailDeliveryQuery({ variables: { infoMailDeliveryId } });
  const infoMailDelivery = data?.getInfoMailDelivery.data;

  if (!infoMailDelivery?.resentDeliveryList?.length) return null;

  return (
    <>
      <Divider />

      <CardDetailsInfoRow infoRowTitle="Erneut Versendet">
        <Space direction="vertical" size={20}>
          {infoMailDelivery.resentDeliveryList.map((emailDelivery, index) => (
            <EmailDelivery key={index} emailDelivery={emailDelivery} />
          ))}
        </Space>
      </CardDetailsInfoRow>
    </>
  );
};

export default ResentDeliveryInfoRow;
