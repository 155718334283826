import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Col, Divider, Row } from 'antd';
import { DatePicker, Input } from 'formik-antd';
import { incomingInvoiceFormFields, IncomingInvoiceFormValues } from './incomingInvoiceFormMapper';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../components/DatePicker/DatePicker_formikAntD';
import RechtstraegerBankDetailsSelect from '../../BankDetails/Select/RechtstraegerBankDetailsSelect';
import { isBillingTypeEinzelrechnung, isPaymentMethodLastschrift } from '../../Payment/paymentHelpers';
import FormBlockRow from './shared/FormBlockRow';
import PaymentMethodListFormSelect from '../../PaymentMethodSelect/PaymentMethodListFormSelect';
import IncomingInvoiceBankDetailsSelect from './shared/IncomingInvoiceBankDetailsSelect';

type Props = {
  formikProps: FormikProps<IncomingInvoiceFormValues>;
  shouldRestore: boolean;
};

const PaymentBlockForm: FC<Props> = ({ formikProps, shouldRestore }) => {
  const { activeForFirmendatenId } = useFDAuthorized();

  return (
    <>
      <FormBlockRow
        rowTitle="Zahlung"
        content={
          <Row gutter={16}>
            <Col span={24}>
              <FormItemWithoutColon name={incomingInvoiceFormFields.paymentMethod} label="Zahlungsart">
                <PaymentMethodListFormSelect
                  name={incomingInvoiceFormFields.paymentMethod}
                  size="middle"
                  disabled={shouldRestore}
                  onChange={(paymentMethod) => {
                    !isPaymentMethodLastschrift(paymentMethod) &&
                      formikProps.values.sepaMandatReference &&
                      formikProps.setFieldValue(incomingInvoiceFormFields.sepaMandatReference, undefined);
                  }}
                  selection
                />
              </FormItemWithoutColon>

              <Row>
                <Col span={24}>
                  <FormItemWithoutColon name={incomingInvoiceFormFields.payeeBankDetailsId} label="Bankverbindung Rechnungsempfänger">
                    <IncomingInvoiceBankDetailsSelect
                      firmendatenId={activeForFirmendatenId}
                      objektId={formikProps.values.objektId}
                      rechtstraegerId={formikProps.values.payeeRechtstraegerId}
                      rechtstraegerRolleList={formikProps.values.payeeRechtstraegerRolleList}
                      name={incomingInvoiceFormFields.payeeBankDetailsId}
                      onNewItemCreateSelected={() => formikProps.setFieldValue(incomingInvoiceFormFields.payeeBankDetailsId, '')}
                      onSetNewCreatedBankDetailsId={(id, allowObjektZuweisung) => {
                        if (allowObjektZuweisung) {
                          formikProps.setFieldValue(incomingInvoiceFormFields.payeeBankDetailsId, id);
                        } else {
                          formikProps.setFieldValue(incomingInvoiceFormFields.payeeBankDetailsId, '');
                        }
                      }}
                      disabled={shouldRestore || !formikProps.values.payeeRechtstraegerId}
                    />
                  </FormItemWithoutColon>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormItemWithoutColon name={incomingInvoiceFormFields.issuerBankDetailsId} label="Bankverbindung Lieferant">
                    <RechtstraegerBankDetailsSelect
                      name={incomingInvoiceFormFields.issuerBankDetailsId}
                      rechtstraegerId={formikProps.values.ausstellerRechtstraegerId}
                      disabled={shouldRestore || !formikProps.values.ausstellerRechtstraegerId}
                      size="middle"
                      onNewItemCreateSelected={() => formikProps.setFieldValue(incomingInvoiceFormFields.issuerBankDetailsId, '')}
                      onHasOnlyOneBankDetails={(bankDetailsId) => {
                        bankDetailsId && formikProps.setFieldValue(incomingInvoiceFormFields.issuerBankDetailsId, bankDetailsId);
                      }}
                    />
                  </FormItemWithoutColon>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormItemWithoutColon name={incomingInvoiceFormFields.purposeOfUseText} label="Verwendungszweck">
                    <Input
                      disabled={shouldRestore || !!formikProps.values.paymentReferenceText}
                      size="middle"
                      name={incomingInvoiceFormFields.purposeOfUseText}
                    />
                  </FormItemWithoutColon>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormItemWithoutColon name={incomingInvoiceFormFields.paymentReferenceText} label="Zahlungsreferenz">
                    <Input
                      disabled={shouldRestore || !!formikProps.values.purposeOfUseText}
                      size="middle"
                      name={incomingInvoiceFormFields.paymentReferenceText}
                    />
                  </FormItemWithoutColon>
                </Col>
              </Row>
              {isBillingTypeEinzelrechnung(formikProps.values.billingType) && (
                <Row>
                  <Col span={24}>
                    <FormItemWithoutColon name={incomingInvoiceFormFields.requestedPaymentDate} label="Gewünschtes Zahlungsdatum">
                      <DatePicker
                        size="middle"
                        id={incomingInvoiceFormFields.requestedPaymentDate}
                        name={incomingInvoiceFormFields.requestedPaymentDate}
                        style={{ width: '100%' }}
                        disabled={shouldRestore}
                        format={DATE_DISPLAY_FORMAT_DEFAULT}
                      />
                    </FormItemWithoutColon>
                  </Col>
                </Row>
              )}

              <Row>
                <Col span={24}>
                  <FormItemWithoutColon name={incomingInvoiceFormFields.sepaMandatReference} label="SEPA Mandatsreferenz">
                    <Input
                      disabled={shouldRestore || !isPaymentMethodLastschrift(formikProps.values.paymentMethod)}
                      size="middle"
                      name={incomingInvoiceFormFields.sepaMandatReference}
                    />
                  </FormItemWithoutColon>
                </Col>
              </Row>
            </Col>
          </Row>
        }
      />
      <Divider />
    </>
  );
};

export default PaymentBlockForm;
