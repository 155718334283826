import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateOrUpdateColumnSettingsMutationVariables = Types.Exact<{
  input: Types.ColumnSettingsCreateOrUpdateInput;
}>;

export type CreateOrUpdateColumnSettingsMutation = {
  createOrUpdateColumnSettings: {
    data: { filterIdentifier: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteColumnSettingsMutationVariables = Types.Exact<{
  filterIdentifier: Types.Scalars['String']['input'];
}>;

export type DeleteColumnSettingsMutation = {
  deleteColumnSettings: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateOrUpdateColumnSettingsDocument = gql`
  mutation createOrUpdateColumnSettings($input: ColumnSettingsCreateOrUpdateInput!) {
    createOrUpdateColumnSettings(input: $input) {
      data {
        filterIdentifier
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateOrUpdateColumnSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateColumnSettingsMutation, CreateOrUpdateColumnSettingsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOrUpdateColumnSettingsMutation, CreateOrUpdateColumnSettingsMutationVariables>(
    CreateOrUpdateColumnSettingsDocument,
    options
  );
}
export type CreateOrUpdateColumnSettingsMutationHookResult = ReturnType<typeof useCreateOrUpdateColumnSettingsMutation>;
export type CreateOrUpdateColumnSettingsMutationResult = Apollo.MutationResult<CreateOrUpdateColumnSettingsMutation>;
export type CreateOrUpdateColumnSettingsMutationOptions = Apollo.BaseMutationOptions<
  CreateOrUpdateColumnSettingsMutation,
  CreateOrUpdateColumnSettingsMutationVariables
>;
export const DeleteColumnSettingsDocument = gql`
  mutation deleteColumnSettings($filterIdentifier: String!) {
    deleteColumnSettings(filterIdentifier: $filterIdentifier) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteColumnSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteColumnSettingsMutation, DeleteColumnSettingsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteColumnSettingsMutation, DeleteColumnSettingsMutationVariables>(DeleteColumnSettingsDocument, options);
}
export type DeleteColumnSettingsMutationHookResult = ReturnType<typeof useDeleteColumnSettingsMutation>;
export type DeleteColumnSettingsMutationResult = Apollo.MutationResult<DeleteColumnSettingsMutation>;
export type DeleteColumnSettingsMutationOptions = Apollo.BaseMutationOptions<DeleteColumnSettingsMutation, DeleteColumnSettingsMutationVariables>;
