import { FormFields } from '../../../../helpers/formikHelper';
import { TVertagVposIndexedValueVerarbeitungQueryParams } from './filtersQueryParams';
import { GenerierlaufEntryExitCode, ValuePreservationType } from '../../../../types';

export type VertragVposIndexedValueVerarbeitungEntryFiltersFormValues = {
  objektId?: string;
  bestandseinheitIdList?: string[];
  vertragId?: string;
  valuePreservationType?: ValuePreservationType;
  indexSeriesId?: string;
  exitCodeList?: GenerierlaufEntryExitCode[];
};

export const vertragVposIndexedValueVerarbEntryListingFiltersFormFields: FormFields<VertragVposIndexedValueVerarbeitungEntryFiltersFormValues> = {
  objektId: 'objektId',
  bestandseinheitIdList: 'bestandseinheitIdList',
  vertragId: 'vertragId',
  valuePreservationType: 'valuePreservationType',
  indexSeriesId: 'indexSeriesId',
  exitCodeList: 'exitCodeList',
};

export const vertragVposIndexedValueVerarbEntryListingFiltersFormInitialValues = (
  queryParams: TVertagVposIndexedValueVerarbeitungQueryParams
): VertragVposIndexedValueVerarbeitungEntryFiltersFormValues => ({
  objektId: queryParams.objektId,
  bestandseinheitIdList: queryParams.bestandseinheitIdList,
  vertragId: queryParams.vertragId,
  valuePreservationType: queryParams.valuePreservationType,
  indexSeriesId: queryParams.indexSeriesId,
  exitCodeList: queryParams.exitCodeList,
});

export const mapFormValuesToVertragVposIndexedValueVerarbeitungFilters = (
  formValues: VertragVposIndexedValueVerarbeitungEntryFiltersFormValues
): TVertagVposIndexedValueVerarbeitungQueryParams => ({
  objektId: formValues.objektId,
  bestandseinheitIdList: formValues.bestandseinheitIdList,
  vertragId: formValues.vertragId,
  valuePreservationType: formValues.valuePreservationType,
  indexSeriesId: formValues.indexSeriesId,
  exitCodeList: formValues.exitCodeList,
});
