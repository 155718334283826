import React, { FC, useEffect } from 'react';
import { FormikProps, useField } from 'formik';
import { Space } from 'antd';
import { OnlineBankingFormValues } from './bankstatementOnlineBankingFormMapper';
import FileUploadArea from '../../../../../components/FileUpload/FileUploadArea';
import usePermission from '../../../../../shared/Auth/usePermission';
import { useAuth } from '../../../../../shared/Auth/authContext';
import { FormFileReference } from '../../../../../components/FileUpload/formHelpers';
import { useFileInfoForFirmendatenQuery } from '../../../../Document/gql/FileStorageQueries.types';
import { FileInfo } from '../../../../../types';
import { loadPreviewForMitarbeiter, LoadPreviewForMitarbeiterResponse } from '../../../../Document/useFileDownloadAsyncForDocument';
import FileUploadView from '../../../../../components/FileUpload/FileUploadView';

type Props = {
  firmendatenId: string;
  formikProps: FormikProps<OnlineBankingFormValues>;
  name: string; // formik: name of the file which gets uploaded
  fileId?: string | null; // formik: fileId of already saved file coming from backend
  nameForTempBildFileId: string; // formik: name of the fileId of mitarbeiter file after selected from modal
  disabled?: boolean;
};

const OnlineBankingFormUpload: FC<Props> = ({ formikProps, firmendatenId, name, nameForTempBildFileId, fileId, disabled }) => {
  const { isAdmin } = usePermission();
  const { mitarbeiter } = useAuth();
  const [{ value: file }] = useField<FormFileReference | undefined>(name);
  const [{ value: tempFileId }] = useField<string | undefined>(nameForTempBildFileId);

  const { data } = useFileInfoForFirmendatenQuery({
    variables: {
      firmendatenId,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      fileId: fileId!,
    },
    skip: !fileId,
  });
  const fileFromFileStorage = data?.getFileInfoForFirmendaten.data;

  const onDelete = () => {
    formikProps.setFieldValue(name, undefined);
    formikProps.setFieldValue(nameForTempBildFileId, undefined);
    formikProps.setFieldValue(`${name}FileId`, undefined);
  };

  const onSelectFile = (data: FileInfo) => {
    formikProps.setFieldValue(nameForTempBildFileId, data.fileId);
  };

  useEffect(() => {
    if (tempFileId && !isAdmin) {
      loadPreviewForMitarbeiter({ mitarbeiterId: mitarbeiter?.mitarbeiterId, fileId: tempFileId }).then((maybePreview) => {
        const preview = maybePreview as LoadPreviewForMitarbeiterResponse;
        const headerContentDisposition = preview.headers.get('Content-Disposition');
        const regexPattern = /filename="([^"]+)"/;
        const matchFilename = headerContentDisposition?.match(regexPattern);

        const filename = matchFilename?.[1] ?? 'filename';
        formikProps.setFieldValue(name, { filename, file: preview.blob, isUpload: true });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempFileId]);

  let fileView;
  if ((file && file?.filename) || (fileFromFileStorage && fileFromFileStorage?.name)) {
    const filename = file?.filename || fileFromFileStorage?.name;
    fileView = (
      <Space direction="vertical">
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        <FileUploadView filename={filename!} onDelete={onDelete} />
      </Space>
    );
  }

  return fileView ? (
    <>{fileView}</>
  ) : (
    <FileUploadArea<OnlineBankingFormValues>
      formikProps={formikProps}
      name={name}
      disabled={disabled}
      validateFile={() => true} // The file format is not validated on the client side due to requirement from PO
      onSelectFileSetFieldValue={onSelectFile}
    />
  );
};

export default OnlineBankingFormUpload;
