import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../styles/pdfStyles';
import { MahnungLine } from '../../mahnung-types';
import { columnWidthsAndAlignment } from './styles/mahnung-table-styles';

type ColumnTitlesProps = {
  titles: MahnungLine;
};
const ColumnTitles: FC<ColumnTitlesProps> = ({ titles }) => (
  <View
    style={[
      pdfStyles.row,
      pdfStyles.textNormal,
      pdfStyles.borderBottomSmall,
      {
        paddingTop: '3mm',
        fontWeight: 'bold',
      },
    ]}
  >
    <Text
      style={[pdfStyles.textNormal, { width: columnWidthsAndAlignment.belegnummer.width, textAlign: columnWidthsAndAlignment.belegnummer.textAlign }]}
    >
      {titles.belegnummer}
    </Text>
    <Text
      style={[pdfStyles.textNormal, { width: columnWidthsAndAlignment.belegdatum.width, textAlign: columnWidthsAndAlignment.belegdatum.textAlign }]}
    >
      {titles.belegdatum}
    </Text>
    <Text
      style={[
        pdfStyles.textNormal,
        { width: columnWidthsAndAlignment.rechnungsbetrag.width, textAlign: columnWidthsAndAlignment.rechnungsbetrag.textAlign },
      ]}
    >
      {titles.rechnungsbetrag}
    </Text>
    <Text style={[pdfStyles.textNormal, { width: columnWidthsAndAlignment.zahlung.width, textAlign: columnWidthsAndAlignment.zahlung.textAlign }]}>
      {titles.zahlung}
    </Text>
    <Text
      style={[pdfStyles.textNormal, { width: columnWidthsAndAlignment.restbetrag.width, textAlign: columnWidthsAndAlignment.restbetrag.textAlign }]}
    >
      {titles.restbetrag}
    </Text>
    <Text
      style={[pdfStyles.textNormal, { width: columnWidthsAndAlignment.mahnstufe.width, textAlign: columnWidthsAndAlignment.mahnstufe.textAlign }]}
    >
      {titles.mahnstufe}
    </Text>
  </View>
);

export default ColumnTitles;
