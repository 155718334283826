import * as Yup from 'yup';
import { entityIsRequired, entityMaxCharLength } from '../../../components/message/validationMsg';
import { ticketSendEmailFormFields } from './ticketSendEmailFormMapper';

export const ticketSendEmailFormValidationSchema = Yup.object().shape({
  [ticketSendEmailFormFields.subject]: Yup.string().required(entityIsRequired('Betreff')).max(255, entityMaxCharLength('Betreff', 255)),
  [ticketSendEmailFormFields.body]: Yup.string().required(entityIsRequired('Textkörper')),
  [ticketSendEmailFormFields.toList]: Yup.array().of(
    Yup.object().shape({
      contactId: Yup.string().nullable(),
      emailAddress: Yup.string().nullable().email('E-Mail-Adresse muss gültig sein'),
    })
  ),
  [ticketSendEmailFormFields.ccList]: Yup.array().of(
    Yup.object().shape({
      contactId: Yup.string().nullable(),
      emailAddress: Yup.string().nullable().email('E-Mail-Adresse muss gültig sein'),
    })
  ),
  [ticketSendEmailFormFields.bccList]: Yup.array().of(
    Yup.object().shape({
      contactId: Yup.string().nullable(),
      emailAddress: Yup.string().nullable().email('E-Mail-Adresse muss gültig sein'),
    })
  ),
});
