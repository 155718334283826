import { FC } from 'react';
import { useToggle } from '../../../../../hooks/useToggle';
import VPosIndexationAgreementButtonsAndModal from './ListingButtonsAndModal/VPosIndexationAgreementButtonsAndModal';
import IndexationAgreementVPosTable from './Table/Level1/IndexationAgreementVPosTable';
import { useVertragVposIndexationAgreementVorschreibungspositionListQuery } from './gql/VPosIndexationAgreementQueries.types';

type Props = { bestandseinheitId: string; objektId: string; vertragId: string };

const VPosIndexationAgreementListing: FC<Props> = ({ bestandseinheitId, objektId, vertragId }) => {
  const [showArchived, toggleShowArchived] = useToggle();

  const { data, loading, refetch } = useVertragVposIndexationAgreementVorschreibungspositionListQuery({
    variables: {
      bestandseinheitId,
      objektId,
      vertragId,
      includeArchiviert: showArchived,
    },
  });

  const indexationAgreementVPosList = data?.getVertragVposIndexationAgreementVorschreibungspositionList.data;

  return (
    <>
      <VPosIndexationAgreementButtonsAndModal
        showArchived={showArchived}
        toggleShowArchived={toggleShowArchived}
        objektId={objektId}
        bestandseinheitId={bestandseinheitId}
        vertragId={vertragId}
      />
      <IndexationAgreementVPosTable
        indexationAgreementVPosList={indexationAgreementVPosList}
        loading={loading}
        objektId={objektId}
        bestandseinheitId={bestandseinheitId}
        vertragId={vertragId}
        onListChange={refetch}
      />
    </>
  );
};

export default VPosIndexationAgreementListing;
