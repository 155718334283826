import React, { FC } from 'react';
import { Space } from 'antd';
import DataWithShortenedTextAndExtraInfo from '../../../../components/Helpers/DataWithShortenedTextAndExtraInfo';
import StatusBadge from '../../../../components/Status/StatusBadge';
import DocumentStatusTag from '../../../../components/Status/DocumentStatus/DocumentStatusTag';
import { capitalizeFirstLetter } from '../../../../helpers/stringHelper';
import { AuftragStatusDetails, Eigenbeleg, UmbuchungInfo } from '../../../../types';
import PaidTag from './PaidTag';

type RowEigenbelegStatusProps = {
  eigenbeleg: Eigenbeleg;
};

const RowEigenbelegStatus: FC<RowEigenbelegStatusProps> = ({ eigenbeleg }) => {
  const { buchungsanweisungStatus, paid: isPaid } = eigenbeleg;

  return (
    <Space size={4}>
      <DataWithShortenedTextAndExtraInfo maxTextLength={'Belegstatus'.length} text="Belegstatus" extraTexts={buildAuftragStatusTexts(eigenbeleg)}>
        {() => (isPaid ? <PaidTag /> : <DocumentStatusTag documentStatus={eigenbeleg.status} />)}
      </DataWithShortenedTextAndExtraInfo>

      {eigenbeleg.deviationDetails ? (
        <DataWithShortenedTextAndExtraInfo
          maxTextLength={'Umbuchung notwendig'.length}
          text="Umbuchung notwendig"
          alternativeText="Umbuchung notwendig"
          extraTexts={buildUmbuchungTexts(eigenbeleg)}
        >
          {() => buchungsanweisungStatus && <StatusBadge entityStatus={buchungsanweisungStatus} />}
        </DataWithShortenedTextAndExtraInfo>
      ) : (
        buchungsanweisungStatus && <StatusBadge entityStatus={buchungsanweisungStatus} />
      )}
    </Space>
  );
};

const buildAuftragStatusTexts = (eigenbeleg: Eigenbeleg) => eigenbeleg.auftragStatusDetailsList.map(buildAuftragStatusText);

const buildAuftragStatusText = (auftragStatusDetails: AuftragStatusDetails) =>
  `${auftragStatusDetails.fakturierungsperiode.monat}/${auftragStatusDetails.fakturierungsperiode.jahr}: ${
    capitalizeFirstLetter(auftragStatusDetails.eigenbelegStatus.text) + auftragStatusDetails.eigenbelegStatus.text.slice(1)
  }`;

const buildUmbuchungTexts = (eigenbeleg: Eigenbeleg) => eigenbeleg.deviationDetails?.umbuchungInfoList.map(buildUmbuchungText);

const buildUmbuchungText = (umbuchungInfo: UmbuchungInfo) =>
  `${umbuchungInfo.fakturierungsperiode.monat}/${umbuchungInfo.fakturierungsperiode.jahr}: ${umbuchungInfo.kontoKeyIst.nummer} -> ${umbuchungInfo.kontoKeySoll.nummer}`;

export default RowEigenbelegStatus;
