import React, { FC, useState } from 'react';
import { Divider, Space, Typography } from 'antd';
import { VertragInfoFeldType } from '../../../../../types';
import VertragInfoFeldTypeSelect from './VertragInfoFeldTypeSelect';
import WeiterePersonCreateForm from './WeiterePerson/Create/WeiterePersonCreateForm';
import KuendigungsinfosCreateForm from './Kuendigungsinfos/Create/KuendigungsinfosCreateForm';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
};

const VertragInfoFeldCreateForm: FC<Props> = ({ objektId, bestandseinheitId, vertragId, onSuccess, onCancel }) => {
  const [type, setType] = useState<VertragInfoFeldType>();

  let content;
  if (type === VertragInfoFeldType.WeiterePerson) {
    content = (
      <WeiterePersonCreateForm
        onSuccess={onSuccess}
        onCancel={onCancel}
        objektId={objektId}
        bestandseinheitId={bestandseinheitId}
        vertragId={vertragId}
      />
    );
  }
  if (type === VertragInfoFeldType.Kuendigungsinfos) {
    content = (
      <KuendigungsinfosCreateForm
        onSuccess={onSuccess}
        onCancel={onCancel}
        objektId={objektId}
        bestandseinheitId={bestandseinheitId}
        vertragId={vertragId}
      />
    );
  }

  return (
    <>
      <Space style={{ marginBottom: '16px' }}>
        <Typography.Text>Art der Information</Typography.Text>
        <VertragInfoFeldTypeSelect onChange={setType} />
      </Space>
      <Divider />
      {content}
    </>
  );
};

export default VertragInfoFeldCreateForm;
