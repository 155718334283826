import React, { FC } from 'react';
import { Typography } from 'antd';
import { FormikProps } from 'formik';
import BuchungskreisSelect from './Selects/BuchungskreisSelect';
import {
  bookingSuggestionFormFields,
  BookingSuggestionFormValues,
  bSBuchungsanweisungFormFields,
  BSBuchungsanweisungFormValuesDescriptions,
} from '../Form/buchungsmaskeFormMapper';
import PaymentBelegSymbolSelect from '../../../BelegSymbol/Payment/PaymentBelegSymbolSelect';
import PaymentBuchungTypeSelect from '../../../BuchungType/Payment/PaymentBuchungTypeSelect';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import { PaymentFibuBuchungStatusTuple } from '../../../../types';
import { StyledSpaceForGeneraInfoBlock } from '../../../BookingDetails/shared/styled/StyledGeneralInfoBlock.style';

type Props = {
  formikProps: FormikProps<BookingSuggestionFormValues>;
  fibuBuchungStatus: PaymentFibuBuchungStatusTuple;
};

const GeneralInfoBlock: FC<Props> = ({ formikProps, fibuBuchungStatus }) => {
  return (
    <StyledSpaceForGeneraInfoBlock align="start" direction="vertical">
      <FormItemWithoutColon
        key={bSBuchungsanweisungFormFields.buchungskreisId}
        name={`${bookingSuggestionFormFields.buchungsanweisung}.${bSBuchungsanweisungFormFields.buchungskreisId}`}
        label={BSBuchungsanweisungFormValuesDescriptions.buchungskreisId}
      >
        <BuchungskreisSelect
          name={`${bookingSuggestionFormFields.buchungsanweisung}.${bSBuchungsanweisungFormFields.buchungskreisId}`}
          formikProps={formikProps}
        />
      </FormItemWithoutColon>
      <FormItemWithoutColon
        key={bSBuchungsanweisungFormFields.belegSymbol}
        name={`${bookingSuggestionFormFields.buchungsanweisung}.${bSBuchungsanweisungFormFields.belegSymbol}`}
        label={BSBuchungsanweisungFormValuesDescriptions.belegSymbol}
      >
        <PaymentBelegSymbolSelect name={`${bookingSuggestionFormFields.buchungsanweisung}.${bSBuchungsanweisungFormFields.belegSymbol}`} />
      </FormItemWithoutColon>
      <FormItemWithoutColon
        key={bSBuchungsanweisungFormFields.buchungType}
        name={`${bookingSuggestionFormFields.buchungsanweisung}.${bSBuchungsanweisungFormFields.buchungType}`}
        label={BSBuchungsanweisungFormValuesDescriptions.buchungType}
      >
        <PaymentBuchungTypeSelect
          name={`${bookingSuggestionFormFields.buchungsanweisung}.${bSBuchungsanweisungFormFields.buchungType}`}
          formikProps={formikProps}
          disabled={!formikProps.values.buchungsanweisung.belegSymbol} // relevant to manuel booking suggestion mask
        />
      </FormItemWithoutColon>
      <FormItemWithoutColon key="status" label="Status" name="status">
        <Typography.Text>{fibuBuchungStatus.text}</Typography.Text>
      </FormItemWithoutColon>
    </StyledSpaceForGeneraInfoBlock>
  );
};

export default GeneralInfoBlock;
