import { ContactFormValues, mapFormValuesToContactList } from '../../../../shared/components/Contact/contactMapper';
import { UserCreateInput } from '../../../../types';

export interface AdminCreateFormValues {
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  emailList: [ContactFormValues];
  phoneList: [ContactFormValues];
}

export const adminCreateFormInitialValues: AdminCreateFormValues = {
  username: '',
  email: '',
  firstname: '',
  lastname: '',
  emailList: [{ wert: '' }],
  phoneList: [{ wert: '' }],
};

export const mapValuesToAdminCreate = (values: AdminCreateFormValues): UserCreateInput => ({
  admin: true,
  username: values.username,
  email: values.email,
  firstname: values.firstname,
  lastname: values.lastname,
  contactList: mapFormValuesToContactList(values.emailList, values.phoneList),
});
