import { Dayjs, OpUnitType } from 'dayjs';
import { FieldHelperProps, FieldInputProps, FieldMetaProps, FormikHelpers } from 'formik';
import { dayjsCustom } from './dayjsCustom';

export const updateFormikField = (formik: FormikHelpers<any>, name: string, value: any) => {
  formik.setFieldValue(name, value);
  formik.setFieldTouched(name);
};

export const updateFormikFieldError = (formik: FormikHelpers<any>, name: string, value: string | undefined, shouldValidate = true) => {
  if (typeof value === 'string') {
    formik.setFieldError(name, value);
  }
  formik.setFieldTouched(name, true, shouldValidate);
};

/* date helpers */
export const mapDateStringToFormDateValue = (dateString: string): Dayjs => {
  const dateFormat = 'YYYY-MM-DD';
  const date = dayjsCustom(dateString, dateFormat, true);
  if (!date.isValid()) {
    throw new Error(`dateString is not a valid "${dateFormat}" date: ${dateString}`);
  }
  return date;
};

export const mapFormDateValueToDateString = (formDateValue: string | Dayjs, format = 'YYYY-MM-DD'): string => {
  const date = dayjsCustom(formDateValue);
  if (!date.isValid()) {
    throw new Error(`formDateValid is not a valid ISO_8601 date: ${formDateValue}`);
  }
  return date.format(format);
};

export const mapFormDateValueToDateStringWithStartOf = (formDateValue: string | Dayjs, startOf: OpUnitType, format = 'YYYY-MM-DD'): string => {
  const date = dayjsCustom(formDateValue).startOf(startOf);
  return mapFormDateValueToDateString(date, format);
};

export const mapFormDateValueToDateStringWithEndOf = (formDateValue: string | Dayjs, endOf: OpUnitType, format = 'YYYY-MM-DD'): string => {
  const date = dayjsCustom(formDateValue).endOf(endOf);
  return mapFormDateValueToDateString(date, format);
};
/* end of date helpers */

// Formik defines validationSchema type this way on <Formik /> that why it is okay if we do the same
export type FormikYupValidationSchema = any | (() => any);

type Properties<T> = keyof T;

/*
Use this utility type to make the name props for Formik inputs and Ant.Design FormItem "typesafe". See usages for example.
This way if you make a change in a *FieldValues type (adding/removing/renaming new field) you have to adjust your *FormFields utility type as well
hence you can't forget to update the name prop for Formik inputs and Ant.Design FormItem
 */
export type FormFields<T> = {
  [P in Properties<T>]-?: P;
};

// Formik-AntD

export type FormikAntDValidateStatuses = 'success' | 'warning' | 'error' | 'validating' | '';

export type FormikFieldHookProps<T> = [FieldInputProps<T>, FieldMetaProps<T>, FieldHelperProps<T>];

export type TFormattedDecimal = number | '';
