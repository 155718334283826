import { FC } from 'react';
import HistoryListingTable from '../../../History/HistoryListingTable';
import { HistoryType } from '../../../History/shared/HistoryModal';
import { useObjektVorschreibungspositionChangeHistoryListQuery } from '../gql/ObjektVorschreibungPositionQueries.types';

type Props = {
  objektId: string;
  objektVorschreibungspositionId: string;
};

const ObjektVorschreibungsPositionChangeHistoryListingTable: FC<Props> = ({ objektId, objektVorschreibungspositionId }) => {
  const { data, loading } = useObjektVorschreibungspositionChangeHistoryListQuery({ variables: { objektId, objektVorschreibungspositionId } });
  const historyList = data?.getObjektVorschreibungspositionChangeHistoryList.data;

  return (
    <HistoryListingTable
      historyType={HistoryType.Change}
      historyList={historyList}
      loading={loading}
      filterIdPrefix="objekt-vorschreibungsposition"
    />
  );
};

export default ObjektVorschreibungsPositionChangeHistoryListingTable;
