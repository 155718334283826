import React, { FC } from 'react';
import { Avatar, Tooltip } from 'antd';
import { AvatarSize } from 'antd/lib/avatar/AvatarContext';
import { FibuBelegSymbolTuple, PaymentBelegSymbolTuple } from '../../../types';
import avatarColorForBelegSymbol from './avatarColorForBelegSymbol';

type Props = {
  belegSymbol: FibuBelegSymbolTuple | PaymentBelegSymbolTuple;
  size?: AvatarSize | undefined;
};

/**
 * <h2>Usage</h2>
 * For Avatars with abbreviation based on FibuBelegSymbol delivered from Backend. <br />
 * Often in listings.
 */
const AvatarBasedOnBelegSymbolWithTooltip: FC<Props> = ({ belegSymbol, size }) => (
  <Tooltip title={belegSymbol.text}>
    <Avatar shape="square" style={{ backgroundColor: avatarColorForBelegSymbol(belegSymbol.value) }} size={size}>
      {belegSymbol.abbreviation}
    </Avatar>
  </Tooltip>
);

export default AvatarBasedOnBelegSymbolWithTooltip;
