import React, { FC, useState } from 'react';
import { Flex } from 'rebass';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, Space, Tabs, TabsProps, Typography } from 'antd';
import { Spacer } from '../../../../components/Grid';
import { mapTextbausteinListToFormValues, TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';
import HauseigentuemerAbrechnungTemplate from '../../../../pages/PDFTemplates/templates/hauseigentuemerAbrechnung/HauseigentuemerAbrechnungTemplate';
import { AbrechnungsdefinitionType } from '../../../../types';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import AbrDefTemplateVersionViewAbrKreisList from './AbrDefTemplateVersionViewAbrKreisList';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';
import { generatePathToAbrechnungsdefinitionTemplateVersionUpdatePage } from '../../abrDefTemplateUriPaths';
import { HeAbrechnungsdefinitionVersionFieldsFragment } from '../../../Abrechnungsdefinition/gql/HeAbrDef/HeAbrDefVersionFragments.types';
import AbrDefTemplateKontenzuordnung from '../../shared/AbrDefTemplateKontenzuordnung';
import {
  createHeAbrechnungPDFTemplateData,
  mapTextbausteinToHeAbrechnungSelectableSection,
} from '../../../Abrechnungsdefinition/shared/Templates/HeTemplates/heAbrDefTemplateFormMapper';
import HeAbrDefVersionViewTabWithTemplate from '../../../Abrechnungsdefinition/shared/Templates/HeTemplates/HeAbrDefVersionViewTabWithTemplate';

type Props = {
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersion: HeAbrechnungsdefinitionVersionFieldsFragment;
  isHistoricized: boolean;
};

const HeAbrDefVersionView: FC<Props> = ({ abrechnungsdefinitionId, abrechnungsdefinitionVersion, isHistoricized }) => {
  const navigate = useNavigate();
  const [heAbrDefTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(abrechnungsdefinitionVersion.heAbrechnungTextbausteinList)
  );

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Abrechnungskreise',
      children: (
        <>
          <Spacer height={16} />
          <AbrDefTemplateVersionViewAbrKreisList
            abrechdefAbrechkreisList={abrechnungsdefinitionVersion.abrechdefAbrechkreisList}
            abrDefType={AbrechnungsdefinitionType.HeAbrechnung}
          />
        </>
      ),
    },
    {
      key: '2',
      label: 'Hauseigentümerabrechnung',
      children: (
        <HeAbrDefVersionViewTabWithTemplate
          outputOptions={abrechnungsdefinitionVersion.outputOptions}
          heAbrDefTemplateValues={heAbrDefTemplateValues}
          renderTemplatePreview={({ selectedTextbaustein }) => (
            <HauseigentuemerAbrechnungTemplate
              data={createHeAbrechnungPDFTemplateData(heAbrDefTemplateValues, abrechnungsdefinitionVersion.outputOptions)}
              selectedTextbaustein={mapTextbausteinToHeAbrechnungSelectableSection(selectedTextbaustein)}
            />
          )}
        />
      ),
    },
    {
      key: '4',
      label: 'Kontenzuordnung',
      children: (
        <AbrDefTemplateKontenzuordnung
          abrechnungsdefinitionVersion={abrechnungsdefinitionVersion}
          abrechnungsdefinitionId={abrechnungsdefinitionId}
          abrDefinitionType={AbrechnungsdefinitionType.HeAbrechnung}
          mode="onlyView"
        />
      ),
    },
  ];

  return (
    <>
      <Flex>
        <Space>
          <Typography.Text strong>Gültig ab</Typography.Text>
          <Typography.Text>
            <CustomFormattedDate value={abrechnungsdefinitionVersion.validFrom} />
          </Typography.Text>
        </Space>
        <Space style={{ marginLeft: '10px' }}>
          <Typography.Text strong>Kommentar</Typography.Text>
          <Typography.Text>{abrechnungsdefinitionVersion.kommentar}</Typography.Text>
        </Space>
      </Flex>
      <Divider />
      <Tabs items={items} />
      <Spacer height={16} />
      <Space style={{ width: '100%', justifyContent: 'end' }}>
        <Button onClick={() => navigate(URI_ANDROMEDA_SYS_SETTINGS.abrechnungsdefinitionPage)}>Schließen</Button>
        <Button
          type="primary"
          disabled={isHistoricized}
          onClick={() =>
            navigate(
              generatePathToAbrechnungsdefinitionTemplateVersionUpdatePage(
                abrechnungsdefinitionId,
                abrechnungsdefinitionVersion.abrechnungsdefinitionVersionId
              )
            )
          }
        >
          Bearbeiten
        </Button>
      </Space>
    </>
  );
};

export default HeAbrDefVersionView;
