import React, { FC } from 'react';
import { Typography } from 'antd';
import StreetAddressFormPart from '../../../../shared/components/Address/form/StreetAddressFormPart';
import ContactListFormPart from '../../../../shared/components/Contact/ContactListFormPart';
import ContactPersonListFormPart from '../../../../shared/components/ContactPerson/form/ContactPersonListFormPart';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import FieldHelpIconWithTooltip from '../../../../components/FieldHelpIcon/FieldHelpIconWithTooltip';

type FirmensitzFormPartProps = {
  kundenSystemId?: string;
  fieldNamePrefix?: string;
  fieldHelpSelectorEntityName: 'KundenSystem' | 'Firmendaten'; //Important to narrow down the type
};

const FirmensitzFormPart: FC<FirmensitzFormPartProps> = ({ kundenSystemId, fieldNamePrefix = '', fieldHelpSelectorEntityName }) => {
  /* FIELD HELP */
  const getFieldHelpTexKundensystem = useGetAppFieldHelpText<'KundenSystem'>('KundenSystem');
  const getFieldHelpTextFirmendaten = useGetAppFieldHelpText<'Firmendaten'>('Firmendaten');
  const isFieldHelpSelectorEntityNameKundenSystem = fieldHelpSelectorEntityName === 'KundenSystem';
  /* END OF FIELD HELP */

  return (
    <>
      <StreetAddressFormPart
        fieldNamePrefix={`${fieldNamePrefix}address.`}
        fieldHelpNames={
          isFieldHelpSelectorEntityNameKundenSystem
            ? {
                city: getFieldHelpTexKundensystem('KundenSystem.erstelltVonFirma.hauptAddress.city'),
                cityAdditionalInformation: getFieldHelpTexKundensystem('KundenSystem.erstelltVonFirma.hauptAddress.cityAdditionalInformation'),
                countryCodeIso2: getFieldHelpTexKundensystem('KundenSystem.erstelltVonFirma.hauptAddress.countryCodeIso2'),
                houseEntranceApartmentNumber: getFieldHelpTexKundensystem('KundenSystem.erstelltVonFirma.hauptAddress.houseEntranceApartmentNumber'),
                street: getFieldHelpTexKundensystem('KundenSystem.erstelltVonFirma.hauptAddress.street'),
                zipCode: getFieldHelpTexKundensystem('KundenSystem.erstelltVonFirma.hauptAddress.zipCode'),
              }
            : {
                city: getFieldHelpTextFirmendaten('Firmendaten.hauptAddress.city'),
                cityAdditionalInformation: getFieldHelpTextFirmendaten('Firmendaten.hauptAddress.cityAdditionalInformation'),
                countryCodeIso2: getFieldHelpTextFirmendaten('Firmendaten.hauptAddress.countryCodeIso2'),
                houseEntranceApartmentNumber: getFieldHelpTextFirmendaten('Firmendaten.hauptAddress.houseEntranceApartmentNumber'),
                street: getFieldHelpTextFirmendaten('Firmendaten.hauptAddress.street'),
                zipCode: getFieldHelpTextFirmendaten('Firmendaten.hauptAddress.zipCode'),
              }
        }
      />
      <Typography.Title level={5}>
        Kontakt
        <FieldHelpIconWithTooltip
          fieldHelp={
            isFieldHelpSelectorEntityNameKundenSystem
              ? getFieldHelpTexKundensystem('KundenSystem.erstelltVonFirma.contactList')
              : getFieldHelpTextFirmendaten('Firmendaten.contactList')
          }
        />
      </Typography.Title>
      <ContactListFormPart fieldNamePrefix={fieldNamePrefix} />
      <Typography.Title level={5}>
        Ansprechpartner
        <FieldHelpIconWithTooltip
          fieldHelp={
            isFieldHelpSelectorEntityNameKundenSystem
              ? getFieldHelpTexKundensystem('KundenSystem.erstelltVonFirma.contactPersonList')
              : getFieldHelpTextFirmendaten('Firmendaten.contactPersonList')
          }
        />
      </Typography.Title>
      <ContactPersonListFormPart kundenSystemId={kundenSystemId} fieldNamePrefix={fieldNamePrefix} />
    </>
  );
};

export default FirmensitzFormPart;
