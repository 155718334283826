import { dayjsCustom } from '../../../../helpers/dayjsCustom';

export const LS_IMPORT_BANKSTATEMENT_NOTIFICATION_KEY = 'importBankstatement';

// 60000 ms = 1 minute
const ONE_MINUTE_IN_MS = 60000;

export const isLessOrEqualOneMinute = (date1: string, date2: string) => {
  return Math.abs(dayjsCustom(date1).diff(dayjsCustom(date2))) <= ONE_MINUTE_IN_MS;
};

export const getImportStatementItemFromLocalStorage = () => localStorage.getItem(LS_IMPORT_BANKSTATEMENT_NOTIFICATION_KEY);

export const onRemoveImportBankstatementFromLocaleStorage = () => localStorage.removeItem(LS_IMPORT_BANKSTATEMENT_NOTIFICATION_KEY);

export type FileUploadLocaleStorage = {
  createTs: string;
  hasFinished?: boolean;
  hasError?: boolean;
};
