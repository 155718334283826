import React, { FC } from 'react';
import { Box, Flex } from 'rebass';
import { Divider, Typography } from 'antd';
import ZuordnungTableHeader from '../shared/ZuordnungTableHeader';
import ZuordnungKontoListViewAndEdit from './ZuordnungKontoListViewAndEdit';
import { ZuordnungColumnMode, ZuordnungKontoKlasseType, zuordnungListHeight } from '../shared/AbrKreisKontenzuordnungHelpers';
import ZuordnungColumnViewAndEdit from '../shared/ZuordnungColumn/ZuordnungColumnViewAndEdit';
import { AbrechnungsdefinitionVersion } from '../../Abrechnungsdefinition/shared/abrDefTypes';
import { AbrechnungsdefinitionType, Konto } from '../../../types';
import { ZuordnungKontoListingFormValues } from '../Form/zuordnungKontoListingFormMapper';
import FieldHelpIconWithTooltip from '../../../components/FieldHelpIcon/FieldHelpIconWithTooltip';

type Props = {
  mode: ZuordnungColumnMode;
  abrechnungsdefinitionVersion: AbrechnungsdefinitionVersion;
  abrechnungsdefinitionId: string;
  abrDefinitionType: AbrechnungsdefinitionType;
  isEinnahmenListOpen: boolean;
  toggleEinnahmenKontoList: () => void;
  isAusgabenListOpen: boolean;
  toggleAusgabenList: () => void;
  isVerrechnungListOpen: boolean;
  toggleVerrechnungList: () => void;
  einnahmenKontoList: Konto[];
  ausgabenKontoList: Konto[];
  verrechnungKontoList: Konto[];
  onValuesChange?: (isDirty: boolean) => void;
  onSubmit?: (formValues: ZuordnungKontoListingFormValues) => Promise<unknown>;
  fieldHelp?: string | null;
};

const AbrDefKontenzuordnungViewAndEdit: FC<Props> = ({
  mode,
  abrechnungsdefinitionVersion,
  abrechnungsdefinitionId,
  abrDefinitionType,
  einnahmenKontoList,
  ausgabenKontoList,
  verrechnungKontoList,
  isEinnahmenListOpen,
  isAusgabenListOpen,
  isVerrechnungListOpen,
  toggleEinnahmenKontoList,
  toggleAusgabenList,
  toggleVerrechnungList,
  onValuesChange,
  onSubmit,
  fieldHelp,
}) => {
  return (
    <Flex flexDirection="row" flexWrap="wrap">
      <Flex>
        <Box width={zuordnungListHeight.left}>
          <ZuordnungTableHeader>
            <Typography.Title level={5} style={{ paddingLeft: '16px' }}>
              Konto
              <FieldHelpIconWithTooltip fieldHelp={fieldHelp} />
            </Typography.Title>
          </ZuordnungTableHeader>
          <ZuordnungKontoListViewAndEdit
            title="Einnahmen"
            zuordnungKontoKlasseType={ZuordnungKontoKlasseType.EINNAHMEN}
            list={einnahmenKontoList}
            isCollapsed={isEinnahmenListOpen}
            collapse={toggleEinnahmenKontoList}
            keyForCollapse="1"
          />
          <Box height="16px" />
          <ZuordnungKontoListViewAndEdit
            zuordnungKontoKlasseType={ZuordnungKontoKlasseType.AUSGABEN}
            title="Ausgaben"
            list={ausgabenKontoList}
            isCollapsed={isAusgabenListOpen}
            collapse={toggleAusgabenList}
            keyForCollapse="2"
          />
          <Box height="16px" />
          <ZuordnungKontoListViewAndEdit
            title="Verrechnung"
            zuordnungKontoKlasseType={ZuordnungKontoKlasseType.VERRECHNUNG}
            list={verrechnungKontoList}
            isCollapsed={isVerrechnungListOpen}
            collapse={toggleVerrechnungList}
            keyForCollapse="3"
          />
        </Box>
        <Divider style={{ height: '100%', padding: 0, margin: 0 }} type="vertical" />
      </Flex>
      <Flex key={`${abrechnungsdefinitionVersion.abrechnungsdefinitionVersionId}-${abrechnungsdefinitionVersion.lastUpdateTs}`}>
        <ZuordnungColumnViewAndEdit
          editable
          title=""
          mode={mode}
          abrechnungsdefinitionId={abrechnungsdefinitionId}
          abrechnungsdefinitionVersionId={abrechnungsdefinitionVersion.abrechnungsdefinitionVersionId}
          abrDefVersionAbrechkreisList={abrechnungsdefinitionVersion.abrechdefAbrechkreisList}
          abrDefinitionType={abrDefinitionType}
          einnahmenKontoList={einnahmenKontoList}
          ausgabenKontoList={ausgabenKontoList}
          verrechnungList={verrechnungKontoList}
          isEinnahmenListOpen={isEinnahmenListOpen}
          isAusgabenListOpen={isAusgabenListOpen}
          isVerrechnungListOpen={isVerrechnungListOpen}
          zuordnungList={abrechnungsdefinitionVersion.kontoAbrechnungskreisList}
          onValuesChange={onValuesChange}
          withHeader={false}
          shouldSubmitOnChange
          onSubmit={onSubmit}
        />
      </Flex>
    </Flex>
  );
};

export default AbrDefKontenzuordnungViewAndEdit;
