import React, { useEffect, useState } from 'react';
import { Box } from 'rebass';
import { Input } from 'formik-antd';
import { useField, useFormikContext } from 'formik';
import MitarbeiterTooltip from '../../Card/MitarbeiterTooltip';
import { ITimeblock, IVersionable, IVersionableFormValues } from '../timelineCtx';
import FormItemWithoutColon from '../../Form/FormItemWithoutColon';

type Props<T extends IVersionable<T>, FormValues extends IVersionableFormValues> = {
  timeblock: ITimeblock<IVersionable<T>>;
  valuesDescriptions: Record<string, string>;
  name?: string;
  buildKommentarText?: (
    restInitialValues: FormValues,
    restValues: FormValues,
    valuesDescriptions: Record<string, string>,
    isValidFromChanged: boolean,
    currentKommentar: string
  ) => string;
};

/* Never use fieldHelp in this component - as per discussion with Assad */
function TimelineFormKommentar<T extends IVersionable<T>, FormValues extends IVersionableFormValues>({
  timeblock,
  valuesDescriptions,
  name = 'kommentar',
  buildKommentarText,
}: Props<T, FormValues>) {
  const { initialValues, values } = useFormikContext<{ kommentar: string } & FormValues>();
  const [, , { setValue: setKommentar }] = useField<string>(name);

  const [isKommentarChangedByUser, setIsKommentarChangedByUser] = useState(false);

  const isValidFromChanged = initialValues.validFrom !== timeblock.validFrom;

  useEffect(() => {
    const { kommentar: initialKommentar, ...restInitialValues } = initialValues;
    const { kommentar, ...restValues } = values;
    if (!isKommentarChangedByUser) {
      const buildKommentarFn = buildKommentarText ?? defaultBuildKommentarText;
      // @ts-ignore
      setKommentar(buildKommentarFn(restInitialValues, restValues, valuesDescriptions, isValidFromChanged, kommentar));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, values, isKommentarChangedByUser, valuesDescriptions]);

  return (
    <Box width={2 / 3}>
      <FormItemWithoutColon
        name={name}
        label={<MitarbeiterTooltip showsInitials userId={timeblock.createdBy} mitarbeiterId={timeblock.createdByMitarbeiterId} alignment="right" />}
        labelCol={{ span: 12 }}
      >
        <Input.TextArea
          rows={3}
          id={name}
          name={name}
          size="small"
          placeholder="Fügen Sie eine optionale Beschreibung hinzu..."
          onChange={() => {
            if (!isKommentarChangedByUser) {
              setIsKommentarChangedByUser(true);
            }
          }}
        />
      </FormItemWithoutColon>
    </Box>
  );
}

// function mapToTimelineDataSource<T extends IVersionable<T>>(dataSource: T[]): ITimeblock<T>[] {

function defaultBuildKommentarText<FormValues extends IVersionableFormValues>(
  restInitialValues: FormValues,
  restValues: FormValues,
  valuesDescriptions: Record<string, string>,
  isValidFromChanged: boolean,
  currentKommentar: string
) {
  const changedValuesDescriptions: string[] = [];
  if (restValues) {
    Object.keys(restInitialValues).forEach((key) => {
      // @ts-ignore
      const isValueChanged = key === 'validFrom' ? isValidFromChanged : restInitialValues[key] !== restValues[key];
      if (isValueChanged) {
        changedValuesDescriptions.push(valuesDescriptions[key]);
      } else if (changedValuesDescriptions.includes(key)) {
        const itemToDelete = changedValuesDescriptions.indexOf(key);
        changedValuesDescriptions.splice(itemToDelete);
      }
    });
  }
  return changedValuesDescriptions.length > 0 ? defaultCommentText(changedValuesDescriptions) : currentKommentar;
}

const defaultCommentText = (changedValuesDescriptions: string[]) =>
  changedValuesDescriptions.length !== 0 ? changedValuesDescriptions.join(', ').concat(' geändert') : '';

export default TimelineFormKommentar;
