import React, { FC } from 'react';
import { View, Text } from '@react-pdf/renderer';
import pdfStyles from '../styles/pdfStyles';
import { RechnungsEmpfaengerShippingLabel } from './types';

type Props = {
  rechnungsEmpfaengerShippingLabel: RechnungsEmpfaengerShippingLabel;
};

const RechnungsEmpfaengerBlock: FC<Props> = ({ rechnungsEmpfaengerShippingLabel }) => (
  <View style={[pdfStyles.column]}>
    <Text style={pdfStyles.lineBlock}>{rechnungsEmpfaengerShippingLabel.line1}</Text>
    <Text style={pdfStyles.lineBlock}>{rechnungsEmpfaengerShippingLabel.line2}</Text>
    <Text style={pdfStyles.lineBlock}>{rechnungsEmpfaengerShippingLabel.line3}</Text>
    {rechnungsEmpfaengerShippingLabel.line4 ? <Text style={pdfStyles.lineBlock}>{rechnungsEmpfaengerShippingLabel.line4}</Text> : null}
    {rechnungsEmpfaengerShippingLabel.line5 ? <Text style={pdfStyles.lineBlock}>{rechnungsEmpfaengerShippingLabel.line5}</Text> : null}
    {rechnungsEmpfaengerShippingLabel.line6 ? <Text style={pdfStyles.lineBlock}>{rechnungsEmpfaengerShippingLabel.line6}</Text> : null}
    {rechnungsEmpfaengerShippingLabel.line7 ? <Text style={pdfStyles.lineBlock}>{rechnungsEmpfaengerShippingLabel.line7}</Text> : null}
  </View>
);

export default RechnungsEmpfaengerBlock;
