import React, { FC } from 'react';
import { Box, Flex } from 'rebass';
import { Skeleton, Typography } from 'antd';
import DataWithShortenedTextAndExtraInfo from '../../../../components/Helpers/DataWithShortenedTextAndExtraInfo';
import { useBudgetingResultByVertragLevelQuery } from '../../gql/BudgetingQueries.types';
import BudgetingResultByVertragLevelList from './BudgetingResultByVertragLevelList';

type Props = {
  budgetingResultId: string;
  objektId: string;
};

const BudgetingResultByVertragLevelDetails: FC<Props> = ({ budgetingResultId, objektId }) => {
  const { data, loading } = useBudgetingResultByVertragLevelQuery({
    variables: {
      objektId,
      budgetingResultId,
    },
  });

  const budgetingResultByVertragLevel = data?.getBudgetingResultByVertragLevel.data ?? [];

  return (
    <Skeleton active loading={loading}>
      <Flex p="8px" backgroundColor="#f5f5f5" paddingRight="40px">
        <Box width={['70%']}>
          <Typography.Text strong>Vertragsart</Typography.Text>
        </Box>

        <Box width={['30%']} display="flex">
          <Box width={['100%']} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <DataWithShortenedTextAndExtraInfo text="Netto Summe" maxTextLength={12}>
              {(shortenedText) => <Typography.Text strong>{shortenedText}</Typography.Text>}
            </DataWithShortenedTextAndExtraInfo>
          </Box>
        </Box>
      </Flex>
      <BudgetingResultByVertragLevelList vertragLevelList={budgetingResultByVertragLevel} />
    </Skeleton>
  );
};

export default BudgetingResultByVertragLevelDetails;
