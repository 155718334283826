import * as Yup from 'yup';
import { postItFormFields, postItFormValuesDescriptions } from './PostItFormMapper';
import { entityIsRequired, entityMaxCharLength } from '../../message/validationMsg';

/* eslint-disable no-template-curly-in-string */
export const postItFormValidationSchema = Yup.object().shape({
  [postItFormFields.text]: Yup.string().required(entityIsRequired(postItFormValuesDescriptions.text)),
  [postItFormFields.titel]: Yup.string()
    .required(entityIsRequired(postItFormValuesDescriptions.titel))
    .max(100, entityMaxCharLength(postItFormValuesDescriptions.titel, 100)),
});
/* eslint-enable no-template-curly-in-string */
