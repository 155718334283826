import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { LabeledValue } from '../../../shared/types';
import LabelValue from '../../../shared/LabelValue';

const Zwischensumme: FC<{ data: LabeledValue }> = ({ data }) => (
  <LabelValue
    valueTuple={data}
    viewStyle={{ ...pdfStyles.borderBottomSmall, paddingTop: '5mm' }}
    labelStyle={{ fontWeight: 'bold' }}
    valueStyle={{ fontWeight: 'bold' }}
  />
);

export default Zwischensumme;
