import React, { FC, useEffect } from 'react';
import { Empty, Typography } from 'antd';
import { useTransactionInstructionListQuery } from '../../../../gql/Order/TransactionInstructionQueries.types';
import { PaymentType, TransactionInstruction } from '../../../../../../types';
import { transactionInstructionRowColumns } from './transactionInstructionTableColumns';
import NestedTableWithColSelector from '../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';

type Props = {
  paymentId: string;
  paymentType: PaymentType;
  isRowExpanded: boolean;
  parentRowKeys?: string[];
};

const TransactionInstructionRow: FC<Props> = ({ paymentId, paymentType, isRowExpanded, parentRowKeys }) => {
  const { data, loading, refetch } = useTransactionInstructionListQuery({
    variables: {
      paymentId,
    },
  });

  const transactionInstructionList = data?.getTransactionInstructionList.data ?? [];

  // UseEffect to refetch data when row gets expanded
  useEffect(() => {
    if (isRowExpanded) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRowExpanded]);

  return (
    <NestedTableWithColSelector<TransactionInstruction>
      parentRowKeys={parentRowKeys}
      visible={isRowExpanded}
      aligned={false}
      colWidth={160}
      level={2}
      subLevel={1}
      loading={loading}
      locale={{
        emptyText: <Empty description={<span>Keine Transaktionsanweisung vorhanden</span>} />,
      }}
      title={() => <Typography.Text style={{ paddingLeft: '80px', fontWeight: 'bold' }}>Transaktionsanweisung</Typography.Text>}
      dataSource={transactionInstructionList}
      columns={transactionInstructionRowColumns(paymentType)}
      expandable={{ rowExpandable: () => false }}
      rowKey={(record) => record.transactionInstructionId}
    />
  );
};

export default TransactionInstructionRow;
