import React, { FC } from 'react';
import { Empty } from 'antd';
import mahnungErstellenVerarbEntryTableColumns from './mahnungErstellenVerarbEntryTableColumns';
import { isVerarbeitungEntryTableRowExpandable } from '../../../verarbeitungHelpers';
import GenerierlaufStatusExpandedRow from '../../../shared/GenerierlaufStatusExpandedRow';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { MahnungErzeugenGenerierlaufEntry } from '../../../../../types';
import { TMahnungVerarbeitungQueryParams } from '../../shared/EntryListingFilters/filtersQueryParams';
import { usePaginationQueryParams } from '../../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../../hooks/useQueryWithPagingAndSorting';
import { useMahnungErzeugenGenerierlaufEntryListQuery } from '../../../gql/MahnungGenerierlauf/MahnungErzeugenGenerierlaufQueries.types';

type Props = {
  generierlaufId: string;
  queryParams: TMahnungVerarbeitungQueryParams;
};

const MahnungErstellenVerarbEntryTable: FC<Props> = ({ generierlaufId, queryParams }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useMahnungErzeugenGenerierlaufEntryListQuery,
    {
      variables: {
        generierlaufId,
        ...queryParams,
      },
      fetchPolicy: 'no-cache',
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const entryList = data?.getMahnungErzeugenGenerierlaufEntryList.data.contentList || [];

  return (
    <>
      <TableWithColSelector<MahnungErzeugenGenerierlaufEntry>
        locale={{
          emptyText: <Empty description={<span>Keine Mahnungen vorhanden</span>} />,
        }}
        loading={loading}
        dataSource={entryList}
        pagination={pagination}
        onChange={handleTableSorting}
        columns={mahnungErstellenVerarbEntryTableColumns}
        rowKey={(record) => record.generierlaufEntryId}
        size="small"
        expandable={{
          expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
          rowExpandable: isVerarbeitungEntryTableRowExpandable,
        }}
        filterIdentifier="verarbeitung-mahnung-erstellen"
      />
    </>
  );
};

export default MahnungErstellenVerarbEntryTable;
