import React, { DetailedHTMLProps, FC, HTMLAttributes, useEffect, useState } from 'react';
import { TableComponents } from 'rc-table/lib/interface';
import NestedTable, { NestedTableColProps, NestedTableProps } from '../NestedTable/NestedTable';
import { useNestedTableWithColSelector } from './NestedTableWithColSelectorContext';
import { getColumnTitle } from '../TableWithColSelector/tableWithColSelectorHelpers';
import { TableWithColSelectorColumnProps } from '../TableWithColSelector/TableWithColSelectorProps';

export type NestedTableWithColSelectorProps<T> = {
  parentRowKeys?: string[];
  visible?: boolean;
  subLevel?: number;
  columns: NestedTableWithColSelectorColProps<T>[];
  aligned?: boolean;
} & NestedTableProps<T>;

export type NestedTableWithColSelectorColProps<T> = NestedTableColProps<T> & TableWithColSelectorColumnProps<T>;

export type NestedTableLevel<T> = {
  level: number;
  subLevel: number;
  columns: NestedTableWithColSelectorColProps<T>[];
  parentRowKeys?: string[][] | undefined;
  aligned: boolean;
};

const NestedTableWithColSelector = <T extends Record<string, unknown>>({
  parentRowKeys,
  visible = true,
  level = 1,
  subLevel = 1,
  columns,
  aligned = true,
  ...restProps
}: NestedTableWithColSelectorProps<T>) => {
  const { state, dispatch } = useNestedTableWithColSelector();
  const components = getComponents<T>(restProps.components);

  useEffect(() => {
    // Add to current table props
    dispatch({ type: 'ADD_TABLE', payload: { level, subLevel, columns, aligned } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({ type: 'UPDATE_PARENT_ROW_KEYS', payload: { parentRowKeys, visible, level, subLevel } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const [filteredColumns, setFilteredColumns] = useState<NestedTableWithColSelectorColProps<T>[]>([]);

  useEffect(() => {
    if (!state.allLevels.length || !visible) return;
    setFilteredColumns(getVisibleColumns(state.allLevels, state.selectedColumnsToShowInTable, level, subLevel, state.displayLevelCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.displayLevelCount, state.selectedColumnsToShowInTable]);

  return (
    <NestedTable<T>
      key={JSON.stringify(restProps.dataSource || [].length)}
      components={components}
      columns={filteredColumns}
      level={level}
      {...restProps}
      loading={restProps.loading || !state.isColumnSelectorLoaded}
    />
  );
};

function getComponents<RecordType>(components?: TableComponents<RecordType>) {
  return {
    ...(components ?? {}),
    body: {
      ...(components?.body ?? {}),
      cell: BodyCellContentWrapper,
    },
    header: {
      ...(components?.body ?? {}),
      cell: HeaderCellContentWrapper,
    },
  };
}

const HeaderCellContentWrapper: FC<DetailedHTMLProps<HTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement> & { children: React.ReactNode }> = ({
  children,
  ...restProps
}) => {
  const classes = (restProps?.className ?? '').split(' ');
  const style = { ...(classes.includes('ant-table-cell-hidden') ? { display: 'none' } : {}) };

  return (
    <th {...restProps}>
      <div className="ant-table-cell-inner" style={style}>
        {children}
      </div>
    </th>
  );
};

const BodyCellContentWrapper: FC<DetailedHTMLProps<HTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement> & { children: React.ReactNode }> = ({
  children,
  ...restProps
}) => {
  const classes = (restProps?.className ?? '').split(' ');
  const style = { ...(classes.includes('ant-table-cell-hidden') ? { display: 'none' } : {}) };

  return (
    <td {...restProps}>
      <div className="ant-table-cell-inner" style={style}>
        {children}
      </div>
    </td>
  );
};

const getVisibleColumns = (
  allLevelsColumns: NestedTableLevel<any>[],
  selectedColumnsToShowInTable: NestedTableWithColSelectorColProps<any>[],
  level: number,
  subLevel: number,
  displayLevelCount: number
) => {
  const all: NestedTableLevel<any>[] = [];
  allLevelsColumns.forEach((cols) => {
    const c = cols.columns.flat().map((col) => {
      const visibleByTitle = selectedColumnsToShowInTable.find((selectedCol) => {
        const matchByTitle = getColumnTitle(selectedCol) === getColumnTitle(col);
        const mathByKey = selectedCol.key && selectedCol.key === col.key;
        return matchByTitle || mathByKey;
      });

      if (visibleByTitle && cols.level <= displayLevelCount) {
        return col;
      }
      return {
        ...col,
        className: 'ant-table-cell-hidden',
      };
    });

    all.push({ level: cols.level, subLevel: cols.subLevel, columns: c, aligned: cols.aligned });
  });

  // Remove indexes
  const indicesToRemove: number[] = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < all?.[0]?.columns.length; i++) {
    if (
      all.every(
        (levelData) =>
          // levelData.aligned &&
          levelData.columns?.[i]?.className?.split(' ').includes('ant-table-cell-hidden') === true
      )
    ) {
      indicesToRemove.push(i);
    }
  }

  const result = all.map((row) => ({
    ...row,
    columns: row.columns.filter((_, index) => !indicesToRemove.includes(index)),
  }));

  const levelData = result.find((levelData) => levelData.level === level && levelData.subLevel === subLevel);
  if (!levelData) return [];
  return (
    levelData.columns
      // Filter out hidden column when level (table) is not aligned and doesn't key the key 'empty'
      .filter(
        (col) => levelData.aligned || (!levelData.aligned && col.key === 'empty') || !col.className?.split(' ').includes('ant-table-cell-hidden')
      )
      // Add extra 32px (max padding for 5th level) width to first column if a column has a width (due to the indentation)
      .map((col, index) => (index !== 0 || !col.width ? col : { ...col, width: parseFloat(col.width as string) + 32 }))
  );
};

export default NestedTableWithColSelector;
