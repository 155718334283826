import React, { Reducer, useEffect, useReducer } from 'react';
import { Button } from 'antd';
import NestedTable, { NestedTableColProps, NestedTableProps } from '../NestedTable/NestedTable';
import { EditableTableProvider } from './editableTableContext';
import { EditableTableProps } from './editTableProps';
import editableTableReducer, { EditableTableAction, EditableTableState } from './editableTableReducer';
import { EditableTableRecordType } from '../EditableTableOld/EditableTableProps';
import { EditableRow } from './EditableRow';
import EditableCell from './EditableCell';
import { isNotNil } from '../../../helpers/assertionHelper';

const EditableTable = <TableRecordType extends Record<string, any> = any, TableRecordFormValuesType extends Record<string, any> = any>({
  dataSource,
  getRowKey,
  columns,
  validationSchema,
  mapToFormValues,
  onSubmit,
  newEntryRowToBeAdded,
  actionsWithoutAddButton,
  loading,
  ...restProps
}: EditableTableProps<TableRecordType, TableRecordFormValuesType> & Omit<NestedTableProps<TableRecordType>, 'columns'>) => {
  const [{ records, selectedRecord, isSubmitLoading }, dispatch] = useReducer<
    Reducer<EditableTableState<TableRecordType>, EditableTableAction<TableRecordType>>
  >(editableTableReducer, {
    records: [],
    selectedRecord: null,
    isSubmitLoading: false,
    getRowKey,
  });

  // ----editable table responsibility
  useEffect(() => {
    if (dataSource) dispatch({ type: 'INIT_RECORDS', records: dataSource });
  }, [dataSource]);
  // ----editable table responsibility

  const handleAdd = () => {
    if (newEntryRowToBeAdded) {
      dispatch({ type: 'ADD_RECORD', record: newEntryRowToBeAdded.defaultNewEntry });
    }
  };

  const handleUpdate = (selectedRecord: EditableTableRecordType<TableRecordType>) => dispatch({ type: 'SELECT_RECORD', selectedRecord });

  const handleCancel = () => dispatch({ type: 'CANCEL_EDIT' });

  const editableTableColumns = columns.map((col) => ({
    ...col,
    onCell: (record: TableRecordType) => ({
      record,
      editable: col.editable ? col.editable : false,
      renderInEditMode: col.renderInEditMode,
    }),
  })) as NestedTableColProps<TableRecordType>[];

  const addButton = newEntryRowToBeAdded ? (
    <Button type="primary" size="small" onClick={handleAdd} disabled={!!selectedRecord}>
      {newEntryRowToBeAdded.addButtonText}
    </Button>
  ) : undefined;

  const actions = (newEntryRowToBeAdded || actionsWithoutAddButton) && (
    <>
      {actionsWithoutAddButton && actionsWithoutAddButton}
      {newEntryRowToBeAdded && addButton}
    </>
  );

  const isRowInEditMode = (rowKey?: string | null) => (isNotNil(selectedRecord?.recordKey) ? selectedRecord.recordKey === rowKey : false);

  return (
    <EditableTableProvider<TableRecordType>
      selectedRecord={selectedRecord}
      cancelEdit={handleCancel}
      onUpdate={handleUpdate}
      isSubmitLoading={isSubmitLoading}
      isRowInEditMode={isRowInEditMode}
      validationSchema={validationSchema}
      mapToFormValues={mapToFormValues}
      onSubmit={onSubmit}
      dispatch={dispatch}
    >
      <NestedTable<TableRecordType>
        components={{
          body: {
            cell: EditableCell,
            row: EditableRow,
          },
        }}
        rowKey={(record) => record.recordKey}
        loading={loading}
        dataSource={records}
        columns={editableTableColumns}
        actions={actions}
        {...restProps}
      />
    </EditableTableProvider>
  );
};

export default EditableTable;
