import * as Yup from 'yup';
import { indAgreeIndexAnnualFormFields } from './indexAnnualFormMapper';
import {
  entityHasToBeNonNegative,
  entityIsRequired,
  entityMustBeSelected,
  entityMustBeSmallerThanNumber,
} from '../../../../../../../../../components/message/validationMsg';
import { indAgreeIndexThresholdFormFields } from '../IndexThreshold/indexThresholdFormMapper';
import { isThresholdTypePercent } from '../shared/indexationAgreementFormHelpers';

export const indexAnnualFormValidationSchema = Yup.object().shape({
  [indAgreeIndexAnnualFormFields.effectiveWithMonth]: Yup.string().nullable(),
  [indAgreeIndexAnnualFormFields.indexOld]: Yup.number().nullable(),
  [indAgreeIndexAnnualFormFields.indexSeriesId]: Yup.string().required(entityMustBeSelected('Indexreihe')),
  [indAgreeIndexThresholdFormFields.minimumLimit]: Yup.number().nullable(),
  [indAgreeIndexAnnualFormFields.monthOfIndexPoint]: Yup.string().when([indAgreeIndexAnnualFormFields.annualAverage], ([annualAverage], schema) =>
    !annualAverage ? schema.required(entityMustBeSelected('Indexwert aus Monat')) : schema.nullable()
  ),
  [indAgreeIndexAnnualFormFields.monthYearOld]: Yup.string().required(entityIsRequired('Monat und Jahr', true)),
  [indAgreeIndexThresholdFormFields.shareInPercentToApply]: Yup.number()
    .nullable()
    .min(0, entityHasToBeNonNegative('Anteil'))
    .max(100, entityMustBeSmallerThanNumber('Anteil', 100)),
  [indAgreeIndexAnnualFormFields.subsequentBillingObjektVorschreibungspositionId]: Yup.string().when(
    [indAgreeIndexAnnualFormFields.subsequentBilling],
    ([subsequentBilling], schema) => (subsequentBilling ? schema.required(entityMustBeSelected('Vorschreibungsposition')) : schema.nullable())
  ),
  [indAgreeIndexAnnualFormFields.thresholdType]: Yup.string().required(entityMustBeSelected('Typ')),
  [indAgreeIndexAnnualFormFields.threshold]: Yup.number()
    .required(entityIsRequired('Schwellenwert'))
    .when([indAgreeIndexThresholdFormFields.thresholdType], ([thresholdType], schema) =>
      isThresholdTypePercent(thresholdType)
        ? schema.min(0, entityHasToBeNonNegative('Schwellenwert')).max(100, entityMustBeSmallerThanNumber('Schwellenwert', 100))
        : schema.min(0, entityHasToBeNonNegative('Schwellenwert'))
    ),
  [indAgreeIndexAnnualFormFields.validFrom]: Yup.string().required(entityIsRequired('Monat und Jahr', true)),
});
