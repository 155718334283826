import React, { FC } from 'react';
import { MessageTwoTone, PhoneTwoTone } from '@ant-design/icons';
import { Space, Spin } from 'antd';
import { useRechtstraegerAddressesAndContactsQuery } from '../../Rechtstraeger/AddressesAndContacts/gql/AddressesAndContactsQueries.types';
import { AddressesAndContacts } from '../../../types';
import { IStatus } from '../../../helpers/statusHelper';
import { useCreateOutgoingCallLogMutation } from '../../CallLog/gql/CallLogMutations.types';
import { isNotNil } from '../../../helpers/assertionHelper';

type Props = {
  rechtstraegerId: string;
  rechtstraegerName: string;
  status?: IStatus;
};

const ContactsTooltipContent: FC<Props> = ({ rechtstraegerId, rechtstraegerName, status }) => {
  const [runOutgoingCallLog] = useCreateOutgoingCallLogMutation({ variables: { rechtstraegerId } });
  const { data, loading } = useRechtstraegerAddressesAndContactsQuery({ variables: { rechtstraegerId } });

  const addressesAndContacts = data?.getRechtstraegerAddressesAndContacts?.data;

  const phoneContact = findPhoneContact(addressesAndContacts);
  const mail = findMailContact(addressesAndContacts);

  const contactContent = loading ? (
    <Spin spinning size="small" />
  ) : (
    <>
      {isNotNil(phoneContact) && (
        <a
          href={`tel:${phoneContact.wert}`}
          onMouseUp={() => {
            runOutgoingCallLog({ variables: { rechtstraegerId, contactId: phoneContact.contactId } });
          }}
        >
          <PhoneTwoTone style={{ fontSize: '16px' }} />
        </a>
      )}
      {mail && (
        <a href={`mailto:${mail}`}>
          <MessageTwoTone style={{ fontSize: '16px' }} />
        </a>
      )}
    </>
  );

  return (
    <Space direction="vertical">
      <Space>
        {rechtstraegerName}
        {contactContent}
      </Space>
      {status && status.description}
    </Space>
  );
};

const findPhoneContact = (addressesAndContacts?: AddressesAndContacts) => {
  if (addressesAndContacts?.hauptPhoneContact) {
    return addressesAndContacts.hauptPhoneContact;
  } else if (addressesAndContacts?.phoneContactList?.length) {
    return addressesAndContacts.phoneContactList[0];
  }
  return null;
};

const findMailContact = (addressesAndContacts?: AddressesAndContacts) => {
  if (addressesAndContacts?.hauptEmailContact) {
    return addressesAndContacts.hauptEmailContact.wert;
  } else if (addressesAndContacts?.emailContactList?.length) {
    return addressesAndContacts.emailContactList[0].wert;
  }
  return '';
};

export default ContactsTooltipContent;
