import styled from 'styled-components';
import { FibuBuchungszeile } from '../../../../types';
import NestedTableWithColSelector, {
  NestedTableWithColSelectorProps,
} from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';

export const StyledNestedTableNoBorderWhenScrollY = styled(NestedTableWithColSelector)<NestedTableWithColSelectorProps<FibuBuchungszeile>>`
  .ant-table .ant-table-container::before,
  .ant-table .ant-table-container::after {
    width: 0 !important;
  }

  .ant-table-cell-border-top > td {
    border-top: 1px solid black;
  }

  .ant-table-cell-border-bottom {
    border-bottom: 1px solid black;
  }
`;
