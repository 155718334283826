/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import { entityMustBeSelected } from '../../../../components/message/validationMsg';
import { abrDefAbrKreisFormFields, abrDefAbrKreisListingFormFields } from './abrDefAbrKreisListingFormMapper';
import { isAbrKreisTypeInformation, isAbrKreisTypeRepFonds, isAbrKreisTypeVstKuerzung } from './abrDefAbrKreisListingFormHelpers';

export const bkAndWeAbrDefAbrKreisListFormValidationSchema = Yup.object().shape({
  [abrDefAbrKreisListingFormFields.abrechdefAbrechkreisList]: Yup.array()
    .of(
      Yup.object().shape({
        abrechnungskreisId: Yup.string().required(entityMustBeSelected('Abrechnungskreis')),
        aufteilungsschluesselId: Yup.string().when(abrDefAbrKreisFormFields.abrechnungskreisType, ([type], schema) =>
          !isAbrKreisTypeVstKuerzung(type) && !isAbrKreisTypeRepFonds(type) && !isAbrKreisTypeInformation(type)
            ? schema.required(entityMustBeSelected('Aufteilungsschlüssel'))
            : schema
        ),
        vorschreibungspositionId: Yup.string().when(abrDefAbrKreisFormFields.abrechnungskreisType, ([type], schema) =>
          !isAbrKreisTypeInformation(type) ? schema.required(entityMustBeSelected('Vorschreibungsposition')) : schema
        ),
      })
    )
    .min(1, 'Mindestens ein Abrechnungskreis muss hinzugefügt werden'),
});

export const heAndSubAbrDefAbrKreisListFormValidationSchema = Yup.object().shape({
  [abrDefAbrKreisListingFormFields.abrechdefAbrechkreisList]: Yup.array()
    .of(
      Yup.object().shape({
        abrechnungskreisId: Yup.string().required(entityMustBeSelected('Abrechnungskreis')),
        aufteilungsschluesselId: Yup.string().length(0, 'Aufteilungsschluessel darf nicht ausgewählt werden'),
        vorschreibungspositionId: Yup.string().length(0, 'Vorschreibungsposition darf nicht ausgewählt werden'),
      })
    )
    .min(1, 'Mindestens ein Abrechnungskreis muss hinzugefügt werden'),
});

/* eslint-enable no-template-curly-in-string */
