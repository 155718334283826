import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { MahnungVerarbeitungEntryListingFiltersFormValues, mahnungVerarbEntryListingFiltersFormFields } from './ListingFiltersFormMapper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import FiltersWithMax3Fields from '../../../../../components/Filters/FiltersWithMax3Fields';
import GenerierlaufObjektFormSelect from '../../../shared/Filters/Fibu/GenerierlaufObjektFormSelect';
import GenerierlaufEntryExitCodeListFormSelect from '../../../shared/Filters/Fibu/GenerierlaufEntryExitCodeListFormSelect';
import GenerierlaufRechnungsAusstellerFormSelect from '../../../shared/Filters/Fibu/GenerierlaufRechnungsAusstellerFormSelect';
import { FibuGenerierlaufType } from '../../../../../types';

type Props = {
  formikProps: FormikProps<MahnungVerarbeitungEntryListingFiltersFormValues>;
  generierlaufId: string;
  generierlaufType: FibuGenerierlaufType;
};

const ListingFilters: FC<Props> = ({ formikProps, generierlaufId, generierlaufType }) => (
  <FiltersWithMax3Fields
    hideTitle
    filters={
      <>
        <FormItemWithoutColon name={mahnungVerarbEntryListingFiltersFormFields.rechnungsausstellerId} label="Rechnungsaussteller">
          <GenerierlaufRechnungsAusstellerFormSelect
            name={mahnungVerarbEntryListingFiltersFormFields.rechnungsausstellerId}
            generierlaufId={generierlaufId}
            generierlaufType={generierlaufType}
            onChange={formikProps.submitForm}
            style={{ width: '200px' }}
          />
        </FormItemWithoutColon>
        <FormItemWithoutColon name={mahnungVerarbEntryListingFiltersFormFields.objektId} label="Objekt">
          <GenerierlaufObjektFormSelect
            name={mahnungVerarbEntryListingFiltersFormFields.objektId}
            rechnungsAusstellerId={formikProps.values.rechnungsausstellerId}
            generierlaufId={generierlaufId}
            generierlaufType={generierlaufType}
            style={{ width: '200px' }}
            onChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
        <FormItemWithoutColon name={mahnungVerarbEntryListingFiltersFormFields.exitCodeList} label="Exit Code Status">
          <GenerierlaufEntryExitCodeListFormSelect
            name={mahnungVerarbEntryListingFiltersFormFields.exitCodeList}
            generierlaufId={generierlaufId}
            generierlaufType={generierlaufType}
            style={{ width: '200px' }}
            onChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      </>
    }
  />
);

export default ListingFilters;
