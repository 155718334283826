import React, { FC } from 'react';
import { Empty } from 'antd';
import { SubAdministrationListEntry } from '../../../../types';
import NestedTableWithColSelector from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import NestedTableWithColSelectorWrapper from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelectorWrapper';
import objSubAdministrationTableColumns from './objSubAdministrationTableColumns';

type Props = {
  subAdministrationList: SubAdministrationListEntry[];
  loading: boolean;
  objektId: string;
};

const ObjSubAdministrationTable: FC<Props> = ({ subAdministrationList, loading, objektId }) => {
  return (
    <NestedTableWithColSelectorWrapper filterIdentifier="objekt-sub-abrechnung">
      <NestedTableWithColSelector<SubAdministrationListEntry>
        colWidth={150}
        level={1}
        locale={{
          emptyText: <Empty description={<span>Keine Eigentümer vorhanden</span>} />,
        }}
        loading={loading}
        dataSource={subAdministrationList}
        columns={objSubAdministrationTableColumns(objektId)}
        pagination={{ showSizeChanger: true, defaultPageSize: 30 }}
        rowKey={(record) => record.eigentuemerId}
        expandable={{
          rowExpandable: () => false,
        }}
      />
    </NestedTableWithColSelectorWrapper>
  );
};

export default ObjSubAdministrationTable;
