import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import TabPaneContent from '../../../components/Tabs/TabPaneContent';
import CallLogListing from './CallLogListing';

type Props = {
  rechtstraegerId: string;
};

const CallLogTab: FC<Props> = ({ rechtstraegerId }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Anrufprotokoll',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <CallLogListing rechtstraegerId={rechtstraegerId} />
        </TabPaneContent>
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default CallLogTab;
