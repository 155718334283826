import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { FormattedNumber } from 'react-intl';
import { ITimeblock } from '../../../../components/Timeline/timelineCtx';
import { BENutzflaecheVersionTimelineDataSourceEntry } from './nutzflaecheVersionTimelineMapper';
import TimelineCardContentInfoRow from '../../../../components/Timeline/Card/TimelineCardContentInfoRow';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

type NutzflaecheVersionCardContentProps = {
  timeblock: ITimeblock<BENutzflaecheVersionTimelineDataSourceEntry>;
};

const NutzflaecheVersionCardContent: FC<NutzflaecheVersionCardContentProps> = ({ timeblock }) => (
  <Space direction="vertical" style={{ width: '100%' }}>
    <TimelineCardContentInfoRow infoRowTitle="Gültig ab">
      <CustomFormattedDate value={timeblock.validFrom} />
    </TimelineCardContentInfoRow>
    <TimelineCardContentInfoRow infoRowTitle="Nutzfläche">
      <Typography.Text>
        <FormattedNumber value={timeblock.nutzflaeche} maximumFractionDigits={2}>
          {(text: string) => <>{`${text} m²`}</>}
        </FormattedNumber>
      </Typography.Text>
    </TimelineCardContentInfoRow>
  </Space>
);

export default NutzflaecheVersionCardContent;
