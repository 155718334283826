import React, { FC } from 'react';
import { Button, Drawer, Modal, Tooltip } from 'antd';
import { ControlOutlined, FileTextOutlined, NodeCollapseOutlined } from '@ant-design/icons';
import { Budgeting, BudgetingVPos } from '../../../../../types';
import { useBudgetingTransferToVPosMutation } from '../../../gql/BudgetingMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { showSuccessMsgOther } from '../../../../../components/message';
import { useToggle } from '../../../../../hooks/useToggle';
import BudgetingVPosAdjustAufteilungsschluesselForm from '../../Form/BudgetingVPosAdjustAufteilungsschluesselForm/BudgetingVPosAdjustAufteilungsschluesselForm';
import { isStatusArchived, isStatusVerarbeitet, IStatus } from '../../../../../helpers/statusHelper';
import TextinfoWirtschaftsplanForm from '../../Form/TextinfoWirtschaftsplanForm/TextinfoWirtschaftsplanForm';

type Props = {
  budgeting: Budgeting;
  budgetingVPos: BudgetingVPos;
  refetch: () => Promise<unknown>;
  isUpdating: boolean;
  updatingRowKey: string | null;
  setUpdatingRowKey: React.Dispatch<React.SetStateAction<string | null>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  budgetingVPosStatus: IStatus;
};

const BudgetingVPosTableColumnActions: FC<Props> = ({
  budgeting,
  budgetingVPos,
  refetch,
  isUpdating,
  updatingRowKey,
  setIsLoading,
  budgetingVPosStatus,
}) => {
  const [runTransferToVPos] = useBudgetingTransferToVPosMutation({
    variables: {
      budgetingId: budgeting.budgetingId,
      objektId: budgeting.objekt.objektId,
      input: { budgetingVPosId: budgetingVPos.budgetingVPosId },
    },
    onCompleted: () => {
      showSuccessMsgOther('Budgetierung Vorschreibungsposition aktualisiert.');
      setIsLoading(true);
      refetch().finally(() => {
        setIsLoading(false);
      });
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [isAdjustAufteilungsschluesselDrawerOpen, toggleIsAdjustAufteilungsschluesselDrawerOpen] = useToggle();
  const [isTextInfoDrawerOpen, toggleIsTextInfoDrawerOpen] = useToggle();

  const isUpdatingByCurrentRow = isUpdating && updatingRowKey === budgetingVPos.budgetingVPosId;
  const isUpdatingByNotRow = isUpdating && updatingRowKey === null;

  const isDisabled =
    isStatusArchived(budgetingVPosStatus) ||
    isUpdatingByCurrentRow ||
    isUpdatingByNotRow ||
    isStatusArchived(budgeting.status) ||
    isStatusVerarbeitet(budgeting.status);

  return (
    <>
      <Tooltip title="Vorgabe für Anpassung">
        <Button type="link" icon={<ControlOutlined />} disabled={isDisabled} onClick={toggleIsAdjustAufteilungsschluesselDrawerOpen} />
      </Tooltip>
      <Tooltip title="Wert in Budgetierung übernehmen" placement="topRight" align={{ offset: [10, 0] }}>
        <Button
          type="link"
          icon={<NodeCollapseOutlined />}
          disabled={isDisabled}
          onClick={() => showTransferToVPos(runTransferToVPos, budgetingVPos)}
        />
      </Tooltip>
      <Tooltip title="Textinfo Wirtschaftplan" placement="topRight" align={{ offset: [10, 0] }}>
        <Button
          disabled={isStatusArchived(budgetingVPosStatus) || isUpdatingByCurrentRow || isUpdatingByNotRow}
          type="link"
          target="_blank"
          icon={<FileTextOutlined />}
          onClick={toggleIsTextInfoDrawerOpen}
        />
      </Tooltip>
      <Drawer
        title={`Textinfo Wirtschaftsplan für ${budgetingVPos.bezeichnung}`}
        width={720}
        open={isTextInfoDrawerOpen}
        onClose={toggleIsTextInfoDrawerOpen}
        destroyOnClose
      >
        <TextinfoWirtschaftsplanForm
          budgeting={budgeting}
          budgetingVPos={budgetingVPos}
          onCancel={toggleIsTextInfoDrawerOpen}
          onSuccess={() => {
            setIsLoading(true);
            refetch().finally(() => {
              setIsLoading(false);
            });
            toggleIsTextInfoDrawerOpen();
          }}
        />
      </Drawer>
      <Drawer
        title="Vorgabe für Anpassung"
        width={720}
        open={isAdjustAufteilungsschluesselDrawerOpen}
        onClose={toggleIsAdjustAufteilungsschluesselDrawerOpen}
        destroyOnClose
      >
        <BudgetingVPosAdjustAufteilungsschluesselForm
          budgeting={budgeting}
          budgetingVPos={budgetingVPos}
          onCancel={toggleIsAdjustAufteilungsschluesselDrawerOpen}
          onSuccess={() => {
            setIsLoading(true);
            refetch().finally(() => {
              setIsLoading(false);
            });
            toggleIsAdjustAufteilungsschluesselDrawerOpen();
          }}
        />
      </Drawer>
    </>
  );
};

const showTransferToVPos = (runTransferToVPos: () => void, budgetingVPos: BudgetingVPos) =>
  Modal.confirm({
    title: `Möchten Sie den neuen Wert der Abrechnungskreise in die Vorschreibung "${budgetingVPos.bezeichnung}" übernehmen?"`,
    okText: 'Übernehmen',
    cancelText: 'Abbrechen',
    onOk() {
      return runTransferToVPos();
    },
  });

export default BudgetingVPosTableColumnActions;
