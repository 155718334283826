import React, { FC } from 'react';
import { Divider, Skeleton, Space, Typography } from 'antd';
import CardWithFooter from '../../../../components/Card';
import { SubAbrechnung } from '../../../../types';
import CardTitle from '../../../../components/Card/CardTitle';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import LinkWithStatus from '../../../../components/Link/LinkWithStatus';
import { pathsToObjektDetailsPage } from '../../../Objekt/objektUriPaths';
import StatusBadge from '../../../../components/Status/StatusBadge';
import SubAbrechnungCardActions from './SubAbrechnungCardActions';

type Props = {
  abrechnung: SubAbrechnung;
  loading: boolean;
  objektId: string;
};

const SubAbrechnungCard: FC<Props> = ({ abrechnung, loading, objektId }) => (
  <>
    <CardWithFooter
      loading={loading}
      style={{ minWidth: '320px' }}
      size="default"
      bordered={false}
      title={<CardTitle title={abrechnung.bezeichnung} status={abrechnung.status} />}
      extra={<SubAbrechnungCardActions abrechnung={abrechnung} objektId={objektId} />}
      createTs={abrechnung.createTs}
      userId={abrechnung.createdBy}
      mitarbeiterId={abrechnung.createdByMitarbeiterId}
    >
      <Skeleton active loading={loading}>
        <Space direction="horizontal">
          <Space align="baseline">
            <Typography.Text type="secondary">von</Typography.Text>
            <CustomFormattedDate value={abrechnung.abrechnungszeitraumVon} />
          </Space>
          <Space align="baseline">
            <Typography.Text type="secondary">bis</Typography.Text>
            <CustomFormattedDate value={abrechnung.abrechnungszeitraumBis} />
          </Space>
        </Space>
        <Divider dashed />
        <CardDetailsInfoRow infoRowTitle="Objekt">
          <LinkWithStatus
            status={abrechnung.objekt.status}
            text={abrechnung.objekt.kurzBezeichnung}
            to={pathsToObjektDetailsPage(abrechnung.objekt.objektId).objektDetails}
            maxTextLength={25}
          />
        </CardDetailsInfoRow>
        <Divider dashed />
        <CardDetailsInfoRow infoRowTitle="Subverwaltungsabrechnungen">
          <StatusBadge entityStatus={abrechnung.status} />
        </CardDetailsInfoRow>
      </Skeleton>
    </CardWithFooter>
  </>
);

export default SubAbrechnungCard;
