import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Col, Row, Space, Typography } from 'antd';
import { mapReportLogosToFormValues, reportLogosFormFields, ReportLogosFormValues } from './reportLogosFormMapper';
import ReportLogosFormUpload from './ReportLogosFormUpload';
import FormButtons from '../../../../../../components/Button/FormButtons';
import saveReportLogosSubmit from './handleSubmit';
import { validateFile } from './reportLogosHelpers';
import { ReportLogos } from '../../../../../../types';
import ErrorMessagePanel from '../../../../../../components/Error/ErrorMessagePanel';
import { useGetAppFieldHelpText } from '../../../../../FieldHelp/useGetFieldHelpText';
import FieldHelpIconWithTooltip from '../../../../../../components/FieldHelpIcon/FieldHelpIconWithTooltip';

type ReportLogosFormProps = {
  firmendatenId: string;
  onSuccess: () => void;
  onCancel: () => void;
  reportLogos?: ReportLogos;
};

const ReportLogosForm: FC<ReportLogosFormProps> = ({ firmendatenId, onSuccess, onCancel, reportLogos }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'ReportLogosRequest'>('ReportLogosRequest');

  return (
    <Formik<ReportLogosFormValues>
      initialValues={mapReportLogosToFormValues(reportLogos)}
      onSubmit={(values, { setSubmitting }) => {
        saveReportLogosSubmit(firmendatenId, values, onSuccess, setSubmitting);
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row>
            <Col span={6}>
              <Typography.Title level={5}>Logos</Typography.Title>
            </Col>
            <Col span={18}>
              <Space direction="vertical" size="large">
                <Space direction="vertical">
                  <Typography.Text>
                    Logo groß <FieldHelpIconWithTooltip fieldHelp={getFieldHelpText('ReportLogosRequest.logoGrossFileId')} />
                  </Typography.Text>
                  <ReportLogosFormUpload
                    firmendatenId={firmendatenId}
                    formikProps={formikProps}
                    name={reportLogosFormFields.logoGross}
                    nameForTempBildFileId={reportLogosFormFields.tempLogoGrossFileId}
                    fileId={formikProps.values.logoGrossFileId}
                    validateFile={(file) => validateFile(file, formikProps, reportLogosFormFields.logoGross, false)}
                    fieldHelpForAlignment={getFieldHelpText('ReportLogosRequest.logoGrossAlignment')}
                  />
                  {formikProps.errors.logoGross && <ErrorMessagePanel error={formikProps.errors.logoGross} />}
                </Space>
                <Space direction="vertical">
                  <Typography.Text>
                    Logo klein <FieldHelpIconWithTooltip fieldHelp={getFieldHelpText('ReportLogosRequest.logoKleinFileId')} />
                  </Typography.Text>
                  <ReportLogosFormUpload
                    firmendatenId={firmendatenId}
                    formikProps={formikProps}
                    name={reportLogosFormFields.logoKlein}
                    nameForTempBildFileId={reportLogosFormFields.tempLogoKleinFileId}
                    fileId={formikProps.values.logoKleinFileId}
                    validateFile={(file) => validateFile(file, formikProps, reportLogosFormFields.logoKlein, true)}
                    fieldHelpForAlignment={getFieldHelpText('ReportLogosRequest.logoKleinAlignment')}
                  />
                  {formikProps.errors.logoKlein && <ErrorMessagePanel error={formikProps.errors.logoKlein} />}
                </Space>
              </Space>
            </Col>
          </Row>
          <FormButtons onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} okText="Speichern" updateMode />
        </Form>
      )}
    </Formik>
  );
};

export default ReportLogosForm;
