import React, { FC } from 'react';
import { useFirmendatenKurzBezeichnungQuery } from '../../../Breadcrumb/gql/KurzbezeichnungQueries.types';
import { isStatusArchived } from '../../../../helpers/statusHelper';
import CurrentBasedData from '../../../../components/Helpers/CurrentBasedData';
import { IndexSeriesStatusTuple } from '../../../../types';

type Props = {
  firmendatenId?: string | null;
  status?: IndexSeriesStatusTuple | null;
};

const IndexSeriesVerwaltungColumn: FC<Props> = ({ firmendatenId, status }) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data } = useFirmendatenKurzBezeichnungQuery({ variables: { firmendatenId: firmendatenId! }, skip: !firmendatenId });
  const kurzbezeichnung = data?.getFirmendaten.data.kurzBezeichnung;

  return <CurrentBasedData text={kurzbezeichnung ?? 'System'} maxTextLength={20} isCurrent={status ? !isStatusArchived(status) : true} />;
};

export default IndexSeriesVerwaltungColumn;
