import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../shared/dataChangedSubscription';
import { DataChangedChangeType } from '../../types';

const useOnBankStatementDataChangedEvents = (key: string, callback: () => Promise<unknown>) => {
  useOnDataChangedEvent([
    {
      filter: {
        type: dataChangedSubscriptionTypes.bankStatement,
        changeType: DataChangedChangeType.Delete,
      },
      notificationDescription: 'Kontoauszüge wurden gerade gelöscht',
      key: `${key}-bank-statement-delete`,
      callback,
    },
  ]);
};

export default useOnBankStatementDataChangedEvents;
