import React, { FC } from 'react';
import { Button, Divider, Drawer, Dropdown, Modal, Space, Tag, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, EllipsisOutlined, HistoryOutlined, LockOutlined, UnlockOutlined, UserOutlined } from '@ant-design/icons';
import { ArchiveIcon } from '@radix-ui/react-icons';
import { MutationFunctionOptions } from '@apollo/client';
import { isStatusArchived, isStatusGesperrt } from '../../../../helpers/statusHelper';
import {
  showSuccessMsgArchived,
  showSuccessMsgDelete,
  showSuccessMsgEntsperrt,
  showSuccessMsgGesperrt,
  showSuccessMsgUnarchived,
} from '../../../../components/message';
import CardWithFooter from '../../../../components/Card';
import RechtstraegerBankDetails from '../../../BankDetails/RechtstraegerBankDetails/RechtstraegerBankDetails';
import { useGoBack } from '../../../../shared/GoBack/GoBackContext';
import FirmaForm from '../Form/FirmaForm';
import { useToggle } from '../../../../hooks/useToggle';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import {
  ArchiveFirmaMutation,
  ArchiveFirmaMutationVariables,
  DeleteFirmaMutation,
  DeleteFirmaMutationVariables,
  EntsperrenFirmaMutation,
  EntsperrenFirmaMutationVariables,
  SperrenFirmaMutation,
  SperrenFirmaMutationVariables,
  UnarchiveFirmaMutation,
  UnarchiveFirmaMutationVariables,
  useArchiveFirmaMutation,
  useDeleteFirmaMutation,
  useEntsperrenFirmaMutation,
  useSperrenFirmaMutation,
  useUnarchiveFirmaMutation,
} from '../gql/FirmaMutations.types';
import { URI_RECHTSTRAEGER_PAGE } from '../../rechtstraegerUriPaths';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import FirmaDetailedInfo from './FirmaDetailedInfo';
import FinanzSteuerparameterInfo from '../../Steuer/FinanzSteuerparameterInfo';
import CardTitle from '../../../../components/Card/CardTitle';
import { radixActionStyles } from '../../../../helpers/radixIconsStyles';
import RechtstraegerPostIt from '../../PostIt/RechtstraegerPostIt';
import FirmaChangeHistoryListingTable from './FirmaChangeHistoryListingTable';
import HistoryModal, { HistoryType } from '../../../History/shared/HistoryModal';
import { Firma } from '../../../../types';

type FirmaCardProps = {
  firma: Firma;
  refetch: () => void;
  isLoading: boolean;
  kundenSystemId: string;
  rechtstraegerId: string;
};

const FirmaCard: FC<FirmaCardProps> = ({ firma, refetch, isLoading, kundenSystemId, rechtstraegerId }) => {
  const [isCollapsed, onCollapse] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();
  const goBack = useGoBack(URI_RECHTSTRAEGER_PAGE);
  const entity = 'Firma';

  const [runDelete] = useDeleteFirmaMutation({
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      goBack();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runSperren] = useSperrenFirmaMutation({
    onCompleted: () => {
      showSuccessMsgGesperrt(entity);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runEntsperren] = useEntsperrenFirmaMutation({
    onCompleted: () => {
      showSuccessMsgEntsperrt(entity);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runArchive] = useArchiveFirmaMutation({
    onCompleted: () => {
      showSuccessMsgArchived(entity);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUnarchive] = useUnarchiveFirmaMutation({
    onCompleted: () => {
      showSuccessMsgUnarchived(entity);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <CardWithFooter
      loading={isLoading}
      style={{ minWidth: '320px' }}
      title={
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <CardTitle title={firma.kurzBezeichnung} status={firma.status} />
          <RechtstraegerPostIt rechtstraegerId={rechtstraegerId} postIt={firma.postIt} refresh={refetch} />
        </Space>
      }
      extra={
        <Dropdown
          menu={{ items: menuItems(firma, onCollapse, toggleChangeHistoryOpen, runSperren, runEntsperren, runDelete, runArchive, runUnarchive) }}
        >
          <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
        </Dropdown>
      }
      createTs={firma.createTs}
      userId={firma.createdBy}
      mitarbeiterId={firma.createdByMitarbeiterId}
    >
      <CardDetailsInfoRow
        infoRowTitle={
          <Space>
            <UserOutlined />
            {firma.salutation?.text ?? 'keine Anrede'}
          </Space>
        }
      >
        <Typography.Text>{firma.kurzBezeichnung}</Typography.Text>
      </CardDetailsInfoRow>

      <Divider dashed />
      <Typography.Title level={5}>Merkmale</Typography.Title>
      {firma.tagList.map((tag) => (
        <Tag key={tag}>{tag}</Tag>
      ))}
      <Divider dashed />
      <FinanzSteuerparameterInfo
        rechtstraegerSteuerpflicht={firma.rechtstraegerSteuerpflicht}
        rechtstraegerId={rechtstraegerId}
        reloadRechtstraeger={refetch}
      />
      <Divider dashed />
      <FirmaDetailedInfo firma={firma} />
      <Divider dashed />
      <RechtstraegerBankDetails rechtstraegerId={rechtstraegerId} fibuKontoCreatable={firma.fibuKontoCreatable} />
      <Drawer title="Firma bearbeiten" width={720} onClose={onCollapse} open={isCollapsed} destroyOnClose>
        <FirmaForm
          firma={firma}
          kundenSystemId={kundenSystemId}
          onSuccess={() => {
            refetch();
            onCollapse();
          }}
          onCancel={onCollapse}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <FirmaChangeHistoryListingTable firmaId={firma.rechtstraegerId} />
      </HistoryModal>
    </CardWithFooter>
  );
};

const menuItems = (
  firma: Firma,
  onCollapse: () => void,
  toggleChangeHistoryOpen: () => void,
  runSperren: (options?: MutationFunctionOptions<SperrenFirmaMutation, SperrenFirmaMutationVariables>) => Promise<any>,
  runEntsperren: (options?: MutationFunctionOptions<EntsperrenFirmaMutation, EntsperrenFirmaMutationVariables>) => Promise<any>,
  runDelete: (options?: MutationFunctionOptions<DeleteFirmaMutation, DeleteFirmaMutationVariables>) => Promise<any>,
  runArchive: (options?: MutationFunctionOptions<ArchiveFirmaMutation, ArchiveFirmaMutationVariables>) => Promise<any>,
  runUnarchive: (options?: MutationFunctionOptions<UnarchiveFirmaMutation, UnarchiveFirmaMutationVariables>) => Promise<any>
) => [
  {
    key: '1',
    label: 'Bearbeiten',
    onClick: onCollapse,
    disabled: isStatusGesperrt(firma.status),
    icon: <EditOutlined />,
  },
  {
    key: '2',
    label: 'Änderungsverlauf',
    onClick: toggleChangeHistoryOpen,
    icon: <HistoryOutlined style={radixActionStyles} />,
  },
  {
    key: '3',
    label: isStatusArchived(firma.status) ? 'Reaktivieren' : 'Archivieren',
    onClick: () => showConfirmArchive(firma, runArchive, runUnarchive),
    disabled: !!firma.deletable,
    icon: <ArchiveIcon style={radixActionStyles} />,
  },
  {
    key: '4',
    label: isStatusGesperrt(firma.status) ? 'Entsperren' : 'Sperren',
    onClick: () => showConfirmLock(firma, runSperren, runEntsperren),
    disabled: !!firma.deletable,
    icon: isStatusGesperrt(firma.status) ? <UnlockOutlined /> : <LockOutlined />,
  },
  {
    key: '5',
    label: 'Löschen',
    onClick: () => showConfirmDelete(firma, runDelete),
    disabled: !firma.deletable,
    icon: <DeleteOutlined />,
  },
];

const showConfirmArchive = (
  firma: Firma,
  runArchive: (options?: MutationFunctionOptions<ArchiveFirmaMutation, ArchiveFirmaMutationVariables>) => Promise<any>,
  runUnarchive: (options?: MutationFunctionOptions<UnarchiveFirmaMutation, UnarchiveFirmaMutationVariables>) => Promise<any>
) => {
  Modal.confirm({
    title: isStatusArchived(firma.status) ? 'Möchten Sie die Firma reaktivieren?' : 'Möchten Sie die Firma archivieren?',
    content: `${firma.kurzBezeichnung}`,
    okText: isStatusArchived(firma.status) ? 'Reaktivieren' : 'Archivieren',
    cancelText: 'Nein',
    onOk() {
      if (isStatusArchived(firma.status)) {
        return runUnarchive({ variables: { firmaId: firma.rechtstraegerId } });
      } else {
        return runArchive({ variables: { firmaId: firma.rechtstraegerId } });
      }
    },
  });
};

const showConfirmLock = (
  firma: Firma,
  runSperren: (options?: MutationFunctionOptions<SperrenFirmaMutation, SperrenFirmaMutationVariables>) => Promise<any>,
  runEntsperren: (options?: MutationFunctionOptions<EntsperrenFirmaMutation, EntsperrenFirmaMutationVariables>) => Promise<any>
) => {
  Modal.confirm({
    title: isStatusGesperrt(firma.status) ? 'Möchten Sie die Firma entsperren?' : 'Möchten Sie die Firma sperren?',
    content: `${firma.kurzBezeichnung}`,
    okText: isStatusGesperrt(firma.status) ? 'Entsperren' : 'Sperren',
    cancelText: 'Nein',
    onOk() {
      if (isStatusGesperrt(firma.status)) {
        return runEntsperren({ variables: { firmaId: firma.rechtstraegerId } });
      } else {
        return runSperren({ variables: { firmaId: firma.rechtstraegerId } });
      }
    },
  });
};

const showConfirmDelete = (
  firma: Firma,
  runDelete: (options?: MutationFunctionOptions<DeleteFirmaMutation, DeleteFirmaMutationVariables>) => Promise<any>
) => {
  Modal.confirm({
    title: 'Möchten Sie die Firma löschen?',
    content: `${firma.kurzBezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete({ variables: { firmaId: firma.rechtstraegerId } });
    },
  });
};

export default FirmaCard;
