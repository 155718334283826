import React, { FC } from 'react';
import { Empty } from 'antd';
import mahndefinitionTableColumns from './mahndefinitionTableColumns';
import TableWithColSelector from '../../../components/Table/TableWithColSelector/TableWithColSelector';
import { MahndefinitionListEntryWithStatus } from '../mahndefinitonUriPaths';

type Props = {
  mahndefinitionenList?: MahndefinitionListEntryWithStatus[];
  refetch: () => void;
};

const MahndefinitionTable: FC<Props> = ({ mahndefinitionenList, refetch }) => {
  return (
    <TableWithColSelector<MahndefinitionListEntryWithStatus>
      locale={{
        emptyText: <Empty description={<span>Keine Mahndefinitionen vorhanden</span>} />,
      }}
      size="small"
      dataSource={mahndefinitionenList}
      loading={!mahndefinitionenList}
      columns={mahndefinitionTableColumns(refetch)}
      rowKey={(record) => record.mahndefinitionId}
      filterIdentifier="sys-settings-mahndefinition"
    />
  );
};

export default MahndefinitionTable;
