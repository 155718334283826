import React, { FC } from 'react';
import { Typography } from 'antd';
import { Box, Flex } from 'rebass';
import { EuroAmount } from '../../../../components/Number';
import DataWithShortenedTextAndExtraInfo from '../../../../components/Helpers/DataWithShortenedTextAndExtraInfo';

type Props = {
  bezeichnung: string;
  sumBrutto: number;
  sumNetto: number;
};

const VPosForVertragLevelSummary: FC<Props> = ({ bezeichnung, sumBrutto, sumNetto }) => (
  <Flex flexDirection="row">
    <Box width={['50%']}>
      {/*FIXME: tooltipPlacement="topLeft" */}
      <DataWithShortenedTextAndExtraInfo text={bezeichnung} maxTextLength={15}>
        {(shortenedText) => <Typography.Text strong>{shortenedText}</Typography.Text>}
      </DataWithShortenedTextAndExtraInfo>
    </Box>
    <Box width={['50%']} textAlign="right">
      <Flex flex="1">
        <Box width={[1 / 2]}>
          <Typography.Text>
            <EuroAmount value={sumNetto} />
          </Typography.Text>
        </Box>
        <Box width={[1 / 2]}>
          <Typography.Text>
            <EuroAmount value={sumBrutto} />
          </Typography.Text>
        </Box>
      </Flex>
    </Box>
  </Flex>
);

export default VPosForVertragLevelSummary;
