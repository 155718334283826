import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useVertragVposIndexedValueStatusListQuery } from '../../gql/VertragVposIndexedValueStatusQueries.types';

const IndexedValueStatusSelect: FC<Omit<SelectProps, 'id'>> = ({ name, ...restProps }) => {
  const { data, loading } = useVertragVposIndexedValueStatusListQuery();
  const indexedValueStatusList = data?.getVertragVposIndexedValueStatusList.data;

  return (
    <Select
      size="small"
      id={name}
      name={name}
      loading={loading}
      placeholder="Status auswählen"
      mode="multiple"
      style={{ minWidth: '200px' }}
      allowClear
      {...restProps}
    >
      {indexedValueStatusList?.map((status) => (
        <Select.Option key={status.value} value={status.value}>
          {status.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default IndexedValueStatusSelect;
