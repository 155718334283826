import React, { FC } from 'react';
import TableListEmpty from '../../../../../components/Helpers/TableListEmpty';
import { ustKategorieTableColumns } from './ustKategorieTableColumns';
import UstKategorieTableExpandedRowTable from './UstKategorieTableExpandedRowTable';
import NestedTable from '../../../../../components/Table/NestedTable/NestedTable';
import { UstKategorieWithStatus } from '../../ustKategorieUriHelper';

type Props = {
  onCollapse: () => void;
  refetch: () => void;
  ustKategorieList?: UstKategorieWithStatus[];
  loading: boolean;
};

const UstKategorieTable: FC<Props> = ({ onCollapse, loading, refetch, ustKategorieList }) => (
  <NestedTable<UstKategorieWithStatus>
    level={1}
    locale={{
      emptyText: <TableListEmpty onClick={onCollapse} emptyTxt="Keine USt.-Kategorien vorhanden" btnTxt="USt.-Kategorie erstellen" />,
    }}
    loading={loading}
    scroll={{ x: 900 }}
    dataSource={ustKategorieList}
    columns={ustKategorieTableColumns(refetch)}
    rowKey={(record) => record.ustKategorieId}
    expandedRowKey="ustKategorieId"
    useHighlighting
    expandable={{
      expandedRowRender: (record) => <UstKategorieTableExpandedRowTable loading={loading} record={record} />,
    }}
  />
);

export default UstKategorieTable;
