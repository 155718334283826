import { isEmpty } from 'lodash';
import React, { FC } from 'react';
import { useToggle } from '../../../../hooks/useToggle';
import { useObjektVorschreibungspositionListQuery } from '../gql/ObjektVorschreibungPositionQueries.types';
import ObjektVorschreibungPositionTable from '../Table/ObjektVorschreibungPositionTable';
import ObjektVorschreibungPositionListingBtnAndModal from './ButtonsAndModal/ObjektVorschreibungPositionListingBtnAndModal';
import { Verwaltungsart } from '../../../../types';

type Props = {
  objektId: string;
  verwaltungsart: Verwaltungsart;
};

const ObjektVorschreibungPositionListing: FC<Props> = ({ objektId, verwaltungsart }) => {
  const [isCollapsed, onCollapse] = useToggle();
  const [showArchived, toggleShowArchived] = useToggle();

  const { data, refetch } = useObjektVorschreibungspositionListQuery({
    variables: {
      objektId,
      withDetails: true,
      includeArchiviert: showArchived,
    },
  });
  const vorschreibungsPositionList = data?.getObjektVorschreibungspositionList.data;
  const loading = !vorschreibungsPositionList;

  return (
    <>
      <ObjektVorschreibungPositionListingBtnAndModal
        objektId={objektId}
        isCollapsed={isCollapsed}
        onCollapse={onCollapse}
        showArchived={showArchived}
        toggleShowArchived={toggleShowArchived}
        showZuweisenBtn={!isEmpty(vorschreibungsPositionList)}
        refetch={refetch}
      />
      <ObjektVorschreibungPositionTable
        vorschreibungsPositionList={vorschreibungsPositionList}
        loading={loading}
        objektId={objektId}
        onCollapse={onCollapse}
        refetch={refetch}
        verwaltungsart={verwaltungsart}
      />
    </>
  );
};

export default ObjektVorschreibungPositionListing;
