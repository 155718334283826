import { FormFields } from '../../../../../helpers/formikHelper';
import { THeAbrechnungVerarbeitungQueryParams } from './filtersQueryParams';
import { GenerierlaufEntryExitCode } from '../../../../../types';

export type HeAbrechnungVerarbeitungEntryFiltersFormValues = {
  objektId?: string;
  exitCodeList?: GenerierlaufEntryExitCode[];
  hauseigentuemerId?: string;
};

export const heAbrechnungVerarbEntryListingFiltersFormFields: FormFields<HeAbrechnungVerarbeitungEntryFiltersFormValues> = {
  objektId: 'objektId',
  exitCodeList: 'exitCodeList',
  hauseigentuemerId: 'hauseigentuemerId',
};

export const heAbrechnungVerarbEntryListingFiltersFormInitialValues = (
  queryParams: THeAbrechnungVerarbeitungQueryParams
): HeAbrechnungVerarbeitungEntryFiltersFormValues => ({
  objektId: queryParams.objektId,
  exitCodeList: queryParams.exitCodeList,
  hauseigentuemerId: queryParams.hauseigentuemerId,
});

export const mapFormValuesToHeAbrechnungVerarbeitungFilters = (
  formValues: HeAbrechnungVerarbeitungEntryFiltersFormValues
): THeAbrechnungVerarbeitungQueryParams => ({
  objektId: formValues.objektId,
  exitCodeList: formValues.exitCodeList,
  hauseigentuemerId: formValues.hauseigentuemerId,
});
