import { FormikProps } from 'formik';
import React, { FC } from 'react';
import FiltersWith4OrMoreFields from '../../../../../components/Filters/FiltersWith4OrMoreFields';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { FiltersFormValues, listingFiltersFormFields, listingLabelList } from './listingFiltersFormMapper';
import { OrderGenerierlaufType } from '../../../../../types';
import GenerierlaufEntryExitCodeListSelect from '../../../shared/Filters/GenerierlaufEntryExitCodeListSelect';
import FromToFormDatePicker from '../../../../shared/FromToForm/FromToFormDatePicker';
import GenerierlaufBelegnummerFormSelect from '../../../shared/Filters/Order/GenerierlaufBelegnummerFormSelect';
import GenerierlaufObjektFormSelect from '../../../shared/Filters/Order/GenerierlaufObjektFormSelect';
import GenerierlaufRechnungsAusstellerFormSelect from '../../../shared/Filters/Order/GenerierlaufRechnungsAusstellerFormSelect';
import GenerierlaufRechnungsEmpfaengerFormSelect from '../../../shared/Filters/Order/GenerierlaufRechnungsEmpfaengerFormSelect';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
  generierlaufId: string;
};

const ListingFilters: FC<Props> = ({ formikProps, generierlaufId }) => {
  return (
    <FiltersWith4OrMoreFields
      hideTitle
      leftColumn={
        <>
          <FormItemWithoutColon name={listingFiltersFormFields.issuerId} label={listingLabelList.issuerId}>
            <GenerierlaufRechnungsAusstellerFormSelect
              name={listingFiltersFormFields.issuerId}
              generierlaufId={generierlaufId}
              generierlaufType={OrderGenerierlaufType.IncomingInvoiceBookingCreation}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={listingFiltersFormFields.payeeId} label={listingLabelList.payeeId}>
            <GenerierlaufRechnungsEmpfaengerFormSelect
              name={listingFiltersFormFields.payeeId}
              generierlaufId={generierlaufId}
              generierlaufType={OrderGenerierlaufType.IncomingInvoiceBookingCreation}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={listingFiltersFormFields.objektId} label={listingLabelList.objektId}>
            <GenerierlaufObjektFormSelect
              name={listingFiltersFormFields.objektId}
              generierlaufId={generierlaufId}
              generierlaufType={OrderGenerierlaufType.IncomingInvoiceBookingCreation}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
      rightColumn={
        <>
          <FormItemWithoutColon name={listingFiltersFormFields.invoiceNumber} label={listingLabelList.invoiceNumber}>
            <GenerierlaufBelegnummerFormSelect
              name={listingFiltersFormFields.invoiceNumber}
              generierlaufId={generierlaufId}
              generierlaufType={OrderGenerierlaufType.IncomingInvoiceBookingCreation}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name="rechnungsdatum" label={listingLabelList.invoiceDateFrom}>
            <FromToFormDatePicker
              format="day"
              fromName={listingFiltersFormFields.invoiceDateFrom}
              toName={listingFiltersFormFields.invoiceDateTo}
              fromOnChange={formikProps.submitForm}
              toOnChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={listingFiltersFormFields.exitCodeList} label={listingLabelList.exitCodeList}>
            <GenerierlaufEntryExitCodeListSelect name={listingFiltersFormFields.exitCodeList} onChange={formikProps.submitForm} />
          </FormItemWithoutColon>
        </>
      }
    />
  );
};

export default ListingFilters;
