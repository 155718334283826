import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import OffenePostenTable from './Table/Level1/OffenePostenTable';
import { useToggle } from '../../hooks/useToggle';
import { useQueryParams } from '../../hooks/useStringQueryParams';
import { TOffenePostenQueryParams, updateQueryParams } from './offenePostenUriPaths';
import {
  mapFormValuesToOffenePostenListingFilters,
  mapQueryParamsToFormValues,
  OffenePostenListingFiltersFormValues,
} from './Filters/offenePostenListingFiltersFormMapper';
import OffenePostenListingFilters from './OffenePostenListingFilters';
import { usePaginationQueryParams } from '../../hooks/usePaginationQueryParams';
import OffenePostenListSummary from './OffenePostenListSummary';

const OffenePostenListing = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TOffenePostenQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const [showAusgeziffert, setAusgeziffert] = useToggle(false);

  const setInitialQueryParams = () => {
    const filters = mapQueryParamsToFormValues(queryParams);
    updateQueryParams(navigate, mapFormValuesToOffenePostenListingFilters(filters), paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: OffenePostenListingFiltersFormValues) => {
    const filters = mapFormValuesToOffenePostenListingFilters(values);
    updateQueryParams(navigate, filters, paginationParams);
  };

  return (
    <>
      <Formik<OffenePostenListingFiltersFormValues>
        initialValues={mapQueryParamsToFormValues(queryParams)}
        onSubmit={(values) => {
          onChange(values);
        }}
      >
        {(formikProps) => (
          <OffenePostenListingFilters
            formikProps={formikProps}
            setAusgeziffert={setAusgeziffert}
            showAusgeziffert={showAusgeziffert}
            queryParams={queryParams}
          />
        )}
      </Formik>
      <OffenePostenListSummary queryParams={queryParams} showAusgeziffert={showAusgeziffert} />
      <OffenePostenTable showAusgeziffert={showAusgeziffert} queryParams={queryParams} paginationParams={paginationParams} />
    </>
  );
};
export default OffenePostenListing;
