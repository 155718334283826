import { URI_SYS_SETTINGS } from '../../constants/configurationUriPaths';
import { AufteilungsschluesselStatus, AufteilungsschluesselStatusTuple } from '../../types';
import { mapToQueryString, QUERY_PARAM_KEY } from '../../hooks/useStringQueryParams';

export const generatePathToAufteilunsschluesselPage = (
  aufteilungsschluesselId: string,
  aufteilungsschluesselStatus: AufteilungsschluesselStatusTuple
) =>
  `${URI_SYS_SETTINGS.aufteilungsschluesselPage}?${mapToQueryString({
    [QUERY_PARAM_KEY.SHOW_ARCHIVED]: aufteilungsschluesselStatus.value === AufteilungsschluesselStatus.Archiviert,
    [QUERY_PARAM_KEY.HIGHLIGHTED_ROW_KEY]: aufteilungsschluesselId,
  })}`;
