import { useParams } from 'react-router-dom';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../../shared/dataChangedSubscription';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { useAuth } from '../../../shared/Auth/authContext';
import { DataChangedChangeType } from '../../../types';

type RouteParams = { objektId: string };

const useOnBestandseinheitDataChangedEvents = (key: string, callback: () => Promise<unknown>, bestandseinheitIds: string[]) => {
  const { user } = useFDAuthorized();
  const { mitarbeiter } = useAuth();

  const { objektId } = useParams() as RouteParams;

  useOnDataChangedEvent([
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.bestandseinheit,
        params: {
          bestandseinheitId: bestandseinheitIds,
        },
        changeType: DataChangedChangeType.Update,
      },
      notificationDescription: 'Bestandseinheit wurde gerade verändert',
      key: `${key}-update`,
      callback,
    },
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.bestandseinheit,
        params: {
          objektId,
        },
        changeType: DataChangedChangeType.Create,
      },
      notificationDescription: 'Bestandseinheit wurde gerade erstellt',
      key: `${key}-create`,
      callback,
    },
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.bestandseinheit,
        params: {
          objektId,
        },
        changeType: DataChangedChangeType.Delete,
      },
      notificationDescription: 'Bestandseinheit wurde gerade gelöscht',
      key: `${key}-delete`,
      callback,
    },
  ]);
};

export { useOnBestandseinheitDataChangedEvents };
