import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useKontierungstabelleTemplateListQuery } from '../KontierungstabelleTemplate/gql/KontierungstabelleTemplateQueries.types';

type Props = SelectProps;

const KontierungstabelleTemplateSelect: FC<Props> = (props) => {
  const { loading, data } = useKontierungstabelleTemplateListQuery();
  const kontierungstabelleList = data?.getKontierungstabelleTemplateList.data ?? [];

  return (
    <Select
      size="small"
      id={props.name}
      loading={loading}
      placeholder="Kontierungstabelle auswählen"
      allowClear
      showSearch
      optionFilterProp="children"
      {...props}
    >
      {kontierungstabelleList.map((kontierungstabelle) => (
        <Select.Option key={kontierungstabelle.kontierungstabelleId} value={kontierungstabelle.kontierungstabelleId}>
          {kontierungstabelle.bezeichnung}
        </Select.Option>
      ))}
    </Select>
  );
};

export default KontierungstabelleTemplateSelect;
