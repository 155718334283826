import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTicketTemplateTemplateMutationVariables = Types.Exact<{
  input: Types.TicketTemplateCreateInput;
}>;

export type CreateTicketTemplateTemplateMutation = {
  createTicketTemplateTemplate: {
    data: { ticketTemplateId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateTicketTemplateTemplateMutationVariables = Types.Exact<{
  ticketTemplateId: Types.Scalars['ID']['input'];
  input: Types.TicketTemplateUpdateInput;
}>;

export type UpdateTicketTemplateTemplateMutation = {
  updateTicketTemplateTemplate: {
    data: { ticketTemplateId: string; dueDateText?: string | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteTicketTemplateTemplateMutationVariables = Types.Exact<{
  ticketTemplateId: Types.Scalars['ID']['input'];
}>;

export type DeleteTicketTemplateTemplateMutation = {
  deleteTicketTemplateTemplate: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type TicketTemplateTemplateCreateSubTicketTemplateMutationVariables = Types.Exact<{
  ticketTemplateId: Types.Scalars['ID']['input'];
  input: Types.TicketTemplateActionCreateSubTicketTemplateInput;
}>;

export type TicketTemplateTemplateCreateSubTicketTemplateMutation = {
  actionTicketTemplateTemplateCreateSubTicketTemplate: {
    data: { ticketTemplateId: string; dueDateText?: string | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type TicketTemplateTemplateChangePositionMutationVariables = Types.Exact<{
  ticketTemplateId: Types.Scalars['ID']['input'];
  input: Types.TicketTemplateActionChangePositionInput;
}>;

export type TicketTemplateTemplateChangePositionMutation = {
  actionTicketTemplateTemplateChangePosition: {
    data: { name: string; ticketTemplateId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateTicketTemplateTemplateDocument = gql`
  mutation CreateTicketTemplateTemplate($input: TicketTemplateCreateInput!) {
    createTicketTemplateTemplate(input: $input) {
      data {
        ticketTemplateId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateTicketTemplateTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTicketTemplateTemplateMutation, CreateTicketTemplateTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTicketTemplateTemplateMutation, CreateTicketTemplateTemplateMutationVariables>(
    CreateTicketTemplateTemplateDocument,
    options
  );
}
export type CreateTicketTemplateTemplateMutationHookResult = ReturnType<typeof useCreateTicketTemplateTemplateMutation>;
export type CreateTicketTemplateTemplateMutationResult = Apollo.MutationResult<CreateTicketTemplateTemplateMutation>;
export type CreateTicketTemplateTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateTicketTemplateTemplateMutation,
  CreateTicketTemplateTemplateMutationVariables
>;
export const UpdateTicketTemplateTemplateDocument = gql`
  mutation UpdateTicketTemplateTemplate($ticketTemplateId: ID!, $input: TicketTemplateUpdateInput!) {
    updateTicketTemplateTemplate(ticketTemplateId: $ticketTemplateId, input: $input) {
      data {
        ticketTemplateId
        dueDateText
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateTicketTemplateTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTicketTemplateTemplateMutation, UpdateTicketTemplateTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTicketTemplateTemplateMutation, UpdateTicketTemplateTemplateMutationVariables>(
    UpdateTicketTemplateTemplateDocument,
    options
  );
}
export type UpdateTicketTemplateTemplateMutationHookResult = ReturnType<typeof useUpdateTicketTemplateTemplateMutation>;
export type UpdateTicketTemplateTemplateMutationResult = Apollo.MutationResult<UpdateTicketTemplateTemplateMutation>;
export type UpdateTicketTemplateTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateTicketTemplateTemplateMutation,
  UpdateTicketTemplateTemplateMutationVariables
>;
export const DeleteTicketTemplateTemplateDocument = gql`
  mutation DeleteTicketTemplateTemplate($ticketTemplateId: ID!) {
    deleteTicketTemplateTemplate(ticketTemplateId: $ticketTemplateId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteTicketTemplateTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTicketTemplateTemplateMutation, DeleteTicketTemplateTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTicketTemplateTemplateMutation, DeleteTicketTemplateTemplateMutationVariables>(
    DeleteTicketTemplateTemplateDocument,
    options
  );
}
export type DeleteTicketTemplateTemplateMutationHookResult = ReturnType<typeof useDeleteTicketTemplateTemplateMutation>;
export type DeleteTicketTemplateTemplateMutationResult = Apollo.MutationResult<DeleteTicketTemplateTemplateMutation>;
export type DeleteTicketTemplateTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteTicketTemplateTemplateMutation,
  DeleteTicketTemplateTemplateMutationVariables
>;
export const TicketTemplateTemplateCreateSubTicketTemplateDocument = gql`
  mutation TicketTemplateTemplateCreateSubTicketTemplate($ticketTemplateId: ID!, $input: TicketTemplateActionCreateSubTicketTemplateInput!) {
    actionTicketTemplateTemplateCreateSubTicketTemplate(ticketTemplateId: $ticketTemplateId, input: $input) {
      data {
        ticketTemplateId
        dueDateText
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useTicketTemplateTemplateCreateSubTicketTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TicketTemplateTemplateCreateSubTicketTemplateMutation,
    TicketTemplateTemplateCreateSubTicketTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TicketTemplateTemplateCreateSubTicketTemplateMutation, TicketTemplateTemplateCreateSubTicketTemplateMutationVariables>(
    TicketTemplateTemplateCreateSubTicketTemplateDocument,
    options
  );
}
export type TicketTemplateTemplateCreateSubTicketTemplateMutationHookResult = ReturnType<
  typeof useTicketTemplateTemplateCreateSubTicketTemplateMutation
>;
export type TicketTemplateTemplateCreateSubTicketTemplateMutationResult =
  Apollo.MutationResult<TicketTemplateTemplateCreateSubTicketTemplateMutation>;
export type TicketTemplateTemplateCreateSubTicketTemplateMutationOptions = Apollo.BaseMutationOptions<
  TicketTemplateTemplateCreateSubTicketTemplateMutation,
  TicketTemplateTemplateCreateSubTicketTemplateMutationVariables
>;
export const TicketTemplateTemplateChangePositionDocument = gql`
  mutation TicketTemplateTemplateChangePosition($ticketTemplateId: ID!, $input: TicketTemplateActionChangePositionInput!) {
    actionTicketTemplateTemplateChangePosition(ticketTemplateId: $ticketTemplateId, input: $input) {
      data {
        name
        ticketTemplateId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useTicketTemplateTemplateChangePositionMutation(
  baseOptions?: Apollo.MutationHookOptions<TicketTemplateTemplateChangePositionMutation, TicketTemplateTemplateChangePositionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TicketTemplateTemplateChangePositionMutation, TicketTemplateTemplateChangePositionMutationVariables>(
    TicketTemplateTemplateChangePositionDocument,
    options
  );
}
export type TicketTemplateTemplateChangePositionMutationHookResult = ReturnType<typeof useTicketTemplateTemplateChangePositionMutation>;
export type TicketTemplateTemplateChangePositionMutationResult = Apollo.MutationResult<TicketTemplateTemplateChangePositionMutation>;
export type TicketTemplateTemplateChangePositionMutationOptions = Apollo.BaseMutationOptions<
  TicketTemplateTemplateChangePositionMutation,
  TicketTemplateTemplateChangePositionMutationVariables
>;
