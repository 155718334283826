import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Typography } from 'antd';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { pathsToRechtstraegerDetails } from '../../Rechtstraeger/rechtstraegerHelper';
import { Budgeting } from '../../../types';

type Props = {
  budgeting: Budgeting;
};

const BudgetingAbrDefInfoRows: FC<Props> = ({ budgeting }) => {
  const { eigentuemerAbrechnungsdefinition, mieterAbrechnungsdefinition } = budgeting;
  if (!eigentuemerAbrechnungsdefinition && !mieterAbrechnungsdefinition) return null;
  return (
    <>
      <Divider dashed />
      <Typography.Title level={5}>Abrechnungsdefinition</Typography.Title>
      {eigentuemerAbrechnungsdefinition && (
        <InfoRow
          title="Eigentümer"
          text={eigentuemerAbrechnungsdefinition.bezeichnung}
          rechtstraegerId={budgeting.objekt.rechnungsAussteller.rechtstraegerId}
        />
      )}
      {mieterAbrechnungsdefinition && (
        <InfoRow
          title="Mieter"
          text={mieterAbrechnungsdefinition.bezeichnung}
          rechtstraegerId={budgeting.objekt.rechnungsAussteller.rechtstraegerId}
        />
      )}
    </>
  );
};

type BudgetingCardDetailsInfoRowProps = {
  title: string;
  text: string;
  rechtstraegerId: string;
};

const InfoRow: FC<BudgetingCardDetailsInfoRowProps> = ({ title, text, rechtstraegerId }) => (
  <CardDetailsInfoRow infoRowTitle={title}>
    <DataWithShortenedText maxTextLength={25} text={text}>
      {(shortenedText) => (
        <Link target="_blank" to={pathsToRechtstraegerDetails(rechtstraegerId).rechnungAusstellerAbrDefTab}>
          {shortenedText}
        </Link>
      )}
    </DataWithShortenedText>
  </CardDetailsInfoRow>
);

export default BudgetingAbrDefInfoRows;
