import React, { FC } from 'react';
import { Drawer, Skeleton } from 'antd';
import { useToggle } from '../../../hooks/useToggle';
import CardWithFooter from '../../../components/Card';
import ObjektRechnungsausstellerWithBankDetails from './shared/ObjektRechnungsausstellerWithBankDetails';
import InnerCardObjektBasicInformation from './shared/InnerCardObjektBasicInformation';
import ObjektFotoUpload from './shared/ObjektFotoUpload';
import ObjektActions from './shared/ObjektActions';
import ObjektForm from './Form/ObjektForm';
import InnerCardAddressList from './shared/InnerCardAddressList';
import InnerCardTagList from '../../shared/InnerCardTagList';
import InnerCardTitle from './shared/InnerCardTitle';
import { isMietObjekt, isWegObjekt } from '../objektHelper';
import InnerCardSummeAnteile from './MietObjekt/InnerCardSummeAnteile';
import HistoryModal, { HistoryType } from '../../History/shared/HistoryModal';
import ObjektChangeHistoryListingTable from './ObjektChangeHistoryListingTable';
import { Objekt } from '../../../types';
import WeRechnungsausstellerWithBankDetails from './WeObjekt/WeRechnungsAusstellerWithBankDetails';

type Props = {
  objekt: Objekt;
  loading: boolean;
  onSuccess: () => void;
};

export const ObjektCard: FC<Props> = ({ objekt, loading, onSuccess }) => {
  const [isUpdateDrawerOpen, toggleUpdateDrawer] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  return (
    <>
      <CardWithFooter
        cover={<ObjektFotoUpload objektId={objekt.objektId} fotoFileId={objekt?.fotoFileId} onUploadSuccess={onSuccess} />}
        loading={loading}
        style={{ minWidth: '320px' }}
        size="default"
        bordered={false}
        title={<InnerCardTitle objekt={objekt} onSuccess={onSuccess} />}
        extra={
          <ObjektActions
            objekt={objekt}
            onSuccess={onSuccess}
            onClickUpdate={toggleUpdateDrawer}
            onClickChangeHistoryOpen={toggleChangeHistoryOpen}
          />
        }
        createTs={objekt.createTs}
        userId={objekt.createdBy}
        mitarbeiterId={objekt.createdByMitarbeiterId}
      >
        <Skeleton loading={loading}>
          <InnerCardObjektBasicInformation objekt={objekt} />

          {isMietObjekt(objekt.verwaltungsart.value) && (
            <InnerCardSummeAnteile
              objektId={objekt.objektId}
              summeAnteileId={objekt.objektSummeAnteile?.objektSummeAnteileId}
              onSuccess={onSuccess}
              warningList={objekt.warningList}
            />
          )}

          {objekt.tagList.length > 0 ? <InnerCardTagList tagList={objekt.tagList} /> : null}

          {objekt.addressList !== null ? <InnerCardAddressList addressList={objekt.addressList} /> : null}

          <ObjektRechnungsausstellerWithBankDetails
            rechnungsAusstellerBezeichnung={objekt.rechnungsAusstellerBezeichnung}
            rechnungsaussteller={objekt.rechnungsAussteller}
            objektId={objekt.objektId}
          />

          {isWegObjekt(objekt.verwaltungsart.value) && (
            <WeRechnungsausstellerWithBankDetails objektId={objekt.objektId} rechtstraegerId={objekt.rechnungsAussteller.rechtstraegerId} />
          )}
        </Skeleton>
      </CardWithFooter>
      {/* Drawer & Modals */}
      <Drawer title="Objekt bearbeiten" width={720} onClose={toggleUpdateDrawer} open={isUpdateDrawerOpen} destroyOnClose>
        <ObjektForm
          objekt={objekt}
          onSuccess={() => {
            toggleUpdateDrawer();
            onSuccess();
          }}
          onCancel={toggleUpdateDrawer}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <ObjektChangeHistoryListingTable objektId={objekt.objektId} />
      </HistoryModal>
    </>
  );
};

export default ObjektCard;
