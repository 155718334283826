import React, { FC, useEffect } from 'react';
import { Input, InputProps } from 'formik-antd';
import { FormikProps } from 'formik';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import { useGeneratePasswordQuery } from '../../gql/GeneratePasswordQueries.types';
import { validateNewPassword } from '../../../../shared/components/Password/passwordValidation';
import { EbicsUserFormValues } from './ebicsUserFormMapper';

type Props = { name: string; formikProps: FormikProps<EbicsUserFormValues> } & InputProps;

const EbicsUserPasswordFormInput: FC<Props> = ({ name, formikProps, ...restProps }) => {
  const { data } = useGeneratePasswordQuery();

  const generatedPassword = data?.generatePassword.data;

  useEffect(() => {
    if (!formikProps.values.password) {
      formikProps.setFieldValue(name, generatedPassword);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedPassword]);

  return (
    <FormItemWithoutColon name={name} label="Passwort">
      <Input.Password
        size="small"
        id={name}
        name={name}
        validate={validateNewPassword}
        placeholder="Passwort eingeben"
        {...restProps}
        aria-autocomplete="none"
      />
    </FormItemWithoutColon>
  );
};

export default EbicsUserPasswordFormInput;
