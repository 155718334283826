import { Select, SelectProps } from 'formik-antd';
import React, { FC } from 'react';
import { useKuendigungDurchListQuery } from '../../../gql/KuendigungDurchQueries.types';

const KuendigungDurchSelect: FC<SelectProps> = ({ name, ...restProps }) => {
  const { data, loading } = useKuendigungDurchListQuery();

  const kuendigungDurchList = data?.getKuendigungDurchList.data ?? [];

  return (
    <Select name={name} id={name} loading={loading} placeholder="Typ auswählen" allowClear size="small" style={{ minWidth: '160px' }} {...restProps}>
      {kuendigungDurchList.map((infoFeldType) => (
        <Select.Option value={infoFeldType.value} key={infoFeldType.value}>
          {infoFeldType.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default KuendigungDurchSelect;
