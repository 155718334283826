import React, { FC } from 'react';
import { Popover, Space, Typography } from 'antd';
import { BankOutlined } from '@ant-design/icons';
import { friendlyFormatIBAN } from 'ibantools';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import DataWithShortenedTextAndExtraInfo from '../../../components/Helpers/DataWithShortenedTextAndExtraInfo';
import { BankDetails } from '../../../types';

type Props = {
  bankDetails: Pick<BankDetails, 'bankname' | 'accountHolder' | 'bic' | 'iban'>;
};

const BankDetailsCardDetailsInfoRow: FC<Props> = ({ bankDetails }) => (
  <CardDetailsInfoRow
    infoRowTitle={
      <DataWithShortenedTextAndExtraInfo text={bankDetails.bankname} maxTextLength={12}>
        {(shortenedText) => {
          return (
            <Space align="start">
              <Popover placement="top" title={bankDetails.accountHolder} content={bankDetails.bic} trigger="click">
                <BankOutlined />
              </Popover>
              <Typography.Text type="secondary">{shortenedText}</Typography.Text>
            </Space>
          );
        }}
      </DataWithShortenedTextAndExtraInfo>
    }
  >
    <DataWithShortenedTextAndExtraInfo maxTextLength={18} text={`${friendlyFormatIBAN(bankDetails.iban)}`}>
      {(shortenedText) => <Typography.Text>{shortenedText}</Typography.Text>}
    </DataWithShortenedTextAndExtraInfo>
  </CardDetailsInfoRow>
);

export default BankDetailsCardDetailsInfoRow;
