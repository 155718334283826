import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { AbrechnungStatus } from '../../../../types';

type Props = SelectProps;

const AbrechnungStatusListFormSelect: FC<Props> = ({ name, size, ...restProps }) => {
  const list = [
    {
      text: 'Gültig',
      value: AbrechnungStatus.Valid,
    },
    {
      text: 'Ungültig',
      value: AbrechnungStatus.Invalid,
    },
    {
      text: 'Überprüfung fehlerhaft',
      value: AbrechnungStatus.ReviewError,
    },
    {
      text: 'In Prüfung',
      value: AbrechnungStatus.UnderReview,
    },
  ];

  return (
    <Select
      name={name}
      size={size ?? 'middle'}
      prefix="Status"
      placeholder="Status auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
      {...restProps}
    >
      {list.map((status) => (
        <Select.Option key={status.value} value={status.value}>
          {status.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default AbrechnungStatusListFormSelect;
