import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../helpers/selectHelper';
import DataWithShortenedText from '../../components/Helpers/DataWithShortenedText';
import { usePaymentProposalListQuery } from './gql/PaymentProposalQueries.types';
import { PaymentProposalStatus } from '../../types';
import { PaymentProposalData, PaymentProposalListData } from './gql/paymentProposalTypesHelper';

type Props = {
  onChange: (paymentProposal?: PaymentProposalData) => void;
} & SelectProps;

const PaymentProposalSelectForDelete: FC<Props> = ({ onChange, ...restProps }) => {
  const { loading, data } = usePaymentProposalListQuery({
    variables: { statusList: [PaymentProposalStatus.Created] },
  });
  const paymentProposalList = data?.getPaymentProposalList.data ?? [];

  return (
    <Select
      size="small"
      placeholder="Zahlungsvorschlag auswählen"
      {...restProps}
      id={restProps.name}
      loading={loading}
      allowClear
      showSearch
      filterOption={selectFilterOption}
      onChange={(paymentProposalId) => callOnChange(paymentProposalList, paymentProposalId, onChange)}
    >
      {paymentProposalList?.map((zahlungsvorschlag) => {
        return (
          <Select.Option key={zahlungsvorschlag.paymentProposalId} value={zahlungsvorschlag.paymentProposalId}>
            <DataWithShortenedText maxTextLength={40} text={zahlungsvorschlag.bezeichnung}>
              {(shortenedText) => shortenedText}
            </DataWithShortenedText>
          </Select.Option>
        );
      })}
    </Select>
  );
};

const callOnChange = (list: PaymentProposalListData, paymentProposalId: string, onChange: (paymentProposal?: PaymentProposalData) => void) => {
  const paymentProposal = list.find((pp) => pp.paymentProposalId === paymentProposalId);
  onChange(paymentProposal);
};

export default PaymentProposalSelectForDelete;
