import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import BookingInstructionPage from './BookingInstructionPage';
import { URI_BOOKING_INSTRUCTION_PAGE } from '../../features/BookingInstruction/bookingInstructionUriPaths';

const bookingInstructionPageRoute = (
  <Route
    path={URI_BOOKING_INSTRUCTION_PAGE}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<BookingInstructionPage />} />}
  />
);

export default bookingInstructionPageRoute;
