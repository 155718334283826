import React, { FC } from 'react';
import VersionTimeline from '../../../../components/Timeline/VersionTimeline';
import { showSuccessMsgDelete } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import UstRegelsetVersionCardContent from './UstRegelsetVersionCardContent';
import UstRegelsetVersionForm from './Form/UstRegelsetVersionForm';
import { useDeleteUstRegelsetUstKategorieZuweisungMutation } from './gql/UstRegelsetUstKategorieMutations.types';
import { useUstRegelsetUstKategorieZuweisungListQuery } from './gql/UstRegelsetUstKategorieQueries.types';
import { UstKategorieStatus, UstRegelset } from '../../../../types';
import { mapToVersionTimelineDataSourceWithAlwaysDeletableEntries } from '../../../../components/Timeline/versionTimelineHelper';
import { UstRegelsetUstKategorieTimelineDataSourceEntry } from './ustRegelsetVersionTimelineMapper';

type Props = {
  ustRegelset: UstRegelset;
  reloadUstRegelset: () => void;
};

const UstRegelsetVersionTimeline: FC<Props> = ({ ustRegelset, reloadUstRegelset }) => {
  const { data, refetch: reloadUstRegelsetUstKategorieVersionList } = useUstRegelsetUstKategorieZuweisungListQuery({
    variables: {
      ustRegelsetId: ustRegelset.ustRegelsetId,
    },
  });
  const ustRegelsetVersionListData = data?.getUstRegelsetUstKategorieZuweisungList.data;
  const ustRegelsetVersionList = ustRegelsetVersionListData ?? [];
  const loading = !ustRegelsetVersionListData;

  const onActionSuccess = () => {
    reloadUstRegelsetUstKategorieVersionList();
    reloadUstRegelset();
  };

  const [runDelete] = useDeleteUstRegelsetUstKategorieZuweisungMutation({
    onCompleted: () => {
      showSuccessMsgDelete(`USt.-Kategoriezwuweisung`);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <VersionTimeline<UstRegelsetUstKategorieTimelineDataSourceEntry>
      dataSource={mapToVersionTimelineDataSourceWithAlwaysDeletableEntries(ustRegelsetVersionList)}
      versionIdKey="ustRegelsetKategorieZuweisungId"
      loading={loading}
      defaultNewTimeblock={{
        ustRegelsetKategorieZuweisungId: '',
        ustKategorie: {
          createTs: '',
          createdBy: '',
          createdByMitarbeiterId: null,
          status: {
            text: '',
            value: UstKategorieStatus.Aktiv,
          },
          ustKategorieId: '',
          kurzBezeichnung: '',
          ustKategorieEntryList: [],
          warningList: [],
        },
        warningList: [],
      }}
      renderForm={(timeblock) => (
        <UstRegelsetVersionForm
          ustRegelsetId={ustRegelset.ustRegelsetId}
          firmendatenId={ustRegelset.firmendatenId}
          timeblock={timeblock}
          onSubmitSuccess={onActionSuccess}
        />
      )}
      isAllowedToDeleteInitialTimeblock
      onDelete={(timeblock) =>
        runDelete({
          variables: {
            ustRegelsetId: ustRegelset.ustRegelsetId,
            ustRegelsetUstKategorieZuweisungId: timeblock.ustRegelsetKategorieZuweisungId,
          },
        })
      }
      renderCardContent={(timeblock) => <UstRegelsetVersionCardContent timeblock={timeblock} />}
    />
  );
};

export default UstRegelsetVersionTimeline;
