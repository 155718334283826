import * as Yup from 'yup';
import { entityHasToBeAnInt, entityHasToBeNonNegative, entityIsRequired, entityMaxCharLength } from '../../../components/message/validationMsg';
import { ticketFormFields } from './ticketFormMapper';

export const ticketFormValidationSchema = (isTemplateIdNotRequired: boolean) =>
  Yup.object().shape({
    ...(!isTemplateIdNotRequired && { [ticketFormFields.ticketTemplateId]: Yup.string().required(entityIsRequired('Art')) }),
    [ticketFormFields.priority]: Yup.string().required(entityIsRequired('Priorität')),
    [ticketFormFields.title]: Yup.string().required(entityIsRequired('Titel')).max(200, entityMaxCharLength('Titel', 200)),
    [ticketFormFields.assigneeMitarbeiterId]: Yup.string().required(entityIsRequired('Bearbeiter')),
    [ticketFormFields.dueDate]: Yup.string().required(entityIsRequired('Fälligkeit')),
    [ticketFormFields.reminder]: Yup.object().shape({
      amount: Yup.number().required(entityIsRequired('Anzahl')).min(0, entityHasToBeNonNegative('Anzahl')).integer(entityHasToBeAnInt('Anzahl')),
      unit: Yup.string().required(entityIsRequired('Einheit')),
    }),
    [ticketFormFields.participantList]: Yup.array().of(
      Yup.object().shape({
        contactId: Yup.string().nullable(),
        emailAddress: Yup.string().nullable().email('E-Mail-Adresse muss gültig sein'),
      })
    ),
  });
