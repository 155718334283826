import React from 'react';
import { Skeleton } from 'antd';
import { useMahndefinitionTemplateQuery } from '../../../../../features/Mahndefinition/gql/MahndefinitionQueries.types';
import MahndefinitionCreateForm from '../../../../../features/Mahndefinition/Form/Create/MahndefinitionCreateForm';

const SysSettingsMahndefinitionCreatePage = () => {
  const { data: mahndefinitionTextbausteinListData, loading: loadingMahndefinitionTemplate } = useMahndefinitionTemplateQuery();
  const mahndefinitionTextbausteinList = mahndefinitionTextbausteinListData?.getMahndefinitionTemplate.data.textbausteinList ?? [];

  if (loadingMahndefinitionTemplate) {
    return <Skeleton active />;
  }

  return <MahndefinitionCreateForm mahndefinitionTextbausteinList={mahndefinitionTextbausteinList} />;
};

export default SysSettingsMahndefinitionCreatePage;
