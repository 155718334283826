import React, { FC, ReactNode } from 'react';
import { Button, Divider, Modal, Space } from 'antd';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';

export enum HistoryType {
  Change = 'Change',
  Deletion = 'Deletion',
}

type Props = {
  open: boolean;
  onCancel: () => void;
  children: ReactNode;
  /**
   * * required if historyType is Deletion
   * */
  entityName?: string;
  historyType: HistoryType;
};

const HistoryModal: FC<Props> = ({ open, onCancel, children, entityName, historyType }) => {
  return (
    <Modal
      width={1200}
      title={historyType === HistoryType.Change ? 'Änderungsverlauf' : `Löschverlauf ${entityName}`}
      open={open}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      closable
    >
      {open && children}
      <Space direction="vertical" style={{ width: '100%' }}>
        <Divider style={{ margin: 8 }} />
        <ButtonsAligned usage={ButtonsAlignedUsage.drawerFooter}>
          <Button type="primary" onClick={onCancel}>
            Schließen
          </Button>
        </ButtonsAligned>
      </Space>
    </Modal>
  );
};
export default HistoryModal;
