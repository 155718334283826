import styled from 'styled-components';
import DebouncedTextSearch from '../../../components/Input-antd/DebouncedTextSearch';

export const StyledDebouncedTextSearch = styled(DebouncedTextSearch)`
  .ant-btn {
    vertical-align: 0;
  }

  .ant-input-group-addon {
    height: 22px;
    line-height: 0;
  }
`;
