import React, { FC } from 'react';
import { Col, Divider, Row } from 'antd';
import { FormikProps } from 'formik';
import { DatePicker, Radio } from 'formik-antd';
import { incomingInvoiceFormFields, IncomingInvoiceFormValues } from './incomingInvoiceFormMapper';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../components/DatePicker/DatePicker_formikAntD';
import FormBlockRow from './shared/FormBlockRow';

type Props = {
  formikProps: FormikProps<IncomingInvoiceFormValues>;
  shouldRestore: boolean;
  isIncomingInvoiceVerbucht: boolean;
};

const AbgrenzungBuchungBlockForm: FC<Props> = ({ formikProps, shouldRestore, isIncomingInvoiceVerbucht }) => (
  <>
    <FormBlockRow
      rowTitle="Buchung"
      content={
        <>
          <Row style={{ marginTop: 10 }}>
            <Col style={{ width: '100%' }}>
              <Radio.Group name={incomingInvoiceFormFields.abgrenzungsBuchung} disabled={shouldRestore} buttonStyle="solid" block>
                <Radio.Button value={false}>Buchung</Radio.Button>
                <Radio.Button value>Buchung mit Aufwand Abgrenzung</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: 10 }}>
            {!formikProps.values.abgrenzungsBuchung ? (
              <Col style={{ width: '100%' }}>
                <FormItemWithoutColon
                  name={incomingInvoiceFormFields.buchungsdatum}
                  label={`Buchungsdatum ${isIncomingInvoiceVerbucht ? '(Eingangsrechnung verbucht)' : ''}`}
                >
                  <DatePicker
                    disabled={shouldRestore}
                    size="middle"
                    id={incomingInvoiceFormFields.buchungsdatum}
                    name={incomingInvoiceFormFields.buchungsdatum}
                    style={{ width: '100%' }}
                    format={DATE_DISPLAY_FORMAT_DEFAULT}
                  />
                </FormItemWithoutColon>
              </Col>
            ) : (
              <Col style={{ width: '100%' }}>
                <FormItemWithoutColon name={incomingInvoiceFormFields.buchungsdatum} label="Buchung mit Aufwand Abgrenzung - Buchungsdatum">
                  <DatePicker
                    disabled={shouldRestore}
                    size="middle"
                    id={incomingInvoiceFormFields.buchungsdatum}
                    name={incomingInvoiceFormFields.buchungsdatum}
                    style={{ width: '100%' }}
                    format={DATE_DISPLAY_FORMAT_DEFAULT}
                  />
                </FormItemWithoutColon>
                <FormItemWithoutColon
                  name={incomingInvoiceFormFields.abgrenzungsBuchungsdatum}
                  label="Aufwand Buchung der Aufwand Abgrenzung - Buchungsdatum"
                >
                  <DatePicker
                    size="middle"
                    disabled={shouldRestore}
                    id={incomingInvoiceFormFields.abgrenzungsBuchungsdatum}
                    name={incomingInvoiceFormFields.abgrenzungsBuchungsdatum}
                    style={{ width: '100%' }}
                    format={DATE_DISPLAY_FORMAT_DEFAULT}
                  />
                </FormItemWithoutColon>
              </Col>
            )}
          </Row>
        </>
      }
    />
    <Divider />
  </>
);

export default AbgrenzungBuchungBlockForm;
