import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { VertragVposIndexedValueAutomaticallyDetailsPageRouteParams } from './routes';
import menuListVertragVposIndexedValueAutomatically from './menuListVertragVposIndexedValueAutomatically';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import { VertragVposIndexedValueGenerierlauf } from '../../../types';
import { useVertragVposIndexedValueGenerierlaufQuery } from '../../../features/Verarbeitung/gql/VertragVposIndexedValueGenerierlauf/VertragVposIndexedValueGenerierlaufQueries.types';
import { useRestartVertragVposIndexedValueGenerierlaufMutation } from '../../../features/Verarbeitung/gql/VertragVposIndexedValueGenerierlauf/VertragVposIndexedValueGenerierlaufMutations.types';

const VertragVposIndexedValueAutomaticallyDetailsPage = () => {
  const { generierlaufId } = useParams() as VertragVposIndexedValueAutomaticallyDetailsPageRouteParams;

  const { data, refetch } = useVertragVposIndexedValueGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getVertragVposIndexedValueGenerierlauf.data;

  const [runRestart] = useRestartVertragVposIndexedValueGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<VertragVposIndexedValueGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.VertragVposIndexedValueAutomatically}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.VertragVposIndexedValueAutomatically}
          onGenerierlaufRestart={runRestart}
        />
      )}
      isLoading={false}
      sidebarMenuList={menuListVertragVposIndexedValueAutomatically}
    />
  );
};

export default VertragVposIndexedValueAutomaticallyDetailsPage;
