import React from 'react';
import { useParams } from 'react-router-dom';
import UstVomAufwandCard from '../../features/UstVomAufwand/Card/UstVomAufwandCard';
import DetailsPageTemplate from '../../components/Template/DetailsPageTemplate';
import menuListUstVomAufwand from './menuListUstVomAufwand';
import { useUstVomAufwandQuery } from '../../features/UstVomAufwand/gql/UstVomAufwandQueries.types';
import { UstVomAufwandDetailsPageRouteParams } from './routes';
import { useOnUstVomAufwandDataChangedEvent } from '../../features/UstVomAufwand/useOnUstVomAufwandDataChangedEvent';
import { UstVomAufwand } from '../../types';

const UstVomAufwandDetailsPage = () => {
  const { objektId, ustVomAufwandId } = useParams() as UstVomAufwandDetailsPageRouteParams;
  const { data, refetch } = useUstVomAufwandQuery({ variables: { objektId, ustVomAufwandId } });
  const ustVomAufwand = data?.getUstVomAufwand.data;

  useOnUstVomAufwandDataChangedEvent('ustVomAufwandDetails', refetch);

  return (
    <DetailsPageTemplate<UstVomAufwand>
      pageTitle={() => 'Ust. vom Aufwand'}
      data={ustVomAufwand}
      card={(ustVomAufwand) => <UstVomAufwandCard ustVomAufwand={ustVomAufwand} objektId={objektId} onAction={refetch} />}
      sidebarMenuList={(ustVomAufwand) => menuListUstVomAufwand(objektId, ustVomAufwand, refetch)}
    />
  );
};

export default UstVomAufwandDetailsPage;
