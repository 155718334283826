import React from 'react';
import { Row } from 'antd';
import PDFPageCol from './PDFPageCol';
import HauseigentuemerAbrechnungTemplate from './templates/hauseigentuemerAbrechnung/HauseigentuemerAbrechnungTemplate';
import { mockHauseigentuemerAbrechnung } from './mocks/hauseigentuemerAbrechnungMocks';

const PDFPageHauseigentuemerAbrechnung = () => (
  <Row>
    <PDFPageCol pdfTemplate={<HauseigentuemerAbrechnungTemplate data={mockHauseigentuemerAbrechnung} />} />
  </Row>
);

export default PDFPageHauseigentuemerAbrechnung;
