import React, { useState } from 'react';
import { Button, Card, Drawer, Dropdown, MenuProps } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import ObjektForm from './Card/Form/ObjektForm';
import { Spacer } from '../../components/Grid';
import { useToggle } from '../../hooks/useToggle';
import ObjektList from './ObjektList';
import '../../components/Cards/styles/cardStyles.css';
import { useObjektListQuery } from './gql/ObjektQueries.types';
import DebouncedTextSearch from '../../components/Input-antd/DebouncedTextSearch';

const ObjektListCard = () => {
  const [isObjektCreateDrawerOpen, toggleObjektCreateDrawer] = useToggle();
  const [showArchived, toggleShowArchived] = useToggle();

  const items: MenuProps['items'] = [
    { key: '1', label: 'Objekt anlegen', onClick: toggleObjektCreateDrawer },
    { key: '2', label: `Archivierte Einträge ${showArchived ? 'ausblenden' : 'anzeigen'}`, onClick: toggleShowArchived },
  ];

  const [kurzBezeichnung, setKurzBezeichnung] = useState<string>('');

  const { data, refetch } = useObjektListQuery({
    variables: {
      includeArchiviert: showArchived,
      kurzBezeichnung,
    },
  });
  const objektList = data?.getObjektList.data?.contentList;
  const loading = !objektList;

  return (
    <Card
      size="small"
      title="Objekte"
      extra={
        <Dropdown menu={{ items }}>
          <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
        </Dropdown>
      }
    >
      <Spacer />
      <DebouncedTextSearch style={{ width: '100%' }} placeholder="Nach Objekt suchen…" onChange={(value) => setKurzBezeichnung(value.trim())} />
      <Spacer />
      <ObjektList onClickCreate={toggleObjektCreateDrawer} objektList={objektList} loading={loading} />
      <Drawer title="Objekt anlegen" width={720} open={isObjektCreateDrawerOpen} onClose={toggleObjektCreateDrawer} destroyOnClose>
        <ObjektForm
          onSuccess={() => {
            toggleObjektCreateDrawer();
            refetch();
          }}
          onCancel={toggleObjektCreateDrawer}
        />
      </Drawer>
    </Card>
  );
};

export default ObjektListCard;
