import React, { FC } from 'react';
import { Button, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { DocumentRowProps, findLabelValueByLabelId } from './util';
import { ValueTitle } from '../../../../components/Helpers/ValueTitle';
import { pathsToObjektDetailsPage } from '../../../Objekt/objektUriPaths';
import { pathsToWeAbrechnungDetails } from '../../../Abrechnung/BkOrWe/weAbrechnungUriPaths';
import { useFDAuthorized } from '../../../../shared/Auth/useAuthorized';
import { uriFileOfFirmendaten } from '../../documentApiPaths';
import { DocumentInfo, DocumentRowContextProvider } from './documentRowContext';
import LinkWithStatus from '../../../../components/Link/LinkWithStatus';
import AvatarBasedOnFileInfoTypeWithTooltip from '../../../../components/Avatar/BasedOnFileInfoType/AvatarBasedOnFileInfoTypeWithTooltip';
import OutdatedData from '../../../../components/Helpers/OutdatedData';
import { useObjektAbrechnungQuery } from '../../../Abrechnung/BkOrWe/gql/ObjektAbrechnungQueries.types';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

const RowObjektAbrechnung: FC<DocumentRowProps> = ({ record, rechnungTyp, invoiceRecipientId, invoiceIssuerId, ...restProps }) => {
  const { activeForFirmendatenId } = useFDAuthorized();

  const objektId = findLabelValueByLabelId(record.labelList, 'objektId');
  const objektAbrechnungId = findLabelValueByLabelId(record.labelList, 'objektAbrechnungId');
  const isOutdated = findLabelValueByLabelId(record.labelList, 'outdated');

  const { loading, data: maybeObjektAbrechnung } = useObjektAbrechnungQuery({ variables: { objektId, objektAbrechnungId } });

  const objektAbrechnung = maybeObjektAbrechnung?.getObjektAbrechnung.data;

  const documentInfo: DocumentInfo | undefined = objektAbrechnung
    ? {
        column1: (
          <OutdatedData isOutdated={isOutdated} isAvatar>
            <AvatarBasedOnFileInfoTypeWithTooltip fileType={record.type} />
          </OutdatedData>
        ),
        column2: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle
              small
              title="Abrechnung"
              content={
                <DataWithShortenedText text={objektAbrechnung.bezeichnung} maxTextLength={25}>
                  {(shortenedText) => <Link to={pathsToWeAbrechnungDetails(objektId, objektAbrechnungId).objAbr}>{shortenedText}</Link>}
                </DataWithShortenedText>
              }
            />
          </OutdatedData>
        ),
        column3: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle
              small
              title="Objekt"
              content={
                objektAbrechnung.objekt ? (
                  <LinkWithStatus
                    to={pathsToObjektDetailsPage(objektAbrechnung.objekt.objektId).objektDetails}
                    text={objektAbrechnung.objekt.kurzBezeichnung}
                    status={objektAbrechnung.objekt.status}
                  />
                ) : (
                  <Typography.Text>-</Typography.Text>
                )
              }
            />
          </OutdatedData>
        ),
        column4: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle small title="-" content="-" />
          </OutdatedData>
        ),
        column5: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle small title="von" content={<CustomFormattedDate value={objektAbrechnung.fromInclusive} />} />
          </OutdatedData>
        ),
        column6: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle small title="bis" content={<CustomFormattedDate value={objektAbrechnung.toInclusive} />} />
          </OutdatedData>
        ),
        column7: <OutdatedData isOutdated={isOutdated}>-</OutdatedData>,
        column8: (
          <OutdatedData isOutdated={isOutdated}>
            <Button>
              <Link to={uriFileOfFirmendaten(activeForFirmendatenId, record.fileId, record.name)} target="_blank">
                Öffnen
              </Link>
            </Button>
          </OutdatedData>
        ),
      }
    : undefined;

  return (
    <DocumentRowContextProvider value={{ isLoading: loading, documentInfo }}>
      <tr {...restProps} />
    </DocumentRowContextProvider>
  );
};

export default RowObjektAbrechnung;
