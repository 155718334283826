import React, { FC } from 'react';
import { Divider } from 'antd';
import { Box } from 'rebass';
import { FormikProps } from 'formik';
import { Form } from 'formik-antd';
import { Spacer } from '../../../../../components/Grid';
import TimelineFormButtons from '../../../../../components/Timeline/Form/TimelineFormButtons';
import { getPreviousTimeblockBefore, ITimeblock, useTimeline } from '../../../../../components/Timeline/timelineCtx';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import TimelineFormKommentar from '../../../../../components/Timeline/Form/TimelineFormKommentar';
import TimelineFormDatePicker from '../../../../../components/Timeline/Form/TimelineFormDatePicker';
import {
  repFondsKontenZuordnungVersionFormFields,
  repFondsKontenZuweisungDescriptionsForKommentar,
  RepFondsKontenZuweisungVersionFormValues,
} from './repFondsKontenZuweisungVersionFormMapper';
import RepFondsKontenZuweisungListingForm from './RepFondsKontenZuweisungListingForm';
import { buildKommentarText, isSameAsPreviousVersion } from './repFondsKontenZuweisungVersionFormHelpers';
import { RepFondsEinstellung } from '../../../../../types';
import FormItemWithFieldHelp from '../../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../../FieldHelp/useGetFieldHelpText';

type Props = {
  isLoading: boolean;
  formikProps: FormikProps<RepFondsKontenZuweisungVersionFormValues>;
  timeblock: ITimeblock<RepFondsEinstellung>;
};

const RepFondsKontenZuweisungVersionFormContent: FC<Props> = ({ formikProps, timeblock, isLoading }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'RepFondsEinstellung'>('RepFondsEinstellung');
  const { dataSource } = useTimeline<RepFondsEinstellung>();

  return (
    <Form labelAlign="left">
      <Box width={2 / 3}>
        <FormItemWithFieldHelp
          name={repFondsKontenZuordnungVersionFormFields.validFrom}
          label="Gültig ab"
          labelCol={{ span: 12 }}
          fieldHelp={getFieldHelpText('RepFondsEinstellung.validFrom')}
        >
          <TimelineFormDatePicker
            picker="month"
            name={repFondsKontenZuordnungVersionFormFields.validFrom}
            timeblock={timeblock}
            placeholder="Datum wählen"
          />
        </FormItemWithFieldHelp>
      </Box>

      <Divider style={{ padding: 0, marginTop: 0, marginBottom: '16px' }} />

      <TimelineFormKommentar
        timeblock={timeblock}
        valuesDescriptions={repFondsKontenZuweisungDescriptionsForKommentar}
        buildKommentarText={buildKommentarText}
      />

      <Divider style={{ padding: 0, marginTop: 0, marginBottom: '16px' }} />
      <RepFondsKontenZuweisungListingForm formikProps={formikProps} fieldHelp={getFieldHelpText('RepFondsEinstellung.kontenZuweisungList')} />

      <Spacer height={16} />
      <TimelineFormButtons<RepFondsEinstellung>
        timeblock={timeblock}
        isSubmitting={formikProps.isSubmitting}
        isSameAsPreviousVersion={isSameAsPreviousVersion(
          formikProps.values,
          getPreviousTimeblockBefore(dataSource, dayjsCustom(formikProps.values.validFrom))
        )}
        submitBtnLoading={isLoading}
      />
    </Form>
  );
};

export default RepFondsKontenZuweisungVersionFormContent;
