import React, { FC, ReactNode } from 'react';
import { FormikProps } from 'formik';
import { Typography } from 'antd';
import { DatePicker, Input } from 'formik-antd';
import {
  bookingInstructionBuchungsKopfFormFields,
  BookingInstructionBuchungsKopfFormValuesDescriptions,
  bookingInstructionFormFields,
  BookingInstructionFormValues,
  BookingInstructionFormValuesDescriptions,
} from '../bookingInstructionFormMapper';
import GeneralInfoBlockSectionWithThreeColumns from './GeneralInfoBlockSectionWithThreeColumns';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import FibuBelegSymbolFormSelect from '../../../BelegSymbol/Fibu/FibuBelegSymbolFormSelect';
import FibuBuchungTypeFormSelect from '../../../BuchungType/Fibu/FibuBuchungTypeFormSelect';
import BuchungskreisSelect from './Selects/BuchungskreisFormSelect';
import { capitalizeString } from '../../../../helpers/stringHelper';
import { FibuBuchungsanweisungStatus } from '../../../../types';
import ActiveOrDeactivatedFormSelect from '../../../shared/ActiveOrDeactivatedFormSelect';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../components/DatePicker/DatePicker_formikAntD';
import BelegFileUpload from './Selects/BelegFileUpload';
import BookingInstructionVertragspartnerSelect from './Selects/BookingInstructionVertragspartnerSelect';
import { isVertragspartnerChosen } from '../../shared/fibuBuchungsanweisungHelpers';
import BookingInstructionContactEmailSelect from './Selects/BookingInstructionContactEmailSelect';
import BookingInstructionContactPostSelect from './Selects/BookingInstructionContactPostSelect';

type Props = {
  firmendatenId: string;
  formikProps: FormikProps<BookingInstructionFormValues>;
};

const GeneralInfoBlock: FC<Props> = ({ firmendatenId, formikProps }) => {
  // here come all the form fields for the Buchungskopf (=generalInfo) block
  const elements: ReactNode[] = [
    // Fachliche Pruefung - DONE - TODO: should be mandatory - check it
    <FormItemWithoutColon name={bookingInstructionFormFields.fachlichePruefung} label={BookingInstructionFormValuesDescriptions.fachlichePruefung}>
      <ActiveOrDeactivatedFormSelect name={bookingInstructionFormFields.fachlichePruefung} />
    </FormItemWithoutColon>,
    // Status - DONE - NUR LABEL, kein input - TODO: check when Offen & when Verbucht
    <FormItemWithoutColon name="status" label="Status">
      <Typography.Text>{capitalizeString(FibuBuchungsanweisungStatus.Offen)}</Typography.Text>
    </FormItemWithoutColon>,
    // Buchungskreis - DONE - TODO: should be mandatory - check it
    <FormItemWithoutColon
      name={bookingInstructionFormFields.buchungskreisRechtstraegerId}
      label={BookingInstructionFormValuesDescriptions.buchungskreisRechtstraegerId}
    >
      <BuchungskreisSelect formikProps={formikProps} name={bookingInstructionFormFields.buchungskreisRechtstraegerId} />
    </FormItemWithoutColon>,
    //  Belegsymbol - DONE - TODO: mandatory - check "Mögliche Belegsymbole siehe Excel: Buchungszeile Felder - verschiedene Buchungen_v2_Androstyle.xlsx – Tab Buchungsmaske-Fälle"
    <FormItemWithoutColon
      key={bookingInstructionBuchungsKopfFormFields.belegSymbol}
      name={`${bookingInstructionFormFields.buchungsKopf}.${bookingInstructionBuchungsKopfFormFields.belegSymbol}`}
      label={BookingInstructionBuchungsKopfFormValuesDescriptions.belegSymbol}
    >
      <FibuBelegSymbolFormSelect
        name={`${bookingInstructionFormFields.buchungsKopf}.${bookingInstructionBuchungsKopfFormFields.belegSymbol}`}
        onChange={() => {
          const buchungstypeFieldName = `${bookingInstructionFormFields.buchungsKopf}.${bookingInstructionBuchungsKopfFormFields.buchungsType}`;
          formikProps.setFieldValue(buchungstypeFieldName, undefined);
        }}
      />
    </FormItemWithoutColon>,
    // Buchungstyp - DONE - TODO: mandatory - "Mögliche Belegsymbol/Buchungstyp Kombinationen siehe Excel: Buchungszeile Felder - verschiedene Buchungen_v2_Androstyle.xlsx – Tab Buchungsmaske-Fälle"
    <FormItemWithoutColon
      key={bookingInstructionBuchungsKopfFormFields.buchungsType}
      name={`${bookingInstructionFormFields.buchungsKopf}.${bookingInstructionBuchungsKopfFormFields.buchungsType}`}
      label={BookingInstructionBuchungsKopfFormValuesDescriptions.buchungsType}
    >
      <FibuBuchungTypeFormSelect
        name={`${bookingInstructionFormFields.buchungsKopf}.${bookingInstructionBuchungsKopfFormFields.buchungsType}`}
        disabled={!formikProps.values.buchungsKopf?.belegSymbol}
      />
    </FormItemWithoutColon>,
    // Zu stornierende Buchung (Optional)
    //     TODO: Bezeichnung: „{Belegsymbol, normal geschrieben!} - Beleg {Belegnummer, wenn nicht vorhanden „Ohne Belegnr.“} – {Betrag nur eine Seite} {Währungszeichen} - Buchung am {Buchungsdatum}“
    //           Enabled if Buchungskreis and Belegsymbol/Buchungstyp + mandatory (BE) if enabled
    //           CHECK the story

    // Buchungsdatum - TODO: it should be Mandatory - check it
    <FormItemWithoutColon
      name={`${bookingInstructionFormFields.buchungsKopf}.${bookingInstructionBuchungsKopfFormFields.buchungsdatum}`}
      label={BookingInstructionBuchungsKopfFormValuesDescriptions.buchungsdatum}
    >
      <DatePicker
        id={`${bookingInstructionFormFields.buchungsKopf}.${bookingInstructionBuchungsKopfFormFields.buchungsdatum}`}
        name={`${bookingInstructionFormFields.buchungsKopf}.${bookingInstructionBuchungsKopfFormFields.buchungsdatum}`}
        placeholder="Datum wählen"
        format={DATE_DISPLAY_FORMAT_DEFAULT}
        allowClear={false}
        size="small"
        style={{ width: '100%' }}
      />
    </FormItemWithoutColon>,
    // Belegdatum - TODO: it should be Optional - check it
    <FormItemWithoutColon
      name={`${bookingInstructionFormFields.buchungsKopf}.${bookingInstructionBuchungsKopfFormFields.belegDatum}`}
      label={BookingInstructionBuchungsKopfFormValuesDescriptions.belegDatum}
    >
      <DatePicker
        id={`${bookingInstructionFormFields.buchungsKopf}.${bookingInstructionBuchungsKopfFormFields.belegDatum}`}
        name={`${bookingInstructionFormFields.buchungsKopf}.${bookingInstructionBuchungsKopfFormFields.belegDatum}`}
        placeholder="Datum wählen"
        format={DATE_DISPLAY_FORMAT_DEFAULT}
        allowClear={false}
        size="small"
        style={{ width: '100%' }}
      />
    </FormItemWithoutColon>,
    // Belegnummer - DONE input as in "ER anlegen" - TODO: it should be Optional - check it
    <FormItemWithoutColon
      name={`${bookingInstructionFormFields.buchungsKopf}.${bookingInstructionBuchungsKopfFormFields.belegnummer}`}
      label={BookingInstructionBuchungsKopfFormValuesDescriptions.belegnummer}
    >
      <Input
        size="middle"
        id={`${bookingInstructionFormFields.buchungsKopf}.${bookingInstructionBuchungsKopfFormFields.belegnummer}`}
        name={`${bookingInstructionFormFields.buchungsKopf}.${bookingInstructionBuchungsKopfFormFields.belegnummer}`}
      />
    </FormItemWithoutColon>,

    // Belegdatei - TODO: should be optional - check it
    <FormItemWithoutColon name={bookingInstructionFormFields.inputFile} label={BookingInstructionBuchungsKopfFormValuesDescriptions.belegFileId}>
      <BelegFileUpload
        firmendatenId={firmendatenId}
        formikProps={formikProps}
        name={bookingInstructionFormFields.inputFile}
        nameForTempBildFileId={bookingInstructionFormFields.tempInputFileId}
        fileId={formikProps.values.inputFileId}
      />
    </FormItemWithoutColon>,

    // Fälligkeit - TODO: it should be Optional - check it
    <FormItemWithoutColon
      name={`${bookingInstructionFormFields.buchungsKopf}.${bookingInstructionBuchungsKopfFormFields.dueDate}`}
      label={BookingInstructionBuchungsKopfFormValuesDescriptions.dueDate}
    >
      <DatePicker
        id={`${bookingInstructionFormFields.buchungsKopf}.${bookingInstructionBuchungsKopfFormFields.dueDate}`}
        name={`${bookingInstructionFormFields.buchungsKopf}.${bookingInstructionBuchungsKopfFormFields.dueDate}`}
        placeholder="Datum wählen"
        format={DATE_DISPLAY_FORMAT_DEFAULT}
        allowClear={false}
        size="small"
        style={{ width: '100%' }}
      />
    </FormItemWithoutColon>,

    // Vertragspartner - DONE - mandatory (BE) if enabled
    <FormItemWithoutColon
      name={`${bookingInstructionFormFields.buchungsKopf}.${bookingInstructionBuchungsKopfFormFields.vertragspartnerId}`}
      label={BookingInstructionBuchungsKopfFormValuesDescriptions.vertragspartnerId}
    >
      <BookingInstructionVertragspartnerSelect
        name={`${bookingInstructionFormFields.buchungsKopf}.${bookingInstructionBuchungsKopfFormFields.vertragspartnerId}`}
        formikProps={formikProps}
      />
    </FormItemWithoutColon>,

    // "E-Mail an" - DONE - Show only if "Vertragspartner" is selected
    isVertragspartnerChosen(formikProps.values) ? (
      <FormItemWithoutColon
        name={bookingInstructionFormFields.recipientEmailContactId}
        label={BookingInstructionFormValuesDescriptions.recipientEmailContactId}
      >
        <BookingInstructionContactEmailSelect
          name={bookingInstructionFormFields.recipientEmailContactId}
          formikProps={formikProps}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          rechtstraegerId={formikProps.values.buchungsKopf!.vertragspartnerId!}
        />
      </FormItemWithoutColon>
    ) : null,
    // "Post an" - DONE - Show only if "Vertragspartner" is selected
    isVertragspartnerChosen(formikProps.values) ? (
      <FormItemWithoutColon name={bookingInstructionFormFields.deliveryAddressId} label={BookingInstructionFormValuesDescriptions.deliveryAddressId}>
        <BookingInstructionContactPostSelect
          name={bookingInstructionFormFields.deliveryAddressId}
          formikProps={formikProps}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          rechtstraegerId={formikProps.values.buchungsKopf!.vertragspartnerId!}
        />
      </FormItemWithoutColon>
    ) : null,
    // TODO: "Rechnungsaussteller Bankverbindung" - Show only if "Vertragspartner" is selected - similar to "Bankverbindung-Zuordnung anlegen (im Objekt)"
  ];

  return <GeneralInfoBlockSectionWithThreeColumns elements={elements} />;
};

export default GeneralInfoBlock;
