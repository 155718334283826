import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTransactionInstructionListQuery } from '../../../../Payment/gql/Order/TransactionInstructionQueries.types';
import { generatePathToPaymentPage } from '../../../../Payment/Listing/Filters/filtersQueryParams';
import { LoadingCol } from '../../../../../components/Loading';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';

type Props = {
  buchungId: string;
  vertragspartnerId: string;
  vertragspartnerName: string;
};

const AssignedPaymentColumn: FC<Props> = ({ buchungId, vertragspartnerId, vertragspartnerName }) => {
  const { data, loading } = useTransactionInstructionListQuery({
    variables: {
      fibuBuchungId: buchungId,
    },
  });

  const transactionInstruction = data?.getTransactionInstructionList.data[0];

  const text = transactionInstruction
    ? `${transactionInstruction.paymentType?.text} - ${dayjsCustom(transactionInstruction.requestedExecutionDate).format('D.M.YYYY')} - ${transactionInstruction.invoiceIssuerName} - ${vertragspartnerName} - ${transactionInstruction.amount}`
    : '';

  return (
    <LoadingCol isLoading={loading}>
      <DataWithShortenedText maxTextLength={15} text={text}>
        {(shortenedText) => (
          <Link
            to={generatePathToPaymentPage({
              amountFrom: transactionInstruction?.amount,
              amountTo: transactionInstruction?.amount,
              payeeId: vertragspartnerId,
              paymentDateFrom: transactionInstruction?.requestedExecutionDate,
              paymentDateTo: transactionInstruction?.requestedExecutionDate,
              paymentTypeList: transactionInstruction?.paymentType ? [transactionInstruction.paymentType.value] : undefined,
              rechnungsAusstellerId: transactionInstruction?.buchungskreisRechtstraegerId,
            })}
            target="_blank"
          >
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    </LoadingCol>
  );
};

export default AssignedPaymentColumn;
