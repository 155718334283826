import React, { FC } from 'react';
import { Alert, Button, Space } from 'antd';

type Props = {
  isOkDisabled?: boolean;
  isBuchungsanweisungChanged: boolean;
  onCancel: () => void;
  onReset: () => void;
  onSubmit: () => void;
  showNotSavedWarning: boolean;
};

const BookingSuggestionButtons: FC<Props> = ({ onCancel, onReset, onSubmit, showNotSavedWarning, isOkDisabled, isBuchungsanweisungChanged }) => {
  return (
    <Space direction="vertical" size="middle" align="end" style={{ width: '100%', height: '100%', justifyContent: 'flex-start' }}>
      <Space size="small">
        <Button onClick={onCancel}>Abbrechen</Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            onReset();
          }}
        >
          Zurücksetzen
        </Button>
        <Button type="primary" onClick={onSubmit} disabled={isOkDisabled}>
          {isBuchungsanweisungChanged ? 'Speichern und Verbuchen' : 'Speichern'}
        </Button>
      </Space>
      <Space style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        {showNotSavedWarning ? <Alert message="Daten wurden noch nicht gespeichert" type="warning" showIcon /> : null}
      </Space>
    </Space>
  );
};

export default BookingSuggestionButtons;
