import React, { FC } from 'react';
import MultipleTexts from '../../components/Text/MultipleTexts';

type VorschreibungObjektTopContentProps = {
  bezeichnunglist: string[];
  maxFirstTextLength: number;
};

const VorschreibungObjektTopContent: FC<VorschreibungObjektTopContentProps> = ({ bezeichnunglist, maxFirstTextLength }) => {
  return <MultipleTexts texts={bezeichnunglist} maxFirstTextLength={maxFirstTextLength} />;
};

export default VorschreibungObjektTopContent;
