import { FormFields } from '../../../../../helpers/formikHelper';
import { AuftragsartFieldsFragment } from '../../../gql/AuftragsartFragments.types';

export type AuftragsartUpdateParamFormValues = {
  bezeichnung: string;
  showUstBlock: boolean;
  stornoAuftragsartId?: string | null;
};

export const auftragsartUpdateParamFormFields: FormFields<AuftragsartUpdateParamFormValues> = {
  bezeichnung: 'bezeichnung',
  showUstBlock: 'showUstBlock',
  stornoAuftragsartId: 'stornoAuftragsartId',
};

export const mapAuftragsartToFormValues = (auftragsart: AuftragsartFieldsFragment): AuftragsartUpdateParamFormValues => ({
  bezeichnung: auftragsart.bezeichnung,
  showUstBlock: auftragsart.showUstBlock ?? false,
  stornoAuftragsartId: auftragsart.stornoAuftragsart?.auftragsartId ?? undefined,
});
