import React, { FC } from 'react';
import { Select, Typography } from 'antd';
import { StyledFiltersSelectSpace } from './styled/Filters.style';
import { selectFilterOption } from '../../helpers/selectHelper';

type Props = {
  defaultSelectedFilterList: string[];
  selectedFilterList: string[];
  onChange: (valueList: string[]) => void;
  labelList: Record<string, string>;
  filterOption?: (input: string, option: any) => boolean;
};

const FiltersSelect: FC<Props> = ({ defaultSelectedFilterList, selectedFilterList, onChange, labelList, filterOption }) => {
  return (
    <StyledFiltersSelectSpace direction="vertical">
      <Typography.Text strong>Filterauswahl</Typography.Text>
      <Select
        mode="multiple"
        allowClear
        placeholder="Filterauswahl"
        value={selectedFilterList}
        onChange={onChange}
        defaultValue={defaultSelectedFilterList}
        filterOption={filterOption ?? selectFilterOption}
        style={{ width: '100%' }}
      >
        {Object.entries(labelList)
          .sort(([aKey, aVal], [bKey, bVal]) => aVal.localeCompare(bVal))
          .map(([key, value]) => (
            <Select.Option key={key}>{value}</Select.Option>
          ))}
      </Select>
    </StyledFiltersSelectSpace>
  );
};

export default FiltersSelect;
