import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';
import {
  BkOrWeAbrDefFieldHelpNames,
  BkOrWeAbrDefObjektOutputOptionsFieldHelpNames,
  BkOrWeAbrDefTopOutputOptionsFieldHelpNames,
} from './abrDefFieldHelpMapper';

export const useWeAbrDefVersionFieldHelp = (): BkOrWeAbrDefFieldHelpNames => {
  const getFieldHelpText = useGetAppFieldHelpText<'WeAbrechnungsdefinitionVersion'>('WeAbrechnungsdefinitionVersion');

  const fieldHelpAbrDefAbrechkreisList = getFieldHelpText('WeAbrechnungsdefinitionVersion.abrechdefAbrechkreisList');

  const fieldHelpAbrDefKontoAbrechkreisList = getFieldHelpText('WeAbrechnungsdefinitionVersion.kontoAbrechnungskreisList');

  const fieldHelpNamesObjekt: BkOrWeAbrDefObjektOutputOptionsFieldHelpNames = {
    buchungsdetails: getFieldHelpText('WeAbrechnungsdefinitionVersion.objektAbrechnungOutputOptions.buchungsdetails'),
    consolidateEinnahmen: getFieldHelpText('WeAbrechnungsdefinitionVersion.objektAbrechnungOutputOptions.consolidateEinnahmen'),
    displayEinnahmen: getFieldHelpText('WeAbrechnungsdefinitionVersion.objektAbrechnungOutputOptions.displayEinnahmen'),
    displayKontonummer: getFieldHelpText('WeAbrechnungsdefinitionVersion.objektAbrechnungOutputOptions.displayKontonummer'),
  };

  const fieldHelpNamesTop: BkOrWeAbrDefTopOutputOptionsFieldHelpNames = {
    considerRueckstand: getFieldHelpText('WeAbrechnungsdefinitionVersion.topAbrechnungOutputOptions.considerRueckstand'),
    displayDueDate: getFieldHelpText('WeAbrechnungsdefinitionVersion.topAbrechnungOutputOptions.displayDueDate'),
    displayRueckstandOtherPersons: getFieldHelpText('WeAbrechnungsdefinitionVersion.topAbrechnungOutputOptions.displayRueckstandOtherPersons'),
    displayZahlungsverlauf: getFieldHelpText('WeAbrechnungsdefinitionVersion.topAbrechnungOutputOptions.displayZahlungsverlauf'),
    dueDateDisplayOptionMieter: {
      month: getFieldHelpText('WeAbrechnungsdefinitionVersion.topAbrechnungOutputOptions.dueDateDisplayOptionMieter.month'),
      type: getFieldHelpText('WeAbrechnungsdefinitionVersion.topAbrechnungOutputOptions.dueDateDisplayOptionMieter.type'),
    },
    dueDateDisplayOptionEigentuemerGuthaben: {
      month: getFieldHelpText('WeAbrechnungsdefinitionVersion.topAbrechnungOutputOptions.dueDateDisplayOptionEigentuemerGuthaben.month'),
      type: getFieldHelpText('WeAbrechnungsdefinitionVersion.topAbrechnungOutputOptions.dueDateDisplayOptionEigentuemerGuthaben.type'),
    },
    dueDateDisplayOptionEigentuemerNachzahlung: {
      month: getFieldHelpText('WeAbrechnungsdefinitionVersion.topAbrechnungOutputOptions.dueDateDisplayOptionEigentuemerNachzahlung.month'),
      type: getFieldHelpText('WeAbrechnungsdefinitionVersion.topAbrechnungOutputOptions.dueDateDisplayOptionEigentuemerNachzahlung.type'),
    },
    rueckstandOtherPersonsThreshold: getFieldHelpText('WeAbrechnungsdefinitionVersion.topAbrechnungOutputOptions.rueckstandOtherPersonsThreshold'),
  };

  return { fieldHelpAbrDefAbrechkreisList, fieldHelpAbrDefKontoAbrechkreisList, fieldHelpNamesObjekt, fieldHelpNamesTop };
};
