import React, { FC } from 'react';
import { Empty, PaginationProps } from 'antd';
import emailDeliveryTableColumns from './EmailDeliveryTableColumns';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { MailBaseData } from '../../../../types';
import { HandleTableSorting } from '../../../../shared/typeHelpers';

type Props = {
  handleTableSorting: HandleTableSorting;
  mailList?: MailBaseData[];
  pagination: PaginationProps;
};

const EmailDeliveryTable: FC<Props> = ({ pagination, handleTableSorting, mailList }) => {
  return (
    <TableWithColSelector<MailBaseData>
      size="small"
      pagination={pagination}
      loading={!mailList}
      dataSource={mailList}
      columns={emailDeliveryTableColumns}
      locale={{
        emptyText: <Empty description={<span>Kein E-mail vorhanden</span>} />,
      }}
      onChange={handleTableSorting}
      rowKey={(record) => record.mailId}
      filterIdentifier="main-email-delivery"
    />
  );
};

export default EmailDeliveryTable;
