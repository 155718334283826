import { FormikProps } from 'formik';
import React, { FC } from 'react';
import { FileInfo } from '../../../../types';
import FileUploadArea, { ValidateFileProps } from '../../../../components/FileUpload/FileUploadArea';
import { filterFormFields, FiltersFormValues } from './formMapper';
import { FILE_CONTENT_TYPE_PDF, IMAGE_SUPPORTED_FORMATS } from '../../../../helpers/fileHelper';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
};

const IncomingInvoiceUploadSelect: FC<Props> = ({ formikProps }) => {
  const onSelectFile = (data: FileInfo) => {
    formikProps.setFieldValue(filterFormFields.incomingInvoice, {
      fileId: data.fileId,
      filename: data.name,
      isUpload: false,
    });
  };

  return (
    <FileUploadArea<FiltersFormValues>
      name={filterFormFields.incomingInvoice}
      formikProps={formikProps}
      onSelectFileSetFieldValue={onSelectFile}
      validateFile={(file) => validateFile(file, formikProps)}
    />
  );
};

const validateFile = (file: ValidateFileProps, formikProps: FormikProps<FiltersFormValues>): boolean => {
  const hasNoType = !file.type;
  if (hasNoType) {
    formikProps.setFieldError(filterFormFields.incomingInvoice, `${file.name} hat keinen Dateityp. Ordner dürfen nicht hochgeladen werden.`);
    return false;
  }
  const allowedFileContentTypes = [FILE_CONTENT_TYPE_PDF, ...IMAGE_SUPPORTED_FORMATS];
  if (!allowedFileContentTypes.includes(file.type)) {
    formikProps.setFieldError(
      filterFormFields.incomingInvoice,
      `Das Format der Beleg Datei ${file.type} wird nicht unterstützt, unterstützte Formate sind: ${allowedFileContentTypes}`
    );
    return false;
  }
  const fileSizeInMB = file.size / 1024 / 1024;
  const isTooLarge = fileSizeInMB > 20;
  if (isTooLarge) {
    formikProps.setFieldError(filterFormFields.incomingInvoice, `${file.name} ist zu groß. Dateien müssen kleiner als 20 MB sein.`);
    return false;
  }
  return true;
};

export default IncomingInvoiceUploadSelect;
