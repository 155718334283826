import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { Form, Space } from 'antd';
import { FibuBelegSymbol, FibuBuchungszeile } from '../../../../../../types';
import BuchungszeileDetailsCardsBlock from '../../../shared/BuchungszeileListBlock/KontoDetailsCards/BuchungszeileDetailsCardsBlock';
import ListingFilters from './Filters/ListingFilters';
import { FiltersFormValues } from './Filters/buchungszeileListingFiltersFormMapper';
import { useToggle } from '../../../../../../hooks/useToggle';
import { filterBuchungszeileList } from './Filters/filterHelpers';
import BuchungszeileTable from './Table/BuchungszeileTable';
import { useQueryParams } from '../../../../../../hooks/useStringQueryParams';
import { BookingDetailsPageQueryParams } from '../../../../bookingDetailsPaths';
import BuchungsanweisungDeviationModal from './Deviation/BuchungsanweisungDeviationModal';
import BzShowStornoSwitch from './Filters/BzShowStornoSwitch';
import BzShowUmbuchungenSwitch from './Filters/BzShowUmbuchungenSwitch';
import { hasBookingStornoBuchungszeileListEntries } from '../../../shared/bookingHelpers';

type Props = {
  belegId?: string | null;
  buchungszeileList: FibuBuchungszeile[];
  belegSymbol: FibuBelegSymbol;
  hasOnlyOneBuchung: boolean;
  includeDeviations: boolean;
  onAction: () => void;
};

const BuchungszeileListing: FC<Props> = ({ belegId, buchungszeileList, belegSymbol, hasOnlyOneBuchung, includeDeviations, onAction }) => {
  const { buchungIdList } = useQueryParams<BookingDetailsPageQueryParams>();
  const [selectedRow, setSelectedRow] = useState<FibuBuchungszeile>();
  const [showDeviations, toggleShowDeviations] = useToggle();
  const [showUmbuchungen, setShowUmbuchungen] = useToggle();
  const [showStorno, setShowStorno] = useToggle(hasOnlyOneBuchung);
  const [deviationBuchungsanweisungId, setDeviationBuchungsanweisungId] = useState<string>('');

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const shouldShowFilters = buchungIdList!.length > 1;

  const hasUmbuchungen = buchungszeileList.some((bz) => bz.umbuchung);
  const hasStorno = hasBookingStornoBuchungszeileListEntries(buchungszeileList, belegSymbol);

  return (
    <>
      <Formik<FiltersFormValues> initialValues={{}} onSubmit={(values) => console.log(values)}>
        {(formikProps) => {
          const filteredBuchungszeileList = filterBuchungszeileList(buchungszeileList, formikProps, hasOnlyOneBuchung, showStorno, showUmbuchungen);

          return (
            <Form layout="vertical">
              {/* Block 1 - Filters if there is more than one buchung (= buchungIdList.length > 1 */}
              {shouldShowFilters && <ListingFilters buchungszeileList={buchungszeileList} formikProps={formikProps} />}

              {/* Block 2 - checkbox for showing Storno und Umbuchungen entries in the BuchungszeileTable */}
              {((!hasOnlyOneBuchung && hasStorno) || hasUmbuchungen) && (
                <Space size="large">
                  {hasStorno && <BzShowStornoSwitch showStorno={showStorno} setShowStorno={setShowStorno} />}
                  {hasUmbuchungen && <BzShowUmbuchungenSwitch showUmbuchungen={showUmbuchungen} setShowUmbuchungen={setShowUmbuchungen} />}
                </Space>
              )}

              {/* Block 3 - BuchungszeileTable with/without Umbuchungen entries and marked deviations entries */}
              <BuchungszeileTable
                buchungszeileList={filteredBuchungszeileList}
                belegId={belegId}
                includeDeviations={includeDeviations}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                setShowDeviations={toggleShowDeviations}
                setDeviationBuchungsanweisungId={setDeviationBuchungsanweisungId}
              />
            </Form>
          );
        }}
      </Formik>
      {/* Block 4 - Konto and Gegenkonto details cards */}
      {selectedRow && <BuchungszeileDetailsCardsBlock selectedRow={selectedRow} />}

      {/* Block 5 - deviations listing */}
      {showDeviations && deviationBuchungsanweisungId && belegId && (
        <BuchungsanweisungDeviationModal
          open={showDeviations}
          onCancel={() => {
            toggleShowDeviations();
            setDeviationBuchungsanweisungId('');
          }}
          buchungsanweisungId={deviationBuchungsanweisungId}
          onAction={() => {
            onAction();
            setDeviationBuchungsanweisungId('');
          }}
          buchungszeileList={buchungszeileList}
        />
      )}
    </>
  );
};

export default BuchungszeileListing;
