import * as Yup from 'yup';
import { entityIsRequired } from '../../../../components/message/validationMsg';
import { vertragCreateFormFields } from './vertragCreateFormMapper';
import { isVertragsartTypeLeerstehung, isVertragsartTypeWegLeerstehung } from '../../shared/vertragsartHelpers';

export const vertragCreateFormValidationSchema = Yup.object().shape({
  [vertragCreateFormFields.vertragsart]: Yup.string().required(entityIsRequired('Vertragsart')),
  [vertragCreateFormFields.vertragsBeginn]: Yup.date().required(entityIsRequired('Vertragsbeginn')),
  [vertragCreateFormFields.vertragspartnerId]: Yup.string().required(entityIsRequired('Vertragspartner')),
  [vertragCreateFormFields.verrechnungsart]: Yup.string()
    .nullable()
    .when(vertragCreateFormFields.vertragsart, ([vertragsart], schema) =>
      isVertragsartTypeLeerstehung(vertragsart) || isVertragsartTypeWegLeerstehung(vertragsart)
        ? schema
        : schema.required(entityIsRequired('Verrechnungsart'))
    ),
  [vertragCreateFormFields.paymentMethod]: Yup.string()
    .nullable()
    .when(vertragCreateFormFields.vertragsart, ([vertragsart], schema) =>
      isVertragsartTypeLeerstehung(vertragsart) || isVertragsartTypeWegLeerstehung(vertragsart)
        ? schema
        : schema.required(entityIsRequired('Zahlungsart'))
    ),
});
