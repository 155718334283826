import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import { URI_INCOMING_INVOICE_PAGE } from '../../features/IncomingInvoice/incomingInvoiceUriPaths';
import IncomingInvoicePage from './IncomingInvoicePage';

const incomingInvoicePageRoute = (
  <Route path={URI_INCOMING_INVOICE_PAGE} element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<IncomingInvoicePage />} />} />
);

export default incomingInvoicePageRoute;
