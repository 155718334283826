import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { Box, Flex } from 'rebass';
import { EuroAmount, Percent } from '../../../../components/Number';
import { TTVPosForVertragLevelDetailsForUst } from './vPosForVertragLevelList.model';

type Props = {
  ustColList: TTVPosForVertragLevelDetailsForUst[];
};

const VPosForVertragLevelDetails: FC<Props> = ({ ustColList }) => (
  <Flex flexDirection="row">
    <Box width={[ustColList.length === 3 ? '25%' : '50%']}>
      <Space direction="vertical">
        <Typography.Text type="secondary">Steuersatz</Typography.Text>
        <Typography.Text type="secondary">Netto Summe</Typography.Text>
      </Space>
    </Box>
    <Box width={[ustColList.length === 3 ? '75%' : '50%']} display="flex" textAlign="right">
      {ustColList.map((ustCol, index) => {
        return (
          <Space key={index} direction="vertical" align="end" style={{ width: '100%', justifyContent: 'center' }}>
            <Typography.Text type="secondary">
              USt. <Percent value={ustCol.steuersatz / 100} />
            </Typography.Text>
            <Typography.Text>
              <EuroAmount value={ustCol.sumNetto} />
            </Typography.Text>
          </Space>
        );
      })}
    </Box>
  </Flex>
);

export default VPosForVertragLevelDetails;
