import { generatePath } from 'react-router-dom';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../constants/andromedaSystemSettingsUriPaths';

export const URI_ANDROMEDA_SYS_SETTINGS_AUFTRAGSART_PAGE = {
  createPage: `${URI_ANDROMEDA_SYS_SETTINGS.auftragsartPage}/new`,
  updatePage: `${URI_ANDROMEDA_SYS_SETTINGS.auftragsartPage}/:auftragsartId`,
};

export const generatePathToAuftragsartTemplateUpdatePage = (auftragsartId: string) =>
  generatePath(URI_ANDROMEDA_SYS_SETTINGS_AUFTRAGSART_PAGE.updatePage, { auftragsartId });

export default URI_ANDROMEDA_SYS_SETTINGS_AUFTRAGSART_PAGE;
