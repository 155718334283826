import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AufteilungsschluesselFieldsFragmentDoc } from '../../../Aufteilungsschluessel/gql/AufteilungsschluesselFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateWeAbrechnungsdefinitionForKsMutationVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
  input: Types.WeAbrechnungsdefinitionCreateInput;
}>;

export type CreateWeAbrechnungsdefinitionForKsMutation = {
  createWeAbrechnungsdefinitionForKS: {
    data: {
      abrechdefAbrechkreisList: Array<{
        abrechnungskreis: { abrechnungskreisId: string };
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vorschreibungsposition?: { vorschreibungspositionId: string } | null;
      }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateWeAbrechnungsdefinitionForFdMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  input: Types.WeAbrechnungsdefinitionCreateInput;
}>;

export type CreateWeAbrechnungsdefinitionForFdMutation = {
  createWeAbrechnungsdefinitionForFD: {
    data: {
      abrechdefAbrechkreisList: Array<{
        abrechnungskreis: { abrechnungskreisId: string };
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vorschreibungsposition?: { vorschreibungspositionId: string } | null;
      }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateWeAbrechnungsdefinitionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  input: Types.WeAbrechnungsdefinitionUpdateInput;
}>;

export type UpdateWeAbrechnungsdefinitionMutation = {
  updateWeAbrechnungsdefinition: {
    data: {
      abrechdefAbrechkreisList: Array<{
        abrechnungskreis: { abrechnungskreisId: string };
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vorschreibungsposition?: { vorschreibungspositionId: string } | null;
      }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateWeAbrechnungsdefinitionForKsDocument = gql`
  mutation CreateWeAbrechnungsdefinitionForKS($kundenSystemId: ID!, $input: WeAbrechnungsdefinitionCreateInput!) {
    createWeAbrechnungsdefinitionForKS(kundenSystemId: $kundenSystemId, input: $input) {
      data {
        abrechdefAbrechkreisList {
          abrechnungskreis {
            abrechnungskreisId
          }
          aufteilungsschluessel {
            ...AufteilungsschluesselFields
          }
          vorschreibungsposition {
            vorschreibungspositionId
          }
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
`;
export function useCreateWeAbrechnungsdefinitionForKsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateWeAbrechnungsdefinitionForKsMutation, CreateWeAbrechnungsdefinitionForKsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateWeAbrechnungsdefinitionForKsMutation, CreateWeAbrechnungsdefinitionForKsMutationVariables>(
    CreateWeAbrechnungsdefinitionForKsDocument,
    options
  );
}
export type CreateWeAbrechnungsdefinitionForKsMutationHookResult = ReturnType<typeof useCreateWeAbrechnungsdefinitionForKsMutation>;
export type CreateWeAbrechnungsdefinitionForKsMutationResult = Apollo.MutationResult<CreateWeAbrechnungsdefinitionForKsMutation>;
export type CreateWeAbrechnungsdefinitionForKsMutationOptions = Apollo.BaseMutationOptions<
  CreateWeAbrechnungsdefinitionForKsMutation,
  CreateWeAbrechnungsdefinitionForKsMutationVariables
>;
export const CreateWeAbrechnungsdefinitionForFdDocument = gql`
  mutation CreateWeAbrechnungsdefinitionForFD($firmendatenId: ID!, $input: WeAbrechnungsdefinitionCreateInput!) {
    createWeAbrechnungsdefinitionForFD(firmendatenId: $firmendatenId, input: $input) {
      data {
        abrechdefAbrechkreisList {
          abrechnungskreis {
            abrechnungskreisId
          }
          aufteilungsschluessel {
            ...AufteilungsschluesselFields
          }
          vorschreibungsposition {
            vorschreibungspositionId
          }
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
`;
export function useCreateWeAbrechnungsdefinitionForFdMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateWeAbrechnungsdefinitionForFdMutation, CreateWeAbrechnungsdefinitionForFdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateWeAbrechnungsdefinitionForFdMutation, CreateWeAbrechnungsdefinitionForFdMutationVariables>(
    CreateWeAbrechnungsdefinitionForFdDocument,
    options
  );
}
export type CreateWeAbrechnungsdefinitionForFdMutationHookResult = ReturnType<typeof useCreateWeAbrechnungsdefinitionForFdMutation>;
export type CreateWeAbrechnungsdefinitionForFdMutationResult = Apollo.MutationResult<CreateWeAbrechnungsdefinitionForFdMutation>;
export type CreateWeAbrechnungsdefinitionForFdMutationOptions = Apollo.BaseMutationOptions<
  CreateWeAbrechnungsdefinitionForFdMutation,
  CreateWeAbrechnungsdefinitionForFdMutationVariables
>;
export const UpdateWeAbrechnungsdefinitionDocument = gql`
  mutation UpdateWeAbrechnungsdefinition($abrechnungsdefinitionId: ID!, $input: WeAbrechnungsdefinitionUpdateInput!) {
    updateWeAbrechnungsdefinition(abrechnungsdefinitionId: $abrechnungsdefinitionId, input: $input) {
      data {
        abrechdefAbrechkreisList {
          abrechnungskreis {
            abrechnungskreisId
          }
          aufteilungsschluessel {
            ...AufteilungsschluesselFields
          }
          vorschreibungsposition {
            vorschreibungspositionId
          }
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
`;
export function useUpdateWeAbrechnungsdefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateWeAbrechnungsdefinitionMutation, UpdateWeAbrechnungsdefinitionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWeAbrechnungsdefinitionMutation, UpdateWeAbrechnungsdefinitionMutationVariables>(
    UpdateWeAbrechnungsdefinitionDocument,
    options
  );
}
export type UpdateWeAbrechnungsdefinitionMutationHookResult = ReturnType<typeof useUpdateWeAbrechnungsdefinitionMutation>;
export type UpdateWeAbrechnungsdefinitionMutationResult = Apollo.MutationResult<UpdateWeAbrechnungsdefinitionMutation>;
export type UpdateWeAbrechnungsdefinitionMutationOptions = Apollo.BaseMutationOptions<
  UpdateWeAbrechnungsdefinitionMutation,
  UpdateWeAbrechnungsdefinitionMutationVariables
>;
