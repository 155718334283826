import React, { Reducer, useEffect, useReducer } from 'react';
import { Button } from 'antd';
import { NestedTableColProps } from '../NestedTable/NestedTable';
import { EditableTableProvider } from './editableTableContext';
import { EditableTableWithColSelectorProps } from './editTableProps';
import editableTableReducer, { EditableTableAction, EditableTableState } from './editableTableReducer';
import { EditableTableRecordType } from '../EditableTableOld/EditableTableProps';
import { EditableRow } from './EditableRow';
import EditableCell from './EditableCell';
import { isNotNil } from '../../../helpers/assertionHelper';
import NestedTableWithColSelectorWrapper from '../NestedTableWithColSelector/NestedTableWithColSelectorWrapper';
import NestedTableWithColSelector, { NestedTableWithColSelectorProps } from '../NestedTableWithColSelector/NestedTableWithColSelector';

const EditableTableWithColSelector = <
  TableRecordType extends Record<string, any> = any,
  TableRecordFormValuesType extends Record<string, any> = any,
>({
  dataSource,
  getRowKey,
  columns,
  validationSchema,
  mapToFormValues,
  onSubmit,
  newEntryRowToBeAdded,
  actionsWithoutAddButton,
  loading,
  filterIdentifier,
  ...restProps
}: EditableTableWithColSelectorProps<TableRecordType, TableRecordFormValuesType> &
  Omit<NestedTableWithColSelectorProps<TableRecordType>, 'columns'>) => {
  const [{ records, selectedRecord, isSubmitLoading }, dispatch] = useReducer<
    Reducer<EditableTableState<TableRecordType>, EditableTableAction<TableRecordType>>
  >(editableTableReducer, {
    records: [],
    selectedRecord: null,
    isSubmitLoading: false,
    getRowKey,
  });

  // ----editable table responsibility
  useEffect(() => {
    if (dataSource) dispatch({ type: 'INIT_RECORDS', records: dataSource });
  }, [dataSource]);
  // ----editable table responsibility

  const handleAdd = () => {
    if (newEntryRowToBeAdded) {
      dispatch({ type: 'ADD_RECORD', record: newEntryRowToBeAdded.defaultNewEntry });
    }
  };

  const handleUpdate = (selectedRecord: EditableTableRecordType<TableRecordType>) => dispatch({ type: 'SELECT_RECORD', selectedRecord });

  const handleCancel = () => dispatch({ type: 'CANCEL_EDIT' });

  const editableTableColumns = columns.map((col) => ({
    ...col,
    onCell: (record: TableRecordType) => ({
      record,
      editable: col.editable ? col.editable : false,
      renderInEditMode: col.renderInEditMode,
    }),
  })) as NestedTableColProps<TableRecordType>[];

  const addButton = newEntryRowToBeAdded ? (
    <Button type="primary" size="small" onClick={handleAdd} disabled={!!selectedRecord}>
      {newEntryRowToBeAdded.addButtonText}
    </Button>
  ) : undefined;

  const actions = (newEntryRowToBeAdded || actionsWithoutAddButton) && (
    <>
      {actionsWithoutAddButton && actionsWithoutAddButton}
      {newEntryRowToBeAdded && addButton}
    </>
  );

  const isRowInEditMode = (rowKey?: string | null) => (isNotNil(selectedRecord?.recordKey) ? selectedRecord.recordKey === rowKey : false);

  return (
    <EditableTableProvider<TableRecordType>
      selectedRecord={selectedRecord}
      cancelEdit={handleCancel}
      onUpdate={handleUpdate}
      isSubmitLoading={isSubmitLoading}
      isRowInEditMode={isRowInEditMode}
      validationSchema={validationSchema}
      mapToFormValues={mapToFormValues}
      onSubmit={onSubmit}
      dispatch={dispatch}
    >
      <NestedTableWithColSelectorWrapper filterIdentifier={filterIdentifier}>
        <NestedTableWithColSelector<TableRecordType>
          components={{
            body: {
              cell: EditableCell,
              row: EditableRow,
            },
          }}
          rowKey={(record) => record.recordKey}
          loading={loading}
          dataSource={records}
          columns={editableTableColumns}
          actions={actions}
          {...restProps}
        />
      </NestedTableWithColSelectorWrapper>
    </EditableTableProvider>
  );
};

export default EditableTableWithColSelector;
