import { FC } from 'react';
import HistoryListingTable from '../../../../features/History/HistoryListingTable';
import { useFirmendatenOperatingSiteDeletionHistoryListQuery } from '../../../../features/KundenSystem/Firmendaten/gql/FirmendatenQueries.types';
import { HistoryType } from '../../../../features/History/shared/HistoryModal';

type Props = {
  firmendatenId: string;
};

const FirmendatenOperatingSiteDeletionHistoryListingTable: FC<Props> = ({ firmendatenId }) => {
  const { data, loading } = useFirmendatenOperatingSiteDeletionHistoryListQuery({ variables: { firmendatenId } });
  const historyList = data?.getFirmendatenOperatingSiteDeletionHistoryList.data;

  return (
    <HistoryListingTable historyType={HistoryType.Deletion} historyList={historyList} loading={loading} filterIdPrefix="firmendaten-operating-site" />
  );
};

export default FirmendatenOperatingSiteDeletionHistoryListingTable;
