import React, { FC } from 'react';
import { Button, Dropdown, Modal, Space, MenuProps } from 'antd';
import { DislikeOutlined, DownOutlined, MedicineBoxOutlined, ToolOutlined } from '@ant-design/icons';
import { Spacer } from '../../Grid';
import { useToggle } from '../../../hooks/useToggle';

type DeviationActionButtonsProps = {
  isApplyAllDeviationsDisabled: boolean;
  onShowFolgeDeviationsButtonClick?: () => void;
  runApplyAllDeviations: () => Promise<unknown> | void;
  runPruefungAussetzen?: () => Promise<unknown> | void;
  showSubsequentDeviations?: boolean;
  showSubsequentDeviationsButton?: boolean;
};

const DeviationActionButtons: FC<DeviationActionButtonsProps> = ({
  isApplyAllDeviationsDisabled,
  onShowFolgeDeviationsButtonClick,
  runApplyAllDeviations,
  runPruefungAussetzen,
  showSubsequentDeviations,
  showSubsequentDeviationsButton,
}) => {
  const [loading, setLoading] = useToggle(false);

  function isPromise(value: any): value is Promise<unknown> {
    return value instanceof Promise;
  }

  const onAccept = () => {
    setLoading(true);

    const result = runApplyAllDeviations();
    if (isPromise(result)) {
      result.finally(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    return result;
  };

  const onIgnore = runPruefungAussetzen
    ? () => {
        setLoading(true);
        const result = runPruefungAussetzen();
        if (isPromise(result)) {
          result.finally(() => {
            setLoading(false);
          });
        } else {
          setLoading(false);
        }
      }
    : undefined;

  return (
    <>
      <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
        {showSubsequentDeviationsButton && (
          <Button onClick={onShowFolgeDeviationsButtonClick}>Folge-Abweichungen {showSubsequentDeviations ? 'ausblenden' : 'einblenden'}</Button>
        )}
        <Dropdown disabled={loading} menu={{ items: menuItems(isApplyAllDeviationsDisabled, onAccept, onIgnore) }}>
          <Button loading={loading}>
            Abweichungen... <DownOutlined />
          </Button>
        </Dropdown>
      </Space>
      <Spacer height={16} />
    </>
  );
};

const menuItems = (isApplyAllDeviationsDisabled: boolean, onAccept: () => void, onIgnore?: () => void): MenuProps['items'] => {
  return [
    {
      key: '1',
      label: 'korrigieren',
      onClick: () => onAccept(),
      icon: <ToolOutlined />,
      disabled: isApplyAllDeviationsDisabled,
    },
    onIgnore
      ? {
          key: '2',
          label: 'ignorieren',
          onClick: () => showConfirmPruefungAussetzen(onIgnore),
          icon: <DislikeOutlined />,
          danger: true,
        }
      : null,
  ];
};

const showConfirmPruefungAussetzen = (runPruefungAussetzen: () => void) => {
  Modal.confirm({
    title: `Möchten Sie die Überprüfung für alle Abweichungen aussetzen?`,
    content: `EasyCloud setzt die fachliche und logische Überprüfung für alle Abweichungen aus.`,
    okText: 'Ja',
    cancelText: 'Nein',
    onOk() {
      return runPruefungAussetzen();
    },
  });
};

export default DeviationActionButtons;
