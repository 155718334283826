import React, { FC, ReactNode } from 'react';
import { FormikProps } from 'formik';
import { ebicsUserListingFiltersFormFields, EbicsUserListingFiltersFormValues } from './ebicsUserListingFiltersFormMapper';
import FiltersWith4OrMoreFields from '../../../../../components/Filters/FiltersWith4OrMoreFields';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import MitarbeiterSelect from '../../../../../shared/components/MitarbeiterSelect/MitarbeiterSelect';
import EbicsBankConfigSelectForm from '../../../shared/EbicsBankConfigSelectForm';
import EbicsUserStatusSelectForm from '../../../shared/EbicsUserStatusSelectForm';
import EbicsIbanSelectForm from '../../../shared/EbicsIbanSelectForm';

type Props = {
  actionButtons: ReactNode;
  formikProps: FormikProps<EbicsUserListingFiltersFormValues>;
};

const EbicsUserListingFilters: FC<Props> = ({ actionButtons, formikProps }) => (
  <FiltersWith4OrMoreFields
    actionButtons={actionButtons}
    leftColumn={
      <>
        <FormItemWithoutColon name={ebicsUserListingFiltersFormFields.mitarbeiterIdList} label="Mitarbeiter">
          <MitarbeiterSelect mode="multiple" name={ebicsUserListingFiltersFormFields.mitarbeiterIdList} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
        <FormItemWithoutColon name={ebicsUserListingFiltersFormFields.ibanList} label="IBAN">
          <EbicsIbanSelectForm mode="multiple" name={ebicsUserListingFiltersFormFields.ibanList} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      </>
    }
    rightColumn={
      <>
        <FormItemWithoutColon name={ebicsUserListingFiltersFormFields.banknameId} label="Bankname">
          <EbicsBankConfigSelectForm name={ebicsUserListingFiltersFormFields.banknameId} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
        <FormItemWithoutColon name={ebicsUserListingFiltersFormFields.status} label="Status">
          <EbicsUserStatusSelectForm name={ebicsUserListingFiltersFormFields.status} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      </>
    }
  />
);

export default EbicsUserListingFilters;
