import React, { FC } from 'react';
import { Skeleton } from 'antd';
import {
  useBkAbrechnungsdefinitionTemplateVersionListQuery,
  useBkAbrechnungsdefinitionTemplateVersionQuery,
} from '../../../../../../features/AbrechnungsdefinitionTemplate/gql/BkAbrDef/BkAbrDefTemplateVersionQueries.types';
import BkAbrDefTemplateVersionUpdateForm from '../../../../../../features/AbrechnungsdefinitionTemplate/Version/Form/Update/BkAbrDefTemplateVersionUpdateForm';

type Props = {
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersionId: string;
};
const AndromedaSysSettingsBkAbrechnungsdefinitionVersionUpdatePage: FC<Props> = ({ abrechnungsdefinitionId, abrechnungsdefinitionVersionId }) => {
  const { data: bkAbrDefVersionListData } = useBkAbrechnungsdefinitionTemplateVersionListQuery({
    variables: { abrechnungsdefinitionId },
  });
  const abrechnungsdefinitionVersionList = bkAbrDefVersionListData?.getBkAbrechnungsdefinitionTemplateVersionList.data;

  const { data: bkAbrDefVersion, refetch } = useBkAbrechnungsdefinitionTemplateVersionQuery({
    variables: { abrechnungsdefinitionId, abrechnungsdefinitionVersionId },
  });
  const abrechnungsdefinitionVersion = bkAbrDefVersion?.getBkAbrechnungsdefinitionTemplateVersion.data;

  if (!abrechnungsdefinitionVersion || !abrechnungsdefinitionVersionList) return <Skeleton active />;

  const firmendatenId = abrechnungsdefinitionVersion.firmendatenId ?? '';

  return (
    <BkAbrDefTemplateVersionUpdateForm
      abrechnungsdefinitionVersionList={abrechnungsdefinitionVersionList}
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      abrechnungsdefinitionVersion={abrechnungsdefinitionVersion}
      validFrom={abrechnungsdefinitionVersion.validFrom}
      kommentar={abrechnungsdefinitionVersion.kommentar ?? ''}
      onSuccess={refetch}
      firmendatenId={firmendatenId}
    />
  );
};

export default AndromedaSysSettingsBkAbrechnungsdefinitionVersionUpdatePage;
