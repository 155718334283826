import styled from 'styled-components';
import { Tag } from 'antd';

export const StyledTag = styled(Tag)`
  background: ${(props) => (props.color === 'red' ? '#fff1f0' : '#f5f5f5')};
  border: 1px solid ${(props) => (props.color === 'red' ? '#ffc8c5' : '#f0f0f0')};
  margin: 3px 3px 3px 0;
  line-height: 13px;
  padding: 0 2px;
  height: 16px;
`;
