import React, { FC } from 'react';
import { Input, InputNumber } from 'formik-antd';
import { FormFields } from '../../../../../../helpers/formikHelper';
import { GrundstueckdsdatenCreateFormValues } from '../Create/grundstueckdsdatenCreateFormMapper';
import FormattedDecimalFormInput from '../../../../../../components/Input-antd/FormattedDecimalFormInput';
import DatenTabColumnsLayout from '../../shared/DatenTabColumnsLayout';
import FormItemWithFieldHelp from '../../../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../../../FieldHelp/useGetFieldHelpText';

type Props = {
  formFields: FormFields<GrundstueckdsdatenCreateFormValues>;
};

const GrundstuecksdatenDatenTab: FC<Props> = ({ formFields }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'ObjektInfoFeldGruendstuecksdaten'>('ObjektInfoFeldGruendstuecksdaten');

  return (
    <DatenTabColumnsLayout
      leftColumn={
        <>
          <FormItemWithFieldHelp
            name={formFields.grundstuecksnummer}
            label="Grundstücksnummer"
            fieldHelp={getFieldHelpText('ObjektInfoFeldGruendstuecksdaten.grundstuecksnummer')}
          >
            <Input id={formFields.grundstuecksnummer} name={formFields.grundstuecksnummer} size="small" />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.bebauteFlaeche}
            label="Bebaute Fläche"
            fieldHelp={getFieldHelpText('ObjektInfoFeldGruendstuecksdaten.bebauteFlaeche')}
          >
            <FormattedDecimalFormInput name={formFields.bebauteFlaeche} size="small" style={{ width: '100%' }} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.bauflaeche}
            label="Baufläche"
            fieldHelp={getFieldHelpText('ObjektInfoFeldGruendstuecksdaten.bauflaeche')}
          >
            <FormattedDecimalFormInput name={formFields.bauflaeche} size="small" style={{ width: '100%' }} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp name={formFields.baujahr} label="Baujahr" fieldHelp={getFieldHelpText('ObjektInfoFeldGruendstuecksdaten.baujahr')}>
            <InputNumber name={formFields.baujahr} size="small" controls={false} min={0} style={{ width: '100%' }} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.einlagezahl}
            label="Einlagezahl"
            fieldHelp={getFieldHelpText('ObjektInfoFeldGruendstuecksdaten.einlagezahl')}
          >
            <Input id={formFields.einlagezahl} name={formFields.einlagezahl} size="small" />
          </FormItemWithFieldHelp>
        </>
      }
      rightColumn={
        <>
          <FormItemWithFieldHelp
            name={formFields.benuetzungsbewilligung}
            label="Benützungsbewilligung"
            fieldHelp={getFieldHelpText('ObjektInfoFeldGruendstuecksdaten.benuetzungsbewilligung')}
          >
            <Input id={formFields.benuetzungsbewilligung} name={formFields.benuetzungsbewilligung} size="small" />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.katastralgemeinde}
            label="Katastralgemeinde"
            fieldHelp={getFieldHelpText('ObjektInfoFeldGruendstuecksdaten.katastralgemeinde')}
          >
            <Input id={formFields.katastralgemeinde} name={formFields.katastralgemeinde} size="small" />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.anzahlGeschosseUeberNiveau}
            label="Anzahl Geschoße über Niveau"
            fieldHelp={getFieldHelpText('ObjektInfoFeldGruendstuecksdaten.anzahlGeschosseUeberNiveau')}
          >
            <InputNumber name={formFields.anzahlGeschosseUeberNiveau} size="small" style={{ width: '100%' }} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.anzahlGeschosseUnterNiveau}
            label="Anzahl Geschoße unter Niveau"
            fieldHelp={getFieldHelpText('ObjektInfoFeldGruendstuecksdaten.anzahlGeschosseUnterNiveau')}
          >
            <InputNumber name={formFields.anzahlGeschosseUnterNiveau} size="small" style={{ width: '100%' }} />
          </FormItemWithFieldHelp>
        </>
      }
    />
  );
};

export default GrundstuecksdatenDatenTab;
