import React from 'react';
import { Space, Tag, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { TableWithAlignedColsColumnType } from '../../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { generatePathToKontoblattPage } from '../../../../../Kontoblatt/kontoblattUriPaths';
import { EuroAmount } from '../../../../../../components/Number';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';
import { ObjAbrKonto } from '../../../../../../types';

const ustVomAufwandKontoTableColumns = (
  objektId: string,
  fromInclusive: string,
  toInclusive: string,
  buchungskreisId: string
): TableWithAlignedColsColumnType<ObjAbrKonto>[] => [
  {
    title: '',
    render: (konto) => <Typography.Text>{konto.bezeichnung}</Typography.Text>,
    align: 'left',
  },
  {
    title: '',
    align: 'center',
    render: (konto) =>
      konto.aufteilungsschluesselList.length > 0 ? (
        <Space style={{ width: '100%', justifyContent: 'center' }} wrap>
          {konto.aufteilungsschluesselList.map((aufteilungsschluessel, index) => (
            <Tag key={index}>{aufteilungsschluessel}</Tag>
          ))}
        </Space>
      ) : (
        <TextForEmptyValue textToShow="none" />
      ),
  },
  {
    title: '',
    colSpan: 2,
    render: (konto) => (
      <Link
        target="_blank"
        to={generatePathToKontoblattPage({
          kontoId: konto.kontoId,
          objektId,
          buchungsdatumFrom: fromInclusive,
          buchungsdatumTo: toInclusive,
          buchungskreisId,
        })}
      >
        <EuroAmount value={konto.summe} />
      </Link>
    ),
  },
  {
    title: '',
    colSpan: 2,
    render: () => <TextForEmptyValue textToShow="none" />,
  },
  {
    title: '',
    render: () => <TextForEmptyValue textToShow="none" />,
  },
];

export default ustVomAufwandKontoTableColumns;
