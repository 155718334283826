import React, { FC } from 'react';
import { Button, Col, Row } from 'antd';
import { FieldArrayRenderProps } from 'formik';
import { createNewMahngebuehr } from '../../../mahndefinitionFormMapper';

type Props = {
  arrayHelpers: FieldArrayRenderProps;
};

const MahngebuehrEmpty: FC<Props> = ({ arrayHelpers }) => {
  return (
    <Row justify="center">
      <Col style={{ width: 'fit-content', justifyContent: 'center' }}>
        <Button
          size="small"
          onClick={() => {
            arrayHelpers.push(createNewMahngebuehr());
          }}
        >
          Mahngebühr hinzufügen
        </Button>
      </Col>
    </Row>
  );
};

export default MahngebuehrEmpty;
