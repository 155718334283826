import React, { FC } from 'react';
import { ExclamationCircleOutlined, WarningTwoTone } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import { TGenerierlaufEntry } from '../verarbeitungHelpers';
import theme from '../../../theme';

type Props = {
  generierlaufEntryList: TGenerierlaufEntry;
};

/** Renders the expanded row with both errors and warnings if present */
const GenerierlaufStatusExpandedRow: FC<Props> = ({ generierlaufEntryList }) => {
  const warningList =
    generierlaufEntryList.warningList.length > 0 ? (
      <>
        {generierlaufEntryList.warningList.map((item, index) => (
          <Typography.Paragraph style={{ paddingLeft: '48px' }} key={index}>
            <Space size={4}>
              <WarningTwoTone style={{ cursor: 'help' }} twoToneColor={theme.colors.warning} /> {item.message}
            </Space>
          </Typography.Paragraph>
        ))}
      </>
    ) : null;

  const errorList =
    generierlaufEntryList.errorList.length > 0 ? (
      <>
        {generierlaufEntryList.errorList.map((item, index) => (
          <Typography.Paragraph style={{ paddingLeft: '48px' }} key={index}>
            <Space size={4}>
              <ExclamationCircleOutlined style={{ color: theme.colors.error }} /> {item.message}
            </Space>
          </Typography.Paragraph>
        ))}
      </>
    ) : null;

  if (warningList || errorList) {
    return (
      <Space direction="vertical" size={4} style={{ padding: '5px 0' }}>
        {errorList}
        {warningList}
      </Space>
    );
  }

  return <Typography.Paragraph style={{ padding: '5px' }}>{generierlaufEntryList.infoMessage}</Typography.Paragraph>;
};

export default GenerierlaufStatusExpandedRow;
