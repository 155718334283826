import React, { FC, useState } from 'react';
import { Select, SelectProps } from 'formik-antd';
import useDebounce from '../../../../hooks/useDebounce';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import SelectNotFoundContent from '../../../shared/SelectNotFoundContent';
import { useFilterReceivedBankTransactionPurposeOfUseTextListQuery } from '../../gql/ReceivedBankTransactionQueries.types';
import { isTextEqualOrLongerThanThreeCharacters } from '../../../../helpers/stringHelper';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = {
  purposeOfUse?: string | null;
} & Omit<
  SelectProps,
  | 'id'
  | 'placeholder'
  | 'size'
  | 'allowClear'
  | 'showSearch'
  | 'filterOption'
  | 'onSelect'
  | 'onSearch'
  | 'onClear'
  | 'searchValue'
  | 'notFoundContent'
>;

const ReceivedBankTransactionPurposeOfUseTextSelect: FC<Props> = ({ purposeOfUse, ...restProps }) => {
  const [purposeOfUseText, setPurposeOfUseText] = useState<string>(purposeOfUse ? purposeOfUse.toString() : '');
  const debouncedText = useDebounce(purposeOfUseText);

  const { loading, data } = useFilterReceivedBankTransactionPurposeOfUseTextListQuery({
    variables: {
      purposeOfUseText: debouncedText as string,
    },
    skip: debouncedText ? !isTextEqualOrLongerThanThreeCharacters(debouncedText as string) : true,
  });

  const purposeOfUseTextList = data?.getFilterReceivedBankTransactionPurposeOfUseTextList.data ?? [];

  return (
    <Select
      {...restProps}
      id={restProps.name}
      loading={loading}
      size="small"
      allowClear
      showSearch
      placeholder="Text auswählen"
      filterOption={selectFilterOption}
      onSelect={setPurposeOfUseText}
      onSearch={(value) => {
        purposeOfUseText.length === 1 && !value && setPurposeOfUseText('');
        value && setPurposeOfUseText(value);
      }}
      onClear={() => setPurposeOfUseText('')}
      searchValue={purposeOfUseText}
      notFoundContent={<SelectNotFoundContent loading={loading} text={debouncedText as string} />}
    >
      {purposeOfUseTextList?.map((purposeOfUseText) => (
        <Select.Option key={purposeOfUseText} value={purposeOfUseText}>
          <DataWithShortenedText text={purposeOfUseText} maxTextLength={40} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default ReceivedBankTransactionPurposeOfUseTextSelect;
