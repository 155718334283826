import React from 'react';
import { Route } from 'react-router';
import { isAdmin } from '../../../../security/permissionChecks';
import VerwaltungAdminPage from './VerwaltungAdminPage';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';

const andromedaSysSettingsVerwaltungAdminPageRoute = (
  <Route path={URI_ANDROMEDA_SYS_SETTINGS.adminPage} element={<AuthRoute hasPermissionFn={isAdmin} element={<VerwaltungAdminPage />} />} />
);

export default andromedaSysSettingsVerwaltungAdminPageRoute;
