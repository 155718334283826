import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import { URI_VERARBEITUNG_BK_OBJEKT_ABRECHNUNGEN_DETAILS_PAGE } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import BkObjAbrDetailsPage from './BkObjAbrDetailsPage';

export type VerarbeitungBkObjAbrDetailsPageRouteParams = { generierlaufId: string };

const verarbeitungBkObjAbrDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_BK_OBJEKT_ABRECHNUNGEN_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<BkObjAbrDetailsPage />} />}
  />
);

export default verarbeitungBkObjAbrDetailsPageRoute;
