import React from 'react';
import { Layout, Space, Spin, Typography } from 'antd';

const LoadingFullPage: React.FC = () => (
  <Layout>
    <Layout.Content
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Space direction="vertical" align="center">
        <Spin size="large" />
        <Typography.Title level={2}>EasyCloud lädt...</Typography.Title>
      </Space>
    </Layout.Content>
  </Layout>
);

export default LoadingFullPage;
