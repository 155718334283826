import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestartEigenbelegErstellenGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartEigenbelegErstellenGenerierlaufMutation = {
  actionRestartEigenbelegErstellenGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateEigenbelegErstellenGenerierlaufMutationVariables = Types.Exact<{
  input: Types.EigenbelegErstellenGenerierlaufInput;
}>;

export type CreateEigenbelegErstellenGenerierlaufMutation = {
  createEigenbelegErstellenGenerierlauf: {
    data: { generierlaufId: string; generierDatum: string; createTs: string; status: { text: string } };
    warningList: Array<{ message: string; type: string }>;
  };
};

export const RestartEigenbelegErstellenGenerierlaufDocument = gql`
  mutation RestartEigenbelegErstellenGenerierlauf($generierlaufId: ID!) {
    actionRestartEigenbelegErstellenGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartEigenbelegErstellenGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<RestartEigenbelegErstellenGenerierlaufMutation, RestartEigenbelegErstellenGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestartEigenbelegErstellenGenerierlaufMutation, RestartEigenbelegErstellenGenerierlaufMutationVariables>(
    RestartEigenbelegErstellenGenerierlaufDocument,
    options
  );
}
export type RestartEigenbelegErstellenGenerierlaufMutationHookResult = ReturnType<typeof useRestartEigenbelegErstellenGenerierlaufMutation>;
export type RestartEigenbelegErstellenGenerierlaufMutationResult = Apollo.MutationResult<RestartEigenbelegErstellenGenerierlaufMutation>;
export type RestartEigenbelegErstellenGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartEigenbelegErstellenGenerierlaufMutation,
  RestartEigenbelegErstellenGenerierlaufMutationVariables
>;
export const CreateEigenbelegErstellenGenerierlaufDocument = gql`
  mutation CreateEigenbelegErstellenGenerierlauf($input: EigenbelegErstellenGenerierlaufInput!) {
    createEigenbelegErstellenGenerierlauf(input: $input) {
      data {
        generierlaufId
        generierDatum
        createTs
        status {
          text
        }
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreateEigenbelegErstellenGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEigenbelegErstellenGenerierlaufMutation, CreateEigenbelegErstellenGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEigenbelegErstellenGenerierlaufMutation, CreateEigenbelegErstellenGenerierlaufMutationVariables>(
    CreateEigenbelegErstellenGenerierlaufDocument,
    options
  );
}
export type CreateEigenbelegErstellenGenerierlaufMutationHookResult = ReturnType<typeof useCreateEigenbelegErstellenGenerierlaufMutation>;
export type CreateEigenbelegErstellenGenerierlaufMutationResult = Apollo.MutationResult<CreateEigenbelegErstellenGenerierlaufMutation>;
export type CreateEigenbelegErstellenGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  CreateEigenbelegErstellenGenerierlaufMutation,
  CreateEigenbelegErstellenGenerierlaufMutationVariables
>;
