import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  mapFormValuesToQueryParams,
  mapQueryParamsToFormValues,
  ZinslisteListingFiltersFormValues,
} from './Filters/zinslisteListingFiltersFormMapper';
import { useQueryParams } from '../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';
import { TZinslisteQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../hooks/useQueryWithPagingAndSorting';
import { useZinslisteListQuery } from '../gql/ZinslisteQueries.types';
import ZinslisteListingFilters from './Filters/ZinslisteListingFilters';
import ZinslisteCreateBtnAndModal from './Filters/ZinslisteCreateBtnAndModal';
import ZinslisteTable from './Table/ZinslisteTable';

const ZinslisteListingForm = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TZinslisteQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: ZinslisteListingFiltersFormValues) => {
    const filters = mapFormValuesToQueryParams(values);
    updateQueryParams(navigate, filters, paginationParams);
  };

  const { data, refetch, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useZinslisteListQuery,
    { variables: { ...queryParams } },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: paginationParams.currentPage ? Number(paginationParams.currentPage) : DEFAULT_CURRENT,
      pageSize: paginationParams.pageSize ? Number(paginationParams.pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const zinsliste = data?.list.data?.contentList;
  const loading = !zinsliste;

  return (
    <>
      <Formik<ZinslisteListingFiltersFormValues> initialValues={mapQueryParamsToFormValues(queryParams)} onSubmit={onChange}>
        {(formikProps) => (
          <ZinslisteListingFilters
            actionButtons={<ZinslisteCreateBtnAndModal onCreateSuccess={refetch} />}
            formikProps={formikProps}
            queryParams={queryParams}
          />
        )}
      </Formik>
      <ZinslisteTable zinslisteList={zinsliste} loading={loading} pagination={pagination} handleTableSorting={handleTableSorting} />
    </>
  );
};

export default ZinslisteListingForm;
