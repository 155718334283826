import React from 'react';
import { Helmet } from 'react-helmet-async';
import { PageLayout } from '../../components/LayoutAndromeda';
import AbrechnungListingForm from '../../features/Abrechnung/Listing/AbrechnungListingForm';

const AbrechnungPage = () => {
  return (
    <>
      <Helmet>
        <title>Abrechnungen</title>
      </Helmet>
      <PageLayout>
        <AbrechnungListingForm />
      </PageLayout>
    </>
  );
};

export default AbrechnungPage;
