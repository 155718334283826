import React from 'react';
import { Button, Space, Tooltip } from 'antd';
import { ExpandAltOutlined } from '@ant-design/icons';
import { ITimeblock, IVersionable } from '../timelineCtx';
import TimelineCardTitle from './TimelineCardTitle';
import theme from '../../../theme';

type TimelineCardContentProps<T extends IVersionable<T>> = {
  isHistoricized?: boolean;
  timeblock: ITimeblock<T>;
  title?: string | null;
  toggleCollapsed: (id: string) => void;
};

function TimelineCardContent<T extends IVersionable<T>>({ isHistoricized, timeblock, title, toggleCollapsed }: TimelineCardContentProps<T>) {
  return (
    <Space align="center" style={{ width: '100%', justifyContent: 'space-between' }}>
      <TimelineCardTitle isHistoricized={isHistoricized} timeblock={timeblock} titleKommentarPart={title} />
      <Tooltip title="Details einblenden" placement="topRight">
        <Button
          shape="circle"
          type="text"
          icon={<ExpandAltOutlined style={{ color: theme.colors.blue }} />}
          onClick={(e) => {
            e.stopPropagation();
            toggleCollapsed(timeblock.uuid);
          }}
          size="small"
        />
      </Tooltip>
    </Space>
  );
}

export default TimelineCardContent;
