import React, { FC } from 'react';
import { Select } from 'formik-antd';
import { TFormattedDecimal } from '../../../../../helpers/formikHelper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';

type MahnstufeColumnProps = {
  mahnstufe: TFormattedDecimal;
  name: string;
  disabled: boolean;
};

const MahnstufeColumn: FC<MahnstufeColumnProps> = ({ mahnstufe, name, disabled }) => {
  return (
    <FormItemWithoutColon name={name} style={{ margin: 0 }}>
      <Select id={name} name={name} defaultValue={mahnstufe} style={{ minWidth: '120px' }} disabled={disabled}>
        <Select.Option key="1" value={1}>
          Mahnstufe 1
        </Select.Option>
        <Select.Option key="2" value={2}>
          Mahnstufe 2
        </Select.Option>
        <Select.Option key="3" value={3}>
          Mahnstufe 3
        </Select.Option>
        <Select.Option key="4" value={4}>
          Mahnstufe 4
        </Select.Option>
      </Select>
    </FormItemWithoutColon>
  );
};

export default MahnstufeColumn;
