import { InMemoryCache } from '@apollo/client/cache';
import gqlPossibleTypes from './possibleTypes-generated';
import easyCloudOffsetLimitPaginationForRechtstraegerContactList from './easyCloudOffsetLimitPaginationForRechtstraegerContactList';

const apolloCache = new InMemoryCache({
  possibleTypes: gqlPossibleTypes.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        getRechtstraegerContactList: easyCloudOffsetLimitPaginationForRechtstraegerContactList(['kurzBezeichnung']),
      },
    },
  },
});

export default apolloCache;
