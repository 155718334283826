import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AufteilungsschluesselFieldsFragmentDoc } from '../../../Aufteilungsschluessel/gql/AufteilungsschluesselFragments.types';
import { KontoAbrechnungskreisFieldsFragmentDoc } from '../../../Abrechnungsdefinition/gql/AbrDefFragments.types';
import { TextbausteinFieldsFragmentDoc } from '../../../../components/Template/PDFTemplates/gql/TemplateFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSubAdministrationAbrechnungsdefinitionTemplateVersionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  request: Types.SubAdministrationAbrechnungsdefinitionVersionCreateInput;
}>;

export type CreateSubAdministrationAbrechnungsdefinitionTemplateVersionMutation = {
  createSubAdministrationAbrechnungsdefinitionTemplateVersion: {
    data: {
      abrechnungsdefinitionVersionId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      validFrom: string;
      kommentar?: string | null;
      abrechdefAbrechkreisList: Array<{
        abrechnungskreis: { abrechnungskreisId: string };
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vorschreibungsposition?: { vorschreibungspositionId: string } | null;
      }>;
      kontoAbrechnungskreisList: Array<{
        abrechnen: boolean;
        buchungszeilenAnzeigen: boolean;
        expenseReducing: boolean;
        ustVomAufwand: boolean;
        abrechnungskreis?: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
      subAdministrationAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateSubAdministrationAbrechnungsdefinitionTemplateVersionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Types.Scalars['ID']['input'];
  request: Types.SubAdministrationAbrechnungsdefinitionVersionUpdateInput;
}>;

export type UpdateSubAdministrationAbrechnungsdefinitionTemplateVersionMutation = {
  updateSubAdministrationAbrechnungsdefinitionTemplateVersion: {
    data: {
      abrechnungsdefinitionVersionId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      validFrom: string;
      kommentar?: string | null;
      abrechdefAbrechkreisList: Array<{
        abrechnungskreis: { abrechnungskreisId: string };
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vorschreibungsposition?: { vorschreibungspositionId: string } | null;
      }>;
      kontoAbrechnungskreisList: Array<{
        abrechnen: boolean;
        buchungszeilenAnzeigen: boolean;
        expenseReducing: boolean;
        ustVomAufwand: boolean;
        abrechnungskreis?: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
      subAdministrationAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteSubAdministrationAbrechnungsdefinitionTemplateVersionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Types.Scalars['ID']['input'];
}>;

export type DeleteSubAdministrationAbrechnungsdefinitionTemplateVersionMutation = {
  deleteSubAdministrationAbrechnungsdefinitionTemplateVersion: {
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateSubAdministrationAbrechnungsdefinitionTemplateVersionDocument = gql`
  mutation CreateSubAdministrationAbrechnungsdefinitionTemplateVersion(
    $abrechnungsdefinitionId: ID!
    $request: SubAdministrationAbrechnungsdefinitionVersionCreateInput!
  ) {
    createSubAdministrationAbrechnungsdefinitionTemplateVersion(abrechnungsdefinitionId: $abrechnungsdefinitionId, input: $request) {
      data {
        abrechdefAbrechkreisList {
          abrechnungskreis {
            abrechnungskreisId
          }
          aufteilungsschluessel {
            ...AufteilungsschluesselFields
          }
          vorschreibungsposition {
            vorschreibungspositionId
          }
        }
        abrechnungsdefinitionVersionId
        createTs
        createdBy
        createdByMitarbeiterId
        current
        deletable
        validFrom
        kommentar
        kontoAbrechnungskreisList {
          ...KontoAbrechnungskreisFields
        }
        subAdministrationAbrechnungTextbausteinList {
          ...TextbausteinFields
          textbausteinList {
            ...TextbausteinFields
          }
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
  ${KontoAbrechnungskreisFieldsFragmentDoc}
  ${TextbausteinFieldsFragmentDoc}
`;
export function useCreateSubAdministrationAbrechnungsdefinitionTemplateVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSubAdministrationAbrechnungsdefinitionTemplateVersionMutation,
    CreateSubAdministrationAbrechnungsdefinitionTemplateVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSubAdministrationAbrechnungsdefinitionTemplateVersionMutation,
    CreateSubAdministrationAbrechnungsdefinitionTemplateVersionMutationVariables
  >(CreateSubAdministrationAbrechnungsdefinitionTemplateVersionDocument, options);
}
export type CreateSubAdministrationAbrechnungsdefinitionTemplateVersionMutationHookResult = ReturnType<
  typeof useCreateSubAdministrationAbrechnungsdefinitionTemplateVersionMutation
>;
export type CreateSubAdministrationAbrechnungsdefinitionTemplateVersionMutationResult =
  Apollo.MutationResult<CreateSubAdministrationAbrechnungsdefinitionTemplateVersionMutation>;
export type CreateSubAdministrationAbrechnungsdefinitionTemplateVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateSubAdministrationAbrechnungsdefinitionTemplateVersionMutation,
  CreateSubAdministrationAbrechnungsdefinitionTemplateVersionMutationVariables
>;
export const UpdateSubAdministrationAbrechnungsdefinitionTemplateVersionDocument = gql`
  mutation UpdateSubAdministrationAbrechnungsdefinitionTemplateVersion(
    $abrechnungsdefinitionId: ID!
    $abrechnungsdefinitionVersionId: ID!
    $request: SubAdministrationAbrechnungsdefinitionVersionUpdateInput!
  ) {
    updateSubAdministrationAbrechnungsdefinitionTemplateVersion(
      abrechnungsdefinitionId: $abrechnungsdefinitionId
      abrechnungsdefinitionVersionId: $abrechnungsdefinitionVersionId
      input: $request
    ) {
      data {
        abrechdefAbrechkreisList {
          abrechnungskreis {
            abrechnungskreisId
          }
          aufteilungsschluessel {
            ...AufteilungsschluesselFields
          }
          vorschreibungsposition {
            vorschreibungspositionId
          }
        }
        abrechnungsdefinitionVersionId
        createTs
        createdBy
        createdByMitarbeiterId
        current
        deletable
        validFrom
        kommentar
        kontoAbrechnungskreisList {
          ...KontoAbrechnungskreisFields
        }
        subAdministrationAbrechnungTextbausteinList {
          ...TextbausteinFields
          textbausteinList {
            ...TextbausteinFields
          }
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
  ${KontoAbrechnungskreisFieldsFragmentDoc}
  ${TextbausteinFieldsFragmentDoc}
`;
export function useUpdateSubAdministrationAbrechnungsdefinitionTemplateVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSubAdministrationAbrechnungsdefinitionTemplateVersionMutation,
    UpdateSubAdministrationAbrechnungsdefinitionTemplateVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSubAdministrationAbrechnungsdefinitionTemplateVersionMutation,
    UpdateSubAdministrationAbrechnungsdefinitionTemplateVersionMutationVariables
  >(UpdateSubAdministrationAbrechnungsdefinitionTemplateVersionDocument, options);
}
export type UpdateSubAdministrationAbrechnungsdefinitionTemplateVersionMutationHookResult = ReturnType<
  typeof useUpdateSubAdministrationAbrechnungsdefinitionTemplateVersionMutation
>;
export type UpdateSubAdministrationAbrechnungsdefinitionTemplateVersionMutationResult =
  Apollo.MutationResult<UpdateSubAdministrationAbrechnungsdefinitionTemplateVersionMutation>;
export type UpdateSubAdministrationAbrechnungsdefinitionTemplateVersionMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubAdministrationAbrechnungsdefinitionTemplateVersionMutation,
  UpdateSubAdministrationAbrechnungsdefinitionTemplateVersionMutationVariables
>;
export const DeleteSubAdministrationAbrechnungsdefinitionTemplateVersionDocument = gql`
  mutation DeleteSubAdministrationAbrechnungsdefinitionTemplateVersion($abrechnungsdefinitionId: ID!, $abrechnungsdefinitionVersionId: ID!) {
    deleteSubAdministrationAbrechnungsdefinitionTemplateVersion(
      abrechnungsdefinitionId: $abrechnungsdefinitionId
      abrechnungsdefinitionVersionId: $abrechnungsdefinitionVersionId
    ) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteSubAdministrationAbrechnungsdefinitionTemplateVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSubAdministrationAbrechnungsdefinitionTemplateVersionMutation,
    DeleteSubAdministrationAbrechnungsdefinitionTemplateVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSubAdministrationAbrechnungsdefinitionTemplateVersionMutation,
    DeleteSubAdministrationAbrechnungsdefinitionTemplateVersionMutationVariables
  >(DeleteSubAdministrationAbrechnungsdefinitionTemplateVersionDocument, options);
}
export type DeleteSubAdministrationAbrechnungsdefinitionTemplateVersionMutationHookResult = ReturnType<
  typeof useDeleteSubAdministrationAbrechnungsdefinitionTemplateVersionMutation
>;
export type DeleteSubAdministrationAbrechnungsdefinitionTemplateVersionMutationResult =
  Apollo.MutationResult<DeleteSubAdministrationAbrechnungsdefinitionTemplateVersionMutation>;
export type DeleteSubAdministrationAbrechnungsdefinitionTemplateVersionMutationOptions = Apollo.BaseMutationOptions<
  DeleteSubAdministrationAbrechnungsdefinitionTemplateVersionMutation,
  DeleteSubAdministrationAbrechnungsdefinitionTemplateVersionMutationVariables
>;
