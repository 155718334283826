import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import menuListBkTopAbr from './menuListBkTopAbr';
import { VerarbeitungBkTopAbrDetailsPageRouteParams } from './routes';
import { useTopAbrechnungGenerierlaufQuery } from '../../../features/Verarbeitung/gql/TopAbrechnungGenerierlauf/TopAbrGenerierlaufQueries.types';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import { TopAbrechnungGenerierlauf } from '../../../types';
import { useRestartTopAbrechnungGenerierlaufMutation } from '../../../features/Verarbeitung/gql/TopAbrechnungGenerierlauf/TopAbrGenerierlaufMutations.types';

const BkTopAbrDetailsPage = () => {
  const { generierlaufId } = useParams() as VerarbeitungBkTopAbrDetailsPageRouteParams;

  const { data, refetch } = useTopAbrechnungGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getTopAbrechnungGenerierlauf.data;

  const [runRestart] = useRestartTopAbrechnungGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<TopAbrechnungGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.BkTopAbrechnung}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.BkTopAbrechnung}
          onGenerierlaufRestart={runRestart}
        />
      )}
      sidebarMenuList={(generierlauf) => menuListBkTopAbr(generierlauf.generierlaufId, generierlauf)}
    />
  );
};

export default BkTopAbrDetailsPage;
