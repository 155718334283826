import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../../styles/pdfStyles';
import { beSummaryColumnWidthsAndAlignment } from '../objekt-block-tables-styles';
import { VVPosSummary } from '../../../vorschreibung-types';

const VTBestandseinheitSummary: FC<{ data: VVPosSummary }> = ({ data }) => (
  <View style={[pdfStyles.row, { fontWeight: 'bold' }]}>
    <Text
      style={[
        pdfStyles.textBulletin,
        {
          width: beSummaryColumnWidthsAndAlignment.summeText.width,
          textAlign: beSummaryColumnWidthsAndAlignment.summeText.textAlign,
        },
      ]}
    >
      {data.summeText}
    </Text>
    <Text
      style={[
        pdfStyles.textBulletin,
        {
          width: beSummaryColumnWidthsAndAlignment.netto.width,
          textAlign: beSummaryColumnWidthsAndAlignment.netto.textAlign,
        },
      ]}
    >
      {data.netto}
    </Text>
    <Text
      style={[
        pdfStyles.textBulletin,
        {
          width: beSummaryColumnWidthsAndAlignment.ustBetrag.width,
          textAlign: beSummaryColumnWidthsAndAlignment.ustBetrag.textAlign,
        },
      ]}
    >
      {data.ustBetrag}
    </Text>
    <Text
      style={[
        pdfStyles.textBulletin,
        {
          width: beSummaryColumnWidthsAndAlignment.brutto.width,
          textAlign: beSummaryColumnWidthsAndAlignment.brutto.textAlign,
        },
      ]}
    >
      {data.brutto}
    </Text>
  </View>
);

export default VTBestandseinheitSummary;
