import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestartAuftragVerbuchenGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartAuftragVerbuchenGenerierlaufMutation = {
  actionRestartAuftragVerbuchenGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ type: string; message: string }>;
    errorList: Array<{ type: string; message: string }>;
  };
};

export type CreateAuftragVerbuchenGenerierlaufMutationVariables = Types.Exact<{
  input: Types.AuftragVerbuchenGenerierlaufInput;
}>;

export type CreateAuftragVerbuchenGenerierlaufMutation = {
  createAuftragVerbuchenGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ type: string; message: string }>;
    errorList: Array<{ type: string; message: string }>;
  };
};

export const RestartAuftragVerbuchenGenerierlaufDocument = gql`
  mutation RestartAuftragVerbuchenGenerierlauf($generierlaufId: ID!) {
    actionRestartAuftragVerbuchenGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        type
        message
      }
      errorList {
        type
        message
      }
    }
  }
`;
export function useRestartAuftragVerbuchenGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<RestartAuftragVerbuchenGenerierlaufMutation, RestartAuftragVerbuchenGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestartAuftragVerbuchenGenerierlaufMutation, RestartAuftragVerbuchenGenerierlaufMutationVariables>(
    RestartAuftragVerbuchenGenerierlaufDocument,
    options
  );
}
export type RestartAuftragVerbuchenGenerierlaufMutationHookResult = ReturnType<typeof useRestartAuftragVerbuchenGenerierlaufMutation>;
export type RestartAuftragVerbuchenGenerierlaufMutationResult = Apollo.MutationResult<RestartAuftragVerbuchenGenerierlaufMutation>;
export type RestartAuftragVerbuchenGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartAuftragVerbuchenGenerierlaufMutation,
  RestartAuftragVerbuchenGenerierlaufMutationVariables
>;
export const CreateAuftragVerbuchenGenerierlaufDocument = gql`
  mutation CreateAuftragVerbuchenGenerierlauf($input: AuftragVerbuchenGenerierlaufInput!) {
    createAuftragVerbuchenGenerierlauf(input: $input) {
      data {
        generierlaufId
      }
      warningList {
        type
        message
      }
      errorList {
        type
        message
      }
    }
  }
`;
export function useCreateAuftragVerbuchenGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAuftragVerbuchenGenerierlaufMutation, CreateAuftragVerbuchenGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAuftragVerbuchenGenerierlaufMutation, CreateAuftragVerbuchenGenerierlaufMutationVariables>(
    CreateAuftragVerbuchenGenerierlaufDocument,
    options
  );
}
export type CreateAuftragVerbuchenGenerierlaufMutationHookResult = ReturnType<typeof useCreateAuftragVerbuchenGenerierlaufMutation>;
export type CreateAuftragVerbuchenGenerierlaufMutationResult = Apollo.MutationResult<CreateAuftragVerbuchenGenerierlaufMutation>;
export type CreateAuftragVerbuchenGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  CreateAuftragVerbuchenGenerierlaufMutation,
  CreateAuftragVerbuchenGenerierlaufMutationVariables
>;
