import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import menuListBkObjAbr from './menuListBkObjAbr';
import { VerarbeitungBkObjAbrDetailsPageRouteParams } from './routes';
import { useObjektAbrechnungGenerierlaufQuery } from '../../../features/Verarbeitung/gql/ObjektAbrechnungGenerierlauf/ObjAbrGenerierlaufQueries.types';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import { ObjektAbrechnungGenerierlauf } from '../../../types';
import { useRestartObjektAbrechnungGenerierlaufMutation } from '../../../features/Verarbeitung/gql/ObjektAbrechnungGenerierlauf/ObjAbrGenerierlaufMutations.types';

const BkObjAbrDetailsPage = () => {
  const { generierlaufId } = useParams() as VerarbeitungBkObjAbrDetailsPageRouteParams;

  const { data, refetch } = useObjektAbrechnungGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getObjektAbrechnungGenerierlauf.data;

  const [runRestart] = useRestartObjektAbrechnungGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<ObjektAbrechnungGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.BkObjektAbrechnung}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.BkObjektAbrechnung}
          onGenerierlaufRestart={runRestart}
        />
      )}
      sidebarMenuList={(generierlauf) => menuListBkObjAbr(generierlauf.generierlaufId, generierlauf)}
    />
  );
};

export default BkObjAbrDetailsPage;
