import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToggle } from './useToggle';
import { mapToQueryString, QUERY_PARAM_KEY, useQueryParams } from './useStringQueryParams';

/**
 * Future potential:
 * We could implement it a generic way. Fn could return all search params provided by a generic type and
 * it could return state and setter tuples for the search params with the right types.
 *
 * Usage would be sg like this:
 * const {[showArchived, setShowArchived], [entityId: setEntityId]} = useQueryParamStates<{showArchived: boolean, entityId: string}>()
 * (I'm not sure about the returned tuples)
 */
export const useShowArchivedQueryParamState = (): ReturnType<typeof useToggle> => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { showArchived: showArchivedQueryParam, ...restQueryParams } = useQueryParams<{ [QUERY_PARAM_KEY.SHOW_ARCHIVED]: boolean }>();
  const [showArchived, toggleShowArchived] = useToggle(showArchivedQueryParam);

  // Set initial query params to the state in the URI
  useEffect(() => {
    navigate(`${pathname}?${mapToQueryString({ [QUERY_PARAM_KEY.SHOW_ARCHIVED]: showArchived, ...restQueryParams })}`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Apply URI query param value to toggle state if it differs from the current state
  useEffect(() => {
    if (typeof showArchivedQueryParam === 'undefined' || showArchivedQueryParam === showArchived) return;
    toggleShowArchived(showArchivedQueryParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showArchivedQueryParam]);

  // Update history stack if the state changes
  useEffect(() => {
    // Skip pushing if the current query params are the same as the current state (it would clutter the history stack), or if the showArchivedQueryParam is undefined (updated at the initial effect)
    const queryString = mapToQueryString({ [QUERY_PARAM_KEY.SHOW_ARCHIVED]: showArchived, ...restQueryParams });
    if (typeof showArchivedQueryParam === 'undefined' || queryString === search.split('?')[1]) return;
    navigate(`${pathname}?${queryString}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showArchived]);

  return [showArchived, toggleShowArchived];
};
