import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = {
  objektList: VerarbeitungObjektFormSelectProps[];
  width?: string;
  maxTextLength?: number;
  rechnungsausstellerId?: string | null;
} & SelectProps;

export interface VerarbeitungObjektFormSelectProps {
  objektId: string;
  kurzBezeichnung: string;
  rechnungsausstellerId?: string | null;
}

const VerarbeitungObjektFormSelect: FC<Props> = ({ objektList, name, width, maxTextLength, rechnungsausstellerId, ...restProps }) => (
  <Select
    size="small"
    name={name}
    placeholder="Objekt auswählen"
    showSearch
    allowClear
    filterOption={selectFilterOption}
    style={{ width: width || '100%' }}
    {...restProps}
  >
    {objektList
      .filter((obj) => (rechnungsausstellerId ? obj.rechnungsausstellerId === rechnungsausstellerId : true))
      .map((objekt) => (
        <Select.Option key={objekt.objektId} value={objekt.objektId}>
          <DataWithShortenedText maxTextLength={maxTextLength ?? 20} text={objekt.kurzBezeichnung} />
        </Select.Option>
      ))}
  </Select>
);

export default VerarbeitungObjektFormSelect;
