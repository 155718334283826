import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateFirmendatenMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  request: Types.FirmendatenUpdateInput;
}>;

export type UpdateFirmendatenMutation = {
  updateFirmendaten: {
    data: { createTs: string; createdBy: string; createdByMitarbeiterId?: string | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SperrenFirmendatenMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
}>;

export type SperrenFirmendatenMutation = {
  actionSperrenFirmendaten: {
    data: { createTs: string; createdBy: string; createdByMitarbeiterId?: string | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type EntsperrenFirmendatenMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
}>;

export type EntsperrenFirmendatenMutation = {
  actionEntsperrenFirmendaten: {
    data: { createTs: string; createdBy: string; createdByMitarbeiterId?: string | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteFirmendatenMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
}>;

export type DeleteFirmendatenMutation = {
  deleteFirmendaten: {
    data: { createTs: string; createdBy: string; createdByMitarbeiterId?: string | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type RestoreDefaultFirmeneinstellungMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
}>;

export type RestoreDefaultFirmeneinstellungMutation = {
  actionRestoreDefaultFirmeneinstellung: {
    data: { createTs: string; createdBy: string; createdByMitarbeiterId?: string | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateFirmeneinstellungMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  request: Types.UpdateFirmendateneinstellungenInput;
}>;

export type UpdateFirmeneinstellungMutation = {
  updateFirmeneinstellung: {
    data: { createTs: string; createdBy: string; createdByMitarbeiterId?: string | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const UpdateFirmendatenDocument = gql`
  mutation UpdateFirmendaten($firmendatenId: ID!, $request: FirmendatenUpdateInput!) {
    updateFirmendaten(firmendatenId: $firmendatenId, request: $request) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateFirmendatenMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFirmendatenMutation, UpdateFirmendatenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFirmendatenMutation, UpdateFirmendatenMutationVariables>(UpdateFirmendatenDocument, options);
}
export type UpdateFirmendatenMutationHookResult = ReturnType<typeof useUpdateFirmendatenMutation>;
export type UpdateFirmendatenMutationResult = Apollo.MutationResult<UpdateFirmendatenMutation>;
export type UpdateFirmendatenMutationOptions = Apollo.BaseMutationOptions<UpdateFirmendatenMutation, UpdateFirmendatenMutationVariables>;
export const SperrenFirmendatenDocument = gql`
  mutation SperrenFirmendaten($firmendatenId: ID!) {
    actionSperrenFirmendaten(firmendatenId: $firmendatenId) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSperrenFirmendatenMutation(
  baseOptions?: Apollo.MutationHookOptions<SperrenFirmendatenMutation, SperrenFirmendatenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SperrenFirmendatenMutation, SperrenFirmendatenMutationVariables>(SperrenFirmendatenDocument, options);
}
export type SperrenFirmendatenMutationHookResult = ReturnType<typeof useSperrenFirmendatenMutation>;
export type SperrenFirmendatenMutationResult = Apollo.MutationResult<SperrenFirmendatenMutation>;
export type SperrenFirmendatenMutationOptions = Apollo.BaseMutationOptions<SperrenFirmendatenMutation, SperrenFirmendatenMutationVariables>;
export const EntsperrenFirmendatenDocument = gql`
  mutation EntsperrenFirmendaten($firmendatenId: ID!) {
    actionEntsperrenFirmendaten(firmendatenId: $firmendatenId) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useEntsperrenFirmendatenMutation(
  baseOptions?: Apollo.MutationHookOptions<EntsperrenFirmendatenMutation, EntsperrenFirmendatenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EntsperrenFirmendatenMutation, EntsperrenFirmendatenMutationVariables>(EntsperrenFirmendatenDocument, options);
}
export type EntsperrenFirmendatenMutationHookResult = ReturnType<typeof useEntsperrenFirmendatenMutation>;
export type EntsperrenFirmendatenMutationResult = Apollo.MutationResult<EntsperrenFirmendatenMutation>;
export type EntsperrenFirmendatenMutationOptions = Apollo.BaseMutationOptions<EntsperrenFirmendatenMutation, EntsperrenFirmendatenMutationVariables>;
export const DeleteFirmendatenDocument = gql`
  mutation DeleteFirmendaten($firmendatenId: ID!) {
    deleteFirmendaten(firmendatenId: $firmendatenId) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteFirmendatenMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFirmendatenMutation, DeleteFirmendatenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFirmendatenMutation, DeleteFirmendatenMutationVariables>(DeleteFirmendatenDocument, options);
}
export type DeleteFirmendatenMutationHookResult = ReturnType<typeof useDeleteFirmendatenMutation>;
export type DeleteFirmendatenMutationResult = Apollo.MutationResult<DeleteFirmendatenMutation>;
export type DeleteFirmendatenMutationOptions = Apollo.BaseMutationOptions<DeleteFirmendatenMutation, DeleteFirmendatenMutationVariables>;
export const RestoreDefaultFirmeneinstellungDocument = gql`
  mutation RestoreDefaultFirmeneinstellung($firmendatenId: ID!) {
    actionRestoreDefaultFirmeneinstellung(firmendatenId: $firmendatenId) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestoreDefaultFirmeneinstellungMutation(
  baseOptions?: Apollo.MutationHookOptions<RestoreDefaultFirmeneinstellungMutation, RestoreDefaultFirmeneinstellungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestoreDefaultFirmeneinstellungMutation, RestoreDefaultFirmeneinstellungMutationVariables>(
    RestoreDefaultFirmeneinstellungDocument,
    options
  );
}
export type RestoreDefaultFirmeneinstellungMutationHookResult = ReturnType<typeof useRestoreDefaultFirmeneinstellungMutation>;
export type RestoreDefaultFirmeneinstellungMutationResult = Apollo.MutationResult<RestoreDefaultFirmeneinstellungMutation>;
export type RestoreDefaultFirmeneinstellungMutationOptions = Apollo.BaseMutationOptions<
  RestoreDefaultFirmeneinstellungMutation,
  RestoreDefaultFirmeneinstellungMutationVariables
>;
export const UpdateFirmeneinstellungDocument = gql`
  mutation UpdateFirmeneinstellung($firmendatenId: ID!, $request: UpdateFirmendateneinstellungenInput!) {
    updateFirmeneinstellung(firmendatenId: $firmendatenId, request: $request) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateFirmeneinstellungMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFirmeneinstellungMutation, UpdateFirmeneinstellungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFirmeneinstellungMutation, UpdateFirmeneinstellungMutationVariables>(UpdateFirmeneinstellungDocument, options);
}
export type UpdateFirmeneinstellungMutationHookResult = ReturnType<typeof useUpdateFirmeneinstellungMutation>;
export type UpdateFirmeneinstellungMutationResult = Apollo.MutationResult<UpdateFirmeneinstellungMutation>;
export type UpdateFirmeneinstellungMutationOptions = Apollo.BaseMutationOptions<
  UpdateFirmeneinstellungMutation,
  UpdateFirmeneinstellungMutationVariables
>;
