import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { EbicsUserFieldsFragmentDoc } from '../../../PaymentTransaction/EbicsUser/gql/EbicsUserFragments.types';
import { GenerierlaufAsyncStepFieldsFragmentDoc } from '../shared/generierlaufFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DataCarrierPainCreationGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type DataCarrierPainCreationGenerierlaufQuery = {
  getDataCarrierPainCreationGenerierlauf: {
    data: {
      countFehler: number;
      countNichtVerarbeitet: number;
      countVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierDatum: string;
      generierlaufId: string;
      errorList: Array<{ message: string; type: string }>;
      status: { description?: string | null; text: string; value: Types.GenerierlaufStatus };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DataCarrierPainCreationGenerierlaufEntryListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  dataCarrierType?: Types.InputMaybe<Types.DataCarrierType>;
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  paymentTransactionType?: Types.InputMaybe<Types.PaymentTransactionType>;
  iban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  order?: Types.InputMaybe<Types.DataCarrierPainCreationGenerierlaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type DataCarrierPainCreationGenerierlaufEntryListQuery = {
  getDataCarrierPainCreationGenerierlaufEntryList: {
    data: {
      contentList: Array<{
        createTs: string;
        createdByMitarbeiterId: string;
        currentStep?: string | null;
        generierlaufEntryId: string;
        infoMessage?: string | null;
        retry: boolean;
        stepCount?: number | null;
        dataCarrier: {
          countTransactionInstructions: number;
          dataCarrierId: string;
          ibanList: Array<string>;
          name: string;
          paymentProposalName: string;
          sumTransactionInstruction: number;
          xmlFilename: string;
          dataCarrierType: { value: Types.DataCarrierType; text: string };
          paymentTransactionType: { value: Types.PaymentTransactionType; text: string };
          status: { text: string; value: Types.DataCarrierPainStatus };
        };
        ebicsUser?: {
          bankname: string;
          camtImportEnabled: boolean;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable: boolean;
          ebicsBankConfigId: string;
          ebicsUserId: string;
          editable: boolean;
          initializationDocumentFileId?: string | null;
          mitarbeiterId: string;
          mitarbeiterName: string;
          name?: string | null;
          partnerId: string;
          password: string;
          userId: string;
          orderTypeList: Array<{ description?: string | null; text: string; value: string }>;
          status: { text: string; value: Types.EbicsUserStatus };
        } | null;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { text: string; value: Types.GenerierlaufEntryExitCode } | null;
        stepList: Array<{
          designation: string;
          receivingApplication?: string | null;
          stepId: string;
          type?: string | null;
          status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
        }>;
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { size: number; number: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const DataCarrierPainCreationGenerierlaufDocument = gql`
  query DataCarrierPainCreationGenerierlauf($generierlaufId: ID!) {
    getDataCarrierPainCreationGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countNichtVerarbeitet
        countVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        generierDatum
        generierlaufId
        status {
          description
          text
          value
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDataCarrierPainCreationGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<DataCarrierPainCreationGenerierlaufQuery, DataCarrierPainCreationGenerierlaufQueryVariables> &
    ({ variables: DataCarrierPainCreationGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DataCarrierPainCreationGenerierlaufQuery, DataCarrierPainCreationGenerierlaufQueryVariables>(
    DataCarrierPainCreationGenerierlaufDocument,
    options
  );
}
export function useDataCarrierPainCreationGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DataCarrierPainCreationGenerierlaufQuery, DataCarrierPainCreationGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DataCarrierPainCreationGenerierlaufQuery, DataCarrierPainCreationGenerierlaufQueryVariables>(
    DataCarrierPainCreationGenerierlaufDocument,
    options
  );
}
export function useDataCarrierPainCreationGenerierlaufSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<DataCarrierPainCreationGenerierlaufQuery, DataCarrierPainCreationGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DataCarrierPainCreationGenerierlaufQuery, DataCarrierPainCreationGenerierlaufQueryVariables>(
    DataCarrierPainCreationGenerierlaufDocument,
    options
  );
}
export type DataCarrierPainCreationGenerierlaufQueryHookResult = ReturnType<typeof useDataCarrierPainCreationGenerierlaufQuery>;
export type DataCarrierPainCreationGenerierlaufLazyQueryHookResult = ReturnType<typeof useDataCarrierPainCreationGenerierlaufLazyQuery>;
export type DataCarrierPainCreationGenerierlaufSuspenseQueryHookResult = ReturnType<typeof useDataCarrierPainCreationGenerierlaufSuspenseQuery>;
export type DataCarrierPainCreationGenerierlaufQueryResult = Apollo.QueryResult<
  DataCarrierPainCreationGenerierlaufQuery,
  DataCarrierPainCreationGenerierlaufQueryVariables
>;
export const DataCarrierPainCreationGenerierlaufEntryListDocument = gql`
  query DataCarrierPainCreationGenerierlaufEntryList(
    $generierlaufId: ID!
    $dataCarrierType: DataCarrierType
    $name: String
    $paymentTransactionType: PaymentTransactionType
    $iban: String
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $order: DataCarrierPainCreationGenerierlaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getDataCarrierPainCreationGenerierlaufEntryList(
      generierlaufId: $generierlaufId
      dataCarrierType: $dataCarrierType
      name: $name
      paymentTransactionType: $paymentTransactionType
      iban: $iban
      exitCodeList: $exitCodeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          createTs
          createdByMitarbeiterId
          currentStep
          dataCarrier {
            countTransactionInstructions
            dataCarrierId
            dataCarrierType {
              value
              text
            }
            ibanList
            name
            paymentProposalName
            paymentTransactionType {
              value
              text
            }
            status {
              text
              value
            }
            sumTransactionInstruction
            xmlFilename
          }
          ebicsUser {
            ...EbicsUserFields
          }
          errorList {
            message
            type
          }
          exitCode {
            text
            value
          }
          generierlaufEntryId
          infoMessage
          retry
          stepCount
          stepList {
            ...GenerierlaufAsyncStepFields
          }
          warningList {
            message
            type
          }
        }
        page {
          size
          number
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${EbicsUserFieldsFragmentDoc}
  ${GenerierlaufAsyncStepFieldsFragmentDoc}
`;
export function useDataCarrierPainCreationGenerierlaufEntryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    DataCarrierPainCreationGenerierlaufEntryListQuery,
    DataCarrierPainCreationGenerierlaufEntryListQueryVariables
  > &
    ({ variables: DataCarrierPainCreationGenerierlaufEntryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DataCarrierPainCreationGenerierlaufEntryListQuery, DataCarrierPainCreationGenerierlaufEntryListQueryVariables>(
    DataCarrierPainCreationGenerierlaufEntryListDocument,
    options
  );
}
export function useDataCarrierPainCreationGenerierlaufEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DataCarrierPainCreationGenerierlaufEntryListQuery,
    DataCarrierPainCreationGenerierlaufEntryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DataCarrierPainCreationGenerierlaufEntryListQuery, DataCarrierPainCreationGenerierlaufEntryListQueryVariables>(
    DataCarrierPainCreationGenerierlaufEntryListDocument,
    options
  );
}
export function useDataCarrierPainCreationGenerierlaufEntryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<DataCarrierPainCreationGenerierlaufEntryListQuery, DataCarrierPainCreationGenerierlaufEntryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DataCarrierPainCreationGenerierlaufEntryListQuery, DataCarrierPainCreationGenerierlaufEntryListQueryVariables>(
    DataCarrierPainCreationGenerierlaufEntryListDocument,
    options
  );
}
export type DataCarrierPainCreationGenerierlaufEntryListQueryHookResult = ReturnType<typeof useDataCarrierPainCreationGenerierlaufEntryListQuery>;
export type DataCarrierPainCreationGenerierlaufEntryListLazyQueryHookResult = ReturnType<
  typeof useDataCarrierPainCreationGenerierlaufEntryListLazyQuery
>;
export type DataCarrierPainCreationGenerierlaufEntryListSuspenseQueryHookResult = ReturnType<
  typeof useDataCarrierPainCreationGenerierlaufEntryListSuspenseQuery
>;
export type DataCarrierPainCreationGenerierlaufEntryListQueryResult = Apollo.QueryResult<
  DataCarrierPainCreationGenerierlaufEntryListQuery,
  DataCarrierPainCreationGenerierlaufEntryListQueryVariables
>;
