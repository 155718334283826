import * as Types from '../../../../types';

import { gql } from '@apollo/client';
export type TextbausteinFieldsFragment = {
  index: number;
  value?: string | null;
  platzhalterList: Array<{ example: string; platzhalter: string }>;
  textbausteinList: Array<{
    index: number;
    value?: string | null;
    platzhalterList: Array<{ example: string; platzhalter: string }>;
    type: { text: string; value: Types.TextbausteinType };
  }>;
  type: { text: string; value: Types.TextbausteinType };
};

export const TextbausteinFieldsFragmentDoc = gql`
  fragment TextbausteinFields on Textbaustein {
    index
    platzhalterList {
      example
      platzhalter
    }
    textbausteinList {
      index
      platzhalterList {
        example
        platzhalter
      }
      type {
        text
        value
      }
      value
    }
    type {
      text
      value
    }
    value
  }
`;
