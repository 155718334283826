import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useRechtstraegerBankDetailsListQuery } from '../../../../../BankDetails/gql/BankDetailsQueries.types';
import { BankDetailsFibuVerwendung } from '../../../../../../types';
import KontoWithNonRepFondsVerwendungBadge from './KontoWithNonRepFondsVerwendungBadge';
import { BankDetailsFieldsFragment } from '../../../../../BankDetails/gql/BankDetailsFragments.types';

type Props = {
  rechtstraegerId: string;
  excludedFibuBankAccountIds?: string[];
  assignedFibuKontoId?: string;
} & SelectProps;

const RechnungsAusstellerFibuBankAccountSelect: FC<Props> = ({ rechtstraegerId, excludedFibuBankAccountIds, assignedFibuKontoId, ...restProps }) => {
  const { loading, data: maybeBankDetailsListData } = useRechtstraegerBankDetailsListQuery({
    variables: { rechtstraegerId },
  });

  const bankDetailsList = maybeBankDetailsListData?.getRechtstraegerBankDetailsList.data ?? [];
  const fibuBankAccountList = bankDetailsList
    .filter((bankDetails) => isRepFondOrCurrentlyAssigned(bankDetails, assignedFibuKontoId))
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .filter((bankDetails) => !excludedFibuBankAccountIds?.includes(bankDetails.fibuKontoId!));

  return (
    <Select loading={loading} placeholder="Bankverbindung auswählen" {...restProps}>
      {fibuBankAccountList.map((bankDetails) => (
        <Select.Option key={bankDetails.fibuKontoId} value={bankDetails.fibuKontoId}>
          <KontoWithNonRepFondsVerwendungBadge
            kontoBezeichnung={bankDetails.bankname}
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
            kontoNummer={bankDetails.fibuKontonummer!}
            verwendung={bankDetails.fibuVerwendung}
            isInSelect
            maxBankNameLength={30}
          />
        </Select.Option>
      ))}
    </Select>
  );
};

const isRepFondOrCurrentlyAssigned = (bankDetails: BankDetailsFieldsFragment, assignedFibuKontoId?: string) => {
  return isRepFond(bankDetails) || bankDetails.fibuKontoId === assignedFibuKontoId;
};

const isRepFond = (bankDetails: BankDetailsFieldsFragment) => bankDetails.fibuVerwendung.value === BankDetailsFibuVerwendung.RepFonds;

export default RechnungsAusstellerFibuBankAccountSelect;
