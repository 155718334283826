import React, { FC } from 'react';
import { Drawer } from 'antd';
import { RechtstraegerType } from '../../../types';
import RechtsTraegerCreateForm from './RechtsTraegerCreateForm';

type Props = {
  onSuccess: (rechtstraegerId: string, kurzBezeichnung: string, type: { value: RechtstraegerType }) => void;
  isCollapsed: boolean;
  onCollapse: () => void;
  typeList?: RechtstraegerType[];
};

const RechtsTraegerCreateDrawer: FC<Props> = ({ onSuccess, isCollapsed, onCollapse, typeList }) => {
  return (
    <Drawer title="Firma / Person anlegen" width={720} open={isCollapsed} onClose={onCollapse} destroyOnClose>
      <RechtsTraegerCreateForm onSuccess={onSuccess} onCollapse={onCollapse} typeList={typeList} />
    </Drawer>
  );
};

export default RechtsTraegerCreateDrawer;
