import React, { FC } from 'react';
import styled from 'styled-components';
import { topAbrDefGeneralSettingsFormFields } from '../../topAbrDefGeneralSettingsFormMapper';
import FormattedDecimalFormInput from '../../../../../../../../../components/Input-antd/FormattedDecimalFormInput';
import { isNotNil } from '../../../../../../../../../helpers/assertionHelper';
import FormItemWithFieldHelp from '../../../../../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  name: string;
  isDisabled: boolean;
  onChange: (rueckstandOtherPersonsThreshold: number | null) => void;
  fieldHelp?: string | null;
};
const RueckstandOtherPersonsThresholdFormItem: FC<Props> = ({ name, isDisabled, onChange, fieldHelp }) => (
  <StyledFormItem name={name} label="Mindestbetrag" fieldHelp={fieldHelp}>
    <FormattedDecimalFormInput
      name={topAbrDefGeneralSettingsFormFields.rueckstandOtherPersonsThreshold}
      placeholder="Mindestbetrag"
      size="small"
      style={{ width: '200px' }}
      min={0}
      controls={false}
      onChange={(value) => {
        onChange(isNotNil(value) ? (value as number) : null);
      }}
      disabled={isDisabled}
      isCurrency
    />
  </StyledFormItem>
);

export const StyledFormItem = styled(FormItemWithFieldHelp)`
  width: 100%;
  .ant-col:first-child {
    width: 33%;
    text-align: left;
    padding-left: 14px;
  }
`;
export default RueckstandOtherPersonsThresholdFormItem;
