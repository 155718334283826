import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { PositionListHeader } from '../../../hauseigentuemerAbrechnung-types';
import pdfStyles from '../../../../styles/pdfStyles';
import { columnWidthsAndAlignment } from './details-table-styles';

const DetailsColumnsTitles: FC<{ titles: PositionListHeader }> = ({ titles }) => (
  <View
    style={[
      pdfStyles.row,
      pdfStyles.textNormal,
      pdfStyles.borderBottomSmall,
      {
        paddingTop: '10mm',
        fontWeight: 'bold',
      },
    ]}
  >
    <Text
      style={[
        pdfStyles.textNormal,
        { width: columnWidthsAndAlignment.positionText.width, textAlign: columnWidthsAndAlignment.positionText.textAlign },
      ]}
    >
      {titles.positionText}
    </Text>
    <Text
      style={[
        pdfStyles.textNormal,
        { width: columnWidthsAndAlignment.objektsummeText.width, textAlign: columnWidthsAndAlignment.objektsummeText.textAlign },
      ]}
    >
      {titles.objektsummeText}
    </Text>
    <Text
      style={[pdfStyles.textNormal, { width: columnWidthsAndAlignment.anteilText.width, textAlign: columnWidthsAndAlignment.anteilText.textAlign }]}
    >
      {titles.anteilText}
    </Text>
  </View>
);

export default DetailsColumnsTitles;
