import React, { FC, useEffect } from 'react';
import { useField } from 'formik';
import { SelectProps } from 'formik-antd';
import {
  useVorschreibungspositionListForSelectByFirmendatenIdQuery,
  useVorschreibungspositionListForSelectByKundenSystemIdQuery,
} from './gql/VorschreibungspositionQueries.types';
import { useFDAuthorized } from '../../shared/Auth/useAuthorized';
import { belongsToKundenSystem, belongsToSelectedFirmendaten, excludeArts } from '../../helpers/selectHelper';
import { EntityStatusFormSelect } from '../../components/Status/EntityStatus';
import { Vorschreibungsposition, VorschreibungspositionArt } from '../../types';

type Props = {
  firmendatenId?: string | null;
  includeFromFirmendaten?: boolean; // applies if firmendatenId in not set
  exludeArts?: VorschreibungspositionArt[] | undefined;
} & SelectProps;

const VorschreibungspositionSelect: FC<Props> = ({ name, firmendatenId, includeFromFirmendaten, exludeArts, ...restProps }) => {
  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();
  const [{ value: selectedId }, , { setValue: setSelectedId }] = useField<string>(name);

  const exludeArtList = exludeArts ?? [];

  const { loading: loadingByKS, data: maybeListByKS } = useVorschreibungspositionListForSelectByKundenSystemIdQuery({
    variables: {
      kundenSystemId,
      includeArchiviert: true,
    },
    skip: !!firmendatenId,
  });

  const { loading: loadingByFD, data: maybeListByFD } = useVorschreibungspositionListForSelectByFirmendatenIdQuery({
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      firmendatenId: firmendatenId!,
      includeArchiviert: true,
    },
    skip: !firmendatenId,
  });

  const loading = loadingByKS || loadingByFD;
  const listByKS = maybeListByKS?.getVorschreibungspositionListByKundenSystemId.data.contentList ?? [];
  const listByFD = maybeListByFD?.getVorschreibungspositionListByFirmendatenId.data.contentList ?? [];

  const list =
    // eslint-disable-next-line no-nested-ternary
    listByKS.length > 0
      ? includeFromFirmendaten
        ? listByKS
        : listByKS.filter(belongsToKundenSystem)
      : listByFD.filter((item) => belongsToSelectedFirmendaten(item, firmendatenId));

  const filteredList = list.filter((item) => excludeArts(item, exludeArtList));

  // f_id changes => list changes => if there was already a selected entity it may not be available in the scope of newly selected f_id => clear entity
  useEffect(() => {
    if (!loading && selectedId && !filteredList.map((item) => item.vorschreibungspositionId).includes(selectedId)) {
      setSelectedId('');
    }
  }, [loading, filteredList, selectedId, setSelectedId]);

  return (
    <EntityStatusFormSelect<Vorschreibungsposition>
      list={filteredList}
      getSelectItemId={(vp) => vp.vorschreibungspositionId}
      getSelectItemContent={(vp) => vp.bezeichnung}
      getSelectItemTooltipTitle={(vp) => vp.bezeichnung}
      id="vorschreibungsposition"
      name={name}
      loading={loadingByFD || loadingByKS}
      placeholder="Vorschreibungsposition auswählen"
      {...restProps}
    />
  );
};

export default VorschreibungspositionSelect;
