import React from 'react';
import { Route } from 'react-router';
import { URI_CONTACT_DETAILS_PAGE } from '../../constants/appUriPaths';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import AuthRoute from '../../security/AuthRoute';
// @ts-ignore
import ContactDetailsPage from './ContactDetailsPage';

const contactDetailsPageRoute = (
  <Route
    path={`${URI_CONTACT_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<ContactDetailsPage />} />}
  />
);

export default contactDetailsPageRoute;
