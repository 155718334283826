import React from 'react';
import { Route } from 'react-router';
import { isAdmin } from '../../../../security/permissionChecks';
import AuthRoute from '../../../../security/AuthRoute';
import AndromedaSysSettingsVorsteuerkuerzungPage from './AndromedaSysSettingsVorsteuerkuerzungPage';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';

const andromedaSysSettingsVorsteuerkuerzungPageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS.vorsteuerkuerzungPage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsVorsteuerkuerzungPage />} />}
  />
);

export default andromedaSysSettingsVorsteuerkuerzungPageRoute;
