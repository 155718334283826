import { Select, SelectProps } from 'formik-antd';
import { FC } from 'react';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { useFilterReceivedBankTransactionDebtorListQuery } from '../../gql/ReceivedBankTransactionQueries.types';

type Props = Omit<SelectProps, 'id' | 'placeholder' | 'size' | 'allowClear' | 'showSearch' | 'filterOption'>;

const ReceivedBankTransactionDebtorSelect: FC<Props> = ({ name, ...restProps }) => {
  const { data, loading } = useFilterReceivedBankTransactionDebtorListQuery();
  const debtorList = data?.getFilterReceivedBankTransactionDebtorList.data ?? [];

  return (
    <Select
      name={name}
      id={name}
      {...restProps}
      size="small"
      loading={loading}
      placeholder="Debitor auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {debtorList.map((debtor) => (
        <Select.Option key={debtor} value={debtor}>
          {debtor}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ReceivedBankTransactionDebtorSelect;
