export const downloadURL = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.click();
};

export const downloadFile = (blobParts: BlobPart[], fileName: string, contentType: string) => {
  const file = new Blob(blobParts, { type: contentType });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(file);
  link.download = fileName;
  link.click();
};

export const FILE_CONTENT_TYPE_PDF = 'application/pdf';

export const ALLOWED_CONTENT_TYPES = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'image/gif',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
];

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#Supported_image_formats
export const IMAGE_SUPPORTED_FORMATS = [
  'image/gif',
  'image/jpeg',
  'image/png',
  // Currently not supported by the backend
  // 'image/apng',
  // 'image/bmp',
  // 'image/x-icon',
  // 'image/svg+xml',
  // 'image/webp',
];

// Email formats
// message/rfc822 -> .eml (RFC 822 email format)
// application/vnd.ms-outlook -> .msg (Microsoft Outlook email format)
export const EMAIL_SUPPORTED_FORMATS = ['message/rfc822', 'application/vnd.ms-outlook'];
