import { Dayjs } from 'dayjs';
import { SaldenlisteKontoType } from '../../../types';
import { FormFields, mapFormDateValueToDateString } from '../../../helpers/formikHelper';
import { dayjsCustom } from '../../../helpers/dayjsCustom';
import { TSaldenlisteQueryParams } from './filtersQueryParams';

export type SaldenlisteListingFiltersFormValues = {
  buchungskreisId: string;
  objektId: string;
  buchungsmonat: Dayjs | string;
  kontoType: SaldenlisteListingFiltersKontoType;
  // for form functionality
  rechnungsAusstellerIdForBuchungskreis: string;
  rechnungsAusstellerIdForObjekt: string;
};

export const saldenlisteListingFiltersFormFields: FormFields<SaldenlisteListingFiltersFormValues> = {
  buchungskreisId: 'buchungskreisId',
  buchungsmonat: 'buchungsmonat',
  kontoType: 'kontoType',
  objektId: 'objektId',
  rechnungsAusstellerIdForBuchungskreis: 'rechnungsAusstellerIdForBuchungskreis',
  rechnungsAusstellerIdForObjekt: 'rechnungsAusstellerIdForObjekt',
};

export const mapSaldenlisteListingFiltersToFormValues = (filters: TSaldenlisteQueryParams): SaldenlisteListingFiltersFormValues => ({
  buchungskreisId: filters.buchungskreisId ?? '',
  objektId: filters.objektId ?? '',
  buchungsmonat: filters.buchungsmonat ? filters.buchungsmonat : dayjsCustom().startOf('month'),
  kontoType: filters.kontoType || 'ALL_KONTO_TYPE',
  rechnungsAusstellerIdForBuchungskreis: '',
  rechnungsAusstellerIdForObjekt: '',
});

export type SaldenlisteListingFiltersKontoType = SaldenlisteKontoType | 'ALL_KONTO_TYPE';

export const ALL_KONTO_TYPE_TUPLE: { value: SaldenlisteListingFiltersKontoType; text: string } = {
  value: 'ALL_KONTO_TYPE',
  text: 'Alle Kontotypen',
};

export const mapFormValuesToSaldenlisteListingFilters = (formValues: SaldenlisteListingFiltersFormValues): TSaldenlisteQueryParams => ({
  buchungskreisId: formValues.buchungskreisId || undefined,
  objektId: formValues.objektId || undefined,
  kontoType: formValues.kontoType !== 'ALL_KONTO_TYPE' ? formValues.kontoType : undefined,
  buchungsmonat: mapFormDateValueToDateString(dayjsCustom(formValues.buchungsmonat).startOf('month')),
});
