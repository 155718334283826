import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { FibuRechtstraegerFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import { MahnungFieldsFragmentDoc } from './MahnungFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MahnungListQueryVariables = Types.Exact<{
  rechnungsAusstellerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  vertragspartnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  statusList?: Types.InputMaybe<Array<Types.MahnungStatus> | Types.MahnungStatus>;
  sumRechnungsbetragVonInklusive?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  sumRechnungsbetragBisInklusive?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  sumMahnbetragVonInklusive?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  sumMahnbetragBisInklusive?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  mahngebuehrVonInklusive?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  mahngebuehrBisInklusive?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  verzugszinsenVonInklusive?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  verzugszinsenBisInklusive?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  mahnstichtagBisInklusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  mahnstichtagVonInklusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  versandEinschreiben?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  createTsFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  erstellerMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  order?: Types.InputMaybe<Types.MahnungOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type MahnungListQuery = {
  getMahnungList: {
    data: {
      contentList: Array<{
        buchungId?: string | null;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        mahngebuehr: number;
        mahnstichtag: string;
        mahnungId: string;
        rechnungsAusstellerBankDetailsIban: string;
        sumMahnbetrag: number;
        sumRechnungsbetrag: number;
        sumVerzugszinsen: number;
        versandEinschreiben: boolean;
        rechnungsAussteller: {
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
        };
        status: { description?: string | null; value: Types.MahnungStatus; text: string };
        vertragspartner: {
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
        };
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MahnungListSummaryQueryVariables = Types.Exact<{
  rechnungsAusstellerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  vertragspartnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  statusList?: Types.InputMaybe<Array<Types.MahnungStatus> | Types.MahnungStatus>;
  sumRechnungsbetragVonInklusive?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  sumRechnungsbetragBisInklusive?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  sumMahnbetragVonInklusive?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  sumMahnbetragBisInklusive?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  mahngebuehrVonInklusive?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  mahngebuehrBisInklusive?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  verzugszinsenVonInklusive?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  verzugszinsenBisInklusive?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  mahnstichtagBisInklusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  mahnstichtagVonInklusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  versandEinschreiben?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  erstellerMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
}>;

export type MahnungListSummaryQuery = {
  getMahnungListSummary: {
    data: { sumDefaultInterest: number; sumDunningAmount: number; sumDunningCharge: number; sumInvoiceAmount: number };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MahnungObjektListQueryVariables = Types.Exact<{
  mahnungId: Types.Scalars['ID']['input'];
}>;

export type MahnungObjektListQuery = {
  getMahnungObjektList: {
    data: Array<{ kurzBezeichnung: string; objektId: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MahnungStatusListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MahnungStatusListQuery = {
  getMahnungStatusList: {
    data: Array<{ description?: string | null; text: string; value: Types.MahnungStatus }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MahnungQueryVariables = Types.Exact<{
  mahnungId: Types.Scalars['ID']['input'];
}>;

export type MahnungQuery = {
  getMahnung: {
    data: {
      buchungId?: string | null;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      fileId?: string | null;
      freigegebenTs?: string | null;
      mahngebuehr: number;
      mahnstichtag: string;
      mahnungEmail?: string | null;
      mahnungId: string;
      opEmail: string;
      rechnungsAusstellerBankDetailsAccountHolder: string;
      rechnungsAusstellerBankDetailsIban: string;
      updatedByMitarbeiterId?: string | null;
      sumMahnbetrag: number;
      sumRechnungsbetrag: number;
      sumVerzugszinsen: number;
      sumZahlungen: number;
      versandEinschreiben: boolean;
      versendetTs?: string | null;
      verzugszinsen: number;
      buchungskreis: {
        buchungskreisId: string;
        rechtstraeger: {
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
        };
      };
      debitorKonto: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        haupt: boolean;
        kontoId: string;
        kontoTemplateId?: string | null;
        nummer: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        klasse: { text: string; value: Types.KontoKlasse };
        status: { description?: string | null; text: string; value: Types.KontoStatus };
        type: { text: string; value: Types.KontoType };
        verwendung?: { text: string; value: Types.KontoVerwendung } | null;
      };
      mahnPositionList: Array<{
        belegDatum: string;
        belegFileId: string;
        belegnummer: string;
        buchungskreisId: string;
        dataCarrierBelegId?: string | null;
        dueDate: string;
        gesamtBetrag: number;
        iban?: string | null;
        letzterMahnstichtag?: string | null;
        mahnPositionId: string;
        mahnstufe: number;
        offenerBetrag: number;
        statementNumber?: string | null;
        verzugszinsen: number;
        verzugszinsenBerechnen: boolean;
        zahlungBetrag: number;
        belegSymbol: { abbreviation: string; text: string; value: Types.FibuBelegSymbol };
      }>;
      mahnungDeliveryAddress?: {
        addressId: string;
        city: string;
        cityAdditionalInformation?: string | null;
        countryCodeIso2: string;
        houseEntranceApartmentNumber?: string | null;
        postofficeBoxNumber?: string | null;
        postofficeBoxZipCode?: string | null;
        street?: string | null;
        type: Types.AddressType;
        zipCode?: string | null;
      } | null;
      mailDelivery?: { mailId?: string | null; status: { text: string; value: Types.MahnungMailDeliveryStatus } } | null;
      objektList: Array<{ kurzBezeichnung: string; objektId: string }>;
      opDeliveryAddress: {
        addressId: string;
        city: string;
        cityAdditionalInformation?: string | null;
        countryCodeIso2: string;
        houseEntranceApartmentNumber?: string | null;
        postofficeBoxNumber?: string | null;
        postofficeBoxZipCode?: string | null;
        street?: string | null;
        type: Types.AddressType;
        zipCode?: string | null;
      };
      opZustellRechtstraeger: {
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
      };
      rechnungsAussteller: {
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
      };
      status: { value: Types.MahnungStatus; text: string };
      vertragspartner: {
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
      };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SumVerzugszinsenQueryVariables = Types.Exact<{
  mahnungId: Types.Scalars['ID']['input'];
  mahnPositionIdList: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
  verzugszinsenProzent: Types.Scalars['Float']['input'];
}>;

export type SumVerzugszinsenQuery = {
  getSumVerzugszinsen: {
    data: { mahnPositionIdVerzugszinsenMap: any; sumVerzugszinsen: number };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MahnungChangeHistoryListQueryVariables = Types.Exact<{
  mahnungId: Types.Scalars['ID']['input'];
}>;

export type MahnungChangeHistoryListQuery = {
  getMahnungChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type MahnPositionChangeHistoryListQueryVariables = Types.Exact<{
  mahnungId: Types.Scalars['ID']['input'];
  mahnPositionId: Types.Scalars['ID']['input'];
}>;

export type MahnPositionChangeHistoryListQuery = {
  getMahnPositionChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type MahnPositionDeletionHistoryListQueryVariables = Types.Exact<{
  mahnungId: Types.Scalars['ID']['input'];
}>;

export type MahnPositionDeletionHistoryListQuery = {
  getMahnPositionDeletionHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const MahnungListDocument = gql`
  query MahnungList(
    $rechnungsAusstellerId: String
    $vertragspartnerId: String
    $objektId: String
    $statusList: [MahnungStatus!]
    $sumRechnungsbetragVonInklusive: Float
    $sumRechnungsbetragBisInklusive: Float
    $sumMahnbetragVonInklusive: Float
    $sumMahnbetragBisInklusive: Float
    $mahngebuehrVonInklusive: Float
    $mahngebuehrBisInklusive: Float
    $verzugszinsenVonInklusive: Float
    $verzugszinsenBisInklusive: Float
    $mahnstichtagBisInklusive: String
    $mahnstichtagVonInklusive: String
    $versandEinschreiben: Boolean
    $createTsFrom: String
    $createTsTo: String
    $erstellerMitarbeiterIdList: [String!]
    $order: MahnungOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getMahnungList(
      rechnungsAusstellerId: $rechnungsAusstellerId
      vertragspartnerId: $vertragspartnerId
      objektId: $objektId
      statusList: $statusList
      sumRechnungsbetragVonInklusive: $sumRechnungsbetragVonInklusive
      sumRechnungsbetragBisInklusive: $sumRechnungsbetragBisInklusive
      sumMahnbetragVonInklusive: $sumMahnbetragVonInklusive
      sumMahnbetragBisInklusive: $sumMahnbetragBisInklusive
      mahngebuehrVonInklusive: $mahngebuehrVonInklusive
      mahngebuehrBisInklusive: $mahngebuehrBisInklusive
      verzugszinsenVonInklusive: $verzugszinsenVonInklusive
      verzugszinsenBisInklusive: $verzugszinsenBisInklusive
      mahnstichtagBisInklusive: $mahnstichtagBisInklusive
      mahnstichtagVonInklusive: $mahnstichtagVonInklusive
      versandEinschreiben: $versandEinschreiben
      createTsFrom: $createTsFrom
      createTsTo: $createTsTo
      erstellerMitarbeiterIdList: $erstellerMitarbeiterIdList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          buchungId
          createTs
          createdBy
          createdByMitarbeiterId
          mahngebuehr
          mahnstichtag
          mahnungId
          rechnungsAussteller {
            ...FibuRechtstraeger
          }
          rechnungsAusstellerBankDetailsIban
          status {
            description
            value
            text
          }
          sumMahnbetrag
          sumRechnungsbetrag
          sumVerzugszinsen
          versandEinschreiben
          vertragspartner {
            ...FibuRechtstraeger
          }
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${FibuRechtstraegerFragmentDoc}
`;
export function useMahnungListQuery(baseOptions?: Apollo.QueryHookOptions<MahnungListQuery, MahnungListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahnungListQuery, MahnungListQueryVariables>(MahnungListDocument, options);
}
export function useMahnungListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MahnungListQuery, MahnungListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahnungListQuery, MahnungListQueryVariables>(MahnungListDocument, options);
}
export function useMahnungListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MahnungListQuery, MahnungListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahnungListQuery, MahnungListQueryVariables>(MahnungListDocument, options);
}
export type MahnungListQueryHookResult = ReturnType<typeof useMahnungListQuery>;
export type MahnungListLazyQueryHookResult = ReturnType<typeof useMahnungListLazyQuery>;
export type MahnungListSuspenseQueryHookResult = ReturnType<typeof useMahnungListSuspenseQuery>;
export type MahnungListQueryResult = Apollo.QueryResult<MahnungListQuery, MahnungListQueryVariables>;
export const MahnungListSummaryDocument = gql`
  query MahnungListSummary(
    $rechnungsAusstellerId: String
    $vertragspartnerId: String
    $objektId: String
    $statusList: [MahnungStatus!]
    $sumRechnungsbetragVonInklusive: Float
    $sumRechnungsbetragBisInklusive: Float
    $sumMahnbetragVonInklusive: Float
    $sumMahnbetragBisInklusive: Float
    $mahngebuehrVonInklusive: Float
    $mahngebuehrBisInklusive: Float
    $verzugszinsenVonInklusive: Float
    $verzugszinsenBisInklusive: Float
    $mahnstichtagBisInklusive: String
    $mahnstichtagVonInklusive: String
    $versandEinschreiben: Boolean
    $erstellerMitarbeiterIdList: [String!]
  ) {
    getMahnungListSummary(
      rechnungsAusstellerId: $rechnungsAusstellerId
      vertragspartnerId: $vertragspartnerId
      objektId: $objektId
      statusList: $statusList
      sumRechnungsbetragVonInklusive: $sumRechnungsbetragVonInklusive
      sumRechnungsbetragBisInklusive: $sumRechnungsbetragBisInklusive
      sumMahnbetragVonInklusive: $sumMahnbetragVonInklusive
      sumMahnbetragBisInklusive: $sumMahnbetragBisInklusive
      mahngebuehrVonInklusive: $mahngebuehrVonInklusive
      mahngebuehrBisInklusive: $mahngebuehrBisInklusive
      verzugszinsenVonInklusive: $verzugszinsenVonInklusive
      verzugszinsenBisInklusive: $verzugszinsenBisInklusive
      mahnstichtagBisInklusive: $mahnstichtagBisInklusive
      mahnstichtagVonInklusive: $mahnstichtagVonInklusive
      versandEinschreiben: $versandEinschreiben
      erstellerMitarbeiterIdList: $erstellerMitarbeiterIdList
    ) {
      data {
        sumDefaultInterest
        sumDunningAmount
        sumDunningCharge
        sumInvoiceAmount
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useMahnungListSummaryQuery(baseOptions?: Apollo.QueryHookOptions<MahnungListSummaryQuery, MahnungListSummaryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahnungListSummaryQuery, MahnungListSummaryQueryVariables>(MahnungListSummaryDocument, options);
}
export function useMahnungListSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MahnungListSummaryQuery, MahnungListSummaryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahnungListSummaryQuery, MahnungListSummaryQueryVariables>(MahnungListSummaryDocument, options);
}
export function useMahnungListSummarySuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MahnungListSummaryQuery, MahnungListSummaryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahnungListSummaryQuery, MahnungListSummaryQueryVariables>(MahnungListSummaryDocument, options);
}
export type MahnungListSummaryQueryHookResult = ReturnType<typeof useMahnungListSummaryQuery>;
export type MahnungListSummaryLazyQueryHookResult = ReturnType<typeof useMahnungListSummaryLazyQuery>;
export type MahnungListSummarySuspenseQueryHookResult = ReturnType<typeof useMahnungListSummarySuspenseQuery>;
export type MahnungListSummaryQueryResult = Apollo.QueryResult<MahnungListSummaryQuery, MahnungListSummaryQueryVariables>;
export const MahnungObjektListDocument = gql`
  query MahnungObjektList($mahnungId: ID!) {
    getMahnungObjektList(mahnungId: $mahnungId) {
      data {
        kurzBezeichnung
        objektId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useMahnungObjektListQuery(
  baseOptions: Apollo.QueryHookOptions<MahnungObjektListQuery, MahnungObjektListQueryVariables> &
    ({ variables: MahnungObjektListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahnungObjektListQuery, MahnungObjektListQueryVariables>(MahnungObjektListDocument, options);
}
export function useMahnungObjektListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MahnungObjektListQuery, MahnungObjektListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahnungObjektListQuery, MahnungObjektListQueryVariables>(MahnungObjektListDocument, options);
}
export function useMahnungObjektListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MahnungObjektListQuery, MahnungObjektListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahnungObjektListQuery, MahnungObjektListQueryVariables>(MahnungObjektListDocument, options);
}
export type MahnungObjektListQueryHookResult = ReturnType<typeof useMahnungObjektListQuery>;
export type MahnungObjektListLazyQueryHookResult = ReturnType<typeof useMahnungObjektListLazyQuery>;
export type MahnungObjektListSuspenseQueryHookResult = ReturnType<typeof useMahnungObjektListSuspenseQuery>;
export type MahnungObjektListQueryResult = Apollo.QueryResult<MahnungObjektListQuery, MahnungObjektListQueryVariables>;
export const MahnungStatusListDocument = gql`
  query MahnungStatusList {
    getMahnungStatusList {
      data {
        description
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useMahnungStatusListQuery(baseOptions?: Apollo.QueryHookOptions<MahnungStatusListQuery, MahnungStatusListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahnungStatusListQuery, MahnungStatusListQueryVariables>(MahnungStatusListDocument, options);
}
export function useMahnungStatusListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MahnungStatusListQuery, MahnungStatusListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahnungStatusListQuery, MahnungStatusListQueryVariables>(MahnungStatusListDocument, options);
}
export function useMahnungStatusListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MahnungStatusListQuery, MahnungStatusListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahnungStatusListQuery, MahnungStatusListQueryVariables>(MahnungStatusListDocument, options);
}
export type MahnungStatusListQueryHookResult = ReturnType<typeof useMahnungStatusListQuery>;
export type MahnungStatusListLazyQueryHookResult = ReturnType<typeof useMahnungStatusListLazyQuery>;
export type MahnungStatusListSuspenseQueryHookResult = ReturnType<typeof useMahnungStatusListSuspenseQuery>;
export type MahnungStatusListQueryResult = Apollo.QueryResult<MahnungStatusListQuery, MahnungStatusListQueryVariables>;
export const MahnungDocument = gql`
  query Mahnung($mahnungId: ID!) {
    getMahnung(mahnungId: $mahnungId) {
      data {
        ...MahnungFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${MahnungFieldsFragmentDoc}
`;
export function useMahnungQuery(
  baseOptions: Apollo.QueryHookOptions<MahnungQuery, MahnungQueryVariables> &
    ({ variables: MahnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahnungQuery, MahnungQueryVariables>(MahnungDocument, options);
}
export function useMahnungLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MahnungQuery, MahnungQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahnungQuery, MahnungQueryVariables>(MahnungDocument, options);
}
export function useMahnungSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MahnungQuery, MahnungQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahnungQuery, MahnungQueryVariables>(MahnungDocument, options);
}
export type MahnungQueryHookResult = ReturnType<typeof useMahnungQuery>;
export type MahnungLazyQueryHookResult = ReturnType<typeof useMahnungLazyQuery>;
export type MahnungSuspenseQueryHookResult = ReturnType<typeof useMahnungSuspenseQuery>;
export type MahnungQueryResult = Apollo.QueryResult<MahnungQuery, MahnungQueryVariables>;
export const SumVerzugszinsenDocument = gql`
  query SumVerzugszinsen($mahnungId: ID!, $mahnPositionIdList: [String!]!, $verzugszinsenProzent: Float!) {
    getSumVerzugszinsen(mahnungId: $mahnungId, mahnPositionIdList: $mahnPositionIdList, verzugszinsenProzent: $verzugszinsenProzent) {
      data {
        mahnPositionIdVerzugszinsenMap
        sumVerzugszinsen
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSumVerzugszinsenQuery(
  baseOptions: Apollo.QueryHookOptions<SumVerzugszinsenQuery, SumVerzugszinsenQueryVariables> &
    ({ variables: SumVerzugszinsenQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SumVerzugszinsenQuery, SumVerzugszinsenQueryVariables>(SumVerzugszinsenDocument, options);
}
export function useSumVerzugszinsenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SumVerzugszinsenQuery, SumVerzugszinsenQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SumVerzugszinsenQuery, SumVerzugszinsenQueryVariables>(SumVerzugszinsenDocument, options);
}
export function useSumVerzugszinsenSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SumVerzugszinsenQuery, SumVerzugszinsenQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SumVerzugszinsenQuery, SumVerzugszinsenQueryVariables>(SumVerzugszinsenDocument, options);
}
export type SumVerzugszinsenQueryHookResult = ReturnType<typeof useSumVerzugszinsenQuery>;
export type SumVerzugszinsenLazyQueryHookResult = ReturnType<typeof useSumVerzugszinsenLazyQuery>;
export type SumVerzugszinsenSuspenseQueryHookResult = ReturnType<typeof useSumVerzugszinsenSuspenseQuery>;
export type SumVerzugszinsenQueryResult = Apollo.QueryResult<SumVerzugszinsenQuery, SumVerzugszinsenQueryVariables>;
export const MahnungChangeHistoryListDocument = gql`
  query MahnungChangeHistoryList($mahnungId: ID!) {
    getMahnungChangeHistoryList(mahnungId: $mahnungId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useMahnungChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<MahnungChangeHistoryListQuery, MahnungChangeHistoryListQueryVariables> &
    ({ variables: MahnungChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahnungChangeHistoryListQuery, MahnungChangeHistoryListQueryVariables>(MahnungChangeHistoryListDocument, options);
}
export function useMahnungChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MahnungChangeHistoryListQuery, MahnungChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahnungChangeHistoryListQuery, MahnungChangeHistoryListQueryVariables>(MahnungChangeHistoryListDocument, options);
}
export function useMahnungChangeHistoryListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MahnungChangeHistoryListQuery, MahnungChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahnungChangeHistoryListQuery, MahnungChangeHistoryListQueryVariables>(MahnungChangeHistoryListDocument, options);
}
export type MahnungChangeHistoryListQueryHookResult = ReturnType<typeof useMahnungChangeHistoryListQuery>;
export type MahnungChangeHistoryListLazyQueryHookResult = ReturnType<typeof useMahnungChangeHistoryListLazyQuery>;
export type MahnungChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useMahnungChangeHistoryListSuspenseQuery>;
export type MahnungChangeHistoryListQueryResult = Apollo.QueryResult<MahnungChangeHistoryListQuery, MahnungChangeHistoryListQueryVariables>;
export const MahnPositionChangeHistoryListDocument = gql`
  query MahnPositionChangeHistoryList($mahnungId: ID!, $mahnPositionId: ID!) {
    getMahnPositionChangeHistoryList(mahnungId: $mahnungId, mahnPositionId: $mahnPositionId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useMahnPositionChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<MahnPositionChangeHistoryListQuery, MahnPositionChangeHistoryListQueryVariables> &
    ({ variables: MahnPositionChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahnPositionChangeHistoryListQuery, MahnPositionChangeHistoryListQueryVariables>(
    MahnPositionChangeHistoryListDocument,
    options
  );
}
export function useMahnPositionChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MahnPositionChangeHistoryListQuery, MahnPositionChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahnPositionChangeHistoryListQuery, MahnPositionChangeHistoryListQueryVariables>(
    MahnPositionChangeHistoryListDocument,
    options
  );
}
export function useMahnPositionChangeHistoryListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MahnPositionChangeHistoryListQuery, MahnPositionChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahnPositionChangeHistoryListQuery, MahnPositionChangeHistoryListQueryVariables>(
    MahnPositionChangeHistoryListDocument,
    options
  );
}
export type MahnPositionChangeHistoryListQueryHookResult = ReturnType<typeof useMahnPositionChangeHistoryListQuery>;
export type MahnPositionChangeHistoryListLazyQueryHookResult = ReturnType<typeof useMahnPositionChangeHistoryListLazyQuery>;
export type MahnPositionChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useMahnPositionChangeHistoryListSuspenseQuery>;
export type MahnPositionChangeHistoryListQueryResult = Apollo.QueryResult<
  MahnPositionChangeHistoryListQuery,
  MahnPositionChangeHistoryListQueryVariables
>;
export const MahnPositionDeletionHistoryListDocument = gql`
  query MahnPositionDeletionHistoryList($mahnungId: ID!) {
    getMahnPositionDeletionHistoryList(mahnungId: $mahnungId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useMahnPositionDeletionHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<MahnPositionDeletionHistoryListQuery, MahnPositionDeletionHistoryListQueryVariables> &
    ({ variables: MahnPositionDeletionHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahnPositionDeletionHistoryListQuery, MahnPositionDeletionHistoryListQueryVariables>(
    MahnPositionDeletionHistoryListDocument,
    options
  );
}
export function useMahnPositionDeletionHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MahnPositionDeletionHistoryListQuery, MahnPositionDeletionHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahnPositionDeletionHistoryListQuery, MahnPositionDeletionHistoryListQueryVariables>(
    MahnPositionDeletionHistoryListDocument,
    options
  );
}
export function useMahnPositionDeletionHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<MahnPositionDeletionHistoryListQuery, MahnPositionDeletionHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahnPositionDeletionHistoryListQuery, MahnPositionDeletionHistoryListQueryVariables>(
    MahnPositionDeletionHistoryListDocument,
    options
  );
}
export type MahnPositionDeletionHistoryListQueryHookResult = ReturnType<typeof useMahnPositionDeletionHistoryListQuery>;
export type MahnPositionDeletionHistoryListLazyQueryHookResult = ReturnType<typeof useMahnPositionDeletionHistoryListLazyQuery>;
export type MahnPositionDeletionHistoryListSuspenseQueryHookResult = ReturnType<typeof useMahnPositionDeletionHistoryListSuspenseQuery>;
export type MahnPositionDeletionHistoryListQueryResult = Apollo.QueryResult<
  MahnPositionDeletionHistoryListQuery,
  MahnPositionDeletionHistoryListQueryVariables
>;
