import React, { FC, JSX } from 'react';
import { Button, Dropdown, MenuProps, Space, Typography } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import ButtonsAligned, { ButtonsAlignedUsage } from '../Button/ButtonsAligned';

type Props = {
  title: string;
  buttons: JSX.Element;
  items?: MenuProps['items'];
};

/**
 * <h2>Usage</h2>
 * Before a listing (table or list) component when the listing title and right aligned buttons should be rendered
 */
const ListingTitleWithButtons: FC<Props> = ({ title, buttons, items }) => {
  return (
    <Space align="baseline" style={{ width: '100%', justifyContent: 'space-between' }}>
      <Space align="start">
        <Typography.Title level={4}>{title}</Typography.Title>
        {items && (
          <Dropdown menu={{ items }}>
            <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
          </Dropdown>
        )}
      </Space>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>{buttons}</ButtonsAligned>
    </Space>
  );
};

export default ListingTitleWithButtons;
