import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { DebitorBelegData } from '../../op-list-debitor-types';
import pdfStyles from '../../../../styles/pdfStyles';
import { opDebitorTableListColumnWidthAndAlignment } from './op-debitor-table-styles';

type Props = {
  belegList: DebitorBelegData[];
};

const OPDebitorBelegListing: FC<Props> = ({ belegList }) => (
  <View>
    {belegList.map((beleg, belegIndex) => (
      <View key={`${beleg.rechnungsAusstellerBezeichnung}-${belegIndex}`} style={[pdfStyles.row, pdfStyles.textBulletin]}>
        <Text style={[pdfStyles.column, opDebitorTableListColumnWidthAndAlignment.belegnummer]}>{beleg.belegnummer}</Text>
        <Text style={[pdfStyles.column, opDebitorTableListColumnWidthAndAlignment.bezeichnung]}>{beleg.rechnungsAusstellerBezeichnung}</Text>
        <Text style={[pdfStyles.column, opDebitorTableListColumnWidthAndAlignment.buchungstext]}>{beleg.buchungstext}</Text>
        <Text style={[pdfStyles.column, opDebitorTableListColumnWidthAndAlignment.buchungsdatum]}>{beleg.buchungsdatum}</Text>
        <Text style={[pdfStyles.column, opDebitorTableListColumnWidthAndAlignment.dueDate]}>{beleg.dueDate}</Text>
        <Text style={[pdfStyles.column, opDebitorTableListColumnWidthAndAlignment.brutto]}>{beleg.brutto}</Text>
        <Text style={[pdfStyles.column, opDebitorTableListColumnWidthAndAlignment.zahlungsbetrag]}>{beleg.zahlungsbetrag}</Text>
        <Text style={[pdfStyles.column, opDebitorTableListColumnWidthAndAlignment.offenerBetrag]}>{beleg.offenerBetrag}</Text>
        <Text style={[pdfStyles.column, opDebitorTableListColumnWidthAndAlignment.mahnstufe]}>{beleg.mahnstufe}</Text>
      </View>
    ))}
  </View>
);

export default OPDebitorBelegListing;
