import * as Yup from 'yup';
import { ReactText } from 'react';
import { FormikYupValidationSchema } from './formikHelper';
import { entityIsRequired, entityMaxCharLength } from '../components/message/validationMsg';

export const validateSyncWithSchemaAndReturnErrorIfAny = (
  validationSchema: Yup.StringSchema | Yup.NumberSchema | Yup.BooleanSchema | Yup.DateSchema | Yup.AnySchema,
  valueToValidate: ReactText
) => {
  try {
    validationSchema.validateSync(valueToValidate);
    return undefined;
  } catch (error: any) {
    return error.errors[0];
  }
};

interface ObjectSyncWithSchemaAndReturnErrorsIfAny {
  validationSchema: FormikYupValidationSchema;
  valueToValidate: any;
}

export const validateObjectSyncWithSchemaAndReturnErrorsIfAny = ({ validationSchema, valueToValidate }: ObjectSyncWithSchemaAndReturnErrorsIfAny) => {
  try {
    validationSchema.validateSync(valueToValidate, { abortEarly: false });
    return null;
  } catch (error: any) {
    return error.errors;
  }
};

export const validateAsyncAndReturnErrorIfAny = (validationFn: { (): any; (): any; (): Promise<any> }) =>
  validationFn()
    .then(() => undefined)
    .catch(returnFirstValidationErrorOrLogUnexpectedError);

const returnFirstValidationErrorOrLogUnexpectedError = (error: { validationError: any; errorList: { message: any }[] }) => {
  if (error.validationError) {
    throw error.errorList[0].message;
  } else {
    console.error('Unexpected error', error);
    return null;
  }
};

export const validateRequiredStringWithMaxLength = (entity: string, maxLength: number) => {
  return Yup.string().required(entityIsRequired(entity)).max(maxLength, entityMaxCharLength(entity, maxLength));
};

export const validateRequiredNumberWithMaxLength = (entity: string, maxLength: number) => {
  return Yup.number().required(entityIsRequired(entity)).max(maxLength, entityMaxCharLength(entity, maxLength));
};
