import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AufteilungsschluesselFieldsFragmentDoc } from '../../Aufteilungsschluessel/gql/AufteilungsschluesselFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAufteilungsschluesselTemplateMutationVariables = Types.Exact<{
  input: Types.AufteilungsschluesselCreateInput;
}>;

export type CreateAufteilungsschluesselTemplateMutation = {
  createAufteilungsschluesselTemplate: {
    data: {
      aufteilungsschluesselId: string;
      bezeichnung: string;
      bezugsbasisEditable?: boolean | null;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      updatedByMitarbeiterId?: string | null;
      bezugsbasis: { text: string; value: Types.Bezugsbasis };
      masseinheit: { text: string; value: Types.Masseinheit };
      status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateAufteilungsschluesselTemplateMutationVariables = Types.Exact<{
  input: Types.AufteilungsschluesselUpdateInput;
  aufteilungsschluesselId: Types.Scalars['ID']['input'];
}>;

export type UpdateAufteilungsschluesselTemplateMutation = {
  updateAufteilungsschluesselTemplate: {
    data: {
      aufteilungsschluesselId: string;
      bezeichnung: string;
      bezugsbasisEditable?: boolean | null;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      updatedByMitarbeiterId?: string | null;
      bezugsbasis: { text: string; value: Types.Bezugsbasis };
      masseinheit: { text: string; value: Types.Masseinheit };
      status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteAufteilungsschluesselTemplateMutationVariables = Types.Exact<{
  aufteilungsschluesselId: Types.Scalars['ID']['input'];
}>;

export type DeleteAufteilungsschluesselTemplateMutation = {
  deleteAufteilungsschluesselTemplate: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type UnarchiveAufteilungsschluesselTemplateMutationVariables = Types.Exact<{
  aufteilungsschluesselId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveAufteilungsschluesselTemplateMutation = {
  actionUnarchiveAufteilungsschluesselTemplate: {
    data: {
      aufteilungsschluesselId: string;
      bezeichnung: string;
      bezugsbasisEditable?: boolean | null;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      updatedByMitarbeiterId?: string | null;
      bezugsbasis: { text: string; value: Types.Bezugsbasis };
      masseinheit: { text: string; value: Types.Masseinheit };
      status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ArchiveAufteilungsschluesselTemplateMutationVariables = Types.Exact<{
  aufteilungsschluesselId: Types.Scalars['ID']['input'];
}>;

export type ArchiveAufteilungsschluesselTemplateMutation = {
  actionArchiveAufteilungsschluesselTemplate: {
    data: {
      aufteilungsschluesselId: string;
      bezeichnung: string;
      bezugsbasisEditable?: boolean | null;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      updatedByMitarbeiterId?: string | null;
      bezugsbasis: { text: string; value: Types.Bezugsbasis };
      masseinheit: { text: string; value: Types.Masseinheit };
      status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateAufteilungsschluesselTemplateDocument = gql`
  mutation createAufteilungsschluesselTemplate($input: AufteilungsschluesselCreateInput!) {
    createAufteilungsschluesselTemplate(input: $input) {
      data {
        ...AufteilungsschluesselFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
`;
export function useCreateAufteilungsschluesselTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAufteilungsschluesselTemplateMutation, CreateAufteilungsschluesselTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAufteilungsschluesselTemplateMutation, CreateAufteilungsschluesselTemplateMutationVariables>(
    CreateAufteilungsschluesselTemplateDocument,
    options
  );
}
export type CreateAufteilungsschluesselTemplateMutationHookResult = ReturnType<typeof useCreateAufteilungsschluesselTemplateMutation>;
export type CreateAufteilungsschluesselTemplateMutationResult = Apollo.MutationResult<CreateAufteilungsschluesselTemplateMutation>;
export type CreateAufteilungsschluesselTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateAufteilungsschluesselTemplateMutation,
  CreateAufteilungsschluesselTemplateMutationVariables
>;
export const UpdateAufteilungsschluesselTemplateDocument = gql`
  mutation updateAufteilungsschluesselTemplate($input: AufteilungsschluesselUpdateInput!, $aufteilungsschluesselId: ID!) {
    updateAufteilungsschluesselTemplate(input: $input, aufteilungsschluesselId: $aufteilungsschluesselId) {
      data {
        ...AufteilungsschluesselFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
`;
export function useUpdateAufteilungsschluesselTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAufteilungsschluesselTemplateMutation, UpdateAufteilungsschluesselTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAufteilungsschluesselTemplateMutation, UpdateAufteilungsschluesselTemplateMutationVariables>(
    UpdateAufteilungsschluesselTemplateDocument,
    options
  );
}
export type UpdateAufteilungsschluesselTemplateMutationHookResult = ReturnType<typeof useUpdateAufteilungsschluesselTemplateMutation>;
export type UpdateAufteilungsschluesselTemplateMutationResult = Apollo.MutationResult<UpdateAufteilungsschluesselTemplateMutation>;
export type UpdateAufteilungsschluesselTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateAufteilungsschluesselTemplateMutation,
  UpdateAufteilungsschluesselTemplateMutationVariables
>;
export const DeleteAufteilungsschluesselTemplateDocument = gql`
  mutation deleteAufteilungsschluesselTemplate($aufteilungsschluesselId: ID!) {
    deleteAufteilungsschluesselTemplate(aufteilungsschluesselId: $aufteilungsschluesselId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteAufteilungsschluesselTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAufteilungsschluesselTemplateMutation, DeleteAufteilungsschluesselTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAufteilungsschluesselTemplateMutation, DeleteAufteilungsschluesselTemplateMutationVariables>(
    DeleteAufteilungsschluesselTemplateDocument,
    options
  );
}
export type DeleteAufteilungsschluesselTemplateMutationHookResult = ReturnType<typeof useDeleteAufteilungsschluesselTemplateMutation>;
export type DeleteAufteilungsschluesselTemplateMutationResult = Apollo.MutationResult<DeleteAufteilungsschluesselTemplateMutation>;
export type DeleteAufteilungsschluesselTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteAufteilungsschluesselTemplateMutation,
  DeleteAufteilungsschluesselTemplateMutationVariables
>;
export const UnarchiveAufteilungsschluesselTemplateDocument = gql`
  mutation UnarchiveAufteilungsschluesselTemplate($aufteilungsschluesselId: ID!) {
    actionUnarchiveAufteilungsschluesselTemplate(aufteilungsschluesselId: $aufteilungsschluesselId) {
      data {
        ...AufteilungsschluesselFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
`;
export function useUnarchiveAufteilungsschluesselTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveAufteilungsschluesselTemplateMutation, UnarchiveAufteilungsschluesselTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveAufteilungsschluesselTemplateMutation, UnarchiveAufteilungsschluesselTemplateMutationVariables>(
    UnarchiveAufteilungsschluesselTemplateDocument,
    options
  );
}
export type UnarchiveAufteilungsschluesselTemplateMutationHookResult = ReturnType<typeof useUnarchiveAufteilungsschluesselTemplateMutation>;
export type UnarchiveAufteilungsschluesselTemplateMutationResult = Apollo.MutationResult<UnarchiveAufteilungsschluesselTemplateMutation>;
export type UnarchiveAufteilungsschluesselTemplateMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveAufteilungsschluesselTemplateMutation,
  UnarchiveAufteilungsschluesselTemplateMutationVariables
>;
export const ArchiveAufteilungsschluesselTemplateDocument = gql`
  mutation ArchiveAufteilungsschluesselTemplate($aufteilungsschluesselId: ID!) {
    actionArchiveAufteilungsschluesselTemplate(aufteilungsschluesselId: $aufteilungsschluesselId) {
      data {
        ...AufteilungsschluesselFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
`;
export function useArchiveAufteilungsschluesselTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveAufteilungsschluesselTemplateMutation, ArchiveAufteilungsschluesselTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveAufteilungsschluesselTemplateMutation, ArchiveAufteilungsschluesselTemplateMutationVariables>(
    ArchiveAufteilungsschluesselTemplateDocument,
    options
  );
}
export type ArchiveAufteilungsschluesselTemplateMutationHookResult = ReturnType<typeof useArchiveAufteilungsschluesselTemplateMutation>;
export type ArchiveAufteilungsschluesselTemplateMutationResult = Apollo.MutationResult<ArchiveAufteilungsschluesselTemplateMutation>;
export type ArchiveAufteilungsschluesselTemplateMutationOptions = Apollo.BaseMutationOptions<
  ArchiveAufteilungsschluesselTemplateMutation,
  ArchiveAufteilungsschluesselTemplateMutationVariables
>;
