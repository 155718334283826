import React from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import GenerierlaufEntryStatus from '../../../shared/GenerierlaufEntryStatus';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { generatePathToRechtstraegerDetailsPage } from '../../../../Rechtstraeger/rechtstraegerHelper';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../../../../components/Card';
import { URI_EMAIL_VERSAND_PAGE } from '../../../../EmailDelivery/emailDeliveryUriPaths';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import MultipleTexts from '../../../../../components/Text/MultipleTexts';
import { getObjektVertragList } from '../../../../EmailDelivery/VorschreibungMailDelivery/helpers';
import {
  VorschreibungMailDeliveryDetermineRecipientGenLaufOrderBy,
  VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntry,
} from '../../../../../types';

const EntryTableColumns: TableWithColSelectorColumnProps<VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntry>[] = [
  {
    title: 'Objekt-Top',
    defaultSelected: true,
    dataIndex: VorschreibungMailDeliveryDetermineRecipientGenLaufOrderBy.Objekt,
    sorter: true,
    render: (_, record) => {
      const objektList = getObjektVertragList(record.vorschreibungMailDelivery?.objektBestandseinheitVertragList).map((vertag) => vertag.topName);
      return <MultipleTexts texts={objektList} maxFirstTextLength={30} />;
    },
  },
  {
    title: 'An',
    defaultSelected: true,
    dataIndex: VorschreibungMailDeliveryDetermineRecipientGenLaufOrderBy.Email,
    sorter: true,
    render: (_, record) =>
      record.vorschreibungMailDelivery?.emailAddress ? (
        <Typography.Text>{record.vorschreibungMailDelivery.emailAddress}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Vertragspartner',
    defaultSelected: true,
    dataIndex: VorschreibungMailDeliveryDetermineRecipientGenLaufOrderBy.Vertragspartner,
    sorter: true,
    render: (_, record) =>
      record.vorschreibungMailDelivery?.vertragspartner ? (
        <DataWithShortenedText maxTextLength={15} text={record.vorschreibungMailDelivery.vertragspartner.kurzBezeichnung}>
          {(shortenedText) => (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <Link to={generatePathToRechtstraegerDetailsPage(record.vorschreibungMailDelivery!.vertragspartner.rechtstraegerId)} target="_blank">
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Empfänger',
    defaultSelected: true,
    dataIndex: VorschreibungMailDeliveryDetermineRecipientGenLaufOrderBy.Empfaenger,
    sorter: true,
    render: (_, record) =>
      record.vorschreibungMailDelivery?.recipient ? (
        <DataWithShortenedText maxTextLength={15} text={record.vorschreibungMailDelivery.recipient.kurzBezeichnung}>
          {(shortenedText) => (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <Link to={generatePathToRechtstraegerDetailsPage(record.vorschreibungMailDelivery!.recipient.rechtstraegerId)} target="_blank">
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Erstellt am',
    render: (_, record) =>
      record.vorschreibungMailDelivery?.createTs ? (
        <FormattedDateTime createdTs={record.vorschreibungMailDelivery.createTs} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Ersteller',
    render: (_, record) =>
      record.vorschreibungMailDelivery?.createdBy ? (
        <MitarbeiterTooltip
          userId={record.vorschreibungMailDelivery.createdBy}
          mitarbeiterId={record.vorschreibungMailDelivery.createdByMitarbeiterId}
          showsInitials
          alignment="left"
        />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Status',
    defaultSelected: true,
    dataIndex: VorschreibungMailDeliveryDetermineRecipientGenLaufOrderBy.Status,
    sorter: true,
    render: (_, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (_, record) =>
      record.vorschreibungMailDelivery?.mailId ? (
        <Link to={`${URI_EMAIL_VERSAND_PAGE}/${record.vorschreibungMailDelivery?.mailId}`} target="_blank">
          Details
        </Link>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
];

export default EntryTableColumns;
