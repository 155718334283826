import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { CamtGenerierlauf } from '../../../../types';
import { TCamtVerarbeitungQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import { CamtVerarbeitungEntryFiltersFormValues, camtVerarbEntryListingFiltersFormInitialValues } from './Filters/listingFiltersFormMapper';
import ListingFilters from './Filters/ListingFilters';
import CamtEbicsEntryTable from './Ebics/CamtEbicsEntryTable';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { verarbeitungTypes } from '../../verarbeitungHelpers';
import OnlineBankingDataCarrierDetailsTable from './Onlinebanking/OnlineBankingDataCarrierDetailsTable';

type Props = {
  generierlauf: CamtGenerierlauf;
  verarbeitungType: string;
};

const EntryListing: FC<Props> = ({ generierlauf, verarbeitungType }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TCamtVerarbeitungQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(navigate, verarbeitungType, generierlauf.generierlaufId, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: CamtVerarbeitungEntryFiltersFormValues) => {
    updateQueryParams(navigate, verarbeitungType, generierlauf.generierlaufId, values, paginationParams);
  };

  return (
    <Formik<CamtVerarbeitungEntryFiltersFormValues> initialValues={camtVerarbEntryListingFiltersFormInitialValues(queryParams)} onSubmit={onChange}>
      {(formikProps) => (
        <>
          <ListingFilters formikProps={formikProps} generierlaufId={generierlauf.generierlaufId} />
          {(verarbeitungType === verarbeitungTypes.Camt || verarbeitungType === verarbeitungTypes.CamtEbicsManually) && (
            <CamtEbicsEntryTable generierlaufId={generierlauf.generierlaufId} queryParams={queryParams} />
          )}
          {verarbeitungType === verarbeitungTypes.CamtOnlineBankingManually && (
            <OnlineBankingDataCarrierDetailsTable generierlaufId={generierlauf.generierlaufId} queryParams={queryParams} />
          )}
        </>
      )}
    </Formik>
  );
};

export default EntryListing;
