import React, { FC } from 'react';
import { Radio } from 'antd';
import { ValuePreservationType } from '../../../types';

type Props = {
  activeType?: ValuePreservationType;
  onSelectionChange: (type: ValuePreservationType) => void;
};

const ValuePreservationTypeSelection: FC<Props> = ({ activeType, onSelectionChange }) => {
  return (
    <Radio.Group buttonStyle="solid" onChange={(value) => onSelectionChange(value.target.value)} defaultValue={activeType}>
      <Radio.Button value={ValuePreservationType.IndexThreshold}>Index Schwellenwert</Radio.Button>
      <Radio.Button value={ValuePreservationType.IndexAnnual}>Index jährlich</Radio.Button>
      <Radio.Button value={ValuePreservationType.Category}>Kategorie</Radio.Button>
      <Radio.Button value={ValuePreservationType.ReferenceValue}>Richtwert</Radio.Button>
    </Radio.Group>
  );
};

export default ValuePreservationTypeSelection;
