import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../helpers/selectHelper';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { useFirmendatenEbicsUserIbanListQuery } from '../EbicsUser/gql/EbicsUserIbanQueries.types';

type Props = { name: string } & SelectProps;

const EbicsIbanSelectForm: FC<Props> = ({ name, ...restProps }) => {
  const { data, loading } = useFirmendatenEbicsUserIbanListQuery();

  const ibanList = data?.getFirmendatenEbicsUserIbanList.data ?? [];

  return (
    <Select
      size="small"
      name={name}
      loading={loading}
      placeholder="IBAN auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
      {...restProps}
    >
      {ibanList.map((iban) => (
        <Select.Option key={iban} value={iban}>
          <DataWithShortenedText maxTextLength={35} text={iban} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default EbicsIbanSelectForm;
