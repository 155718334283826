import React from 'react';

import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { pathsToObjektDetailsPage } from '../../Objekt/objektUriPaths';
import { generatePathToBestandseinheitDetailsPage } from '../../Bestandseinheit/bestandseinheitUriPaths';
import { generatePathToBeVertragDetailsPage } from '../../Vertrag/BeVertrag/beVertragUriPaths';
import { MitarbeiterTooltip } from '../../../components/Card';
import { useToggle } from '../../../hooks/useToggle';
import { EuroAmount } from '../../../components/Number';
import { DetailDrawer } from '../../Verarbeitung/DescriptionDevLog';
import { generateVOPDetailSections } from './VorschreibungVOPSections';
import SteuersatzAdminLink from '../../Vertrag/BeVertrag/Vertragsdaten/VorschreibungspositionTab/VorschreibungspositionWert/SteuersatzAdminLink';
import LinkWithStatus from '../../../components/Link/LinkWithStatus';
import DataWithShortenedTextAndExtraInfo from '../../../components/Helpers/DataWithShortenedTextAndExtraInfo';
import { compareTwoStringsForSorting } from '../../../helpers/stringHelper';
import { compareTwoNumbersForSorting } from '../../../helpers/numberHelper';
import { compareTwoDatesForSorting } from '../../../helpers/dateHelper';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { AuftragsPosition } from '../../../types';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';
import { generatePathToBookingDetailsPage } from '../../BookingDetails/bookingDetailsPaths';

const vorschreibungPositionTableColumns = (
  ausstellerRechtstraegerId: string,
  buchungIdList?: string[] | null
): TableWithColSelectorColumnProps<AuftragsPosition>[] => [
  {
    title: 'Vorschreibung',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.vorschreibungspositionBezeichnung, b.vorschreibungspositionBezeichnung),
    render: (text, record) => <DevLogDrawer record={record} />,
  },
  {
    title: 'Objekt',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.objekt.kurzBezeichnung, b.objekt.kurzBezeichnung),
    render: (text, record) => {
      return (
        <LinkWithStatus
          status={record.objekt.status}
          text={record.objekt.kurzBezeichnung}
          to={pathsToObjektDetailsPage(record.objektId).objektDetails}
          maxTextLength={12}
        />
      );
    },
  },
  {
    title: 'Bestandseinheit',
    defaultSelected: true,
    width: 100,
    sorter: (a, b) => compareTwoStringsForSorting(a.bestandseinheit.bezeichnung, b.bestandseinheit.bezeichnung),
    render: (text, record) => {
      return (
        <LinkWithStatus
          status={record.bestandseinheit.status}
          text={record.bestandseinheit.bezeichnung}
          to={generatePathToBestandseinheitDetailsPage(record.objektId, record.bestandseinheitId)}
          maxTextLength={12}
        />
      );
    },
  },
  {
    title: 'Vertrag',
    defaultSelected: true,
    width: 130,
    sorter: (a, b) => compareTwoStringsForSorting(a.vertragBezeichnung, b.vertragBezeichnung),
    render: (text, record) => {
      return (
        <DataWithShortenedTextAndExtraInfo text={record.vertragBezeichnung} maxTextLength={15}>
          {(shortenText) => (
            <Link to={generatePathToBeVertragDetailsPage(record.objektId, record.bestandseinheitId, record.vertragId)}>{shortenText}</Link>
          )}
        </DataWithShortenedTextAndExtraInfo>
      );
    },
  },
  {
    title: 'Leistungszeitraum',
    defaultSelected: true,
    sorter: (a, b) => compareTwoDatesForSorting(a.performancePeriodFromInclusive, b.performancePeriodFromInclusive),
    render: (text, record) => (
      <>
        <CustomFormattedDate value={record.performancePeriodFromInclusive} /> - <CustomFormattedDate value={record.performancePeriodToInclusive} />
      </>
    ),
  },
  {
    title: 'Netto',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.netto, b.netto),
    render: (text, record) => <EuroAmount value={record.netto} />,
  },
  {
    title: 'USt',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.ust, b.ust),
    render: (text, record) => <EuroAmount value={record.ust} />,
  },
  {
    title: 'Steuersatz',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.steuersatz, b.steuersatz),
    render: (text, record) => (
      <div style={{ justifyContent: 'right' }}>
        <SteuersatzAdminLink steuersatz={record.steuersatz} ustKategorieEntryId={record.ustKategorieEntryId} ustKategorieId={record.ustKategorieId} />
      </div>
    ),
  },
  {
    title: 'Brutto',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.brutto, b.brutto),
    render: (text, record) => {
      return buchungIdList && buchungIdList.length > 0 ? (
        <Link to={generatePathToBookingDetailsPage(buchungIdList, record.auftragId)} target="_blank">
          <EuroAmount value={record.brutto} />
        </Link>
      ) : (
        <EuroAmount value={record.brutto} />
      );
    },
  },
  {
    title: 'Erstellt am',
    sorter: (a, b) => compareTwoDatesForSorting(a.createTs, b.createTs),
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
];

const DevLogDrawer = (props: { record: AuftragsPosition }) => {
  const [isCollapsed, onCollapse] = useToggle();

  return (
    <>
      <DataWithShortenedTextAndExtraInfo text={props.record.vorschreibungspositionBezeichnung} maxTextLength={18}>
        {(shortenedText) => <Typography.Link onClick={onCollapse}>{shortenedText}</Typography.Link>}
      </DataWithShortenedTextAndExtraInfo>

      <DetailDrawer
        title={`DevLog ${props.record.bezeichnung}`}
        onCollapse={onCollapse}
        isCollapsed={isCollapsed}
        detailSections={generateVOPDetailSections(props.record)}
        loading={false}
      />
    </>
  );
};

export default vorschreibungPositionTableColumns;
