import React, { FC } from 'react';
import { Space } from 'antd';
import { UploadedFileView } from '../../../../../shared/UploadedFileView';
import { showSuccessMsgDelete } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { useDeleteVorschreibungMailDeliveryAttachmentMutation } from '../../../gql/VorschreibungMailDeliveryWizardMutations.types';
import { VorschreibungMailDeliveryWizardFileInfo } from '../../../../../../types';

type Props = {
  attachmentList?: VorschreibungMailDeliveryWizardFileInfo[];
  vorschreibungMailDeliveryId: string;
  vorschreibungMailDeliveryWizardId: string;
  isCompleted?: boolean;
  onAction: () => void;
};

const AttachmentPartUploadView: FC<Props> = ({
  attachmentList,
  onAction,
  vorschreibungMailDeliveryWizardId,
  vorschreibungMailDeliveryId,
  isCompleted,
}) => {
  const [runDelete] = useDeleteVorschreibungMailDeliveryAttachmentMutation({
    onCompleted: () => {
      showSuccessMsgDelete('File');
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const uploadedFileList = attachmentList?.map((attachment, index) => {
    const onDelete = () => {
      if (isCompleted || !attachment.deletable) return;
      runDelete({ variables: { vorschreibungMailDeliveryId, vorschreibungMailDeliveryWizardId, attachmentId: attachment.fileId } });
    };
    return (
      <UploadedFileView
        key={index}
        filename={attachment.name}
        filesize={attachment.size}
        onDelete={onDelete}
        disableDelete={isCompleted || !attachment.deletable}
      />
    );
  });

  return <Space direction="vertical">{uploadedFileList}</Space>;
};

export default AttachmentPartUploadView;
