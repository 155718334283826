import React, { FC } from 'react';
import { useEigenbelegForBuchungsmaskeQuery } from './gql/EigenbelegQueries.types';
import LoadingPage from '../../../components/Loading/LoadingPage';
import BookingDetails from '../../../features/BookingDetails/View/WithoutBookingSuggestion/BookingDetails';

type Props = {
  buchungIdList: string[];
  auftragId: string;
};

const BookingDetailsEigenbelegPage: FC<Props> = ({ buchungIdList, auftragId }) => {
  const { loading: loadingEigenbeleg, data: maybeEigenbeleg } = useEigenbelegForBuchungsmaskeQuery({
    variables: { auftragId },
    notifyOnNetworkStatusChange: true,
  });
  const eigenbeleg = maybeEigenbeleg?.getEigenbeleg.data;
  const buchungErrorList = eigenbeleg?.buchungErrorList || [];

  if (!eigenbeleg || loadingEigenbeleg) {
    return <LoadingPage loading={loadingEigenbeleg} />;
  }

  return <BookingDetails belegId={eigenbeleg.belegId} buchungIdList={buchungIdList} errorList={buchungErrorList} />;
};

export default BookingDetailsEigenbelegPage;
