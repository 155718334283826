import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useValuePreservationTypeListQuery } from './gql/ValuePreservationQueries.types';
import { selectFilterOption } from '../../../helpers/selectHelper';

const ValuePreservationTypeSelect: FC<SelectProps> = ({ ...restProps }) => {
  const { data, loading } = useValuePreservationTypeListQuery();

  const valuePreservationTypeList = data?.getValuePreservationTypeList.data || [];

  return (
    <Select
      id={restProps.name}
      loading={loading}
      placeholder="Wertsicherungsart auswählen"
      allowClear
      mode="multiple"
      showSearch
      filterOption={selectFilterOption}
      {...restProps}
    >
      {valuePreservationTypeList.map((valuePreservationType) => (
        <Select.Option key={valuePreservationType.value} value={valuePreservationType.value}>
          {valuePreservationType.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ValuePreservationTypeSelect;
