import { TIncomingInvoiceBookingCreationQueryParams } from './filtersQueryParams';
import { FormFields, mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';
import { ObjectOfStrings } from '../../../../../shared/typeHelpers';
import { GenerierlaufEntryExitCode, QueryGetIncomingInvoiceBookingCreationGenerierlaufEntryListArgs } from '../../../../../types';

export type FiltersFormValues = {
  exitCodeList?: GenerierlaufEntryExitCode[] | null;
  invoiceDateFrom?: string | null;
  invoiceDateTo?: string | null;
  invoiceNumber?: string | null;
  issuerId?: string | null;
  objektId?: string | null;
  payeeId?: string | null;
};

export const listingFiltersFormFields: FormFields<FiltersFormValues> = {
  exitCodeList: 'exitCodeList',
  invoiceDateFrom: 'invoiceDateFrom',
  invoiceDateTo: 'invoiceDateTo',
  invoiceNumber: 'invoiceNumber',
  issuerId: 'issuerId',
  objektId: 'objektId',
  payeeId: 'payeeId',
};

export const mapQueryParamsToFormValues = (queryParams: TIncomingInvoiceBookingCreationQueryParams): FiltersFormValues => ({
  exitCodeList: queryParams.exitCodeList,
  invoiceDateFrom: queryParams.invoiceDateFrom,
  invoiceDateTo: queryParams.invoiceDateTo,
  invoiceNumber: queryParams.invoiceNumber,
  issuerId: queryParams.issuerId,
  objektId: queryParams.objektId,
  payeeId: queryParams.payeeId,
});

export const mapFormValuesToQueryParams = (values: FiltersFormValues): TIncomingInvoiceBookingCreationQueryParams => ({
  exitCodeList: values.exitCodeList,
  invoiceDateFrom: values.invoiceDateFrom ? mapFormDateValueToDateString(values.invoiceDateFrom) : undefined,
  invoiceDateTo: values.invoiceDateTo ? mapFormDateValueToDateString(values.invoiceDateTo) : undefined,
  invoiceNumber: values.invoiceNumber,
  issuerId: values.issuerId,
  objektId: values.objektId,
  payeeId: values.payeeId,
});

export const mapQueryParamsToListQueryVariables = (
  generierlaufId: string,
  queryParams: TIncomingInvoiceBookingCreationQueryParams
): QueryGetIncomingInvoiceBookingCreationGenerierlaufEntryListArgs => ({
  ...queryParams,
  generierlaufId,
  invoiceNumber: queryParams.invoiceNumber?.toString(),
});

export const listingLabelList: ObjectOfStrings<Omit<FiltersFormValues, 'invoiceDateTo'>> = {
  exitCodeList: 'Exit Code Status',
  invoiceDateFrom: 'Rechnungsdatum',
  invoiceNumber: 'Rechnungsnummer',
  issuerId: 'Lieferant',
  objektId: 'Objekt',
  payeeId: 'Empfänger',
};
