import React, { FC } from 'react';
import { Modal } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Formik } from 'formik';
import { DatePicker, Form } from 'formik-antd';
import { showSuccessMsgFreigegeben } from '../../../../components/message/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../components/DatePicker/DatePicker_formikAntD';
import FormButtons from '../../../../components/Button/FormButtons';
import {
  eigenbelegFreigebenModalFormFields,
  EigenbelegFreigebenModalValues,
  mapFormValuesToEigenbelegFreigeben,
} from './eigenbelegFreigebenModalFormMapper';
import { useCreateEigenbelegErstellenGenerierlaufMutation } from '../../../Verarbeitung/gql/BelegGenerierlauf/BelegGenerierlaufMutations.types';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import { Auftrag } from '../../../../types';

type EigenbelegFreigebenModalProps = {
  auftrag: Auftrag;
  visible: boolean;
  onSuccess(): void;
  onCancel(): void;
};

const EigenbelegFreigebenModal: FC<EigenbelegFreigebenModalProps> = ({ auftrag, visible, onSuccess, onCancel }) => {
  const [runFreigeben, { loading }] = useCreateEigenbelegErstellenGenerierlaufMutation({
    onCompleted: () => {
      showSuccessMsgFreigegeben(auftrag.auftragsKopf.auftragsartType.text);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Modal
      open={visible}
      title={
        <>
          <QuestionCircleTwoTone twoToneColor="#FAAD14" />
          <span style={{ paddingLeft: '8px' }}>Möchten Sie den Beleg verbuchen?</span>
        </>
      }
      footer={null}
      onCancel={onCancel}
      destroyOnClose
      maskClosable={false}
    >
      <Formik<EigenbelegFreigebenModalValues>
        initialValues={{ belegdatum: dayjsCustom(), generierDatum: dayjsCustom() }}
        onSubmit={(values, { setSubmitting }) => {
          const input = mapFormValuesToEigenbelegFreigeben(values);
          runFreigeben({
            variables: {
              input,
            },
          }).finally(() => {
            setSubmitting(false);
          });
        }}
      >
        {(formikProps) => (
          <Form>
            <FormItemWithoutColon name={eigenbelegFreigebenModalFormFields.belegdatum} label="Rechnungsdatum">
              <DatePicker
                id={eigenbelegFreigebenModalFormFields.belegdatum}
                name={eigenbelegFreigebenModalFormFields.belegdatum}
                placeholder="Datum wählen"
                format={DATE_DISPLAY_FORMAT_DEFAULT}
                allowClear={false}
                size="small"
                style={{ width: '180px' }}
              />
            </FormItemWithoutColon>
            <FormButtons okText="Starten" onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} isOkLoading={loading} updateMode={false} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EigenbelegFreigebenModal;
