import { FetchResult } from '@apollo/client';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Button, Col, Divider, Drawer, Modal, Row, Skeleton, Space, Switch, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import FormButtons from '../../../components/Button/FormButtons';
import { TicketTemplate, TicketTemplatePeriodInput, WarningData } from '../../../types';
import {
  mapFormValuesToTicketTemplate,
  mapTicketTemplateToFormValues,
  ticketTemplateFormFields,
  TicketTemplateFormValues,
} from '../../TicketTemplate/Form/ticketTemplateFormMapper';
import TicketPrioritySelect from '../../Ticket/shared/TicketPrioritySelect';
import { ticketTemplateFormValidationSchema } from '../../TicketTemplate/Form/ticketTemplateFormValidationSchema';
import TicketPeriod from '../../TicketTemplate/Form/TicketPeriod';
import ErrorMessagePanel from '../../../components/Error/ErrorMessagePanel';
import { useMutationHookResultsProvider } from '../TicketTemplateTemplateMutationHookResultProvider';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';
import { useToggle } from '../../../hooks/useToggle';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';
import TicketTemplateTemplateSubTicketTable from '../Table/TicketTemplateTemplateSubTicketTable';
import { UpdateTicketTemplateTemplateMutationHookResult } from '../gql/TicketTemplateTemplateMutations.types';

type Props = {
  ticketTemplate?: TicketTemplate;
  ticketTemplateList?: TicketTemplate[];
  onSuccess: () => void;
  onCancel: () => void;
  setDrawerTitle?: Dispatch<SetStateAction<string>>;
  parentTicketTemplate?: TicketTemplate;
  loading?: boolean;
};

const TicketTemplateTemplateForm: FC<Props> = ({
  onCancel,
  ticketTemplate: ticketTemplateData,
  ticketTemplateList,
  onSuccess,
  setDrawerTitle,
  parentTicketTemplate,
  loading = false,
}) => {
  const getFieldHelpText = useGetAppFieldHelpText<'TicketTemplate'>('TicketTemplate');

  /**
   * TicketTemplate and TicketTemplateId props are used to determine if the form is in update mode or not.
   * This form can be in update mode without the ticketTemplate prop, if the ticketTemplateId is passed. In this case, the ticketTemplate is fetched from the ticketTemplateList.
   * This approach is used to switch from create mode to update mode, when creating a ticket then creating a subticket without closing the drawer.
   */
  const [ticketTemplate, setTicketTemplate] = useState(ticketTemplateData);
  const [ticketTemplateId, setTicketTemplateId] = useState<string | undefined>(ticketTemplate?.ticketTemplateId);
  const isUpdate = !!ticketTemplate;
  const isSubTicket = ticketTemplate?.isSubTicketTemplate || parentTicketTemplate;
  const isNewMainTicket = !isUpdate && !isSubTicket;
  const [hasError, setHasError] = useState(false);
  const [isCollapsed, toggleIsCollapsed] = useToggle();
  const [showCreateSubticket, setShowCreateSubTicket] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    if (!isNewMainTicket) setShowCreateSubTicket(false);
    setDrawerTitle?.(isUpdate ? 'bearbeiten' : 'anlegen');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewMainTicket]);

  useEffect(() => {
    setTicketTemplate(ticketTemplateData);
  }, [ticketTemplateData]);

  useEffect(() => {
    if (ticketTemplate) {
      setTicketTemplateId(ticketTemplate?.ticketTemplateId);
    }
  }, [ticketTemplate]);

  useEffect(() => {
    const ticketTemplate = ticketTemplateList?.find((ticketTemplate) => ticketTemplate.ticketTemplateId === ticketTemplateId);
    if (!ticketTemplate) return;
    setTicketTemplate(ticketTemplate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketTemplateList]);

  const {
    runCreate: [runCreate, { loading: loadingCreate }],
    runUpdate: [runUpdate, { loading: loadingUpdate }],
    runCreateSubTicketTemplate: [runCreateSubTicketTemplate, { loading: loadingCreateSubTicket }],
  } = useMutationHookResultsProvider();

  const isLoading = loadingCreate || loadingUpdate || loadingCreateSubTicket || loading;

  return (
    <Skeleton loading={loading && showCreateSubticket} active>
      <Formik<TicketTemplateFormValues>
        key={JSON.stringify(ticketTemplate)}
        initialValues={mapTicketTemplateToFormValues(ticketTemplate)}
        validationSchema={ticketTemplateFormValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setHasError(false);
          if (nameAlreadyExists(values, isUpdate, ticketTemplateList, ticketTemplate?.ticketTemplateId)) {
            setHasError(true);
            setSubmitting(false);
            return;
          }

          if (!isUpdate) {
            if (!parentTicketTemplate) {
              runCreate({ variables: { input: mapFormValuesToTicketTemplate(values) } })
                .then(({ data }) => {
                  if (!showCreateSubticket) {
                    onSuccess();
                  } else {
                    const ticketTemplateId = data?.createTicketTemplateTemplate.data.ticketTemplateId;
                    if (ticketTemplateId) {
                      setTicketTemplateId(ticketTemplateId);
                    }
                  }
                })
                .then(() => setHasChanged(false))
                .finally(() => setSubmitting(false));
            } else {
              runCreateSubTicketTemplate({
                variables: { ticketTemplateId: parentTicketTemplate.ticketTemplateId, input: mapFormValuesToTicketTemplate(values) },
              })
                .then((response) => handleResponseWarnings<typeof response>(response, values, parentTicketTemplate, runUpdate, onSuccess))
                .then(onSuccess)
                .finally(() => setSubmitting(false));
            }
          } else {
            runUpdate({
              variables: {
                ticketTemplateId: ticketTemplate.ticketTemplateId,
                input: mapFormValuesToTicketTemplate(values),
              },
            })
              .then((response) => handleResponseWarnings<typeof response>(response, values, parentTicketTemplate, runUpdate, onSuccess))
              .then(onSuccess)
              .finally(() => setSubmitting(false));
          }
        }}
      >
        {(formikProps) => (
          <Form layout="vertical">
            {hasError && <ErrorMessagePanel error="Mit diesen Bezeichnung existiert bereits ein Eintrag" />}
            <Row align="top">
              <Col span={6}>
                <Typography.Title level={5}>Aufgabenart</Typography.Title>
              </Col>
              <Col span={18}>
                <FormItemWithFieldHelp name={ticketTemplateFormFields.name} label="Bezeichnung" fieldHelp={getFieldHelpText('TicketTemplate.name')}>
                  <Input
                    onChange={() => setHasChanged(true)}
                    id={ticketTemplateFormFields.name}
                    name={ticketTemplateFormFields.name}
                    placeholder="Bezeichnung"
                    prefix={<span />}
                  />
                </FormItemWithFieldHelp>
                <FormItemWithFieldHelp
                  name={ticketTemplateFormFields.priority}
                  label="Priorität"
                  fieldHelp={getFieldHelpText('TicketTemplate.priority')}
                >
                  <TicketPrioritySelect size="middle" name={ticketTemplateFormFields.priority} onChange={() => setHasChanged(true)} />
                </FormItemWithFieldHelp>
              </Col>
            </Row>
            <Row align="top">
              <Col span={6}>
                <Typography.Title level={5}>Terminvorgaben</Typography.Title>
              </Col>
              <Col span={18}>
                <FormItemWithoutColon name={`${ticketTemplateFormFields.dueDate}`} label="Fälligkeit" help={null} style={{ marginBottom: 0 }}>
                  <TicketPeriod
                    allowClear
                    onChange={() => setHasChanged(true)}
                    name={ticketTemplateFormFields.dueDate}
                    fieldHelp={{
                      amount: getFieldHelpText('TicketTemplate.dueDate.amount'),
                      unit: getFieldHelpText('TicketTemplate.dueDate.unit'),
                    }}
                  />
                </FormItemWithoutColon>
                <FormItemWithoutColon name={`${ticketTemplateFormFields.reminder}`} label="Erinnerung" help={null} style={{ marginBottom: 0 }}>
                  <TicketPeriod
                    allowClear
                    onChange={() => setHasChanged(true)}
                    name={ticketTemplateFormFields.reminder}
                    fieldHelp={{
                      amount: getFieldHelpText('TicketTemplate.reminder.amount'),
                      unit: getFieldHelpText('TicketTemplate.reminder.unit'),
                    }}
                  />
                </FormItemWithoutColon>
              </Col>
            </Row>
            {isUpdate && !isSubTicket && (
              <>
                <Divider />
                <Typography.Title level={5}>Unteraufgabenarten</Typography.Title>
                <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
                  <Button icon={<PlusOutlined />} type="primary" onClick={toggleIsCollapsed}>
                    Neu anlegen
                  </Button>
                </ButtonsAligned>
                <Drawer title="Unteraufgabenart anlegen" width={720} onClose={toggleIsCollapsed} open={isCollapsed} destroyOnClose>
                  <TicketTemplateTemplateForm
                    parentTicketTemplate={ticketTemplate}
                    ticketTemplateList={ticketTemplate.subTicketTemplateList ?? []}
                    onSuccess={() => toggleIsCollapsed(false)}
                    onCancel={() => toggleIsCollapsed(false)}
                  />
                </Drawer>
              </>
            )}
            {isNewMainTicket && (
              <Space style={{ alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                <Switch size="small" onClick={() => setShowCreateSubTicket(!showCreateSubticket)} title="Unteraufgabenart anlegen" />
                <Typography.Text>Unteraufgabenart anlegen</Typography.Text>
              </Space>
            )}
            {isUpdate && !isSubTicket && (
              <TicketTemplateTemplateSubTicketTable
                style={{ marginBottom: 16 }}
                ticketTemplateList={ticketTemplate?.subTicketTemplateList ?? []}
                parentTicketTemplate={ticketTemplate}
              />
            )}
            {!isUpdate || hasChanged ? (
              <FormButtons updateMode={isUpdate} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} isOkLoading={isLoading} />
            ) : (
              <Space direction="vertical" style={{ width: '100%', justifyContent: 'flex-end' }}>
                <Divider style={{ margin: '8px 0' }} />
                <ButtonsAligned usage={ButtonsAlignedUsage.drawerFooter}>
                  <Button onClick={onCancel}>Schließen</Button>
                </ButtonsAligned>
              </Space>
            )}
          </Form>
        )}
      </Formik>
    </Skeleton>
  );
};

const nameAlreadyExists = (values: TicketTemplateFormValues, isUpdate: boolean, ticketTemplateList?: TicketTemplate[], ticketTemplateId?: string) =>
  ticketTemplateList?.find((ticketTemplate) => {
    const isDuplicate = ticketTemplate.name === values.name;

    if (!isUpdate) {
      return isDuplicate;
    } else {
      const isSame = ticketTemplateId === ticketTemplate.ticketTemplateId;
      return isDuplicate && !isSame;
    }
  });

function handleResponseWarnings<T extends FetchResult>(
  response: T,
  formValues: TicketTemplateFormValues,
  parentTicketTemplate: TicketTemplate | undefined | null,
  runUpdate: UpdateTicketTemplateTemplateMutationHookResult[0],
  onSuccess: () => void
) {
  const data = response.data;
  let dueDateText;
  if (!data) return;
  let warningList: WarningData[] = [];
  if ('updateTicketTemplateTemplate' in data) {
    warningList = data.updateTicketTemplateTemplate?.warningList;
    dueDateText = data.updateTicketTemplateTemplate?.data?.dueDateText;
  } else if ('actionTicketTemplateTemplateCreateSubTicketTemplate' in data) {
    warningList = data.actionTicketTemplateTemplateCreateSubTicketTemplate?.warningList;
    dueDateText = data.actionTicketTemplateTemplateCreateSubTicketTemplate?.data?.dueDateText;
  }
  const warning = warningList.find((warning) => warning.type === 'DUE_DATE_IS_AFTER_PARENTS_DUE_DATE');
  if (!warning || !parentTicketTemplate || !dueDateText) return;

  const dueDate = mapFormValuesToTicketTemplate(formValues).dueDate as TicketTemplatePeriodInput;

  const onOk = () =>
    runUpdate({
      variables: {
        ticketTemplateId: parentTicketTemplate.ticketTemplateId,
        input: {
          ...mapFormValuesToTicketTemplate(mapTicketTemplateToFormValues(parentTicketTemplate)),
          dueDate,
        },
      },
    }).then(onSuccess);

  showConfirmUpdateParent(dueDateText, onOk);
}

const showConfirmUpdateParent = (dueDate: string, onOk: () => Promise<void>) => {
  Modal.confirm({
    title: 'Fälligkeit der Hauptaufgabe ändern?',
    content: `Die Fälligkeit einer Unteraufgabe ist nach der Fälligkeit der Hauptaufgabe ausgerichtet. Möchten Sie die Fälligkeit der Hauptaufgabe ebenfalls auf ${dueDate} ändern?`,
    okText: 'Ja',
    cancelText: 'Nein',
    onOk,
  });
};

export default TicketTemplateTemplateForm;
