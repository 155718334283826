import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { ContactPersonFieldsFragmentDoc, ContactFieldsFragmentDoc } from '../../../Contact/gql/ContactFragments.types';
import { AddressFieldsFragmentDoc } from '../../../Address/gql/AddressFragments.types';
export type AddressesAndContactsFragment = {
  contactPersonList: Array<{
    contactPersonId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    firstname: string;
    lastname: string;
    tagList: Array<string>;
    title?: string | null;
    titleTrailing?: string | null;
    contactList: Array<{
      contactId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      haupt?: boolean | null;
      tagList: Array<string>;
      type: string;
      wert: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    rechtstraeger?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }>;
  emailContactList: Array<{
    contactId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    haupt?: boolean | null;
    tagList: Array<string>;
    type: string;
    wert: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }>;
  hauptAddress?: {
    addressId: string;
    city: string;
    cityAdditionalInformation?: string | null;
    countryCodeIso2: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    haupt?: boolean | null;
    houseEntranceApartmentNumber?: string | null;
    postofficeBoxNumber?: string | null;
    postofficeBoxZipCode?: string | null;
    street?: string | null;
    tagList: Array<string>;
    type: Types.AddressType;
    zipCode?: string | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  hauptEmailContact?: {
    contactId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    haupt?: boolean | null;
    tagList: Array<string>;
    type: string;
    wert: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  hauptPhoneContact?: {
    contactId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    haupt?: boolean | null;
    tagList: Array<string>;
    type: string;
    wert: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  postofficeBoxAddressList: Array<{
    addressId: string;
    city: string;
    cityAdditionalInformation?: string | null;
    countryCodeIso2: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    haupt?: boolean | null;
    houseEntranceApartmentNumber?: string | null;
    postofficeBoxNumber?: string | null;
    postofficeBoxZipCode?: string | null;
    street?: string | null;
    tagList: Array<string>;
    type: Types.AddressType;
    zipCode?: string | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }>;
  streetAddressList: Array<{
    addressId: string;
    city: string;
    cityAdditionalInformation?: string | null;
    countryCodeIso2: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    haupt?: boolean | null;
    houseEntranceApartmentNumber?: string | null;
    postofficeBoxNumber?: string | null;
    postofficeBoxZipCode?: string | null;
    street?: string | null;
    tagList: Array<string>;
    type: Types.AddressType;
    zipCode?: string | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }>;
  phoneContactList: Array<{
    contactId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    haupt?: boolean | null;
    tagList: Array<string>;
    type: string;
    wert: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }>;
};

export const AddressesAndContactsFragmentDoc = gql`
  fragment AddressesAndContacts on AddressesAndContacts {
    contactPersonList {
      ...ContactPersonFields
    }
    emailContactList {
      ...ContactFields
    }
    hauptAddress {
      ...AddressFields
    }
    hauptEmailContact {
      ...ContactFields
    }
    hauptPhoneContact {
      ...ContactFields
    }
    postofficeBoxAddressList {
      ...AddressFields
    }
    streetAddressList {
      ...AddressFields
    }
    phoneContactList {
      ...ContactFields
    }
  }
  ${ContactPersonFieldsFragmentDoc}
  ${ContactFieldsFragmentDoc}
  ${AddressFieldsFragmentDoc}
`;
