export enum SortOrder {
  NoSorting = 'NO_SORTING',
  Asc = 'ASC',
  Desc = 'DESC',
}

export const isNoSorting = (sortOrder: SortOrder) => sortOrder === SortOrder.NoSorting;
export const isSortingAsc = (sortOrder: SortOrder) => sortOrder === SortOrder.Asc;
export const isSortingDesc = (sortOrder: SortOrder) => sortOrder === SortOrder.Desc;

export const shouldSortTheSameColumn = (columnIndex: number, sortedColumnIndex: number) => columnIndex === sortedColumnIndex;
export const isColumnSortedAsc = (columnIndex: number, sortedColumnIndex: number, sortOrder: SortOrder) =>
  shouldSortTheSameColumn(columnIndex, sortedColumnIndex) && isSortingAsc(sortOrder);
export const isColumnSortedDesc = (columnIndex: number, sortedColumnIndex: number, sortOrder: SortOrder) =>
  shouldSortTheSameColumn(columnIndex, sortedColumnIndex) && isSortingDesc(sortOrder);
export const isColumnSorted = (columnIndex: number, sortedColumnIndex: number, sortOrder: SortOrder) =>
  isColumnSortedAsc(columnIndex, sortedColumnIndex, sortOrder) || isColumnSortedDesc(columnIndex, sortedColumnIndex, sortOrder);

export const setSortOrder = (index: number, sortOrder: SortOrder, sortedColumnIndex: number) => {
  //sortOrder if sorting related to the same column
  if (shouldSortTheSameColumn(index, sortedColumnIndex)) {
    if (isNoSorting(sortOrder)) {
      return SortOrder.Asc;
    }
    if (isSortingAsc(sortOrder)) {
      return SortOrder.Desc;
    }
    return SortOrder.NoSorting;
  }

  //sortOrder if sorting should be based on a different column than before
  return SortOrder.Asc;
};

// ----------- Tooltip for Sorting ----------- //

enum sortingTooltipText {
  forAscSorting = 'Klicken zur aufsteigenden Sortierung',
  forDescSorting = 'Klicken zur absteigenden Sortierung',
  forNoSorting = 'Klicken zum Abbrechen der Sortierung',
}

export const tooltipTitleForSorting = (index: number, sortedColumnIndex: number, sortOrder: SortOrder) =>
  shouldSortTheSameColumn(index, sortedColumnIndex) ? tooltipTextForNextSorting(sortOrder) : sortingTooltipText.forAscSorting;

//Tooltip should show the message for the next sort order type
//TODO in future - as the tooltip text is hardcoded in German and not depending on the locally defined language at the moment,
// we should seek for the solution like it is in origin antd component where the tooltip text depends on the locally chosen language
const tooltipTextForNextSorting = (sortOrder: SortOrder) => {
  if (isSortingAsc(sortOrder)) {
    return sortingTooltipText.forDescSorting;
  }
  if (isSortingDesc(sortOrder)) {
    return sortingTooltipText.forNoSorting;
  }
  return sortingTooltipText.forAscSorting;
};
