import { QueryHookOptions, QueryResult } from '@apollo/client';

type PagingAndSortingQueryVariables = {
  page?: number | null;
  pageSize?: number | null;
  order?: string | null;
  orderDir?: string | null;
};

export const useQueryWithPolling = <QueryResponse, QueryVariables extends PagingAndSortingQueryVariables>(
  queryHook: (baseOptions: QueryHookOptions<QueryResponse, QueryVariables>) => QueryResult<QueryResponse, QueryVariables>,
  baseOptions?: QueryHookOptions<QueryResponse, QueryVariables>,
  pollingOptions?: { shouldPoll: (data: QueryResponse | undefined) => boolean }
) => {
  const queryResult = queryHook({
    ...baseOptions,
  });

  if (pollingOptions?.shouldPoll(queryResult.data)) {
    queryResult.startPolling(1000);
  } else {
    queryResult.stopPolling();
  }

  return queryResult;
};
