import styled from 'styled-components';
import { Checkbox } from 'antd';

export const StyledCheckboxForFilter = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;

  & .ant-checkbox-wrapper {
    max-width: 225px;
    margin-left: 0 !important;
  }
  & .ant-checkbox {
    align-self: flex-start;
    margin-top: 3px;

    & + span {
      max-width: 100%;
      overflow: hidden;
      padding-right: 0;
    }
  }
`;
