import React, { FC } from 'react';
import { Modal, Tag } from 'antd';
import { showSuccessMsgOther } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import DataWithStatus from '../../../../../../components/Helpers/DataWithStatus';
import { useSetAbrechnungsdefinitionForRechnungsausstellerMutation } from '../../gql/RechnungsAusstellerMutations.types';
import MitarbeiterTooltip from '../../../../../../components/Card/MitarbeiterTooltip';
import { TableWithColSelectorColumnProps } from '../../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { AbrechnungsdefinitionType } from '../../../../../../types';
import { TRechnAusstellerAbrechnungsdefinition } from './RechnungsAusstellerAbrDefTable';
import {
  isAbrDefTypeBkAbrechnung,
  isAbrDefTypeHeAbrechnung,
  isAbrDefTypeSubAbrechnung,
} from '../../../../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import FormattedDateTime from '../../../../../../components/FormattedDateTime/FormattedDateTime';

const rechnungsAusstellerAbrDefTableColumns = (
  onSuccess: () => void,
  rechtstraegerId: string
): TableWithColSelectorColumnProps<TRechnAusstellerAbrechnungsdefinition>[] => [
  {
    title: 'Bezeichnung',
    defaultSelected: true,
    render: (text, record) => <DataWithStatus status={record.status} text={record.bezeichnung} />,
  },
  {
    title: 'Kategorie/Typ',
    defaultSelected: true,
    render: (text, record) => {
      let tagText = 'WE Abrechnung';
      if (isAbrDefTypeHeAbrechnung(record.type)) {
        tagText = 'HE Abrechnung';
      }
      if (isAbrDefTypeBkAbrechnung(record.type)) {
        tagText = 'BK Abrechnung';
      }
      if (isAbrDefTypeSubAbrechnung(record.type)) {
        tagText = 'SUB Abrechnung';
      }

      return <Tag>{tagText}</Tag>;
    },
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => <RechnungsAusstellerAbrDefActions onSuccess={onSuccess} rechtstraegerId={rechtstraegerId} abrDefType={record.type} />,
  },
];

type Props = {
  onSuccess: () => void;
  rechtstraegerId: string;
  abrDefType: AbrechnungsdefinitionType;
};

const RechnungsAusstellerAbrDefActions: FC<Props> = ({ onSuccess, rechtstraegerId, abrDefType }) => {
  const [deleteAbrDefZuweisung] = useSetAbrechnungsdefinitionForRechnungsausstellerMutation({
    variables: { rechtstraegerId, input: { type: abrDefType, abrechnungsdefinitionId: '' } },
    onCompleted: () => {
      showSuccessMsgOther(`Abrechnungsdefinitionzuweisung erfolgreich gelöscht.`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return <a onClick={showConfirmDelete(deleteAbrDefZuweisung)}>Löschen</a>;
};

const showConfirmDelete = (runDelete: () => void) => () => {
  Modal.confirm({
    title: 'Möchten Sie die Zuweisung löschen?',
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default rechnungsAusstellerAbrDefTableColumns;
