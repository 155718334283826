import { GetRowKey } from 'rc-table/lib/interface';
import { EditableTableRecordType } from './editTableProps';
import { NEW_RECORD_KEY } from '../EditableTableOld/editableTableReducer';

export type EditableTableState<TableRecordType> = {
  records: EditableTableRecordType<TableRecordType>[];
  selectedRecord: EditableTableRecordType<TableRecordType> | null;
  isSubmitLoading: boolean;
  getRowKey: GetRowKey<TableRecordType>;
};

export type EditableTableAction<TableRecordType> =
  | { type: 'INIT_RECORDS'; records: TableRecordType[] }
  | { type: 'ADD_RECORD'; record: TableRecordType }
  | { type: 'SELECT_RECORD'; selectedRecord: EditableTableRecordType<TableRecordType> }
  | { type: 'CANCEL_EDIT' }
  | { type: 'SET_SUBMIT_LOADING'; isSubmitLoading: boolean }
  | { type: 'SUBMIT_SUCCESS'; updatedRecord: TableRecordType };

function editableTableReducer<TableRecordType>(
  state: EditableTableState<TableRecordType>,
  action: EditableTableAction<TableRecordType>
): EditableTableState<TableRecordType> {
  switch (action.type) {
    case 'INIT_RECORDS':
      return {
        ...state,
        records: action.records.map((item) => addKeyToRecord(item, state.getRowKey)),
      };
    case 'ADD_RECORD':
      // eslint-disable-next-line no-case-declarations
      const newRecord = { ...action.record, recordKey: NEW_RECORD_KEY };
      return {
        ...state,
        selectedRecord: newRecord,
        records: [newRecord, ...state.records],
      };
    case 'SELECT_RECORD':
      return {
        ...state,
        selectedRecord: action.selectedRecord,
      };
    case 'CANCEL_EDIT':
      return {
        ...state,
        selectedRecord: null,
        records: state.records.filter((record) => record.recordKey !== NEW_RECORD_KEY),
      };
    case 'SET_SUBMIT_LOADING':
      return {
        ...state,
        isSubmitLoading: action.isSubmitLoading,
      };
    case 'SUBMIT_SUCCESS':
      return {
        ...state,
        selectedRecord: null,
        records: state.records.map((record) => {
          if (record.recordKey === NEW_RECORD_KEY || record.recordKey === addKeyToRecord(action.updatedRecord, state.getRowKey).recordKey) {
            return addKeyToRecord(action.updatedRecord, state.getRowKey);
          } else {
            return record;
          }
        }),
      };
    default:
      throw new Error(`unsupported editable table action: ${action}`);
  }
}

const addKeyToRecord = <TableRecordType>(
  record: TableRecordType,
  getRowKey: GetRowKey<TableRecordType>
): EditableTableRecordType<TableRecordType> => ({
  ...record,
  recordKey: getRowKey(record),
});

export default editableTableReducer;
