import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import {
  mapQueryParamsToPaymentProposalCreationGenerierlauf,
  PaymentProposalCreateFormValues,
  paymentProposalFormFields,
  paymentProposalInitialValues,
} from './createFormMapper';
import FormButtons from '../../../../../components/Button/FormButtons';
import PaymentProposalNameFormItem from './PaymentProposalNameFormItem';
import { useCreatePaymentProposalCreationGenerierlaufMutation } from '../../../../Verarbeitung/gql/PaymentProposalGenerierlauf/PaymentProposalGenerierlaufMutations.types';
import { showErrorMessage, showSuccessMsgOther } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { TPaymentQueryParams } from '../../../Listing/Filters/filtersQueryParams';
import { PAYMENT_PROPOSAL_ID_NOT_ASSIGNED } from '../../../Listing/Filters/paymentListingFiltersFormMapper';
import ZahlungsverkehrsartFormItem from './ZahlungsverkehrsartFormItem';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
  queryParams: TPaymentQueryParams;
  excludeList: string[];
};

const CreateForm: FC<Props> = ({ onSuccess, onCancel, queryParams, excludeList }) => {
  const [runCreatePaymentProposal] = useCreatePaymentProposalCreationGenerierlaufMutation({
    onCompleted: () => {
      showSuccessMsgOther('Zahlungsvorschlag wird angelegt');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<PaymentProposalCreateFormValues>
      initialValues={paymentProposalInitialValues}
      onSubmit={(values, { setSubmitting }) => {
        if (queryParams.paymentProposalId && queryParams.paymentProposalId !== PAYMENT_PROPOSAL_ID_NOT_ASSIGNED) {
          showErrorMessage(
            '',
            'Der Zahlungsvorschlag kann nicht erstellt werden, solange in den Filterkriterien ein Zahlungsvorschlag ausgewählt ist..'
          );
          setSubmitting(false);
          onCancel();
        } else {
          const input = mapQueryParamsToPaymentProposalCreationGenerierlauf(values, queryParams, excludeList);
          runCreatePaymentProposal({
            variables: { input },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <PaymentProposalNameFormItem formikProps={formikProps} />
          <ZahlungsverkehrsartFormItem formikProps={formikProps} name={paymentProposalFormFields.ebicsOnly} />
          <FormButtons onCancel={onCancel} updateMode={false} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default CreateForm;
