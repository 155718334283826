import React from 'react';
import { FormikProps } from 'formik';
import { DeleteOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/es/table';
import FormItemWithTooltip from '../../../../../components/Form/FormItemWithTooltip';
import { KontoKlasse, Verwaltungsart } from '../../../../../types';
import { isMietObjekt, isWegObjekt } from '../../../../Objekt/objektHelper';
import {
  VorsteuerkuerzungKontenZuweisungFormValues,
  VorsteuerkuerzungKontenZuweisungVersionFormValues,
  vstKuerzungKontenZuordnungVersionFormFields,
  vstKuerzungKontenZuweisungFormFields,
  vstKuerzungKontenZuweisungFormValuesDescriptions,
} from '../../../../Vorsteuerkuerzung/KontenZuweisung/Version/Form/vstKuerzungKontenZuweisungVersionFormMapper';
import AufwandsTemplateKontenSelect from '../../../../shared/AufwandsTemplateKontenSelect';
import AufteilungsschluesselTemplateSelectColumn from './columns/AufteilungsschluesselTemplateSelectColumn';

const vstKuerzungTemplateKontenZuweisungTableColumns = (
  formikProps: FormikProps<VorsteuerkuerzungKontenZuweisungVersionFormValues>,
  onDelete: (index: number) => void,
  verwaltungsart: Verwaltungsart
): ColumnProps<VorsteuerkuerzungKontenZuweisungFormValues>[] => {
  const columns: ColumnProps<VorsteuerkuerzungKontenZuweisungFormValues>[] = [
    {
      title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).aufwandsKontoIdList,
      key: vstKuerzungKontenZuweisungFormFields.aufwandsKontoIdList,
      width: isWegObjekt(verwaltungsart) ? 220 : 180,
      render: (text, record, index) => {
        const name = `${vstKuerzungKontenZuordnungVersionFormFields.kontenZuweisungList}.${index}.${vstKuerzungKontenZuweisungFormFields.aufwandsKontoIdList}`;

        const aufwandskontenIds = formikProps.values.kontenZuweisungList.map((konten) => konten.aufwandsKontoIdList).flat();
        const excludedAufwandsKontenIds = aufwandskontenIds.filter((kontoId) => !record.aufwandsKontoIdList.includes(kontoId));

        const vorsteuerkuerzungKontoId = formikProps.values.kontenZuweisungList?.map((konto) => konto.vorsteuerkuerzungKontoId).flat();
        const vorsteuerkuerzungEigennutzungKontoId = formikProps.values.kontenZuweisungList
          ?.map((konto) => konto.vorsteuerkuerzungEigennutzungKontoId)
          .flat()
          .filter((konto) => konto) as string[];

        const vorsteuerkuerzungPauschalmieterKontoId = formikProps.values.kontenZuweisungList
          ?.map((konto) => konto.vorsteuerkuerzungPauschalmieterKontoId)
          .flat()
          .filter((konto) => konto) as string[];

        const excludedKontenIds = [
          ...excludedAufwandsKontenIds,
          ...vorsteuerkuerzungKontoId,
          ...vorsteuerkuerzungEigennutzungKontoId,
          ...vorsteuerkuerzungPauschalmieterKontoId,
        ];

        return (
          <FormItemWithTooltip name={name}>
            <AufwandsTemplateKontenSelect
              key={index}
              name={name}
              mode="multiple"
              excludedKontenIds={excludedKontenIds}
              showKontoNummerAsTag={false}
              allowClear={false}
              klasseList={[KontoKlasse.HandelswarenEinsatz, KontoKlasse.PersonalAufwand, KontoKlasse.BetrieblicherAufwand, KontoKlasse.FinanzAufwand]}
            />
          </FormItemWithTooltip>
        );
      },
    },
    {
      title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).aufteilungsschluesselId,
      width: isWegObjekt(verwaltungsart) ? 220 : 160,
      key: vstKuerzungKontenZuweisungFormFields.aufteilungsschluesselId,
      render: (text, record, index) => <AufteilungsschluesselTemplateSelectColumn index={index} formikProps={formikProps} />,
      // render: (text, record, index) => <>f</>,
    },
    {
      title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).vorsteuerkuerzungKontoId,
      width: isWegObjekt(verwaltungsart) ? 200 : 160,
      key: vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungKontoId,
      render: (text, record, index) => {
        const name = `${vstKuerzungKontenZuordnungVersionFormFields.kontenZuweisungList}.${index}.${vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungKontoId}`;
        const excludedKontenIds = getExcludedVstKuerzungKontoIdsWithSameAuftSchluesselId(
          formikProps,
          index,
          vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungKontoId
        );

        return (
          <FormItemWithTooltip name={name}>
            <AufwandsTemplateKontenSelect
              key={index}
              name={name}
              excludedKontenIds={excludedKontenIds}
              showKontoNummerAsTag={false}
              klasseList={[KontoKlasse.HandelswarenEinsatz, KontoKlasse.PersonalAufwand, KontoKlasse.BetrieblicherAufwand, KontoKlasse.FinanzAufwand]}
              allowClear={false}
            />
          </FormItemWithTooltip>
        );
      },
    },
    {
      title: 'Aktion',
      width: 70,
      align: 'center',
      render: (text, record, index) => <DeleteOutlined onClick={() => onDelete(index)} />,
    },
  ];

  if (isMietObjekt(verwaltungsart)) {
    columns.splice(INDEX_POSITION_OF_EIGENNUTZUNG, 0, {
      title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).vorsteuerkuerzungEigennutzungKontoId,
      width: 160,
      key: vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungEigennutzungKontoId,
      render: (text, record, index) => {
        const name = `${vstKuerzungKontenZuordnungVersionFormFields.kontenZuweisungList}.${index}.${vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungEigennutzungKontoId}`;
        const excludedKontenIds = getExcludedVstKuerzungKontoIdsWithSameAuftSchluesselId(
          formikProps,
          index,
          vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungEigennutzungKontoId
        );

        return (
          <FormItemWithTooltip name={name}>
            <AufwandsTemplateKontenSelect
              key={index}
              name={name}
              excludedKontenIds={excludedKontenIds}
              showKontoNummerAsTag={false}
              klasseList={[KontoKlasse.HandelswarenEinsatz, KontoKlasse.PersonalAufwand, KontoKlasse.BetrieblicherAufwand, KontoKlasse.FinanzAufwand]}
              allowClear={false}
            />
          </FormItemWithTooltip>
        );
      },
    });

    columns.splice(INDEX_POSITION_OF_PAUSCHALMIETER, 0, {
      title: vstKuerzungKontenZuweisungFormValuesDescriptions(verwaltungsart).vorsteuerkuerzungPauschalmieterKontoId,
      width: 160,
      key: vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungPauschalmieterKontoId,
      render: (text, record, index) => {
        const name = `${vstKuerzungKontenZuordnungVersionFormFields.kontenZuweisungList}.${index}.${vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungPauschalmieterKontoId}`;
        const excludedKontenIds = getExcludedVstKuerzungKontoIdsWithSameAuftSchluesselId(
          formikProps,
          index,
          vstKuerzungKontenZuweisungFormFields.vorsteuerkuerzungPauschalmieterKontoId
        );

        return (
          <FormItemWithTooltip name={name}>
            <AufwandsTemplateKontenSelect
              key={index}
              name={name}
              excludedKontenIds={excludedKontenIds}
              showKontoNummerAsTag={false}
              klasseList={[KontoKlasse.HandelswarenEinsatz, KontoKlasse.PersonalAufwand, KontoKlasse.BetrieblicherAufwand, KontoKlasse.FinanzAufwand]}
              allowClear={false}
            />
          </FormItemWithTooltip>
        );
      },
    });
  }

  return columns;
};

const getExcludedVstKuerzungKontoIdsWithSameAuftSchluesselId = (
  formikProps: FormikProps<VorsteuerkuerzungKontenZuweisungVersionFormValues>,
  index: number,
  name: keyof typeof vstKuerzungKontenZuweisungFormFields
) => {
  const aufwandsKontenIds = formikProps.values.kontenZuweisungList?.map((konto) => konto.aufwandsKontoIdList).flat();

  const excludedVstKuerzungKontoIdsWithSameAuftSchluesselId = formikProps.values.kontenZuweisungList
    .filter((konto) => konto.aufteilungsschluesselId === formikProps.values.kontenZuweisungList[index].aufteilungsschluesselId)
    .map((konto) => konto[name])
    .filter((kontoId) => kontoId !== formikProps.values.kontenZuweisungList[index][name])
    .filter((kontoId) => kontoId) as string[];

  return [...excludedVstKuerzungKontoIdsWithSameAuftSchluesselId, ...aufwandsKontenIds];
};

const INDEX_POSITION_OF_EIGENNUTZUNG = 3;
const INDEX_POSITION_OF_PAUSCHALMIETER = 4;

export default vstKuerzungTemplateKontenZuweisungTableColumns;
