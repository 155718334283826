import React, { FC } from 'react';
import { Col, Row, Space, Typography } from 'antd';
import { useFirmendatenBankDetailsQuery, useRechtstraegerBankDetailsQuery } from '../../../BankDetails/gql/BankDetailsQueries.types';
import { ObjBankDetailsRechnungsausstellerType } from './objektBankDetailsFormMapper';
import { BankDetails, FirmendatenBankDetails } from '../../../../types';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';

type Props = {
  tempRechnungsausstellerType?: ObjBankDetailsRechnungsausstellerType;
  bankDetailsId: string;
  rechtstraegerId: string;
  firmendatenId: string;
  weRechnungsAusstellerId?: string;
  objBankDetails?: BankDetails;
};

const BankDetailsAccountInfo: FC<Props> = ({
  bankDetailsId,
  rechtstraegerId,
  firmendatenId,
  tempRechnungsausstellerType,
  objBankDetails,
  weRechnungsAusstellerId,
}) => {
  const { data: maybeFirmendatenData } = useFirmendatenBankDetailsQuery({
    variables: { firmendatenId, bankDetailsId },
    skip: !bankDetailsId || tempRechnungsausstellerType !== ObjBankDetailsRechnungsausstellerType.HAUSVERWALTUNG,
  });

  const { data: maybeRechtstraegerData } = useRechtstraegerBankDetailsQuery({
    variables: { rechtstraegerId, bankDetailsId },
    skip: !bankDetailsId || tempRechnungsausstellerType !== ObjBankDetailsRechnungsausstellerType.RECHNUNGS_AUSSTELLER,
  });

  const { data: maybeWeRechtstraegerData } = useRechtstraegerBankDetailsQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { rechtstraegerId: weRechnungsAusstellerId!, bankDetailsId },
    skip: !bankDetailsId || tempRechnungsausstellerType !== ObjBankDetailsRechnungsausstellerType.WE_RECHNUNGS_AUSSTELLER || !weRechnungsAusstellerId,
  });

  const bankDetailsFirmendaten = maybeFirmendatenData?.getFirmendatenBankDetails.data;
  const bankDetailsRechtstraeger = maybeRechtstraegerData?.getRechtstraegerBankDetails.data;
  const bankDetailsWeRechtstraeger = maybeWeRechtstraegerData?.getRechtstraegerBankDetails.data;

  const bankDetailsData = getBankDetailsData(
    tempRechnungsausstellerType,
    bankDetailsFirmendaten,
    bankDetailsRechtstraeger,
    bankDetailsWeRechtstraeger
  );

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {getBankDetails(bankDetailsData ?? objBankDetails)}
    </Space>
  );
};

const getBankDetailsData = (
  tempRechnungsausstellerType?: ObjBankDetailsRechnungsausstellerType,
  bankDetailsFirmendaten?: FirmendatenBankDetails,
  bankDetailsRechtstraeger?: BankDetails,
  bankDetailsWeRechtstraeger?: BankDetails
) => {
  if (tempRechnungsausstellerType === ObjBankDetailsRechnungsausstellerType.HAUSVERWALTUNG) {
    return bankDetailsFirmendaten;
  } else if (tempRechnungsausstellerType === ObjBankDetailsRechnungsausstellerType.WE_RECHNUNGS_AUSSTELLER) {
    return bankDetailsWeRechtstraeger;
  } else if (tempRechnungsausstellerType === ObjBankDetailsRechnungsausstellerType.RECHNUNGS_AUSSTELLER) {
    return bankDetailsRechtstraeger;
  }
  return null;
};

const getBankDetails = (bankDetails?: BankDetails) => (
  <>
    {getRow('Kontoinhaber', bankDetails?.accountHolder)}
    {getRow('Bank', bankDetails?.bankname)}
    {getRow('IBAN', bankDetails?.iban)}
    {getRow('BIC', bankDetails?.bic)}
    {getRow('Fibu Konto', bankDetails?.fibuKontonummer)}
  </>
);

const getRow = (title: string, text?: string | null) => (
  <Row align="top">
    <Col span={4}>
      <Typography.Text>{title}: </Typography.Text>
    </Col>
    <Col span={18}>{text ? <Typography.Text>{text}</Typography.Text> : <TextForEmptyValue textToShow="minus" />}</Col>
  </Row>
);

export default BankDetailsAccountInfo;
