import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { generatePathToZinslisteDetailsPageOrDiscrepancies } from '../zinslisteUriPaths';
import { calculateVerarbeitungsstandVerbucht } from '../zinslisteCalculations';
import { EuroAmount } from '../../../components/Number';
import { LoadingCol } from '../../../components/Loading';
import StatusBadge from '../../../components/Status/StatusBadge';
import { compareTwoStringsForSorting } from '../../../helpers/stringHelper';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { Zinsliste, ZinslisteListEntry, ZinslisteVerarbeitungsstand } from '../../../types';

export interface RowContextInterface {
  isLoading: boolean;
  verarbeitungsstand?: ZinslisteVerarbeitungsstand;
  zinsliste?: Zinsliste;
}

export const RowContextZinsliste = React.createContext<RowContextInterface>({
  isLoading: true,
});

const LoadingDeviationListAndVerarbeitungsstand: FC<{
  children: (props: RowContextInterface) => React.ReactNode;
}> = (props) => (
  <RowContextZinsliste.Consumer>
    {(rowProps) => <LoadingCol isLoading={rowProps.isLoading}>{props.children(rowProps)}</LoadingCol>}
  </RowContextZinsliste.Consumer>
);

const zinslisteTableColumns = (objektId: string): TableWithColSelectorColumnProps<ZinslisteListEntry>[] => [
  {
    title: 'Periode',
    align: 'right',
    sorter: true,
    defaultSelected: true,
    render: (text, record) => (
      <span>
        {record.vorschreibungsperiode.monat}/{record.vorschreibungsperiode.jahr}
      </span>
    ),
  },
  {
    title: 'Brutto',
    align: 'right',
    sorter: true,
    defaultSelected: true,
    render: (text, record) => <EuroAmount value={record.sumBrutto ?? 0} />,
  },
  {
    title: 'Netto',
    align: 'right',
    sorter: true,
    defaultSelected: true,
    render: (text, record) => <EuroAmount value={record.sumNetto ?? 0} />,
  },
  {
    title: 'Vorschreibungspos. Anzahl',
    align: 'right',
    sorter: true,
    defaultSelected: true,
    render: (text, record) => record.countVorschreibungsposition,
  },
  {
    key: 'nichtErstellt',
    title: 'nicht erstellt',
    align: 'right',
    defaultSelected: true,
    // TODO: Build Sorter
    render: () => (
      <LoadingDeviationListAndVerarbeitungsstand>{(props) => props.verarbeitungsstand?.countNichtErstellt}</LoadingDeviationListAndVerarbeitungsstand>
    ),
  },
  {
    key: 'erstellt',
    title: 'erstellt',
    align: 'right',
    defaultSelected: true,
    // TODO: Build Sorter
    render: () => (
      <LoadingDeviationListAndVerarbeitungsstand>{(props) => props.verarbeitungsstand?.countErstellt}</LoadingDeviationListAndVerarbeitungsstand>
    ),
  },
  {
    key: 'ausgegeben',
    title: 'ausgegeben',
    align: 'right',
    defaultSelected: true,
    // TODO: Build Sorter
    render: () => (
      <LoadingDeviationListAndVerarbeitungsstand>{(props) => props.verarbeitungsstand?.countAusgegeben}</LoadingDeviationListAndVerarbeitungsstand>
    ),
  },
  {
    key: 'verbucht',
    title: 'verbucht',
    align: 'right',
    defaultSelected: true,
    // TODO: Build Sorter
    render: () => (
      <LoadingDeviationListAndVerarbeitungsstand>
        {(props) => (props.verarbeitungsstand ? calculateVerarbeitungsstandVerbucht(props.verarbeitungsstand) : null)}
      </LoadingDeviationListAndVerarbeitungsstand>
    ),
  },
  {
    key: 'status',
    title: 'Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.status.text, b.status.text),
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },
  {
    title: 'Aktion',
    key: 'action',
    defaultSelected: true,
    render: (text, record) => (
      <Link key={record.zinslisteId} to={generatePathToZinslisteDetailsPageOrDiscrepancies(objektId, record.zinslisteId, record.status)}>
        Details
      </Link>
    ),
  },
];

export default zinslisteTableColumns;
