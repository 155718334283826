import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../constants/andromedaSystemSettingsUriPaths';
import FieldHelpPage from './FieldHelpPage';

const andromedaSysSettingsFieldHelpPageRoute = (
  <Route path={URI_ANDROMEDA_SYS_SETTINGS.fieldHelp} element={<AuthRoute element={<FieldHelpPage />} />} />
);

export default andromedaSysSettingsFieldHelpPageRoute;
