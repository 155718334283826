import React, { FC } from 'react';
import { Checkbox } from 'antd';
import { Input, InputNumber } from 'formik-antd';
import { FormikProps } from 'formik';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FormFields, updateFormikField } from '../../../../../../helpers/formikHelper';
import { ZimmerCreateFormValues } from '../Create/zimmerCreateFormMapper';
import DatenTabColumnsLayout from '../../../../../Objekt/InfoFeld/Form/shared/DatenTabColumnsLayout';
import { zimmerUpdateFormFields, ZimmerUpdateFormValues } from '../Update/zimmerUpdateFormMapper';
import ObjektVPosSelect from '../../../../../shared/ObjektVPosSelect';
import FormItemWithFieldHelp from '../../../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../../../FieldHelp/useGetFieldHelpText';

type Props = {
  formFields: FormFields<ZimmerCreateFormValues> | FormFields<ZimmerUpdateFormValues>;
  formikProps: FormikProps<ZimmerCreateFormValues> | FormikProps<ZimmerUpdateFormValues>;
  objektId: string;
};

const ZimmerDatenTab: FC<Props> = ({ formFields, objektId, formikProps }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'BeInfoFeldZimmer'>('BeInfoFeldZimmer');

  return (
    <DatenTabColumnsLayout
      leftColumn={
        <>
          <FormItemWithFieldHelp name={formFields.bezeichnung} label="Bezeichnung" fieldHelp={getFieldHelpText('BeInfoFeldZimmer.bezeichnung')}>
            <Input id={formFields.bezeichnung} name={formFields.bezeichnung} size="small" />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp name={formFields.anmerkung} label="Anmerkung" fieldHelp={getFieldHelpText('BeInfoFeldZimmer.anmerkung')}>
            <Input id={formFields.anmerkung} name={formFields.anmerkung} size="small" />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp name={formFields.bodenbelag} label="Bodenbelag" fieldHelp={getFieldHelpText('BeInfoFeldZimmer.bodenbelag')}>
            <Input id={formFields.bodenbelag} name={formFields.bodenbelag} size="small" />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp name={formFields.nummer} label="Nummer" fieldHelp={getFieldHelpText('BeInfoFeldZimmer.nummer')}>
            <Input id={formFields.nummer} name={formFields.nummer} size="small" />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.energiekennzahl}
            label="Energiekennzahl"
            fieldHelp={getFieldHelpText('BeInfoFeldZimmer.energiekennzahl')}
          >
            <Input id={formFields.energiekennzahl} name={formFields.energiekennzahl} size="small" />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp name={formFields.flaechentyp} label="Flächentyp" fieldHelp={getFieldHelpText('BeInfoFeldZimmer.flaechentyp')}>
            <Input id={formFields.flaechentyp} name={formFields.flaechentyp} size="small" />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.initialQuadratmeterPreis}
            label="Ursprüngl. Preis pro m2"
            fieldHelp={getFieldHelpText('BeInfoFeldZimmer.initialQuadratmeterPreis')}
          >
            <InputNumber name={formFields.initialQuadratmeterPreis} size="small" min={0} style={{ width: '100%' }} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.quadratmeterPreis}
            label="Preis pro m2"
            fieldHelp={getFieldHelpText('BeInfoFeldZimmer.quadratmeterPreis')}
          >
            <InputNumber name={formFields.quadratmeterPreis} size="small" min={0} style={{ width: '100%' }} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.initialQuadratmeterBetrag}
            label="Ursprüngl. Betrag"
            fieldHelp={getFieldHelpText('BeInfoFeldZimmer.initialQuadratmeterBetrag')}
          >
            <InputNumber name={formFields.initialQuadratmeterBetrag} size="small" min={0} style={{ width: '100%' }} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.quadratmeterBetrag}
            label="Betrag pro m2"
            fieldHelp={getFieldHelpText('BeInfoFeldZimmer.quadratmeterBetrag')}
          >
            <InputNumber name={formFields.quadratmeterBetrag} size="small" min={0} style={{ width: '100%' }} />
          </FormItemWithFieldHelp>
        </>
      }
      rightColumn={
        <>
          <FormItemWithFieldHelp name={formFields.breite} label="Breite" fieldHelp={getFieldHelpText('BeInfoFeldZimmer.breite')}>
            <InputNumber name={formFields.breite} size="small" min={0} style={{ width: '100%' }} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp name={formFields.hoehe} label="Höhe" fieldHelp={getFieldHelpText('BeInfoFeldZimmer.hoehe')}>
            <InputNumber name={formFields.hoehe} size="small" min={0} style={{ width: '100%' }} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp name={formFields.laenge} label="Länge" fieldHelp={getFieldHelpText('BeInfoFeldZimmer.laenge')}>
            <InputNumber name={formFields.laenge} size="small" min={0} style={{ width: '100%' }} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp name={formFields.kubikmeter} label="M3 genau" fieldHelp={getFieldHelpText('BeInfoFeldZimmer.kubikmeter')}>
            <InputNumber name={formFields.kubikmeter} size="small" min={0} style={{ width: '100%' }} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp name={formFields.quadratmeter} label="M2 genau" fieldHelp={getFieldHelpText('BeInfoFeldZimmer.quadratmeter')}>
            <InputNumber name={formFields.quadratmeter} size="small" min={0} style={{ width: '100%' }} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp name={formFields.nutzflaeche} label="Nutzfläche" fieldHelp={getFieldHelpText('BeInfoFeldZimmer.nutzflaeche')}>
            <InputNumber name={formFields.nutzflaeche} size="small" min={0} style={{ width: '100%' }} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp name={formFields.heizung} label="Heizung" fieldHelp={getFieldHelpText('BeInfoFeldZimmer.heizung')}>
            <Input id={formFields.heizung} name={formFields.heizung} size="small" />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.objektVorschreibungspositionId}
            label="Vorschreibungsposition"
            fieldHelp={getFieldHelpText('BeInfoFeldZimmer.objektVorschreibungspositionId')}
          >
            <ObjektVPosSelect name={formFields.objektVorschreibungspositionId} objektId={objektId} includeArchiviert />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp name={formFields.nutzung} label="Nutzung mehrfach" fieldHelp={getFieldHelpText('BeInfoFeldZimmer.nutzung')}>
            <Checkbox
              name={formFields.nutzung}
              id={formFields.nutzung}
              checked={formikProps.values.nutzung}
              onChange={(e) => onChangeCheckbox(e, formikProps)}
            />
          </FormItemWithFieldHelp>
        </>
      }
    />
  );
};

const onChangeCheckbox = (isChecked: CheckboxChangeEvent, formikProps: FormikProps<any>) =>
  updateFormikField(formikProps, zimmerUpdateFormFields.nutzung, !formikProps.values.nutzung);

export default ZimmerDatenTab;
