import React, { FC, useState } from 'react';
import { Button, Divider, Dropdown, Input, MenuProps } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { Mitarbeiter } from '../../../../types';

type Props = {
  mitarbeiterList: Mitarbeiter[];
  onSelect: (mitarbeiterId: string) => void;
  loading?: boolean;
};

const MitarbeiterDropdown: FC<Props> = ({ onSelect, mitarbeiterList, loading }) => {
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const filteredMitarbeiterList = mitarbeiterList.filter(
    (mitarbeiter) => !searchKeyword || (searchKeyword && getMitabeiterName(mitarbeiter).toLowerCase().includes(searchKeyword.toLowerCase()))
  );

  const maxItemsToShow = 5;
  const maxHeight = 32 + 28 * maxItemsToShow;

  const items: MenuProps['items'] = filteredMitarbeiterList.map((mitarbeiter) => ({
    key: mitarbeiter.mitarbeiterId,
    label: getMitabeiterName(mitarbeiter),
    onClick: () => onSelect(mitarbeiter.mitarbeiterId),
  }));

  return (
    <Dropdown
      placement="topCenter"
      menu={{ items, style: { boxShadow: 'none', maxHeight, overflowY: 'auto' } }}
      trigger={['click']}
      onOpenChange={(open) => {
        if (open) setSearchKeyword('');
      }}
      dropdownRender={(menu) => (
        <div className="ant-dropdown-menu" style={{ background: '#fff' }}>
          {mitarbeiterList.length > maxItemsToShow && (
            <>
              <div style={{ padding: '5px 8px' }}>
                <Input.Search
                  placeholder="Bearbeiter auswählen"
                  value={searchKeyword}
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                  }}
                  size="small"
                  allowClear
                />
              </div>
              {filteredMitarbeiterList.length > 0 && <Divider style={{ marginBlock: 5 }} />}
            </>
          )}
          {menu}
        </div>
      )}
    >
      <div>
        <Dropdown.Button
          disabled={loading}
          loading={loading}
          type="primary"
          open={false}
          buttonsRender={([leftButton]) => [
            <span onClick={(e) => e.stopPropagation()}>{leftButton}</span>,
            <span onClick={(e) => e.preventDefault()}>
              <Button disabled={loading} type="primary" icon={<EllipsisOutlined />} />
            </span>,
          ]}
        >
          Übernehmen für
        </Dropdown.Button>
      </div>
    </Dropdown>
  );
};

const getMitabeiterName = (mitabeiter: Mitarbeiter) => `${mitabeiter.lastname}, ${mitabeiter.firstname}`;

export default MitarbeiterDropdown;
