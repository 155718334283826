import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AuftragsartTemplateTable from './Table/AuftragsartTemplateTable';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../components/Button/ButtonsAligned';
import { useToggle } from '../../hooks/useToggle';
import URI_ANDROMEDA_SYS_SETTINGS_AUFTRAGSART_PAGE from './auftragsartTemplateUriPaths';

const AuftragsartTemplateListing = () => {
  const navigate = useNavigate();

  const [showArchived, toggleShowArchived] = useToggle();

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <Button onClick={toggleShowArchived}>Archivierte Einträge {showArchived ? 'ausblenden' : 'anzeigen'}</Button>
        <Button icon={<PlusOutlined />} type="primary" onClick={() => navigate(URI_ANDROMEDA_SYS_SETTINGS_AUFTRAGSART_PAGE.createPage)}>
          Auftragsart anlegen
        </Button>
      </ButtonsAligned>

      <AuftragsartTemplateTable showArchived={showArchived} />
    </>
  );
};

export default AuftragsartTemplateListing;
