import React, { FC } from 'react';
import { MenuProps, Modal, Typography } from 'antd';
import { CoffeeOutlined, UploadOutlined } from '@ant-design/icons';
import { DataCarrierPainListEntry, DataCarrierPainStatus, DataCarrierPainStatusTuple } from '../../../../../types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { useUploadedDataCarrierPainMutation, useUploadManuallyDataCarrierPainMutation } from '../../../gql/DataCarrierMutations.types';
import ActionDropdown from '../../../../../components/Dropdown/ActionDropdown';
import { isPaymentTransactionTypeOnlineBanking } from '../../../../Payment/paymentHelpers';

type Props = {
  dataCarrier: DataCarrierPainListEntry;
  onAction: () => void;
};

const DirectDebitOrBankWireTableActions: FC<Props> = ({ dataCarrier, onAction }) => {
  const [runUploaded] = useUploadedDataCarrierPainMutation({
    onCompleted: () => {
      //showSuccessMsgOther('');
      onAction();
    },
    variables: { dataCarrierId: dataCarrier.dataCarrierId },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUploadManually] = useUploadManuallyDataCarrierPainMutation({
    onCompleted: () => {
      //showSuccessMsgOther(entity);
      onAction();
    },
    variables: { dataCarrierId: dataCarrier.dataCarrierId },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: `Auf ${isDataCarrierStatusUploaded(dataCarrier.status) ? 'Manuell hochzuladen' : 'Hochgeladen'} ändern`,
      onClick: isDataCarrierStatusUploaded(dataCarrier.status)
        ? showConfirmUploadManually(runUploadManually, dataCarrier)
        : showConfirmUploaded(runUploaded, dataCarrier),
      icon: isDataCarrierStatusUploaded(dataCarrier.status) ? <CoffeeOutlined /> : <UploadOutlined />,
    },
  ];

  return isPaymentTransactionTypeOnlineBanking(dataCarrier.paymentTransactionType.value) ? (
    <ActionDropdown items={items} />
  ) : (
    <Typography.Text disabled>Aktion</Typography.Text>
  );
};

const showConfirmUploaded = (run: () => void, dataCarrier: DataCarrierPainListEntry) => () => {
  Modal.confirm({
    title: `Möchten Sie auf "Hochgeladen" ändern?`,
    content: `${dataCarrier.name}`,
    okText: 'Ändern',
    cancelText: 'Abbrechen',
    onOk() {
      return run();
    },
  });
};

const showConfirmUploadManually = (run: () => void, dataCarrier: DataCarrierPainListEntry) => () => {
  Modal.confirm({
    title: `Möchten Sie auf "Manuell hochzuladen" ändern?`,
    content: `${dataCarrier.name}`,
    okText: 'Ändern',
    cancelText: 'Abbrechen',
    onOk() {
      return run();
    },
  });
};

const isDataCarrierStatusUploaded = (dataCarrierStatus: DataCarrierPainStatusTuple) => dataCarrierStatus.value === DataCarrierPainStatus.Uploaded;

export default DirectDebitOrBankWireTableActions;
