import React, { FC } from 'react';
import { Empty } from 'antd';
import { Vorschreibungsposition } from '../../../../types';
import NestedTable from '../../../../components/Table/NestedTable/NestedTable';
import vorschreibungspositionTemplateTableColumns from './vorschreibungspositionTemplateTableColumns';

type Props = {
  loading: boolean;
  refetch: () => void;
  vorschreibungspositionList?: Vorschreibungsposition[];
};
const VorschreibungspositionTemplateTable: FC<Props> = ({ loading, refetch, vorschreibungspositionList }) => (
  <NestedTable<Vorschreibungsposition>
    locale={{
      emptyText: <Empty description={<span>Keine Vorschreibungsposition vorhanden</span>} />,
    }}
    loading={loading}
    scroll={{ x: 900 }}
    dataSource={vorschreibungspositionList}
    columns={vorschreibungspositionTemplateTableColumns(refetch)}
    rowKey={(record) => record.vorschreibungspositionId}
    expandable={{
      rowExpandable: () => false,
    }}
    useHighlighting
  />
);

export default VorschreibungspositionTemplateTable;
