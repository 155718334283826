import React, { FC } from 'react';
import { Empty, PaginationProps } from 'antd';
import rechtstraegerTableColumns from './rechtstraegerTableColumns';
import TableWithColSelector from '../../../components/Table/TableWithColSelector/TableWithColSelector';
import { RechtstraegerListEntry } from '../../../types';
import { HandleTableSorting } from '../../../shared/typeHelpers';

type Props = {
  handleTableSorting: HandleTableSorting;
  loading: boolean;
  pagination: PaginationProps;
  rechtstraegerList?: RechtstraegerListEntry[];
};

const RechtstraegerTable: FC<Props> = ({ handleTableSorting, loading, pagination, rechtstraegerList }) => {
  return (
    <TableWithColSelector<RechtstraegerListEntry>
      columns={rechtstraegerTableColumns}
      size="small"
      locale={{
        emptyText: <Empty description={<span>Kein Rechtsträger vorhanden</span>} />,
      }}
      loading={loading}
      scroll={{ x: 900 }}
      pagination={pagination}
      dataSource={rechtstraegerList}
      onChange={handleTableSorting}
      rowKey={(record) => record.rechtstraegerId}
      filterIdentifier="main-rechtstraeger"
    />
  );
};

export default RechtstraegerTable;
