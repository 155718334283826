import { FormFileReference } from '../../../../../components/FileUpload/formHelpers';
import { FormFields } from '../../../../../helpers/formikHelper';

export type OnlineBankingFormValues = {
  inputFile?: FormFileReference;
  inputFileId?: string | null;
  tempInputFileId?: string;
};

export const onlineBankingFormFields: FormFields<OnlineBankingFormValues> = {
  inputFile: 'inputFile',
  inputFileId: 'inputFileId',
  tempInputFileId: 'tempInputFileId',
};
