import { FC } from 'react';
import { useContactChangeHistorListQuery } from '../../../gql/AddressesAndContactsQueries.types';
import { HistoryType } from '../../../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../../../History/HistoryListingTable';

type Props = {
  contactId: string;
};

const ContactChangeHistoryListingTable: FC<Props> = ({ contactId }) => {
  const { data, loading } = useContactChangeHistorListQuery({ variables: { contactId } });
  const historyList = data?.getContactChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="contact" />;
};

export default ContactChangeHistoryListingTable;
