import React, { FC } from 'react';
import { Empty, PaginationProps } from 'antd';
import GenerierlaufStatusExpandedRow from '../../../shared/GenerierlaufStatusExpandedRow';
import { isVerarbeitungEntryTableRowExpandable } from '../../../verarbeitungHelpers';
import { IncomingInvoiceBookingCreationGenerierlaufListEntry } from '../../../../../types';
import { HandleTableSorting } from '../../../../../shared/typeHelpers';
import entryTableColumns from './entryTableColumns';
import NestedTable from '../../../../../components/Table/NestedTable/NestedTable';
import { isBillingTypeEinzelrechnung } from '../../../../Payment/paymentHelpers';

type Props = {
  entryList: IncomingInvoiceBookingCreationGenerierlaufListEntry[];
  handleTableSorting: HandleTableSorting;
  pagination: PaginationProps;
  loading: boolean;
};

const EntryTable: FC<Props> = ({ entryList, handleTableSorting, loading, pagination }) => {
  return (
    <NestedTable<IncomingInvoiceBookingCreationGenerierlaufListEntry>
      dataSource={entryList}
      columns={entryTableColumns()}
      loading={loading}
      locale={{
        emptyText: <Empty description={<span>Keine Eingangsrechnungen vorhanden</span>} />,
      }}
      pagination={pagination}
      onChange={handleTableSorting}
      expandable={{
        expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
        rowExpandable: isVerarbeitungEntryTableRowExpandable,
      }}
      colWidth={150}
      rowKey={(record) =>
        isBillingTypeEinzelrechnung(record.billingType.value)
          ? record.generierlaufEntryId
          : `${record.generierlaufEntryId}-${record.partialAmountName}`
      }
    />
  );
};

export default EntryTable;
