import React, { FC } from 'react';
import { Empty } from 'antd';
import { SubAbrAbrechnungskreisList, SubAbrechnungEigentuemer, SubAbrechnungEigentuemerBestandseinheit } from '../../../../../../../types';
import NestedTableWithColSelectorWrapper from '../../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelectorWrapper';
import NestedTableWithColSelector from '../../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import subAbrTabBestandseinheitTableColumns from './subAbrTabBestandseinheitTableColumns';
import EinnahmenTable from '../Level2/EinnahmenTable';
import AusgabenTable from '../Level2/AusgabenTable';

type Props = {
  subAbrechnungEigentuemer: SubAbrechnungEigentuemer;
};

const SubAbrTabBestandseinheitTable: FC<Props> = ({ subAbrechnungEigentuemer }) => {
  return (
    <NestedTableWithColSelectorWrapper filterIdentifier="sub-abr-tab-be">
      <NestedTableWithColSelector<SubAbrechnungEigentuemerBestandseinheit>
        colWidth={150}
        level={1}
        locale={{
          emptyText: <Empty description={<span>Keine Daten vorhanden</span>} />,
        }}
        columns={subAbrTabBestandseinheitTableColumns}
        dataSource={subAbrechnungEigentuemer.subAbrechnungEigentuemerBestandseinheitList}
        rowKey={getRowKey}
        expandable={{
          rowExpandable: isRowExpandable,
          expandedRowRender: (record, index, indent, expanded) => (
            <>
              <EinnahmenTable
                parentRowKeys={[getRowKey(record)]}
                visible={expanded}
                einnahmen={record.einnahmen as SubAbrAbrechnungskreisList | undefined}
                buchungskreisId={subAbrechnungEigentuemer.buchungskreisId}
                abrechnungszeitraumVon={subAbrechnungEigentuemer.abrechnungszeitraumVon}
                abrechnungszeitraumBis={subAbrechnungEigentuemer.abrechnungszeitraumBis}
                objektId={subAbrechnungEigentuemer.objekt.objektId}
              />
              <AusgabenTable
                parentRowKeys={[getRowKey(record)]}
                visible={expanded}
                ausgaben={record.ausgaben as SubAbrAbrechnungskreisList | undefined}
                buchungskreisId={subAbrechnungEigentuemer.buchungskreisId}
                abrechnungszeitraumVon={subAbrechnungEigentuemer.abrechnungszeitraumVon}
                abrechnungszeitraumBis={subAbrechnungEigentuemer.abrechnungszeitraumBis}
                objektId={subAbrechnungEigentuemer.objekt.objektId}
              />
            </>
          ),
        }}
      />
    </NestedTableWithColSelectorWrapper>
  );
};

const getRowKey = (record: SubAbrechnungEigentuemerBestandseinheit) => record.bestandseinheit.bestandseinheitId;

const isRowExpandable = (record: SubAbrechnungEigentuemerBestandseinheit) => !!record.ausgaben || !!record.einnahmen;

export default SubAbrTabBestandseinheitTable;
