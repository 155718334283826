import { FC } from 'react';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';
import { useAufteilungsschluesselTemplateChangeHistoryListQuery } from '../gql/AufteilungsschluesselTemplateQueries.types';

type Props = {
  aufteilungsschluesselId: string;
};

const AufteilungsschluesselTemplateChangeHistoryListingTable: FC<Props> = ({ aufteilungsschluesselId }) => {
  const { data, loading } = useAufteilungsschluesselTemplateChangeHistoryListQuery({ variables: { aufteilungsschluesselId } });
  const historyList = data?.getAufteilungsschluesselTemplateChangeHistoryList.data;

  return (
    <HistoryListingTable
      historyType={HistoryType.Change}
      historyList={historyList}
      loading={loading}
      filterIdPrefix="aufteilungsschluessel-template"
    />
  );
};

export default AufteilungsschluesselTemplateChangeHistoryListingTable;
