import React, { FC } from 'react';
import { Spin } from 'antd';
import TemplateAbrechnung from '../TemplateAbrechnung';
import ObjektAbrechnungTemplate from '../../../../../pages/PDFTemplates/templates/objektAbrechnung/ObjektAbrechnungTemplate';
import {
  createObjektAbrechnungTemplatePDFData,
  mapTextbausteinToObjektAbrechnungSelectableSection,
} from '../WeTemplates/weAbrDefTemplateFormObjAbrMapper';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import { useBelegTexteFirmendatenQuery } from '../../../../Belegtext/gql/BelegtexteQueries.types';
import { useFirmendatenOperatingSiteListQuery } from '../../../../KundenSystem/Firmendaten/gql/FirmendatenOperatingSiteQueries.types';
import { useFDAuthorized } from '../../../../../shared/Auth/useAuthorized';
import { BelegTextePlatzhalter } from '../../../../Belegtext/Form/belegTexteFormTypes';
import { useFirmendatenQuery } from '../../../../KundenSystem/Firmendaten/gql/FirmendatenQueries.types';
import { findBelegTexteHauptAddress, findBelegTexteHauptEmail, findBelegTextePhoneNumber } from '../../../../shared/operatingSiteHelpers';
import { ObjAbrDefGeneralSettingsFormValues } from './BkOrWe/ObjAbrDefGeneralSettingsForm/objAbrDefGeneralSettingsFormMapper';

type TemplateObjektAbrechnungProps = {
  values: TextbausteinFormValues[];
  onTemplateChange: (templateValues: TextbausteinFormValues[]) => void;
  objAbrDefGeneralSettingsValues: ObjAbrDefGeneralSettingsFormValues;
};

const TemplateObjektAbrechnung: FC<TemplateObjektAbrechnungProps> = ({ values, onTemplateChange, objAbrDefGeneralSettingsValues }) => {
  const { activeForFirmendatenId: firmendatenId } = useFDAuthorized();

  const { data } = useFirmendatenQuery({ variables: { firmendatenId } });
  const firmendaten = data?.getFirmendaten.data;

  const { data: belegTexteData } = useBelegTexteFirmendatenQuery({ variables: { firmendatenId } });

  const belegTexte = belegTexteData?.getBelegTexteFirmendaten.data;

  const { data: firmendatenOperatingSiteListData } = useFirmendatenOperatingSiteListQuery({ variables: { firmendatenId } });
  const operatingSiteList = firmendatenOperatingSiteListData?.getFirmendatenOperatingSiteList.data ?? [];

  if (!firmendaten || !belegTexte || !operatingSiteList) {
    return <Spin />;
  }

  const belegTextePlatzhalter: BelegTextePlatzhalter = {
    hauptAddress: findBelegTexteHauptAddress(operatingSiteList),
    vatIdentificationNumber: firmendaten.vatIdentificationNumber,
    companyRegistrationNumber: firmendaten.companyRegistrationNumber,
    phoneNumber: findBelegTextePhoneNumber(operatingSiteList),
    email: findBelegTexteHauptEmail(operatingSiteList),
  };

  return (
    <TemplateAbrechnung
      values={values}
      onTemplateChange={onTemplateChange}
      renderPreview={({ selectedTextbaustein }) => (
        <ObjektAbrechnungTemplate
          data={createObjektAbrechnungTemplatePDFData(values, objAbrDefGeneralSettingsValues, belegTexte, belegTextePlatzhalter, firmendatenId)}
          isInEditMode
          selectedTextbaustein={mapTextbausteinToObjektAbrechnungSelectableSection(selectedTextbaustein)}
        />
      )}
    />
  );
};

export default TemplateObjektAbrechnung;
