import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppFieldHelpListQueryVariables = Types.Exact<{
  selector?: Types.InputMaybe<Types.Scalars['String']['input']>;
  fieldHelp?: Types.InputMaybe<Types.Scalars['String']['input']>;
  deprecated?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  order?: Types.InputMaybe<Types.FieldHelpOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type AppFieldHelpListQuery = {
  getAppFieldHelpList: {
    data: {
      contentList: Array<{ deprecated: boolean; fieldHelp?: string | null; fieldHelpId: string; selector: string }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const AppFieldHelpListDocument = gql`
  query AppFieldHelpList(
    $selector: String
    $fieldHelp: String
    $deprecated: Boolean
    $order: FieldHelpOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getAppFieldHelpList(
      selector: $selector
      fieldHelp: $fieldHelp
      deprecated: $deprecated
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          deprecated
          fieldHelp
          fieldHelpId
          selector
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useAppFieldHelpListQuery(baseOptions?: Apollo.QueryHookOptions<AppFieldHelpListQuery, AppFieldHelpListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppFieldHelpListQuery, AppFieldHelpListQueryVariables>(AppFieldHelpListDocument, options);
}
export function useAppFieldHelpListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppFieldHelpListQuery, AppFieldHelpListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppFieldHelpListQuery, AppFieldHelpListQueryVariables>(AppFieldHelpListDocument, options);
}
export function useAppFieldHelpListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AppFieldHelpListQuery, AppFieldHelpListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AppFieldHelpListQuery, AppFieldHelpListQueryVariables>(AppFieldHelpListDocument, options);
}
export type AppFieldHelpListQueryHookResult = ReturnType<typeof useAppFieldHelpListQuery>;
export type AppFieldHelpListLazyQueryHookResult = ReturnType<typeof useAppFieldHelpListLazyQuery>;
export type AppFieldHelpListSuspenseQueryHookResult = ReturnType<typeof useAppFieldHelpListSuspenseQuery>;
export type AppFieldHelpListQueryResult = Apollo.QueryResult<AppFieldHelpListQuery, AppFieldHelpListQueryVariables>;
