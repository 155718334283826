import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../components/Template/DetailsPageTemplate';
import { SubAdministration } from '../../types';
import { SubAdministrationDetailsPageRouteParams } from './routes';
import { useSubAdministrationQuery } from '../../features/SubAdministration/gql/SubAdministrationQueries.types';
import menuListSubAdministration from './menuListSubAdministration';
import SubAdministrationCard from '../../features/SubAdministration/Card/SubAdministrationCard';

const SubAdministrationDetailsPage: FC = () => {
  const { objektId, subAdministrationId } = useParams() as SubAdministrationDetailsPageRouteParams;
  const { data, refetch } = useSubAdministrationQuery({ variables: { objektId, subAdministrationId } });
  const subAdministration = data?.getSubAdministration.data;

  return (
    <DetailsPageTemplate<SubAdministration>
      pageTitle={() => 'Subverwaltung'}
      data={subAdministration}
      card={(subAdministration) => <SubAdministrationCard objektId={objektId} subAdministration={subAdministration} onAction={refetch} />}
      sidebarMenuList={(subAdministration) => menuListSubAdministration(objektId, subAdministration, refetch)}
    />
  );
};

export default SubAdministrationDetailsPage;
