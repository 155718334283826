import React, { FC, useState } from 'react';
import { Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../hooks/useToggle';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../components/Button/ButtonsAligned';
import { TicketTemplate } from '../../types';
import TicketTemplateTemplateForm from './Form/TicketTemplateTemplateForm';

type Props = {
  ticketTemplateList?: TicketTemplate[];
  loading: boolean;
};

const TicketTemplateTemplateActionButtonsAndDrawer: FC<Props> = ({ ticketTemplateList, loading }) => {
  const [isCollapsed, toggleIsCollapsed] = useToggle();
  const [drawerTitle, setDrawerTitle] = useState('anlegen');

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <Button icon={<PlusOutlined />} type="primary" onClick={toggleIsCollapsed}>
          Aufgabenart anlegen
        </Button>
      </ButtonsAligned>
      <Drawer title={`Aufgabenart ${drawerTitle}`} width={720} onClose={toggleIsCollapsed} open={isCollapsed} destroyOnClose>
        <TicketTemplateTemplateForm
          ticketTemplateList={ticketTemplateList}
          onSuccess={toggleIsCollapsed}
          onCancel={toggleIsCollapsed}
          setDrawerTitle={setDrawerTitle}
          loading={loading}
        />
      </Drawer>
    </>
  );
};

export default TicketTemplateTemplateActionButtonsAndDrawer;
