import * as Yup from 'yup';
import { entityIsRequired } from '../../../components/message/validationMsg';
import { emailDeliveryDefinitionFormFields } from './emailDeliveryDefFormMapper';

/* eslint-disable no-template-curly-in-string */
export const emailDeliveryDefValidationSchema = Yup.object().shape({
  [emailDeliveryDefinitionFormFields.bezeichnung]: Yup.string().required(entityIsRequired('Bezeichnung')),
  [emailDeliveryDefinitionFormFields.type]: Yup.string().required(entityIsRequired('Typ')),
  [emailDeliveryDefinitionFormFields.subject]: Yup.object().test({
    name: 'subject',
    message: entityIsRequired('E-Mail Betreff'),
    test: (value) => {
      // @ts-ignore
      return !!value.value;
    },
  }),
});
/* eslint-enable no-template-curly-in-string */
