import React, { FC } from 'react';
import { Select } from 'formik-antd';
import { SelectProps } from 'antd';
import { useVertragInfoFeldTypeListQuery } from '../gql/VertragInfoFeldTypeQueries.types';

type Props = { name: string } & SelectProps;

const VertragInfoFeldTypeFormSelect: FC<Props> = ({ name, ...restProps }) => {
  const { data, loading } = useVertragInfoFeldTypeListQuery();

  const vertragInfoFeldTypeList = data?.getVertragInfoFeldTypeList.data ?? [];

  return (
    <Select name={name} id={name} loading={loading} placeholder="Typ auswählen" allowClear style={{ minWidth: '160px' }} {...restProps}>
      {vertragInfoFeldTypeList.map((infoFeldType) => (
        <Select.Option value={infoFeldType.value} key={infoFeldType.value}>
          {infoFeldType.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default VertragInfoFeldTypeFormSelect;
