import React, { FC } from 'react';
import { SelectProps } from 'formik-antd';
import { useKsFirmendatenSelectListQuery } from './gql/KSFirmendatenSelectQueries.types';
import { useFDAuthorized } from '../../shared/Auth/useAuthorized';
import { EntityStatusFormSelect } from '../../components/Status/EntityStatus';
import { FirmendatenListEntry } from '../../types';

type KsFirmendatenSelectProps = {
  firma?: string | null;
} & SelectProps;

const KSFirmendatenSelect: FC<KsFirmendatenSelectProps> = ({ firma, ...restProps }) => {
  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();
  const { loading, data: maybeKSFirmendatenListData } = useKsFirmendatenSelectListQuery({
    variables: {
      kundenSystemId,
    },
  });

  const firmendatenList = maybeKSFirmendatenListData?.getKSFirmendatenList?.data ?? [];

  return (
    <EntityStatusFormSelect<FirmendatenListEntry>
      list={firmendatenList}
      getSelectItemId={(firmendaten) => firmendaten.firmendatenId}
      getSelectItemContent={(firmendaten) => firmendaten.kurzBezeichnung}
      getSelectItemTooltipTitle={(firmendaten) => firmendaten.kurzBezeichnung}
      defaultValue={firma}
      id={restProps.name}
      loading={loading}
      size="small"
      {...restProps}
    />
  );
};

export default KSFirmendatenSelect;
