import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { useGenerierlaufStatusListQuery } from '../../gql/GenerierlaufInfo/GenerierlaufInfoQueries.types';

type Props = SelectProps;

const StatusListFormSelect: FC<Props> = ({ name, ...restProps }) => {
  const { data, loading } = useGenerierlaufStatusListQuery();

  const list = data?.getGenerierlaufStatusList.data || [];

  return (
    <Select
      size="middle"
      placeholder=""
      {...restProps}
      id={name}
      name={name}
      loading={loading}
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {list.map((status) => (
        <Select.Option key={status.value} value={status.value}>
          {status.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default StatusListFormSelect;
