import React, { FC, ReactNode, useState } from 'react';
import { Drawer, MenuProps, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ArchiveIcon } from '@radix-ui/react-icons';
import ActionDropdown from '../../../../../components/Dropdown/ActionDropdown';
import { useToggle } from '../../../../../hooks/useToggle';
import ObjektInfoFeldPostIt from './ObjektInfoFeldPostIt';
import DocumentCount from '../../../../InfoFeld/DocumentCount';
import TicketCount from '../../../../InfoFeld/TicketCount';
import { InfoFeldUpdateFormTabKey } from '../../../../InfoFeld/infoFeldTypes';
import { InfoFeldStatusTuple, PostIt } from '../../../../../types';
import { isStatusArchived } from '../../../../../helpers/statusHelper';
import { radixActionStyles } from '../../../../../helpers/radixIconsStyles';
import { showSuccessMsgArchived, showSuccessMsgUnarchived } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { useArchiveObjektInfoFeldMutation, useUnarchiveObjektInfoFeldMutation } from '../../gql/ObjektInfoFeldMutations.types';
import { showConfirmArchiveInfoFeld, showConfirmDeleteInfoFeld, showConfirmUnarchiveInfoFeld } from '../../../../InfoFeld/InfoFeldConfirmModals';

type Props = {
  fileIdList: string[];
  objektId: string;
  objektInfoFeldId: string;
  renderUpdateForm: (closeDrawer: () => void, activeKeyTab: InfoFeldUpdateFormTabKey) => ReactNode;
  onDelete: () => Promise<unknown>;
  onSuccess: () => void;
  infoFeldStatus: InfoFeldStatusTuple;
  ticketCount?: number;
  postIt?: PostIt | null;
  deletable?: boolean | null;
};

const InfoFeldListItemActions: FC<Props> = ({
  onDelete,
  renderUpdateForm,
  objektId,
  objektInfoFeldId,
  fileIdList,
  onSuccess,
  ticketCount,
  postIt,
  deletable,
  infoFeldStatus,
}) => {
  const [isCollapsed, onCollapse] = useToggle();
  const [activeKeyTab, setActiveKeyTab] = useState<InfoFeldUpdateFormTabKey>(InfoFeldUpdateFormTabKey.DATA);

  const isArchived = isStatusArchived(infoFeldStatus);

  const [runArchive] = useArchiveObjektInfoFeldMutation({
    variables: {
      objektId,
      infoFeldId: objektInfoFeldId,
    },
    onCompleted: () => {
      showSuccessMsgArchived('Infofeld');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUnarchive] = useUnarchiveObjektInfoFeldMutation({
    variables: {
      objektId,
      infoFeldId: objektInfoFeldId,
    },
    onCompleted: () => {
      showSuccessMsgUnarchived('Infofeld');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Bearbeiten',
      onClick: () => {
        setActiveKeyTab(InfoFeldUpdateFormTabKey.DATA);
        onCollapse();
      },
      icon: <EditOutlined />,
    },
    {
      key: '2',
      label: isArchived ? 'Reaktivieren' : 'Archivieren',
      onClick: isArchived ? showConfirmUnarchiveInfoFeld(runUnarchive) : showConfirmArchiveInfoFeld(runArchive),
      icon: <ArchiveIcon style={radixActionStyles} />,
      disabled: !!deletable,
    },
    {
      key: '3',
      label: 'Löschen',
      onClick: showConfirmDeleteInfoFeld(onDelete),
      icon: <DeleteOutlined />,
      danger: true,
      disabled: !deletable,
    },
  ];

  return (
    <>
      <Space direction="horizontal" size={16}>
        <ObjektInfoFeldPostIt postIt={postIt} objektId={objektId} objektInfoFeldId={objektInfoFeldId} refresh={onSuccess} />
        <DocumentCount
          fileIdList={fileIdList}
          onClick={() => {
            setActiveKeyTab(InfoFeldUpdateFormTabKey.DOCUMENT);
            onCollapse();
          }}
        />
        <TicketCount
          ticketCount={ticketCount}
          onClick={() => {
            setActiveKeyTab(InfoFeldUpdateFormTabKey.TICKET);
            onCollapse();
          }}
        />
        <ActionDropdown items={items} />
      </Space>
      <Drawer title="Informationen bearbeiten" width={720} open={isCollapsed} onClose={onCollapse} destroyOnClose>
        {renderUpdateForm(onCollapse, activeKeyTab)}
      </Drawer>
    </>
  );
};

export default InfoFeldListItemActions;
