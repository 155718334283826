import React, { FC } from 'react';
import { Select } from 'formik-antd';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';

const optionsDueDateVorschreibung = [
  {
    value: 'MONATS_ERSTER',
    label: 'Monats Erster',
  },
  {
    value: 'MONATS_FUENFTER',
    label: 'Monats Fünfter',
  },
];

type Props = {
  name: string;
  fieldHelp?: string | null;
};

const DueDateVorschreibungSelect: FC<Props> = ({ name, fieldHelp, ...props }) => (
  <FormItemWithFieldHelp labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name={name} label="Fälligkeit" fieldHelp={fieldHelp}>
    <Select id={name} name={name} placeholder="Fälligkeit auswählen" {...props}>
      {optionsDueDateVorschreibung.map((item) => (
        <Select.Option key={item.value} value={item.value}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  </FormItemWithFieldHelp>
);

export default DueDateVorschreibungSelect;
