import { Dayjs } from 'dayjs';
import { IndexSeriesPeriodInput, ValuePreservationType } from '../../../../../../../../types';
import { dayjsCustom } from '../../../../../../../../helpers/dayjsCustom';

export const isValuePreservationTypeCategory = (type?: ValuePreservationType) => type === ValuePreservationType.Category;
export const isValuePreservationTypeIndexAnnual = (type?: ValuePreservationType) => type === ValuePreservationType.IndexAnnual;
export const isValuePreservationTypeIndexThreshold = (type?: ValuePreservationType) => type === ValuePreservationType.IndexThreshold;
export const isValuePreservationTypeReferenceValue = (type?: ValuePreservationType) => type === ValuePreservationType.ReferenceValue;

export const mapDateFormValueToMonthYear = (date: Dayjs | string): IndexSeriesPeriodInput => {
  return {
    month: dayjsCustom(date).month() + 1,
    year: dayjsCustom(date).year(),
  };
};

export const mapMonthYearToDateFormat = (date: string): string => {
  const dateAsDayJs = dayjsCustom(date, 'MM.YYYY').startOf('month');
  return dateAsDayJs.toString();
};
