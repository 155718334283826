import React, { FC, Fragment } from 'react';
import { Table } from 'antd';
import { v4 as uuid4 } from 'uuid';
import { TVPosList } from './mapZinslisteVPosListToTable';
import { EuroAmount } from '../../../../components/Number';
import VPosTableDetails from './VPosTableDetails';
import { StyledVPosTable } from './styled/StyledVPosTable';
import DataWithShortenedTextAndExtraInfo from '../../../../components/Helpers/DataWithShortenedTextAndExtraInfo';
import { Zinsliste } from '../../../../types';
import { TVPosForVertragLevel } from '../../Card/VPosForVertragLevelList/vPosForVertragLevelList.model';

type VPosTableProps = {
  objektId: string;
  rows: TVPosList[];
  vorschreibungspositionList: TVPosForVertragLevel[];
  zinsliste: Zinsliste;
  paginationForVPosList: { page: number; pageSize: number };
};

const VPosTable: FC<VPosTableProps> = ({ objektId, rows, vorschreibungspositionList, zinsliste, paginationForVPosList }) => (
  <StyledVPosTable
    bordered
    size="small"
    pagination={false}
    dataSource={rows}
    summary={() => (
      <VPosTableDetails
        zinsliste={zinsliste}
        objektId={objektId}
        vorschreibungspositionList={vorschreibungspositionList}
        pagination={paginationForVPosList}
      />
    )}
    rowKey={() => uuid4()}
  >
    {vorschreibungspositionList.map((col, index) => {
      return (
        <Fragment key={index}>
          {index === 0 ? (
            <>
              <Table.Column key="topCol" render={() => null} />
              <Table.Column key="vertragCol" render={() => null} />
              <Table.Column key="vertragsPartnerCol" render={() => null} />
            </>
          ) : null}
          <Table.ColumnGroup
            key={index}
            title={
              <div style={{ justifyContent: 'center' }}>
                <DataWithShortenedTextAndExtraInfo text={col.bezeichnung} maxTextLength={25} />
              </div>
            }
          >
            <Table.Column title="Summe" key="sumCol" render={(text, record: TVPosList) => record.bezeichnung} width="105px" />
            <Table.Column
              align="right"
              title="Betrag"
              key="betrag"
              width="105px"
              render={(text, record: TVPosList) => <EuroAmount value={record[col.bezeichnung].betrag} />}
            />
          </Table.ColumnGroup>
        </Fragment>
      );
    })}
  </StyledVPosTable>
);

export default VPosTable;
