import React, { FC } from 'react';
import { List, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { RechtstraegerContactListEntry } from '../../types';
import RechtstraegerContactListItem from './RechtstraegerContactListItem';

type Props = {
  fetchMore: any; // FIXME would be better with type. not so easy if it is in a different component
  loading: boolean;
  contentList: RechtstraegerContactListEntry[];
  hasMore: boolean;
};

const RechtstraegerContactInfiniteList: FC<Props> = ({ loading, fetchMore, contentList, hasMore, ...restProps }) => {
  return (
    <div className="infinite-container" {...restProps}>
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={(page) => fetchMore({ variables: { page } })}
        hasMore={!loading && hasMore}
        useWindow={false}
      >
        <List
          dataSource={contentList}
          renderItem={(rechtstraeger: RechtstraegerContactListEntry) => <RechtstraegerContactListItem rechtstraeger={rechtstraeger} />}
        >
          {loading && hasMore && (
            <div className="loading-container">
              <Spin />
            </div>
          )}
        </List>
      </InfiniteScroll>
    </div>
  );
};

export default RechtstraegerContactInfiniteList;
