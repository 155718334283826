import React, { FC } from 'react';
import { friendlyFormatIBAN } from 'ibantools';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';

type Props = {
  ibanList: string[];
  maxTextLength?: number;
};

const DataCarrierIbanListColumn: FC<Props> = ({ ibanList, maxTextLength }) => {
  if (!ibanList.length) return <TextForEmptyValue textToShow="minus" />;

  const firstIban = friendlyFormatIBAN(ibanList[0]) as string;
  const tooltipIbanList = ibanList.slice(1).map((iban) => friendlyFormatIBAN(iban) as string);

  return <DataWithShortenedText maxTextLength={maxTextLength ?? firstIban.length} text={firstIban} extraTexts={tooltipIbanList} />;
};

export default DataCarrierIbanListColumn;
