import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { SepaMandatFieldsFragmentDoc } from '../SepaMandat/gql/SepaMandatFragments.types';
export type BankDetailsFieldsFragment = {
  accountHolder: string;
  accountLimit?: number | null;
  assignedHeVertragZahlungsregelMap: any;
  assignedObjektMap: any;
  bankDetailsId: string;
  bankname: string;
  bic: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  ebicsCreditTransferUserList: Array<string>;
  ebicsDirectDebitUserList: Array<string>;
  fibuKontoId?: string | null;
  fibuKontonummer?: string | null;
  iban: string;
  updatedByMitarbeiterId?: string | null;
  fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
  fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
  sepaMandat?: {
    fileId?: string | null;
    fileName?: string | null;
    sepaMandatId: string;
    sepaMandatReference: string;
    signatureDate?: string | null;
    firmendaten?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      firmendatenId: string;
      kundenSystemId: string;
      kurzBezeichnung: string;
      name1: string;
      name2?: string | null;
      name3?: string | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    rechnungsAussteller?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
    warningList: Array<{ message: string; type: string }>;
  } | null;
  status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type FirmendatenBankDetailsFieldsFragment = {
  accountHolder: string;
  accountLimit?: number | null;
  allowObjektZuweisung?: boolean | null;
  assignedHeVertragZahlungsregelMap: any;
  assignedObjektMap: any;
  bankDetailsId: string;
  bankname: string;
  bic: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  ebicsCreditTransferUserList: Array<string>;
  ebicsDirectDebitUserList: Array<string>;
  fibuKontoId?: string | null;
  fibuKontonummer?: string | null;
  iban: string;
  updatedByMitarbeiterId?: string | null;
  fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
  fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
  sepaMandat?: {
    fileId?: string | null;
    fileName?: string | null;
    sepaMandatId: string;
    sepaMandatReference: string;
    signatureDate?: string | null;
    firmendaten?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      firmendatenId: string;
      kundenSystemId: string;
      kurzBezeichnung: string;
      name1: string;
      name2?: string | null;
      name3?: string | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    rechnungsAussteller?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
    warningList: Array<{ message: string; type: string }>;
  } | null;
  status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type ObjektBankDetailsFieldsFragment = {
  bezeichnung: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  objektBankDetailsId: string;
  updatedByMitarbeiterId?: string | null;
  bankDetails: {
    accountHolder: string;
    accountLimit?: number | null;
    assignedHeVertragZahlungsregelMap: any;
    assignedObjektMap: any;
    bankDetailsId: string;
    bankname: string;
    bic: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    ebicsCreditTransferUserList: Array<string>;
    ebicsDirectDebitUserList: Array<string>;
    fibuKontoId?: string | null;
    fibuKontonummer?: string | null;
    iban: string;
    updatedByMitarbeiterId?: string | null;
    fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
    fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
    sepaMandat?: {
      fileId?: string | null;
      fileName?: string | null;
      sepaMandatId: string;
      sepaMandatReference: string;
      signatureDate?: string | null;
      firmendaten?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firmendatenId: string;
        kundenSystemId: string;
        kurzBezeichnung: string;
        name1: string;
        name2?: string | null;
        name3?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechnungsAussteller?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
      warningList: Array<{ message: string; type: string }>;
    } | null;
    status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  verwendungList: Array<{ description?: string | null; text: string; value: Types.ObjektBankDetailsVerwendung }>;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type OrderBankDetailsFieldsFragment = { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string };

export const FirmendatenBankDetailsFieldsFragmentDoc = gql`
  fragment FirmendatenBankDetailsFields on FirmendatenBankDetails {
    accountHolder
    accountLimit
    allowObjektZuweisung
    assignedHeVertragZahlungsregelMap
    assignedObjektMap
    bankDetailsId
    bankname
    bic
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    ebicsCreditTransferUserList
    ebicsDirectDebitUserList
    fibuKontoId
    fibuKontonummer
    fibuStatus {
      text
      value
    }
    fibuVerwendung {
      text
      value
    }
    iban
    sepaMandat {
      ...SepaMandatFields
    }
    status {
      description
      text
      value
    }
    updatedByMitarbeiterId
    warningList {
      attribute
      message
      type
    }
  }
  ${SepaMandatFieldsFragmentDoc}
`;
export const BankDetailsFieldsFragmentDoc = gql`
  fragment BankDetailsFields on BankDetails {
    accountHolder
    accountLimit
    assignedHeVertragZahlungsregelMap
    assignedObjektMap
    bankDetailsId
    bankname
    bic
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    ebicsCreditTransferUserList
    ebicsDirectDebitUserList
    fibuKontoId
    fibuKontonummer
    fibuStatus {
      text
      value
    }
    fibuVerwendung {
      text
      value
    }
    iban
    sepaMandat {
      ...SepaMandatFields
    }
    status {
      description
      text
      value
    }
    updatedByMitarbeiterId
    warningList {
      attribute
      message
      type
    }
  }
  ${SepaMandatFieldsFragmentDoc}
`;
export const ObjektBankDetailsFieldsFragmentDoc = gql`
  fragment ObjektBankDetailsFields on ObjektBankDetails {
    bankDetails {
      ...BankDetailsFields
    }
    bezeichnung
    createTs
    createdBy
    createdByMitarbeiterId
    objektBankDetailsId
    updatedByMitarbeiterId
    verwendungList {
      description
      text
      value
    }
    warningList {
      attribute
      message
      type
    }
  }
  ${BankDetailsFieldsFragmentDoc}
`;
export const OrderBankDetailsFieldsFragmentDoc = gql`
  fragment OrderBankDetailsFields on OrderBankDetails {
    accountHolder
    bankDetailsId
    bankname
    bic
    iban
  }
`;
