import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { FileInfo, FileInfoType, Label } from '../../../../types';

export type DocumentRowProps = {
  record: FileInfo;
  rechnungTyp?: FileInfoType;
  invoiceIssuerId?: string;
  invoiceRecipientId?: string;
} & DetailedHTMLProps<HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>;

export const findLabelValueByLabelId = (labelList: Label[], id: string) => {
  const foundLabel = labelList.find((label) => label.label === id);
  return foundLabel ? foundLabel.value : '';
};
