import React, { FC } from 'react';
import { Alert, Button, Space } from 'antd';

type Props = {
  disabled: boolean;
  onSubmit: () => void;
  showNotSavedWarning?: boolean;
};

const AuszifferungSaveButton: FC<Props> = ({ disabled, onSubmit, showNotSavedWarning }) => {
  return (
    <Space direction="vertical" size="middle" align="end" style={{ width: '100%', height: '100%', justifyContent: 'flex-start' }}>
      <Space size="small">
        <Button type="primary" disabled={disabled} onClick={onSubmit}>
          Auszifferungen speichern
        </Button>
      </Space>
      <Space style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        {showNotSavedWarning ? <Alert message="Daten wurden noch nicht gespeichert" type="warning" showIcon /> : null}
      </Space>
    </Space>
  );
};
export default AuszifferungSaveButton;
