import React from 'react';
import { Route } from 'react-router';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import AuthRoute from '../../security/AuthRoute';
import { URI_HE_ABRECHNUNG_DETAILS_PAGE } from '../../features/Abrechnung/He/heAbrechnungUriPaths';
import HeAbrechnungDetailsPage from './HeAbrechnungDetailsPage';

export type HeAbrDetailsPageRouteParams = { objektId: string; heAbrechnungId: string };

const heAbrechnungDetailsPageRoute = (
  <Route
    path={`${URI_HE_ABRECHNUNG_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<HeAbrechnungDetailsPage />} />}
  />
);

export default heAbrechnungDetailsPageRoute;
