import React, { FC } from 'react';
import { Button } from 'antd';
import { useFormikContext } from 'formik';
import { useAuthorizedMitarbeiter } from '../../../../../../../../../shared/Auth/useAuthorized';
import {
  AuszifferungBlockFormValues,
  auszifferungFormFields,
  createNewAuszifferung,
  OffenePostenForBooking,
  offenePostenListForPersonenkontoFormFields,
} from '../../../../auszifferungFormMapper';
import {
  calculateInitialBetragForNewAuszifferung,
  disableAddButtonBasedOnAvailableAmount,
  getAuszifferungListFormFieldNameFromMainOPListBasedOnIndex,
  getOffenePostenIndexFromMainOPList,
  hasOPListExistingNotStorniertAuszifferung,
  isNewAuszifferung,
} from '../../../../auszifferungFormHelpers';
import { FibuBuchungType } from '../../../../../../../../../types';

type Props = {
  buchungType: FibuBuchungType;
  bookingZahlungId: string;
  offenePostenForPersonenkonto: OffenePostenForBooking;
  toggleExpandedRow: () => void;
  fieldNamePrefix: string;
};

const AddAuszifferungColumnForOPTableForPersonenkonto: FC<Props> = ({
  buchungType,
  bookingZahlungId,
  offenePostenForPersonenkonto,
  toggleExpandedRow,
  fieldNamePrefix,
}) => {
  const {
    mitarbeiter: { mitarbeiterId },
    user: { username },
  } = useAuthorizedMitarbeiter();

  const { values: formikValues, setFieldValue } = useFormikContext<AuszifferungBlockFormValues>();
  const auszifferungFieldName = `${fieldNamePrefix}.${offenePostenListForPersonenkontoFormFields.auszifferungList}`;

  // Get index from OP List for Booking to check if OP already exists in that list
  let opIndexFromMainOPList = getOffenePostenIndexFromMainOPList(formikValues, offenePostenForPersonenkonto);

  const handleAdd = () => {
    toggleExpandedRow();

    // Step 1. If OP doesn't exist in the OP List for Booking, then add it and update the OP index for that list

    if (opIndexFromMainOPList === -1) {
      const newOPList = [...formikValues.offenePostenListFromBooking, offenePostenForPersonenkonto];
      opIndexFromMainOPList = formikValues.offenePostenListFromBooking.length;
      setFieldValue(auszifferungFormFields.offenePostenListFromBooking, newOPList);
    }

    // Step 2. add new Auszifferung to the OP from the OPListForBooking

    const newAuszifferung = createNewAuszifferung(
      offenePostenForPersonenkonto,
      mitarbeiterId,
      username,
      calculateInitialBetragForNewAuszifferung(formikValues, offenePostenForPersonenkonto, buchungType)
    );
    const newAuszifferungList = [...offenePostenForPersonenkonto.auszifferungList, newAuszifferung];
    const auszifferungListForOPForBookingFormFieldName = getAuszifferungListFormFieldNameFromMainOPListBasedOnIndex(opIndexFromMainOPList);

    setFieldValue(auszifferungListForOPForBookingFormFieldName, newAuszifferungList);
    setFieldValue(auszifferungFieldName, newAuszifferungList);
  };

  // Add button should be disabled if the Auszifferung list in OffenePosten already has a new Auszifferung or an existing but not storniert Auszifferung
  const hasAzListNewOrExistingButNotStorniertAuszifferung =
    offenePostenForPersonenkonto.auszifferungList.some(isNewAuszifferung) ||
    hasOPListExistingNotStorniertAuszifferung(bookingZahlungId, [offenePostenForPersonenkonto]);

  return (
    <Button
      type="primary"
      disabled={
        hasAzListNewOrExistingButNotStorniertAuszifferung || disableAddButtonBasedOnAvailableAmount(formikValues.availableAmount, buchungType)
      }
      onClick={handleAdd}
    >
      Auszifferung hinzufügen
    </Button>
  );
};

export default AddAuszifferungColumnForOPTableForPersonenkonto;
