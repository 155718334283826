import { TContactPersonAddressOrContactListItem } from './AddressAndContactList';
import { AddressesAndContacts } from '../../../../types';

export type TAdresseContactContactPersonListItem = TContactPersonAddressOrContactListItem & { id: string };

export const buildAdresseAndContactListWithId = (addressesAndContacts?: AddressesAndContacts): TAdresseContactContactPersonListItem[] => {
  return buildAdresseAndContactList(addressesAndContacts).map(addIdToListItem);
};

export const buildAdresseAndContactList = (addressesAndContacts?: AddressesAndContacts): TContactPersonAddressOrContactListItem[] => {
  const hauptAddress = getHauptAddress(addressesAndContacts);
  const streetAddressList = addressesAndContacts?.streetAddressList ?? [];
  const postofficeBoxAddressList = addressesAndContacts?.postofficeBoxAddressList ?? [];

  const contactPersonList = addressesAndContacts?.contactPersonList ?? [];

  const hauptEmailContact = getHauptEmailContact(addressesAndContacts);
  const emailContactList = addressesAndContacts?.emailContactList ?? [];

  const hauptPhoneContact = getHauptPhoneContact(addressesAndContacts);
  const phoneContactList = addressesAndContacts?.phoneContactList ?? [];

  const addressAndContactList: TContactPersonAddressOrContactListItem[] = [
    ...contactPersonList,
    ...streetAddressList,
    ...emailContactList,
    ...phoneContactList,
    ...postofficeBoxAddressList,
  ];
  if (hauptPhoneContact) {
    addressAndContactList.unshift(hauptPhoneContact);
  }
  if (hauptEmailContact) {
    addressAndContactList.unshift(hauptEmailContact);
  }
  if (hauptAddress) {
    addressAndContactList.unshift(hauptAddress);
  }

  return addressAndContactList;
};

export const getHauptAddress = (addressOrContact?: AddressesAndContacts) => {
  return addressOrContact?.hauptAddress ?? undefined;
};

export const getHauptEmailContact = (addressOrContact?: AddressesAndContacts) => {
  return addressOrContact?.hauptEmailContact ?? undefined;
};

export const getHauptPhoneContact = (addressOrContact?: AddressesAndContacts) => {
  return addressOrContact?.hauptPhoneContact ?? undefined;
};

export const addIdToListItem = (item: TContactPersonAddressOrContactListItem) => ({
  ...item,
  id: item.createTs,
});

export const CLASS_OPERATING_SITE_LIST = 'operatingSite-list';
export const CLASS_DRAG_AND_DROP_HANDLE = 'handle';
export const CLASS_DRAG_AND_DROP_HANDLE_REF = `.${CLASS_DRAG_AND_DROP_HANDLE}`;

export const toggleGrabbingClassToAllOperatingSiteLists = () => {
  const list = document.getElementsByClassName(CLASS_OPERATING_SITE_LIST);
  if (list.length > 0) {
    for (let i = 0; i < list.length; i += 1) {
      list[i].classList.contains('grabbing') ? list[i].classList.remove('grabbing') : list[i].classList.add('grabbing');
    }
  }
};
