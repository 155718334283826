import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TransactionInstructionListQueryVariables = Types.Exact<{
  paymentId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  fibuBuchungId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type TransactionInstructionListQuery = {
  getTransactionInstructionList: {
    data: Array<{
      amount: number;
      buchungskreisRechtstraegerId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      creditor: string;
      creditorIban: string;
      dataCarrierId?: string | null;
      dataCarrierName?: string | null;
      debtor: string;
      debtorIban: string;
      endToEndId?: string | null;
      invoiceIssuerName: string;
      paymentInformationId?: string | null;
      paymentReferenceText?: string | null;
      purposeOfUseText?: string | null;
      requestedExecutionDate: string;
      sepaCreditorId?: string | null;
      sepaMandateId?: string | null;
      transactionInstructionId: string;
      paymentTransactionType?: { text: string; value: Types.PaymentTransactionType } | null;
      paymentType: { text: string; value: Types.PaymentType };
      transactionInstructionType: { text: string; value: Types.TransactionInstructionType };
      warningList: Array<{ message: string; type: string }>;
    }>;
    errorList: Array<{ type: string; message: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const TransactionInstructionListDocument = gql`
  query TransactionInstructionList($paymentId: String, $fibuBuchungId: String) {
    getTransactionInstructionList(paymentId: $paymentId, fibuBuchungId: $fibuBuchungId) {
      data {
        amount
        buchungskreisRechtstraegerId
        createTs
        createdBy
        createdByMitarbeiterId
        creditor
        creditorIban
        dataCarrierId
        dataCarrierName
        debtor
        debtorIban
        endToEndId
        invoiceIssuerName
        paymentInformationId
        paymentReferenceText
        paymentTransactionType {
          text
          value
        }
        paymentType {
          text
          value
        }
        purposeOfUseText
        requestedExecutionDate
        sepaCreditorId
        sepaMandateId
        transactionInstructionId
        transactionInstructionType {
          text
          value
        }
        warningList {
          message
          type
        }
      }
      errorList {
        type
        message
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useTransactionInstructionListQuery(
  baseOptions?: Apollo.QueryHookOptions<TransactionInstructionListQuery, TransactionInstructionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TransactionInstructionListQuery, TransactionInstructionListQueryVariables>(TransactionInstructionListDocument, options);
}
export function useTransactionInstructionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TransactionInstructionListQuery, TransactionInstructionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TransactionInstructionListQuery, TransactionInstructionListQueryVariables>(TransactionInstructionListDocument, options);
}
export function useTransactionInstructionListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TransactionInstructionListQuery, TransactionInstructionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TransactionInstructionListQuery, TransactionInstructionListQueryVariables>(
    TransactionInstructionListDocument,
    options
  );
}
export type TransactionInstructionListQueryHookResult = ReturnType<typeof useTransactionInstructionListQuery>;
export type TransactionInstructionListLazyQueryHookResult = ReturnType<typeof useTransactionInstructionListLazyQuery>;
export type TransactionInstructionListSuspenseQueryHookResult = ReturnType<typeof useTransactionInstructionListSuspenseQuery>;
export type TransactionInstructionListQueryResult = Apollo.QueryResult<TransactionInstructionListQuery, TransactionInstructionListQueryVariables>;
