import React, { FC, useEffect } from 'react';
import { FormikProps } from 'formik';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import { useRechtstraegerListQuery } from '../../../../Rechtstraeger/gql/RechtsTraegerQueries.types';
import { bookingSuggestionFormFields, BookingSuggestionFormValues, bSBuchungsanweisungFormFields } from '../../Form/buchungsmaskeFormMapper';

type Props = {
  formikProps: FormikProps<BookingSuggestionFormValues>;
} & Omit<SelectProps, 'id' | 'placeholder' | 'filterOption'>;

const BuchungskreisSelect: FC<Props> = ({ formikProps, ...restProps }) => {
  const { data, loading } = useRechtstraegerListQuery({
    variables: {
      hasBuchungskreis: true,
    },
  });

  const rechtstraegerWithBuchungskreisList = data?.getRechtstraegerList.data.contentList || [];

  useEffect(() => {
    const getRechtstraeger = rechtstraegerWithBuchungskreisList.filter(
      (rechtstraeger) => rechtstraeger.buchungskreisId === formikProps.values.buchungsanweisung.buchungskreisId
    );

    if (getRechtstraeger.length) {
      formikProps.setFieldValue(
        `${bookingSuggestionFormFields.buchungsanweisung}.${bSBuchungsanweisungFormFields.buchungskreisRechtstraegerId}`,
        getRechtstraeger[0].rechtstraegerId
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rechtstraegerWithBuchungskreisList]);

  return (
    <Select
      {...restProps}
      id={restProps.name}
      name={restProps.name}
      loading={loading}
      placeholder="Buchungskreis auswählen"
      allowClear
      showSearch
      style={{ width: '100%' }}
      size="small"
      filterOption={selectFilterOption}
      onChange={(value) => {
        formikProps.setFieldValue(
          `${bookingSuggestionFormFields.buchungsanweisung}.${bSBuchungsanweisungFormFields.buchungskreisRechtstraegerId}`,
          rechtstraegerWithBuchungskreisList.filter((rechtstraeger) => rechtstraeger.buchungskreisId === value)[0].rechtstraegerId
        );
      }}
    >
      {rechtstraegerWithBuchungskreisList.map((rechtstrager) => (
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        <Select.Option key={rechtstrager.buchungskreisId!} value={rechtstrager.buchungskreisId!}>
          {rechtstrager.kurzBezeichnung}
        </Select.Option>
      ))}
    </Select>
  );
};

export default BuchungskreisSelect;
