import { NotificationInfoKeyValue } from '../../../types';

enum NotificationParameterKeyTypes {
  generierlaufId = 'generierlaufId',
  objektAbrechnungId = 'objektAbrechnungId',
  objektKurzBezeichnung = 'objektKurzBezeichnung',
  vorschreibungsPeriode = 'vp',
  vonInklusive = 'vonInklusive',
  bisInklusive = 'bisInklusive',
  message = 'message',
  errorListSize = 'errorListSize',
  errorMessage = 'errorMessage',
  status = 'status',
  type = 'type',
  buchungsanweisungId = 'buchungsanweisungId',
  belegId = 'belegId',
  buchungskreisRechtstraegerId = 'buchungskreisRechtstraegerId',
  buchungId = 'buchungId',
  budgetingBezeichnung = 'budgetingBezeichnung',
  indexationAgreementId = 'indexationAgreementId',
  vertragKurzBezeichnung = 'vertragKurzBezeichnung',
}

const findParameterValue = (parameterList: NotificationInfoKeyValue[], key: NotificationParameterKeyTypes | string) =>
  parameterList.find((parameter) => parameter.key === key)?.value;

export { NotificationParameterKeyTypes, findParameterValue };
