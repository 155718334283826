import { isEmpty } from 'lodash';
import { TextbausteinFormValues } from '../../templateMapper';

export const isTextbausteinVariantComplete = (textbaustein: TextbausteinFormValues): boolean => {
  // leaf element
  if (isEmpty(textbaustein.textbausteinList) && textbaustein.value.length > 0) {
    return true;
  }
  // not leaf element
  if (textbaustein.textbausteinList && textbaustein.textbausteinList.length > 0) {
    return textbaustein.textbausteinList.every(isTextbausteinVariantComplete);
  }
  return false;
};
