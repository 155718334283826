import { Space } from 'antd';

import React, { FC } from 'react';
import TimelineCardContentInfoRow from '../../../../components/Timeline/Card/TimelineCardContentInfoRow';
import { ITimeblock } from '../../../../components/Timeline/timelineCtx';
import InnerCardContentAufwandskonto from '../../shared/InnerCardContentAufwandskonto';
import { FirmendatenPauschalmieteAufwandskonto } from '../../../../types';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  timeblock: ITimeblock<FirmendatenPauschalmieteAufwandskonto>;
};

const AufwandskontoVersionCardContent: FC<Props> = ({ timeblock }) => (
  <Space direction="vertical" style={{ width: '100%' }}>
    <TimelineCardContentInfoRow infoRowTitle="Gültig ab">
      <CustomFormattedDate value={timeblock.validFrom} />
    </TimelineCardContentInfoRow>
    <TimelineCardContentInfoRow infoRowTitle="Aufwandskonto">
      <InnerCardContentAufwandskonto kontoId={timeblock.pauschalmieteAufwandskontoId} />
    </TimelineCardContentInfoRow>
  </Space>
);

export default AufwandskontoVersionCardContent;
