import React from 'react';
import { Tag } from 'antd';
import { Flex, Box } from 'rebass';

const VerifiedTag = (props: { isVerified: boolean }) =>
  props.isVerified ? <Tag color="green">verifiziert</Tag> : <Tag color="orange">nicht verifiziert</Tag>;

type EmailVerifiedProps = {
  email?: string | null;
  emailUnverified?: string | null;
  state?: 'table';
};

const EmailVerified = (props: EmailVerifiedProps) => {
  const { email, emailUnverified, state } = props;
  switch (state) {
    case 'table':
      return (
        <Flex>
          <Box>{getEmail(email, emailUnverified)}</Box>
          <Box mx="auto" />
          <VerifiedTag isVerified={!!email} />
        </Flex>
      );
    default:
      return (
        <>
          {email ? (
            <Flex>
              <Box>{email}</Box>
              <Box mx="auto" />
              <VerifiedTag isVerified />
            </Flex>
          ) : null}
          {emailUnverified ? (
            <Flex>
              <Box>{emailUnverified}</Box>
              <Box mx="auto" />
              <VerifiedTag isVerified={false} />
            </Flex>
          ) : null}
        </>
      );
  }
};

const getEmail = (email?: string | null, emailUnverified?: string | null) => email || emailUnverified;

export default EmailVerified;
