import { Select, SelectProps } from 'formik-antd';
import React, { FC } from 'react';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { useFilterAuftragVertragsartListQuery } from '../../gql/AuftragQueries.types';

type Props = {
  name: string;
} & SelectProps;

const VertragsartListFormSelect: FC<Props> = ({ name, ...restProps }) => {
  const { loading, data } = useFilterAuftragVertragsartListQuery();
  const list = data?.getFilterAuftragVertragsartList.data ?? [];

  return (
    <Select
      size="middle"
      placeholder=""
      style={{ minWidth: '220px' }}
      {...restProps}
      name={name}
      id={name}
      loading={loading}
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {list.map((vertragsart) => (
        <Select.Option key={vertragsart.value} value={vertragsart.value}>
          <DataWithShortenedText maxTextLength={35} text={vertragsart.text} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default VertragsartListFormSelect;
