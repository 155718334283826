import React from 'react';
import { Link } from 'react-router-dom';
import { HeAbrAbrechnungskreis } from '../../../../../../types';
import { TableWithAlignedColsColumnType } from '../../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';
import { EuroAmount } from '../../../../../../components/Number';
import { generatePathToAbrechnungsdefinitionVersionViewPage } from '../../../../../Abrechnungsdefinition/abrDefUriPaths';

const heAbrHeVertragAbrKreisColumns: TableWithAlignedColsColumnType<HeAbrAbrechnungskreis>[] = [
  {
    title: '',
    align: 'left',
    render: (abrKreis) => (
      <Link
        to={generatePathToAbrechnungsdefinitionVersionViewPage(abrKreis.heAbrechnungsdefinitionId, abrKreis.heAbrechnungsdefinitionVersionId, true)}
        target="_blank"
      >
        {abrKreis.bezeichnung}
      </Link>
    ),
  },
  {
    title: '',
    colSpan: 2,
    render: (abrKreis) => <EuroAmount value={abrKreis.summe} />,
  },
  {
    title: '',
    render: () => <TextForEmptyValue textToShow="none" />,
  },
];

export default heAbrHeVertragAbrKreisColumns;
