import React, { FC } from 'react';
import { View } from '@react-pdf/renderer';
import { paddingLeftForRow } from '../HeADetailsBlock/DetailsTable/details-table-styles';
import pdfStyles from '../../../styles/pdfStyles';
import { KontoData } from '../../hauseigentuemerAbrechnung-types';
import HeABuchungszeile from './HeABuchungszeile';
import LabelValue from '../../../shared/LabelValue';

const PositionKontoListing: FC<{ kontoList: KontoData[] }> = ({ kontoList }) => (
  <View style={paddingLeftForRow.konto}>
    {kontoList.map((konto, index) => (
      <View key={index} style={pdfStyles.column}>
        <LabelValue valueTuple={konto.konto} labelStyle={{ textDecoration: 'underline' }} />
        {/* Buchungzeilen list */}
        {konto.buchungszeileList?.map((buchungzeile, index) => <HeABuchungszeile key={index} buchungszeile={buchungzeile} />)}
      </View>
    ))}
  </View>
);

export default PositionKontoListing;
