import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { ZVorschreibungspositionHeaderText, ZVPosSummary } from '../../zinsliste-types';
import pdfStyles from '../../../styles/pdfStyles';
import ZVPosListVPosList from './ZVPosListVPosList';
import { zVposColumnWidthsAndAlignment } from '../styles/zVPos-table-styles';

type Props = {
  vPosVertragsartListSummary: ZVPosSummary[];
  vPosHeaderText: ZVorschreibungspositionHeaderText;
};

const ZVPosListSummaryList: FC<Props> = ({ vPosVertragsartListSummary, vPosHeaderText }) => {
  return (
    <View>
      {vPosVertragsartListSummary.map((vPosVertragsartListSummary, index) => (
        <View key={index}>
          <View style={[pdfStyles.row, { margin: '2mm 0' }]}>
            <View style={{ width: '40%', fontWeight: 'bold' }}>
              <Text style={[pdfStyles.textNormal]}>{vPosVertragsartListSummary.vertragsart}</Text>
            </View>
            <View style={[pdfStyles.row, { width: '60%' }]}>
              <Text style={[pdfStyles.textNormal, { ...zVposColumnWidthsAndAlignment.netto }]}>{vPosVertragsartListSummary.sumNetto}</Text>
              <Text style={[{ width: zVposColumnWidthsAndAlignment.ustSatz.width }]} />
              <Text style={[{ width: zVposColumnWidthsAndAlignment.ustBetrag.width }]} />
              <Text style={[pdfStyles.textNormal, { ...zVposColumnWidthsAndAlignment.brutto }]}>{vPosVertragsartListSummary.sumBrutto}</Text>
            </View>
          </View>
          {vPosVertragsartListSummary.vorschreibungspositionList && vPosVertragsartListSummary.vorschreibungspositionList.length > 0 ? (
            <ZVPosListVPosList vorschreibungspositionList={vPosVertragsartListSummary.vorschreibungspositionList} vPosHeaderText={vPosHeaderText} />
          ) : null}
        </View>
      ))}
    </View>
  );
};

export default ZVPosListSummaryList;
