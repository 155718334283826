import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { GeneralTextbausteinFieldsFragmentDoc } from '../../Textbaustein/gql/TextbausteinFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TextbausteinTemplateListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TextbausteinTemplateListQuery = {
  getTextbausteinTemplateList: {
    data: Array<{
      bezeichnung: string;
      createdBy?: string | null;
      createTs: string;
      createdByMitarbeiterId?: string | null;
      firmendatenId?: string | null;
      textbausteinId: string;
      auftragsartList: Array<{
        auftragsartId: string;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        showUstBlock: boolean;
        updatedByMitarbeiterId?: string | null;
        status: { text: string; value: Types.AuftragsartStatus };
        type: { text: string; value: Types.AuftragsartType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      textbaustein: {
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      };
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type TextbausteinTemplateChangeHistoryListQueryVariables = Types.Exact<{
  textbausteinId: Types.Scalars['ID']['input'];
}>;

export type TextbausteinTemplateChangeHistoryListQuery = {
  getTextbausteinTemplateChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const TextbausteinTemplateListDocument = gql`
  query TextbausteinTemplateList {
    getTextbausteinTemplateList {
      data {
        ...GeneralTextbausteinFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${GeneralTextbausteinFieldsFragmentDoc}
`;
export function useTextbausteinTemplateListQuery(
  baseOptions?: Apollo.QueryHookOptions<TextbausteinTemplateListQuery, TextbausteinTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TextbausteinTemplateListQuery, TextbausteinTemplateListQueryVariables>(TextbausteinTemplateListDocument, options);
}
export function useTextbausteinTemplateListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TextbausteinTemplateListQuery, TextbausteinTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TextbausteinTemplateListQuery, TextbausteinTemplateListQueryVariables>(TextbausteinTemplateListDocument, options);
}
export function useTextbausteinTemplateListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TextbausteinTemplateListQuery, TextbausteinTemplateListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TextbausteinTemplateListQuery, TextbausteinTemplateListQueryVariables>(TextbausteinTemplateListDocument, options);
}
export type TextbausteinTemplateListQueryHookResult = ReturnType<typeof useTextbausteinTemplateListQuery>;
export type TextbausteinTemplateListLazyQueryHookResult = ReturnType<typeof useTextbausteinTemplateListLazyQuery>;
export type TextbausteinTemplateListSuspenseQueryHookResult = ReturnType<typeof useTextbausteinTemplateListSuspenseQuery>;
export type TextbausteinTemplateListQueryResult = Apollo.QueryResult<TextbausteinTemplateListQuery, TextbausteinTemplateListQueryVariables>;
export const TextbausteinTemplateChangeHistoryListDocument = gql`
  query TextbausteinTemplateChangeHistoryList($textbausteinId: ID!) {
    getTextbausteinTemplateChangeHistoryList(textbausteinId: $textbausteinId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useTextbausteinTemplateChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<TextbausteinTemplateChangeHistoryListQuery, TextbausteinTemplateChangeHistoryListQueryVariables> &
    ({ variables: TextbausteinTemplateChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TextbausteinTemplateChangeHistoryListQuery, TextbausteinTemplateChangeHistoryListQueryVariables>(
    TextbausteinTemplateChangeHistoryListDocument,
    options
  );
}
export function useTextbausteinTemplateChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TextbausteinTemplateChangeHistoryListQuery, TextbausteinTemplateChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TextbausteinTemplateChangeHistoryListQuery, TextbausteinTemplateChangeHistoryListQueryVariables>(
    TextbausteinTemplateChangeHistoryListDocument,
    options
  );
}
export function useTextbausteinTemplateChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TextbausteinTemplateChangeHistoryListQuery, TextbausteinTemplateChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TextbausteinTemplateChangeHistoryListQuery, TextbausteinTemplateChangeHistoryListQueryVariables>(
    TextbausteinTemplateChangeHistoryListDocument,
    options
  );
}
export type TextbausteinTemplateChangeHistoryListQueryHookResult = ReturnType<typeof useTextbausteinTemplateChangeHistoryListQuery>;
export type TextbausteinTemplateChangeHistoryListLazyQueryHookResult = ReturnType<typeof useTextbausteinTemplateChangeHistoryListLazyQuery>;
export type TextbausteinTemplateChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useTextbausteinTemplateChangeHistoryListSuspenseQuery>;
export type TextbausteinTemplateChangeHistoryListQueryResult = Apollo.QueryResult<
  TextbausteinTemplateChangeHistoryListQuery,
  TextbausteinTemplateChangeHistoryListQueryVariables
>;
