import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import HistoryModal, { HistoryType } from '../../History/shared/HistoryModal';
import AuftragsartTemplateGeneralChangeHistoryListingTable from './AuftragsartTemplateGeneralChangeHistoryListingTable';
import AuftragsartTemplateTexteChangeHistoryListingTable from './AuftragsartTemplateTexteChangeHistoryListingTable';

type Props = {
  auftragsartId: string;
  open: boolean;
  onCancel: () => void;
};

const AuftragsartTemplateChangeHistoryModal: FC<Props> = ({ auftragsartId, open, onCancel }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Allgemeine Parameter',
      children: <AuftragsartTemplateGeneralChangeHistoryListingTable auftragsartId={auftragsartId} />,
    },
    {
      key: '2',
      label: 'PDF-Vorlage',
      children: <AuftragsartTemplateTexteChangeHistoryListingTable auftragsartId={auftragsartId} />,
    },
  ];

  return (
    <HistoryModal historyType={HistoryType.Change} open={open} onCancel={onCancel}>
      <Tabs items={items} />
    </HistoryModal>
  );
};

export default AuftragsartTemplateChangeHistoryModal;
