import { FC } from 'react';
import { Space, Tooltip, Typography } from 'antd';
import {
  AbstractVertragVposIndexationAgreement,
  ValuePreservationType,
  VertragVposIndexationAgreementCategory,
  VertragVposIndexationAgreementIndexAnnual,
  VertragVposIndexationAgreementIndexThreshold,
} from '../../../../types';
import ReferenceValueTooltipTitle from './ReferenceValueTooltipTitle';
import CategoryTooltipTitle from './CategoryTooltipTitle';
import IndexAnnualTooltipTitle from './IndexAnnualTooltipTitle';
import IndexThresholdTooltipTitle from './IndexThresholdTooltipTitle';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import TypographyTextForTooltip from '../../../../components/Text/TypographyTextForTooltip';

type Props = {
  indexationAgreement: AbstractVertragVposIndexationAgreement;
};

const ValuePreservationSettings: FC<Props> = ({ indexationAgreement }) => {
  return (
    <Space size="small" align="end" style={{ width: '100%', justifyContent: 'space-between' }}>
      <Typography.Text>{indexationAgreement.type.text}:</Typography.Text>
      <Tooltip
        title={
          <>
            <TypographyTextForTooltip text={`${indexationAgreement.type.text}:`} />
            <br />
            <SettingsTooltipTitle indexationAgreement={indexationAgreement} />
          </>
        }
        placement="right"
      >
        [...]
      </Tooltip>
    </Space>
  );
};

const SettingsTooltipTitle: FC<Props> = ({ indexationAgreement }) => {
  switch (indexationAgreement.type.value) {
    case ValuePreservationType.ReferenceValue:
      return <ReferenceValueTooltipTitle />;
    case ValuePreservationType.Category:
      return <CategoryTooltipTitle indexationAgreement={indexationAgreement as VertragVposIndexationAgreementCategory} />;
    case ValuePreservationType.IndexAnnual:
      return <IndexAnnualTooltipTitle indexationAgreement={indexationAgreement as VertragVposIndexationAgreementIndexAnnual} />;
    case ValuePreservationType.IndexThreshold:
      return <IndexThresholdTooltipTitle indexationAgreement={indexationAgreement as VertragVposIndexationAgreementIndexThreshold} />;
    default:
      return <TextForEmptyValue textToShow="none" />;
  }
};

export default ValuePreservationSettings;
