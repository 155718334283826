import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { VUstListHeader } from '../../vorschreibung-types';
import { ustColumnWidthsAndAlignment } from './ust-table-styles';

const VTUstTableColumnTitles: FC<{ data: VUstListHeader }> = ({ data }) => (
  <View style={[pdfStyles.row, { fontWeight: 'bold' }]}>
    <Text
      style={[
        pdfStyles.textBulletin,
        { width: ustColumnWidthsAndAlignment.ustSatz.width, textAlign: ustColumnWidthsAndAlignment.ustSatz.textAlign, fontWeight: 'bold' },
      ]}
    >
      {data.ustSatzText}
    </Text>
    <Text
      style={[pdfStyles.textBulletin, { width: ustColumnWidthsAndAlignment.netto.width, textAlign: ustColumnWidthsAndAlignment.netto.textAlign }]}
    >
      {data.nettoText}
    </Text>
    <Text style={[pdfStyles.textBulletin, { width: ustColumnWidthsAndAlignment.ust.width, textAlign: ustColumnWidthsAndAlignment.ust.textAlign }]}>
      {data.ustBetragText}
    </Text>
    <Text
      style={[pdfStyles.textBulletin, { width: ustColumnWidthsAndAlignment.brutto.width, textAlign: ustColumnWidthsAndAlignment.brutto.textAlign }]}
    >
      {data.bruttoText}
    </Text>
  </View>
);

export default VTUstTableColumnTitles;
