import { Col, Row, Tabs, TabsProps } from 'antd';
import React from 'react';
import TabPaneContent from '../../../components/Tabs/TabPaneContent';

const Todos = () => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Aufgaben',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <Row justify="space-between" align="middle">
            <Col flex="auto">@TODO Aufgaben</Col>
          </Row>
        </TabPaneContent>
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default Todos;
