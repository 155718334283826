import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../styles/pdfStyles';
import { LabeledValue } from '../../shared/types';
import LabelValue from '../../shared/LabelValue';

const HeASummary: FC<{ zahlungsrueckstaendeAnteilHe?: LabeledValue | null; endErgebnis: LabeledValue }> = ({
  zahlungsrueckstaendeAnteilHe,
  endErgebnis,
}) => (
  <View style={[pdfStyles.row, pdfStyles.tablePaddingLeftRight, { marginTop: '10mm' }]}>
    <View style={[pdfStyles.column, { width: '45%' }]} />

    <View style={[pdfStyles.column, { width: '55%' }]}>
      {/* Zahlungsrueckstände Anteil Hauseigentuemer */}
      {zahlungsrueckstaendeAnteilHe ? (
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={[pdfStyles.textBulletin, { width: '70%' }]}>{zahlungsrueckstaendeAnteilHe.label}</Text>
          <Text style={[pdfStyles.textBulletin, { width: '30%', fontWeight: 'bold', alignItems: 'flex-end', textAlign: 'right' }]}>
            {zahlungsrueckstaendeAnteilHe.value}
          </Text>
        </View>
      ) : null}
      {/* ErgebnisTotal */}
      <LabelValue
        valueTuple={endErgebnis}
        labelStyle={{ width: '70%', fontWeight: 'bold' }}
        valueStyle={{ width: '30%', fontWeight: 'bold', alignItems: 'flex-end', textAlign: 'right' }}
      />
    </View>
  </View>
);

export default HeASummary;
