import React, { FC } from 'react';
import { Col, Space, Typography } from 'antd';
import { KontoKlasseTuple, KontoVerwendungTuple, SollHaben } from '../../../../../types';

export type KontoForCard = {
  bezeichnung: string;
  klasse: KontoKlasseTuple;
  kontoId: string;
  nummer: string;
  verwendung?: KontoVerwendungTuple | null;
};

type Props = {
  konto: KontoForCard;
  kontoType: SollHaben;
};

const KontoDetails: FC<Props> = ({ konto, kontoType }) => {
  return (
    <Col>
      <Typography.Title level={5}>{kontoType}</Typography.Title>
      <Space size="large" direction="vertical">
        <Space>
          <Typography.Text type="secondary">Kontonummer</Typography.Text>
          <Typography.Text>{konto.nummer}</Typography.Text>
        </Space>
        <Space>
          <Typography.Text type="secondary">Bezeichnung</Typography.Text>
          <Typography.Text>{konto.bezeichnung}</Typography.Text>
        </Space>
      </Space>
    </Col>
  );
};

export default KontoDetails;
