import { FC } from 'react';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';
import { useMahndefinitionTemplateChangeHistoryListQuery } from '../gql/MahndefinitionTemplateQueries.types';

type Props = {
  mahndefinitionId: string;
};

const MahndefinitionTemplateChangeHistoryListingTable: FC<Props> = ({ mahndefinitionId }) => {
  const { data, loading } = useMahndefinitionTemplateChangeHistoryListQuery({ variables: { mahndefinitionId } });
  const historyList = data?.getMahndefinitionTemplateChangeHistoryList.data;

  return (
    <HistoryListingTable
      historyType={HistoryType.Change}
      historyList={historyList}
      loading={loading}
      filterIdPrefix="mahndefinition-template-general"
    />
  );
};

export default MahndefinitionTemplateChangeHistoryListingTable;
