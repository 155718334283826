import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../../styles/pdfStyles';
import { RepFondsAbrechnungAusgabe } from '../../../../../topAbrechnung-types';

const TARepFondsAbrAusgabenKontoAusgabe: FC<{ ausgabe: RepFondsAbrechnungAusgabe }> = ({ ausgabe }) => (
  <View style={[pdfStyles.row, pdfStyles.tablePaddingLeftRight]}>
    <Text style={[pdfStyles.column, pdfStyles.textBulletinSmall, { width: '30%' }]}>{ausgabe.bezeichnung}</Text>
    <Text style={[pdfStyles.column, pdfStyles.textBulletinSmall, { width: '10%' }]}>{ausgabe.buchungsDatum}</Text>
    <Text style={[pdfStyles.column, pdfStyles.textBulletinSmall, { width: '15%' }]}>{ausgabe.belegnummer}</Text>
    <Text style={[pdfStyles.column, pdfStyles.textBulletinSmall, { width: '35%' }]}>{ausgabe.kurzbezeichnungVertragspartner}</Text>
    <Text
      style={[
        pdfStyles.column,
        pdfStyles.textBulletinSmall,
        {
          width: '10%',
          alignItems: 'flex-end',
          textAlign: 'right',
        },
      ]}
    >
      {ausgabe.betragNetto}
    </Text>
  </View>
);

export default TARepFondsAbrAusgabenKontoAusgabe;
