import { Button, Dropdown, MenuProps, Modal } from 'antd';
import React, { FC } from 'react';
import { DownOutlined, FilePdfOutlined } from '@ant-design/icons';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../components/Button/ButtonsAligned';
import { useToggle } from '../../hooks/useToggle';
import PDFExportOPListForm from './Form/PDFExportForm/PDFExportOPListForm';

type Props = {
  showAusgeziffert: boolean;
  setAusgeziffert: () => void;
};

const OffenePostenListingActionButtons: FC<Props> = ({ showAusgeziffert, setAusgeziffert }) => {
  const [showExportAsPdfModal, toggleExportAsPdfModal] = useToggle();

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'OP-Liste Debitoren PDF exportieren',
      icon: <FilePdfOutlined />,
      onClick: () => toggleExportAsPdfModal(),
    },
  ];

  return (
    <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
      <Button size="small" onClick={setAusgeziffert}>
        Ausgezifferte OPs {showAusgeziffert ? 'ausblenden' : 'anzeigen'}
      </Button>
      <Dropdown menu={{ items }}>
        <Button type="primary" size="small" onClick={(e) => e.preventDefault()}>
          Export <DownOutlined />
        </Button>
      </Dropdown>
      <Modal
        title="OP-Liste Debitoren PDF exportieren"
        onCancel={toggleExportAsPdfModal}
        open={showExportAsPdfModal}
        destroyOnClose
        maskClosable={false}
        footer={false}
      >
        <PDFExportOPListForm onAction={toggleExportAsPdfModal} />
      </Modal>
    </ButtonsAligned>
  );
};

export default OffenePostenListingActionButtons;
