import React from 'react';
import { Card, List, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { DownloadOutlined, PhoneTwoTone, UploadOutlined } from '@ant-design/icons';
import { useLast5CallLogListQuery } from './gql/CallLogQueries.types';
import DataWithShortenedText from '../../components/Helpers/DataWithShortenedText';
import { isCallLogTypeOutgoing } from '../Rechtstraeger/CallLog/callLogHelper';
import { useCreateOutgoingCallLogMutation } from './gql/CallLogMutations.types';
import CardTitle from '../../components/Card/CardTitle';
import { Spacer } from '../../components/Grid';
import { pathsToRechtstraegerDetails } from '../Rechtstraeger/rechtstraegerHelper';
import theme from '../../theme';

const DashboardCallLogListCard = () => {
  const [runOutgoingCallLog] = useCreateOutgoingCallLogMutation();

  const { data } = useLast5CallLogListQuery();

  const callLogList = data?.getLast5CallLogList.data ?? [];

  if (!callLogList || callLogList.length === 0) {
    return null;
  }

  return (
    <>
      <Card style={{ minWidth: '320px' }} size="default" variant="borderless" title={<CardTitle title="Letzten 5 Anrufe" />}>
        <List
          dataSource={callLogList}
          renderItem={(callLog) => (
            <List.Item style={{ padding: '2px 0' }}>
              <Space>
                {isCallLogTypeOutgoing(callLog.type.value) ? (
                  <UploadOutlined style={{ color: 'green', fontSize: '14px' }} />
                ) : (
                  <DownloadOutlined style={{ color: 'red', fontSize: '14px' }} />
                )}
                <DataWithShortenedText text={callLog.rechtstraeger.kurzBezeichnung} maxTextLength={25}>
                  {(shortenedText) => (
                    <Link
                      to={pathsToRechtstraegerDetails(callLog.rechtstraeger.rechtstraegerId).callLogMenu}
                      target="_blank"
                      style={{ color: theme.linkColor.primary }}
                    >
                      {shortenedText}
                    </Link>
                  )}
                </DataWithShortenedText>
              </Space>
              <a
                href={`tel:${callLog.phoneNumber}`}
                onMouseUp={() => {
                  runOutgoingCallLog({ variables: { rechtstraegerId: callLog.rechtstraeger.rechtstraegerId, phoneNumber: callLog.phoneNumber } });
                }}
              >
                <PhoneTwoTone style={{ paddingRight: '6px' }} />
                <Typography.Text type="secondary">{callLog.phoneNumber}</Typography.Text>
              </a>
            </List.Item>
          )}
        />
      </Card>
      <Spacer />
    </>
  );
};

export default DashboardCallLogListCard;
