import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { friendlyFormatIBAN } from 'ibantools';
import { useFirmendatenBankDetailsListQuery, useRechtstraegerBankDetailsListQuery } from '../../../BankDetails/gql/BankDetailsQueries.types';
import BankDetailsCreateDrawer from '../../../BankDetails/Drawer/BankDetailsCreateDrawer';
import { useToggle } from '../../../../hooks/useToggle';
import { useCreateNewItemInSelect } from '../../../../components/Select/useCreateNewItemInSelect';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { RechtstraegerRolle } from '../../../../types';
import { hasRechtstraegerRolleObjektRechnungsAussteller, hasRechtstraegerRolleWeRechnungsAussteller } from '../../../Rechtstraeger/Rolle/rolleHelper';
import { useWeRechnungsAusstellerListQuery } from '../../../BankDetails/gql/WeRechnungsAusstellerQueries.types';
import { useObjektBankDetailsListQuery } from '../../../Objekt/BankDetails/gql/ObjektBankDetailsQueries.types';

type Props = {
  firmendatenId: string;
  rechtstraegerId?: string;
  rechtstraegerRolleList?: RechtstraegerRolle[];
  objektId?: string | null;
  onNewItemCreateSelected: () => void;
  onSetNewCreatedBankDetailsId?: (id: string, allowObjektZuweisung?: boolean) => void;
} & SelectProps;

const IncomingInvoiceBankDetailsSelect: FC<Props> = ({
  firmendatenId,
  rechtstraegerId,
  rechtstraegerRolleList,
  objektId,
  onNewItemCreateSelected,
  onSetNewCreatedBankDetailsId,
  ...restProps
}) => {
  const [isCreateNewDrawerRAOpen, toggleCreateNewRADrawer] = useToggle();
  const [isCreateNewDrawerFDOpen, toggleCreateNewFDDrawer] = useToggle();

  const onNewItemCreateSelectedRAOpenDrawer = () => {
    onNewItemCreateSelected();
    toggleCreateNewRADrawer();
  };

  const onNewItemCreateSelectedFDOpenDrawer = () => {
    onNewItemCreateSelected();
    toggleCreateNewFDDrawer();
  };

  const textForRtBankDetails = getTextForRtBankDetails(rechtstraegerRolleList, objektId);

  const {
    data: fdBankDetailsListData,
    loading: loadingFdBankDetailsList,
    refetch: refetchFdBankDetailsList,
  } = useFirmendatenBankDetailsListQuery({ variables: { firmendatenId } });

  const {
    data: rtBankDetailsListData,
    loading: loadingRtBankDetailsList,
    refetch: refetchRtBankDetailsList,
  } = useRechtstraegerBankDetailsListQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { rechtstraegerId: rechtstraegerId! },
    skip: !(rechtstraegerId && !(hasRechtstraegerRolleWeRechnungsAussteller(rechtstraegerRolleList) && objektId)),
  });

  const {
    data: objBankDetailsListData,
    loading: loadingObjBankDetails,
    refetch: refetchObjBankDetails,
  } = useObjektBankDetailsListQuery({
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      objektId: objektId!,
    },
    skip: !(hasRechtstraegerRolleObjektRechnungsAussteller(rechtstraegerRolleList) && objektId),
  });

  const {
    data: weRaListData,
    loading: loadingWeRAList,
    refetch: refetchWeRAList,
  } = useWeRechnungsAusstellerListQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { objektId: objektId! },
    skip: !(objektId && hasRechtstraegerRolleWeRechnungsAussteller(rechtstraegerRolleList)),
  });

  const firmendatenBankDetailsList =
    fdBankDetailsListData?.getFirmendatenBankDetailsList.data.filter((bankDetails) => bankDetails.allowObjektZuweisung) ?? [];

  const weAusstellerList = weRaListData?.getWeRechnungsAusstellerList.data;

  let bankDetailsList = rtBankDetailsListData?.getRechtstraegerBankDetailsList.data ?? [];

  if (hasRechtstraegerRolleObjektRechnungsAussteller(rechtstraegerRolleList) && objektId) {
    const objBankDetailsList = objBankDetailsListData?.getObjektBankDetailsList.data.objektBankDetailsList ?? [];
    bankDetailsList = objBankDetailsList.map((objektBankDetails) => objektBankDetails.bankDetails);
  } else if (weAusstellerList) {
    const weAussteller = weAusstellerList.find((we) => we.weRechnungsAusstellerRechtstraegerId === rechtstraegerId);
    if (weAussteller) {
      bankDetailsList = weAussteller.objektBankDetailsList.map((objektBankDetails) => objektBankDetails.bankDetails);
    }
  }

  const onAction = () => {
    refetchFdBankDetailsList();
    if (rechtstraegerId) refetchRtBankDetailsList();
    if (objektId && hasRechtstraegerRolleWeRechnungsAussteller(rechtstraegerRolleList)) refetchWeRAList();
    if (objektId && hasRechtstraegerRolleObjektRechnungsAussteller(rechtstraegerRolleList)) refetchObjBankDetails();
  };

  const {
    handleSelect: handleSelectFD,
    handleSearch: handleSearchFD,
    renderCreateNewItem: renderCreateNewItemFD,
  } = useCreateNewItemInSelect(firmendatenBankDetailsList, onNewItemCreateSelectedFDOpenDrawer, 'Neue Bankverbindung für HV anlegen');

  const {
    handleSelect: handleSelectRA,
    handleSearch: handleSearchRA,
    renderCreateNewItem: renderCreateNewItemRA,
  } = useCreateNewItemInSelect(
    bankDetailsList,
    onNewItemCreateSelectedRAOpenDrawer,
    `Neue Bankverbindung für ${textForRtBankDetails} anlegen`,
    CREATE_NEW_ITEM_VALUE_RA
  );

  return (
    <>
      <Select
        loading={loadingFdBankDetailsList || loadingRtBankDetailsList || loadingObjBankDetails || loadingWeRAList}
        placeholder="Bankverbindung auswählen"
        filterOption={selectFilterOption}
        {...restProps}
        onSelect={(value: string) => {
          if (value === CREATE_NEW_ITEM_VALUE_RA) {
            handleSelectRA?.(value);
          } else {
            handleSelectFD?.(value);
          }
        }}
        showSearch
        onSearch={(value) => {
          if (value === CREATE_NEW_ITEM_VALUE_RA) {
            handleSearchRA?.(value);
          } else {
            handleSearchFD?.(value);
          }
        }}
      >
        <Select.OptGroup label={textForRtBankDetails}>
          {bankDetailsList
            .filter((bankDetails) => {
              return firmendatenBankDetailsList.length > 0
                ? firmendatenBankDetailsList.some((fdBankDetails) => {
                    return fdBankDetails.bankDetailsId !== bankDetails.bankDetailsId;
                  })
                : true;
            })
            .map((bankDetails) => (
              <Select.Option key={bankDetails.bankDetailsId} value={bankDetails.bankDetailsId}>
                {bankDetails.bankname} {friendlyFormatIBAN(bankDetails.iban)}
              </Select.Option>
            ))}
          {renderCreateNewItemRA()}
        </Select.OptGroup>
        <Select.OptGroup label="Hausverwaltung">
          {firmendatenBankDetailsList.map((bankDetails) => (
            <Select.Option key={bankDetails.bankDetailsId} value={bankDetails.bankDetailsId}>
              {bankDetails.bankname} {friendlyFormatIBAN(bankDetails.iban)}
            </Select.Option>
          ))}
          {renderCreateNewItemFD()}
        </Select.OptGroup>
      </Select>
      <BankDetailsCreateDrawer
        isDrawerOpen={isCreateNewDrawerRAOpen}
        toggleDrawerOpen={toggleCreateNewRADrawer}
        rechtstraegerId={rechtstraegerId}
        onActionSuccess={onAction}
        onSetFieldValue={onSetNewCreatedBankDetailsId}
      />
      <BankDetailsCreateDrawer
        isDrawerOpen={isCreateNewDrawerFDOpen}
        toggleDrawerOpen={toggleCreateNewFDDrawer}
        firmendatenId={firmendatenId}
        onActionSuccess={onAction}
        onSetFieldValue={onSetNewCreatedBankDetailsId}
        fibuKontoCreatable
      />
    </>
  );
};

const getTextForRtBankDetails = (rolleList?: RechtstraegerRolle[], objektId?: string | null) => {
  if (hasRechtstraegerRolleObjektRechnungsAussteller(rolleList) && objektId) {
    return 'Objekt-Rechnungsaussteller';
  } else if (hasRechtstraegerRolleWeRechnungsAussteller(rolleList) && objektId) {
    return 'WE-Rechnungsaussteller';
  }
  return 'Empfänger';
};

const CREATE_NEW_ITEM_VALUE_RA = 'create-new-select-item-value-ra';

export default IncomingInvoiceBankDetailsSelect;
