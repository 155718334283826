import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { generatePathToAbrechnungsdefinitionVersionViewPage } from '../../Abrechnungsdefinition/abrDefUriPaths';
import { InfoAbrKreis } from './InfoAbrKreisTable/Level1/InfoAbrKreisTable';
import { ObjektAbrechnungkreis } from '../../../types';

type Props = {
  abrKreis: ObjektAbrechnungkreis | InfoAbrKreis;
  isInfoAbrKreis?: boolean;
};

const AbrKreisBezeichnung: FC<Props> = ({ abrKreis, isInfoAbrKreis = false }) => {
  return (
    <Space>
      <Link
        target="_blank"
        to={generatePathToAbrechnungsdefinitionVersionViewPage(
          abrKreis.abrechnungsdefinitionId,
          abrKreis.abrechnungsdefinitionVersionId,
          true,
          abrKreis.abrechnungskreisId
        )}
      >
        {abrKreis.bezeichnung}
      </Link>
      {isInfoAbrKreis && <Typography.Text italic>(Information)</Typography.Text>}
    </Space>
  );
};

export default AbrKreisBezeichnung;
