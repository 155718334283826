import { useNavigate } from 'react-router-dom';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../shared/dataChangedSubscription';
import { DataChangedChangeType } from '../../types';
import { useBookingSuggestionWithCurrentBookingDataLazyQuery } from '../BookingSuggestion/gql/BookingSuggestionQueries.types';
import { generatePathToBookingDetailsWithBookingSuggestionPage } from './bookingDetailsPaths';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../helpers/apolloHelper';

const useOnBookingDataChangeEvents = (bookingSuggestionId: string) => {
  // Upon clicking "Aktualisieren", the user should be redirected to page with the current booking. This is done by calling the getBookingSuggestionWithCurrentBookingData function.

  const navigate = useNavigate();

  const [getBookingSuggestionWithCurrentBookingData] = useBookingSuggestionWithCurrentBookingDataLazyQuery({
    variables: { bookingSuggestionId },
    onCompleted: (data) => {
      const currentBookingData = data.getBookingSuggestion.data;
      currentBookingData &&
        // use navigate instead of window.open to smoothly open the new booking within the same browser tab
        navigate(generatePathToBookingDetailsWithBookingSuggestionPage(bookingSuggestionId, currentBookingData.fibuBuchungIdList));
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  useOnDataChangedEvent([
    {
      filter: {
        type: dataChangedSubscriptionTypes.bookingSuggestionFibuBuchungen,
        changeType: DataChangedChangeType.Update,
        params: {
          bookingSuggestionId,
        },
      },
      notificationDescription: 'Buchung wurde gerade verändert',
      key: 'booking-suggestion-fibu-buchungen-update',
      callback: getBookingSuggestionWithCurrentBookingData,
    },
  ]);
};

export default useOnBookingDataChangeEvents;
