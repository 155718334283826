import { FC } from 'react';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';
import { useAbrechnungskreisTemplateChangeHistoryListQuery } from '../gql/AbrechnungskreisTemplateQueries.types';

type Props = {
  abrechnungskreisId: string;
};

const AbrechnungskreisTemplateChangeHistoryListingTable: FC<Props> = ({ abrechnungskreisId }) => {
  const { data, loading } = useAbrechnungskreisTemplateChangeHistoryListQuery({ variables: { abrechnungskreisId } });
  const historyList = data?.getAbrechnungskreisTemplateChangeHistoryList.data;

  return (
    <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="abrechnungskreis-template" />
  );
};

export default AbrechnungskreisTemplateChangeHistoryListingTable;
