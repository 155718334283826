import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import TabPaneContent from '../../../../components/Tabs/TabPaneContent';
import VertragInfoFeldListing from './VertragInfoFeldListing';

type VertragInfoFeldTabProps = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
};

const VertragInfoFeldTab: FC<VertragInfoFeldTabProps> = ({ objektId, bestandseinheitId, vertragId }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Informationen',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <VertragInfoFeldListing objektId={objektId} bestandseinheitId={bestandseinheitId} vertragId={vertragId} />
        </TabPaneContent>
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default VertragInfoFeldTab;
