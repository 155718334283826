import { AuftragsartType, EigenbelegType, FibuBelegSymbol, FileInfoType, InvoiceType } from '../../../types';

const fileInfoTypeAbkuerzungForAvatar = (fileType: string) => {
  // EigenbelegType is used for Dokumenten listing, AuftragsartType is used for Vorschreibungen listing
  switch (fileType) {
    case FileInfoType.ObjektFinancialPlan:
      return 'OWP';
    case FileInfoType.TopFinancialPlan:
      return 'TWP';
    case InvoiceType.SingleInvoice:
      return 'EER';
    case InvoiceType.StandingInvoice:
      return 'EDR';
    case AuftragsartType.VorschreibungDauerrechnung:
    case EigenbelegType.VorschreibungDauerrechnung:
      return 'VDR';
    case AuftragsartType.VorschreibungEinzel:
    case EigenbelegType.VorschreibungEinzel:
      return 'VER';
    case AuftragsartType.VorschreibungEinzelAbrechnungsergebnisGutschrift:
    case EigenbelegType.VorschreibungAbrErgebnisGutschrift:
      return 'GST';
    case AuftragsartType.VorschreibungEinzelAbrechnungsergebnisRechnung:
    case EigenbelegType.VorschreibungAbrErgebnisRechnung:
      return 'EZR';
    case AuftragsartType.VorschreibungDauerrechnungStorno:
    case AuftragsartType.VorschreibungEinzelStorno:
    case AuftragsartType.VorschreibungEinzelAbrechnungsergebnisRechnungStorno:
    case AuftragsartType.VorschreibungEinzelAbrechnungsergebnisGutschriftStorno:
    case EigenbelegType.StornoRechnung:
    case 'STORNO_RECHNUNG':
      return 'STR';
    case FileInfoType.MailAttachment:
      return 'AHG';
    case FileInfoType.HeAbrechnung:
      return 'HEA';
    case FileInfoType.BkObjektAbrechnung:
      return 'BKO';
    case FileInfoType.WeObjektAbrechnung:
      return 'WEO';
    case FileInfoType.BkTopAbrechnung:
      return 'BKT';
    case FileInfoType.WeTopAbrechnung:
      return 'WET';
    case FibuBelegSymbol.Zahlung:
      return 'ZHG';
    case FibuBelegSymbol.Vorsteuerkuerzung:
      return 'VSK';
    case FileInfoType.SubAbrechnungObjekt:
      return 'SAO';
    case FileInfoType.SubAbrechnungBestandseinheit:
      return 'SAB';
    case 'Generierlauf':
      return 'GRL';
    case 'Zinsliste':
      return 'ZIL';
    case 'BANK':
      return 'BNK';
    case FileInfoType.Mahnung:
      return 'MHG';
    default:
      return '?';
  }
};

export default fileInfoTypeAbkuerzungForAvatar;
