import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { BudgetingBaseFieldsFragmentDoc } from '../../../Budgeting/gql/BudgetingFragments.types';
export type BudgetingGenerierlaufEntryFieldsFragment = {
  generierlaufEntryId: string;
  infoMessage?: string | null;
  budgeting?: {
    bezeichnung: string;
    budgetSumNextYear?: number | null;
    budgetingId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    currentYear: number;
    increase?: number | null;
    prevYear: number;
    updatedByMitarbeiterId?: string | null;
    vertragVPosWertDate?: string | null;
    vorschreibungCurrentYearSum: number;
    vorschreibungPrevYearSum: number;
    restOfCurrentYearSum?: number | null;
    totalCurrentYearSum?: number | null;
    objekt: {
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      objektId: string;
      updatedByMitarbeiterId?: string | null;
      rechnungsAussteller: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      status: { text: string; value: Types.ObjektStatus; description?: string | null };
      verwaltungsart: { text: string; value: Types.Verwaltungsart };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    resultSourceType?: { description?: string | null; text: string; value: Types.BudgetingResultSourceType } | null;
    status: { text: string; value: Types.BudgetingStatus };
    warningList: Array<{ message: string; type: string }>;
  } | null;
  errorList: Array<{ message: string; type: string }>;
  exitCode?: { value: Types.GenerierlaufEntryExitCode; text: string } | null;
  warningList: Array<{ message: string; type: string }>;
};

export type BudgetingGenerierlaufFieldsFragment = {
  bezeichnung: string;
  considerationPeriodUntil: string;
  countFehler: number;
  countVerarbeitet: number;
  countNichtVerarbeitet: number;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  generierDatum: string;
  generierlaufId: string;
  updatedByMitarbeiterId?: string | null;
  entryList: Array<{
    generierlaufEntryId: string;
    infoMessage?: string | null;
    budgeting?: {
      bezeichnung: string;
      budgetSumNextYear?: number | null;
      budgetingId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      currentYear: number;
      increase?: number | null;
      prevYear: number;
      updatedByMitarbeiterId?: string | null;
      vertragVPosWertDate?: string | null;
      vorschreibungCurrentYearSum: number;
      vorschreibungPrevYearSum: number;
      restOfCurrentYearSum?: number | null;
      totalCurrentYearSum?: number | null;
      objekt: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        objektId: string;
        updatedByMitarbeiterId?: string | null;
        rechnungsAussteller: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        status: { text: string; value: Types.ObjektStatus; description?: string | null };
        verwaltungsart: { text: string; value: Types.Verwaltungsart };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      resultSourceType?: { description?: string | null; text: string; value: Types.BudgetingResultSourceType } | null;
      status: { text: string; value: Types.BudgetingStatus };
      warningList: Array<{ message: string; type: string }>;
    } | null;
    errorList: Array<{ message: string; type: string }>;
    exitCode?: { value: Types.GenerierlaufEntryExitCode; text: string } | null;
    warningList: Array<{ message: string; type: string }>;
  }>;
  errorList: Array<{ message: string; type: string }>;
  status: { description?: string | null; value: Types.GenerierlaufStatus; text: string };
  warningList: Array<{ message: string; type: string }>;
};

export const BudgetingGenerierlaufEntryFieldsFragmentDoc = gql`
  fragment BudgetingGenerierlaufEntryFields on BudgetingGenerierlaufEntry {
    budgeting {
      ...BudgetingBaseFields
    }
    errorList {
      message
      type
    }
    exitCode {
      value
      text
    }
    generierlaufEntryId
    infoMessage
    warningList {
      message
      type
    }
  }
  ${BudgetingBaseFieldsFragmentDoc}
`;
export const BudgetingGenerierlaufFieldsFragmentDoc = gql`
  fragment BudgetingGenerierlaufFields on BudgetingGenerierlauf {
    bezeichnung
    considerationPeriodUntil
    countFehler
    countVerarbeitet
    countNichtVerarbeitet
    createTs
    createdBy
    createdByMitarbeiterId
    entryList {
      ...BudgetingGenerierlaufEntryFields
    }
    errorList {
      message
      type
    }
    generierDatum
    generierlaufId
    status {
      description
      value
      text
    }
    updatedByMitarbeiterId
    warningList {
      message
      type
    }
  }
  ${BudgetingGenerierlaufEntryFieldsFragmentDoc}
`;
