import { Form, FormikProps, useFormikContext } from 'formik';
import React, { FC } from 'react';

type Props = {
  editableTableContextProps: any;
  renderInEditMode: any;
  children: React.ReactNode;
};

export const EditableCellEditMode: FC<Props> = ({ renderInEditMode, editableTableContextProps, children }) => {
  const formikProps = useFormikContext<any | null>();
  const { initialValues } = formikProps;

  const isColumnEditableAndFormIsInitialized = !!initialValues;

  if (isColumnEditableAndFormIsInitialized && renderInEditMode) {
    // type assertion is safe here because isCurrentRecordTheSelectedRecord ensures that selectedRecord is present
    // type assertion is safe here because isColumnEditableAndFormIsInitialized ensures that initialValues are initialized
    return <Form>{renderInEditMode(editableTableContextProps, formikProps as FormikProps<any>)}</Form>;
  } else {
    return <>{children}</>;
  }
};
