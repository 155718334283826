import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { BudgetingBase } from '../../../../types';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { EuroAmount, Percent } from '../../../../components/Number';
import { generatePathToBudgetingDetailsPage } from '../../budgetingUriPaths';
import { compareTwoNumbersForSorting } from '../../../../helpers/numberHelper';
import { compareTwoStringsForSorting } from '../../../../helpers/stringHelper';
import DataWithStatus from '../../../../components/Helpers/DataWithStatus';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { isNotNil } from '../../../../helpers/assertionHelper';

const budgetingTableColumns = (objektId: string): TableWithColSelectorColumnProps<BudgetingBase>[] => [
  {
    title: 'Bezeichnung',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.bezeichnung, b.bezeichnung),
    render: (text, record) => (
      <DataWithStatus maxTextLength={20} status={record.status} text={record.bezeichnung} showBadgeDot={false} showStatusDescription={false} />
    ),
  },
  {
    title: 'Vorschreibung Vorjahr',
    sorter: (a, b) => compareTwoNumbersForSorting(a.prevYear, b.prevYear),
    render: (text, record) => (
      <DataWithStatus status={record.status} text={record.prevYear.toString()} showBadgeDot={false} showStatusDescription={false} />
    ),
  },
  {
    title: 'Vorschreibung aktuell',
    sorter: (a, b) => compareTwoNumbersForSorting(a.currentYear, b.currentYear),
    render: (text, record) => (
      <DataWithStatus status={record.status} text={record.currentYear.toString()} showBadgeDot={false} showStatusDescription={false} />
    ),
  },
  {
    title: 'Vorschreibung Vorjahr Summe',
    align: 'right',
    defaultSelected: true,
    sorter: (a, b) => compareTwoNumbersForSorting(a.vorschreibungPrevYearSum, b.vorschreibungPrevYearSum),
    render: (text, record) => (
      <DataWithStatus status={record.status} text="" showBadgeDot={false} showStatusDescription={false}>
        {() => <EuroAmount value={record.vorschreibungPrevYearSum} />}
      </DataWithStatus>
    ),
  },
  {
    title: 'Vorschreibung aktuell Summe',
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.vorschreibungCurrentYearSum, b.vorschreibungCurrentYearSum),
    render: (text, record) => (
      <DataWithStatus status={record.status} text="" showBadgeDot={false} showStatusDescription={false}>
        {() => <EuroAmount value={record.vorschreibungCurrentYearSum} />}
      </DataWithStatus>
    ),
  },
  {
    title: 'Hochrechnung Restjahr',
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.restOfCurrentYearSum, b.restOfCurrentYearSum),
    render: (text, record) => (
      <DataWithStatus status={record.status} text="" showBadgeDot={false} showStatusDescription={false}>
        {() =>
          isNotNil(record.restOfCurrentYearSum) ? <EuroAmount value={record.restOfCurrentYearSum} /> : <TextForEmptyValue textToShow="minus" />
        }
      </DataWithStatus>
    ),
  },
  {
    title: 'Basisbetrag',
    align: 'right',
    defaultSelected: true,
    sorter: (a, b) => compareTwoNumbersForSorting(a.totalCurrentYearSum, b.totalCurrentYearSum),
    render: (text, record) => (
      <DataWithStatus status={record.status} text="" showBadgeDot={false} showStatusDescription={false}>
        {() => (isNotNil(record.totalCurrentYearSum) ? <EuroAmount value={record.totalCurrentYearSum} /> : <TextForEmptyValue textToShow="minus" />)}
      </DataWithStatus>
    ),
  },
  {
    title: 'Erhöhung %',
    align: 'right',
    defaultSelected: true,
    sorter: (a, b) => compareTwoNumbersForSorting(a.increase, b.increase),
    render: (text, record) => (
      <DataWithStatus status={record.status} text="" showBadgeDot={false} showStatusDescription={false}>
        {() => (isNotNil(record.increase) ? <Percent value={record.increase / 100} /> : <TextForEmptyValue textToShow="minus" />)}
      </DataWithStatus>
    ),
  },
  {
    title: 'Jahresbudget Summe',
    align: 'right',
    defaultSelected: true,
    sorter: (a, b) => compareTwoNumbersForSorting(a.budgetSumNextYear, b.budgetSumNextYear),
    render: (text, record) => (
      <DataWithStatus status={record.status} text="" showBadgeDot={false} showStatusDescription={false}>
        {() => (isNotNil(record.budgetSumNextYear) ? <EuroAmount value={record.budgetSumNextYear} /> : <TextForEmptyValue textToShow="minus" />)}
      </DataWithStatus>
    ),
  },
  {
    title: 'Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.status.text, b.status.text),
    render: (text, record) => <Typography.Text>{record.status.text}</Typography.Text>,
  },
  {
    title: 'Erstellt am',
    render: (text, record) => (
      <DataWithStatus status={record.status} text="" showBadgeDot={false} showStatusDescription={false}>
        {() => <FormattedDateTime createdTs={record.createTs} />}
      </DataWithStatus>
    ),
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => <Link to={generatePathToBudgetingDetailsPage(objektId, record.budgetingId)}>Details</Link>,
  },
];

export default budgetingTableColumns;
