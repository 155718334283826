import React, { FC } from 'react';
import VersionTimeline from '../../../../components/Timeline/VersionTimeline';
import NutzwertVersionCardContent from './NutzwertVersionCardContent';
import NutzwertVersionForm from './Form/NutzwertVersionForm';
import { mapToVersionTimelineDataSourceWithAlwaysDeletableEntries } from '../../../../components/Timeline/versionTimelineHelper';
import { BENutzwertVersionTimelineDataSourceEntry } from './nutzwertVersionTimelineMapper';
import { showSuccessMsgDelete } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { useDeleteBestandseinheitNutzwertVersionMutation } from './gql/NutzwertVersionMutations.types';
import { gqlOperations } from '../../../../graphql/gqlNamedOperations-generated';
import { useBestandseinheitNutzwertVersionListQuery } from './gql/NutzwertVersionQueries.types';
import { BestandseinheitNutzwertVersion } from '../../../../types';

type NutzwertVersionTimelineProps = {
  objektId: string;
  bestandseinheitId: string;
  reloadBestandseinheit: () => void;
};

const NutzwertVersionTimeline: FC<NutzwertVersionTimelineProps> = ({ objektId, bestandseinheitId, reloadBestandseinheit }) => {
  const {
    data,
    loading,
    refetch: reloadVersionList,
  } = useBestandseinheitNutzwertVersionListQuery({
    variables: {
      objektId,
      bestandseinheitId,
    },
  });
  const versionList: BestandseinheitNutzwertVersion[] = data?.getBestandseinheitNutzwertVersionList?.data ?? [];

  const onActionSuccess = () => {
    reloadBestandseinheit();
    reloadVersionList();
  };

  const [runDelete] = useDeleteBestandseinheitNutzwertVersionMutation({
    onCompleted: () => {
      showSuccessMsgDelete(`Nutzwert`);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <VersionTimeline<BENutzwertVersionTimelineDataSourceEntry>
      dataSource={mapToVersionTimelineDataSourceWithAlwaysDeletableEntries(versionList)}
      versionIdKey="beNutzwertId"
      loading={loading}
      defaultNewTimeblock={{
        beNutzwertId: '',
        nutzwert: 0,
        warningList: [],
      }}
      renderForm={(timeblock) => (
        <NutzwertVersionForm timeblock={timeblock} objektId={objektId} bestandseinheitId={bestandseinheitId} onSubmitSuccess={onActionSuccess} />
      )}
      isAllowedToDeleteInitialTimeblock
      onDelete={(timeblock) => {
        return runDelete({
          variables: {
            objektId,
            bestandseinheitId,
            nutzwertVersionId: timeblock.beNutzwertId,
          },
          refetchQueries: () => [
            gqlOperations.Query.BestandseinheitAufteilungsschluesselList,
            gqlOperations.Query.BestandseinheitAufteilungsschluesselWertAenderungList,
          ],
        });
      }}
      kommentarForCardTitle={(timeblock) => timeblock.kommentar}
      renderCardContent={(timeblock) => <NutzwertVersionCardContent timeblock={timeblock} />}
    />
  );
};

export default NutzwertVersionTimeline;
