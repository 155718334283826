import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
export type NutzungsArtFieldsFragment = {
  beNutzungsArtId: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  current: boolean;
  kommentar?: string | null;
  lastUpdateTs: string;
  vertragId?: string | null;
  vertragKurzBezeichnung?: string | null;
  validFrom: string;
  historicizedList?: Array<{
    beNutzungsArtId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    current: boolean;
    kommentar?: string | null;
    lastUpdateTs: string;
    validFrom: string;
    vertragId?: string | null;
    vertragKurzBezeichnung?: string | null;
    nutzungsArt: { text: string; value: Types.NutzungsArt };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }> | null;
  nutzungsArt: { text: string; value: Types.NutzungsArt };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const NutzungsArtFieldsFragmentDoc = gql`
  fragment NutzungsArtFields on BeNutzungsArt {
    beNutzungsArtId
    createTs
    createdBy
    createdByMitarbeiterId
    current
    historicizedList {
      beNutzungsArtId
      createTs
      createdBy
      createdByMitarbeiterId
      current
      kommentar
      lastUpdateTs
      nutzungsArt {
        text
        value
      }
      validFrom
      vertragId
      vertragKurzBezeichnung
      warningList {
        attribute
        message
        type
      }
    }
    kommentar
    nutzungsArt {
      text
      value
    }
    lastUpdateTs
    vertragId
    vertragKurzBezeichnung
    validFrom
    warningList {
      attribute
      message
      type
    }
  }
`;
