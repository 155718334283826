import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Space, Typography } from 'antd';
import { Switch } from 'formik-antd';
import { IndexedValueFormValues } from '../../Vertrag/BeVertrag/Vertragsdaten/VPosIndexationAgreement/ListingButtonsAndModal/indexedValuesCreateFormMapper';
import {
  isValuePreservationTypeIndexAnnual,
  isValuePreservationTypeIndexThreshold,
} from '../../Vertrag/BeVertrag/Vertragsdaten/VPosIndexationAgreement/Table/Level2/CreateUpdateIndexationAgreement/indexationAgreementHelpers';

type Props = {
  name: string;
  formikProps: FormikProps<IndexedValueFormValues>;
};

const AllowPreliminaryValuesSwitch: FC<Props> = ({ name, formikProps }) => (
  <Space>
    <Switch
      id={name}
      name={name}
      disabled={
        !formikProps.values.valuePreservationTypeList?.some(
          (value) => isValuePreservationTypeIndexAnnual(value) || isValuePreservationTypeIndexThreshold(value)
        )
      }
    />
    <Typography.Text>{formikProps.values.allowPreliminaryValues ? 'Ja' : 'Nein'}</Typography.Text>
  </Space>
);

export default AllowPreliminaryValuesSwitch;
