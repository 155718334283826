import React from 'react';
import { CopyOutlined, InboxOutlined, InfoCircleOutlined, SendOutlined, SignatureOutlined, ThunderboltOutlined } from '@ant-design/icons';
import {
  generatePathToBeVertragDetailsPage,
  generatePathToBeVertragDetailsPageInfoFeldMenu,
  generatePathToBeVertragDetailsPageVertragsdaten,
} from '../../features/Vertrag/BeVertrag/beVertragUriPaths';
import DocumentTab from '../../features/Document/DocumentTab';
import { GroupedMenueListInterface } from '../../components/ContextSidebar/ContentWithSidebar';
import { Dokumentenversand, VertragStatus } from '../../types';
import VertragDeviationList from '../../features/Vertrag/BeVertrag/Deviation/VertragDeviationList';
import { DEVIATIONS_MENU_LIST_ITEM_TITLE } from '../../components/Deviation/deviationHelpers';
import VertragInfoFeldTab from '../../features/Vertrag/BeVertrag/InfoFeld/VertragInfoFeldTab';
import VertragVertragsdatenTabs from '../../features/Vertrag/BeVertrag/Vertragsdaten/VertragVertragsdatenTabs';
import VertragDokumentenVersand from '../../features/Vertrag/BeVertrag/Versand/VertragDokumentenVersand';
import { isMissingEmailAddress, isMissingPostAddress } from '../../features/Vertrag/BeVertrag/Versand/vertragVersandHelper';
import { BeVertragFragment } from '../../features/Vertrag/BeVertrag/gql/BeVertragFragments.types';
import { DOCUMENT_FILTERS_SETTINGS } from '../../features/Document/Listing/Filters/listingFiltersHelper';
import BeVertragTicketTab from '../../features/Vertrag/BeVertrag/Ticket/BeVertragTicketTab';

const menuListBeVertrag = (
  objektId: string,
  bestandseinheitId: string,
  vertragId: string,
  vertrag: BeVertragFragment,
  onVertragAction: () => void,
  onDokumentversandAction: () => void,
  dokumentenversand?: Dokumentenversand
): GroupedMenueListInterface => ({
  topMenuList: [
    {
      title: 'Vertragsdaten',
      icon: <SignatureOutlined />,
      uri: generatePathToBeVertragDetailsPageVertragsdaten(objektId, bestandseinheitId, vertragId),
      content: () => (
        <VertragVertragsdatenTabs
          objektId={objektId}
          bestandseinheitId={bestandseinheitId}
          vertragId={vertragId}
          vertragsBeginn={vertrag.vertragsBeginn}
        />
      ),
    },
    {
      state: isMissingEmailAddress(dokumentenversand) || isMissingPostAddress(dokumentenversand) ? 'warning' : undefined,
      title: 'Versand',
      icon: <SendOutlined />,
      uri: `${generatePathToBeVertragDetailsPage(objektId, bestandseinheitId, vertragId)}/send`,
      content: () => (
        <VertragDokumentenVersand
          dokumentenversand={dokumentenversand}
          onSuccess={onDokumentversandAction}
          objektId={objektId}
          bestandseinheitId={bestandseinheitId}
          vertragId={vertragId}
          vertragspartnerId={vertrag.vertragspartner.rechtstraegerId}
        />
      ),
    },
    {
      title: 'Aufgaben',
      icon: <InboxOutlined />,
      uri: `${generatePathToBeVertragDetailsPage(objektId, bestandseinheitId, vertragId)}/aufgaben`,
      content: () => <BeVertragTicketTab objektId={objektId} bestandseinheitId={bestandseinheitId} vertragId={vertragId} />,
    },
    {
      title: 'Dokumente',
      icon: <CopyOutlined />,
      uri: `${generatePathToBeVertragDetailsPage(objektId, bestandseinheitId, vertragId)}/documents`,
      content: (props) => (
        <DocumentTab
          objektId={objektId}
          bestandseinheitId={bestandseinheitId}
          vertragId={vertragId}
          disabledFilters={DOCUMENT_FILTERS_SETTINGS.vertrag.disabled}
          defaultFilters={DOCUMENT_FILTERS_SETTINGS.vertrag.default}
          {...props}
        />
      ),
    },
    {
      title: 'Infofelder',
      icon: <InfoCircleOutlined />,
      uri: generatePathToBeVertragDetailsPageInfoFeldMenu(objektId, bestandseinheitId, vertragId),
      content: () => <VertragInfoFeldTab objektId={objektId} bestandseinheitId={bestandseinheitId} vertragId={vertragId} />,
    },
    {
      state: vertrag.status.value === VertragStatus.Invalid ? 'error' : undefined,
      title: DEVIATIONS_MENU_LIST_ITEM_TITLE,
      icon: <ThunderboltOutlined />,
      uri: `${generatePathToBeVertragDetailsPage(objektId, bestandseinheitId, vertragId)}/discrepancies`,
      content: () => (
        <VertragDeviationList
          objektId={objektId}
          bestandseinheitId={bestandseinheitId}
          vertragId={vertragId}
          status={vertrag.status}
          onAction={onVertragAction}
        />
      ),
    },
  ],
});

export default menuListBeVertrag;
