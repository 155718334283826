import { FormFields } from '../../../../../helpers/formikHelper';
import { GenerierlaufEntryExitCode } from '../../../../../types';
import { TopFinancialPlanVerarbeitungQueryParams } from './filtersQueryParams';

export type TopFinancialPlanVerarbeitungEntryFiltersFormValues = {
  bestandseinheitId?: string;
  budgetingId?: string;
  exitCodeList?: GenerierlaufEntryExitCode[];
  objektId?: string;
  rechnungsAusstellerId?: string;
  vertragspartnerId?: string;
};

export const topFinancialPlanVerarbeitungEntryFiltersFormFields: FormFields<TopFinancialPlanVerarbeitungEntryFiltersFormValues> = {
  bestandseinheitId: 'bestandseinheitId',
  budgetingId: 'budgetingId',
  exitCodeList: 'exitCodeList',
  objektId: 'objektId',
  rechnungsAusstellerId: 'rechnungsAusstellerId',
  vertragspartnerId: 'vertragspartnerId',
};

export const topFinancialPlanVerarbeitungEntryFiltersFormInitialValues = (
  queryParams: TopFinancialPlanVerarbeitungQueryParams
): TopFinancialPlanVerarbeitungEntryFiltersFormValues => ({
  bestandseinheitId: queryParams.bestandseinheitId,
  budgetingId: queryParams.budgetingId,
  exitCodeList: queryParams.exitCodeList,
  objektId: queryParams.objektId,
  rechnungsAusstellerId: queryParams.rechnungsAusstellerId,
  vertragspartnerId: queryParams.vertragspartnerId,
});

export const mapFormValuesToTopFinancialPlanVerarbeitungFilters = (
  formValues: TopFinancialPlanVerarbeitungEntryFiltersFormValues
): TopFinancialPlanVerarbeitungQueryParams => ({
  bestandseinheitId: formValues.bestandseinheitId,
  budgetingId: formValues.budgetingId,
  exitCodeList: formValues.exitCodeList,
  objektId: formValues.objektId,
  rechnungsAusstellerId: formValues.rechnungsAusstellerId,
  vertragspartnerId: formValues.vertragspartnerId,
});
