import React from 'react';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/es/table';
import { FieldArrayRenderProps, FormikProps } from 'formik';
import {
  abrDefAbrKreisFormFields,
  AbrDefAbrKreisFormValues,
  abrDefAbrKreisListingFormFields,
  AbrDefAbrKreisListingFormValues,
} from './abrDefAbrKreisListingFormMapper';
import { FormikFieldHookProps } from '../../../../helpers/formikHelper';
import { AbrechnungsdefinitionType, AbrechnungskreisType } from '../../../../types';
import AbrechnungskreisSelect from '../../../AbrechnungskreisSelect/AbrechnungskreisSelect';

const heAndSubAbrDefAbrKreisTableColumns = (
  abrDefType: AbrechnungsdefinitionType,
  formikProps: FormikProps<AbrDefAbrKreisListingFormValues>,
  firmendatenId: string,
  arrayHelpers: FieldArrayRenderProps,
  onAbrechnungskreisChange?: (newAbrechnungskreisId: string, fieldProps: FormikFieldHookProps<string>, onSuccess: () => void) => void,
  onZuweisungDelete?: (abrechnungskreisId: string, onDelete: () => void) => void
): ColumnProps<AbrDefAbrKreisFormValues>[] => {
  return [
    {
      title: 'Abrechnungskreis',
      key: abrDefAbrKreisFormFields.abrechnungskreisId,
      width: 280,
      render: (text, record, i) => {
        const abrechnungskreisType = `${abrDefAbrKreisListingFormFields.abrechdefAbrechkreisList}.${i}.${abrDefAbrKreisFormFields.abrechnungskreisType}`;

        const changeAbrKreisTypeAndAufteilungsschluessel = (
          newAbrechnungskreisId: string,
          fieldProps: FormikFieldHookProps<string>,
          abrKreisType?: AbrechnungskreisType
        ) => {
          const onSuccess = () => abrKreisType && formikProps.setFieldValue(abrechnungskreisType, abrKreisType);

          if (onAbrechnungskreisChange) {
            onAbrechnungskreisChange(newAbrechnungskreisId, fieldProps, onSuccess);
          } else {
            onSuccess();
          }
        };

        return (
          <AbrechnungskreisSelect
            key={i}
            name={`${abrDefAbrKreisListingFormFields.abrechdefAbrechkreisList}.${i}.${abrDefAbrKreisFormFields.abrechnungskreisId}`}
            firmendatenId={firmendatenId}
            abrDefType={abrDefType}
            onChange={changeAbrKreisTypeAndAufteilungsschluessel}
            allowClear={false}
            style={{ width: '100%' }}
          />
        );
      },
    },
    {
      key: 'actions',
      render: (text, record, index) => (
        <Button
          style={{ border: 'none' }}
          icon={<DeleteOutlined />}
          name={abrDefAbrKreisListingFormFields.abrechdefAbrechkreisList}
          onClick={() => {
            const removeItem = () => arrayHelpers.remove(index);
            onZuweisungDelete ? onZuweisungDelete(record.abrechnungskreisId, removeItem) : removeItem();
          }}
        />
      ),
    },
  ];
};

export default heAndSubAbrDefAbrKreisTableColumns;
