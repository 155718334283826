import React, { FC } from 'react';
import DataWithShortenedText from './DataWithShortenedText';
import { isTruthy } from '../../helpers/assertionHelper';

type DataWithShortenedTextAndExtraInfoProps = {
  maxTextLength: number;
  text: string;
  alternativeText?: string;
  extraTexts?: string[];
  statusDescription?: string | null;
  children?: (shortenedText: string) => React.ReactNode;
  tooltipFooter?: React.ReactNode;
  fontWeight?: 'normal' | 'bold';
};

const DataWithShortenedTextAndExtraInfo: FC<DataWithShortenedTextAndExtraInfoProps> = ({
  maxTextLength,
  text,
  alternativeText,
  extraTexts = [],
  statusDescription,
  children,
  tooltipFooter,
  fontWeight,
}) => {
  const tooltipTitleArray = [alternativeText, ...extraTexts, statusDescription].filter(isTruthy);

  return (
    <DataWithShortenedText
      maxTextLength={maxTextLength}
      text={text}
      extraTexts={tooltipTitleArray}
      tooltipFooter={tooltipFooter}
      fontWeight={fontWeight}
    >
      {children}
    </DataWithShortenedText>
  );
};

export default DataWithShortenedTextAndExtraInfo;
