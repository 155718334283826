import React, { FC } from 'react';
import { FormikProps } from 'formik';
import BuchungskreisSelect from '../../Saldenliste/sharedFilters/BuchungskreisSelect';
import ObjektSelect from '../../Saldenliste/sharedFilters/ObjektSelect';
import {
  kontoblattListingFiltersFormFields,
  KontoblattListingFiltersFormValues,
  kontoblattListingLabelList,
} from './kontoblattTableFiltersFormMapper';
import FiltersWith4OrMoreFields from '../../../components/Filters/FiltersWith4OrMoreFields';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import KontoFormSelect from '../../KontoSelect/KontoFormSelect';
import { KontoblattPageQueryParams } from '../kontoblattUriPaths';
import FiltersSelect from '../../../components/Filters/FiltersSelect';
import { onFilterSelectChange } from '../../../components/Filters/FiltersHelpers';
import FromToFormDatePicker from '../../shared/FromToForm/FromToFormDatePicker';
import BelegnummerFormSelect from '../../../shared/components/Belegnummer/BelegnummerFormSelect';
import MitarbeiterSelect from '../../../shared/components/MitarbeiterSelect/MitarbeiterSelect';
import VertragspartnerKontoFormSelect from '../../../shared/components/Vertragspartner/VertragspartnerKontoFormSelect';
import useFilterWithQueryParams from '../../shared/useFilterWithQueryParams';
import YesOrNoFormSelect from '../../shared/YesOrNoFormSelect';
import FibuBelegSymbolFormSelect from '../../BelegSymbol/Fibu/FibuBelegSymbolFormSelect';
import FibuBuchungTypeFormSelect from '../../BuchungType/Fibu/FibuBuchungTypeFormSelect';

type Props = {
  formikProps: FormikProps<KontoblattListingFiltersFormValues>;
  queryParams: KontoblattPageQueryParams;
};

const KontoblattListingFilters: FC<Props> = ({ formikProps, queryParams }) => {
  const defaultSelectedFilterList = [
    kontoblattListingFiltersFormFields.buchungskreisId,
    kontoblattListingFiltersFormFields.objektId,
    kontoblattListingFiltersFormFields.kontoId,
    kontoblattListingFiltersFormFields.buchungsdatumFrom,
  ];

  const mapFilterLabelToQueryFilterList = (filter: string) => {
    if (filter === kontoblattListingFiltersFormFields.buchungsdatumFrom || filter === kontoblattListingFiltersFormFields.buchungsdatumTo) {
      return kontoblattListingFiltersFormFields.buchungsdatumFrom;
    }
    if (filter === kontoblattListingFiltersFormFields.belegdatumFrom || filter === kontoblattListingFiltersFormFields.belegdatumTo) {
      return kontoblattListingFiltersFormFields.belegdatumFrom;
    }
    return filter;
  };

  const filters = (filter: string) => {
    if (filter === kontoblattListingFiltersFormFields.buchungskreisId) {
      return (
        <FormItemWithoutColon name={kontoblattListingFiltersFormFields.buchungskreisId} label="Buchungskreis" key={filter}>
          <BuchungskreisSelect
            name={kontoblattListingFiltersFormFields.buchungskreisId}
            rechnungsAusstellerId={formikProps.values.rechnungsAusstellerIdForBuchungskreis}
            onChange={(rechtstraeger) => {
              formikProps.setFieldValue(
                kontoblattListingFiltersFormFields.rechnungsAusstellerIdForObjekt,
                rechtstraeger?.rechtstraegerId ?? '',
                false
              );
              formikProps.submitForm();
            }}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === kontoblattListingFiltersFormFields.objektId) {
      return (
        <FormItemWithoutColon name={kontoblattListingFiltersFormFields.objektId} label="Objekt" key={filter}>
          <ObjektSelect
            name={kontoblattListingFiltersFormFields.objektId}
            rechnungsAusstellerId={formikProps.values.rechnungsAusstellerIdForObjekt}
            onChange={(objekt) => {
              formikProps.setFieldValue(
                kontoblattListingFiltersFormFields.rechnungsAusstellerIdForBuchungskreis,
                objekt?.rechnungsAusstellerId ?? '',
                false
              );
              formikProps.submitForm();
            }}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === kontoblattListingFiltersFormFields.kontoId) {
      return (
        <FormItemWithoutColon name={kontoblattListingFiltersFormFields.kontoId} label="Für Konto" key={filter}>
          <KontoFormSelect name={kontoblattListingFiltersFormFields.kontoId} onChange={formikProps.submitForm} placeholder="Konto auswählen" />
        </FormItemWithoutColon>
      );
    }
    if (filter === kontoblattListingFiltersFormFields.buchungsdatumFrom) {
      return (
        <FormItemWithoutColon name="Buchungsdatum" label="Buchungsdatum" key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={kontoblattListingFiltersFormFields.buchungsdatumFrom}
            toName={kontoblattListingFiltersFormFields.buchungsdatumTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === kontoblattListingFiltersFormFields.belegdatumFrom) {
      return (
        <FormItemWithoutColon name="belegdatum" label="Belegdatum" key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={kontoblattListingFiltersFormFields.belegdatumFrom}
            toName={kontoblattListingFiltersFormFields.belegdatumTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === kontoblattListingFiltersFormFields.belegnummer) {
      return (
        <FormItemWithoutColon name={kontoblattListingFiltersFormFields.belegnummer} label="Belegnummer" key={filter}>
          <BelegnummerFormSelect
            name={kontoblattListingFiltersFormFields.belegnummer}
            belegnummer={formikProps.values.belegnummer}
            onChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === kontoblattListingFiltersFormFields.belegSymbol) {
      return (
        <FormItemWithoutColon name={kontoblattListingFiltersFormFields.belegSymbol} label="Belegsymbol" key={filter}>
          <FibuBelegSymbolFormSelect name={kontoblattListingFiltersFormFields.belegSymbol} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === kontoblattListingFiltersFormFields.buchungsTypeList) {
      return (
        <FormItemWithoutColon name={kontoblattListingFiltersFormFields.buchungsTypeList} label="Buchungstyp" key={filter}>
          <FibuBuchungTypeFormSelect name={kontoblattListingFiltersFormFields.buchungsTypeList} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === kontoblattListingFiltersFormFields.erstellerMitarbeiterIdList) {
      return (
        <FormItemWithoutColon name={kontoblattListingFiltersFormFields.erstellerMitarbeiterIdList} label="Ersteller" key={filter}>
          <MitarbeiterSelect mode="multiple" name={kontoblattListingFiltersFormFields.erstellerMitarbeiterIdList} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === kontoblattListingFiltersFormFields.offenerOPBetrag) {
      return (
        <FormItemWithoutColon name={kontoblattListingFiltersFormFields.offenerOPBetrag} label="nur mit offenem OP-Betrag" key={filter}>
          <YesOrNoFormSelect name={kontoblattListingFiltersFormFields.offenerOPBetrag} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === kontoblattListingFiltersFormFields.gegenKontoId) {
      return (
        <FormItemWithoutColon name={kontoblattListingFiltersFormFields.gegenKontoId} label="Gegenkonto" key={filter}>
          <KontoFormSelect name={kontoblattListingFiltersFormFields.gegenKontoId} onChange={formikProps.submitForm} placeholder="Konto auswählen" />
        </FormItemWithoutColon>
      );
    }
    if (filter === kontoblattListingFiltersFormFields.vertragspartnerId) {
      return (
        <FormItemWithoutColon name={kontoblattListingFiltersFormFields.vertragspartnerId} label="Vertragspartner" key={filter}>
          <VertragspartnerKontoFormSelect
            name={kontoblattListingFiltersFormFields.vertragspartnerId}
            buchungskreisId={formikProps.values.buchungskreisId}
            onChange={formikProps.submitForm}
            placeholder="Vertragspartner auswählen"
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === kontoblattListingFiltersFormFields.includeStorno) {
      return (
        <FormItemWithoutColon
          name={kontoblattListingFiltersFormFields.includeStorno}
          label="Stornierte und Storno-Buchungen berücksichtigen"
          key={filter}
        >
          <YesOrNoFormSelect name={kontoblattListingFiltersFormFields.includeStorno} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    return undefined;
  };

  const updateFilterValues = (selectedFilterList: string[]) => {
    if (!selectedFilterList.includes(kontoblattListingFiltersFormFields.buchungsdatumFrom) && formikProps.values.buchungsdatumTo) {
      formikProps.setFieldValue(kontoblattListingFiltersFormFields.buchungsdatumTo, undefined);
    }
    if (!selectedFilterList.includes(kontoblattListingFiltersFormFields.belegdatumFrom) && formikProps.values.belegdatumTo) {
      formikProps.setFieldValue(kontoblattListingFiltersFormFields.belegdatumTo, undefined);
    }
    formikProps.submitForm();
  };

  const { selectedFilterList, setSelectedFilterList, leftColumn, rightColumn } = useFilterWithQueryParams({
    defaultSelectedFilterList,
    updateFilterValues,
    filters,
    labelList: kontoblattListingLabelList,
    mapFilterLabelToQueryFilterList,
    queryParams,
  });

  return (
    <FiltersWith4OrMoreFields
      hideTitle
      filtersSelect={
        <FiltersSelect
          defaultSelectedFilterList={defaultSelectedFilterList}
          selectedFilterList={selectedFilterList}
          onChange={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
          labelList={kontoblattListingLabelList}
        />
      }
      leftColumn={<>{leftColumn}</>}
      rightColumn={<>{rightColumn}</>}
    />
  );
};

export default KontoblattListingFilters;
