import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AufteilungsschluesselFieldsFragmentDoc } from '../../Aufteilungsschluessel/gql/AufteilungsschluesselFragments.types';
import { KontierungstabelleBaseFieldsFragmentDoc } from '../../Kontierungstabelle/gql/KontierungstabelleFragments.types';
export type BudgetingVPosAuftschluesselLinkageFieldsFragment = {
  budgetingVPosAuftschluesselLinkageId: string;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  firmendatenId?: string | null;
  kundenSystemId?: string | null;
  updatedByMitarbeiterId?: string | null;
  aufteilungsschluessel: {
    aufteilungsschluesselId: string;
    bezeichnung: string;
    bezugsbasisEditable?: boolean | null;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    firmendatenId?: string | null;
    kurzBezeichnung: string;
    updatedByMitarbeiterId?: string | null;
    bezugsbasis: { text: string; value: Types.Bezugsbasis };
    masseinheit: { text: string; value: Types.Masseinheit };
    status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  objektVerwaltungsart: { text: string; value: Types.Verwaltungsart };
  vertragLevel: { shortName: string; text: string; value: Types.VertragLevel };
  vorschreibungsposition: {
    bezeichnung: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    firmendatenId?: string | null;
    kurzBezeichnung: string;
    tagList: Array<string>;
    updatedByMitarbeiterId?: string | null;
    vorschreibungspositionId: string;
    art: { text: string; value: Types.VorschreibungspositionArt };
    kontierungstabelle?: {
      basiskonto: number;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      kontierungstabelleId: string;
      updatedByMitarbeiterId?: string | null;
      status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
      subAdministrationExpenseAccount?: {
        bezeichnung: string;
        firmendatenId?: string | null;
        kontoId: string;
        kontoKlasse: string;
        nummer: string;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    status: { description?: string | null; text: string; value: Types.VorschreibungspositionStatus };
    umsatzsteuerkennzeichen?: {
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      kuerzel: string;
      umsatzsteuerkennzeichenId: string;
      status: { description?: string | null; text: string; value: Types.UmsatzsteuerkennzeichenStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  warningList: Array<{ attribute?: string | null; type: string; message: string }>;
};

export const BudgetingVPosAuftschluesselLinkageFieldsFragmentDoc = gql`
  fragment BudgetingVPosAuftschluesselLinkageFields on BudgetingVPosAuftschluesselLinkage {
    aufteilungsschluessel {
      ...AufteilungsschluesselFields
    }
    budgetingVPosAuftschluesselLinkageId
    createTs
    createdBy
    createdByMitarbeiterId
    firmendatenId
    kundenSystemId
    objektVerwaltungsart {
      text
      value
    }
    updatedByMitarbeiterId
    vertragLevel {
      shortName
      text
      value
    }
    vorschreibungsposition {
      art {
        text
        value
      }
      bezeichnung
      createTs
      createdBy
      createdByMitarbeiterId
      deletable
      firmendatenId
      kontierungstabelle {
        ...KontierungstabelleBaseFields
      }
      kurzBezeichnung
      status {
        description
        text
        value
      }
      tagList
      umsatzsteuerkennzeichen {
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        kuerzel
        status {
          description
          text
          value
        }
        umsatzsteuerkennzeichenId
        warningList {
          attribute
          message
          type
        }
      }
      updatedByMitarbeiterId
      vorschreibungspositionId
      warningList {
        attribute
        message
        type
      }
    }
    warningList {
      attribute
      type
      message
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
  ${KontierungstabelleBaseFieldsFragmentDoc}
`;
