import React, { FC } from 'react';
import { CardProps } from 'antd/lib/card';
import { Skeleton } from 'antd';
import { StyledCardWithFooter } from './Styled/Card.style';
import AuditFooter from '../Footer/AuditFooter';

interface CardWithFooterProps extends CardProps {
  loading: boolean;
  bordered?: boolean;
  minWidth?: number;
  userId?: string | null;
  mitarbeiterId?: string | null;
  createTs: string;
}

const CardWithFooter: FC<CardWithFooterProps> = ({ loading, bordered, minWidth, userId, mitarbeiterId, createTs, children, ...restProps }) => {
  const minWidthForStyle = minWidth || 320;

  return (
    <>
      <StyledCardWithFooter variant={bordered ? 'outlined' : 'borderless'} style={{ minWidth: `${minWidthForStyle}px` }} {...restProps}>
        <Skeleton loading={loading} title active paragraph={{ rows: 4 }}>
          {children}
        </Skeleton>
      </StyledCardWithFooter>
      <AuditFooter userId={userId} mitarbeiterId={mitarbeiterId} createTs={createTs} size="large" minWidth={minWidth} />
    </>
  );
};

export default CardWithFooter;
