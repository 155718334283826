import { FC } from 'react';
import { FormikProps } from 'formik';
import { VertragInfoFeldListingFiltersFormValues } from './vertragInfoFeldListingFormMapper';
import VertragInfoFeldListingFilters from './VertragInfoFeldListingFilters';
import VertragInfoFeldList from './List/VertragInfoFeldList';
import { useBeVertragInfoFeldListQuery } from './gql/VertragInfoFeldQueries.types';
import { VertragInfoFeldFragment } from './gql/VertragInfoFeldFragments.types';
import { useToggle } from '../../../../hooks/useToggle';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  formikProps: FormikProps<VertragInfoFeldListingFiltersFormValues>;
};

const VertragInfoFeldListingForm: FC<Props> = ({ objektId, bestandseinheitId, vertragId, formikProps }) => {
  const [showArchived, toggleShowArchived] = useToggle();

  const { data, loading, refetch } = useBeVertragInfoFeldListQuery({
    variables: { objektId, bestandseinheitId, vertragId, includeArchiviert: showArchived },
  });
  const infoFeldList = data?.getBeVertragInfoFeldList.data ?? [];
  const infoFeldListForType = filterInfoFeldListByType(infoFeldList, formikProps);

  return (
    <>
      <VertragInfoFeldListingFilters
        objektId={objektId}
        bestandseinheitId={bestandseinheitId}
        vertragId={vertragId}
        onSuccess={refetch}
        showArchived={showArchived}
        toggleShowArchived={toggleShowArchived}
      />
      <VertragInfoFeldList
        infoFeldList={infoFeldListForType}
        loading={loading}
        objektId={objektId}
        bestandseinheitId={bestandseinheitId}
        vertragId={vertragId}
        onSuccess={refetch}
      />
    </>
  );
};

const filterInfoFeldListByType = (
  vertragInfoFeldList: VertragInfoFeldFragment[],
  formikProps: FormikProps<VertragInfoFeldListingFiltersFormValues>
) => vertragInfoFeldList.filter((infoFeld) => (formikProps.values.type ? infoFeld.type.value === formikProps.values.type : true));

export default VertragInfoFeldListingForm;
