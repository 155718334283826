import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { BeOptionSteuerpflichtVersionFragmentDoc } from '../OptionSteuerpflicht/gql/BeOptionSteuerpflichtFragments.types';
import { AddressFieldsFragmentDoc, OrderAddressFragmentDoc } from '../../Address/gql/AddressFragments.types';
import { PostItFragmentDoc } from '../../Rechtstraeger/PostIt/gql/PostItFragments.types';
export type BestandseinheitListEntryFieldsFragment = {
  aktivSeit?: string | null;
  bauteil?: string | null;
  bestandseinheitId: string;
  bezeichnung: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  geschoss?: string | null;
  inaktivSeit?: string | null;
  position: number;
  stiege?: string | null;
  vertragspartnerId?: string | null;
  vertragspartnerKurzBezeichnung?: string | null;
  optionSteuerpflicht?: {
    beOptionSteuerpflichtId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    current: boolean;
    deletable?: boolean | null;
    kommentar?: string | null;
    lastUpdateTs: string;
    optionSteuerpflicht: boolean;
    validFrom: string;
    historicizedList?: Array<{
      beOptionSteuerpflichtId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      kommentar?: string | null;
      lastUpdateTs: string;
      optionSteuerpflicht: boolean;
      validFrom: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }> | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  nutzungsArt?: { text: string; value: Types.NutzungsArt } | null;
  status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
  vertragspartnerStatus?: { text: string; value: Types.RechtstraegerStatus } | null;
  vertragspartnerType?: { text: string; value: Types.RechtstraegerType } | null;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type BestandseinheitFieldsFragment = {
  aktivSeit?: string | null;
  bauteil?: string | null;
  bestandseinheitId: string;
  bezeichnung: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  geschoss?: string | null;
  inaktivSeit?: string | null;
  nutzflaeche?: number | null;
  nutzwert?: number | null;
  stiege?: string | null;
  tagList: Array<string>;
  address: {
    addressId: string;
    city: string;
    cityAdditionalInformation?: string | null;
    countryCodeIso2: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    haupt?: boolean | null;
    houseEntranceApartmentNumber?: string | null;
    postofficeBoxNumber?: string | null;
    postofficeBoxZipCode?: string | null;
    street?: string | null;
    tagList: Array<string>;
    type: Types.AddressType;
    zipCode?: string | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  nutzungsArt?: { text: string; value: Types.NutzungsArt } | null;
  optionSteuerpflicht?: {
    beOptionSteuerpflichtId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    current: boolean;
    deletable?: boolean | null;
    kommentar?: string | null;
    lastUpdateTs: string;
    optionSteuerpflicht: boolean;
    validFrom: string;
    historicizedList?: Array<{
      beOptionSteuerpflichtId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      kommentar?: string | null;
      lastUpdateTs: string;
      optionSteuerpflicht: boolean;
      validFrom: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }> | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  objektVerwaltungsart: { text: string; value: Types.Verwaltungsart };
  postIt?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    immerAnzeigen: boolean;
    postItId: string;
    text: string;
    titel: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type BestandseinheitBaseFieldsFragment = {
  bestandseinheitId: string;
  bezeichnung: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  objektId: string;
  status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type OrderBestandseinheitFragment = {
  bestandseinheitId: string;
  bezeichnung: string;
  address: {
    addressId: string;
    city: string;
    cityAdditionalInformation?: string | null;
    countryCodeIso2: string;
    houseEntranceApartmentNumber?: string | null;
    postofficeBoxNumber?: string | null;
    postofficeBoxZipCode?: string | null;
    street?: string | null;
    type: Types.AddressType;
    zipCode?: string | null;
  };
};

export const BestandseinheitListEntryFieldsFragmentDoc = gql`
  fragment BestandseinheitListEntryFields on BestandseinheitListEntry {
    aktivSeit
    bauteil
    bestandseinheitId
    bezeichnung
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    geschoss
    inaktivSeit
    optionSteuerpflicht {
      ...BeOptionSteuerpflichtVersion
    }
    nutzungsArt {
      text
      value
    }
    position
    stiege
    status {
      description
      text
      value
    }
    vertragspartnerId
    vertragspartnerKurzBezeichnung
    vertragspartnerStatus {
      text
      value
    }
    vertragspartnerType {
      text
      value
    }
    warningList {
      attribute
      message
      type
    }
  }
  ${BeOptionSteuerpflichtVersionFragmentDoc}
`;
export const BestandseinheitFieldsFragmentDoc = gql`
  fragment BestandseinheitFields on Bestandseinheit {
    address {
      ...AddressFields
    }
    aktivSeit
    bauteil
    bestandseinheitId
    bezeichnung
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    geschoss
    inaktivSeit
    nutzflaeche
    nutzungsArt {
      text
      value
    }
    nutzwert
    optionSteuerpflicht {
      ...BeOptionSteuerpflichtVersion
    }
    objektVerwaltungsart {
      text
      value
    }
    postIt {
      ...PostIt
    }
    status {
      description
      text
      value
    }
    stiege
    tagList
    warningList {
      attribute
      message
      type
    }
  }
  ${AddressFieldsFragmentDoc}
  ${BeOptionSteuerpflichtVersionFragmentDoc}
  ${PostItFragmentDoc}
`;
export const BestandseinheitBaseFieldsFragmentDoc = gql`
  fragment BestandseinheitBaseFields on BestandseinheitBase {
    bestandseinheitId
    bezeichnung
    createTs
    createdBy
    createdByMitarbeiterId
    objektId
    status {
      text
      value
      description
    }
    warningList {
      attribute
      message
      type
    }
  }
`;
export const OrderBestandseinheitFragmentDoc = gql`
  fragment OrderBestandseinheit on OrderBestandseinheit {
    address {
      ...OrderAddress
    }
    bestandseinheitId
    bezeichnung
  }
  ${OrderAddressFragmentDoc}
`;
