import { TransferItem } from 'antd/lib/transfer';
import { AuftragsartBase } from '../../../../types';

type TextbausteinTransferItem = TransferItem & AuftragsartBase;

export const mapToTransferItems = (auftragsartList: AuftragsartBase[]): TextbausteinTransferItem[] => {
  return auftragsartList.map((auftragsart) => addKeyAttr(auftragsart));
};

const addKeyAttr = (auftragsart: AuftragsartBase): TextbausteinTransferItem => {
  return {
    key: auftragsart.auftragsartId,
    ...auftragsart,
  };
};
