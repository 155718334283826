import React, { FC, useContext } from 'react';
import { FormikProps } from 'formik';
import FormItemWithoutColon from '../../../../../../../../components/Form/FormItemWithoutColon';
import { vertragVposIndexedValueFormFields, VertragVposIndexedValueFormValues } from '../indexedValueMapper';
import FormattedDecimalFormInput from '../../../../../../../../components/Input-antd/FormattedDecimalFormInput';
import { useCalculateVertragVposIndexedValueFactorLazyQuery } from '../../../../../../../IndexedValue/gql/VertragVposIndexedValueQueries.types';
import { IndexationAgreementContext } from '../../Level2/IndexationAgreementContext';

type Props = {
  name: string;
  indexationAgreementId: string;
  indexedValueId: string;
  formikProps: FormikProps<VertragVposIndexedValueFormValues>;
};

const AmountFormItem: FC<Props> = ({ name, indexationAgreementId, indexedValueId, formikProps }) => {
  const { bestandseinheitId, objektId, vertragId, vorschreibungspositionId } = useContext(IndexationAgreementContext);

  const [runQuery] = useCalculateVertragVposIndexedValueFactorLazyQuery();

  const handleAmountChange = (value: number) => {
    runQuery({
      variables: {
        objektId,
        bestandseinheitId,
        vertragId,
        vorschreibungspositionId,
        indexationAgreementId,
        indexedValueId,
        amount: value,
      },
    }).then((result) => {
      const calculatedFactor = result.data?.calculateVertragVposIndexedValueFactor.data;
      calculatedFactor && formikProps.setFieldValue(vertragVposIndexedValueFormFields.factorPercent, calculatedFactor);
    });
  };

  return (
    <FormItemWithoutColon name={name} style={{ width: '100%', marginBottom: '0px' }}>
      <FormattedDecimalFormInput name={name} isCurrency onChange={(value) => value && handleAmountChange(value as number)} />
    </FormItemWithoutColon>
  );
};

export default AmountFormItem;
