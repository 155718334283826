import React, { FC, useState } from 'react';
import { Button, Col, Collapse, Row, Space, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FieldArray, FormikProps } from 'formik';
import { DatePicker } from 'formik-antd';
import {
  createPartialAmountFormValues,
  IncomingInvoiceBuchungPositionFormValues,
  incomingInvoiceFormFields,
  IncomingInvoiceFormValues,
  partialAmountFormFields,
} from './incomingInvoiceFormMapper';
import { compareTwoDatesForSorting } from '../../../helpers/dateHelper';
import { EuroAmount } from '../../../components/Number';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../components/DatePicker/DatePicker_formikAntD';
import PositionListFormFieldArray from './PositionListFormFieldArray';
import { Spacer } from '../../../components/Grid';
import { StyledCollapsePanel } from './styled/StandingInvoiceStyledCollapsePanel.style';
import FormBlockRow from './shared/FormBlockRow';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  name: string;
  formikProps: FormikProps<IncomingInvoiceFormValues>;
  shouldRestore: boolean;
};

const PartialAmountListFormFieldArray: FC<Props> = ({ name, formikProps, shouldRestore }) => {
  // When new partial amount is added, it should be expanded and the rest should be collapsed
  const [newActiveKey, setNewActiveKey] = useState<string | undefined>();
  const collapseIdList = formikProps.values.partialAmountList?.map((partialPayment) => partialPayment.id);

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <Space direction="vertical" style={{ width: '100%' }}>
          {formikProps.values.partialAmountList
            ?.sort((a, b) => compareTwoDatesForSorting(a.dueDate, b.dueDate))
            .map((partialPayment, index) => {
              const partialPaymentName = `${incomingInvoiceFormFields.partialAmountList}[${index}]`;

              const groupSumBruttoBySteuersatz = groupBuchungPositionBySteuersatz(partialPayment.buchungPositionList).map(
                // ---------------------------------------- Netto          + Vat ----------------------------------------//
                (position) => position.summe + position.summe * (position.steuersatz / 100)
              );
              const sumBrutto = formikProps.values.inklusiveSteuer
                ? partialPayment.buchungPositionList.reduce((acc, val) => acc + Number(val.betrag), 0)
                : groupSumBruttoBySteuersatz.reduce((acc, val) => acc + val, 0);

              const attr = getCollapseAttribute(partialPayment.id, collapseIdList, newActiveKey);

              return (
                <Row align="top" key={partialPayment.id}>
                  <Col span={6} style={{ display: 'flex', alignItems: 'center', minHeight: '44px' }}>
                    <Typography.Text strong>Teilbetrag {index + 1}</Typography.Text>
                  </Col>
                  <Col span={18} style={{ width: '100%' }}>
                    <Collapse
                      {...attr}
                      onChange={(activeKey) => {
                        setNewActiveKey(activeKey[0]);
                      }}
                    >
                      <StyledCollapsePanel
                        key={partialPayment.id}
                        header={
                          <Row style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Col span={8}>
                              <Typography.Text>Fällig am: </Typography.Text>
                              <CustomFormattedDate value={partialPayment.dueDate} />
                            </Col>
                            <Col span={6}>
                              <Typography.Text>Brutto: </Typography.Text>
                              <EuroAmount value={sumBrutto} />
                            </Col>
                            <Col span={4}>
                              <Button type="link" disabled={shouldRestore} onClick={() => arrayHelpers.remove(index)}>
                                Löschen
                              </Button>
                            </Col>
                          </Row>
                        }
                      >
                        <Row gutter={16}>
                          <Col span={8}>
                            <FormItemWithoutColon name={`${partialPaymentName}.${partialAmountFormFields.dueDate}`} label="Fällig am">
                              <DatePicker
                                disabled={shouldRestore}
                                size="middle"
                                id={`${partialPaymentName}.${partialAmountFormFields.dueDate}`}
                                name={`${partialPaymentName}.${partialAmountFormFields.dueDate}`}
                                style={{ width: '100%' }}
                                format={DATE_DISPLAY_FORMAT_DEFAULT}
                              />
                            </FormItemWithoutColon>
                          </Col>
                          <Col span={8}>
                            <FormItemWithoutColon name={`${partialPaymentName}.${partialAmountFormFields.buchungsdatum}`} label="Buchungsdatum">
                              <DatePicker
                                disabled={shouldRestore}
                                size="middle"
                                id={`${partialPaymentName}.${partialAmountFormFields.buchungsdatum}`}
                                name={`${partialPaymentName}.${partialAmountFormFields.buchungsdatum}`}
                                style={{ width: '100%' }}
                                format={DATE_DISPLAY_FORMAT_DEFAULT}
                              />
                            </FormItemWithoutColon>
                          </Col>
                          <Col span={8}>
                            <FormItemWithoutColon
                              name={`${partialPaymentName}.${partialAmountFormFields.requestedPaymentDate}`}
                              label="Zahlungsdatum"
                            >
                              <DatePicker
                                disabled={shouldRestore}
                                size="middle"
                                id={`${partialPaymentName}.${partialAmountFormFields.requestedPaymentDate}`}
                                name={`${partialPaymentName}.${partialAmountFormFields.requestedPaymentDate}`}
                                style={{ width: '100%' }}
                                format={DATE_DISPLAY_FORMAT_DEFAULT}
                              />
                            </FormItemWithoutColon>
                          </Col>
                        </Row>
                        <PositionListFormFieldArray
                          shouldRestore={shouldRestore}
                          name={`${partialPaymentName}.${partialAmountFormFields.buchungPositionList}`}
                          buchungPositionList={partialPayment.buchungPositionList}
                        />
                      </StyledCollapsePanel>
                    </Collapse>
                  </Col>
                </Row>
              );
            })}
          <Spacer />
          <FormBlockRow
            rowTitle=""
            content={
              <>
                <Button
                  disabled={shouldRestore}
                  icon={<PlusOutlined />}
                  type="dashed"
                  htmlType="button"
                  block
                  onClick={() => {
                    const newPartialAmount = createPartialAmountFormValues(
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      formikProps.values.partialAmountList![formikProps.values.partialAmountList!.length - 1]
                    );
                    arrayHelpers.push(newPartialAmount);
                    setNewActiveKey(newPartialAmount.id);
                  }}
                >
                  Teilbetrag hinzufügen
                </Button>
                <Error formikProps={formikProps} />
              </>
            }
          />
        </Space>
      )}
    />
  );
};

// When new partial amount is added, it should be expanded and the rest should be collapsed
const getCollapseAttribute = (partialPaymentId: string, collapseIdList?: string[], newActiveKey?: string) => {
  if (newActiveKey) {
    return newActiveKey === partialPaymentId
      ? {
          defaultActiveKey: [partialPaymentId],
          activeKey: [partialPaymentId],
        }
      : {
          activeKey: [],
        };
  }
  if (collapseIdList?.length === 1) {
    return {
      defaultActiveKey: [partialPaymentId],
    };
  }
  if (collapseIdList && collapseIdList.length >= 1) {
    return {
      defaultActiveKey: [collapseIdList[collapseIdList.length - 1]],
    };
  }
  return {};
};

const groupBuchungPositionBySteuersatz = (buchungPositionList: IncomingInvoiceBuchungPositionFormValues[]) => {
  return buchungPositionList.reduce(
    (acc, pos) => {
      const group = acc.find((g) => g.steuersatz === pos.steuersatz);
      if (group) {
        group.summe += pos.betrag as number;
      } else {
        acc.push({ steuersatz: pos.steuersatz as number, summe: pos.betrag as number });
      }
      return acc;
    },
    [] as { steuersatz: number; summe: number }[]
  );
};

type ErrorProps = {
  formikProps: FormikProps<IncomingInvoiceFormValues>;
};

const Error: FC<ErrorProps> = ({ formikProps }) => {
  if (formikProps.errors.partialAmountList && formikProps.touched.partialAmountList && !Array.isArray(formikProps.errors.partialAmountList)) {
    return <Typography.Text type="danger">{formikProps.errors.partialAmountList}</Typography.Text>;
  }
  return null;
};

export default PartialAmountListFormFieldArray;
