import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { ApplyVertragVposIndexedValueGenerierlaufEntryFieldsFragmentDoc } from './ApplyVertragVposIndexedValueGenerierlaufFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApplyVertragVposIndexedValueGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type ApplyVertragVposIndexedValueGenerierlaufQuery = {
  getApplyVertragVposIndexedValueGenerierlauf: {
    data: {
      countFehler: number;
      countVerarbeitet: number;
      countNichtVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierDatum: string;
      generierlaufId: string;
      errorList: Array<{ message: string; type: string }>;
      status: { text: string; value: Types.GenerierlaufStatus; description?: string | null };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ApplyVertragVposIndexedValueGenerierlaufEntryListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  bestandseinheitIdList?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>
  >;
  vertragId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  valuePreservationType?: Types.InputMaybe<Types.ValuePreservationType>;
  indexSeriesId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  order?: Types.InputMaybe<Types.VertragVposIndexedValueGenerierlaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type ApplyVertragVposIndexedValueGenerierlaufEntryListQuery = {
  getApplyVertragVposIndexedValueGenerierlaufEntryList: {
    data: {
      contentList: Array<{
        bestandseinheitBezeichnung: string;
        bestandseinheitId: string;
        currentStep: number;
        generierlaufEntryId: string;
        infoMessage?: string | null;
        objektId: string;
        objektKurzBezeichnung: string;
        rechnungsAusstellerBezeichnung: string;
        rechnungsAusstellerId: string;
        retry: boolean;
        stepCount: number;
        vertragBezeichnung: string;
        vertragId: string;
        vertragspartnerId: string;
        vertragspartnerKurzBezeichnung: string;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { text: string; value: Types.GenerierlaufEntryExitCode } | null;
        indexedValue: {
          amountNew: number;
          amountOld: number;
          bestandseinheitBezeichnung: string;
          bestandseinheitId: string;
          calculatedAmountNew: number;
          calculatedFactorNew: number;
          calculatedFactorNewPercent: number;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          factorNew: number;
          factorNewPercent: number;
          indexNew: number;
          indexNewPreliminary: boolean;
          indexOld: number;
          indexOldPreliminary: boolean;
          indexSeriesId: string;
          indexSeriesName: string;
          monthYearNew: string;
          monthYearOld: string;
          name: string;
          objektId: string;
          objektKurzBezeichnung: string;
          rechnungsAusstellerId: string;
          rechnungsAusstellerKurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragId: string;
          vertragKurzBezeichnung: string;
          vertragVposIndexedValueId: string;
          vertragspartnerId: string;
          vertragspartnerKurzBezeichnung: string;
          vorschreibungspositionId: string;
          vposWertNewId?: string | null;
          vposWertOldId?: string | null;
          indexationAgreement:
            | {
                __typename: 'VertragVposIndexationAgreementCategory';
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                indexOld?: number | null;
                indexOldPreliminary: boolean;
                monthYearOld: string;
                updatedByMitarbeiterId?: string | null;
                validFrom: string;
                vertragVposIndexationAgreementId: string;
                basedOnUsableArea: boolean;
                indexSeries: {
                  chainingFactor?: number | null;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  csvColumnImportId?: string | null;
                  csvColumnIndexPoint?: string | null;
                  csvColumnMonthYear?: string | null;
                  csvRowImportId?: string | null;
                  deletable: boolean;
                  firmendatenId?: string | null;
                  importUrl?: string | null;
                  indexSeriesId: string;
                  name: string;
                  updatedByMitarbeiterId?: string | null;
                  indexSeriesEntryList: Array<{
                    annualAverageFinal?: number | null;
                    annualAveragePreliminary?: number | null;
                    createTs: string;
                    createdBy?: string | null;
                    createdByMitarbeiterId?: string | null;
                    indexSeriesEntryId: string;
                    updatedByMitarbeiterId?: string | null;
                    year: number;
                    indexPointList: Array<{
                      createTs: string;
                      createdBy?: string | null;
                      createdByMitarbeiterId?: string | null;
                      finalValue?: number | null;
                      indexPointId: string;
                      month: string;
                      preliminaryValue?: number | null;
                      updatedByMitarbeiterId?: string | null;
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    }>;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  indexType: { text: string; value: Types.IndexType };
                  sourceType: { text: string; value: Types.IndexSeriesSourceType };
                  status?: { text: string; value: Types.IndexSeriesStatus } | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                type: { text: string; value: Types.ValuePreservationType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }
            | {
                __typename: 'VertragVposIndexationAgreementIndexAnnual';
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                indexOld?: number | null;
                indexOldPreliminary: boolean;
                monthYearOld: string;
                updatedByMitarbeiterId?: string | null;
                validFrom: string;
                vertragVposIndexationAgreementId: string;
                annualAverage: boolean;
                minimumLimit?: number | null;
                shareInPercentToApply?: number | null;
                subsequentBilling: boolean;
                threshold: number;
                indexSeries: {
                  chainingFactor?: number | null;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  csvColumnImportId?: string | null;
                  csvColumnIndexPoint?: string | null;
                  csvColumnMonthYear?: string | null;
                  csvRowImportId?: string | null;
                  deletable: boolean;
                  firmendatenId?: string | null;
                  importUrl?: string | null;
                  indexSeriesId: string;
                  name: string;
                  updatedByMitarbeiterId?: string | null;
                  indexSeriesEntryList: Array<{
                    annualAverageFinal?: number | null;
                    annualAveragePreliminary?: number | null;
                    createTs: string;
                    createdBy?: string | null;
                    createdByMitarbeiterId?: string | null;
                    indexSeriesEntryId: string;
                    updatedByMitarbeiterId?: string | null;
                    year: number;
                    indexPointList: Array<{
                      createTs: string;
                      createdBy?: string | null;
                      createdByMitarbeiterId?: string | null;
                      finalValue?: number | null;
                      indexPointId: string;
                      month: string;
                      preliminaryValue?: number | null;
                      updatedByMitarbeiterId?: string | null;
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    }>;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  indexType: { text: string; value: Types.IndexType };
                  sourceType: { text: string; value: Types.IndexSeriesSourceType };
                  status?: { text: string; value: Types.IndexSeriesStatus } | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                type: { text: string; value: Types.ValuePreservationType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                effectiveWithMonth?: { text: string; value: Types.Month } | null;
                monthOfIndexPoint: { text: string; value: Types.Month };
                subsequentBillingObjektVorschreibungsposition?: {
                  bezeichnung: string;
                  objektVorschreibungspositionId: string;
                  kurzBezeichnung: string;
                } | null;
                thresholdType: { text: string; value: Types.ThresholdType };
              }
            | {
                __typename: 'VertragVposIndexationAgreementIndexThreshold';
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                indexOld?: number | null;
                indexOldPreliminary: boolean;
                monthYearOld: string;
                updatedByMitarbeiterId?: string | null;
                validFrom: string;
                vertragVposIndexationAgreementId: string;
                effectiveAfterMonths?: number | null;
                minimumLimit?: number | null;
                shareInPercentToApply?: number | null;
                subsequentBilling: boolean;
                threshold: number;
                indexSeries: {
                  chainingFactor?: number | null;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  csvColumnImportId?: string | null;
                  csvColumnIndexPoint?: string | null;
                  csvColumnMonthYear?: string | null;
                  csvRowImportId?: string | null;
                  deletable: boolean;
                  firmendatenId?: string | null;
                  importUrl?: string | null;
                  indexSeriesId: string;
                  name: string;
                  updatedByMitarbeiterId?: string | null;
                  indexSeriesEntryList: Array<{
                    annualAverageFinal?: number | null;
                    annualAveragePreliminary?: number | null;
                    createTs: string;
                    createdBy?: string | null;
                    createdByMitarbeiterId?: string | null;
                    indexSeriesEntryId: string;
                    updatedByMitarbeiterId?: string | null;
                    year: number;
                    indexPointList: Array<{
                      createTs: string;
                      createdBy?: string | null;
                      createdByMitarbeiterId?: string | null;
                      finalValue?: number | null;
                      indexPointId: string;
                      month: string;
                      preliminaryValue?: number | null;
                      updatedByMitarbeiterId?: string | null;
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    }>;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  indexType: { text: string; value: Types.IndexType };
                  sourceType: { text: string; value: Types.IndexSeriesSourceType };
                  status?: { text: string; value: Types.IndexSeriesStatus } | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                type: { text: string; value: Types.ValuePreservationType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                subsequentBillingObjektVorschreibungsposition?: {
                  bezeichnung: string;
                  objektVorschreibungspositionId: string;
                  kurzBezeichnung: string;
                } | null;
                thresholdType: { text: string; value: Types.ThresholdType };
              }
            | {
                __typename: 'VertragVposIndexationAgreementReferenceValue';
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                indexOld?: number | null;
                indexOldPreliminary: boolean;
                monthYearOld: string;
                updatedByMitarbeiterId?: string | null;
                validFrom: string;
                vertragVposIndexationAgreementId: string;
                indexSeries: {
                  chainingFactor?: number | null;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  csvColumnImportId?: string | null;
                  csvColumnIndexPoint?: string | null;
                  csvColumnMonthYear?: string | null;
                  csvRowImportId?: string | null;
                  deletable: boolean;
                  firmendatenId?: string | null;
                  importUrl?: string | null;
                  indexSeriesId: string;
                  name: string;
                  updatedByMitarbeiterId?: string | null;
                  indexSeriesEntryList: Array<{
                    annualAverageFinal?: number | null;
                    annualAveragePreliminary?: number | null;
                    createTs: string;
                    createdBy?: string | null;
                    createdByMitarbeiterId?: string | null;
                    indexSeriesEntryId: string;
                    updatedByMitarbeiterId?: string | null;
                    year: number;
                    indexPointList: Array<{
                      createTs: string;
                      createdBy?: string | null;
                      createdByMitarbeiterId?: string | null;
                      finalValue?: number | null;
                      indexPointId: string;
                      month: string;
                      preliminaryValue?: number | null;
                      updatedByMitarbeiterId?: string | null;
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    }>;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  indexType: { text: string; value: Types.IndexType };
                  sourceType: { text: string; value: Types.IndexSeriesSourceType };
                  status?: { text: string; value: Types.IndexSeriesStatus } | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                type: { text: string; value: Types.ValuePreservationType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
          status: { text: string; value: Types.VertragVposIndexedValueStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        stepList: Array<{
          designation: string;
          receivingApplication?: string | null;
          stepId: string;
          type?: string | null;
          status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
        }>;
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const ApplyVertragVposIndexedValueGenerierlaufDocument = gql`
  query ApplyVertragVposIndexedValueGenerierlauf($generierlaufId: ID!) {
    getApplyVertragVposIndexedValueGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countVerarbeitet
        countNichtVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        generierDatum
        generierlaufId
        status {
          text
          value
          description
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useApplyVertragVposIndexedValueGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<ApplyVertragVposIndexedValueGenerierlaufQuery, ApplyVertragVposIndexedValueGenerierlaufQueryVariables> &
    ({ variables: ApplyVertragVposIndexedValueGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApplyVertragVposIndexedValueGenerierlaufQuery, ApplyVertragVposIndexedValueGenerierlaufQueryVariables>(
    ApplyVertragVposIndexedValueGenerierlaufDocument,
    options
  );
}
export function useApplyVertragVposIndexedValueGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ApplyVertragVposIndexedValueGenerierlaufQuery, ApplyVertragVposIndexedValueGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApplyVertragVposIndexedValueGenerierlaufQuery, ApplyVertragVposIndexedValueGenerierlaufQueryVariables>(
    ApplyVertragVposIndexedValueGenerierlaufDocument,
    options
  );
}
export function useApplyVertragVposIndexedValueGenerierlaufSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ApplyVertragVposIndexedValueGenerierlaufQuery, ApplyVertragVposIndexedValueGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ApplyVertragVposIndexedValueGenerierlaufQuery, ApplyVertragVposIndexedValueGenerierlaufQueryVariables>(
    ApplyVertragVposIndexedValueGenerierlaufDocument,
    options
  );
}
export type ApplyVertragVposIndexedValueGenerierlaufQueryHookResult = ReturnType<typeof useApplyVertragVposIndexedValueGenerierlaufQuery>;
export type ApplyVertragVposIndexedValueGenerierlaufLazyQueryHookResult = ReturnType<typeof useApplyVertragVposIndexedValueGenerierlaufLazyQuery>;
export type ApplyVertragVposIndexedValueGenerierlaufSuspenseQueryHookResult = ReturnType<
  typeof useApplyVertragVposIndexedValueGenerierlaufSuspenseQuery
>;
export type ApplyVertragVposIndexedValueGenerierlaufQueryResult = Apollo.QueryResult<
  ApplyVertragVposIndexedValueGenerierlaufQuery,
  ApplyVertragVposIndexedValueGenerierlaufQueryVariables
>;
export const ApplyVertragVposIndexedValueGenerierlaufEntryListDocument = gql`
  query ApplyVertragVposIndexedValueGenerierlaufEntryList(
    $generierlaufId: ID!
    $objektId: String
    $bestandseinheitIdList: [String]
    $vertragId: String
    $valuePreservationType: ValuePreservationType
    $indexSeriesId: String
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $order: VertragVposIndexedValueGenerierlaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getApplyVertragVposIndexedValueGenerierlaufEntryList(
      generierlaufId: $generierlaufId
      objektId: $objektId
      bestandseinheitIdList: $bestandseinheitIdList
      vertragId: $vertragId
      valuePreservationType: $valuePreservationType
      indexSeriesId: $indexSeriesId
      exitCodeList: $exitCodeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          ...ApplyVertragVposIndexedValueGenerierlaufEntryFields
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${ApplyVertragVposIndexedValueGenerierlaufEntryFieldsFragmentDoc}
`;
export function useApplyVertragVposIndexedValueGenerierlaufEntryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApplyVertragVposIndexedValueGenerierlaufEntryListQuery,
    ApplyVertragVposIndexedValueGenerierlaufEntryListQueryVariables
  > &
    ({ variables: ApplyVertragVposIndexedValueGenerierlaufEntryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApplyVertragVposIndexedValueGenerierlaufEntryListQuery, ApplyVertragVposIndexedValueGenerierlaufEntryListQueryVariables>(
    ApplyVertragVposIndexedValueGenerierlaufEntryListDocument,
    options
  );
}
export function useApplyVertragVposIndexedValueGenerierlaufEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplyVertragVposIndexedValueGenerierlaufEntryListQuery,
    ApplyVertragVposIndexedValueGenerierlaufEntryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApplyVertragVposIndexedValueGenerierlaufEntryListQuery, ApplyVertragVposIndexedValueGenerierlaufEntryListQueryVariables>(
    ApplyVertragVposIndexedValueGenerierlaufEntryListDocument,
    options
  );
}
export function useApplyVertragVposIndexedValueGenerierlaufEntryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ApplyVertragVposIndexedValueGenerierlaufEntryListQuery,
        ApplyVertragVposIndexedValueGenerierlaufEntryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ApplyVertragVposIndexedValueGenerierlaufEntryListQuery,
    ApplyVertragVposIndexedValueGenerierlaufEntryListQueryVariables
  >(ApplyVertragVposIndexedValueGenerierlaufEntryListDocument, options);
}
export type ApplyVertragVposIndexedValueGenerierlaufEntryListQueryHookResult = ReturnType<
  typeof useApplyVertragVposIndexedValueGenerierlaufEntryListQuery
>;
export type ApplyVertragVposIndexedValueGenerierlaufEntryListLazyQueryHookResult = ReturnType<
  typeof useApplyVertragVposIndexedValueGenerierlaufEntryListLazyQuery
>;
export type ApplyVertragVposIndexedValueGenerierlaufEntryListSuspenseQueryHookResult = ReturnType<
  typeof useApplyVertragVposIndexedValueGenerierlaufEntryListSuspenseQuery
>;
export type ApplyVertragVposIndexedValueGenerierlaufEntryListQueryResult = Apollo.QueryResult<
  ApplyVertragVposIndexedValueGenerierlaufEntryListQuery,
  ApplyVertragVposIndexedValueGenerierlaufEntryListQueryVariables
>;
