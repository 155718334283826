import React, { FC } from 'react';
import { WizardStepForm } from '../../../../components/Wizard';
import FirmendatenBaseFormPart from '../form/FirmendatenBaseFormPart';
import { mapFirmendatenBaseToFormValues } from '../form/firmendatenBaseFormPartMapper';
import { firmendatenBaseFormPartValidationSchema } from '../form/firmendatenBaseFormPartValidationSchema';

type FirmendatenBaseWizardStepFormProps = {
  stepId: string;
  fieldHelpSelectorEntityName: 'KundenSystem' | 'Firmendaten'; //Important to narrow down the type
};

const FirmendatenBaseWizardStepForm: FC<FirmendatenBaseWizardStepFormProps> = ({ stepId, fieldHelpSelectorEntityName }) => (
  <WizardStepForm
    stepId={stepId}
    formInitialValues={mapFirmendatenBaseToFormValues()}
    formValidationSchema={firmendatenBaseFormPartValidationSchema}
    formValidateOnChange={false}
    heading="Firmenstammdaten"
    render={(formikProps) => <FirmendatenBaseFormPart fieldHelpSelectorEntityName={fieldHelpSelectorEntityName} formikProps={formikProps} />}
  />
);

export default FirmendatenBaseWizardStepForm;
