import React from 'react';
import { Link } from 'react-router-dom';
import { Tag, Typography } from 'antd';
import { generatePathToUstKategorieTemplatePage } from '../../../UstKaterogieTemplate/ustKategorieTemplateUriHelper';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { compareTwoDatesForSorting } from '../../../../../helpers/dateHelper';
import { MitarbeiterTooltip } from '../../../../../components/Card';
import { UstRegelset } from '../../../../../types';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import UstRegelsetTemplateTableActions from './UstRegelsetTemplateTableActions';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';

export const ustRegelsetTemplateTableColumns = (onActionSuccess: () => void): TableWithColSelectorColumnProps<UstRegelset>[] => [
  {
    title: 'Kurzbezeichnung',
    defaultSelected: true,
    render: (text, record) => record.bezeichnung,
  },
  {
    title: 'Land',
    defaultSelected: true,
    render: (text, record) => record.land?.bezeichnung ?? null,
  },
  {
    title: 'Nutzungsart',
    defaultSelected: true,
    render: (text, record) => record.nutzungsArt.text,
  },
  {
    title: 'Steuerpflicht',
    defaultSelected: true,
    render: (text, record) => record.steuerpflicht.text,
  },
  {
    title: 'Option zur Steuerpflicht',
    defaultSelected: true,
    render: (text, record) => <Typography.Text>{record.optionSteuerpflicht ? 'ja' : 'nein'}</Typography.Text>,
  },
  {
    title: 'Leerstehung',
    defaultSelected: true,
    render: (text, record) => <Typography.Text>{record.leerstehung ? 'ja' : 'nein'}</Typography.Text>,
  },
  {
    title: 'Eigennutzung',
    defaultSelected: true,
    render: (text, record) => <Typography.Text>{record.eigennutzung ? 'ja' : 'nein'}</Typography.Text>,
  },
  {
    title: 'USt.-Kategorie',
    defaultSelected: true,
    render: (text, record) =>
      /* eslint-disable-next-line no-nested-ternary */
      record.ustKategorie ? (
        <DataWithShortenedText text={record.ustKategorie.kurzBezeichnung} maxTextLength={30}>
          {(shortenedText) => (
            <Link
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              to={generatePathToUstKategorieTemplatePage(record.ustKategorie!.ustKategorieId, {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ustKategorieId: record.ustKategorie!.ustKategorieId,
              })}
            >
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ) : (
        <Tag color="warning">Keine aktive Zuweisung</Tag>
      ),
  },
  {
    title: 'Erstellt am',
    sorter: (a, b) => compareTwoDatesForSorting(a.createTs, b.createTs),
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    key: 'action',
    width: 80,
    render: (text, record) => <UstRegelsetTemplateTableActions onAction={onActionSuccess} ustRegelset={record} />,
  },
];
