import { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { usePain008SchemaListQuery } from '../gql/PainSchemaQueries.types';

type Props = {
  name: string;
} & Omit<SelectProps, 'name' | 'id' | 'loading'>;

const Pain008FormSelect: FC<Props> = ({ name, ...restProps }) => {
  const { data, loading } = usePain008SchemaListQuery();
  const painSchemaList = data?.getPain008SchemaList.data ?? [];

  return (
    <Select {...restProps} size="small" name={name} id={name} loading={loading}>
      {painSchemaList.map((schema) => (
        <Select.Option key={schema.value} value={schema.value}>
          {schema.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default Pain008FormSelect;
