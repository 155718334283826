import React, { FC } from 'react';
import OperatingSiteBtnAndModal from '../../../../shared/components/OperatingSite/OperatingSiteBtnAndModal';
import { useFDAuthorized } from '../../../../shared/Auth/useAuthorized';
import OperatingSiteList from '../../../../shared/components/OperatingSite/List/OperatingSiteList';
import { useFirmaOperatingSiteListQuery } from '../gql/FirmaOperatingSiteQueries.types';

type Props = {
  firmaId: string;
};

const OperatingSiteListing: FC<Props> = ({ firmaId }) => {
  const { activeForFirmendatenId } = useFDAuthorized();
  const { data, refetch } = useFirmaOperatingSiteListQuery({ variables: { firmaId } });
  const operatingSiteList = data?.getFirmaOperatingSiteList.data ?? [];

  return (
    <>
      <OperatingSiteBtnAndModal firmaId={firmaId} onSuccess={refetch} />
      {operatingSiteList.map((operatingSite) => (
        <OperatingSiteList
          operatingSiteList={operatingSiteList}
          firmendatenId={activeForFirmendatenId}
          key={operatingSite.operatingSiteId}
          operatingSite={operatingSite}
          firmaId={firmaId}
          onSuccess={refetch}
        />
      ))}
    </>
  );
};

export default OperatingSiteListing;
