import React, { FC } from 'react';
import { Button, Divider, Space } from 'antd';
import SingleInvoiceDirectBookingPreviewDetails from './SingleInvoiceDirectBookingPreviewDetails';
import SingleInvoiceAccrualBookingPreviewDetails from './SingleInvoiceAccrualBookingPreviewDetails';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../../../components/Button/ButtonsAligned';
import { IncomingInvoiceFormValues, mapFormValuesToInvoiceCreate } from '../../incomingInvoiceFormMapper';
import { SingleInvoiceCreateInput, StandingInvoiceCreateInput } from '../../../../../types';
import { isBillingTypeEinzelrechnung } from '../../../../Payment/paymentHelpers';
import StandingInvoiceBookingPreviewDetails from './StandingInvoiceBookingPreviewDetails';

type Props = {
  incomingInvoiceFormValues: IncomingInvoiceFormValues;
  toggleBookingPreviewModal: () => void;
};

const BookingPreview: FC<Props> = ({ incomingInvoiceFormValues, toggleBookingPreviewModal }) => {
  const input = mapFormValuesToInvoiceCreate(incomingInvoiceFormValues);

  const content = isBillingTypeEinzelrechnung(incomingInvoiceFormValues.billingType) ? (
    <>
      {!incomingInvoiceFormValues.abgrenzungsBuchung ? (
        <SingleInvoiceDirectBookingPreviewDetails input={input as SingleInvoiceCreateInput} />
      ) : (
        <SingleInvoiceAccrualBookingPreviewDetails input={input as SingleInvoiceCreateInput} />
      )}
    </>
  ) : (
    <StandingInvoiceBookingPreviewDetails input={input as StandingInvoiceCreateInput} />
  );

  return (
    <>
      {content}
      <Space direction="vertical" style={{ width: '100%' }}>
        <Divider style={{ margin: 8 }} />
        <ButtonsAligned usage={ButtonsAlignedUsage.drawerFooter}>
          <Button type="primary" onClick={toggleBookingPreviewModal}>
            Schließen
          </Button>
        </ButtonsAligned>
      </Space>
    </>
  );
};

export default BookingPreview;
