import React, { FC } from 'react';
import { Input } from 'formik-antd';
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';

type EmailFormPartProps = {
  emailInputName?: string;
  emailInputLabel?: string;
  emailInputPlaceholder?: string;
  emailInputValidation: any; // func
};

const EmailFormPart: FC<EmailFormPartProps> = ({
  emailInputName = 'email',
  emailInputLabel = 'E-Mail-Adresse',
  emailInputPlaceholder = 'z.B. email@email.at',
  emailInputValidation,
}) => (
  <FormItemWithoutColon name={emailInputName} label={emailInputLabel}>
    <Input
      id={emailInputName}
      name={emailInputName}
      placeholder={emailInputPlaceholder}
      validate={emailInputValidation}
      prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
    />
  </FormItemWithoutColon>
);

export default EmailFormPart;
