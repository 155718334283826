import { Modal } from 'antd';
import { Konto } from '../../../types';
import { isStatusActive } from '../../../helpers/statusHelper';

export const showKontoConfirmDelete = (konto: Konto, runDelete: () => void) => () => {
  Modal.confirm({
    title: 'Möchten Sie dieses Konto löschen?',
    content: `${konto.bezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export const showKontoConfirmArchive = (runArchive: () => void, runUnarchive: () => void, konto: Konto) => () => {
  Modal.confirm({
    title: !isStatusActive(konto.status) ? `Möchten Sie das Konto reaktivieren?` : `Möchten Sie das Konto archivieren?`,
    content: konto.bezeichnung,
    okText: !isStatusActive(konto.status) ? 'Reaktivieren' : 'Archivieren',
    cancelText: 'Nein',
    onOk() {
      if (!isStatusActive(konto.status)) {
        return runArchive();
      } else {
        return runUnarchive();
      }
    },
  });
};
