import React, { FC } from 'react';
import { Button, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import KundenSystemCreateWizard from './KundenSystemCreateWizard';
import { useToggle } from '../../../hooks/useToggle';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { useCreateKundenSystemMutation } from './gql/KundenSystemMutations.types';

type KundenSystemActionsProps = {
  onSuccess: () => void;
};

const KundenSystemActions: FC<KundenSystemActionsProps> = ({ onSuccess }) => {
  const [isCollapsed, onCollapse] = useToggle();

  const [createKundenSystem] = useCreateKundenSystemMutation({
    onCompleted: () => {
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <>
      <Button htmlType="button" type="primary" size="small" onClick={onCollapse}>
        <PlusOutlined />
        Kundensystem anlegen
      </Button>
      <Modal title="Kundensystem anlegen" open={isCollapsed} onCancel={onCollapse} footer={null} destroyOnClose maskClosable={false}>
        <KundenSystemCreateWizard
          onCreate={(kundenSystem) => {
            createKundenSystem({ variables: { request: kundenSystem } });
            onCollapse();
          }}
          onCancel={onCollapse}
        />
      </Modal>
    </>
  );
};

export default KundenSystemActions;
