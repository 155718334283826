import React, { FC } from 'react';
import { Space } from 'antd';
import { Form, Formik } from 'formik';
import MahndefinitionParamFormMahnstufen from '../Mahnstufen/MahndefinitionParamFormMahnstufen';
import { MahndefinitionParamFormValues, mapMahndefinitionToParamFormValues } from '../../mahndefinitionFormMapper';
import { mahndefinitionParamFormValidationSchema } from '../mahndefinitionParamFormValidationSchema';
import DefinitionUpdateFormButtons from '../../../shared/DefinitionUpdateFormButtons';
import { validateMahngebuehren } from '../../mahndefinitionValidation';
import { URI_SYS_SETTINGS } from '../../../../constants/configurationUriPaths';
import MahndefinitionParamFormAllgemein from '../MahndefinitionParamFormAllgemein';
import { MahndefinitionFieldsFragment } from '../../gql/MahndefinitionFragments.types';

type Props = {
  mahndefinition: MahndefinitionFieldsFragment;
  onFormSubmit: (paramFormValues?: MahndefinitionParamFormValues) => void;
  setIsVersandEinschreiben1?: (value: boolean) => void;
  setIsVersandEinschreiben2?: (value: boolean) => void;
  setIsVersandEinschreiben3?: (value: boolean) => void;
  setIsVersandEinschreiben4?: (value: boolean) => void;
};

const MahndefinitionUpdateParamForm: FC<Props> = ({
  mahndefinition,
  onFormSubmit,
  setIsVersandEinschreiben1,
  setIsVersandEinschreiben2,
  setIsVersandEinschreiben3,
  setIsVersandEinschreiben4,
}) => {
  return (
    <Formik<MahndefinitionParamFormValues>
      initialValues={mapMahndefinitionToParamFormValues(mahndefinition)}
      validationSchema={mahndefinitionParamFormValidationSchema}
      onSubmit={(mahndefinitionParamFormValues, formikHelpers) => {
        if (validateMahngebuehren(mahndefinitionParamFormValues, formikHelpers)) {
          return;
        }
        onFormSubmit(mahndefinitionParamFormValues);
      }}
    >
      {(formikProps) => (
        <Form>
          <Space direction="vertical" size="middle" style={{ width: '50%', marginTop: '16px' }}>
            <MahndefinitionParamFormAllgemein />
            <MahndefinitionParamFormMahnstufen
              formikProps={formikProps}
              setIsVersandEinschreiben1={setIsVersandEinschreiben1}
              setIsVersandEinschreiben2={setIsVersandEinschreiben2}
              setIsVersandEinschreiben3={setIsVersandEinschreiben3}
              setIsVersandEinschreiben4={setIsVersandEinschreiben4}
            />
          </Space>
          <DefinitionUpdateFormButtons formikProps={formikProps} pushPath={URI_SYS_SETTINGS.mahndefinitionPage} />
        </Form>
      )}
    </Formik>
  );
};

export default MahndefinitionUpdateParamForm;
