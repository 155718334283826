import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useBeVertragKurzBezeichnungQuery } from '../../../../../Breadcrumb/gql/KurzbezeichnungQueries.types';
import DataWithShortenedTextAndExtraInfo from '../../../../../../components/Helpers/DataWithShortenedTextAndExtraInfo';
import { generatePathToBeVertragDetailsPage } from '../../../../../Vertrag/BeVertrag/beVertragUriPaths';
import { LoadingCol } from '../../../../../../components/Loading';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  maxTextLength?: number;
  openLinkInNewTab?: boolean;
};

const VertragKurzbezeichnungWithLinkCol: FC<Props> = ({ objektId, bestandseinheitId, vertragId, maxTextLength, openLinkInNewTab }) => {
  const { loading, data } = useBeVertragKurzBezeichnungQuery({
    variables: { objektId, bestandseinheitId, vertragId },
  });

  const vertragKurzbezeichnung = data?.getBeVertragKurzBezeichnung.data ?? '';

  return (
    <LoadingCol isLoading={loading}>
      <DataWithShortenedTextAndExtraInfo maxTextLength={maxTextLength ?? 12} text={vertragKurzbezeichnung}>
        {(shortenedText) => (
          <Link target={openLinkInNewTab ? '_blank' : '_self'} to={generatePathToBeVertragDetailsPage(objektId, bestandseinheitId, vertragId)}>
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedTextAndExtraInfo>
    </LoadingCol>
  );
};

export default VertragKurzbezeichnungWithLinkCol;
