import { FormFields } from '../../../../helpers/formikHelper';
import { EbicsUser, EbicsUserCreateInput, EbicsUserUpdateInput } from '../../../../types';

export type EbicsUserFormValues = {
  ebicsBankConfigId: string;
  mitarbeiterId: string;
  partnerId: string;
  password: string;
  userId: string;
};

export const ebicsUserFormFields: FormFields<EbicsUserFormValues> = {
  ebicsBankConfigId: 'ebicsBankConfigId',
  mitarbeiterId: 'mitarbeiterId',
  partnerId: 'partnerId',
  password: 'password',
  userId: 'userId',
};

export const mapEbicsUserToFormValues = (ebicsUser?: EbicsUser): EbicsUserFormValues => ({
  ebicsBankConfigId: ebicsUser?.ebicsBankConfigId ?? '',
  mitarbeiterId: ebicsUser?.mitarbeiterId ?? '',
  partnerId: ebicsUser?.partnerId ?? '',
  password: ebicsUser?.password ?? '',
  userId: ebicsUser?.userId ?? '',
});

export const mapFormValuesToEbicsConfig = (values: EbicsUserFormValues): EbicsUserCreateInput | EbicsUserUpdateInput => ({
  ebicsBankConfigId: values.ebicsBankConfigId,
  mitarbeiterId: values.mitarbeiterId,
  partnerId: values.partnerId,
  password: values.password,
  userId: values.userId,
});
