import styled from 'styled-components';
import { Space } from 'antd';
import theme from '../../../theme';

export const StyledSpace = styled(Space)`
  font-size: 9.2px;
  color: ${theme.textColor.secondary};
  .ant-typography {
    color: ${theme.textColor.secondary};
  }
`;
