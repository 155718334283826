import { FC } from 'react';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';
import { useAuftragsartGeneralChangeHistoryListQuery } from '../gql/AuftragsartQueries.types';

type Props = {
  auftragsartId: string;
};

const AuftragsartGeneralChangeHistoryListingTable: FC<Props> = ({ auftragsartId }) => {
  const { data, loading } = useAuftragsartGeneralChangeHistoryListQuery({ variables: { auftragsartId } });
  const historyList = data?.getAuftragsartGeneralChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="auftragsart-general" />;
};

export default AuftragsartGeneralChangeHistoryListingTable;
