import React from 'react';
import { Link } from 'react-router-dom';

import { Typography } from 'antd';
import { generatePathToVorschreibungDetailsPage } from '../vorschreibungUriPaths';
import { EuroAmount } from '../../../components/Number';
import RechtstraegerName from '../../../components/Rechtstraeger/RechtstraegerName';
import { MitarbeiterTooltip } from '../../../components/Card';
import AuftragsketteBelegnummerCol from './AuftragsketteBelegnummerCol';
import { compareTwoStringsForSorting } from '../../../helpers/stringHelper';
import { compareTwoNumbersForSorting } from '../../../helpers/numberHelper';
import { compareTwoDatesForSorting } from '../../../helpers/dateHelper';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { AuftragLinkListEntry } from '../../../types';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';
import { generatePathToBookingDetailsPage } from '../../BookingDetails/bookingDetailsPaths';

const auftragsketteTableColumns: TableWithColSelectorColumnProps<AuftragLinkListEntry>[] = [
  {
    title: 'Auftragsart',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.auftragsartType.text, b.auftragsartType.text),
    render: (text, record) => <Typography.Text type={record.current ? 'secondary' : undefined}>{record.auftragsartType.text}</Typography.Text>,
  },
  {
    title: 'Belegnr.',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.belegnummer, b.belegnummer),
    render: (text, record) => <AuftragsketteBelegnummerCol record={record} />,
  },
  {
    title: 'Belegdatum',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.belegdatum, b.belegdatum),
    render: (text, record) => (
      <Typography.Text type={record.current ? 'secondary' : undefined}>
        {record.belegdatum ? <CustomFormattedDate value={record.belegdatum} /> : '-'}
      </Typography.Text>
    ),
  },

  {
    title: 'Rechnungsaussteller',
    defaultSelected: true,
    render: (text, record) => (
      <RechtstraegerName rechtstraegerId={record.ausstellerRechtstraegerId} textType={record.current ? 'secondary' : undefined} maxLength={25} />
    ),
  },
  {
    title: 'Rechnungsempfänger',
    defaultSelected: true,
    render: (text, record) => (
      <RechtstraegerName rechtstraegerId={record.empfaengerRechtstraegerId} textType={record.current ? 'secondary' : undefined} maxLength={25} />
    ),
  },
  {
    title: 'Netto',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.sumNetto, b.sumNetto),
    render: (text, record) => (
      <Typography.Text type={record.current ? 'secondary' : undefined}>
        <EuroAmount value={record.sumNetto} />
      </Typography.Text>
    ),
  },
  {
    title: 'USt',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.sumUst, b.sumUst),
    render: (text, record) => (
      <Typography.Text type={record.current ? 'secondary' : undefined}>
        <EuroAmount value={record.sumUst} />
      </Typography.Text>
    ),
  },
  {
    title: 'Brutto',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.sumBrutto, b.sumBrutto),
    render: (text, record) =>
      record.buchungIdList && record.buchungIdList.length > 0 ? (
        <Link to={generatePathToBookingDetailsPage(record.buchungIdList, record.auftragId)} target="_blank">
          <EuroAmount value={record.sumBrutto} />
        </Link>
      ) : (
        <Typography.Text type={record.current ? 'secondary' : undefined}>
          <EuroAmount value={record.sumBrutto} />
        </Typography.Text>
      ),
  },
  {
    title: 'Erstellt am',
    sorter: (a, b) => compareTwoDatesForSorting(a.createTs, b.createTs),
    render: (text, record) => (
      <Typography.Text type={record.current ? 'secondary' : undefined}>
        <FormattedDateTime createdTs={record.createTs} />
      </Typography.Text>
    ),
  },
  {
    title: 'Ersteller',
    render: (text, record) => {
      return <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} showsInitials alignment="left" />;
    },
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) =>
      record.current ? (
        <Typography.Text type="secondary">Geöffnet</Typography.Text>
      ) : (
        <Link to={generatePathToVorschreibungDetailsPage(record.auftragId)}>Details</Link>
      ),
  },
];

export default auftragsketteTableColumns;
