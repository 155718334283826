import React, { FC } from 'react';
import { Divider, Typography } from 'antd';
import RechnungsAusstellerBelegnummernkreisBtnAndDrawer from './RechnungsAusstellerBelegnummernkreisBtnAndDrawer';
import BelegnummernkreisDefinitionTable from './Table/BelegnummernkreisDefinition/BelegnummernkreisDefinitionTable';
import BelegnummernkreisTable from './Table/Belegnummernkreis/BelegnummernkreisTable';
import { useBelegnummernkreisDefinitionListQuery } from '../../../../BelegnummernkreisDefinition/gql/BelegnummernkreisDefinitionQueries.types';
import { RechnungsAusstellerFieldsFragment } from '../gql/RechnungsAusstellerFragments.types';

type RechnungsAusstellerBelegnummernkreisListingProps = {
  rechnungsAussteller: RechnungsAusstellerFieldsFragment;
  rechtstraegerId: string;
};

const RechnungsAusstellerBelegnummernkreisListing: FC<RechnungsAusstellerBelegnummernkreisListingProps> = ({
  rechtstraegerId,
  rechnungsAussteller,
}) => {
  const { data, loading, refetch } = useBelegnummernkreisDefinitionListQuery({
    variables: {
      rechtstraegerId,
    },
  });

  const belegnummernkreisDefinitionList = data?.getBelegnummernkreisDefinitionList.data ?? [];

  const belegnummernkreisList = belegnummernkreisDefinitionList
    .map((belegnummernkreisDefinition) => belegnummernkreisDefinition.belegnummernkreisList)
    .flat();

  return (
    <>
      <RechnungsAusstellerBelegnummernkreisBtnAndDrawer
        onSuccess={refetch}
        rechtstraegerId={rechtstraegerId}
        belegnummernkreisDefinitionList={belegnummernkreisDefinitionList}
      />
      <BelegnummernkreisDefinitionTable
        rechnungsAussteller={rechnungsAussteller}
        belegnummernkreisDefinitionList={belegnummernkreisDefinitionList}
        loading={loading}
        onAction={refetch}
        rechtstraegerId={rechtstraegerId}
      />
      <Divider />
      <Typography.Title level={4}>Belegnummernkreise</Typography.Title>
      <BelegnummernkreisTable belegnummernkreisList={belegnummernkreisList} />
    </>
  );
};

export default RechnungsAusstellerBelegnummernkreisListing;
