import { get } from 'lodash';
import { FormikProps } from 'formik';
import { NatuerlichePersonFormValues } from '../../../features/Rechtstraeger/NatuerlichePerson/Form/natuerlichePersonFormMapper';

export const getFirstnameFields = (formikProps: FormikProps<NatuerlichePersonFormValues>) => ({
  firstname: get(formikProps, 'values.firstname'),
});

export const getLastnameFields = (formikProps: FormikProps<NatuerlichePersonFormValues>) => ({
  lastname: get(formikProps, 'values.lastname'),
});

export const getTitelFields = (formikProps: FormikProps<NatuerlichePersonFormValues>) => ({
  title: get(formikProps, 'values.title'),
});
