import { generatePath } from 'react-router';
import { mapToQueryString, QueryParams } from '../../../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../../../hooks/usePaginationQueryParams';
import { FiltersFormValues } from './listingFiltersFormMapper';
import { URI_WIZARD_PAGE } from '../../wizardApiPaths';

export enum QueryParamKey {
  CREATE_TS_FROM_INCLUSIVE = 'createTsFromInclusive',
  CREATE_TS_TO_INCLUSIVE = 'createTsToInclusive',
  ERSTELLER_MITARBEITER_ID_LIST = 'erstellerMitarbeiterIdList',
  SHOW_COMPLETED = 'showCompleted',
  STATUS_LIST = 'statusList',
  TYPE = 'type',
}

export type TWizardQueryParams = QueryParams<FiltersFormValues>;

export const updateQueryParams = (navigate: (path: string) => void, filters: TWizardQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  navigate(generatePathToWizardListingPage(filters, paginationParams));

export const generatePathToWizardListingPage = (filters: TWizardQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  `${generatePath(URI_WIZARD_PAGE)}?${mapToQueryString({
    [QueryParamKey.CREATE_TS_FROM_INCLUSIVE]: filters?.createTsFromInclusive,
    [QueryParamKey.CREATE_TS_TO_INCLUSIVE]: filters?.createTsToInclusive,
    [QueryParamKey.ERSTELLER_MITARBEITER_ID_LIST]: filters?.erstellerMitarbeiterIdList,
    [QueryParamKey.SHOW_COMPLETED]: filters?.showCompleted,
    [QueryParamKey.STATUS_LIST]: filters?.statusList,
    [QueryParamKey.TYPE]: filters?.type,
    ...paginationParams,
  })}`;
