import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { Abrechnungskreis } from '../../../../objektAbrechnung-types';
import OAAbrKreisBuchungenEinnahme from '../shared/OAAbrKreisBuchungenEinnahme';
import OAAbrKreisSummeEinnahmen from '../shared/OAAbrKreisSummeEinnahmen';
import OAAbrKreisRepFondsEinnahmenListText from './OAAbrKreisRepFondsEinnahmenListText';

const OAAbrKreisRepFondsEinnahmen: FC<{ abrechKreis: Abrechnungskreis }> = ({ abrechKreis }) => (
  <View>
    <OAAbrKreisRepFondsEinnahmenListText abrechKreis={abrechKreis} />

    {/* Buchungen Einnahmen */}
    {abrechKreis.buchungEinnahmenList.map((buchungEinname, index) => (
      <OAAbrKreisBuchungenEinnahme buchungEinname={buchungEinname} key={index} isRepFonds />
    ))}

    {/* Summe Einnahmen */}
    <OAAbrKreisSummeEinnahmen abrechKreis={abrechKreis} isRepFonds />
  </View>
);

export default OAAbrKreisRepFondsEinnahmen;
