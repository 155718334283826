import { mapToQueryString, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { GenerierlaufEntryExitCode } from '../../../../../types';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../../verarbeitungApiPaths';

enum TopAbrVerarbeitungQueryParamKey {
  RECHNUNGSEMPFAENGER_ID = 'rechnungsempfaengerId',
  EXIT_CODE_LIST = 'exitCodeList',
  OBJEKT_ID = 'objektId',
  BESTANDSEINHEIT_ID_LIST = 'bestandseinheitIdList',
  RECHNUNGSAUSSTELLER_ID = 'rechnungsausstellerId',
}

export type TTopAbrVerarbeitungQueryParams = QueryParams<{
  rechnungsempfaengerId: string;
  objektId: string;
  exitCodeList: GenerierlaufEntryExitCode[];
  bestandseinheitIdList: string[];
  rechnungsausstellerId: string;
}>;

export const updateQueryParams = (
  verarbeitungType: string,
  generierlaufId: string,
  navigate: (path: string) => void,
  filters: TTopAbrVerarbeitungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) =>
  navigate(
    generatePathToTopAbrVerarbeitungPage(
      verarbeitungType,
      generierlaufId,
      filters.rechnungsempfaengerId,
      filters.objektId,
      filters.exitCodeList,
      filters.bestandseinheitIdList,
      filters.rechnungsausstellerId,
      paginationParams
    )
  );

const generatePathToTopAbrVerarbeitungPage = (
  verarbeitungType: string,
  generierlaufId: string,
  rechnungsempfaengerId?: string,
  objektId?: string,
  exitCodeList?: GenerierlaufEntryExitCode[],
  bestandseinheitIdList?: string[],
  rechnungsausstellerId?: string,
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [TopAbrVerarbeitungQueryParamKey.RECHNUNGSEMPFAENGER_ID]: rechnungsempfaengerId,
    [TopAbrVerarbeitungQueryParamKey.OBJEKT_ID]: objektId,
    [TopAbrVerarbeitungQueryParamKey.EXIT_CODE_LIST]: exitCodeList,
    [TopAbrVerarbeitungQueryParamKey.BESTANDSEINHEIT_ID_LIST]: bestandseinheitIdList,
    [TopAbrVerarbeitungQueryParamKey.RECHNUNGSAUSSTELLER_ID]: rechnungsausstellerId,
    ...paginationParams,
  })}`;
