import React, { FC } from 'react';
import { Divider } from 'antd';
import { Box } from 'rebass';
import { FormikProps } from 'formik';
import { Form } from 'formik-antd';
import {
  vstKuerzungKontenZuweisungDescriptionsForKommentar,
  vstKuerzungKontenZuordnungVersionFormFields,
  VorsteuerkuerzungKontenZuweisungVersionFormValues,
} from './vstKuerzungKontenZuweisungVersionFormMapper';
import { Spacer } from '../../../../../components/Grid';
import TimelineFormButtons from '../../../../../components/Timeline/Form/TimelineFormButtons';
import { getPreviousTimeblockBefore, ITimeblock, useTimeline } from '../../../../../components/Timeline/timelineCtx';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import VstKuerzungKontenZuweisungListingForm from './VstKuerzungKontenZuweisungListingForm';
import TimelineFormKommentar from '../../../../../components/Timeline/Form/TimelineFormKommentar';
import { buildKommentarText, isSameAsPreviousVersion } from './vstKuerzungKontenZuweisungVersionFormHelpers';
import TimelineFormDatePicker from '../../../../../components/Timeline/Form/TimelineFormDatePicker';
import { Verwaltungsart, VorsteuerkuerzungEinstellung } from '../../../../../types';
import { useGetAppFieldHelpText } from '../../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  isLoading: boolean;
  formikProps: FormikProps<VorsteuerkuerzungKontenZuweisungVersionFormValues>;
  timeblock: ITimeblock<VorsteuerkuerzungEinstellung>;
  verwaltungsart: Verwaltungsart;
};

const VstKuerzungKontenZuweisungVersionFormContent: FC<Props> = ({ formikProps, timeblock, isLoading, verwaltungsart }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'VorsteuerkuerzungEinstellung'>('VorsteuerkuerzungEinstellung');
  const { dataSource } = useTimeline<VorsteuerkuerzungEinstellung>();

  return (
    <Form labelAlign="left">
      <Box width={2 / 3}>
        <FormItemWithFieldHelp
          name={vstKuerzungKontenZuordnungVersionFormFields.validFrom}
          label="Gültig ab"
          labelCol={{ span: 12 }}
          fieldHelp={getFieldHelpText('VorsteuerkuerzungEinstellung.validFrom')}
        >
          <TimelineFormDatePicker
            picker="month"
            name={vstKuerzungKontenZuordnungVersionFormFields.validFrom}
            timeblock={timeblock}
            placeholder="Datum wählen"
          />
        </FormItemWithFieldHelp>
      </Box>

      <Divider style={{ padding: 0, marginTop: 0, marginBottom: '16px' }} />

      <TimelineFormKommentar
        timeblock={timeblock}
        valuesDescriptions={vstKuerzungKontenZuweisungDescriptionsForKommentar}
        buildKommentarText={buildKommentarText}
      />

      <VstKuerzungKontenZuweisungListingForm
        formikProps={formikProps}
        verwaltungsart={verwaltungsart}
        fieldHelp={getFieldHelpText('VorsteuerkuerzungEinstellung.kontenZuweisungList')}
      />

      <Spacer height={16} />
      <TimelineFormButtons<VorsteuerkuerzungEinstellung>
        timeblock={timeblock}
        isSubmitting={formikProps.isSubmitting}
        isSameAsPreviousVersion={isSameAsPreviousVersion(
          formikProps.values,
          getPreviousTimeblockBefore(dataSource, dayjsCustom(formikProps.values.validFrom))
        )}
        submitBtnLoading={isLoading}
      />
    </Form>
  );
};

export default VstKuerzungKontenZuweisungVersionFormContent;
