import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { TicketTemplateFieldsFragmentDoc } from '../../TicketTemplate/gql/TicketTemplateFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TicketTemplateTemplateListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TicketTemplateTemplateListQuery = {
  getTicketTemplateTemplateList: {
    data: Array<{
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      dueDateText?: string | null;
      firmendatenId?: string | null;
      isSubTicketTemplate: boolean;
      name: string;
      parentId?: string | null;
      position: number;
      reminderText?: string | null;
      ticketTemplateId: string;
      updatedByMitarbeiterId?: string | null;
      subTicketTemplateList?: Array<{
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        dueDateText?: string | null;
        firmendatenId?: string | null;
        isSubTicketTemplate: boolean;
        name: string;
        parentId?: string | null;
        position: number;
        reminderText?: string | null;
        ticketTemplateId: string;
        updatedByMitarbeiterId?: string | null;
        dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
        priority: { text: string; value: Types.TicketPriority };
        reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
      priority: { text: string; value: Types.TicketPriority };
      reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type TicketTemplateTemplateQueryVariables = Types.Exact<{
  ticketTemplateId: Types.Scalars['ID']['input'];
}>;

export type TicketTemplateTemplateQuery = {
  getTicketTemplateTemplate: {
    data: {
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      dueDateText?: string | null;
      firmendatenId?: string | null;
      isSubTicketTemplate: boolean;
      name: string;
      parentId?: string | null;
      position: number;
      reminderText?: string | null;
      ticketTemplateId: string;
      updatedByMitarbeiterId?: string | null;
      subTicketTemplateList?: Array<{
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        dueDateText?: string | null;
        firmendatenId?: string | null;
        isSubTicketTemplate: boolean;
        name: string;
        parentId?: string | null;
        position: number;
        reminderText?: string | null;
        ticketTemplateId: string;
        updatedByMitarbeiterId?: string | null;
        dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
        priority: { text: string; value: Types.TicketPriority };
        reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
      priority: { text: string; value: Types.TicketPriority };
      reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type TicketTemplateTemplateChangeHistoryListQueryVariables = Types.Exact<{
  ticketTemplateId: Types.Scalars['ID']['input'];
}>;

export type TicketTemplateTemplateChangeHistoryListQuery = {
  getTicketTemplateTemplateChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const TicketTemplateTemplateListDocument = gql`
  query TicketTemplateTemplateList {
    getTicketTemplateTemplateList {
      data {
        ...TicketTemplateFields
        subTicketTemplateList {
          ...TicketTemplateFields
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${TicketTemplateFieldsFragmentDoc}
`;
export function useTicketTemplateTemplateListQuery(
  baseOptions?: Apollo.QueryHookOptions<TicketTemplateTemplateListQuery, TicketTemplateTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketTemplateTemplateListQuery, TicketTemplateTemplateListQueryVariables>(TicketTemplateTemplateListDocument, options);
}
export function useTicketTemplateTemplateListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TicketTemplateTemplateListQuery, TicketTemplateTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketTemplateTemplateListQuery, TicketTemplateTemplateListQueryVariables>(TicketTemplateTemplateListDocument, options);
}
export function useTicketTemplateTemplateListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TicketTemplateTemplateListQuery, TicketTemplateTemplateListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TicketTemplateTemplateListQuery, TicketTemplateTemplateListQueryVariables>(
    TicketTemplateTemplateListDocument,
    options
  );
}
export type TicketTemplateTemplateListQueryHookResult = ReturnType<typeof useTicketTemplateTemplateListQuery>;
export type TicketTemplateTemplateListLazyQueryHookResult = ReturnType<typeof useTicketTemplateTemplateListLazyQuery>;
export type TicketTemplateTemplateListSuspenseQueryHookResult = ReturnType<typeof useTicketTemplateTemplateListSuspenseQuery>;
export type TicketTemplateTemplateListQueryResult = Apollo.QueryResult<TicketTemplateTemplateListQuery, TicketTemplateTemplateListQueryVariables>;
export const TicketTemplateTemplateDocument = gql`
  query TicketTemplateTemplate($ticketTemplateId: ID!) {
    getTicketTemplateTemplate(ticketTemplateId: $ticketTemplateId) {
      data {
        ...TicketTemplateFields
        subTicketTemplateList {
          ...TicketTemplateFields
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${TicketTemplateFieldsFragmentDoc}
`;
export function useTicketTemplateTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<TicketTemplateTemplateQuery, TicketTemplateTemplateQueryVariables> &
    ({ variables: TicketTemplateTemplateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketTemplateTemplateQuery, TicketTemplateTemplateQueryVariables>(TicketTemplateTemplateDocument, options);
}
export function useTicketTemplateTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TicketTemplateTemplateQuery, TicketTemplateTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketTemplateTemplateQuery, TicketTemplateTemplateQueryVariables>(TicketTemplateTemplateDocument, options);
}
export function useTicketTemplateTemplateSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TicketTemplateTemplateQuery, TicketTemplateTemplateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TicketTemplateTemplateQuery, TicketTemplateTemplateQueryVariables>(TicketTemplateTemplateDocument, options);
}
export type TicketTemplateTemplateQueryHookResult = ReturnType<typeof useTicketTemplateTemplateQuery>;
export type TicketTemplateTemplateLazyQueryHookResult = ReturnType<typeof useTicketTemplateTemplateLazyQuery>;
export type TicketTemplateTemplateSuspenseQueryHookResult = ReturnType<typeof useTicketTemplateTemplateSuspenseQuery>;
export type TicketTemplateTemplateQueryResult = Apollo.QueryResult<TicketTemplateTemplateQuery, TicketTemplateTemplateQueryVariables>;
export const TicketTemplateTemplateChangeHistoryListDocument = gql`
  query TicketTemplateTemplateChangeHistoryList($ticketTemplateId: ID!) {
    getTicketTemplateTemplateChangeHistoryList(ticketTemplateId: $ticketTemplateId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useTicketTemplateTemplateChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<TicketTemplateTemplateChangeHistoryListQuery, TicketTemplateTemplateChangeHistoryListQueryVariables> &
    ({ variables: TicketTemplateTemplateChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketTemplateTemplateChangeHistoryListQuery, TicketTemplateTemplateChangeHistoryListQueryVariables>(
    TicketTemplateTemplateChangeHistoryListDocument,
    options
  );
}
export function useTicketTemplateTemplateChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TicketTemplateTemplateChangeHistoryListQuery, TicketTemplateTemplateChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketTemplateTemplateChangeHistoryListQuery, TicketTemplateTemplateChangeHistoryListQueryVariables>(
    TicketTemplateTemplateChangeHistoryListDocument,
    options
  );
}
export function useTicketTemplateTemplateChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TicketTemplateTemplateChangeHistoryListQuery, TicketTemplateTemplateChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TicketTemplateTemplateChangeHistoryListQuery, TicketTemplateTemplateChangeHistoryListQueryVariables>(
    TicketTemplateTemplateChangeHistoryListDocument,
    options
  );
}
export type TicketTemplateTemplateChangeHistoryListQueryHookResult = ReturnType<typeof useTicketTemplateTemplateChangeHistoryListQuery>;
export type TicketTemplateTemplateChangeHistoryListLazyQueryHookResult = ReturnType<typeof useTicketTemplateTemplateChangeHistoryListLazyQuery>;
export type TicketTemplateTemplateChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useTicketTemplateTemplateChangeHistoryListSuspenseQuery
>;
export type TicketTemplateTemplateChangeHistoryListQueryResult = Apollo.QueryResult<
  TicketTemplateTemplateChangeHistoryListQuery,
  TicketTemplateTemplateChangeHistoryListQueryVariables
>;
