import { FC, ReactNode } from 'react';
import { Space } from 'antd';

type Props = {
  elements: ReactNode[];
};

const GeneralInfoBlockSectionWithThreeColumns: FC<Props> = ({ elements }) => {
  const sections = divideIntoSections(elements);

  return (
    <Space size="large" align="start">
      {sections.map((section, index) => {
        return (
          <Space key={index} direction="vertical" style={{ width: '332px' }}>
            {section}
          </Space>
        );
      })}
    </Space>
  );
};

const divideIntoSections = (elements: ReactNode[]) => {
  const sections = [];
  sections.push(elements.slice(0, 4)); // 1st to 4th elements
  sections.push(elements.slice(4, 8)); // 5th to 8th elements
  sections.push(elements.slice(8, 12)); // 9th to 12th elements
  return sections;
};

export default GeneralInfoBlockSectionWithThreeColumns;
