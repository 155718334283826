import { Empty } from 'antd';
import React, { FC } from 'react';
import NestedTableWithColSelector from '../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import { AbrInfoBuchungszeile } from '../../../../../types';
import { NestedTableColProps } from '../../../../../components/Table/NestedTable/NestedTable';

type Props = {
  buchungszeileList: AbrInfoBuchungszeile[];
  columns: NestedTableColProps<AbrInfoBuchungszeile>[];
  parentRowKeys?: string[];
  visible?: boolean;
  subLevel?: number;
};

const BuchungszeileTable: FC<Props> = ({ buchungszeileList, columns, parentRowKeys, visible, subLevel }) => {
  return (
    <NestedTableWithColSelector<AbrInfoBuchungszeile>
      visible={visible}
      parentRowKeys={parentRowKeys}
      level={3}
      subLevel={subLevel}
      locale={{
        emptyText: <Empty description={<span>Keine Daten vorhanden</span>} />,
      }}
      dataSource={buchungszeileList}
      columns={columns}
    />
  );
};

export default BuchungszeileTable;
