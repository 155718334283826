import { FormFields } from '../../../../../helpers/formikHelper';

export interface PaymentProposalExecutionFormValues {
  paymentProposalId: string;
}

export const paymentProposalInitialValues: PaymentProposalExecutionFormValues = {
  paymentProposalId: '',
};

export const paymentProposalFormFields: FormFields<PaymentProposalExecutionFormValues> = {
  paymentProposalId: 'paymentProposalId',
};
