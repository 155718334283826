import React from 'react';
import { useParams } from 'react-router';
import { Skeleton } from 'antd';
import { AbrechnungsdefinitionVersionUpdatePageRouteParams } from './routes';
import SysSettingsWeAbrechnungsdefinitionVersionUpdatePage from './SysSettingsWeAbrechnungsdefinitionVersionUpdatePage';
import {
  isAbrDefTypeHeAbrechnung,
  isAbrDefTypeSubAbrechnung,
  isAbrDefTypeWeAbrechnung,
} from '../../../../../../features/Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import SysSettingsHeAbrechnungsdefinitionVersionUpdatePage from './SysSettingsHeAbrechnungsdefinitionVersionUpdatePage';
import { useAbrechnungsdefinitionTypeQuery } from '../../../../../../features/Abrechnungsdefinition/gql/AbrDefQueries.types';
import SysSettingsBkAbrechnungsdefinitionVersionUpdatePage from './SysSettingsBkAbrechnungsdefinitionVersionUpdatePage';
import SysSettingsSubAbrechnungsdefinitionVersionUpdatePage from './SysSettingsSubAbrechnungsdefinitionVersionUpdatePage';

const SysSettingsAbrechnungsdefinitionVersionUpdatePage = () => {
  const { abrechnungsdefinitionId, abrechnungsdefinitionVersionId } = useParams() as AbrechnungsdefinitionVersionUpdatePageRouteParams;

  const { loading, data } = useAbrechnungsdefinitionTypeQuery({ variables: { abrechnungsdefinitionId } });
  const abrDefType = data?.getAbrechnungsdefinitionType.data;

  if (!abrDefType || loading) {
    return <Skeleton active />;
  } else if (isAbrDefTypeWeAbrechnung(abrDefType.value)) {
    return (
      <SysSettingsWeAbrechnungsdefinitionVersionUpdatePage
        abrechnungsdefinitionId={abrechnungsdefinitionId}
        abrechnungsdefinitionVersionId={abrechnungsdefinitionVersionId}
      />
    );
  } else if (isAbrDefTypeHeAbrechnung(abrDefType.value)) {
    return (
      <SysSettingsHeAbrechnungsdefinitionVersionUpdatePage
        abrechnungsdefinitionId={abrechnungsdefinitionId}
        abrechnungsdefinitionVersionId={abrechnungsdefinitionVersionId}
      />
    );
  } else if (isAbrDefTypeSubAbrechnung(abrDefType.value)) {
    return (
      <SysSettingsSubAbrechnungsdefinitionVersionUpdatePage
        abrechnungsdefinitionId={abrechnungsdefinitionId}
        abrechnungsdefinitionVersionId={abrechnungsdefinitionVersionId}
      />
    );
  } else {
    return (
      <SysSettingsBkAbrechnungsdefinitionVersionUpdatePage
        abrechnungsdefinitionId={abrechnungsdefinitionId}
        abrechnungsdefinitionVersionId={abrechnungsdefinitionVersionId}
      />
    );
  }
};

export default SysSettingsAbrechnungsdefinitionVersionUpdatePage;
