import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { ListingFiltersFormFields, ListingFiltersFormValues } from './ListingFiltersFormMapper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { OrderGenerierlaufType } from '../../../../../types';
import GenerierlaufObjektFormSelect from '../../../shared/Filters/Order/GenerierlaufObjektFormSelect';
import FiltersWith4OrMoreFields from '../../../../../components/Filters/FiltersWith4OrMoreFields';
import GenerierlaufEntryExitCodeListSelect from '../../../shared/Filters/GenerierlaufEntryExitCodeListSelect';
import GenerierlaufVertragspartnerFormSelect from '../../../shared/Filters/Order/GenerierlaufVertragspartnerFormSelect';
import GenerierlaufRecipientFormSelect from '../../../shared/Filters/Order/GenerierlaufRecipientFormSelect';
import GenerierlaufRecipientEmailAddressFormSelect from '../../../shared/Filters/Order/GenerierlaufRecipientEmailAddressFormSelect';

type Props = {
  formikProps: FormikProps<ListingFiltersFormValues>;
  generierlaufId: string;
};

const ListingFilters: FC<Props> = ({ formikProps, generierlaufId }) => {
  return (
    <FiltersWith4OrMoreFields
      hideTitle
      leftColumn={
        <>
          <FormItemWithoutColon name={ListingFiltersFormFields.vertragspartnerId} label="Vertragspartner">
            <GenerierlaufVertragspartnerFormSelect
              name={ListingFiltersFormFields.vertragspartnerId}
              generierlaufId={generierlaufId}
              generierlaufType={OrderGenerierlaufType.VorschreibungMailDeliverySend}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={ListingFiltersFormFields.objektId} label="Objekt">
            <GenerierlaufObjektFormSelect
              name={ListingFiltersFormFields.objektId}
              generierlaufId={generierlaufId}
              generierlaufType={OrderGenerierlaufType.VorschreibungMailDeliverySend}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={ListingFiltersFormFields.exitCodeList} label="Status">
            <GenerierlaufEntryExitCodeListSelect name={ListingFiltersFormFields.exitCodeList} onChange={formikProps.submitForm} />
          </FormItemWithoutColon>
        </>
      }
      rightColumn={
        <>
          <FormItemWithoutColon name={ListingFiltersFormFields.recipientId} label="Empfänger">
            <GenerierlaufRecipientFormSelect
              name={ListingFiltersFormFields.recipientId}
              generierlaufId={generierlaufId}
              generierlaufType={OrderGenerierlaufType.VorschreibungMailDeliverySend}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={ListingFiltersFormFields.emailAddress} label="An">
            <GenerierlaufRecipientEmailAddressFormSelect
              name={ListingFiltersFormFields.emailAddress}
              generierlaufId={generierlaufId}
              generierlaufType={OrderGenerierlaufType.VorschreibungMailDeliverySend}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
    />
  );
};

export default ListingFilters;
