import React, { FC } from 'react';
import { Typography } from 'antd';
import { FormItemProps } from 'formik-antd';
import FormItemWithFieldHelp from './FormItemWithFieldHelp';
import { StyledSpaceSplit60And40 } from '../Space/styled/Space.style';

type Props = {
  name: string;
  label: string;
  textAfter?: string | React.ReactNode;
  marginBottom?: string;
  labelColSpan?: number | string;
  fieldHelp?: string | null;
} & Omit<FormItemProps, 'htmlFor' | 'colon' | 'labelCol'>;

const ParamFormItem: FC<Props> = ({ name, label, labelColSpan, children, textAfter, marginBottom, ...restProps }) => (
  <FormItemWithFieldHelp
    {...restProps}
    hasFeedback={false}
    label={label}
    name={name}
    style={{ margin: 0, marginBottom: marginBottom ?? '0' }}
    labelAlign="left"
    labelCol={{ span: labelColSpan ?? 8 }}
  >
    {textAfter ? (
      <StyledSpaceSplit60And40 size={8}>
        {children}
        <Typography.Text>{textAfter}</Typography.Text>
      </StyledSpaceSplit60And40>
    ) : (
      children
    )}
  </FormItemWithFieldHelp>
);

export default ParamFormItem;
