import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AuftragFragmentDoc } from '../../../Vorschreibung/gql/AuftragFragments.types';
import { EigenbelegFragmentDoc } from '../../../../pages/BookingDetailsPage/BookingDetailsEigenbelegPage/gql/EigenbelegFragments.types';
import { GenerierlaufAsyncStepFieldsFragmentDoc } from '../shared/generierlaufFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EigenbelegErstellenGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type EigenbelegErstellenGenerierlaufQuery = {
  getEigenbelegErstellenGenerierlauf: {
    data: {
      countFehler: number;
      countVerarbeitet: number;
      countNichtVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierDatum: string;
      generierlaufId: string;
      errorList: Array<{ message: string; type: string }>;
      status: { description?: string | null; value: Types.GenerierlaufStatus; text: string };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type EigenbelegErstellenGenerierlaufEntryListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  belegnummer?: Types.InputMaybe<Types.Scalars['String']['input']>;
  fakturierungsperiode?: Types.InputMaybe<Types.Scalars['String']['input']>;
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ausstellerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  empfaengerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  order?: Types.InputMaybe<Types.EigenbelegErstellenGenerierlaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type EigenbelegErstellenGenerierlaufEntryListQuery = {
  getEigenbelegErstellenGenerierlaufEntryList: {
    data: {
      contentList: Array<{
        currentStep?: number | null;
        generierlaufEntryId: string;
        infoMessage?: string | null;
        objektId?: string | null;
        objektKurzBezeichnung?: string | null;
        retry: boolean;
        stepCount?: number | null;
        auftrag?: {
          auftragId: string;
          buchungIdList?: Array<string> | null;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          dueDate: string;
          generierDatum?: string | null;
          generierlaufId?: string | null;
          hasAuftragskette: boolean;
          stornierbar?: boolean | null;
          storniert: boolean;
          sumBrutto: number;
          sumNetto: number;
          sumUst: number;
          auftragsKopf: {
            consolidationCriteria?: string | null;
            rechnungsAusstellerBezeichnung: string;
            sepaCreditorId?: string | null;
            sepaMandatReference?: string | null;
            auftragsartType: { text: string; value: Types.AuftragsartType };
            aussteller: {
              kurzBezeichnung: string;
              kundennummer: string;
              rechtstraegerId: string;
              type: { text: string; value: Types.RechtstraegerType };
            };
            ausstellerBankDetails: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string };
            belegform: { text: string; value: Types.BelegFormArt };
            deliveryAddress: {
              addressId: string;
              city: string;
              cityAdditionalInformation?: string | null;
              countryCodeIso2: string;
              houseEntranceApartmentNumber?: string | null;
              postofficeBoxNumber?: string | null;
              postofficeBoxZipCode?: string | null;
              street?: string | null;
              type: Types.AddressType;
              zipCode?: string | null;
            };
            dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
            empfaenger: {
              kurzBezeichnung: string;
              kundennummer: string;
              rechtstraegerId: string;
              type: { text: string; value: Types.RechtstraegerType };
            };
            empfaengerBankDetails?: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string } | null;
            fakturierungsperiode: { jahr: number; monat: number };
            objekt: { kurzBezeichnung: string; objektId: string };
            paymentMethod: { text: string; value: Types.PaymentMethod };
            recipientEmailContact: { contactId: string; type: string; wert: string };
            verrechnungsart: { text: string; value: Types.Verrechnungsart };
            vertragsart: { text: string; value: Types.Vertragsart };
            zustellRechtstraeger: {
              kurzBezeichnung: string;
              kundennummer: string;
              rechtstraegerId: string;
              type: { text: string; value: Types.RechtstraegerType };
            };
          };
          auftragsPositionList: Array<{
            auftragId: string;
            auftragsPositionId: string;
            bestandseinheitId: string;
            bezeichnung: string;
            brutto: number;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            generierDatum?: string | null;
            generierlaufId?: string | null;
            netto: number;
            objektId: string;
            originId: string;
            performancePeriodFromInclusive: string;
            performancePeriodToInclusive: string;
            rechnungskennzeichen: string;
            steuersatz: number;
            ust: number;
            ustKategorieEntryId: string;
            ustKategorieId: string;
            ustKategorieVersionId: string;
            vertragBezeichnung: string;
            vertragId: string;
            vorschreibungspositionBezeichnung: string;
            vorschreibungspositionId: string;
            bestandseinheit: {
              aktivSeit?: string | null;
              bauteil?: string | null;
              bestandseinheitId: string;
              bezeichnung: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              geschoss?: string | null;
              inaktivSeit?: string | null;
              nutzflaeche?: number | null;
              nutzwert?: number | null;
              stiege?: string | null;
              tagList: Array<string>;
              address: {
                addressId: string;
                city: string;
                cityAdditionalInformation?: string | null;
                countryCodeIso2: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                haupt?: boolean | null;
                houseEntranceApartmentNumber?: string | null;
                postofficeBoxNumber?: string | null;
                postofficeBoxZipCode?: string | null;
                street?: string | null;
                tagList: Array<string>;
                type: Types.AddressType;
                zipCode?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              nutzungsArt?: { text: string; value: Types.NutzungsArt } | null;
              optionSteuerpflicht?: {
                beOptionSteuerpflichtId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                deletable?: boolean | null;
                kommentar?: string | null;
                lastUpdateTs: string;
                optionSteuerpflicht: boolean;
                validFrom: string;
                historicizedList?: Array<{
                  beOptionSteuerpflichtId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  current: boolean;
                  deletable?: boolean | null;
                  kommentar?: string | null;
                  lastUpdateTs: string;
                  optionSteuerpflicht: boolean;
                  validFrom: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }> | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              objektVerwaltungsart: { text: string; value: Types.Verwaltungsart };
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            fibuStatus?: { text: string; value: Types.AuftragFibuStatus; description?: string | null } | null;
            labelList: Array<{ label: string; value: string }>;
            objekt: {
              countryCodeIso2: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fotoFileId?: string | null;
              fotoType?: Types.FotoType | null;
              kurzBezeichnung: string;
              objektId: string;
              rechnungsAusstellerBezeichnung?: string | null;
              rechnungsAusstellerChangeable?: boolean | null;
              subAdministration: boolean;
              sumNutzflaeche: number;
              sumNutzwert: number;
              tagList: Array<string>;
              updatedByMitarbeiterId?: string | null;
              verwaltungBis?: string | null;
              verwaltungSeit: string;
              verwaltungsartChangeable?: boolean | null;
              addressList: Array<{
                addressId: string;
                city: string;
                cityAdditionalInformation?: string | null;
                countryCodeIso2: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                haupt?: boolean | null;
                houseEntranceApartmentNumber?: string | null;
                postofficeBoxNumber?: string | null;
                postofficeBoxZipCode?: string | null;
                street?: string | null;
                tagList: Array<string>;
                type: Types.AddressType;
                zipCode?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              objektSummeAnteile?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                kommentar?: string | null;
                lastUpdateTs: string;
                objektSummeAnteileId: string;
                summeAnteile: number;
                validFrom: string;
                historicizedList?: Array<{
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  current: boolean;
                  kommentar?: string | null;
                  lastUpdateTs: string;
                  objektSummeAnteileId: string;
                  summeAnteile: number;
                  validFrom: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }> | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rechnungsAussteller: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fibuKontoCreatable: boolean;
                kundennummer: string;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                tagList: Array<string>;
                updatedByMitarbeiterId?: string | null;
                bankDetailsList: Array<{
                  accountHolder: string;
                  accountLimit?: number | null;
                  assignedHeVertragZahlungsregelMap: any;
                  assignedObjektMap: any;
                  bankDetailsId: string;
                  bankname: string;
                  bic: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  deletable?: boolean | null;
                  ebicsCreditTransferUserList: Array<string>;
                  ebicsDirectDebitUserList: Array<string>;
                  fibuKontoId?: string | null;
                  fibuKontonummer?: string | null;
                  iban: string;
                  updatedByMitarbeiterId?: string | null;
                  fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
                  fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
                  sepaMandat?: {
                    fileId?: string | null;
                    fileName?: string | null;
                    sepaMandatId: string;
                    sepaMandatReference: string;
                    signatureDate?: string | null;
                    firmendaten?: {
                      createTs: string;
                      createdBy: string;
                      createdByMitarbeiterId?: string | null;
                      firmendatenId: string;
                      kundenSystemId: string;
                      kurzBezeichnung: string;
                      name1: string;
                      name2?: string | null;
                      name3?: string | null;
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    } | null;
                    rechnungsAussteller?: {
                      createTs: string;
                      createdBy: string;
                      createdByMitarbeiterId?: string | null;
                      kurzBezeichnung: string;
                      rechtstraegerId: string;
                      status: { text: string; value: Types.RechtstraegerStatus };
                      type: { text: string; value: Types.RechtstraegerType };
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    } | null;
                    status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
                    warningList: Array<{ message: string; type: string }>;
                  } | null;
                  status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                personenbezugList: Array<{
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  personenbezugId: string;
                  tagList: Array<string>;
                  person: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                rechtstraegerSteuerpflicht?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  current: boolean;
                  deletable?: boolean | null;
                  kommentar?: string | null;
                  lastUpdateTs: string;
                  rechtstraegerSteuerpflichtId: string;
                  validFrom: string;
                  historicizedList?: Array<{
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    current: boolean;
                    deletable?: boolean | null;
                    kommentar?: string | null;
                    lastUpdateTs: string;
                    rechtstraegerSteuerpflichtId: string;
                    validFrom: string;
                    steuerpflicht: { text: string; value: Types.Steuerpflicht };
                    warningList: Array<{ message: string; type: string }>;
                  }> | null;
                  steuerpflicht: { text: string; value: Types.Steuerpflicht };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
                sprache: { text: string; value: Types.Sprache };
                status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
                type: { value: Types.RechtstraegerType; text: string };
                vertragSummary?: {
                  heVertragCount: number;
                  heVertragVerwaltungCount: number;
                  mietVertragCount: number;
                  mietVertragVerwaltungCount: number;
                  weSubverwaltungCount: number;
                  weVertragCount: number;
                  weVertragVerwaltungCount: number;
                } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              sachbearbeiter?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                fileStorageInitialized: boolean;
                firmendatenId: string;
                firstname: string;
                funktionsModulList: Array<string>;
                lastname: string;
                mitarbeiterId: string;
                title?: string | null;
                titleTrailing?: string | null;
                username: string;
                contactPerson?: {
                  contactPersonId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  firstname: string;
                  lastname: string;
                  tagList: Array<string>;
                  title?: string | null;
                  titleTrailing?: string | null;
                  contactList: Array<{
                    contactId: string;
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    haupt?: boolean | null;
                    tagList: Array<string>;
                    type: string;
                    wert: string;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  rechtstraeger?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                  salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                rolleList: Array<{
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  name: string;
                  rolleId: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
                status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
                user?: {
                  email?: string | null;
                  emailUnverified?: string | null;
                  firstname?: string | null;
                  lastname?: string | null;
                  role: string;
                  username: string;
                  status: { text: string; value: string };
                } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.ObjektStatus; description?: string | null };
              verwaltungsart: { text: string; value: Types.Verwaltungsart };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
          }>;
          auftragsart: {
            auftragsartId: string;
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            showUstBlock: boolean;
            updatedByMitarbeiterId?: string | null;
            status: { text: string; value: Types.AuftragsartStatus };
            type: { text: string; value: Types.AuftragsartType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          billingAddress: {
            addressId: string;
            city: string;
            cityAdditionalInformation?: string | null;
            countryCodeIso2: string;
            houseEntranceApartmentNumber?: string | null;
            postofficeBoxNumber?: string | null;
            postofficeBoxZipCode?: string | null;
            street?: string | null;
            type: Types.AddressType;
            zipCode?: string | null;
          };
          buchungErrorList: Array<{ message: string; type: string }>;
          copyEmailRecipientList?: Array<{
            contact: { contactId: string; type: string; wert: string };
            rechtstraeger: {
              kurzBezeichnung: string;
              kundennummer: string;
              rechtstraegerId: string;
              type: { text: string; value: Types.RechtstraegerType };
            };
          }> | null;
          copyMailDeliveryList: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            emailAddress: string;
            emailDeliveryDefinitionBezeichnung: string;
            emailDeliveryDefinitionId: string;
            mailId?: string | null;
            original: boolean;
            sender?: string | null;
            sentTs?: string | null;
            shouldNeverSend: boolean;
            shouldSend: boolean;
            updatedByMitarbeiterId?: string | null;
            vorschreibungMailDeliveryId: string;
            resentDeliveryList?: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              emailAddress: string;
              emailDeliveryDefinitionBezeichnung: string;
              emailDeliveryDefinitionId: string;
              mailId?: string | null;
              original: boolean;
              sender?: string | null;
              sentTs?: string | null;
              shouldNeverSend: boolean;
              shouldSend: boolean;
              updatedByMitarbeiterId?: string | null;
              vorschreibungMailDeliveryId: string;
              emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
              fakturierungsperiode: { jahr: number; monat: number };
              objektBestandseinheitVertragList: Array<{
                vertragId: string;
                bestandseinheit: {
                  bestandseinheitId: string;
                  bezeichnung: string;
                  address: {
                    addressId: string;
                    city: string;
                    cityAdditionalInformation?: string | null;
                    countryCodeIso2: string;
                    houseEntranceApartmentNumber?: string | null;
                    postofficeBoxNumber?: string | null;
                    postofficeBoxZipCode?: string | null;
                    street?: string | null;
                    type: Types.AddressType;
                    zipCode?: string | null;
                  };
                };
                objekt: { kurzBezeichnung: string; objektId: string };
              }>;
              recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
              vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }> | null;
            sourceDelivery?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              emailAddress: string;
              emailDeliveryDefinitionBezeichnung: string;
              emailDeliveryDefinitionId: string;
              mailId?: string | null;
              original: boolean;
              sender?: string | null;
              sentTs?: string | null;
              shouldNeverSend: boolean;
              shouldSend: boolean;
              updatedByMitarbeiterId?: string | null;
              vorschreibungMailDeliveryId: string;
              emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
              fakturierungsperiode: { jahr: number; monat: number };
              objektBestandseinheitVertragList: Array<{
                vertragId: string;
                bestandseinheit: {
                  bestandseinheitId: string;
                  bezeichnung: string;
                  address: {
                    addressId: string;
                    city: string;
                    cityAdditionalInformation?: string | null;
                    countryCodeIso2: string;
                    houseEntranceApartmentNumber?: string | null;
                    postofficeBoxNumber?: string | null;
                    postofficeBoxZipCode?: string | null;
                    street?: string | null;
                    type: Types.AddressType;
                    zipCode?: string | null;
                  };
                };
                objekt: { kurzBezeichnung: string; objektId: string };
              }>;
              recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
              vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
            fakturierungsperiode: { jahr: number; monat: number };
            objektBestandseinheitVertragList: Array<{
              vertragId: string;
              bestandseinheit: {
                bestandseinheitId: string;
                bezeichnung: string;
                address: {
                  addressId: string;
                  city: string;
                  cityAdditionalInformation?: string | null;
                  countryCodeIso2: string;
                  houseEntranceApartmentNumber?: string | null;
                  postofficeBoxNumber?: string | null;
                  postofficeBoxZipCode?: string | null;
                  street?: string | null;
                  type: Types.AddressType;
                  zipCode?: string | null;
                };
              };
              objekt: { kurzBezeichnung: string; objektId: string };
            }>;
            recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
            vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          eigenbeleg?: {
            auftragIdList: Array<string>;
            ausstellerRechtstraegerId: string;
            belegId: string;
            belegdatum: string;
            belegnummer: string;
            bezeichnung: string;
            buchungIdList: Array<string>;
            buchungsanweisungIdList: Array<string>;
            buchungskreisId?: string | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            empfaengerRechtstraegerId: string;
            mailId?: string | null;
            paid: boolean;
            sumBrutto?: number | null;
            sumNetto?: number | null;
            sumUst?: number | null;
            auftragStatusDetailsList: Array<{
              auftragId: string;
              offenerBetrag?: number | null;
              paymentCreatable: boolean;
              paymentIdList: Array<string>;
              buchungsanweisungStatus?: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus } | null;
              eigenbelegStatus: { description?: string | null; text: string; value: Types.EigenbelegStatus };
              fakturierungsperiode: { jahr: number; monat: number };
            }>;
            ausstellerBankDetails: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string };
            ausstellerRechtstraeger: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fibuKontoCreatable: boolean;
              kundennummer: string;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              tagList: Array<string>;
              updatedByMitarbeiterId?: string | null;
              bankDetailsList: Array<{
                accountHolder: string;
                accountLimit?: number | null;
                assignedHeVertragZahlungsregelMap: any;
                assignedObjektMap: any;
                bankDetailsId: string;
                bankname: string;
                bic: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                ebicsCreditTransferUserList: Array<string>;
                ebicsDirectDebitUserList: Array<string>;
                fibuKontoId?: string | null;
                fibuKontonummer?: string | null;
                iban: string;
                updatedByMitarbeiterId?: string | null;
                fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
                fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
                sepaMandat?: {
                  fileId?: string | null;
                  fileName?: string | null;
                  sepaMandatId: string;
                  sepaMandatReference: string;
                  signatureDate?: string | null;
                  firmendaten?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    firmendatenId: string;
                    kundenSystemId: string;
                    kurzBezeichnung: string;
                    name1: string;
                    name2?: string | null;
                    name3?: string | null;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                  rechnungsAussteller?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                  status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
                  warningList: Array<{ message: string; type: string }>;
                } | null;
                status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              personenbezugList: Array<{
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                personenbezugId: string;
                tagList: Array<string>;
                person: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rechtstraegerSteuerpflicht?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                deletable?: boolean | null;
                kommentar?: string | null;
                lastUpdateTs: string;
                rechtstraegerSteuerpflichtId: string;
                validFrom: string;
                historicizedList?: Array<{
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  current: boolean;
                  deletable?: boolean | null;
                  kommentar?: string | null;
                  lastUpdateTs: string;
                  rechtstraegerSteuerpflichtId: string;
                  validFrom: string;
                  steuerpflicht: { text: string; value: Types.Steuerpflicht };
                  warningList: Array<{ message: string; type: string }>;
                }> | null;
                steuerpflicht: { text: string; value: Types.Steuerpflicht };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
              sprache: { text: string; value: Types.Sprache };
              status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
              type: { value: Types.RechtstraegerType; text: string };
              vertragSummary?: {
                heVertragCount: number;
                heVertragVerwaltungCount: number;
                mietVertragCount: number;
                mietVertragVerwaltungCount: number;
                weSubverwaltungCount: number;
                weVertragCount: number;
                weVertragVerwaltungCount: number;
              } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            belegform: { text: string; value: Types.BelegFormArt };
            buchungErrorList: Array<{ message: string; type: string }>;
            buchungsanweisungListEntryDataList: Array<{
              buchungsanweisungId: string;
              status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
              fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
            }>;
            buchungsanweisungStatus?: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus } | null;
            deviationDetails?: {
              umbuchungInfoList: Array<{
                fakturierungsperiode: { jahr: number; monat: number };
                kontoKeyIst: { nummer: string; klasse: { text: string; value: Types.KontoKlasse } };
                kontoKeySoll: { nummer: string; klasse: { text: string; value: Types.KontoKlasse } };
              }>;
            } | null;
            dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
            eigenbelegPositionList: Array<{
              bestandseinheitId: string;
              bezeichnung: string;
              brutto: number;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              eigenbelegsPositionId: string;
              generierDatum?: string | null;
              generierlaufId?: string | null;
              netto: number;
              objektId: string;
              performancePeriodFromInclusive: string;
              performancePeriodToInclusive: string;
              steuersatz: number;
              ust: number;
              ustKategorieEntryId: string;
              ustKategorieId: string;
              vertragId: string;
              vorschreibungspositionBezeichnung: string;
              vorschreibungspositionId: string;
              bestandseinheit?: {
                aktivSeit?: string | null;
                bauteil?: string | null;
                bestandseinheitId: string;
                bezeichnung: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                geschoss?: string | null;
                inaktivSeit?: string | null;
                nutzflaeche?: number | null;
                nutzwert?: number | null;
                stiege?: string | null;
                tagList: Array<string>;
                address: {
                  addressId: string;
                  city: string;
                  cityAdditionalInformation?: string | null;
                  countryCodeIso2: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  haupt?: boolean | null;
                  houseEntranceApartmentNumber?: string | null;
                  postofficeBoxNumber?: string | null;
                  postofficeBoxZipCode?: string | null;
                  street?: string | null;
                  tagList: Array<string>;
                  type: Types.AddressType;
                  zipCode?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                nutzungsArt?: { text: string; value: Types.NutzungsArt } | null;
                optionSteuerpflicht?: {
                  beOptionSteuerpflichtId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  current: boolean;
                  deletable?: boolean | null;
                  kommentar?: string | null;
                  lastUpdateTs: string;
                  optionSteuerpflicht: boolean;
                  validFrom: string;
                  historicizedList?: Array<{
                    beOptionSteuerpflichtId: string;
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    current: boolean;
                    deletable?: boolean | null;
                    kommentar?: string | null;
                    lastUpdateTs: string;
                    optionSteuerpflicht: boolean;
                    validFrom: string;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }> | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                objektVerwaltungsart: { text: string; value: Types.Verwaltungsart };
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              labelList: Array<{ label: string; value: string }>;
              objekt?: {
                countryCodeIso2: string;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fotoFileId?: string | null;
                fotoType?: Types.FotoType | null;
                kurzBezeichnung: string;
                objektId: string;
                rechnungsAusstellerBezeichnung?: string | null;
                rechnungsAusstellerChangeable?: boolean | null;
                subAdministration: boolean;
                sumNutzflaeche: number;
                sumNutzwert: number;
                tagList: Array<string>;
                updatedByMitarbeiterId?: string | null;
                verwaltungBis?: string | null;
                verwaltungSeit: string;
                verwaltungsartChangeable?: boolean | null;
                addressList: Array<{
                  addressId: string;
                  city: string;
                  cityAdditionalInformation?: string | null;
                  countryCodeIso2: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  haupt?: boolean | null;
                  houseEntranceApartmentNumber?: string | null;
                  postofficeBoxNumber?: string | null;
                  postofficeBoxZipCode?: string | null;
                  street?: string | null;
                  tagList: Array<string>;
                  type: Types.AddressType;
                  zipCode?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                objektSummeAnteile?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  current: boolean;
                  kommentar?: string | null;
                  lastUpdateTs: string;
                  objektSummeAnteileId: string;
                  summeAnteile: number;
                  validFrom: string;
                  historicizedList?: Array<{
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    current: boolean;
                    kommentar?: string | null;
                    lastUpdateTs: string;
                    objektSummeAnteileId: string;
                    summeAnteile: number;
                    validFrom: string;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }> | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                rechnungsAussteller: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  deletable?: boolean | null;
                  fibuKontoCreatable: boolean;
                  kundennummer: string;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  tagList: Array<string>;
                  updatedByMitarbeiterId?: string | null;
                  bankDetailsList: Array<{
                    accountHolder: string;
                    accountLimit?: number | null;
                    assignedHeVertragZahlungsregelMap: any;
                    assignedObjektMap: any;
                    bankDetailsId: string;
                    bankname: string;
                    bic: string;
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    deletable?: boolean | null;
                    ebicsCreditTransferUserList: Array<string>;
                    ebicsDirectDebitUserList: Array<string>;
                    fibuKontoId?: string | null;
                    fibuKontonummer?: string | null;
                    iban: string;
                    updatedByMitarbeiterId?: string | null;
                    fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
                    fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
                    sepaMandat?: {
                      fileId?: string | null;
                      fileName?: string | null;
                      sepaMandatId: string;
                      sepaMandatReference: string;
                      signatureDate?: string | null;
                      firmendaten?: {
                        createTs: string;
                        createdBy: string;
                        createdByMitarbeiterId?: string | null;
                        firmendatenId: string;
                        kundenSystemId: string;
                        kurzBezeichnung: string;
                        name1: string;
                        name2?: string | null;
                        name3?: string | null;
                        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                      } | null;
                      rechnungsAussteller?: {
                        createTs: string;
                        createdBy: string;
                        createdByMitarbeiterId?: string | null;
                        kurzBezeichnung: string;
                        rechtstraegerId: string;
                        status: { text: string; value: Types.RechtstraegerStatus };
                        type: { text: string; value: Types.RechtstraegerType };
                        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                      } | null;
                      status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
                      warningList: Array<{ message: string; type: string }>;
                    } | null;
                    status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  personenbezugList: Array<{
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    personenbezugId: string;
                    tagList: Array<string>;
                    person: {
                      createTs: string;
                      createdBy: string;
                      createdByMitarbeiterId?: string | null;
                      kurzBezeichnung: string;
                      rechtstraegerId: string;
                      status: { text: string; value: Types.RechtstraegerStatus };
                      type: { text: string; value: Types.RechtstraegerType };
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  postIt?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    immerAnzeigen: boolean;
                    postItId: string;
                    text: string;
                    titel: string;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                  rechtstraegerSteuerpflicht?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    current: boolean;
                    deletable?: boolean | null;
                    kommentar?: string | null;
                    lastUpdateTs: string;
                    rechtstraegerSteuerpflichtId: string;
                    validFrom: string;
                    historicizedList?: Array<{
                      createTs: string;
                      createdBy: string;
                      createdByMitarbeiterId?: string | null;
                      current: boolean;
                      deletable?: boolean | null;
                      kommentar?: string | null;
                      lastUpdateTs: string;
                      rechtstraegerSteuerpflichtId: string;
                      validFrom: string;
                      steuerpflicht: { text: string; value: Types.Steuerpflicht };
                      warningList: Array<{ message: string; type: string }>;
                    }> | null;
                    steuerpflicht: { text: string; value: Types.Steuerpflicht };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                  salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
                  sprache: { text: string; value: Types.Sprache };
                  status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
                  type: { value: Types.RechtstraegerType; text: string };
                  vertragSummary?: {
                    heVertragCount: number;
                    heVertragVerwaltungCount: number;
                    mietVertragCount: number;
                    mietVertragVerwaltungCount: number;
                    weSubverwaltungCount: number;
                    weVertragCount: number;
                    weVertragVerwaltungCount: number;
                  } | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                sachbearbeiter?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  fileStorageInitialized: boolean;
                  firmendatenId: string;
                  firstname: string;
                  funktionsModulList: Array<string>;
                  lastname: string;
                  mitarbeiterId: string;
                  title?: string | null;
                  titleTrailing?: string | null;
                  username: string;
                  contactPerson?: {
                    contactPersonId: string;
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    firstname: string;
                    lastname: string;
                    tagList: Array<string>;
                    title?: string | null;
                    titleTrailing?: string | null;
                    contactList: Array<{
                      contactId: string;
                      createTs: string;
                      createdBy: string;
                      createdByMitarbeiterId?: string | null;
                      haupt?: boolean | null;
                      tagList: Array<string>;
                      type: string;
                      wert: string;
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    }>;
                    rechtstraeger?: {
                      createTs: string;
                      createdBy: string;
                      createdByMitarbeiterId?: string | null;
                      kurzBezeichnung: string;
                      rechtstraegerId: string;
                      status: { text: string; value: Types.RechtstraegerStatus };
                      type: { text: string; value: Types.RechtstraegerType };
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    } | null;
                    salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                  rolleList: Array<{
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    name: string;
                    rolleId: string;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
                  status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
                  user?: {
                    email?: string | null;
                    emailUnverified?: string | null;
                    firstname?: string | null;
                    lastname?: string | null;
                    role: string;
                    username: string;
                    status: { text: string; value: string };
                  } | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { text: string; value: Types.ObjektStatus; description?: string | null };
                verwaltungsart: { text: string; value: Types.Verwaltungsart };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
            }>;
            empfaengerBankDetails?: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string } | null;
            empfaengerRechtstraeger: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fibuKontoCreatable: boolean;
              kundennummer: string;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              tagList: Array<string>;
              updatedByMitarbeiterId?: string | null;
              bankDetailsList: Array<{
                accountHolder: string;
                accountLimit?: number | null;
                assignedHeVertragZahlungsregelMap: any;
                assignedObjektMap: any;
                bankDetailsId: string;
                bankname: string;
                bic: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                ebicsCreditTransferUserList: Array<string>;
                ebicsDirectDebitUserList: Array<string>;
                fibuKontoId?: string | null;
                fibuKontonummer?: string | null;
                iban: string;
                updatedByMitarbeiterId?: string | null;
                fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
                fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
                sepaMandat?: {
                  fileId?: string | null;
                  fileName?: string | null;
                  sepaMandatId: string;
                  sepaMandatReference: string;
                  signatureDate?: string | null;
                  firmendaten?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    firmendatenId: string;
                    kundenSystemId: string;
                    kurzBezeichnung: string;
                    name1: string;
                    name2?: string | null;
                    name3?: string | null;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                  rechnungsAussteller?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                  status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
                  warningList: Array<{ message: string; type: string }>;
                } | null;
                status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              personenbezugList: Array<{
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                personenbezugId: string;
                tagList: Array<string>;
                person: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rechtstraegerSteuerpflicht?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                deletable?: boolean | null;
                kommentar?: string | null;
                lastUpdateTs: string;
                rechtstraegerSteuerpflichtId: string;
                validFrom: string;
                historicizedList?: Array<{
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  current: boolean;
                  deletable?: boolean | null;
                  kommentar?: string | null;
                  lastUpdateTs: string;
                  rechtstraegerSteuerpflichtId: string;
                  validFrom: string;
                  steuerpflicht: { text: string; value: Types.Steuerpflicht };
                  warningList: Array<{ message: string; type: string }>;
                }> | null;
                steuerpflicht: { text: string; value: Types.Steuerpflicht };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
              sprache: { text: string; value: Types.Sprache };
              status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
              type: { value: Types.RechtstraegerType; text: string };
              vertragSummary?: {
                heVertragCount: number;
                heVertragVerwaltungCount: number;
                mietVertragCount: number;
                mietVertragVerwaltungCount: number;
                weSubverwaltungCount: number;
                weVertragCount: number;
                weVertragVerwaltungCount: number;
              } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            fakturierungsperiode: { jahr: number; monat: number };
            fileInfo: { fileId: string; name: string; size: number };
            paymentMethod: { text: string; value: Types.PaymentMethod };
            status: { description?: string | null; text: string; value: Types.EigenbelegStatus };
            type: { text: string; value: Types.EigenbelegType };
            verrechnungsart: { text: string; value: Types.Verrechnungsart };
          } | null;
          empfaengerBankDetailsList: Array<{
            accountHolder: string;
            accountLimit?: number | null;
            assignedHeVertragZahlungsregelMap: any;
            assignedObjektMap: any;
            bankDetailsId: string;
            bankname: string;
            bic: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            ebicsCreditTransferUserList: Array<string>;
            ebicsDirectDebitUserList: Array<string>;
            fibuKontoId?: string | null;
            fibuKontonummer?: string | null;
            iban: string;
            updatedByMitarbeiterId?: string | null;
            fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
            fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
            sepaMandat?: {
              fileId?: string | null;
              fileName?: string | null;
              sepaMandatId: string;
              sepaMandatReference: string;
              signatureDate?: string | null;
              firmendaten?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                firmendatenId: string;
                kundenSystemId: string;
                kurzBezeichnung: string;
                name1: string;
                name2?: string | null;
                name3?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rechnungsAussteller?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
              warningList: Array<{ message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null>;
          fibuStatus?: { description?: string | null; text: string; value: Types.AuftragFibuStatus } | null;
          objektBestandseinheitIdDataList: Array<{ bestandseinheitId: string; objektId: string }>;
          originalMailDelivery?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            emailAddress: string;
            emailDeliveryDefinitionBezeichnung: string;
            emailDeliveryDefinitionId: string;
            mailId?: string | null;
            original: boolean;
            sender?: string | null;
            sentTs?: string | null;
            shouldNeverSend: boolean;
            shouldSend: boolean;
            updatedByMitarbeiterId?: string | null;
            vorschreibungMailDeliveryId: string;
            resentDeliveryList?: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              emailAddress: string;
              emailDeliveryDefinitionBezeichnung: string;
              emailDeliveryDefinitionId: string;
              mailId?: string | null;
              original: boolean;
              sender?: string | null;
              sentTs?: string | null;
              shouldNeverSend: boolean;
              shouldSend: boolean;
              updatedByMitarbeiterId?: string | null;
              vorschreibungMailDeliveryId: string;
              emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
              fakturierungsperiode: { jahr: number; monat: number };
              objektBestandseinheitVertragList: Array<{
                vertragId: string;
                bestandseinheit: {
                  bestandseinheitId: string;
                  bezeichnung: string;
                  address: {
                    addressId: string;
                    city: string;
                    cityAdditionalInformation?: string | null;
                    countryCodeIso2: string;
                    houseEntranceApartmentNumber?: string | null;
                    postofficeBoxNumber?: string | null;
                    postofficeBoxZipCode?: string | null;
                    street?: string | null;
                    type: Types.AddressType;
                    zipCode?: string | null;
                  };
                };
                objekt: { kurzBezeichnung: string; objektId: string };
              }>;
              recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
              vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }> | null;
            sourceDelivery?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              emailAddress: string;
              emailDeliveryDefinitionBezeichnung: string;
              emailDeliveryDefinitionId: string;
              mailId?: string | null;
              original: boolean;
              sender?: string | null;
              sentTs?: string | null;
              shouldNeverSend: boolean;
              shouldSend: boolean;
              updatedByMitarbeiterId?: string | null;
              vorschreibungMailDeliveryId: string;
              emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
              fakturierungsperiode: { jahr: number; monat: number };
              objektBestandseinheitVertragList: Array<{
                vertragId: string;
                bestandseinheit: {
                  bestandseinheitId: string;
                  bezeichnung: string;
                  address: {
                    addressId: string;
                    city: string;
                    cityAdditionalInformation?: string | null;
                    countryCodeIso2: string;
                    houseEntranceApartmentNumber?: string | null;
                    postofficeBoxNumber?: string | null;
                    postofficeBoxZipCode?: string | null;
                    street?: string | null;
                    type: Types.AddressType;
                    zipCode?: string | null;
                  };
                };
                objekt: { kurzBezeichnung: string; objektId: string };
              }>;
              recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
              vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
            fakturierungsperiode: { jahr: number; monat: number };
            objektBestandseinheitVertragList: Array<{
              vertragId: string;
              bestandseinheit: {
                bestandseinheitId: string;
                bezeichnung: string;
                address: {
                  addressId: string;
                  city: string;
                  cityAdditionalInformation?: string | null;
                  countryCodeIso2: string;
                  houseEntranceApartmentNumber?: string | null;
                  postofficeBoxNumber?: string | null;
                  postofficeBoxZipCode?: string | null;
                  street?: string | null;
                  type: Types.AddressType;
                  zipCode?: string | null;
                };
              };
              objekt: { kurzBezeichnung: string; objektId: string };
            }>;
            recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
            vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.AuftragStatus };
        } | null;
        eigenbeleg?: {
          auftragIdList: Array<string>;
          ausstellerRechtstraegerId: string;
          belegId: string;
          belegdatum: string;
          belegnummer: string;
          bezeichnung: string;
          buchungIdList: Array<string>;
          buchungsanweisungIdList: Array<string>;
          buchungskreisId?: string | null;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          empfaengerRechtstraegerId: string;
          mailId?: string | null;
          paid: boolean;
          sumBrutto?: number | null;
          sumNetto?: number | null;
          sumUst?: number | null;
          auftragStatusDetailsList: Array<{
            auftragId: string;
            offenerBetrag?: number | null;
            paymentCreatable: boolean;
            paymentIdList: Array<string>;
            buchungsanweisungStatus?: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus } | null;
            eigenbelegStatus: { description?: string | null; text: string; value: Types.EigenbelegStatus };
            fakturierungsperiode: { jahr: number; monat: number };
          }>;
          ausstellerBankDetails: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string };
          ausstellerRechtstraeger: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fibuKontoCreatable: boolean;
            kundennummer: string;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            tagList: Array<string>;
            updatedByMitarbeiterId?: string | null;
            bankDetailsList: Array<{
              accountHolder: string;
              accountLimit?: number | null;
              assignedHeVertragZahlungsregelMap: any;
              assignedObjektMap: any;
              bankDetailsId: string;
              bankname: string;
              bic: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              ebicsCreditTransferUserList: Array<string>;
              ebicsDirectDebitUserList: Array<string>;
              fibuKontoId?: string | null;
              fibuKontonummer?: string | null;
              iban: string;
              updatedByMitarbeiterId?: string | null;
              fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
              fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
              sepaMandat?: {
                fileId?: string | null;
                fileName?: string | null;
                sepaMandatId: string;
                sepaMandatReference: string;
                signatureDate?: string | null;
                firmendaten?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  firmendatenId: string;
                  kundenSystemId: string;
                  kurzBezeichnung: string;
                  name1: string;
                  name2?: string | null;
                  name3?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                rechnungsAussteller?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
                warningList: Array<{ message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            personenbezugList: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              personenbezugId: string;
              tagList: Array<string>;
              person: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechtstraegerSteuerpflicht?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              rechtstraegerSteuerpflichtId: string;
              validFrom: string;
              historicizedList?: Array<{
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                deletable?: boolean | null;
                kommentar?: string | null;
                lastUpdateTs: string;
                rechtstraegerSteuerpflichtId: string;
                validFrom: string;
                steuerpflicht: { text: string; value: Types.Steuerpflicht };
                warningList: Array<{ message: string; type: string }>;
              }> | null;
              steuerpflicht: { text: string; value: Types.Steuerpflicht };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
            sprache: { text: string; value: Types.Sprache };
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { value: Types.RechtstraegerType; text: string };
            vertragSummary?: {
              heVertragCount: number;
              heVertragVerwaltungCount: number;
              mietVertragCount: number;
              mietVertragVerwaltungCount: number;
              weSubverwaltungCount: number;
              weVertragCount: number;
              weVertragVerwaltungCount: number;
            } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          belegform: { text: string; value: Types.BelegFormArt };
          buchungErrorList: Array<{ message: string; type: string }>;
          buchungsanweisungListEntryDataList: Array<{
            buchungsanweisungId: string;
            status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
            fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
          }>;
          buchungsanweisungStatus?: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus } | null;
          deviationDetails?: {
            umbuchungInfoList: Array<{
              fakturierungsperiode: { jahr: number; monat: number };
              kontoKeyIst: { nummer: string; klasse: { text: string; value: Types.KontoKlasse } };
              kontoKeySoll: { nummer: string; klasse: { text: string; value: Types.KontoKlasse } };
            }>;
          } | null;
          dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
          eigenbelegPositionList: Array<{
            bestandseinheitId: string;
            bezeichnung: string;
            brutto: number;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            eigenbelegsPositionId: string;
            generierDatum?: string | null;
            generierlaufId?: string | null;
            netto: number;
            objektId: string;
            performancePeriodFromInclusive: string;
            performancePeriodToInclusive: string;
            steuersatz: number;
            ust: number;
            ustKategorieEntryId: string;
            ustKategorieId: string;
            vertragId: string;
            vorschreibungspositionBezeichnung: string;
            vorschreibungspositionId: string;
            bestandseinheit?: {
              aktivSeit?: string | null;
              bauteil?: string | null;
              bestandseinheitId: string;
              bezeichnung: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              geschoss?: string | null;
              inaktivSeit?: string | null;
              nutzflaeche?: number | null;
              nutzwert?: number | null;
              stiege?: string | null;
              tagList: Array<string>;
              address: {
                addressId: string;
                city: string;
                cityAdditionalInformation?: string | null;
                countryCodeIso2: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                haupt?: boolean | null;
                houseEntranceApartmentNumber?: string | null;
                postofficeBoxNumber?: string | null;
                postofficeBoxZipCode?: string | null;
                street?: string | null;
                tagList: Array<string>;
                type: Types.AddressType;
                zipCode?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              nutzungsArt?: { text: string; value: Types.NutzungsArt } | null;
              optionSteuerpflicht?: {
                beOptionSteuerpflichtId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                deletable?: boolean | null;
                kommentar?: string | null;
                lastUpdateTs: string;
                optionSteuerpflicht: boolean;
                validFrom: string;
                historicizedList?: Array<{
                  beOptionSteuerpflichtId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  current: boolean;
                  deletable?: boolean | null;
                  kommentar?: string | null;
                  lastUpdateTs: string;
                  optionSteuerpflicht: boolean;
                  validFrom: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }> | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              objektVerwaltungsart: { text: string; value: Types.Verwaltungsart };
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            labelList: Array<{ label: string; value: string }>;
            objekt?: {
              countryCodeIso2: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fotoFileId?: string | null;
              fotoType?: Types.FotoType | null;
              kurzBezeichnung: string;
              objektId: string;
              rechnungsAusstellerBezeichnung?: string | null;
              rechnungsAusstellerChangeable?: boolean | null;
              subAdministration: boolean;
              sumNutzflaeche: number;
              sumNutzwert: number;
              tagList: Array<string>;
              updatedByMitarbeiterId?: string | null;
              verwaltungBis?: string | null;
              verwaltungSeit: string;
              verwaltungsartChangeable?: boolean | null;
              addressList: Array<{
                addressId: string;
                city: string;
                cityAdditionalInformation?: string | null;
                countryCodeIso2: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                haupt?: boolean | null;
                houseEntranceApartmentNumber?: string | null;
                postofficeBoxNumber?: string | null;
                postofficeBoxZipCode?: string | null;
                street?: string | null;
                tagList: Array<string>;
                type: Types.AddressType;
                zipCode?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              objektSummeAnteile?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                kommentar?: string | null;
                lastUpdateTs: string;
                objektSummeAnteileId: string;
                summeAnteile: number;
                validFrom: string;
                historicizedList?: Array<{
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  current: boolean;
                  kommentar?: string | null;
                  lastUpdateTs: string;
                  objektSummeAnteileId: string;
                  summeAnteile: number;
                  validFrom: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }> | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rechnungsAussteller: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fibuKontoCreatable: boolean;
                kundennummer: string;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                tagList: Array<string>;
                updatedByMitarbeiterId?: string | null;
                bankDetailsList: Array<{
                  accountHolder: string;
                  accountLimit?: number | null;
                  assignedHeVertragZahlungsregelMap: any;
                  assignedObjektMap: any;
                  bankDetailsId: string;
                  bankname: string;
                  bic: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  deletable?: boolean | null;
                  ebicsCreditTransferUserList: Array<string>;
                  ebicsDirectDebitUserList: Array<string>;
                  fibuKontoId?: string | null;
                  fibuKontonummer?: string | null;
                  iban: string;
                  updatedByMitarbeiterId?: string | null;
                  fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
                  fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
                  sepaMandat?: {
                    fileId?: string | null;
                    fileName?: string | null;
                    sepaMandatId: string;
                    sepaMandatReference: string;
                    signatureDate?: string | null;
                    firmendaten?: {
                      createTs: string;
                      createdBy: string;
                      createdByMitarbeiterId?: string | null;
                      firmendatenId: string;
                      kundenSystemId: string;
                      kurzBezeichnung: string;
                      name1: string;
                      name2?: string | null;
                      name3?: string | null;
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    } | null;
                    rechnungsAussteller?: {
                      createTs: string;
                      createdBy: string;
                      createdByMitarbeiterId?: string | null;
                      kurzBezeichnung: string;
                      rechtstraegerId: string;
                      status: { text: string; value: Types.RechtstraegerStatus };
                      type: { text: string; value: Types.RechtstraegerType };
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    } | null;
                    status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
                    warningList: Array<{ message: string; type: string }>;
                  } | null;
                  status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                personenbezugList: Array<{
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  personenbezugId: string;
                  tagList: Array<string>;
                  person: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                rechtstraegerSteuerpflicht?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  current: boolean;
                  deletable?: boolean | null;
                  kommentar?: string | null;
                  lastUpdateTs: string;
                  rechtstraegerSteuerpflichtId: string;
                  validFrom: string;
                  historicizedList?: Array<{
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    current: boolean;
                    deletable?: boolean | null;
                    kommentar?: string | null;
                    lastUpdateTs: string;
                    rechtstraegerSteuerpflichtId: string;
                    validFrom: string;
                    steuerpflicht: { text: string; value: Types.Steuerpflicht };
                    warningList: Array<{ message: string; type: string }>;
                  }> | null;
                  steuerpflicht: { text: string; value: Types.Steuerpflicht };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
                sprache: { text: string; value: Types.Sprache };
                status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
                type: { value: Types.RechtstraegerType; text: string };
                vertragSummary?: {
                  heVertragCount: number;
                  heVertragVerwaltungCount: number;
                  mietVertragCount: number;
                  mietVertragVerwaltungCount: number;
                  weSubverwaltungCount: number;
                  weVertragCount: number;
                  weVertragVerwaltungCount: number;
                } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              sachbearbeiter?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                fileStorageInitialized: boolean;
                firmendatenId: string;
                firstname: string;
                funktionsModulList: Array<string>;
                lastname: string;
                mitarbeiterId: string;
                title?: string | null;
                titleTrailing?: string | null;
                username: string;
                contactPerson?: {
                  contactPersonId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  firstname: string;
                  lastname: string;
                  tagList: Array<string>;
                  title?: string | null;
                  titleTrailing?: string | null;
                  contactList: Array<{
                    contactId: string;
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    haupt?: boolean | null;
                    tagList: Array<string>;
                    type: string;
                    wert: string;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  rechtstraeger?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                  salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                rolleList: Array<{
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  name: string;
                  rolleId: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
                status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
                user?: {
                  email?: string | null;
                  emailUnverified?: string | null;
                  firstname?: string | null;
                  lastname?: string | null;
                  role: string;
                  username: string;
                  status: { text: string; value: string };
                } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.ObjektStatus; description?: string | null };
              verwaltungsart: { text: string; value: Types.Verwaltungsart };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
          }>;
          empfaengerBankDetails?: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string } | null;
          empfaengerRechtstraeger: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fibuKontoCreatable: boolean;
            kundennummer: string;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            tagList: Array<string>;
            updatedByMitarbeiterId?: string | null;
            bankDetailsList: Array<{
              accountHolder: string;
              accountLimit?: number | null;
              assignedHeVertragZahlungsregelMap: any;
              assignedObjektMap: any;
              bankDetailsId: string;
              bankname: string;
              bic: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              ebicsCreditTransferUserList: Array<string>;
              ebicsDirectDebitUserList: Array<string>;
              fibuKontoId?: string | null;
              fibuKontonummer?: string | null;
              iban: string;
              updatedByMitarbeiterId?: string | null;
              fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
              fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
              sepaMandat?: {
                fileId?: string | null;
                fileName?: string | null;
                sepaMandatId: string;
                sepaMandatReference: string;
                signatureDate?: string | null;
                firmendaten?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  firmendatenId: string;
                  kundenSystemId: string;
                  kurzBezeichnung: string;
                  name1: string;
                  name2?: string | null;
                  name3?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                rechnungsAussteller?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
                warningList: Array<{ message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            personenbezugList: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              personenbezugId: string;
              tagList: Array<string>;
              person: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechtstraegerSteuerpflicht?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              rechtstraegerSteuerpflichtId: string;
              validFrom: string;
              historicizedList?: Array<{
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                deletable?: boolean | null;
                kommentar?: string | null;
                lastUpdateTs: string;
                rechtstraegerSteuerpflichtId: string;
                validFrom: string;
                steuerpflicht: { text: string; value: Types.Steuerpflicht };
                warningList: Array<{ message: string; type: string }>;
              }> | null;
              steuerpflicht: { text: string; value: Types.Steuerpflicht };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
            sprache: { text: string; value: Types.Sprache };
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { value: Types.RechtstraegerType; text: string };
            vertragSummary?: {
              heVertragCount: number;
              heVertragVerwaltungCount: number;
              mietVertragCount: number;
              mietVertragVerwaltungCount: number;
              weSubverwaltungCount: number;
              weVertragCount: number;
              weVertragVerwaltungCount: number;
            } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          fakturierungsperiode: { jahr: number; monat: number };
          fileInfo: { fileId: string; name: string; size: number };
          paymentMethod: { text: string; value: Types.PaymentMethod };
          status: { description?: string | null; text: string; value: Types.EigenbelegStatus };
          type: { text: string; value: Types.EigenbelegType };
          verrechnungsart: { text: string; value: Types.Verrechnungsart };
        } | null;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { value: Types.GenerierlaufEntryExitCode; text: string } | null;
        stepList: Array<{
          designation: string;
          receivingApplication?: string | null;
          stepId: string;
          type?: string | null;
          status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
        }>;
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const EigenbelegErstellenGenerierlaufDocument = gql`
  query EigenbelegErstellenGenerierlauf($generierlaufId: ID!) {
    getEigenbelegErstellenGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countVerarbeitet
        countNichtVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        generierDatum
        generierlaufId
        status {
          description
          value
          text
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useEigenbelegErstellenGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<EigenbelegErstellenGenerierlaufQuery, EigenbelegErstellenGenerierlaufQueryVariables> &
    ({ variables: EigenbelegErstellenGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EigenbelegErstellenGenerierlaufQuery, EigenbelegErstellenGenerierlaufQueryVariables>(
    EigenbelegErstellenGenerierlaufDocument,
    options
  );
}
export function useEigenbelegErstellenGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EigenbelegErstellenGenerierlaufQuery, EigenbelegErstellenGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EigenbelegErstellenGenerierlaufQuery, EigenbelegErstellenGenerierlaufQueryVariables>(
    EigenbelegErstellenGenerierlaufDocument,
    options
  );
}
export function useEigenbelegErstellenGenerierlaufSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<EigenbelegErstellenGenerierlaufQuery, EigenbelegErstellenGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EigenbelegErstellenGenerierlaufQuery, EigenbelegErstellenGenerierlaufQueryVariables>(
    EigenbelegErstellenGenerierlaufDocument,
    options
  );
}
export type EigenbelegErstellenGenerierlaufQueryHookResult = ReturnType<typeof useEigenbelegErstellenGenerierlaufQuery>;
export type EigenbelegErstellenGenerierlaufLazyQueryHookResult = ReturnType<typeof useEigenbelegErstellenGenerierlaufLazyQuery>;
export type EigenbelegErstellenGenerierlaufSuspenseQueryHookResult = ReturnType<typeof useEigenbelegErstellenGenerierlaufSuspenseQuery>;
export type EigenbelegErstellenGenerierlaufQueryResult = Apollo.QueryResult<
  EigenbelegErstellenGenerierlaufQuery,
  EigenbelegErstellenGenerierlaufQueryVariables
>;
export const EigenbelegErstellenGenerierlaufEntryListDocument = gql`
  query EigenbelegErstellenGenerierlaufEntryList(
    $generierlaufId: ID!
    $belegnummer: String
    $fakturierungsperiode: String
    $objektId: String
    $ausstellerId: String
    $empfaengerId: String
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $order: EigenbelegErstellenGenerierlaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getEigenbelegErstellenGenerierlaufEntryList(
      generierlaufId: $generierlaufId
      belegnummer: $belegnummer
      fakturierungsperiode: $fakturierungsperiode
      objektId: $objektId
      ausstellerId: $ausstellerId
      empfaengerId: $empfaengerId
      exitCodeList: $exitCodeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          auftrag {
            ...Auftrag
          }
          currentStep
          eigenbeleg {
            ...Eigenbeleg
          }
          errorList {
            message
            type
          }
          exitCode {
            value
            text
          }
          generierlaufEntryId
          infoMessage
          objektId
          objektKurzBezeichnung
          retry
          stepCount
          stepList {
            ...GenerierlaufAsyncStepFields
          }
          warningList {
            message
            type
          }
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AuftragFragmentDoc}
  ${EigenbelegFragmentDoc}
  ${GenerierlaufAsyncStepFieldsFragmentDoc}
`;
export function useEigenbelegErstellenGenerierlaufEntryListQuery(
  baseOptions: Apollo.QueryHookOptions<EigenbelegErstellenGenerierlaufEntryListQuery, EigenbelegErstellenGenerierlaufEntryListQueryVariables> &
    ({ variables: EigenbelegErstellenGenerierlaufEntryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EigenbelegErstellenGenerierlaufEntryListQuery, EigenbelegErstellenGenerierlaufEntryListQueryVariables>(
    EigenbelegErstellenGenerierlaufEntryListDocument,
    options
  );
}
export function useEigenbelegErstellenGenerierlaufEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EigenbelegErstellenGenerierlaufEntryListQuery, EigenbelegErstellenGenerierlaufEntryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EigenbelegErstellenGenerierlaufEntryListQuery, EigenbelegErstellenGenerierlaufEntryListQueryVariables>(
    EigenbelegErstellenGenerierlaufEntryListDocument,
    options
  );
}
export function useEigenbelegErstellenGenerierlaufEntryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<EigenbelegErstellenGenerierlaufEntryListQuery, EigenbelegErstellenGenerierlaufEntryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EigenbelegErstellenGenerierlaufEntryListQuery, EigenbelegErstellenGenerierlaufEntryListQueryVariables>(
    EigenbelegErstellenGenerierlaufEntryListDocument,
    options
  );
}
export type EigenbelegErstellenGenerierlaufEntryListQueryHookResult = ReturnType<typeof useEigenbelegErstellenGenerierlaufEntryListQuery>;
export type EigenbelegErstellenGenerierlaufEntryListLazyQueryHookResult = ReturnType<typeof useEigenbelegErstellenGenerierlaufEntryListLazyQuery>;
export type EigenbelegErstellenGenerierlaufEntryListSuspenseQueryHookResult = ReturnType<
  typeof useEigenbelegErstellenGenerierlaufEntryListSuspenseQuery
>;
export type EigenbelegErstellenGenerierlaufEntryListQueryResult = Apollo.QueryResult<
  EigenbelegErstellenGenerierlaufEntryListQuery,
  EigenbelegErstellenGenerierlaufEntryListQueryVariables
>;
