import React from 'react';
import { Space, Typography } from 'antd';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { MitarbeiterTooltip } from '../../../../components/Card';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { BudgetingAbrDefVPosLinkage } from '../../../../types';
import EntityWarningList from '../../../../components/message/EntityWarningList';
import EntryListingTableActions from './EntryListingTableActions';

const entryListingTableColumns = (
  onAction: () => void,
  linkageEntryList: BudgetingAbrDefVPosLinkage[]
): TableWithColSelectorColumnProps<BudgetingAbrDefVPosLinkage>[] => {
  return [
    {
      title: 'Abrechnungsdefinition',
      defaultSelected: true,
      render: (text, record) => (
        <Space>
          <DataWithShortenedText maxTextLength={35} text={record.abrechnungsdefinition.bezeichnung}>
            {(shortenedText) => <Typography.Text>{shortenedText}</Typography.Text>}
          </DataWithShortenedText>
          {record.warningList && <EntityWarningList warningList={record.warningList} />}
        </Space>
      ),
    },
    {
      title: 'Abrechnungskreise – VPos Verknüpfung',
      defaultSelected: true,
      render: (text, record) => {
        const abrKreisVPosPairs = record.abrKreisVPosList.map(
          (abrkreis) => `${abrkreis.abrechnungskreis.bezeichnung} - ${abrkreis.vorschreibungsposition.bezeichnung}`
        );

        return <DataWithShortenedText maxTextLength={35} text={abrKreisVPosPairs.join(', ')} longText />;
      },
    },
    {
      title: 'Erstellt am',
      render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
    },
    {
      title: 'Ersteller',
      render: (text, record) => (
        <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
      ),
    },
    {
      title: 'Aktion',
      defaultSelected: true,
      render: (text, record) => <EntryListingTableActions linkage={record} onAction={onAction} linkageEntryList={linkageEntryList} />,
    },
  ];
};

export default entryListingTableColumns;
