import { isNotNil } from '../../helpers/assertionHelper';
import { Period, PeriodUnit } from '../../types';

export const compareTwoPeriodsForSorting = (date1: Period | undefined | null, date2: Period | undefined | null) => {
  if (!isNotNil(date1)) {
    return -1;
  } else if (!isNotNil(date2)) {
    return 1;
  }

  return convertPeriodToDays(date1) - convertPeriodToDays(date2);
};

const convertPeriodToDays = ({ amount, unit }: Period) => {
  switch (unit.value) {
    case PeriodUnit.Days:
      return amount;
    case PeriodUnit.Weeks:
      return amount * 7;
    case PeriodUnit.Months:
      return amount * 30;
    case PeriodUnit.Years:
      return amount * 365;
    default:
      return 0;
  }
};
