import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Collapse, Space, Typography } from 'antd';
import { EditorState } from 'lexical';
import { BelegTexte, TextbausteinType } from '../../../../types';
import { StyledCollapsePanel } from '../../../Abrechnungsdefinition/styled/Collapse.styles';
import TextbausteinEditor from './TextbausteinListElements/TextbausteinEditor';
import { TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';
import TextbausteinUploadArea from './TextbausteinListElements/TextbausteinUploadArea';
import { belegTexteFormFields, BelegTexteFormValues } from '../belegTexteFormMapper';
import { TextbausteinCollapsePanelHeader } from '../../../../components/Template/PDFTemplates/TextbausteinList/TextbausteinListElements/TextbausteinCollapsePanelHeader';
import { capitalizeFirstLetter } from '../../../../helpers/stringHelper';
import ErrorMessagePanel from '../../../../components/Error/ErrorMessagePanel';
import { isEditorStateEmpty } from '../belegTexteFormHelpers';

type Props = {
  formikProps: FormikProps<BelegTexteFormValues>;
  textbausteinList: TextbausteinFormValues[];
  onSelectTextbaustein: (value: TextbausteinType) => void;
  level: number;
  belegTexte: BelegTexte;
};

const TextbausteinListWithUpload: FC<Props> = ({ formikProps, textbausteinList, onSelectTextbaustein, level, belegTexte }) => {
  return (
    <Collapse bordered ghost accordion>
      {textbausteinList
        .sort((a, b) => a.index - b.index)
        .map((textbaustein) => {
          const textbausteinType = textbaustein.type.value === TextbausteinType.BelegFuss ? 'belegFuss' : 'belegKopf';
          const belegTextFormFieldText = belegTexteFormFields[`${textbausteinType}Text`];
          const belegTextFormFieldTextAsHtml = belegTexteFormFields[`${textbausteinType}TextAsHtml`];

          const isFileAvailable = !!formikProps.values[textbausteinType] || !!formikProps.values[`${textbausteinType}BildFileId`];
          let isEditorTextAvailable = false;
          if (typeof formikProps.values[belegTextFormFieldText] === 'string' && formikProps.values[belegTextFormFieldText]) {
            isEditorTextAvailable = true;
          }
          if (typeof formikProps.values[belegTextFormFieldText] === 'object') {
            isEditorTextAvailable = !isEditorStateEmpty(formikProps.values[belegTextFormFieldText] as EditorState);
          }

          const tempFormTextbausteinTypeName = `temp${capitalizeFirstLetter(textbausteinType) + textbausteinType.slice(1)}BildFileId`;

          return (
            <StyledCollapsePanel
              header={
                <TextbausteinCollapsePanelHeader
                  title={textbaustein?.type.text}
                  isTextbausteinComplete={isEditorTextAvailable || isFileAvailable}
                  onClick={() => onSelectTextbaustein(textbaustein.type.value)}
                  isVariant={isVariant(level)}
                />
              }
              key={textbaustein.index}
              showArrow={false}
            >
              <Space direction="vertical" style={{ width: '100%', height: '100%' }}>
                <Space direction="vertical">
                  <Typography.Text>Bild</Typography.Text>
                  <TextbausteinUploadArea
                    formikProps={formikProps}
                    textbausteinType={textbaustein.type.value}
                    name={belegTexteFormFields[textbausteinType]}
                    // @ts-ignore
                    nameForTempBildFileId={belegTexteFormFields[tempFormTextbausteinTypeName]}
                    file={formikProps.values[textbausteinType]}
                    fileInfo={belegTexte[`${textbausteinType}BildFileInfo`]}
                    // @ts-ignore
                    tempFileId={formikProps.values[tempFormTextbausteinTypeName]}
                    disabled={isEditorTextAvailable}
                  />
                  {formikProps.errors[textbausteinType] && <ErrorMessagePanel error={formikProps.errors[textbausteinType] as string} />}
                </Space>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Typography.Text>Text</Typography.Text>
                  <TextbausteinEditor
                    name={belegTextFormFieldText}
                    nameForHtml={belegTextFormFieldTextAsHtml}
                    platzhalterList={textbaustein.platzhalterList}
                    disabled={!!formikProps.values[textbausteinType] || isFileAvailable}
                  />
                </Space>
              </Space>
            </StyledCollapsePanel>
          );
        })}
    </Collapse>
  );
};

const isVariant = (level: number) => level > 0;

export { TextbausteinListWithUpload };
