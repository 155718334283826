import React, { FC, useState } from 'react';
import AbrDefVersionViewTemplateTextbausteinList from './AbrDefVersionViewTemplateTextbausteinList';
import { TextbausteinType } from '../../../../types';
import PDFTemplatePage, { TemplatePreviewProps } from '../../../../components/Template/PDFTemplates/PDFTemplatePage';
import { TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';

type Props = {
  values: TextbausteinFormValues[];
  renderPreview: (props: TemplatePreviewProps) => React.ReactNode;
};

const AbrDefVersionViewTemplate: FC<Props> = ({ renderPreview, values }) => {
  const [selectedTextbaustein, setSelectedTextbaustein] = useState<TextbausteinType>();

  const setSelectedTextbausteinToNewOrDefaultValue = (newValue: TextbausteinType) => {
    setSelectedTextbaustein((currentSelected) => (currentSelected !== newValue ? newValue : undefined));
  };

  return (
    <PDFTemplatePage
      textbausteinList={
        <AbrDefVersionViewTemplateTextbausteinList values={values} level={0} onSelectTextbaustein={setSelectedTextbausteinToNewOrDefaultValue} />
      }
      renderPreview={renderPreview}
      selectedTextbaustein={selectedTextbaustein}
    />
  );
};

export default AbrDefVersionViewTemplate;
