import { FormFields } from '../../../../../helpers/formikHelper';
import { GenerierlaufEntryExitCode } from '../../../../../types';
import { filtersQueryParams } from './filtersQueryParams';

export type ListingFiltersFormValues = {
  exitCodeList?: GenerierlaufEntryExitCode[];
  objektId?: string;
};

export const ListingFiltersFormFields: FormFields<ListingFiltersFormValues> = {
  exitCodeList: 'exitCodeList',
  objektId: 'objektId',
};

export const listingFiltersFormInitialValues = (queryParams: filtersQueryParams): ListingFiltersFormValues => ({
  exitCodeList: queryParams.exitCodeList,
  objektId: queryParams.objektId,
});

export const mapFormValuesToListingFilters = (formValues: ListingFiltersFormValues): filtersQueryParams => ({
  exitCodeList: formValues.exitCodeList,
  objektId: formValues.objektId,
});
