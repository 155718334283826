import { message } from 'antd';
import Fetcher from '../../../../../helpers/fetcher';
import { processAndReturnError, processResponse } from '../../../../../helpers/APIUtils';
import { showSuccessMsgOther } from '../../../../../components/message';
import { mapErrorToErrorMessage } from '../../../../../helpers/errorAndWarningHelper';
import { SepaMandatActivateOrUpdateFormValues } from './sepaMandatActivateOrUpdateFormMapper';
import { mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';

enum SepaMandatAction {
  Activate = 'ACTIVATE',
  Deactivate = 'DEACTIVATE',
}

export const handleUpdateSubmit = (
  firmendatenId: string,
  rechtstraegerId: string,
  bankDetailsId: string,
  sepaMandatId: string,
  values: SepaMandatActivateOrUpdateFormValues,
  setSubmitting: (isSubmitting: boolean) => void,
  onSuccess: () => void
) => {
  const formData = new FormData();
  formData.append(
    'request',
    new Blob(
      [
        JSON.stringify({
          data: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            signatureDate: mapFormDateValueToDateString(values.signatureDate!),
            signatureFileId: values.signatureFile?.fileId,
          },
        }),
      ],
      {
        type: 'application/json',
      }
    ),
    'signatureFile'
  );

  if (values.signatureFile?.isUpload) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    formData.append('signatureFile', values.signatureFile.file!, values.signatureFile.filename);
  }

  setSubmitting(true);
  Fetcher.getInstance()
    .fetch(`/api/firmendaten/${firmendatenId}/rechtstraeger/${rechtstraegerId}/bank-details/${bankDetailsId}/sepa-mandat/${sepaMandatId}`, {
      method: 'PUT',
      body: formData,
    })
    .then(processResponse)
    .then(() => {
      showSuccessMsgOther(`SEPA Mandat wurde erfolgreich aktualisiert`);
      setSubmitting(false);
      onSuccess();
    })
    .catch(processAndReturnError)
    .catch((error) => {
      setSubmitting(false);
      return message.error({
        content: `SEPA Mandat konnte nicht aktualisiert werden: ${mapErrorToErrorMessage(error)}`,
      });
    });
};

export const handleActivateSubmit = (
  firmendatenId: string,
  rechtstraegerId: string,
  bankDetailsId: string,
  sepaMandatId: string,
  values: SepaMandatActivateOrUpdateFormValues,
  setSubmitting: (isSubmitting: boolean) => void,
  onSuccess: () => void
) => {
  const formData = new FormData();
  formData.append(
    'request',
    new Blob(
      [
        JSON.stringify({
          data: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            signatureDate: mapFormDateValueToDateString(values.signatureDate!),
            signatureFileId: values.signatureFile?.fileId,
          },
          meta: { action: SepaMandatAction.Activate },
        }),
      ],
      {
        type: 'application/json',
      }
    ),
    'signatureFile'
  );

  if (values.signatureFile?.isUpload) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    formData.append('signatureFile', values.signatureFile.file!, values.signatureFile.filename);
  }

  setSubmitting(true);
  Fetcher.getInstance()
    .fetch(`/api/firmendaten/${firmendatenId}/rechtstraeger/${rechtstraegerId}/bank-details/${bankDetailsId}/sepa-mandat/${sepaMandatId}`, {
      method: 'POST',
      body: formData,
    })
    .then(processResponse)
    .then(() => {
      showSuccessMsgOther(`SEPA Mandat wurde erfolgreich aktiviert`);
      setSubmitting(false);
      onSuccess();
    })
    .catch(processAndReturnError)
    .catch((error) => {
      setSubmitting(false);
      return message.error({
        content: `SEPA Mandat konnte nicht aktiviert werden: ${mapErrorToErrorMessage(error)}`,
      });
    });
};

export const handleDeactivateSubmit = (
  firmendatenId: string,
  rechtstraegerId: string,
  bankDetailsId: string,
  sepaMandatId: string,
  onSuccess: () => void
) => {
  const formData = new FormData();
  formData.append(
    'request',
    new Blob(
      [
        JSON.stringify({
          meta: { action: SepaMandatAction.Deactivate },
        }),
      ],
      {
        type: 'application/json',
      }
    )
  );

  Fetcher.getInstance()
    .fetch(`/api/firmendaten/${firmendatenId}/rechtstraeger/${rechtstraegerId}/bank-details/${bankDetailsId}/sepa-mandat/${sepaMandatId}`, {
      method: 'POST',
      body: formData,
    })
    .then(processResponse)
    .then(() => {
      showSuccessMsgOther(`SEPA Mandat wurde erfolgreich aktiviert`);
      onSuccess();
    })
    .catch(processAndReturnError)
    .catch((error) => {
      return message.error({
        content: `SEPA Mandat konnte nicht aktiviert werden: ${mapErrorToErrorMessage(error)}`,
      });
    });
};
