import { FC } from 'react';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';
import { useMahnungTemplateChangeHistoryListQuery } from '../gql/MahndefinitionQueries.types';

type Props = {
  mahndefinitionId: string;
  mahnstufeIndex: number;
};

const MahnungTemplateHistoryListingTable: FC<Props> = ({ mahndefinitionId, mahnstufeIndex }) => {
  const { data, loading } = useMahnungTemplateChangeHistoryListQuery({ variables: { mahndefinitionId, mahnstufeIndex } });
  const historyList = data?.getMahnungTemplateChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="mahnung-template" />;
};

export default MahnungTemplateHistoryListingTable;
