import React from 'react';
import { Tag, Typography } from 'antd';
import UstKategorieTableActions from './UstKategorieTableActions';
import { archivedOrNormalStyle, StatusEnum } from '../../../../../helpers/statusHelper';
import { MitarbeiterTooltip } from '../../../../../components/Card';
import StatusBadge from '../../../../../components/Status/StatusBadge';
import DataWithStatus from '../../../../../components/Helpers/DataWithStatus';
import { isNil, isNotNil } from '../../../../../helpers/assertionHelper';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { Percent } from '../../../../../components/Number';
import { NestedTableColProps } from '../../../../../components/Table/NestedTable/NestedTable';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import FirmendatenKurzBezeichnung from '../../../../shared/FirmendatenKurzBezeichnung';
import { UstKategorieWithStatus } from '../../ustKategorieUriHelper';

export const ustKategorieTableColumns = (onActionSuccess: () => void): NestedTableColProps<UstKategorieWithStatus>[] => [
  {
    title: 'Kurzbezeichnung',
    width: 200,
    render: (text, record) => (
      <DataWithStatus status={record.status} text={record.kurzBezeichnung} showStatusDescription={false} showBadgeDot={false} />
    ),
  },
  {
    title: 'Firma',
    render: (text, record) =>
      record.firmendatenId ? (
        <FirmendatenKurzBezeichnung firmendatenId={record.firmendatenId} />
      ) : (
        <Tag style={archivedOrNormalStyle(record.status)}>System</Tag>
      ),
  },
  {
    title: 'Vst.-Kürzung',
    render: (text, record) => {
      if (isNil(record.vstKuerzung)) {
        return <TextForEmptyValue textToShow="NV" />;
      } else {
        return record.vstKuerzung ? <Typography.Text>JA</Typography.Text> : <TextForEmptyValue textToShow="minus" />;
      }
    },
  },
  {
    title: 'USt. vom Aufwand',
    render: (text, record) =>
      isNotNil(record.ustVomAufwand) ? <Percent value={record.ustVomAufwand / 100} /> : <TextForEmptyValue textToShow="NV" />,
  },
  {
    title: 'Status',
    filters: [
      { text: 'Aktiv', value: StatusEnum.Aktiv },
      { text: 'Archiviert', value: StatusEnum.Archiviert },
    ],
    onFilter: (value, record) => record.status.value.includes(value as string),
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },
  {
    title: 'Erstellt am',
    render: (text, record) => (
      <DataWithStatus status={record.status} text="" showBadgeDot={false} showStatusDescription={false}>
        {() => <FormattedDateTime createdTs={record.createTs} />}
      </DataWithStatus>
    ),
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Aktion',
    key: 'action',
    render: (text, record) => <UstKategorieTableActions onActionSuccess={onActionSuccess} ustKategorie={record} />,
  },
];
