import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateRechtstreagerBankSettingsMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  input: Types.RechtstraegerBankSettingsUpdateInput;
}>;

export type UpdateRechtstreagerBankSettingsMutation = {
  actionUpdateRechtstreagerBankSettings: {
    data: { bankSettings: { bankSettingsId?: string | null; sepaCreditorId?: string | null } };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const UpdateRechtstreagerBankSettingsDocument = gql`
  mutation UpdateRechtstreagerBankSettings($rechtstraegerId: ID!, $input: RechtstraegerBankSettingsUpdateInput!) {
    actionUpdateRechtstreagerBankSettings(rechtstraegerId: $rechtstraegerId, input: $input) {
      data {
        bankSettings {
          bankSettingsId
          sepaCreditorId
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateRechtstreagerBankSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRechtstreagerBankSettingsMutation, UpdateRechtstreagerBankSettingsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRechtstreagerBankSettingsMutation, UpdateRechtstreagerBankSettingsMutationVariables>(
    UpdateRechtstreagerBankSettingsDocument,
    options
  );
}
export type UpdateRechtstreagerBankSettingsMutationHookResult = ReturnType<typeof useUpdateRechtstreagerBankSettingsMutation>;
export type UpdateRechtstreagerBankSettingsMutationResult = Apollo.MutationResult<UpdateRechtstreagerBankSettingsMutation>;
export type UpdateRechtstreagerBankSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateRechtstreagerBankSettingsMutation,
  UpdateRechtstreagerBankSettingsMutationVariables
>;
