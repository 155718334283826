import React, { FC } from 'react';
import { Divider, List } from 'antd';
import { SourceEntityStyledCollapse, SourceEntityStyledPanel } from '../../Styled/Deviation.style';
import { TDeviation, TDeviationListGroupedBySourceEntity } from '../../deviation.model';
import Deviation from '../../Item/Deviation';
import { isVorschreibungSourceEntity } from '../../deviationHelpers';
import DeviationListGroupedBySourceEntityActions from './DeviationListGroupedBySourceEntityActions';
import SourceEntityHeader from './SourceEntityHeader/SourceEntityHeader';

type Props = {
  deviationSourceEntityGroupList: TDeviationListGroupedBySourceEntity[];
  loading: boolean;
  showSourceEntity?: boolean;
  altTextForMultipleReferenceOfCause?: string;
};

const DeviationListGroupedBySourceEntity: FC<Props> = ({
  deviationSourceEntityGroupList,
  loading,
  showSourceEntity,
  altTextForMultipleReferenceOfCause,
}) => {
  return (
    <>
      {deviationSourceEntityGroupList.map((deviationSourceEntityGroup) => {
        return showSourceEntity ? (
          <SourceEntityStyledCollapse key={deviationSourceEntityGroup.sourceEntity.entityId} style={{ backgroundColor: '#fff' }} ghost>
            <SourceEntityStyledPanel
              key={deviationSourceEntityGroup.sourceEntity.entityId}
              extra={isVorschreibungSourceEntity(deviationSourceEntityGroup.sourceEntity) && <DeviationListGroupedBySourceEntityActions />}
              header={
                <SourceEntityHeader sourceEntity={deviationSourceEntityGroup.sourceEntity} changeType={deviationSourceEntityGroup.changeType} />
              }
            >
              <DeviationListOptionallyGroupedByGroup
                deviationList={deviationSourceEntityGroup.deviationList}
                loading={loading}
                showSourceEntity={showSourceEntity}
                altTextForMultipleReferenceOfCause={altTextForMultipleReferenceOfCause}
              />
            </SourceEntityStyledPanel>
          </SourceEntityStyledCollapse>
        ) : (
          <DeviationListOptionallyGroupedByGroup
            key={deviationSourceEntityGroup.sourceEntity.entityId}
            deviationList={deviationSourceEntityGroup.deviationList}
            loading={loading}
            showSourceEntity={showSourceEntity}
            altTextForMultipleReferenceOfCause={altTextForMultipleReferenceOfCause}
          />
        );
      })}
    </>
  );
};

type DeviationListProps = {
  deviationList: TDeviation[];
  loading: boolean;
  showSourceEntity?: boolean;
  altTextForMultipleReferenceOfCause?: string;
};

const DeviationListOptionallyGroupedByGroup: FC<DeviationListProps> = ({
  deviationList,
  loading,
  showSourceEntity,
  altTextForMultipleReferenceOfCause,
}) => {
  const hasGroup = deviationList.some((deviation) => deviation.group);
  return !hasGroup ? (
    <DeviationList
      deviationList={deviationList}
      loading={loading}
      showSourceEntity={showSourceEntity}
      altTextForMultipleReferenceOfCause={altTextForMultipleReferenceOfCause}
    />
  ) : (
    <DeviationListGroupedByGroup
      deviationList={deviationList}
      loading={loading}
      showSourceEntity={showSourceEntity}
      altTextForMultipleReferenceOfCause={altTextForMultipleReferenceOfCause}
    />
  );
};

const DeviationList: FC<DeviationListProps> = ({ deviationList, loading, showSourceEntity, altTextForMultipleReferenceOfCause }) => {
  return (
    <List<TDeviation>
      loading={loading}
      itemLayout="horizontal"
      dataSource={deviationList}
      renderItem={(deviation, index) => (
        <Deviation
          key={index}
          showSourceEntity={showSourceEntity}
          deviation={deviation}
          altTextForMultipleReferenceOfCause={altTextForMultipleReferenceOfCause}
        /> //STOPPED
      )}
    />
  );
};

const DeviationListGroupedByGroup: FC<DeviationListProps> = ({ deviationList, loading, showSourceEntity, altTextForMultipleReferenceOfCause }) => {
  const unknownGroupId = 'Unbekannte Gruppe';
  const deviationsGroupedByGroup = deviationList.reduce(
    (acc, deviation) => {
      const groupId = deviation.group?.id ?? unknownGroupId;
      acc[groupId] = [...(acc[groupId] || []), deviation];
      return acc;
    },
    {} as Record<string, TDeviation[]>
  );

  return (
    <>
      {Object.keys(deviationsGroupedByGroup).map((groupId) => {
        const deviationListData = deviationsGroupedByGroup[groupId];
        return (
          <div key={groupId}>
            <Divider orientation="left">{deviationListData[0].group?.text ?? unknownGroupId}</Divider>
            <DeviationList
              deviationList={deviationListData}
              loading={loading}
              showSourceEntity={showSourceEntity}
              altTextForMultipleReferenceOfCause={altTextForMultipleReferenceOfCause}
            />
          </div>
        );
      })}
    </>
  );
};

export default DeviationListGroupedBySourceEntity;
