import React, { FC } from 'react';
import { Button, Modal, Space } from 'antd';
import { useToggle } from '../../../hooks/useToggle';
import IncomingInvoiceFormUpload from './UploadForm/IncomingInvoiceFormUpload';

const IncomingInvoiceListingActionButtons: FC = () => {
  const [isIncomingInvoiceUploadModalOpen, toggleIncomingInvoiceUploadModalOpen] = useToggle();

  return (
    <Space direction="horizontal">
      <Button onClick={toggleIncomingInvoiceUploadModalOpen} type="primary">
        Eingangsrechungen erfassen
      </Button>
      <Modal
        title="Eingangsrechungen erfassen"
        onCancel={toggleIncomingInvoiceUploadModalOpen}
        open={isIncomingInvoiceUploadModalOpen}
        destroyOnClose
        maskClosable={false}
        footer={false}
      >
        <IncomingInvoiceFormUpload onCancel={toggleIncomingInvoiceUploadModalOpen} />
      </Modal>
    </Space>
  );
};

export default IncomingInvoiceListingActionButtons;
