import React from 'react';
import { FireOutlined, HomeOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../../components/ContextSidebar/ContentWithSidebar';
import { verarbeitungPageAndMenuListTitles, verarbeitungTypes } from '../../../features/Verarbeitung/verarbeitungHelpers';
import { BookingSuggestionCreationGenerierlauf } from '../../../types';
import { generatePathToVerarbeitungBookingSuggestionCreationDetailsPage } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import VerarbeitungErrorAndWarningListing from '../../../features/Verarbeitung/shared/ErrorAndWarningListing/VerarbeitungErrorAndWarningListing';
import VerarbeitungBookingSuggestionCreation from '../../../features/Verarbeitung/BookingSuggestionCreation/VerarbeitungBookingSuggestionCreation';

const menuListBookingSuggestionCreation = (generierlauf: BookingSuggestionCreationGenerierlauf): GroupedMenueListInterface => {
  return {
    topMenuList: [
      {
        title: verarbeitungPageAndMenuListTitles.BookingSuggestionCreation,
        icon: <HomeOutlined />,
        uri: `${generatePathToVerarbeitungBookingSuggestionCreationDetailsPage(generierlauf.generierlaufId)}/entries`,
        content: () => (
          <VerarbeitungBookingSuggestionCreation
            generierlauf={generierlauf}
            title={verarbeitungPageAndMenuListTitles.BookingSuggestionCreation}
            verarbeitungType={verarbeitungTypes.BookingSuggestionCreation}
          />
        ),
      },
      {
        state: generierlauf.errorList.length > 0 || generierlauf.warningList.length > 0 ? 'error' : undefined,
        title: verarbeitungPageAndMenuListTitles.ErrorsAndWarnings,
        icon: <FireOutlined />,
        uri: `${generatePathToVerarbeitungBookingSuggestionCreationDetailsPage(generierlauf.generierlaufId)}/errors-and-warnings`,
        content: () => <VerarbeitungErrorAndWarningListing errorList={generierlauf.errorList} warningList={generierlauf.warningList} />,
      },
    ],
  };
};

export default menuListBookingSuggestionCreation;
