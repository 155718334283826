import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { DebitorSumData } from '../../op-list-debitor-types';
import pdfStyles from '../../../../styles/pdfStyles';
import { opDebitorListSummaryColumnWidthAndAlignment } from './op-debitor-table-styles';

type Props = {
  summary: DebitorSumData;
};

const OPDebitorSummary: FC<Props> = ({ summary }) => (
  <View style={[pdfStyles.row, pdfStyles.borderTopSmall, pdfStyles.textBulletin, { fontWeight: 'bold' }]}>
    <Text style={[pdfStyles.column, opDebitorListSummaryColumnWidthAndAlignment.emptyFirstCol]} />
    <Text style={[pdfStyles.column, opDebitorListSummaryColumnWidthAndAlignment.sumText]}>{summary.sumText}</Text>
    <Text style={[pdfStyles.column, opDebitorListSummaryColumnWidthAndAlignment.sumBrutto]}>{summary.sumBrutto}</Text>
    <Text style={[pdfStyles.column, opDebitorListSummaryColumnWidthAndAlignment.sumZahlungsbetrag]}>{summary.sumZahlungsbetrag}</Text>
    <Text style={[pdfStyles.column, opDebitorListSummaryColumnWidthAndAlignment.sumOffenerBetrag]}>{summary.sumOffenerBetrag}</Text>
    <Text style={[pdfStyles.column, opDebitorListSummaryColumnWidthAndAlignment.emptyLastCol]} />
  </View>
);

export default OPDebitorSummary;
