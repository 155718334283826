import React, { FC } from 'react';
import { Button, Dropdown, MenuProps, Modal } from 'antd';
import { DeleteOutlined, EllipsisOutlined, HistoryOutlined } from '@ant-design/icons';
import { ArchiveIcon } from '@radix-ui/react-icons';
import { radixActionStyles } from '../../../helpers/radixIconsStyles';
import {
  useArchiveBestandseinheitMutation,
  useDeleteBestandseinheitMutation,
  useUnarchiveBestandseinheitMutation,
} from '../gql/BestandseinheitMutations.types';
import { showSuccessMsgArchived, showSuccessMsgDelete, showSuccessMsgUnarchived } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { useGoBack } from '../../../shared/GoBack/GoBackContext';
import { pathsToObjektDetailsPage } from '../../Objekt/objektUriPaths';
import { isStatusArchived } from '../../../helpers/statusHelper';
import { Bestandseinheit } from '../../../types';

type Props = {
  objektId: string;
  bestandseinheit: Bestandseinheit;
  onAction: () => void;
  onChangeHistoryClicked: () => void;
};

const BestandseinheitCardActions: FC<Props> = ({ objektId, bestandseinheit, onAction, onChangeHistoryClicked }) => {
  const goBack = useGoBack(pathsToObjektDetailsPage(objektId).objektDetails);
  const isArchived = isStatusArchived(bestandseinheit.status);

  const [runArchive] = useArchiveBestandseinheitMutation({
    variables: {
      objektId,
      bestandseinheitId: bestandseinheit.bestandseinheitId,
    },
    onCompleted: () => {
      showSuccessMsgArchived('Bestandseinheit');
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUnarchive] = useUnarchiveBestandseinheitMutation({
    variables: {
      objektId,
      bestandseinheitId: bestandseinheit.bestandseinheitId,
    },
    onCompleted: () => {
      showSuccessMsgUnarchived('Bestandseinheit');
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runDelete] = useDeleteBestandseinheitMutation({
    variables: {
      objektId,
      bestandseinheitId: bestandseinheit.bestandseinheitId,
    },
    onCompleted: () => {
      showSuccessMsgDelete('Bestandseinheit');
      goBack();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: isArchived ? 'Reaktivieren' : 'Archivieren',
      onClick: isArchived ? showConfirmUnarchive(bestandseinheit, runUnarchive) : showConfirmArchive(bestandseinheit, runArchive),
      icon: <ArchiveIcon style={radixActionStyles} />,
      disabled: !!bestandseinheit.deletable,
    },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: onChangeHistoryClicked,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '3',
      label: 'Löschen',
      onClick: () => showConfirmDelete(bestandseinheit, runDelete),
      icon: <DeleteOutlined style={radixActionStyles} />,
      disabled: !bestandseinheit.deletable,
      danger: true,
    },
  ];

  return (
    <Dropdown menu={{ items }}>
      <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
    </Dropdown>
  );
};

const showConfirmArchive = (bestandseinheit: Bestandseinheit, runArchive: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie diese Bestandseinheit archivieren?`,
    content: `${bestandseinheit.bezeichnung}`,
    okText: 'Archivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runArchive();
    },
  });
};

const showConfirmUnarchive = (bestandseinheit: Bestandseinheit, runUnarchive: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie diese Bestandseinheitv reaktivieren?`,
    content: `${bestandseinheit.bezeichnung}`,
    okText: 'Reaktivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runUnarchive();
    },
  });
};

const showConfirmDelete = (bestandseinheit: Bestandseinheit, runDelete: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie diese Bestandseinheit löschen?',
    content: bestandseinheit.bezeichnung,
    okText: 'Löschen',
    okButtonProps: { danger: true },
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default BestandseinheitCardActions;
