import React, { FC } from 'react';
import { friendlyFormatIBAN } from 'ibantools';
import { Button, Dropdown, MenuProps, Modal, Tooltip } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExceptionOutlined,
  FileAddOutlined,
  FileDoneOutlined,
  FileSyncOutlined,
  HistoryOutlined,
} from '@ant-design/icons';
import { ArchiveIcon } from '@radix-ui/react-icons';
import { BankDetails, FirmendatenBankDetails } from '../../../types';
import { radixActionStyles } from '../../../helpers/radixIconsStyles';
import useDeleteBankDetails from './useDeleteBankDetails';
import { useToggle } from '../../../hooks/useToggle';
import ActivateOrUpdateModal from '../SepaMandat/Form/ActivateOrUpdate/ActivateOrUpdateModal';
import { handleDeactivateSubmit } from '../SepaMandat/Form/ActivateOrUpdate/handleSubmit';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { isSepaMandatStatusSigned, isSepaMandatStatusUnsigned } from '../SepaMandat/sepaMandatHelpers';
import { isStatusArchived } from '../../../helpers/statusHelper';
import { useArchiveBankDetails } from './useArchiveBankDetails';
import { useUnarchiveBankDetails } from './useUnarchiveBankDetails';
import SepaMandatCreateModal from '../SepaMandat/Form/Create/SepaMandatCreateModal';

type Props = {
  bankDetails: BankDetails | FirmendatenBankDetails;
  onAction: () => Record<string, unknown>;
  rechtstraegerId?: string;
  firmendatenId?: string;
  toggleBankDetailsUpdateDrawer: () => void;
  toggleBankDetailsChangeHistoryOpen: () => void;
  toggleSepaMandatChangeHistoryOpen: () => void;
};

const BankDetailsCardMenuDropdown: FC<Props> = ({
  rechtstraegerId,
  firmendatenId,
  bankDetails,
  onAction,
  toggleBankDetailsUpdateDrawer,
  toggleBankDetailsChangeHistoryOpen,
  toggleSepaMandatChangeHistoryOpen,
}) => {
  const {
    activeForFirmendaten: { firmendatenId: firmendatenIdForSepaMandatDactivationHandler },
  } = useFDAuthorized();

  const [isCreateModalOpen, toggleCreateModalOpen] = useToggle();
  const [isUpdateModalOpen, toggleUpdateModalOpen] = useToggle();
  const [isActivateModalOpen, toggleActivateModalOpen] = useToggle();
  const runDelete = useDeleteBankDetails(bankDetails.bankDetailsId, onAction, firmendatenId, rechtstraegerId);
  const runArchive = useArchiveBankDetails(bankDetails.bankDetailsId, onAction, firmendatenId, rechtstraegerId);
  const runUnarchive = useUnarchiveBankDetails(bankDetails.bankDetailsId, onAction, firmendatenId, rechtstraegerId);

  const rechtstraegerIdForSepaMandat = (rechtstraegerId || firmendatenId) as Required<string>;

  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      label: !bankDetails.sepaMandat ? (
        'Bearbeiten'
      ) : (
        <Tooltip title="Die Bankverbindung kann nicht bearbeitet werden, weil ein aktiver oder erstellter SEPA Mandat exisitiert.">
          Bearbeiten
        </Tooltip>
      ),
      icon: <EditOutlined />,
      disabled: !!bankDetails.sepaMandat,
      onClick: toggleBankDetailsUpdateDrawer,
    },
    {
      key: '2',
      label: 'Änderungsverlauf',
      icon: <HistoryOutlined style={radixActionStyles} />,
      onClick: toggleBankDetailsChangeHistoryOpen,
    },
    !bankDetails.sepaMandat
      ? {
          key: '3',
          label: 'SEPA Mandat anlegen',
          icon: <FileAddOutlined />,
          onClick: toggleCreateModalOpen,
        }
      : null,
    bankDetails.sepaMandat && isSepaMandatStatusUnsigned(bankDetails.sepaMandat.status.value)
      ? {
          key: '4',
          label: 'SEPA Mandat aktivieren',
          icon: <FileDoneOutlined />,
          onClick: toggleActivateModalOpen,
        }
      : null,
    ...(bankDetails.sepaMandat && isSepaMandatStatusSigned(bankDetails.sepaMandat.status.value)
      ? [
          {
            key: '5',
            label: 'SEPA Mandat deaktivieren',
            icon: <ExceptionOutlined />,
            onClick: () =>
              showConfirmDeactivate(bankDetails, () =>
                handleDeactivateSubmit(
                  firmendatenIdForSepaMandatDactivationHandler,
                  rechtstraegerIdForSepaMandat,
                  bankDetails.bankDetailsId,
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  bankDetails.sepaMandat!.sepaMandatId,
                  onAction
                )
              ),
          },
          {
            key: '6',
            label: 'SEPA Mandat bearbeiten',
            icon: <FileSyncOutlined />,
            onClick: toggleUpdateModalOpen,
          },
        ]
      : []),
    {
      key: '7',
      label: 'SEPA Mandat Änderungsverlauf',
      icon: <HistoryOutlined style={radixActionStyles} />,
      onClick: toggleSepaMandatChangeHistoryOpen,
    },
    bankDetails.deletable
      ? {
          key: '8',
          label: 'Löschen',
          onClick: () => showConfirmDelete(bankDetails, runDelete),
          icon: <DeleteOutlined />,
          danger: true,
        }
      : {
          key: '8',
          label: isStatusArchived(bankDetails.status) ? 'Reaktivieren' : 'Archivieren',
          onClick: isStatusArchived(bankDetails.status)
            ? showConfirmUnarchive(bankDetails, runUnarchive)
            : showConfirmArchive(bankDetails, runArchive),
          icon: <ArchiveIcon style={radixActionStyles} />,
        },
  ];

  return (
    <>
      <Dropdown menu={{ items: menuItems }}>
        <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
      </Dropdown>
      <SepaMandatCreateModal
        isCreateModalOpen={isCreateModalOpen}
        toggleCreateModalOpen={toggleCreateModalOpen}
        bankDetailsId={bankDetails.bankDetailsId}
        rechtstraegerIdForSepaMandat={rechtstraegerIdForSepaMandat}
        onAction={onAction}
      />
      <ActivateOrUpdateModal
        modalType="aktivate"
        open={isActivateModalOpen}
        onCancel={toggleActivateModalOpen}
        onSuccess={() => {
          toggleActivateModalOpen();
          onAction();
        }}
        bankDetails={bankDetails}
        rechtstraegerIdForSepaMandat={rechtstraegerIdForSepaMandat}
      />
      <ActivateOrUpdateModal
        modalType="update"
        open={isUpdateModalOpen}
        onCancel={toggleUpdateModalOpen}
        onSuccess={() => {
          toggleUpdateModalOpen();
          onAction();
        }}
        bankDetails={bankDetails}
        rechtstraegerIdForSepaMandat={rechtstraegerIdForSepaMandat}
      />
    </>
  );
};

const showConfirmDelete = (bankDetails: BankDetails | FirmendatenBankDetails, runDelete: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie diese Bankverbindung löschen?',
    content: `${bankDetails.bankname} - ${friendlyFormatIBAN(bankDetails.iban)}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

const showConfirmArchive = (bankDetails: BankDetails | FirmendatenBankDetails, runArchive: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie die Bankverbindung archivieren?`,
    content: `${bankDetails.bankname} - ${friendlyFormatIBAN(bankDetails.iban)}`,
    okText: 'Archivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runArchive();
    },
  });
};

const showConfirmUnarchive = (bankDetails: BankDetails | FirmendatenBankDetails, runUnarchive: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie die Bankverbindung reaktivieren?`,
    content: `${bankDetails.bankname} - ${friendlyFormatIBAN(bankDetails.iban)}`,
    okText: 'Reaktivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runUnarchive();
    },
  });
};

const showConfirmDeactivate = (bankDetails: BankDetails | FirmendatenBankDetails, runDeactivate: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie diesen SEPA Mandat deaktivieren?',
    content: `${bankDetails.sepaMandat?.sepaMandatReference}`,
    okButtonProps: { danger: true },
    okText: 'Deaktivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runDeactivate();
    },
  });
};

export default BankDetailsCardMenuDropdown;
