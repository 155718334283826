import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { MahnungLine } from '../../mahnung-types';
import pdfStyles from '../../../styles/pdfStyles';
import { columnWidthsAndAlignment } from './styles/mahnung-table-styles';

type Props = {
  mahnungLineList: MahnungLine[];
};

const MahnungLineList: FC<Props> = ({ mahnungLineList }) => (
  <View style={[pdfStyles.column, pdfStyles.borderBottomSmall, { marginTop: '2mm' }]}>
    {mahnungLineList.map((mahnungLine, index) => (
      <View style={pdfStyles.row} key={index}>
        <Text
          style={[
            pdfStyles.textNormal,
            { width: columnWidthsAndAlignment.belegnummer.width, textAlign: columnWidthsAndAlignment.belegnummer.textAlign },
          ]}
        >
          {mahnungLine.belegnummer}
        </Text>
        <Text
          style={[
            pdfStyles.textNormal,
            { width: columnWidthsAndAlignment.belegdatum.width, textAlign: columnWidthsAndAlignment.belegdatum.textAlign },
          ]}
        >
          {mahnungLine.belegdatum}
        </Text>
        <Text
          style={[
            pdfStyles.textNormal,
            { width: columnWidthsAndAlignment.rechnungsbetrag.width, textAlign: columnWidthsAndAlignment.rechnungsbetrag.textAlign },
          ]}
        >
          {mahnungLine.rechnungsbetrag}
        </Text>
        <Text
          style={[pdfStyles.textNormal, { width: columnWidthsAndAlignment.zahlung.width, textAlign: columnWidthsAndAlignment.zahlung.textAlign }]}
        >
          {mahnungLine.zahlung}
        </Text>
        <Text
          style={[
            pdfStyles.textNormal,
            { width: columnWidthsAndAlignment.restbetrag.width, textAlign: columnWidthsAndAlignment.restbetrag.textAlign },
          ]}
        >
          {mahnungLine.restbetrag}
        </Text>
        <Text
          style={[pdfStyles.textNormal, { width: columnWidthsAndAlignment.mahnstufe.width, textAlign: columnWidthsAndAlignment.mahnstufe.textAlign }]}
        >
          {mahnungLine.mahnstufe}
        </Text>
      </View>
    ))}
  </View>
);

export default MahnungLineList;
