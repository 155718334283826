import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useFilterFibuBzBuchungKzListQuery } from '../gql/FibuBuchungszeileQueries.types';
import { selectFilterOption } from '../../../helpers/selectHelper';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';

type Props = {
  rechtstraegerIdList?: string[] | null;
} & Omit<SelectProps, 'id' | 'allowClear' | 'filterOption' | 'loading' | 'placeholder' | 'size' | 'showSearch'>;
const FibuBzBuchungKZListFormSelect: FC<Props> = ({ rechtstraegerIdList, ...restProps }) => {
  const { loading, data } = useFilterFibuBzBuchungKzListQuery({
    variables: {
      rechtstraegerIdList,
    },
  });
  const list = data?.getFilterFibuBzBuchungKZList.data ?? [];

  return (
    <Select {...restProps} size="middle" id={restProps.name} loading={loading} placeholder="" allowClear showSearch filterOption={selectFilterOption}>
      {list.map((buchungKZ) => (
        <Select.Option key={buchungKZ} value={buchungKZ}>
          <DataWithShortenedText maxTextLength={48} text={buchungKZ} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default FibuBzBuchungKZListFormSelect;
