import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KsFirmendatenSelectListQueryVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
}>;

export type KsFirmendatenSelectListQuery = {
  getKSFirmendatenList?: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      firmendatenId: string;
      kundenSystemId: string;
      kurzBezeichnung: string;
      status: { description?: string | null; text: string; value: Types.FirmendatenStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  } | null;
};

export const KsFirmendatenSelectListDocument = gql`
  query KSFirmendatenSelectList($kundenSystemId: ID!) {
    getKSFirmendatenList(kundenSystemId: $kundenSystemId) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
        firmendatenId
        kundenSystemId
        kurzBezeichnung
        status {
          description
          text
          value
        }
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useKsFirmendatenSelectListQuery(
  baseOptions: Apollo.QueryHookOptions<KsFirmendatenSelectListQuery, KsFirmendatenSelectListQueryVariables> &
    ({ variables: KsFirmendatenSelectListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KsFirmendatenSelectListQuery, KsFirmendatenSelectListQueryVariables>(KsFirmendatenSelectListDocument, options);
}
export function useKsFirmendatenSelectListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KsFirmendatenSelectListQuery, KsFirmendatenSelectListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KsFirmendatenSelectListQuery, KsFirmendatenSelectListQueryVariables>(KsFirmendatenSelectListDocument, options);
}
export function useKsFirmendatenSelectListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KsFirmendatenSelectListQuery, KsFirmendatenSelectListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KsFirmendatenSelectListQuery, KsFirmendatenSelectListQueryVariables>(KsFirmendatenSelectListDocument, options);
}
export type KsFirmendatenSelectListQueryHookResult = ReturnType<typeof useKsFirmendatenSelectListQuery>;
export type KsFirmendatenSelectListLazyQueryHookResult = ReturnType<typeof useKsFirmendatenSelectListLazyQuery>;
export type KsFirmendatenSelectListSuspenseQueryHookResult = ReturnType<typeof useKsFirmendatenSelectListSuspenseQuery>;
export type KsFirmendatenSelectListQueryResult = Apollo.QueryResult<KsFirmendatenSelectListQuery, KsFirmendatenSelectListQueryVariables>;
