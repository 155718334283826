import { useQueryParams } from './useStringQueryParams';

export enum PAGINATION_QUERY_PARAM_KEY {
  CURRENT_PAGE = 'currentPage',
  PAGE_SIZE = 'pageSize',
}

export type PaginationQueryParamsProps = {
  currentPage?: string;
  pageSize?: string;
};

export const usePaginationQueryParams = (): PaginationQueryParamsProps => {
  const { currentPage, pageSize } = useQueryParams<{
    [PAGINATION_QUERY_PARAM_KEY.CURRENT_PAGE]?: string;
    [PAGINATION_QUERY_PARAM_KEY.PAGE_SIZE]?: string;
  }>();

  return { currentPage, pageSize };
};
