import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../../styles/pdfStyles';
import { Konto } from '../../../../../objektAbrechnung-types';

const OAAbrKreisKontoZwischensumme: FC<{ konto: Konto }> = ({ konto }) => (
  <View style={[pdfStyles.row, pdfStyles.tablePaddingLeftRight]}>
    <View style={[pdfStyles.column, { width: '55%' }]} />
    <View style={[pdfStyles.row, pdfStyles.borderTopSmall, { width: '45%', borderTopWidth: 2 }]}>
      <Text style={[pdfStyles.column, pdfStyles.textBulletinSmall, { width: '80%', marginTop: '2mm' }]}>{konto.betragNettoSummeBuchungenText}</Text>
      <Text style={[pdfStyles.column, pdfStyles.textBulletinSmall, { width: '20%', marginTop: '2mm', alignItems: 'flex-end', textAlign: 'right' }]}>
        {konto.betragNettoSummeBuchungen}
      </Text>
    </View>
  </View>
);
export default OAAbrKreisKontoZwischensumme;
