import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../hooks/useQueryWithPagingAndSorting';
import { FiltersFormValues, mapFormValuesToQueryParams, mapQueryParamsToFormValues } from './Filters/filtersFormMapper';
import {
  DataCarrierPainIbanListDocument,
  DataCarrierPainListDocument,
  DataCarrierPainNameListDocument,
  DataCarrierPainPaymentProposalNameListDocument,
  useDataCarrierPainListQuery,
} from '../../gql/DataCarrierQueries.types';
import { TDirectDebitOrBankWireQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import DirectDebitOrBankWireTable from './Table/DirectDebitOrBankWireTable';
import ListingFilters from './Filters/ListingFilters';
import { useOnDirectDebitOrBankWireDataChangeEvents } from './useOnDirectDebitOrBankWireDataChangeEvents';

const DirectDebitOrBankWireListing = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TDirectDebitOrBankWireQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(navigate, queryParams, paginationParams);
  };

  const { data, loading, pagination, handleTableSorting, refetch } = useQueryWithPagingAndSorting(
    useDataCarrierPainListQuery,
    {
      variables: {
        ...queryParams,
      },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: paginationParams.currentPage ? Number(paginationParams.currentPage) : DEFAULT_CURRENT,
      pageSize: paginationParams.pageSize ? Number(paginationParams.pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const dataCarrierPainList = data?.getDataCarrierPainList.data.contentList ?? [];

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: FiltersFormValues) => {
    const filters = mapFormValuesToQueryParams(values);
    updateQueryParams(navigate, filters, paginationParams);
  };

  const client = useApolloClient();
  useOnDirectDebitOrBankWireDataChangeEvents('direct-debit-or-bank-wire', async () => {
    await client.refetchQueries({
      include: [
        DataCarrierPainListDocument,
        DataCarrierPainIbanListDocument,
        DataCarrierPainNameListDocument,
        DataCarrierPainPaymentProposalNameListDocument,
      ],
    });
  });

  return (
    <>
      <Formik<FiltersFormValues> initialValues={mapQueryParamsToFormValues(queryParams)} onSubmit={onChange}>
        {(formikProps) => <ListingFilters formikProps={formikProps} queryParams={queryParams} />}
      </Formik>
      <DirectDebitOrBankWireTable
        dataCarrierPainList={dataCarrierPainList}
        loading={loading}
        pagination={pagination}
        handleTableSorting={handleTableSorting}
        onAction={refetch}
      />
    </>
  );
};

export default DirectDebitOrBankWireListing;
