import { Helmet } from 'react-helmet-async';
import { PageLayout } from '../../components/LayoutAndromeda';
import BookingFolderListing from '../../features/BookingFolder/Listing/BookingFolderListing';

const BookingFolderPage = () => {
  return (
    <>
      <Helmet>
        <title>Buchungsmappe</title>
      </Helmet>
      <PageLayout>
        <BookingFolderListing />
      </PageLayout>
    </>
  );
};

export default BookingFolderPage;
