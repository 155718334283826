import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestartZinslisteGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartZinslisteGenerierlaufMutation = {
  actionRestartZinslisteGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateZinslisteGenerierlaufMutationVariables = Types.Exact<{
  input: Types.ZinslisteGenerierlaufCreateInput;
}>;

export type CreateZinslisteGenerierlaufMutation = {
  createZinslisteGenerierlauf: {
    data: {
      generierDatum: string;
      generierlaufId: string;
      status: { text: string; value: Types.GenerierlaufStatus };
      vorschreibungsperiodeList: Array<{ jahr: number; monat: number }>;
    };
    warningList: Array<{ type: string; message: string }>;
    errorList: Array<{ type: string; message: string }>;
  };
};

export const RestartZinslisteGenerierlaufDocument = gql`
  mutation RestartZinslisteGenerierlauf($generierlaufId: ID!) {
    actionRestartZinslisteGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartZinslisteGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<RestartZinslisteGenerierlaufMutation, RestartZinslisteGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestartZinslisteGenerierlaufMutation, RestartZinslisteGenerierlaufMutationVariables>(
    RestartZinslisteGenerierlaufDocument,
    options
  );
}
export type RestartZinslisteGenerierlaufMutationHookResult = ReturnType<typeof useRestartZinslisteGenerierlaufMutation>;
export type RestartZinslisteGenerierlaufMutationResult = Apollo.MutationResult<RestartZinslisteGenerierlaufMutation>;
export type RestartZinslisteGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartZinslisteGenerierlaufMutation,
  RestartZinslisteGenerierlaufMutationVariables
>;
export const CreateZinslisteGenerierlaufDocument = gql`
  mutation CreateZinslisteGenerierlauf($input: ZinslisteGenerierlaufCreateInput!) {
    createZinslisteGenerierlauf(input: $input) {
      data {
        generierDatum
        generierlaufId
        status {
          text
          value
        }
        vorschreibungsperiodeList {
          jahr
          monat
        }
      }
      warningList {
        type
        message
      }
      errorList {
        type
        message
      }
    }
  }
`;
export function useCreateZinslisteGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateZinslisteGenerierlaufMutation, CreateZinslisteGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateZinslisteGenerierlaufMutation, CreateZinslisteGenerierlaufMutationVariables>(
    CreateZinslisteGenerierlaufDocument,
    options
  );
}
export type CreateZinslisteGenerierlaufMutationHookResult = ReturnType<typeof useCreateZinslisteGenerierlaufMutation>;
export type CreateZinslisteGenerierlaufMutationResult = Apollo.MutationResult<CreateZinslisteGenerierlaufMutation>;
export type CreateZinslisteGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  CreateZinslisteGenerierlaufMutation,
  CreateZinslisteGenerierlaufMutationVariables
>;
