import { Box, Flex } from 'rebass';
import { Collapse, Typography } from 'antd';
import React, { FC } from 'react';
import { EuroAmount } from '../../../../components/Number';
import VPosForVertragLevelList from './VPosForVertragLevelList';
import DataWithShortenedTextAndExtraInfo from '../../../../components/Helpers/DataWithShortenedTextAndExtraInfo';
import { Zinsliste } from '../../../../types';
import { StyledPanelVorschreibungsposition } from './Styled/VPosForVertragLevelListSummary.styled';
import { mapZinslisteToVPosGroupedByVertragLevelForListSummary } from './vPosForVertragLevelListMapper';

type Props = {
  zinsliste: Zinsliste;
};

const VPosForVertragLevelListSummary: FC<Props> = ({ zinsliste }) => {
  const vPosGroupedByVertragLevelForListSummaryData = mapZinslisteToVPosGroupedByVertragLevelForListSummary(zinsliste);

  return (
    <>
      <Flex p="8px" backgroundColor="#f5f5f5" paddingRight="40px">
        <Box width={['50%']}>
          <Typography.Text strong>Vertragsart</Typography.Text>
        </Box>
        <Box width={['50%']} display="flex">
          <Box width={['50%']} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/*FIXME: tooltipPlacement="topLeft" */}
            <DataWithShortenedTextAndExtraInfo text="Netto Summe" maxTextLength={8}>
              {(shortenedText) => <Typography.Text strong>{shortenedText}</Typography.Text>}
            </DataWithShortenedTextAndExtraInfo>
          </Box>
          <Box width={['50%']} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/*FIXME: tooltipPlacement="topLeft" */}
            <DataWithShortenedTextAndExtraInfo text="Brutto Summe" maxTextLength={8}>
              {(shortenedText) => <Typography.Text strong>{shortenedText}</Typography.Text>}
            </DataWithShortenedTextAndExtraInfo>
          </Box>
        </Box>
      </Flex>
      {Object.keys(vPosGroupedByVertragLevelForListSummaryData).map((level, index) => {
        const vPosForVertragsartListSummaryData = vPosGroupedByVertragLevelForListSummaryData[level];
        return (
          <Collapse expandIconPosition="end" bordered={false} key={index}>
            <StyledPanelVorschreibungsposition
              header={
                <Flex width={['100%']}>
                  <Box width={['50%']}>
                    <Typography.Text strong>{vPosForVertragsartListSummaryData.vertragLevel?.text}</Typography.Text>
                  </Box>
                  <Box width={['50%']} display="flex" textAlign="right">
                    <Box width={['50%']}>
                      <Typography.Text strong>
                        <EuroAmount value={vPosForVertragsartListSummaryData.sumNetto} />
                      </Typography.Text>
                    </Box>
                    <Box width={['50%']}>
                      <Typography.Text strong>
                        <EuroAmount value={vPosForVertragsartListSummaryData.sumBrutto} />
                      </Typography.Text>
                    </Box>
                  </Box>
                </Flex>
              }
              key={index}
            >
              <VPosForVertragLevelList vorschreibungspositionList={vPosForVertragsartListSummaryData.vorschreibungspositionList} />
            </StyledPanelVorschreibungsposition>
          </Collapse>
        );
      })}
    </>
  );
};

export default VPosForVertragLevelListSummary;
