import { FC } from 'react';
import { Tooltip } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';

const ContactPhone: FC<{ phone: string }> = ({ phone }) => (
  <Tooltip placement="top" title={phone}>
    <a href={`tel:${phone}`}>
      <PhoneOutlined />
    </a>
  </Tooltip>
);

export default ContactPhone;
