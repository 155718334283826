import React, { FC } from 'react';
import { SubAbrechnungEigentuemer } from '../../../../../types';
import { GesamtergebnisWithWrapper } from '../../../shared/Gesamtergebnis';
import SubAbrTabSaldovortragTable from './SaldovortragTable/SubAbrTabSaldovortragTable';
import { Spacer } from '../../../../../components/Grid';
import SubAbrTabBestandseinheitTable from './BestandseinheitTable/Level1/SubAbrTabBestandseinheitTable';
import SubAbrTabObjektTable from './ObjektTable/Level1/SubAbrTabObjektTable';
import InfoAbrKreisTable from '../../../shared/InfoAbrKreisTable/Level1/InfoAbrKreisTable';
import SubAbrTabOffenePostenTable from './OffenePosten/Level1/SubAbrTabOffenePostenTable';

type Props = {
  subAbrechnungId: string;
  subAbrechnungEigentuemer: SubAbrechnungEigentuemer;
  objektId: string;
};

const SubAbrTab: FC<Props> = ({ subAbrechnungEigentuemer, objektId }) => {
  return (
    <>
      <GesamtergebnisWithWrapper guthaben={subAbrechnungEigentuemer.guthaben} saldo={subAbrechnungEigentuemer.saldo} />
      <Spacer height={16} />
      <SubAbrTabSaldovortragTable subAbrechnungEigentuemer={subAbrechnungEigentuemer} />
      <Spacer height={16} />
      <SubAbrTabBestandseinheitTable subAbrechnungEigentuemer={subAbrechnungEigentuemer} />
      <Spacer height={16} />
      <SubAbrTabOffenePostenTable subAbrechnungEigentuemer={subAbrechnungEigentuemer} objektId={objektId} />
      <Spacer height={16} />
      <SubAbrTabObjektTable subAbrechnungEigentuemer={subAbrechnungEigentuemer} />
      {subAbrechnungEigentuemer.subAbrechnungEigentuemerObjekt.infoAbrechnungskreisList.length > 0 && (
        <>
          <Spacer height={16} />
          <InfoAbrKreisTable abrechnungskreisList={subAbrechnungEigentuemer.subAbrechnungEigentuemerObjekt.infoAbrechnungskreisList} />
        </>
      )}
    </>
  );
};

export default SubAbrTab;
