// UX: use func for for Inputs
const entityIsRequired = (entity: string, plural?: boolean) => `${entity} ${plural ? 'müssen' : 'muss'} angegeben werden`;

// UX: use func for for Selects/Datepicker
const entityMustBeSelected = (entity: string) => `${entity} muss ausgewählt werden`;

// UX: use func for Selects/Datepicker
const entityMustHaveSpecificCharLength = (entity: string, min: number) => `${entity} darf genau ${min} Zeichen lang sein`;

// UX: specify min/max
const entityMinCharLength = (entity: string, min: number) => `${entity} muss mindestens ${min} Zeichen lang sein`;
const entityMaxCharLength = (entity: string, max: number) => `${entity} darf maximal ${max} Zeichen lang sein`;

// UX: specify subcriterias in validation
const entityHasToBeANumber = (entity: string) => `${entity} muss eine Zahl sein`;
const entityHasToBeAnInt = (entity: string) => `${entity} muss ganzzahlig sein`;
const entityHasToBeNonNegative = (entity: string) => `${entity} darf nicht negativ sein`;

const entityMustBeSmallerThanNumber = (entity: string, maxNumber: number) => `${entity} darf nicht größer als ${maxNumber} sein`;
const entityMustBeGreaterThanNumber = (entity: string, minNumber: number) => `${entity} muss größer als ${minNumber} sein`;

export {
  entityIsRequired,
  entityMustBeSelected,
  entityMustHaveSpecificCharLength,
  entityMinCharLength,
  entityMaxCharLength,
  entityHasToBeAnInt,
  entityHasToBeNonNegative,
  entityMustBeSmallerThanNumber,
  entityMustBeGreaterThanNumber,
  entityHasToBeANumber,
};
