import { Empty } from 'antd';
import React, { FC } from 'react';
import { HeVertragOverviewListEntry } from '../../../../../types';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { useHeVertragOverviewListQuery } from '../../../../Vertrag/HeVertrag/gql/HeVertragQueries.types';
import heVertragTableColumns from './heVertragTableColumns';
import { usePaginationQueryParams } from '../../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../../hooks/useQueryWithPagingAndSorting';

type Props = {
  filterIdentifier: string;
  rechnungsAusstellerId?: string;
  vertragspartnerId?: string;
};

const HeVertragTable: FC<Props> = ({ filterIdentifier, rechnungsAusstellerId, vertragspartnerId }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useHeVertragOverviewListQuery,
    {
      variables: {
        rechnungsAusstellerId,
        vertragspartnerId,
      },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const vertragList = data?.getHeVertragOverviewList.data.contentList || [];

  return (
    <TableWithColSelector<HeVertragOverviewListEntry>
      size="small"
      loading={loading}
      pagination={pagination}
      onChange={handleTableSorting}
      columns={heVertragTableColumns}
      dataSource={vertragList}
      rowKey={(record) => record.heVertragId}
      filterIdentifier={filterIdentifier}
      locale={{
        emptyText: <Empty description={<span>Keine Verträge vorhanden</span>} />,
      }}
    />
  );
};

export default HeVertragTable;
