import React, { FC } from 'react';
import HistoryListingTable from '../../../../History/HistoryListingTable';
import { HistoryType } from '../../../../History/shared/HistoryModal';
import { useMahnPositionDeletionHistoryListQuery } from '../../../gql/MahnungQueries.types';

type Props = {
  mahnungId: string;
};

const MahnpositionDeletionHistoryListingTable: FC<Props> = ({ mahnungId }) => {
  const { data, loading } = useMahnPositionDeletionHistoryListQuery({ variables: { mahnungId } });
  const historyList = data?.getMahnPositionDeletionHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Deletion} historyList={historyList} loading={loading} filterIdPrefix="mahnposition" />;
};

export default MahnpositionDeletionHistoryListingTable;
