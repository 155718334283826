import { Space } from 'antd';
import { FC } from 'react';
import KontoWithQuery from '../../../shared/components/Konto/KontoWithQuery';
import { UstVomAufwand } from '../../../types';

type Props = {
  ustVomAufwand: UstVomAufwand;
  objektId: string;
};

const AufwandKontoList: FC<Props> = ({ ustVomAufwand, objektId }) => {
  const aufwandKontoList = ustVomAufwand.ustVomAufwandVertragList
    .map((vertrag) =>
      vertrag.repFondsKontoList.map((ustVomAufwand) => ustVomAufwand.aufwandsKontoList.map((aufwandsKonto) => aufwandsKonto.aufwandsKontoId)).flat()
    )
    .flat();
  const uniqueAufwandKontoList = Array.from(new Set(aufwandKontoList));

  return (
    <Space direction="vertical">
      {uniqueAufwandKontoList.map((kontoId) => (
        <KontoWithQuery key={kontoId} kontoId={kontoId} objektId={objektId} />
      ))}
    </Space>
  );
};

export default AufwandKontoList;
