import { FormFields } from '../../../../../helpers/formikHelper';
import { BeVertragDokumentenversandInput } from '../../../../../types';
import { DokumentenversandEntryFragment } from '../gql/BeVertragDokumentenVersandFragments.types';
import { HAUPT_TAG_TEXT } from '../../../../../components/Tag/tagHelpers';

export type VersandFormValues = {
  emailAktiv?: boolean;
  emailContactId?: string;
  postAktiv?: boolean;
  postAddressId?: string;
  rechtstraegerId: string;
};

export const versandFormFields: FormFields<VersandFormValues> = {
  emailAktiv: 'emailAktiv',
  emailContactId: 'emailContactId',
  postAktiv: 'postAktiv',
  postAddressId: 'postAddressId',
  rechtstraegerId: 'rechtstraegerId',
};

export const versandFormInitialValues = (dokumentenversand?: DokumentenversandEntryFragment): VersandFormValues => ({
  emailAktiv: dokumentenversand?.emailAktiv ?? true,
  emailContactId: dokumentenversand?.emailHaupt ? HAUPT_TAG_TEXT : dokumentenversand?.emailContact?.contactId,
  postAktiv: dokumentenversand?.postAktiv ?? false,
  postAddressId: dokumentenversand?.postHaupt ? HAUPT_TAG_TEXT : dokumentenversand?.postAddress?.addressId,
  rechtstraegerId: dokumentenversand?.rechtstraeger.rechtstraegerId ?? '',
});

export const mapFormValuesToDokumentenversand = (values: VersandFormValues): BeVertragDokumentenversandInput => ({
  emailAktiv: values.emailAktiv ?? false,
  emailHaupt: values.emailContactId === HAUPT_TAG_TEXT,
  emailContactId: values.emailContactId === HAUPT_TAG_TEXT ? null : values.emailContactId,
  postAddressId: values.postAddressId === HAUPT_TAG_TEXT ? null : values.postAddressId,
  postAktiv: values.postAktiv ?? false,
  postHaupt: values.postAddressId === HAUPT_TAG_TEXT,
  rechtstraegerId: values.rechtstraegerId,
});
