import React, { FC } from 'react';
import { Box, Flex } from 'rebass';
import { Divider } from 'antd';
import { TDeviation } from '../deviation.model';
import { DeviationStyledListItem } from '../Styled/Deviation.style';
import DeviationChangeTypeCol from './Col/DeviationChangeTypeCol';
import DeviationAltNeuRowTypeCol from './Col/DeviationAltNeuRowTypeCol';
import DeviationColumns from './Col/DeviationColumns';
import DeviationLinkList from './Col/DeviationLinkList';

type Props = {
  deviation: TDeviation;
  showSourceEntity?: boolean;
  altTextForMultipleReferenceOfCause?: string;
};

const Deviation: FC<Props> = ({ deviation, showSourceEntity, altTextForMultipleReferenceOfCause }) => {
  const { columns } = deviation;
  return (
    <DeviationStyledListItem>
      <Flex
        flexDirection="row"
        width="100%"
        paddingLeft="40px"
        paddingRight="8px"
        paddingY="8px"
        justifyContent="space-between"
        alignItems="center"
        bg={showSourceEntity ? '#FAFAFA' : '#FFF'}
      >
        {!showSourceEntity ? (
          <>
            <DeviationChangeTypeCol type={columns[0].cells[0].changeType} />
            <Divider type="vertical" style={{ height: '80px' }} />
          </>
        ) : null}
        {deviation.linkList && deviation.linkList.length > 0 && (
          <>
            <DeviationLinkList linkList={deviation.linkList} />
            <Divider type="vertical" style={{ height: '80px' }} />
          </>
        )}

        <DeviationAltNeuRowTypeCol cellsCol={columns[0].cells} />
        <Box marginLeft="8px" />
        <DeviationColumns columns={columns} altTextForMultipleReferenceOfCause={altTextForMultipleReferenceOfCause} />
      </Flex>
    </DeviationStyledListItem>
  );
};

export default Deviation;
