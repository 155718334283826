import React, { FC } from 'react';
import { Space } from 'antd';
import { FormikProps } from 'formik';
import { DatePicker, Input, InputNumber } from 'formik-antd';
import { FormFields } from '../../../../../../helpers/formikHelper';
import { hausbetreuungCreateFormFields, HausbetreuungCreateFormValues } from '../Create/hausbetreuungCreateFormMapper';
import RechtstraegerSelect from '../../../../../../shared/Rechtstraeger/RechtstraegerSelect';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../../../components/DatePicker/DatePicker_formikAntD';
import KuendigungfristSelect from '../../../../../../shared/components/Kuendigungsfrist/KuendigungfristSelect';
import KuendigungsfristJeweilsZumSelect from '../../../../../../shared/components/KuendigungsfristJeweilsZum/KuendigungsfristJeweilsZumSelect';
import { RechtstraegerType } from '../../../../../../types';
import FirmaContactPersonSelect from '../../../../../../shared/components/Firma/FirmaContactPersonSelect';
import { hausbetreuungUpdateFormFields, HausbetreuungUpdateFormValues } from '../Update/hausbetreuungUpdateFormMapper';
import DatenTabColumnsLayout from '../../shared/DatenTabColumnsLayout';
import FormItemWithFieldHelp from '../../../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../../../FieldHelp/useGetFieldHelpText';

type Props = {
  formFields: FormFields<HausbetreuungCreateFormValues | HausbetreuungUpdateFormValues>;
  formikProps: FormikProps<HausbetreuungCreateFormValues> | FormikProps<HausbetreuungUpdateFormValues>;
};

const HausbetreuungDatenTab: FC<Props> = ({ formFields, formikProps }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'ObjektInfoFeldHausbetreuung'>('ObjektInfoFeldHausbetreuung');

  const rechtstraegerId = formikProps.values.rechtstraegerId;
  const rechtstraegerType = formikProps.values.rechtstraegerType;

  return (
    <DatenTabColumnsLayout
      leftColumn={
        <>
          <FormItemWithFieldHelp
            name={formFields.beauftragungsgrund}
            label="Beauftragt für"
            fieldHelp={getFieldHelpText('ObjektInfoFeldHausbetreuung.beauftragungsgrund')}
          >
            <Input id={formFields.beauftragungsgrund} name={formFields.beauftragungsgrund} size="small" />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.rechtstraegerId}
            label="Vertragspartner"
            fieldHelp={getFieldHelpText('ObjektInfoFeldHausbetreuung.rechtstraegerId')}
          >
            <RechtstraegerSelect
              name={formFields.rechtstraegerId}
              typeList={[RechtstraegerType.Firma, RechtstraegerType.NatuerlichePerson]}
              onTypeChange={(rechtstraeger) => {
                formikProps.setFieldValue(hausbetreuungCreateFormFields.rechtstraegerType, rechtstraeger?.type.value);
                if (rechtstraeger?.type.value !== RechtstraegerType.Firma) {
                  formikProps.setFieldValue(hausbetreuungCreateFormFields.contactPersonId, undefined);
                }
              }}
              onNewItemCreated={(rechtstraegerId) => formikProps.setFieldValue(hausbetreuungUpdateFormFields.rechtstraegerId, rechtstraegerId)}
              onNewItemCreateSelected={() => formikProps.setFieldValue(hausbetreuungUpdateFormFields.rechtstraegerId, '')}
            />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.contactPersonId}
            label="Ansprechpartner"
            fieldHelp={getFieldHelpText('ObjektInfoFeldHausbetreuung.contactPersonId')}
          >
            <FirmaContactPersonSelect
              name={formFields.contactPersonId}
              rechtstraegerId={rechtstraegerType === RechtstraegerType.Firma ? rechtstraegerId : undefined}
              disabled={rechtstraegerType !== RechtstraegerType.Firma}
            />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp name={formFields.beginn} label="Beginn" fieldHelp={getFieldHelpText('ObjektInfoFeldHausbetreuung.beginn')}>
            <DatePicker size="small" id={formFields.beginn} name={formFields.beginn} style={{ width: '100%' }} format={DATE_DISPLAY_FORMAT_DEFAULT} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp name={formFields.ende} label="Ende" fieldHelp={getFieldHelpText('ObjektInfoFeldHausbetreuung.ende')}>
            <DatePicker size="small" id={formFields.ende} name={formFields.ende} style={{ width: '100%' }} format={DATE_DISPLAY_FORMAT_DEFAULT} />
          </FormItemWithFieldHelp>
        </>
      }
      rightColumn={
        <>
          <Space align="start">
            <FormItemWithFieldHelp
              name={formFields.kuendigungsfristDauer}
              label="Kündigungsdauer"
              fieldHelp={getFieldHelpText('ObjektInfoFeldHausbetreuung.kuendigungsfristDauer')}
            >
              <InputNumber name={formFields.kuendigungsfristDauer} size="small" min={0} style={{ width: '100%' }} />
            </FormItemWithFieldHelp>
            <FormItemWithFieldHelp
              name={formFields.kuendigungsfrist}
              label="Kündigungsfrist"
              fieldHelp={getFieldHelpText('ObjektInfoFeldHausbetreuung.kuendigungsfrist')}
            >
              <KuendigungfristSelect name={formFields.kuendigungsfrist} />
            </FormItemWithFieldHelp>
          </Space>
          <FormItemWithFieldHelp
            name={formFields.kuendigungsfristJeweilsZum}
            label="Jeweils zum"
            fieldHelp={getFieldHelpText('ObjektInfoFeldHausbetreuung.kuendigungsfristJeweilsZum')}
          >
            <KuendigungsfristJeweilsZumSelect name={formFields.kuendigungsfristJeweilsZum} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.vertragsdaten}
            label="Vertragsdaten"
            fieldHelp={getFieldHelpText('ObjektInfoFeldHausbetreuung.vertragsdaten')}
          >
            <Input id={formFields.vertragsdaten} name={formFields.vertragsdaten} size="small" />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.wertsicherung}
            label="Wertsicherung"
            fieldHelp={getFieldHelpText('ObjektInfoFeldHausbetreuung.wertsicherung')}
          >
            <Input id={formFields.wertsicherung} name={formFields.wertsicherung} size="small" />
          </FormItemWithFieldHelp>
        </>
      }
    />
  );
};

export default HausbetreuungDatenTab;
