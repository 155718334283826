import { FormikProps } from 'formik';
import { getFirstnameFields, getLastnameFields, getTitelFields } from '../../../../shared/components/Person/personFormPartHelper';
import { URL_KURZBEZEICHNUNG_VORSCHLAG_NATUERLICHE_PERSON } from '../../../../shared/api/ApiPaths';
import getKurzBezeichnungVorschlagText from '../../../../shared/api/KurzBezeichnungVorschlagApi';
import { NatuerlichePersonFormValues } from './natuerlichePersonFormMapper';

export const getKurzBezeichnungVorschlagFieldsToBeDebounced = (formikProps: FormikProps<NatuerlichePersonFormValues>) => ({
  ...getFirstnameFields(formikProps),
  ...getLastnameFields(formikProps),
  ...getTitelFields(formikProps),
});

export const getNatuerlichePersonKurzBezeichnungVorschlag = (kurzBezeichnungVorschlagFields: NatuerlichePersonFormValues) =>
  getKurzBezeichnungVorschlagText(URL_KURZBEZEICHNUNG_VORSCHLAG_NATUERLICHE_PERSON, kurzBezeichnungVorschlagFields);
