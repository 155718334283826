import React from 'react';
import { CopyOutlined, EuroCircleOutlined, SignatureOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../components/ContextSidebar/ContentWithSidebar';
import { FileInfoType, SubAdministration, Vertragsart } from '../../types';
import DocumentTab from '../../features/Document/DocumentTab';
import { generatePathToSubAdministrationDetailsPage } from '../../features/SubAdministration/subAdministrationUriPaths';
import WeAndMietVertragTable from '../../features/Rechtstraeger/Rolle/Vertrag/WeAndMietVertragTable/WeAndMietVertragTable';

const menuListSubAdministration = (objektId: string, subAdministration: SubAdministration, onAction: () => void): GroupedMenueListInterface => ({
  topMenuList: [
    {
      title: 'Auszahlungen',
      icon: <EuroCircleOutlined />,
      uri: `${generatePathToSubAdministrationDetailsPage(objektId, subAdministration.subAdministrationId)}/auszahlungen`,
      content: () => <>Umsetzung - eigene Story „BE+FE: Akonto WE-Auszahlungen in Subverwaltungs Contextseite wie bei HE-Vertrag umsetzen</>,
    },
    {
      title: 'WE Mietverträge',
      icon: <SignatureOutlined />,
      uri: `${generatePathToSubAdministrationDetailsPage(objektId, subAdministration.subAdministrationId)}/we-mietvertraege`,
      content: () => (
        <WeAndMietVertragTable
          rechnungsAusstellerId={subAdministration.eigentuemerId}
          vertragsartList={[Vertragsart.WegMieter, Vertragsart.WegPauschalmieter, Vertragsart.WegLeerstehung]}
          filterIdentifier="sub-administration-we-subverwaltungen-table"
        />
      ),
    },
    {
      title: 'Dokumente',
      icon: <CopyOutlined />,
      uri: `${generatePathToSubAdministrationDetailsPage(objektId, subAdministration.subAdministrationId)}/documents`,
      content: (props) => (
        <DocumentTab objektId={objektId} typeList={[FileInfoType.SubAbrechnungObjekt, FileInfoType.SubAbrechnungBestandseinheit]} {...props} />
      ),
    },
  ],
});

export default menuListSubAdministration;
