import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IncomingInvoiceAusstellerListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IncomingInvoiceAusstellerListQuery = {
  getIncomingInvoiceAusstellerList: {
    data: Array<{ kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type IncomingInvoiceAusstellerBankDetailsListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IncomingInvoiceAusstellerBankDetailsListQuery = {
  getIncomingInvoiceAusstellerBankDetailsList: {
    data: Array<{ accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type IncomingInvoiceKontoListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IncomingInvoiceKontoListQuery = {
  getIncomingInvoiceKontoList: {
    data: Array<{ bezeichnung: string; kontoId: string; nummer: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type IncomingInvoicePayeeListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IncomingInvoicePayeeListQuery = {
  getIncomingInvoicePayeeList: {
    data: Array<{ kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type IncomingInvoicePayeeBankDetailsListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IncomingInvoicePayeeBankDetailsListQuery = {
  getIncomingInvoicePayeeBankDetailsList: {
    data: Array<{ accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type IncomingInvoiceKundennummerListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IncomingInvoiceKundennummerListQuery = {
  getIncomingInvoiceKundennummerList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type IncomingInvoicePaymentReferenceTextListQueryVariables = Types.Exact<{
  paymentReferenceText?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type IncomingInvoicePaymentReferenceTextListQuery = {
  getIncomingInvoicePaymentReferenceTextList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type IncomingInvoicePurposeOfUseTextListQueryVariables = Types.Exact<{
  purposeOfUseText?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type IncomingInvoicePurposeOfUseTextListQuery = {
  getIncomingInvoicePurposeOfUseTextList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type IncomingInvoiceSepaMandateReferenceListQueryVariables = Types.Exact<{
  sepaMandateReference?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type IncomingInvoiceSepaMandateReferenceListQuery = {
  getIncomingInvoiceSepaMandateReferenceList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type IncomingInvoiceInvoiceNumberListQueryVariables = Types.Exact<{
  invoiceNumber?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type IncomingInvoiceInvoiceNumberListQuery = {
  getIncomingInvoiceInvoiceNumberList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const IncomingInvoiceAusstellerListDocument = gql`
  query IncomingInvoiceAusstellerList {
    getIncomingInvoiceAusstellerList {
      data {
        kurzBezeichnung
        rechtstraegerId
        type {
          text
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useIncomingInvoiceAusstellerListQuery(
  baseOptions?: Apollo.QueryHookOptions<IncomingInvoiceAusstellerListQuery, IncomingInvoiceAusstellerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IncomingInvoiceAusstellerListQuery, IncomingInvoiceAusstellerListQueryVariables>(
    IncomingInvoiceAusstellerListDocument,
    options
  );
}
export function useIncomingInvoiceAusstellerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IncomingInvoiceAusstellerListQuery, IncomingInvoiceAusstellerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IncomingInvoiceAusstellerListQuery, IncomingInvoiceAusstellerListQueryVariables>(
    IncomingInvoiceAusstellerListDocument,
    options
  );
}
export function useIncomingInvoiceAusstellerListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IncomingInvoiceAusstellerListQuery, IncomingInvoiceAusstellerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IncomingInvoiceAusstellerListQuery, IncomingInvoiceAusstellerListQueryVariables>(
    IncomingInvoiceAusstellerListDocument,
    options
  );
}
export type IncomingInvoiceAusstellerListQueryHookResult = ReturnType<typeof useIncomingInvoiceAusstellerListQuery>;
export type IncomingInvoiceAusstellerListLazyQueryHookResult = ReturnType<typeof useIncomingInvoiceAusstellerListLazyQuery>;
export type IncomingInvoiceAusstellerListSuspenseQueryHookResult = ReturnType<typeof useIncomingInvoiceAusstellerListSuspenseQuery>;
export type IncomingInvoiceAusstellerListQueryResult = Apollo.QueryResult<
  IncomingInvoiceAusstellerListQuery,
  IncomingInvoiceAusstellerListQueryVariables
>;
export const IncomingInvoiceAusstellerBankDetailsListDocument = gql`
  query IncomingInvoiceAusstellerBankDetailsList {
    getIncomingInvoiceAusstellerBankDetailsList {
      data {
        accountHolder
        bankDetailsId
        bankname
        bic
        iban
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useIncomingInvoiceAusstellerBankDetailsListQuery(
  baseOptions?: Apollo.QueryHookOptions<IncomingInvoiceAusstellerBankDetailsListQuery, IncomingInvoiceAusstellerBankDetailsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IncomingInvoiceAusstellerBankDetailsListQuery, IncomingInvoiceAusstellerBankDetailsListQueryVariables>(
    IncomingInvoiceAusstellerBankDetailsListDocument,
    options
  );
}
export function useIncomingInvoiceAusstellerBankDetailsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IncomingInvoiceAusstellerBankDetailsListQuery, IncomingInvoiceAusstellerBankDetailsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IncomingInvoiceAusstellerBankDetailsListQuery, IncomingInvoiceAusstellerBankDetailsListQueryVariables>(
    IncomingInvoiceAusstellerBankDetailsListDocument,
    options
  );
}
export function useIncomingInvoiceAusstellerBankDetailsListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IncomingInvoiceAusstellerBankDetailsListQuery, IncomingInvoiceAusstellerBankDetailsListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IncomingInvoiceAusstellerBankDetailsListQuery, IncomingInvoiceAusstellerBankDetailsListQueryVariables>(
    IncomingInvoiceAusstellerBankDetailsListDocument,
    options
  );
}
export type IncomingInvoiceAusstellerBankDetailsListQueryHookResult = ReturnType<typeof useIncomingInvoiceAusstellerBankDetailsListQuery>;
export type IncomingInvoiceAusstellerBankDetailsListLazyQueryHookResult = ReturnType<typeof useIncomingInvoiceAusstellerBankDetailsListLazyQuery>;
export type IncomingInvoiceAusstellerBankDetailsListSuspenseQueryHookResult = ReturnType<
  typeof useIncomingInvoiceAusstellerBankDetailsListSuspenseQuery
>;
export type IncomingInvoiceAusstellerBankDetailsListQueryResult = Apollo.QueryResult<
  IncomingInvoiceAusstellerBankDetailsListQuery,
  IncomingInvoiceAusstellerBankDetailsListQueryVariables
>;
export const IncomingInvoiceKontoListDocument = gql`
  query IncomingInvoiceKontoList {
    getIncomingInvoiceKontoList {
      data {
        bezeichnung
        kontoId
        nummer
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useIncomingInvoiceKontoListQuery(
  baseOptions?: Apollo.QueryHookOptions<IncomingInvoiceKontoListQuery, IncomingInvoiceKontoListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IncomingInvoiceKontoListQuery, IncomingInvoiceKontoListQueryVariables>(IncomingInvoiceKontoListDocument, options);
}
export function useIncomingInvoiceKontoListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IncomingInvoiceKontoListQuery, IncomingInvoiceKontoListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IncomingInvoiceKontoListQuery, IncomingInvoiceKontoListQueryVariables>(IncomingInvoiceKontoListDocument, options);
}
export function useIncomingInvoiceKontoListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IncomingInvoiceKontoListQuery, IncomingInvoiceKontoListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IncomingInvoiceKontoListQuery, IncomingInvoiceKontoListQueryVariables>(IncomingInvoiceKontoListDocument, options);
}
export type IncomingInvoiceKontoListQueryHookResult = ReturnType<typeof useIncomingInvoiceKontoListQuery>;
export type IncomingInvoiceKontoListLazyQueryHookResult = ReturnType<typeof useIncomingInvoiceKontoListLazyQuery>;
export type IncomingInvoiceKontoListSuspenseQueryHookResult = ReturnType<typeof useIncomingInvoiceKontoListSuspenseQuery>;
export type IncomingInvoiceKontoListQueryResult = Apollo.QueryResult<IncomingInvoiceKontoListQuery, IncomingInvoiceKontoListQueryVariables>;
export const IncomingInvoicePayeeListDocument = gql`
  query IncomingInvoicePayeeList {
    getIncomingInvoicePayeeList {
      data {
        kurzBezeichnung
        rechtstraegerId
        type {
          text
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useIncomingInvoicePayeeListQuery(
  baseOptions?: Apollo.QueryHookOptions<IncomingInvoicePayeeListQuery, IncomingInvoicePayeeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IncomingInvoicePayeeListQuery, IncomingInvoicePayeeListQueryVariables>(IncomingInvoicePayeeListDocument, options);
}
export function useIncomingInvoicePayeeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IncomingInvoicePayeeListQuery, IncomingInvoicePayeeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IncomingInvoicePayeeListQuery, IncomingInvoicePayeeListQueryVariables>(IncomingInvoicePayeeListDocument, options);
}
export function useIncomingInvoicePayeeListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IncomingInvoicePayeeListQuery, IncomingInvoicePayeeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IncomingInvoicePayeeListQuery, IncomingInvoicePayeeListQueryVariables>(IncomingInvoicePayeeListDocument, options);
}
export type IncomingInvoicePayeeListQueryHookResult = ReturnType<typeof useIncomingInvoicePayeeListQuery>;
export type IncomingInvoicePayeeListLazyQueryHookResult = ReturnType<typeof useIncomingInvoicePayeeListLazyQuery>;
export type IncomingInvoicePayeeListSuspenseQueryHookResult = ReturnType<typeof useIncomingInvoicePayeeListSuspenseQuery>;
export type IncomingInvoicePayeeListQueryResult = Apollo.QueryResult<IncomingInvoicePayeeListQuery, IncomingInvoicePayeeListQueryVariables>;
export const IncomingInvoicePayeeBankDetailsListDocument = gql`
  query IncomingInvoicePayeeBankDetailsList {
    getIncomingInvoicePayeeBankDetailsList {
      data {
        accountHolder
        bankDetailsId
        bankname
        bic
        iban
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useIncomingInvoicePayeeBankDetailsListQuery(
  baseOptions?: Apollo.QueryHookOptions<IncomingInvoicePayeeBankDetailsListQuery, IncomingInvoicePayeeBankDetailsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IncomingInvoicePayeeBankDetailsListQuery, IncomingInvoicePayeeBankDetailsListQueryVariables>(
    IncomingInvoicePayeeBankDetailsListDocument,
    options
  );
}
export function useIncomingInvoicePayeeBankDetailsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IncomingInvoicePayeeBankDetailsListQuery, IncomingInvoicePayeeBankDetailsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IncomingInvoicePayeeBankDetailsListQuery, IncomingInvoicePayeeBankDetailsListQueryVariables>(
    IncomingInvoicePayeeBankDetailsListDocument,
    options
  );
}
export function useIncomingInvoicePayeeBankDetailsListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IncomingInvoicePayeeBankDetailsListQuery, IncomingInvoicePayeeBankDetailsListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IncomingInvoicePayeeBankDetailsListQuery, IncomingInvoicePayeeBankDetailsListQueryVariables>(
    IncomingInvoicePayeeBankDetailsListDocument,
    options
  );
}
export type IncomingInvoicePayeeBankDetailsListQueryHookResult = ReturnType<typeof useIncomingInvoicePayeeBankDetailsListQuery>;
export type IncomingInvoicePayeeBankDetailsListLazyQueryHookResult = ReturnType<typeof useIncomingInvoicePayeeBankDetailsListLazyQuery>;
export type IncomingInvoicePayeeBankDetailsListSuspenseQueryHookResult = ReturnType<typeof useIncomingInvoicePayeeBankDetailsListSuspenseQuery>;
export type IncomingInvoicePayeeBankDetailsListQueryResult = Apollo.QueryResult<
  IncomingInvoicePayeeBankDetailsListQuery,
  IncomingInvoicePayeeBankDetailsListQueryVariables
>;
export const IncomingInvoiceKundennummerListDocument = gql`
  query IncomingInvoiceKundennummerList {
    getIncomingInvoiceKundennummerList {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useIncomingInvoiceKundennummerListQuery(
  baseOptions?: Apollo.QueryHookOptions<IncomingInvoiceKundennummerListQuery, IncomingInvoiceKundennummerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IncomingInvoiceKundennummerListQuery, IncomingInvoiceKundennummerListQueryVariables>(
    IncomingInvoiceKundennummerListDocument,
    options
  );
}
export function useIncomingInvoiceKundennummerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IncomingInvoiceKundennummerListQuery, IncomingInvoiceKundennummerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IncomingInvoiceKundennummerListQuery, IncomingInvoiceKundennummerListQueryVariables>(
    IncomingInvoiceKundennummerListDocument,
    options
  );
}
export function useIncomingInvoiceKundennummerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IncomingInvoiceKundennummerListQuery, IncomingInvoiceKundennummerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IncomingInvoiceKundennummerListQuery, IncomingInvoiceKundennummerListQueryVariables>(
    IncomingInvoiceKundennummerListDocument,
    options
  );
}
export type IncomingInvoiceKundennummerListQueryHookResult = ReturnType<typeof useIncomingInvoiceKundennummerListQuery>;
export type IncomingInvoiceKundennummerListLazyQueryHookResult = ReturnType<typeof useIncomingInvoiceKundennummerListLazyQuery>;
export type IncomingInvoiceKundennummerListSuspenseQueryHookResult = ReturnType<typeof useIncomingInvoiceKundennummerListSuspenseQuery>;
export type IncomingInvoiceKundennummerListQueryResult = Apollo.QueryResult<
  IncomingInvoiceKundennummerListQuery,
  IncomingInvoiceKundennummerListQueryVariables
>;
export const IncomingInvoicePaymentReferenceTextListDocument = gql`
  query IncomingInvoicePaymentReferenceTextList($paymentReferenceText: String) {
    getIncomingInvoicePaymentReferenceTextList(paymentReferenceText: $paymentReferenceText) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useIncomingInvoicePaymentReferenceTextListQuery(
  baseOptions?: Apollo.QueryHookOptions<IncomingInvoicePaymentReferenceTextListQuery, IncomingInvoicePaymentReferenceTextListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IncomingInvoicePaymentReferenceTextListQuery, IncomingInvoicePaymentReferenceTextListQueryVariables>(
    IncomingInvoicePaymentReferenceTextListDocument,
    options
  );
}
export function useIncomingInvoicePaymentReferenceTextListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IncomingInvoicePaymentReferenceTextListQuery, IncomingInvoicePaymentReferenceTextListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IncomingInvoicePaymentReferenceTextListQuery, IncomingInvoicePaymentReferenceTextListQueryVariables>(
    IncomingInvoicePaymentReferenceTextListDocument,
    options
  );
}
export function useIncomingInvoicePaymentReferenceTextListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IncomingInvoicePaymentReferenceTextListQuery, IncomingInvoicePaymentReferenceTextListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IncomingInvoicePaymentReferenceTextListQuery, IncomingInvoicePaymentReferenceTextListQueryVariables>(
    IncomingInvoicePaymentReferenceTextListDocument,
    options
  );
}
export type IncomingInvoicePaymentReferenceTextListQueryHookResult = ReturnType<typeof useIncomingInvoicePaymentReferenceTextListQuery>;
export type IncomingInvoicePaymentReferenceTextListLazyQueryHookResult = ReturnType<typeof useIncomingInvoicePaymentReferenceTextListLazyQuery>;
export type IncomingInvoicePaymentReferenceTextListSuspenseQueryHookResult = ReturnType<
  typeof useIncomingInvoicePaymentReferenceTextListSuspenseQuery
>;
export type IncomingInvoicePaymentReferenceTextListQueryResult = Apollo.QueryResult<
  IncomingInvoicePaymentReferenceTextListQuery,
  IncomingInvoicePaymentReferenceTextListQueryVariables
>;
export const IncomingInvoicePurposeOfUseTextListDocument = gql`
  query IncomingInvoicePurposeOfUseTextList($purposeOfUseText: String) {
    getIncomingInvoicePurposeOfUseTextList(purposeOfUseText: $purposeOfUseText) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useIncomingInvoicePurposeOfUseTextListQuery(
  baseOptions?: Apollo.QueryHookOptions<IncomingInvoicePurposeOfUseTextListQuery, IncomingInvoicePurposeOfUseTextListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IncomingInvoicePurposeOfUseTextListQuery, IncomingInvoicePurposeOfUseTextListQueryVariables>(
    IncomingInvoicePurposeOfUseTextListDocument,
    options
  );
}
export function useIncomingInvoicePurposeOfUseTextListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IncomingInvoicePurposeOfUseTextListQuery, IncomingInvoicePurposeOfUseTextListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IncomingInvoicePurposeOfUseTextListQuery, IncomingInvoicePurposeOfUseTextListQueryVariables>(
    IncomingInvoicePurposeOfUseTextListDocument,
    options
  );
}
export function useIncomingInvoicePurposeOfUseTextListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IncomingInvoicePurposeOfUseTextListQuery, IncomingInvoicePurposeOfUseTextListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IncomingInvoicePurposeOfUseTextListQuery, IncomingInvoicePurposeOfUseTextListQueryVariables>(
    IncomingInvoicePurposeOfUseTextListDocument,
    options
  );
}
export type IncomingInvoicePurposeOfUseTextListQueryHookResult = ReturnType<typeof useIncomingInvoicePurposeOfUseTextListQuery>;
export type IncomingInvoicePurposeOfUseTextListLazyQueryHookResult = ReturnType<typeof useIncomingInvoicePurposeOfUseTextListLazyQuery>;
export type IncomingInvoicePurposeOfUseTextListSuspenseQueryHookResult = ReturnType<typeof useIncomingInvoicePurposeOfUseTextListSuspenseQuery>;
export type IncomingInvoicePurposeOfUseTextListQueryResult = Apollo.QueryResult<
  IncomingInvoicePurposeOfUseTextListQuery,
  IncomingInvoicePurposeOfUseTextListQueryVariables
>;
export const IncomingInvoiceSepaMandateReferenceListDocument = gql`
  query IncomingInvoiceSepaMandateReferenceList($sepaMandateReference: String) {
    getIncomingInvoiceSepaMandateReferenceList(sepaMandateReference: $sepaMandateReference) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useIncomingInvoiceSepaMandateReferenceListQuery(
  baseOptions?: Apollo.QueryHookOptions<IncomingInvoiceSepaMandateReferenceListQuery, IncomingInvoiceSepaMandateReferenceListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IncomingInvoiceSepaMandateReferenceListQuery, IncomingInvoiceSepaMandateReferenceListQueryVariables>(
    IncomingInvoiceSepaMandateReferenceListDocument,
    options
  );
}
export function useIncomingInvoiceSepaMandateReferenceListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IncomingInvoiceSepaMandateReferenceListQuery, IncomingInvoiceSepaMandateReferenceListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IncomingInvoiceSepaMandateReferenceListQuery, IncomingInvoiceSepaMandateReferenceListQueryVariables>(
    IncomingInvoiceSepaMandateReferenceListDocument,
    options
  );
}
export function useIncomingInvoiceSepaMandateReferenceListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IncomingInvoiceSepaMandateReferenceListQuery, IncomingInvoiceSepaMandateReferenceListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IncomingInvoiceSepaMandateReferenceListQuery, IncomingInvoiceSepaMandateReferenceListQueryVariables>(
    IncomingInvoiceSepaMandateReferenceListDocument,
    options
  );
}
export type IncomingInvoiceSepaMandateReferenceListQueryHookResult = ReturnType<typeof useIncomingInvoiceSepaMandateReferenceListQuery>;
export type IncomingInvoiceSepaMandateReferenceListLazyQueryHookResult = ReturnType<typeof useIncomingInvoiceSepaMandateReferenceListLazyQuery>;
export type IncomingInvoiceSepaMandateReferenceListSuspenseQueryHookResult = ReturnType<
  typeof useIncomingInvoiceSepaMandateReferenceListSuspenseQuery
>;
export type IncomingInvoiceSepaMandateReferenceListQueryResult = Apollo.QueryResult<
  IncomingInvoiceSepaMandateReferenceListQuery,
  IncomingInvoiceSepaMandateReferenceListQueryVariables
>;
export const IncomingInvoiceInvoiceNumberListDocument = gql`
  query IncomingInvoiceInvoiceNumberList($invoiceNumber: String) {
    getIncomingInvoiceInvoiceNumberList(invoiceNumber: $invoiceNumber) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useIncomingInvoiceInvoiceNumberListQuery(
  baseOptions?: Apollo.QueryHookOptions<IncomingInvoiceInvoiceNumberListQuery, IncomingInvoiceInvoiceNumberListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IncomingInvoiceInvoiceNumberListQuery, IncomingInvoiceInvoiceNumberListQueryVariables>(
    IncomingInvoiceInvoiceNumberListDocument,
    options
  );
}
export function useIncomingInvoiceInvoiceNumberListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IncomingInvoiceInvoiceNumberListQuery, IncomingInvoiceInvoiceNumberListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IncomingInvoiceInvoiceNumberListQuery, IncomingInvoiceInvoiceNumberListQueryVariables>(
    IncomingInvoiceInvoiceNumberListDocument,
    options
  );
}
export function useIncomingInvoiceInvoiceNumberListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IncomingInvoiceInvoiceNumberListQuery, IncomingInvoiceInvoiceNumberListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IncomingInvoiceInvoiceNumberListQuery, IncomingInvoiceInvoiceNumberListQueryVariables>(
    IncomingInvoiceInvoiceNumberListDocument,
    options
  );
}
export type IncomingInvoiceInvoiceNumberListQueryHookResult = ReturnType<typeof useIncomingInvoiceInvoiceNumberListQuery>;
export type IncomingInvoiceInvoiceNumberListLazyQueryHookResult = ReturnType<typeof useIncomingInvoiceInvoiceNumberListLazyQuery>;
export type IncomingInvoiceInvoiceNumberListSuspenseQueryHookResult = ReturnType<typeof useIncomingInvoiceInvoiceNumberListSuspenseQuery>;
export type IncomingInvoiceInvoiceNumberListQueryResult = Apollo.QueryResult<
  IncomingInvoiceInvoiceNumberListQuery,
  IncomingInvoiceInvoiceNumberListQueryVariables
>;
