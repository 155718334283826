import { FC } from 'react';
import TableWithAlignedCols from '../../../../../../components/Table/TableWithAlignedCols/TableWithAlignedCols';
import heAbrHeVertragTableColumns from './heAbrHeVertragTableColumns';
import { HeAbrAbrechnungskreisList, HeAbrechnungHeVertrag } from '../../../../../../types';
import heAbrHeVertragAbrKreisRow from './heAbrHeVertragAbrKreisRow';

type Props = {
  abrechnung: HeAbrechnungHeVertrag;
};

const HeAbrHeVertragTable: FC<Props> = ({ abrechnung }) => {
  const { abrechnungszeitraumVon, abrechnungszeitraumBis, objekt, buchungskreisId } = abrechnung;
  const list: HeAbrAbrechnungskreisList[] = [
    abrechnung.einnahmen as HeAbrAbrechnungskreisList,
    abrechnung.ausgaben as HeAbrAbrechnungskreisList,
    abrechnung.vorsteuerkuerzung as HeAbrAbrechnungskreisList,
    abrechnung.eigentuemerverrechnung as HeAbrAbrechnungskreisList,
  ].filter((abrechnung) => !!abrechnung);

  return (
    <TableWithAlignedCols<HeAbrAbrechnungskreisList>
      tableId={HE_ABR_ABRECHNUNGSKREIS_LIST_TABLE_ID}
      dataSource={list}
      columns={heAbrHeVertragTableColumns()}
      expandedRow={() => heAbrHeVertragAbrKreisRow(objekt.objektId, abrechnungszeitraumVon, abrechnungszeitraumBis, buchungskreisId)}
      rowKey={(data) => data.abrechnungskreisType.text}
    />
  );
};

export const HE_ABR_ABRECHNUNGSKREIS_LIST_TABLE_ID = 'HeAbrAbrechnungskreisListTable';

export default HeAbrHeVertragTable;
