import { FibuBelegSymbol, FibuBuchungszeile, FibuBuchungType, SollHaben } from '../../../../types';
import { isKontoTypePersonenkonto, isKontoVerwendungBank } from '../../../Kontenplan/Form/kontoFormHelpers';

// ---- buchungType ----
export const isFibuBuchungTypeZahlung = (buchungType?: FibuBuchungType) => buchungType === FibuBuchungType.Zahlung;
export const isFibuBuchungTypeRuecklastschrift = (buchungType?: FibuBuchungType) => buchungType === FibuBuchungType.Ruecklastschrift;
export const isFibuBuchungTypeSpesen = (buchungType?: FibuBuchungType) => buchungType === FibuBuchungType.Spesen;
export const isFibuBuchungTypeZahlungOrRuecklastschrift = (buchungType?: FibuBuchungType) =>
  isFibuBuchungTypeZahlung(buchungType) || isFibuBuchungTypeRuecklastschrift(buchungType);
export const isFibuBuchungTypeAusgangsRechnung = (buchungType?: FibuBuchungType) => buchungType === FibuBuchungType.AusgangsRechnung;

// ---- BelegSymbol ----
export const isFibuBuchungBelegSymbolStorno = (belegSymbol: FibuBelegSymbol) => belegSymbol?.toLowerCase().includes('storno'.toLowerCase());
export const isFibuBuchungBelegSymbolVorschreibung = (belegSymbol?: FibuBelegSymbol | null) => belegSymbol === FibuBelegSymbol.Vorschreibung;
export const isFibuBuchungBelegSymbolEinzelrechnung = (belegSymbol?: FibuBelegSymbol | null) => belegSymbol === FibuBelegSymbol.Einzelrechnung;
export const isFibuBuchungBelegSymbolSpesenRuecklastschrift = (belegSymbol?: FibuBelegSymbol | null) =>
  belegSymbol === FibuBelegSymbol.SpesenRuecklastschrift;

// ---- Buchungszeile ----
export const isBuchungszeileKontoTypeSoll = (sollHaben: SollHaben) => sollHaben === SollHaben.Soll;

export const hasBookingStornoBuchungszeileListEntries = (buchungszeileList: FibuBuchungszeile[], belegSymbol: FibuBelegSymbol) =>
  isFibuBuchungBelegSymbolStorno(belegSymbol) || buchungszeileList.some((bz) => bz.storniert);

const isNotStorniertAndNotStornoBelegSymbol = (buchungszeile: FibuBuchungszeile) =>
  !buchungszeile.storniert && !isFibuBuchungBelegSymbolStorno(buchungszeile.buchungsKopf.belegSymbol.value);

export const getBuchungszeileForCurrentBookingWithBankVerwendung = (buchungszeileList: FibuBuchungszeile[]) =>
  // there has always at least 2 entries in the buchungszeileList and there is always one bank entry => filtering and returning the first entry is safe and gives always one entry (no undefined)
  buchungszeileList
    .filter(isNotStorniertAndNotStornoBelegSymbol)
    .filter((buchungszeile) => isKontoVerwendungBank(buchungszeile.konto.verwendung?.value))[0];

export const getBuchungszeileForCurrentBookingWithPersonenkonto = (buchungszeileList: FibuBuchungszeile[]) =>
  // buchungszeileList can have entry with Perosonenkonto so using .find() is ok as it can return undefined
  buchungszeileList.filter(isNotStorniertAndNotStornoBelegSymbol).find((buchungszeile) => isKontoTypePersonenkonto(buchungszeile.konto.type.value));
