import { FC } from 'react';
import HistoryListingTable from '../../../../../History/HistoryListingTable';
import { HistoryType } from '../../../../../History/shared/HistoryModal';
import { useBeVertragVorschreibungspositionWertDeletionHistoryListQuery } from '../gql/BeVertragVorschreibungspositionQueries.types';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  vorschreibungspositionId: string;
};

const VertragVorschreibungspositionWertDeletionHistoryListingTable: FC<Props> = ({
  objektId,
  bestandseinheitId,
  vertragId,
  vorschreibungspositionId,
}) => {
  const { data, loading } = useBeVertragVorschreibungspositionWertDeletionHistoryListQuery({
    variables: { objektId, bestandseinheitId, vertragId, vorschreibungspositionId },
  });
  const historyList = data?.getBeVertragVorschreibungspositionWertDeletionHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Deletion} historyList={historyList} loading={loading} filterIdPrefix="be-vertrag-vpos-wert" />;
};

export default VertragVorschreibungspositionWertDeletionHistoryListingTable;
