import { FC } from 'react';
import TextbausteinActionButtonAndDrawer from './TextbausteinActionButtonAndDrawer';
import TextbausteinTable from './Table/TextbausteinTable';
import { useTextbausteinListQuery } from './gql/TextbausteinQueries.types';

const TextbausteinListing: FC = () => {
  const { data, loading, refetch } = useTextbausteinListQuery();
  const textbausteinList = data?.getTextbausteinList.data ?? [];

  return (
    <>
      <TextbausteinActionButtonAndDrawer refetch={refetch} />
      <TextbausteinTable textbausteinList={textbausteinList} loading={loading} refetch={refetch} />
    </>
  );
};

export default TextbausteinListing;
