import { Button, Card, Col, Drawer, Dropdown } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { EllipsisOutlined } from '@ant-design/icons';
import { useToggle } from '../../../hooks/useToggle';

const NotYetTitledCard = ({ contact }) => {
  const [isCollapsed, onCollapse] = useToggle();

  const items = [{ key: '1', label: 'Bearbeiten', onClick: onCollapse }];

  return (
    <>
      {contact.rechnungseinstellungen ? (
        <Col>
          <Card
            style={{ maxWidth: '360px' }}
            size="small"
            title="Rechnungseinstellungen"
            extra={
              <Dropdown menu={{ items }}>
                <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
              </Dropdown>
            }
          >
            <p>Bezeichnung Rechnungsaussteller: {contact.rechnungseinstellungen.rechnungsAusstellerBez}</p>
          </Card>
        </Col>
      ) : null}
      <Drawer title="Allgemeine Einstellungen bearbeiten" width={720} open={isCollapsed} onClose={onCollapse} destroyOnClose>
        <p>@TODO inputs</p>
      </Drawer>
    </>
  );
};

NotYetTitledCard.propTypes = {
  contact: PropTypes.object.isRequired,
};

export default NotYetTitledCard;
