import React, { FC } from 'react';
import { Avatar, Tooltip } from 'antd';
import { AvatarSize } from 'antd/lib/avatar/AvatarContext';
import avatarColorForFileInfoType from './avatarColorForFileInfoType';
import fileInfoTypeAbkuerzungForAvatar from './fileInfoTypeAbkuerzungForAvatar';

type Props = {
  fileType: { text: string; value: string; description?: string | null };
  size?: AvatarSize | undefined;
  backgroundColor?: string | null;
};
const AvatarBasedOnFileInfoTypeWithTooltip: FC<Props> = ({ fileType, size, backgroundColor }) => (
  <Tooltip title={fileType.text}>
    <Avatar shape="square" style={{ backgroundColor: backgroundColor ?? avatarColorForFileInfoType(fileType.value) }} size={size}>
      {fileInfoTypeAbkuerzungForAvatar(fileType.value)}
    </Avatar>
  </Tooltip>
);

export default AvatarBasedOnFileInfoTypeWithTooltip;
