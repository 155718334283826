import React from 'react';
import { FireOutlined, HomeOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../../components/ContextSidebar/ContentWithSidebar';
import { verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungErrorAndWarningListing from '../../../features/Verarbeitung/shared/ErrorAndWarningListing/VerarbeitungErrorAndWarningListing';
import { SubAbrechnungGenerierlauf } from '../../../types';
import { generatePathToVerarbeitungSubAbrechnungDetailsPage } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import VerarbeitungSubAbrechnung from '../../../features/Verarbeitung/SubAbrechnung/VerarbeitungSubAbrechnung';

const menuListSubAbrechnung = (generierlauf: SubAbrechnungGenerierlauf): GroupedMenueListInterface => {
  return {
    topMenuList: [
      {
        title: verarbeitungPageAndMenuListTitles.SubAbrechnung,
        icon: <HomeOutlined />,
        uri: `${generatePathToVerarbeitungSubAbrechnungDetailsPage(generierlauf.generierlaufId)}/entries`,
        content: () => <VerarbeitungSubAbrechnung generierlauf={generierlauf} />,
      },
      {
        state: generierlauf.errorList.length > 0 || generierlauf.warningList.length > 0 ? 'error' : undefined,
        title: verarbeitungPageAndMenuListTitles.ErrorsAndWarnings,
        icon: <FireOutlined />,
        uri: `${generatePathToVerarbeitungSubAbrechnungDetailsPage(generierlauf.generierlaufId)}/errors-and-warnings`,
        content: () => <VerarbeitungErrorAndWarningListing errorList={generierlauf.errorList} warningList={generierlauf.warningList} />,
      },
    ],
  };
};

export default menuListSubAbrechnung;
