import React, { FC } from 'react';
import { Form, Select, Typography } from 'antd';
import { StyledSpaceForGeneraInfoBlock } from '../../../shared/styled/StyledGeneralInfoBlock.style';
import { FibuBelegSymbolTuple, FibuBuchungTypeTuple, PaymentFibuBuchungStatus } from '../../../../../types';
import BuchungskreisSelect from './Selects/BuchungskreisSelect';
import { capitalizeString } from '../../../../../helpers/stringHelper';

type Props = {
  belegSymbol: FibuBelegSymbolTuple;
  buchungType: FibuBuchungTypeTuple;
  buchungskreisRechtstraegerId: string;
};

const GeneralInfoBlockContent: FC<Props> = ({ belegSymbol, buchungType, buchungskreisRechtstraegerId }) => {
  return (
    // Use Form and Form.Item with label in order to match the layout of GeneralInfoBlock in the Booking Suggestion mask
    <Form layout="vertical">
      <StyledSpaceForGeneraInfoBlock align="start" direction="vertical">
        <Form.Item label={<Typography.Text>Buchungskreis</Typography.Text>}>
          <BuchungskreisSelect buchungskreisRechtstraegerId={buchungskreisRechtstraegerId} />
        </Form.Item>
        <Form.Item label={<Typography.Text>Belegsymbol</Typography.Text>}>
          <Select disabled defaultValue={belegSymbol.text} style={{ width: '100%' }} size="small" options={[belegSymbol]} />
        </Form.Item>
        <Form.Item label={<Typography.Text>Buchungstyp</Typography.Text>}>
          <Select disabled defaultValue={buchungType.text} style={{ width: '100%' }} size="small" options={[buchungType]} />
        </Form.Item>

        <Form.Item label={<Typography.Text>Status</Typography.Text>}>
          <Typography.Text>{capitalizeString(PaymentFibuBuchungStatus.Verbucht)}</Typography.Text>
        </Form.Item>
      </StyledSpaceForGeneraInfoBlock>
    </Form>
  );
};

export default GeneralInfoBlockContent;
