import React, { FC } from 'react';
import { Typography } from 'antd';
import { Spacer } from '../../../components/Grid';
import PaymentProposalVerarbeitungEntryListing from './EntryListing/PaymentProposalVerarbeitungEntryListing';
import { PaymentProposalGenerierlauf } from '../verarbeitungHelpers';
import { OrderGenerierlaufType } from '../../../types';

type Props = {
  generierlauf: PaymentProposalGenerierlauf;
  generierlaufType: OrderGenerierlaufType;
  verarbeitungTitle: string;
  verarbeitungType: string;
};

const VerarbeitungPaymentProposal: FC<Props> = ({ generierlauf, generierlaufType, verarbeitungTitle, verarbeitungType }) => (
  <>
    <Typography.Title level={4}>{verarbeitungTitle}</Typography.Title>
    <Spacer />
    <PaymentProposalVerarbeitungEntryListing generierlauf={generierlauf} generierlaufType={generierlaufType} verarbeitungType={verarbeitungType} />
  </>
);

export default VerarbeitungPaymentProposal;
