import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { GenerierlaufAsyncStepFieldsFragmentDoc } from '../shared/generierlaufFragments.types';
import {
  TopAbrechnungFieldsFragmentDoc,
  TopAbrechnungOverviewFieldsFragmentDoc,
} from '../../../Abrechnung/BkOrWe/Top/gql/TopAbrechnungFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TopAbrechnungGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type TopAbrechnungGenerierlaufQuery = {
  getTopAbrechnungGenerierlauf: {
    data: {
      countFehler: number;
      countNichtVerarbeitet: number;
      countVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      fromInclusive: string;
      generierDatum: string;
      generierlaufId: string;
      toInclusive: string;
      errorList: Array<{ message: string; type: string }>;
      status: { value: Types.GenerierlaufStatus; text: string };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type TopAbrechnungGenerierlaufEntryListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  bestandseinheitIdList?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>
  >;
  rechnungsausstellerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechnungsempfaengerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  order?: Types.InputMaybe<Types.TopAbrechnungGenerierlaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type TopAbrechnungGenerierlaufEntryListQuery = {
  getTopAbrechnungGenerierlaufEntryList: {
    data: {
      contentList: Array<{
        currentStep?: number | null;
        generierlaufEntryId: string;
        infoMessage?: string | null;
        objektAbrechnungId: string;
        objektId: string;
        objektKurzBezeichnung: string;
        retry: boolean;
        stepCount?: number | null;
        topAbrechnungDeleted: boolean;
        topAbrechnungDeletedBy?: string | null;
        topAbrechnungDeletedByMitarbeiterId?: string | null;
        topAbrechnungDeletedTs?: string | null;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { value: Types.GenerierlaufEntryExitCode; text: string } | null;
        stepList: Array<{
          designation: string;
          receivingApplication?: string | null;
          stepId: string;
          type?: string | null;
          status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
        }>;
        topAbrechnung?: {
          aufwand: number;
          bestandseinheitBezeichnung?: string | null;
          bestandseinheitId: string;
          bezeichnung: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          dueDate?: string | null;
          einleitungsText: string;
          einnahmen: number;
          ergebnisText: string;
          fileId?: string | null;
          fromInclusive: string;
          guthaben: boolean;
          objektId: string;
          objektKurzBezeichnung: string;
          rueckstandBestandnehmer: number;
          saldo: number;
          schlussText: string;
          toInclusive: string;
          topAbrechnungId: string;
          abrechnungskreisList: Array<{
            abrechnungskreisId: string;
            aufteilungsschluesselBezeichnung?: string | null;
            aufwand: number;
            aufwandGesamt: number;
            aufwandProzent?: number | null;
            bezeichnung: string;
            brutto: number;
            einnahmen: number;
            netto: number;
            steuersatz: number;
            ust: number;
            ustKategorieEntryId: string;
            ustKategorieId: string;
            ustKategorieVersionId: string;
            abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
            aufwandAufteilungList: Array<{
              aufteilungsschluesselBezeichnung: string;
              summe: number;
              kontoList: Array<{ bezeichnung: string; kontoId: string; summe: number }>;
              zeitscheibeAufwandList: Array<{
                anteilAufwand: number;
                aufwand: number;
                beAufteilungsschluesselWert?: number | null;
                bisInklusive: string;
                prozentAufteilungsschluesselAnteil: number;
                sumAufteilungsschluesselWert: number;
                vonInklusive: string;
              }>;
            }>;
            einnahmenBuchungszeileList: Array<{
              auftragId?: string | null;
              belegDatum: string;
              belegFileId?: string | null;
              belegId?: string | null;
              belegnummer: string;
              betrag: number;
              buchungId: string;
              buchungskreisId: string;
              dueDate: string;
              kontoBezeichnung: string;
              kontoId: string;
              kontoNummer: string;
              offenerBetrag: number;
              text: string;
              buchungskreisRechtstraeger: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              sollHaben: { text: string; value: Types.SollHaben };
              vertragspartner: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
            }>;
            ustKategorieStatus: { description?: string | null; text: string; value: Types.UstKategorieStatus };
            ustVomAufwandAnteilList: Array<{
              anteilNetto: number;
              basis: number;
              belastung: number;
              summe: number;
              ustVomAufwand: number;
              ustVomAufwandSteuersatz: number;
              grundlage: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand };
            }>;
          }>;
          dueDateDisplayOption?: { month?: number | null; type: { text: string; value: Types.DueDateDisplayOptionType } } | null;
          dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
          infoAbrechnungskreisList: Array<{
            abrechnungsdefinitionId: string;
            abrechnungsdefinitionVersionId: string;
            abrechnungskreisId: string;
            bezeichnung: string;
            saldo: number;
            sumAufwand: number;
            vorsaldo: number;
            aufwand: {
              summe: number;
              kontoList: Array<{
                bezeichnung: string;
                kontoId: string;
                summe: number;
                buchungszeileList: Array<{
                  auftragId?: string | null;
                  belegDatum?: string | null;
                  belegFileId?: string | null;
                  belegId: string;
                  belegnummer?: string | null;
                  betrag: number;
                  buchungIdList: Array<string>;
                  dueDate?: string | null;
                  standingInvoicePartialAmountId?: string | null;
                  text: string;
                  umbuchung?: boolean | null;
                  umgebucht?: boolean | null;
                  buchungskreisRechtstraeger: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  };
                  sollHaben: { text: string; value: Types.SollHaben };
                  vertragspartner?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                }>;
              }>;
            };
            erloes: {
              summe: number;
              kontoList: Array<{
                bezeichnung: string;
                kontoId: string;
                summe: number;
                buchungszeileList: Array<{
                  auftragId?: string | null;
                  belegDatum?: string | null;
                  belegFileId?: string | null;
                  belegId: string;
                  belegnummer?: string | null;
                  betrag: number;
                  buchungIdList: Array<string>;
                  dueDate?: string | null;
                  standingInvoicePartialAmountId?: string | null;
                  text: string;
                  umbuchung?: boolean | null;
                  umgebucht?: boolean | null;
                  buchungskreisRechtstraeger: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  };
                  sollHaben: { text: string; value: Types.SollHaben };
                  vertragspartner?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                }>;
              }>;
            };
          }>;
          paymentMethod: { text: string; value: Types.PaymentMethod };
          rechnungsAussteller: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          status: { description?: string | null; text: string; value: Types.TopAbrechnungStatus };
          type: { text: string; value: Types.AbrechnungType };
          verrechnungsart: { text: string; value: Types.Verrechnungsart };
          vertragspartner: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        topAbrechnungOverview?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          fileId?: string | null;
          fromInclusive: string;
          objektId: string;
          objektKurzBezeichnung: string;
          toInclusive: string;
          errorList: Array<{ message: string; type: string }>;
          topAbrechnungList: Array<{
            aufwand: number;
            bestandseinheitBezeichnung?: string | null;
            bestandseinheitId: string;
            bezeichnung: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            dueDate?: string | null;
            einleitungsText: string;
            einnahmen: number;
            ergebnisText: string;
            fileId?: string | null;
            fromInclusive: string;
            guthaben: boolean;
            objektId: string;
            objektKurzBezeichnung: string;
            rueckstandBestandnehmer: number;
            saldo: number;
            schlussText: string;
            toInclusive: string;
            topAbrechnungId: string;
            abrechnungskreisList: Array<{
              abrechnungskreisId: string;
              aufteilungsschluesselBezeichnung?: string | null;
              aufwand: number;
              aufwandGesamt: number;
              aufwandProzent?: number | null;
              bezeichnung: string;
              brutto: number;
              einnahmen: number;
              netto: number;
              steuersatz: number;
              ust: number;
              ustKategorieEntryId: string;
              ustKategorieId: string;
              ustKategorieVersionId: string;
              abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
              aufwandAufteilungList: Array<{
                aufteilungsschluesselBezeichnung: string;
                summe: number;
                kontoList: Array<{ bezeichnung: string; kontoId: string; summe: number }>;
                zeitscheibeAufwandList: Array<{
                  anteilAufwand: number;
                  aufwand: number;
                  beAufteilungsschluesselWert?: number | null;
                  bisInklusive: string;
                  prozentAufteilungsschluesselAnteil: number;
                  sumAufteilungsschluesselWert: number;
                  vonInklusive: string;
                }>;
              }>;
              einnahmenBuchungszeileList: Array<{
                auftragId?: string | null;
                belegDatum: string;
                belegFileId?: string | null;
                belegId?: string | null;
                belegnummer: string;
                betrag: number;
                buchungId: string;
                buchungskreisId: string;
                dueDate: string;
                kontoBezeichnung: string;
                kontoId: string;
                kontoNummer: string;
                offenerBetrag: number;
                text: string;
                buchungskreisRechtstraeger: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                sollHaben: { text: string; value: Types.SollHaben };
                vertragspartner: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
              }>;
              ustKategorieStatus: { description?: string | null; text: string; value: Types.UstKategorieStatus };
              ustVomAufwandAnteilList: Array<{
                anteilNetto: number;
                basis: number;
                belastung: number;
                summe: number;
                ustVomAufwand: number;
                ustVomAufwandSteuersatz: number;
                grundlage: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand };
              }>;
            }>;
            dueDateDisplayOption?: { month?: number | null; type: { text: string; value: Types.DueDateDisplayOptionType } } | null;
            dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
            infoAbrechnungskreisList: Array<{
              abrechnungsdefinitionId: string;
              abrechnungsdefinitionVersionId: string;
              abrechnungskreisId: string;
              bezeichnung: string;
              saldo: number;
              sumAufwand: number;
              vorsaldo: number;
              aufwand: {
                summe: number;
                kontoList: Array<{
                  bezeichnung: string;
                  kontoId: string;
                  summe: number;
                  buchungszeileList: Array<{
                    auftragId?: string | null;
                    belegDatum?: string | null;
                    belegFileId?: string | null;
                    belegId: string;
                    belegnummer?: string | null;
                    betrag: number;
                    buchungIdList: Array<string>;
                    dueDate?: string | null;
                    standingInvoicePartialAmountId?: string | null;
                    text: string;
                    umbuchung?: boolean | null;
                    umgebucht?: boolean | null;
                    buchungskreisRechtstraeger: {
                      createTs: string;
                      createdBy: string;
                      createdByMitarbeiterId?: string | null;
                      kurzBezeichnung: string;
                      rechtstraegerId: string;
                      status: { text: string; value: Types.RechtstraegerStatus };
                      type: { text: string; value: Types.RechtstraegerType };
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    };
                    sollHaben: { text: string; value: Types.SollHaben };
                    vertragspartner?: {
                      createTs: string;
                      createdBy: string;
                      createdByMitarbeiterId?: string | null;
                      kurzBezeichnung: string;
                      rechtstraegerId: string;
                      status: { text: string; value: Types.RechtstraegerStatus };
                      type: { text: string; value: Types.RechtstraegerType };
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    } | null;
                  }>;
                }>;
              };
              erloes: {
                summe: number;
                kontoList: Array<{
                  bezeichnung: string;
                  kontoId: string;
                  summe: number;
                  buchungszeileList: Array<{
                    auftragId?: string | null;
                    belegDatum?: string | null;
                    belegFileId?: string | null;
                    belegId: string;
                    belegnummer?: string | null;
                    betrag: number;
                    buchungIdList: Array<string>;
                    dueDate?: string | null;
                    standingInvoicePartialAmountId?: string | null;
                    text: string;
                    umbuchung?: boolean | null;
                    umgebucht?: boolean | null;
                    buchungskreisRechtstraeger: {
                      createTs: string;
                      createdBy: string;
                      createdByMitarbeiterId?: string | null;
                      kurzBezeichnung: string;
                      rechtstraegerId: string;
                      status: { text: string; value: Types.RechtstraegerStatus };
                      type: { text: string; value: Types.RechtstraegerType };
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    };
                    sollHaben: { text: string; value: Types.SollHaben };
                    vertragspartner?: {
                      createTs: string;
                      createdBy: string;
                      createdByMitarbeiterId?: string | null;
                      kurzBezeichnung: string;
                      rechtstraegerId: string;
                      status: { text: string; value: Types.RechtstraegerStatus };
                      type: { text: string; value: Types.RechtstraegerType };
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    } | null;
                  }>;
                }>;
              };
            }>;
            paymentMethod: { text: string; value: Types.PaymentMethod };
            rechnungsAussteller: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            status: { description?: string | null; text: string; value: Types.TopAbrechnungStatus };
            type: { text: string; value: Types.AbrechnungType };
            verrechnungsart: { text: string; value: Types.Verrechnungsart };
            vertragspartner: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          zahlungsrueckstandList?: Array<{
            offenerBetrag: number;
            letzteBuchungAm?: string | null;
            bestandseinheit: {
              bestandseinheitId: string;
              bezeichnung: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              objektId: string;
              status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            rechtstraeger: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
          }> | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { size: number; number: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const TopAbrechnungGenerierlaufDocument = gql`
  query TopAbrechnungGenerierlauf($generierlaufId: ID!) {
    getTopAbrechnungGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countNichtVerarbeitet
        countVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        fromInclusive
        errorList {
          message
          type
        }
        generierDatum
        generierlaufId
        status {
          value
          text
        }
        toInclusive
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useTopAbrechnungGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<TopAbrechnungGenerierlaufQuery, TopAbrechnungGenerierlaufQueryVariables> &
    ({ variables: TopAbrechnungGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopAbrechnungGenerierlaufQuery, TopAbrechnungGenerierlaufQueryVariables>(TopAbrechnungGenerierlaufDocument, options);
}
export function useTopAbrechnungGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TopAbrechnungGenerierlaufQuery, TopAbrechnungGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TopAbrechnungGenerierlaufQuery, TopAbrechnungGenerierlaufQueryVariables>(TopAbrechnungGenerierlaufDocument, options);
}
export function useTopAbrechnungGenerierlaufSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TopAbrechnungGenerierlaufQuery, TopAbrechnungGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TopAbrechnungGenerierlaufQuery, TopAbrechnungGenerierlaufQueryVariables>(TopAbrechnungGenerierlaufDocument, options);
}
export type TopAbrechnungGenerierlaufQueryHookResult = ReturnType<typeof useTopAbrechnungGenerierlaufQuery>;
export type TopAbrechnungGenerierlaufLazyQueryHookResult = ReturnType<typeof useTopAbrechnungGenerierlaufLazyQuery>;
export type TopAbrechnungGenerierlaufSuspenseQueryHookResult = ReturnType<typeof useTopAbrechnungGenerierlaufSuspenseQuery>;
export type TopAbrechnungGenerierlaufQueryResult = Apollo.QueryResult<TopAbrechnungGenerierlaufQuery, TopAbrechnungGenerierlaufQueryVariables>;
export const TopAbrechnungGenerierlaufEntryListDocument = gql`
  query TopAbrechnungGenerierlaufEntryList(
    $generierlaufId: ID!
    $objektId: String
    $bestandseinheitIdList: [String]
    $rechnungsausstellerId: String
    $rechnungsempfaengerId: String
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $order: TopAbrechnungGenerierlaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getTopAbrechnungGenerierlaufEntryList(
      generierlaufId: $generierlaufId
      objektId: $objektId
      bestandseinheitIdList: $bestandseinheitIdList
      rechnungsausstellerId: $rechnungsausstellerId
      rechnungsempfaengerId: $rechnungsempfaengerId
      exitCodeList: $exitCodeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          currentStep
          errorList {
            message
            type
          }
          exitCode {
            value
            text
          }
          generierlaufEntryId
          infoMessage
          objektAbrechnungId
          objektId
          objektKurzBezeichnung
          retry
          stepList {
            ...GenerierlaufAsyncStepFields
          }
          stepCount
          topAbrechnung {
            ...TopAbrechnungFields
          }
          topAbrechnungDeleted
          topAbrechnungDeletedBy
          topAbrechnungDeletedByMitarbeiterId
          topAbrechnungDeletedTs
          topAbrechnungOverview {
            ...TopAbrechnungOverviewFields
          }
          warningList {
            message
            type
          }
        }
        page {
          size
          number
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${GenerierlaufAsyncStepFieldsFragmentDoc}
  ${TopAbrechnungFieldsFragmentDoc}
  ${TopAbrechnungOverviewFieldsFragmentDoc}
`;
export function useTopAbrechnungGenerierlaufEntryListQuery(
  baseOptions: Apollo.QueryHookOptions<TopAbrechnungGenerierlaufEntryListQuery, TopAbrechnungGenerierlaufEntryListQueryVariables> &
    ({ variables: TopAbrechnungGenerierlaufEntryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopAbrechnungGenerierlaufEntryListQuery, TopAbrechnungGenerierlaufEntryListQueryVariables>(
    TopAbrechnungGenerierlaufEntryListDocument,
    options
  );
}
export function useTopAbrechnungGenerierlaufEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TopAbrechnungGenerierlaufEntryListQuery, TopAbrechnungGenerierlaufEntryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TopAbrechnungGenerierlaufEntryListQuery, TopAbrechnungGenerierlaufEntryListQueryVariables>(
    TopAbrechnungGenerierlaufEntryListDocument,
    options
  );
}
export function useTopAbrechnungGenerierlaufEntryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TopAbrechnungGenerierlaufEntryListQuery, TopAbrechnungGenerierlaufEntryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TopAbrechnungGenerierlaufEntryListQuery, TopAbrechnungGenerierlaufEntryListQueryVariables>(
    TopAbrechnungGenerierlaufEntryListDocument,
    options
  );
}
export type TopAbrechnungGenerierlaufEntryListQueryHookResult = ReturnType<typeof useTopAbrechnungGenerierlaufEntryListQuery>;
export type TopAbrechnungGenerierlaufEntryListLazyQueryHookResult = ReturnType<typeof useTopAbrechnungGenerierlaufEntryListLazyQuery>;
export type TopAbrechnungGenerierlaufEntryListSuspenseQueryHookResult = ReturnType<typeof useTopAbrechnungGenerierlaufEntryListSuspenseQuery>;
export type TopAbrechnungGenerierlaufEntryListQueryResult = Apollo.QueryResult<
  TopAbrechnungGenerierlaufEntryListQuery,
  TopAbrechnungGenerierlaufEntryListQueryVariables
>;
