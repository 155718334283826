import React, { FC } from 'react';
import { Button, Dropdown, MenuProps } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { styleForIconAndButton } from './ContactListItemStyleHelpers';

type Props = {
  menuItems: MenuProps['items'];
};

const ContactListItemDropdown: FC<Props> = ({ menuItems }) => (
  <div style={{ padding: '8px 0', ...styleForIconAndButton }}>
    <Dropdown menu={{ items: menuItems }}>
      <Button data-testid="card-menu-dropdown" icon={<EllipsisOutlined />} shape="circle" size="small" />
    </Dropdown>
  </div>
);

export default ContactListItemDropdown;
