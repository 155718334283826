import * as Yup from 'yup';
import { formattedDecimalType } from '../../../components/Input-antd';
import {
  entityHasToBeAnInt,
  entityIsRequired,
  entityMaxCharLength,
  entityMustBeSelected,
  entityMustHaveSpecificCharLength,
} from '../../../components/message/validationMsg';
import { bestandseinheitCreateFormFields } from '../Form/Create/bestandseinheitCreateFormMapper';
import { bestandseinheitUpdateFormFields } from '../Form/Update/bestandseinheitUpdateFormMapper';

/* eslint-disable no-template-curly-in-string */
export const bestandseinheitCreateFormValidationSchema = Yup.object().shape({
  [bestandseinheitCreateFormFields.bezeichnung]: Yup.string().required(entityIsRequired('Top')).max(30, entityMaxCharLength('Top', 30)),
  [bestandseinheitCreateFormFields.geschoss]: Yup.string().max(30, entityMaxCharLength('Geschoss', 30)),
  [bestandseinheitCreateFormFields.stiege]: Yup.string().max(30, entityMaxCharLength('Stiege', 30)),
  [bestandseinheitCreateFormFields.bauteil]: Yup.string().max(30, entityMaxCharLength('Bauteil', 30)),
  [bestandseinheitCreateFormFields.nutzungsValidFrom]: Yup.string().required(entityIsRequired('Gültig ab')),
  [bestandseinheitCreateFormFields.nutzflaeche]: formattedDecimalType('Nutzfläche muss eine Nummer sein'),
  [bestandseinheitCreateFormFields.nutzungsArt]: Yup.string().required(entityMustBeSelected('Nutzungsart')),
  [bestandseinheitCreateFormFields.nutzwert]: formattedDecimalType().integer(entityHasToBeAnInt('Nutzwert')),
  [bestandseinheitCreateFormFields.steuerpflichtValidFrom]: Yup.string().required(entityIsRequired('Gültig ab')),

  [bestandseinheitCreateFormFields.addressId]: Yup.string()
    .required(entityIsRequired('Adresse'))
    .min(36, entityMustHaveSpecificCharLength('AdresseId', 36))
    .max(36, entityMustHaveSpecificCharLength('AdresseId', 36)),
});

export const bestandseinheitUpdateFormValidationSchema = Yup.object().shape({
  [bestandseinheitUpdateFormFields.bezeichnung]: Yup.string().required(entityIsRequired('Top')).max(30, entityMaxCharLength('Top', 30)),
  [bestandseinheitUpdateFormFields.geschoss]: Yup.string().max(30, entityMaxCharLength('Geschoss', 30)),
  [bestandseinheitUpdateFormFields.stiege]: Yup.string().max(30, entityMaxCharLength('Stiege', 30)),
  [bestandseinheitUpdateFormFields.bauteil]: Yup.string().max(30, entityMaxCharLength('Bauteil', 30)),
  [bestandseinheitUpdateFormFields.addressId]: Yup.string()
    .required(entityIsRequired('Adresse'))
    .min(36, entityMustHaveSpecificCharLength('AdresseId', 36))
    .max(36, entityMustHaveSpecificCharLength('AdresseId', 36)),
});

/* eslint-enable no-template-curly-in-string */
