import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { Drawer } from 'antd';
import Address from '../../../shared/components/Address/AddressText';
import { Address as AddressModel } from '../../../types';
import { useToggle } from '../../../hooks/useToggle';
import StreetAddressForm from '../../../shared/components/Address/form/StreetAddressForm';
import { StreetAddressFormValues } from '../../../shared/components/Address/AddressSharedModel';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { useCreateObjektAddressMutation } from '../../Objekt/gql/ObjektMutations.types';
import { addressToString } from '../../../shared/components/Address/addressHelper';
import { selectFilterOption } from '../../../helpers/selectHelper';
import { useCreateNewItemInSelect } from '../../../components/Select/useCreateNewItemInSelect';

type Props = {
  objektId: string;
  addressList: AddressModel[];
  name: string;
  onNewItemCreateSelected: () => void;
  onNewItemCreated: (id: string) => void;
} & SelectProps;

const ObjektAddressSelect: FC<Props> = ({ addressList, objektId, onNewItemCreated, onNewItemCreateSelected, ...restProps }) => {
  const [isCreateNewDrawerOpen, toggleCreateNewDrawer] = useToggle();
  const onNewItemCreateSelectedOpenDrawer = () => {
    onNewItemCreateSelected();
    toggleCreateNewDrawer();
  };
  const { handleSelect, handleSearch, renderCreateNewItem } = useCreateNewItemInSelect(
    addressList,
    onNewItemCreateSelectedOpenDrawer,
    'Neue Adresse anlegen'
  );

  const [runCreateObjektAddress] = useCreateObjektAddressMutation({
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onSubmit = (formValues: StreetAddressFormValues) => {
    runCreateObjektAddress({
      variables: {
        objektId,
        input: formValues,
      },
    }).then((res) => {
      const id = res.data?.createObjektAddress.data.addressId;
      id && onNewItemCreated(id);
      toggleCreateNewDrawer();
    });
  };

  return (
    <>
      <Select
        id={restProps.name}
        size="small"
        placeholder="Adresse auswählen"
        allowClear
        showSearch
        filterOption={selectFilterOption}
        onSelect={handleSelect}
        onSearch={handleSearch}
        {...restProps}
      >
        {addressList.map((address) => {
          return (
            <Select.Option key={address.addressId} value={address.addressId} title={addressToString(address)}>
              <Address address={address} oneLine />
            </Select.Option>
          );
        })}
        {renderCreateNewItem()}
      </Select>
      <Drawer title="Adresse anlegen" width={720} onClose={toggleCreateNewDrawer} open={isCreateNewDrawerOpen} destroyOnClose>
        <StreetAddressForm onSubmit={onSubmit} onCancel={toggleCreateNewDrawer} isUpdate={false} />
      </Drawer>
    </>
  );
};

export default ObjektAddressSelect;
