import { mapToQueryString, QueryParams } from '../../../../../../hooks/useStringQueryParams';
import { GenerierlaufEntryExitCode } from '../../../../../../types';
import { PaginationQueryParamsProps } from '../../../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../../../verarbeitungApiPaths';

enum VorschreibungVerbuchenVerarbeitungQueryParamKey {
  FAKTURIERUNGSPERIODE = 'fakturierungsperiode',
  RECHNUNGSAUSSTELLER_ID = 'rechnungsausstellerId',
  RECHNUNGSEMPFAENGER_ID = 'rechnungsempfaengerId',
  EXIT_CODE_LIST = 'exitCodeList',
}

export type TVorschreibungVerbuchenVerarbeitungQueryParams = QueryParams<{
  fakturierungsperiode: string;
  rechnungsausstellerId: string;
  rechnungsempfaengerId: string;
  exitCodeList: GenerierlaufEntryExitCode[];
}>;

export const updateQueryParams = (
  verarbeitungType: string,
  generierlaufId: string,
  navigate: (path: string) => void,
  filters: TVorschreibungVerbuchenVerarbeitungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) =>
  navigate(
    generatePathToVorschreibungVerbuchenVerarbeitungPage(
      verarbeitungType,
      generierlaufId,
      filters.fakturierungsperiode,
      filters.rechnungsausstellerId,
      filters.rechnungsempfaengerId,
      filters.exitCodeList,
      paginationParams
    )
  );

export const generatePathToVorschreibungVerbuchenVerarbeitungPage = (
  verarbeitungType: string,
  generierlaufId: string,
  fakturierungsperiode?: string,
  rechnungsausstellerId?: string,
  rechnungsempfaengerId?: string,
  exitCodeList?: GenerierlaufEntryExitCode[],
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [VorschreibungVerbuchenVerarbeitungQueryParamKey.FAKTURIERUNGSPERIODE]: fakturierungsperiode,
    [VorschreibungVerbuchenVerarbeitungQueryParamKey.RECHNUNGSAUSSTELLER_ID]: rechnungsausstellerId,
    [VorschreibungVerbuchenVerarbeitungQueryParamKey.RECHNUNGSEMPFAENGER_ID]: rechnungsempfaengerId,
    [VorschreibungVerbuchenVerarbeitungQueryParamKey.EXIT_CODE_LIST]: exitCodeList,
    ...paginationParams,
  })}`;
