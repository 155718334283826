import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { kontoFormFields, KontoFormValues } from '../../kontoFormMapper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import EditableOrDisabledDrawerField from '../../EditableOrDisabledDrawerField';
import KontoTypeButtons from './KontoTypeButtons';

type Props = {
  disabled: boolean;
  formikProps: FormikProps<KontoFormValues>;
};
const KontoFormItemType: FC<Props> = ({ disabled, formikProps }) => (
  <FormItemWithoutColon name={kontoFormFields.type} label="Kontotyp">
    <EditableOrDisabledDrawerField isDisabled={disabled}>
      <KontoTypeButtons
        id={kontoFormFields.type}
        name={kontoFormFields.type}
        formikProps={formikProps}
        disabled={disabled || !formikProps.values.klasse}
      />
    </EditableOrDisabledDrawerField>
  </FormItemWithoutColon>
);

export default KontoFormItemType;
