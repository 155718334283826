import { FC } from 'react';
import { FormikProps } from 'formik';
import { Switch } from 'formik-antd';
import { Space, Typography } from 'antd';
import { topAbrDefGeneralSettingsFormFields, TopAbrDefGeneralSettingsFormValues } from '../topAbrDefGeneralSettingsFormMapper';
import ParamFormItem from '../../../../../../../../components/Form/ParamFormItem';

type Props = {
  formikProps: FormikProps<TopAbrDefGeneralSettingsFormValues>;
  isDisabled: boolean;
  onChange: (value: boolean) => void;
  fieldHelp?: string | null;
};

const DisplayPaymentHistoryFormItem: FC<Props> = ({ formikProps, isDisabled, onChange, fieldHelp }) => (
  <ParamFormItem
    name={topAbrDefGeneralSettingsFormFields.displayZahlungsverlauf}
    label="Zahlungsverlauf ausgeben"
    marginBottom="16px"
    fieldHelp={fieldHelp}
  >
    <Space>
      <Switch name={topAbrDefGeneralSettingsFormFields.displayZahlungsverlauf} size="small" onChange={onChange} disabled={isDisabled} />
      <Typography.Text>{formikProps.values.displayZahlungsverlauf ? 'Ja' : 'Nein'}</Typography.Text>
    </Space>
  </ParamFormItem>
);

export default DisplayPaymentHistoryFormItem;
