import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  MahnungVerarbeitungEntryListingFiltersFormValues,
  mahnungVerarbEntryListingFiltersFormInitialValues,
  mapFormValuesToMahnungVerarbeitungFilters,
} from '../shared/EntryListingFilters/ListingFiltersFormMapper';
import { verarbeitungPageAndMenuListTitles, verarbeitungTypes } from '../../verarbeitungHelpers';
import { Spacer } from '../../../../components/Grid';
import MahnungErstellenVerarbEntryListing from './EntryListing/MahnungErstellenVerarbEntryListing';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { TMahnungVerarbeitungQueryParams, updateQueryParams } from '../shared/EntryListingFilters/filtersQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { MahnungErzeugenGenerierlauf } from '../../../../types';

type Props = {
  generierlauf: MahnungErzeugenGenerierlauf;
};

const VerarbeitungMahnungErstellen: FC<Props> = ({ generierlauf }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TMahnungVerarbeitungQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(verarbeitungTypes.MahnungErzeugen, generierlauf.generierlaufId, navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: MahnungVerarbeitungEntryListingFiltersFormValues) => {
    updateQueryParams(
      verarbeitungTypes.MahnungErzeugen,
      generierlauf.generierlaufId,
      navigate,
      mapFormValuesToMahnungVerarbeitungFilters(values),
      paginationParams
    );
  };

  return (
    <>
      <Typography.Title level={4}>{verarbeitungPageAndMenuListTitles.MahnungErstellung}</Typography.Title>
      <Spacer />
      <Formik<MahnungVerarbeitungEntryListingFiltersFormValues>
        initialValues={mahnungVerarbEntryListingFiltersFormInitialValues(queryParams)}
        onSubmit={onChange}
      >
        {(formikProps) => <MahnungErstellenVerarbEntryListing formikProps={formikProps} generierlauf={generierlauf} queryParams={queryParams} />}
      </Formik>
    </>
  );
};

export default VerarbeitungMahnungErstellen;
