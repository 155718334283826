import React, { FC } from 'react';
import { Modal } from 'antd';
import { Formik } from 'formik';
import { DatePicker, Form } from 'formik-antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { showSuccessMsgOther } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import FormButtons from '../../../../../components/Button/FormButtons';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../../components/DatePicker/DatePicker_formikAntD';
import {
  mapFormValuesToVorschreibungVerbuchen,
  vorschreibungVerbuchenModalFormFields,
  VorschreibungVerbuchenModalFormValues,
} from './vorschreibungVerbuchenModalFormMapper';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import { useCreateAuftragVerbuchenGenerierlaufMutation } from '../../../gql/AuftragGenerierlauf/AuftragVerbuchenGenerierlaufMutations.types';
import ObjektSelectWithAllTag from '../../../../../shared/ObjektSelect/ObjektSelectWithAllTag';

export type Props = {
  onCreate(): void;
  onCancel(): void;
  visible: boolean;
};

const VorschreibungVerbuchenModal: FC<Props> = ({ onCreate, onCancel, visible, ...props }) => {
  const [auftragVerbuchen, { loading }] = useCreateAuftragVerbuchenGenerierlaufMutation({
    onCompleted: () => showSuccessMsgOther('Verbuchung für alle ausgegebenen Vorschreibungen durchgeführt.'),
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Modal
      open={visible}
      title={
        <>
          <QuestionCircleTwoTone twoToneColor="#FAAD14" />
          <span style={{ paddingLeft: '8px' }}>Vorschreibungen verbuchen</span>
        </>
      }
      footer={null}
      onCancel={onCancel}
      destroyOnClose
      maskClosable={false}
      {...props}
    >
      <Formik<VorschreibungVerbuchenModalFormValues>
        initialValues={{ generierDatum: dayjsCustom(), buchungsdatum: dayjsCustom() }}
        onSubmit={(values, { setSubmitting }) => {
          const input = mapFormValuesToVorschreibungVerbuchen(values);
          auftragVerbuchen({ variables: { input } })
            .then(() => {
              onCreate();
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {(formikProps) => (
          <Form layout="vertical">
            <FormItemWithoutColon name={vorschreibungVerbuchenModalFormFields.objektIdList} label="Objekt">
              <ObjektSelectWithAllTag name={vorschreibungVerbuchenModalFormFields.objektIdList} formikProps={formikProps} />
            </FormItemWithoutColon>
            <FormItemWithoutColon name={vorschreibungVerbuchenModalFormFields.dueDate} label="Fälligkeit">
              <DatePicker
                id={vorschreibungVerbuchenModalFormFields.dueDate}
                name={vorschreibungVerbuchenModalFormFields.dueDate}
                placeholder="Datum wählen"
                format={DATE_DISPLAY_FORMAT_DEFAULT}
                size="small"
                style={{ width: '100%' }}
              />
            </FormItemWithoutColon>
            <FormItemWithoutColon name={vorschreibungVerbuchenModalFormFields.buchungsdatum} label="Verbuchen mit Buchungsdatum">
              <DatePicker
                id={vorschreibungVerbuchenModalFormFields.buchungsdatum}
                name={vorschreibungVerbuchenModalFormFields.buchungsdatum}
                placeholder="Datum wählen"
                format={DATE_DISPLAY_FORMAT_DEFAULT}
                allowClear={false}
                size="small"
                style={{ width: '100%' }}
              />
            </FormItemWithoutColon>
            <FormButtons okText="Starten" onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} isOkLoading={loading} updateMode={false} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default VorschreibungVerbuchenModal;
