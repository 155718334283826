import { Select, SelectProps } from 'formik-antd';
import React, { FC, useEffect } from 'react';
import { useField } from 'formik';
import { friendlyFormatIBAN } from 'ibantools';
import { useFilterPaymentInvoiceIssuerIbanListQuery } from '../../../../../Payment/Listing/Filters/gql/PaymentFilterQueries.types';
import { selectFilterOption } from '../../../../../../helpers/selectHelper';
import DataWithShortenedText from '../../../../../../components/Helpers/DataWithShortenedText';

type Props = { rechtstraegerId?: string | null } & Omit<SelectProps, 'id' | 'placeholder' | 'size' | 'allowClear' | 'showSearch' | 'filterOption'>;

const PaymentAssignmentInvoiceIssuerIbanSelect: FC<Props> = ({ rechtstraegerId, ...restProps }) => {
  const { loading, data } = useFilterPaymentInvoiceIssuerIbanListQuery({ variables: { rechtstraegerId } });
  const invoiceIssuerIbanList = data?.getFilterPaymentInvoiceIssuerIbanList.data;

  const [{ value: rechnungsAusstellerIban }, , { setValue: setRechnungsAusstellerIban }] = useField(restProps.name);

  // On init remove payeeIban if list does not include it
  useEffect(() => {
    if (rechnungsAusstellerIban) {
      !invoiceIssuerIbanList?.includes(rechnungsAusstellerIban) && setRechnungsAusstellerIban(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      {...restProps}
      id={restProps.name}
      loading={loading}
      size="small"
      placeholder="Bankverbindung auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {invoiceIssuerIbanList?.map((iban) => {
        return (
          <Select.Option key={iban} value={iban}>
            <DataWithShortenedText maxTextLength={40} text={`${friendlyFormatIBAN(iban)}`} />
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default PaymentAssignmentInvoiceIssuerIbanSelect;
