import { IndexType } from '../../../../types';
import { FormFields } from '../../../../helpers/formikHelper';
import { TIndexSeriesQueryParams } from '../../indexSeriesUriPaths';

export type IndexSeriesListingFiltersFormValues = {
  typ?: IndexType | null;
};

export const indexSeriesListingFiltersFormFields: FormFields<IndexSeriesListingFiltersFormValues> = {
  typ: 'typ',
};

export const mapFormValuesToIndexSeriesListingFilters = (formValues: IndexSeriesListingFiltersFormValues): TIndexSeriesQueryParams => ({
  typ: formValues.typ || undefined,
});

export const mapQueryParamsToFormValues = (filters: TIndexSeriesQueryParams): IndexSeriesListingFiltersFormValues => ({
  typ: filters.typ,
});
