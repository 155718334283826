import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { ObjektFinancialPlanPageRouteParams } from './routes';
import menuListInfoObjektFinancialPlan from './menuListInfoObjektFinancialPlan';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import { ObjektFinancialPlanGenerierlauf } from '../../../types';
import { useObjektFinancialPlanGenerierlaufQuery } from '../../../features/Verarbeitung/gql/ObjektFinancialPlanGenerierlauf/ObjektFinancialPlanGenerierlaufQueries.types';
import { useRestartObjektFinancialPlanGenerierlaufMutation } from '../../../features/Verarbeitung/gql/ObjektFinancialPlanGenerierlauf/ObjektFinancialPlanGenerierlaufMutations.types';

const ObjektFinancialPlanDetailsPage = () => {
  const { generierlaufId } = useParams() as ObjektFinancialPlanPageRouteParams;

  const { data, refetch } = useObjektFinancialPlanGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getObjektFinancialPlanGenerierlauf.data;

  const [runRestart] = useRestartObjektFinancialPlanGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<ObjektFinancialPlanGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.ObjektFinancialPlan}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.ObjektFinancialPlan}
          onGenerierlaufRestart={runRestart}
        />
      )}
      isLoading={false}
      sidebarMenuList={menuListInfoObjektFinancialPlan}
    />
  );
};

export default ObjektFinancialPlanDetailsPage;
