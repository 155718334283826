import { Formik } from 'formik';
import { EditableRowProps } from './editTableProps';
import { EditableTableRecordType } from '../EditableTableOld/EditableTableProps';
import { useEditableTableIntern } from './editableTableContext';

export const EditableRow = <TableRecordType extends Record<string, any> = any>({
  children,
  ...restProps
}: EditableRowProps) => {
  const { selectedRecord, dispatch, onSubmit, validationSchema, mapToFormValues, isRowInEditMode } = useEditableTableIntern<TableRecordType>();

  if (isRowInEditMode(restProps.rowKey)) {
    return (
      <tr {...restProps}>
        <Formik<any>
          initialValues={selectedRecord ? mapToFormValues(selectedRecord as EditableTableRecordType<TableRecordType>) : null}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            dispatch({ type: 'SET_SUBMIT_LOADING', isSubmitLoading: true });
            dispatch({
              type: 'SELECT_RECORD',
              selectedRecord: {
                ...selectedRecord,
                ...values,
              },
            });
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onSubmit(values, selectedRecord!) // selectedRecord is always present when onSubmit is called and before SUBMIT_SUCCESS is dispatched
              .then((updatedRecord) => {
                dispatch({ type: 'SUBMIT_SUCCESS', updatedRecord });
              })
              // errors should be handled in components where it is used, here we catch and swallow all errors to prevent technical errors coming to UI
              .catch(() => Promise.resolve())
              .finally(() => dispatch({ type: 'SET_SUBMIT_LOADING', isSubmitLoading: false }));
          }}
        >
          {(formikProps) => {
            console.log('formikProps', formikProps.values);
            return <>{children}</>;
          }}
        </Formik>
      </tr>
    );
  }

  return <tr {...restProps}>{children}</tr>;
};
