import { FormFields } from '../../../../../../helpers/formikHelper';
import { InfoFeldFormValues } from '../../../../../shared/InfoFeld/infoFeldFormMapper';

export interface ParkplatzCreateFormValues extends InfoFeldFormValues {
  nummer: string;
  text?: string;
  bestandseinheitId?: string;
}

export const parkplatzCreateFormFields: FormFields<ParkplatzCreateFormValues> = {
  nummer: 'nummer',
  text: 'text',
  bestandseinheitId: 'bestandseinheitId',
  documentList: 'documentList',
};

export const parkplatzCreateFormInitialValues: ParkplatzCreateFormValues = {
  documentList: [],
  nummer: '',
};
