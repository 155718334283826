import React from 'react';
import { Empty } from 'antd';
import { DataCarrierPainCreationGenerierlaufEntry } from '../../../../types';
import dataCarrierPainCreationEntryTableColumns from './dataCarrierPainCreationEntryTableColumns';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { useAuth } from '../../../../shared/Auth/authContext';
import GenerierlaufStatusExpandedRow from '../../shared/GenerierlaufStatusExpandedRow';
import { isVerarbeitungEntryTableRowExpandable } from '../../verarbeitungHelpers';
import { useDataCarrierPainCreationGenerierlaufEntryListQuery } from '../../gql/DataCarrierPainCreationGenerierlauf/DataCarrierPainCreationGenerierlaufQueries.types';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../hooks/useQueryWithPagingAndSorting';
import { TDataCarrierPainCreationQueryParams } from './Filters/filtersQueryParams';

type Props = {
  queryParams: TDataCarrierPainCreationQueryParams;
  generierlaufId: string;
};

const DataCarrierPainCreationEntryTable: React.FC<Props> = ({ generierlaufId, queryParams }) => {
  const { activeForFirmendatenId: firmendatenId } = useAuth();
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, refetch, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useDataCarrierPainCreationGenerierlaufEntryListQuery,
    {
      variables: {
        generierlaufId,
        ...queryParams,
      },
      fetchPolicy: 'no-cache',
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const entryList = data?.getDataCarrierPainCreationGenerierlaufEntryList.data.contentList || [];

  return (
    <TableWithColSelector<DataCarrierPainCreationGenerierlaufEntry>
      size="small"
      locale={{
        emptyText: <Empty description={<span>Keine Datenträger vorhanden</span>} />,
      }}
      scroll={{ x: 900 }}
      loading={loading}
      pagination={pagination}
      dataSource={entryList}
      columns={dataCarrierPainCreationEntryTableColumns(firmendatenId as string, refetch)}
      onChange={handleTableSorting}
      expandable={{
        expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
        rowExpandable: isVerarbeitungEntryTableRowExpandable,
      }}
      rowKey={(record) => record.dataCarrier.dataCarrierId}
      filterIdentifier="data-carrier-pain-creation"
    />
  );
};

export default DataCarrierPainCreationEntryTable;
