import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../styles/pdfStyles';

type Props = {
  label: string;
  value?: string | null;
  paddingTop?: string;
};

const SLMTwoColumns: FC<Props> = ({ label, value, paddingTop }) => {
  return (
    <View style={[pdfStyles.row, { paddingTop: paddingTop ?? '5mm' }]}>
      <View style={[pdfStyles.column, { width: '30%' }]}>
        <Text style={pdfStyles.textNormal}>{label}</Text>
      </View>
      <View style={[pdfStyles.column, { width: '70%' }]}>
        <Text
          style={[
            pdfStyles.textNormal,
            pdfStyles.borderBottomSmall,
            {
              width: '100%',
            },
          ]}
        >
          {value}
        </Text>
      </View>
    </View>
  );
};

export default SLMTwoColumns;
