import React, { FC } from 'react';
import { Button, Popconfirm, Space, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { FieldHelp } from '../../../../types';
import { showSuccessMsgDelete } from '../../../../components/message';
import { useDeleteAppFieldHelpMutation } from '../../gql/AppFieldHelpMutations.types';
import { useEditableTable } from '../../../../components/Table/EditableTableV2/editableTableContext';
import { FieldHelpService } from '../../useFieldHelpList';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { useDeleteFibuFieldHelpMutation } from '../../gql/FibuFieldHelpMutations.types';
import { useDeleteOrderFieldHelpMutation } from '../../gql/OrderFieldHelpMutations.types';
import { useDeletePaymentFieldHelpMutation } from '../../gql/PaymentFieldHelpMutations.types';

type Props = {
  fieldHelp: FieldHelp;
  onAction: () => void;
  service?: FieldHelpService;
};

const FieldHelpTableActions: FC<Props> = ({ fieldHelp, onAction, service }) => {
  const { onUpdate } = useEditableTable<FieldHelp>();

  const handleSuccess = () => {
    showSuccessMsgDelete(`Feld ${fieldHelp.selector}`);
    onAction();
  };

  const [runDeleteAppFieldhelp] = useDeleteAppFieldHelpMutation({
    variables: {
      fieldHelpId: fieldHelp.fieldHelpId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
    onCompleted: handleSuccess,
  });

  const [runDeleteFibuFieldhelp] = useDeleteFibuFieldHelpMutation({
    variables: {
      fieldHelpId: fieldHelp.fieldHelpId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
    onCompleted: handleSuccess,
  });

  const [runDeleteOrderFieldhelp] = useDeleteOrderFieldHelpMutation({
    variables: {
      fieldHelpId: fieldHelp.fieldHelpId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
    onCompleted: handleSuccess,
  });

  const [runDeletePaymentFieldhelp] = useDeletePaymentFieldHelpMutation({
    variables: {
      fieldHelpId: fieldHelp.fieldHelpId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
    onCompleted: handleSuccess,
  });

  const runDelete = () => {
    switch (service) {
      case FieldHelpService.App:
        return runDeleteAppFieldhelp();
      case FieldHelpService.Fibu:
        return runDeleteFibuFieldhelp();
      case FieldHelpService.Order:
        return runDeleteOrderFieldhelp();
      case FieldHelpService.Payment:
        return runDeletePaymentFieldhelp();
    }
    return null;
  };

  return (
    <Space>
      <Tooltip title="Bearbeiten">
        <Button
          type="link"
          icon={<EditOutlined />}
          onClick={() => {
            onUpdate(fieldHelp);
          }}
        />
      </Tooltip>
      <Popconfirm title="Sind Sie sicher?" icon={<QuestionCircleOutlined style={{ color: 'red' }} />} onConfirm={() => runDelete()}>
        <Tooltip title="Löschen">
          <Button type="link" icon={<DeleteOutlined />} />
        </Tooltip>
      </Popconfirm>
    </Space>
  );
};

export default FieldHelpTableActions;
