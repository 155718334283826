import React, { FC, useState } from 'react';
import { chunk } from 'lodash';
import { FormikProps } from 'formik';
import FiltersWith4OrMoreFields from '../../../../components/Filters/FiltersWith4OrMoreFields';
import FiltersSelect from '../../../../components/Filters/FiltersSelect';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import BuchungszeilenAnzeigenSelect from '../../../AbrKontenzuordnung/Listing/Filters/BuchungszeilenAnzeigenSelect';
import VerwendungRepFondsSelect from '../../../AbrKontenzuordnung/Listing/Filters/VerwendungRepFondsSelect';
import ExpenseReducingSelect from '../../../AbrKontenzuordnung/Listing/Filters/ExpenseReducingSelect';
import AufteilungsschluesselTemplateSelect from '../../../AufteilungsschluesselTemplateSelect/AufteilungsschluesselTemplateSelect';
import {
  abrKontenzuordnungFiltersFields,
  AbrKontenzuordnungFiltersFormValues,
  abrKontenzuordnungLabelList,
} from '../../../AbrKontenzuordnung/Listing/Filters/abrKontenzuordnungFiltersMapper';

type Props = {
  formikProps: FormikProps<AbrKontenzuordnungFiltersFormValues>;
};

const AbrKontenzuordnungTemplateFilters: FC<Props> = ({ formikProps }) => {
  const [selectedFilterList, setSelectedFilterList] = useState<string[]>([]);

  const filterList = selectedFilterList.map((filter, index) => {
    if (filter === abrKontenzuordnungFiltersFields.buchungszeilenAnzeigen) {
      return (
        <FormItemWithoutColon name={abrKontenzuordnungFiltersFields.buchungszeilenAnzeigen} label="Buchungszeilen anzeigen" key={index}>
          <BuchungszeilenAnzeigenSelect name={abrKontenzuordnungFiltersFields.buchungszeilenAnzeigen} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === abrKontenzuordnungFiltersFields.verwendungVonRepFonds) {
      return (
        <FormItemWithoutColon name={abrKontenzuordnungFiltersFields.verwendungVonRepFonds} label="Verwendung v. Rep.Fonds" key={index}>
          <VerwendungRepFondsSelect name={abrKontenzuordnungFiltersFields.verwendungVonRepFonds} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === abrKontenzuordnungFiltersFields.displayExpenseReducing) {
      return (
        <FormItemWithoutColon name={abrKontenzuordnungFiltersFields.displayExpenseReducing} label="Aufwandsmindernd" key={index}>
          <ExpenseReducingSelect name={abrKontenzuordnungFiltersFields.displayExpenseReducing} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === abrKontenzuordnungFiltersFields.displayAbweichenderAufteilungsschluesselId) {
      return (
        <FormItemWithoutColon
          name={abrKontenzuordnungFiltersFields.displayAbweichenderAufteilungsschluesselId}
          label="Abweichender Aufteilungsschlüssel"
          key={index}
        >
          <AufteilungsschluesselTemplateSelect
            name={abrKontenzuordnungFiltersFields.displayAbweichenderAufteilungsschluesselId}
            onChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    return undefined;
  });
  const chunkArray = filterList.length > 1 ? chunk(filterList, Math.round(filterList.length / 2)) : [filterList, undefined];

  const handleChange = (filterList: string[]) => {
    const diffFilterList = selectedFilterList.filter((x) => !filterList.includes(x));
    if (diffFilterList.length > 0) {
      removeFilters(formikProps, diffFilterList);
    }
    setSelectedFilterList(filterList);
  };

  return (
    <FiltersWith4OrMoreFields
      filtersSelect={
        <FiltersSelect
          defaultSelectedFilterList={[]}
          selectedFilterList={selectedFilterList}
          onChange={handleChange}
          labelList={abrKontenzuordnungLabelList}
        />
      }
      hideTitle
      leftColumn={<>{chunkArray[0]}</>}
      rightColumn={<>{chunkArray[1]}</>}
    />
  );
};

const removeFilters = (formikProps: FormikProps<AbrKontenzuordnungFiltersFormValues>, filterList: string[]) => {
  filterList.forEach(async (filter) => {
    await formikProps.setFieldValue(filter, undefined);
  });
  formikProps.submitForm();
};

export default AbrKontenzuordnungTemplateFilters;
