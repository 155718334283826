import React, { FC } from 'react';
import { Empty } from 'antd';
import heAbrHeVertragTableColumns from './heAbrHeVertragTableColumns';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { HeAbrechnungHeVertragListEntry } from '../../../../../types';

type Props = {
  heAbrechnungHeVertragList: HeAbrechnungHeVertragListEntry[];
  loading: boolean;
  objektId: string;
  heAbrechnungId: string;
};

const HeAbrHeVertragTable: FC<Props> = ({ heAbrechnungHeVertragList, objektId, heAbrechnungId, loading }) => {
  return (
    <TableWithColSelector<HeAbrechnungHeVertragListEntry>
      size="small"
      locale={{
        emptyText: <Empty description={<span>Keine Haueigentümerabrechnungen vorhanden</span>} />,
      }}
      scroll={{ x: 900 }}
      loading={loading}
      pagination={{ showSizeChanger: true, defaultPageSize: 20 }}
      columns={heAbrHeVertragTableColumns(objektId, heAbrechnungId)}
      dataSource={heAbrechnungHeVertragList}
      rowKey={(record) => record.heAbrechnungHeVertragId}
      filterIdentifier="objekt-top-abrechnung"
    />
  );
};

export default HeAbrHeVertragTable;
