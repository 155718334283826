import React from 'react';
import { Button, Dropdown, MenuProps } from 'antd';
import { DownOutlined, FileAddOutlined, FilePdfOutlined, ReadOutlined } from '@ant-design/icons';
import VorschreibungAusgebenVerarbeitungModal from '../../Verarbeitung/Vorschreibung/Ausgeben/Modal/VorschreibungAusgebenVerarbeitungModal';
import VorschreibungErstellenModal from '../../Verarbeitung/Vorschreibung/Erstellen/Modal/VorschreibungErstellenModal';
import { useToggle } from '../../../hooks/useToggle';
import VorschreibungVerbuchenModal from '../../Verarbeitung/Vorschreibung/Verbuchen/Modal/VorschreibungVerbuchenModal';

const VorschreibungActions = () => {
  const [isZinslisteVerarbeitenModalCollapsed, setIsZinslisteVerarbeitenModalCollapse] = useToggle();
  const [isCollapsedVorschreibungAusgeben, onCollapseVorschreibungAusgeben] = useToggle(false);
  const [isCollapsedVorschreibungVerbuchen, onCollapseVorschreibungVerbuchen] = useToggle(false);

  const items: MenuProps['items'] = [
    { key: '1', label: 'Vorschreibungen erstellen', onClick: () => setIsZinslisteVerarbeitenModalCollapse(), icon: <FileAddOutlined /> },
    { key: '2', label: 'Vorschreibungen ausgeben', onClick: () => onCollapseVorschreibungAusgeben(), icon: <FilePdfOutlined /> },
    { key: '3', label: 'Vorschreibungen verbuchen', onClick: () => onCollapseVorschreibungVerbuchen(), icon: <ReadOutlined /> },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <Button type="primary">
          Verarbeitung starten
          <DownOutlined />
        </Button>
      </Dropdown>

      <VorschreibungErstellenModal
        onCreate={() => {
          setIsZinslisteVerarbeitenModalCollapse();
        }}
        visible={isZinslisteVerarbeitenModalCollapsed}
        onCancel={() => {
          setIsZinslisteVerarbeitenModalCollapse();
        }}
      />
      <VorschreibungAusgebenVerarbeitungModal
        onCreate={() => {
          onCollapseVorschreibungAusgeben();
        }}
        visible={isCollapsedVorschreibungAusgeben}
        onCancel={() => {
          onCollapseVorschreibungAusgeben();
        }}
      />
      <VorschreibungVerbuchenModal
        onCreate={() => {
          onCollapseVorschreibungVerbuchen();
        }}
        visible={isCollapsedVorschreibungVerbuchen}
        onCancel={onCollapseVorschreibungVerbuchen}
      />
    </>
  );
};

export default VorschreibungActions;
