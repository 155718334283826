import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ObjektAbrechnungApplyAllDeviationsMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  objektAbrechnungId: Types.Scalars['ID']['input'];
}>;

export type ObjektAbrechnungApplyAllDeviationsMutation = {
  actionObjektAbrechnungApplyAllDeviations: {
    data: { objektAbrechnungId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const ObjektAbrechnungApplyAllDeviationsDocument = gql`
  mutation ObjektAbrechnungApplyAllDeviations($objektId: ID!, $objektAbrechnungId: ID!) {
    actionObjektAbrechnungApplyAllDeviations(objektId: $objektId, objektAbrechnungId: $objektAbrechnungId) {
      data {
        objektAbrechnungId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useObjektAbrechnungApplyAllDeviationsMutation(
  baseOptions?: Apollo.MutationHookOptions<ObjektAbrechnungApplyAllDeviationsMutation, ObjektAbrechnungApplyAllDeviationsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ObjektAbrechnungApplyAllDeviationsMutation, ObjektAbrechnungApplyAllDeviationsMutationVariables>(
    ObjektAbrechnungApplyAllDeviationsDocument,
    options
  );
}
export type ObjektAbrechnungApplyAllDeviationsMutationHookResult = ReturnType<typeof useObjektAbrechnungApplyAllDeviationsMutation>;
export type ObjektAbrechnungApplyAllDeviationsMutationResult = Apollo.MutationResult<ObjektAbrechnungApplyAllDeviationsMutation>;
export type ObjektAbrechnungApplyAllDeviationsMutationOptions = Apollo.BaseMutationOptions<
  ObjektAbrechnungApplyAllDeviationsMutation,
  ObjektAbrechnungApplyAllDeviationsMutationVariables
>;
