import React from 'react';
import { TableWithAlignedColsExpandedRowType } from '../../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { AbrechnungskreisType, AbrechnungskreisTypeTuple, HeAbrAbrechnungskreis, HeAbrAbrechnungskreisList } from '../../../../../../types';
import TableWithAlignedColsHeaderRow from '../../../../../../components/Table/TableWithAlignedCols/TableWithAlignedColsHeaderRow';
import TableWithAlignedColsBody from '../../../../../../components/Table/TableWithAlignedCols/TableWithAlignedColsBody';
import heAbrHeVertragAbrKreisColumns from './heAbrHeVertragAbrKreisColumns';
import heAbrHeVertragKontoRow from './heAbrHeVertragKontoRow';

const heAbrHeVertragAbrKreisRow = (
  objektId: string,
  abrechnungszeitraumVon: string,
  abrechnungszeitraumBis: string,
  buchungskreisId: string
): TableWithAlignedColsExpandedRowType<HeAbrAbrechnungskreisList>[] => {
  return [
    {
      header: (isOpen, setIsOpen) => {
        return (
          <TableWithAlignedColsHeaderRow
            isOpen={isOpen}
            bezeichnungHeaderText="Abrechnungskreis"
            sumHeaderText={['Objektsumme']}
            cellToUseForSumHeaderText={[2]}
            onClick={setIsOpen}
            startCell={0}
            endCell={3}
            positionOfButton={1}
          />
        );
      },
      body: (heAbrAbrKreisList) => (
        <>
          {heAbrAbrKreisList.abrechnungskreisList.map((abrKreis, index) => {
            const abrKreisTypeText = getAbrKreisTypeText(abrKreis.abrechnungskreisType);

            return (
              <TableWithAlignedColsBody<HeAbrAbrechnungskreis>
                key={index}
                dataSource={abrKreis}
                columns={heAbrHeVertragAbrKreisColumns}
                expandedRow={() =>
                  heAbrHeVertragKontoRow(abrKreisTypeText, objektId, abrechnungszeitraumVon, abrechnungszeitraumBis, buchungskreisId)
                }
                positionOfButton={1}
              />
            );
          })}
        </>
      ),
    },
  ];
};

const getAbrKreisTypeText = (abrechnungskreisType: AbrechnungskreisTypeTuple) => {
  if (isAbrKreisTypeEigentuemerVerrechnung(abrechnungskreisType.value)) {
    return 'Zahlung';
  }
  return abrechnungskreisType.text;
};
const isAbrKreisTypeEigentuemerVerrechnung = (abrKreis: AbrechnungskreisType) => abrKreis === AbrechnungskreisType.Eigentuemerverrechnung;

export default heAbrHeVertragAbrKreisRow;
