import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Empty } from 'antd';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import entryListingTableColumns from './EntryListingTableColumns';
import { ListingFiltersFormValues } from '../Filters/listingFiltersFormMapper';
import { BudgetingVPosAuftschluesselLinkage } from '../../../../types';

type Props = {
  formikProps: FormikProps<ListingFiltersFormValues>;
  linkageEntryList: BudgetingVPosAuftschluesselLinkage[];
  loading: boolean;
  onAction: () => void;
};

const EntryListingTable: FC<Props> = ({ linkageEntryList, formikProps, onAction, loading }) => {
  const filterVertragLevel = (entry: BudgetingVPosAuftschluesselLinkage) =>
    !formikProps.values.vertragLevel || entry.vertragLevel.value === formikProps.values.vertragLevel;

  const filterWervaltungSart = (entry: BudgetingVPosAuftschluesselLinkage) =>
    !formikProps.values.objektVerwaltungsart || entry.objektVerwaltungsart.value === formikProps.values.objektVerwaltungsart;

  const filterAufteilungsschluesselId = (entry: BudgetingVPosAuftschluesselLinkage) =>
    !formikProps.values.aufteilungsschluesselId || entry.aufteilungsschluessel.aufteilungsschluesselId === formikProps.values.aufteilungsschluesselId;

  const filterVorschreibungspositionId = (entry: BudgetingVPosAuftschluesselLinkage) =>
    !formikProps.values.vorschreibungspositionId ||
    entry.vorschreibungsposition.vorschreibungspositionId === formikProps.values.vorschreibungspositionId;

  const filteredEntryList = linkageEntryList
    .filter(filterVertragLevel)
    .filter(filterWervaltungSart)
    .filter(filterAufteilungsschluesselId)
    .filter(filterVorschreibungspositionId);

  return (
    <TableWithColSelector<BudgetingVPosAuftschluesselLinkage>
      // EC-10822: TableWithColSelector does get rerendered when linkageEntryList changes, but with this workaround it does rerender
      key={JSON.stringify(filteredEntryList)}
      size="small"
      locale={{
        emptyText: <Empty description={<span>Keine Verknüpfungen vorhanden</span>} />,
      }}
      loading={loading}
      columns={entryListingTableColumns(onAction, filteredEntryList)}
      dataSource={filteredEntryList}
      rowKey={(record) => record.budgetingVPosAuftschluesselLinkageId}
      filterIdentifier="main-budgeting-vpos-auftschluesse-linkage"
    />
  );
};

export default EntryListingTable;
