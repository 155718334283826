import * as Yup from 'yup';
import { entityMaxCharLength, entityMustBeSelected } from '../../../../../../../components/message/validationMsg';
import { weiterePersonCreateFormFields } from '../Create/weiterePersonCreateFormMapper';

/* eslint-disable no-template-curly-in-string */
export const weiterPersonValidationSchema = Yup.object().shape({
  [weiterePersonCreateFormFields.natuerlichePersonId]: Yup.string().required(entityMustBeSelected('Natürliche Person')),
  [weiterePersonCreateFormFields.text]: Yup.string().max(500, entityMaxCharLength('Text', 500)),
});
/* eslint-enable no-template-curly-in-string */
