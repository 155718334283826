import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../styles/pdfStyles';
import { Abrechnungskreis } from '../../../../objektAbrechnung-types';

const OAAbrKreisSummeErgebnis: FC<{ abrechKreis: Abrechnungskreis; isRepFonds?: boolean }> = ({ abrechKreis, isRepFonds }) => (
  <View style={[pdfStyles.row, pdfStyles.tablePaddingLeftRight]}>
    {isRepFonds ? null : <View style={[pdfStyles.column, { width: '55%' }]} />}

    <View style={[pdfStyles.column, pdfStyles.borderBottomSmall, { width: '45%', borderBottomWidth: 2 }]}>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Text style={[pdfStyles.textBulletin, { width: '80%', fontWeight: 'bold' }]}>{abrechKreis.ergebnisNettoText}</Text>
        <Text style={[pdfStyles.textBulletin, { width: '20%', fontWeight: 'bold', alignItems: 'flex-end', textAlign: 'right' }]}>
          {abrechKreis.ergebnisNetto}
        </Text>
      </View>
    </View>

    {isRepFonds ? <View style={[pdfStyles.column, { width: '55%' }]} /> : null}
  </View>
);

export default OAAbrKreisSummeErgebnis;
