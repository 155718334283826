import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type IndexSeriesEntryFieldsFragment = {
  annualAverageFinal?: number | null;
  annualAveragePreliminary?: number | null;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  indexSeriesEntryId: string;
  updatedByMitarbeiterId?: string | null;
  year: number;
  indexPointList: Array<{
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    finalValue?: number | null;
    indexPointId: string;
    month: string;
    preliminaryValue?: number | null;
    updatedByMitarbeiterId?: string | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }>;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const IndexSeriesEntryFieldsFragmentDoc = gql`
  fragment IndexSeriesEntryFields on IndexSeriesEntry {
    annualAverageFinal
    annualAveragePreliminary
    createTs
    createdBy
    createdByMitarbeiterId
    indexPointList {
      createTs
      createdBy
      createdByMitarbeiterId
      finalValue
      indexPointId
      month
      preliminaryValue
      updatedByMitarbeiterId
      warningList {
        attribute
        message
        type
      }
    }
    indexSeriesEntryId
    updatedByMitarbeiterId
    warningList {
      attribute
      message
      type
    }
    year
  }
`;
