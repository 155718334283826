import React, { FC } from 'react';
import { Formik } from 'formik';
import { DatePicker, Form, Input, Select } from 'formik-antd';
import { Col, Divider, Row, Typography } from 'antd';
import FormButtons from '../../../components/Button/FormButtons';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import KurzBezeichnungFormPart from '../../../shared/components/KurzBezeichnung/form/KurzBezeichnungFormPart';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../components/DatePicker/DatePicker_formikAntD';
import { Vorschreibungsposition } from '../../../types';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';
import {
  useCreateVorschreibungspositionTemplateMutation,
  useUpdateVorschreibungspositionTemplateMutation,
} from '../gql/VorschreibungspositionTemplateMutations.types';

import { VorschreibungspositionFormValidationSchema } from '../../Vorschreibungsposition/Form/vorschreibungspositionFormValidationSchema';

import KontierungstabelleTemplateSelect from '../../shared/KontierungstabelleTemplateSelect';
import UmsatzsteuerkennzeichenTemplateSelect from '../../shared/UmsatzsteuerkennzeichenTemplate/UmsatzsteuerkennzeichenTemplateSelect';
import VorschreibungspositionArtSelect from '../../Vorschreibungsposition/Form/VorschreibunspositionArtSelect/VorschreibungspositionArtSelect';
import {
  mapFormValuesToVorschreibungspositionCreate,
  mapFormValuesToVorschreibungspositionUpdate,
  mapVorschreibungspositionToFormValues,
  vorschreibungspositionFormFields,
  VorschreibungspositionFormValues,
} from '../../Vorschreibungsposition/Form/vorschreibungspositionFormMapper';

type Props = {
  vorschreibungsposition?: Vorschreibungsposition;
  onSuccess: () => void;
  onCancel: () => void;
};

const VorschreibungpositionTemplateForm: FC<Props> = ({ vorschreibungsposition, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'Vorschreibungsposition'>('Vorschreibungsposition');
  const isUpdate = !!vorschreibungsposition;
  const entity = 'Vorschreibungsposition';

  const [runCreate, { loading: loadingCreate }] = useCreateVorschreibungspositionTemplateMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateVorschreibungspositionTemplateMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<VorschreibungspositionFormValues>
      initialValues={mapVorschreibungspositionToFormValues(vorschreibungsposition)}
      validationSchema={VorschreibungspositionFormValidationSchema}
      onSubmit={(formValues, formikHelpers) => {
        if (!vorschreibungsposition) {
          runCreate({ variables: { input: mapFormValuesToVorschreibungspositionCreate(formValues) } }).finally(() =>
            formikHelpers.setSubmitting(false)
          );
        } else {
          runUpdate({
            variables: {
              vorschreibungspositionId: vorschreibungsposition.vorschreibungspositionId,
              input: mapFormValuesToVorschreibungspositionUpdate(formValues),
            },
          }).finally(() => formikHelpers.setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Kurzinformationen</Typography.Title>
            </Col>
            <Col span={18}>
              <Row gutter={8}>
                <Col span={12}>
                  <KurzBezeichnungFormPart<VorschreibungspositionFormValues>
                    placeholder="z.B. BK"
                    formikProps={formikProps}
                    fieldHelp={getFieldHelpText('Vorschreibungsposition.kurzBezeichnung')}
                  />
                </Col>
                <Col span={12}>
                  <FormItemWithFieldHelp
                    name={vorschreibungspositionFormFields.bezeichnung}
                    label="Bezeichnung"
                    fieldHelp={getFieldHelpText('Vorschreibungsposition.bezeichnung')}
                  >
                    <Input
                      name={vorschreibungspositionFormFields.bezeichnung}
                      id={vorschreibungspositionFormFields.bezeichnung}
                      placeholder="z.B. Betriebskostenkonto"
                    />
                  </FormItemWithFieldHelp>
                </Col>
              </Row>
            </Col>
          </Row>

          <Divider />

          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Positionsbasis</Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithFieldHelp
                name={vorschreibungspositionFormFields.art}
                label="Vorschreibungspositionart"
                fieldHelp={getFieldHelpText('Vorschreibungsposition.art')}
              >
                <VorschreibungspositionArtSelect name={vorschreibungspositionFormFields.art} />
              </FormItemWithFieldHelp>
              <FormItemWithFieldHelp
                label="Merkmale"
                name={vorschreibungspositionFormFields.tagList}
                fieldHelp={getFieldHelpText('Vorschreibungsposition.tagList')}
              >
                <Select name={vorschreibungspositionFormFields.tagList} id="tagList" mode="tags" placeholder="z.B. WE, BK" />
              </FormItemWithFieldHelp>
            </Col>
          </Row>

          {!isUpdate ? (
            <Row align="top">
              {/* TODO: find a good name for this block */}
              <Col span={6} />
              <Col span={18}>
                <FormItemWithFieldHelp
                  name={vorschreibungspositionFormFields.versionValidFrom}
                  label="Gültig ab"
                  fieldHelp={getFieldHelpText('Vorschreibungsposition.version.validFrom')}
                >
                  <DatePicker
                    size="small"
                    id={vorschreibungspositionFormFields.versionValidFrom}
                    name={vorschreibungspositionFormFields.versionValidFrom}
                    style={{ width: '100%' }}
                    format={DATE_DISPLAY_FORMAT_DEFAULT}
                  />
                </FormItemWithFieldHelp>
                <Row gutter={8}>
                  <Col span={12}>
                    <FormItemWithFieldHelp
                      name={vorschreibungspositionFormFields.umsatzsteuerkennzeichenId}
                      label="Umsatzsteuerkennzeichen"
                      fieldHelp={getFieldHelpText('Vorschreibungsposition.version.umsatzsteuerkennzeichenId')}
                    >
                      <UmsatzsteuerkennzeichenTemplateSelect name={vorschreibungspositionFormFields.umsatzsteuerkennzeichenId} />
                    </FormItemWithFieldHelp>
                  </Col>
                  <Col span={12}>
                    <FormItemWithFieldHelp
                      name={vorschreibungspositionFormFields.kontierungstabelleId}
                      label="Kontierungstabelle"
                      fieldHelp={getFieldHelpText('Vorschreibungsposition.version.kontierungstabelleId')}
                    >
                      <KontierungstabelleTemplateSelect name={vorschreibungspositionFormFields.kontierungstabelleId} />
                    </FormItemWithFieldHelp>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : null}

          <FormButtons
            updateMode={isUpdate}
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default VorschreibungpositionTemplateForm;
