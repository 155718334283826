import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DataCarrierListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type DataCarrierListQuery = {
  getDataCarrierList: {
    data: Array<{ dataCarrierId?: string | null; name?: string | null }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type DataCarrierPainListQueryVariables = Types.Exact<{
  dataCarrierType?: Types.InputMaybe<Types.DataCarrierType>;
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  paymentProposalName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  paymentTransactionType?: Types.InputMaybe<Types.PaymentTransactionType>;
  iban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createdByMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  status?: Types.InputMaybe<Types.DataCarrierPainStatus>;
  order?: Types.InputMaybe<Types.DataCarrierPainOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type DataCarrierPainListQuery = {
  getDataCarrierPainList: {
    data: {
      contentList: Array<{
        countTransactionInstructions: number;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        dataCarrierId: string;
        ibanList: Array<string>;
        name: string;
        paymentProposalName: string;
        sumTransactionInstruction: number;
        xmlFilename: string;
        dataCarrierType: { text: string; value: Types.DataCarrierType };
        paymentTransactionType: { text: string; value: Types.PaymentTransactionType };
        status: { text: string; value: Types.DataCarrierPainStatus };
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type DataCarrierPainIbanListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type DataCarrierPainIbanListQuery = {
  getDataCarrierPainIbanList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DataCarrierPainNameListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type DataCarrierPainNameListQuery = {
  getDataCarrierPainNameList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DataCarrierPainPaymentProposalNameListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type DataCarrierPainPaymentProposalNameListQuery = {
  getDataCarrierPainPaymentProposalNameList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DataCarrierCamtListQueryVariables = Types.Exact<{
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  paymentTransactionType?: Types.InputMaybe<Types.PaymentTransactionType>;
  iban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  statementNumber?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createdByMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  order?: Types.InputMaybe<Types.DataCarrierCamtOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type DataCarrierCamtListQuery = {
  getDataCarrierCamtList: {
    data: {
      contentList: Array<{
        createTs: string;
        createdByMitarbeiterId?: string | null;
        dataCarrierId: string;
        deletable: boolean;
        ibanImportedList: Array<string>;
        ibanNotImportedList: Array<string>;
        name: string;
        xmlFilename: string;
        zipFileId?: string | null;
        zipFilename?: string | null;
        bankStatementList: Array<{
          bankStatementId: string;
          closingBalance?: number | null;
          countTransactions?: number | null;
          countTransactionsCredited?: number | null;
          countTransactionsDebited?: number | null;
          createdOn: string;
          deletable: boolean;
          iban: string;
          openingBalance?: number | null;
          referencedDataCarrierNameList: Array<string>;
          statementNumber: string;
          sumTransactions?: number | null;
        }>;
        dataCarrierType: { text: string; value: Types.DataCarrierType };
        paymentTransactionType: { text: string; value: Types.PaymentTransactionType };
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type DataCarrierCamtIbanListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type DataCarrierCamtIbanListQuery = {
  getDataCarrierCamtIbanList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DataCarrierCamtNameListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type DataCarrierCamtNameListQuery = {
  getDataCarrierCamtNameList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DataCarrierCamtStatementNumberListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type DataCarrierCamtStatementNumberListQuery = {
  getDataCarrierCamtStatementNumberList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DataCarrierPainStatusListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type DataCarrierPainStatusListQuery = {
  getDataCarrierPainStatusList: {
    data: Array<{ text: string; value: Types.DataCarrierPainStatus }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const DataCarrierListDocument = gql`
  query DataCarrierList {
    getDataCarrierList {
      data {
        dataCarrierId
        name
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDataCarrierListQuery(baseOptions?: Apollo.QueryHookOptions<DataCarrierListQuery, DataCarrierListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DataCarrierListQuery, DataCarrierListQueryVariables>(DataCarrierListDocument, options);
}
export function useDataCarrierListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataCarrierListQuery, DataCarrierListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DataCarrierListQuery, DataCarrierListQueryVariables>(DataCarrierListDocument, options);
}
export function useDataCarrierListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DataCarrierListQuery, DataCarrierListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DataCarrierListQuery, DataCarrierListQueryVariables>(DataCarrierListDocument, options);
}
export type DataCarrierListQueryHookResult = ReturnType<typeof useDataCarrierListQuery>;
export type DataCarrierListLazyQueryHookResult = ReturnType<typeof useDataCarrierListLazyQuery>;
export type DataCarrierListSuspenseQueryHookResult = ReturnType<typeof useDataCarrierListSuspenseQuery>;
export type DataCarrierListQueryResult = Apollo.QueryResult<DataCarrierListQuery, DataCarrierListQueryVariables>;
export const DataCarrierPainListDocument = gql`
  query DataCarrierPainList(
    $dataCarrierType: DataCarrierType
    $name: String
    $paymentProposalName: String
    $paymentTransactionType: PaymentTransactionType
    $iban: String
    $createTsFrom: String
    $createTsTo: String
    $createdByMitarbeiterIdList: [String!]
    $status: DataCarrierPainStatus
    $order: DataCarrierPainOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getDataCarrierPainList(
      dataCarrierType: $dataCarrierType
      name: $name
      paymentProposalName: $paymentProposalName
      paymentTransactionType: $paymentTransactionType
      iban: $iban
      createTsFrom: $createTsFrom
      createTsTo: $createTsTo
      createdByMitarbeiterIdList: $createdByMitarbeiterIdList
      status: $status
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          countTransactionInstructions
          createTs
          createdBy
          createdByMitarbeiterId
          dataCarrierId
          dataCarrierType {
            text
            value
          }
          ibanList
          name
          paymentProposalName
          paymentTransactionType {
            text
            value
          }
          status {
            text
            value
          }
          sumTransactionInstruction
          xmlFilename
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDataCarrierPainListQuery(baseOptions?: Apollo.QueryHookOptions<DataCarrierPainListQuery, DataCarrierPainListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DataCarrierPainListQuery, DataCarrierPainListQueryVariables>(DataCarrierPainListDocument, options);
}
export function useDataCarrierPainListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DataCarrierPainListQuery, DataCarrierPainListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DataCarrierPainListQuery, DataCarrierPainListQueryVariables>(DataCarrierPainListDocument, options);
}
export function useDataCarrierPainListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DataCarrierPainListQuery, DataCarrierPainListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DataCarrierPainListQuery, DataCarrierPainListQueryVariables>(DataCarrierPainListDocument, options);
}
export type DataCarrierPainListQueryHookResult = ReturnType<typeof useDataCarrierPainListQuery>;
export type DataCarrierPainListLazyQueryHookResult = ReturnType<typeof useDataCarrierPainListLazyQuery>;
export type DataCarrierPainListSuspenseQueryHookResult = ReturnType<typeof useDataCarrierPainListSuspenseQuery>;
export type DataCarrierPainListQueryResult = Apollo.QueryResult<DataCarrierPainListQuery, DataCarrierPainListQueryVariables>;
export const DataCarrierPainIbanListDocument = gql`
  query DataCarrierPainIbanList {
    getDataCarrierPainIbanList {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDataCarrierPainIbanListQuery(
  baseOptions?: Apollo.QueryHookOptions<DataCarrierPainIbanListQuery, DataCarrierPainIbanListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DataCarrierPainIbanListQuery, DataCarrierPainIbanListQueryVariables>(DataCarrierPainIbanListDocument, options);
}
export function useDataCarrierPainIbanListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DataCarrierPainIbanListQuery, DataCarrierPainIbanListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DataCarrierPainIbanListQuery, DataCarrierPainIbanListQueryVariables>(DataCarrierPainIbanListDocument, options);
}
export function useDataCarrierPainIbanListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DataCarrierPainIbanListQuery, DataCarrierPainIbanListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DataCarrierPainIbanListQuery, DataCarrierPainIbanListQueryVariables>(DataCarrierPainIbanListDocument, options);
}
export type DataCarrierPainIbanListQueryHookResult = ReturnType<typeof useDataCarrierPainIbanListQuery>;
export type DataCarrierPainIbanListLazyQueryHookResult = ReturnType<typeof useDataCarrierPainIbanListLazyQuery>;
export type DataCarrierPainIbanListSuspenseQueryHookResult = ReturnType<typeof useDataCarrierPainIbanListSuspenseQuery>;
export type DataCarrierPainIbanListQueryResult = Apollo.QueryResult<DataCarrierPainIbanListQuery, DataCarrierPainIbanListQueryVariables>;
export const DataCarrierPainNameListDocument = gql`
  query DataCarrierPainNameList {
    getDataCarrierPainNameList {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDataCarrierPainNameListQuery(
  baseOptions?: Apollo.QueryHookOptions<DataCarrierPainNameListQuery, DataCarrierPainNameListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DataCarrierPainNameListQuery, DataCarrierPainNameListQueryVariables>(DataCarrierPainNameListDocument, options);
}
export function useDataCarrierPainNameListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DataCarrierPainNameListQuery, DataCarrierPainNameListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DataCarrierPainNameListQuery, DataCarrierPainNameListQueryVariables>(DataCarrierPainNameListDocument, options);
}
export function useDataCarrierPainNameListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DataCarrierPainNameListQuery, DataCarrierPainNameListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DataCarrierPainNameListQuery, DataCarrierPainNameListQueryVariables>(DataCarrierPainNameListDocument, options);
}
export type DataCarrierPainNameListQueryHookResult = ReturnType<typeof useDataCarrierPainNameListQuery>;
export type DataCarrierPainNameListLazyQueryHookResult = ReturnType<typeof useDataCarrierPainNameListLazyQuery>;
export type DataCarrierPainNameListSuspenseQueryHookResult = ReturnType<typeof useDataCarrierPainNameListSuspenseQuery>;
export type DataCarrierPainNameListQueryResult = Apollo.QueryResult<DataCarrierPainNameListQuery, DataCarrierPainNameListQueryVariables>;
export const DataCarrierPainPaymentProposalNameListDocument = gql`
  query DataCarrierPainPaymentProposalNameList {
    getDataCarrierPainPaymentProposalNameList {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDataCarrierPainPaymentProposalNameListQuery(
  baseOptions?: Apollo.QueryHookOptions<DataCarrierPainPaymentProposalNameListQuery, DataCarrierPainPaymentProposalNameListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DataCarrierPainPaymentProposalNameListQuery, DataCarrierPainPaymentProposalNameListQueryVariables>(
    DataCarrierPainPaymentProposalNameListDocument,
    options
  );
}
export function useDataCarrierPainPaymentProposalNameListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DataCarrierPainPaymentProposalNameListQuery, DataCarrierPainPaymentProposalNameListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DataCarrierPainPaymentProposalNameListQuery, DataCarrierPainPaymentProposalNameListQueryVariables>(
    DataCarrierPainPaymentProposalNameListDocument,
    options
  );
}
export function useDataCarrierPainPaymentProposalNameListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<DataCarrierPainPaymentProposalNameListQuery, DataCarrierPainPaymentProposalNameListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DataCarrierPainPaymentProposalNameListQuery, DataCarrierPainPaymentProposalNameListQueryVariables>(
    DataCarrierPainPaymentProposalNameListDocument,
    options
  );
}
export type DataCarrierPainPaymentProposalNameListQueryHookResult = ReturnType<typeof useDataCarrierPainPaymentProposalNameListQuery>;
export type DataCarrierPainPaymentProposalNameListLazyQueryHookResult = ReturnType<typeof useDataCarrierPainPaymentProposalNameListLazyQuery>;
export type DataCarrierPainPaymentProposalNameListSuspenseQueryHookResult = ReturnType<typeof useDataCarrierPainPaymentProposalNameListSuspenseQuery>;
export type DataCarrierPainPaymentProposalNameListQueryResult = Apollo.QueryResult<
  DataCarrierPainPaymentProposalNameListQuery,
  DataCarrierPainPaymentProposalNameListQueryVariables
>;
export const DataCarrierCamtListDocument = gql`
  query DataCarrierCamtList(
    $name: String
    $paymentTransactionType: PaymentTransactionType
    $iban: String
    $statementNumber: String
    $createTsFrom: String
    $createTsTo: String
    $createdByMitarbeiterIdList: [String!]
    $order: DataCarrierCamtOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getDataCarrierCamtList(
      name: $name
      paymentTransactionType: $paymentTransactionType
      iban: $iban
      statementNumber: $statementNumber
      createTsFrom: $createTsFrom
      createTsTo: $createTsTo
      createdByMitarbeiterIdList: $createdByMitarbeiterIdList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          bankStatementList {
            bankStatementId
            closingBalance
            countTransactions
            countTransactionsCredited
            countTransactionsDebited
            createdOn
            deletable
            iban
            openingBalance
            referencedDataCarrierNameList
            statementNumber
            sumTransactions
          }
          createTs
          createdByMitarbeiterId
          dataCarrierId
          dataCarrierType {
            text
            value
          }
          deletable
          ibanImportedList
          ibanNotImportedList
          name
          paymentTransactionType {
            text
            value
          }
          warningList {
            message
            type
          }
          xmlFilename
          zipFileId
          zipFilename
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDataCarrierCamtListQuery(baseOptions?: Apollo.QueryHookOptions<DataCarrierCamtListQuery, DataCarrierCamtListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DataCarrierCamtListQuery, DataCarrierCamtListQueryVariables>(DataCarrierCamtListDocument, options);
}
export function useDataCarrierCamtListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DataCarrierCamtListQuery, DataCarrierCamtListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DataCarrierCamtListQuery, DataCarrierCamtListQueryVariables>(DataCarrierCamtListDocument, options);
}
export function useDataCarrierCamtListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DataCarrierCamtListQuery, DataCarrierCamtListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DataCarrierCamtListQuery, DataCarrierCamtListQueryVariables>(DataCarrierCamtListDocument, options);
}
export type DataCarrierCamtListQueryHookResult = ReturnType<typeof useDataCarrierCamtListQuery>;
export type DataCarrierCamtListLazyQueryHookResult = ReturnType<typeof useDataCarrierCamtListLazyQuery>;
export type DataCarrierCamtListSuspenseQueryHookResult = ReturnType<typeof useDataCarrierCamtListSuspenseQuery>;
export type DataCarrierCamtListQueryResult = Apollo.QueryResult<DataCarrierCamtListQuery, DataCarrierCamtListQueryVariables>;
export const DataCarrierCamtIbanListDocument = gql`
  query DataCarrierCamtIbanList {
    getDataCarrierCamtIbanList {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDataCarrierCamtIbanListQuery(
  baseOptions?: Apollo.QueryHookOptions<DataCarrierCamtIbanListQuery, DataCarrierCamtIbanListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DataCarrierCamtIbanListQuery, DataCarrierCamtIbanListQueryVariables>(DataCarrierCamtIbanListDocument, options);
}
export function useDataCarrierCamtIbanListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DataCarrierCamtIbanListQuery, DataCarrierCamtIbanListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DataCarrierCamtIbanListQuery, DataCarrierCamtIbanListQueryVariables>(DataCarrierCamtIbanListDocument, options);
}
export function useDataCarrierCamtIbanListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DataCarrierCamtIbanListQuery, DataCarrierCamtIbanListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DataCarrierCamtIbanListQuery, DataCarrierCamtIbanListQueryVariables>(DataCarrierCamtIbanListDocument, options);
}
export type DataCarrierCamtIbanListQueryHookResult = ReturnType<typeof useDataCarrierCamtIbanListQuery>;
export type DataCarrierCamtIbanListLazyQueryHookResult = ReturnType<typeof useDataCarrierCamtIbanListLazyQuery>;
export type DataCarrierCamtIbanListSuspenseQueryHookResult = ReturnType<typeof useDataCarrierCamtIbanListSuspenseQuery>;
export type DataCarrierCamtIbanListQueryResult = Apollo.QueryResult<DataCarrierCamtIbanListQuery, DataCarrierCamtIbanListQueryVariables>;
export const DataCarrierCamtNameListDocument = gql`
  query DataCarrierCamtNameList {
    getDataCarrierCamtNameList {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDataCarrierCamtNameListQuery(
  baseOptions?: Apollo.QueryHookOptions<DataCarrierCamtNameListQuery, DataCarrierCamtNameListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DataCarrierCamtNameListQuery, DataCarrierCamtNameListQueryVariables>(DataCarrierCamtNameListDocument, options);
}
export function useDataCarrierCamtNameListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DataCarrierCamtNameListQuery, DataCarrierCamtNameListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DataCarrierCamtNameListQuery, DataCarrierCamtNameListQueryVariables>(DataCarrierCamtNameListDocument, options);
}
export function useDataCarrierCamtNameListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DataCarrierCamtNameListQuery, DataCarrierCamtNameListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DataCarrierCamtNameListQuery, DataCarrierCamtNameListQueryVariables>(DataCarrierCamtNameListDocument, options);
}
export type DataCarrierCamtNameListQueryHookResult = ReturnType<typeof useDataCarrierCamtNameListQuery>;
export type DataCarrierCamtNameListLazyQueryHookResult = ReturnType<typeof useDataCarrierCamtNameListLazyQuery>;
export type DataCarrierCamtNameListSuspenseQueryHookResult = ReturnType<typeof useDataCarrierCamtNameListSuspenseQuery>;
export type DataCarrierCamtNameListQueryResult = Apollo.QueryResult<DataCarrierCamtNameListQuery, DataCarrierCamtNameListQueryVariables>;
export const DataCarrierCamtStatementNumberListDocument = gql`
  query DataCarrierCamtStatementNumberList {
    getDataCarrierCamtStatementNumberList {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDataCarrierCamtStatementNumberListQuery(
  baseOptions?: Apollo.QueryHookOptions<DataCarrierCamtStatementNumberListQuery, DataCarrierCamtStatementNumberListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DataCarrierCamtStatementNumberListQuery, DataCarrierCamtStatementNumberListQueryVariables>(
    DataCarrierCamtStatementNumberListDocument,
    options
  );
}
export function useDataCarrierCamtStatementNumberListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DataCarrierCamtStatementNumberListQuery, DataCarrierCamtStatementNumberListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DataCarrierCamtStatementNumberListQuery, DataCarrierCamtStatementNumberListQueryVariables>(
    DataCarrierCamtStatementNumberListDocument,
    options
  );
}
export function useDataCarrierCamtStatementNumberListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<DataCarrierCamtStatementNumberListQuery, DataCarrierCamtStatementNumberListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DataCarrierCamtStatementNumberListQuery, DataCarrierCamtStatementNumberListQueryVariables>(
    DataCarrierCamtStatementNumberListDocument,
    options
  );
}
export type DataCarrierCamtStatementNumberListQueryHookResult = ReturnType<typeof useDataCarrierCamtStatementNumberListQuery>;
export type DataCarrierCamtStatementNumberListLazyQueryHookResult = ReturnType<typeof useDataCarrierCamtStatementNumberListLazyQuery>;
export type DataCarrierCamtStatementNumberListSuspenseQueryHookResult = ReturnType<typeof useDataCarrierCamtStatementNumberListSuspenseQuery>;
export type DataCarrierCamtStatementNumberListQueryResult = Apollo.QueryResult<
  DataCarrierCamtStatementNumberListQuery,
  DataCarrierCamtStatementNumberListQueryVariables
>;
export const DataCarrierPainStatusListDocument = gql`
  query DataCarrierPainStatusList {
    getDataCarrierPainStatusList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDataCarrierPainStatusListQuery(
  baseOptions?: Apollo.QueryHookOptions<DataCarrierPainStatusListQuery, DataCarrierPainStatusListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DataCarrierPainStatusListQuery, DataCarrierPainStatusListQueryVariables>(DataCarrierPainStatusListDocument, options);
}
export function useDataCarrierPainStatusListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DataCarrierPainStatusListQuery, DataCarrierPainStatusListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DataCarrierPainStatusListQuery, DataCarrierPainStatusListQueryVariables>(DataCarrierPainStatusListDocument, options);
}
export function useDataCarrierPainStatusListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DataCarrierPainStatusListQuery, DataCarrierPainStatusListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DataCarrierPainStatusListQuery, DataCarrierPainStatusListQueryVariables>(DataCarrierPainStatusListDocument, options);
}
export type DataCarrierPainStatusListQueryHookResult = ReturnType<typeof useDataCarrierPainStatusListQuery>;
export type DataCarrierPainStatusListLazyQueryHookResult = ReturnType<typeof useDataCarrierPainStatusListLazyQuery>;
export type DataCarrierPainStatusListSuspenseQueryHookResult = ReturnType<typeof useDataCarrierPainStatusListSuspenseQuery>;
export type DataCarrierPainStatusListQueryResult = Apollo.QueryResult<DataCarrierPainStatusListQuery, DataCarrierPainStatusListQueryVariables>;
