import { useEffect } from 'react';
import { DataChangedEventConsumer } from './dataChangedSubscriptionContextProps';
import { useOnDataChangedEventContext } from './DataChangedEventProvider';

export const useOnDataChangedEvent = (dataChangedEventConsumer: DataChangedEventConsumer | DataChangedEventConsumer[]) => {
  const { addConsumer, removeConsumer } = useOnDataChangedEventContext();
  const consumers = Array.isArray(dataChangedEventConsumer) ? dataChangedEventConsumer : [dataChangedEventConsumer];
  // useEffect ensures that registering callbacks will only occur after the component is rendered. It is not recommended to try to update
  // a parent component's state while rendering a child component. React even throws a warning about it.
  const consumersJson = JSON.stringify(consumers);
  useEffect(() => {
    consumers.forEach((consumer) => {
      addConsumer(consumer);
    });
    return () => {
      consumers.forEach((consumer) => {
        removeConsumer(consumer);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addConsumer, removeConsumer, consumersJson]); // deep equals for filter
};
