import { Dayjs } from 'dayjs';
import { FormFields } from '../../../../../../../../../helpers/formikHelper';
import {
  ThresholdType,
  ValuePreservationType,
  VertragVposIndexationAgreementIndexThreshold,
  VertragVposIndexationAgreementIndexThresholdCreateInput,
  VertragVposIndexationAgreementIndexThresholdUpdateInput,
} from '../../../../../../../../../types';
import { mapDateFormValueToMonthYear, mapMonthYearToDateFormat } from '../indexationAgreementHelpers';
import { isTruthy } from '../../../../../../../../../helpers/assertionHelper';

export interface IndexationAgreementIndexThresholdFormValues {
  effectiveAfterMonths?: number | null;
  indexOld?: number | null;
  indexSeriesId: string;
  minimumLimit?: number | null;
  monthYearOld: Dayjs | string;
  shareInPercentToApply?: number | null;
  subsequentBilling?: boolean;
  subsequentBillingObjektVorschreibungspositionId?: string;
  threshold?: number;
  thresholdType: ThresholdType;
  type: ValuePreservationType;
  validFrom: Dayjs | string;
}

export const indAgreeIndexThresholdFormFields: FormFields<IndexationAgreementIndexThresholdFormValues> = {
  effectiveAfterMonths: 'effectiveAfterMonths',
  indexOld: 'indexOld',
  indexSeriesId: 'indexSeriesId',
  minimumLimit: 'minimumLimit',
  monthYearOld: 'monthYearOld',
  shareInPercentToApply: 'shareInPercentToApply',
  subsequentBilling: 'subsequentBilling',
  subsequentBillingObjektVorschreibungspositionId: 'subsequentBillingObjektVorschreibungspositionId',
  threshold: 'threshold',
  thresholdType: 'thresholdType',
  type: 'type',
  validFrom: 'validFrom',
};

export const indexThresholdFormInitialValues: IndexationAgreementIndexThresholdFormValues = {
  effectiveAfterMonths: 2,
  indexOld: null,
  indexSeriesId: '',
  minimumLimit: undefined,
  monthYearOld: '',
  shareInPercentToApply: undefined,
  subsequentBilling: false,
  subsequentBillingObjektVorschreibungspositionId: '',
  threshold: 0,
  thresholdType: ThresholdType.Percent,
  type: ValuePreservationType.ReferenceValue,
  validFrom: '',
};
export const mapIndexThresholdToFormValues = (
  referenceValue?: VertragVposIndexationAgreementIndexThreshold
): IndexationAgreementIndexThresholdFormValues => {
  if (!referenceValue) return indexThresholdFormInitialValues;
  return {
    effectiveAfterMonths: referenceValue.effectiveAfterMonths,
    indexOld: referenceValue.indexOld,
    indexSeriesId: referenceValue.indexSeries?.indexSeriesId,
    minimumLimit: referenceValue.minimumLimit,
    monthYearOld: mapMonthYearToDateFormat(referenceValue.monthYearOld),
    shareInPercentToApply: referenceValue.shareInPercentToApply,
    subsequentBilling: referenceValue.subsequentBilling,
    subsequentBillingObjektVorschreibungspositionId: referenceValue.subsequentBillingObjektVorschreibungsposition?.objektVorschreibungspositionId,
    threshold: referenceValue.threshold,
    thresholdType: referenceValue.thresholdType.value,
    type: ValuePreservationType.ReferenceValue,
    validFrom: mapMonthYearToDateFormat(referenceValue.validFrom),
  };
};

export const mapFormValuesToIndexThreshold = (
  formValues: IndexationAgreementIndexThresholdFormValues
): VertragVposIndexationAgreementIndexThresholdCreateInput | VertragVposIndexationAgreementIndexThresholdUpdateInput => ({
  effectiveAfterMonths: isTruthy(formValues.effectiveAfterMonths) ? formValues.effectiveAfterMonths : undefined,
  indexOld: formValues.indexOld,
  indexSeriesId: formValues.indexSeriesId,
  minimumLimit: formValues.minimumLimit,
  monthYearOld: mapDateFormValueToMonthYear(formValues.monthYearOld),
  shareInPercentToApply: formValues.shareInPercentToApply,
  subsequentBilling: formValues.subsequentBilling,
  subsequentBillingObjektVorschreibungspositionId: formValues.subsequentBillingObjektVorschreibungspositionId,
  threshold: formValues.threshold ?? 0,
  thresholdType: formValues.thresholdType,
  type: ValuePreservationType.IndexThreshold,
  validFrom: mapDateFormValueToMonthYear(formValues.validFrom),
});
