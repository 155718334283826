import React, { FC } from 'react';
import { Empty, PaginationProps } from 'antd';
import ticketTableColumns from './ticketTableColumns';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { HandleTableSorting } from '../../../../shared/typeHelpers';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { TicketBase } from '../../../../types';

type Props = {
  ticketList?: TicketBase[];
  loading: boolean;
  handleTableSorting: HandleTableSorting;
  pagination: PaginationProps;
  refetch: () => void;
  tableFilterIdentifier: string;
  tableColumns?: (onAction: () => any) => TableWithColSelectorColumnProps<TicketBase>[];
};

const TicketTable: FC<Props> = ({ tableFilterIdentifier, tableColumns, loading, ticketList, refetch, pagination, handleTableSorting }) => {
  return (
    <TableWithColSelector<TicketBase>
      size="small"
      locale={{
        emptyText: <Empty description={<span>Keine Aufgaben vorhanden</span>} />,
      }}
      loading={loading}
      dataSource={ticketList}
      columns={tableColumns ? tableColumns(refetch) : ticketTableColumns(refetch)}
      rowKey={(record) => record.ticketId}
      pagination={pagination}
      onChange={handleTableSorting}
      filterIdentifier={tableFilterIdentifier}
    />
  );
};

export default TicketTable;
