import { TicketAttachedEmail } from '../../ticketHelpers';
import { Contact, RechtstraegerBase, TicketParticipant } from '../../../../types';

export type TicketParticipantWithEmail = { email?: TicketAttachedEmail } & TicketParticipant;

export type GroupedParticipant = {
  emails?: {
    sender?: string;
    sentTs?: string;
    subject?: string;
  }[];
  ticketParticipants: TicketParticipantWithEmail[];
  rechtstraeger?: RechtstraegerBase | null;
  contact?: Contact | null;
  emailAddress?: string | null;
};

export const getGroupedParticipantListByContact = (ticketParticipants: TicketParticipantWithEmail[]): GroupedParticipant[] => {
  const list: GroupedParticipant[] = [];

  ticketParticipants
    .filter((ticketParticipant) => ticketParticipant.participant.contact?.contactId)
    .forEach((ticketParticipant) => {
      const existing = list.find((groupedParticipant) =>
        groupedParticipant.ticketParticipants.map((p) => p.participant.contact?.contactId).includes(ticketParticipant.participant.contact?.contactId)
      );
      if (!existing) {
        list.push({
          ticketParticipants: [{ ...ticketParticipant }],
          rechtstraeger: ticketParticipant.participant.rechtstraeger,
          contact: ticketParticipant.participant.contact,
        });
      } else {
        existing.ticketParticipants.push({ ...ticketParticipant });
      }
    });

  return list;
};

export const getGroupedParticipantListByEmail = (ticketParticipants: TicketParticipantWithEmail[]): GroupedParticipant[] => {
  const list: GroupedParticipant[] = [];

  ticketParticipants
    .filter((ticketParticipant) => ticketParticipant.participant.emailAddress)
    .forEach((ticketParticipant) => {
      //const existing = list.find((p) => p.emailAddress === ticketParticipant.participant.emailAddress);
      const existing = list.find((groupedParticipant) =>
        groupedParticipant.ticketParticipants.map((p) => p.participant.emailAddress).includes(ticketParticipant.participant.emailAddress)
      );

      if (!existing) {
        const entry: GroupedParticipant = {
          emailAddress: ticketParticipant.participant.emailAddress,
          ticketParticipants: [{ ...ticketParticipant }],
          rechtstraeger: ticketParticipant.participant.rechtstraeger,
          contact: ticketParticipant.participant.contact,
        };
        if (ticketParticipant.email) {
          entry.emails = [{ ...ticketParticipant.email }];
        }
        list.push(entry);
      } else {
        existing.ticketParticipants.push({ ...ticketParticipant });
        if (ticketParticipant.email) {
          if (existing.emails) {
            existing.emails.push({ ...ticketParticipant.email });
          } else {
            existing.emails = [{ ...ticketParticipant.email }];
          }
        }
      }
    });

  return list;
};
