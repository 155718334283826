import React from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'antd';
import { CheckSquareOutlined, CloseSquareOutlined, HomeOutlined, SolutionOutlined, TruckOutlined } from '@ant-design/icons';
import { OrderPaymentStatus, PaymentListEntry, PaymentType } from '../../../../../types';
import {
  isPaymentStatusDurchfuehrbar,
  isPaymentStatusErstellt,
  isPaymentStatusNichtDurchfuehrbar,
  isPaymentStatusPaymentProposal,
  isPaymentTypeAusgangsrechnung,
  isPaymentTypeEingangsrechnung,
  isPaymentTypeHeAuszahlung,
} from '../../../paymentHelpers';
import { generatePathToVorschreibungDetailsPage } from '../../../../Vorschreibung/vorschreibungUriPaths';
import { generatePathToHeVertragDetailsPage } from '../../../../Vertrag/HeVertrag/heVertragUriPaths';
import { radixActionStyles } from '../../../../../helpers/radixIconsStyles';
import { generatePathToStandingOrSingleInvoiceDetailsPage } from '../../../../IncomingInvoice/incomingInvoiceUriPaths';

const IconForLinkToDetails = (paymentType: PaymentType) => {
  switch (paymentType) {
    case PaymentType.Ausgangsrechnung:
      return <SolutionOutlined />;
    case PaymentType.Eingangsrechnung:
      return <TruckOutlined />;
    case PaymentType.HeAuszahlung:
      return <HomeOutlined />;
    default:
      return null;
  }
};

const LinkToPaymentDetails = (zahlung: PaymentListEntry) => {
  if (isPaymentTypeEingangsrechnung(zahlung.paymentType.value) && zahlung.belegFileId && zahlung.belegId) {
    return (
      <Link
        target="_blank"
        to={generatePathToStandingOrSingleInvoiceDetailsPage(zahlung.belegFileId, zahlung.belegId, zahlung.standingInvoicePartialAmountId)}
      >
        Eingangsrechnung Details
      </Link>
    );
  }
  if (isPaymentTypeAusgangsrechnung(zahlung.paymentType.value) && zahlung.auftragId) {
    return (
      <Link target="_blank" to={generatePathToVorschreibungDetailsPage(zahlung.auftragId)}>
        Vorschreibung Details
      </Link>
    );
  }
  if (isPaymentTypeHeAuszahlung(zahlung.paymentType.value) && zahlung.heVertragId) {
    return (
      <Link target="_blank" to={generatePathToHeVertragDetailsPage(zahlung.objekt.objektId, zahlung.heVertragId)}>
        HE-Auszahlung Details
      </Link>
    );
  }
  return null;
};

const isEditingEnabled = (paymentStatus: OrderPaymentStatus) =>
  isPaymentStatusErstellt(paymentStatus) ||
  isPaymentStatusPaymentProposal(paymentStatus) ||
  isPaymentStatusDurchfuehrbar(paymentStatus) ||
  isPaymentStatusNichtDurchfuehrbar(paymentStatus);

const showConfirmChangeToDurchfuehrbar = (runChange: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie den Status für die Zahlung auf Durchführbar ändern?`,
    okText: `Auf "Durchführbar" ändern`,
    cancelText: 'Abbrechen',
    onOk() {
      return runChange();
    },
  });
};

const showConfirmChangeToNichtDurchfuehrbar = (runChange: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie den Status für die Zahlung auf Nicht Durchführbar ändern?`,
    okText: `Auf "Nicht Durchführbar" ändern`,
    cancelText: 'Abbrechen',
    onOk() {
      return runChange();
    },
  });
};

const showConfirmChangeToDatentraegerErzeugt = (runChange: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie den Status für die Zahlung auf Nicht Durchführbar ändern?`,
    okText: `Auf "Nicht Durchgefuert" ändern`,
    cancelText: 'Abbrechen',
    onOk() {
      return runChange();
    },
  });
};

const showConfirmDelete = (runDelete: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie die Zahlung löschen?`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

const showConfirmApprove = (runApprove: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie die Zahlung freigeben?`,
    okText: 'Freigeben',
    cancelText: 'Abbrechen',
    onOk() {
      return runApprove();
    },
  });
};

const isPaymentStatusChangeableToDatentraegerErzeugt = (payment: PaymentListEntry) => payment.changeableToDatentraegerErzeugt;
const isPaymentStatusChangeableToDurchfuehrbar = (payment: PaymentListEntry) => payment.changeableToDurchfuehrbar;
const isPaymentStatusChangeableToNichtDurchfuehrbar = (payment: PaymentListEntry) => payment.changeableToNichtDurchfuehrbar;
const isPaymentStatusChangeableToNichtDurchgefuert = (payment: PaymentListEntry) => payment.changeableToNichtDurchgefuehrt;

const shouldShowChangeStatusAction = (payment: PaymentListEntry) =>
  isPaymentStatusChangeableToDatentraegerErzeugt(payment) ||
  isPaymentStatusChangeableToDurchfuehrbar(payment) ||
  isPaymentStatusChangeableToNichtDurchfuehrbar(payment) ||
  isPaymentStatusChangeableToNichtDurchgefuert(payment);

const labelForChangeStatusAction = (payment: PaymentListEntry) => {
  if (isPaymentStatusChangeableToNichtDurchfuehrbar(payment)) {
    return `Auf „Nicht durchführbar“ ändern`;
  }
  if (isPaymentStatusChangeableToDurchfuehrbar(payment)) {
    return `Auf „Durchführbar“ ändern`;
  }
  if (isPaymentStatusChangeableToNichtDurchgefuert(payment)) {
    return `Auf „Nicht durchgeführt“ ändern`;
  }
  if (isPaymentStatusChangeableToDatentraegerErzeugt(payment)) {
    return `Auf „Datenträger erzeugt (Onlinebanking)“ ändern`;
  }
  return null;
};

const iconForChangeStatusAction = (payment: PaymentListEntry) => {
  if (isPaymentStatusChangeableToNichtDurchfuehrbar(payment) || isPaymentStatusChangeableToNichtDurchgefuert(payment)) {
    return <CloseSquareOutlined style={radixActionStyles} />;
  }
  if (isPaymentStatusChangeableToDurchfuehrbar(payment) || isPaymentStatusChangeableToDatentraegerErzeugt(payment)) {
    return <CheckSquareOutlined />;
  }
  return null;
};

const onChangePaymentStatusAction = (
  payment: PaymentListEntry,
  action: {
    changeToDatentraegerErzeugt: () => void;
    changeToDurchfuehrbar: () => void;
    changeToNichtDurchfuehrbar: () => void;
    changeToNichtDurchgefuehrt: () => void;
  }
) => {
  if (isPaymentStatusChangeableToDurchfuehrbar(payment)) {
    action.changeToDurchfuehrbar();
  }
  if (isPaymentStatusChangeableToDatentraegerErzeugt(payment)) {
    action.changeToDatentraegerErzeugt();
  }
  if (isPaymentStatusChangeableToNichtDurchfuehrbar(payment)) {
    action.changeToNichtDurchfuehrbar();
  }
  if (isPaymentStatusChangeableToNichtDurchgefuert(payment)) {
    action.changeToNichtDurchgefuehrt();
  }
};

export {
  IconForLinkToDetails,
  LinkToPaymentDetails,
  showConfirmChangeToDurchfuehrbar,
  showConfirmChangeToNichtDurchfuehrbar,
  showConfirmChangeToDatentraegerErzeugt,
  showConfirmDelete,
  showConfirmApprove,
  shouldShowChangeStatusAction,
  labelForChangeStatusAction,
  iconForChangeStatusAction,
  onChangePaymentStatusAction,
  isEditingEnabled,
};
