import { HeADetailsColumnStyle } from './details-table-styles-types';

export const columnWidthsAndAlignment: HeADetailsColumnStyle = {
  positionText: { width: '70%', textAlign: 'left' },
  objektsummeText: { width: '15%', textAlign: 'right' },
  anteilText: { width: '15%', textAlign: 'right' },
};

export const paddingLeftForRow = {
  abrKreis: { paddingLeft: '5mm' },
  konto: { paddingLeft: '5mm' },
  buchungszeile: { paddingLeft: '5mm' },
};

export const widthRowWithoutAnteil = '85%';
