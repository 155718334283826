import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteAbrechnungsdefinitionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
}>;

export type DeleteAbrechnungsdefinitionMutation = {
  deleteAbrechnungsdefinition: { errorList: Array<{ message: string; type: string }>; warningList: Array<{ message: string; type: string }> };
};

export type ArchiveAbrechnungsdefinitionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
}>;

export type ArchiveAbrechnungsdefinitionMutation = {
  actionArchiveAbrechnungsdefinition: {
    data: { abrechnungsdefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveAbrechnungsdefinitionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveAbrechnungsdefinitionMutation = {
  actionUnarchiveAbrechnungsdefinition: {
    data: { abrechnungsdefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const DeleteAbrechnungsdefinitionDocument = gql`
  mutation DeleteAbrechnungsdefinition($abrechnungsdefinitionId: ID!) {
    deleteAbrechnungsdefinition(abrechnungsdefinitionId: $abrechnungsdefinitionId) {
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeleteAbrechnungsdefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAbrechnungsdefinitionMutation, DeleteAbrechnungsdefinitionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAbrechnungsdefinitionMutation, DeleteAbrechnungsdefinitionMutationVariables>(
    DeleteAbrechnungsdefinitionDocument,
    options
  );
}
export type DeleteAbrechnungsdefinitionMutationHookResult = ReturnType<typeof useDeleteAbrechnungsdefinitionMutation>;
export type DeleteAbrechnungsdefinitionMutationResult = Apollo.MutationResult<DeleteAbrechnungsdefinitionMutation>;
export type DeleteAbrechnungsdefinitionMutationOptions = Apollo.BaseMutationOptions<
  DeleteAbrechnungsdefinitionMutation,
  DeleteAbrechnungsdefinitionMutationVariables
>;
export const ArchiveAbrechnungsdefinitionDocument = gql`
  mutation ArchiveAbrechnungsdefinition($abrechnungsdefinitionId: ID!) {
    actionArchiveAbrechnungsdefinition(abrechnungsdefinitionId: $abrechnungsdefinitionId) {
      data {
        abrechnungsdefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveAbrechnungsdefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveAbrechnungsdefinitionMutation, ArchiveAbrechnungsdefinitionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveAbrechnungsdefinitionMutation, ArchiveAbrechnungsdefinitionMutationVariables>(
    ArchiveAbrechnungsdefinitionDocument,
    options
  );
}
export type ArchiveAbrechnungsdefinitionMutationHookResult = ReturnType<typeof useArchiveAbrechnungsdefinitionMutation>;
export type ArchiveAbrechnungsdefinitionMutationResult = Apollo.MutationResult<ArchiveAbrechnungsdefinitionMutation>;
export type ArchiveAbrechnungsdefinitionMutationOptions = Apollo.BaseMutationOptions<
  ArchiveAbrechnungsdefinitionMutation,
  ArchiveAbrechnungsdefinitionMutationVariables
>;
export const UnarchiveAbrechnungsdefinitionDocument = gql`
  mutation UnarchiveAbrechnungsdefinition($abrechnungsdefinitionId: ID!) {
    actionUnarchiveAbrechnungsdefinition(abrechnungsdefinitionId: $abrechnungsdefinitionId) {
      data {
        abrechnungsdefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveAbrechnungsdefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveAbrechnungsdefinitionMutation, UnarchiveAbrechnungsdefinitionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveAbrechnungsdefinitionMutation, UnarchiveAbrechnungsdefinitionMutationVariables>(
    UnarchiveAbrechnungsdefinitionDocument,
    options
  );
}
export type UnarchiveAbrechnungsdefinitionMutationHookResult = ReturnType<typeof useUnarchiveAbrechnungsdefinitionMutation>;
export type UnarchiveAbrechnungsdefinitionMutationResult = Apollo.MutationResult<UnarchiveAbrechnungsdefinitionMutation>;
export type UnarchiveAbrechnungsdefinitionMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveAbrechnungsdefinitionMutation,
  UnarchiveAbrechnungsdefinitionMutationVariables
>;
