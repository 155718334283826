import React, { FC } from 'react';
import { Table } from 'antd';
import belegnummernkreisTableColumns from './belegnummernkreisTableColumns';
import { Belegnummernkreis } from '../../../../../../../types';

type BelegnummernkreisTableProps = {
  belegnummernkreisList: Belegnummernkreis[];
};

const BelegnummernkreisTable: FC<BelegnummernkreisTableProps> = ({ belegnummernkreisList }) => {
  return (
    <Table<Belegnummernkreis>
      size="small"
      pagination={false}
      dataSource={belegnummernkreisList}
      columns={belegnummernkreisTableColumns}
      rowKey={(record) => record.belegnummernkreisId}
    />
  );
};

export default BelegnummernkreisTable;
