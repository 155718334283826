import React, { FC } from 'react';
import { Drawer, Space, Tabs, TabsProps } from 'antd';
import { MailOutlined, PaperClipOutlined, TeamOutlined } from '@ant-design/icons';
import { Ticket } from '../../../types';
import TabPaneContent from '../../../components/Tabs/TabPaneContent';
import TicketDetailsOverview from './TicketDetailsOverview';
import TicketActionButton from '../shared/TicketActionButton';
import { useToggle } from '../../../hooks/useToggle';
import TicketParticipantsUpdateForm from '../Form/TicketParticipantsUpdateForm/TicketParticipantsUpdateForm';
import TicketAttachmentsUpdateForm from '../Form/TicketAttachmentsUpdateForm/TicketAttachmentsUpdateForm';
import { isTicketArchived } from '../ticketHelpers';
import TicketEmailsUpdateForm from '../Form/TicketEmailsUpdateForm/TicketEmailsUpdateForm';

type Props = {
  ticket: Ticket;
  refetch: () => Promise<unknown>;
};

const TicketDetailsOverviewTab: FC<Props> = ({ ticket, refetch }) => {
  const [isParticipantsDrawerOpen, toggleIsParticipantsDrawerOpen] = useToggle();
  const [isAttachmentsDrawerOpen, toggleIsAttachmentsDrawerOpen] = useToggle();
  const [isEmailsDrawerOpen, toggleIsEmailsDrawerOpen] = useToggle();

  const isArchived = isTicketArchived(ticket);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Übersicht',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <TicketDetailsOverview ticket={ticket} refetch={refetch} />
        </TabPaneContent>
      ),
    },
  ];

  return (
    <div style={{ position: 'relative' }}>
      <Tabs items={items} />
      <div style={{ position: 'absolute', top: 0, right: 0, padding: '0 8px' }}>
        <Space>
          {/* Participants */}
          <TicketActionButton
            label="Beteiligte"
            icon={<TeamOutlined />}
            onClick={toggleIsParticipantsDrawerOpen}
            disabled={isArchived}
            badgeCount={ticket.participantCount}
          >
            <Drawer
              width={720}
              title="Beteiligte der Aufgabe bearbeiten"
              onClose={toggleIsParticipantsDrawerOpen}
              open={isParticipantsDrawerOpen}
              destroyOnClose
            >
              <TicketParticipantsUpdateForm
                ticketId={ticket.ticketId}
                onSuccess={() => {
                  toggleIsParticipantsDrawerOpen();
                  refetch();
                }}
                onCancel={toggleIsParticipantsDrawerOpen}
              />
            </Drawer>
          </TicketActionButton>

          {/* Attachments */}
          <TicketActionButton
            label="Anhänge"
            icon={<PaperClipOutlined />}
            onClick={toggleIsAttachmentsDrawerOpen}
            disabled={isArchived}
            badgeCount={ticket.attachmentCount}
          >
            <Drawer
              width={720}
              title="Anhänge der Aufgabe bearbeiten"
              onClose={toggleIsAttachmentsDrawerOpen}
              open={isAttachmentsDrawerOpen}
              destroyOnClose
            >
              <TicketAttachmentsUpdateForm
                ticketId={ticket.ticketId}
                onSuccess={() => {
                  toggleIsAttachmentsDrawerOpen();
                  refetch();
                }}
                onCancel={toggleIsAttachmentsDrawerOpen}
              />
            </Drawer>
          </TicketActionButton>

          {/* Email */}
          <TicketActionButton
            label="Kommunikation"
            icon={<MailOutlined />}
            onClick={toggleIsEmailsDrawerOpen}
            disabled={isArchived}
            badgeCount={ticket.emailCount}
          >
            <Drawer
              width={720}
              title="Kommunikation der Aufgabe bearbeiten"
              onClose={toggleIsEmailsDrawerOpen}
              open={isEmailsDrawerOpen}
              destroyOnClose
            >
              <TicketEmailsUpdateForm
                ticketId={ticket.ticketId}
                onSuccess={() => {
                  toggleIsEmailsDrawerOpen();
                  refetch();
                }}
                onCancel={toggleIsEmailsDrawerOpen}
              />
            </Drawer>
          </TicketActionButton>
        </Space>
      </div>
    </div>
  );
};

export default TicketDetailsOverviewTab;
