import React from 'react';
import { Layout, Table, Button, Modal } from 'antd';
import columns from '../../../../features/Admin/adminTableColumns';
import { useAdminListQuery } from '../../../../features/Admin/gql/AdminQueries.types';
import AdminCreate from '../../../../features/Admin/Form/Create/AdminCreate';
import { User, UserCreateInput } from '../../../../types';
import { useToggle } from '../../../../hooks/useToggle';
import { useCreateAdminMutation } from '../../../../features/Admin/gql/AdminMutations.types';
import { showSuccessMsgCreate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';

const { Content } = Layout;

const VerwaltungAdminPage = () => {
  const [isCollapsed, onCollapse] = useToggle();
  const { loading, data: maybeData, refetch } = useAdminListQuery();

  const [runCreateAdmin, { loading: loadingCreate }] = useCreateAdminMutation({
    onCompleted: () => {
      showSuccessMsgCreate(`Admin`);
      onCollapse();
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const adminList = maybeData?.getUsers.data ?? [];

  return (
    <Content style={{ margin: '0 16px' }}>
      <Button type="primary" size="middle" onClick={onCollapse}>
        Admin anlegen
      </Button>
      <Content style={{ margin: '16px 0px' }}>
        <Table<User> size="small" loading={loading} columns={columns} dataSource={adminList} rowKey={(record) => record.username} />
      </Content>
      <Modal title="Admin anlegen" open={isCollapsed} onCancel={onCollapse} footer={null} destroyOnClose maskClosable={false}>
        <AdminCreate
          loading={loadingCreate}
          onCreate={(adminToCreate: UserCreateInput) => {
            runCreateAdmin({
              variables: {
                request: adminToCreate,
              },
            });
          }}
          onCancel={onCollapse}
        />
      </Modal>
    </Content>
  );
};

export default VerwaltungAdminPage;
