import React, { FC } from 'react';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import AbrDefVersionTimeline from './AbrDefVersionTimeline';
import { useHeAbrechnungsdefinitionVersionListQuery } from '../../gql/HeAbrDef/HeAbrDefVersionQueries.types';
import { useDeleteHeAbrechnungsdefinitionVersionMutation } from '../../gql/HeAbrDef/HeAbrDefVersionMutations.types';
import { HeAbrechnungsdefinitionVersionListEntry } from '../../../../types';

type Props = {
  abrechnungsdefinitionId: string;
};

const AbrDefHeVersionTimeline: FC<Props> = ({ abrechnungsdefinitionId }) => {
  const { data, loading, refetch } = useHeAbrechnungsdefinitionVersionListQuery({ variables: { abrechnungsdefinitionId, withDetails: true } });
  const versionList = data?.getHeAbrechnungsdefinitionVersionList.data;

  const [deleteHeAbrDefVersion] = useDeleteHeAbrechnungsdefinitionVersionMutation({
    onCompleted: () => {
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onDelete = (version: HeAbrechnungsdefinitionVersionListEntry): Promise<unknown> => {
    return deleteHeAbrDefVersion({
      variables: {
        abrechnungsdefinitionId,
        abrechnungsdefinitionVersionId: version.abrechnungsdefinitionVersionId,
      },
    });
  };

  return <AbrDefVersionTimeline abrechnungsdefinitionId={abrechnungsdefinitionId} loading={loading} versionList={versionList} onDelete={onDelete} />;
};

export default AbrDefHeVersionTimeline;
