import React, { FC } from 'react';
import { Typography } from 'antd';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { AbrechdefAbrechkreis, AbrechnungsdefinitionType } from '../../../../types';

import NestedTable from '../../../../components/Table/NestedTable/NestedTable';
import {
  isAbrDefTypeHeAbrechnung,
  isAbrDefTypeSubAbrechnung,
} from '../../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';

type Props = {
  abrechdefAbrechkreisList: AbrechdefAbrechkreis[];
  abrDefType: AbrechnungsdefinitionType;
};

const AbrDefTemplateVersionViewAbrKreisList: FC<Props> = ({ abrechdefAbrechkreisList, abrDefType }) => {
  return (
    <NestedTable<AbrechdefAbrechkreis>
      useHighlighting
      expandable={{ rowExpandable: () => false }}
      style={{ width: '100%' }}
      dataSource={abrechdefAbrechkreisList}
      rowKey={(record) => record.abrechnungskreis.abrechnungskreisId}
      pagination={false}
      columns={[
        {
          title: 'Abrechnungskreis',
          key: 'abrechnungskreisId',
          render: (text, record) => <Typography.Text>{record.abrechnungskreis.bezeichnung}</Typography.Text>,
        },
        {
          title: isAbrDefTypeHeAbrechnung(abrDefType) || isAbrDefTypeSubAbrechnung(abrDefType) ? null : 'Aufteilungsschlüssel',
          key: 'aufteilungsschluesselId',
          render: (text, record) => {
            if (isAbrDefTypeHeAbrechnung(abrDefType) || isAbrDefTypeSubAbrechnung(abrDefType)) {
              return null;
            } else {
              return record.aufteilungsschluessel ? (
                <Typography.Text>{record.aufteilungsschluessel.bezeichnung}</Typography.Text>
              ) : (
                <TextForEmptyValue textToShow="NV" />
              );
            }
          },
        },
        {
          title: isAbrDefTypeHeAbrechnung(abrDefType) || isAbrDefTypeSubAbrechnung(abrDefType) ? null : 'Vorschreibungsposition',
          key: 'vorschreibungspositionId',
          render: (text, record) => {
            if (isAbrDefTypeHeAbrechnung(abrDefType) || isAbrDefTypeSubAbrechnung(abrDefType)) {
              return null;
            } else {
              return record.vorschreibungsposition ? (
                <Typography.Text>{record.vorschreibungsposition.bezeichnung}</Typography.Text>
              ) : (
                <TextForEmptyValue textToShow="NV" />
              );
            }
          },
        },
      ]}
    />
  );
};

export default AbrDefTemplateVersionViewAbrKreisList;
