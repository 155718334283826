import React, { FC } from 'react';
import RechnungsAusstellerKontoTable from './Table/RechnungsAusstellerKontoTable';
import RechnungsAusstellerBuchungskreisAnlegenBtn from './RechnungsAusstellerBuchungskreisAnlegenBtn';
import { Firma, NatuerlichePerson, Personengemeinschaft } from '../../../../../types';
import { RechnungsAusstellerFieldsFragment } from '../gql/RechnungsAusstellerFragments.types';

type Props = {
  rechnungsAussteller: RechnungsAusstellerFieldsFragment;
  rechtstraeger: Firma | NatuerlichePerson | Personengemeinschaft;
  onSuccess: () => void;
};

const RechnungsAusstellerKontoListing: FC<Props> = ({ rechnungsAussteller, rechtstraeger, onSuccess }) => (
  <>
    <RechnungsAusstellerBuchungskreisAnlegenBtn rechnungsAussteller={rechnungsAussteller} rechtstraeger={rechtstraeger} onSuccess={onSuccess} />
    {rechnungsAussteller.buchungskreis ? (
      <RechnungsAusstellerKontoTable rechnungsAussteller={rechnungsAussteller} rechtstraeger={rechtstraeger} onSuccess={onSuccess} />
    ) : null}
  </>
);

export default RechnungsAusstellerKontoListing;
