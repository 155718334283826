import React, { FC } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import FormButtons from '../../../../components/Button/FormButtons';
import { StyledWizardSpaceForContent } from '../../../shared/styled/WizardSpace.style';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';
import { MahndefinitionParamFormValues } from '../../../Mahndefinition/mahndefinitionFormMapper';
import { mahndefinitionParamFormValidationSchema } from '../../../Mahndefinition/ParameterForm/mahndefinitionParamFormValidationSchema';
import { validateMahngebuehren, validateMahngebuehren2 } from '../../../Mahndefinition/mahndefinitionValidation';
import MahndefinitionParamFormAllgemein from '../../../Mahndefinition/ParameterForm/MahndefinitionParamFormAllgemein';
import MahndefinitionParamFormMahnstufen from '../../../Mahndefinition/ParameterForm/Mahnstufen/MahndefinitionParamFormMahnstufen';

type Props = {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  parameterFormValues: MahndefinitionParamFormValues;
  setParameterFormValues: React.Dispatch<React.SetStateAction<MahndefinitionParamFormValues>>;
};

const MahndefinitionTemplateCreateParamForm: FC<Props> = ({ setActiveStep, parameterFormValues, setParameterFormValues }) => {
  const navigate = useNavigate();
  return (
    <Formik<MahndefinitionParamFormValues>
      initialValues={parameterFormValues}
      validationSchema={mahndefinitionParamFormValidationSchema}
      validate={validateMahngebuehren2}
      onSubmit={(values, formikHelpers) => {
        if (validateMahngebuehren(values, formikHelpers)) {
          return;
        }
        setParameterFormValues(values);
        setActiveStep(1);
      }}
    >
      {(formikProps) => (
        <Form>
          <StyledWizardSpaceForContent direction="vertical">
            <MahndefinitionParamFormAllgemein />
            <MahndefinitionParamFormMahnstufen formikProps={formikProps} />
          </StyledWizardSpaceForContent>
          <FormButtons onCancel={() => navigate(generatePath(URI_ANDROMEDA_SYS_SETTINGS.mahndefinitionPage))} updateMode={false} okText="Weiter" />
        </Form>
      )}
    </Formik>
  );
};

export default MahndefinitionTemplateCreateParamForm;
