import React, { FC } from 'react';
import { Space } from 'antd';
import { ITimeblock } from '../../../../components/Timeline/timelineCtx';
import TimelineCardContentInfoRow from '../../../../components/Timeline/Card/TimelineCardContentInfoRow';
import RepFondsKontenZuweisungTable from '../Table/Read/RepFondsKontenZuweisungTable';
import { RepFondsEinstellung } from '../../../../types';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  timeblock: ITimeblock<RepFondsEinstellung>;
};

const RepFondsKontenZuweisungVersionCardContent: FC<Props> = ({ timeblock }) => (
  <Space direction="vertical" style={{ width: '100%', paddingBottom: '8px' }}>
    <TimelineCardContentInfoRow infoRowTitle="Gültig ab">
      {timeblock.validFrom && <CustomFormattedDate value={timeblock.validFrom} />}
    </TimelineCardContentInfoRow>
    <RepFondsKontenZuweisungTable repFondsEinstellung={timeblock} isInCardContent />
  </Space>
);

export default RepFondsKontenZuweisungVersionCardContent;
