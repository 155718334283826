import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { WeAbrechnungsdefinitionVersionFieldsFragmentDoc } from '../../../Abrechnungsdefinition/gql/WeAbrDef/WeAbrDefVersionFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateWeAbrechnungsdefinitionTemplateVersionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  request: Types.WeAbrechnungsdefinitionVersionCreateInput;
}>;

export type CreateWeAbrechnungsdefinitionTemplateVersionMutation = {
  createWeAbrechnungsdefinitionTemplateVersion: {
    data: {
      abrechnungsdefinitionVersionId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      validFrom: string;
      kommentar?: string | null;
      lastUpdateTs: string;
      abrechdefAbrechkreisList: Array<{
        abrechnungskreis: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vorschreibungsposition?: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          vorschreibungspositionId: string;
          art: { text: string; value: Types.VorschreibungspositionArt };
          kontierungstabelle?: {
            basiskonto: number;
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kontierungstabelleId: string;
            updatedByMitarbeiterId?: string | null;
            status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
            subAdministrationExpenseAccount?: {
              bezeichnung: string;
              firmendatenId?: string | null;
              kontoId: string;
              kontoKlasse: string;
              nummer: string;
            } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
          umsatzsteuerkennzeichen?: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ message: string; type: string; attribute?: string | null }>;
          } | null;
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        } | null;
      }>;
      kontoAbrechnungskreisList: Array<{
        abrechnen: boolean;
        buchungszeilenAnzeigen: boolean;
        expenseReducing: boolean;
        ustVomAufwand: boolean;
        abrechnungskreis?: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
      objektAbrechnungOutputOptions: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        displayEinnahmen: boolean;
        displayKontonummer: boolean;
        updatedByMitarbeiterId?: string | null;
        buchungsdetails: { text: string; value: Types.BuchungsdetailsType };
        consolidateEinnahmen?: { text: string; value: Types.ConsolidateEinnahmenType } | null;
      };
      objektAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      topAbrechnungOutputOptions: {
        considerRueckstand: boolean;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        displayDueDate: boolean;
        displayRueckstandOtherPersons: boolean;
        displayZahlungsverlauf: boolean;
        rueckstandOtherPersonsThreshold?: number | null;
        updatedByMitarbeiterId?: string | null;
        dueDateDisplayOptionEigentuemerGuthaben?: { month?: number | null; type: { text: string; value: Types.DueDateDisplayOptionType } } | null;
        dueDateDisplayOptionEigentuemerNachzahlung?: { month?: number | null; type: { text: string; value: Types.DueDateDisplayOptionType } } | null;
        dueDateDisplayOptionMieter?: { month?: number | null; type: { text: string; value: Types.DueDateDisplayOptionType } } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      topAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateWeAbrechnungsdefinitionTemplateVersionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Types.Scalars['ID']['input'];
  request: Types.WeAbrechnungsdefinitionVersionUpdateInput;
}>;

export type UpdateWeAbrechnungsdefinitionTemplateVersionMutation = {
  updateWeAbrechnungsdefinitionTemplateVersion: {
    data: {
      abrechnungsdefinitionVersionId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      validFrom: string;
      kommentar?: string | null;
      lastUpdateTs: string;
      abrechdefAbrechkreisList: Array<{
        abrechnungskreis: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vorschreibungsposition?: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          vorschreibungspositionId: string;
          art: { text: string; value: Types.VorschreibungspositionArt };
          kontierungstabelle?: {
            basiskonto: number;
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kontierungstabelleId: string;
            updatedByMitarbeiterId?: string | null;
            status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
            subAdministrationExpenseAccount?: {
              bezeichnung: string;
              firmendatenId?: string | null;
              kontoId: string;
              kontoKlasse: string;
              nummer: string;
            } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
          umsatzsteuerkennzeichen?: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ message: string; type: string; attribute?: string | null }>;
          } | null;
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        } | null;
      }>;
      kontoAbrechnungskreisList: Array<{
        abrechnen: boolean;
        buchungszeilenAnzeigen: boolean;
        expenseReducing: boolean;
        ustVomAufwand: boolean;
        abrechnungskreis?: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
      objektAbrechnungOutputOptions: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        displayEinnahmen: boolean;
        displayKontonummer: boolean;
        updatedByMitarbeiterId?: string | null;
        buchungsdetails: { text: string; value: Types.BuchungsdetailsType };
        consolidateEinnahmen?: { text: string; value: Types.ConsolidateEinnahmenType } | null;
      };
      objektAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      topAbrechnungOutputOptions: {
        considerRueckstand: boolean;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        displayDueDate: boolean;
        displayRueckstandOtherPersons: boolean;
        displayZahlungsverlauf: boolean;
        rueckstandOtherPersonsThreshold?: number | null;
        updatedByMitarbeiterId?: string | null;
        dueDateDisplayOptionEigentuemerGuthaben?: { month?: number | null; type: { text: string; value: Types.DueDateDisplayOptionType } } | null;
        dueDateDisplayOptionEigentuemerNachzahlung?: { month?: number | null; type: { text: string; value: Types.DueDateDisplayOptionType } } | null;
        dueDateDisplayOptionMieter?: { month?: number | null; type: { text: string; value: Types.DueDateDisplayOptionType } } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      topAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteWeAbrechnungsdefinitionTemplateVersionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Types.Scalars['ID']['input'];
}>;

export type DeleteWeAbrechnungsdefinitionTemplateVersionMutation = {
  deleteWeAbrechnungsdefinitionTemplateVersion: {
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateWeAbrechnungsdefinitionTemplateVersionDocument = gql`
  mutation CreateWeAbrechnungsdefinitionTemplateVersion($abrechnungsdefinitionId: ID!, $request: WeAbrechnungsdefinitionVersionCreateInput!) {
    createWeAbrechnungsdefinitionTemplateVersion(abrechnungsdefinitionId: $abrechnungsdefinitionId, input: $request) {
      data {
        ...WeAbrechnungsdefinitionVersionFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${WeAbrechnungsdefinitionVersionFieldsFragmentDoc}
`;
export function useCreateWeAbrechnungsdefinitionTemplateVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWeAbrechnungsdefinitionTemplateVersionMutation,
    CreateWeAbrechnungsdefinitionTemplateVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateWeAbrechnungsdefinitionTemplateVersionMutation, CreateWeAbrechnungsdefinitionTemplateVersionMutationVariables>(
    CreateWeAbrechnungsdefinitionTemplateVersionDocument,
    options
  );
}
export type CreateWeAbrechnungsdefinitionTemplateVersionMutationHookResult = ReturnType<
  typeof useCreateWeAbrechnungsdefinitionTemplateVersionMutation
>;
export type CreateWeAbrechnungsdefinitionTemplateVersionMutationResult = Apollo.MutationResult<CreateWeAbrechnungsdefinitionTemplateVersionMutation>;
export type CreateWeAbrechnungsdefinitionTemplateVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateWeAbrechnungsdefinitionTemplateVersionMutation,
  CreateWeAbrechnungsdefinitionTemplateVersionMutationVariables
>;
export const UpdateWeAbrechnungsdefinitionTemplateVersionDocument = gql`
  mutation UpdateWeAbrechnungsdefinitionTemplateVersion(
    $abrechnungsdefinitionId: ID!
    $abrechnungsdefinitionVersionId: ID!
    $request: WeAbrechnungsdefinitionVersionUpdateInput!
  ) {
    updateWeAbrechnungsdefinitionTemplateVersion(
      abrechnungsdefinitionId: $abrechnungsdefinitionId
      abrechnungsdefinitionVersionId: $abrechnungsdefinitionVersionId
      input: $request
    ) {
      data {
        ...WeAbrechnungsdefinitionVersionFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${WeAbrechnungsdefinitionVersionFieldsFragmentDoc}
`;
export function useUpdateWeAbrechnungsdefinitionTemplateVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWeAbrechnungsdefinitionTemplateVersionMutation,
    UpdateWeAbrechnungsdefinitionTemplateVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWeAbrechnungsdefinitionTemplateVersionMutation, UpdateWeAbrechnungsdefinitionTemplateVersionMutationVariables>(
    UpdateWeAbrechnungsdefinitionTemplateVersionDocument,
    options
  );
}
export type UpdateWeAbrechnungsdefinitionTemplateVersionMutationHookResult = ReturnType<
  typeof useUpdateWeAbrechnungsdefinitionTemplateVersionMutation
>;
export type UpdateWeAbrechnungsdefinitionTemplateVersionMutationResult = Apollo.MutationResult<UpdateWeAbrechnungsdefinitionTemplateVersionMutation>;
export type UpdateWeAbrechnungsdefinitionTemplateVersionMutationOptions = Apollo.BaseMutationOptions<
  UpdateWeAbrechnungsdefinitionTemplateVersionMutation,
  UpdateWeAbrechnungsdefinitionTemplateVersionMutationVariables
>;
export const DeleteWeAbrechnungsdefinitionTemplateVersionDocument = gql`
  mutation DeleteWeAbrechnungsdefinitionTemplateVersion($abrechnungsdefinitionId: ID!, $abrechnungsdefinitionVersionId: ID!) {
    deleteWeAbrechnungsdefinitionTemplateVersion(
      abrechnungsdefinitionId: $abrechnungsdefinitionId
      abrechnungsdefinitionVersionId: $abrechnungsdefinitionVersionId
    ) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteWeAbrechnungsdefinitionTemplateVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteWeAbrechnungsdefinitionTemplateVersionMutation,
    DeleteWeAbrechnungsdefinitionTemplateVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteWeAbrechnungsdefinitionTemplateVersionMutation, DeleteWeAbrechnungsdefinitionTemplateVersionMutationVariables>(
    DeleteWeAbrechnungsdefinitionTemplateVersionDocument,
    options
  );
}
export type DeleteWeAbrechnungsdefinitionTemplateVersionMutationHookResult = ReturnType<
  typeof useDeleteWeAbrechnungsdefinitionTemplateVersionMutation
>;
export type DeleteWeAbrechnungsdefinitionTemplateVersionMutationResult = Apollo.MutationResult<DeleteWeAbrechnungsdefinitionTemplateVersionMutation>;
export type DeleteWeAbrechnungsdefinitionTemplateVersionMutationOptions = Apollo.BaseMutationOptions<
  DeleteWeAbrechnungsdefinitionTemplateVersionMutation,
  DeleteWeAbrechnungsdefinitionTemplateVersionMutationVariables
>;
