import React, { FC, MutableRefObject } from 'react';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import { LexicalEditor as LexicalEditorType } from 'lexical';

import { OnChangeDebouncePlugin } from './OnChangeDebouncePlugin';
import ToolbarPlugin from './ToolbarPlugin';
import './style/style.css';

type Props = {
  disabled?: boolean;
  onChange: (editorStateJson: string, editorText: string) => void;
  editorRef: React.RefCallback<LexicalEditorType> | MutableRefObject<LexicalEditorType | null | undefined>;
};

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
export const onError = (error: any) => {
  console.error('error', error);
};

const placeholder = 'Geben Sie einen Text ein...';

const LexicalEditor: FC<Props> = ({ disabled, onChange, editorRef }) => {
  return (
    <div className="editor-container">
      <ToolbarPlugin disabled={disabled} />
      <div className="editor-inner">
        <RichTextPlugin
          contentEditable={
            <ContentEditable
              className="editor-input"
              aria-placeholder={placeholder}
              placeholder={<div className="editor-placeholder">{placeholder}</div>}
            />
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        <AutoFocusPlugin />
        <EditorRefPlugin editorRef={editorRef} />
        <OnChangeDebouncePlugin onChange={onChange} wait={300} />
      </div>
    </div>
  );
};

export default LexicalEditor;
