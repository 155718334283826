import React, { FC } from 'react';
import { Space, Tag } from 'antd';
import { AuftragCreationStatus, AuftragCreationStatusTuple } from '../../../types';
import theme from '../../../theme';

type Props = {
  auftragCreationStatus: AuftragCreationStatusTuple;
  countAuftragCreated?: number | null;
  countAuftragToCreateTotal?: number | null;
};

const AuftragCreationStatusTag: FC<Props> = ({ auftragCreationStatus, countAuftragCreated, countAuftragToCreateTotal }) => {
  let color;
  switch (auftragCreationStatus.value) {
    case AuftragCreationStatus.Created:
      color = 'green';
      break;
    case AuftragCreationStatus.NotCreatable:
      color = theme.textColor.disabled;
      break;
  }

  const created = countAuftragCreated || 0;
  const total = countAuftragToCreateTotal || 0;

  const counterContent =
    auftragCreationStatus.value === AuftragCreationStatus.Created && created < total ? (
      <span>
        ({created} / {total})
      </span>
    ) : null;

  return (
    <Tag color={color}>
      <Space size={4}>
        <span>{auftragCreationStatus.text}</span>
        {counterContent}
      </Space>
    </Tag>
  );
};

export default AuftragCreationStatusTag;
