import React, { FC } from 'react';
import { Space, Tooltip } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import EntityWarningList from '../../../../../components/message/EntityWarningList';
import PaymentStatusTag from './PaymentStatusTag';
import { PaymentListEntry } from '../../../../../types';

type Props = {
  payment: PaymentListEntry;
  fontWeightOfTagText?: 'normal' | 'bold';
};

const PaymentStatusColumn: FC<Props> = ({ payment, fontWeightOfTagText }) => {
  return (
    <Space size={2}>
      <PaymentStatusTag status={payment.status} fontWeight={fontWeightOfTagText} />
      <EntityWarningList warningList={payment.warningList} icon={<InfoCircleTwoTone style={{ cursor: 'help' }} />} />
      {/* INFO: Comment only returns value when PaymentTransactionType Online-Banking */}
      {payment.comment && (
        <Tooltip title={payment.comment}>
          <InfoCircleTwoTone style={{ cursor: 'help' }} />
        </Tooltip>
      )}
      {/* INFO: StatusReasonList only returns value when PaymentTransactionType Ebics */}
      {payment.statusReasonList.length > 0 && (
        <Tooltip
          title={payment.statusReasonList.map((reason) => (
            <div>{reason}</div>
          ))}
        >
          <InfoCircleTwoTone style={{ cursor: 'help' }} />
        </Tooltip>
      )}
    </Space>
  );
};

export default PaymentStatusColumn;
