import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { RechtstraegerFieldsFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RechnungsAusstellerListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type RechnungsAusstellerListQuery = {
  getRechnungsAusstellerList: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      fibuKontoCreatable: boolean;
      kundennummer: string;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      bankDetailsList: Array<{
        accountHolder: string;
        accountLimit?: number | null;
        assignedHeVertragZahlungsregelMap: any;
        assignedObjektMap: any;
        bankDetailsId: string;
        bankname: string;
        bic: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        ebicsCreditTransferUserList: Array<string>;
        ebicsDirectDebitUserList: Array<string>;
        fibuKontoId?: string | null;
        fibuKontonummer?: string | null;
        iban: string;
        updatedByMitarbeiterId?: string | null;
        fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
        fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
        sepaMandat?: {
          fileId?: string | null;
          fileName?: string | null;
          sepaMandatId: string;
          sepaMandatReference: string;
          signatureDate?: string | null;
          firmendaten?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firmendatenId: string;
            kundenSystemId: string;
            kurzBezeichnung: string;
            name1: string;
            name2?: string | null;
            name3?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechnungsAussteller?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
          warningList: Array<{ message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      personenbezugList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        personenbezugId: string;
        tagList: Array<string>;
        person: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechtstraegerSteuerpflicht?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        rechtstraegerSteuerpflichtId: string;
        validFrom: string;
        historicizedList?: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ message: string; type: string }>;
        }> | null;
        steuerpflicht: { text: string; value: Types.Steuerpflicht };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
      sprache: { text: string; value: Types.Sprache };
      status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
      type: { value: Types.RechtstraegerType; text: string };
      vertragSummary?: {
        heVertragCount: number;
        heVertragVerwaltungCount: number;
        mietVertragCount: number;
        mietVertragVerwaltungCount: number;
        weSubverwaltungCount: number;
        weVertragCount: number;
        weVertragVerwaltungCount: number;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RechnungsAusstellerListDocument = gql`
  query RechnungsAusstellerList {
    getRechnungsAusstellerList {
      data {
        ...RechtstraegerFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RechtstraegerFieldsFragmentDoc}
`;
export function useRechnungsAusstellerListQuery(
  baseOptions?: Apollo.QueryHookOptions<RechnungsAusstellerListQuery, RechnungsAusstellerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechnungsAusstellerListQuery, RechnungsAusstellerListQueryVariables>(RechnungsAusstellerListDocument, options);
}
export function useRechnungsAusstellerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RechnungsAusstellerListQuery, RechnungsAusstellerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechnungsAusstellerListQuery, RechnungsAusstellerListQueryVariables>(RechnungsAusstellerListDocument, options);
}
export function useRechnungsAusstellerListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RechnungsAusstellerListQuery, RechnungsAusstellerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechnungsAusstellerListQuery, RechnungsAusstellerListQueryVariables>(RechnungsAusstellerListDocument, options);
}
export type RechnungsAusstellerListQueryHookResult = ReturnType<typeof useRechnungsAusstellerListQuery>;
export type RechnungsAusstellerListLazyQueryHookResult = ReturnType<typeof useRechnungsAusstellerListLazyQuery>;
export type RechnungsAusstellerListSuspenseQueryHookResult = ReturnType<typeof useRechnungsAusstellerListSuspenseQuery>;
export type RechnungsAusstellerListQueryResult = Apollo.QueryResult<RechnungsAusstellerListQuery, RechnungsAusstellerListQueryVariables>;
