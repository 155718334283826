import React, { FC } from 'react';
import LoadingPage from '../../../components/Loading/LoadingPage';
import { isInvoiceTypeSingleInvoice } from '../../../features/IncomingInvoice/Form/incomingInvoiceFormHelpers';
import { SingleInvoice, StandingInvoice } from '../../../types';
import { useIncomingInvoiceQuery } from '../../../features/IncomingInvoice/gql/IncomingInvoiceQueries.types';
import BookingDetails from '../../../features/BookingDetails/View/WithoutBookingSuggestion/BookingDetails';

type Props = {
  buchungIdList: string[];
  belegId: string;
};

const BookingDetailsIncomingInvoicePage: FC<Props> = ({ buchungIdList, belegId }) => {
  const { loading: loadingEingangsRechnung, data: maybeEingangsRechnung } = useIncomingInvoiceQuery({
    variables: { belegId },
    notifyOnNetworkStatusChange: true,
  });
  const rechnung = maybeEingangsRechnung?.getIncomingInvoice.data;

  if (!rechnung || loadingEingangsRechnung) {
    return <LoadingPage loading={loadingEingangsRechnung} />;
  }

  let buchungErrorList;

  if (isInvoiceTypeSingleInvoice(rechnung.type)) {
    buchungErrorList = (rechnung as SingleInvoice).buchungErrorList;
  } else {
    buchungErrorList = (rechnung as StandingInvoice).partialAmountList.map((partialAmount) => partialAmount.buchungErrorList).flat();
  }

  return (
    <BookingDetails
      belegId={rechnung.belegId}
      buchungIdList={buchungIdList}
      errorList={buchungErrorList}
      // INFO: Deviations are not possible for standing invoices
      includeDeviations={isInvoiceTypeSingleInvoice(rechnung.type)}
    />
  );
};

export default BookingDetailsIncomingInvoicePage;
