import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { ObjektBaseFragmentDoc } from '../../Objekt/gql/ObjektFragments.types';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
import { BestandseinheitBaseFieldsFragmentDoc } from '../../Bestandseinheit/gql/BestandseinheitFragments.types';
import { AufteilungsschluesselFieldsFragmentDoc } from '../../Aufteilungsschluessel/gql/AufteilungsschluesselFragments.types';
import { VertragBaseFieldsFragmentDoc } from '../../Vertrag/gql/VertragFragments.types';
export type UstVomAufwandFieldsFragment = {
  belegnummer?: string | null;
  bisInklusive: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  fibuBuchungIdList: Array<string>;
  lastBuchungsdatum?: string | null;
  sumNetto: number;
  sumSteuer: number;
  sumUstVomAufwand: number;
  updatedByMitarbeiterId?: string | null;
  ustVomAufwandId: string;
  vonInklusive: string;
  buchungErrorList: Array<{ message: string; type: string }>;
  buchungStatus: { description?: string | null; text: string; value: Types.UstVomAufwandBuchungStatus };
  errorList: Array<{ message: string; type: string }>;
  objekt: {
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    objektId: string;
    updatedByMitarbeiterId?: string | null;
    rechnungsAussteller: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    status: { text: string; value: Types.ObjektStatus; description?: string | null };
    verwaltungsart: { text: string; value: Types.Verwaltungsart };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  status: { description?: string | null; text: string; value: Types.UstVomAufwandStatus };
  ustVomAufwandVertragList: Array<{
    fibuBuchungIdList: Array<string>;
    sumNetto: number;
    sumSteuer: number;
    sumUstVomAufwand: number;
    bestandseinheit: {
      bestandseinheitId: string;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      objektId: string;
      status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    repFondsKontoList: Array<{
      repFondsKontoId: string;
      sumNetto: number;
      sumSteuer: number;
      sumUstVomAufwand: number;
      aufwandsKontoList: Array<{
        aufwandsKontoId: string;
        sumNetto: number;
        sumSteuer: number;
        sumUstVomAufwand: number;
        aufteilungList: Array<{
          anteilTopAufteilungsschluessel: number;
          belegFileId: string;
          belegId: string;
          belegnummer: string;
          buchungId: string;
          buchungsdatum: string;
          einheitskosten: number;
          gesamtAnteilAufteilungsschluessel: number;
          netto: number;
          standingInvoicePartialAmountId?: string | null;
          steuer: number;
          ustVomAufwandAnteil: number;
          ustVomAufwandSteuersatz: number;
          aufteilungsschluessel: {
            aufteilungsschluesselId: string;
            bezeichnung: string;
            bezugsbasisEditable?: boolean | null;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            kurzBezeichnung: string;
            updatedByMitarbeiterId?: string | null;
            bezugsbasis: { text: string; value: Types.Bezugsbasis };
            masseinheit: { text: string; value: Types.Masseinheit };
            status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          lieferant: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
        }>;
      }>;
    }>;
    vertrag: {
      bestandseinheitId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      objektId: string;
      vertragId: string;
      vertragsBeginn: string;
      status: { text: string; value: Types.VertragStatus; description?: string | null };
      vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
      vertragspartner: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
  }>;
  vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const UstVomAufwandFieldsFragmentDoc = gql`
  fragment UstVomAufwandFields on UstVomAufwand {
    belegnummer
    bisInklusive
    buchungErrorList {
      message
      type
    }
    buchungStatus {
      description
      text
      value
    }
    createTs
    createdBy
    createdByMitarbeiterId
    errorList {
      message
      type
    }
    fibuBuchungIdList
    lastBuchungsdatum
    objekt {
      ...ObjektBase
    }
    status {
      description
      text
      value
    }
    sumNetto
    sumSteuer
    sumUstVomAufwand
    updatedByMitarbeiterId
    ustVomAufwandId
    ustVomAufwandVertragList {
      bestandseinheit {
        ...BestandseinheitBaseFields
      }
      fibuBuchungIdList
      repFondsKontoList {
        aufwandsKontoList {
          aufteilungList {
            anteilTopAufteilungsschluessel
            aufteilungsschluessel {
              ...AufteilungsschluesselFields
            }
            belegFileId
            belegId
            belegnummer
            buchungId
            buchungsdatum
            einheitskosten
            gesamtAnteilAufteilungsschluessel
            lieferant {
              ...RechtstraegerBaseFields
            }
            netto
            standingInvoicePartialAmountId
            steuer
            ustVomAufwandAnteil
            ustVomAufwandSteuersatz
          }
          aufwandsKontoId
          sumNetto
          sumSteuer
          sumUstVomAufwand
        }
        repFondsKontoId
        sumNetto
        sumSteuer
        sumUstVomAufwand
      }
      sumNetto
      sumSteuer
      sumUstVomAufwand
      vertrag {
        ...VertragBaseFields
      }
    }
    vertragsart {
      supportsExplicitVertragsEnde
      text
      value
    }
    vonInklusive
    warningList {
      attribute
      message
      type
    }
  }
  ${ObjektBaseFragmentDoc}
  ${BestandseinheitBaseFieldsFragmentDoc}
  ${AufteilungsschluesselFieldsFragmentDoc}
  ${RechtstraegerBaseFieldsFragmentDoc}
  ${VertragBaseFieldsFragmentDoc}
`;
