import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Radio } from 'formik-antd';
import { PaymentProposalCreateFormValues } from './createFormMapper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';

type Props = {
  name: string;
  formikProps: FormikProps<PaymentProposalCreateFormValues>;
};

const ZahlungsverkehrsartFormItem: FC<Props> = ({ formikProps, name }) => {
  return (
    <FormItemWithoutColon name={name} label="Zahlungen für Zahlungsverkehrsart inkludieren">
      <Radio.Group
        name={name}
        id={name}
        size="small"
        block
        value={formikProps.values.ebicsOnly}
        defaultValue={false}
        style={{ minWidth: '200px', height: 22 }}
      >
        <Radio.Button value={false}>EBICS und Onlinebanking</Radio.Button>
        <Radio.Button value>Nur EBICS</Radio.Button>
      </Radio.Group>
    </FormItemWithoutColon>
  );
};

export default ZahlungsverkehrsartFormItem;
