import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  mapFormValuesToVstKuerzungVerarbeitungFilters,
  VstKuerzungVerarbeitungEntryFiltersFormValues,
  vstKuerzungVerarbEntryListingFiltersFormInitialValues,
} from './Filters/ListingFiltersFormMapper';
import ListingFilters from './Filters/ListingFilters';
import VstKuerzungVerarbeitungEntryTable from './VstKuerzungVerarbeitungEntryTable';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { verarbeitungTypes } from '../../verarbeitungHelpers';
import { TVstKuerzungVerarbeitungQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import { VorsteuerkuerzungGenerierlauf } from '../../../../types';

type VorschreibungVerarbeitungEntryListingProps = {
  generierlauf: VorsteuerkuerzungGenerierlauf;
};

const VstKuerzungVerarbeitungEntryListing: FC<VorschreibungVerarbeitungEntryListingProps> = ({ generierlauf }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TVstKuerzungVerarbeitungQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(verarbeitungTypes.VorsteuerkuerzungErzeugen, generierlauf.generierlaufId, navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: VstKuerzungVerarbeitungEntryFiltersFormValues) => {
    updateQueryParams(
      verarbeitungTypes.VorsteuerkuerzungErzeugen,
      generierlauf.generierlaufId,
      navigate,
      mapFormValuesToVstKuerzungVerarbeitungFilters(values),
      paginationParams
    );
  };

  return (
    <Formik<VstKuerzungVerarbeitungEntryFiltersFormValues>
      initialValues={vstKuerzungVerarbEntryListingFiltersFormInitialValues(queryParams)}
      onSubmit={onChange}
    >
      {(formikProps) => (
        <>
          <ListingFilters formikProps={formikProps} entryList={generierlauf.entryList} />
          <VstKuerzungVerarbeitungEntryTable entryList={generierlauf.entryList} formikProps={formikProps} />
        </>
      )}
    </Formik>
  );
};

export default VstKuerzungVerarbeitungEntryListing;
