import { IndexSeriesSourceType, ThresholdType } from '../../../../../../../../../types';

export interface IndexSeriesPeriodFormValues {
  month: number;
  year: number;
}

export const isThresholdTypePercent = (thresholdType?: ThresholdType | null) => thresholdType === ThresholdType.Percent;

export const isIndexSeriesSourceTypeImport = (sourceType?: IndexSeriesSourceType | null) => sourceType === IndexSeriesSourceType.Import;
