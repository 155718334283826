import { FormFields } from '../../../../../helpers/formikHelper';
import { GenerierlaufEntryExitCode } from '../../../../../types';
import { TMahnungVerarbeitungQueryParams } from './filtersQueryParams';

export type MahnungVerarbeitungEntryListingFiltersFormValues = {
  rechnungsausstellerId?: string;
  objektId?: string;
  exitCodeList?: GenerierlaufEntryExitCode[];
};

export const mahnungVerarbEntryListingFiltersFormFields: FormFields<MahnungVerarbeitungEntryListingFiltersFormValues> = {
  objektId: 'objektId',
  exitCodeList: 'exitCodeList',
  rechnungsausstellerId: 'rechnungsausstellerId',
};

export const mahnungVerarbEntryListingFiltersFormInitialValues = (
  queryParams: TMahnungVerarbeitungQueryParams
): MahnungVerarbeitungEntryListingFiltersFormValues => ({
  objektId: queryParams.objektId,
  exitCodeList: queryParams.exitCodeList,
  rechnungsausstellerId: queryParams.rechnungsausstellerId,
});

export const mapFormValuesToMahnungVerarbeitungFilters = (
  formValues: MahnungVerarbeitungEntryListingFiltersFormValues
): TMahnungVerarbeitungQueryParams => ({
  objektId: formValues.objektId,
  exitCodeList: formValues.exitCodeList,
  rechnungsausstellerId: formValues.rechnungsausstellerId,
});
