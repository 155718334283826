import React from 'react';
import { Link } from 'react-router-dom';

import { TableColumnProps } from 'antd';
import { BestandseinheitBase, Budgeting, BudgetingResultVertrag } from '../../../../../types';
import { EuroAmount } from '../../../../../components/Number';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { generatePathToBeVertragDetailsPage } from '../../../../Vertrag/BeVertrag/beVertragUriPaths';
import { generatePathToRechtstraegerDetailsPage } from '../../../../Rechtstraeger/rechtstraegerHelper';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';

const budgetingResultVertragTableColumns = (
  budgeting: Budgeting,
  bestandseinheit: BestandseinheitBase
): TableColumnProps<BudgetingResultVertrag>[] => [
  {
    title: 'Vertrag',
    width: 200,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={20} text={record.vertrag.vertragsart.text}>
        {(shortenedText) => (
          <Link
            to={generatePathToBeVertragDetailsPage(budgeting.objekt.objektId, bestandseinheit.bestandseinheitId, record.vertrag.vertragId)}
            target="_blank"
          >
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Vertragspartner',
    align: 'right',
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={20} text={record.vertrag.vertragspartner.kurzBezeichnung}>
        {(shortenedText) => (
          <Link to={generatePathToRechtstraegerDetailsPage(record.vertrag.vertragspartner.rechtstraegerId)} target="_blank">
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Vertragsbeginn',
    align: 'right',
    render: (text, record) => <CustomFormattedDate value={record.vertrag.vertragsBeginn} />,
  },
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: 'Netto monatlich',
    align: 'right',
    render: (text, record) => <EuroAmount value={record.monthlyNetto} />,
  },
];

export default budgetingResultVertragTableColumns;
