import { FC } from 'react';
import { Formik } from 'formik';
import { TopAbrDefGeneralSettingsFormValues } from './topAbrDefGeneralSettingsFormMapper';
import { AbrechnungsdefinitionType, DueDateDisplayOptionsInput } from '../../../../../../../types';
import { topAbrDefGeneralSettingsValidationSchema } from './topAbrDefGeneralSettingsValidationSchema';
import TopAbrDefGeneralSettingsForm from './TopAbrDefGeneralSettingsForm';
import { BkOrWeAbrDefTopOutputOptionsFieldHelpNames } from '../../../../../abrDefFieldHelpHelpers/abrDefFieldHelpMapper';

type Props = {
  abrDefType: AbrechnungsdefinitionType;
  values: TopAbrDefGeneralSettingsFormValues;
  onSubmit?: (formValues: TopAbrDefGeneralSettingsFormValues) => void;
  setIsFormValid?: (isFormValid: boolean) => void;
  fieldHelpNames?: BkOrWeAbrDefTopOutputOptionsFieldHelpNames;
};

const TopAbrDefGeneralSettings: FC<Props> = ({ abrDefType, values, onSubmit, setIsFormValid, fieldHelpNames }) => {
  const isDisabled = !onSubmit;
  const onDisplayPaymentHistoryChange = (value: boolean) => onSubmit?.({ ...values, displayZahlungsverlauf: value });
  const onConsiderRueckstandChange = (value: boolean) => onSubmit?.({ ...values, considerRueckstand: value });
  const onRueckstandOtherPersonsChange = (displayRueckstandOtherPersons: boolean, rueckstandOtherPersonsThreshold: number | null) =>
    onSubmit?.({
      ...values,
      displayRueckstandOtherPersons,
      rueckstandOtherPersonsThreshold,
    });
  const onDueDateOptionsChange = (
    displayDueDate: boolean,
    dueDateDisplayOptionEigentuemerGuthaben?: DueDateDisplayOptionsInput | null,
    dueDateDisplayOptionEigentuemerNachzahlung?: DueDateDisplayOptionsInput | null,
    dueDateDisplayOptionMieter?: DueDateDisplayOptionsInput | null
  ) =>
    onSubmit?.({
      ...values,
      displayDueDate,
      dueDateDisplayOptionEigentuemerGuthaben,
      dueDateDisplayOptionEigentuemerNachzahlung,
      dueDateDisplayOptionMieter,
    });

  return (
    <Formik<TopAbrDefGeneralSettingsFormValues>
      initialValues={values}
      onSubmit={() => {}}
      validationSchema={topAbrDefGeneralSettingsValidationSchema(abrDefType)}
    >
      {(formikProps) => (
        <TopAbrDefGeneralSettingsForm
          abrDefType={abrDefType}
          isDisabled={isDisabled}
          formikProps={formikProps}
          onDisplayPaymentHistoryChange={onDisplayPaymentHistoryChange}
          onConsiderRueckstandChange={onConsiderRueckstandChange}
          onRueckstandOtherPersonsChange={onRueckstandOtherPersonsChange}
          onDueDateOptionsChange={onDueDateOptionsChange}
          setIsFormValid={setIsFormValid}
          fieldHelpNames={fieldHelpNames}
        />
      )}
    </Formik>
  );
};

export default TopAbrDefGeneralSettings;
