import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';

const TARepFondsAbrechnungListTitleText: FC<{ listTitleText: string }> = ({ listTitleText }) => (
  <View>
    <Text style={[pdfStyles.textNormal, { textDecoration: 'underline', marginTop: '5mm' }]}>{listTitleText}</Text>
  </View>
);

export default TARepFondsAbrechnungListTitleText;
