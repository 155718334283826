import * as Yup from 'yup';
import { entityIsRequired } from '../../../../../../components/message/validationMsg';
import { personCreateFormFields } from '../Create/personCreateFormMapper';

/* eslint-disable no-template-curly-in-string */
export const personValidationSchema = Yup.object().shape({
  [personCreateFormFields.rolle]: Yup.string().required(entityIsRequired('Rolle')),
  [personCreateFormFields.rechtstraegerId]: Yup.string().required(entityIsRequired('Person')),
});
/* eslint-enable no-template-curly-in-string */
