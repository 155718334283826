import React from 'react';
import { CalendarOutlined, CopyOutlined, GoldOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { LayersIcon } from '@radix-ui/react-icons';
import ObjektDetailsPageTopTabs from './ObjektDetailsPageTopTabs';
import DocumentTab from '../../features/Document/DocumentTab';
import { GroupedMenueListInterface } from '../../components/ContextSidebar/ContentWithSidebar';
import { pathsToObjektDetailsPage } from '../../features/Objekt/objektUriPaths';
import ObjektInfoFeldTab from '../../features/Objekt/InfoFeld/ObjektInfoFeldTab';
import { Objekt } from '../../types';
import { DOCUMENT_FILTERS_SETTINGS } from '../../features/Document/Listing/Filters/listingFiltersHelper';
import ObjektTicketTab from '../../features/Objekt/Ticket/ObjektTicketTab';
import { MenuItemInterface } from '../../components/ContextSidebar/ContextSidebar';
import ObjektSubAdministrationTab from '../../features/Objekt/SubAdministration/ObjektSubAdministrationTab';

const menuListObjekt = (objekt: Objekt, refetch: () => Promise<unknown>, isRefetchingObjekt: boolean): GroupedMenueListInterface => {
  const paths = pathsToObjektDetailsPage(objekt.objektId);

  const topMenuList: MenuItemInterface[] = [
    {
      title: 'Bestandseinheiten',
      icon: <LayersIcon />,
      uri: `${paths.topMenu}`,
      content: (props: MenuItemInterface) => (
        <ObjektDetailsPageTopTabs objekt={objekt} isRefetchingObjekt={isRefetchingObjekt} refetchCard={refetch} {...props} />
      ),
    },
    objekt.subAdministration && {
      title: 'Subverwaltungen',
      icon: <GoldOutlined />,
      uri: `${paths.objektDetails}/subverwaltungen`,
      content: () => <ObjektSubAdministrationTab objekt={objekt} />,
    },
    {
      title: 'Aufgaben',
      icon: <CalendarOutlined />,
      uri: `${paths.objektDetails}/aufgaben`,
      content: () => <ObjektTicketTab objekt={objekt} />,
    },
    {
      title: 'Dokumente',
      icon: <CopyOutlined />,
      uri: `${paths.objektDetails}/documents`,
      content: (props: MenuItemInterface) => (
        <DocumentTab
          objektId={objekt.objektId}
          disabledFilters={DOCUMENT_FILTERS_SETTINGS.objekt.disabled}
          defaultFilters={DOCUMENT_FILTERS_SETTINGS.objekt.default}
          {...props}
        />
      ),
    },
    {
      title: 'Infofelder',
      icon: <InfoCircleOutlined />,
      uri: `${paths.infoFeldMenu}`,
      content: () => <ObjektInfoFeldTab objekt={objekt} />,
    },
  ].filter((item) => !!item);

  return { topMenuList };
};

export default menuListObjekt;
