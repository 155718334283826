import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Space, Tooltip, Typography } from 'antd';
import { friendlyFormatIBAN } from 'ibantools';
import { EuroAmount } from '../../../../components/Number';
import VorschreibungObjektTopContent from '../../VorschreibungObjektTopContent';
import StatusBadge from '../../../../components/Status/StatusBadge';
import AvatarBasedOnFileInfoTypeWithTooltip from '../../../../components/Avatar/BasedOnFileInfoType/AvatarBasedOnFileInfoTypeWithTooltip';
import { AuftragListEntry, AuftragOrderBy } from '../../../../types';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { MitarbeiterTooltip } from '../../../../components/Card';
import LinkToBelegFile from '../../../../components/Link/LinkToBelegFile/LinkToBelegFile';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import VorschreibungTableActions from './VorschreibungTableActions';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import RechtstraegerWithContactsTooltip from '../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';
import { generatePathToBookingDetailsPage } from '../../../BookingDetails/bookingDetailsPaths';

export const TOOLTIP_TEXTS_LENGTH_DEFAULT = {
  aussteller: 18,
  empfaenger: 18,
  objektTop: 30,
};

export const TOOLTIP_TEXTS_LENGTH_ZINSLISTE = {
  aussteller: 18,
  empfaenger: 18,
  objektTop: 26,
};

const vorschreibungTableColumns = (useDefaultTextLength: boolean): TableWithColSelectorColumnProps<AuftragListEntry>[] => {
  return [
    {
      title: 'Bezeichnung',
      defaultSelected: true,
      render: (text, record) => (
        <Space size="small">
          <AvatarBasedOnFileInfoTypeWithTooltip fileType={record.auftragsart.type} size="small" />
          <Typography.Text>{record.auftragsart.bezeichnung}</Typography.Text>
        </Space>
      ),
    },
    {
      title: 'Vorschreibung-Typ',
      defaultSelected: true,
      dataIndex: AuftragOrderBy.AuftragsArt,
      sorter: true,
      render: (text, record) => {
        return (
          <Space direction="vertical" size={0}>
            {record.storniert ? (
              <Tooltip title="Storniert">
                <Badge dot status="warning">
                  <Typography.Text style={{ fontWeight: 400 }}>{record.auftragsart.type.text}</Typography.Text>
                </Badge>
              </Tooltip>
            ) : (
              <Typography.Text style={{ fontWeight: 400 }}>{record.auftragsart.type.text}</Typography.Text>
            )}
          </Space>
        );
      },
    },
    {
      title: 'Vertragsart',
      defaultSelected: true,
      dataIndex: AuftragOrderBy.Vertragsart,
      sorter: true,
      render: (text, record) => <Typography.Text>{record.vertragsart.text}</Typography.Text>,
    },
    {
      title: 'Zinsliste',
      defaultSelected: true,
      dataIndex: AuftragOrderBy.Fakturierungsperiode,
      sorter: true,
      render: (text, record) => (
        <Typography.Text>
          {record.fakturierungsperiode.monat} / {record.fakturierungsperiode.jahr}
        </Typography.Text>
      ),
    },
    {
      title: 'Rechnungsaussteller',
      defaultSelected: true,
      dataIndex: AuftragOrderBy.Aussteller,
      sorter: true,
      render: (text, record) => (
        <RechtstraegerWithContactsTooltip
          rechtstraegerId={record.aussteller.rechtstraegerId}
          rechtstraegerName={record.aussteller.kurzBezeichnung}
          maxTextLength={18}
        />
      ),
    },
    {
      title: 'Rechnungsaussteller Bankverbindung',
      dataIndex: AuftragOrderBy.AusstellerBankDetailsIban,
      sorter: true,
      render: (text, record) => <DataWithShortenedText maxTextLength={20} text={`${friendlyFormatIBAN(record.ausstellerBankDetails.iban)}`} />,
    },
    {
      title: 'Empfänger',
      defaultSelected: true,
      dataIndex: AuftragOrderBy.Empfaenger,
      sorter: true,
      render: (text, record) => (
        <RechtstraegerWithContactsTooltip
          rechtstraegerId={record.empfaenger.rechtstraegerId}
          rechtstraegerName={record.empfaenger.kurzBezeichnung}
          maxTextLength={18}
        />
      ),
    },
    {
      title: 'Empfänger Bankverbindung',
      dataIndex: AuftragOrderBy.EmpfaengerBankDetailsIban,
      sorter: true,
      render: (text, record) =>
        record.empfaengerBankDetails ? (
          <DataWithShortenedText maxTextLength={20} text={`${friendlyFormatIBAN(record.empfaengerBankDetails.iban)}`} />
        ) : (
          <TextForEmptyValue textToShow="minus" />
        ),
    },
    {
      title: 'Objekt-Top',
      defaultSelected: true,
      render: (text, record) => (
        <VorschreibungObjektTopContent
          bezeichnunglist={record.bestandseinheitWithObjektBezeichnungList}
          maxFirstTextLength={useDefaultTextLength ? TOOLTIP_TEXTS_LENGTH_DEFAULT.objektTop : TOOLTIP_TEXTS_LENGTH_ZINSLISTE.objektTop}
        />
      ),
    },
    {
      title: 'Fälligkeit',
      defaultSelected: true,
      dataIndex: AuftragOrderBy.DueDate,
      sorter: true,
      render: (text, record) => {
        return record.dueDate ? <CustomFormattedDate value={record.dueDate} /> : <TextForEmptyValue textToShow="minus" />;
      },
    },
    {
      title: 'Rechnung Nr.',
      defaultSelected: true,
      dataIndex: AuftragOrderBy.Belegnummer,
      sorter: true,
      render: (text, record) => {
        if (record.belegnummer && record.belegFileId) {
          return <LinkToBelegFile fileId={record.belegFileId} belegnummer={record.belegnummer} hideIcon />;
        }
        return record.belegnummer ? <Typography.Text>{record.belegnummer}</Typography.Text> : <TextForEmptyValue textToShow="minus" />;
      },
    },
    {
      title: 'Rechnungsdatum',
      dataIndex: AuftragOrderBy.RechnungsDatum,
      sorter: true,
      render: (text, record) => {
        return record.rechnungsDatum ? <CustomFormattedDate value={record.rechnungsDatum} /> : <TextForEmptyValue textToShow="minus" />;
      },
    },
    {
      title: 'Kundennummer',
      defaultSelected: true,
      dataIndex: AuftragOrderBy.Kundennummer,
      sorter: true,
      render: (text, record) => <Typography.Text>{record.empfaenger.kundennummer}</Typography.Text>,
    },
    {
      title: 'Zahlungsart',
      defaultSelected: true,
      dataIndex: AuftragOrderBy.PaymentMethod,
      sorter: true,
      render: (text, record) => <Typography.Text>{record.paymentMethod.text}</Typography.Text>,
    },
    {
      title: 'SEPA Creditor-ID',
      dataIndex: AuftragOrderBy.SepaCreditorId,
      sorter: true,
      render: (text, record) =>
        record.sepaCreditorId ? <Typography.Text>{record.sepaCreditorId}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
    },
    {
      title: 'SEPA Mandatsreferenz',
      dataIndex: AuftragOrderBy.SepaMandatReference,
      sorter: true,
      render: (text, record) =>
        record.sepaMandatReference ? <Typography.Text>{record.sepaMandatReference}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
    },
    {
      title: 'Netto',
      defaultSelected: true,
      dataIndex: AuftragOrderBy.Netto,
      sorter: true,
      align: 'right',
      render: (text, record) => <EuroAmount value={record.sumNetto} />,
    },
    {
      title: 'Ust.',
      defaultSelected: true,
      dataIndex: AuftragOrderBy.Ust,
      sorter: true,
      align: 'right',
      render: (text, record) => <EuroAmount value={record.sumUst} />,
    },
    {
      title: 'Brutto',
      defaultSelected: true,
      dataIndex: AuftragOrderBy.Brutto,
      sorter: true,
      align: 'right',
      render: (text, record) =>
        record.buchungIdList && record.buchungIdList.length > 0 ? (
          <Link to={generatePathToBookingDetailsPage(record.buchungIdList, record.auftragId)} target="_blank">
            <EuroAmount value={record.sumBrutto} />
          </Link>
        ) : (
          <EuroAmount value={record.sumBrutto} />
        ),
    },
    {
      title: 'Vorschreibung Status',
      defaultSelected: true,
      dataIndex: AuftragOrderBy.Status,
      sorter: true,
      render: (text, record) => (
        <StatusBadge entityStatus={record.status} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
      ),
    },
    {
      title: 'Buchung Status',
      defaultSelected: true,
      dataIndex: AuftragOrderBy.BuchungsanweisungStatus,
      sorter: true,
      render: (text, record) =>
        record.buchungsanweisungStatus ? <StatusBadge entityStatus={record.buchungsanweisungStatus} /> : <TextForEmptyValue textToShow="minus" />,
    },
    {
      title: 'Erstellt am',
      dataIndex: AuftragOrderBy.CreateTs,
      sorter: true,
      render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
    },
    {
      title: 'Ersteller',
      dataIndex: AuftragOrderBy.CreatedBy,
      sorter: true,
      render: (text, record) => (
        <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
      ),
    },
    {
      title: 'Aktion',
      defaultSelected: true,
      render: (text, record) => <VorschreibungTableActions auftrag={record} />,
    },
  ];
};

export default vorschreibungTableColumns;
