import React, { FC } from 'react';
import { Button, Divider, Drawer, Space, Tabs, TabsProps, Tooltip, Typography } from 'antd';
import { HistoryOutlined, WarningTwoTone } from '@ant-design/icons';
import { FormattedNumber } from 'react-intl';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { useTimelineOpen } from '../../../../components/Timeline/useTimelineOpen';
import ContextMenu from '../../../../components/Timeline/ContextMenu';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import ObjektSummeAnteileVersionTimeline from '../../SummeAnteile/VersionTimeline/ObjektSummeAnteileVersionTimeline';
import { useObjektSummeAnteileQuery } from '../../SummeAnteile/gql/ObjektSummeAnteileQueries.types';
import { LoadingCol } from '../../../../components/Loading';
import { WarningData } from '../../../../types';

type Props = {
  objektId: string;
  onSuccess: () => void;
  warningList: WarningData[];
  summeAnteileId?: string;
};

const InnerCardSummeAnteile: FC<Props> = ({ objektId, summeAnteileId, onSuccess, warningList }) => {
  const { timelineDrawer, openTimelineUpdateDrawer, closeTimelineDrawer } = useTimelineOpen();
  const { data, loading } = useObjektSummeAnteileQuery({
    variables: {
      objektId,
      // @ts-ignore
      summeAnteileId,
    },
    skip: !summeAnteileId,
  });

  const summeAnteileTooHighWarning = warningList.find((warning) => warning.type === 'HE_VERTRAG_SUMME_ANTEILE_TOO_HIGH');

  const objektSummeAnteile = data?.getObjektSummeAnteile.data;

  const tabItemsInsideOfTab: TabsProps['items'] = [
    {
      key: '1',
      label: 'Summe Anteile',
      children: <ObjektSummeAnteileVersionTimeline objektId={objektId} onSuccess={onSuccess} />,
    },
  ];

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span>
          <HistoryOutlined />
          Verlauf
        </span>
      ),
      children: <Tabs items={tabItemsInsideOfTab} size="small" />,
    },
  ];

  return (
    <>
      <Space style={{ width: '100%', justifyContent: 'space-between' }}>
        <Typography.Title level={5}>Hausanteile</Typography.Title>
        <ContextMenu openDrawerCreate={openTimelineUpdateDrawer} withHistory />
      </Space>
      <CardDetailsInfoRow
        infoRowTitle={
          summeAnteileTooHighWarning ? (
            <Space>
              <Typography.Text>Summe Anteile</Typography.Text>
              <Tooltip title={summeAnteileTooHighWarning.message}>
                <WarningTwoTone />
              </Tooltip>
            </Space>
          ) : (
            <Typography.Text>Summe Anteile</Typography.Text>
          )
        }
      >
        <LoadingCol isLoading={loading}>
          {objektSummeAnteile?.summeAnteile ? <FormattedNumber value={objektSummeAnteile.summeAnteile} /> : <TextForEmptyValue textToShow="minus" />}
        </LoadingCol>
      </CardDetailsInfoRow>
      <Divider style={{ marginTop: '8px', marginBottom: '8px' }} dashed />
      <Drawer
        title="Bearbeiten"
        width={720}
        open={timelineDrawer.visible}
        onClose={closeTimelineDrawer}
        destroyOnClose
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={closeTimelineDrawer} type="primary">
              Schließen
            </Button>
          </div>
        }
      >
        <Tabs items={items} />
      </Drawer>
    </>
  );
};

export default InnerCardSummeAnteile;
