import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { TPaymentProposalVerarbeitungQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import { mapFormValuesToQueryParams, FiltersFormValues, mapQueryParamsToFormValues } from './Filters/ListingFiltersFormMapper';
import ListingFilters from './Filters/ListingFilters';
import { PaymentProposalGenerierlauf } from '../../verarbeitungHelpers';
import { OrderGenerierlaufType } from '../../../../types';
import PaymentProposalCreateEntryTable from '../Create/PaymentProposalCreateEntryTable';
import PaymentProposalExecuteEntryTable from '../Execute/PaymentProposalExecuteEntryTable';

type Props = {
  generierlauf: PaymentProposalGenerierlauf;
  generierlaufType: OrderGenerierlaufType;
  verarbeitungType: string;
};

const PaymentProposalVerarbeitungEntryListing: FC<Props> = ({ generierlauf, generierlaufType, verarbeitungType }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TPaymentProposalVerarbeitungQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(verarbeitungType, generierlauf.generierlaufId, navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: FiltersFormValues) => {
    updateQueryParams(verarbeitungType, generierlauf.generierlaufId, navigate, mapFormValuesToQueryParams(values), paginationParams);
  };

  return (
    <Formik<FiltersFormValues> initialValues={mapQueryParamsToFormValues(queryParams)} onSubmit={onChange}>
      {(formikProps) => (
        <>
          <ListingFilters formikProps={formikProps} generierlaufId={generierlauf.generierlaufId} generierlaufType={generierlaufType} />
          {generierlaufType === OrderGenerierlaufType.PaymentProposalCreation && (
            <PaymentProposalCreateEntryTable generierlaufId={generierlauf.generierlaufId} queryParams={queryParams} />
          )}
          {generierlaufType === OrderGenerierlaufType.PaymentProposalExecution && (
            <PaymentProposalExecuteEntryTable generierlaufId={generierlauf.generierlaufId} queryParams={queryParams} />
          )}
        </>
      )}
    </Formik>
  );
};

export default PaymentProposalVerarbeitungEntryListing;
