import React, { FC } from 'react';
import { Form } from 'formik-antd';
import { Col, Row, Typography } from 'antd';
import { Formik } from 'formik';
import FormButtons from '../../components/Button/FormButtons';
import {
  indexSeriesEntryFormFields,
  IndexSeriesEntryFormValues,
  initialIndexSeriesEntryFormValues,
  mapFormValuesToIndexSeriesEntry,
} from './shared/Form/indexSeriesEntryFormMapper';
import IndexSeriesEntryYearPicker from './shared/IndexSeriesEntryYearPicker';
import { showSuccessMsgCreate } from '../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../helpers/apolloHelper';
import { useCreateIndexSeriesEntryMutation, useUpdateIndexSeriesEntryMutation } from './gql/IndexSeriesEntryMutations.types';
import { indexSeriesEntryFormValidationSchema } from './shared/Form/indexSeriesEntryFormValidationSchema';
import IndexPointListFieldArray from './shared/Form/IndexPointListFieldArray';
import { IndexSeriesEntry, IndexType } from '../../types';
import { isIndexSeriesIndexTypeIndex } from '../IndexSeries/indexSeriesHelpers';

type Props = {
  onAction: () => void;
  indexSeriesId: string;
  indexSeriesType: IndexType;
  indexSeriesEntry?: IndexSeriesEntry;
};

const IndexSeriesEntryForm: FC<Props> = ({ onAction, indexSeriesId, indexSeriesType, indexSeriesEntry }) => {
  const isUpdate = !!indexSeriesEntry;
  const entity = 'Jahr';

  const [runCreate, { loading: loadingCreate }] = useCreateIndexSeriesEntryMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateIndexSeriesEntryMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<IndexSeriesEntryFormValues>
      initialValues={initialIndexSeriesEntryFormValues(indexSeriesEntry)}
      validationSchema={indexSeriesEntryFormValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const input = mapFormValuesToIndexSeriesEntry(values);
        if (isUpdate) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          runUpdate({ variables: { indexSeriesId, indexSeriesEntryId: indexSeriesEntry.indexSeriesEntryId!, input } }).finally(() =>
            setSubmitting(false)
          );
        } else {
          runCreate({ variables: { indexSeriesId, input } }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form>
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Jahr</Typography.Title>
            </Col>
            <Col span={18}>
              <IndexSeriesEntryYearPicker name={indexSeriesEntryFormFields.year} />
            </Col>
          </Row>
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Monate</Typography.Title>
            </Col>
            <Col span={18}>
              <Row style={{ width: '100%', alignItems: 'baseline' }}>
                <Col span={6} />
                <Col span={6}>
                  <Typography.Text strong>Endgültig</Typography.Text>
                </Col>
                {isIndexSeriesIndexTypeIndex(indexSeriesType) && (
                  <Col span={6} style={{ marginLeft: '10px' }}>
                    <Typography.Text strong>Vorläufig</Typography.Text>
                  </Col>
                )}
              </Row>
              <IndexPointListFieldArray formikProps={formikProps} indexSeriesType={indexSeriesType} />
            </Col>
          </Row>
          <FormButtons
            onCancel={onAction}
            isOkDisabled={formikProps.isSubmitting}
            updateMode={isUpdate}
            isOkLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default IndexSeriesEntryForm;
