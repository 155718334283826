import React, { FC } from 'react';
import { FormItem, FormItemProps } from 'formik-antd';

type FormItemWithoutColonProps = Omit<FormItemProps, 'htmlFor' | 'colon'>;

/**
 *
 * <h2>Usage</h2>
 * Used instead of Form.Item (formik-antd) to keep the code consistent and use form items without colons.
 *
 * <h2>Component Info</h2>
 *  <ul>
 *    <li>Props htmlFor and colon are omitted</li>
 *    <li>Use name value as id in the children component as htmlFor value is set to name prop value</li>
 *  </ul>
 *
 */
const FormItemWithoutColon: FC<FormItemWithoutColonProps> = ({ children, ...restProps }) => (
  <FormItem hasFeedback={false} {...restProps} htmlFor={restProps.name} colon={false}>
    {children}
  </FormItem>
);

export default FormItemWithoutColon;
