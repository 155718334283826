import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useQueryParams } from '../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';
import { TReceivedBankTransactionQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import {
  FiltersFormValues,
  mapFormValuesToQueryParams,
  mapQueryParamsToReceivedBankTransactionListQueryVariables,
  receivedBankTransactionInitialValues,
} from './Filters/filtersFormMapper';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../hooks/useQueryWithPagingAndSorting';
import { useReceivedBankTransactionListQuery, useReceivedBankTransactionListSummaryQuery } from '../gql/ReceivedBankTransactionQueries.types';
import ListingFilters from './Filters/ListingFilters';
import ReceivedBankTransactionTable from './Table/ReceivedBankTransactionTable';
import ReceivedBankTransactionSummary from './ReceivedBankTransactionSummary';
import useOnReceivedBankTransactionDataChangedEvents from '../useOnReceivedBankTransactionDataChangedEvents';
import useOnBankStatementDataChangedEvents from '../useOnBankStatementDataChangedEvents';
import { useShowArchivedQueryParamState } from '../../../hooks/useShowArchivedQueryParamState';

const ReceivedBankTransactionListing = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TReceivedBankTransactionQueryParams>();
  const paginationParams = usePaginationQueryParams();
  const [showArchived, toggleShowArchived] = useShowArchivedQueryParamState();

  const setInitialQueryParams = () => {
    updateQueryParams(navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: FiltersFormValues) => {
    const filters = mapFormValuesToQueryParams(values);
    updateQueryParams(navigate, filters, paginationParams);
  };

  const { data, loading, refetch, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useReceivedBankTransactionListQuery,
    {
      variables: {
        ...mapQueryParamsToReceivedBankTransactionListQueryVariables(queryParams),
      },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: paginationParams.currentPage ? Number(paginationParams.currentPage) : DEFAULT_CURRENT,
      pageSize: paginationParams.pageSize ? Number(paginationParams.pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const list = data?.getReceivedBankTransactionList.data.contentList;

  const { data: summaryData, refetch: summaryRefetch } = useReceivedBankTransactionListSummaryQuery({
    variables: {
      ...mapQueryParamsToReceivedBankTransactionListQueryVariables(queryParams),
    },
  });

  const summary = summaryData?.getReceivedBankTransactionListSummary.data;

  const onAction = () => refetch().then(() => summaryRefetch());

  useOnReceivedBankTransactionDataChangedEvents('received-bank-transaction-listing', onAction);
  useOnBankStatementDataChangedEvents('bank-statement-listing', onAction);

  return (
    <>
      <Formik<FiltersFormValues> initialValues={receivedBankTransactionInitialValues(queryParams)} onSubmit={onChange}>
        {(formikProps) => (
          <ListingFilters
            queryParams={queryParams}
            formikProps={formikProps}
            showArchived={showArchived}
            toggleShowArchived={toggleShowArchived}
            onAction={onAction}
          />
        )}
      </Formik>
      <ReceivedBankTransactionSummary summary={summary} />
      <ReceivedBankTransactionTable
        receivedBankTransactionList={list}
        loading={loading}
        pagination={pagination}
        handleTableSorting={handleTableSorting}
        onAction={onAction}
      />
    </>
  );
};

export default ReceivedBankTransactionListing;
