import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { ZinslisteListEntryFragmentDoc, ZinslisteFragmentDoc } from './ZinslisteFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ZinslisteListQueryVariables = Types.Exact<{
  bruttoFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  bruttoTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  erstellDatumBisInklusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  erstellDatumVonInklusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  erstellerMitarbeiterIdList?: Types.InputMaybe<
    Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>
  >;
  nettoFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  nettoTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  objektId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  status?: Types.InputMaybe<Types.ZinslisteStatus>;
  vorschreibungsperiodeFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  vorschreibungsperiodeTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  zinslisteIdList?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>>;
  order?: Types.InputMaybe<Types.ZinslisteOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type ZinslisteListQuery = {
  list: {
    data: {
      contentList: Array<{
        countVorschreibungsposition?: number | null;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        generierlaufId?: string | null;
        objektId: string;
        objektKurzbezeichnung: string;
        sumBrutto?: number | null;
        sumNetto?: number | null;
        sumUst?: number | null;
        zinslisteId: string;
        status: { text: string; value: Types.ZinslisteStatus; description?: string | null };
        vorschreibungsperiode: { jahr: number; monat: number };
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
  };
};

export type ZinslisteQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  zinslisteId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type ZinslisteQuery = {
  getZinsliste: {
    data: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      sumBrutto?: number | null;
      sumNetto?: number | null;
      sumUst?: number | null;
      bestandseinheitList: Array<{
        bestandseinheitId: string;
        bezeichnung: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        aufteilungsschluesselList: Array<{
          aufteilungsschluesselId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          wert: number;
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        }>;
        vertragList: Array<{
          ausstellerBankDetailsId?: string | null;
          ausstellerId: string;
          ausstellerKurzBezeichnung: string;
          consolidationCriteria?: string | null;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          vertragId: string;
          vertragspartnerBankDetailsId?: string | null;
          belegform: { text: string; value: Types.BelegFormArt };
          dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
          paymentMethod: { text: string; value: Types.PaymentMethod };
          verrechnungsart: { text: string; value: Types.Verrechnungsart };
          vertragVorschreibungspositionList: Array<{
            bezeichnung: string;
            brutto?: number | null;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            netto?: number | null;
            performancePeriodFromInclusive: string;
            performancePeriodToInclusive: string;
            steuersatz?: number | null;
            ust?: number | null;
            vorschreibungspositionId: string;
            zinslisteVertragVorschrPosId: string;
            warningList: Array<{ message: string; type: string; attribute?: string | null }>;
          }>;
          vertragLevel: { shortName: string; text: string; value: Types.VertragLevel };
          vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
          vertragspartner: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        }>;
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      }>;
      kopfdaten: {
        generierDatum: string;
        generierlaufId?: string | null;
        objektId: string;
        objektKurzBezeichnung: string;
        zinslisteId: string;
        vorschreibungsperiode: { jahr: number; monat: number };
      };
      status: { description?: string | null; text: string; value: Types.ZinslisteStatus };
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
    };
  };
};

export type ZinslisteSelectQueryVariables = Types.Exact<{
  vorschreibungsperiodeFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  vorschreibungsperiodeTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  objektId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  order?: Types.InputMaybe<Types.ZinslisteOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  zinslisteIdList?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>>;
}>;

export type ZinslisteSelectQuery = {
  getZinslisteListForFirmendaten: {
    data: {
      contentList: Array<{
        countVorschreibungsposition?: number | null;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        generierlaufId?: string | null;
        objektId: string;
        objektKurzbezeichnung: string;
        sumBrutto?: number | null;
        sumNetto?: number | null;
        sumUst?: number | null;
        zinslisteId: string;
        status: { text: string; value: Types.ZinslisteStatus; description?: string | null };
        vorschreibungsperiode: { jahr: number; monat: number };
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
  };
};

export type ZinslisteVerarbeitungsstandQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  zinslisteId: Types.Scalars['ID']['input'];
}>;

export type ZinslisteVerarbeitungsstandQuery = {
  getZinslisteVerarbeitungsstand: {
    data: { countAusgegeben: number; countErstellt: number; countFreigegeben: number; countNichtErstellt: number; countUebertragen: number };
    warningList: Array<{ message: string; type: string }>;
  };
};

export const ZinslisteListDocument = gql`
  query ZinslisteList(
    $bruttoFrom: Float
    $bruttoTo: Float
    $erstellDatumBisInklusive: String
    $erstellDatumVonInklusive: String
    $erstellerMitarbeiterIdList: [String]
    $nettoFrom: Float
    $nettoTo: Float
    $objektId: ID
    $status: ZinslisteStatus
    $vorschreibungsperiodeFrom: String
    $vorschreibungsperiodeTo: String
    $zinslisteIdList: [String]
    $order: ZinslisteOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    list: getZinslisteListForFirmendaten(
      bruttoFrom: $bruttoFrom
      bruttoTo: $bruttoTo
      erstellDatumBisInklusive: $erstellDatumBisInklusive
      erstellDatumVonInklusive: $erstellDatumVonInklusive
      erstellerMitarbeiterIdList: $erstellerMitarbeiterIdList
      nettoFrom: $nettoFrom
      nettoTo: $nettoTo
      objektId: $objektId
      status: $status
      vorschreibungsperiodeFrom: $vorschreibungsperiodeFrom
      vorschreibungsperiodeTo: $vorschreibungsperiodeTo
      zinslisteIdList: $zinslisteIdList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          ...ZinslisteListEntry
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
    }
  }
  ${ZinslisteListEntryFragmentDoc}
`;
export function useZinslisteListQuery(baseOptions?: Apollo.QueryHookOptions<ZinslisteListQuery, ZinslisteListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZinslisteListQuery, ZinslisteListQueryVariables>(ZinslisteListDocument, options);
}
export function useZinslisteListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZinslisteListQuery, ZinslisteListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZinslisteListQuery, ZinslisteListQueryVariables>(ZinslisteListDocument, options);
}
export function useZinslisteListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ZinslisteListQuery, ZinslisteListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ZinslisteListQuery, ZinslisteListQueryVariables>(ZinslisteListDocument, options);
}
export type ZinslisteListQueryHookResult = ReturnType<typeof useZinslisteListQuery>;
export type ZinslisteListLazyQueryHookResult = ReturnType<typeof useZinslisteListLazyQuery>;
export type ZinslisteListSuspenseQueryHookResult = ReturnType<typeof useZinslisteListSuspenseQuery>;
export type ZinslisteListQueryResult = Apollo.QueryResult<ZinslisteListQuery, ZinslisteListQueryVariables>;
export const ZinslisteDocument = gql`
  query Zinsliste($objektId: ID!, $zinslisteId: ID!, $withDetails: Boolean) {
    getZinsliste(objektId: $objektId, zinslisteId: $zinslisteId, withDetails: $withDetails) {
      data {
        ...Zinsliste
      }
    }
  }
  ${ZinslisteFragmentDoc}
`;
export function useZinslisteQuery(
  baseOptions: Apollo.QueryHookOptions<ZinslisteQuery, ZinslisteQueryVariables> &
    ({ variables: ZinslisteQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZinslisteQuery, ZinslisteQueryVariables>(ZinslisteDocument, options);
}
export function useZinslisteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZinslisteQuery, ZinslisteQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZinslisteQuery, ZinslisteQueryVariables>(ZinslisteDocument, options);
}
export function useZinslisteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ZinslisteQuery, ZinslisteQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ZinslisteQuery, ZinslisteQueryVariables>(ZinslisteDocument, options);
}
export type ZinslisteQueryHookResult = ReturnType<typeof useZinslisteQuery>;
export type ZinslisteLazyQueryHookResult = ReturnType<typeof useZinslisteLazyQuery>;
export type ZinslisteSuspenseQueryHookResult = ReturnType<typeof useZinslisteSuspenseQuery>;
export type ZinslisteQueryResult = Apollo.QueryResult<ZinslisteQuery, ZinslisteQueryVariables>;
export const ZinslisteSelectDocument = gql`
  query ZinslisteSelect(
    $vorschreibungsperiodeFrom: String
    $vorschreibungsperiodeTo: String
    $objektId: ID
    $order: ZinslisteOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
    $zinslisteIdList: [String]
  ) {
    getZinslisteListForFirmendaten(
      vorschreibungsperiodeFrom: $vorschreibungsperiodeFrom
      vorschreibungsperiodeTo: $vorschreibungsperiodeTo
      objektId: $objektId
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
      zinslisteIdList: $zinslisteIdList
    ) {
      data {
        contentList {
          ...ZinslisteListEntry
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
    }
  }
  ${ZinslisteListEntryFragmentDoc}
`;
export function useZinslisteSelectQuery(baseOptions?: Apollo.QueryHookOptions<ZinslisteSelectQuery, ZinslisteSelectQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZinslisteSelectQuery, ZinslisteSelectQueryVariables>(ZinslisteSelectDocument, options);
}
export function useZinslisteSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZinslisteSelectQuery, ZinslisteSelectQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZinslisteSelectQuery, ZinslisteSelectQueryVariables>(ZinslisteSelectDocument, options);
}
export function useZinslisteSelectSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ZinslisteSelectQuery, ZinslisteSelectQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ZinslisteSelectQuery, ZinslisteSelectQueryVariables>(ZinslisteSelectDocument, options);
}
export type ZinslisteSelectQueryHookResult = ReturnType<typeof useZinslisteSelectQuery>;
export type ZinslisteSelectLazyQueryHookResult = ReturnType<typeof useZinslisteSelectLazyQuery>;
export type ZinslisteSelectSuspenseQueryHookResult = ReturnType<typeof useZinslisteSelectSuspenseQuery>;
export type ZinslisteSelectQueryResult = Apollo.QueryResult<ZinslisteSelectQuery, ZinslisteSelectQueryVariables>;
export const ZinslisteVerarbeitungsstandDocument = gql`
  query ZinslisteVerarbeitungsstand($objektId: ID!, $zinslisteId: ID!) {
    getZinslisteVerarbeitungsstand(objektId: $objektId, zinslisteId: $zinslisteId) {
      data {
        countAusgegeben
        countErstellt
        countFreigegeben
        countNichtErstellt
        countUebertragen
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useZinslisteVerarbeitungsstandQuery(
  baseOptions: Apollo.QueryHookOptions<ZinslisteVerarbeitungsstandQuery, ZinslisteVerarbeitungsstandQueryVariables> &
    ({ variables: ZinslisteVerarbeitungsstandQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZinslisteVerarbeitungsstandQuery, ZinslisteVerarbeitungsstandQueryVariables>(ZinslisteVerarbeitungsstandDocument, options);
}
export function useZinslisteVerarbeitungsstandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ZinslisteVerarbeitungsstandQuery, ZinslisteVerarbeitungsstandQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZinslisteVerarbeitungsstandQuery, ZinslisteVerarbeitungsstandQueryVariables>(
    ZinslisteVerarbeitungsstandDocument,
    options
  );
}
export function useZinslisteVerarbeitungsstandSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ZinslisteVerarbeitungsstandQuery, ZinslisteVerarbeitungsstandQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ZinslisteVerarbeitungsstandQuery, ZinslisteVerarbeitungsstandQueryVariables>(
    ZinslisteVerarbeitungsstandDocument,
    options
  );
}
export type ZinslisteVerarbeitungsstandQueryHookResult = ReturnType<typeof useZinslisteVerarbeitungsstandQuery>;
export type ZinslisteVerarbeitungsstandLazyQueryHookResult = ReturnType<typeof useZinslisteVerarbeitungsstandLazyQuery>;
export type ZinslisteVerarbeitungsstandSuspenseQueryHookResult = ReturnType<typeof useZinslisteVerarbeitungsstandSuspenseQuery>;
export type ZinslisteVerarbeitungsstandQueryResult = Apollo.QueryResult<ZinslisteVerarbeitungsstandQuery, ZinslisteVerarbeitungsstandQueryVariables>;
