import React, { FC } from 'react';
import { Input } from 'formik-antd';
import { newEmailValidationSchema } from './validationSchema';
import { validateEmailAsync, validateEmailSync } from './emailValidation';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';

type Props = {
  emailInputName?: string;
  emailInputPlaceholder?: string;
  emailInputLabel?: string;
  fieldHelp?: string | null;
};

const NewEmailFormPart: FC<Props> = ({
  emailInputName = 'email',
  emailInputPlaceholder = 'E-Mail-Adresse',
  emailInputLabel = 'E-Mail-Adresse',
  fieldHelp,
}) => {
  return (
    <FormItemWithFieldHelp name={emailInputName} label={emailInputLabel} validate={validateNewEmailRequired} fieldHelp={fieldHelp}>
      <Input id={emailInputName} name={emailInputName} placeholder={emailInputPlaceholder} />
    </FormItemWithFieldHelp>
  );
};

const validateNewEmailRequired = (email: string) => validateEmailSync(email, newEmailValidationSchema) || validateEmailAsync(email);

export default NewEmailFormPart;
