import { FormFields } from '../../../../../helpers/formikHelper';
import { SepaMandatCreateInput } from '../../../../../types';

export enum BankDetailsRechnungsausstellerType {
  HAUSVERWALTUNG = 'hausverwaltung',
  RECHNUNGS_AUSSTELLER = 'rechnungsAussteller',
}

export interface SepaMandatCreateFormValues {
  rechnungsAusstellerId?: string;
  sepaMandatReference?: string;
  tempRechnungsausstellerType?: BankDetailsRechnungsausstellerType;
}

export const sepaMandatCreateFormInitialValues: SepaMandatCreateFormValues = {
  rechnungsAusstellerId: '',
};

export const sepaMandatCreateFormFields: FormFields<SepaMandatCreateFormValues> = {
  rechnungsAusstellerId: 'rechnungsAusstellerId',
  sepaMandatReference: 'sepaMandatReference',
  tempRechnungsausstellerType: 'tempRechnungsausstellerType',
};

export const mapFormValuesToSepaMandatCreate = (formValues: SepaMandatCreateFormValues): SepaMandatCreateInput => ({
  sepaMandatReference: formValues.sepaMandatReference,
  rechnungsAusstellerFirmendatenId:
    formValues.tempRechnungsausstellerType === BankDetailsRechnungsausstellerType.HAUSVERWALTUNG ? formValues.rechnungsAusstellerId : null,
  rechnungsAusstellerRechtstraegerId:
    formValues.tempRechnungsausstellerType === BankDetailsRechnungsausstellerType.RECHNUNGS_AUSSTELLER ? formValues.rechnungsAusstellerId : null,
});
