import React, { FC } from 'react';
import { Space, Tag } from 'antd';

import { BeAufteilingsschluesselWert } from '../../../../../types';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  record: BeAufteilingsschluesselWert;
};

const BeAufteilungsschluesselWertOhneBezugValidFrombWithInfo: FC<Props> = ({ record }) => {
  const isCurrentTag = record.current ? <Tag>Aktiv</Tag> : null;

  return record.validFrom ? (
    <Space size={4}>
      <CustomFormattedDate value={record.validFrom} />
      {isCurrentTag}
    </Space>
  ) : null;
};

export default BeAufteilungsschluesselWertOhneBezugValidFrombWithInfo;
