import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { filtersFormFields, FiltersFormValues, listingLabelList } from './listingFiltersFormMapper';
import { TVerarbeitungQueryParams } from './filtersQueryParams';
import FromToFormDatePicker from '../../../shared/FromToForm/FromToFormDatePicker';
import MitarbeiterSelect from '../../../../shared/components/MitarbeiterSelect/MitarbeiterSelect';
import { onFilterSelectChange } from '../../../../components/Filters/FiltersHelpers';
import VerarbeitungTypeListFormSelect from './VerarbeitungTypeListFormSelect';
import StatusListFormSelect from './StatusListFormSelect';
import useFilterWithQueryParamsNew from '../../../../components/Filters/useFilterWithQueryParamsNew';
import FiltersWithSelectorWrapper from '../../../../components/Filters/FiltersWithSelector/FiltersWithSelectorWrapper';
import FormItemForFilter from '../../../../components/Form/FormItemForFilter';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
  queryParams: TVerarbeitungQueryParams;
};

const ListingFilters: FC<Props> = ({ formikProps, queryParams }) => {
  const mapFilterLabelToQueryFilterList = (filter: string) => {
    if (filter === filtersFormFields.generierDatumVonInklusive || filter === filtersFormFields.generierDatumBisInklusive) {
      return filtersFormFields.generierDatumVonInklusive;
    }
    return filter;
  };

  const filters = (filter: string) => {
    if (filter === filtersFormFields.typeList) {
      return (
        <FormItemForFilter name={filtersFormFields.typeList} key={filter}>
          <VerarbeitungTypeListFormSelect name={filtersFormFields.typeList} onChange={formikProps.submitForm} prefix={listingLabelList.typeList} />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.statusList) {
      return (
        <FormItemForFilter name={filtersFormFields.statusList} key={filter}>
          <StatusListFormSelect
            name={filtersFormFields.statusList}
            onChange={formikProps.submitForm}
            mode="multiple"
            prefix={listingLabelList.statusList}
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.generierDatumVonInklusive) {
      return (
        <FormItemForFilter name="erstelltAm" key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={filtersFormFields.generierDatumVonInklusive}
            toName={filtersFormFields.generierDatumBisInklusive}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
            fromPrefix={`${listingLabelList.generierDatumVonInklusive} - von`}
            toPrefix={`${listingLabelList.generierDatumVonInklusive} - bis`}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    if (filter === filtersFormFields.erstellerMitarbeiterIdList) {
      return (
        <FormItemForFilter name={filtersFormFields.erstellerMitarbeiterIdList} key={filter}>
          <MitarbeiterSelect
            name={filtersFormFields.erstellerMitarbeiterIdList}
            onChange={formikProps.submitForm}
            mode="multiple"
            prefix={listingLabelList.erstellerMitarbeiterIdList}
            placeholder=""
          />
        </FormItemForFilter>
      );
    }
    return undefined;
  };

  const updateFilterValues = (selectedFilterList: string[]) => {
    if (!selectedFilterList.includes(filtersFormFields.generierDatumVonInklusive) && formikProps.values.generierDatumBisInklusive) {
      formikProps.setFieldValue(filtersFormFields.generierDatumBisInklusive, undefined);
    }
    formikProps.submitForm();
  };

  const { selectedFilterList, setSelectedFilterList, filterList } = useFilterWithQueryParamsNew({
    updateFilterValues,
    filters,
    labelList: listingLabelList,
    mapFilterLabelToQueryFilterList,
    queryParams,
  });

  return (
    <FiltersWithSelectorWrapper
      selectedFilterList={selectedFilterList}
      onSelectedFilterTitleList={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
      allFilterTitles={listingLabelList}
      filters={<>{filterList}</>}
    />
  );
};

export default ListingFilters;
