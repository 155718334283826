import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Button, Col, Modal, Row, Skeleton, Space, Tooltip, Typography } from 'antd';
import { Formik, FormikConfig, FormikProps } from 'formik';
import { DatePicker, Form, Input } from 'formik-antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { TicketTemplate } from '../../../types';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';
import TicketPrioritySelect from '../shared/TicketPrioritySelect';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import TicketPeriod from '../../TicketTemplate/Form/TicketPeriod';
import FormButtons from '../../../components/Button/FormButtons';
import { useUpdateTicketMutation } from '../gql/TicketMutations.types';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';
import { mapFormValuesToTicketUpdateInput, mapTicketToFormValues, ticketFormFields, TicketFormValues } from './ticketFormMapper';
import TicketTemplateSelect from './TicketTemplateSelect';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../components/DatePicker/DatePicker_formikAntD';
import { ticketFormValidationSchema } from './ticketFormValidationSchema';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../components/message';
import { convertPeriodToDateString } from '../ticketHelpers';
import { useTicketLazyQuery, useTicketQuery } from '../gql/TicketQueries.types';
import TicketTypeNameSelect from '../shared/TicketTypeNameSelect';
import TicketAttachmentsForm from './TicketAttachmentsForm/TicketAttachmentsForm';
import { mapFormValuesToTicketAttachment, TicketAttachmentsFormValues } from './TicketAttachmentsForm/ticketAttachmentsFormMapper';
import ParticipantSelect from './ParticipantSelect/ParticipantSelect';
import { TicketAndParentId } from '../ticketUriPaths';
import { handleSyncAttachments } from './TicketAttachmentsForm/handleSyncAttachments';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { handleSubTicketMutationResponseWarnings } from './ticketFormHelpers';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';
import { useCustomModal } from '../../../components/Modal/ModalProvider';
import { useToggle } from '../../../hooks/useToggle';
import TicketFormCalendarModal from './TicketFormCalendar/TicketFormCalendarModal';
import { gqlOperations } from '../../../graphql/gqlNamedOperations-generated';
import TicketEmailsForm from './TicketEmailsForm/TicketEmailsForm';
import { mapFormValuesToTicketEmails, TicketEmailsFormValues } from './TicketEmailsForm/ticketEmailsFormMapper';
import { handleSyncEmails } from './TicketEmailsForm/handleSyncEmails';
import MitarbeiterSelectWithTicketCount from '../MitarbeiterSelectWithTicketCount/MitarbeiterSelectWithTicketCount';

type Props = {
  ticketId?: string;
  onFormSubmitCreate?: (...args: Parameters<FormikConfig<TicketFormValues>['onSubmit']>) => Promise<TicketAndParentId | undefined>;
  loadingCreate?: boolean;
  onSuccess: (ticket?: TicketAndParentId) => void;
  onCancel: () => void;
  isSubticket?: boolean;
  parentId?: string;
};

const TicketForm: FC<Props> = ({ ticketId, onFormSubmitCreate, loadingCreate, onSuccess, onCancel, isSubticket = false, parentId }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'Ticket'>('Ticket');
  const [isModalOpen, toggleIsModalOpen] = useToggle();
  const [assigneeMitarbeiterId, setAssigneeMitarbeiterId] = useState<string | null>(null);

  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data, loading } = useTicketQuery({ variables: { ticketId: ticketId! }, skip: !ticketId });
  const [getParent, { data: parentData, loading: parentLoading }] = useTicketLazyQuery();
  const ticket = data?.getTicket.data;
  const parentTicket = parentData?.getTicket.data;
  const isUpdate = !!ticket;

  const modal = useCustomModal();

  useEffect(() => {
    if (ticket) {
      setAssigneeMitarbeiterId(ticket.assignee.mitarbeiterId);
    }

    const parentTicketId = parentId || ticket?.parent?.ticketId;
    if (!parentTicketId) return;
    getParent({ variables: { ticketId: parentTicketId } });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket]);

  const entity = `Aufgabe`;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSuccess = (isUpdate: boolean, ticket?: TicketAndParentId) => {
    if (isUpdate) {
      showSuccessMsgUpdate(entity);
    } else {
      showSuccessMsgCreate(entity);
    }

    const shouldSyncAttachments =
      attachmentsFormikRef.current?.values.newAttachmentList.length || attachmentsFormikRef.current?.values.deletableIds.length;
    const shouldSyncEmails = emailsFormikRef.current?.values.newEmailList.length || emailsFormikRef.current?.values.deletableIds.length;

    setIsSubmitting(true);
    const syncFunctions: (() => Promise<unknown>)[] = [];

    syncFunctions.push(() => {
      if (!shouldSyncAttachments) return Promise.resolve();

      return handleSyncAttachments(
        firmendatenId,
        ticket?.ticketId,
        attachmentsFormikRef.current.values,
        mapFormValuesToTicketAttachment(attachmentsFormikRef.current.values)
      );
    });

    syncFunctions.push(() => {
      if (!shouldSyncEmails) return Promise.resolve();

      return handleSyncEmails(
        firmendatenId,
        ticket?.ticketId,
        emailsFormikRef.current.values,
        mapFormValuesToTicketEmails(emailsFormikRef.current.values)
      );
    });

    // Execute functions sequentially
    (async () => {
      for (const syncFunction of syncFunctions) {
        try {
          // eslint-disable-next-line no-await-in-loop
          await syncFunction();
        } catch (error) {
          /* empty */
        }
      }
      onSuccess(ticket);
      setIsSubmitting(false);
    })();
  };

  const attachmentsFormikRef = useRef<FormikProps<TicketAttachmentsFormValues>>(null);
  const emailsFormikRef = useRef<FormikProps<TicketEmailsFormValues>>(null);

  const [runUpdate, { loading: loadingUpdate }] = useUpdateTicketMutation({
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const isLoading = loadingCreate || loadingUpdate || isSubmitting || parentLoading;

  return (
    <Skeleton active paragraph={{ rows: 10 }} loading={loading || parentLoading}>
      <Formik<TicketFormValues>
        initialValues={mapTicketToFormValues(isSubticket, ticket, parentTicket)}
        validationSchema={ticketFormValidationSchema(isSubticket || isUpdate)}
        onSubmit={(values, formikHelpers) => {
          setIsSubmitting(true);
          if (!isUpdate) {
            onFormSubmitCreate?.(values, formikHelpers)
              .then((ticket: TicketAndParentId | undefined) => {
                if (!ticket?.ticketId) {
                  setIsSubmitting(false);
                } else {
                  handleSuccess(isUpdate, ticket);
                }
              })
              .catch(() => {
                setIsSubmitting(false);
              });
          } else {
            // Update
            runUpdate({
              variables: { ticketId: ticket.ticketId, input: mapFormValuesToTicketUpdateInput(values) },
              onCompleted: () => {
                handleSuccess(isUpdate, ticket);
              },
              refetchQueries: () => [gqlOperations.Query.TicketList],
            })
              .then((response) =>
                handleSubTicketMutationResponseWarnings<typeof response>(response, parentTicket, runUpdate, onSuccess, (dueDate, onOk) => {
                  modal.confirm({
                    title: 'Fälligkeit der Hauptaufgabe ändern?',
                    content: (
                      <Typography.Text>
                        Die Fälligkeit einer Unteraufgabe ist nach der Fälligkeit der Hauptaufgabe ausgerichtet. Möchten Sie die Fälligkeit der
                        Hauptaufgabe ebenfalls auf <CustomFormattedDate value={dueDate} /> ändern?
                      </Typography.Text>
                    ),

                    okText: 'Ja',
                    cancelText: 'Nein',
                    onOk,
                  });
                })
              )
              .finally(() => {
                setIsSubmitting(false);
              });
          }
        }}
      >
        {(formikProps) => (
          <Form layout="vertical">
            {!isSubticket && (
              <Row align="top">
                <Col span={6}>
                  <Typography.Title level={5}>Aufgabenart</Typography.Title>
                </Col>
                <Col span={18}>
                  <FormItemWithFieldHelp name={ticketFormFields.ticketTemplateId} label="Art" fieldHelp={getFieldHelpText('Ticket.ticketTemplateId')}>
                    {!isUpdate ? (
                      <TicketTemplateSelect
                        size="middle"
                        name={ticketFormFields.ticketTemplateId}
                        onSelectTemplate={(ticketTemplate) => applyTemplateDataToForm(ticketTemplate, formikProps)}
                      />
                    ) : (
                      <TicketTypeNameSelect size="middle" name={ticketFormFields.typeName} disabled />
                    )}
                  </FormItemWithFieldHelp>
                </Col>
              </Row>
            )}

            {(formikProps.values.ticketTemplateId || isSubticket || isUpdate) && (
              <>
                <Row align="top">
                  <Col span={6}>
                    <Typography.Title level={5}>Titel</Typography.Title>
                  </Col>
                  <Col span={18}>
                    <FormItemWithFieldHelp name={ticketFormFields.title} label="Titel" fieldHelp={getFieldHelpText('Ticket.title')}>
                      <Input id={ticketFormFields.title} name={ticketFormFields.title} placeholder="Titel" prefix={<span />} />
                    </FormItemWithFieldHelp>
                  </Col>
                </Row>

                <Row align="top">
                  <Col span={6}>
                    <Typography.Title level={5}>Terminvorgaben</Typography.Title>
                  </Col>
                  <Col span={18}>
                    <FormItemWithFieldHelp name={ticketFormFields.dueDate} label="Fälligkeit" fieldHelp={getFieldHelpText('Ticket.dueDate')}>
                      <DatePicker
                        id={ticketFormFields.dueDate}
                        name={ticketFormFields.dueDate}
                        placeholder="Beteiligte auswählen"
                        size="middle"
                        format={DATE_DISPLAY_FORMAT_DEFAULT}
                        allowClear={false}
                        style={{ width: '100%' }}
                      />
                    </FormItemWithFieldHelp>

                    <FormItemWithoutColon name={`${ticketFormFields.reminder}`} label="Erinnerung" help={null} style={{ marginBottom: 0 }}>
                      <TicketPeriod
                        name={ticketFormFields.reminder}
                        fieldHelp={{
                          amount: getFieldHelpText('Ticket.reminder.amount'),
                          unit: getFieldHelpText('Ticket.reminder.unit'),
                        }}
                        allowClear={false}
                      />
                    </FormItemWithoutColon>
                  </Col>
                </Row>

                <Row align="top">
                  <Col span={6}>
                    <Typography.Title level={5}>Details</Typography.Title>
                  </Col>
                  <Col span={18}>
                    <FormItemWithFieldHelp name={ticketFormFields} label="Priorität" fieldHelp={getFieldHelpText('Ticket.ticketTemplateId')}>
                      <TicketPrioritySelect size="middle" name={ticketFormFields.priority} />
                    </FormItemWithFieldHelp>

                    <FormItemWithFieldHelp
                      name={ticketFormFields.description}
                      label="Beschreibung"
                      fieldHelp={getFieldHelpText('Ticket.description')}
                    >
                      <Input.TextArea id={ticketFormFields.description} name={ticketFormFields.description} placeholder="Beschreibung" />
                    </FormItemWithFieldHelp>

                    <MitarbeiterSelectWithTicketCountFormItemWrapper
                      extra={
                        <Button disabled={!formikProps.values.dueDate} type="link" onClick={toggleIsModalOpen}>
                          Kalender
                        </Button>
                      }
                    >
                      <FormItemWithFieldHelp
                        name={ticketFormFields.assigneeMitarbeiterId}
                        label={
                          <>
                            {!formikProps.values.dueDate ? (
                              <Space size={4}>
                                <Typography.Text>Bearbeiter</Typography.Text>
                                <Tooltip title="Bitte wählen Sie zuerst eine Fälligkeit aus">
                                  <InfoCircleOutlined />
                                </Tooltip>
                              </Space>
                            ) : (
                              'Bearbeiter'
                            )}
                          </>
                        }
                        fieldHelp={getFieldHelpText('Ticket.assigneeMitarbeiterId')}
                      >
                        <MitarbeiterSelectWithTicketCount
                          id={ticketFormFields.assigneeMitarbeiterId}
                          name={ticketFormFields.assigneeMitarbeiterId}
                          placeholder="Bearbeiter auswählen"
                          size="middle"
                          allowClear={false}
                          dueDate={formikProps.values.dueDate}
                          disabled={!formikProps.values.dueDate}
                          onMitarbeiterChange={(value) => {
                            setAssigneeMitarbeiterId(value ? value.mitarbeiterId : null);
                          }}
                        />
                      </FormItemWithFieldHelp>
                    </MitarbeiterSelectWithTicketCountFormItemWrapper>

                    <FormItemWithFieldHelp
                      name={ticketFormFields.participantList}
                      label="Beteiligte"
                      fieldHelp={getFieldHelpText('Ticket.participantList')}
                      help={formikProps.errors.participantList && <>E-Mail-Adresse muss gültig sein</>}
                      validateStatus={formikProps.errors.participantList ? 'error' : undefined}
                    >
                      <ParticipantSelect id={ticketFormFields.participantList} name={ticketFormFields.participantList} />
                    </FormItemWithFieldHelp>
                  </Col>
                </Row>

                <Typography.Title level={5}>Anhänge</Typography.Title>
                <TicketAttachmentsForm ref={attachmentsFormikRef} attachmentList={ticket?.attachments || []} />

                <Typography.Title level={5}>E-Mails</Typography.Title>
                <TicketEmailsForm ref={emailsFormikRef} emailList={ticket?.emailList || []} />
              </>
            )}
            <TicketFormCalendarModal
              formikProps={formikProps}
              open={isModalOpen}
              assigneeMitarbeiterId={assigneeMitarbeiterId}
              onCancel={toggleIsModalOpen}
              onOk={toggleIsModalOpen}
            />

            <FormButtons updateMode={isUpdate} onCancel={onCancel} isOkDisabled={isSubmitting} isOkLoading={isLoading} />
          </Form>
        )}
      </Formik>
    </Skeleton>
  );
};

const applyTemplateDataToForm = (ticketTemplate: TicketTemplate | undefined, formikProps: FormikProps<TicketFormValues>) => {
  if (!ticketTemplate) return;

  const prefillFormValues = () => {
    formikProps.setFieldValue(ticketFormFields.priority, ticketTemplate.priority.value);
    formikProps.setFieldValue(ticketFormFields.dueDate, convertPeriodToDateString(ticketTemplate.dueDate));
    formikProps.setFieldValue(ticketFormFields.reminder, {
      amount: ticketTemplate.reminder?.amount,
      unit: ticketTemplate.reminder?.unit.value,
    });
  };

  if (formikProps.values.ticketTemplateId && Object.keys(formikProps.touched).length > 1) {
    showConfirmFillWithTemplate(prefillFormValues);
  } else {
    prefillFormValues();
  }
};

const showConfirmFillWithTemplate = (onOk: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie die Standardeinstellungen für diese Art verwenden?',
    okButtonProps: { danger: true },
    okText: 'Ja',
    cancelText: 'Nein',
    onOk,
  });
};

const MitarbeiterSelectWithTicketCountFormItemWrapper: FC<PropsWithChildren & { extra?: React.ReactNode }> = ({ extra, children }) => {
  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', right: 0, zIndex: 1 }}>{extra}</div>
      {children}
    </div>
  );
};

export default TicketForm;
