import React, { FC } from 'react';
import { Dropdown, MenuProps } from 'antd';
import { useExportFieldHelpAsJson } from '../useExportFieldHelpAsJson';
import { downloadURL } from '../../../helpers/fileHelper';
import { showSuccessMsgOther, showWarningMessage } from '../../../components/message';
import { FieldHelpService } from '../useFieldHelpList';

const FieldHelpDownloadButton: FC = () => {
  const onExportSuccess = (response: Response & { service: FieldHelpService | undefined }) => {
    if (response.status !== 200) {
      showWarningMessage('', `Feldhilfedaten aus '${response.service}' wurden nicht exportiert.`);
    } else {
      downloadURL(response.url);
      showSuccessMsgOther(`Feldhilfedaten aus '${response.service}' erfolgreich exportiert.`);
    }
  };

  const onExportError = (error: any) => {
    showWarningMessage(error, 'Feldhilfedaten wurden nicht exportiert.');
  };

  const { run, isLoading } = useExportFieldHelpAsJson(onExportSuccess, onExportError);

  const items: MenuProps['items'] = Object.entries(FieldHelpService).map(([key, value]) => ({
    key,
    label: key,
    onClick: () => run(value, key),
  }));

  return (
    <Dropdown.Button type="primary" menu={{ items }} loading={isLoading}>
      Daten herunterladen
    </Dropdown.Button>
  );
};

export default FieldHelpDownloadButton;
