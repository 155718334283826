import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router';
import { uri, URI_APP_ROUTE, URI_DASHBOARD_WORKSPACE_PAGE } from '../../constants/appUriPaths';
import { GoBackProvider } from '../../shared/GoBack/GoBackContext';
import AuthRoute from '../../security/AuthRoute';
import pdfPageRoute from '../../pages/PDFTemplates';
import { URI_LOGIN_PAGE_CHOOSE_FIRMA, URI_LOGIN_PAGE_USER_IS_NOT_MITARBEITER_ERROR } from '../../constants/loginUriPaths';
import { URI_EMAIL_VERIFICATION_FOR_TOKEN, URI_REGISTRATION_FOR_TOKEN } from '../../constants/registrationUriPaths';
import { URI_PASSWORD_RESET, URI_PASSWORD_RESET_FOR_TOKEN } from '../../constants/passwordResetUriPaths';

/** LOGIN PAGE */
import loginPageRoute from '../../pages/LoginPage';
/** VERIFY EMAIL */
import EmailVerificationPage from '../../pages/EmailVerificationPage/EmailVerificationPage';
/** GENERAL PAGES */
import ErrorPage from '../../pages/ErrorPage/FeedbackErrorPage';
import LoadingFullPage from '../../components/Loading/LoadingFullPage';
import dashboardWorkspacePageRoute from '../../pages/DashboardWorkspacePage';
import budgetingDetailsPageRoute from '../../pages/BudgetingDetailsPage';
import rechtstraegerDetailsPageRoute from '../../pages/RechtstraegerDetailsPage';
import singleInvoiceDetailsPageRoute from '../../pages/RechnungPage/SingleInvoiceDetailsPage';
import singleInvoiceUpdatePageRoute from '../../pages/RechnungPage/SingleInvoiceUpdatePage';
import documentsFirmaPage from '../../pages/DocumentPage';
import rechtstraegerPageRoute from '../../pages/RechtstraegerPage';
import {
  verarbeitungPaymentProposalCreateDetailsPageRoute,
  verarbeitungPaymentProposalExecuteDetailsPageRoute,
} from '../../pages/VerarbeitungDetailsPages/PaymentProposalDetailsPage';
import { verarbeitungBelegErstellenDetailsPageRoute } from '../../pages/VerarbeitungDetailsPages/BelegDetailsPage';
import verarbeitungBudgetingDetailsPageRoute from '../../pages/VerarbeitungDetailsPages/BudgetingDetailsPage';
import { verarbeitungHeAbrechnungErstellenDetailsPageRoute } from '../../pages/VerarbeitungDetailsPages/HeAbrechnungDetailsPage';
import verarbeitungInfoMailDeliveryDetermineRecipientListDetailsListPageRoute from '../../pages/VerarbeitungDetailsPages/InfoMailDeliveryDetermineRecipientDetailsPage';
import verarbeitungInfoMailDeliverySendDetailsPageRoute from '../../pages/VerarbeitungDetailsPages/InfoMailDeliverySendDetailsPage';
import verarbeitungObjektFinancialPlanDetailsPageRoute from '../../pages/VerarbeitungDetailsPages/ObjektFinancialPlanDetailsPage';
import verarbeitungTopFinancialPlanDetailsPageRoute from '../../pages/VerarbeitungDetailsPages/TopFinancialPlanDetailsPage';
import verarbeitungVorschreibungMailDeliveryDetermineRecipientDetailsPage from '../../pages/VerarbeitungDetailsPages/VorschreibungMailDeliveryDetermineRecipientDetailsPage';
import verarbeitungVorschreibungMailDeliverySendDetailsPageRoute from '../../pages/VerarbeitungDetailsPages/VorschreibungMailDeliverySendDetailsPage';
import {
  verarbeitungVorschreibungErstellenDetailsPageRoute,
  verarbeitungVorschreibungFreigebenDetailsPageRoute,
} from '../../pages/VerarbeitungDetailsPages/VorschreibungDetailsPage';
import {
  verarbeitungMahnungAusgebenDetailsPageRoute,
  verarbeitungMahnungErstellenDetailsPageRoute,
  verarbeitungMahnungVerbuchenDetailsPageRoute,
  verarbeitungMahnungVersendenDetailsPageRoute,
} from '../../pages/VerarbeitungDetailsPages/MahnungDetailsPage';
import verarbeitungUstVomAufwandDetailsPageRoute from '../../pages/VerarbeitungDetailsPages/UstVomAufwandDetailsPage';
import verarbeitungVstKuerzungDetailsPageRoute from '../../pages/VerarbeitungDetailsPages/VstKuerzungDetailsPage';
import verarbeitungZinslisteDetailsPageRoute from '../../pages/VerarbeitungDetailsPages/ZinslisteDetailsPage';
import verarbeitungVertragVposIndexedValueAutomaticallyDetailsPageRoute from '../../pages/VerarbeitungDetailsPages/VertragVposIndexedValueAutomaticallyDetailsPage';
import verarbeitungVertragVposIndexedValueManuallyDetailsPageRoute from '../../pages/VerarbeitungDetailsPages/VertragVposIndexedValueManuallyDetailsPage';
import { verarbeitungApplyVertragVposIndexedValueDetailsPageRoute } from '../../pages/VerarbeitungDetailsPages/ApplyVertragVposIndexedValuDetailsPage/routes';
import verarbeitungWeObjAbrDetailsPageRoute from '../../pages/VerarbeitungDetailsPages/WeObjektAbrechnungDetailsPage';
import verarbeitungTopAbrDetailsPageRoute from '../../pages/VerarbeitungDetailsPages/WeTopAbrechnungDetailsPage';
import verarbeitungBkObjAbrDetailsPageRoute from '../../pages/VerarbeitungDetailsPages/BkObjektAbrechnungDetailsPage';
import verarbeitungBkTopAbrDetailsPageRoute from '../../pages/VerarbeitungDetailsPages/BkTopAbrechnungDetailsPage';
import verarbeitungSyncEbicsUserDetailsPageRoute from '../../pages/VerarbeitungDetailsPages/SyncEbicsUserDetailsPage';
import verarbeitungPageRoute from '../../pages/VerarbeitungPage';
import zinslistePageRoute from '../../pages/ZinslistePage';
import abrechnungPageRoute from '../../pages/AbrechnungPage';
import beVertragDetailsPageRoute from '../../pages/BeVertragDetailsPage';
import heVertragDetailsPageRoute from '../../pages/HeVertragDetailsPage';
import zinslisteDetailsPageRoute from '../../pages/ZinslisteDetailsPage';
import bestandseinheitDetailsPageRoute from '../../pages/BestandseinheitDetailsPage';
import vorsteuerkuerzungDetailsPageRoute from '../../pages/VorsteuerkuerzungDetailsPage';
import heAbrechnungDetailsHeVertragDetailsPageRoute from '../../pages/HeAbrechnungDetailsHeVertragDetailsPage';
import heAbrechnungDetailsPageRoute from '../../pages/HeAbrechnungDetailsPage';
import bkTopAbrechnungDetailsPageRoute from '../../pages/BkTopAbrechnungDetailsPage';
import weTopAbrechnungDetailsPageRoute from '../../pages/WeTopAbrechnungDetailsPage';
import weAbrechnungDetailsPageRoute from '../../pages/WeAbrechnungDetailsPage';
import bkAbrechnungDetailsPageRoute from '../../pages/BkAbrechnungDetailsPage';
import ustVomAufwandDetailsPageRoute from '../../pages/UstVomAufwandDetailsPage';
import objektDetailsPageRoute from '../../pages/ObjektDetailsPage';
import sysSettingsKontoDetailsPageRoute from '../../pages/SysSettingsPage/KundenSystem/KontoDetailsPage';
import rechnungCreatePageRoute from '../../pages/RechnungPage/RechnungCreatePage';
import profilePageRoute from '../../pages/ProfilePage';
import verwaltungMitarbeiterDetailsPageRoute from '../../pages/VerwaltungPage/MitarbeiterDetailsPage/routes';
import verwaltungFirmendatenDetailsPageRoute from '../../pages/VerwaltungPage/FirmendatenDetailsPage/routes';
import customSystemSettingsPageRoute from '../../pages/AndromedaSystemSettingsPage';
import sysSettingsPageRoute from '../../pages/SysSettingsPage';
import contactDetailsPage from '../../pages/ContactDetailsPage';
import vorschreibungDetailsPageRoute from '../../pages/VorschreibungDetailsPage';
import vorschreibungPageRoute from '../../pages/VorschreibungPage';
import indexedValuePageRoute from '../../pages/IndexedValuePage';
import kontoblattPageRoute from '../../pages/KontoblattPage';
import saldenlistePageRoute from '../../pages/SaldenlistePage';
import offenePostenPageRoute from '../../pages/OffenePostenPage';
import paymentPageRoute from '../../pages/PaymentPage';
import dataCarrierPageRoute from '../../pages/DataCarrierPage';
import mahnungDetailsPageRoute from '../../pages/MahnungDetailsPage';
import mahnungPageRoute from '../../pages/MahnungPage';
import notificationPageRoute from '../../pages/NotificationPage';
import wizardPageRoute from '../../pages/WizardPage';
import wizardUpdatePageRoute from '../../pages/WizardUpdatePage';
import emailVersandDetailsPageRoute from '../../pages/EmailVersandDetailsPage';
import emailVersandPageRoute from '../../pages/EmailVersandPage';
import verarbeitungCamtDetailsPageRoute from '../../pages/VerarbeitungDetailsPages/CamtDetailsPage';
import { verarbeitungCamtEbicsManuallyDetailsPageRoute } from '../../pages/VerarbeitungDetailsPages/CamtEbicsManuallyDetailsPage/routes';
import { verarbeitungCamtOnlineBankingManuallyDetailsPageRoute } from '../../pages/VerarbeitungDetailsPages/CamtOnlineBankingManuallyDetailsPage/routes';
import { verarbeitungDataCarrierPainCreationDetailsPageRoute } from '../../pages/VerarbeitungDetailsPages/DataCarrierPainCreationDetailsPage/routes';
import ticketPageRoute from '../../pages/TicketPage';
import ticketDetailsPageRoute from '../../pages/TicketDetailsPage';
import { subTicketDetailsPageRoute } from '../../pages/TicketDetailsPage/routes';
import standingInvoiceDetailsPageRoute from '../../pages/RechnungPage/StandingInvoiceDetailsPage';
import standingInvoiceUpdatePageRoute from '../../pages/RechnungPage/StandingInvoiceUpdatePage';
import { verarbeitungBookingSuggestionCreationDetailsPageRoute } from '../../pages/VerarbeitungDetailsPages/BookingSuggestionCreationDetailsPage/routes';
import incomingInvoicePageRoute from '../../pages/IncomingInvoicePage';
import bookingSuggestionBuchungsmaskeWithTabsPageRoute from '../../pages/BookingSuggestionBuchungsmaskeWithTabsPage';
import { verarbeitungIncomingInvoiceBookingCreationDetailsPageRoute } from '../../pages/VerarbeitungDetailsPages/IncomingInvoiceBookingCreationDetailsPage/routes';
import ModalProvider from '../../components/Modal/ModalProvider';
import receivedBankTransactionPageRoute from '../../pages/ReceivedBankTransactionPage';
import paymentClearingPageRoute from '../../pages/PaymentClearingPage';
import { verarbeitungBookingSuggestionVerbuchenDetailsPageRoute } from '../../pages/VerarbeitungDetailsPages/BookingSuggestionVerbuchenDetailsPage/routes';
import { PopoverStyles } from '../../components/Popover/PopoverStyles';
import verarbeitungFirmendatenInitializerDetailsPageRoute from '../../pages/VerarbeitungDetailsPages/FirmendatenInitializer';
import { verarbeitungReceivedBankTransactionVerbuchenDetailsPageRoute } from '../../pages/VerarbeitungDetailsPages/ReceivedBankTransactionVerbuchenDetailsPage/routes';
import andromedaSysSettingsKontoDetailsPageRoute from '../../pages/AndromedaSystemSettingsPage/KundenSystem/KontoDetailsPage';
import bookingJournalPageRoute from '../../pages/BookingJournalPage';
import bookingDetailsWithBookingSuggestionPageRoute from '../../pages/BookingDetailsWithBookingSuggestionPage';
import bookingSuggestionBuchungsmaskePageRoute from '../../pages/BookingSuggestionBuchungsmaskePage';
import bookingDetailsPageRoute from '../../pages/BookingDetailsPage';
import callLogIncomingPageRoute from '../../pages/CallLogIncomingPage';
import bookingInstructionPageRoute from '../../pages/BookingInstructionPage';
import bookingFolderPageRoute from '../../pages/BookingFolderPage';
import subAdministrationDetailsPageRoute from '../../pages/SubAdministrationDetailsPage';
import verarbeitungSubAbrechnungDetailsPageRoute from '../../pages/VerarbeitungDetailsPages/SubAbrechnungDetailsPage';
import subAbrechnungDetailsPageRoute from '../../pages/SubAbrechnungDetailsPage/routes';
import subAbrechnungEigentuemerDetailsPageRoute from '../../pages/SubAbrechnungEigentuemerDetailsPage';

/** OHTER PAGES * */
const Dashboard = lazy(() => import('../Dashboard'));
const NotFoundPage = lazy(() => import('../../pages/NotFoundPage'));

/** CHOOSE COMPNAY */
const FirmendatenAuswahlPage = lazy(() => import('../../pages/FirmendatenAuswahlPage/FirmendatenAuswahlPage'));
const UserIsNotMitarbeiterError = lazy(() => import('../../pages/FirmendatenAuswahlPage/ErrorPage'));

/** REGISTRATION */
const RegistrationPage = lazy(() => import('../../pages/RegistrationPage/RegistrationPage'));

/** PASSWORD FORGOTTEN */
const PasswortForgottenPage = lazy(() => import('../../pages/PasswordForgottenPage/PasswordForgottenPage'));
const PasswortResetPage = lazy(() => import('../../pages/PasswordForgottenPage/PasswordResetPage'));

const App: React.FC = () => (
  <Suspense fallback={<LoadingFullPage />}>
    <PopoverStyles />
    <ModalProvider>
      <GoBackProvider>
        <Routes>
          <Route path="*" element={<NotFoundPage />} />
          <Route path={URI_APP_ROUTE} element={<Navigate to={uri.dashboard} replace />} />
          {pdfPageRoute}
          {loginPageRoute}
          <Route path={uri.error} element={<ErrorPage />} />
          <Route path={URI_REGISTRATION_FOR_TOKEN} element={<RegistrationPage />} />
          <Route path={URI_EMAIL_VERIFICATION_FOR_TOKEN} element={<EmailVerificationPage />} />
          <Route path={URI_LOGIN_PAGE_CHOOSE_FIRMA} element={<AuthRoute element={<FirmendatenAuswahlPage />} />} />
          <Route path={URI_LOGIN_PAGE_USER_IS_NOT_MITARBEITER_ERROR} element={<AuthRoute element={<UserIsNotMitarbeiterError />} />} />
          <Route path={URI_PASSWORD_RESET} element={<PasswortForgottenPage />} />
          <Route path={URI_PASSWORD_RESET_FOR_TOKEN} element={<PasswortResetPage />} />
          <Route path={uri.dashboard} element={<AuthRoute element={<Dashboard />} />}>
            <Route path={uri.dashboard} element={<Navigate to={URI_DASHBOARD_WORKSPACE_PAGE} replace />} />
            {dashboardWorkspacePageRoute}
            {callLogIncomingPageRoute}
            {budgetingDetailsPageRoute}
            {rechtstraegerDetailsPageRoute}
            {singleInvoiceDetailsPageRoute}
            {standingInvoiceDetailsPageRoute}
            {singleInvoiceUpdatePageRoute}
            {standingInvoiceUpdatePageRoute}
            {documentsFirmaPage}
            {rechtstraegerPageRoute}
            {verarbeitungPaymentProposalCreateDetailsPageRoute}
            {verarbeitungPaymentProposalExecuteDetailsPageRoute}
            {verarbeitungBelegErstellenDetailsPageRoute}
            {verarbeitungBudgetingDetailsPageRoute}
            {verarbeitungHeAbrechnungErstellenDetailsPageRoute}
            {verarbeitungInfoMailDeliveryDetermineRecipientListDetailsListPageRoute}
            {verarbeitungInfoMailDeliverySendDetailsPageRoute}
            {verarbeitungObjektFinancialPlanDetailsPageRoute}
            {verarbeitungTopFinancialPlanDetailsPageRoute}
            {verarbeitungVorschreibungMailDeliveryDetermineRecipientDetailsPage}
            {verarbeitungVorschreibungMailDeliverySendDetailsPageRoute}
            {verarbeitungVorschreibungFreigebenDetailsPageRoute}
            {verarbeitungMahnungAusgebenDetailsPageRoute}
            {verarbeitungMahnungErstellenDetailsPageRoute}
            {verarbeitungMahnungVersendenDetailsPageRoute}
            {verarbeitungMahnungVerbuchenDetailsPageRoute}
            {verarbeitungUstVomAufwandDetailsPageRoute}
            {verarbeitungVorschreibungErstellenDetailsPageRoute}
            {verarbeitungVstKuerzungDetailsPageRoute}
            {verarbeitungZinslisteDetailsPageRoute}
            {verarbeitungVertragVposIndexedValueAutomaticallyDetailsPageRoute}
            {verarbeitungVertragVposIndexedValueManuallyDetailsPageRoute}
            {verarbeitungApplyVertragVposIndexedValueDetailsPageRoute}
            {verarbeitungWeObjAbrDetailsPageRoute}
            {verarbeitungTopAbrDetailsPageRoute}
            {verarbeitungBkObjAbrDetailsPageRoute}
            {verarbeitungBkTopAbrDetailsPageRoute}
            {verarbeitungSyncEbicsUserDetailsPageRoute}
            {verarbeitungCamtDetailsPageRoute}
            {verarbeitungCamtEbicsManuallyDetailsPageRoute}
            {verarbeitungCamtOnlineBankingManuallyDetailsPageRoute}
            {verarbeitungDataCarrierPainCreationDetailsPageRoute}
            {verarbeitungBookingSuggestionCreationDetailsPageRoute}
            {verarbeitungBookingSuggestionVerbuchenDetailsPageRoute}
            {verarbeitungIncomingInvoiceBookingCreationDetailsPageRoute}
            {verarbeitungFirmendatenInitializerDetailsPageRoute}
            {verarbeitungReceivedBankTransactionVerbuchenDetailsPageRoute}
            {verarbeitungSubAbrechnungDetailsPageRoute}
            {verarbeitungPageRoute}
            {zinslistePageRoute}
            {abrechnungPageRoute}
            {beVertragDetailsPageRoute}
            {heVertragDetailsPageRoute}
            {zinslisteDetailsPageRoute}
            {bestandseinheitDetailsPageRoute}
            {vorsteuerkuerzungDetailsPageRoute}
            {heAbrechnungDetailsHeVertragDetailsPageRoute}
            {heAbrechnungDetailsPageRoute}
            {bkTopAbrechnungDetailsPageRoute}
            {weTopAbrechnungDetailsPageRoute}
            {weAbrechnungDetailsPageRoute}
            {bkAbrechnungDetailsPageRoute}
            {ustVomAufwandDetailsPageRoute}
            {subAdministrationDetailsPageRoute}
            {subAbrechnungEigentuemerDetailsPageRoute}
            {subAbrechnungDetailsPageRoute}
            {objektDetailsPageRoute}
            {sysSettingsKontoDetailsPageRoute}
            {rechnungCreatePageRoute}
            {profilePageRoute}
            {verwaltungMitarbeiterDetailsPageRoute}
            {verwaltungFirmendatenDetailsPageRoute}
            {customSystemSettingsPageRoute}
            {sysSettingsPageRoute}
            {contactDetailsPage}
            {vorschreibungDetailsPageRoute}
            {vorschreibungPageRoute}
            {indexedValuePageRoute}
            {incomingInvoicePageRoute}
            {receivedBankTransactionPageRoute}
            {bookingDetailsPageRoute}
            {bookingDetailsWithBookingSuggestionPageRoute}
            {bookingFolderPageRoute}
            {bookingInstructionPageRoute}
            {bookingSuggestionBuchungsmaskeWithTabsPageRoute}
            {bookingSuggestionBuchungsmaskePageRoute}
            {kontoblattPageRoute}
            {saldenlistePageRoute}
            {bookingJournalPageRoute}
            {offenePostenPageRoute}
            {paymentClearingPageRoute}
            {paymentPageRoute}
            {dataCarrierPageRoute}
            {mahnungDetailsPageRoute}
            {mahnungPageRoute}
            {notificationPageRoute}
            {wizardPageRoute}
            {wizardUpdatePageRoute}
            {emailVersandDetailsPageRoute}
            {emailVersandPageRoute}
            {ticketPageRoute}
            {ticketDetailsPageRoute}
            {subTicketDetailsPageRoute}
            {andromedaSysSettingsKontoDetailsPageRoute}
          </Route>
        </Routes>
      </GoBackProvider>
    </ModalProvider>
  </Suspense>
);

export default App;
