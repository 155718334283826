import React from 'react';
import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../../components/Template/DetailsPageTemplate';
import VerarbeitungDetailsPageCard from '../../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../../features/Verarbeitung/verarbeitungHelpers';
import { useMahnungAusgebenGenerierlaufQuery } from '../../../../features/Verarbeitung/gql/MahnungGenerierlauf/MahnungAusgebenGenerierlaufQueries.types';
import menuListMahnungAusgeben from './menuListMahnungAusgeben';
import { VerarbeitungMahnungDetailsPageRouteParams } from '../routes';
import { MahnungAusgebenGenerierlauf } from '../../../../types';
import { useRestartMahnungAusgebenGenerierlaufMutation } from '../../../../features/Verarbeitung/gql/MahnungGenerierlauf/MahnungAusgebenGenerierlaufMutations.types';

const MahnungAusgebenDetailsPage = () => {
  const { generierlaufId } = useParams() as VerarbeitungMahnungDetailsPageRouteParams;

  const { data, refetch } = useMahnungAusgebenGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getMahnungAusgebenGenerierlauf.data;

  const [runRestart] = useRestartMahnungAusgebenGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<MahnungAusgebenGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.MahnungAusgabe}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.MahnungAusgabe}
          onGenerierlaufRestart={runRestart}
        />
      )}
      sidebarMenuList={(generierlauf) => menuListMahnungAusgeben(generierlauf)}
    />
  );
};

export default MahnungAusgebenDetailsPage;
