import { FormFields } from '../../../../../../helpers/formikHelper';

export type RechnungsAusstellerMahnDefZuweisenFormValues = {
  mahndefinitionId: string;
};

export const rechnungsAusstellerMahnDefZuweisenFormValuesDefault: RechnungsAusstellerMahnDefZuweisenFormValues = {
  mahndefinitionId: '',
};

export const rechnungsAusstellerMahnDefZuweisenFormFields: FormFields<RechnungsAusstellerMahnDefZuweisenFormValues> = {
  mahndefinitionId: 'mahndefinitionId',
};
