import React, { FC } from 'react';
import { Empty } from 'antd';
import kontoblattTableColumns from './kontoblattTableColumns';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../hooks/useQueryWithPagingAndSorting';
import TableWithColSelector from '../../../components/Table/TableWithColSelector/TableWithColSelector';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';
import { FibuBuchungszeile } from '../../../types';
import { KontoblattPageQueryParams } from '../kontoblattUriPaths';
import { useFibuBuchungszeileListQuery } from '../../BookingJournal/gql/FibuBuchungszeileQueries.types';

type Props = {
  queryParams: KontoblattPageQueryParams;
};

const KontoblattTable: FC<Props> = ({ queryParams }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();
  const { belegnummer, ...restQueryParamProps } = queryParams;

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useFibuBuchungszeileListQuery,
    {
      variables: {
        belegnummer: belegnummer?.toString(),
        ...restQueryParamProps,
      },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );
  const buchungszeileList = data?.getFibuBuchungszeileList.data.contentList;

  return (
    <TableWithColSelector<FibuBuchungszeile>
      size="small"
      locale={{
        emptyText: <Empty description={<span>Keine Kontoblätter vorhanden</span>} />,
      }}
      scroll={{ x: 900 }}
      loading={loading}
      pagination={pagination}
      columns={kontoblattTableColumns(buchungszeileList)}
      dataSource={buchungszeileList}
      rowKey={(record) => record.buchungszeileId}
      onChange={handleTableSorting}
      filterIdentifier="main-kontoblatt"
    />
  );
};

export default KontoblattTable;
