import { generatePath } from 'react-router';
import { mapToQueryString, QUERY_PARAM_KEY, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { FiltersFormValues } from './filtersFormMapper';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { uriToDataCarrierBankstatement } from '../../../uriDataCarrierPaths';

export enum QueryParamKey {
  CREATED_BY_MITARBEITER_ID_LIST = 'createdByMitarbeiterIdList',
  CREATE_TS_FROM = 'createTsFrom',
  CREATE_TS_TO = 'createTsTo',
  IBAN = 'iban',
  NAME = 'name',
  PAYMENT_TRANSACTION_TYPE = 'paymentTransactionType',
  STATEMENT_NUMBER = 'statementNumber',
}

export type TBankstatementQueryParams = QueryParams<FiltersFormValues> & {
  expandedRowKeys?: Record<string, string> | null;
  highlightedRowKey?: string | null;
};

export const updateQueryParams = (
  navigate: (path: string) => void,
  filters: TBankstatementQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToBankstatementListingPage(filters, paginationParams));

export const generatePathToBankstatementListingPage = (filters: TBankstatementQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  `${generatePath(uriToDataCarrierBankstatement)}?${mapToQueryString({
    [QueryParamKey.CREATED_BY_MITARBEITER_ID_LIST]: filters?.createdByMitarbeiterIdList,
    [QueryParamKey.CREATE_TS_FROM]: filters?.createTsFrom,
    [QueryParamKey.CREATE_TS_TO]: filters?.createTsTo,
    [QueryParamKey.IBAN]: filters?.iban,
    [QueryParamKey.NAME]: filters?.name,
    [QueryParamKey.PAYMENT_TRANSACTION_TYPE]: filters?.paymentTransactionType,
    [QueryParamKey.STATEMENT_NUMBER]: filters?.statementNumber,
    [QUERY_PARAM_KEY.HIGHLIGHTED_ROW_KEY]: filters?.highlightedRowKey,
    [QUERY_PARAM_KEY.EXPANDED_ROW_KEYS]: filters?.expandedRowKeys ? JSON.stringify(filters.expandedRowKeys) : undefined,
    ...paginationParams,
  })}`;
