import React, { FC, useEffect } from 'react';
import { useField } from 'formik';
import { SelectProps } from 'formik-antd';
import { EntityStatusFormSelect } from '../../components/Status/EntityStatus';
import { FormikFieldHookProps } from '../../helpers/formikHelper';
import { AbrechnungsdefinitionType, AbrechnungskreisType } from '../../types';
import {
  isAbrDefTypeBkAbrechnung,
  isAbrDefTypeHeAbrechnung,
  isAbrDefTypeSubAbrechnung,
  isAbrDefTypeWeAbrechnung,
  isAbrKreisTypeAusgaben,
  isAbrKreisTypeBewirtschaftungskosten,
  isAbrKreisTypeEigentuemerverrechnung,
  isAbrKreisTypeEinnahmen,
  isAbrKreisTypeRepFonds,
} from '../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import { useAbrechnungskreisTemplateListQuery } from '../AbrechnungskreisTemplate/gql/AbrechnungskreisTemplateQueries.types';

type Props = {
  abrDefType: AbrechnungsdefinitionType;
  onChange?: (newAbrechnungskreisId: string, fieldProps: FormikFieldHookProps<string>, abrKreisType?: AbrechnungskreisType) => void;
} & Omit<SelectProps, 'onChange'>;

const AbrechnungskreisTemplateSelect: FC<Props> = ({ name, abrDefType, onChange, ...restProps }) => {
  const fieldProps = useField<string>(name);
  const [{ value: selectedId }, , { setValue: setSelectedId }] = fieldProps;

  const { loading, data } = useAbrechnungskreisTemplateListQuery({
    variables: {
      includeArchiviert: true,
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const list = data?.getAbrechnungskreisTemplateList.data ?? [];

  const listFilteredByAbrDefType = list.filter((item) => {
    if (isAbrDefTypeWeAbrechnung(abrDefType)) {
      return (
        !isAbrKreisTypeEinnahmen(item.type.value) &&
        !isAbrKreisTypeAusgaben(item.type.value) &&
        !isAbrKreisTypeEigentuemerverrechnung(item.type.value)
      );
    }
    if (isAbrDefTypeBkAbrechnung(abrDefType)) {
      return (
        !isAbrKreisTypeRepFonds(item.type.value) &&
        !isAbrKreisTypeEinnahmen(item.type.value) &&
        !isAbrKreisTypeAusgaben(item.type.value) &&
        !isAbrKreisTypeEigentuemerverrechnung(item.type.value)
      );
    }
    if (isAbrDefTypeHeAbrechnung(abrDefType) || isAbrDefTypeSubAbrechnung(abrDefType)) {
      return !isAbrKreisTypeRepFonds(item.type.value) && !isAbrKreisTypeBewirtschaftungskosten(item.type.value);
    } else return true;
  });

  // f_id changes => list changes => if there was already a selected entity it may not be available in the scope of newly selected f_id => clear entity
  useEffect(() => {
    if (!loading && selectedId && !list.map((item) => item.abrechnungskreisId).includes(selectedId)) {
      setSelectedId('');
    }
  }, [loading, list, selectedId, setSelectedId]);

  return (
    <EntityStatusFormSelect
      list={listFilteredByAbrDefType}
      getSelectItemId={(abrechnungskreis) => abrechnungskreis.abrechnungskreisId}
      getSelectItemContent={(abrechnungskreis) => abrechnungskreis.bezeichnung}
      getSelectItemTooltipTitle={(abrechnungskreis) => abrechnungskreis.bezeichnung}
      id="Abrechnungskreise"
      name={name}
      loading={loading}
      placeholder="Abrechnungskreis auswählen"
      {...restProps}
      onChange={(value) => {
        const current = list.find((abrKreis) => abrKreis.abrechnungskreisId === value);
        onChange?.(value, fieldProps, current?.type.value);
      }}
    />
  );
};

export default AbrechnungskreisTemplateSelect;
