import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../styles/pdfStyles';
import { VIndexationInformation } from '../../vorschreibung-types';

type Props = {
  indexationInformation: VIndexationInformation;
};

const IndexationInformation: FC<Props> = ({ indexationInformation }) => {
  return (
    <View style={[pdfStyles.row, { justifyContent: 'flex-start' }]}>
      <Text style={[pdfStyles.textBulletinSmall, { marginLeft: '25mm' }]}>
        {indexationInformation.indexSeriesName} {indexationInformation.thresholdValue.label} {indexationInformation.thresholdValue.value}
      </Text>
      <Text style={[pdfStyles.textBulletinSmall, { paddingLeft: '2mm', textAlign: 'left' }]}>
        {indexationInformation.baseOld.label}: {indexationInformation.baseOld.value}, {indexationInformation.baseNew.label}:{' '}
        {indexationInformation.baseNew.value}
      </Text>
    </View>
  );
};

export default IndexationInformation;
