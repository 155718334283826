import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Switch } from 'formik-antd';
import { Space, Typography } from 'antd';
import { IndexValueCreationCronJobSettings } from '../../../types';
import { showSuccessMsgUpdate } from '../../../components/message';
import FormButtons from '../../../components/Button/FormButtons';
import { useUpdateIndexValueCreationCronJobSettingsMutation } from '../gql/IndexValueCreationCronJobSettingsMutations.types';
import {
  indexSeriesEntryFormFields,
  IndexValueCreationCronJobSettingsFormValues,
  mapIndexValueCreationCronJobSettingsToFormValues,
} from './indexValueCreationCronJobSettingsFormMapper';
import { Spacer } from '../../../components/Grid';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';

type Props = {
  indexValueCreationCronJobSettings: IndexValueCreationCronJobSettings;
  onSuccess: () => void;
  onCancel: () => void;
};

const IndexValueCreationCronJobSettingsForm: FC<Props> = ({ indexValueCreationCronJobSettings, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'IndexValueCreationCronJobSettings'>('IndexValueCreationCronJobSettings');
  const [runUpdate] = useUpdateIndexValueCreationCronJobSettingsMutation({
    onCompleted: () => {
      showSuccessMsgUpdate('Einstellung automatische Erstellung');
      onSuccess();
    },
  });

  return (
    <>
      <Formik<IndexValueCreationCronJobSettingsFormValues>
        initialValues={mapIndexValueCreationCronJobSettingsToFormValues(indexValueCreationCronJobSettings)}
        onSubmit={(values, { setSubmitting }) => {
          runUpdate({ variables: { input: values } }).finally(() => setSubmitting(false));
        }}
      >
        {(formikProps) => (
          <Form layout="vertical">
            <Typography.Text strong>Für Wertsicherungsarten</Typography.Text>
            <Spacer height={16} />
            <FormItemWithFieldHelp
              name={indexSeriesEntryFormFields.indexThreshold}
              label="Index Schwellenwert"
              fieldHelp={getFieldHelpText('IndexValueCreationCronJobSettings.indexThreshold')}
            >
              <Space>
                <Switch name={indexSeriesEntryFormFields.indexThreshold} size="small" />
                <Typography.Text>{formikProps.values.indexThreshold ? 'Ja' : 'Nein'}</Typography.Text>
              </Space>
            </FormItemWithFieldHelp>
            <FormItemWithFieldHelp
              name={indexSeriesEntryFormFields.indexAnnual}
              label="Index jährlich"
              fieldHelp={getFieldHelpText('IndexValueCreationCronJobSettings.indexAnnual')}
            >
              <Space>
                <Switch name={indexSeriesEntryFormFields.indexAnnual} size="small" />
                <Typography.Text>{formikProps.values.indexAnnual ? 'Ja' : 'Nein'}</Typography.Text>
              </Space>
            </FormItemWithFieldHelp>
            <FormButtons updateMode onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default IndexValueCreationCronJobSettingsForm;
