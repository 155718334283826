import React from 'react';
import { Table } from 'antd';
import { generatePathToBestandseinheitDetailsPage } from '../bestandseinheitUriPaths';
import LinkWithStatus from '../../../components/Link/LinkWithStatus';
import DataWithStatus from '../../../components/Helpers/DataWithStatus';
import { compareTwoStringsForSorting } from '../../../helpers/stringHelper';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { compareTwoDatesForSorting } from '../../../helpers/dateHelper';
import { MitarbeiterTooltip } from '../../../components/Card';
import { BestandseinheitListEntry } from '../../../types';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';
import RechtstraegerWithContactsTooltip from '../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

export const bestandseinheitTableColumns = (objektId: string, isWegObjekt: boolean): TableWithColSelectorColumnProps<BestandseinheitListEntry>[] => [
  { key: 'sort', defaultSelected: true, width: 15 },
  Table.EXPAND_COLUMN,
  {
    title: 'Top',
    defaultSelected: true,
    width: 300,
    sorter: (a, b) => compareTwoStringsForSorting(a.bezeichnung, b.bezeichnung),
    render: (text, record) => (
      <LinkWithStatus
        status={record.status}
        text={record.bezeichnung}
        to={generatePathToBestandseinheitDetailsPage(objektId, record.bestandseinheitId)}
      />
    ),
  },
  {
    title: 'BT',
    defaultSelected: true,
    width: 64,
    sorter: (a, b) => compareTwoStringsForSorting(a.bauteil, b.bauteil),
    render: (text, record) =>
      record.bauteil ? <DataWithStatus status={record.status} text={record.bauteil} showBadgeDot={false} showStatusDescription={false} /> : null,
  },
  {
    title: 'Stiege',
    defaultSelected: true,
    width: 64,
    sorter: (a, b) => compareTwoStringsForSorting(a.stiege, b.stiege),
    render: (text, record) =>
      record.stiege ? <DataWithStatus status={record.status} text={record.stiege} showBadgeDot={false} showStatusDescription={false} /> : null,
  },
  {
    title: 'Nutzung',
    defaultSelected: true,
    width: 240,
    sorter: (a, b) => compareTwoStringsForSorting(a.nutzungsArt?.text, b.nutzungsArt?.text),
    render: (text, record) => (
      <DataWithStatus status={record.status} text={record.nutzungsArt?.text ?? ''} showBadgeDot={false} showStatusDescription={false} />
    ),
  },
  {
    title: isWegObjekt ? 'Eigentümer' : 'Mieter',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.vertragspartnerKurzBezeichnung, b.vertragspartnerKurzBezeichnung),
    render: (text, record) => {
      const { vertragspartnerKurzBezeichnung, vertragspartnerId, vertragspartnerStatus } = record;
      return vertragspartnerKurzBezeichnung && vertragspartnerId && vertragspartnerStatus ? (
        <RechtstraegerWithContactsTooltip
          rechtstraegerName={vertragspartnerKurzBezeichnung}
          rechtstraegerId={vertragspartnerId}
          status={vertragspartnerStatus}
        />
      ) : null;
    },
  },
  {
    title: 'Erstellt am',
    sorter: (a, b) => compareTwoDatesForSorting(a.createTs, b.createTs),
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
];
