import React, { FC } from 'react';
import { Drawer, MenuProps, Space, Typography } from 'antd';
import { BankOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import theme from '../../theme';
import { radixActionStyles } from '../../helpers/radixIconsStyles';
import { useToggle } from '../../hooks/useToggle';
import HistoryModal, { HistoryType } from '../History/shared/HistoryModal';
import { styleForIconAndButton } from '../../components/Dropdown/SettingsItemStyleHelpers';
import SettingsItemDropdown from '../../components/Dropdown/SettingsItemDropdown';
import TextForEmptyValue from '../../components/Text/TextForEmptyValue';
import { StyledSpaceForSettingsDetails, StyledSpaceForItemContent } from '../../components/Card/Styled/SettingsCard.style';
import { BankSettings } from '../../types';
import RechtstraegerBankSettingsUpdateForm from './UpdateForm/RechtstraegerBankSettingsUpdateForm';
import RABankSettingsChangeHistoryListingTable from './RABankSettingsChangeHistoryListingTable';

type Props = {
  bankSettings: BankSettings;
  rechtstraegerId: string;
  onAction: () => void;
};

const RechtstraegerBankSettingsCard: FC<Props> = ({ bankSettings, rechtstraegerId, onAction }) => {
  const [isUpdateModalOpen, toggleUpdateModalOpan] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const menuItems: MenuProps['items'] = [
    { key: '1', label: 'Bearbeiten', onClick: toggleUpdateModalOpan, icon: <EditOutlined /> },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
  ];

  const bankSettingsDetails = (
    <StyledSpaceForSettingsDetails direction="vertical" size="large">
      <Typography.Text>Bank</Typography.Text>
      <Space size="large">
        <Typography.Text>Abweichende SEPA Creditor-ID</Typography.Text>
        {bankSettings.sepaCreditorId ? <Typography.Text>{bankSettings.sepaCreditorId}</Typography.Text> : <TextForEmptyValue textToShow="minus" />}
      </Space>
    </StyledSpaceForSettingsDetails>
  );

  return (
    <>
      <StyledSpaceForItemContent align="start">
        <div
          style={{
            backgroundColor: theme.cardBackgroundColors.grey,
            padding: '12px 0',
            ...styleForIconAndButton,
          }}
        >
          <BankOutlined />
        </div>
        {bankSettingsDetails}
        <SettingsItemDropdown menuItems={menuItems} />
      </StyledSpaceForItemContent>
      <Drawer title="Bank bearbeiten" open={isUpdateModalOpen} onClose={toggleUpdateModalOpan} width={720} destroyOnClose>
        <RechtstraegerBankSettingsUpdateForm
          bankSettings={bankSettings}
          rechtstraegerId={rechtstraegerId}
          onCancel={toggleUpdateModalOpan}
          onSuccess={() => {
            onAction();
            toggleUpdateModalOpan();
          }}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <RABankSettingsChangeHistoryListingTable rechtstraegerId={rechtstraegerId} />
      </HistoryModal>
    </>
  );
};

export default RechtstraegerBankSettingsCard;
