import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../../../styles/pdfStyles';
import { Summe } from '../../../../../../topAbrechnung-types';

const TAAbrKreisAbrechnungskreisergebnis: FC<{ abrechnungskreisErgebnis: Summe }> = ({ abrechnungskreisErgebnis }) => (
  <View>
    <View style={[pdfStyles.row, { marginTop: '5mm' }]}>
      <View style={[pdfStyles.column, { width: '60%' }]}>
        <Text style={[pdfStyles.column, pdfStyles.textUppercase, { fontSize: '11px' }]}>{abrechnungskreisErgebnis.text}</Text>
      </View>
      <View style={[pdfStyles.column, { width: '40%' }]}>
        <Text style={[pdfStyles.column, { textAlign: 'right', fontSize: '11px' }]}>{abrechnungskreisErgebnis.betrag}</Text>
      </View>
    </View>

    <View style={[pdfStyles.row, pdfStyles.borderBottomSmall, { borderBottomWidth: 2 }]} />
  </View>
);

export default TAAbrKreisAbrechnungskreisergebnis;
