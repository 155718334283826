import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { TopFinancialPlanGenerierlaufFieldsFragmentDoc } from './TopFinancialPlanGenerierlaufFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TopFinancialPlanGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type TopFinancialPlanGenerierlaufQuery = {
  getTopFinancialPlanGenerierlauf: {
    data: {
      countFehler: number;
      countVerarbeitet: number;
      countNichtVerarbeitet: number;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      generierDatum: string;
      generierlaufId: string;
      budgeting: {
        bezeichnung: string;
        budgetSumNextYear?: number | null;
        budgetingId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        currentYear: number;
        increase?: number | null;
        prevYear: number;
        updatedByMitarbeiterId?: string | null;
        vertragVPosWertDate?: string | null;
        vorschreibungCurrentYearSum: number;
        vorschreibungPrevYearSum: number;
        restOfCurrentYearSum?: number | null;
        totalCurrentYearSum?: number | null;
        objekt: {
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          objektId: string;
          updatedByMitarbeiterId?: string | null;
          rechnungsAussteller: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          status: { text: string; value: Types.ObjektStatus; description?: string | null };
          verwaltungsart: { text: string; value: Types.Verwaltungsart };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        resultSourceType?: { description?: string | null; text: string; value: Types.BudgetingResultSourceType } | null;
        status: { text: string; value: Types.BudgetingStatus };
        warningList: Array<{ message: string; type: string }>;
      };
      entryList: Array<{
        budgetSumNextYear: number;
        budgetingResultVertragId?: string | null;
        currentStep?: number | null;
        generierlaufEntryId: string;
        infoMessage?: string | null;
        retry: boolean;
        stepCount?: number | null;
        bestandseinheit?: {
          bestandseinheitId: string;
          bezeichnung: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          objektId: string;
          status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { value: Types.GenerierlaufEntryExitCode; text: string } | null;
        stepList: Array<{
          designation: string;
          receivingApplication?: string | null;
          stepId: string;
          type?: string | null;
          status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
        }>;
        vertrag?: {
          bestandseinheitId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          objektId: string;
          vertragId: string;
          vertragsBeginn: string;
          status: { text: string; value: Types.VertragStatus; description?: string | null };
          vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
          vertragspartner: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        warningList: Array<{ message: string; type: string }>;
      }>;
      errorList: Array<{ message: string; type: string }>;
      status: { description?: string | null; value: Types.GenerierlaufStatus; text: string };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const TopFinancialPlanGenerierlaufDocument = gql`
  query TopFinancialPlanGenerierlauf($generierlaufId: ID!) {
    getTopFinancialPlanGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        ...TopFinancialPlanGenerierlaufFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${TopFinancialPlanGenerierlaufFieldsFragmentDoc}
`;
export function useTopFinancialPlanGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<TopFinancialPlanGenerierlaufQuery, TopFinancialPlanGenerierlaufQueryVariables> &
    ({ variables: TopFinancialPlanGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopFinancialPlanGenerierlaufQuery, TopFinancialPlanGenerierlaufQueryVariables>(
    TopFinancialPlanGenerierlaufDocument,
    options
  );
}
export function useTopFinancialPlanGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TopFinancialPlanGenerierlaufQuery, TopFinancialPlanGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TopFinancialPlanGenerierlaufQuery, TopFinancialPlanGenerierlaufQueryVariables>(
    TopFinancialPlanGenerierlaufDocument,
    options
  );
}
export function useTopFinancialPlanGenerierlaufSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TopFinancialPlanGenerierlaufQuery, TopFinancialPlanGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TopFinancialPlanGenerierlaufQuery, TopFinancialPlanGenerierlaufQueryVariables>(
    TopFinancialPlanGenerierlaufDocument,
    options
  );
}
export type TopFinancialPlanGenerierlaufQueryHookResult = ReturnType<typeof useTopFinancialPlanGenerierlaufQuery>;
export type TopFinancialPlanGenerierlaufLazyQueryHookResult = ReturnType<typeof useTopFinancialPlanGenerierlaufLazyQuery>;
export type TopFinancialPlanGenerierlaufSuspenseQueryHookResult = ReturnType<typeof useTopFinancialPlanGenerierlaufSuspenseQuery>;
export type TopFinancialPlanGenerierlaufQueryResult = Apollo.QueryResult<
  TopFinancialPlanGenerierlaufQuery,
  TopFinancialPlanGenerierlaufQueryVariables
>;
