import { FormFields } from '../../../../../../helpers/formikHelper';
import { InfoFeldFormValues } from '../../../../../shared/InfoFeld/infoFeldFormMapper';
import { BeInfoFeld_BeInfoFeldParkplatz_Fragment } from '../../../gql/BeInfoFeldFragments.types';

export interface ParkplatzUpdateFormValues extends InfoFeldFormValues {
  nummer: string;
  text?: string;
  bestandseinheitId?: string;
  deleteDocumentFileIdList: string[];
}

export const parkplatzUpdateFormFields: FormFields<ParkplatzUpdateFormValues> = {
  nummer: 'nummer',
  text: 'text',
  bestandseinheitId: 'bestandseinheitId',
  documentList: 'documentList',
  deleteDocumentFileIdList: 'deleteDocumentFileIdList',
};

export const mapToUpdateFormValues = (parkplatz: BeInfoFeld_BeInfoFeldParkplatz_Fragment): ParkplatzUpdateFormValues => ({
  documentList: [],
  deleteDocumentFileIdList: [],
  nummer: parkplatz.parkplatzNummer,
  text: parkplatz.text ?? undefined,
  bestandseinheitId: parkplatz.bestandseinheit?.bestandseinheitId ?? undefined,
});
