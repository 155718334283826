import React from 'react';
import { Link } from 'react-router-dom';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { TicketBase } from '../../../../types';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { generatePathToObjektDetailsPage } from '../../../Objekt/objektUriPaths';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { generatePathToBestandseinheitDetailsPage } from '../../../Bestandseinheit/bestandseinheitUriPaths';
import { generatePathToBeVertragDetailsPage } from '../../../Vertrag/BeVertrag/beVertragUriPaths';
import LinkWithStatus from '../../../../components/Link/LinkWithStatus';

export type Props = {
  isObjektSelected?: boolean;
  isBestandseinheitSelected?: boolean;
  isVertagSelected?: boolean;
};

const ticketTableObjektColumns = ({
  isObjektSelected = false,
  isBestandseinheitSelected = false,
  isVertagSelected = false,
}: Props = {}): TableWithColSelectorColumnProps<TicketBase>[] => {
  return [
    {
      title: 'Objekt',
      defaultSelected: isObjektSelected,
      render: (text, record) =>
        record.objekt ? (
          <LinkWithStatus
            status={record.objekt.status}
            text={record.objekt.kurzBezeichnung}
            maxTextLength={25}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            to={generatePathToObjektDetailsPage(record.objekt!.objektId)}
            openInNewTab
          />
        ) : (
          <TextForEmptyValue textToShow="minus" />
        ),
    },
    {
      title: 'Top',
      defaultSelected: isBestandseinheitSelected,
      render: (text, record) =>
        record.bestandseinheit ? (
          <LinkWithStatus
            status={record.bestandseinheit.status}
            text={record.bestandseinheit.bezeichnung}
            maxTextLength={25}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            to={generatePathToBestandseinheitDetailsPage(record.bestandseinheit!.objektId, record.bestandseinheit!.bestandseinheitId)}
            openInNewTab
          />
        ) : (
          <TextForEmptyValue textToShow="minus" />
        ),
    },
    {
      title: 'Vertag',
      defaultSelected: isVertagSelected,
      render: (text, record) =>
        record.vertrag ? (
          <DataWithShortenedText text={record.vertrag.kurzBezeichnung} maxTextLength={25}>
            {(shortenedText) => (
              <Link
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                to={generatePathToBeVertragDetailsPage(record.vertrag!.objektId, record.vertrag!.bestandseinheitId, record.vertrag!.vertragId)}
                target="_blank"
              >
                {shortenedText}
              </Link>
            )}
          </DataWithShortenedText>
        ) : (
          <TextForEmptyValue textToShow="minus" />
        ),
    },
  ];
};

export default ticketTableObjektColumns;
