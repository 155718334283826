import { generatePath } from 'react-router';
import { mapToQueryString, QueryParams } from '../../../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../../../hooks/usePaginationQueryParams';
import { URI_BOOKING_JOURNAL_PAGE } from '../../bookingJournalUriPaths';
import { FiltersFormValues } from './filtersFormMapper';

export enum QueryParamKey {
  BELEGDATUM_FROM = 'belegdatumFrom',
  BELEGDATUM_TO = 'belegdatumTo',
  BELEGNUMMER = 'belegnummer',
  BELEG_SYMBOL_LIST = 'belegSymbolList',
  BESTANDSEINHEIT_ID_LIST = 'bestandseinheitIdList',
  BETRAG_FROM = 'betragFrom',
  BETRAG_TO = 'betragTo',
  BUCHUNGSDATUM_FROM = 'buchungsdatumFrom',
  BUCHUNGSDATUM_TO = 'buchungsdatumTo',
  BUCHUNGS_KZ = 'buchungsKZ',
  BUCHUNGS_TYPE_LIST = 'buchungsTypeList',
  CREATE_TS_FROM = 'createTsFrom',
  CREATE_TS_TO = 'createTsTo',
  DUE_DATE_FROM = 'dueDateFrom',
  DUE_DATE_TO = 'dueDateTo',
  ERSTELLER_MITARBEITER_ID_LIST = 'erstellerMitarbeiterIdList',
  FACHLICHE_PRUEFUNG = 'fachlichePruefung',
  GEGEN_KONTO_ID = 'gegenKontoId',
  KONTO_ID = 'kontoId',
  OBJEKT_ID = 'objektId',
  RECHTSTRAEGER_ID_LIST = 'rechtstraegerIdList',
  SOLL_HABEN = 'sollHaben',
  SOURCE = 'source',
  STEUERSATZ_FROM = 'steuersatzFrom',
  STEUERSATZ_TO = 'steuersatzTo',
  STEUER_FROM = 'steuerFrom',
  STEUER_TO = 'steuerTo',
  STORNIERTE_BUCHUNG_BEZEICHNUNG = 'stornierteBuchungBezeichnung',
  STORNIERTE_BUCHUNG_ID = 'stornierteBuchungId',
  STORNO = 'storno',
  TEXT = 'text',
  UMBUCHUNG = 'umbuchung',
  UST_VOM_AUFWAND_STEUERSATZ_FROM = 'ustVomAufwandSteuersatzFrom',
  UST_VOM_AUFWAND_STEUERSATZ_TO = 'ustVomAufwandSteuersatzTo',
  VERTRAGSPARTNER_ID = 'vertragspartnerId',
  WAEHRUNG = 'waehrung',
}

export type TBookingJournalQueryParams = QueryParams<FiltersFormValues>;

export const updateQueryParams = (
  navigate: (path: string) => void,
  filters: TBookingJournalQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToBookingJournalListingPage(filters, paginationParams));

export const generatePathToBookingJournalListingPage = (filters: TBookingJournalQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  `${generatePath(URI_BOOKING_JOURNAL_PAGE)}?${mapToQueryString({
    [QueryParamKey.BELEGDATUM_FROM]: filters?.belegdatumFrom,
    [QueryParamKey.BELEGDATUM_TO]: filters?.belegdatumTo,
    [QueryParamKey.BELEGNUMMER]: filters?.belegnummer,
    [QueryParamKey.BELEG_SYMBOL_LIST]: filters?.belegSymbolList,
    [QueryParamKey.BESTANDSEINHEIT_ID_LIST]: filters?.bestandseinheitIdList,
    [QueryParamKey.BETRAG_FROM]: filters?.betragFrom,
    [QueryParamKey.BETRAG_TO]: filters?.betragTo,
    [QueryParamKey.BUCHUNGSDATUM_FROM]: filters?.buchungsdatumFrom,
    [QueryParamKey.BUCHUNGSDATUM_TO]: filters?.buchungsdatumTo,
    [QueryParamKey.BUCHUNGS_KZ]: filters?.buchungsKZ,
    [QueryParamKey.BUCHUNGS_TYPE_LIST]: filters?.buchungsTypeList,
    [QueryParamKey.CREATE_TS_FROM]: filters?.createTsFrom,
    [QueryParamKey.CREATE_TS_TO]: filters?.createTsTo,
    [QueryParamKey.DUE_DATE_FROM]: filters?.dueDateFrom,
    [QueryParamKey.DUE_DATE_TO]: filters?.dueDateTo,
    [QueryParamKey.ERSTELLER_MITARBEITER_ID_LIST]: filters?.erstellerMitarbeiterIdList,
    [QueryParamKey.FACHLICHE_PRUEFUNG]: filters?.fachlichePruefung,
    [QueryParamKey.GEGEN_KONTO_ID]: filters?.gegenKontoId,
    [QueryParamKey.KONTO_ID]: filters?.kontoId,
    [QueryParamKey.OBJEKT_ID]: filters?.objektId,
    [QueryParamKey.RECHTSTRAEGER_ID_LIST]: filters?.rechtstraegerIdList,
    [QueryParamKey.SOLL_HABEN]: filters?.sollHaben,
    [QueryParamKey.SOURCE]: filters?.source,
    [QueryParamKey.STEUERSATZ_FROM]: filters?.steuersatzFrom,
    [QueryParamKey.STEUERSATZ_TO]: filters?.steuersatzTo,
    [QueryParamKey.STEUER_FROM]: filters?.steuerFrom,
    [QueryParamKey.STEUER_TO]: filters?.steuerTo,
    [QueryParamKey.STORNIERTE_BUCHUNG_BEZEICHNUNG]: filters?.stornierteBuchungBezeichnung,
    [QueryParamKey.STORNIERTE_BUCHUNG_ID]: filters?.stornierteBuchungId,
    [QueryParamKey.STORNO]: filters?.storno,
    [QueryParamKey.TEXT]: filters?.text,
    [QueryParamKey.UMBUCHUNG]: filters?.umbuchung,
    [QueryParamKey.UST_VOM_AUFWAND_STEUERSATZ_FROM]: filters?.ustVomAufwandSteuersatzFrom,
    [QueryParamKey.UST_VOM_AUFWAND_STEUERSATZ_TO]: filters?.ustVomAufwandSteuersatzTo,
    [QueryParamKey.VERTRAGSPARTNER_ID]: filters?.vertragspartnerId,
    [QueryParamKey.WAEHRUNG]: filters?.waehrung,
    ...paginationParams,
  })}`;
