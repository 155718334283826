import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../../../styles/pdfStyles';
import { AufwandAufteilung } from '../../../../../../topAbrechnung-types';
import TATAufwandZeitscheibe from '../../../../../TATAufwandZeitscheibe';

const TAAbrKreisAufwandAufteilung: FC<{ aufwandAufteilung: AufwandAufteilung }> = ({ aufwandAufteilung }) => (
  <View style={[{ marginTop: '7mm' }]}>
    {/* Aufteilungschluessel name */}
    <Text>{aufwandAufteilung.aufteilungschluesselText}</Text>

    {/* ZeitscheibeList */}
    {aufwandAufteilung.zeitscheibeList.map((zeitscheibe) => (
      <TATAufwandZeitscheibe zeitscheibe={zeitscheibe} fontSize="9px" key={zeitscheibe.zeitraumText} />
    ))}

    {aufwandAufteilung.zeitscheibeList.length > 0 ? <View style={[pdfStyles.row, pdfStyles.borderBottomSmall, { marginTop: '2mm' }]} /> : null}
  </View>
);

export default TAAbrKreisAufwandAufteilung;
