import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { HauseigentuemerAbrechnung } from '../../hauseigentuemerAbrechnung-types';
import pdfStyles from '../../../styles/pdfStyles';
import DetailsColumnsTitles from './DetailsTable/DetailsColumnsTitles';
import Saldovortrag from './DetailsTable/Saldovortrag';
import Zwischensumme from './DetailsTable/Zwischensumme';
import HeAEigentuemerverrechnungBlock from './DetailsTable/HeAEigentuemerverrechnungBlock';
import HeAPositionBlock from './DetailsTable/HeAPositionBlock';
import LabelValue from '../../../shared/LabelValue';

const HeADetailsBlock: FC<{ data: HauseigentuemerAbrechnung }> = ({ data }) => (
  <View style={[pdfStyles.tablePaddingLeftRight, { marginTop: '3mm' }]}>
    {/* Details Table */}
    <DetailsColumnsTitles titles={data.detailsBlock.positionListHeader} />
    <Saldovortrag data={data.detailsBlock.saldovortrag} />

    {/* Einnahmen, Ausgaben and VstKuerzung */}
    {data.detailsBlock.einnahmen ? <HeAPositionBlock data={data.detailsBlock.einnahmen} /> : null}
    {data.detailsBlock.ausgaben ? <HeAPositionBlock data={data.detailsBlock.ausgaben} /> : null}
    {data.detailsBlock.vorsteuerkuerzung ? <HeAPositionBlock data={data.detailsBlock.vorsteuerkuerzung} /> : null}

    {/* Zwischensumme */}
    {data.detailsBlock.einnahmen || data.detailsBlock.ausgaben || data.detailsBlock.vorsteuerkuerzung ? (
      <Zwischensumme data={data.detailsBlock.zwischensumme} />
    ) : null}

    {/* Eigentuemerverrechnung */}
    {data.detailsBlock.eigentuemerverrechnung ? <HeAEigentuemerverrechnungBlock data={data.detailsBlock.eigentuemerverrechnung} /> : null}

    {/* ErgebnisAbrechnung */}
    <View style={[pdfStyles.column, { marginTop: '5mm' }]}>
      <LabelValue
        valueTuple={data.abrechnungErgebnis}
        viewStyle={{ ...pdfStyles.borderBottomSmall, borderBottomWidth: 2 }}
        labelStyle={{ width: '70%', fontWeight: 'bold' }}
        valueStyle={{ width: '30%', textAlign: 'right', fontWeight: 'bold' }}
      />
    </View>
  </View>
);

export default HeADetailsBlock;
