import React, { FC } from 'react';
import { useWeAbrechnungsdefinitionVersionListQuery } from '../../gql/WeAbrDef/WeAbrDefVersionQueries.types';
import { useDeleteWeAbrechnungsdefinitionVersionMutation } from '../../gql/WeAbrDef/WeAbrDefVersionMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import AbrDefVersionTimeline from './AbrDefVersionTimeline';
import { WeAbrechnungsdefinitionVersionListEntry } from '../../../../types';

type Props = {
  abrechnungsdefinitionId: string;
};

const AbrDefWeVersionTimeline: FC<Props> = ({ abrechnungsdefinitionId }) => {
  const { data, loading, refetch } = useWeAbrechnungsdefinitionVersionListQuery({ variables: { abrechnungsdefinitionId, withDetails: true } });
  const versionList = data?.getWeAbrechnungsdefinitionVersionList.data;

  const [deleteWegAbrDefVersion] = useDeleteWeAbrechnungsdefinitionVersionMutation({
    onCompleted: () => {
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onDelete = (version: WeAbrechnungsdefinitionVersionListEntry): Promise<unknown> => {
    return deleteWegAbrDefVersion({
      variables: {
        abrechnungsdefinitionId,
        abrechnungsdefinitionVersionId: version.abrechnungsdefinitionVersionId,
      },
    });
  };

  return <AbrDefVersionTimeline abrechnungsdefinitionId={abrechnungsdefinitionId} loading={loading} versionList={versionList} onDelete={onDelete} />;
};

export default AbrDefWeVersionTimeline;
