import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListMutationVariables = Types.Exact<{
  input: Types.AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListInput;
}>;

export type AssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListMutation = {
  assignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungList: {
    data: Array<{ abrechnungsdefinition: { abrechnungsdefinitionId: string } }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const AssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListDocument = gql`
  mutation AssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungList($input: AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListInput!) {
    assignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungList(input: $input) {
      data {
        abrechnungsdefinition {
          abrechnungsdefinitionId
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useAssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListMutation,
    AssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListMutation,
    AssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListMutationVariables
  >(AssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListDocument, options);
}
export type AssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListMutationHookResult = ReturnType<
  typeof useAssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListMutation
>;
export type AssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListMutationResult =
  Apollo.MutationResult<AssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListMutation>;
export type AssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListMutationOptions = Apollo.BaseMutationOptions<
  AssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListMutation,
  AssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListMutationVariables
>;
