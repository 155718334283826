import React from 'react';
import { FireOutlined, HomeOutlined } from '@ant-design/icons';
import { verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import { GroupedMenueListInterface } from '../../../components/ContextSidebar/ContentWithSidebar';
import { generatePathToVerarbeitungFirmendatenInitializerDetailsPage } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import VerarbeitungErrorAndWarningListing from '../../../features/Verarbeitung/shared/ErrorAndWarningListing/VerarbeitungErrorAndWarningListing';
import { FirmendatenInitializerGenerierlauf } from '../../../types';
import VerarbeitungFirmendatenInitializer from '../../../features/Verarbeitung/FirmendatenInitializer/VerarbeitungFirmendatenInitializer';

const menuListInfoFirmendatenInitializer = (generierlauf: FirmendatenInitializerGenerierlauf): GroupedMenueListInterface => {
  return {
    topMenuList: [
      {
        title: verarbeitungPageAndMenuListTitles.FirmendatenInitializer,
        icon: <HomeOutlined />,
        uri: `${generatePathToVerarbeitungFirmendatenInitializerDetailsPage(generierlauf.generierlaufId)}/entries`,
        content: () => <VerarbeitungFirmendatenInitializer generierlauf={generierlauf} />,
      },
      {
        state: generierlauf.errorList.length > 0 || generierlauf.warningList.length > 0 ? 'error' : undefined,
        title: verarbeitungPageAndMenuListTitles.ErrorsAndWarnings,
        icon: <FireOutlined />,
        uri: `${generatePathToVerarbeitungFirmendatenInitializerDetailsPage(generierlauf.generierlaufId)}/errors-and-warnings`,
        content: () => <VerarbeitungErrorAndWarningListing errorList={generierlauf.errorList} warningList={generierlauf.warningList} />,
      },
    ],
  };
};

export default menuListInfoFirmendatenInitializer;
