import React, { FC } from 'react';
import { Modal } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import { Form } from 'formik-antd';
import FormButtons from '../../../../components/Button/FormButtons';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { showSuccessMsgCreate } from '../../../../components/message';
import { AbrechnungType } from '../../../../types';
import ObjektSelectWithAllTag from '../../../../shared/ObjektSelect/ObjektSelectWithAllTag';
import { getAbrTypeName } from '../../shared/abrechnungHelpers';
import {
  AbrechnungFormValues,
  abrechnungFormFields,
  abrechnungFormInitialValues,
  mapFormValuesToFirmendatenAbrechnungCreateInput,
} from './abrechnungCreateFormMapper';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import { useCreateFirmendatenHeOrObjektOrSubAbrechnungGenerierlaufMutation } from '../../gql/AbrechnungGenerierlauf/AbrechnungGenerierlaufMutations.types';
import { abrechnungCreateValidationSchema } from './abrechnungValidationSchema';
import RangePicker from '../../../../components/DatePicker/RangePicker/RangePicker';

type Props = {
  type: AbrechnungType;
  onCreate: () => void;
  onCancel: () => void;
  visible: boolean;
};
const HeOrObjektOrSubAbrechnungCreateModal: FC<Props> = ({ type, onCreate, onCancel, visible }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'ObjektAbrechnungGenerierlauf'>('ObjektAbrechnungGenerierlauf');

  const [runCreate, { loading }] = useCreateFirmendatenHeOrObjektOrSubAbrechnungGenerierlaufMutation({
    onCompleted: () => {
      const entity = 'Abrechnung';
      showSuccessMsgCreate(entity);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const handleCreate = (values: AbrechnungFormValues, actions: FormikHelpers<AbrechnungFormValues>) => {
    const input = mapFormValuesToFirmendatenAbrechnungCreateInput(type, values);
    runCreate({ variables: { input } })
      .then(onCreate)
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <Modal title={`${getAbrTypeName(type)} erstellen`} open={visible} footer={null} onCancel={onCancel} destroyOnClose maskClosable={false}>
      <Formik<AbrechnungFormValues>
        initialValues={abrechnungFormInitialValues}
        validationSchema={abrechnungCreateValidationSchema}
        onSubmit={handleCreate}
      >
        {(formikProps) => (
          <Form layout="vertical">
            <FormItemWithFieldHelp
              name={abrechnungFormFields.objektIdList}
              label="Objekt"
              fieldHelp={getFieldHelpText('ObjektAbrechnungGenerierlauf.objektIdList')}
            >
              <ObjektSelectWithAllTag name={abrechnungFormFields.objektIdList} formikProps={formikProps} abrechnungType={type} />
            </FormItemWithFieldHelp>
            <FormItemWithFieldHelp
              name={abrechnungFormFields.fromTo}
              label="Abrechnungsperiode"
              fieldHelp={getFieldHelpText('ObjektAbrechnungGenerierlauf.fromInclusive')}
            >
              <RangePicker
                name={abrechnungFormFields.fromTo}
                //@ts-ignore
                style={{ width: '100%' }}
              />
            </FormItemWithFieldHelp>
            <FormButtons updateMode={false} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} isOkLoading={loading} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default HeOrObjektOrSubAbrechnungCreateModal;
