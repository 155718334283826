import React, { FC } from 'react';
import { Button, Tooltip, Space } from 'antd';
import { DeleteOutlined, HistoryOutlined } from '@ant-design/icons';
import HistoryModal, { HistoryType } from '../../../../History/shared/HistoryModal';
import BuchungszeileChangeHistoryListingTable from '../../BuchungszeileChangeHistoryListingTable';
import { useToggle } from '../../../../../hooks/useToggle';

type Props = {
  bookingSuggestionId: string;
  buchungszeileId: string;
  onRowDelete: () => void;
  romDeleteDisabled: boolean;
};

const BuchungszeileTableActions: FC<Props> = ({ bookingSuggestionId, buchungszeileId, onRowDelete, romDeleteDisabled }) => {
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  return (
    <>
      <Space.Compact>
        <Tooltip title="Entfernen" placement="topRight">
          <Button
            size="small"
            shape="circle"
            type="link"
            icon={<DeleteOutlined />}
            disabled={romDeleteDisabled}
            onClick={(e) => {
              onRowDelete();
            }}
          />
        </Tooltip>
        <Tooltip title="Änderungsverlauf">
          <Button
            size="small"
            shape="circle"
            type="link"
            icon={<HistoryOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              toggleChangeHistoryOpen();
            }}
          />
        </Tooltip>
      </Space.Compact>

      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <BuchungszeileChangeHistoryListingTable bookingSuggestionId={bookingSuggestionId} buchungszeileId={buchungszeileId} />
      </HistoryModal>
    </>
  );
};

export default BuchungszeileTableActions;
