import { FC } from 'react';
import { Input } from 'formik-antd';
import { postItFormFields } from '../PostItFormMapper';
import FormItemWithFieldHelp from '../../../Form/FormItemWithFieldHelp';

type Props = {
  fieldHelp?: string | null;
};

const PostItFormItemTextArea: FC<Props> = ({ fieldHelp }) => (
  <FormItemWithFieldHelp name={postItFormFields.text} fieldHelp={fieldHelp}>
    <Input.TextArea
      id={postItFormFields.text}
      name={postItFormFields.text}
      placeholder="Fügen Sie die Notiz hinzu..."
      autoSize={{ minRows: 10 }}
      //INFO: Add prefix so input doesn't loses focus - https://github.com/jannikbuschke/formik-antd/issues/200
      prefix=""
    />
  </FormItemWithFieldHelp>
);

export default PostItFormItemTextArea;
