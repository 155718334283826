import { isNotNil } from './assertionHelper';

const compareTwoNumbersForSorting = (number1: number | null | undefined, number2: number | null | undefined) => {
  if (!isNotNil(number1)) {
    return -1;
  } else if (!isNotNil(number2)) {
    return 1;
  }
  return number1 - number2;
};

export { compareTwoNumbersForSorting };
