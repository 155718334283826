import * as Yup from 'yup';
import { emailObjectArrayValidationSchema } from '../../shared/components/Contact/contactFieldsValidationSchema';
import { newUsernameValidationSchema } from './username/usernameValidationSchema';
import { newEmailValidationSchema } from '../../shared/components/Email/validationSchema';
import { entityMaxCharLength } from '../../components/message/validationMsg';
/* eslint-disable no-template-curly-in-string */
export const firstnameValidationSchema = Yup.string().nullable().max(64, entityMaxCharLength('Vorname', 64));

export const lastnameValidationSchema = Yup.string().nullable().max(64, entityMaxCharLength('Nachname', 64));

export const adminUpdateFormValidationSchema = Yup.object().shape({
  firstname: firstnameValidationSchema,
  lastname: lastnameValidationSchema,
  emails: emailObjectArrayValidationSchema,
});

export const adminCreateFormValidationSchema = Yup.object().shape({
  username: newUsernameValidationSchema.nullable(),
  email: newEmailValidationSchema.nullable(),
  firstname: firstnameValidationSchema,
  lastname: lastnameValidationSchema,
  emails: emailObjectArrayValidationSchema,
});

/* eslint-enable no-template-curly-in-string */
