import { IndexSeries, IndexSeriesCreateInput, IndexSeriesSourceType, IndexSeriesUpdateInput } from '../../../../types';
import { IndexSeriesFormValues } from '../../../IndexSeries/Form/indexSeriesFormMapper';

export const mapIndexSeriesToFormValues = (indexSeries?: IndexSeries): IndexSeriesFormValues => ({
  name: indexSeries?.name ?? '',
  indexType: indexSeries?.indexType.value ?? null,
  sourceType: IndexSeriesSourceType.Manual,
});

export const mapFormValuesToIndexSeries = (values: IndexSeriesFormValues): IndexSeriesCreateInput | IndexSeriesUpdateInput => ({
  name: values.name,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  indexType: values.indexType!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  sourceType: values.sourceType!,
});
