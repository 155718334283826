import React, { FC } from 'react';
import { View } from '@react-pdf/renderer';
import pdfStyles from '../../styles/pdfStyles';
import { ObjektZahlungsrueckstaendeBlock } from '../../hauseigentuemerAbrechnung/hauseigentuemerAbrechnung-types';
import ZrColumnTitles from './ZrColumnTitles';
import ZrSummary from './ZrSummary';
import ZrLineList from './ZrLineList';

const ObjektZahlungsrueckstaendeTable: FC<{ data: ObjektZahlungsrueckstaendeBlock }> = ({ data }) => (
  <View style={[pdfStyles.column, pdfStyles.tablePaddingLeftRight, { marginTop: '3mm', paddingRight: '25mm' }]}>
    {/* Column titles */}
    <ZrColumnTitles titles={data.zahlungsrueckstaendeListHeader} />

    {/* Objekt-Zahlungsrueckstaende-Zeile List */}
    <ZrLineList data={data.zahlungsrueckstaendeList} />

    {/* Summary */}
    <ZrSummary data={data.zahlungsrueckstaendeSummary} />
  </View>
);

export default ObjektZahlungsrueckstaendeTable;
