import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestartMahnungVersendenGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartMahnungVersendenGenerierlaufMutation = {
  actionRestartMahnungVersendenGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateMahnungVersendenGenerierlaufMutationVariables = Types.Exact<{
  input: Types.MahnungVersendenGenerierlaufInput;
}>;

export type CreateMahnungVersendenGenerierlaufMutation = {
  createMahnungVersendenGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RestartMahnungVersendenGenerierlaufDocument = gql`
  mutation RestartMahnungVersendenGenerierlauf($generierlaufId: ID!) {
    actionRestartMahnungVersendenGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartMahnungVersendenGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<RestartMahnungVersendenGenerierlaufMutation, RestartMahnungVersendenGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestartMahnungVersendenGenerierlaufMutation, RestartMahnungVersendenGenerierlaufMutationVariables>(
    RestartMahnungVersendenGenerierlaufDocument,
    options
  );
}
export type RestartMahnungVersendenGenerierlaufMutationHookResult = ReturnType<typeof useRestartMahnungVersendenGenerierlaufMutation>;
export type RestartMahnungVersendenGenerierlaufMutationResult = Apollo.MutationResult<RestartMahnungVersendenGenerierlaufMutation>;
export type RestartMahnungVersendenGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartMahnungVersendenGenerierlaufMutation,
  RestartMahnungVersendenGenerierlaufMutationVariables
>;
export const CreateMahnungVersendenGenerierlaufDocument = gql`
  mutation CreateMahnungVersendenGenerierlauf($input: MahnungVersendenGenerierlaufInput!) {
    createMahnungVersendenGenerierlauf(input: $input) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateMahnungVersendenGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMahnungVersendenGenerierlaufMutation, CreateMahnungVersendenGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMahnungVersendenGenerierlaufMutation, CreateMahnungVersendenGenerierlaufMutationVariables>(
    CreateMahnungVersendenGenerierlaufDocument,
    options
  );
}
export type CreateMahnungVersendenGenerierlaufMutationHookResult = ReturnType<typeof useCreateMahnungVersendenGenerierlaufMutation>;
export type CreateMahnungVersendenGenerierlaufMutationResult = Apollo.MutationResult<CreateMahnungVersendenGenerierlaufMutation>;
export type CreateMahnungVersendenGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  CreateMahnungVersendenGenerierlaufMutation,
  CreateMahnungVersendenGenerierlaufMutationVariables
>;
