import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { useAuth } from '../../../shared/Auth/authContext';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../../shared/dataChangedSubscription';

export const useOnVertragspartnerAdresseDataChangedEvents = (key: string, callback: () => Promise<unknown>, rechtstraegerId: string) => {
  const { user } = useFDAuthorized();
  const { mitarbeiter } = useAuth();

  useOnDataChangedEvent({
    filter: {
      userId: user.username,
      mitarbeiterId: mitarbeiter?.mitarbeiterId,
      type: dataChangedSubscriptionTypes.rechtstraegerHauptTagAddress,
      params: {
        rechtstraegerId,
      },
    },
    notificationDescription: 'Haupttag-Adresse wurde gerade verändert',
    callback,
    key,
  });
};

export const useOnVertragspartnerEmailDataChangedEvent = (key: string, callback: () => Promise<unknown>, rechtstraegerId: string) => {
  const { user } = useFDAuthorized();
  const { mitarbeiter } = useAuth();

  useOnDataChangedEvent({
    filter: {
      userId: user.username,
      mitarbeiterId: mitarbeiter?.mitarbeiterId,
      type: dataChangedSubscriptionTypes.rechtstraegerHauptTagEmail,
      params: {
        rechtstraegerId,
      },
    },
    notificationDescription: 'Haupttag-Email wurde gerade verändert',
    callback,
    key,
  });
};
