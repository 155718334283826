import React, { FC } from 'react';
import { Space } from 'antd';
import { UploadedFileView } from '../../../../../shared/UploadedFileView';
import { useDeleteInfoMailDeliveryAttachmentMutation } from '../../../gql/InfoMailDeliveryWizardMutations.types';
import { showSuccessMsgDelete } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { WizardFileInfo } from '../../../../../../types';

type Props = {
  attachmentList?: WizardFileInfo[];
  infoMailDeliveryId: string;
  infoMailDeliveryWizardId: string;
  isCompleted?: boolean;
  onAction: () => void;
};

const AttachmentPartUploadView: FC<Props> = ({ attachmentList, onAction, infoMailDeliveryWizardId, infoMailDeliveryId, isCompleted }) => {
  const [runDelete] = useDeleteInfoMailDeliveryAttachmentMutation({
    onCompleted: () => {
      showSuccessMsgDelete('File');
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const uploadedFileList = attachmentList?.map((attachment, index) => {
    const onDelete = () => {
      if (isCompleted) return;
      runDelete({ variables: { infoMailDeliveryId, infoMailDeliveryWizardId, attachmentId: attachment.fileId } });
    };
    return <UploadedFileView key={index} filename={attachment.name} filesize={attachment.size} onDelete={onDelete} disableDelete={isCompleted} />;
  });

  return <Space direction="vertical">{uploadedFileList}</Space>;
};

export default AttachmentPartUploadView;
