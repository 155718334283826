import React, { FC } from 'react';
import { Button, Divider, Space } from 'antd';
import ButtonsAligned, { ButtonsAlignedUsage } from './ButtonsAligned';

type FormButtonsProps = {
  /** default: Abbrechen */
  cancelText?: string;
  onCancel: () => void;
  isOkDisabled?: boolean;
  isCancelDisabled?: boolean;
  /** function to be excecuted upon submitting the form */
  isOkLoading?: boolean;
  onOk?: () => void;
  /**
   * default: Abschicken,
   *
   * default if updateMode === true: Aktualisieren
   */
  okText?: string;
  updateMode: boolean;
};

/**
 * <h2>Usage</h2>
 * In any form used in Drawer, Modal or other places, where two buttons are needed: one for canceling and one for submitting the form.
 * Use also in forms with steps where 'Weiter' and 'Zurück' buttons are needed
 */
const FormButtons: FC<FormButtonsProps> = ({
  isOkDisabled = false,
  onOk,
  okText,
  cancelText,
  onCancel,
  isCancelDisabled,
  updateMode,
  isOkLoading,
}) => {
  let submitText;
  if (okText) {
    submitText = okText;
  } else {
    submitText = updateMode ? 'Aktualisieren' : 'Abschicken';
  }
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Divider style={{ margin: '8px 0' }} />
      <ButtonsAligned usage={ButtonsAlignedUsage.drawerFooter}>
        <Button
          disabled={isCancelDisabled}
          onClick={(e) => {
            e.preventDefault();
            onCancel();
          }}
        >
          {cancelText ?? 'Abbrechen'}
        </Button>
        <Button type="primary" htmlType="submit" disabled={isOkDisabled} loading={isOkLoading} onClick={onOk || (() => {})}>
          {submitText}
        </Button>
      </ButtonsAligned>
    </Space>
  );
};

export default FormButtons;
