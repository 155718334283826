import { generatePath } from 'react-router-dom';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../constants/andromedaSystemSettingsUriPaths';

export const URI_ANDROMEDA_SYS_SETTINGS_MAHNDEFINITION_PAGE = {
  createPage: `${URI_ANDROMEDA_SYS_SETTINGS.mahndefinitionPage}/new`,
  updatePage: `${URI_ANDROMEDA_SYS_SETTINGS.mahndefinitionPage}/:mahndefinitionId`,
};

export const generatePathToMahndefinitionTemplateUpdatePage = (mahndefinitionId: string) =>
  generatePath(URI_ANDROMEDA_SYS_SETTINGS_MAHNDEFINITION_PAGE.updatePage, { mahndefinitionId });

export default URI_ANDROMEDA_SYS_SETTINGS_MAHNDEFINITION_PAGE;
