import { FC } from 'react';
import { Typography } from 'antd';
import { Spacer } from '../../../components/Grid';
import { verarbeitungPageAndMenuListTitles } from '../verarbeitungHelpers';
import { TopFinancialPlanGenerierlauf } from '../../../types';
import TopFinancialPlanVerarbeitungEntryListing from './EntryListing/TopFinancialPlanVerarbeitungEntryListing';

type Props = {
  generierlauf: TopFinancialPlanGenerierlauf;
};

const VerarbeitungTopFinancialPlan: FC<Props> = ({ generierlauf }) => {
  return (
    <>
      <Typography.Title level={4}>{verarbeitungPageAndMenuListTitles.TopFinancialPlan}</Typography.Title>
      <Spacer />
      <TopFinancialPlanVerarbeitungEntryListing generierlauf={generierlauf} />
    </>
  );
};

export default VerarbeitungTopFinancialPlan;
