import { FormikProps } from 'formik';
import { IndexationAgreementCategoryFormValues } from '../Category/categoryFormMapper';
import { mapDateFormValueToMonthYear } from '../indexationAgreementHelpers';
import { useFirmendatenIndexSeriesFinalValueLazyQuery } from '../../../../../../../../Firmendaten/IndexSeries/gql/FirmendatenIndexSeriesQueries.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../../../../helpers/apolloHelper';
import { IndexationAgreementReferenceValueFormValues } from '../ReferenceValue/referenceValueFormMapper';
import { IndexationAgreementIndexAnnualFormValues } from '../IndexAnnual/indexAnnualFormMapper';
import { IndexationAgreementIndexThresholdFormValues } from '../IndexThreshold/indexThresholdFormMapper';

type TIndexactionAgreementFormValues =
  | FormikProps<IndexationAgreementCategoryFormValues>
  | FormikProps<IndexationAgreementIndexAnnualFormValues>
  | FormikProps<IndexationAgreementIndexThresholdFormValues>
  | FormikProps<IndexationAgreementReferenceValueFormValues>;

export const useSetIndexOld = () => {
  const [runQuery] = useFirmendatenIndexSeriesFinalValueLazyQuery({
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const handleIndexSeriesChange = (indexOldName: string, indexSeriesId: string, formikProps: TIndexactionAgreementFormValues) => {
    const isIndexOldTouched = formikProps.touched.indexOld;
    const shouldUpdateValue = !isIndexOldTouched || (isIndexOldTouched && !formikProps.values.indexOld);

    if (shouldUpdateValue && formikProps.values.monthYearOld && indexSeriesId) {
      runQuery({
        variables: {
          indexSeriesId,
          month: mapDateFormValueToMonthYear(formikProps.values.monthYearOld).month,
          year: mapDateFormValueToMonthYear(formikProps.values.monthYearOld).year,
        },
      }).then((res) => {
        const finalValue = res.data?.getIndexSeriesFinalValue.data;
        formikProps.setFieldValue(indexOldName, finalValue);
        formikProps.setFieldTouched(indexOldName, false);
      });
    }
  };

  const handleMonthYearChange = (indexOldName: string, month: number, year: number, formikProps: TIndexactionAgreementFormValues) => {
    const isIndexOldTouched = formikProps.touched.indexOld;
    const shouldUpdateValue = !isIndexOldTouched || (isIndexOldTouched && !formikProps.values.indexOld);

    if (shouldUpdateValue && !!formikProps.values.indexSeriesId) {
      runQuery({
        variables: {
          indexSeriesId: formikProps.values.indexSeriesId,
          month,
          year,
        },
      }).then((res) => {
        const finalValue = res.data?.getIndexSeriesFinalValue.data;
        formikProps.setFieldValue(indexOldName, finalValue);
        formikProps.setFieldTouched(indexOldName, false);
      });
    }
  };

  return { handleIndexSeriesChange, handleMonthYearChange };
};
