import React, { FC } from 'react';
import { FormikProps } from 'formik';
import FormItemWithTooltip from '../../../../../../components/Form/FormItemWithTooltip';
import {
  VorsteuerkuerzungKontenZuweisungVersionFormValues,
  vstKuerzungKontenZuordnungVersionFormFields,
  vstKuerzungKontenZuweisungFormFields,
} from '../../../../../Vorsteuerkuerzung/KontenZuweisung/Version/Form/vstKuerzungKontenZuweisungVersionFormMapper';
import AufteilungsschluesselTemplateSelect from '../../../../../AufteilungsschluesselTemplateSelect/AufteilungsschluesselTemplateSelect';

type Props = {
  formikProps: FormikProps<VorsteuerkuerzungKontenZuweisungVersionFormValues>;
  index: number;
};

const AufteilungsschluesselTemplateSelectColumn: FC<Props> = ({ formikProps, index }) => {
  const name = `${vstKuerzungKontenZuordnungVersionFormFields.kontenZuweisungList}.${index}.${vstKuerzungKontenZuweisungFormFields.aufteilungsschluesselId}`;

  const excludedAuftSchluesselIdsWithSameVstKuerzungId = formikProps.values.kontenZuweisungList
    .filter((konto) => konto.vorsteuerkuerzungKontoId === formikProps.values.kontenZuweisungList[index].vorsteuerkuerzungKontoId)
    .map((konto) => konto.aufteilungsschluesselId)
    .filter((kontoId) => kontoId !== formikProps.values.kontenZuweisungList[index].aufteilungsschluesselId)
    .filter((kontoId) => kontoId);

  return (
    <FormItemWithTooltip name={name}>
      <AufteilungsschluesselTemplateSelect
        name={name}
        allowClear={false}
        excludedAufteilungsschluesselIds={excludedAuftSchluesselIdsWithSameVstKuerzungId}
      />
    </FormItemWithTooltip>
  );
};

export default AufteilungsschluesselTemplateSelectColumn;
