import { BelegTextePlatzhalter } from '../../../Belegtext/Form/belegTexteFormTypes';
import { getBelegTexteValue, getURLToBelegTexteBildFileId } from './belegTexteHelpers';
import { getBelegTexteForTypeTextOrBild } from './belegTexteTemplateHelpers';
import { BelegTexte } from '../../../../types';
import { DocumentHeaderAndFooterImageValue } from '../../../../pages/PDFTemplates/templates/shared/types';

export const getDocumentHeaderWithoutTextbaustein = (firmendatenId: string, belegTexte: BelegTexte, belegTextePlatzhalter: BelegTextePlatzhalter) => {
  const belegKopfValue: DocumentHeaderAndFooterImageValue | string | undefined = getBelegTexteValue(
    belegTexte.belegKopfTextAsHtml,
    getURLToBelegTexteBildFileId(firmendatenId, belegTexte.belegKopfBildFileInfo?.fileId, belegTexte.belegKopfBildAlignment?.value)
  );

  return getBelegTexteForTypeTextOrBild(belegKopfValue, belegTextePlatzhalter);
};

export const getDocumentFooterWithoutTextbaustein = (firmendatenId: string, belegTexte: BelegTexte, belegTextePlatzhalter: BelegTextePlatzhalter) => {
  const isBelegTextKopfEmpty = !(belegTexte.belegKopfBildFileInfo?.fileId || belegTexte.belegKopfTextAsHtml);

  const belegFussValue: DocumentHeaderAndFooterImageValue | string | undefined = getBelegTexteValue(
    belegTexte.belegFussTextAsHtml,
    getURLToBelegTexteBildFileId(firmendatenId, belegTexte.belegFussBildFileInfo?.fileId, belegTexte.belegFussBildAlignment?.value)
  );

  return !isBelegTextKopfEmpty ? getBelegTexteForTypeTextOrBild(belegFussValue, belegTextePlatzhalter) : undefined;
};

export const findAndReplacedPlatzhalterWithoutTextbaustein = (text: string, belegTextePlatzhalter: BelegTextePlatzhalter) => {
  let textWithReplacedPlatzhalter = text;

  const findAndReplacePlatzhalter = (value: string, text?: string | null) => {
    const platzhalter = '${' + value + '}';
    if (textWithReplacedPlatzhalter.includes(platzhalter)) {
      textWithReplacedPlatzhalter = textWithReplacedPlatzhalter.replaceAll(platzhalter, text ?? platzhalter);
    }
  };

  findAndReplacePlatzhalter('Hausverwaltung.Hauptadresse', belegTextePlatzhalter.hauptAddress);
  findAndReplacePlatzhalter('Hausverwaltung.Uid', belegTextePlatzhalter.vatIdentificationNumber);
  findAndReplacePlatzhalter('Hausverwaltung.Firmenbuchnummer', belegTextePlatzhalter.companyRegistrationNumber);
  findAndReplacePlatzhalter('Hausverwaltung.Telefonnummer', belegTextePlatzhalter.phoneNumber);
  findAndReplacePlatzhalter('Hausverwaltung.E-Mail', belegTextePlatzhalter.email);

  return textWithReplacedPlatzhalter;
};
