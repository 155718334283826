import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { CamtGenerierlauf } from '../../../types';
import menuListCamtOnlineBankingManually from './menuListCamtOnlineBankingManually';
import { CamtOnlineBankingManuallyPageRouteParams } from './routes';
import { useCamtGenerierlaufQuery } from '../../../features/Verarbeitung/gql/CamtGenerierlauf/CamtGenerierlaufQueries.types';

const CamtOnlineBankingManuallyDetailsPage = () => {
  const { generierlaufId } = useParams() as CamtOnlineBankingManuallyPageRouteParams;

  const { data } = useCamtGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getCamtGenerierlauf.data;

  return (
    <DetailsPageTemplate<CamtGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.CamtOnlineBankingManually}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard generierlauf={generierlauf} cardTitle={verarbeitungDetailsCardTitles.CamtOnlineBankingManually} />
      )}
      isLoading={false}
      sidebarMenuList={menuListCamtOnlineBankingManually}
    />
  );
};

export default CamtOnlineBankingManuallyDetailsPage;
