import React from 'react';
import { Route } from 'react-router';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../security/permissionChecks';
import SysSettingsAbrKontenzuordnungPage from './SysSettingsAbrKontenzuordnungPage';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_SYS_SETTINGS } from '../../../../constants/configurationUriPaths';
import { KontoKlasse } from '../../../../types';

export type AbrKontenzuordnungPageRouteParams = { abrechnungsdefinitionId?: string | undefined; kontoKlasse?: KontoKlasse | undefined };

const sysSettingsAbrKontenzuordnungPageRoute = (
  <Route
    path={URI_SYS_SETTINGS.abrechnungKontenZuordnungPage}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsAbrKontenzuordnungPage />} />}
  />
);

export default sysSettingsAbrKontenzuordnungPageRoute;
