import { FC } from 'react';
import { Typography } from 'antd';
import { SubAbrechnungGenerierlauf } from '../../../types';
import { verarbeitungPageAndMenuListTitles, verarbeitungTypes } from '../verarbeitungHelpers';
import { Spacer } from '../../../components/Grid';
import SubAbrechnungVerarbeitungEntryListing from './EntryListing/SubAbrechnungVerarbeitungEntryListing';

type Props = {
  generierlauf: SubAbrechnungGenerierlauf;
};

const VerarbeitungSubAbrechnung: FC<Props> = ({ generierlauf }) => {
  return (
    <>
      <Typography.Title level={4}>{verarbeitungPageAndMenuListTitles.SubAbrechnung}</Typography.Title>
      <Spacer />
      <SubAbrechnungVerarbeitungEntryListing generierlauf={generierlauf} verarbeitungType={verarbeitungTypes.SubAbrechnung} />
    </>
  );
};

export default VerarbeitungSubAbrechnung;
