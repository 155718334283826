import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../styles/pdfStyles';
import { columnWidthsAndAlignment, grundlageColumnPaddingLeft } from './aufwandAufteilung-table-styles';
import { RepFondsAufwandAufteilungLine } from '../../../../topAbrechnung-types';

const AufwandAufteilungList: FC<{ aufwandAufteilungList: RepFondsAufwandAufteilungLine[] }> = ({ aufwandAufteilungList }) => (
  <View style={[pdfStyles.column, pdfStyles.borderBottomSmall, { marginTop: '2mm' }]}>
    {aufwandAufteilungList.map((aufwandAufteilung, index) => (
      <View style={pdfStyles.row} key={index}>
        <Text
          style={[
            pdfStyles.textBulletin,
            {
              width: columnWidthsAndAlignment.basis.width,
              textAlign: columnWidthsAndAlignment.basis.textAlign,
            },
          ]}
        >
          {aufwandAufteilung.basis}
        </Text>
        <Text
          style={[
            pdfStyles.textBulletin,
            {
              width: columnWidthsAndAlignment.anteilNetto.width,
              textAlign: columnWidthsAndAlignment.anteilNetto.textAlign,
            },
          ]}
        >
          {aufwandAufteilung.anteilNetto}
        </Text>
        <Text
          style={[
            pdfStyles.textBulletin,
            {
              width: columnWidthsAndAlignment.ustProzent.width,
              textAlign: columnWidthsAndAlignment.ustProzent.textAlign,
            },
          ]}
        >
          {aufwandAufteilung.ustProzent}
        </Text>
        <Text
          style={[
            pdfStyles.textBulletin,
            {
              width: columnWidthsAndAlignment.ust.width,
              textAlign: columnWidthsAndAlignment.ust.textAlign,
            },
          ]}
        >
          {aufwandAufteilung.ust}
        </Text>
        <Text
          style={[
            pdfStyles.textBulletin,
            {
              width: columnWidthsAndAlignment.summe.width,
              textAlign: columnWidthsAndAlignment.summe.textAlign,
            },
          ]}
        >
          {aufwandAufteilung.summe}
        </Text>
        <Text
          style={[
            pdfStyles.textBulletin,
            {
              width: columnWidthsAndAlignment.grundlage.width,
              textAlign: columnWidthsAndAlignment.grundlage.textAlign,
              paddingLeft: grundlageColumnPaddingLeft,
            },
          ]}
        >
          {aufwandAufteilung.grundlage}
        </Text>
        <Text
          style={[
            pdfStyles.textBulletin,
            {
              width: columnWidthsAndAlignment.belastung.width,
              textAlign: columnWidthsAndAlignment.belastung.textAlign,
            },
          ]}
        >
          {aufwandAufteilung.belastung}
        </Text>
      </View>
    ))}
  </View>
);

export default AufwandAufteilungList;
