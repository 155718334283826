import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSubAdministrationPostItMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  subAdministrationId: Types.Scalars['ID']['input'];
  input: Types.PostItInput;
}>;

export type CreateSubAdministrationPostItMutation = {
  createSubAdministrationPostIt: {
    data: { postItId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateSubAdministrationPostItDocument = gql`
  mutation createSubAdministrationPostIt($objektId: ID!, $subAdministrationId: ID!, $input: PostItInput!) {
    createSubAdministrationPostIt(objektId: $objektId, subAdministrationId: $subAdministrationId, input: $input) {
      data {
        postItId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateSubAdministrationPostItMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSubAdministrationPostItMutation, CreateSubAdministrationPostItMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSubAdministrationPostItMutation, CreateSubAdministrationPostItMutationVariables>(
    CreateSubAdministrationPostItDocument,
    options
  );
}
export type CreateSubAdministrationPostItMutationHookResult = ReturnType<typeof useCreateSubAdministrationPostItMutation>;
export type CreateSubAdministrationPostItMutationResult = Apollo.MutationResult<CreateSubAdministrationPostItMutation>;
export type CreateSubAdministrationPostItMutationOptions = Apollo.BaseMutationOptions<
  CreateSubAdministrationPostItMutation,
  CreateSubAdministrationPostItMutationVariables
>;
