import React, { FC } from 'react';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { HeAbrechnungsdefinitionVersionListEntry } from '../../../../types';
import { useHeAbrechnungsdefinitionTemplateVersionListQuery } from '../../gql/HeAbrDef/HeAbrDefTemplateVersionQueries.types';
import { useDeleteHeAbrechnungsdefinitionTemplateVersionMutation } from '../../gql/HeAbrDef/HeAbrDefTemplateVersionMutations.types';
import AbrDefTemplateVersionTimeline from './AbrDefTemplateVersionTimeline';

type Props = {
  abrechnungsdefinitionId: string;
};

const AbrDefTemplateHeVersionTimeline: FC<Props> = ({ abrechnungsdefinitionId }) => {
  const { data, loading, refetch } = useHeAbrechnungsdefinitionTemplateVersionListQuery({ variables: { abrechnungsdefinitionId } });
  const versionList = data?.getHeAbrechnungsdefinitionTemplateVersionList.data;

  const [deleteHeAbrDefVersion] = useDeleteHeAbrechnungsdefinitionTemplateVersionMutation({
    onCompleted: () => {
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onDelete = (version: HeAbrechnungsdefinitionVersionListEntry): Promise<unknown> => {
    return deleteHeAbrDefVersion({
      variables: {
        abrechnungsdefinitionId,
        abrechnungsdefinitionVersionId: version.abrechnungsdefinitionVersionId,
      },
    });
  };

  return (
    <AbrDefTemplateVersionTimeline
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      loading={loading}
      versionList={versionList}
      onDelete={onDelete}
    />
  );
};

export default AbrDefTemplateHeVersionTimeline;
