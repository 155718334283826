import React, { FC } from 'react';
import { Empty, Table } from 'antd';
import { v4 as uuid4 } from 'uuid';
import zahlungsrueckstandTableColumns from './zahlungsrueckstandTableColumns';
import { Spacer } from '../../../../components/Grid';
import { HeAbrechnung, HeAbrZahlungsrueckstand } from '../../../../types';

type Props = {
  abrechnung: HeAbrechnung;
  objektId: string;
  loading: boolean;
};

const ZahlungsrueckstandTab: FC<Props> = ({ abrechnung, objektId }) => {
  const zahlungsrueckstandList = abrechnung.zahlungsrueckstandList || [];

  return (
    <>
      <Spacer height={16} />
      <Table<HeAbrZahlungsrueckstand>
        size="small"
        locale={{
          emptyText: <Empty description={<span>Keine Zahlungsrückstände vorhanden</span>} />,
        }}
        columns={zahlungsrueckstandTableColumns(abrechnung, objektId)}
        dataSource={zahlungsrueckstandList}
        rowKey={() => uuid4()}
      />
    </>
  );
};

export default ZahlungsrueckstandTab;
