import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type AddressFieldsFragment = {
  addressId: string;
  city: string;
  cityAdditionalInformation?: string | null;
  countryCodeIso2: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  haupt?: boolean | null;
  houseEntranceApartmentNumber?: string | null;
  postofficeBoxNumber?: string | null;
  postofficeBoxZipCode?: string | null;
  street?: string | null;
  tagList: Array<string>;
  type: Types.AddressType;
  zipCode?: string | null;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type OrderAddressFragment = {
  addressId: string;
  city: string;
  cityAdditionalInformation?: string | null;
  countryCodeIso2: string;
  houseEntranceApartmentNumber?: string | null;
  postofficeBoxNumber?: string | null;
  postofficeBoxZipCode?: string | null;
  street?: string | null;
  type: Types.AddressType;
  zipCode?: string | null;
};

export type FibuAddressFragment = {
  addressId: string;
  city: string;
  cityAdditionalInformation?: string | null;
  countryCodeIso2: string;
  houseEntranceApartmentNumber?: string | null;
  postofficeBoxNumber?: string | null;
  postofficeBoxZipCode?: string | null;
  street?: string | null;
  type: Types.AddressType;
  zipCode?: string | null;
};

export const AddressFieldsFragmentDoc = gql`
  fragment AddressFields on Address {
    addressId
    city
    cityAdditionalInformation
    countryCodeIso2
    createTs
    createdBy
    createdByMitarbeiterId
    haupt
    houseEntranceApartmentNumber
    postofficeBoxNumber
    postofficeBoxZipCode
    street
    tagList
    type
    warningList {
      attribute
      message
      type
    }
    zipCode
  }
`;
export const OrderAddressFragmentDoc = gql`
  fragment OrderAddress on OrderAddress {
    addressId
    city
    cityAdditionalInformation
    countryCodeIso2
    houseEntranceApartmentNumber
    postofficeBoxNumber
    postofficeBoxZipCode
    street
    type
    zipCode
    addressId
    city
    cityAdditionalInformation
    countryCodeIso2
  }
`;
export const FibuAddressFragmentDoc = gql`
  fragment FibuAddress on FibuAddress {
    addressId
    city
    cityAdditionalInformation
    countryCodeIso2
    houseEntranceApartmentNumber
    postofficeBoxNumber
    postofficeBoxZipCode
    street
    type
    zipCode
    addressId
    city
    cityAdditionalInformation
    countryCodeIso2
  }
`;
