import React, { FC } from 'react';
import { Skeleton, Typography } from 'antd';
import { useInfoMailDeliveryQuery } from './gql/InfoMailDeliveryQueries.types';
import { StyledEmailDeliverySpace } from '../Styled/Space.style';
import EmailDeliveryDefinitionTextOrLink from '../EmailDeliveryDefinitionTextOrLink';

type Props = {
  infoMailDeliveryId: string;
};

const InfoMailDeliveryDefinition: FC<Props> = ({ infoMailDeliveryId }) => {
  const { data, loading } = useInfoMailDeliveryQuery({ variables: { infoMailDeliveryId } });

  const emailDeliveryData = data?.getInfoMailDelivery.data;

  return (
    <StyledEmailDeliverySpace>
      <Typography.Text>Text aus Vorlage</Typography.Text>
      <Skeleton paragraph={false} active loading={loading} style={{ minWidth: 150 }}>
        {emailDeliveryData && (
          <EmailDeliveryDefinitionTextOrLink
            bezeichnung={emailDeliveryData.emailDeliveryDefinitionBezeichnung}
            emailDeliveryDefinitionId={emailDeliveryData.emailDeliveryDefinitionId}
          />
        )}
      </Skeleton>
    </StyledEmailDeliverySpace>
  );
};

export default InfoMailDeliveryDefinition;
