import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { TableProps, Typography } from 'antd';
import { StyledTableWithPagination } from './styled/StyledTableWithPagination.style';
import { buildPaginationQueryParams } from '../TableWithColSelector/tableWithColSelectorHelpers';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, DEFAULT_SIZE_CHANGER } from '../../../hooks/useQueryWithPagingAndSorting';

export type TablePaginationProps =
  | boolean
  | (TablePaginationConfig & {
      syncWithUri?: boolean;
    });

export type TableWithPaginationProps<T> = {
  pagination?: TablePaginationProps;
} & Omit<TableProps<T>, 'pagination'>;

/**
 * Component for Tables with pagination
 *
 * Implemented in TableWithColselector, NestedTable and EditableTable components, and can be used as standalone component
 * For further details see: EC-13478
 */
const TableWithPagination = <T extends Record<string, unknown>>({ pagination, ...restProps }: TableWithPaginationProps<T>) => {
  const { currentPage: currentPageParam, pageSize: pageSizeParam } = usePaginationQueryParams();
  const [showQuickJumper, setShowQuickJumper] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  const paginationProps = pagination && typeof pagination === 'boolean' ? {} : pagination;
  const disableSyncWithUri = paginationProps && paginationProps.syncWithUri === false;

  let current;
  let pageSize;

  if (disableSyncWithUri) {
    current = paginationProps?.current ?? DEFAULT_CURRENT;
    pageSize = paginationProps?.pageSize;
  } else {
    // @ts-ignore
    current = currentPageParam ? Number(currentPageParam) : paginationProps?.current;
    // @ts-ignore
    pageSize = pageSizeParam ? Number(pageSizeParam) : paginationProps?.pageSize;
  }

  const paginationParams: TablePaginationProps = paginationProps
    ? {
        ...paginationProps,
        current,
        pageSize,
        onChange: (page, pageSize) => {
          paginationProps && paginationProps.onChange?.(page, pageSize);
          if (disableSyncWithUri) return;
          buildPaginationQueryParams({ currentPage: page.toString(), pageSize: pageSize.toString() }, location.search, navigate);
        },
        showSizeChanger: DEFAULT_SIZE_CHANGER,
        defaultPageSize: DEFAULT_PAGE_SIZE,
        pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
        showQuickJumper,
        showTotal:
          paginationProps && 'showTotal' in paginationProps
            ? paginationProps.showTotal
            : (total, range) => <Typography.Text type="secondary">{`Ergebnis ${range[0]}-${range[1]} von ${total}`}</Typography.Text>,
      }
    : false;

  const entriesCount = (paginationProps && paginationProps.total) || restProps.dataSource?.length || 0;
  const pageSizeCount = (paginationProps && paginationProps.pageSize) || 1;

  useEffect(() => {
    setShowQuickJumper(entriesCount / pageSizeCount > 7);
  }, [entriesCount, pageSizeCount]);

  return <StyledTableWithPagination<T> {...restProps} pagination={paginationParams} />;
};

export default TableWithPagination;
