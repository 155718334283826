import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Space } from 'antd';
import TicketAttachmentFileUpload from './TicketAttachmentFileUpload';
import { TicketAttachment } from '../../../../types';
import { ticketAttachmentsFormFields, TicketAttachmentsFormValues, TicketUnifiedAttachment } from './ticketAttachmentsFormMapper';
import { UploadedFileView } from '../../../shared/UploadedFileView';
import ErrorMessagePanel from '../../../../components/Error/ErrorMessagePanel';

type Props = {
  formikProps: FormikProps<TicketAttachmentsFormValues>;
  attachmentList: TicketAttachment[];
};

const TicketAttachmentUpload: FC<Props> = ({ formikProps, attachmentList }) => {
  const attachments: TicketUnifiedAttachment[] = [
    ...attachmentList.map(({ fileInfo: { name, size, fileId } }) => ({ name, size, fileId })),
    ...formikProps.values.newAttachmentList.map((attachment) => ({ name: attachment.file.name, size: attachment.file.size, file: attachment.file })),
  ].filter((attachment: TicketUnifiedAttachment) => !attachment.fileId || !formikProps.values.deletableIds.includes(attachment.fileId));

  return (
    <Space
      direction="vertical"
      style={{ width: '100%', ...(attachments.length || formikProps.errors.newAttachmentList ? { marginBottom: 20 } : {}) }}
    >
      <TicketAttachmentFileUpload name={ticketAttachmentsFormFields.newAttachmentList} formikProps={formikProps} attachmentList={attachments} />
      <Space direction="vertical">
        {attachments.map((attachment, index) => {
          const onDelete = () => {
            const deleteableIds = formikProps.values.deletableIds;

            if (attachment.fileId) {
              if (!deleteableIds.includes(attachment.fileId)) {
                formikProps.setFieldValue(ticketAttachmentsFormFields.deletableIds, [...deleteableIds, attachment.fileId]);
              }
            } else if (attachment.file) {
              formikProps.setFieldValue(
                ticketAttachmentsFormFields.newAttachmentList,
                formikProps.values.newAttachmentList.filter((file) => file.file !== attachment.file)
              );
            }
          };
          return <UploadedFileView key={index} filename={attachment.name} filesize={attachment.size} onDelete={onDelete} />;
        })}
        {formikProps.errors.newAttachmentList && <ErrorMessagePanel error={formikProps.errors.newAttachmentList as string} />}
      </Space>
    </Space>
  );
};

export default TicketAttachmentUpload;
