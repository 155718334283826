import React, { FC } from 'react';
import { Col, Row, Typography } from 'antd';
import { showSuccessMsgUpdate } from '../../../components/message';
import { useUpdateAufwandAbgrenzungskontoMutation } from '../gql/FirmendatenBuchhaltungEinstellungMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import KontoSelect from '../../KontoSelect/KontoSelect';
import { FirmendatenBuchhaltungEinstellung, KontoKlasse } from '../../../types';
import FieldHelpIconWithTooltip from '../../../components/FieldHelpIcon/FieldHelpIconWithTooltip';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';

type Props = {
  firmendatenBuchhaltungEinstellung: FirmendatenBuchhaltungEinstellung;
  onSuccess: () => void;
};

const AufwandAbgrenzungskonto: FC<Props> = ({ firmendatenBuchhaltungEinstellung, onSuccess }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'FirmendatenAufwandAbgrenzungskonto'>('FirmendatenAufwandAbgrenzungskonto');

  const [runUpdate] = useUpdateAufwandAbgrenzungskontoMutation({
    onCompleted: () => {
      showSuccessMsgUpdate('Allgemein Firmendaten Einstellungen');
      onSuccess();
    },
  });

  return (
    <Row>
      <Col span={4}>
        <Typography.Text>
          Aufwand Abgrenzungskonto
          <FieldHelpIconWithTooltip fieldHelp={getFieldHelpText('FirmendatenAufwandAbgrenzungskonto.aufwandAbgrenzungskontoId')} />
        </Typography.Text>
      </Col>
      <Col span={14}>
        <KontoSelect
          kontoId={firmendatenBuchhaltungEinstellung.aufwandAbgrenzungskontoId}
          kontoKlasse={[KontoKlasse.BetrieblicherAufwand, KontoKlasse.HandelswarenEinsatz]}
          style={{ width: '320px' }}
          size="small"
          onChange={(value) => {
            runUpdate({
              variables: {
                input: { aufwandAbgrenzungskontoId: value },
              },
            }).catch(APOLLO_DUMMY_ERROR_HANDLER);
          }}
        />
      </Col>
    </Row>
  );
};

export default AufwandAbgrenzungskonto;
