import { FinancialPlanTop } from '../templates/financialPlan/Top/financialPlanTop-types';
import { DocumentHeaderAndFooterType } from '../templates/shared/types';

const financialPlanTopMocks: FinancialPlanTop = {
  type: 'FINANCIAL_PLAN_TOPS',
  footer: {
    type: DocumentHeaderAndFooterType.TEXT,
    value:
      '<p><span style="font-size: 8px;">andromeda Hausverwaltung GmbH<br>office@andromeda.software - FN: ${Hausverwaltung.Firmenbuchnummer} / UID: ${Hausverwaltung.Uid}</span></p>',
  },
  topList: [
    {
      sender: { label: 'Abs:', value: 'andromeda software GmnH, Grillgasse 46; 1110 Wien' },
      rechnungsEmpfaenger: {
        line1: 'Herr',
        line2: 'Max Mustermann',
        line3: '',
        line4: 'Mustergasse 124 Top 8',
        line7: '1060 Wien',
      },
      recipientEmailAddress: 'max.must@max.at',
      erstellungsDatum: 'Wien, 28.06.2017',
      title: 'Vorschau 2022',
      rechnungsAusstellerBezeichnung: 'Objekt WEG 05 Am Kanal 12, 1110 Wien',
      topBezeichnung: 'Top 2',
      letterSalutation: 'für MVO Landesorganisation Wien',
      objektSumText: 'Objektsumme',
      anteilText: 'Ihr Anteil',
      vorschreibungspositionList: [
        {
          bezeichnung: 'Betriebskosten',
          einleitungsText: '{Platzhalter Einleitungstext}',
          aufwandSumExpectedCostBlock: {
            sumAufwand: { label: 'Gesamtaufwand 2021', value: '19.756,00' },
            expectedCostIncrease: { label: 'voraussichtliche Kostensteigerung 2,85%', value: '564,00' },
          },
          expectedCostSum: { label: 'voraussichtliche Kosten 2022', value: '20.320,00' },
          aufwandskontenTable: {
            headerText: {
              bezeichnungText: 'Bezeichnung',
              aufteilungsschluesselText: 'Aufteilungsschlüssel',
              annualAmountNettoText: 'Jahresbetrag netto',
            },
            kontoList: [
              {
                bezeichnung: 'Aufzugwartung',
                aufteilungsschluessel: 'Lift',
                annualAmount: '420,00',
              },
              {
                bezeichnung: 'Schädlingsbekämpfung',
                aufteilungsschluessel: 'Nutzwert',
                annualAmount: '2.000,00',
              },
            ],
            kontoSummary: {
              annualAmount: '2.420,00',
              annualAmountText: 'Summe',
            },
          },
          currentVPosWert: { label: 'Jahrespauschale für Betriebskosten derzeit', value: '20.000,16' },
          newVPosWert: { label: 'Jahrespauschale für Betriebskosten ab Jänner 2022', value: '20.319,96' },
          schlussText: '{Platzhalter Schlusstext}',
        },
        {
          bezeichnung: 'Reparaturfond',
          einleitungsText: '{Platzhalter Einleitungstext}',
          repFondsExpensesTable: {
            headerText: {
              nameTitle: 'Mittelfristig durchzuführende Arbeiten',
              amountTitle: 'Voraussichtliche Kosten(in € exkl. USt)',
            },
            expenseList: [
              {
                name: 'Sanierung Gehweg',
                amount: '32.000,00',
              },
              {
                name: 'Neugestaltung Kinderspielplatz',
                amount: '16.000,00',
              },
            ],
            expensesSummary: {
              name: 'Gesamtkosten',
              amount: '48.000,00',
            },
          },
          aufwandSumExpectedCostBlock: {
            sumAufwand: { label: 'Gesamtaufwand 2021', value: '19.756,00' },
            expectedCostIncrease: { label: 'voraussichtliche Kostensteigerung 2,85%', value: '564,00' },
          },
          expectedCostSum: { label: 'voraussichtliche Kosten 2022', value: '20.320,00' },
          aufwandskontenTable: {
            headerText: {
              bezeichnungText: 'Bezeichnung',
              aufteilungsschluesselText: 'Aufteilungsschlüssel',
              annualAmountNettoText: 'Jahresbetrag netto',
            },
            kontoList: [
              {
                bezeichnung: 'Aufzugwartung',
                aufteilungsschluessel: 'Lift',
                annualAmount: '420,00',
              },
              {
                bezeichnung: 'Schädlingsbekämpfung',
                aufteilungsschluessel: 'Nutzwert',
                annualAmount: '2.000,00',
              },
            ],
            kontoSummary: {
              annualAmount: '2.420,00',
              annualAmountText: 'Summe',
            },
          },
          currentVPosWert: { label: 'Jahrespauschale für Reparaturfond derzeit', value: '1.500,00' },
          newVPosWert: { label: 'Jahrespauschale für Reparaturfond ab Jänner 2022', value: '1.500,00' },
          repFondsExtra: { label: 'Stand Kto. Rep. Rücklage per 31.12.2021', value: '40.000,00' },
          schlussText: '{Platzhalter Schlusstext}',
        },
      ],
      vorschreibungspositionSum: { label: 'Gesamstkosten', value: '68.240,00' },
    },
  ],
};

export default financialPlanTopMocks;
