import React, { FC } from 'react';
import { Empty } from 'antd';
import { SubAbrAbrechnungskreis } from '../../../../../../../types';
import NestedTableWithColSelector from '../../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import abrKreisTableColumns from './abrKreisTableColumns';
import KontoTable from '../Level4/KontoTable';

type Props = {
  abrechnungskreisList: SubAbrAbrechnungskreis[];
  parentRowKeys?: string[];
  visible?: boolean;
  subLevel?: number;
  buchungskreisId?: string;
  abrechnungszeitraumVon?: string;
  abrechnungszeitraumBis?: string;
  objektId?: string;
};

const AbrechnungskreisTable: FC<Props> = ({
  abrechnungskreisList,
  parentRowKeys,
  subLevel,
  buchungskreisId,
  objektId,
  abrechnungszeitraumVon,
  abrechnungszeitraumBis,
  visible = true,
}) => {
  return (
    <NestedTableWithColSelector<SubAbrAbrechnungskreis>
      visible={visible}
      parentRowKeys={parentRowKeys}
      level={3}
      subLevel={subLevel}
      rowKey={getRowKey}
      locale={{
        emptyText: <Empty description={<span>Keine Daten vorhanden</span>} />,
      }}
      dataSource={abrechnungskreisList}
      columns={abrKreisTableColumns}
      expandable={{
        rowExpandable: (record) => !!record.summe,
        expandedRowRender: (record, index, indent, expanded) => (
          <KontoTable
            abrechnungskreisType={record.abrechnungskreisType}
            parentRowKeys={[...(parentRowKeys ?? []), getRowKey(record)]}
            visible={visible && expanded}
            subLevel={subLevel}
            kontoList={record.kontoList}
            buchungskreisId={buchungskreisId}
            abrechnungszeitraumVon={abrechnungszeitraumVon}
            abrechnungszeitraumBis={abrechnungszeitraumBis}
            objektId={objektId}
          />
        ),
      }}
    />
  );
};

const getRowKey = (record: SubAbrAbrechnungskreis) => record.bezeichnung;

export default AbrechnungskreisTable;
