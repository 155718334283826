import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { DocumentRowProps, findLabelValueByLabelId } from './util';
import { DocumentInfo, DocumentRowContextProvider } from './documentRowContext';
import DeletedData from '../../../../components/Helpers/DeletedData';
import AvatarBasedOnFileInfoTypeWithTooltip from '../../../../components/Avatar/BasedOnFileInfoType/AvatarBasedOnFileInfoTypeWithTooltip';
import { ValueTitle } from '../../../../components/Helpers/ValueTitle';
import LinkWithStatus from '../../../../components/Link/LinkWithStatus';
import { pathsToObjektDetailsPage } from '../../../Objekt/objektUriPaths';
import { uriFileOfFirmendaten } from '../../documentApiPaths';
import { useHeAbrechnungQuery } from '../../../Abrechnung/He/gql/HeAbrechnungQueries.types';
import { pathsToHeAbrechnungDetails } from '../../../Abrechnung/He/heAbrechnungUriPaths';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { useFDAuthorized } from '../../../../shared/Auth/useAuthorized';

const RowHeAbrechnungForDeletedHeVertrag: FC<DocumentRowProps> = ({ record, ...restProps }) => {
  const { activeForFirmendatenId } = useFDAuthorized();

  const objektId = findLabelValueByLabelId(record.labelList, 'objektId');
  const heAbrechnungId = findLabelValueByLabelId(record.labelList, 'heAbrechnungId');

  const { loading, data: maybeHeAbrechnung } = useHeAbrechnungQuery({ variables: { objektId, heAbrechnungId } });
  const heAbrechnung = maybeHeAbrechnung?.getHeAbrechnung.data;

  const documentInfo: DocumentInfo | undefined = heAbrechnung
    ? {
        column1: (
          <DeletedData isAvatar>
            <AvatarBasedOnFileInfoTypeWithTooltip fileType={record.type} />
          </DeletedData>
        ),
        column2: (
          <DeletedData>
            <ValueTitle
              small
              title="Abrechnung"
              content={
                <DataWithShortenedText maxTextLength={28} text={heAbrechnung.bezeichnung}>
                  {(shortenedText) => (
                    <Link to={pathsToHeAbrechnungDetails(heAbrechnung.objekt.objektId, heAbrechnungId).heAbrTab}>{shortenedText}</Link>
                  )}
                </DataWithShortenedText>
              }
            />
          </DeletedData>
        ),
        column3: (
          <DeletedData>
            <ValueTitle
              small
              title="Objekt"
              content={
                <DataWithShortenedText maxTextLength={25} text={heAbrechnung.objekt.kurzBezeichnung}>
                  {(shortenedText) => (
                    <LinkWithStatus
                      to={pathsToObjektDetailsPage(heAbrechnung.objekt.objektId).objektDetails}
                      text={shortenedText}
                      status={heAbrechnung.objekt.status}
                    />
                  )}
                </DataWithShortenedText>
              }
            />
          </DeletedData>
        ),
        column4: (
          <DeletedData>
            <TextForEmptyValue textToShow="minus" />
          </DeletedData>
        ),
        column5: (
          <DeletedData>
            <TextForEmptyValue textToShow="minus" />
          </DeletedData>
        ),
        column6: (
          <DeletedData>
            <TextForEmptyValue textToShow="minus" />
          </DeletedData>
        ),
        column7: (
          <DeletedData>
            <TextForEmptyValue textToShow="minus" />
          </DeletedData>
        ),
        column8: (
          <DeletedData>
            <Button>
              <Link to={uriFileOfFirmendaten(activeForFirmendatenId, record.fileId, record.name)} target="_blank">
                Öffnen
              </Link>
            </Button>
          </DeletedData>
        ),
      }
    : undefined;

  return (
    <DocumentRowContextProvider value={{ isLoading: loading, documentInfo }}>
      <tr {...restProps} />
    </DocumentRowContextProvider>
  );
};

export default RowHeAbrechnungForDeletedHeVertrag;
