import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import {
  URI_DATA_CARRIER_PAGE,
  uriToDataCarrierBankstatement,
  uriToDataCarrierDebitCardOrBankWire,
  uriToDataCarrierIbanWithFibuKonto,
} from '../../features/DataCarrier/uriDataCarrierPaths';
import DataCarrierPage from './DataCarrierPage';
import BankstatementListing from '../../features/DataCarrier/Listing/Bankstatement/BankstatementListing';
import DirectDebitOrBankWireListing from '../../features/DataCarrier/Listing/DirectDebitOrBankWire/DirectDebitOrBankWireListing';
import IbanFibuKontoListing from '../../features/DataCarrier/Listing/IbanFibuKonto/IbanFibuKontoListing';

const dataCarrierPageRoute = (
  <Route path={`${URI_DATA_CARRIER_PAGE}`} element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<DataCarrierPage />} />}>
    <Route index element={<BankstatementListing />} />
    <Route path={uriToDataCarrierBankstatement} element={<BankstatementListing />} />
    <Route path={uriToDataCarrierDebitCardOrBankWire} element={<DirectDebitOrBankWireListing />} />
    <Route path={uriToDataCarrierIbanWithFibuKonto} element={<IbanFibuKontoListing />} />
  </Route>
);
export default dataCarrierPageRoute;
