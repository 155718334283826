import { FC } from 'react';
import { FormattedDate } from 'react-intl';
import { CustomFormatConfig } from '@formatjs/intl';

type Props = Intl.DateTimeFormatOptions & CustomFormatConfig & { value: string | number | Date | undefined };

const CustomFormattedDate: FC<Props> = ({ value, ...restProps }) => {
  return <FormattedDate value={value} day="2-digit" month="2-digit" year="numeric" {...restProps} />;
};

export default CustomFormattedDate;
