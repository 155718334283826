import React, { FC } from 'react';
import { Empty, PaginationProps } from 'antd';
import mahnungTableColumns from './mahnungTableColumns';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { MahnungListEntry } from '../../../../types';
import { HandleTableSorting } from '../../../../shared/typeHelpers';

type MahnungTableProps = {
  mahnungList?: MahnungListEntry[];
  loading: boolean;
  pagination: PaginationProps;
  handleTableSorting: HandleTableSorting;
};

const MahnungTable: FC<MahnungTableProps> = ({ mahnungList, loading, pagination, handleTableSorting }) => (
  <TableWithColSelector<MahnungListEntry>
    locale={{
      emptyText: <Empty description={<span>Keine Mahnungen vorhanden</span>} />,
    }}
    size="small"
    loading={loading}
    dataSource={mahnungList}
    columns={mahnungTableColumns}
    pagination={pagination}
    onChange={handleTableSorting}
    rowKey={(record) => record.mahnungId}
    filterIdentifier="main-mahnungen"
  />
);

export default MahnungTable;
