import React from 'react';
import { ContactsOutlined, CopyOutlined, ThunderboltOutlined } from '@ant-design/icons';
import BkOrWeAbrDetailsTabs from '../../features/Abrechnung/BkOrWe/BkOrWeAbrDetailsTabs';
import { GroupedMenueListInterface } from '../../components/ContextSidebar/ContentWithSidebar';
import DocumentTab from '../../features/Document/DocumentTab';
import { AbrechnungStatus, ObjektAbrechnung, TopAbrechnungOverview, TopAbrechnungStatus } from '../../types';
import BkOrWeAbrechnungDeviationList from '../../features/Abrechnung/Deviation/BkAndWeAbrechnung/BkOrWeAbrechnungDeviationList';
import { isStatusInvalid } from '../../helpers/statusHelper';
import { DEVIATIONS_MENU_LIST_ITEM_TITLE } from '../../components/Deviation/deviationHelpers';
import { pathsToBkAbrechnungDetails } from '../../features/Abrechnung/BkOrWe/bkAbrechnungUriPaths';
import { DOCUMENT_FILTERS_SETTINGS } from '../../features/Document/Listing/Filters/listingFiltersHelper';

const menuListBkAbr = (abrechnung: ObjektAbrechnung, refetch: () => Promise<any>, objektId: string, loading: boolean): GroupedMenueListInterface => ({
  topMenuList: [
    {
      title: 'Abrechnungen',
      icon: <ContactsOutlined />,
      uri: pathsToBkAbrechnungDetails(objektId, abrechnung.objektAbrechnungId).objAbr,
      content: (props) => <BkOrWeAbrDetailsTabs abrechnung={abrechnung} objektId={objektId} loading={loading} {...props} />,
    },
    {
      title: 'Dokumente',
      icon: <CopyOutlined />,
      uri: `${pathsToBkAbrechnungDetails(objektId, abrechnung.objektAbrechnungId).abrechnungDetails}/documents`,
      content: (props) => (
        <DocumentTab
          objektId={abrechnung.objekt.objektId}
          objektAbrechnungId={abrechnung.objektAbrechnungId}
          disabledFilters={DOCUMENT_FILTERS_SETTINGS.objAbrechnung.disabled}
          defaultFilters={DOCUMENT_FILTERS_SETTINGS.objAbrechnung.default}
          {...props}
        />
      ),
    },
    {
      state:
        abrechnung.status.value === AbrechnungStatus.Invalid ||
        abrechnung.topAbrechnungOverview?.topAbrechnungList.some((topAbrechnung) => isStatusInvalid(topAbrechnung.status))
          ? 'error'
          : undefined,
      title: DEVIATIONS_MENU_LIST_ITEM_TITLE,
      icon: <ThunderboltOutlined />,
      uri: `${pathsToBkAbrechnungDetails(objektId, abrechnung.objektAbrechnungId).deviations}`,
      content: () => (
        <BkOrWeAbrechnungDeviationList
          objektId={objektId}
          objektAbrechnungId={abrechnung.objektAbrechnungId}
          abrechnungType={abrechnung.type.value}
          onAction={refetch}
          objektAbrechnungStatus={abrechnung.status}
          topAbrechnungStatus={
            //FIXME: check if there is another solution than adding manually status=Valid
            (abrechnung.topAbrechnungOverview && topAbrechnungStatusForDeviation(abrechnung.topAbrechnungOverview)) ?? {
              description: '',
              text: 'valid',
              value: TopAbrechnungStatus.Valid,
            }
          }
        />
      ),
    },
  ],
});

const topAbrechnungStatusForDeviation = (topAbrechnungOverview: TopAbrechnungOverview) => {
  const topAbrechnungWithNotValidStatus = topAbrechnungOverview.topAbrechnungList.find(
    (topAbrechnung) =>
      topAbrechnung.status.value === TopAbrechnungStatus.ReviewError || TopAbrechnungStatus.UnderReview || TopAbrechnungStatus.Invalid
  );

  if (topAbrechnungWithNotValidStatus) {
    return topAbrechnungWithNotValidStatus.status;
  }

  if (topAbrechnungOverview.topAbrechnungList.length > 0) {
    return topAbrechnungOverview.topAbrechnungList[0].status;
  }

  return {
    description: '',
    text: 'valid',
    value: TopAbrechnungStatus.Valid,
  };
};

export default menuListBkAbr;
