import { FormFields } from '../../../helpers/formikHelper';
// FIXME: EC-9440 - resolve naming conflict between Textbaustein and general Textbaustein
import {
  TextbausteinFormValues as TextbausteinDefinitonFormValues,
  TextbausteinModel,
} from '../../../components/Template/PDFTemplates/templateMapper';
import { GeneralTextbausteinCreateInput, GeneralTextbausteinUpdateInput, TextbausteinType } from '../../../types';
import { GeneralTextbausteinFieldsFragment } from '../gql/TextbausteinFragments.types';

export type TextbausteinFormValues = {
  bezeichnung: string;
  type: TextbausteinType | undefined;
  auftragsartIdList: string[];
  textbaustein: string;
};

export const textbausteinFormFields: FormFields<TextbausteinFormValues> = {
  bezeichnung: 'bezeichnung',
  type: 'type',
  auftragsartIdList: 'auftragsartIdList',
  textbaustein: 'textbaustein',
};

export const textbausteinFormInitialValues: TextbausteinFormValues = {
  bezeichnung: '',
  type: undefined,
  auftragsartIdList: [],
  textbaustein: '',
};
export const textbausteinInitialValues: TextbausteinModel = {
  type: { text: '', value: TextbausteinType.ZahlartUeberweisung },
  value: null,
  index: 0,
  platzhalterList: [],
};

export const mapFormValuesToTextbaustein = (
  values: TextbausteinFormValues,
  textbaustein: TextbausteinDefinitonFormValues
): GeneralTextbausteinCreateInput | GeneralTextbausteinUpdateInput => ({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  auftragsartIdList: values.auftragsartIdList!,
  bezeichnung: values.bezeichnung,
  textbaustein: {
    type: textbaustein.type.value,
    value: values.textbaustein,
  },
});

export const mapTextbausteinToFormValues = (values: GeneralTextbausteinFieldsFragment): TextbausteinFormValues => ({
  auftragsartIdList: values.auftragsartList.map((auftragsart) => auftragsart.auftragsartId),
  bezeichnung: values.bezeichnung,
  type: values.textbaustein.type.value,
  textbaustein: values.textbaustein.value ?? '',
});
