import React, { FC } from 'react';
import { MenuProps, Modal } from 'antd';
import { DeleteOutlined, SyncOutlined } from '@ant-design/icons';
import { DataCarrierCamtListEntry } from '../../../../../../types';
import ActionDropdown from '../../../../../../components/Dropdown/ActionDropdown';
import { showSuccessMsgDelete, showSuccessMsgOther } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { useDeleteDataCarrierCamtMutation, useReimportDataCarrierCamtMutation } from '../../../../gql/DataCarrierMutations.types';

type Props = {
  dataCarrierCamt: DataCarrierCamtListEntry;
  onAction: () => void;
};

const DataCarrierCamtTableActions: FC<Props> = ({ dataCarrierCamt, onAction }) => {
  const [runReimport] = useReimportDataCarrierCamtMutation({
    variables: { dataCarrierId: dataCarrierCamt.dataCarrierId },
    onCompleted: () => {
      showSuccessMsgOther('Datenträger werden reimportiert.');
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runDelete] = useDeleteDataCarrierCamtMutation({
    onCompleted: () => {
      showSuccessMsgDelete('Datenträger');
      onAction();
    },
    variables: {
      dataCarrierId: dataCarrierCamt.dataCarrierId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Reimportieren',
      icon: <SyncOutlined />,
      onClick: showConfirmReimport(runReimport, dataCarrierCamt),
    },
    {
      key: '2',
      label: 'Löschen',
      icon: <DeleteOutlined />,
      disabled: !dataCarrierCamt.deletable,
      onClick: showConfirmDelete(runDelete, dataCarrierCamt),
      danger: true,
    },
  ];

  return <ActionDropdown items={items} />;
};

const showConfirmReimport = (runReimport: () => void, dataCarrierCamt: DataCarrierCamtListEntry) => () => {
  Modal.confirm({
    title: `Möchten Sie den Datenträger reimportieren?`,
    content: `${dataCarrierCamt.name}`,
    okText: 'Reimportieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runReimport();
    },
  });
};

const showConfirmDelete = (runDelete: () => void, dataCarrierCamt: DataCarrierCamtListEntry) => () => {
  Modal.confirm({
    title: `Möchten Sie die Datenträger löschen?`,
    content: `${dataCarrierCamt.name}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default DataCarrierCamtTableActions;
