import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { useFilterPaymentClearingKontoListQuery } from '../../gql/PaymentClearingQueries.types';
import TextWithTag from '../../../../components/Text/TextWithTag';

type Props = Omit<SelectProps, 'id' | 'placeholder' | 'size' | 'loading' | 'allowClear' | 'showSearch' | 'filterOption'>;

const PaymentClearingKontoListFormSelect: FC<Props> = ({ name, ...restProps }) => {
  const { data, loading } = useFilterPaymentClearingKontoListQuery();

  const list = data?.getFilterPaymentClearingKontoList.data ?? [];

  return (
    <Select
      size="small"
      placeholder="Konto auswählen"
      loading={loading}
      name={name}
      id={name}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      {...restProps}
    >
      {list.map((konto) => (
        <Select.Option key={konto.kontoId} value={konto.kontoId}>
          <TextWithTag textContent={konto.nummer} tagContent={konto.bezeichnung} maxTextLength={30} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default PaymentClearingKontoListFormSelect;
