import React from 'react';
import { Route } from 'react-router';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../security/permissionChecks';
import SysSettingsAbrechnungskreisPage from './SysSettingsAbrechnungskreisPage';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_SYS_SETTINGS } from '../../../../constants/configurationUriPaths';

const sysSettingsAbrechnungskreisPageRoute = (
  <Route
    path={URI_SYS_SETTINGS.abrechnungskreisPage}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsAbrechnungskreisPage />} />}
  />
);

export default sysSettingsAbrechnungskreisPageRoute;
