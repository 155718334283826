import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { personPartValidationSchema } from '../../../../shared/components/Person/personFormValidationSchema';
import PersonFormPart from '../../../../shared/components/Person/PersonFormPart';
import { useUpdateMitarbeiterMutation } from '../../gql/MitarbeiterMutations.types';
import { showSuccessMsgUpdate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { mapFormValuesToMitarbeiterUpdate, mapMitarbeiterToFormValues, MitarbeiterUpdateFormValues } from './mitarbeiterUpdateFormMapper';
import FormButtons from '../../../../components/Button/FormButtons';
import { Mitarbeiter } from '../../../../types';

type MitarbeiterUpdateFormProps = {
  mitarbeiter: Mitarbeiter;
  kundenSystemId: string;
  firmendatenId: string;
  formValidationSchema?: Record<string, any>;
  onSuccess: () => void;
  onCancel: () => void;
};

const MitarbeiterUpdateForm: FC<MitarbeiterUpdateFormProps> = ({
  mitarbeiter,
  kundenSystemId,
  firmendatenId,
  formValidationSchema = personPartValidationSchema,
  onSuccess,
  onCancel,
}) => {
  const [updateMitarbeiter, { loading: loadingUpdateMitarbeiter }] = useUpdateMitarbeiterMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`Mitarbeiter`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<MitarbeiterUpdateFormValues>
      initialValues={mapMitarbeiterToFormValues(mitarbeiter)}
      validationSchema={formValidationSchema}
      onSubmit={(formValues, formikHelpers) => {
        if (mitarbeiter) {
          updateMitarbeiter({
            variables: {
              firmendatenId,
              mitarbeiterId: mitarbeiter.mitarbeiterId,
              input: mapFormValuesToMitarbeiterUpdate(formValues),
            },
          }).finally(() => formikHelpers.setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <PersonFormPart kundenSystemId={kundenSystemId} />
          <FormButtons onCancel={onCancel} updateMode isOkDisabled={formikProps.isSubmitting} isOkLoading={loadingUpdateMitarbeiter} />
        </Form>
      )}
    </Formik>
  );
};

export default MitarbeiterUpdateForm;
