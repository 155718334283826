import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateVorschreibungspositionTemplateVersionMutationVariables = Types.Exact<{
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  input: Types.VorschreibungspositionVersionCreateInput;
}>;

export type CreateVorschreibungspositionTemplateVersionMutation = {
  createVorschreibungspositionTemplateVersion: {
    data: { vorschreibungspositionVersionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateVorschreibungspositionTemplateVersionMutationVariables = Types.Exact<{
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  vorschreibungspositionVersionId: Types.Scalars['ID']['input'];
  input: Types.VorschreibungspositionVersionUpdateInput;
}>;

export type UpdateVorschreibungspositionTemplateVersionMutation = {
  updateVorschreibungspositionTemplateVersion: {
    data: { vorschreibungspositionVersionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteVorschreibungspositionTemplateVersionMutationVariables = Types.Exact<{
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  vorschreibungspositionVersionId: Types.Scalars['ID']['input'];
}>;

export type DeleteVorschreibungspositionTemplateVersionMutation = {
  deleteVorschreibungspositionTemplateVersion: {
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateVorschreibungspositionTemplateVersionDocument = gql`
  mutation CreateVorschreibungspositionTemplateVersion($vorschreibungspositionId: ID!, $input: VorschreibungspositionVersionCreateInput!) {
    createVorschreibungspositionTemplateVersion(vorschreibungspositionId: $vorschreibungspositionId, input: $input) {
      data {
        vorschreibungspositionVersionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateVorschreibungspositionTemplateVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVorschreibungspositionTemplateVersionMutation,
    CreateVorschreibungspositionTemplateVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVorschreibungspositionTemplateVersionMutation, CreateVorschreibungspositionTemplateVersionMutationVariables>(
    CreateVorschreibungspositionTemplateVersionDocument,
    options
  );
}
export type CreateVorschreibungspositionTemplateVersionMutationHookResult = ReturnType<typeof useCreateVorschreibungspositionTemplateVersionMutation>;
export type CreateVorschreibungspositionTemplateVersionMutationResult = Apollo.MutationResult<CreateVorschreibungspositionTemplateVersionMutation>;
export type CreateVorschreibungspositionTemplateVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateVorschreibungspositionTemplateVersionMutation,
  CreateVorschreibungspositionTemplateVersionMutationVariables
>;
export const UpdateVorschreibungspositionTemplateVersionDocument = gql`
  mutation UpdateVorschreibungspositionTemplateVersion(
    $vorschreibungspositionId: ID!
    $vorschreibungspositionVersionId: ID!
    $input: VorschreibungspositionVersionUpdateInput!
  ) {
    updateVorschreibungspositionTemplateVersion(
      vorschreibungspositionId: $vorschreibungspositionId
      vorschreibungspositionVersionId: $vorschreibungspositionVersionId
      input: $input
    ) {
      data {
        vorschreibungspositionVersionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateVorschreibungspositionTemplateVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVorschreibungspositionTemplateVersionMutation,
    UpdateVorschreibungspositionTemplateVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateVorschreibungspositionTemplateVersionMutation, UpdateVorschreibungspositionTemplateVersionMutationVariables>(
    UpdateVorschreibungspositionTemplateVersionDocument,
    options
  );
}
export type UpdateVorschreibungspositionTemplateVersionMutationHookResult = ReturnType<typeof useUpdateVorschreibungspositionTemplateVersionMutation>;
export type UpdateVorschreibungspositionTemplateVersionMutationResult = Apollo.MutationResult<UpdateVorschreibungspositionTemplateVersionMutation>;
export type UpdateVorschreibungspositionTemplateVersionMutationOptions = Apollo.BaseMutationOptions<
  UpdateVorschreibungspositionTemplateVersionMutation,
  UpdateVorschreibungspositionTemplateVersionMutationVariables
>;
export const DeleteVorschreibungspositionTemplateVersionDocument = gql`
  mutation DeleteVorschreibungspositionTemplateVersion($vorschreibungspositionId: ID!, $vorschreibungspositionVersionId: ID!) {
    deleteVorschreibungspositionTemplateVersion(
      vorschreibungspositionId: $vorschreibungspositionId
      vorschreibungspositionVersionId: $vorschreibungspositionVersionId
    ) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteVorschreibungspositionTemplateVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVorschreibungspositionTemplateVersionMutation,
    DeleteVorschreibungspositionTemplateVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVorschreibungspositionTemplateVersionMutation, DeleteVorschreibungspositionTemplateVersionMutationVariables>(
    DeleteVorschreibungspositionTemplateVersionDocument,
    options
  );
}
export type DeleteVorschreibungspositionTemplateVersionMutationHookResult = ReturnType<typeof useDeleteVorschreibungspositionTemplateVersionMutation>;
export type DeleteVorschreibungspositionTemplateVersionMutationResult = Apollo.MutationResult<DeleteVorschreibungspositionTemplateVersionMutation>;
export type DeleteVorschreibungspositionTemplateVersionMutationOptions = Apollo.BaseMutationOptions<
  DeleteVorschreibungspositionTemplateVersionMutation,
  DeleteVorschreibungspositionTemplateVersionMutationVariables
>;
