import * as Yup from 'yup';
import { entityHasToBeNonNegative, entityIsRequired } from '../../../../components/message/validationMsg';
import { formattedDecimalType } from '../../../../components/Input-antd';
import { mahnungUpdateFormFields } from './mahnungUpdateFormMapper';

/* eslint-disable no-template-curly-in-string */
export const mahnungUpdateFormValidationSchema = Yup.object().shape({
  [mahnungUpdateFormFields.verzugszinsen]: formattedDecimalType()
    .required(entityIsRequired('Verzugszinsen'))
    .min(0, entityHasToBeNonNegative('Verzugszinsen')),
  [mahnungUpdateFormFields.mahngebuehr]: formattedDecimalType()
    .required(entityIsRequired('Mahngebühr'))
    .min(0, entityHasToBeNonNegative('Mahngebühr')),
});

/* eslint-enable no-template-curly-in-string */
