import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../styles/pdfStyles';
import SLMTwoColumns from './SLMTwoColumns';
import { SLMZahlungspflichtiger } from './sepaLastschriftMandat-types';

type Props = {
  data: SLMZahlungspflichtiger;
};

const SLMZahlungspflichtigerBlock: FC<Props> = ({ data }) => {
  return (
    <View style={pdfStyles.column}>
      <Text
        style={[
          pdfStyles.textNormal,
          {
            marginTop: '10mm',
            fontWeight: 'bold',
          },
        ]}
      >
        {data.zahlungspflichtigerBlockTitle}
      </Text>
      <SLMTwoColumns label={data.name.label} value={data.name.value} />
      <SLMTwoColumns label={data.addressLine.label} value={data.addressLine.value} />
      <SLMTwoColumns label={data.zipCode.label} value={data.zipCode.value} />
      <SLMTwoColumns label={data.city.label} value={data.city.value} />
      <SLMTwoColumns label={data.iban.label} value={data.iban.value} />
      <SLMTwoColumns label={data.bic.label} value={data.bic.value} />
    </View>
  );
};

export default SLMZahlungspflichtigerBlock;
