import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import TabPaneContent from '../../../components/Tabs/TabPaneContent';
import BestandseinheitTicketListing from './BestandseinheitTicketListing';

type Props = {
  objektId: string;
  bestandseinheitId: string;
};

const BestandseinheitTicketTab: FC<Props> = ({ objektId, bestandseinheitId }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Aufgaben',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <BestandseinheitTicketListing objektId={objektId} bestandseinheitId={bestandseinheitId} />
        </TabPaneContent>
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default BestandseinheitTicketTab;
