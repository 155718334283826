import React, { FC } from 'react';
import { useField } from 'formik';
import { DatePicker } from 'formik-antd';
import { MonthPickerProps } from 'formik-antd/lib/date-picker';
import { DATE_DISPLAY_MONTH_FORMAT_DEFAULT } from '../DatePicker_formikAntD';
import { dayjsCustom } from '../../../helpers/dayjsCustom';

type Props = {
  /** Default: 'Monat und Jahr auswählen' */
  placeholder?: string;
} & Omit<MonthPickerProps, 'id' | 'format' | 'placeholder'>;

/**
 * MonthPicker component with display format 'M/YYYY'
 * @param restProps  DatePicker.MonthPicker (formik-antd) props
 ** Omitted props: id, format, size
 ** Width is set to 100% by default.
 ** Placeholder is set to 'Monat und Jahr auswählen' by default.
 */
const MonthPicker: FC<Props> = ({ size, ...restProps }) => {
  const [, { value }] = useField(restProps.name);
  const defaultValue = value ? dayjsCustom(value) : undefined;

  return (
    <DatePicker.MonthPicker
      {...restProps}
      id={restProps.name}
      defaultValue={defaultValue}
      format={DATE_DISPLAY_MONTH_FORMAT_DEFAULT}
      placeholder={restProps.placeholder ?? 'Monat und Jahr auswählen'}
      size={size ?? 'middle'}
      style={{ width: restProps.style?.width ?? '100%' }}
    />
  );
};

export default MonthPicker;
