import React from 'react';
import { Input, InputNumber } from 'formik-antd';
import { TableColumnProps } from 'antd';
import { UstKategorieEntry } from '../../../../../types';
import { ustKategorieEntryListFormFields, ustKategorieVersionFormFields } from '../Form/ustKategorieVersionMapper';

export const ustKategorieEntryVersionTableColumnsEdit: TableColumnProps<UstKategorieEntry>[] = [
  {
    title: 'USt-Kennzeichen',
    dataIndex: ['ustKz', 'bezeichnung'],
  },
  {
    title: 'Kennzeichen für Rechnung',
    key: 'rechnungskennzeichen',
    render: (text, record, i) => (
      <Input
        key={i}
        name={`${ustKategorieVersionFormFields.ustKategorieEntryList}.${i}.${ustKategorieEntryListFormFields.rechnungskennzeichen}`}
        size="small"
      />
    ),
  },
  {
    title: 'USt %',
    key: 'steuersatz',
    render: (text, record, i) => (
      <InputNumber
        key={i}
        min={0}
        max={100}
        step={1}
        name={`${ustKategorieVersionFormFields.ustKategorieEntryList}.${i}.${ustKategorieEntryListFormFields.steuersatz}`}
        size="small"
        formatter={(value) => `${value}%`}
        parser={(value) => (value ? value.replace('%', '') : '')}
      />
    ),
  },
  {
    title: 'Addition Konto',
    key: 'additionKonto',
    render: (text, record, i) => (
      <InputNumber
        key={i}
        name={`${ustKategorieVersionFormFields.ustKategorieEntryList}.${i}.${ustKategorieEntryListFormFields.additionKonto}`}
        size="small"
      />
    ),
  },
  {
    title: 'Buchungskennzeichen',
    key: 'buchungsKZ',
    dataIndex: ['buchungsKZ', 'text'],
    render: (text, record, i) => (
      <Input
        key={i}
        name={`${ustKategorieVersionFormFields.ustKategorieEntryList}.${i}.${ustKategorieEntryListFormFields.buchungsKZ}`}
        size="small"
      />
    ),
  },
];
