import * as Yup from 'yup';
import { parseFormattedDecimal } from './index';

// eslint-disable-next-line no-template-curly-in-string
export const formattedDecimalType = (typeErrorMsg = 'this must be a `formatted number` type, but the final value was: ${value}.') =>
  Yup.number()
    .transform((value, originalValue) => {
      if (originalValue === undefined || originalValue === null || originalValue === '') {
        return undefined;
      }
      const originalNumber = parseFormattedDecimal(originalValue);
      // originalValue is not falsy but it cannot be unformatted => it is not a number => let number schema do the work
      if (originalValue && !originalNumber) {
        return value;
      }
      // originalValue has been unformatted successfully => let number schema work with the unformatted value
      return originalNumber;
    })
    .typeError(typeErrorMsg);
