import React, { FC } from 'react';
import { InputNumber, InputNumberProps } from 'formik-antd';
import { useField } from 'formik';

const FRACTION_DIGITS_DEFAULT = 2;

export type FormattedDecimalFormInputProps = {
  name: string;
  fractionDigits?: number;
  isCurrency?: boolean;
  isPercentage?: boolean;
  style?: any;
} & InputNumberProps;

const FormattedDecimalFormInput: FC<FormattedDecimalFormInputProps> = ({
  name,
  disabled,
  fractionDigits = FRACTION_DIGITS_DEFAULT,
  isCurrency,
  isPercentage,
  ...props
}) => {
  const [, , { setValue, setTouched }] = useField(name);

  // TODO Remove any
  const handleChange = (value: any) => {
    setValue(value);
    setTouched(true);
  };

  const addonAfter = () => {
    if (isCurrency) {
      return '€';
    }
    if (isPercentage) {
      return '%';
    }
    return undefined;
  };

  return (
    <InputNumber
      name={name}
      disabled={disabled}
      size="small"
      onChange={handleChange}
      formatter={formatter(fractionDigits)}
      parser={parser(fractionDigits)}
      //INFO: Add prefix so input doesn't loses focus - https://github.com/jannikbuschke/formik-antd/issues/200
      prefix=""
      addonAfter={addonAfter()}
      {...props}
    />
  );
};

const locale = 'de-DE';

const formatter = (fractionDigits: number) => (formattedValue?: string | number) => {
  return formattedValue !== undefined && typeof formattedValue === 'string' && formattedValue.length > 0
    ? new Intl.NumberFormat(locale, { maximumFractionDigits: fractionDigits }).format(Number(formattedValue))
    : '';
};

const parser = (fractionDigits: number) => (displayValue?: string) => {
  // for when the input gets clears
  if (displayValue === undefined || displayValue.length === 0) {
    return '';
  }
  try {
    const formattedValue = displayValue
      .replace(new RegExp('\\' + thousandSeparator(), 'g'), '')
      .replace(new RegExp('\\' + decimalSeparator(), 'g'), '.');
    //  => 1232.21 €

    // removing everything except the digits and dot
    const formattedValueSanitized = formattedValue.replace(/[^0-9.]/g, '');
    //  => 1232.21

    let formattedValueSanitizedWithSign = formattedValueSanitized;
    if (formattedValue[0] === '-') {
      formattedValueSanitizedWithSign = '-' + formattedValueSanitized;
    }

    // appending digits properly
    const digitsAfterDecimalCount = (formattedValueSanitizedWithSign.split('.')[1] || []).length;
    const needsDigitsAppended = digitsAfterDecimalCount > fractionDigits;

    let formattedValueSanitzedWithAppendedDigits = formattedValueSanitizedWithSign;
    if (needsDigitsAppended) {
      // @ts-ignore
      formattedValueSanitzedWithAppendedDigits *= 10 ** (digitsAfterDecimalCount - fractionDigits);
    }

    return Number.isNaN(formattedValueSanitzedWithAppendedDigits) ? 0 : Number(formattedValueSanitzedWithAppendedDigits);
  } catch (error) {
    console.error(error);
    return 0;
  }
};

const thousandSeparator = () => new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');

const decimalSeparator = () => new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');

export default FormattedDecimalFormInput;
