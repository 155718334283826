import { FC } from 'react';
import { useHeVertragChangeHistoryListQuery } from '../gql/HeVertragQueries.types';
import { HistoryType } from '../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../History/HistoryListingTable';

type Props = {
  objektId: string;
  heVertragId: string;
};

const HeVertragChangeHistoryListingTable: FC<Props> = ({ objektId, heVertragId }) => {
  const { data, loading } = useHeVertragChangeHistoryListQuery({ variables: { objektId, heVertragId } });
  const historyList = data?.getHeVertragChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="he-vertrag" />;
};

export default HeVertragChangeHistoryListingTable;
