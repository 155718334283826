import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ZahlungsperiodeListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ZahlungsperiodeListQuery = {
  getZahlungsperiodeList: {
    data: Array<{ text: string; value: Types.Zahlungsperiode }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const ZahlungsperiodeListDocument = gql`
  query ZahlungsperiodeList {
    getZahlungsperiodeList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useZahlungsperiodeListQuery(baseOptions?: Apollo.QueryHookOptions<ZahlungsperiodeListQuery, ZahlungsperiodeListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZahlungsperiodeListQuery, ZahlungsperiodeListQueryVariables>(ZahlungsperiodeListDocument, options);
}
export function useZahlungsperiodeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ZahlungsperiodeListQuery, ZahlungsperiodeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZahlungsperiodeListQuery, ZahlungsperiodeListQueryVariables>(ZahlungsperiodeListDocument, options);
}
export function useZahlungsperiodeListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ZahlungsperiodeListQuery, ZahlungsperiodeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ZahlungsperiodeListQuery, ZahlungsperiodeListQueryVariables>(ZahlungsperiodeListDocument, options);
}
export type ZahlungsperiodeListQueryHookResult = ReturnType<typeof useZahlungsperiodeListQuery>;
export type ZahlungsperiodeListLazyQueryHookResult = ReturnType<typeof useZahlungsperiodeListLazyQuery>;
export type ZahlungsperiodeListSuspenseQueryHookResult = ReturnType<typeof useZahlungsperiodeListSuspenseQuery>;
export type ZahlungsperiodeListQueryResult = Apollo.QueryResult<ZahlungsperiodeListQuery, ZahlungsperiodeListQueryVariables>;
