import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../styles/pdfStyles';
import { TopAbrechnung } from '../../../topAbrechnung-types';

const TAAbrKreisErgebnis: FC<{ data: TopAbrechnung }> = ({ data }) => (
  <View style={[pdfStyles.row, { marginTop: '2mm' }]}>
    <Text style={[pdfStyles.textNormal, pdfStyles.textUppercase, { textAlign: 'left', width: '15%' }]}>
      {data.deckblatt.abrechnungskreisSummary.ergebnis.text}
    </Text>
    <Text style={[pdfStyles.textNormal, { width: '15%' }]} />
    <Text style={[pdfStyles.textNormal, { width: '15%' }]} />
    <Text style={[pdfStyles.textNormal, { width: '15%' }]} />
    <Text style={[pdfStyles.textNormal, { width: '15%' }]} />
    <Text
      style={[
        pdfStyles.textNormal,
        pdfStyles.boxShadow,
        {
          textAlign: 'right',
          fontWeight: 'bold',
          width: '10%',
        },
      ]}
    >
      {data.deckblatt.abrechnungskreisSummary.ergebnis.betrag}
    </Text>
  </View>
);

export default TAAbrKreisErgebnis;
