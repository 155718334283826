import React, { FC } from 'react';
import { FormikProps } from 'formik';
import FirmennameFormPart from '../../../../shared/components/Firmenname/form/FirmennameFormPart';
import LegalFormSelect from '../../../../shared/components/LegalFormSelect/LegalFormSelect';
import CompanyRegistrationNumberFormPart from '../../../../shared/components/CompanyRegistrationNumber/form/CompanyRegistrationNumberFormPart';
import { getFirmendatenKurzBezeichnungVorschlag, getKurzBezeichnungVorschlagFieldsToBeDebounced } from './firmendatenBaseFormPartHelper';
// @ts-ignore
import VatIdNumberFormPart from '../../../../shared/components/VatIdentificationNumber/form/VatIdNumberFormPart';
// @ts-ignore
import KurzBezeichnungFormPart from '../../../../shared/components/KurzBezeichnung/form/KurzBezeichnungFormPart';
import { FirmendatenFormValues } from './fimendatenFormMapper';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';

type Props = {
  formikProps: FormikProps<FirmendatenFormValues>;
  fieldHelpSelectorEntityName: 'KundenSystem' | 'Firmendaten'; //Important to narrow down the type
};

const FirmendatenBaseFormPart: FC<Props> = ({ formikProps, fieldHelpSelectorEntityName }) => {
  /* FIELD HELP */
  const getFieldHelpTexKundensystem = useGetAppFieldHelpText<'KundenSystem'>('KundenSystem');
  const getFieldHelpTextFirmendaten = useGetAppFieldHelpText<'Firmendaten'>('Firmendaten');
  const isFieldHelpSelectorEntityNameKundenSystem = fieldHelpSelectorEntityName === 'KundenSystem';
  /* END OF FIELD HELP */

  return (
    <>
      <FirmennameFormPart
        fieldHelpNames={{
          name1: isFieldHelpSelectorEntityNameKundenSystem
            ? getFieldHelpTexKundensystem('KundenSystem.erstelltVonFirma.name1')
            : getFieldHelpTextFirmendaten('Firmendaten.name1'),
          name2: isFieldHelpSelectorEntityNameKundenSystem
            ? getFieldHelpTexKundensystem('KundenSystem.erstelltVonFirma.name2')
            : getFieldHelpTextFirmendaten('Firmendaten.name2'),
          name3: isFieldHelpSelectorEntityNameKundenSystem
            ? getFieldHelpTexKundensystem('KundenSystem.erstelltVonFirma.name3')
            : getFieldHelpTextFirmendaten('Firmendaten.name3'),
        }}
      />
      <KurzBezeichnungFormPart<FirmendatenFormValues>
        placeholder="Kurzbezeichnung"
        kurzBezeichnungVorschlagConfig={{
          getFieldsToBeDebounced: getKurzBezeichnungVorschlagFieldsToBeDebounced,
          getKurzBezeichnungVorschlagFn: getFirmendatenKurzBezeichnungVorschlag,
        }}
        formikProps={formikProps}
        fieldHelp={
          isFieldHelpSelectorEntityNameKundenSystem
            ? getFieldHelpTexKundensystem('KundenSystem.erstelltVonFirma.kurzBezeichnung')
            : getFieldHelpTextFirmendaten('Firmendaten.kurzBezeichnung')
        }
      />
      <LegalFormSelect
        fieldHelp={
          isFieldHelpSelectorEntityNameKundenSystem
            ? getFieldHelpTexKundensystem('KundenSystem.erstelltVonFirma.legalForm')
            : getFieldHelpTextFirmendaten('Firmendaten.legalForm')
        }
      />
      <VatIdNumberFormPart
        formikProps={formikProps}
        fieldHelp={
          isFieldHelpSelectorEntityNameKundenSystem
            ? getFieldHelpTexKundensystem('KundenSystem.erstelltVonFirma.vatIdentificationNumber')
            : getFieldHelpTextFirmendaten('Firmendaten.vatIdentificationNumber')
        }
        onValidationSuccess={() => {}} // This form will be refactored in the future, therefore there's no need to do anything upon validation success at the moment
      />
      <CompanyRegistrationNumberFormPart
        fieldHelp={
          isFieldHelpSelectorEntityNameKundenSystem
            ? getFieldHelpTexKundensystem('KundenSystem.erstelltVonFirma.companyRegistrationNumber')
            : getFieldHelpTextFirmendaten('Firmendaten.companyRegistrationNumber')
        }
      />
    </>
  );
};

export default FirmendatenBaseFormPart;
