import React, { FC } from 'react';
import { Drawer, MenuProps, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, HistoryOutlined, PhoneOutlined } from '@ant-design/icons';
import { useToggle } from '../../../../../../hooks/useToggle';
import { showSuccessMsgDelete, showSuccessMsgUpdate } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { useDeleteContactMutation, useSetOperatingSiteForContactMutation } from '../../../gql/AddressesAndContactsMutations.types';
import PhoneContactUpdateForm from '../../../Form/PhoneContact/Update/PhoneContactUpdateForm';
import AddressOrContactListItem from '../AddressOrContactListItem';
import { TOperatingSiteList } from '../../../../../../shared/components/OperatingSite/List/OperatingSiteList';
import HistoryModal, { HistoryType } from '../../../../../History/shared/HistoryModal';
import ContactChangeHistoryListingTable from '../shared/ContactChangeHistoryListingTable';
import { radixActionStyles } from '../../../../../../helpers/radixIconsStyles';
import { Contact } from '../../../../../../types';
import { useCreateOutgoingCallLogMutation } from '../../../../../CallLog/gql/CallLogMutations.types';

type Props = {
  phoneContact: Contact;
  firmendatenId: string;
  rechtstraegerId?: string;
  onSuccess: () => void;
  isHauptInUse: boolean;
  isDraggable: boolean;
  operatingSiteList?: TOperatingSiteList[];
};

const PhoneContactListItem: FC<Props> = ({
  phoneContact,
  firmendatenId,
  rechtstraegerId,
  onSuccess,
  isDraggable,
  isHauptInUse,
  operatingSiteList,
}) => {
  const [isCollapsed, onCollapse] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const [runOutgoingCallLog] = useCreateOutgoingCallLogMutation();

  const [runDelete] = useDeleteContactMutation({
    variables: {
      firmendatenId,
      contactId: phoneContact.contactId,
    },
    onCompleted: () => {
      showSuccessMsgDelete('Kontakt');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runSetContactOperatingSite] = useSetOperatingSiteForContactMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`Adresse`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onUpdateSuccess = () => {
    onSuccess();
    onCollapse();
  };

  const operatingSiteListItem: MenuProps['items'] =
    operatingSiteList?.map((operatingSite) => ({
      key: operatingSite.operatingSiteId,
      label: `Verschieben zu ${operatingSite.bezeichnung}`,
      onClick: () =>
        runSetContactOperatingSite({
          variables: { firmendatenId, contactId: phoneContact.contactId, input: { operatingSiteId: operatingSite.operatingSiteId } },
        }),
    })) ?? [];

  const items: MenuProps['items'] = [
    { key: '1', label: 'Bearbeiten', onClick: onCollapse, icon: <EditOutlined /> },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    { key: '3', label: 'Löschen', onClick: () => showConfirmDelete(runDelete), icon: <DeleteOutlined />, danger: true },
    ...operatingSiteListItem,
  ];

  return (
    <>
      <AddressOrContactListItem
        firmendatenId={firmendatenId}
        item={phoneContact}
        icon={<PhoneOutlined />}
        content={
          <a
            href={`tel:${phoneContact.wert}`}
            onMouseUp={() => {
              rechtstraegerId &&
                runOutgoingCallLog({
                  variables: { rechtstraegerId, contactId: phoneContact.contactId },
                });
            }}
          >
            {phoneContact.wert}
          </a>
        }
        menuItems={items}
        onSuccess={onSuccess}
        isHauptInUse={isHauptInUse}
        isDraggable={isDraggable}
      />
      <Drawer title="Kontakt bearbeiten" width={720} open={isCollapsed} onClose={onCollapse} destroyOnClose>
        <PhoneContactUpdateForm item={phoneContact} firmendatenId={firmendatenId} onSuccess={onUpdateSuccess} onCancel={onCollapse} />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <ContactChangeHistoryListingTable contactId={phoneContact.contactId} />
      </HistoryModal>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void) => {
  Modal.confirm({
    title: 'Sind Sie sicher das Sie diesen Kontakt löschen möchten?',
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default PhoneContactListItem;
