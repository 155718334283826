import React from 'react';
import EmptyTableRow from '../../../../../components/Table/TableWithAlignedCols/EmptyTableRow';
import { TableWithAlignedColsExpandedRowType } from '../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { createCells, TextWithStyling } from '../../shared/abrechnungTableHelper';
import UstVomAufwandDetails from './UstVomAufwandDetails';
import VorschreibungDetails from './VorschreibungDetails';
import { StyledTableRepFondsEinnahmenRows, StyledTableRepFondsUstVomAufwandRows } from './styled/RepFondsRows.style';
import { TopAbrAbrechnungkreis } from '../../../../../types';

const ustVomAufwandHeaderTexts: TextWithStyling[] = [
  { render: 'Basis Aufwand' },
  { render: 'Anteil Netto', style: { textAlign: 'right' } },
  { render: 'Ust-%', style: { textAlign: 'right' } },
  { render: 'Ust.vom Aufwand', style: { textAlign: 'right' } },
  { render: 'Summe', style: { textAlign: 'right' } },
  { render: 'Grundlage', style: { textAlign: 'right' } },
  { render: 'U.v.Aufwand.Belastung', style: { textAlign: 'right' } },
];

const einnahmenHeaderTexts: TextWithStyling[] = [
  { render: 'Vorschreibung' },
  { render: 'KontoInfo' },
  { render: 'Beleg-Datum' },
  { render: 'Beleg-Nr' },
  { render: 'Vorschreibung (Netto)', style: { textAlign: 'right' } },
  { render: 'OP zum Abrechnungsdatum', style: { textAlign: 'right' } },
  { render: 'Soll/Haben' },
];

const topAbrechnungWithRepFondsDetailsTableExpandedRow = (objektId: string): TableWithAlignedColsExpandedRowType<TopAbrAbrechnungkreis>[] => [
  {
    header: () => createCells(ustVomAufwandHeaderTexts, true),
    body: (abrKreis) => (
      <>
        {abrKreis.ustVomAufwandAnteilList.length > 0 ? (
          abrKreis.ustVomAufwandAnteilList.map((zeitScheibe, index) => {
            return <UstVomAufwandDetails key={index} zeitScheibe={zeitScheibe} />;
          })
        ) : (
          <EmptyTableRow colSpan={3} entity="Aufwände" />
        )}
      </>
    ),
    table: {
      colSpanSize: [5, 1],
      positionOfButton: 1,
      StyledComponent: StyledTableRepFondsUstVomAufwandRows,
      showCellButton: true,
    },
  },
  {
    header: () => createCells(einnahmenHeaderTexts, true),
    body: (abrKreis) => (
      <>
        {abrKreis.einnahmenBuchungszeileList.length > 0 ? (
          abrKreis.einnahmenBuchungszeileList.map((buchungsZeile, index) => {
            return <VorschreibungDetails key={index} buchungszeile={buchungsZeile} objektId={objektId} />;
          })
        ) : (
          <EmptyTableRow colSpan={2} entity="Vorschreibungen" />
        )}
      </>
    ),
    table: {
      colSpanSize: [7],
      positionOfButton: 1,
      StyledComponent: StyledTableRepFondsEinnahmenRows,
      showCellButton: true,
    },
  },
];

export default topAbrechnungWithRepFondsDetailsTableExpandedRow;
