import { Firmendaten, UpdateFirmendateneinstellungenInput } from '../../../../types';

export interface FirmendatenEinstellungFormValues {
  dueDateVorschreibung: string;
}

export const mapFirmeneinstellungToValues = (firmendaten: Firmendaten): FirmendatenEinstellungFormValues => ({
  dueDateVorschreibung: firmendaten.firmeneinstellung.dueDateVorschreibung.value,
});

export const mapValuesToFirmeneinstellung = (values: FirmendatenEinstellungFormValues): UpdateFirmendateneinstellungenInput => ({
  dueDateVorschreibung: values.dueDateVorschreibung,
});
