import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spin, Steps } from 'antd';
import EmailTemplateStep from './Steps/EmailTemplateStep/EmailTemplateStep';
import RecipientListStep from './Steps/RecipientListStep/RecipientListStep';
import DetermineRecipientListStep from './Steps/DetermineRecipientListStep/DetermineRecipientListStep';
import { WizardPageProps, wizardTypes } from '../wizardHelpers';
import { generatePathToWizardUpdatePage } from '../wizardApiPaths';

const InfoMailDeliveryWizard: FC<WizardPageProps> = ({ wizardId, activeStep, onCancel }) => {
  let content = <Spin />;

  const navigate = useNavigate();

  if (activeStep === 1) {
    content = (
      <EmailTemplateStep
        onCancel={onCancel}
        onComplete={() => {
          navigate(generatePathToWizardUpdatePage(wizardTypes.InfoMailDelivery, wizardId, 2));
        }}
        wizardId={wizardId}
      />
    );
  }

  if (activeStep === 2) {
    content = (
      <DetermineRecipientListStep
        onCancel={onCancel}
        onComplete={() => {
          navigate(generatePathToWizardUpdatePage(wizardTypes.InfoMailDelivery, wizardId, 3));
        }}
        onBack={() => {
          navigate(generatePathToWizardUpdatePage(wizardTypes.InfoMailDelivery, wizardId, 1));
        }}
        wizardId={wizardId}
      />
    );
  }

  if (activeStep === 3) {
    content = (
      <RecipientListStep
        onCancel={onCancel}
        onComplete={onCancel}
        onBack={() => {
          navigate(generatePathToWizardUpdatePage(wizardTypes.InfoMailDelivery, wizardId, 2));
        }}
        wizardId={wizardId}
      />
    );
  }

  return (
    <>
      <Steps type="navigation" size="small" current={activeStep - 1} className="site-navigation-steps">
        <Steps.Step title="Text und Anhänge" />
        <Steps.Step title="Empfänger ermitteln" />
        <Steps.Step title="Versand" />
      </Steps>
      {content}
    </>
  );
};

export default InfoMailDeliveryWizard;
