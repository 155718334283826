import { FormikProps } from 'formik';
import { IMAGE_SUPPORTED_FORMATS } from '../../../../../../helpers/fileHelper';
import { ReportLogosFormValues } from './reportLogosFormMapper';
import { ValidateFileProps } from '../../../../../../components/FileUpload/FileUploadArea';

export const validateFile = (
  file: ValidateFileProps,
  formikProps: FormikProps<ReportLogosFormValues>,
  name: string,
  isLogoSmall: boolean
): boolean => {
  const allowedFileContentTypes = [...IMAGE_SUPPORTED_FORMATS];
  if (!allowedFileContentTypes.includes(file.type)) {
    formikProps.setFieldError(
      name,
      `Das Format der Beleg Datei ${file.type} wird nicht unterstützt, unterstützte Formate sind: ${allowedFileContentTypes}`
    );
    return false;
  }
  const fileSizeInMB = file.size / 1024 / 1024;

  if (isTooLarge(fileSizeInMB, isLogoSmall)) {
    formikProps.setFieldError(
      name,
      `${file.name} ist zu groß. Datei muss kleiner als ${isLogoSmall ? LOGO_KLEIN_IMAGE_MAX_SIZE * 1000 : LOGO_GROSS_IMAGE_MAX_SIZE * 1000}kb sein.`
    );
    return false;
  }
  return true;
};

const isTooLarge = (fileSizeInMB: number, isLogoSmall: boolean) => {
  if (isLogoSmall) {
    return fileSizeInMB > LOGO_KLEIN_IMAGE_MAX_SIZE;
  }
  return fileSizeInMB > LOGO_GROSS_IMAGE_MAX_SIZE;
};

const LOGO_GROSS_IMAGE_MAX_SIZE = 0.512;
const LOGO_KLEIN_IMAGE_MAX_SIZE = 0.128;
