import { Formik } from 'formik';
import React, { FC } from 'react';
import AbrKontenzuordnungFilters from './AbrKontenzuordnungFilters';
import { AbrKontenzuordnungFiltersFormValues } from './abrKontenzuordnungFiltersMapper';

type Props = {
  onSubmit: (val: AbrKontenzuordnungFiltersFormValues) => void;
};

const AbrKontenzuordnungFormFilters: FC<Props> = ({ onSubmit }) => (
  <Formik<AbrKontenzuordnungFiltersFormValues> initialValues={{}} onSubmit={onSubmit}>
    {(formikProps) => <AbrKontenzuordnungFilters formikProps={formikProps} />}
  </Formik>
);

export default AbrKontenzuordnungFormFilters;
