import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { useToggle } from '../../hooks/useToggle';
import ContentWithSidebar, { GroupedMenueListInterface } from '../ContextSidebar/ContentWithSidebar';
import { ContentMain } from '../LayoutAndromeda/Styled/Layout.style';

type DetailsPageContentMainProps = {
  card: React.ReactNode;
  sidebarMenuList: GroupedMenueListInterface;
  contentDefaultSelected?: string;
  contentContextList?: string[];
};

const DetailsPageContentMain: FC<DetailsPageContentMainProps> = ({ card, sidebarMenuList, contentDefaultSelected, contentContextList }) => {
  const [isCardCollapsed, onCardCollapse] = useToggle(false);
  return (
    <ContentMain>
      <Row gutter={16}>
        {isCardCollapsed ? null : <Col span={6}>{card}</Col>}
        <Col span={isCardCollapsed ? 24 : 18}>
          <ContentWithSidebar
            menuList={sidebarMenuList}
            defaultSelected={contentDefaultSelected}
            onCardCollapse={onCardCollapse}
            isCardCollapsed={isCardCollapsed}
            contextList={contentContextList}
          />
        </Col>
      </Row>
    </ContentMain>
  );
};

export default DetailsPageContentMain;
