import { FC } from 'react';
import { List, Space } from 'antd';
import { isRechtstraegerInfoFeldPersoneninfos } from './rechtstraegerInfoFeldListHelpers';
import PersoneninfosListItem from './ListItem/Personeninfos/PersoneninfosListItem';
import { RechtstraegerInfoFeldFragment } from '../gql/rechtstraegerInfoFeldFragments.types';

type Props = {
  loading: boolean;
  infoFeldList: RechtstraegerInfoFeldFragment[];
  rechtstraegerId: string;
  onSuccess: () => void;
};

const RechtstraegerInfoFeldList: FC<Props> = ({ infoFeldList, loading, rechtstraegerId, onSuccess }) => {
  const personeninfosList = infoFeldList.filter(isRechtstraegerInfoFeldPersoneninfos);

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={16}>
      {personeninfosList && personeninfosList.length > 0 && (
        <List<RechtstraegerInfoFeldFragment>
          bordered
          loading={loading}
          dataSource={personeninfosList}
          renderItem={(item) => <PersoneninfosListItem personeninfos={item} rechtstraegerId={rechtstraegerId} onSuccess={onSuccess} />}
        />
      )}
    </Space>
  );
};

export default RechtstraegerInfoFeldList;
