import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { OrderPaymentFieldsFragmentDoc } from '../../../Payment/gql/Order/PaymentFragment.types';
import { GenerierlaufAsyncStepFieldsFragmentDoc } from '../shared/generierlaufFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PaymentProposalCreationGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type PaymentProposalCreationGenerierlaufQuery = {
  getPaymentProposalCreationGenerierlauf: {
    data: {
      countFehler: number;
      countNichtVerarbeitet: number;
      countVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierlaufId: string;
      generierDatum: string;
      errorList: Array<{ message: string; type: string }>;
      status: { text: string; value: Types.GenerierlaufStatus };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type PaymentProposalCreationGenerierlaufEntryListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  objektIdList?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>>;
  paymentTypeList?: Types.InputMaybe<Array<Types.PaymentType> | Types.PaymentType>;
  paymentTransactionType?: Types.InputMaybe<Types.PaymentTransactionType>;
  rechnungsAusstellerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  order?: Types.InputMaybe<Types.PaymentProposalGenerierlaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type PaymentProposalCreationGenerierlaufEntryListQuery = {
  getPaymentProposalCreationGenerierlaufEntryList: {
    data: {
      contentList: Array<{
        currentStep?: number | null;
        generierlaufEntryId: string;
        infoMessage?: string | null;
        retry: boolean;
        stepCount?: number | null;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { value: Types.GenerierlaufEntryExitCode; text: string } | null;
        payment?: {
          auftragId?: string | null;
          belegFileId?: string | null;
          belegId?: string | null;
          belegdatum?: string | null;
          belegnummer?: string | null;
          amount: number;
          buchungsdatum?: string | null;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deckungspruefungErrorText?: string | null;
          heVertragId?: string | null;
          noted: boolean;
          paymentDate: string;
          paymentId: string;
          paymentProposal?: string | null;
          paymentProposalId?: string | null;
          payeeAccountHolder: string;
          payeeIban: string;
          paymentReferenceText?: string | null;
          rechnungsAusstellerAccountHolder: string;
          rechnungsAusstellerIban: string;
          sepaCreditorId?: string | null;
          sepaMandatReference?: string | null;
          transactionInstructionId?: string | null;
          updatedByMitarbeiterId?: string | null;
          verwendungszweck?: string | null;
          approvalList: Array<{ mitarbeiterId: string; text: string }>;
          objekt: { kurzBezeichnung: string; objektId: string };
          paymentApprovalStatus?: { text: string; value: Types.PaymentApprovalStatus } | null;
          payee: { kurzBezeichnung: string; kundennummer: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
          paymentTransactionType?: { text: string; value: Types.PaymentTransactionType } | null;
          rechnungsAussteller: {
            kurzBezeichnung: string;
            kundennummer: string;
            rechtstraegerId: string;
            type: { text: string; value: Types.RechtstraegerType };
          };
          rechnungsAusstellerFibuKonto: { bezeichnung: string; kontoId: string; nummer: string };
          status: { text: string; value: Types.OrderPaymentStatus };
          type: { text: string; value: Types.PaymentType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        stepList: Array<{
          designation: string;
          receivingApplication?: string | null;
          stepId: string;
          type?: string | null;
          status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
        }>;
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { size: number; number: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type PaymentProposalExecutionGenerierlaufEntryListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  objektIdList?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>>;
  paymentTypeList?: Types.InputMaybe<Array<Types.PaymentType> | Types.PaymentType>;
  paymentTransactionType?: Types.InputMaybe<Types.PaymentTransactionType>;
  rechnungsAusstellerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  order?: Types.InputMaybe<Types.PaymentProposalGenerierlaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type PaymentProposalExecutionGenerierlaufEntryListQuery = {
  getPaymentProposalExecutionGenerierlaufEntryList: {
    data: {
      contentList: Array<{
        currentStep?: number | null;
        generierlaufEntryId: string;
        infoMessage?: string | null;
        retry: boolean;
        stepCount?: number | null;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { value: Types.GenerierlaufEntryExitCode; text: string } | null;
        payment?: {
          auftragId?: string | null;
          belegFileId?: string | null;
          belegId?: string | null;
          belegdatum?: string | null;
          belegnummer?: string | null;
          amount: number;
          buchungsdatum?: string | null;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deckungspruefungErrorText?: string | null;
          heVertragId?: string | null;
          noted: boolean;
          paymentDate: string;
          paymentId: string;
          paymentProposal?: string | null;
          paymentProposalId?: string | null;
          payeeAccountHolder: string;
          payeeIban: string;
          paymentReferenceText?: string | null;
          rechnungsAusstellerAccountHolder: string;
          rechnungsAusstellerIban: string;
          sepaCreditorId?: string | null;
          sepaMandatReference?: string | null;
          transactionInstructionId?: string | null;
          updatedByMitarbeiterId?: string | null;
          verwendungszweck?: string | null;
          approvalList: Array<{ mitarbeiterId: string; text: string }>;
          objekt: { kurzBezeichnung: string; objektId: string };
          paymentApprovalStatus?: { text: string; value: Types.PaymentApprovalStatus } | null;
          payee: { kurzBezeichnung: string; kundennummer: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
          paymentTransactionType?: { text: string; value: Types.PaymentTransactionType } | null;
          rechnungsAussteller: {
            kurzBezeichnung: string;
            kundennummer: string;
            rechtstraegerId: string;
            type: { text: string; value: Types.RechtstraegerType };
          };
          rechnungsAusstellerFibuKonto: { bezeichnung: string; kontoId: string; nummer: string };
          status: { text: string; value: Types.OrderPaymentStatus };
          type: { text: string; value: Types.PaymentType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        stepList: Array<{
          designation: string;
          receivingApplication?: string | null;
          stepId: string;
          type?: string | null;
          status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
        }>;
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { size: number; number: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type PaymentProposalExecutionGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type PaymentProposalExecutionGenerierlaufQuery = {
  getPaymentProposalExecutionGenerierlauf: {
    data: {
      countFehler: number;
      countNichtVerarbeitet: number;
      countVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierlaufId: string;
      generierDatum: string;
      errorList: Array<{ message: string; type: string }>;
      status: { text: string; value: Types.GenerierlaufStatus };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const PaymentProposalCreationGenerierlaufDocument = gql`
  query PaymentProposalCreationGenerierlauf($generierlaufId: ID!) {
    getPaymentProposalCreationGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countNichtVerarbeitet
        countVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        generierlaufId
        generierDatum
        status {
          text
          value
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function usePaymentProposalCreationGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<PaymentProposalCreationGenerierlaufQuery, PaymentProposalCreationGenerierlaufQueryVariables> &
    ({ variables: PaymentProposalCreationGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentProposalCreationGenerierlaufQuery, PaymentProposalCreationGenerierlaufQueryVariables>(
    PaymentProposalCreationGenerierlaufDocument,
    options
  );
}
export function usePaymentProposalCreationGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentProposalCreationGenerierlaufQuery, PaymentProposalCreationGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentProposalCreationGenerierlaufQuery, PaymentProposalCreationGenerierlaufQueryVariables>(
    PaymentProposalCreationGenerierlaufDocument,
    options
  );
}
export function usePaymentProposalCreationGenerierlaufSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<PaymentProposalCreationGenerierlaufQuery, PaymentProposalCreationGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaymentProposalCreationGenerierlaufQuery, PaymentProposalCreationGenerierlaufQueryVariables>(
    PaymentProposalCreationGenerierlaufDocument,
    options
  );
}
export type PaymentProposalCreationGenerierlaufQueryHookResult = ReturnType<typeof usePaymentProposalCreationGenerierlaufQuery>;
export type PaymentProposalCreationGenerierlaufLazyQueryHookResult = ReturnType<typeof usePaymentProposalCreationGenerierlaufLazyQuery>;
export type PaymentProposalCreationGenerierlaufSuspenseQueryHookResult = ReturnType<typeof usePaymentProposalCreationGenerierlaufSuspenseQuery>;
export type PaymentProposalCreationGenerierlaufQueryResult = Apollo.QueryResult<
  PaymentProposalCreationGenerierlaufQuery,
  PaymentProposalCreationGenerierlaufQueryVariables
>;
export const PaymentProposalCreationGenerierlaufEntryListDocument = gql`
  query PaymentProposalCreationGenerierlaufEntryList(
    $generierlaufId: ID!
    $objektIdList: [String]
    $paymentTypeList: [PaymentType!]
    $paymentTransactionType: PaymentTransactionType
    $rechnungsAusstellerId: String
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $order: PaymentProposalGenerierlaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getPaymentProposalCreationGenerierlaufEntryList(
      generierlaufId: $generierlaufId
      objektIdList: $objektIdList
      paymentTypeList: $paymentTypeList
      paymentTransactionType: $paymentTransactionType
      rechnungsAusstellerId: $rechnungsAusstellerId
      exitCodeList: $exitCodeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          currentStep
          errorList {
            message
            type
          }
          exitCode {
            value
            text
          }
          generierlaufEntryId
          infoMessage
          payment {
            ...OrderPaymentFields
          }
          retry
          stepCount
          stepList {
            ...GenerierlaufAsyncStepFields
          }
          warningList {
            message
            type
          }
        }
        page {
          size
          number
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${OrderPaymentFieldsFragmentDoc}
  ${GenerierlaufAsyncStepFieldsFragmentDoc}
`;
export function usePaymentProposalCreationGenerierlaufEntryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaymentProposalCreationGenerierlaufEntryListQuery,
    PaymentProposalCreationGenerierlaufEntryListQueryVariables
  > &
    ({ variables: PaymentProposalCreationGenerierlaufEntryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentProposalCreationGenerierlaufEntryListQuery, PaymentProposalCreationGenerierlaufEntryListQueryVariables>(
    PaymentProposalCreationGenerierlaufEntryListDocument,
    options
  );
}
export function usePaymentProposalCreationGenerierlaufEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaymentProposalCreationGenerierlaufEntryListQuery,
    PaymentProposalCreationGenerierlaufEntryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentProposalCreationGenerierlaufEntryListQuery, PaymentProposalCreationGenerierlaufEntryListQueryVariables>(
    PaymentProposalCreationGenerierlaufEntryListDocument,
    options
  );
}
export function usePaymentProposalCreationGenerierlaufEntryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<PaymentProposalCreationGenerierlaufEntryListQuery, PaymentProposalCreationGenerierlaufEntryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaymentProposalCreationGenerierlaufEntryListQuery, PaymentProposalCreationGenerierlaufEntryListQueryVariables>(
    PaymentProposalCreationGenerierlaufEntryListDocument,
    options
  );
}
export type PaymentProposalCreationGenerierlaufEntryListQueryHookResult = ReturnType<typeof usePaymentProposalCreationGenerierlaufEntryListQuery>;
export type PaymentProposalCreationGenerierlaufEntryListLazyQueryHookResult = ReturnType<
  typeof usePaymentProposalCreationGenerierlaufEntryListLazyQuery
>;
export type PaymentProposalCreationGenerierlaufEntryListSuspenseQueryHookResult = ReturnType<
  typeof usePaymentProposalCreationGenerierlaufEntryListSuspenseQuery
>;
export type PaymentProposalCreationGenerierlaufEntryListQueryResult = Apollo.QueryResult<
  PaymentProposalCreationGenerierlaufEntryListQuery,
  PaymentProposalCreationGenerierlaufEntryListQueryVariables
>;
export const PaymentProposalExecutionGenerierlaufEntryListDocument = gql`
  query PaymentProposalExecutionGenerierlaufEntryList(
    $generierlaufId: ID!
    $objektIdList: [String]
    $paymentTypeList: [PaymentType!]
    $paymentTransactionType: PaymentTransactionType
    $rechnungsAusstellerId: String
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $order: PaymentProposalGenerierlaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getPaymentProposalExecutionGenerierlaufEntryList(
      generierlaufId: $generierlaufId
      objektIdList: $objektIdList
      paymentTypeList: $paymentTypeList
      paymentTransactionType: $paymentTransactionType
      rechnungsAusstellerId: $rechnungsAusstellerId
      exitCodeList: $exitCodeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          currentStep
          errorList {
            message
            type
          }
          exitCode {
            value
            text
          }
          generierlaufEntryId
          infoMessage
          payment {
            ...OrderPaymentFields
          }
          retry
          stepCount
          stepList {
            ...GenerierlaufAsyncStepFields
          }
          warningList {
            message
            type
          }
        }
        page {
          size
          number
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${OrderPaymentFieldsFragmentDoc}
  ${GenerierlaufAsyncStepFieldsFragmentDoc}
`;
export function usePaymentProposalExecutionGenerierlaufEntryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaymentProposalExecutionGenerierlaufEntryListQuery,
    PaymentProposalExecutionGenerierlaufEntryListQueryVariables
  > &
    ({ variables: PaymentProposalExecutionGenerierlaufEntryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentProposalExecutionGenerierlaufEntryListQuery, PaymentProposalExecutionGenerierlaufEntryListQueryVariables>(
    PaymentProposalExecutionGenerierlaufEntryListDocument,
    options
  );
}
export function usePaymentProposalExecutionGenerierlaufEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaymentProposalExecutionGenerierlaufEntryListQuery,
    PaymentProposalExecutionGenerierlaufEntryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentProposalExecutionGenerierlaufEntryListQuery, PaymentProposalExecutionGenerierlaufEntryListQueryVariables>(
    PaymentProposalExecutionGenerierlaufEntryListDocument,
    options
  );
}
export function usePaymentProposalExecutionGenerierlaufEntryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<PaymentProposalExecutionGenerierlaufEntryListQuery, PaymentProposalExecutionGenerierlaufEntryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaymentProposalExecutionGenerierlaufEntryListQuery, PaymentProposalExecutionGenerierlaufEntryListQueryVariables>(
    PaymentProposalExecutionGenerierlaufEntryListDocument,
    options
  );
}
export type PaymentProposalExecutionGenerierlaufEntryListQueryHookResult = ReturnType<typeof usePaymentProposalExecutionGenerierlaufEntryListQuery>;
export type PaymentProposalExecutionGenerierlaufEntryListLazyQueryHookResult = ReturnType<
  typeof usePaymentProposalExecutionGenerierlaufEntryListLazyQuery
>;
export type PaymentProposalExecutionGenerierlaufEntryListSuspenseQueryHookResult = ReturnType<
  typeof usePaymentProposalExecutionGenerierlaufEntryListSuspenseQuery
>;
export type PaymentProposalExecutionGenerierlaufEntryListQueryResult = Apollo.QueryResult<
  PaymentProposalExecutionGenerierlaufEntryListQuery,
  PaymentProposalExecutionGenerierlaufEntryListQueryVariables
>;
export const PaymentProposalExecutionGenerierlaufDocument = gql`
  query PaymentProposalExecutionGenerierlauf($generierlaufId: ID!) {
    getPaymentProposalExecutionGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countNichtVerarbeitet
        countVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        generierlaufId
        generierDatum
        status {
          text
          value
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function usePaymentProposalExecutionGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<PaymentProposalExecutionGenerierlaufQuery, PaymentProposalExecutionGenerierlaufQueryVariables> &
    ({ variables: PaymentProposalExecutionGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentProposalExecutionGenerierlaufQuery, PaymentProposalExecutionGenerierlaufQueryVariables>(
    PaymentProposalExecutionGenerierlaufDocument,
    options
  );
}
export function usePaymentProposalExecutionGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentProposalExecutionGenerierlaufQuery, PaymentProposalExecutionGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentProposalExecutionGenerierlaufQuery, PaymentProposalExecutionGenerierlaufQueryVariables>(
    PaymentProposalExecutionGenerierlaufDocument,
    options
  );
}
export function usePaymentProposalExecutionGenerierlaufSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<PaymentProposalExecutionGenerierlaufQuery, PaymentProposalExecutionGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaymentProposalExecutionGenerierlaufQuery, PaymentProposalExecutionGenerierlaufQueryVariables>(
    PaymentProposalExecutionGenerierlaufDocument,
    options
  );
}
export type PaymentProposalExecutionGenerierlaufQueryHookResult = ReturnType<typeof usePaymentProposalExecutionGenerierlaufQuery>;
export type PaymentProposalExecutionGenerierlaufLazyQueryHookResult = ReturnType<typeof usePaymentProposalExecutionGenerierlaufLazyQuery>;
export type PaymentProposalExecutionGenerierlaufSuspenseQueryHookResult = ReturnType<typeof usePaymentProposalExecutionGenerierlaufSuspenseQuery>;
export type PaymentProposalExecutionGenerierlaufQueryResult = Apollo.QueryResult<
  PaymentProposalExecutionGenerierlaufQuery,
  PaymentProposalExecutionGenerierlaufQueryVariables
>;
