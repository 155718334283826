import React, { FC } from 'react';
import { Col, Row, Typography } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Spacer } from '../../../../../components/Grid';
import { AuftragsartType } from '../../../../../types';

import FormButtons from '../../../../../components/Button/FormButtons';
import {
  auftragsartCreateParamFormFields,
  auftragsartCreateParamFormInitialValues,
  AuftragsartCreateParamFormValues,
} from '../../../../Auftragsart/Form/Create/ParameterForm/auftragsartCreateParamFormMapper';
import { auftragsartCreateParamFormValidationSchema } from '../../../../Auftragsart/Form/shared/ParamForm/Schema/auftragsartCreateParamFormValidationSchema';
import ParamFormItemBezeichnung from '../../../../Auftragsart/Form/shared/ParamForm/ParamFormItems/ParamFormItemBezeichnung';
import ParamFormItemUstBlockSwitch from '../../../../Auftragsart/Form/shared/ParamForm/ParamFormItems/ParamFormItemUstBlockSwitch';
import { isNotStornoAuftragsart } from '../../../../Auftragsart/Form/shared/auftragsartFormHelpers';
import ParamFormItemStornoAuftragsartSelect from '../../shared/ParamFormItemStornoAuftragsartSelect';

type Props = {
  auftragsartBezeichnung: string;
  auftragsartType: AuftragsartType;
  paramFormValues?: AuftragsartCreateParamFormValues;
  setParamFormValues: React.Dispatch<React.SetStateAction<AuftragsartCreateParamFormValues | undefined>>;
  onSubmit: () => void;
  onCancel: () => void;
};

const AuftragsartTemplateCreateParamForm: FC<Props> = ({
  auftragsartBezeichnung,
  auftragsartType,
  paramFormValues,
  setParamFormValues,
  onSubmit,
  onCancel,
}) => {
  const ParamForm = () => (
    <Formik<AuftragsartCreateParamFormValues>
      initialValues={auftragsartCreateParamFormInitialValues(paramFormValues)}
      onSubmit={(formValues) => {
        setParamFormValues(formValues);
        onSubmit();
      }}
      validationSchema={auftragsartCreateParamFormValidationSchema}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row gutter={8} style={{ display: 'flex', justifyContent: 'center' }}>
            <Col span={8}>
              <ParamFormItemBezeichnung name={auftragsartCreateParamFormFields.bezeichnung} placeholder="z.B. Standard Vorschreibung Dauerrechnung" />
              <ParamFormItemUstBlockSwitch
                name={auftragsartCreateParamFormFields.showUstBlock}
                defaultChecked={formikProps.values.showUstBlock}
                onChange={(checked) => formikProps.setFieldValue(auftragsartCreateParamFormFields.showUstBlock, checked)}
                shouldShowUstBlock={formikProps.values.showUstBlock}
              />
              {isNotStornoAuftragsart(auftragsartType) && (
                <ParamFormItemStornoAuftragsartSelect name={auftragsartCreateParamFormFields.stornoAuftragsartId} auftragsartType={auftragsartType} />
              )}
            </Col>
          </Row>

          <FormButtons onCancel={onCancel} cancelText="Zurück" updateMode={false} okText="Weiter" isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );

  return (
    <>
      <Spacer height={16} />

      <Typography.Title level={5} style={{ textAlign: 'center' }}>
        {auftragsartBezeichnung}
      </Typography.Title>
      <Spacer height={16} />

      <ParamForm />
    </>
  );
};

export default AuftragsartTemplateCreateParamForm;
