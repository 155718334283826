import { FormFields } from '../../../../../helpers/formikHelper';

export interface PaymentProposalDeleteFormValues {
  paymentProposalId: string;
  paymentProposalName: string;
}

export const paymentProposalInitialValues: PaymentProposalDeleteFormValues = {
  paymentProposalId: '',
  paymentProposalName: '',
};

export const paymentProposalFormFields: FormFields<PaymentProposalDeleteFormValues> = {
  paymentProposalId: 'paymentProposalId',
  paymentProposalName: 'paymentProposalName',
};
