import React, { FC } from 'react';
import { Button, Divider, Drawer, Dropdown, Modal, Space, Tag, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, EllipsisOutlined, HistoryOutlined, LockOutlined, UnlockOutlined, UserOutlined } from '@ant-design/icons';
import { MutationFunctionOptions } from '@apollo/client';
import { ArchiveIcon } from '@radix-ui/react-icons';
import { isStatusArchived, isStatusGesperrt } from '../../../../helpers/statusHelper';
import VatIdentificationNumber from '../../../../shared/components/VatIdentificationNumber/VatIdentificationNumber';
import PersonenGemeinschaftForm from '../Form/PersonenGemeinschaftForm';
import { useToggle } from '../../../../hooks/useToggle';
import CardWithFooter from '../../../../components/Card';
import RechtstraegerBankDetails from '../../../BankDetails/RechtstraegerBankDetails/RechtstraegerBankDetails';
import {
  showSuccessMsgArchived,
  showSuccessMsgDelete,
  showSuccessMsgEntsperrt,
  showSuccessMsgGesperrt,
  showSuccessMsgUnarchived,
} from '../../../../components/message';
import { useGoBack } from '../../../../shared/GoBack/GoBackContext';
import {
  ArchivePersonenGemeinschaftMutation,
  ArchivePersonenGemeinschaftMutationVariables,
  DeletePersonenGemeinschaftMutation,
  DeletePersonenGemeinschaftMutationVariables,
  EntsperrenPersonenGemeinschaftMutation,
  EntsperrenPersonenGemeinschaftMutationVariables,
  SperrenPersonenGemeinschaftMutation,
  SperrenPersonenGemeinschaftMutationVariables,
  UnarchivePersonenGemeinschaftMutation,
  UnarchivePersonenGemeinschaftMutationVariables,
  useArchivePersonenGemeinschaftMutation,
  useDeletePersonenGemeinschaftMutation,
  useEntsperrenPersonenGemeinschaftMutation,
  useSperrenPersonenGemeinschaftMutation,
  useUnarchivePersonenGemeinschaftMutation,
} from '../gql/PersonenGemeinschaftMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { URI_RECHTSTRAEGER_PAGE } from '../../rechtstraegerUriPaths';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import FinanzSteuerparameterInfo from '../../Steuer/FinanzSteuerparameterInfo';
import CardTitle from '../../../../components/Card/CardTitle';
import { radixActionStyles } from '../../../../helpers/radixIconsStyles';
import RechtstraegerPostIt from '../../PostIt/RechtstraegerPostIt';
import HistoryModal, { HistoryType } from '../../../History/shared/HistoryModal';
import PersonenGemeinschaftChangeHistoryListingTable from './PersonenGemeinschaftChangeHistoryListingTable';
import { Personengemeinschaft } from '../../../../types';

type PersonenGemeinschaftCardProps = {
  personenGemeinschaft: Personengemeinschaft;
  refetch: () => void;
  isLoading: boolean;
  kundenSystemId: string;
  rechtstraegerId: string;
};

export const PersonenGemeinschaftCard: FC<PersonenGemeinschaftCardProps> = ({
  personenGemeinschaft,
  refetch,
  isLoading,
  kundenSystemId,
  rechtstraegerId,
}) => {
  const [isCollapsed, onCollapse] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();
  const goBack = useGoBack(URI_RECHTSTRAEGER_PAGE);
  const entity = 'Personengemeinschaft';

  const [runDelete] = useDeletePersonenGemeinschaftMutation({
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      goBack();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runSperren] = useSperrenPersonenGemeinschaftMutation({
    onCompleted: () => {
      showSuccessMsgGesperrt(entity);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runEntsperren] = useEntsperrenPersonenGemeinschaftMutation({
    onCompleted: () => {
      showSuccessMsgEntsperrt(entity);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runArchive] = useArchivePersonenGemeinschaftMutation({
    onCompleted: () => {
      showSuccessMsgArchived(entity);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUnarchive] = useUnarchivePersonenGemeinschaftMutation({
    onCompleted: () => {
      showSuccessMsgUnarchived(entity);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <CardWithFooter
      loading={isLoading}
      style={{ minWidth: '320px' }}
      title={
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <CardTitle title={personenGemeinschaft.kurzBezeichnung} status={personenGemeinschaft.status} />
          <RechtstraegerPostIt rechtstraegerId={rechtstraegerId} postIt={personenGemeinschaft.postIt} refresh={refetch} />
        </Space>
      }
      extra={
        <Dropdown
          menu={{
            items: menuItems(
              personenGemeinschaft,
              onCollapse,
              toggleChangeHistoryOpen,
              runSperren,
              runEntsperren,
              runDelete,
              runArchive,
              runUnarchive
            ),
          }}
        >
          <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
        </Dropdown>
      }
      createTs={personenGemeinschaft.createTs}
      userId={personenGemeinschaft.createdBy}
    >
      <CardDetailsInfoRow
        infoRowTitle={
          <Space>
            <UserOutlined />
            {personenGemeinschaft.salutation?.text ?? 'keine Anrede'}
          </Space>
        }
      >
        <Typography.Text>{personenGemeinschaft.kurzBezeichnung}</Typography.Text>
      </CardDetailsInfoRow>

      <Divider dashed />

      <Typography.Title level={5}>Merkmale</Typography.Title>
      {personenGemeinschaft.tagList.map((tag) => (
        <Tag key={tag}>{tag}</Tag>
      ))}
      <Divider dashed />
      <FinanzSteuerparameterInfo
        rechtstraegerSteuerpflicht={personenGemeinschaft.rechtstraegerSteuerpflicht}
        rechtstraegerId={rechtstraegerId}
        reloadRechtstraeger={refetch}
      />
      <Divider dashed />
      <Typography.Title level={5}>Information</Typography.Title>
      <CardDetailsInfoRow infoRowTitle="Kundennummer">
        <Typography.Text>{personenGemeinschaft.kundennummer}</Typography.Text>
      </CardDetailsInfoRow>

      <VatIdentificationNumber vatIdentificationNumber={personenGemeinschaft.vatIdentificationNumber} />

      <CardDetailsInfoRow infoRowTitle="Sprache">
        <Typography.Text>{personenGemeinschaft.sprache.text}</Typography.Text>
      </CardDetailsInfoRow>
      <Divider dashed />
      <RechtstraegerBankDetails rechtstraegerId={rechtstraegerId} fibuKontoCreatable={personenGemeinschaft.fibuKontoCreatable} />
      <Drawer title="Personengemeinschaft bearbeiten" width={720} onClose={onCollapse} open={isCollapsed} destroyOnClose>
        <PersonenGemeinschaftForm
          personenGemeinschaft={personenGemeinschaft}
          kundenSystemId={kundenSystemId}
          onSuccess={() => {
            refetch();
            onCollapse();
          }}
          onCancel={onCollapse}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <PersonenGemeinschaftChangeHistoryListingTable personenGemeinschaftId={personenGemeinschaft.rechtstraegerId} />
      </HistoryModal>
    </CardWithFooter>
  );
};

const menuItems = (
  personenGemeinschaft: Personengemeinschaft,
  onCollapse: () => void,
  toggleChangeHistoryOpen: () => void,
  runSperren: (options?: MutationFunctionOptions<SperrenPersonenGemeinschaftMutation, SperrenPersonenGemeinschaftMutationVariables>) => Promise<any>,
  runEntsperren: (
    options?: MutationFunctionOptions<EntsperrenPersonenGemeinschaftMutation, EntsperrenPersonenGemeinschaftMutationVariables>
  ) => Promise<any>,
  runDelete: (options?: MutationFunctionOptions<DeletePersonenGemeinschaftMutation, DeletePersonenGemeinschaftMutationVariables>) => Promise<any>,
  runArchive: (options?: MutationFunctionOptions<ArchivePersonenGemeinschaftMutation, ArchivePersonenGemeinschaftMutationVariables>) => Promise<any>,
  runUnarchive: (
    options?: MutationFunctionOptions<UnarchivePersonenGemeinschaftMutation, UnarchivePersonenGemeinschaftMutationVariables>
  ) => Promise<any>
) => [
  {
    key: '1',
    label: 'Bearbeiten',
    onClick: onCollapse,
    disabled: isStatusGesperrt(personenGemeinschaft.status),
    icon: <EditOutlined />,
  },
  {
    key: '2',
    label: 'Änderungsverlauf',
    onClick: toggleChangeHistoryOpen,
    icon: <HistoryOutlined style={radixActionStyles} />,
  },
  {
    key: '3',
    label: isStatusArchived(personenGemeinschaft.status) ? 'Reaktivieren' : 'Archivieren',
    onClick: () => showConfirmArchive(personenGemeinschaft, runArchive, runUnarchive),
    disabled: !!personenGemeinschaft.deletable,
    icon: <ArchiveIcon style={radixActionStyles} />,
  },
  {
    key: '4',
    label: isStatusGesperrt(personenGemeinschaft.status) ? 'Entsperren' : 'Sperren',
    onClick: () => showConfirmLock(personenGemeinschaft, runSperren, runEntsperren),
    disabled: !!personenGemeinschaft.deletable,
    icon: isStatusGesperrt(personenGemeinschaft.status) ? <UnlockOutlined /> : <LockOutlined />,
  },
  {
    key: '5',
    label: 'Löschen',
    onClick: () => showConfirmDelete(personenGemeinschaft, runDelete),
    disabled: !personenGemeinschaft.deletable,
    icon: <DeleteOutlined />,
  },
];

const showConfirmArchive = (
  personenGemeinschaft: Personengemeinschaft,
  runArchive: (options?: MutationFunctionOptions<ArchivePersonenGemeinschaftMutation, ArchivePersonenGemeinschaftMutationVariables>) => Promise<any>,
  runUnarchive: (
    options?: MutationFunctionOptions<UnarchivePersonenGemeinschaftMutation, UnarchivePersonenGemeinschaftMutationVariables>
  ) => Promise<any>
) => {
  Modal.confirm({
    title: isStatusArchived(personenGemeinschaft.status)
      ? 'Möchten Sie die Personengemeinschaft reaktivieren?'
      : 'Möchten Sie die Personengemeinschaft archivieren?',
    content: `${personenGemeinschaft.kurzBezeichnung}`,
    okText: isStatusArchived(personenGemeinschaft.status) ? 'Reaktivieren' : 'Archivieren',
    cancelText: 'Nein',
    onOk() {
      if (isStatusArchived(personenGemeinschaft.status)) {
        return runUnarchive({ variables: { personenGemeinschaftId: personenGemeinschaft.rechtstraegerId } });
      } else {
        return runArchive({ variables: { personenGemeinschaftId: personenGemeinschaft.rechtstraegerId } });
      }
    },
  });
};

const showConfirmLock = (
  personenGemeinschaft: Personengemeinschaft,
  runSperren: (options?: MutationFunctionOptions<SperrenPersonenGemeinschaftMutation, SperrenPersonenGemeinschaftMutationVariables>) => Promise<any>,
  runEntsperren: (
    options?: MutationFunctionOptions<EntsperrenPersonenGemeinschaftMutation, EntsperrenPersonenGemeinschaftMutationVariables>
  ) => Promise<any>
) => {
  Modal.confirm({
    title: isStatusGesperrt(personenGemeinschaft.status)
      ? 'Möchten Sie die Personengemeinschaft entsperren?'
      : 'Möchten Sie die Personengemeinschaft sperren?',
    content: `${personenGemeinschaft.kurzBezeichnung}`,
    okText: isStatusGesperrt(personenGemeinschaft.status) ? 'Entsperren' : 'Sperren',
    cancelText: 'Nein',
    onOk() {
      if (isStatusGesperrt(personenGemeinschaft.status)) {
        return runEntsperren({ variables: { personenGemeinschaftId: personenGemeinschaft.rechtstraegerId } });
      } else {
        return runSperren({ variables: { personenGemeinschaftId: personenGemeinschaft.rechtstraegerId } });
      }
    },
  });
};

const showConfirmDelete = (
  personenGemeinschaft: Personengemeinschaft,
  runDelete: (options?: MutationFunctionOptions<DeletePersonenGemeinschaftMutation, DeletePersonenGemeinschaftMutationVariables>) => Promise<any>
) => {
  Modal.confirm({
    title: 'Möchten Sie die Personengemeinschaft löschen?',
    content: `${personenGemeinschaft.kurzBezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete({ variables: { personenGemeinschaftId: personenGemeinschaft.rechtstraegerId } });
    },
  });
};

export default PersonenGemeinschaftCard;
