import React, { FC } from 'react';
import { Col, Row, Typography } from 'antd';
import { DatePicker } from 'formik-antd';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';

type Props = { name: string };

const IndexSeriesEntryYearPicker: FC<Props> = ({ name }) => {
  return (
    <Row style={{ width: '100%', alignItems: 'baseline' }}>
      <Col span={6}>
        <Typography.Text>Jahr</Typography.Text>
      </Col>
      <Col span={6}>
        <FormItemWithoutColon name={name}>
          <DatePicker picker="year" name={name} placeholder="Jahr wählen" size="small" style={{ width: '100%' }} allowClear={false} />
        </FormItemWithoutColon>
      </Col>
    </Row>
  );
};

export default IndexSeriesEntryYearPicker;
