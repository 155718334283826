import React, { FC } from 'react';
import Html from 'react-pdf-html';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../styles/pdfStyles';
import { DocumentHeaderAndFooter, DocumentHeaderAndFooterType } from './types';
import HeaderAndFooterImage from './HeaderAndFooterImage';

type Props = { footer: DocumentHeaderAndFooter };

const Footer: FC<Props> = ({ footer }) => (
  <View fixed style={pdfStyles.footer}>
    <View style={[pdfStyles.row, pdfStyles.borderBottomSmall, { borderBottomWidth: 0.4, marginTop: '2mm', marginBottom: '2mm' }]} />
    <Text render={({ pageNumber, totalPages }) => `Seite ${pageNumber} von ${totalPages}`} fixed style={{ textAlign: 'right', fontSize: 6 }} />
    <FooterTextOrImage footer={footer} />
  </View>
);

const FooterTextOrImage: FC<Props> = ({ footer }) => {
  let content = null;
  if (footer.value) {
    content =
      footer.type === DocumentHeaderAndFooterType.TEXT ? (
        <Text>
          <Html>{footer.value}</Html>
        </Text>
      ) : (
        <HeaderAndFooterImage
          value={footer.value.value}
          horizontalAlignment={footer.value.alignment}
          verticalAlignment="BOTTOM"
          style={{ maxHeight: 42 }}
        />
      );
  }
  return <View style={[pdfStyles.row, { height: '12mm' }]}>{content}</View>;
};

export default Footer;
