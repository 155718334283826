import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { useIncomingInvoiceAusstellerListQuery } from '../../gql/IncomingInvoiceFilterQueries.types';

type Props = {
  maxTextLength?: number;
} & Omit<SelectProps, 'id' | 'placeholder' | 'size' | 'allowClear' | 'showSearch' | 'filterOption'>;

const InvoiceAusstellerListFormSelect: FC<Props> = ({ maxTextLength, ...restProps }) => {
  const { data, loading } = useIncomingInvoiceAusstellerListQuery();
  const ausstellerList = data?.getIncomingInvoiceAusstellerList.data ?? [];

  return (
    <Select
      {...restProps}
      id={restProps.name}
      size="small"
      loading={loading}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      placeholder="Lieferant auswählen"
    >
      {ausstellerList.map((aussteller) => (
        <Select.Option key={aussteller.rechtstraegerId} value={aussteller.rechtstraegerId}>
          <DataWithShortenedText maxTextLength={maxTextLength ?? aussteller.kurzBezeichnung.length} text={aussteller.kurzBezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default InvoiceAusstellerListFormSelect;
