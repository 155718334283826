import styled from 'styled-components';
import { Space } from 'antd';

export const StyledEmailDeliverySpace = styled(Space)`
  & > .ant-space-item {
    :first-child {
      width: 140px;
      flex-shrink: 0;
    }
  }
`;
