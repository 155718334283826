import { Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { FC } from 'react';
import { useToggle } from '../../../../hooks/useToggle';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../../components/Button/ButtonsAligned';
import UstRegelsetTemplateForm from '../../UstRegelset/Form/UstRegelsetTemplateForm';

type Props = {
  refetch: () => void;
};

const UstRegelsetTemplateTableButtonsAndDrawer: FC<Props> = ({ refetch }) => {
  const [isCollapsed, onCollapse] = useToggle();

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <Button type="primary" icon={<PlusOutlined />} onClick={onCollapse}>
          USt-Regelsatz anlegen
        </Button>
      </ButtonsAligned>
      <Drawer title="USt.-Regelsatz anlegen" width={720} onClose={onCollapse} open={isCollapsed} destroyOnClose>
        <UstRegelsetTemplateForm
          onSuccess={() => {
            refetch();
            onCollapse();
          }}
          onCancel={onCollapse}
        />
      </Drawer>
    </>
  );
};

export default UstRegelsetTemplateTableButtonsAndDrawer;
