import React, { FC, PropsWithChildren, RefObject } from 'react';
import { NestedTableWithColSelectorProvider } from './NestedTableWithColSelectorContext';
import NestedTableWithColSelectorColumnSelector from './NestedTableWithColSelectorColumnSelector';
import { Spacer } from '../../Grid';
import { StyledStickyHeaderDivForNestedTable } from '../../StickyHeader/styled/StickyHeader.styled';

type Props = {
  filterIdentifier: string;
  columnSelectorRef: RefObject<HTMLDivElement>;
};

const NestedTableWithColSelectorWrapperStickyHeader: FC<Props & PropsWithChildren> = ({ filterIdentifier, columnSelectorRef, children }) => {
  return (
    <div>
      <NestedTableWithColSelectorProvider>
        <StyledStickyHeaderDivForNestedTable ref={columnSelectorRef} direction="vertical" size={0}>
          <NestedTableWithColSelectorColumnSelector filterIdentifier={filterIdentifier} />
          <Spacer height={16} />
        </StyledStickyHeaderDivForNestedTable>
        {children}
      </NestedTableWithColSelectorProvider>
    </div>
  );
};

export default NestedTableWithColSelectorWrapperStickyHeader;
