import React, { FC } from 'react';
import { FormattedNumber } from 'react-intl';
import { Divider, Typography } from 'antd';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import { isWegObjekt } from '../../objektHelper';
import { Objekt } from '../../../../types';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  objekt: Objekt;
};

const InnerCardObjektBasicInformation: FC<Props> = ({ objekt }) => (
  <>
    <Typography.Title level={5}>Allgemeine Information</Typography.Title>
    <CardDetailsInfoRow infoRowTitle="Nutzfläche">
      <FormattedNumber value={objekt.sumNutzflaeche} minimumFractionDigits={2} maximumFractionDigits={2}>
        {(text: string) => <>{`${text} m²`}</>}
      </FormattedNumber>
    </CardDetailsInfoRow>

    {isWegObjekt(objekt.verwaltungsart.value) && (
      <CardDetailsInfoRow infoRowTitle="Nutzwert">
        <FormattedNumber value={objekt.sumNutzwert} minimumFractionDigits={0} />
      </CardDetailsInfoRow>
    )}

    <CardDetailsInfoRow infoRowTitle="Verwaltet seit">
      <CustomFormattedDate value={objekt.verwaltungSeit} />
    </CardDetailsInfoRow>

    <CardDetailsInfoRow infoRowTitle="Verwaltungsart">
      {objekt.verwaltungsart.text ? <Typography.Text>{objekt.verwaltungsart.text}</Typography.Text> : <TextForEmptyValue textToShow="minus" />}
    </CardDetailsInfoRow>

    <CardDetailsInfoRow infoRowTitle="Verwaltet bis">
      {objekt.verwaltungBis && <CustomFormattedDate value={objekt.verwaltungBis} />}
    </CardDetailsInfoRow>

    <Divider style={{ marginTop: '8px', marginBottom: '8px' }} dashed />
  </>
);

export default InnerCardObjektBasicInformation;
