import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRechtstraegerPostItMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  input: Types.PostItInput;
}>;

export type CreateRechtstraegerPostItMutation = {
  createRechtstraegerPostIt: {
    data: { postItId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateRechtstraegerPostItDocument = gql`
  mutation CreateRechtstraegerPostIt($rechtstraegerId: ID!, $input: PostItInput!) {
    createRechtstraegerPostIt(rechtstraegerId: $rechtstraegerId, input: $input) {
      data {
        postItId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateRechtstraegerPostItMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRechtstraegerPostItMutation, CreateRechtstraegerPostItMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRechtstraegerPostItMutation, CreateRechtstraegerPostItMutationVariables>(
    CreateRechtstraegerPostItDocument,
    options
  );
}
export type CreateRechtstraegerPostItMutationHookResult = ReturnType<typeof useCreateRechtstraegerPostItMutation>;
export type CreateRechtstraegerPostItMutationResult = Apollo.MutationResult<CreateRechtstraegerPostItMutation>;
export type CreateRechtstraegerPostItMutationOptions = Apollo.BaseMutationOptions<
  CreateRechtstraegerPostItMutation,
  CreateRechtstraegerPostItMutationVariables
>;
