import styled from 'styled-components';
import { Radio } from 'antd';

export const StyledRadio = styled(Radio)`
  .ant-radio + span {
    width: 100%;
    display: block;
  }

  &.ant-form-item-has-error {
    .ant-radio-inner {
      border-color: #ffa39e;
    }
  }
`;
