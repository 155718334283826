import React, { FC } from 'react';
import { Empty } from 'antd';
import emailDeliveryDefinitionTableColumns from './emailDeliveryDefinitionTableColumns';
import { EmailDeliveryDefinitionBase } from '../../../types';
import NestedTable from '../../../components/Table/NestedTable/NestedTable';

type Props = {
  emailDeliveryDefList: EmailDeliveryDefinitionBase[];
  loading: boolean;
  onAction: () => void;
};

const EmailDeliveryDefinitionTable: FC<Props> = ({ emailDeliveryDefList, loading, onAction }) => {
  return (
    <NestedTable<EmailDeliveryDefinitionBase>
      loading={loading}
      dataSource={emailDeliveryDefList}
      columns={emailDeliveryDefinitionTableColumns(onAction)}
      locale={{
        emptyText: <Empty description="Keine Versanddefinition vorhanden" />,
      }}
      rowKey={(record) => record.emailDeliveryDefinitionId}
      expandable={{
        rowExpandable: () => false,
      }}
      useHighlighting
    />
  );
};

export default EmailDeliveryDefinitionTable;
