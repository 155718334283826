import React, { FC } from 'react';
import { Button, Space } from 'antd';
import StreetAddressFormPart from '../StreetAddressFormPart';

type Props = {
  fieldNamePrefix: string;
  isVisible: boolean;
  saveAddress: () => void;
  resetAddress: () => void;
  fieldHelpNames?: {
    city?: string | null;
    cityAdditionalInformation?: string | null;
    countryCodeIso2?: string | null;
    houseEntranceApartmentNumber?: string | null;
    street?: string | null;
    zipCode?: string | null;
  };
};

export const AddressFormAddEditItem: FC<Props> = ({ fieldNamePrefix, isVisible, saveAddress, resetAddress, fieldHelpNames }) => {
  if (isVisible) {
    return (
      <>
        <StreetAddressFormPart fieldNamePrefix={fieldNamePrefix} fieldHelpNames={fieldHelpNames} />
        <Space style={{ width: '100%', justifyContent: 'end' }}>
          <OptionalCancelButton resetAddress={resetAddress} />
          <Button htmlType="button" type="primary" onClick={saveAddress}>
            Fertig
          </Button>
        </Space>
      </>
    );
  } else {
    return null;
  }
};

export const OptionalCancelButton = (props: { resetAddress: () => void }) =>
  props.resetAddress ? (
    <Button style={{ float: 'right' }} type="default" htmlType="button" onClick={props.resetAddress}>
      Abbrechen
    </Button>
  ) : null;

export default AddressFormAddEditItem;
