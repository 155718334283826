import React from 'react';
import { Link } from 'react-router-dom';

import { friendlyFormatIBAN } from 'ibantools';
import { TableColumnProps, Tooltip, Typography } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';
import { PaymentType, TransactionInstruction, TransactionInstructionType } from '../../../../../../types';
import FormattedDateTime from '../../../../../../components/FormattedDateTime/FormattedDateTime';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';
import { EuroAmount } from '../../../../../../components/Number';
import theme from '../../../../../../theme';
import { generatePathToDirectDebitOrBankWireListingPage } from '../../../../../DataCarrier/Listing/DirectDebitOrBankWire/Filters/filtersQueryParams';
import { isPaymentTypeEingangsrechnung } from '../../../../paymentHelpers';
import DataWithShortenedText from '../../../../../../components/Helpers/DataWithShortenedText';
import CustomFormattedDate from '../../../../../../components/FormattedDate/CustomFormattedDate';

const isTransactionInstructionTypeLastschrift = (value: TransactionInstructionType) => value === TransactionInstructionType.DirectDebit;

export const transactionInstructionRowColumns = (paymentType: PaymentType): TableColumnProps<TransactionInstruction>[] => [
  {
    // this column is only for the layout purpose
    title: '',
    key: 'empty',
    width: 96,
    render: () => '',
  },
  {
    title: 'Erstellzeitpunkt',
    key: 'createTs',
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'TransaktionsanweisungsId',
    key: 'transactionInstructionId',
    render: (text, record) => (record.endToEndId ? <Typography.Text>{record.endToEndId}</Typography.Text> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Datenträger',
    key: 'dataCarrierName',
    render: (text, record) =>
      record.dataCarrierName ? (
        <Link
          target="_blank"
          to={generatePathToDirectDebitOrBankWireListingPage({
            name: record.dataCarrierName,
            iban: isPaymentTypeEingangsrechnung(paymentType) ? record.debtorIban : record.creditorIban,
          })}
        >
          <DataWithShortenedText maxTextLength={30} text={record.dataCarrierName} />
        </Link>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Zahlungsverkehrsart',
    key: 'paymentTransactionType',
    render: (text, record) =>
      record.paymentTransactionType ? (
        <Typography.Text>{record.paymentTransactionType.text}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Transaktionsart',
    key: 'transactionType',
    render: (text, record) => <Typography.Text>{record.transactionInstructionType.text}</Typography.Text>,
  },
  {
    title: 'Bestandskontrollnummer',
    key: 'paymentInformationId',
    render: (text, record) => <Typography.Text>{record.paymentInformationId}</Typography.Text>,
  },
  {
    title: 'Gewünschtes Transaktionsdatum',
    key: 'requestedExecutionDate',
    render: (text, record) => <CustomFormattedDate value={record.requestedExecutionDate} />,
  },
  {
    title: 'SEPA-Creditor-ID',
    key: 'sepaCreditorId',
    render: (text, record) => {
      return isTransactionInstructionTypeLastschrift(record.transactionInstructionType.value) && !record.sepaCreditorId ? (
        <Tooltip title="Für diese Zahlung mit Typ Lastschrift fehlt die SEPA-Creditor-ID beim Rechnungsaussteller.">
          <WarningTwoTone style={{ cursor: 'help' }} twoToneColor={theme.colors.warning} />
        </Tooltip>
      ) : (
        <Typography.Text>{record.sepaCreditorId}</Typography.Text>
      );
    },
  },
  {
    title: 'SEPA-Mandat',
    key: 'sepaMandateId',
    render: (text, record) => {
      return isTransactionInstructionTypeLastschrift(record.transactionInstructionType.value) && !record.sepaMandateId ? (
        <Tooltip title="Für diese Zahlung mit Typ Lastschrift fehlt für die Bankverbindung ein SEPA-Mandat.">
          <WarningTwoTone style={{ cursor: 'help' }} twoToneColor={theme.colors.warning} />
        </Tooltip>
      ) : (
        <Typography.Text>{record.sepaMandateId}</Typography.Text>
      );
    },
  },
  {
    title: 'Kreditor',
    key: 'creditor',
    render: (text, record) => <Typography.Text>{record.creditor}</Typography.Text>,
  },
  {
    title: 'Kreditor Bankverbindung',
    key: 'creditorIban',
    render: (text, record) => <Typography.Text>{friendlyFormatIBAN(record.creditorIban)}</Typography.Text>,
  },
  {
    title: 'Debitor',
    key: 'debtor',
    render: (text, record) => <Typography.Text>{record.debtor}</Typography.Text>,
  },
  {
    title: 'Debitor Bankverbindung',
    key: 'debtorIban',
    render: (text, record) => <Typography.Text>{friendlyFormatIBAN(record.debtorIban)}</Typography.Text>,
  },
  {
    title: 'Betrag',
    key: 'amount',
    align: 'right',
    render: (text, record) => <EuroAmount value={record.amount} />,
  },
  {
    title: 'Verwendungszweck',
    key: 'purposeOfUseText',
    render: (text, record) =>
      record.purposeOfUseText ? <Typography.Text>{record.purposeOfUseText}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Zahlungsreferenz',
    key: 'paymentReferenceText',
    render: (text, record) => <Typography.Text>{record.paymentReferenceText}</Typography.Text>,
  },
];
