import React, { FC } from 'react';
import { Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../../../hooks/useToggle';
import VertragInfoFeldCreateForm from './Form/VertragInfoFeldCreateForm';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../../components/Button/ButtonsAligned';

type Props = {
  onSuccess: () => void;
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  showArchived: boolean;
  toggleShowArchived: () => void;
};

const VertragInfoFeldDrawerAndButton: FC<Props> = ({ objektId, bestandseinheitId, vertragId, onSuccess, showArchived, toggleShowArchived }) => {
  const [isCollapsed, onCollapse] = useToggle();

  const onCreateSuccess = () => {
    onSuccess();
    onCollapse();
  };

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <Button onClick={toggleShowArchived}>Archivierte Einträge {showArchived ? 'ausblenden' : 'anzeigen'}</Button>
        <Button type="primary" onClick={onCollapse}>
          <PlusOutlined /> Info Neuanlage
        </Button>
      </ButtonsAligned>
      <Drawer title="Informationen anlegen" width={720} open={isCollapsed} onClose={onCollapse} destroyOnClose>
        <VertragInfoFeldCreateForm
          vertragId={vertragId}
          bestandseinheitId={bestandseinheitId}
          objektId={objektId}
          onCancel={onCollapse}
          onSuccess={onCreateSuccess}
        />
      </Drawer>
    </>
  );
};

export default VertragInfoFeldDrawerAndButton;
