import React, { FC } from 'react';
import { Typography } from 'antd';
import { verarbeitungPageAndMenuListTitles } from '../../verarbeitungHelpers';
import { Spacer } from '../../../../components/Grid';
import VorschreibungAusgebenVerarbeitungEntryListing from './EntryListing/VorschreibungAusgebenVerarbeitungEntryListing';
import { EigenbelegErstellenGenerierlauf } from '../../../../types';

type Props = {
  generierlauf: EigenbelegErstellenGenerierlauf;
};

const VerarbeitungBelegErstellen: FC<Props> = ({ generierlauf }) => (
  <>
    <Typography.Title level={4}>{verarbeitungPageAndMenuListTitles.VorschreibungAusgeben}</Typography.Title>
    <Spacer />
    <VorschreibungAusgebenVerarbeitungEntryListing generierlauf={generierlauf} />
  </>
);

export default VerarbeitungBelegErstellen;
