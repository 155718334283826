import styled from 'styled-components';
import { Space } from 'antd';
import ParamFormItem from '../../../components/Form/ParamFormItem';

export const StyledWizardSpaceForTextArea = styled(Space)`
  width: 100%;

  &.ant-space-vertical > .ant-space-item {
    width: 100%;
  }

  &.ant-space-horizontal > .ant-space-item {
    &:first-child {
      width: 33%;
      text-align: left;
      flex-shrink: 0;
    }

    &:nth-child(2) {
      width: 67%;
    }
  }
`;

export const StyledWizardSpaceForContent = styled(Space)`
  width: 50%;
  margin-top: 16px;
`;

export const StyledParamFormItemForWizard = styled(ParamFormItem)`
  .ant-form-item-label > label {
    white-space: break-spaces;
    height: auto;
  }
`;
