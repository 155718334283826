import React, { FC } from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import { ExclamationCircleOutlined, UndoOutlined } from '@ant-design/icons';
import { Budgeting, BudgetingKonto } from '../../../../../types';
import { useBudgetingResetKontoEstimateMutation } from '../../../gql/BudgetingMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { showSuccessMsgOther } from '../../../../../components/message';
import { isStatusArchived, isStatusVerarbeitet } from '../../../../../helpers/statusHelper';
import theme from '../../../../../theme';

type Props = {
  budgeting: Budgeting;
  budgetingKonto: BudgetingKonto;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: () => Promise<unknown>;
  isUpdating: boolean;
  updatingRowKey: string | null;
  setUpdatingRowKey: React.Dispatch<React.SetStateAction<string | null>>;
  disabled?: boolean;
};

const BudgetingKontoResetKonto: FC<Props> = ({ budgeting, budgetingKonto, refetch, isUpdating, updatingRowKey, setUpdatingRowKey, disabled }) => {
  const {
    budgetingId,
    objekt: { objektId },
  } = budgeting;
  const { budgetingKontoId } = budgetingKonto;

  const [runAction] = useBudgetingResetKontoEstimateMutation({
    onCompleted: () => {
      showSuccessMsgOther(`Budgetierung aktualisiert`);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const isUpdatingByCurrentRow = isUpdating && updatingRowKey === budgetingKontoId;
  const isDisabled = disabled || isUpdatingByCurrentRow || isStatusArchived(budgeting.status) || isStatusVerarbeitet(budgeting.status);

  const onConfirm = () => {
    setUpdatingRowKey(budgetingKontoId);
    runAction({ variables: { budgetingId, objektId, input: { budgetingKontoId } } }).finally(() => {
      setUpdatingRowKey(null);
    });
  };

  return (
    <Popconfirm
      title="Möchten Sie diesen Wert zurücksetzen?"
      icon={<ExclamationCircleOutlined style={{ color: theme.colors.warning }} />}
      disabled={isDisabled}
      onConfirm={onConfirm}
      okText="Zurücksetzen"
    >
      <Tooltip title="Zurücksetzen">
        <Button disabled={isDisabled} type="link" icon={<UndoOutlined />} />
      </Tooltip>
    </Popconfirm>
  );
};

export default BudgetingKontoResetKonto;
