import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { Konto } from '../../../objektAbrechnung/objektAbrechnung-types';

const InfobrKreisKontoBezeichnung: FC<{ konto: Konto }> = ({ konto }) => (
  <View style={[pdfStyles.row]}>
    <Text
      style={[
        pdfStyles.textNormal,
        konto.buchungAusgabenList.length > 0 ? pdfStyles.displayBuchungszeilen : pdfStyles.buchungSumPerAccount,
        {
          textDecoration: 'underline',
          marginTop: '5mm',
          marginBottom: '0mm',
          width: '50%',
        },
      ]}
    >
      {konto.kontonummer ? `${konto.bezeichnung} / ${konto.kontonummer}` : konto.bezeichnung}
    </Text>
    {konto.buchungAusgabenList.length === 0 && (
      <Text
        style={[
          pdfStyles.textNormal,
          pdfStyles.buchungSumPerAccount,
          {
            width: '50%',
            textAlign: 'right',
            paddingRight: '3mm',
          },
        ]}
      >
        {konto.betragNettoSummeBuchungen}
      </Text>
    )}
  </View>
);

export default InfobrKreisKontoBezeichnung;
