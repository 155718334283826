import { generatePath } from 'react-router-dom';
import { URI_OBJEKT_DETAILS_PAGE } from '../Objekt/objektUriPaths';
import { isStatusInvalid } from '../../helpers/statusHelper';
import { VorsteuerkuerzungStatusTuple } from '../../types';

export const URI_VORSTEUERKUERZUNG_DETAILS_PAGE = `${URI_OBJEKT_DETAILS_PAGE}/tops/vorsteuerkuerzung/:vorsteuerkuerzungId`;

export const generatePathToVorsteuerkuerzungDetailsPage = (objektId: string, vorsteuerkuerzungId: string) =>
  generatePath(URI_VORSTEUERKUERZUNG_DETAILS_PAGE, { objektId, vorsteuerkuerzungId });

export const generatePathToVorsteuerkuerzungDetailsPageOrDiscrepancies = (
  objektId: string,
  vorsteuerkuerzungId: string,
  status: VorsteuerkuerzungStatusTuple
) =>
  isStatusInvalid(status)
    ? `${generatePathToVorsteuerkuerzungDetailsPage(objektId, vorsteuerkuerzungId)}/discrepancies`
    : generatePath(URI_VORSTEUERKUERZUNG_DETAILS_PAGE, { objektId, vorsteuerkuerzungId });
