import React, { FC } from 'react';
import { Typography } from 'antd';
import { Select, SelectProps } from 'formik-antd';
import { friendlyFormatIBAN } from 'ibantools';
import { PaymentGenerierlaufType } from '../../../../../types';
import { useFilterGenLaufPaymentIbanListQuery } from '../../../gql/GenerierlaufFilter/PaymentGenerierlaufFilterQueries.types';

type Props = {
  name: string;
  generierlaufId: string;
  generierlaufType: PaymentGenerierlaufType;
} & SelectProps;

const GenerierlaufIbanListFormSelect: FC<Props> = ({ name, generierlaufId, generierlaufType, ...restProps }) => {
  const { data, loading } = useFilterGenLaufPaymentIbanListQuery({
    variables: {
      generierlaufId,
      generierlaufType,
    },
  });

  const list = data?.getFilterGenLaufPaymentIbanList.data ?? [];

  return (
    <Select size="small" loading={loading} id={name} name={name} allowClear placeholder="IBAN auswählen" {...restProps}>
      {list.map((iban) => (
        <Select.Option key={iban} value={iban}>
          <Typography.Text>{friendlyFormatIBAN(iban)}</Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export default GenerierlaufIbanListFormSelect;
