import React from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import AuftragsartTemplateUpdateForm from '../../../../../features/AugtragsartTemplate/Form/Update/AuftragsartTemplateUpdateForm';
import { AuftragsartUpdatePageRouteParams } from '../../../../SysSettingsPage/KundenSystem/AuftragsartenPage/Update/routes';
import { useAuftragsartTemplateQuery } from '../../../../../features/AugtragsartTemplate/gql/AuftragsartTemplateQueries.types';

const AndromedaSysSettingsAuftragsartUpdatePage = () => {
  const { auftragsartId } = useParams() as AuftragsartUpdatePageRouteParams;

  const { data, refetch } = useAuftragsartTemplateQuery({
    variables: {
      auftragsartId,
    },
  });

  const auftragsart = data?.getAuftragsartTemplate.data;

  if (!auftragsart) {
    return <Skeleton active />;
  }

  return <AuftragsartTemplateUpdateForm auftragsart={auftragsart} onSuccess={refetch} />;
};

export default AndromedaSysSettingsAuftragsartUpdatePage;
