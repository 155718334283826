import React, { FC } from 'react';
import { Input, Select } from 'formik-antd';
import { FormFields } from '../../../../../../helpers/formikHelper';
import DatenTabColumnsLayout from '../../../../../Objekt/InfoFeld/Form/shared/DatenTabColumnsLayout';
import { PersoneninfosCreateFormValues } from '../Create/personeninfosCreateFormMapper';
import { PersoneninfosUpdateFormValues } from '../Update/personeninfosUpdateFormMapper';
import FormItemWithFieldHelp from '../../../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../../../FieldHelp/useGetFieldHelpText';

type Props = {
  formFields: FormFields<PersoneninfosCreateFormValues> | FormFields<PersoneninfosUpdateFormValues>;
};

const PersoneninfosDatenTab: FC<Props> = ({ formFields }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'RechtstraegerInfoFeldPersoneninfos'>('RechtstraegerInfoFeldPersoneninfos');

  return (
    <DatenTabColumnsLayout
      leftColumn={
        <>
          <FormItemWithFieldHelp
            name={formFields.merkmalList}
            label="Merkmale"
            fieldHelp={getFieldHelpText('RechtstraegerInfoFeldPersoneninfos.merkmalList')}
          >
            <Select id={formFields.merkmalList} name={formFields.merkmalList} mode="tags" size="small" />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.staatsbuergerschaft}
            label="Staatsbürgerschaft"
            fieldHelp={getFieldHelpText('RechtstraegerInfoFeldPersoneninfos.staatsbuergerschaft')}
          >
            <Input id={formFields.staatsbuergerschaft} name={formFields.staatsbuergerschaft} size="small" />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.geburtsort}
            label="Geburtsort"
            fieldHelp={getFieldHelpText('RechtstraegerInfoFeldPersoneninfos.geburtsort')}
          >
            <Input id={formFields.geburtsort} name={formFields.geburtsort} size="small" />
          </FormItemWithFieldHelp>
        </>
      }
      rightColumn={
        <>
          <FormItemWithFieldHelp
            name={formFields.kundengruppeList}
            label="Kundengruppe"
            fieldHelp={getFieldHelpText('RechtstraegerInfoFeldPersoneninfos.kundengruppeList')}
          >
            <Select id={formFields.kundengruppeList} name={formFields.kundengruppeList} mode="tags" size="small" />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={formFields.identitaetsnachweis}
            label="Identitätsnachweis"
            fieldHelp={getFieldHelpText('RechtstraegerInfoFeldPersoneninfos.identitaetsnachweis')}
          >
            <Input id={formFields.identitaetsnachweis} name={formFields.identitaetsnachweis} size="small" />
          </FormItemWithFieldHelp>
        </>
      }
    />
  );
};

export default PersoneninfosDatenTab;
