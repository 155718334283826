import React from 'react';
import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../../components/Template/DetailsPageTemplate';
import menuListVorschreibungErstellen from './menuListVorschreibungErstellen';
import { VerarbeitungVorschreibungDetailsPageRouteParams } from '../routes';
import { useAuftragGenerierlaufQuery } from '../../../../features/Verarbeitung/gql/AuftragGenerierlauf/AuftragGenerierlaufQueries.types';
import VerarbeitungDetailsPageCard from '../../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../../features/Verarbeitung/verarbeitungHelpers';
import { AuftragGenerierlauf } from '../../../../types';
import { useRestartAuftragGenerierlaufMutation } from '../../../../features/Verarbeitung/gql/AuftragGenerierlauf/AuftragGenerierlaufMutation.types';

const VorschreibungErstellenDetailsPage = () => {
  const { generierlaufId } = useParams() as VerarbeitungVorschreibungDetailsPageRouteParams;

  const { data, refetch } = useAuftragGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getAuftragGenerierlauf.data;

  const [runRestart] = useRestartAuftragGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<AuftragGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.Auftragserstellung}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.Auftragserstellung}
          onGenerierlaufRestart={runRestart}
        />
      )}
      sidebarMenuList={(generierlauf) => menuListVorschreibungErstellen(generierlauf)}
    />
  );
};

export default VorschreibungErstellenDetailsPage;
