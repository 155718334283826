import * as Yup from 'yup';
import { entityMustBeSelected } from '../../../../../components/message/validationMsg';
import { paymentProposalFormFields } from './deleteFormMapper';
/* eslint-disable no-template-curly-in-string */

export const deleteFormValidationSchema = Yup.object().shape({
  [paymentProposalFormFields.paymentProposalId]: Yup.string().required(entityMustBeSelected('Zahlungsvorschlag')),
});

/* eslint-enable no-template-curly-in-string */
