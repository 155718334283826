import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { FormikProps } from 'formik';
import { filtersFormFields, FiltersFormValues, listingLabelList, mapFilterLabelToQueryFilterList } from './filtersFormMapper';
import { BookingFolderQueryParams } from './filtersQueryParams';
import useFilterWithQueryParams from '../../../shared/useFilterWithQueryParams';
import FiltersWith4OrMoreFields from '../../../../components/Filters/FiltersWith4OrMoreFields';
import FiltersSelect from '../../../../components/Filters/FiltersSelect';
import { onFilterSelectChange } from '../../../../components/Filters/FiltersHelpers';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import MitarbeiterSelect from '../../../../shared/components/MitarbeiterSelect/MitarbeiterSelect';
import FromToFormInputNumber from '../../../shared/FromToForm/FromToFormInputNumber';
import { StyledStickyHeaderDivForFilterOrSummary } from '../../../../components/StickyHeader/styled/StickyHeader.styled';
import ActiveOrDeactivatedFormSelect from '../../../shared/ActiveOrDeactivatedFormSelect';
import BookingFolderTableBulkActions from '../BookingFolderTableBulkActions';
import { generatePathToBookingInstruction } from '../../../BookingInstruction/bookingInstructionUriPaths';
import FibuBaZuStornierendeBuchungSelect from '../../../BookingInstruction/shared/Filters/FibuBaZuStornierendeBuchungSelect';
import FibuBaVertragspartnerSelect from '../../../BookingInstruction/shared/Filters/FibuBaVertragspartnerSelect';
import {
  isBaFilterBelegdatumSelected,
  isBaFilterBelegnummerSelected,
  isBaFilterBelegSymbolListSelected,
  isBaFilterBestandseinheitSelected,
  isBaFilterBuchungsdatumSelected,
  isBaFilterBuchungsTypeListSelected,
  isBaFilterCreateByMitarbeiterIdListSelected,
  isBaFilterCreateTsSelected,
  isBaFilterDueDateSelected,
  isBaFilterFachlichePruefungSelected,
  isBaFilterHeVertragSelected,
  isBaFilterObjektSelected,
  isBaFilterRechtstraegerIdListSelected,
  isBaFilterStatusSelected,
  isBaFilterSumBruttoSelected,
  isBaFilterSumSteuerSelected,
  isBaFilterVertragSelected,
  isBaFilterVertragspartnerListSelected,
  isBaFilterZuStornierendeBuchungIdSelected,
  updateListingFilterValues,
} from './filtersFormHelpers';
import ObjektSelectFormItem from './SelectFormItems/ObjektSelectFormItem';
import BestandseinheitSelectFormItem from './SelectFormItems/BestandseinheitSelectFormItem';
import HeVertragSelectFormItem from './SelectFormItems/HeVertragSelectFormItem';
import BeVertragSelectFormItem from './SelectFormItems/BeVertragSelectFormItem';
import BelegSymbolSelectFormItem from './SelectFormItems/BelegSymbolSelectFormItem';
import BelegnummerSelectFormItem from './SelectFormItems/BelegnummerSelectFormItem';
import DateSelectFormItem from './SelectFormItems/DateSelectFormItem';
import BuchungskreisRechtstraegerSelectFormItem from './SelectFormItems/BuchungskreisRechtstraegerSelectFormItem';
import BuchungTypeSelectFormItem from './SelectFormItems/BuchungTypeSelectFormItem';
import StatusSelectFormItem from './SelectFormItems/StatusSelectFormItem';

const ListingFilters = forwardRef<
  HTMLDivElement,
  {
    formikProps: FormikProps<FiltersFormValues>;
    queryParams: BookingFolderQueryParams;
  }
>(({ queryParams, formikProps }, ref) => {
  const navigate = useNavigate();
  const defaultSelectedFilterList = [filtersFormFields.rechtstraegerIdList, filtersFormFields.belegSymbolList, filtersFormFields.objektId];

  const filters = (filter: string, selectedFilterList: string[]) => {
    if (isBaFilterBelegSymbolListSelected(filter)) {
      return <BelegSymbolSelectFormItem formikProps={formikProps} key={filter} />;
    }
    if (isBaFilterBelegdatumSelected(filter)) {
      return (
        <DateSelectFormItem
          name="buchungsdatum"
          label={listingLabelList.belegDatumFrom}
          key={filter}
          fromName={filtersFormFields.belegDatumFrom}
          toName={filtersFormFields.belegDatumTo}
          formikProps={formikProps}
        />
      );
    }
    if (isBaFilterBelegnummerSelected(filter)) {
      return <BelegnummerSelectFormItem formikProps={formikProps} key={filter} />;
    }
    if (isBaFilterBestandseinheitSelected(filter)) {
      return <BestandseinheitSelectFormItem formikProps={formikProps} key={filter} />;
    }
    if (isBaFilterBuchungsTypeListSelected(filter)) {
      return <BuchungTypeSelectFormItem formikProps={formikProps} key={filter} />;
    }
    if (isBaFilterBuchungsdatumSelected(filter)) {
      return (
        <DateSelectFormItem
          name="buchungsdatum"
          label={listingLabelList.buchungsdatumFrom}
          key={filter}
          fromName={filtersFormFields.buchungsdatumFrom}
          toName={filtersFormFields.buchungsdatumTo}
          formikProps={formikProps}
        />
      );
    }
    if (isBaFilterCreateByMitarbeiterIdListSelected(filter)) {
      return (
        <FormItemWithoutColon name={filtersFormFields.createByMitarbeiterIdList} label={listingLabelList.createByMitarbeiterIdList} key={filter}>
          <MitarbeiterSelect mode="multiple" name={filtersFormFields.createByMitarbeiterIdList} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (isBaFilterCreateTsSelected(filter)) {
      return (
        <DateSelectFormItem
          name="erstelltAm"
          label={listingLabelList.createTsFrom}
          key={filter}
          fromName={filtersFormFields.createTsFrom}
          toName={filtersFormFields.createTsTo}
          formikProps={formikProps}
        />
      );
    }
    if (isBaFilterDueDateSelected(filter)) {
      return (
        <DateSelectFormItem
          name="faelligkeit"
          label={listingLabelList.dueDateFrom}
          key={filter}
          fromName={filtersFormFields.dueDateFrom}
          toName={filtersFormFields.dueDateTo}
          formikProps={formikProps}
        />
      );
    }
    if (isBaFilterFachlichePruefungSelected(filter)) {
      return (
        <FormItemWithoutColon name={filtersFormFields.fachlichePruefung} label={listingLabelList.fachlichePruefung} key={filter}>
          <ActiveOrDeactivatedFormSelect name={filtersFormFields.fachlichePruefung} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    // if HeVertrag filter is chosen, then Objekt and HeVertrag filter should be rendered
    if (isBaFilterHeVertragSelected(filter)) {
      if (!selectedFilterList.find(isBaFilterObjektSelected)) {
        return (
          <React.Fragment key={filter}>
            <ObjektSelectFormItem formikProps={formikProps} />
            <HeVertragSelectFormItem formikProps={formikProps} />
          </React.Fragment>
        );
      }
      return <HeVertragSelectFormItem formikProps={formikProps} key={filter} />;
    }
    if (isBaFilterObjektSelected(filter)) {
      return <ObjektSelectFormItem formikProps={formikProps} key={filter} />;
    }
    if (isBaFilterRechtstraegerIdListSelected(filter)) {
      return <BuchungskreisRechtstraegerSelectFormItem formikProps={formikProps} key={filter} />;
    }
    if (isBaFilterStatusSelected(filter)) {
      return <StatusSelectFormItem formikProps={formikProps} key={filter} />;
    }
    if (isBaFilterSumBruttoSelected(filter)) {
      return (
        <FromToFormInputNumber
          key={filter}
          name={listingLabelList.sumBruttoFrom}
          fromName={filtersFormFields.sumBruttoFrom}
          toName={filtersFormFields.sumBruttoTo}
          onChange={formikProps.submitForm}
          fractionDigits={2}
        />
      );
    }
    if (isBaFilterSumSteuerSelected(filter)) {
      return (
        <FromToFormInputNumber
          key={filter}
          name={listingLabelList.sumSteuerFrom}
          fromName={filtersFormFields.sumSteuerFrom}
          toName={filtersFormFields.sumSteuerTo}
          onChange={formikProps.submitForm}
          fractionDigits={0}
        />
      );
    }
    // if Vertrag filter is chosen, then Objekt, Bestandseinheit and Vertrag filter should be rendered
    if (isBaFilterVertragSelected(filter)) {
      if (!selectedFilterList.find(isBaFilterObjektSelected) && !selectedFilterList.find(isBaFilterBestandseinheitSelected)) {
        return (
          <React.Fragment key={filter}>
            <ObjektSelectFormItem formikProps={formikProps} />
            <BestandseinheitSelectFormItem formikProps={formikProps} />;
            <BeVertragSelectFormItem formikProps={formikProps} />
          </React.Fragment>
        );
      }
      if (!!selectedFilterList.find(isBaFilterObjektSelected) && !selectedFilterList.find(isBaFilterBestandseinheitSelected)) {
        return (
          <React.Fragment key={filter}>
            <BestandseinheitSelectFormItem formikProps={formikProps} />;
            <BeVertragSelectFormItem formikProps={formikProps} />
          </React.Fragment>
        );
      }
      return <BeVertragSelectFormItem formikProps={formikProps} key={filter} />;
    }
    if (isBaFilterVertragspartnerListSelected(filter)) {
      return (
        <FormItemWithoutColon name={filtersFormFields.vertragspartnerIdList} label={listingLabelList.vertragspartnerIdList} key={filter}>
          <FibuBaVertragspartnerSelect
            name={filtersFormFields.vertragspartnerIdList}
            onChange={formikProps.submitForm}
            rechtstraegerIdList={formikProps.values.rechtstraegerIdList}
          />
        </FormItemWithoutColon>
      );
    }
    if (isBaFilterZuStornierendeBuchungIdSelected(filter)) {
      return (
        <FormItemWithoutColon name={filtersFormFields.zuStornierendeBuchungId} label={listingLabelList.zuStornierendeBuchungId} key={filter}>
          <FibuBaZuStornierendeBuchungSelect name={filtersFormFields.zuStornierendeBuchungId} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    return undefined;
  };

  const updateFilterValues = (selectedFilterList: string[]) => updateListingFilterValues(formikProps, selectedFilterList);

  const { selectedFilterList, setSelectedFilterList, leftColumn, rightColumn } = useFilterWithQueryParams({
    defaultSelectedFilterList,
    updateFilterValues,
    filters,
    labelList: listingLabelList,
    mapFilterLabelToQueryFilterList,
    queryParams,
  });

  return (
    <StyledStickyHeaderDivForFilterOrSummary ref={ref}>
      <FiltersWith4OrMoreFields
        actionButtons={
          <Button type="primary" onClick={() => navigate(generatePathToBookingInstruction())}>
            Buchungsanweisung erstellen
          </Button>
        }
        filterActions={
          // FIXME: Update onAction
          <BookingFolderTableBulkActions
            onAction={() => {
              console.log('bulk action clicked');
            }}
            queryParams={queryParams}
          />
        }
        filtersSelect={
          <FiltersSelect
            defaultSelectedFilterList={defaultSelectedFilterList}
            selectedFilterList={selectedFilterList}
            onChange={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
            labelList={listingLabelList}
          />
        }
        hideTitle
        leftColumn={<>{leftColumn}</>}
        rightColumn={<>{rightColumn}</>}
      />
    </StyledStickyHeaderDivForFilterOrSummary>
  );
});

export default ListingFilters;
