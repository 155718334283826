import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestartFirmendatenInitializerGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartFirmendatenInitializerGenerierlaufMutation = {
  actionRestartFirmendatenInitializerGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RestartFirmendatenInitializerGenerierlaufDocument = gql`
  mutation RestartFirmendatenInitializerGenerierlauf($generierlaufId: ID!) {
    actionRestartFirmendatenInitializerGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartFirmendatenInitializerGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestartFirmendatenInitializerGenerierlaufMutation,
    RestartFirmendatenInitializerGenerierlaufMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestartFirmendatenInitializerGenerierlaufMutation, RestartFirmendatenInitializerGenerierlaufMutationVariables>(
    RestartFirmendatenInitializerGenerierlaufDocument,
    options
  );
}
export type RestartFirmendatenInitializerGenerierlaufMutationHookResult = ReturnType<typeof useRestartFirmendatenInitializerGenerierlaufMutation>;
export type RestartFirmendatenInitializerGenerierlaufMutationResult = Apollo.MutationResult<RestartFirmendatenInitializerGenerierlaufMutation>;
export type RestartFirmendatenInitializerGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartFirmendatenInitializerGenerierlaufMutation,
  RestartFirmendatenInitializerGenerierlaufMutationVariables
>;
