import { Dropdown, MenuProps, Modal } from 'antd';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { EuroCircleOutlined, FileSearchOutlined, PlusSquareOutlined, ReadOutlined, WalletOutlined } from '@ant-design/icons';
import { uriFileOfFirmendaten } from '../documentApiPaths';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { showSuccessMsgOther } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { useEigenbelegMarkUnderReviewMutation } from '../../../pages/BookingDetailsPage/BookingDetailsEigenbelegPage/gql/EigenbelegMutations.types';
import { AuftragStatusDetails, Eigenbeleg, FileInfo } from '../../../types';
import { useToggle } from '../../../hooks/useToggle';
import PaymentCreateForm from '../../Payment/Form/Create/PaymentCreateForm';
import { isBillingTypeEinzelrechnung, isPaymentMethodLastschrift } from '../../Payment/paymentHelpers';
import { generatePathToPaymentPage } from '../../Payment/Listing/Filters/filtersQueryParams';
import { isStatusAusgegeben, isStatusUebertragen } from '../../../helpers/statusHelper';
import { generatePathToOffenePostenPage } from '../../OffenePosten/offenePostenUriPaths';
import { generatePathToBookingDetailsPage } from '../../BookingDetails/bookingDetailsPaths';

type Props = {
  record: FileInfo;
  eigenbeleg: Eigenbeleg;
  auftragId: string;
  onAction: () => void;
};

const EigenbelegActions: FC<Props> = ({ record, eigenbeleg, auftragId, onAction }) => {
  const { activeForFirmendatenId } = useFDAuthorized();

  const [auftragStatusDetails, setAuftragStatusDetails] = useState<AuftragStatusDetails>();
  const [isZahlungCreateOpen, toggleZahlungCreateOpen] = useToggle();

  const [runMarkUnderReview] = useEigenbelegMarkUnderReviewMutation({
    variables: { belegId: eigenbeleg.belegId },
    onCompleted: () => {
      showSuccessMsgOther(`Ausgangsrechnung wird überprüft`);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Prüfen',
      onClick: () => showConfirmMarkUnderReview(runMarkUnderReview),
      icon: <FileSearchOutlined />,
    },
    {
      key: '2',
      label: 'Buchungsdetails ansehen',
      onClick: () => window.open(generatePathToBookingDetailsPage(eigenbeleg.buchungIdList, auftragId)),
      disabled: !eigenbeleg.buchungsanweisungStatus,
      icon: <ReadOutlined />,
    },
    isStatusUebertragen(eigenbeleg.status) && eigenbeleg.buchungIdList.length > 0
      ? {
          key: '6',
          label: 'Offene Posten anzeigen',
          icon: <WalletOutlined />,
          onClick: () =>
            window.open(
              generatePathToOffenePostenPage({
                buchungskreisId: eigenbeleg.buchungskreisId,
                belegnummer: eigenbeleg.belegnummer,
                vertragspartnerId: eigenbeleg.empfaengerRechtstraegerId,
                belegDatumTo: eigenbeleg.belegdatum,
                belegDatumFrom: eigenbeleg.belegdatum,
              })
            ),
        }
      : null,
    ...(isPaymentMethodLastschrift(eigenbeleg.paymentMethod.value) && !isStatusAusgegeben(eigenbeleg.status)
      ? [
          {
            key: '3',
            label: 'Lastschriften anzeigen',
            icon: <EuroCircleOutlined />,
            onClick: () =>
              window.open(
                generatePathToPaymentPage({
                  belegnummer: eigenbeleg.belegnummer,
                  invoiceDateFrom: eigenbeleg.belegdatum,
                  invoiceDateTo: eigenbeleg.belegdatum,
                  payeeId: eigenbeleg.empfaengerRechtstraegerId,
                  rechnungsAusstellerId: eigenbeleg.ausstellerRechtstraegerId,
                })
              ),
            disabled: eigenbeleg.auftragStatusDetailsList[0].paymentIdList.length <= 0,
          },
          isBillingTypeEinzelrechnung(eigenbeleg.verrechnungsart.value)
            ? {
                key: '4',
                label: 'Lastschrift erstellen',
                icon: <PlusSquareOutlined />,
                onClick: () => {
                  setAuftragStatusDetails(eigenbeleg.auftragStatusDetailsList[0]);
                  toggleZahlungCreateOpen();
                },
                disabled: !eigenbeleg.auftragStatusDetailsList[0].paymentCreatable,
              }
            : {
                key: '5',
                label: 'Lastschrift erstellen',
                icon: <PlusSquareOutlined />,
                children: eigenbeleg.auftragStatusDetailsList.map((auftragStatusDetails) => ({
                  key: auftragStatusDetails.auftragId,
                  label: `VS ${auftragStatusDetails.fakturierungsperiode.monat}/${auftragStatusDetails.fakturierungsperiode.jahr}`,
                  onClick: () => {
                    setAuftragStatusDetails(auftragStatusDetails);
                    toggleZahlungCreateOpen();
                  },
                  disabled: !auftragStatusDetails.paymentCreatable,
                })),
              },
        ]
      : []),
  ];

  const onActionSuccess = () => {
    onAction();
    toggleZahlungCreateOpen();
  };

  return (
    <>
      <Dropdown.Button menu={{ items }}>
        <Link to={uriFileOfFirmendaten(activeForFirmendatenId, record.fileId, record.name)} target="_blank">
          Öffnen
        </Link>
      </Dropdown.Button>
      <Modal
        title={
          auftragStatusDetails
            ? `Lastschrift für VS ${auftragStatusDetails.fakturierungsperiode.monat}/${auftragStatusDetails.fakturierungsperiode.jahr}`
            : 'Lastschrift erstellen'
        }
        onCancel={toggleZahlungCreateOpen}
        open={isZahlungCreateOpen}
        destroyOnClose
        maskClosable={false}
        footer={false}
      >
        <PaymentCreateForm
          onCancel={toggleZahlungCreateOpen}
          onSuccess={onActionSuccess}
          belegId={eigenbeleg.belegId}
          paymentMethod={eigenbeleg.paymentMethod.value}
          auftragId={auftragStatusDetails?.auftragId}
          outstandingAmount={auftragStatusDetails?.offenerBetrag}
        />
      </Modal>
    </>
  );
};

const showConfirmMarkUnderReview = (runMarkUnderReview: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie dieses Ausgangsrechnung prüfen?',
    okText: 'Prüfen',
    cancelText: 'Abbrechen',
    onOk() {
      return runMarkUnderReview();
    },
  });
};

export default EigenbelegActions;
