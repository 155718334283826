import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FirmendatenBuchhaltungEinstellungQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FirmendatenBuchhaltungEinstellungQuery = {
  getFirmendatenBuchhaltungEinstellung: {
    data: {
      aufwandAbgrenzungskontoId?: string | null;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      heAbrechnungVerrechnungskontoId?: string | null;
      leerstehungAufwandskontoId?: string | null;
      leerstehungForderungskontoId?: string | null;
      pauschalmieteAufwandskontoId?: string | null;
      pauschalmieteForderungskontoId?: string | null;
      subAdministrationBillingAccountId?: string | null;
      updatedByMitarbeiterId?: string | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type LeerstehungAufwandskontoQueryVariables = Types.Exact<{
  firmendatenLeerstehungAufwandskontoId: Types.Scalars['ID']['input'];
}>;

export type LeerstehungAufwandskontoQuery = {
  getLeerstehungAufwandskonto: {
    data: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      firmendatenLeerstehungAufwandskontoId: string;
      validFrom: string;
      kommentar?: string | null;
      lastUpdateTs: string;
      leerstehungAufwandskontoId: string;
      historicizedList?: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        firmendatenLeerstehungAufwandskontoId: string;
        validFrom: string;
        kommentar?: string | null;
        lastUpdateTs: string;
        leerstehungAufwandskontoId: string;
        warningList?: Array<{ attribute?: string | null; message: string; type: string }> | null;
      }> | null;
      warningList?: Array<{ attribute?: string | null; message: string; type: string }> | null;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type LeerstehungAufwandskontoListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type LeerstehungAufwandskontoListQuery = {
  getLeerstehungAufwandskontoList: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      firmendatenLeerstehungAufwandskontoId: string;
      validFrom: string;
      kommentar?: string | null;
      lastUpdateTs: string;
      leerstehungAufwandskontoId: string;
      historicizedList?: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        firmendatenLeerstehungAufwandskontoId: string;
        validFrom: string;
        kommentar?: string | null;
        lastUpdateTs: string;
        leerstehungAufwandskontoId: string;
        warningList?: Array<{ attribute?: string | null; message: string; type: string }> | null;
      }> | null;
      warningList?: Array<{ attribute?: string | null; message: string; type: string }> | null;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type LeerstehungForderungsKontoChangeHistoryListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type LeerstehungForderungsKontoChangeHistoryListQuery = {
  getLeerstehungForderungsKontoChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type BillingChangeHistoryQueryVariables = Types.Exact<{ [key: string]: never }>;

export type BillingChangeHistoryQuery = {
  getBillingChangeHistory: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const FirmendatenBuchhaltungEinstellungDocument = gql`
  query FirmendatenBuchhaltungEinstellung {
    getFirmendatenBuchhaltungEinstellung {
      data {
        aufwandAbgrenzungskontoId
        createTs
        createdBy
        createdByMitarbeiterId
        heAbrechnungVerrechnungskontoId
        leerstehungAufwandskontoId
        leerstehungForderungskontoId
        pauschalmieteAufwandskontoId
        pauschalmieteForderungskontoId
        subAdministrationBillingAccountId
        updatedByMitarbeiterId
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFirmendatenBuchhaltungEinstellungQuery(
  baseOptions?: Apollo.QueryHookOptions<FirmendatenBuchhaltungEinstellungQuery, FirmendatenBuchhaltungEinstellungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenBuchhaltungEinstellungQuery, FirmendatenBuchhaltungEinstellungQueryVariables>(
    FirmendatenBuchhaltungEinstellungDocument,
    options
  );
}
export function useFirmendatenBuchhaltungEinstellungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenBuchhaltungEinstellungQuery, FirmendatenBuchhaltungEinstellungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenBuchhaltungEinstellungQuery, FirmendatenBuchhaltungEinstellungQueryVariables>(
    FirmendatenBuchhaltungEinstellungDocument,
    options
  );
}
export function useFirmendatenBuchhaltungEinstellungSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FirmendatenBuchhaltungEinstellungQuery, FirmendatenBuchhaltungEinstellungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenBuchhaltungEinstellungQuery, FirmendatenBuchhaltungEinstellungQueryVariables>(
    FirmendatenBuchhaltungEinstellungDocument,
    options
  );
}
export type FirmendatenBuchhaltungEinstellungQueryHookResult = ReturnType<typeof useFirmendatenBuchhaltungEinstellungQuery>;
export type FirmendatenBuchhaltungEinstellungLazyQueryHookResult = ReturnType<typeof useFirmendatenBuchhaltungEinstellungLazyQuery>;
export type FirmendatenBuchhaltungEinstellungSuspenseQueryHookResult = ReturnType<typeof useFirmendatenBuchhaltungEinstellungSuspenseQuery>;
export type FirmendatenBuchhaltungEinstellungQueryResult = Apollo.QueryResult<
  FirmendatenBuchhaltungEinstellungQuery,
  FirmendatenBuchhaltungEinstellungQueryVariables
>;
export const LeerstehungAufwandskontoDocument = gql`
  query LeerstehungAufwandskonto($firmendatenLeerstehungAufwandskontoId: ID!) {
    getLeerstehungAufwandskonto(firmendatenLeerstehungAufwandskontoId: $firmendatenLeerstehungAufwandskontoId) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
        current
        firmendatenLeerstehungAufwandskontoId
        validFrom
        historicizedList {
          createTs
          createdBy
          createdByMitarbeiterId
          current
          firmendatenLeerstehungAufwandskontoId
          validFrom
          kommentar
          lastUpdateTs
          leerstehungAufwandskontoId
          warningList {
            attribute
            message
            type
          }
        }
        kommentar
        lastUpdateTs
        leerstehungAufwandskontoId
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useLeerstehungAufwandskontoQuery(
  baseOptions: Apollo.QueryHookOptions<LeerstehungAufwandskontoQuery, LeerstehungAufwandskontoQueryVariables> &
    ({ variables: LeerstehungAufwandskontoQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LeerstehungAufwandskontoQuery, LeerstehungAufwandskontoQueryVariables>(LeerstehungAufwandskontoDocument, options);
}
export function useLeerstehungAufwandskontoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LeerstehungAufwandskontoQuery, LeerstehungAufwandskontoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LeerstehungAufwandskontoQuery, LeerstehungAufwandskontoQueryVariables>(LeerstehungAufwandskontoDocument, options);
}
export function useLeerstehungAufwandskontoSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LeerstehungAufwandskontoQuery, LeerstehungAufwandskontoQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LeerstehungAufwandskontoQuery, LeerstehungAufwandskontoQueryVariables>(LeerstehungAufwandskontoDocument, options);
}
export type LeerstehungAufwandskontoQueryHookResult = ReturnType<typeof useLeerstehungAufwandskontoQuery>;
export type LeerstehungAufwandskontoLazyQueryHookResult = ReturnType<typeof useLeerstehungAufwandskontoLazyQuery>;
export type LeerstehungAufwandskontoSuspenseQueryHookResult = ReturnType<typeof useLeerstehungAufwandskontoSuspenseQuery>;
export type LeerstehungAufwandskontoQueryResult = Apollo.QueryResult<LeerstehungAufwandskontoQuery, LeerstehungAufwandskontoQueryVariables>;
export const LeerstehungAufwandskontoListDocument = gql`
  query LeerstehungAufwandskontoList {
    getLeerstehungAufwandskontoList {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
        current
        firmendatenLeerstehungAufwandskontoId
        validFrom
        historicizedList {
          createTs
          createdBy
          createdByMitarbeiterId
          current
          firmendatenLeerstehungAufwandskontoId
          validFrom
          kommentar
          lastUpdateTs
          leerstehungAufwandskontoId
          warningList {
            attribute
            message
            type
          }
        }
        kommentar
        lastUpdateTs
        leerstehungAufwandskontoId
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useLeerstehungAufwandskontoListQuery(
  baseOptions?: Apollo.QueryHookOptions<LeerstehungAufwandskontoListQuery, LeerstehungAufwandskontoListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LeerstehungAufwandskontoListQuery, LeerstehungAufwandskontoListQueryVariables>(
    LeerstehungAufwandskontoListDocument,
    options
  );
}
export function useLeerstehungAufwandskontoListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LeerstehungAufwandskontoListQuery, LeerstehungAufwandskontoListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LeerstehungAufwandskontoListQuery, LeerstehungAufwandskontoListQueryVariables>(
    LeerstehungAufwandskontoListDocument,
    options
  );
}
export function useLeerstehungAufwandskontoListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LeerstehungAufwandskontoListQuery, LeerstehungAufwandskontoListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LeerstehungAufwandskontoListQuery, LeerstehungAufwandskontoListQueryVariables>(
    LeerstehungAufwandskontoListDocument,
    options
  );
}
export type LeerstehungAufwandskontoListQueryHookResult = ReturnType<typeof useLeerstehungAufwandskontoListQuery>;
export type LeerstehungAufwandskontoListLazyQueryHookResult = ReturnType<typeof useLeerstehungAufwandskontoListLazyQuery>;
export type LeerstehungAufwandskontoListSuspenseQueryHookResult = ReturnType<typeof useLeerstehungAufwandskontoListSuspenseQuery>;
export type LeerstehungAufwandskontoListQueryResult = Apollo.QueryResult<
  LeerstehungAufwandskontoListQuery,
  LeerstehungAufwandskontoListQueryVariables
>;
export const LeerstehungForderungsKontoChangeHistoryListDocument = gql`
  query LeerstehungForderungsKontoChangeHistoryList {
    getLeerstehungForderungsKontoChangeHistoryList {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useLeerstehungForderungsKontoChangeHistoryListQuery(
  baseOptions?: Apollo.QueryHookOptions<LeerstehungForderungsKontoChangeHistoryListQuery, LeerstehungForderungsKontoChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LeerstehungForderungsKontoChangeHistoryListQuery, LeerstehungForderungsKontoChangeHistoryListQueryVariables>(
    LeerstehungForderungsKontoChangeHistoryListDocument,
    options
  );
}
export function useLeerstehungForderungsKontoChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LeerstehungForderungsKontoChangeHistoryListQuery,
    LeerstehungForderungsKontoChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LeerstehungForderungsKontoChangeHistoryListQuery, LeerstehungForderungsKontoChangeHistoryListQueryVariables>(
    LeerstehungForderungsKontoChangeHistoryListDocument,
    options
  );
}
export function useLeerstehungForderungsKontoChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<LeerstehungForderungsKontoChangeHistoryListQuery, LeerstehungForderungsKontoChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LeerstehungForderungsKontoChangeHistoryListQuery, LeerstehungForderungsKontoChangeHistoryListQueryVariables>(
    LeerstehungForderungsKontoChangeHistoryListDocument,
    options
  );
}
export type LeerstehungForderungsKontoChangeHistoryListQueryHookResult = ReturnType<typeof useLeerstehungForderungsKontoChangeHistoryListQuery>;
export type LeerstehungForderungsKontoChangeHistoryListLazyQueryHookResult = ReturnType<
  typeof useLeerstehungForderungsKontoChangeHistoryListLazyQuery
>;
export type LeerstehungForderungsKontoChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useLeerstehungForderungsKontoChangeHistoryListSuspenseQuery
>;
export type LeerstehungForderungsKontoChangeHistoryListQueryResult = Apollo.QueryResult<
  LeerstehungForderungsKontoChangeHistoryListQuery,
  LeerstehungForderungsKontoChangeHistoryListQueryVariables
>;
export const BillingChangeHistoryDocument = gql`
  query BillingChangeHistory {
    getBillingChangeHistory {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useBillingChangeHistoryQuery(baseOptions?: Apollo.QueryHookOptions<BillingChangeHistoryQuery, BillingChangeHistoryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillingChangeHistoryQuery, BillingChangeHistoryQueryVariables>(BillingChangeHistoryDocument, options);
}
export function useBillingChangeHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BillingChangeHistoryQuery, BillingChangeHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BillingChangeHistoryQuery, BillingChangeHistoryQueryVariables>(BillingChangeHistoryDocument, options);
}
export function useBillingChangeHistorySuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BillingChangeHistoryQuery, BillingChangeHistoryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BillingChangeHistoryQuery, BillingChangeHistoryQueryVariables>(BillingChangeHistoryDocument, options);
}
export type BillingChangeHistoryQueryHookResult = ReturnType<typeof useBillingChangeHistoryQuery>;
export type BillingChangeHistoryLazyQueryHookResult = ReturnType<typeof useBillingChangeHistoryLazyQuery>;
export type BillingChangeHistorySuspenseQueryHookResult = ReturnType<typeof useBillingChangeHistorySuspenseQuery>;
export type BillingChangeHistoryQueryResult = Apollo.QueryResult<BillingChangeHistoryQuery, BillingChangeHistoryQueryVariables>;
