import { FC } from 'react';
import { EbicsBankConfig } from '../../../../types';
import EbicsBankConfigBtnAndDrawer from './EbicsBankConfigBtnAndDrawer';
import EbicsBankConfigTable from './Table/EbicsBankConfigTable';

type Props = {
  ebicsBankConfigList: EbicsBankConfig[];
  onAction: () => void;
};

const EbicsBankConfigListing: FC<Props> = ({ ebicsBankConfigList, onAction }) => {
  return (
    <>
      <EbicsBankConfigBtnAndDrawer onAction={onAction} />
      <EbicsBankConfigTable ebicsBankConfigList={ebicsBankConfigList} onAction={onAction} />
    </>
  );
};

export default EbicsBankConfigListing;
