import React from 'react';
import { Link } from 'react-router-dom';
import { TableColumnProps, Typography } from 'antd';
import { friendlyFormatIBAN } from 'ibantools';
import { IncomingInvoiceListEntry, IncomingInvoiceOrderAttribute } from '../../../../../types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { generatePathToObjektDetailsPage } from '../../../../Objekt/objektUriPaths';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import LinkToBelegFile from '../../../../../components/Link/LinkToBelegFile/LinkToBelegFile';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';
import { EuroAmount } from '../../../../../components/Number';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../../../../components/Card';
import DocumentStatusTag from '../../../../../components/Status/DocumentStatus/DocumentStatusTag';
import StatusBadge from '../../../../../components/Status/StatusBadge';
import SingleInvoiceTableActions from './SingleInvoiceTableActions';
import { isBillingTypeEinzelrechnung } from '../../../../Payment/paymentHelpers';
import StandingInvoiceTableActions from './StandingInvoiceTableActions';
import PaidTag from '../../../../Document/Listing/Row/PaidTag';
import { StyledSpaceSplit60And40 } from '../../../../../components/Space/styled/Space.style';
import RechtstraegerWithContactsTooltip from '../../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

const incomingInvoiceTableColumns = (onAction: () => void): TableColumnProps<IncomingInvoiceListEntry>[] => [
  {
    title: 'Verrechnungsart',
    dataIndex: IncomingInvoiceOrderAttribute.BillingType,
    sorter: true,
    width: 120,
    render: (text, record) => <Typography.Text>{record.billingType.text}</Typography.Text>,
  },
  {
    title: 'Lieferant',
    dataIndex: IncomingInvoiceOrderAttribute.Issuer,
    sorter: true,
    render: (text, record) => (
      <RechtstraegerWithContactsTooltip rechtstraegerId={record.issuerRechtstraegerId} rechtstraegerName={record.issuerName} maxTextLength={20} />
    ),
  },
  {
    title: 'Empfänger',
    dataIndex: IncomingInvoiceOrderAttribute.Payee,
    sorter: true,
    render: (text, record) => (
      <RechtstraegerWithContactsTooltip rechtstraegerId={record.payeeRechtstraegerId} rechtstraegerName={record.payeeName} maxTextLength={20} />
    ),
  },
  {
    title: 'Objekt',
    dataIndex: IncomingInvoiceOrderAttribute.Objekt,
    sorter: true,
    render: (text, record) =>
      record.objektId && record.objektBezeichnung ? (
        <DataWithShortenedText text={record.objektBezeichnung} maxTextLength={20}>
          {(shortenedText) => (
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
            <Link to={generatePathToObjektDetailsPage(record.objektId!)} target="_blank">
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Rechnungsnummer',
    dataIndex: IncomingInvoiceOrderAttribute.RechnungsNummer,
    sorter: true,
    render: (text, record) => <LinkToBelegFile fileId={record.fileId} belegnummer={record.rechnungsNummer} />,
  },
  {
    title: 'Rechnungsdatum',
    dataIndex: IncomingInvoiceOrderAttribute.RechnungsDatum,
    sorter: true,
    render: (text, record) => <CustomFormattedDate value={record.rechnungsDatum} />,
  },
  {
    title: 'Kundennummer',
    dataIndex: IncomingInvoiceOrderAttribute.KundenNummer,
    sorter: true,
    render: (text, record) =>
      record.kundenNummer ? <Typography.Text>{record.kundenNummer}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Netto',
    dataIndex: IncomingInvoiceOrderAttribute.Netto,
    sorter: true,
    align: 'right',
    render: (text, record) => (record.netto ? <EuroAmount value={record.netto} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'USt.',
    dataIndex: IncomingInvoiceOrderAttribute.Vat,
    sorter: true,
    align: 'right',
    render: (text, record) => (record.vat ? <EuroAmount value={record.vat} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Brutto',
    dataIndex: IncomingInvoiceOrderAttribute.Brutto,
    sorter: true,
    align: 'right',
    render: (text, record) => (record.brutto ? <EuroAmount value={record.brutto} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Zahlungsart',
    dataIndex: IncomingInvoiceOrderAttribute.PaymentMethod,
    sorter: true,
    render: (text, record) => <Typography.Text>{record.paymentMethod.text}</Typography.Text>,
  },
  {
    title: 'Bankverbindung Rechnungsempfänger',
    dataIndex: IncomingInvoiceOrderAttribute.PayeeBankDetailsIban,
    sorter: true,
    render: (text, record) => <DataWithShortenedText maxTextLength={15} text={`${friendlyFormatIBAN(record.payeeBankDetailsIban)}`} />,
  },
  {
    title: 'Bankverbindung Lieferant',
    dataIndex: IncomingInvoiceOrderAttribute.IssuerBankDetailsIban,
    sorter: true,
    render: (text, record) => <DataWithShortenedText maxTextLength={15} text={`${friendlyFormatIBAN(record.issuerBankDetailsIban)}`} />,
  },
  {
    title: 'Verwendungszweck',
    dataIndex: IncomingInvoiceOrderAttribute.PurposeOfUseText,
    sorter: true,
    render: (text, record) =>
      record.purposeOfUseText ? (
        <DataWithShortenedText maxTextLength={20} text={record.purposeOfUseText} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Zahlungsreferenz',
    dataIndex: IncomingInvoiceOrderAttribute.PaymentReferenceText,
    sorter: true,
    render: (text, record) =>
      record.paymentReferenceText ? (
        <DataWithShortenedText maxTextLength={20} text={record.paymentReferenceText} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'SEPA Mandatsreferenz',
    dataIndex: IncomingInvoiceOrderAttribute.SepaMandateReference,
    sorter: true,
    render: (text, record) =>
      record.sepaMandatReference ? (
        <DataWithShortenedText maxTextLength={20} text={record.sepaMandatReference} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Status',
    dataIndex: IncomingInvoiceOrderAttribute.Status,
    sorter: true,
    width: 180,
    render: (text, record) => {
      return (
        <StyledSpaceSplit60And40 size={0}>
          {record.paid ? <PaidTag /> : <DocumentStatusTag documentStatus={record.status} />}
          {record.buchungsanweisungStatus && <StatusBadge entityStatus={record.buchungsanweisungStatus} />}
        </StyledSpaceSplit60And40>
      );
    },
  },
  {
    title: 'Erstellt am',
    dataIndex: IncomingInvoiceOrderAttribute.CreateTs,
    sorter: true,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    dataIndex: IncomingInvoiceOrderAttribute.CreatedBy,
    sorter: true,
    width: 80,
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    render: (text, record) =>
      isBillingTypeEinzelrechnung(record.billingType.value) ? (
        <SingleInvoiceTableActions invoice={record} onAction={onAction} />
      ) : (
        <StandingInvoiceTableActions invoice={record} onAction={onAction} />
      ),
  },
];

export default incomingInvoiceTableColumns;
