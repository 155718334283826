import React from 'react';
import { Typography } from 'antd';
import { Budgeting, BudgetingAbrKreis } from '../../../../../types';
import { EuroAmount, Percent } from '../../../../../components/Number';
import TableColumnHeadingWithPeriod from '../../../shared/TableColumnHeadingWithPeriod';
import { IStatus } from '../../../../../helpers/statusHelper';
import DataWithStatus from '../../../../../components/Helpers/DataWithStatus';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { NestedTableWithColSelectorColProps } from '../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';

const budgetingAbrKreisTableColumns = (
  budgeting: Budgeting,
  budgetingVPosStatus: IStatus
): NestedTableWithColSelectorColProps<BudgetingAbrKreis>[] => {
  return [
    {
      title: 'Abrechnungkreis',
      width: 200,
      key: 'vpos',
      render: (text, record) => (
        <DataWithShortenedText maxTextLength={30} text={record.abrechnungskreis.bezeichnung}>
          {(shortenedText) => <Typography.Text>{shortenedText}</Typography.Text>}
        </DataWithShortenedText>
      ),
    },
    {
      title: <TableColumnHeadingWithPeriod text="Gebucht Vorjahr" date={budgeting.prevYear.toString()} />,
      colSelectorTitle: `Gebucht Vorjahr`,
      key: 'prev-year',
      width: 170,
      align: 'right',
      render: (text, record) => (
        <DataWithStatus status={budgetingVPosStatus} text="" showBadgeDot={false} showStatusDescription={false}>
          {() => <EuroAmount value={record.gebuchtPrevYear} />}
        </DataWithStatus>
      ),
    },
    {
      title: <TableColumnHeadingWithPeriod text="Gebucht aktuelles Jahr" date={budgeting.considerationPeriodUntil} endOf="month" />,
      colSelectorTitle: `Gebucht aktuelles Jahr`,
      key: 'current-year',
      width: 170,
      align: 'right',
      render: (text, record) => (
        <DataWithStatus status={budgetingVPosStatus} text="" showBadgeDot={false} showStatusDescription={false}>
          {() => <EuroAmount value={record.gebuchtCurrentYear} />}
        </DataWithStatus>
      ),
    },
    {
      title: '',
      width: 200,
    },
    {
      title: 'Hochrechnung Restjahr',
      key: 'restjahr',
      align: 'right',
      render: (text, record) => (
        <DataWithStatus status={budgetingVPosStatus} text="" showBadgeDot={false} showStatusDescription={false}>
          {() => <EuroAmount value={record.estimateRestOfCurrentYear} />}
        </DataWithStatus>
      ),
    },
    {
      title: 'Basisbetrag',
      key: 'basisbetrag',
      align: 'right',
      render: (text, record) => (
        <DataWithStatus status={budgetingVPosStatus} text="" showBadgeDot={false} showStatusDescription={false}>
          {() => <EuroAmount value={record.estimateTotalCurrentYear} />}
        </DataWithStatus>
      ),
    },
    {
      title: 'Erhöhung %',
      key: 'erhoehung',
      align: 'right',
      render: (text, record) => (
        <DataWithStatus status={budgetingVPosStatus} text="" showBadgeDot={false} showStatusDescription={false}>
          {() => <Percent value={record.increase / 100} />}
        </DataWithStatus>
      ),
    },
    {
      title: 'Jahresbudget Summe',
      key: 'jahresbudget-summe',
      align: 'right',
      render: (text, record) => (
        <DataWithStatus status={budgetingVPosStatus} text="" showBadgeDot={false} showStatusDescription={false}>
          {() => <EuroAmount value={record.estimateNextYear} />}
        </DataWithStatus>
      ),
    },
    {
      title: '',
      width: 100,
      key: 'action',
    },
  ];
};

export default budgetingAbrKreisTableColumns;
