import { FC } from 'react';
import { HistoryType } from '../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../History/HistoryListingTable';
import { useBudgetingAbrDefVPosLinkageTemplateChangeHistoryListQuery } from '../../gql/budgetingAbrDefVPosLinkageTemplateQueries.types';

type Props = {
  budgetingAbrDefVPosLinkageId: string;
};

const LinkageChangeHistoryListingTable: FC<Props> = ({ budgetingAbrDefVPosLinkageId }) => {
  const { data, loading } = useBudgetingAbrDefVPosLinkageTemplateChangeHistoryListQuery({ variables: { budgetingAbrDefVPosLinkageId } });
  const historyList = data?.getBudgetingAbrDefVPosLinkageTemplateChangeHistoryList.data;

  return (
    <HistoryListingTable
      historyType={HistoryType.Change}
      historyList={historyList}
      loading={loading}
      filterIdPrefix="budgeting-abf-def-vpos-linkage-template"
    />
  );
};

export default LinkageChangeHistoryListingTable;
