import { message } from 'antd';
import { RechtstraegerInfoFeldType } from '../../../../../types';
import { InfoFeldFormValues } from '../../../../shared/InfoFeld/infoFeldFormMapper';
import Fetcher from '../../../../../helpers/fetcher';
import { processAndReturnError, processResponse } from '../../../../../helpers/APIUtils';
import { showSuccessMsgOther } from '../../../../../components/message';
import { mapErrorToErrorMessage } from '../../../../../helpers/errorAndWarningHelper';

export const handleCreateSubmit = (
  firmendatenId: string,
  rechtstraegerId: string,
  type: RechtstraegerInfoFeldType,
  values: InfoFeldFormValues,
  setSubmitting: (isSubmitting: boolean) => void,
  onSuccess: () => void
) => {
  const formData = new FormData();
  formData.append(
    'infoFeld',
    new Blob([JSON.stringify({ data: { ...values, type } })], {
      type: 'application/json',
    }),
    'payload'
  );

  if (values.documentList.length > 0) {
    values.documentList.forEach((document) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      formData.append('documentList', document.file!, document.filename);
    });
  }

  setSubmitting(true);
  Fetcher.getInstance()
    .fetch(`/api/firmendaten/${firmendatenId}/rechtstraeger/${rechtstraegerId}/info-felder`, {
      method: 'POST',
      body: formData,
    })
    .then(processResponse)
    .then(() => {
      showSuccessMsgOther(`Infofeld wurde erfolgreich erstellt`);
      setSubmitting(false);
      onSuccess();
    })
    .catch(processAndReturnError)
    .catch((error) => {
      setSubmitting(false);
      return message.error({
        content: `Infofeld konnte nicht erstellt werden: ${mapErrorToErrorMessage(error)}`,
      });
    });
};

export const handleUpdateSubmit = (
  firmendatenId: string,
  rechtstraegerId: string,
  personInfoFeldId: string,
  type: RechtstraegerInfoFeldType,
  values: InfoFeldFormValues,
  setSubmitting: (isSubmitting: boolean) => void,
  onSuccess: () => void
) => {
  const formData = new FormData();
  formData.append(
    'infoFeld',
    new Blob([JSON.stringify({ data: { ...values, type } })], {
      type: 'application/json',
    }),
    'payload'
  );

  if (values.documentList.length > 0) {
    values.documentList.forEach((document) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      formData.append('documentList', document.file!, document.filename);
    });
  }

  setSubmitting(true);
  Fetcher.getInstance()
    .fetch(`/api/firmendaten/${firmendatenId}/rechtstraeger/${rechtstraegerId}/info-felder/${personInfoFeldId}`, {
      method: 'PUT',
      body: formData,
    })
    .then(processResponse)
    .then(() => {
      showSuccessMsgOther(`Infofeld wurde erfolgreich aktualisiert`);
      setSubmitting(false);
      onSuccess();
    })
    .catch(processAndReturnError)
    .catch((error) => {
      setSubmitting(false);
      return message.error({
        content: `Infofeld konnte nicht aktualisiert werden: ${mapErrorToErrorMessage(error)}`,
      });
    });
};
