import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateFdAbrechnungskreisMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  input: Types.AbrechnungskreisCreateInput;
}>;

export type CreateFdAbrechnungskreisMutation = {
  createFDAbrechnungskreis: {
    data: { abrechnungskreisId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateKsAbrechnungskreisMutationVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
  input: Types.AbrechnungskreisCreateInput;
}>;

export type CreateKsAbrechnungskreisMutation = {
  createKSAbrechnungskreis: {
    data: { abrechnungskreisId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateAbrechnungskreisMutationVariables = Types.Exact<{
  abrechnungskreisId: Types.Scalars['ID']['input'];
  input: Types.AbrechnungskreisUpdateInput;
}>;

export type UpdateAbrechnungskreisMutation = {
  updateAbrechnungskreis: {
    data: { abrechnungskreisId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteAbrechnungskreisMutationVariables = Types.Exact<{
  abrechnungskreisId: Types.Scalars['ID']['input'];
}>;

export type DeleteAbrechnungskreisMutation = {
  deleteAbrechnungskreis: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type ArchiveAbrechnungskreisMutationVariables = Types.Exact<{
  abrechnungskreisId: Types.Scalars['ID']['input'];
}>;

export type ArchiveAbrechnungskreisMutation = {
  actionArchiveAbrechnungskreis: {
    data: { abrechnungskreisId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveAbrechnungskreisMutationVariables = Types.Exact<{
  abrechnungskreisId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveAbrechnungskreisMutation = {
  actionUnarchiveAbrechnungskreis: {
    data: { abrechnungskreisId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const CreateFdAbrechnungskreisDocument = gql`
  mutation CreateFDAbrechnungskreis($firmendatenId: ID!, $input: AbrechnungskreisCreateInput!) {
    createFDAbrechnungskreis(firmendatenId: $firmendatenId, input: $input) {
      data {
        abrechnungskreisId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateFdAbrechnungskreisMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFdAbrechnungskreisMutation, CreateFdAbrechnungskreisMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFdAbrechnungskreisMutation, CreateFdAbrechnungskreisMutationVariables>(CreateFdAbrechnungskreisDocument, options);
}
export type CreateFdAbrechnungskreisMutationHookResult = ReturnType<typeof useCreateFdAbrechnungskreisMutation>;
export type CreateFdAbrechnungskreisMutationResult = Apollo.MutationResult<CreateFdAbrechnungskreisMutation>;
export type CreateFdAbrechnungskreisMutationOptions = Apollo.BaseMutationOptions<
  CreateFdAbrechnungskreisMutation,
  CreateFdAbrechnungskreisMutationVariables
>;
export const CreateKsAbrechnungskreisDocument = gql`
  mutation createKSAbrechnungskreis($kundenSystemId: ID!, $input: AbrechnungskreisCreateInput!) {
    createKSAbrechnungskreis(kundenSystemId: $kundenSystemId, input: $input) {
      data {
        abrechnungskreisId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateKsAbrechnungskreisMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateKsAbrechnungskreisMutation, CreateKsAbrechnungskreisMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateKsAbrechnungskreisMutation, CreateKsAbrechnungskreisMutationVariables>(CreateKsAbrechnungskreisDocument, options);
}
export type CreateKsAbrechnungskreisMutationHookResult = ReturnType<typeof useCreateKsAbrechnungskreisMutation>;
export type CreateKsAbrechnungskreisMutationResult = Apollo.MutationResult<CreateKsAbrechnungskreisMutation>;
export type CreateKsAbrechnungskreisMutationOptions = Apollo.BaseMutationOptions<
  CreateKsAbrechnungskreisMutation,
  CreateKsAbrechnungskreisMutationVariables
>;
export const UpdateAbrechnungskreisDocument = gql`
  mutation UpdateAbrechnungskreis($abrechnungskreisId: ID!, $input: AbrechnungskreisUpdateInput!) {
    updateAbrechnungskreis(abrechnungskreisId: $abrechnungskreisId, input: $input) {
      data {
        abrechnungskreisId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateAbrechnungskreisMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAbrechnungskreisMutation, UpdateAbrechnungskreisMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAbrechnungskreisMutation, UpdateAbrechnungskreisMutationVariables>(UpdateAbrechnungskreisDocument, options);
}
export type UpdateAbrechnungskreisMutationHookResult = ReturnType<typeof useUpdateAbrechnungskreisMutation>;
export type UpdateAbrechnungskreisMutationResult = Apollo.MutationResult<UpdateAbrechnungskreisMutation>;
export type UpdateAbrechnungskreisMutationOptions = Apollo.BaseMutationOptions<
  UpdateAbrechnungskreisMutation,
  UpdateAbrechnungskreisMutationVariables
>;
export const DeleteAbrechnungskreisDocument = gql`
  mutation DeleteAbrechnungskreis($abrechnungskreisId: ID!) {
    deleteAbrechnungskreis(abrechnungskreisId: $abrechnungskreisId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteAbrechnungskreisMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAbrechnungskreisMutation, DeleteAbrechnungskreisMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAbrechnungskreisMutation, DeleteAbrechnungskreisMutationVariables>(DeleteAbrechnungskreisDocument, options);
}
export type DeleteAbrechnungskreisMutationHookResult = ReturnType<typeof useDeleteAbrechnungskreisMutation>;
export type DeleteAbrechnungskreisMutationResult = Apollo.MutationResult<DeleteAbrechnungskreisMutation>;
export type DeleteAbrechnungskreisMutationOptions = Apollo.BaseMutationOptions<
  DeleteAbrechnungskreisMutation,
  DeleteAbrechnungskreisMutationVariables
>;
export const ArchiveAbrechnungskreisDocument = gql`
  mutation ArchiveAbrechnungskreis($abrechnungskreisId: ID!) {
    actionArchiveAbrechnungskreis(abrechnungskreisId: $abrechnungskreisId) {
      data {
        abrechnungskreisId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useArchiveAbrechnungskreisMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveAbrechnungskreisMutation, ArchiveAbrechnungskreisMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveAbrechnungskreisMutation, ArchiveAbrechnungskreisMutationVariables>(ArchiveAbrechnungskreisDocument, options);
}
export type ArchiveAbrechnungskreisMutationHookResult = ReturnType<typeof useArchiveAbrechnungskreisMutation>;
export type ArchiveAbrechnungskreisMutationResult = Apollo.MutationResult<ArchiveAbrechnungskreisMutation>;
export type ArchiveAbrechnungskreisMutationOptions = Apollo.BaseMutationOptions<
  ArchiveAbrechnungskreisMutation,
  ArchiveAbrechnungskreisMutationVariables
>;
export const UnarchiveAbrechnungskreisDocument = gql`
  mutation UnarchiveAbrechnungskreis($abrechnungskreisId: ID!) {
    actionUnarchiveAbrechnungskreis(abrechnungskreisId: $abrechnungskreisId) {
      data {
        abrechnungskreisId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveAbrechnungskreisMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveAbrechnungskreisMutation, UnarchiveAbrechnungskreisMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveAbrechnungskreisMutation, UnarchiveAbrechnungskreisMutationVariables>(
    UnarchiveAbrechnungskreisDocument,
    options
  );
}
export type UnarchiveAbrechnungskreisMutationHookResult = ReturnType<typeof useUnarchiveAbrechnungskreisMutation>;
export type UnarchiveAbrechnungskreisMutationResult = Apollo.MutationResult<UnarchiveAbrechnungskreisMutation>;
export type UnarchiveAbrechnungskreisMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveAbrechnungskreisMutation,
  UnarchiveAbrechnungskreisMutationVariables
>;
