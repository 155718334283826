import { ITimeblock } from '../../../../../components/Timeline/timelineCtx';
import { FormFields, mapFormDateValueToDateString, TFormattedDecimal } from '../../../../../helpers/formikHelper';
import { BENutzwertVersionTimelineDataSourceEntry } from '../nutzwertVersionTimelineMapper';
import { BestandseinheitNutzwertVersionInput } from '../../../../../types';
import { ValuesDescriptionForKommentar } from '../../../../../components/Timeline/versionTimelineHelper';
import { mapFormattedDecimalOrThrowIfEmpty } from '../../../../../components/Input-antd/formattedDecimalHelper';

export type NutzwertVersionFormValues = {
  validFrom: string;
  kommentar: string;
  nutzwert: TFormattedDecimal;
};

export const nutzwertVersionFormFields: FormFields<NutzwertVersionFormValues> = {
  validFrom: 'validFrom',
  kommentar: 'kommentar',
  nutzwert: 'nutzwert',
};

export const mapTimeblockToFormValues = (timeblock: ITimeblock<BENutzwertVersionTimelineDataSourceEntry>): NutzwertVersionFormValues => ({
  validFrom: timeblock.validFrom,
  kommentar: timeblock.kommentar ?? '',
  nutzwert: timeblock.nutzwert,
});

export const mapFormValuesToBestandseinheitVersion = (values: NutzwertVersionFormValues): BestandseinheitNutzwertVersionInput => ({
  validFrom: mapFormDateValueToDateString(values.validFrom),
  kommentar: values.kommentar,
  nutzwert: mapFormattedDecimalOrThrowIfEmpty(values.nutzwert),
});

export const nutzwertVersionFormValuesDescriptionsForKommentar: ValuesDescriptionForKommentar<NutzwertVersionFormValues> = {
  validFrom: 'Gültigkeitsdatum',
  nutzwert: 'Nutzwert',
};
