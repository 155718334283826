import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { TextbausteinFieldsFragmentDoc } from '../../../../components/Template/PDFTemplates/gql/TemplateFragments.types';
import { VorschreibungMailDeliveryFieldsFragmentDoc } from '../../../EmailDelivery/VorschreibungMailDelivery/gql/VorschreibungMailDeliveryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateVorschreibungMailDeliveryWizardMutationVariables = Types.Exact<{
  emailDeliveryDefinitionId: Types.Scalars['ID']['input'];
}>;

export type CreateVorschreibungMailDeliveryWizardMutation = {
  createVorschreibungMailDeliveryWizard: {
    data: {
      completed: boolean;
      currentStep: number;
      emailDeliveryDefinitionBezeichnung: string;
      stepCount: number;
      wizardId: string;
      attachmentInfoList: Array<{ fileId: string; name: string; size: number }>;
      body?: {
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      } | null;
      subject: {
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateVorschreibungMailDeliveryMutationVariables = Types.Exact<{
  vorschreibungMailDeliveryWizardId: Types.Scalars['String']['input'];
  vorschreibungMailDeliveryId: Types.Scalars['ID']['input'];
  shouldSend: Types.Scalars['Boolean']['input'];
  shouldNeverSend: Types.Scalars['Boolean']['input'];
}>;

export type UpdateVorschreibungMailDeliveryMutation = {
  updateVorschreibungMailDelivery: {
    data: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      emailAddress: string;
      emailDeliveryDefinitionBezeichnung: string;
      emailDeliveryDefinitionId: string;
      mailId?: string | null;
      original: boolean;
      sender?: string | null;
      sentTs?: string | null;
      shouldNeverSend: boolean;
      shouldSend: boolean;
      updatedByMitarbeiterId?: string | null;
      vorschreibungMailDeliveryId: string;
      emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
      fakturierungsperiode: { jahr: number; monat: number };
      objektBestandseinheitVertragList: Array<{
        vertragId: string;
        bestandseinheit: {
          bestandseinheitId: string;
          bezeichnung: string;
          address: {
            addressId: string;
            city: string;
            cityAdditionalInformation?: string | null;
            countryCodeIso2: string;
            houseEntranceApartmentNumber?: string | null;
            postofficeBoxNumber?: string | null;
            postofficeBoxZipCode?: string | null;
            street?: string | null;
            type: Types.AddressType;
            zipCode?: string | null;
          };
        };
        objekt: { kurzBezeichnung: string; objektId: string };
      }>;
      recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
      vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteVorschreibungMailDeliveryWizardMutationVariables = Types.Exact<{
  vorschreibungMailDeliveryWizardId: Types.Scalars['ID']['input'];
}>;

export type DeleteVorschreibungMailDeliveryWizardMutation = {
  deleteVorschreibungMailDeliveryWizard: {
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteVorschreibungMailDeliveryAttachmentMutationVariables = Types.Exact<{
  vorschreibungMailDeliveryWizardId: Types.Scalars['String']['input'];
  vorschreibungMailDeliveryId: Types.Scalars['String']['input'];
  attachmentId: Types.Scalars['ID']['input'];
}>;

export type DeleteVorschreibungMailDeliveryAttachmentMutation = {
  deleteVorschreibungMailDeliveryAttachment: {
    data: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      emailAddress: string;
      emailDeliveryDefinitionBezeichnung: string;
      emailDeliveryDefinitionId: string;
      mailId?: string | null;
      original: boolean;
      sender?: string | null;
      sentTs?: string | null;
      shouldNeverSend: boolean;
      shouldSend: boolean;
      updatedByMitarbeiterId?: string | null;
      vorschreibungMailDeliveryId: string;
      emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
      fakturierungsperiode: { jahr: number; monat: number };
      objektBestandseinheitVertragList: Array<{
        vertragId: string;
        bestandseinheit: {
          bestandseinheitId: string;
          bezeichnung: string;
          address: {
            addressId: string;
            city: string;
            cityAdditionalInformation?: string | null;
            countryCodeIso2: string;
            houseEntranceApartmentNumber?: string | null;
            postofficeBoxNumber?: string | null;
            postofficeBoxZipCode?: string | null;
            street?: string | null;
            type: Types.AddressType;
            zipCode?: string | null;
          };
        };
        objekt: { kurzBezeichnung: string; objektId: string };
      }>;
      recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
      vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CompleteVorschreibungMailDetermineRecipientListStepMutationVariables = Types.Exact<{
  vorschreibungMailDeliveryWizardId: Types.Scalars['ID']['input'];
}>;

export type CompleteVorschreibungMailDetermineRecipientListStepMutation = {
  actionCompleteVorschreibungMailDetermineRecipientListStep: {
    data: { completed: boolean; currentStep: number; generierlaufId: string; wizardId: string; stepCount: number };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SendVorschreibungMailMutationVariables = Types.Exact<{
  vorschreibungMailWizardId: Types.Scalars['ID']['input'];
}>;

export type SendVorschreibungMailMutation = {
  actionSendVorschreibungMail: {
    data: { completed: boolean; currentStep: number; sendGenerierlaufId?: string | null; stepCount: number; wizardId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const CreateVorschreibungMailDeliveryWizardDocument = gql`
  mutation createVorschreibungMailDeliveryWizard($emailDeliveryDefinitionId: ID!) {
    createVorschreibungMailDeliveryWizard(emailDeliveryDefinitionId: $emailDeliveryDefinitionId) {
      data {
        attachmentInfoList {
          fileId
          name
          size
        }
        completed
        currentStep
        body {
          ...TextbausteinFields
          textbausteinList {
            ...TextbausteinFields
          }
        }
        emailDeliveryDefinitionBezeichnung
        emailDeliveryDefinitionBezeichnung
        stepCount
        subject {
          ...TextbausteinFields
          textbausteinList {
            ...TextbausteinFields
          }
        }
        wizardId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${TextbausteinFieldsFragmentDoc}
`;
export function useCreateVorschreibungMailDeliveryWizardMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateVorschreibungMailDeliveryWizardMutation, CreateVorschreibungMailDeliveryWizardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVorschreibungMailDeliveryWizardMutation, CreateVorschreibungMailDeliveryWizardMutationVariables>(
    CreateVorschreibungMailDeliveryWizardDocument,
    options
  );
}
export type CreateVorschreibungMailDeliveryWizardMutationHookResult = ReturnType<typeof useCreateVorschreibungMailDeliveryWizardMutation>;
export type CreateVorschreibungMailDeliveryWizardMutationResult = Apollo.MutationResult<CreateVorschreibungMailDeliveryWizardMutation>;
export type CreateVorschreibungMailDeliveryWizardMutationOptions = Apollo.BaseMutationOptions<
  CreateVorschreibungMailDeliveryWizardMutation,
  CreateVorschreibungMailDeliveryWizardMutationVariables
>;
export const UpdateVorschreibungMailDeliveryDocument = gql`
  mutation updateVorschreibungMailDelivery(
    $vorschreibungMailDeliveryWizardId: String!
    $vorschreibungMailDeliveryId: ID!
    $shouldSend: Boolean!
    $shouldNeverSend: Boolean!
  ) {
    updateVorschreibungMailDelivery(
      vorschreibungMailDeliveryWizardId: $vorschreibungMailDeliveryWizardId
      vorschreibungMailDeliveryId: $vorschreibungMailDeliveryId
      shouldSend: $shouldSend
      shouldNeverSend: $shouldNeverSend
    ) {
      data {
        ...VorschreibungMailDeliveryFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VorschreibungMailDeliveryFieldsFragmentDoc}
`;
export function useUpdateVorschreibungMailDeliveryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateVorschreibungMailDeliveryMutation, UpdateVorschreibungMailDeliveryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateVorschreibungMailDeliveryMutation, UpdateVorschreibungMailDeliveryMutationVariables>(
    UpdateVorschreibungMailDeliveryDocument,
    options
  );
}
export type UpdateVorschreibungMailDeliveryMutationHookResult = ReturnType<typeof useUpdateVorschreibungMailDeliveryMutation>;
export type UpdateVorschreibungMailDeliveryMutationResult = Apollo.MutationResult<UpdateVorschreibungMailDeliveryMutation>;
export type UpdateVorschreibungMailDeliveryMutationOptions = Apollo.BaseMutationOptions<
  UpdateVorschreibungMailDeliveryMutation,
  UpdateVorschreibungMailDeliveryMutationVariables
>;
export const DeleteVorschreibungMailDeliveryWizardDocument = gql`
  mutation deleteVorschreibungMailDeliveryWizard($vorschreibungMailDeliveryWizardId: ID!) {
    deleteVorschreibungMailDeliveryWizard(vorschreibungMailDeliveryWizardId: $vorschreibungMailDeliveryWizardId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteVorschreibungMailDeliveryWizardMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteVorschreibungMailDeliveryWizardMutation, DeleteVorschreibungMailDeliveryWizardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVorschreibungMailDeliveryWizardMutation, DeleteVorschreibungMailDeliveryWizardMutationVariables>(
    DeleteVorschreibungMailDeliveryWizardDocument,
    options
  );
}
export type DeleteVorschreibungMailDeliveryWizardMutationHookResult = ReturnType<typeof useDeleteVorschreibungMailDeliveryWizardMutation>;
export type DeleteVorschreibungMailDeliveryWizardMutationResult = Apollo.MutationResult<DeleteVorschreibungMailDeliveryWizardMutation>;
export type DeleteVorschreibungMailDeliveryWizardMutationOptions = Apollo.BaseMutationOptions<
  DeleteVorschreibungMailDeliveryWizardMutation,
  DeleteVorschreibungMailDeliveryWizardMutationVariables
>;
export const DeleteVorschreibungMailDeliveryAttachmentDocument = gql`
  mutation deleteVorschreibungMailDeliveryAttachment(
    $vorschreibungMailDeliveryWizardId: String!
    $vorschreibungMailDeliveryId: String!
    $attachmentId: ID!
  ) {
    deleteVorschreibungMailDeliveryAttachment(
      vorschreibungMailDeliveryWizardId: $vorschreibungMailDeliveryWizardId
      vorschreibungMailDeliveryId: $vorschreibungMailDeliveryId
      attachmentId: $attachmentId
    ) {
      data {
        ...VorschreibungMailDeliveryFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VorschreibungMailDeliveryFieldsFragmentDoc}
`;
export function useDeleteVorschreibungMailDeliveryAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVorschreibungMailDeliveryAttachmentMutation,
    DeleteVorschreibungMailDeliveryAttachmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVorschreibungMailDeliveryAttachmentMutation, DeleteVorschreibungMailDeliveryAttachmentMutationVariables>(
    DeleteVorschreibungMailDeliveryAttachmentDocument,
    options
  );
}
export type DeleteVorschreibungMailDeliveryAttachmentMutationHookResult = ReturnType<typeof useDeleteVorschreibungMailDeliveryAttachmentMutation>;
export type DeleteVorschreibungMailDeliveryAttachmentMutationResult = Apollo.MutationResult<DeleteVorschreibungMailDeliveryAttachmentMutation>;
export type DeleteVorschreibungMailDeliveryAttachmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteVorschreibungMailDeliveryAttachmentMutation,
  DeleteVorschreibungMailDeliveryAttachmentMutationVariables
>;
export const CompleteVorschreibungMailDetermineRecipientListStepDocument = gql`
  mutation CompleteVorschreibungMailDetermineRecipientListStep($vorschreibungMailDeliveryWizardId: ID!) {
    actionCompleteVorschreibungMailDetermineRecipientListStep(vorschreibungMailDeliveryWizardId: $vorschreibungMailDeliveryWizardId) {
      data {
        completed
        currentStep
        generierlaufId
        wizardId
        stepCount
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCompleteVorschreibungMailDetermineRecipientListStepMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteVorschreibungMailDetermineRecipientListStepMutation,
    CompleteVorschreibungMailDetermineRecipientListStepMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteVorschreibungMailDetermineRecipientListStepMutation,
    CompleteVorschreibungMailDetermineRecipientListStepMutationVariables
  >(CompleteVorschreibungMailDetermineRecipientListStepDocument, options);
}
export type CompleteVorschreibungMailDetermineRecipientListStepMutationHookResult = ReturnType<
  typeof useCompleteVorschreibungMailDetermineRecipientListStepMutation
>;
export type CompleteVorschreibungMailDetermineRecipientListStepMutationResult =
  Apollo.MutationResult<CompleteVorschreibungMailDetermineRecipientListStepMutation>;
export type CompleteVorschreibungMailDetermineRecipientListStepMutationOptions = Apollo.BaseMutationOptions<
  CompleteVorschreibungMailDetermineRecipientListStepMutation,
  CompleteVorschreibungMailDetermineRecipientListStepMutationVariables
>;
export const SendVorschreibungMailDocument = gql`
  mutation sendVorschreibungMail($vorschreibungMailWizardId: ID!) {
    actionSendVorschreibungMail(vorschreibungMailDeliveryWizardId: $vorschreibungMailWizardId) {
      data {
        completed
        currentStep
        sendGenerierlaufId
        stepCount
        wizardId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useSendVorschreibungMailMutation(
  baseOptions?: Apollo.MutationHookOptions<SendVorschreibungMailMutation, SendVorschreibungMailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendVorschreibungMailMutation, SendVorschreibungMailMutationVariables>(SendVorschreibungMailDocument, options);
}
export type SendVorschreibungMailMutationHookResult = ReturnType<typeof useSendVorschreibungMailMutation>;
export type SendVorschreibungMailMutationResult = Apollo.MutationResult<SendVorschreibungMailMutation>;
export type SendVorschreibungMailMutationOptions = Apollo.BaseMutationOptions<SendVorschreibungMailMutation, SendVorschreibungMailMutationVariables>;
