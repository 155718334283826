import React from 'react';
import { Space, Spin } from 'antd';
import { useFibuEinstellungQuery } from './gql/FibuEinstellungQueries.types';
import NegierteHabenBetraege from './NegierteHabenBetraege';
import LeerstehungCard from './Leerstehung/LeerstehungCard';
import { useFirmendatenBuchhaltungEinstellungQuery } from './gql/FirmendatenBuchhaltungEinstellungQueries.types';
import AufwandAbgrenzungskonto from './AufwandAbgrenzungskonto/AufwandAbgrenzungskonto';
import PaulschalmieteCard from './Paulschalmiete/PaulschalmieteCard';
import BillingAccountCard from './BillingAccount/BillingAccountCard';

const AllgemeineEinstellung = () => {
  const { data: fibuEinstellungData, refetch: fibuEinstellungRefetch } = useFibuEinstellungQuery();
  const fibuEinstellung = fibuEinstellungData?.getFibuEinstellung.data;

  const { data: firmendatenBuchhaltungEinstellungData, refetch: firmendatenBuchhaltungEinstellungRefetch } =
    useFirmendatenBuchhaltungEinstellungQuery();

  const firmendatenBuchhaltungEinstellung = firmendatenBuchhaltungEinstellungData?.getFirmendatenBuchhaltungEinstellung.data;

  if (!fibuEinstellung || !firmendatenBuchhaltungEinstellung) {
    return <Spin />;
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <NegierteHabenBetraege negierteHabenBetraege={fibuEinstellung.negierteHabenBetraege} onSuccess={fibuEinstellungRefetch} />
      <AufwandAbgrenzungskonto
        firmendatenBuchhaltungEinstellung={firmendatenBuchhaltungEinstellung}
        onSuccess={firmendatenBuchhaltungEinstellungRefetch}
      />
      <BillingAccountCard
        firmendatenBuchhaltungEinstellung={firmendatenBuchhaltungEinstellung}
        onSuccess={firmendatenBuchhaltungEinstellungRefetch}
      />
      <LeerstehungCard firmendatenBuchhaltungEinstellung={firmendatenBuchhaltungEinstellung} onSuccess={firmendatenBuchhaltungEinstellungRefetch} />
      <PaulschalmieteCard
        firmendatenBuchhaltungEinstellung={firmendatenBuchhaltungEinstellung}
        onSuccess={firmendatenBuchhaltungEinstellungRefetch}
      />
    </Space>
  );
};

export default AllgemeineEinstellung;
