import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Select } from 'formik-antd';
import { Col, Divider, Row, Typography } from 'antd';
import { personenGemeinschaftFormValidationSchema } from './personenGemeinschaftFormValidationSchema';
import {
  mapFormValuesToPersonenGemeinschaftCreate,
  mapFormValuesToPersonenGemeinschaftUpdate,
  mapPersonenGemeinschaftToFormValues,
  personGemeinschaftFormFields,
  PersonGemeinschaftFormValues,
} from './personenGemeinschaftFormMapper';
import {
  getKurzBezeichnungVorschlagFieldsNotToBeDebounced,
  getKurzBezeichnungVorschlagFieldsToBeDebounced,
  getPersonenGemeinschaftKurzBezeichnungVorschlag,
  updateFirmennameAndAddress,
} from './personenGemeinschaftFormHelper';
import FormButtons from '../../../../components/Button/FormButtons';
import SpracheSelect from '../../Sprache/SpracheSelect';
import PersonsCommunityTypeSelect from '../PersonsCommunityTypeSelect/PersonsCommunityTypeSelect';
import FirmennameFormPart from '../../../../shared/components/Firmenname/form/FirmennameFormPart';
import SalutationSelect from '../../../../shared/components/Salutation/SalutationSelect';
import { useCreatePersonenGemeinschaftMutation, useUpdatePersonenGemeinschaftMutation } from '../gql/PersonenGemeinschaftMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import VatIdNumberFormPart from '../../../../shared/components/VatIdentificationNumber/form/VatIdNumberFormPart';
import KurzBezeichnungFormPart from '../../../../shared/components/KurzBezeichnung/form/KurzBezeichnungFormPart';
import HauptAddressOptionalFormPart from '../../HauptAddress/HauptAddressOptionalFormPart';
import SteuerpflichtSelect from '../../../../shared/components/SteuerpflichtSelect/SteuerpflichtSelect';
import { showSuccessMsgCreateWithPath, showSuccessMsgUpdate } from '../../../../components/message/message';
import { generatePathToRechtstraegerDetailsPage } from '../../rechtstraegerHelper';
import { Personengemeinschaft, RechtstraegerType } from '../../../../types';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';

type PersonenGemeinschaftFormProps = {
  personenGemeinschaft?: Personengemeinschaft;
  kundenSystemId: string;
  onSuccess: (rechtstraegerId: string, kurzBezeichnung: string, type: { value: RechtstraegerType }) => void;
  onCancel: () => void;
};

const PersonenGemeinschaftForm: FC<PersonenGemeinschaftFormProps> = ({ personenGemeinschaft, kundenSystemId, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'RechtstraegerPersonenGemeinschaft'>('RechtstraegerPersonenGemeinschaft');

  const initialValues = mapPersonenGemeinschaftToFormValues(personenGemeinschaft);
  const isUpdate = !!personenGemeinschaft;

  const entity = `Personengemeinschaft`;

  const [runCreate, { loading: loadingCreate }] = useCreatePersonenGemeinschaftMutation({
    onCompleted: (data) => {
      const { rechtstraegerId, kurzBezeichnung, type } = data.createPersonenGemeinschaft.data;
      showSuccessMsgCreateWithPath(entity, kurzBezeichnung, generatePathToRechtstraegerDetailsPage(rechtstraegerId));
      onSuccess(rechtstraegerId, kurzBezeichnung, type);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdatePersonenGemeinschaftMutation({
    onCompleted: (data) => {
      const { rechtstraegerId, kurzBezeichnung, type } = data.updatePersonenGemeinschaft.data;
      showSuccessMsgUpdate(entity);
      onSuccess(rechtstraegerId, kurzBezeichnung, type);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<PersonGemeinschaftFormValues>
      initialValues={initialValues}
      validateOnChange={false}
      validationSchema={personenGemeinschaftFormValidationSchema}
      onSubmit={(formValues, formikHelpers) => {
        if (!isUpdate) {
          runCreate({ variables: { request: mapFormValuesToPersonenGemeinschaftCreate(formValues) } }).finally(() =>
            formikHelpers.setSubmitting(false)
          );
        } else {
          runUpdate({
            variables: {
              request: mapFormValuesToPersonenGemeinschaftUpdate(formValues),
              personenGemeinschaftId: personenGemeinschaft.rechtstraegerId,
            },
          }).finally(() => formikHelpers.setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Einstellungen</Typography.Title>
            </Col>
            <Col span={18}>
              <SpracheSelect fieldHelp={getFieldHelpText('RechtstraegerPersonenGemeinschaft.sprache')} />
              <FormItemWithFieldHelp
                label="Merkmale"
                name={personGemeinschaftFormFields.tagList}
                fieldHelp={getFieldHelpText('RechtstraegerPersonenGemeinschaft.tagList')}
              >
                <Select
                  id={personGemeinschaftFormFields.tagList}
                  name={personGemeinschaftFormFields.tagList}
                  mode="tags"
                  placeholder="z.B. Berufsgruppe, Weihnachskarte"
                />
              </FormItemWithFieldHelp>
            </Col>
          </Row>
          <Divider />
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Allgemeine Daten</Typography.Title>
            </Col>
            <Col span={18}>
              <VatIdNumberFormPart
                onValidationSuccess={updateFirmennameAndAddress(formikProps)}
                formikProps={formikProps}
                fieldHelp={getFieldHelpText('RechtstraegerPersonenGemeinschaft.vatIdentificationNumber')}
              />
              <PersonsCommunityTypeSelect name="communityType" fieldHelp={getFieldHelpText('RechtstraegerPersonenGemeinschaft.communityType')} />
            </Col>
          </Row>
          <Divider />
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Etikett</Typography.Title>
            </Col>
            <Col span={18}>
              <SalutationSelect
                name="salutation"
                kundenSystemId={kundenSystemId}
                fieldHelp={getFieldHelpText('RechtstraegerPersonenGemeinschaft.salutation')}
              />
              <FirmennameFormPart
                fieldHelpNames={{
                  name1: getFieldHelpText('RechtstraegerPersonenGemeinschaft.name1'),
                  name2: getFieldHelpText('RechtstraegerPersonenGemeinschaft.name2'),
                  name3: getFieldHelpText('RechtstraegerPersonenGemeinschaft.name3'),
                }}
              />
              <KurzBezeichnungFormPart<PersonGemeinschaftFormValues>
                placeholder="Kurzbezeichnung"
                kurzBezeichnungVorschlagConfig={{
                  getFieldsToBeDebounced: getKurzBezeichnungVorschlagFieldsToBeDebounced,
                  getFieldsNotToBeDebounced: getKurzBezeichnungVorschlagFieldsNotToBeDebounced,
                  getKurzBezeichnungVorschlagFn: getPersonenGemeinschaftKurzBezeichnungVorschlag,
                }}
                formikProps={formikProps}
                fieldHelp={getFieldHelpText('RechtstraegerPersonenGemeinschaft.kurzBezeichnung')}
              />
            </Col>
          </Row>
          {!isUpdate && (
            <>
              <Divider />
              <Row align="top">
                <Col span={6}>
                  <h3>Hauptadresse</h3>
                </Col>
                <Col span={18}>
                  <HauptAddressOptionalFormPart
                    fieldHelpNames={{
                      city: getFieldHelpText('RechtstraegerPersonenGemeinschaft.hauptAddress.city'),
                      cityAdditionalInformation: getFieldHelpText('RechtstraegerPersonenGemeinschaft.hauptAddress.cityAdditionalInformation'),
                      countryCodeIso2: getFieldHelpText('RechtstraegerPersonenGemeinschaft.hauptAddress.countryCodeIso2'),
                      houseEntranceApartmentNumber: getFieldHelpText('RechtstraegerPersonenGemeinschaft.hauptAddress.houseEntranceApartmentNumber'),
                      street: getFieldHelpText('RechtstraegerPersonenGemeinschaft.hauptAddress.street'),
                      zipCode: getFieldHelpText('RechtstraegerPersonenGemeinschaft.hauptAddress.zipCode'),
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          {!isUpdate && (
            <>
              <Divider />
              <Row align="top">
                <Col span={6}>
                  <Typography.Title level={5}>Finanz/Steuerparameter</Typography.Title>
                </Col>
                <Col span={18}>
                  <SteuerpflichtSelect fieldHelp={getFieldHelpText('RechtstraegerPersonenGemeinschaft.steuerpflicht.steuerpflicht')} />
                </Col>
              </Row>
            </>
          )}
          <FormButtons
            updateMode={isUpdate}
            isOkDisabled={formikProps.isSubmitting}
            onCancel={onCancel}
            isOkLoading={loadingUpdate || loadingCreate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default PersonenGemeinschaftForm;
