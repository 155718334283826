import React, { FC, useState } from 'react';
import { Tabs, TabsProps } from 'antd';
import MahndefinitionUpdateParamForm from '../../ParameterForm/Update/MahndefinitionUpdateParamForm';
import MahndefinitionUpdateTemplateForm from './MahndefinitionUpdateTemplateForm';
import { useUpdateMahndefinitionMutation } from '../../gql/MahndefinitionMutations.types';
import { showSuccessMsgUpdate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { mapTextbausteinListToFormValues, TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';
import { MahndefinitionParamFormValues, mapFormValuesToMahndefinition, mapMahndefinitionToParamFormValues } from '../../mahndefinitionFormMapper';
import { MahndefinitionUpdateInput } from '../../../../types';
import TabPaneContent from '../../../../components/Tabs/TabPaneContent';
import { MahndefinitionFieldsFragment } from '../../gql/MahndefinitionFragments.types';

type Props = {
  mahndefinition: MahndefinitionFieldsFragment;
};

const MahndefinitionUpdateForm: FC<Props> = ({ mahndefinition }) => {
  const [mahnstufe1TemplateValues, setMahnstufe1TemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(mahndefinition.mahnstufe1TextbausteinList)
  );
  const [mahnstufe2TemplateValues, setMahnstufe2TemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(mahndefinition.mahnstufe2TextbausteinList)
  );
  const [mahnstufe3TemplateValues, setMahnstufe3TemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(mahndefinition.mahnstufe3TextbausteinList)
  );
  const [mahnstufe4TemplateValues, setMahnstufe4TemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(mahndefinition.mahnstufe4TextbausteinList)
  );

  const [isVersandEinschreiben1, setIsVersandEinschreiben1] = useState(mahndefinition.mahnstufe1.versandEinschreiben);
  const [isVersandEinschreiben2, setIsVersandEinschreiben2] = useState(mahndefinition.mahnstufe2.versandEinschreiben);
  const [isVersandEinschreiben3, setIsVersandEinschreiben3] = useState(mahndefinition.mahnstufe3.versandEinschreiben);
  const [isVersandEinschreiben4, setIsVersandEinschreiben4] = useState(mahndefinition.mahnstufe4.versandEinschreiben);

  const [updateMahndefinition] = useUpdateMahndefinitionMutation({
    onCompleted: () => {
      showSuccessMsgUpdate('Mahndefinition');
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onSubmit = (paramFormValues?: MahndefinitionParamFormValues) => {
    const mahndefinitionParamFormValues = paramFormValues ?? mapMahndefinitionToParamFormValues(mahndefinition);

    const mahndefinitionUpdateInputValues = mapFormValuesToMahndefinition(
      mahndefinitionParamFormValues,
      mahnstufe1TemplateValues,
      mahnstufe2TemplateValues,
      mahnstufe3TemplateValues,
      mahnstufe4TemplateValues
    );
    updateMahndefinition({
      variables: {
        mahndefinitionId: mahndefinition.mahndefinitionId,
        input: mahndefinitionUpdateInputValues as MahndefinitionUpdateInput,
      },
    });
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Mahnstufen',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <MahndefinitionUpdateParamForm
            mahndefinition={mahndefinition}
            onFormSubmit={onSubmit}
            setIsVersandEinschreiben1={setIsVersandEinschreiben1}
            setIsVersandEinschreiben2={setIsVersandEinschreiben2}
            setIsVersandEinschreiben3={setIsVersandEinschreiben3}
            setIsVersandEinschreiben4={setIsVersandEinschreiben4}
          />
        </TabPaneContent>
      ),
    },
    {
      key: '2',
      label: 'Mahnungen',
      children: (
        <MahndefinitionUpdateTemplateForm
          mahnstufe1TemplateValues={mahnstufe1TemplateValues}
          onMahnstufe1TemplateValuesChange={setMahnstufe1TemplateValues}
          mahnstufe2TemplateValues={mahnstufe2TemplateValues}
          onMahnstufe2TemplateValuesChange={setMahnstufe2TemplateValues}
          mahnstufe3TemplateValues={mahnstufe3TemplateValues}
          onMahnstufe3TemplateValuesChange={setMahnstufe3TemplateValues}
          mahnstufe4TemplateValues={mahnstufe4TemplateValues}
          onMahnstufe4TemplateValuesChange={setMahnstufe4TemplateValues}
          onFormSubmit={onSubmit}
          isVersandEinschreiben1={isVersandEinschreiben1}
          isVersandEinschreiben2={isVersandEinschreiben2}
          isVersandEinschreiben3={isVersandEinschreiben3}
          isVersandEinschreiben4={isVersandEinschreiben4}
        />
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default MahndefinitionUpdateForm;
