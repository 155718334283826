import React, { FC } from 'react';
import { Typography } from 'antd';
import theme from '../../../theme';
import { isNotNil } from '../../../helpers/assertionHelper';

type Props = {
  noted: boolean;
  content: React.ReactNode;
  isCurrent?: boolean;
};

const fontWeightForNotedRecord = (noted: boolean) => {
  return noted ? 'normal' : 'bold';
};

const TextAndFontWeightForNotedRecord: FC<Props> = ({ noted, content, isCurrent }) => {
  const colorToUse = isNotNil(isCurrent) && !isCurrent ? theme.textColor.disabled : 'inherit';

  return <Typography.Text style={{ fontWeight: fontWeightForNotedRecord(noted), color: colorToUse }}>{content}</Typography.Text>;
};

export { fontWeightForNotedRecord, TextAndFontWeightForNotedRecord };
