import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { FirmendatenFieldsFragmentDoc } from '../../../Firmendaten/gql/FirmendatenFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FirmendatenQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
}>;

export type FirmendatenQuery = {
  getFirmendaten: {
    data: {
      companyRegistrationNumber?: string | null;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      firmendatenId: string;
      kundenSystemId: string;
      kurzBezeichnung: string;
      name1: string;
      name2?: string | null;
      name3?: string | null;
      sepaCreditorId?: string | null;
      vatIdentificationNumber?: string | null;
      firmeneinstellung: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        dueDateVorschreibung: { text: string; value: string };
      };
      legalForm: { text: string; value: Types.LegalForm };
      rolleList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        name: string;
        rolleId: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      status: { text: string; value: Types.FirmendatenStatus; description?: string | null };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FirmeneinstellungenQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
}>;

export type FirmeneinstellungenQuery = {
  getFirmeneinstellung: {
    data: { createTs: string; createdBy: string; createdByMitarbeiterId?: string | null; dueDateVorschreibung: { text: string; value: string } };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FirmendatenContactPersonDeletionHistoryListQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
}>;

export type FirmendatenContactPersonDeletionHistoryListQuery = {
  getFirmendatenContactPersonDeletionHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type FirmendatenEmailContactDeletionHistoryListQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
}>;

export type FirmendatenEmailContactDeletionHistoryListQuery = {
  getFirmendatenEmailContactDeletionHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type FirmendatenOperatingSiteDeletionHistoryListQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
}>;

export type FirmendatenOperatingSiteDeletionHistoryListQuery = {
  getFirmendatenOperatingSiteDeletionHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type FirmendatenPhoneContactDeletionHistoryListQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
}>;

export type FirmendatenPhoneContactDeletionHistoryListQuery = {
  getFirmendatenPhoneContactDeletionHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type FirmendatenPostofficeBoxAddressDeletionHistoryListQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
}>;

export type FirmendatenPostofficeBoxAddressDeletionHistoryListQuery = {
  getFirmendatenPostofficeBoxAddressDeletionHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type FirmendatenStreetAddressDeletionHistoryListQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
}>;

export type FirmendatenStreetAddressDeletionHistoryListQuery = {
  getFirmendatenStreetAddressDeletionHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type FirmendatenObjektVorschreibungspositionNameListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FirmendatenObjektVorschreibungspositionNameListQuery = {
  getFirmendatenObjektVorschreibungspositionNameList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const FirmendatenDocument = gql`
  query Firmendaten($firmendatenId: ID!) {
    getFirmendaten(firmendatenId: $firmendatenId) {
      data {
        ...FirmendatenFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${FirmendatenFieldsFragmentDoc}
`;
export function useFirmendatenQuery(
  baseOptions: Apollo.QueryHookOptions<FirmendatenQuery, FirmendatenQueryVariables> &
    ({ variables: FirmendatenQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenQuery, FirmendatenQueryVariables>(FirmendatenDocument, options);
}
export function useFirmendatenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenQuery, FirmendatenQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenQuery, FirmendatenQueryVariables>(FirmendatenDocument, options);
}
export function useFirmendatenSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FirmendatenQuery, FirmendatenQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenQuery, FirmendatenQueryVariables>(FirmendatenDocument, options);
}
export type FirmendatenQueryHookResult = ReturnType<typeof useFirmendatenQuery>;
export type FirmendatenLazyQueryHookResult = ReturnType<typeof useFirmendatenLazyQuery>;
export type FirmendatenSuspenseQueryHookResult = ReturnType<typeof useFirmendatenSuspenseQuery>;
export type FirmendatenQueryResult = Apollo.QueryResult<FirmendatenQuery, FirmendatenQueryVariables>;
export const FirmeneinstellungenDocument = gql`
  query Firmeneinstellungen($firmendatenId: ID!) {
    getFirmeneinstellung(firmendatenId: $firmendatenId) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
        dueDateVorschreibung {
          text
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFirmeneinstellungenQuery(
  baseOptions: Apollo.QueryHookOptions<FirmeneinstellungenQuery, FirmeneinstellungenQueryVariables> &
    ({ variables: FirmeneinstellungenQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmeneinstellungenQuery, FirmeneinstellungenQueryVariables>(FirmeneinstellungenDocument, options);
}
export function useFirmeneinstellungenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmeneinstellungenQuery, FirmeneinstellungenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmeneinstellungenQuery, FirmeneinstellungenQueryVariables>(FirmeneinstellungenDocument, options);
}
export function useFirmeneinstellungenSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FirmeneinstellungenQuery, FirmeneinstellungenQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmeneinstellungenQuery, FirmeneinstellungenQueryVariables>(FirmeneinstellungenDocument, options);
}
export type FirmeneinstellungenQueryHookResult = ReturnType<typeof useFirmeneinstellungenQuery>;
export type FirmeneinstellungenLazyQueryHookResult = ReturnType<typeof useFirmeneinstellungenLazyQuery>;
export type FirmeneinstellungenSuspenseQueryHookResult = ReturnType<typeof useFirmeneinstellungenSuspenseQuery>;
export type FirmeneinstellungenQueryResult = Apollo.QueryResult<FirmeneinstellungenQuery, FirmeneinstellungenQueryVariables>;
export const FirmendatenContactPersonDeletionHistoryListDocument = gql`
  query FirmendatenContactPersonDeletionHistoryList($firmendatenId: ID!) {
    getFirmendatenContactPersonDeletionHistoryList(firmendatenId: $firmendatenId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useFirmendatenContactPersonDeletionHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<FirmendatenContactPersonDeletionHistoryListQuery, FirmendatenContactPersonDeletionHistoryListQueryVariables> &
    ({ variables: FirmendatenContactPersonDeletionHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenContactPersonDeletionHistoryListQuery, FirmendatenContactPersonDeletionHistoryListQueryVariables>(
    FirmendatenContactPersonDeletionHistoryListDocument,
    options
  );
}
export function useFirmendatenContactPersonDeletionHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FirmendatenContactPersonDeletionHistoryListQuery,
    FirmendatenContactPersonDeletionHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenContactPersonDeletionHistoryListQuery, FirmendatenContactPersonDeletionHistoryListQueryVariables>(
    FirmendatenContactPersonDeletionHistoryListDocument,
    options
  );
}
export function useFirmendatenContactPersonDeletionHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FirmendatenContactPersonDeletionHistoryListQuery, FirmendatenContactPersonDeletionHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenContactPersonDeletionHistoryListQuery, FirmendatenContactPersonDeletionHistoryListQueryVariables>(
    FirmendatenContactPersonDeletionHistoryListDocument,
    options
  );
}
export type FirmendatenContactPersonDeletionHistoryListQueryHookResult = ReturnType<typeof useFirmendatenContactPersonDeletionHistoryListQuery>;
export type FirmendatenContactPersonDeletionHistoryListLazyQueryHookResult = ReturnType<
  typeof useFirmendatenContactPersonDeletionHistoryListLazyQuery
>;
export type FirmendatenContactPersonDeletionHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useFirmendatenContactPersonDeletionHistoryListSuspenseQuery
>;
export type FirmendatenContactPersonDeletionHistoryListQueryResult = Apollo.QueryResult<
  FirmendatenContactPersonDeletionHistoryListQuery,
  FirmendatenContactPersonDeletionHistoryListQueryVariables
>;
export const FirmendatenEmailContactDeletionHistoryListDocument = gql`
  query FirmendatenEmailContactDeletionHistoryList($firmendatenId: ID!) {
    getFirmendatenEmailContactDeletionHistoryList(firmendatenId: $firmendatenId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useFirmendatenEmailContactDeletionHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<FirmendatenEmailContactDeletionHistoryListQuery, FirmendatenEmailContactDeletionHistoryListQueryVariables> &
    ({ variables: FirmendatenEmailContactDeletionHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenEmailContactDeletionHistoryListQuery, FirmendatenEmailContactDeletionHistoryListQueryVariables>(
    FirmendatenEmailContactDeletionHistoryListDocument,
    options
  );
}
export function useFirmendatenEmailContactDeletionHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenEmailContactDeletionHistoryListQuery, FirmendatenEmailContactDeletionHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenEmailContactDeletionHistoryListQuery, FirmendatenEmailContactDeletionHistoryListQueryVariables>(
    FirmendatenEmailContactDeletionHistoryListDocument,
    options
  );
}
export function useFirmendatenEmailContactDeletionHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FirmendatenEmailContactDeletionHistoryListQuery, FirmendatenEmailContactDeletionHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenEmailContactDeletionHistoryListQuery, FirmendatenEmailContactDeletionHistoryListQueryVariables>(
    FirmendatenEmailContactDeletionHistoryListDocument,
    options
  );
}
export type FirmendatenEmailContactDeletionHistoryListQueryHookResult = ReturnType<typeof useFirmendatenEmailContactDeletionHistoryListQuery>;
export type FirmendatenEmailContactDeletionHistoryListLazyQueryHookResult = ReturnType<typeof useFirmendatenEmailContactDeletionHistoryListLazyQuery>;
export type FirmendatenEmailContactDeletionHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useFirmendatenEmailContactDeletionHistoryListSuspenseQuery
>;
export type FirmendatenEmailContactDeletionHistoryListQueryResult = Apollo.QueryResult<
  FirmendatenEmailContactDeletionHistoryListQuery,
  FirmendatenEmailContactDeletionHistoryListQueryVariables
>;
export const FirmendatenOperatingSiteDeletionHistoryListDocument = gql`
  query FirmendatenOperatingSiteDeletionHistoryList($firmendatenId: ID!) {
    getFirmendatenOperatingSiteDeletionHistoryList(firmendatenId: $firmendatenId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useFirmendatenOperatingSiteDeletionHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<FirmendatenOperatingSiteDeletionHistoryListQuery, FirmendatenOperatingSiteDeletionHistoryListQueryVariables> &
    ({ variables: FirmendatenOperatingSiteDeletionHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenOperatingSiteDeletionHistoryListQuery, FirmendatenOperatingSiteDeletionHistoryListQueryVariables>(
    FirmendatenOperatingSiteDeletionHistoryListDocument,
    options
  );
}
export function useFirmendatenOperatingSiteDeletionHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FirmendatenOperatingSiteDeletionHistoryListQuery,
    FirmendatenOperatingSiteDeletionHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenOperatingSiteDeletionHistoryListQuery, FirmendatenOperatingSiteDeletionHistoryListQueryVariables>(
    FirmendatenOperatingSiteDeletionHistoryListDocument,
    options
  );
}
export function useFirmendatenOperatingSiteDeletionHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FirmendatenOperatingSiteDeletionHistoryListQuery, FirmendatenOperatingSiteDeletionHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenOperatingSiteDeletionHistoryListQuery, FirmendatenOperatingSiteDeletionHistoryListQueryVariables>(
    FirmendatenOperatingSiteDeletionHistoryListDocument,
    options
  );
}
export type FirmendatenOperatingSiteDeletionHistoryListQueryHookResult = ReturnType<typeof useFirmendatenOperatingSiteDeletionHistoryListQuery>;
export type FirmendatenOperatingSiteDeletionHistoryListLazyQueryHookResult = ReturnType<
  typeof useFirmendatenOperatingSiteDeletionHistoryListLazyQuery
>;
export type FirmendatenOperatingSiteDeletionHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useFirmendatenOperatingSiteDeletionHistoryListSuspenseQuery
>;
export type FirmendatenOperatingSiteDeletionHistoryListQueryResult = Apollo.QueryResult<
  FirmendatenOperatingSiteDeletionHistoryListQuery,
  FirmendatenOperatingSiteDeletionHistoryListQueryVariables
>;
export const FirmendatenPhoneContactDeletionHistoryListDocument = gql`
  query FirmendatenPhoneContactDeletionHistoryList($firmendatenId: ID!) {
    getFirmendatenPhoneContactDeletionHistoryList(firmendatenId: $firmendatenId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useFirmendatenPhoneContactDeletionHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<FirmendatenPhoneContactDeletionHistoryListQuery, FirmendatenPhoneContactDeletionHistoryListQueryVariables> &
    ({ variables: FirmendatenPhoneContactDeletionHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenPhoneContactDeletionHistoryListQuery, FirmendatenPhoneContactDeletionHistoryListQueryVariables>(
    FirmendatenPhoneContactDeletionHistoryListDocument,
    options
  );
}
export function useFirmendatenPhoneContactDeletionHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenPhoneContactDeletionHistoryListQuery, FirmendatenPhoneContactDeletionHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenPhoneContactDeletionHistoryListQuery, FirmendatenPhoneContactDeletionHistoryListQueryVariables>(
    FirmendatenPhoneContactDeletionHistoryListDocument,
    options
  );
}
export function useFirmendatenPhoneContactDeletionHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FirmendatenPhoneContactDeletionHistoryListQuery, FirmendatenPhoneContactDeletionHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenPhoneContactDeletionHistoryListQuery, FirmendatenPhoneContactDeletionHistoryListQueryVariables>(
    FirmendatenPhoneContactDeletionHistoryListDocument,
    options
  );
}
export type FirmendatenPhoneContactDeletionHistoryListQueryHookResult = ReturnType<typeof useFirmendatenPhoneContactDeletionHistoryListQuery>;
export type FirmendatenPhoneContactDeletionHistoryListLazyQueryHookResult = ReturnType<typeof useFirmendatenPhoneContactDeletionHistoryListLazyQuery>;
export type FirmendatenPhoneContactDeletionHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useFirmendatenPhoneContactDeletionHistoryListSuspenseQuery
>;
export type FirmendatenPhoneContactDeletionHistoryListQueryResult = Apollo.QueryResult<
  FirmendatenPhoneContactDeletionHistoryListQuery,
  FirmendatenPhoneContactDeletionHistoryListQueryVariables
>;
export const FirmendatenPostofficeBoxAddressDeletionHistoryListDocument = gql`
  query FirmendatenPostofficeBoxAddressDeletionHistoryList($firmendatenId: ID!) {
    getFirmendatenPostofficeBoxAddressDeletionHistoryList(firmendatenId: $firmendatenId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useFirmendatenPostofficeBoxAddressDeletionHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    FirmendatenPostofficeBoxAddressDeletionHistoryListQuery,
    FirmendatenPostofficeBoxAddressDeletionHistoryListQueryVariables
  > &
    ({ variables: FirmendatenPostofficeBoxAddressDeletionHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenPostofficeBoxAddressDeletionHistoryListQuery, FirmendatenPostofficeBoxAddressDeletionHistoryListQueryVariables>(
    FirmendatenPostofficeBoxAddressDeletionHistoryListDocument,
    options
  );
}
export function useFirmendatenPostofficeBoxAddressDeletionHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FirmendatenPostofficeBoxAddressDeletionHistoryListQuery,
    FirmendatenPostofficeBoxAddressDeletionHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FirmendatenPostofficeBoxAddressDeletionHistoryListQuery,
    FirmendatenPostofficeBoxAddressDeletionHistoryListQueryVariables
  >(FirmendatenPostofficeBoxAddressDeletionHistoryListDocument, options);
}
export function useFirmendatenPostofficeBoxAddressDeletionHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FirmendatenPostofficeBoxAddressDeletionHistoryListQuery,
        FirmendatenPostofficeBoxAddressDeletionHistoryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FirmendatenPostofficeBoxAddressDeletionHistoryListQuery,
    FirmendatenPostofficeBoxAddressDeletionHistoryListQueryVariables
  >(FirmendatenPostofficeBoxAddressDeletionHistoryListDocument, options);
}
export type FirmendatenPostofficeBoxAddressDeletionHistoryListQueryHookResult = ReturnType<
  typeof useFirmendatenPostofficeBoxAddressDeletionHistoryListQuery
>;
export type FirmendatenPostofficeBoxAddressDeletionHistoryListLazyQueryHookResult = ReturnType<
  typeof useFirmendatenPostofficeBoxAddressDeletionHistoryListLazyQuery
>;
export type FirmendatenPostofficeBoxAddressDeletionHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useFirmendatenPostofficeBoxAddressDeletionHistoryListSuspenseQuery
>;
export type FirmendatenPostofficeBoxAddressDeletionHistoryListQueryResult = Apollo.QueryResult<
  FirmendatenPostofficeBoxAddressDeletionHistoryListQuery,
  FirmendatenPostofficeBoxAddressDeletionHistoryListQueryVariables
>;
export const FirmendatenStreetAddressDeletionHistoryListDocument = gql`
  query FirmendatenStreetAddressDeletionHistoryList($firmendatenId: ID!) {
    getFirmendatenStreetAddressDeletionHistoryList(firmendatenId: $firmendatenId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useFirmendatenStreetAddressDeletionHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<FirmendatenStreetAddressDeletionHistoryListQuery, FirmendatenStreetAddressDeletionHistoryListQueryVariables> &
    ({ variables: FirmendatenStreetAddressDeletionHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenStreetAddressDeletionHistoryListQuery, FirmendatenStreetAddressDeletionHistoryListQueryVariables>(
    FirmendatenStreetAddressDeletionHistoryListDocument,
    options
  );
}
export function useFirmendatenStreetAddressDeletionHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FirmendatenStreetAddressDeletionHistoryListQuery,
    FirmendatenStreetAddressDeletionHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenStreetAddressDeletionHistoryListQuery, FirmendatenStreetAddressDeletionHistoryListQueryVariables>(
    FirmendatenStreetAddressDeletionHistoryListDocument,
    options
  );
}
export function useFirmendatenStreetAddressDeletionHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FirmendatenStreetAddressDeletionHistoryListQuery, FirmendatenStreetAddressDeletionHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenStreetAddressDeletionHistoryListQuery, FirmendatenStreetAddressDeletionHistoryListQueryVariables>(
    FirmendatenStreetAddressDeletionHistoryListDocument,
    options
  );
}
export type FirmendatenStreetAddressDeletionHistoryListQueryHookResult = ReturnType<typeof useFirmendatenStreetAddressDeletionHistoryListQuery>;
export type FirmendatenStreetAddressDeletionHistoryListLazyQueryHookResult = ReturnType<
  typeof useFirmendatenStreetAddressDeletionHistoryListLazyQuery
>;
export type FirmendatenStreetAddressDeletionHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useFirmendatenStreetAddressDeletionHistoryListSuspenseQuery
>;
export type FirmendatenStreetAddressDeletionHistoryListQueryResult = Apollo.QueryResult<
  FirmendatenStreetAddressDeletionHistoryListQuery,
  FirmendatenStreetAddressDeletionHistoryListQueryVariables
>;
export const FirmendatenObjektVorschreibungspositionNameListDocument = gql`
  query FirmendatenObjektVorschreibungspositionNameList {
    getFirmendatenObjektVorschreibungspositionNameList {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFirmendatenObjektVorschreibungspositionNameListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FirmendatenObjektVorschreibungspositionNameListQuery,
    FirmendatenObjektVorschreibungspositionNameListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenObjektVorschreibungspositionNameListQuery, FirmendatenObjektVorschreibungspositionNameListQueryVariables>(
    FirmendatenObjektVorschreibungspositionNameListDocument,
    options
  );
}
export function useFirmendatenObjektVorschreibungspositionNameListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FirmendatenObjektVorschreibungspositionNameListQuery,
    FirmendatenObjektVorschreibungspositionNameListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenObjektVorschreibungspositionNameListQuery, FirmendatenObjektVorschreibungspositionNameListQueryVariables>(
    FirmendatenObjektVorschreibungspositionNameListDocument,
    options
  );
}
export function useFirmendatenObjektVorschreibungspositionNameListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FirmendatenObjektVorschreibungspositionNameListQuery,
        FirmendatenObjektVorschreibungspositionNameListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenObjektVorschreibungspositionNameListQuery, FirmendatenObjektVorschreibungspositionNameListQueryVariables>(
    FirmendatenObjektVorschreibungspositionNameListDocument,
    options
  );
}
export type FirmendatenObjektVorschreibungspositionNameListQueryHookResult = ReturnType<
  typeof useFirmendatenObjektVorschreibungspositionNameListQuery
>;
export type FirmendatenObjektVorschreibungspositionNameListLazyQueryHookResult = ReturnType<
  typeof useFirmendatenObjektVorschreibungspositionNameListLazyQuery
>;
export type FirmendatenObjektVorschreibungspositionNameListSuspenseQueryHookResult = ReturnType<
  typeof useFirmendatenObjektVorschreibungspositionNameListSuspenseQuery
>;
export type FirmendatenObjektVorschreibungspositionNameListQueryResult = Apollo.QueryResult<
  FirmendatenObjektVorschreibungspositionNameListQuery,
  FirmendatenObjektVorschreibungspositionNameListQueryVariables
>;
