import React, { FC } from 'react';
import { Drawer } from 'antd';
import EbicsBankConfigForm from '../Form/EbicsBankConfigForm';
import { EbicsBankConfig } from '../../../../types';

type Props = {
  drawerTitle: string;
  isDrawerOpen: boolean;
  onDrawerClose: () => void;
  ebicsBankConfig?: EbicsBankConfig;
  onFormAction: () => void;
  onFormCancel: () => void;
};

const EbicsBankConfigDrawer: FC<Props> = ({ drawerTitle, isDrawerOpen, onDrawerClose, ebicsBankConfig, onFormAction, onFormCancel }) => {
  return (
    <Drawer title={drawerTitle} width={720} open={isDrawerOpen} onClose={onDrawerClose} destroyOnClose>
      <EbicsBankConfigForm ebicsBankConfig={ebicsBankConfig} onAction={onFormAction} onCancel={onFormCancel} />
    </Drawer>
  );
};

export default EbicsBankConfigDrawer;
