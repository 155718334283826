import React, { FC } from 'react';
import PersonenGemeinschaftCard from '../../../features/Rechtstraeger/PersonenGemeinschaft/Card/PersonenGemeinschaftCard';
import menuListPersonenGemeinschaft from './menuListPersonenGemeinschaft';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { usePersonenGemeinschaftQuery } from '../../../features/Rechtstraeger/PersonenGemeinschaft/gql/PersonenGemeinschaftQueries.types';
import { useRechtsTraegerRolleListQuery } from '../../../features/Rechtstraeger/Rolle/gql/RolleQueries.types';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { Personengemeinschaft, RechtstraegerType } from '../../../types';

type Props = {
  rechtstraegerId: string;
  rechtstraegerType: RechtstraegerType;
};

const PersonenGemeinschaftDetailPage: FC<Props> = ({ rechtstraegerId, rechtstraegerType }) => {
  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();

  const { data: personengemeinschaftData, refetch } = usePersonenGemeinschaftQuery({
    variables: { personenGemeinschaftId: rechtstraegerId, withDetails: true },
  });
  const personenGemeinschaft = personengemeinschaftData?.getPersonenGemeinschaft.data;

  const { data: rolleListData } = useRechtsTraegerRolleListQuery({
    variables: { rechtstraegerId },
  });
  const rolleList = rolleListData?.getRechtstraegerRolleList.data;
  const loadingPG = !personenGemeinschaft;

  return (
    <DetailsPageTemplate<Personengemeinschaft>
      data={personenGemeinschaft}
      pageTitle={(personenGemeinschaft) => personenGemeinschaft.kurzBezeichnung}
      card={(personenGemeinschaft) => (
        <PersonenGemeinschaftCard
          refetch={refetch}
          isLoading={loadingPG}
          kundenSystemId={kundenSystemId}
          rechtstraegerId={rechtstraegerId}
          personenGemeinschaft={personenGemeinschaft}
        />
      )}
      sidebarMenuList={(personenGemeinschaft) => menuListPersonenGemeinschaft(rechtstraegerId, personenGemeinschaft, refetch, rechtstraegerType)}
      contentContextList={() => rolleList}
    />
  );
};

export default PersonenGemeinschaftDetailPage;
