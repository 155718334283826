import { toString } from 'lodash';
import { Mitarbeiter } from '../../../types';

export const personFormPartInitialValues = () => ({
  title: '',
  firstname: '',
  lastname: '',
  titleTrailing: '',
});

export const personToString = (person: Mitarbeiter) =>
  `${toString(person.title)} ${toString(person.firstname)} ${toString(person.lastname)} ${toString(person.titleTrailing)}`;
