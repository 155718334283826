import * as Types from '../../../types';

import { gql } from '@apollo/client';
import {
  EmailDeliveryDefinitionBaseFieldsFragmentDoc,
  EmailDeliveryDefinitionFieldsFragmentDoc,
  EmailDeliveryDefinitionTemplatesFieldsFragmentDoc,
} from './EmailDeliveryDefinitionFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmailDeliveryDefinitionListQueryVariables = Types.Exact<{
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  type?: Types.InputMaybe<Types.EmailDeliveryDefinitionType>;
}>;

export type EmailDeliveryDefinitionListQuery = {
  getEmailDeliveryDefinitionList: {
    data: Array<{
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      emailDeliveryDefinitionId: string;
      type: { text: string; value: Types.EmailDeliveryDefinitionType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type EmailDeliveryDefinitionQueryVariables = Types.Exact<{
  emailDeliveryDefinitionId: Types.Scalars['ID']['input'];
}>;

export type EmailDeliveryDefinitionQuery = {
  getEmailDeliveryDefinition: {
    data: {
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      emailDeliveryDefinitionId: string;
      body?: {
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      } | null;
      subject: {
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      };
      type: { text: string; value: Types.EmailDeliveryDefinitionType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type EmailDeliveryDefinitionTemplatesQueryVariables = Types.Exact<{
  type: Types.EmailDeliveryDefinitionType;
}>;

export type EmailDeliveryDefinitionTemplatesQuery = {
  getEmailDeliveryDefinitionTemplates: {
    data: Array<{
      body?: {
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      } | null;
      subject: {
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type EmailDeliveryDefinitionKurzbezeichnungQueryVariables = Types.Exact<{
  emailDeliveryDefinitionId: Types.Scalars['ID']['input'];
}>;

export type EmailDeliveryDefinitionKurzbezeichnungQuery = {
  getEmailDeliveryDefinition: {
    data: { bezeichnung: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type EmailDeliveryTypeListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type EmailDeliveryTypeListQuery = {
  getEmailDeliveryTypeList: {
    data: Array<{ text: string; value: Types.EmailDeliveryType }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type EmailDeliveryDefinitionChangeHistoryListQueryVariables = Types.Exact<{
  emailDeliveryDefinitionId: Types.Scalars['ID']['input'];
}>;

export type EmailDeliveryDefinitionChangeHistoryListQuery = {
  getEmailDeliveryDefinitionChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const EmailDeliveryDefinitionListDocument = gql`
  query EmailDeliveryDefinitionList($withDetails: Boolean, $type: EmailDeliveryDefinitionType) {
    getEmailDeliveryDefinitionList(withDetails: $withDetails, type: $type) {
      data {
        ...EmailDeliveryDefinitionBaseFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${EmailDeliveryDefinitionBaseFieldsFragmentDoc}
`;
export function useEmailDeliveryDefinitionListQuery(
  baseOptions?: Apollo.QueryHookOptions<EmailDeliveryDefinitionListQuery, EmailDeliveryDefinitionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmailDeliveryDefinitionListQuery, EmailDeliveryDefinitionListQueryVariables>(EmailDeliveryDefinitionListDocument, options);
}
export function useEmailDeliveryDefinitionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EmailDeliveryDefinitionListQuery, EmailDeliveryDefinitionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EmailDeliveryDefinitionListQuery, EmailDeliveryDefinitionListQueryVariables>(
    EmailDeliveryDefinitionListDocument,
    options
  );
}
export function useEmailDeliveryDefinitionListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EmailDeliveryDefinitionListQuery, EmailDeliveryDefinitionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EmailDeliveryDefinitionListQuery, EmailDeliveryDefinitionListQueryVariables>(
    EmailDeliveryDefinitionListDocument,
    options
  );
}
export type EmailDeliveryDefinitionListQueryHookResult = ReturnType<typeof useEmailDeliveryDefinitionListQuery>;
export type EmailDeliveryDefinitionListLazyQueryHookResult = ReturnType<typeof useEmailDeliveryDefinitionListLazyQuery>;
export type EmailDeliveryDefinitionListSuspenseQueryHookResult = ReturnType<typeof useEmailDeliveryDefinitionListSuspenseQuery>;
export type EmailDeliveryDefinitionListQueryResult = Apollo.QueryResult<EmailDeliveryDefinitionListQuery, EmailDeliveryDefinitionListQueryVariables>;
export const EmailDeliveryDefinitionDocument = gql`
  query EmailDeliveryDefinition($emailDeliveryDefinitionId: ID!) {
    getEmailDeliveryDefinition(emailDeliveryDefinitionId: $emailDeliveryDefinitionId) {
      data {
        ...EmailDeliveryDefinitionFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${EmailDeliveryDefinitionFieldsFragmentDoc}
`;
export function useEmailDeliveryDefinitionQuery(
  baseOptions: Apollo.QueryHookOptions<EmailDeliveryDefinitionQuery, EmailDeliveryDefinitionQueryVariables> &
    ({ variables: EmailDeliveryDefinitionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmailDeliveryDefinitionQuery, EmailDeliveryDefinitionQueryVariables>(EmailDeliveryDefinitionDocument, options);
}
export function useEmailDeliveryDefinitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EmailDeliveryDefinitionQuery, EmailDeliveryDefinitionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EmailDeliveryDefinitionQuery, EmailDeliveryDefinitionQueryVariables>(EmailDeliveryDefinitionDocument, options);
}
export function useEmailDeliveryDefinitionSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EmailDeliveryDefinitionQuery, EmailDeliveryDefinitionQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EmailDeliveryDefinitionQuery, EmailDeliveryDefinitionQueryVariables>(EmailDeliveryDefinitionDocument, options);
}
export type EmailDeliveryDefinitionQueryHookResult = ReturnType<typeof useEmailDeliveryDefinitionQuery>;
export type EmailDeliveryDefinitionLazyQueryHookResult = ReturnType<typeof useEmailDeliveryDefinitionLazyQuery>;
export type EmailDeliveryDefinitionSuspenseQueryHookResult = ReturnType<typeof useEmailDeliveryDefinitionSuspenseQuery>;
export type EmailDeliveryDefinitionQueryResult = Apollo.QueryResult<EmailDeliveryDefinitionQuery, EmailDeliveryDefinitionQueryVariables>;
export const EmailDeliveryDefinitionTemplatesDocument = gql`
  query EmailDeliveryDefinitionTemplates($type: EmailDeliveryDefinitionType!) {
    getEmailDeliveryDefinitionTemplates(type: $type) {
      data {
        ...EmailDeliveryDefinitionTemplatesFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${EmailDeliveryDefinitionTemplatesFieldsFragmentDoc}
`;
export function useEmailDeliveryDefinitionTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<EmailDeliveryDefinitionTemplatesQuery, EmailDeliveryDefinitionTemplatesQueryVariables> &
    ({ variables: EmailDeliveryDefinitionTemplatesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmailDeliveryDefinitionTemplatesQuery, EmailDeliveryDefinitionTemplatesQueryVariables>(
    EmailDeliveryDefinitionTemplatesDocument,
    options
  );
}
export function useEmailDeliveryDefinitionTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EmailDeliveryDefinitionTemplatesQuery, EmailDeliveryDefinitionTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EmailDeliveryDefinitionTemplatesQuery, EmailDeliveryDefinitionTemplatesQueryVariables>(
    EmailDeliveryDefinitionTemplatesDocument,
    options
  );
}
export function useEmailDeliveryDefinitionTemplatesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<EmailDeliveryDefinitionTemplatesQuery, EmailDeliveryDefinitionTemplatesQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EmailDeliveryDefinitionTemplatesQuery, EmailDeliveryDefinitionTemplatesQueryVariables>(
    EmailDeliveryDefinitionTemplatesDocument,
    options
  );
}
export type EmailDeliveryDefinitionTemplatesQueryHookResult = ReturnType<typeof useEmailDeliveryDefinitionTemplatesQuery>;
export type EmailDeliveryDefinitionTemplatesLazyQueryHookResult = ReturnType<typeof useEmailDeliveryDefinitionTemplatesLazyQuery>;
export type EmailDeliveryDefinitionTemplatesSuspenseQueryHookResult = ReturnType<typeof useEmailDeliveryDefinitionTemplatesSuspenseQuery>;
export type EmailDeliveryDefinitionTemplatesQueryResult = Apollo.QueryResult<
  EmailDeliveryDefinitionTemplatesQuery,
  EmailDeliveryDefinitionTemplatesQueryVariables
>;
export const EmailDeliveryDefinitionKurzbezeichnungDocument = gql`
  query EmailDeliveryDefinitionKurzbezeichnung($emailDeliveryDefinitionId: ID!) {
    getEmailDeliveryDefinition(emailDeliveryDefinitionId: $emailDeliveryDefinitionId) {
      data {
        bezeichnung
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useEmailDeliveryDefinitionKurzbezeichnungQuery(
  baseOptions: Apollo.QueryHookOptions<EmailDeliveryDefinitionKurzbezeichnungQuery, EmailDeliveryDefinitionKurzbezeichnungQueryVariables> &
    ({ variables: EmailDeliveryDefinitionKurzbezeichnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmailDeliveryDefinitionKurzbezeichnungQuery, EmailDeliveryDefinitionKurzbezeichnungQueryVariables>(
    EmailDeliveryDefinitionKurzbezeichnungDocument,
    options
  );
}
export function useEmailDeliveryDefinitionKurzbezeichnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EmailDeliveryDefinitionKurzbezeichnungQuery, EmailDeliveryDefinitionKurzbezeichnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EmailDeliveryDefinitionKurzbezeichnungQuery, EmailDeliveryDefinitionKurzbezeichnungQueryVariables>(
    EmailDeliveryDefinitionKurzbezeichnungDocument,
    options
  );
}
export function useEmailDeliveryDefinitionKurzbezeichnungSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<EmailDeliveryDefinitionKurzbezeichnungQuery, EmailDeliveryDefinitionKurzbezeichnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EmailDeliveryDefinitionKurzbezeichnungQuery, EmailDeliveryDefinitionKurzbezeichnungQueryVariables>(
    EmailDeliveryDefinitionKurzbezeichnungDocument,
    options
  );
}
export type EmailDeliveryDefinitionKurzbezeichnungQueryHookResult = ReturnType<typeof useEmailDeliveryDefinitionKurzbezeichnungQuery>;
export type EmailDeliveryDefinitionKurzbezeichnungLazyQueryHookResult = ReturnType<typeof useEmailDeliveryDefinitionKurzbezeichnungLazyQuery>;
export type EmailDeliveryDefinitionKurzbezeichnungSuspenseQueryHookResult = ReturnType<typeof useEmailDeliveryDefinitionKurzbezeichnungSuspenseQuery>;
export type EmailDeliveryDefinitionKurzbezeichnungQueryResult = Apollo.QueryResult<
  EmailDeliveryDefinitionKurzbezeichnungQuery,
  EmailDeliveryDefinitionKurzbezeichnungQueryVariables
>;
export const EmailDeliveryTypeListDocument = gql`
  query EmailDeliveryTypeList {
    getEmailDeliveryTypeList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useEmailDeliveryTypeListQuery(
  baseOptions?: Apollo.QueryHookOptions<EmailDeliveryTypeListQuery, EmailDeliveryTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmailDeliveryTypeListQuery, EmailDeliveryTypeListQueryVariables>(EmailDeliveryTypeListDocument, options);
}
export function useEmailDeliveryTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EmailDeliveryTypeListQuery, EmailDeliveryTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EmailDeliveryTypeListQuery, EmailDeliveryTypeListQueryVariables>(EmailDeliveryTypeListDocument, options);
}
export function useEmailDeliveryTypeListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EmailDeliveryTypeListQuery, EmailDeliveryTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EmailDeliveryTypeListQuery, EmailDeliveryTypeListQueryVariables>(EmailDeliveryTypeListDocument, options);
}
export type EmailDeliveryTypeListQueryHookResult = ReturnType<typeof useEmailDeliveryTypeListQuery>;
export type EmailDeliveryTypeListLazyQueryHookResult = ReturnType<typeof useEmailDeliveryTypeListLazyQuery>;
export type EmailDeliveryTypeListSuspenseQueryHookResult = ReturnType<typeof useEmailDeliveryTypeListSuspenseQuery>;
export type EmailDeliveryTypeListQueryResult = Apollo.QueryResult<EmailDeliveryTypeListQuery, EmailDeliveryTypeListQueryVariables>;
export const EmailDeliveryDefinitionChangeHistoryListDocument = gql`
  query EmailDeliveryDefinitionChangeHistoryList($emailDeliveryDefinitionId: ID!) {
    getEmailDeliveryDefinitionChangeHistoryList(emailDeliveryDefinitionId: $emailDeliveryDefinitionId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useEmailDeliveryDefinitionChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<EmailDeliveryDefinitionChangeHistoryListQuery, EmailDeliveryDefinitionChangeHistoryListQueryVariables> &
    ({ variables: EmailDeliveryDefinitionChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmailDeliveryDefinitionChangeHistoryListQuery, EmailDeliveryDefinitionChangeHistoryListQueryVariables>(
    EmailDeliveryDefinitionChangeHistoryListDocument,
    options
  );
}
export function useEmailDeliveryDefinitionChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EmailDeliveryDefinitionChangeHistoryListQuery, EmailDeliveryDefinitionChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EmailDeliveryDefinitionChangeHistoryListQuery, EmailDeliveryDefinitionChangeHistoryListQueryVariables>(
    EmailDeliveryDefinitionChangeHistoryListDocument,
    options
  );
}
export function useEmailDeliveryDefinitionChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<EmailDeliveryDefinitionChangeHistoryListQuery, EmailDeliveryDefinitionChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EmailDeliveryDefinitionChangeHistoryListQuery, EmailDeliveryDefinitionChangeHistoryListQueryVariables>(
    EmailDeliveryDefinitionChangeHistoryListDocument,
    options
  );
}
export type EmailDeliveryDefinitionChangeHistoryListQueryHookResult = ReturnType<typeof useEmailDeliveryDefinitionChangeHistoryListQuery>;
export type EmailDeliveryDefinitionChangeHistoryListLazyQueryHookResult = ReturnType<typeof useEmailDeliveryDefinitionChangeHistoryListLazyQuery>;
export type EmailDeliveryDefinitionChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useEmailDeliveryDefinitionChangeHistoryListSuspenseQuery
>;
export type EmailDeliveryDefinitionChangeHistoryListQueryResult = Apollo.QueryResult<
  EmailDeliveryDefinitionChangeHistoryListQuery,
  EmailDeliveryDefinitionChangeHistoryListQueryVariables
>;
