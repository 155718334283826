import { stringOrEmpty } from '../../../../helpers/stringHelper';

export type FirmennameFormPartValues = {
  name1: string;
  name2: string;
  name3: string;
};

export type FirmennameModel = {
  name1: string;
  name2?: string | null;
  name3?: string | null;
};

export const firmennameFormPartInitialValues = (): FirmennameFormPartValues => ({
  name1: '',
  name2: '',
  name3: '',
});

export const mapFirmennameToFormValues = (firmendaten: FirmennameModel): FirmennameFormPartValues => ({
  name1: firmendaten.name1,
  name2: stringOrEmpty(firmendaten.name2),
  name3: stringOrEmpty(firmendaten.name3),
});
