import React, { Dispatch, SetStateAction, useState } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Card, Col, Row } from 'antd';
import TicketCalendar from '../../TicketCalender/TicketCalendar';
import { TicketStatus } from '../../../types';
import { TicketListQueryVariables } from '../../Ticket/gql/TicketQueries.types';
import { useAuthorizedMitarbeiter } from '../../../shared/Auth/useAuthorized';
import TicketStatusSelect from '../../Ticket/shared/TicketStatusSelect';
import TicketPrioritySelect from '../../Ticket/shared/TicketPrioritySelect';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import { Spacer } from '../../../components/Grid';
import TicketCalendarDateCell from '../../TicketCalender/TicketCalendarDateCell';

const DashboardTicketCalendar = () => {
  const { mitarbeiter } = useAuthorizedMitarbeiter();
  const defaultStatus = [TicketStatus.InProgress, TicketStatus.ToDo];
  const [status, setStatus] = useState<TicketListQueryVariables['status']>(defaultStatus);
  const [priority, setPriority] = useState<TicketListQueryVariables['priority']>();

  const queryProps: TicketListQueryVariables = {
    assigneeMitarbeiterId: mitarbeiter.mitarbeiterId,
    searchInSubTickets: true,
    priority,
    status: status?.length ? status : defaultStatus, // set default status if none selected
  };

  return (
    <Card size="small" title="Kalender">
      <TicketCalendar
        header={{ bottom: () => headerBottom(status, setStatus, priority, setPriority) }}
        dateCellRender={(date, ticketList) => (
          <TicketCalendarDateCell date={date} ticketListByDueDate={ticketList} openLinkInNewTab showAssignee={false} />
        )}
        {...queryProps}
      />
    </Card>
  );
};

const headerBottom = (
  status: TicketListQueryVariables['status'],
  setStatus: Dispatch<SetStateAction<TicketListQueryVariables['status']>>,
  priority: TicketListQueryVariables['priority'],
  setPriority: Dispatch<SetStateAction<TicketListQueryVariables['priority']>>
) => {
  return (
    <>
      <Spacer height={12} />
      <Formik initialValues={{}} onSubmit={() => {}}>
        <Form>
          <Row justify="end" gutter={16}>
            <Col>
              <FormItemWithoutColon name="priority" label="Priorität">
                <TicketPrioritySelect
                  mode="multiple"
                  allowClear
                  name="priority"
                  style={{ width: 200 }}
                  value={priority}
                  onChange={(value) => setPriority(value)}
                />
              </FormItemWithoutColon>
            </Col>
            <Col>
              <FormItemWithoutColon name="status" label="Status">
                <TicketStatusSelect mode="multiple" name="status" style={{ width: 250 }} value={status} onChange={(value) => setStatus(value)} />
              </FormItemWithoutColon>
            </Col>
          </Row>
        </Form>
      </Formik>
      <div style={{ marginBottom: -16 }} />
    </>
  );
};

export default DashboardTicketCalendar;
