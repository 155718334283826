import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBeVertragVersionMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  request: Types.BeVertragVersionCreateInput;
}>;

export type CreateBeVertragVersionMutation = {
  createBeVertragVersion: {
    data: { vertragVersionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateBeVertragVersionMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vertragVersionId: Types.Scalars['ID']['input'];
  request: Types.BeVertragVersionUpdateInput;
}>;

export type UpdateBeVertragVersionMutation = {
  updateBeVertragVersion: {
    data: { vertragVersionId: string; historicizedList?: Array<{ vertragVersionId: string }> | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteBeVertragVersionMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vertragVersionId: Types.Scalars['ID']['input'];
}>;

export type DeleteBeVertragVersionMutation = {
  deleteBeVertragVersion: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateBeVertragVersionDocument = gql`
  mutation CreateBeVertragVersion($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!, $request: BeVertragVersionCreateInput!) {
    createBeVertragVersion(objektId: $objektId, bestandseinheitId: $bestandseinheitId, vertragId: $vertragId, request: $request) {
      data {
        vertragVersionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateBeVertragVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBeVertragVersionMutation, CreateBeVertragVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBeVertragVersionMutation, CreateBeVertragVersionMutationVariables>(CreateBeVertragVersionDocument, options);
}
export type CreateBeVertragVersionMutationHookResult = ReturnType<typeof useCreateBeVertragVersionMutation>;
export type CreateBeVertragVersionMutationResult = Apollo.MutationResult<CreateBeVertragVersionMutation>;
export type CreateBeVertragVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateBeVertragVersionMutation,
  CreateBeVertragVersionMutationVariables
>;
export const UpdateBeVertragVersionDocument = gql`
  mutation UpdateBeVertragVersion(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vertragVersionId: ID!
    $request: BeVertragVersionUpdateInput!
  ) {
    updateBeVertragVersion(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vertragVersionId: $vertragVersionId
      request: $request
    ) {
      data {
        vertragVersionId
        historicizedList {
          vertragVersionId
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateBeVertragVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBeVertragVersionMutation, UpdateBeVertragVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBeVertragVersionMutation, UpdateBeVertragVersionMutationVariables>(UpdateBeVertragVersionDocument, options);
}
export type UpdateBeVertragVersionMutationHookResult = ReturnType<typeof useUpdateBeVertragVersionMutation>;
export type UpdateBeVertragVersionMutationResult = Apollo.MutationResult<UpdateBeVertragVersionMutation>;
export type UpdateBeVertragVersionMutationOptions = Apollo.BaseMutationOptions<
  UpdateBeVertragVersionMutation,
  UpdateBeVertragVersionMutationVariables
>;
export const DeleteBeVertragVersionDocument = gql`
  mutation DeleteBeVertragVersion($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!, $vertragVersionId: ID!) {
    deleteBeVertragVersion(objektId: $objektId, bestandseinheitId: $bestandseinheitId, vertragId: $vertragId, vertragVersionId: $vertragVersionId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteBeVertragVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBeVertragVersionMutation, DeleteBeVertragVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBeVertragVersionMutation, DeleteBeVertragVersionMutationVariables>(DeleteBeVertragVersionDocument, options);
}
export type DeleteBeVertragVersionMutationHookResult = ReturnType<typeof useDeleteBeVertragVersionMutation>;
export type DeleteBeVertragVersionMutationResult = Apollo.MutationResult<DeleteBeVertragVersionMutation>;
export type DeleteBeVertragVersionMutationOptions = Apollo.BaseMutationOptions<
  DeleteBeVertragVersionMutation,
  DeleteBeVertragVersionMutationVariables
>;
