import React, { FC, useContext } from 'react';
import { Col, Row, Space, Typography } from 'antd';
import { FormikProps } from 'formik';
import { Switch } from 'formik-antd';
import DrawerRow from '../../../../../../../../../components/Drawer/DrawerRow';
import { VorschreibungspositionArt } from '../../../../../../../../../types';
import { IndexationAgreementIndexThresholdFormValues } from '../IndexThreshold/indexThresholdFormMapper';
import { IndexationAgreementIndexAnnualFormValues } from '../IndexAnnual/indexAnnualFormMapper';
import ObjektVPosSelect from '../../../../../../../../shared/ObjektVPosSelect';
import { IndexationAgreementContext } from '../../IndexationAgreementContext';
import FormItemWithFieldHelp from '../../../../../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  formikProps: FormikProps<IndexationAgreementIndexAnnualFormValues> | FormikProps<IndexationAgreementIndexThresholdFormValues>;
  subsequentBillingName: string;
  subsequentBillingObjektVPosName: string;
  fieldHelpNames?: {
    subsequentBilling?: string | null;
    subsequentBillingObjektVorschreibungspositionId?: string | null;
  };
};

const SubsequentBillingBlock: FC<Props> = ({ formikProps, subsequentBillingName, subsequentBillingObjektVPosName, fieldHelpNames }) => {
  const { objektId } = useContext(IndexationAgreementContext);

  const content = (
    <Row gutter={8}>
      <Col span={6}>
        <FormItemWithFieldHelp name={subsequentBillingName} label="Nachverrechnung" fieldHelp={fieldHelpNames?.subsequentBilling}>
          <Space>
            <Switch
              id={subsequentBillingName}
              name={subsequentBillingName}
              onChange={(value) => {
                if (value && !formikProps.values.subsequentBilling) {
                  formikProps.setFieldValue(subsequentBillingObjektVPosName, null);
                }
              }}
            />
            <Typography.Text>{formikProps.values.subsequentBilling ? 'Ja' : 'Nein'}</Typography.Text>
          </Space>
        </FormItemWithFieldHelp>
      </Col>
      <Col span={18}>
        <FormItemWithFieldHelp
          name={subsequentBillingObjektVPosName}
          label="mit Vorschreibungsposition"
          fieldHelp={fieldHelpNames?.subsequentBillingObjektVorschreibungspositionId}
        >
          <ObjektVPosSelect
            name={subsequentBillingObjektVPosName}
            objektId={objektId}
            includeArchiviert
            exludeArts={[
              VorschreibungspositionArt.Abrechnungsergebnis,
              VorschreibungspositionArt.Betriebskosten,
              VorschreibungspositionArt.DotRepFond,
              VorschreibungspositionArt.Heizkosten,
              VorschreibungspositionArt.Miete,
            ]}
            disabled={!formikProps.values.subsequentBilling}
          />
        </FormItemWithFieldHelp>
      </Col>
    </Row>
  );

  return <DrawerRow rowTitle="Nachverrechnung" content={content} />;
};

export default SubsequentBillingBlock;
