import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { useIncomingInvoicePayeeListQuery } from '../../gql/IncomingInvoiceFilterQueries.types';

type Props = {
  maxTextLength?: number;
} & Omit<SelectProps, 'id' | 'placeholder' | 'size' | 'allowClear' | 'showSearch' | 'filterOption'>;

const InvoicePayeeListFormSelect: FC<Props> = ({ maxTextLength, ...restProps }) => {
  const { data, loading } = useIncomingInvoicePayeeListQuery();
  const payeeList = data?.getIncomingInvoicePayeeList.data ?? [];

  return (
    <Select
      {...restProps}
      id={restProps.name}
      size="small"
      loading={loading}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      placeholder="Empfänger auswählen"
    >
      {payeeList.map((payee) => (
        <Select.Option key={payee.rechtstraegerId} value={payee.rechtstraegerId}>
          <DataWithShortenedText maxTextLength={maxTextLength ?? payee.kurzBezeichnung.length} text={payee.kurzBezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default InvoicePayeeListFormSelect;
