import * as Yup from 'yup';
import { entityIsRequired } from '../../../../../components/message/validationMsg';
import { stornoAbgrenzungFields, stornoDirektFields, stornoFormFields, StornoFormValues } from './stornoFormMapper';

/* eslint-disable no-template-curly-in-string */

export const stornoFormValidationSchema = Yup.object().shape({
  [stornoFormFields.abgrenzungsBuchung]: Yup.boolean().required(),
  [stornoFormFields.stornoDirekt]: Yup.object().when([stornoFormFields.abgrenzungsBuchung], {
    is: (abgrenzungsBuchung: StornoFormValues['abgrenzungsBuchung']) => !abgrenzungsBuchung,
    then: (schema) =>
      schema.shape({
        [stornoDirektFields.stornoBuchungsdatum]: Yup.date().required(entityIsRequired('Storno - Buchungsdatum')),
        [stornoDirektFields.korrekturBuchungsdatum]: Yup.date().required(entityIsRequired('Korrektur - Buchungsdatum')),
      }),
  }),
  [stornoFormFields.stornoAbgrenzung]: Yup.object().when([stornoFormFields.abgrenzungsBuchung], {
    is: (abgrenzungsBuchung: StornoFormValues['abgrenzungsBuchung']) => abgrenzungsBuchung,
    then: (schema) =>
      schema.shape({
        [stornoAbgrenzungFields.stornoBuchungsdatum]: Yup.date().required(entityIsRequired('Stornobuchung mit Aufwand Abgrenzung - Buchungsdatum')),
        [stornoAbgrenzungFields.stornoAbgrenzungsBuchungsdatum]: Yup.date().required(
          entityIsRequired('Aufwand Stornobuchung der Aufwand Abgrenzung - Buchungsdatum')
        ),
        [stornoAbgrenzungFields.korrekturBuchungsdatum]: Yup.date().required(
          entityIsRequired('Korrekturbuchung mit Aufwand Abgrenzung - Buchungsdatum')
        ),
        [stornoAbgrenzungFields.korrekturAbgrenzungsBuchungsdatum]: Yup.date().required(
          entityIsRequired('Aufwand Korrekturbuchung der Aufwand Abgrenzung - Buchungsdatum')
        ),
      }),
  }),
});

/* eslint-enable no-template-curly-in-string */
