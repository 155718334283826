import HistoryListingTable from '../History/HistoryListingTable';
import { HistoryType } from '../History/shared/HistoryModal';
import { useFirmendatenBankSettingsChangeHistoryListQuery } from './gql/FirmendatenBankSettingsQueries.types';

const HVBankSettingsChangeHistoryListingTable = () => {
  const { data, loading } = useFirmendatenBankSettingsChangeHistoryListQuery();
  const historyList = data?.getFirmendatenBankSettingsChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="address" />;
};

export default HVBankSettingsChangeHistoryListingTable;
