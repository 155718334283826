import React, { FC } from 'react';
import { Empty } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';
import { EbicsUser } from '../../../../../../types';
import ebicsUserTableColumns from './ebicsUserTableColumns';
import { useFDAuthorized } from '../../../../../../shared/Auth/useAuthorized';
import EbicsUserIbanTable from '../Level2/EbicsUserIbanTable';
import NestedTable from '../../../../../../components/Table/NestedTable/NestedTable';
import { HandleTableSorting } from '../../../../../../shared/typeHelpers';

type Props = {
  ebicsUserList: EbicsUser[];
  onAction: () => void;
  loading: boolean;
  pagination: PaginationProps;
  handleTableSorting: HandleTableSorting;
};

const EbicsUserTable: FC<Props> = ({ ebicsUserList, loading, onAction, pagination, handleTableSorting }) => {
  const { activeForFirmendatenId: firmendatenId } = useFDAuthorized();

  return (
    <NestedTable<EbicsUser>
      colWidth={150}
      loading={loading}
      level={1}
      locale={{
        emptyText: <Empty description={<span>Keine Ebics User vorhanden</span>} />,
      }}
      pagination={pagination}
      columns={ebicsUserTableColumns(firmendatenId, onAction)}
      dataSource={ebicsUserList}
      onChange={handleTableSorting}
      rowKey={(record) => record.ebicsUserId}
      expandedRowKey="ebicsUserId"
      expandable={{
        expandedRowRender: (record) => <EbicsUserIbanTable ebicsUser={record} />,
      }}
      useHighlighting
    />
  );
};

export default EbicsUserTable;
