import React, { FC } from 'react';
import { SubAbrAbrechnungskreisList } from '../../../../../../../types';
import NestedTableWithColSelector from '../../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import ausgabenTableColumns from './ausgabenTableColumns';
import AbrechnungskreisTable from '../Level3/AbrechnungskreisTable';

type Props = {
  parentRowKeys?: string[];
  visible?: boolean;
  ausgaben?: SubAbrAbrechnungskreisList;
  buchungskreisId?: string;
  abrechnungszeitraumVon?: string;
  abrechnungszeitraumBis?: string;
  objektId?: string;
};

const AusgabenTable: FC<Props> = ({
  visible,
  parentRowKeys,
  ausgaben,
  buchungskreisId,
  objektId,
  abrechnungszeitraumVon,
  abrechnungszeitraumBis,
}) => {
  const dataSource = ausgaben ? [ausgaben] : [];

  return (
    <NestedTableWithColSelector<SubAbrAbrechnungskreisList>
      level={2}
      subLevel={2}
      parentRowKeys={parentRowKeys}
      visible={visible}
      dataSource={dataSource}
      columns={ausgabenTableColumns}
      rowKey={getRowKey}
      expandable={{
        rowExpandable: (record) => !!record.abrechnungskreisList.length,
        expandedRowRender: (record, index, indent, expanded) => (
          <AbrechnungskreisTable
            subLevel={2}
            parentRowKeys={[...(parentRowKeys ?? []), getRowKey(record)]}
            visible={visible && expanded}
            abrechnungskreisList={record.abrechnungskreisList}
            buchungskreisId={buchungskreisId}
            abrechnungszeitraumVon={abrechnungszeitraumVon}
            abrechnungszeitraumBis={abrechnungszeitraumBis}
            objektId={objektId}
          />
        ),
      }}
    />
  );
};

const getRowKey = (record: SubAbrAbrechnungskreisList) => `${record.abrechnungskreisType.text}-${record.summe}`;

export default AusgabenTable;
