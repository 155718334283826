import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { ITimeblock } from '../../../../components/Timeline/timelineCtx';
import { RechtstraegerSteuerpflicht, Steuerpflicht, SteuerpflichtInput } from '../../../../types';
import { ValuesDescriptions } from '../../../../components/Timeline/versionTimelineHelper';

export type RechtstraegerSteuerpflichtVersionFormValues = {
  validFrom: string;
  steuerpflicht: Steuerpflicht;
  kommentar: string;
};

export const steuerpflichtVersionFormValuesDescriptions: ValuesDescriptions<RechtstraegerSteuerpflichtVersionFormValues> = {
  validFrom: 'Gültig ab',
  steuerpflicht: 'Steuerpflicht',
  kommentar: '',
};

export const steuerpflichtVersionFormFields: FormFields<RechtstraegerSteuerpflichtVersionFormValues> = {
  validFrom: 'validFrom',
  steuerpflicht: 'steuerpflicht',
  kommentar: 'kommentar',
};

export const mapTimeblockToFormValues = (timeblock: ITimeblock<RechtstraegerSteuerpflicht>): RechtstraegerSteuerpflichtVersionFormValues => ({
  validFrom: timeblock.validFrom,
  steuerpflicht: timeblock.steuerpflicht?.value ?? '',
  kommentar: timeblock.kommentar ? timeblock.kommentar : '',
});

export const mapFormValuesToSteuerpflichtVersion = (values: RechtstraegerSteuerpflichtVersionFormValues): SteuerpflichtInput => ({
  validFrom: mapFormDateValueToDateString(values.validFrom),
  steuerpflicht: values.steuerpflicht,
  kommentar: values.kommentar,
});
