import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import { showSuccessMsgOther } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import DeviationTab from '../../../components/Deviation/Tab/DeviationTab';
import { useZinslisteDeviationListQuery } from './gql/ZinslisteDeviationQueries.types';
import { ZinslisteStatusTuple } from '../../../types';
import { useZinslisteApplyAllDeviationsMutation } from './gql/ZinstlisteDeviationMutations.types';
import { useOnZinslisteDataChangedEvents } from '../useOnZinslisteDataChangedEvents';
import { DEVIATIONS_TAB_TITLE } from '../../../components/Deviation/deviationHelpers';

type Props = {
  objektId: string;
  zinslisteId: string;
  status: ZinslisteStatusTuple;
  onAction: () => void;
};

const ZinslisteDeviationList: FC<Props> = ({ objektId, zinslisteId, status, onAction }) => {
  const { data, refetch } = useZinslisteDeviationListQuery({
    variables: {
      objektId,
      zinslisteId,
    },
  });
  const zinslisteDeviationList = data?.getZinslisteDeviationList.data;
  const loading = !zinslisteDeviationList;

  useOnZinslisteDataChangedEvents('zinslisteDeviationList', refetch);

  const [runApplyAllDeviations] = useZinslisteApplyAllDeviationsMutation({
    onCompleted: () => {
      showSuccessMsgOther('Alle Abweichungen sind korrigiert.');
      refetch();
      onAction();
    },
    variables: {
      objektId,
      zinslisteId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: DEVIATIONS_TAB_TITLE,
      children: (
        <DeviationTab
          dataSource={zinslisteDeviationList}
          loading={loading}
          status={status}
          runApplyAllDeviations={runApplyAllDeviations}
          showSourceEntity
        />
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default ZinslisteDeviationList;
