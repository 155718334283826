import React, { FC } from 'react';
import { Typography } from 'antd';
import { FormikProps } from 'formik';
import { BookingSuggestionFormValues } from '../Form/buchungsmaskeFormMapper';
import OffenePostenFromBookingSuggTable from './AuszifferungListingFromBookingSuggestion/Level1/OffenePostenFromBookingSuggTable';
import OffenePostenForPersonenkontoListing from './OffenePostenListingForPersonenkonto/OffenePostenForPersonenkontoListing';
import AvailableAmount from './AvailableAmount';
import { isKontoTypePersonenkonto } from '../../../Kontenplan/Form/kontoFormHelpers';
import DividerForBooking from '../../../BookingDetails/shared/DividerForBooking';
import { Spacer } from '../../../../components/Grid';
import SpaceForBookingBlockAndForm from '../../../BookingDetails/shared/SpaceForBookingBlockAndForm';
import { isTruthy } from '../../../../helpers/assertionHelper';

type Props = {
  formikProps: FormikProps<BookingSuggestionFormValues>;
  auszifferungBlockTitle: string;
};

const AuszifferungBlock: FC<Props> = ({ formikProps, auszifferungBlockTitle }) => {
  const personenkontoList = formikProps.values.buchungsanweisung.buchungszeileList.filter(
    (bz) => isKontoTypePersonenkonto(bz.konto?.type.value) && isTruthy(bz.kontoId) // checking is truthy, as kontoId might be "" (empty string) what will still cause fetching the OffenePosten listing (=OffenePostenForPersonenkontoListing)
  );

  if (personenkontoList.length === 0) {
    return null;
  }

  return personenkontoList.length > 1 ? (
    <Typography.Title level={5}>Auszifferungsvorschläge können nicht angezeigt werden, da zu viele Personenkonten ausgewählt sind.</Typography.Title>
  ) : (
    <SpaceForBookingBlockAndForm>
      <Typography.Title level={5}>{auszifferungBlockTitle}</Typography.Title>
      <Spacer height={8} />
      <AvailableAmount formikProps={formikProps} />
      {/* Table - initial data from bookingSuggestion data if konto had not been changed OR [] if konto had been changed  - controlled by KontoSelect */}
      <OffenePostenFromBookingSuggTable dataList={formikProps.values.offenePostenForClearingSuggestionList} formikProps={formikProps} />
      {formikProps.values.buchungsanweisung.buchungskreisId && (
        <>
          <Spacer height={16} />
          <DividerForBooking dashed />

          <Typography.Title level={5}>Offene Posten</Typography.Title>
          {/* Filter and Table - data fetched separately from BE */}
          <OffenePostenForPersonenkontoListing personenkonto={personenkontoList[0]} formikProps={formikProps} />
        </>
      )}
    </SpaceForBookingBlockAndForm>
  );
};
export default AuszifferungBlock;
