import React, { FC } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import pdfStyles from '../styles/pdfStyles';
import { Zinsliste } from './zinsliste-types';
import Header from '../shared/Header';
import ZZinsliste from './components/ZZinsliste';

type Props = {
  data: Zinsliste;
};

const ZinslisteTemplate: FC<Props> = ({ data }) => (
  <Document>
    <Page size="A4" style={[pdfStyles.body]}>
      {/* Header */}
      {data.header && (
        <View>
          <Header header={data.header} />
        </View>
      )}

      <ZZinsliste zinsliste={data} />

      {/* Footer */}
      <View fixed style={pdfStyles.footer}>
        <View style={[pdfStyles.row, pdfStyles.borderBottomSmall, { borderBottomWidth: 0.4, marginTop: '2mm', marginBottom: '2mm' }]} />
        <Text render={({ pageNumber, totalPages }) => `Seite ${pageNumber} von ${totalPages}`} fixed style={{ textAlign: 'right', fontSize: 6 }} />
      </View>
    </Page>
  </Document>
);

export default ZinslisteTemplate;
