import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { PaymentGenerierlaufType } from '../../../../../types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { useFilterGenLaufPaymentTypeListQuery } from '../../../gql/GenerierlaufFilter/PaymentGenerierlaufFilterQueries.types';

type Props = {
  name: string;
  generierlaufId: string;
  generierlaufType: PaymentGenerierlaufType;
} & SelectProps;

const GenerierlaufPaymentTypeFormSelect: FC<Props> = ({ name, generierlaufId, generierlaufType, ...restProps }) => {
  const { data, loading } = useFilterGenLaufPaymentTypeListQuery({ variables: { generierlaufId, generierlaufType } });

  const list = data?.getFilterGenLaufPaymentTypeList.data ?? [];

  return (
    <Select size="small" loading={loading} id={name} name={name} allowClear placeholder="Zahlungstyp auswählen" {...restProps}>
      {list.map((transactionType) => (
        <Select.Option key={transactionType.value} value={transactionType.value}>
          <DataWithShortenedText maxTextLength={30} text={transactionType.text} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default GenerierlaufPaymentTypeFormSelect;
