import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Skeleton } from 'antd';
import { BookingDetailsWithBookingSuggestionPageQueryParams } from '../../features/BookingDetails/bookingDetailsPaths';
import { PageLayout } from '../../components/LayoutAndromeda';
import { useQueryParams } from '../../hooks/useStringQueryParams';
import BookingDetailsWithBookingSuggestion from '../../features/BookingDetails/View/WithBookingSuggestion/BookingDetailsWithBookingSuggestion';
import { useBuchungszeileListWithOrWithoutUmbuchungen } from '../../features/BookingDetails/View/shared/BuchungszeileListBlock/useBuchungszeileListWithOrWithoutUmbuchungen';

const BookingDetailsWithBookingSuggestionPage = () => {
  const { bookingSuggestionId, buchungIdList } = useQueryParams<BookingDetailsWithBookingSuggestionPageQueryParams>();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { buchungszeileList } = useBuchungszeileListWithOrWithoutUmbuchungen(buchungIdList!, true);

  return (
    <>
      <Helmet>
        <title>Buchungsdetails</title>
      </Helmet>
      <PageLayout>
        {!buchungszeileList.length ? (
          <Skeleton active />
        ) : (
          <BookingDetailsWithBookingSuggestion
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            bookingSuggestionId={bookingSuggestionId!}
            buchungszeileList={buchungszeileList}
          />
        )}
      </PageLayout>
    </>
  );
};

export default BookingDetailsWithBookingSuggestionPage;
