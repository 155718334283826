import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { TPaymentQueryParams } from './filtersQueryParams';
import { ObjectOfStrings } from '../../../../shared/typeHelpers';
import { EmployeeApproval, OrderPaymentStatus, PaymentApprovalStatus, PaymentTransactionType, PaymentType } from '../../../../types';
import { PaymentListQueryVariables } from '../../gql/Order/PaymentQueries.types';
import { isPaymentEmployeeApprovalAll } from '../../paymentHelpers';
import { isNotNil } from '../../../../helpers/assertionHelper';

export enum EmployeeApprovalAll {
  All = 'ALL',
}

export type TEmployeeApproval = EmployeeApproval | EmployeeApprovalAll;

export const PAYMENT_PROPOSAL_ID_NOT_ASSIGNED = 'NOT_ASSIGNED';

export type PaymentListingFiltersFormValues = {
  amountFrom?: number | null;
  amountTo?: number | null;
  approvedByMitarbeiterIdList?: string[];
  assignedToPaymentProposal?: boolean;
  belegnummer?: string | null;
  buchungsdatumBis?: string | null;
  buchungsdatumVon?: string | null;
  dataCarrierIdList?: string[];
  dueDateFrom?: string | null;
  dueDateTo?: string | null;
  employeeApproval?: TEmployeeApproval;
  erstellerMitarbeiterIdList?: string[];
  invoiceDateFrom?: string;
  invoiceDateTo?: string;
  noted?: boolean | null;
  objektIdList?: string[] | null;
  payeeIban?: string;
  payeeId?: string | null;
  paymentApprovalStatusList?: PaymentApprovalStatus[];
  paymentDateFrom?: string | null;
  paymentDateTo?: string | null;
  paymentProposalId?: string;
  paymentTransactionType?: PaymentTransactionType | null;
  paymentTypeList?: PaymentType[];
  rechnungsAusstellerIban?: string;
  rechnungsAusstellerFibuKontoId?: string;
  rechnungsAusstellerId?: string | null;
  sepaCreditorId?: string;
  sepaMandateReference?: string;
  statusList?: OrderPaymentStatus[] | null;
  purposeOfUseText?: string | null;
  paymentReferenceText?: string | null;
};

export const paymentListingFiltersFormFields: FormFields<Omit<PaymentListingFiltersFormValues, 'assignedToPaymentProposal'>> = {
  amountFrom: 'amountFrom',
  amountTo: 'amountTo',
  approvedByMitarbeiterIdList: 'approvedByMitarbeiterIdList',
  belegnummer: 'belegnummer',
  buchungsdatumBis: 'buchungsdatumBis',
  buchungsdatumVon: 'buchungsdatumVon',
  dataCarrierIdList: 'dataCarrierIdList',
  dueDateFrom: 'dueDateFrom',
  dueDateTo: 'dueDateTo',
  employeeApproval: 'employeeApproval',
  erstellerMitarbeiterIdList: 'erstellerMitarbeiterIdList',
  invoiceDateFrom: 'invoiceDateFrom',
  invoiceDateTo: 'invoiceDateTo',
  noted: 'noted',
  objektIdList: 'objektIdList',
  payeeIban: 'payeeIban',
  payeeId: 'payeeId',
  paymentApprovalStatusList: 'paymentApprovalStatusList',
  paymentDateFrom: 'paymentDateFrom',
  paymentDateTo: 'paymentDateTo',
  paymentProposalId: 'paymentProposalId',
  paymentTransactionType: 'paymentTransactionType',
  paymentTypeList: 'paymentTypeList',
  rechnungsAusstellerIban: 'rechnungsAusstellerIban',
  rechnungsAusstellerFibuKontoId: 'rechnungsAusstellerFibuKontoId',
  rechnungsAusstellerId: 'rechnungsAusstellerId',
  sepaCreditorId: 'sepaCreditorId',
  sepaMandateReference: 'sepaMandateReference',
  statusList: 'statusList',
  purposeOfUseText: 'purposeOfUseText',
  paymentReferenceText: 'paymentReferenceText',
};

export const mapQueryParamsToFormValues = (queryParams: TPaymentQueryParams): PaymentListingFiltersFormValues => {
  return {
    amountFrom: queryParams.amountFrom,
    amountTo: queryParams.amountTo,
    approvedByMitarbeiterIdList: queryParams.approvedByMitarbeiterIdList,
    belegnummer: queryParams.belegnummer,
    buchungsdatumBis: queryParams.buchungsdatumBis,
    buchungsdatumVon: queryParams.buchungsdatumVon,
    dataCarrierIdList: queryParams.dataCarrierIdList,
    dueDateFrom: queryParams.dueDateFrom,
    dueDateTo: queryParams.dueDateTo,
    employeeApproval: queryParams.employeeApproval,
    erstellerMitarbeiterIdList: queryParams.erstellerMitarbeiterIdList,
    invoiceDateFrom: queryParams.invoiceDateFrom,
    invoiceDateTo: queryParams.invoiceDateTo,
    noted: queryParams.noted,
    objektIdList: queryParams.objektIdList,
    payeeIban: queryParams.payeeIban,
    payeeId: queryParams.payeeId,
    paymentApprovalStatusList: queryParams.paymentApprovalStatusList,
    paymentDateFrom: queryParams.paymentDateFrom,
    paymentDateTo: queryParams.paymentDateTo,
    paymentProposalId: queryParams.paymentProposalId,
    paymentTransactionType: queryParams.paymentTransactionType,
    paymentTypeList: queryParams.paymentTypeList,
    rechnungsAusstellerIban: queryParams.rechnungsAusstellerIban,
    rechnungsAusstellerFibuKontoId: queryParams.rechnungsAusstellerFibuKontoId,
    rechnungsAusstellerId: queryParams.rechnungsAusstellerId,
    sepaCreditorId: queryParams.sepaCreditorId,
    sepaMandateReference: queryParams.sepaMandateReference,
    statusList: queryParams.statusList,
    purposeOfUseText: queryParams.purposeOfUseText,
    paymentReferenceText: queryParams.paymentReferenceText,
  };
};

export const mapQueryParamsToPaymentListQueryVariables = (queryParams: TPaymentQueryParams): PaymentListQueryVariables => {
  return {
    ...queryParams,
    assignedToPaymentProposal: queryParams.paymentProposalId !== PAYMENT_PROPOSAL_ID_NOT_ASSIGNED ? undefined : false,
    paymentProposalId: queryParams.paymentProposalId === PAYMENT_PROPOSAL_ID_NOT_ASSIGNED ? undefined : queryParams.paymentProposalId,
    belegnummer: queryParams.belegnummer?.toString(),
    employeeApproval:
      !queryParams.employeeApproval || isPaymentEmployeeApprovalAll(queryParams.employeeApproval)
        ? undefined
        : (queryParams.employeeApproval as EmployeeApproval),
  };
};

export const mapFormValuesToPaymentListingFilters = (formValues: PaymentListingFiltersFormValues): TPaymentQueryParams => ({
  approvedByMitarbeiterIdList: formValues.approvedByMitarbeiterIdList,
  belegnummer: formValues.belegnummer,
  amountTo: formValues.amountTo,
  amountFrom: formValues.amountFrom,
  buchungsdatumBis: formValues.buchungsdatumBis ? mapFormDateValueToDateString(formValues.buchungsdatumBis) : undefined,
  buchungsdatumVon: formValues.buchungsdatumVon ? mapFormDateValueToDateString(formValues.buchungsdatumVon) : undefined,
  dataCarrierIdList: formValues.dataCarrierIdList,
  dueDateFrom: formValues.dueDateFrom ? mapFormDateValueToDateString(formValues.dueDateFrom) : undefined,
  dueDateTo: formValues.dueDateTo ? mapFormDateValueToDateString(formValues.dueDateTo) : undefined,
  employeeApproval: formValues.employeeApproval,
  erstellerMitarbeiterIdList: formValues.erstellerMitarbeiterIdList,
  invoiceDateFrom: formValues.invoiceDateFrom ? mapFormDateValueToDateString(formValues.invoiceDateFrom) : undefined,
  invoiceDateTo: formValues.invoiceDateTo ? mapFormDateValueToDateString(formValues.invoiceDateTo) : undefined,
  noted: isNotNil(formValues.noted) ? formValues.noted : undefined, // need to check if is not nil to be able to set the value to false if chosen, otherwise false is treated as undefined
  objektIdList: formValues.objektIdList,
  payeeIban: formValues.payeeIban,
  payeeId: formValues.payeeId,
  paymentApprovalStatusList: formValues.paymentApprovalStatusList,
  paymentDateFrom: formValues.paymentDateFrom ? mapFormDateValueToDateString(formValues.paymentDateFrom) : undefined,
  paymentDateTo: formValues.paymentDateTo ? mapFormDateValueToDateString(formValues.paymentDateTo) : undefined,
  paymentProposalId: formValues.paymentProposalId,
  paymentTransactionType: formValues.paymentTransactionType,
  paymentTypeList: formValues.paymentTypeList,
  rechnungsAusstellerIban: formValues.rechnungsAusstellerIban,
  rechnungsAusstellerFibuKontoId: formValues.rechnungsAusstellerFibuKontoId,
  rechnungsAusstellerId: formValues.rechnungsAusstellerId,
  sepaCreditorId: formValues.sepaCreditorId,
  sepaMandateReference: formValues.sepaMandateReference,
  statusList: formValues.statusList,
  purposeOfUseText: formValues.purposeOfUseText,
  paymentReferenceText: formValues.paymentReferenceText,
});

export const paymentListingLabelList: ObjectOfStrings<
  Omit<
    PaymentListingFiltersFormValues,
    'assignedToPaymentProposal' | 'invoiceDateTo' | 'paymentDateTo' | 'buchungsdatumBis' | 'amountTo' | 'dueDateTo'
  >
> = {
  approvedByMitarbeiterIdList: 'Freigaben durch Mitarbeiter',
  belegnummer: 'Belegnummer',
  amountFrom: 'Betrag',
  buchungsdatumVon: 'Buchungsdatum',
  dataCarrierIdList: 'Datenträger',
  dueDateFrom: 'Fälligkeit',
  employeeApproval: 'Meine Freigabe',
  erstellerMitarbeiterIdList: 'Ersteller',
  invoiceDateFrom: 'Belegdatum',
  noted: 'Gesehen',
  objektIdList: 'Objekt',
  payeeIban: 'Zahlender/Zahlungsempfänger Bankverbindung',
  payeeId: 'Zahlender/Zahlungsempfänger',
  paymentApprovalStatusList: 'Freigabe Status',
  paymentDateFrom: 'Zahlungsdatum',
  paymentProposalId: 'Zahlungsvorschlag',
  paymentTransactionType: 'Zahlungsverkehrsart',
  paymentTypeList: 'Zahlungstyp',
  rechnungsAusstellerIban: 'Buchungskreis Bankverbindung',
  rechnungsAusstellerFibuKontoId: 'Fibu-Bankkonto',
  rechnungsAusstellerId: 'Buchungskreis',
  sepaCreditorId: 'SEPA Creditor-ID',
  sepaMandateReference: 'SEPA Mandatsreferenz',
  statusList: 'Zahlung Status',
  purposeOfUseText: 'Verwendungszweck',
  paymentReferenceText: 'Zahlungsreferenz',
};
