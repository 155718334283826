import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoNotificationActionMutationVariables = Types.Exact<{
  link: Types.Scalars['String']['input'];
  method: Types.Scalars['String']['input'];
  body: Types.Scalars['JSON']['input'];
  notificationType: Types.NotificationType;
}>;

export type DoNotificationActionMutation = {
  doNotificationAction: {
    data: any;
    warningList?: Array<{ message: string; type: string }> | null;
    errorList?: Array<{ message: string; type: string }> | null;
  };
};

export type DismissNotificationMutationVariables = Types.Exact<{
  request: Types.NotificationDismissInput;
}>;

export type DismissNotificationMutation = {
  actionDismissNotification: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const DoNotificationActionDocument = gql`
  mutation doNotificationAction($link: String!, $method: String!, $body: JSON!, $notificationType: NotificationType!) {
    doNotificationAction(method: $method, link: $link, body: $body, notificationType: $notificationType) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDoNotificationActionMutation(
  baseOptions?: Apollo.MutationHookOptions<DoNotificationActionMutation, DoNotificationActionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DoNotificationActionMutation, DoNotificationActionMutationVariables>(DoNotificationActionDocument, options);
}
export type DoNotificationActionMutationHookResult = ReturnType<typeof useDoNotificationActionMutation>;
export type DoNotificationActionMutationResult = Apollo.MutationResult<DoNotificationActionMutation>;
export type DoNotificationActionMutationOptions = Apollo.BaseMutationOptions<DoNotificationActionMutation, DoNotificationActionMutationVariables>;
export const DismissNotificationDocument = gql`
  mutation dismissNotification($request: NotificationDismissInput!) {
    actionDismissNotification(request: $request) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDismissNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<DismissNotificationMutation, DismissNotificationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DismissNotificationMutation, DismissNotificationMutationVariables>(DismissNotificationDocument, options);
}
export type DismissNotificationMutationHookResult = ReturnType<typeof useDismissNotificationMutation>;
export type DismissNotificationMutationResult = Apollo.MutationResult<DismissNotificationMutation>;
export type DismissNotificationMutationOptions = Apollo.BaseMutationOptions<DismissNotificationMutation, DismissNotificationMutationVariables>;
