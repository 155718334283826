import React from 'react';
import FormItemWithoutColon from '../../../../../../components/Form/FormItemWithoutColon';
import { zahlungsregelFormFields } from '../zahlungsregelFormMapper';
import FormattedDecimalFormInput from '../../../../../../components/Input-antd/FormattedDecimalFormInput';

const FixbetragFormItem = () => {
  return (
    <FormItemWithoutColon name={zahlungsregelFormFields.fixbetrag} label="Fixbetrag">
      <FormattedDecimalFormInput
        id={zahlungsregelFormFields.fixbetrag}
        name={zahlungsregelFormFields.fixbetrag}
        placeholder="z.B. 1.000,00"
        size="small"
        style={{ width: '100%' }}
        min={0}
        isCurrency
      />
    </FormItemWithoutColon>
  );
};

export default FixbetragFormItem;
