import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AbrechnungskreisFieldsFragmentDoc } from '../../Abrechnungskreis/gql/AbrechnungskreisFragments.types';
import { AufteilungsschluesselFieldsFragmentDoc } from '../../Aufteilungsschluessel/gql/AufteilungsschluesselFragments.types';
import { VorschreibungspositionFragmentDoc } from '../../Vorschreibungsposition/gql/VorschreibungspositionFragments.types';
import { AppKontoFieldsFragmentDoc } from '../../Kontenplan/gql/KontenplanFragments.types';
export type AbrechnungsdefinitionListEntryFieldsFragment = {
  abrechnungsdefinitionId: string;
  bezeichnung: string;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  firmendatenId?: string | null;
  textBausteineBefuellt: boolean;
  abrechnungsdefinitionVersion?: {
    abrechnungsdefinitionVersionId: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    current: boolean;
    deletable?: boolean | null;
    firmendatenId?: string | null;
    kommentar?: string | null;
    lastUpdateTs: string;
    updatedByMitarbeiterId?: string | null;
    validFrom: string;
    abrechdefAbrechkreisList: Array<{
      abrechnungskreis: {
        abrechnungskreisId: string;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        updatedByMitarbeiterId?: string | null;
        status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
        type: { text: string; value: Types.AbrechnungskreisType };
        verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      aufteilungsschluessel?: {
        aufteilungsschluesselId: string;
        bezeichnung: string;
        bezugsbasisEditable?: boolean | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        bezugsbasis: { text: string; value: Types.Bezugsbasis };
        masseinheit: { text: string; value: Types.Masseinheit };
        status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      vorschreibungsposition?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        vorschreibungspositionId: string;
        art: { text: string; value: Types.VorschreibungspositionArt };
        kontierungstabelle?: {
          basiskonto: number;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kontierungstabelleId: string;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
          subAdministrationExpenseAccount?: {
            bezeichnung: string;
            firmendatenId?: string | null;
            kontoId: string;
            kontoKlasse: string;
            nummer: string;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
        umsatzsteuerkennzeichen?: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        } | null;
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      } | null;
    }>;
    kontoAbrechnungskreisList: Array<{
      abrechnen: boolean;
      buchungszeilenAnzeigen: boolean;
      expenseReducing: boolean;
      ustVomAufwand: boolean;
      abrechnungskreis?: {
        abrechnungskreisId: string;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        updatedByMitarbeiterId?: string | null;
        status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
        type: { text: string; value: Types.AbrechnungskreisType };
        verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      aufteilungsschluessel?: {
        aufteilungsschluesselId: string;
        bezeichnung: string;
        bezugsbasisEditable?: boolean | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        bezugsbasis: { text: string; value: Types.Bezugsbasis };
        masseinheit: { text: string; value: Types.Masseinheit };
        status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
    }>;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  status?: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus } | null;
  type: { text: string; value: Types.AbrechnungsdefinitionType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type AbrechDefAbrechKreisFieldsFragment = {
  abrechnungskreis: {
    abrechnungskreisId: string;
    bezeichnung: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    firmendatenId?: string | null;
    updatedByMitarbeiterId?: string | null;
    status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
    type: { text: string; value: Types.AbrechnungskreisType };
    verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  aufteilungsschluessel?: {
    aufteilungsschluesselId: string;
    bezeichnung: string;
    bezugsbasisEditable?: boolean | null;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    firmendatenId?: string | null;
    kurzBezeichnung: string;
    updatedByMitarbeiterId?: string | null;
    bezugsbasis: { text: string; value: Types.Bezugsbasis };
    masseinheit: { text: string; value: Types.Masseinheit };
    status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  vorschreibungsposition?: {
    bezeichnung: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    firmendatenId?: string | null;
    kurzBezeichnung: string;
    tagList: Array<string>;
    updatedByMitarbeiterId?: string | null;
    vorschreibungspositionId: string;
    art: { text: string; value: Types.VorschreibungspositionArt };
    kontierungstabelle?: {
      basiskonto: number;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      kontierungstabelleId: string;
      updatedByMitarbeiterId?: string | null;
      status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
      subAdministrationExpenseAccount?: {
        bezeichnung: string;
        firmendatenId?: string | null;
        kontoId: string;
        kontoKlasse: string;
        nummer: string;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
    umsatzsteuerkennzeichen?: {
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      kuerzel: string;
      umsatzsteuerkennzeichenId: string;
      status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
    } | null;
    warningList: Array<{ message: string; type: string; attribute?: string | null }>;
  } | null;
};

export type KontoAbrechnungskreisFieldsFragment = {
  abrechnen: boolean;
  buchungszeilenAnzeigen: boolean;
  expenseReducing: boolean;
  ustVomAufwand: boolean;
  abrechnungskreis?: {
    abrechnungskreisId: string;
    bezeichnung: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    firmendatenId?: string | null;
    updatedByMitarbeiterId?: string | null;
    status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
    type: { text: string; value: Types.AbrechnungskreisType };
    verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  aufteilungsschluessel?: {
    aufteilungsschluesselId: string;
    bezeichnung: string;
    bezugsbasisEditable?: boolean | null;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    firmendatenId?: string | null;
    kurzBezeichnung: string;
    updatedByMitarbeiterId?: string | null;
    bezugsbasis: { text: string; value: Types.Bezugsbasis };
    masseinheit: { text: string; value: Types.Masseinheit };
    status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
};

export const KontoAbrechnungskreisFieldsFragmentDoc = gql`
  fragment KontoAbrechnungskreisFields on KontoAbrechnungskreis {
    abrechnen
    abrechnungskreis {
      ...AbrechnungskreisFields
    }
    aufteilungsschluessel {
      ...AufteilungsschluesselFields
    }
    buchungszeilenAnzeigen
    expenseReducing
    konto {
      ...AppKontoFields
    }
    ustVomAufwand
  }
  ${AbrechnungskreisFieldsFragmentDoc}
  ${AufteilungsschluesselFieldsFragmentDoc}
  ${AppKontoFieldsFragmentDoc}
`;
export const AbrechnungsdefinitionListEntryFieldsFragmentDoc = gql`
  fragment AbrechnungsdefinitionListEntryFields on AbrechnungsdefinitionListEntry {
    abrechnungsdefinitionId
    abrechnungsdefinitionVersion {
      abrechdefAbrechkreisList {
        abrechnungskreis {
          ...AbrechnungskreisFields
        }
        aufteilungsschluessel {
          ...AufteilungsschluesselFields
        }
        vorschreibungsposition {
          ...Vorschreibungsposition
        }
      }
      abrechnungsdefinitionVersionId
      createTs
      createdBy
      createdByMitarbeiterId
      current
      deletable
      firmendatenId
      kommentar
      kontoAbrechnungskreisList {
        ...KontoAbrechnungskreisFields
      }
      lastUpdateTs
      updatedByMitarbeiterId
      validFrom
      warningList {
        attribute
        message
        type
      }
    }
    bezeichnung
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    firmendatenId
    status {
      description
      text
      value
    }
    textBausteineBefuellt
    type {
      text
      value
    }
    warningList {
      attribute
      message
      type
    }
  }
  ${AbrechnungskreisFieldsFragmentDoc}
  ${AufteilungsschluesselFieldsFragmentDoc}
  ${VorschreibungspositionFragmentDoc}
  ${KontoAbrechnungskreisFieldsFragmentDoc}
`;
export const AbrechDefAbrechKreisFieldsFragmentDoc = gql`
  fragment AbrechDefAbrechKreisFields on AbrechdefAbrechkreis {
    abrechnungskreis {
      ...AbrechnungskreisFields
    }
    aufteilungsschluessel {
      ...AufteilungsschluesselFields
    }
    vorschreibungsposition {
      ...Vorschreibungsposition
    }
  }
  ${AbrechnungskreisFieldsFragmentDoc}
  ${AufteilungsschluesselFieldsFragmentDoc}
  ${VorschreibungspositionFragmentDoc}
`;
