import React, { FC } from 'react';
import FieldArrayInput from '../../../components/FieldArrayInput';
import { validateNewEmailNotRequired } from './emailValidation';

type EmailFieldArrayInputProps = {
  arrayName: string;
  arrayObjectAttribute: string;
  newFieldValue: Record<string, any>;
  validateOnChange: boolean;
};

const EmailFieldArrayInput: FC<EmailFieldArrayInputProps> = ({ arrayName, arrayObjectAttribute, newFieldValue, validateOnChange }) => (
  <FieldArrayInput
    type="email"
    placeholder="E-Mail-Adresse"
    label="E-Mail-Adresse"
    fieldValidation={validateNewEmailNotRequired}
    arrayName={arrayName}
    arrayObjectAttribute={arrayObjectAttribute}
    newFieldValue={newFieldValue}
    validateOnChange={validateOnChange}
  />
);

export default EmailFieldArrayInput;
