import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { StandingInvoiceFieldsFragmentDoc } from './StandingInvoiceFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StandingInvoiceQueryVariables = Types.Exact<{
  belegId: Types.Scalars['String']['input'];
}>;

export type StandingInvoiceQuery = {
  getStandingInvoice: {
    data: {
      ausstellerBezeichnung: string;
      ausstellerRechtstraegerId: string;
      belegId: string;
      buchungIdList: Array<string>;
      brutto: number;
      buchungskreisId?: string | null;
      fileId: string;
      issuerBankDetailsIban: string;
      issuerBankDetailsId: string;
      kundenNummer?: string | null;
      netto: number;
      objektBezeichnung?: string | null;
      objektId?: string | null;
      offenerBetrag?: number | null;
      paid: boolean;
      payeeBezeichnung: string;
      payeeBankDetailsIban: string;
      payeeBankDetailsId: string;
      payeeRechtstraegerId: string;
      paymentCreatable: boolean;
      paymentIdList: Array<string>;
      paymentReferenceText?: string | null;
      purposeOfUseText?: string | null;
      rechnungsDatum: string;
      rechnungsNummer: string;
      sepaMandatReference?: string | null;
      type: Types.InvoiceType;
      vat: number;
      eingangsRechnungId: string;
      basis: { text: string; value: Types.IncomingInvoiceBasis };
      billingType: { text: string; value: Types.Verrechnungsart };
      partialAmountList: Array<{
        brutto: number;
        buchungIdList: Array<string>;
        buchungsanweisungIdList: Array<string>;
        buchungsdatum: string;
        dueDate: string;
        netto: number;
        offenerBetrag?: number | null;
        paid: boolean;
        partialAmountId: string;
        paymentCreatable: boolean;
        paymentIdList: Array<string>;
        requestedPaymentDate?: string | null;
        buchungErrorList: Array<{ message: string; type: string }>;
        buchungPositionList: Array<{
          betrag: number;
          bestandseinheitId?: string | null;
          bestandseinheitBezeichnung?: string | null;
          buchungstext: string;
          incomingInvoiceBuchungPositionId: string;
          kontoBezeichnung: string;
          kontoId: string;
          kontoNummer: string;
          steuersatz: number;
        }>;
        buchungsanweisungListEntryDataList: Array<{
          buchungsanweisungId: string;
          fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
          status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
        }>;
        buchungsanweisungStatus?: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus } | null;
        steuerzeileList: Array<{ betrag: number; steuersatz: number }>;
      }>;
      paymentMethod: { text: string; value: Types.PaymentMethod };
      status: { text: string; value: Types.IncomingInvoiceStatus };
      warningList: Array<{ message: string; type: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type StandingInvoiceForDetailsAndUpdatePageQueryVariables = Types.Exact<{
  belegId: Types.Scalars['String']['input'];
}>;

export type StandingInvoiceForDetailsAndUpdatePageQuery = {
  getStandingInvoiceForDetailsAndUpdatePage: {
    data: {
      ausstellerBezeichnung: string;
      ausstellerRechtstraegerId: string;
      belegId: string;
      buchungIdList: Array<string>;
      brutto: number;
      buchungskreisId?: string | null;
      fileId: string;
      issuerBankDetailsIban: string;
      issuerBankDetailsId: string;
      kundenNummer?: string | null;
      netto: number;
      objektBezeichnung?: string | null;
      objektId?: string | null;
      offenerBetrag?: number | null;
      paid: boolean;
      payeeBezeichnung: string;
      payeeBankDetailsIban: string;
      payeeBankDetailsId: string;
      payeeRechtstraegerId: string;
      paymentCreatable: boolean;
      paymentIdList: Array<string>;
      paymentReferenceText?: string | null;
      purposeOfUseText?: string | null;
      rechnungsDatum: string;
      rechnungsNummer: string;
      sepaMandatReference?: string | null;
      type: Types.InvoiceType;
      vat: number;
      eingangsRechnungId: string;
      basis: { text: string; value: Types.IncomingInvoiceBasis };
      billingType: { text: string; value: Types.Verrechnungsart };
      partialAmountList: Array<{
        brutto: number;
        buchungIdList: Array<string>;
        buchungsanweisungIdList: Array<string>;
        buchungsdatum: string;
        dueDate: string;
        netto: number;
        offenerBetrag?: number | null;
        paid: boolean;
        partialAmountId: string;
        paymentCreatable: boolean;
        paymentIdList: Array<string>;
        requestedPaymentDate?: string | null;
        buchungErrorList: Array<{ message: string; type: string }>;
        buchungPositionList: Array<{
          betrag: number;
          bestandseinheitId?: string | null;
          bestandseinheitBezeichnung?: string | null;
          buchungstext: string;
          incomingInvoiceBuchungPositionId: string;
          kontoBezeichnung: string;
          kontoId: string;
          kontoNummer: string;
          steuersatz: number;
        }>;
        buchungsanweisungListEntryDataList: Array<{
          buchungsanweisungId: string;
          fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
          status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
        }>;
        buchungsanweisungStatus?: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus } | null;
        steuerzeileList: Array<{ betrag: number; steuersatz: number }>;
      }>;
      paymentMethod: { text: string; value: Types.PaymentMethod };
      status: { text: string; value: Types.IncomingInvoiceStatus };
      warningList: Array<{ message: string; type: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type StandingInvoiceChangeHistoryQueryVariables = Types.Exact<{
  belegId: Types.Scalars['ID']['input'];
}>;

export type StandingInvoiceChangeHistoryQuery = {
  getStandingInvoiceChangeHistory: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const StandingInvoiceDocument = gql`
  query StandingInvoice($belegId: String!) {
    getStandingInvoice(belegId: $belegId) {
      data {
        eingangsRechnungId: belegId
        ...StandingInvoiceFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${StandingInvoiceFieldsFragmentDoc}
`;
export function useStandingInvoiceQuery(
  baseOptions: Apollo.QueryHookOptions<StandingInvoiceQuery, StandingInvoiceQueryVariables> &
    ({ variables: StandingInvoiceQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StandingInvoiceQuery, StandingInvoiceQueryVariables>(StandingInvoiceDocument, options);
}
export function useStandingInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StandingInvoiceQuery, StandingInvoiceQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StandingInvoiceQuery, StandingInvoiceQueryVariables>(StandingInvoiceDocument, options);
}
export function useStandingInvoiceSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<StandingInvoiceQuery, StandingInvoiceQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<StandingInvoiceQuery, StandingInvoiceQueryVariables>(StandingInvoiceDocument, options);
}
export type StandingInvoiceQueryHookResult = ReturnType<typeof useStandingInvoiceQuery>;
export type StandingInvoiceLazyQueryHookResult = ReturnType<typeof useStandingInvoiceLazyQuery>;
export type StandingInvoiceSuspenseQueryHookResult = ReturnType<typeof useStandingInvoiceSuspenseQuery>;
export type StandingInvoiceQueryResult = Apollo.QueryResult<StandingInvoiceQuery, StandingInvoiceQueryVariables>;
export const StandingInvoiceForDetailsAndUpdatePageDocument = gql`
  query StandingInvoiceForDetailsAndUpdatePage($belegId: String!) {
    getStandingInvoiceForDetailsAndUpdatePage(belegId: $belegId) {
      data {
        eingangsRechnungId: belegId
        ...StandingInvoiceFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${StandingInvoiceFieldsFragmentDoc}
`;
export function useStandingInvoiceForDetailsAndUpdatePageQuery(
  baseOptions: Apollo.QueryHookOptions<StandingInvoiceForDetailsAndUpdatePageQuery, StandingInvoiceForDetailsAndUpdatePageQueryVariables> &
    ({ variables: StandingInvoiceForDetailsAndUpdatePageQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StandingInvoiceForDetailsAndUpdatePageQuery, StandingInvoiceForDetailsAndUpdatePageQueryVariables>(
    StandingInvoiceForDetailsAndUpdatePageDocument,
    options
  );
}
export function useStandingInvoiceForDetailsAndUpdatePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StandingInvoiceForDetailsAndUpdatePageQuery, StandingInvoiceForDetailsAndUpdatePageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StandingInvoiceForDetailsAndUpdatePageQuery, StandingInvoiceForDetailsAndUpdatePageQueryVariables>(
    StandingInvoiceForDetailsAndUpdatePageDocument,
    options
  );
}
export function useStandingInvoiceForDetailsAndUpdatePageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<StandingInvoiceForDetailsAndUpdatePageQuery, StandingInvoiceForDetailsAndUpdatePageQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<StandingInvoiceForDetailsAndUpdatePageQuery, StandingInvoiceForDetailsAndUpdatePageQueryVariables>(
    StandingInvoiceForDetailsAndUpdatePageDocument,
    options
  );
}
export type StandingInvoiceForDetailsAndUpdatePageQueryHookResult = ReturnType<typeof useStandingInvoiceForDetailsAndUpdatePageQuery>;
export type StandingInvoiceForDetailsAndUpdatePageLazyQueryHookResult = ReturnType<typeof useStandingInvoiceForDetailsAndUpdatePageLazyQuery>;
export type StandingInvoiceForDetailsAndUpdatePageSuspenseQueryHookResult = ReturnType<typeof useStandingInvoiceForDetailsAndUpdatePageSuspenseQuery>;
export type StandingInvoiceForDetailsAndUpdatePageQueryResult = Apollo.QueryResult<
  StandingInvoiceForDetailsAndUpdatePageQuery,
  StandingInvoiceForDetailsAndUpdatePageQueryVariables
>;
export const StandingInvoiceChangeHistoryDocument = gql`
  query StandingInvoiceChangeHistory($belegId: ID!) {
    getStandingInvoiceChangeHistory(belegId: $belegId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useStandingInvoiceChangeHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<StandingInvoiceChangeHistoryQuery, StandingInvoiceChangeHistoryQueryVariables> &
    ({ variables: StandingInvoiceChangeHistoryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StandingInvoiceChangeHistoryQuery, StandingInvoiceChangeHistoryQueryVariables>(
    StandingInvoiceChangeHistoryDocument,
    options
  );
}
export function useStandingInvoiceChangeHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StandingInvoiceChangeHistoryQuery, StandingInvoiceChangeHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StandingInvoiceChangeHistoryQuery, StandingInvoiceChangeHistoryQueryVariables>(
    StandingInvoiceChangeHistoryDocument,
    options
  );
}
export function useStandingInvoiceChangeHistorySuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<StandingInvoiceChangeHistoryQuery, StandingInvoiceChangeHistoryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<StandingInvoiceChangeHistoryQuery, StandingInvoiceChangeHistoryQueryVariables>(
    StandingInvoiceChangeHistoryDocument,
    options
  );
}
export type StandingInvoiceChangeHistoryQueryHookResult = ReturnType<typeof useStandingInvoiceChangeHistoryQuery>;
export type StandingInvoiceChangeHistoryLazyQueryHookResult = ReturnType<typeof useStandingInvoiceChangeHistoryLazyQuery>;
export type StandingInvoiceChangeHistorySuspenseQueryHookResult = ReturnType<typeof useStandingInvoiceChangeHistorySuspenseQuery>;
export type StandingInvoiceChangeHistoryQueryResult = Apollo.QueryResult<
  StandingInvoiceChangeHistoryQuery,
  StandingInvoiceChangeHistoryQueryVariables
>;
