import { Space, Typography } from 'antd';
import { FC } from 'react';
import { FormattedNumber } from 'react-intl';
import { HomeOutlined } from '@ant-design/icons';
import GrundstuecksdatenListItemActions from './GrundstuecksdatenListItemActions';
import InfoFeldListItem from '../InfoFeldListItem';
import { ObjektInfoFeldGrundstuecksdaten } from '../../../../../../types';
import { StyledSpaceSplitA30 } from '../../../../../../components/Space/styled/Space.style';

type Props = {
  grundstuecksdaten: ObjektInfoFeldGrundstuecksdaten;
  objektId: string;
  onSuccess: () => void;
};

const GrundstuecksdatenListItem: FC<Props> = ({ grundstuecksdaten, objektId, onSuccess }) => {
  return (
    <InfoFeldListItem
      icon={<HomeOutlined />}
      content={{ fileIdList: grundstuecksdaten.fileIdList, props: { height: 48 } }}
      actions={<GrundstuecksdatenListItemActions infoFeld={grundstuecksdaten} objektId={objektId} onSuccess={onSuccess} />}
    >
      <Space direction="vertical" style={{ width: '220px' }}>
        <Typography.Text type="secondary">Grundstücksdaten</Typography.Text>
        <Typography.Text>Grundstücksnummer {grundstuecksdaten.grundstuecksnummer}</Typography.Text>
      </Space>
      <StyledSpaceSplitA30>
        {grundstuecksdaten.bebauteFlaeche && (
          <Typography.Text>
            bebaute Fläche <FormattedNumber value={grundstuecksdaten.bebauteFlaeche} />
          </Typography.Text>
        )}
        {grundstuecksdaten.bauflaeche && (
          <Typography.Text>
            Grundstücksfläche <FormattedNumber value={grundstuecksdaten.bauflaeche} />
          </Typography.Text>
        )}
        {grundstuecksdaten.baujahr && <Typography.Text>Baujahr {grundstuecksdaten.baujahr}</Typography.Text>}
      </StyledSpaceSplitA30>
    </InfoFeldListItem>
  );
};

export default GrundstuecksdatenListItem;
