import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import DeviationTab from '../../../components/Deviation/Tab/DeviationTab';
import { useVorsteuerkuerzungDeviationListQuery } from './gql/VorsteuerkuerzungDeviationQueries.types';
import { useOnVstKuerzungDataChangedEvent } from '../useOnVstKuerzungDataChangedEvent';
import { DEVIATIONS_TAB_TITLE } from '../../../components/Deviation/deviationHelpers';
import { showSuccessMsgDeviationsUebernehmen } from '../../../components/message/message';
import VstKrzApplyDeviationsModal from './ApplyDeviationsModal/VstKrzApplyDeviationsModal';
import { useToggle } from '../../../hooks/useToggle';
import { useVorsteuerkuerzungApplyAllDeviationsMutation } from './gql/VorsteuerkuerzungDeviationMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { isVstKuerzungBuchungStatusNichtVerbucht } from '../../../helpers/statusHelper';
import { generatePathToVorsteuerkuerzungDetailsPage } from '../vorsteuerkuerzungUriPaths';
import { Vorsteuerkuerzung } from '../../../types';

type Props = {
  objektId: string;
  vorsteuerkuerzung: Vorsteuerkuerzung;
  onAction: () => void;
};

const VorsteuerkuerzungDeviationList: FC<Props> = ({ objektId, vorsteuerkuerzung, onAction }) => {
  const { vorsteuerkuerzungId, status, buchungStatus, lastBuchungsdatum } = vorsteuerkuerzung;

  const { data, refetch } = useVorsteuerkuerzungDeviationListQuery({
    variables: {
      objektId,
      vorsteuerkuerzungId,
    },
  });

  const vorsteuerkuerzungDeviationList = data?.getVorsteuerkuerzungDeviationList.data;
  const loading = !vorsteuerkuerzungDeviationList;

  useOnVstKuerzungDataChangedEvent('vorsteuerkuerzungDeviationList', refetch);

  const [runApplyAllDeviations] = useVorsteuerkuerzungApplyAllDeviationsMutation({
    variables: {
      objektId,
      vorsteuerkuerzungId,
    },
    onCompleted: () => {
      showSuccessMsgDeviationsUebernehmen();
      refetch();
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [isModalOpen, toggleIsModalOpen] = useToggle();

  const onSuccess = () => {
    showSuccessMsgDeviationsUebernehmen();
    refetch();
    onAction();
    toggleIsModalOpen();
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: DEVIATIONS_TAB_TITLE,
      children: (
        <>
          <DeviationTab
            dataSource={vorsteuerkuerzungDeviationList}
            loading={loading}
            status={status}
            showSourceEntity
            runApplyAllDeviations={
              !isVstKuerzungBuchungStatusNichtVerbucht(buchungStatus) ? () => toggleIsModalOpen() : () => runApplyAllDeviations()
            }
            reviewErrorUriPath={generatePathToVorsteuerkuerzungDetailsPage(objektId, vorsteuerkuerzungId)}
          />
          <VstKrzApplyDeviationsModal
            objektId={objektId}
            vorsteuerkuerzungId={vorsteuerkuerzungId}
            lastBuchungsdatum={lastBuchungsdatum}
            visible={isModalOpen}
            onSuccess={onSuccess}
            onCancel={toggleIsModalOpen}
          />
        </>
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default VorsteuerkuerzungDeviationList;
