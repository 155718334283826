import { Skeleton } from 'antd';
import { useParams } from 'react-router-dom';
import { MahndefinitionUpdatePageRouteParams } from '../../../../SysSettingsPage/KundenSystem/MahndefinitionPage/Update/routes';
import { useMahndefinitionTemplateSingleQuery } from '../../../../../features/MahndefinitionTemplate/gql/MahndefinitionTemplateQueries.types';
import MahndefinitionTemplateUpdateForm from '../../../../../features/MahndefinitionTemplate/Form/Update/MahndefinitionTemplateUpdateForm';

const AndromedaSysSettingsMahndefinitionUpdatePage = () => {
  const { mahndefinitionId } = useParams() as MahndefinitionUpdatePageRouteParams;

  const { data, loading: loadingMahndefinition } = useMahndefinitionTemplateSingleQuery({
    variables: {
      mahndefinitionId,
    },
  });
  const mahndefinition = data?.getMahndefinitionTemplateSingle.data;

  if (loadingMahndefinition) {
    return <Skeleton active />;
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return <MahndefinitionTemplateUpdateForm mahndefinition={mahndefinition!} />;
};

export default AndromedaSysSettingsMahndefinitionUpdatePage;
