import React, { FC, useState } from 'react';
import { Divider, Space, Typography } from 'antd';
import { BeInfoFeldType } from '../../../../types';
import BeInfoFeldTypeFormSelect from './BeInfoFeldTypeSelect';
import KellerabteilCreateForm from './Kellerabteil/Create/KellerabteilCreateForm';
import ParkplatzCreateForm from './Parkplatz/Create/ParkplatzCreateForm';
import ZimmerCreateForm from './Zimmer/Create/ZimmerCreateForm';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  objektId: string;
  bestandseinheitId: string;
};

const BeInfoFeldCreateForm: FC<Props> = ({ objektId, bestandseinheitId, onSuccess, onCancel }) => {
  const [type, setType] = useState<BeInfoFeldType>();

  let content;
  if (type === BeInfoFeldType.Kellerabteil) {
    content = <KellerabteilCreateForm objektId={objektId} bestandseinheitId={bestandseinheitId} onSuccess={onSuccess} onCancel={onCancel} />;
  }
  if (type === BeInfoFeldType.Parkplatz) {
    content = <ParkplatzCreateForm objektId={objektId} bestandseinheitId={bestandseinheitId} onSuccess={onSuccess} onCancel={onCancel} />;
  }
  if (type === BeInfoFeldType.Zimmer) {
    content = <ZimmerCreateForm objektId={objektId} bestandseinheitId={bestandseinheitId} onSuccess={onSuccess} onCancel={onCancel} />;
  }

  return (
    <>
      <Space style={{ marginBottom: '16px' }}>
        <Typography.Text>Art der Information</Typography.Text>
        <BeInfoFeldTypeFormSelect onChange={setType} />
      </Space>
      <Divider />
      {content}
    </>
  );
};

export default BeInfoFeldCreateForm;
