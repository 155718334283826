import { Space, Typography } from 'antd';
import React, { FC } from 'react';
import { Switch } from 'formik-antd';
import { mahnungUpdateFormFields } from './mahnungUpdateFormMapper';
import FormattedDecimalFormInput from '../../../../components/Input-antd/FormattedDecimalFormInput';
import ParamFormItem from '../../../../components/Form/ParamFormItem';
import { EuroAmount } from '../../../../components/Number';
import { MahnungSumVerzugszinsen } from '../../../../types';

type MahnungUpdateFormGeneralParamsProps = {
  onMahnungVerzugszinsenProzentChange: (mahnungVerzugszinsen: number) => void;
  isStatusErstellt: boolean;
  isVersandEinschreiben: boolean;
  sumVerzugszinsenData?: MahnungSumVerzugszinsen;
};

const MahnungUpdateFormGeneralParams: FC<MahnungUpdateFormGeneralParamsProps> = ({
  onMahnungVerzugszinsenProzentChange,
  sumVerzugszinsenData,
  isVersandEinschreiben,
  isStatusErstellt,
}) => {
  return (
    <>
      <Typography.Title level={5} style={{ marginBottom: '16px' }}>
        Allgemein
      </Typography.Title>
      <Space direction="vertical" style={{ width: '50%' }}>
        <ParamFormItem name={mahnungUpdateFormFields.mahngebuehr} label="Mahngebühr" textAfter="€">
          <FormattedDecimalFormInput
            name={mahnungUpdateFormFields.mahngebuehr}
            style={{ width: '150px' }}
            controls={false}
            min={0}
            disabled={!isStatusErstellt}
          />
        </ParamFormItem>

        <ParamFormItem
          name={mahnungUpdateFormFields.verzugszinsen}
          label="Verzugszinsen"
          textAfter={
            <Typography.Text>
              % p.a. ergibt {sumVerzugszinsenData ? <EuroAmount value={sumVerzugszinsenData.sumVerzugszinsen} /> : <EuroAmount value={0} />}
            </Typography.Text>
          }
        >
          <FormattedDecimalFormInput
            name={mahnungUpdateFormFields.verzugszinsen}
            style={{ width: '150px' }}
            controls={false}
            onChange={(value) => (value !== null ? onMahnungVerzugszinsenProzentChange(value as number) : onMahnungVerzugszinsenProzentChange(0))}
            min={0}
            disabled={!isStatusErstellt}
          />
        </ParamFormItem>
        <ParamFormItem name={mahnungUpdateFormFields.versandEinschreiben} label="Versand per Einschreiben">
          <Space>
            <Switch
              name={mahnungUpdateFormFields.versandEinschreiben}
              id={mahnungUpdateFormFields.versandEinschreiben}
              size="small"
              disabled={!isStatusErstellt}
            />
            <Typography.Text>{isVersandEinschreiben ? 'Ja' : 'Nein'}</Typography.Text>
          </Space>
        </ParamFormItem>
      </Space>
    </>
  );
};

export default MahnungUpdateFormGeneralParams;
