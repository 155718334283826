import { Month } from '../../../types';

export const mapGermanMonthToEnglishMonth = (month: string): Month => {
  switch (month) {
    case 'Januar':
      return Month.January;
    case 'Februar':
      return Month.February;
    case 'März':
      return Month.March;
    case 'April':
      return Month.April;
    case 'Mai':
      return Month.May;
    case 'Juni':
      return Month.June;
    case 'Juli':
      return Month.July;
    case 'August':
      return Month.August;
    case 'September':
      return Month.September;
    case 'Oktober':
      return Month.October;
    case 'November':
      return Month.November;
    case 'Dezember':
      return Month.December;
    default:
      return Month.January;
  }
};

export const mapEnglishMonthToGermanMonth = (month: Month): string => {
  switch (month) {
    case Month.January:
      return 'Januar';
    case Month.February:
      return 'Februar';
    case Month.March:
      return 'März';
    case Month.April:
      return 'April';
    case Month.May:
      return 'Mai';
    case Month.June:
      return 'Juni';
    case Month.July:
      return 'Juli';
    case Month.August:
      return 'August';
    case Month.September:
      return 'September';
    case Month.October:
      return 'Oktober';
    case Month.November:
      return 'November';
    case Month.December:
      return 'Dezember';
    default:
      return '';
  }
};
