import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import { UstKategorieFieldsFragmentDoc, UstKategorieEntryFieldsFragmentDoc } from '../../../UstKategorie/gql/UstKategorieFragments.types';
export type UstRegelsetUstKategorieZuweisungFieldsFragment = {
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  current: boolean;
  validFrom: string;
  ustRegelsetKategorieZuweisungId: string;
  ustKategorie: {
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    firmendatenId?: string | null;
    kurzBezeichnung: string;
    ustKategorieId: string;
    ustVomAufwand?: number | null;
    vstKuerzung?: boolean | null;
    status?: { text: string; value: Types.UstKategorieStatus } | null;
    ustKategorieEntryList: Array<{
      additionKonto: number;
      buchungsKZ?: string | null;
      createTs?: string | null;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      rechnungskennzeichen: string;
      steuersatz: number;
      ustKategorieEntryId?: string | null;
      ustKz: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kuerzel: string;
        umsatzsteuerkennzeichenId: string;
        status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  warningList: Array<{ message: string; attribute?: string | null; type: string }>;
};

export const UstRegelsetUstKategorieZuweisungFieldsFragmentDoc = gql`
  fragment UstRegelsetUstKategorieZuweisungFields on UstRegelsetUstKategorieZuweisung {
    createTs
    createdBy
    createdByMitarbeiterId
    current
    validFrom
    ustKategorie {
      ...UstKategorieFields
    }
    ustRegelsetKategorieZuweisungId
    warningList {
      message
      attribute
      type
    }
  }
  ${UstKategorieFieldsFragmentDoc}
`;
