import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { OrderBankDetailsFieldsFragmentDoc, BankDetailsFieldsFragmentDoc } from '../../../../features/BankDetails/gql/BankDetailsFragments.types';
import { RechtstraegerFieldsFragmentDoc } from '../../../../features/Rechtstraeger/gql/RechtsTraegerFragments.types';
import {
  BestandseinheitFieldsFragmentDoc,
  BestandseinheitBaseFieldsFragmentDoc,
} from '../../../../features/Bestandseinheit/gql/BestandseinheitFragments.types';
import { ObjektFragmentDoc } from '../../../../features/Objekt/gql/ObjektFragments.types';
export type EigenbelegFragment = {
  auftragIdList: Array<string>;
  ausstellerRechtstraegerId: string;
  belegId: string;
  belegdatum: string;
  belegnummer: string;
  bezeichnung: string;
  buchungIdList: Array<string>;
  buchungsanweisungIdList: Array<string>;
  buchungskreisId?: string | null;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  empfaengerRechtstraegerId: string;
  mailId?: string | null;
  paid: boolean;
  sumBrutto?: number | null;
  sumNetto?: number | null;
  sumUst?: number | null;
  auftragStatusDetailsList: Array<{
    auftragId: string;
    offenerBetrag?: number | null;
    paymentCreatable: boolean;
    paymentIdList: Array<string>;
    buchungsanweisungStatus?: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus } | null;
    eigenbelegStatus: { description?: string | null; text: string; value: Types.EigenbelegStatus };
    fakturierungsperiode: { jahr: number; monat: number };
  }>;
  ausstellerBankDetails: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string };
  ausstellerRechtstraeger: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    fibuKontoCreatable: boolean;
    kundennummer: string;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    tagList: Array<string>;
    updatedByMitarbeiterId?: string | null;
    bankDetailsList: Array<{
      accountHolder: string;
      accountLimit?: number | null;
      assignedHeVertragZahlungsregelMap: any;
      assignedObjektMap: any;
      bankDetailsId: string;
      bankname: string;
      bic: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      ebicsCreditTransferUserList: Array<string>;
      ebicsDirectDebitUserList: Array<string>;
      fibuKontoId?: string | null;
      fibuKontonummer?: string | null;
      iban: string;
      updatedByMitarbeiterId?: string | null;
      fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
      fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
      sepaMandat?: {
        fileId?: string | null;
        fileName?: string | null;
        sepaMandatId: string;
        sepaMandatReference: string;
        signatureDate?: string | null;
        firmendaten?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firmendatenId: string;
          kundenSystemId: string;
          kurzBezeichnung: string;
          name1: string;
          name2?: string | null;
          name3?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechnungsAussteller?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
        warningList: Array<{ message: string; type: string }>;
      } | null;
      status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    personenbezugList: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      personenbezugId: string;
      tagList: Array<string>;
      person: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    postIt?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      immerAnzeigen: boolean;
      postItId: string;
      text: string;
      titel: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    rechtstraegerSteuerpflicht?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      kommentar?: string | null;
      lastUpdateTs: string;
      rechtstraegerSteuerpflichtId: string;
      validFrom: string;
      historicizedList?: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        rechtstraegerSteuerpflichtId: string;
        validFrom: string;
        steuerpflicht: { text: string; value: Types.Steuerpflicht };
        warningList: Array<{ message: string; type: string }>;
      }> | null;
      steuerpflicht: { text: string; value: Types.Steuerpflicht };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
    sprache: { text: string; value: Types.Sprache };
    status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
    type: { value: Types.RechtstraegerType; text: string };
    vertragSummary?: {
      heVertragCount: number;
      heVertragVerwaltungCount: number;
      mietVertragCount: number;
      mietVertragVerwaltungCount: number;
      weSubverwaltungCount: number;
      weVertragCount: number;
      weVertragVerwaltungCount: number;
    } | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  belegform: { text: string; value: Types.BelegFormArt };
  buchungErrorList: Array<{ message: string; type: string }>;
  buchungsanweisungListEntryDataList: Array<{
    buchungsanweisungId: string;
    status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
    fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
  }>;
  buchungsanweisungStatus?: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus } | null;
  deviationDetails?: {
    umbuchungInfoList: Array<{
      fakturierungsperiode: { jahr: number; monat: number };
      kontoKeyIst: { nummer: string; klasse: { text: string; value: Types.KontoKlasse } };
      kontoKeySoll: { nummer: string; klasse: { text: string; value: Types.KontoKlasse } };
    }>;
  } | null;
  dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
  eigenbelegPositionList: Array<{
    bestandseinheitId: string;
    bezeichnung: string;
    brutto: number;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    eigenbelegsPositionId: string;
    generierDatum?: string | null;
    generierlaufId?: string | null;
    netto: number;
    objektId: string;
    performancePeriodFromInclusive: string;
    performancePeriodToInclusive: string;
    steuersatz: number;
    ust: number;
    ustKategorieEntryId: string;
    ustKategorieId: string;
    vertragId: string;
    vorschreibungspositionBezeichnung: string;
    vorschreibungspositionId: string;
    bestandseinheit?: {
      aktivSeit?: string | null;
      bauteil?: string | null;
      bestandseinheitId: string;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      geschoss?: string | null;
      inaktivSeit?: string | null;
      nutzflaeche?: number | null;
      nutzwert?: number | null;
      stiege?: string | null;
      tagList: Array<string>;
      address: {
        addressId: string;
        city: string;
        cityAdditionalInformation?: string | null;
        countryCodeIso2: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        houseEntranceApartmentNumber?: string | null;
        postofficeBoxNumber?: string | null;
        postofficeBoxZipCode?: string | null;
        street?: string | null;
        tagList: Array<string>;
        type: Types.AddressType;
        zipCode?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      nutzungsArt?: { text: string; value: Types.NutzungsArt } | null;
      optionSteuerpflicht?: {
        beOptionSteuerpflichtId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        optionSteuerpflicht: boolean;
        validFrom: string;
        historicizedList?: Array<{
          beOptionSteuerpflichtId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          optionSteuerpflicht: boolean;
          validFrom: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }> | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      objektVerwaltungsart: { text: string; value: Types.Verwaltungsart };
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    labelList: Array<{ label: string; value: string }>;
    objekt?: {
      countryCodeIso2: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      fotoFileId?: string | null;
      fotoType?: Types.FotoType | null;
      kurzBezeichnung: string;
      objektId: string;
      rechnungsAusstellerBezeichnung?: string | null;
      rechnungsAusstellerChangeable?: boolean | null;
      subAdministration: boolean;
      sumNutzflaeche: number;
      sumNutzwert: number;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      verwaltungBis?: string | null;
      verwaltungSeit: string;
      verwaltungsartChangeable?: boolean | null;
      addressList: Array<{
        addressId: string;
        city: string;
        cityAdditionalInformation?: string | null;
        countryCodeIso2: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        houseEntranceApartmentNumber?: string | null;
        postofficeBoxNumber?: string | null;
        postofficeBoxZipCode?: string | null;
        street?: string | null;
        tagList: Array<string>;
        type: Types.AddressType;
        zipCode?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      objektSummeAnteile?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        kommentar?: string | null;
        lastUpdateTs: string;
        objektSummeAnteileId: string;
        summeAnteile: number;
        validFrom: string;
        historicizedList?: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          kommentar?: string | null;
          lastUpdateTs: string;
          objektSummeAnteileId: string;
          summeAnteile: number;
          validFrom: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }> | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechnungsAussteller: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fibuKontoCreatable: boolean;
        kundennummer: string;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        bankDetailsList: Array<{
          accountHolder: string;
          accountLimit?: number | null;
          assignedHeVertragZahlungsregelMap: any;
          assignedObjektMap: any;
          bankDetailsId: string;
          bankname: string;
          bic: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          ebicsCreditTransferUserList: Array<string>;
          ebicsDirectDebitUserList: Array<string>;
          fibuKontoId?: string | null;
          fibuKontonummer?: string | null;
          iban: string;
          updatedByMitarbeiterId?: string | null;
          fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
          fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
          sepaMandat?: {
            fileId?: string | null;
            fileName?: string | null;
            sepaMandatId: string;
            sepaMandatReference: string;
            signatureDate?: string | null;
            firmendaten?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firmendatenId: string;
              kundenSystemId: string;
              kurzBezeichnung: string;
              name1: string;
              name2?: string | null;
              name3?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechnungsAussteller?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
            warningList: Array<{ message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        personenbezugList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          personenbezugId: string;
          tagList: Array<string>;
          person: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechtstraegerSteuerpflicht?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          historicizedList?: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ message: string; type: string }>;
          }> | null;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
        sprache: { text: string; value: Types.Sprache };
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { value: Types.RechtstraegerType; text: string };
        vertragSummary?: {
          heVertragCount: number;
          heVertragVerwaltungCount: number;
          mietVertragCount: number;
          mietVertragVerwaltungCount: number;
          weSubverwaltungCount: number;
          weVertragCount: number;
          weVertragVerwaltungCount: number;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      sachbearbeiter?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        fileStorageInitialized: boolean;
        firmendatenId: string;
        firstname: string;
        funktionsModulList: Array<string>;
        lastname: string;
        mitarbeiterId: string;
        title?: string | null;
        titleTrailing?: string | null;
        username: string;
        contactPerson?: {
          contactPersonId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firstname: string;
          lastname: string;
          tagList: Array<string>;
          title?: string | null;
          titleTrailing?: string | null;
          contactList: Array<{
            contactId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            haupt?: boolean | null;
            tagList: Array<string>;
            type: string;
            wert: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          rechtstraeger?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rolleList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          name: string;
          rolleId: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
        status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
        user?: {
          email?: string | null;
          emailUnverified?: string | null;
          firstname?: string | null;
          lastname?: string | null;
          role: string;
          username: string;
          status: { text: string; value: string };
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { text: string; value: Types.ObjektStatus; description?: string | null };
      verwaltungsart: { text: string; value: Types.Verwaltungsart };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
  }>;
  empfaengerBankDetails?: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string } | null;
  empfaengerRechtstraeger: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    fibuKontoCreatable: boolean;
    kundennummer: string;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    tagList: Array<string>;
    updatedByMitarbeiterId?: string | null;
    bankDetailsList: Array<{
      accountHolder: string;
      accountLimit?: number | null;
      assignedHeVertragZahlungsregelMap: any;
      assignedObjektMap: any;
      bankDetailsId: string;
      bankname: string;
      bic: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      ebicsCreditTransferUserList: Array<string>;
      ebicsDirectDebitUserList: Array<string>;
      fibuKontoId?: string | null;
      fibuKontonummer?: string | null;
      iban: string;
      updatedByMitarbeiterId?: string | null;
      fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
      fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
      sepaMandat?: {
        fileId?: string | null;
        fileName?: string | null;
        sepaMandatId: string;
        sepaMandatReference: string;
        signatureDate?: string | null;
        firmendaten?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firmendatenId: string;
          kundenSystemId: string;
          kurzBezeichnung: string;
          name1: string;
          name2?: string | null;
          name3?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechnungsAussteller?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
        warningList: Array<{ message: string; type: string }>;
      } | null;
      status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    personenbezugList: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      personenbezugId: string;
      tagList: Array<string>;
      person: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    postIt?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      immerAnzeigen: boolean;
      postItId: string;
      text: string;
      titel: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    rechtstraegerSteuerpflicht?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      kommentar?: string | null;
      lastUpdateTs: string;
      rechtstraegerSteuerpflichtId: string;
      validFrom: string;
      historicizedList?: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        rechtstraegerSteuerpflichtId: string;
        validFrom: string;
        steuerpflicht: { text: string; value: Types.Steuerpflicht };
        warningList: Array<{ message: string; type: string }>;
      }> | null;
      steuerpflicht: { text: string; value: Types.Steuerpflicht };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
    sprache: { text: string; value: Types.Sprache };
    status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
    type: { value: Types.RechtstraegerType; text: string };
    vertragSummary?: {
      heVertragCount: number;
      heVertragVerwaltungCount: number;
      mietVertragCount: number;
      mietVertragVerwaltungCount: number;
      weSubverwaltungCount: number;
      weVertragCount: number;
      weVertragVerwaltungCount: number;
    } | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  fakturierungsperiode: { jahr: number; monat: number };
  fileInfo: { fileId: string; name: string; size: number };
  paymentMethod: { text: string; value: Types.PaymentMethod };
  status: { description?: string | null; text: string; value: Types.EigenbelegStatus };
  type: { text: string; value: Types.EigenbelegType };
  verrechnungsart: { text: string; value: Types.Verrechnungsart };
};

export type EigenbelegsPositionFragment = {
  bestandseinheitId: string;
  bezeichnung: string;
  brutto: number;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  eigenbelegsPositionId: string;
  generierDatum?: string | null;
  generierlaufId?: string | null;
  netto: number;
  objektId: string;
  performancePeriodFromInclusive: string;
  performancePeriodToInclusive: string;
  steuersatz: number;
  ust: number;
  ustKategorieEntryId: string;
  ustKategorieId: string;
  vertragId: string;
  vorschreibungspositionBezeichnung: string;
  vorschreibungspositionId: string;
  bestandseinheit?: {
    aktivSeit?: string | null;
    bauteil?: string | null;
    bestandseinheitId: string;
    bezeichnung: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    geschoss?: string | null;
    inaktivSeit?: string | null;
    nutzflaeche?: number | null;
    nutzwert?: number | null;
    stiege?: string | null;
    tagList: Array<string>;
    address: {
      addressId: string;
      city: string;
      cityAdditionalInformation?: string | null;
      countryCodeIso2: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      haupt?: boolean | null;
      houseEntranceApartmentNumber?: string | null;
      postofficeBoxNumber?: string | null;
      postofficeBoxZipCode?: string | null;
      street?: string | null;
      tagList: Array<string>;
      type: Types.AddressType;
      zipCode?: string | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    nutzungsArt?: { text: string; value: Types.NutzungsArt } | null;
    optionSteuerpflicht?: {
      beOptionSteuerpflichtId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      kommentar?: string | null;
      lastUpdateTs: string;
      optionSteuerpflicht: boolean;
      validFrom: string;
      historicizedList?: Array<{
        beOptionSteuerpflichtId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        optionSteuerpflicht: boolean;
        validFrom: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    objektVerwaltungsart: { text: string; value: Types.Verwaltungsart };
    postIt?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      immerAnzeigen: boolean;
      postItId: string;
      text: string;
      titel: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  labelList: Array<{ label: string; value: string }>;
  objekt?: {
    countryCodeIso2: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    fotoFileId?: string | null;
    fotoType?: Types.FotoType | null;
    kurzBezeichnung: string;
    objektId: string;
    rechnungsAusstellerBezeichnung?: string | null;
    rechnungsAusstellerChangeable?: boolean | null;
    subAdministration: boolean;
    sumNutzflaeche: number;
    sumNutzwert: number;
    tagList: Array<string>;
    updatedByMitarbeiterId?: string | null;
    verwaltungBis?: string | null;
    verwaltungSeit: string;
    verwaltungsartChangeable?: boolean | null;
    addressList: Array<{
      addressId: string;
      city: string;
      cityAdditionalInformation?: string | null;
      countryCodeIso2: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      haupt?: boolean | null;
      houseEntranceApartmentNumber?: string | null;
      postofficeBoxNumber?: string | null;
      postofficeBoxZipCode?: string | null;
      street?: string | null;
      tagList: Array<string>;
      type: Types.AddressType;
      zipCode?: string | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    objektSummeAnteile?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      kommentar?: string | null;
      lastUpdateTs: string;
      objektSummeAnteileId: string;
      summeAnteile: number;
      validFrom: string;
      historicizedList?: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        kommentar?: string | null;
        lastUpdateTs: string;
        objektSummeAnteileId: string;
        summeAnteile: number;
        validFrom: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    postIt?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      immerAnzeigen: boolean;
      postItId: string;
      text: string;
      titel: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    rechnungsAussteller: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      fibuKontoCreatable: boolean;
      kundennummer: string;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      bankDetailsList: Array<{
        accountHolder: string;
        accountLimit?: number | null;
        assignedHeVertragZahlungsregelMap: any;
        assignedObjektMap: any;
        bankDetailsId: string;
        bankname: string;
        bic: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        ebicsCreditTransferUserList: Array<string>;
        ebicsDirectDebitUserList: Array<string>;
        fibuKontoId?: string | null;
        fibuKontonummer?: string | null;
        iban: string;
        updatedByMitarbeiterId?: string | null;
        fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
        fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
        sepaMandat?: {
          fileId?: string | null;
          fileName?: string | null;
          sepaMandatId: string;
          sepaMandatReference: string;
          signatureDate?: string | null;
          firmendaten?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firmendatenId: string;
            kundenSystemId: string;
            kurzBezeichnung: string;
            name1: string;
            name2?: string | null;
            name3?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechnungsAussteller?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
          warningList: Array<{ message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      personenbezugList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        personenbezugId: string;
        tagList: Array<string>;
        person: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechtstraegerSteuerpflicht?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        rechtstraegerSteuerpflichtId: string;
        validFrom: string;
        historicizedList?: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ message: string; type: string }>;
        }> | null;
        steuerpflicht: { text: string; value: Types.Steuerpflicht };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
      sprache: { text: string; value: Types.Sprache };
      status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
      type: { value: Types.RechtstraegerType; text: string };
      vertragSummary?: {
        heVertragCount: number;
        heVertragVerwaltungCount: number;
        mietVertragCount: number;
        mietVertragVerwaltungCount: number;
        weSubverwaltungCount: number;
        weVertragCount: number;
        weVertragVerwaltungCount: number;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    sachbearbeiter?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      fileStorageInitialized: boolean;
      firmendatenId: string;
      firstname: string;
      funktionsModulList: Array<string>;
      lastname: string;
      mitarbeiterId: string;
      title?: string | null;
      titleTrailing?: string | null;
      username: string;
      contactPerson?: {
        contactPersonId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firstname: string;
        lastname: string;
        tagList: Array<string>;
        title?: string | null;
        titleTrailing?: string | null;
        contactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        rechtstraeger?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rolleList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        name: string;
        rolleId: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
      status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
      user?: {
        email?: string | null;
        emailUnverified?: string | null;
        firstname?: string | null;
        lastname?: string | null;
        role: string;
        username: string;
        status: { text: string; value: string };
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    status: { text: string; value: Types.ObjektStatus; description?: string | null };
    verwaltungsart: { text: string; value: Types.Verwaltungsart };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
};

export const EigenbelegsPositionFragmentDoc = gql`
  fragment EigenbelegsPosition on EigenbelegsPosition {
    bestandseinheit {
      ...BestandseinheitFields
    }
    bestandseinheitId
    bezeichnung
    brutto
    createTs
    createdBy
    createdByMitarbeiterId
    eigenbelegsPositionId
    generierDatum
    generierlaufId
    labelList {
      label
      value
    }
    netto
    objekt {
      ...Objekt
    }
    objektId
    performancePeriodFromInclusive
    performancePeriodToInclusive
    steuersatz
    ust
    ustKategorieEntryId
    ustKategorieId
    vertragId
    vorschreibungspositionBezeichnung
    vorschreibungspositionId
  }
  ${BestandseinheitFieldsFragmentDoc}
  ${ObjektFragmentDoc}
`;
export const EigenbelegFragmentDoc = gql`
  fragment Eigenbeleg on Eigenbeleg {
    auftragIdList
    auftragStatusDetailsList {
      auftragId
      buchungsanweisungStatus {
        description
        text
        value
      }
      eigenbelegStatus {
        description
        text
        value
      }
      fakturierungsperiode {
        jahr
        monat
      }
      offenerBetrag
      paymentCreatable
      paymentIdList
    }
    ausstellerBankDetails {
      ...OrderBankDetailsFields
    }
    ausstellerRechtstraeger {
      ...RechtstraegerFields
    }
    ausstellerRechtstraegerId
    belegId
    belegdatum
    belegform {
      text
      value
    }
    belegnummer
    bezeichnung
    buchungErrorList {
      message
      type
    }
    buchungIdList
    buchungsanweisungIdList
    buchungsanweisungListEntryDataList {
      buchungsanweisungId
      status {
        description
        text
        value
      }
      fibuStatus {
        description
        text
        value
      }
    }
    buchungsanweisungStatus {
      description
      text
      value
    }
    buchungskreisId
    createTs
    createdBy
    createdByMitarbeiterId
    deviationDetails {
      umbuchungInfoList {
        fakturierungsperiode {
          jahr
          monat
        }
        kontoKeyIst {
          klasse {
            text
            value
          }
          nummer
        }
        kontoKeySoll {
          klasse {
            text
            value
          }
          nummer
        }
      }
    }
    dueDateVorschreibung {
      text
      value
    }
    eigenbelegPositionList {
      ...EigenbelegsPosition
    }
    empfaengerBankDetails {
      ...OrderBankDetailsFields
    }
    empfaengerRechtstraeger {
      ...RechtstraegerFields
    }
    empfaengerRechtstraegerId
    fakturierungsperiode {
      jahr
      monat
    }
    fileInfo {
      fileId
      name
      size
    }
    mailId
    paid
    paymentMethod {
      text
      value
    }
    status {
      description
      text
      value
    }
    sumBrutto
    sumNetto
    sumUst
    type {
      text
      value
    }
    verrechnungsart {
      text
      value
    }
  }
  ${OrderBankDetailsFieldsFragmentDoc}
  ${RechtstraegerFieldsFragmentDoc}
  ${EigenbelegsPositionFragmentDoc}
`;
