import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { topAbrDefGeneralSettingsFormFields, TopAbrDefGeneralSettingsFormValues } from '../../topAbrDefGeneralSettingsFormMapper';
import DisplayRueckstandOtherPersonsFormItem from './DisplayRueckstandOtherPersonsFormItem';
import RueckstandOtherPersonsThresholdFormItem from './RueckstandOtherPersonsThresholdFormItem';

type Props = {
  formikProps: FormikProps<TopAbrDefGeneralSettingsFormValues>;
  isDisabled: boolean;
  onChange: (displayRueckstandOtherPersons: boolean, rueckstandOtherPersonsThreshold: number | null) => void;
  fieldHelpNames: {
    displayRueckstandOtherPersons?: string | null;
    rueckstandOtherPersonsThreshold?: string | null;
  };
};

const RueckstandOtherPersonsFormItems: FC<Props> = ({ formikProps, isDisabled, onChange, fieldHelpNames }) => (
  <>
    <DisplayRueckstandOtherPersonsFormItem
      formikProps={formikProps}
      isDisabled={isDisabled}
      onChange={onChange}
      fieldHelp={fieldHelpNames.displayRueckstandOtherPersons}
    />
    <RueckstandOtherPersonsThresholdFormItem
      name={topAbrDefGeneralSettingsFormFields.rueckstandOtherPersonsThreshold}
      isDisabled={isDisabled || !formikProps.values.displayRueckstandOtherPersons}
      onChange={(value) => onChange(formikProps.values.displayRueckstandOtherPersons, value)}
      fieldHelp={fieldHelpNames.rueckstandOtherPersonsThreshold}
    />
  </>
);

export default RueckstandOtherPersonsFormItems;
