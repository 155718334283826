import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../styles/pdfStyles';

const OAAbrKreisAusgabenDetailsTitle: FC<{ titleText?: string | null }> = ({ titleText }) => (
  <View style={[pdfStyles.row, pdfStyles.tablePaddingLeftRight, { marginTop: '5mm' }]}>
    <Text style={[pdfStyles.textNormal, { textDecoration: 'underline', width: '80%', fontWeight: 'bold' }]}>{titleText}</Text>
  </View>
);

export default OAAbrKreisAusgabenDetailsTitle;
