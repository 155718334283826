import React, { FC } from 'react';
import { Space, Tag, Tooltip } from 'antd';

import { InfoCircleOutlined } from '@ant-design/icons';
import { BeAufteilingsschluesselWert, BeAufteilingsschluesselWertVerwaltungSeitRelation, BezugsbasisTuple } from '../../../../../types';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  record: BeAufteilingsschluesselWert;
  bezugsbasis: BezugsbasisTuple;
};

const BeAufteilungsschluesselWertMitBezugValidFromWithInfo: FC<Props> = ({ record, bezugsbasis }) => {
  const isCurrentTag = record.current ? <Tag>Aktiv</Tag> : null;

  if (!record.validFrom) {
    return null;
  } else if (record.beAufteilungsschluesselWertId === null) {
    return (
      <Space size={4}>
        <CustomFormattedDate value={record.validFrom} />
        <Tooltip placement="topLeft" title={`${bezugsbasis.text} Änderung`}>
          <InfoCircleOutlined style={{ cursor: 'help' }} />
        </Tooltip>
        {isCurrentTag}
      </Space>
    );
  } else if (record.verwaltungSeitRelation.value === BeAufteilingsschluesselWertVerwaltungSeitRelation.IstVerwaltungSeit) {
    return (
      <Space size={4}>
        <CustomFormattedDate value={record.validFrom} />
        <Tooltip placement="topLeft" title={record.verwaltungSeitRelation.text}>
          <InfoCircleOutlined style={{ cursor: 'help' }} />
        </Tooltip>
        {isCurrentTag}
      </Space>
    );
  } else {
    return (
      <Space size={4}>
        <CustomFormattedDate value={record.validFrom} />
        {isCurrentTag}
      </Space>
    );
  }
};

export default BeAufteilungsschluesselWertMitBezugValidFromWithInfo;
