import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { filtersFormFields, FiltersFormValues, listingLabelList } from './filtersFormMapper';
import { TIbanQueryParams } from './filtersQueryParams';
import FiltersWith4OrMoreFields from '../../../../../components/Filters/FiltersWith4OrMoreFields';
import FiltersSelect from '../../../../../components/Filters/FiltersSelect';
import { onFilterSelectChange } from '../../../../../components/Filters/FiltersHelpers';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import FromToFormDatePicker from '../../../../shared/FromToForm/FromToFormDatePicker';
import PaymentTransactionTypeFormSelect from '../../../../Payment/shared/PaymentTransactionTypeFormSelect';
import AccountHolderListFormSelect from './AccountHolderListFormSelect';
import IbanListFormSelect from './IbanListFormSelect';
import BanknameListFormSelect from './BanknameListFormSelect';
import BicListFormSelect from './BicListFormSelect';
import FibuAccountNumberListFormSelect from './FibuAccountNumberListFormSelect';
import useFilterWithQueryParams from '../../../../shared/useFilterWithQueryParams';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
  queryParams: TIbanQueryParams;
};

const ListingFilters: FC<Props> = ({ formikProps, queryParams }) => {
  const defaultSelectedFilterList = [filtersFormFields.iban, filtersFormFields.fibuAccountNumber, filtersFormFields.paymentTransactionType];

  const mapFilterLabelToQueryFilterList = (filter: string) => {
    if (filter === filtersFormFields.lastBankStatementFrom || filter === filtersFormFields.lastBankStatementTo) {
      return filtersFormFields.lastBankStatementFrom;
    }
    return filter;
  };

  const filters = (filter: string) => {
    if (filter === filtersFormFields.lastBankStatementFrom) {
      return (
        <FormItemWithoutColon name="erstelltAm" label={listingLabelList.lastBankStatementFrom} key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={filtersFormFields.lastBankStatementFrom}
            toName={filtersFormFields.lastBankStatementTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.paymentTransactionType) {
      return (
        <FormItemWithoutColon name={filtersFormFields.paymentTransactionType} label={listingLabelList.paymentTransactionType} key={filter}>
          <PaymentTransactionTypeFormSelect name={filtersFormFields.paymentTransactionType} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.accountHolder) {
      return (
        <FormItemWithoutColon name={filtersFormFields.accountHolder} label={listingLabelList.accountHolder} key={filter}>
          <AccountHolderListFormSelect name={filtersFormFields.accountHolder} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.iban) {
      return (
        <FormItemWithoutColon name={filtersFormFields.iban} label={listingLabelList.iban} key={filter}>
          <IbanListFormSelect name={filtersFormFields.iban} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.bankname) {
      return (
        <FormItemWithoutColon name={filtersFormFields.bankname} label={listingLabelList.bankname} key={filter}>
          <BanknameListFormSelect name={filtersFormFields.bankname} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.bic) {
      return (
        <FormItemWithoutColon name={filtersFormFields.bic} label={listingLabelList.bic} key={filter}>
          <BicListFormSelect name={filtersFormFields.bic} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.fibuAccountNumber) {
      return (
        <FormItemWithoutColon name={filtersFormFields.fibuAccountNumber} label={listingLabelList.fibuAccountNumber} key={filter}>
          <FibuAccountNumberListFormSelect name={filtersFormFields.fibuAccountNumber} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    return undefined;
  };

  const updateFilterValues = (selectedFilterList: string[]) => {
    if (!selectedFilterList.includes(filtersFormFields.lastBankStatementFrom) && formikProps.values.lastBankStatementTo) {
      formikProps.setFieldValue(filtersFormFields.lastBankStatementTo, undefined);
    }
    formikProps.submitForm();
  };

  const { selectedFilterList, setSelectedFilterList, leftColumn, rightColumn } = useFilterWithQueryParams({
    defaultSelectedFilterList,
    updateFilterValues,
    filters,
    labelList: listingLabelList,
    mapFilterLabelToQueryFilterList,
    queryParams,
  });

  return (
    <FiltersWith4OrMoreFields
      filtersSelect={
        <FiltersSelect
          defaultSelectedFilterList={defaultSelectedFilterList}
          selectedFilterList={selectedFilterList}
          onChange={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
          labelList={listingLabelList}
        />
      }
      hideTitle
      leftColumn={<>{leftColumn}</>}
      rightColumn={<>{rightColumn}</>}
    />
  );
};

export default ListingFilters;
