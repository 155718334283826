import React from 'react';
import { HistoryOutlined, HomeOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../components/ContextSidebar/ContentWithSidebar';
import { pathsToTicketDetailsPage } from '../../features/Ticket/ticketUriPaths';
import TicketDetailsOverviewTab from '../../features/Ticket/TicketDetailsOverViewTab/TicketDetailsOverviewTab';
import { Ticket } from '../../types';
import TicketDetailsChangeEntryTab from '../../features/Ticket/TicketDetailsChangeEntryTab/TicketDetailsChangeEntryTab';

const menuListTicket = (ticket: Ticket, refetch: () => Promise<unknown>): GroupedMenueListInterface => {
  return {
    topMenuList: [
      {
        title: 'Übersicht',
        icon: <HomeOutlined />,
        uri: pathsToTicketDetailsPage(ticket).overviewTab,
        content: () => <TicketDetailsOverviewTab ticket={ticket} refetch={refetch} />,
      },
      {
        title: 'Verlauf',
        icon: <HistoryOutlined />,
        uri: pathsToTicketDetailsPage(ticket).activityTab,
        content: () => <TicketDetailsChangeEntryTab ticket={ticket} />,
      },
    ],
  };
};

export default menuListTicket;
