import { PaymentTransactionType, QueryGetDataCarrierCamtListArgs } from '../../../../../types';
import { FormFields, mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';
import { ObjectOfStrings } from '../../../../../shared/typeHelpers';
import { TBankstatementQueryParams } from './filtersQueryParams';

export type FiltersFormValues = {
  createTsFrom?: string | null;
  createTsTo?: string | null;
  createdByMitarbeiterIdList?: string[] | null;
  iban?: string | null;
  name?: string | null;
  paymentTransactionType?: PaymentTransactionType | null;
  statementNumber?: string | null;
};

export const filtersFormFields: FormFields<FiltersFormValues> = {
  createTsFrom: 'createTsFrom',
  createTsTo: 'createTsTo',
  createdByMitarbeiterIdList: 'createdByMitarbeiterIdList',
  iban: 'iban',
  name: 'name',
  paymentTransactionType: 'paymentTransactionType',
  statementNumber: 'statementNumber',
};

export const mapFormValuesToQueryParams = (formValues: FiltersFormValues): TBankstatementQueryParams => ({
  createTsFrom: formValues.createTsFrom ? mapFormDateValueToDateString(formValues.createTsFrom) : null,
  createTsTo: formValues.createTsTo ? mapFormDateValueToDateString(formValues.createTsTo) : null,
  createdByMitarbeiterIdList: formValues.createdByMitarbeiterIdList,
  iban: formValues.iban,
  name: formValues.name,
  paymentTransactionType: formValues.paymentTransactionType,
  statementNumber: formValues.statementNumber,
});

export const mapQueryParamsToFormValues = (queryParams: TBankstatementQueryParams): FiltersFormValues => ({
  createTsFrom: queryParams.createTsFrom,
  createTsTo: queryParams.createTsTo,
  createdByMitarbeiterIdList: queryParams.createdByMitarbeiterIdList,
  iban: queryParams.iban,
  name: queryParams.name,
  paymentTransactionType: queryParams.paymentTransactionType,
  statementNumber: queryParams.statementNumber,
});
export const mapQueryParamsToListQueryVariables = (queryParams: TBankstatementQueryParams): QueryGetDataCarrierCamtListArgs => ({
  ...queryParams,
  statementNumber: queryParams.statementNumber?.toString(),
});

export const listingLabelList: ObjectOfStrings<Omit<FiltersFormValues, 'createTsTo'>> = {
  [filtersFormFields.createTsFrom]: 'Erstellt am',
  [filtersFormFields.createdByMitarbeiterIdList]: 'Ersteller',
  [filtersFormFields.iban]: 'IBAN',
  [filtersFormFields.name]: 'Bezeichnung (des Datenträgers)',
  [filtersFormFields.paymentTransactionType]: 'Zahlungsverkehrsart',
  [filtersFormFields.statementNumber]: 'Kontoauszugnummer',
};
