import React, { FC } from 'react';
import { FormikProps } from 'formik';
import {
  AuszifferungBlockFormValues,
  AuszifferungFormValues,
  mapOffenePostenToAuszifferungFormValues,
  OffenePostenForBooking,
} from '../../../../auszifferungFormMapper';
import { auszifferungTableColumns } from '../../../shared/Level2/auszifferungTableColumns';
import { StyledTableForEntriesFromThisZahlung } from '../../../shared/Level2/StyledTableForThisZahlung.style';
import { isNewAuszifferung } from '../../../../auszifferungFormHelpers';
import { belongsAuszifferungToAnotherZahlung, isAuszifferungSourceStorno } from '../../../../../../../shared/auszifferungHelpers';

type Props = {
  bookingZahlungId: string;
  formikProps: FormikProps<AuszifferungBlockFormValues>;
  offenePosten: OffenePostenForBooking;
};

const AuszifferungForPersonenkontoTable: FC<Props> = ({ bookingZahlungId, formikProps, offenePosten }) => {
  const auszifferungList = mapOffenePostenToAuszifferungFormValues(offenePosten);

  const opListForBooking = formikProps.values.offenePostenListFromBooking.filter((op) => op.offenePostenId === offenePosten.offenePostenId);

  const auszifferungListFromOPListForBooking: AuszifferungFormValues[] = opListForBooking.length ? opListForBooking[0].auszifferungList : [];

  const auszifferungListForOP = auszifferungListFromOPListForBooking.length ? auszifferungListFromOPListForBooking : auszifferungList;

  return (
    <StyledTableForEntriesFromThisZahlung<AuszifferungFormValues>
      rowKey={(record) => record.auszifferungId}
      columns={auszifferungTableColumns(offenePosten, formikProps, bookingZahlungId, true)}
      dataSource={auszifferungListForOP}
      pagination={false}
      rowClassName={(record) =>
        !isAuszifferungSourceStorno(record.source?.value) &&
        (!belongsAuszifferungToAnotherZahlung(bookingZahlungId, record.zahlungId) || isNewAuszifferung(record))
          ? 'row-entry-from-this-zahlung'
          : ''
      }
    />
  );
};

export default AuszifferungForPersonenkontoTable;
