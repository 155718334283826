import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AbrechnungskreisFieldsFragmentDoc } from '../../Abrechnungskreis/gql/AbrechnungskreisFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AbrechnungsdefinitionAbrechnungskreisSelectListQueryVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['String']['input'];
}>;

export type AbrechnungsdefinitionAbrechnungskreisSelectListQuery = {
  getAbrechnungsdefinitionAbrechnungskreisList: {
    data: Array<{
      abrechnungskreisId: string;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      updatedByMitarbeiterId?: string | null;
      status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
      type: { text: string; value: Types.AbrechnungskreisType };
      verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const AbrechnungsdefinitionAbrechnungskreisSelectListDocument = gql`
  query AbrechnungsdefinitionAbrechnungskreisSelectList($abrechnungsdefinitionId: String!) {
    getAbrechnungsdefinitionAbrechnungskreisList(abrechnungsdefinitionId: $abrechnungsdefinitionId) {
      data {
        ...AbrechnungskreisFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AbrechnungskreisFieldsFragmentDoc}
`;
export function useAbrechnungsdefinitionAbrechnungskreisSelectListQuery(
  baseOptions: Apollo.QueryHookOptions<
    AbrechnungsdefinitionAbrechnungskreisSelectListQuery,
    AbrechnungsdefinitionAbrechnungskreisSelectListQueryVariables
  > &
    ({ variables: AbrechnungsdefinitionAbrechnungskreisSelectListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbrechnungsdefinitionAbrechnungskreisSelectListQuery, AbrechnungsdefinitionAbrechnungskreisSelectListQueryVariables>(
    AbrechnungsdefinitionAbrechnungskreisSelectListDocument,
    options
  );
}
export function useAbrechnungsdefinitionAbrechnungskreisSelectListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AbrechnungsdefinitionAbrechnungskreisSelectListQuery,
    AbrechnungsdefinitionAbrechnungskreisSelectListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbrechnungsdefinitionAbrechnungskreisSelectListQuery, AbrechnungsdefinitionAbrechnungskreisSelectListQueryVariables>(
    AbrechnungsdefinitionAbrechnungskreisSelectListDocument,
    options
  );
}
export function useAbrechnungsdefinitionAbrechnungskreisSelectListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AbrechnungsdefinitionAbrechnungskreisSelectListQuery,
        AbrechnungsdefinitionAbrechnungskreisSelectListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AbrechnungsdefinitionAbrechnungskreisSelectListQuery, AbrechnungsdefinitionAbrechnungskreisSelectListQueryVariables>(
    AbrechnungsdefinitionAbrechnungskreisSelectListDocument,
    options
  );
}
export type AbrechnungsdefinitionAbrechnungskreisSelectListQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionAbrechnungskreisSelectListQuery
>;
export type AbrechnungsdefinitionAbrechnungskreisSelectListLazyQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionAbrechnungskreisSelectListLazyQuery
>;
export type AbrechnungsdefinitionAbrechnungskreisSelectListSuspenseQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionAbrechnungskreisSelectListSuspenseQuery
>;
export type AbrechnungsdefinitionAbrechnungskreisSelectListQueryResult = Apollo.QueryResult<
  AbrechnungsdefinitionAbrechnungskreisSelectListQuery,
  AbrechnungsdefinitionAbrechnungskreisSelectListQueryVariables
>;
