import React, { FC } from 'react';
import belegnummernkreisDefinitionTableColumns from './belegnummernkreisDefinitionTableColumns';
import TableWithColSelector from '../../../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { BelegnummernkreisDefinitionFieldsFragment } from '../../../../../../BelegnummernkreisDefinition/gql/BelegnummernkreisDefinitionFragments.types';
import { RechnungsAusstellerFieldsFragment } from '../../../gql/RechnungsAusstellerFragments.types';

type BelegnummernkreisDefinitionTableProps = {
  rechnungsAussteller: RechnungsAusstellerFieldsFragment;
  belegnummernkreisDefinitionList: BelegnummernkreisDefinitionFieldsFragment[];
  loading: boolean;
  onAction: () => void;
  rechtstraegerId: string;
};

const BelegnummernkreisDefinitionTable: FC<BelegnummernkreisDefinitionTableProps> = ({
  belegnummernkreisDefinitionList,
  loading,
  onAction,
  rechtstraegerId,
}) => {
  return (
    <TableWithColSelector<BelegnummernkreisDefinitionFieldsFragment>
      size="small"
      loading={loading}
      dataSource={belegnummernkreisDefinitionList}
      columns={belegnummernkreisDefinitionTableColumns(rechtstraegerId, onAction)}
      filterIdentifier="belegnummernkreis-definition"
      rowKey={(record) => record.belegnummernkreisDefinitionId}
    />
  );
};

export default BelegnummernkreisDefinitionTable;
