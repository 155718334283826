import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import { useIbanFibuKontoAccountHolderListQuery } from '../gql/IbanFibuKontoQueries.types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';

type Props = Omit<SelectProps, 'id' | 'size' | 'placeholder' | 'filterOption'>;

const AccountHolderListFormSelect: FC<Props> = ({ ...restProps }) => {
  const { loading, data } = useIbanFibuKontoAccountHolderListQuery();
  const list = data?.getIbanFibuKontoAccountHolderList.data ?? [];

  return (
    <Select
      {...restProps}
      size="small"
      id={restProps.name}
      loading={loading}
      placeholder="Kontoinhaber auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {list.map((accountHolder) => (
        <Select.Option key={accountHolder} value={accountHolder}>
          <DataWithShortenedText maxTextLength={40} text={accountHolder} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default AccountHolderListFormSelect;
