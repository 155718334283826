import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { sendAgainFormFields, SendAgainFormValues } from './sendAgainFormMapper';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import FormButtons from '../../../../components/Button/FormButtons';
import { sendAgainFormValidationSchema } from './sendAgainFormValidationSchema';
import { MailData } from '../../../../types';

type Props = {
  emailDelivery: MailData;
  onSend: (formValues: SendAgainFormValues) => void;
  onCancel: () => void;
};

const SendAgainForm: FC<Props> = ({ emailDelivery, onCancel, onSend }) => {
  return (
    <Formik<SendAgainFormValues>
      onSubmit={onSend}
      validationSchema={sendAgainFormValidationSchema}
      initialValues={{ emailAddress: emailDelivery.recipient }}
    >
      {() => (
        <Form layout="vertical">
          <FormItemWithoutColon name={sendAgainFormFields.emailAddress} label="Empfänger E-Mail-Adresse">
            <Input name="emailAddress" />
          </FormItemWithoutColon>
          <FormButtons isOkLoading={false} onCancel={onCancel} updateMode={false} okText="Senden" />
        </Form>
      )}
    </Formik>
  );
};

export default SendAgainForm;
