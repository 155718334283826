import React, { FC } from 'react';
import { MenuProps } from 'antd';
import { EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { radixActionStyles } from '../../../../../../helpers/radixIconsStyles';
import { BeVertragVorschreibunsposition } from '../../../../../../types';
import { useToggle } from '../../../../../../hooks/useToggle';
import ActionDropdown from '../../../../../../components/Dropdown/ActionDropdown';
import HistoryModal, { HistoryType } from '../../../../../History/shared/HistoryModal';
import VertragVorschreibungspositionWertDeletionHistoryListingTable from './VertragVorschreibungspositionWertDeletionHistoryListingTable';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  vorschreibungsposition: BeVertragVorschreibunsposition;
  expandedRowKeys: string[];
  toggleExpandedRowKey: (rowKey: string) => void;
};

const VertragVorschreibungspositionTableActions: FC<Props> = ({
  objektId,
  bestandseinheitId,
  vertragId,
  vorschreibungsposition,
  expandedRowKeys,
  toggleExpandedRowKey,
}) => {
  const [isDeletionHistoryOpen, toggleIsDeletionHistoryOpen] = useToggle();

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Bearbeiten',
      onClick: () => toggleExpandedRowKey(vorschreibungsposition.vorschreibungspositionId),
      icon: <EditOutlined />,
      disabled: expandedRowKeys.includes(vorschreibungsposition.vorschreibungspositionId),
    },
    {
      key: '2',
      label: 'Löschverlauf',
      onClick: toggleIsDeletionHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
  ];

  return (
    <>
      <ActionDropdown items={items} />
      <HistoryModal
        entityName="Vorschreibungsposition"
        historyType={HistoryType.Deletion}
        open={isDeletionHistoryOpen}
        onCancel={toggleIsDeletionHistoryOpen}
      >
        <VertragVorschreibungspositionWertDeletionHistoryListingTable
          objektId={objektId}
          bestandseinheitId={bestandseinheitId}
          vertragId={vertragId}
          vorschreibungspositionId={vorschreibungsposition.vorschreibungspositionId}
        />
      </HistoryModal>
    </>
  );
};

export default VertragVorschreibungspositionTableActions;
