import React from 'react';
import { Tag } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { friendlyFormatIBAN } from 'ibantools';
import { Link } from 'react-router-dom';
import { generatePathToKontoDetailsPage } from '../../../../Kontenplan/kontoUriPaths';
import { Firmendaten, FirmendatenBankDetails } from '../../../../../types';
import FirmendatenKontoTableActions from './FirmendatenKontoTableActions';
import MitarbeiterTooltip from '../../../../../components/Card/MitarbeiterTooltip';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { EuroAmount } from '../../../../../components/Number';
import { isBankDetailsFibuStatusWirdAngelegt } from '../../../../BankDetails/bankDetailsHelpers';
import { NestedTableColProps } from '../../../../../components/Table/NestedTable/NestedTable';
import TruncatedTextWithTooltip from '../../../../../components/Helpers/TruncatedTextWithTooltip';

const firmendatenKontoTableColumns = (firmendaten: Firmendaten, onAction: () => void): NestedTableColProps<FirmendatenBankDetails>[] => [
  {
    title: 'Kontoinhaber',
    render: (text, record) => <TruncatedTextWithTooltip text={record.accountHolder} />,
  },
  {
    title: 'Bankname',
    render: (text, record) => <TruncatedTextWithTooltip text={record.bankname} />,
  },
  {
    title: 'IBAN',
    render: (text, record) => friendlyFormatIBAN(record.iban),
  },
  {
    title: 'FIBU-Kontonummer',
    render: (text, record) =>
      record.fibuKontoId ? <Link to={generatePathToKontoDetailsPage(record.fibuKontoId)}>{record.fibuKontonummer}</Link> : <Tag>Nicht erstellt</Tag>,
  },
  {
    title: 'Überziehungsrahmen',
    render: (text, record) => {
      return record.accountLimit ? <EuroAmount value={record.accountLimit} /> : <TextForEmptyValue textToShow="minus" />;
    },
  },
  {
    title: 'Verwendung',
    render: (text, record) => <Tag>{record.fibuVerwendung.text}</Tag>,
  },
  {
    title: 'FIBU-Status',
    render: (text, record) =>
      isBankDetailsFibuStatusWirdAngelegt(record.fibuStatus.value) ? (
        <Tag icon={<SyncOutlined spin />} color="processing">
          {record.fibuStatus.text}
        </Tag>
      ) : (
        <Tag>{record.fibuStatus.text}</Tag>
      ),
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Aktion',
    width: 60,
    render: (text, record) => <FirmendatenKontoTableActions firmendaten={firmendaten} bankDetails={record} onAction={onAction} />,
  },
];

export default firmendatenKontoTableColumns;
