import React, { FC } from 'react';
import { Input } from 'formik-antd';
import { Col, Row } from 'antd';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import CountryCodeSelect from '../../CountryCode/CountryCodeSelect';

type Props = {
  fieldNamePrefix?: string;
  fieldHelpNames?: {
    city?: string | null;
    cityAdditionalInformation?: string | null;
    countryCodeIso2?: string | null;
    houseEntranceApartmentNumber?: string | null;
    street?: string | null;
    zipCode?: string | null;
  };
};

const StreetAddressFormPart: FC<Props> = ({ fieldNamePrefix = '', fieldHelpNames }) => (
  <>
    <Row gutter={8}>
      <Col span={12}>
        <FormItemWithFieldHelp name={`${fieldNamePrefix}street`} label="Straße" fieldHelp={fieldHelpNames?.street}>
          <Input id={`${fieldNamePrefix}street`} name={`${fieldNamePrefix}street`} type="text" placeholder="Straße" />
        </FormItemWithFieldHelp>
      </Col>
      <Col span={12}>
        <FormItemWithFieldHelp
          name={`${fieldNamePrefix}houseEntranceApartmentNumber`}
          label="Hausnummer / Stiege / Tür"
          fieldHelp={fieldHelpNames?.houseEntranceApartmentNumber}
        >
          <Input
            id={`${fieldNamePrefix}houseEntranceApartmentNumber`}
            name={`${fieldNamePrefix}houseEntranceApartmentNumber`}
            type="text"
            placeholder="Hausnummer / Stiege / Tür"
          />
        </FormItemWithFieldHelp>
      </Col>
    </Row>
    <Row gutter={8}>
      <Col span={10}>
        <FormItemWithFieldHelp name={`${fieldNamePrefix}zipCode`} label="Postleitzahl" fieldHelp={fieldHelpNames?.zipCode}>
          <Input id={`${fieldNamePrefix}zipCode`} name={`${fieldNamePrefix}zipCode`} type="text" placeholder="Postleitzahl" />
        </FormItemWithFieldHelp>
      </Col>
      <Col span={14}>
        <FormItemWithFieldHelp name={`${fieldNamePrefix}city`} label="Ort" fieldHelp={fieldHelpNames?.city}>
          <Input id={`${fieldNamePrefix}city`} name={`${fieldNamePrefix}city`} type="text" placeholder="Ort" />
        </FormItemWithFieldHelp>
      </Col>
    </Row>
    <Row gutter={8}>
      <Col span={10}>
        <FormItemWithFieldHelp
          name={`${fieldNamePrefix}cityAdditionalInformation`}
          label="Ortzusatz"
          fieldHelp={fieldHelpNames?.cityAdditionalInformation}
        >
          <Input
            id={`${fieldNamePrefix}cityAdditionalInformation`}
            name={`${fieldNamePrefix}cityAdditionalInformation`}
            type="text"
            placeholder="Ortzusatz"
          />
        </FormItemWithFieldHelp>
      </Col>
      <Col span={14}>
        <CountryCodeSelect name={`${fieldNamePrefix}countryCodeIso2`} fieldHelp={fieldHelpNames?.countryCodeIso2} />
      </Col>
    </Row>
  </>
);

export default StreetAddressFormPart;
