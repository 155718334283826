import { FC } from 'react';
import { Formik } from 'formik';
import { VertragInfoFeldListingFiltersFormValues } from './vertragInfoFeldListingFormMapper';
import VertragInfoFeldListingForm from './VertragInfoFeldListingForm';

type VertragInfoFeldListingProps = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
};

const VertragInfoFeldListing: FC<VertragInfoFeldListingProps> = ({ objektId, bestandseinheitId, vertragId }) => (
  <Formik<VertragInfoFeldListingFiltersFormValues> initialValues={{ type: undefined }} onSubmit={() => {}}>
    {(formikProps) => (
      <VertragInfoFeldListingForm objektId={objektId} bestandseinheitId={bestandseinheitId} vertragId={vertragId} formikProps={formikProps} />
    )}
  </Formik>
);

export default VertragInfoFeldListing;
