import React, { FC } from 'react';
import { Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../hooks/useToggle';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../components/Button/ButtonsAligned';
import TextbausteinForm from './Form/TextbausteinForm';

type Props = {
  refetch: () => void;
};

const TextbausteinActionButtonAndDrawer: FC<Props> = ({ refetch }) => {
  const [isDrawerOpen, toggleDrawerOpen] = useToggle();

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <Button icon={<PlusOutlined />} type="primary" onClick={toggleDrawerOpen}>
          Text anlegen
        </Button>
      </ButtonsAligned>
      <Drawer title="Text anlegen" width={720} onClose={toggleDrawerOpen} open={isDrawerOpen} destroyOnClose>
        <TextbausteinForm
          onCancel={toggleDrawerOpen}
          onSubmit={() => {
            toggleDrawerOpen();
            refetch();
          }}
        />
      </Drawer>
    </>
  );
};

export default TextbausteinActionButtonAndDrawer;
