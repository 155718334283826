import { VertragVposIndexedValueStatus } from '../../../types';

export const isVertragVposIndexedValueStatusApplied = (status: VertragVposIndexedValueStatus) => status === VertragVposIndexedValueStatus.Applied;
export const isVertragVposIndexedValueStatusNotApplied = (status: VertragVposIndexedValueStatus) =>
  status === VertragVposIndexedValueStatus.NotApplied;
export const isVertragVposIndexedValueStatusSubmitted = (status: VertragVposIndexedValueStatus) => status === VertragVposIndexedValueStatus.Submitted;

export const getStatusTagColor = (value: VertragVposIndexedValueStatus) => {
  switch (value) {
    case VertragVposIndexedValueStatus.Applied:
      return 'green';
    case VertragVposIndexedValueStatus.NotApplied:
      return 'red';
    case VertragVposIndexedValueStatus.Submitted:
    default:
      return 'blue';
  }
};
