import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeletePaymentProposalMutationVariables = Types.Exact<{
  paymentProposalId: Types.Scalars['ID']['input'];
}>;

export type DeletePaymentProposalMutation = {
  deletePaymentProposal: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const DeletePaymentProposalDocument = gql`
  mutation deletePaymentProposal($paymentProposalId: ID!) {
    deletePaymentProposal(paymentProposalId: $paymentProposalId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeletePaymentProposalMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePaymentProposalMutation, DeletePaymentProposalMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePaymentProposalMutation, DeletePaymentProposalMutationVariables>(DeletePaymentProposalDocument, options);
}
export type DeletePaymentProposalMutationHookResult = ReturnType<typeof useDeletePaymentProposalMutation>;
export type DeletePaymentProposalMutationResult = Apollo.MutationResult<DeletePaymentProposalMutation>;
export type DeletePaymentProposalMutationOptions = Apollo.BaseMutationOptions<DeletePaymentProposalMutation, DeletePaymentProposalMutationVariables>;
