import React, { FC } from 'react';
import { Checkbox } from 'antd';
import { showSuccessMsgUpdate } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { useUpdateVorschreibungMailDeliveryMutation } from '../../../gql/VorschreibungMailDeliveryWizardMutations.types';
import { VorschreibungMailDelivery } from '../../../../../../types';

type Props = {
  vorschreibungMailDelivery: VorschreibungMailDelivery;
  onUpdate: () => void;
  wizardId: string;
  isCompleted?: boolean;
};

const SendCheckboxColumn: FC<Props> = ({ vorschreibungMailDelivery, onUpdate, wizardId, isCompleted }) => {
  const [runUpdate, { loading }] = useUpdateVorschreibungMailDeliveryMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`E-Mail Versand`);
      onUpdate();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Checkbox
      name="shouldSend"
      id="shouldSend"
      onChange={(e) => {
        if (isCompleted) return;
        runUpdate({
          variables: {
            vorschreibungMailDeliveryWizardId: wizardId,
            vorschreibungMailDeliveryId: vorschreibungMailDelivery.vorschreibungMailDeliveryId,
            shouldSend: e.target.checked,
            shouldNeverSend: false,
          },
        });
      }}
      checked={vorschreibungMailDelivery.shouldSend}
      disabled={loading || isCompleted}
    />
  );
};

export default SendCheckboxColumn;
