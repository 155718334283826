import * as Yup from 'yup';
import { entityIsRequired, entityMaxCharLength } from '../../../../../components/message/validationMsg';
import { zahlungsregelFormFields } from './zahlungsregelFormMapper';
import {
  isZahlungsregelTypeFixbetrag,
  isZahlungsregelTypeProzentsatzGesamtVs,
  isZahlungsregelTypeProzentsatzHeAbrechnung,
} from './zahlungsregelHelpers';

export const zahlungsregelFormValidationSchema = Yup.object().shape({
  [zahlungsregelFormFields.bezeichnung]: Yup.string().required(entityIsRequired('Bezeichnung')),
  [zahlungsregelFormFields.zahlungsempfaengerId]: Yup.string().required(entityIsRequired('Zahlungsempfänger')),
  [zahlungsregelFormFields.periode]: Yup.string().required(entityIsRequired('Zahlungsintervall')),
  [zahlungsregelFormFields.durchfuehrenAm]: Yup.number().required(entityIsRequired('Jeweils am')),
  [zahlungsregelFormFields.verwendungszweck]: Yup.string()
    .nullable()
    .when(zahlungsregelFormFields.zahlungsreferenz, ([zahlungsreferenz], schema) =>
      !zahlungsreferenz ? schema.required(entityIsRequired('Verwendungszweck')) : schema
    ),
  [zahlungsregelFormFields.zahlungsreferenz]: Yup.string().nullable().max(35, entityMaxCharLength('Zahlungsreferenz', 35)),
  [zahlungsregelFormFields.fixbetrag]: Yup.string()
    .nullable()
    .when(zahlungsregelFormFields.type, ([isType], schema) =>
      isZahlungsregelTypeFixbetrag(isType) ? schema.required(entityIsRequired('Fixbetrag')) : schema
    ),
  [zahlungsregelFormFields.prozentsatz]: Yup.string()
    .nullable()
    .when(zahlungsregelFormFields.type, ([isType], schema) => {
      return isZahlungsregelTypeProzentsatzGesamtVs(isType) || isZahlungsregelTypeProzentsatzHeAbrechnung(isType)
        ? schema.required(entityIsRequired('Prozentsatz'))
        : schema;
    }),
  [zahlungsregelFormFields.turnus]: Yup.string()
    .nullable()
    .when(zahlungsregelFormFields.type, ([isType], schema) => {
      return isZahlungsregelTypeProzentsatzHeAbrechnung(isType) ? schema.required(entityIsRequired('Turnus')) : schema;
    }),
});
