import React from 'react';
import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../../components/Template/DetailsPageTemplate';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungDetailsPageCard from '../../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import menuListPaymentProposalCreate from './menuListPaymentProposalCreate';
import { VerarbeitungPaymentProposalDetailsPageRouteParams } from '../routes';
import { useRestartPaymentProposalCreationGenerierlaufMutation } from '../../../../features/Verarbeitung/gql/PaymentProposalGenerierlauf/PaymentProposalGenerierlaufMutations.types';
import { usePaymentProposalCreationGenerierlaufQuery } from '../../../../features/Verarbeitung/gql/PaymentProposalGenerierlauf/PaymentProposalGenerierlaufQueries.types';
import { PaymentProposalCreationGenerierlauf } from '../../../../types';

const PaymentProposalCreateDetailsPage = () => {
  const { generierlaufId } = useParams() as VerarbeitungPaymentProposalDetailsPageRouteParams;

  const { data, refetch } = usePaymentProposalCreationGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getPaymentProposalCreationGenerierlauf.data;

  const [runRestart] = useRestartPaymentProposalCreationGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<PaymentProposalCreationGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.PaymentProposalCreate}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.PaymentProposalCreate}
          onGenerierlaufRestart={runRestart}
        />
      )}
      sidebarMenuList={(generierlauf) => menuListPaymentProposalCreate(generierlauf)}
    />
  );
};

export default PaymentProposalCreateDetailsPage;
