import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBestandseinheitNutzwertVersionMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  nutzwertVersion: Types.BestandseinheitNutzwertVersionInput;
}>;

export type CreateBestandseinheitNutzwertVersionMutation = {
  createBestandseinheitNutzwertVersion: {
    data: { beNutzwertId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UpdateBestandseinheitNutzwertVersionMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  nutzwertVersionId: Types.Scalars['ID']['input'];
  nutzwertVersion: Types.BestandseinheitNutzwertVersionInput;
}>;

export type UpdateBestandseinheitNutzwertVersionMutation = {
  updateBestandseinheitNutzwertVersion: {
    data: { beNutzwertId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type DeleteBestandseinheitNutzwertVersionMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  nutzwertVersionId: Types.Scalars['ID']['input'];
}>;

export type DeleteBestandseinheitNutzwertVersionMutation = {
  deleteBestandseinheitNutzwertVersion: {
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const CreateBestandseinheitNutzwertVersionDocument = gql`
  mutation CreateBestandseinheitNutzwertVersion($objektId: ID!, $bestandseinheitId: ID!, $nutzwertVersion: BestandseinheitNutzwertVersionInput!) {
    createBestandseinheitNutzwertVersion(objektId: $objektId, bestandseinheitId: $bestandseinheitId, nutzwertVersion: $nutzwertVersion) {
      data {
        beNutzwertId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreateBestandseinheitNutzwertVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBestandseinheitNutzwertVersionMutation, CreateBestandseinheitNutzwertVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBestandseinheitNutzwertVersionMutation, CreateBestandseinheitNutzwertVersionMutationVariables>(
    CreateBestandseinheitNutzwertVersionDocument,
    options
  );
}
export type CreateBestandseinheitNutzwertVersionMutationHookResult = ReturnType<typeof useCreateBestandseinheitNutzwertVersionMutation>;
export type CreateBestandseinheitNutzwertVersionMutationResult = Apollo.MutationResult<CreateBestandseinheitNutzwertVersionMutation>;
export type CreateBestandseinheitNutzwertVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateBestandseinheitNutzwertVersionMutation,
  CreateBestandseinheitNutzwertVersionMutationVariables
>;
export const UpdateBestandseinheitNutzwertVersionDocument = gql`
  mutation UpdateBestandseinheitNutzwertVersion(
    $objektId: ID!
    $bestandseinheitId: ID!
    $nutzwertVersionId: ID!
    $nutzwertVersion: BestandseinheitNutzwertVersionInput!
  ) {
    updateBestandseinheitNutzwertVersion(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      nutzwertVersionId: $nutzwertVersionId
      nutzwertVersion: $nutzwertVersion
    ) {
      data {
        beNutzwertId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUpdateBestandseinheitNutzwertVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBestandseinheitNutzwertVersionMutation, UpdateBestandseinheitNutzwertVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBestandseinheitNutzwertVersionMutation, UpdateBestandseinheitNutzwertVersionMutationVariables>(
    UpdateBestandseinheitNutzwertVersionDocument,
    options
  );
}
export type UpdateBestandseinheitNutzwertVersionMutationHookResult = ReturnType<typeof useUpdateBestandseinheitNutzwertVersionMutation>;
export type UpdateBestandseinheitNutzwertVersionMutationResult = Apollo.MutationResult<UpdateBestandseinheitNutzwertVersionMutation>;
export type UpdateBestandseinheitNutzwertVersionMutationOptions = Apollo.BaseMutationOptions<
  UpdateBestandseinheitNutzwertVersionMutation,
  UpdateBestandseinheitNutzwertVersionMutationVariables
>;
export const DeleteBestandseinheitNutzwertVersionDocument = gql`
  mutation DeleteBestandseinheitNutzwertVersion($objektId: ID!, $bestandseinheitId: ID!, $nutzwertVersionId: ID!) {
    deleteBestandseinheitNutzwertVersion(objektId: $objektId, bestandseinheitId: $bestandseinheitId, nutzwertVersionId: $nutzwertVersionId) {
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeleteBestandseinheitNutzwertVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBestandseinheitNutzwertVersionMutation, DeleteBestandseinheitNutzwertVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBestandseinheitNutzwertVersionMutation, DeleteBestandseinheitNutzwertVersionMutationVariables>(
    DeleteBestandseinheitNutzwertVersionDocument,
    options
  );
}
export type DeleteBestandseinheitNutzwertVersionMutationHookResult = ReturnType<typeof useDeleteBestandseinheitNutzwertVersionMutation>;
export type DeleteBestandseinheitNutzwertVersionMutationResult = Apollo.MutationResult<DeleteBestandseinheitNutzwertVersionMutation>;
export type DeleteBestandseinheitNutzwertVersionMutationOptions = Apollo.BaseMutationOptions<
  DeleteBestandseinheitNutzwertVersionMutation,
  DeleteBestandseinheitNutzwertVersionMutationVariables
>;
