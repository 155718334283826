import React, { FC, useCallback, useState } from 'react';
import { FilterOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Input, Popover, Space } from 'antd';
import { StyledCheckboxForFilter } from '../../Table/TableWithColSelector/styled/StyledTableWithFilter.style';
import TruncatedTextWithTooltip from '../../Helpers/TruncatedTextWithTooltip';
import { useToggle } from '../../../hooks/useToggle';

type Props = {
  allFilterTitles: Record<string, string>;
  onSelectedFilterTitleList: (filters: string[]) => void;
  selectedFilterList: string[];
};

const FiltersWithSelector: FC<Props> = ({ allFilterTitles, onSelectedFilterTitleList, selectedFilterList }) => {
  const [isFilterSelectorOpen, toggleFilterSelector] = useToggle();

  const [searchText, setSearchText] = useState<string>();

  const handleSearchInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  }, []);

  return (
    <Popover
      title="Filter auswählen"
      trigger="click"
      open={isFilterSelectorOpen}
      onOpenChange={toggleFilterSelector}
      placement="bottom"
      content={
        <Space direction="vertical" style={{ width: '100%' }}>
          <Input size="small" placeholder="Suche..." onChange={handleSearchInputChange} allowClear />
          <Divider style={{ margin: 0, padding: 0 }} />
          <FilterSelectorCheckboxFilter
            allFilterTitles={allFilterTitles}
            onSelectedFilterTitleList={onSelectedFilterTitleList}
            selectedFilterList={selectedFilterList}
            searchText={searchText}
          />
        </Space>
      }
    >
      <Button>
        Filterauswahl <FilterOutlined />
      </Button>
    </Popover>
  );
};

type FilterSelectorCheckboxFilterProps = {
  allFilterTitles: Record<string, string>;
  onSelectedFilterTitleList: (filters: string[]) => void;
  selectedFilterList: string[];
  searchText?: string;
};

const FilterSelectorCheckboxFilter: FC<FilterSelectorCheckboxFilterProps> = ({
  allFilterTitles,
  onSelectedFilterTitleList,
  selectedFilterList,
  searchText,
}) => {
  return (
    <StyledCheckboxForFilter onChange={(selectedFilters) => onSelectedFilterTitleList(selectedFilters as string[])} value={selectedFilterList}>
      {Object.entries(allFilterTitles)
        .sort(([aKey, aVal], [bKey, bVal]) => aVal.localeCompare(bVal))
        .filter(([_, value]) => {
          if (searchText) {
            return value.toLowerCase().includes(searchText.toLowerCase());
          }
          return true;
        })
        .map(([key, value], index) => {
          return (
            <Checkbox key={key} value={key}>
              <Space size={0} direction="vertical" style={{ width: '100%' }}>
                <TruncatedTextWithTooltip tooltipProps={{ placement: 'topLeft' }} text={value} key={index} />
              </Space>
            </Checkbox>
          );
        })}
    </StyledCheckboxForFilter>
  );
};

export default FiltersWithSelector;
