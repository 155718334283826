import React, { FC, useState } from 'react';
import { Empty } from 'antd';
import TableWithColSelector from '../../components/Table/TableWithColSelector/TableWithColSelector';
import { TableWithColSelectorColumnProps } from '../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { HistoryFieldsFragment } from './gql/HistoryFragments.types';
import { HistoryType } from './shared/HistoryModal';
import deleteHistoryListingTableColumns from './deleteHistoryListingTableColumns';
import changeHistoryListingTableColumns from './changeHistoryListingTableColumns';

type Props = {
  historyType: HistoryType;
  historyList?: HistoryFieldsFragment[];
  loading: boolean;
  filterIdPrefix: string;
};

const HistoryListingTable: FC<Props> = ({ historyType, historyList, loading, filterIdPrefix }) => {
  const [scrollX, setScrollX] = useState<number | undefined>();

  const calculateWidth = (selectedColumnsToShowInTable?: TableWithColSelectorColumnProps<any>[]): number | undefined => {
    if (!selectedColumnsToShowInTable) return undefined;
    let width = 0;

    selectedColumnsToShowInTable.forEach((column) => {
      if (column.width) {
        width += typeof column.width === 'string' ? parseFloat(column.width) : column.width;
      } else {
        width += 150;
      }
    });
    return width;
  };

  return (
    <TableWithColSelector<HistoryFieldsFragment>
      key={JSON.stringify(historyList)}
      size="small"
      locale={{
        emptyText: (
          <Empty description={<span>{`Kein ${historyType === HistoryType.Change ? 'Änderungsverlauf' : 'Löschverlauf'} vorhanden.`}</span>} />
        ),
      }}
      pagination={{ syncWithUri: false }}
      onSelectionChange={(selectedColumnsToShowInTable) => {
        setScrollX(calculateWidth(selectedColumnsToShowInTable));
      }}
      loading={loading}
      columns={historyType === HistoryType.Change ? changeHistoryListingTableColumns(historyList) : deleteHistoryListingTableColumns(historyList)}
      dataSource={historyList}
      rowKey={(record) => record.updateTs}
      filterIdentifier={historyType === HistoryType.Change ? `${filterIdPrefix}-change-history` : `${filterIdPrefix}-delete-history`}
      scroll={{ x: scrollX }}
    />
  );
};

export default HistoryListingTable;
