import React, { FC } from 'react';
import { TabsProps } from 'antd';
import RouteTabs from '../../../../components/Tabs/RouteTabs';
import { SubAbrechnung } from '../../../../types';
import { pathsToSubAbrechnungDetails } from '../subAbrechnungUriPaths';
import SubAbrechnungTable from './SubAbrechnungTable';

type Props = {
  abrechnung: SubAbrechnung;
  objektId: string;
};

const SubAbrDetailsTabs: FC<Props> = ({ abrechnung, objektId }) => {
  const paths = pathsToSubAbrechnungDetails(objektId, abrechnung.subAbrechnungId);

  const items: TabsProps['items'] = [
    {
      key: paths.subAbrTab,
      label: 'Subverwaltungsabrechnung',
      children: <SubAbrechnungTable abrechnung={abrechnung} objektId={objektId} />,
    },
  ];
  return <RouteTabs items={items} />;
};

export default SubAbrDetailsTabs;
