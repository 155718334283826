import { InfoFeldFormValues } from '../../../../../shared/InfoFeld/infoFeldFormMapper';
import { FormFields } from '../../../../../../helpers/formikHelper';

import { ObjektInfoFeld_ObjektInfoFeldPerson_Fragment } from '../../../gql/objektInfoFeldFragments.types';

export interface PersonUpdateFormValues extends InfoFeldFormValues {
  rolle: string;
  rechtstraegerId: string;
  deleteDocumentFileIdList: string[];
}

export const personUpdateFormFields: FormFields<PersonUpdateFormValues> = {
  rolle: 'rolle',
  rechtstraegerId: 'rechtstraegerId',
  documentList: 'documentList',
  deleteDocumentFileIdList: 'deleteDocumentFileIdList',
};

export const mapToUpdateFormValues = (infofeld: ObjektInfoFeld_ObjektInfoFeldPerson_Fragment): PersonUpdateFormValues => ({
  rolle: infofeld.rolle,
  rechtstraegerId: infofeld.personRechtstraeger.rechtstraegerId,
  documentList: [],
  deleteDocumentFileIdList: [],
});
