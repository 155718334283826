import { FieldHelpQueryArgs, FieldHelpService } from '../../useFieldHelpList';
import { FormFields } from '../../../../helpers/formikHelper';

export type FieldHelpListingFiltersFormValues = {
  selector?: string | undefined;
  fieldHelp?: string | undefined;
  deprecated?: boolean | undefined;
  services: FieldHelpService[] | undefined;
};

export const fieldHelpListingFiltersFormFields: FormFields<FieldHelpListingFiltersFormValues> = {
  selector: 'selector',
  fieldHelp: 'fieldHelp',
  deprecated: 'deprecated',
  services: 'services',
};

export const fieldHelpListingFiltersFormInitialValues = {
  selector: undefined,
  fieldHelp: undefined,
  deprecated: undefined,
  services: Object.values(FieldHelpService),
};

export const mapFormValuesToFieldHelpListingFilters = (formValues: FieldHelpListingFiltersFormValues): FieldHelpQueryArgs => ({
  selector: formValues.selector || undefined,
  fieldHelp: formValues.fieldHelp || undefined,
  deprecated: formValues.deprecated,
  services: formValues.services,
});
