import React, { FC, useEffect, useState } from 'react';
import { Layout, Menu, MenuProps } from 'antd';
import { useLocation } from 'react-router-dom';
import { ItemType } from 'antd/lib/menu/interface';

type Props = {
  items: MenuProps['items'] | undefined;
};

const SettingsSider: FC<Props> = ({ items }) => {
  const { pathname } = useLocation();
  const childMenuItems = getChildItemsWithParentPaths(items);
  const selectedByPath = childMenuItems.find((item) => item.item?.key === pathname);
  const selectedOpenKeysByPath = selectedByPath?.path || [];
  const [selectedKeys, setSeletedKeys] = useState<React.Key[]>([pathname]);
  const [openKeys, setOpenKeys] = useState<React.Key[]>(selectedOpenKeysByPath);

  useEffect(() => {
    setSeletedKeys([pathname]);
  }, [pathname]);

  return (
    <Layout.Sider width="auto" style={{ padding: '16px 0', background: '#fff' }}>
      <Menu
        forceSubMenuRender
        mode="inline"
        selectedKeys={selectedKeys as string[]}
        openKeys={openKeys as string[]}
        onSelect={({ key }) => setSeletedKeys([key])}
        onOpenChange={(openKeys) => {
          setOpenKeys(openKeys);
        }}
        style={{ height: '100%' }}
        items={items}
      />
    </Layout.Sider>
  );
};

const getChildItemsWithParentPaths = (items: MenuProps['items'] | undefined): { item: ItemType; path: React.Key[] }[] => {
  const result: { item: ItemType; path: React.Key[] }[] = [];
  const traverse = (nodes: ItemType[], currentPath: React.Key[]) => {
    for (const node of nodes) {
      if (node !== null && node.key) {
        if ('children' in node && node.children && node.children?.length > 0) {
          const newPath = [...currentPath, node.key];
          traverse(node.children, newPath);
        } else {
          const newPath = [...currentPath];
          result.push({ item: node, path: newPath });
        }
      }
    }
  };

  if (items) {
    traverse(items, []);
  }

  return result;
};

export default SettingsSider;
