import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { VUstSummary } from '../../vorschreibung-types';
import pdfStyles from '../../../styles/pdfStyles';
import { ustColumnWidthsAndAlignment } from './ust-table-styles';

const VTUstTableSummary: FC<{ data: VUstSummary }> = ({ data }) => (
  <View style={[pdfStyles.row, { fontWeight: 'bold' }]}>
    <Text
      style={[pdfStyles.textBulletin, { width: ustColumnWidthsAndAlignment.ustSatz.width, textAlign: ustColumnWidthsAndAlignment.ustSatz.textAlign }]}
    />
    <Text
      style={[pdfStyles.textBulletin, { width: ustColumnWidthsAndAlignment.netto.width, textAlign: ustColumnWidthsAndAlignment.netto.textAlign }]}
    >
      {data.summeNetto}
    </Text>
    <Text style={[pdfStyles.textBulletin, { width: ustColumnWidthsAndAlignment.ust.width, textAlign: ustColumnWidthsAndAlignment.ust.textAlign }]}>
      {data.summeUst}
    </Text>
    <Text
      style={[pdfStyles.textBulletin, { width: ustColumnWidthsAndAlignment.brutto.width, textAlign: ustColumnWidthsAndAlignment.brutto.textAlign }]}
    >
      {data.summeBrutto}
    </Text>
  </View>
);

export default VTUstTableSummary;
