import { FormFields } from '../../../../../../helpers/formikHelper';
import { InfoFeldFormValues } from '../../../../../shared/InfoFeld/infoFeldFormMapper';

export interface ZimmerCreateFormValues extends InfoFeldFormValues {
  bezeichnung: string;
  anmerkung?: string;
  bodenbelag?: string;
  breite?: number;
  energiekennzahl?: string;
  flaechentyp?: string;
  heizung?: string;
  hoehe?: number;
  initialQuadratmeterBetrag?: number;
  initialQuadratmeterPreis?: number;
  kubikmeter?: number;
  laenge?: number;
  nummer?: string;
  nutzflaeche?: number;
  nutzung?: boolean;
  quadratmeter?: number;
  quadratmeterBetrag?: number;
  quadratmeterPreis?: number;
  objektVorschreibungspositionId?: string;
}

export const zimmerCreateFormFields: FormFields<ZimmerCreateFormValues> = {
  bezeichnung: 'bezeichnung',
  anmerkung: 'anmerkung',
  bodenbelag: 'bodenbelag',
  breite: 'breite',
  energiekennzahl: 'energiekennzahl',
  flaechentyp: 'flaechentyp',
  heizung: 'heizung',
  hoehe: 'hoehe',
  initialQuadratmeterBetrag: 'initialQuadratmeterBetrag',
  initialQuadratmeterPreis: 'initialQuadratmeterPreis',
  kubikmeter: 'kubikmeter',
  laenge: 'laenge',
  nummer: 'nummer',
  nutzflaeche: 'nutzflaeche',
  nutzung: 'nutzung',
  quadratmeter: 'quadratmeter',
  quadratmeterBetrag: 'quadratmeterBetrag',
  quadratmeterPreis: 'quadratmeterPreis',
  objektVorschreibungspositionId: 'objektVorschreibungspositionId',
  documentList: 'documentList',
};

export const zimmerCreateFormInitialValues: ZimmerCreateFormValues = {
  documentList: [],
  bezeichnung: '',
};
