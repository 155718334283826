import React, { FC } from 'react';
import { Empty } from 'antd';
import GenerierlaufStatusExpandedRow from '../../../shared/GenerierlaufStatusExpandedRow';
import { isVerarbeitungEntryTableRowExpandable } from '../../../verarbeitungHelpers';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import heAbrechnungErstellenVerarbeitungEntryTableColumns from './heAbrechnungErstellenVerarbeitungEntryTableColumns';
import { HeAbrechnungGenerierlauf, HeAbrechnungGenerierlaufEntry } from '../../../../../types';
import { usePaginationQueryParams } from '../../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../../hooks/useQueryWithPagingAndSorting';
import { THeAbrechnungVerarbeitungQueryParams } from '../../shared/EntryListingFilters/filtersQueryParams';
import { useHeAbrechnungGenerierlaufEntryListQuery } from '../../../gql/HeAbrechnungGenerierlauf/HeAbrechnungGenerierlaufQueries.types';

type Props = {
  generierlauf: HeAbrechnungGenerierlauf;
  queryParams: THeAbrechnungVerarbeitungQueryParams;
};

const HeAbrechnungErstellenVerarbeitungEntryTable: FC<Props> = ({ generierlauf, queryParams }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useHeAbrechnungGenerierlaufEntryListQuery,
    {
      variables: {
        generierlaufId: generierlauf.generierlaufId,
        ...queryParams,
      },
      fetchPolicy: 'no-cache',
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const entryList = data?.getHeAbrechnungGenerierlaufEntryList.data.contentList || [];

  return (
    <TableWithColSelector<HeAbrechnungGenerierlaufEntry>
      loading={loading}
      locale={{
        emptyText: <Empty description={<span>Keine Positionen vorhanden</span>} />,
      }}
      size="small"
      dataSource={entryList}
      pagination={pagination}
      onChange={handleTableSorting}
      columns={heAbrechnungErstellenVerarbeitungEntryTableColumns(generierlauf.heAbrechnungId)}
      rowKey={(record) => record.heAbrechnungHeVertrag?.heAbrechnungHeVertragId ?? record.generierlaufEntryId}
      expandable={{
        expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
        rowExpandable: isVerarbeitungEntryTableRowExpandable,
      }}
      filterIdentifier="verarbeitung-beleg-erstellen"
    />
  );
};

export default HeAbrechnungErstellenVerarbeitungEntryTable;
