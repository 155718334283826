import React, { FC } from 'react';
import { Empty } from 'antd';
import objektAbrechnungTableColumns from './objektAbrechnungTableColumns';
import { AbrechnungTypeTuple, ObjAbrKonto, ObjektAbrechnungkreis } from '../../../../../../types';
import ObjektAbrechnungAusgabenKontoTable from '../Level2/ObjektAbrechnungAusgabenKontoTable/ObjektAbrechnungAusgabenKontoTable';
import ObjektAbrechnungEinnahmenKontoTable from '../Level2/ObjektAbrechnungEinnahmenKontoTable/ObjektAbrechnungEinnahmenKontoTable';
import ObjektAbrechnungUstVomAufwandKontoTable from '../Level2/ObjektAbrechnungUstVomAufwandKontoTable/ObjektAbrechnungUstVomAufwandKontoTable';
import NestedTableWithColSelector from '../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';

type Props = {
  abrechnungskreisList: ObjektAbrechnungkreis[];
  fromInclusive: string;
  toInclusive: string;
  objektId: string;
  buchungskreisId: string;
  abrechnungType: AbrechnungTypeTuple;
};

const ObjektAbrechnungTable: FC<Props> = ({ abrechnungskreisList, fromInclusive, toInclusive, objektId, buchungskreisId, abrechnungType }) => (
  <NestedTableWithColSelector<ObjektAbrechnungkreis>
    colWidth={150}
    level={1}
    locale={{
      emptyText: <Empty description={<span>Keine Daten vorhanden</span>} />,
    }}
    dataSource={abrechnungskreisList}
    columns={objektAbrechnungTableColumns}
    rowKey={getRowKey}
    expandable={{
      rowExpandable: isRowExpandable,
      expandedRowRender: (record, index, indent, expanded) => (
        <>
          {getFilteredKontoList(record.aufwand.kontoList).length > 0 && (
            <ObjektAbrechnungAusgabenKontoTable
              parentRowKeys={[getRowKey(record)]}
              visible={expanded}
              objektId={objektId}
              kontoList={getFilteredKontoList(record.aufwand.kontoList)}
              fromInclusive={fromInclusive}
              toInclusive={toInclusive}
              buchungskreisId={buchungskreisId}
            />
          )}
          {getFilteredKontoList(record.ustVomAufwand?.kontoList).length > 0 && (
            <ObjektAbrechnungUstVomAufwandKontoTable
              parentRowKeys={[getRowKey(record)]}
              visible={expanded}
              objektId={objektId}
              kontoList={getFilteredKontoList(record.ustVomAufwand?.kontoList)}
              fromInclusive={fromInclusive}
              toInclusive={toInclusive}
              buchungskreisId={buchungskreisId}
            />
          )}
          {getFilteredKontoList(record.erloes.kontoList).length > 0 && (
            <ObjektAbrechnungEinnahmenKontoTable
              parentRowKeys={[getRowKey(record)]}
              visible={expanded}
              objektId={objektId}
              kontoList={getFilteredKontoList(record.erloes.kontoList)}
              fromInclusive={fromInclusive}
              toInclusive={toInclusive}
              buchungskreisId={buchungskreisId}
              abrechnungType={abrechnungType}
            />
          )}
        </>
      ),
    }}
  />
);

const getRowKey = (record: ObjektAbrechnungkreis) => record.abrechnungskreisId;

const getFilteredKontoList = (kontoList?: ObjAbrKonto[]) => (kontoList || []).filter((konto) => konto.summe > 0);

const isRowExpandable = (record: ObjektAbrechnungkreis) =>
  getFilteredKontoList(record.aufwand.kontoList).length > 0 ||
  getFilteredKontoList(record.erloes.kontoList).length > 0 ||
  getFilteredKontoList(record.ustVomAufwand?.kontoList).length > 0;

export default ObjektAbrechnungTable;
