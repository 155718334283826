import React from 'react';
import { Tooltip } from 'antd';

type TooltipTextTabProps = {
  showTooltip: boolean;
  text: string;
};

const TooltipTextTab = ({ showTooltip, text }: TooltipTextTabProps) => {
  return showTooltip ? (
    <Tooltip title="Für Weiterbearbeitung bitte die Änderungen speichern">
      <span>{text}</span>
    </Tooltip>
  ) : (
    <span>{text}</span>
  );
};

export default TooltipTextTab;
