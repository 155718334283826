import { FC } from 'react';
import { useObjektChangeHistoryListQuery } from '../gql/ObjektQueries.types';
import { HistoryType } from '../../History/shared/HistoryModal';
import HistoryListingTable from '../../History/HistoryListingTable';

type Props = {
  objektId: string;
};

const ObjektChangeHistoryListingTable: FC<Props> = ({ objektId }) => {
  const { data, loading } = useObjektChangeHistoryListQuery({ variables: { objektId } });
  const historyList = data?.getObjektChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="objekt" />;
};

export default ObjektChangeHistoryListingTable;
