import React, { FC, useEffect } from 'react';
import { useField } from 'formik';
import { SelectProps } from 'formik-antd';
import { EntityStatusFormSelect } from '../../components/Status/EntityStatus';
import { Aufteilungsschluessel } from '../../types';
import { useAufteilungsschluesselTemplateListQuery } from '../AufteilungsschluesselTemplate/gql/AufteilungsschluesselTemplateQueries.types';

type Props = {
  excludedAufteilungsschluesselIds?: string[];
} & SelectProps;

const AufteilungsschluesselTemplateSelect: FC<Props> = ({ name, excludedAufteilungsschluesselIds, ...restProps }) => {
  const [{ value: selectedId }, , { setValue: setSelectedId }] = useField<string>(name);

  const { loading, data } = useAufteilungsschluesselTemplateListQuery({
    variables: {
      includeArchiviert: true,
    },
  });

  const list = data?.getAufteilungsschluesselTemplateList.data ?? [];
  const filteredList = list.filter((entity) => !excludedAufteilungsschluesselIds?.includes(entity.aufteilungsschluesselId));

  // f_id changes => list changes => if there was already a selected entity it may not be available in the scope of newly selected f_id => clear entity
  useEffect(() => {
    if (!loading && selectedId && !filteredList.map((item) => item.aufteilungsschluesselId).includes(selectedId)) {
      setSelectedId('');
    }
  }, [loading, filteredList, selectedId, setSelectedId]);

  return (
    <EntityStatusFormSelect<Aufteilungsschluessel>
      list={filteredList}
      getSelectItemId={(aufteilungsschluessel) => aufteilungsschluessel.aufteilungsschluesselId}
      getSelectItemContent={(aufteilungsschluessel) => aufteilungsschluessel.kurzBezeichnung}
      getSelectItemTooltipTitle={(aufteilungsschluessel) => aufteilungsschluessel.kurzBezeichnung}
      id="aufteilungsschluessel"
      name={name}
      loading={loading}
      placeholder="Aufteilungsschlüssel auswählen"
      {...restProps}
    />
  );
};

export default AufteilungsschluesselTemplateSelect;
