import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, MenuProps, Space, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import LinkWithStatus from '../../../../../components/Link/LinkWithStatus';
import { EuroAmount } from '../../../../../components/Number';
import { generatePathToVorschreibungDetailsPage } from '../../../../Vorschreibung/vorschreibungUriPaths';
import { MitarbeiterTooltip } from '../../../../../components/Card';
import { compareTwoStringsForSorting } from '../../../../../helpers/stringHelper';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import GenerierlaufEntryStatus from '../../../shared/GenerierlaufEntryStatus';
import { generatePathToRechtstraegerDetailsPage } from '../../../../Rechtstraeger/rechtstraegerHelper';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { AuftragGenerierlaufEntry, AuftragGenerierlaufOrderBy } from '../../../../../types';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';

const vorschreibungErstellenVerarbeitungEntryTableColumns: TableWithColSelectorColumnProps<AuftragGenerierlaufEntry>[] = [
  {
    title: 'Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.exitCode?.text, b.exitCode?.text),
    render: (text, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },
  {
    title: 'Zinsliste',
    dataIndex: AuftragGenerierlaufOrderBy.Zinsliste,
    sorter: true,
    render: (text, record) => (
      <Typography.Text>
        {record.fakturierungsperiode.monat} / {record.fakturierungsperiode.jahr}
      </Typography.Text>
    ),
  },
  {
    title: 'Aussteller',
    defaultSelected: true,
    dataIndex: AuftragGenerierlaufOrderBy.Aussteller,
    sorter: true,
    render: (text, record) => (
      <LinkWithStatus
        status={record.aussteller.status}
        text={record.aussteller.kurzBezeichnung}
        to={generatePathToRechtstraegerDetailsPage(record.aussteller.rechtstraegerId)}
        maxTextLength={15}
      />
    ),
  },
  {
    title: 'Empfänger',
    defaultSelected: true,
    dataIndex: AuftragGenerierlaufOrderBy.Empfaenger,
    sorter: true,
    render: (text, record) => (
      <LinkWithStatus
        status={record.empfaenger.status}
        to={generatePathToRechtstraegerDetailsPage(record.empfaenger.rechtstraegerId)}
        text={record.empfaenger.kurzBezeichnung}
        maxTextLength={15}
      />
    ),
  },
  {
    title: 'Objekt-Top',
    defaultSelected: true,
    dataIndex: AuftragGenerierlaufOrderBy.Objekt,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText text={`${record.objekt.kurzBezeichnung} - ${record.bestandseinheit.bezeichnung}`} maxTextLength={20} />
    ),
  },
  {
    title: 'Netto',
    defaultSelected: true,
    align: 'right',
    dataIndex: AuftragGenerierlaufOrderBy.Netto,
    sorter: true,
    render: (text, record) => {
      if (!record.sumNetto) {
        return <TextForEmptyValue textToShow="minus" />;
      }

      return <EuroAmount value={record.sumNetto} />;
    },
  },
  {
    title: 'Ust.',
    defaultSelected: true,
    align: 'right',
    dataIndex: AuftragGenerierlaufOrderBy.Ust,
    sorter: true,
    render: (text, record) => {
      if (!record.sumUst) {
        return <TextForEmptyValue textToShow="minus" />;
      }

      return <EuroAmount value={record.sumUst} />;
    },
  },
  {
    title: 'Brutto',
    defaultSelected: true,
    align: 'right',
    dataIndex: AuftragGenerierlaufOrderBy.Brutto,
    sorter: true,
    render: (text, record) => {
      if (!record.sumBrutto) {
        return <TextForEmptyValue textToShow="minus" />;
      }

      return <EuroAmount value={record.sumBrutto} />;
    },
  },
  {
    title: 'Erstellt am',
    render: (text, record) =>
      record.auftragCreateTs ? <FormattedDateTime createdTs={record.auftragCreateTs} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.auftragCreatedByMitarbeiterId} userId={record.auftragCreatedBy} alignment="left" showsInitials />
    ),
  },

  {
    title: 'Aktion',
    defaultSelected: true,
    width: '75px',
    render: (text, record) => {
      const auftragIdList = record.auftragIdList;
      if (auftragIdList.length === 0) {
        return <Typography.Text disabled>Details</Typography.Text>;
      } else if (auftragIdList.length === 1) {
        return (
          <Link to={generatePathToVorschreibungDetailsPage(record.auftragIdList[0])} target="_blank">
            Details
          </Link>
        );
      } else {
        const items: MenuProps['items'] = auftragIdList.map((listItem, index) => ({
          key: index,
          label: <Link to={generatePathToVorschreibungDetailsPage(listItem)} target="_blank">{`Vorschreibung ${index + 1}`}</Link>,
        }));

        return (
          <Dropdown menu={{ items }}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                Details
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        );
      }
    },
  },
];

export default vorschreibungErstellenVerarbeitungEntryTableColumns;
