import { FC } from 'react';
import HistoryListingTable from '../../../../features/History/HistoryListingTable';
import { useFirmendatenPostofficeBoxAddressDeletionHistoryListQuery } from '../../../../features/KundenSystem/Firmendaten/gql/FirmendatenQueries.types';
import { HistoryType } from '../../../../features/History/shared/HistoryModal';

type Props = {
  firmendatenId: string;
};

const FirmendatenPostofficeBoxAddressDeletionHistoryListingTable: FC<Props> = ({ firmendatenId }) => {
  const { data, loading } = useFirmendatenPostofficeBoxAddressDeletionHistoryListQuery({ variables: { firmendatenId } });
  const historyList = data?.getFirmendatenPostofficeBoxAddressDeletionHistoryList.data;

  return (
    <HistoryListingTable
      historyType={HistoryType.Deletion}
      historyList={historyList}
      loading={loading}
      filterIdPrefix="firmendaten-postoffice-box-address"
    />
  );
};

export default FirmendatenPostofficeBoxAddressDeletionHistoryListingTable;
