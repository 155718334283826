import * as Types from '../../../types';

import { gql } from '@apollo/client';
import {
  TicketBaseFieldsFragmentDoc,
  TicketFieldsFragmentDoc,
  TicketChangeEntryFieldsFragmentDoc,
  TicketCommentFieldsFragmentDoc,
} from './TicketFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TicketListQueryVariables = Types.Exact<{
  objektId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  bestandseinheitId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vertragId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  rechtstraegerId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  objektInfoFeldId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  beInfoFeldId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vertragInfoFeldId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  rechtstraegerInfoFeldId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  priority?: Types.InputMaybe<Array<Types.TicketPriority> | Types.TicketPriority>;
  status?: Types.InputMaybe<Array<Types.TicketStatus> | Types.TicketStatus>;
  title?: Types.InputMaybe<Types.Scalars['String']['input']>;
  assigneeMitarbeiterId?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  participantId?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  dueDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dueDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  searchInSubTickets?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  typeName?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  order?: Types.InputMaybe<Types.TicketOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type TicketListQuery = {
  getTicketList: {
    data: {
      contentList: Array<{
        attachmentCount: number;
        commentCount: number;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        description?: string | null;
        dueDate: string;
        emailCount: number;
        isSubTicket: boolean;
        number: number;
        participantCount: number;
        position: number;
        reminderText: string;
        ticketId: string;
        title: string;
        typeName?: string | null;
        updatedByMitarbeiterId?: string | null;
        parent?: {
          attachmentCount: number;
          commentCount: number;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          description?: string | null;
          dueDate: string;
          emailCount: number;
          isSubTicket: boolean;
          number: number;
          participantCount: number;
          position: number;
          reminderText: string;
          ticketId: string;
          title: string;
          typeName?: string | null;
          updatedByMitarbeiterId?: string | null;
          assignee: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firstname: string;
            lastname: string;
            mitarbeiterId: string;
            updatedByMitarbeiterId?: string | null;
            firmendatenBaseData: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firmendatenId: string;
              kundenSystemId: string;
              kurzBezeichnung: string;
              name1: string;
              name2?: string | null;
              name3?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          beInfoFeld?:
            | {
                __typename: 'BeInfoFeldKellerabteil';
                beInfoFeldId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fileIdList: Array<string>;
                text?: string | null;
                kellerabteilNummer: string;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { text: string; value: Types.InfoFeldStatus };
                type: { text: string; value: Types.BeInfoFeldType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }
            | {
                __typename: 'BeInfoFeldParkplatz';
                beInfoFeldId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fileIdList: Array<string>;
                text?: string | null;
                parkplatzNummer: string;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { text: string; value: Types.InfoFeldStatus };
                type: { text: string; value: Types.BeInfoFeldType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                bestandseinheit?: { bestandseinheitId: string; bezeichnung: string } | null;
              }
            | {
                __typename: 'BeInfoFeldZimmer';
                beInfoFeldId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fileIdList: Array<string>;
                anmerkung?: string | null;
                bezeichnung: string;
                bodenbelag?: string | null;
                breite?: number | null;
                energiekennzahl?: string | null;
                flaechentyp?: string | null;
                heizung?: string | null;
                hoehe?: number | null;
                initialQuadratmeterBetrag?: number | null;
                initialQuadratmeterPreis?: number | null;
                kubikmeter?: number | null;
                laenge?: number | null;
                nutzflaeche?: number | null;
                nutzung?: boolean | null;
                quadratmeter?: number | null;
                quadratmeterBetrag?: number | null;
                quadratmeterPreis?: number | null;
                zimmerNummer?: string | null;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { text: string; value: Types.InfoFeldStatus };
                type: { text: string; value: Types.BeInfoFeldType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                vorschreibungsposition?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  deletable?: boolean | null;
                  kurzBezeichnung: string;
                  objektVorschreibungspositionId: string;
                  vorschreibungspositionId: string;
                  art: { text: string; value: Types.VorschreibungspositionArt };
                  objektVorschreibungspositionStatus: { text: string; value: Types.ObjektVorschreibungspositionStatus };
                  vorschreibungspositionStatus: { text: string; value: Types.VorschreibungspositionStatus };
                } | null;
              }
            | null;
          bestandseinheit?: {
            bestandseinheitId: string;
            bezeichnung: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            objektId: string;
            status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          objekt?: {
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            objektId: string;
            updatedByMitarbeiterId?: string | null;
            rechnungsAussteller: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            status: { text: string; value: Types.ObjektStatus; description?: string | null };
            verwaltungsart: { text: string; value: Types.Verwaltungsart };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          objektInfoFeld?:
            | {
                __typename: 'ObjektInfoFeldAuflage';
                objektInfoFeldId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fileIdList: Array<string>;
                amtlicheQuadratmeterFestsetzung: boolean;
                assanierungsgebiet: boolean;
                bebaut: boolean;
                denkmalschutz: boolean;
                gruenderzeitviertel: boolean;
                naturschutz: boolean;
                schutzzone: boolean;
                vollanwendungMRG: boolean;
                wohnzone: boolean;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { text: string; value: Types.InfoFeldStatus };
                type: { text: string; value: Types.ObjektInfoFeldType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }
            | {
                __typename: 'ObjektInfoFeldGrundstuecksdaten';
                objektInfoFeldId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fileIdList: Array<string>;
                anzahlGeschosseUeberNiveau?: number | null;
                anzahlGeschosseUnterNiveau?: number | null;
                bauflaeche?: number | null;
                baujahr?: number | null;
                bebauteFlaeche?: number | null;
                benuetzungsbewilligung?: string | null;
                einlagezahl?: string | null;
                grundstuecksnummer: string;
                katastralgemeinde?: string | null;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { text: string; value: Types.InfoFeldStatus };
                type: { text: string; value: Types.ObjektInfoFeldType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }
            | {
                __typename: 'ObjektInfoFeldHausbetreuung';
                objektInfoFeldId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fileIdList: Array<string>;
                beauftragungsgrund: string;
                beginn?: string | null;
                ende?: string | null;
                kuendigungsfristDauer?: number | null;
                vertragsdaten?: string | null;
                wertsicherung?: string | null;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { text: string; value: Types.InfoFeldStatus };
                type: { text: string; value: Types.ObjektInfoFeldType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                contactPerson?: {
                  contactPersonId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  lastname: string;
                  title?: string | null;
                  firstname: string;
                  salutation?: { text: string; value: Types.Salutation } | null;
                  contactList: Array<{
                    contactId: string;
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    haupt?: boolean | null;
                    tagList: Array<string>;
                    type: string;
                    wert: string;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  warningList: Array<{ message: string; type: string }>;
                } | null;
                kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
                kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
                hausbetreuungRechtstraeger?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
              }
            | {
                __typename: 'ObjektInfoFeldPerson';
                objektInfoFeldId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fileIdList: Array<string>;
                rolle: string;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { text: string; value: Types.InfoFeldStatus };
                type: { text: string; value: Types.ObjektInfoFeldType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                personRechtstraeger: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
              }
            | {
                __typename: 'ObjektInfoFeldVersicherung';
                objektInfoFeldId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fileIdList: Array<string>;
                beginn?: string | null;
                datumSchaetzung?: string | null;
                ende?: string | null;
                erstmalsFaellig?: string | null;
                validFrom?: string | null;
                hauptDueDate?: string | null;
                kuendigungsfristDauer?: number | null;
                neubauwert?: number | null;
                polizze?: string | null;
                versicherungsart: string;
                versicherungssumme?: number | null;
                wertsicherung?: string | null;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { text: string; value: Types.InfoFeldStatus };
                type: { text: string; value: Types.ObjektInfoFeldType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                contactPerson?: {
                  contactPersonId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  lastname: string;
                  title?: string | null;
                  firstname: string;
                  salutation?: { text: string; value: Types.Salutation } | null;
                  contactList: Array<{
                    contactId: string;
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    haupt?: boolean | null;
                    tagList: Array<string>;
                    type: string;
                    wert: string;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  warningList: Array<{ message: string; type: string }>;
                } | null;
                firma?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  name1: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ message: string; type: string }>;
                } | null;
                kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
                kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
                makler?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  rechtstraegerId: string;
                  kurzBezeichnung: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ message: string; type: string }>;
                } | null;
                sachbearbeiter?: {
                  contactPersonId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  firstname: string;
                  lastname: string;
                  tagList: Array<string>;
                  title?: string | null;
                  titleTrailing?: string | null;
                  contactList: Array<{
                    contactId: string;
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    haupt?: boolean | null;
                    tagList: Array<string>;
                    type: string;
                    wert: string;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  rechtstraeger?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                  salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                zahlungsperiode?: { text: string; value: Types.Zahlungsperiode } | null;
              }
            | null;
          priority: { text: string; value: Types.TicketPriority };
          rechtstraeger?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechtstraegerInfoFeld?: {
            __typename: 'RechtstraegerInfoFeldPersoneninfos';
            rechtstraegerInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            geburtsort?: string | null;
            identitaetsnachweis?: string | null;
            kundengruppeList?: Array<string> | null;
            merkmalList: Array<string>;
            staatsbuergerschaft?: string | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.RechtstraegerInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          reminder: { amount: number; unit: { text: string; value: Types.PeriodUnit } };
          ticketStatus: { text: string; value: Types.TicketStatus };
          type?: {
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            dueDateText?: string | null;
            firmendatenId?: string | null;
            isSubTicketTemplate: boolean;
            name: string;
            parentId?: string | null;
            position: number;
            reminderText?: string | null;
            ticketTemplateId: string;
            updatedByMitarbeiterId?: string | null;
            subTicketTemplateList?: Array<{
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              dueDateText?: string | null;
              firmendatenId?: string | null;
              isSubTicketTemplate: boolean;
              name: string;
              parentId?: string | null;
              position: number;
              reminderText?: string | null;
              ticketTemplateId: string;
              updatedByMitarbeiterId?: string | null;
              dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
              priority: { text: string; value: Types.TicketPriority };
              reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }> | null;
            dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
            priority: { text: string; value: Types.TicketPriority };
            reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          vertrag?: {
            bestandseinheitId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            objektId: string;
            vertragId: string;
            vertragsBeginn: string;
            status: { text: string; value: Types.VertragStatus; description?: string | null };
            vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
            vertragspartner: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          vertragInfoFeld?:
            | {
                __typename: 'VertragInfoFeldKuendigungsinfos';
                vertragInfoFeldId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fileIdList: Array<string>;
                kuendigungBis: string;
                kuendigungsverzichtBestandgeberBis?: string | null;
                kuendigungsverzichtBestandnehmerBis?: string | null;
                bestandnehmerKuendigungsfrist?: number | null;
                kuendigungJeweilsZum?: string | null;
                kuendigungMoeglichZum?: string | null;
                gerichtlicheKuendigung?: boolean | null;
                kuendigungErfolgtAm?: string | null;
                kuendigungWirksamZum?: string | null;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { text: string; value: Types.InfoFeldStatus };
                type: { text: string; value: Types.VertragInfoFeldType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                kuendigungDurch?: { text: string; value: Types.KuendigungDurch } | null;
              }
            | {
                __typename: 'VertragInfoFeldWeiterePerson';
                vertragInfoFeldId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fileIdList: Array<string>;
                text?: string | null;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { text: string; value: Types.InfoFeldStatus };
                type: { text: string; value: Types.VertragInfoFeldType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                natuerlichePerson: { rechtstraegerId: string; kurzBezeichnung: string; lastname: string; firstname: string };
              }
            | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        assignee: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firstname: string;
          lastname: string;
          mitarbeiterId: string;
          updatedByMitarbeiterId?: string | null;
          firmendatenBaseData: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firmendatenId: string;
            kundenSystemId: string;
            kurzBezeichnung: string;
            name1: string;
            name2?: string | null;
            name3?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        beInfoFeld?:
          | {
              __typename: 'BeInfoFeldKellerabteil';
              beInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              text?: string | null;
              kellerabteilNummer: string;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.BeInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }
          | {
              __typename: 'BeInfoFeldParkplatz';
              beInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              text?: string | null;
              parkplatzNummer: string;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.BeInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              bestandseinheit?: { bestandseinheitId: string; bezeichnung: string } | null;
            }
          | {
              __typename: 'BeInfoFeldZimmer';
              beInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              anmerkung?: string | null;
              bezeichnung: string;
              bodenbelag?: string | null;
              breite?: number | null;
              energiekennzahl?: string | null;
              flaechentyp?: string | null;
              heizung?: string | null;
              hoehe?: number | null;
              initialQuadratmeterBetrag?: number | null;
              initialQuadratmeterPreis?: number | null;
              kubikmeter?: number | null;
              laenge?: number | null;
              nutzflaeche?: number | null;
              nutzung?: boolean | null;
              quadratmeter?: number | null;
              quadratmeterBetrag?: number | null;
              quadratmeterPreis?: number | null;
              zimmerNummer?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.BeInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              vorschreibungsposition?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                kurzBezeichnung: string;
                objektVorschreibungspositionId: string;
                vorschreibungspositionId: string;
                art: { text: string; value: Types.VorschreibungspositionArt };
                objektVorschreibungspositionStatus: { text: string; value: Types.ObjektVorschreibungspositionStatus };
                vorschreibungspositionStatus: { text: string; value: Types.VorschreibungspositionStatus };
              } | null;
            }
          | null;
        bestandseinheit?: {
          bestandseinheitId: string;
          bezeichnung: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          objektId: string;
          status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        objekt?: {
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          objektId: string;
          updatedByMitarbeiterId?: string | null;
          rechnungsAussteller: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          status: { text: string; value: Types.ObjektStatus; description?: string | null };
          verwaltungsart: { text: string; value: Types.Verwaltungsart };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        objektInfoFeld?:
          | {
              __typename: 'ObjektInfoFeldAuflage';
              objektInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              amtlicheQuadratmeterFestsetzung: boolean;
              assanierungsgebiet: boolean;
              bebaut: boolean;
              denkmalschutz: boolean;
              gruenderzeitviertel: boolean;
              naturschutz: boolean;
              schutzzone: boolean;
              vollanwendungMRG: boolean;
              wohnzone: boolean;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.ObjektInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }
          | {
              __typename: 'ObjektInfoFeldGrundstuecksdaten';
              objektInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              anzahlGeschosseUeberNiveau?: number | null;
              anzahlGeschosseUnterNiveau?: number | null;
              bauflaeche?: number | null;
              baujahr?: number | null;
              bebauteFlaeche?: number | null;
              benuetzungsbewilligung?: string | null;
              einlagezahl?: string | null;
              grundstuecksnummer: string;
              katastralgemeinde?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.ObjektInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }
          | {
              __typename: 'ObjektInfoFeldHausbetreuung';
              objektInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              beauftragungsgrund: string;
              beginn?: string | null;
              ende?: string | null;
              kuendigungsfristDauer?: number | null;
              vertragsdaten?: string | null;
              wertsicherung?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.ObjektInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              contactPerson?: {
                contactPersonId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                lastname: string;
                title?: string | null;
                firstname: string;
                salutation?: { text: string; value: Types.Salutation } | null;
                contactList: Array<{
                  contactId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  haupt?: boolean | null;
                  tagList: Array<string>;
                  type: string;
                  wert: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ message: string; type: string }>;
              } | null;
              kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
              kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
              hausbetreuungRechtstraeger?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
            }
          | {
              __typename: 'ObjektInfoFeldPerson';
              objektInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              rolle: string;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.ObjektInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              personRechtstraeger: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
            }
          | {
              __typename: 'ObjektInfoFeldVersicherung';
              objektInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              beginn?: string | null;
              datumSchaetzung?: string | null;
              ende?: string | null;
              erstmalsFaellig?: string | null;
              validFrom?: string | null;
              hauptDueDate?: string | null;
              kuendigungsfristDauer?: number | null;
              neubauwert?: number | null;
              polizze?: string | null;
              versicherungsart: string;
              versicherungssumme?: number | null;
              wertsicherung?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.ObjektInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              contactPerson?: {
                contactPersonId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                lastname: string;
                title?: string | null;
                firstname: string;
                salutation?: { text: string; value: Types.Salutation } | null;
                contactList: Array<{
                  contactId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  haupt?: boolean | null;
                  tagList: Array<string>;
                  type: string;
                  wert: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ message: string; type: string }>;
              } | null;
              firma?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                name1: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ message: string; type: string }>;
              } | null;
              kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
              kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
              makler?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                rechtstraegerId: string;
                kurzBezeichnung: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ message: string; type: string }>;
              } | null;
              sachbearbeiter?: {
                contactPersonId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                firstname: string;
                lastname: string;
                tagList: Array<string>;
                title?: string | null;
                titleTrailing?: string | null;
                contactList: Array<{
                  contactId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  haupt?: boolean | null;
                  tagList: Array<string>;
                  type: string;
                  wert: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                rechtstraeger?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              zahlungsperiode?: { text: string; value: Types.Zahlungsperiode } | null;
            }
          | null;
        priority: { text: string; value: Types.TicketPriority };
        rechtstraeger?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechtstraegerInfoFeld?: {
          __typename: 'RechtstraegerInfoFeldPersoneninfos';
          rechtstraegerInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          geburtsort?: string | null;
          identitaetsnachweis?: string | null;
          kundengruppeList?: Array<string> | null;
          merkmalList: Array<string>;
          staatsbuergerschaft?: string | null;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.RechtstraegerInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        reminder: { amount: number; unit: { text: string; value: Types.PeriodUnit } };
        ticketStatus: { text: string; value: Types.TicketStatus };
        type?: {
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          dueDateText?: string | null;
          firmendatenId?: string | null;
          isSubTicketTemplate: boolean;
          name: string;
          parentId?: string | null;
          position: number;
          reminderText?: string | null;
          ticketTemplateId: string;
          updatedByMitarbeiterId?: string | null;
          subTicketTemplateList?: Array<{
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            dueDateText?: string | null;
            firmendatenId?: string | null;
            isSubTicketTemplate: boolean;
            name: string;
            parentId?: string | null;
            position: number;
            reminderText?: string | null;
            ticketTemplateId: string;
            updatedByMitarbeiterId?: string | null;
            dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
            priority: { text: string; value: Types.TicketPriority };
            reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }> | null;
          dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
          priority: { text: string; value: Types.TicketPriority };
          reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vertrag?: {
          bestandseinheitId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          objektId: string;
          vertragId: string;
          vertragsBeginn: string;
          status: { text: string; value: Types.VertragStatus; description?: string | null };
          vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
          vertragspartner: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vertragInfoFeld?:
          | {
              __typename: 'VertragInfoFeldKuendigungsinfos';
              vertragInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              kuendigungBis: string;
              kuendigungsverzichtBestandgeberBis?: string | null;
              kuendigungsverzichtBestandnehmerBis?: string | null;
              bestandnehmerKuendigungsfrist?: number | null;
              kuendigungJeweilsZum?: string | null;
              kuendigungMoeglichZum?: string | null;
              gerichtlicheKuendigung?: boolean | null;
              kuendigungErfolgtAm?: string | null;
              kuendigungWirksamZum?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.VertragInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              kuendigungDurch?: { text: string; value: Types.KuendigungDurch } | null;
            }
          | {
              __typename: 'VertragInfoFeldWeiterePerson';
              vertragInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              text?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.VertragInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              natuerlichePerson: { rechtstraegerId: string; kurzBezeichnung: string; lastname: string; firstname: string };
            }
          | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      page: { totalPages: number; totalElements: number; number: number; size: number };
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type TicketQueryVariables = Types.Exact<{
  ticketId: Types.Scalars['ID']['input'];
  includeAttachmentsFromSubTickets?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeParticipantsFromSubTickets?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeEmailsFromSubTickets?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type TicketQuery = {
  getTicket: {
    data: {
      attachmentCount: number;
      commentCount: number;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      description?: string | null;
      dueDate: string;
      emailCount: number;
      isSubTicket: boolean;
      number: number;
      participantCount: number;
      position: number;
      reminderText: string;
      ticketId: string;
      title: string;
      typeName?: string | null;
      updatedByMitarbeiterId?: string | null;
      parent?: {
        attachmentCount: number;
        commentCount: number;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        description?: string | null;
        dueDate: string;
        emailCount: number;
        isSubTicket: boolean;
        number: number;
        participantCount: number;
        position: number;
        reminderText: string;
        ticketId: string;
        title: string;
        typeName?: string | null;
        updatedByMitarbeiterId?: string | null;
        assignee: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firstname: string;
          lastname: string;
          mitarbeiterId: string;
          updatedByMitarbeiterId?: string | null;
          firmendatenBaseData: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firmendatenId: string;
            kundenSystemId: string;
            kurzBezeichnung: string;
            name1: string;
            name2?: string | null;
            name3?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        beInfoFeld?:
          | {
              __typename: 'BeInfoFeldKellerabteil';
              beInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              text?: string | null;
              kellerabteilNummer: string;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.BeInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }
          | {
              __typename: 'BeInfoFeldParkplatz';
              beInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              text?: string | null;
              parkplatzNummer: string;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.BeInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              bestandseinheit?: { bestandseinheitId: string; bezeichnung: string } | null;
            }
          | {
              __typename: 'BeInfoFeldZimmer';
              beInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              anmerkung?: string | null;
              bezeichnung: string;
              bodenbelag?: string | null;
              breite?: number | null;
              energiekennzahl?: string | null;
              flaechentyp?: string | null;
              heizung?: string | null;
              hoehe?: number | null;
              initialQuadratmeterBetrag?: number | null;
              initialQuadratmeterPreis?: number | null;
              kubikmeter?: number | null;
              laenge?: number | null;
              nutzflaeche?: number | null;
              nutzung?: boolean | null;
              quadratmeter?: number | null;
              quadratmeterBetrag?: number | null;
              quadratmeterPreis?: number | null;
              zimmerNummer?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.BeInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              vorschreibungsposition?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                kurzBezeichnung: string;
                objektVorschreibungspositionId: string;
                vorschreibungspositionId: string;
                art: { text: string; value: Types.VorschreibungspositionArt };
                objektVorschreibungspositionStatus: { text: string; value: Types.ObjektVorschreibungspositionStatus };
                vorschreibungspositionStatus: { text: string; value: Types.VorschreibungspositionStatus };
              } | null;
            }
          | null;
        bestandseinheit?: {
          bestandseinheitId: string;
          bezeichnung: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          objektId: string;
          status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        objekt?: {
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          objektId: string;
          updatedByMitarbeiterId?: string | null;
          rechnungsAussteller: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          status: { text: string; value: Types.ObjektStatus; description?: string | null };
          verwaltungsart: { text: string; value: Types.Verwaltungsart };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        objektInfoFeld?:
          | {
              __typename: 'ObjektInfoFeldAuflage';
              objektInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              amtlicheQuadratmeterFestsetzung: boolean;
              assanierungsgebiet: boolean;
              bebaut: boolean;
              denkmalschutz: boolean;
              gruenderzeitviertel: boolean;
              naturschutz: boolean;
              schutzzone: boolean;
              vollanwendungMRG: boolean;
              wohnzone: boolean;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.ObjektInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }
          | {
              __typename: 'ObjektInfoFeldGrundstuecksdaten';
              objektInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              anzahlGeschosseUeberNiveau?: number | null;
              anzahlGeschosseUnterNiveau?: number | null;
              bauflaeche?: number | null;
              baujahr?: number | null;
              bebauteFlaeche?: number | null;
              benuetzungsbewilligung?: string | null;
              einlagezahl?: string | null;
              grundstuecksnummer: string;
              katastralgemeinde?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.ObjektInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }
          | {
              __typename: 'ObjektInfoFeldHausbetreuung';
              objektInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              beauftragungsgrund: string;
              beginn?: string | null;
              ende?: string | null;
              kuendigungsfristDauer?: number | null;
              vertragsdaten?: string | null;
              wertsicherung?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.ObjektInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              contactPerson?: {
                contactPersonId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                lastname: string;
                title?: string | null;
                firstname: string;
                salutation?: { text: string; value: Types.Salutation } | null;
                contactList: Array<{
                  contactId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  haupt?: boolean | null;
                  tagList: Array<string>;
                  type: string;
                  wert: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ message: string; type: string }>;
              } | null;
              kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
              kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
              hausbetreuungRechtstraeger?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
            }
          | {
              __typename: 'ObjektInfoFeldPerson';
              objektInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              rolle: string;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.ObjektInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              personRechtstraeger: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
            }
          | {
              __typename: 'ObjektInfoFeldVersicherung';
              objektInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              beginn?: string | null;
              datumSchaetzung?: string | null;
              ende?: string | null;
              erstmalsFaellig?: string | null;
              validFrom?: string | null;
              hauptDueDate?: string | null;
              kuendigungsfristDauer?: number | null;
              neubauwert?: number | null;
              polizze?: string | null;
              versicherungsart: string;
              versicherungssumme?: number | null;
              wertsicherung?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.ObjektInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              contactPerson?: {
                contactPersonId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                lastname: string;
                title?: string | null;
                firstname: string;
                salutation?: { text: string; value: Types.Salutation } | null;
                contactList: Array<{
                  contactId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  haupt?: boolean | null;
                  tagList: Array<string>;
                  type: string;
                  wert: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ message: string; type: string }>;
              } | null;
              firma?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                name1: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ message: string; type: string }>;
              } | null;
              kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
              kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
              makler?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                rechtstraegerId: string;
                kurzBezeichnung: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ message: string; type: string }>;
              } | null;
              sachbearbeiter?: {
                contactPersonId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                firstname: string;
                lastname: string;
                tagList: Array<string>;
                title?: string | null;
                titleTrailing?: string | null;
                contactList: Array<{
                  contactId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  haupt?: boolean | null;
                  tagList: Array<string>;
                  type: string;
                  wert: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                rechtstraeger?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              zahlungsperiode?: { text: string; value: Types.Zahlungsperiode } | null;
            }
          | null;
        priority: { text: string; value: Types.TicketPriority };
        rechtstraeger?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechtstraegerInfoFeld?: {
          __typename: 'RechtstraegerInfoFeldPersoneninfos';
          rechtstraegerInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          geburtsort?: string | null;
          identitaetsnachweis?: string | null;
          kundengruppeList?: Array<string> | null;
          merkmalList: Array<string>;
          staatsbuergerschaft?: string | null;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.RechtstraegerInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        reminder: { amount: number; unit: { text: string; value: Types.PeriodUnit } };
        ticketStatus: { text: string; value: Types.TicketStatus };
        type?: {
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          dueDateText?: string | null;
          firmendatenId?: string | null;
          isSubTicketTemplate: boolean;
          name: string;
          parentId?: string | null;
          position: number;
          reminderText?: string | null;
          ticketTemplateId: string;
          updatedByMitarbeiterId?: string | null;
          subTicketTemplateList?: Array<{
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            dueDateText?: string | null;
            firmendatenId?: string | null;
            isSubTicketTemplate: boolean;
            name: string;
            parentId?: string | null;
            position: number;
            reminderText?: string | null;
            ticketTemplateId: string;
            updatedByMitarbeiterId?: string | null;
            dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
            priority: { text: string; value: Types.TicketPriority };
            reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }> | null;
          dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
          priority: { text: string; value: Types.TicketPriority };
          reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vertrag?: {
          bestandseinheitId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          objektId: string;
          vertragId: string;
          vertragsBeginn: string;
          status: { text: string; value: Types.VertragStatus; description?: string | null };
          vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
          vertragspartner: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vertragInfoFeld?:
          | {
              __typename: 'VertragInfoFeldKuendigungsinfos';
              vertragInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              kuendigungBis: string;
              kuendigungsverzichtBestandgeberBis?: string | null;
              kuendigungsverzichtBestandnehmerBis?: string | null;
              bestandnehmerKuendigungsfrist?: number | null;
              kuendigungJeweilsZum?: string | null;
              kuendigungMoeglichZum?: string | null;
              gerichtlicheKuendigung?: boolean | null;
              kuendigungErfolgtAm?: string | null;
              kuendigungWirksamZum?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.VertragInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              kuendigungDurch?: { text: string; value: Types.KuendigungDurch } | null;
            }
          | {
              __typename: 'VertragInfoFeldWeiterePerson';
              vertragInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              text?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.VertragInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              natuerlichePerson: { rechtstraegerId: string; kurzBezeichnung: string; lastname: string; firstname: string };
            }
          | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      subTicketList: Array<{
        attachmentCount: number;
        commentCount: number;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        description?: string | null;
        dueDate: string;
        emailCount: number;
        isSubTicket: boolean;
        number: number;
        participantCount: number;
        position: number;
        reminderText: string;
        ticketId: string;
        title: string;
        typeName?: string | null;
        updatedByMitarbeiterId?: string | null;
        parent?: {
          attachmentCount: number;
          commentCount: number;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          description?: string | null;
          dueDate: string;
          emailCount: number;
          isSubTicket: boolean;
          number: number;
          participantCount: number;
          position: number;
          reminderText: string;
          ticketId: string;
          title: string;
          typeName?: string | null;
          updatedByMitarbeiterId?: string | null;
          assignee: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firstname: string;
            lastname: string;
            mitarbeiterId: string;
            updatedByMitarbeiterId?: string | null;
            firmendatenBaseData: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firmendatenId: string;
              kundenSystemId: string;
              kurzBezeichnung: string;
              name1: string;
              name2?: string | null;
              name3?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          beInfoFeld?:
            | {
                __typename: 'BeInfoFeldKellerabteil';
                beInfoFeldId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fileIdList: Array<string>;
                text?: string | null;
                kellerabteilNummer: string;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { text: string; value: Types.InfoFeldStatus };
                type: { text: string; value: Types.BeInfoFeldType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }
            | {
                __typename: 'BeInfoFeldParkplatz';
                beInfoFeldId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fileIdList: Array<string>;
                text?: string | null;
                parkplatzNummer: string;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { text: string; value: Types.InfoFeldStatus };
                type: { text: string; value: Types.BeInfoFeldType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                bestandseinheit?: { bestandseinheitId: string; bezeichnung: string } | null;
              }
            | {
                __typename: 'BeInfoFeldZimmer';
                beInfoFeldId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fileIdList: Array<string>;
                anmerkung?: string | null;
                bezeichnung: string;
                bodenbelag?: string | null;
                breite?: number | null;
                energiekennzahl?: string | null;
                flaechentyp?: string | null;
                heizung?: string | null;
                hoehe?: number | null;
                initialQuadratmeterBetrag?: number | null;
                initialQuadratmeterPreis?: number | null;
                kubikmeter?: number | null;
                laenge?: number | null;
                nutzflaeche?: number | null;
                nutzung?: boolean | null;
                quadratmeter?: number | null;
                quadratmeterBetrag?: number | null;
                quadratmeterPreis?: number | null;
                zimmerNummer?: string | null;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { text: string; value: Types.InfoFeldStatus };
                type: { text: string; value: Types.BeInfoFeldType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                vorschreibungsposition?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  deletable?: boolean | null;
                  kurzBezeichnung: string;
                  objektVorschreibungspositionId: string;
                  vorschreibungspositionId: string;
                  art: { text: string; value: Types.VorschreibungspositionArt };
                  objektVorschreibungspositionStatus: { text: string; value: Types.ObjektVorschreibungspositionStatus };
                  vorschreibungspositionStatus: { text: string; value: Types.VorschreibungspositionStatus };
                } | null;
              }
            | null;
          bestandseinheit?: {
            bestandseinheitId: string;
            bezeichnung: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            objektId: string;
            status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          objekt?: {
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            objektId: string;
            updatedByMitarbeiterId?: string | null;
            rechnungsAussteller: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            status: { text: string; value: Types.ObjektStatus; description?: string | null };
            verwaltungsart: { text: string; value: Types.Verwaltungsart };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          objektInfoFeld?:
            | {
                __typename: 'ObjektInfoFeldAuflage';
                objektInfoFeldId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fileIdList: Array<string>;
                amtlicheQuadratmeterFestsetzung: boolean;
                assanierungsgebiet: boolean;
                bebaut: boolean;
                denkmalschutz: boolean;
                gruenderzeitviertel: boolean;
                naturschutz: boolean;
                schutzzone: boolean;
                vollanwendungMRG: boolean;
                wohnzone: boolean;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { text: string; value: Types.InfoFeldStatus };
                type: { text: string; value: Types.ObjektInfoFeldType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }
            | {
                __typename: 'ObjektInfoFeldGrundstuecksdaten';
                objektInfoFeldId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fileIdList: Array<string>;
                anzahlGeschosseUeberNiveau?: number | null;
                anzahlGeschosseUnterNiveau?: number | null;
                bauflaeche?: number | null;
                baujahr?: number | null;
                bebauteFlaeche?: number | null;
                benuetzungsbewilligung?: string | null;
                einlagezahl?: string | null;
                grundstuecksnummer: string;
                katastralgemeinde?: string | null;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { text: string; value: Types.InfoFeldStatus };
                type: { text: string; value: Types.ObjektInfoFeldType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }
            | {
                __typename: 'ObjektInfoFeldHausbetreuung';
                objektInfoFeldId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fileIdList: Array<string>;
                beauftragungsgrund: string;
                beginn?: string | null;
                ende?: string | null;
                kuendigungsfristDauer?: number | null;
                vertragsdaten?: string | null;
                wertsicherung?: string | null;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { text: string; value: Types.InfoFeldStatus };
                type: { text: string; value: Types.ObjektInfoFeldType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                contactPerson?: {
                  contactPersonId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  lastname: string;
                  title?: string | null;
                  firstname: string;
                  salutation?: { text: string; value: Types.Salutation } | null;
                  contactList: Array<{
                    contactId: string;
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    haupt?: boolean | null;
                    tagList: Array<string>;
                    type: string;
                    wert: string;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  warningList: Array<{ message: string; type: string }>;
                } | null;
                kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
                kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
                hausbetreuungRechtstraeger?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
              }
            | {
                __typename: 'ObjektInfoFeldPerson';
                objektInfoFeldId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fileIdList: Array<string>;
                rolle: string;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { text: string; value: Types.InfoFeldStatus };
                type: { text: string; value: Types.ObjektInfoFeldType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                personRechtstraeger: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
              }
            | {
                __typename: 'ObjektInfoFeldVersicherung';
                objektInfoFeldId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fileIdList: Array<string>;
                beginn?: string | null;
                datumSchaetzung?: string | null;
                ende?: string | null;
                erstmalsFaellig?: string | null;
                validFrom?: string | null;
                hauptDueDate?: string | null;
                kuendigungsfristDauer?: number | null;
                neubauwert?: number | null;
                polizze?: string | null;
                versicherungsart: string;
                versicherungssumme?: number | null;
                wertsicherung?: string | null;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { text: string; value: Types.InfoFeldStatus };
                type: { text: string; value: Types.ObjektInfoFeldType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                contactPerson?: {
                  contactPersonId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  lastname: string;
                  title?: string | null;
                  firstname: string;
                  salutation?: { text: string; value: Types.Salutation } | null;
                  contactList: Array<{
                    contactId: string;
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    haupt?: boolean | null;
                    tagList: Array<string>;
                    type: string;
                    wert: string;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  warningList: Array<{ message: string; type: string }>;
                } | null;
                firma?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  name1: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ message: string; type: string }>;
                } | null;
                kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
                kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
                makler?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  rechtstraegerId: string;
                  kurzBezeichnung: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ message: string; type: string }>;
                } | null;
                sachbearbeiter?: {
                  contactPersonId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  firstname: string;
                  lastname: string;
                  tagList: Array<string>;
                  title?: string | null;
                  titleTrailing?: string | null;
                  contactList: Array<{
                    contactId: string;
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    haupt?: boolean | null;
                    tagList: Array<string>;
                    type: string;
                    wert: string;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  rechtstraeger?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                  salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                zahlungsperiode?: { text: string; value: Types.Zahlungsperiode } | null;
              }
            | null;
          priority: { text: string; value: Types.TicketPriority };
          rechtstraeger?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechtstraegerInfoFeld?: {
            __typename: 'RechtstraegerInfoFeldPersoneninfos';
            rechtstraegerInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            geburtsort?: string | null;
            identitaetsnachweis?: string | null;
            kundengruppeList?: Array<string> | null;
            merkmalList: Array<string>;
            staatsbuergerschaft?: string | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.RechtstraegerInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          reminder: { amount: number; unit: { text: string; value: Types.PeriodUnit } };
          ticketStatus: { text: string; value: Types.TicketStatus };
          type?: {
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            dueDateText?: string | null;
            firmendatenId?: string | null;
            isSubTicketTemplate: boolean;
            name: string;
            parentId?: string | null;
            position: number;
            reminderText?: string | null;
            ticketTemplateId: string;
            updatedByMitarbeiterId?: string | null;
            subTicketTemplateList?: Array<{
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              dueDateText?: string | null;
              firmendatenId?: string | null;
              isSubTicketTemplate: boolean;
              name: string;
              parentId?: string | null;
              position: number;
              reminderText?: string | null;
              ticketTemplateId: string;
              updatedByMitarbeiterId?: string | null;
              dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
              priority: { text: string; value: Types.TicketPriority };
              reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }> | null;
            dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
            priority: { text: string; value: Types.TicketPriority };
            reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          vertrag?: {
            bestandseinheitId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            objektId: string;
            vertragId: string;
            vertragsBeginn: string;
            status: { text: string; value: Types.VertragStatus; description?: string | null };
            vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
            vertragspartner: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          vertragInfoFeld?:
            | {
                __typename: 'VertragInfoFeldKuendigungsinfos';
                vertragInfoFeldId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fileIdList: Array<string>;
                kuendigungBis: string;
                kuendigungsverzichtBestandgeberBis?: string | null;
                kuendigungsverzichtBestandnehmerBis?: string | null;
                bestandnehmerKuendigungsfrist?: number | null;
                kuendigungJeweilsZum?: string | null;
                kuendigungMoeglichZum?: string | null;
                gerichtlicheKuendigung?: boolean | null;
                kuendigungErfolgtAm?: string | null;
                kuendigungWirksamZum?: string | null;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { text: string; value: Types.InfoFeldStatus };
                type: { text: string; value: Types.VertragInfoFeldType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                kuendigungDurch?: { text: string; value: Types.KuendigungDurch } | null;
              }
            | {
                __typename: 'VertragInfoFeldWeiterePerson';
                vertragInfoFeldId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fileIdList: Array<string>;
                text?: string | null;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { text: string; value: Types.InfoFeldStatus };
                type: { text: string; value: Types.VertragInfoFeldType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                natuerlichePerson: { rechtstraegerId: string; kurzBezeichnung: string; lastname: string; firstname: string };
              }
            | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        assignee: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firstname: string;
          lastname: string;
          mitarbeiterId: string;
          updatedByMitarbeiterId?: string | null;
          firmendatenBaseData: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firmendatenId: string;
            kundenSystemId: string;
            kurzBezeichnung: string;
            name1: string;
            name2?: string | null;
            name3?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        beInfoFeld?:
          | {
              __typename: 'BeInfoFeldKellerabteil';
              beInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              text?: string | null;
              kellerabteilNummer: string;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.BeInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }
          | {
              __typename: 'BeInfoFeldParkplatz';
              beInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              text?: string | null;
              parkplatzNummer: string;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.BeInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              bestandseinheit?: { bestandseinheitId: string; bezeichnung: string } | null;
            }
          | {
              __typename: 'BeInfoFeldZimmer';
              beInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              anmerkung?: string | null;
              bezeichnung: string;
              bodenbelag?: string | null;
              breite?: number | null;
              energiekennzahl?: string | null;
              flaechentyp?: string | null;
              heizung?: string | null;
              hoehe?: number | null;
              initialQuadratmeterBetrag?: number | null;
              initialQuadratmeterPreis?: number | null;
              kubikmeter?: number | null;
              laenge?: number | null;
              nutzflaeche?: number | null;
              nutzung?: boolean | null;
              quadratmeter?: number | null;
              quadratmeterBetrag?: number | null;
              quadratmeterPreis?: number | null;
              zimmerNummer?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.BeInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              vorschreibungsposition?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                kurzBezeichnung: string;
                objektVorschreibungspositionId: string;
                vorschreibungspositionId: string;
                art: { text: string; value: Types.VorschreibungspositionArt };
                objektVorschreibungspositionStatus: { text: string; value: Types.ObjektVorschreibungspositionStatus };
                vorschreibungspositionStatus: { text: string; value: Types.VorschreibungspositionStatus };
              } | null;
            }
          | null;
        bestandseinheit?: {
          bestandseinheitId: string;
          bezeichnung: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          objektId: string;
          status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        objekt?: {
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          objektId: string;
          updatedByMitarbeiterId?: string | null;
          rechnungsAussteller: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          status: { text: string; value: Types.ObjektStatus; description?: string | null };
          verwaltungsart: { text: string; value: Types.Verwaltungsart };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        objektInfoFeld?:
          | {
              __typename: 'ObjektInfoFeldAuflage';
              objektInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              amtlicheQuadratmeterFestsetzung: boolean;
              assanierungsgebiet: boolean;
              bebaut: boolean;
              denkmalschutz: boolean;
              gruenderzeitviertel: boolean;
              naturschutz: boolean;
              schutzzone: boolean;
              vollanwendungMRG: boolean;
              wohnzone: boolean;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.ObjektInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }
          | {
              __typename: 'ObjektInfoFeldGrundstuecksdaten';
              objektInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              anzahlGeschosseUeberNiveau?: number | null;
              anzahlGeschosseUnterNiveau?: number | null;
              bauflaeche?: number | null;
              baujahr?: number | null;
              bebauteFlaeche?: number | null;
              benuetzungsbewilligung?: string | null;
              einlagezahl?: string | null;
              grundstuecksnummer: string;
              katastralgemeinde?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.ObjektInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }
          | {
              __typename: 'ObjektInfoFeldHausbetreuung';
              objektInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              beauftragungsgrund: string;
              beginn?: string | null;
              ende?: string | null;
              kuendigungsfristDauer?: number | null;
              vertragsdaten?: string | null;
              wertsicherung?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.ObjektInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              contactPerson?: {
                contactPersonId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                lastname: string;
                title?: string | null;
                firstname: string;
                salutation?: { text: string; value: Types.Salutation } | null;
                contactList: Array<{
                  contactId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  haupt?: boolean | null;
                  tagList: Array<string>;
                  type: string;
                  wert: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ message: string; type: string }>;
              } | null;
              kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
              kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
              hausbetreuungRechtstraeger?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
            }
          | {
              __typename: 'ObjektInfoFeldPerson';
              objektInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              rolle: string;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.ObjektInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              personRechtstraeger: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
            }
          | {
              __typename: 'ObjektInfoFeldVersicherung';
              objektInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              beginn?: string | null;
              datumSchaetzung?: string | null;
              ende?: string | null;
              erstmalsFaellig?: string | null;
              validFrom?: string | null;
              hauptDueDate?: string | null;
              kuendigungsfristDauer?: number | null;
              neubauwert?: number | null;
              polizze?: string | null;
              versicherungsart: string;
              versicherungssumme?: number | null;
              wertsicherung?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.ObjektInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              contactPerson?: {
                contactPersonId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                lastname: string;
                title?: string | null;
                firstname: string;
                salutation?: { text: string; value: Types.Salutation } | null;
                contactList: Array<{
                  contactId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  haupt?: boolean | null;
                  tagList: Array<string>;
                  type: string;
                  wert: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ message: string; type: string }>;
              } | null;
              firma?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                name1: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ message: string; type: string }>;
              } | null;
              kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
              kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
              makler?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                rechtstraegerId: string;
                kurzBezeichnung: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ message: string; type: string }>;
              } | null;
              sachbearbeiter?: {
                contactPersonId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                firstname: string;
                lastname: string;
                tagList: Array<string>;
                title?: string | null;
                titleTrailing?: string | null;
                contactList: Array<{
                  contactId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  haupt?: boolean | null;
                  tagList: Array<string>;
                  type: string;
                  wert: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                rechtstraeger?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              zahlungsperiode?: { text: string; value: Types.Zahlungsperiode } | null;
            }
          | null;
        priority: { text: string; value: Types.TicketPriority };
        rechtstraeger?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechtstraegerInfoFeld?: {
          __typename: 'RechtstraegerInfoFeldPersoneninfos';
          rechtstraegerInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          geburtsort?: string | null;
          identitaetsnachweis?: string | null;
          kundengruppeList?: Array<string> | null;
          merkmalList: Array<string>;
          staatsbuergerschaft?: string | null;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.RechtstraegerInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        reminder: { amount: number; unit: { text: string; value: Types.PeriodUnit } };
        ticketStatus: { text: string; value: Types.TicketStatus };
        type?: {
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          dueDateText?: string | null;
          firmendatenId?: string | null;
          isSubTicketTemplate: boolean;
          name: string;
          parentId?: string | null;
          position: number;
          reminderText?: string | null;
          ticketTemplateId: string;
          updatedByMitarbeiterId?: string | null;
          subTicketTemplateList?: Array<{
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            dueDateText?: string | null;
            firmendatenId?: string | null;
            isSubTicketTemplate: boolean;
            name: string;
            parentId?: string | null;
            position: number;
            reminderText?: string | null;
            ticketTemplateId: string;
            updatedByMitarbeiterId?: string | null;
            dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
            priority: { text: string; value: Types.TicketPriority };
            reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }> | null;
          dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
          priority: { text: string; value: Types.TicketPriority };
          reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vertrag?: {
          bestandseinheitId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          objektId: string;
          vertragId: string;
          vertragsBeginn: string;
          status: { text: string; value: Types.VertragStatus; description?: string | null };
          vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
          vertragspartner: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vertragInfoFeld?:
          | {
              __typename: 'VertragInfoFeldKuendigungsinfos';
              vertragInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              kuendigungBis: string;
              kuendigungsverzichtBestandgeberBis?: string | null;
              kuendigungsverzichtBestandnehmerBis?: string | null;
              bestandnehmerKuendigungsfrist?: number | null;
              kuendigungJeweilsZum?: string | null;
              kuendigungMoeglichZum?: string | null;
              gerichtlicheKuendigung?: boolean | null;
              kuendigungErfolgtAm?: string | null;
              kuendigungWirksamZum?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.VertragInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              kuendigungDurch?: { text: string; value: Types.KuendigungDurch } | null;
            }
          | {
              __typename: 'VertragInfoFeldWeiterePerson';
              vertragInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              text?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.VertragInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              natuerlichePerson: { rechtstraegerId: string; kurzBezeichnung: string; lastname: string; firstname: string };
            }
          | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      assignee: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firstname: string;
        lastname: string;
        mitarbeiterId: string;
        updatedByMitarbeiterId?: string | null;
        firmendatenBaseData: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firmendatenId: string;
          kundenSystemId: string;
          kurzBezeichnung: string;
          name1: string;
          name2?: string | null;
          name3?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      attachments: Array<{ ticketId: string; ticketTitle: string; fileInfo: { fileId: string; name: string; size: number } }>;
      beInfoFeld?:
        | {
            __typename: 'BeInfoFeldKellerabteil';
            beInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            text?: string | null;
            kellerabteilNummer: string;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.BeInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }
        | {
            __typename: 'BeInfoFeldParkplatz';
            beInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            text?: string | null;
            parkplatzNummer: string;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.BeInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            bestandseinheit?: { bestandseinheitId: string; bezeichnung: string } | null;
          }
        | {
            __typename: 'BeInfoFeldZimmer';
            beInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            anmerkung?: string | null;
            bezeichnung: string;
            bodenbelag?: string | null;
            breite?: number | null;
            energiekennzahl?: string | null;
            flaechentyp?: string | null;
            heizung?: string | null;
            hoehe?: number | null;
            initialQuadratmeterBetrag?: number | null;
            initialQuadratmeterPreis?: number | null;
            kubikmeter?: number | null;
            laenge?: number | null;
            nutzflaeche?: number | null;
            nutzung?: boolean | null;
            quadratmeter?: number | null;
            quadratmeterBetrag?: number | null;
            quadratmeterPreis?: number | null;
            zimmerNummer?: string | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.BeInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            vorschreibungsposition?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              kurzBezeichnung: string;
              objektVorschreibungspositionId: string;
              vorschreibungspositionId: string;
              art: { text: string; value: Types.VorschreibungspositionArt };
              objektVorschreibungspositionStatus: { text: string; value: Types.ObjektVorschreibungspositionStatus };
              vorschreibungspositionStatus: { text: string; value: Types.VorschreibungspositionStatus };
            } | null;
          }
        | null;
      bestandseinheit?: {
        bestandseinheitId: string;
        bezeichnung: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        objektId: string;
        status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      emailList: Array<{
        sender?: string | null;
        sentTs?: string | null;
        subject?: string | null;
        ticketId: string;
        ticketTitle: string;
        attachments: Array<{ ticketId: string; ticketTitle: string; fileInfo: { fileId: string; name: string; size: number } }>;
        fileInfo: { fileId: string; name: string; size: number };
        ticketParticipant: {
          ticketId: string;
          ticketTitle: string;
          participant: {
            emailAddress?: string | null;
            participantId?: string | null;
            contact?: {
              contactId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              haupt?: boolean | null;
              tagList: Array<string>;
              type: string;
              wert: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechtstraeger?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
          };
        };
      }>;
      objekt?: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        objektId: string;
        updatedByMitarbeiterId?: string | null;
        rechnungsAussteller: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        status: { text: string; value: Types.ObjektStatus; description?: string | null };
        verwaltungsart: { text: string; value: Types.Verwaltungsart };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      objektInfoFeld?:
        | {
            __typename: 'ObjektInfoFeldAuflage';
            objektInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            amtlicheQuadratmeterFestsetzung: boolean;
            assanierungsgebiet: boolean;
            bebaut: boolean;
            denkmalschutz: boolean;
            gruenderzeitviertel: boolean;
            naturschutz: boolean;
            schutzzone: boolean;
            vollanwendungMRG: boolean;
            wohnzone: boolean;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.ObjektInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }
        | {
            __typename: 'ObjektInfoFeldGrundstuecksdaten';
            objektInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            anzahlGeschosseUeberNiveau?: number | null;
            anzahlGeschosseUnterNiveau?: number | null;
            bauflaeche?: number | null;
            baujahr?: number | null;
            bebauteFlaeche?: number | null;
            benuetzungsbewilligung?: string | null;
            einlagezahl?: string | null;
            grundstuecksnummer: string;
            katastralgemeinde?: string | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.ObjektInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }
        | {
            __typename: 'ObjektInfoFeldHausbetreuung';
            objektInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            beauftragungsgrund: string;
            beginn?: string | null;
            ende?: string | null;
            kuendigungsfristDauer?: number | null;
            vertragsdaten?: string | null;
            wertsicherung?: string | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.ObjektInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            contactPerson?: {
              contactPersonId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              lastname: string;
              title?: string | null;
              firstname: string;
              salutation?: { text: string; value: Types.Salutation } | null;
              contactList: Array<{
                contactId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                haupt?: boolean | null;
                tagList: Array<string>;
                type: string;
                wert: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ message: string; type: string }>;
            } | null;
            kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
            kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
            hausbetreuungRechtstraeger?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
          }
        | {
            __typename: 'ObjektInfoFeldPerson';
            objektInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            rolle: string;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.ObjektInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            personRechtstraeger: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
          }
        | {
            __typename: 'ObjektInfoFeldVersicherung';
            objektInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            beginn?: string | null;
            datumSchaetzung?: string | null;
            ende?: string | null;
            erstmalsFaellig?: string | null;
            validFrom?: string | null;
            hauptDueDate?: string | null;
            kuendigungsfristDauer?: number | null;
            neubauwert?: number | null;
            polizze?: string | null;
            versicherungsart: string;
            versicherungssumme?: number | null;
            wertsicherung?: string | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.ObjektInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            contactPerson?: {
              contactPersonId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              lastname: string;
              title?: string | null;
              firstname: string;
              salutation?: { text: string; value: Types.Salutation } | null;
              contactList: Array<{
                contactId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                haupt?: boolean | null;
                tagList: Array<string>;
                type: string;
                wert: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ message: string; type: string }>;
            } | null;
            firma?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              name1: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ message: string; type: string }>;
            } | null;
            kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
            kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
            makler?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              rechtstraegerId: string;
              kurzBezeichnung: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ message: string; type: string }>;
            } | null;
            sachbearbeiter?: {
              contactPersonId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firstname: string;
              lastname: string;
              tagList: Array<string>;
              title?: string | null;
              titleTrailing?: string | null;
              contactList: Array<{
                contactId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                haupt?: boolean | null;
                tagList: Array<string>;
                type: string;
                wert: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              rechtstraeger?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            zahlungsperiode?: { text: string; value: Types.Zahlungsperiode } | null;
          }
        | null;
      participantList: Array<{
        ticketId: string;
        ticketTitle: string;
        participant: {
          emailAddress?: string | null;
          participantId?: string | null;
          contact?: {
            contactId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            haupt?: boolean | null;
            tagList: Array<string>;
            type: string;
            wert: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechtstraeger?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
        };
      }>;
      priority: { text: string; value: Types.TicketPriority };
      rechtstraeger?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechtstraegerInfoFeld?: {
        __typename: 'RechtstraegerInfoFeldPersoneninfos';
        rechtstraegerInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        geburtsort?: string | null;
        identitaetsnachweis?: string | null;
        kundengruppeList?: Array<string> | null;
        merkmalList: Array<string>;
        staatsbuergerschaft?: string | null;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.RechtstraegerInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      reminder: { amount: number; unit: { text: string; value: Types.PeriodUnit } };
      ticketStatus: { text: string; value: Types.TicketStatus };
      type?: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        dueDateText?: string | null;
        firmendatenId?: string | null;
        isSubTicketTemplate: boolean;
        name: string;
        parentId?: string | null;
        position: number;
        reminderText?: string | null;
        ticketTemplateId: string;
        updatedByMitarbeiterId?: string | null;
        subTicketTemplateList?: Array<{
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          dueDateText?: string | null;
          firmendatenId?: string | null;
          isSubTicketTemplate: boolean;
          name: string;
          parentId?: string | null;
          position: number;
          reminderText?: string | null;
          ticketTemplateId: string;
          updatedByMitarbeiterId?: string | null;
          dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
          priority: { text: string; value: Types.TicketPriority };
          reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }> | null;
        dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
        priority: { text: string; value: Types.TicketPriority };
        reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      vertrag?: {
        bestandseinheitId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        objektId: string;
        vertragId: string;
        vertragsBeginn: string;
        status: { text: string; value: Types.VertragStatus; description?: string | null };
        vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
        vertragspartner: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      vertragInfoFeld?:
        | {
            __typename: 'VertragInfoFeldKuendigungsinfos';
            vertragInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            kuendigungBis: string;
            kuendigungsverzichtBestandgeberBis?: string | null;
            kuendigungsverzichtBestandnehmerBis?: string | null;
            bestandnehmerKuendigungsfrist?: number | null;
            kuendigungJeweilsZum?: string | null;
            kuendigungMoeglichZum?: string | null;
            gerichtlicheKuendigung?: boolean | null;
            kuendigungErfolgtAm?: string | null;
            kuendigungWirksamZum?: string | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.VertragInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            kuendigungDurch?: { text: string; value: Types.KuendigungDurch } | null;
          }
        | {
            __typename: 'VertragInfoFeldWeiterePerson';
            vertragInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            text?: string | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.VertragInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            natuerlichePerson: { rechtstraegerId: string; kurzBezeichnung: string; lastname: string; firstname: string };
          }
        | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type MyTicketListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MyTicketListQuery = {
  getMyTicketList: {
    data: Array<{
      attachmentCount: number;
      commentCount: number;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      description?: string | null;
      dueDate: string;
      emailCount: number;
      isSubTicket: boolean;
      number: number;
      participantCount: number;
      position: number;
      reminderText: string;
      ticketId: string;
      title: string;
      typeName?: string | null;
      updatedByMitarbeiterId?: string | null;
      parent?: {
        attachmentCount: number;
        commentCount: number;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        description?: string | null;
        dueDate: string;
        emailCount: number;
        isSubTicket: boolean;
        number: number;
        participantCount: number;
        position: number;
        reminderText: string;
        ticketId: string;
        title: string;
        typeName?: string | null;
        updatedByMitarbeiterId?: string | null;
        assignee: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firstname: string;
          lastname: string;
          mitarbeiterId: string;
          updatedByMitarbeiterId?: string | null;
          firmendatenBaseData: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firmendatenId: string;
            kundenSystemId: string;
            kurzBezeichnung: string;
            name1: string;
            name2?: string | null;
            name3?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        beInfoFeld?:
          | {
              __typename: 'BeInfoFeldKellerabteil';
              beInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              text?: string | null;
              kellerabteilNummer: string;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.BeInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }
          | {
              __typename: 'BeInfoFeldParkplatz';
              beInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              text?: string | null;
              parkplatzNummer: string;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.BeInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              bestandseinheit?: { bestandseinheitId: string; bezeichnung: string } | null;
            }
          | {
              __typename: 'BeInfoFeldZimmer';
              beInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              anmerkung?: string | null;
              bezeichnung: string;
              bodenbelag?: string | null;
              breite?: number | null;
              energiekennzahl?: string | null;
              flaechentyp?: string | null;
              heizung?: string | null;
              hoehe?: number | null;
              initialQuadratmeterBetrag?: number | null;
              initialQuadratmeterPreis?: number | null;
              kubikmeter?: number | null;
              laenge?: number | null;
              nutzflaeche?: number | null;
              nutzung?: boolean | null;
              quadratmeter?: number | null;
              quadratmeterBetrag?: number | null;
              quadratmeterPreis?: number | null;
              zimmerNummer?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.BeInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              vorschreibungsposition?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                kurzBezeichnung: string;
                objektVorschreibungspositionId: string;
                vorschreibungspositionId: string;
                art: { text: string; value: Types.VorschreibungspositionArt };
                objektVorschreibungspositionStatus: { text: string; value: Types.ObjektVorschreibungspositionStatus };
                vorschreibungspositionStatus: { text: string; value: Types.VorschreibungspositionStatus };
              } | null;
            }
          | null;
        bestandseinheit?: {
          bestandseinheitId: string;
          bezeichnung: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          objektId: string;
          status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        objekt?: {
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          objektId: string;
          updatedByMitarbeiterId?: string | null;
          rechnungsAussteller: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          status: { text: string; value: Types.ObjektStatus; description?: string | null };
          verwaltungsart: { text: string; value: Types.Verwaltungsart };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        objektInfoFeld?:
          | {
              __typename: 'ObjektInfoFeldAuflage';
              objektInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              amtlicheQuadratmeterFestsetzung: boolean;
              assanierungsgebiet: boolean;
              bebaut: boolean;
              denkmalschutz: boolean;
              gruenderzeitviertel: boolean;
              naturschutz: boolean;
              schutzzone: boolean;
              vollanwendungMRG: boolean;
              wohnzone: boolean;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.ObjektInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }
          | {
              __typename: 'ObjektInfoFeldGrundstuecksdaten';
              objektInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              anzahlGeschosseUeberNiveau?: number | null;
              anzahlGeschosseUnterNiveau?: number | null;
              bauflaeche?: number | null;
              baujahr?: number | null;
              bebauteFlaeche?: number | null;
              benuetzungsbewilligung?: string | null;
              einlagezahl?: string | null;
              grundstuecksnummer: string;
              katastralgemeinde?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.ObjektInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }
          | {
              __typename: 'ObjektInfoFeldHausbetreuung';
              objektInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              beauftragungsgrund: string;
              beginn?: string | null;
              ende?: string | null;
              kuendigungsfristDauer?: number | null;
              vertragsdaten?: string | null;
              wertsicherung?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.ObjektInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              contactPerson?: {
                contactPersonId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                lastname: string;
                title?: string | null;
                firstname: string;
                salutation?: { text: string; value: Types.Salutation } | null;
                contactList: Array<{
                  contactId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  haupt?: boolean | null;
                  tagList: Array<string>;
                  type: string;
                  wert: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ message: string; type: string }>;
              } | null;
              kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
              kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
              hausbetreuungRechtstraeger?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
            }
          | {
              __typename: 'ObjektInfoFeldPerson';
              objektInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              rolle: string;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.ObjektInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              personRechtstraeger: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
            }
          | {
              __typename: 'ObjektInfoFeldVersicherung';
              objektInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              beginn?: string | null;
              datumSchaetzung?: string | null;
              ende?: string | null;
              erstmalsFaellig?: string | null;
              validFrom?: string | null;
              hauptDueDate?: string | null;
              kuendigungsfristDauer?: number | null;
              neubauwert?: number | null;
              polizze?: string | null;
              versicherungsart: string;
              versicherungssumme?: number | null;
              wertsicherung?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.ObjektInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              contactPerson?: {
                contactPersonId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                lastname: string;
                title?: string | null;
                firstname: string;
                salutation?: { text: string; value: Types.Salutation } | null;
                contactList: Array<{
                  contactId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  haupt?: boolean | null;
                  tagList: Array<string>;
                  type: string;
                  wert: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                warningList: Array<{ message: string; type: string }>;
              } | null;
              firma?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                name1: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ message: string; type: string }>;
              } | null;
              kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
              kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
              makler?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                rechtstraegerId: string;
                kurzBezeichnung: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ message: string; type: string }>;
              } | null;
              sachbearbeiter?: {
                contactPersonId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                firstname: string;
                lastname: string;
                tagList: Array<string>;
                title?: string | null;
                titleTrailing?: string | null;
                contactList: Array<{
                  contactId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  haupt?: boolean | null;
                  tagList: Array<string>;
                  type: string;
                  wert: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                rechtstraeger?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              zahlungsperiode?: { text: string; value: Types.Zahlungsperiode } | null;
            }
          | null;
        priority: { text: string; value: Types.TicketPriority };
        rechtstraeger?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechtstraegerInfoFeld?: {
          __typename: 'RechtstraegerInfoFeldPersoneninfos';
          rechtstraegerInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          geburtsort?: string | null;
          identitaetsnachweis?: string | null;
          kundengruppeList?: Array<string> | null;
          merkmalList: Array<string>;
          staatsbuergerschaft?: string | null;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.RechtstraegerInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        reminder: { amount: number; unit: { text: string; value: Types.PeriodUnit } };
        ticketStatus: { text: string; value: Types.TicketStatus };
        type?: {
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          dueDateText?: string | null;
          firmendatenId?: string | null;
          isSubTicketTemplate: boolean;
          name: string;
          parentId?: string | null;
          position: number;
          reminderText?: string | null;
          ticketTemplateId: string;
          updatedByMitarbeiterId?: string | null;
          subTicketTemplateList?: Array<{
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            dueDateText?: string | null;
            firmendatenId?: string | null;
            isSubTicketTemplate: boolean;
            name: string;
            parentId?: string | null;
            position: number;
            reminderText?: string | null;
            ticketTemplateId: string;
            updatedByMitarbeiterId?: string | null;
            dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
            priority: { text: string; value: Types.TicketPriority };
            reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }> | null;
          dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
          priority: { text: string; value: Types.TicketPriority };
          reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vertrag?: {
          bestandseinheitId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          objektId: string;
          vertragId: string;
          vertragsBeginn: string;
          status: { text: string; value: Types.VertragStatus; description?: string | null };
          vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
          vertragspartner: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vertragInfoFeld?:
          | {
              __typename: 'VertragInfoFeldKuendigungsinfos';
              vertragInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              kuendigungBis: string;
              kuendigungsverzichtBestandgeberBis?: string | null;
              kuendigungsverzichtBestandnehmerBis?: string | null;
              bestandnehmerKuendigungsfrist?: number | null;
              kuendigungJeweilsZum?: string | null;
              kuendigungMoeglichZum?: string | null;
              gerichtlicheKuendigung?: boolean | null;
              kuendigungErfolgtAm?: string | null;
              kuendigungWirksamZum?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.VertragInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              kuendigungDurch?: { text: string; value: Types.KuendigungDurch } | null;
            }
          | {
              __typename: 'VertragInfoFeldWeiterePerson';
              vertragInfoFeldId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fileIdList: Array<string>;
              text?: string | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.InfoFeldStatus };
              type: { text: string; value: Types.VertragInfoFeldType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              natuerlichePerson: { rechtstraegerId: string; kurzBezeichnung: string; lastname: string; firstname: string };
            }
          | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      assignee: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firstname: string;
        lastname: string;
        mitarbeiterId: string;
        updatedByMitarbeiterId?: string | null;
        firmendatenBaseData: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firmendatenId: string;
          kundenSystemId: string;
          kurzBezeichnung: string;
          name1: string;
          name2?: string | null;
          name3?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      beInfoFeld?:
        | {
            __typename: 'BeInfoFeldKellerabteil';
            beInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            text?: string | null;
            kellerabteilNummer: string;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.BeInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }
        | {
            __typename: 'BeInfoFeldParkplatz';
            beInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            text?: string | null;
            parkplatzNummer: string;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.BeInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            bestandseinheit?: { bestandseinheitId: string; bezeichnung: string } | null;
          }
        | {
            __typename: 'BeInfoFeldZimmer';
            beInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            anmerkung?: string | null;
            bezeichnung: string;
            bodenbelag?: string | null;
            breite?: number | null;
            energiekennzahl?: string | null;
            flaechentyp?: string | null;
            heizung?: string | null;
            hoehe?: number | null;
            initialQuadratmeterBetrag?: number | null;
            initialQuadratmeterPreis?: number | null;
            kubikmeter?: number | null;
            laenge?: number | null;
            nutzflaeche?: number | null;
            nutzung?: boolean | null;
            quadratmeter?: number | null;
            quadratmeterBetrag?: number | null;
            quadratmeterPreis?: number | null;
            zimmerNummer?: string | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.BeInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            vorschreibungsposition?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              kurzBezeichnung: string;
              objektVorschreibungspositionId: string;
              vorschreibungspositionId: string;
              art: { text: string; value: Types.VorschreibungspositionArt };
              objektVorschreibungspositionStatus: { text: string; value: Types.ObjektVorschreibungspositionStatus };
              vorschreibungspositionStatus: { text: string; value: Types.VorschreibungspositionStatus };
            } | null;
          }
        | null;
      bestandseinheit?: {
        bestandseinheitId: string;
        bezeichnung: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        objektId: string;
        status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      objekt?: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        objektId: string;
        updatedByMitarbeiterId?: string | null;
        rechnungsAussteller: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        status: { text: string; value: Types.ObjektStatus; description?: string | null };
        verwaltungsart: { text: string; value: Types.Verwaltungsart };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      objektInfoFeld?:
        | {
            __typename: 'ObjektInfoFeldAuflage';
            objektInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            amtlicheQuadratmeterFestsetzung: boolean;
            assanierungsgebiet: boolean;
            bebaut: boolean;
            denkmalschutz: boolean;
            gruenderzeitviertel: boolean;
            naturschutz: boolean;
            schutzzone: boolean;
            vollanwendungMRG: boolean;
            wohnzone: boolean;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.ObjektInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }
        | {
            __typename: 'ObjektInfoFeldGrundstuecksdaten';
            objektInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            anzahlGeschosseUeberNiveau?: number | null;
            anzahlGeschosseUnterNiveau?: number | null;
            bauflaeche?: number | null;
            baujahr?: number | null;
            bebauteFlaeche?: number | null;
            benuetzungsbewilligung?: string | null;
            einlagezahl?: string | null;
            grundstuecksnummer: string;
            katastralgemeinde?: string | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.ObjektInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }
        | {
            __typename: 'ObjektInfoFeldHausbetreuung';
            objektInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            beauftragungsgrund: string;
            beginn?: string | null;
            ende?: string | null;
            kuendigungsfristDauer?: number | null;
            vertragsdaten?: string | null;
            wertsicherung?: string | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.ObjektInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            contactPerson?: {
              contactPersonId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              lastname: string;
              title?: string | null;
              firstname: string;
              salutation?: { text: string; value: Types.Salutation } | null;
              contactList: Array<{
                contactId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                haupt?: boolean | null;
                tagList: Array<string>;
                type: string;
                wert: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ message: string; type: string }>;
            } | null;
            kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
            kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
            hausbetreuungRechtstraeger?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
          }
        | {
            __typename: 'ObjektInfoFeldPerson';
            objektInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            rolle: string;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.ObjektInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            personRechtstraeger: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
          }
        | {
            __typename: 'ObjektInfoFeldVersicherung';
            objektInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            beginn?: string | null;
            datumSchaetzung?: string | null;
            ende?: string | null;
            erstmalsFaellig?: string | null;
            validFrom?: string | null;
            hauptDueDate?: string | null;
            kuendigungsfristDauer?: number | null;
            neubauwert?: number | null;
            polizze?: string | null;
            versicherungsart: string;
            versicherungssumme?: number | null;
            wertsicherung?: string | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.ObjektInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            contactPerson?: {
              contactPersonId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              lastname: string;
              title?: string | null;
              firstname: string;
              salutation?: { text: string; value: Types.Salutation } | null;
              contactList: Array<{
                contactId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                haupt?: boolean | null;
                tagList: Array<string>;
                type: string;
                wert: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ message: string; type: string }>;
            } | null;
            firma?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              name1: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ message: string; type: string }>;
            } | null;
            kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
            kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
            makler?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              rechtstraegerId: string;
              kurzBezeichnung: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ message: string; type: string }>;
            } | null;
            sachbearbeiter?: {
              contactPersonId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firstname: string;
              lastname: string;
              tagList: Array<string>;
              title?: string | null;
              titleTrailing?: string | null;
              contactList: Array<{
                contactId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                haupt?: boolean | null;
                tagList: Array<string>;
                type: string;
                wert: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              rechtstraeger?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            zahlungsperiode?: { text: string; value: Types.Zahlungsperiode } | null;
          }
        | null;
      priority: { text: string; value: Types.TicketPriority };
      rechtstraeger?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechtstraegerInfoFeld?: {
        __typename: 'RechtstraegerInfoFeldPersoneninfos';
        rechtstraegerInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        geburtsort?: string | null;
        identitaetsnachweis?: string | null;
        kundengruppeList?: Array<string> | null;
        merkmalList: Array<string>;
        staatsbuergerschaft?: string | null;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.RechtstraegerInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      reminder: { amount: number; unit: { text: string; value: Types.PeriodUnit } };
      ticketStatus: { text: string; value: Types.TicketStatus };
      type?: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        dueDateText?: string | null;
        firmendatenId?: string | null;
        isSubTicketTemplate: boolean;
        name: string;
        parentId?: string | null;
        position: number;
        reminderText?: string | null;
        ticketTemplateId: string;
        updatedByMitarbeiterId?: string | null;
        subTicketTemplateList?: Array<{
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          dueDateText?: string | null;
          firmendatenId?: string | null;
          isSubTicketTemplate: boolean;
          name: string;
          parentId?: string | null;
          position: number;
          reminderText?: string | null;
          ticketTemplateId: string;
          updatedByMitarbeiterId?: string | null;
          dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
          priority: { text: string; value: Types.TicketPriority };
          reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }> | null;
        dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
        priority: { text: string; value: Types.TicketPriority };
        reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      vertrag?: {
        bestandseinheitId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        objektId: string;
        vertragId: string;
        vertragsBeginn: string;
        status: { text: string; value: Types.VertragStatus; description?: string | null };
        vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
        vertragspartner: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      vertragInfoFeld?:
        | {
            __typename: 'VertragInfoFeldKuendigungsinfos';
            vertragInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            kuendigungBis: string;
            kuendigungsverzichtBestandgeberBis?: string | null;
            kuendigungsverzichtBestandnehmerBis?: string | null;
            bestandnehmerKuendigungsfrist?: number | null;
            kuendigungJeweilsZum?: string | null;
            kuendigungMoeglichZum?: string | null;
            gerichtlicheKuendigung?: boolean | null;
            kuendigungErfolgtAm?: string | null;
            kuendigungWirksamZum?: string | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.VertragInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            kuendigungDurch?: { text: string; value: Types.KuendigungDurch } | null;
          }
        | {
            __typename: 'VertragInfoFeldWeiterePerson';
            vertragInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            text?: string | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.VertragInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            natuerlichePerson: { rechtstraegerId: string; kurzBezeichnung: string; lastname: string; firstname: string };
          }
        | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type TicketChangeEntryListQueryVariables = Types.Exact<{
  ticketId: Types.Scalars['ID']['input'];
  scope?: Types.InputMaybe<Types.TicketChangeEntryScope>;
}>;

export type TicketChangeEntryListQuery = {
  getTicketChangeEntryList: {
    data: Array<{
      changeType: Types.TicketChangeType;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      newAssigneeKurzBezeichnung?: string | null;
      newAttachmentList?: Array<string> | null;
      newComment?: string | null;
      newDescription?: string | null;
      newDueDate?: string | null;
      newParticipantList?: Array<string> | null;
      newReminderText?: string | null;
      newTitle?: string | null;
      oldAssigneeKurzBezeichnung?: string | null;
      oldAttachmentList?: Array<string> | null;
      oldComment?: string | null;
      oldDescription?: string | null;
      oldDueDate?: string | null;
      oldParticipantList?: Array<string> | null;
      oldReminderText?: string | null;
      oldTitle?: string | null;
      subTicketChangeEntry: boolean;
      ticketId: string;
      updatedByMitarbeiterId?: string | null;
      newEmailList?: Array<{ sender: string; subject: string }> | null;
      newPriority?: { text: string; value: Types.TicketPriority } | null;
      newReminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
      newStatus?: { description?: string | null; text: string; value: Types.TicketStatus } | null;
      oldEmailList?: Array<{ sender: string; subject: string }> | null;
      oldPriority?: { text: string; value: Types.TicketPriority } | null;
      oldReminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
      oldStatus?: { description?: string | null; text: string; value: Types.TicketStatus } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type TicketTypeNameListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TicketTypeNameListQuery = {
  getTicketTypeNameList: {
    data: Array<string>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type SimilarTicketListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type SimilarTicketListQuery = {
  getSimilarTicketList: {
    data: {
      attachmentCount: number;
      commentCount: number;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      description?: string | null;
      dueDate: string;
      emailCount: number;
      isSubTicket: boolean;
      number: number;
      participantCount: number;
      position: number;
      reminderText: string;
      ticketId: string;
      title: string;
      typeName?: string | null;
      updatedByMitarbeiterId?: string | null;
      assignee: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firstname: string;
        lastname: string;
        mitarbeiterId: string;
        updatedByMitarbeiterId?: string | null;
        firmendatenBaseData: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firmendatenId: string;
          kundenSystemId: string;
          kurzBezeichnung: string;
          name1: string;
          name2?: string | null;
          name3?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      beInfoFeld?:
        | {
            __typename: 'BeInfoFeldKellerabteil';
            beInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            text?: string | null;
            kellerabteilNummer: string;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.BeInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }
        | {
            __typename: 'BeInfoFeldParkplatz';
            beInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            text?: string | null;
            parkplatzNummer: string;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.BeInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            bestandseinheit?: { bestandseinheitId: string; bezeichnung: string } | null;
          }
        | {
            __typename: 'BeInfoFeldZimmer';
            beInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            anmerkung?: string | null;
            bezeichnung: string;
            bodenbelag?: string | null;
            breite?: number | null;
            energiekennzahl?: string | null;
            flaechentyp?: string | null;
            heizung?: string | null;
            hoehe?: number | null;
            initialQuadratmeterBetrag?: number | null;
            initialQuadratmeterPreis?: number | null;
            kubikmeter?: number | null;
            laenge?: number | null;
            nutzflaeche?: number | null;
            nutzung?: boolean | null;
            quadratmeter?: number | null;
            quadratmeterBetrag?: number | null;
            quadratmeterPreis?: number | null;
            zimmerNummer?: string | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.BeInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            vorschreibungsposition?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              kurzBezeichnung: string;
              objektVorschreibungspositionId: string;
              vorschreibungspositionId: string;
              art: { text: string; value: Types.VorschreibungspositionArt };
              objektVorschreibungspositionStatus: { text: string; value: Types.ObjektVorschreibungspositionStatus };
              vorschreibungspositionStatus: { text: string; value: Types.VorschreibungspositionStatus };
            } | null;
          }
        | null;
      bestandseinheit?: {
        bestandseinheitId: string;
        bezeichnung: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        objektId: string;
        status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      objekt?: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        objektId: string;
        updatedByMitarbeiterId?: string | null;
        rechnungsAussteller: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        status: { text: string; value: Types.ObjektStatus; description?: string | null };
        verwaltungsart: { text: string; value: Types.Verwaltungsart };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      objektInfoFeld?:
        | {
            __typename: 'ObjektInfoFeldAuflage';
            objektInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            amtlicheQuadratmeterFestsetzung: boolean;
            assanierungsgebiet: boolean;
            bebaut: boolean;
            denkmalschutz: boolean;
            gruenderzeitviertel: boolean;
            naturschutz: boolean;
            schutzzone: boolean;
            vollanwendungMRG: boolean;
            wohnzone: boolean;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.ObjektInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }
        | {
            __typename: 'ObjektInfoFeldGrundstuecksdaten';
            objektInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            anzahlGeschosseUeberNiveau?: number | null;
            anzahlGeschosseUnterNiveau?: number | null;
            bauflaeche?: number | null;
            baujahr?: number | null;
            bebauteFlaeche?: number | null;
            benuetzungsbewilligung?: string | null;
            einlagezahl?: string | null;
            grundstuecksnummer: string;
            katastralgemeinde?: string | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.ObjektInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }
        | {
            __typename: 'ObjektInfoFeldHausbetreuung';
            objektInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            beauftragungsgrund: string;
            beginn?: string | null;
            ende?: string | null;
            kuendigungsfristDauer?: number | null;
            vertragsdaten?: string | null;
            wertsicherung?: string | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.ObjektInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            contactPerson?: {
              contactPersonId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              lastname: string;
              title?: string | null;
              firstname: string;
              salutation?: { text: string; value: Types.Salutation } | null;
              contactList: Array<{
                contactId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                haupt?: boolean | null;
                tagList: Array<string>;
                type: string;
                wert: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ message: string; type: string }>;
            } | null;
            kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
            kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
            hausbetreuungRechtstraeger?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
          }
        | {
            __typename: 'ObjektInfoFeldPerson';
            objektInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            rolle: string;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.ObjektInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            personRechtstraeger: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
          }
        | {
            __typename: 'ObjektInfoFeldVersicherung';
            objektInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            beginn?: string | null;
            datumSchaetzung?: string | null;
            ende?: string | null;
            erstmalsFaellig?: string | null;
            validFrom?: string | null;
            hauptDueDate?: string | null;
            kuendigungsfristDauer?: number | null;
            neubauwert?: number | null;
            polizze?: string | null;
            versicherungsart: string;
            versicherungssumme?: number | null;
            wertsicherung?: string | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.ObjektInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            contactPerson?: {
              contactPersonId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              lastname: string;
              title?: string | null;
              firstname: string;
              salutation?: { text: string; value: Types.Salutation } | null;
              contactList: Array<{
                contactId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                haupt?: boolean | null;
                tagList: Array<string>;
                type: string;
                wert: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ message: string; type: string }>;
            } | null;
            firma?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              name1: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ message: string; type: string }>;
            } | null;
            kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
            kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
            makler?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              rechtstraegerId: string;
              kurzBezeichnung: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ message: string; type: string }>;
            } | null;
            sachbearbeiter?: {
              contactPersonId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firstname: string;
              lastname: string;
              tagList: Array<string>;
              title?: string | null;
              titleTrailing?: string | null;
              contactList: Array<{
                contactId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                haupt?: boolean | null;
                tagList: Array<string>;
                type: string;
                wert: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              rechtstraeger?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            zahlungsperiode?: { text: string; value: Types.Zahlungsperiode } | null;
          }
        | null;
      priority: { text: string; value: Types.TicketPriority };
      rechtstraeger?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechtstraegerInfoFeld?: {
        __typename: 'RechtstraegerInfoFeldPersoneninfos';
        rechtstraegerInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        geburtsort?: string | null;
        identitaetsnachweis?: string | null;
        kundengruppeList?: Array<string> | null;
        merkmalList: Array<string>;
        staatsbuergerschaft?: string | null;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.RechtstraegerInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      reminder: { amount: number; unit: { text: string; value: Types.PeriodUnit } };
      ticketStatus: { text: string; value: Types.TicketStatus };
      type?: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        dueDateText?: string | null;
        firmendatenId?: string | null;
        isSubTicketTemplate: boolean;
        name: string;
        parentId?: string | null;
        position: number;
        reminderText?: string | null;
        ticketTemplateId: string;
        updatedByMitarbeiterId?: string | null;
        subTicketTemplateList?: Array<{
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          dueDateText?: string | null;
          firmendatenId?: string | null;
          isSubTicketTemplate: boolean;
          name: string;
          parentId?: string | null;
          position: number;
          reminderText?: string | null;
          ticketTemplateId: string;
          updatedByMitarbeiterId?: string | null;
          dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
          priority: { text: string; value: Types.TicketPriority };
          reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }> | null;
        dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
        priority: { text: string; value: Types.TicketPriority };
        reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      vertrag?: {
        bestandseinheitId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        objektId: string;
        vertragId: string;
        vertragsBeginn: string;
        status: { text: string; value: Types.VertragStatus; description?: string | null };
        vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
        vertragspartner: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      vertragInfoFeld?:
        | {
            __typename: 'VertragInfoFeldKuendigungsinfos';
            vertragInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            kuendigungBis: string;
            kuendigungsverzichtBestandgeberBis?: string | null;
            kuendigungsverzichtBestandnehmerBis?: string | null;
            bestandnehmerKuendigungsfrist?: number | null;
            kuendigungJeweilsZum?: string | null;
            kuendigungMoeglichZum?: string | null;
            gerichtlicheKuendigung?: boolean | null;
            kuendigungErfolgtAm?: string | null;
            kuendigungWirksamZum?: string | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.VertragInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            kuendigungDurch?: { text: string; value: Types.KuendigungDurch } | null;
          }
        | {
            __typename: 'VertragInfoFeldWeiterePerson';
            vertragInfoFeldId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fileIdList: Array<string>;
            text?: string | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.InfoFeldStatus };
            type: { text: string; value: Types.VertragInfoFeldType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            natuerlichePerson: { rechtstraegerId: string; kurzBezeichnung: string; lastname: string; firstname: string };
          }
        | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type TicketStatusListQueryVariables = Types.Exact<{
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type TicketStatusListQuery = {
  getTicketStatusList: {
    data: Array<{ description?: string | null; text: string; value: Types.TicketStatus }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type TicketPriorityListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TicketPriorityListQuery = {
  getTicketPriorityList: {
    data: Array<{ text: string; value: Types.TicketPriority }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type TicketCommentListQueryVariables = Types.Exact<{
  ticketId: Types.Scalars['ID']['input'];
}>;

export type TicketCommentListQuery = {
  getTicketCommentList: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      text: string;
      ticketCommentId: string;
      updatedByMitarbeiterId?: string | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const TicketListDocument = gql`
  query TicketList(
    $objektId: ID
    $bestandseinheitId: ID
    $vertragId: ID
    $rechtstraegerId: ID
    $objektInfoFeldId: ID
    $beInfoFeldId: ID
    $vertragInfoFeldId: ID
    $rechtstraegerInfoFeldId: ID
    $priority: [TicketPriority!]
    $status: [TicketStatus!]
    $title: String
    $assigneeMitarbeiterId: [String!]
    $participantId: [String!]
    $dueDateFrom: String
    $dueDateTo: String
    $searchInSubTickets: Boolean
    $typeName: [String!]
    $order: TicketOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getTicketList(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      rechtstraegerId: $rechtstraegerId
      objektInfoFeldId: $objektInfoFeldId
      beInfoFeldId: $beInfoFeldId
      vertragInfoFeldId: $vertragInfoFeldId
      rechtstraegerInfoFeldId: $rechtstraegerInfoFeldId
      priority: $priority
      status: $status
      title: $title
      dueDateFrom: $dueDateFrom
      dueDateTo: $dueDateTo
      assigneeMitarbeiterId: $assigneeMitarbeiterId
      participantId: $participantId
      searchInSubTickets: $searchInSubTickets
      typeName: $typeName
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          ...TicketBaseFields
          parent {
            ...TicketBaseFields
          }
        }
        page {
          totalPages
          totalElements
          number
          size
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${TicketBaseFieldsFragmentDoc}
`;
export function useTicketListQuery(baseOptions?: Apollo.QueryHookOptions<TicketListQuery, TicketListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketListQuery, TicketListQueryVariables>(TicketListDocument, options);
}
export function useTicketListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketListQuery, TicketListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketListQuery, TicketListQueryVariables>(TicketListDocument, options);
}
export function useTicketListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TicketListQuery, TicketListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TicketListQuery, TicketListQueryVariables>(TicketListDocument, options);
}
export type TicketListQueryHookResult = ReturnType<typeof useTicketListQuery>;
export type TicketListLazyQueryHookResult = ReturnType<typeof useTicketListLazyQuery>;
export type TicketListSuspenseQueryHookResult = ReturnType<typeof useTicketListSuspenseQuery>;
export type TicketListQueryResult = Apollo.QueryResult<TicketListQuery, TicketListQueryVariables>;
export const TicketDocument = gql`
  query Ticket(
    $ticketId: ID!
    $includeAttachmentsFromSubTickets: Boolean
    $includeParticipantsFromSubTickets: Boolean
    $includeEmailsFromSubTickets: Boolean
  ) {
    getTicket(
      ticketId: $ticketId
      includeAttachmentsFromSubTickets: $includeAttachmentsFromSubTickets
      includeParticipantsFromSubTickets: $includeParticipantsFromSubTickets
      includeEmailsFromSubTickets: $includeEmailsFromSubTickets
    ) {
      data {
        ...TicketFields
        parent {
          ...TicketBaseFields
        }
        subTicketList {
          ...TicketBaseFields
          parent {
            ...TicketBaseFields
          }
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${TicketFieldsFragmentDoc}
  ${TicketBaseFieldsFragmentDoc}
`;
export function useTicketQuery(
  baseOptions: Apollo.QueryHookOptions<TicketQuery, TicketQueryVariables> & ({ variables: TicketQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketQuery, TicketQueryVariables>(TicketDocument, options);
}
export function useTicketLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketQuery, TicketQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketQuery, TicketQueryVariables>(TicketDocument, options);
}
export function useTicketSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TicketQuery, TicketQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TicketQuery, TicketQueryVariables>(TicketDocument, options);
}
export type TicketQueryHookResult = ReturnType<typeof useTicketQuery>;
export type TicketLazyQueryHookResult = ReturnType<typeof useTicketLazyQuery>;
export type TicketSuspenseQueryHookResult = ReturnType<typeof useTicketSuspenseQuery>;
export type TicketQueryResult = Apollo.QueryResult<TicketQuery, TicketQueryVariables>;
export const MyTicketListDocument = gql`
  query MyTicketList {
    getMyTicketList {
      data {
        ...TicketBaseFields
        parent {
          ...TicketBaseFields
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${TicketBaseFieldsFragmentDoc}
`;
export function useMyTicketListQuery(baseOptions?: Apollo.QueryHookOptions<MyTicketListQuery, MyTicketListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyTicketListQuery, MyTicketListQueryVariables>(MyTicketListDocument, options);
}
export function useMyTicketListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyTicketListQuery, MyTicketListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyTicketListQuery, MyTicketListQueryVariables>(MyTicketListDocument, options);
}
export function useMyTicketListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MyTicketListQuery, MyTicketListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MyTicketListQuery, MyTicketListQueryVariables>(MyTicketListDocument, options);
}
export type MyTicketListQueryHookResult = ReturnType<typeof useMyTicketListQuery>;
export type MyTicketListLazyQueryHookResult = ReturnType<typeof useMyTicketListLazyQuery>;
export type MyTicketListSuspenseQueryHookResult = ReturnType<typeof useMyTicketListSuspenseQuery>;
export type MyTicketListQueryResult = Apollo.QueryResult<MyTicketListQuery, MyTicketListQueryVariables>;
export const TicketChangeEntryListDocument = gql`
  query TicketChangeEntryList($ticketId: ID!, $scope: TicketChangeEntryScope) {
    getTicketChangeEntryList(ticketId: $ticketId, scope: $scope) {
      data {
        ...TicketChangeEntryFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${TicketChangeEntryFieldsFragmentDoc}
`;
export function useTicketChangeEntryListQuery(
  baseOptions: Apollo.QueryHookOptions<TicketChangeEntryListQuery, TicketChangeEntryListQueryVariables> &
    ({ variables: TicketChangeEntryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketChangeEntryListQuery, TicketChangeEntryListQueryVariables>(TicketChangeEntryListDocument, options);
}
export function useTicketChangeEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TicketChangeEntryListQuery, TicketChangeEntryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketChangeEntryListQuery, TicketChangeEntryListQueryVariables>(TicketChangeEntryListDocument, options);
}
export function useTicketChangeEntryListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TicketChangeEntryListQuery, TicketChangeEntryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TicketChangeEntryListQuery, TicketChangeEntryListQueryVariables>(TicketChangeEntryListDocument, options);
}
export type TicketChangeEntryListQueryHookResult = ReturnType<typeof useTicketChangeEntryListQuery>;
export type TicketChangeEntryListLazyQueryHookResult = ReturnType<typeof useTicketChangeEntryListLazyQuery>;
export type TicketChangeEntryListSuspenseQueryHookResult = ReturnType<typeof useTicketChangeEntryListSuspenseQuery>;
export type TicketChangeEntryListQueryResult = Apollo.QueryResult<TicketChangeEntryListQuery, TicketChangeEntryListQueryVariables>;
export const TicketTypeNameListDocument = gql`
  query TicketTypeNameList {
    getTicketTypeNameList {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useTicketTypeNameListQuery(baseOptions?: Apollo.QueryHookOptions<TicketTypeNameListQuery, TicketTypeNameListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketTypeNameListQuery, TicketTypeNameListQueryVariables>(TicketTypeNameListDocument, options);
}
export function useTicketTypeNameListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketTypeNameListQuery, TicketTypeNameListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketTypeNameListQuery, TicketTypeNameListQueryVariables>(TicketTypeNameListDocument, options);
}
export function useTicketTypeNameListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TicketTypeNameListQuery, TicketTypeNameListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TicketTypeNameListQuery, TicketTypeNameListQueryVariables>(TicketTypeNameListDocument, options);
}
export type TicketTypeNameListQueryHookResult = ReturnType<typeof useTicketTypeNameListQuery>;
export type TicketTypeNameListLazyQueryHookResult = ReturnType<typeof useTicketTypeNameListLazyQuery>;
export type TicketTypeNameListSuspenseQueryHookResult = ReturnType<typeof useTicketTypeNameListSuspenseQuery>;
export type TicketTypeNameListQueryResult = Apollo.QueryResult<TicketTypeNameListQuery, TicketTypeNameListQueryVariables>;
export const SimilarTicketListDocument = gql`
  query SimilarTicketList {
    getSimilarTicketList {
      data {
        ...TicketBaseFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${TicketBaseFieldsFragmentDoc}
`;
export function useSimilarTicketListQuery(baseOptions?: Apollo.QueryHookOptions<SimilarTicketListQuery, SimilarTicketListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SimilarTicketListQuery, SimilarTicketListQueryVariables>(SimilarTicketListDocument, options);
}
export function useSimilarTicketListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimilarTicketListQuery, SimilarTicketListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SimilarTicketListQuery, SimilarTicketListQueryVariables>(SimilarTicketListDocument, options);
}
export function useSimilarTicketListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SimilarTicketListQuery, SimilarTicketListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SimilarTicketListQuery, SimilarTicketListQueryVariables>(SimilarTicketListDocument, options);
}
export type SimilarTicketListQueryHookResult = ReturnType<typeof useSimilarTicketListQuery>;
export type SimilarTicketListLazyQueryHookResult = ReturnType<typeof useSimilarTicketListLazyQuery>;
export type SimilarTicketListSuspenseQueryHookResult = ReturnType<typeof useSimilarTicketListSuspenseQuery>;
export type SimilarTicketListQueryResult = Apollo.QueryResult<SimilarTicketListQuery, SimilarTicketListQueryVariables>;
export const TicketStatusListDocument = gql`
  query TicketStatusList($includeArchiviert: Boolean) {
    getTicketStatusList(includeArchiviert: $includeArchiviert) {
      data {
        description
        text
        value
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useTicketStatusListQuery(baseOptions?: Apollo.QueryHookOptions<TicketStatusListQuery, TicketStatusListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketStatusListQuery, TicketStatusListQueryVariables>(TicketStatusListDocument, options);
}
export function useTicketStatusListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketStatusListQuery, TicketStatusListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketStatusListQuery, TicketStatusListQueryVariables>(TicketStatusListDocument, options);
}
export function useTicketStatusListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TicketStatusListQuery, TicketStatusListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TicketStatusListQuery, TicketStatusListQueryVariables>(TicketStatusListDocument, options);
}
export type TicketStatusListQueryHookResult = ReturnType<typeof useTicketStatusListQuery>;
export type TicketStatusListLazyQueryHookResult = ReturnType<typeof useTicketStatusListLazyQuery>;
export type TicketStatusListSuspenseQueryHookResult = ReturnType<typeof useTicketStatusListSuspenseQuery>;
export type TicketStatusListQueryResult = Apollo.QueryResult<TicketStatusListQuery, TicketStatusListQueryVariables>;
export const TicketPriorityListDocument = gql`
  query TicketPriorityList {
    getTicketPriorityList {
      data {
        text
        value
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useTicketPriorityListQuery(baseOptions?: Apollo.QueryHookOptions<TicketPriorityListQuery, TicketPriorityListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketPriorityListQuery, TicketPriorityListQueryVariables>(TicketPriorityListDocument, options);
}
export function useTicketPriorityListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketPriorityListQuery, TicketPriorityListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketPriorityListQuery, TicketPriorityListQueryVariables>(TicketPriorityListDocument, options);
}
export function useTicketPriorityListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TicketPriorityListQuery, TicketPriorityListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TicketPriorityListQuery, TicketPriorityListQueryVariables>(TicketPriorityListDocument, options);
}
export type TicketPriorityListQueryHookResult = ReturnType<typeof useTicketPriorityListQuery>;
export type TicketPriorityListLazyQueryHookResult = ReturnType<typeof useTicketPriorityListLazyQuery>;
export type TicketPriorityListSuspenseQueryHookResult = ReturnType<typeof useTicketPriorityListSuspenseQuery>;
export type TicketPriorityListQueryResult = Apollo.QueryResult<TicketPriorityListQuery, TicketPriorityListQueryVariables>;
export const TicketCommentListDocument = gql`
  query TicketCommentList($ticketId: ID!) {
    getTicketCommentList(ticketId: $ticketId) {
      data {
        ...TicketCommentFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${TicketCommentFieldsFragmentDoc}
`;
export function useTicketCommentListQuery(
  baseOptions: Apollo.QueryHookOptions<TicketCommentListQuery, TicketCommentListQueryVariables> &
    ({ variables: TicketCommentListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketCommentListQuery, TicketCommentListQueryVariables>(TicketCommentListDocument, options);
}
export function useTicketCommentListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketCommentListQuery, TicketCommentListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketCommentListQuery, TicketCommentListQueryVariables>(TicketCommentListDocument, options);
}
export function useTicketCommentListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TicketCommentListQuery, TicketCommentListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TicketCommentListQuery, TicketCommentListQueryVariables>(TicketCommentListDocument, options);
}
export type TicketCommentListQueryHookResult = ReturnType<typeof useTicketCommentListQuery>;
export type TicketCommentListLazyQueryHookResult = ReturnType<typeof useTicketCommentListLazyQuery>;
export type TicketCommentListSuspenseQueryHookResult = ReturnType<typeof useTicketCommentListSuspenseQuery>;
export type TicketCommentListQueryResult = Apollo.QueryResult<TicketCommentListQuery, TicketCommentListQueryVariables>;
