import React, { FC } from 'react';
import { Button, Divider, Drawer, Dropdown, Modal, Space, Tag, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, EllipsisOutlined, HistoryOutlined, LockOutlined, UnlockOutlined, UserOutlined } from '@ant-design/icons';
import { MutationFunctionOptions } from '@apollo/client';
import { ArchiveIcon } from '@radix-ui/react-icons';
import { isStatusArchived, isStatusGesperrt } from '../../../../helpers/statusHelper';
import NatuerlichePersonForm from '../Form/NatuerlichePersonForm';
import { useToggle } from '../../../../hooks/useToggle';
import CardWithFooter from '../../../../components/Card';
import RechtstraegerBankDetails from '../../../BankDetails/RechtstraegerBankDetails/RechtstraegerBankDetails';
import { useGoBack } from '../../../../shared/GoBack/GoBackContext';
import {
  showSuccessMsgArchived,
  showSuccessMsgDelete,
  showSuccessMsgEntsperrt,
  showSuccessMsgGesperrt,
  showSuccessMsgUnarchived,
} from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import {
  ArchiveNatuerlichePersonMutation,
  ArchiveNatuerlichePersonMutationVariables,
  DeleteNatuerlichePersonMutation,
  DeleteNatuerlichePersonMutationVariables,
  EntsperrenNatuerlichePersonMutation,
  EntsperrenNatuerlichePersonMutationVariables,
  SperrenNatuerlichePersonMutation,
  SperrenNatuerlichePersonMutationVariables,
  UnarchiveNatuerlichePersonMutation,
  UnarchiveNatuerlichePersonMutationVariables,
  useArchiveNatuerlichePersonMutation,
  useDeleteNatuerlichePersonMutation,
  useEntsperrenNatuerlichePersonMutation,
  useSperrenNatuerlichePersonMutation,
  useUnarchiveNatuerlichePersonMutation,
} from '../gql/NatuerlichePersonMutations.types';
import { URI_RECHTSTRAEGER_PAGE } from '../../rechtstraegerUriPaths';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import FinanzSteuerparameterInfo from '../../Steuer/FinanzSteuerparameterInfo';
import CardTitle from '../../../../components/Card/CardTitle';
import { radixActionStyles } from '../../../../helpers/radixIconsStyles';
import RechtstraegerPostIt from '../../PostIt/RechtstraegerPostIt';
import HistoryModal, { HistoryType } from '../../../History/shared/HistoryModal';
import NatuerlichePerseonChangeHistoryListingTable from './NatuerlichePersonChangeHistoryListingTable';
import { NatuerlichePerson } from '../../../../types';

type Props = {
  natuerlichePerson: NatuerlichePerson;
  kundenSystemId: string;
  refetch: () => Promise<unknown>;
  isLoading: boolean;
  firmendatenId: string;
  rechtstraegerId: string;
};

const NatuerlichePersonCard: FC<Props> = ({ natuerlichePerson, kundenSystemId, refetch, isLoading, rechtstraegerId }) => {
  const [isCollapsed, onCollapse] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();
  const goBack = useGoBack(URI_RECHTSTRAEGER_PAGE);
  const entity = `Natürliche Person`;

  const [runDelete] = useDeleteNatuerlichePersonMutation({
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      goBack();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runSperren] = useSperrenNatuerlichePersonMutation({
    onCompleted: () => {
      showSuccessMsgGesperrt(entity);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runEntsperren] = useEntsperrenNatuerlichePersonMutation({
    onCompleted: () => {
      showSuccessMsgEntsperrt(entity);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runArchive] = useArchiveNatuerlichePersonMutation({
    onCompleted: () => {
      showSuccessMsgArchived(entity);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUnarchive] = useUnarchiveNatuerlichePersonMutation({
    onCompleted: () => {
      showSuccessMsgUnarchived(entity);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <CardWithFooter
      loading={isLoading}
      style={{ minWidth: '320px' }}
      title={
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <CardTitle title={natuerlichePerson.kurzBezeichnung} status={natuerlichePerson.status} warningList={natuerlichePerson.warningList} />
          <RechtstraegerPostIt rechtstraegerId={rechtstraegerId} postIt={natuerlichePerson.postIt} refresh={refetch} />
        </Space>
      }
      extra={
        <Dropdown
          menu={{
            items: menuItems(natuerlichePerson, onCollapse, toggleChangeHistoryOpen, runSperren, runEntsperren, runDelete, runArchive, runUnarchive),
          }}
        >
          <Button data-testid="card-menu-dropdown" icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
        </Dropdown>
      }
      createTs={natuerlichePerson.createTs}
      userId={natuerlichePerson.createdBy}
      mitarbeiterId={natuerlichePerson.createdByMitarbeiterId}
    >
      <CardDetailsInfoRow
        infoRowTitle={
          <Space>
            <UserOutlined />
            {natuerlichePerson.salutation?.text ?? 'keine Anrede'}
          </Space>
        }
      >
        <Typography.Text>{natuerlichePerson.kurzBezeichnung}</Typography.Text>
      </CardDetailsInfoRow>
      <Divider dashed />
      <Typography.Title level={5}>Merkmale</Typography.Title>
      {natuerlichePerson.tagList.map((tag) => (
        <Tag key={tag}>{tag}</Tag>
      ))}
      <Divider dashed />
      <FinanzSteuerparameterInfo
        rechtstraegerSteuerpflicht={natuerlichePerson.rechtstraegerSteuerpflicht}
        rechtstraegerId={rechtstraegerId}
        reloadRechtstraeger={refetch}
      />
      <Divider dashed />
      <Typography.Title level={5}>Information</Typography.Title>
      <CardDetailsInfoRow infoRowTitle="Kundennummer">
        <Typography.Text>{natuerlichePerson.kundennummer}</Typography.Text>
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Sprache">
        <Typography.Text>{natuerlichePerson.sprache.text}</Typography.Text>
      </CardDetailsInfoRow>
      <Divider dashed />
      <RechtstraegerBankDetails rechtstraegerId={rechtstraegerId} fibuKontoCreatable={natuerlichePerson.fibuKontoCreatable} />
      <Drawer title="Natürliche Person bearbeiten" width={720} onClose={onCollapse} open={isCollapsed} destroyOnClose>
        <NatuerlichePersonForm
          natuerlichePerson={natuerlichePerson}
          kundenSystemId={kundenSystemId}
          onSuccess={() => {
            refetch();
            onCollapse();
          }}
          onCancel={onCollapse}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <NatuerlichePerseonChangeHistoryListingTable natuerlichePersonId={natuerlichePerson.rechtstraegerId} />
      </HistoryModal>
    </CardWithFooter>
  );
};

const menuItems = (
  natuerlichePerson: NatuerlichePerson,
  onCollapse: () => void,
  toggleChangeHistoryOpen: () => void,
  runSperren: (options?: MutationFunctionOptions<SperrenNatuerlichePersonMutation, SperrenNatuerlichePersonMutationVariables>) => Promise<any>,
  runEntsperren: (
    options?: MutationFunctionOptions<EntsperrenNatuerlichePersonMutation, EntsperrenNatuerlichePersonMutationVariables>
  ) => Promise<any>,
  runDelete: (options?: MutationFunctionOptions<DeleteNatuerlichePersonMutation, DeleteNatuerlichePersonMutationVariables>) => Promise<any>,
  runArchive: (options?: MutationFunctionOptions<ArchiveNatuerlichePersonMutation, ArchiveNatuerlichePersonMutationVariables>) => Promise<any>,
  runUnarchive: (options?: MutationFunctionOptions<UnarchiveNatuerlichePersonMutation, UnarchiveNatuerlichePersonMutationVariables>) => Promise<any>
) => [
  {
    key: '1',
    label: 'Bearbeiten',
    onClick: onCollapse,
    disabled: isStatusGesperrt(natuerlichePerson.status),
    icon: <EditOutlined />,
  },
  {
    key: '2',
    label: 'Änderungsverlauf',
    onClick: toggleChangeHistoryOpen,
    icon: <HistoryOutlined style={radixActionStyles} />,
  },
  {
    key: '3',
    label: isStatusArchived(natuerlichePerson.status) ? 'Reaktivieren' : 'Archivieren',
    onClick: () => showConfirmArchive(natuerlichePerson, runArchive, runUnarchive),
    disabled: !!natuerlichePerson.deletable,
    icon: <ArchiveIcon style={radixActionStyles} />,
  },
  {
    key: '4',
    label: isStatusGesperrt(natuerlichePerson.status) ? 'Entsperren' : 'Sperren',
    onClick: () => showConfirmLock(natuerlichePerson, runSperren, runEntsperren),
    disabled: !!natuerlichePerson.deletable,
    icon: isStatusGesperrt(natuerlichePerson.status) ? <UnlockOutlined /> : <LockOutlined />,
  },
  {
    key: '5',
    label: 'Löschen',
    onClick: () => showConfirmDelete(natuerlichePerson, runDelete),
    disabled: !natuerlichePerson.deletable,
    icon: <DeleteOutlined />,
  },
];

const showConfirmArchive = (
  natuerlichePerson: NatuerlichePerson,
  runArchive: (options?: MutationFunctionOptions<ArchiveNatuerlichePersonMutation, ArchiveNatuerlichePersonMutationVariables>) => Promise<any>,
  runUnarchive: (options?: MutationFunctionOptions<UnarchiveNatuerlichePersonMutation, UnarchiveNatuerlichePersonMutationVariables>) => Promise<any>
) => {
  Modal.confirm({
    title: isStatusArchived(natuerlichePerson.status)
      ? 'Möchten Sie die Natürliche Person reaktivieren?'
      : 'Möchten Sie die Natürliche Person archivieren?',
    content: `${natuerlichePerson.kurzBezeichnung}`,
    okText: isStatusArchived(natuerlichePerson.status) ? 'Reaktivieren' : 'Archivieren',
    cancelText: 'Nein',
    onOk() {
      if (isStatusArchived(natuerlichePerson.status)) {
        return runUnarchive({ variables: { rechtstraegerId: natuerlichePerson.rechtstraegerId } });
      } else {
        return runArchive({ variables: { rechtstraegerId: natuerlichePerson.rechtstraegerId } });
      }
    },
  });
};

const showConfirmLock = (
  natuerlichePerson: NatuerlichePerson,
  runSperren: (options?: MutationFunctionOptions<SperrenNatuerlichePersonMutation, SperrenNatuerlichePersonMutationVariables>) => Promise<any>,
  runEntsperren: (
    options?: MutationFunctionOptions<EntsperrenNatuerlichePersonMutation, EntsperrenNatuerlichePersonMutationVariables>
  ) => Promise<any>
) => {
  Modal.confirm({
    title: isStatusGesperrt(natuerlichePerson.status)
      ? 'Möchten Sie die Natürliche Person entsperren?'
      : 'Möchten Sie die Natürliche Person sperren?',
    content: `${natuerlichePerson.kurzBezeichnung}`,
    okText: isStatusGesperrt(natuerlichePerson.status) ? 'Entsperren' : 'Sperren',
    cancelText: 'Nein',
    onOk() {
      if (isStatusGesperrt(natuerlichePerson.status)) {
        return runEntsperren({ variables: { rechtstraegerId: natuerlichePerson.rechtstraegerId } });
      } else {
        return runSperren({ variables: { rechtstraegerId: natuerlichePerson.rechtstraegerId } });
      }
    },
  });
};

const showConfirmDelete = (
  natuerlichePerson: NatuerlichePerson,
  runDelete: (options?: MutationFunctionOptions<DeleteNatuerlichePersonMutation, DeleteNatuerlichePersonMutationVariables>) => Promise<any>
) => {
  Modal.confirm({
    title: 'Möchten Sie die Natürliche Person löschen?',
    content: `${natuerlichePerson.kurzBezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete({ variables: { rechtstraegerId: natuerlichePerson.rechtstraegerId } });
    },
  });
};

export default NatuerlichePersonCard;
