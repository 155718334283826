import React, { FC } from 'react';
import { Empty } from 'antd';
import topAbrAuflistungTableColumns from './topAbrVerarbeitungEntryTableColumns';
import GenerierlaufStatusExpandedRow from '../../shared/GenerierlaufStatusExpandedRow';
import { isVerarbeitungEntryTableRowExpandable } from '../../verarbeitungHelpers';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { TopAbrechnungGenerierlauf, TopAbrechnungGenerierlaufEntry } from '../../../../types';
import { TTopAbrVerarbeitungQueryParams } from './Filters/filtersQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../hooks/useQueryWithPagingAndSorting';
import { useTopAbrechnungGenerierlaufEntryListQuery } from '../../gql/TopAbrechnungGenerierlauf/TopAbrGenerierlaufQueries.types';

type Props = {
  generierlauf: TopAbrechnungGenerierlauf;
  queryParams: TTopAbrVerarbeitungQueryParams;
  type: string;
};

const TopAbrVerarbeitungEntryTable: FC<Props> = ({ queryParams, generierlauf, type }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useTopAbrechnungGenerierlaufEntryListQuery,
    {
      variables: {
        generierlaufId: generierlauf.generierlaufId,
        ...queryParams,
      },
      fetchPolicy: 'no-cache',
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const entryList = data?.getTopAbrechnungGenerierlaufEntryList.data.contentList || [];

  return (
    <>
      <TableWithColSelector<TopAbrechnungGenerierlaufEntry>
        loading={loading}
        dataSource={entryList}
        pagination={pagination}
        onChange={handleTableSorting}
        columns={topAbrAuflistungTableColumns}
        locale={{
          emptyText: <Empty description={<span>Keine Topabrechnungen vorhanden</span>} />,
        }}
        size="small"
        expandable={{
          expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
          rowExpandable: isVerarbeitungEntryTableRowExpandable,
        }}
        rowKey={(record) => `${record.objektAbrechnungId} - ${record.topAbrechnung?.topAbrechnungId}`}
        filterIdentifier={`verarbeitung-${type}`}
      />
    </>
  );
};

export default TopAbrVerarbeitungEntryTable;
