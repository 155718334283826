import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { HistoryListFieldsFragmentDoc } from '../../../History/gql/HistoryFragments.types';
import { ObjektBankDetailsFieldsFragmentDoc } from '../../../BankDetails/gql/BankDetailsFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ObjektBankDetailsChangeHistoryListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bankDetailsId: Types.Scalars['ID']['input'];
}>;

export type ObjektBankDetailsChangeHistoryListQuery = {
  getObjektBankDetailsChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type ObjektBankDetailsListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
}>;

export type ObjektBankDetailsListQuery = {
  getObjektBankDetailsList: {
    data: {
      objektBankDetailsList: Array<{
        bezeichnung: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        objektBankDetailsId: string;
        updatedByMitarbeiterId?: string | null;
        bankDetails: {
          accountHolder: string;
          accountLimit?: number | null;
          assignedHeVertragZahlungsregelMap: any;
          assignedObjektMap: any;
          bankDetailsId: string;
          bankname: string;
          bic: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          ebicsCreditTransferUserList: Array<string>;
          ebicsDirectDebitUserList: Array<string>;
          fibuKontoId?: string | null;
          fibuKontonummer?: string | null;
          iban: string;
          updatedByMitarbeiterId?: string | null;
          fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
          fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
          sepaMandat?: {
            fileId?: string | null;
            fileName?: string | null;
            sepaMandatId: string;
            sepaMandatReference: string;
            signatureDate?: string | null;
            firmendaten?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firmendatenId: string;
              kundenSystemId: string;
              kurzBezeichnung: string;
              name1: string;
              name2?: string | null;
              name3?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechnungsAussteller?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
            warningList: Array<{ message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        verwendungList: Array<{ description?: string | null; text: string; value: Types.ObjektBankDetailsVerwendung }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ObjektBankDetailsVerwendungListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ObjektBankDetailsVerwendungListQuery = {
  getObjektBankDetailsVerwendungList: {
    data: Array<{ description?: string | null; text: string; value: Types.ObjektBankDetailsVerwendung }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const ObjektBankDetailsChangeHistoryListDocument = gql`
  query ObjektBankDetailsChangeHistoryList($objektId: ID!, $bankDetailsId: ID!) {
    getObjektBankDetailsChangeHistoryList(objektId: $objektId, bankDetailsId: $bankDetailsId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useObjektBankDetailsChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<ObjektBankDetailsChangeHistoryListQuery, ObjektBankDetailsChangeHistoryListQueryVariables> &
    ({ variables: ObjektBankDetailsChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ObjektBankDetailsChangeHistoryListQuery, ObjektBankDetailsChangeHistoryListQueryVariables>(
    ObjektBankDetailsChangeHistoryListDocument,
    options
  );
}
export function useObjektBankDetailsChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ObjektBankDetailsChangeHistoryListQuery, ObjektBankDetailsChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ObjektBankDetailsChangeHistoryListQuery, ObjektBankDetailsChangeHistoryListQueryVariables>(
    ObjektBankDetailsChangeHistoryListDocument,
    options
  );
}
export function useObjektBankDetailsChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ObjektBankDetailsChangeHistoryListQuery, ObjektBankDetailsChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ObjektBankDetailsChangeHistoryListQuery, ObjektBankDetailsChangeHistoryListQueryVariables>(
    ObjektBankDetailsChangeHistoryListDocument,
    options
  );
}
export type ObjektBankDetailsChangeHistoryListQueryHookResult = ReturnType<typeof useObjektBankDetailsChangeHistoryListQuery>;
export type ObjektBankDetailsChangeHistoryListLazyQueryHookResult = ReturnType<typeof useObjektBankDetailsChangeHistoryListLazyQuery>;
export type ObjektBankDetailsChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useObjektBankDetailsChangeHistoryListSuspenseQuery>;
export type ObjektBankDetailsChangeHistoryListQueryResult = Apollo.QueryResult<
  ObjektBankDetailsChangeHistoryListQuery,
  ObjektBankDetailsChangeHistoryListQueryVariables
>;
export const ObjektBankDetailsListDocument = gql`
  query ObjektBankDetailsList($objektId: ID!) {
    getObjektBankDetailsList(objektId: $objektId) {
      data {
        objektBankDetailsList {
          ...ObjektBankDetailsFields
        }
        warningList {
          message
          type
          attribute
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${ObjektBankDetailsFieldsFragmentDoc}
`;
export function useObjektBankDetailsListQuery(
  baseOptions: Apollo.QueryHookOptions<ObjektBankDetailsListQuery, ObjektBankDetailsListQueryVariables> &
    ({ variables: ObjektBankDetailsListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ObjektBankDetailsListQuery, ObjektBankDetailsListQueryVariables>(ObjektBankDetailsListDocument, options);
}
export function useObjektBankDetailsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ObjektBankDetailsListQuery, ObjektBankDetailsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ObjektBankDetailsListQuery, ObjektBankDetailsListQueryVariables>(ObjektBankDetailsListDocument, options);
}
export function useObjektBankDetailsListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ObjektBankDetailsListQuery, ObjektBankDetailsListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ObjektBankDetailsListQuery, ObjektBankDetailsListQueryVariables>(ObjektBankDetailsListDocument, options);
}
export type ObjektBankDetailsListQueryHookResult = ReturnType<typeof useObjektBankDetailsListQuery>;
export type ObjektBankDetailsListLazyQueryHookResult = ReturnType<typeof useObjektBankDetailsListLazyQuery>;
export type ObjektBankDetailsListSuspenseQueryHookResult = ReturnType<typeof useObjektBankDetailsListSuspenseQuery>;
export type ObjektBankDetailsListQueryResult = Apollo.QueryResult<ObjektBankDetailsListQuery, ObjektBankDetailsListQueryVariables>;
export const ObjektBankDetailsVerwendungListDocument = gql`
  query ObjektBankDetailsVerwendungList {
    getObjektBankDetailsVerwendungList {
      data {
        description
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useObjektBankDetailsVerwendungListQuery(
  baseOptions?: Apollo.QueryHookOptions<ObjektBankDetailsVerwendungListQuery, ObjektBankDetailsVerwendungListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ObjektBankDetailsVerwendungListQuery, ObjektBankDetailsVerwendungListQueryVariables>(
    ObjektBankDetailsVerwendungListDocument,
    options
  );
}
export function useObjektBankDetailsVerwendungListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ObjektBankDetailsVerwendungListQuery, ObjektBankDetailsVerwendungListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ObjektBankDetailsVerwendungListQuery, ObjektBankDetailsVerwendungListQueryVariables>(
    ObjektBankDetailsVerwendungListDocument,
    options
  );
}
export function useObjektBankDetailsVerwendungListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ObjektBankDetailsVerwendungListQuery, ObjektBankDetailsVerwendungListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ObjektBankDetailsVerwendungListQuery, ObjektBankDetailsVerwendungListQueryVariables>(
    ObjektBankDetailsVerwendungListDocument,
    options
  );
}
export type ObjektBankDetailsVerwendungListQueryHookResult = ReturnType<typeof useObjektBankDetailsVerwendungListQuery>;
export type ObjektBankDetailsVerwendungListLazyQueryHookResult = ReturnType<typeof useObjektBankDetailsVerwendungListLazyQuery>;
export type ObjektBankDetailsVerwendungListSuspenseQueryHookResult = ReturnType<typeof useObjektBankDetailsVerwendungListSuspenseQuery>;
export type ObjektBankDetailsVerwendungListQueryResult = Apollo.QueryResult<
  ObjektBankDetailsVerwendungListQuery,
  ObjektBankDetailsVerwendungListQueryVariables
>;
