import * as Yup from 'yup';
import { validateGeneralBezeichnung } from '../../../shared/components/KurzBezeichnung/form/kurzBezeichnungFormPartValidationSchema';
import { entityIsRequired } from '../../../components/message/validationMsg';
import { vorschreibungspositionFormFields } from './vorschreibungspositionFormMapper';

/* eslint-disable no-template-curly-in-string */

export const VorschreibungspositionFormValidationSchema = Yup.object().shape(
  {
    // kurzBezeichnungSchema is not imported and merged to this schema because that way cyclic dependency problems appear (merge doesn't seem to work with attributes which are depending on each other
    [vorschreibungspositionFormFields.kurzBezeichnung]: validateGeneralBezeichnung(25),
    [vorschreibungspositionFormFields.bezeichnung]: validateGeneralBezeichnung(100, true),
    [vorschreibungspositionFormFields.art]: Yup.string().required(entityIsRequired('Art')),
    [vorschreibungspositionFormFields.versionValidFrom]: Yup.string()
      .nullable()
      .when(
        [vorschreibungspositionFormFields.kontierungstabelleId, vorschreibungspositionFormFields.umsatzsteuerkennzeichenId],
        ([kontierungstabelleId, umsatzsteuerkennzeichenId], schema) =>
          !!kontierungstabelleId || !!umsatzsteuerkennzeichenId ? schema.nullable().required(entityIsRequired('Gültig ab')) : schema
      ),
    [vorschreibungspositionFormFields.kontierungstabelleId]: Yup.string()
      .nullable()
      .when(
        [vorschreibungspositionFormFields.versionValidFrom, vorschreibungspositionFormFields.umsatzsteuerkennzeichenId],
        ([versionValidFrom, umsatzsteuerkennzeichenId], schema) =>
          !!versionValidFrom || !!umsatzsteuerkennzeichenId ? schema.nullable().required(entityIsRequired('Kontierungstabelle')) : schema
      ),
    [vorschreibungspositionFormFields.umsatzsteuerkennzeichenId]: Yup.string()
      .nullable()
      .when(
        [vorschreibungspositionFormFields.versionValidFrom, vorschreibungspositionFormFields.kontierungstabelleId],
        ([versionValidFrom, kontierungstabelleId], schema) =>
          !!versionValidFrom || !!kontierungstabelleId ? schema.nullable().required(entityIsRequired('Umsatzsteuerkennzeichen')) : schema
      ),
  },
  [
    [vorschreibungspositionFormFields.versionValidFrom, vorschreibungspositionFormFields.kontierungstabelleId],
    [vorschreibungspositionFormFields.versionValidFrom, vorschreibungspositionFormFields.umsatzsteuerkennzeichenId],
    [vorschreibungspositionFormFields.kontierungstabelleId, vorschreibungspositionFormFields.umsatzsteuerkennzeichenId],
  ]
);

/* eslint-enable no-template-curly-in-string */
