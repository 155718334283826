import React from 'react';
import ObjektAufteilungsschluesselTableActions from './ObjektAufteilungsschluesselTableActions';
import { generatePathToAufteilunsschluesselPage } from '../../../Aufteilungsschluessel/aufteilungsschluesselUriPaths';
import StatusBadge from '../../../../components/Status/StatusBadge';
import LinkWithStatus from '../../../../components/Link/LinkWithStatus';
import DataWithStatus from '../../../../components/Helpers/DataWithStatus';
import { compareTwoStringsForSorting } from '../../../../helpers/stringHelper';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { ObjektAufteilungsschluessel } from '../../../../types';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';

const objektAufteilungsSchluesselTableColumns = (
  objektId: string,
  onAction: () => void,
  isWegObjekt: boolean
): TableWithColSelectorColumnProps<ObjektAufteilungsschluessel>[] => [
  {
    title: 'Kurzbezeichnung',
    defaultSelected: true,
    dataIndex: 'kurzbezeichnung',
    sorter: (a, b) => compareTwoStringsForSorting(a.kurzBezeichnung, b.kurzBezeichnung),
    render: (text, record) => (
      <DataWithStatus
        status={record.objektAufteilungsschluesselStatus}
        text={record.kurzBezeichnung}
        showBadgeDot={false}
        showStatusDescription={false}
      />
    ),
  },
  {
    title: 'Bezeichnung',
    defaultSelected: true,
    dataIndex: 'bezeichnung',
    width: '250px',
    sorter: (a, b) => compareTwoStringsForSorting(a.bezeichnung, b.bezeichnung),
    render: (text, record) => (
      <LinkWithStatus
        status={record.aufteilungsschluesselStatus}
        text={record.bezeichnung}
        to={generatePathToAufteilunsschluesselPage(record.aufteilungsschluesselId, record.aufteilungsschluesselStatus)}
        isLinkOnlyForAdmin
        maxTextLength={30}
      />
    ),
  },
  {
    title: 'Bezugsbasis',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.bezugsbasis.text, b.bezugsbasis.text),
    render: (text, record) => (
      <DataWithStatus
        status={record.objektAufteilungsschluesselStatus}
        text={record.bezugsbasis.text}
        showBadgeDot={false}
        showStatusDescription={false}
      />
    ),
  },
  {
    title: 'Verwendung',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.verwendung?.text, b.verwendung?.text),
    render: (text, record) => (
      <DataWithStatus
        status={record.objektAufteilungsschluesselStatus}
        text={record.verwendung?.text ?? ''}
        showBadgeDot={false}
        showStatusDescription={false}
      />
    ),
  },
  {
    title: 'Einheit',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.masseinheit.text, b.masseinheit.text),
    render: (text, record) => (
      <DataWithStatus
        status={record.objektAufteilungsschluesselStatus}
        text={record.masseinheit.text}
        showBadgeDot={false}
        showStatusDescription={false}
      />
    ),
  },
  {
    title: 'Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.objektAufteilungsschluesselStatus.text, b.objektAufteilungsschluesselStatus.text),
    render: (text, record) => <StatusBadge entityStatus={record.objektAufteilungsschluesselStatus} />,
  },
  {
    title: 'Erstellt am',
    render: (text, record) => (
      <DataWithStatus text={record.createTs} status={record.objektAufteilungsschluesselStatus} showBadgeDot={false} showStatusDescription={false}>
        {() => <FormattedDateTime createdTs={record.createTs} />}
      </DataWithStatus>
    ),
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    key: 'action',
    render: (text, record) => (
      <ObjektAufteilungsschluesselTableActions aufteilungsschluessel={record} objektId={objektId} onAction={onAction} isWegObjekt={isWegObjekt} />
    ),
  },
];

export default objektAufteilungsSchluesselTableColumns;
