import { generatePath } from 'react-router-dom';
import { URI_OBJEKT_DETAILS_PAGE } from '../Objekt/objektUriPaths';
import { ZinslisteStatus, ZinslisteStatusTuple } from '../../types';

// OBJEKT-ZINSLISTE
export const URI_DASHBOARD_OBJEKT_ZINSLISTE_LIST = `${URI_OBJEKT_DETAILS_PAGE}/rent-roll`;
export const URI_ZINSLISTE_DETAILS_PAGE = `${URI_DASHBOARD_OBJEKT_ZINSLISTE_LIST}/:zinslisteId`;

// DASHBOARD-ZINSLISTE
export const URI_ZINSLISTE_PAGE = '/dashboard/zinslisten';

export const generatePathToZinslisteDetailsPage = (objektId: string, zinslisteId: string) =>
  generatePath(URI_ZINSLISTE_DETAILS_PAGE, { objektId, zinslisteId });

export const generatePathToZinslisteDetailsPageOrDiscrepancies = (objektId: string, zinslisteId: string, status: ZinslisteStatusTuple) =>
  status.value === ZinslisteStatus.Invalid
    ? `${generatePathToZinslisteDetailsPage(objektId, zinslisteId)}/discrepancies`
    : generatePathToZinslisteDetailsPage(objektId, zinslisteId);
