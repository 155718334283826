import React from 'react';
import { ContactsOutlined, HomeOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../../../components/ContextSidebar/ContentWithSidebar';
import { pathsToKontoDetailsPage } from '../../../../features/Kontenplan/kontoUriPaths';
import KontoAbrechnungenTabs from '../../../../features/Kontenplan/KontoDetails/KontoAbrechnungenTabs';
import KontoEinstellungenTabs from '../../../../features/Kontenplan/KontoDetails/KontoEinstellungenTabs';
import { Konto } from '../../../../types';

const menuListKonto = (konto: Konto): GroupedMenueListInterface => ({
  topMenuList: [
    {
      title: 'Einstellungen',
      icon: <HomeOutlined />,
      uri: `${pathsToKontoDetailsPage(konto.kontoId).bezuege}`,
      content: (props) => <KontoEinstellungenTabs konto={konto} {...props} />,
    },
  ],
  contextMenuList: [
    {
      title: 'Abrechnungen',
      icon: <ContactsOutlined />,
      uri: `${pathsToKontoDetailsPage(konto.kontoId).abrechnungen}`,
      content: (props) => <KontoAbrechnungenTabs konto={konto} {...props} />,
      contextKey: ['ABRECHNUNGEN'],
    },
  ],
});

export default menuListKonto;
