import React, { FC } from 'react';
import { Empty } from 'antd';
import { Budgeting, BudgetingVPos } from '../../../../../types';
import budgetingVPosTableColumns from './budgetingVPosTableColumns';
import BudgetingAbrKreisTable from '../Level2/BudgetingAbrKreisTable';
import NestedTableWithColSelector from '../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';

type Props = {
  budgeting: Budgeting;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: () => Promise<unknown>;
  isUpdating: boolean;
  updatingRowKey: string | null;
  setUpdatingRowKey: React.Dispatch<React.SetStateAction<string | null>>;
};

const BudgetingVPosTable: FC<Props> = ({ budgeting, isLoading, setIsLoading, refetch, isUpdating, updatingRowKey, setUpdatingRowKey }) => {
  const { budgetingVPosList } = budgeting;

  const getRowKey = (record: BudgetingVPos) => record.budgetingVPosId;

  return (
    <NestedTableWithColSelector<BudgetingVPos>
      colWidth={150}
      level={1}
      locale={{
        emptyText: <Empty description={<span>Keine Budgetierung vorhanden</span>} />,
      }}
      pagination={false}
      loading={isLoading}
      dataSource={budgetingVPosList}
      columns={budgetingVPosTableColumns(budgeting, isLoading, setIsLoading, refetch, isUpdating, updatingRowKey, setUpdatingRowKey)}
      rowKey={getRowKey}
      useHighlighting
      expandable={{
        rowExpandable: (record) => !!record.budgetingAbrKreisList.length,
        expandedRowRender: (record, index, indent, expanded) => {
          return (
            <BudgetingAbrKreisTable
              parentRowKeys={[getRowKey(record)]}
              visible={expanded}
              budgeting={budgeting}
              abrechnungKreisList={record.budgetingAbrKreisList}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              refetch={refetch}
              isUpdating={isUpdating}
              updatingRowKey={updatingRowKey}
              setUpdatingRowKey={setUpdatingRowKey}
              budgetingVPosStatus={record.status}
            />
          );
        },
      }}
    />
  );
};
export default BudgetingVPosTable;
