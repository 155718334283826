import { FC } from 'react';
import { useStandingInvoiceChangeHistoryQuery } from '../../gql/StandingInvoiceQueries.types';
import { HistoryType } from '../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../History/HistoryListingTable';

type Props = {
  belegId: string;
};

const StandingInvoiceChangeHistoryListingTable: FC<Props> = ({ belegId }) => {
  const { data, loading } = useStandingInvoiceChangeHistoryQuery({ variables: { belegId } });
  const historyList = data?.getStandingInvoiceChangeHistory.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="standingInvoice" />;
};

export default StandingInvoiceChangeHistoryListingTable;
