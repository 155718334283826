import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { filtersFormFields, FiltersFormValues, listingLabelList } from '../filtersFormMapper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import BeVertragBaseSelect from '../../../../Vertrag/shared/BeVertragBaseSelect';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
};

const BeVertragSelectFormItem: FC<Props> = ({ formikProps }) => {
  return (
    <FormItemWithoutColon name={filtersFormFields.vertragId} label={listingLabelList.vertragId}>
      <BeVertragBaseSelect
        name={filtersFormFields.vertragId}
        onChange={formikProps.submitForm}
        disabled={!formikProps.values.objektId || !formikProps.values.bestandseinheitId}
        objektId={formikProps.values.objektId}
        bestandseinheitId={formikProps.values.bestandseinheitId}
        showObjekt
        showBestandseinheit
        showVertragsdauer
      />
    </FormItemWithoutColon>
  );
};

export default BeVertragSelectFormItem;
