import React from 'react';
import { Helmet } from 'react-helmet-async';
import { PageLayout } from '../../components/LayoutAndromeda';
import ZinslisteListingForm from '../../features/Zinsliste/Listing/ZinslisteListingForm';

const ZinslistePage = () => {
  return (
    <>
      <Helmet>
        <title>Zinslisten</title>
      </Helmet>
      <PageLayout>
        <ZinslisteListingForm />
      </PageLayout>
    </>
  );
};

export default ZinslistePage;
