import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { OrderGenerierlaufType } from '../../../../../types';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import { useFilterGenLaufOrderBelegnummerListQuery } from '../../../gql/GenerierlaufFilter/OrderGenerierlaufFilterQueries.types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';

type Props = {
  name: string;
  generierlaufId: string;
  generierlaufType: OrderGenerierlaufType;
} & SelectProps;

const GenerierlaufBelegnummerFormSelect: FC<Props> = ({ name, generierlaufId, generierlaufType, ...restProps }) => {
  const { data, loading } = useFilterGenLaufOrderBelegnummerListQuery({
    variables: {
      generierlaufId,
      generierlaufType,
    },
  });

  const list = data?.getFilterGenLaufOrderBelegnummerList.data ?? [];

  return (
    <Select
      size="small"
      loading={loading}
      id={name}
      name={name}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      placeholder="Belegnummer auswählen"
      {...restProps}
    >
      {list.map((belegnummer) => (
        <Select.Option key={belegnummer} value={belegnummer}>
          <DataWithShortenedText text={belegnummer} maxTextLength={40} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default GenerierlaufBelegnummerFormSelect;
