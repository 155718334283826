import { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { useUpdateFirmendatenBankSettingsMutation } from '../gql/FirmendatenBankSettingsMutations.types';
import { showSuccessMsgUpdate } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { bankSettingsUpdateFormFields, BankSettingsUpdateFormValues, mapBankSettingsToFormValues } from './bankSettingsUpdateFormMapper';
import { BankSettings } from '../../../types';
import DrawerRow from '../../../components/Drawer/DrawerRow';
import FormButtons from '../../../components/Button/FormButtons';
import SepaCreditorIdFormItem from './SepaCreditorIdFormItem';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';

type Props = {
  bankSettings?: BankSettings | null;
  onCancel: () => void;
  onSuccess: () => void;
};

const FirmendatenBankSettingsUpdateForm: FC<Props> = ({ bankSettings, onCancel, onSuccess }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'FirmendatenBankSettings'>('FirmendatenBankSettings');

  const [updateBankSettings, { loading }] = useUpdateFirmendatenBankSettingsMutation({
    onCompleted: () => {
      showSuccessMsgUpdate('BankSettings');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<BankSettingsUpdateFormValues>
      initialValues={mapBankSettingsToFormValues(bankSettings)}
      onSubmit={(values, { setSubmitting }) => {
        updateBankSettings({
          variables: {
            input: {
              sepaCreditorId: values.sepaCreditorId,
            },
          },
        }).finally(() => setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <DrawerRow
            rowTitle="Bank"
            content={
              <SepaCreditorIdFormItem
                name={bankSettingsUpdateFormFields.sepaCreditorId}
                fieldHelp={getFieldHelpText('FirmendatenBankSettings.sepaCreditorId')}
              />
            }
          />
          <FormButtons updateMode onCancel={onCancel} isOkLoading={loading} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default FirmendatenBankSettingsUpdateForm;
