import * as Yup from 'yup';

import { streetAddressValidationSchemaOptional } from '../../../shared/components/Address/form/addressFieldsValidationSchema';
import { entityIsRequired } from '../../../components/message/validationMsg';

/* eslint-disable no-template-curly-in-string */

export const addressListValidationSchema = Yup.array().min(1, entityIsRequired('Mindestens eine Adresse'));

export const addressListFormPartValidationSchema = Yup.object().shape({
  // rule for addressList is pretty special, it will maybe not apply for all use cases, then it has to be customizable
  addressList: addressListValidationSchema,
  tempAddress: streetAddressValidationSchemaOptional,
});

/* eslint-enable no-template-curly-in-string */
