import React, { FC, useContext } from 'react';
import { Formik } from 'formik';
import { Divider } from 'antd';
import { Form } from 'formik-antd';
import { IndexationAgreementContext } from '../../IndexationAgreementContext';
import { showSuccessMsgCreate } from '../../../../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../../../../helpers/apolloHelper';
import {
  useCreateVertragVposIndexationAgreementIndexAnnualMutation,
  useUpdateVertragVposIndexationAgreementIndexAnnualMutation,
} from '../../../../gql/VPosIndexationAgreementMutations.types';
import {
  indAgreeIndexAnnualFormFields,
  IndexationAgreementIndexAnnualFormValues,
  mapFormValuesToIndexAnnual,
  mapIndexAnnualToFormValues,
} from './indexAnnualFormMapper';
import FormButtons from '../../../../../../../../../components/Button/FormButtons';
import IndexBlock from './IndexBlock';
import { ValuePreservationType, VertragVposIndexationAgreementIndexAnnual } from '../../../../../../../../../types';
import ThresholdBlock from '../shared/ThresholdBlock';
import { indexAnnualFormValidationSchema } from './indexAnnualFormValidationSchema';
import SubsequentBillingBlock from '../shared/SubsequentBillingBlock';
import { useSetIndexOld } from '../shared/useSetIndexOld';
import LastValueBaseBlock from '../shared/LastValueBaseBlock';
import { useGetAppFieldHelpText } from '../../../../../../../../FieldHelp/useGetFieldHelpText';

type Props = {
  indexationAgreement?: VertragVposIndexationAgreementIndexAnnual;
  onCancel: () => void;
  onSuccess: () => void;
};

const IndexAnnualForm: FC<Props> = ({ indexationAgreement, onCancel, onSuccess }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'VertragVposIndexationAgreementIndexAnnual'>('VertragVposIndexationAgreementIndexAnnual');

  const isUpdate = !!indexationAgreement;
  const { bestandseinheitId, objektId, vertragId, vorschreibungspositionId } = useContext(IndexationAgreementContext);
  const entity = 'Index jährlich';

  const [runCreate, { loading: loadingCreate }] = useCreateVertragVposIndexationAgreementIndexAnnualMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateVertragVposIndexationAgreementIndexAnnualMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const { handleIndexSeriesChange, handleMonthYearChange } = useSetIndexOld();

  return (
    <Formik<IndexationAgreementIndexAnnualFormValues>
      initialValues={mapIndexAnnualToFormValues(indexationAgreement)}
      validationSchema={indexAnnualFormValidationSchema}
      onSubmit={(values, formikHelpers) => {
        isUpdate
          ? runUpdate({
              variables: {
                objektId,
                bestandseinheitId,
                vertragId,
                vorschreibungspositionId,
                indexationAgreementId: indexationAgreement.vertragVposIndexationAgreementId,
                input: mapFormValuesToIndexAnnual(values),
              },
            }).finally(() => formikHelpers.setSubmitting(false))
          : runCreate({
              variables: {
                objektId,
                bestandseinheitId,
                vertragId,
                vorschreibungspositionId,
                input: mapFormValuesToIndexAnnual(values),
              },
            }).finally(() => formikHelpers.setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <IndexBlock
            formikProps={formikProps}
            onIndexSeriesChange={(indexSeriesId) => handleIndexSeriesChange(indAgreeIndexAnnualFormFields.indexOld, indexSeriesId, formikProps)}
            indexSourceType={indexationAgreement?.indexSeries?.sourceType.value}
            fieldHelpNames={{
              validFrom: getFieldHelpText('VertragVposIndexationAgreementIndexAnnual.validFrom.year'),
              indexSeriesId: getFieldHelpText('VertragVposIndexationAgreementIndexAnnual.indexSeriesId'),
              annualAverage: getFieldHelpText('VertragVposIndexationAgreementIndexAnnual.annualAverage'),
              monthOfIndexPoint: getFieldHelpText('VertragVposIndexationAgreementIndexAnnual.monthOfIndexPoint'),
              effectiveWithMonth: getFieldHelpText('VertragVposIndexationAgreementIndexAnnual.effectiveWithMonth'),
            }}
          />
          <Divider />
          <LastValueBaseBlock
            monthYearName={indAgreeIndexAnnualFormFields.monthYearOld}
            onMonthYearChange={(month, year) => handleMonthYearChange(indAgreeIndexAnnualFormFields.indexOld, month, year, formikProps)}
            indexOldName={indAgreeIndexAnnualFormFields.indexOld}
            valuePreservationType={ValuePreservationType.IndexAnnual}
            isIndexOldPreliminary={!!indexationAgreement?.indexOldPreliminary}
            fieldHelpNames={{
              monthYearOld: getFieldHelpText('VertragVposIndexationAgreementIndexAnnual.monthYearOld.year'),
              indexOld: getFieldHelpText('VertragVposIndexationAgreementIndexAnnual.indexOld'),
            }}
          />
          <Divider />
          <ThresholdBlock
            formikProps={formikProps}
            thresholdName={indAgreeIndexAnnualFormFields.threshold}
            thresholdTypeName={indAgreeIndexAnnualFormFields.thresholdType}
            shareInPercentToApplyName={indAgreeIndexAnnualFormFields.shareInPercentToApply}
            minimumLimitName={indAgreeIndexAnnualFormFields.minimumLimit}
            fieldHelpNames={{
              threshold: getFieldHelpText('VertragVposIndexationAgreementIndexAnnual.threshold'),
              thresholdType: getFieldHelpText('VertragVposIndexationAgreementIndexAnnual.thresholdType'),
              shareInPercentToApply: getFieldHelpText('VertragVposIndexationAgreementIndexAnnual.shareInPercentToApply'),
              minimumLimit: getFieldHelpText('VertragVposIndexationAgreementIndexAnnual.minimumLimit'),
            }}
          />
          <Divider />
          <SubsequentBillingBlock
            formikProps={formikProps}
            subsequentBillingName={indAgreeIndexAnnualFormFields.subsequentBilling}
            subsequentBillingObjektVPosName={indAgreeIndexAnnualFormFields.subsequentBillingObjektVorschreibungspositionId}
            fieldHelpNames={{
              subsequentBilling: getFieldHelpText('VertragVposIndexationAgreementIndexAnnual.subsequentBilling'),
              subsequentBillingObjektVorschreibungspositionId: getFieldHelpText(
                'VertragVposIndexationAgreementIndexAnnual.subsequentBillingObjektVorschreibungspositionId'
              ),
            }}
          />
          <FormButtons
            updateMode={isUpdate}
            isOkLoading={loadingCreate || loadingUpdate}
            isOkDisabled={formikProps.isSubmitting}
            onCancel={onCancel}
          />
        </Form>
      )}
    </Formik>
  );
};

export default IndexAnnualForm;
