import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import MahnungPage from './MahnungPage';
import { URI_MAHNUNG_PAGE } from '../../features/Mahnung/mahnungUriPaths';

const mahnungPageRoute = (
  <Route path={URI_MAHNUNG_PAGE} element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<MahnungPage />} />} />
);

export default mahnungPageRoute;
