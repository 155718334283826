import React, { FC } from 'react';
import { Select } from 'formik-antd';
import { useRechtstraegerListQuery } from '../../Rechtstraeger/gql/RechtsTraegerQueries.types';
import { selectFilterOption } from '../../../helpers/selectHelper';
import { RechtstraegerListEntry } from '../../../types';

type OPBuchungskreisSelectProps = {
  name: string;
  onChange: (rechtstraeger?: RechtstraegerListEntry) => void;
  style?: any;
};

const OPBuchungskreisSelect: FC<OPBuchungskreisSelectProps> = ({ name, onChange, style }) => {
  const { data, loading } = useRechtstraegerListQuery({
    variables: {
      hasBuchungskreis: true,
    },
  });

  const rechtstraegerWithBuchungskreisList = data?.getRechtstraegerList.data.contentList || [];

  return (
    <Select
      name={name}
      id={name}
      loading={loading}
      placeholder="Buchungskreis auswählen"
      showSearch
      filterOption={selectFilterOption}
      allowClear
      onChange={(buchungskreisId) => callOnChange(rechtstraegerWithBuchungskreisList, buchungskreisId, onChange)}
      style={style}
    >
      {rechtstraegerWithBuchungskreisList.map((rechtstrager) => (
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        <Select.Option key={rechtstrager.buchungskreisId!} value={rechtstrager.buchungskreisId!}>
          {rechtstrager.kurzBezeichnung}
        </Select.Option>
      ))}
    </Select>
  );
};

const callOnChange = (
  rechtstraegerList: RechtstraegerListEntry[],
  buchungskreisId: string,
  onChange: (rechtstraeger?: RechtstraegerListEntry) => void
) => {
  const rechtstrager = rechtstraegerList.find((rechtstrager) => rechtstrager.buchungskreisId === buchungskreisId);
  onChange(rechtstrager);
};

export default OPBuchungskreisSelect;
