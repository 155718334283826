import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import IndexSeriesBtnAndDrawer from './IndexSeriesBtnAndDrawer';
import IndexSeriesTable from './Table/IndexSeriesTable';
import { IndexSeries } from '../../../types';
import {
  IndexSeriesListingFiltersFormValues,
  mapFormValuesToIndexSeriesListingFilters,
  mapQueryParamsToFormValues,
} from '../shared/Filters/listingFiltersFormMapper';
import ListingFilters from '../shared/Filters/ListingFilters';
import { useQueryParams } from '../../../hooks/useStringQueryParams';
import { TIndexSeriesQueryParams, updateAndromedaSystemSettingsIndexSeriesQueryParams } from '../indexSeriesUriPaths';

type Props = {
  indexSeriesList: IndexSeries[];
  onAction: () => void;
};

const IndexSeriesListing: FC<Props> = ({ indexSeriesList, onAction }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TIndexSeriesQueryParams>();

  const setInitialQueryParams = () => {
    const filters = mapQueryParamsToFormValues(queryParams);
    updateAndromedaSystemSettingsIndexSeriesQueryParams(navigate, mapFormValuesToIndexSeriesListingFilters(filters));
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik<IndexSeriesListingFiltersFormValues>
      initialValues={mapQueryParamsToFormValues(queryParams)}
      onSubmit={(values) => {
        const filters = mapFormValuesToIndexSeriesListingFilters(values);
        updateAndromedaSystemSettingsIndexSeriesQueryParams(navigate, filters);
      }}
    >
      {(formikProps) => (
        <>
          <ListingFilters formikProps={formikProps} actionButtons={<IndexSeriesBtnAndDrawer onSuccess={onAction} />} />
          <IndexSeriesTable formikProps={formikProps} indexSeriesList={indexSeriesList} onAction={onAction} />
        </>
      )}
    </Formik>
  );
};

export default IndexSeriesListing;
