import * as Types from '../../../../../../types';

import { gql } from '@apollo/client';
import { VertragVposIndexationAgreementFieldsFragmentDoc } from './VertragVposIndexationAgreementFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../../../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VertragVposIndexationAgreementVorschreibungspositionListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type VertragVposIndexationAgreementVorschreibungspositionListQuery = {
  getVertragVposIndexationAgreementVorschreibungspositionList: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      indexOld?: number | null;
      indexOldPreliminary?: boolean | null;
      indexationAgreementCount: number;
      monthYearOld?: string | null;
      name: string;
      updatedByMitarbeiterId?: string | null;
      validFrom?: string | null;
      vorschreibungspositionId: string;
      objektVorschreibungspositionStatus: { description?: string | null; text: string; value: Types.ObjektVorschreibungspositionStatus };
      type?: { text: string; value: Types.ValuePreservationType } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type VertragVposIndexationAgreementListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
}>;

export type VertragVposIndexationAgreementListQuery = {
  getVertragVposIndexationAgreementList: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      indexOld?: number | null;
      indexOldPreliminary: boolean;
      indexedValueCount: number;
      monthYearOld: string;
      name: string;
      updatedByMitarbeiterId?: string | null;
      validFrom: string;
      vertragVposIndexationAgreementId: string;
      vorschreibungspositionId: string;
      objektVorschreibungspositionStatus: { description?: string | null; text: string; value: Types.ObjektVorschreibungspositionStatus };
      type: { text: string; value: Types.ValuePreservationType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type VertragVposIndexationAgreementQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  indexationAgreementId: Types.Scalars['ID']['input'];
}>;

export type VertragVposIndexationAgreementQuery = {
  getVertragVposIndexationAgreement?: {
    data:
      | {
          __typename: 'VertragVposIndexationAgreementCategory';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          basedOnUsableArea: boolean;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }
      | {
          __typename: 'VertragVposIndexationAgreementIndexAnnual';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          annualAverage: boolean;
          minimumLimit?: number | null;
          shareInPercentToApply?: number | null;
          subsequentBilling: boolean;
          threshold: number;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          effectiveWithMonth?: { text: string; value: Types.Month } | null;
          monthOfIndexPoint: { text: string; value: Types.Month };
          subsequentBillingObjektVorschreibungsposition?: {
            bezeichnung: string;
            objektVorschreibungspositionId: string;
            kurzBezeichnung: string;
          } | null;
          thresholdType: { text: string; value: Types.ThresholdType };
        }
      | {
          __typename: 'VertragVposIndexationAgreementIndexThreshold';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          effectiveAfterMonths?: number | null;
          minimumLimit?: number | null;
          shareInPercentToApply?: number | null;
          subsequentBilling: boolean;
          threshold: number;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          subsequentBillingObjektVorschreibungsposition?: {
            bezeichnung: string;
            objektVorschreibungspositionId: string;
            kurzBezeichnung: string;
          } | null;
          thresholdType: { text: string; value: Types.ThresholdType };
        }
      | {
          __typename: 'VertragVposIndexationAgreementReferenceValue';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  } | null;
};

export type VertragVposIndexationAgreementChangeHistoryListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  indexationAgreementId: Types.Scalars['ID']['input'];
}>;

export type VertragVposIndexationAgreementChangeHistoryListQuery = {
  getVertragVposIndexationAgreementChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const VertragVposIndexationAgreementVorschreibungspositionListDocument = gql`
  query VertragVposIndexationAgreementVorschreibungspositionList(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $includeArchiviert: Boolean
  ) {
    getVertragVposIndexationAgreementVorschreibungspositionList(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      includeArchiviert: $includeArchiviert
    ) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
        indexOld
        indexOldPreliminary
        indexationAgreementCount
        monthYearOld
        name
        objektVorschreibungspositionStatus {
          description
          text
          value
        }
        type {
          text
          value
        }
        updatedByMitarbeiterId
        validFrom
        vorschreibungspositionId
        warningList {
          attribute
          message
          type
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useVertragVposIndexationAgreementVorschreibungspositionListQuery(
  baseOptions: Apollo.QueryHookOptions<
    VertragVposIndexationAgreementVorschreibungspositionListQuery,
    VertragVposIndexationAgreementVorschreibungspositionListQueryVariables
  > &
    ({ variables: VertragVposIndexationAgreementVorschreibungspositionListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VertragVposIndexationAgreementVorschreibungspositionListQuery,
    VertragVposIndexationAgreementVorschreibungspositionListQueryVariables
  >(VertragVposIndexationAgreementVorschreibungspositionListDocument, options);
}
export function useVertragVposIndexationAgreementVorschreibungspositionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VertragVposIndexationAgreementVorschreibungspositionListQuery,
    VertragVposIndexationAgreementVorschreibungspositionListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VertragVposIndexationAgreementVorschreibungspositionListQuery,
    VertragVposIndexationAgreementVorschreibungspositionListQueryVariables
  >(VertragVposIndexationAgreementVorschreibungspositionListDocument, options);
}
export function useVertragVposIndexationAgreementVorschreibungspositionListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VertragVposIndexationAgreementVorschreibungspositionListQuery,
        VertragVposIndexationAgreementVorschreibungspositionListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    VertragVposIndexationAgreementVorschreibungspositionListQuery,
    VertragVposIndexationAgreementVorschreibungspositionListQueryVariables
  >(VertragVposIndexationAgreementVorschreibungspositionListDocument, options);
}
export type VertragVposIndexationAgreementVorschreibungspositionListQueryHookResult = ReturnType<
  typeof useVertragVposIndexationAgreementVorschreibungspositionListQuery
>;
export type VertragVposIndexationAgreementVorschreibungspositionListLazyQueryHookResult = ReturnType<
  typeof useVertragVposIndexationAgreementVorschreibungspositionListLazyQuery
>;
export type VertragVposIndexationAgreementVorschreibungspositionListSuspenseQueryHookResult = ReturnType<
  typeof useVertragVposIndexationAgreementVorschreibungspositionListSuspenseQuery
>;
export type VertragVposIndexationAgreementVorschreibungspositionListQueryResult = Apollo.QueryResult<
  VertragVposIndexationAgreementVorschreibungspositionListQuery,
  VertragVposIndexationAgreementVorschreibungspositionListQueryVariables
>;
export const VertragVposIndexationAgreementListDocument = gql`
  query VertragVposIndexationAgreementList($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!, $vorschreibungspositionId: ID!) {
    getVertragVposIndexationAgreementList(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
    ) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
        indexOld
        indexOldPreliminary
        indexedValueCount
        monthYearOld
        name
        objektVorschreibungspositionStatus {
          description
          text
          value
        }
        type {
          text
          value
        }
        updatedByMitarbeiterId
        validFrom
        vertragVposIndexationAgreementId
        vorschreibungspositionId
        warningList {
          attribute
          message
          type
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useVertragVposIndexationAgreementListQuery(
  baseOptions: Apollo.QueryHookOptions<VertragVposIndexationAgreementListQuery, VertragVposIndexationAgreementListQueryVariables> &
    ({ variables: VertragVposIndexationAgreementListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VertragVposIndexationAgreementListQuery, VertragVposIndexationAgreementListQueryVariables>(
    VertragVposIndexationAgreementListDocument,
    options
  );
}
export function useVertragVposIndexationAgreementListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VertragVposIndexationAgreementListQuery, VertragVposIndexationAgreementListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VertragVposIndexationAgreementListQuery, VertragVposIndexationAgreementListQueryVariables>(
    VertragVposIndexationAgreementListDocument,
    options
  );
}
export function useVertragVposIndexationAgreementListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<VertragVposIndexationAgreementListQuery, VertragVposIndexationAgreementListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VertragVposIndexationAgreementListQuery, VertragVposIndexationAgreementListQueryVariables>(
    VertragVposIndexationAgreementListDocument,
    options
  );
}
export type VertragVposIndexationAgreementListQueryHookResult = ReturnType<typeof useVertragVposIndexationAgreementListQuery>;
export type VertragVposIndexationAgreementListLazyQueryHookResult = ReturnType<typeof useVertragVposIndexationAgreementListLazyQuery>;
export type VertragVposIndexationAgreementListSuspenseQueryHookResult = ReturnType<typeof useVertragVposIndexationAgreementListSuspenseQuery>;
export type VertragVposIndexationAgreementListQueryResult = Apollo.QueryResult<
  VertragVposIndexationAgreementListQuery,
  VertragVposIndexationAgreementListQueryVariables
>;
export const VertragVposIndexationAgreementDocument = gql`
  query VertragVposIndexationAgreement(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vorschreibungspositionId: ID!
    $indexationAgreementId: ID!
  ) {
    getVertragVposIndexationAgreement(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      indexationAgreementId: $indexationAgreementId
    ) {
      data {
        ...VertragVposIndexationAgreementFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${VertragVposIndexationAgreementFieldsFragmentDoc}
`;
export function useVertragVposIndexationAgreementQuery(
  baseOptions: Apollo.QueryHookOptions<VertragVposIndexationAgreementQuery, VertragVposIndexationAgreementQueryVariables> &
    ({ variables: VertragVposIndexationAgreementQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VertragVposIndexationAgreementQuery, VertragVposIndexationAgreementQueryVariables>(
    VertragVposIndexationAgreementDocument,
    options
  );
}
export function useVertragVposIndexationAgreementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VertragVposIndexationAgreementQuery, VertragVposIndexationAgreementQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VertragVposIndexationAgreementQuery, VertragVposIndexationAgreementQueryVariables>(
    VertragVposIndexationAgreementDocument,
    options
  );
}
export function useVertragVposIndexationAgreementSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VertragVposIndexationAgreementQuery, VertragVposIndexationAgreementQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VertragVposIndexationAgreementQuery, VertragVposIndexationAgreementQueryVariables>(
    VertragVposIndexationAgreementDocument,
    options
  );
}
export type VertragVposIndexationAgreementQueryHookResult = ReturnType<typeof useVertragVposIndexationAgreementQuery>;
export type VertragVposIndexationAgreementLazyQueryHookResult = ReturnType<typeof useVertragVposIndexationAgreementLazyQuery>;
export type VertragVposIndexationAgreementSuspenseQueryHookResult = ReturnType<typeof useVertragVposIndexationAgreementSuspenseQuery>;
export type VertragVposIndexationAgreementQueryResult = Apollo.QueryResult<
  VertragVposIndexationAgreementQuery,
  VertragVposIndexationAgreementQueryVariables
>;
export const VertragVposIndexationAgreementChangeHistoryListDocument = gql`
  query VertragVposIndexationAgreementChangeHistoryList(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vorschreibungspositionId: ID!
    $indexationAgreementId: ID!
  ) {
    getVertragVposIndexationAgreementChangeHistoryList(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      indexationAgreementId: $indexationAgreementId
    ) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useVertragVposIndexationAgreementChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    VertragVposIndexationAgreementChangeHistoryListQuery,
    VertragVposIndexationAgreementChangeHistoryListQueryVariables
  > &
    ({ variables: VertragVposIndexationAgreementChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VertragVposIndexationAgreementChangeHistoryListQuery, VertragVposIndexationAgreementChangeHistoryListQueryVariables>(
    VertragVposIndexationAgreementChangeHistoryListDocument,
    options
  );
}
export function useVertragVposIndexationAgreementChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VertragVposIndexationAgreementChangeHistoryListQuery,
    VertragVposIndexationAgreementChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VertragVposIndexationAgreementChangeHistoryListQuery, VertragVposIndexationAgreementChangeHistoryListQueryVariables>(
    VertragVposIndexationAgreementChangeHistoryListDocument,
    options
  );
}
export function useVertragVposIndexationAgreementChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VertragVposIndexationAgreementChangeHistoryListQuery,
        VertragVposIndexationAgreementChangeHistoryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VertragVposIndexationAgreementChangeHistoryListQuery, VertragVposIndexationAgreementChangeHistoryListQueryVariables>(
    VertragVposIndexationAgreementChangeHistoryListDocument,
    options
  );
}
export type VertragVposIndexationAgreementChangeHistoryListQueryHookResult = ReturnType<
  typeof useVertragVposIndexationAgreementChangeHistoryListQuery
>;
export type VertragVposIndexationAgreementChangeHistoryListLazyQueryHookResult = ReturnType<
  typeof useVertragVposIndexationAgreementChangeHistoryListLazyQuery
>;
export type VertragVposIndexationAgreementChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useVertragVposIndexationAgreementChangeHistoryListSuspenseQuery
>;
export type VertragVposIndexationAgreementChangeHistoryListQueryResult = Apollo.QueryResult<
  VertragVposIndexationAgreementChangeHistoryListQuery,
  VertragVposIndexationAgreementChangeHistoryListQueryVariables
>;
