import { useEffect } from 'react';

export const LISTING_FILTER_HAS_CHANGED = 'listingFilterHasChanged';

const useOnEventsSetStickyFilterSummary = (refList: React.RefObject<HTMLDivElement>[]) => {
  useEffect(() => {
    const onScroll = () => setStylesForRefStickyElements(refList);
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onChange = () => setStylesForRefStickyElements(refList);
    document.addEventListener(LISTING_FILTER_HAS_CHANGED, onChange);
    return () => {
      document.removeEventListener(LISTING_FILTER_HAS_CHANGED, onChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const setStylesForRefStickyElements = (refList: React.RefObject<HTMLDivElement>[]) => {
  refList.forEach((refItem, index) => {
    const currentRef = refItem.current;
    if (currentRef) {
      const clientRect = currentRef.getBoundingClientRect();

      // Alle vorherigen Elemente abrufen
      const prevListItems = refList.slice(0, index).map((prevRef) => prevRef.current);

      // Überprüfen Sie die Positionen der vorherigen Elemente
      if (prevListItems.length > 0) {
        prevListItems.forEach((prevItem) => {
          if (prevItem) {
            const prevClientRect = prevItem.getBoundingClientRect();
            const prevClientRectList = prevListItems
              .slice(0, index)
              .map((prevRef) => prevRef?.getBoundingClientRect().height)
              .filter((x) => !!x)
              // @ts-ignore
              .reduce((acc: number, prev: number) => {
                return acc + prev;
              }, 0);

            if (prevClientRect.top <= clientRect.height || prevClientRect.top <= 0) {
              currentRef.classList.add('sticky');
              currentRef.style.top = `${prevClientRectList}px`;
            }
          }
        });
      } else if (clientRect.top <= 0) {
        currentRef.classList.add('sticky');
        currentRef.style.top = '0px';
      } else {
        currentRef.classList.remove('sticky');
      }
    }
  });
};

export default useOnEventsSetStickyFilterSummary;
