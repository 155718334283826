import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Empty } from 'antd';
import { UstVomAufwandVerarbeitungEntryFiltersFormValues } from './EntryListing/Filters/ListingFiltersFormMapper';
import GenerierlaufStatusExpandedRow from '../shared/GenerierlaufStatusExpandedRow';
import { isVerarbeitungEntryTableRowExpandable } from '../verarbeitungHelpers';
import ustVomAufwandVerarbeitungEntryTableColumns from './EntryListing/ustVomAufwandVerarbeitungEntryTableColumns';
import TableWithColSelector from '../../../components/Table/TableWithColSelector/TableWithColSelector';
import { UstVomAufwandGenerierlaufEntry } from '../../../types';

type UstVomAufwandVerarbeitungEntryTableProps = {
  entryList: UstVomAufwandGenerierlaufEntry[];
  formikProps: FormikProps<UstVomAufwandVerarbeitungEntryFiltersFormValues>;
};

const UstVomAufwandVerarbeitungEntryTable: FC<UstVomAufwandVerarbeitungEntryTableProps> = ({ entryList, formikProps }) => {
  const { objektId, buchungStatus } = formikProps.values;

  const filterForObjektId = (entry: UstVomAufwandGenerierlaufEntry) => (objektId ? entry.objekt.objektId === objektId : entry);

  const filterForBuchungStatus = (entry: UstVomAufwandGenerierlaufEntry) => {
    if (buchungStatus && buchungStatus.length > 0) {
      return entry.ustVomAufwand ? buchungStatus.includes(entry.ustVomAufwand.buchungStatus.value) : false;
    }
    return entry;
  };

  const filteredEntryList = entryList.filter(filterForObjektId).filter(filterForBuchungStatus);

  return (
    <TableWithColSelector<UstVomAufwandGenerierlaufEntry>
      loading={!entryList}
      locale={{
        emptyText: <Empty description={<span>Keine Positionen vorhanden</span>} />,
      }}
      size="small"
      dataSource={filteredEntryList}
      columns={ustVomAufwandVerarbeitungEntryTableColumns}
      pagination={{ defaultPageSize: 20, showSizeChanger: true }}
      rowKey={(record) => record.generierlaufEntryId}
      expandable={{
        expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
        rowExpandable: isVerarbeitungEntryTableRowExpandable,
      }}
      filterIdentifier="verarbeitung-ust-vom-aufwand"
    />
  );
};

export default UstVomAufwandVerarbeitungEntryTable;
