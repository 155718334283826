import { forwardRef } from 'react';
import { Formik, FormikProps } from 'formik';
import { TicketAttachment } from '../../../../types';
import { ticketAttachmentsFormInitialValues, TicketAttachmentsFormValues } from './ticketAttachmentsFormMapper';
import TicketAttachmentUpload from './TicketAttachmentUpload';

type Props = {
  attachmentList: TicketAttachment[];
};

const TicketAttachmentsForm = forwardRef<FormikProps<TicketAttachmentsFormValues>, Props>(({ attachmentList }, ref) => (
  <Formik<TicketAttachmentsFormValues> initialValues={ticketAttachmentsFormInitialValues} innerRef={ref} onSubmit={() => {}}>
    {(formikProps) => <TicketAttachmentUpload formikProps={formikProps} attachmentList={attachmentList} />}
  </Formik>
));

export default TicketAttachmentsForm;
