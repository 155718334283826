import { FC } from 'react';
import { useFirmaContactPersonDeletionHistoryListQuery } from '../Firma/gql/FirmaQueries.types';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';

type Props = {
  firmaId: string;
};

const FirmaContactPersonDeletionHistoryListingTable: FC<Props> = ({ firmaId }) => {
  const { data, loading } = useFirmaContactPersonDeletionHistoryListQuery({ variables: { firmaId } });
  const historyList = data?.getFirmaContactPersonDeletionHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Deletion} historyList={historyList} loading={loading} filterIdPrefix="firma-contact-person" />;
};

export default FirmaContactPersonDeletionHistoryListingTable;
