import { Space } from 'antd';
import { Box } from 'rebass';
import React, { FC } from 'react';
import { BaseType } from 'antd/lib/typography/Base';
import { DeviationColumnTitleStyledText } from '../../Styled/Deviation.style';
import { TDeviationCell } from '../../deviation.model';
import {
  cellsNewAltOrder,
  isDeviationChangeTypeAenderungAndEntryTypeAlt,
  isDeviationChangeTypeAenderungAndEntryTypeNeu,
  isDeviationChangeTypeEntferntAndEntryTypeAlt,
  isDeviationChangeTypeEntferntAndEntryTypeNeu,
  isDeviationChangeTypeNeuAndEntryTypeAlt,
  isDeviationChangeTypeNeuAndEntryTypeNeu,
} from '../../deviationHelpers';

type Props = {
  cellsCol: TDeviationCell[];
};

const DeviationAltNeuRowTypeCol: FC<Props> = ({ cellsCol }) => {
  const allCellsInNeuAltOrder = cellsNewAltOrder(cellsCol);

  return (
    <Space direction="vertical" style={{ minWidth: '24px' }}>
      <Box height="16px" />
      {allCellsInNeuAltOrder.map((cell, index) => (
        <React.Fragment key={index}>
          <DeviationColumnTitleStyledText
            strong={
              isDeviationChangeTypeAenderungAndEntryTypeNeu(cell) ||
              isDeviationChangeTypeEntferntAndEntryTypeAlt(cell) ||
              isDeviationChangeTypeNeuAndEntryTypeNeu(cell)
            }
            type={textType(cell)}
          >
            {cell.rowType}
          </DeviationColumnTitleStyledText>
        </React.Fragment>
      ))}
    </Space>
  );
};

const textType = (cell: TDeviationCell): BaseType | undefined => {
  if (
    isDeviationChangeTypeAenderungAndEntryTypeAlt(cell) ||
    isDeviationChangeTypeNeuAndEntryTypeAlt(cell) ||
    isDeviationChangeTypeEntferntAndEntryTypeNeu(cell)
  ) {
    return 'secondary';
  }
  return undefined;
};

export default DeviationAltNeuRowTypeCol;
