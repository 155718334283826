import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type FileInfoFieldsFragment = {
  budgetingYear?: number | null;
  contentType: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  dunningReferenceDate?: string | null;
  fileId: string;
  geloescht: boolean;
  homeownerId?: string | null;
  invoiceDate?: string | null;
  invoiceIssuerId?: string | null;
  invoiceNumber?: string | null;
  invoiceRecipientId?: string | null;
  name: string;
  size: string;
  bestandseinheitList: Array<{ bestandseinheitId: string; bezeichnung: string }>;
  labelList: Array<{ label: string; value: string }>;
  objektList: Array<{ objektId: string; kurzBezeichnung: string }>;
  type: { text: string; value: Types.FileInfoType };
  dimensions?: { height: number; width: number } | null;
};

export const FileInfoFieldsFragmentDoc = gql`
  fragment FileInfoFields on FileInfo {
    bestandseinheitList {
      bestandseinheitId
      bezeichnung
    }
    budgetingYear
    contentType
    createTs
    createdBy
    createdByMitarbeiterId
    dunningReferenceDate
    fileId
    geloescht
    homeownerId
    invoiceDate
    invoiceIssuerId
    invoiceNumber
    invoiceRecipientId
    labelList {
      label
      value
    }
    name
    size
    objektList {
      objektId
      kurzBezeichnung
    }
    type {
      text
      value
    }
    dimensions {
      height
      width
    }
  }
`;
