import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../../styles/pdfStyles';
import { columnWidthsAndAlignment } from './details-table-styles';
import { LabeledValue } from '../../../../shared/types';

const Saldovortrag: FC<{ data: LabeledValue }> = ({ data }) => (
  <View style={[pdfStyles.row, { paddingTop: '3mm' }]}>
    <Text
      style={[
        pdfStyles.textNormal,
        {
          width: columnWidthsAndAlignment.positionText.width + columnWidthsAndAlignment.objektsummeText.width,
          textAlign: columnWidthsAndAlignment.positionText.textAlign,
          fontWeight: 'bold',
          textDecoration: 'underline',
        },
      ]}
    >
      {data.label}
    </Text>
    <Text
      style={[
        pdfStyles.textNormal,
        { width: columnWidthsAndAlignment.anteilText.width, textAlign: columnWidthsAndAlignment.anteilText.textAlign, fontWeight: 'bold' },
      ]}
    >
      {data.value}
    </Text>
  </View>
);

export default Saldovortrag;
