import { MenuItemInterface } from './ContextSidebar';

/*
 * Looks for the current path in the uri attributes of the menuList and returns the path of the menu item that matches the current path.
 * It has to be recursive because if we have route tabs (tabs whose name appear in the uri) in content area then they are not in the menuList and
 * therefore we have to "navigate back" in the path until we find a match.
 *
 * e.g.:
 * - uri: http://localhost:3000/dashboard/legal-entities/89520298-4373-4b9c-a218-d81b686ab962/invoice-issuer/belegtext
 * - menuList uri for the invoice issuer context menu: http://localhost:3000/dashboard/legal-entities/89520298-4373-4b9c-a218-d81b686ab962/invoice-issuer
 * - belegtext comes from a tab in the content area of the invoice issuer content
 * -> so we have to navigate back on the path until we find http://localhost:3000/dashboard/legal-entities/89520298-4373-4b9c-a218-d81b686ab962/invoice-issuer
 */
export const findSelectedMenuItemPath = (menuList: MenuItemInterface[], menuItemHome: MenuItemInterface | undefined, path: string): string => {
  if (!hasAnyMenuItemTheCurrentPath(menuList, path)) {
    const indexOfLastSlash = path.lastIndexOf('/');
    if (indexOfLastSlash === -1 && menuItemHome) {
      return menuItemHome.uri;
    }
    const pathUntilLastSlash = path.slice(0, indexOfLastSlash);
    return findSelectedMenuItemPath(menuList, menuItemHome, pathUntilLastSlash);
  }
  return path;
};

const hasAnyMenuItemTheCurrentPath = (menuList: MenuItemInterface[], path: string) => menuList.some((menu) => menu.uri === path);
