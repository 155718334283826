import {
  Abrechnungskreis,
  AbrechnungskreisCreateInput,
  AbrechnungskreisType,
  AbrechnungskreisUpdateInput,
  VerrechnungsUstVomAufwand,
} from '../../../types';
import { FormFields } from '../../../helpers/formikHelper';

export interface AbrechnungskreisFormValues {
  bezeichnung: string;
  firmendatenId: string;
  type: AbrechnungskreisType;
  verrechnungUstVomAufwand?: VerrechnungsUstVomAufwand;
}

const abrechnungskreisFormInitialValues: AbrechnungskreisFormValues = {
  bezeichnung: '',
  firmendatenId: '',
  type: AbrechnungskreisType.Bewirtschaftungskosten,
};

export const mapAbrechnungskreisToFormValues = (abrechnungskreis?: Abrechnungskreis): AbrechnungskreisFormValues => {
  if (!abrechnungskreis) {
    return abrechnungskreisFormInitialValues;
  }
  return {
    bezeichnung: abrechnungskreis.bezeichnung,
    firmendatenId: abrechnungskreis.firmendatenId ?? '',
    type: abrechnungskreis.type.value,
    verrechnungUstVomAufwand: abrechnungskreis.verrechnungUstVomAufwand?.value,
  };
};

export const mapFormValuesToAbrechnungskreis = (values: AbrechnungskreisFormValues): AbrechnungskreisCreateInput | AbrechnungskreisUpdateInput => ({
  bezeichnung: values.bezeichnung,
  type: values.type,
  verrechnungUstVomAufwand: values.verrechnungUstVomAufwand,
});

export const abrechnungskreisFormFields: FormFields<AbrechnungskreisFormValues> = {
  bezeichnung: 'bezeichnung',
  type: 'type',
  firmendatenId: 'firmendatenId',
  verrechnungUstVomAufwand: 'verrechnungUstVomAufwand',
};
