import React, { FC } from 'react';
import { Space, Tooltip, Typography } from 'antd';
import { shortenTextIfNeeded, shouldTextBeShortened } from '../../helpers/stringHelper';
import { isTruthy } from '../../helpers/assertionHelper';
import { TOOLTIP_CHILD_TEXT } from '../../constants/testIds';

type Props = {
  maxTextLength: number;
  text: string;
  extraTexts?: string[];
  children?: (shortenedText: string) => React.ReactNode;
  // Set to true when parent component is already wrapped in a tooltip
  useInTooltip?: boolean;
  // Set to true if the text is long and should be split with a custom character to display the text row-wise in the tooltip
  longText?: boolean;
  longTextSplitWith?: string;
  fontWeight?: 'normal' | 'bold';
  tooltipFooter?: React.ReactNode;
};

const DataWithShortenedText: FC<Props> = ({
  maxTextLength,
  text,
  extraTexts = [],
  children,
  useInTooltip,
  longText,
  longTextSplitWith = ',',
  fontWeight = 'normal',
  // Extra ReactNode to be displayed at the end of the tooltip
  tooltipFooter,
}) => {
  const shouldDataTextBeShortened = shouldTextBeShortened(text, maxTextLength);
  const tooltipTitleArray = [
    (shouldDataTextBeShortened && text) || (!shouldDataTextBeShortened && extraTexts.length && text),
    ...extraTexts,
    tooltipFooter,
  ].filter(isTruthy);
  const shortenedText = shortenTextIfNeeded(text, maxTextLength);

  const tooltipChild = children ? (
    <>{children(shortenedText)}</>
  ) : (
    // font color is inherited to match the color set in parent component e.g. within DeviationCellStyledText style in DeviationColumnNotBezugCol
    <Typography.Text data-testid={TOOLTIP_CHILD_TEXT} style={{ color: 'inherit', fontWeight }}>
      {shortenedText}
    </Typography.Text>
  );

  if (useInTooltip) {
    return <>{tooltipChild}</>;
  }

  const toolTipTitle = longText ? (
    <Space direction="vertical" size={0}>
      {tooltipTitleArray
        .filter((title) => typeof title === 'string')
        .toString()
        .split(longTextSplitWith)
        .map((text, index) => (
          <React.Fragment key={index}>{text}</React.Fragment>
        ))}
      {tooltipFooter}
    </Space>
  ) : (
    tooltipTitleArray.map((text, index) => <div key={index}>{text}</div>)
  );

  return tooltipTitleArray.length > 0 ? (
    /* 'display: flex' has to be used in order to avoid default positioning behavior problem of an tooltip when its children is a custom component or a div
        If a child is a custom component or a div, the tooltip will take the whole possible component width and render the tooltip:
        - in the middle of the width (by default)
       - somewhere else if the placement is not the default 'top'

        Examples if we remove display:flex  [Should be FIXED]
          * Zinsliste > Informationen > [Vertragspartner sperren] > Tooltip appears in the middle of the Vertragspartner
          * Zinsliste > Informationen > [columns: 'Aufzugsbetriebskostenakonto' should appear shortened + remove the div with justifyContent from the column] > [Shortened name is left aligned and the tooltip appears in the column center] > The shortened name should be centered, and the Tooltip in the name middle
          * Objekt > ObjektCard > [Rechnungsaussteller sperren] >  Tooltip appears in the middle of the div surrounding the Rechnungsaussteller
          * Objekt > ObjektCard > [maxTextLength für Rechnungsaussteller auf 5 setzen] > Tooltip appears in the middle of the div surrounding the Rechnungsaussteller
          * Objekt > Abrechnungen > [shortened] Bezeichnung > [remove div with justifyContent from code] > Tooltip appears in the column middle
          * Vorschreibung DetailsPage > Positionen > [remove div with justifyContent from code] > [columns: Steuersatz appears left aligned and the tooltip appears in the column center] > Steuersatz should be right aligned, and the Tooltip in the name middle
          * Vertrag > Vorschreibungspositionen >  [remove div with justifyContent from code] > [columns: Steuersatz appears left aligned and the tooltip appears in the column center] > Steuersatz should be right aligned, and the Tooltip in the name middle

     */

    <div style={{ display: 'inline-flex' }}>
      <Tooltip styles={{ root: { maxWidth: '800px' } }} title={toolTipTitle}>
        <div>{tooltipChild}</div>
      </Tooltip>
    </div>
  ) : (
    tooltipChild
  );
};

export default DataWithShortenedText;
