import { FormFields } from '../../../../../../helpers/formikHelper';
import { InfoFeldFormValues } from '../../../../../shared/InfoFeld/infoFeldFormMapper';

export interface KellerabteilCreateFormValues extends InfoFeldFormValues {
  nummer: string;
  text?: string;
}

export const kellerabteilCreateFormFields: FormFields<KellerabteilCreateFormValues> = {
  nummer: 'nummer',
  text: 'text',
  documentList: 'documentList',
};

export const kellerabteilCreateFormInitialValues: KellerabteilCreateFormValues = {
  documentList: [],
  nummer: '',
};
