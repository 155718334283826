import React, { FC } from 'react';
import TableListEmpty from '../../../../components/Helpers/TableListEmpty';
import objektVorschreibungPositionTableColumns from './objektVorschreibungPositionTableColumns';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { ObjektVorschreibungsposition, Verwaltungsart } from '../../../../types';

type ObjektVorschreibungPositionTableProps = {
  vorschreibungsPositionList?: ObjektVorschreibungsposition[];
  loading: boolean;
  objektId: string;
  onCollapse: () => void;
  refetch: () => void;
  verwaltungsart: Verwaltungsart;
};

const ObjektVorschreibungPositionTable: FC<ObjektVorschreibungPositionTableProps> = ({
  vorschreibungsPositionList,
  loading,
  objektId,
  onCollapse,
  refetch,
  verwaltungsart,
}) => (
  <TableWithColSelector<ObjektVorschreibungsposition>
    size="small"
    locale={{
      emptyText: <TableListEmpty onClick={onCollapse} emptyTxt="Keine Vorschreibungsposition vorhanden" btnTxt="Vorschreibungsposition zuweisen" />,
    }}
    scroll={{ x: 900 }}
    loading={loading}
    pagination={false}
    columns={objektVorschreibungPositionTableColumns(objektId, refetch, verwaltungsart)}
    dataSource={vorschreibungsPositionList}
    rowKey={(record) => record.vorschreibungspositionId}
    filterIdentifier="objekt-vorschreibungsposition"
  />
);

export default ObjektVorschreibungPositionTable;
