import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../styles/pdfStyles';
import { ZahlungsrueckstandOtherPersons } from '../../topAbrechnung-types';
import { columnWidthsAndAlignment } from './styles/zahlRueckOtherPers-table-styles';

type Props = {
  list: ZahlungsrueckstandOtherPersons[];
};

const LineList: FC<Props> = ({ list }) => (
  <View style={[pdfStyles.column, pdfStyles.borderBottomSmall, { marginTop: '2mm' }]}>
    {list.map((zahlRueckstOtherPersonsLine, index) => (
      <View style={pdfStyles.row} key={index}>
        <Text style={[pdfStyles.textNormal, { ...columnWidthsAndAlignment.top }]}>{zahlRueckstOtherPersonsLine.top}</Text>
        <Text style={[pdfStyles.textNormal, { ...columnWidthsAndAlignment.vertragspartner }]}>{zahlRueckstOtherPersonsLine.vertragspartner}</Text>
        <Text style={[pdfStyles.textNormal, { ...columnWidthsAndAlignment.offenerBetrag }]}>{zahlRueckstOtherPersonsLine.offenerBetrag}</Text>
      </View>
    ))}
  </View>
);

export default LineList;
