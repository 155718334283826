import dayjs, { Dayjs } from 'dayjs';
import { isNotNil } from './assertionHelper';
import { dayjsCustom } from './dayjsCustom';

const isToday = (date: dayjs.ConfigType) => dayjsCustom(date).isSame(dayjsCustom(), 'day');
const isEntityCurrent = (date?: string | null) => (date ? dayjsCustom().isBefore(dayjsCustom(date)) : true);

const getNextAvailableDateFromToday = (notAvailableDates: Dayjs[]): Dayjs => {
  return getNextAvailableDateFrom(notAvailableDates, dayjsCustom());
};

const getNextAvailableDateFrom = (notAvailableDates: Dayjs[], fromDate: Dayjs): Dayjs => {
  let currentDate = fromDate; // for iteration
  let nextAvailableDate;
  while (!nextAvailableDate) {
    // eslint-disable-next-line no-loop-func
    if (notAvailableDates.some((item) => item.isSame(currentDate, 'day'))) {
      currentDate = currentDate.add(1, 'day');
    } else {
      nextAvailableDate = currentDate;
    }
  }
  return nextAvailableDate;
};

const compareTwoDatesForSorting = (date1: string | null | undefined, date2: string | null | undefined) => {
  if (!isNotNil(date1)) {
    return -1;
  } else if (!isNotNil(date2)) {
    return 1;
  }
  return dayjsCustom(date1).unix() - dayjsCustom(date2).unix();
};

const firstDayOfCurrentMonth = () => dayjsCustom().startOf('month').format('YYYY-MM-DD');

const firstDayOfCurrentYear = () => dayjsCustom().startOf('year');
const lastDayOfCurrentYear = () => dayjsCustom().endOf('year');

const firstDayOfLastYear = () => dayjsCustom().subtract(1, 'year').startOf('year');
const lastDayOfLastYear = () => dayjsCustom().subtract(1, 'year').endOf('year');

export {
  isToday,
  isEntityCurrent,
  getNextAvailableDateFrom,
  getNextAvailableDateFromToday,
  compareTwoDatesForSorting,
  firstDayOfCurrentMonth,
  firstDayOfCurrentYear,
  lastDayOfCurrentYear,
  firstDayOfLastYear,
  lastDayOfLastYear,
};
