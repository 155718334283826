import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import { URI_VERARBEITUNG_VORSTEUERKUERZUNG_ERSTELLEN_DETAILS_PAGE } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import VstKuerzungDetailsPage from './VstKuerzungDetailsPage';

export type VerarbeitungVstKuerzungDetailsPageRouteParams = { generierlaufId: string };

const verarbeitungVstKuerzungDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_VORSTEUERKUERZUNG_ERSTELLEN_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<VstKuerzungDetailsPage />} />}
  />
);

export default verarbeitungVstKuerzungDetailsPageRoute;
