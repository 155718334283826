import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBuchungskreisForRechtstraegerMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type CreateBuchungskreisForRechtstraegerMutation = {
  actionCreateBuchungskreisForRechtstraeger: {
    data: { buchungskreis: boolean };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type AddKontoForRechtstraegerBankDetailsMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['String']['input'];
  bankDetailsId: Types.Scalars['ID']['input'];
}>;

export type AddKontoForRechtstraegerBankDetailsMutation = {
  actionAddKontoForRechtstraegerBankDetails: {
    data: {
      bankname: string;
      bankDetailsId: string;
      bic: string;
      fibuKontoId?: string | null;
      fibuKontonummer?: string | null;
      iban: string;
      accountHolder: string;
      fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type SetAbrechnungsdefinitionForRechnungsausstellerMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  input: Types.SetAbrechnungsdefinitionForRechtstraegerInput;
}>;

export type SetAbrechnungsdefinitionForRechnungsausstellerMutation = {
  actionSetAbrechnungsdefinitionForRechtstraeger: {
    data: { weAbrechnungsdefinition?: { abrechnungsdefinitionId: string } | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SetMahndefinitionForRechtstraegerMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  input: Types.SetMahndefinitionForRechtstraegerInput;
}>;

export type SetMahndefinitionForRechtstraegerMutation = {
  actionSetMahndefinitionForRechtstraeger: {
    data: { mahndefinitionId?: string | null };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UpdateSubAdministrationSettingsMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  input: Types.UpdateSubAdministrationSettingsInput;
}>;

export type UpdateSubAdministrationSettingsMutation = {
  actionUpdateSubAdministrationSettings: {
    data: { subAdministrationSettings?: { paymentClearsIncomingInvoice: boolean; subAdministrationSettingsId: string } | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SetVerwendungForKontoForRechtstraegerBankDetailsMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['String']['input'];
  bankDetailsId: Types.Scalars['ID']['input'];
  input: Types.SetVerwendungForKontoForRechtstraegerInput;
}>;

export type SetVerwendungForKontoForRechtstraegerBankDetailsMutation = {
  actionSetVerwendungForKontoForRechtstraegerBankDetails: {
    data: { bankDetailsId: string; fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung } };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const CreateBuchungskreisForRechtstraegerDocument = gql`
  mutation CreateBuchungskreisForRechtstraeger($rechtstraegerId: ID!) {
    actionCreateBuchungskreisForRechtstraeger(rechtstraegerId: $rechtstraegerId) {
      data {
        buchungskreis
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreateBuchungskreisForRechtstraegerMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBuchungskreisForRechtstraegerMutation, CreateBuchungskreisForRechtstraegerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBuchungskreisForRechtstraegerMutation, CreateBuchungskreisForRechtstraegerMutationVariables>(
    CreateBuchungskreisForRechtstraegerDocument,
    options
  );
}
export type CreateBuchungskreisForRechtstraegerMutationHookResult = ReturnType<typeof useCreateBuchungskreisForRechtstraegerMutation>;
export type CreateBuchungskreisForRechtstraegerMutationResult = Apollo.MutationResult<CreateBuchungskreisForRechtstraegerMutation>;
export type CreateBuchungskreisForRechtstraegerMutationOptions = Apollo.BaseMutationOptions<
  CreateBuchungskreisForRechtstraegerMutation,
  CreateBuchungskreisForRechtstraegerMutationVariables
>;
export const AddKontoForRechtstraegerBankDetailsDocument = gql`
  mutation AddKontoForRechtstraegerBankDetails($rechtstraegerId: String!, $bankDetailsId: ID!) {
    actionAddKontoForRechtstraegerBankDetails(rechtstraegerId: $rechtstraegerId, bankDetailsId: $bankDetailsId) {
      data {
        bankname
        bankDetailsId
        bic
        fibuKontoId
        fibuKontonummer
        fibuStatus {
          text
          value
        }
        iban
        accountHolder
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useAddKontoForRechtstraegerBankDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<AddKontoForRechtstraegerBankDetailsMutation, AddKontoForRechtstraegerBankDetailsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddKontoForRechtstraegerBankDetailsMutation, AddKontoForRechtstraegerBankDetailsMutationVariables>(
    AddKontoForRechtstraegerBankDetailsDocument,
    options
  );
}
export type AddKontoForRechtstraegerBankDetailsMutationHookResult = ReturnType<typeof useAddKontoForRechtstraegerBankDetailsMutation>;
export type AddKontoForRechtstraegerBankDetailsMutationResult = Apollo.MutationResult<AddKontoForRechtstraegerBankDetailsMutation>;
export type AddKontoForRechtstraegerBankDetailsMutationOptions = Apollo.BaseMutationOptions<
  AddKontoForRechtstraegerBankDetailsMutation,
  AddKontoForRechtstraegerBankDetailsMutationVariables
>;
export const SetAbrechnungsdefinitionForRechnungsausstellerDocument = gql`
  mutation setAbrechnungsdefinitionForRechnungsaussteller($rechtstraegerId: ID!, $input: SetAbrechnungsdefinitionForRechtstraegerInput!) {
    actionSetAbrechnungsdefinitionForRechtstraeger(rechtstraegerId: $rechtstraegerId, input: $input) {
      data {
        weAbrechnungsdefinition {
          abrechnungsdefinitionId
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSetAbrechnungsdefinitionForRechnungsausstellerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetAbrechnungsdefinitionForRechnungsausstellerMutation,
    SetAbrechnungsdefinitionForRechnungsausstellerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetAbrechnungsdefinitionForRechnungsausstellerMutation, SetAbrechnungsdefinitionForRechnungsausstellerMutationVariables>(
    SetAbrechnungsdefinitionForRechnungsausstellerDocument,
    options
  );
}
export type SetAbrechnungsdefinitionForRechnungsausstellerMutationHookResult = ReturnType<
  typeof useSetAbrechnungsdefinitionForRechnungsausstellerMutation
>;
export type SetAbrechnungsdefinitionForRechnungsausstellerMutationResult =
  Apollo.MutationResult<SetAbrechnungsdefinitionForRechnungsausstellerMutation>;
export type SetAbrechnungsdefinitionForRechnungsausstellerMutationOptions = Apollo.BaseMutationOptions<
  SetAbrechnungsdefinitionForRechnungsausstellerMutation,
  SetAbrechnungsdefinitionForRechnungsausstellerMutationVariables
>;
export const SetMahndefinitionForRechtstraegerDocument = gql`
  mutation SetMahndefinitionForRechtstraeger($rechtstraegerId: ID!, $input: SetMahndefinitionForRechtstraegerInput!) {
    actionSetMahndefinitionForRechtstraeger(rechtstraegerId: $rechtstraegerId, input: $input) {
      data {
        mahndefinitionId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useSetMahndefinitionForRechtstraegerMutation(
  baseOptions?: Apollo.MutationHookOptions<SetMahndefinitionForRechtstraegerMutation, SetMahndefinitionForRechtstraegerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetMahndefinitionForRechtstraegerMutation, SetMahndefinitionForRechtstraegerMutationVariables>(
    SetMahndefinitionForRechtstraegerDocument,
    options
  );
}
export type SetMahndefinitionForRechtstraegerMutationHookResult = ReturnType<typeof useSetMahndefinitionForRechtstraegerMutation>;
export type SetMahndefinitionForRechtstraegerMutationResult = Apollo.MutationResult<SetMahndefinitionForRechtstraegerMutation>;
export type SetMahndefinitionForRechtstraegerMutationOptions = Apollo.BaseMutationOptions<
  SetMahndefinitionForRechtstraegerMutation,
  SetMahndefinitionForRechtstraegerMutationVariables
>;
export const UpdateSubAdministrationSettingsDocument = gql`
  mutation UpdateSubAdministrationSettings($rechtstraegerId: ID!, $input: UpdateSubAdministrationSettingsInput!) {
    actionUpdateSubAdministrationSettings(rechtstraegerId: $rechtstraegerId, input: $input) {
      data {
        subAdministrationSettings {
          paymentClearsIncomingInvoice
          subAdministrationSettingsId
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateSubAdministrationSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSubAdministrationSettingsMutation, UpdateSubAdministrationSettingsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSubAdministrationSettingsMutation, UpdateSubAdministrationSettingsMutationVariables>(
    UpdateSubAdministrationSettingsDocument,
    options
  );
}
export type UpdateSubAdministrationSettingsMutationHookResult = ReturnType<typeof useUpdateSubAdministrationSettingsMutation>;
export type UpdateSubAdministrationSettingsMutationResult = Apollo.MutationResult<UpdateSubAdministrationSettingsMutation>;
export type UpdateSubAdministrationSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubAdministrationSettingsMutation,
  UpdateSubAdministrationSettingsMutationVariables
>;
export const SetVerwendungForKontoForRechtstraegerBankDetailsDocument = gql`
  mutation SetVerwendungForKontoForRechtstraegerBankDetails(
    $rechtstraegerId: String!
    $bankDetailsId: ID!
    $input: SetVerwendungForKontoForRechtstraegerInput!
  ) {
    actionSetVerwendungForKontoForRechtstraegerBankDetails(rechtstraegerId: $rechtstraegerId, bankDetailsId: $bankDetailsId, input: $input) {
      data {
        bankDetailsId
        fibuVerwendung {
          text
          value
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useSetVerwendungForKontoForRechtstraegerBankDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetVerwendungForKontoForRechtstraegerBankDetailsMutation,
    SetVerwendungForKontoForRechtstraegerBankDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetVerwendungForKontoForRechtstraegerBankDetailsMutation,
    SetVerwendungForKontoForRechtstraegerBankDetailsMutationVariables
  >(SetVerwendungForKontoForRechtstraegerBankDetailsDocument, options);
}
export type SetVerwendungForKontoForRechtstraegerBankDetailsMutationHookResult = ReturnType<
  typeof useSetVerwendungForKontoForRechtstraegerBankDetailsMutation
>;
export type SetVerwendungForKontoForRechtstraegerBankDetailsMutationResult =
  Apollo.MutationResult<SetVerwendungForKontoForRechtstraegerBankDetailsMutation>;
export type SetVerwendungForKontoForRechtstraegerBankDetailsMutationOptions = Apollo.BaseMutationOptions<
  SetVerwendungForKontoForRechtstraegerBankDetailsMutation,
  SetVerwendungForKontoForRechtstraegerBankDetailsMutationVariables
>;
