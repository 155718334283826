import React, { FC, useContext } from 'react';
import { Button, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { IndexationAgreementContext } from '../../Level2/IndexationAgreementContext';
import { useSubmitVertragVposIndexedValueMutation } from '../../../../../../../IndexedValue/gql/VertragVposIndexedValueMutations.types';
import { VertragVposIndexedValue } from '../../../../../../../../types';
import { showSuccessMsgOther } from '../../../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../../../helpers/apolloHelper';
import {
  isVertragVposIndexedValueStatusApplied,
  isVertragVposIndexedValueStatusSubmitted,
} from '../../../../../../../IndexedValue/shared/indexedValueHelpers';

type Props = {
  indexedValue: VertragVposIndexedValue;
  onSubmit: () => void;
};

const ActionSubmit: FC<Props> = ({ indexedValue, onSubmit }) => {
  const { bestandseinheitId, objektId, vertragId, vorschreibungspositionId } = useContext(IndexationAgreementContext);

  const isDisabled =
    isVertragVposIndexedValueStatusSubmitted(indexedValue.status.value) || isVertragVposIndexedValueStatusApplied(indexedValue.status.value);

  const [runSubmit] = useSubmitVertragVposIndexedValueMutation({
    variables: {
      objektId,
      bestandseinheitId,
      vertragId,
      vorschreibungspositionId,
      indexationAgreementId: indexedValue.indexationAgreement.vertragVposIndexationAgreementId,
      indexedValueId: indexedValue.vertragVposIndexedValueId,
    },
    onCompleted: () => {
      showSuccessMsgOther(`Indexierter Wert ${indexedValue.name} angewendet`);
      onSubmit();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Tooltip title="Vorlegen">
      <Button
        type="link"
        icon={<ExclamationCircleOutlined style={{ color: !isDisabled ? '#FAAD14' : 'inherit' }} />}
        onClick={() => runSubmit()}
        disabled={isDisabled}
      />
    </Tooltip>
  );
};

export default ActionSubmit;
