import React, { FC } from 'react';
import AddressAndContactList from './List/AddressAndContactList';
import AddressesAndContactsBtnAndDrawer from './AddressesAndContactsBtnAndDrawer';
import { Spacer } from '../../../components/Grid';
import { RechtstraegerType } from '../../../types';
import { useRechtstraegerAddressesAndContactsQuery } from './gql/AddressesAndContactsQueries.types';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { useOnRechtstraegerDataChangeEvent } from '../useOnRechtstraegerDataChangeEvent';

type Props = {
  rechtstraegerId: string;
  rechtstraegerType: RechtstraegerType;
};

const AddressesAndContactsListing: FC<Props> = ({ rechtstraegerId, rechtstraegerType }) => {
  const { activeForFirmendatenId } = useFDAuthorized();
  const { data, refetch } = useRechtstraegerAddressesAndContactsQuery({ variables: { rechtstraegerId } });
  const addressesAndContacts = data?.getRechtstraegerAddressesAndContacts.data ?? undefined;

  useOnRechtstraegerDataChangeEvent('rechtstraegerAddresses', refetch, rechtstraegerId);

  return (
    <>
      <AddressesAndContactsBtnAndDrawer rechtstraegerId={rechtstraegerId} onSuccess={refetch} rechtstraegerType={rechtstraegerType} />
      <Spacer height={16} />
      <AddressAndContactList
        addressesAndContacts={addressesAndContacts}
        firmendatenId={activeForFirmendatenId}
        rechtstraegerId={rechtstraegerId}
        onSuccess={refetch}
      />
    </>
  );
};

export default AddressesAndContactsListing;
