import { FormFields } from '../../../../helpers/formikHelper';
import { FirmendatenBuchhaltungEinstellungUpdateInput } from '../../../../types';

export interface FilterFormValues {
  heAbrechnungVerrechnungskontoId?: string | null;
  subAdministrationBillingAccountId?: string | null;
}

export const formInitialValues = (
  heAbrechnungVerrechnungskontoId?: string | null,
  subAdministrationBillingAccountId?: string | null
): FilterFormValues => ({
  heAbrechnungVerrechnungskontoId,
  subAdministrationBillingAccountId,
});

export const formFormFields: FormFields<FilterFormValues> = {
  heAbrechnungVerrechnungskontoId: 'heAbrechnungVerrechnungskontoId',
  subAdministrationBillingAccountId: 'subAdministrationBillingAccountId',
};

export const mapFormValuesToInput = (values: FilterFormValues): FirmendatenBuchhaltungEinstellungUpdateInput => ({
  heAbrechnungVerrechnungskontoId: values.heAbrechnungVerrechnungskontoId,
  subAdministrationBillingAccountId: values.subAdministrationBillingAccountId,
});
