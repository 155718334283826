import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import { RechtstraegerFieldsFragmentDoc } from '../../../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import { BankDetailsFieldsFragmentDoc } from '../../../../BankDetails/gql/BankDetailsFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HeVertragZahlungsregelListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  heVertragId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type HeVertragZahlungsregelListQuery = {
  getHeVertragZahlungsregelList: {
    data: Array<{
      beendetAm?: string | null;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable: boolean;
      durchfuehrenAm: number;
      fixbetrag?: number | null;
      heAbrechnungsdefinitionId?: string | null;
      naechsteZahlung?: string | null;
      prozentsatz?: number | null;
      verwendungszweck?: string | null;
      zahlungsreferenz?: string | null;
      zahlungsregelId: string;
      periode: { text: string; value: Types.Turnus };
      status: { text: string; value: Types.HeVertragZahlungsregelStatus };
      turnus?: { text: string; value: Types.Turnus } | null;
      type: { text: string; value: Types.HeVertragZahlungsregelType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      zahlungsempfaenger: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fibuKontoCreatable: boolean;
        kundennummer: string;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        bankDetailsList: Array<{
          accountHolder: string;
          accountLimit?: number | null;
          assignedHeVertragZahlungsregelMap: any;
          assignedObjektMap: any;
          bankDetailsId: string;
          bankname: string;
          bic: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          ebicsCreditTransferUserList: Array<string>;
          ebicsDirectDebitUserList: Array<string>;
          fibuKontoId?: string | null;
          fibuKontonummer?: string | null;
          iban: string;
          updatedByMitarbeiterId?: string | null;
          fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
          fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
          sepaMandat?: {
            fileId?: string | null;
            fileName?: string | null;
            sepaMandatId: string;
            sepaMandatReference: string;
            signatureDate?: string | null;
            firmendaten?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firmendatenId: string;
              kundenSystemId: string;
              kurzBezeichnung: string;
              name1: string;
              name2?: string | null;
              name3?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechnungsAussteller?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
            warningList: Array<{ message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        personenbezugList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          personenbezugId: string;
          tagList: Array<string>;
          person: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechtstraegerSteuerpflicht?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          historicizedList?: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ message: string; type: string }>;
          }> | null;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
        sprache: { text: string; value: Types.Sprache };
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { value: Types.RechtstraegerType; text: string };
        vertragSummary?: {
          heVertragCount: number;
          heVertragVerwaltungCount: number;
          mietVertragCount: number;
          mietVertragVerwaltungCount: number;
          weSubverwaltungCount: number;
          weVertragCount: number;
          weVertragVerwaltungCount: number;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      zahlungsempfaengerBankDetails?: {
        accountHolder: string;
        accountLimit?: number | null;
        assignedHeVertragZahlungsregelMap: any;
        assignedObjektMap: any;
        bankDetailsId: string;
        bankname: string;
        bic: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        ebicsCreditTransferUserList: Array<string>;
        ebicsDirectDebitUserList: Array<string>;
        fibuKontoId?: string | null;
        fibuKontonummer?: string | null;
        iban: string;
        updatedByMitarbeiterId?: string | null;
        fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
        fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
        sepaMandat?: {
          fileId?: string | null;
          fileName?: string | null;
          sepaMandatId: string;
          sepaMandatReference: string;
          signatureDate?: string | null;
          firmendaten?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firmendatenId: string;
            kundenSystemId: string;
            kurzBezeichnung: string;
            name1: string;
            name2?: string | null;
            name3?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechnungsAussteller?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
          warningList: Array<{ message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type HeVertragZahlungsregelQueryVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  heVertragId: Types.Scalars['String']['input'];
  zahlungsregelId: Types.Scalars['ID']['input'];
}>;

export type HeVertragZahlungsregelQuery = {
  getHeVertragZahlungsregel: {
    data: {
      beendetAm?: string | null;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable: boolean;
      durchfuehrenAm: number;
      fixbetrag?: number | null;
      heAbrechnungsdefinitionId?: string | null;
      naechsteZahlung?: string | null;
      prozentsatz?: number | null;
      verwendungszweck?: string | null;
      zahlungsreferenz?: string | null;
      zahlungsregelId: string;
      periode: { text: string; value: Types.Turnus };
      status: { text: string; value: Types.HeVertragZahlungsregelStatus };
      turnus?: { text: string; value: Types.Turnus } | null;
      type: { text: string; value: Types.HeVertragZahlungsregelType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      zahlungsempfaenger: { rechtstraegerId: string; kurzBezeichnung: string };
      zahlungsempfaengerBankDetails?: { bankname: string; bankDetailsId: string; bic: string; iban: string; accountHolder: string } | null;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type HeVertragZahlungsregelPeriodeListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type HeVertragZahlungsregelPeriodeListQuery = {
  getHeVertragZahlungsregelPeriodeList: {
    data: Array<{ text: string; value: Types.Turnus }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const HeVertragZahlungsregelListDocument = gql`
  query HeVertragZahlungsregelList($objektId: String!, $heVertragId: ID!, $includeArchiviert: Boolean) {
    getHeVertragZahlungsregelList(objektId: $objektId, heVertragId: $heVertragId, includeArchiviert: $includeArchiviert) {
      data {
        beendetAm
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        deletable
        durchfuehrenAm
        fixbetrag
        heAbrechnungsdefinitionId
        naechsteZahlung
        periode {
          text
          value
        }
        prozentsatz
        status {
          text
          value
        }
        turnus {
          text
          value
        }
        type {
          text
          value
        }
        verwendungszweck
        warningList {
          attribute
          message
          type
        }
        zahlungsempfaenger {
          ...RechtstraegerFields
        }
        zahlungsempfaengerBankDetails {
          ...BankDetailsFields
        }
        zahlungsreferenz
        zahlungsregelId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RechtstraegerFieldsFragmentDoc}
  ${BankDetailsFieldsFragmentDoc}
`;
export function useHeVertragZahlungsregelListQuery(
  baseOptions: Apollo.QueryHookOptions<HeVertragZahlungsregelListQuery, HeVertragZahlungsregelListQueryVariables> &
    ({ variables: HeVertragZahlungsregelListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HeVertragZahlungsregelListQuery, HeVertragZahlungsregelListQueryVariables>(HeVertragZahlungsregelListDocument, options);
}
export function useHeVertragZahlungsregelListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HeVertragZahlungsregelListQuery, HeVertragZahlungsregelListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HeVertragZahlungsregelListQuery, HeVertragZahlungsregelListQueryVariables>(HeVertragZahlungsregelListDocument, options);
}
export function useHeVertragZahlungsregelListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HeVertragZahlungsregelListQuery, HeVertragZahlungsregelListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HeVertragZahlungsregelListQuery, HeVertragZahlungsregelListQueryVariables>(
    HeVertragZahlungsregelListDocument,
    options
  );
}
export type HeVertragZahlungsregelListQueryHookResult = ReturnType<typeof useHeVertragZahlungsregelListQuery>;
export type HeVertragZahlungsregelListLazyQueryHookResult = ReturnType<typeof useHeVertragZahlungsregelListLazyQuery>;
export type HeVertragZahlungsregelListSuspenseQueryHookResult = ReturnType<typeof useHeVertragZahlungsregelListSuspenseQuery>;
export type HeVertragZahlungsregelListQueryResult = Apollo.QueryResult<HeVertragZahlungsregelListQuery, HeVertragZahlungsregelListQueryVariables>;
export const HeVertragZahlungsregelDocument = gql`
  query HeVertragZahlungsregel($objektId: String!, $heVertragId: String!, $zahlungsregelId: ID!) {
    getHeVertragZahlungsregel(objektId: $objektId, heVertragId: $heVertragId, zahlungsregelId: $zahlungsregelId) {
      data {
        beendetAm
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        deletable
        durchfuehrenAm
        fixbetrag
        heAbrechnungsdefinitionId
        naechsteZahlung
        periode {
          text
          value
        }
        prozentsatz
        status {
          text
          value
        }
        turnus {
          text
          value
        }
        type {
          text
          value
        }
        verwendungszweck
        warningList {
          attribute
          message
          type
        }
        zahlungsempfaenger {
          rechtstraegerId
          kurzBezeichnung
        }
        zahlungsempfaengerBankDetails {
          bankname
          bankDetailsId
          bic
          iban
          accountHolder
        }
        zahlungsreferenz
        zahlungsregelId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useHeVertragZahlungsregelQuery(
  baseOptions: Apollo.QueryHookOptions<HeVertragZahlungsregelQuery, HeVertragZahlungsregelQueryVariables> &
    ({ variables: HeVertragZahlungsregelQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HeVertragZahlungsregelQuery, HeVertragZahlungsregelQueryVariables>(HeVertragZahlungsregelDocument, options);
}
export function useHeVertragZahlungsregelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HeVertragZahlungsregelQuery, HeVertragZahlungsregelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HeVertragZahlungsregelQuery, HeVertragZahlungsregelQueryVariables>(HeVertragZahlungsregelDocument, options);
}
export function useHeVertragZahlungsregelSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HeVertragZahlungsregelQuery, HeVertragZahlungsregelQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HeVertragZahlungsregelQuery, HeVertragZahlungsregelQueryVariables>(HeVertragZahlungsregelDocument, options);
}
export type HeVertragZahlungsregelQueryHookResult = ReturnType<typeof useHeVertragZahlungsregelQuery>;
export type HeVertragZahlungsregelLazyQueryHookResult = ReturnType<typeof useHeVertragZahlungsregelLazyQuery>;
export type HeVertragZahlungsregelSuspenseQueryHookResult = ReturnType<typeof useHeVertragZahlungsregelSuspenseQuery>;
export type HeVertragZahlungsregelQueryResult = Apollo.QueryResult<HeVertragZahlungsregelQuery, HeVertragZahlungsregelQueryVariables>;
export const HeVertragZahlungsregelPeriodeListDocument = gql`
  query HeVertragZahlungsregelPeriodeList {
    getHeVertragZahlungsregelPeriodeList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useHeVertragZahlungsregelPeriodeListQuery(
  baseOptions?: Apollo.QueryHookOptions<HeVertragZahlungsregelPeriodeListQuery, HeVertragZahlungsregelPeriodeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HeVertragZahlungsregelPeriodeListQuery, HeVertragZahlungsregelPeriodeListQueryVariables>(
    HeVertragZahlungsregelPeriodeListDocument,
    options
  );
}
export function useHeVertragZahlungsregelPeriodeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HeVertragZahlungsregelPeriodeListQuery, HeVertragZahlungsregelPeriodeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HeVertragZahlungsregelPeriodeListQuery, HeVertragZahlungsregelPeriodeListQueryVariables>(
    HeVertragZahlungsregelPeriodeListDocument,
    options
  );
}
export function useHeVertragZahlungsregelPeriodeListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<HeVertragZahlungsregelPeriodeListQuery, HeVertragZahlungsregelPeriodeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HeVertragZahlungsregelPeriodeListQuery, HeVertragZahlungsregelPeriodeListQueryVariables>(
    HeVertragZahlungsregelPeriodeListDocument,
    options
  );
}
export type HeVertragZahlungsregelPeriodeListQueryHookResult = ReturnType<typeof useHeVertragZahlungsregelPeriodeListQuery>;
export type HeVertragZahlungsregelPeriodeListLazyQueryHookResult = ReturnType<typeof useHeVertragZahlungsregelPeriodeListLazyQuery>;
export type HeVertragZahlungsregelPeriodeListSuspenseQueryHookResult = ReturnType<typeof useHeVertragZahlungsregelPeriodeListSuspenseQuery>;
export type HeVertragZahlungsregelPeriodeListQueryResult = Apollo.QueryResult<
  HeVertragZahlungsregelPeriodeListQuery,
  HeVertragZahlungsregelPeriodeListQueryVariables
>;
