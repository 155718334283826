import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateIncomingCallLogMutationVariables = Types.Exact<{
  input: Types.CallLogCreateInput;
}>;

export type CreateIncomingCallLogMutation = {
  createIncomingCallLog: {
    data: Array<{
      rechtstraeger: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      type: { text: string; value: Types.CallLogType };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateOutgoingCallLogMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  contactId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  phoneNumber?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type CreateOutgoingCallLogMutation = {
  createOutgoingCallLog: {
    data: { callTs: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateCallLogMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  callLogId: Types.Scalars['ID']['input'];
  input: Types.CallLogUpdateInput;
}>;

export type UpdateCallLogMutation = {
  updateCallLog: {
    data: { callLogId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateIncomingCallLogDocument = gql`
  mutation CreateIncomingCallLog($input: CallLogCreateInput!) {
    createIncomingCallLog(input: $input) {
      data {
        rechtstraeger {
          ...RechtstraegerBaseFields
        }
        type {
          text
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
export function useCreateIncomingCallLogMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateIncomingCallLogMutation, CreateIncomingCallLogMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateIncomingCallLogMutation, CreateIncomingCallLogMutationVariables>(CreateIncomingCallLogDocument, options);
}
export type CreateIncomingCallLogMutationHookResult = ReturnType<typeof useCreateIncomingCallLogMutation>;
export type CreateIncomingCallLogMutationResult = Apollo.MutationResult<CreateIncomingCallLogMutation>;
export type CreateIncomingCallLogMutationOptions = Apollo.BaseMutationOptions<CreateIncomingCallLogMutation, CreateIncomingCallLogMutationVariables>;
export const CreateOutgoingCallLogDocument = gql`
  mutation CreateOutgoingCallLog($rechtstraegerId: ID!, $contactId: ID, $phoneNumber: String) {
    createOutgoingCallLog(rechtstraegerId: $rechtstraegerId, contactId: $contactId, phoneNumber: $phoneNumber) {
      data {
        callTs
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateOutgoingCallLogMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOutgoingCallLogMutation, CreateOutgoingCallLogMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOutgoingCallLogMutation, CreateOutgoingCallLogMutationVariables>(CreateOutgoingCallLogDocument, options);
}
export type CreateOutgoingCallLogMutationHookResult = ReturnType<typeof useCreateOutgoingCallLogMutation>;
export type CreateOutgoingCallLogMutationResult = Apollo.MutationResult<CreateOutgoingCallLogMutation>;
export type CreateOutgoingCallLogMutationOptions = Apollo.BaseMutationOptions<CreateOutgoingCallLogMutation, CreateOutgoingCallLogMutationVariables>;
export const UpdateCallLogDocument = gql`
  mutation UpdateCallLog($rechtstraegerId: ID!, $callLogId: ID!, $input: CallLogUpdateInput!) {
    updateCallLog(rechtstraegerId: $rechtstraegerId, callLogId: $callLogId, input: $input) {
      data {
        callLogId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateCallLogMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCallLogMutation, UpdateCallLogMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCallLogMutation, UpdateCallLogMutationVariables>(UpdateCallLogDocument, options);
}
export type UpdateCallLogMutationHookResult = ReturnType<typeof useUpdateCallLogMutation>;
export type UpdateCallLogMutationResult = Apollo.MutationResult<UpdateCallLogMutation>;
export type UpdateCallLogMutationOptions = Apollo.BaseMutationOptions<UpdateCallLogMutation, UpdateCallLogMutationVariables>;
