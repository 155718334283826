import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import React from 'react';
import { FieldArrayRenderProps, FormikProps } from 'formik';
import { ColumnProps } from 'antd/es/table';
import {
  abrDefAbrKreisFormFields,
  AbrDefAbrKreisFormValues,
  abrDefAbrKreisListingFormFields,
  AbrDefAbrKreisListingFormValues,
} from './abrDefAbrKreisListingFormMapper';
import { FormikFieldHookProps } from '../../../../helpers/formikHelper';
import { AbrechnungsdefinitionType, AbrechnungskreisType } from '../../../../types';
import { isAbrKreisTypeInformation, isAbrKreisTypeRepFonds, isAbrKreisTypeVstKuerzung } from './abrDefAbrKreisListingFormHelpers';
import AbrechnungskreisSelect from '../../../AbrechnungskreisSelect/AbrechnungskreisSelect';
import AufteilungsschluesselSelect from '../../../AufteilungsschluesselSelect/AufteilungsschluesselSelect';
import VorschreibungspositionSelect from '../../../VorschreibungspositionSelect/VorschreibungspositionSelect';

const weAndBkAbrDefAbrKreisTableColumns = (
  abrDefType: AbrechnungsdefinitionType,
  formikProps: FormikProps<AbrDefAbrKreisListingFormValues>,
  firmendatenId: string,
  arrayHelpers: FieldArrayRenderProps,
  onAbrechnungskreisChange?: (newAbrechnungskreisId: string, fieldProps: FormikFieldHookProps<string>, onSuccess: () => void) => void,
  onZuweisungDelete?: (abrechnungskreisId: string, onDelete: () => void) => void
): ColumnProps<AbrDefAbrKreisFormValues>[] => [
  {
    title: 'Abrechnungskreis',
    key: abrDefAbrKreisFormFields.abrechnungskreisId,
    width: 280,
    render: (text, record, i) => {
      const abrechnungskreisType = `${abrDefAbrKreisListingFormFields.abrechdefAbrechkreisList}.${i}.${abrDefAbrKreisFormFields.abrechnungskreisType}`;
      const aufteilungsschluesselId = `${abrDefAbrKreisListingFormFields.abrechdefAbrechkreisList}.${i}.${abrDefAbrKreisFormFields.aufteilungsschluesselId}`;
      const vorschreibungspositionId = `${abrDefAbrKreisListingFormFields.abrechdefAbrechkreisList}.${i}.${abrDefAbrKreisFormFields.vorschreibungspositionId}`;

      const changeAbrKreisTypeAndAufteilungsschluessel = (
        newAbrechnungskreisId: string,
        fieldProps: FormikFieldHookProps<string>,
        abrKreisType?: AbrechnungskreisType
      ) => {
        const onSuccess = () => {
          if (abrKreisType) {
            formikProps.setFieldValue(abrechnungskreisType, abrKreisType);
            const isRepFondOrVstKuerzung = isAbrKreisTypeVstKuerzung(abrKreisType) || isAbrKreisTypeRepFonds(abrKreisType);
            if (isRepFondOrVstKuerzung) {
              formikProps.setFieldValue(aufteilungsschluesselId, '');
            } else if (isAbrKreisTypeInformation(abrKreisType)) {
              formikProps.setFieldValue(aufteilungsschluesselId, '');
              formikProps.setFieldValue(vorschreibungspositionId, '');
            }
          }
        };
        if (onAbrechnungskreisChange) {
          onAbrechnungskreisChange(newAbrechnungskreisId, fieldProps, onSuccess);
        } else {
          onSuccess();
        }
      };

      return (
        <AbrechnungskreisSelect
          key={i}
          name={`${abrDefAbrKreisListingFormFields.abrechdefAbrechkreisList}.${i}.${abrDefAbrKreisFormFields.abrechnungskreisId}`}
          firmendatenId={firmendatenId}
          abrDefType={abrDefType}
          onChange={changeAbrKreisTypeAndAufteilungsschluessel}
          allowClear={false}
          style={{ width: '100%' }}
        />
      );
    },
  },
  {
    title: 'Aufteilungsschlüssel',
    key: abrDefAbrKreisFormFields.aufteilungsschluesselId,
    render: (text, record, i) => {
      const isDisabled =
        isAbrKreisTypeVstKuerzung(record.abrechnungskreisType) ||
        isAbrKreisTypeRepFonds(record.abrechnungskreisType) ||
        isAbrKreisTypeInformation(record.abrechnungskreisType);
      return (
        <AufteilungsschluesselSelect
          key={i}
          firmendatenId={firmendatenId}
          name={`${abrDefAbrKreisListingFormFields.abrechdefAbrechkreisList}.${i}.${abrDefAbrKreisFormFields.aufteilungsschluesselId}`}
          allowClear={false}
          disabled={isDisabled}
          style={{ width: '100%' }}
        />
      );
    },
  },
  {
    title: 'Vorschreibungsposition',
    key: abrDefAbrKreisFormFields.vorschreibungspositionId,
    render: (text, record, i) => {
      const isDisabled = isAbrKreisTypeInformation(record.abrechnungskreisType);
      return (
        <VorschreibungspositionSelect
          key={i}
          name={`${abrDefAbrKreisListingFormFields.abrechdefAbrechkreisList}.${i}.${abrDefAbrKreisFormFields.vorschreibungspositionId}`}
          firmendatenId={firmendatenId}
          allowClear={false}
          disabled={isDisabled}
          style={{ width: '100%' }}
        />
      );
    },
  },
  {
    key: 'actions',
    render: (text, record, index) => (
      <Button
        style={{ border: 'none' }}
        icon={<DeleteOutlined />}
        name={abrDefAbrKreisListingFormFields.abrechdefAbrechkreisList}
        onClick={() => {
          const removeItem = () => arrayHelpers.remove(index);
          onZuweisungDelete ? onZuweisungDelete(record.abrechnungskreisId, removeItem) : removeItem();
        }}
      />
    ),
  },
];

export default weAndBkAbrDefAbrKreisTableColumns;
