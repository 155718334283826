import React, { FC } from 'react';
import { Flex } from 'rebass';
import { Button, Drawer, Typography } from 'antd';
import { useTimelineOpen } from '../../../components/Timeline/useTimelineOpen';
import ContextMenu from '../../../components/Timeline/ContextMenu';
import { capitalizeString } from '../../../helpers/stringHelper';
import SteuerpflichtVersionTimeline from './Steuerpflicht/SteuerpflichtVersionTimeline';
import { RechtstraegerSteuerpflicht } from '../../../types';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';

type FinanzSteuerparameterInfoProps = {
  rechtstraegerId: string;
  rechtstraegerSteuerpflicht?: RechtstraegerSteuerpflicht | null;
  reloadRechtstraeger: () => void;
};

const FinanzSteuerparameterInfo: FC<FinanzSteuerparameterInfoProps> = ({ rechtstraegerId, rechtstraegerSteuerpflicht, reloadRechtstraeger }) => {
  const { timelineDrawer, openTimelineUpdateDrawer, closeTimelineDrawer } = useTimelineOpen();

  return (
    <>
      <Flex justifyContent="space-between">
        <Typography.Title level={5}>Finanz/Steuerparameter</Typography.Title>
        <ContextMenu openDrawerCreate={openTimelineUpdateDrawer} withHistory />
      </Flex>
      <CardDetailsInfoRow infoRowTitle="Steuerpflicht">
        <Typography.Text>
          {rechtstraegerSteuerpflicht?.steuerpflicht.text ? capitalizeString(rechtstraegerSteuerpflicht?.steuerpflicht.text) : 'Keine Angaben'}
        </Typography.Text>
      </CardDetailsInfoRow>
      <Drawer
        title="Bearbeiten"
        width={720}
        open={timelineDrawer.visible}
        onClose={closeTimelineDrawer}
        destroyOnClose
        footer={
          <ButtonsAligned usage={ButtonsAlignedUsage.drawerFooter}>
            <Button onClick={closeTimelineDrawer} type="primary">
              Schließen
            </Button>
          </ButtonsAligned>
        }
      >
        <SteuerpflichtVersionTimeline rechtstraegerId={rechtstraegerId} reloadRechtstraeger={reloadRechtstraeger} />
      </Drawer>
    </>
  );
};

export default FinanzSteuerparameterInfo;
