import React, { FC } from 'react';
import { Empty } from 'antd';
import { FieldArray, FormikProps } from 'formik';
import { AuszifferungBlockFormValues, auszifferungFormFields, OffenePostenForBooking } from '../../../auszifferungFormMapper';
import { useTableExpandedRowKeys } from '../../../../../../../../components/Table/shared/useTableExpandedRowKeys';
import TableWithColSelector from '../../../../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { offenePostenTableColumns } from '../../shared/Level1/offenePostenTableColumns';
import AuszifferungListTableFromBooking from '../Level2/AuszifferungListTableFromBooking';
import { FibuBuchungType } from '../../../../../../../../types';

type Props = {
  bookingZahlungId: string;
  buchungType: FibuBuchungType;
  formikProps: FormikProps<AuszifferungBlockFormValues>;
};

const OffenePostenListTableFromBooking: FC<Props> = ({ bookingZahlungId, buchungType, formikProps }) => {
  const { expandedRowKeys, toggleExpandedRowKey } = useTableExpandedRowKeys();
  const { offenePostenListFromBooking: auszifferungList } = formikProps.values;

  const fieldNamePrefix = auszifferungFormFields.offenePostenListFromBooking;

  return (
    <FieldArray
      name={fieldNamePrefix}
      render={() => {
        const toggleExpRowKey = (rowKey: string) => {
          !expandedRowKeys.includes(rowKey) && toggleExpandedRowKey(rowKey);
        };

        return (
          <TableWithColSelector<OffenePostenForBooking>
            size="small"
            locale={{
              emptyText: <Empty description="Keine Auszifferungsvorschläge gefunden" />,
            }}
            dataSource={auszifferungList}
            columns={offenePostenTableColumns(fieldNamePrefix, toggleExpRowKey, bookingZahlungId, false, buchungType)}
            rowKey={(record) => record.offenePostenId}
            expandable={{
              expandedRowKeys,
              onExpand: (expanded, record) => toggleExpandedRowKey(record.offenePostenId),
              expandedRowRender: (record, index) => (
                <AuszifferungListTableFromBooking bookingZahlungId={bookingZahlungId} formikProps={formikProps} offenePosten={record} />
              ),
            }}
            filterIdentifier="main-zahlungserfassung-offene-posten"
          />
        );
      }}
    />
  );
};

export default OffenePostenListTableFromBooking;
