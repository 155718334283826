import React, { FC } from 'react';
import { MenuProps } from 'antd';
import { HistoryOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { FieldArrayRenderProps, FormikProps } from 'formik';
import { MahnungUpdateFormValues } from '../mahnungUpdateFormMapper';
import { useToggle } from '../../../../../hooks/useToggle';
import { radixActionStyles } from '../../../../../helpers/radixIconsStyles';
import HistoryModal, { HistoryType } from '../../../../History/shared/HistoryModal';
import MahnpositionChangeHistoryListingTable from './MahnpositionChangeHistoryListingTable';
import ActionDropdown from '../../../../../components/Dropdown/ActionDropdown';

type Props = {
  mahnungId: string;
  mahnpositionId: string;
  index: number;
  formikProps: FormikProps<MahnungUpdateFormValues>;
  arrayHelpers: FieldArrayRenderProps;
  isStatusErstellt: boolean;
};

const MahnpositionActions: FC<Props> = ({ mahnungId, mahnpositionId, index, formikProps, arrayHelpers, isStatusErstellt }) => {
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();
  const areMoreThanOneMahnPositionAvailable = formikProps.values.mahnPositionList.length > 1;

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Entfernen',
      onClick: () => (areMoreThanOneMahnPositionAvailable ? arrayHelpers.remove(index) : null),
      icon: <MinusCircleOutlined />,
      disabled: !areMoreThanOneMahnPositionAvailable || !isStatusErstellt,
    },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
  ];

  return (
    <>
      <ActionDropdown items={items} />
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <MahnpositionChangeHistoryListingTable mahnungId={mahnungId} mahnPositionId={mahnpositionId} />
      </HistoryModal>
    </>
  );
};

export default MahnpositionActions;
