import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { AbrechnungsdefinitionType } from '../../../../../../types';
import { useBkAbrechnungsdefinitionTemplateVersionQuery } from '../../../../../../features/AbrechnungsdefinitionTemplate/gql/BkAbrDef/BkAbrDefTemplateVersionQueries.types';
import BkOrWeAbrDefTemplateVersionView from '../../../../../../features/AbrechnungsdefinitionTemplate/Version/View/BkOrWeAbrDefTemplateVersionView';

type Props = {
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersionId: string;
  isHistoricized: boolean;
};
const AndromedaSysSettingsBkAbrechnungsdefinitionVersionViewPage: FC<Props> = ({
  abrechnungsdefinitionId,
  abrechnungsdefinitionVersionId,
  isHistoricized,
}) => {
  const { data } = useBkAbrechnungsdefinitionTemplateVersionQuery({
    variables: { abrechnungsdefinitionId, abrechnungsdefinitionVersionId, searchInHistory: isHistoricized },
  });
  const abrechnungsdefinitionVersion = data?.getBkAbrechnungsdefinitionTemplateVersion.data;

  if (!abrechnungsdefinitionVersion) return <Skeleton active />;

  return (
    <BkOrWeAbrDefTemplateVersionView
      abrDefType={AbrechnungsdefinitionType.BkAbrechnung}
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      abrechnungsdefinitionVersion={abrechnungsdefinitionVersion}
      isHistoricized={isHistoricized}
    />
  );
};

export default AndromedaSysSettingsBkAbrechnungsdefinitionVersionViewPage;
