import React, { FC } from 'react';
import { Spin, TabsProps } from 'antd';
import RechnungsAusstellerAbrDefListing from './AbrechnungsdefinitionListing/RechnungsAusstellerAbrDefListing';
import RechnungsAusstellerKontoListing from './KontoListing/RechnungsAusstellerKontoListing';
import { useRechnungsAusstellerQuery } from './gql/RechnungsAusstellerQueries.types';
import { pathsToRechtstraegerDetails } from '../../rechtstraegerHelper';
import RechnungsAusstellerMahnDefListing from './MahndefinitionListing/Table/RechnungsAusstellerMahnDefListing';
import TabPaneContent from '../../../../components/Tabs/TabPaneContent';
import RechnungsAusstellerRepFondsListing from './RepFondsListing/RechnungsAusstellerRepFondsListing';
import RouteTabs from '../../../../components/Tabs/RouteTabs';
import RechnungsAusstellerBelegnummernkreisListing from './Belegnummernkreis/RechnungsAusstellerBelegnummernkreisListing';
import RechnungsAusstellerBelegtextListing from './Belegtext/RechnungsAusstellerBelegtextListing';
import { Firma, NatuerlichePerson, Personengemeinschaft } from '../../../../types';
import RechnungsAusstellerGeneralInfo from './RechnungsAusstellerGeneralInfo';

type Props = {
  rechtstraeger: Firma | NatuerlichePerson | Personengemeinschaft;
  onSuccess: () => void;
};

const RechnungsAussteller: FC<Props> = ({ rechtstraeger, onSuccess }) => {
  const { data: maybeRechnungsAussteller, refetch } = useRechnungsAusstellerQuery({
    variables: { rechtstraegerId: rechtstraeger.rechtstraegerId },
  });
  const rechnungsAussteller = maybeRechnungsAussteller?.getRechnungsAussteller.data;

  const paths = pathsToRechtstraegerDetails(rechtstraeger.rechtstraegerId);

  if (!rechnungsAussteller) {
    return <Spin />;
  }

  const items: TabsProps['items'] = [
    {
      key: paths.rechnungAusstellerGeneralTab,
      label: 'Allgemein',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <RechnungsAusstellerGeneralInfo
            rechtstraegerId={rechtstraeger.rechtstraegerId}
            rechnungsAussteller={rechnungsAussteller}
            onAction={refetch}
          />
        </TabPaneContent>
      ),
    },
    {
      key: paths.rechnungAusstellerKontenTab,
      label: 'Konten',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <RechnungsAusstellerKontoListing
            rechnungsAussteller={rechnungsAussteller}
            rechtstraeger={rechtstraeger}
            onSuccess={() => {
              onSuccess();
              refetch();
            }}
          />
        </TabPaneContent>
      ),
    },
    {
      key: paths.rechnungAusstellerAbrDefTab,
      label: 'Abrechnungsdefinitionen',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <RechnungsAusstellerAbrDefListing
            onSuccess={refetch}
            rechnungsAussteller={rechnungsAussteller}
            rechtstraegerId={rechtstraeger.rechtstraegerId}
          />
        </TabPaneContent>
      ),
    },
    {
      key: paths.rechnungAusstellerMahnDefTab,
      label: 'Mahndefinition',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <RechnungsAusstellerMahnDefListing
            rechnungsAussteller={rechnungsAussteller}
            rechtstraegerId={rechtstraeger.rechtstraegerId}
            onSuccess={refetch}
          />
        </TabPaneContent>
      ),
    },
    {
      key: paths.rechnungAustellerRepFondsDefTab,
      label: 'Rep.Fonds',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <RechnungsAusstellerRepFondsListing rechnungsAussteller={rechnungsAussteller} rechtstraegerId={rechtstraeger.rechtstraegerId} />
        </TabPaneContent>
      ),
    },
    {
      key: paths.rechnungAustellerBelegnummernkreisDefTab,
      label: 'Belegnummernkreis',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <RechnungsAusstellerBelegnummernkreisListing rechnungsAussteller={rechnungsAussteller} rechtstraegerId={rechtstraeger.rechtstraegerId} />
        </TabPaneContent>
      ),
    },
    {
      key: paths.rechnungAustellerBelegtextTab,
      label: 'Beleg Text',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <RechnungsAusstellerBelegtextListing rechtstraegerId={rechtstraeger.rechtstraegerId} rechtstraeger={rechtstraeger} />
        </TabPaneContent>
      ),
    },
  ];
  return <RouteTabs items={items} />;
};

export default RechnungsAussteller;
