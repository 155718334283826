import { useEffect, useRef, useCallback, DependencyList, EffectCallback } from 'react';

/*
 * useEffectAfterMount is a custom React hook that executes side effects only after the initial render.
 * This hook is useful for running side effects only on component updates, avoiding the initial render.
 */
function useEffectAfterMount(effect: EffectCallback, deps: DependencyList) {
  const isMounted = useRef(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedEffect = useCallback(effect, deps);

  useEffect(() => {
    if (isMounted.current) {
      return memoizedEffect();
    } else {
      isMounted.current = true;
      return undefined;
    }
  }, [memoizedEffect]);
}

export default useEffectAfterMount;
