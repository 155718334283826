import React, { FC } from 'react';
import { useSaldenlisteSummaryQuery } from '../gql/SaldenlisteQueries.types';
import saldenlisteSummaryTableColumns from './saldenlisteSummaryTableColumns';
import { SaldenlisteKontoType, SaldenlisteYearSummary } from '../../../types';
import TableWithColSelector from '../../../components/Table/TableWithColSelector/TableWithColSelector';

type SaldenlisteSummaryTableProps = {
  buchungskreisId: string;
  date: string;
  objektId?: string;
  kontoType?: SaldenlisteKontoType;
};

const SaldenlisteSummaryTable: FC<SaldenlisteSummaryTableProps> = ({ buchungskreisId, objektId, date, kontoType }) => {
  const { data, loading } = useSaldenlisteSummaryQuery({ variables: { buchungskreisId, objektId, buchungsmonat: date, type: kontoType } });
  const saldenlisteYearSummary = data?.getSaldenlisteSummary.data;

  return saldenlisteYearSummary ? (
    <TableWithColSelector<SaldenlisteYearSummary>
      size="small"
      loading={loading}
      pagination={false}
      columns={saldenlisteSummaryTableColumns(saldenlisteYearSummary.saldoForMonatList)}
      dataSource={[saldenlisteYearSummary]}
      // rowKey does not matter because we have only 1 element in the table
      rowKey={(record) => record.saldoEB}
      filterIdentifier="main-saldenliste-summary"
    />
  ) : null;
};

export default SaldenlisteSummaryTable;
