import React, { FC, useEffect } from 'react';
import { Empty } from 'antd';
import { PaymentClearing, PaymentClearingBooking } from '../../../../../types';
import NestedTable from '../../../../../components/Table/NestedTable/NestedTable';
import { usePaymentClearingBookingListQuery } from '../../../gql/PaymentClearingQueries.types';
import bookingTableColumns from './bookingTableColumns';

type Props = {
  paymentClearing: PaymentClearing;
  isRowExpanded: boolean;
  vertragspartnerId: string;
  vertragspartnerName: string;
};

const BookingTable: FC<Props> = ({ paymentClearing, isRowExpanded, vertragspartnerId, vertragspartnerName }) => {
  const { data, loading, refetch } = usePaymentClearingBookingListQuery({
    variables: {
      buchungskreisId: paymentClearing.buchungskreisId,
      vertragspartnerId: paymentClearing.vertragspartnerId,
      kontoId: paymentClearing.kontoId,
    },
  });

  const bookingList = data?.getPaymentClearingBookingList.data || [];

  // UseEffect to refetch data when row gets expanded
  useEffect(() => {
    if (isRowExpanded) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRowExpanded]);

  return (
    <NestedTable<PaymentClearingBooking>
      level={2}
      columns={bookingTableColumns(vertragspartnerId, vertragspartnerName)}
      dataSource={bookingList}
      loading={loading}
      pagination={false}
      rowKey={(record) => record.buchungId}
      locale={{
        emptyText: <Empty description={<span>Keine Zahlungen vorhanden</span>} />,
      }}
      expandable={{ rowExpandable: () => false }}
    />
  );
};

export default BookingTable;
