import { PaymentCreateInput, PaymentMethod } from '../../../../types';
import { FormFields } from '../../../../helpers/formikHelper';

export type PaymentCreateFormValues = {
  amount?: number | null;
};

export const paymentCreateFormInitialValues = (outstandingAmount?: number | null): PaymentCreateFormValues => ({
  amount: outstandingAmount || null,
});

export const paymentCreateFormFields: FormFields<PaymentCreateFormValues> = {
  amount: 'amount',
};

export const mapFormValuesToPayment = (
  values: PaymentCreateFormValues,
  belegId: string,
  paymentMethod: PaymentMethod,
  auftragId?: string,
  standingInvoicePartialAmountId?: string | null
): PaymentCreateInput => ({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  amount: values.amount!,
  belegId,
  paymentMethod,
  auftragId,
  standingInvoicePartialAmountId,
});
