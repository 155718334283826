import React, { FC } from 'react';
import { Spin } from 'antd';
import TemplateAbrechnung from '../TemplateAbrechnung';
import TopAbrechnungTemplate from '../../../../../pages/PDFTemplates/templates/topAbrechnung/TopAbrechnungTemplate';
import { createTopAbrechnungTemplatePDFData, mapTextbausteinToTopAbrechnungSelectableSection } from '../WeTemplates/weAbrDefTemplateFormTopAbrMapper';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import { useFDAuthorized } from '../../../../../shared/Auth/useAuthorized';
import { useFirmendatenQuery } from '../../../../KundenSystem/Firmendaten/gql/FirmendatenQueries.types';
import { useBelegTexteFirmendatenQuery } from '../../../../Belegtext/gql/BelegtexteQueries.types';
import { useFirmendatenOperatingSiteListQuery } from '../../../../KundenSystem/Firmendaten/gql/FirmendatenOperatingSiteQueries.types';
import { BelegTextePlatzhalter } from '../../../../Belegtext/Form/belegTexteFormTypes';
import { findBelegTexteHauptAddress, findBelegTexteHauptEmail, findBelegTextePhoneNumber } from '../../../../shared/operatingSiteHelpers';
import { TopAbrDefGeneralSettingsFormValues } from './BkOrWe/TopAbrDefGeneralSettingsForm/topAbrDefGeneralSettingsFormMapper';

type TemplateTopAbrechnungProps = {
  values: TextbausteinFormValues[];
  onTemplateChange: (templateValues: TextbausteinFormValues[]) => void;
  topAbrDefGeneralSettingsValues: TopAbrDefGeneralSettingsFormValues;
};

const TemplateTopAbrechnung: FC<TemplateTopAbrechnungProps> = ({ values, onTemplateChange, topAbrDefGeneralSettingsValues }) => {
  const { activeForFirmendatenId: firmendatenId } = useFDAuthorized();

  const { data } = useFirmendatenQuery({ variables: { firmendatenId } });
  const firmendaten = data?.getFirmendaten.data;

  const { data: belegTexteData } = useBelegTexteFirmendatenQuery({ variables: { firmendatenId } });

  const belegTexte = belegTexteData?.getBelegTexteFirmendaten.data;

  const { data: firmendatenOperatingSiteListData } = useFirmendatenOperatingSiteListQuery({ variables: { firmendatenId } });
  const operatingSiteList = firmendatenOperatingSiteListData?.getFirmendatenOperatingSiteList.data ?? [];

  if (!firmendaten || !belegTexte || !operatingSiteList) {
    return <Spin />;
  }

  const belegTextePlatzhalter: BelegTextePlatzhalter = {
    hauptAddress: findBelegTexteHauptAddress(operatingSiteList),
    vatIdentificationNumber: firmendaten.vatIdentificationNumber,
    companyRegistrationNumber: firmendaten.companyRegistrationNumber,
    phoneNumber: findBelegTextePhoneNumber(operatingSiteList),
    email: findBelegTexteHauptEmail(operatingSiteList),
  };

  return (
    <TemplateAbrechnung
      values={values}
      onTemplateChange={onTemplateChange}
      renderPreview={({ selectedTextbaustein }) => (
        <TopAbrechnungTemplate
          data={createTopAbrechnungTemplatePDFData(
            values,
            topAbrDefGeneralSettingsValues,
            selectedTextbaustein,
            belegTexte,
            belegTextePlatzhalter,
            firmendatenId
          )}
          isInEditMode
          selectedTextbaustein={mapTextbausteinToTopAbrechnungSelectableSection(selectedTextbaustein)}
        />
      )}
    />
  );
};

export default TemplateTopAbrechnung;
