import React, { FC } from 'react';
import { Button, Dropdown, MenuProps, Modal } from 'antd';
import { DownOutlined, ImportOutlined } from '@ant-design/icons';
import { handleImportBankstatementEbicsSubmit } from './handleSubmit';
import { useFDAuthorized } from '../../../../shared/Auth/useAuthorized';
import { useToggle } from '../../../../hooks/useToggle';
import BankstatementOnlineBankingForm from './OnlineBankingForm/BankstatementOnlineBankingForm';

type Props = {
  onAction: () => void;
};

const BankstatementBtnAndModal: FC<Props> = ({ onAction }) => {
  const { activeForFirmendatenId } = useFDAuthorized();

  const [isOnlineBankingImportModalOpen, toggleOnlineBankingImportModal] = useToggle(false);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Kontoauszug Onlinebanking importieren',
      onClick: toggleOnlineBankingImportModal,
      icon: <ImportOutlined />,
    },
    {
      key: '2',
      label: 'Kontoauszug EBICS importieren',
      icon: <ImportOutlined />,
      onClick: () => handleImportBankstatementEbicsSubmit(activeForFirmendatenId, onAction),
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <Button icon={<DownOutlined />} type="primary">
          Kontoauszug importieren
        </Button>
      </Dropdown>
      <Modal
        title="Kontoauszug Onlinebanking importieren"
        open={isOnlineBankingImportModalOpen}
        destroyOnClose
        footer={false}
        maskClosable={false}
        onCancel={toggleOnlineBankingImportModal}
      >
        <BankstatementOnlineBankingForm onAction={toggleOnlineBankingImportModal} />
      </Modal>
    </>
  );
};

export default BankstatementBtnAndModal;
