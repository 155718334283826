import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateHeAbrechnungsdefinitionForKsMutationVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
  input: Types.HeAbrechnungsdefinitionCreateInput;
}>;

export type CreateHeAbrechnungsdefinitionForKsMutation = {
  createHeAbrechnungsdefinitionForKS: {
    data: { abrechnungsdefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateHeAbrechnungsdefinitionForFdMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  input: Types.HeAbrechnungsdefinitionCreateInput;
}>;

export type CreateHeAbrechnungsdefinitionForFdMutation = {
  createHeAbrechnungsdefinitionForFD: {
    data: { abrechnungsdefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateHeAbrechnungsdefinitionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  input: Types.HeAbrechnungsdefinitionUpdateInput;
}>;

export type UpdateHeAbrechnungsdefinitionMutation = {
  updateHeAbrechnungsdefinition: {
    data: { abrechnungsdefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateHeAbrechnungsdefinitionForKsDocument = gql`
  mutation CreateHeAbrechnungsdefinitionForKS($kundenSystemId: ID!, $input: HeAbrechnungsdefinitionCreateInput!) {
    createHeAbrechnungsdefinitionForKS(kundenSystemId: $kundenSystemId, input: $input) {
      data {
        abrechnungsdefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateHeAbrechnungsdefinitionForKsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateHeAbrechnungsdefinitionForKsMutation, CreateHeAbrechnungsdefinitionForKsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateHeAbrechnungsdefinitionForKsMutation, CreateHeAbrechnungsdefinitionForKsMutationVariables>(
    CreateHeAbrechnungsdefinitionForKsDocument,
    options
  );
}
export type CreateHeAbrechnungsdefinitionForKsMutationHookResult = ReturnType<typeof useCreateHeAbrechnungsdefinitionForKsMutation>;
export type CreateHeAbrechnungsdefinitionForKsMutationResult = Apollo.MutationResult<CreateHeAbrechnungsdefinitionForKsMutation>;
export type CreateHeAbrechnungsdefinitionForKsMutationOptions = Apollo.BaseMutationOptions<
  CreateHeAbrechnungsdefinitionForKsMutation,
  CreateHeAbrechnungsdefinitionForKsMutationVariables
>;
export const CreateHeAbrechnungsdefinitionForFdDocument = gql`
  mutation CreateHeAbrechnungsdefinitionForFD($firmendatenId: ID!, $input: HeAbrechnungsdefinitionCreateInput!) {
    createHeAbrechnungsdefinitionForFD(firmendatenId: $firmendatenId, input: $input) {
      data {
        abrechnungsdefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateHeAbrechnungsdefinitionForFdMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateHeAbrechnungsdefinitionForFdMutation, CreateHeAbrechnungsdefinitionForFdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateHeAbrechnungsdefinitionForFdMutation, CreateHeAbrechnungsdefinitionForFdMutationVariables>(
    CreateHeAbrechnungsdefinitionForFdDocument,
    options
  );
}
export type CreateHeAbrechnungsdefinitionForFdMutationHookResult = ReturnType<typeof useCreateHeAbrechnungsdefinitionForFdMutation>;
export type CreateHeAbrechnungsdefinitionForFdMutationResult = Apollo.MutationResult<CreateHeAbrechnungsdefinitionForFdMutation>;
export type CreateHeAbrechnungsdefinitionForFdMutationOptions = Apollo.BaseMutationOptions<
  CreateHeAbrechnungsdefinitionForFdMutation,
  CreateHeAbrechnungsdefinitionForFdMutationVariables
>;
export const UpdateHeAbrechnungsdefinitionDocument = gql`
  mutation UpdateHeAbrechnungsdefinition($abrechnungsdefinitionId: ID!, $input: HeAbrechnungsdefinitionUpdateInput!) {
    updateHeAbrechnungsdefinition(abrechnungsdefinitionId: $abrechnungsdefinitionId, input: $input) {
      data {
        abrechnungsdefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateHeAbrechnungsdefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateHeAbrechnungsdefinitionMutation, UpdateHeAbrechnungsdefinitionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateHeAbrechnungsdefinitionMutation, UpdateHeAbrechnungsdefinitionMutationVariables>(
    UpdateHeAbrechnungsdefinitionDocument,
    options
  );
}
export type UpdateHeAbrechnungsdefinitionMutationHookResult = ReturnType<typeof useUpdateHeAbrechnungsdefinitionMutation>;
export type UpdateHeAbrechnungsdefinitionMutationResult = Apollo.MutationResult<UpdateHeAbrechnungsdefinitionMutation>;
export type UpdateHeAbrechnungsdefinitionMutationOptions = Apollo.BaseMutationOptions<
  UpdateHeAbrechnungsdefinitionMutation,
  UpdateHeAbrechnungsdefinitionMutationVariables
>;
