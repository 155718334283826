type Nil = undefined | null;
const isNil = (x: any): x is Nil => x === null || x === undefined;
export type PartialNull<T> = {
  [P in keyof T]?: T[P] | null;
};

export default class Maybe<T> {
  private constructor(private value: T | Nil) {}

  static some<T>(value: T) {
    return new Maybe(value);
  }

  static none<T>() {
    return new Maybe<T>(null);
  }

  static fromValue<T>(value: T | Nil) {
    return value ? Maybe.some(value) : Maybe.none<T>();
  }

  map<R>(f: (wrapped: T) => R): Maybe<R> {
    if (isNil(this.value)) {
      return Maybe.none<R>();
    } else {
      return Maybe.some(f(this.value));
    }
  }

  flatMap<R>(f: (wrapped: T) => Maybe<R>): Maybe<R> {
    if (isNil(this.value)) {
      return Maybe.none<R>();
    } else {
      return f(this.value);
    }
  }

  valueOr(defaultValue: T): T {
    return this.value ? this.value : defaultValue;
  }

  valueOrUndefined(): T | undefined {
    return this.value ? this.value : undefined;
  }
}
