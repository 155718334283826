import { FormFields } from '../../../../../helpers/formikHelper';
import { EbicsUserStatus, GenerierlaufEntryExitCode } from '../../../../../types';
import { TSyncEbicsUserVerarbeitungQueryParams } from './filtersQueryParams';

export type SyncEbicsUserVerarbeitungEntryFiltersFormValues = {
  mitarbeiterId?: string | null;
  teilnehmer?: string | null;
  userId?: string | null;
  partnerId?: string | null;
  bankname?: string | null;
  ebicsStatus?: EbicsUserStatus | null;
  exitCodeList?: GenerierlaufEntryExitCode[] | null;
};

export const syncEbicsUserVerarbeitungEntryFiltersFormFields: FormFields<SyncEbicsUserVerarbeitungEntryFiltersFormValues> = {
  mitarbeiterId: 'mitarbeiterId',
  teilnehmer: 'teilnehmer',
  userId: 'userId',
  partnerId: 'partnerId',
  bankname: 'bankname',
  ebicsStatus: 'ebicsStatus',
  exitCodeList: 'exitCodeList',
};

export const syncEbicsUserVerarbEntryListingFiltersFormInitialValues = (
  queryParams: TSyncEbicsUserVerarbeitungQueryParams
): SyncEbicsUserVerarbeitungEntryFiltersFormValues => ({
  mitarbeiterId: queryParams.mitarbeiterId,
  teilnehmer: queryParams.teilnehmer,
  userId: queryParams.userId,
  partnerId: queryParams.partnerId,
  bankname: queryParams.bankname,
  ebicsStatus: queryParams.ebicsStatus,
  exitCodeList: queryParams.exitCodeList,
});
