import React, { FC } from 'react';
import { Button, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../../hooks/useToggle';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';
import VstKuerzungCreateForm from '../Form/Create/VstKuerzungCreateForm';

type Props = {
  objektId: string;
  objektVerwaltungSeit: string;
};

const VstKuerzungErstellenBtnAndModal: FC<Props> = ({ objektId, objektVerwaltungSeit }) => {
  const [isCollapsed, onCollapse] = useToggle();

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <Button type="primary" size="small" icon={<PlusOutlined />} onClick={onCollapse}>
          Vorsteuerkürzung berechnen
        </Button>
      </ButtonsAligned>
      <Modal
        title="Vorsteuerkürzung Berechnung erstellen"
        onCancel={onCollapse}
        open={isCollapsed}
        destroyOnClose
        maskClosable={false}
        footer={false}
      >
        <VstKuerzungCreateForm onSuccess={onCollapse} onCancel={onCollapse} objektId={objektId} verwaltungSeit={objektVerwaltungSeit} />
      </Modal>
    </>
  );
};

export default VstKuerzungErstellenBtnAndModal;
