import * as Yup from 'yup';
import { entityIsRequired, entityMaxCharLength } from '../../../components/message/validationMsg';

/* eslint-disable no-template-curly-in-string */
export const salutationValidationSchema = Yup.string().nullable();

export const firstnameValidationSchema = Yup.string().required(entityIsRequired('Vorname')).max(30, entityMaxCharLength('Vorname', 30));

export const lastnameValidationSchema = Yup.string().required(entityIsRequired('Nachname')).max(30, entityMaxCharLength('Nachname', 30));

export const titleValidationSchema = Yup.string().nullable().max(30, entityMaxCharLength('Titel', 30));

export const titleTrailingValidationSchema = Yup.string().nullable().max(30, entityMaxCharLength('Titel nachlaufend', 30));

export const personPartValidationSchema = Yup.object().shape({
  salutation: salutationValidationSchema,
  title: titleValidationSchema,
  firstname: firstnameValidationSchema,
  lastname: lastnameValidationSchema,
  titleTrailing: titleTrailingValidationSchema,
});

/* eslint-enable no-template-curly-in-string */
