import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../styles/pdfStyles';
import { SubAdminAbrTopDetailsBlock } from '../subAdministrationAbrechnungTop-types';
import DetailsColumnsTitle from '../../shared/DetailsTable/DetailsColumnsTitle';
import Saldovortrag from '../../shared/DetailsTable/Saldovortrag';
import PositionBlock from '../../shared/DetailsTable/PositionBlock';
import Zwischensumme from '../../shared/DetailsTable/Zwischensumme';
import EigentuemerverrechnungBlock from '../../shared/DetailsTable/EigentuemerverrechnungBlock';
import LabelValue from '../../../shared/LabelValue';

const BestandseinheitBlockTable: FC<{
  bestandseinheitBlock: SubAdminAbrTopDetailsBlock;
}> = ({ bestandseinheitBlock }) => (
  <View>
    <View style={[pdfStyles.tablePaddingLeftRight, { marginTop: '3mm' }]}>
      {/* Details Table */}
      <DetailsColumnsTitle title={bestandseinheitBlock.positionListHeader.positionText} />
      <Saldovortrag data={bestandseinheitBlock.saldovortrag} />
    </View>

    {bestandseinheitBlock.bestandseinheitList.map((bestandseinheit, index) => (
      <View style={[pdfStyles.tablePaddingLeftRight, { marginTop: '3mm' }]} key={index}>
        <Text style={[{ fontWeight: 'bold', backgroundColor: '#dfdfdf', fontSize: 10 }]}>{bestandseinheit.bezeichnung}</Text>

        {/* Einnahmen, Ausgaben and VstKuerzung */}
        {bestandseinheit.einnahmen ? <PositionBlock data={bestandseinheit.einnahmen} /> : null}
        {bestandseinheit.ausgaben ? <PositionBlock data={bestandseinheit.ausgaben} /> : null}
        {bestandseinheit.vorsteuerkuerzung ? <PositionBlock data={bestandseinheit.vorsteuerkuerzung} /> : null}

        {/* Zwischensumme */}
        {bestandseinheit.einnahmen || bestandseinheit.ausgaben || bestandseinheit.vorsteuerkuerzung ? (
          <Zwischensumme data={bestandseinheit.zwischensumme} />
        ) : null}

        {/* Eigentuemerverrechnung */}
        {bestandseinheit.eigentuemerverrechnung ? <EigentuemerverrechnungBlock data={bestandseinheit.eigentuemerverrechnung} /> : null}

        {/* Ergebnis Abrechnung */}
        <View style={[pdfStyles.column, { marginTop: '5mm' }]}>
          <LabelValue
            valueTuple={bestandseinheit.bestandseinheitErgebnis}
            viewStyle={{ ...pdfStyles.borderBottomSmall, borderBottomWidth: 2 }}
            labelStyle={{ width: '70%', fontWeight: 'bold' }}
            valueStyle={{ width: '30%', textAlign: 'right', fontWeight: 'bold' }}
          />
        </View>
      </View>
    ))}
  </View>
);

export default BestandseinheitBlockTable;
