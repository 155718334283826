import React, { FC } from 'react';
import { Button, Tooltip } from 'antd';
import { EditOutlined, HistoryOutlined } from '@ant-design/icons';
import HistoryModal, { HistoryType } from '../../../../../../../History/shared/HistoryModal';
import { useToggle } from '../../../../../../../../hooks/useToggle';
import ActionApply from './ActionApply';
import { VertragVposIndexedValue } from '../../../../../../../../types';
import ActionDoNotApply from './ActionDoNotApply';
import ActionSubmit from './ActionSubmit';
import ActionDelete from './ActionDelete';
import IndexedValueChangeHistoryListingTable from './IndexedValueChangeHistoryListingTable';
import {
  isVertragVposIndexedValueStatusApplied,
  isVertragVposIndexedValueStatusNotApplied,
} from '../../../../../../../IndexedValue/shared/indexedValueHelpers';
import { useEditableTable } from '../../../../../../../../components/Table/EditableTableV2/editableTableContext';

type Props = {
  indexedValue: VertragVposIndexedValue;
  onAction: () => void;
};

const IndexedValueTableActions: FC<Props> = ({ indexedValue, onAction }) => {
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const { onUpdate } = useEditableTable<VertragVposIndexedValue>();

  return (
    <>
      <ActionApply indexedValue={indexedValue} onSubmit={onAction} />
      <ActionDoNotApply indexedValue={indexedValue} onSubmit={onAction} />
      <ActionSubmit indexedValue={indexedValue} onSubmit={onAction} />

      <Tooltip title="Bearbeiten">
        <Button
          type="link"
          icon={<EditOutlined />}
          disabled={
            isVertragVposIndexedValueStatusApplied(indexedValue.status.value) || isVertragVposIndexedValueStatusNotApplied(indexedValue.status.value)
          }
          onClick={() => {
            onUpdate(indexedValue);
          }}
        />
      </Tooltip>

      <ActionDelete indexedValue={indexedValue} onSubmit={onAction} />

      <Tooltip title="Änderungsverlauf">
        <Button type="link" icon={<HistoryOutlined />} onClick={toggleChangeHistoryOpen} data-testid="aufteilungsschluesselWert-btn-history" />
      </Tooltip>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <IndexedValueChangeHistoryListingTable indexedValue={indexedValue} />
      </HistoryModal>
    </>
  );
};

export default IndexedValueTableActions;
