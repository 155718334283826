import { get } from 'lodash';
import React, { FC } from 'react';
import { Avatar, Button, Card, Col, Divider, Drawer, Dropdown, MenuProps, Row, Typography } from 'antd';
import { EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { personToString } from '../../shared/components/Person/personHelper';
import EmailVerified from '../../shared/components/Email/EmailVerified';
import EmailUpdateForm from '../../shared/components/Email/EmailUpdateForm';
import { isStatusEingeladen } from '../../helpers/statusHelper';
import { useToggle } from '../../hooks/useToggle';
import { usePasswordResetUserMutation } from '../../graphql/Authentication/UserMutation.types';
import { showSuccessMsgCreate } from '../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../helpers/apolloHelper';
import MitarbeiterUpdateForm from './Form/Update/MitarbeiterUpdateForm';
import { useResendEinladungMitarbeiterMutation } from './gql/MitarbeiterMutations.types';
import { Mitarbeiter } from '../../types';
import { useAuth } from '../../shared/Auth/authContext';

type Props = {
  firmendatenId: string;
  mitarbeiter: Mitarbeiter;
  onSuccess: () => void;
};

const MitarbeiterCard: FC<Props> = ({ mitarbeiter, firmendatenId, onSuccess }) => {
  const [isCollapsed, onCollapse] = useToggle();
  const { activeForFirmendaten } = useAuth();

  const items: MenuProps['items'] = [
    { key: '1', label: 'Bearbeiten', onClick: onCollapse, icon: <EditOutlined /> },
    { key: '2', label: 'Passwort zurücksetzen', onClick: () => runPasswordResetUser({ variables: { username: mitarbeiter.username } }) },
    {
      key: '3',
      label: 'Mitarbeiter erneut einladen',
      onClick: () =>
        runResendEinladungMitarbeiter({
          variables: {
            firmendatenId,
            mitarbeiterId: mitarbeiter.mitarbeiterId,
          },
        }),
      disabled: !isStatusEingeladen(get(mitarbeiter, 'status')),
    },
  ];

  const [runResendEinladungMitarbeiter] = useResendEinladungMitarbeiterMutation({
    onCompleted: () => {
      showSuccessMsgCreate(`Eine E-Mail zum Aktualisieren des Passwortes wurde verschickt.`);
      onCollapse();
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runPasswordResetUser] = usePasswordResetUserMutation({
    onCompleted: () => {
      showSuccessMsgCreate(`Eine E-Mail zum Aktualisieren des Passwortes wurde verschickt.`);
      onCollapse();
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <>
      <Card
        title={personToString(mitarbeiter)}
        extra={
          <Dropdown menu={{ items }}>
            <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
          </Dropdown>
        }
      >
        <Card.Meta
          avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
          title={personToString(mitarbeiter)}
          description={mitarbeiter.status.text}
        />
        <Divider dashed />
        <b>Username:</b> {mitarbeiter.user?.email}
        <br />
        <b>E-Mail:</b> <EmailVerified email={mitarbeiter.user?.email} emailUnverified={mitarbeiter.user?.emailUnverified} />
        <Divider dashed />
        <Typography.Title level={5}>General Information</Typography.Title>
        Abteilungen
        <br />
        Berechtigungen
        <br />
        Aktivitätsprotokoll
        <br />
        Änderungsprotokoll
        <br />
        Spracheinstellungen
      </Card>
      <Drawer
        width={720}
        title="Profil bearbeiten"
        open={isCollapsed}
        onClose={onCollapse}
        destroyOnClose
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={onCollapse} type="primary">
              Schließen
            </Button>
          </div>
        }
      >
        <Row align="top">
          <Col span={6}>
            <Typography.Title level={5}>Zur Person</Typography.Title>
          </Col>
          <Col span={18}>
            <MitarbeiterUpdateForm
              mitarbeiter={mitarbeiter}
              onSuccess={onSuccess}
              onCancel={onCollapse}
              firmendatenId={firmendatenId}
              /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
              kundenSystemId={activeForFirmendaten!.kundenSystemId}
            />
          </Col>
        </Row>
        <Divider />
        <Row align="top">
          <Col span={6}>
            <Typography.Title level={5}>E-Mail-Adresse</Typography.Title>
          </Col>
          <Col span={18}>
            <EmailUpdateForm mitarbeiter={mitarbeiter} user={null} onCancel={onCollapse} onSuccess={onSuccess} />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default MitarbeiterCard;
