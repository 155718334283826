import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { generatePathToBestandseinheitDetailsPage } from '../../Bestandseinheit/bestandseinheitUriPaths';
import { Bestandseinheit } from '../../../types';

type Props = {
  objektId: string;
  bestandseinheitList: Bestandseinheit[];
};

const OffenePostenBestandseinheitColumn: FC<Props> = ({ objektId, bestandseinheitList }) => {
  if (bestandseinheitList.length === 1) {
    return (
      <DataWithShortenedText maxTextLength={MAX_TEXT_LENGTH} text={bestandseinheitList[0].bezeichnung}>
        {(shortenedText) => (
          <Link to={generatePathToBestandseinheitDetailsPage(objektId, bestandseinheitList[0].bestandseinheitId)}>{shortenedText}</Link>
        )}
      </DataWithShortenedText>
    );
  }

  const extraTexteForTooltip = bestandseinheitList.map((be) => be.bezeichnung);

  return (
    <DataWithShortenedText maxTextLength={MAX_TEXT_LENGTH} text={bestandseinheitList[0].bezeichnung} extraTexts={extraTexteForTooltip}>
      {(shortenedText) => (extraTexteForTooltip.length >= 1 ? `${shortenedText}...` : shortenedText)}
    </DataWithShortenedText>
  );
};

const MAX_TEXT_LENGTH = 20;

export default OffenePostenBestandseinheitColumn;
