import React from 'react';
import { Tag } from 'antd';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { compareTwoStringsForSorting } from '../../../../helpers/stringHelper';
import { Kontierungstabelle } from '../../../../types';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import DataWithStatus from '../../../../components/Helpers/DataWithStatus';
import { archivedOrNormalStyle, StatusEnum } from '../../../../helpers/statusHelper';
import StatusBadge from '../../../../components/Status/StatusBadge';
import KontierungstabelleTemplateTableActions from './KontierungstabelleTemplateTableActions';
import { NestedTableWithColSelectorColProps } from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';

export const kontierungstabelleTemplateTableColumns = (refetch: () => void): NestedTableWithColSelectorColProps<Kontierungstabelle>[] => [
  {
    title: 'Bezeichnung',
    key: 'bezeichnung',
    width: 200,
    defaultSortOrder: 'ascend',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.bezeichnung, b.bezeichnung),
    render: (value, record) => (
      <DataWithStatus status={record.status} text={record.bezeichnung} showStatusDescription={false} showBadgeDot={false} maxTextLength={25} />
    ),
  },
  {
    title: 'Basiskonto',
    key: 'basiskonto',
    defaultSelected: true,
    sorter: (a, b) => a.basiskonto - b.basiskonto,
    render: (value, record) => (
      <Tag key={record.basiskonto} style={archivedOrNormalStyle(record.status)}>
        {record.basiskonto}
      </Tag>
    ),
  },
  {
    title: 'Status',
    defaultSelected: true,
    filters: [
      { text: 'Aktiv', value: StatusEnum.Aktiv },
      { text: 'Archiviert', value: StatusEnum.Archiviert },
    ],
    onFilter: (value, record) => record.status.value.includes(value as string),
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },
  {
    title: 'Erstellt am',
    key: 'erstelltAm',
    render: (text, record) => (
      <DataWithStatus status={record.status} text="" showBadgeDot={false} showStatusDescription={false}>
        {() => <FormattedDateTime createdTs={record.createTs} />}
      </DataWithStatus>
    ),
  },
  {
    title: 'Ersteller',
    key: 'ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Aktion',
    key: 'action',
    defaultSelected: true,
    render: (text, record) => <KontierungstabelleTemplateTableActions kontierungstabelle={record} refetch={refetch} />,
  },
];
