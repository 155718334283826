import { BelegTexte, TextbausteinType } from '../../../../../types';
import { TopAbrechnung, TopAbrechnungList } from '../../../../../pages/PDFTemplates/templates/topAbrechnung/topAbrechnung-types';
import { TopAbrechnungSelectableSection } from '../../../../../pages/PDFTemplates/templates/topAbrechnung/TopAbrechnungTemplate';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import { findTextbausteinByType, replacePlatzhalterValuesWithExampleValues } from '../../../../../components/Template/PDFTemplates/templateHelpers';
import { einleitungstextPlatzhalter } from '../../../../../components/Template/PDFTemplates/templateDataExamples';
import { BelegTextePlatzhalter } from '../../../../Belegtext/Form/belegTexteFormTypes';
import {
  getDocumentFooterWithoutTextbaustein,
  getDocumentHeaderWithoutTextbaustein,
} from '../../../../shared/Belegtext/Template/belegTexteWithoutTextbausteinTemplateHelper';
import { TopAbrDefGeneralSettingsFormValues } from '../shared/BkOrWe/TopAbrDefGeneralSettingsForm/topAbrDefGeneralSettingsFormMapper';

export const createTopAbrechnungTemplatePDFData = (
  textbausteinList: TextbausteinFormValues[],
  topAbrDefGeneralSettingsValues: TopAbrDefGeneralSettingsFormValues,
  selectedTextbausteinType?: TextbausteinType,
  belegTexte?: BelegTexte | undefined,
  belegTextePlatzhalter?: BelegTextePlatzhalter | undefined,
  firmendatenId?: string | undefined
): TopAbrechnungList => {
  const einleitungstext = findTextbausteinByType(textbausteinList, TextbausteinType.Einleitungstext);
  const ergebnistext = findTextbausteinByType(textbausteinList, findSelectedErgebnisTextbausteinVariantType(selectedTextbausteinType));
  const schlusstext = findTextbausteinByType(textbausteinList, TextbausteinType.Schlusstext);

  return {
    type: 'TOP_ABRECHNUNG',
    topAbrechnungList: [
      {
        ...topAbrechnungExample(
          topAbrDefGeneralSettingsValues.considerRueckstand,
          topAbrDefGeneralSettingsValues.displayDueDate,
          topAbrDefGeneralSettingsValues.displayZahlungsverlauf,
          topAbrDefGeneralSettingsValues.displayRueckstandOtherPersons
        ),
        deckblatt: {
          ...topAbrechnungExample(
            topAbrDefGeneralSettingsValues.considerRueckstand,
            topAbrDefGeneralSettingsValues.displayDueDate,
            topAbrDefGeneralSettingsValues.displayZahlungsverlauf,
            topAbrDefGeneralSettingsValues.displayRueckstandOtherPersons
          ).deckblatt,
          einleitungsText: replacePlatzhalterValuesWithExampleValues(einleitungstext),
          ergebnisText: replacePlatzhalterValuesWithExampleValues(ergebnistext),
          schlussText: replacePlatzhalterValuesWithExampleValues(schlusstext),
        },
      },
    ],
    header:
      firmendatenId && belegTexte && belegTextePlatzhalter
        ? getDocumentHeaderWithoutTextbaustein(firmendatenId, belegTexte, belegTextePlatzhalter)
        : null,
    footer:
      firmendatenId && belegTexte && belegTextePlatzhalter
        ? getDocumentFooterWithoutTextbaustein(firmendatenId, belegTexte, belegTextePlatzhalter)
        : null,
  };
};

const findSelectedErgebnisTextbausteinVariantType = (selectedTextbausteinType?: TextbausteinType) => {
  const defaultErgebnisTextbausteinType = TextbausteinType.GhMitUewBankvBekannt;

  if (!selectedTextbausteinType) {
    return defaultErgebnisTextbausteinType;
  }

  switch (selectedTextbausteinType) {
    case TextbausteinType.GhMitUewBankvBekannt:
    case TextbausteinType.GhMitUewBankvUnbekannt:
    case TextbausteinType.GhMitEinzug:
    case TextbausteinType.NachfMitUew:
    case TextbausteinType.NachfMitEinzug:
      return selectedTextbausteinType;
    default:
      return defaultErgebnisTextbausteinType;
  }
};

export const mapTextbausteinToTopAbrechnungSelectableSection = (type?: TextbausteinType): TopAbrechnungSelectableSection | undefined => {
  if (!type) {
    return undefined;
  }
  switch (type) {
    case TextbausteinType.Einleitungstext:
      return 'EINLEITUNGSTEXT';
    case TextbausteinType.Ergebnistext:
    case TextbausteinType.GhMitUewBankvBekannt:
    case TextbausteinType.GhMitUewBankvUnbekannt:
    case TextbausteinType.GhMitEinzug:
    case TextbausteinType.NachfMitUew:
    case TextbausteinType.NachfMitEinzug:
      return 'ERGEBNISTEXT';
    case TextbausteinType.Schlusstext:
      return 'SCHLUSSTEXT';
    default:
      return undefined;
  }
};

const topAbrechnungExample = (
  considerRueckstand: boolean,
  displayDueDate: boolean,
  displayZahlungsverlauf: boolean,
  displayRueckstandOtherPersons: boolean
): TopAbrechnung => {
  const rueckstandBlock = considerRueckstand
    ? {
        rueckstand: {
          label: 'Rückstand des Bestandnehmers:',
          value: '100,00',
        },
        rueckstandGesamtErgebnis: {
          label: 'Nachzahlung Brutto',
          value: '97,98',
        },
      }
    : null;
  const dueDate = displayDueDate
    ? {
        label: 'Fälligkeit:',
        value: '5.1.2024',
      }
    : null;
  const zahlungsverlaufBlock = displayZahlungsverlauf
    ? {
        zahlungsverlaufBlockTitle: 'Zahlungsverlauf',
        zahlungsverlaufListHeader: {
          dueDateText: 'Fälligkeit',
          belegnummerText: 'Belegnummer',
          belegdatumText: 'Belegdatum',
          vorschreibungText: 'Vorschreibung',
          offenerBetragText: 'Offener Betrag',
        },
        zahlungsverlaufList: [
          {
            dueDate: '5.1.2023',
            belegnummer: '2023/00046',
            belegdatum: '1.1.2023',
            vorschreibung: '193,86',
            offenerBetrag: '0,00',
            auszifferung: [
              {
                text: 'gezahlt am 5.1.2023',
                betrag: '-193,86',
              },
            ],
          },
          {
            dueDate: '5.2.2023',
            belegnummer: '2023/00046',
            belegdatum: '1.1.2023',
            vorschreibung: '193,86',
            offenerBetrag: '0,00',
            auszifferung: [
              {
                text: 'gezahlt am 5.2.2023',
                betrag: '-193,86',
              },
            ],
          },
          {
            dueDate: '5.3.2023',
            belegnummer: '2023/00046',
            belegdatum: '1.1.2023',
            vorschreibung: '193,86',
            offenerBetrag: '0,00',
            auszifferung: [
              {
                text: 'gezahlt am 5.3.2023',
                betrag: '-100,00',
              },
              {
                text: 'gezahlt am 7.3.2023',
                betrag: '-93,86',
              },
            ],
          },
          {
            dueDate: '5.4.2023',
            belegnummer: '2023/00046',
            belegdatum: '1.1.2023',
            vorschreibung: '193,86',
            offenerBetrag: '143,86',
            auszifferung: [
              {
                text: 'gezahlt am 5.4.2023',
                betrag: '-50,86',
              },
            ],
          },
        ],
        zahlungsverlaufSumme: {
          text: 'Rückstand',
          betrag: '143,86',
        },
      }
    : null;
  const zahlungsrueckstandOtherPersonsBlock = displayRueckstandOtherPersons
    ? {
        zahlungsrueckstandOtherPersonsBlockTitle: 'Zahlungsrückstände',
        zahlungsrueckstandOtherPersonsListHeader: {
          topText: 'Top',
          vertragspartnerText: 'Vertragspartner',
          offenerBetragText: 'Offener Betrag',
        },
        zahlungsrueckstandOtherPersonsList: [
          {
            top: '2',
            vertragspartner: 'Wallich, Dr. Alexandra',
            offenerBetrag: '197,54',
          },
          {
            top: '3',
            vertragspartner: 'Otto Immobilien',
            offenerBetrag: '193,86',
          },
          {
            top: '12',
            vertragspartner: 'Pichler, Florentine',
            offenerBetrag: '921,02',
          },
        ],
        zahlungsrueckstandSumme: {
          text: 'Rückstand',
          betrag: '1.312,42',
        },
      }
    : null;

  return {
    topAbrechnungId: '001',
    deckblatt: {
      absender: {
        label: 'Abs:',
        value: 'andromeda software GmnH, Grillgasse 46; 1110 Wien',
      },
      rechnungsEmpfaengerShippingLabel: {
        line1: 'Familie',
        line2: 'Erika und Max Mustermann',
        line3: 'Mustergasse 11',
        line4: 'Wien',
        line5: null,
        line6: null,
        line7: null,
      },
      abrechnungsDatumLine: 'Wien, 28.06.2017',

      kundennummer: {
        text: 'Kundennummer:',
        nummer: '12121',
      },
      einleitungsText: einleitungstextPlatzhalter,

      abrechnungskreisSummary: {
        header: {
          abrechnungskreisText: '',
          aufwandText: 'Aufwand',
          vorschreibungText: 'Vorschreibung',
          nettoText: 'Netto',
          ustText: 'Ust',
          saldoText: 'Saldo',
        },
        abrechnungskreisList: [
          {
            bezeichnung: 'Betriebkosten',
            aufwandBetrag: '1.276,98',
            vorschreibungBetrag: '1.452,12',
            nettoBetrag: '-175,14',
            ustBetrag: '-15,51',
            saldoBetrag: '-192,65',
          },
          {
            bezeichnung: 'BK-Haus',
            aufwandBetrag: '310,32',
            vorschreibungBetrag: '304,92',
            nettoBetrag: '5,40',
            ustBetrag: '0,54',
            saldoBetrag: '5,94',
          },
          {
            bezeichnung: 'Lift',
            aufwandBetrag: '117,89',
            vorschreibungBetrag: '137,40',
            nettoBetrag: '-19,51',
            ustBetrag: '-1,95',
            saldoBetrag: '-21,46',
          },
        ],
        summe: {
          aufwand: '1.705,19',
          vorschreibung: '1.894,44',
          netto: '-189,25',
          ust: '-18,92',
          saldo: '-208,17',
        },
        ergebnis: {
          text: 'Guthaben',
          betrag: '-208,17',
        },
      },
      ergebnisText: '{Platzhalter Endtext je nach Use-Case}',
      schlussText: 'Beste Grüße, Ihre {Hausverwaltung.kurzbezeichnung}',
    },
    abrechnungskreisDetails: {
      aufzaehlungsText: 'Folgende Abrechnungskreise werden abgrechnet:',
      header: {
        abrechnungsKreisText: 'Abrechnungskreis',
        betragText: 'Betrag (netto)',
        summeText: 'in €',
      },
      abrechnungskreisList: [
        {
          type: 'BEWIRTSCHAFTUNGSKOSTEN',
          index: 1,
          abrechnungskreisTitelText: '1. Abrechnung von Allg. Betriebskosten',
          aufteilungAufwandNonRepFondsList: [
            {
              aufwandAufteilung: {
                aufteilungschluesselText: 'Die Kostenaufteilung erfolgt nach folgendem Schlüssel: Nutzfläche',
                zeitscheibeList: [],
                summe: {
                  zeitraumText: '01.01.2020 bis zum 31.12.2020',
                  anteilProzentText: 'Ihr Anteil 82,70 von 1.590,10 das entspricht 5,200931 %',
                  anteilBetrag: '202,64',
                },
              },
              kontoList: [
                {
                  bezeichnung: 'Wegerecht Huber',
                  betragNetto: '-100,00',
                },
                {
                  bezeichnung: 'Wasser-/Abwassergebühr',
                  betragNetto: '10.722,89',
                },
                {
                  bezeichnung: 'Hausverwaltung',
                  betragNetto: '8.886,48',
                },
                {
                  bezeichnung: 'Hausbetreuung',
                  betragNetto: '6.202.64',
                },
                {
                  bezeichnung: 'Wartung/Leuchtmittel',
                  betragNetto: '60,02',
                },
                {
                  bezeichnung: 'Strom allgemein',
                  betragNetto: '2.226,39',
                },
                {
                  bezeichnung: 'Grundsteuer',
                  betragNetto: '5.330,32',
                },
                {
                  bezeichnung: 'Wartung Garage',
                  betragNetto: '838,79',
                },
                {
                  bezeichnung: 'Grünflächenbetreuung',
                  betragNetto: '595,00',
                },
                {
                  bezeichnung: 'Winterdienst',
                  betragNetto: '488,00',
                },
                {
                  bezeichnung: 'Sonstige BK',
                  betragNetto: '937,60',
                },
                {
                  bezeichnung: 'Versicherungen',
                  betragNetto: '4.708,34',
                },
              ],
              aufwandSumme: {
                text: 'Summe',
                betrag: '40.996,47',
              },
            },
          ],
          aufteilungTop: {
            akontoVorschreibung: {
              text: 'abzüglich Akontovorschreibung (netto)',
              betrag: '-1.452,12',
            },
            saldo: {
              text: 'Saldo',
              betrag: '-175,14',
            },
            ust: {
              text: 'zuzügl. 10,00 % Umsatzsteuer',
              betrag: '-17,51',
            },
            abrechnungskreisErgebnis: {
              text: 'Betriebskosten Guthaben brutto',
              betrag: '-192,65',
            },
          },
        },
        {
          type: 'BEWIRTSCHAFTUNGSKOSTEN',
          index: 2,
          abrechnungskreisTitelText: '2. Abrechnung von BK-Haus',
          aufteilungAufwandNonRepFondsList: [
            {
              aufwandAufteilung: {
                aufteilungschluesselText: 'Die Kostenaufteilung erfolgt nach folgendem Schlüssel: NFL-Haus',
                zeitscheibeList: [],
                summe: {
                  zeitraumText: '01.01.2020 bis zum 31.12.2020',
                  anteilProzentText: 'Ihr Anteil 82,70 von 1.590,10 das entspricht 5,200931 %',
                  anteilBetrag: '202,64',
                },
              },
              kontoList: [
                {
                  bezeichnung: 'Müllabfuhr',
                  betragNetto: '6.106,77',
                },
              ],
              aufwandSumme: {
                text: 'Summe',
                betrag: '6.106,77',
              },
            },
          ],
          aufteilungTop: {
            akontoVorschreibung: {
              text: 'abzüglich Akontovorschreibung (netto)',
              betrag: '-304,92',
            },
            saldo: {
              text: 'Saldo',
              betrag: '5,40',
            },
            ust: {
              text: 'zuzügl. 10,00 % Umsatzsteuer',
              betrag: '0,54',
            },
            abrechnungskreisErgebnis: {
              text: 'BK-Haus Nachzahlung brutto',
              betrag: '5,94',
            },
          },
        },
        {
          type: 'BEWIRTSCHAFTUNGSKOSTEN',
          index: 3,
          abrechnungskreisTitelText: '3. Abrechnung von Allg. Betriebskosten',
          aufteilungAufwandNonRepFondsList: [
            {
              aufwandAufteilung: {
                aufteilungschluesselText: 'Die Kostenaufteilung erfolgt nach folgendem Schlüssel: NFL-Lift',
                zeitscheibeList: [],
                summe: {
                  zeitraumText: '01.01.2020 bis zum 31.12.2020',
                  anteilProzentText: 'Ihr Anteil 82,70 von 1.590,10 das entspricht 5,200931 %',
                  anteilBetrag: '202,64',
                },
              },
              kontoList: [
                {
                  bezeichnung: 'Aufzugswartung',
                  betragNetto: '2.164,42',
                },
                {
                  bezeichnung: 'Telefon Notrufanlage',
                  betragNetto: '219,51',
                },
                {
                  bezeichnung: 'Aufzugsstörung',
                  betragNetto: '690,00',
                },
              ],
              aufwandSumme: {
                text: 'Summe',
                betrag: '3.073,93',
              },
            },
          ],
          aufteilungTop: {
            akontoVorschreibung: {
              text: 'abzüglich Akontovorschreibung (netto)',
              betrag: '-137,40',
            },
            saldo: {
              text: 'Saldo',
              betrag: '-19,51',
            },
            ust: {
              text: 'zuzügl. 10,00 % Umsatzsteuer',
              betrag: '-1,95',
            },
            abrechnungskreisErgebnis: {
              text: 'Betriebskosten Guthaben brutto',
              betrag: '-21,46',
            },
          },
        },
      ],
      gesamtErgebnis: {
        text: 'GESAMTERGEBNIS Guthaben brutto',
        betrag: '-208,17',
      },
    },
    zahlungsDetails: {
      rueckstandBlock,
      dueDate,
      zahlungsverlaufBlock,
      zahlungsrueckstandOtherPersonsBlock,
    },
  };
};
