import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../styles/pdfStyles';
import { InformationAbrechnungskreisBlock } from '../../objektAbrechnung/objektAbrechnung-types';
import InfoAbrechnungskreis from './InfoAbrechnungskreis';

const InfoAbrechnungskreisBlock: FC<{ informationAbrechnungskreiseBlock: InformationAbrechnungskreisBlock }> = ({
  informationAbrechnungskreiseBlock,
}) => (
  <View style={[pdfStyles.column]} break>
    {informationAbrechnungskreiseBlock.abrechnungskreisList.map((abrechKreis, index) => (
      <InfoAbrechnungskreis abrechKreis={abrechKreis} key={index} />
    ))}
  </View>
);

export default InfoAbrechnungskreisBlock;
