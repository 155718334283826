import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateFibuFieldHelpMutationVariables = Types.Exact<{
  fieldHelpId: Types.Scalars['ID']['input'];
  input: Types.FieldHelpUpdateInput;
}>;

export type UpdateFibuFieldHelpMutation = {
  updateFibuFieldHelp: {
    data: { deprecated: boolean; fieldHelp?: string | null; fieldHelpId: string; selector: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteFibuFieldHelpMutationVariables = Types.Exact<{
  fieldHelpId: Types.Scalars['ID']['input'];
}>;

export type DeleteFibuFieldHelpMutation = {
  deleteFibuFieldHelp: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const UpdateFibuFieldHelpDocument = gql`
  mutation updateFibuFieldHelp($fieldHelpId: ID!, $input: FieldHelpUpdateInput!) {
    updateFibuFieldHelp(fieldHelpId: $fieldHelpId, input: $input) {
      data {
        deprecated
        fieldHelp
        fieldHelpId
        selector
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateFibuFieldHelpMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFibuFieldHelpMutation, UpdateFibuFieldHelpMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFibuFieldHelpMutation, UpdateFibuFieldHelpMutationVariables>(UpdateFibuFieldHelpDocument, options);
}
export type UpdateFibuFieldHelpMutationHookResult = ReturnType<typeof useUpdateFibuFieldHelpMutation>;
export type UpdateFibuFieldHelpMutationResult = Apollo.MutationResult<UpdateFibuFieldHelpMutation>;
export type UpdateFibuFieldHelpMutationOptions = Apollo.BaseMutationOptions<UpdateFibuFieldHelpMutation, UpdateFibuFieldHelpMutationVariables>;
export const DeleteFibuFieldHelpDocument = gql`
  mutation deleteFibuFieldHelp($fieldHelpId: ID!) {
    deleteFibuFieldHelp(fieldHelpId: $fieldHelpId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteFibuFieldHelpMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFibuFieldHelpMutation, DeleteFibuFieldHelpMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFibuFieldHelpMutation, DeleteFibuFieldHelpMutationVariables>(DeleteFibuFieldHelpDocument, options);
}
export type DeleteFibuFieldHelpMutationHookResult = ReturnType<typeof useDeleteFibuFieldHelpMutation>;
export type DeleteFibuFieldHelpMutationResult = Apollo.MutationResult<DeleteFibuFieldHelpMutation>;
export type DeleteFibuFieldHelpMutationOptions = Apollo.BaseMutationOptions<DeleteFibuFieldHelpMutation, DeleteFibuFieldHelpMutationVariables>;
