import React from 'react';
import { Row } from 'antd';
import PDFPageCol from './PDFPageCol';
import FinancialPlanObjektTemplate from './templates/financialPlan/Objekt/FinancialPlanObjektTemplate';
import financialPlanObjektMocks from './mocks/financialPlanObjektMocks';

const PDFPageFinancialPlanObjekt = () => (
  <Row>
    <PDFPageCol pdfTemplate={<FinancialPlanObjektTemplate data={financialPlanObjektMocks} />} />
  </Row>
);

export default PDFPageFinancialPlanObjekt;
