import React, { FC } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../../../hooks/useToggle';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../../components/Button/ButtonsAligned';
import EbicsBankConfigDrawer from './EbicsBankConfigDrawer';

type Props = {
  onAction: () => void;
};

const EbicsBankConfigBtnAndDrawer: FC<Props> = ({ onAction }) => {
  const [isDrawerOpen, toggleDrawer] = useToggle();

  const onSuccessAction = () => {
    toggleDrawer();
    onAction();
  };

  return (
    <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
      <Button icon={<PlusOutlined />} type="primary" onClick={toggleDrawer}>
        Bank anlegen
      </Button>
      <EbicsBankConfigDrawer
        drawerTitle="EBICS Bank anlegen"
        isDrawerOpen={isDrawerOpen}
        onDrawerClose={toggleDrawer}
        onFormAction={onSuccessAction}
        onFormCancel={toggleDrawer}
      />
    </ButtonsAligned>
  );
};

export default EbicsBankConfigBtnAndDrawer;
