import React, { FC } from 'react';
import { Empty, Table } from 'antd';
import { BuchungszeileWithDeviationInfo, OrderBuchungsanweisung } from '../../../../../../types';
import bookingPreviewTableColumns from './bookingPreviewTableColumns';

type Props = {
  data: OrderBuchungsanweisung;
  loading: boolean;
};

const BookingPreviewTable: FC<Props> = ({ data, loading }) => {
  return (
    <Table<BuchungszeileWithDeviationInfo>
      size="small"
      dataSource={data.buchungszeileList}
      rowKey={(record) => record.buchungszeileId}
      columns={bookingPreviewTableColumns(data.buchungsdatum)}
      loading={loading}
      locale={{
        emptyText: <Empty description={<span>Keine Buchungen vorhanden</span>} />,
      }}
      scroll={{ x: 890 }}
      pagination={false}
    />
  );
};

export default BookingPreviewTable;
