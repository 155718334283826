import React, { FC } from 'react';
import { Dayjs } from 'dayjs';
import { Button, Col, Divider, Row, Select, Space } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

export type TicketCalenderHeaderProps = {
  value: Dayjs;
  onChange: (date: Dayjs) => void;
  navigation?: boolean;
  bottom?: (value: Dayjs, onChange: (date: Dayjs) => void) => React.ReactNode;
};

const TicketCalenderHeader: FC<TicketCalenderHeaderProps> = ({ value, onChange, navigation = true, bottom }) => (
  <>
    <div style={{ padding: 8 }}>
      <Row justify="end" gutter={8}>
        <Col>
          <TicketCalendarHeaderYearSelect value={value} onChange={onChange} />
        </Col>
        <Col>
          <TicketCalendarHeaderMonthSelect value={value} onChange={onChange} />
        </Col>
        {navigation && (
          <Col>
            <Space direction="horizontal" size={4}>
              <Button
                style={{ paddingInline: 7 }}
                onClick={() => {
                  const now = value.clone().month(value.month() - 1);
                  onChange(now);
                }}
              >
                <LeftOutlined />
              </Button>
              <Button
                style={{ paddingInline: 7 }}
                onClick={() => {
                  const now = value.clone().month(value.month() + 1);
                  onChange(now);
                }}
              >
                <RightOutlined />
              </Button>
            </Space>
          </Col>
        )}
      </Row>
      {bottom && bottom(value, onChange)}
    </div>
    <Divider style={{ marginTop: 10, marginBottom: 16 }} />
  </>
);

const TicketCalendarHeaderMonthSelect: FC<{ value: Dayjs; onChange: (date: Dayjs) => void }> = ({ value, onChange }) => {
  const current = value.clone();
  const localeData = value.clone().localeData();
  const monthOptions = [];
  const month = value.month();

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 12; i++) {
    monthOptions.push(
      <Select.Option key={i} value={i} className="month-item">
        {localeData.monthsShort(current.month(i))}
      </Select.Option>
    );
  }

  return (
    <Select
      value={month}
      onChange={(newMonth) => {
        const now = value.clone().month(newMonth);
        onChange(now);
      }}
    >
      {monthOptions}
    </Select>
  );
};

const TicketCalendarHeaderYearSelect: FC<{ value: Dayjs; onChange: (date: Dayjs) => void }> = ({ value, onChange }) => {
  const yearOptions = [];
  const year = value.year();

  // eslint-disable-next-line no-plusplus
  for (let i = year - 10; i < year + 10; i++) {
    yearOptions.push(
      <Select.Option key={i} value={i} className="year-item">
        {i}
      </Select.Option>
    );
  }

  return (
    <Select
      value={year}
      onChange={(newYear) => {
        const now = value.clone().year(newYear);
        onChange(now);
      }}
    >
      {yearOptions}
    </Select>
  );
};

export default TicketCalenderHeader;
