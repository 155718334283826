import { FC } from 'react';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';
import { useTextbausteinChangeHistoryListQuery } from '../gql/TextbausteinQueries.types';

type Props = {
  textbausteinId: string;
};

const TextbausteinChangeHistoryListingTable: FC<Props> = ({ textbausteinId }) => {
  const { data, loading } = useTextbausteinChangeHistoryListQuery({ variables: { textbausteinId } });
  const historyList = data?.getTextbausteinChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="textbaustein" />;
};

export default TextbausteinChangeHistoryListingTable;
