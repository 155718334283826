import React, { FC, PropsWithChildren, useState } from 'react';
import { Button } from 'antd';
import TicketActionButtonWithTooltip from './TicketActionButtonIconWithTooltip';
import theme from '../../../theme';

type PanelActionProps = {
  badgeCount?: number;
  label: string;
  icon: React.ReactNode;
  disabled?: boolean;
  onClick: () => void;
};

const TicketActionButton: FC<PanelActionProps & PropsWithChildren> = ({ badgeCount, disabled, icon, label, onClick, children }) => {
  // Note: The tooltip should be hidden after the button is clicked to prevent it from overlapping with the drawer, which slides in and could display the tooltip on top of it.
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean | undefined>();

  return (
    <TicketActionButtonWithTooltip badgeCount={badgeCount} label={label} isTooltipOpen={isTooltipOpen}>
      <Button
        icon={icon}
        style={!disabled ? { color: theme.colors.blue } : {}}
        disabled={disabled}
        shape="circle"
        size="small"
        type="text"
        onClick={() => {
          setIsTooltipOpen(false);
          onClick();
        }}
        onMouseEnter={() => setIsTooltipOpen(undefined)}
      />
      {children}
    </TicketActionButtonWithTooltip>
  );
};

export default TicketActionButton;
