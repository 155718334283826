import React from 'react';
import { Formik } from 'formik';
import FieldHelpTable from './Table/FieldHelpTable';
import useFieldHelpList from '../useFieldHelpList';
import FieldHelpListingFilters from './Filters/FieldHelpListingFilters';
import {
  fieldHelpListingFiltersFormInitialValues,
  FieldHelpListingFiltersFormValues,
  mapFormValuesToFieldHelpListingFilters,
} from './Filters/fieldHelpListingFiltersFormMapper';

const FieldHelpListing = () => {
  const { data, refetch, loading, setQueryArgs } = useFieldHelpList();

  const onSubmit = (values: FieldHelpListingFiltersFormValues) => {
    const filters = mapFormValuesToFieldHelpListingFilters(values);
    setQueryArgs(filters);
  };

  return (
    <Formik<FieldHelpListingFiltersFormValues> initialValues={fieldHelpListingFiltersFormInitialValues} onSubmit={onSubmit}>
      {(formikProps) => (
        <>
          <FieldHelpListingFilters formikProps={formikProps} />
          <FieldHelpTable fieldHelpList={data} onAction={refetch} loading={loading} />
        </>
      )}
    </Formik>
  );
};

export default FieldHelpListing;
