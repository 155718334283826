import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { VRechnungAussteller } from '../../vorschreibung-types';
import { ausstellerEmpfaengerBlockColumnWidthsAndAlignment } from './rechnung-info-styles';

const VTRechnungAusstellerBlock: FC<{ rechnungAussteller: VRechnungAussteller }> = ({ rechnungAussteller }) => (
  <View style={[pdfStyles.row, { marginTop: '10mm', fontWeight: 'bold' }]}>
    <Text style={[pdfStyles.column, pdfStyles.textNormal, { width: ausstellerEmpfaengerBlockColumnWidthsAndAlignment.column1.width }]}>
      {rechnungAussteller.bezeichnung.label}
    </Text>
    <Text style={[pdfStyles.column, pdfStyles.textNormal, { width: ausstellerEmpfaengerBlockColumnWidthsAndAlignment.column2.width }]}>
      {rechnungAussteller.bezeichnung.value}
    </Text>
    <Text
      style={[
        pdfStyles.column,
        pdfStyles.textNormal,
        {
          width: ausstellerEmpfaengerBlockColumnWidthsAndAlignment.column3.width,
          textAlign: ausstellerEmpfaengerBlockColumnWidthsAndAlignment.column3.textAlign,
        },
      ]}
    />
  </View>
);

export default VTRechnungAusstellerBlock;
