import React, { FC } from 'react';
import { DeleteOutlined, EditOutlined, EllipsisOutlined, HistoryOutlined } from '@ant-design/icons';
import { FormattedNumber } from 'react-intl';
import { Button, Divider, Drawer, Dropdown, Modal, Space, Typography } from 'antd';
import CardTitle from '../../../../components/Card/CardTitle';
import CardWithFooter from '../../../../components/Card';
import HeVertragPostIt from '../PostIt/HeVertragPostIt';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { addressToString } from '../../../../shared/components/Address/addressHelper';
import HauptTag from '../../BeVertrag/Versand/Form/shared/HauptTag';
import { showSuccessMsgDelete } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { useGoBack } from '../../../../shared/GoBack/GoBackContext';
import { useDeleteHeVertragMutation } from '../gql/HeVertragMutations.types';
import { pathsToObjektDetailsPage } from '../../../Objekt/objektUriPaths';
import HeVertragForm from '../Form/HeVertragForm';
import { useToggle } from '../../../../hooks/useToggle';
import { EuroAmount, Percent } from '../../../../components/Number';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import VertragspartnerCardDetailsInfoRow from '../../shared/VertragspartnerCardDetailsInfoRow';
import { getEntityWarningMsgByAttribute } from '../../../../helpers/entityWarningHelper';
import { radixActionStyles } from '../../../../helpers/radixIconsStyles';
import HistoryModal, { HistoryType } from '../../../History/shared/HistoryModal';
import HeVertragChangeHistoryListingTable from './HeVertragChangeHistoryListingTable';
import { HeVertrag } from '../../../../types';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import InnerCardTagList from '../../../shared/InnerCardTagList';

type Props = {
  objektId: string;
  loading: boolean;
  heVertrag: HeVertrag;
  onActionSuccess: () => void;
};

const HeVertragCard: FC<Props> = ({ objektId, loading, heVertrag, onActionSuccess }) => {
  const [isCollapsed, onCollapse] = useToggle();

  const [isChangeHistoryOpen, toggleChangehistoryOpen] = useToggle();

  const [runDelete] = useDeleteHeVertragMutation({
    onCompleted: () => {
      showSuccessMsgDelete('Hauseigentümervertrag');
      goBack();
    },
    variables: {
      objektId,
      heVertragId: heVertrag.heVertragId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onSuccess = () => {
    onActionSuccess();
    onCollapse();
  };

  const goBack = useGoBack(pathsToObjektDetailsPage(objektId).heVertrag);

  return (
    <CardWithFooter
      loading={loading}
      style={{ minWidth: '320px' }}
      size="default"
      bordered={false}
      title={
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <CardTitle title="Hauseigentümervertrag" warningList={heVertrag.warningList} />
          <HeVertragPostIt objektId={objektId} heVertragId={heVertrag.heVertragId} postIt={heVertrag.postIt} onSuccess={onActionSuccess} />
        </Space>
      }
      extra={
        <Dropdown menu={{ items: menuItems(heVertrag, onCollapse, runDelete, toggleChangehistoryOpen) }}>
          <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
        </Dropdown>
      }
      createTs={heVertrag.createTs}
      userId={heVertrag.createdBy}
      mitarbeiterId={heVertrag.createdByMitarbeiterId}
    >
      <Typography.Title level={5}>Allgemeine Informationen</Typography.Title>
      <CardDetailsInfoRow infoRowTitle="Vertragsbeginn">
        <CustomFormattedDate value={heVertrag.vertragsBeginn} />
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Vertragsende">
        {heVertrag.vertragsEnde ? <CustomFormattedDate value={heVertrag.vertragsEnde} /> : <TextForEmptyValue textToShow="minus" />}
      </CardDetailsInfoRow>

      <VertragspartnerCardDetailsInfoRow
        rechtstraegerId={heVertrag.vertragspartner.rechtstraegerId}
        status={heVertrag.vertragspartner.status}
        kurzBezeichnung={heVertrag.vertragspartner.kurzBezeichnung}
        warningList={getWarningList(heVertrag, 'vertragspartner')}
      />

      <CardDetailsInfoRow infoRowTitle="Zustelladresse">
        {heVertrag.deliveryAddress ? (
          <DataWithShortenedText maxTextLength={25} text={addressToString(heVertrag.deliveryAddress)}>
            {(shortenedText) => (
              <Space direction="vertical" size={2}>
                <Typography.Text>{shortenedText}</Typography.Text>
                {heVertrag.deliveryAddressHaupt && <HauptTag />}
              </Space>
            )}
          </DataWithShortenedText>
        ) : (
          <TextForEmptyValue textToShow="minus" />
        )}
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle={getSaldouebertragTitle(heVertrag)}>
        <EuroAmount value={heVertrag.saldouebertrag} />
      </CardDetailsInfoRow>

      <Divider dashed />

      {heVertrag.tagList && heVertrag.tagList.length > 0 ? <InnerCardTagList tagList={heVertrag.tagList} /> : null}

      <Typography.Title level={5}>Anteile</Typography.Title>
      <CardDetailsInfoRow infoRowTitle="Anteile">
        <Typography.Text>
          <FormattedNumber value={heVertrag.anteile} /> von {heVertrag.objektSummeAnteile}
        </Typography.Text>
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Anteile %">
        <Percent value={heVertrag.anteile / heVertrag.objektSummeAnteile} />
      </CardDetailsInfoRow>

      <Drawer title="Bearbeiten" width={720} open={isCollapsed} onClose={onCollapse} destroyOnClose>
        <HeVertragForm
          objektId={objektId}
          heVertrag={heVertrag}
          objektVerwaltungSeit={heVertrag.objektVerwaltungSeit}
          onCancel={onCollapse}
          onSuccess={onSuccess}
        />
      </Drawer>

      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangehistoryOpen}>
        <HeVertragChangeHistoryListingTable objektId={objektId} heVertragId={heVertrag.heVertragId} />
      </HistoryModal>
    </CardWithFooter>
  );
};

const getSaldouebertragTitle = (heVertrag: HeVertrag) => {
  const vetragsBeginnDayJS = dayjsCustom(heVertrag.vertragsBeginn);
  const objVerwaltungSeitDayJS = dayjsCustom(heVertrag.objektVerwaltungSeit);

  const additionalText = (
    <Typography.Text type="secondary" italic>
      {vetragsBeginnDayJS.isSameOrAfter(objVerwaltungSeitDayJS) ? `("Vertragsbeginn")` : `("verwaltet seit")`}
    </Typography.Text>
  );

  return vetragsBeginnDayJS.isSameOrAfter(objVerwaltungSeitDayJS) || objVerwaltungSeitDayJS.isAfter(vetragsBeginnDayJS) ? (
    <Space direction="vertical" size={0}>
      <Typography.Text type="secondary">Saldoübertrag</Typography.Text>
      {additionalText}
    </Space>
  ) : (
    'Saldoübertrag'
  );
};

const menuItems = (vertrag: HeVertrag, onEdit: () => void, runDelete: () => void, onChangeHistoryClicked: () => void) => [
  {
    key: '1',
    label: 'Bearbeiten',
    onClick: onEdit,
    icon: <EditOutlined />,
  },
  {
    key: '2',
    label: 'Änderungsverlauf',
    onClick: onChangeHistoryClicked,
    icon: <HistoryOutlined style={radixActionStyles} />,
  },
  {
    key: '3',
    label: 'Löschen',
    onClick: () => showConfirmDelete(runDelete),
    icon: <DeleteOutlined />,
    danger: true,
    disabled: !vertrag.deletable,
  },
];

const getWarningList = (vertrag: HeVertrag, attribute: keyof HeVertrag) =>
  getEntityWarningMsgByAttribute(vertrag, attribute) ? (
    <Typography.Text type="danger">{getEntityWarningMsgByAttribute(vertrag, attribute)}</Typography.Text>
  ) : null;

const showConfirmDelete = (runDelete: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie diesen Vertrag löschen?',
    okText: 'Löschen',
    okButtonProps: { danger: true },
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default HeVertragCard;
