import React from 'react';
import { Link } from 'react-router-dom';
import { friendlyFormatIBAN } from 'ibantools';
import { TableColumnProps } from 'antd';
import { ReceivedBankTransactionListEntry, ReceivedBankTransactionOrderBy } from '../../../../../../types';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';
import { EuroAmount } from '../../../../../../components/Number';
import ReceivedBankTransactionTableActions from './ReceivedBankTransactionTableActions';
import { fontWeightForNotedRecord, TextAndFontWeightForNotedRecord } from '../../../../shared/TextAndFontWeightForNotedRecord';
import { compareTwoStringsForSorting } from '../../../../../../helpers/stringHelper';
import { compareTwoBooleansForSorting } from '../../../../../../helpers/booleanHelper';
import { compareTwoDatesForSorting } from '../../../../../../helpers/dateHelper';
import { compareTwoNumbersForSorting } from '../../../../../../helpers/numberHelper';
import { generatePathToBankstatementListingPage } from '../../../../../DataCarrier/Listing/Bankstatement/Filters/filtersQueryParams';
import DataWithShortenedText from '../../../../../../components/Helpers/DataWithShortenedText';
import CustomFormattedDate from '../../../../../../components/FormattedDate/CustomFormattedDate';
import { isNil, isNotNil } from '../../../../../../helpers/assertionHelper';
import { isStatusArchived, StatusEnum } from '../../../../../../helpers/statusHelper';
import StatusBadge from '../../../../../../components/Status/StatusBadge';

export const receivedBankTransactionTableColumns = (onAction: () => void): TableColumnProps<ReceivedBankTransactionListEntry>[] => [
  {
    // this column is only for the layout purpose
    title: '',
    key: 'empty',
    width: 96,
    render: () => '',
  },
  {
    title: 'Gesehen',
    key: 'noted',
    sorter: (a, b) => compareTwoBooleansForSorting(a.noted, b.noted),
    render: (text, record) => <TextAndFontWeightForNotedRecord noted={record.noted} content={record.noted ? 'Gesehen' : 'Neu'} />,
  },
  {
    title: 'Datenträger',
    key: 'dataCarrierName',
    sorter: (a, b) => compareTwoStringsForSorting(a.dataCarrierName, b.dataCarrierName),
    render: (text, record) => (
      <Link
        target="_blank"
        to={generatePathToBankstatementListingPage({
          name: record.dataCarrierName,
          statementNumber: record.statementNumber,
          iban: record.creditorIban,
        })}
        style={{ fontWeight: fontWeightForNotedRecord(record.noted) }}
      >
        <DataWithShortenedText maxTextLength={30} text={record.dataCarrierName} />
      </Link>
    ),
  },
  {
    title: 'Kontoauszugsnummer',
    key: 'statementNumber',
    sorter: (a, b) => compareTwoStringsForSorting(a.statementNumber, b.statementNumber),
    render: (text, record) => <TextAndFontWeightForNotedRecord noted={record.noted} content={record.statementNumber} />,
  },
  {
    title: 'Kontoauszug IBAN',
    sorter: (a, b) => compareTwoStringsForSorting(a.bankStatementIban, b.bankStatementIban),
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={20} text={`${friendlyFormatIBAN(record.bankStatementIban)}`}>
        {(shortenedText) => (
          <TextAndFontWeightForNotedRecord noted={record.noted} content={shortenedText} isCurrent={!isStatusArchived(record.status)} />
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'TransaktionsanweisungsId',
    key: 'transactionInstructionId',
    render: (text, record) =>
      record.endToEndId ? (
        <TextAndFontWeightForNotedRecord noted={record.noted} content={record.endToEndId} />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />
      ),
  },
  {
    title: 'Transaktionsart',
    key: 'transactionType',
    sorter: (a, b) => compareTwoStringsForSorting(a.transactionType.text, b.transactionType.text),
    render: (text, record) => <TextAndFontWeightForNotedRecord noted={record.noted} content={record.transactionType.text} />,
  },
  {
    title: 'Valutadatum',
    key: 'valueDate',
    sorter: (a, b) => compareTwoDatesForSorting(a.valueDate, b.valueDate),
    render: (text, record) => {
      if (record.valueDate) {
        return <TextAndFontWeightForNotedRecord noted={record.noted} content={<CustomFormattedDate value={record.valueDate} />} />;
      }
      return <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />;
    },
  },
  {
    title: 'Rückbuchung',
    key: 'reversal',
    sorter: (a, b) => compareTwoBooleansForSorting(a.reversal, b.reversal),
    render: (text, record) => <TextAndFontWeightForNotedRecord noted={record.noted} content={record.reversal ? 'Ja' : 'Nein'} />,
  },
  {
    title: 'Kreditor',
    key: 'creditor',
    sorter: (a, b) => compareTwoStringsForSorting(a.creditor, b.creditor),
    render: (text, record) =>
      record.creditor ? (
        <TextAndFontWeightForNotedRecord noted={record.noted} content={record.creditor} />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />
      ),
  },
  {
    title: 'Kreditor Bankverbindung',
    key: 'creditorIban',
    sorter: (a, b) => compareTwoStringsForSorting(a.creditorIban, b.creditorIban),
    render: (text, record) =>
      record.creditorIban ? (
        <TextAndFontWeightForNotedRecord noted={record.noted} content={friendlyFormatIBAN(record.creditorIban)} />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />
      ),
  },
  {
    title: 'Debitor',
    key: 'debtor',
    sorter: (a, b) => compareTwoStringsForSorting(a.debtor, b.debtor),
    render: (text, record) =>
      record.debtor ? (
        <TextAndFontWeightForNotedRecord noted={record.noted} content={record.debtor} />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />
      ),
  },
  {
    title: 'Debitor Bankverbindung',
    key: 'debtorIban',
    sorter: (a, b) => compareTwoStringsForSorting(a.debtorIban, b.debtorIban),
    render: (text, record) =>
      record.debtorIban ? (
        <TextAndFontWeightForNotedRecord noted={record.noted} content={friendlyFormatIBAN(record.debtorIban)} />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />
      ),
  },
  {
    title: 'Betrag',
    key: 'amount',
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.amount, b.amount),
    render: (text, record) => <TextAndFontWeightForNotedRecord noted={record.noted} content={<EuroAmount value={record.amount} />} />,
  },
  {
    title: 'Spesen',
    key: 'charges',
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.charges, b.charges),
    render: (text, record) =>
      record.charges ? (
        <TextAndFontWeightForNotedRecord noted={record.noted} content={<EuroAmount value={record.charges} />} />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />
      ),
  },
  {
    title: 'Spesen in eigener Bankbuchung',
    key: 'chargesInSeparateEntry',
    sorter: (a, b) => compareTwoBooleansForSorting(a.chargesInSeparateEntry, b.chargesInSeparateEntry),
    render: (text, record) => <TextAndFontWeightForNotedRecord noted={record.noted} content={record.chargesInSeparateEntry ? 'Ja' : 'Nein'} />,
  },
  {
    title: 'Verwendungszweck',
    key: 'purposeOfUse',
    sorter: (a, b) => compareTwoStringsForSorting(a.purposeOfUseText, b.purposeOfUseText),
    render: (text, record) =>
      record.purposeOfUseText ? (
        <TextAndFontWeightForNotedRecord noted={record.noted} content={record.purposeOfUseText} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Zahlungsreferenz',
    key: 'paymentReference',
    sorter: (a, b) => compareTwoStringsForSorting(a.paymentReferenceText, b.paymentReferenceText),
    render: (text, record) =>
      record.paymentReferenceText ? (
        <TextAndFontWeightForNotedRecord noted={record.noted} content={record.paymentReferenceText} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Verbucht',
    dataIndex: ReceivedBankTransactionOrderBy.Booked,
    sorter: true,
    render: (text, record) => <TextAndFontWeightForNotedRecord noted={record.noted} content={record.booked ? 'Ja' : 'Nein'} />,
  },
  {
    title: 'Buchungsdatum',
    dataIndex: ReceivedBankTransactionOrderBy.BookingDate,
    sorter: true,
    render: (text, record) => {
      if (record.bookingDate) {
        return <TextAndFontWeightForNotedRecord noted={record.noted} content={<CustomFormattedDate value={record.bookingDate} />} />;
      }
      return <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />;
    },
  },
  {
    title: 'Buchungsvorschlag Status',
    render: (text, record) => {
      if (isNil(record.valid)) {
        return <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />;
      }

      const status = {
        text: record.valid ? 'Gültig' : 'Ungültig',
        value: record.valid ? StatusEnum.Valid : StatusEnum.Invalid,
      };

      return (
        <StatusBadge
          entityStatus={status}
          badgeTextStyle={{
            fontWeight: fontWeightForNotedRecord(record.noted),
          }}
        />
      );
    },
  },
  {
    title: 'Auszifferungsvorschläge Anzahl',
    render: (_, record) =>
      isNotNil(record.countBookingSuggestionClearingSuggestion) ? (
        <TextAndFontWeightForNotedRecord
          noted={record.noted}
          content={record.countBookingSuggestionClearingSuggestion}
          isCurrent={!isStatusArchived(record.status)}
        />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} isCurrent={!isStatusArchived(record.status)} />
      ),
  },
  {
    title: 'Verfügbarer Betrag',
    render: (_, record) =>
      isNotNil(record.availableAmount) ? (
        <TextAndFontWeightForNotedRecord
          noted={record.noted}
          content={<EuroAmount value={record.availableAmount} />}
          isCurrent={!isStatusArchived(record.status)}
        />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} isCurrent={!isStatusArchived(record.status)} />
      ),
  },
  {
    title: 'Aktion',
    key: 'action',
    render: (text, record) => (
      <ReceivedBankTransactionTableActions
        receivedBankTransaction={record}
        receivedBankTransactionId={record.receivedBankTransactionId}
        onSuccess={onAction}
      />
    ),
  },
];
