import { FC } from 'react';
import { Button, Dropdown, MenuProps } from 'antd';
import { EllipsisOutlined, RedoOutlined } from '@ant-design/icons';
import { GenerierlaufStatus } from '../../../types';

type Props = {
  generierlaufStatus: GenerierlaufStatus;
  onRestart: () => void;
};

const VerarbeitungDetailsPageCardActions: FC<Props> = ({ generierlaufStatus, onRestart }) => {
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Wiederaufnehmen',
      onClick: onRestart,
      icon: <RedoOutlined />,
      disabled: !(generierlaufStatus === GenerierlaufStatus.BeendetFehler),
    },
  ];

  return (
    <Dropdown menu={{ items }}>
      <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
    </Dropdown>
  );
};

export default VerarbeitungDetailsPageCardActions;
