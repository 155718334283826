import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  mapTextbausteinListToFormValues,
  TextbausteinFormValues,
  TextbausteinModel,
} from '../../../../../../components/Template/PDFTemplates/templateMapper';

import { showSuccessMsgCreate } from '../../../../../../components/message';
import { URI_SYS_SETTINGS } from '../../../../../../constants/configurationUriPaths';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { mapFormValuesToBkOrWeAbrechnungsdefinitionCreate } from '../../../../Form/Create/abrDefCreateFormMapper';
import { AbrechnungsdefinitionParameterFormValues } from '../../../../Form/Create/abrDefParameterFormMapper';
import { AbrDefAbrKreisListingFormValues } from '../../../AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormMapper';
import {
  useCreateWeAbrechnungsdefinitionForFdMutation,
  useCreateWeAbrechnungsdefinitionForKsMutation,
} from '../../../../gql/WeAbrDef/WeAbrDefMutations.types';
import {
  useCreateBkAbrechnungsdefinitionForFdMutation,
  useCreateBkAbrechnungsdefinitionForKsMutation,
} from '../../../../gql/BkAbrDef/BkAbrDefMutations.types';
import { AbrechnungsdefinitionType } from '../../../../../../types';
import BkAbrDefTemplates from '../../BkTemplates/BkAbrDefTemplates';
import WeAbrDefTemplates from '../../WeTemplates/WeAbrDefTemplates';
import {
  objAbrDefGeneralSettingsFormInitialValues,
  ObjAbrDefGeneralSettingsFormValues,
} from './ObjAbrDefGeneralSettingsForm/objAbrDefGeneralSettingsFormMapper';
import {
  mapTopAbrDefGeneralSettingsToInitialValues,
  TopAbrDefGeneralSettingsFormValues,
} from './TopAbrDefGeneralSettingsForm/topAbrDefGeneralSettingsFormMapper';
import { isAbrDefTypeBkAbrechnung } from '../../../AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import {
  BkOrWeAbrDefObjektOutputOptionsFieldHelpNames,
  BkOrWeAbrDefTopOutputOptionsFieldHelpNames,
} from '../../../../abrDefFieldHelpHelpers/abrDefFieldHelpMapper';

type Props = {
  kundenSystemId: string;
  parameterFormValues: AbrechnungsdefinitionParameterFormValues;
  abrKreisListFormValues: AbrDefAbrKreisListingFormValues;
  objektAbrechnungTextbausteinList: TextbausteinModel[];
  topAbrechnungTextbausteinList: TextbausteinModel[];
  validFromFormValue: string;
  setIsValidFromValid: (validFromValid: boolean) => void;
  onBack: () => void;
  fieldHelpNames?: {
    objekt?: BkOrWeAbrDefObjektOutputOptionsFieldHelpNames;
    top?: BkOrWeAbrDefTopOutputOptionsFieldHelpNames;
  };
};

const BkOrWeAbrDefTemplatesForm: FC<Props> = ({
  kundenSystemId,
  parameterFormValues,
  abrKreisListFormValues,
  objektAbrechnungTextbausteinList,
  topAbrechnungTextbausteinList,
  validFromFormValue,
  setIsValidFromValid,
  onBack,
  fieldHelpNames,
}) => {
  const entity = 'Abrechnungsdefinition';
  const navigate = useNavigate();

  // BK and WE
  const [objAbrDefTemplateValues, setObjAbrDefTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(objektAbrechnungTextbausteinList)
  );
  const [topAbrDefTemplateValues, setTopAbrDefTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(topAbrechnungTextbausteinList)
  );
  const [objAbrDefGeneralSettingsValues, setObjAbrDefGeneralSettingsValues] = useState<ObjAbrDefGeneralSettingsFormValues>(
    objAbrDefGeneralSettingsFormInitialValues
  );
  const [topAbrDefGeneralSettingsValues, setTopAbrDefGeneralSettingsValues] = useState<TopAbrDefGeneralSettingsFormValues>(
    mapTopAbrDefGeneralSettingsToInitialValues(
      isAbrDefTypeBkAbrechnung(parameterFormValues.type) ? AbrechnungsdefinitionType.BkAbrechnung : AbrechnungsdefinitionType.WeAbrechnung
    )
  );
  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  const baseOptions = {
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      navigate(URI_SYS_SETTINGS.abrechnungsdefinitionPage);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  };

  const [createWegForFD, { loading: loadingCreateWegForFD }] = useCreateWeAbrechnungsdefinitionForFdMutation(baseOptions);
  const [createWegForKS, { loading: loadingCreateWegForKS }] = useCreateWeAbrechnungsdefinitionForKsMutation(baseOptions);

  const [createBkForFD, { loading: loadingCreateBkForFD }] = useCreateBkAbrechnungsdefinitionForFdMutation(baseOptions);
  const [createBkForKS, { loading: loadingCreateBkForKS }] = useCreateBkAbrechnungsdefinitionForKsMutation(baseOptions);

  if (parameterFormValues.type === AbrechnungsdefinitionType.BkAbrechnung) {
    return (
      <BkAbrDefTemplates
        bkObjektAbrTemplateValues={objAbrDefTemplateValues}
        onBkObjektAbrDefTemplateChange={setObjAbrDefTemplateValues}
        bkTopAbrTemplateValues={topAbrDefTemplateValues}
        onBkTopAbrDefTemplateChange={setTopAbrDefTemplateValues}
        objAbrDefGeneralSettingsValues={objAbrDefGeneralSettingsValues}
        onObjAbrDefGeneralSettingsChange={setObjAbrDefGeneralSettingsValues}
        topAbrDefGeneralSettingsValues={topAbrDefGeneralSettingsValues}
        onTopAbrDefGeneralSettingsChange={setTopAbrDefGeneralSettingsValues}
        setIsFormValid={setIsFormValid}
        onSubmit={() => {
          const input = mapFormValuesToBkOrWeAbrechnungsdefinitionCreate(
            parameterFormValues,
            abrKreisListFormValues,
            objAbrDefTemplateValues,
            topAbrDefTemplateValues,
            validFromFormValue,
            objAbrDefGeneralSettingsValues,
            topAbrDefGeneralSettingsValues
          );
          if (!validFromFormValue) {
            setIsValidFromValid(false);
            return;
          }
          if (!isFormValid) return;
          if (parameterFormValues?.firmendatenId) {
            createBkForFD({ variables: { input, firmendatenId: parameterFormValues.firmendatenId } });
          } else {
            createBkForKS({ variables: { input, kundenSystemId } });
          }
        }}
        isSubmitting={loadingCreateBkForFD || loadingCreateBkForKS}
        onBack={onBack}
        fieldHelpNames={{ objekt: fieldHelpNames?.objekt, top: fieldHelpNames?.top }}
      />
    );
  }

  if (parameterFormValues.type === AbrechnungsdefinitionType.WeAbrechnung) {
    return (
      <WeAbrDefTemplates
        weObjektAbrTemplateValues={objAbrDefTemplateValues}
        onWeObjektAbrDefTemplateChange={setObjAbrDefTemplateValues}
        weTopAbrTemplateValues={topAbrDefTemplateValues}
        onWeTopAbrDefTemplateChange={setTopAbrDefTemplateValues}
        objAbrDefGeneralSettingsValues={objAbrDefGeneralSettingsValues}
        onObjAbrDefGeneralSettingsChange={setObjAbrDefGeneralSettingsValues}
        topAbrDefGeneralSettingsValues={topAbrDefGeneralSettingsValues}
        onTopAbrDefGeneralSettingsChange={setTopAbrDefGeneralSettingsValues}
        setIsFormValid={setIsFormValid}
        onSubmit={() => {
          const input = mapFormValuesToBkOrWeAbrechnungsdefinitionCreate(
            parameterFormValues,
            abrKreisListFormValues,
            objAbrDefTemplateValues,
            topAbrDefTemplateValues,
            validFromFormValue,
            objAbrDefGeneralSettingsValues,
            topAbrDefGeneralSettingsValues
          );
          if (!validFromFormValue) {
            setIsValidFromValid(false);
            return;
          }
          if (!isFormValid) return;
          if (parameterFormValues?.firmendatenId) {
            createWegForFD({ variables: { input, firmendatenId: parameterFormValues.firmendatenId } });
          } else {
            createWegForKS({ variables: { input, kundenSystemId } });
          }
        }}
        isSubmitting={loadingCreateWegForFD || loadingCreateWegForKS}
        onBack={onBack}
        fieldHelpNames={{ objekt: fieldHelpNames?.objekt, top: fieldHelpNames?.top }}
      />
    );
  }

  return <></>;
};

export default BkOrWeAbrDefTemplatesForm;
