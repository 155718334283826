import React, { FC } from 'react';
import { Dropdown, MenuProps, Modal } from 'antd';
import { DeleteOutlined, DownOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import RechnAusstellerRepFondsKontoZuweisungForm from '../KontoZuweisungForm/RechnAusstellerRepFondsKontoZuweisungForm';
import { useToggle } from '../../../../../../hooks/useToggle';
import { showSuccessMsgDelete } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { radixActionStyles } from '../../../../../../helpers/radixIconsStyles';
import HistoryModal, { HistoryType } from '../../../../../History/shared/HistoryModal';
import RepFondsChangeHistoryListingTable from '../RepFondsChangeHistoryListingTable';
import { RepFondsErloesKontenZuweisung } from '../../../../../../types';
import { useDeleteRepFondsErloesKontenZuweisungMutation } from '../../../../../ReparaturFonds/gql/RepFondsErloesKontenZuweisungMutations.types';

type RechnungsAusstellerRepFondsTableActionsProps = {
  record: RepFondsErloesKontenZuweisung;
  rechtstraegerId: string;
  onSuccess: () => void;
  repFondsErloesKontenZuweisungList?: RepFondsErloesKontenZuweisung[];
};

const RechnungsAusstellerRepFondsTableActions: FC<RechnungsAusstellerRepFondsTableActionsProps> = ({
  rechtstraegerId,
  record,
  onSuccess,
  repFondsErloesKontenZuweisungList,
}) => {
  const [showModal, toggleShowModal] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const [runDelete] = useDeleteRepFondsErloesKontenZuweisungMutation({
    variables: {
      rechtstraegerId,
      repFondsErloesKontenZuweisungId: record.repFondsErloesKontenZuweisungId,
    },
    onCompleted: () => {
      showSuccessMsgDelete('Reparaturfonds-Erlöskontenzuweisung');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    { key: '1', label: 'Bearbeiten', onClick: toggleShowModal, icon: <EditOutlined /> },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    { key: '3', label: 'Löschen', onClick: showConfirmDelete(runDelete), icon: <DeleteOutlined />, danger: true },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Aktion <DownOutlined />
        </a>
      </Dropdown>
      <Modal title="Erlöskonten-Zuweisung bearbeiten" footer={null} open={showModal} onCancel={toggleShowModal} destroyOnClose maskClosable={false}>
        <RechnAusstellerRepFondsKontoZuweisungForm
          rechtstraegerId={rechtstraegerId}
          onSuccess={() => {
            onSuccess();
            toggleShowModal();
          }}
          onCancel={toggleShowModal}
          repFondsErloesKontenZuweisung={record}
          repFondsErloesKontenZuweisungList={repFondsErloesKontenZuweisungList}
        />
      </Modal>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <RepFondsChangeHistoryListingTable
          rechtstraegerId={rechtstraegerId}
          repFondsErloesKontenZuweisungId={record.repFondsErloesKontenZuweisungId}
        />
      </HistoryModal>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie die Reparaturfonds-Erlöskontenzuweisung löschen?`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default RechnungsAusstellerRepFondsTableActions;
