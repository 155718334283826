import { mapToQueryString, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { EbicsUserStatus, GenerierlaufEntryExitCode } from '../../../../../types';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../../verarbeitungApiPaths';

enum VertragVposIndexedValueVerarbeitungQueryParamKey {
  MITARBEITER_ID = 'mitarbeiterId',
  TEILNEHMER = 'teilnehmer',
  USER_ID = 'userId',
  PARTNER_ID = 'partnerId',
  BANKNAME = 'bankname',
  EBICS_STATUS = 'ebicsStatus',
  EXIT_CODE_LIST = 'exitCodeList',
}

export type TSyncEbicsUserVerarbeitungQueryParams = QueryParams<{
  mitarbeiterId?: string | null;
  teilnehmer?: string | null;
  userId?: string | null;
  partnerId?: string | null;
  bankname?: string | null;
  ebicsStatus?: EbicsUserStatus | null;
  exitCodeList?: GenerierlaufEntryExitCode[] | null;
}>;

export const updateQueryParams = (
  verarbeitungType: string,
  generierlaufId: string,
  navigate: (path: string) => void,
  filters: TSyncEbicsUserVerarbeitungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToSyncEbicsUserVerarbeitungPage(verarbeitungType, generierlaufId, filters, paginationParams));

const generatePathToSyncEbicsUserVerarbeitungPage = (
  verarbeitungType: string,
  generierlaufId: string,
  filters?: TSyncEbicsUserVerarbeitungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [VertragVposIndexedValueVerarbeitungQueryParamKey.MITARBEITER_ID]: filters?.mitarbeiterId,
    [VertragVposIndexedValueVerarbeitungQueryParamKey.TEILNEHMER]: filters?.teilnehmer,
    [VertragVposIndexedValueVerarbeitungQueryParamKey.USER_ID]: filters?.userId,
    [VertragVposIndexedValueVerarbeitungQueryParamKey.PARTNER_ID]: filters?.partnerId,
    [VertragVposIndexedValueVerarbeitungQueryParamKey.BANKNAME]: filters?.bankname,
    [VertragVposIndexedValueVerarbeitungQueryParamKey.EBICS_STATUS]: filters?.ebicsStatus,
    [VertragVposIndexedValueVerarbeitungQueryParamKey.EXIT_CODE_LIST]: filters?.exitCodeList,
    ...paginationParams,
  })}`;
