import { EditorState } from 'lexical';
import { FormFields } from '../../../helpers/formikHelper';
import { FormFileReference } from '../../../components/FileUpload/formHelpers';
import { BelegTexte, DocumentHeaderAndFooterImageAlignment } from '../../../types';

export type BelegTexteFormValues = {
  belegKopfBildFileId?: string | null;
  belegKopfBildAlignment?: DocumentHeaderAndFooterImageAlignment | null;
  tempBelegKopfBildFileId?: string | null;
  belegFussBildFileId?: string | null;
  belegFussBildAlignment?: DocumentHeaderAndFooterImageAlignment | null;
  tempBelegFussBildFileId?: string | null;
  belegKopf?: FormFileReference;
  belegFuss?: FormFileReference;

  belegKopfText?: EditorState | string;
  belegKopfTextAsHtml?: string | null;
  belegFussText?: EditorState | string;
  belegFussTextAsHtml?: string | null;
};

export const belegTexteFormFields: FormFields<BelegTexteFormValues> = {
  belegKopfBildFileId: 'belegKopfBildFileId',
  belegKopfBildAlignment: 'belegKopfBildAlignment',
  tempBelegKopfBildFileId: 'tempBelegKopfBildFileId',
  belegFussBildFileId: 'belegFussBildFileId',
  belegFussBildAlignment: 'belegFussBildAlignment',
  tempBelegFussBildFileId: 'tempBelegFussBildFileId',
  belegKopf: 'belegKopf',
  belegFuss: 'belegFuss',
  belegKopfText: 'belegKopfText',
  belegKopfTextAsHtml: 'belegKopfTextAsHtml',
  belegFussText: 'belegFussText',
  belegFussTextAsHtml: 'belegFussTextAsHtml',
};

export const mapBelegTexteToFormValues = (belegTexte: BelegTexte): BelegTexteFormValues => ({
  belegKopfBildFileId: belegTexte.belegKopfBildFileInfo?.fileId,
  belegKopfBildAlignment: belegTexte.belegKopfBildAlignment?.value,
  belegFussBildFileId: belegTexte.belegFussBildFileInfo?.fileId,
  belegFussBildAlignment: belegTexte.belegFussBildAlignment?.value,
  belegKopfText: belegTexte.belegKopfText ? belegTexte.belegKopfText : undefined,
  belegKopfTextAsHtml: belegTexte.belegKopfText ? belegTexte.belegKopfTextAsHtml : undefined,
  belegFussText: belegTexte.belegFussText ? belegTexte.belegFussText : undefined,
  belegFussTextAsHtml: belegTexte.belegFussText ? belegTexte.belegFussTextAsHtml : undefined,
});
