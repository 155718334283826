import React, { FC } from 'react';
import { Button, Drawer, Space, Tabs, TabsProps, Typography } from 'antd';
import { Box } from 'rebass';
import { HistoryOutlined } from '@ant-design/icons';
import { FormattedNumber } from 'react-intl';
import { useTimelineOpen } from '../../../components/Timeline/useTimelineOpen';
import ContextMenu from '../../../components/Timeline/ContextMenu';
import NutzungsartVersionTimeline from '../Version/Nutzungsart/NutzungsartVersionTimeline';
import NutzflaecheVersionTimeline from '../Version/Nutzflaeche/NutzflaecheVersionTimeline';
import NutzwertVersionTimeline from '../Version/Nutzwert/NutzwertVersionTimeline';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import EntityWarningList from '../../../components/message/EntityWarningList';
import { isWegObjekt } from '../../Objekt/objektHelper';
import { Bestandseinheit } from '../../../types';

type InnerCardBestandseinheitNutzungProps = {
  objektId: string;
  bestandseinheit: Bestandseinheit;
  reloadBestandseinheit: () => void;
};

const InnerCardBestandseinheitNutzung: FC<InnerCardBestandseinheitNutzungProps> = ({ objektId, bestandseinheit, reloadBestandseinheit }) => {
  const { timelineDrawer, openTimelineUpdateDrawer, closeTimelineDrawer } = useTimelineOpen();

  const tabItemsInsideOfTab: TabsProps['items'] = [
    {
      key: '1',
      label: 'Nutzungsart',
      children: (
        <Box p={16}>
          <NutzungsartVersionTimeline
            objektId={objektId}
            bestandseinheitId={bestandseinheit.bestandseinheitId}
            reloadBestandseinheit={reloadBestandseinheit}
          />
        </Box>
      ),
    },
    {
      key: '2',
      label: 'Nutzfläche',
      children: (
        <Box p={16}>
          <NutzflaecheVersionTimeline
            objektId={objektId}
            bestandseinheitId={bestandseinheit.bestandseinheitId}
            reloadBestandseinheit={reloadBestandseinheit}
          />
        </Box>
      ),
    },
    isWegObjekt(bestandseinheit.objektVerwaltungsart.value) &&
      ({
        key: '3',
        label: 'Nutzwert',
        children: (
          <Box p={16}>
            <NutzwertVersionTimeline
              objektId={objektId}
              bestandseinheitId={bestandseinheit.bestandseinheitId}
              reloadBestandseinheit={reloadBestandseinheit}
            />
          </Box>
        ),
      } as any),
  ];

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span>
          <HistoryOutlined />
          Verlauf
        </span>
      ),
      children: <Tabs items={tabItemsInsideOfTab} size="small" />,
    },
  ];

  return (
    <>
      <ContextMenu openDrawerCreate={openTimelineUpdateDrawer} withHistory />
      <Typography.Title level={5}>Nutzung</Typography.Title>
      <CardDetailsInfoRow infoRowTitle="Nutzungsart">
        <Space size={4}>
          <Typography.Text>{bestandseinheit.nutzungsArt?.text ?? 'Keine Nutzungsart angegeben'}</Typography.Text>
          {hasWarningAboutNutzungsart(bestandseinheit) && <EntityWarningList warningList={bestandseinheit.warningList} />}
        </Space>
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Nutzfläche">
        {bestandseinheit.nutzflaeche ? (
          <FormattedNumber value={bestandseinheit.nutzflaeche} maximumFractionDigits={2}>
            {(text: string) => <>{`${text} m²`}</>}
          </FormattedNumber>
        ) : (
          'Keine Nutzfläche angegeben'
        )}
      </CardDetailsInfoRow>
      {isWegObjekt(bestandseinheit.objektVerwaltungsart.value) && (
        <CardDetailsInfoRow infoRowTitle="Nutzwert">
          <Typography.Text>
            {bestandseinheit.nutzwert ? <FormattedNumber value={bestandseinheit.nutzwert} /> : 'Kein Nutzwert angegeben'}
          </Typography.Text>
        </CardDetailsInfoRow>
      )}

      <Drawer
        title="Bearbeiten"
        width={720}
        open={timelineDrawer.visible}
        onClose={closeTimelineDrawer}
        destroyOnClose
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={closeTimelineDrawer} type="primary">
              Schließen
            </Button>
          </div>
        }
      >
        <Tabs items={items} />
      </Drawer>
    </>
  );
};

const hasWarningAboutNutzungsart = (bestandseinheit: Bestandseinheit): boolean =>
  bestandseinheit.warningList?.some((warning) => warning.attribute === 'nutzungsArt');

export default InnerCardBestandseinheitNutzung;
