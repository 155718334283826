import React, { FC } from 'react';
import { Col, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useKontoQuery } from '../../Kontenplan/gql/KontenplanQueries.types';
import { generatePathToKontoDetailsPage } from '../../Kontenplan/kontoUriPaths';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';

type Props = {
  text: string;
  kontoId?: string | null;
};

const AllgemeineEinstellungKonto: FC<Props> = ({ text, kontoId }) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data } = useKontoQuery({ variables: { kontoId: kontoId! }, skip: !kontoId });

  const konto = data?.getKonto.data;

  return (
    <Row gutter={16}>
      <Col span={8}>
        <Typography.Text>{text}</Typography.Text>
      </Col>
      <Col span={16}>
        {konto ? (
          <DataWithShortenedText maxTextLength={35} text={`${konto.nummer} - ${konto.bezeichnung}`}>
            {(shortenedText) => <Link to={generatePathToKontoDetailsPage(konto.kontoId)}>{shortenedText}</Link>}
          </DataWithShortenedText>
        ) : (
          <TextForEmptyValue textToShow="minus" />
        )}
      </Col>
    </Row>
  );
};

export default AllgemeineEinstellungKonto;
