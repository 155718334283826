import { mapToQueryString, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../../verarbeitungApiPaths';
import { FiltersFormValues } from './listingFiltersFormMapper';

enum QueryParamKey {
  OBJEKT_ID = 'objektId',
  PAYMENT_TYPE = 'paymentType',
  PAYMENT_TRANSACTION_TYPE = 'paymentTransactionType',
  BUCHUNGSKREIS_RECHTSTRAEGER_ID = 'buchungskreisRechtstraegerId',
  EXIT_CODE_LIST = 'exitCodeList',
}

export type TBookingSuggestionVerbuchenQueryParams = QueryParams<FiltersFormValues>;

export const updateQueryParams = (
  navigate: (path: string) => void,
  verarbeitungType: string,
  generierlaufId: string,
  filters: TBookingSuggestionVerbuchenQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToBookingSuggestionVerbuchenVerarbeitungPage(verarbeitungType, generierlaufId, filters, paginationParams));

export const generatePathToBookingSuggestionVerbuchenVerarbeitungPage = (
  verarbeitungType: string,
  generierlaufId: string,
  filters: TBookingSuggestionVerbuchenQueryParams,
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [QueryParamKey.OBJEKT_ID]: filters?.objektId,
    [QueryParamKey.PAYMENT_TYPE]: filters?.paymentType,
    [QueryParamKey.PAYMENT_TRANSACTION_TYPE]: filters?.paymentTransactionType,
    [QueryParamKey.BUCHUNGSKREIS_RECHTSTRAEGER_ID]: filters?.buchungskreisRechtstraegerId,
    [QueryParamKey.EXIT_CODE_LIST]: filters?.exitCodeList,
    ...paginationParams,
  })}`;
