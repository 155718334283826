import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import { TextbausteinFormValues } from '../../../../../../components/Template/PDFTemplates/templateMapper';
import TemplateObjektAbrechnung from '../TemplateObjektAbrechnung';
import ObjAbrDefGeneralSettingsForm from './ObjAbrDefGeneralSettingsForm/ObjAbrDefGeneralSettingsForm';
import { ObjAbrDefGeneralSettingsFormValues } from './ObjAbrDefGeneralSettingsForm/objAbrDefGeneralSettingsFormMapper';
import { BkOrWeAbrDefObjektOutputOptionsFieldHelpNames } from '../../../../abrDefFieldHelpHelpers/abrDefFieldHelpMapper';

type Props = {
  objektAbrTemplateValues: TextbausteinFormValues[];
  objAbrDefGeneralSettingsValues: ObjAbrDefGeneralSettingsFormValues;
  onObjektAbrDefTemplateChange: (templateValues: TextbausteinFormValues[]) => void;
  onObjAbrDefGeneralSettingsChange: (values: ObjAbrDefGeneralSettingsFormValues) => void;
  fieldHelpNames?: BkOrWeAbrDefObjektOutputOptionsFieldHelpNames;
};

const BkOrWeObjAbrDefTemplate: FC<Props> = ({
  objektAbrTemplateValues,
  onObjektAbrDefTemplateChange,
  objAbrDefGeneralSettingsValues,
  onObjAbrDefGeneralSettingsChange,
  fieldHelpNames,
}) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Allgemeine Einstellungen',
      children: (
        <ObjAbrDefGeneralSettingsForm
          values={objAbrDefGeneralSettingsValues}
          onSubmit={onObjAbrDefGeneralSettingsChange}
          fieldHelpNames={fieldHelpNames}
        />
      ),
    },
    {
      key: '2',
      label: 'Texte',
      children: (
        <TemplateObjektAbrechnung
          values={objektAbrTemplateValues}
          onTemplateChange={onObjektAbrDefTemplateChange}
          objAbrDefGeneralSettingsValues={objAbrDefGeneralSettingsValues}
        />
      ),
    },
  ];

  return <Tabs defaultActiveKey="Allgemeine Einstellungen" items={items} />;
};

export default BkOrWeObjAbrDefTemplate;
