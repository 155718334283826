import React, { FC } from 'react';
import { showSuccessMsgDelete } from '../../../../../../components/message';
import { useDeleteObjektInfoFeldMutation } from '../../../gql/ObjektInfoFeldMutations.types';
import GrundstuecksdatenUpdateForm from '../../../Form/Grundstuecksdaten/Update/GrundstuecksdatenUpdateForm';
import { ObjektInfoFeldGrundstuecksdaten } from '../../../../../../types';
import InfoFeldListItemActions from '../InfoFeldListItemActions';
import useTicketCount from '../../../../../Ticket/useTicketCount';

type Props = {
  infoFeld: ObjektInfoFeldGrundstuecksdaten;
  objektId: string;
  onSuccess: () => void;
};

const GrundstuecksdatenListItemActions: FC<Props> = ({ infoFeld, objektId, onSuccess }) => {
  const { ticketCount, refetchTicketCount } = useTicketCount({ objektInfoFeldId: infoFeld.objektInfoFeldId });

  const onActionSuccess = () => {
    onSuccess();
    refetchTicketCount();
  };

  const [runDelete] = useDeleteObjektInfoFeldMutation({
    variables: { objektId, infoFeldId: infoFeld.objektInfoFeldId },
    onCompleted: () => {
      showSuccessMsgDelete(`Grundstücksdaten Infofeld`);
      onActionSuccess();
    },
  });

  return (
    <InfoFeldListItemActions
      onDelete={runDelete}
      ticketCount={ticketCount}
      objektId={objektId}
      objektInfoFeldId={infoFeld.objektInfoFeldId}
      fileIdList={infoFeld.fileIdList}
      onSuccess={onActionSuccess}
      postIt={infoFeld.postIt}
      deletable={infoFeld.deletable}
      infoFeldStatus={infoFeld.status}
      renderUpdateForm={(closeDrawer, activeKeyTab) => (
        <GrundstuecksdatenUpdateForm
          infoFeld={infoFeld}
          objektId={objektId}
          onInfoFeldAction={() => {
            onActionSuccess();
            closeDrawer();
          }}
          onTicketAction={onActionSuccess}
          onCancel={closeDrawer}
          activeKeyTab={activeKeyTab}
        />
      )}
    />
  );
};

export default GrundstuecksdatenListItemActions;
