import React, { FC } from 'react';
import { Modal } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Formik } from 'formik';
import { DatePicker, Form } from 'formik-antd';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../components/DatePicker/DatePicker_formikAntD';
import FormButtons from '../../../../components/Button/FormButtons';
import {
  mapFormValuesToVstKrzFreigeben,
  VstKrzFreigbenModalValues,
  vstKrzFreigebenModalFormFields,
  vstKrzFreigebenModalFormInitialValues,
} from './vstKrzFreigbenModalFormMapper';
import { useVorsteuerkuerzungFreigebenMutation } from '../../gql/VorsteuerkuerzungMutations.types';
import { showSuccessMsgFreigegeben } from '../../../../components/message/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { vstKrzFreigbenModalFormValidationSchema } from './vstKrzFreigbenModalFormValidationSchema';
import { Vorsteuerkuerzung } from '../../../../types';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  objektId: string;
  vstKuerzung: Vorsteuerkuerzung;
  visible: boolean;
  onAction(): void;
};

const VstKrzFreigebenModal: FC<Props> = ({ objektId, vstKuerzung, visible, onAction }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'VorsteuerkuerzungActionDataFreigeben'>('VorsteuerkuerzungActionDataFreigeben');

  const [runFreigeben, { loading }] = useVorsteuerkuerzungFreigebenMutation({
    onCompleted: () => {
      showSuccessMsgFreigegeben('Vorsteuerkürzung');
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Modal
      open={visible}
      title={
        <>
          <QuestionCircleTwoTone twoToneColor="#FAAD14" />
          <span style={{ paddingLeft: '8px' }}>Möchten Sie die Vorsteuerkürzung verbuchen?</span>
        </>
      }
      footer={null}
      onCancel={onAction}
      destroyOnClose
      maskClosable={false}
    >
      <Formik<VstKrzFreigbenModalValues>
        initialValues={vstKrzFreigebenModalFormInitialValues}
        validationSchema={vstKrzFreigbenModalFormValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          runFreigeben({
            variables: {
              objektId,
              vorsteuerkuerzungId: vstKuerzung.vorsteuerkuerzungId,
              input: mapFormValuesToVstKrzFreigeben(values),
            },
          }).finally(() => {
            setSubmitting(false);
          });
        }}
      >
        {(formikProps) => (
          <Form>
            <FormItemWithFieldHelp
              name={vstKrzFreigebenModalFormFields.buchungsdatum}
              label="Buchungsdatum"
              fieldHelp={getFieldHelpText('VorsteuerkuerzungActionDataFreigeben.buchungsdatum')}
            >
              <DatePicker
                id={vstKrzFreigebenModalFormFields.buchungsdatum}
                name={vstKrzFreigebenModalFormFields.buchungsdatum}
                placeholder="Datum wählen"
                format={DATE_DISPLAY_FORMAT_DEFAULT}
                allowClear={false}
                size="small"
                style={{ width: '180px' }}
              />
            </FormItemWithFieldHelp>
            <FormButtons okText="Verbuchen" onCancel={onAction} isOkDisabled={formikProps.isSubmitting} isOkLoading={loading} updateMode={false} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default VstKrzFreigebenModal;
