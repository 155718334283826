import React, { FC } from 'react';
import { Formik } from 'formik';
import { ITimeblock } from '../../../../../components/Timeline/timelineCtx';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { Verwaltungsart, VorsteuerkuerzungEinstellung } from '../../../../../types';
import { isWegObjekt } from '../../../../Objekt/objektHelper';
import {
  useCreateVorsteuerkuerzungEinstellungTemplateMutation,
  useUpdateVorsteuerkuerzungEinstellungTemplateMutation,
} from '../../../gql/VorsteuerkuerzungEinstellungTemplateMutations.types';
import {
  mapFormValuesToVstKuerzungKontenZuweisungVersionCreate,
  mapFormValuesToVstKuerzungKontenZuweisungVersionUpdate,
  mapTimeblockToFormValues,
  VorsteuerkuerzungKontenZuweisungVersionFormValues,
} from '../../../../Vorsteuerkuerzung/KontenZuweisung/Version/Form/vstKuerzungKontenZuweisungVersionFormMapper';
import {
  vstKuerzungKontenZuweisungVersionMietObjektFormValidationSchema,
  vstKuerzungKontenZuweisungVersionWeObjektFormValidationSchema,
} from '../../../../Vorsteuerkuerzung/KontenZuweisung/Version/Form/vstKuerzungKontenZuweisungVersionFormValidationSchema';
import VstKuerzungTemplateKontenZuweisungVersionFormContent from './VstKuerzungTemplateKontenZuweisungVersionFormContent';

type Props = {
  verwaltungsart: Verwaltungsart;
  onSubmitSuccess: () => void;
  timeblock: ITimeblock<VorsteuerkuerzungEinstellung>;
};

const VstKuerzungTemplateKontenZuweisungVersionForm: FC<Props> = ({ verwaltungsart, timeblock, onSubmitSuccess }) => {
  const isUpdate = !!timeblock.vorsteuerkuerzungEinstellungId;
  const entity = 'Vorsteuerkürzung-Kontenzuweisung';

  const [runCreate, { loading: loadingCreate }] = useCreateVorsteuerkuerzungEinstellungTemplateMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSubmitSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateVorsteuerkuerzungEinstellungTemplateMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSubmitSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const isLoading = loadingCreate || loadingUpdate;

  return (
    <Formik<VorsteuerkuerzungKontenZuweisungVersionFormValues>
      initialValues={mapTimeblockToFormValues(timeblock)}
      validationSchema={
        isWegObjekt(verwaltungsart)
          ? vstKuerzungKontenZuweisungVersionWeObjektFormValidationSchema
          : vstKuerzungKontenZuweisungVersionMietObjektFormValidationSchema
      }
      onSubmit={(values, { setSubmitting }) => {
        if (!isUpdate) {
          const input = mapFormValuesToVstKuerzungKontenZuweisungVersionCreate(values, verwaltungsart);
          runCreate({ variables: { input } }).finally(() => setSubmitting(false));
        } else {
          const input = mapFormValuesToVstKuerzungKontenZuweisungVersionUpdate(values, verwaltungsart);
          runUpdate({
            variables: { vorsteuerkuerzungEinstellungId: timeblock.vorsteuerkuerzungEinstellungId, input },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <VstKuerzungTemplateKontenZuweisungVersionFormContent
          formikProps={formikProps}
          timeblock={timeblock}
          isLoading={isLoading}
          verwaltungsart={verwaltungsart}
        />
      )}
    </Formik>
  );
};

export default VstKuerzungTemplateKontenZuweisungVersionForm;
