import React from 'react';
import { FormikProps } from 'formik';
import { ColumnProps } from 'antd/es/table';
import { Typography } from 'antd';
import FormattedDateTime from '../../../../../../../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../../../../../../../components/Card';
import SaldoOffenerBetragColumn from './SaldoOffenerBetragColumn';
import AmountColumn from './AmountColumn';
import { AuszifferungBlockFormValues, AuszifferungFormValues, OffenePostenForBooking } from '../../../auszifferungFormMapper';
import AuszifferungActionColumn from './AuszifferungActionColumn/AuszifferungActionColumn';
import AuszifferungSourceLink from '../../../../../../shared/AuszifferungSourceLink';
import { isAuszifferungStorniert } from '../../../auszifferungFormHelpers';
import StorniertStatus from '../../../../../../../shared/StorniertStatus';
import { belongsAuszifferungToAnotherZahlung, isAuszifferungSourceStorno } from '../../../../../../shared/auszifferungHelpers';

export const auszifferungTableColumns = (
  offenePosten: OffenePostenForBooking,
  formikProps: FormikProps<AuszifferungBlockFormValues>,
  bookingZahlungId: string,
  isOPForPersonenkonto: boolean
): ColumnProps<AuszifferungFormValues>[] => {
  return [
    {
      title: 'Auszifferung',
      dataIndex: 'auszifferung',
      align: 'right',
      render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
    },
    {
      title: '',
      dataIndex: 'zahlungId',
      align: 'left',
      render: (text, record) => {
        if (isAuszifferungSourceStorno(record.source?.value) || belongsAuszifferungToAnotherZahlung(bookingZahlungId, record.zahlungId)) {
          return null;
        }

        return <Typography.Text italic>aus dieser Zahlung</Typography.Text>;
      },
    },
    {
      title: 'Betrag',
      dataIndex: 'betrag',
      width: 400,
      align: 'right',
      render: (text, record) => (
        <Typography.Text delete={isAuszifferungStorniert(record)}>
          <AmountColumn record={record} formikValues={formikProps.values} isOPForPersonenkonto={isOPForPersonenkonto} offenePosten={offenePosten} />
        </Typography.Text>
      ),
    },
    {
      title: 'Saldo offener Betrag',
      dataIndex: 'offenerBetrag',
      align: 'right',
      render: (text, record) => (
        <SaldoOffenerBetragColumn
          auszifferung={record}
          formikValues={formikProps.values}
          isOPForPersonenkonto={isOPForPersonenkonto}
          offenePosten={offenePosten}
        />
      ),
    },
    {
      title: 'Erfasst von',
      dataIndex: 'erfasstVon',
      align: 'right',
      render: (text, record) => (
        <MitarbeiterTooltip alignment="right" mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} showsInitials />
      ),
    },
    {
      title: '',
      dataIndex: 'source',
      width: 160,
      render: (text, record) =>
        record.source && record.buchungId ? (
          <AuszifferungSourceLink
            bookingZahlungId={bookingZahlungId}
            buchungId={record.buchungId}
            source={record.source}
            zahlungId={record.zahlungId}
          />
        ) : null,
    },
    {
      dataIndex: 'stornoStatus',
      align: 'right',
      width: 300,
      render: (text, record) =>
        record.storniert && record.storniertTs && record.storniertByMitarbeiterId ? (
          <StorniertStatus storniertByMitarbeiterId={record.storniertByMitarbeiterId} storniertTs={record.storniertTs} />
        ) : null,
    },
    {
      title: 'Aktion',
      dataIndex: 'deleteAuszifferung',
      align: 'right',
      width: 90,
      render: (text, record) => {
        // record.zahlungId is undefined when the new record (=Auszifferung) was added to the list
        // we cannot delete (storno) an existing Auszifferung if it is already storniert or if it belongs to another Zahlung
        if (
          isAuszifferungSourceStorno(record.source?.value) ||
          isAuszifferungStorniert(record) ||
          belongsAuszifferungToAnotherZahlung(bookingZahlungId, record.zahlungId)
        ) {
          return null;
        }

        return <AuszifferungActionColumn bookingZahlungId={bookingZahlungId} formikProps={formikProps} offenePosten={offenePosten} record={record} />;
      },
    },
  ];
};
