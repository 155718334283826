import React, { FC } from 'react';
import { Input } from 'formik-antd';
import { Col, Row } from 'antd';
import SalutationSelect from '../Salutation/SalutationSelect';
import { SalutationTuple } from '../../../types';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';

type Props = {
  kundenSystemId?: string;
  fieldNamePrefix?: string;
  salutationOptionList?: SalutationTuple[];
  layout?: Record<string, unknown>;
  fieldHelpNames?: {
    firstname?: string | null;
    lastname?: string | null;
    salutation?: string | null;
    title?: string | null;
    titleTrailing?: string | null;
  };
};

const PersonFormPart: FC<Props> = ({ kundenSystemId, fieldNamePrefix = '', salutationOptionList, layout, fieldHelpNames }) => (
  <>
    <Row gutter={8}>
      <Col span={8}>
        <SalutationSelect
          {...layout}
          name={`${fieldNamePrefix}salutation`}
          salutationOptionList={salutationOptionList}
          kundenSystemId={kundenSystemId}
          fieldHelp={fieldHelpNames?.salutation}
        />
      </Col>
      <Col span={8}>
        <FormItemWithFieldHelp name={`${fieldNamePrefix}title`} label="Titel" fieldHelp={fieldHelpNames?.title}>
          <Input {...layout} id={`${fieldNamePrefix}title`} name={`${fieldNamePrefix}title`} placeholder="Titel" />
        </FormItemWithFieldHelp>
      </Col>
      <Col span={8}>
        <FormItemWithFieldHelp name={`${fieldNamePrefix}titleTrailing`} label="Titel nachlaufend" fieldHelp={fieldHelpNames?.titleTrailing}>
          <Input {...layout} id={`${fieldNamePrefix}titleTrailing`} name={`${fieldNamePrefix}titleTrailing`} placeholder="Titel nachlaufend" />
        </FormItemWithFieldHelp>
      </Col>
    </Row>
    <Row gutter={8}>
      <Col span={12}>
        <FormItemWithFieldHelp name={`${fieldNamePrefix}firstname`} label="Vorname" fieldHelp={fieldHelpNames?.firstname}>
          <Input {...layout} id={`${fieldNamePrefix}firstname`} name={`${fieldNamePrefix}firstname`} placeholder="Vorname" />
        </FormItemWithFieldHelp>
      </Col>
      <Col flex={12}>
        <FormItemWithFieldHelp name={`${fieldNamePrefix}lastname`} label="Nachname" fieldHelp={fieldHelpNames?.lastname}>
          <Input {...layout} id={`${fieldNamePrefix}lastname`} name={`${fieldNamePrefix}lastname`} placeholder="Nachname" />
        </FormItemWithFieldHelp>
      </Col>
    </Row>
  </>
);

export default PersonFormPart;
