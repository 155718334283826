import React, { FC, PropsWithChildren } from 'react';
import { ModalStaticFunctions } from 'antd/es/modal/confirm';
import { Modal } from 'antd';
import createCtx from '../../helpers/createCtx';

export type ModalContext = {
  modal: Omit<ModalStaticFunctions, 'warn'>;
};

const [useModalProvider, ModalContextProvider] = createCtx<ModalContext>();

export const useCustomModal = () => {
  const { modal } = useModalProvider();
  return modal;
};

const ModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [modal, contextHolder] = Modal.useModal();

  return (
    <ModalContextProvider value={{ modal }}>
      <div>{contextHolder}</div>
      {children}
    </ModalContextProvider>
  );
};

export default ModalProvider;
