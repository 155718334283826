import styled from 'styled-components';
import { Table as TableAntd } from 'antd';

export const StyledExpandedSingleLineTable = styled(TableAntd)`
  &&& {
    margin-bottom: 8px !important;
  }
  .ant-table-small {
    border: none !important;
  }
  .ant-table-small.ant-table-bordered .ant-table-content {
    border-right: none !important;
  }
` as any;
