import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateKsUstKategorieMutationVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
  input: Types.UstKategorieCreateInput;
}>;

export type CreateKsUstKategorieMutation = {
  createKSUstKategorie: {
    data: { ustKategorieId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateFdUstKategorieMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  input: Types.UstKategorieCreateInput;
}>;

export type CreateFdUstKategorieMutation = {
  createFDUstKategorie: {
    data: { ustKategorieId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateUstKategorieMutationVariables = Types.Exact<{
  ustKategorieId: Types.Scalars['ID']['input'];
  input: Types.UstKategorieUpdateInput;
}>;

export type UpdateUstKategorieMutation = {
  updateUstKategorie: {
    data: { ustKategorieId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ArchiveUstKategorieMutationVariables = Types.Exact<{
  ustKategorieId: Types.Scalars['ID']['input'];
}>;

export type ArchiveUstKategorieMutation = {
  actionArchiveUstKategorie: {
    data: { ustKategorieId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveUstKategorieMutationVariables = Types.Exact<{
  ustKategorieId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveUstKategorieMutation = {
  actionUnarchiveUstKategorie: {
    data: { ustKategorieId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteUstKategorieMutationVariables = Types.Exact<{
  ustKategorieId: Types.Scalars['ID']['input'];
}>;

export type DeleteUstKategorieMutation = {
  deleteUstKategorie: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateKsUstKategorieDocument = gql`
  mutation CreateKSUstKategorie($kundenSystemId: ID!, $input: UstKategorieCreateInput!) {
    createKSUstKategorie(kundenSystemId: $kundenSystemId, input: $input) {
      data {
        ustKategorieId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateKsUstKategorieMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateKsUstKategorieMutation, CreateKsUstKategorieMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateKsUstKategorieMutation, CreateKsUstKategorieMutationVariables>(CreateKsUstKategorieDocument, options);
}
export type CreateKsUstKategorieMutationHookResult = ReturnType<typeof useCreateKsUstKategorieMutation>;
export type CreateKsUstKategorieMutationResult = Apollo.MutationResult<CreateKsUstKategorieMutation>;
export type CreateKsUstKategorieMutationOptions = Apollo.BaseMutationOptions<CreateKsUstKategorieMutation, CreateKsUstKategorieMutationVariables>;
export const CreateFdUstKategorieDocument = gql`
  mutation CreateFDUstKategorie($firmendatenId: ID!, $input: UstKategorieCreateInput!) {
    createFDUstKategorie(firmendatenId: $firmendatenId, input: $input) {
      data {
        ustKategorieId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateFdUstKategorieMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFdUstKategorieMutation, CreateFdUstKategorieMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFdUstKategorieMutation, CreateFdUstKategorieMutationVariables>(CreateFdUstKategorieDocument, options);
}
export type CreateFdUstKategorieMutationHookResult = ReturnType<typeof useCreateFdUstKategorieMutation>;
export type CreateFdUstKategorieMutationResult = Apollo.MutationResult<CreateFdUstKategorieMutation>;
export type CreateFdUstKategorieMutationOptions = Apollo.BaseMutationOptions<CreateFdUstKategorieMutation, CreateFdUstKategorieMutationVariables>;
export const UpdateUstKategorieDocument = gql`
  mutation UpdateUstKategorie($ustKategorieId: ID!, $input: UstKategorieUpdateInput!) {
    updateUstKategorie(ustKategorieId: $ustKategorieId, input: $input) {
      data {
        ustKategorieId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateUstKategorieMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUstKategorieMutation, UpdateUstKategorieMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUstKategorieMutation, UpdateUstKategorieMutationVariables>(UpdateUstKategorieDocument, options);
}
export type UpdateUstKategorieMutationHookResult = ReturnType<typeof useUpdateUstKategorieMutation>;
export type UpdateUstKategorieMutationResult = Apollo.MutationResult<UpdateUstKategorieMutation>;
export type UpdateUstKategorieMutationOptions = Apollo.BaseMutationOptions<UpdateUstKategorieMutation, UpdateUstKategorieMutationVariables>;
export const ArchiveUstKategorieDocument = gql`
  mutation ArchiveUstKategorie($ustKategorieId: ID!) {
    actionArchiveUstKategorie(ustKategorieId: $ustKategorieId) {
      data {
        ustKategorieId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveUstKategorieMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveUstKategorieMutation, ArchiveUstKategorieMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveUstKategorieMutation, ArchiveUstKategorieMutationVariables>(ArchiveUstKategorieDocument, options);
}
export type ArchiveUstKategorieMutationHookResult = ReturnType<typeof useArchiveUstKategorieMutation>;
export type ArchiveUstKategorieMutationResult = Apollo.MutationResult<ArchiveUstKategorieMutation>;
export type ArchiveUstKategorieMutationOptions = Apollo.BaseMutationOptions<ArchiveUstKategorieMutation, ArchiveUstKategorieMutationVariables>;
export const UnarchiveUstKategorieDocument = gql`
  mutation UnarchiveUstKategorie($ustKategorieId: ID!) {
    actionUnarchiveUstKategorie(ustKategorieId: $ustKategorieId) {
      data {
        ustKategorieId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveUstKategorieMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveUstKategorieMutation, UnarchiveUstKategorieMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveUstKategorieMutation, UnarchiveUstKategorieMutationVariables>(UnarchiveUstKategorieDocument, options);
}
export type UnarchiveUstKategorieMutationHookResult = ReturnType<typeof useUnarchiveUstKategorieMutation>;
export type UnarchiveUstKategorieMutationResult = Apollo.MutationResult<UnarchiveUstKategorieMutation>;
export type UnarchiveUstKategorieMutationOptions = Apollo.BaseMutationOptions<UnarchiveUstKategorieMutation, UnarchiveUstKategorieMutationVariables>;
export const DeleteUstKategorieDocument = gql`
  mutation DeleteUstKategorie($ustKategorieId: ID!) {
    deleteUstKategorie(ustKategorieId: $ustKategorieId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteUstKategorieMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUstKategorieMutation, DeleteUstKategorieMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUstKategorieMutation, DeleteUstKategorieMutationVariables>(DeleteUstKategorieDocument, options);
}
export type DeleteUstKategorieMutationHookResult = ReturnType<typeof useDeleteUstKategorieMutation>;
export type DeleteUstKategorieMutationResult = Apollo.MutationResult<DeleteUstKategorieMutation>;
export type DeleteUstKategorieMutationOptions = Apollo.BaseMutationOptions<DeleteUstKategorieMutation, DeleteUstKategorieMutationVariables>;
