import { ObjektInfoFeldType } from '../../../types';
import { FormFields } from '../../../helpers/formikHelper';

export type ObjektInfoFeldListingFiltersFormValues = {
  type?: ObjektInfoFeldType;
};

export const objektInfoFeldListingFiltersFormFields: FormFields<ObjektInfoFeldListingFiltersFormValues> = {
  type: 'type',
};
