import { mapToQueryString, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../../verarbeitungApiPaths';

enum UstVomAufwandVerarbeitungQueryParamKey {
  OBJEKT_ID = 'objektId',
  BUCHUNG_STATUS = 'buchungStatus',
}

export type TUstVomAufwandVerarbeitungQueryParams = QueryParams<{
  objektId: string;
  buchungStatus: string[];
}>;

export const updateQueryParams = (
  verarbeitungType: string,
  generierlaufId: string,
  navigate: (path: string) => void,
  filters: TUstVomAufwandVerarbeitungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) =>
  navigate(generatePathToUstVomAufwandVerarbeitungPage(verarbeitungType, generierlaufId, filters.objektId, filters.buchungStatus, paginationParams));

const generatePathToUstVomAufwandVerarbeitungPage = (
  verarbeitungType: string,
  generierlaufId: string,
  objektId?: string,
  buchungStatus?: string[],
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [UstVomAufwandVerarbeitungQueryParamKey.OBJEKT_ID]: objektId,
    [UstVomAufwandVerarbeitungQueryParamKey.BUCHUNG_STATUS]: buchungStatus,
    ...paginationParams,
  })}`;
