import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { useHeAbrechnungsdefinitionTemplateVersionQuery } from '../../../../../../features/AbrechnungsdefinitionTemplate/gql/HeAbrDef/HeAbrDefTemplateVersionQueries.types';
import HeAbrDefTemplateVersionView from '../../../../../../features/AbrechnungsdefinitionTemplate/Version/View/HeAbrDefTemplateVersionView';

type Props = {
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersionId: string;
  isHistoricized: boolean;
};
const AndromedaSysSettingsHeAbrechnungsdefinitionVersionViewPage: FC<Props> = ({
  abrechnungsdefinitionId,
  abrechnungsdefinitionVersionId,
  isHistoricized,
}) => {
  const { data } = useHeAbrechnungsdefinitionTemplateVersionQuery({
    variables: { abrechnungsdefinitionId, abrechnungsdefinitionVersionId, searchInHistory: isHistoricized },
  });
  const abrechnungsdefinitionVersion = data?.getHeAbrechnungsdefinitionTemplateVersion.data;

  if (!abrechnungsdefinitionVersion) return <Skeleton active />;

  return (
    <HeAbrDefTemplateVersionView
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      abrechnungsdefinitionVersion={abrechnungsdefinitionVersion}
      isHistoricized={isHistoricized}
    />
  );
};

export default AndromedaSysSettingsHeAbrechnungsdefinitionVersionViewPage;
