import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { DebitorSumData } from '../../op-list-debitor-types';
import pdfStyles from '../../../../styles/pdfStyles';
import { opDebitorListSummaryColumnWidthAndAlignment } from './op-debitor-table-styles';

type Props = {
  debitorListSum: DebitorSumData;
};

const OPDebitorListSummary: FC<Props> = ({ debitorListSum }) => (
  <View style={[pdfStyles.row, pdfStyles.textBulletin, { fontWeight: 'bold', marginTop: '8mm' }]}>
    <Text style={[pdfStyles.column, opDebitorListSummaryColumnWidthAndAlignment.emptyFirstCol]} />
    <Text style={[pdfStyles.column, opDebitorListSummaryColumnWidthAndAlignment.sumText]}>{debitorListSum.sumText}</Text>
    <Text style={[pdfStyles.column, opDebitorListSummaryColumnWidthAndAlignment.sumBrutto]}>{debitorListSum.sumBrutto}</Text>
    <Text style={[pdfStyles.column, opDebitorListSummaryColumnWidthAndAlignment.sumZahlungsbetrag]}>{debitorListSum.sumZahlungsbetrag}</Text>
    <Text style={[pdfStyles.column, opDebitorListSummaryColumnWidthAndAlignment.sumOffenerBetrag]}>{debitorListSum.sumOffenerBetrag}</Text>
    <Text style={[pdfStyles.column, opDebitorListSummaryColumnWidthAndAlignment.emptyLastCol]} />
  </View>
);

export default OPDebitorListSummary;
