import * as Yup from 'yup';
import { entityIsRequired } from '../../../../components/message/validationMsg';
import { PaymentBelegSymbol, PaymentBuchungType } from '../../../../types';
import { bookingSuggestionFormFields } from './buchungsmaskeFormMapper';

/* eslint-disable no-template-curly-in-string */

export const buchungszeileValidationSchema = Yup.array().of(
  Yup.object().shape({
    amount: Yup.number().required(entityIsRequired('Betrag')),
    buchungszeileId: Yup.string().nullable(),
    kontoId: Yup.string().required(entityIsRequired('Konto')),
    text: Yup.string().required(entityIsRequired('Buchungstext')),
  })
);

export const buchungsanweisungValidationSchema = Yup.object().shape({
  belegSymbol: Yup.mixed<PaymentBelegSymbol>().oneOf(Object.values(PaymentBelegSymbol)).required(entityIsRequired('Belegsymbol')),
  buchungskreisId: Yup.string().required(entityIsRequired('Buchungskreis')),
  buchungskreisRechtstraegerId: Yup.string().required(entityIsRequired('Buchungskreis')),
  buchungszeileList: buchungszeileValidationSchema,
  buchungType: Yup.mixed<PaymentBuchungType>().oneOf(Object.values(PaymentBuchungType)).required(entityIsRequired('Buchungstyp')),
});

export const bookingSuggestionUpdateFormValidationSchema = Yup.object().shape({
  [bookingSuggestionFormFields.buchungsanweisung]: buchungsanweisungValidationSchema,
});

/* eslint-enable no-template-curly-in-string */
