import React, { FC } from 'react';
import { Button } from 'antd';
import { Budgeting } from '../../../../types';
import BudgetingVPosTable from './Level1/BudgetingVPosTable';
import ListingTitleWithButtons from '../../../../components/Listing/ListingTitleWithButtons';
import NestedTableWithColSelectorWrapper from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelectorWrapper';

type Props = {
  budgeting: Budgeting;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: () => Promise<unknown>;
  isUpdating: boolean;
  updatingRowKey: string | null;
  setUpdatingRowKey: React.Dispatch<React.SetStateAction<string | null>>;
  showArchived: boolean;
  toggleShowArchived: () => void;
};

const BudgetingDetailsListing: FC<Props> = ({
  budgeting,
  isLoading,
  setIsLoading,
  refetch,
  isUpdating,
  updatingRowKey,
  setUpdatingRowKey,
  showArchived,
  toggleShowArchived,
}) => {
  return (
    <>
      <ListingTitleWithButtons
        title=""
        buttons={
          <Button size="small" onClick={toggleShowArchived}>
            Archivierte Einträge {showArchived ? 'ausblenden' : 'anzeigen'}
          </Button>
        }
      />
      <NestedTableWithColSelectorWrapper filterIdentifier="budgeting-main">
        <BudgetingVPosTable
          budgeting={budgeting}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          refetch={refetch}
          isUpdating={isUpdating}
          updatingRowKey={updatingRowKey}
          setUpdatingRowKey={setUpdatingRowKey}
        />
      </NestedTableWithColSelectorWrapper>
    </>
  );
};

export default BudgetingDetailsListing;
