import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateVertragVposIndexedValueGenerierlaufMutationVariables = Types.Exact<{
  input: Types.VertragVposIndexedValueGenerierlaufCreateInput;
}>;

export type CreateVertragVposIndexedValueGenerierlaufMutation = {
  createVertragVposIndexedValueGenerierlauf: {
    data: {
      countFehler: number;
      countVerarbeitet: number;
      countNichtVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierDatum: string;
      generierlaufId: string;
      errorList: Array<{ message: string; type: string }>;
      status: { text: string; value: Types.GenerierlaufStatus; description?: string | null };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type RestartVertragVposIndexedValueGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartVertragVposIndexedValueGenerierlaufMutation = {
  actionRestartVertragVposIndexedValueGenerierlauf: {
    data: {
      countFehler: number;
      countVerarbeitet: number;
      countNichtVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierDatum: string;
      generierlaufId: string;
      errorList: Array<{ message: string; type: string }>;
      status: { text: string; value: Types.GenerierlaufStatus; description?: string | null };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateVertragVposIndexedValueGenerierlaufDocument = gql`
  mutation CreateVertragVposIndexedValueGenerierlauf($input: VertragVposIndexedValueGenerierlaufCreateInput!) {
    createVertragVposIndexedValueGenerierlauf(input: $input) {
      data {
        countFehler
        countVerarbeitet
        countNichtVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        generierDatum
        generierlaufId
        status {
          text
          value
          description
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateVertragVposIndexedValueGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVertragVposIndexedValueGenerierlaufMutation,
    CreateVertragVposIndexedValueGenerierlaufMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVertragVposIndexedValueGenerierlaufMutation, CreateVertragVposIndexedValueGenerierlaufMutationVariables>(
    CreateVertragVposIndexedValueGenerierlaufDocument,
    options
  );
}
export type CreateVertragVposIndexedValueGenerierlaufMutationHookResult = ReturnType<typeof useCreateVertragVposIndexedValueGenerierlaufMutation>;
export type CreateVertragVposIndexedValueGenerierlaufMutationResult = Apollo.MutationResult<CreateVertragVposIndexedValueGenerierlaufMutation>;
export type CreateVertragVposIndexedValueGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  CreateVertragVposIndexedValueGenerierlaufMutation,
  CreateVertragVposIndexedValueGenerierlaufMutationVariables
>;
export const RestartVertragVposIndexedValueGenerierlaufDocument = gql`
  mutation RestartVertragVposIndexedValueGenerierlauf($generierlaufId: ID!) {
    actionRestartVertragVposIndexedValueGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countVerarbeitet
        countNichtVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        generierDatum
        generierlaufId
        status {
          text
          value
          description
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartVertragVposIndexedValueGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestartVertragVposIndexedValueGenerierlaufMutation,
    RestartVertragVposIndexedValueGenerierlaufMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestartVertragVposIndexedValueGenerierlaufMutation, RestartVertragVposIndexedValueGenerierlaufMutationVariables>(
    RestartVertragVposIndexedValueGenerierlaufDocument,
    options
  );
}
export type RestartVertragVposIndexedValueGenerierlaufMutationHookResult = ReturnType<typeof useRestartVertragVposIndexedValueGenerierlaufMutation>;
export type RestartVertragVposIndexedValueGenerierlaufMutationResult = Apollo.MutationResult<RestartVertragVposIndexedValueGenerierlaufMutation>;
export type RestartVertragVposIndexedValueGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartVertragVposIndexedValueGenerierlaufMutation,
  RestartVertragVposIndexedValueGenerierlaufMutationVariables
>;
