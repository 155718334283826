import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import ObjAbrDefGeneralSettingsForm from '../../shared/Templates/shared/BkOrWe/ObjAbrDefGeneralSettingsForm/ObjAbrDefGeneralSettingsForm';
import ObjektAbrechnungTemplate from '../../../../pages/PDFTemplates/templates/objektAbrechnung/ObjektAbrechnungTemplate';
import { mapTextbausteinToObjektAbrechnungSelectableSection } from '../../shared/Templates/WeTemplates/weAbrDefTemplateFormObjAbrMapper';
import AbrDefVersionViewTemplate from './AbrDefVersionViewTemplate';
import { ObjektAbrechnung } from '../../../../pages/PDFTemplates/templates/objektAbrechnung/objektAbrechnung-types';
import { TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';
import { ObjAbrDefGeneralSettingsFormValues } from '../../shared/Templates/shared/BkOrWe/ObjAbrDefGeneralSettingsForm/objAbrDefGeneralSettingsFormMapper';

type Props = {
  objAbrDefTemplateValues: TextbausteinFormValues[];
  objAbrData: ObjektAbrechnung;
  objAbrDefGeneralSettingsValues: ObjAbrDefGeneralSettingsFormValues;
};

const BkOrWeObjAbrDefVersionView: FC<Props> = ({ objAbrDefTemplateValues, objAbrData, objAbrDefGeneralSettingsValues }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Allgemeine Einstellungen',
      children: <ObjAbrDefGeneralSettingsForm values={objAbrDefGeneralSettingsValues} />,
    },
    {
      key: '2',
      label: 'Texte',
      children: (
        <AbrDefVersionViewTemplate
          values={objAbrDefTemplateValues}
          renderPreview={({ selectedTextbaustein }) => (
            <ObjektAbrechnungTemplate
              data={objAbrData}
              selectedTextbaustein={mapTextbausteinToObjektAbrechnungSelectableSection(selectedTextbaustein)}
            />
          )}
        />
      ),
    },
  ];

  return <Tabs defaultActiveKey="Allgemeine Einstellungen" items={items} />;
};

export default BkOrWeObjAbrDefVersionView;
