import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import { UstKategorieVersionFieldsFragmentDoc } from './UstKategorieVersionFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UstKategorieVersionListQueryVariables = Types.Exact<{
  ustKategorieId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type UstKategorieVersionListQuery = {
  getUstKategorieVersionList: {
    data: Array<{
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      lastUpdateTs: string;
      ustKategorieVersionId: string;
      ustVomAufwand: number;
      validFrom: string;
      versionIndex: number;
      vstKuerzung: boolean;
      historicizedList?: Array<{
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        lastUpdateTs: string;
        ustKategorieVersionId: string;
        ustVomAufwand: number;
        validFrom: string;
        versionIndex: number;
        vstKuerzung: boolean;
        ustKategorieEntryList: Array<{
          additionKonto: number;
          buchungsKZ?: string | null;
          createTs?: string | null;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          rechnungskennzeichen: string;
          steuersatz: number;
          ustKategorieEntryId?: string | null;
          ustKz: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      ustKategorieEntryList: Array<{
        additionKonto: number;
        buchungsKZ?: string | null;
        createTs?: string | null;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        rechnungskennzeichen: string;
        steuersatz: number;
        ustKategorieEntryId?: string | null;
        ustKz: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UstKategorieVersionQueryVariables = Types.Exact<{
  ustKategorieId: Types.Scalars['ID']['input'];
  ustKategorieVersionId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type UstKategorieVersionQuery = {
  getUstKategorieVersion: {
    data: {
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      lastUpdateTs: string;
      ustKategorieVersionId: string;
      ustVomAufwand: number;
      validFrom: string;
      versionIndex: number;
      vstKuerzung: boolean;
      historicizedList?: Array<{
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        lastUpdateTs: string;
        ustKategorieVersionId: string;
        ustVomAufwand: number;
        validFrom: string;
        versionIndex: number;
        vstKuerzung: boolean;
        ustKategorieEntryList: Array<{
          additionKonto: number;
          buchungsKZ?: string | null;
          createTs?: string | null;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          rechnungskennzeichen: string;
          steuersatz: number;
          ustKategorieEntryId?: string | null;
          ustKz: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      ustKategorieEntryList: Array<{
        additionKonto: number;
        buchungsKZ?: string | null;
        createTs?: string | null;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        rechnungskennzeichen: string;
        steuersatz: number;
        ustKategorieEntryId?: string | null;
        ustKz: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const UstKategorieVersionListDocument = gql`
  query UstKategorieVersionList($ustKategorieId: ID!, $withDetails: Boolean) {
    getUstKategorieVersionList(ustKategorieId: $ustKategorieId, withDetails: $withDetails) {
      data {
        ...UstKategorieVersionFields
        historicizedList {
          ...UstKategorieVersionFields
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${UstKategorieVersionFieldsFragmentDoc}
`;
export function useUstKategorieVersionListQuery(
  baseOptions: Apollo.QueryHookOptions<UstKategorieVersionListQuery, UstKategorieVersionListQueryVariables> &
    ({ variables: UstKategorieVersionListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstKategorieVersionListQuery, UstKategorieVersionListQueryVariables>(UstKategorieVersionListDocument, options);
}
export function useUstKategorieVersionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UstKategorieVersionListQuery, UstKategorieVersionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstKategorieVersionListQuery, UstKategorieVersionListQueryVariables>(UstKategorieVersionListDocument, options);
}
export function useUstKategorieVersionListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UstKategorieVersionListQuery, UstKategorieVersionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstKategorieVersionListQuery, UstKategorieVersionListQueryVariables>(UstKategorieVersionListDocument, options);
}
export type UstKategorieVersionListQueryHookResult = ReturnType<typeof useUstKategorieVersionListQuery>;
export type UstKategorieVersionListLazyQueryHookResult = ReturnType<typeof useUstKategorieVersionListLazyQuery>;
export type UstKategorieVersionListSuspenseQueryHookResult = ReturnType<typeof useUstKategorieVersionListSuspenseQuery>;
export type UstKategorieVersionListQueryResult = Apollo.QueryResult<UstKategorieVersionListQuery, UstKategorieVersionListQueryVariables>;
export const UstKategorieVersionDocument = gql`
  query UstKategorieVersion($ustKategorieId: ID!, $ustKategorieVersionId: ID!, $withDetails: Boolean) {
    getUstKategorieVersion(ustKategorieId: $ustKategorieId, ustKategorieVersionId: $ustKategorieVersionId, withDetails: $withDetails) {
      data {
        ...UstKategorieVersionFields
        historicizedList {
          ...UstKategorieVersionFields
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${UstKategorieVersionFieldsFragmentDoc}
`;
export function useUstKategorieVersionQuery(
  baseOptions: Apollo.QueryHookOptions<UstKategorieVersionQuery, UstKategorieVersionQueryVariables> &
    ({ variables: UstKategorieVersionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstKategorieVersionQuery, UstKategorieVersionQueryVariables>(UstKategorieVersionDocument, options);
}
export function useUstKategorieVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UstKategorieVersionQuery, UstKategorieVersionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstKategorieVersionQuery, UstKategorieVersionQueryVariables>(UstKategorieVersionDocument, options);
}
export function useUstKategorieVersionSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UstKategorieVersionQuery, UstKategorieVersionQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstKategorieVersionQuery, UstKategorieVersionQueryVariables>(UstKategorieVersionDocument, options);
}
export type UstKategorieVersionQueryHookResult = ReturnType<typeof useUstKategorieVersionQuery>;
export type UstKategorieVersionLazyQueryHookResult = ReturnType<typeof useUstKategorieVersionLazyQuery>;
export type UstKategorieVersionSuspenseQueryHookResult = ReturnType<typeof useUstKategorieVersionSuspenseQuery>;
export type UstKategorieVersionQueryResult = Apollo.QueryResult<UstKategorieVersionQuery, UstKategorieVersionQueryVariables>;
