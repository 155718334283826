import { Konto, KontoCreateInput, KontoKlasse, KontoType, KontoUpdateInput, KontoVerwendung } from '../../../types';
import { FormFields } from '../../../helpers/formikHelper';
import { ktoNumberPrefix } from './kontoFormHelpers';

export interface KontoFormValues {
  klasse: KontoKlasse | undefined;
  type: KontoType;
  verwendung: KontoVerwendung | undefined;
  bezeichnung: string;
  nummer: string;
}

const kontoFormInitialValues: KontoFormValues = {
  klasse: undefined,
  verwendung: undefined,
  bezeichnung: '',
  nummer: '',
  type: KontoType.Sachkonto,
};

export const mapKontoToFormValues = (konto?: Konto): KontoFormValues => {
  if (!konto) {
    return kontoFormInitialValues;
  }
  return {
    klasse: konto.klasse.value,
    type: konto.type.value,
    verwendung: konto.verwendung?.value,
    bezeichnung: konto.bezeichnung,
    nummer: konto.nummer.slice(1), //should show number without the prefix
  };
};

export const mapFormValuesKonto = (values: KontoFormValues): KontoCreateInput | KontoUpdateInput => {
  return {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    klasse: values.klasse!,
    type: values.type,
    verwendung: values.verwendung,
    bezeichnung: values.bezeichnung,
    nummer: `${ktoNumberPrefix(values.klasse, values.type, values.verwendung)}${values.nummer}`,
  };
};

export const kontoFormFields: FormFields<KontoFormValues> = {
  klasse: 'klasse',
  type: 'type',
  verwendung: 'verwendung',
  nummer: 'nummer',
  bezeichnung: 'bezeichnung',
};
