import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { TReceivedBankTransactionQueryParams } from './filtersQueryParams';
import { filtersFormFields, FiltersFormValues, listingLabelList } from './filtersFormMapper';
import useFilterWithQueryParams from '../../../shared/useFilterWithQueryParams';
import FiltersWith4OrMoreFields from '../../../../components/Filters/FiltersWith4OrMoreFields';
import FiltersSelect from '../../../../components/Filters/FiltersSelect';
import { onFilterSelectChange } from '../../../../components/Filters/FiltersHelpers';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import NotedFormSelect from '../../../shared/NotedFormSelect';
import ReceivedBankTransactionStatementNumberSelect from './ReceivedBankTransactionStatementNumberSelect';
import FromToFormDatePicker from '../../../shared/FromToForm/FromToFormDatePicker';
import ReceivedBankTransactionCreditorIbanSelect from './ReceivedBankTransactionCreditorIbanSelect';
import ReceivedBankTransactionCreditorSelect from './ReceivedBankTransactionCreditorSelect';
import ReceivedBankTransactionDebtorSelect from './ReceivedBankTransactionDebtorSelect';
import ReceivedBankTransactionDebtorIbanSelect from './ReceivedBankTransactionDebtorIbanSelect';
import FromToFormInputNumber from '../../../shared/FromToForm/FromToFormInputNumber';
import ReceivedBankTransactionPaymentReferenceTextSelect from './ReceivedBankTransactionPaymentReferenceTextSelect';
import ReceivedBankTransactionPurposeOfUseTextSelect from './ReceivedBankTransactionPurposeOfUseTextSelect';
import MitarbeiterSelect from '../../../../shared/components/MitarbeiterSelect/MitarbeiterSelect';
import CamtNameListFormSelect from '../../../DataCarrier/Listing/Bankstatement/Filters/CamtNameListFormSelect';
import TransactionTypeFormSelect from '../../shared/TransactionTypeFormSelect';
import ReceivedBankTransactionListingActionButtons from '../ReceivedBankTransactionListingActionButtons';
import ReceivedBankTransactionTableBulkActions from '../ReceivedBankTransactionTableBulkActions';
import YesOrNoFormSelect from '../../../shared/YesOrNoFormSelect';
import ReceivedBankTransactionEndToEndIdSelect from './ReceivedBankTransactionEndToEndIdSelect';
import ReceivedBankTransactionStatusSelect from './ReceivedBankTransactionStatusSelect';
import ValidOrInvalidFormSelect from '../../../shared/ValidOrInvalidFormSelect';
import ReceivedBankTransactionBankstatementIbanSelect from './ReceivedBankTransactionBankstatementIbanSelect';

type Props = {
  queryParams: TReceivedBankTransactionQueryParams;
  formikProps: FormikProps<FiltersFormValues>;
  showArchived: boolean;
  toggleShowArchived: () => void;
  onAction: () => void;
};

const ListingFilters: FC<Props> = ({ queryParams, formikProps, toggleShowArchived, showArchived, onAction }) => {
  const defaultSelectedFilterList = [
    filtersFormFields.valueDateFrom,
    filtersFormFields.status,
    filtersFormFields.booked,
    filtersFormFields.paymentAssigned,
    filtersFormFields.statementNumber,
    filtersFormFields.bankStatementIban,
  ];

  const mapFilterLabelToQueryFilterList = (filter: string) => {
    if (filter === filtersFormFields.valueDateFrom || filter === filtersFormFields.valueDateTo) {
      return filtersFormFields.valueDateFrom;
    }
    if (filter === filtersFormFields.amountFrom || filter === filtersFormFields.amountTo) {
      return filtersFormFields.amountFrom;
    }
    if (filter === filtersFormFields.chargesFrom || filter === filtersFormFields.chargesTo) {
      return filtersFormFields.chargesFrom;
    }
    if (filter === filtersFormFields.createTsFrom || filter === filtersFormFields.createTsTo) {
      return filtersFormFields.createTsFrom;
    }
    if (filter === filtersFormFields.bookingDateFrom || filter === filtersFormFields.bookingDateTo) {
      return filtersFormFields.bookingDateFrom;
    }
    return filter;
  };

  const updateFilterValues = (selectedFilterList: string[]) => {
    if (!selectedFilterList.includes(filtersFormFields.valueDateFrom) && formikProps.values.valueDateTo) {
      formikProps.setFieldValue(filtersFormFields.valueDateTo, undefined);
    }
    if (!selectedFilterList.includes(filtersFormFields.amountFrom) && formikProps.values.amountTo) {
      formikProps.setFieldValue(filtersFormFields.amountTo, undefined);
    }
    if (!selectedFilterList.includes(filtersFormFields.chargesFrom) && formikProps.values.chargesTo) {
      formikProps.setFieldValue(filtersFormFields.chargesTo, undefined);
    }
    if (!selectedFilterList.includes(filtersFormFields.createTsFrom) && formikProps.values.createTsTo) {
      formikProps.setFieldValue(filtersFormFields.createTsTo, undefined);
    }
    if (!selectedFilterList.includes(filtersFormFields.bookingDateFrom) && formikProps.values.bookingDateTo) {
      formikProps.setFieldValue(filtersFormFields.bookingDateTo, undefined);
    }
    formikProps.submitForm();
  };

  const filters = (filter: string) => {
    if (filter === filtersFormFields.noted) {
      return (
        <FormItemWithoutColon name={filtersFormFields.noted} label={listingLabelList.noted} key={filter}>
          <NotedFormSelect name={filtersFormFields.noted} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.dataCarrierName) {
      return (
        <FormItemWithoutColon name={filtersFormFields.dataCarrierName} label={listingLabelList.dataCarrierName} key={filter}>
          <CamtNameListFormSelect name={filtersFormFields.dataCarrierName} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.transactionType) {
      return (
        <FormItemWithoutColon name={filtersFormFields.transactionType} label={listingLabelList.transactionType} key={filter}>
          <TransactionTypeFormSelect name={filtersFormFields.transactionType} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.statementNumber) {
      return (
        <FormItemWithoutColon name={filtersFormFields.statementNumber} label={listingLabelList.statementNumber} key={filter}>
          <ReceivedBankTransactionStatementNumberSelect name={filtersFormFields.statementNumber} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.valueDateFrom) {
      return (
        <FormItemWithoutColon name="valutadatum" label={listingLabelList.valueDateFrom} key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={filtersFormFields.valueDateFrom}
            toName={filtersFormFields.valueDateTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.creditor) {
      return (
        <FormItemWithoutColon name={filtersFormFields.creditor} label={listingLabelList.creditor} key={filter}>
          <ReceivedBankTransactionCreditorSelect name={filtersFormFields.creditor} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.creditorIban) {
      return (
        <FormItemWithoutColon name={filtersFormFields.creditorIban} label={listingLabelList.creditorIban} key={filter}>
          <ReceivedBankTransactionCreditorIbanSelect name={filtersFormFields.creditorIban} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.debtor) {
      return (
        <FormItemWithoutColon name={filtersFormFields.debtor} label={listingLabelList.debtor} key={filter}>
          <ReceivedBankTransactionDebtorSelect name={filtersFormFields.debtor} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.debtorIban) {
      return (
        <FormItemWithoutColon name={filtersFormFields.debtorIban} label={listingLabelList.debtorIban} key={filter}>
          <ReceivedBankTransactionDebtorIbanSelect name={filtersFormFields.debtorIban} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.amountFrom) {
      return (
        <FromToFormInputNumber
          name="Betrag"
          fromName={filtersFormFields.amountFrom}
          toName={filtersFormFields.amountTo}
          onChange={formikProps.submitForm}
          key={filter}
        />
      );
    }
    if (filter === filtersFormFields.chargesFrom) {
      return (
        <FromToFormInputNumber
          name="Spesen"
          fromName={filtersFormFields.chargesFrom}
          toName={filtersFormFields.chargesTo}
          onChange={formikProps.submitForm}
          key={filter}
        />
      );
    }
    if (filter === filtersFormFields.reversal) {
      return (
        <FormItemWithoutColon name={filtersFormFields.reversal} label={listingLabelList.reversal} key={filter}>
          <YesOrNoFormSelect name={filtersFormFields.reversal} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.chargesInSeparateEntry) {
      return (
        <FormItemWithoutColon name={filtersFormFields.chargesInSeparateEntry} label={listingLabelList.chargesInSeparateEntry} key={filter}>
          <YesOrNoFormSelect name={filtersFormFields.chargesInSeparateEntry} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.purposeOfUseText) {
      return (
        <FormItemWithoutColon name={filtersFormFields.purposeOfUseText} label={listingLabelList.purposeOfUseText} key={filter}>
          <ReceivedBankTransactionPurposeOfUseTextSelect
            name={filtersFormFields.purposeOfUseText}
            purposeOfUse={formikProps.values.purposeOfUseText}
            onChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.paymentReferenceText) {
      return (
        <FormItemWithoutColon name={filtersFormFields.paymentReferenceText} label={listingLabelList.paymentReferenceText} key={filter}>
          <ReceivedBankTransactionPaymentReferenceTextSelect
            name={filtersFormFields.paymentReferenceText}
            paymentReference={formikProps.values.paymentReferenceText}
            onChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.createTsFrom) {
      return (
        <FormItemWithoutColon name="erstelltAm" label={listingLabelList.createTsFrom} key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={filtersFormFields.createTsFrom}
            toName={filtersFormFields.createTsTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.createdByMitarbeiterIdList) {
      return (
        <FormItemWithoutColon name={filtersFormFields.createdByMitarbeiterIdList} label={listingLabelList.createdByMitarbeiterIdList} key={filter}>
          <MitarbeiterSelect name={filtersFormFields.createdByMitarbeiterIdList} onChange={formikProps.submitForm} mode="multiple" />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.endToEndId) {
      return (
        <FormItemWithoutColon name={filtersFormFields.endToEndId} label={listingLabelList.endToEndId} key={filter}>
          <ReceivedBankTransactionEndToEndIdSelect name={filtersFormFields.endToEndId} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.status) {
      return (
        <FormItemWithoutColon name={filtersFormFields.status} label={listingLabelList.status} key={filter}>
          <ReceivedBankTransactionStatusSelect name={filtersFormFields.status} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.booked) {
      return (
        <FormItemWithoutColon name={filtersFormFields.booked} label={listingLabelList.booked} key={filter}>
          <YesOrNoFormSelect name={filtersFormFields.booked} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.valid) {
      return (
        <FormItemWithoutColon name={filtersFormFields.valid} label={listingLabelList.valid} key={filter}>
          <ValidOrInvalidFormSelect name={filtersFormFields.valid} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.paymentAssigned) {
      return (
        <FormItemWithoutColon name={filtersFormFields.paymentAssigned} label={listingLabelList.paymentAssigned} key={filter}>
          <YesOrNoFormSelect name={filtersFormFields.paymentAssigned} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.bookingDateFrom) {
      return (
        <FormItemWithoutColon name="bookingDate" label={listingLabelList.bookingDateFrom} key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={filtersFormFields.bookingDateFrom}
            toName={filtersFormFields.bookingDateTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.bankStatementIban) {
      return (
        <FormItemWithoutColon name={filtersFormFields.bankStatementIban} label={listingLabelList.bankStatementIban} key={filter}>
          <ReceivedBankTransactionBankstatementIbanSelect name={filtersFormFields.bankStatementIban} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.hasWarning) {
      return (
        <FormItemWithoutColon name={filtersFormFields.hasWarning} label={listingLabelList.hasWarning} key={filter}>
          <YesOrNoFormSelect name={filtersFormFields.hasWarning} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    return undefined;
  };

  const { selectedFilterList, setSelectedFilterList, leftColumn, rightColumn } = useFilterWithQueryParams({
    defaultSelectedFilterList,
    updateFilterValues,
    filters,
    labelList: listingLabelList,
    mapFilterLabelToQueryFilterList,
    queryParams,
  });

  return (
    <FiltersWith4OrMoreFields
      filtersSelect={
        <FiltersSelect
          defaultSelectedFilterList={defaultSelectedFilterList}
          selectedFilterList={selectedFilterList}
          onChange={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
          labelList={listingLabelList}
        />
      }
      actionButtons={<ReceivedBankTransactionListingActionButtons showArchived={showArchived} toggleArchived={toggleShowArchived} />}
      filterActions={<ReceivedBankTransactionTableBulkActions onAction={onAction} queryParams={queryParams} />}
      hideTitle
      leftColumn={<>{leftColumn}</>}
      rightColumn={<>{rightColumn}</>}
    />
  );
};

export default ListingFilters;
