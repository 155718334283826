import React from 'react';
import styled from 'styled-components';

const StyledTooltipInner = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  * {
    color: white;
  }
`;

export default StyledTooltipInner;
