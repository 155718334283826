import React, { FC } from 'react';
import { Spin } from 'antd';
import TemplateAbrechnung from '../TemplateAbrechnung';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import { useBelegTexteFirmendatenQuery } from '../../../../Belegtext/gql/BelegtexteQueries.types';
import { useFirmendatenOperatingSiteListQuery } from '../../../../KundenSystem/Firmendaten/gql/FirmendatenOperatingSiteQueries.types';
import { useFDAuthorized } from '../../../../../shared/Auth/useAuthorized';
import { BelegTextePlatzhalter } from '../../../../Belegtext/Form/belegTexteFormTypes';
import { useFirmendatenQuery } from '../../../../KundenSystem/Firmendaten/gql/FirmendatenQueries.types';
import { findBelegTexteHauptAddress, findBelegTexteHauptEmail, findBelegTextePhoneNumber } from '../../../../shared/operatingSiteHelpers';
import HauseigentuemerAbrechnungTemplate from '../../../../../pages/PDFTemplates/templates/hauseigentuemerAbrechnung/HauseigentuemerAbrechnungTemplate';
import { createHeAbrechnungPDFTemplateData, mapTextbausteinToHeAbrechnungSelectableSection } from './heAbrDefTemplateFormMapper';
import { HeOrSubAbrDefGeneralSettingsFormValues } from '../shared/HeOrSub/GeneralSettingForm/heOrSubAbrDefGeneralSettingsFormMapper';

type Props = {
  values: TextbausteinFormValues[];
  onTemplateChange: (templateValues: TextbausteinFormValues[]) => void;
  abrDefGeneralSettingsValues: HeOrSubAbrDefGeneralSettingsFormValues;
};

const TemplateHeAbrechnung: FC<Props> = ({ values, onTemplateChange, abrDefGeneralSettingsValues }) => {
  const { activeForFirmendatenId: firmendatenId } = useFDAuthorized();

  const { data } = useFirmendatenQuery({ variables: { firmendatenId } });
  const firmendaten = data?.getFirmendaten.data;

  const { data: belegTexteData } = useBelegTexteFirmendatenQuery({ variables: { firmendatenId } });

  const belegTexte = belegTexteData?.getBelegTexteFirmendaten.data;

  const { data: firmendatenOperatingSiteListData } = useFirmendatenOperatingSiteListQuery({ variables: { firmendatenId } });
  const operatingSiteList = firmendatenOperatingSiteListData?.getFirmendatenOperatingSiteList.data ?? [];

  if (!firmendaten || !belegTexte || !operatingSiteList) {
    return <Spin />;
  }

  const belegTextePlatzhalter: BelegTextePlatzhalter = {
    hauptAddress: findBelegTexteHauptAddress(operatingSiteList),
    vatIdentificationNumber: firmendaten.vatIdentificationNumber,
    companyRegistrationNumber: firmendaten.companyRegistrationNumber,
    phoneNumber: findBelegTextePhoneNumber(operatingSiteList),
    email: findBelegTexteHauptEmail(operatingSiteList),
  };

  return (
    <TemplateAbrechnung
      values={values}
      onTemplateChange={onTemplateChange}
      renderPreview={({ selectedTextbaustein }) => (
        <HauseigentuemerAbrechnungTemplate
          data={createHeAbrechnungPDFTemplateData(values, abrDefGeneralSettingsValues, belegTexte, belegTextePlatzhalter, firmendatenId)}
          isInEditMode
          selectedTextbaustein={mapTextbausteinToHeAbrechnungSelectableSection(selectedTextbaustein)}
        />
      )}
    />
  );
};

export default TemplateHeAbrechnung;
