import React, { FC } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import pdfStyles from '../styles/pdfStyles';
import { SepaLastschriftMandat } from './sepaLastschriftMandat-types';
import SLMMandatsreferenz from './SLMMandatsreferenz';
import SLMErmaechtigungText from './SLMErmaechtigungText';
import SLMTwoColumns from './SLMTwoColumns';
import SLMZahlungsempfaengerBlock from './SLMZahlungsempfaengerBlock';
import SLMZahlungspflichtigerBlock from './SLMZahlungspflichtigerBlock';

type Props = {
  data: SepaLastschriftMandat;
};

const SepaLastschriftMandatTemplate: FC<Props> = ({ data }) => (
  <Document>
    <Page size="A4" style={pdfStyles.body}>
      <View>
        {/* Document title */}
        <Text style={[{ fontSize: '16px', textAlign: 'left', fontWeight: 'bold', paddingLeft: 40 }]}>{data.title}</Text>
      </View>
      {/* Mandatsreferenz */}
      <SLMMandatsreferenz mandatReference={data.mandatReference} />
      {/* Zahlungsempfänger block */}
      <SLMZahlungsempfaengerBlock data={data.zahlungsempfaengerBlock} />
      {/* Ermaechtigung text */}
      <SLMErmaechtigungText ermaechtigungTextLine1={data.ermaechtigungTextLine1} ermaechtigungTextLine2={data.ermaechtigungTextLine2} />
      {/* Zahlungspflichtiger block */}
      <SLMZahlungspflichtigerBlock data={data.zahlungspflichtigerBlock} />
      {/* City and Date block */}
      <SLMTwoColumns label={data.cityAndDateText} paddingTop="15mm" />
      {/* Signature block */}
      <SLMTwoColumns label={data.signatureText} paddingTop="10mm" />
    </Page>
  </Document>
);

export default SepaLastschriftMandatTemplate;
