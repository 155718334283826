import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { AppGenerierlaufType } from '../../../../../types';
import { useFilterGenLaufAppHauseigentuemerListQuery } from '../../../gql/GenerierlaufFilter/AppGenerierlaufFilterQueries.types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { selectFilterOption } from '../../../../../helpers/selectHelper';

type Props = {
  name: string;
  generierlaufId: string;
  generierlaufType: AppGenerierlaufType;
  maxTextLength?: number;
} & SelectProps;

const GenerierlaufHauseigentuemerFormSelect: FC<Props> = ({ name, generierlaufId, generierlaufType, maxTextLength, ...restProps }) => {
  const { data, loading } = useFilterGenLaufAppHauseigentuemerListQuery({
    variables: {
      generierlaufId,
      generierlaufType,
    },
  });

  const list = data?.getFilterGenLaufAppHauseigentuemerList.data ?? [];

  return (
    <Select
      size="small"
      loading={loading}
      id={name}
      name={name}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      placeholder="Hauseigentümer auswählen"
      {...restProps}
    >
      {list.map((hauseigentuemer) => (
        <Select.Option key={hauseigentuemer.rechtstraegerId} value={hauseigentuemer.rechtstraegerId}>
          <DataWithShortenedText maxTextLength={maxTextLength ?? hauseigentuemer.kurzBezeichnung.length} text={hauseigentuemer.kurzBezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default GenerierlaufHauseigentuemerFormSelect;
