import { verarbeitungTypes } from '../../../verarbeitungHelpers';

export const verarbeitungTypeAbkuerzungForAvatar = (verarbeitungType: string) => {
  switch (verarbeitungType) {
    case verarbeitungTypes.Budgeting:
      return 'BU';
    case verarbeitungTypes.InfoMailDeliveryDetermineRecipientList:
    case verarbeitungTypes.InfoMailDeliverySend:
      return 'AS';
    case verarbeitungTypes.VorschreibungMailDeliveryDetermineRecipientList:
    case verarbeitungTypes.VorschreibungMailDeliverySend:
      return 'AS';
    case verarbeitungTypes.MahnungAusgeben:
      return 'MA';
    case verarbeitungTypes.MahnungErzeugen:
      return 'ME';
    case verarbeitungTypes.MahnungVersenden:
      return 'MV';
    case verarbeitungTypes.MahnungVerbuchen:
      return 'MVB';
    case verarbeitungTypes.VorschreibungAusgeben:
      return 'VA';
    case verarbeitungTypes.VorschreibungErstellen:
      return 'VS';
    case verarbeitungTypes.VorschreibungVerbuchen:
      return 'VV';
    case verarbeitungTypes.WeObjektAbrechnung:
      return 'WO';
    case verarbeitungTypes.WeTopAbrechnung:
      return 'WT';
    case verarbeitungTypes.UstVomAufwand:
      return 'UA';
    case verarbeitungTypes.VorsteuerkuerzungErzeugen:
      return 'VE';
    case verarbeitungTypes.PaymentProposalCreate:
      return 'ZE';
    case verarbeitungTypes.PaymentProposalExecute:
      return 'ZD';
    case verarbeitungTypes.HeAbrechnung:
      return 'HE';
    case verarbeitungTypes.Zinsliste:
      return 'ZL';
    case verarbeitungTypes.BkObjektAbrechnung:
      return 'BO';
    case verarbeitungTypes.BkTopAbrechnung:
      return 'BT';
    case verarbeitungTypes.SubAbrechnung:
      return 'SA';
    case verarbeitungTypes.VertragVposIndexedValueManually:
      return 'IM';
    case verarbeitungTypes.VertragVposIndexedValueAutomatically:
      return 'IA';
    case verarbeitungTypes.ApplyVertragVposIndexedValue:
      return 'IW';
    case verarbeitungTypes.ObjektFinancialPlan:
      return 'OW';
    case verarbeitungTypes.TopFinancialPlan:
      return 'TW';
    case verarbeitungTypes.SyncEbicsUser:
      return 'BA';
    case verarbeitungTypes.Camt:
      return 'KA';
    case verarbeitungTypes.CamtEbicsManually:
      return 'KE';
    case verarbeitungTypes.CamtOnlineBankingManually:
      return 'KO';
    case verarbeitungTypes.DataCarrierPainCreation:
      return 'DE';
    case verarbeitungTypes.BookingSuggestionCreation:
      return 'BE';
    case verarbeitungTypes.IncomingInvoiceBookingCreation:
      return 'EV';
    case verarbeitungTypes.BookingSuggestionVerbuchen:
      return 'ZV';
    case verarbeitungTypes.FirmendatenInitializer:
      return 'FI';
    case verarbeitungTypes.ReceivedBankTransactionVerbuchen:
      return 'EBV';
    default:
      return '?';
  }
};
