import React, { FC } from 'react';
import { StyledSkeleton } from '../../../../components/Loading';
import { useObjektSummeAnteileListQuery } from '../gql/ObjektSummeAnteileQueries.types';
import { mapToVersionTimelineDataSourceWithAlwaysDeletableEntries } from '../../../../components/Timeline/versionTimelineHelper';
import VersionTimeline from '../../../../components/Timeline/VersionTimeline';
import { useDeleteObjektSummeAnteileMutation } from '../gql/ObjektSummeAnteileMutations.types';
import { showSuccessMsgDelete } from '../../../../components/message';
import ObjektSummeAnteileVersionCardContent from './ObjektSummeAnteileVersionCardContent';
import ObjektSummeAnteileVersionForm from './Form/ObjektSummeAnteileVersionForm';
import { gqlOperations } from '../../../../graphql/gqlNamedOperations-generated';
import { ObjektSummeAnteile } from '../../../../types';

type ObjektSummeAnteileDrawerProps = {
  objektId: string;
  onSuccess: () => void;
};

const ObjektSummeAnteileVersionTimeline: FC<ObjektSummeAnteileDrawerProps> = ({ objektId, onSuccess }) => {
  const { data, loading, refetch } = useObjektSummeAnteileListQuery({
    variables: {
      objektId,
    },
  });

  const onActionSuccess = () => {
    onSuccess();
    refetch();
  };

  const [runDelete] = useDeleteObjektSummeAnteileMutation({
    onCompleted: () => {
      showSuccessMsgDelete('Summe Anteile');
      refetch();
    },
    refetchQueries: [gqlOperations.Query.HeVertragList, gqlOperations.Query.ObjektSummeAnteile],
  });
  const summeAnteileList = data?.getObjektSummeAnteileList?.data ?? [];

  if (loading) {
    return <StyledSkeleton loading={loading} active paragraph={{ rows: 4 }} />;
  }

  return (
    <VersionTimeline<ObjektSummeAnteile>
      dataSource={mapToVersionTimelineDataSourceWithAlwaysDeletableEntries(summeAnteileList)}
      isAllowedToDeleteInitialTimeblock
      loading={loading}
      versionIdKey="objektSummeAnteileId"
      defaultNewTimeblock={{
        objektSummeAnteileId: '',
        warningList: summeAnteileList[0]?.warningList,
        summeAnteile: summeAnteileList[0]?.summeAnteile,
      }}
      renderForm={(timeblock) => <ObjektSummeAnteileVersionForm objektId={objektId} timeblock={timeblock} onSubmitSuccess={onActionSuccess} />}
      renderCardContent={(timeblock) => <ObjektSummeAnteileVersionCardContent timeblock={timeblock} />}
      kommentarForCardTitle={(timeblock) => timeblock.kommentar}
      onDelete={(timeblock) => {
        return runDelete({
          variables: {
            objektId,
            summeAnteileId: timeblock.objektSummeAnteileId,
          },
        }).finally(onActionSuccess);
      }}
    />
  );
};

export default ObjektSummeAnteileVersionTimeline;
