import React from 'react';
import { ColumnProps } from 'antd/es/table';
import { ChangeLog } from '../../../types';

const adminChangeLogTableColumns: ColumnProps<ChangeLog>[] = [
  {
    title: 'Typ',
    render: (text, record) => <span>{record.type}</span>,
  },
  {
    title: 'Aktion',
    render: (text, record) => <span>{record.changeType}</span>,
  },
  {
    title: 'Wert',
    render: (text, record) => (
      <span>
        {record.oldValue} von {record.newValue}
      </span>
    ),
  },
  {
    title: 'geändert von',
    render: (text, record) => <span>{record.changedBy}</span>,
  },
  {
    title: 'geändert am',
    render: (text, record) => <span>{record.changedTs}</span>,
  },
];

export default adminChangeLogTableColumns;
