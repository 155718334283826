import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FilterPaymentApprovedByMitarbeiterListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FilterPaymentApprovedByMitarbeiterListQuery = {
  getFilterPaymentApprovedByMitarbeiterList: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      firmendatenId: string;
      firstname: string;
      lastname: string;
      mitarbeiterId: string;
      updatedByMitarbeiterId?: string | null;
      username: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterPaymentBelegnummerListQueryVariables = Types.Exact<{
  belegnummer?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type FilterPaymentBelegnummerListQuery = {
  getFilterPaymentBelegnummerList: {
    data?: Array<string> | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterPaymentInvoiceIssuerIbanListQueryVariables = Types.Exact<{
  rechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type FilterPaymentInvoiceIssuerIbanListQuery = {
  getFilterPaymentInvoiceIssuerIbanList: {
    data?: Array<string> | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterPaymentPayeeIbanListQueryVariables = Types.Exact<{
  rechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type FilterPaymentPayeeIbanListQuery = {
  getFilterPaymentPayeeIbanList: {
    data?: Array<string> | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterPaymentSepaCreditorIdListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FilterPaymentSepaCreditorIdListQuery = {
  getFilterPaymentSepaCreditorIdList: {
    data?: Array<string> | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterPaymentSepaMandateReferenceListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FilterPaymentSepaMandateReferenceListQuery = {
  getFilterPaymentSepaMandateReferenceList: {
    data?: Array<string> | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterPaymentStatusListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FilterPaymentStatusListQuery = {
  getFilterPaymentStatusList: {
    data: Array<{ text: string; value: Types.OrderPaymentStatus }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterPaymentTransactionTypeListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FilterPaymentTransactionTypeListQuery = {
  getFilterPaymentTransactionTypeList: {
    data: Array<{ text: string; value: Types.PaymentTransactionType }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterPaymentTypeListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FilterPaymentTypeListQuery = {
  getFilterPaymentTypeList: {
    data: Array<{ text: string; value: Types.PaymentType }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterPaymentReferenceTextListQueryVariables = Types.Exact<{
  paymentReferenceText?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type FilterPaymentReferenceTextListQuery = {
  getFilterPaymentReferenceTextList: {
    data?: Array<string> | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterPaymentPurposeOfUseTextListQueryVariables = Types.Exact<{
  purposeOfUseText?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type FilterPaymentPurposeOfUseTextListQuery = {
  getFilterPaymentPurposeOfUseTextList: {
    data?: Array<string> | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const FilterPaymentApprovedByMitarbeiterListDocument = gql`
  query FilterPaymentApprovedByMitarbeiterList {
    getFilterPaymentApprovedByMitarbeiterList {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
        firmendatenId
        firstname
        lastname
        mitarbeiterId
        updatedByMitarbeiterId
        username
        warningList {
          attribute
          message
          type
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterPaymentApprovedByMitarbeiterListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterPaymentApprovedByMitarbeiterListQuery, FilterPaymentApprovedByMitarbeiterListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterPaymentApprovedByMitarbeiterListQuery, FilterPaymentApprovedByMitarbeiterListQueryVariables>(
    FilterPaymentApprovedByMitarbeiterListDocument,
    options
  );
}
export function useFilterPaymentApprovedByMitarbeiterListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterPaymentApprovedByMitarbeiterListQuery, FilterPaymentApprovedByMitarbeiterListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterPaymentApprovedByMitarbeiterListQuery, FilterPaymentApprovedByMitarbeiterListQueryVariables>(
    FilterPaymentApprovedByMitarbeiterListDocument,
    options
  );
}
export function useFilterPaymentApprovedByMitarbeiterListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterPaymentApprovedByMitarbeiterListQuery, FilterPaymentApprovedByMitarbeiterListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterPaymentApprovedByMitarbeiterListQuery, FilterPaymentApprovedByMitarbeiterListQueryVariables>(
    FilterPaymentApprovedByMitarbeiterListDocument,
    options
  );
}
export type FilterPaymentApprovedByMitarbeiterListQueryHookResult = ReturnType<typeof useFilterPaymentApprovedByMitarbeiterListQuery>;
export type FilterPaymentApprovedByMitarbeiterListLazyQueryHookResult = ReturnType<typeof useFilterPaymentApprovedByMitarbeiterListLazyQuery>;
export type FilterPaymentApprovedByMitarbeiterListSuspenseQueryHookResult = ReturnType<typeof useFilterPaymentApprovedByMitarbeiterListSuspenseQuery>;
export type FilterPaymentApprovedByMitarbeiterListQueryResult = Apollo.QueryResult<
  FilterPaymentApprovedByMitarbeiterListQuery,
  FilterPaymentApprovedByMitarbeiterListQueryVariables
>;
export const FilterPaymentBelegnummerListDocument = gql`
  query FilterPaymentBelegnummerList($belegnummer: String) {
    getFilterPaymentBelegnummerList(belegnummer: $belegnummer) {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterPaymentBelegnummerListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterPaymentBelegnummerListQuery, FilterPaymentBelegnummerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterPaymentBelegnummerListQuery, FilterPaymentBelegnummerListQueryVariables>(
    FilterPaymentBelegnummerListDocument,
    options
  );
}
export function useFilterPaymentBelegnummerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterPaymentBelegnummerListQuery, FilterPaymentBelegnummerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterPaymentBelegnummerListQuery, FilterPaymentBelegnummerListQueryVariables>(
    FilterPaymentBelegnummerListDocument,
    options
  );
}
export function useFilterPaymentBelegnummerListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterPaymentBelegnummerListQuery, FilterPaymentBelegnummerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterPaymentBelegnummerListQuery, FilterPaymentBelegnummerListQueryVariables>(
    FilterPaymentBelegnummerListDocument,
    options
  );
}
export type FilterPaymentBelegnummerListQueryHookResult = ReturnType<typeof useFilterPaymentBelegnummerListQuery>;
export type FilterPaymentBelegnummerListLazyQueryHookResult = ReturnType<typeof useFilterPaymentBelegnummerListLazyQuery>;
export type FilterPaymentBelegnummerListSuspenseQueryHookResult = ReturnType<typeof useFilterPaymentBelegnummerListSuspenseQuery>;
export type FilterPaymentBelegnummerListQueryResult = Apollo.QueryResult<
  FilterPaymentBelegnummerListQuery,
  FilterPaymentBelegnummerListQueryVariables
>;
export const FilterPaymentInvoiceIssuerIbanListDocument = gql`
  query FilterPaymentInvoiceIssuerIbanList($rechtstraegerId: String) {
    getFilterPaymentInvoiceIssuerIbanList(rechtstraegerId: $rechtstraegerId) {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterPaymentInvoiceIssuerIbanListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterPaymentInvoiceIssuerIbanListQuery, FilterPaymentInvoiceIssuerIbanListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterPaymentInvoiceIssuerIbanListQuery, FilterPaymentInvoiceIssuerIbanListQueryVariables>(
    FilterPaymentInvoiceIssuerIbanListDocument,
    options
  );
}
export function useFilterPaymentInvoiceIssuerIbanListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterPaymentInvoiceIssuerIbanListQuery, FilterPaymentInvoiceIssuerIbanListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterPaymentInvoiceIssuerIbanListQuery, FilterPaymentInvoiceIssuerIbanListQueryVariables>(
    FilterPaymentInvoiceIssuerIbanListDocument,
    options
  );
}
export function useFilterPaymentInvoiceIssuerIbanListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterPaymentInvoiceIssuerIbanListQuery, FilterPaymentInvoiceIssuerIbanListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterPaymentInvoiceIssuerIbanListQuery, FilterPaymentInvoiceIssuerIbanListQueryVariables>(
    FilterPaymentInvoiceIssuerIbanListDocument,
    options
  );
}
export type FilterPaymentInvoiceIssuerIbanListQueryHookResult = ReturnType<typeof useFilterPaymentInvoiceIssuerIbanListQuery>;
export type FilterPaymentInvoiceIssuerIbanListLazyQueryHookResult = ReturnType<typeof useFilterPaymentInvoiceIssuerIbanListLazyQuery>;
export type FilterPaymentInvoiceIssuerIbanListSuspenseQueryHookResult = ReturnType<typeof useFilterPaymentInvoiceIssuerIbanListSuspenseQuery>;
export type FilterPaymentInvoiceIssuerIbanListQueryResult = Apollo.QueryResult<
  FilterPaymentInvoiceIssuerIbanListQuery,
  FilterPaymentInvoiceIssuerIbanListQueryVariables
>;
export const FilterPaymentPayeeIbanListDocument = gql`
  query FilterPaymentPayeeIbanList($rechtstraegerId: String) {
    getFilterPaymentPayeeIbanList(rechtstraegerId: $rechtstraegerId) {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterPaymentPayeeIbanListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterPaymentPayeeIbanListQuery, FilterPaymentPayeeIbanListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterPaymentPayeeIbanListQuery, FilterPaymentPayeeIbanListQueryVariables>(FilterPaymentPayeeIbanListDocument, options);
}
export function useFilterPaymentPayeeIbanListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterPaymentPayeeIbanListQuery, FilterPaymentPayeeIbanListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterPaymentPayeeIbanListQuery, FilterPaymentPayeeIbanListQueryVariables>(FilterPaymentPayeeIbanListDocument, options);
}
export function useFilterPaymentPayeeIbanListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterPaymentPayeeIbanListQuery, FilterPaymentPayeeIbanListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterPaymentPayeeIbanListQuery, FilterPaymentPayeeIbanListQueryVariables>(
    FilterPaymentPayeeIbanListDocument,
    options
  );
}
export type FilterPaymentPayeeIbanListQueryHookResult = ReturnType<typeof useFilterPaymentPayeeIbanListQuery>;
export type FilterPaymentPayeeIbanListLazyQueryHookResult = ReturnType<typeof useFilterPaymentPayeeIbanListLazyQuery>;
export type FilterPaymentPayeeIbanListSuspenseQueryHookResult = ReturnType<typeof useFilterPaymentPayeeIbanListSuspenseQuery>;
export type FilterPaymentPayeeIbanListQueryResult = Apollo.QueryResult<FilterPaymentPayeeIbanListQuery, FilterPaymentPayeeIbanListQueryVariables>;
export const FilterPaymentSepaCreditorIdListDocument = gql`
  query FilterPaymentSepaCreditorIdList {
    getFilterPaymentSepaCreditorIdList {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterPaymentSepaCreditorIdListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterPaymentSepaCreditorIdListQuery, FilterPaymentSepaCreditorIdListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterPaymentSepaCreditorIdListQuery, FilterPaymentSepaCreditorIdListQueryVariables>(
    FilterPaymentSepaCreditorIdListDocument,
    options
  );
}
export function useFilterPaymentSepaCreditorIdListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterPaymentSepaCreditorIdListQuery, FilterPaymentSepaCreditorIdListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterPaymentSepaCreditorIdListQuery, FilterPaymentSepaCreditorIdListQueryVariables>(
    FilterPaymentSepaCreditorIdListDocument,
    options
  );
}
export function useFilterPaymentSepaCreditorIdListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterPaymentSepaCreditorIdListQuery, FilterPaymentSepaCreditorIdListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterPaymentSepaCreditorIdListQuery, FilterPaymentSepaCreditorIdListQueryVariables>(
    FilterPaymentSepaCreditorIdListDocument,
    options
  );
}
export type FilterPaymentSepaCreditorIdListQueryHookResult = ReturnType<typeof useFilterPaymentSepaCreditorIdListQuery>;
export type FilterPaymentSepaCreditorIdListLazyQueryHookResult = ReturnType<typeof useFilterPaymentSepaCreditorIdListLazyQuery>;
export type FilterPaymentSepaCreditorIdListSuspenseQueryHookResult = ReturnType<typeof useFilterPaymentSepaCreditorIdListSuspenseQuery>;
export type FilterPaymentSepaCreditorIdListQueryResult = Apollo.QueryResult<
  FilterPaymentSepaCreditorIdListQuery,
  FilterPaymentSepaCreditorIdListQueryVariables
>;
export const FilterPaymentSepaMandateReferenceListDocument = gql`
  query FilterPaymentSepaMandateReferenceList {
    getFilterPaymentSepaMandateReferenceList {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterPaymentSepaMandateReferenceListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterPaymentSepaMandateReferenceListQuery, FilterPaymentSepaMandateReferenceListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterPaymentSepaMandateReferenceListQuery, FilterPaymentSepaMandateReferenceListQueryVariables>(
    FilterPaymentSepaMandateReferenceListDocument,
    options
  );
}
export function useFilterPaymentSepaMandateReferenceListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterPaymentSepaMandateReferenceListQuery, FilterPaymentSepaMandateReferenceListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterPaymentSepaMandateReferenceListQuery, FilterPaymentSepaMandateReferenceListQueryVariables>(
    FilterPaymentSepaMandateReferenceListDocument,
    options
  );
}
export function useFilterPaymentSepaMandateReferenceListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterPaymentSepaMandateReferenceListQuery, FilterPaymentSepaMandateReferenceListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterPaymentSepaMandateReferenceListQuery, FilterPaymentSepaMandateReferenceListQueryVariables>(
    FilterPaymentSepaMandateReferenceListDocument,
    options
  );
}
export type FilterPaymentSepaMandateReferenceListQueryHookResult = ReturnType<typeof useFilterPaymentSepaMandateReferenceListQuery>;
export type FilterPaymentSepaMandateReferenceListLazyQueryHookResult = ReturnType<typeof useFilterPaymentSepaMandateReferenceListLazyQuery>;
export type FilterPaymentSepaMandateReferenceListSuspenseQueryHookResult = ReturnType<typeof useFilterPaymentSepaMandateReferenceListSuspenseQuery>;
export type FilterPaymentSepaMandateReferenceListQueryResult = Apollo.QueryResult<
  FilterPaymentSepaMandateReferenceListQuery,
  FilterPaymentSepaMandateReferenceListQueryVariables
>;
export const FilterPaymentStatusListDocument = gql`
  query FilterPaymentStatusList {
    getFilterPaymentStatusList {
      data {
        text
        value
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterPaymentStatusListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterPaymentStatusListQuery, FilterPaymentStatusListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterPaymentStatusListQuery, FilterPaymentStatusListQueryVariables>(FilterPaymentStatusListDocument, options);
}
export function useFilterPaymentStatusListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterPaymentStatusListQuery, FilterPaymentStatusListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterPaymentStatusListQuery, FilterPaymentStatusListQueryVariables>(FilterPaymentStatusListDocument, options);
}
export function useFilterPaymentStatusListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterPaymentStatusListQuery, FilterPaymentStatusListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterPaymentStatusListQuery, FilterPaymentStatusListQueryVariables>(FilterPaymentStatusListDocument, options);
}
export type FilterPaymentStatusListQueryHookResult = ReturnType<typeof useFilterPaymentStatusListQuery>;
export type FilterPaymentStatusListLazyQueryHookResult = ReturnType<typeof useFilterPaymentStatusListLazyQuery>;
export type FilterPaymentStatusListSuspenseQueryHookResult = ReturnType<typeof useFilterPaymentStatusListSuspenseQuery>;
export type FilterPaymentStatusListQueryResult = Apollo.QueryResult<FilterPaymentStatusListQuery, FilterPaymentStatusListQueryVariables>;
export const FilterPaymentTransactionTypeListDocument = gql`
  query FilterPaymentTransactionTypeList {
    getFilterPaymentTransactionTypeList {
      data {
        text
        value
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterPaymentTransactionTypeListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterPaymentTransactionTypeListQuery, FilterPaymentTransactionTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterPaymentTransactionTypeListQuery, FilterPaymentTransactionTypeListQueryVariables>(
    FilterPaymentTransactionTypeListDocument,
    options
  );
}
export function useFilterPaymentTransactionTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterPaymentTransactionTypeListQuery, FilterPaymentTransactionTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterPaymentTransactionTypeListQuery, FilterPaymentTransactionTypeListQueryVariables>(
    FilterPaymentTransactionTypeListDocument,
    options
  );
}
export function useFilterPaymentTransactionTypeListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterPaymentTransactionTypeListQuery, FilterPaymentTransactionTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterPaymentTransactionTypeListQuery, FilterPaymentTransactionTypeListQueryVariables>(
    FilterPaymentTransactionTypeListDocument,
    options
  );
}
export type FilterPaymentTransactionTypeListQueryHookResult = ReturnType<typeof useFilterPaymentTransactionTypeListQuery>;
export type FilterPaymentTransactionTypeListLazyQueryHookResult = ReturnType<typeof useFilterPaymentTransactionTypeListLazyQuery>;
export type FilterPaymentTransactionTypeListSuspenseQueryHookResult = ReturnType<typeof useFilterPaymentTransactionTypeListSuspenseQuery>;
export type FilterPaymentTransactionTypeListQueryResult = Apollo.QueryResult<
  FilterPaymentTransactionTypeListQuery,
  FilterPaymentTransactionTypeListQueryVariables
>;
export const FilterPaymentTypeListDocument = gql`
  query FilterPaymentTypeList {
    getFilterPaymentTypeList {
      data {
        text
        value
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterPaymentTypeListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterPaymentTypeListQuery, FilterPaymentTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterPaymentTypeListQuery, FilterPaymentTypeListQueryVariables>(FilterPaymentTypeListDocument, options);
}
export function useFilterPaymentTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterPaymentTypeListQuery, FilterPaymentTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterPaymentTypeListQuery, FilterPaymentTypeListQueryVariables>(FilterPaymentTypeListDocument, options);
}
export function useFilterPaymentTypeListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterPaymentTypeListQuery, FilterPaymentTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterPaymentTypeListQuery, FilterPaymentTypeListQueryVariables>(FilterPaymentTypeListDocument, options);
}
export type FilterPaymentTypeListQueryHookResult = ReturnType<typeof useFilterPaymentTypeListQuery>;
export type FilterPaymentTypeListLazyQueryHookResult = ReturnType<typeof useFilterPaymentTypeListLazyQuery>;
export type FilterPaymentTypeListSuspenseQueryHookResult = ReturnType<typeof useFilterPaymentTypeListSuspenseQuery>;
export type FilterPaymentTypeListQueryResult = Apollo.QueryResult<FilterPaymentTypeListQuery, FilterPaymentTypeListQueryVariables>;
export const FilterPaymentReferenceTextListDocument = gql`
  query FilterPaymentReferenceTextList($paymentReferenceText: String) {
    getFilterPaymentReferenceTextList(paymentReferenceText: $paymentReferenceText) {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterPaymentReferenceTextListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterPaymentReferenceTextListQuery, FilterPaymentReferenceTextListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterPaymentReferenceTextListQuery, FilterPaymentReferenceTextListQueryVariables>(
    FilterPaymentReferenceTextListDocument,
    options
  );
}
export function useFilterPaymentReferenceTextListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterPaymentReferenceTextListQuery, FilterPaymentReferenceTextListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterPaymentReferenceTextListQuery, FilterPaymentReferenceTextListQueryVariables>(
    FilterPaymentReferenceTextListDocument,
    options
  );
}
export function useFilterPaymentReferenceTextListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterPaymentReferenceTextListQuery, FilterPaymentReferenceTextListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterPaymentReferenceTextListQuery, FilterPaymentReferenceTextListQueryVariables>(
    FilterPaymentReferenceTextListDocument,
    options
  );
}
export type FilterPaymentReferenceTextListQueryHookResult = ReturnType<typeof useFilterPaymentReferenceTextListQuery>;
export type FilterPaymentReferenceTextListLazyQueryHookResult = ReturnType<typeof useFilterPaymentReferenceTextListLazyQuery>;
export type FilterPaymentReferenceTextListSuspenseQueryHookResult = ReturnType<typeof useFilterPaymentReferenceTextListSuspenseQuery>;
export type FilterPaymentReferenceTextListQueryResult = Apollo.QueryResult<
  FilterPaymentReferenceTextListQuery,
  FilterPaymentReferenceTextListQueryVariables
>;
export const FilterPaymentPurposeOfUseTextListDocument = gql`
  query FilterPaymentPurposeOfUseTextList($purposeOfUseText: String) {
    getFilterPaymentPurposeOfUseTextList(purposeOfUseText: $purposeOfUseText) {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterPaymentPurposeOfUseTextListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterPaymentPurposeOfUseTextListQuery, FilterPaymentPurposeOfUseTextListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterPaymentPurposeOfUseTextListQuery, FilterPaymentPurposeOfUseTextListQueryVariables>(
    FilterPaymentPurposeOfUseTextListDocument,
    options
  );
}
export function useFilterPaymentPurposeOfUseTextListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterPaymentPurposeOfUseTextListQuery, FilterPaymentPurposeOfUseTextListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterPaymentPurposeOfUseTextListQuery, FilterPaymentPurposeOfUseTextListQueryVariables>(
    FilterPaymentPurposeOfUseTextListDocument,
    options
  );
}
export function useFilterPaymentPurposeOfUseTextListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterPaymentPurposeOfUseTextListQuery, FilterPaymentPurposeOfUseTextListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterPaymentPurposeOfUseTextListQuery, FilterPaymentPurposeOfUseTextListQueryVariables>(
    FilterPaymentPurposeOfUseTextListDocument,
    options
  );
}
export type FilterPaymentPurposeOfUseTextListQueryHookResult = ReturnType<typeof useFilterPaymentPurposeOfUseTextListQuery>;
export type FilterPaymentPurposeOfUseTextListLazyQueryHookResult = ReturnType<typeof useFilterPaymentPurposeOfUseTextListLazyQuery>;
export type FilterPaymentPurposeOfUseTextListSuspenseQueryHookResult = ReturnType<typeof useFilterPaymentPurposeOfUseTextListSuspenseQuery>;
export type FilterPaymentPurposeOfUseTextListQueryResult = Apollo.QueryResult<
  FilterPaymentPurposeOfUseTextListQuery,
  FilterPaymentPurposeOfUseTextListQueryVariables
>;
