import { FormFields } from '../../../../helpers/formikHelper';
import { BeVertragUpdateInput } from '../../../../types';
import { BeVertragFragment } from '../gql/BeVertragFragments.types';

export type VertragPaymentDetailsFormValues = {
  bankDetailsId: string;
  belegform: string;
  consolidationCriteria?: string | null;
  vertragsart: string;
};

export const vertragPaymentDetailsFormFields: FormFields<VertragPaymentDetailsFormValues> = {
  bankDetailsId: 'bankDetailsId',
  belegform: 'belegform',
  consolidationCriteria: 'consolidationCriteria',
  vertragsart: 'vertragsart',
};

export const mapVertragToVertragPaymentDetailsFormValues = (vertrag: BeVertragFragment): VertragPaymentDetailsFormValues => ({
  bankDetailsId: vertrag.bankDetails ? vertrag.bankDetails.bankDetailsId : '',
  belegform: vertrag.belegform.value,
  consolidationCriteria: !vertrag.consolidationCriteriaEditable ? vertrag.consolidationCriteria : '',
  vertragsart: vertrag.vertragsart.text,
});

export const mapVertragPaymentDetailsFormValuesToVertragUpdate = (
  formValues: VertragPaymentDetailsFormValues,
  originalVertrag: BeVertragFragment
): BeVertragUpdateInput => ({
  bankDetailsId: formValues.bankDetailsId,
  vertragsBeginn: originalVertrag.vertragsBeginn,
  vertragspartnerId: originalVertrag.vertragspartner.rechtstraegerId,
  consolidationCriteria: formValues.consolidationCriteria,
  vertragsart: originalVertrag.vertragsart.value,
  applyIndexedValues: originalVertrag.applyIndexedValues,
});
