import { FC } from 'react';
import { useToggle } from '../../../../hooks/useToggle';
import ZahlungsverlaufButtonsAndDrawer from './ZahlungsverlaufButtonsAndDrawer';
import { useZahlungsverlaufQuery } from './gql/HeVertragAuszahlungQueries.types';
import ZahlungsverlaufTable from './Table/ZahlungsverlaufTable';
import { HeVertrag, HeVertragAuszahlungStatus } from '../../../../types';

type Props = {
  objektId: string;
  heVertragId: string;
  vertrag: HeVertrag;
  rechnungsAusstellerId: string;
};

const ZahlungsverlaufListing: FC<Props> = ({ objektId, heVertragId, vertrag, rechnungsAusstellerId }) => {
  const [showProcessedPayments, toggleShowProcessedPayments] = useToggle();
  const { data, loading, refetch } = useZahlungsverlaufQuery({ variables: { objektId, heVertragId } });

  const zahlungsverlaufList = data?.getZahlungsverlauf.data.filter((zahlung) => {
    if (zahlung.status.value === HeVertragAuszahlungStatus.Durchgefuehrt || zahlung.status.value === HeVertragAuszahlungStatus.NichtDurchgefuehrt) {
      return showProcessedPayments;
    }
    return true;
  });

  return (
    <>
      <ZahlungsverlaufButtonsAndDrawer
        objektId={objektId}
        heVertragId={heVertragId}
        vertrag={vertrag}
        onSuccess={refetch}
        showProcessedPayments={showProcessedPayments}
        toggleShowProcessedPayments={toggleShowProcessedPayments}
      />
      <ZahlungsverlaufTable
        objektId={objektId}
        heVertragId={heVertragId}
        onSuccess={refetch}
        loading={loading}
        zahlungsverlaufList={zahlungsverlaufList}
        rechnungsAusstellerId={rechnungsAusstellerId}
      />
    </>
  );
};

export default ZahlungsverlaufListing;
