import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../../security/AuthRoute';
import AndromedaSysSettingsAuftragsartCreatePage from './AndromedaSysSettingsAuftragsartCreatePage';
import { isAdmin } from '../../../../../security/permissionChecks';
import URI_ANDROMEDA_SYS_SETTINGS_AUFTRAGSART_PAGE from '../../../../../features/AugtragsartTemplate/auftragsartTemplateUriPaths';

const andromedaSysSettingsAuftragsartCreatePageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS_AUFTRAGSART_PAGE.createPage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsAuftragsartCreatePage />} />}
  />
);

export default andromedaSysSettingsAuftragsartCreatePageRoute;
