import React from 'react';
import { FireOutlined, HomeOutlined } from '@ant-design/icons';
import { generatePathToVerarbeitungHeAbrechnungErstellenDetailsPage } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import { GroupedMenueListInterface } from '../../../components/ContextSidebar/ContentWithSidebar';
import { verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungErrorAndWarningListing from '../../../features/Verarbeitung/shared/ErrorAndWarningListing/VerarbeitungErrorAndWarningListing';
import VerarbeitungHeAbrechnungErstellen from '../../../features/Verarbeitung/HeAbrechnung/VerarbeitungHeAbrechnungErstellen';
import { HeAbrechnungGenerierlauf } from '../../../types';

const menuListHeAbrechnungErstellen = (generierlauf: HeAbrechnungGenerierlauf): GroupedMenueListInterface => {
  return {
    topMenuList: [
      {
        title: verarbeitungPageAndMenuListTitles.HeAbrechnung,
        icon: <HomeOutlined />,
        uri: `${generatePathToVerarbeitungHeAbrechnungErstellenDetailsPage(generierlauf.generierlaufId)}/entries`,
        content: () => <VerarbeitungHeAbrechnungErstellen generierlauf={generierlauf} />,
      },
      {
        state: generierlauf.errorList.length > 0 || generierlauf.warningList.length > 0 ? 'error' : undefined,
        title: verarbeitungPageAndMenuListTitles.ErrorsAndWarnings,
        icon: <FireOutlined />,
        uri: `${generatePathToVerarbeitungHeAbrechnungErstellenDetailsPage(generierlauf.generierlaufId)}/errors-and-warnings`,
        content: () => <VerarbeitungErrorAndWarningListing errorList={generierlauf.errorList} warningList={generierlauf.warningList} />,
      },
    ],
  };
};

export default menuListHeAbrechnungErstellen;
