import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { showSuccessMsgOther } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import {
  mapFormValuesToAbrDefZuweisen,
  rechnungsAusstellerAbrDefZuweisenFormFields,
  RechnungsAusstellerAbrDefZuweisenFormValues,
  rechnungsAusstellerAbrDefZuweisenFormValuesDefault,
} from './rechnungsAusstellerAbrDefZuweisenFormMapper';
import { rechnungsAusstellerAbrDefZuweisenFormValidationSchema } from './RechnungsAusstellerAbrDefZuweisenFormValidationSchema';
import FormButtons from '../../../../../../components/Button/FormButtons';
import AbrechnungsdefinitionSelect from '../../../../../AbrechnungsdefinitionSelect/AbrechnungsdefinitionSelect';
import { useSetAbrechnungsdefinitionForRechnungsausstellerMutation } from '../../gql/RechnungsAusstellerMutations.types';
import { useGetAppFieldHelpText } from '../../../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
  rechtstraegerId: string;
};

const RechnungsAusstellerAbrDefZuweisenForm: FC<Props> = ({ onSuccess, onCancel, rechtstraegerId }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'RechnungsAusstellerActionDataSetAbrechnungsdefinition'>(
    'RechnungsAusstellerActionDataSetAbrechnungsdefinition'
  );

  const [setAbrechungsdefinition, { loading: loadingSetAbrDef }] = useSetAbrechnungsdefinitionForRechnungsausstellerMutation({
    onCompleted: () => {
      showSuccessMsgOther(`Abrechnungsdefinition erfolgreich zugewiesen.`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<RechnungsAusstellerAbrDefZuweisenFormValues>
      initialValues={rechnungsAusstellerAbrDefZuweisenFormValuesDefault}
      validationSchema={rechnungsAusstellerAbrDefZuweisenFormValidationSchema}
      onSubmit={(values, formikHelpers) => {
        const input = mapFormValuesToAbrDefZuweisen(values);
        setAbrechungsdefinition({ variables: { rechtstraegerId, input } }).finally(() => {
          formikHelpers.setSubmitting(false);
        });
      }}
    >
      {(formikProps) => (
        <Form>
          <FormItemWithFieldHelp
            name={rechnungsAusstellerAbrDefZuweisenFormFields.abrechnungsdefinitionId}
            label="Abrechnungsdefinition"
            fieldHelp={getFieldHelpText('RechnungsAusstellerActionDataSetAbrechnungsdefinition.abrechnungsdefinitionId')}
          >
            <AbrechnungsdefinitionSelect
              name={rechnungsAusstellerAbrDefZuweisenFormFields.abrechnungsdefinitionId}
              id={rechnungsAusstellerAbrDefZuweisenFormFields.abrechnungsdefinitionId}
              onAbrDefSelect={(type) => formikProps.setFieldValue(rechnungsAusstellerAbrDefZuweisenFormFields.type, type)}
            />
          </FormItemWithFieldHelp>
          <FormButtons
            updateMode={false}
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={loadingSetAbrDef}
            okText="Abrechnungsdefinition zuweisen"
          />
        </Form>
      )}
    </Formik>
  );
};

export default RechnungsAusstellerAbrDefZuweisenForm;
