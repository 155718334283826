import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  mahnungListingFiltersFormInitialValues,
  MahnungListingFiltersFormValues,
  mapFormValuesToMahnungListingFilters,
} from './mahnungListingFiltersFormMapper';
import MahnungListingForm from './MahnungListingForm';
import { useQueryParams } from '../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';
import { TMahnungQueryParams, updateQueryParams } from './Filters/filtersQueryParams';

const MahnungListing = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TMahnungQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: MahnungListingFiltersFormValues) => {
    const filters = mapFormValuesToMahnungListingFilters(values);
    updateQueryParams(navigate, filters, paginationParams);
  };

  return (
    <Formik<MahnungListingFiltersFormValues> initialValues={mahnungListingFiltersFormInitialValues(queryParams)} onSubmit={onChange}>
      {(formikProps) => <MahnungListingForm formikProps={formikProps} queryParams={queryParams} paginationParams={paginationParams} />}
    </Formik>
  );
};

export default MahnungListing;
