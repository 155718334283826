import React, { FC } from 'react';
import { Box } from 'rebass';
import { Button, Drawer, Tabs, TabsProps } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';

import { ITimelineState } from '../../../components/Timeline/useTimelineOpen';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';
import { Verwaltungsart, VorsteuerkuerzungEinstellung } from '../../../types';
import theme from '../../../theme';
import VstKuerzungTemplateKontenZuweisungVersionTimeline from './Version/VstKuerzungTemplateKontenZuweisungVersionTimeline';

type Props = {
  verwaltungsart: Verwaltungsart;
  vorsteuerkuerzungList?: VorsteuerkuerzungEinstellung;
  timelineDrawer: ITimelineState;
  closeTimelineDrawer: () => void;
  reloadVstKuerzungCurrentKontenZuweisung: () => void;
};

const VstKuerzungTemplateKontenZuweisungDrawer: FC<Props> = ({
  verwaltungsart,
  timelineDrawer,
  closeTimelineDrawer,
  reloadVstKuerzungCurrentKontenZuweisung,
}) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span>
          <HistoryOutlined style={{ color: theme.colors.blue }} />
          Verlauf
        </span>
      ),
      children: (
        <Box p={16}>
          <VstKuerzungTemplateKontenZuweisungVersionTimeline
            verwaltungsart={verwaltungsart}
            reloadVstKuerzungCurrentKontenZuweisung={reloadVstKuerzungCurrentKontenZuweisung}
          />
        </Box>
      ),
    },
  ];

  return (
    <Drawer
      title="Bearbeiten"
      width={1280}
      open={timelineDrawer.visible}
      onClose={closeTimelineDrawer}
      destroyOnClose
      footer={
        <ButtonsAligned usage={ButtonsAlignedUsage.drawerFooter}>
          <Button onClick={closeTimelineDrawer} type="primary">
            Schließen
          </Button>
        </ButtonsAligned>
      }
    >
      <Tabs defaultActiveKey="1" items={items} />
    </Drawer>
  );
};

export default VstKuerzungTemplateKontenZuweisungDrawer;
