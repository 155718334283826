import { FieldArrayRenderProps, FormikProps } from 'formik';
import { ColumnProps } from 'antd/es/table';
import { Typography } from 'antd';
import { AuszifferungFormValues } from '../../auszifferungFormMapper';
import FormattedDateTime from '../../../../../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../../../../../components/Card';
import { BookingSuggestionFormValues, OffenePostenForClearingSuggestion } from '../../../Form/buchungsmaskeFormMapper';
import DeleteAuszifferungForClearingSuggTableColumn from '../../AuszifferungListingFromBookingSuggestion/Level2/DeleteAuszifferungForClearingSuggTableColumn';
import DeleteAuszifferungColumnForPersonenkonto from '../../OffenePostenListingForPersonenkonto/Table/Level2/DeleteAuszifferungColumnForPersonenkonto';
import SaldoOffenerBetragColumn from './SaldoOffenerBetragColumn';
import AmountColumn from './AmountColumn';
import { isAuszifferungStorniert } from '../../../../../BookingDetails/View/shared/AuszifferungBlock/auszifferungFormHelpers';
import StorniertStatus from '../../../../../shared/StorniertStatus';
import { isAuszifferungSourceStorno } from '../../../../../BookingDetails/shared/auszifferungHelpers';

export const auszifferungTableColumns = (
  auszifferungListFieldName: string,
  offenePosten: OffenePostenForClearingSuggestion,
  offenePostenIndex: number,
  arrayHelpers: FieldArrayRenderProps,
  formikProps: FormikProps<BookingSuggestionFormValues>,
  isOPForPersonenkonto: boolean
): ColumnProps<AuszifferungFormValues>[] => {
  return [
    {
      title: 'Auszifferung',
      dataIndex: 'auszifferung',
      align: 'right',
      render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
    },
    {
      title: '',
      dataIndex: 'zahlungId',
      align: 'left',
      render: (text, record) => {
        return isAuszifferungSourceStorno(record.source?.value) || !!record.zahlungId ? null : (
          <Typography.Text italic>aus dieser Zahlung</Typography.Text>
        );
      },
    },
    {
      title: 'Betrag',
      dataIndex: 'betrag',
      width: 400,
      align: 'right',
      render: (text, record) => (
        <Typography.Text delete={isAuszifferungStorniert(record)}>
          <AmountColumn record={record} formikValues={formikProps.values} isOPForPersonenkonto={isOPForPersonenkonto} offenePosten={offenePosten} />
        </Typography.Text>
      ),
    },
    {
      title: 'Saldo offener Betrag',
      dataIndex: 'offenerBetrag',
      align: 'right',
      render: (text, record) => (
        <SaldoOffenerBetragColumn
          auszifferung={record}
          formikValues={formikProps.values}
          isOPForPersonenkonto={isOPForPersonenkonto}
          offenePosten={offenePosten}
        />
      ),
    },
    {
      title: 'Erfasst von',
      dataIndex: 'erfasstVon',
      align: 'right',
      render: (text, record) => (
        <MitarbeiterTooltip alignment="right" mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} showsInitials />
      ),
    },
    {
      title: '',
      dataIndex: 'stornoStatus',
      align: 'right',
      width: 300,
      render: (text, record) =>
        record.storniert && record.storniertTs && record.storniertByMitarbeiterId ? (
          <StorniertStatus storniertByMitarbeiterId={record.storniertByMitarbeiterId} storniertTs={record.storniertTs} />
        ) : null,
    },
    {
      title: 'Aktion',
      dataIndex: 'deleteAuszifferung',
      align: 'right',
      width: 90,
      render: (text, record) => {
        if (isAuszifferungSourceStorno(record.source?.value)) return null;

        return isOPForPersonenkonto ? (
          <DeleteAuszifferungColumnForPersonenkonto
            auszifferungListFieldName={auszifferungListFieldName}
            formikProps={formikProps}
            offenePosten={offenePosten}
            record={record}
          />
        ) : (
          <DeleteAuszifferungForClearingSuggTableColumn
            arrayHelpers={arrayHelpers}
            formikProps={formikProps}
            offenePosten={offenePosten}
            offenePostenIndex={offenePostenIndex}
            record={record}
          />
        );
      },
    },
  ];
};
