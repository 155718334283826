import { FC } from 'react';
import { Space, Typography } from 'antd';
import { Contact } from '../../../../types';
import ContactEmail from './ContactEmail';
import ContactPhone from './ContactPhone';
import { ContactType } from '../../../../shared/components/Contact/contactHelper';

type Props = {
  firstname: string;
  lastname: string;
  contactList: Contact[];
};

const ListItemContact: FC<Props> = ({ contactList, firstname, lastname }) => {
  const getFirstEmail = contactList.filter((contact) => contact.type === ContactType.EMAIL)[0];
  const getFirstTelefon = contactList.filter((contact) => contact.type === ContactType.PHONE)[0];

  return (
    <Space style={{ width: '100%' }}>
      <Typography.Text>{`${firstname}, ${lastname}`}</Typography.Text>
      {getFirstEmail && <ContactEmail email={getFirstEmail.wert} />}
      {getFirstTelefon && <ContactPhone phone={getFirstTelefon.wert} />}
    </Space>
  );
};

export default ListItemContact;
