import React, { FC } from 'react';
import { Space, Switch, Table, Typography } from 'antd';

import { ITimeblock } from '../../../../components/Timeline/timelineCtx';
import { ustKategorieEntryVersionTableColumnsRead } from './columns/ustKategorieEntryVersionTableColumnsRead';
import TimelineCardContentInfoRow from '../../../../components/Timeline/Card/TimelineCardContentInfoRow';
import { ustKategorieVersionFormFieldsDescriptions } from './Form/ustKategorieVersionMapper';
import { Percent } from '../../../../components/Number';
import { UstKategorieEntry, UstKategorieVersion } from '../../../../types';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  timeblock: ITimeblock<UstKategorieVersion>;
};

const UstKategorieVersionCardContent: FC<Props> = ({ timeblock }) => (
  <Space direction="vertical" style={{ width: '100%' }}>
    <TimelineCardContentInfoRow infoRowTitle={ustKategorieVersionFormFieldsDescriptions.validFrom}>
      {timeblock.validFrom && <CustomFormattedDate value={timeblock.validFrom} />}
    </TimelineCardContentInfoRow>
    <TimelineCardContentInfoRow infoRowTitle={ustKategorieVersionFormFieldsDescriptions.ustVomAufwand}>
      <Percent value={timeblock.ustVomAufwand / 100} />
    </TimelineCardContentInfoRow>
    <TimelineCardContentInfoRow infoRowTitle={ustKategorieVersionFormFieldsDescriptions.vstKuerzung}>
      <Space>
        <Switch checked={timeblock.vstKuerzung} disabled size="small" />
        <Typography.Text>{timeblock.vstKuerzung ? 'Ja' : 'Nein'}</Typography.Text>
      </Space>
    </TimelineCardContentInfoRow>

    <Table<UstKategorieEntry>
      size="small"
      dataSource={timeblock.ustKategorieEntryList}
      rowKey="ustKategorieEntryId"
      columns={ustKategorieEntryVersionTableColumnsRead()}
      pagination={false}
    />
  </Space>
);

export default UstKategorieVersionCardContent;
