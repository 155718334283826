import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AbrechnungskreisFieldsFragmentDoc } from '../../Abrechnungskreis/gql/AbrechnungskreisFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AbrechnungsdefinitionTemplateVersionAbrechnungskreisListQueryVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Types.Scalars['ID']['input'];
}>;

export type AbrechnungsdefinitionTemplateVersionAbrechnungskreisListQuery = {
  getAbrechnungsdefinitionTemplateVersionAbrechnungskreisList: {
    data: Array<{
      abrechnungskreisId: string;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      updatedByMitarbeiterId?: string | null;
      status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
      type: { text: string; value: Types.AbrechnungskreisType };
      verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const AbrechnungsdefinitionTemplateVersionAbrechnungskreisListDocument = gql`
  query AbrechnungsdefinitionTemplateVersionAbrechnungskreisList($abrechnungsdefinitionId: ID!, $abrechnungsdefinitionVersionId: ID!) {
    getAbrechnungsdefinitionTemplateVersionAbrechnungskreisList(
      abrechnungsdefinitionId: $abrechnungsdefinitionId
      abrechnungsdefinitionVersionId: $abrechnungsdefinitionVersionId
    ) {
      data {
        ...AbrechnungskreisFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AbrechnungskreisFieldsFragmentDoc}
`;
export function useAbrechnungsdefinitionTemplateVersionAbrechnungskreisListQuery(
  baseOptions: Apollo.QueryHookOptions<
    AbrechnungsdefinitionTemplateVersionAbrechnungskreisListQuery,
    AbrechnungsdefinitionTemplateVersionAbrechnungskreisListQueryVariables
  > &
    ({ variables: AbrechnungsdefinitionTemplateVersionAbrechnungskreisListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AbrechnungsdefinitionTemplateVersionAbrechnungskreisListQuery,
    AbrechnungsdefinitionTemplateVersionAbrechnungskreisListQueryVariables
  >(AbrechnungsdefinitionTemplateVersionAbrechnungskreisListDocument, options);
}
export function useAbrechnungsdefinitionTemplateVersionAbrechnungskreisListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AbrechnungsdefinitionTemplateVersionAbrechnungskreisListQuery,
    AbrechnungsdefinitionTemplateVersionAbrechnungskreisListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AbrechnungsdefinitionTemplateVersionAbrechnungskreisListQuery,
    AbrechnungsdefinitionTemplateVersionAbrechnungskreisListQueryVariables
  >(AbrechnungsdefinitionTemplateVersionAbrechnungskreisListDocument, options);
}
export function useAbrechnungsdefinitionTemplateVersionAbrechnungskreisListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AbrechnungsdefinitionTemplateVersionAbrechnungskreisListQuery,
        AbrechnungsdefinitionTemplateVersionAbrechnungskreisListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AbrechnungsdefinitionTemplateVersionAbrechnungskreisListQuery,
    AbrechnungsdefinitionTemplateVersionAbrechnungskreisListQueryVariables
  >(AbrechnungsdefinitionTemplateVersionAbrechnungskreisListDocument, options);
}
export type AbrechnungsdefinitionTemplateVersionAbrechnungskreisListQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionTemplateVersionAbrechnungskreisListQuery
>;
export type AbrechnungsdefinitionTemplateVersionAbrechnungskreisListLazyQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionTemplateVersionAbrechnungskreisListLazyQuery
>;
export type AbrechnungsdefinitionTemplateVersionAbrechnungskreisListSuspenseQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionTemplateVersionAbrechnungskreisListSuspenseQuery
>;
export type AbrechnungsdefinitionTemplateVersionAbrechnungskreisListQueryResult = Apollo.QueryResult<
  AbrechnungsdefinitionTemplateVersionAbrechnungskreisListQuery,
  AbrechnungsdefinitionTemplateVersionAbrechnungskreisListQueryVariables
>;
