import React from 'react';
import { Link } from 'react-router-dom';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { compareTwoStringsForSorting } from '../../../../../helpers/stringHelper';
import { compareTwoDatesForSorting } from '../../../../../helpers/dateHelper';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import MitarbeiterTooltip from '../../../../../components/Card/MitarbeiterTooltip';
import { addressToString } from '../../../../../shared/components/Address/addressHelper';
import { compareTwoNumbersForSorting } from '../../../../../helpers/numberHelper';
import CurrentBasedData from '../../../../../components/Helpers/CurrentBasedData';
import { generatePathToHeVertragDetailsPage } from '../../heVertragUriPaths';
import HeVertragTableVertragspartnerColumn from './HeVertragTableVertragspartnerColumn';
import { Percent } from '../../../../../components/Number';
import { HeVertragListEntry } from '../../../../../types';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';
import EntityWarningList from '../../../../../components/message/EntityWarningList';

const heVertragTableColumns = (objektId: string): TableWithColSelectorColumnProps<HeVertragListEntry>[] => {
  return [
    {
      title: 'Vertragspartner',
      defaultSelected: true,
      sorter: (a, b) => compareTwoStringsForSorting(a.vertragspartner.kurzBezeichnung, b.vertragspartner.kurzBezeichnung),
      render: (text, record) => <HeVertragTableVertragspartnerColumn record={record} />,
    },
    {
      title: 'Vertragsbeginn',
      defaultSelected: true,
      sorter: (a, b) => compareTwoDatesForSorting(a.vertragsBeginn, b.vertragsBeginn),
      render: (text, record) => {
        return (
          <CurrentBasedData text="" isCurrent={record.current}>
            <CustomFormattedDate value={record.vertragsBeginn} />
          </CurrentBasedData>
        );
      },
    },
    {
      title: 'Vertragsende',
      defaultSelected: true,
      sorter: (a, b) => compareTwoDatesForSorting(a.vertragsEnde, b.vertragsEnde),
      render: (text, record) => {
        return record.vertragsEnde ? (
          <CurrentBasedData text="" isCurrent={record.current}>
            <CustomFormattedDate value={record.vertragsEnde} />
          </CurrentBasedData>
        ) : (
          <TextForEmptyValue textToShow="minus" />
        );
      },
    },
    {
      title: 'Adresse',
      defaultSelected: true,
      sorter: (a, b) =>
        compareTwoStringsForSorting(
          a.deliveryAddress ? addressToString(a.deliveryAddress) : '',
          b.deliveryAddress ? addressToString(b.deliveryAddress) : ''
        ),
      render: (text, record) => {
        const addressWarningList = record.warningList.filter((warning) => warning.type === 'DELIVERY_ADDRESS_EMPTY');

        if (record.deliveryAddress) {
          return <CurrentBasedData text={addressToString(record.deliveryAddress)} isCurrent={record.current} />;
        }
        if (addressWarningList.length > 0) {
          return <EntityWarningList warningList={addressWarningList} />;
        }
        return <TextForEmptyValue textToShow="minus" />;
      },
    },
    {
      title: 'Anteile',
      defaultSelected: true,
      sorter: (a, b) => compareTwoStringsForSorting(a.anteile, b.anteile),
      render: (text, record) => {
        return <CurrentBasedData text={record.anteile} isCurrent={record.current} />;
      },
    },
    {
      title: 'Anteile %',
      defaultSelected: true,
      sorter: (a, b) => compareTwoNumbersForSorting(a.anteilePercent, b.anteilePercent),
      render: (text, record) => (
        <CurrentBasedData text="" isCurrent={record.current}>
          <Percent value={record.anteilePercent / 100} />
        </CurrentBasedData>
      ),
    },
    {
      title: 'Erstellt am',
      sorter: (a, b) => compareTwoDatesForSorting(a.createTs, b.createTs),
      render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
    },
    {
      title: 'Ersteller',
      render: (text, record) => (
        <MitarbeiterTooltip alignment="left" mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} showsInitials />
      ),
    },
    {
      title: 'Aktion',
      defaultSelected: true,
      render: (text, record) => <Link to={generatePathToHeVertragDetailsPage(objektId, record.heVertragId)}>Details</Link>,
    },
  ];
};

export default heVertragTableColumns;
