import { generatePath } from 'react-router-dom';
import { mapToQueryString, QueryParams } from '../../../../hooks/useStringQueryParams';
import { FiltersFormValues } from './filtersFormMapper';
import { PaginationQueryParamsProps } from '../../../../hooks/usePaginationQueryParams';
import { URI_RECEIVED_BANK_TRANSACTION_PAGE } from '../../receivedBankTransactionUriPaths';

enum QueryParamKey {
  AMOUNT_FROM = 'amountFrom',
  AMOUNT_TO = 'amountTo',
  BANK_STATEMENT_IBAN = 'bankStatementIban',
  BOOKED = 'booked',
  BOOKING_DATE_FROM = 'bookingDateFrom',
  BOOKING_DATE_TO = 'bookingDateTo',
  CHARGES_FROM = 'chargesFrom',
  CHARGES_IN_SEPARATE_ENTRY = 'chargesInSeparateEntry',
  CHARGES_TO = 'chargesTo',
  CREATED_BY_MITARBEITER_ID_LIST = 'createdByMitarbeiterIdList',
  CREATE_TS_FROM = 'createTsFrom',
  CREATE_TS_TO = 'createTsTo',
  CREDITOR = 'creditor',
  CREDITOR_IBAN = 'creditorIban',
  DATA_CARRIER_NAME = 'dataCarrierName',
  DEBTOR = 'debtor',
  DEBTOR_IBAN = 'debtorIban',
  END_TO_END_ID = 'endToEndId',
  HAS_WARNING = 'hasWarning',
  NOTED = 'noted',
  PAYMENT_ASSIGNED = 'paymentAssigned',
  PAYMENT_REFERENCE_TEXT = 'paymentReferenceText',
  PURPOSE_OF_USE_TEXT = 'purposeOfUseText',
  REVERSAL = 'reversal',
  SET_DEFAULT_VALUES_ON_INIT = 'setDefaultValuesOnInit',
  SHOW_ARCHIVED = 'showArchived',
  STATEMENT_NUMBER = 'statementNumber',
  STATUS = 'status',
  TRANSACTION_TYPE = 'transactionType',
  VALID = 'valid',
  VALUE_DATE_FROM = 'valueDateFrom',
  VALUE_DATE_TO = 'valueDateTo',
}

export type TReceivedBankTransactionQueryParams = QueryParams<FiltersFormValues>;

export const updateQueryParams = (
  navigate: (path: string) => void,
  filters: TReceivedBankTransactionQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToReceivedBankTransactionListingPage(filters, paginationParams));

export const generatePathToReceivedBankTransactionListingPage = (
  filters: TReceivedBankTransactionQueryParams,
  paginationParams?: PaginationQueryParamsProps
) =>
  `${generatePath(URI_RECEIVED_BANK_TRANSACTION_PAGE)}?${mapToQueryString({
    [QueryParamKey.AMOUNT_FROM]: filters?.amountFrom,
    [QueryParamKey.AMOUNT_TO]: filters?.amountTo,
    [QueryParamKey.BANK_STATEMENT_IBAN]: filters?.bankStatementIban,
    [QueryParamKey.BOOKED]: filters?.booked,
    [QueryParamKey.BOOKING_DATE_FROM]: filters?.bookingDateFrom,
    [QueryParamKey.BOOKING_DATE_TO]: filters?.bookingDateTo,
    [QueryParamKey.CHARGES_FROM]: filters?.chargesFrom,
    [QueryParamKey.CHARGES_IN_SEPARATE_ENTRY]: filters?.chargesInSeparateEntry,
    [QueryParamKey.CHARGES_TO]: filters?.chargesTo,
    [QueryParamKey.CREATED_BY_MITARBEITER_ID_LIST]: filters?.createdByMitarbeiterIdList,
    [QueryParamKey.CREATE_TS_FROM]: filters?.createTsFrom,
    [QueryParamKey.CREATE_TS_TO]: filters?.createTsTo,
    [QueryParamKey.CREDITOR]: filters?.creditor,
    [QueryParamKey.CREDITOR_IBAN]: filters?.creditorIban,
    [QueryParamKey.DATA_CARRIER_NAME]: filters?.dataCarrierName,
    [QueryParamKey.DEBTOR]: filters?.debtor,
    [QueryParamKey.DEBTOR_IBAN]: filters?.debtorIban,
    [QueryParamKey.END_TO_END_ID]: filters?.endToEndId,
    [QueryParamKey.HAS_WARNING]: filters?.hasWarning,
    [QueryParamKey.NOTED]: filters?.noted,
    [QueryParamKey.PAYMENT_ASSIGNED]: filters?.paymentAssigned,
    [QueryParamKey.PAYMENT_REFERENCE_TEXT]: filters?.paymentReferenceText,
    [QueryParamKey.PURPOSE_OF_USE_TEXT]: filters?.purposeOfUseText,
    [QueryParamKey.REVERSAL]: filters?.reversal,
    [QueryParamKey.SET_DEFAULT_VALUES_ON_INIT]: filters?.setDefaultValuesOnInit,
    [QueryParamKey.SHOW_ARCHIVED]: filters?.showArchived,
    [QueryParamKey.STATEMENT_NUMBER]: filters?.statementNumber,
    [QueryParamKey.STATUS]: filters?.status,
    [QueryParamKey.TRANSACTION_TYPE]: filters?.transactionType,
    [QueryParamKey.VALID]: filters?.valid,
    [QueryParamKey.VALUE_DATE_FROM]: filters?.valueDateFrom,
    [QueryParamKey.VALUE_DATE_TO]: filters?.valueDateTo,
    ...paginationParams,
  })}`;
