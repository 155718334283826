import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import FormattedDecimalFormInput from '../../../../components/Input-antd/FormattedDecimalFormInput';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../components/DatePicker/DatePicker_formikAntD';

type Props = {
  name: string;
  vertragsBeginn?: string | null;
  objektVerwaltungSeit?: string;
};

const HeVertragSaldouebertrag: FC<Props> = ({ name, vertragsBeginn, objektVerwaltungSeit }) => {
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <FormattedDecimalFormInput name={name} size="small" controls={false} min={0} style={{ width: '100%' }} precision={2} isCurrency />
      <Typography.Text>gültig ab {getDatum(vertragsBeginn, objektVerwaltungSeit)}</Typography.Text>
    </Space>
  );
};

const getDatum = (vertragsBeginn?: string | null, objektVerwaltungSeit?: string) => {
  const vetragsBeginnDayJS = dayjsCustom(vertragsBeginn);
  const objVerwaltungSeitDayJS = dayjsCustom(objektVerwaltungSeit);

  if (vertragsBeginn && objektVerwaltungSeit && vetragsBeginnDayJS.isSameOrAfter(objVerwaltungSeitDayJS)) {
    return `${vetragsBeginnDayJS.format(DATE_DISPLAY_FORMAT_DEFAULT)} ("Vertragsbeginn")`;
  }
  if (vertragsBeginn && objektVerwaltungSeit && objVerwaltungSeitDayJS.isAfter(vetragsBeginnDayJS)) {
    return `${objVerwaltungSeitDayJS.format(DATE_DISPLAY_FORMAT_DEFAULT)} ("verwaltet seit")`;
  }
  if (vertragsBeginn) {
    return `${vetragsBeginnDayJS.format(DATE_DISPLAY_FORMAT_DEFAULT)} ("Vertragsbeginn")`;
  }
  if (objektVerwaltungSeit) {
    return `${objVerwaltungSeitDayJS.format(DATE_DISPLAY_FORMAT_DEFAULT)} ("verwaltet seit")`;
  }
  return '...';
};

export default HeVertragSaldouebertrag;
