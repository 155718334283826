import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import { Budgeting } from '../../../types';
import { useBudgetingResultQuery } from '../gql/BudgetingQueries.types';
import { isNil } from '../../../helpers/assertionHelper';
import BudgetingResultBestandseinheitTable from './Table/Level1/BudgetingResultBestandseinheitTable';

type Props = {
  budgeting: Budgeting;
};

const BudgetingResultTab: FC<Props> = ({ budgeting }) => {
  const { data, loading } = useBudgetingResultQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { budgetingResultId: budgeting.budgetingResultId!, objektId: budgeting.objekt.objektId },
    skip: isNil(budgeting.budgetingResultId),
  });

  const budgetingResult = data?.getBudgetingResult.data;

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Vertagswerte',
      children: <BudgetingResultBestandseinheitTable budgeting={budgeting} budgetingResult={budgetingResult} loading={loading} />,
    },
  ];
  return <Tabs items={items} />;
};

export default BudgetingResultTab;
