import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArchiveMahndefinitionMutationVariables = Types.Exact<{
  mahndefinitionId: Types.Scalars['ID']['input'];
}>;

export type ArchiveMahndefinitionMutation = {
  actionArchiveMahndefinition: {
    data: { mahndefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveMahndefinitionMutationVariables = Types.Exact<{
  mahndefinitionId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveMahndefinitionMutation = {
  actionUnarchiveMahndefinition: {
    data: { mahndefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateMahndefinitionMutationVariables = Types.Exact<{
  input: Types.MahndefinitionCreateInput;
}>;

export type CreateMahndefinitionMutation = {
  createMahndefinition: {
    data: { mahndefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateMahndefinitionMutationVariables = Types.Exact<{
  mahndefinitionId: Types.Scalars['ID']['input'];
  input: Types.MahndefinitionUpdateInput;
}>;

export type UpdateMahndefinitionMutation = {
  updateMahndefinition: {
    data: { mahndefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteMahndefinitionMutationVariables = Types.Exact<{
  mahndefinitionId: Types.Scalars['ID']['input'];
}>;

export type DeleteMahndefinitionMutation = {
  deleteMahndefinition: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const ArchiveMahndefinitionDocument = gql`
  mutation ArchiveMahndefinition($mahndefinitionId: ID!) {
    actionArchiveMahndefinition(mahndefinitionId: $mahndefinitionId) {
      data {
        mahndefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveMahndefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveMahndefinitionMutation, ArchiveMahndefinitionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveMahndefinitionMutation, ArchiveMahndefinitionMutationVariables>(ArchiveMahndefinitionDocument, options);
}
export type ArchiveMahndefinitionMutationHookResult = ReturnType<typeof useArchiveMahndefinitionMutation>;
export type ArchiveMahndefinitionMutationResult = Apollo.MutationResult<ArchiveMahndefinitionMutation>;
export type ArchiveMahndefinitionMutationOptions = Apollo.BaseMutationOptions<ArchiveMahndefinitionMutation, ArchiveMahndefinitionMutationVariables>;
export const UnarchiveMahndefinitionDocument = gql`
  mutation UnarchiveMahndefinition($mahndefinitionId: ID!) {
    actionUnarchiveMahndefinition(mahndefinitionId: $mahndefinitionId) {
      data {
        mahndefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveMahndefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveMahndefinitionMutation, UnarchiveMahndefinitionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveMahndefinitionMutation, UnarchiveMahndefinitionMutationVariables>(UnarchiveMahndefinitionDocument, options);
}
export type UnarchiveMahndefinitionMutationHookResult = ReturnType<typeof useUnarchiveMahndefinitionMutation>;
export type UnarchiveMahndefinitionMutationResult = Apollo.MutationResult<UnarchiveMahndefinitionMutation>;
export type UnarchiveMahndefinitionMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveMahndefinitionMutation,
  UnarchiveMahndefinitionMutationVariables
>;
export const CreateMahndefinitionDocument = gql`
  mutation CreateMahndefinition($input: MahndefinitionCreateInput!) {
    createMahndefinition(input: $input) {
      data {
        mahndefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateMahndefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMahndefinitionMutation, CreateMahndefinitionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMahndefinitionMutation, CreateMahndefinitionMutationVariables>(CreateMahndefinitionDocument, options);
}
export type CreateMahndefinitionMutationHookResult = ReturnType<typeof useCreateMahndefinitionMutation>;
export type CreateMahndefinitionMutationResult = Apollo.MutationResult<CreateMahndefinitionMutation>;
export type CreateMahndefinitionMutationOptions = Apollo.BaseMutationOptions<CreateMahndefinitionMutation, CreateMahndefinitionMutationVariables>;
export const UpdateMahndefinitionDocument = gql`
  mutation UpdateMahndefinition($mahndefinitionId: ID!, $input: MahndefinitionUpdateInput!) {
    updateMahndefinition(mahndefinitionId: $mahndefinitionId, input: $input) {
      data {
        mahndefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateMahndefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMahndefinitionMutation, UpdateMahndefinitionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMahndefinitionMutation, UpdateMahndefinitionMutationVariables>(UpdateMahndefinitionDocument, options);
}
export type UpdateMahndefinitionMutationHookResult = ReturnType<typeof useUpdateMahndefinitionMutation>;
export type UpdateMahndefinitionMutationResult = Apollo.MutationResult<UpdateMahndefinitionMutation>;
export type UpdateMahndefinitionMutationOptions = Apollo.BaseMutationOptions<UpdateMahndefinitionMutation, UpdateMahndefinitionMutationVariables>;
export const DeleteMahndefinitionDocument = gql`
  mutation DeleteMahndefinition($mahndefinitionId: ID!) {
    deleteMahndefinition(mahndefinitionId: $mahndefinitionId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteMahndefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteMahndefinitionMutation, DeleteMahndefinitionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMahndefinitionMutation, DeleteMahndefinitionMutationVariables>(DeleteMahndefinitionDocument, options);
}
export type DeleteMahndefinitionMutationHookResult = ReturnType<typeof useDeleteMahndefinitionMutation>;
export type DeleteMahndefinitionMutationResult = Apollo.MutationResult<DeleteMahndefinitionMutation>;
export type DeleteMahndefinitionMutationOptions = Apollo.BaseMutationOptions<DeleteMahndefinitionMutation, DeleteMahndefinitionMutationVariables>;
