import * as Yup from 'yup';
import { legalFormSelectValidationSchema } from '../../../../shared/components/LegalFormSelect/legalFormSelectValidationSchema';
import { firmennameFormPartValidationSchema } from '../../../../shared/components/Firmenname/form/firmennameFormPartValidationSchema';
import { companyRegistrationNumberFormPartValidationSchema } from '../../../../shared/components/CompanyRegistrationNumber/form/companyRegistrationNumberFormPartValidationSchema';
import { kurzBezeichnungFormPartValidationSchema } from '../../../../shared/components/KurzBezeichnung/form/kurzBezeichnungFormPartValidationSchema';

/* eslint-disable no-template-curly-in-string */
export const firmendatenBaseFormPartValidationSchema = Yup.object()
  .concat(firmennameFormPartValidationSchema)
  .concat(kurzBezeichnungFormPartValidationSchema)
  .concat(legalFormSelectValidationSchema)
  .concat(companyRegistrationNumberFormPartValidationSchema);

/* eslint-enable no-template-curly-in-string */
