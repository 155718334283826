import React, { FC } from 'react';
import { WizardStepForm } from '../../../../components/Wizard';
import { firmendatenFirmensitzValidationSchema } from '../Firmensitz/firmensitzFieldsValidationSchema';
import { FIRMENSITZ_FORM_INITIAL_VALUES_DEFAULT } from '../Firmensitz/firmensitzMapper';
import FirmensitzFormPart from '../Firmensitz/FirmensitzFormPart';

type FirmensitzWizardStepFormProps = {
  stepId: string;
  kundenSystemId?: string;
  fieldHelpSelectorEntityName: 'KundenSystem' | 'Firmendaten'; //Important to narrow down the type
};

const FirmensitzWizardStepForm: FC<FirmensitzWizardStepFormProps> = ({ stepId, kundenSystemId, fieldHelpSelectorEntityName }) => (
  <WizardStepForm
    stepId={stepId}
    formInitialValues={FIRMENSITZ_FORM_INITIAL_VALUES_DEFAULT}
    formValidationSchema={firmendatenFirmensitzValidationSchema}
    formValidateOnChange={false}
    heading="Firmensitz"
    render={() => <FirmensitzFormPart kundenSystemId={kundenSystemId} fieldNamePrefix="" fieldHelpSelectorEntityName={fieldHelpSelectorEntityName} />}
  />
);

export default FirmensitzWizardStepForm;
