import React, { FC } from 'react';
import { OrderPaymentApproval } from '../../../types';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';

type Props = {
  /** Should be a list of at least one element */
  approvalList: OrderPaymentApproval[];
  fontWeight?: 'normal' | 'bold';
};

const PaymentMitarbeiterApprovalColumn: FC<Props> = ({ approvalList, fontWeight }) => {
  const extraTexteForTooltip = approvalList.reduce((approvalTextList, approval) => {
    return [...approvalTextList, approval.text];
  }, [] as string[]);

  const firstApproval = extraTexteForTooltip.shift() as string; // the list has at least one element hence the casting is safe

  return <DataWithShortenedText maxTextLength={15} text={firstApproval} extraTexts={extraTexteForTooltip} fontWeight={fontWeight} />;
};

export default PaymentMitarbeiterApprovalColumn;
