import React from 'react';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { friendlyFormatIBAN } from 'ibantools';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import { PaymentListEntry, PaymentOrderBy } from '../../../../../../types';
import { fontWeightForNotedRecord, TextAndFontWeightForNotedRecord } from '../../../../../Payment/shared/TextAndFontWeightForNotedRecord';
import DataWithShortenedText from '../../../../../../components/Helpers/DataWithShortenedText';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';
import CustomFormattedDate from '../../../../../../components/FormattedDate/CustomFormattedDate';
import { EuroAmount } from '../../../../../../components/Number';
import { NestedTableColProps } from '../../../../../../components/Table/NestedTable/NestedTable';
import { isPaymentTypeAusgangsrechnung } from '../../../../../Payment/paymentHelpers';
import { generatePathToRechtstraegerDetailsPage } from '../../../../../Rechtstraeger/rechtstraegerHelper';
import KontoLinkToKontoblatt from '../../../../../../shared/components/Konto/KontoLinkToKontoblatt';
import PaymentStatusColumn from '../../../../../Payment/Listing/Table/Level 1/PaymentStatusColumn';
import LinkToBelegFile from '../../../../../../components/Link/LinkToBelegFile/LinkToBelegFile';

const paymentAssignmentTableColumns: NestedTableColProps<PaymentListEntry>[] = [
  {
    title: 'Zahlungstyp',
    dataIndex: PaymentOrderBy.PaymentType,
    sorter: true,
    render: (text, record) => (
      <Space>
        {isPaymentTypeAusgangsrechnung(record.paymentType.value) ? (
          <ArrowDownOutlined style={{ color: 'green' }} />
        ) : (
          <ArrowUpOutlined style={{ color: 'red' }} />
        )}
        <DataWithShortenedText maxTextLength={10} text={record.paymentType.text} fontWeight={fontWeightForNotedRecord(record.noted)} />
      </Space>
    ),
  },
  {
    title: 'Zahlungsdatum',
    dataIndex: PaymentOrderBy.PaymentDate,
    sorter: true,
    render: (text, record) => <TextAndFontWeightForNotedRecord noted={record.noted} content={<CustomFormattedDate value={record.paymentDate} />} />,
  },
  {
    title: 'Zahlungsvorschlag',
    dataIndex: PaymentOrderBy.PaymentProposal,
    sorter: true,
    render: (text, record) =>
      record.paymentProposal ? (
        <DataWithShortenedText maxTextLength={15} text={record.paymentProposal} fontWeight={fontWeightForNotedRecord(record.noted)} />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />
      ),
  },
  {
    title: 'Belegnummer',
    dataIndex: PaymentOrderBy.Belegnummer,
    sorter: true,
    render: (text, record) => {
      if (record.belegnummer) {
        return record.belegFileId ? (
          <LinkToBelegFile fileId={record.belegFileId} belegnummer={record.belegnummer} hideIcon />
        ) : (
          <TextAndFontWeightForNotedRecord noted={record.noted} content={record.belegnummer} />
        );
      }

      return <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />;
    },
  },
  {
    title: 'Zahlungsverkehrsart',
    // defaultSelected: true,
    dataIndex: PaymentOrderBy.PaymentTransactionType,
    sorter: true,
    render: (text, record) =>
      record.paymentTransactionType ? (
        <TextAndFontWeightForNotedRecord noted={record.noted} content={record.paymentTransactionType.text} />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />
      ),
  },
  {
    title: 'Buchungskreis',
    // defaultSelected: true,
    dataIndex: PaymentOrderBy.RechnungsAussteller,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={15} text={record.rechnungsAussteller.kurzBezeichnung}>
        {(shortenedText) => (
          <Link
            target="_blank"
            to={generatePathToRechtstraegerDetailsPage(record.rechnungsAussteller.rechtstraegerId)}
            style={{ fontWeight: fontWeightForNotedRecord(record.noted) }}
          >
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Buchungskreis Bankverb.',
    // defaultSelected: true,
    dataIndex: PaymentOrderBy.RechnungsAusstellerIban,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText
        maxTextLength={15}
        text={`${record.rechnungsAusstellerAccountHolder} - ${friendlyFormatIBAN(record.rechnungsAusstellerIban)}`}
        fontWeight={fontWeightForNotedRecord(record.noted)}
      />
    ),
  },
  {
    title: 'Fibu-Bankkonto',
    // defaultSelected: true,
    dataIndex: PaymentOrderBy.RechnungsAusstellerFibuBankAccount,
    sorter: true,
    width: 200,
    render: (text, record) => (
      <KontoLinkToKontoblatt
        maxBezeichnungLength={15}
        konto={{
          kontoId: record.rechnungsAusstellerFibuKonto.kontoId,
          nummer: record.rechnungsAusstellerFibuKonto.nummer,
          bezeichnung: record.rechnungsAusstellerFibuKonto.bezeichnung,
        }}
        buchungsdatumFrom={record.bookingDate}
        objektId={record.objekt.objektId}
        fontWeight={fontWeightForNotedRecord(record.noted)}
      />
    ),
  },
  {
    title: 'Zahlender/Zahlungsempfänger',
    // defaultSelected: true,
    dataIndex: PaymentOrderBy.Payee,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={15} text={record.payee.kurzBezeichnung}>
        {(shortenedText) => (
          <Link
            target="_blank"
            to={generatePathToRechtstraegerDetailsPage(record.payee.rechtstraegerId)}
            style={{ fontWeight: fontWeightForNotedRecord(record.noted) }}
          >
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Zahlender/Zahlungsempfänger Bankverb.',
    // defaultSelected: true,
    dataIndex: PaymentOrderBy.PayeeIban,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText
        maxTextLength={15}
        text={`${record.payeeAccountHolder} - ${friendlyFormatIBAN(record.payeeIban)}`}
        fontWeight={fontWeightForNotedRecord(record.noted)}
      />
    ),
  },
  {
    title: 'Betrag',
    // defaultSelected: true,
    dataIndex: PaymentOrderBy.Amount,
    sorter: true,
    align: 'right',
    width: 120,
    render: (text, record) => <TextAndFontWeightForNotedRecord noted={record.noted} content={<EuroAmount value={record.amount} />} />,
  },
  {
    title: 'Verwendungszweck',
    dataIndex: PaymentOrderBy.Verwendungszweck,
    sorter: true,
    render: (text, record) =>
      record.verwendungszweck ? (
        <DataWithShortenedText maxTextLength={15} text={record.verwendungszweck} fontWeight={fontWeightForNotedRecord(record.noted)} />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />
      ),
  },
  {
    title: 'Zahlungsreferenz',
    dataIndex: PaymentOrderBy.PaymentReferenceText,
    sorter: true,
    render: (text, record) =>
      record.paymentReferenceText ? (
        <TextAndFontWeightForNotedRecord noted={record.noted} content={record.paymentReferenceText} />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />
      ),
  },
  {
    title: 'Zahlung Status',
    // defaultSelected: true,
    dataIndex: PaymentOrderBy.Status,
    sorter: true,
    render: (text, record) => <PaymentStatusColumn payment={record} fontWeightOfTagText={fontWeightForNotedRecord(record.noted)} />,
  },
];

export default paymentAssignmentTableColumns;
