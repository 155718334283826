import React from 'react';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { BookingSuggestionVerbuchenGenerierlaufEntry, BookingSuggestionVerbuchenGenerierlaufOrderBy } from '../../../../types';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../../../components/Card';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import GenerierlaufEntryStatus from '../../shared/GenerierlaufEntryStatus';
import GenerierlaufEntryStep from '../../shared/GenerierlaufEntryStep';
import ReceivedBankTransactionColumn from './ReceivedBankTransactionColumn';

const bookingSuggestionVerbuchenEntryTableColumns: TableWithColSelectorColumnProps<BookingSuggestionVerbuchenGenerierlaufEntry>[] = [
  {
    title: 'Status',
    dataIndex: BookingSuggestionVerbuchenGenerierlaufOrderBy.Status,
    sorter: true,
    render: (text, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },
  {
    title: 'Schritte',
    render: (text, record) => <GenerierlaufEntryStep stepList={record.stepList} currentStep={record.currentStep} stepCount={record.stepCount} />,
  },
  {
    title: 'Objekt',
    dataIndex: BookingSuggestionVerbuchenGenerierlaufOrderBy.Objekt,
    sorter: true,
    render: (text, record) => <DataWithShortenedText text={record.objekt.kurzBezeichnung} maxTextLength={20} />,
  },
  {
    title: 'Zahlungstyp',
    dataIndex: BookingSuggestionVerbuchenGenerierlaufOrderBy.PaymentType,
    sorter: true,
    defaultSelected: true,
    render: (text, record) => <DataWithShortenedText text={record.paymentType.text} maxTextLength={15} />,
  },
  {
    title: 'Zahlungsverkehrsart',
    dataIndex: BookingSuggestionVerbuchenGenerierlaufOrderBy.PaymentType,
    sorter: true,
    defaultSelected: true,
    render: (text, record) => <DataWithShortenedText text={record.paymentTransactionType.text} maxTextLength={20} />,
  },
  {
    title: 'Rechnungsaussteller',
    dataIndex: BookingSuggestionVerbuchenGenerierlaufOrderBy.BuchungskreisRechtstraeger,
    sorter: true,
    defaultSelected: true,
    render: (text, record) => {
      return record.buchungskreisRechtstraeger.kurzBezeichnung ? (
        <DataWithShortenedText text={record.buchungskreisRechtstraeger.kurzBezeichnung} maxTextLength={20} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      );
    },
  },
  {
    title: 'Zahlender/Zahlungsempfänger',
    dataIndex: BookingSuggestionVerbuchenGenerierlaufOrderBy.Payee,
    sorter: true,
    defaultSelected: true,
    render: (text, record) => {
      return record.payee.kurzBezeichnung ? (
        <DataWithShortenedText text={record.payee.kurzBezeichnung} maxTextLength={20} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      );
    },
  },
  {
    title: 'Zahlungsdatum',
    defaultSelected: true,
    render: (text, record) => <CustomFormattedDate value={record.zahlungsdatum} />,
  },
  {
    title: 'Zahlungsvorschlag',
    dataIndex: BookingSuggestionVerbuchenGenerierlaufOrderBy.PaymentProposalName,
    sorter: true,
    defaultSelected: true,
    render: (text, record) => <DataWithShortenedText text={record.paymentProposalName} maxTextLength={20} />,
  },
  {
    title: 'Empfangene Bankbuchungen',
    defaultSelected: true,
    render: (text, record) => <ReceivedBankTransactionColumn record={record} />,
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} alignment="left" showsInitials />
    ),
  },
];

export default bookingSuggestionVerbuchenEntryTableColumns;
