import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { EmailDeliveryDefinitionListQuery, useEmailDeliveryDefinitionListQuery } from '../gql/EmailDeliveryDefinitionQueries.types';
import { EmailDeliveryDefinitionType } from '../../../types';

type Props = {
  type?: EmailDeliveryDefinitionType;
  onReady?: (data: EmailDeliveryDefinitionListQuery) => void;
} & SelectProps;

const EmailDeliveryDefinitionSelect: FC<Props> = ({ name, type, onReady }) => {
  const { data, loading } = useEmailDeliveryDefinitionListQuery({ variables: { type }, onCompleted: onReady });

  const emailDeliveryDefList = data?.getEmailDeliveryDefinitionList.data ?? [];

  return (
    <Select loading={loading} name={name} placeholder="E-Mail Versanddefinition auswählen" style={{ minWidth: '200px' }}>
      {emailDeliveryDefList.map((emailDeliveryDef) => (
        <Select.Option key={emailDeliveryDef.emailDeliveryDefinitionId} value={emailDeliveryDef.emailDeliveryDefinitionId}>
          {emailDeliveryDef.bezeichnung}
        </Select.Option>
      ))}
    </Select>
  );
};

export default EmailDeliveryDefinitionSelect;
