import React from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import GenerierlaufEntryStatus from '../../../shared/GenerierlaufEntryStatus';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import LinkWithStatus from '../../../../../components/Link/LinkWithStatus';
import { generatePathToRechtstraegerDetailsPage } from '../../../../Rechtstraeger/rechtstraegerHelper';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../../../../components/Card';
import { URI_EMAIL_VERSAND_PAGE } from '../../../../EmailDelivery/emailDeliveryUriPaths';
import { InfoMailDeliverySendGenerierlaufEntry, InfoMailDeliverySendGenerierlaufOrderBy } from '../../../../../types';

const InfoMailDeliverySendVerarbeitungEntryTableColumns: TableWithColSelectorColumnProps<InfoMailDeliverySendGenerierlaufEntry>[] = [
  {
    title: 'Objekt-Top',
    defaultSelected: true,
    dataIndex: InfoMailDeliverySendGenerierlaufOrderBy.Objekt,
    sorter: true,
    render: (_, record) =>
      record.infoMailDelivery?.objektTopVertrag ? (
        <Typography.Text>{record.infoMailDelivery.objektTopVertrag.name}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'An',
    defaultSelected: true,
    dataIndex: InfoMailDeliverySendGenerierlaufOrderBy.Email,
    sorter: true,
    render: (_, record) =>
      record.infoMailDelivery?.emailAddress ? (
        <Typography.Text>{record.infoMailDelivery.emailAddress}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Vertragspartner',
    defaultSelected: true,
    dataIndex: InfoMailDeliverySendGenerierlaufOrderBy.Vertragspartner,
    sorter: true,
    render: (_, record) =>
      record.infoMailDelivery?.vertragspartner ? (
        <LinkWithStatus
          status={record.infoMailDelivery.vertragspartner.status}
          text={record.infoMailDelivery.vertragspartner.kurzBezeichnung}
          to={generatePathToRechtstraegerDetailsPage(record.infoMailDelivery.vertragspartner.rechtstraegerId)}
          maxTextLength={15}
        />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Empfänger',
    defaultSelected: true,
    dataIndex: InfoMailDeliverySendGenerierlaufOrderBy.Empfaenger,
    sorter: true,
    render: (_, record) =>
      record.infoMailDelivery?.recipient ? (
        <LinkWithStatus
          status={record.infoMailDelivery.recipient.status}
          text={record.infoMailDelivery.recipient.kurzBezeichnung}
          to={generatePathToRechtstraegerDetailsPage(record.infoMailDelivery.recipient.rechtstraegerId)}
          maxTextLength={15}
        />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Erstellt am',
    render: (_, record) =>
      record.infoMailDelivery?.createTs ? (
        <FormattedDateTime createdTs={record.infoMailDelivery.createTs} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Ersteller',
    render: (_, record) =>
      record.infoMailDelivery?.createdBy ? (
        <MitarbeiterTooltip
          userId={record.infoMailDelivery.createdBy}
          mitarbeiterId={record.infoMailDelivery.createdByMitarbeiterId}
          showsInitials
          alignment="left"
        />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Status',
    defaultSelected: true,
    dataIndex: InfoMailDeliverySendGenerierlaufOrderBy.Status,
    sorter: true,
    render: (_, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },

  {
    title: 'Aktion',
    defaultSelected: true,
    render: (_, record) =>
      record.infoMailDelivery?.mailId ? (
        <Link to={`${URI_EMAIL_VERSAND_PAGE}/${record.infoMailDelivery?.mailId}`} target="_blank">
          Details
        </Link>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
];

export default InfoMailDeliverySendVerarbeitungEntryTableColumns;
