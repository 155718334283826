import { firmennameFormPartInitialValues, mapFirmennameToFormValues } from '../../../../shared/components/Firmenname/form/firmennameFormPartMapper';
import { mapVatIdNumberToFormValues } from '../../../../shared/components/VatIdentificationNumber/form/vatIdNumberFormPartMapper';
import {
  companyRegistrationNumberFormPartInitialValues,
  mapCompanyRegistrationNumberToFormValues,
} from '../../../../shared/components/CompanyRegistrationNumber/form/companyRegistrationNumberFormPartMapper';
import { legalFormSelectInitialValues, mapLegalFormToFormValues } from '../../../../shared/components/LegalFormSelect/legalFormSelectMapper';
import { SPRACHE_DEFAULT } from '../../Sprache/spracheHelper';
import {
  kurzBezeichnungFormPartInitialValues,
  mapKurzBezeichnungToFormValues,
} from '../../../../shared/components/KurzBezeichnung/form/kurzBezeichnungFormPartMapper';
import { Firma, FirmaCreateInput, FirmaUpdateInput, LegalForm, RechtstraegerType, Salutation, Steuerpflicht } from '../../../../types';
import { FormFields } from '../../../../helpers/formikHelper';
import { StreetAddressFormValues } from '../../../../shared/components/Address/AddressSharedModel';
import { STREET_ADDRESS_FORM_INITIAL_VALUES_DEFAULT } from '../../../../shared/components/Address/form/addressMapper';

export interface FirmaFormValues {
  type: RechtstraegerType;
  sprache: string;
  salutation?: string | null;
  name1: string;
  name2: string;
  name3: string;
  kurzBezeichnung: string;
  legalForm: LegalForm | null;
  vatIdentificationNumber: string;
  companyRegistrationNumber: string;
  hauptAddress: StreetAddressFormValues;
  steuerpflicht: Steuerpflicht;
  tagList: string[];
}

export const firmaFormFields: FormFields<FirmaFormValues> = {
  type: 'type',
  sprache: 'sprache',
  salutation: 'salutation',
  name1: 'name1',
  name2: 'name2',
  name3: 'name3',
  kurzBezeichnung: 'kurzBezeichnung',
  legalForm: 'legalForm',
  vatIdentificationNumber: 'vatIdentificationNumber',
  companyRegistrationNumber: 'companyRegistrationNumber',
  hauptAddress: 'hauptAddress',
  steuerpflicht: 'steuerpflicht',
  tagList: 'tagList',
};

export const firmaFormInitialValues: FirmaFormValues = {
  type: RechtstraegerType.Firma,
  sprache: SPRACHE_DEFAULT,
  salutation: Salutation.Company,
  ...firmennameFormPartInitialValues(),
  ...kurzBezeichnungFormPartInitialValues(),
  ...legalFormSelectInitialValues(),
  vatIdentificationNumber: '',
  ...companyRegistrationNumberFormPartInitialValues(),
  steuerpflicht: Steuerpflicht.Steuerpflichtig,
  hauptAddress: { ...STREET_ADDRESS_FORM_INITIAL_VALUES_DEFAULT },
  tagList: [],
};

export const mapFirmaToFormValues = (firma?: Firma): FirmaFormValues => {
  if (!firma) {
    return firmaFormInitialValues;
  }
  return {
    type: firma.type.value,
    sprache: firma.sprache.value,
    salutation: firma.salutation?.value,
    ...mapFirmennameToFormValues(firma),
    ...mapKurzBezeichnungToFormValues(firma.kurzBezeichnung),
    ...mapLegalFormToFormValues(firma.legalForm),
    ...mapVatIdNumberToFormValues(firma.vatIdentificationNumber),
    ...mapCompanyRegistrationNumberToFormValues(firma.companyRegistrationNumber),
    steuerpflicht: firma.rechtstraegerSteuerpflicht?.steuerpflicht.value ?? Steuerpflicht.Steuerpflichtig,
    hauptAddress: { ...STREET_ADDRESS_FORM_INITIAL_VALUES_DEFAULT },
    tagList: firma.tagList,
  };
};

export const mapFormValuesToUpdateFirma = (values: FirmaFormValues): FirmaUpdateInput => ({
  sprache: values.sprache,
  salutation: values.salutation,
  name1: values.name1,
  name2: values.name2,
  name3: values.name3,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  legalForm: values.legalForm!,
  kurzBezeichnung: values.kurzBezeichnung,
  vatIdentificationNumber: values.vatIdentificationNumber,
  companyRegistrationNumber: values.companyRegistrationNumber,
  tagList: values.tagList,
});

export const mapFormValuesToCreateFirma = (values: FirmaFormValues): FirmaCreateInput => ({
  sprache: values.sprache,
  salutation: values.salutation,
  name1: values.name1,
  name2: values.name2,
  name3: values.name3,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  legalForm: values.legalForm!,
  kurzBezeichnung: values.kurzBezeichnung,
  vatIdentificationNumber: values.vatIdentificationNumber,
  companyRegistrationNumber: values.companyRegistrationNumber,
  steuerpflicht: { validFrom: '1900-01-01', steuerpflicht: values.steuerpflicht },
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  hauptAddress: values.hauptAddress!,
  tagList: values.tagList,
});
