import { FC } from 'react';
import { HistoryType } from '../../History/shared/HistoryModal';
import HistoryListingTable from '../../History/HistoryListingTable';
import { useSepaMandatChangeHistoryListQuery } from './gql/SepaMandatQueries.types';

type Props = {
  rechtstraegerId: string;
  bankDetailsId: string;
};

const SepaMandatChangeHistoryListingTable: FC<Props> = ({ rechtstraegerId, bankDetailsId }) => {
  const { data, loading } = useSepaMandatChangeHistoryListQuery({ variables: { rechtstraegerId, bankDetailsId } });
  const historyList = data?.getSepaMandatChangeHistoryList.data;

  return (
    <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="firmendaten-bank-details" />
  );
};

export default SepaMandatChangeHistoryListingTable;
