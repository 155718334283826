import React from 'react';
import { Tag, Typography } from 'antd';
import { MitarbeiterTooltip } from '../../../components/Card';
import { TicketTemplate } from '../../../types';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';
import TicketTemplateTableActions from './TicketTemplateTableActions';
import { compareTwoStringsForSorting } from '../../../helpers/stringHelper';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import FirmendatenKurzBezeichnung from '../../shared/FirmendatenKurzBezeichnung';
import { compareTwoPeriodsForSorting } from '../ticketTemplateHelpers';

const ticketTemplateTableColumns = (ticketTemplateList?: TicketTemplate[]): TableWithColSelectorColumnProps<TicketTemplate>[] => {
  return [
    {
      title: 'Bezeichnung',
      defaultSortOrder: 'ascend',
      defaultSelected: true,
      width: 200,
      sorter: (a, b) => compareTwoStringsForSorting(a.name, b.name),
      render: (text, record) => <DataWithShortenedText text={record.name} maxTextLength={25} />,
    },
    {
      title: 'Priorität',
      defaultSelected: true,
      width: 100,
      sorter: (a, b) => compareTwoStringsForSorting(a.priority.text, b.priority.text),
      render: (text, record) => <Typography.Text>{record.priority.text}</Typography.Text>,
    },
    {
      title: 'Fälligkeit',
      defaultSelected: true,
      width: 150,
      sorter: (a, b) => compareTwoPeriodsForSorting(a.dueDate, b.dueDate),
      render: (text, record) => <Typography.Text>{record.dueDate ? record.dueDateText : <TextForEmptyValue textToShow="minus" />}</Typography.Text>,
    },
    {
      title: 'Erinnerung',
      defaultSelected: true,
      width: 150,
      sorter: (a, b) => compareTwoPeriodsForSorting(a.reminder, b.reminder),
      render: (text, record) => <Typography.Text>{record.reminder ? record.reminderText : <TextForEmptyValue textToShow="minus" />}</Typography.Text>,
    },
    {
      title: 'Firma',
      defaultSelected: true,
      render: (text, record) => (record.firmendatenId ? <FirmendatenKurzBezeichnung firmendatenId={record.firmendatenId} /> : <Tag>System</Tag>),
    },
    {
      title: 'Erstellt am',
      width: 150,
      render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
    },
    {
      title: 'Ersteller',
      width: 80,
      render: (text, record) => (
        <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
      ),
    },
    {
      title: 'Aktion',
      defaultSelected: true,
      width: 100,
      key: 'action',
      render: (text, record) => <TicketTemplateTableActions ticketTemplate={record} ticketTemplateList={ticketTemplateList} />,
    },
  ];
};

export default ticketTemplateTableColumns;
