import React, { FC } from 'react';
import { Collapse, Row, Space, Typography } from 'antd';
import { Spacer } from '../../../../components/Grid';
import { StyledCollapsePanel } from '../../styled/Collapse.styles';
import { TextbausteinType } from '../../../../types';
import { TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';
import { TextbausteinCollapsePanelHeader } from '../../../../components/Template/PDFTemplates/TextbausteinList/TextbausteinListElements/TextbausteinCollapsePanelHeader';
import { isTextbausteinVariantComplete } from '../../../../components/Template/PDFTemplates/TextbausteinList/TextbausteinListElements/textbausteinListHelpers';

type Props = {
  level: number;
  onSelectTextbaustein: (value: TextbausteinType) => void;
  values: TextbausteinFormValues[];
};

const AbrDefVersionViewTemplateTextbausteinList: FC<Props> = ({ values, level, onSelectTextbaustein }) => {
  return (
    <Collapse bordered ghost accordion>
      {values
        .sort((a, b) => a.index - b.index)
        .map((textbaustein) => {
          if (textbaustein.textbausteinList && textbaustein.textbausteinList.length > 0) {
            return (
              <StyledCollapsePanel
                key={textbaustein.index}
                header={
                  <TextbausteinCollapsePanelHeader
                    onClick={() => onSelectTextbaustein(textbaustein.type.value)}
                    isTextbausteinComplete={isTextbausteinVariantComplete(textbaustein)}
                    title={textbaustein?.type.text}
                  />
                }
                showArrow={false}
              >
                <AbrDefVersionViewTemplateTextbausteinList
                  values={textbaustein.textbausteinList}
                  level={level + 1}
                  onSelectTextbaustein={onSelectTextbaustein}
                />
              </StyledCollapsePanel>
            );
          } else {
            return (
              <StyledCollapsePanel
                key={textbaustein.index}
                header={
                  <TextbausteinCollapsePanelHeader
                    onClick={() => onSelectTextbaustein(textbaustein.type.value)}
                    isTextbausteinComplete={isTextbausteinVariantComplete(textbaustein)}
                    title={textbaustein?.type.text}
                  />
                }
                showArrow={false}
              >
                <Row>
                  <Spacer />
                  <Space direction="vertical">
                    <Typography.Text>{textbaustein.value}</Typography.Text>
                  </Space>
                </Row>
              </StyledCollapsePanel>
            );
          }
        })}
    </Collapse>
  );
};

export default AbrDefVersionViewTemplateTextbausteinList;
