import React from 'react';
import { TableWithAlignedColsExpandedRowType } from '../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import TableWithAlignedColsHeaderRow from '../../../../components/Table/TableWithAlignedCols/TableWithAlignedColsHeaderRow';
import TableWithAlignedColsBody from '../../../../components/Table/TableWithAlignedCols/TableWithAlignedColsBody';
import ustVomAufwandKontoRowColumns from './ustVomAufwandKontoRowColumns';
import aufwandsKontoRow from './aufwandsKontoRow';
import { UstVomAufwandRepFondsKonto, UstVomAufwandVertrag } from '../../../../types';

const ustVomAufwandKontoRow = (
  objektId: string,
  rechtstraegerId: string,
  vonInklusive: string,
  bisInklusive: string
): TableWithAlignedColsExpandedRowType<UstVomAufwandVertrag>[] => [
  {
    header: (isOpen, setIsOpen) => (
      <TableWithAlignedColsHeaderRow
        isOpen={isOpen}
        bezeichnungHeaderText="Ust.-vom-Aufwand-Konto"
        sumHeaderText={['Summe Aufwand Rep. Fonds', 'Summe-Ust.-vom-Aufwand']}
        cellToUseForSumHeaderText={[5, 7]}
        onClick={setIsOpen}
        startCell={2}
        endCell={7}
        colSpan={[3, 2]}
      />
    ),
    body: (ustVomAufwand) => (
      <>
        {ustVomAufwand.repFondsKontoList.map((konto, index) => (
          <TableWithAlignedColsBody<UstVomAufwandRepFondsKonto>
            key={index}
            dataSource={konto}
            columns={ustVomAufwandKontoRowColumns(objektId)}
            expandedRow={() =>
              aufwandsKontoRow(objektId, rechtstraegerId, ustVomAufwand.bestandseinheit.bestandseinheitId, vonInklusive, bisInklusive)
            }
            positionOfButton={2}
          />
        ))}
      </>
    ),
  },
];

export default ustVomAufwandKontoRow;
