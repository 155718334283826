import React, { useState } from 'react';
import { Card, Input } from 'antd';
import CardTitle from '../../components/Card/CardTitle';
import { useRechtstraegerContactListQuery } from './gql/RechtstraegerContactQueries.types';
import RechtstraegerContactInfiniteList from './RechtstraegerContactInfiniteList';
import useDebounce from '../../hooks/useDebounce';

const DashboardRechtstraegerContactCard = () => {
  const [filter, setFilter] = useState<string>('');
  const debouncedText = useDebounce(filter);

  const { loading, data, fetchMore } = useRechtstraegerContactListQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: debouncedText as string,
      page: 0,
      pageSize: 15,
    },
  });

  const rechtstraegerContactList = data?.getRechtstraegerContactList.data;
  const totalElements = rechtstraegerContactList?.page?.totalElements ?? 0;
  const contentList = rechtstraegerContactList?.contentList ?? [];
  const isInitializing = contentList.length === 0 && loading;

  return (
    <Card style={{ minWidth: '320px' }} size="default" variant="borderless" title={<CardTitle title="Kontakte" />}>
      <div>
        <Input.Search placeholder="Suche Kontakte" style={{ width: '100%' }} onChange={(e) => setFilter(e.target.value)} />
      </div>
      <RechtstraegerContactInfiniteList
        contentList={contentList}
        fetchMore={fetchMore}
        loading={loading}
        hasMore={isInitializing || contentList.length < totalElements}
      />
    </Card>
  );
};

export default DashboardRechtstraegerContactCard;
