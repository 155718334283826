import { generatePath } from 'react-router-dom';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../constants/andromedaSystemSettingsUriPaths';

export const URI_ANDROMEDA_SYS_SETTINGS_KONTO_DETAILS_PAGE = `${URI_ANDROMEDA_SYS_SETTINGS.kontenRahmenPage}/:kontoId`;

export const pathsToKontoTemplateDetailsPage = (kontoId: string) => ({
  abrechnungen: `${generatePathToKontoTemplateDetailsPage(kontoId)}/abrechnungen`,
  bezuege: `${generatePathToKontoTemplateDetailsPage(kontoId)}/bezuege`,
});

export const generatePathToKontoTemplateDetailsPage = (kontoId: string): string =>
  generatePath(URI_ANDROMEDA_SYS_SETTINGS_KONTO_DETAILS_PAGE, { kontoId });
