import React, { FC } from 'react';
import { Spacer } from '../../../../../components/Grid';
import AuftragsartTemplate from '../../shared/Template/AuftragsartTemplate';
import DefinitionUpdateFormButtons from '../../../../shared/DefinitionUpdateFormButtons';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import { useFDAuthorized } from '../../../../../shared/Auth/useAuthorized';
import { useBelegTexteFirmendatenQuery } from '../../../../Belegtext/gql/BelegtexteQueries.types';
import LoadingPage from '../../../../../components/Loading/LoadingPage';
import { useFirmendatenQuery } from '../../../../KundenSystem/Firmendaten/gql/FirmendatenQueries.types';
import { useFirmendatenAddressesAndContactsQuery } from '../../../../Rechtstraeger/AddressesAndContacts/gql/AddressesAndContactsQueries.types';
import { getAuftragsartBelegKopfFussPlatzhalter } from '../../shared/auftragsartBelegKopfFussHelpers';
import { AuftragsartFieldsFragment } from '../../../gql/AuftragsartFragments.types';

type Props = {
  auftragsart: AuftragsartFieldsFragment;
  auftragsartTemplateValues: TextbausteinFormValues[];
  setAuftragsartTemplateValues: React.Dispatch<React.SetStateAction<TextbausteinFormValues[]>>;
  onSubmit: () => void;
  closePath: string;
};

const AuftragsartUpdateTemplateForm: FC<Props> = ({ auftragsart, auftragsartTemplateValues, setAuftragsartTemplateValues, onSubmit, closePath }) => {
  const { activeForFirmendatenId } = useFDAuthorized();

  const { data: firmendatendData, loading: fdLoading } = useFirmendatenQuery({ variables: { firmendatenId: activeForFirmendatenId } });
  const firmendaten = firmendatendData?.getFirmendaten.data;

  const { data: belegTexteData, loading: belegTexteLoading } = useBelegTexteFirmendatenQuery({
    variables: { firmendatenId: activeForFirmendatenId },
  });
  const belegTexte = belegTexteData?.getBelegTexteFirmendaten.data;

  const { data: fdAddressesAndContactsData, loading: fdAddressesAndContactsLoading } = useFirmendatenAddressesAndContactsQuery();
  const addressesAndContacts = fdAddressesAndContactsData?.getFirmendatenAddressesAndContacts.data;

  const auftragsartBelegKopfFussPlatzhalter = getAuftragsartBelegKopfFussPlatzhalter(firmendaten, addressesAndContacts);

  return (
    <LoadingPage loading={fdLoading || belegTexteLoading || fdAddressesAndContactsLoading}>
      <Spacer height={16} />
      <AuftragsartTemplate
        templateFormValues={auftragsartTemplateValues}
        onTemplateChange={setAuftragsartTemplateValues}
        showUstBlock={auftragsart.showUstBlock ?? false}
        firmendatenId={activeForFirmendatenId}
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        belegTexte={belegTexte!}
        belegTextePlatzhalter={auftragsartBelegKopfFussPlatzhalter}
      />
      <DefinitionUpdateFormButtons onSubmit={onSubmit} pushPath={closePath} />
    </LoadingPage>
  );
};

export default AuftragsartUpdateTemplateForm;
