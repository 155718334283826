import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Col, Row, Typography } from 'antd';
import FormButtons from '../../../../../../components/Button/FormButtons';
import { postofficeBoxAddressValidationSchema } from '../../../../../../shared/components/Address/form/addressFieldsValidationSchema';
import { showSuccessMsgUpdate } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { useUpdateAddressMutation } from '../../../gql/AddressesAndContactsMutations.types';
import { mapFormValuesToAddress } from '../../../../../../shared/components/Address/form/addressMapper';
import StreetAddressFormInputs from '../shared/StreetAddressFormInputs';
import {
  mapPostofficeBoxAddressToFormValues,
  postofficeBoxAddressUpdateFormFields,
  PostofficeBoxAddressUpdateFormValues,
} from './postofficeBoxAddressUpdateFormMapper';
import { Address } from '../../../../../../types';

type Props = {
  item: Address;
  firmendatenId: string;
  onSuccess: () => void;
  onCancel: () => void;
};

const PostofficeBoxAddressUpdateForm: FC<Props> = ({ item, firmendatenId, onSuccess, onCancel }) => {
  const [updateAddress, { loading }] = useUpdateAddressMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`Adresse`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<PostofficeBoxAddressUpdateFormValues>
      initialValues={mapPostofficeBoxAddressToFormValues(item)}
      validationSchema={postofficeBoxAddressValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        updateAddress({
          variables: { firmendatenId, addressId: item.addressId, input: mapFormValuesToAddress(values) },
        }).finally(() => setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Adresse</Typography.Title>
            </Col>
            <Col span={18}>
              <StreetAddressFormInputs formFields={postofficeBoxAddressUpdateFormFields} />
            </Col>
          </Row>
          <FormButtons updateMode={false} onCancel={onCancel} isOkLoading={loading} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default PostofficeBoxAddressUpdateForm;
