import React, { FC } from 'react';
import { Form, Input } from 'formik-antd';
import { FormikProps } from 'formik';
import KontoFormItemKlasse from '../Form/FormItems/KontoKlasse/KontoFormItemKlasse';
import KontoFormItemType from '../Form/FormItems/KontoType/KontoFormItemType';
import { disabledPersonenkontoAndNoKontoverwendung, isKontoTypePersonenkonto, ktoNumberPrefix } from '../Form/kontoFormHelpers';
import KontoFormItemVerwendung from '../Form/FormItems/KontoVerwendung/KontoFormItemVerwendung';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import { kontoFormFields, KontoFormValues } from '../Form/kontoFormMapper';
import FormButtons from '../../../components/Button/FormButtons';

type Props = {
  isEditable: boolean;
  isLoading: boolean;
  isUpdate: boolean;
  onCancel: () => void;
  formikProps: FormikProps<KontoFormValues>;
};

const KontoFormContent: FC<Props> = ({ isEditable, isLoading, isUpdate, onCancel, formikProps }) => {
  return (
    <Form layout="vertical">
      <KontoFormItemKlasse disabled={!isEditable} />
      <KontoFormItemType disabled={!isEditable} formikProps={formikProps} />
      {!formikProps.values.klasse || disabledPersonenkontoAndNoKontoverwendung(formikProps.values.klasse) ? null : (
        <KontoFormItemVerwendung disabled={!isEditable} formikProps={formikProps} />
      )}
      <FormItemWithoutColon name={kontoFormFields.bezeichnung} label="Bezeichnung">
        <Input id={kontoFormFields.bezeichnung} name={kontoFormFields.bezeichnung} maxLength={100} placeholder='z.B. Konto "leicht zu merken"' />
      </FormItemWithoutColon>
      <FormItemWithoutColon name={kontoFormFields.nummer} label="Nummer">
        <Input
          id={kontoFormFields.nummer}
          name={kontoFormFields.nummer}
          // maxLength={10}
          addonBefore={ktoNumberPrefix(formikProps.values.klasse, formikProps.values.type, formikProps.values.verwendung)}
          disabled={isUpdate && isKontoTypePersonenkonto(formikProps.values.type)}
          placeholder="z.B. 1220"
        />
      </FormItemWithoutColon>

      <FormButtons updateMode={isUpdate} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} isOkLoading={isLoading} />
    </Form>
  );
};

export default KontoFormContent;
