import { FC } from 'react';
import HistoryListingTable from '../../../../History/HistoryListingTable';
import { HistoryType } from '../../../../History/shared/HistoryModal';
import { useMahnPositionChangeHistoryListQuery } from '../../../gql/MahnungQueries.types';

type Props = {
  mahnungId: string;
  mahnPositionId: string;
};

const MahnpositionChangeHistoryListingTable: FC<Props> = ({ mahnungId, mahnPositionId }) => {
  const { data, loading } = useMahnPositionChangeHistoryListQuery({ variables: { mahnungId, mahnPositionId } });
  const historyList = data?.getMahnPositionChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="mahnposition" />;
};

export default MahnpositionChangeHistoryListingTable;
