import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { isZuordnungKontoKlasseTypeAusgaben, isZuordnungKontoKlasseTypeEinnahmen, ZuordnungKontoKlasseType } from './AbrKreisKontenzuordnungHelpers';
import { KontoVerwendung } from '../../../types';
import { isKontoVerwendungUmsatzsteuer, isKontoVerwendungVorsteuer } from '../../Kontenplan/Form/kontoFormHelpers';

type Props = {
  bezeichnung: string;
  zuordnungKontoKlasseType: ZuordnungKontoKlasseType;
  verwendung?: KontoVerwendung | null;
  nummer: string;
};

const ZuordnungKontoListItemBezeichnung: FC<Props> = ({ bezeichnung, zuordnungKontoKlasseType, verwendung, nummer }) => {
  let fibuInfoText;
  if (isZuordnungKontoKlasseTypeEinnahmen(zuordnungKontoKlasseType)) {
    fibuInfoText = verwendung && isKontoVerwendungUmsatzsteuer(verwendung) ? 'Umsatzsteuerkonto' : 'Erlöskonto';
  } else if (isZuordnungKontoKlasseTypeAusgaben(zuordnungKontoKlasseType)) {
    fibuInfoText = verwendung && isKontoVerwendungVorsteuer(verwendung) ? 'Vorsteuerkonto' : 'Aufwandskonto';
  } else fibuInfoText = 'Eigenkapitalkonto';

  return (
    <Space direction="vertical" size={0}>
      <Typography.Text strong>
        {nummer} - {bezeichnung}
      </Typography.Text>
      <Typography.Text disabled italic>
        ({fibuInfoText})
      </Typography.Text>
    </Space>
  );
};

export default ZuordnungKontoListItemBezeichnung;
