import { InfoFeldFormValues } from '../../../../../shared/InfoFeld/infoFeldFormMapper';
import { Kuendigungsfrist, KuendigungsfristJeweilsZum, ObjektInfoFeldVersicherung, Zahlungsperiode } from '../../../../../../types';
import { FormFields } from '../../../../../../helpers/formikHelper';

export interface VersicherungUpdateFormValues extends InfoFeldFormValues {
  versicherungsart: string;
  firmaId?: string;
  contactPersonId?: string;
  beginn?: string;
  datumSchaetzung?: string;
  ende?: string;
  erstmalsFaellig?: string;
  validFrom?: string;
  hauptDueDate: string | null;
  kuendigungsfrist: Kuendigungsfrist | null;
  kuendigungsfristDauer?: number;
  kuendigungsfristJeweilsZum?: KuendigungsfristJeweilsZum;
  maklerId?: string;
  neubauwert?: number;
  polizze?: string;
  sachbearbeiterId?: string;
  versicherungssumme?: number;
  wertsicherung?: string;
  zahlungsperiode?: Zahlungsperiode;
  deleteDocumentFileIdList: string[];
}

export const versicherungUpdateFormFields: FormFields<VersicherungUpdateFormValues> = {
  contactPersonId: 'contactPersonId',
  beginn: 'beginn',
  datumSchaetzung: 'datumSchaetzung',
  ende: 'ende',
  erstmalsFaellig: 'erstmalsFaellig',
  firmaId: 'firmaId',
  validFrom: 'validFrom',
  hauptDueDate: 'hauptDueDate',
  kuendigungsfrist: 'kuendigungsfrist',
  kuendigungsfristDauer: 'kuendigungsfristDauer',
  kuendigungsfristJeweilsZum: 'kuendigungsfristJeweilsZum',
  maklerId: 'maklerId',
  neubauwert: 'neubauwert',
  polizze: 'polizze',
  sachbearbeiterId: 'sachbearbeiterId',
  versicherungsart: 'versicherungsart',
  versicherungssumme: 'versicherungssumme',
  wertsicherung: 'wertsicherung',
  zahlungsperiode: 'zahlungsperiode',
  documentList: 'documentList',
  deleteDocumentFileIdList: 'deleteDocumentFileIdList',
};

export const mapToUpdateFormValues = (infofeld: ObjektInfoFeldVersicherung): VersicherungUpdateFormValues => ({
  versicherungsart: infofeld.versicherungsart,
  firmaId: infofeld.firma?.rechtstraegerId ?? undefined,
  contactPersonId: infofeld.contactPerson?.contactPersonId ?? undefined,
  beginn: infofeld.beginn ?? undefined,
  datumSchaetzung: infofeld.datumSchaetzung ?? undefined,
  ende: infofeld.ende ?? undefined,
  erstmalsFaellig: infofeld.erstmalsFaellig ?? undefined,
  validFrom: infofeld.validFrom ?? undefined,
  hauptDueDate: infofeld.hauptDueDate ?? null,
  kuendigungsfrist: infofeld.kuendigungsfrist?.value ?? null,
  kuendigungsfristDauer: infofeld.kuendigungsfristDauer ?? undefined,
  kuendigungsfristJeweilsZum: infofeld.kuendigungsfristJeweilsZum?.value ?? undefined,
  maklerId: infofeld.makler?.rechtstraegerId ?? undefined,
  neubauwert: infofeld.neubauwert ?? undefined,
  polizze: infofeld.polizze ?? undefined,
  sachbearbeiterId: infofeld.sachbearbeiter?.contactPersonId ?? undefined,
  versicherungssumme: infofeld.versicherungssumme ?? undefined,
  wertsicherung: infofeld.wertsicherung ?? undefined,
  zahlungsperiode: infofeld.zahlungsperiode?.value ?? undefined,
  documentList: [],
  deleteDocumentFileIdList: [],
});
