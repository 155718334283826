import { generatePath } from 'react-router';
import { uri } from '../../constants/appUriPaths';

export const URI_DASHBOARD_BOOKING_SUGGESTION_BUCHUNGSMASKE_PAGE = `${uri.dashboard}/booking-suggestion-accounting-mask/:bookingSuggestionId`;
export const URI_DASHBOARD_BOOKING_SUGGESTION_BUCHUNGSMASKE_WITH_TABS_PAGE = `${uri.dashboard}/booking-suggestions-accounting-mask/:receivedBankTransactionId`;

export const generatePathToBookingSuggestionBuchungsmaskePage = (bookingSuggestionId: string) =>
  generatePath(URI_DASHBOARD_BOOKING_SUGGESTION_BUCHUNGSMASKE_PAGE, { bookingSuggestionId });

export const generatePathToBookingSuggestionBuchungsmaskeWithTabsPage = (receivedBankTransactionId: string) =>
  generatePath(URI_DASHBOARD_BOOKING_SUGGESTION_BUCHUNGSMASKE_WITH_TABS_PAGE, { receivedBankTransactionId });
