import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestartMahnungAusgebenGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartMahnungAusgebenGenerierlaufMutation = {
  actionRestartMahnungAusgebenGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateMahnungAusgebenGenerierlaufMutationVariables = Types.Exact<{
  input: Types.MahnungGenerierlaufInput;
}>;

export type CreateMahnungAusgebenGenerierlaufMutation = {
  createMahnungAusgebenGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RestartMahnungAusgebenGenerierlaufDocument = gql`
  mutation RestartMahnungAusgebenGenerierlauf($generierlaufId: ID!) {
    actionRestartMahnungAusgebenGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartMahnungAusgebenGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<RestartMahnungAusgebenGenerierlaufMutation, RestartMahnungAusgebenGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestartMahnungAusgebenGenerierlaufMutation, RestartMahnungAusgebenGenerierlaufMutationVariables>(
    RestartMahnungAusgebenGenerierlaufDocument,
    options
  );
}
export type RestartMahnungAusgebenGenerierlaufMutationHookResult = ReturnType<typeof useRestartMahnungAusgebenGenerierlaufMutation>;
export type RestartMahnungAusgebenGenerierlaufMutationResult = Apollo.MutationResult<RestartMahnungAusgebenGenerierlaufMutation>;
export type RestartMahnungAusgebenGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartMahnungAusgebenGenerierlaufMutation,
  RestartMahnungAusgebenGenerierlaufMutationVariables
>;
export const CreateMahnungAusgebenGenerierlaufDocument = gql`
  mutation CreateMahnungAusgebenGenerierlauf($input: MahnungGenerierlaufInput!) {
    createMahnungAusgebenGenerierlauf(input: $input) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateMahnungAusgebenGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMahnungAusgebenGenerierlaufMutation, CreateMahnungAusgebenGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMahnungAusgebenGenerierlaufMutation, CreateMahnungAusgebenGenerierlaufMutationVariables>(
    CreateMahnungAusgebenGenerierlaufDocument,
    options
  );
}
export type CreateMahnungAusgebenGenerierlaufMutationHookResult = ReturnType<typeof useCreateMahnungAusgebenGenerierlaufMutation>;
export type CreateMahnungAusgebenGenerierlaufMutationResult = Apollo.MutationResult<CreateMahnungAusgebenGenerierlaufMutation>;
export type CreateMahnungAusgebenGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  CreateMahnungAusgebenGenerierlaufMutation,
  CreateMahnungAusgebenGenerierlaufMutationVariables
>;
