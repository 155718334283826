import React, { FC } from 'react';
import { Box } from 'rebass';
import { Skeleton } from 'antd';
import ZuordnungKontoKlasseTitle from './ZuordnungKontoKlasseTitle';
import { Konto } from '../../../types';

type Props = {
  title: string;
  kontoList: Konto[];
  isCollapsed: boolean;
};

const ZuordnungListLoading: FC<Props> = ({ title, kontoList, isCollapsed }) => (
  <>
    <ZuordnungKontoKlasseTitle title={title} isTransparent />
    {isCollapsed ? (
      <>
        {kontoList.map((konto, index) => (
          <Box px="12px" key={`${konto.nummer}${index}`}>
            <Skeleton paragraph={false} title loading />
          </Box>
        ))}
      </>
    ) : null}
  </>
);

export default ZuordnungListLoading;
