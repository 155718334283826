import { formattedDecimalType } from '../../components/Input-antd';
import { entityHasToBeAnInt, entityHasToBeANumber, entityHasToBeNonNegative, entityIsRequired } from '../../components/message/validationMsg';

export const nettoValidationSchema = formattedDecimalType(entityHasToBeANumber('Netto'));
export const bruttoValidationSchema = formattedDecimalType(entityHasToBeANumber('Brutto'));
export const ustValidationSchema = formattedDecimalType(entityHasToBeANumber('Ust'));

export const steuersatzValidationSchema = formattedDecimalType(entityHasToBeANumber('Steuersatz'))
  .required(entityIsRequired('Steuersatz'))
  .integer(entityHasToBeAnInt('Steuersatz'))
  .min(0, entityHasToBeNonNegative('Steuersatz'));

export const steuersatzVersionValidationSchema = formattedDecimalType(entityHasToBeANumber('Steuersatz'))
  .integer(entityHasToBeAnInt('Steuersatz'))
  .min(0, entityHasToBeNonNegative('Steuersatz'));
