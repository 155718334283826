import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import HistoryModal, { HistoryType } from '../../History/shared/HistoryModal';
import MahndefinitionTemplateChangeHistoryListingTable from './MahndefinitionTemplateChangeHistoryListingTable';
import MahnstufeTemplateChangeHistoryListingTable from './MahnstufeTemplateChangeHistoryListingTable';
import MahnungTemplateTemplateHistoryListingTable from './MahnungTemplateTemplateHistoryListingTable';

type Props = {
  mahndefinitionId: string;
  open: boolean;
  onCancel: () => void;
};

const MahndefintionTemplateChangeHistoryModal: FC<Props> = ({ mahndefinitionId, open, onCancel }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Allgemein',
      children: <MahndefinitionTemplateChangeHistoryListingTable mahndefinitionId={mahndefinitionId} />,
    },
    {
      key: '2',
      label: 'Mahnstufen',
      children: mahnstufeChangeHistoryTabs(mahndefinitionId),
    },
    {
      key: '3',
      label: 'Mahnungen',
      children: mahnungTemplateChangeHistoryTabs(mahndefinitionId),
    },
  ];

  return (
    <HistoryModal historyType={HistoryType.Change} open={open} onCancel={onCancel}>
      <Tabs items={items} />
    </HistoryModal>
  );
};

const mahnstufeChangeHistoryTabs = (mahndefinitionId: string): React.ReactNode => {
  const items: TabsProps['items'] = [];
  let index = 1;
  while (index <= 4) {
    items.push({
      key: String(index),
      label: `Mahnstufen ${index}`,
      children: <MahnstufeTemplateChangeHistoryListingTable mahndefinitionId={mahndefinitionId} mahnstufeIndex={index} />,
    });
    // eslint-disable-next-line no-plusplus
    index++;
  }
  return <Tabs items={items} />;
};

const mahnungTemplateChangeHistoryTabs = (mahndefinitionId: string): React.ReactNode => {
  const items: TabsProps['items'] = [];
  let index = 1;
  while (index <= 4) {
    items.push({
      key: String(index),
      label: `Mahnstufen ${index}`,
      children: <MahnungTemplateTemplateHistoryListingTable mahndefinitionId={mahndefinitionId} mahnstufeIndex={index} />,
    });
    // eslint-disable-next-line no-plusplus
    index++;
  }
  return <Tabs items={items} />;
};

export default MahndefintionTemplateChangeHistoryModal;
