import React, { FC, useState } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useFilterFibuBzTexteListQuery } from '../gql/FibuBuchungszeileQueries.types';
import { selectFilterOption } from '../../../helpers/selectHelper';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import useDebounce from '../../../hooks/useDebounce';
import { isTextEqualOrLongerThanThreeCharacters } from '../../../helpers/stringHelper';
import SelectNotFoundContent from '../../shared/SelectNotFoundContent';

type Props = {
  rechtstraegerIdList?: string[] | null;
  text?: string | null;
} & Omit<SelectProps, 'id' | 'allowClear' | 'filterOption' | 'loading' | 'showSearch'>;

const FibuBzTextListFormSelect: FC<Props> = ({ rechtstraegerIdList, text, ...restProps }) => {
  const [buchungsText, setBuchungsText] = useState<string>(text ? text.toString() : '');
  const debouncedText = useDebounce(buchungsText);

  const { loading, data } = useFilterFibuBzTexteListQuery({
    variables: {
      rechtstraegerIdList,
      text: debouncedText as string,
    },
    skip: debouncedText ? !isTextEqualOrLongerThanThreeCharacters(debouncedText as string) : true,
  });
  const list = data?.getFilterFibuBzTexteList.data ?? [];

  return (
    <Select
      size="middle"
      placeholder="Buchungstext auswählen"
      {...restProps}
      id={restProps.name}
      loading={loading}
      allowClear
      showSearch
      filterOption={selectFilterOption}
      onSelect={setBuchungsText}
      onSearch={(value) => {
        buchungsText.length === 1 && !value && setBuchungsText(''); // Checking the length of the belegnummerText makes the search component editable
        value && setBuchungsText(value);
      }}
      onClear={() => setBuchungsText('')}
      searchValue={buchungsText}
      notFoundContent={<SelectNotFoundContent loading={loading} text={debouncedText as string} />}
    >
      {list.map((text) => (
        <Select.Option key={text} value={text}>
          <DataWithShortenedText maxTextLength={48} text={text} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default FibuBzTextListFormSelect;
