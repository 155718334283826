/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import { entityIsRequired } from '../../../../../components/message/validationMsg';
import { sepaMandatCreateFormFields } from './sepaMandatCreateFormMapper';

export const sepaMandatCreateFormValidationSchema = Yup.object().shape({
  [sepaMandatCreateFormFields.rechnungsAusstellerId]: Yup.string().required(entityIsRequired('Rechnungsaussteller')),
  [sepaMandatCreateFormFields.sepaMandatReference]: Yup.string().nullable(),
});
/* eslint-enable no-template-curly-in-string */
