import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PersonsCommunityTypeListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type PersonsCommunityTypeListQuery = {
  getPersonsCommunityTypeList: {
    data: Array<{ text: string; value: Types.PersonsCommunityType }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const PersonsCommunityTypeListDocument = gql`
  query PersonsCommunityTypeList {
    getPersonsCommunityTypeList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function usePersonsCommunityTypeListQuery(
  baseOptions?: Apollo.QueryHookOptions<PersonsCommunityTypeListQuery, PersonsCommunityTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PersonsCommunityTypeListQuery, PersonsCommunityTypeListQueryVariables>(PersonsCommunityTypeListDocument, options);
}
export function usePersonsCommunityTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PersonsCommunityTypeListQuery, PersonsCommunityTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PersonsCommunityTypeListQuery, PersonsCommunityTypeListQueryVariables>(PersonsCommunityTypeListDocument, options);
}
export function usePersonsCommunityTypeListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PersonsCommunityTypeListQuery, PersonsCommunityTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PersonsCommunityTypeListQuery, PersonsCommunityTypeListQueryVariables>(PersonsCommunityTypeListDocument, options);
}
export type PersonsCommunityTypeListQueryHookResult = ReturnType<typeof usePersonsCommunityTypeListQuery>;
export type PersonsCommunityTypeListLazyQueryHookResult = ReturnType<typeof usePersonsCommunityTypeListLazyQuery>;
export type PersonsCommunityTypeListSuspenseQueryHookResult = ReturnType<typeof usePersonsCommunityTypeListSuspenseQuery>;
export type PersonsCommunityTypeListQueryResult = Apollo.QueryResult<PersonsCommunityTypeListQuery, PersonsCommunityTypeListQueryVariables>;
