import { FormFields } from '../../../../../../helpers/formikHelper';
import { GenerierlaufEntryExitCode } from '../../../../../../types';
import { TVorschreibungVerbuchenVerarbeitungQueryParams } from './filtersQueryParams';

export type VorschreibungVerbuchenVerarbeitungEntryFiltersFormValues = {
  fakturierungsperiode?: string;
  rechnungsausstellerId?: string;
  rechnungsempfaengerId?: string;
  exitCodeList?: GenerierlaufEntryExitCode[];
};

export const vorschreibungVerbuchenVerarbEntryListingFiltersFormFields: FormFields<VorschreibungVerbuchenVerarbeitungEntryFiltersFormValues> = {
  fakturierungsperiode: 'fakturierungsperiode',
  rechnungsausstellerId: 'rechnungsausstellerId',
  rechnungsempfaengerId: 'rechnungsempfaengerId',
  exitCodeList: 'exitCodeList',
};

export const vorschreibungVerbuchenVerarbEntryListingFiltersFormInitialValues = (
  queryParams: TVorschreibungVerbuchenVerarbeitungQueryParams
): VorschreibungVerbuchenVerarbeitungEntryFiltersFormValues => ({
  fakturierungsperiode: queryParams.fakturierungsperiode,
  rechnungsausstellerId: queryParams.rechnungsausstellerId,
  rechnungsempfaengerId: queryParams.rechnungsempfaengerId,
  exitCodeList: queryParams.exitCodeList,
});

export const mapFormValuesToVorschreibungVerbuchenVerarbeitungFilters = (
  formValues: VorschreibungVerbuchenVerarbeitungEntryFiltersFormValues
): TVorschreibungVerbuchenVerarbeitungQueryParams => ({
  fakturierungsperiode: formValues.fakturierungsperiode,
  rechnungsausstellerId: formValues.rechnungsausstellerId,
  rechnungsempfaengerId: formValues.rechnungsempfaengerId,
  exitCodeList: formValues.exitCodeList,
});
