import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Tabs, TabsProps } from 'antd';
import useChangeRouteTabs from './useChangeRouteTabs';
import { RouteTabsProps } from './RouteTabsProps';

const RouteTabs: FC<TabsProps> = ({ items, ...props }) => {
  const defaultSelectedTabKey = items?.[0].key;
  const [activeTabKey, changeTab] = useChangeRouteTabs(items, defaultSelectedTabKey);
  const tabItems = createTabItems(items, changeTab);

  return <Tabs activeKey={activeTabKey?.toString()} destroyInactiveTabPane items={tabItems} {...props} />;
};

const createTabItems = (tabs: RouteTabsProps, onTabChange: (activeKey?: string) => void) => {
  const tabItems: RouteTabsProps = [];

  tabs?.forEach((tab) => {
    tabItems.push({
      key: tab.key,
      label: (
        <Link
          to={tab.key}
          style={{
            color: 'inherit',
          }}
          onClick={() => onTabChange(tab.key)}
        >
          {tab.label}
        </Link>
      ),
      children: tab.children,
    });
  });

  return tabItems;
};

export default RouteTabs;
