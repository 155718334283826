import React from 'react';
import { Route } from 'react-router';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../security/permissionChecks';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_SYS_SETTINGS } from '../../../../constants/configurationUriPaths';
import SysSettingsRepFondsPage from './SysSettingsRepFondsPage';

const sysSettingsRepFondsPageRoute = (
  <Route
    path={URI_SYS_SETTINGS.repFondsPage}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsRepFondsPage />} />}
  />
);

export default sysSettingsRepFondsPageRoute;
