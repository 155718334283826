import React, { FC } from 'react';
import { generatePath } from 'react-router';
import { Button, Drawer, Dropdown, Modal, Space } from 'antd';
import { DeleteOutlined, EllipsisOutlined, FileDoneOutlined, FileSearchOutlined, HistoryOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { showSuccessMsgDelete, showSuccessMsgOther } from '../../../../components/message';
import { useToggle } from '../../../../hooks/useToggle';
import { URI_OBJEKT_BESTANDSEINHEIT_DETAILS_PAGE } from '../../../Bestandseinheit/bestandseinheitUriPaths';
import CardWithFooter from '../../../../components/Card';
import { useGoBack } from '../../../../shared/GoBack/GoBackContext';
import { StyledSkeleton } from '../../../../components/Loading';
import VertragVersionTimeline from '../Version/VertragVersionTimeline';
import VertragNutzungDetailsForm from '../Forms/VertragNutzungDetailsForm';
import VertragPaymentDetailsForm from '../Forms/VertragPaymentDetailsForm';
import { useTimelineOpen } from '../../../../components/Timeline/useTimelineOpen';
import ContextMenu from '../../../../components/Timeline/ContextMenu';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { isStatusPruefungAusgesetzt } from '../../../../helpers/statusHelper';
import VertragNutzungDetails from './VertragNutzungDetails';
import VertragRechnungDetails from './VertragRechnungDetails';
import VertragPaymentDetails from './VertragPaymentDetails';
import CardTitle from '../../../../components/Card/CardTitle';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../../components/Button/ButtonsAligned';
import BeVertragPostIt from '../PostIt/BeVertragPostIt';
import { useBeVertragPruefungAktivierenMutation, useBeVertragPruefungAussetzenMutation } from '../Deviation/gql/BeVertragDeviationMutations.types';
import { useBeVertragMarkUnderReviewMutation, useDeleteBeVertragMutation } from '../gql/BeVertragMutations.types';
import { radixActionStyles } from '../../../../helpers/radixIconsStyles';
import HistoryModal, { HistoryType } from '../../../History/shared/HistoryModal';
import VertragChangeHistoryListingTable from './VertragChangeHistoryListingTable';
import { Bestandseinheit } from '../../../../types';
import { BeVertragFragment } from '../gql/BeVertragFragments.types';
import InnerCardTagList from '../../../shared/InnerCardTagList';

type Props = {
  objektId: string;
  vertragId: string;
  bestandseinheitId: string;
  bestandseinheit: Bestandseinheit;
  vertrag: BeVertragFragment;
  loading: boolean;
  refetch: () => void;
  rechnungsausstellerId: string;
};

const VertragCard: FC<Props> = ({ objektId, vertragId, bestandseinheitId, bestandseinheit, vertrag, loading, refetch, rechnungsausstellerId }) => {
  const [isOpenVertragNutzungDetailsForm, toggleVertragNutzungDetailsForm] = useToggle();
  const [isOpenZahlungDetailsForm, toggleZahlungDetailsForm] = useToggle();
  const { timelineDrawer, openTimelineUpdateDrawer, closeTimelineDrawer } = useTimelineOpen();
  const [isChangeHistoryOpen, toggleChangehistoryOpen] = useToggle();

  const [runPruefungAktivieren] = useBeVertragPruefungAktivierenMutation({
    onCompleted: () => {
      showSuccessMsgOther('@TODO');
      refetch();
    },
    variables: {
      objektId,
      bestandseinheitId,
      vertragId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runPruefungAussetzen] = useBeVertragPruefungAussetzenMutation({
    onCompleted: () => {
      showSuccessMsgOther('@TODO');
      refetch();
    },
    variables: {
      objektId,
      bestandseinheitId,
      vertragId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runDeleteVertrag] = useDeleteBeVertragMutation({
    onCompleted: () => {
      showSuccessMsgDelete('Vertrag');
      goBack();
    },
    variables: {
      objektId,
      bestandseinheitId,
      vertragId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runMarkUnderReviewVertrag] = useBeVertragMarkUnderReviewMutation({
    onCompleted: () => {
      showSuccessMsgOther(`Vertrag wird überprüft`);
    },
    variables: {
      objektId,
      bestandseinheitId,
      vertragId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const goBack = useGoBack(generatePath(URI_OBJEKT_BESTANDSEINHEIT_DETAILS_PAGE, { objektId, bestandseinheitId }));

  if (!vertrag) {
    return <StyledSkeleton loading={loading} active paragraph={{ rows: 4 }} />;
  }

  return (
    <CardWithFooter
      loading={loading}
      style={{ minWidth: '320px' }}
      size="default"
      bordered={false}
      title={
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <CardTitle
            title={`${vertrag.vertragsart.text} ${bestandseinheit.bezeichnung} Vertrag`}
            status={vertrag.status}
            warningList={vertrag.warningList}
          />
          <BeVertragPostIt
            objektId={objektId}
            bestandseinheitId={bestandseinheitId}
            vertragId={vertragId}
            postIt={vertrag.postIt}
            refresh={refetch}
          />
        </Space>
      }
      extra={
        <Dropdown
          menu={{
            items: menuItems(
              vertrag,
              runDeleteVertrag,
              runMarkUnderReviewVertrag,
              runPruefungAktivieren,
              runPruefungAussetzen,
              toggleChangehistoryOpen
            ),
          }}
        >
          <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
        </Dropdown>
      }
      createTs={vertrag.createTs}
      userId={vertrag.createdBy}
      mitarbeiterId={vertrag.createdByMitarbeiterId}
    >
      <VertragNutzungDetails
        vertrag={vertrag}
        objektId={objektId}
        bestandseinheitId={bestandseinheitId}
        onZuruecksetzenSuccess={refetch}
        toggleVertragNutzungDetailsForm={toggleVertragNutzungDetailsForm}
      />

      {vertrag.tagList && vertrag.tagList.length > 0 ? <InnerCardTagList tagList={vertrag.tagList} /> : null}

      <VertragRechnungDetails vertrag={vertrag} openTimelineUpdateDrawer={openTimelineUpdateDrawer} />

      <ContextMenu openDrawerCreate={toggleZahlungDetailsForm} />
      <VertragPaymentDetails vertrag={vertrag} />

      <Drawer
        title="Bearbeiten"
        width={720}
        open={timelineDrawer.visible}
        onClose={closeTimelineDrawer}
        destroyOnClose
        footer={
          <ButtonsAligned usage={ButtonsAlignedUsage.drawerFooter}>
            <Button onClick={closeTimelineDrawer} type="primary">
              Schließen
            </Button>
          </ButtonsAligned>
        }
      >
        <VertragVersionTimeline objektId={objektId} bestandseinheitId={bestandseinheitId} vertrag={vertrag} reloadBeVertrag={refetch} />
      </Drawer>

      <Drawer title="Bearbeiten" width={720} open={isOpenVertragNutzungDetailsForm} onClose={toggleVertragNutzungDetailsForm} destroyOnClose>
        <VertragNutzungDetailsForm
          onSuccess={() => {
            toggleVertragNutzungDetailsForm();
            refetch();
          }}
          onCancel={toggleVertragNutzungDetailsForm}
          objektId={objektId}
          bestandseinheitId={bestandseinheitId}
          vertrag={vertrag}
          rechnungsausstellerId={rechnungsausstellerId}
        />
      </Drawer>

      <Drawer title="Bearbeiten" width={720} open={isOpenZahlungDetailsForm} onClose={toggleZahlungDetailsForm} destroyOnClose>
        <VertragPaymentDetailsForm
          onSuccess={() => {
            toggleZahlungDetailsForm();
            refetch();
          }}
          onCancel={toggleZahlungDetailsForm}
          objektId={objektId}
          bestandseinheitId={bestandseinheitId}
          vertrag={vertrag}
        />
      </Drawer>

      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangehistoryOpen}>
        <VertragChangeHistoryListingTable objektId={objektId} bestandseinheitId={bestandseinheit.bestandseinheitId} vertragId={vertragId} />
      </HistoryModal>
    </CardWithFooter>
  );
};

const menuItems = (
  vertrag: BeVertragFragment,
  runDelete: () => void,
  runMarkUnderReview: () => void,
  runPruefungAktivieren: () => void,
  runPruefungAussetzen: () => void,
  onChangeHistoryClicked: () => void
) => [
  {
    label: 'Abweichungen',
    key: 'Abweichungen',
    icon: <ThunderboltOutlined />,
    children: [
      {
        key: 'deviations-item-1',
        label: 'Überprüfen',
        onClick: () => showConfirmPruefungAktivieren(runPruefungAktivieren),
        icon: <FileDoneOutlined />,
        disabled: !isStatusPruefungAusgesetzt(vertrag.status),
      },
      {
        key: 'deviations-item-2',
        label: 'Ignorieren',
        onClick: () => showConfirmPruefungAussetzen(runPruefungAussetzen),
        disabled: isStatusPruefungAusgesetzt(vertrag.status),
      },
    ],
  },
  {
    key: '2',
    label: 'Prüfen',
    onClick: () => showConfirmMarkUnderReview(runMarkUnderReview),
    icon: <FileSearchOutlined />,
  },
  {
    key: '3',
    label: 'Änderungsverlauf',
    onClick: onChangeHistoryClicked,
    icon: <HistoryOutlined style={radixActionStyles} />,
  },
  {
    key: '4',
    label: 'Löschen',
    onClick: () => showConfirmDelete(vertrag, runDelete),
    icon: <DeleteOutlined />,
    disabled: !vertrag.deletable,
    danger: true,
  },
];

const showConfirmPruefungAussetzen = (runPruefungAussetzen: () => void) => {
  Modal.confirm({
    title: `Möchten Sie die Überprüfung für alle Abweichungen aussetzen?`,
    content: `EasyCloud setzt die fachlich und logische Überprüfung für alle Abweichungen aus.`,
    okText: 'Ja',
    cancelText: 'Nein',
    onOk() {
      return runPruefungAussetzen();
    },
  });
};

const showConfirmPruefungAktivieren = (runPruefungAktivieren: () => void) => {
  Modal.confirm({
    title: `Möchten Sie die Überprüfung für alle Abweichungen aktivieren?`,
    content: `EasyCloud überprüft alles fachlich und logisch für Sie.`,
    okText: 'Ja',
    cancelText: 'Nein',
    onOk() {
      return runPruefungAktivieren();
    },
  });
};

const showConfirmDelete = (vertrag: BeVertragFragment, runDelete: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie diesen Vertrag löschen?',
    content: `Vertrag ${vertrag.vertragsart.text}`,
    okText: 'Löschen',
    okButtonProps: { danger: true },
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

const showConfirmMarkUnderReview = (runMarkUnderReview: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie diesen Vertrag prüfen?',
    okText: 'Prüfen',
    cancelText: 'Abbrechen',
    onOk() {
      return runMarkUnderReview();
    },
  });
};

export default VertragCard;
