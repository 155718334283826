import React, { FC, useEffect } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useField } from 'formik';
import { VertragLevel, VertragLevelTuple, Verwaltungsart } from '../../../types';
import { useVertragLevelListQuery } from './vertragLevelQueries.types';

type Props = {
  verwaltungsart?: Verwaltungsart;
  excludeLevels?: VertragLevel[];
  onOptionsChange?: (filteredOptions: VertragLevelTuple[]) => void;
} & SelectProps;

const VertragLevelSelect: FC<Props> = ({ verwaltungsart, excludeLevels, onOptionsChange, ...props }) => {
  const { data, loading } = useVertragLevelListQuery({ variables: { verwaltungsart } });

  const [field, meta, helpers] = useField(props.name);
  const vertragLevelList = data?.getVertragLevelList.data;

  useEffect(() => {
    if (!vertragLevelList?.length) return;
    const exists = vertragLevelList?.find((vertragLevel) => vertragLevel.value === field.value);

    if (!exists && field.value) {
      helpers.setValue(null);
    }
  }, [vertragLevelList, field, meta, helpers]);

  const filterLevels = (level: VertragLevelTuple) => level.value === field.value || !excludeLevels || !excludeLevels.includes(level.value);

  const filteredOptions = vertragLevelList ? vertragLevelList.filter(filterLevels) : [];

  useEffect(() => {
    onOptionsChange?.(filteredOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredOptions]);

  return (
    <Select size="small" id={props.name} loading={loading} placeholder="Vertragsebene auswählen" {...props}>
      {filteredOptions.map((vertraglevel) => {
        return (
          <Select.Option key={vertraglevel.value} value={vertraglevel.value}>
            {vertraglevel.text}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default VertragLevelSelect;
