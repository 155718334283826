import { URI_CONTACT_DETAILS_PAGE } from '../../constants/appUriPaths';

export const URI_CONTACT_DETAILS_PAGE_TODOS = `${URI_CONTACT_DETAILS_PAGE}/todos`;
export const URI_CONTACT_DETAILS_PAGE_CALENDAR = `${URI_CONTACT_DETAILS_PAGE}/calendar`;
export const URI_CONTACT_DETAILS_PAGE_HISTORY = `${URI_CONTACT_DETAILS_PAGE}/history`;
export const URI_CONTACT_DETAILS_PAGE_ARCHIVE = `${URI_CONTACT_DETAILS_PAGE}/archive`;

export const URI_CONTACT_DETAILS_PAGE_HOME = `${URI_CONTACT_DETAILS_PAGE}/information`;

export const URI_PART_RENTER = 'renter';
export const URI_PART_SUBTENANT = 'subtenant'; // untermieter
export const URI_PART_LANDLORD = 'landlord';
export const URI_PART_OWNER = 'owner';
export const URI_PART_INVOICE_WRITER = 'invoice-writer';

export enum URI_PART_CONTEXT_TYPES {
  RENTER = 'renter',
  SUBTENANT = 'subtenant',
  LANDLORD = 'landlord',
  OWNER = 'owner',
  INVOICE_WRITER = 'invoice-writer',
}
