import React from 'react';
import { useShowArchivedQueryParamState } from '../../hooks/useShowArchivedQueryParamState';
import { useAufteilungsschluesselTemplateListQuery } from './gql/AufteilungsschluesselTemplateQueries.types';
import AufteilungschluesselTemplateActionButtonsAndDrawer from './AufteilungschluesselTemplateActionButtonsAndDrawer';
import AufteilungsschluesselTemplateTable from './Table/AufteilungsschluesselTemplateTable';

const AufteilungsschluesselTemplateListing = () => {
  const [showArchived, toggleShowArchived] = useShowArchivedQueryParamState();

  const { data, loading, refetch } = useAufteilungsschluesselTemplateListQuery({ variables: { withDetails: true, includeArchiviert: showArchived } });
  const aufteilungsschluesselList = data?.getAufteilungsschluesselTemplateList.data;

  return (
    <>
      <AufteilungschluesselTemplateActionButtonsAndDrawer refetch={refetch} showArchived={showArchived} toggleShowArchived={toggleShowArchived} />
      <AufteilungsschluesselTemplateTable aufteilungsschluesselList={aufteilungsschluesselList} loading={loading} refetch={refetch} />
    </>
  );
};

export default AufteilungsschluesselTemplateListing;
