import { Select } from 'formik-antd';
import React, { FC } from 'react';
import { useField } from 'formik';
import { selectFilterOption } from '../../../helpers/selectHelper';
import { useRechtstraegerListQuery } from '../../Rechtstraeger/gql/RechtsTraegerQueries.types';
import { RechtstraegerListEntry } from '../../../types';

type Props = {
  name: string;
  rechnungsAusstellerId?: string | null;
  onChange: (rechtstraeger?: RechtstraegerListEntry) => void;
};

const BuchungskreisSelect: FC<Props> = ({ name, rechnungsAusstellerId, onChange }) => {
  const [, { initialValue }] = useField<string>(name);
  const { data, loading } = useRechtstraegerListQuery({
    variables: {
      hasBuchungskreis: true,
    },
    onCompleted: (data) => {
      if (initialValue)
        callOnChange(filterByRechnungsAusstellerId(data.getRechtstraegerList.data.contentList, rechnungsAusstellerId), initialValue, onChange);
    },
  });
  const rechtstraegerWithBuchungskreisList = data?.getRechtstraegerList.data.contentList || [];
  const rechtstraegerWithBuchungskreisListForRechnungsAussteller = filterByRechnungsAusstellerId(
    rechtstraegerWithBuchungskreisList,
    rechnungsAusstellerId
  );

  return (
    <Select
      name={name}
      id={name}
      loading={loading}
      placeholder="Buchungskreis auswählen"
      showSearch
      filterOption={selectFilterOption}
      allowClear
      onChange={(buchungskreisId) => {
        callOnChange(rechtstraegerWithBuchungskreisList, buchungskreisId, onChange);
      }}
    >
      {rechtstraegerWithBuchungskreisListForRechnungsAussteller.map((rechtstrager) => (
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        <Select.Option key={rechtstrager.buchungskreisId!} value={rechtstrager.buchungskreisId!}>
          {rechtstrager.kurzBezeichnung}
        </Select.Option>
      ))}
    </Select>
  );
};

const filterByRechnungsAusstellerId = (rechtstraegerList: RechtstraegerListEntry[], rechnungsAusstellerId?: string | null) => {
  return rechnungsAusstellerId
    ? rechtstraegerList.filter((rechtstrager) => rechtstrager.rechtstraegerId === rechnungsAusstellerId)
    : rechtstraegerList;
};

const callOnChange = (
  rechtstraegerList: RechtstraegerListEntry[],
  buchungskreisId: string,
  onChange: (rechtstraeger?: RechtstraegerListEntry) => void
) => {
  const rechtstrager = rechtstraegerList.find((rechtstrager) => rechtstrager.buchungskreisId === buchungskreisId);
  onChange(rechtstrager);
};

export default BuchungskreisSelect;
