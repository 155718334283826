import { Select, SelectProps } from 'formik-antd';
import { FC } from 'react';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { useFilterReceivedBankTransactionStatusListQuery } from '../../gql/ReceivedBankTransactionQueries.types';

type Props = Omit<SelectProps, 'id' | 'placeholder' | 'size' | 'allowClear' | 'showSearch' | 'filterOption'>;

const ReceivedBankTransactionStatusSelect: FC<Props> = ({ name, ...restProps }) => {
  const { data, loading } = useFilterReceivedBankTransactionStatusListQuery();
  const transactionStatusList = data?.getFilterReceivedBankTransactionStatusList.data ?? [];

  return (
    <Select
      {...restProps}
      name={name}
      id={name}
      size="small"
      loading={loading}
      placeholder="Status auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {transactionStatusList.map((status) => (
        <Select.Option key={status.value} value={status.value}>
          {status.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ReceivedBankTransactionStatusSelect;
