import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { OrderBestandseinheitFragmentDoc } from '../../../Bestandseinheit/gql/BestandseinheitFragments.types';
import { OrderObjektFragmentDoc, ObjektFragmentDoc } from '../../../Objekt/gql/ObjektFragments.types';
import { VorschreibungRechtstraegerFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerFragments.types';
export type VorschreibungMailDeliveryFieldsFragment = {
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  emailAddress: string;
  emailDeliveryDefinitionBezeichnung: string;
  emailDeliveryDefinitionId: string;
  mailId?: string | null;
  original: boolean;
  sender?: string | null;
  sentTs?: string | null;
  shouldNeverSend: boolean;
  shouldSend: boolean;
  updatedByMitarbeiterId?: string | null;
  vorschreibungMailDeliveryId: string;
  emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
  fakturierungsperiode: { jahr: number; monat: number };
  objektBestandseinheitVertragList: Array<{
    vertragId: string;
    bestandseinheit: {
      bestandseinheitId: string;
      bezeichnung: string;
      address: {
        addressId: string;
        city: string;
        cityAdditionalInformation?: string | null;
        countryCodeIso2: string;
        houseEntranceApartmentNumber?: string | null;
        postofficeBoxNumber?: string | null;
        postofficeBoxZipCode?: string | null;
        street?: string | null;
        type: Types.AddressType;
        zipCode?: string | null;
      };
    };
    objekt: { kurzBezeichnung: string; objektId: string };
  }>;
  recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
  vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const VorschreibungMailDeliveryFieldsFragmentDoc = gql`
  fragment VorschreibungMailDeliveryFields on VorschreibungMailDelivery {
    createTs
    createdBy
    createdByMitarbeiterId
    emailAddress
    emailDeliveryDefinitionBezeichnung
    emailDeliveryDefinitionId
    emailDeliveryStatus {
      text
      value
      description
    }
    fakturierungsperiode {
      jahr
      monat
    }
    mailId
    objektBestandseinheitVertragList {
      bestandseinheit {
        ...OrderBestandseinheit
      }
      objekt {
        ...OrderObjekt
      }
      vertragId
    }
    original
    recipient {
      ...VorschreibungRechtstraeger
    }
    sender
    sentTs
    shouldNeverSend
    shouldSend
    updatedByMitarbeiterId
    vertragspartner {
      ...VorschreibungRechtstraeger
    }
    vorschreibungMailDeliveryId
    warningList {
      attribute
      message
      type
    }
  }
  ${OrderBestandseinheitFragmentDoc}
  ${OrderObjektFragmentDoc}
  ${VorschreibungRechtstraegerFragmentDoc}
`;
