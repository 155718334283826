import React, { FC } from 'react';
import TableWithAlignedCols from '../../../../components/Table/TableWithAlignedCols/TableWithAlignedCols';
import ustVomAufwandDetailsTableColumns from './ustVomAufwandDetailsTableColumns';
import ustVomAufwandKontoRow from './ustVomAufwandKontoRow';
import { UstVomAufwand, UstVomAufwandVertrag } from '../../../../types';

type Props = {
  ustVomAufwand: UstVomAufwand;
};

const UstVomAufwandDetailsTable: FC<Props> = ({ ustVomAufwand }) => (
  <TableWithAlignedCols<UstVomAufwandVertrag>
    dataSource={ustVomAufwand.ustVomAufwandVertragList}
    columns={ustVomAufwandDetailsTableColumns(
      ustVomAufwand.objekt.objektId,
      ustVomAufwand.objekt.rechnungsAussteller.rechtstraegerId,
      ustVomAufwand.fibuBuchungIdList
    )}
    expandedRow={() =>
      ustVomAufwandKontoRow(
        ustVomAufwand.objekt.objektId,
        ustVomAufwand.objekt.rechnungsAussteller.rechtstraegerId,
        ustVomAufwand.vonInklusive,
        ustVomAufwand.bisInklusive
      )
    }
    rowKey={(data) => data.bestandseinheit.bestandseinheitId}
  />
);

export default UstVomAufwandDetailsTable;
