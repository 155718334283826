import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import HistoryModal, { HistoryType } from '../../History/shared/HistoryModal';
import AuftragsartGeneralChangeHistoryListingTable from './AuftragsartGeneralChangeHistoryListingTable';
import AuftragsartTexteChangeHistoryListingTable from './AuftragsartTexteChangeHistoryListingTable';

type Props = {
  auftragsartId: string;
  open: boolean;
  onCancel: () => void;
};

const AuftragsartChangeHistoryModal: FC<Props> = ({ auftragsartId, open, onCancel }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Allgemeine Parameter',
      children: <AuftragsartGeneralChangeHistoryListingTable auftragsartId={auftragsartId} />,
    },
    {
      key: '2',
      label: 'PDF-Vorlage',
      children: <AuftragsartTexteChangeHistoryListingTable auftragsartId={auftragsartId} />,
    },
  ];

  return (
    <HistoryModal historyType={HistoryType.Change} open={open} onCancel={onCancel}>
      <Tabs items={items} />
    </HistoryModal>
  );
};

export default AuftragsartChangeHistoryModal;
