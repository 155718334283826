import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { ObjektAbrechnung } from '../../objektAbrechnung-types';

const OATableColumnTitles: FC<{ data: ObjektAbrechnung }> = ({ data }) => (
  <View
    style={[
      pdfStyles.row,
      pdfStyles.tablePaddingLeftRight,
      pdfStyles.textNormal,
      pdfStyles.textUppercase,
      pdfStyles.borderBottomSmall,
      {
        paddingTop: '10mm',
      },
    ]}
    fixed
  >
    <Text style={[pdfStyles.column, { width: '30%' }]}>{data.abrechnungskreiseBlock.abrechnungskreisTableBezeichnungText}</Text>
    <Text style={[pdfStyles.column, { width: '10%' }]}>{data.abrechnungskreiseBlock.abrechnungskreisTableBelegDatumText}</Text>
    <Text style={[pdfStyles.column, { width: '15%' }]}>{data.abrechnungskreiseBlock.abrechnungskreisTableBelegNummerText}</Text>
    <Text style={[pdfStyles.column, { width: '35%' }]}>{data.abrechnungskreiseBlock.abrechnungskreisTableVertragspartnerText}</Text>
    <Text style={[pdfStyles.column, { width: '10%' }]} />
  </View>
);

export default OATableColumnTitles;
