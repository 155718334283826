import { FetchResult } from '@apollo/client';
import { Ticket, WarningData } from '../../../types';
import { UpdateTicketMutationHookResult } from '../gql/TicketMutations.types';
import { mapFormValuesToTicketUpdateInput, mapTicketToFormValues } from './ticketFormMapper';
import { showSuccessMsgUpdate } from '../../../components/message';

export function handleSubTicketMutationResponseWarnings<T extends FetchResult>(
  response: T,
  parentTicket: Ticket | undefined | null,
  runUpdate: UpdateTicketMutationHookResult[0],
  onSuccess: () => void,
  showConfirmUpdateTicketParent: (dueDate: string, onOk: () => Promise<unknown>) => void
) {
  const data = response.data;
  let dueDate;
  if (!data || !parentTicket) return response;

  let warningList: WarningData[] = [];
  if ('updateTicket' in data) {
    warningList = data.updateTicket?.warningList;
    dueDate = data.updateTicket?.data?.dueDate;
  } else if ('actionTicketCreateSubTicket' in data) {
    warningList = data.actionTicketCreateSubTicket?.warningList;
    dueDate = data.actionTicketCreateSubTicket?.data?.dueDate;
  }

  const warning = warningList.find((warning) => warning.type === 'DUE_DATE_IS_AFTER_PARENTS_DUE_DATE');
  if (!warning || !dueDate) return response;

  const onOk = () =>
    runUpdate({
      variables: {
        ticketId: parentTicket.ticketId,
        input: { ...mapFormValuesToTicketUpdateInput(mapTicketToFormValues(true, parentTicket)), dueDate },
      },
      onCompleted: () => {
        showSuccessMsgUpdate('Hauptaufgabe');
        onSuccess();
      },
    });

  showConfirmUpdateTicketParent(dueDate, onOk);
  return response;
}
