import React, { FC } from 'react';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { kontoFormFields } from '../../kontoFormMapper';
import EditableOrDisabledDrawerField from '../../EditableOrDisabledDrawerField';
import KontoKlasseSelect from './KontoKlasseSelect';

type Props = {
  disabled: boolean;
};

const KontoFormItemKlasse: FC<Props> = ({ disabled }) => (
  <FormItemWithoutColon name={kontoFormFields.klasse} label="Kontoklasse">
    <EditableOrDisabledDrawerField isDisabled={disabled}>
      <KontoKlasseSelect
        id={kontoFormFields.klasse}
        name={kontoFormFields.klasse}
        placeholder="Kontoklasse auswählen"
        allowClear={false}
        disabled={disabled}
      />
    </EditableOrDisabledDrawerField>
  </FormItemWithoutColon>
);

export default KontoFormItemKlasse;
