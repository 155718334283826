import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRechtstraegerSteuerpflichtMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  request: Types.SteuerpflichtInput;
}>;

export type CreateRechtstraegerSteuerpflichtMutation = {
  createRechtstraegerSteuerpflicht: {
    data: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      validFrom: string;
      kommentar?: string | null;
      rechtstraegerSteuerpflichtId: string;
      historicizedList?: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        validFrom: string;
        kommentar?: string | null;
        rechtstraegerSteuerpflichtId: string;
        steuerpflicht: { text: string; value: Types.Steuerpflicht };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      steuerpflicht: { text: string; value: Types.Steuerpflicht };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateRechtstraegerSteuerpflichtMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  rechtstraegerSteuerpflichtId: Types.Scalars['ID']['input'];
  request: Types.SteuerpflichtInput;
}>;

export type UpdateRechtstraegerSteuerpflichtMutation = {
  updateRechtstraegerSteuerpflicht: {
    data: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      validFrom: string;
      kommentar?: string | null;
      rechtstraegerSteuerpflichtId: string;
      historicizedList?: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        validFrom: string;
        kommentar?: string | null;
        rechtstraegerSteuerpflichtId: string;
        steuerpflicht: { text: string; value: Types.Steuerpflicht };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      steuerpflicht: { text: string; value: Types.Steuerpflicht };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteRechtstraegerSteuerpflichtMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  rechtstraegerSteuerpflichtId: Types.Scalars['ID']['input'];
}>;

export type DeleteRechtstraegerSteuerpflichtMutation = {
  deleteRechtstraegerSteuerpflicht: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateRechtstraegerSteuerpflichtDocument = gql`
  mutation CreateRechtstraegerSteuerpflicht($rechtstraegerId: ID!, $request: SteuerpflichtInput!) {
    createRechtstraegerSteuerpflicht(rechtstraegerId: $rechtstraegerId, request: $request) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
        current
        deletable
        validFrom
        historicizedList {
          createTs
          createdBy
          createdByMitarbeiterId
          current
          deletable
          validFrom
          kommentar
          rechtstraegerSteuerpflichtId
          steuerpflicht {
            text
            value
          }
          warningList {
            attribute
            message
            type
          }
        }
        kommentar
        rechtstraegerSteuerpflichtId
        steuerpflicht {
          text
          value
        }
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateRechtstraegerSteuerpflichtMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRechtstraegerSteuerpflichtMutation, CreateRechtstraegerSteuerpflichtMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRechtstraegerSteuerpflichtMutation, CreateRechtstraegerSteuerpflichtMutationVariables>(
    CreateRechtstraegerSteuerpflichtDocument,
    options
  );
}
export type CreateRechtstraegerSteuerpflichtMutationHookResult = ReturnType<typeof useCreateRechtstraegerSteuerpflichtMutation>;
export type CreateRechtstraegerSteuerpflichtMutationResult = Apollo.MutationResult<CreateRechtstraegerSteuerpflichtMutation>;
export type CreateRechtstraegerSteuerpflichtMutationOptions = Apollo.BaseMutationOptions<
  CreateRechtstraegerSteuerpflichtMutation,
  CreateRechtstraegerSteuerpflichtMutationVariables
>;
export const UpdateRechtstraegerSteuerpflichtDocument = gql`
  mutation UpdateRechtstraegerSteuerpflicht($rechtstraegerId: ID!, $rechtstraegerSteuerpflichtId: ID!, $request: SteuerpflichtInput!) {
    updateRechtstraegerSteuerpflicht(
      rechtstraegerId: $rechtstraegerId
      rechtstraegerSteuerpflichtId: $rechtstraegerSteuerpflichtId
      request: $request
    ) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
        current
        deletable
        validFrom
        historicizedList {
          createTs
          createdBy
          createdByMitarbeiterId
          current
          deletable
          validFrom
          kommentar
          rechtstraegerSteuerpflichtId
          steuerpflicht {
            text
            value
          }
          warningList {
            attribute
            message
            type
          }
        }
        kommentar
        rechtstraegerSteuerpflichtId
        steuerpflicht {
          text
          value
        }
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateRechtstraegerSteuerpflichtMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRechtstraegerSteuerpflichtMutation, UpdateRechtstraegerSteuerpflichtMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRechtstraegerSteuerpflichtMutation, UpdateRechtstraegerSteuerpflichtMutationVariables>(
    UpdateRechtstraegerSteuerpflichtDocument,
    options
  );
}
export type UpdateRechtstraegerSteuerpflichtMutationHookResult = ReturnType<typeof useUpdateRechtstraegerSteuerpflichtMutation>;
export type UpdateRechtstraegerSteuerpflichtMutationResult = Apollo.MutationResult<UpdateRechtstraegerSteuerpflichtMutation>;
export type UpdateRechtstraegerSteuerpflichtMutationOptions = Apollo.BaseMutationOptions<
  UpdateRechtstraegerSteuerpflichtMutation,
  UpdateRechtstraegerSteuerpflichtMutationVariables
>;
export const DeleteRechtstraegerSteuerpflichtDocument = gql`
  mutation DeleteRechtstraegerSteuerpflicht($rechtstraegerId: ID!, $rechtstraegerSteuerpflichtId: ID!) {
    deleteRechtstraegerSteuerpflicht(rechtstraegerId: $rechtstraegerId, rechtstraegerSteuerpflichtId: $rechtstraegerSteuerpflichtId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteRechtstraegerSteuerpflichtMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRechtstraegerSteuerpflichtMutation, DeleteRechtstraegerSteuerpflichtMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRechtstraegerSteuerpflichtMutation, DeleteRechtstraegerSteuerpflichtMutationVariables>(
    DeleteRechtstraegerSteuerpflichtDocument,
    options
  );
}
export type DeleteRechtstraegerSteuerpflichtMutationHookResult = ReturnType<typeof useDeleteRechtstraegerSteuerpflichtMutation>;
export type DeleteRechtstraegerSteuerpflichtMutationResult = Apollo.MutationResult<DeleteRechtstraegerSteuerpflichtMutation>;
export type DeleteRechtstraegerSteuerpflichtMutationOptions = Apollo.BaseMutationOptions<
  DeleteRechtstraegerSteuerpflichtMutation,
  DeleteRechtstraegerSteuerpflichtMutationVariables
>;
