import { FC } from 'react';
import { EuroAmount } from '../../../../../../../../components/Number';
import { AuszifferungBlockFormValues, AuszifferungFormValues, OffenePostenForBooking } from '../../../auszifferungFormMapper';
import {
  getAuszifferungIndex,
  getAuszifferungListFormFieldNameFromMainOPList,
  getOffenePostenIndexFromMainOPList,
  isExistingAuszifferung,
} from '../../../auszifferungFormHelpers';
import FormItemWithoutColon from '../../../../../../../../components/Form/FormItemWithoutColon';
import FormattedDecimalFormInput from '../../../../../../../../components/Input-antd/FormattedDecimalFormInput';

type Props = {
  record: AuszifferungFormValues;
  formikValues: AuszifferungBlockFormValues;
  isOPForPersonenkonto: boolean;
  offenePosten: OffenePostenForBooking;
};

const AmountColumn: FC<Props> = ({ record, formikValues, isOPForPersonenkonto, offenePosten }) => {
  const auszifferungListFormFieldNameFromMainOPList = getAuszifferungListFormFieldNameFromMainOPList(formikValues, offenePosten);

  let oPList = offenePosten;
  if (isOPForPersonenkonto) {
    const oPIndexFromMaintOpList = getOffenePostenIndexFromMainOPList(formikValues, offenePosten);
    if (oPIndexFromMaintOpList >= 0) {
      oPList = formikValues.offenePostenListFromBooking[oPIndexFromMaintOpList];
    }
  }

  const azIndex = getAuszifferungIndex(record, oPList);
  const formItemName = `${auszifferungListFormFieldNameFromMainOPList}[${azIndex}].amount`;

  return isExistingAuszifferung(record) ? (
    <EuroAmount value={record.amount as number} />
  ) : (
    <FormItemWithoutColon name={formItemName} style={{ margin: 0 }}>
      <FormattedDecimalFormInput id={formItemName} name={formItemName} size="small" style={{ width: '100%' }} isCurrency />
    </FormItemWithoutColon>
  );
};

export default AmountColumn;
