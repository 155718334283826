import { Select, SelectProps } from 'formik-antd';
import { FC } from 'react';
import { selectFilterOption } from '../../../helpers/selectHelper';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { useHeVertragBaseListQuery } from '../HeVertrag/gql/HeVertragQueries.types';

type Props = {
  objektId?: string | null;
  showObjekt?: boolean;
  showVertragsdauer?: boolean;
} & Omit<SelectProps, 'id' | 'allowClear' | 'filterOption' | 'loading' | 'placeholder' | 'size' | 'showSearch'>;

const HeVertragBaseSelect: FC<Props> = ({ name, objektId, showObjekt = false, showVertragsdauer = false, ...restProps }) => {
  const { data, loading } = useHeVertragBaseListQuery({
    skip: !objektId,
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      objektId: objektId!,
      showObjekt,
      showVertragsdauer,
    },
  });

  const vertragList = data?.getHeVertragBaseList.data ?? [];

  return (
    <Select
      {...restProps}
      name={name}
      size="small"
      id={name}
      loading={loading}
      placeholder="Vertrag auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {vertragList.map((vertrag) => (
        <Select.Option key={vertrag.heVertragId} value={vertrag.heVertragId}>
          <DataWithShortenedText maxTextLength={48} text={vertrag.kurzBezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default HeVertragBaseSelect;
