import { Dayjs } from 'dayjs';
import React, { FC } from 'react';
import StatusBadge from '../../components/Status/StatusBadge';
import TicketPreview from '../Ticket/shared/TicketPreview/TicketPreview';
import { TicketListGroupedByDueDate } from './TicketCalendar';
import { StyledSpace } from './syled/Space.style';

type Props = {
  date: Dayjs;
  ticketListByDueDate: TicketListGroupedByDueDate;
  openLinkInNewTab?: boolean;
  showAssignee?: boolean;
};

const TicketCalendarDateCell: FC<Props> = ({ date, ticketListByDueDate, openLinkInNewTab, showAssignee }) => {
  const current = ticketListByDueDate[date.format('YYYY-MM-DD')];
  return current?.map((ticket) => {
    return (
      <StyledSpace key={ticket.ticketId} direction="horizontal" size={4} style={{ display: 'flex', maxWidth: 'fit-content' }}>
        <StatusBadge entityStatus={ticket.ticketStatus} showBadgeText={false} />
        <TicketPreview ticket={ticket} openLinkInNewTab={openLinkInNewTab} showAssignee={showAssignee} />
      </StyledSpace>
    );
  });
};

export default TicketCalendarDateCell;
