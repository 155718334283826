import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FibuBelegSymbolListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FibuBelegSymbolListQuery = {
  getFibuBelegSymbolList: {
    data: Array<{ abbreviation: string; text: string; value: Types.FibuBelegSymbol }>;
    errorList: Array<{ type: string; message: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const FibuBelegSymbolListDocument = gql`
  query FibuBelegSymbolList {
    getFibuBelegSymbolList {
      data {
        abbreviation
        text
        value
      }
      errorList {
        type
        message
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFibuBelegSymbolListQuery(baseOptions?: Apollo.QueryHookOptions<FibuBelegSymbolListQuery, FibuBelegSymbolListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FibuBelegSymbolListQuery, FibuBelegSymbolListQueryVariables>(FibuBelegSymbolListDocument, options);
}
export function useFibuBelegSymbolListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FibuBelegSymbolListQuery, FibuBelegSymbolListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FibuBelegSymbolListQuery, FibuBelegSymbolListQueryVariables>(FibuBelegSymbolListDocument, options);
}
export function useFibuBelegSymbolListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FibuBelegSymbolListQuery, FibuBelegSymbolListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FibuBelegSymbolListQuery, FibuBelegSymbolListQueryVariables>(FibuBelegSymbolListDocument, options);
}
export type FibuBelegSymbolListQueryHookResult = ReturnType<typeof useFibuBelegSymbolListQuery>;
export type FibuBelegSymbolListLazyQueryHookResult = ReturnType<typeof useFibuBelegSymbolListLazyQuery>;
export type FibuBelegSymbolListSuspenseQueryHookResult = ReturnType<typeof useFibuBelegSymbolListSuspenseQuery>;
export type FibuBelegSymbolListQueryResult = Apollo.QueryResult<FibuBelegSymbolListQuery, FibuBelegSymbolListQueryVariables>;
