import { Button, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Box, Flex } from 'rebass';
import React from 'react';
import { TimelineMode, useTimeline } from './timelineCtx';

function TimelineCreateItemBtnAndSearchField() {
  const { changeToCreateMode, timelineMode } = useTimeline();

  return (
    <Flex width="100%">
      <Button block type="default" icon={<PlusOutlined />} onClick={changeToCreateMode} disabled={timelineMode === TimelineMode.create}>
        Neue Gültigkeit anlegen
      </Button>
      <Box width="16px" />
      {/* eslint-disable-next-line no-console */}
      <Input.Search placeholder="Änderung suchen" onSearch={(value) => console.log(value)} allowClear />
    </Flex>
  );
}

export default TimelineCreateItemBtnAndSearchField;
