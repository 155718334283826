import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import { URI_ZINSLISTE_PAGE } from '../../features/Zinsliste/zinslisteUriPaths';
import ZinslistePage from './ZinslistePage';

const zinslistePageRoute = (
  <Route path={URI_ZINSLISTE_PAGE} element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<ZinslistePage />} />} />
);

export default zinslistePageRoute;
