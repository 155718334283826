import React, { FC } from 'react';
import { Typography } from 'antd';
import { isExitCodeFehler, isExitCodeOk, isExitCodeRetry, TGenerierlaufEntry } from '../verarbeitungHelpers';

type Props = {
  generierlaufEntry: TGenerierlaufEntry;
};

const GenerierlaufEntryStatus: FC<Props> = ({ generierlaufEntry }) => {
  if (generierlaufEntry.exitCode) {
    if (isExitCodeOk(generierlaufEntry.exitCode.value)) {
      return <Typography.Text>Verarbeitet</Typography.Text>;
    }

    if (isExitCodeFehler(generierlaufEntry.exitCode.value)) {
      return <Typography.Text>Fehler</Typography.Text>;
    }

    if (isExitCodeRetry(generierlaufEntry.exitCode.value)) {
      return <Typography.Text>Retry</Typography.Text>;
    }
  }
  return <Typography.Text>Noch nicht verarbeitet</Typography.Text>;
};

export default GenerierlaufEntryStatus;
