import { Select } from 'formik-antd';
import React, { FC } from 'react';
import { useField } from 'formik';
import { selectFilterOption } from '../../../helpers/selectHelper';
import { useObjektListQuery } from '../../Objekt/gql/ObjektQueries.types';
import { ObjektListEntry } from '../../../types';

type Props = {
  name: string;
  rechnungsAusstellerId?: string | null;
  onChange: (objekt?: ObjektListEntry) => void;
};

const ObjektSelect: FC<Props> = ({ name, rechnungsAusstellerId, onChange }) => {
  const [, { initialValue }] = useField<string>(name);
  const { data, loading } = useObjektListQuery({
    variables: {
      rechnungsAusstellerId,
    },
    onCompleted: (data) => {
      // Set initial value for i.e. rechnungsAusstellerIdForBuchungskreis when rechnungsAusstellerId is set
      if (initialValue) callOnChange(data.getObjektList.data.contentList, initialValue, onChange);
    },
  });
  const objektList = data?.getObjektList.data.contentList || [];

  return (
    <Select
      name={name}
      id={name}
      loading={loading}
      placeholder="Objekt auswählen"
      showSearch
      filterOption={selectFilterOption}
      allowClear
      onChange={(objektId) => callOnChange(objektList, objektId, onChange)}
    >
      {objektList.map((objekt) => (
        <Select.Option key={objekt.objektId} value={objekt.objektId}>
          {objekt.kurzBezeichnung}
        </Select.Option>
      ))}
    </Select>
  );
};

const callOnChange = (objektList: ObjektListEntry[], objektId: string, onChange: (objekt?: ObjektListEntry) => void) => {
  const objekt = objektList.find((objekt) => objekt.objektId === objektId);
  onChange(objekt);
};

export default ObjektSelect;
