import { useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../hooks/useQueryWithPagingAndSorting';
import { FiltersFormValues, mapFormValuesToQueryParams, mapQueryParamsToFormValues } from './Filters/filtersFormMapper';
import { TIbanQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import IbanFibuKontoTable from './Table/IbanFibuKontoTable';
import {
  IbanFibuKontoAccountHolderListDocument,
  IbanFibuKontoBanknameListDocument,
  IbanFibuKontoBicListDocument,
  IbanFibuKontoFibuAccountNumberListDocument,
  IbanFibuKontoIbanListDocument,
  IbanFibuKontoListDocument,
  useIbanFibuKontoListQuery,
} from './gql/IbanFibuKontoQueries.types';
import ListingFilters from './Filters/ListingFilters';
import { useOnIbanFibuKontoDataChangeEvents } from './useOnIbanFibuKontoDataChangeEvents';

const IbanFibuKontoListing = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TIbanQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(navigate, queryParams, paginationParams);
  };

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useIbanFibuKontoListQuery,
    {
      variables: {
        ...queryParams,
      },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: paginationParams.currentPage ? Number(paginationParams.currentPage) : DEFAULT_CURRENT,
      pageSize: paginationParams.pageSize ? Number(paginationParams.pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const ibanList = data?.getIbanFibuKontoList.data.contentList ?? [];

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: FiltersFormValues) => {
    const filters = mapFormValuesToQueryParams(values);
    updateQueryParams(navigate, filters, paginationParams);
  };

  const client = useApolloClient();
  useOnIbanFibuKontoDataChangeEvents('iban-fibu-konto', async () => {
    await client.refetchQueries({
      include: [
        IbanFibuKontoListDocument,
        IbanFibuKontoIbanListDocument,
        IbanFibuKontoBicListDocument,
        IbanFibuKontoAccountHolderListDocument,
        IbanFibuKontoBanknameListDocument,
        IbanFibuKontoFibuAccountNumberListDocument,
      ],
    });
  });

  return (
    <>
      <Formik<FiltersFormValues> initialValues={mapQueryParamsToFormValues(queryParams)} onSubmit={onChange}>
        {(formikProps) => <ListingFilters queryParams={queryParams} formikProps={formikProps} />}
      </Formik>
      <IbanFibuKontoTable ibanList={ibanList} pagination={pagination} handleTableSorting={handleTableSorting} loading={loading} />
    </>
  );
};

export default IbanFibuKontoListing;
