import React from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from 'formik-antd';
import { Space } from 'antd';

import { FieldArrayRenderProps, FormikProps } from 'formik';
import { ColumnProps } from 'antd/es/table';
import LinkToBelegFile from '../../../../../components/Link/LinkToBelegFile/LinkToBelegFile';
import { EuroAmount } from '../../../../../components/Number';
import MahnstufeColumn from './MahnstufeColumn';
import { mahnpositionFormFields, MahnpositionFormValues, mahnungUpdateFormFields, MahnungUpdateFormValues } from '../mahnungUpdateFormMapper';
import { generatePathToOffenePostenPage } from '../../../../OffenePosten/offenePostenUriPaths';
import { MahnungSumVerzugszinsen, OffenePostenKontoType } from '../../../../../types';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import AvatarBasedOnBelegSymbolWithTooltip from '../../../../../components/Avatar/BasedOnBelegSymbol/AvatarBasedOnBelegSymbolWithTooltip';
import MahnpositionActions from './MahnpositionActions';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { generatePathToBankstatementListingPage } from '../../../../DataCarrier/Listing/Bankstatement/Filters/filtersQueryParams';

const mahnpositionTableColumns = (
  mahnungId: string,
  formikProps: FormikProps<MahnungUpdateFormValues>,
  arrayHelpers: FieldArrayRenderProps,
  onMahnPositionVerzugszinsenBerechnenChanged: (isChecked: boolean, mahnPositionId: string) => void,
  isStatusErstellt: boolean,
  vertragspartnerId: string,
  fibuKontoId: string,
  sumVerzugszinsen?: MahnungSumVerzugszinsen
): ColumnProps<MahnpositionFormValues>[] => [
  {
    title: 'Beleg-Nr.',
    width: 100,
    render: (text, record) => {
      let content;

      if (record.dataCarrierBelegId) {
        content = (
          <DataWithShortenedText maxTextLength={15} text={record.belegnummer}>
            {(shortenedText) => (
              <Link
                to={generatePathToBankstatementListingPage({
                  iban: record.iban,
                  statementNumber: record.statementNumber,
                })}
                target="_blank"
              >
                {shortenedText}
              </Link>
            )}
          </DataWithShortenedText>
        );
      } else {
        content = <LinkToBelegFile fileId={record.belegFileId} belegnummer={record.belegnummer} hideIcon />;
      }

      return (
        <Space size="small">
          <AvatarBasedOnBelegSymbolWithTooltip belegSymbol={record.belegSymbol} size="small" />
          {content}
        </Space>
      );
    },
  },
  {
    title: 'Belegdatum',
    width: 80,
    render: (text, record) => <CustomFormattedDate value={record.belegDatum} />,
  },
  {
    title: 'Rechnungsbetrag',
    align: 'right',
    width: 110,
    render: (text, record) => <EuroAmount value={record.gesamtBetrag} />,
  },
  {
    title: 'Zahlung',
    align: 'right',
    width: 90,
    render: (text, record) => (
      <Link
        to={generatePathToOffenePostenPage({
          buchungskreisId: record.buchungskreisId,
          dueDateFrom: record.dueDate,
          dueDateTo: record.dueDate,
          kontoType: [OffenePostenKontoType.Debitor],
          belegnummer: record.belegnummer,
          fibuKontoId,
          vertragspartnerId,
        })}
        target="_blank"
      >
        <EuroAmount value={record.zahlungBetrag} />
      </Link>
    ),
  },
  {
    title: 'Mahnbetrag',
    align: 'right',
    width: 90,
    render: (text, record) => <EuroAmount value={record.offenerBetrag} />,
  },
  {
    title: 'Fälligkeit',
    render: (text, record) => <CustomFormattedDate value={record.dueDate} />,
  },
  {
    title: 'Letzt. Mahnstichtag',
    width: 130,
    render: (text, record) =>
      record.letzterMahnstichtag ? <CustomFormattedDate value={record.letzterMahnstichtag} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Mahnstufe',
    render: (text, record, index) => (
      <MahnstufeColumn
        mahnstufe={record.mahnstufe}
        name={`${mahnungUpdateFormFields.mahnPositionList}.${index}.${mahnpositionFormFields.mahnstufe}`}
        disabled={!isStatusErstellt}
      />
    ),
  },
  {
    title: 'Verzugszinsen',
    align: 'right',
    render: (text, record) => {
      let value = record.verzugszinsen;
      if (sumVerzugszinsen) {
        const mahnPositionIdVerzugszinsenMapKeyForCurrentRecord = Object.keys(sumVerzugszinsen.mahnPositionIdVerzugszinsenMap).find(
          (mahnPositionId) => mahnPositionId === record.mahnPositionId
        );
        if (mahnPositionIdVerzugszinsenMapKeyForCurrentRecord) {
          value = sumVerzugszinsen.mahnPositionIdVerzugszinsenMap[mahnPositionIdVerzugszinsenMapKeyForCurrentRecord];
        } else {
          value = 0;
        }
      }

      return <EuroAmount value={value} />;
    },
  },
  {
    title: 'Verzugszinsen berechnen',
    align: 'center',
    width: 170,
    render: (text, record, index) => {
      const currentMahnPositionFieldName = `${mahnungUpdateFormFields.mahnPositionList}.${index}.${mahnpositionFormFields.verzugszinsenBerechnen}`;
      const onChange = (event: any) => {
        onMahnPositionVerzugszinsenBerechnenChanged(event.target.checked, record.mahnPositionId);
      };

      return (
        <FormItemWithoutColon name={currentMahnPositionFieldName} style={{ margin: 0 }}>
          <Checkbox name={currentMahnPositionFieldName} id={currentMahnPositionFieldName} onChange={onChange} disabled={!isStatusErstellt} />
        </FormItemWithoutColon>
      );
    },
  },
  {
    title: 'Aktion',
    render: (_, record, index) => (
      <MahnpositionActions
        mahnungId={mahnungId}
        mahnpositionId={record.mahnPositionId}
        index={index}
        formikProps={formikProps}
        arrayHelpers={arrayHelpers}
        isStatusErstellt={isStatusErstellt}
      />
    ),
  },
];

export default mahnpositionTableColumns;
