import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import { CallLogIncomingPageRouteParams } from './routes';
import { pathsToRechtstraegerDetails } from '../../features/Rechtstraeger/rechtstraegerHelper';
import { useCreateIncomingCallLogMutation } from '../../features/CallLog/gql/CallLogMutations.types';

const CallLogIncomingPage = () => {
  const navigate = useNavigate();
  const { phoneNumber } = useParams() as CallLogIncomingPageRouteParams;

  const [runIncomingCallLog] = useCreateIncomingCallLogMutation({ variables: { input: { phoneNumber: decodeURIComponent(phoneNumber) } } });

  useEffect(() => {
    runIncomingCallLog().then((res) => {
      const callLogList = res.data?.createIncomingCallLog.data;
      const firstCallLog = callLogList?.[0];
      if (firstCallLog) {
        navigate(pathsToRechtstraegerDetails(firstCallLog.rechtstraeger.rechtstraegerId).callLogMenu);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Skeleton active />;
};

export default CallLogIncomingPage;
