import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import AbrDefVersionViewTemplate from '../../../Version/View/AbrDefVersionViewTemplate';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import { TemplatePreviewProps } from '../../../../../components/Template/PDFTemplates/PDFTemplatePage';
import { HeAbrechnungOutputOptions } from '../../../../../types';
import HeOrSubGeneralSettingsForm from '../shared/HeOrSub/GeneralSettingForm/HeOrSubGeneralSettingsForm';
import { mapHeOrSubAbrDefOutputOptionsToFormValues } from '../shared/HeOrSub/GeneralSettingForm/heOrSubAbrDefGeneralSettingsFormMapper';

type Props = {
  outputOptions: HeAbrechnungOutputOptions;
  heAbrDefTemplateValues: TextbausteinFormValues[];
  renderTemplatePreview: (props: TemplatePreviewProps) => React.ReactNode;
};

const HeAbrDefVersionViewTabWithTemplate: FC<Props> = ({ outputOptions, heAbrDefTemplateValues, renderTemplatePreview }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Allgemeine Einstellungen',
      children: <HeOrSubGeneralSettingsForm values={mapHeOrSubAbrDefOutputOptionsToFormValues(outputOptions)} />,
    },
    {
      key: '2',
      label: 'Texte',
      children: <AbrDefVersionViewTemplate values={heAbrDefTemplateValues} renderPreview={renderTemplatePreview} />,
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey="Allgemeine Einstellungen" items={items} />
    </>
  );
};

export default HeAbrDefVersionViewTabWithTemplate;
