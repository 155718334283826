import { PDFViewer } from '@react-pdf/renderer';
import { FC, PropsWithChildren } from 'react';

type Props = {
  dimensions?: {
    width: string;
    height: string;
  };
};

const PDFTemplatePDFViewer: FC<Props & PropsWithChildren> = ({ dimensions, children }) => (
  <PDFViewer width={dimensions?.width ? dimensions.width : '794px'} height={dimensions?.height ? dimensions.height : '800px'}>
    <>{children}</>
  </PDFViewer>
);

export default PDFTemplatePDFViewer;
