import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useVertragVerrechnungsartListQuery } from './gql/BeVertragQueries.types';

type Props = SelectProps;

const VertragVerrechnungsartSelect: FC<Props> = (props) => {
  const { loading, data: maybeVerrechnungsArtListData } = useVertragVerrechnungsartListQuery();

  const verrechnungsartList = maybeVerrechnungsArtListData?.getVertragVerrechnungsartList.data ?? [];

  return (
    <Select id={props.name} placeholder="Verrechnungsart auswählen" loading={loading} {...props}>
      {verrechnungsartList.map((verrechnungsart) => (
        <Select.Option key={verrechnungsart.value} value={verrechnungsart.value}>
          {verrechnungsart.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default VertragVerrechnungsartSelect;
