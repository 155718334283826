import { get, isEmpty } from 'lodash';
import React, { FC } from 'react';
import { Space } from 'antd';
import { FieldArray, FieldArrayRenderProps, FormikProps, useFormikContext } from 'formik';
import ContactPersonFormAddEditItem from './ContactPersonFormAddEditItem';
import ContactPersonFormList from './ContactPersonFormList';
import { validateObjectSyncWithSchemaAndReturnErrorsIfAny } from '../../../../helpers/validationHelper';
import { tempContactPersonValidationSchema } from '../../../../features/KundenSystem/Firmendaten/Firmensitz/firmensitzFieldsValidationSchema';
import AdditionalContactPersonButton from './AdditionalContactPersonButton';
import { IContactCreateOrUpdateInput, IContactPersonCreateOrUpdateInput } from './TContactPerson';

type Props = {
  fieldNamePrefix?: string;
  tempContactPerson?: IContactPersonCreateOrUpdateInput;
  tempSelectedIndex?: number;
  tempShowContactPersonFormPartItem?: boolean;
  kundenSystemId?: string;
};

const ContactPersonListFormPart: FC<Props> = ({ fieldNamePrefix = '', kundenSystemId }) => {
  const formikProps = useFormikContext();
  const { values } = formikProps;

  const tempShowContactPersonFormPartItem = get(values, tempContactPersonValuesPath(fieldNamePrefix));
  const tempSelectedIndex = get(values, tempSelectedIndexValuesPath(fieldNamePrefix));
  const tempContactPerson = get(values, tempContactPersonValuesPath(fieldNamePrefix));
  const contactPersonList = get(values, contactPersonListValuesPath(fieldNamePrefix));

  return (
    <FieldArray
      name={`${fieldNamePrefix}contactPersonList`}
      render={(arrayHelpers) => (
        <Space direction="vertical" style={{ width: '100%' }}>
          <ContactPersonFormList
            contactPersonList={contactPersonList}
            removeContactPerson={removeContactPerson(arrayHelpers)}
            getEditFocusOnContactPerson={selectContactPerson(fieldNamePrefix, formikProps)}
          />
          <AdditionalContactPersonButton
            isVisible={!tempShowContactPersonFormPartItem}
            contactPersonList={contactPersonList}
            onClick={openContactPersonFormAddEditItem(fieldNamePrefix, formikProps)}
          />
          <ContactPersonFormAddEditItem
            fieldNamePrefix={`${fieldNamePrefix}tempContactPerson.`}
            isVisible={!!tempShowContactPersonFormPartItem}
            saveContactPerson={
              tempSelectedIndex >= 0
                ? updateContactPerson(arrayHelpers, fieldNamePrefix, formikProps, tempSelectedIndex, tempContactPerson)
                : addContactPerson(arrayHelpers, contactPersonList, fieldNamePrefix, formikProps, tempContactPerson)
            }
            resetContactPerson={resetContactPerson(fieldNamePrefix, formikProps)}
            kundenSystemId={kundenSystemId}
          />
        </Space>
      )}
    />
  );
};

const removeContactPerson = (arrayHelpers: FieldArrayRenderProps) => (index: number) => () => {
  arrayHelpers.remove(index);
};

const selectContactPerson =
  (fieldNamePrefix: string, formikProps: FormikProps<unknown>) => (contactPerson: IContactPersonCreateOrUpdateInput, index: number) => () => {
    const { setFieldValue } = formikProps;
    resetContactPerson(fieldNamePrefix, formikProps)();
    initContactPerson(fieldNamePrefix, formikProps, contactPerson);

    setFieldValue(tempSelectedIndexValuesPath(fieldNamePrefix), index);
    setFieldValue(tempShowContactPersonFormPartItemValuesPath(fieldNamePrefix), true);
  };

const resetContactPerson = (fieldNamePrefix: string, formikProps: FormikProps<unknown>) => () => {
  const { setFieldTouched, setFieldError, setFieldValue } = formikProps;

  setFieldValue(tempContactPersonValuesPath(fieldNamePrefix), undefined);
  setFieldTouched(tempContactPersonValuesPath(fieldNamePrefix), false);
  setFieldError(tempContactPersonValuesPath(fieldNamePrefix), undefined);

  setFieldValue(tempSelectedIndexValuesPath(fieldNamePrefix), undefined);
  setFieldValue(tempShowContactPersonFormPartItemValuesPath(fieldNamePrefix), false);
};

const initContactPerson = (
  fieldNamePrefix: string,
  formikProps: { setFieldValue: any },
  selectedContactPerson?: IContactPersonCreateOrUpdateInput
) => {
  const { setFieldValue } = formikProps;
  const contactPerson = {
    contactPersonId: get(selectedContactPerson, 'contactPersonId', null),
    salutation: get(selectedContactPerson, 'salutation', undefined),
    firstname: get(selectedContactPerson, 'firstname', ''),
    lastname: get(selectedContactPerson, 'lastname', ''),
    title: get(selectedContactPerson, 'title', ''),
    titleTrailing: get(selectedContactPerson, 'titleTrailing', ''),
    emailList: addDefaultFirstValueIfContactListEmpty(get(selectedContactPerson, 'emailList', [])),
    phoneList: addDefaultFirstValueIfContactListEmpty(get(selectedContactPerson, 'phoneList', [])),
  };

  setFieldValue(tempContactPersonValuesPath(fieldNamePrefix), { ...contactPerson });
};

const addDefaultFirstValueIfContactListEmpty = (contactList: IContactCreateOrUpdateInput[]) =>
  isEmpty(contactList) ? [{ wert: '' }] : [...contactList];

const openContactPersonFormAddEditItem = (fieldNamePrefix: string, formikProps: FormikProps<unknown>) => () => {
  const { setFieldValue } = formikProps;
  setFieldValue(tempShowContactPersonFormPartItemValuesPath(fieldNamePrefix), true);
  initContactPerson(fieldNamePrefix, formikProps);
};

const addContactPerson =
  (
    arrayHelpers: FieldArrayRenderProps,
    contactPersonList: string | IContactCreateOrUpdateInput[],
    fieldNamePrefix: string,
    formikProps: FormikProps<unknown>,
    tempContactPerson: IContactPersonCreateOrUpdateInput
  ) =>
  () => {
    tempContactPerson = removeEmptyEmailListAndPhoneList(tempContactPerson);
    setFieldTouchedForContactPersonFormSelected(`${tempContactPersonValuesPath(fieldNamePrefix)}.`, formikProps);

    if (isContactPersonValid(tempContactPerson)) {
      resetListFieldError(fieldNamePrefix, formikProps);
      arrayHelpers.insert(contactPersonList.length, {
        ...tempContactPerson,
      });
      resetContactPerson(fieldNamePrefix, formikProps)();
    }
  };

// workaround due to https://github.com/jaredpalmer/formik/issues/1158
const resetListFieldError = (fieldNamePrefix: string, formikProps: FormikProps<unknown>) => {
  formikProps.setFieldError(
    contactPersonListValuesPath(fieldNamePrefix),
    // @ts-ignore FIXME
    []
  );
};

const removeEmptyEmailListAndPhoneList = (tempContactPerson: IContactPersonCreateOrUpdateInput) => {
  let contactPerson = { ...tempContactPerson };
  if (!isEmpty(contactPerson.emailList)) {
    const newEmailList = contactPerson.emailList.filter((email: { wert: string }) => email.wert !== '');
    contactPerson = { ...contactPerson, emailList: newEmailList };
  }

  if (!isEmpty(contactPerson.phoneList)) {
    const newPhoneList = contactPerson.phoneList.filter((phone: { wert: string }) => phone.wert !== '');
    contactPerson = { ...contactPerson, phoneList: newPhoneList };
  }
  return contactPerson;
};

const setFieldTouchedForContactPersonFormSelected = (fieldNamePrefix: string, formikProps: { setFieldTouched: any }) => {
  const { setFieldTouched } = formikProps;
  setFieldTouched(`${fieldNamePrefix}salutation`);
  setFieldTouched(`${fieldNamePrefix}title`);
  setFieldTouched(`${fieldNamePrefix}firstname`);
  setFieldTouched(`${fieldNamePrefix}lastname`);
  setFieldTouched(`${fieldNamePrefix}titleTrailing`);
};

const isContactPersonValid = (contactPerson: IContactPersonCreateOrUpdateInput) =>
  !validateObjectSyncWithSchemaAndReturnErrorsIfAny({
    // @ts-ignore FIXME
    validationSchema: tempContactPersonValidationSchema,
    valueToValidate: contactPerson,
  });

const updateContactPerson =
  (
    arrayHelpers: string | FieldArrayRenderProps,
    fieldNamePrefix: string,
    formikProps: FormikProps<unknown>,
    selectedIndex: any,
    tempContactPerson: any
  ) =>
  () => {
    tempContactPerson = removeEmptyEmailListAndPhoneList(tempContactPerson);

    if (isContactPersonValid(tempContactPerson)) {
      arrayHelpers.replace(selectedIndex, { ...tempContactPerson });
      resetContactPerson(fieldNamePrefix, formikProps)();
    }
  };

const tempContactPersonValuesPath = (fieldNamePrefix: string) => `${fieldNamePrefix}tempContactPerson`;
const tempSelectedIndexValuesPath = (fieldNamePrefix: string) => `${fieldNamePrefix}tempSelectedIndex`;
const tempShowContactPersonFormPartItemValuesPath = (fieldNamePrefix: string) => `${fieldNamePrefix}tempShowContactPersonFormPartItem`;
const contactPersonListValuesPath = (fieldNamePrefix: string) => `${fieldNamePrefix}contactPersonList`;

export default ContactPersonListFormPart;
