import React from 'react';
import { Route } from 'react-router';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../../security/permissionChecks';
import AuthRoute from '../../../../../security/AuthRoute';
import SysSettingsAbrechnungsdefinitionCreatePage from './SysSettingsAbrechnungsdefinitionCreatePage';
import { URI_SYS_SETTINGS_ABRECHNUNGSDEFINITION } from '../../../../../features/Abrechnungsdefinition/abrDefUriPaths';

const sysSettingsAbrechnungsdefinitionCreatePageRoute = (
  <Route
    path={URI_SYS_SETTINGS_ABRECHNUNGSDEFINITION.createPage}
    element={
      <AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsAbrechnungsdefinitionCreatePage />} />
    }
  />
);

export default sysSettingsAbrechnungsdefinitionCreatePageRoute;
