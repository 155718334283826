import { FC } from 'react';
import { FormikProps } from 'formik';
import FiltersWith4OrMoreFields from '../../../../../components/Filters/FiltersWith4OrMoreFields';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { listingFiltersFormFields, FiltersFormValues } from './ListingFiltersFormMapper';
import GenerierlaufRechnungsAusstellerFormSelect from '../../../shared/Filters/Order/GenerierlaufRechnungsAusstellerFormSelect';
import { OrderGenerierlaufType } from '../../../../../types';
import GenerierlaufObjektFormSelect from '../../../shared/Filters/Order/GenerierlaufObjektFormSelect';
import GenerierlaufEntryExitCodeListFormSelect from '../../../shared/Filters/Order/GenerierlaufEntryExitCodeListFormSelect';
import GenerierlaufPaymentTypeFormSelect from '../../../shared/Filters/Order/GenerierlaufPaymentTypeFormSelect';
import GenerierlaufPaymentTransactionTypeFormSelect from '../../../shared/Filters/Order/GenerierlaufPaymentTransactionTypeFormSelect';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
  generierlaufId: string;
  generierlaufType: OrderGenerierlaufType;
};

const ListingFilters: FC<Props> = ({ formikProps, generierlaufId, generierlaufType }) => {
  return (
    <FiltersWith4OrMoreFields
      hideTitle
      leftColumn={
        <>
          <FormItemWithoutColon name={listingFiltersFormFields.objektIdList} label="Objekt">
            <GenerierlaufObjektFormSelect
              name={listingFiltersFormFields.objektIdList}
              generierlaufType={generierlaufType}
              generierlaufId={generierlaufId}
              onChange={formikProps.submitForm}
              rechnungsAusstellerId={formikProps.values.rechnungsAusstellerId}
              mode="multiple"
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={listingFiltersFormFields.paymentTypeList} label="Zahlungstyp">
            <GenerierlaufPaymentTypeFormSelect
              name={listingFiltersFormFields.paymentTypeList}
              generierlaufType={generierlaufType}
              generierlaufId={generierlaufId}
              onChange={formikProps.submitForm}
              mode="multiple"
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={listingFiltersFormFields.paymentTransactionType} label="Zahlungsverkehrsart">
            <GenerierlaufPaymentTransactionTypeFormSelect
              name={listingFiltersFormFields.paymentTransactionType}
              generierlaufType={generierlaufType}
              generierlaufId={generierlaufId}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
      rightColumn={
        <>
          <FormItemWithoutColon name={listingFiltersFormFields.rechnungsAusstellerId} label="Rechnungsaussteller">
            <GenerierlaufRechnungsAusstellerFormSelect
              name={listingFiltersFormFields.rechnungsAusstellerId}
              generierlaufType={generierlaufType}
              generierlaufId={generierlaufId}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={listingFiltersFormFields.exitCodeList} label="Exit Code Status">
            <GenerierlaufEntryExitCodeListFormSelect
              name={listingFiltersFormFields.exitCodeList}
              generierlaufType={generierlaufType}
              generierlaufId={generierlaufId}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
    />
  );
};
export default ListingFilters;
