import React, { FC } from 'react';
import { Dropdown, MenuProps, Modal } from 'antd';
import { DeleteOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import { EbicsBankConfig } from '../../../../../types';
import { useDeleteEbicsBankConfigMutation } from '../../gql/EbicsBankConfigMutations.types';
import { showSuccessMsgDelete } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { useToggle } from '../../../../../hooks/useToggle';
import EbicsBankConfigDrawer from '../EbicsBankConfigDrawer';

type Props = {
  ebicsBankConfig: EbicsBankConfig;
  onAction: () => void;
};

const EbicsBankConfigTableActions: FC<Props> = ({ ebicsBankConfig, onAction }) => {
  const [isDrawerOpen, toggleDrawer] = useToggle();
  const entity = 'EBICS Bank';

  const [runDelete] = useDeleteEbicsBankConfigMutation({
    variables: {
      ebicsBankConfigId: ebicsBankConfig.ebicsBankConfigId,
    },
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    { key: '1', label: 'Bearbeiten', onClick: toggleDrawer, icon: <EditOutlined /> },
    {
      key: '2',
      label: 'Löschen',
      onClick: showConfirmDelete(runDelete, ebicsBankConfig),
      icon: <DeleteOutlined />,
      disabled: !ebicsBankConfig.deletable,
      danger: true,
    },
  ];

  const onUpdateSuccess = () => {
    onAction();
    toggleDrawer();
  };

  return (
    <>
      <Dropdown menu={{ items }}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Aktion <DownOutlined />
        </a>
      </Dropdown>
      <EbicsBankConfigDrawer
        drawerTitle="EBICS Bank bearbeiten"
        isDrawerOpen={isDrawerOpen}
        onDrawerClose={toggleDrawer}
        ebicsBankConfig={ebicsBankConfig}
        onFormAction={onUpdateSuccess}
        onFormCancel={toggleDrawer}
      />
    </>
  );
};

const showConfirmDelete = (runDelete: () => void, ebicsBankConfig: EbicsBankConfig) => () => {
  Modal.confirm({
    title: `Möchten Sie die EBICS Bank löschen?`,
    content: `${ebicsBankConfig.name}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default EbicsBankConfigTableActions;
