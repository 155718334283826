import { FC } from 'react';
import { TOperatingSiteList } from '../../../../../../../shared/components/OperatingSite/List/OperatingSiteList';
import { showSuccessMsgUpdate } from '../../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../../helpers/apolloHelper';
import ContactPersonListItem from '../ContactPersonListItem';
import { ContactPerson, OperatingSiteActionInput } from '../../../../../../../types';
import {
  useSetContactPersonTagListForFirmendatenContactPersonMutation,
  useSetOperatingSiteForFirmendatenContactPersonMutation,
} from '../../../../gql/AddressesAndContactsMutations.types';

type Props = {
  contactPerson: ContactPerson;
  onSuccess: () => void;
  operatingSiteId: string;
  firmendatenId: string;
  operatingSiteList?: TOperatingSiteList[];
};

const ContactPersonFirmendatenListItem: FC<Props> = ({ contactPerson, operatingSiteList, onSuccess, firmendatenId, operatingSiteId }) => {
  const [runSetContactPersonBs] = useSetOperatingSiteForFirmendatenContactPersonMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`Ansprechpartner`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runSetContactPersonTag, { loading }] = useSetContactPersonTagListForFirmendatenContactPersonMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`Tag-Liste`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const setOperatingSite = (operatingSite: TOperatingSiteList) => {
    runSetContactPersonBs({
      variables: {
        firmendatenId,
        contactPersonId: contactPerson.contactPersonId,
        input: { operatingSiteId: operatingSite.operatingSiteId },
      },
    });
  };

  const setTagList = (input: OperatingSiteActionInput) => {
    runSetContactPersonTag({
      variables: { firmendatenId, contactPersonId: contactPerson.contactPersonId, input },
    });
  };

  return (
    <ContactPersonListItem
      contactPerson={contactPerson}
      onSuccess={onSuccess}
      operatingSiteId={operatingSiteId}
      operatingSiteList={operatingSiteList}
      setOperatingSite={setOperatingSite}
      setTagList={setTagList}
      loadingTagList={loading}
      firmendatenId={firmendatenId}
    />
  );
};

export default ContactPersonFirmendatenListItem;
