import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WizardTypesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type WizardTypesQuery = {
  getWizardTypes: { data: Array<string>; errorList: Array<{ message: string; type: string }>; warningList: Array<{ message: string; type: string }> };
};

export const WizardTypesDocument = gql`
  query WizardTypes {
    getWizardTypes {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useWizardTypesQuery(baseOptions?: Apollo.QueryHookOptions<WizardTypesQuery, WizardTypesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WizardTypesQuery, WizardTypesQueryVariables>(WizardTypesDocument, options);
}
export function useWizardTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WizardTypesQuery, WizardTypesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WizardTypesQuery, WizardTypesQueryVariables>(WizardTypesDocument, options);
}
export function useWizardTypesSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WizardTypesQuery, WizardTypesQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<WizardTypesQuery, WizardTypesQueryVariables>(WizardTypesDocument, options);
}
export type WizardTypesQueryHookResult = ReturnType<typeof useWizardTypesQuery>;
export type WizardTypesLazyQueryHookResult = ReturnType<typeof useWizardTypesLazyQuery>;
export type WizardTypesSuspenseQueryHookResult = ReturnType<typeof useWizardTypesSuspenseQuery>;
export type WizardTypesQueryResult = Apollo.QueryResult<WizardTypesQuery, WizardTypesQueryVariables>;
