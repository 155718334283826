import React, { FC } from 'react';
import UstVomAufwandKontoTable from './UstVomAufwandKontoTable';
import { ObjAbrKonto } from '../../../../../../types';

type Props = {
  kontoList: ObjAbrKonto[];
  fromInclusive: string;
  toInclusive: string;
  objektId: string;
  buchungskreisId: string;
};

const UstVomAufwandRows: FC<Props> = ({ kontoList, fromInclusive, toInclusive, objektId, buchungskreisId }) => (
  <>
    {kontoList
      .filter((konto) => konto.summe)
      .map((konto, index) => (
        <UstVomAufwandKontoTable
          key={index}
          konto={konto}
          fromInclusive={fromInclusive}
          toInclusive={toInclusive}
          objektId={objektId}
          buchungskreisId={buchungskreisId}
        />
      ))}
  </>
);

export default UstVomAufwandRows;
