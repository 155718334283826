import React, { FC } from 'react';
import { FormikProps } from 'formik';
import NotificationListingFilters from './Filters/NotificationListingFilters';
import NotificationTable from './Table/NotificationTable';
import { NotificationListingFiltersFormValues } from './Filters/notificationListingFiltersFormMapper';

type NotificationListingFormProps = {
  formikProps: FormikProps<NotificationListingFiltersFormValues>;
};

const NotificationListingForm: FC<NotificationListingFormProps> = ({ formikProps }) => (
  <>
    <NotificationListingFilters />
    <NotificationTable formikProps={formikProps} />
  </>
);

export default NotificationListingForm;
