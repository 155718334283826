import React, { FC } from 'react';
import { Space, Tooltip } from 'antd';
import { IncomingInvoicePartialAmountListEntry } from '../../../../../types';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';

type Props = {
  record: IncomingInvoicePartialAmountListEntry;
};

const BuchungsdatumColumn: FC<Props> = ({ record }) => {
  if (record.abgrenzungsBuchung) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const buchungsdatum = record.buchungsdatum!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const abgrenzungsBuchungsdatum = record.abgrenzungsBuchungsdatum!;

    return (
      <Tooltip
        title={
          <Space direction="vertical" size={0}>
            <div>
              Buchung mit Aufwand Abgrenzung - <CustomFormattedDate value={buchungsdatum} />
            </div>
            <div>
              Aufwand Buchung der Aufwand Abgrenzung - <CustomFormattedDate value={abgrenzungsBuchungsdatum} />
            </div>
          </Space>
        }
        styles={{ root: { maxWidth: '400px' } }}
      >
        <CustomFormattedDate value={buchungsdatum} />
        ...
      </Tooltip>
    );
  }

  return record.buchungsdatum ? <CustomFormattedDate value={record.buchungsdatum} /> : <TextForEmptyValue textToShow="minus" />;
};

export default BuchungsdatumColumn;
