import { FC } from 'react';
import HistoryListingTable from '../../../History/HistoryListingTable';
import { HistoryType } from '../../../History/shared/HistoryModal';
import { useKontoTemplateChangeHistoryListQuery } from '../../gql/KontenrahmenQueries.types';

type Props = {
  kontoId: string;
};

const KontoTemplateChangeHistoryListingTable: FC<Props> = ({ kontoId }) => {
  const { data, loading } = useKontoTemplateChangeHistoryListQuery({ variables: { kontoId } });
  const historyList = data?.getKontoTemplateChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="konto-template" />;
};

export default KontoTemplateChangeHistoryListingTable;
