import React, { FC } from 'react';
import { MenuProps, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { generateURIforDeviation } from '../../deviationHelpers';
import { TDeviationReference } from '../../deviation.model';
import { DeviationStyledDropdownButton } from '../../Styled/Deviation.style';

type Props = {
  linkList: TDeviationReference[];
};

const DeviationLinkList: FC<Props> = ({ linkList }) => {
  const items: MenuProps['items'] = linkList.map((linkListItem, index) => ({
    key: index,
    label: linkListItem.uriParams ? (
      <Link to={generateURIforDeviation(linkListItem.uriParams)}>{linkListItem.text}</Link>
    ) : (
      <Typography.Text>{linkListItem.text}</Typography.Text>
    ),
  }));

  return (
    <DeviationStyledDropdownButton size="small" menu={{ items }}>
      Link
    </DeviationStyledDropdownButton>
  );
};

export default DeviationLinkList;
