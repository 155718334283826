import { FC } from 'react';
import { Tooltip } from 'antd';
import { MailOutlined } from '@ant-design/icons';

const ContactEmail: FC<{ email: string }> = ({ email }) => (
  <Tooltip placement="top" title={email}>
    <a href={`mailto:${email}`}>
      <MailOutlined />
    </a>
  </Tooltip>
);

export default ContactEmail;
