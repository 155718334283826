import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedNumber } from 'react-intl';
import { TableColumnProps } from 'antd';
import { Budgeting, BudgetingResultBestandseinheit } from '../../../../../types';
import { Percent } from '../../../../../components/Number';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { generatePathToBestandseinheitDetailsPage } from '../../../../Bestandseinheit/bestandseinheitUriPaths';

const budgetingResultBestandseinheitTableColumns = (budgeting: Budgeting): TableColumnProps<BudgetingResultBestandseinheit>[] => [
  {
    title: 'Top',
    width: 200,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={30} text={record.bestandseinheit.bezeichnung}>
        {(shortenedText) => (
          <Link to={generatePathToBestandseinheitDetailsPage(budgeting.objekt.objektId, record.bestandseinheit.bestandseinheitId)} target="_blank">
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Nutzung',
    align: 'right',
    render: (text, record) => <DataWithShortenedText maxTextLength={20} text={record.nutzungsArt.text} />,
  },
  {
    title: 'Nutzfläche',
    align: 'right',
    render: (text, record) => <FormattedNumber value={record.nutzflaeche} />,
  },
  {
    title: 'Anteil NF',
    align: 'right',
    render: (text, record) => <Percent value={record.nutzflaecheAnteil / 100} />,
  },
  {
    title: 'Nutzwert',
    align: 'right',
    render: (text, record) => <FormattedNumber value={record.nutzwert} />,
  },
  {
    title: 'Anteil NW',
    align: 'right',
    render: (text, record) => <Percent value={record.nutzwertAnteil / 100} />,
  },
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: '',
  },
];

export default budgetingResultBestandseinheitTableColumns;
