import { generatePath } from 'react-router-dom';
import { Notification, NotificationTitleType, PubSubNotification } from '../../types';
import { findParameterValue, NotificationParameterKeyTypes } from './Shared/parameterHelper';
import { generatePathToBookingDetailsPage } from '../BookingDetails/bookingDetailsPaths';

export const URI_NOTIFICATION_PAGE = '/dashboard/notifications';

export const generatePathToNotificationPage = () => generatePath(URI_NOTIFICATION_PAGE);

export const generatePathFromNotificationToBuchungsmaskeOrGenerierlauf = (notification: Notification | PubSubNotification) => {
  if (isNotificationInfoTitleTypeBuchungsanweisung(notification.notificationInfo.titleType)) {
    const buchungId = findParameterValue(notification.notificationInfo.parameterList, NotificationParameterKeyTypes.buchungId);

    return buchungId ? generatePathToBookingDetailsPage([buchungId]) : '';
  }
  // GENERIERLAUF
  return '';
};

const isNotificationInfoTitleTypeBuchungsanweisung = (titleType: NotificationTitleType) => titleType === NotificationTitleType.Buchungsanweisung;
