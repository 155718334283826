import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useVorschreibungspositionArtListQuery } from './gql/VorschreibungspositionArtQueries.types';

type Props = SelectProps;

const VorschreibungspositionArtSelect: FC<Props> = ({ ...props }) => {
  const { loading, data } = useVorschreibungspositionArtListQuery();

  const vorschreibungsPositionArtList = data?.getVorschreibungspositionArtList.data ?? [];

  return (
    <Select id={props.name} loading={loading} placeholder="Vorschreibungspositionart auswählen" {...props}>
      {vorschreibungsPositionArtList?.map((art) => (
        <Select.Option key={art.value} value={art.value}>
          {art.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default VorschreibungspositionArtSelect;
