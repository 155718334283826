import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useUmsatzsteuerkennzeichenTemplateListQuery } from './gql/UmsatzsteuerkennzeichenTemplateQueries.types';

type Props = SelectProps;

const UmsatzsteuerkennzeichenTemplateSelect: FC<Props> = (props) => {
  const { loading, data } = useUmsatzsteuerkennzeichenTemplateListQuery();

  const umsatzsteuerkennzeichenList = data?.getUmsatzsteuerkennzeichenTemplateList.data ?? [];

  return (
    <Select
      size="small"
      id={props.name}
      loading={loading}
      placeholder="Umsatzsteuerkennzeichen auswählen"
      allowClear
      showSearch
      optionFilterProp="children"
      {...props}
    >
      {umsatzsteuerkennzeichenList.map((umsatzsteuerkennzeichen) => (
        <Select.Option
          key={umsatzsteuerkennzeichen.umsatzsteuerkennzeichenId}
          value={umsatzsteuerkennzeichen.umsatzsteuerkennzeichenId}
        >{`${umsatzsteuerkennzeichen.kuerzel} - ${umsatzsteuerkennzeichen.bezeichnung}`}</Select.Option>
      ))}
    </Select>
  );
};

export default UmsatzsteuerkennzeichenTemplateSelect;
