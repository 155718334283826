import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { DataCarrierPainCreationGenerierlaufEntry, DataCarrierPainCreationGenerierlaufOrderBy } from '../../../../types';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import XmlDataCarrierTableColumn from '../../../DataCarrier/Listing/shared/XmlDataCarrierTableColumn';
import DataCarrierIbanListColumn from '../../../DataCarrier/Listing/shared/DataCarrierIbanListColumn';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { generatePathToDirectDebitOrBankWireListingPage } from '../../../DataCarrier/Listing/DirectDebitOrBankWire/Filters/filtersQueryParams';

const dataCarrierPainCreationEntryTableColumns = (
  firmendatenId: string,
  onAction: () => void
): TableWithColSelectorColumnProps<DataCarrierPainCreationGenerierlaufEntry>[] => {
  return [
    {
      title: 'Datenträgertyp',
      defaultSelected: true,
      dataIndex: DataCarrierPainCreationGenerierlaufOrderBy.DataCarrierType,
      sorter: true,
      render: (text, record) => <DataWithShortenedText text={record.dataCarrier.dataCarrierType.text} maxTextLength={20} />,
    },
    {
      title: 'Bezeichnung',
      defaultSelected: true,
      dataIndex: DataCarrierPainCreationGenerierlaufOrderBy.Name,
      sorter: true,
      render: (text, record) => <DataWithShortenedText text={record.dataCarrier.name} maxTextLength={20} />,
    },
    {
      title: 'Zahlungsvorschlag',
      defaultSelected: true,
      dataIndex: DataCarrierPainCreationGenerierlaufOrderBy.PaymentProposalName,
      sorter: true,
      render: (text, record) => <DataWithShortenedText text={record.dataCarrier.paymentProposalName} maxTextLength={20} />,
    },
    {
      title: 'Zahlungsverkehrsart: EBICS / Onlinebanking',
      defaultSelected: true,
      dataIndex: DataCarrierPainCreationGenerierlaufOrderBy.PaymentTransactionType,
      sorter: true,
      render: (text, record) => <Typography.Text>{record.dataCarrier.paymentTransactionType.text}</Typography.Text>,
    },
    {
      title: 'Bankverbindungen',
      defaultSelected: true,
      render: (text, record) => <DataCarrierIbanListColumn ibanList={record.dataCarrier.ibanList} maxTextLength={20} />,
    },
    {
      title: 'XML Datenträger',
      defaultSelected: true,
      render: (text, record) => (
        <XmlDataCarrierTableColumn
          dataCarrierId={record.dataCarrier.dataCarrierId}
          xmlFilename={record.dataCarrier.xmlFilename}
          firmendatenId={firmendatenId}
        />
      ),
    },
    {
      title: 'Anzahl Transaktionen',
      defaultSelected: true,
      dataIndex: DataCarrierPainCreationGenerierlaufOrderBy.CountTransactionInstructions,
      sorter: true,
      render: (text, record) => <Typography.Text>{record.dataCarrier.countTransactionInstructions}</Typography.Text>,
    },
    {
      title: 'Summe Transaktionen',
      defaultSelected: true,
      dataIndex: DataCarrierPainCreationGenerierlaufOrderBy.SumTransactionInstruction,
      sorter: true,
      render: (text, record) => <FormattedNumber value={record.dataCarrier.sumTransactionInstruction} />,
    },
    {
      title: 'Erstellt am',
      defaultSelected: false,
      render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
    },
    {
      title: 'Ersteller',
      defaultSelected: false,
      render: (text, record) => <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} alignment="left" showsInitials />,
    },
    {
      title: 'Aktion',
      defaultSelected: true,
      render: (text, record) => (
        <Link
          target="_blank"
          to={generatePathToDirectDebitOrBankWireListingPage({
            dataCarrierType: record.dataCarrier.dataCarrierType.value,
            name: record.dataCarrier.name,
            paymentProposalName: record.dataCarrier.paymentProposalName,
            paymentTransactionType: record.dataCarrier.paymentTransactionType.value,
          })}
        >
          Details
        </Link>
      ),
    },
  ];
};

export default dataCarrierPainCreationEntryTableColumns;
