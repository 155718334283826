import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UmsatzsteuerkennzeichenListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UmsatzsteuerkennzeichenListQuery = {
  getUmsatzsteuerkennzeichenList: {
    data: Array<{
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      kuerzel: string;
      umsatzsteuerkennzeichenId: string;
      status: { description?: string | null; text: string; value: Types.UmsatzsteuerkennzeichenStatus };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const UmsatzsteuerkennzeichenListDocument = gql`
  query UmsatzsteuerkennzeichenList {
    getUmsatzsteuerkennzeichenList {
      data {
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        kuerzel
        status {
          description
          text
          value
        }
        umsatzsteuerkennzeichenId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUmsatzsteuerkennzeichenListQuery(
  baseOptions?: Apollo.QueryHookOptions<UmsatzsteuerkennzeichenListQuery, UmsatzsteuerkennzeichenListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UmsatzsteuerkennzeichenListQuery, UmsatzsteuerkennzeichenListQueryVariables>(UmsatzsteuerkennzeichenListDocument, options);
}
export function useUmsatzsteuerkennzeichenListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UmsatzsteuerkennzeichenListQuery, UmsatzsteuerkennzeichenListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UmsatzsteuerkennzeichenListQuery, UmsatzsteuerkennzeichenListQueryVariables>(
    UmsatzsteuerkennzeichenListDocument,
    options
  );
}
export function useUmsatzsteuerkennzeichenListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UmsatzsteuerkennzeichenListQuery, UmsatzsteuerkennzeichenListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UmsatzsteuerkennzeichenListQuery, UmsatzsteuerkennzeichenListQueryVariables>(
    UmsatzsteuerkennzeichenListDocument,
    options
  );
}
export type UmsatzsteuerkennzeichenListQueryHookResult = ReturnType<typeof useUmsatzsteuerkennzeichenListQuery>;
export type UmsatzsteuerkennzeichenListLazyQueryHookResult = ReturnType<typeof useUmsatzsteuerkennzeichenListLazyQuery>;
export type UmsatzsteuerkennzeichenListSuspenseQueryHookResult = ReturnType<typeof useUmsatzsteuerkennzeichenListSuspenseQuery>;
export type UmsatzsteuerkennzeichenListQueryResult = Apollo.QueryResult<UmsatzsteuerkennzeichenListQuery, UmsatzsteuerkennzeichenListQueryVariables>;
