import { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { paymentListingLabelList } from './paymentListingFiltersFormMapper';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import { useFilterPaymentApprovedByMitarbeiterListQuery } from './gql/PaymentFilterQueries.types';

const ApprovedByMitarbeiterSelectFilter: FC<Omit<SelectProps, 'id'>> = ({ ...restProps }) => {
  const { data, loading } = useFilterPaymentApprovedByMitarbeiterListQuery();
  const selectOptionList = data?.getFilterPaymentApprovedByMitarbeiterList.data ?? [];

  return (
    <FormItemWithoutColon name={restProps.name} label={paymentListingLabelList.approvedByMitarbeiterIdList}>
      <Select {...restProps} mode="multiple" id={restProps.name} name={restProps.name} loading={loading} placeholder="Freigabe auswählen" allowClear>
        {selectOptionList.map((option) => (
          <Select.Option key={option.mitarbeiterId} value={option.mitarbeiterId}>
            {option.firstname} {option.lastname}
          </Select.Option>
        ))}
      </Select>
    </FormItemWithoutColon>
  );
};

export default ApprovedByMitarbeiterSelectFilter;
