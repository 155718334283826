import { message } from 'antd';
import { RcFile } from 'antd/lib/upload/interface';
import Fetcher from '../../../../helpers/fetcher';
import { processAndReturnError, processResponse } from '../../../../helpers/APIUtils';
import { mapErrorToErrorMessage } from '../../../../helpers/errorAndWarningHelper';

export const handleSaveFile = (
  mitarbeiterId: string,
  file: RcFile,
  onSuccess: (fileId: string) => void,
  setSubmitting: (isSubmitting: boolean) => void
) => {
  const formData = new FormData();
  const jsonData = { data: { type: 'UNKNOWN' } };
  formData.append('fileInfo', new Blob([JSON.stringify(jsonData)], { type: 'application/json' }), file.name);

  formData.append('payload', file as RcFile, file.name);

  Fetcher.getInstance()
    .fetch(`/api/mitarbeiter/${mitarbeiterId}/files`, {
      method: 'POST',
      body: formData,
    })
    .then(processResponse)
    .then((response: any) => {
      if (response.data.data) {
        onSuccess(response.data.data.fileId);
      } else if (response && response.data.warningList.length > 0) {
        message.warning(`${response.data.warningList[0].message}`);
      }
    })
    .catch(processAndReturnError)
    .catch((error) => {
      return message.error({
        content: `${file.name} konnte nicht hochgeladen werden: ${mapErrorToErrorMessage(error)}`,
      });
    })
    .finally(() => {
      setSubmitting(false);
    });
};
