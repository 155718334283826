import React, { FC } from 'react';
import { DocumentRowProps, findLabelValueByLabelId } from './util';
import { generatePathToRechtstraegerDocuments } from '../../../Rechtstraeger/rechtstraegerHelper';
import { ValueTitle } from '../../../../components/Helpers/ValueTitle';
import { EuroAmount } from '../../../../components/Number';
import SingleInvoiceActions from '../../actions/SingleInvoiceActions';
import { DocumentInfo, DocumentRowContextProvider } from './documentRowContext';
import AvatarBasedOnFileInfoTypeWithTooltip from '../../../../components/Avatar/BasedOnFileInfoType/AvatarBasedOnFileInfoTypeWithTooltip';
import RowSingleInvoiceStatus from './RowSingleInvoiceStatus';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import { useIncomingInvoiceQuery } from '../../../IncomingInvoice/gql/IncomingInvoiceQueries.types';
import { isInvoiceTypeSingleInvoice } from '../../../IncomingInvoice/Form/incomingInvoiceFormHelpers';
import RowStandingInvoiceStatus from './RowStandingInvoiceStatus';
import { SingleInvoice, StandingInvoice } from '../../../../types';
import StandingInvoiceActions from '../../actions/StandingInvoiceActions';
import RechtstraegerWithContactsTooltip from '../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

const RowIncomingInvoice: FC<DocumentRowProps> = ({ record, rechnungTyp, invoiceRecipientId, invoiceIssuerId, ...restProps }) => {
  const belegId = findLabelValueByLabelId(record.labelList, 'belegId');

  const {
    loading,
    data: maybeData,
    refetch,
  } = useIncomingInvoiceQuery({
    variables: { belegId },
  });

  const eingangsRechnung = maybeData?.getIncomingInvoice.data;

  const documentInfo: DocumentInfo | undefined = eingangsRechnung
    ? {
        column1: <AvatarBasedOnFileInfoTypeWithTooltip fileType={{ value: eingangsRechnung.type, text: record.type.text }} />,
        column2: (
          <ValueTitle
            small
            title="Rechnungsaussteller"
            content={
              <RechtstraegerWithContactsTooltip
                rechtstraegerId={eingangsRechnung.ausstellerRechtstraegerId}
                rechtstraegerName={eingangsRechnung.ausstellerBezeichnung}
                linkTo={generatePathToRechtstraegerDocuments(eingangsRechnung.ausstellerRechtstraegerId, rechnungTyp, null, invoiceRecipientId)}
              />
            }
          />
        ),
        column3: (
          <ValueTitle
            small
            title="Rechnungsempfänger"
            content={
              <RechtstraegerWithContactsTooltip
                rechtstraegerId={eingangsRechnung.payeeRechtstraegerId}
                rechtstraegerName={eingangsRechnung.payeeBezeichnung}
                linkTo={generatePathToRechtstraegerDocuments(eingangsRechnung.payeeRechtstraegerId, rechnungTyp, null, invoiceRecipientId)}
                maxTextLength={25}
              />
            }
          />
        ),
        column4: <ValueTitle small title="Rechnungsnummer" content={eingangsRechnung.rechnungsNummer} />,
        column5: <ValueTitle small title="Datum" content={<CustomFormattedDate value={eingangsRechnung.rechnungsDatum} />} />,
        column6: <ValueTitle small title="brutto" content={<EuroAmount value={eingangsRechnung.brutto} />} />,
        column7: isInvoiceTypeSingleInvoice(eingangsRechnung.type) ? (
          <RowSingleInvoiceStatus rechnung={eingangsRechnung as SingleInvoice} />
        ) : (
          <RowStandingInvoiceStatus rechnung={eingangsRechnung as StandingInvoice} />
        ),
        column8: isInvoiceTypeSingleInvoice(eingangsRechnung.type) ? (
          <SingleInvoiceActions record={record} file={record} invoice={eingangsRechnung as SingleInvoice} onAction={refetch} />
        ) : (
          <StandingInvoiceActions record={record} file={record} invoice={eingangsRechnung as StandingInvoice} onAction={refetch} />
        ),
      }
    : undefined;

  return (
    <DocumentRowContextProvider value={{ isLoading: loading, documentInfo }}>
      <tr {...restProps} />
    </DocumentRowContextProvider>
  );
};

export default RowIncomingInvoice;
