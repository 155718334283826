import React, { FC } from 'react';
import { Space } from 'antd';
import { TDeviationCell, TDeviationReference } from '../../../deviation.model';
import { cellsNewAltOrder, hasDeviationCellEmptyValue } from '../../../deviationHelpers';
import ColWithOneReferenceOfCause from './ColWithOneReferenceOfCause';
import ColWithMultipleReferenceOfCause from './ColWithMultipleReferenceOfCause';
import { DeviationCellStyledText, deviationCellTextColor, DeviationColumnTitleStyledText } from '../../../Styled/Deviation.style';
import TextForEmptyValue from '../../../../Text/TextForEmptyValue';

type Props = {
  cellsCol: TDeviationCell[];
  altTextForMultipleReferenceOfCause?: string;
  columnCount: number;
};

const DeviationReferenceOfCauseCol: FC<Props> = ({ cellsCol, altTextForMultipleReferenceOfCause, columnCount }) => {
  const allCellsInNeuAltOrder = cellsNewAltOrder(cellsCol);

  return (
    <Space direction="vertical" align="end">
      <DeviationColumnTitleStyledText type="secondary">{allCellsInNeuAltOrder[0].header.text}</DeviationColumnTitleStyledText>
      {allCellsInNeuAltOrder.map((cell, index) => {
        if (hasDeviationCellEmptyValue(cell)) {
          return (
            <DeviationCellStyledText key={index} strong={cell.hasChanged} style={{ color: deviationCellTextColor(cell) }}>
              {cell.value as string}
            </DeviationCellStyledText>
          );
        }

        const referenceOfCauseList = cell.value as TDeviationReference[];

        if (referenceOfCauseList.length === 1) {
          return <ColWithOneReferenceOfCause cell={cell} referenceOfCauseList={referenceOfCauseList} key={index} columnCount={columnCount} />;
        } else if (referenceOfCauseList.length > 1) {
          return (
            <ColWithMultipleReferenceOfCause
              cell={cell}
              referenceOfCauseList={referenceOfCauseList}
              key={index}
              altTextForMultipleReferenceOfCause={altTextForMultipleReferenceOfCause}
            />
          );
        }

        return <TextForEmptyValue textToShow="minus" key={index} />;
      })}
    </Space>
  );
};

export default DeviationReferenceOfCauseCol;
