import React, { FC } from 'react';
import { Typography } from 'antd';
import FormattedDateTime from '../FormattedDateTime/FormattedDateTime';
import MitarbeiterTooltip from '../Card/MitarbeiterTooltip';
import { StyledSpace } from './styled/Modal.style';

type ModalCreateInfoProps = {
  createdTs: string | null;
  mitarbeiterId?: string | null | undefined;
};

/**
 * <h2>Usage</h2>
 * In Modal component to show information about who and when created the entity.
 */
const ModalCreateInfo: FC<ModalCreateInfoProps> = ({ createdTs, mitarbeiterId }) => (
  <>
    <StyledSpace style={{ width: '75%', justifyContent: 'flex-start' }} align="end">
      <div>
        <Typography.Text>
          erstellt am<span style={{ paddingLeft: '4px', paddingRight: '4px' }}>•</span>
        </Typography.Text>
        <FormattedDateTime createdTs={createdTs} />
      </div>
      <MitarbeiterTooltip showsInitials={false} mitarbeiterId={mitarbeiterId} alignment="right" />
    </StyledSpace>
  </>
);

export default ModalCreateInfo;
