import React, { FC } from 'react';
import { Box } from 'rebass';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../../components/message';
import { getPreviousTimeblockBefore, ITimeblock, useTimeline } from '../../../../../components/Timeline/timelineCtx';
import TimelineFormButtons from '../../../../../components/Timeline/Form/TimelineFormButtons';
import TimelineFormDatePicker from '../../../../../components/Timeline/Form/TimelineFormDatePicker';
import {
  mapFormValuesToUstRegelsetVersion,
  mapUstRegelsetTimeblockToFormValues,
  ustRegelsetVersionFormFields,
  UstRegelsetVersionFormValues,
} from './ustRegelsetVersionFormMapper';
import {
  useCreateUstRegelsetUstKategorieZuweisungMutation,
  useUpdateUstRegelsetUstKategorieZuweisungMutation,
} from '../gql/UstRegelsetUstKategorieMutations.types';
import UstKategorieSelect from '../../../UstKategorieSelect/UstKategorieSelect';
import { layoutTimelineForm } from '../../../../../components/Input-antd/formLayoutHelper';
import { ustRegelsetVersionFormValidationSchema } from './ustRegelsetVersionFormValidationSchema';
import { UstRegelsetUstKategorieTimelineDataSourceEntry } from '../ustRegelsetVersionTimelineMapper';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import { useGetAppFieldHelpText } from '../../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  ustRegelsetId: string;
  firmendatenId?: string | null;
  timeblock: ITimeblock<UstRegelsetUstKategorieTimelineDataSourceEntry>;
  onSubmitSuccess: () => void;
};

const UstRegelsetVersionForm: FC<Props> = ({ ustRegelsetId, firmendatenId, timeblock, onSubmitSuccess }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'UstRegelsetKategorieZuweisungRequest'>('UstRegelsetKategorieZuweisungRequest');

  const { dataSource, toggleEdit, changeToUpdateMode } = useTimeline<UstRegelsetUstKategorieTimelineDataSourceEntry>();
  const isUpdate = !!timeblock.ustRegelsetKategorieZuweisungId;
  const entity = 'USt-Kategorie';

  const [runUpdate, { loading: loadingUpdate }] = useUpdateUstRegelsetUstKategorieZuweisungMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      toggleEdit(timeblock.uuid);
      onSubmitSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runCreate, { loading: loadingCreate }] = useCreateUstRegelsetUstKategorieZuweisungMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      changeToUpdateMode(timeblock.uuid);
      onSubmitSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<UstRegelsetVersionFormValues>
      initialValues={mapUstRegelsetTimeblockToFormValues(timeblock)}
      validationSchema={ustRegelsetVersionFormValidationSchema}
      onSubmit={(formValues, { setSubmitting }) => {
        if (isUpdate) {
          runUpdate({
            variables: {
              ustRegelsetId,
              ustRegelsetUstKategorieZuweisungId: timeblock.ustRegelsetKategorieZuweisungId,
              input: mapFormValuesToUstRegelsetVersion(formValues),
            },
          }).finally(() => setSubmitting(false));
        } else {
          runCreate({
            variables: { ustRegelsetId, input: mapFormValuesToUstRegelsetVersion(formValues) },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form {...layoutTimelineForm} layout="horizontal" labelAlign="left">
          <Box width={2 / 3}>
            <FormItemWithFieldHelp
              name={ustRegelsetVersionFormFields.validFrom}
              label="Gültig ab"
              fieldHelp={getFieldHelpText('UstRegelsetKategorieZuweisungRequest.validFrom')}
            >
              <TimelineFormDatePicker<UstRegelsetUstKategorieTimelineDataSourceEntry>
                name={ustRegelsetVersionFormFields.validFrom}
                timeblock={timeblock}
              />
            </FormItemWithFieldHelp>

            <UstKategorieSelect
              name={ustRegelsetVersionFormFields.ustKategorieId}
              firmendatenId={firmendatenId}
              fieldHelp={getFieldHelpText('UstRegelsetKategorieZuweisungRequest.ustKategorieId')}
            />
          </Box>

          <TimelineFormButtons<UstRegelsetUstKategorieTimelineDataSourceEntry>
            timeblock={timeblock}
            isSubmitting={formikProps.isSubmitting}
            isSameAsPreviousVersion={isSameAsPreviousVersion(
              formikProps.values,
              getPreviousTimeblockBefore(dataSource, dayjsCustom(formikProps.values.validFrom))
            )}
            submitBtnLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

const isSameAsPreviousVersion = (
  formValues: UstRegelsetVersionFormValues,
  previousTimeblock?: ITimeblock<UstRegelsetUstKategorieTimelineDataSourceEntry>
) => formValues.ustKategorieId === previousTimeblock?.ustKategorie?.ustKategorieId;

export default UstRegelsetVersionForm;
