import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import FirmendatenOrRechnungsausstellerSelect from '../FirmendatenOrRechnungsausstellerSelect';
import { useCreateSepaMandatMutation } from '../../gql/SepaMandatMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import {
  mapFormValuesToSepaMandatCreate,
  sepaMandatCreateFormFields,
  sepaMandatCreateFormInitialValues,
  SepaMandatCreateFormValues,
} from './sepaMandatCreateFormMapper';
import FormButtons from '../../../../../components/Button/FormButtons';
import { sepaMandatCreateFormValidationSchema } from './sepaMandatCreateFormValidationSchema';
import SepaMandatReferenceInput from './SepaMandatReferenceInput';
import { useGetAppFieldHelpText } from '../../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  bankDetailsId: string;
  rechtstraegerId: string;
  onSuccess: () => void;
  onCancel: () => void;
};

const SepaMandatCreateForm: FC<Props> = ({ rechtstraegerId, bankDetailsId, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'SepaMandat'>('SepaMandat');

  const [runCreateSepaMandat, { loading }] = useCreateSepaMandatMutation({
    onCompleted: () => onSuccess(),
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<SepaMandatCreateFormValues>
      initialValues={sepaMandatCreateFormInitialValues}
      onSubmit={(values) => {
        const input = mapFormValuesToSepaMandatCreate(values);
        runCreateSepaMandat({
          variables: {
            bankDetailsId,
            rechtstraegerId,
            input,
          },
        });
      }}
      validationSchema={sepaMandatCreateFormValidationSchema}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithFieldHelp
            name={sepaMandatCreateFormFields.rechnungsAusstellerId}
            label="Rechnungsaussteller"
            fieldHelp={getFieldHelpText('SepaMandat.rechnungsAusstellerFirmendatenId')} // Using "SepaMandat.rechnungsAusstellerFirmendatenId" as fieldHelp as it is more important than "SepaMandat.rechnungsAusstellerRechtstraegerId" in this case
          >
            <FirmendatenOrRechnungsausstellerSelect
              name={sepaMandatCreateFormFields.rechnungsAusstellerId}
              onRechnungsausstellerSelect={(rechnungsausstellerType) =>
                formikProps.setFieldValue(sepaMandatCreateFormFields.tempRechnungsausstellerType, rechnungsausstellerType)
              }
            />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={sepaMandatCreateFormFields.sepaMandatReference}
            label="SEPA Mandatsreferenz"
            fieldHelp={getFieldHelpText('SepaMandat.sepaMandatReference')}
          >
            <SepaMandatReferenceInput
              name={sepaMandatCreateFormFields.sepaMandatReference}
              rechtstraegerId={rechtstraegerId}
              formikProps={formikProps}
            />
          </FormItemWithFieldHelp>
          <FormButtons onCancel={onCancel} isOkLoading={loading} okText="Abschicken" updateMode={false} />
        </Form>
      )}
    </Formik>
  );
};

export default SepaMandatCreateForm;
