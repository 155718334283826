import { indexedValueListingFiltersFormFields } from './indexedValueListingFiltersFormMapper';

export const isIndexedValueFilterObjektSelected = (filter: string) => filter === indexedValueListingFiltersFormFields.objektId;
export const isIndexedValueFilterBestandseinheitSelected = (filter: string) => filter === indexedValueListingFiltersFormFields.bestandseinheitId;
export const isIndexedValueFilterVertragSelected = (filter: string) => filter === indexedValueListingFiltersFormFields.vertragId;
export const isIndexedValueFilterStatusSelected = (filter: string) => filter === indexedValueListingFiltersFormFields.statusList;
export const isIndexedValueFilterVertragspartnerSelected = (filter: string) => filter === indexedValueListingFiltersFormFields.vertragspartnerId;
export const isIndexedValueFilterValidFromSelected = (filter: string) => filter === indexedValueListingFiltersFormFields.validFrom;
export const isIndexedValueFilterValidToSelected = (filter: string) => filter === indexedValueListingFiltersFormFields.validTo;
export const isIndexedValueFilterValuePreservationTypeSelected = (filter: string) =>
  filter === indexedValueListingFiltersFormFields.valuePreservationTypeList;
export const isIndexedValueFilterIndexSeriesSelected = (filter: string) => filter === indexedValueListingFiltersFormFields.indexSeriesId;
export const isIndexedValueFilterVorschreibungspositionNameSelected = (filter: string) =>
  filter === indexedValueListingFiltersFormFields.vorschreibungspositionName;
export const isIndexedValueFilterRechnungsAusstellerSelected = (filter: string) =>
  filter === indexedValueListingFiltersFormFields.rechnungsAusstellerId;
