import { RcFile } from 'antd/lib/upload/interface';
import { FormFields } from '../../../../helpers/formikHelper';

export type TicketSyncEmailsInput = {
  deletableIds: string[];
};

export type TicketEmailFileData = {
  filename: string;
  file: RcFile;
  isUpload: boolean;
  ts: string;
};

// Unified type from uploader and stored attachments
export type TicketUnifiedEmail = {
  fileId?: string;
  name: string;
  size: number;
  file?: File;
};

export type TicketEmailsFormValues = {
  deletableIds: string[];
  newEmailList: TicketEmailFileData[];
};

export const ticketEmailsFormFields: FormFields<TicketEmailsFormValues> = {
  deletableIds: 'deletableIds',
  newEmailList: 'newEmailList',
};

export const ticketEmailsFormInitialValues: TicketEmailsFormValues = {
  deletableIds: [],
  newEmailList: [],
};

export const mapFormValuesToTicketEmails = (formValues: TicketEmailsFormValues) => ({
  deletableIds: formValues.deletableIds,
});
