import React, { FC } from 'react';
import { Row } from 'antd';
import KontoDetailsCardsBlock from './KontoDetailsCardsBlock';
import { BSBuchungszeileForTable } from '../../Form/buchungsmaskeFormMapper';

type Props = {
  selectedRow: BSBuchungszeileForTable;
};

const BuchungszeileDetailsCardsBlock: FC<Props> = ({ selectedRow }) => {
  return (
    <Row justify="space-between" style={{ padding: selectedRow ? '16px' : '16px 16px 8px 16px' }}>
      <KontoDetailsCardsBlock buchungszeile={selectedRow} />
    </Row>
  );
};

export default BuchungszeileDetailsCardsBlock;
