import React, { FC } from 'react';
import { MenuProps, Modal } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import ActionDropdown from '../../../components/Dropdown/ActionDropdown';
import { TRechtstraegerQueryParams } from './Filters/filtersQueryParams';
import { mapQueryParamsToRechtstreagerListQueryVariables } from './Filters/filtersFormMapper';
import { runExportRechtstraegerListAsExcel } from './runExportRechtstraegerListAsExcel';
import { useAuth } from '../../../shared/Auth/authContext';
import { downloadURL } from '../../../helpers/fileHelper';
import { showSuccessMsgOther, showWarningMessage } from '../../../components/message';

type Props = {
  queryParams: TRechtstraegerQueryParams;
};

const RechtstraegerTableBulkActions: FC<Props> = ({ queryParams }) => {
  const { activeForFirmendatenId } = useAuth();
  const rechtstraegerListArgs = mapQueryParamsToRechtstreagerListQueryVariables(queryParams);

  const onExportSuccess = (response: any) => {
    downloadURL(response.url);
    showSuccessMsgOther(`Rechtsträger Liste erfolgreich exportiert.`);
  };

  const onExportError = (error: any) => {
    showWarningMessage(error, 'Rechtsträger Liste wurde nicht exportiert');
  };

  const onExport = () => {
    runExportRechtstraegerListAsExcel({ firmendatenId: activeForFirmendatenId, queryParams: rechtstraegerListArgs })
      .then(onExportSuccess)
      .catch(onExportError);
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Excel exportieren',
      onClick: () => showConfirmExportAsExcel(onExport),
      icon: <FileExcelOutlined />,
    },
  ];

  return <ActionDropdown items={items} title="Auf Liste anwenden" />;
};

const showConfirmExportAsExcel = (handleExport: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie die Rechtsträger als Excel exportieren?',
    okText: 'Ja',
    cancelText: 'Nein',
    onOk() {
      return handleExport();
    },
  });
};

export default RechtstraegerTableBulkActions;
