import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAppFieldHelpMutationVariables = Types.Exact<{
  fieldHelpId: Types.Scalars['ID']['input'];
  input: Types.FieldHelpUpdateInput;
}>;

export type UpdateAppFieldHelpMutation = {
  updateAppFieldHelp: {
    data: { deprecated: boolean; fieldHelp?: string | null; fieldHelpId: string; selector: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteAppFieldHelpMutationVariables = Types.Exact<{
  fieldHelpId: Types.Scalars['ID']['input'];
}>;

export type DeleteAppFieldHelpMutation = {
  deleteAppFieldHelp: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const UpdateAppFieldHelpDocument = gql`
  mutation updateAppFieldHelp($fieldHelpId: ID!, $input: FieldHelpUpdateInput!) {
    updateAppFieldHelp(fieldHelpId: $fieldHelpId, input: $input) {
      data {
        deprecated
        fieldHelp
        fieldHelpId
        selector
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateAppFieldHelpMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAppFieldHelpMutation, UpdateAppFieldHelpMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAppFieldHelpMutation, UpdateAppFieldHelpMutationVariables>(UpdateAppFieldHelpDocument, options);
}
export type UpdateAppFieldHelpMutationHookResult = ReturnType<typeof useUpdateAppFieldHelpMutation>;
export type UpdateAppFieldHelpMutationResult = Apollo.MutationResult<UpdateAppFieldHelpMutation>;
export type UpdateAppFieldHelpMutationOptions = Apollo.BaseMutationOptions<UpdateAppFieldHelpMutation, UpdateAppFieldHelpMutationVariables>;
export const DeleteAppFieldHelpDocument = gql`
  mutation deleteAppFieldHelp($fieldHelpId: ID!) {
    deleteAppFieldHelp(fieldHelpId: $fieldHelpId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteAppFieldHelpMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAppFieldHelpMutation, DeleteAppFieldHelpMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAppFieldHelpMutation, DeleteAppFieldHelpMutationVariables>(DeleteAppFieldHelpDocument, options);
}
export type DeleteAppFieldHelpMutationHookResult = ReturnType<typeof useDeleteAppFieldHelpMutation>;
export type DeleteAppFieldHelpMutationResult = Apollo.MutationResult<DeleteAppFieldHelpMutation>;
export type DeleteAppFieldHelpMutationOptions = Apollo.BaseMutationOptions<DeleteAppFieldHelpMutation, DeleteAppFieldHelpMutationVariables>;
