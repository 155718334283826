import React from 'react';
import { Route } from 'react-router';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../security/permissionChecks';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_SYS_SETTINGS } from '../../../../constants/configurationUriPaths';
import SysSettingsBudgetingVerknuepfungAbrDefVPosPage from './SysSettingsBudgetingVerknuepfungAbrDefVPosPage';

const sysSettingsBudgetingVerknuepfungAbrDefVPosPageRoute = (
  <Route
    path={URI_SYS_SETTINGS.budgetingVerknuepfungAbrDefVPosPage}
    element={
      <AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsBudgetingVerknuepfungAbrDefVPosPage />} />
    }
  />
);

export default sysSettingsBudgetingVerknuepfungAbrDefVPosPageRoute;
