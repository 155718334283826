import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../../styles/pdfStyles';
import TARepFondsAbrAusgabenKontoBezeichnung from './TARepFondsAbrAusgabenKontoBezeichnung';
import TARepFondsAbrAusgabenKontoAusgabe from './TARepFondsAbrAusgabenKontoAusgabe';
import TARepFondsAbrSumme from '../../TARepFondsAbrSumme';
import { RepFondsAbrechnungAusgabenKonto } from '../../../../../topAbrechnung-types';

const TARepFondsAusgabenWithAusgabenList: FC<{ ausgabenKonto: RepFondsAbrechnungAusgabenKonto }> = ({ ausgabenKonto }) => (
  <View style={[pdfStyles.column]} wrap={false}>
    {/* Kontobezeichnung */}
    <TARepFondsAbrAusgabenKontoBezeichnung bezeichnung={ausgabenKonto.kontoBezeichnung} />

    {/* Ausgaben list */}
    {ausgabenKonto.ausgabenList &&
      ausgabenKonto.ausgabenList.map((ausgabe, index) => <TARepFondsAbrAusgabenKontoAusgabe ausgabe={ausgabe} key={index} />)}

    {/* Ausgaben summe */}
    <TARepFondsAbrSumme summe={ausgabenKonto.summeNettoAusgaben} />
  </View>
);

export default TARepFondsAusgabenWithAusgabenList;
