import React from 'react';
import { Typography } from 'antd';
import MitarbeiterTooltip from '../../../components/Card/MitarbeiterTooltip';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { FirmaForKsFragment } from './gql/KundenSystemFragments.types';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';

const firmendatenListTableColumns: TableWithColSelectorColumnProps<FirmaForKsFragment>[] = [
  {
    title: 'Kurzbezeichnung',
    defaultSelected: true,
    render: (text, record) => <Typography.Text>{record.kurzBezeichnung}</Typography.Text>,
  },
  {
    title: 'Mitarbeiteradmin',
    defaultSelected: true,
    render: () => <span>@TODO</span>,
  },
  {
    title: 'Paket',
    defaultSelected: true,
    render: () => <span>@TODO</span>,
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Status',
    defaultSelected: true,
    render: (text, record) => <span>{record.status.value}</span>,
  },
];

export default firmendatenListTableColumns;
