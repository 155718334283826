import React, { FC } from 'react';
import { Row } from 'antd';
import PDFPageCol from './PDFPageCol';
import { mockVorschreibungDauerRgStorno } from './mocks/vorschreibung/vorschreibungDauerRgStornoMocks';
import VorschreibungTemplate, { VorschreibungSelectableSection } from './templates/vorschreibung/VorschreibungTemplate';

type PDFPageVorschreibungDauerRgStornoProps = {
  isInEditMode?: boolean;
  selectedTextbaustein?: VorschreibungSelectableSection;
};

const PDFPageVorschreibungDauerRgStorno: FC<PDFPageVorschreibungDauerRgStornoProps> = ({ isInEditMode, selectedTextbaustein }) => (
  <Row>
    <PDFPageCol
      pdfTemplate={
        <VorschreibungTemplate data={mockVorschreibungDauerRgStorno} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />
      }
    />
  </Row>
);

export default PDFPageVorschreibungDauerRgStorno;
