import { MahnungColumnsStyle } from './mahnung-styles-types';

export const columnWidthsAndAlignment: MahnungColumnsStyle = {
  belegnummer: { width: '15%', textAlign: 'left' },
  belegdatum: { width: '20%', textAlign: 'left' },
  rechnungsbetrag: { width: '30%', textAlign: 'right' },
  zahlung: { width: '15%', textAlign: 'right' },
  restbetrag: { width: '15%', textAlign: 'right' },
  mahnstufe: { width: '25mm', textAlign: 'right' },
};
