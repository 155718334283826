import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { TableWithAlignedColsColumnType } from '../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { generatePathToBestandseinheitDetailsPage } from '../../Bestandseinheit/bestandseinheitUriPaths';
import { EuroAmount } from '../../../components/Number';
import { generatePathToBeVertragDetailsPage } from '../../Vertrag/BeVertrag/beVertragUriPaths';
import { VorsteuerkuerzungVertrag } from '../../../types';
import { generatePathToBookingDetailsPage } from '../../BookingDetails/bookingDetailsPaths';
import RechtstraegerWithContactsTooltip from '../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

const vstKuerzungDetailsTableColumns = (objektId: string, rechtstraegerId: string): TableWithAlignedColsColumnType<VorsteuerkuerzungVertrag>[] => [
  {
    title: 'Top',
    align: 'left',
    render: (vstKuerzungForVertrag) => (
      <Link to={generatePathToBestandseinheitDetailsPage(objektId, vstKuerzungForVertrag.bestandseinheit.bestandseinheitId)}>
        {vstKuerzungForVertrag.bestandseinheit.bezeichnung}
      </Link>
    ),
  },
  {
    title: 'Vertrag',
    align: 'left',
    render: (vstKuerzungForVertrag) => (
      <RechtstraegerWithContactsTooltip
        rechtstraegerId={vstKuerzungForVertrag.vertrag.vertragspartner.rechtstraegerId}
        rechtstraegerName={vstKuerzungForVertrag.vertrag.vertragspartner.kurzBezeichnung}
        maxTextLength={20}
        linkTo={generatePathToBeVertragDetailsPage(
          objektId,
          vstKuerzungForVertrag.bestandseinheit.bestandseinheitId,
          vstKuerzungForVertrag.vertrag.vertragId
        )}
      />
    ),
  },
  {
    title: 'Vertragsart',
    align: 'left',
    render: (vstKuerzungForVertrag) => <Typography.Text>{vstKuerzungForVertrag.vertrag.vertragsart.text}</Typography.Text>,
  },
  {
    title: 'Summe Vst-Buchungsbetrag',
    align: 'right',
    colSpan: 3,
    render: (vstKuerzungForVertrag) => <EuroAmount value={vstKuerzungForVertrag.sumSteuer} />,
  },
  {
    title: 'Anz. berücksichtigter ER',
    align: 'center',
    width: '30%',
    render: (vstKuerzungForVertrag) => {
      const allBuchungIds = vstKuerzungForVertrag.vorsteuerkuerzungKontoList
        .map((vstKuerzung) =>
          vstKuerzung.aufwandsKontoList.map((aufwandsKonto) => aufwandsKonto.aufteilungList.map((aufteilung) => aufteilung.buchungId).flat())
        )
        .flat();
      const uniqueBuchungIds = Array.from(new Set(allBuchungIds));

      return <Typography.Text>{uniqueBuchungIds.length}</Typography.Text>;
    },
  },
  {
    title: 'Summe-Vst-Kürzung',
    align: 'right',
    colSpan: 2,
    width: '16%',
    render: (vstKuerzungForVertrag) =>
      vstKuerzungForVertrag.fibuBuchungIdList.length > 0 ? (
        <Link to={generatePathToBookingDetailsPage(vstKuerzungForVertrag.fibuBuchungIdList)}>
          <EuroAmount value={vstKuerzungForVertrag.sumVstKuerzung} />
        </Link>
      ) : (
        <EuroAmount value={vstKuerzungForVertrag.sumVstKuerzung} />
      ),
  },
];

export default vstKuerzungDetailsTableColumns;
