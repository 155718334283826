import React, { FC } from 'react';
import { StyledListSummarySpace } from '../../shared/ListSummary/styled/StyledListSummarySpace';
import { SummaryText } from '../../shared/ListSummary/SummaryText';
import { LoadingCol } from '../../../components/Loading';
import { MahnungListSummary as TMahnungListSummary } from '../../../types';

type Props = {
  summary?: TMahnungListSummary;
};

const MahnungListSummary: FC<Props> = ({ summary }) => {
  return (
    <>
      {summary ? (
        <StyledListSummarySpace>
          <SummaryText text="Rechnungsbetrag" value={summary.sumInvoiceAmount} />
          <SummaryText text="Mahnbetrag" value={summary.sumDunningAmount} />
          <SummaryText text="Mahngebühr" value={summary.sumDunningCharge} />
          <SummaryText text="Verzugszinsen" value={summary.sumDefaultInterest} />
        </StyledListSummarySpace>
      ) : (
        <LoadingCol isLoading />
      )}
    </>
  );
};

export default MahnungListSummary;
