import { FormFields } from '../../../../../helpers/formikHelper';
import { GenerierlaufEntryExitCode } from '../../../../../types';
import { TObjAbrVerarbeitungQueryParams } from './filtersQueryParams';

export type ObjAbrVerarbeitungEntryTableFormValues = {
  rechnungsausstellerId?: string;
  objektId?: string;
  exitCodeList?: GenerierlaufEntryExitCode[];
};

export const objAbrVerarbeitungEntryTableFormFields: FormFields<ObjAbrVerarbeitungEntryTableFormValues> = {
  objektId: 'objektId',
  exitCodeList: 'exitCodeList',
  rechnungsausstellerId: 'rechnungsausstellerId',
};

export const objAbrVerarbEntryListingFiltersFormInitialValues = (
  queryParams: TObjAbrVerarbeitungQueryParams
): ObjAbrVerarbeitungEntryTableFormValues => ({
  objektId: queryParams.objektId,
  exitCodeList: queryParams.exitCodeList,
  rechnungsausstellerId: queryParams.rechnungsausstellerId,
});

export const mapFormValuesToObjAbrVerarbeitungFilters = (formValues: ObjAbrVerarbeitungEntryTableFormValues): TObjAbrVerarbeitungQueryParams => ({
  objektId: formValues.objektId,
  exitCodeList: formValues.exitCodeList,
  rechnungsausstellerId: formValues.rechnungsausstellerId,
});
