import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import SubAbrechnungDetailsPage from './SubAbrechnungDetailsPage';
import { URI_SUB_ABRECHNUNG_DETAILS_PAGE } from '../../features/Abrechnung/Sub/subAbrechnungUriPaths';

export type SubAbrechnungDetailsPageRouteParams = { objektId: string; subAbrechnungId: string };

const subAbrechnungDetailsPageRoute = (
  <Route
    path={`${URI_SUB_ABRECHNUNG_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<SubAbrechnungDetailsPage />} />}
  />
);

export default subAbrechnungDetailsPageRoute;
