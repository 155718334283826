import React, { FC, useContext } from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { IndexationAgreementContext } from '../../Level2/IndexationAgreementContext';
import { useDeleteVertragVposIndexedValueMutation } from '../../../../../../../IndexedValue/gql/VertragVposIndexedValueMutations.types';
import { VertragVposIndexedValue } from '../../../../../../../../types';
import { showSuccessMsgDelete } from '../../../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../../../helpers/apolloHelper';
import { isVertragVposIndexedValueStatusApplied } from '../../../../../../../IndexedValue/shared/indexedValueHelpers';

type Props = {
  indexedValue: VertragVposIndexedValue;
  onSubmit: () => void;
};

const ActionDelete: FC<Props> = ({ indexedValue, onSubmit }) => {
  const { bestandseinheitId, objektId, vertragId, vorschreibungspositionId } = useContext(IndexationAgreementContext);

  const isDeletionDisabled = isVertragVposIndexedValueStatusApplied(indexedValue.status.value);

  const [runDelete] = useDeleteVertragVposIndexedValueMutation({
    variables: {
      objektId,
      bestandseinheitId,
      vertragId,
      vorschreibungspositionId,
      indexationAgreementId: indexedValue.indexationAgreement.vertragVposIndexationAgreementId,
      indexedValueId: indexedValue.vertragVposIndexedValueId,
    },
    onCompleted: () => {
      showSuccessMsgDelete(`Indexierter Wert ${indexedValue.name} angewendet`);
      onSubmit();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Popconfirm
      title="Sind Sie sicher?"
      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
      disabled={isDeletionDisabled}
      onConfirm={() => runDelete()}
    >
      <Tooltip title="Löschen">
        <Button type="link" icon={<DeleteOutlined />} disabled={isDeletionDisabled} />
      </Tooltip>
    </Popconfirm>
  );
};

export default ActionDelete;
