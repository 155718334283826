import * as Yup from 'yup';
import { topAbrDefGeneralSettingsFormFields } from './topAbrDefGeneralSettingsFormMapper';
import { entityIsRequired, entityMustBeSelected } from '../../../../../../../components/message/validationMsg';
import { isDueDateOptionTypeVerrechnungsperiode } from '../dueDateHelpers';
import { AbrechnungsdefinitionType } from '../../../../../../../types';
import { isAbrDefTypeBkAbrechnung } from '../../../../AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';

export const topAbrDefGeneralSettingsValidationSchema = (abrDefType: AbrechnungsdefinitionType) =>
  isAbrDefTypeBkAbrechnung(abrDefType) ? bkTopAbrDefGeneralSettingsValidationSchema : weTopAbrDefGeneralSettingsValidationSchema;

const bkTopAbrDefGeneralSettingsValidationSchema = Yup.object().shape({
  [topAbrDefGeneralSettingsFormFields.dueDateDisplayOptionMieter]: Yup.object()
    .shape({
      type: Yup.string().test({
        test: (value, context) => {
          const formData = context.options.context;
          if (formData?.displayDueDate) {
            return !!value;
          } else {
            return true;
          }
        },
        message: entityMustBeSelected('Typ'),
      }),
      month: Yup.string()
        .nullable()
        .test({
          test: (value, context) => {
            const formData = context.options.context;
            if (formData?.displayDueDate && isDueDateOptionTypeVerrechnungsperiode(formData?.dueDateDisplayOptionMieter.type)) {
              return !!value;
            } else {
              return true;
            }
          },
          message: entityIsRequired('Monat'),
        }),
    })
    .nullable(),
});

const weTopAbrDefGeneralSettingsValidationSchema = Yup.object().shape({
  [topAbrDefGeneralSettingsFormFields.rueckstandOtherPersonsThreshold]: Yup.number().when(
    [topAbrDefGeneralSettingsFormFields.displayRueckstandOtherPersons],
    ([displayRueckstandOtherPersons], schema) =>
      displayRueckstandOtherPersons ? schema.required(entityIsRequired('Mindestbetrag')) : schema.nullable()
  ),
  [topAbrDefGeneralSettingsFormFields.dueDateDisplayOptionEigentuemerGuthaben]: Yup.object()
    .shape({
      type: Yup.string().test({
        test: (value, context) => {
          const formData = context.options.context;
          if (formData?.displayDueDate) {
            return !!value;
          } else {
            return true;
          }
        },
        message: entityMustBeSelected('Typ'),
      }),
      month: Yup.string()
        .nullable()
        .test({
          test: (value, context) => {
            const formData = context.options.context;
            if (formData?.displayDueDate && isDueDateOptionTypeVerrechnungsperiode(formData?.dueDateDisplayOptionEigentuemerGuthaben.type)) {
              return !!value;
            } else {
              return true;
            }
          },
          message: entityIsRequired('Monat'),
        }),
    })
    .nullable(),
  [topAbrDefGeneralSettingsFormFields.dueDateDisplayOptionEigentuemerNachzahlung]: Yup.object()
    .shape({
      type: Yup.string()
        .nullable()
        .test({
          test: (value, context) => {
            const formData = context.options.context;
            if (formData?.displayDueDate) {
              return !!value;
            } else {
              return true;
            }
          },
          message: entityMustBeSelected('Typ'),
        }),
      month: Yup.string().test({
        test: (value, context) => {
          const formData = context.options.context;
          if (formData?.displayDueDate && isDueDateOptionTypeVerrechnungsperiode(formData?.dueDateDisplayOptionEigentuemerNachzahlung.type)) {
            return !!value;
          } else {
            return true;
          }
        },
        message: entityIsRequired('Monat'),
      }),
    })
    .nullable(),
});
