import * as Yup from 'yup';
import { spracheValidationSchema } from '../../Sprache/spracheValidationSchema';
import { personPartValidationSchema } from '../../../../shared/components/Person/personFormValidationSchema';
import { kurzBezeichnungFormPartValidationSchema } from '../../../../shared/components/KurzBezeichnung/form/kurzBezeichnungFormPartValidationSchema';
import { tempHauptAddressValidationSchemaOptional } from '../../HauptAddress/hauptAddressOptionalFormPartValidationSchema';

export const natuerlichePersonCreateFormValidationSchema = Yup.object()
  .shape({
    sprache: spracheValidationSchema,
    tempHauptAddress: tempHauptAddressValidationSchemaOptional,
  })
  // @ts-ignore
  .concat(personPartValidationSchema)
  // @ts-ignore
  .concat(kurzBezeichnungFormPartValidationSchema);

export const natuerlichePersonUpdateFormValidationSchema = Yup.object()
  .shape({
    sprache: spracheValidationSchema,
  })
  // @ts-ignore
  .concat(personPartValidationSchema)
  // @ts-ignore
  .concat(kurzBezeichnungFormPartValidationSchema);
