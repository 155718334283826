import { generatePath } from 'react-router-dom';
import { URI_SYS_SETTINGS } from '../../constants/configurationUriPaths';

export const URI_SYS_SETTINGS_AUFTRAGSART_PAGE = {
  createPage: `${URI_SYS_SETTINGS.auftragsartPage}/new`,
  updatePage: `${URI_SYS_SETTINGS.auftragsartPage}/:auftragsartId`,
};

export const generatePathToAuftragsartUpdatePage = (auftragsartId: string) =>
  generatePath(URI_SYS_SETTINGS_AUFTRAGSART_PAGE.updatePage, { auftragsartId });

export default URI_SYS_SETTINGS_AUFTRAGSART_PAGE;
