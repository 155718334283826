import { FC } from 'react';
import { List, Space } from 'antd';
import { isBeInfoFeldKellerabteil, isBeInfoFeldParkplatz, isBeInfoFeldZimmer } from './beInfoFeldListHelpers';
import KellerabteilListItem from './ListItem/Kellerabteil/KellerabteilListItem';
import {
  BeInfoFeld_BeInfoFeldKellerabteil_Fragment,
  BeInfoFeld_BeInfoFeldParkplatz_Fragment,
  BeInfoFeld_BeInfoFeldZimmer_Fragment,
  BeInfoFeldFragment,
} from '../gql/BeInfoFeldFragments.types';
import ParkplatzListItem from './ListItem/Parkplatz/ParkplatzListItem';
import ZimmerListItem from './ListItem/Zimmer/ZimmerListItem';
import { AbstractBeInfoFeld } from '../../../../types';

type Props = {
  loading: boolean;
  infoFeldList: BeInfoFeldFragment[];
  objektId: string;
  bestandseinheitId: string;
  onSuccess: () => void;
};

const BeInfoFeldList: FC<Props> = ({ infoFeldList, loading, objektId, bestandseinheitId, onSuccess }) => {
  const kellerabteilList = infoFeldList.filter(isBeInfoFeldKellerabteil);
  const parkplatzList = infoFeldList.filter(isBeInfoFeldParkplatz);
  const zimmmerList = infoFeldList.filter(isBeInfoFeldZimmer);

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={16}>
      {kellerabteilList && kellerabteilList.length > 0 && (
        <List<AbstractBeInfoFeld>
          bordered
          loading={loading}
          dataSource={kellerabteilList}
          renderItem={(item) => (
            <KellerabteilListItem
              kellerabteil={item as BeInfoFeld_BeInfoFeldKellerabteil_Fragment}
              objektId={objektId}
              bestandseinheitId={bestandseinheitId}
              onSuccess={onSuccess}
            />
          )}
        />
      )}
      {parkplatzList && parkplatzList.length > 0 && (
        <List<AbstractBeInfoFeld>
          bordered
          loading={loading}
          dataSource={parkplatzList}
          renderItem={(item) => (
            <ParkplatzListItem
              parkplatz={item as BeInfoFeld_BeInfoFeldParkplatz_Fragment}
              objektId={objektId}
              bestandseinheitId={bestandseinheitId}
              onSuccess={onSuccess}
            />
          )}
        />
      )}
      {zimmmerList && zimmmerList.length > 0 && (
        <List<AbstractBeInfoFeld>
          bordered
          loading={loading}
          dataSource={zimmmerList}
          renderItem={(item) => (
            <ZimmerListItem
              zimmer={item as BeInfoFeld_BeInfoFeldZimmer_Fragment}
              objektId={objektId}
              bestandseinheitId={bestandseinheitId}
              onSuccess={onSuccess}
            />
          )}
        />
      )}
    </Space>
  );
};

export default BeInfoFeldList;
