import React, { FC } from 'react';
import { Drawer, Dropdown, MenuProps, Modal } from 'antd';
import { CheckSquareOutlined, CloseSquareOutlined, DeleteOutlined, DownOutlined, EditOutlined, ImportOutlined } from '@ant-design/icons';
import { EbicsUser } from '../../../../../../types';
import { useToggle } from '../../../../../../hooks/useToggle';
import EbicsUserForm from '../../../Form/EbicsUserForm';
import { showSuccessMsgDelete, showSuccessMsgOther } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { useActivateEbicsUserMutation, useDeactivateEbicsUserMutation, useDeleteEbicsUserMutation } from '../../../gql/EbicsUserMutations.types';
import { isEbicsUserStatusActive, isEbicsUserStatusInactive } from '../../../../shared/ebicsHelpers';
import { useAuth } from '../../../../../../shared/Auth/authContext';
import { handleImportBankstatementPerEbicsUserSubmit } from './handleImportBankstatementPerEbicsUserSubmit';

type Props = {
  ebicsUser: EbicsUser;
  onAction: () => void;
};

const EbicsUserActions: FC<Props> = ({ ebicsUser, onAction }) => {
  const { activeForFirmendatenId } = useAuth();

  const [isDrawerOpen, toggleDrawer] = useToggle();

  const entity = 'Ebics Bankteilnehmer';

  const [runActivate] = useActivateEbicsUserMutation({
    onCompleted: () => {
      showSuccessMsgOther(`${entity} aktiviert`);
      onAction();
    },
    variables: { ebicsUserId: ebicsUser.ebicsUserId },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runDeactivate] = useDeactivateEbicsUserMutation({
    onCompleted: () => {
      showSuccessMsgOther(`${entity} deaktiviert`);
      onAction();
    },
    variables: { ebicsUserId: ebicsUser.ebicsUserId },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runDelete] = useDeleteEbicsUserMutation({
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onAction();
    },
    variables: { ebicsUserId: ebicsUser.ebicsUserId },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'EBICS Aktivieren',
      onClick: showConfirmActivate(runActivate, ebicsUser),
      icon: <CheckSquareOutlined />,
      disabled: !isEbicsUserStatusInactive(ebicsUser.status.value),
    },
    {
      key: '2',
      label: 'EBICS Deaktivieren',
      onClick: showConfirmDeactivate(runDeactivate, ebicsUser),
      icon: <CloseSquareOutlined />,
      disabled: !isEbicsUserStatusActive(ebicsUser.status.value),
    },
    {
      key: '3',
      label: 'Kontoauszüge importieren',
      onClick: () => handleImportBankstatementPerEbicsUserSubmit(activeForFirmendatenId as string, ebicsUser.ebicsUserId, onAction),
      icon: <ImportOutlined />,
      disabled: !ebicsUser.camtImportEnabled,
    },
    {
      key: '4',
      label: 'Bearbeiten',
      onClick: toggleDrawer,
      disabled: !ebicsUser.editable,
      icon: <EditOutlined />,
    },
    {
      key: '5',
      label: 'Löschen',
      onClick: showConfirmDelete(runDelete, ebicsUser),
      icon: <DeleteOutlined />,
      disabled: !ebicsUser.deletable,
      danger: true,
    },
  ];

  const onActionSuccess = () => {
    toggleDrawer();
    onAction();
  };

  return (
    <>
      <Dropdown menu={{ items }}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Aktion <DownOutlined />
        </a>
      </Dropdown>
      <Drawer title="EBICS Bankteilnehmer bearbeiten" width={720} onClose={toggleDrawer} open={isDrawerOpen} destroyOnClose>
        <EbicsUserForm onCancel={toggleDrawer} onSuccess={onActionSuccess} ebicsUser={ebicsUser} />
      </Drawer>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void, ebicsUser: EbicsUser) => () => {
  Modal.confirm({
    title: `Möchten Sie den Ebics Bankteilnehmer löschen?`,
    content: `${ebicsUser.mitarbeiterName}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

const showConfirmActivate = (runActivate: () => void, ebicsUser: EbicsUser) => () => {
  Modal.confirm({
    title: `Möchten Sie den Ebics Bankteilnehmer aktivieren?`,
    content: `${ebicsUser.mitarbeiterName}`,
    okText: 'Aktivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runActivate();
    },
  });
};

const showConfirmDeactivate = (runDeactivate: () => void, ebicsUser: EbicsUser) => () => {
  Modal.confirm({
    title: `Möchten Sie den Ebics Bankteilnehmer deaktivieren?`,
    content: `${ebicsUser.mitarbeiterName}`,
    okText: 'Deaktivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runDeactivate();
    },
  });
};

export default EbicsUserActions;
