import React from 'react';
import { Link } from 'react-router-dom';
import { StatusEnum } from '../../../../helpers/statusHelper';
import { MitarbeiterTooltip } from '../../../../components/Card';
import StatusBadge from '../../../../components/Status/StatusBadge';
import DataWithStatus from '../../../../components/Helpers/DataWithStatus';
import { Konto, KontoOrderBy } from '../../../../types';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import { EMPTY_VALUE_WITH_MINUS } from '../../../../components/Text/TextForEmptyValue';
import { generatePathToKontoTemplateDetailsPage } from '../../kontoTemplateUriPaths';
import { compareTwoStringsForSorting } from '../../../../helpers/stringHelper';
import KontoTemplateBezug from '../../KontoTemplateDetails/KontoTemplateBezug/KontoTemplateBezug';

const kontoTemplateTableColumns: TableWithColSelectorColumnProps<Konto>[] = [
  {
    title: 'Nummer',
    defaultSelected: true,
    dataIndex: 'nummer',
    sorter: (a, b) => compareTwoStringsForSorting(a.nummer, b.nummer),
    width: 80,
    render: (text, record) => <DataWithStatus text={record.nummer} status={record.status} showBadgeDot={false} showStatusDescription={false} />,
  },
  {
    title: 'Bezeichnung',
    defaultSelected: true,
    dataIndex: 'bezeichnung',
    sorter: (a, b) => compareTwoStringsForSorting(a.bezeichnung, b.bezeichnung),
    width: 150,
    render: (text, record) => <DataWithStatus status={record.status} text={record.bezeichnung} maxTextLength={20} />,
  },
  {
    title: 'Klasse',
    defaultSelected: true,
    dataIndex: 'klasse',
    sorter: (a, b) => compareTwoStringsForSorting(a.klasse.text, b.klasse.text),
    width: 150,
    render: (text, record) => (
      <DataWithStatus text={record.klasse.text} maxTextLength={20} status={record.status} showBadgeDot={false} showStatusDescription={false} />
    ),
  },
  {
    title: 'Typ',
    defaultSelected: true,
    dataIndex: 'type',
    sorter: (a, b) => compareTwoStringsForSorting(a.type.text, b.type.text),
    width: 130,
    render: (text, record) => <DataWithStatus text={record.type.text} status={record.status} showBadgeDot={false} showStatusDescription={false} />,
  },
  {
    title: 'Verwendung',
    defaultSelected: true,
    dataIndex: 'verwendung',
    sorter: (a, b) => compareTwoStringsForSorting(a.verwendung?.text, b.verwendung?.text),
    width: 120,
    render: (text, record) => (
      <DataWithStatus
        text={record.verwendung?.text ?? EMPTY_VALUE_WITH_MINUS}
        status={record.status}
        showBadgeDot={false}
        showStatusDescription={false}
      />
    ),
  },
  {
    title: 'Bezug',
    defaultSelected: true,
    render: (text, record) => <KontoTemplateBezug konto={record} />,
  },
  {
    title: 'Status',
    defaultSelected: true,
    filters: [
      { text: 'Aktiv', value: StatusEnum.Aktiv },
      { text: 'Archiviert', value: StatusEnum.Archiviert },
    ],
    onFilter: (value, record) => record.status.value.includes(value as string),
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },
  {
    title: 'Erstellt am',
    dataIndex: KontoOrderBy.CreateTs,
    sorter: true,
    width: 100,
    render: (text, record) => (
      <DataWithStatus text={record.createTs} status={record.status} showBadgeDot={false} showStatusDescription={false}>
        {() => <FormattedDateTime createdTs={record.createTs} />}
      </DataWithStatus>
    ),
  },
  {
    title: 'Ersteller',
    width: 70,
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => <Link to={generatePathToKontoTemplateDetailsPage(record.kontoId)}>Details</Link>,
  },
];

export default kontoTemplateTableColumns;
