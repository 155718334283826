import { Document, Page, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { TopAbrechnung, TopAbrechnungList } from './topAbrechnung-types';
import pdfStyles from '../styles/pdfStyles';
import TADeckblattBlock from './components/DeckblattBlock/TADeckblattBlock';
import TAAbrechnungskreisDetailsBlock from './components/AbrechnungskreisDetailsBlock/TAAbrechnungskreisDetailsBlock';
import TARepFondsAbrechnungBlock from './components/RepFondsAbrechnungBlock/TARepFondsAbrechnungBlock';
import Header from '../shared/Header';
import { DocumentHeaderAndFooter } from '../shared/types';
import Footer from '../shared/Footer';
import { InfoAbrechnungskreis as TInfoAbrechnungskreis } from '../objektAbrechnung/objektAbrechnung-types';
import InfoAbrechnungskreis from '../shared/Abrechnungskreis/InfoAbrechnungskreis';
import { isNotNil } from '../helpers/assertionHelper';
import TADueDate from './components/TADueDate';
import TARueckstandBlock from './components/TARueckstandBlock';
import TAZahlungsrueckstandOtherPersonsBlock from './components/ZahlungsrueckstandOtherPersonsBlock/TAZahlungsrueckstandOtherPersonsBlock';
import TAZahlungsverlaufBlock from './components/ZahlungsverlaufBlock/TAZahlungsverlaufBlock';

type TopAbrechnungTemplateProps = {
  data: TopAbrechnungList;
  isInEditMode?: boolean;
  selectedTextbaustein?: TopAbrechnungSelectableSection;
};

export type TopAbrechnungSelectableSection = 'EINLEITUNGSTEXT' | 'ERGEBNISTEXT' | 'SCHLUSSTEXT';

const TopAbrechnungTemplate: FC<TopAbrechnungTemplateProps> = ({ data, isInEditMode, selectedTextbaustein }) => (
  <Document>
    {/* TODO: metadata
      following props: should also come from the BE and be specific to the Objekt, Firma, Creator, Keywords (optional)
      * title
      * author
      * subject
      * keywords
      * creator
      * producer
      */}
    {data.topAbrechnungList.map((topAbrechnung, index) => (
      <SingleTopAbrechnung
        key={index}
        data={topAbrechnung}
        footer={data.footer}
        header={data.header}
        infoAbrechnungskreisList={data.infoAbrechnungskreisList}
        isInEditMode={isInEditMode}
        selectedTextbaustein={selectedTextbaustein}
      />
    ))}
  </Document>
);

type SingleTopAbrechnungProps = {
  data: TopAbrechnung;
  header?: DocumentHeaderAndFooter | null;
  footer?: DocumentHeaderAndFooter | null;
  infoAbrechnungskreisList?: TInfoAbrechnungskreis[] | null;
  isInEditMode?: boolean;
  selectedTextbaustein?: TopAbrechnungSelectableSection;
};

const SingleTopAbrechnung: FC<SingleTopAbrechnungProps> = ({
  data,
  isInEditMode,
  selectedTextbaustein,
  header,
  footer,
  infoAbrechnungskreisList,
}) => {
  return (
    <>
      <Page size="A4" style={[pdfStyles.body, { paddingBottom: footer?.value ? '32mm' : pdfStyles.body.paddingBottom }]}>
        <View>
          {/* header */}
          <Header header={header} />
        </View>

        {/* Deckblatt */}
        <TADeckblattBlock data={data} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />

        {/* Footer */}
        {footer?.value && <Footer footer={footer} />}
      </Page>

      <Page size="A4" style={pdfStyles.body}>
        {/* Abrechnungskreisdetails */}
        <TAAbrechnungskreisDetailsBlock data={data} />

        {/* ZahlungsDetails */}
        {/* RueckstandBlock */}
        {isNotNil(data.zahlungsDetails.rueckstandBlock) ? <TARueckstandBlock data={data.zahlungsDetails.rueckstandBlock} /> : null}
        {/* DueDate */}
        {isNotNil(data.zahlungsDetails.dueDate) ? <TADueDate dueDate={data.zahlungsDetails.dueDate} /> : null}
        {/* Zahlungsverlauf  */}
        {isNotNil(data.zahlungsDetails.zahlungsverlaufBlock) ? <TAZahlungsverlaufBlock data={data.zahlungsDetails.zahlungsverlaufBlock} /> : null}
        {/* RueckstandList of other persons */}
        {data.zahlungsDetails.zahlungsrueckstandOtherPersonsBlock &&
        data.zahlungsDetails.zahlungsrueckstandOtherPersonsBlock.zahlungsrueckstandOtherPersonsList.length > 0 ? (
          <TAZahlungsrueckstandOtherPersonsBlock data={data.zahlungsDetails.zahlungsrueckstandOtherPersonsBlock} />
        ) : null}

        {/* Footer */}
        {footer?.value && <Footer footer={footer} />}
      </Page>

      {data.abrechnungskreisDetails.repFondsAbrechnungList?.map((repFondsAbrechnung, index) => (
        <Page size="A4" style={pdfStyles.body} key={index}>
          {/* Rep.Fonds-Abrechnung block */}
          <TARepFondsAbrechnungBlock repFondsAbrechnung={repFondsAbrechnung} />

          {/* Footer */}
          {footer?.value && <Footer footer={footer} />}
        </Page>
      ))}

      {isNotNil(infoAbrechnungskreisList) && infoAbrechnungskreisList.length > 0 && (
        <Page size="A4" style={pdfStyles.body}>
          {infoAbrechnungskreisList.map((abrechKreis, index) => (
            <InfoAbrechnungskreis abrechKreis={abrechKreis} key={index} />
          ))}
        </Page>
      )}
    </>
  );
};

export default TopAbrechnungTemplate;
