import React from 'react';
import { Tag } from 'antd';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';
import { MitarbeiterTooltip } from '../../../components/Card';
import TextbausteinTableActions from './TextbausteinTableActions';
import { compareTwoStringsForSorting } from '../../../helpers/stringHelper';
import { compareTwoDatesForSorting } from '../../../helpers/dateHelper';
import FirmendatenKurzBezeichnung from '../../shared/FirmendatenKurzBezeichnung';
import AuftragsartenZuweisung from './AuftragsartenZuweisung';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { GeneralTextbausteinFieldsFragment } from '../gql/TextbausteinFragments.types';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';

const textbausteinTableColumns = (onAction: () => void): TableWithColSelectorColumnProps<GeneralTextbausteinFieldsFragment>[] => {
  return [
    {
      title: 'Bezeichnung',
      defaultSelected: true,
      sorter: (a, b) => compareTwoStringsForSorting(a.bezeichnung, b.bezeichnung),
      defaultSortOrder: 'ascend',
      render: (text, record) => <DataWithShortenedText maxTextLength={30} text={record.bezeichnung} />,
    },
    {
      title: 'Typ',
      defaultSelected: true,
      sorter: (a, b) => compareTwoStringsForSorting(a.textbaustein.type.text, b.textbaustein.type.text),

      render: (text, record) => <DataWithShortenedText maxTextLength={30} text={record.textbaustein.type.text} />,
    },
    {
      title: 'Text',
      defaultSelected: true,
      render: (text, record) =>
        record.textbaustein.value ? (
          <DataWithShortenedText maxTextLength={30} text={record.textbaustein.value} />
        ) : (
          <TextForEmptyValue textToShow="NV" />
        ),
    },
    {
      title: 'Auftragsarten-Zuweisung',
      defaultSelected: true,
      render: (text, record) =>
        record.auftragsartList.length > 0 ? (
          <AuftragsartenZuweisung auftragsartenZuweisung={record.auftragsartList} />
        ) : (
          <TextForEmptyValue textToShow="minus" />
        ),
    },
    {
      title: 'Firma',
      defaultSelected: true,
      render: (text, record) => (record.firmendatenId ? <FirmendatenKurzBezeichnung firmendatenId={record.firmendatenId} /> : <Tag>System</Tag>),
    },
    {
      title: 'Erstellt am',
      sorter: (a, b) => compareTwoDatesForSorting(a.createTs, b.createTs),
      render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
    },
    {
      title: 'Ersteller',
      render: (text, record) => (
        <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
      ),
    },
    {
      title: 'Aktion',
      defaultSelected: true,
      render: (text, record) => <TextbausteinTableActions textbaustein={record} onAction={onAction} />,
    },
  ];
};

export default textbausteinTableColumns;
