import { FormFields, mapFormDateValueToDateString, TFormattedDecimal } from '../../../../helpers/formikHelper';
import { BestandseinheitCreateInput, NutzungsArt, Objekt } from '../../../../types';

export type BestandseinheitCreateFormValues = {
  addressId: string;
  aktivSeit?: string;
  bezeichnung: string;
  bestandseinheitInsertBeforeId?: string;
  geschoss?: string;
  stiege?: string;
  bauteil?: string;
  nutzungsValidFrom: string;
  nutzflaeche: TFormattedDecimal;
  nutzungsArt: NutzungsArt | null;
  nutzwert: TFormattedDecimal;
  steuerpflichtValidFrom: string;
  steuerpflichtOption: boolean;
  tagList?: string[];
};

export const bestandseinheitCreateFormFields: FormFields<BestandseinheitCreateFormValues> = {
  addressId: 'addressId',
  aktivSeit: 'aktivSeit',
  bauteil: 'bauteil',
  bezeichnung: 'bezeichnung',
  bestandseinheitInsertBeforeId: 'bestandseinheitInsertBeforeId',
  geschoss: 'geschoss',
  nutzflaeche: 'nutzflaeche',
  nutzungsArt: 'nutzungsArt',
  nutzungsValidFrom: 'nutzungsValidFrom',
  nutzwert: 'nutzwert',
  stiege: 'stiege',
  steuerpflichtValidFrom: 'steuerpflichtValidFrom',
  steuerpflichtOption: 'steuerpflichtOption',
  tagList: 'tagList',
};

export const mapObjektForBestandseinheitToFormValues = (selectedAddress: string, objekt: Objekt): BestandseinheitCreateFormValues => {
  return {
    addressId: selectedAddress,
    bezeichnung: '',
    nutzungsValidFrom: objekt.verwaltungSeit,
    nutzflaeche: '',
    nutzwert: '',
    nutzungsArt: null,
    steuerpflichtValidFrom: objekt.verwaltungSeit,
    steuerpflichtOption: false,
    tagList: [],
  };
};

export const mapFormValuesToBestandseinheitCreate = (formValues: BestandseinheitCreateFormValues): BestandseinheitCreateInput => {
  return {
    addressId: formValues.addressId,
    aktivSeit: formValues.aktivSeit ? mapFormDateValueToDateString(formValues.aktivSeit) : undefined,
    bauteil: formValues.bauteil,
    bezeichnung: formValues.bezeichnung,
    bestandseinheitInsertBeforeId: formValues.bestandseinheitInsertBeforeId,
    geschoss: formValues.geschoss,
    nutzung: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      nutzungsArt: formValues.nutzungsArt!,
      validFrom: mapFormDateValueToDateString(formValues.nutzungsValidFrom),
      nutzflaeche: formValues.nutzflaeche || undefined,
      nutzwert: formValues.nutzwert || undefined,
    },
    stiege: formValues.stiege,
    tagList: formValues.tagList,
    optionSteuerpflicht: {
      validFrom: mapFormDateValueToDateString(formValues.steuerpflichtValidFrom),
      optionSteuerpflicht: formValues.steuerpflichtOption ?? false,
    },
  };
};
