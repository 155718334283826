import React from 'react';
import { Typography } from 'antd';
import { ColumnProps } from 'antd/es/table';
import CustomFormattedDate from '../../../../../../components/FormattedDate/CustomFormattedDate';
import KontoWithQuery from '../../../../../../shared/components/Konto/KontoWithQuery';
import { EuroAmount, Percent } from '../../../../../../components/Number';
import BetragBasedOnFirmendatenSettingsAndSollHaben from '../../../../../BookingDetails/View/shared/BuchungszeileListBlock/Columns/BetragBasedOnFirmendatenSettingsAndSollHaben';
import SollHabenTag from '../../../../../shared/SollHabenTag';
import DataWithShortenedTextAndExtraInfo from '../../../../../../components/Helpers/DataWithShortenedTextAndExtraInfo';
import { StandingInvoiceBuchungszeile } from './BookingPreviewTable';

const boookingPreviewTableColumns: ColumnProps<StandingInvoiceBuchungszeile>[] = [
  {
    title: 'Teilbetrag',
    render: (text, record) => <Typography.Text>{record.partialAmountName}</Typography.Text>,
    width: 80,
  },
  {
    title: 'Buchungs-Dat.',
    render: (text, record) => <CustomFormattedDate value={record.buchungsdatum} />,
    width: 100,
  },
  {
    title: 'KtoInfo',
    render: (text, record) => <>{record.kontoId && <KontoWithQuery kontoId={record.kontoId} maxTextLength={15} />}</>,
    width: 220,
  },
  {
    title: 'GKtoNr.',
    render: (text, record) => <Typography.Text>{record.gegenKontoKey?.nummer}</Typography.Text>,
    width: 80,
  },
  {
    title: 'Proz.',
    render: (text, record) => (record.steuersatz || record.steuersatz === 0 ? <Percent value={record.steuersatz / 100} /> : null),
    width: 60,
    align: 'right',
  },
  {
    title: 'Betrag',
    render: (text, record) => <BetragBasedOnFirmendatenSettingsAndSollHaben betrag={record.betrag} sollHaben={record.sollHaben.value} />,
    width: 120,
    align: 'right',
  },
  {
    title: 'S/H',
    render: (text, record) => <SollHabenTag sollHaben={record.sollHaben} />,
    width: 70,
  },
  {
    title: 'Steuer',
    render: (text, record) => (record.steuer ? <EuroAmount value={record.steuer} /> : null),
    width: 120,
    align: 'right',
  },
  {
    title: 'Text',
    render: (text, record) => <DataWithShortenedTextAndExtraInfo maxTextLength={15} text={record.text} />,
  },
];

export default boookingPreviewTableColumns;
