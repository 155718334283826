import React, { FC, useRef, useState } from 'react';
import { Skeleton } from 'antd';
import { FormikProps } from 'formik';
import { useTicketQuery } from '../../gql/TicketQueries.types';
import FormButtons from '../../../../components/Button/FormButtons';
import TicketAttachmentsForm from '../TicketAttachmentsForm/TicketAttachmentsForm';
import { mapFormValuesToTicketAttachment, TicketAttachmentsFormValues } from '../TicketAttachmentsForm/ticketAttachmentsFormMapper';
import { showSuccessMsgUpdate } from '../../../../components/message';
import { useOnTicketDetailsDataChangedEvents } from '../../useOnTicketDataChangedEvents';
import { handleSyncAttachments } from '../TicketAttachmentsForm/handleSyncAttachments';
import { useFDAuthorized } from '../../../../shared/Auth/useAuthorized';

type Props = {
  ticketId: string;
  onSuccess: () => void;
  onCancel: () => void;
};

const TicketAttachmentsUpdateForm: FC<Props> = ({ ticketId, onSuccess, onCancel }) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data, loading, refetch } = useTicketQuery({ variables: { ticketId: ticketId! }, skip: !ticketId });
  const [isLoading, setIsLoading] = useState(false);

  const ticket = data?.getTicket.data;
  const attachmentsFormikRef = useRef<FormikProps<TicketAttachmentsFormValues>>(null);

  useOnTicketDetailsDataChangedEvents('ticket-attachments', refetch, ticketId);

  const handleSubmit = () => {
    setIsLoading(true);
    const syncAttachments = () => {
      if (!(attachmentsFormikRef.current?.values.newAttachmentList.length || attachmentsFormikRef.current?.values.deletableIds.length))
        return Promise.resolve();

      return handleSyncAttachments(
        firmendatenId,
        ticketId,
        attachmentsFormikRef.current.values,
        mapFormValuesToTicketAttachment(attachmentsFormikRef.current.values)
      );
    };

    syncAttachments()
      .then(() => {
        showSuccessMsgUpdate('Anhänge');
        onSuccess();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Skeleton active paragraph={{ rows: 10 }} loading={loading}>
      {ticket && (
        <>
          <TicketAttachmentsForm attachmentList={ticket.attachments} ref={attachmentsFormikRef} />
          <FormButtons updateMode onCancel={onCancel} isOkDisabled={isLoading} isOkLoading={loading || isLoading} onOk={handleSubmit} />
        </>
      )}
    </Skeleton>
  );
};

export default TicketAttachmentsUpdateForm;
