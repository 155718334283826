import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRepFondsErloesKontenZuweisungMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  request: Types.RepFondsErloesKontenZuweisungInput;
}>;

export type CreateRepFondsErloesKontenZuweisungMutation = {
  createRepFondsErloesKontenZuweisung: {
    data: { repFondsErloesKontenZuweisungId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateRepFondsErloesKontenZuweisungMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  repFondsErloesKontenZuweisungId: Types.Scalars['ID']['input'];
  request: Types.RepFondsErloesKontenZuweisungInput;
}>;

export type UpdateRepFondsErloesKontenZuweisungMutation = {
  updateRepFondsErloesKontenZuweisung: {
    data: { repFondsErloesKontenZuweisungId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteRepFondsErloesKontenZuweisungMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  repFondsErloesKontenZuweisungId: Types.Scalars['ID']['input'];
}>;

export type DeleteRepFondsErloesKontenZuweisungMutation = {
  deleteRepFondsErloesKontenZuweisung: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateRepFondsErloesKontenZuweisungDocument = gql`
  mutation CreateRepFondsErloesKontenZuweisung($rechtstraegerId: ID!, $request: RepFondsErloesKontenZuweisungInput!) {
    createRepFondsErloesKontenZuweisung(rechtstraegerId: $rechtstraegerId, request: $request) {
      data {
        repFondsErloesKontenZuweisungId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateRepFondsErloesKontenZuweisungMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRepFondsErloesKontenZuweisungMutation, CreateRepFondsErloesKontenZuweisungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRepFondsErloesKontenZuweisungMutation, CreateRepFondsErloesKontenZuweisungMutationVariables>(
    CreateRepFondsErloesKontenZuweisungDocument,
    options
  );
}
export type CreateRepFondsErloesKontenZuweisungMutationHookResult = ReturnType<typeof useCreateRepFondsErloesKontenZuweisungMutation>;
export type CreateRepFondsErloesKontenZuweisungMutationResult = Apollo.MutationResult<CreateRepFondsErloesKontenZuweisungMutation>;
export type CreateRepFondsErloesKontenZuweisungMutationOptions = Apollo.BaseMutationOptions<
  CreateRepFondsErloesKontenZuweisungMutation,
  CreateRepFondsErloesKontenZuweisungMutationVariables
>;
export const UpdateRepFondsErloesKontenZuweisungDocument = gql`
  mutation UpdateRepFondsErloesKontenZuweisung(
    $rechtstraegerId: ID!
    $repFondsErloesKontenZuweisungId: ID!
    $request: RepFondsErloesKontenZuweisungInput!
  ) {
    updateRepFondsErloesKontenZuweisung(
      rechtstraegerId: $rechtstraegerId
      repFondsErloesKontenZuweisungId: $repFondsErloesKontenZuweisungId
      request: $request
    ) {
      data {
        repFondsErloesKontenZuweisungId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateRepFondsErloesKontenZuweisungMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRepFondsErloesKontenZuweisungMutation, UpdateRepFondsErloesKontenZuweisungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRepFondsErloesKontenZuweisungMutation, UpdateRepFondsErloesKontenZuweisungMutationVariables>(
    UpdateRepFondsErloesKontenZuweisungDocument,
    options
  );
}
export type UpdateRepFondsErloesKontenZuweisungMutationHookResult = ReturnType<typeof useUpdateRepFondsErloesKontenZuweisungMutation>;
export type UpdateRepFondsErloesKontenZuweisungMutationResult = Apollo.MutationResult<UpdateRepFondsErloesKontenZuweisungMutation>;
export type UpdateRepFondsErloesKontenZuweisungMutationOptions = Apollo.BaseMutationOptions<
  UpdateRepFondsErloesKontenZuweisungMutation,
  UpdateRepFondsErloesKontenZuweisungMutationVariables
>;
export const DeleteRepFondsErloesKontenZuweisungDocument = gql`
  mutation DeleteRepFondsErloesKontenZuweisung($rechtstraegerId: ID!, $repFondsErloesKontenZuweisungId: ID!) {
    deleteRepFondsErloesKontenZuweisung(rechtstraegerId: $rechtstraegerId, repFondsErloesKontenZuweisungId: $repFondsErloesKontenZuweisungId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteRepFondsErloesKontenZuweisungMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRepFondsErloesKontenZuweisungMutation, DeleteRepFondsErloesKontenZuweisungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRepFondsErloesKontenZuweisungMutation, DeleteRepFondsErloesKontenZuweisungMutationVariables>(
    DeleteRepFondsErloesKontenZuweisungDocument,
    options
  );
}
export type DeleteRepFondsErloesKontenZuweisungMutationHookResult = ReturnType<typeof useDeleteRepFondsErloesKontenZuweisungMutation>;
export type DeleteRepFondsErloesKontenZuweisungMutationResult = Apollo.MutationResult<DeleteRepFondsErloesKontenZuweisungMutation>;
export type DeleteRepFondsErloesKontenZuweisungMutationOptions = Apollo.BaseMutationOptions<
  DeleteRepFondsErloesKontenZuweisungMutation,
  DeleteRepFondsErloesKontenZuweisungMutationVariables
>;
