import React, { FC } from 'react';
import { View, Text } from '@react-pdf/renderer';
import { MahnungBlock } from '../../mahnung-types';
import pdfStyles from '../../../styles/pdfStyles';

const Summary: FC<{ data: MahnungBlock }> = ({ data }) => (
  <View style={[pdfStyles.column, pdfStyles.borderBottomSmall, { marginRight: '23mm', marginLeft: '40%', marginTop: '2mm' }]}>
    <View style={pdfStyles.row}>
      <Text style={pdfStyles.textNormal}>{data.zwischenSumme.text}</Text>
      <Text style={pdfStyles.textNormal}>{data.zwischenSumme.value}</Text>
    </View>
    <View style={pdfStyles.row}>
      <Text style={pdfStyles.textNormal}>{data.verzugszinsen.text}</Text>
      <Text style={pdfStyles.textNormal}>{data.verzugszinsen.value}</Text>
    </View>
    <View style={pdfStyles.row}>
      <Text style={pdfStyles.textNormal}>{data.mahngebuehr.text}</Text>
      <Text style={pdfStyles.textNormal}>{data.mahngebuehr.value}</Text>
    </View>
    <View style={pdfStyles.row}>
      <Text style={pdfStyles.textNormal}>{data.offenerSaldo.text}</Text>
      <Text style={pdfStyles.textNormal}>{data.offenerSaldo.value}</Text>
    </View>
  </View>
);

export default Summary;
