import React, { FC } from 'react';
import { FieldArray, FormikProps } from 'formik';
import { Button, Typography } from 'antd';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../../../components/Button/ButtonsAligned';
import RepFondsTemplateKontenZuweisungTable from '../../Table/Edit/RepFondsTemplateKontenZuweisungTable';
import FieldHelpIconWithTooltip from '../../../../../components/FieldHelpIcon/FieldHelpIconWithTooltip';
import {
  repFondsKontenZuordnungVersionFormFields,
  repFondsKontenZuweisungFormInitialValues,
  RepFondsKontenZuweisungVersionFormValues,
} from '../../../../ReparaturFonds/KontenZuweisung/Version/Form/repFondsKontenZuweisungVersionFormMapper';

type Props = {
  formikProps: FormikProps<RepFondsKontenZuweisungVersionFormValues>;
  fieldHelp?: string | null;
};

const RepFondsTemplateKontenZuweisungListingForm: FC<Props> = ({ formikProps, fieldHelp }) => (
  <FieldArray
    name={repFondsKontenZuordnungVersionFormFields.kontenZuweisungList}
    render={(arrayHelpers) => {
      const onDelete = (index: number) => {
        arrayHelpers.remove(index);
      };
      return (
        <>
          <Typography.Title level={5}>
            Kontenzuweisungen
            <FieldHelpIconWithTooltip fieldHelp={fieldHelp} />
          </Typography.Title>
          <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
            <Button
              onClick={() => {
                arrayHelpers.push({ ...repFondsKontenZuweisungFormInitialValues });
              }}
            >
              Zuweisung hinzufügen
            </Button>
          </ButtonsAligned>
          <RepFondsTemplateKontenZuweisungTable formikProps={formikProps} onDelete={onDelete} />
        </>
      );
    }}
  />
);

export default RepFondsTemplateKontenZuweisungListingForm;
