import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../styles/pdfStyles';
import { ZVorschreibungspositionHeaderText } from '../../zinsliste-types';
import { zVposColumnWidthsAndAlignment } from '../styles/zVPos-table-styles';

type Props = {
  vorschreibungspositionText: ZVorschreibungspositionHeaderText;
  width: string;
};

const ZVPosTableHeader: FC<Props> = ({ vorschreibungspositionText, width }) => (
  <View style={[pdfStyles.row, pdfStyles.borderBottomSmall, { width }]}>
    <Text style={[pdfStyles.textBulletin, { ...zVposColumnWidthsAndAlignment.netto }]}>{vorschreibungspositionText.nettoText}</Text>
    <Text style={[pdfStyles.textBulletin, { ...zVposColumnWidthsAndAlignment.ustSatz }]}>{vorschreibungspositionText.ustSatzText}</Text>
    <Text style={[pdfStyles.textBulletin, { ...zVposColumnWidthsAndAlignment.ustBetrag }]}>{vorschreibungspositionText.ustBetragText}</Text>
    <Text style={[pdfStyles.textBulletin, { ...zVposColumnWidthsAndAlignment.brutto }]}>{vorschreibungspositionText.bruttoText}</Text>
  </View>
);

export default ZVPosTableHeader;
