import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import { EbicsUserFieldsFragmentDoc } from '../../../../PaymentTransaction/EbicsUser/gql/EbicsUserFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SyncEbicsUserGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type SyncEbicsUserGenerierlaufQuery = {
  getSyncEbicsUserGenerierlauf: {
    data: {
      countFehler: number;
      countNichtVerarbeitet: number;
      countVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierlaufId: string;
      generierDatum: string;
      entryList: Array<{
        generierlaufEntryId: string;
        infoMessage?: string | null;
        ebicsUser: {
          bankname: string;
          camtImportEnabled: boolean;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable: boolean;
          ebicsBankConfigId: string;
          ebicsUserId: string;
          editable: boolean;
          initializationDocumentFileId?: string | null;
          mitarbeiterId: string;
          mitarbeiterName: string;
          name?: string | null;
          partnerId: string;
          password: string;
          userId: string;
          orderTypeList: Array<{ description?: string | null; text: string; value: string }>;
          status: { text: string; value: Types.EbicsUserStatus };
        };
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { text: string; value: Types.GenerierlaufEntryExitCode } | null;
        warningList: Array<{ message: string; type: string }>;
      }>;
      errorList: Array<{ message: string; type: string }>;
      status: { text: string; value: Types.GenerierlaufStatus };
      warningList: Array<{ message: string; type: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const SyncEbicsUserGenerierlaufDocument = gql`
  query SyncEbicsUserGenerierlauf($generierlaufId: ID!) {
    getSyncEbicsUserGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countNichtVerarbeitet
        countVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        entryList {
          ebicsUser {
            ...EbicsUserFields
          }
          errorList {
            message
            type
          }
          exitCode {
            text
            value
          }
          generierlaufEntryId
          infoMessage
          warningList {
            message
            type
          }
        }
        errorList {
          message
          type
        }
        generierlaufId
        generierDatum
        status {
          text
          value
        }
        warningList {
          message
          type
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${EbicsUserFieldsFragmentDoc}
`;
export function useSyncEbicsUserGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<SyncEbicsUserGenerierlaufQuery, SyncEbicsUserGenerierlaufQueryVariables> &
    ({ variables: SyncEbicsUserGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SyncEbicsUserGenerierlaufQuery, SyncEbicsUserGenerierlaufQueryVariables>(SyncEbicsUserGenerierlaufDocument, options);
}
export function useSyncEbicsUserGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SyncEbicsUserGenerierlaufQuery, SyncEbicsUserGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SyncEbicsUserGenerierlaufQuery, SyncEbicsUserGenerierlaufQueryVariables>(SyncEbicsUserGenerierlaufDocument, options);
}
export function useSyncEbicsUserGenerierlaufSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SyncEbicsUserGenerierlaufQuery, SyncEbicsUserGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SyncEbicsUserGenerierlaufQuery, SyncEbicsUserGenerierlaufQueryVariables>(SyncEbicsUserGenerierlaufDocument, options);
}
export type SyncEbicsUserGenerierlaufQueryHookResult = ReturnType<typeof useSyncEbicsUserGenerierlaufQuery>;
export type SyncEbicsUserGenerierlaufLazyQueryHookResult = ReturnType<typeof useSyncEbicsUserGenerierlaufLazyQuery>;
export type SyncEbicsUserGenerierlaufSuspenseQueryHookResult = ReturnType<typeof useSyncEbicsUserGenerierlaufSuspenseQuery>;
export type SyncEbicsUserGenerierlaufQueryResult = Apollo.QueryResult<SyncEbicsUserGenerierlaufQuery, SyncEbicsUserGenerierlaufQueryVariables>;
