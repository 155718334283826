import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { EmailDeliveryDefinitionType } from '../../../../types';
import { CreateFormValues, createFormFields, createFormInitialValues } from './createFormMapper';
import { createFormValidationSchema } from './createFormValidationSchema';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import EmailDeliveryDefinitionSelect from '../../../EmailDeliveryDefinition/Select/EmailDeliveryDefinitionSelect';
import FormButtons from '../../../../components/Button/FormButtons';
import { useCreateVorschreibungMailDeliveryWizardMutation } from '../gql/VorschreibungMailDeliveryWizardMutations.types';
import { EmailDeliveryDefinitionListQuery } from '../../../EmailDeliveryDefinition/gql/EmailDeliveryDefinitionQueries.types';
import { generatePathToWizardUpdatePage } from '../../wizardApiPaths';
import { wizardTypes } from '../../wizardHelpers';

type Props = {
  onCancel: () => void;
};

const CreateForm: FC<Props> = ({ onCancel }) => {
  const navigate = useNavigate();

  const [runCreate] = useCreateVorschreibungMailDeliveryWizardMutation();

  return (
    <Formik<CreateFormValues>
      initialValues={createFormInitialValues}
      validationSchema={createFormValidationSchema}
      onSubmit={(formValues) => {
        runCreate({
          variables: {
            emailDeliveryDefinitionId: formValues.emailDeliveryDefinitionId,
          },
        }).then((result) => {
          const { data } = result;
          const wizardId = data?.createVorschreibungMailDeliveryWizard.data.wizardId;

          if (!wizardId) return;
          navigate(generatePathToWizardUpdatePage(wizardTypes.VorschreibungMailDelivery, wizardId));
        });
      }}
    >
      {(formikProps) => {
        const onEmailDeliveryDefinitionSelectReady = (data: EmailDeliveryDefinitionListQuery) => {
          const emailDeliveryDefList = data.getEmailDeliveryDefinitionList.data ?? [];
          if (!emailDeliveryDefList.length) return;
          formikProps.setFieldValue(createFormFields.emailDeliveryDefinitionId, emailDeliveryDefList?.[0]?.emailDeliveryDefinitionId);
          if (emailDeliveryDefList.length > 1) return;
          formikProps.submitForm();
        };

        return (
          <Form layout="vertical">
            <FormItemWithoutColon name={createFormFields.emailDeliveryDefinitionId} label="E-Mail Versanddefinition">
              <EmailDeliveryDefinitionSelect
                name={createFormFields.emailDeliveryDefinitionId}
                type={EmailDeliveryDefinitionType.Vorschreibung}
                onReady={onEmailDeliveryDefinitionSelectReady}
              />
            </FormItemWithoutColon>
            <FormButtons okText="Weiter" updateMode={false} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateForm;
