import { TicketListQueryVariables, useTicketListQuery } from './gql/TicketQueries.types';
import { useQueryWithPagingAndSorting } from '../../hooks/useQueryWithPagingAndSorting';
import { TicketStatus } from '../../types';

const useTicketCount = (queryVariables: TicketListQueryVariables) => {
  const { data: ticketListData, refetch: refetchTicketCount } = useQueryWithPagingAndSorting(useTicketListQuery, {
    variables: { ...queryVariables, status: [TicketStatus.ToDo, TicketStatus.InProgress] },
  });
  return { ticketCount: ticketListData?.getTicketList.data.page.totalElements, refetchTicketCount };
};

export default useTicketCount;
