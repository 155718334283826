import React, { FC, useState } from 'react';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import { Typography } from 'antd';
import {
  emailTemplateStepFormFields,
  EmailTemplateStepFormValues,
  mapFormValuesIntoTemplateStepInput,
  mapTemplateStepToFormValues,
} from './emailTemplateStepFormMapper';
import EmailDeliveryTextbausteinFormItem from '../../../../EmailDeliveryDefinition/Form/EmailDeliveryTextbausteinFormItem';
import AttachmentPart from './AttachmentPart';
import { handleUpdateInfoSubmit } from './handleSubmit';
import { useFDAuthorized } from '../../../../../shared/Auth/useAuthorized';
import { emailTemplateStepFormValidationSchema } from './emailTemplateStepFormValidationSchema';
import EmailDeliveryDefinitionTextOrLink from '../../../../EmailDelivery/EmailDeliveryDefinitionTextOrLink';
import StepButtons from '../../../Shared/StepButtons';
import { useDeleteInfoMailDeliveryWizardMutation } from '../../gql/InfoMailDeliveryWizardMutations.types';
import { showSuccessMsgDelete } from '../../../../../components/message';
import confirmDeleteWizard from '../../../Shared/confirmDeleteWizard';
import { WizardFileInfo } from '../../../../../types';
import { useGetAppFieldHelpText } from '../../../../FieldHelp/useGetFieldHelpText';
import { StyledWizardSpaceForContent, StyledWizardSpaceForTextArea } from '../../../../shared/styled/WizardSpace.style';

type Props = {
  attachmentInfoList: WizardFileInfo[];
  emailDeliveryDefinitionBezeichnung: string;
  emailDeliveryDefinitionId: string;
  formData: EmailTemplateStepFormValues;
  isCompleted?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  wizardId: string;
  loading?: boolean;
};

const EmailTemplateStepForm: FC<Props> = ({
  emailDeliveryDefinitionBezeichnung,
  emailDeliveryDefinitionId,
  formData,
  isCompleted,
  onCancel,
  onSubmit,
  wizardId,
  attachmentInfoList,
  loading,
}) => {
  const getFieldHelpText = useGetAppFieldHelpText<'InfoMailDeliveryWizardTemplateStep'>('InfoMailDeliveryWizardTemplateStep');

  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  const [inProgress, setInProgress] = useState(loading);
  const [runDelete] = useDeleteInfoMailDeliveryWizardMutation();

  return (
    <>
      <Formik<EmailTemplateStepFormValues>
        validationSchema={emailTemplateStepFormValidationSchema}
        initialValues={mapTemplateStepToFormValues(formData)}
        onSubmit={(values, { setSubmitting }) => {
          setInProgress(true);
          const input = mapFormValuesIntoTemplateStepInput(values, true);
          handleUpdateInfoSubmit(firmendatenId, wizardId, values, input, setSubmitting, onSubmit).finally(() => {
            setInProgress(false);
          });
        }}
      >
        {(formikProps) => {
          const onSave = () => {
            // TODO: EC-7366 CR: use formikProps.validate instead of errors.subject?
            if (isCompleted || formikProps.errors.subject || formikProps.isSubmitting) return;
            setInProgress(true);
            const input = mapFormValuesIntoTemplateStepInput(formikProps.values, false);
            handleUpdateInfoSubmit(firmendatenId, wizardId, formikProps.values, input, formikProps.setSubmitting, onCancel).finally(() => {
              setInProgress(false);
            });
          };
          const onNext = () => {
            // TODO: EC-7366 CR: I don't think we have to check the validity because submitForm should do it for us
            if (formikProps.errors.subject || formikProps.isSubmitting) return;
            if (isCompleted) {
              onSubmit();
              return;
            }
            formikProps.submitForm();
          };
          const onDelete = () => {
            if (isCompleted) return;
            confirmDeleteWizard(() => {
              setInProgress(true);
              runDelete({ variables: { infoMailDeliveryWizardId: wizardId } })
                .then(() => {
                  showSuccessMsgDelete('Assistent');
                  onCancel();
                })
                .finally(() => {
                  setInProgress(false);
                });
            });
          };
          return (
            <Form>
              <StyledWizardSpaceForContent direction="vertical">
                <StyledWizardSpaceForTextArea align="start" size={0}>
                  <Typography.Text>Texte aus Vorlage</Typography.Text>
                  <EmailDeliveryDefinitionTextOrLink
                    bezeichnung={emailDeliveryDefinitionBezeichnung}
                    emailDeliveryDefinitionId={emailDeliveryDefinitionId}
                  />
                </StyledWizardSpaceForTextArea>
                <EmailDeliveryTextbausteinFormItem
                  disabled={isCompleted || formikProps.isSubmitting}
                  name={emailTemplateStepFormFields.subject}
                  id={emailTemplateStepFormFields.subject}
                  fieldHelp={getFieldHelpText('InfoMailDeliveryWizardTemplateStep.subject.value')}
                  label="E-Mail Betreff"
                />
                <EmailDeliveryTextbausteinFormItem
                  disabled={isCompleted || formikProps.isSubmitting}
                  name={emailTemplateStepFormFields.body}
                  id={emailTemplateStepFormFields.body}
                  fieldHelp={getFieldHelpText('InfoMailDeliveryWizardTemplateStep.body.value')}
                  label="Text Körper"
                />
                <AttachmentPart
                  attachmentInfoList={attachmentInfoList}
                  disabled={isCompleted || formikProps.isSubmitting}
                  formikProps={formikProps}
                />
              </StyledWizardSpaceForContent>

              <StepButtons
                isBackHidden
                isDropdownDisabled={inProgress}
                isDropdownHidden={isCompleted}
                onNext={onNext}
                isNextDisabled={inProgress}
                onCancel={onCancel}
                onSave={onSave}
                onDelete={onDelete}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default EmailTemplateStepForm;
