import React from 'react';
import { Tag, Typography } from 'antd';
import { Input } from 'formik-antd';
import { EditableTableColumnProps } from '../../../../components/Table/EditableTableOld/EditableTableProps';
import { fieldHelpFormFields, FieldHelpFormValues } from './fieldHelpFormMapper';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import ActionButtonsInEditMode from '../../../../components/Table/EditableTableOld/ActionButtonsInEditMode';
import FieldHelpTableActions from './FieldHelpTableActions';
import { FieldHelpService, FieldHelpWithService } from '../../useFieldHelpList';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { compareTwoStringsForSorting } from '../../../../helpers/stringHelper';
import { compareTwoBooleansForSorting } from '../../../../helpers/booleanHelper';

const fieldHelpTableColumns = (onAction: () => void): EditableTableColumnProps<FieldHelpWithService, FieldHelpFormValues>[] => [
  {
    title: 'Nicht mehr in Verwendung',
    key: 'deprecated',
    defaultSelected: true,
    sorter: (a, b) => compareTwoBooleansForSorting(a.deprecated, b.deprecated),
    width: 160,
    render: (text, record) => <Typography.Text>{record.deprecated ? 'Ja' : 'Nein'}</Typography.Text>,
  },
  {
    title: 'Feld',
    key: 'selector',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.selector, b.selector),
    editable: true,
    defaultSortOrder: 'ascend',
    render: (text, record) => <Typography.Text>{record.selector}</Typography.Text>,
    renderInEditMode: () => (
      <FormItemWithoutColon name={fieldHelpFormFields.selector} style={{ margin: 0 }}>
        <Input name={fieldHelpFormFields.selector} placeholder="Feld eingeben" />
      </FormItemWithoutColon>
    ),
  },
  {
    title: 'Feldhilfe',
    key: 'fieldHelp',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.fieldHelp, b.fieldHelp),
    editable: true,
    render: (text, record) => <Typography.Text>{record.fieldHelp}</Typography.Text>,
    renderInEditMode: () => (
      <FormItemWithoutColon name={fieldHelpFormFields.fieldHelp} style={{ margin: 0 }}>
        <Input.TextArea name={fieldHelpFormFields.fieldHelp} placeholder="Feldhilfe eingeben" />
      </FormItemWithoutColon>
    ),
  },
  {
    title: 'Service',
    width: 120,
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.service, b.service),
    render: (text, record) => {
      const service = Object.entries(FieldHelpService).find(([, value]) => value === record.service);
      if (!service) return <TextForEmptyValue textToShow="minus" />;
      const [key] = service;
      return <Tag>{key}</Tag>;
    },
  },
  {
    title: 'Aktion',
    width: 120,
    defaultSelected: true,
    editable: true,
    render: (text, record) => <FieldHelpTableActions service={record.service} fieldHelp={record} onAction={onAction} />,
    renderInEditMode: ({ cancelEdit, isSubmitLoading }) => <ActionButtonsInEditMode cancelEdit={cancelEdit} isSubmitLoading={isSubmitLoading} />,
  },
];

export default fieldHelpTableColumns;
