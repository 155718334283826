import React, { PropsWithChildren, useCallback, useEffect, useReducer } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';
import { ApolloClient, useApolloClient } from '@apollo/client';
import createCtx from '../../helpers/createCtx';
import { callLogout } from './loginApi';
import { UserRole } from '../../types';
import Fetcher from '../../helpers/fetcher';
import { useAuthMitarbeiterMeQuery, useLoginMitarbeiterListQuery, useMeQuery } from './gql/AuthQueries.types';
import { authReducer } from './authReducer';
import { AuthAction, AuthStateStatus } from './auth.model';
import LoadingFullPage from '../../components/Loading/LoadingFullPage';
import { onRemoveImportBankstatementFromLocaleStorage } from '../../features/Notification/NotificationArea/ImportBankStatementFileUpload/fileUploadHelpers';

export type User = {
  email?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  role: UserRole;
  username: string;
};

export type Mitarbeiter = {
  firmendatenId: string;
  funktionsModulList: string[];
  mitarbeiterId: string;
  rolleList: { name: string; rolleId: string }[];
};

export type LogoutFn = (reason: 'user_logout' | 'unauthenticated') => void;

export type AuthContextType = {
  user?: User;
  reloadUser: () => void;
  mitarbeiter?: Mitarbeiter;
  activeForFirmendaten?: ActiveForFirmendaten;
  activeForFirmendatenId: string | null;
  authState: AuthStateStatus;
  dispatch: (action: AuthAction) => void;
  logout: LogoutFn;
  apolloClient?: ApolloClient<object>;
};

export type ActiveForFirmendaten = {
  firmendatenId: string;
  kundenSystemId: string;
};

const [useAuth, AuthContextProvider, AuthContext] = createCtx<AuthContextType>();

const useInitUserAndMitarbeiter = () => {
  const [firmendatenFromLocalStorage, saveFirmendatenToLocalStorage, removeFirmendatenFromLocalStorage] =
    useLocalStorage<ActiveForFirmendaten>('activeForFirmendaten');
  const [{ user, mitarbeiter, activeForFirmendaten, authState }, dispatch] = useReducer(authReducer, {
    authState: 'PENDING',
    activeForFirmendaten: firmendatenFromLocalStorage ?? undefined,
  });
  const apolloClient = useApolloClient();

  // sync with local storage
  useEffect(() => {
    if (activeForFirmendaten) {
      saveFirmendatenToLocalStorage(activeForFirmendaten);
    } else {
      removeFirmendatenFromLocalStorage();
      onRemoveImportBankstatementFromLocaleStorage();
    }
  }, [activeForFirmendaten, saveFirmendatenToLocalStorage, removeFirmendatenFromLocalStorage]);

  const logout = useCallback<LogoutFn>(
    (reason) => {
      callLogout().finally(() => {
        dispatch({ type: 'CLEAR_STATE' });
        // FIXME test it!
        dispatch({ type: 'RESET_APP_STATE' });
        Fetcher.getInstance().firmendatenId = undefined;
        apolloClient.clearStore();
      });
    },
    [apolloClient]
  );

  const { loading: isUserLoading, refetch } = useMeQuery({
    onCompleted: (data) => dispatch({ type: 'LOAD_USER_SUCCESS', payload: data.getMe.data }),
    // calling only clearState without calling logout because if this query does not work user is unauthorized anyway, no need for extra logout
    onError: () => dispatch({ type: 'CLEAR_STATE' }),
    // https://github.com/apollographql/react-apollo/issues/3571
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  useLoginMitarbeiterListQuery({
    onCompleted: (data) => {
      // TODO http://jira.andromeda.intern/browse/EC-3507
      if (data) dispatch({ type: 'LOAD_MITARBEITER_LIST_SUCCESS', payload: data.getOwnMitarbeiterList.data });
    },
    onError: () => logout('unauthenticated'),
    // skip if no user available yet or ...
    // user is ANDRO_ADMIN => they don't have mitarbeiter or ...
    // a firma has been already selected => then we only have to load the mitarbeiterData to the selected firma
    skip: !user || isUserLoading || user.role === UserRole.Admin || !!firmendatenFromLocalStorage?.firmendatenId,
  });

  useAuthMitarbeiterMeQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain,@typescript-eslint/no-non-null-assertion
    variables: { firmendatenId: firmendatenFromLocalStorage?.firmendatenId! },
    onCompleted: (data) => {
      // TODO http://jira.andromeda.intern/browse/EC-3507
      if (data) {
        dispatch({ type: 'LOAD_MITARBEITER_SUCCESS', payload: data.getAuthMitarbeiterMe.data });
      }
    },
    onError: () => logout('unauthenticated'),
    // skip if no user available yet or ...
    // user is ANDRO_ADMIN => they don't have mitarbeiter or ...
    // a firma has not yet been selected => we have to know to which firma the mitarbeiter belongs in advance
    skip: !user || isUserLoading || user.role === UserRole.Admin || !firmendatenFromLocalStorage?.firmendatenId,
  });

  return {
    user,
    mitarbeiter,
    activeForFirmendaten,
    reloadUser: refetch,
    authState,
    dispatch,
    apolloClient,
    logout,
  };
};

// Motivation for these context module functions: https://github.com/kentcdodds/advanced-react-patterns/blob/master/src/exercise/01.md
// (original: https://twitter.com/dan_abramov/status/1125773153584676864)
export const clearActiveForFirmendaten = (authDispatch: React.Dispatch<AuthAction>) => authDispatch({ type: 'CLEAR_ACTIVE_FOR_FIRMENDATEN' });
export const setActiveForFirmendaten = (authDispatch: React.Dispatch<AuthAction>, firmendaten: ActiveForFirmendaten) => {
  authDispatch({ type: 'SET_ACTIVE_FOR_FIRMENDATEN', payload: firmendaten });
};

const AuthProvider = (props: PropsWithChildren<unknown>) => {
  const { user, mitarbeiter, activeForFirmendaten, reloadUser, authState, dispatch, logout, apolloClient } = useInitUserAndMitarbeiter();

  if (authState === 'PENDING') {
    return <LoadingFullPage />;
  }

  return (
    <AuthContextProvider
      value={{
        user,
        reloadUser,
        mitarbeiter,
        activeForFirmendaten,
        activeForFirmendatenId: activeForFirmendaten?.firmendatenId ?? null,
        authState,
        dispatch,
        apolloClient,
        logout,
      }}
      {...props}
    />
  );
};

export { AuthProvider, useAuth, AuthContextProvider, AuthContext };
