import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { Typography } from 'antd';
import { selectFilterOption } from '../../helpers/selectHelper';
import { usePaymentMethodListQuery } from './gql/PaymentMethodQueries.types';

type Props = { selection?: boolean | null } & SelectProps;

const PaymentMethodListFormSelect: FC<Props> = ({ selection, ...restProps }) => {
  const { data, loading } = usePaymentMethodListQuery({ variables: { selection } });
  const list = data?.getPaymentMethodList.data || [];

  return (
    <Select
      id={restProps.name}
      loading={loading}
      size="middle"
      placeholder="Zahlungsart auswählen"
      showSearch
      filterOption={selectFilterOption}
      allowClear
      {...restProps}
    >
      {list.map((paymentMethod) => (
        <Select.Option key={paymentMethod.value} value={paymentMethod.value}>
          <Typography.Text>{paymentMethod.text}</Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export default PaymentMethodListFormSelect;
