import React, { FC } from 'react';
import { Empty } from 'antd';
import auftragsartTableColumns from './auftragsartTableColumns';
import { useAuftragsartListQuery } from '../gql/AuftragsartQueries.types';
import TableWithColSelector from '../../../components/Table/TableWithColSelector/TableWithColSelector';
import { AuftragsartBase } from '../../../types';

type Props = {
  showArchived: boolean;
};

const AuftragsartTable: FC<Props> = ({ showArchived }) => {
  const { data, loading, refetch } = useAuftragsartListQuery({
    variables: {
      includeArchiviert: showArchived,
      withDetails: true,
    },
  });
  const auftragsartList = data?.getAuftragsartList.data;

  return (
    <TableWithColSelector<AuftragsartBase>
      size="small"
      locale={{ emptyText: <Empty description={<span>Keine Auftragsarten vorhanden</span>} /> }}
      loading={loading}
      dataSource={auftragsartList}
      columns={auftragsartTableColumns(refetch)}
      rowKey={(record) => record.auftragsartId}
      filterIdentifier="sys-settings-auftragsart"
    />
  );
};

export default AuftragsartTable;
