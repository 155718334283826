import React, { FC } from 'react';
import { Empty } from 'antd';
import { TBookingSuggestionCreationQueryParams } from './Filters/filtersQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../hooks/useQueryWithPagingAndSorting';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import GenerierlaufStatusExpandedRow from '../../shared/GenerierlaufStatusExpandedRow';
import { isVerarbeitungEntryTableRowExpandable } from '../../verarbeitungHelpers';
import { useBookingSuggestionCreationGenerierlaufEntryQuery } from '../../gql/BookingSuggestionCreationGenerierlauf/BookingSuggestionCreationGenerierlaufQueries.types';
import { BookingSuggestionCreationGenerierlaufEntry } from '../../../../types';
import bookingSuggestionCreationEntryTableColumns from './bookingSuggestionCreationEntryTableColumns';

type Props = {
  queryParams: TBookingSuggestionCreationQueryParams;
  generierlaufId: string;
};

const BookingSuggestionCreationEntryTable: FC<Props> = ({ generierlaufId, queryParams }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useBookingSuggestionCreationGenerierlaufEntryQuery,
    {
      variables: {
        generierlaufId,
        ...queryParams,
      },
      fetchPolicy: 'no-cache',
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const entryList = data?.getBookingSuggestionCreationGenerierlaufEntry.data.contentList || [];

  return (
    <TableWithColSelector<BookingSuggestionCreationGenerierlaufEntry>
      size="small"
      locale={{
        emptyText: <Empty description={<span>Keine Buchungsvorschläge vorhanden</span>} />,
      }}
      scroll={{ x: 900 }}
      loading={loading}
      pagination={pagination}
      dataSource={entryList}
      columns={bookingSuggestionCreationEntryTableColumns}
      onChange={handleTableSorting}
      expandable={{
        expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
        rowExpandable: isVerarbeitungEntryTableRowExpandable,
      }}
      rowKey={(record) => record.generierlaufEntryId}
      filterIdentifier="booking-suggestion-creation"
    />
  );
};

export default BookingSuggestionCreationEntryTable;
