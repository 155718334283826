import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { DataCarrierPainCreationGenerierlauf } from '../../../../types';
import DataCarrierPainCreationEntryTable from './DataCarrierPainCreationEntryTable';
import { TDataCarrierPainCreationQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { mapQueryParamsToFormValues, FiltersFormValues } from './Filters/listingFiltersFormMapper';
import ListingFilters from './Filters/ListingFilters';

type Props = {
  generierlauf: DataCarrierPainCreationGenerierlauf;
  verarbeitungType: string;
};

const EntryListing: FC<Props> = ({ generierlauf, verarbeitungType }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TDataCarrierPainCreationQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(navigate, verarbeitungType, generierlauf.generierlaufId, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: FiltersFormValues) => {
    updateQueryParams(navigate, verarbeitungType, generierlauf.generierlaufId, values, paginationParams);
  };

  return (
    <Formik<FiltersFormValues> initialValues={mapQueryParamsToFormValues(queryParams)} onSubmit={onChange}>
      {(formikProps) => (
        <>
          <ListingFilters formikProps={formikProps} generierlaufId={generierlauf.generierlaufId} />
          <DataCarrierPainCreationEntryTable generierlaufId={generierlauf.generierlaufId} queryParams={queryParams} />
        </>
      )}
    </Formik>
  );
};

export default EntryListing;
