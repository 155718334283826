import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import { URI_BUDGETING_DETAILS_PAGE } from '../../features/Budgeting/budgetingUriPaths';
import BudgetingDetailsPage from './BudgetingDetailsPage';

export type BudgetingDetailsPageRouteParams = {
  objektId: string;
  budgetingId: string;
};

const budgetingDetailsPageRoute = (
  <Route
    path={`${URI_BUDGETING_DETAILS_PAGE}/*`}
    element={<AuthRoute element={<BudgetingDetailsPage />} hasPermissionFn={isActiveForSomeFirmendaten} />}
  />
);

export default budgetingDetailsPageRoute;
