import { FC } from 'react';
import { Typography } from 'antd';
import { Spacer } from '../../../components/Grid';
import { verarbeitungPageAndMenuListTitles } from '../verarbeitungHelpers';
import { FirmendatenInitializerGenerierlauf } from '../../../types';
import FirmendatenInitializerVerarbeitungTable from './Table/FirmendatenInitializerVerarbeitungTable';

type Props = {
  generierlauf: FirmendatenInitializerGenerierlauf;
};

const VerarbeitungFirmendatenInitializer: FC<Props> = ({ generierlauf }) => {
  return (
    <>
      <Typography.Title level={4}>{verarbeitungPageAndMenuListTitles.FirmendatenInitializer}</Typography.Title>
      <Spacer />
      <FirmendatenInitializerVerarbeitungTable generierlauf={generierlauf} />
    </>
  );
};

export default VerarbeitungFirmendatenInitializer;
