import { GenerierlaufEntryExitCode } from '../../../../../types';
import { TBookingSuggestionCreationQueryParams } from './filtersQueryParams';
import { FormFields, mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';
import { ObjectOfStrings } from '../../../../../shared/typeHelpers';

export type FiltersFormValues = {
  dataCarrierName?: string | null;
  statementNumber?: string | null;
  valueDateFrom?: string | null;
  valueDateTo?: string | null;
  creditor?: string | null;
  debtor?: string | null;
  amountFrom?: number | null;
  amountTo?: number | null;
  exitCodeList?: GenerierlaufEntryExitCode[] | null;
};

export const listingFiltersFormFields: FormFields<FiltersFormValues> = {
  dataCarrierName: 'dataCarrierName',
  statementNumber: 'statementNumber',
  valueDateFrom: 'valueDateFrom',
  valueDateTo: 'valueDateTo',
  creditor: 'creditor',
  debtor: 'debtor',
  amountFrom: 'amountFrom',
  amountTo: 'amountTo',
  exitCodeList: 'exitCodeList',
};

export const mapQueryParamsToFormValues = (queryParams: TBookingSuggestionCreationQueryParams): FiltersFormValues => ({
  dataCarrierName: queryParams.dataCarrierName,
  statementNumber: queryParams.statementNumber,
  valueDateFrom: queryParams.valueDateFrom,
  valueDateTo: queryParams.valueDateTo,
  creditor: queryParams.creditor,
  debtor: queryParams.debtor,
  amountFrom: queryParams.amountFrom,
  amountTo: queryParams.amountTo,
  exitCodeList: queryParams.exitCodeList,
});

export const mapFormValuesToQueryParams = (values: FiltersFormValues): TBookingSuggestionCreationQueryParams => ({
  dataCarrierName: values.dataCarrierName,
  statementNumber: values.statementNumber,
  valueDateFrom: values.valueDateFrom ? mapFormDateValueToDateString(values.valueDateFrom) : undefined,
  valueDateTo: values.valueDateTo ? mapFormDateValueToDateString(values.valueDateTo) : undefined,
  creditor: values.creditor,
  debtor: values.debtor,
  amountFrom: values.amountFrom,
  amountTo: values.amountTo,
  exitCodeList: values.exitCodeList,
});

export const listingLabelList: ObjectOfStrings<Omit<FiltersFormValues, 'valueDateTo' | 'amountTo'>> = {
  dataCarrierName: 'Bezeichnung (des Datenträgers)',
  statementNumber: 'Kontoauszugsnummer',
  valueDateFrom: 'Valutadatum',
  creditor: 'Kreditor',
  debtor: 'Debitor',
  amountFrom: 'Betrag',
  exitCodeList: 'Status',
};
