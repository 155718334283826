import React, { FC } from 'react';
import { Button, Drawer, MenuProps, Modal, Tabs, TabsProps } from 'antd';
import { DeleteOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { Box } from 'rebass';
import { radixActionStyles } from '../../../../../helpers/radixIconsStyles';
import ActionDropdown from '../../../../../components/Dropdown/ActionDropdown';
import { useDeleteUstRegelsetTemplateMutation } from '../../gql/UstRegelsetTemplateMutations.types';
import { UstRegelset } from '../../../../../types';
import { useToggle } from '../../../../../hooks/useToggle';
import HistoryModal, { HistoryType } from '../../../../History/shared/HistoryModal';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { showSuccessMsgDelete } from '../../../../../components/message';
import UstRegelsetChangeHistoryListingTable from './UstRegelsetChangeHistoryListingTable';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../../../components/Button/ButtonsAligned';
import UstRegelsetTemplateVersionTimeline from '../../Version/UstRegelsetTemplateVersionTimeline';
import UstRegelsetTemplateForm from '../../../UstRegelset/Form/UstRegelsetTemplateForm';

type Props = {
  ustRegelset: UstRegelset;
  onAction: () => void;
};

const UstRegelsetTemplateTableActions: FC<Props> = ({ ustRegelset, onAction }) => {
  const [isCollapsed, onCollapse] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const [runDelete] = useDeleteUstRegelsetTemplateMutation({
    onCompleted: () => {
      showSuccessMsgDelete('USt-Regelset');
      onAction();
    },
    variables: {
      ustRegelsetId: ustRegelset.ustRegelsetId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    { key: '1', label: 'Bearbeiten', onClick: onCollapse, icon: <EditOutlined /> },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '3',
      label: 'Löschen',
      onClick: showConfirmDelete(runDelete, ustRegelset),
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span>
          <HistoryOutlined />
          Verlauf
        </span>
      ),
      children: (
        <Box p={16}>
          <UstRegelsetTemplateVersionTimeline ustRegelset={ustRegelset} reloadUstRegelset={onAction} />
        </Box>
      ),
    },
    {
      key: '2',
      label: (
        <span>
          <EditOutlined />
          Bearbeiten
        </span>
      ),
      children: (
        <Box p={16}>
          <UstRegelsetTemplateForm
            ustRegelset={ustRegelset}
            onSuccess={() => {
              onAction();
              onCollapse();
            }}
            onCancel={onCollapse}
          />
        </Box>
      ),
    },
  ];

  return (
    <>
      <ActionDropdown items={items} />
      <Drawer
        title="USt.-Regelsatz bearbeiten"
        width={720}
        onClose={onCollapse}
        open={isCollapsed}
        destroyOnClose
        footer={
          <ButtonsAligned usage={ButtonsAlignedUsage.drawerFooter}>
            <Button onClick={onCollapse} type="primary">
              Schließen
            </Button>
          </ButtonsAligned>
        }
      >
        <Tabs defaultActiveKey="2" items={tabItems} />
      </Drawer>

      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <UstRegelsetChangeHistoryListingTable ustRegelsetId={ustRegelset.ustRegelsetId} />
      </HistoryModal>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void, ustRegelset: UstRegelset) => () => {
  Modal.confirm({
    title: `Möchten Sie das USt-Regelset löschen?`,
    content: `${ustRegelset.bezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default UstRegelsetTemplateTableActions;
