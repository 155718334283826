import { FormFields } from '../../../../../helpers/formikHelper';
import { ITimeblock } from '../../../../../components/Timeline/timelineCtx';
import {
  RepFondsEinstellung,
  RepFondsEinstellungCreateInput,
  RepFondsEinstellungKontenZuweisung,
  RepFondsEinstellungKontenZuweisungCreateInput,
  RepFondsEinstellungKontenZuweisungUpdateInput,
} from '../../../../../types';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import { ValuesDescriptionForKommentar } from '../../../../../components/Timeline/versionTimelineHelper';

export type RepFondsKontenZuweisungVersionFormValues = {
  validFrom: string;
  kontenZuweisungList: RepFondsKontenZuweisungFormValues[];
  kommentar?: string | null;
};

export const repFondsKontenZuordnungVersionFormFields: FormFields<RepFondsKontenZuweisungVersionFormValues> = {
  validFrom: 'validFrom',
  kontenZuweisungList: 'kontenZuweisungList',
  kommentar: 'kommentar',
};

export type RepFondsKontenZuweisungFormValues = {
  aufteilungsschluesselId: string;
  aufwandsKontoIdList: string[];
  repFondsEinstellungKontenZuweisungId?: string;
  repFondsKontoId: string;
};

export const repFondsKontenZuweisungFormFields: FormFields<RepFondsKontenZuweisungFormValues> = {
  aufteilungsschluesselId: 'aufteilungsschluesselId',
  aufwandsKontoIdList: 'aufwandsKontoIdList',
  repFondsEinstellungKontenZuweisungId: 'repFondsEinstellungKontenZuweisungId',
  repFondsKontoId: 'repFondsKontoId',
};

export const mapTimeblockToFormValues = (timeblock: ITimeblock<RepFondsEinstellung>): RepFondsKontenZuweisungVersionFormValues => {
  return {
    validFrom: timeblock.validFrom,
    kontenZuweisungList: timeblock.kontenZuweisungList ? mapRepFondsKontenZuweisungToFormValues(timeblock.kontenZuweisungList) : [],
    kommentar: timeblock.kommentar ?? '',
  };
};

export const repFondsKontenZuweisungFormInitialValues: RepFondsKontenZuweisungFormValues = {
  aufteilungsschluesselId: '',
  aufwandsKontoIdList: [],
  repFondsEinstellungKontenZuweisungId: '',
  repFondsKontoId: '',
};

const mapRepFondsKontenZuweisungToFormValues = (repFondsKonten: RepFondsEinstellungKontenZuweisung[]): RepFondsKontenZuweisungFormValues[] => {
  return repFondsKonten.map((konto) => {
    return {
      aufteilungsschluesselId: konto.aufteilungsschluessel.aufteilungsschluesselId,
      aufwandsKontoIdList: konto.aufwandsKontoList.map((konto) => konto.kontoId),
      repFondsEinstellungKontenZuweisungId: konto.repFondsEinstellungKontenZuweisungId,
      repFondsKontoId: konto.repFondsKonto.kontoId,
    };
  });
};

export const mapFormValuesToRepFondsKontenZuweisungVersionCreate = (
  repFonds: RepFondsKontenZuweisungVersionFormValues
): RepFondsEinstellungCreateInput => {
  return {
    validFrom: dayjsCustom(repFonds.validFrom).startOf('month').format('YYYY-MM-DD'),
    kontenZuweisungList: mapFormValuesToRepFondsKontenZuweisungCreate(repFonds.kontenZuweisungList),
    kommentar: repFonds.kommentar ?? '',
  };
};

const mapFormValuesToRepFondsKontenZuweisungCreate = (
  repFondsKonten: RepFondsKontenZuweisungFormValues[]
): RepFondsEinstellungKontenZuweisungCreateInput[] => {
  return repFondsKonten.map((konto) => {
    return {
      aufteilungsschluesselId: konto.aufteilungsschluesselId,
      aufwandsKontoIdList: konto.aufwandsKontoIdList,
      repFondsKontoId: konto.repFondsKontoId,
    };
  });
};

export const mapFormValuesToRepFondsKontenZuweisungVersionUpdate = (
  repFonds: RepFondsKontenZuweisungVersionFormValues
): RepFondsEinstellungCreateInput => {
  return {
    validFrom: dayjsCustom(repFonds.validFrom).startOf('month').format('YYYY-MM-DD'),
    kontenZuweisungList: mapFormValuesToRepFondsKontenZuweisungUpdate(repFonds.kontenZuweisungList),
    kommentar: repFonds.kommentar ?? '',
  };
};

const mapFormValuesToRepFondsKontenZuweisungUpdate = (
  repFondsKonten: RepFondsKontenZuweisungFormValues[]
): RepFondsEinstellungKontenZuweisungUpdateInput[] => {
  return repFondsKonten.map((konto) => {
    return {
      aufteilungsschluesselId: konto.aufteilungsschluesselId,
      aufwandsKontoIdList: konto.aufwandsKontoIdList,
      repFondsEinstellungKontenZuweisungId: konto.repFondsEinstellungKontenZuweisungId,
      repFondsKontoId: konto.repFondsKontoId,
    };
  });
};

export const repFondsKontenZuweisungDescriptionsForKommentar: ValuesDescriptionForKommentar<RepFondsKontenZuweisungFormValues> = {
  aufwandsKontoIdList: 'Aufwandskonten',
  aufteilungsschluesselId: 'Aufteilungschlüssel',
  repFondsEinstellungKontenZuweisungId: '',
  repFondsKontoId: 'Rep.Fonds Konto',
};
