import React, { FC } from 'react';
import { Empty, Table } from 'antd';
import { v4 as uuid4 } from 'uuid';
import zahlungsrueckstandTableColumns from './zahlungsrueckstandTableColumns';
import { Spacer } from '../../../../components/Grid';
import { ObjektAbrechnung, TopAbrOverviewZahlungsrueckstand } from '../../../../types';

type Props = {
  abrechnung: ObjektAbrechnung;
  loading: boolean;
  objektId: string;
};

const ZahlungsrueckstandTab: FC<Props> = ({ abrechnung, objektId }) => {
  const zahlungsrueckstandList = abrechnung.topAbrechnungOverview?.zahlungsrueckstandList || [];

  return (
    <>
      <Spacer height={16} />
      <Table<TopAbrOverviewZahlungsrueckstand>
        size="small"
        locale={{
          emptyText: <Empty description={<span>Keine Zahlungsrückstände vorhanden</span>} />,
        }}
        columns={zahlungsrueckstandTableColumns(abrechnung, objektId)}
        dataSource={zahlungsrueckstandList}
        rowKey={() => uuid4()}
      />
    </>
  );
};

export default ZahlungsrueckstandTab;
