import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTopFinancialPlanGenerierlaufMutationVariables = Types.Exact<{
  input: Types.TopFinancialPlanGenerierlaufInput;
}>;

export type CreateTopFinancialPlanGenerierlaufMutation = {
  createTopFinancialPlanGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type RestartTopFinancialPlanGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartTopFinancialPlanGenerierlaufMutation = {
  actionRestartTopFinancialPlanGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateTopFinancialPlanGenerierlaufDocument = gql`
  mutation CreateTopFinancialPlanGenerierlauf($input: TopFinancialPlanGenerierlaufInput!) {
    createTopFinancialPlanGenerierlauf(input: $input) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateTopFinancialPlanGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTopFinancialPlanGenerierlaufMutation, CreateTopFinancialPlanGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTopFinancialPlanGenerierlaufMutation, CreateTopFinancialPlanGenerierlaufMutationVariables>(
    CreateTopFinancialPlanGenerierlaufDocument,
    options
  );
}
export type CreateTopFinancialPlanGenerierlaufMutationHookResult = ReturnType<typeof useCreateTopFinancialPlanGenerierlaufMutation>;
export type CreateTopFinancialPlanGenerierlaufMutationResult = Apollo.MutationResult<CreateTopFinancialPlanGenerierlaufMutation>;
export type CreateTopFinancialPlanGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  CreateTopFinancialPlanGenerierlaufMutation,
  CreateTopFinancialPlanGenerierlaufMutationVariables
>;
export const RestartTopFinancialPlanGenerierlaufDocument = gql`
  mutation RestartTopFinancialPlanGenerierlauf($generierlaufId: ID!) {
    actionRestartTopFinancialPlanGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartTopFinancialPlanGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<RestartTopFinancialPlanGenerierlaufMutation, RestartTopFinancialPlanGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestartTopFinancialPlanGenerierlaufMutation, RestartTopFinancialPlanGenerierlaufMutationVariables>(
    RestartTopFinancialPlanGenerierlaufDocument,
    options
  );
}
export type RestartTopFinancialPlanGenerierlaufMutationHookResult = ReturnType<typeof useRestartTopFinancialPlanGenerierlaufMutation>;
export type RestartTopFinancialPlanGenerierlaufMutationResult = Apollo.MutationResult<RestartTopFinancialPlanGenerierlaufMutation>;
export type RestartTopFinancialPlanGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartTopFinancialPlanGenerierlaufMutation,
  RestartTopFinancialPlanGenerierlaufMutationVariables
>;
