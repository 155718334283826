import { Zinsliste } from '../../../../types';
import { mapZinslisteToVPosForVertragsartListSummary } from '../../Card/VPosForVertragLevelList/vPosForVertragLevelListMapper';

export type TVPosList = {
  [key: string]: { betrag: number };
} & { vPosBezeichnung: string; bezeichnung: string };

export const mapZinslisteVPosListToTable = (zinsliste: Zinsliste) => {
  const { vorschreibungspositionList } = mapZinslisteToVPosForVertragsartListSummary(zinsliste);

  let nettoRow: TVPosList;
  let bruttoRow: TVPosList;
  let ustRow: TVPosList;

  vorschreibungspositionList.forEach((vp) => {
    // @ts-ignore
    nettoRow = {
      ...nettoRow,
      [vp.bezeichnung]: {
        betrag: vp.sumNetto,
      },
      bezeichnung: 'Netto',
    };
    // @ts-ignore
    bruttoRow = {
      ...bruttoRow,
      [vp.bezeichnung]: {
        betrag: vp.sumBrutto,
      },
      bezeichnung: 'Brutto',
    };
    // @ts-ignore
    ustRow = {
      ...ustRow,
      [vp.bezeichnung]: {
        betrag: vp.sumBrutto - vp.sumNetto,
      },
      bezeichnung: 'USt.',
    };
  });

  // @ts-ignore
  const rows = [nettoRow, bruttoRow, ustRow];

  return { vorschreibungspositionList, rows };
};
