import React from 'react';
import { ColumnProps } from 'antd/es/table';
import { Tag } from 'antd';
import { ErrorData } from '../../../types';

const errorLogTableColumns: ColumnProps<ErrorData>[] = [
  {
    title: 'Status',
    render: (text, record) => <Tag>{record.type}</Tag>,
  },
  {
    title: 'Erklärung',
    align: 'left',
    render: (text, record) => record.message,
  },
  {
    title: 'Korrekturmöglichkeiten',
    align: 'right',
    render: () => <div />,
  },
];

export default errorLogTableColumns;
