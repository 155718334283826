import React, { FC } from 'react';
import { Button, Space } from 'antd';
import { FieldArray } from 'formik';
import { Input } from 'formik-antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { ContactType } from './contactHelper';

type ContactFormPartProps = {
  name: string;
  type: ContactType;
  wertList: string[];
  onRemove: (name: string) => void;
  isValueWertInObject?: boolean;
  layout?: Record<string, unknown>;
  allowCreateMultipleContact?: boolean;
  errors?: string[];
};

const ContactFormPart: FC<ContactFormPartProps> = ({
  name,
  type,
  wertList,
  onRemove,
  isValueWertInObject,
  layout,
  allowCreateMultipleContact = true,
  errors,
}) => (
  <FieldArray
    name={name}
    render={(arrayHelpers) => {
      const add = () => {
        arrayHelpers.push('');
      };
      return (
        <Space direction="vertical">
          {wertList.map((wert, index) => {
            const isOnlyOneInputLeft = wertList.length === 1;
            const contactName = isValueWertInObject ? `${name}.${index}.wert` : `${name}.${index}`;
            const remove = () => {
              if (isOnlyOneInputLeft) {
                onRemove(contactName);
              } else {
                arrayHelpers.remove(index);
              }
            };
            return (
              <ContactItem
                error={!!errors?.[index]}
                name={contactName}
                type={type}
                add={add}
                remove={remove}
                key={index}
                {...layout}
                allowCreateMultipleContact={allowCreateMultipleContact}
              />
            );
          })}
        </Space>
      );
    }}
  />
);

type ContactItemProps = {
  name: string;
  type: ContactType;
  add: () => void;
  remove: () => void;
  allowCreateMultipleContact: boolean;
  error?: boolean;
};

const ContactItem: FC<ContactItemProps> = ({ name, type, add, remove, allowCreateMultipleContact, error, ...restProps }) => (
  <Space>
    <Input
      {...restProps}
      name={name}
      id={name}
      style={{ width: '340px' }}
      placeholder={type === ContactType.EMAIL ? 'max@mustermann.at' : '+43 1 1234567'}
      {...(error && { status: 'error' })}
    />
    {allowCreateMultipleContact && (
      <Space>
        <Button size="small" shape="circle" icon={<MinusOutlined />} onClick={remove} />
        <Button size="small" shape="circle" icon={<PlusOutlined />} onClick={add} />
      </Space>
    )}
  </Space>
);

export default ContactFormPart;
