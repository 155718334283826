import * as Yup from 'yup';
import { steuersatzVersionValidationSchema } from '../../../../shared/BruttoNettoUst/bruttoNettoUstSteuerValidationSchema';
import { entityHasToBeAnInt, entityHasToBeANumber, entityMaxCharLength } from '../../../../components/message/validationMsg';
import { ustKategorieEntryListFormFields } from '../Version/Form/ustKategorieVersionMapper';

/* eslint-disable no-template-curly-in-string */
export const ustKategorieEntryVersionValidationSchema = Yup.object().shape({
  [ustKategorieEntryListFormFields.additionKonto]: Yup.number()
    .typeError(entityHasToBeANumber('Addition Konto'))
    .integer(entityHasToBeAnInt('Addition Konto')),
  [ustKategorieEntryListFormFields.buchungsKZ]: Yup.string().nullable(),
  [ustKategorieEntryListFormFields.rechnungskennzeichen]: Yup.string().max(1, entityMaxCharLength('Kennzeichen für Rechnung', 1)),
  [ustKategorieEntryListFormFields.steuersatz]: steuersatzVersionValidationSchema, // USt %
});
/* eslint-enable no-template-curly-in-string */
