import React, { FC } from 'react';
import { DatePicker } from 'formik-antd';
import { Form, Modal } from 'antd';
import FormButtons from '../../../../components/Button/FormButtons';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../components/DatePicker/DatePicker_formikAntD';
import { bookingSuggestionFormFields } from '../../../BookingSuggestion/shared/Form/buchungsmaskeFormMapper';

type Props = {
  isOpen: boolean;
  onSuccess: () => void;
  onCancel: () => void;
  isOkDisabled: boolean;
  isOkLoading?: boolean;
};

const BuchungDateModal: FC<Props> = ({ isOpen, onCancel, onSuccess, isOkDisabled, isOkLoading }) => {
  return (
    <Modal title="Buchung speichern und verbuchen" width={520} footer={null} open={isOpen} onCancel={onCancel} destroyOnClose>
      <Form layout="vertical">
        <FormItemWithoutColon name={bookingSuggestionFormFields.buchungsdatum} label="Buchungsdatum">
          <DatePicker
            size="small"
            name={bookingSuggestionFormFields.buchungsdatum}
            placeholder="Buchungsdatum auswählen"
            allowClear={false}
            style={{ width: '100%' }}
            format={DATE_DISPLAY_FORMAT_DEFAULT}
          />
        </FormItemWithoutColon>
        <FormButtons
          updateMode={false}
          onCancel={onCancel}
          onOk={onSuccess}
          isOkDisabled={isOkDisabled}
          isOkLoading={isOkLoading}
          okText="Speichern und verbuchen"
          cancelText="Abbrechen"
        />
      </Form>
    </Modal>
  );
};

export default BuchungDateModal;
