import { FC } from 'react';
import { Typography } from 'antd';
import ObjAbrVerarbeitungEntryListing from './EntryListing/ObjAbrVerarbeitungEntryListing';
import { Spacer } from '../../../components/Grid';
import { AppGenerierlaufType, ObjektAbrechnungGenerierlauf } from '../../../types';

type Props = {
  title: string;
  type: string;
  generierlauf: ObjektAbrechnungGenerierlauf;
  generierlaufType: AppGenerierlaufType;
};

const VerarbeitungObjektAbrechnung: FC<Props> = ({ title, type, generierlauf, generierlaufType }) => (
  <>
    <Typography.Title level={4}>{title}</Typography.Title>
    <Spacer />
    <ObjAbrVerarbeitungEntryListing generierlauf={generierlauf} type={type} generierlaufType={generierlaufType} />
  </>
);

export default VerarbeitungObjektAbrechnung;
