import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_SYS_SETTINGS } from '../../../../constants/configurationUriPaths';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../security/permissionChecks';
import IndexValueCreationCronJobSettingsPage from './IndexValueCreationCronJobSettingsPage';

const sysSettingsIndexValueCreationCronJobSettingsPageRoute = (
  <Route
    path={URI_SYS_SETTINGS.indexValueCreationCronJobSettings}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<IndexValueCreationCronJobSettingsPage />} />}
  />
);

export default sysSettingsIndexValueCreationCronJobSettingsPageRoute;
