import { electronicFormatIBAN, friendlyFormatIBAN } from 'ibantools';
import {
  BankDetails,
  FirmendatenBankDetails,
  FirmendatenBankDetailsCreateInput,
  FirmendatenBankDetailsUpdateInput,
  RechtstraegerBankDetailsCreateInput,
  RechtstraegerBankDetailsUpdateInput,
} from '../../../types';
import { FormFields } from '../../../helpers/formikHelper';

export type FDAndRechtstrBankDetailsInput =
  | RechtstraegerBankDetailsCreateInput
  | RechtstraegerBankDetailsUpdateInput
  | FirmendatenBankDetailsCreateInput
  | FirmendatenBankDetailsUpdateInput;

export interface BankDetailsFormValues {
  iban?: string | null;
  bic?: string;
  accountHolder?: string;
  bankname?: string;
  allowObjektZuweisung?: boolean;
  createFibuKonto?: boolean;
}

export const bankDetailsFormInitialValues: BankDetailsFormValues = {
  iban: '',
  bic: '',
  accountHolder: '',
  bankname: '',
};

export const bankDetailsFormFields: FormFields<BankDetailsFormValues> = {
  iban: 'iban',
  bic: 'bic',
  accountHolder: 'accountHolder',
  bankname: 'bankname',
  allowObjektZuweisung: 'allowObjektZuweisung',
  createFibuKonto: 'createFibuKonto',
};

export const mapBankDetailsToFormValues = (bankDetails?: BankDetails | FirmendatenBankDetails): BankDetailsFormValues => {
  if (!bankDetails) {
    return bankDetailsFormInitialValues;
  }
  return {
    iban: friendlyFormatIBAN(bankDetails.iban),
    bic: bankDetails.bic,
    accountHolder: bankDetails.accountHolder,
    bankname: bankDetails.bankname,
    // TODO: EC-8574-CR: use typeguard or so instead of ts-ignore
    // @ts-ignore
    allowObjektZuweisung: bankDetails?.allowObjektZuweisung ?? undefined,
  };
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const mapFormValuesToBankDetails = (values: BankDetailsFormValues): FDAndRechtstrBankDetailsInput => ({
  iban: electronicFormatIBAN(values.iban!) as string,
  bic: values.bic!,
  accountHolder: values.accountHolder!,
  bankname: values.bankname!,
  allowObjektZuweisung: values.allowObjektZuweisung,
  createFibuKonto: values.createFibuKonto,
});
/* eslint-enable @typescript-eslint/no-non-null-assertion */
