import React, { FC } from 'react';
import { Link2Icon } from '@radix-ui/react-icons';
import { Button, Space, Tooltip } from 'antd';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import subTicketTooltipContent from '../Tooltip/subTicketTooltipContent';
import { Ticket } from '../../../../types';
import { GroupedParticipant } from './ticketParticipantHelpers';
import { isTicketArchived } from '../../ticketHelpers';

type Props = {
  ticket: Ticket;
  participant: GroupedParticipant;
  handleAssignParticipant: (participantIds: string[], emailAddress: string) => void;
  loadingAssign: boolean;
};

export const ParticipantWithEmailAddress: FC<Props> = ({ ticket, participant, loadingAssign, handleAssignParticipant }) => {
  const { emailAddress } = participant;
  if (!emailAddress) return null;

  const subTicketList = ticket.subTicketList;
  const ticketIds = participant.ticketParticipants.map((ticketParticipant) => ticketParticipant.ticketId);
  const participantIds = participant.ticketParticipants.map((ticketParticipant) => ticketParticipant.participant.participantId);

  return (
    <Space style={{ justifyContent: 'space-between', width: '100%' }}>
      <DataWithShortenedText maxTextLength={20} text={emailAddress} tooltipFooter={subTicketTooltipContent(subTicketList, ticket.ticketId)} />
      {isAssignEnabled(ticketIds, ticket.ticketId) && (
        <Tooltip title="Die E-Mail einem Kontakt zuordnen">
          <Button
            style={{ height: 20 }}
            icon={<Link2Icon />}
            disabled={isTicketArchived(ticket) || loadingAssign}
            onClick={() => handleAssignParticipant(participantIds as string[], emailAddress)}
            shape="circle"
            size="small"
            type="text"
          />
        </Tooltip>
      )}
    </Space>
  );
};

const isAssignEnabled = (ticketIds: string[], ticketId: string) => {
  return ticketIds.includes(ticketId);
};

export default ParticipantWithEmailAddress;
