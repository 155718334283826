import { generatePath } from 'react-router-dom';
import { AuftragStatus, AuftragStatusTuple } from '../../types';

export const URI_VORSCHREIBUNG_PAGE = '/dashboard/orders';
export const URI_VORSCHREIBUNG_DETAILS_PAGE = `${URI_VORSCHREIBUNG_PAGE}/:auftragId`;

export const generatePathToVorschreibungDetailsPage = (auftragId: string) => generatePath(URI_VORSCHREIBUNG_DETAILS_PAGE, { auftragId });

export const generatePathToVorschreibungDetailsPageOrDiscrepancies = (auftragId: string, status: AuftragStatusTuple) =>
  status.value === AuftragStatus.Invalid
    ? `${generatePathToVorschreibungDetailsPage(auftragId)}/discrepancies`
    : generatePathToVorschreibungDetailsPage(auftragId);
