import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { useRechtstraegerListQuery } from '../gql/RechtsTraegerQueries.types';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../hooks/useQueryWithPagingAndSorting';
import RechtstraegerTable from './RechtstraegerTable';
import { useQueryParams } from '../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';
import { TRechtstraegerQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import ListingFilters from './Filters/ListingFilters';
import {
  FiltersFormValues,
  mapFormValuesToQueryParams,
  mapQueryParamsToFormValues,
  mapQueryParamsToRechtstreagerListQueryVariables,
} from './Filters/filtersFormMapper';

const RechtstraegerListingForm = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TRechtstraegerQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(navigate, queryParams, paginationParams);
  };

  const { data, refetch, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useRechtstraegerListQuery,
    {
      variables: {
        ...mapQueryParamsToRechtstreagerListQueryVariables(queryParams),
      },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: paginationParams.currentPage ? Number(paginationParams.currentPage) : DEFAULT_CURRENT,
      pageSize: paginationParams.pageSize ? Number(paginationParams.pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const rechtstraegerList = data?.getRechtstraegerList.data?.contentList;
  const loading = !rechtstraegerList;

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: FiltersFormValues) => {
    const filters = mapFormValuesToQueryParams(values);
    updateQueryParams(navigate, filters, paginationParams);
  };

  return (
    <>
      <Formik<FiltersFormValues> initialValues={mapQueryParamsToFormValues(queryParams)} onSubmit={onChange}>
        {(formikProps) => <ListingFilters formikProps={formikProps} queryParams={queryParams} onAction={refetch} />}
      </Formik>
      <RechtstraegerTable loading={loading} pagination={pagination} rechtstraegerList={rechtstraegerList} handleTableSorting={handleTableSorting} />
    </>
  );
};

export default RechtstraegerListingForm;
