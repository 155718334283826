import React, { FC } from 'react';
import { Typography } from 'antd';
import FormattedDecimalFormInput from '../../../components/Input-antd/FormattedDecimalFormInput';
import { StyledSpaceFullWidthInFilter } from '../../../components/Space/styled/StyledSpaceFullWidthInFilter';
import FormItemForFilter from '../../../components/Form/FormItemForFilter';

type Props = {
  /** form item name and label */
  name: string;
  fromName: string;
  toName: string;
  fromPrefix: string;
  toPrefix: string;
  onChange?: () => void;
  fractionDigits?: number;
};

const FromToFormInputNumberForFilter: FC<Props> = ({ name, fromPrefix, toPrefix, fromName, toName, onChange, fractionDigits }) => (
  <FormItemForFilter name={name}>
    <StyledSpaceFullWidthInFilter size="small">
      <FormattedDecimalFormInput
        name={fromName}
        style={{ width: '100%' }}
        onChange={onChange}
        min={0}
        placeholder=""
        fractionDigits={fractionDigits}
        size="middle"
        prefix={fromPrefix}
      />
      <Typography.Text>-</Typography.Text>
      <FormattedDecimalFormInput
        name={toName}
        style={{ width: '100%' }}
        onChange={onChange}
        min={0}
        placeholder=""
        fractionDigits={fractionDigits}
        prefix={toPrefix}
        size="middle"
      />
    </StyledSpaceFullWidthInFilter>
  </FormItemForFilter>
);

export default FromToFormInputNumberForFilter;
