import { firmennameFormPartInitialValues, mapFirmennameToFormValues } from '../../../../shared/components/Firmenname/form/firmennameFormPartMapper';
import {
  mapVatIdNumberToFormValues,
  vatIdNumberFormPartInitialValues,
} from '../../../../shared/components/VatIdentificationNumber/form/vatIdNumberFormPartMapper';
import { mapFormValuesToAddress } from '../../../../shared/components/Address/form/addressMapper';
import {
  kurzBezeichnungFormPartInitialValues,
  mapKurzBezeichnungToFormValues,
} from '../../../../shared/components/KurzBezeichnung/form/kurzBezeichnungFormPartMapper';
import {
  Personengemeinschaft,
  PersonenGemeinschaftCreateInput,
  PersonenGemeinschaftUpdateInput,
  PersonsCommunityType,
  RechtstraegerType,
  Salutation,
  Steuerpflicht,
} from '../../../../types';
import { SPRACHE_DEFAULT } from '../../Sprache/spracheHelper';
import { StreetAddressFormValues } from '../../../../shared/components/Address/AddressSharedModel';
import { FormFields } from '../../../../helpers/formikHelper';

export interface PersonGemeinschaftFormValues {
  type: RechtstraegerType;
  sprache: string;
  salutation?: string;
  name1: string;
  name2?: string;
  name3?: string;
  kurzBezeichnung: string;
  vatIdentificationNumber: string;
  communityType: PersonsCommunityType | null;
  hauptAddress?: StreetAddressFormValues;
  tagList: string[];
  steuerpflicht: Steuerpflicht;
  // helper attributes for toggling and temporary store hauptAddress before adding it to personenGemeinschaft
  tempShowHauptAddressFormPartItem: boolean;
  tempHauptAddress?: StreetAddressFormValues;
}

export const personGemeinschaftFormFields: FormFields<PersonGemeinschaftFormValues> = {
  type: 'type',
  sprache: 'sprache',
  salutation: 'salutation',
  name1: 'name1',
  name2: 'name2',
  name3: 'name3',
  kurzBezeichnung: 'kurzBezeichnung',
  vatIdentificationNumber: 'vatIdentificationNumber',
  communityType: 'communityType',
  hauptAddress: 'hauptAddress',
  tagList: 'tagList',
  steuerpflicht: 'steuerpflicht',
  tempShowHauptAddressFormPartItem: 'tempShowHauptAddressFormPartItem',
  tempHauptAddress: 'tempHauptAddress',
};

export const personenGemeinschaftFormInitialValues: PersonGemeinschaftFormValues = {
  type: RechtstraegerType.PersonenGemeinschaft,
  sprache: SPRACHE_DEFAULT,
  salutation: Salutation.SirAndMadame,
  ...firmennameFormPartInitialValues(),
  ...kurzBezeichnungFormPartInitialValues(),
  ...vatIdNumberFormPartInitialValues(),
  kurzBezeichnung: '',
  vatIdentificationNumber: '',
  communityType: null,
  tagList: [],
  steuerpflicht: Steuerpflicht.Steuerpflichtig,
  tempShowHauptAddressFormPartItem: false, // close address form part initially for create because it is not mandatory
};

export const mapPersonenGemeinschaftToFormValues = (personenGemeinschaft?: Personengemeinschaft): PersonGemeinschaftFormValues => {
  if (!personenGemeinschaft) {
    return personenGemeinschaftFormInitialValues;
  }
  return {
    type: personenGemeinschaft.type.value,
    sprache: personenGemeinschaft.sprache.value,
    salutation: personenGemeinschaft?.salutation?.value,
    ...mapFirmennameToFormValues(personenGemeinschaft), // name 1, name 2, name 3
    ...mapKurzBezeichnungToFormValues(personenGemeinschaft.kurzBezeichnung),
    ...mapVatIdNumberToFormValues(personenGemeinschaft.vatIdentificationNumber),
    communityType: personenGemeinschaft.communityType.value,
    tagList: personenGemeinschaft.tagList,
    tempShowHauptAddressFormPartItem: false,
    steuerpflicht: personenGemeinschaft.rechtstraegerSteuerpflicht?.steuerpflicht.value ?? Steuerpflicht.Steuerpflichtig,
  };
};

export const mapFormValuesToPersonenGemeinschaftCreate = (values: PersonGemeinschaftFormValues): PersonenGemeinschaftCreateInput => {
  let personenGemeinschaft: PersonenGemeinschaftCreateInput = {
    sprache: values.sprache,
    salutation: values.salutation,
    name1: values.name1,
    name2: values.name2,
    name3: values.name3,
    kurzBezeichnung: values.kurzBezeichnung,
    vatIdentificationNumber: values.vatIdentificationNumber,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    communityType: values.communityType!,
    steuerpflicht: { validFrom: '1900-01-01', steuerpflicht: values.steuerpflicht },
    tagList: values.tagList,
  };

  if (values.hauptAddress) {
    const hauptAddress = mapFormValuesToAddress(values.hauptAddress);
    personenGemeinschaft = { ...personenGemeinschaft, hauptAddress };
  }
  return personenGemeinschaft;
};

export const mapFormValuesToPersonenGemeinschaftUpdate = (values: PersonGemeinschaftFormValues): PersonenGemeinschaftUpdateInput => {
  return {
    sprache: values.sprache,
    salutation: values.salutation,
    name1: values.name1,
    name2: values.name2,
    name3: values.name3,
    kurzBezeichnung: values.kurzBezeichnung,
    vatIdentificationNumber: values.vatIdentificationNumber,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    communityType: values.communityType!,
    tagList: values.tagList,
  };
};
