import React, { FC } from 'react';
import { Select } from 'antd';
import { selectFilterOption } from '../../../../../helpers/selectHelper';

type Props = {
  vertragspartnerList: HeVertragRechtstraegerAttributes[];
  selectedRechtstraegerId?: string;
  setSelectedRechtstraegerId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export interface HeVertragRechtstraegerAttributes {
  rechtstraegerId: string;
  kurzBezeichnung: string;
}

const HeVertragRechtstraegerSelect: FC<Props> = ({ vertragspartnerList, selectedRechtstraegerId, setSelectedRechtstraegerId }) => {
  return (
    <Select
      placeholder="Vertragspartner auswählen"
      allowClear
      size="small"
      style={{ minWidth: '160px' }}
      defaultValue={selectedRechtstraegerId}
      showSearch
      filterOption={selectFilterOption}
      onChange={(value) => setSelectedRechtstraegerId(value)}
    >
      {vertragspartnerList.map((rechtstraeger) => (
        <Select.Option value={rechtstraeger.rechtstraegerId} key={rechtstraeger.rechtstraegerId}>
          {rechtstraeger.kurzBezeichnung}
        </Select.Option>
      ))}
    </Select>
  );
};

export default HeVertragRechtstraegerSelect;
