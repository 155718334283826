import { mapToQueryString, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { GenerierlaufEntryExitCode } from '../../../../../types';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../../verarbeitungApiPaths';

enum CamtVerarbeitungQueryParamKey {
  EBICS_USER_NAME = 'ebicsUserName',
  EBICS_USER_USER_ID = 'ebicsUserUserId',
  EXIT_CODE_LIST = 'exitCodeList',
  IBAN = 'iban',
  MITARBEITER_ID = 'mitarbeiterId',
}

export type TCamtVerarbeitungQueryParams = QueryParams<{
  ebicsUserName?: string | null;
  ebicsUserUserId?: string | null;
  exitCodeList?: GenerierlaufEntryExitCode[] | null;
  iban?: string | null;
  mitarbeiterId?: string | null;
}>;

export const updateQueryParams = (
  navigate: (path: string) => void,
  verarbeitungType: string,
  generierlaufId: string,
  filters: TCamtVerarbeitungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToDirectDebitOrBankWireListingPage(verarbeitungType, generierlaufId, filters, paginationParams));

export const generatePathToDirectDebitOrBankWireListingPage = (
  verarbeitungType: string,
  generierlaufId: string,
  filters: TCamtVerarbeitungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [CamtVerarbeitungQueryParamKey.EBICS_USER_NAME]: filters?.ebicsUserName,
    [CamtVerarbeitungQueryParamKey.EBICS_USER_USER_ID]: filters?.ebicsUserUserId,
    [CamtVerarbeitungQueryParamKey.EXIT_CODE_LIST]: filters?.exitCodeList,
    [CamtVerarbeitungQueryParamKey.IBAN]: filters?.iban,
    [CamtVerarbeitungQueryParamKey.MITARBEITER_ID]: filters?.mitarbeiterId,
    ...paginationParams,
  })}`;
