import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateEbicsUserMutationVariables = Types.Exact<{
  input: Types.EbicsUserCreateInput;
}>;

export type CreateEbicsUserMutation = {
  createEbicsUser: {
    data: { ebicsUserId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UpdateEbicsUserMutationVariables = Types.Exact<{
  ebicsUserId: Types.Scalars['ID']['input'];
  input: Types.EbicsUserUpdateInput;
}>;

export type UpdateEbicsUserMutation = {
  updateEbicsUser: {
    data: { ebicsUserId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type DeleteEbicsUserMutationVariables = Types.Exact<{
  ebicsUserId: Types.Scalars['ID']['input'];
}>;

export type DeleteEbicsUserMutation = {
  deleteEbicsUser: { errorList: Array<{ message: string; type: string }>; warningList: Array<{ message: string; type: string }> };
};

export type ActivateEbicsUserMutationVariables = Types.Exact<{
  ebicsUserId: Types.Scalars['ID']['input'];
}>;

export type ActivateEbicsUserMutation = {
  actionActivateEbicsUser: {
    data: { ebicsUserId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type DeactivateEbicsUserMutationVariables = Types.Exact<{
  ebicsUserId: Types.Scalars['ID']['input'];
}>;

export type DeactivateEbicsUserMutation = {
  actionDeactivateEbicsUser: {
    data: { ebicsUserId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const CreateEbicsUserDocument = gql`
  mutation CreateEbicsUser($input: EbicsUserCreateInput!) {
    createEbicsUser(input: $input) {
      data {
        ebicsUserId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreateEbicsUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateEbicsUserMutation, CreateEbicsUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEbicsUserMutation, CreateEbicsUserMutationVariables>(CreateEbicsUserDocument, options);
}
export type CreateEbicsUserMutationHookResult = ReturnType<typeof useCreateEbicsUserMutation>;
export type CreateEbicsUserMutationResult = Apollo.MutationResult<CreateEbicsUserMutation>;
export type CreateEbicsUserMutationOptions = Apollo.BaseMutationOptions<CreateEbicsUserMutation, CreateEbicsUserMutationVariables>;
export const UpdateEbicsUserDocument = gql`
  mutation updateEbicsUser($ebicsUserId: ID!, $input: EbicsUserUpdateInput!) {
    updateEbicsUser(ebicsUserId: $ebicsUserId, input: $input) {
      data {
        ebicsUserId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUpdateEbicsUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEbicsUserMutation, UpdateEbicsUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEbicsUserMutation, UpdateEbicsUserMutationVariables>(UpdateEbicsUserDocument, options);
}
export type UpdateEbicsUserMutationHookResult = ReturnType<typeof useUpdateEbicsUserMutation>;
export type UpdateEbicsUserMutationResult = Apollo.MutationResult<UpdateEbicsUserMutation>;
export type UpdateEbicsUserMutationOptions = Apollo.BaseMutationOptions<UpdateEbicsUserMutation, UpdateEbicsUserMutationVariables>;
export const DeleteEbicsUserDocument = gql`
  mutation DeleteEbicsUser($ebicsUserId: ID!) {
    deleteEbicsUser(ebicsUserId: $ebicsUserId) {
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeleteEbicsUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEbicsUserMutation, DeleteEbicsUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEbicsUserMutation, DeleteEbicsUserMutationVariables>(DeleteEbicsUserDocument, options);
}
export type DeleteEbicsUserMutationHookResult = ReturnType<typeof useDeleteEbicsUserMutation>;
export type DeleteEbicsUserMutationResult = Apollo.MutationResult<DeleteEbicsUserMutation>;
export type DeleteEbicsUserMutationOptions = Apollo.BaseMutationOptions<DeleteEbicsUserMutation, DeleteEbicsUserMutationVariables>;
export const ActivateEbicsUserDocument = gql`
  mutation ActivateEbicsUser($ebicsUserId: ID!) {
    actionActivateEbicsUser(ebicsUserId: $ebicsUserId) {
      data {
        ebicsUserId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useActivateEbicsUserMutation(
  baseOptions?: Apollo.MutationHookOptions<ActivateEbicsUserMutation, ActivateEbicsUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ActivateEbicsUserMutation, ActivateEbicsUserMutationVariables>(ActivateEbicsUserDocument, options);
}
export type ActivateEbicsUserMutationHookResult = ReturnType<typeof useActivateEbicsUserMutation>;
export type ActivateEbicsUserMutationResult = Apollo.MutationResult<ActivateEbicsUserMutation>;
export type ActivateEbicsUserMutationOptions = Apollo.BaseMutationOptions<ActivateEbicsUserMutation, ActivateEbicsUserMutationVariables>;
export const DeactivateEbicsUserDocument = gql`
  mutation DeactivateEbicsUser($ebicsUserId: ID!) {
    actionDeactivateEbicsUser(ebicsUserId: $ebicsUserId) {
      data {
        ebicsUserId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeactivateEbicsUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeactivateEbicsUserMutation, DeactivateEbicsUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeactivateEbicsUserMutation, DeactivateEbicsUserMutationVariables>(DeactivateEbicsUserDocument, options);
}
export type DeactivateEbicsUserMutationHookResult = ReturnType<typeof useDeactivateEbicsUserMutation>;
export type DeactivateEbicsUserMutationResult = Apollo.MutationResult<DeactivateEbicsUserMutation>;
export type DeactivateEbicsUserMutationOptions = Apollo.BaseMutationOptions<DeactivateEbicsUserMutation, DeactivateEbicsUserMutationVariables>;
