import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OnDataChangedSubscriptionVariables = Types.Exact<{ [key: string]: never }>;

export type OnDataChangedSubscription = {
  dataChanged: {
    changeType: Types.DataChangedChangeType;
    type: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    paramList: Array<{ key: string; value: string }>;
  };
};

export const OnDataChangedDocument = gql`
  subscription OnDataChanged {
    dataChanged {
      changeType
      type
      paramList {
        key
        value
      }
      createdBy
      createdByMitarbeiterId
    }
  }
`;
export function useOnDataChangedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<OnDataChangedSubscription, OnDataChangedSubscriptionVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnDataChangedSubscription, OnDataChangedSubscriptionVariables>(OnDataChangedDocument, options);
}
export type OnDataChangedSubscriptionHookResult = ReturnType<typeof useOnDataChangedSubscription>;
export type OnDataChangedSubscriptionResult = Apollo.SubscriptionResult<OnDataChangedSubscription>;
