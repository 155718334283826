import { OffenePostenDebitorListData } from '../templates/offenePosten/Debitor/op-list-debitor-types';

export const mockOpDebitorList: OffenePostenDebitorListData = {
  type: 'OFFENE_POSTEN',
  title: 'Offene Posten Debitoren',
  objektText: 'für WEG Döblinger HauptStr.44',
  dueDateToText: 'mit Fälligkeit bis 05.06.2024',
  debitorListHeader: {
    belegnummerText: 'Beleg-Nr.',
    bezeichnungText: 'Bezeichnung',
    buchungsdatumText: 'Buchungsdatum',
    dueDateText: 'Fällig am',
    sumBruttoText: 'Summe Brutto',
    zahlungsbetragText: 'Zahlungsbetrag',
    offenerBetragText: 'Offener Betrag',
    mahnstufeText: 'Mahnstufe',
  },
  debitorListSum: { sumText: 'Gesamtsumme', sumBrutto: '42.757,02', sumZahlungsbetrag: '1.000,00', sumOffenerBetrag: '41.757,02' },
  debitorList: [
    {
      vertragspartnerBezeichnung: 'Messiner-Karwan, C.',
      summary: {
        sumText: 'Summe',
        sumBrutto: '5.227,02',
        sumZahlungsbetrag: '0,00',
        sumOffenerBetrag: '5.227,02',
      },
      belegList: [
        {
          belegnummer: '2024/00026',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/09',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '290,39',
          zahlungsbetrag: '0,00',
          offenerBetrag: '290,39',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00026',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/07',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '290,39',
          zahlungsbetrag: '0,00',
          offenerBetrag: '290,39',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00026',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/06',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '290,39',
          zahlungsbetrag: '0,00',
          offenerBetrag: '290,39',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00046',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/03',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '290,39',
          zahlungsbetrag: '0,00',
          offenerBetrag: '290,39',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00026',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/01',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '290,39',
          zahlungsbetrag: '0,00',
          offenerBetrag: '290,39',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00026',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/12',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '290,39',
          zahlungsbetrag: '0,00',
          offenerBetrag: '290,39',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00046',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/01',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '290,39',
          zahlungsbetrag: '0,00',
          offenerBetrag: '290,39',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00046',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/05',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '290,39',
          zahlungsbetrag: '0,00',
          offenerBetrag: '290,39',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00026',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/08',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '290,39',
          zahlungsbetrag: '0,00',
          offenerBetrag: '290,39',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00026',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/05',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '290,39',
          zahlungsbetrag: '0,00',
          offenerBetrag: '290,39',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00046',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/02',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '290,39',
          zahlungsbetrag: '0,00',
          offenerBetrag: '290,39',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00046',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/06',
          buchungsdatum: '04.06.2024',
          dueDate: '05.06.2024',
          brutto: '290,39',
          zahlungsbetrag: '0,00',
          offenerBetrag: '290,39',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00026',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/03',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '290,39',
          zahlungsbetrag: '0,00',
          offenerBetrag: '290,39',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00026',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/02',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '290,39',
          zahlungsbetrag: '0,00',
          offenerBetrag: '290,39',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00026',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/10',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '290,39',
          zahlungsbetrag: '0,00',
          offenerBetrag: '290,39',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00046',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/04',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '290,39',
          zahlungsbetrag: '0,00',
          offenerBetrag: '290,39',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00026',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/04',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '290,39',
          zahlungsbetrag: '0,00',
          offenerBetrag: '290,39',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00026',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/11',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '290,39',
          zahlungsbetrag: '0,00',
          offenerBetrag: '290,39',
          mahnstufe: '0',
        },
      ],
    },
    {
      vertragspartnerBezeichnung: 'MANUEL, Judith',
      summary: {
        sumText: 'Summe',
        sumBrutto: '3.287,34',
        sumZahlungsbetrag: '0,00',
        sumOffenerBetrag: '3.287,34',
      },
      belegList: [
        {
          belegnummer: '2024/00050',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/05',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '182,63',
          zahlungsbetrag: '0,00',
          offenerBetrag: '182,63',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00077',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/08',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '182,63',
          zahlungsbetrag: '0,00',
          offenerBetrag: '182,63',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00050',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/03',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '182,63',
          zahlungsbetrag: '0,00',
          offenerBetrag: '182,63',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00050',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/02',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '182,63',
          zahlungsbetrag: '0,00',
          offenerBetrag: '182,63',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00077',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/07',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '182,63',
          zahlungsbetrag: '0,00',
          offenerBetrag: '182,63',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00077',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/09',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '182,63',
          zahlungsbetrag: '0,00',
          offenerBetrag: '182,63',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00077',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/10',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '182,63',
          zahlungsbetrag: '0,00',
          offenerBetrag: '182,63',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00077',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/04',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '182,63',
          zahlungsbetrag: '0,00',
          offenerBetrag: '182,63',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00050',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/04',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '182,63',
          zahlungsbetrag: '0,00',
          offenerBetrag: '182,63',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00077',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/02',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '182,63',
          zahlungsbetrag: '0,00',
          offenerBetrag: '182,63',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00050',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/01',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '182,63',
          zahlungsbetrag: '0,00',
          offenerBetrag: '182,63',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00077',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/03',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '182,63',
          zahlungsbetrag: '0,00',
          offenerBetrag: '182,63',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00077',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/05',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '182,63',
          zahlungsbetrag: '0,00',
          offenerBetrag: '182,63',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00077',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/06',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '182,63',
          zahlungsbetrag: '0,00',
          offenerBetrag: '182,63',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00077',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/12',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '182,63',
          zahlungsbetrag: '0,00',
          offenerBetrag: '182,63',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00077',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/11',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '182,63',
          zahlungsbetrag: '0,00',
          offenerBetrag: '182,63',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00077',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/01',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '182,63',
          zahlungsbetrag: '0,00',
          offenerBetrag: '182,63',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00050',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/06',
          buchungsdatum: '04.06.2024',
          dueDate: '05.06.2024',
          brutto: '182,63',
          zahlungsbetrag: '0,00',
          offenerBetrag: '182,63',
          mahnstufe: '0',
        },
      ],
    },
    {
      vertragspartnerBezeichnung: 'Schwatzek S u. E.',
      summary: {
        sumText: 'Summe',
        sumBrutto: '3.984,48',
        sumZahlungsbetrag: '0,00',
        sumOffenerBetrag: '3.984,48',
      },
      belegList: [
        {
          belegnummer: '2024/00056',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2024/06',
          buchungsdatum: '04.06.2024',
          dueDate: '05.06.2024',
          brutto: '221,36',
          zahlungsbetrag: '0,00',
          offenerBetrag: '221,36',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00063',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/04',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '221,36',
          zahlungsbetrag: '0,00',
          offenerBetrag: '221,36',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00019',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/11',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '221,36',
          zahlungsbetrag: '0,00',
          offenerBetrag: '221,36',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00031',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/03',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '221,36',
          zahlungsbetrag: '0,00',
          offenerBetrag: '221,36',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00113',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/08',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '221,36',
          zahlungsbetrag: '0,00',
          offenerBetrag: '221,36',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00033',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/07',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '221,36',
          zahlungsbetrag: '0,00',
          offenerBetrag: '221,36',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00108',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2024/02',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '221,36',
          zahlungsbetrag: '0,00',
          offenerBetrag: '221,36',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00003',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/02',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '221,36',
          zahlungsbetrag: '0,00',
          offenerBetrag: '221,36',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00099',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/01',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '221,36',
          zahlungsbetrag: '0,00',
          offenerBetrag: '221,36',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00116',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2024/05',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '221,36',
          zahlungsbetrag: '0,00',
          offenerBetrag: '221,36',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00023',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/09',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '221,36',
          zahlungsbetrag: '0,00',
          offenerBetrag: '221,36',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00091',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/12',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '221,36',
          zahlungsbetrag: '0,00',
          offenerBetrag: '221,36',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00097',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/06',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '221,36',
          zahlungsbetrag: '0,00',
          offenerBetrag: '221,36',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00095',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/05',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '221,36',
          zahlungsbetrag: '0,00',
          offenerBetrag: '221,36',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00027',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2024/03',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '221,36',
          zahlungsbetrag: '0,00',
          offenerBetrag: '221,36',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00074',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2024/04',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '221,36',
          zahlungsbetrag: '0,00',
          offenerBetrag: '221,36',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00093',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2024/01',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '221,36',
          zahlungsbetrag: '0,00',
          offenerBetrag: '221,36',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00109',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/10',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '221,36',
          zahlungsbetrag: '0,00',
          offenerBetrag: '221,36',
          mahnstufe: '0',
        },
      ],
    },
    {
      vertragspartnerBezeichnung: 'Steiner, Silvia',
      summary: {
        sumText: 'Summe',
        sumBrutto: '4.340,88',
        sumZahlungsbetrag: '0,00',
        sumOffenerBetrag: '4.340,88',
      },
      belegList: [
        {
          belegnummer: '2024/00070',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/05',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '241,16',
          zahlungsbetrag: '0,00',
          offenerBetrag: '241,16',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00070',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/03',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '241,16',
          zahlungsbetrag: '0,00',
          offenerBetrag: '241,16',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00071',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/12',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '241,16',
          zahlungsbetrag: '0,00',
          offenerBetrag: '241,16',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00071',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/03',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '241,16',
          zahlungsbetrag: '0,00',
          offenerBetrag: '241,16',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00071',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/02',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '241,16',
          zahlungsbetrag: '0,00',
          offenerBetrag: '241,16',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00070',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/02',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '241,16',
          zahlungsbetrag: '0,00',
          offenerBetrag: '241,16',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00071',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/08',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '241,16',
          zahlungsbetrag: '0,00',
          offenerBetrag: '241,16',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00071',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/01',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '241,16',
          zahlungsbetrag: '0,00',
          offenerBetrag: '241,16',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00070',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/04',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '241,16',
          zahlungsbetrag: '0,00',
          offenerBetrag: '241,16',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00071',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/06',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '241,16',
          zahlungsbetrag: '0,00',
          offenerBetrag: '241,16',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00071',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/07',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '241,16',
          zahlungsbetrag: '0,00',
          offenerBetrag: '241,16',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00071',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/10',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '241,16',
          zahlungsbetrag: '0,00',
          offenerBetrag: '241,16',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00070',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/01',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '241,16',
          zahlungsbetrag: '0,00',
          offenerBetrag: '241,16',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00071',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/11',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '241,16',
          zahlungsbetrag: '0,00',
          offenerBetrag: '241,16',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00070',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/06',
          buchungsdatum: '04.06.2024',
          dueDate: '05.06.2024',
          brutto: '241,16',
          zahlungsbetrag: '0,00',
          offenerBetrag: '241,16',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00071',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/09',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '241,16',
          zahlungsbetrag: '0,00',
          offenerBetrag: '241,16',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00071',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/04',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '241,16',
          zahlungsbetrag: '0,00',
          offenerBetrag: '241,16',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00071',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/05',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '241,16',
          zahlungsbetrag: '0,00',
          offenerBetrag: '241,16',
          mahnstufe: '0',
        },
      ],
    },
    {
      vertragspartnerBezeichnung: 'Lingens, Denise',
      summary: {
        sumText: 'Summe',
        sumBrutto: '4.718,16',
        sumZahlungsbetrag: '0,00',
        sumOffenerBetrag: '4.718,16',
      },
      belegList: [
        {
          belegnummer: '2024/00036',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/09',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '262,12',
          zahlungsbetrag: '0,00',
          offenerBetrag: '262,12',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00036',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/04',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '262,12',
          zahlungsbetrag: '0,00',
          offenerBetrag: '262,12',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00037',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/03',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '262,12',
          zahlungsbetrag: '0,00',
          offenerBetrag: '262,12',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00036',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/01',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '262,12',
          zahlungsbetrag: '0,00',
          offenerBetrag: '262,12',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00036',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/11',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '262,12',
          zahlungsbetrag: '0,00',
          offenerBetrag: '262,12',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00036',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/03',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '262,12',
          zahlungsbetrag: '0,00',
          offenerBetrag: '262,12',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00036',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/08',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '262,12',
          zahlungsbetrag: '0,00',
          offenerBetrag: '262,12',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00037',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/05',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '262,12',
          zahlungsbetrag: '0,00',
          offenerBetrag: '262,12',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00037',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/01',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '262,12',
          zahlungsbetrag: '0,00',
          offenerBetrag: '262,12',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00037',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/06',
          buchungsdatum: '04.06.2024',
          dueDate: '05.06.2024',
          brutto: '262,12',
          zahlungsbetrag: '0,00',
          offenerBetrag: '262,12',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00036',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/02',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '262,12',
          zahlungsbetrag: '0,00',
          offenerBetrag: '262,12',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00036',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/07',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '262,12',
          zahlungsbetrag: '0,00',
          offenerBetrag: '262,12',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00037',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/02',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '262,12',
          zahlungsbetrag: '0,00',
          offenerBetrag: '262,12',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00036',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/10',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '262,12',
          zahlungsbetrag: '0,00',
          offenerBetrag: '262,12',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00036',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/06',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '262,12',
          zahlungsbetrag: '0,00',
          offenerBetrag: '262,12',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00036',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/05',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '262,12',
          zahlungsbetrag: '0,00',
          offenerBetrag: '262,12',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00036',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/12',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '262,12',
          zahlungsbetrag: '0,00',
          offenerBetrag: '262,12',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00037',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/04',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '262,12',
          zahlungsbetrag: '0,00',
          offenerBetrag: '262,12',
          mahnstufe: '0',
        },
      ],
    },
    {
      vertragspartnerBezeichnung: 'Luisser, Mag. Richard',
      summary: {
        sumText: 'Summe',
        sumBrutto: '2.282,40',
        sumZahlungsbetrag: '0,00',
        sumOffenerBetrag: '2.282,40',
      },
      belegList: [
        {
          belegnummer: '2024/00017',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/12',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '126,80',
          zahlungsbetrag: '0,00',
          offenerBetrag: '126,80',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00017',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/08',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '126,80',
          zahlungsbetrag: '0,00',
          offenerBetrag: '126,80',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00058',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/04',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '126,80',
          zahlungsbetrag: '0,00',
          offenerBetrag: '126,80',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00058',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/02',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '126,80',
          zahlungsbetrag: '0,00',
          offenerBetrag: '126,80',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00017',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/02',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '126,80',
          zahlungsbetrag: '0,00',
          offenerBetrag: '126,80',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00017',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/07',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '126,80',
          zahlungsbetrag: '0,00',
          offenerBetrag: '126,80',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00017',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/05',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '126,80',
          zahlungsbetrag: '0,00',
          offenerBetrag: '126,80',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00058',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/03',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '126,80',
          zahlungsbetrag: '0,00',
          offenerBetrag: '126,80',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00058',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/01',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '126,80',
          zahlungsbetrag: '0,00',
          offenerBetrag: '126,80',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00017',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/04',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '126,80',
          zahlungsbetrag: '0,00',
          offenerBetrag: '126,80',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00017',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/06',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '126,80',
          zahlungsbetrag: '0,00',
          offenerBetrag: '126,80',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00058',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/05',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '126,80',
          zahlungsbetrag: '0,00',
          offenerBetrag: '126,80',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00017',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/11',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '126,80',
          zahlungsbetrag: '0,00',
          offenerBetrag: '126,80',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00017',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/01',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '126,80',
          zahlungsbetrag: '0,00',
          offenerBetrag: '126,80',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00017',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/09',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '126,80',
          zahlungsbetrag: '0,00',
          offenerBetrag: '126,80',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00017',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/10',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '126,80',
          zahlungsbetrag: '0,00',
          offenerBetrag: '126,80',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00058',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/06',
          buchungsdatum: '04.06.2024',
          dueDate: '05.06.2024',
          brutto: '126,80',
          zahlungsbetrag: '0,00',
          offenerBetrag: '126,80',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00017',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/03',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '126,80',
          zahlungsbetrag: '0,00',
          offenerBetrag: '126,80',
          mahnstufe: '0',
        },
      ],
    },
    {
      vertragspartnerBezeichnung: 'Brand, Mag. Petra',
      summary: {
        sumText: 'Summe',
        sumBrutto: '5.850,54',
        sumZahlungsbetrag: '1.000,00',
        sumOffenerBetrag: '4.850,54',
      },
      belegList: [
        {
          belegnummer: '2024/00069',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/01',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '325,03',
          zahlungsbetrag: '0,00',
          offenerBetrag: '325,03',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00069',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/03',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '325,03',
          zahlungsbetrag: '325,03',
          offenerBetrag: '0,00',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00068',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/04',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '325,03',
          zahlungsbetrag: '325,03',
          offenerBetrag: '0,00',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00068',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/06',
          buchungsdatum: '04.06.2024',
          dueDate: '05.06.2024',
          brutto: '325,03',
          zahlungsbetrag: '325,03',
          offenerBetrag: '0,00',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00069',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/12',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '325,03',
          zahlungsbetrag: '24,91',
          offenerBetrag: '300,12',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00068',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/03',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '325,03',
          zahlungsbetrag: '0,00',
          offenerBetrag: '325,03',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00068',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/02',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '325,03',
          zahlungsbetrag: '0,00',
          offenerBetrag: '325,03',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00069',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/02',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '325,03',
          zahlungsbetrag: '0,00',
          offenerBetrag: '325,03',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00069',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/11',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '325,03',
          zahlungsbetrag: '0,00',
          offenerBetrag: '325,03',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00069',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/09',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '325,03',
          zahlungsbetrag: '0,00',
          offenerBetrag: '325,03',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00069',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/06',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '325,03',
          zahlungsbetrag: '0,00',
          offenerBetrag: '325,03',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00068',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/05',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '325,03',
          zahlungsbetrag: '0,00',
          offenerBetrag: '325,03',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00069',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/05',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '325,03',
          zahlungsbetrag: '0,00',
          offenerBetrag: '325,03',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00069',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/04',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '325,03',
          zahlungsbetrag: '0,00',
          offenerBetrag: '325,03',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00069',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/08',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '325,03',
          zahlungsbetrag: '0,00',
          offenerBetrag: '325,03',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00069',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/07',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '325,03',
          zahlungsbetrag: '0,00',
          offenerBetrag: '325,03',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00069',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/10',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '325,03',
          zahlungsbetrag: '0,00',
          offenerBetrag: '325,03',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00068',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/01',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '325,03',
          zahlungsbetrag: '0,00',
          offenerBetrag: '325,03',
          mahnstufe: '0',
        },
      ],
    },
    {
      vertragspartnerBezeichnung: 'Otto Immobilien',
      summary: {
        sumText: 'Summe',
        sumBrutto: '11.859,48',
        sumZahlungsbetrag: '0,00',
        sumOffenerBetrag: '11.859,48',
      },
      belegList: [
        {
          belegnummer: '2024/00120',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2024/05',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '658,86',
          zahlungsbetrag: '0,00',
          offenerBetrag: '658,86',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00007',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/03',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '658,86',
          zahlungsbetrag: '0,00',
          offenerBetrag: '658,86',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00102',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/01',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '658,86',
          zahlungsbetrag: '0,00',
          offenerBetrag: '658,86',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00034',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2024/06',
          buchungsdatum: '04.06.2024',
          dueDate: '05.06.2024',
          brutto: '658,86',
          zahlungsbetrag: '0,00',
          offenerBetrag: '658,86',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00060',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2024/03',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '658,86',
          zahlungsbetrag: '0,00',
          offenerBetrag: '658,86',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00024',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/09',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '658,86',
          zahlungsbetrag: '0,00',
          offenerBetrag: '658,86',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00038',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/06',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '658,86',
          zahlungsbetrag: '0,00',
          offenerBetrag: '658,86',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00049',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2024/01',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '658,86',
          zahlungsbetrag: '0,00',
          offenerBetrag: '658,86',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00005',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/02',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '658,86',
          zahlungsbetrag: '0,00',
          offenerBetrag: '658,86',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00105',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2024/02',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '658,86',
          zahlungsbetrag: '0,00',
          offenerBetrag: '658,86',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00053',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/12',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '658,86',
          zahlungsbetrag: '0,00',
          offenerBetrag: '658,86',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00032',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/07',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '658,86',
          zahlungsbetrag: '0,00',
          offenerBetrag: '658,86',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00035',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/08',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '658,86',
          zahlungsbetrag: '0,00',
          offenerBetrag: '658,86',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00112',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/10',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '658,86',
          zahlungsbetrag: '0,00',
          offenerBetrag: '658,86',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00072',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2024/04',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '658,86',
          zahlungsbetrag: '0,00',
          offenerBetrag: '658,86',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00062',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/04',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '658,86',
          zahlungsbetrag: '0,00',
          offenerBetrag: '658,86',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00047',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/11',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '658,86',
          zahlungsbetrag: '0,00',
          offenerBetrag: '658,86',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00096',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Einzelrechnung 2023/05',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '658,86',
          zahlungsbetrag: '0,00',
          offenerBetrag: '658,86',
          mahnstufe: '0',
        },
      ],
    },
    {
      vertragspartnerBezeichnung: 'Wallisch, Dr. Alexandra',
      summary: {
        sumText: 'Summe',
        sumBrutto: '1.206,72',
        sumZahlungsbetrag: '0,00',
        sumOffenerBetrag: '1.206,72',
      },
      belegList: [
        {
          belegnummer: '2024/00084',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/06',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '67,04',
          zahlungsbetrag: '0,00',
          offenerBetrag: '67,04',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00083',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/02',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '67,04',
          zahlungsbetrag: '0,00',
          offenerBetrag: '67,04',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00084',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/05',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '67,04',
          zahlungsbetrag: '0,00',
          offenerBetrag: '67,04',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00083',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/06',
          buchungsdatum: '04.06.2024',
          dueDate: '05.06.2024',
          brutto: '67,04',
          zahlungsbetrag: '0,00',
          offenerBetrag: '67,04',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00083',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/04',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '67,04',
          zahlungsbetrag: '0,00',
          offenerBetrag: '67,04',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00083',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/01',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '67,04',
          zahlungsbetrag: '0,00',
          offenerBetrag: '67,04',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00083',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/05',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '67,04',
          zahlungsbetrag: '0,00',
          offenerBetrag: '67,04',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00084',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/10',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '67,04',
          zahlungsbetrag: '0,00',
          offenerBetrag: '67,04',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00084',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/11',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '67,04',
          zahlungsbetrag: '0,00',
          offenerBetrag: '67,04',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00084',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/09',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '67,04',
          zahlungsbetrag: '0,00',
          offenerBetrag: '67,04',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00084',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/07',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '67,04',
          zahlungsbetrag: '0,00',
          offenerBetrag: '67,04',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00084',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/12',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '67,04',
          zahlungsbetrag: '0,00',
          offenerBetrag: '67,04',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00084',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/01',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '67,04',
          zahlungsbetrag: '0,00',
          offenerBetrag: '67,04',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00084',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/08',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '67,04',
          zahlungsbetrag: '0,00',
          offenerBetrag: '67,04',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00083',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2024/03',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '67,04',
          zahlungsbetrag: '0,00',
          offenerBetrag: '67,04',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00084',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/02',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '67,04',
          zahlungsbetrag: '0,00',
          offenerBetrag: '67,04',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00084',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/03',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '67,04',
          zahlungsbetrag: '0,00',
          offenerBetrag: '67,04',
          mahnstufe: '0',
        },
        {
          belegnummer: '2024/00084',
          rechnungsAusstellerBezeichnung: 'WEG Döblinger HauptStr.44',
          buchungstext: 'VS Dauerrechnung 2023/04',
          buchungsdatum: '04.06.2024',
          dueDate: '04.06.2024',
          brutto: '67,04',
          zahlungsbetrag: '0,00',
          offenerBetrag: '67,04',
          mahnstufe: '0',
        },
      ],
    },
  ],
};
