import React, { FC } from 'react';
import { Select } from 'formik-antd';
import { SelectProps } from 'antd';
import { RechtstraegerInfoFeldType } from '../../../../types';

type RechtstraegerInfoFeldTypeFormSelectProps = { name: string } & SelectProps;

const RechtstraegerInfoFeldTypeFormSelect: FC<RechtstraegerInfoFeldTypeFormSelectProps> = ({ name, ...restProps }) => (
  <Select name={name} id={name} placeholder="Typ auswählen" allowClear style={{ minWidth: '160px' }} {...restProps}>
    {Object.entries(RechtstraegerInfoFeldType).map(([text, value]) => (
      <Select.Option value={value} key={value}>
        {text}
      </Select.Option>
    ))}
  </Select>
);

export default RechtstraegerInfoFeldTypeFormSelect;
