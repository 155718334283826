import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FirmendatenFileInfoBestandseinheitListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
}>;

export type FirmendatenFileInfoBestandseinheitListQuery = {
  getFirmendatenFileInfoBestandseinheitList: {
    data: Array<{ bestandseinheitId: string; bezeichnung: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FirmendatenFileInfoInvoiceNumberListQueryVariables = Types.Exact<{
  invoiceNumber?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type FirmendatenFileInfoInvoiceNumberListQuery = {
  getFirmendatenFileInfoInvoiceNumberList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FirmendatenFileInfoHomeownerListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FirmendatenFileInfoHomeownerListQuery = {
  getFirmendatenFileInfoHomeownerList: {
    data: Array<{ rechtstraegerId: string; kurzBezeichnung: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FirmendatenFileInfoInvoiceIssuerListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FirmendatenFileInfoInvoiceIssuerListQuery = {
  getFirmendatenFileInfoInvoiceIssuerList: {
    data: Array<{ rechtstraegerId: string; kurzBezeichnung: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FirmendatenFileInfoInvoiceRecipientListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FirmendatenFileInfoInvoiceRecipientListQuery = {
  getFirmendatenFileInfoInvoiceRecipientList: {
    data: Array<{ rechtstraegerId: string; kurzBezeichnung: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FirmendatenFileInfoObjektListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FirmendatenFileInfoObjektListQuery = {
  getFirmendatenFileInfoObjektList: {
    data: Array<{ objektId: string; kurzBezeichnung: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const FirmendatenFileInfoBestandseinheitListDocument = gql`
  query FirmendatenFileInfoBestandseinheitList($objektId: ID!) {
    getFirmendatenFileInfoBestandseinheitList(objektId: $objektId) {
      data {
        bestandseinheitId
        bezeichnung
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFirmendatenFileInfoBestandseinheitListQuery(
  baseOptions: Apollo.QueryHookOptions<FirmendatenFileInfoBestandseinheitListQuery, FirmendatenFileInfoBestandseinheitListQueryVariables> &
    ({ variables: FirmendatenFileInfoBestandseinheitListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenFileInfoBestandseinheitListQuery, FirmendatenFileInfoBestandseinheitListQueryVariables>(
    FirmendatenFileInfoBestandseinheitListDocument,
    options
  );
}
export function useFirmendatenFileInfoBestandseinheitListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenFileInfoBestandseinheitListQuery, FirmendatenFileInfoBestandseinheitListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenFileInfoBestandseinheitListQuery, FirmendatenFileInfoBestandseinheitListQueryVariables>(
    FirmendatenFileInfoBestandseinheitListDocument,
    options
  );
}
export function useFirmendatenFileInfoBestandseinheitListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FirmendatenFileInfoBestandseinheitListQuery, FirmendatenFileInfoBestandseinheitListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenFileInfoBestandseinheitListQuery, FirmendatenFileInfoBestandseinheitListQueryVariables>(
    FirmendatenFileInfoBestandseinheitListDocument,
    options
  );
}
export type FirmendatenFileInfoBestandseinheitListQueryHookResult = ReturnType<typeof useFirmendatenFileInfoBestandseinheitListQuery>;
export type FirmendatenFileInfoBestandseinheitListLazyQueryHookResult = ReturnType<typeof useFirmendatenFileInfoBestandseinheitListLazyQuery>;
export type FirmendatenFileInfoBestandseinheitListSuspenseQueryHookResult = ReturnType<typeof useFirmendatenFileInfoBestandseinheitListSuspenseQuery>;
export type FirmendatenFileInfoBestandseinheitListQueryResult = Apollo.QueryResult<
  FirmendatenFileInfoBestandseinheitListQuery,
  FirmendatenFileInfoBestandseinheitListQueryVariables
>;
export const FirmendatenFileInfoInvoiceNumberListDocument = gql`
  query FirmendatenFileInfoInvoiceNumberList($invoiceNumber: String) {
    getFirmendatenFileInfoInvoiceNumberList(invoiceNumber: $invoiceNumber) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFirmendatenFileInfoInvoiceNumberListQuery(
  baseOptions?: Apollo.QueryHookOptions<FirmendatenFileInfoInvoiceNumberListQuery, FirmendatenFileInfoInvoiceNumberListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenFileInfoInvoiceNumberListQuery, FirmendatenFileInfoInvoiceNumberListQueryVariables>(
    FirmendatenFileInfoInvoiceNumberListDocument,
    options
  );
}
export function useFirmendatenFileInfoInvoiceNumberListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenFileInfoInvoiceNumberListQuery, FirmendatenFileInfoInvoiceNumberListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenFileInfoInvoiceNumberListQuery, FirmendatenFileInfoInvoiceNumberListQueryVariables>(
    FirmendatenFileInfoInvoiceNumberListDocument,
    options
  );
}
export function useFirmendatenFileInfoInvoiceNumberListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FirmendatenFileInfoInvoiceNumberListQuery, FirmendatenFileInfoInvoiceNumberListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenFileInfoInvoiceNumberListQuery, FirmendatenFileInfoInvoiceNumberListQueryVariables>(
    FirmendatenFileInfoInvoiceNumberListDocument,
    options
  );
}
export type FirmendatenFileInfoInvoiceNumberListQueryHookResult = ReturnType<typeof useFirmendatenFileInfoInvoiceNumberListQuery>;
export type FirmendatenFileInfoInvoiceNumberListLazyQueryHookResult = ReturnType<typeof useFirmendatenFileInfoInvoiceNumberListLazyQuery>;
export type FirmendatenFileInfoInvoiceNumberListSuspenseQueryHookResult = ReturnType<typeof useFirmendatenFileInfoInvoiceNumberListSuspenseQuery>;
export type FirmendatenFileInfoInvoiceNumberListQueryResult = Apollo.QueryResult<
  FirmendatenFileInfoInvoiceNumberListQuery,
  FirmendatenFileInfoInvoiceNumberListQueryVariables
>;
export const FirmendatenFileInfoHomeownerListDocument = gql`
  query FirmendatenFileInfoHomeownerList {
    getFirmendatenFileInfoHomeownerList {
      data {
        rechtstraegerId
        kurzBezeichnung
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFirmendatenFileInfoHomeownerListQuery(
  baseOptions?: Apollo.QueryHookOptions<FirmendatenFileInfoHomeownerListQuery, FirmendatenFileInfoHomeownerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenFileInfoHomeownerListQuery, FirmendatenFileInfoHomeownerListQueryVariables>(
    FirmendatenFileInfoHomeownerListDocument,
    options
  );
}
export function useFirmendatenFileInfoHomeownerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenFileInfoHomeownerListQuery, FirmendatenFileInfoHomeownerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenFileInfoHomeownerListQuery, FirmendatenFileInfoHomeownerListQueryVariables>(
    FirmendatenFileInfoHomeownerListDocument,
    options
  );
}
export function useFirmendatenFileInfoHomeownerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FirmendatenFileInfoHomeownerListQuery, FirmendatenFileInfoHomeownerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenFileInfoHomeownerListQuery, FirmendatenFileInfoHomeownerListQueryVariables>(
    FirmendatenFileInfoHomeownerListDocument,
    options
  );
}
export type FirmendatenFileInfoHomeownerListQueryHookResult = ReturnType<typeof useFirmendatenFileInfoHomeownerListQuery>;
export type FirmendatenFileInfoHomeownerListLazyQueryHookResult = ReturnType<typeof useFirmendatenFileInfoHomeownerListLazyQuery>;
export type FirmendatenFileInfoHomeownerListSuspenseQueryHookResult = ReturnType<typeof useFirmendatenFileInfoHomeownerListSuspenseQuery>;
export type FirmendatenFileInfoHomeownerListQueryResult = Apollo.QueryResult<
  FirmendatenFileInfoHomeownerListQuery,
  FirmendatenFileInfoHomeownerListQueryVariables
>;
export const FirmendatenFileInfoInvoiceIssuerListDocument = gql`
  query FirmendatenFileInfoInvoiceIssuerList {
    getFirmendatenFileInfoInvoiceIssuerList {
      data {
        rechtstraegerId
        kurzBezeichnung
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFirmendatenFileInfoInvoiceIssuerListQuery(
  baseOptions?: Apollo.QueryHookOptions<FirmendatenFileInfoInvoiceIssuerListQuery, FirmendatenFileInfoInvoiceIssuerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenFileInfoInvoiceIssuerListQuery, FirmendatenFileInfoInvoiceIssuerListQueryVariables>(
    FirmendatenFileInfoInvoiceIssuerListDocument,
    options
  );
}
export function useFirmendatenFileInfoInvoiceIssuerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenFileInfoInvoiceIssuerListQuery, FirmendatenFileInfoInvoiceIssuerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenFileInfoInvoiceIssuerListQuery, FirmendatenFileInfoInvoiceIssuerListQueryVariables>(
    FirmendatenFileInfoInvoiceIssuerListDocument,
    options
  );
}
export function useFirmendatenFileInfoInvoiceIssuerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FirmendatenFileInfoInvoiceIssuerListQuery, FirmendatenFileInfoInvoiceIssuerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenFileInfoInvoiceIssuerListQuery, FirmendatenFileInfoInvoiceIssuerListQueryVariables>(
    FirmendatenFileInfoInvoiceIssuerListDocument,
    options
  );
}
export type FirmendatenFileInfoInvoiceIssuerListQueryHookResult = ReturnType<typeof useFirmendatenFileInfoInvoiceIssuerListQuery>;
export type FirmendatenFileInfoInvoiceIssuerListLazyQueryHookResult = ReturnType<typeof useFirmendatenFileInfoInvoiceIssuerListLazyQuery>;
export type FirmendatenFileInfoInvoiceIssuerListSuspenseQueryHookResult = ReturnType<typeof useFirmendatenFileInfoInvoiceIssuerListSuspenseQuery>;
export type FirmendatenFileInfoInvoiceIssuerListQueryResult = Apollo.QueryResult<
  FirmendatenFileInfoInvoiceIssuerListQuery,
  FirmendatenFileInfoInvoiceIssuerListQueryVariables
>;
export const FirmendatenFileInfoInvoiceRecipientListDocument = gql`
  query FirmendatenFileInfoInvoiceRecipientList {
    getFirmendatenFileInfoInvoiceRecipientList {
      data {
        rechtstraegerId
        kurzBezeichnung
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFirmendatenFileInfoInvoiceRecipientListQuery(
  baseOptions?: Apollo.QueryHookOptions<FirmendatenFileInfoInvoiceRecipientListQuery, FirmendatenFileInfoInvoiceRecipientListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenFileInfoInvoiceRecipientListQuery, FirmendatenFileInfoInvoiceRecipientListQueryVariables>(
    FirmendatenFileInfoInvoiceRecipientListDocument,
    options
  );
}
export function useFirmendatenFileInfoInvoiceRecipientListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenFileInfoInvoiceRecipientListQuery, FirmendatenFileInfoInvoiceRecipientListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenFileInfoInvoiceRecipientListQuery, FirmendatenFileInfoInvoiceRecipientListQueryVariables>(
    FirmendatenFileInfoInvoiceRecipientListDocument,
    options
  );
}
export function useFirmendatenFileInfoInvoiceRecipientListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FirmendatenFileInfoInvoiceRecipientListQuery, FirmendatenFileInfoInvoiceRecipientListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenFileInfoInvoiceRecipientListQuery, FirmendatenFileInfoInvoiceRecipientListQueryVariables>(
    FirmendatenFileInfoInvoiceRecipientListDocument,
    options
  );
}
export type FirmendatenFileInfoInvoiceRecipientListQueryHookResult = ReturnType<typeof useFirmendatenFileInfoInvoiceRecipientListQuery>;
export type FirmendatenFileInfoInvoiceRecipientListLazyQueryHookResult = ReturnType<typeof useFirmendatenFileInfoInvoiceRecipientListLazyQuery>;
export type FirmendatenFileInfoInvoiceRecipientListSuspenseQueryHookResult = ReturnType<
  typeof useFirmendatenFileInfoInvoiceRecipientListSuspenseQuery
>;
export type FirmendatenFileInfoInvoiceRecipientListQueryResult = Apollo.QueryResult<
  FirmendatenFileInfoInvoiceRecipientListQuery,
  FirmendatenFileInfoInvoiceRecipientListQueryVariables
>;
export const FirmendatenFileInfoObjektListDocument = gql`
  query FirmendatenFileInfoObjektList {
    getFirmendatenFileInfoObjektList {
      data {
        objektId
        kurzBezeichnung
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFirmendatenFileInfoObjektListQuery(
  baseOptions?: Apollo.QueryHookOptions<FirmendatenFileInfoObjektListQuery, FirmendatenFileInfoObjektListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenFileInfoObjektListQuery, FirmendatenFileInfoObjektListQueryVariables>(
    FirmendatenFileInfoObjektListDocument,
    options
  );
}
export function useFirmendatenFileInfoObjektListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenFileInfoObjektListQuery, FirmendatenFileInfoObjektListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenFileInfoObjektListQuery, FirmendatenFileInfoObjektListQueryVariables>(
    FirmendatenFileInfoObjektListDocument,
    options
  );
}
export function useFirmendatenFileInfoObjektListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FirmendatenFileInfoObjektListQuery, FirmendatenFileInfoObjektListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenFileInfoObjektListQuery, FirmendatenFileInfoObjektListQueryVariables>(
    FirmendatenFileInfoObjektListDocument,
    options
  );
}
export type FirmendatenFileInfoObjektListQueryHookResult = ReturnType<typeof useFirmendatenFileInfoObjektListQuery>;
export type FirmendatenFileInfoObjektListLazyQueryHookResult = ReturnType<typeof useFirmendatenFileInfoObjektListLazyQuery>;
export type FirmendatenFileInfoObjektListSuspenseQueryHookResult = ReturnType<typeof useFirmendatenFileInfoObjektListSuspenseQuery>;
export type FirmendatenFileInfoObjektListQueryResult = Apollo.QueryResult<
  FirmendatenFileInfoObjektListQuery,
  FirmendatenFileInfoObjektListQueryVariables
>;
