import { TopAbrechnung, TopAbrechnungList } from '../templates/topAbrechnung/topAbrechnung-types';

export const mockTopAbrechnung1: TopAbrechnung = {
  topAbrechnungId: '001',
  deckblatt: {
    absender: { label: 'Abs:', value: 'andromeda software GmnH, Grillgasse 46; 1110 Wien' },
    rechnungsEmpfaengerShippingLabel: {
      line1: 'Familie',
      line2: 'Elke und Alexander Kuchar',
      line3: 'Galileigasse 3a',
      line4: 'Deutsch Wagram',
      line5: null,
      line6: null,
      line7: null,
    },

    abrechnungsDatumLine: 'Wien, 28.06.2017',

    kundennummer: {
      text: 'Kundennummer:',
      nummer: '41620',
    },

    einleitungsText: '{Platzhalter Einleitungstext}',

    abrechnungskreisSummary: {
      header: {
        abrechnungskreisText: '',
        aufwandText: 'Aufwand',
        vorschreibungText: 'Vorschreibung',
        nettoText: 'Netto',
        ustText: 'Ust',
        saldoText: 'Saldo',
      },
      abrechnungskreisList: [
        {
          bezeichnung: 'Betriebkosten',
          aufwandBetrag: '1.276,98',
          vorschreibungBetrag: '1.452,12',
          nettoBetrag: '-175,14',
          ustBetrag: '-15,51',
          saldoBetrag: '-192,65',
        },
        {
          bezeichnung: 'BK-Haus',
          aufwandBetrag: '310,32',
          vorschreibungBetrag: '304,92',
          nettoBetrag: '5,40',
          ustBetrag: '0,54',
          saldoBetrag: '5,94',
        },
        {
          bezeichnung: 'Lift',
          aufwandBetrag: '117,89',
          vorschreibungBetrag: '137,40',
          nettoBetrag: '-19,51',
          ustBetrag: '-1,95',
          saldoBetrag: '-21,46',
        },
        {
          bezeichnung: 'Vorsteuerkürzung',
          aufwandBetrag: '206,15',
          vorschreibungBetrag: '0,00',
          nettoBetrag: '206,15',
          ustBetrag: '0,00',
          saldoBetrag: '206,15',
        },
        {
          bezeichnung: 'Rep. Fonds',
          aufwandBetrag: '56,15',
          vorschreibungBetrag: '0,00',
          nettoBetrag: '56,15',
          ustBetrag: '0,00',
          saldoBetrag: '56,15',
        },
      ],
      summe: {
        aufwand: '1967,49',
        vorschreibung: '1.894,44',
        netto: '39,86',
        ust: '-18,92',
        saldo: '54,13',
      },
      ergebnis: {
        text: 'Nachzahlung',
        betrag: '54,13',
      },
    },

    ergebnisText: '{Platzhalter Endtext je nach Use-Case}',
    schlussText: 'Beste Grüße, Ihre {Hausverwaltung.kurzbezeichnung}',
  },

  abrechnungskreisDetails: {
    aufzaehlungsText: 'Abgrechnet werden:',
    header: {
      abrechnungsKreisText: 'Abrechnungskreis',
      betragText: 'Betrag (netto)',
      summeText: 'in €',
    },
    abrechnungskreisList: [
      {
        type: 'BEWIRTSCHAFTUNGSKOSTEN',
        index: 1,
        abrechnungskreisTitelText: '1. Abrechnung von Allg. Betriebskosten',

        aufteilungAufwandNonRepFondsList: [
          {
            aufwandAufteilung: {
              aufteilungschluesselText: 'Die Kostenaufteilung erfolgt nach folgendem Schlüssel: Allg. Betriebskosten',
              zeitscheibeList: [
                {
                  zeitraumText: '01.01.2020 bis zum 31.08.2020',
                  anteilProzentText: 'Ihr Anteil 100,00 von 244,00 das entspricht 40,983607 %',
                  anteilBetrag: '1.000,00',
                  anteilText: 'vom anteiligen Aufwand 2.440,00',
                },
                {
                  zeitraumText: '01.09.2020 bis zum 31.12.2020',
                  anteilProzentText: 'Ihr Anteil 100,00 von 122,00 das entspricht 81,967213 %',
                  anteilBetrag: '1.000,00',
                  anteilText: 'vom anteiligen Aufwand 1.220,00',
                },
              ],
              summe: {
                zeitraumText: '01.01.2020 bis zum 31.12.2020',
                anteilProzentText: 'Ihr Anteil entspricht somit gewichtet 52,211814 %',
                anteilBetrag: '4.013,00',
                anteilText: 'vom gesamten Aufwand 7.686,00',
              },
            },
            kontoList: [
              {
                bezeichnung: 'Wasser-/Abwassergebühr',
                betragNetto: '10.722,89',
              },
              {
                bezeichnung: 'Hausverwaltung',
                betragNetto: '8.886,48',
              },
              {
                bezeichnung: 'Hausbetreuung',
                betragNetto: '6.202.64',
              },
              {
                bezeichnung: 'Wartung/Leuchtmittel',
                betragNetto: '60,02',
              },
              {
                bezeichnung: 'Strom allgemein',
                betragNetto: '2.226,39',
              },
              {
                bezeichnung: 'Grundsteuer',
                betragNetto: '5.330,32',
              },
              {
                bezeichnung: 'Wartung Garage',
                betragNetto: '838,79',
              },
              {
                bezeichnung: 'Grünflächenbetreuung',
                betragNetto: '595,00',
              },
              {
                bezeichnung: 'Winterdienst',
                betragNetto: '488,00',
              },
              {
                bezeichnung: 'Sonstige BK',
                betragNetto: '937,60',
              },
              {
                bezeichnung: 'Versicherungen',
                betragNetto: '4.708,34',
              },
            ],
            aufwandSumme: {
              text: 'Summe',
              betrag: '40.996,47',
            },
          },
          {
            aufwandAufteilung: {
              aufteilungschluesselText: 'Die Kostenaufteilung erfolgt nach folgendem Schlüssel: Allg. Betriebskosten',
              zeitscheibeList: [
                {
                  zeitraumText: '01.01.2020 bis zum 31.08.2020',
                  anteilProzentText: 'Ihr Anteil 100,00 von 244,00 das entspricht 40,983607 %',
                  anteilBetrag: '1.000,00',
                  anteilText: 'vom anteiligen Aufwand 2.440,00',
                },
                {
                  zeitraumText: '01.09.2020 bis zum 31.12.2020',
                  anteilProzentText: 'Ihr Anteil 100,00 von 122,00 das entspricht 81,967213 %',
                  anteilBetrag: '1.000,00',
                  anteilText: 'vom anteiligen Aufwand 1.220,00',
                },
              ],
              summe: {
                zeitraumText: '01.01.2020 bis zum 31.12.2020',
                anteilProzentText: 'Ihr Anteil entspricht somit gewichtet 52,211814 %',
                anteilBetrag: '4.013,00',
                anteilText: 'vom gesamten Aufwand 7.686,00',
              },
            },
            kontoList: [
              {
                bezeichnung: 'Wasser-/Abwassergebühr',
                betragNetto: '10.722,89',
              },
              {
                bezeichnung: 'Hausverwaltung',
                betragNetto: '8.886,48',
              },
              {
                bezeichnung: 'Hausbetreuung',
                betragNetto: '6.202.64',
              },
              {
                bezeichnung: 'Wartung/Leuchtmittel',
                betragNetto: '60,02',
              },
              {
                bezeichnung: 'Strom allgemein',
                betragNetto: '2.226,39',
              },
              {
                bezeichnung: 'Grundsteuer',
                betragNetto: '5.330,32',
              },
              {
                bezeichnung: 'Wartung Garage',
                betragNetto: '838,79',
              },
              {
                bezeichnung: 'Grünflächenbetreuung',
                betragNetto: '595,00',
              },
              {
                bezeichnung: 'Winterdienst',
                betragNetto: '488,00',
              },
              {
                bezeichnung: 'Sonstige BK',
                betragNetto: '937,60',
              },
              {
                bezeichnung: 'Versicherungen',
                betragNetto: '4.708,34',
              },
            ],
            aufwandSumme: {
              text: 'Summe',
              betrag: '40.996,47',
            },
          },
        ],

        aufteilungTop: {
          akontoVorschreibung: {
            text: 'abzüglich Akontovorschreibung (netto)',
            betrag: '-1.452,12',
          },
          saldo: {
            text: 'Saldo',
            betrag: '-175,14',
          },
          ust: {
            text: 'zuzügl. 10,00 % Umsatzsteuer',
            betrag: '-17,51',
          },
          abrechnungskreisErgebnis: {
            text: 'Betriebskosten Guthaben brutto',
            betrag: '-192,65',
          },
        },
      },
      {
        type: 'BEWIRTSCHAFTUNGSKOSTEN',
        index: 2,
        abrechnungskreisTitelText: '2. Abrechnung von BK-Haus',
        aufteilungAufwandNonRepFondsList: [
          {
            aufwandAufteilung: {
              aufteilungschluesselText: 'Die Kostenaufteilung erfolgt nach folgendem Schlüssel: NFL-Haus',
              zeitscheibeList: [],
              summe: {
                zeitraumText: '01.01.2020 bis zum 31.12.2020',
                anteilProzentText: 'Ihr Anteil 82,70 von 1.590,10 das entspricht 5,200931 %',
                anteilBetrag: '202,64',
              },
            },
            kontoList: [
              {
                bezeichnung: 'Müllabfuhr',
                betragNetto: '6.106,77',
              },
            ],
            aufwandSumme: {
              text: 'Summe',
              betrag: '6.106,77',
            },
          },
        ],
        aufteilungTop: {
          akontoVorschreibung: {
            text: 'abzüglich Akontovorschreibung (netto)',
            betrag: '-304,92',
          },
          saldo: {
            text: 'Saldo',
            betrag: '5,40',
          },
          ust: {
            text: 'zuzügl. 10,00 % Umsatzsteuer',
            betrag: '0,54',
          },
          abrechnungskreisErgebnis: {
            text: 'BK-Haus Nachzahlung brutto',
            betrag: '5,94',
          },
        },
      },
      {
        type: 'BEWIRTSCHAFTUNGSKOSTEN',
        index: 3,
        abrechnungskreisTitelText: '3. Abrechnung von Allg. Betriebskosten',
        aufteilungAufwandNonRepFondsList: [
          {
            aufwandAufteilung: {
              aufteilungschluesselText: 'Die Kostenaufteilung erfolgt nach folgendem Schlüssel: NFL-Lift',
              zeitscheibeList: [
                {
                  zeitraumText: '01.01.2020 bis zum 31.05.2020',
                  anteilProzentText: 'Ihr Anteil 80,70 von 1.588,10 das entspricht 5,081544 %',
                  anteilBetrag: '100,32',
                  anteilText: 'vom anteiligen Aufwand 450,00',
                },
                {
                  zeitraumText: '01.06.2020 bis zum 31.12.2020',
                  anteilProzentText: 'Ihr Anteil 82,70 von 1.590,10 das entspricht 5,200931 %',
                  anteilBetrag: '102,32',
                  anteilText: 'vom anteiligen Aufwand 550,00',
                },
              ],
              summe: {
                zeitraumText: '01.01.2020 bis zum 31.12.2020',
                anteilProzentText: 'Ihr Anteil entspricht somit gewichtet 5,200931 %',
                anteilBetrag: '202,64',
                anteilText: 'vom gesamten Aufwand 1000,00',
              },
            },
            kontoList: [
              {
                bezeichnung: 'Aufzugswartung',
                betragNetto: '2.164,42',
              },
              {
                bezeichnung: 'Telefon Notrufanlage',
                betragNetto: '219,51',
              },
              {
                bezeichnung: 'Aufzugsstörung',
                betragNetto: '690,00',
              },
            ],
            aufwandSumme: {
              text: 'Summe',
              betrag: '3.073,93',
            },
          },
        ],
        aufteilungTop: {
          akontoVorschreibung: {
            text: 'abzüglich Akontovorschreibung (netto)',
            betrag: '-137,40',
          },
          saldo: {
            text: 'Saldo',
            betrag: '-19,51',
          },
          ust: {
            text: 'zuzügl. 10,00 % Umsatzsteuer',
            betrag: '-1,95',
          },
          abrechnungskreisErgebnis: {
            text: 'Betriebskosten Guthaben brutto',
            betrag: '-21,46',
          },
        },
      },
      {
        type: 'VORSTEUERKUERZUNG',
        index: 4,
        abrechnungskreisTitelText: '4. Abrechnung von Vorsteuerkürzung',
        aufteilungAufwandNonRepFondsList: [
          {
            aufwandAufteilung: {
              aufteilungschluesselText: 'Die Kostenaufteilung erfolgt nach folgendem Schlüssel: Nutzfläche, Nutzwert',
              zeitscheibeList: [],
            },
            kontoList: [
              {
                bezeichnung: 'Vst-Kürzung Verr- Mieter/WE',
                betragNetto: '74,93',
              },
              {
                bezeichnung: 'Vst-Kürzung Rep. Fonds/Verr. WE',
                betragNetto: '635,23',
              },
            ],
            aufwandSumme: {
              text: 'Summe',
              betrag: '710,16',
            },
          },
        ],

        aufteilungTop: {
          abrechnungskreisErgebnis: {
            text: 'Betriebskosten Nachzahlung brutto',
            betrag: '206,15',
          },
        },
      },
      {
        type: 'REPARATUR_FONDS',
        index: 5,
        abrechnungskreisTitelText: '5. Summe Abrechnung von Reparaturfonds',
        kontoListRepFonds: {
          kontoListHeader: {
            betragNettoText: 'Aufwand',
            betragUstText: 'USt.',
            betragBelastungText: 'Belastung',
          },
          kontoList: [
            {
              bezeichnung: 'Ust. vom Rücklagenaufwand laut Abrechnung',
              betragNetto: '199,01',
              betragUst: '0.00',
              betragBelastung: '0.00',
            },
            {
              bezeichnung: 'Ust. vom HV-aufwand laut Abrechnung',
              betragNetto: '86,48',
              betragUst: '3.00',
              betragBelastung: '10.50',
            },
          ],
        },
      },
    ],
    gesamtErgebnis: {
      text: 'GESAMTERGEBNIS Guthaben brutto',
      betrag: '-2,02',
    },
    repFondsAbrechnungList: [
      {
        repFondsAbrechnungTitelText: 'Abrechnung Rep.Fonds vom 01.01.2017 bis 31.12.2017',
        uebersicht: {
          uebersichtTitleText: 'Übersicht:',
          saldoVortragText: 'Saldovertrag zum 01.01.2017',
          saldoVortrag: '47.823,86',
          ausgabenText: 'Ausgaben vom 01.01.2017 bis 31.12.2017',
          ausgaben: '-5.437,54',
          einnahmenText: 'Ennahmen vom 01.01.2017 bis 31.12.2017',
          einnahmen: '22.386,96',
          saldoText: 'Saldo zum 31.12.2017',
          saldo: '64.773,28',
        },
        details: {
          repFondsAbrechnungDetailText: 'Detail:',
          ausgabenKontoListText: 'Ausgaben:',
          ausgabenKontoList: [
            {
              kontoBezeichnung: 'Aufwand detailliert',
              ausgabenList: [
                {
                  bezeichnung: 'def. Ventil',
                  buchungsDatum: '28.01.2019',
                  belegnummer: 'ER136/2019',
                  kurzbezeichnungVertragspartner: 'Stadtgemeinde Deutsch-Wagram',
                  betragNetto: '26,49',
                },
                {
                  bezeichnung: 'Haustor einstellen',
                  buchungsDatum: '29.04.2019',
                  belegnummer: 'ER611/2019',
                  kurzbezeichnungVertragspartner: 'Stadtgemeinde Deutsch-Wagram',
                  betragNetto: '226,49',
                },
                {
                  bezeichnung: 'Keller def.Lichschalter',
                  buchungsDatum: '21.07.2019',
                  belegnummer: 'ER1267/2019',
                  kurzbezeichnungVertragspartner: 'Stadtgemeinde Deutsch-Wagram',
                  betragNetto: '2.156,49',
                },
                {
                  bezeichnung: 'def. Ventil',
                  buchungsDatum: '14.10.2019',
                  belegnummer: 'ER1389/2019',
                  kurzbezeichnungVertragspartner: 'Stadtgemeinde Deutsch-Wagram',
                  betragNetto: '226,42',
                },
                {
                  bezeichnung: 'Haustor einstellen',
                  buchungsDatum: '14.12.2019',
                  belegnummer: 'ER1389/2019',
                  kurzbezeichnungVertragspartner: 'Stadtgemeinde Deutsch-Wagram',
                  betragNetto: '226,42',
                },
                {
                  bezeichnung: 'Keller def.Lichschalter',
                  buchungsDatum: '21.07.2019',
                  belegnummer: 'ER1267/2019',
                  kurzbezeichnungVertragspartner: 'Stadtgemeinde Deutsch-Wagram',
                  betragNetto: '56,49',
                },
              ],
              summeNettoAusgaben: {
                text: 'Summe',
                betrag: '4.730,25',
              },
            },
            {
              kontoBezeichnung: 'Bankspesen',
              ausgabenList: [
                {
                  bezeichnung: 'Bankspesen 1. Quartal',
                  buchungsDatum: '31.03.2019',
                  belegnummer: 'HON 0030',
                  betragNetto: '72,42',
                },
                {
                  bezeichnung: 'Bankspesen 2. Quartal',
                  buchungsDatum: '30.06.2019',
                  belegnummer: 'HON 0085',
                  betragNetto: '72,30',
                },
                {
                  bezeichnung: 'Bankspesen 3. Quartal',
                  buchungsDatum: '30.09.2019',
                  belegnummer: 'HON 0130',
                  betragNetto: '76,38',
                },
                {
                  bezeichnung: 'Bankspesen 4. Quartal',
                  buchungsDatum: '29.12.2019',
                  belegnummer: 'HON 0177',
                  betragNetto: '68,63',
                },
              ],
              summeNettoAusgaben: {
                text: 'Summe',
                betrag: '289,73',
              },
            },
          ],
          ausgabenNettoGesamt: {
            text: 'Ausgaben netto gesamt',
            betrag: '5019.98',
          },
          aufwandSumme: {
            text: 'Summe Aufwand',
            betrag: '5437,54',
          },
          ustVomAufwandList: [
            {
              bezeichnung: '+10% Umsatzsteuer vom Aufwand',
              betragNetto: '14,00',
            },
            {
              bezeichnung: '+20% Umsatzsteuer vom Aufwand',
              betragNetto: '25,93',
            },
          ],
          einnahmenListText: 'Einnahmen:',
          einnahmenList: [{ bezeichnung: 'Einnahmen RF', betragNetto: '22.386,96' }],
          einnahmenSumme: {
            text: 'Summe Einnahmen',
            betrag: '22.386,96',
          },
        },
        aufwandAufteilung: {
          aufteilungschluesselText: 'Die Kostenaufteilung erfolgt nach folgendem Schlüssel: Nutzfläche',
          anteilProzentText: 'Ihr Anteil 76,33 von 1.814,32 das entspricht 4.21 %',
          aufwandAufteilungTableTitles: {
            basis: 'Basis',
            anteilNetto: 'Anteil Netto',
            ustProzent: 'Ust %',
            ust: 'Ust',
            summe: 'Summe',
            grundlage: 'Grundlage',
            belastung: 'daher Belastung',
          },
          aufwandAufteilungTableRowList: [
            {
              basis: '4730,25',
              anteilNetto: '199,01',
              ustProzent: '0%',
              ust: '0,00',
              summe: '199,01',
              grundlage: '10% aus RF. Rest an WE',
              belastung: '0,00',
            },
            {
              basis: '4730,25',
              anteilNetto: '199,01',
              ustProzent: '0%',
              ust: '0,00',
              summe: '199,01',
              grundlage: '10% aus RF. Rest an WE',
              belastung: '0,00',
            },
          ],
          aufwandAufteilungSummary: {
            basis: '9460,50',
            anteilNetto: '398,02',
            ust: '0,00',
            summe: '398,02',
            belastung: '0,00',
          },
        },
      },
    ],
  },
  zahlungsDetails: {
    rueckstandBlock: {
      rueckstand: {
        label: 'Rückstand des Bestandnehmers:',
        value: '100,00',
      },
      rueckstandGesamtErgebnis: {
        label: 'Nachzahlung Brutto',
        value: '97,98',
      },
    },
    dueDate: { label: 'Fälligkeit:', value: '5.1.2024' },
    zahlungsverlaufBlock: {
      zahlungsverlaufBlockTitle: 'Zahlungsverlauf',
      zahlungsverlaufListHeader: {
        dueDateText: 'Fälligkeit',
        belegnummerText: 'Belegnummer',
        belegdatumText: 'Belegdatum',
        vorschreibungText: 'Vorschreibung',
        offenerBetragText: 'Offener Betrag',
      },
      zahlungsverlaufList: [
        {
          dueDate: '5.1.2023',
          belegnummer: '2023/00046',
          belegdatum: '1.1.2023',
          vorschreibung: '193,86',
          offenerBetrag: '0,00',
          auszifferung: [
            {
              text: 'gezahlt am 5.1.2023',
              betrag: '-193,86',
            },
          ],
        },
        {
          dueDate: '5.2.2023',
          belegnummer: '2023/00046',
          belegdatum: '1.1.2023',
          vorschreibung: '193,86',
          offenerBetrag: '0,00',
          auszifferung: [
            {
              text: 'gezahlt am 5.2.2023',
              betrag: '-193,86',
            },
          ],
        },
        {
          dueDate: '5.3.2023',
          belegnummer: '2023/00046',
          belegdatum: '1.1.2023',
          vorschreibung: '193,86',
          offenerBetrag: '0,00',
          auszifferung: [
            {
              text: 'gezahlt am 5.3.2023',
              betrag: '-100,00',
            },
            {
              text: 'gezahlt am 7.3.2023',
              betrag: '-93,86',
            },
          ],
        },
        {
          dueDate: '5.4.2023',
          belegnummer: '2023/00046',
          belegdatum: '1.1.2023',
          vorschreibung: '193,86',
          offenerBetrag: '143,86',
          auszifferung: [
            {
              text: 'gezahlt am 5.4.2023',
              betrag: '-50,86',
            },
          ],
        },
      ],
      zahlungsverlaufSumme: {
        text: 'Rückstand',
        betrag: '143,86',
      },
    },
    zahlungsrueckstandOtherPersonsBlock: {
      zahlungsrueckstandOtherPersonsBlockTitle: 'Zahlungsrückstände',
      zahlungsrueckstandOtherPersonsListHeader: {
        topText: 'Top',
        vertragspartnerText: 'Vertragspartner',
        offenerBetragText: 'Offener Betrag',
      },
      zahlungsrueckstandOtherPersonsList: [
        {
          top: '2',
          vertragspartner: 'Wallich, Dr. Alexandra',
          offenerBetrag: '197,54',
        },
        {
          top: '3',
          vertragspartner: 'Otto Immobilien',
          offenerBetrag: '193,86',
        },
        {
          top: '12',
          vertragspartner: 'Pichler, Florentine',
          offenerBetrag: '921,02',
        },
      ],
      zahlungsrueckstandSumme: {
        text: 'Rückstand',
        betrag: '1.312,42',
      },
    },
  },
};

export const mockTopAbrechnung2: TopAbrechnung = {
  topAbrechnungId: '002',
  deckblatt: {
    absender: { label: 'Abs:', value: 'andromeda software GmnH, Grillgasse 46; 1110 Wien' },
    rechnungsEmpfaengerShippingLabel: {
      line1: 'Familie',
      line2: 'Stefanie und Max Muster',
      line3: 'Grillgasse 22',
      line4: 'Wien',
      line5: null,
      line6: null,
      line7: null,
    },

    abrechnungsDatumLine: 'Wien, 28.06.2017',

    kundennummer: {
      text: 'Kundennummer:',
      nummer: '23245',
    },

    einleitungsText: '{Platzhalter Einleitungstext}',

    abrechnungskreisSummary: {
      header: {
        abrechnungskreisText: '',
        aufwandText: 'Aufwand',
        vorschreibungText: 'Vorschreibung',
        nettoText: 'Netto',
        ustText: 'Ust',
        saldoText: 'Saldo',
      },
      abrechnungskreisList: [
        {
          bezeichnung: 'Betriebkosten',
          aufwandBetrag: '1.276,98',
          vorschreibungBetrag: '1.452,12',
          nettoBetrag: '-175,14',
          ustBetrag: '-15,51',
          saldoBetrag: '-192,65',
        },
        {
          bezeichnung: 'BK-Haus',
          aufwandBetrag: '310,32',
          vorschreibungBetrag: '304,92',
          nettoBetrag: '5,40',
          ustBetrag: '0,54',
          saldoBetrag: '5,94',
        },
      ],
      summe: {
        aufwand: '1.705,19',
        vorschreibung: '1.894,44',
        netto: '-189,25',
        ust: '-18,92',
        saldo: '-208,17',
      },
      ergebnis: {
        text: 'Guthaben',
        betrag: '-208,17',
      },
    },

    ergebnisText: '{Platzhalter Endtext je nach Use-Case}',
    schlussText: 'Beste Grüße, Ihre {Hausverwaltung.kurzbezeichnung}',
  },

  abrechnungskreisDetails: {
    aufzaehlungsText: 'Folgende Abrechnungskreise werden abgrechnet:',
    header: {
      abrechnungsKreisText: 'Abrechnungskreis',
      betragText: 'Betrag (netto)',
      summeText: 'in €',
    },
    abrechnungskreisList: [
      {
        type: 'BEWIRTSCHAFTUNGSKOSTEN',
        index: 1,
        abrechnungskreisTitelText: '1. Abrechnung von Allg. Betriebskosten',
        aufteilungAufwandNonRepFondsList: [
          {
            aufwandAufteilung: {
              aufteilungschluesselText: 'Die Kostenaufteilung erfolgt nach folgendem Schlüssel: Nutzfläche',
              zeitscheibeList: [],
              summe: {
                zeitraumText: '01.01.2020 bis zum 31.12.2020',
                anteilProzentText: 'Ihr Anteil 82,70 von 1.590,10 das entspricht 5,200931 %',
                anteilBetrag: '202,64',
              },
            },
            kontoList: [
              {
                bezeichnung: 'Wasser-/Abwassergebühr',
                betragNetto: '10.722,89',
              },
              {
                bezeichnung: 'Hausverwaltung',
                betragNetto: '8.886,48',
              },
              {
                bezeichnung: 'Hausbetreuung',
                betragNetto: '6.202.64',
              },
              {
                bezeichnung: 'Wartung/Leuchtmittel',
                betragNetto: '60,02',
              },
              {
                bezeichnung: 'Strom allgemein',
                betragNetto: '2.226,39',
              },
              {
                bezeichnung: 'Grundsteuer',
                betragNetto: '5.330,32',
              },
              {
                bezeichnung: 'Wartung Garage',
                betragNetto: '838,79',
              },
              {
                bezeichnung: 'Grünflächenbetreuung',
                betragNetto: '595,00',
              },
              {
                bezeichnung: 'Winterdienst',
                betragNetto: '488,00',
              },
              {
                bezeichnung: 'Sonstige BK',
                betragNetto: '937,60',
              },
              {
                bezeichnung: 'Versicherungen',
                betragNetto: '4.708,34',
              },
            ],
            aufwandSumme: {
              text: 'Summe',
              betrag: '40.996,47',
            },
          },
        ],
        aufteilungTop: {
          akontoVorschreibung: {
            text: 'abzüglich Akontovorschreibung (netto)',
            betrag: '-1.452,12',
          },
          saldo: {
            text: 'Saldo',
            betrag: '-175,14',
          },
          ust: {
            text: 'zuzügl. 10,00 % Umsatzsteuer',
            betrag: '-17,51',
          },
          abrechnungskreisErgebnis: {
            text: 'Betriebskosten Guthaben brutto',
            betrag: '-192,65',
          },
        },
      },
      {
        type: 'BEWIRTSCHAFTUNGSKOSTEN',
        index: 2,
        abrechnungskreisTitelText: '2. Abrechnung von BK-Haus',
        aufteilungAufwandNonRepFondsList: [
          {
            aufwandAufteilung: {
              aufteilungschluesselText: 'Die Kostenaufteilung erfolgt nach folgendem Schlüssel: NFL-Haus',
              zeitscheibeList: [],
              summe: {
                zeitraumText: '01.01.2020 bis zum 31.12.2020',
                anteilProzentText: 'Ihr Anteil 82,70 von 1.590,10 das entspricht 5,200931 %',
                anteilBetrag: '202,64',
              },
            },
            kontoList: [
              {
                bezeichnung: 'Müllabfuhr',
                betragNetto: '6.106,77',
              },
            ],
            aufwandSumme: {
              text: 'Summe',
              betrag: '6.106,77',
            },
          },
        ],

        aufteilungTop: {
          akontoVorschreibung: {
            text: 'abzüglich Akontovorschreibung (netto)',
            betrag: '-304,92',
          },
          saldo: {
            text: 'Saldo',
            betrag: '5,40',
          },
          ust: {
            text: 'zuzügl. 10,00 % Umsatzsteuer',
            betrag: '0,54',
          },
          abrechnungskreisErgebnis: {
            text: 'BK-Haus Nachzahlung brutto',
            betrag: '5,94',
          },
        },
      },
    ],
    gesamtErgebnis: {
      text: 'GESAMTERGEBNIS Guthaben brutto',
      betrag: '-208,17',
    },
  },
  zahlungsDetails: {
    rueckstandBlock: {
      rueckstand: {
        label: 'Rückstand des Bestandnehmers:',
        value: '100,00',
      },
      rueckstandGesamtErgebnis: {
        label: 'Nachzahlung Brutto',
        value: '97,98',
      },
    },
    dueDate: { label: 'Fälligkeit:', value: '5.1.2024' },
    zahlungsverlaufBlock: {
      zahlungsverlaufBlockTitle: 'Zahlungsverlauf',
      zahlungsverlaufListHeader: {
        dueDateText: 'Fälligkeit',
        belegnummerText: 'Belegnummer',
        belegdatumText: 'Belegdatum',
        vorschreibungText: 'Vorschreibung',
        offenerBetragText: 'Offener Betrag',
      },
      zahlungsverlaufList: [
        {
          dueDate: '5.1.2023',
          belegnummer: '2023/00046',
          belegdatum: '1.1.2023',
          vorschreibung: '193,86',
          offenerBetrag: '0,00',
          auszifferung: [
            {
              text: 'gezahlt am 5.1.2023',
              betrag: '-193,86',
            },
          ],
        },
        {
          dueDate: '5.2.2023',
          belegnummer: '2023/00046',
          belegdatum: '1.1.2023',
          vorschreibung: '193,86',
          offenerBetrag: '0,00',
          auszifferung: [
            {
              text: 'gezahlt am 5.2.2023',
              betrag: '-193,86',
            },
          ],
        },
        {
          dueDate: '5.3.2023',
          belegnummer: '2023/00046',
          belegdatum: '1.1.2023',
          vorschreibung: '193,86',
          offenerBetrag: '0,00',
          auszifferung: [
            {
              text: 'gezahlt am 5.3.2023',
              betrag: '-100,00',
            },
            {
              text: 'gezahlt am 7.3.2023',
              betrag: '-93,86',
            },
          ],
        },
        {
          dueDate: '5.4.2023',
          belegnummer: '2023/00046',
          belegdatum: '1.1.2023',
          vorschreibung: '193,86',
          offenerBetrag: '143,86',
          auszifferung: [
            {
              text: 'gezahlt am 5.4.2023',
              betrag: '-50,86',
            },
          ],
        },
      ],
      zahlungsverlaufSumme: {
        text: 'Rückstand',
        betrag: '143,86',
      },
    },
    zahlungsrueckstandOtherPersonsBlock: {
      zahlungsrueckstandOtherPersonsBlockTitle: 'Zahlungsrückstände',
      zahlungsrueckstandOtherPersonsListHeader: {
        topText: 'Top',
        vertragspartnerText: 'Vertragspartner',
        offenerBetragText: 'Offener Betrag',
      },
      zahlungsrueckstandOtherPersonsList: [
        {
          top: '2',
          vertragspartner: 'Wallich, Dr. Alexandra',
          offenerBetrag: '197,54',
        },
        {
          top: '3',
          vertragspartner: 'Otto Immobilien',
          offenerBetrag: '193,86',
        },
        {
          top: '12',
          vertragspartner: 'Pichler, Florentine',
          offenerBetrag: '921,02',
        },
      ],
      zahlungsrueckstandSumme: {
        text: 'Rückstand',
        betrag: '1.312,42',
      },
    },
  },
};

export const mockTopAbrechnungListWith1Top: TopAbrechnungList = {
  type: 'TOP_ABRECHNUNG',
  topAbrechnungList: [mockTopAbrechnung1],
  infoAbrechnungskreisList: [
    {
      type: 'INFORMATION',
      bezeichnung: 'Information',
      aufteilungsschluessel: null,
      summeNettoAusgabenText: 'Summe Ausgaben',
      summeNettoAusgaben: '64,99',
      summeNettoEinnahmenText: 'Summe Einnahmen',
      summeNettoEinnahmen: '95,62',
      ergebnisNettoText: 'Ergebnis: Saldo',
      ergebnisNetto: '-30,63',
      buchungEinnahmenListText: 'Einnahmen',
      saldoVortragText: 'Vorsaldo:',
      saldoVortrag: '0,00',
      ausgabenDetailsTitleText: null,
      kontoList: [
        {
          bezeichnung: 'Waschmaschine',
          kontonummer: 'S1000',
          aufteilungsschluessel: null,
          betragNettoSummeBuchungenText: 'Zwischensumme',
          betragNettoSummeBuchungen: '64,99',
          buchungAusgabenList: [
            {
              bezeichnung: 'Vorschreibung 2023/12',
              buchungsDatum: '13.12.2023',
              belegnummer: '1',
              kurzbezeichnungVertragsparthner: 'Kraker L. u.Heiner G.',
              betragNetto: '64,99',
            },
          ],
        },
      ],
      buchungEinnahmenList: [
        {
          bezeichnung: '2023/12',
          betragNetto: '95,62',
        },
        {
          bezeichnung: '2023/11',
          betragNetto: '95,62',
        },
        {
          bezeichnung: '2023/10',
          betragNetto: '95,62',
        },
        {
          bezeichnung: '2023/9',
          betragNetto: '95,62',
        },
        {
          bezeichnung: '2023/8',
          betragNetto: '95,62',
        },
      ],
      buchungUstVomAufwandList: [],
      summeNettoExpenseReducingText: 'Zwischensumme Aufwandsmindernd',
      summeNettoExpenseReducing: '60,80',
    },
  ],
};

export const mockTopAbrechnungListWith2Tops: TopAbrechnungList = {
  type: 'TOP_ABRECHNUNG',
  topAbrechnungList: [mockTopAbrechnung1, mockTopAbrechnung2],
  infoAbrechnungskreisList: [
    {
      type: 'INFORMATION',
      bezeichnung: 'Information',
      aufteilungsschluessel: null,
      summeNettoAusgabenText: 'Summe Ausgaben',
      summeNettoAusgaben: '64,99',
      summeNettoEinnahmenText: 'Summe Einnahmen',
      summeNettoEinnahmen: '95,62',
      ergebnisNettoText: 'Ergebnis: Saldo',
      ergebnisNetto: '-30,63',
      buchungEinnahmenListText: 'Einnahmen',
      saldoVortragText: 'Vorsaldo:',
      saldoVortrag: '0,00',
      ausgabenDetailsTitleText: null,
      kontoList: [
        {
          bezeichnung: 'Waschmaschine',
          kontonummer: 'S1000',
          aufteilungsschluessel: null,
          betragNettoSummeBuchungenText: 'Zwischensumme',
          betragNettoSummeBuchungen: '64,99',
          buchungAusgabenList: [
            {
              bezeichnung: 'Vorschreibung 2023/12',
              buchungsDatum: '13.12.2023',
              belegnummer: '1',
              kurzbezeichnungVertragsparthner: 'Kraker L. u.Heiner G.',
              betragNetto: '64,99',
            },
          ],
        },
      ],
      buchungEinnahmenList: [
        {
          bezeichnung: '2023/12',
          betragNetto: '95,62',
        },
        {
          bezeichnung: '2023/11',
          betragNetto: '95,62',
        },
        {
          bezeichnung: '2023/10',
          betragNetto: '95,62',
        },
        {
          bezeichnung: '2023/9',
          betragNetto: '95,62',
        },
        {
          bezeichnung: '2023/8',
          betragNetto: '95,62',
        },
      ],
      buchungUstVomAufwandList: [],
      summeNettoExpenseReducingText: 'Zwischensumme Aufwandsmindernd',
      summeNettoExpenseReducing: '60,80',
    },
  ],
};
