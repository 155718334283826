import React, { FC, useState } from 'react';
import { Divider, Space, Steps } from 'antd';
import { Dayjs } from 'dayjs';
import { Flex } from 'rebass';
import { useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash';
import { showSuccessMsgCreate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import {
  mapTextbausteinListToFormValues,
  TextbausteinFormValues,
  TextbausteinModel,
} from '../../../../../components/Template/PDFTemplates/templateMapper';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import { AbrechnungsdefinitionType, BkAbrechnungsdefinitionVersionListEntry } from '../../../../../types';
import BkAbrDefTemplates from '../../../shared/Templates/BkTemplates/BkAbrDefTemplates';
import { BkAbrechnungsdefinitionVersionFieldsFragment } from '../../../../Abrechnungsdefinition/gql/BkAbrDef/BkAbrDefVersionFragments.types';
import { useBkAbrDefVersionFieldHelp } from '../../../../Abrechnungsdefinition/abrDefFieldHelpHelpers/useBkAbrDefVersionFieldHelp';
import { useCreateBkAbrechnungsdefinitionTemplateVersionMutation } from '../../../gql/BkAbrDef/BkAbrDefTemplateVersionMutations.types';
import {
  getAlreadyTakenValidFromDates,
  getNextAvailableValidFromOrToday,
  updateCommentOnTemplateChange,
} from '../../../../Abrechnungsdefinition/Version/Form/Create/abrDefVersionCreateFormHelpers';
import {
  AbrDefAbrKreisListingFormValues,
  mapAbrechnungsdefinitionToAbrKreisListFormValues,
} from '../../../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormMapper';
import {
  mapObjAbrDefOutputOptionsToFormValues,
  objAbrDefGeneralSettingsFormInitialValues,
  ObjAbrDefGeneralSettingsFormValues,
} from '../../../../Abrechnungsdefinition/shared/Templates/shared/BkOrWe/ObjAbrDefGeneralSettingsForm/objAbrDefGeneralSettingsFormMapper';
import {
  mapTopAbrDefGeneralSettingsToInitialValues,
  TopAbrDefGeneralSettingsFormValues,
} from '../../../../Abrechnungsdefinition/shared/Templates/shared/BkOrWe/TopAbrDefGeneralSettingsForm/topAbrDefGeneralSettingsFormMapper';
import AbrDefTemplateAbrKreisListingForm from '../../../shared/AbrDefAbrKreisListingForm/AbrDefTemplateAbrKreisListingForm';
import AbrDefVersionFormKommentar from '../../../../Abrechnungsdefinition/shared/AbrDefVersionFormKommentar';
import { mapFormValuesToBkOrWeAbrechnungsdefinitionVersionCreate } from '../../../../Abrechnungsdefinition/Version/Form/Create/abrDefVersionCreateFormMapper';
import AbrDefFormValidFrom, { formatAbrechnungsdefinitionValidFromValue } from '../../../../Abrechnungsdefinition/shared/AbrDefFormValidFrom';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../../constants/andromedaSystemSettingsUriPaths';

type Props = {
  abrechnungsdefinitionVersionList: BkAbrechnungsdefinitionVersionListEntry[];
  abrechnungsdefinitionId: string;
  precedingAbrechnungsdefinitionVersion?: BkAbrechnungsdefinitionVersionFieldsFragment;
  objektAbrechnungTextbausteinList: TextbausteinModel[];
  topAbrechnungTextbausteinList: TextbausteinModel[];
};

const BkAbrDefTemplateVersionCreateForm: FC<Props> = ({
  abrechnungsdefinitionVersionList,
  abrechnungsdefinitionId,
  precedingAbrechnungsdefinitionVersion,
  objektAbrechnungTextbausteinList,
  topAbrechnungTextbausteinList,
}) => {
  const { fieldHelpAbrDefAbrechkreisList, fieldHelpNamesObjekt, fieldHelpNamesTop } = useBkAbrDefVersionFieldHelp();

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [validFromFormValue, setValidFromFormValue] = useState<string>(
    getNextAvailableValidFromOrToday(abrechnungsdefinitionVersionList, precedingAbrechnungsdefinitionVersion)
  );
  const [isValidFromValid, setIsValidFromValid] = useState<boolean>();
  const [kommentarFormValues, setKommentarFormValues] = useState<string>('');
  const [abrKreisListFormValues, setAbrKreisListFormValues] = useState<AbrDefAbrKreisListingFormValues>(
    mapAbrechnungsdefinitionToAbrKreisListFormValues(precedingAbrechnungsdefinitionVersion?.abrechdefAbrechkreisList)
  );
  const [objAbrDefTemplateValues, setObjAbrDefTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(objektAbrechnungTextbausteinList)
  );
  const [topAbrDefTemplateValues, setTopAbrDefTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(topAbrechnungTextbausteinList)
  );

  const [objAbrDefGeneralSettingsValues, setObjAbrDefGeneralSettingsValues] = useState<ObjAbrDefGeneralSettingsFormValues>(
    precedingAbrechnungsdefinitionVersion?.objektAbrechnungOutputOptions
      ? mapObjAbrDefOutputOptionsToFormValues(precedingAbrechnungsdefinitionVersion.objektAbrechnungOutputOptions)
      : objAbrDefGeneralSettingsFormInitialValues
  );

  const [topAbrDefGeneralSettingsValues, setTopAbrDefGeneralSettingsValues] = useState<TopAbrDefGeneralSettingsFormValues>(
    mapTopAbrDefGeneralSettingsToInitialValues(
      AbrechnungsdefinitionType.BkAbrechnung,
      precedingAbrechnungsdefinitionVersion?.topAbrechnungOutputOptions
    )
  );
  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  const entity = 'AbrechnungsdefinitionVersion';

  const [createBkAbrDefVersion, { loading: loadingCreate }] = useCreateBkAbrechnungsdefinitionTemplateVersionMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      navigate(URI_ANDROMEDA_SYS_SETTINGS.abrechnungsdefinitionPage);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onAbrKreisListingFormSubmit = (formValues: AbrDefAbrKreisListingFormValues) => {
    const hasAbrechnungskreisZuordnungChanged = !isEqual(
      mapAbrechnungsdefinitionToAbrKreisListFormValues(precedingAbrechnungsdefinitionVersion?.abrechdefAbrechkreisList),
      formValues
    );
    hasAbrechnungskreisZuordnungChanged && setKommentarFormValues('Abrechnungskreise geändert');
    setAbrKreisListFormValues(formValues);
    setActiveStep(1);
  };

  const onAbrDefTemplatesSubmit = () => {
    if (!validFromFormValue) {
      setIsValidFromValid(false);
    }
    if (!isFormValid) return;
    const input = mapFormValuesToBkOrWeAbrechnungsdefinitionVersionCreate(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      abrKreisListFormValues!,
      objAbrDefTemplateValues,
      topAbrDefTemplateValues,
      validFromFormValue,
      kommentarFormValues,
      objAbrDefGeneralSettingsValues,
      topAbrDefGeneralSettingsValues
    );
    createBkAbrDefVersion({ variables: { abrechnungsdefinitionId, input } });
  };

  let content;
  if (activeStep === 0) {
    content = (
      <AbrDefTemplateAbrKreisListingForm
        abrDefType={AbrechnungsdefinitionType.BkAbrechnung}
        values={abrKreisListFormValues}
        onSubmit={onAbrKreisListingFormSubmit}
        onBack={() => navigate(URI_ANDROMEDA_SYS_SETTINGS.abrechnungsdefinitionPage)}
        backBtnText="Abbrechen"
        fieldHelp={fieldHelpAbrDefAbrechkreisList}
      />
    );
  } else {
    // activeStep === 1
    content = (
      <BkAbrDefTemplates
        bkObjektAbrTemplateValues={objAbrDefTemplateValues}
        onBkObjektAbrDefTemplateChange={(formValues) => {
          updateCommentOnTemplateChange(
            mapTextbausteinListToFormValues(objektAbrechnungTextbausteinList),
            formValues,
            'BK-Objektabrechnung',
            setKommentarFormValues
          );
          setObjAbrDefTemplateValues(formValues);
        }}
        bkTopAbrTemplateValues={topAbrDefTemplateValues}
        onBkTopAbrDefTemplateChange={(formValues) => {
          updateCommentOnTemplateChange(
            mapTextbausteinListToFormValues(topAbrechnungTextbausteinList),
            formValues,
            'BK-Topabrechnung',
            setKommentarFormValues
          );
          setTopAbrDefTemplateValues(formValues);
        }}
        objAbrDefGeneralSettingsValues={objAbrDefGeneralSettingsValues}
        onObjAbrDefGeneralSettingsChange={setObjAbrDefGeneralSettingsValues}
        topAbrDefGeneralSettingsValues={topAbrDefGeneralSettingsValues}
        onTopAbrDefGeneralSettingsChange={(values) => setTopAbrDefGeneralSettingsValues(values)}
        setIsFormValid={setIsFormValid}
        onSubmit={onAbrDefTemplatesSubmit}
        isSubmitting={loadingCreate}
        onBack={() => setActiveStep(0)}
        fieldHelpNames={{ objekt: fieldHelpNamesObjekt, top: fieldHelpNamesTop }}
      />
    );
  }

  const onChangeDate = (date: Dayjs | null) => {
    if (date) {
      setIsValidFromValid(true);
      setValidFromFormValue(formatAbrechnungsdefinitionValidFromValue(date));
    } else {
      setIsValidFromValid(false);
    }
  };

  return (
    <>
      <Flex>
        <Space>
          <AbrDefFormValidFrom
            isValid={isValidFromValid}
            onChange={onChangeDate}
            initialValue={validFromFormValue ? dayjsCustom(validFromFormValue) : undefined}
            disabledDates={getAlreadyTakenValidFromDates(abrechnungsdefinitionVersionList)}
          />
        </Space>
        <Space style={{ marginLeft: '10px' }}>
          <AbrDefVersionFormKommentar onChange={setKommentarFormValues} value={kommentarFormValues} />
        </Space>
      </Flex>
      <Divider />
      <Steps type="navigation" size="small" current={activeStep} className="site-navigation-steps">
        <Steps.Step title="Abrechnungskreise" />
        <Steps.Step title="Abrechnung" />
      </Steps>
      {content}
    </>
  );
};

export default BkAbrDefTemplateVersionCreateForm;
