import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../styles/pdfStyles';
import { TopAbrechnung } from '../../../topAbrechnung-types';
import TAAbrechnungskreis from './Abrechnungskreis/TAAbrechnungskreis';
import TAAbrKreisDetailsGesamtErgebnis from './TAAbrKreisDetailsGesamtErgebnis';

const TAAbrKreisDetailsAbrechnungskreisBlock: FC<{ data: TopAbrechnung }> = ({ data }) => (
  <View style={[pdfStyles.column, pdfStyles.textNormal, { paddingTop: '2mm' }]}>
    {/* Abrechnungskreis list */}
    {data.abrechnungskreisDetails.abrechnungskreisList
      .sort((abrechKreis1, abrechKreis2) => abrechKreis1.index - abrechKreis2.index)
      .map((abrechKreis, index) => (
        <TAAbrechnungskreis abrechKreis={abrechKreis} key={index} />
      ))}

    {/* Gesamtergebnis */}
    <TAAbrKreisDetailsGesamtErgebnis data={data} />
  </View>
);

export default TAAbrKreisDetailsAbrechnungskreisBlock;
