import React from 'react';
import { Route } from 'react-router';
import { isAdmin } from '../../../../../security/permissionChecks';
import AndromedaSysSettingsAbrechnungsdefinitionPage from './AndromedaSysSettingsAbrechnungsdefinitionPage';
import AuthRoute from '../../../../../security/AuthRoute';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../../constants/andromedaSystemSettingsUriPaths';

const andromedaSysSettingsAbrechnungsdefinitionPageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS.abrechnungsdefinitionPage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsAbrechnungsdefinitionPage />} />}
  />
);

export default andromedaSysSettingsAbrechnungsdefinitionPageRoute;
