import React, { FC } from 'react';
import { Empty } from 'antd';
import { EbicsBankConfig } from '../../../../../types';
import ebicsBankConfigTableColumns from './ebicsBankConfigTableColumns';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';

type Props = {
  ebicsBankConfigList: EbicsBankConfig[];
  onAction: () => void;
};

const EbicsBankConfigTable: FC<Props> = ({ ebicsBankConfigList, onAction }) => (
  <TableWithColSelector<EbicsBankConfig>
    size="small"
    locale={{
      emptyText: <Empty description={<span>Keine EBICS Banken vorhanden</span>} />,
    }}
    dataSource={ebicsBankConfigList}
    columns={ebicsBankConfigTableColumns(onAction)}
    pagination={{ showSizeChanger: true, defaultPageSize: 20 }}
    rowKey={(record) => record.ebicsBankConfigId}
    filterIdentifier="ebics-bank-config"
  />
);

export default EbicsBankConfigTable;
