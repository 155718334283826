import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { useSubAdministrationAbrechnungsdefinitionTemplateVersionQuery } from '../../../../../../features/AbrechnungsdefinitionTemplate/gql/SubAdministrationAbrDef/SubAdministrationAbrDefTemplateVersionQueries.types';
import SubAbrDefTemplateVersionView from '../../../../../../features/AbrechnungsdefinitionTemplate/Version/View/SubAbrDefTemplateVersionView';

type Props = {
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersionId: string;
  isHistoricized: boolean;
};
const AndromedaSysSettingsSubAbrechnungsdefinitionVersionViewPage: FC<Props> = ({
  abrechnungsdefinitionId,
  abrechnungsdefinitionVersionId,
  isHistoricized,
}) => {
  const { data } = useSubAdministrationAbrechnungsdefinitionTemplateVersionQuery({
    variables: { abrechnungsdefinitionId, abrechnungsdefinitionVersionId, searchInHistory: isHistoricized },
  });
  const abrechnungsdefinitionVersion = data?.getSubAdministrationAbrechnungsdefinitionTemplateVersion.data;

  if (!abrechnungsdefinitionVersion) return <Skeleton active />;

  return (
    <SubAbrDefTemplateVersionView
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      abrechnungsdefinitionVersion={abrechnungsdefinitionVersion}
      isHistoricized={isHistoricized}
    />
  );
};

export default AndromedaSysSettingsSubAbrechnungsdefinitionVersionViewPage;
