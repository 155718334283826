import React from 'react';
import { Row } from 'antd';
import ObjektAbrechnungTemplate from './templates/objektAbrechnung/ObjektAbrechnungTemplate';
import { mockObjektAbrechnungData } from './mocks/objektAbrechnungMocks';
import PDFPageCol from './PDFPageCol';

const PDFPageObjektAbrechnung = () => (
  <Row>
    <PDFPageCol pdfTemplate={<ObjektAbrechnungTemplate data={mockObjektAbrechnungData} />} />
  </Row>
);

export default PDFPageObjektAbrechnung;
