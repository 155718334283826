import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { RepFondsAbrechnungDetails } from '../../../../topAbrechnung-types';
import TARepFondsAbrUstVomAufwandList from './TARepFondsAbrUstVomAufwandList';
import TARepFondsAbrSumme from '../TARepFondsAbrSumme';
import TARepFondsAbrAusgabenBlock from './Ausgaben/TARepFondsAbrAusgabenBlock';

const TARepFondsAbrAufwandBlock: FC<{ details: RepFondsAbrechnungDetails }> = ({ details }) => (
  <View>
    {/* Ausgaben block */}
    <TARepFondsAbrAusgabenBlock details={details} />

    {/* Ust. vom Aufwand block */}
    {details.ustVomAufwandList ? <TARepFondsAbrUstVomAufwandList ustVomAufwandList={details.ustVomAufwandList} /> : null}

    {/* Summe Aufwand */}
    <TARepFondsAbrSumme summe={details.aufwandSumme} />
  </View>
);

export default TARepFondsAbrAufwandBlock;
