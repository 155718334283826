import { FormFields } from '../../../helpers/formikHelper';
import { TicketPriority, TicketStatus } from '../../../types';
import { dayjsCustom } from '../../../helpers/dayjsCustom';
import { TTicketQueryParams } from './Filters/filtersQueryParams';
import { TicketListQueryVariables } from '../gql/TicketQueries.types';

export type TicketListingFiltersFormValues = {
  dueDateFrom?: string | null;
  dueDateTo?: string | null;
  assigneeMitarbeiterId?: string[] | null;
  participantId?: string[] | null;
  priority?: TicketPriority[] | null;
  status?: TicketStatus[] | null;
  title?: string;
  typeName?: string[] | null;
  searchInSubTickets?: boolean | null;
  showArchived: boolean;
  objektId?: string | null;
  bestandseinheitId?: string | null;
  vertragId?: string | null;
  rechtstraegerId?: string | null;
};

export const ticketListingFiltersFormFields: FormFields<TicketListingFiltersFormValues> = {
  dueDateFrom: 'dueDateFrom',
  dueDateTo: 'dueDateTo',
  assigneeMitarbeiterId: 'assigneeMitarbeiterId',
  participantId: 'participantId',
  priority: 'priority',
  status: 'status',
  title: 'title',
  typeName: 'typeName',
  showArchived: 'showArchived',
  searchInSubTickets: 'searchInSubTickets',
  objektId: 'objektId',
  bestandseinheitId: 'bestandseinheitId',
  vertragId: 'vertragId',
  rechtstraegerId: 'rechtstraegerId',
};

export const ticketListingFiltersFormInitialValues = (filters?: TTicketQueryParams): TicketListingFiltersFormValues => ({
  dueDateFrom: filters?.dueDateFrom,
  dueDateTo: filters?.dueDateTo,
  assigneeMitarbeiterId: filters?.assigneeMitarbeiterId,
  participantId: filters?.participantId,
  priority: filters?.priority,
  status: filters?.status ?? [TicketStatus.ToDo, TicketStatus.InProgress],
  title: filters?.title,
  typeName: filters?.typeName,
  showArchived: filters?.showArchived ?? false,
  searchInSubTickets: filters?.searchInSubTickets ?? false,
  objektId: filters?.objektId,
  bestandseinheitId: filters?.bestandseinheitId,
  vertragId: filters?.vertragId,
  rechtstraegerId: filters?.rechtstraegerId,
});

export const mapFormValuesToQueryVariables = (values: TicketListingFiltersFormValues): TicketListQueryVariables & { showArchived: boolean } => ({
  dueDateFrom: values.dueDateFrom ? dayjsCustom(values.dueDateFrom).format('YYYY-MM-DD') : undefined,
  dueDateTo: values.dueDateTo ? dayjsCustom(values.dueDateTo).format('YYYY-MM-DD') : undefined,
  assigneeMitarbeiterId: values.assigneeMitarbeiterId?.length ? values.assigneeMitarbeiterId : undefined,
  participantId: values.participantId?.length ? values.participantId : undefined,
  priority: values.priority?.length ? values.priority : undefined,
  status: values.status?.length ? values.status : undefined,
  title: values.title ? values.title : undefined,
  typeName: values.typeName?.length ? values.typeName : undefined,
  searchInSubTickets: values.searchInSubTickets,
  showArchived: values.showArchived,
  objektId: values.objektId,
  bestandseinheitId: values.bestandseinheitId,
  vertragId: values.vertragId,
  rechtstraegerId: values.rechtstraegerId,
});

type TicketListingLabelListProps = {
  includeObjekt?: boolean;
  includeBestandseinheit?: boolean;
  includeVertrag?: boolean;
  includeRechtstraeger?: boolean;
};

export const ticketListingLabelList = ({
  includeObjekt,
  includeBestandseinheit,
  includeRechtstraeger,
  includeVertrag,
}: TicketListingLabelListProps = {}) => {
  const baseList = {
    [ticketListingFiltersFormFields.typeName]: 'Art',
    [ticketListingFiltersFormFields.title]: 'Titel',
    [ticketListingFiltersFormFields.assigneeMitarbeiterId]: 'Bearbeiter',
    [ticketListingFiltersFormFields.status]: 'Status',
    [ticketListingFiltersFormFields.priority]: 'Priorität',
    [ticketListingFiltersFormFields.dueDateFrom]: 'Fälligkeit',
    [ticketListingFiltersFormFields.participantId]: 'Beteiligte',
  };

  return {
    ...baseList,
    ...(includeObjekt ? { [ticketListingFiltersFormFields.objektId]: 'Objekt' } : {}),
    ...(includeBestandseinheit ? { [ticketListingFiltersFormFields.bestandseinheitId]: 'Bestandseinheit' } : {}),
    ...(includeVertrag ? { [ticketListingFiltersFormFields.vertragId]: 'Vertrag' } : {}),
    ...(includeRechtstraeger ? { [ticketListingFiltersFormFields.rechtstraegerId]: 'Rechtstraeger' } : {}),
  };
};
