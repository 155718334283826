import React, { FC } from 'react';
import { Divider, Typography } from 'antd';
import { useCreateObjektInfoFeldTicketMutation } from '../../../../../Ticket/gql/TicketMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { mapFormValuesToTicketCreateInput, TicketFormValues } from '../../../../../Ticket/Form/ticketFormMapper';
import TicketListing from '../../../../../Ticket/Listing/TicketListing';
import TicketListingFilters from '../../../../../Ticket/Listing/TicketListingFilters';
import TicketActionButtonsAndDrawer from '../../../../../Ticket/TicketActionButtonsAndDrawer';
import { AbstractObjektInfoFeld } from '../../../../../../types';
import { gqlOperations } from '../../../../../../graphql/gqlNamedOperations-generated';
import TicketListingCalendar from '../../../../../Ticket/Listing/TicketListingCalendar';

type Props = {
  objektId: string;
  infoFeld: AbstractObjektInfoFeld;
  onSuccess: () => void;
};

const ObjektInfoFeldTicketListing: FC<Props> = ({ objektId, infoFeld, onSuccess }) => {
  const [runCreate, { loading: loadingCreate }] = useCreateObjektInfoFeldTicketMutation({
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onSubmitCreate = async (values: TicketFormValues) => {
    const response = await runCreate({
      variables: { objektId, objektInfoFeldId: infoFeld.objektInfoFeldId, input: mapFormValuesToTicketCreateInput(values) },
      refetchQueries: () => [gqlOperations.Query.TicketList],
    });
    return response.data?.createObjektInfoFeldTicket.data;
  };

  return (
    <TicketListing
      tableFilterIdentifier={`objekt-info-feld-${infoFeld.type.value}-ticket}`}
      objektInfoFeldId={infoFeld.objektInfoFeldId}
      onSuccess={onSuccess}
      bottom={(formikProps) => (
        <>
          <Divider />
          <Typography.Title level={4} style={{ marginTop: 16 }}>
            Kalender
          </Typography.Title>
          <TicketListingCalendar formikProps={formikProps} objektInfoFeldId={infoFeld.objektInfoFeldId} />
        </>
      )}
    >
      {({ formikProps, refetch, queryParams }) => (
        <TicketListingFilters
          formikProps={formikProps}
          queryParams={queryParams}
          actionButtons={
            <TicketActionButtonsAndDrawer
              refetch={refetch}
              allowCreate
              loadingCreate={loadingCreate}
              onFormSubmitCreate={onSubmitCreate}
              formikProps={formikProps}
            />
          }
        />
      )}
    </TicketListing>
  );
};

export default ObjektInfoFeldTicketListing;
