import React, { FC } from 'react';
import { EditOutlined, MinusOutlined } from '@ant-design/icons';
import { Box } from 'rebass';
import { Button, Space, Typography } from 'antd';
import ContactList from '../../Contact/ContactList';
import { IContactPersonCreateOrUpdateInput } from './TContactPerson';

type ContactPersonProps = {
  contactPerson: IContactPersonCreateOrUpdateInput;
  removeContactPerson: () => void;
  getEditFocusOnContactPerson: () => void;
};

const ContactPersonFormListItem: FC<ContactPersonProps> = ({ contactPerson, removeContactPerson, getEditFocusOnContactPerson }) => (
  <Space style={{ width: '100%', justifyContent: 'space-between' }}>
    <Typography.Text>
      {contactPerson.salutation} {contactPerson.title} {contactPerson.firstname} {contactPerson.lastname} {contactPerson.titleTrailing}
    </Typography.Text>
    <ContactList contactList={contactPerson.emailList} isMore />
    <ContactList contactList={contactPerson.phoneList} isMore />
    <Box mx="auto" />
    <Space>
      <Button size="small" shape="circle" icon={<MinusOutlined />} onClick={removeContactPerson} />
      <Button size="small" shape="circle" icon={<EditOutlined />} onClick={getEditFocusOnContactPerson} />
    </Space>
  </Space>
);

export default ContactPersonFormListItem;
