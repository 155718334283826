import React, { FC } from 'react';

type ErrorFallbackProps = {
  componentStack?: string;
  error?: Error;
};

const ErrorFallback: FC<ErrorFallbackProps> = ({ error, componentStack }) => (
  <div role="alert">
    <p>Something went wrong:</p>
    <pre>{error ? error.toString() : ''}</pre>
    <pre>{componentStack}</pre>
  </div>
);

export default ErrorFallback;
