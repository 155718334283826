import React, { FC } from 'react';
import ObjektAbrechnungTemplate from '../../../../../pages/PDFTemplates/templates/objektAbrechnung/ObjektAbrechnungTemplate';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import { ObjAbrDefGeneralSettingsFormValues } from '../../../../Abrechnungsdefinition/shared/Templates/shared/BkOrWe/ObjAbrDefGeneralSettingsForm/objAbrDefGeneralSettingsFormMapper';
import TemplateAbrechnung from '../../../../Abrechnungsdefinition/shared/Templates/TemplateAbrechnung';
import {
  createObjektAbrechnungTemplatePDFData,
  mapTextbausteinToObjektAbrechnungSelectableSection,
} from '../../../../Abrechnungsdefinition/shared/Templates/WeTemplates/weAbrDefTemplateFormObjAbrMapper';

type TemplateObjektAbrechnungProps = {
  values: TextbausteinFormValues[];
  onTemplateChange: (templateValues: TextbausteinFormValues[]) => void;
  objAbrDefGeneralSettingsValues: ObjAbrDefGeneralSettingsFormValues;
};

const TemplateObjektAbrechnung: FC<TemplateObjektAbrechnungProps> = ({ values, onTemplateChange, objAbrDefGeneralSettingsValues }) => (
  <TemplateAbrechnung
    values={values}
    onTemplateChange={onTemplateChange}
    renderPreview={({ selectedTextbaustein }) => (
      <ObjektAbrechnungTemplate
        data={createObjektAbrechnungTemplatePDFData(values, objAbrDefGeneralSettingsValues)}
        isInEditMode
        selectedTextbaustein={mapTextbausteinToObjektAbrechnungSelectableSection(selectedTextbaustein)}
      />
    )}
  />
);

export default TemplateObjektAbrechnung;
