import React, { FC } from 'react';
import { Modal } from 'antd';
import ExecuteForm from './Form/Execute/ExecuteForm';

type Props = {
  onAction: () => void;
  open: boolean;
};

const PaymentProposalExecutionModal: FC<Props> = ({ onAction, open }) => {
  return (
    <Modal title="Zahlungsvorschlag durchführen" onCancel={onAction} open={open} destroyOnClose maskClosable={false} footer={false}>
      <ExecuteForm onCancel={onAction} onSuccess={onAction} />
    </Modal>
  );
};

export default PaymentProposalExecutionModal;
