import React from 'react';
import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../../components/Template/DetailsPageTemplate';
import VerarbeitungDetailsPageCard from '../../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../../features/Verarbeitung/verarbeitungHelpers';
import menuListMahnungVerbuchen from './menuListMahnungVerbuchen';
import { VerarbeitungMahnungDetailsPageRouteParams } from '../routes';
import { useMahnungVerbuchenGenerierlaufQuery } from '../../../../features/Verarbeitung/gql/MahnungGenerierlauf/MahnungVerbuchenGenerierlaufQueries.types';
import { MahnungVerbuchenGenerierlauf } from '../../../../types';
import { useRestartMahnungVerbuchenGenerierlaufMutation } from '../../../../features/Verarbeitung/gql/MahnungGenerierlauf/MahnungVerbuchenGenerierlaufMutations.types';

const MahnungVerbuchenDetailsPage = () => {
  const { generierlaufId } = useParams() as VerarbeitungMahnungDetailsPageRouteParams;

  const { data, refetch } = useMahnungVerbuchenGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getMahnungVerbuchenGenerierlauf.data;

  const [runRestart] = useRestartMahnungVerbuchenGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<MahnungVerbuchenGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.MahnungVerbuchen}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.MahnungVerbuchen}
          onGenerierlaufRestart={runRestart}
        />
      )}
      sidebarMenuList={(generierlauf) => menuListMahnungVerbuchen(generierlauf)}
    />
  );
};

export default MahnungVerbuchenDetailsPage;
