import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AppKontoFieldsFragmentDoc } from '../../Kontenplan/gql/KontenplanFragments.types';
import { AufteilungsschluesselFieldsFragmentDoc } from '../../Aufteilungsschluessel/gql/AufteilungsschluesselFragments.types';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
import { AbrechnungskreisFieldsFragmentDoc } from '../../Abrechnungskreis/gql/AbrechnungskreisFragments.types';
import { ObjektBaseFragmentDoc } from '../../Objekt/gql/ObjektFragments.types';
import { BestandseinheitBaseFieldsFragmentDoc } from '../../Bestandseinheit/gql/BestandseinheitFragments.types';
import { BeVertragVorschreibungspositionBaseFieldsFragmentDoc } from '../../Vertrag/BeVertrag/Vertragsdaten/VorschreibungspositionTab/gql/BeVertragVorschreibungspositionFragments.types';
import { VertragBaseFieldsFragmentDoc } from '../../Vertrag/gql/VertragFragments.types';
export type BudgetingKontoBaseFieldsFragment = {
  budgetingKontoId: string;
  estimateNextYear: number;
  estimateRestOfCurrentYear: number;
  estimateTotalCurrentYear: number;
  gebuchtCurrentYear: number;
  gebuchtPrevYear: number;
  increase: number;
  konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
};

export type BudgetingKontoFieldsFragment = {
  budgetingKontoId: string;
  estimateNextYear: number;
  estimateRestOfCurrentYear: number;
  estimateTotalCurrentYear: number;
  gebuchtCurrentYear: number;
  gebuchtPrevYear: number;
  increase: number;
  budgetingAufteilungsschluesselList: Array<{
    aufteilungsschluessel: {
      aufteilungsschluesselId: string;
      bezeichnung: string;
      bezugsbasisEditable?: boolean | null;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      updatedByMitarbeiterId?: string | null;
      bezugsbasis: { text: string; value: Types.Bezugsbasis };
      masseinheit: { text: string; value: Types.Masseinheit };
      status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    vertragLevel: { shortName: string; text: string; value: Types.VertragLevel };
  }>;
  budgetingBuchungszeileList: Array<{
    ausgabenCurrentYear?: number | null;
    ausgabenPrevYear?: number | null;
    belegDatum: string;
    belegFileId?: string | null;
    belegnummer?: string | null;
    buchungId?: string | null;
    buchungsdatum?: string | null;
    steuer?: number | null;
    text: string;
    vertragspartner?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
  }>;
  konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
};

export type BudgetingVPosBaseFieldsFragment = {
  bezeichnung: string;
  budgetingVPosId: string;
  estimateNextYear: number;
  increase: number;
  leaveFlatRate: boolean;
  vorschreibungCurrentYear: number;
  vorschreibungPrevYear: number;
  vorschreibungRestOfCurrentYear: number;
  vorschreibungTotalCurrentYear: number;
  vorschreibungspositionId?: string | null;
  art: { text: string; value: Types.VorschreibungspositionArt };
  status: { description?: string | null; text: string; value: Types.ObjektVorschreibungspositionStatus };
};

export type BudgetingVPosFieldsFragment = {
  bezeichnung: string;
  budgetingVPosId: string;
  estimateNextYear: number;
  increase: number;
  leaveFlatRate: boolean;
  vorschreibungCurrentYear: number;
  vorschreibungPrevYear: number;
  vorschreibungRestOfCurrentYear: number;
  vorschreibungTotalCurrentYear: number;
  vorschreibungspositionId?: string | null;
  art: { text: string; value: Types.VorschreibungspositionArt };
  budgetingAbrKreisList: Array<{
    estimateNextYear: number;
    estimateRestOfCurrentYear: number;
    estimateTotalCurrentYear: number;
    gebuchtCurrentYear: number;
    gebuchtPrevYear: number;
    increase: number;
    abrechnungskreis: {
      abrechnungskreisId: string;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      updatedByMitarbeiterId?: string | null;
      status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
      type: { text: string; value: Types.AbrechnungskreisType };
      verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    budgetingKontoList: Array<{
      budgetingKontoId: string;
      estimateNextYear: number;
      estimateRestOfCurrentYear: number;
      estimateTotalCurrentYear: number;
      gebuchtCurrentYear: number;
      gebuchtPrevYear: number;
      increase: number;
      budgetingAufteilungsschluesselList: Array<{
        aufteilungsschluessel: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        vertragLevel: { shortName: string; text: string; value: Types.VertragLevel };
      }>;
      budgetingBuchungszeileList: Array<{
        ausgabenCurrentYear?: number | null;
        ausgabenPrevYear?: number | null;
        belegDatum: string;
        belegFileId?: string | null;
        belegnummer?: string | null;
        buchungId?: string | null;
        buchungsdatum?: string | null;
        steuer?: number | null;
        text: string;
        vertragspartner?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
      }>;
      konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
    }>;
  }>;
  budgetingAufteilungsschluesselList: Array<{
    aufteilungsschluessel: {
      aufteilungsschluesselId: string;
      bezeichnung: string;
      bezugsbasisEditable?: boolean | null;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      updatedByMitarbeiterId?: string | null;
      bezugsbasis: { text: string; value: Types.Bezugsbasis };
      masseinheit: { text: string; value: Types.Masseinheit };
      status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    vertragLevel: { shortName: string; text: string; value: Types.VertragLevel };
  }>;
  status: { description?: string | null; text: string; value: Types.ObjektVorschreibungspositionStatus };
};

export type BudgetingBaseFieldsFragment = {
  bezeichnung: string;
  budgetSumNextYear?: number | null;
  budgetingId: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  currentYear: number;
  increase?: number | null;
  prevYear: number;
  updatedByMitarbeiterId?: string | null;
  vertragVPosWertDate?: string | null;
  vorschreibungCurrentYearSum: number;
  vorschreibungPrevYearSum: number;
  restOfCurrentYearSum?: number | null;
  totalCurrentYearSum?: number | null;
  objekt: {
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    objektId: string;
    updatedByMitarbeiterId?: string | null;
    rechnungsAussteller: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    status: { text: string; value: Types.ObjektStatus; description?: string | null };
    verwaltungsart: { text: string; value: Types.Verwaltungsart };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  resultSourceType?: { description?: string | null; text: string; value: Types.BudgetingResultSourceType } | null;
  status: { text: string; value: Types.BudgetingStatus };
  warningList: Array<{ message: string; type: string }>;
};

export type BudgetingFieldsFragment = {
  bezeichnung: string;
  budgetSumNextYear?: number | null;
  budgetingId: string;
  budgetingResultId?: string | null;
  considerationPeriodUntil: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  currentYear: number;
  increase?: number | null;
  prevYear: number;
  updatedByMitarbeiterId?: string | null;
  vertragVPosWertDate?: string | null;
  vorschreibungCurrentYearSum: number;
  vorschreibungPrevYearSum: number;
  restOfCurrentYearSum?: number | null;
  totalCurrentYearSum?: number | null;
  budgetingVPosList: Array<{
    bezeichnung: string;
    budgetingVPosId: string;
    estimateNextYear: number;
    increase: number;
    leaveFlatRate: boolean;
    vorschreibungCurrentYear: number;
    vorschreibungPrevYear: number;
    vorschreibungRestOfCurrentYear: number;
    vorschreibungTotalCurrentYear: number;
    vorschreibungspositionId?: string | null;
    art: { text: string; value: Types.VorschreibungspositionArt };
    budgetingAbrKreisList: Array<{
      estimateNextYear: number;
      estimateRestOfCurrentYear: number;
      estimateTotalCurrentYear: number;
      gebuchtCurrentYear: number;
      gebuchtPrevYear: number;
      increase: number;
      abrechnungskreis: {
        abrechnungskreisId: string;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        updatedByMitarbeiterId?: string | null;
        status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
        type: { text: string; value: Types.AbrechnungskreisType };
        verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      budgetingKontoList: Array<{
        budgetingKontoId: string;
        estimateNextYear: number;
        estimateRestOfCurrentYear: number;
        estimateTotalCurrentYear: number;
        gebuchtCurrentYear: number;
        gebuchtPrevYear: number;
        increase: number;
        budgetingAufteilungsschluesselList: Array<{
          aufteilungsschluessel: {
            aufteilungsschluesselId: string;
            bezeichnung: string;
            bezugsbasisEditable?: boolean | null;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            kurzBezeichnung: string;
            updatedByMitarbeiterId?: string | null;
            bezugsbasis: { text: string; value: Types.Bezugsbasis };
            masseinheit: { text: string; value: Types.Masseinheit };
            status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          vertragLevel: { shortName: string; text: string; value: Types.VertragLevel };
        }>;
        budgetingBuchungszeileList: Array<{
          ausgabenCurrentYear?: number | null;
          ausgabenPrevYear?: number | null;
          belegDatum: string;
          belegFileId?: string | null;
          belegnummer?: string | null;
          buchungId?: string | null;
          buchungsdatum?: string | null;
          steuer?: number | null;
          text: string;
          vertragspartner?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
        }>;
        konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
    }>;
    budgetingAufteilungsschluesselList: Array<{
      aufteilungsschluessel: {
        aufteilungsschluesselId: string;
        bezeichnung: string;
        bezugsbasisEditable?: boolean | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        bezugsbasis: { text: string; value: Types.Bezugsbasis };
        masseinheit: { text: string; value: Types.Masseinheit };
        status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      vertragLevel: { shortName: string; text: string; value: Types.VertragLevel };
    }>;
    status: { description?: string | null; text: string; value: Types.ObjektVorschreibungspositionStatus };
  }>;
  eigentuemerAbrechnungsdefinition?: {
    abrechnungsdefinitionId: string;
    bezeichnung: string;
    status?: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus } | null;
    type: { text: string; value: Types.AbrechnungsdefinitionType };
  } | null;
  mieterAbrechnungsdefinition?: {
    abrechnungsdefinitionId: string;
    bezeichnung: string;
    status?: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus } | null;
    type: { text: string; value: Types.AbrechnungsdefinitionType };
  } | null;
  objekt: {
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    objektId: string;
    updatedByMitarbeiterId?: string | null;
    rechnungsAussteller: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    status: { text: string; value: Types.ObjektStatus; description?: string | null };
    verwaltungsart: { text: string; value: Types.Verwaltungsart };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  resultSourceType?: { description?: string | null; text: string; value: Types.BudgetingResultSourceType } | null;
  status: { text: string; value: Types.BudgetingStatus };
  rechnungsAussteller?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  warningList: Array<{ message: string; type: string }>;
};

export type BudgetingResultFieldsFragment = {
  budgetingResultId: string;
  budgetingResultBestandseinheitList: Array<{
    nutzflaeche: number;
    nutzflaecheAnteil: number;
    nutzwert: number;
    nutzwertAnteil: number;
    bestandseinheit: {
      bestandseinheitId: string;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      objektId: string;
      status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    budgetingResultVertragList: Array<{
      annualNetto: number;
      monthlyNetto: number;
      budgetingResultVertragVPosWertList: Array<{
        annualNetto: number;
        monthlyNetto: number;
        validFrom: string;
        warning?: Types.BudgetingResultVertragVPosWertWarning | null;
        budgetingResultAufteilungsschluesselList: Array<{
          aufteilungsschluesselAnteil: number;
          aufteilungsschluesselBasis: number;
          budgetingWertAnteil: number;
          kurzBezeichnung: string;
          estimateNextYear: number;
          name: string;
          unitValue: number;
          budgetingResultBudgetingVPos?: {
            estimateNextYear: number;
            increase: number;
            vorschreibungCurrentYear: number;
            budgetingVPos: {
              bezeichnung: string;
              budgetingVPosId: string;
              estimateNextYear: number;
              increase: number;
              leaveFlatRate: boolean;
              vorschreibungCurrentYear: number;
              vorschreibungPrevYear: number;
              vorschreibungRestOfCurrentYear: number;
              vorschreibungTotalCurrentYear: number;
              vorschreibungspositionId?: string | null;
              art: { text: string; value: Types.VorschreibungspositionArt };
              status: { description?: string | null; text: string; value: Types.ObjektVorschreibungspositionStatus };
            };
          } | null;
          budgetingResultBudgetingKontoList: Array<{
            estimateNextYear: number;
            increase: number;
            vorschreibungCurrentYear: number;
            budgetingKonto: {
              budgetingKontoId: string;
              estimateNextYear: number;
              estimateRestOfCurrentYear: number;
              estimateTotalCurrentYear: number;
              gebuchtCurrentYear: number;
              gebuchtPrevYear: number;
              increase: number;
              konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
            };
          }>;
        }>;
        vertragVorschreibungsposition: {
          bezeichnung: string;
          brutto?: number | null;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          validFrom?: string | null;
          netto?: number | null;
          steuersatz: number;
          ust?: number | null;
          vorschreibungspositionId: string;
          art: { text: string; value: Types.VorschreibungspositionArt };
          basis?: { text: string; value: Types.VorschreibungspositionBasis } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
      }>;
      vertrag: {
        bestandseinheitId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        objektId: string;
        vertragId: string;
        vertragsBeginn: string;
        status: { text: string; value: Types.VertragStatus; description?: string | null };
        vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
        vertragspartner: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
    }>;
    nutzungsArt: { text: string; value: Types.NutzungsArt };
  }>;
  warningList: Array<{ text: string; type: Types.BudgetingResultWarning }>;
};

export const BudgetingBaseFieldsFragmentDoc = gql`
  fragment BudgetingBaseFields on BudgetingBase {
    bezeichnung
    budgetSumNextYear
    budgetingId
    createTs
    createdBy
    createdByMitarbeiterId
    currentYear
    increase
    objekt {
      ...ObjektBase
    }
    prevYear
    resultSourceType {
      description
      text
      value
    }
    status {
      text
      value
    }
    updatedByMitarbeiterId
    vertragVPosWertDate
    vorschreibungCurrentYearSum
    vorschreibungPrevYearSum
    restOfCurrentYearSum
    totalCurrentYearSum
    warningList {
      message
      type
    }
  }
  ${ObjektBaseFragmentDoc}
`;
export const BudgetingKontoFieldsFragmentDoc = gql`
  fragment BudgetingKontoFields on BudgetingKonto {
    budgetingAufteilungsschluesselList {
      aufteilungsschluessel {
        ...AufteilungsschluesselFields
      }
      vertragLevel {
        shortName
        text
        value
      }
    }
    budgetingBuchungszeileList {
      ausgabenCurrentYear
      ausgabenPrevYear
      belegDatum
      belegFileId
      belegnummer
      buchungId
      buchungsdatum
      steuer
      text
      vertragspartner {
        ...RechtstraegerBaseFields
      }
    }
    budgetingKontoId
    estimateNextYear
    estimateRestOfCurrentYear
    estimateTotalCurrentYear
    gebuchtCurrentYear
    gebuchtPrevYear
    increase
    konto {
      ...AppKontoFields
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
  ${RechtstraegerBaseFieldsFragmentDoc}
  ${AppKontoFieldsFragmentDoc}
`;
export const BudgetingVPosFieldsFragmentDoc = gql`
  fragment BudgetingVPosFields on BudgetingVPos {
    art {
      text
      value
    }
    bezeichnung
    budgetingAbrKreisList {
      abrechnungskreis {
        ...AbrechnungskreisFields
      }
      budgetingKontoList {
        ...BudgetingKontoFields
      }
      estimateNextYear
      estimateRestOfCurrentYear
      estimateTotalCurrentYear
      gebuchtCurrentYear
      gebuchtPrevYear
      increase
    }
    budgetingAufteilungsschluesselList {
      aufteilungsschluessel {
        ...AufteilungsschluesselFields
      }
      vertragLevel {
        shortName
        text
        value
      }
    }
    budgetingVPosId
    estimateNextYear
    increase
    leaveFlatRate
    status {
      description
      text
      value
    }
    vorschreibungCurrentYear
    vorschreibungPrevYear
    vorschreibungRestOfCurrentYear
    vorschreibungTotalCurrentYear
    vorschreibungspositionId
  }
  ${AbrechnungskreisFieldsFragmentDoc}
  ${BudgetingKontoFieldsFragmentDoc}
  ${AufteilungsschluesselFieldsFragmentDoc}
`;
export const BudgetingFieldsFragmentDoc = gql`
  fragment BudgetingFields on Budgeting {
    bezeichnung
    budgetSumNextYear
    budgetingId
    budgetingResultId
    budgetingVPosList {
      ...BudgetingVPosFields
    }
    considerationPeriodUntil
    createTs
    createdBy
    createdByMitarbeiterId
    currentYear
    eigentuemerAbrechnungsdefinition {
      abrechnungsdefinitionId
      bezeichnung
      status {
        description
        text
        value
      }
      type {
        text
        value
      }
    }
    increase
    mieterAbrechnungsdefinition {
      abrechnungsdefinitionId
      bezeichnung
      status {
        description
        text
        value
      }
      type {
        text
        value
      }
    }
    objekt {
      ...ObjektBase
    }
    prevYear
    resultSourceType {
      description
      text
      value
    }
    status {
      text
      value
    }
    rechnungsAussteller {
      ...RechtstraegerBaseFields
    }
    updatedByMitarbeiterId
    vertragVPosWertDate
    vorschreibungCurrentYearSum
    vorschreibungPrevYearSum
    restOfCurrentYearSum
    totalCurrentYearSum
    warningList {
      message
      type
    }
  }
  ${BudgetingVPosFieldsFragmentDoc}
  ${ObjektBaseFragmentDoc}
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
export const BudgetingVPosBaseFieldsFragmentDoc = gql`
  fragment BudgetingVPosBaseFields on BudgetingVPosBase {
    art {
      text
      value
    }
    bezeichnung
    budgetingVPosId
    estimateNextYear
    increase
    status {
      description
      text
      value
    }
    leaveFlatRate
    vorschreibungCurrentYear
    vorschreibungPrevYear
    vorschreibungRestOfCurrentYear
    vorschreibungTotalCurrentYear
    vorschreibungspositionId
  }
`;
export const BudgetingKontoBaseFieldsFragmentDoc = gql`
  fragment BudgetingKontoBaseFields on BudgetingKontoBase {
    budgetingKontoId
    estimateNextYear
    estimateRestOfCurrentYear
    estimateTotalCurrentYear
    gebuchtCurrentYear
    gebuchtPrevYear
    increase
    konto {
      ...AppKontoFields
    }
  }
  ${AppKontoFieldsFragmentDoc}
`;
export const BudgetingResultFieldsFragmentDoc = gql`
  fragment BudgetingResultFields on BudgetingResult {
    budgetingResultBestandseinheitList {
      bestandseinheit {
        ...BestandseinheitBaseFields
      }
      budgetingResultVertragList {
        annualNetto
        budgetingResultVertragVPosWertList {
          annualNetto
          budgetingResultAufteilungsschluesselList {
            aufteilungsschluesselAnteil
            aufteilungsschluesselBasis
            budgetingResultBudgetingVPos {
              budgetingVPos {
                ...BudgetingVPosBaseFields
              }
              estimateNextYear
              increase
              vorschreibungCurrentYear
            }
            budgetingResultBudgetingKontoList {
              budgetingKonto {
                ...BudgetingKontoBaseFields
              }
              estimateNextYear
              increase
              vorschreibungCurrentYear
            }
            budgetingWertAnteil
            kurzBezeichnung
            estimateNextYear
            name
            unitValue
          }
          monthlyNetto
          validFrom
          vertragVorschreibungsposition {
            ...BeVertragVorschreibungspositionBaseFields
          }
          warning
        }
        monthlyNetto
        vertrag {
          ...VertragBaseFields
        }
      }
      nutzflaeche
      nutzflaecheAnteil
      nutzungsArt {
        text
        value
      }
      nutzwert
      nutzwertAnteil
    }
    budgetingResultId
    warningList {
      text
      type
    }
  }
  ${BestandseinheitBaseFieldsFragmentDoc}
  ${BudgetingVPosBaseFieldsFragmentDoc}
  ${BudgetingKontoBaseFieldsFragmentDoc}
  ${BeVertragVorschreibungspositionBaseFieldsFragmentDoc}
  ${VertragBaseFieldsFragmentDoc}
`;
