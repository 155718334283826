import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ColumnsSettingsListQueryVariables = Types.Exact<{
  filterIdentifier?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type ColumnsSettingsListQuery = {
  getColumnsSettingsList: {
    data: Array<{ filterIdentifier: string; mitarbeiterId?: string | null; columns: string; username: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const ColumnsSettingsListDocument = gql`
  query ColumnsSettingsList($filterIdentifier: String) {
    getColumnsSettingsList(filterIdentifier: $filterIdentifier) {
      data {
        filterIdentifier
        mitarbeiterId
        columns
        username
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useColumnsSettingsListQuery(baseOptions?: Apollo.QueryHookOptions<ColumnsSettingsListQuery, ColumnsSettingsListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ColumnsSettingsListQuery, ColumnsSettingsListQueryVariables>(ColumnsSettingsListDocument, options);
}
export function useColumnsSettingsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ColumnsSettingsListQuery, ColumnsSettingsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ColumnsSettingsListQuery, ColumnsSettingsListQueryVariables>(ColumnsSettingsListDocument, options);
}
export function useColumnsSettingsListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ColumnsSettingsListQuery, ColumnsSettingsListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ColumnsSettingsListQuery, ColumnsSettingsListQueryVariables>(ColumnsSettingsListDocument, options);
}
export type ColumnsSettingsListQueryHookResult = ReturnType<typeof useColumnsSettingsListQuery>;
export type ColumnsSettingsListLazyQueryHookResult = ReturnType<typeof useColumnsSettingsListLazyQuery>;
export type ColumnsSettingsListSuspenseQueryHookResult = ReturnType<typeof useColumnsSettingsListSuspenseQuery>;
export type ColumnsSettingsListQueryResult = Apollo.QueryResult<ColumnsSettingsListQuery, ColumnsSettingsListQueryVariables>;
