import * as Types from '../../types';

import { gql } from '@apollo/client';
import { UserFieldsFragmentDoc } from './UserFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserQueryVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
}>;

export type UserQuery = {
  getUser: {
    data: {
      createdTs: string;
      email?: string | null;
      emailUnverified?: string | null;
      firstname?: string | null;
      lastname?: string | null;
      role: Types.UserRole;
      username: string;
      status: { text: string; value: Types.UserStatus };
      contactList?: Array<{
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
    };
  };
};

export type UserNameQueryVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
}>;

export type UserNameQuery = {
  getUserName: { data?: { firstname?: string | null; lastname?: string | null; role: string; username: string } | null };
};

export const UserDocument = gql`
  query User($username: String!) {
    getUser(username: $username) {
      data {
        ...userFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
`;
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables> & ({ variables: UserQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<typeof useUserSuspenseQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserNameDocument = gql`
  query UserName($username: String!) {
    getUserName(username: $username) {
      data {
        firstname
        lastname
        role
        username
      }
    }
  }
`;
export function useUserNameQuery(
  baseOptions: Apollo.QueryHookOptions<UserNameQuery, UserNameQueryVariables> &
    ({ variables: UserNameQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserNameQuery, UserNameQueryVariables>(UserNameDocument, options);
}
export function useUserNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserNameQuery, UserNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserNameQuery, UserNameQueryVariables>(UserNameDocument, options);
}
export function useUserNameSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserNameQuery, UserNameQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserNameQuery, UserNameQueryVariables>(UserNameDocument, options);
}
export type UserNameQueryHookResult = ReturnType<typeof useUserNameQuery>;
export type UserNameLazyQueryHookResult = ReturnType<typeof useUserNameLazyQuery>;
export type UserNameSuspenseQueryHookResult = ReturnType<typeof useUserNameSuspenseQuery>;
export type UserNameQueryResult = Apollo.QueryResult<UserNameQuery, UserNameQueryVariables>;
