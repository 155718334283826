import React, { FC } from 'react';
import { Empty } from 'antd';
import { useParams } from 'react-router-dom';
import { Kontierungstabelle } from '../../../../types';
import { KontierungstabellePageRouteParams } from '../../../../pages/SysSettingsPage/KundenSystem/KontierungstabellePage/routes';
import { kontierungstabelleTemplateTableColumns } from './kontierungstabelleTemplateTableColumns';
import NestedTableWithColSelector from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import NestedTableWithColSelectorWrapper from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelectorWrapper';
import KontierungstabelleTemplateErloesKontoTable from '../Level2/KontierungstabelleTemplateErloesKontoTable';

type Props = {
  kontierungstabelleList?: Kontierungstabelle[];
  refetch: () => void;
};

const KontierungstabelleTemplateTable: FC<Props> = ({ kontierungstabelleList, refetch }) => {
  const { kontierungstabelleId } = useParams<KontierungstabellePageRouteParams>();
  const getRowKey = (record: Kontierungstabelle) => record.kontierungstabelleId;

  return (
    <NestedTableWithColSelectorWrapper filterIdentifier="sys-settings-kontierungstabelle">
      <NestedTableWithColSelector<Kontierungstabelle>
        locale={{
          emptyText: <Empty description={<span>Keine Kontierungstabellen vorhanden</span>} />,
        }}
        loading={!kontierungstabelleList}
        dataSource={kontierungstabelleList}
        columns={kontierungstabelleTemplateTableColumns(refetch)}
        rowKey={getRowKey}
        expandable={{
          rowExpandable: (record) => !!record.errechnetesErloesKontoList.length,
          expandedRowRender: (record, index, indent, expanded) => (
            <KontierungstabelleTemplateErloesKontoTable
              visible={expanded}
              parentRowKeys={[getRowKey(record)]}
              erloesKontoList={record.errechnetesErloesKontoList}
            />
          ),
          defaultExpandedRowKeys: kontierungstabelleId ? [kontierungstabelleId] : [],
        }}
        useHighlighting
      />
    </NestedTableWithColSelectorWrapper>
  );
};

export default KontierungstabelleTemplateTable;
