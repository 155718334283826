import { FC } from 'react';
import { Typography } from 'antd';
import { Spacer } from '../../../components/Grid';
import { verarbeitungPageAndMenuListTitles } from '../verarbeitungHelpers';
import { ObjektFinancialPlanGenerierlauf } from '../../../types';
import ObjektFinancialPlanVerarbeitungEntryListing from './EntryListing/ObjektFinancialPlanVerarbeitungEntryListing';

type Props = {
  generierlauf: ObjektFinancialPlanGenerierlauf;
};

const VerarbeitungObjektFinancialPlan: FC<Props> = ({ generierlauf }) => {
  return (
    <>
      <Typography.Title level={4}>{verarbeitungPageAndMenuListTitles.ObjektFinancialPlan}</Typography.Title>
      <Spacer />
      <ObjektFinancialPlanVerarbeitungEntryListing generierlauf={generierlauf} />
    </>
  );
};

export default VerarbeitungObjektFinancialPlan;
