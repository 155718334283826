import React from 'react';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import EmailVerified from '../../shared/components/Email/EmailVerified';
import { User } from '../../types';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../constants/andromedaSystemSettingsUriPaths';

const adminTableColumns: ColumnProps<User>[] = [
  {
    title: 'Benutzername',
    render: (record) => <Link to={{ pathname: `${URI_ANDROMEDA_SYS_SETTINGS.adminPage}/${record.username}` }}>{record.username}</Link>,
  },
  {
    title: 'E-Mail-Adresse',
    render: (text, record) => <EmailVerified state="table" email={record.email} emailUnverified={record.emailUnverified} />,
  },
  {
    title: 'Vorname',
    render: (text, record) => <span>{record.firstname ? record.firstname : 'nicht angegeben'}</span>,
  },
  {
    title: 'Nachname',
    render: (text, record) => <span>{record.lastname ? record.lastname : 'nicht angegeben'}</span>,
  },
  {
    title: 'Status',
    key: 'status',
    render: (text, record) => <span>{record.status.value}</span>,
  },
];

export default adminTableColumns;
