import { FC } from 'react';
import { useFirmendatenContactPersonDeletionHistoryListQuery } from '../../../../features/KundenSystem/Firmendaten/gql/FirmendatenQueries.types';
import HistoryListingTable from '../../../../features/History/HistoryListingTable';
import { HistoryType } from '../../../../features/History/shared/HistoryModal';

type Props = {
  firmendatenId: string;
};

const FirmendatenContactPersonDeletionHistoryListingTable: FC<Props> = ({ firmendatenId }) => {
  const { data, loading } = useFirmendatenContactPersonDeletionHistoryListQuery({ variables: { firmendatenId } });
  const historyList = data?.getFirmendatenContactPersonDeletionHistoryList.data;

  return (
    <HistoryListingTable historyType={HistoryType.Deletion} historyList={historyList} loading={loading} filterIdPrefix="firmendaten-contact-person" />
  );
};

export default FirmendatenContactPersonDeletionHistoryListingTable;
