import { FC } from 'react';
import { Formik } from 'formik';
import ObjektInfoFeldListingForm from './ObjektInfoFeldListingForm';
import { ObjektInfoFeldListingFiltersFormValues } from './objektInfoFeldListingFiltersFormMapper';
import { Objekt } from '../../../types';

type ObjektInfoFeldListingProps = {
  objekt: Objekt;
};

const ObjektInfoFeldListing: FC<ObjektInfoFeldListingProps> = ({ objekt }) => {
  return (
    <Formik<ObjektInfoFeldListingFiltersFormValues> initialValues={{ type: undefined }} onSubmit={() => {}}>
      {(formikProps) => <ObjektInfoFeldListingForm objektId={objekt.objektId} formikProps={formikProps} />}
    </Formik>
  );
};

export default ObjektInfoFeldListing;
