import { FC } from 'react';
import { FormikProps } from 'formik';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { camtVerarbeitungEntryFiltersFormFields, CamtVerarbeitungEntryFiltersFormValues } from './listingFiltersFormMapper';
import FiltersWith4OrMoreFields from '../../../../../components/Filters/FiltersWith4OrMoreFields';
import GenerierlaufEntryExitCodeListFormSelect from '../../../shared/Filters/Payment/GenerierlaufEntryExitCodeListFormSelect';
import { PaymentGenerierlaufType } from '../../../../../types';
import GenerierlaufIbanListFormSelect from '../../../shared/Filters/Payment/GenerierlaufIbanListFormSelect';
import GenerierlaufMitarbeiterListFormSelect from '../../../shared/Filters/Payment/GenerierlaufMitarbeiterListFormSelect';
import GenerierlaufEbicsUserUserIdListFormSelect from '../../../shared/Filters/Payment/GenerierlaufEbicsUserUserIdListFormSelect';
import GenerierlaufEbicsUserNameListFormSelect from '../../../shared/Filters/Payment/GenerierlaufEbicsUserNameListFormSelect';

type Props = {
  formikProps: FormikProps<CamtVerarbeitungEntryFiltersFormValues>;
  generierlaufId: string;
};

const ListingFilters: FC<Props> = ({ formikProps, generierlaufId }) => {
  return (
    <FiltersWith4OrMoreFields
      hideTitle
      leftColumn={
        <>
          <FormItemWithoutColon name={camtVerarbeitungEntryFiltersFormFields.mitarbeiterId} label="Mitarbeiter">
            <GenerierlaufMitarbeiterListFormSelect
              name={camtVerarbeitungEntryFiltersFormFields.mitarbeiterId}
              generierlaufId={generierlaufId}
              generierlaufType={PaymentGenerierlaufType.Camt}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={camtVerarbeitungEntryFiltersFormFields.ebicsUserName} label="Teilnehmer">
            <GenerierlaufEbicsUserNameListFormSelect
              name={camtVerarbeitungEntryFiltersFormFields.ebicsUserName}
              generierlaufId={generierlaufId}
              generierlaufType={PaymentGenerierlaufType.Camt}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={camtVerarbeitungEntryFiltersFormFields.ebicsUserUserId} label="Teilnehmerkennung">
            <GenerierlaufEbicsUserUserIdListFormSelect
              name={camtVerarbeitungEntryFiltersFormFields.ebicsUserUserId}
              generierlaufId={generierlaufId}
              generierlaufType={PaymentGenerierlaufType.Camt}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
      rightColumn={
        <>
          <FormItemWithoutColon name={camtVerarbeitungEntryFiltersFormFields.iban} label="IBAN">
            <GenerierlaufIbanListFormSelect
              name={camtVerarbeitungEntryFiltersFormFields.iban}
              generierlaufId={generierlaufId}
              generierlaufType={PaymentGenerierlaufType.Camt}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={camtVerarbeitungEntryFiltersFormFields.exitCodeList} label="Exit Code Status">
            <GenerierlaufEntryExitCodeListFormSelect
              name={camtVerarbeitungEntryFiltersFormFields.exitCodeList}
              generierlaufId={generierlaufId}
              generierlaufType={PaymentGenerierlaufType.Camt}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
    />
  );
};

export default ListingFilters;
