import React from 'react';
import { Badge, Select } from 'antd';
import { isStatusActive, isStatusArchived, isStatusGesperrt } from '../../../../helpers/statusHelper';
import { messageEntityArchived, messageEntityGesperrt } from '../../../message/message';
import { EntityStatusSelectProps, ISelectItemWithStatus } from './EntityStatusProps';
import { selectFilterOption } from '../../../../helpers/selectHelper';

// Supports elements without a status prop, treating them as active elements
function EntityStatusSelect<SelectItem extends ISelectItemWithStatus>({
  list,
  getSelectItemId,
  getSelectItemContent,
  getSelectItemTooltipTitle,
  ...restProps
}: EntityStatusSelectProps<SelectItem>) {
  const activeList = list.filter((item) => !item.status || isStatusActive(item.status));
  const archivedList = list.filter((item) => item.status && isStatusArchived(item.status));
  const gesperrtList = list.filter((item) => item.status && isStatusGesperrt(item.status));

  return (
    <Select allowClear showSearch filterOption={selectFilterOption} {...restProps}>
      {activeList.map((item) => {
        return (
          <Select.Option key={getSelectItemId(item)} value={getSelectItemId(item)} title={getSelectItemTooltipTitle(item)}>
            {getSelectItemContent(item)}
          </Select.Option>
        );
      })}
      {archivedList.length > 0 ? (
        <Select.OptGroup label="Archiviert">
          {archivedList.map((item) => (
            <Select.Option
              key={getSelectItemId(item)}
              value={getSelectItemId(item)}
              title={`${getSelectItemTooltipTitle(item)} \n${messageEntityArchived()}`}
            >
              {getSelectItemContent(item)}
              <Badge dot status="error" style={{ top: '-6px' }} />
            </Select.Option>
          ))}
        </Select.OptGroup>
      ) : null}
      {gesperrtList.length > 0 ? (
        <Select.OptGroup label="Gesperrt">
          {gesperrtList.map((item) => (
            <Select.Option
              key={getSelectItemId(item)}
              value={getSelectItemId(item)}
              title={`${getSelectItemTooltipTitle(item)} \n${messageEntityGesperrt()}`}
            >
              {getSelectItemContent(item)}
              <Badge dot status="error" style={{ top: '-6px' }} />
            </Select.Option>
          ))}
        </Select.OptGroup>
      ) : null}
    </Select>
  );
}

export default EntityStatusSelect;
