import React, { FC } from 'react';
import { Modal } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Form, DatePicker } from 'formik-antd';
import { Formik } from 'formik';
import { useCreateEigenbelegErstellenGenerierlaufMutation } from '../../../gql/BelegGenerierlauf/BelegGenerierlaufMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import { showSuccessMsgVerarbeitungStarted } from '../../../../../components/message/message';
import FormButtons from '../../../../../components/Button/FormButtons';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../../components/DatePicker/DatePicker_formikAntD';
import {
  mapFormValuesToVorschreibungAusgeben,
  vorschreibungAusgebenModalFormFields,
  VorschreibungAusgebenModalFormValues,
} from './vorschreibungAusgebenModalFormMapper';
import ObjektSelectWithAllTag from '../../../../../shared/ObjektSelect/ObjektSelectWithAllTag';

export type Props = {
  onCreate(): void;
  onCancel(): void;
  visible: boolean;
};

const VorschreibungAusgebenVerarbeitungModal: FC<Props> = ({ onCreate, onCancel, visible, ...props }) => {
  const [createBelegErstellenGenerierlauf, { loading }] = useCreateEigenbelegErstellenGenerierlaufMutation({
    onCompleted: () => showSuccessMsgVerarbeitungStarted('Belegerstellung für alle erstellten Vorschreibungen'),
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Modal
      open={visible}
      title={
        <>
          <QuestionCircleTwoTone twoToneColor="#FAAD14" />
          <span style={{ paddingLeft: '8px' }}>Vorschreibung ausgeben</span>
        </>
      }
      footer={null}
      onCancel={onCancel}
      destroyOnClose
      maskClosable={false}
      {...props}
    >
      <Formik<VorschreibungAusgebenModalFormValues>
        initialValues={{ belegdatum: dayjsCustom(), generierDatum: dayjsCustom() }}
        onSubmit={(values, { setSubmitting }) => {
          const input = mapFormValuesToVorschreibungAusgeben(values);
          return createBelegErstellenGenerierlauf({ variables: { input } })
            .then(() => {
              onCreate();
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {(formikProps) => (
          <Form layout="vertical">
            <FormItemWithoutColon name={vorschreibungAusgebenModalFormFields.objektIdList} label="Objekt">
              <ObjektSelectWithAllTag name={vorschreibungAusgebenModalFormFields.objektIdList} formikProps={formikProps} />
            </FormItemWithoutColon>
            <FormItemWithoutColon name={vorschreibungAusgebenModalFormFields.belegdatum} label="Ausgabe mit Rechnungsdatum">
              <DatePicker
                id={vorschreibungAusgebenModalFormFields.belegdatum}
                name={vorschreibungAusgebenModalFormFields.belegdatum}
                placeholder="Datum wählen"
                format={DATE_DISPLAY_FORMAT_DEFAULT}
                allowClear={false}
                size="small"
                style={{ width: '100%' }}
              />
            </FormItemWithoutColon>
            <FormButtons okText="Starten" onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} isOkLoading={loading} updateMode={false} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default VorschreibungAusgebenVerarbeitungModal;
