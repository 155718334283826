import React from 'react';
import TicketTableActions from './TicketTableActions';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { TicketBase, TicketOrderBy } from '../../../../types';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import MitarbeiterTooltip from '../../../../components/Card/MitarbeiterTooltip';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import StatusBadge from '../../../../components/Status/StatusBadge';
import OutdatedData from '../../../../components/Helpers/OutdatedData';
import TicketPriorityTag from '../../shared/TicketPriorityTag';
import TicketReminder from '../../shared/TicketReminder';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import DataWithStatus from '../../../../components/Helpers/DataWithStatus';
import { isTicketArchived } from '../../ticketHelpers';

const ticketTableColumns = (
  onAction: () => void,
  contextColumns?: TableWithColSelectorColumnProps<TicketBase>[]
): TableWithColSelectorColumnProps<TicketBase>[] => [
  {
    title: 'Titel',
    defaultSelected: true,
    dataIndex: TicketOrderBy.Title,
    sorter: true,
    render: (text, record) => (
      <div style={{ ...(record.isSubTicket ? { marginLeft: 12 } : {}) }}>
        <DataWithStatus status={record.ticketStatus} text={record.title} showBadgeDot={false} showStatusDescription={false} />
      </div>
    ),
  },
  {
    title: 'Typ',
    width: 100,
    render: (text, record) => (
      <DataWithStatus
        status={record.ticketStatus}
        text={!record.isSubTicket ? 'Hauptaufgabe' : 'Unteraufgabe'}
        showBadgeDot={false}
        showStatusDescription={false}
      />
    ),
  },
  {
    title: 'Art',
    sorter: true,
    width: 150,
    render: (text, record) => (
      <DataWithStatus status={record.ticketStatus} text="" showBadgeDot={false} showStatusDescription={false}>
        {() => (record.typeName ? <DataWithShortenedText text={record.typeName} maxTextLength={15} /> : <TextForEmptyValue textToShow="minus" />)}
      </DataWithStatus>
    ),
  },
  {
    title: 'Priorität',
    defaultSelected: true,
    width: 100,
    render: (text, record) => (
      <OutdatedData isOutdated={isTicketArchived(record)}>
        <TicketPriorityTag priority={record.priority} />
      </OutdatedData>
    ),
  },
  {
    title: 'Status',
    defaultSelected: true,
    width: 150,
    render: (text, record) => <StatusBadge entityStatus={record.ticketStatus} />,
  },
  {
    title: 'Bearbeiter',
    width: 80,
    defaultSelected: true,
    render: (text, record) => <MitarbeiterTooltip mitarbeiterId={record.assignee.mitarbeiterId} showsInitials alignment="left" />,
  },
  {
    title: 'Fälligkeit',
    width: 150,
    defaultSelected: true,
    render: (text, record) => (
      <DataWithStatus status={record.ticketStatus} text="" showBadgeDot={false} showStatusDescription={false}>
        {() => <CustomFormattedDate value={record.dueDate} />}
      </DataWithStatus>
    ),
  },
  {
    title: 'Erinnerung',
    width: 150,
    render: (text, record) => (
      <DataWithStatus status={record.ticketStatus} text="" showBadgeDot={false} showStatusDescription={false}>
        {() => <TicketReminder ticket={record} />}
      </DataWithStatus>
    ),
  },
  ...(contextColumns || []),
  {
    title: 'Erstellt am',
    width: 150,
    dataIndex: TicketOrderBy.CreateTs,
    sorter: true,
    render: (text, record) => (
      <DataWithStatus status={record.ticketStatus} text="" showBadgeDot={false} showStatusDescription={false}>
        {() => <FormattedDateTime createdTs={record.createTs} />}
      </DataWithStatus>
    ),
  },
  {
    title: 'Ersteller',
    width: 150,
    dataIndex: TicketOrderBy.CreatedBy,
    sorter: true,
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    width: 100,
    render: (text, record) => <TicketTableActions ticket={record} onAction={onAction} />,
  },
];

export default ticketTableColumns;
