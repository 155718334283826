import React, { FC } from 'react';
import StyledDanger from './Styled/Span.style';
import { FieldArrayErrorProps } from './FieldArrayInputProps';

export const FieldArrayError: FC<FieldArrayErrorProps> = ({ fieldArrayError }) => {
  if (typeof fieldArrayError === 'string') return <StyledDanger warnings={!!fieldArrayError}>{fieldArrayError}</StyledDanger>;
  return null;
};

export default FieldArrayError;
