import React, { FC } from 'react';
import { ReadOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';
import ActionDropdown from '../../../../components/Dropdown/ActionDropdown';
import { ReceivedBankTransactionVerbuchenGenerierlaufListEntry } from '../../../../types';
import { generatePathToBookingDetailsPage } from '../../../BookingDetails/bookingDetailsPaths';

type Props = {
  record: ReceivedBankTransactionVerbuchenGenerierlaufListEntry;
};

const ReceivedBankTransactionVerbuchenTableActions: FC<Props> = ({ record }) => {
  const bookingInfoListLength = record.bookingInfoList.length;

  let items: MenuProps['items'] = [];
  if (bookingInfoListLength === 1) {
    items = [
      {
        key: '0',
        label: 'Buchungsdetails ansehen',
        icon: <ReadOutlined />,
        disabled: !record.bookingInfoList[0].fibuBuchungId,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        onClick: () => window.open(generatePathToBookingDetailsPage([record.bookingInfoList[0].fibuBuchungId!])),
      },
    ];
  } else if (bookingInfoListLength > 1) {
    items = [
      {
        key: '0',
        label: 'Buchungsdetails ansehen',
        icon: <ReadOutlined />,
        children: record.bookingInfoList
          .filter((bookingInfo) => bookingInfo.fibuBuchungId)
          .map((bookingInfo, index) => ({
            key: index,
            label: bookingInfo.buchungType.text,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onClick: () => window.open(generatePathToBookingDetailsPage([bookingInfo.fibuBuchungId!])),
          })),
      },
    ];
  }

  return <ActionDropdown items={items} />;
};

export default ReceivedBankTransactionVerbuchenTableActions;
