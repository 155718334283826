import React from 'react';
import { Helmet } from 'react-helmet-async';
import { PageLayout } from '../../components/LayoutAndromeda';
import EmailDeliveryListing from '../../features/EmailDelivery/Listing/EmailDeliveryListing';

const EmailVersandPage = () => {
  return (
    <>
      <Helmet>
        <title>E-Mail Versand</title>
      </Helmet>
      <PageLayout>
        <EmailDeliveryListing />
      </PageLayout>
    </>
  );
};

export default EmailVersandPage;
