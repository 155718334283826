import React, { useEffect } from 'react';
import { Box } from 'rebass';
import { Form } from 'formik-antd';
import { FormikProps } from 'formik';
import ZuordnungColumnHeader from '../shared/ZuordnungColumn/ZuordnungColumnHeader';
import { showSuccessMsgOther } from '../../../components/message';
import ZuordnungKontoKlasseTitle from '../shared/ZuordnungKontoKlasseTitle';
import { ZuordnungKontoListingFormValues } from './zuordnungKontoListingFormMapper';
import { ZuordnungKontoKlasseType } from '../shared/AbrKreisKontenzuordnungHelpers';
import ZuordnungFormFieldArray from './FormFieldArray/ZuordnungFormFieldArray';
import { AbrechdefAbrechkreis, AbrechnungsdefinitionType, Konto } from '../../../types';
import { AbrKontenzuordnungFiltersFormValues } from '../Listing/Filters/abrKontenzuordnungFiltersMapper';

type Props = {
  abrDefinitionType: AbrechnungsdefinitionType;
  formikProps: FormikProps<ZuordnungKontoListingFormValues>;
  title: string;
  onClickEdit: () => void;
  isErloesKontoListOpen: boolean;
  isAufwandKontoListOpen: boolean;
  isVerrechnungListOpen: boolean;
  onValuesChange?: (isDirty: boolean) => void;
  withHeader: boolean;
  isSaveAndEditDisabled?: boolean;
  shouldSubmitOnChange?: boolean;
  isSubmitting?: boolean;
  filters?: AbrKontenzuordnungFiltersFormValues | null;
  aufwandKontoList: Konto[];
  erloesKontoList: Konto[];
  verrechnungKontoList: Konto[];
  abrDefVersionAbrechkreisList: AbrechdefAbrechkreis[];
};

const ZuordnungKontoListingFormContent = ({
  abrDefinitionType,
  formikProps,
  title,
  isSubmitting,
  onClickEdit,
  isAufwandKontoListOpen,
  isErloesKontoListOpen,
  isVerrechnungListOpen,
  onValuesChange,
  withHeader,
  shouldSubmitOnChange,
  filters,
  aufwandKontoList,
  erloesKontoList,
  verrechnungKontoList,
  abrDefVersionAbrechkreisList,
}: Props) => {
  useEffect(() => {
    onValuesChange?.(formikProps.dirty);
  }, [formikProps.dirty, onValuesChange]);

  return (
    <Form style={{ width: '320px' }}>
      <ZuordnungColumnHeader
        title={title}
        isEditDisabled={formikProps.isSubmitting || isSubmitting}
        onClickEdit={onClickEdit}
        isSaveDisabled={formikProps.isSubmitting || isSubmitting}
        onClickSave={() => showSuccessMsgOther('Abrechnungskreise zugeordnet')}
        isSaveLoading={isSubmitting}
        isListOpen={isErloesKontoListOpen || isAufwandKontoListOpen || isVerrechnungListOpen}
        withHeader={withHeader}
      />
      <>
        <ZuordnungKontoKlasseTitle title={title || ZuordnungKontoKlasseType.EINNAHMEN} isTransparent />
        {isErloesKontoListOpen && (
          <>
            <ZuordnungFormFieldArray
              name="zuordnungListForErloesKontoList"
              abrDefinitionType={abrDefinitionType}
              shouldSubmitOnChange={shouldSubmitOnChange}
              zuordnungsKontoKlasseType={ZuordnungKontoKlasseType.EINNAHMEN}
              kontoList={erloesKontoList}
              filters={filters}
              abrDefVersionAbrechkreisList={abrDefVersionAbrechkreisList}
            />
          </>
        )}
        <Box height={16} />
        <ZuordnungKontoKlasseTitle title={title || ZuordnungKontoKlasseType.AUSGABEN} isTransparent />
        {isAufwandKontoListOpen && (
          <ZuordnungFormFieldArray
            name="zuordnungListForAufwandKontoList"
            abrDefinitionType={abrDefinitionType}
            shouldSubmitOnChange={shouldSubmitOnChange}
            zuordnungsKontoKlasseType={ZuordnungKontoKlasseType.AUSGABEN} // = isAufwandsKonto
            kontoList={aufwandKontoList}
            filters={filters}
            abrDefVersionAbrechkreisList={abrDefVersionAbrechkreisList}
          />
        )}
        <Box height={16} />
        <ZuordnungKontoKlasseTitle title={title || ZuordnungKontoKlasseType.VERRECHNUNG} isTransparent />
        {isVerrechnungListOpen && (
          <ZuordnungFormFieldArray
            name="zuordnungListForVerrechnungList"
            abrDefinitionType={abrDefinitionType}
            shouldSubmitOnChange={shouldSubmitOnChange}
            zuordnungsKontoKlasseType={ZuordnungKontoKlasseType.VERRECHNUNG}
            kontoList={verrechnungKontoList}
            filters={filters}
            abrDefVersionAbrechkreisList={abrDefVersionAbrechkreisList}
          />
        )}
      </>
    </Form>
  );
};

export default ZuordnungKontoListingFormContent;
