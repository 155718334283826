import React, { FC } from 'react';
import { FormikProps } from 'formik';
import ListingFilters from '../../shared/EntryListingFilters/ListingFilters';
import { MahnungVerarbeitungEntryListingFiltersFormValues } from '../../shared/EntryListingFilters/ListingFiltersFormMapper';
import MahnungErstellenVerarbEntryTable from './MahnungErstellenVerarbEntryTable';
import { FibuGenerierlaufType, MahnungErzeugenGenerierlauf } from '../../../../../types';
import { TMahnungVerarbeitungQueryParams } from '../../shared/EntryListingFilters/filtersQueryParams';

type Props = {
  formikProps: FormikProps<MahnungVerarbeitungEntryListingFiltersFormValues>;
  queryParams: TMahnungVerarbeitungQueryParams;
  generierlauf: MahnungErzeugenGenerierlauf;
};

const MahnungErstellenVerarbEntryListing: FC<Props> = ({ formikProps, queryParams, generierlauf }) => {
  return (
    <>
      <ListingFilters
        formikProps={formikProps}
        generierlaufType={FibuGenerierlaufType.MahnungErstellung}
        generierlaufId={generierlauf.generierlaufId}
      />
      <MahnungErstellenVerarbEntryTable generierlaufId={generierlauf.generierlaufId} queryParams={queryParams} />
    </>
  );
};

export default MahnungErstellenVerarbEntryListing;
