import React from 'react';
import { Tag, Tooltip, Typography } from 'antd';
import { generatePathToRechtstraegerDetailsPage } from '../rechtstraegerHelper';
import LinkWithStatus from '../../../components/Link/LinkWithStatus';
import { RechtstraegerListEntry, RechtstraegerOrderBy } from '../../../types';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { MitarbeiterTooltip } from '../../../components/Card';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import RechtstraegerWithContactsTooltip from '../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

const rechtstraegerTableColumns: TableWithColSelectorColumnProps<RechtstraegerListEntry>[] = [
  {
    title: 'Anrede',
    defaultSelected: true,
    key: 'salutation',
    dataIndex: RechtstraegerOrderBy.Salutation,
    width: '100px',
    sorter: true,
    render: (text, record) =>
      record.salutation ? <Typography.Text>{record.salutation.text}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
    ellipsis: true,
  },
  {
    title: 'Bezeichnung',
    defaultSelected: true,
    key: 'rechtstraegerId',
    dataIndex: RechtstraegerOrderBy.KurzBezeichnung,
    sorter: true,
    width: '300px',
    render: (text, record) => (
      <RechtstraegerWithContactsTooltip rechtstraegerId={record.rechtstraegerId} rechtstraegerName={record.kurzBezeichnung} maxTextLength={40} />
    ),
  },
  {
    title: 'Kundennummer',
    defaultSelected: true,
    key: 'kundennummer',
    dataIndex: RechtstraegerOrderBy.Kundennummer,
    sorter: true,
    render: (text, record) => <DataWithShortenedText text={record.kundennummer} maxTextLength={25} />,
  },
  {
    title: 'Typ',
    defaultSelected: true,
    key: 'type',
    dataIndex: RechtstraegerOrderBy.Type,
    width: '250px',
    sorter: true,
    render: (text, record) => <Typography.Text>{record.type.text}</Typography.Text>,
    ellipsis: true,
  },
  {
    title: 'Merkmale',
    width: '300px',
    key: 'tagList',
    defaultSelected: true,
    render: (text, record) => {
      const MAX_TEXT_LENGTH = 15;
      const tagListLimitedByMaxTextLength = getTagListLimitedByMaxTextLength(record.tagList, MAX_TEXT_LENGTH);

      return (
        <Tooltip styles={{ root: { maxWidth: '800px' } }} title={record.tagList.join(', ')}>
          {tagListLimitedByMaxTextLength.map((tag, index, limitedTaglList) => {
            const isTagListLongerThanMaxTextLength = record.tagList.join().replaceAll(',', '').length > MAX_TEXT_LENGTH;
            const isTagLastListItem = index === limitedTaglList.length - 1;

            if (isTagLastListItem && isTagListLongerThanMaxTextLength) {
              return <Tag key={tag}>{tag}...</Tag>;
            }

            return <Tag key={tag}>{tag}</Tag>;
          })}
        </Tooltip>
      );
    },
  },
  {
    title: 'PLZ',
    defaultSelected: true,
    key: 'zipCode',
    dataIndex: RechtstraegerOrderBy.ZipCode,
    width: '80px',
    sorter: true,
    render: (text, record) => (record.zipCode ? <Typography.Text>{record.zipCode}</Typography.Text> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Ort',
    defaultSelected: true,
    key: 'city',
    dataIndex: RechtstraegerOrderBy.City,
    sorter: true,
    render: (text, record) => (record.city ? <Typography.Text>{record.city}</Typography.Text> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Erstellt am',
    dataIndex: RechtstraegerOrderBy.CreateTs,
    sorter: true,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    dataIndex: RechtstraegerOrderBy.CreatedBy,
    sorter: true,
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => (
      <LinkWithStatus
        key={record.rechtstraegerId}
        text="Details"
        status={record.status}
        to={generatePathToRechtstraegerDetailsPage(record.rechtstraegerId)}
        openInNewTab
      />
    ),
  },
];

const getTagListLimitedByMaxTextLength = (tagList: string[], maxTextLength: number) => {
  let totalLength = 0;

  return tagList.filter((item) => {
    totalLength += item.length;
    return totalLength <= maxTextLength;
  });
};

export default rechtstraegerTableColumns;
