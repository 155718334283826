import React, { FC } from 'react';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';
import { Mahnung } from '../../../types';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  mahnung: Mahnung;
};

const MahnungDates: FC<Props> = ({ mahnung }) => {
  return (
    <>
      <CardDetailsInfoRow infoRowTitle="Mahnungsstichtag">
        <CustomFormattedDate value={mahnung.mahnstichtag} />
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Freigegeben am">
        {mahnung.freigegebenTs ? <CustomFormattedDate value={mahnung.freigegebenTs} /> : <TextForEmptyValue textToShow="minus" />}
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Versandt am">
        {mahnung.versendetTs ? <CustomFormattedDate value={mahnung.versendetTs} /> : <TextForEmptyValue textToShow="minus" />}
      </CardDetailsInfoRow>
    </>
  );
};

export default MahnungDates;
