import { FC, PropsWithChildren } from 'react';
import { ApolloClient } from '@apollo/client/core';
import { ApolloProvider, from } from '@apollo/client';
import httpLink from './apolloHttpLink';
import errorLoggerLink from './apolloErrorLoggerLink';
import { apolloClientDefaultOptions } from './apolloClientDefaultOptions';
import apolloCache from './apolloCache';

const authApolloClient = () =>
  new ApolloClient({
    name: 'authApolloClient',
    link: from([errorLoggerLink, httpLink]),
    cache: apolloCache,
    defaultOptions: apolloClientDefaultOptions,
  });

const AuthApolloProvider: FC<PropsWithChildren> = ({ children }) => <ApolloProvider client={authApolloClient()}>{children}</ApolloProvider>;

export default AuthApolloProvider;
