import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PaymentFieldHelpListQueryVariables = Types.Exact<{
  selector?: Types.InputMaybe<Types.Scalars['String']['input']>;
  fieldHelp?: Types.InputMaybe<Types.Scalars['String']['input']>;
  deprecated?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  order?: Types.InputMaybe<Types.FieldHelpOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type PaymentFieldHelpListQuery = {
  getPaymentFieldHelpList: {
    data: {
      contentList: Array<{ deprecated: boolean; fieldHelp?: string | null; fieldHelpId: string; selector: string }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const PaymentFieldHelpListDocument = gql`
  query PaymentFieldHelpList(
    $selector: String
    $fieldHelp: String
    $deprecated: Boolean
    $order: FieldHelpOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getPaymentFieldHelpList(
      selector: $selector
      fieldHelp: $fieldHelp
      deprecated: $deprecated
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          deprecated
          fieldHelp
          fieldHelpId
          selector
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function usePaymentFieldHelpListQuery(baseOptions?: Apollo.QueryHookOptions<PaymentFieldHelpListQuery, PaymentFieldHelpListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentFieldHelpListQuery, PaymentFieldHelpListQueryVariables>(PaymentFieldHelpListDocument, options);
}
export function usePaymentFieldHelpListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentFieldHelpListQuery, PaymentFieldHelpListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentFieldHelpListQuery, PaymentFieldHelpListQueryVariables>(PaymentFieldHelpListDocument, options);
}
export function usePaymentFieldHelpListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PaymentFieldHelpListQuery, PaymentFieldHelpListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaymentFieldHelpListQuery, PaymentFieldHelpListQueryVariables>(PaymentFieldHelpListDocument, options);
}
export type PaymentFieldHelpListQueryHookResult = ReturnType<typeof usePaymentFieldHelpListQuery>;
export type PaymentFieldHelpListLazyQueryHookResult = ReturnType<typeof usePaymentFieldHelpListLazyQuery>;
export type PaymentFieldHelpListSuspenseQueryHookResult = ReturnType<typeof usePaymentFieldHelpListSuspenseQuery>;
export type PaymentFieldHelpListQueryResult = Apollo.QueryResult<PaymentFieldHelpListQuery, PaymentFieldHelpListQueryVariables>;
