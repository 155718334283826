import React, { FC } from 'react';
import { Empty } from 'antd';
import { ustRegelsetTemplateTableColumns } from './ustRegelsetTemplateTableColumns';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { UstRegelset } from '../../../../../types';

type Props = {
  ustRegelsetList?: UstRegelset[];
  loading: boolean;
  refetch: () => void;
};

const UstRegelsetTemplateTable: FC<Props> = ({ ustRegelsetList, loading, refetch }) => {
  return (
    <TableWithColSelector<UstRegelset>
      size="small"
      locale={{
        emptyText: <Empty description={<span>Kein USt-Regelset vorhanden</span>} />,
      }}
      loading={loading}
      dataSource={ustRegelsetList}
      columns={ustRegelsetTemplateTableColumns(refetch)}
      rowKey={(record) => record.ustRegelsetId}
      filterIdentifier="sys-settings-ust-regelset-template"
    />
  );
};

export default UstRegelsetTemplateTable;
