import { generatePath } from 'react-router-dom';
import { mapToQueryString, QueryParams } from '../../../hooks/useStringQueryParams';
import { SaldenlisteKontoType } from '../../../types';
import { PaginationQueryParamsProps } from '../../../hooks/usePaginationQueryParams';
import { URI_SALDENLISTE_PAGE } from '../saldenlisteUriPaths';

export enum SaldenlisteQueryParamKey {
  BUCHUNGSKREIS_ID = 'buchungskreisId',
  KONTO_TYPE = 'kontoType',
  OBJEKT_ID = 'objektId',
  BUCHUNGSMONAT = 'buchungsmonat',
}

export type TSaldenlisteQueryParams = QueryParams<{
  buchungskreisId: string;
  kontoType: SaldenlisteKontoType;
  objektId: string;
  buchungsmonat: string;
}>;

export const updateQueryParams = (
  navigate: (path: string) => void,
  filters: TSaldenlisteQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToSaldenlistePage(filters.buchungskreisId, filters.kontoType, filters.objektId, filters.buchungsmonat, paginationParams));

const generatePathToSaldenlistePage = (
  buchungskreisId?: string,
  kontoType?: SaldenlisteKontoType,
  objektId?: string,
  buchungsmonat?: string,
  paginationParams?: PaginationQueryParamsProps
) =>
  `${generatePath(URI_SALDENLISTE_PAGE)}?${mapToQueryString({
    [SaldenlisteQueryParamKey.BUCHUNGSKREIS_ID]: buchungskreisId,
    [SaldenlisteQueryParamKey.KONTO_TYPE]: kontoType,
    [SaldenlisteQueryParamKey.OBJEKT_ID]: objektId,
    [SaldenlisteQueryParamKey.BUCHUNGSMONAT]: buchungsmonat,
    ...paginationParams,
  })}`;
