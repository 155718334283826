import React, { FC } from 'react';
import { Tag } from 'antd';
import { OrderPaymentStatus, OrderPaymentStatusTuple } from '../../../../../types';
import theme from '../../../../../theme';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';

type Props = {
  status: OrderPaymentStatusTuple;
  fontWeight?: 'normal' | 'bold';
};

const PaymentStatusTag: FC<Props> = ({ status, fontWeight = 'normal' }) => {
  return (
    <DataWithShortenedText maxTextLength={18} text={status.text}>
      {(shortenedText) => (
        <Tag color={getStatusTagColor(status.value)} style={{ color: getFontColorForTag(status.value), fontWeight }}>
          {shortenedText}
        </Tag>
      )}
    </DataWithShortenedText>
  );
};

const getFontColorForTag = (status: OrderPaymentStatus) => {
  switch (status) {
    case OrderPaymentStatus.Erstellt:
      return theme.colors.black;
    case OrderPaymentStatus.DurchgefuehrtVerbucht:
    case OrderPaymentStatus.NichtDurchgefuehrt:
    case OrderPaymentStatus.NichtDurchfuehrbar:
    case OrderPaymentStatus.Durchgefuehrt:
    case OrderPaymentStatus.PaymentProposal:
    case OrderPaymentStatus.Durchfuehrbar:
    default:
      return theme.colors.white;
  }
};

const getStatusTagColor = (status: OrderPaymentStatus) => {
  switch (status) {
    case OrderPaymentStatus.PaymentProposal:
      return theme.colors.blue;

    case OrderPaymentStatus.Durchfuehrbar:
    case OrderPaymentStatus.DatentraegerWirdErzeugt:
    case OrderPaymentStatus.AnBankGesendet:
    case OrderPaymentStatus.DatentraegerErzeugt:
    case OrderPaymentStatus.AnBankGesendetInArbeit:
    case OrderPaymentStatus.AnBankGesendetUnterschriftenVollstaendig:
    case OrderPaymentStatus.Durchgefuehrt:
    case OrderPaymentStatus.DurchgefuehrtRueckgebucht:
      return theme.colors.success;

    case OrderPaymentStatus.NichtDurchfuehrbar:
    case OrderPaymentStatus.AnBankGesendetUnterschriftenFehlen:
      return theme.colors.warning;

    case OrderPaymentStatus.AnBankGesendetFehler:
    case OrderPaymentStatus.AnBankGesendetAbgebrochen:
    case OrderPaymentStatus.NichtDurchgefuehrt:
      return theme.colors.danger;

    case OrderPaymentStatus.DurchgefuehrtVerbucht:
    case OrderPaymentStatus.DurchgefuehrtRueckgebuchtVerbucht:
      return theme.colors.completed;

    case OrderPaymentStatus.Erstellt:
    default:
      return theme.colors.grey;
  }
};

export default PaymentStatusTag;
