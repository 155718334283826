import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BestandseinheitNutzflaecheVersionListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
}>;

export type BestandseinheitNutzflaecheVersionListQuery = {
  getBestandseinheitNutzflaecheVersionList: {
    data: Array<{
      beNutzflaecheId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      kommentar?: string | null;
      nutzflaeche: number;
      lastUpdateTs: string;
      validFrom: string;
      historicizedList?: Array<{
        beNutzflaecheId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        validFrom: string;
        kommentar?: string | null;
        lastUpdateTs: string;
        nutzflaeche: number;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type BestandseinheitNutzflaecheVersionQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  nutzflaecheVersionId: Types.Scalars['ID']['input'];
}>;

export type BestandseinheitNutzflaecheVersionQuery = {
  getBestandseinheitNutzflaecheVersion: {
    data: {
      beNutzflaecheId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      validFrom: string;
      kommentar?: string | null;
      lastUpdateTs: string;
      nutzflaeche: number;
      historicizedList?: Array<{
        beNutzflaecheId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        validFrom: string;
        kommentar?: string | null;
        lastUpdateTs: string;
        nutzflaeche: number;
      }> | null;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const BestandseinheitNutzflaecheVersionListDocument = gql`
  query BestandseinheitNutzflaecheVersionList($objektId: ID!, $bestandseinheitId: ID!) {
    getBestandseinheitNutzflaecheVersionList(objektId: $objektId, bestandseinheitId: $bestandseinheitId) {
      data {
        beNutzflaecheId
        createTs
        createdBy
        createdByMitarbeiterId
        current
        historicizedList {
          beNutzflaecheId
          createTs
          createdBy
          createdByMitarbeiterId
          current
          validFrom
          kommentar
          lastUpdateTs
          nutzflaeche
          warningList {
            attribute
            message
            type
          }
        }
        kommentar
        nutzflaeche
        lastUpdateTs
        validFrom
        warningList {
          attribute
          message
          type
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useBestandseinheitNutzflaecheVersionListQuery(
  baseOptions: Apollo.QueryHookOptions<BestandseinheitNutzflaecheVersionListQuery, BestandseinheitNutzflaecheVersionListQueryVariables> &
    ({ variables: BestandseinheitNutzflaecheVersionListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BestandseinheitNutzflaecheVersionListQuery, BestandseinheitNutzflaecheVersionListQueryVariables>(
    BestandseinheitNutzflaecheVersionListDocument,
    options
  );
}
export function useBestandseinheitNutzflaecheVersionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BestandseinheitNutzflaecheVersionListQuery, BestandseinheitNutzflaecheVersionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BestandseinheitNutzflaecheVersionListQuery, BestandseinheitNutzflaecheVersionListQueryVariables>(
    BestandseinheitNutzflaecheVersionListDocument,
    options
  );
}
export function useBestandseinheitNutzflaecheVersionListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BestandseinheitNutzflaecheVersionListQuery, BestandseinheitNutzflaecheVersionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BestandseinheitNutzflaecheVersionListQuery, BestandseinheitNutzflaecheVersionListQueryVariables>(
    BestandseinheitNutzflaecheVersionListDocument,
    options
  );
}
export type BestandseinheitNutzflaecheVersionListQueryHookResult = ReturnType<typeof useBestandseinheitNutzflaecheVersionListQuery>;
export type BestandseinheitNutzflaecheVersionListLazyQueryHookResult = ReturnType<typeof useBestandseinheitNutzflaecheVersionListLazyQuery>;
export type BestandseinheitNutzflaecheVersionListSuspenseQueryHookResult = ReturnType<typeof useBestandseinheitNutzflaecheVersionListSuspenseQuery>;
export type BestandseinheitNutzflaecheVersionListQueryResult = Apollo.QueryResult<
  BestandseinheitNutzflaecheVersionListQuery,
  BestandseinheitNutzflaecheVersionListQueryVariables
>;
export const BestandseinheitNutzflaecheVersionDocument = gql`
  query BestandseinheitNutzflaecheVersion($objektId: ID!, $bestandseinheitId: ID!, $nutzflaecheVersionId: ID!) {
    getBestandseinheitNutzflaecheVersion(objektId: $objektId, bestandseinheitId: $bestandseinheitId, nutzflaecheVersionId: $nutzflaecheVersionId) {
      data {
        beNutzflaecheId
        createTs
        createdBy
        createdByMitarbeiterId
        current
        validFrom
        historicizedList {
          beNutzflaecheId
          createTs
          createdBy
          createdByMitarbeiterId
          validFrom
          kommentar
          lastUpdateTs
          nutzflaeche
        }
        kommentar
        lastUpdateTs
        nutzflaeche
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useBestandseinheitNutzflaecheVersionQuery(
  baseOptions: Apollo.QueryHookOptions<BestandseinheitNutzflaecheVersionQuery, BestandseinheitNutzflaecheVersionQueryVariables> &
    ({ variables: BestandseinheitNutzflaecheVersionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BestandseinheitNutzflaecheVersionQuery, BestandseinheitNutzflaecheVersionQueryVariables>(
    BestandseinheitNutzflaecheVersionDocument,
    options
  );
}
export function useBestandseinheitNutzflaecheVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BestandseinheitNutzflaecheVersionQuery, BestandseinheitNutzflaecheVersionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BestandseinheitNutzflaecheVersionQuery, BestandseinheitNutzflaecheVersionQueryVariables>(
    BestandseinheitNutzflaecheVersionDocument,
    options
  );
}
export function useBestandseinheitNutzflaecheVersionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BestandseinheitNutzflaecheVersionQuery, BestandseinheitNutzflaecheVersionQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BestandseinheitNutzflaecheVersionQuery, BestandseinheitNutzflaecheVersionQueryVariables>(
    BestandseinheitNutzflaecheVersionDocument,
    options
  );
}
export type BestandseinheitNutzflaecheVersionQueryHookResult = ReturnType<typeof useBestandseinheitNutzflaecheVersionQuery>;
export type BestandseinheitNutzflaecheVersionLazyQueryHookResult = ReturnType<typeof useBestandseinheitNutzflaecheVersionLazyQuery>;
export type BestandseinheitNutzflaecheVersionSuspenseQueryHookResult = ReturnType<typeof useBestandseinheitNutzflaecheVersionSuspenseQuery>;
export type BestandseinheitNutzflaecheVersionQueryResult = Apollo.QueryResult<
  BestandseinheitNutzflaecheVersionQuery,
  BestandseinheitNutzflaecheVersionQueryVariables
>;
