import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { useMitarbeiterTooltipQuery } from '../../graphql/MitarbeiterService/MitarbeiterQueries.types';
import { useUserNameQuery } from '../../graphql/Authentication/UserQueries.types';
import { UserName } from '../../types';
import { MitarbeiterFragment } from '../../features/Mitarbeiter/gql/MitarbeiterFragments.types';

type Props = {
  userId?: string | null;
  mitarbeiterId?: string | null;
};

const MitarbeiterOrUserName: FC<Props> = ({ userId, mitarbeiterId }) => {
  const { loading: loadingUser, data: maybeUserNameData } = useUserNameQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { username: userId! },
    skip: !!mitarbeiterId || !userId,
  });

  const { loading: loadingMitarbeiter, data: maybeMitarbeiterData } = useMitarbeiterTooltipQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { mitarbeiterId: mitarbeiterId! },
    skip: !mitarbeiterId,
  });

  const user = maybeUserNameData?.getUserName.data;
  const mitarbeiter = maybeMitarbeiterData?.getMitarbeiterDetails.data;

  return (
    <Skeleton active title loading={loadingMitarbeiter && loadingUser}>
      {mitarbeiterOrUserNameText(user, mitarbeiter)}
    </Skeleton>
  );
};

const mitarbeiterOrUserNameText = (user?: UserName | null, mitarbeiter?: MitarbeiterFragment | null) => {
  if (mitarbeiter) {
    return `${mitarbeiter.firstname} ${mitarbeiter.lastname}`;
  } else if (user) {
    return `${user.firstname} ${user.lastname}`;
  } else {
    return 'System';
  }
};

export default MitarbeiterOrUserName;
