import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Modal } from 'antd';
import FormButtons from '../../../../../components/Button/FormButtons';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { showSuccessMsgDelete } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { useDeletePaymentProposalMutation } from '../../../../PaymentProposal/gql/PaymentProposalMutations.types';
import { PaymentProposalDeleteFormValues, paymentProposalFormFields, paymentProposalInitialValues } from './deleteFormMapper';
import { deleteFormValidationSchema } from './deleteFormValidationSchema';
import PaymentProposalSelectForDelete from '../../../../PaymentProposal/PaymentProposalSelectForDelete';
import { PaymentProposalData } from '../../../../PaymentProposal/gql/paymentProposalTypesHelper';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
};

const DeleteForm: FC<Props> = ({ onSuccess, onCancel }) => {
  const [runDeletePaymentProposal] = useDeletePaymentProposalMutation({
    onCompleted: () => {
      showSuccessMsgDelete('Zahlungsvorschlag');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<PaymentProposalDeleteFormValues>
      initialValues={paymentProposalInitialValues}
      validationSchema={deleteFormValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const onCancel = () => setSubmitting(false);
        const onDelete = () =>
          runDeletePaymentProposal({
            variables: { paymentProposalId: values.paymentProposalId },
          }).finally(() => setSubmitting(false));

        showConfirmDelete(onDelete, onCancel, values.paymentProposalName);
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithoutColon name={paymentProposalFormFields.paymentProposalId} label="Zahlungsvorschlag">
            <PaymentProposalSelectForDelete
              name={paymentProposalFormFields.paymentProposalId}
              onChange={(paymentProposal?: PaymentProposalData) => {
                formikProps.setFieldValue(paymentProposalFormFields.paymentProposalName, paymentProposal?.bezeichnung ?? '');
              }}
            />
          </FormItemWithoutColon>
          <FormButtons onCancel={onCancel} updateMode={false} isOkDisabled={formikProps.isSubmitting} okText="Löschen" />
        </Form>
      )}
    </Formik>
  );
};

const showConfirmDelete = (onDelete: () => void, onCancel: () => void, name: string) => {
  Modal.confirm({
    title: `Möchten Sie den Zahlungsvorschlag löschen?`,
    content: `${name}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return onDelete();
    },
    onCancel() {
      return onCancel();
    },
  });
};

export default DeleteForm;
