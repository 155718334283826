import * as Yup from 'yup';
import { objektBankDetailsFormFields } from './objektBankDetailsFormMapper';
import { entityIsRequired, entityMaxCharLength } from '../../../../components/message/validationMsg';

/* eslint-disable no-template-curly-in-string */
export const objektBankDetailsValidationSchema = Yup.object().shape({
  [objektBankDetailsFormFields.bezeichnung]: Yup.string().required(entityIsRequired('Bezeichnung')).max(50, entityMaxCharLength('Name', 50)),
  [objektBankDetailsFormFields.bankDetailsId]: Yup.string().required(entityIsRequired('Bankverbindung')),
});
/* eslint-enable no-template-curly-in-string */
