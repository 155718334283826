import React, { FC } from 'react';
import { Divider, Typography } from 'antd';
import InnerCardsAddressList from './InnerCardsAddressList';
import { Address } from '../../../../types';

type Props = {
  addressList: Address[];
};

const InnerCardAddressList: FC<Props> = ({ addressList }) => {
  return (
    <>
      <Typography.Title level={5}>Adresse</Typography.Title>
      <InnerCardsAddressList addressList={addressList} />
      <Divider style={{ marginTop: '8px', marginBottom: '8px' }} dashed />
    </>
  );
};

export default InnerCardAddressList;
