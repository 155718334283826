import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../../security/AuthRoute';
import { URI_SYS_SETTINGS } from '../../../../../constants/configurationUriPaths';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../../security/permissionChecks';
import SysSettingsAuftragsartenPage from './SysSettingsAuftragsartenPage';

const sysSettingsAuftragsartenPageRoute = (
  <Route
    path={URI_SYS_SETTINGS.auftragsartPage}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsAuftragsartenPage />} />}
  />
);

export default sysSettingsAuftragsartenPageRoute;
