import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../styles/pdfStyles';
import { vPosColumnWidthsAndAlignment } from '../objekt-block-tables-styles';
import { VVPos } from '../../../vorschreibung-types';

const VTVPosTable: FC<{ vPosList: VVPos[] }> = ({ vPosList }) => (
  <View style={pdfStyles.borderBottomSmall}>
    {vPosList.map((vPos, index) => (
      <View key={index} style={pdfStyles.row}>
        <Text
          style={[
            pdfStyles.textBulletin,
            {
              width: vPosColumnWidthsAndAlignment.vorschreibungsposition.width,
              textAlign: vPosColumnWidthsAndAlignment.vorschreibungsposition.textAlign,
            },
          ]}
        >
          {vPos.vorschreibungsposition}
        </Text>
        <Text
          style={[
            pdfStyles.textBulletin,
            {
              width: vPosColumnWidthsAndAlignment.netto.width,
              textAlign: vPosColumnWidthsAndAlignment.netto.textAlign,
            },
          ]}
        >
          {vPos.netto}
        </Text>
        <Text
          style={[
            pdfStyles.textBulletin,
            {
              width: vPosColumnWidthsAndAlignment.ustSatz.width,
              textAlign: vPosColumnWidthsAndAlignment.ustSatz.textAlign,
            },
          ]}
        >
          {vPos.ustSatz}
        </Text>
        <Text
          style={[
            pdfStyles.textBulletin,
            {
              width: vPosColumnWidthsAndAlignment.ustBetrag.width,
              textAlign: vPosColumnWidthsAndAlignment.ustBetrag.textAlign,
            },
          ]}
        >
          {vPos.ustBetrag}
        </Text>
        <Text
          style={[
            pdfStyles.textBulletin,
            {
              width: vPosColumnWidthsAndAlignment.brutto.width,
              textAlign: vPosColumnWidthsAndAlignment.brutto.textAlign,
            },
          ]}
        >
          {vPos.brutto}
        </Text>
      </View>
    ))}
  </View>
);

export default VTVPosTable;
