import React, { FC, useState } from 'react';
import { Drawer, MenuProps, Modal, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, HistoryOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useToggle } from '../../../../../../hooks/useToggle';
import { showSuccessMsgDelete } from '../../../../../../components/message';
import Contact from '../../../../../../shared/components/Contact/Contact';
import ContactPersonForm from '../../../Form/ContactPerson/ContactPersonForm';
import { TOperatingSiteList } from '../../../../../../shared/components/OperatingSite/List/OperatingSiteList';
import { generatePathToRechtstraegerDetailsPage } from '../../../../rechtstraegerHelper';
import ListItemTags from './Tags/ListItemTags';
import { ContactPerson, OperatingSiteActionInput } from '../../../../../../types';
import { StyledSpaceForDetails } from '../shared/ContactListItemStyleHelpers';
import ContactListItem from '../shared/ContactListItem';
import AuditFooter from '../../../../../../components/Footer/AuditFooter';
import HistoryModal, { HistoryType } from '../../../../../History/shared/HistoryModal';
import ContactPersonChangeHistoryListingTable from './ContactPersonChangeHistoryListingTable';
import { radixActionStyles } from '../../../../../../helpers/radixIconsStyles';
import { useDeleteContactPersonMutation } from '../../../gql/AddressesAndContactsMutations.types';

type Props = {
  contactPerson: ContactPerson;
  onSuccess: () => void;
  operatingSiteId: string;
  setOperatingSite: (operatingSite: TOperatingSiteList) => void;
  setTagList: (input: OperatingSiteActionInput) => void;
  loadingTagList: boolean;
  firmaId?: string;
  firmendatenId?: string;
  operatingSiteList?: TOperatingSiteList[];
};

const ContactPersonListItem: FC<Props> = ({
  contactPerson,
  onSuccess,
  operatingSiteId,
  setOperatingSite,
  setTagList,
  loadingTagList,
  firmaId,
  firmendatenId,
  operatingSiteList,
}) => {
  const [isDrawerOpen, toggleDrawerOpen] = useToggle();
  const [tags, setTags] = useState(contactPerson.tagList);
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const [runDelete] = useDeleteContactPersonMutation({
    variables: { contactPersonId: contactPerson.contactPersonId },
    onCompleted: () => {
      showSuccessMsgDelete('Ansprechpartner');
      onSuccess();
    },
  });

  const onUpdateSuccess = () => {
    onSuccess();
    toggleDrawerOpen();
  };

  const isDraggable = !!operatingSiteId;

  const operatingSiteListItem: MenuProps['items'] =
    operatingSiteList?.map((operatingSite) => ({
      key: operatingSite.operatingSiteId,
      label: `Verschieben zu ${operatingSite.bezeichnung}`,
      onClick: () => setOperatingSite(operatingSite),
    })) ?? [];

  const items: MenuProps['items'] = [
    { key: '1', label: 'Bearbeiten', onClick: toggleDrawerOpen, icon: <EditOutlined /> },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    { key: '3', label: 'Löschen', onClick: () => showConfirmDelete(runDelete), icon: <DeleteOutlined />, danger: true },
    ...operatingSiteListItem,
  ];

  const titel = contactPerson.title ? contactPerson.title : '';
  const bezeichnung = `${titel} ${contactPerson.lastname}, ${contactPerson.firstname}`;

  const contactDetails = (
    <StyledSpaceForDetails>
      {contactPerson.rechtstraeger ? (
        <Link to={generatePathToRechtstraegerDetailsPage(contactPerson.rechtstraeger.rechtstraegerId)} target="_blank">
          {bezeichnung}
        </Link>
      ) : (
        <Typography.Text>{bezeichnung}</Typography.Text>
      )}
      {contactPerson.contactList.map((item, index: number) => (
        <Contact contact={item} key={item.contactId || index} />
      ))}
      <ListItemTags tags={tags} setTags={setTags} setTagList={setTagList} loading={loadingTagList} />
    </StyledSpaceForDetails>
  );

  const footer = (
    <AuditFooter createTs={contactPerson.createTs} userId={contactPerson.createdBy} mitarbeiterId={contactPerson.createdByMitarbeiterId} />
  );

  return (
    <>
      <ContactListItem contactDetails={contactDetails} icon={<UserOutlined />} menuItems={items} isDraggable={isDraggable} footer={footer} />
      <Drawer title="Ansprechpartner aktualisieren" width={720} open={isDrawerOpen} onClose={toggleDrawerOpen} destroyOnClose>
        <ContactPersonForm
          onSuccess={onUpdateSuccess}
          onCancel={toggleDrawerOpen}
          firmaId={firmaId}
          operatingSiteId={operatingSiteId}
          contactPerson={contactPerson}
          firmendatenId={firmendatenId}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <ContactPersonChangeHistoryListingTable contactPersonId={contactPerson.contactPersonId} />
      </HistoryModal>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void) => {
  Modal.confirm({
    title: 'Sind Sie sicher das Sie diesen Ansprechpartner löschen möchten?',
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default ContactPersonListItem;
