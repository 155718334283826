import React from 'react';

import {
  VertragInfoFeld_VertragInfoFeldKuendigungsinfos_Fragment,
  VertragInfoFeld_VertragInfoFeldWeiterePerson_Fragment,
  VertragInfoFeldFragment,
} from '../gql/VertragInfoFeldFragments.types';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';

export type TVertragInfoFeldData = VertragInfoFeld_VertragInfoFeldWeiterePerson_Fragment | VertragInfoFeld_VertragInfoFeldKuendigungsinfos_Fragment;

export const isVertragInfoFeldWeiterePerson = (
  attribute: VertragInfoFeldFragment
): attribute is VertragInfoFeld_VertragInfoFeldWeiterePerson_Fragment => attribute.__typename === 'VertragInfoFeldWeiterePerson';

export const isVertragInfoFeldKuendigungsinfos = (
  attribute: VertragInfoFeldFragment
): attribute is VertragInfoFeld_VertragInfoFeldKuendigungsinfos_Fragment => attribute.__typename === 'VertragInfoFeldKuendigungsinfos';

export const getVertragInfoFeldShortName = (vertragInfoFeld: VertragInfoFeldFragment): string | React.ReactNode => {
  if (isVertragInfoFeldWeiterePerson(vertragInfoFeld)) {
    return getVertragInfoFeldWeiterePersonShortName(vertragInfoFeld);
  } else if (isVertragInfoFeldKuendigungsinfos(vertragInfoFeld)) {
    return getVertragInfoFeldKuendigungsinfosShortName(vertragInfoFeld);
  }
  return '';
};

export const getVertragInfoFeldWeiterePersonShortName = (vertragInfoFeld: VertragInfoFeld_VertragInfoFeldWeiterePerson_Fragment): string =>
  vertragInfoFeld.natuerlichePerson.kurzBezeichnung;

export const getVertragInfoFeldKuendigungsinfosShortName = (
  vertragInfoFeld: VertragInfoFeld_VertragInfoFeldKuendigungsinfos_Fragment
): React.ReactNode => <CustomFormattedDate value={vertragInfoFeld.kuendigungBis} />;
