import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { ObjektInfoFeldType } from '../../../../../../types';
import FormButtons from '../../../../../../components/Button/FormButtons';
import { useFDAuthorized } from '../../../../../../shared/Auth/useAuthorized';
import { hausbetreuungCreateFormFields, hausbetreuungCreateFormInitialValues, HausbetreuungCreateFormValues } from './hausbetreuungCreateFormMapper';
import { hausbetreuungValidationSchema } from '../shared/hausbetreuungValidationSchema';
import HausbetreuungDatenTab from '../Tabs/HausbetreuungDatenTab';
import { handleCreateSubmit } from '../../shared/handleSubmit';
import InfoFeldUpload from '../../../../../shared/InfoFeld/InfoFeldUpload';

type Props = {
  objektId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

const HausbetreuungCreateForm: FC<Props> = ({ objektId, onCancel, onSuccess }) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  return (
    <Formik<HausbetreuungCreateFormValues>
      initialValues={hausbetreuungCreateFormInitialValues}
      validationSchema={hausbetreuungValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const { rechtstraegerType, ...valuesWithoutType } = values;
        handleCreateSubmit(firmendatenId, objektId, ObjektInfoFeldType.Hausbetreuung, valuesWithoutType, setSubmitting, onSuccess);
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <HausbetreuungDatenTab formFields={hausbetreuungCreateFormFields} formikProps={formikProps} />
          <InfoFeldUpload
            name={hausbetreuungCreateFormFields.documentList}
            formikProps={formikProps}
            documentList={formikProps.values.documentList}
          />
          <FormButtons updateMode={false} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default HausbetreuungCreateForm;
