import React, { FC } from 'react';
import { Box, Flex } from 'rebass';
import { Button, Space, Typography } from 'antd';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import ZuordnungTableHeader from '../ZuordnungTableHeader';

type Props = {
  title: string;
  isEditDisabled?: boolean;
  onClickEdit: () => void;
  isSaveDisabled?: boolean;
  onClickSave?: () => void;
  isSaveLoading?: boolean;
  isListOpen: boolean;
  withHeader?: boolean;
};

const ZuordnungColumnHeader: FC<Props> = ({
  title,
  isEditDisabled,
  onClickEdit,
  isSaveDisabled,
  onClickSave,
  isSaveLoading,
  isListOpen,
  withHeader = true,
}) => (
  <ZuordnungTableHeader>
    {withHeader ? (
      <Flex flexDirection="row" alignItems="center">
        <Typography.Title level={5} style={{ paddingLeft: '12px' }}>
          {title}
        </Typography.Title>
        <Box mx="auto" />
        {isListOpen ? (
          <Space size={4}>
            <Button size="small" shape="circle" disabled={isEditDisabled} icon={<EditOutlined />} onClick={onClickEdit} />
            <Button
              size="small"
              shape="circle"
              htmlType="submit"
              disabled={isSaveDisabled}
              icon={<SaveOutlined />}
              onClick={() => onClickSave?.()}
              loading={isSaveLoading}
            />
          </Space>
        ) : null}
        <Box pr="16px" />
      </Flex>
    ) : null}
  </ZuordnungTableHeader>
);

export default ZuordnungColumnHeader;
