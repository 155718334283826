import React from 'react';
import { Typography } from 'antd';
import { Spacer } from '../Grid';

export interface EasyErrorType {
  type: string;
  message: string;
}

const ErrorNotification = (props: { errors: EasyErrorType[] }) => (
  <div>
    {props.errors.map((error, index) => (
      <div key={index}>
        <Typography.Text style={{ wordBreak: 'break-all' }} code>
          {error.type}
        </Typography.Text>
        <Spacer />
        <ul>
          <li style={{ marginLeft: '-16px' }} key={index}>
            {/* temp fix for jest specific error with expandable Paragraph "Maximum call stack size exceeded" */}
            <Typography.Paragraph style={{ wordBreak: 'break-word' }} ellipsis={{ rows: 4, expandable: !process.env.JEST_WORKER_ID }}>
              {error.message}
            </Typography.Paragraph>
          </li>
        </ul>
      </div>
    ))}
  </div>
);

export default ErrorNotification;
