import React, { FC } from 'react';
import objektAbrechnungeinnahmenKontoTableColumns from './objektAbrechnungeinnahmenKontoTableColumns';
import { AbrechnungTypeTuple, ObjAbrKonto } from '../../../../../../../types';
import ObjektAbrechnungBuchungszeileTable from '../../Level3/ObjektAbrechnungBuchungszeileTable';
import NestedTableWithColSelector from '../../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import objektAbrechnungEinnahmenBuchungszeileTableColumns from '../../Level3/objektAbrechnungEinnahmenBuchungszeileTableColumns';

type Props = {
  kontoList: ObjAbrKonto[];
  fromInclusive: string;
  toInclusive: string;
  objektId: string;
  buchungskreisId: string;
  abrechnungType: AbrechnungTypeTuple;
  parentRowKeys?: string[];
  visible?: boolean;
};

const ObjektAbrechnungEinnahmenKontoTable: FC<Props> = ({
  kontoList,
  fromInclusive,
  toInclusive,
  objektId,
  buchungskreisId,
  abrechnungType,
  parentRowKeys,
  visible = true,
}) => (
  <NestedTableWithColSelector<ObjAbrKonto>
    level={2}
    subLevel={3}
    parentRowKeys={parentRowKeys}
    visible={visible}
    dataSource={kontoList}
    columns={objektAbrechnungeinnahmenKontoTableColumns(objektId, fromInclusive, toInclusive, buchungskreisId, abrechnungType)}
    rowKey={getRowKey}
    expandable={{
      rowExpandable: (record) => !!record.buchungszeileList.length,
      expandedRowRender: (record, index, indent, expanded) => (
        <ObjektAbrechnungBuchungszeileTable
          subLevel={3}
          parentRowKeys={[...(parentRowKeys ?? []), getRowKey(record)]}
          visible={visible && expanded}
          columns={objektAbrechnungEinnahmenBuchungszeileTableColumns}
          buchungszeileList={record.buchungszeileList}
        />
      ),
    }}
  />
);

const getRowKey = (record: ObjAbrKonto) => record.kontoId;

export default ObjektAbrechnungEinnahmenKontoTable;
