import React, { FC } from 'react';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import SubAdministrationAbrechnungObjektTemplate from '../../../../../pages/PDFTemplates/templates/subAdministrationAbrechnung/Objekt/SubAdministrationAbrechnungObjektTemplate';
import { HeOrSubAbrDefGeneralSettingsFormValues } from '../../../../Abrechnungsdefinition/shared/Templates/shared/HeOrSub/GeneralSettingForm/heOrSubAbrDefGeneralSettingsFormMapper';
import {
  createSubAdministrationAbrechnungPDFTemplateData,
  mapTextbausteinToSubAdministrationAbrechnungSelectableSection,
} from '../../../../Abrechnungsdefinition/shared/Templates/SubTemplates/subAbrDefTemplateFormMapper';
import TemplateAbrechnung from '../../../../Abrechnungsdefinition/shared/Templates/TemplateAbrechnung';

type Props = {
  values: TextbausteinFormValues[];
  onTemplateChange: (templateValues: TextbausteinFormValues[]) => void;
  abrDefGeneralSettingsValues: HeOrSubAbrDefGeneralSettingsFormValues;
};

const TemplateSubAbrechnung: FC<Props> = ({ values, onTemplateChange, abrDefGeneralSettingsValues }) => (
  <TemplateAbrechnung
    values={values}
    onTemplateChange={onTemplateChange}
    renderPreview={({ selectedTextbaustein }) => (
      <SubAdministrationAbrechnungObjektTemplate
        data={createSubAdministrationAbrechnungPDFTemplateData(values, abrDefGeneralSettingsValues)}
        isInEditMode
        selectedTextbaustein={mapTextbausteinToSubAdministrationAbrechnungSelectableSection(selectedTextbaustein)}
      />
    )}
  />
);

export default TemplateSubAbrechnung;
