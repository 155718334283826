import React from 'react';
import styled from 'styled-components';
import NestedTable, { NestedTableProps } from '../../../../components/Table/NestedTable/NestedTable';

export const StyledTableReceivedBankTransaction = styled(NestedTable)`
  & .ant-table {
    margin: 0 !important;
    padding: 0 !important;
  }
` as <T>(props: NestedTableProps<T>) => React.ReactElement;
