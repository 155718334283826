import React, { FC } from 'react';
import { Drawer, MenuProps, Modal } from 'antd';
import { EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { ArchiveIcon, Link1Icon } from '@radix-ui/react-icons';
import { isStatusActive } from '../../../../helpers/statusHelper';
import { useToggle } from '../../../../hooks/useToggle';
import ObjektVorschreibungPositionUpdateForm from '../Form/ObjektVorschreibungPositionUpdateForm';
import { radixActionStyles } from '../../../../helpers/radixIconsStyles';
import { showSuccessMsgArchived, showSuccessMsgUnarchived } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import {
  useArchiveObjektVorschreibungspositionMutation,
  useUnarchiveObjektVorschreibungspositionMutation,
} from '../gql/ObjektVorschreibungPositionMutations.types';
import { ObjektVorschreibungsposition, Verwaltungsart } from '../../../../types';
import AssignVertragartListFormModal from './AssingVertragForm/AssignVertragartListFormModal';
import HistoryModal, { HistoryType } from '../../../History/shared/HistoryModal';
import ObjektVorschreibungsPositionChangeHistoryListingTable from './ObjektVorschreibungsPositionChangeHistoryListingTable';
import ActionDropdown from '../../../../components/Dropdown/ActionDropdown';

type Props = {
  objektId: string;
  vorschreibungsposition: ObjektVorschreibungsposition;
  verwaltungsart: Verwaltungsart;
  onAction: () => void;
};

const ObjektVorschreibungPositionTableActions: FC<Props> = ({ objektId, vorschreibungsposition, onAction, verwaltungsart }) => {
  const [isObjektVorschreibungPositionDrawerOpen, toggleObjektVorschreibungPositionDrawer] = useToggle();
  const [isAssignVertragsartModalOpen, toggleAssignVertragsartModal] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const entity = `ObjektVorschreibungsposition`;

  const [runArchivieren] = useArchiveObjektVorschreibungspositionMutation({
    onCompleted: () => {
      showSuccessMsgArchived(entity);
      onAction();
    },
    variables: {
      objektId,
      objektVorschreibungspositionId: vorschreibungsposition.objektVorschreibungspositionId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runReaktivieren] = useUnarchiveObjektVorschreibungspositionMutation({
    onCompleted: () => {
      showSuccessMsgUnarchived(entity);
      onAction();
    },
    variables: {
      objektId,
      objektVorschreibungspositionId: vorschreibungsposition.objektVorschreibungspositionId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Kurzbezeichnung bearbeiten',
      onClick: toggleObjektVorschreibungPositionDrawer,
      icon: <EditOutlined />,
      disabled: !isStatusActive(vorschreibungsposition.objektVorschreibungspositionStatus),
    },
    {
      key: '2',
      label: 'Vertagsarten zuweisen',
      onClick: toggleAssignVertragsartModal,
      icon: <Link1Icon />,
    },
    {
      key: '3',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '4',
      label: !isStatusActive(vorschreibungsposition.objektVorschreibungspositionStatus) ? 'Reaktivieren' : 'Archivieren',
      onClick: showConfirmArchivierenReaktivieren(runReaktivieren, runArchivieren, vorschreibungsposition),
      icon: <ArchiveIcon style={radixActionStyles} />,
    },
  ];

  return (
    <>
      <ActionDropdown items={items} />
      <Drawer
        title="Vorschreibungsposition bearbeiten"
        width={720}
        open={isObjektVorschreibungPositionDrawerOpen}
        onClose={toggleObjektVorschreibungPositionDrawer}
        destroyOnClose
      >
        <ObjektVorschreibungPositionUpdateForm
          vorschreibungsposition={vorschreibungsposition}
          objektId={objektId}
          onSubmit={() => {
            toggleObjektVorschreibungPositionDrawer();
            onAction();
          }}
          onCancel={toggleObjektVorschreibungPositionDrawer}
        />
      </Drawer>
      <AssignVertragartListFormModal
        objektId={objektId}
        vorschreibungsposition={vorschreibungsposition}
        onAction={onAction}
        isModalOpen={isAssignVertragsartModalOpen}
        toggleModal={toggleAssignVertragsartModal}
        verwaltungsart={verwaltungsart}
      />
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <ObjektVorschreibungsPositionChangeHistoryListingTable
          objektId={objektId}
          objektVorschreibungspositionId={vorschreibungsposition.objektVorschreibungspositionId}
        />
      </HistoryModal>
    </>
  );
};

const showConfirmArchivierenReaktivieren =
  (runReaktivieren: () => void, runArchivieren: () => void, vorschreibungsPosition: ObjektVorschreibungsposition) => () => {
    Modal.confirm({
      title: !isStatusActive(vorschreibungsPosition.objektVorschreibungspositionStatus)
        ? `Möchten Sie die ObjektVorschreibungsposition reaktivieren?`
        : `Möchten Sie die ObjektVorschreibungsposition archivieren?`,
      content: `${vorschreibungsPosition.bezeichnung}`,
      okText: !isStatusActive(vorschreibungsPosition.objektVorschreibungspositionStatus) ? 'Reaktivieren' : 'Archivieren',
      cancelText: 'Nein',
      onOk() {
        if (!isStatusActive(vorschreibungsPosition.objektVorschreibungspositionStatus)) {
          return runReaktivieren();
        } else {
          return runArchivieren();
        }
      },
    });
  };

export default ObjektVorschreibungPositionTableActions;
