import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { AbrechnungsdefinitionType } from '../../../../../../types';
import { useWeAbrechnungsdefinitionTemplateVersionQuery } from '../../../../../../features/AbrechnungsdefinitionTemplate/gql/WeAbrDef/WeAbrDefTemplateVersionQueries.types';
import BkOrWeAbrDefTemplateVersionView from '../../../../../../features/AbrechnungsdefinitionTemplate/Version/View/BkOrWeAbrDefTemplateVersionView';

type Props = {
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersionId: string;

  isHistoricized: boolean;
};
const AndromedaSysSettingsWeAbrechnungsdefinitionVersionViewPage: FC<Props> = ({
  abrechnungsdefinitionId,
  abrechnungsdefinitionVersionId,

  isHistoricized,
}) => {
  const { data } = useWeAbrechnungsdefinitionTemplateVersionQuery({
    variables: { abrechnungsdefinitionId, abrechnungsdefinitionVersionId, searchInHistory: isHistoricized },
  });
  const abrechnungsdefinitionVersion = data?.getWeAbrechnungsdefinitionTemplateVersion.data;

  if (!abrechnungsdefinitionVersion) return <Skeleton active />;

  return (
    <BkOrWeAbrDefTemplateVersionView
      abrDefType={AbrechnungsdefinitionType.WeAbrechnung}
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      abrechnungsdefinitionVersion={abrechnungsdefinitionVersion}
      isHistoricized={isHistoricized}
    />
  );
};

export default AndromedaSysSettingsWeAbrechnungsdefinitionVersionViewPage;
