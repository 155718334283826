import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { KontoFieldsFragmentDoc } from '../../Kontenplan/gql/KontenplanFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaldenlisteQueryVariables = Types.Exact<{
  buchungskreisId: Types.Scalars['String']['input'];
  buchungsmonat: Types.Scalars['String']['input'];
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  type?: Types.InputMaybe<Types.SaldenlisteKontoType>;
  order?: Types.InputMaybe<Types.SaldenlisteOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type SaldenlisteQuery = {
  getSaldenliste: {
    data: {
      contentList: Array<{
        saldoEB: number;
        saldoEnd: number;
        konto: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          haupt: boolean;
          kontoId: string;
          kontoTemplateId?: string | null;
          nummer: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          klasse: { text: string; value: Types.KontoKlasse };
          status: { description?: string | null; text: string; value: Types.KontoStatus };
          type: { text: string; value: Types.KontoType };
          verwendung?: { text: string; value: Types.KontoVerwendung } | null;
        };
        saldoGesamt: { bis: string; saldo: number; von: string };
        saldoMonat: { bis: string; saldo: number; von: string };
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type SaldenlisteSummaryQueryVariables = Types.Exact<{
  buchungskreisId: Types.Scalars['String']['input'];
  buchungsmonat: Types.Scalars['String']['input'];
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  type?: Types.InputMaybe<Types.SaldenlisteKontoType>;
}>;

export type SaldenlisteSummaryQuery = {
  getSaldenlisteSummary: {
    data: { saldoEB: number; saldoForMonatList: Array<{ saldo: number; monat: string }> };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type SaldenlisteKontoTypeListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type SaldenlisteKontoTypeListQuery = {
  getSaldenlisteKontoTypeList: {
    data: Array<{ text: string; value: Types.SaldenlisteKontoType }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const SaldenlisteDocument = gql`
  query Saldenliste(
    $buchungskreisId: String!
    $buchungsmonat: String!
    $objektId: String
    $type: SaldenlisteKontoType
    $order: SaldenlisteOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getSaldenliste(
      buchungskreisId: $buchungskreisId
      buchungsmonat: $buchungsmonat
      objektId: $objektId
      type: $type
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          konto {
            ...KontoFields
          }
          saldoEB
          saldoEnd
          saldoGesamt {
            bis
            saldo
            von
          }
          saldoMonat {
            bis
            saldo
            von
          }
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${KontoFieldsFragmentDoc}
`;
export function useSaldenlisteQuery(
  baseOptions: Apollo.QueryHookOptions<SaldenlisteQuery, SaldenlisteQueryVariables> &
    ({ variables: SaldenlisteQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SaldenlisteQuery, SaldenlisteQueryVariables>(SaldenlisteDocument, options);
}
export function useSaldenlisteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SaldenlisteQuery, SaldenlisteQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SaldenlisteQuery, SaldenlisteQueryVariables>(SaldenlisteDocument, options);
}
export function useSaldenlisteSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SaldenlisteQuery, SaldenlisteQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SaldenlisteQuery, SaldenlisteQueryVariables>(SaldenlisteDocument, options);
}
export type SaldenlisteQueryHookResult = ReturnType<typeof useSaldenlisteQuery>;
export type SaldenlisteLazyQueryHookResult = ReturnType<typeof useSaldenlisteLazyQuery>;
export type SaldenlisteSuspenseQueryHookResult = ReturnType<typeof useSaldenlisteSuspenseQuery>;
export type SaldenlisteQueryResult = Apollo.QueryResult<SaldenlisteQuery, SaldenlisteQueryVariables>;
export const SaldenlisteSummaryDocument = gql`
  query SaldenlisteSummary($buchungskreisId: String!, $buchungsmonat: String!, $objektId: String, $type: SaldenlisteKontoType) {
    getSaldenlisteSummary(buchungskreisId: $buchungskreisId, buchungsmonat: $buchungsmonat, objektId: $objektId, type: $type) {
      data {
        saldoEB
        saldoForMonatList {
          saldo
          monat
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useSaldenlisteSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<SaldenlisteSummaryQuery, SaldenlisteSummaryQueryVariables> &
    ({ variables: SaldenlisteSummaryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SaldenlisteSummaryQuery, SaldenlisteSummaryQueryVariables>(SaldenlisteSummaryDocument, options);
}
export function useSaldenlisteSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SaldenlisteSummaryQuery, SaldenlisteSummaryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SaldenlisteSummaryQuery, SaldenlisteSummaryQueryVariables>(SaldenlisteSummaryDocument, options);
}
export function useSaldenlisteSummarySuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SaldenlisteSummaryQuery, SaldenlisteSummaryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SaldenlisteSummaryQuery, SaldenlisteSummaryQueryVariables>(SaldenlisteSummaryDocument, options);
}
export type SaldenlisteSummaryQueryHookResult = ReturnType<typeof useSaldenlisteSummaryQuery>;
export type SaldenlisteSummaryLazyQueryHookResult = ReturnType<typeof useSaldenlisteSummaryLazyQuery>;
export type SaldenlisteSummarySuspenseQueryHookResult = ReturnType<typeof useSaldenlisteSummarySuspenseQuery>;
export type SaldenlisteSummaryQueryResult = Apollo.QueryResult<SaldenlisteSummaryQuery, SaldenlisteSummaryQueryVariables>;
export const SaldenlisteKontoTypeListDocument = gql`
  query SaldenlisteKontoTypeList {
    getSaldenlisteKontoTypeList {
      data {
        text
        value
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useSaldenlisteKontoTypeListQuery(
  baseOptions?: Apollo.QueryHookOptions<SaldenlisteKontoTypeListQuery, SaldenlisteKontoTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SaldenlisteKontoTypeListQuery, SaldenlisteKontoTypeListQueryVariables>(SaldenlisteKontoTypeListDocument, options);
}
export function useSaldenlisteKontoTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SaldenlisteKontoTypeListQuery, SaldenlisteKontoTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SaldenlisteKontoTypeListQuery, SaldenlisteKontoTypeListQueryVariables>(SaldenlisteKontoTypeListDocument, options);
}
export function useSaldenlisteKontoTypeListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SaldenlisteKontoTypeListQuery, SaldenlisteKontoTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SaldenlisteKontoTypeListQuery, SaldenlisteKontoTypeListQueryVariables>(SaldenlisteKontoTypeListDocument, options);
}
export type SaldenlisteKontoTypeListQueryHookResult = ReturnType<typeof useSaldenlisteKontoTypeListQuery>;
export type SaldenlisteKontoTypeListLazyQueryHookResult = ReturnType<typeof useSaldenlisteKontoTypeListLazyQuery>;
export type SaldenlisteKontoTypeListSuspenseQueryHookResult = ReturnType<typeof useSaldenlisteKontoTypeListSuspenseQuery>;
export type SaldenlisteKontoTypeListQueryResult = Apollo.QueryResult<SaldenlisteKontoTypeListQuery, SaldenlisteKontoTypeListQueryVariables>;
