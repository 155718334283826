import * as Yup from 'yup';
import { entityIsRequired } from '../../../../components/message/validationMsg';
import { heVertragFormFields } from './heVertragFormMapper';

export const heVertragFormValidationSchema = Yup.object().shape({
  [heVertragFormFields.vertragsBeginn]: Yup.date().required(entityIsRequired('Vertragsbeginn')),
  [heVertragFormFields.vertragspartnerId]: Yup.string().required(entityIsRequired('Vertragspartner')),
  [heVertragFormFields.anteile]: Yup.number().required(entityIsRequired('Anteile', true)),
  [heVertragFormFields.saldouebertrag]: Yup.number().required(entityIsRequired('Saldoübertrag')),
});
