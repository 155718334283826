import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { AppGenerierlaufType } from '../../../../../types';
import { useFilterGenLaufAppValuePreservationTypeListQuery } from '../../../gql/GenerierlaufFilter/AppGenerierlaufFilterQueries.types';
import { selectFilterOption } from '../../../../../helpers/selectHelper';

type Props = {
  name: string;
  generierlaufId: string;
  generierlaufType: AppGenerierlaufType;
} & SelectProps;

const GenerierlaufValuePreservationTypeFormSelect: FC<Props> = ({ name, generierlaufId, generierlaufType, ...restProps }) => {
  const { data, loading } = useFilterGenLaufAppValuePreservationTypeListQuery({
    variables: {
      generierlaufId,
      generierlaufType,
    },
  });

  const list = data?.getFilterGenLaufAppValuePreservationTypeList.data ?? [];

  return (
    <Select
      size="small"
      loading={loading}
      id={name}
      name={name}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      placeholder="Wertsicherungsart auswählen"
      {...restProps}
    >
      {list.map((type) => (
        <Select.Option key={type.value} value={type.value}>
          {type.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default GenerierlaufValuePreservationTypeFormSelect;
