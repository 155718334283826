import React, { FC, ReactElement } from 'react';
import { Col } from 'antd';
import { PDFViewer } from '@react-pdf/renderer';

type PDFPageColProps = {
  pdfTemplate: ReactElement;
};

const PDFPageCol: FC<PDFPageColProps> = ({ pdfTemplate }) => (
  <Col>
    <PDFViewer width="794px" height="1123px">
      {pdfTemplate}
    </PDFViewer>
  </Col>
);

export default PDFPageCol;
