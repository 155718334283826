import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { ObjektInfoFeldType } from '../../../../../../types';
import { auflageCreateFormFields, auflageCreateFormInitialValues, AuflageCreateFormValues } from './auflageCreateFormMapper';
import FormButtons from '../../../../../../components/Button/FormButtons';
import { useFDAuthorized } from '../../../../../../shared/Auth/useAuthorized';
import AuflageDatenTab from '../Tabs/AuflageDatenTab';
import { handleCreateSubmit } from '../../shared/handleSubmit';
import AuflageFormErrors from '../shared/AuflageFormErrors';
import { validateAuflageCreateForm } from './auflageCreateFormValidation';
import InfoFeldUpload from '../../../../../shared/InfoFeld/InfoFeldUpload';

type Props = {
  objektId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

const AuflageCreateForm: FC<Props> = ({ objektId, onCancel, onSuccess }) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  return (
    <Formik<AuflageCreateFormValues>
      initialValues={auflageCreateFormInitialValues}
      validate={validateAuflageCreateForm}
      onSubmit={(values, { setSubmitting }) => {
        handleCreateSubmit(firmendatenId, objektId, ObjektInfoFeldType.Auflage, values, setSubmitting, onSuccess);
      }}
    >
      {(formikProps) => (
        <Form>
          <AuflageDatenTab formFields={auflageCreateFormFields} />
          <InfoFeldUpload name={auflageCreateFormFields.documentList} formikProps={formikProps} documentList={formikProps.values.documentList} />
          <AuflageFormErrors formikProps={formikProps} />
          <FormButtons updateMode={false} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default AuflageCreateForm;
