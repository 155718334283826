import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { Spacer } from '../../../../../components/Grid';
import AbrechnungsErgebnis from './AbrechnungsErgebnis';
import TableWithAlignedCols from '../../../../../components/Table/TableWithAlignedCols/TableWithAlignedCols';
import topAbrechnungWithoutRepFondsDetailsTableColumns from './topAbrechnungWithoutRepFondsDetailsTableColumns';
import topAbrechnungWithoutRepFondsDetailsTableExpandedRow from './topAbrechnungWithoutRepFondsDetailsTableExpandedRow';
import topAbrechnungWithRepFondsDetailsTableColumns from './topAbrechnungWithRepFondsDetailsTableColumns';
import topAbrechnungWithRepFondsDetailsTableExpandedRow from './topAbrechnungWithRepFondsDetailsTableExpandedRow';
import InfoAbrKreisTable from '../../../shared/InfoAbrKreisTable/Level1/InfoAbrKreisTable';
import AbrechnungsRueckstand from './AbrechnungsRueckstand';
import { TopAbrAbrechnungkreis, TopAbrechnung } from '../../../../../types';

type Props = {
  objektId: string;
  objektAbrechnungId: string;
  topAbrechnung: TopAbrechnung;
};

const TopAbrechnungDetailsTable: FC<Props> = ({ topAbrechnung, objektId, objektAbrechnungId }) => {
  const { abrechnungskreisList } = topAbrechnung;
  const abrKreisGesamtErgebnis = abrechnungskreisList.reduce((acc, abrKreis) => {
    return abrKreis.brutto + acc;
  }, 0);

  const abrKreisWithoutRepFonds = abrechnungskreisList.filter((abrKreis) => abrKreis.ustVomAufwandAnteilList.length === 0);
  const abrKreisWithRepFonds = abrechnungskreisList.filter((abrKreis) => abrKreis.ustVomAufwandAnteilList.length > 0);

  const iconCellStyling = { width: '32px' };

  return (
    <>
      <Space direction="vertical" align="end" style={{ width: '100%' }}>
        <Typography.Text style={{ fontWeight: 500 }}>Gesamtergebnis:</Typography.Text>
        <AbrechnungsErgebnis brutto={abrKreisGesamtErgebnis} />
      </Space>
      <Spacer height={16} />
      <AbrechnungsRueckstand
        rueckstand={topAbrechnung.rueckstandBestandnehmer}
        objektId={objektId}
        objektAbrechnungId={objektAbrechnungId}
        abrechnungType={topAbrechnung.type.value}
      />
      <Spacer height={16} />
      <TableWithAlignedCols<TopAbrAbrechnungkreis>
        dataSource={abrKreisWithoutRepFonds}
        columns={topAbrechnungWithoutRepFondsDetailsTableColumns(objektId, objektAbrechnungId)}
        expandedRow={() => topAbrechnungWithoutRepFondsDetailsTableExpandedRow(objektId)}
        iconCellStyle={iconCellStyling}
      />
      {abrKreisWithRepFonds.length > 0 && (
        <>
          <Spacer height={32} />
          <TableWithAlignedCols<TopAbrAbrechnungkreis>
            dataSource={abrKreisWithRepFonds}
            columns={topAbrechnungWithRepFondsDetailsTableColumns(objektId, objektAbrechnungId)}
            expandedRow={() => topAbrechnungWithRepFondsDetailsTableExpandedRow(objektId)}
            iconCellStyle={iconCellStyling}
          />
        </>
      )}
      {topAbrechnung.infoAbrechnungskreisList.length > 0 && (
        <>
          <Spacer height={16} />
          <InfoAbrKreisTable abrechnungskreisList={topAbrechnung.infoAbrechnungskreisList} />
        </>
      )}
    </>
  );
};

export default TopAbrechnungDetailsTable;
