import * as Yup from 'yup';

import { kurzBezeichnungFormPartValidationSchema } from '../../../../shared/components/KurzBezeichnung/form/kurzBezeichnungFormPartValidationSchema';
import { objektVerwaltungsArtSelectFormPartValidationSchema } from '../../VerwaltungsArt/objektVerwaltungsArtSelectValidationSchema';
import { entityIsRequired, entityMaxCharLength } from '../../../../components/message/validationMsg';
import { objektFormFields } from './objektFormMapper';
import { addressListFormPartValidationSchema } from '../../AddressListFormPart/addressListFormPartValidationSchema';

/* eslint-disable no-template-curly-in-string */
export const objektFormValidationSchema = Yup.object()
  .shape({
    [objektFormFields.verwaltungSeit]: Yup.date().required(entityIsRequired('Verwaltet seit')),
    [objektFormFields.rechnungsAusstellerId]: Yup.string()
      .required(entityIsRequired('Rechnungsaussteller'))
      .max(36, entityMaxCharLength('Rechnungsaussteller Id', 36)),
  })
  // @ts-ignore
  .concat(kurzBezeichnungFormPartValidationSchema)
  // @ts-ignore
  .concat(addressListFormPartValidationSchema)
  // @ts-ignore
  .concat(objektVerwaltungsArtSelectFormPartValidationSchema);

/* eslint-enable no-template-curly-in-string */
