import React, { FC } from 'react';
import { Button, MenuProps, Modal } from 'antd';
import { HistoryOutlined, PlusOutlined } from '@ant-design/icons';
import { radixActionStyles } from '../../../../../helpers/radixIconsStyles';
import RechnungsAusstellerAbrDefZuweisenForm from './ZuweisenForm/RechnungsAusstellerAbrDefZuweisenForm';
import ListingTitleWithButtons from '../../../../../components/Listing/ListingTitleWithButtons';
import { useToggle } from '../../../../../hooks/useToggle';
import HistoryModal, { HistoryType } from '../../../../History/shared/HistoryModal';
import AbrDefChangeHistoryListingTable from './AbrDefChangeHistoryListingTable';

type RechnungsAusstellerAbrDefListingBtnAndModalProps = {
  onSuccess: () => void;
  rechtstraegerId: string;
};

const RechnungsAusstellerAbrDefListingBtnAndModal: FC<RechnungsAusstellerAbrDefListingBtnAndModalProps> = ({ onSuccess, rechtstraegerId }) => {
  const [isCollapsed, onCollapse] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
  ];

  return (
    <>
      <ListingTitleWithButtons
        title="Abrechnungsdefinitionen"
        items={menuItems}
        buttons={
          <Button type="primary" size="small" onClick={onCollapse}>
            <PlusOutlined />
            Abrechnungsdefinition zuweisen
          </Button>
        }
      />
      <Modal title="Abrechnungsdefinition zuweisen" footer={null} open={isCollapsed} onCancel={onCollapse} destroyOnClose maskClosable={false}>
        <RechnungsAusstellerAbrDefZuweisenForm
          onSuccess={() => {
            onSuccess();
            onCollapse();
          }}
          onCancel={onCollapse}
          rechtstraegerId={rechtstraegerId}
        />
      </Modal>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <AbrDefChangeHistoryListingTable rechtstraegerId={rechtstraegerId} />
      </HistoryModal>
    </>
  );
};

export default RechnungsAusstellerAbrDefListingBtnAndModal;
