import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { showSuccessMsgUpdate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import FormButtons from '../../../../components/Button/FormButtons';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import { AbrechnungsdefinitionListEntry } from '../../../../types';
import {
  abrechnungsdefinitionUpdateFormFields,
  AbrechnungsdefinitionUpdateFormValues,
  mapAbrechnunsdefinitionToFormValues,
  mapFormValuesToAbrechnungsdefinitonUpdate,
} from '../../../Abrechnungsdefinition/Form/Update/abrDefUpdateFormMapper';
import { abrDefUpdateFormValidationSchema } from '../../../Abrechnungsdefinition/Form/Update/abrDefUpdateFormValidationSchema';
import {
  isAbrDefTypeHeAbrechnung,
  isAbrDefTypeSubAbrechnung,
  isAbrDefTypeWeAbrechnung,
} from '../../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import { useUpdateWeAbrechnungsdefinitionTemplateMutation } from '../../gql/WeAbrDef/WeAbrDefTemplateMutations.types';
import { useUpdateBkAbrechnungsdefinitionTemplateMutation } from '../../gql/BkAbrDef/BkAbrDefTemplateMutations.types';
import { useUpdateHeAbrechnungsdefinitionTemplateMutation } from '../../gql/HeAbrDef/HeAbrDefTemplateMutations.types';
import { useUpdateSubAdministrationAbrechnungsdefinitionTemplateMutation } from '../../gql/SubAdministrationAbrDef/SubAdministrationAbrDefTemplateMutations.types';

type Props = {
  abrechnungsdefinition: AbrechnungsdefinitionListEntry;
  onSuccess: () => void;
  onCancel: () => void;
};

const AbrDefTemplateUpdateForm: FC<Props> = ({ abrechnungsdefinition, onSuccess, onCancel }) => {
  const entity = 'Abrechnungsdefinition';
  const baseOptions = {
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  };

  const { abrechnungsdefinitionId } = abrechnungsdefinition;

  const [updateWeAbrechnungsdefinition, { loading: loadingWeAbrechnungsdefinition }] = useUpdateWeAbrechnungsdefinitionTemplateMutation(baseOptions);
  const [updateHeAbrechnungsdefinition, { loading: loadingHeAbrechnungsdefinition }] = useUpdateHeAbrechnungsdefinitionTemplateMutation(baseOptions);
  const [updateBkAbrechnungsdefinition, { loading: loadingBkAbrechnungsdefinition }] = useUpdateBkAbrechnungsdefinitionTemplateMutation(baseOptions);
  const [updateSubAbrechnungsdefinition, { loading: loadingSubAbrechnungsdefinition }] =
    useUpdateSubAdministrationAbrechnungsdefinitionTemplateMutation(baseOptions);

  const onSubmit = (formValues: AbrechnungsdefinitionUpdateFormValues) => {
    const input = mapFormValuesToAbrechnungsdefinitonUpdate(formValues);
    if (isAbrDefTypeWeAbrechnung(abrechnungsdefinition.type.value)) {
      return updateWeAbrechnungsdefinition({ variables: { abrechnungsdefinitionId, input } });
    } else if (isAbrDefTypeHeAbrechnung(abrechnungsdefinition.type.value)) {
      return updateHeAbrechnungsdefinition({ variables: { abrechnungsdefinitionId, input } });
    } else if (isAbrDefTypeSubAbrechnung(abrechnungsdefinition.type.value)) {
      return updateSubAbrechnungsdefinition({ variables: { abrechnungsdefinitionId, input } });
    } else {
      return updateBkAbrechnungsdefinition({ variables: { abrechnungsdefinitionId, input } });
    }
  };

  return (
    <Formik<AbrechnungsdefinitionUpdateFormValues>
      initialValues={mapAbrechnunsdefinitionToFormValues(abrechnungsdefinition)}
      validationSchema={abrDefUpdateFormValidationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithoutColon name={abrechnungsdefinitionUpdateFormFields.bezeichnung} label="Bezeichnung">
            <Input id={abrechnungsdefinitionUpdateFormFields.bezeichnung} name={abrechnungsdefinitionUpdateFormFields.bezeichnung} />
          </FormItemWithoutColon>

          <FormButtons
            updateMode
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={
              loadingWeAbrechnungsdefinition || loadingBkAbrechnungsdefinition || loadingHeAbrechnungsdefinition || loadingSubAbrechnungsdefinition
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export default AbrDefTemplateUpdateForm;
