import { FC } from 'react';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';
import { useMahnstufeTemplateChangeHistoryListQuery } from '../gql/MahndefinitionTemplateQueries.types';

type Props = {
  mahndefinitionId: string;
  mahnstufeIndex: number;
};

const MahnstufeTemplateChangeHistoryListingTable: FC<Props> = ({ mahndefinitionId, mahnstufeIndex }) => {
  const { data, loading } = useMahnstufeTemplateChangeHistoryListQuery({ variables: { mahndefinitionId, mahnstufeIndex } });
  const historyList = data?.getMahnstufeTemplateChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="mahnstufe-template" />;
};

export default MahnstufeTemplateChangeHistoryListingTable;
