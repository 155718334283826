import React, { FC } from 'react';
import { Divider, Typography } from 'antd';
import { useCreateObjektTicketMutation } from '../../Ticket/gql/TicketMutations.types';
import TicketActionButtonsAndDrawer from '../../Ticket/TicketActionButtonsAndDrawer';
import { mapFormValuesToTicketCreateInput, TicketFormValues } from '../../Ticket/Form/ticketFormMapper';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import TicketListing from '../../Ticket/Listing/TicketListing';
import TicketListingFilters from '../../Ticket/Listing/TicketListingFilters';
import ticketTableColumns from '../../Ticket/Listing/Table/ticketTableColumns';
import ticketTableObjektColumns from '../../Ticket/Listing/Table/ticketTableObjektColumns';
import ticketTableInfoFeldColumn from '../../Ticket/Listing/Table/ticketTableInfoFeldColumn';
import { generatePathToObjektDetailsPage } from '../objektUriPaths';
import { gqlOperations } from '../../../graphql/gqlNamedOperations-generated';
import TicketListingCalendar from '../../Ticket/Listing/TicketListingCalendar';

type Props = {
  objektId: string;
};

const ObjektTicketListing: FC<Props> = ({ objektId }) => {
  const [runCreate, { loading: loadingCreate }] = useCreateObjektTicketMutation({
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onSubmitCreate = async (values: TicketFormValues) => {
    const response = await runCreate({
      variables: { objektId, input: mapFormValuesToTicketCreateInput(values) },
      refetchQueries: () => [gqlOperations.Query.TicketList],
    });
    return response.data?.createObjektTicket.data;
  };

  return (
    <TicketListing
      tableFilterIdentifier="objekt-ticket"
      objektId={objektId}
      pageUri={`${generatePathToObjektDetailsPage(objektId)}/aufgaben`}
      tableColumns={(onAction) =>
        ticketTableColumns(onAction, [
          ...ticketTableObjektColumns({ isBestandseinheitSelected: true, isVertagSelected: true }),
          ticketTableInfoFeldColumn(true),
        ])
      }
      bottom={(formikProps) => (
        <>
          <Divider />
          <Typography.Title level={4} style={{ marginTop: 16 }}>
            Kalender
          </Typography.Title>
          <TicketListingCalendar formikProps={formikProps} objektId={objektId} />
        </>
      )}
    >
      {({ formikProps, refetch, queryParams }) => (
        <TicketListingFilters
          formikProps={formikProps}
          objektId={objektId}
          extraFilters={{ bestandseinheitFilter: true, vertragFilter: true }}
          actionButtons={
            <TicketActionButtonsAndDrawer
              refetch={refetch}
              allowCreate
              loadingCreate={loadingCreate}
              onFormSubmitCreate={onSubmitCreate}
              formikProps={formikProps}
            />
          }
          queryParams={queryParams}
        />
      )}
    </TicketListing>
  );
};

export default ObjektTicketListing;
