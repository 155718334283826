import React, { FC } from 'react';
import { Button, Modal } from 'antd';
import ListingTitleWithButtons from '../../../../../components/Listing/ListingTitleWithButtons';
import AufteilungsschluesselTransfer from './Transfer/AufteilungsschluesselTransfer';

type ObjektAufteilungsschluesselListingBtnAndModalProps = {
  objektId: string;
  isCollapsed: boolean;
  onCollapse: () => void;
  showArchived: boolean;
  toggleShowArchived: () => void;
  showZuweisenBtn: boolean;
  refetch: () => void;
  isWegObjekt: boolean;
};

const ObjektAufteilungsschluesselListingBtnAndModal: FC<ObjektAufteilungsschluesselListingBtnAndModalProps> = ({
  objektId,
  isCollapsed,
  onCollapse,
  showArchived,
  toggleShowArchived,
  showZuweisenBtn,
  refetch,
  isWegObjekt,
}) => {
  return (
    <>
      <ListingTitleWithButtons
        title="Aufteilungsschlüssel"
        buttons={
          <>
            <Button size="small" onClick={toggleShowArchived}>
              Archivierte Einträge {showArchived ? 'ausblenden' : 'anzeigen'}
            </Button>
            {showZuweisenBtn ? (
              <Button type="primary" size="small" onClick={onCollapse}>
                Aufteilungsschlüssel zuweisen
              </Button>
            ) : null}
          </>
        }
      />
      <Modal
        title="Aufteilungsschlüssel zuweisen"
        open={isCollapsed}
        onCancel={onCollapse}
        footer={null}
        destroyOnClose
        maskClosable={false}
        width={720}
      >
        <AufteilungsschluesselTransfer
          objektId={objektId}
          onOk={() => {
            refetch();
            onCollapse();
          }}
          onCancel={onCollapse}
          isWegObjekt={isWegObjekt}
        />
      </Modal>
    </>
  );
};

export default ObjektAufteilungsschluesselListingBtnAndModal;
