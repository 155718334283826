import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Col, Divider, Row, Typography } from 'antd';
import FormButtons from '../../../components/Button/FormButtons';
import { showSuccessMsgCreate } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import KurzBezeichnungFormPart from '../../../shared/components/KurzBezeichnung/form/KurzBezeichnungFormPart';
import { Aufteilungsschluessel } from '../../../types';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';
import {
  useCreateAufteilungsschluesselTemplateMutation,
  useUpdateAufteilungsschluesselTemplateMutation,
} from '../gql/AufteilungsschluesselTemplateMutations.types';
import {
  aufteilungsschluesselFormFields,
  AufteilungsschluesselFormValues,
  mapAufteilungsschluesselToFormValues,
  mapFormValuesToAufteilungsschluessel,
} from '../../Aufteilungsschluessel/Form/aufteilungsschluesselFormMapper';
import { aufteilungsschluesselFormValidationSchema } from '../../Aufteilungsschluessel/Form/aufteilungsschluesselFormValidationSchema';
import MasseinheitSelect from '../../Aufteilungsschluessel/Masseinheit/MasseinheitSelect';
import BezugsbasisSelect from '../../Aufteilungsschluessel/Bezugsbasis/BezugsbasisSelect';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';

type Props = {
  aufteilungsschluessel?: Aufteilungsschluessel;
  onSuccess: () => void;
  onCancel: () => void;
};

/**
 * Note: There is no separate form mapper or validation schema; both are reused from aufteilungsschluessel.
 */
const AufteilungsschluesselTemplateForm: FC<Props> = ({ aufteilungsschluessel, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'Aufteilungsschluessel'>('Aufteilungsschluessel');

  const isUpdate = !!aufteilungsschluessel;
  const entity = `Aufteilungsschlüssel`;

  const [createAufteilungsschluesselTemplate, { loading: loadingCreate }] = useCreateAufteilungsschluesselTemplateMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [updateAufteilungsschluesselTemplate, { loading: loadingUpdate }] = useUpdateAufteilungsschluesselTemplateMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<AufteilungsschluesselFormValues>
      initialValues={mapAufteilungsschluesselToFormValues(aufteilungsschluessel)}
      validationSchema={aufteilungsschluesselFormValidationSchema}
      onSubmit={(formValues, formikHelpers) => {
        const input = mapFormValuesToAufteilungsschluessel(formValues);
        if (!isUpdate) {
          createAufteilungsschluesselTemplate({
            variables: { input },
          }).finally(() => formikHelpers.setSubmitting(false));
        } else {
          updateAufteilungsschluesselTemplate({
            variables: { input, aufteilungsschluesselId: aufteilungsschluessel.aufteilungsschluesselId },
          }).finally(() => formikHelpers.setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Kurzinformationen</Typography.Title>
            </Col>
            <Col span={18}>
              <Row gutter={8}>
                <Col span={12}>
                  <KurzBezeichnungFormPart<AufteilungsschluesselFormValues>
                    placeholder="z.B. NFL_42"
                    formikProps={formikProps}
                    fieldHelp={getFieldHelpText('Aufteilungsschluessel.kurzBezeichnung')}
                  />
                </Col>
                <Col span={12}>
                  <FormItemWithFieldHelp
                    name={aufteilungsschluesselFormFields.bezeichnung}
                    label="Bezeichnung"
                    fieldHelp={getFieldHelpText('Aufteilungsschluessel.bezeichnung')}
                  >
                    <Input
                      id={aufteilungsschluesselFormFields.bezeichnung}
                      name={aufteilungsschluesselFormFields.bezeichnung}
                      placeholder="z.B. Aufteilungsschlüssel 42"
                    />
                  </FormItemWithFieldHelp>
                </Col>
              </Row>
            </Col>
          </Row>

          <Divider />

          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Basis</Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithFieldHelp
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name={aufteilungsschluesselFormFields.masseinheit}
                label="Maßeinheit"
                fieldHelp={getFieldHelpText('Aufteilungsschluessel.masseinheit')}
              >
                <MasseinheitSelect />
              </FormItemWithFieldHelp>
              <FormItemWithFieldHelp
                name={aufteilungsschluesselFormFields.bezugsbasis}
                label="Bezugsbasis"
                fieldHelp={getFieldHelpText('Aufteilungsschluessel.bezugsbasis')}
              >
                <BezugsbasisSelect
                  name={aufteilungsschluesselFormFields.bezugsbasis}
                  disabled={!!aufteilungsschluessel && !aufteilungsschluessel.bezugsbasisEditable}
                />
              </FormItemWithFieldHelp>
            </Col>
          </Row>

          <FormButtons
            updateMode={isUpdate}
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AufteilungsschluesselTemplateForm;
