import React, { FC } from 'react';
import { Button, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../../hooks/useToggle';
import IndexedValueCreateForm from './IndexedValueCreateForm';

type Props = {
  onSuccess: () => void;
};

const IndexedValueBtnAndModal: FC<Props> = ({ onSuccess }) => {
  const [isOpen, toggleIsOpen] = useToggle();

  const onActionSuccess = () => {
    onSuccess();
    toggleIsOpen();
  };

  return (
    <>
      <Button icon={<PlusOutlined />} type="primary" onClick={toggleIsOpen}>
        Indexierte Werte erstellen
      </Button>
      <Modal title="Indexierte Werte erstellen" width={520} footer={null} open={isOpen} onCancel={toggleIsOpen} destroyOnClose>
        <IndexedValueCreateForm onCancel={toggleIsOpen} onOk={onActionSuccess} />
      </Modal>
    </>
  );
};

export default IndexedValueBtnAndModal;
