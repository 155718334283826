import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { mapFormValuesToQueryVariables, TicketListingFiltersFormValues } from './ticketListingFormMapper';
import { TicketListingChildrenProps, TicketListingFilterProps } from './TicketListing';
import TicketTable from './Table/TicketTable';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../hooks/useQueryWithPagingAndSorting';
import { useTicketListQuery } from '../gql/TicketQueries.types';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { TicketBase } from '../../../types';
import { TTicketQueryParams } from './Filters/filtersQueryParams';
import { useOnTicketListDataChangedEvents } from '../useOnTicketDataChangedEvents';
import { extendStatusListWithArchived } from '../ticketHelpers';

type Props = {
  formikProps: FormikProps<TicketListingFiltersFormValues>;
  tableFilterIdentifier: string;
  tableColumns?: (onAction: () => any) => TableWithColSelectorColumnProps<TicketBase>[];
  onSuccess?: () => void;
  children?: (props: TicketListingChildrenProps) => React.ReactNode;
  queryParams: TTicketQueryParams;
} & TicketListingFilterProps;

const TicketListingForm: FC<Props> = ({ formikProps, tableFilterIdentifier, tableColumns, onSuccess, children, queryParams, ...restProps }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { showArchived, status, ...restQueryVariables } = mapFormValuesToQueryVariables(formikProps.values);
  const {
    data,
    loading,
    refetch: refetchTicketList,
    pagination,
    handleTableSorting,
  } = useQueryWithPagingAndSorting(
    useTicketListQuery,
    { variables: { ...restQueryVariables, status: extendStatusListWithArchived(status, showArchived), ...restProps } },
    {
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );
  const ticketList = data?.getTicketList.data.contentList;

  useOnTicketListDataChangedEvents('ticketList', refetchTicketList, collectTicketIds(ticketList), restProps);

  const refetch = () => {
    refetchTicketList();
    onSuccess?.();
  };

  return (
    <>
      {children?.({ loading, refetch, formikProps, queryParams })}
      <TicketTable
        ticketList={ticketList}
        loading={loading}
        pagination={pagination}
        handleTableSorting={handleTableSorting}
        refetch={refetch}
        tableFilterIdentifier={tableFilterIdentifier}
        tableColumns={tableColumns}
      />
    </>
  );
};

const collectTicketIds = (ticketList: TicketBase[] | undefined) => {
  return ticketList?.map((ticket) => ticket.ticketId);
};

export default TicketListingForm;
