import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import StatusBadge from '../../../../../components/Status/StatusBadge';
import { isStatusInvalid, isStatusReviewError, isStatusValid } from '../../../../../helpers/statusHelper';
import CardDetailsInfoRow from '../../../../../components/Card/CardDetailsInfoRow';
import { HeAbrechnungHeVertragListEntry } from '../../../../../types';

type Props = {
  heAbrechnungHeVertragList: HeAbrechnungHeVertragListEntry[];
};

const HeVertragStatus: FC<Props> = ({ heAbrechnungHeVertragList }) => {
  const isAnyHeAbrHeVertragStatusValid = heAbrechnungHeVertragList.some((heAbrechnungHeVertrag) => isStatusValid(heAbrechnungHeVertrag.status));
  const isAnyHeAbrHeVertragStatusUnvalid = heAbrechnungHeVertragList.some((heAbrechnungHeVertrag) => isStatusInvalid(heAbrechnungHeVertrag.status));
  const isAnyHeAbrHeVertragStatusReviewError = heAbrechnungHeVertragList.some((heAbrechnungHeVertrag) =>
    isStatusReviewError(heAbrechnungHeVertrag.status)
  );

  return (
    <CardDetailsInfoRow infoRowTitle="Hauseigentümerabrechnungen">
      {isAnyHeAbrHeVertragStatusValid && isAnyHeAbrHeVertragStatusUnvalid ? (
        <ValidAndInvalidStatusBagdes heAbrechnungHeVertragList={heAbrechnungHeVertragList} />
      ) : (
        <div>
          <StatusBadge entityStatus={heAbrechnungHeVertragList[0].status} />
          {!isAnyHeAbrHeVertragStatusReviewError && <Typography.Text>&nbsp;({heAbrechnungHeVertragList.length})</Typography.Text>}
        </div>
      )}
    </CardDetailsInfoRow>
  );
};

const ValidAndInvalidStatusBagdes: FC<Props> = ({ heAbrechnungHeVertragList }) => {
  const validHeAbrHeVertrag = heAbrechnungHeVertragList.filter((HeAbrHeVertrag) => isStatusValid(HeAbrHeVertrag.status));
  const invalidHeAbrHeVertrag = heAbrechnungHeVertragList.filter((HeAbrHeVertrag) => isStatusInvalid(HeAbrHeVertrag.status));

  return (
    <Space direction="vertical" size={4}>
      <div>
        <StatusBadge entityStatus={validHeAbrHeVertrag[0].status} />
        <Typography.Text>&nbsp;({validHeAbrHeVertrag.length})</Typography.Text>
      </div>
      <div>
        <StatusBadge entityStatus={invalidHeAbrHeVertrag[0].status} />
        <Typography.Text>&nbsp;({invalidHeAbrHeVertrag.length})</Typography.Text>
      </div>
    </Space>
  );
};

export default HeVertragStatus;
