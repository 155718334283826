import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { DeviationDataFragment } from '../gql/deviationFragments.types';
import { EntityWithDeviationStatus } from '../deviation.model';
import { isStatusPruefungAusgesetzt, isStatusReviewError, isStatusUnderReview } from '../../../helpers/statusHelper';
import { mapDeviationListResponseToGroupedList } from '../deviationMapper';
import { isDeviationUrsacheNichtErmittelbar } from '../deviationHelpers';
import DeviationListGroupedByUrsache from '../List/GroupedByUrsache/DeviationListGroupedByUrsache';
import DeviationActionButtons from './DeviationActionButtons';
import NoErrorsTabContent from '../../Error/NoErrorsTabContent';

type DeviationTabContentProps = {
  dataSource: DeviationDataFragment[];
  status: EntityWithDeviationStatus;
  loading: boolean;
  runApplyAllDeviations: () => Promise<unknown> | void;
  runPruefungAussetzen?: () => Promise<unknown> | void;
  showSourceEntity?: boolean;
  showSubsequentDeviationsButton?: boolean;
  showSubsequentDeviations?: boolean;
  onShowFolgeDeviationsButtonClick?: () => void;
  altTextForMultipleReferenceOfCause?: string;
  reviewErrorUriPath?: string;
};

const DeviationTabContent: FC<DeviationTabContentProps> = ({
  dataSource,
  status,
  loading,
  runApplyAllDeviations,
  runPruefungAussetzen,
  showSourceEntity,
  showSubsequentDeviationsButton,
  showSubsequentDeviations,
  onShowFolgeDeviationsButtonClick,
  altTextForMultipleReferenceOfCause,
  reviewErrorUriPath,
}) => {
  if (isStatusReviewError(status)) {
    return (
      <Typography.Title level={5}>
        Bei der Überprüfung sind {reviewErrorUriPath ? <Link to={reviewErrorUriPath}>Fehler</Link> : 'Fehler'} aufgetreten.
      </Typography.Title>
    );
  }

  if (isStatusUnderReview(status) || isStatusPruefungAusgesetzt(status)) {
    return <Typography.Title level={5}>{status.description}</Typography.Title>;
  }

  if (dataSource.length > 0) {
    return (
      <>
        <DeviationActionButtons
          isApplyAllDeviationsDisabled={isSomeDeviationUrsacheNichtErmittelbar(dataSource)}
          onShowFolgeDeviationsButtonClick={onShowFolgeDeviationsButtonClick}
          runApplyAllDeviations={runApplyAllDeviations}
          runPruefungAussetzen={runPruefungAussetzen}
          showSubsequentDeviations={showSubsequentDeviations}
          showSubsequentDeviationsButton={showSubsequentDeviationsButton}
        />
        <DeviationListGroupedByUrsache
          deviationUrsacheGroupList={mapDeviationListResponseToGroupedList(dataSource)}
          loading={loading}
          showSourceEntity={showSourceEntity}
          altTextForMultipleReferenceOfCause={altTextForMultipleReferenceOfCause}
        />
      </>
    );
  }

  return <NoErrorsTabContent />;
};

const isSomeDeviationUrsacheNichtErmittelbar = (dataSource: DeviationDataFragment[]) =>
  dataSource.some((deviation) => isDeviationUrsacheNichtErmittelbar(deviation.ursache));

export default DeviationTabContent;
