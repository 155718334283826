import React from 'react';
import { useParams } from 'react-router-dom';
import FirmendatenCard from '../../../features/KundenSystem/Firmendaten/Card/FirmendatenCard';
import { useFirmendatenQuery } from '../../../features/KundenSystem/Firmendaten/gql/FirmendatenQueries.types';
import { LoadingDetailsPage } from '../../../components/Loading';
import { FirmendatenDetailsPageRouteParams } from './routes';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import menuListFirmendaten from './menuListFirmendaten';
import { Firmendaten } from '../../../types';

const VerwaltungFirmendatenDetailsPage = () => {
  const { firmendatenId } = useParams() as FirmendatenDetailsPageRouteParams;
  const { loading, data, refetch } = useFirmendatenQuery({ variables: { firmendatenId }, fetchPolicy: 'no-cache' });
  const firmendaten = data?.getFirmendaten.data;

  if (!firmendaten) return <LoadingDetailsPage loading={loading} />;

  return (
    <DetailsPageTemplate<Firmendaten>
      data={firmendaten}
      pageTitle={(firmendaten) => firmendaten.kurzBezeichnung}
      card={() => <FirmendatenCard firmendaten={firmendaten} onSuccess={refetch} />}
      sidebarMenuList={(firmendaten) => menuListFirmendaten(firmendaten)}
      contentContextList={() => ['OBJEKT_RECHNUNGS_AUSSTELLER', 'WE_RECHNUNGS_AUSSTELLER']}
    />
  );
};

export default VerwaltungFirmendatenDetailsPage;
