import React from 'react';
import { Link } from 'react-router-dom';
import { NestedTableColProps } from '../../../../../components/Table/NestedTable/NestedTable';
import { PaymentClearing, PaymentClearingOrderBy } from '../../../../../types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { EuroAmount } from '../../../../../components/Number';
import { generatePathToOffenePostenPage } from '../../../../OffenePosten/offenePostenUriPaths';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { isNotNil } from '../../../../../helpers/assertionHelper';
import KontoLinkToKontoblatt from '../../../../../shared/components/Konto/KontoLinkToKontoblatt';
import RechtstraegerWithContactsTooltip from '../../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

const paymentClearingTableColumns: NestedTableColProps<PaymentClearing>[] = [
  {
    title: 'Buchungskreis',
    dataIndex: PaymentClearingOrderBy.Buchungskreis,
    sorter: true,
    render: (text, record) => (
      <RechtstraegerWithContactsTooltip
        rechtstraegerId={record.buchungskreisRechtstraegerId}
        rechtstraegerName={record.buchungskreisRechtstraegerName}
        maxTextLength={30}
      />
    ),
  },
  {
    title: 'Debitor / Kreditor',
    dataIndex: PaymentClearingOrderBy.KontoVerwendung,
    sorter: true,
    render: (text, record) => <DataWithShortenedText maxTextLength={20} text={record.kontoVerwendung.text} />,
  },
  {
    title: 'Vertragspartner',
    dataIndex: PaymentClearingOrderBy.Vertragspartner,
    sorter: true,
    render: (text, record) => (
      <RechtstraegerWithContactsTooltip
        rechtstraegerId={record.vertragspartnerId}
        rechtstraegerName={record.vertragspartnerName}
        maxTextLength={20}
      />
    ),
  },
  {
    title: 'Vertragspartner Fibu Konto',
    dataIndex: PaymentClearingOrderBy.VertragspartnerFibuKonto,
    sorter: true,
    render: (text, record) => (
      <KontoLinkToKontoblatt
        konto={{
          kontoId: record.kontoId,
          nummer: record.kontoNummer,
          bezeichnung: record.kontoBezeichnung,
        }}
        buchungskreisId={record.buchungskreisId}
      />
    ),
  },
  {
    title: 'Offener Betrag des OPs',
    dataIndex: PaymentClearingOrderBy.OffenerBetrag,
    sorter: true,
    render: (text, record) => (
      <Link
        to={generatePathToOffenePostenPage({
          buchungskreisId: record.buchungskreisId,
          fibuKontoId: record.kontoId,
        })}
        target="_blank"
      >
        <EuroAmount value={record.outstandingAmount} />
      </Link>
    ),
  },
  {
    title: 'Verfügbarer Zahlungsbetrag',
    dataIndex: PaymentClearingOrderBy.OffenerZahlungsbetrag,
    sorter: true,
    render: (text, record) =>
      isNotNil(record.availableAmount) ? <EuroAmount value={record.availableAmount} /> : <TextForEmptyValue textToShow="minus" />,
  },
];

export default paymentClearingTableColumns;
