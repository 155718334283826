import React, { FC } from 'react';
import { Space } from 'antd';
import AddressZipCodeCity from './AddressZipCodeCity';
import AddressCityAdditionalInformation from './AddressCityAdditionalInformation';
import { shouldRenderLandText } from './renderHelper';
import CountryCodeName from './CountryCodeName';
import { StreetAddressModel } from './AddressSharedModel';

type Props = {
  streetAddress: StreetAddressModel;
  oneLine: boolean;
};

const StreetAddress: FC<Props> = ({ streetAddress, oneLine }) => {
  const zipCodeCity = <AddressZipCodeCity zipCode={streetAddress.zipCode} city={streetAddress.city} />;

  if (oneLine) {
    const landBezeichnung = shouldRenderLandText(streetAddress.countryCodeIso2) ? (
      <>
        {/* FIXME: no need for eslint-disable if https://github.com/yannickcr/eslint-plugin-react/pull/2448 is released and comes with airbnb */}
        {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
        {`, `}
        <CountryCodeName countryCodeIso2={streetAddress.countryCodeIso2} />
      </>
    ) : (
      ''
    );

    return (
      <>
        {`${streetAddress.street} ${streetAddress.houseEntranceApartmentNumber}, `}
        {zipCodeCity}
        {landBezeichnung}
      </>
    );
  }

  return (
    <Space direction="vertical" size={0}>
      {`${streetAddress.street} ${streetAddress.houseEntranceApartmentNumber}`}
      {streetAddress.cityAdditionalInformation && (
        <AddressCityAdditionalInformation cityAdditionalInformation={streetAddress.cityAdditionalInformation} />
      )}
      {zipCodeCity}
      {shouldRenderLandText(streetAddress.countryCodeIso2) && <CountryCodeName countryCodeIso2={streetAddress.countryCodeIso2} />}
    </Space>
  );
};

export default StreetAddress;
