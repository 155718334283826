import React, { FC } from 'react';
import { Button } from 'antd';
import { useToggle } from '../../../../hooks/useToggle';
import ZinslisteCreateModal from '../../Form/Create/ZinslisteCreateModal';

type Props = {
  onCreateSuccess: () => Promise<any>;
};
const ZinslisteCreateBtnAndModal: FC<Props> = ({ onCreateSuccess }) => {
  const [isCreateModalOpen, toggleCreateModal] = useToggle();

  return (
    <>
      <Button type="primary" onClick={() => toggleCreateModal()}>
        Zinslisten erstellen
      </Button>
      <ZinslisteCreateModal
        onCreate={() => {
          toggleCreateModal();
          onCreateSuccess();
        }}
        onCancel={toggleCreateModal}
        visible={isCreateModalOpen}
      />
    </>
  );
};

export default ZinslisteCreateBtnAndModal;
