import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Divider, Space, Typography } from 'antd';
import { DeviationChangeTypeTuple, HeAbrechnungHeVertragDeviationSourceEntity } from '../../../../../types';
import DeviationChangeTypeTag from '../../../DeviationChangeTypeTag';
import { DeviationColumnTitleStyledText } from '../../../Styled/Deviation.style';
import DataWithShortenedTextAndExtraInfo from '../../../../Helpers/DataWithShortenedTextAndExtraInfo';
import { generatePathToHeVertragDetailsPage } from '../../../../../features/Vertrag/HeVertrag/heVertragUriPaths';
import CustomFormattedDate from '../../../../FormattedDate/CustomFormattedDate';

type Props = {
  changeType: DeviationChangeTypeTuple;
  sourceEntity: HeAbrechnungHeVertragDeviationSourceEntity;
};

const HeAbrechnungSourceEntityHeader: FC<Props> = ({ changeType, sourceEntity }) => (
  <Space align="baseline" split={<Divider type="vertical" />}>
    <Space size={4} align="baseline" style={{ width: '200px' }}>
      <DeviationChangeTypeTag changeType={changeType} />
      <DeviationColumnTitleStyledText type="secondary">{sourceEntity.type.text}</DeviationColumnTitleStyledText>
    </Space>
    <Space size={16}>
      <Space size={4} style={{ width: '160px' }}>
        <DataWithShortenedTextAndExtraInfo maxTextLength={30} text={sourceEntity.objektBezeichnung} />
      </Space>
      <Space size={4} style={{ width: '204px' }}>
        <DataWithShortenedTextAndExtraInfo maxTextLength={20} text={sourceEntity.vertragspartnerKurzBezeichnung} />
      </Space>
      <Space size={8}>
        <Space size={4}>
          <Typography.Text type="secondary">von</Typography.Text>
          <CustomFormattedDate value={sourceEntity.vertragsBeginn ?? ''} />
        </Space>
        {sourceEntity.vertragsEnde && (
          <Space size={4}>
            <Typography.Text type="secondary">bis</Typography.Text>
            <CustomFormattedDate value={sourceEntity.vertragsEnde} />
          </Space>
        )}
      </Space>
    </Space>
    {sourceEntity.ids && (
      <Typography.Text strong underline>
        <Link to={generatePathToHeVertragDetailsPage(sourceEntity.ids.objektId, sourceEntity.ids.heVertragId)}>{sourceEntity.ids.linkText}</Link>
      </Typography.Text>
    )}
  </Space>
);
export default HeAbrechnungSourceEntityHeader;
