import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import { useBeVertragDeviationListQuery } from './gql/BeVertragDeviationQueries.types';
import { useBeVertragApplyAllDeviationsMutation, useBeVertragPruefungAussetzenMutation } from './gql/BeVertragDeviationMutations.types';
import { showSuccessMsgOther } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import DeviationTab from '../../../../components/Deviation/Tab/DeviationTab';
import { useToggle } from '../../../../hooks/useToggle';
import { VertragStatusTuple } from '../../../../types';
import { useOnBeVertragDataChangedEvent } from '../useOnBeVertragDataChangedEvent';
import { DEVIATIONS_TAB_TITLE } from '../../../../components/Deviation/deviationHelpers';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  status: VertragStatusTuple;
  onAction: () => void;
};

const VertragDeviationList: FC<Props> = ({ objektId, bestandseinheitId, vertragId, status, onAction }) => {
  const [showSubsequentDeviations, toggleShowSubsequentDeviations] = useToggle(false);

  const { data, refetch } = useBeVertragDeviationListQuery({
    variables: {
      objektId,
      bestandseinheitId,
      vertragId,
      showSubsequentDeviations,
    },
  });
  const beVertragDeviationList = data?.getBeVertragDeviationList.data;
  const loading = !beVertragDeviationList;

  useOnBeVertragDataChangedEvent('vertragDeviation', refetch);

  const [runApplyAllDeviations] = useBeVertragApplyAllDeviationsMutation({
    onCompleted: () => {
      showSuccessMsgOther('Alle Abweichungen sind korrigiert.');
      refetch();
      onAction();
    },
    variables: {
      objektId,
      bestandseinheitId,
      vertragId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runPruefungAussetzen] = useBeVertragPruefungAussetzenMutation({
    onCompleted: () => {
      showSuccessMsgOther('@TODO');
      refetch();
      onAction();
    },
    variables: {
      objektId,
      bestandseinheitId,
      vertragId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: DEVIATIONS_TAB_TITLE,
      children: (
        <DeviationTab
          dataSource={beVertragDeviationList}
          loading={loading}
          status={status}
          runApplyAllDeviations={runApplyAllDeviations}
          runPruefungAussetzen={runPruefungAussetzen}
          showSubsequentDeviationsButton
          showSubsequentDeviations={showSubsequentDeviations}
          onShowFolgeDeviationsButtonClick={toggleShowSubsequentDeviations}
        />
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default VertragDeviationList;
