import { Button, Card, Col, Drawer, Dropdown, Switch } from 'antd';
import React from 'react';
import { CheckOutlined, CloseCircleOutlined, EllipsisOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useToggle } from '../../../hooks/useToggle';

const MahneinstellungenCard = ({ contact }) => {
  const [isCollapsed, onCollapse] = useToggle();

  return (
    <>
      {contact.stammdaten ? (
        <Col>
          <Card
            style={{ minWidth: '360px' }}
            size="small"
            title="Mahnungeinstellungen"
            extra={
              <Dropdown menu={{ items: menuItems(onCollapse) }}>
                <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
              </Dropdown>
            }
          >
            <Switch size="small" checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseCircleOutlined />} defaultChecked />
            <span> Mahnungen erstellen</span> <br />
            <Switch size="small" checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseCircleOutlined />} defaultChecked={false} />
            <span> Verzugszinsen berechnen</span>
          </Card>
        </Col>
      ) : null}
      <Drawer title="Mahnungeinstellungen bearbeiten" width={720} open={isCollapsed} onClose={onCollapse} destroyOnClose>
        <p>@TODO inputs</p>
      </Drawer>
    </>
  );
};

const menuItems = (onCollapse) => [{ key: '1', label: 'Bearbeiten', onClick: onCollapse }];

MahneinstellungenCard.propTypes = {
  contact: PropTypes.object.isRequired,
};

export default MahneinstellungenCard;
