import { Select, SelectProps } from 'formik-antd';
import React, { FC, useState } from 'react';
import { FormikProps } from 'formik';
import useDebounce from '../../../../hooks/useDebounce';
import { useBuchungListQuery } from '../../../BookingDetails/gql/BuchungQueries.types';
import { isTextEqualOrLongerThanThreeCharacters } from '../../../../helpers/stringHelper';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import SelectNotFoundContent from '../../../shared/SelectNotFoundContent';
import { filtersFormFields, FiltersFormValues } from './filtersFormMapper';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
  rechtstraegerIdList?: string[] | null;
} & Omit<SelectProps, 'id' | 'allowClear' | 'filterOption' | 'loading' | 'showSearch'>;

const StornoBuchungListFormSelect: FC<Props> = ({ formikProps, rechtstraegerIdList, ...restProps }) => {
  const [bezeichnungText, setBezeichnungText] = useState<string>('');
  const debouncedText = useDebounce(bezeichnungText);

  const { loading, data } = useBuchungListQuery({
    variables: {
      stornierteBuchung: true,
      bezeichnung: debouncedText as string,
      rechtstraegerIdList,
    },
    skip: debouncedText ? !isTextEqualOrLongerThanThreeCharacters(debouncedText as string) : true,
  });

  const list = data?.getBuchungList.data ?? [];

  return (
    <Select
      size="middle"
      placeholder=""
      {...restProps}
      id={restProps.name}
      loading={loading}
      allowClear
      showSearch
      filterOption={selectFilterOption}
      onSelect={(value) => {
        const findBuchung = list?.find((buchung) => buchung.bezeichnung === value);
        findBuchung && formikProps.setFieldValue(filtersFormFields.stornierteBuchungId, findBuchung.buchungId);
        setBezeichnungText(value);
      }}
      onSearch={(value) => {
        bezeichnungText.length === 1 && !value && setBezeichnungText(''); // Checking the length of the text makes the search component editable
        value && setBezeichnungText(value);
      }}
      onClear={() => {
        formikProps.setFieldValue(filtersFormFields.stornierteBuchungId, '');
        setBezeichnungText('');
      }}
      searchValue={bezeichnungText}
      notFoundContent={<SelectNotFoundContent loading={loading} text={debouncedText as string} />}
    >
      {list?.map((buchung) => (
        <Select.Option key={buchung.bezeichnung} value={buchung.bezeichnung}>
          <DataWithShortenedText text={buchung.bezeichnung} maxTextLength={40} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default StornoBuchungListFormSelect;
