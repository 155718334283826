import React, { CSSProperties, FC, useEffect } from 'react';
import useResize from '../../hooks/useResize';

export type TruncatedTextProps = {
  text: React.ReactNode;
  style?: CSSProperties;
  onTruncationChange?: (isTruncated: boolean) => void;
} & React.ComponentPropsWithRef<'span'>;

const TruncatedText: FC<TruncatedTextProps> = ({ text, style, onTruncationChange, ...restProps }) => {
  const { ref } = useResize();
  const { clientWidth, scrollWidth } = ref.current ?? {};

  useEffect(() => {
    onTruncationChange?.(!!(clientWidth && scrollWidth && scrollWidth > clientWidth));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientWidth, scrollWidth]);

  return (
    <span
      ref={ref}
      style={{
        ...style,
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
      {...restProps}
    >
      {text}
    </span>
  );
};

export default TruncatedText;
