import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import VorschreibungPage from './VorschreibungPage';
import { URI_VORSCHREIBUNG_PAGE } from '../../features/Vorschreibung/vorschreibungUriPaths';

const vorschreibungPageRoute = (
  <Route path={URI_VORSCHREIBUNG_PAGE} element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<VorschreibungPage />} />} />
);

export default vorschreibungPageRoute;
