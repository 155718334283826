import styled from 'styled-components';
import { List } from 'antd';

export const StyledListItem = styled(List.Item)`
  width: 304px;
  margin: 5px 20px;
  padding: 0 !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 4px;
`;

export const StyledDraggableContainer = styled('div')`
  display: flex;
  & span {
    &:first-child {
      margin-right: -7px;
    }
    &:last-child {
      margin-left: -7px;
    }
  }
`;
