import React from 'react';
import { ColumnProps } from 'antd/es/table';
import { EuroAmount, Percent } from '../../../../components/Number';
import { Steuerzeile } from '../../../../types';

const steuertabelleColumns: ColumnProps<Steuerzeile>[] = [
  {
    title: 'Steuersatz',
    key: 'steuersatz',
    align: 'left',
    sorter: (a, b) => a.steuersatz - b.steuersatz,
    defaultSortOrder: 'ascend',
    render: (text, record) => (
      <>
        <Percent value={record.steuersatz / 100} /> USt von
      </>
    ),
  },
  {
    title: 'Netto',
    key: 'summeNetto',
    align: 'right',
    render: (text, record) => <EuroAmount value={record.sumNetto} />,
  },
  {
    title: 'USt',
    key: 'summeUst',
    align: 'right',
    render: (text, record) => <EuroAmount value={record.sumUst} />,
  },
  {
    title: 'Brutto',
    key: 'summeBrutto',
    align: 'right',
    render: (text, record) => <EuroAmount value={record.sumBrutto} />,
  },
];

export default steuertabelleColumns;
