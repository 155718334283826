import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Col, Row, Space, Typography } from 'antd';
import { showSuccessMsgUpdate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import FormButtons from '../../../../components/Button/FormButtons';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import { SubAdministration } from '../../../../types';
import { useUpdateSubAdministrationMutation } from '../../gql/SubAdministrationMutations.types';
import {
  mapFormValuesToSubAdministrationUpdate,
  mapSubAdministrationToFormValues,
  subAdministrationFormFields,
  SubAdministrationFormValues,
} from './subAdministrationFormMapper';
import { subAdministrationUpdateFormValidationSchema } from './subAdministrationUpdateFormValidationSchema';
import DeliveryAddressSelect from '../../../shared/DeliveryAddressSelect';
import FormattedDecimalFormInput from '../../../../components/Input-antd/FormattedDecimalFormInput';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../components/DatePicker/DatePicker_formikAntD';

type Props = {
  objektId: string;
  onSuccess: () => void;
  onCancel: () => void;
  subAdministration: SubAdministration;
};

const SubAdministrationUpdateForm: FC<Props> = ({ objektId, onSuccess, onCancel, subAdministration }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'SubAdministration'>('SubAdministration');

  const entity = 'Subverwaltung';

  const [runUpdate] = useUpdateSubAdministrationMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<SubAdministrationFormValues>
      initialValues={mapSubAdministrationToFormValues(subAdministration)}
      validationSchema={subAdministrationUpdateFormValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const input = mapFormValuesToSubAdministrationUpdate(values);
        runUpdate({ variables: { objektId, subAdministrationId: subAdministration.subAdministrationId, input } }).finally(() => setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Eigentümer</Typography.Title>
            </Col>
            <Col span={18}>
              <Row align="top" gutter={8}>
                <Col span={24}>
                  <FormItemWithFieldHelp name={subAdministrationFormFields.eigentuemerBezeichnung} label="Eigentümer">
                    <Input name={subAdministrationFormFields.eigentuemerBezeichnung} disabled />
                  </FormItemWithFieldHelp>
                </Col>
              </Row>
              <Row align="top" gutter={8}>
                <Col span={24}>
                  <FormItemWithFieldHelp
                    name={subAdministrationFormFields.deliveryAddressId}
                    label="Zustelladresse"
                    fieldHelp={getFieldHelpText('SubAdministration.deliveryAddressId')}
                  >
                    <DeliveryAddressSelect
                      name={subAdministrationFormFields.deliveryAddressId}
                      objektId={objektId}
                      rechtstraegerId={subAdministration.eigentuemerId}
                      onNewItemCreated={(addressId) => formikProps.setFieldValue(subAdministrationFormFields.deliveryAddressId, addressId)}
                      onNewItemCreateSelected={() => formikProps.setFieldValue(subAdministrationFormFields.deliveryAddressId, undefined)}
                    />
                  </FormItemWithFieldHelp>
                </Col>
              </Row>
              <Row align="top" gutter={8}>
                <Col span={24}>
                  <FormItemWithFieldHelp
                    name={subAdministrationFormFields.saldouebertrag}
                    label="Erster Saldovortrag"
                    fieldHelp={getFieldHelpText('SubAdministration.saldouebertrag')}
                  >
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <FormattedDecimalFormInput
                        name={subAdministrationFormFields.saldouebertrag}
                        size="small"
                        controls={false}
                        min={0}
                        style={{ width: '100%' }}
                        precision={2}
                        isCurrency
                      />
                      <Typography.Text>gültig ab {dayjsCustom(subAdministration.from).format(DATE_DISPLAY_FORMAT_DEFAULT)}</Typography.Text>
                    </Space>
                  </FormItemWithFieldHelp>
                </Col>
              </Row>
            </Col>
          </Row>
          <FormButtons updateMode onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default SubAdministrationUpdateForm;
