import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Divider, List, Space, Typography } from 'antd';
import { Ticket, TicketBase, TicketComment } from '../../../../types';
import { useTicketCommentListLazyQuery } from '../../gql/TicketQueries.types';
import { Spacer } from '../../../../components/Grid';
import { compareTwoDatesForSorting } from '../../../../helpers/dateHelper';
import EntryChangeHeader from '../shared/EntryChangeHeader';
import { generatePathToTicketDetailsPage } from '../../ticketUriPaths';
import { isTicketArchived } from '../../ticketHelpers';

type Props = {
  ticket: Ticket;
};

type TicketCommentWithDetails = {
  ticket: TicketBase;
} & TicketComment;

const TicketCommentListing: FC<Props> = ({ ticket }) => {
  const [commentList, setCommentList] = useState<TicketCommentWithDetails[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const ticketsHavingComments = getTicketsWhichHaveComments(ticket);

  const [getCommentList] = useTicketCommentListLazyQuery();

  useEffect(() => {
    const fetchData = async () => {
      if (!ticketsHavingComments.length) return;
      setLoading(true);

      try {
        const results = await Promise.all(
          ticketsHavingComments.map(async ({ ticket }) => {
            const { data } = await getCommentList({
              variables: { ticketId: ticket.ticketId },
            });

            return (
              data?.getTicketCommentList.data?.map((comment) => ({
                ticket,
                ...comment,
              })) || []
            );
          })
        );

        const sortedComments = results.flat().sort((a, b) => compareTwoDatesForSorting(b.createTs, a.createTs));
        setCommentList(sortedComments);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket]);

  return (
    <>
      <Spacer height={16} />
      <List
        loading={loading}
        dataSource={commentList}
        itemLayout="horizontal"
        renderItem={(comment) => (
          <>
            <Space size={16} direction="vertical" style={{ width: '100%', paddingInline: 8 }}>
              <EntryChangeHeader
                userId={comment.createdBy}
                mitarbeiterId={comment.createdByMitarbeiterId}
                date={comment.createTs}
                action={commentAction(comment)}
              />
              <Typography.Text style={{ whiteSpace: 'pre-line' }}>{comment.text}</Typography.Text>
            </Space>
            <Divider style={{ marginBlock: 12 }} />
          </>
        )}
        locale={{
          emptyText: 'Keine Kommentare vorhanden',
        }}
      />
    </>
  );
};

const getTicketsWhichHaveComments = (ticket: Ticket) => [
  ...(ticket.commentCount ? [{ ticket }] : []),
  ...ticket.subTicketList.filter((subticket) => subticket.commentCount && !isTicketArchived(subticket)).map((subTicket) => ({ ticket: subTicket })),
];

const commentAction = (comment: TicketCommentWithDetails): React.ReactNode => {
  if (!comment.ticket.isSubTicket) {
    return ` hat einen Kommentar hinzugefügt`;
  }

  const title = (
    <Link target="_blank" to={generatePathToTicketDetailsPage(comment.ticket as TicketBase)}>
      {comment.ticket.title}
    </Link>
  );

  return (
    <span>
      hat einen Kommentar in der Unteraufgabe {`"`}
      {title}
      {`"`} hinzugefügt
    </span>
  );
};

export default TicketCommentListing;
