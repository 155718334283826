import { Button, Drawer, Tabs, TabsProps, Typography } from 'antd';
import React, { FC } from 'react';
import { HistoryOutlined } from '@ant-design/icons';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import ContextMenu from '../../../components/Timeline/ContextMenu';
import { useTimelineOpen } from '../../../components/Timeline/useTimelineOpen';
import BeOptionSteuerpflichtVersionTimeline from '../OptionSteuerpflicht/Version/BeOptionSteuerpflichtVersionTimeline';
import { BeOptionSteuerpflichtVersion } from '../../../types';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';

type InnerCardOptionSteuerpflichtProps = {
  bestandseinheitId: string;
  objektId: string;
  reloadBestandseinheit: () => void;
  steuerpflichtVersion?: BeOptionSteuerpflichtVersion | null;
};

const InnerCardOptionSteuerpflicht: FC<InnerCardOptionSteuerpflichtProps> = ({
  steuerpflichtVersion,
  bestandseinheitId,
  objektId,
  reloadBestandseinheit,
}) => {
  const { timelineDrawer, openTimelineUpdateDrawer, closeTimelineDrawer } = useTimelineOpen();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span>
          <HistoryOutlined />
          Verlauf
        </span>
      ),
      children: (
        <BeOptionSteuerpflichtVersionTimeline
          bestandseinheitId={bestandseinheitId}
          objektId={objektId}
          reloadBestandseinheit={reloadBestandseinheit}
        />
      ),
    },
  ];

  const getOptionSteuerpflichtText = (optionSteuerpflicht: boolean) => (optionSteuerpflicht ? 'ja' : 'nein');

  return (
    <>
      <ContextMenu openDrawerCreate={openTimelineUpdateDrawer} withHistory />
      <Typography.Title level={5}>Steuerpflicht</Typography.Title>

      <CardDetailsInfoRow infoRowTitle="Option zur Steuerpflicht">
        {steuerpflichtVersion?.optionSteuerpflicht !== undefined ? (
          <Typography.Text>{getOptionSteuerpflichtText(steuerpflichtVersion.optionSteuerpflicht)}</Typography.Text>
        ) : (
          <TextForEmptyValue textToShow="minus" />
        )}
      </CardDetailsInfoRow>
      <Drawer
        title="Bearbeiten"
        width={720}
        open={timelineDrawer.visible}
        onClose={closeTimelineDrawer}
        destroyOnClose
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={closeTimelineDrawer} type="primary">
              Schließen
            </Button>
          </div>
        }
      >
        <Tabs items={items} />
      </Drawer>
    </>
  );
};

export default InnerCardOptionSteuerpflicht;
