import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../styles/pdfStyles';
import { VValuePreservationListHeader } from '../../vorschreibung-types';
import { valuePreservationInfoColumnWidthsAndAlignment } from './value-preservation-info-block-tables-styles';

type Props = {
  listHeader: VValuePreservationListHeader;
};

const ValuePreservationInformationColumnTitles: FC<Props> = ({ listHeader }) => (
  <View style={[pdfStyles.row, pdfStyles.borderBottomSmall, { fontWeight: 'bold' }]}>
    <Text style={[pdfStyles.textBulletin, { ...valuePreservationInfoColumnWidthsAndAlignment.bestandseinheit }]}>
      {listHeader.bestandseinheitText}
    </Text>
    <Text style={[pdfStyles.textBulletin, { ...valuePreservationInfoColumnWidthsAndAlignment.position }]}>{listHeader.positionText}</Text>
    <Text style={[pdfStyles.textBulletin, { ...valuePreservationInfoColumnWidthsAndAlignment.valueOld }]}>{listHeader.valueOldText}</Text>
    <Text style={[pdfStyles.textBulletin, { ...valuePreservationInfoColumnWidthsAndAlignment.valueNew }]}>{listHeader.valueNewText}</Text>
    <Text style={[pdfStyles.textBulletin, { ...valuePreservationInfoColumnWidthsAndAlignment.valueFactor }]}>{listHeader.valueFactorText}</Text>
    <Text style={[pdfStyles.textBulletin, { ...valuePreservationInfoColumnWidthsAndAlignment.amountOld }]}>{listHeader.amountOldText}</Text>
    <Text style={[pdfStyles.textBulletin, { ...valuePreservationInfoColumnWidthsAndAlignment.amountNew }]}>{listHeader.amountNewText}</Text>
    <Text style={[pdfStyles.textBulletin, { ...valuePreservationInfoColumnWidthsAndAlignment.amountFactor }]}>{listHeader.amountFactorText}</Text>
    <Text style={[pdfStyles.textBulletin, { ...valuePreservationInfoColumnWidthsAndAlignment.amountDifference }]}>
      {listHeader.amountDifferenceText}
    </Text>
  </View>
);

export default ValuePreservationInformationColumnTitles;
