import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import { URI_VERARBEITUNG_UST_VOM_AUFWAND_DETAILS_PAGE } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import UstVomAufwandDetailsPage from './UstVomAufwandDetailsPage';

export type VerarbeitungUstVomAufwandDetailsPageRouteParams = { generierlaufId: string };

const verarbeitungUstVomAufwandDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_UST_VOM_AUFWAND_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<UstVomAufwandDetailsPage />} />}
  />
);

export default verarbeitungUstVomAufwandDetailsPageRoute;
