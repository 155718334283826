import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { BookingSuggestionBuchnungsmaskeWithTabsPageRouteParams } from './routes';
import { PageLayout } from '../../components/LayoutAndromeda';
import BookingSuggestionBuchungsmaskeWithTabs from '../../features/BookingSuggestion/BuchungsmaskeWithTabs/BookingSuggestionBuchungsmaskeWithTabs';

const BookingSuggestionBuchungsmaskeWithTabsPage = () => {
  const { receivedBankTransactionId } = useParams() as BookingSuggestionBuchnungsmaskeWithTabsPageRouteParams;

  return (
    <>
      <Helmet>
        <title>Buchungsmaske</title>
      </Helmet>
      <PageLayout>
        <BookingSuggestionBuchungsmaskeWithTabs receivedBankTransactionId={receivedBankTransactionId} />
      </PageLayout>
    </>
  );
};

export default BookingSuggestionBuchungsmaskeWithTabsPage;
