import { FC } from 'react';
import HistoryListingTable from '../../../History/HistoryListingTable';
import { HistoryType } from '../../../History/shared/HistoryModal';
import { useKontierungstabelleChangeHistoryListQuery } from '../../gql/KontierungstabelleQueries.types';

type Props = {
  kontierungstabelleId: string;
};

const KontierungstabelleChangeHistoryListingTable: FC<Props> = ({ kontierungstabelleId }) => {
  const { data, loading } = useKontierungstabelleChangeHistoryListQuery({ variables: { kontierungstabelleId } });
  const historyList = data?.getKontierungstabelleChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="kontierungstabelle" />;
};

export default KontierungstabelleChangeHistoryListingTable;
