import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBkAbrechnungsdefinitionForFdMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  input: Types.BkAbrechnungsdefinitionCreateInput;
}>;

export type CreateBkAbrechnungsdefinitionForFdMutation = {
  createBkAbrechnungsdefinitionForFD: {
    data: { abrechnungsdefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateBkAbrechnungsdefinitionForKsMutationVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
  input: Types.BkAbrechnungsdefinitionCreateInput;
}>;

export type CreateBkAbrechnungsdefinitionForKsMutation = {
  createBkAbrechnungsdefinitionForKS: {
    data: { abrechnungsdefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateBkAbrechnungsdefinitionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  input: Types.BkAbrechnungsdefinitionUpdateInput;
}>;

export type UpdateBkAbrechnungsdefinitionMutation = {
  updateBkAbrechnungsdefinition: {
    data: { abrechnungsdefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateBkAbrechnungsdefinitionForFdDocument = gql`
  mutation CreateBkAbrechnungsdefinitionForFD($firmendatenId: ID!, $input: BkAbrechnungsdefinitionCreateInput!) {
    createBkAbrechnungsdefinitionForFD(firmendatenId: $firmendatenId, input: $input) {
      data {
        abrechnungsdefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateBkAbrechnungsdefinitionForFdMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBkAbrechnungsdefinitionForFdMutation, CreateBkAbrechnungsdefinitionForFdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBkAbrechnungsdefinitionForFdMutation, CreateBkAbrechnungsdefinitionForFdMutationVariables>(
    CreateBkAbrechnungsdefinitionForFdDocument,
    options
  );
}
export type CreateBkAbrechnungsdefinitionForFdMutationHookResult = ReturnType<typeof useCreateBkAbrechnungsdefinitionForFdMutation>;
export type CreateBkAbrechnungsdefinitionForFdMutationResult = Apollo.MutationResult<CreateBkAbrechnungsdefinitionForFdMutation>;
export type CreateBkAbrechnungsdefinitionForFdMutationOptions = Apollo.BaseMutationOptions<
  CreateBkAbrechnungsdefinitionForFdMutation,
  CreateBkAbrechnungsdefinitionForFdMutationVariables
>;
export const CreateBkAbrechnungsdefinitionForKsDocument = gql`
  mutation CreateBkAbrechnungsdefinitionForKS($kundenSystemId: ID!, $input: BkAbrechnungsdefinitionCreateInput!) {
    createBkAbrechnungsdefinitionForKS(kundenSystemId: $kundenSystemId, input: $input) {
      data {
        abrechnungsdefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateBkAbrechnungsdefinitionForKsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBkAbrechnungsdefinitionForKsMutation, CreateBkAbrechnungsdefinitionForKsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBkAbrechnungsdefinitionForKsMutation, CreateBkAbrechnungsdefinitionForKsMutationVariables>(
    CreateBkAbrechnungsdefinitionForKsDocument,
    options
  );
}
export type CreateBkAbrechnungsdefinitionForKsMutationHookResult = ReturnType<typeof useCreateBkAbrechnungsdefinitionForKsMutation>;
export type CreateBkAbrechnungsdefinitionForKsMutationResult = Apollo.MutationResult<CreateBkAbrechnungsdefinitionForKsMutation>;
export type CreateBkAbrechnungsdefinitionForKsMutationOptions = Apollo.BaseMutationOptions<
  CreateBkAbrechnungsdefinitionForKsMutation,
  CreateBkAbrechnungsdefinitionForKsMutationVariables
>;
export const UpdateBkAbrechnungsdefinitionDocument = gql`
  mutation UpdateBkAbrechnungsdefinition($abrechnungsdefinitionId: ID!, $input: BkAbrechnungsdefinitionUpdateInput!) {
    updateBkAbrechnungsdefinition(abrechnungsdefinitionId: $abrechnungsdefinitionId, input: $input) {
      data {
        abrechnungsdefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateBkAbrechnungsdefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBkAbrechnungsdefinitionMutation, UpdateBkAbrechnungsdefinitionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBkAbrechnungsdefinitionMutation, UpdateBkAbrechnungsdefinitionMutationVariables>(
    UpdateBkAbrechnungsdefinitionDocument,
    options
  );
}
export type UpdateBkAbrechnungsdefinitionMutationHookResult = ReturnType<typeof useUpdateBkAbrechnungsdefinitionMutation>;
export type UpdateBkAbrechnungsdefinitionMutationResult = Apollo.MutationResult<UpdateBkAbrechnungsdefinitionMutation>;
export type UpdateBkAbrechnungsdefinitionMutationOptions = Apollo.BaseMutationOptions<
  UpdateBkAbrechnungsdefinitionMutation,
  UpdateBkAbrechnungsdefinitionMutationVariables
>;
