import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PaymentRechtstraegerFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import { PaymentObjektFragmentDoc } from '../../../Objekt/gql/ObjektFragments.types';
import { GenerierlaufAsyncStepFieldsFragmentDoc } from '../shared/generierlaufFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BookingSuggestionVerbuchenGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['String']['input'];
}>;

export type BookingSuggestionVerbuchenGenerierlaufQuery = {
  getBookingSuggestionVerbuchenGenerierlauf: {
    data: {
      countFehler: number;
      countNichtVerarbeitet: number;
      countVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierDatum: string;
      generierlaufId: string;
      errorList: Array<{ message: string; type: string }>;
      status: { description?: string | null; text: string; value: Types.GenerierlaufStatus };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BookingSuggestionVerbuchenGenerierlaufEntryQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['String']['input'];
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  paymentType?: Types.InputMaybe<Types.PaymentType>;
  paymentTransactionType?: Types.InputMaybe<Types.PaymentTransactionType>;
  buchungskreisRechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  order?: Types.InputMaybe<Types.BookingSuggestionVerbuchenGenerierlaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type BookingSuggestionVerbuchenGenerierlaufEntryQuery = {
  getBookingSuggestionVerbuchenGenerierlaufEntry: {
    data: {
      contentList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId: string;
        currentStep?: number | null;
        generierlaufEntryId: string;
        infoMessage?: string | null;
        paymentProposalId: string;
        paymentProposalName: string;
        retry: boolean;
        stepCount?: number | null;
        zahlungsdatum: string;
        buchungskreisRechtstraeger: { buchungskreisId?: string | null; kurzBezeichnung: string; rechtstraegerId: string };
        detailsList: Array<{
          amount: number;
          chargesInSeparateEntry?: boolean | null;
          dataCarrierCamtId: string;
          dataCarrierCamtName: string;
          fibuBuchungId?: string | null;
          reversal: boolean;
          statementNumber: string;
          valueDate?: string | null;
          belegSymbol: { abbreviation: string; text: string; value: Types.PaymentBelegSymbol };
          buchungType: { text: string; value: Types.PaymentBuchungType };
          transactionType: { text: string; value: Types.TransactionType };
        }>;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { text: string; value: Types.GenerierlaufEntryExitCode } | null;
        objekt: { kurzBezeichnung: string; objektId: string };
        payee: { buchungskreisId?: string | null; kurzBezeichnung: string; rechtstraegerId: string };
        paymentTransactionType: { text: string; value: Types.PaymentTransactionType };
        paymentType: { text: string; value: Types.PaymentType };
        stepList: Array<{
          designation: string;
          receivingApplication?: string | null;
          stepId: string;
          type?: string | null;
          status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
        }>;
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { size: number; number: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const BookingSuggestionVerbuchenGenerierlaufDocument = gql`
  query BookingSuggestionVerbuchenGenerierlauf($generierlaufId: String!) {
    getBookingSuggestionVerbuchenGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countNichtVerarbeitet
        countVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        generierDatum
        generierlaufId
        status {
          description
          text
          value
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBookingSuggestionVerbuchenGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<BookingSuggestionVerbuchenGenerierlaufQuery, BookingSuggestionVerbuchenGenerierlaufQueryVariables> &
    ({ variables: BookingSuggestionVerbuchenGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingSuggestionVerbuchenGenerierlaufQuery, BookingSuggestionVerbuchenGenerierlaufQueryVariables>(
    BookingSuggestionVerbuchenGenerierlaufDocument,
    options
  );
}
export function useBookingSuggestionVerbuchenGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingSuggestionVerbuchenGenerierlaufQuery, BookingSuggestionVerbuchenGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingSuggestionVerbuchenGenerierlaufQuery, BookingSuggestionVerbuchenGenerierlaufQueryVariables>(
    BookingSuggestionVerbuchenGenerierlaufDocument,
    options
  );
}
export function useBookingSuggestionVerbuchenGenerierlaufSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BookingSuggestionVerbuchenGenerierlaufQuery, BookingSuggestionVerbuchenGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BookingSuggestionVerbuchenGenerierlaufQuery, BookingSuggestionVerbuchenGenerierlaufQueryVariables>(
    BookingSuggestionVerbuchenGenerierlaufDocument,
    options
  );
}
export type BookingSuggestionVerbuchenGenerierlaufQueryHookResult = ReturnType<typeof useBookingSuggestionVerbuchenGenerierlaufQuery>;
export type BookingSuggestionVerbuchenGenerierlaufLazyQueryHookResult = ReturnType<typeof useBookingSuggestionVerbuchenGenerierlaufLazyQuery>;
export type BookingSuggestionVerbuchenGenerierlaufSuspenseQueryHookResult = ReturnType<typeof useBookingSuggestionVerbuchenGenerierlaufSuspenseQuery>;
export type BookingSuggestionVerbuchenGenerierlaufQueryResult = Apollo.QueryResult<
  BookingSuggestionVerbuchenGenerierlaufQuery,
  BookingSuggestionVerbuchenGenerierlaufQueryVariables
>;
export const BookingSuggestionVerbuchenGenerierlaufEntryDocument = gql`
  query BookingSuggestionVerbuchenGenerierlaufEntry(
    $generierlaufId: String!
    $objektId: String
    $paymentType: PaymentType
    $paymentTransactionType: PaymentTransactionType
    $buchungskreisRechtstraegerId: String
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $order: BookingSuggestionVerbuchenGenerierlaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getBookingSuggestionVerbuchenGenerierlaufEntry(
      generierlaufId: $generierlaufId
      objektId: $objektId
      paymentType: $paymentType
      paymentTransactionType: $paymentTransactionType
      buchungskreisRechtstraegerId: $buchungskreisRechtstraegerId
      exitCodeList: $exitCodeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          buchungskreisRechtstraeger {
            ...PaymentRechtstraeger
          }
          createTs
          createdBy
          createdByMitarbeiterId
          currentStep
          detailsList {
            amount
            belegSymbol {
              abbreviation
              text
              value
            }
            buchungType {
              text
              value
            }
            chargesInSeparateEntry
            dataCarrierCamtId
            dataCarrierCamtName
            fibuBuchungId
            reversal
            statementNumber
            transactionType {
              text
              value
            }
            valueDate
          }
          errorList {
            message
            type
          }
          exitCode {
            text
            value
          }
          generierlaufEntryId
          infoMessage
          objekt {
            ...PaymentObjekt
          }
          payee {
            ...PaymentRechtstraeger
          }
          paymentProposalId
          paymentProposalName
          paymentTransactionType {
            text
            value
          }
          paymentType {
            text
            value
          }
          retry
          stepCount
          stepList {
            ...GenerierlaufAsyncStepFields
          }
          warningList {
            message
            type
          }
          zahlungsdatum
        }
        page {
          size
          number
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${PaymentRechtstraegerFragmentDoc}
  ${PaymentObjektFragmentDoc}
  ${GenerierlaufAsyncStepFieldsFragmentDoc}
`;
export function useBookingSuggestionVerbuchenGenerierlaufEntryQuery(
  baseOptions: Apollo.QueryHookOptions<BookingSuggestionVerbuchenGenerierlaufEntryQuery, BookingSuggestionVerbuchenGenerierlaufEntryQueryVariables> &
    ({ variables: BookingSuggestionVerbuchenGenerierlaufEntryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingSuggestionVerbuchenGenerierlaufEntryQuery, BookingSuggestionVerbuchenGenerierlaufEntryQueryVariables>(
    BookingSuggestionVerbuchenGenerierlaufEntryDocument,
    options
  );
}
export function useBookingSuggestionVerbuchenGenerierlaufEntryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BookingSuggestionVerbuchenGenerierlaufEntryQuery,
    BookingSuggestionVerbuchenGenerierlaufEntryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingSuggestionVerbuchenGenerierlaufEntryQuery, BookingSuggestionVerbuchenGenerierlaufEntryQueryVariables>(
    BookingSuggestionVerbuchenGenerierlaufEntryDocument,
    options
  );
}
export function useBookingSuggestionVerbuchenGenerierlaufEntrySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BookingSuggestionVerbuchenGenerierlaufEntryQuery, BookingSuggestionVerbuchenGenerierlaufEntryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BookingSuggestionVerbuchenGenerierlaufEntryQuery, BookingSuggestionVerbuchenGenerierlaufEntryQueryVariables>(
    BookingSuggestionVerbuchenGenerierlaufEntryDocument,
    options
  );
}
export type BookingSuggestionVerbuchenGenerierlaufEntryQueryHookResult = ReturnType<typeof useBookingSuggestionVerbuchenGenerierlaufEntryQuery>;
export type BookingSuggestionVerbuchenGenerierlaufEntryLazyQueryHookResult = ReturnType<
  typeof useBookingSuggestionVerbuchenGenerierlaufEntryLazyQuery
>;
export type BookingSuggestionVerbuchenGenerierlaufEntrySuspenseQueryHookResult = ReturnType<
  typeof useBookingSuggestionVerbuchenGenerierlaufEntrySuspenseQuery
>;
export type BookingSuggestionVerbuchenGenerierlaufEntryQueryResult = Apollo.QueryResult<
  BookingSuggestionVerbuchenGenerierlaufEntryQuery,
  BookingSuggestionVerbuchenGenerierlaufEntryQueryVariables
>;
