import { Modal } from 'antd';

const confirmDeleteWizard = (onConfirm: () => void, onCancel?: () => void) => {
  Modal.confirm({
    title: `Möchten Sie den Assistenten löschen?`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk: onConfirm,
    onCancel: () => {
      onCancel?.();
    },
  });
};

export default confirmDeleteWizard;
