import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateFibuEinstellungMutationVariables = Types.Exact<{
  request: Types.FibuEinstellungUpdateInput;
}>;

export type UpdateFibuEinstellungMutation = {
  updateFibuEinstellung: {
    data: { negierteHabenBetraege: boolean };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const UpdateFibuEinstellungDocument = gql`
  mutation updateFibuEinstellung($request: FibuEinstellungUpdateInput!) {
    updateFibuEinstellung(request: $request) {
      data {
        negierteHabenBetraege
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateFibuEinstellungMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFibuEinstellungMutation, UpdateFibuEinstellungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFibuEinstellungMutation, UpdateFibuEinstellungMutationVariables>(UpdateFibuEinstellungDocument, options);
}
export type UpdateFibuEinstellungMutationHookResult = ReturnType<typeof useUpdateFibuEinstellungMutation>;
export type UpdateFibuEinstellungMutationResult = Apollo.MutationResult<UpdateFibuEinstellungMutation>;
export type UpdateFibuEinstellungMutationOptions = Apollo.BaseMutationOptions<UpdateFibuEinstellungMutation, UpdateFibuEinstellungMutationVariables>;
