import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DateUnitListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type DateUnitListQuery = {
  getDateUnitList: {
    data: Array<{ value: Types.PeriodUnit; text: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const DateUnitListDocument = gql`
  query DateUnitList {
    getDateUnitList {
      data {
        value
        text
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDateUnitListQuery(baseOptions?: Apollo.QueryHookOptions<DateUnitListQuery, DateUnitListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DateUnitListQuery, DateUnitListQueryVariables>(DateUnitListDocument, options);
}
export function useDateUnitListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DateUnitListQuery, DateUnitListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DateUnitListQuery, DateUnitListQueryVariables>(DateUnitListDocument, options);
}
export function useDateUnitListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DateUnitListQuery, DateUnitListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DateUnitListQuery, DateUnitListQueryVariables>(DateUnitListDocument, options);
}
export type DateUnitListQueryHookResult = ReturnType<typeof useDateUnitListQuery>;
export type DateUnitListLazyQueryHookResult = ReturnType<typeof useDateUnitListLazyQuery>;
export type DateUnitListSuspenseQueryHookResult = ReturnType<typeof useDateUnitListSuspenseQuery>;
export type DateUnitListQueryResult = Apollo.QueryResult<DateUnitListQuery, DateUnitListQueryVariables>;
