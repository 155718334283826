import { generatePath } from 'react-router-dom';

export const URI_OBJEKT_DETAILS_PAGE = `/dashboard/objects/:objektId`;

export const generatePathToObjektDetailsPage = (objektId: string) => generatePath(URI_OBJEKT_DETAILS_PAGE, { objektId });

const generatePathToObjektDetailsPageTopMenu = (objektId: string) => `${generatePathToObjektDetailsPage(objektId)}/tops`;
const generatePathToObjektDetailsPageTopMenuTopsTab = (objektId: string) => `${generatePathToObjektDetailsPageTopMenu(objektId)}/tops`;
const generatePathToObjektDetailsPageTopMenuVorgabenTab = (objektId: string) => `${generatePathToObjektDetailsPageTopMenu(objektId)}/vorgaben`;
const generatePathToObjektDetailsPageTopMenuZinslistenTab = (objektId: string) => `${generatePathToObjektDetailsPageTopMenu(objektId)}/zinslisten`;
const generatePathToObjektDetailsPageTopMenuAbrechnungenTab = (objektId: string) =>
  `${generatePathToObjektDetailsPageTopMenu(objektId)}/abrechnungen`;
const generatePathToObjektDetailsPageTopMenuVorsteuerkuerzungTab = (objektId: string) =>
  `${generatePathToObjektDetailsPageTopMenu(objektId)}/vorsteuerkuerzung`;
const generatePathToObjektDetailsPageTopMenuUstVomAufwandTab = (objektId: string) =>
  `${generatePathToObjektDetailsPageTopMenu(objektId)}/ust-vom-aufwand`;

const generatePathToObjektDetailsPageTopMenuHeVertragTab = (objektId: string) =>
  `${generatePathToObjektDetailsPageTopMenu(objektId)}/hauseigentuemer-vertraege`;

const generatePathToObjektDetailsPageInfoFeldMenu = (objektId: string) => `${generatePathToObjektDetailsPage(objektId)}/info-felder`;

const generatePathToObjektDetailsPageBudgetingTab = (objektId: string) => `${generatePathToObjektDetailsPageTopMenu(objektId)}/budgetierung`;

export const pathsToObjektDetailsPage = (objektId: string) => ({
  objektDetails: generatePathToObjektDetailsPage(objektId),
  topMenu: generatePathToObjektDetailsPageTopMenu(objektId),
  topTab: generatePathToObjektDetailsPageTopMenuTopsTab(objektId),
  vorgabenTab: generatePathToObjektDetailsPageTopMenuVorgabenTab(objektId),
  zinslistenTab: generatePathToObjektDetailsPageTopMenuZinslistenTab(objektId),
  abrechnungenTab: generatePathToObjektDetailsPageTopMenuAbrechnungenTab(objektId),
  ustVomAufwand: generatePathToObjektDetailsPageTopMenuUstVomAufwandTab(objektId),
  vstKuerzung: generatePathToObjektDetailsPageTopMenuVorsteuerkuerzungTab(objektId),
  heVertrag: generatePathToObjektDetailsPageTopMenuHeVertragTab(objektId),
  budgeting: generatePathToObjektDetailsPageBudgetingTab(objektId),
  infoFeldMenu: generatePathToObjektDetailsPageInfoFeldMenu(objektId),
});
