import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Table } from 'antd';
import {
  VorsteuerkuerzungKontenZuweisungFormValues,
  VorsteuerkuerzungKontenZuweisungVersionFormValues,
} from '../../Version/Form/vstKuerzungKontenZuweisungVersionFormMapper';
import vstKuerzungKontenZuweisungTableColumns from './vstKuerzungKontenZuweisungTableColumns';
import { Verwaltungsart } from '../../../../../types';

type Props = {
  formikProps: FormikProps<VorsteuerkuerzungKontenZuweisungVersionFormValues>;
  onDelete: (index: number) => void;
  verwaltungsart: Verwaltungsart;
};

const VstKuerzungKontenZuweisungTable: FC<Props> = ({ formikProps, onDelete, verwaltungsart }) => {
  return (
    <Table<VorsteuerkuerzungKontenZuweisungFormValues>
      dataSource={formikProps.values.kontenZuweisungList}
      //name={vstKuerzungKontenZuordnungVersionFormFields.kontenZuweisungList}
      columns={vstKuerzungKontenZuweisungTableColumns(formikProps, onDelete, verwaltungsart)}
      size="small"
      tableLayout="fixed"
      pagination={false}
    />
  );
};

export default VstKuerzungKontenZuweisungTable;
