import { Modal } from 'antd';

export const showConfirmAusgeben = (runAusgeben: () => Promise<unknown>, singleMahnung?: boolean) => {
  Modal.confirm({
    title: `Möchten Sie die ${singleMahnung ? 'Mahnung' : 'Mahnungen'} ausgeben?`,
    okText: 'Ausgeben',
    cancelText: 'Abbrechen',
    onOk() {
      return runAusgeben();
    },
  });
};

export const showConfirmFreigeben = (runFreigeben: () => Promise<unknown>, singleMahnung?: boolean) => {
  Modal.confirm({
    title: `Möchten Sie die ${singleMahnung ? 'Mahnung' : 'Mahnungen'} freigeben?`,
    okText: 'Freigeben',
    cancelText: 'Abbrechen',
    onOk() {
      return runFreigeben();
    },
  });
};

export const showConfirmDelete = (runDelete: () => Promise<unknown>, singleMahnung?: boolean) => {
  Modal.confirm({
    title: `Möchten Sie die ${singleMahnung ? 'Mahnung' : 'Mahnungen'} löschen?`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export const showConfirmSperren = (runSperren: () => Promise<unknown>, singleMahnung?: boolean) => {
  Modal.confirm({
    title: `Möchten Sie die ${singleMahnung ? 'Mahnung' : 'Mahnungen'} sperren?`,
    okButtonProps: { danger: true },
    okText: 'Sperren',
    cancelText: 'Abbrechen',
    onOk() {
      return runSperren();
    },
  });
};

export const showConfirmEntsperren = (runEntsperren: () => Promise<unknown>, singleMahnung?: boolean) => {
  Modal.confirm({
    title: `Möchten Sie die ${singleMahnung ? 'Mahnung' : 'Mahnungen'} entsperren?`,
    okText: 'Entsperren',
    cancelText: 'Abbrechen',
    onOk() {
      return runEntsperren();
    },
  });
};

export const showConfirmVersenden = (runVersenden: () => Promise<unknown>, singleMahnung?: boolean) => {
  Modal.confirm({
    title: `Möchten Sie die ${singleMahnung ? 'Mahnung' : 'Mahnungen'} versenden?`,
    okText: 'Versenden',
    cancelText: 'Abbrechen',
    onOk() {
      return runVersenden();
    },
  });
};

export const showConfirmVerbuchen = (runVerbuchen: () => Promise<unknown>, singleMahnung?: boolean) => {
  Modal.confirm({
    title: `Möchten Sie die ${singleMahnung ? 'Mahnung' : 'Mahnungen'} verbuchen?`,
    okText: 'Verbuchen',
    cancelText: 'Abbrechen',
    onOk() {
      return runVerbuchen();
    },
  });
};
