import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../hooks/useToggle';
import { useMahndefinitionListQuery } from './gql/MahndefinitionQueries.types';
import URI_SYS_SETTINGS_MAHNDEFINITION_PAGE, { MahndefinitionListEntryWithStatus } from './mahndefinitonUriPaths';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../components/Button/ButtonsAligned';
import MahndefinitionTable from './Table/MahndefinitionTable';

const MahndefinitionListing = () => {
  const navigate = useNavigate();
  const [showArchived, toggleShowArchived] = useToggle();

  const { data, refetch } = useMahndefinitionListQuery({
    variables: {
      includeArchiviert: showArchived,
      withDetails: true,
    },
  });
  const mahndefinitionList = data?.getMahndefinitionList.data as MahndefinitionListEntryWithStatus[] | undefined;

  const mahndefinitionButtons = (
    <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
      <Button onClick={toggleShowArchived}>Archivierte Einträge {showArchived ? 'ausblenden' : 'anzeigen'}</Button>
      <Button icon={<PlusOutlined />} type="primary" onClick={() => navigate(URI_SYS_SETTINGS_MAHNDEFINITION_PAGE.createPage)}>
        Mahndefinition anlegen
      </Button>
    </ButtonsAligned>
  );

  return (
    <>
      {mahndefinitionButtons}
      <MahndefinitionTable mahndefinitionenList={mahndefinitionList} refetch={refetch} />
    </>
  );
};

export default MahndefinitionListing;
