import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteAbrechnungsdefinitionTemplateMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
}>;

export type DeleteAbrechnungsdefinitionTemplateMutation = {
  deleteAbrechnungsdefinitionTemplate: { errorList: Array<{ message: string; type: string }>; warningList: Array<{ message: string; type: string }> };
};

export const DeleteAbrechnungsdefinitionTemplateDocument = gql`
  mutation DeleteAbrechnungsdefinitionTemplate($abrechnungsdefinitionId: ID!) {
    deleteAbrechnungsdefinitionTemplate(abrechnungsdefinitionId: $abrechnungsdefinitionId) {
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeleteAbrechnungsdefinitionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAbrechnungsdefinitionTemplateMutation, DeleteAbrechnungsdefinitionTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAbrechnungsdefinitionTemplateMutation, DeleteAbrechnungsdefinitionTemplateMutationVariables>(
    DeleteAbrechnungsdefinitionTemplateDocument,
    options
  );
}
export type DeleteAbrechnungsdefinitionTemplateMutationHookResult = ReturnType<typeof useDeleteAbrechnungsdefinitionTemplateMutation>;
export type DeleteAbrechnungsdefinitionTemplateMutationResult = Apollo.MutationResult<DeleteAbrechnungsdefinitionTemplateMutation>;
export type DeleteAbrechnungsdefinitionTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteAbrechnungsdefinitionTemplateMutation,
  DeleteAbrechnungsdefinitionTemplateMutationVariables
>;
