import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { RepFondsAbrechnungUebersicht } from '../../topAbrechnung-types';
import TARepFondsAbrechnungUebersichtTable from './TARepFondsAbrechnungUebersichtTable';
import TARepFondsAbrechnungBlockTitle from './TARepFondsAbrechnungBlockTitle';

const TARepFondsAbrechnungUebersichtBlock: FC<{ uebersicht: RepFondsAbrechnungUebersicht }> = ({ uebersicht }) => (
  <View style={[pdfStyles.column, { width: '60%', marginTop: '3mm' }]}>
    {/*  Uebersicht title */}
    <TARepFondsAbrechnungBlockTitle titleText={uebersicht.uebersichtTitleText} />

    {/*  Uebersicht table */}
    <TARepFondsAbrechnungUebersichtTable uebersicht={uebersicht} />
  </View>
);

export default TARepFondsAbrechnungUebersichtBlock;
