import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateZinslisteMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  vorschreibungsPeriode: Types.VorschreibungsperiodeInput;
}>;

export type CreateZinslisteMutation = {
  createZinsliste: {
    data: { kopfdaten: { zinslisteId: string; objektKurzBezeichnung: string; vorschreibungsperiode: { jahr: number; monat: number } } };
    warningList?: Array<{ message: string; type: string }> | null;
  };
};

export type DeleteZinslisteMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  zinslisteId: Types.Scalars['String']['input'];
}>;

export type DeleteZinslisteMutation = {
  deleteZinsliste: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type ZinslisteMarkUnderReviewMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  zinslisteId: Types.Scalars['ID']['input'];
}>;

export type ZinslisteMarkUnderReviewMutation = {
  actionZinslisteMarkUnderReview: {
    data: { createTs: string };
    warningList?: Array<{ message: string; type: string }> | null;
    errorList?: Array<{ message: string; type: string }> | null;
  };
};

export const CreateZinslisteDocument = gql`
  mutation CreateZinsliste($objektId: String!, $vorschreibungsPeriode: VorschreibungsperiodeInput!) {
    createZinsliste(objektId: $objektId, vorschreibungsPeriode: $vorschreibungsPeriode) {
      data {
        kopfdaten {
          zinslisteId
          vorschreibungsperiode {
            jahr
            monat
          }
          objektKurzBezeichnung
        }
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreateZinslisteMutation(baseOptions?: Apollo.MutationHookOptions<CreateZinslisteMutation, CreateZinslisteMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateZinslisteMutation, CreateZinslisteMutationVariables>(CreateZinslisteDocument, options);
}
export type CreateZinslisteMutationHookResult = ReturnType<typeof useCreateZinslisteMutation>;
export type CreateZinslisteMutationResult = Apollo.MutationResult<CreateZinslisteMutation>;
export type CreateZinslisteMutationOptions = Apollo.BaseMutationOptions<CreateZinslisteMutation, CreateZinslisteMutationVariables>;
export const DeleteZinslisteDocument = gql`
  mutation DeleteZinsliste($objektId: String!, $zinslisteId: String!) {
    deleteZinsliste(objektId: $objektId, zinslisteId: $zinslisteId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteZinslisteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteZinslisteMutation, DeleteZinslisteMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteZinslisteMutation, DeleteZinslisteMutationVariables>(DeleteZinslisteDocument, options);
}
export type DeleteZinslisteMutationHookResult = ReturnType<typeof useDeleteZinslisteMutation>;
export type DeleteZinslisteMutationResult = Apollo.MutationResult<DeleteZinslisteMutation>;
export type DeleteZinslisteMutationOptions = Apollo.BaseMutationOptions<DeleteZinslisteMutation, DeleteZinslisteMutationVariables>;
export const ZinslisteMarkUnderReviewDocument = gql`
  mutation ZinslisteMarkUnderReview($objektId: String!, $zinslisteId: ID!) {
    actionZinslisteMarkUnderReview(objektId: $objektId, zinslisteId: $zinslisteId) {
      data {
        createTs
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useZinslisteMarkUnderReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<ZinslisteMarkUnderReviewMutation, ZinslisteMarkUnderReviewMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ZinslisteMarkUnderReviewMutation, ZinslisteMarkUnderReviewMutationVariables>(ZinslisteMarkUnderReviewDocument, options);
}
export type ZinslisteMarkUnderReviewMutationHookResult = ReturnType<typeof useZinslisteMarkUnderReviewMutation>;
export type ZinslisteMarkUnderReviewMutationResult = Apollo.MutationResult<ZinslisteMarkUnderReviewMutation>;
export type ZinslisteMarkUnderReviewMutationOptions = Apollo.BaseMutationOptions<
  ZinslisteMarkUnderReviewMutation,
  ZinslisteMarkUnderReviewMutationVariables
>;
