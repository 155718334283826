import React, { useState } from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { getFirstTimeblock, ITimeblock, IVersionable, useTimeline, VersionTimelineOnDelete } from './timelineCtx';
import theme from '../../theme';

type TimelineDeleteButtonProps<T extends IVersionable<T>> = {
  timeblock: ITimeblock<T>;
  isAllowedToDeleteInitialTimeblock?: boolean;
  onDelete?: VersionTimelineOnDelete<T>;
  editable?: boolean;
};

export function TimelineDeleteButton<T extends IVersionable<T>>({
  timeblock,
  isAllowedToDeleteInitialTimeblock,
  onDelete,
  editable,
}: TimelineDeleteButtonProps<T>) {
  const { dataSource } = useTimeline<T>();
  const [isDeleteLoading] = useState(false);

  const isFirstTimeblock = timeblock.validFrom === getFirstTimeblock(dataSource).validFrom;
  const isDeletionDisabled = !timeblock.deletable;

  if (editable) {
    if (isFirstTimeblock && !isAllowedToDeleteInitialTimeblock) {
      return (
        <Tooltip title="Die erste Version kann nicht gelöscht werden" placement="topRight">
          <Button disabled shape="circle" type="text" icon={<DeleteOutlined />} size="small" loading={isDeleteLoading} />
        </Tooltip>
      );
    } else {
      return (
        <Popconfirm
          disabled={isDeletionDisabled}
          placement="rightBottom"
          title="Möchten Sie diesen Zeitblock löschen?"
          onConfirm={() => onDelete?.(timeblock)}
          okText="Ja"
          cancelText="Nein"
        >
          <Tooltip title="Löschen">
            <Button
              disabled={isDeletionDisabled}
              shape="circle"
              type="text"
              icon={<DeleteOutlined style={{ color: !isDeletionDisabled ? theme.colors.danger : '' }} />}
              size="small"
              loading={isDeleteLoading}
            />
          </Tooltip>
        </Popconfirm>
      );
    }
  }
  return null;
}
