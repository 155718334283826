import * as Yup from 'yup';
import { entityIsRequired } from '../../../../../components/message/validationMsg';

export const textinfoWirtschaftsplanFormValidationSchema = Yup.object().shape({
  budgetingVPosId: Yup.string().required(entityIsRequired('Budgeting Vorschreibungposition')),
  expenseList: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(entityIsRequired('Bezeichnung')),
      amount: Yup.string().required(entityIsRequired('Betrag')),
    })
  ),
});
