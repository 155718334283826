import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { FiltersFormValues, listingFiltersFormFields, listingLabelList } from './listingFiltersFormMapper';
import FiltersWith4OrMoreFields from '../../../../../components/Filters/FiltersWith4OrMoreFields';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { PaymentGenerierlaufType } from '../../../../../types';
import GenerierlaufEntryExitCodeListFormSelect from '../../../shared/Filters/Payment/GenerierlaufEntryExitCodeListFormSelect';
import GenerierlaufObjektFormSelect from '../../../shared/Filters/Payment/GenerierlaufObjektFormSelect';
import GenerierlaufDataCarrierNameListFormSelect from '../../../shared/Filters/Payment/GenerierlaufDataCarrierNameListFormSelect';
import GenerierlaufStatementNumberListFormSelect from '../../../shared/Filters/Payment/GenerierlaufStatementNumberListFormSelect';
import FromToFormDatePicker from '../../../../shared/FromToForm/FromToFormDatePicker';
import GenerierlaufCreditorListFormSelect from '../../../shared/Filters/Payment/GenerierlaufCreditorListFormSelect';
import GenerierlaufDebtorListFormSelect from '../../../shared/Filters/Payment/GenerierlaufDebtorListFormSelect';
import FromToFormInputNumber from '../../../../shared/FromToForm/FromToFormInputNumber';
import { filtersFormFields } from '../../../../ReceivedBankTransaction/Listing/Filters/filtersFormMapper';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
  generierlaufId: string;
};

const ListingFilters: FC<Props> = ({ formikProps, generierlaufId }) => {
  return (
    <FiltersWith4OrMoreFields
      hideTitle
      leftColumn={
        <>
          <FormItemWithoutColon name={listingFiltersFormFields.objektId} label={listingLabelList.objektId}>
            <GenerierlaufObjektFormSelect
              name={listingFiltersFormFields.objektId}
              generierlaufId={generierlaufId}
              generierlaufType={PaymentGenerierlaufType.ReceivedBankTransactionVerbuchen}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={listingFiltersFormFields.dataCarrierCamtName} label={listingLabelList.dataCarrierCamtName}>
            <GenerierlaufDataCarrierNameListFormSelect
              name={listingFiltersFormFields.dataCarrierCamtName}
              generierlaufId={generierlaufId}
              generierlaufType={PaymentGenerierlaufType.ReceivedBankTransactionVerbuchen}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={listingFiltersFormFields.statementNumber} label={listingLabelList.statementNumber}>
            <GenerierlaufStatementNumberListFormSelect
              name={listingFiltersFormFields.statementNumber}
              generierlaufId={generierlaufId}
              generierlaufType={PaymentGenerierlaufType.ReceivedBankTransactionVerbuchen}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name="valueDate" label={listingLabelList.valueDateFrom}>
            <FromToFormDatePicker
              format="day"
              fromName={listingFiltersFormFields.valueDateFrom}
              toName={listingFiltersFormFields.valueDateTo}
              fromOnChange={formikProps.submitForm}
              toOnChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
      rightColumn={
        <>
          <FormItemWithoutColon name={listingFiltersFormFields.creditor} label={listingLabelList.creditor}>
            <GenerierlaufCreditorListFormSelect
              name={listingFiltersFormFields.creditor}
              generierlaufId={generierlaufId}
              generierlaufType={PaymentGenerierlaufType.ReceivedBankTransactionVerbuchen}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={listingFiltersFormFields.debtor} label={listingLabelList.debtor}>
            <GenerierlaufDebtorListFormSelect
              name={listingFiltersFormFields.debtor}
              generierlaufId={generierlaufId}
              generierlaufType={PaymentGenerierlaufType.ReceivedBankTransactionVerbuchen}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FromToFormInputNumber
            name="Betrag"
            fromName={filtersFormFields.amountFrom}
            toName={filtersFormFields.amountTo}
            onChange={formikProps.submitForm}
          />
          <FormItemWithoutColon name={listingFiltersFormFields.exitCodeList} label={listingLabelList.exitCodeList}>
            <GenerierlaufEntryExitCodeListFormSelect
              name={listingFiltersFormFields.exitCodeList}
              generierlaufId={generierlaufId}
              generierlaufType={PaymentGenerierlaufType.ReceivedBankTransactionVerbuchen}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
    />
  );
};

export default ListingFilters;
