import { Skeleton } from 'antd';
import { useIndexValueCreationCronJobSettingsQuery } from '../../../../features/IndexValueCreationCronJobSettings/gql/IndexValueCreationCronJobSettingsQueries.types';
import IndexValueCreationCronJobSettingsCard from '../../../../features/IndexValueCreationCronJobSettings/IndexValueCreationCronJobSettingsCard';

const IndexValueCreationCronJobSettingsPage = () => {
  const { data, refetch } = useIndexValueCreationCronJobSettingsQuery();

  const indexValueCreationCronJobSettings = data?.getIndexValueCreationCronJobSettings.data;

  if (!indexValueCreationCronJobSettings) return <Skeleton active />;

  return <IndexValueCreationCronJobSettingsCard indexValueCreationCronJobSettings={indexValueCreationCronJobSettings} onAction={refetch} />;
};

export default IndexValueCreationCronJobSettingsPage;
