import React, { FC } from 'react';
import { Select, SelectProps, Tag } from 'antd';
import { KontoKlasse, KontoVerwendung } from '../../types';
import { selectFilterOption } from '../../helpers/selectHelper';
import { useKontoSelectListQuery } from './gql/KontoSelectQueries.types';

type Props = {
  kontoId?: string | null;
  kontoKlasse?: KontoKlasse[];
  kontoVerwendung?: KontoVerwendung;
} & SelectProps;
const KontoSelect: FC<Props> = ({ kontoId, kontoKlasse, kontoVerwendung, ...restProps }) => {
  const { loading, data } = useKontoSelectListQuery({ variables: { klasseList: kontoKlasse, verwendung: kontoVerwendung } });
  const kontoList = data?.getKontoList?.data.contentList ?? [];

  return (
    <Select
      loading={loading}
      defaultValue={kontoId}
      allowClear
      showSearch
      optionLabelProp="children"
      filterOption={selectFilterOption}
      {...restProps}
    >
      {kontoList.map((konto) => (
        <Select.Option key={konto.kontoId} value={konto.kontoId}>
          <Tag>{konto.nummer}</Tag> {konto.bezeichnung}
        </Select.Option>
      ))}
    </Select>
  );
};

export default KontoSelect;
