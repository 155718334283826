import { useParams } from 'react-router-dom';
import { useFDAuthorized } from '../../../../shared/Auth/useAuthorized';
import { useAuth } from '../../../../shared/Auth/authContext';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../../../shared/dataChangedSubscription';
import { WeTopAbrechnungDetailsPageRouteParams } from '../../../../pages/WeTopAbrechnungDetailsPage/routes';
import { BkTopAbrechnungDetailsPageRouteParams } from '../../../../pages/BkTopAbrechnungDetailsPage/routes';
import { DataChangedChangeType } from '../../../../types';

type TopAbrechnung = WeTopAbrechnungDetailsPageRouteParams | BkTopAbrechnungDetailsPageRouteParams;

const useOnBkOrWeTopAbrechnungDataChangedEvents = (key: string, callback: () => Promise<unknown>) => {
  const { user } = useFDAuthorized();
  const { mitarbeiter } = useAuth();

  const { objektAbrechnungId } = useParams() as TopAbrechnung;
  useOnDataChangedEvent([
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.topAbrechnung,
        changeType: DataChangedChangeType.Update,
        params: {
          objektAbrechnungId,
        },
      },
      notificationDescription: 'Topabrechnungen wurden gerade verändert',
      key: `${key}`,
      callback,
    },
  ]);
};

export { useOnBkOrWeTopAbrechnungDataChangedEvents };
