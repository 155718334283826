import React, { FC } from 'react';
import { Space } from 'antd';

import { ITimeblock } from '../../../../components/Timeline/timelineCtx';
import TimelineCardContentInfoRow from '../../../../components/Timeline/Card/TimelineCardContentInfoRow';
import VstKuerzungKontenZuweisungTable from '../Table/Read/VstKuerzungKontenZuweisungTable';
import { Verwaltungsart, VorsteuerkuerzungEinstellung } from '../../../../types';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  timeblock: ITimeblock<VorsteuerkuerzungEinstellung>;
  verwaltungsart: Verwaltungsart;
};

const VstKuerzungKontenZuweisungVersionCardContent: FC<Props> = ({ timeblock, verwaltungsart }) => (
  <Space direction="vertical" style={{ width: '100%', paddingBottom: '8px' }}>
    <TimelineCardContentInfoRow infoRowTitle="Gültig ab">
      {timeblock.validFrom && <CustomFormattedDate value={timeblock.validFrom} />}
    </TimelineCardContentInfoRow>
    <VstKuerzungKontenZuweisungTable verwaltungsart={verwaltungsart} vorsteuerkuerzung={timeblock} isInCardContent />
  </Space>
);

export default VstKuerzungKontenZuweisungVersionCardContent;
