import { InvoiceType } from '../../../types';
import { IncomingInvoiceFormValues } from './incomingInvoiceFormMapper';

export const isInvoiceTypeSingleInvoice = (invoiceType?: InvoiceType) => invoiceType === InvoiceType.SingleInvoice;
export const isInvoiceTypeStandingInvoice = (invoiceType?: InvoiceType) => invoiceType === InvoiceType.StandingInvoice;

// INFO: Ignore Payment related values - Do not open modal when changes are only related to Zahlung - ZahlungBlockForm
const getFormValuesWithoutPaymentForSingleInvoice = (values: IncomingInvoiceFormValues) => {
  const {
    paymentMethod,
    payeeBankDetailsId,
    issuerBankDetailsId,
    purposeOfUseText,
    paymentReferenceText,
    payeeRechtstraegerRolleList,
    requestedPaymentDate,
    sepaMandatReference,
    partialAmountList,
    ...restValuesWithoutZahlungValues
  } = values;
  return restValuesWithoutZahlungValues;
};

export const hasSingleInvoiceChanged = (formValues: IncomingInvoiceFormValues, initialFormValues: IncomingInvoiceFormValues) => {
  let hasChanged = false;

  const { buchungPositionList: currentBuchungPositionList, ...restCurrent } = getFormValuesWithoutPaymentForSingleInvoice(formValues);
  const { buchungPositionList: initialBuchungPositionList, ...restInitial } = getFormValuesWithoutPaymentForSingleInvoice(initialFormValues);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const currentPositionList = currentBuchungPositionList!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const initialPositionList = initialBuchungPositionList!;

  const currentJson = JSON.stringify(restCurrent);
  const initialJson = JSON.stringify(restInitial);

  if (currentJson !== initialJson || currentPositionList.length !== initialPositionList.length) hasChanged = true;

  if (!hasChanged) {
    currentPositionList.every((position, index) => {
      const { kontoId, incomingInvoiceBuchungPositionId, ...restPosition } = position;
      const {
        kontoId: inialKontoId,
        incomingInvoiceBuchungPositionId: initialEingangsRechnungBuchungPositionId,
        ...restInitialPosition
      } = initialPositionList[index];

      const currentPosition = JSON.stringify(restPosition);
      const initalPosition = JSON.stringify(restInitialPosition);
      if (currentPosition === initalPosition) return true;

      hasChanged = true;
      return false;
    });
  }
  return hasChanged;
};

// INFO: Ignore Payment related values - Do not open modal when changes are only related to Zahlung - ZahlungBlockForm
const getFormValuesWithoutPaymentForStandingInvoice = (values: IncomingInvoiceFormValues) => {
  const {
    paymentMethod,
    payeeBankDetailsId,
    issuerBankDetailsId,
    purposeOfUseText,
    paymentReferenceText,
    requestedPaymentDate,
    sepaMandatReference,
    buchungPositionList,
    ...restValuesWithoutZahlungValues
  } = values;
  return restValuesWithoutZahlungValues;
};

export const hasStandingInvoiceChanged = (formValues: IncomingInvoiceFormValues, initialFormValues: IncomingInvoiceFormValues) => {
  let hasChanged = false;

  const { partialAmountList: currentPartialAmountList, ...restCurrent } = getFormValuesWithoutPaymentForStandingInvoice(formValues);
  const { partialAmountList: initialPartialAmountList, ...restInitial } = getFormValuesWithoutPaymentForStandingInvoice(initialFormValues);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const currentList = currentPartialAmountList!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const initialList = initialPartialAmountList!;

  const currentJson = JSON.stringify(restCurrent);
  const initialJson = JSON.stringify(restInitial);

  if (currentJson !== initialJson || currentList.length !== initialList.length) hasChanged = true;

  if (!hasChanged) {
    currentList.every((partialAmount, index) => {
      const { id, buchungPositionList: currentBuchungPositionList, ...restPartialAmount } = partialAmount;
      const { id: initialId, buchungPositionList: initialBuchungPositionList, ...restInitialPartialAmount } = initialList[index];

      const currentPartialAmount = JSON.stringify(restPartialAmount);
      const initialPartialAmount = JSON.stringify(restInitialPartialAmount);

      if (currentPartialAmount !== initialPartialAmount || currentPartialAmount.length !== initialPartialAmount.length) {
        hasChanged = true;
        return true;
      }

      if (!hasChanged) {
        return currentBuchungPositionList.every((position, index) => {
          const currentPosition = JSON.stringify(position);
          const initalPosition = JSON.stringify(initialBuchungPositionList[index]);

          if (currentPosition === initalPosition) return true;

          hasChanged = true;
          return false;
        });
      }

      return false;
    });
  }

  return hasChanged;
};
