import React, { FC } from 'react';
import { List } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import {
  getZuordnungKontoListItemHeight,
  ZuordnungKontoKlasseType,
  ZuordnungViewModel,
} from '../../../../AbrKontenzuordnung/shared/AbrKreisKontenzuordnungHelpers';
import { StyledCollapseKonto } from '../../../../AbrKontenzuordnung/Styled/Collapse.style';
import ZuordnungKontoKlasseTitle from '../../../../AbrKontenzuordnung/shared/ZuordnungKontoKlasseTitle';
import ZuordnungKontoListItemBezeichnung from '../../../../AbrKontenzuordnung/shared/ZuordnungKontoListItemBezeichnung';
import { getInformationNumber } from './onlyViewHelpers';

type Props = {
  title: string;
  zuordnungKontoKlasseType: ZuordnungKontoKlasseType;
  list: ZuordnungViewModel[];
  isCollapsed: boolean;
  collapse: () => void;
  keyForCollapse: string;
};

const ZuordnungKontoListOnlyView: FC<Props> = ({ title, zuordnungKontoKlasseType, list, isCollapsed, collapse, keyForCollapse }) => {
  return (
    <StyledCollapseKonto
      ghost
      expandIcon={({ isActive }) => (!isActive ? <PlusOutlined /> : <MinusOutlined />)}
      onChange={collapse}
      defaultActiveKey={isCollapsed ? [keyForCollapse] : []}
      destroyInactivePanel
    >
      <StyledCollapseKonto.Panel header={<ZuordnungKontoKlasseTitle title={title} />} style={{ padding: 0, margin: 0 }} key={keyForCollapse}>
        <List
          size="small"
          bordered={false}
          dataSource={list}
          rowKey={(item) => item.konto.kontoId}
          renderItem={(item) => (
            <List.Item style={{ height: getZuordnungKontoListItemHeight(getInformationNumber(item, zuordnungKontoKlasseType)) }}>
              <ZuordnungKontoListItemBezeichnung
                nummer={item.konto.nummer}
                bezeichnung={item.konto.bezeichnung}
                zuordnungKontoKlasseType={zuordnungKontoKlasseType}
                verwendung={item.konto.verwendung?.value}
              />
            </List.Item>
          )}
        />
      </StyledCollapseKonto.Panel>
    </StyledCollapseKonto>
  );
};

export default ZuordnungKontoListOnlyView;
