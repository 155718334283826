import SollingergasseBild1 from '../../assets/objekt/objekt_Sollingergasse1-11_bild1.png';
import BarthgasseBild1 from '../../assets/objekt/objekt_Barthgasse-3_bild1.png';
import StiegergasseBild1 from '../../assets/objekt/objekt_Stiegergasse-5_bild1.png';

const topListBarthgasse = [
  {
    bestandseinheitId: '0',
    bezeichnung: 'Top1',
    bauteil: '',
    stiege: '',
    geschoss: 'EG',
    nummer: '1',
    nutzungsArt: { text: 'wohnen' },
    nutzflaeche: { zahl: '44,72', percent: '7,40%' },
    nutzwert: { zahl: '39', percent: '6,41%' },
    vertragList: [
      {
        vertragspartner: {
          rechtstraegerId: '1201',
          kurzBezeichnung: 'Hufnagl, Dipl.Päd. Monika',
          type: {
            value: 'NATUERLICHE_PERSON',
            text: 'Natürliche Person',
          },
        },
        vertragsart: {
          value: 'WEG_EIGENTUEMER',
          text: 'WEG Eigentümer',
        },
      },
    ],
    eigentuemerId: '1201',
    eigentuemer: 'Hufnagl, Dipl.Päd. Monika',
    mieterId: '',
    mieter: '',
  },
  {
    bestandseinheitId: '1',
    bezeichnung: 'Top2',
    bauteil: '',
    stiege: '',
    geschoss: 'EG',
    nummer: '2',
    nutzungsArt: { text: 'wohnen' },
    nutzflaeche: { zahl: '43,25', percent: '7,16%' },
    nutzwert: { zahl: '40', percent: '6,58%' },
    vertragList: [
      {
        vertragspartner: {
          rechtstraegerId: '1202',
          kurzBezeichnung: 'Schreiner, Dr. Peter',
          type: {
            value: 'NATUERLICHE_PERSON',
            text: 'Natürliche Person',
          },
        },
        vertragsart: {
          value: 'WEG_EIGENTUEMER',
          text: 'WEG Eigentümer',
        },
      },
    ],
    eigentuemerId: '1202',
    eigentuemer: 'Schreiner, Dr. Peter',
    mieterId: '',
    mieter: '',
  },
  {
    bestandseinheitId: '2',
    bezeichnung: 'Top3',
    bauteil: '',
    stiege: '',
    geschoss: '1.OG',
    nummer: '3',
    nutzungsArt: { text: 'wohnen' },
    nutzflaeche: { zahl: '56,55', percent: '9,36%' },
    nutzwert: { zahl: '62', percent: '10,20%' },
    vertragList: [
      {
        vertragspartner: {
          rechtstraegerId: '1203',
          kurzBezeichnung: 'Neumann, Doris',
          type: {
            value: 'NATUERLICHE_PERSON',
            text: 'Natürliche Person',
          },
        },
        vertragsart: {
          value: 'WEG_EIGENTUEMER',
          text: 'WEG Eigentümer',
        },
      },
    ],
    eigentuemerId: '1203',
    eigentuemer: 'Neumann, Doris',
    mieterId: '',
    mieter: '',
  },
  {
    bestandseinheitId: '3',
    bezeichnung: 'Top4',
    bauteil: '',
    stiege: '',
    geschoss: '1.OG',
    nummer: '4',
    nutzungsArt: { text: 'wohnen' },
    nutzflaeche: { zahl: '63,93', percent: '10,58%' },
    nutzwert: { zahl: '70', percent: '11,51%' },
    vertragList: [
      {
        vertragspartner: {
          rechtstraegerId: '1204',
          kurzBezeichnung: 'Zavodsky, Herbert',
          type: {
            value: 'NATUERLICHE_PERSON',
            text: 'Natürliche Person',
          },
        },
        vertragsart: {
          value: 'WEG_EIGENTUEMER',
          text: 'WEG Eigentümer',
        },
      },
    ],
    eigentuemerId: '1204',
    eigentuemer: 'Zavodsky, Herbert',
    mieterId: '',
    mieter: '',
  },
  {
    bestandseinheitId: '4',
    bezeichnung: 'Top5',
    bauteil: '',
    stiege: '',
    geschoss: '1.OG',
    nummer: '5',
    nutzungsArt: { text: 'wohnen' },
    nutzflaeche: { zahl: '48,97', percent: '8,10%' },
    nutzwert: { zahl: '50', percent: '8,22%' },
    vertragList: [
      {
        vertragspartner: {
          rechtstraegerId: '1000',
          kurzBezeichnung: 'Steiner, Dietmar',
          type: {
            value: 'NATUERLICHE_PERSON',
            text: 'Natürliche Person',
          },
        },
        vertragsart: {
          value: 'WEG_EIGENTUEMER',
          text: 'WEG Eigentümer',
        },
      },
    ],
    eigentuemerId: '1000',
    eigentuemer: 'Steiner, Dietmar',
    mieterId: '',
    mieter: '',
  },
  {
    bestandseinheitId: '5',
    bezeichnung: 'Top6',
    bauteil: '',
    stiege: '',
    geschoss: '2.OG',
    nummer: '6',
    nutzungsArt: { text: 'wohnen' },
    nutzflaeche: { zahl: '28,88', percent: '4,78%' },
    nutzwert: { zahl: '29', percent: '4,77%' },
    vertragList: [
      {
        vertragspartner: {
          rechtstraegerId: '1205',
          kurzBezeichnung: 'Kramer, Elisabeth',
          type: {
            value: 'NATUERLICHE_PERSON',
            text: 'Natürliche Person',
          },
        },
        vertragsart: {
          value: 'WEG_EIGENTUEMER',
          text: 'WEG Eigentümer',
        },
      },
    ],
    eigentuemerId: '1205',
    eigentuemer: 'Kramer, Elisabeth',
    mieterId: '',
    mieter: '',
  },
  {
    bestandseinheitId: '6',
    bezeichnung: 'Top7',
    bauteil: '',
    stiege: '',
    geschoss: '2.OG',
    nummer: '7',
    nutzungsArt: { text: 'wohnen' },
    nutzflaeche: { zahl: '44,35', percent: '7,34%' },
    nutzwert: { zahl: '46', percent: '7,57%' },
    vertragList: [
      {
        vertragspartner: {
          rechtstraegerId: '1206',
          kurzBezeichnung: 'Wurm, Mag. Ilse',
          type: {
            value: 'NATUERLICHE_PERSON',
            text: 'Natürliche Person',
          },
        },
        vertragsart: {
          value: 'WEG_EIGENTUEMER',
          text: 'WEG Eigentümer',
        },
      },
    ],
    eigentuemerId: '1206',
    eigentuemer: 'Wurm, Mag. Ilse',
    mieterId: '',
    mieter: '',
  },
  {
    bestandseinheitId: '7',
    bezeichnung: 'Top8',
    bauteil: '',
    stiege: '',
    geschoss: '3.OG',
    nummer: '8',
    nutzungsArt: { text: 'wohnen' },
    nutzflaeche: { zahl: '58,38', percent: '9,66%' },
    nutzwert: { zahl: '61', percent: '10,03%' },
    vertragList: [
      {
        vertragspartner: {
          rechtstraegerId: '1202',
          kurzBezeichnung: 'Schreiner, Dr. Peter',
          type: {
            value: 'NATUERLICHE_PERSON',
            text: 'Natürliche Person',
          },
        },
        vertragsart: {
          value: 'WEG_EIGENTUEMER',
          text: 'WEG Eigentümer',
        },
      },
    ],
    eigentuemerId: '1202',
    eigentuemer: 'Schreiner, Dr. Peter',
    mieterId: '',
    mieter: '',
  },
  {
    bestandseinheitId: '8',
    bezeichnung: 'Top9',
    bauteil: '',
    stiege: '',
    geschoss: '3.OG',
    nummer: '9',
    nutzungsArt: { text: 'wohnen' },
    nutzflaeche: { zahl: '65,51', percent: '10,84%' },
    nutzwert: { zahl: '67', percent: '11,02%' },
    vertragList: [
      {
        vertragspartner: {
          rechtstraegerId: '1206',
          kurzBezeichnung: 'Wurm, Mag. Ilse',
          type: {
            value: 'NATUERLICHE_PERSON',
            text: 'Natürliche Person',
          },
        },
        vertragsart: {
          value: 'WEG_EIGENTUEMER',
          text: 'WEG Eigentümer',
        },
      },
    ],
    eigentuemerId: '1206',
    eigentuemer: 'Wurm, Mag. Ilse',
    mieterId: '',
    mieter: '',
  },
  {
    bestandseinheitId: '9',
    bezeichnung: 'Top10',
    bauteil: '',
    stiege: '',
    geschoss: 'DG 10',
    nummer: '9',
    nutzungsArt: { text: 'wohnen' },
    nutzflaeche: { zahl: '79,56', percent: '13,17%' },
    nutzwert: { zahl: '86', percent: '14,14%' },
    vertragList: [
      {
        vertragspartner: {
          rechtstraegerId: '1207',
          kurzBezeichnung: 'Walenta, Ing. Manfred',
          type: {
            value: 'NATUERLICHE_PERSON',
            text: 'Natürliche Person',
          },
        },
        vertragsart: {
          value: 'WEG_EIGENTUEMER',
          text: 'WEG Eigentümer',
        },
      },
    ],
    eigentuemerId: '1207',
    eigentuemer: 'Walenta, Ing. Manfred',
    mieterId: '',
    mieter: '',
  },
  {
    bestandseinheitId: '10',
    bezeichnung: 'Top11',
    bauteil: '',
    stiege: '',
    geschoss: 'DG 11',
    nummer: '9',
    nutzungsArt: { text: 'wohnen' },
    nutzflaeche: { zahl: '34,47', percent: '5,70%' },
    nutzwert: { zahl: '40', percent: '6,58%' },
    vertragList: [
      {
        vertragspartner: {
          rechtstraegerId: '1208',
          kurzBezeichnung: 'Wiebach, Dr. Hilde',
          type: {
            value: 'NATUERLICHE_PERSON',
            text: 'Natürliche Person',
          },
        },
        vertragsart: {
          value: 'WEG_EIGENTUEMER',
          text: 'WEG Eigentümer',
        },
      },
    ],
    eigentuemerId: '1208',
    eigentuemer: 'Wiebach, Dr. Hilde',
    mieterId: '',
    mieter: '',
  },
  {
    bestandseinheitId: '11',
    bezeichnung: 'Box1',
    bauteil: '',
    stiege: '',
    geschoss: '',
    nummer: '12',
    nutzungsArt: { text: 'nicht wohnen' },
    nutzflaeche: { zahl: '11,88', percent: '1,97%' },
    nutzwert: { zahl: '6', percent: '0,99%' },
    vertragList: [
      {
        vertragspartner: {
          rechtstraegerId: '1202',
          kurzBezeichnung: 'Schreiner, Dr. Peter',
          type: {
            value: 'NATUERLICHE_PERSON',
            text: 'Natürliche Person',
          },
        },
        vertragsart: {
          value: 'WEG_EIGENTUEMER',
          text: 'WEG Eigentümer',
        },
      },
    ],
    eigentuemerId: '1202',
    eigentuemer: 'Schreiner, Dr. Peter',
    mieterId: '',
    mieter: '',
  },
  {
    bestandseinheitId: '12',
    bezeichnung: 'Box2',
    bauteil: '',
    stiege: '',
    geschoss: '',
    nummer: '13',
    nutzungsArt: { text: 'nicht wohnen' },
    nutzflaeche: { zahl: '11,88', percent: '1,97%' },
    nutzwert: { zahl: '6', percent: '0,99%' },
    vertragList: [
      {
        vertragspartner: {
          rechtstraegerId: '1202',
          kurzBezeichnung: 'Schreiner, Dr. Peter',
          type: {
            value: 'NATUERLICHE_PERSON',
            text: 'Natürliche Person',
          },
        },
        vertragsart: {
          value: 'WEG_EIGENTUEMER',
          text: 'WEG Eigentümer',
        },
      },
    ],
    eigentuemerId: '1202',
    eigentuemer: 'Schreiner, Dr. Peter',
    mieterId: '',
    mieter: '',
  },
  {
    bestandseinheitId: '13',
    bezeichnung: 'Box3',
    bauteil: '',
    stiege: '',
    geschoss: '',
    nummer: '13',
    nutzungsArt: { text: 'nicht wohnen' },
    nutzflaeche: { zahl: '11,88', percent: '1,97%' },
    nutzwert: { zahl: '6', percent: '0,99%' },
    vertragList: [
      {
        vertragspartner: {
          rechtstraegerId: '1205',
          kurzBezeichnung: 'Kramer, Elisabeth',
          type: {
            value: 'NATUERLICHE_PERSON',
            text: 'Natürliche Person',
          },
        },
        vertragsart: {
          value: 'WEG_EIGENTUEMER',
          text: 'WEG Eigentümer',
        },
      },
    ],
    eigentuemerId: '1205',
    eigentuemer: 'Kramer, Elisabeth',
    mieterId: '',
    mieter: '',
  },
];

const topListSollingergasse = [
  {
    bestandseinheitId: '0',
    bezeichnung: 'Top10',
    bauteil: 'A',
    stiege: '1',
    geschoss: '2',
    nummer: '1',
    nutzungsArt: { text: 'wohnen' },
    nutzflaeche: { zahl: '56,58', percent: '2,30%' },
    nutzwert: { zahl: '55', percent: '2,22%' },
    vertragList: [
      {
        vertragspartner: {
          rechtstraegerId: '1209',
          kurzBezeichnung: 'Schöck, Mag. Stefan',
          type: {
            value: 'NATUERLICHE_PERSON',
            text: 'Natürliche Person',
          },
        },
        vertragsart: {
          value: 'WEG_EIGENTUEMER',
          text: 'WEG Eigentümer',
        },
      },
    ],
    eigentuemerId: '1209',
    eigentuemer: 'Schöck, Mag. Stefan',
    mieterId: '1214',
    mieter: 'Kramer, Elisabeth',
  },
  {
    bestandseinheitId: '1',
    bezeichnung: 'Top11',
    bauteil: 'A',
    stiege: '1',
    geschoss: '2',
    nummer: '2',
    nutzungsArt: { text: 'wohnen' },
    nutzflaeche: { zahl: '61,58', percent: '2,50%' },
    nutzwert: { zahl: '62', percent: '2,50%' },
    vertragList: [
      {
        vertragspartner: {
          rechtstraegerId: '1210',
          kurzBezeichnung: 'Holek, Andreas',
          type: {
            value: 'NATUERLICHE_PERSON',
            text: 'Natürliche Person',
          },
        },
        vertragsart: {
          value: 'WEG_EIGENTUEMER',
          text: 'WEG Eigentümer',
        },
      },
    ],
    eigentuemerId: '1210',
    eigentuemer: 'Holek, Andreas',
    mieterId: '1215',
    mieter: 'Hübsch, Ing. Mathias',
  },
  {
    bestandseinheitId: '2',
    bezeichnung: 'Top13',
    bauteil: 'A',
    stiege: '1',
    geschoss: '2',
    nummer: '3',
    nutzungsArt: { text: 'wohnen' },
    nutzflaeche: { zahl: '75,20', percent: '3,06%' },
    nutzwert: { zahl: '73', percent: '2,94%' },
    vertragList: [
      {
        vertragspartner: {
          rechtstraegerId: '1211',
          kurzBezeichnung: 'Donati, Mag. Heiner',
          type: {
            value: 'NATUERLICHE_PERSON',
            text: 'Natürliche Person',
          },
        },
        vertragsart: {
          value: 'WEG_EIGENTUEMER',
          text: 'WEG Eigentümer',
        },
      },
    ],
    eigentuemerId: '1211',
    eigentuemer: 'Donati, Mag. Heiner',
    mieterId: '1216',
    mieter: 'Siedek J. und M.',
  },
  {
    bestandseinheitId: '3',
    bezeichnung: 'Top34',
    bauteil: 'A',
    stiege: '1',
    geschoss: '4',
    nummer: '4',
    nutzungsArt: { text: 'wohnen' },
    nutzflaeche: { zahl: '62,50', percent: '2,54%' },
    nutzwert: { zahl: '59', percent: '2,38%' },
    vertragList: [
      {
        vertragspartner: {
          rechtstraegerId: '1212',
          kurzBezeichnung: 'ERNECIC Herr und Frau',
          type: {
            value: 'NATUERLICHE_PERSON',
            text: 'Natürliche Person',
          },
        },
        vertragsart: {
          value: 'WEG_EIGENTUEMER',
          text: 'WEG Eigentümer',
        },
      },
    ],
    eigentuemerId: '1212',
    eigentuemer: 'ERNECIC Herr und Frau',
    mieterId: '1217',
    mieter: 'Böcksteiner, Judith',
  },
  {
    bestandseinheitId: '4',
    bezeichnung: 'Top10',
    bauteil: 'B',
    stiege: '2',
    geschoss: '2',
    nummer: '5',
    nutzungsArt: { text: 'wohnen' },
    nutzflaeche: { zahl: '56,58', percent: '2,30%' },
    nutzwert: { zahl: '55', percent: '2,22%' },
    vertragList: [
      {
        vertragspartner: {
          rechtstraegerId: '1213',
          kurzBezeichnung: 'Lippl, Roman',
          type: {
            value: 'NATUERLICHE_PERSON',
            text: 'Natürliche Person',
          },
        },
        vertragsart: {
          value: 'WEG_EIGENTUEMER',
          text: 'WEG Eigentümer',
        },
      },
    ],
    eigentuemerId: '1213',
    eigentuemer: 'Lippl, Roman',
    mieterId: '1218',
    mieter: 'Lerch, Lisa',
  },
  {
    bestandseinheitId: '5',
    bezeichnung: 'Top15',
    bauteil: 'B',
    stiege: '2',
    geschoss: '2',
    nummer: '6',
    nutzungsArt: { text: 'wohnen' },
    nutzflaeche: { zahl: '38,27', percent: '1,56%' },
    nutzwert: { zahl: '36', percent: '1,45%' },
    vertragList: [
      {
        vertragspartner: {
          rechtstraegerId: '1000',
          kurzBezeichnung: 'Steiner, Dietmar',
          type: {
            value: 'NATUERLICHE_PERSON',
            text: 'Natürliche Person',
          },
        },
        vertragsart: {
          value: 'WEG_EIGENTUEMER',
          text: 'WEG Eigentümer',
        },
      },
    ],
    eigentuemerId: '1000',
    eigentuemer: 'Steiner, Dietmar',
    mieterId: '1219',
    mieter: 'Kerschbaumer, Dr. Renate',
  },
  {
    bestandseinheitId: '6',
    bezeichnung: 'Top20',
    bauteil: 'B',
    stiege: '2',
    geschoss: '3',
    nummer: '7',
    nutzungsArt: { text: 'wohnen' },
    nutzflaeche: { zahl: '61,58', percent: '2,50%' },
    nutzwert: { zahl: '59', percent: '2,38%' },
    vertragList: [
      {
        vertragspartner: {
          rechtstraegerId: '1000',
          kurzBezeichnung: 'Steiner, Dietmar',
          type: {
            value: 'NATUERLICHE_PERSON',
            text: 'Natürliche Person',
          },
        },
        vertragsart: {
          value: 'WEG_EIGENTUEMER',
          text: 'WEG Eigentümer',
        },
      },
    ],
    eigentuemerId: '1000',
    eigentuemer: 'Steiner, Dietmar',
    mieterId: '1220',
    mieter: 'Lippl, Roman',
  },
  {
    bestandseinheitId: '7',
    bezeichnung: 'Top23',
    bauteil: 'B',
    stiege: '2',
    geschoss: '3',
    nummer: '8',
    nutzungsArt: { text: 'wohnen' },
    nutzflaeche: { zahl: '75,20', percent: '3,06%' },
    nutzwert: { zahl: '70', percent: '2,82%' },
    vertragList: [
      {
        vertragspartner: {
          rechtstraegerId: '1000',
          kurzBezeichnung: 'Steiner, Dietmar',
          type: {
            value: 'NATUERLICHE_PERSON',
            text: 'Natürliche Person',
          },
        },
        vertragsart: {
          value: 'WEG_EIGENTUEMER',
          text: 'WEG Eigentümer',
        },
      },
    ],
    eigentuemerId: '1000',
    eigentuemer: 'Steiner, Dietmar',
    mieterId: '1221',
    mieter: 'Kuchar, Mag. Sylvia',
  },
];

export const objektList = [
  {
    objektId: '0',
    img: SollingergasseBild1,
    kurzBezeichnung: 'WEG Sollingergasse 1 - 11',
    verwaltungSeit: '01.08.2019',
    status: {
      value: 'AKTIV',
      text: 'aktiv',
    },
    objektart: 'WEG',
    typ: {
      value: 'WEG',
      text: 'Wohnungseigentümergemeinschaft',
    },
    rechnungsAussteller: {
      rechtstraegerId: '2001',
      vatIdentificationNumber: 'ATU56193933',
      kurzBezeichnung: 'WEG Sollingergasse 1 - 11',
      type: {
        value: 'PERSONEN_GEMEINSCHAFT',
        text: 'Personengemeinschaft',
      },
    },
    bestandseinheitList: topListSollingergasse,
    countryCodeIso2: 'AT',
    sumNutzwert: '2480',
    sumNutzflaeche: '2.458,69',
    erstelltAm: '07.05.2011',
    addressList: [
      {
        type: 'STREET',
        addressId: '0',
        countryCodeIso2: 'AT',
        city: 'Wien',
        street: 'Sollingergasse',
        houseEntranceApartmentNumber: '11',
        zipCode: '1190',
      },
    ],
  },
  {
    objektId: '1',
    img: BarthgasseBild1,
    kurzBezeichnung: 'WEG Barthgasse 3',
    verwaltungSeit: '01.07.2019',
    status: {
      value: 'AKTIV',
      text: 'aktiv',
    },
    objektart: 'WEG',
    typ: {
      value: 'WEG',
      text: 'Wohnungseigentümergemeinschaft',
    },
    rechnungsAussteller: {
      rechtstraegerId: '2002',
      vatIdentificationNumber: 'ATU62756912',
      kurzBezeichnung: 'WEG Barthgasse 3',
      type: {
        value: 'PERSONEN_GEMEINSCHAFT',
        text: 'Personengemeinschaft',
      },
    },
    bestandseinheitList: topListBarthgasse,
    countryCodeIso2: 'AT',
    sumNutzwert: '608',
    sumNutzflaeche: '604,21',
    erstelltAm: '07.06.2014',
    addressList: [
      {
        type: 'STREET',
        addressId: '0',
        countryCodeIso2: 'AT',
        city: 'Wien',
        street: 'Barthgasse',
        houseEntranceApartmentNumber: '3',
        zipCode: '1030',
      },
    ],
  },
  {
    objektId: '2',
    img: StiegergasseBild1,
    kurzBezeichnung: 'WEG Stiegergasse 5',
    verwaltungSeit: '01.06.2019',
    status: {
      value: 'AKTIV',
      text: 'aktiv',
    },
    objektart: 'WEG',
    typ: {
      value: 'WEG',
      text: 'Wohnungseigentümergemeinschaft',
    },
    rechnungsAussteller: {
      rechtstraegerId: '2000',
      vatIdentificationNumber: 'U58937707',
      kurzBezeichnung: 'WEG Stiegergasse 5',
      type: {
        value: 'PERSONEN_GEMEINSCHAFT',
        text: 'Personengemeinschaft',
      },
    },
    bestandseinheitList: [],
    countryCodeIso2: 'AT',
    sumNutzwert: '',
    sumNutzflaeche: '',
    erstelltAm: '07.05.2013',
    addressList: [
      {
        type: 'STREET',
        addressId: '0',
        countryCodeIso2: 'AT',
        city: 'Wien',
        street: 'Stiegergasse',
        houseEntranceApartmentNumber: '5',
        zipCode: '1150',
      },
    ],
  },
];

/*
*  imageList: [
      {
        src: { Sollingergasse_bild1 },
        alt: 'bild 1',
      },
      {
        src: { Sollingergasse_bild2 },
        alt: 'bild 1',
      },
    ],
    *
    * */
