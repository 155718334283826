import React, { FC } from 'react';
import { Empty } from 'antd';
import { BestandseinheitBase, Budgeting, BudgetingResultAufteilungsschluessel, BudgetingResultSourceType } from '../../../../../types';
import NestedTable from '../../../../../components/Table/NestedTable/NestedTable';
import BudgetingResultBudgetingVPosTable from '../Level5/BudgetingResultBudgetingVPosTable';
import BudgetingResultBudgetingKontoTable from '../Level5/BudgetingResultBudgetingKontoTable';
import budgetingResultAufteilungsschluesselTableColumns from './budgetingResultAufteilungsschluesselTableColumns';

type Props = {
  budgeting: Budgeting;
  budgetingResultAufteilungsschluesselList: BudgetingResultAufteilungsschluessel[];
  bestandseinheit: BestandseinheitBase;
};

const BudgetingResultAufteilungsschluesselTable: FC<Props> = ({ budgeting, budgetingResultAufteilungsschluesselList, bestandseinheit }) => (
  <NestedTable<BudgetingResultAufteilungsschluessel>
    colWidth={100}
    level={4}
    locale={{
      emptyText: <Empty description={<span>Keine Vertragswerte vorhanden</span>} />,
    }}
    dataSource={budgetingResultAufteilungsschluesselList}
    columns={budgetingResultAufteilungsschluesselTableColumns(budgeting, bestandseinheit)}
    rowKey={(record) => record.name}
    expandable={{
      expandedRowRender: (record) => {
        if (budgeting.resultSourceType?.value === BudgetingResultSourceType.VPos) {
          return (
            <BudgetingResultBudgetingVPosTable
              budgeting={budgeting}
              budgetingResultBudgetingVPosList={record.budgetingResultBudgetingVPos ? [record.budgetingResultBudgetingVPos] : []}
            />
          );
        }
        if (budgeting.resultSourceType?.value === BudgetingResultSourceType.Expenses) {
          return (
            <BudgetingResultBudgetingKontoTable budgeting={budgeting} budgetingResultBudgetingKontoList={record.budgetingResultBudgetingKontoList} />
          );
        }

        return null;
      },
    }}
  />
);
export default BudgetingResultAufteilungsschluesselTable;
