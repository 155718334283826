import React, { FC } from 'react';
import { Space } from 'antd';
import { ITimeblock } from '../../../../components/Timeline/timelineCtx';
import TimelineCardContentInfoRow from '../../../../components/Timeline/Card/TimelineCardContentInfoRow';
import { RepFondsEinstellung } from '../../../../types';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import RepFondsTemplateKontenZuweisungTable from '../Table/Read/RepFondsTemplateKontenZuweisungTable';

type Props = {
  timeblock: ITimeblock<RepFondsEinstellung>;
};

const RepFondsTemplateKontenZuweisungVersionCardContent: FC<Props> = ({ timeblock }) => (
  <Space direction="vertical" style={{ width: '100%', paddingBottom: '8px' }}>
    <TimelineCardContentInfoRow infoRowTitle="Gültig ab">
      {timeblock.validFrom && <CustomFormattedDate value={timeblock.validFrom} />}
    </TimelineCardContentInfoRow>
    <RepFondsTemplateKontenZuweisungTable repFondsEinstellung={timeblock} isInCardContent />
  </Space>
);

export default RepFondsTemplateKontenZuweisungVersionCardContent;
