import React from 'react';
import { Typography } from 'antd';
import { useLocation } from 'react-router';
import { ROUTE_STATE_ERROR_REASON_TOKEN_INVALID } from '../../helpers/routerHelper';
import ErrorBoundaryForwardToLogin from '../../features/Feedback/ErrorBoundaryForwardToLogin';
import { ErrorPageLayout as Layout } from '../../components/LayoutAndromeda';

const ErrorPageWithErrorBoundary = () => (
  <ErrorBoundaryForwardToLogin>
    <ErrorPage />
  </ErrorBoundaryForwardToLogin>
);

const ErrorPage = () => {
  const location = useLocation();
  // @ts-ignore
  const { reason } = location.state;
  return <Layout>{reason === ROUTE_STATE_ERROR_REASON_TOKEN_INVALID ? <ErrorLinkNotValid /> : <ErrorGeneral />}</Layout>;
};

const ErrorLinkNotValid = () => <Typography.Title level={2}>Link ist ungültig.</Typography.Title>;
const ErrorGeneral = () => <Typography.Title level={2}>Es gab leider einen Fehler versuchen Sie es erneut.</Typography.Title>;

export default ErrorPageWithErrorBoundary;
