import React, { PropsWithChildren } from 'react';

// should be used only in generic part, not in features where editableTable is used
import { EditableTableRecordType } from '../EditableTableOld/EditableTableProps';
import { EditableTableAction } from './editableTableReducer';
import { FormikYupValidationSchema } from '../../../helpers/formikHelper';

export type EditableTableContextTypeIntern<TableRecordType> = {
  selectedRecord: EditableTableRecordType<TableRecordType> | null;
  onUpdate: (selectedRecord: EditableTableRecordType<TableRecordType>) => void;
  cancelEdit: () => void;
  isSubmitLoading: boolean;
  isRowInEditMode: (rowKey?: string | null) => boolean;
  validationSchema?: FormikYupValidationSchema;
  mapToFormValues: (selectedRecord: TableRecordType) => any;
  onSubmit: (formValues: any, selectedRecord: EditableTableRecordType<TableRecordType>) => Promise<TableRecordType>; // has to return a Promise to be able to determine whether selectedRecord should be cleared
  dispatch: React.Dispatch<EditableTableAction<TableRecordType>>;
};

const EditableTableContext = React.createContext<EditableTableContextTypeIntern<any>>(undefined as any);
const EditableTableContextProvider = EditableTableContext.Provider;

export const EditableTableProvider = <TableRecordType extends Record<string, any> = any>(
  props: PropsWithChildren<EditableTableContextTypeIntern<TableRecordType>>
) => {
  const { children, ...restProps } = props;
  return <EditableTableContextProvider value={restProps}>{children}</EditableTableContextProvider>;
};

// should be used only in generic part, not in features where editableTable is used
export const useEditableTableIntern = <TableRecordType,>() => {
  const context = React.useContext<EditableTableContextTypeIntern<TableRecordType>>(EditableTableContext);
  if (!context) throw new Error('useEditableTable must be inside a Provider with a value');
  return context;
};

export type EditableTableContextType<TableRecordType> = {
  selectedRecord: TableRecordType | null; // context can be accessed anywhere under provider, eg. in render and therefore selectedRecord could be null at the time
  onUpdate: (selectedRecord: TableRecordType) => void;
  cancelEdit: () => void;
  isSubmitLoading: boolean;
  isRowInEditMode: (rowKey?: string | null) => boolean;
  validationSchema?: FormikYupValidationSchema;
  mapToFormValues: (selectedRecord: TableRecordType) => any;
  onSubmit: (formValues: any, selectedRecord: EditableTableRecordType<TableRecordType>) => Promise<TableRecordType>; // has to return a Promise to be able to determine whether selectedRecord should be cleared
  dispatch: React.Dispatch<EditableTableAction<TableRecordType>>;
};

export const useEditableTable = <TableRecordType,>() => {
  const context = React.useContext<EditableTableContextType<TableRecordType>>(EditableTableContext);
  if (!context) throw new Error('useEditableTable must be inside a Provider with a value');
  return context;
};
