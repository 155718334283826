import React, { FC } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import bestandseinheitAufteilungsschluesselTableColumns from './bestandseinheitAufteilungsschluesselTableColumns';
import { useBestandseinheitAufteilungsschluesselListQuery } from './gql/BestandseinheitAufteilungsschluesselQueries.types';
import BestandseinheitAufteilungsschluesselWertTable from './WertVersion/BestandseinheitAufteilungsschluesselWertTable';
import { Spacer } from '../../../components/Grid';
import { useTableExpandedRowKeys } from '../../../components/Table/shared/useTableExpandedRowKeys';
import { useToggle } from '../../../hooks/useToggle';
import TableWithColSelector from '../../../components/Table/TableWithColSelector/TableWithColSelector';
import { BeAufteilungsschluessel } from '../../../types';

type BestandseinheitAufteilungsschluesselTableProps = {
  objektId: string;
  bestandseinheitId: string;
};

const BestandseinheitAufteilungsschluesselTable: FC<BestandseinheitAufteilungsschluesselTableProps> = ({ objektId, bestandseinheitId }) => {
  const [showArchived, toggleShowArchived] = useToggle();
  const { data, refetch } = useBestandseinheitAufteilungsschluesselListQuery({
    variables: {
      objektId,
      bestandseinheitId,
      includeArchiviert: showArchived,
    },
  });
  const aufteilungsschluesselList = data?.getBeAufteilungsschluesselList.data;
  const loading = !aufteilungsschluesselList;

  const { expandedRowKeys, toggleExpandedRowKey } = useTableExpandedRowKeys();

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <Typography.Title level={5}>Aufteilungsschlüssel</Typography.Title>
        </Col>
        <Col flex="auto" />
        <Col>
          <Button size="small" onClick={toggleShowArchived}>
            Archivierte Einträge {showArchived ? 'ausblenden' : 'anzeigen'}
          </Button>
        </Col>
      </Row>
      <Spacer />
      <TableWithColSelector<BeAufteilungsschluessel>
        pagination={false}
        tableLayout="fixed"
        loading={loading}
        scroll={{ x: 900 }}
        size="small"
        columns={bestandseinheitAufteilungsschluesselTableColumns(toggleExpandedRowKey, expandedRowKeys)}
        dataSource={aufteilungsschluesselList}
        rowKey={(aufteilungsschluessel) => aufteilungsschluessel.aufteilungsschluesselId}
        expandable={{
          expandedRowKeys,
          onExpand: (expanded, record) => toggleExpandedRowKey(record.aufteilungsschluesselId),
          expandedRowRender: (record, index, indent, expanded) => (
            <BestandseinheitAufteilungsschluesselWertTable expanded={expanded} aufteilungsschluessel={record} onAction={refetch} />
          ),
        }}
        filterIdentifier="vertrag-bestandeinheit-aufteilungschluessel"
      />
    </>
  );
};

export default BestandseinheitAufteilungsschluesselTable;
