import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { UstKategorieFieldsFragmentDoc, UstKategorieEntryFieldsFragmentDoc } from '../../UstKategorie/gql/UstKategorieFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UstKategorieListForSelectByFirmendatenIdQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type UstKategorieListForSelectByFirmendatenIdQuery = {
  getUstKategorieListByFirmendatenId: {
    data: Array<{
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      ustKategorieId: string;
      ustVomAufwand?: number | null;
      vstKuerzung?: boolean | null;
      status?: { text: string; value: Types.UstKategorieStatus } | null;
      ustKategorieEntryList: Array<{
        additionKonto: number;
        buchungsKZ?: string | null;
        createTs?: string | null;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        rechnungskennzeichen: string;
        steuersatz: number;
        ustKategorieEntryId?: string | null;
        ustKz: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UstKategorieListForSelectByKundenSystemIdQueryVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type UstKategorieListForSelectByKundenSystemIdQuery = {
  getUstKategorieListByKundenSystemId: {
    data: Array<{
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      ustKategorieId: string;
      ustVomAufwand?: number | null;
      vstKuerzung?: boolean | null;
      status?: { text: string; value: Types.UstKategorieStatus } | null;
      ustKategorieEntryList: Array<{
        additionKonto: number;
        buchungsKZ?: string | null;
        createTs?: string | null;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        rechnungskennzeichen: string;
        steuersatz: number;
        ustKategorieEntryId?: string | null;
        ustKz: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const UstKategorieListForSelectByFirmendatenIdDocument = gql`
  query UstKategorieListForSelectByFirmendatenId($firmendatenId: ID!, $includeArchiviert: Boolean) {
    getUstKategorieListByFirmendatenId(firmendatenId: $firmendatenId, includeArchiviert: $includeArchiviert) {
      data {
        ...UstKategorieFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${UstKategorieFieldsFragmentDoc}
`;
export function useUstKategorieListForSelectByFirmendatenIdQuery(
  baseOptions: Apollo.QueryHookOptions<UstKategorieListForSelectByFirmendatenIdQuery, UstKategorieListForSelectByFirmendatenIdQueryVariables> &
    ({ variables: UstKategorieListForSelectByFirmendatenIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstKategorieListForSelectByFirmendatenIdQuery, UstKategorieListForSelectByFirmendatenIdQueryVariables>(
    UstKategorieListForSelectByFirmendatenIdDocument,
    options
  );
}
export function useUstKategorieListForSelectByFirmendatenIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UstKategorieListForSelectByFirmendatenIdQuery, UstKategorieListForSelectByFirmendatenIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstKategorieListForSelectByFirmendatenIdQuery, UstKategorieListForSelectByFirmendatenIdQueryVariables>(
    UstKategorieListForSelectByFirmendatenIdDocument,
    options
  );
}
export function useUstKategorieListForSelectByFirmendatenIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UstKategorieListForSelectByFirmendatenIdQuery, UstKategorieListForSelectByFirmendatenIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstKategorieListForSelectByFirmendatenIdQuery, UstKategorieListForSelectByFirmendatenIdQueryVariables>(
    UstKategorieListForSelectByFirmendatenIdDocument,
    options
  );
}
export type UstKategorieListForSelectByFirmendatenIdQueryHookResult = ReturnType<typeof useUstKategorieListForSelectByFirmendatenIdQuery>;
export type UstKategorieListForSelectByFirmendatenIdLazyQueryHookResult = ReturnType<typeof useUstKategorieListForSelectByFirmendatenIdLazyQuery>;
export type UstKategorieListForSelectByFirmendatenIdSuspenseQueryHookResult = ReturnType<
  typeof useUstKategorieListForSelectByFirmendatenIdSuspenseQuery
>;
export type UstKategorieListForSelectByFirmendatenIdQueryResult = Apollo.QueryResult<
  UstKategorieListForSelectByFirmendatenIdQuery,
  UstKategorieListForSelectByFirmendatenIdQueryVariables
>;
export const UstKategorieListForSelectByKundenSystemIdDocument = gql`
  query UstKategorieListForSelectByKundenSystemId($kundenSystemId: ID!, $includeArchiviert: Boolean) {
    getUstKategorieListByKundenSystemId(kundenSystemId: $kundenSystemId, includeArchiviert: $includeArchiviert) {
      data {
        ...UstKategorieFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${UstKategorieFieldsFragmentDoc}
`;
export function useUstKategorieListForSelectByKundenSystemIdQuery(
  baseOptions: Apollo.QueryHookOptions<UstKategorieListForSelectByKundenSystemIdQuery, UstKategorieListForSelectByKundenSystemIdQueryVariables> &
    ({ variables: UstKategorieListForSelectByKundenSystemIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstKategorieListForSelectByKundenSystemIdQuery, UstKategorieListForSelectByKundenSystemIdQueryVariables>(
    UstKategorieListForSelectByKundenSystemIdDocument,
    options
  );
}
export function useUstKategorieListForSelectByKundenSystemIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UstKategorieListForSelectByKundenSystemIdQuery, UstKategorieListForSelectByKundenSystemIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstKategorieListForSelectByKundenSystemIdQuery, UstKategorieListForSelectByKundenSystemIdQueryVariables>(
    UstKategorieListForSelectByKundenSystemIdDocument,
    options
  );
}
export function useUstKategorieListForSelectByKundenSystemIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UstKategorieListForSelectByKundenSystemIdQuery, UstKategorieListForSelectByKundenSystemIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstKategorieListForSelectByKundenSystemIdQuery, UstKategorieListForSelectByKundenSystemIdQueryVariables>(
    UstKategorieListForSelectByKundenSystemIdDocument,
    options
  );
}
export type UstKategorieListForSelectByKundenSystemIdQueryHookResult = ReturnType<typeof useUstKategorieListForSelectByKundenSystemIdQuery>;
export type UstKategorieListForSelectByKundenSystemIdLazyQueryHookResult = ReturnType<typeof useUstKategorieListForSelectByKundenSystemIdLazyQuery>;
export type UstKategorieListForSelectByKundenSystemIdSuspenseQueryHookResult = ReturnType<
  typeof useUstKategorieListForSelectByKundenSystemIdSuspenseQuery
>;
export type UstKategorieListForSelectByKundenSystemIdQueryResult = Apollo.QueryResult<
  UstKategorieListForSelectByKundenSystemIdQuery,
  UstKategorieListForSelectByKundenSystemIdQueryVariables
>;
