import * as Yup from 'yup';
import { entityIsRequired } from '../../../components/message/validationMsg';

/* eslint-disable no-template-curly-in-string */
const legalFormValidationSchema = Yup.string().required(entityIsRequired('Rechtsform'));

export const legalFormSelectValidationSchema = Yup.object().shape({
  legalForm: legalFormValidationSchema,
});

/* eslint-enable no-template-curly-in-string */
