import { FormFields } from '../../../../../../../helpers/formikHelper';
import { InfoFeldFormValues } from '../../../../../../shared/InfoFeld/infoFeldFormMapper';
import { KuendigungDurch } from '../../../../../../../types';

import { VertragInfoFeld_VertragInfoFeldKuendigungsinfos_Fragment } from '../../../gql/VertragInfoFeldFragments.types';

export interface KuendigungsinfosUpdateFormValues extends InfoFeldFormValues {
  kuendigungBis: string;
  kuendigungsverzichtBestandgeberBis?: string;
  kuendigungsverzichtBestandnehmerBis?: string;
  bestandnehmerKuendigungsfrist?: number;
  kuendigungJeweilsZum?: string;
  kuendigungMoeglichZum?: string;
  gerichtlicheKuendigung?: boolean;
  kuendigungErfolgtAm?: string;
  kuendigungWirksamZum?: string;
  kuendigungDurch?: KuendigungDurch;
  deleteDocumentFileIdList: string[];
}

export const kuendigungsinfosUpdateFormFields: FormFields<KuendigungsinfosUpdateFormValues> = {
  kuendigungBis: 'kuendigungBis',
  kuendigungsverzichtBestandgeberBis: 'kuendigungsverzichtBestandgeberBis',
  kuendigungsverzichtBestandnehmerBis: 'kuendigungsverzichtBestandnehmerBis',
  bestandnehmerKuendigungsfrist: 'bestandnehmerKuendigungsfrist',
  kuendigungJeweilsZum: 'kuendigungJeweilsZum',
  kuendigungMoeglichZum: 'kuendigungMoeglichZum',
  gerichtlicheKuendigung: 'gerichtlicheKuendigung',
  kuendigungErfolgtAm: 'kuendigungErfolgtAm',
  kuendigungWirksamZum: 'kuendigungWirksamZum',
  kuendigungDurch: 'kuendigungDurch',
  documentList: 'documentList',
  deleteDocumentFileIdList: 'deleteDocumentFileIdList',
};

export const mapToUpdateFormValues = (infoFeld: VertragInfoFeld_VertragInfoFeldKuendigungsinfos_Fragment): KuendigungsinfosUpdateFormValues => ({
  documentList: [],
  deleteDocumentFileIdList: [],
  kuendigungBis: infoFeld.kuendigungBis,
  kuendigungsverzichtBestandgeberBis: infoFeld.kuendigungsverzichtBestandgeberBis ?? undefined,
  kuendigungsverzichtBestandnehmerBis: infoFeld.kuendigungsverzichtBestandnehmerBis ?? undefined,
  bestandnehmerKuendigungsfrist: infoFeld.bestandnehmerKuendigungsfrist ?? undefined,
  kuendigungJeweilsZum: infoFeld.kuendigungJeweilsZum ?? undefined,
  kuendigungMoeglichZum: infoFeld.kuendigungMoeglichZum ?? undefined,
  gerichtlicheKuendigung: infoFeld.gerichtlicheKuendigung ?? undefined,
  kuendigungErfolgtAm: infoFeld.kuendigungErfolgtAm ?? undefined,
  kuendigungWirksamZum: infoFeld.kuendigungWirksamZum ?? undefined,
  kuendigungDurch: infoFeld.kuendigungDurch?.value ?? undefined,
});
