import React from 'react';
import { FireOutlined, HomeOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../../components/ContextSidebar/ContentWithSidebar';
import { generatePathToVerarbeitungBkTopAbrDetailsPage } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import VerarbeitungTopAbrechnung from '../../../features/Verarbeitung/TopAbrechnung/VerarbeitungTopAbrechnung';
import { verarbeitungPageAndMenuListTitles, verarbeitungTypes } from '../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungErrorAndWarningListing from '../../../features/Verarbeitung/shared/ErrorAndWarningListing/VerarbeitungErrorAndWarningListing';
import { AppGenerierlaufType, TopAbrechnungGenerierlauf } from '../../../types';

const menuListBkTopAbr = (topAbrId: string, generierlauf: TopAbrechnungGenerierlauf): GroupedMenueListInterface => {
  return {
    topMenuList: [
      {
        title: verarbeitungPageAndMenuListTitles.BkTopAbrechnung,
        icon: <HomeOutlined />,
        uri: `${generatePathToVerarbeitungBkTopAbrDetailsPage(topAbrId)}/entries`,
        content: () => (
          <VerarbeitungTopAbrechnung
            generierlauf={generierlauf}
            title={verarbeitungPageAndMenuListTitles.BkTopAbrechnung}
            type={verarbeitungTypes.BkTopAbrechnung}
            generierlaufType={AppGenerierlaufType.BkTopAbrechnung}
          />
        ),
      },
      {
        state: generierlauf.errorList.length > 0 || generierlauf.warningList.length > 0 ? 'error' : undefined,
        title: verarbeitungPageAndMenuListTitles.ErrorsAndWarnings,
        icon: <FireOutlined />,
        uri: `${generatePathToVerarbeitungBkTopAbrDetailsPage(topAbrId)}/errors-and-warnings`,
        content: () => <VerarbeitungErrorAndWarningListing errorList={generierlauf.errorList} warningList={generierlauf.warningList} />,
      },
    ],
  };
};

export default menuListBkTopAbr;
