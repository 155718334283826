import { Platzhalter, TextbausteinTypeTuple } from '../../../types';

export type TextbausteinFormValues = {
  index: number;
  platzhalterList: Platzhalter[];
  textbausteinList?: TextbausteinFormValues[];
  type: TextbausteinTypeTuple;
  value: string;
};

export type TextbausteinModel = {
  index: number;
  platzhalterList: Platzhalter[];
  textbausteinList?: TextbausteinModel[];
  type: TextbausteinTypeTuple;
  value?: string | null;
};

export const mapTextbausteinListToFormValues = (textbausteinList: TextbausteinModel[]): TextbausteinFormValues[] =>
  textbausteinList.map(mapTextbausteinToFormValues);

export const mapTextbausteinToFormValues = (textbaustein: TextbausteinModel): TextbausteinFormValues => {
  return {
    index: textbaustein.index,
    platzhalterList: textbaustein.platzhalterList,
    textbausteinList:
      textbaustein.textbausteinList && textbaustein.textbausteinList.length > 0
        ? mapTextbausteinListToFormValues(textbaustein.textbausteinList)
        : undefined,
    type: textbaustein.type,
    value: textbaustein.value ?? '',
  };
};
