import React from 'react';
import { TableWithAlignedColsExpandedRowType } from '../../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { HeAbrAbrechnungskreis, HeAbrKonto } from '../../../../../../types';
import TableWithAlignedColsHeaderRow from '../../../../../../components/Table/TableWithAlignedCols/TableWithAlignedColsHeaderRow';
import TableWithAlignedColsBody from '../../../../../../components/Table/TableWithAlignedCols/TableWithAlignedColsBody';
import heAbrHeVertragKontoRowColumns from './heAbrHeVertragKontoRowColumns';
import heAbrHeVertragKontoRowTable from './heAbrHeVertagKontoRowTable';

const heAbrHeVertragKontoRow = (
  abrKreisType: string,
  objektId: string,
  abrechnungszeitraumVon: string,
  abrechnungszeitraumBis: string,
  buchungskreisId: string
): TableWithAlignedColsExpandedRowType<HeAbrAbrechnungskreis>[] => [
  {
    header: (isOpen, setIsOpen) => (
      <TableWithAlignedColsHeaderRow
        isOpen={isOpen}
        bezeichnungHeaderText="Konto"
        sumHeaderText={[abrKreisType]}
        cellToUseForSumHeaderText={[1]}
        onClick={setIsOpen}
        startCell={0}
        endCell={3}
        positionOfButton={2}
      />
    ),
    body: (konto) => (
      <>
        {konto.kontoList
          .filter((konto) => konto.buchungszeileList.length > 0)
          .map((konto, index) => (
            <TableWithAlignedColsBody<HeAbrKonto>
              key={index}
              dataSource={konto}
              columns={heAbrHeVertragKontoRowColumns(objektId, abrechnungszeitraumVon, abrechnungszeitraumBis, buchungskreisId)}
              expandedRow={() => heAbrHeVertragKontoRowTable(abrKreisType)}
              positionOfButton={2}
            />
          ))}
      </>
    ),
  },
];

export default heAbrHeVertragKontoRow;
