import * as Types from '../../../types';

import { gql } from '@apollo/client';
import {
  AbrechnungsdefinitionListEntryFieldsFragmentDoc,
  KontoAbrechnungskreisFieldsFragmentDoc,
} from '../../Abrechnungsdefinition/gql/AbrDefFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQueryVariables = Types.Exact<{
  abrechnungsdefinitionVersionId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  kontoId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery = {
  getAbrechnungsdefinitionKontoAbrechnungskreisZuordnungList: {
    data: Array<{
      abrechnungsdefinitionVersionId: string;
      abrechnungsdefinition: {
        abrechnungsdefinitionId: string;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        textBausteineBefuellt: boolean;
        abrechnungsdefinitionVersion?: {
          abrechnungsdefinitionVersionId: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          abrechdefAbrechkreisList: Array<{
            abrechnungskreis: {
              abrechnungskreisId: string;
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              updatedByMitarbeiterId?: string | null;
              status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
              type: { text: string; value: Types.AbrechnungskreisType };
              verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            aufteilungsschluessel?: {
              aufteilungsschluesselId: string;
              bezeichnung: string;
              bezugsbasisEditable?: boolean | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              kurzBezeichnung: string;
              updatedByMitarbeiterId?: string | null;
              bezugsbasis: { text: string; value: Types.Bezugsbasis };
              masseinheit: { text: string; value: Types.Masseinheit };
              status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            vorschreibungsposition?: {
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              kurzBezeichnung: string;
              tagList: Array<string>;
              updatedByMitarbeiterId?: string | null;
              vorschreibungspositionId: string;
              art: { text: string; value: Types.VorschreibungspositionArt };
              kontierungstabelle?: {
                basiskonto: number;
                bezeichnung: string;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                kontierungstabelleId: string;
                updatedByMitarbeiterId?: string | null;
                status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
                subAdministrationExpenseAccount?: {
                  bezeichnung: string;
                  firmendatenId?: string | null;
                  kontoId: string;
                  kontoKlasse: string;
                  nummer: string;
                } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
              umsatzsteuerkennzeichen?: {
                bezeichnung: string;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                kuerzel: string;
                umsatzsteuerkennzeichenId: string;
                status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
                warningList: Array<{ message: string; type: string; attribute?: string | null }>;
              } | null;
              warningList: Array<{ message: string; type: string; attribute?: string | null }>;
            } | null;
          }>;
          kontoAbrechnungskreisList: Array<{
            abrechnen: boolean;
            buchungszeilenAnzeigen: boolean;
            expenseReducing: boolean;
            ustVomAufwand: boolean;
            abrechnungskreis?: {
              abrechnungskreisId: string;
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              updatedByMitarbeiterId?: string | null;
              status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
              type: { text: string; value: Types.AbrechnungskreisType };
              verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            aufteilungsschluessel?: {
              aufteilungsschluesselId: string;
              bezeichnung: string;
              bezugsbasisEditable?: boolean | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              kurzBezeichnung: string;
              updatedByMitarbeiterId?: string | null;
              bezugsbasis: { text: string; value: Types.Bezugsbasis };
              masseinheit: { text: string; value: Types.Masseinheit };
              status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
          }>;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status?: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus } | null;
        type: { text: string; value: Types.AbrechnungsdefinitionType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      kontoAbrechnungskreisList: Array<{
        abrechnen: boolean;
        buchungszeilenAnzeigen: boolean;
        expenseReducing: boolean;
        ustVomAufwand: boolean;
        abrechnungskreis?: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
}>;

export type AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdQuery = {
  getAbrechnungsdefinitionListByFirmendatenId: {
    data: {
      contentList: Array<{
        abrechnungsdefinitionId: string;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        textBausteineBefuellt: boolean;
        abrechnungsdefinitionVersion?: {
          abrechnungsdefinitionVersionId: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          abrechdefAbrechkreisList: Array<{
            abrechnungskreis: {
              abrechnungskreisId: string;
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              updatedByMitarbeiterId?: string | null;
              status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
              type: { text: string; value: Types.AbrechnungskreisType };
              verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            aufteilungsschluessel?: {
              aufteilungsschluesselId: string;
              bezeichnung: string;
              bezugsbasisEditable?: boolean | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              kurzBezeichnung: string;
              updatedByMitarbeiterId?: string | null;
              bezugsbasis: { text: string; value: Types.Bezugsbasis };
              masseinheit: { text: string; value: Types.Masseinheit };
              status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            vorschreibungsposition?: {
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              kurzBezeichnung: string;
              tagList: Array<string>;
              updatedByMitarbeiterId?: string | null;
              vorschreibungspositionId: string;
              art: { text: string; value: Types.VorschreibungspositionArt };
              kontierungstabelle?: {
                basiskonto: number;
                bezeichnung: string;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                kontierungstabelleId: string;
                updatedByMitarbeiterId?: string | null;
                status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
                subAdministrationExpenseAccount?: {
                  bezeichnung: string;
                  firmendatenId?: string | null;
                  kontoId: string;
                  kontoKlasse: string;
                  nummer: string;
                } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
              umsatzsteuerkennzeichen?: {
                bezeichnung: string;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                kuerzel: string;
                umsatzsteuerkennzeichenId: string;
                status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
                warningList: Array<{ message: string; type: string; attribute?: string | null }>;
              } | null;
              warningList: Array<{ message: string; type: string; attribute?: string | null }>;
            } | null;
          }>;
          kontoAbrechnungskreisList: Array<{
            abrechnen: boolean;
            buchungszeilenAnzeigen: boolean;
            expenseReducing: boolean;
            ustVomAufwand: boolean;
            abrechnungskreis?: {
              abrechnungskreisId: string;
              bezeichnung: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              updatedByMitarbeiterId?: string | null;
              status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
              type: { text: string; value: Types.AbrechnungskreisType };
              verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            aufteilungsschluessel?: {
              aufteilungsschluesselId: string;
              bezeichnung: string;
              bezugsbasisEditable?: boolean | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              firmendatenId?: string | null;
              kurzBezeichnung: string;
              updatedByMitarbeiterId?: string | null;
              bezugsbasis: { text: string; value: Types.Bezugsbasis };
              masseinheit: { text: string; value: Types.Masseinheit };
              status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
          }>;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status?: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus } | null;
        type: { text: string; value: Types.AbrechnungsdefinitionType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListDocument = gql`
  query AbrechnungsdefinitionKontoAbrechnungskreisZuordnungList($abrechnungsdefinitionVersionId: String, $kontoId: String) {
    getAbrechnungsdefinitionKontoAbrechnungskreisZuordnungList(abrechnungsdefinitionVersionId: $abrechnungsdefinitionVersionId, kontoId: $kontoId) {
      data {
        abrechnungsdefinition {
          ...AbrechnungsdefinitionListEntryFields
        }
        abrechnungsdefinitionVersionId
        kontoAbrechnungskreisList {
          ...KontoAbrechnungskreisFields
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AbrechnungsdefinitionListEntryFieldsFragmentDoc}
  ${KontoAbrechnungskreisFieldsFragmentDoc}
`;
export function useAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery,
    AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery,
    AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQueryVariables
  >(AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListDocument, options);
}
export function useAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery,
    AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery,
    AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQueryVariables
  >(AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListDocument, options);
}
export function useAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery,
        AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery,
    AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQueryVariables
  >(AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListDocument, options);
}
export type AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery
>;
export type AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListLazyQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListLazyQuery
>;
export type AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListSuspenseQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionKontoAbrechnungskreisZuordnungListSuspenseQuery
>;
export type AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQueryResult = Apollo.QueryResult<
  AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQuery,
  AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListQueryVariables
>;
export const AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdDocument = gql`
  query AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenId($firmendatenId: String!) {
    getAbrechnungsdefinitionListByFirmendatenId(firmendatenId: $firmendatenId) {
      data {
        contentList {
          ...AbrechnungsdefinitionListEntryFields
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${AbrechnungsdefinitionListEntryFieldsFragmentDoc}
`;
export function useAbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdQuery,
    AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdQueryVariables
  > &
    ({ variables: AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdQuery,
    AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdQueryVariables
  >(AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdDocument, options);
}
export function useAbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdQuery,
    AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdQuery,
    AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdQueryVariables
  >(AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdDocument, options);
}
export function useAbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdQuery,
        AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdQuery,
    AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdQueryVariables
  >(AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdDocument, options);
}
export type AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdQuery
>;
export type AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdLazyQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdLazyQuery
>;
export type AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdSuspenseQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdSuspenseQuery
>;
export type AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdQueryResult = Apollo.QueryResult<
  AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdQuery,
  AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenIdQueryVariables
>;
