import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestartPaymentProposalCreationGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartPaymentProposalCreationGenerierlaufMutation = {
  actionRestartPaymentProposalCreationGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type RestartPaymentProposalExecutionGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartPaymentProposalExecutionGenerierlaufMutation = {
  actionRestartPaymentProposalExecutionGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreatePaymentProposalCreationGenerierlaufMutationVariables = Types.Exact<{
  input: Types.PaymentProposalCreationGenerierlaufCreateInput;
}>;

export type CreatePaymentProposalCreationGenerierlaufMutation = {
  createPaymentProposalCreationGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreatePaymentProposalExecutionGenerierlaufMutationVariables = Types.Exact<{
  input: Types.PaymentProposalExecutionGenerierlaufCreateInput;
}>;

export type CreatePaymentProposalExecutionGenerierlaufMutation = {
  createPaymentProposalExecutionGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RestartPaymentProposalCreationGenerierlaufDocument = gql`
  mutation RestartPaymentProposalCreationGenerierlauf($generierlaufId: ID!) {
    actionRestartPaymentProposalCreationGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartPaymentProposalCreationGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestartPaymentProposalCreationGenerierlaufMutation,
    RestartPaymentProposalCreationGenerierlaufMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestartPaymentProposalCreationGenerierlaufMutation, RestartPaymentProposalCreationGenerierlaufMutationVariables>(
    RestartPaymentProposalCreationGenerierlaufDocument,
    options
  );
}
export type RestartPaymentProposalCreationGenerierlaufMutationHookResult = ReturnType<typeof useRestartPaymentProposalCreationGenerierlaufMutation>;
export type RestartPaymentProposalCreationGenerierlaufMutationResult = Apollo.MutationResult<RestartPaymentProposalCreationGenerierlaufMutation>;
export type RestartPaymentProposalCreationGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartPaymentProposalCreationGenerierlaufMutation,
  RestartPaymentProposalCreationGenerierlaufMutationVariables
>;
export const RestartPaymentProposalExecutionGenerierlaufDocument = gql`
  mutation RestartPaymentProposalExecutionGenerierlauf($generierlaufId: ID!) {
    actionRestartPaymentProposalExecutionGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartPaymentProposalExecutionGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestartPaymentProposalExecutionGenerierlaufMutation,
    RestartPaymentProposalExecutionGenerierlaufMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestartPaymentProposalExecutionGenerierlaufMutation, RestartPaymentProposalExecutionGenerierlaufMutationVariables>(
    RestartPaymentProposalExecutionGenerierlaufDocument,
    options
  );
}
export type RestartPaymentProposalExecutionGenerierlaufMutationHookResult = ReturnType<typeof useRestartPaymentProposalExecutionGenerierlaufMutation>;
export type RestartPaymentProposalExecutionGenerierlaufMutationResult = Apollo.MutationResult<RestartPaymentProposalExecutionGenerierlaufMutation>;
export type RestartPaymentProposalExecutionGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartPaymentProposalExecutionGenerierlaufMutation,
  RestartPaymentProposalExecutionGenerierlaufMutationVariables
>;
export const CreatePaymentProposalCreationGenerierlaufDocument = gql`
  mutation CreatePaymentProposalCreationGenerierlauf($input: PaymentProposalCreationGenerierlaufCreateInput!) {
    createPaymentProposalCreationGenerierlauf(input: $input) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreatePaymentProposalCreationGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaymentProposalCreationGenerierlaufMutation,
    CreatePaymentProposalCreationGenerierlaufMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePaymentProposalCreationGenerierlaufMutation, CreatePaymentProposalCreationGenerierlaufMutationVariables>(
    CreatePaymentProposalCreationGenerierlaufDocument,
    options
  );
}
export type CreatePaymentProposalCreationGenerierlaufMutationHookResult = ReturnType<typeof useCreatePaymentProposalCreationGenerierlaufMutation>;
export type CreatePaymentProposalCreationGenerierlaufMutationResult = Apollo.MutationResult<CreatePaymentProposalCreationGenerierlaufMutation>;
export type CreatePaymentProposalCreationGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentProposalCreationGenerierlaufMutation,
  CreatePaymentProposalCreationGenerierlaufMutationVariables
>;
export const CreatePaymentProposalExecutionGenerierlaufDocument = gql`
  mutation CreatePaymentProposalExecutionGenerierlauf($input: PaymentProposalExecutionGenerierlaufCreateInput!) {
    createPaymentProposalExecutionGenerierlauf(input: $input) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreatePaymentProposalExecutionGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaymentProposalExecutionGenerierlaufMutation,
    CreatePaymentProposalExecutionGenerierlaufMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePaymentProposalExecutionGenerierlaufMutation, CreatePaymentProposalExecutionGenerierlaufMutationVariables>(
    CreatePaymentProposalExecutionGenerierlaufDocument,
    options
  );
}
export type CreatePaymentProposalExecutionGenerierlaufMutationHookResult = ReturnType<typeof useCreatePaymentProposalExecutionGenerierlaufMutation>;
export type CreatePaymentProposalExecutionGenerierlaufMutationResult = Apollo.MutationResult<CreatePaymentProposalExecutionGenerierlaufMutation>;
export type CreatePaymentProposalExecutionGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentProposalExecutionGenerierlaufMutation,
  CreatePaymentProposalExecutionGenerierlaufMutationVariables
>;
