import React, { FC } from 'react';
import TableWithAlignedColsBody from '../../../../../../components/Table/TableWithAlignedCols/TableWithAlignedColsBody';
import einnahmenKontoTableColumns from './einnahmenKontoTableColumns';
import einnahmenKontoTableExpandedRow from './einnahmenKontoTableExpandedRow';
import { ObjAbrKonto } from '../../../../../../types';

type Props = {
  konto: ObjAbrKonto;
  fromInclusive: string;
  toInclusive: string;
  objektId: string;
  buchungskreisId: string;
};

const EinnahmenKontoTable: FC<Props> = ({ konto, fromInclusive, toInclusive, objektId, buchungskreisId }) => {
  return (
    <TableWithAlignedColsBody<ObjAbrKonto>
      dataSource={konto}
      columns={einnahmenKontoTableColumns(objektId, fromInclusive, toInclusive, buchungskreisId)}
      expandedRow={() => einnahmenKontoTableExpandedRow}
      positionOfButton={2}
    />
  );
};

export default EinnahmenKontoTable;
