import { Text } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles, { selectableStyles } from '../../styles/pdfStyles';
import { ObjektAbrechnung } from '../objektAbrechnung-types';
import { ObjektAbrechnungSelectableSection } from '../ObjektAbrechnungTemplate';

type OATitleTextProps = {
  data: ObjektAbrechnung;
  isInEditMode?: boolean;
  selectedTextbaustein?: ObjektAbrechnungSelectableSection;
};

const OATitleText: FC<OATitleTextProps> = ({ data, isInEditMode, selectedTextbaustein }) => {
  const abrechnungsTitel = data.abrechnungsTitel;

  // if in edit mode then we need to render the platzhalter also if it is an empty string so that the user see where the textbaustein will occur in the PDF
  if (isInEditMode) {
    return (
      <Text
        style={[
          pdfStyles.textNormal,
          {
            marginTop: '10mm',
            textAlign: 'center',
          },
          selectableStyles(isInEditMode, selectedTextbaustein === 'ABRECHNUNGSTITEL'),
        ]}
      >
        {abrechnungsTitel}
      </Text>
    );
  }
  return abrechnungsTitel === '' ? null : (
    <Text
      style={[
        pdfStyles.textNormal,
        {
          marginTop: '10mm',
          textAlign: 'center',
        },
      ]}
    >
      {abrechnungsTitel}
    </Text>
  );
};
export default OATitleText;
