import React from 'react';
import { Layout } from 'antd';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router';
import Breadcrumbs from '../../features/Breadcrumb';
import { Spacer } from '../../components/Grid';
import AndromedaSysSettingsSider from './AndromedaSysSettingsSider';

const AndromedaSystemSettingsPage: React.FC = () => (
  <Layout.Content>
    <Helmet>
      <title>Andromeda Einstellungen</title>
    </Helmet>
    <Breadcrumbs />
    <Spacer height={16} />
    <Layout style={{ padding: '0 16px 0 16px' }}>
      <AndromedaSysSettingsSider />
      <Layout.Content style={{ padding: '16px 24px', background: '#fff', minHeight: 280 }}>
        <Outlet />
      </Layout.Content>
    </Layout>
  </Layout.Content>
);

export default AndromedaSystemSettingsPage;
