import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type KontierungstabelleFieldsFragment = {
  basiskonto: number;
  bezeichnung: string;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  firmendatenId?: string | null;
  kontierungstabelleId: string;
  updatedByMitarbeiterId?: string | null;
  errechnetesErloesKontoList: Array<{ buchungsKZ?: string | null; erloeskonto: number; firmendatenId?: string | null; steuersatz: number }>;
  status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
  subAdministrationExpenseAccount?: {
    bezeichnung: string;
    firmendatenId?: string | null;
    kontoId: string;
    kontoKlasse: string;
    nummer: string;
  } | null;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type KontierungstabelleBaseFieldsFragment = {
  basiskonto: number;
  bezeichnung: string;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  kontierungstabelleId: string;
  updatedByMitarbeiterId?: string | null;
  status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
  subAdministrationExpenseAccount?: {
    bezeichnung: string;
    firmendatenId?: string | null;
    kontoId: string;
    kontoKlasse: string;
    nummer: string;
  } | null;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const KontierungstabelleFieldsFragmentDoc = gql`
  fragment KontierungstabelleFields on Kontierungstabelle {
    basiskonto
    bezeichnung
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    errechnetesErloesKontoList {
      buchungsKZ
      erloeskonto
      firmendatenId
      steuersatz
    }
    firmendatenId
    kontierungstabelleId
    status {
      description
      text
      value
    }
    subAdministrationExpenseAccount {
      bezeichnung
      firmendatenId
      kontoId
      kontoKlasse
      nummer
    }
    updatedByMitarbeiterId
    warningList {
      attribute
      message
      type
    }
  }
`;
export const KontierungstabelleBaseFieldsFragmentDoc = gql`
  fragment KontierungstabelleBaseFields on KontierungstabelleBase {
    basiskonto
    bezeichnung
    createTs
    createdBy
    createdByMitarbeiterId
    kontierungstabelleId
    status {
      description
      text
      value
    }
    subAdministrationExpenseAccount {
      bezeichnung
      firmendatenId
      kontoId
      kontoKlasse
      nummer
    }
    updatedByMitarbeiterId
    warningList {
      attribute
      message
      type
    }
  }
`;
