import React, { FC } from 'react';
import { Alert, Button, Space } from 'antd';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { BookingDetailsWithBookingSuggestionPageQueryParams } from '../../bookingDetailsPaths';
import BookingCorrectionWarningWithLinkToNewBooking from './BookingCorrectionWarningWithLinkToNewBooking';

type Props = {
  bookingCorrectionInProgress: boolean | 'done';
  bookingSuggestionId: string;
  isEditAllowed: boolean;
  toggleIsUpdate: () => void;
};

const BookingGeneralButtonAndWarning: FC<Props> = ({ bookingCorrectionInProgress, bookingSuggestionId, isEditAllowed, toggleIsUpdate }) => {
  const { showCorrectionWarning } = useQueryParams<BookingDetailsWithBookingSuggestionPageQueryParams>();

  const buttonContent = isEditAllowed ? (
    <Button type="primary" htmlType="submit" onClick={toggleIsUpdate}>
      Buchung bearbeiten
    </Button>
  ) : null;

  const alertContent =
    bookingCorrectionInProgress === 'done' ? (
      <BookingCorrectionWarningWithLinkToNewBooking bookingSuggestionId={bookingSuggestionId} />
    ) : (
      <Alert message="Die Buchung wird korrigiert" type="warning" showIcon />
    );

  const content = bookingCorrectionInProgress && showCorrectionWarning ? alertContent : buttonContent;

  return (
    <Space direction="vertical" size="middle" align="end" style={{ width: '100%', height: '100%', justifyContent: 'flex-start' }}>
      {content}
    </Space>
  );
};

export default BookingGeneralButtonAndWarning;
