import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../styles/pdfStyles';
import { ObjektAbrechnung } from '../objektAbrechnung-types';

const OAZeitraum: FC<{ data: ObjektAbrechnung }> = ({ data }) => (
  <View style={[pdfStyles.row, { paddingTop: '3mm' }]}>
    <Text style={[pdfStyles.textList, pdfStyles.column, { width: '50%' }]}>{data.kopf.zeitraumText}</Text>
    <View style={[pdfStyles.column, { width: '30%' }]}>
      <Text style={[pdfStyles.textList, pdfStyles.column, { width: '100%', textAlign: 'left' }]}>
        {data.kopf.zeitraumVon} bis {data.kopf.zeitraumBis}
      </Text>
    </View>
    <Text style={[pdfStyles.textList, pdfStyles.column, { width: '10%', textAlign: 'right' }]}>{data.kopf.erstellungsDatum}</Text>
  </View>
);

export default OAZeitraum;
