import { FC } from 'react';
import { List, Space } from 'antd';
import { isVertragInfoFeldKuendigungsinfos, isVertragInfoFeldWeiterePerson } from './vertragInfoFeldListHelpers';
import WeiterePersonListItem from './ListItem/WeiterePerson/WeiterePersonListItem';
import KuendigungsinfosListItem from './ListItem/Kuendigungsinfos/KuendigungsinfosListItem';
import {
  VertragInfoFeld_VertragInfoFeldKuendigungsinfos_Fragment,
  VertragInfoFeld_VertragInfoFeldWeiterePerson_Fragment,
  VertragInfoFeldFragment,
} from '../gql/VertragInfoFeldFragments.types';

type Props = {
  loading: boolean;
  infoFeldList: VertragInfoFeldFragment[];
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  onSuccess: () => void;
};

const VertragInfoFeldList: FC<Props> = ({ infoFeldList, loading, objektId, bestandseinheitId, vertragId, onSuccess }) => {
  const weiterePersonList = infoFeldList.filter(isVertragInfoFeldWeiterePerson);
  const kuendigungsInfoList = infoFeldList.filter(isVertragInfoFeldKuendigungsinfos);

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={16}>
      {weiterePersonList && weiterePersonList.length > 0 && (
        <List<VertragInfoFeldFragment>
          bordered
          loading={loading}
          dataSource={weiterePersonList}
          renderItem={(item) => (
            <WeiterePersonListItem
              objektId={objektId}
              bestandseinheitId={bestandseinheitId}
              vertragId={vertragId}
              onSuccess={onSuccess}
              weiterePerson={item as VertragInfoFeld_VertragInfoFeldWeiterePerson_Fragment}
            />
          )}
        />
      )}
      {kuendigungsInfoList && kuendigungsInfoList.length > 0 && (
        <List<VertragInfoFeldFragment>
          bordered
          loading={loading}
          dataSource={kuendigungsInfoList}
          renderItem={(item) => (
            <KuendigungsinfosListItem
              objektId={objektId}
              bestandseinheitId={bestandseinheitId}
              vertragId={vertragId}
              onSuccess={onSuccess}
              kuendigungsinfos={item as VertragInfoFeld_VertragInfoFeldKuendigungsinfos_Fragment}
            />
          )}
        />
      )}
    </Space>
  );
};

export default VertragInfoFeldList;
