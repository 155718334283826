import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
import { AbrInfoAbrechnungskreisFieldsFragmentDoc } from '../../../gql/AbrechnungFragments.types';
import { BestandseinheitBaseFieldsFragmentDoc } from '../../../../Bestandseinheit/gql/BestandseinheitFragments.types';
import { AppKontoFieldsFragmentDoc } from '../../../../Kontenplan/gql/KontenplanFragments.types';
export type TopAbrechnungFieldsFragment = {
  aufwand: number;
  bestandseinheitBezeichnung?: string | null;
  bestandseinheitId: string;
  bezeichnung: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  dueDate?: string | null;
  einleitungsText: string;
  einnahmen: number;
  ergebnisText: string;
  fileId?: string | null;
  fromInclusive: string;
  guthaben: boolean;
  objektId: string;
  objektKurzBezeichnung: string;
  rueckstandBestandnehmer: number;
  saldo: number;
  schlussText: string;
  toInclusive: string;
  topAbrechnungId: string;
  abrechnungskreisList: Array<{
    abrechnungskreisId: string;
    aufteilungsschluesselBezeichnung?: string | null;
    aufwand: number;
    aufwandGesamt: number;
    aufwandProzent?: number | null;
    bezeichnung: string;
    brutto: number;
    einnahmen: number;
    netto: number;
    steuersatz: number;
    ust: number;
    ustKategorieEntryId: string;
    ustKategorieId: string;
    ustKategorieVersionId: string;
    abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
    aufwandAufteilungList: Array<{
      aufteilungsschluesselBezeichnung: string;
      summe: number;
      kontoList: Array<{ bezeichnung: string; kontoId: string; summe: number }>;
      zeitscheibeAufwandList: Array<{
        anteilAufwand: number;
        aufwand: number;
        beAufteilungsschluesselWert?: number | null;
        bisInklusive: string;
        prozentAufteilungsschluesselAnteil: number;
        sumAufteilungsschluesselWert: number;
        vonInklusive: string;
      }>;
    }>;
    einnahmenBuchungszeileList: Array<{
      auftragId?: string | null;
      belegDatum: string;
      belegFileId?: string | null;
      belegId?: string | null;
      belegnummer: string;
      betrag: number;
      buchungId: string;
      buchungskreisId: string;
      dueDate: string;
      kontoBezeichnung: string;
      kontoId: string;
      kontoNummer: string;
      offenerBetrag: number;
      text: string;
      buchungskreisRechtstraeger: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      sollHaben: { text: string; value: Types.SollHaben };
      vertragspartner: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
    }>;
    ustKategorieStatus: { description?: string | null; text: string; value: Types.UstKategorieStatus };
    ustVomAufwandAnteilList: Array<{
      anteilNetto: number;
      basis: number;
      belastung: number;
      summe: number;
      ustVomAufwand: number;
      ustVomAufwandSteuersatz: number;
      grundlage: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand };
    }>;
  }>;
  dueDateDisplayOption?: { month?: number | null; type: { text: string; value: Types.DueDateDisplayOptionType } } | null;
  dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
  infoAbrechnungskreisList: Array<{
    abrechnungsdefinitionId: string;
    abrechnungsdefinitionVersionId: string;
    abrechnungskreisId: string;
    bezeichnung: string;
    saldo: number;
    sumAufwand: number;
    vorsaldo: number;
    aufwand: {
      summe: number;
      kontoList: Array<{
        bezeichnung: string;
        kontoId: string;
        summe: number;
        buchungszeileList: Array<{
          auftragId?: string | null;
          belegDatum?: string | null;
          belegFileId?: string | null;
          belegId: string;
          belegnummer?: string | null;
          betrag: number;
          buchungIdList: Array<string>;
          dueDate?: string | null;
          standingInvoicePartialAmountId?: string | null;
          text: string;
          umbuchung?: boolean | null;
          umgebucht?: boolean | null;
          buchungskreisRechtstraeger: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          sollHaben: { text: string; value: Types.SollHaben };
          vertragspartner?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
        }>;
      }>;
    };
    erloes: {
      summe: number;
      kontoList: Array<{
        bezeichnung: string;
        kontoId: string;
        summe: number;
        buchungszeileList: Array<{
          auftragId?: string | null;
          belegDatum?: string | null;
          belegFileId?: string | null;
          belegId: string;
          belegnummer?: string | null;
          betrag: number;
          buchungIdList: Array<string>;
          dueDate?: string | null;
          standingInvoicePartialAmountId?: string | null;
          text: string;
          umbuchung?: boolean | null;
          umgebucht?: boolean | null;
          buchungskreisRechtstraeger: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          sollHaben: { text: string; value: Types.SollHaben };
          vertragspartner?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
        }>;
      }>;
    };
  }>;
  paymentMethod: { text: string; value: Types.PaymentMethod };
  rechnungsAussteller: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  status: { description?: string | null; text: string; value: Types.TopAbrechnungStatus };
  type: { text: string; value: Types.AbrechnungType };
  verrechnungsart: { text: string; value: Types.Verrechnungsart };
  vertragspartner: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type TopAbrechnungOverviewFieldsFragment = {
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  fileId?: string | null;
  fromInclusive: string;
  objektId: string;
  objektKurzBezeichnung: string;
  toInclusive: string;
  errorList: Array<{ message: string; type: string }>;
  topAbrechnungList: Array<{
    aufwand: number;
    bestandseinheitBezeichnung?: string | null;
    bestandseinheitId: string;
    bezeichnung: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    dueDate?: string | null;
    einleitungsText: string;
    einnahmen: number;
    ergebnisText: string;
    fileId?: string | null;
    fromInclusive: string;
    guthaben: boolean;
    objektId: string;
    objektKurzBezeichnung: string;
    rueckstandBestandnehmer: number;
    saldo: number;
    schlussText: string;
    toInclusive: string;
    topAbrechnungId: string;
    abrechnungskreisList: Array<{
      abrechnungskreisId: string;
      aufteilungsschluesselBezeichnung?: string | null;
      aufwand: number;
      aufwandGesamt: number;
      aufwandProzent?: number | null;
      bezeichnung: string;
      brutto: number;
      einnahmen: number;
      netto: number;
      steuersatz: number;
      ust: number;
      ustKategorieEntryId: string;
      ustKategorieId: string;
      ustKategorieVersionId: string;
      abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
      aufwandAufteilungList: Array<{
        aufteilungsschluesselBezeichnung: string;
        summe: number;
        kontoList: Array<{ bezeichnung: string; kontoId: string; summe: number }>;
        zeitscheibeAufwandList: Array<{
          anteilAufwand: number;
          aufwand: number;
          beAufteilungsschluesselWert?: number | null;
          bisInklusive: string;
          prozentAufteilungsschluesselAnteil: number;
          sumAufteilungsschluesselWert: number;
          vonInklusive: string;
        }>;
      }>;
      einnahmenBuchungszeileList: Array<{
        auftragId?: string | null;
        belegDatum: string;
        belegFileId?: string | null;
        belegId?: string | null;
        belegnummer: string;
        betrag: number;
        buchungId: string;
        buchungskreisId: string;
        dueDate: string;
        kontoBezeichnung: string;
        kontoId: string;
        kontoNummer: string;
        offenerBetrag: number;
        text: string;
        buchungskreisRechtstraeger: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        sollHaben: { text: string; value: Types.SollHaben };
        vertragspartner: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
      }>;
      ustKategorieStatus: { description?: string | null; text: string; value: Types.UstKategorieStatus };
      ustVomAufwandAnteilList: Array<{
        anteilNetto: number;
        basis: number;
        belastung: number;
        summe: number;
        ustVomAufwand: number;
        ustVomAufwandSteuersatz: number;
        grundlage: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand };
      }>;
    }>;
    dueDateDisplayOption?: { month?: number | null; type: { text: string; value: Types.DueDateDisplayOptionType } } | null;
    dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
    infoAbrechnungskreisList: Array<{
      abrechnungsdefinitionId: string;
      abrechnungsdefinitionVersionId: string;
      abrechnungskreisId: string;
      bezeichnung: string;
      saldo: number;
      sumAufwand: number;
      vorsaldo: number;
      aufwand: {
        summe: number;
        kontoList: Array<{
          bezeichnung: string;
          kontoId: string;
          summe: number;
          buchungszeileList: Array<{
            auftragId?: string | null;
            belegDatum?: string | null;
            belegFileId?: string | null;
            belegId: string;
            belegnummer?: string | null;
            betrag: number;
            buchungIdList: Array<string>;
            dueDate?: string | null;
            standingInvoicePartialAmountId?: string | null;
            text: string;
            umbuchung?: boolean | null;
            umgebucht?: boolean | null;
            buchungskreisRechtstraeger: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            sollHaben: { text: string; value: Types.SollHaben };
            vertragspartner?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
          }>;
        }>;
      };
      erloes: {
        summe: number;
        kontoList: Array<{
          bezeichnung: string;
          kontoId: string;
          summe: number;
          buchungszeileList: Array<{
            auftragId?: string | null;
            belegDatum?: string | null;
            belegFileId?: string | null;
            belegId: string;
            belegnummer?: string | null;
            betrag: number;
            buchungIdList: Array<string>;
            dueDate?: string | null;
            standingInvoicePartialAmountId?: string | null;
            text: string;
            umbuchung?: boolean | null;
            umgebucht?: boolean | null;
            buchungskreisRechtstraeger: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            sollHaben: { text: string; value: Types.SollHaben };
            vertragspartner?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
          }>;
        }>;
      };
    }>;
    paymentMethod: { text: string; value: Types.PaymentMethod };
    rechnungsAussteller: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    status: { description?: string | null; text: string; value: Types.TopAbrechnungStatus };
    type: { text: string; value: Types.AbrechnungType };
    verrechnungsart: { text: string; value: Types.Verrechnungsart };
    vertragspartner: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }>;
  zahlungsrueckstandList?: Array<{
    offenerBetrag: number;
    letzteBuchungAm?: string | null;
    bestandseinheit: {
      bestandseinheitId: string;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      objektId: string;
      status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    rechtstraeger: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
  }> | null;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type TopAbrechnungOutputOptionsFieldsFragment = {
  considerRueckstand: boolean;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  displayDueDate: boolean;
  displayRueckstandOtherPersons: boolean;
  displayZahlungsverlauf: boolean;
  rueckstandOtherPersonsThreshold?: number | null;
  updatedByMitarbeiterId?: string | null;
  dueDateDisplayOptionEigentuemerGuthaben?: { month?: number | null; type: { text: string; value: Types.DueDateDisplayOptionType } } | null;
  dueDateDisplayOptionEigentuemerNachzahlung?: { month?: number | null; type: { text: string; value: Types.DueDateDisplayOptionType } } | null;
  dueDateDisplayOptionMieter?: { month?: number | null; type: { text: string; value: Types.DueDateDisplayOptionType } } | null;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const TopAbrechnungFieldsFragmentDoc = gql`
  fragment TopAbrechnungFields on TopAbrechnung {
    abrechnungskreisList {
      abrechnungskreisId
      abrechnungskreisType {
        text
        value
      }
      aufwandAufteilungList {
        aufteilungsschluesselBezeichnung
        kontoList {
          bezeichnung
          kontoId
          summe
        }
        summe
        zeitscheibeAufwandList {
          anteilAufwand
          aufwand
          beAufteilungsschluesselWert
          bisInklusive
          prozentAufteilungsschluesselAnteil
          sumAufteilungsschluesselWert
          vonInklusive
        }
      }
      aufteilungsschluesselBezeichnung
      aufwand
      aufwandGesamt
      aufwandProzent
      bezeichnung
      brutto
      einnahmen
      einnahmenBuchungszeileList {
        auftragId
        belegDatum
        belegFileId
        belegId
        belegnummer
        betrag
        buchungId
        buchungskreisId
        buchungskreisRechtstraeger {
          ...RechtstraegerBaseFields
        }
        dueDate
        kontoBezeichnung
        kontoId
        kontoNummer
        offenerBetrag
        sollHaben {
          text
          value
        }
        text
        vertragspartner {
          ...RechtstraegerBaseFields
        }
      }
      netto
      steuersatz
      ust
      ustKategorieEntryId
      ustKategorieId
      ustKategorieStatus {
        description
        text
        value
      }
      ustKategorieVersionId
      ustVomAufwandAnteilList {
        anteilNetto
        basis
        belastung
        grundlage {
          description
          text
          value
        }
        summe
        ustVomAufwand
        ustVomAufwandSteuersatz
      }
    }
    aufwand
    bestandseinheitBezeichnung
    bestandseinheitId
    bezeichnung
    createTs
    createdBy
    createdByMitarbeiterId
    dueDate
    dueDateDisplayOption {
      month
      type {
        text
        value
      }
    }
    dueDateVorschreibung {
      text
      value
    }
    einleitungsText
    einnahmen
    ergebnisText
    fileId
    fromInclusive
    guthaben
    infoAbrechnungskreisList {
      ...AbrInfoAbrechnungskreisFields
    }
    objektId
    objektKurzBezeichnung
    paymentMethod {
      text
      value
    }
    rechnungsAussteller {
      ...RechtstraegerBaseFields
    }
    rueckstandBestandnehmer
    saldo
    schlussText
    status {
      description
      text
      value
    }
    toInclusive
    topAbrechnungId
    type {
      text
      value
    }
    verrechnungsart {
      text
      value
    }
    vertragspartner {
      ...RechtstraegerBaseFields
    }
    warningList {
      attribute
      message
      type
    }
  }
  ${RechtstraegerBaseFieldsFragmentDoc}
  ${AbrInfoAbrechnungskreisFieldsFragmentDoc}
`;
export const TopAbrechnungOverviewFieldsFragmentDoc = gql`
  fragment TopAbrechnungOverviewFields on TopAbrechnungOverview {
    createTs
    createdBy
    createdByMitarbeiterId
    errorList {
      message
      type
    }
    fileId
    fromInclusive
    objektId
    objektKurzBezeichnung
    toInclusive
    topAbrechnungList {
      ...TopAbrechnungFields
    }
    zahlungsrueckstandList {
      bestandseinheit {
        ...BestandseinheitBaseFields
      }
      offenerBetrag
      rechtstraeger {
        ...RechtstraegerBaseFields
      }
      konto {
        ...AppKontoFields
      }
      letzteBuchungAm
    }
    warningList {
      attribute
      message
      type
    }
  }
  ${TopAbrechnungFieldsFragmentDoc}
  ${BestandseinheitBaseFieldsFragmentDoc}
  ${RechtstraegerBaseFieldsFragmentDoc}
  ${AppKontoFieldsFragmentDoc}
`;
export const TopAbrechnungOutputOptionsFieldsFragmentDoc = gql`
  fragment TopAbrechnungOutputOptionsFields on TopAbrechnungOutputOptions {
    considerRueckstand
    createTs
    createdBy
    createdByMitarbeiterId
    displayDueDate
    displayRueckstandOtherPersons
    displayZahlungsverlauf
    dueDateDisplayOptionEigentuemerGuthaben {
      month
      type {
        text
        value
      }
    }
    dueDateDisplayOptionEigentuemerNachzahlung {
      month
      type {
        text
        value
      }
    }
    dueDateDisplayOptionMieter {
      month
      type {
        text
        value
      }
    }
    rueckstandOtherPersonsThreshold
    updatedByMitarbeiterId
    warningList {
      attribute
      message
      type
    }
  }
`;
