import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../styles/pdfStyles';
import { Abrechnungskreis } from '../../../../objektAbrechnung-types';

const OAAbrKreisSummeAusgaben: FC<{ abrechKreis: Abrechnungskreis; isRepFonds?: boolean }> = ({ abrechKreis, isRepFonds = false }) => (
  <View style={[pdfStyles.row, pdfStyles.tablePaddingLeftRight, { marginBottom: '2mm' }]}>
    {isRepFonds ? null : <View style={[pdfStyles.column, { width: '55%' }]} />}

    <View
      style={[
        pdfStyles.row,
        pdfStyles.borderTopSmall,
        pdfStyles.borderBottomSmall,
        { width: '45%', borderTopWidth: 2, borderBottomWidth: abrechKreis.buchungEinnahmenList.length === 0 ? 2 : undefined },
      ]}
    >
      <Text style={[pdfStyles.column, pdfStyles.textBulletin, { width: '80%', marginTop: '2mm', fontWeight: 'bold' }]}>
        {abrechKreis.summeNettoAusgabenText}
      </Text>
      <Text
        style={[
          pdfStyles.column,
          pdfStyles.textBulletin,
          { width: '20%', marginTop: '2mm', alignItems: 'flex-end', textAlign: 'right', fontWeight: 'bold' },
        ]}
      >
        {abrechKreis.summeNettoAusgaben}
      </Text>
    </View>

    {isRepFonds ? <View style={[pdfStyles.column, { width: '55%' }]} /> : null}
  </View>
);

export default OAAbrKreisSummeAusgaben;
