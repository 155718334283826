import { FormattedTime } from 'react-intl';
import CustomFormattedDate from '../FormattedDate/CustomFormattedDate';

const FormattedDateTime = (props: { createdTs: string | null }) => (
  <>
    {props.createdTs ? (
      <>
        <CustomFormattedDate value={props.createdTs} /> - <FormattedTime value={props.createdTs} />
      </>
    ) : (
      '-'
    )}
  </>
);
export default FormattedDateTime;
