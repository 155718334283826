import React, { FC } from 'react';
import { Empty } from 'antd';
import fieldHelpTableColumns from './fieldHelpTableColumns';
import { FieldHelpFormValues, mapFieldHelpToFormValues, mapFormValuesToFieldHelp } from './fieldHelpFormMapper';
import { showSuccessMsgUpdate, showWarningMessage } from '../../../../components/message';
import { fieldHelpFormValidationSchema } from './fieldHelpFormValidationSchema';
import './styles/fieldHelpTableStyles.css';
import { useUpdateAppFieldHelpMutation } from '../../gql/AppFieldHelpMutations.types';
import { FieldHelpService, FieldHelpWithService } from '../../useFieldHelpList';
import { useUpdateOrderFieldHelpMutation } from '../../gql/OrderFieldHelpMutations.types';
import { useUpdatePaymentFieldHelpMutation } from '../../gql/PaymentFieldHelpMutations.types';
import { useUpdateFibuFieldHelpMutation } from '../../gql/FibuFieldHelpMutations.types';
import EditableTableWithColSelector from '../../../../components/Table/EditableTableV2/EditableTableWithColSelector';

type Props = {
  fieldHelpList: FieldHelpWithService[];
  onAction: () => void;
  loading: boolean;
};

const FieldHelpTable: FC<Props> = ({ fieldHelpList, onAction, loading }) => {
  const [runUpdateApp] = useUpdateAppFieldHelpMutation();
  const [runUpdateFibu] = useUpdateFibuFieldHelpMutation();
  const [runUpdateOrder] = useUpdateOrderFieldHelpMutation();
  const [runUpdatePayment] = useUpdatePaymentFieldHelpMutation();

  const onSubmit = (formValues: FieldHelpFormValues) => {
    const input = mapFormValuesToFieldHelp(formValues);

    let result: Promise<any>;

    switch (formValues.service) {
      case FieldHelpService.App:
        result = runUpdateApp({ variables: { fieldHelpId: formValues.fieldHelpId, input } }).then(
          (response) => response.data?.updateAppFieldHelp.data
        );
        break;
      case FieldHelpService.Fibu:
        result = runUpdateFibu({ variables: { fieldHelpId: formValues.fieldHelpId, input } }).then(
          (response) => response.data?.updateFibuFieldHelp.data
        );
        break;
      case FieldHelpService.Order:
        result = runUpdateOrder({ variables: { fieldHelpId: formValues.fieldHelpId, input } }).then(
          (response) => response.data?.updateOrderFieldHelp.data
        );
        break;
      case FieldHelpService.Payment:
        result = runUpdatePayment({ variables: { fieldHelpId: formValues.fieldHelpId, input } }).then(
          (response) => response.data?.updatePaymentFieldHelp.data
        );
        break;

      default:
        result = Promise.reject();
    }

    result
      .then(() => {
        showSuccessMsgUpdate(`Feld`);
        onAction();
      })
      .catch((error) => {
        showWarningMessage(error, 'Feld wurde nicht aktualisiert');
        // error has to be thrown to reject promise
        throw error;
      });
    return result;
  };

  return (
    <EditableTableWithColSelector<FieldHelpWithService, FieldHelpFormValues>
      filterIdentifier="field-help-main"
      className="field-help-table"
      loading={loading}
      getRowKey={(record) => record.fieldHelpId}
      locale={{
        emptyText: <Empty description={<span>Keine Feldhilfe vorhanden</span>} />,
      }}
      expandable={{}}
      expanded={false}
      pagination
      dataSource={fieldHelpList}
      columns={fieldHelpTableColumns(onAction)}
      validationSchema={fieldHelpFormValidationSchema}
      mapToFormValues={mapFieldHelpToFormValues}
      onSubmit={onSubmit}
    />
  );
};

export default FieldHelpTable;
