import * as Yup from 'yup';
import { entityIsRequired, entityMaxCharLength } from '../../../../../components/message/validationMsg';
import { emailTemplateStepFormFields } from './emailTemplateStepFormMapper';

export const emailTemplateStepFormValidationSchema = Yup.object().shape({
  [emailTemplateStepFormFields.subject]: Yup.object().test({
    message: (data) => {
      const value = data.value.value;
      if (!value) return entityIsRequired('Betreff');
      if (value.length >= 255) return entityMaxCharLength('Betreff', 255);
      return '';
    },
    test: (data) => {
      // @ts-ignore
      const value = data.value;
      return !!value && value.length <= 255;
    },
  }),
});
