import React, { FC, useState } from 'react';
import { Select, Tag } from 'antd';
import { OperatingSiteActionInput } from '../../../../../../../types';

type Props = {
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
  setTagList: (input: OperatingSiteActionInput) => void;
  loading: boolean;
};

const ListItemTags: FC<Props> = ({ tags, setTags, setTagList, loading }) => {
  const [tagsForOptions, setTagsForOptions] = useState(tags);

  const tagRender = (props: { label: React.ReactNode; value: any; onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void }) => {
    const { label, onClose } = props;

    const onPreventMouseDown = (event: { preventDefault: () => void; stopPropagation: () => void }) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable
        onClose={onClose}
        style={{
          margin: 2,
        }}
      >
        {label}
      </Tag>
    );
  };

  const handleSelectionChange = (tagList: string[]) => {
    setTags(tagList);
    setTagsForOptions(tagList);
    setTagList({ tagList });
  };

  return (
    <Select
      id="tags"
      mode="tags"
      defaultValue={tags}
      loading={loading}
      tagRender={tagRender}
      value={tags}
      style={{ minWidth: '100%', marginBottom: '16px' }}
      onChange={handleSelectionChange}
    >
      {tagsForOptions.map((tag) => {
        return (
          <Select.Option key={tag} value={tag} label={tag}>
            {tag}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default ListItemTags;
