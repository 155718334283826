import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResendInfoMailDeliveryMutationVariables = Types.Exact<{
  infoMailDeliveryId: Types.Scalars['ID']['input'];
  recipient: Types.Scalars['String']['input'];
}>;

export type ResendInfoMailDeliveryMutation = { actionResendInfoMailDelivery: { data: { infoMailDeliveryId: string } } };

export const ResendInfoMailDeliveryDocument = gql`
  mutation ResendInfoMailDelivery($infoMailDeliveryId: ID!, $recipient: String!) {
    actionResendInfoMailDelivery(infoMailDeliveryId: $infoMailDeliveryId, recipient: $recipient) {
      data {
        infoMailDeliveryId
      }
    }
  }
`;
export function useResendInfoMailDeliveryMutation(
  baseOptions?: Apollo.MutationHookOptions<ResendInfoMailDeliveryMutation, ResendInfoMailDeliveryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResendInfoMailDeliveryMutation, ResendInfoMailDeliveryMutationVariables>(ResendInfoMailDeliveryDocument, options);
}
export type ResendInfoMailDeliveryMutationHookResult = ReturnType<typeof useResendInfoMailDeliveryMutation>;
export type ResendInfoMailDeliveryMutationResult = Apollo.MutationResult<ResendInfoMailDeliveryMutation>;
export type ResendInfoMailDeliveryMutationOptions = Apollo.BaseMutationOptions<
  ResendInfoMailDeliveryMutation,
  ResendInfoMailDeliveryMutationVariables
>;
