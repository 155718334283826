import React, { FC } from 'react';
import FiltersWithMax3Fields from '../../../../components/Filters/FiltersWithMax3Fields';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import { vertragInfoFeldListingFiltersFormFields } from './vertragInfoFeldListingFormMapper';
import VertragInfoFeldTypeFormSelect from './Filters/VertragInfoFeldTypeFormSelect';
import VertragInfoFeldDrawerAndButton from './VertragInfoFeldDrawerAndButton';

type Props = {
  onSuccess: () => void;
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  showArchived: boolean;
  toggleShowArchived: () => void;
};

const VertragInfoFeldListingFilters: FC<Props> = ({ objektId, bestandseinheitId, vertragId, onSuccess, showArchived, toggleShowArchived }) => (
  <FiltersWithMax3Fields
    hideTitle
    filters={
      <>
        <FormItemWithoutColon name={vertragInfoFeldListingFiltersFormFields.type} label="Typ">
          <VertragInfoFeldTypeFormSelect name={vertragInfoFeldListingFiltersFormFields.type} />
        </FormItemWithoutColon>
      </>
    }
    actionButtons={
      <VertragInfoFeldDrawerAndButton
        onSuccess={onSuccess}
        objektId={objektId}
        bestandseinheitId={bestandseinheitId}
        vertragId={vertragId}
        showArchived={showArchived}
        toggleShowArchived={toggleShowArchived}
      />
    }
  />
);

export default VertragInfoFeldListingFilters;
