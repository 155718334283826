import React from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Drawer, Dropdown, Modal, Row, Typography } from 'antd';
import { VerwaltungKundenSystemDetailsPageRouteParams } from './routes';
import { useToggle } from '../../../../hooks/useToggle';
import { useKundenSystemQuery } from '../../../../features/KundenSystem/KundenSystem/gql/KundenSystemQueries.types';
import { useDeleteKundenSystemMutation } from '../../../../features/KundenSystem/KundenSystem/gql/KundenSystemMutations.types';
import { showSuccessMsgDelete } from '../../../../components/message';
import { LoadingDetailsPage } from '../../../../components/Loading';
import CardWithFooter from '../../../../components/Card';
import CardTitle from '../../../../components/Card/CardTitle';
import FirmendatenListVerwaltung from '../../../../features/KundenSystem/Firmendaten/FirmendatenListVerwaltung';
import KundenSystemUpdateForm from '../../../../features/KundenSystem/KundenSystem/Form/KundenSystemUpdateForm';
import { KundenSystem } from '../../../../types';
import { isStatusDeleted, isStatusGesperrt } from '../../../../helpers/statusHelper';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';

const VerwaltungKundenSystemDetailsPage = () => {
  const { kundenSystemId } = useParams() as VerwaltungKundenSystemDetailsPageRouteParams;
  const [isCollapsed, onCollapse] = useToggle();
  const navigate = useNavigate();

  const { data, refetch } = useKundenSystemQuery({ variables: { kundenSystemId }, fetchPolicy: 'no-cache' });
  const kundenSystem = data?.getKundenSystem.data;
  const loading = !kundenSystem;

  const [runDelete] = useDeleteKundenSystemMutation({
    variables: { kundenSystemId },
    onCompleted: () => {
      showSuccessMsgDelete(`Kundensystem`);
      navigate(URI_ANDROMEDA_SYS_SETTINGS.kundenSystemPage);
    },
  });

  if (loading) return <LoadingDetailsPage loading={loading} />;

  return (
    <>
      <Row gutter={16}>
        <Col>
          <CardWithFooter
            style={{ minWidth: '320px' }}
            title={<CardTitle title={kundenSystem.name} status={kundenSystem.status} />}
            extra={
              <Dropdown menu={{ items: menuItems(kundenSystem, runDelete, onCollapse) }}>
                <Button data-testid="card-menu-dropdown" icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
              </Dropdown>
            }
            createTs={kundenSystem.createTs}
            loading={loading}
          >
            <Typography.Text type="secondary">Name</Typography.Text> {kundenSystem.name}
            <br />
            <Typography.Text type="secondary">Status</Typography.Text> {kundenSystem.status.text}
          </CardWithFooter>
        </Col>
        <Col flex="auto">
          <FirmendatenListVerwaltung kundenSystem={kundenSystem} loading={loading} onSuccess={refetch} />
        </Col>
      </Row>
      <Drawer title="Kundensystem bearbeiten" width={720} open={isCollapsed} onClose={onCollapse} footer={null} destroyOnClose>
        <KundenSystemUpdateForm
          kundenSystem={kundenSystem}
          onCancel={onCollapse}
          onSuccess={() => {
            refetch();
            onCollapse();
          }}
        />
      </Drawer>
    </>
  );
};

const menuItems = (kundenSystem: KundenSystem, onDelete: () => void, onUpdate: () => void) => {
  return [
    { key: '1', label: 'Kundensystem bearbeiten', onClick: onUpdate, disabled: isStatusGesperrt(kundenSystem.status) },
    {
      key: '2',
      label: 'Kundensystem löschen',
      onClick: () => showConfirmDelete(kundenSystem, onDelete),
      disabled: isStatusDeleted(kundenSystem.status),
      danger: true,
    },
  ];
};

const showConfirmDelete = (kundenSystem: KundenSystem, onDelete: () => void) => {
  Modal.confirm({
    title: `Möchten Sie das Kundensystem löschen?`,
    content: `${kundenSystem.name}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return onDelete();
    },
  });
};

export default VerwaltungKundenSystemDetailsPage;
