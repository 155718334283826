import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import FormButtons from '../../../../components/Button/FormButtons';
import { mapFormValuesToUstKategorie, mapUstKategorieToFormValues, ustKategorieFormFields, UstKategorieFormValues } from './ustKategorieFormMapper';
import { ustKategorieFormValidationSchema } from '../shared/ustKategorieFormValidationSchema';
import KSFirmendatenSelect from '../../../KSFirmendatenSelect/KSFirmendatenSelect';
import { useCreateFdUstKategorieMutation, useCreateKsUstKategorieMutation, useUpdateUstKategorieMutation } from '../gql/UstKategorieMutations.types';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { Spacer } from '../../../../components/Grid';
import { useFDAuthorized } from '../../../../shared/Auth/useAuthorized';
import { UstKategorie } from '../../../../types';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';

type Props = {
  ustKategorie?: UstKategorie;
  onSuccess: () => void;
  onCancel: () => void;
};

const UstKategorieForm: FC<Props> = ({ ustKategorie, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'UstKategorie'>('UstKategorie');

  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();
  const isUpdate = !!ustKategorie;

  const entity = 'USt-Kategorie';

  const [createFDUstKategorie, { loading: loadingFormFD }] = useCreateFdUstKategorieMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [createKSUstKategorie, { loading: loadingFormKS }] = useCreateKsUstKategorieMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [updateUstKategorie, { loading: loadingUpdate }] = useUpdateUstKategorieMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<UstKategorieFormValues>
      initialValues={mapUstKategorieToFormValues(ustKategorie)}
      validationSchema={ustKategorieFormValidationSchema}
      onSubmit={(formValues, formikHelpers) => {
        const input = mapFormValuesToUstKategorie(formValues);
        if (!isUpdate) {
          if (formValues.firmendatenId) {
            createFDUstKategorie({ variables: { firmendatenId: formValues.firmendatenId, input } }).finally(() => formikHelpers.setSubmitting(false));
          } else {
            createKSUstKategorie({ variables: { kundenSystemId, input } }).finally(() => formikHelpers.setSubmitting(false));
          }
        } else {
          updateUstKategorie({ variables: { ustKategorieId: ustKategorie.ustKategorieId, input } }).finally(() => formikHelpers.setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithFieldHelp
            name={ustKategorieFormFields.firmendatenId}
            label="Firma auswählen"
            help="USt-Kategorie wird nur für diese Firma angewendet"
          >
            <KSFirmendatenSelect
              name={ustKategorieFormFields.firmendatenId}
              id="Firma"
              disabled={isUpdate}
              placeholder={!isUpdate ? 'Firma' : 'Keine Firma'}
            />
          </FormItemWithFieldHelp>

          <Spacer height={16} />

          <FormItemWithFieldHelp
            name={ustKategorieFormFields.kurzBezeichnung}
            label="Kurzbezeichnung"
            fieldHelp={getFieldHelpText('UstKategorie.kurzBezeichnung')}
          >
            <Input id="Kurzbezeichnung" name={ustKategorieFormFields.kurzBezeichnung} placeholder='z.B. USt-Kat "leicht zu merken"' />
          </FormItemWithFieldHelp>

          <FormButtons
            updateMode={isUpdate}
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={loadingFormFD || loadingFormKS || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default UstKategorieForm;
