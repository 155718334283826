import React from 'react';
import { PDFObject } from 'react-pdfobject';
import ReactImageMagnify from 'react-image-magnify';
import { Image } from 'rebass';
import { FILE_CONTENT_TYPE_PDF } from '../../../helpers/fileHelper';
import { FileInfo } from '../../../types';

const MediaCard = (props: { fileInfo?: FileInfo; mediaUrl?: string }) => {
  const { fileInfo, mediaUrl } = props;
  let mediaCard: React.ReactNode;

  if (!fileInfo || !mediaUrl) {
    mediaCard = null;
  } else if (fileInfo.contentType === FILE_CONTENT_TYPE_PDF) {
    mediaCard = (
      <div id="pdfDiv">
        <PDFObject url={mediaUrl} containerId="pdfDiv" height="700px" />
      </div>
    );
  } else if (fileInfo.dimensions) {
    // picture with dimensions
    const largeImgDimensions = calculateLargeImgDimensions(fileInfo.dimensions.width, fileInfo.dimensions.height);
    mediaCard = (
      <div>
        <ReactImageMagnify
          smallImage={{ src: mediaUrl, alt: 'dokument', isFluidWidth: true }}
          largeImage={{ src: mediaUrl, width: largeImgDimensions.width, height: largeImgDimensions.height }}
          enlargedImagePosition="over"
          isHintEnabled
          hintTextMouse="zum Vergrößern bewegen Sie Maus über Bild"
        />
      </div>
    );
  } else {
    // picture without dimensions (should not occur)
    mediaCard = (
      <div>
        <Image
          p={24}
          // @ts-ignore
          src={mediaUrl}
          alt="dokument"
        />
      </div>
    );
  }

  return <>{mediaCard}</>;
};

const calculateLargeImgDimensions = (width: number, height: number) => {
  // very primitive, simple algorithm, it could be a lot more dynamic, sophisticated
  const containerWidth = 750; // could get the real width from somewhere instead of hardcoded value
  const multiplier = containerWidth / width + 0.4; // 0.4 is really just a magic number, it worked for my test cases
  return {
    width: width * multiplier,
    height: height * multiplier,
  };
};

export default MediaCard;
