import React, { FC } from 'react';
import { Divider, Typography } from 'antd';
import { AbstractRechtstraegerInfoFeld } from '../../../../types';
import { useCreateRechtstraegerInfoFeldTicketMutation } from '../../../Ticket/gql/TicketMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { mapFormValuesToTicketCreateInput, TicketFormValues } from '../../../Ticket/Form/ticketFormMapper';
import TicketListing from '../../../Ticket/Listing/TicketListing';
import TicketListingFilters from '../../../Ticket/Listing/TicketListingFilters';
import TicketActionButtonsAndDrawer from '../../../Ticket/TicketActionButtonsAndDrawer';
import { gqlOperations } from '../../../../graphql/gqlNamedOperations-generated';
import TicketListingCalendar from '../../../Ticket/Listing/TicketListingCalendar';

type Props = {
  rechtstraegerId: string;
  infoFeld: AbstractRechtstraegerInfoFeld;
  onSuccess: () => void;
};

const RechtstraegerInfoFeldTicketListing: FC<Props> = ({ rechtstraegerId, infoFeld, onSuccess }) => {
  const [runCreate, { loading: loadingCreate }] = useCreateRechtstraegerInfoFeldTicketMutation({
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onSubmitCreate = async (values: TicketFormValues) => {
    const response = await runCreate({
      variables: { rechtstraegerId, rechtstraegerInfoFeldId: infoFeld.rechtstraegerInfoFeldId, input: mapFormValuesToTicketCreateInput(values) },
      refetchQueries: () => [gqlOperations.Query.TicketList],
    });
    return response.data?.createRechtstragerInfoFeldTicket.data;
  };

  return (
    <TicketListing
      tableFilterIdentifier={`rt-info-feld-${infoFeld.type.value}-ticket}`}
      rechtstraegerInfoFeldId={infoFeld.rechtstraegerInfoFeldId}
      onSuccess={onSuccess}
      bottom={(formikProps) => (
        <>
          <Divider />
          <Typography.Title level={4} style={{ marginTop: 16 }}>
            Kalender
          </Typography.Title>
          <TicketListingCalendar formikProps={formikProps} rechtstraegerInfoFeldId={infoFeld.rechtstraegerInfoFeldId} />
        </>
      )}
    >
      {({ formikProps, refetch, queryParams }) => (
        <TicketListingFilters
          formikProps={formikProps}
          queryParams={queryParams}
          actionButtons={
            <TicketActionButtonsAndDrawer
              refetch={refetch}
              allowCreate
              loadingCreate={loadingCreate}
              onFormSubmitCreate={onSubmitCreate}
              formikProps={formikProps}
            />
          }
        />
      )}
    </TicketListing>
  );
};

export default RechtstraegerInfoFeldTicketListing;
