import { useFDAuthorized } from '../../shared/Auth/useAuthorized';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../shared/dataChangedSubscription';
import { DataChangedChangeType } from '../../types';
import { useAuth } from '../../shared/Auth/authContext';

const useOnRechtstraegerDataChangeEvent = (key: string, callback: () => Promise<unknown>, rechtstraegerId: string, isPersonenbezug?: boolean) => {
  const {
    user,
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();
  const { mitarbeiter } = useAuth();

  useOnDataChangedEvent([
    {
      filter: {
        userId: user.username,
        type: dataChangedSubscriptionTypes.rechtstraeger,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        params: {
          kundenSystemId,
          rechtstraegerId,
        },
        changeType: DataChangedChangeType.Update,
      },
      notificationDescription: `${isPersonenbezug ? 'Personenbezug' : 'Rechtstraeger'} wurde gerade verändert`,
      key: `${key}-update`,
      callback,
    },
    {
      filter: {
        userId: user.username,
        type: dataChangedSubscriptionTypes.rechtstraeger,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        params: {
          kundenSystemId,
          rechtstraegerId,
        },
        changeType: DataChangedChangeType.Create,
      },
      notificationDescription: `${isPersonenbezug ? 'Personenbezug' : 'Rechtstraeger'} wurde gerade erstellt`,
      key: `${key}-create`,
      callback,
    },
    {
      filter: {
        userId: user.username,
        type: dataChangedSubscriptionTypes.rechtstraeger,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        params: {
          kundenSystemId,
          rechtstraegerId,
        },
        changeType: DataChangedChangeType.Delete,
      },
      notificationDescription: `${isPersonenbezug ? 'Personenbezug' : 'Rechtstraeger'} wurde gerade gelöscht`,
      key: `${key}-delete`,
      callback,
    },
  ]);
};

export { useOnRechtstraegerDataChangeEvent };
