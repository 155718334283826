import { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useFirmendatenIndexSeriesListQuery } from '../../Firmendaten/IndexSeries/gql/FirmendatenIndexSeriesQueries.types';
import { IndexSeriesSourceType, IndexType } from '../../../types';
import { selectFilterOption } from '../../../helpers/selectHelper';

type Props = {
  name: string;
  indexType?: IndexType;
  includeArchiviert?: boolean;
  onSelectChange: (indexSeriesId: string, sourceType?: IndexSeriesSourceType) => void;
} & SelectProps;

const IndexSeriesSelect: FC<Props> = ({ name, indexType, includeArchiviert, onSelectChange, ...restProps }) => {
  const { data, loading } = useFirmendatenIndexSeriesListQuery({
    variables: {
      includeArchiviert,
      indexType,
    },
  });

  const indexSeriesList = data?.getFirmendatenIndexSeriesList.data ?? [];
  const getSourceType = (indexSeriesId: string) =>
    indexSeriesList.find((indexSeries) => indexSeries.indexSeriesId === indexSeriesId)?.sourceType.value;

  return (
    <>
      <Select
        size="small"
        {...restProps}
        name={name}
        loading={loading}
        placeholder="Indexreihe auswählen"
        allowClear
        showSearch
        filterOption={selectFilterOption}
        onChange={(value) => onSelectChange(value, getSourceType(value))}
      >
        {indexSeriesList.map((indexSeries) => {
          return (
            <Select.Option key={indexSeries.indexSeriesId} value={indexSeries.indexSeriesId}>
              {indexSeries.name}
            </Select.Option>
          );
        })}
      </Select>
    </>
  );
};

export default IndexSeriesSelect;
