import { FC } from 'react';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';
import { useAufteilungsschluesselChangeHistoryListQuery } from '../gql/AufteilungsschluesselQueries.types';

type Props = {
  aufteilungsschluesselId: string;
};

const AufteilungsschluesselChangeHistoryListingTable: FC<Props> = ({ aufteilungsschluesselId }) => {
  const { data, loading } = useAufteilungsschluesselChangeHistoryListQuery({ variables: { aufteilungsschluesselId } });
  const historyList = data?.getAufteilungsschluesselChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="aufteilungsschluessel" />;
};

export default AufteilungsschluesselChangeHistoryListingTable;
