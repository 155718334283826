import React from 'react';
import { ColumnProps } from 'antd/es/table';
import { UserRevision } from '../../../types';

const adminAuditLogTableColumns: ColumnProps<UserRevision>[] = [
  {
    title: 'Name',
    render: (text, record) => (
      <span>
        {record.lastname} {record.lastname}
      </span>
    ),
  },
  {
    title: 'Bezeichnung',
    render: (text, record) => <span>{record.lastPasswordChangeTs}</span>,
  },
  {
    title: 'geändert von',
    render: (text, record) => <span>{record.changedBy}</span>,
  },
  {
    title: 'geändert am',
    render: (text, record) => <span>{record.changedTs}</span>,
  },
];

export default adminAuditLogTableColumns;
