import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { TPaymentQueryParams } from '../../Listing/Filters/filtersQueryParams';
import { ChangePaymentDateBulkActionInput, EmployeeApproval } from '../../../../types';
import { isPaymentEmployeeApprovalAll } from '../../paymentHelpers';

export interface ChangePaymentDateFormValues {
  paymentDate?: string | null;
}

export const formValuesInitialValues: ChangePaymentDateFormValues = {
  paymentDate: null,
};

export const formFields: FormFields<ChangePaymentDateFormValues> = {
  paymentDate: 'paymentDate',
};

export const mapQueryParamsToChangePaymentDateBulkAction = (
  values: ChangePaymentDateFormValues,
  queryParams: TPaymentQueryParams,
  excludeList: string[]
): ChangePaymentDateBulkActionInput => ({
  ...queryParams,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  paymentDate: mapFormDateValueToDateString(values.paymentDate!),
  belegnummer: queryParams.belegnummer?.toString(),
  employeeApproval:
    isPaymentEmployeeApprovalAll(queryParams.employeeApproval) || !queryParams.employeeApproval
      ? undefined
      : (queryParams.employeeApproval as EmployeeApproval),
  excludeList,
});
