import { FC } from 'react';
import { Select } from 'formik-antd';
import { DueDateDisplayOptionType } from '../../../../../../../../../types';
import FormItemWithFieldHelp from '../../../../../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  name: string;
  isDisabled?: boolean;
  onChange: (value: DueDateDisplayOptionType) => void;
  fieldHelp?: string | null;
};

const DueDateDisplayOptionSelect: FC<Props> = ({ name, isDisabled, onChange, fieldHelp }) => {
  const options = [
    { text: 'Übernächster Zinstermin', value: DueDateDisplayOptionType.UebernaechsterZinstermin },
    { text: 'Verrechnungsperiode', value: DueDateDisplayOptionType.Verrechnungsperiode },
  ];

  return (
    <FormItemWithFieldHelp name={name} style={{ width: '180px' }} fieldHelp={fieldHelp}>
      <Select size="small" name={name} id={name} placeholder="Option auswählen" disabled={isDisabled} onChange={onChange}>
        {options.map((dueDateDisplayOption) => (
          <Select.Option key={dueDateDisplayOption.value} value={dueDateDisplayOption.value}>
            {dueDateDisplayOption.text}
          </Select.Option>
        ))}
      </Select>
    </FormItemWithFieldHelp>
  );
};

export default DueDateDisplayOptionSelect;
