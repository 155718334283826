import * as Yup from 'yup';
import { nutzflaecheVersionFormFields } from './nutzflaecheVersionFormMapper';
import { kommentarValidationSchema } from '../../../../../components/Timeline/Form/timelineFormKommentarValidationSchema';
import { formattedDecimalType } from '../../../../../components/Input-antd';
import { entityIsRequired } from '../../../../../components/message/validationMsg';

export const nutzflaecheVersionFormValidationSchema = Yup.object().shape({
  [nutzflaecheVersionFormFields.kommentar]: kommentarValidationSchema,
  [nutzflaecheVersionFormFields.nutzflaeche]: formattedDecimalType().required(entityIsRequired('Nutzfläche')),
});
