import React, { FC } from 'react';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import { useFDAuthorized } from '../../../../../../shared/Auth/useAuthorized';
import { BeInfoFeldType } from '../../../../../../types';
import FormButtons from '../../../../../../components/Button/FormButtons';
import { handleCreateSubmit } from '../../shared/handleSubmit';
import InfoFeldUpload from '../../../../../shared/InfoFeld/InfoFeldUpload';
import { zimmerCreateFormFields, zimmerCreateFormInitialValues, ZimmerCreateFormValues } from './zimmerCreateFormMapper';
import ZimmerDatenTab from '../Tabs/ZimmerDatenTab';
import { zimmerValidationSchema } from '../shared/zimmerValidationSchema';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

const KellerabteilCreateForm: FC<Props> = ({ objektId, bestandseinheitId, onSuccess, onCancel }) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  return (
    <Formik<ZimmerCreateFormValues>
      initialValues={zimmerCreateFormInitialValues}
      onSubmit={(values, { setSubmitting }) => {
        handleCreateSubmit(firmendatenId, objektId, bestandseinheitId, BeInfoFeldType.Zimmer, values, setSubmitting, onSuccess);
      }}
      validationSchema={zimmerValidationSchema}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <ZimmerDatenTab formFields={zimmerCreateFormFields} objektId={objektId} formikProps={formikProps} />
          <InfoFeldUpload name={zimmerCreateFormFields.documentList} formikProps={formikProps} documentList={formikProps.values.documentList} />
          <FormButtons updateMode={false} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default KellerabteilCreateForm;
