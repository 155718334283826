import React, { FC, useEffect, useState } from 'react';
import { InputNumberProps } from 'antd/lib/input-number';
import useDebounce from '../../hooks/useDebounce';
import FormattedDecimalInput, { FormattedDecimalInputProps } from './FormattedDecimalInput';
import useEffectAfterMount from '../../hooks/useEffectAfterMount';
import useUpdateFocus from '../../hooks/useUpdateFocus';

type DebouncedFormattedDecimalFormInputProps = {
  value?: InputNumberProps['value'];
  onChange?: InputNumberProps['onChange'];
  delay?: number;
} & Omit<FormattedDecimalInputProps, 'value' | 'onChange'>;

const DebouncedFormattedDecimalInput: FC<DebouncedFormattedDecimalFormInputProps> = ({ value, onChange, delay, ...restProps }) => {
  const [inputValue, setInputValue] = useState<InputNumberProps['value']>(value);
  const debouncedValue = useDebounce(inputValue as number, delay ?? 500);
  const { onBeforeInput, onBlur } = useUpdateFocus(restProps.disabled, true);

  useEffectAfterMount(() => {
    onChange?.(inputValue ?? null);
  }, [debouncedValue]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <FormattedDecimalInput
      onBeforeInput={onBeforeInput}
      onBlur={onBlur}
      onChange={(value) => setInputValue(value)}
      value={inputValue}
      {...restProps}
    />
  );
};

export default DebouncedFormattedDecimalInput;
