import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateBookingSuggestionMutationVariables = Types.Exact<{
  bookingSuggestionId: Types.Scalars['String']['input'];
  input: Types.BookingSuggestionUpdateInput;
}>;

export type UpdateBookingSuggestionMutation = {
  updateBookingSuggestion: {
    data: { bookingSuggestionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const UpdateBookingSuggestionDocument = gql`
  mutation UpdateBookingSuggestion($bookingSuggestionId: String!, $input: BookingSuggestionUpdateInput!) {
    updateBookingSuggestion(bookingSuggestionId: $bookingSuggestionId, input: $input) {
      data {
        bookingSuggestionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateBookingSuggestionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBookingSuggestionMutation, UpdateBookingSuggestionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBookingSuggestionMutation, UpdateBookingSuggestionMutationVariables>(UpdateBookingSuggestionDocument, options);
}
export type UpdateBookingSuggestionMutationHookResult = ReturnType<typeof useUpdateBookingSuggestionMutation>;
export type UpdateBookingSuggestionMutationResult = Apollo.MutationResult<UpdateBookingSuggestionMutation>;
export type UpdateBookingSuggestionMutationOptions = Apollo.BaseMutationOptions<
  UpdateBookingSuggestionMutation,
  UpdateBookingSuggestionMutationVariables
>;
