import React, { FC, useState } from 'react';
import { Divider, Space, Steps } from 'antd';
import { Dayjs } from 'dayjs';
import { Flex } from 'rebass';
import { useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash';
import WeAbrDefTemplates from '../../../shared/Templates/WeTemplates/WeAbrDefTemplates';
import { showSuccessMsgCreate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import {
  mapTextbausteinListToFormValues,
  TextbausteinFormValues,
  TextbausteinModel,
} from '../../../../../components/Template/PDFTemplates/templateMapper';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../../constants/andromedaSystemSettingsUriPaths';
import { AbrechnungsdefinitionType, WeAbrechnungsdefinitionVersionListEntry } from '../../../../../types';
import {
  AbrDefAbrKreisListingFormValues,
  mapAbrechnungsdefinitionToAbrKreisListFormValues,
} from '../../../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormMapper';
import AbrDefTemplateAbrKreisListingForm from '../../../shared/AbrDefAbrKreisListingForm/AbrDefTemplateAbrKreisListingForm';
import AbrDefFormValidFrom, { formatAbrechnungsdefinitionValidFromValue } from '../../../../Abrechnungsdefinition/shared/AbrDefFormValidFrom';
import { useCreateWeAbrechnungsdefinitionTemplateVersionMutation } from '../../../gql/WeAbrDef/WeAbrDefTemplateVersionMutations.types';
import { mapFormValuesToBkOrWeAbrechnungsdefinitionVersionCreate } from '../../../../Abrechnungsdefinition/Version/Form/Create/abrDefVersionCreateFormMapper';
import AbrDefVersionFormKommentar from '../../../../Abrechnungsdefinition/shared/AbrDefVersionFormKommentar';
import { WeAbrechnungsdefinitionVersionFieldsFragment } from '../../../../Abrechnungsdefinition/gql/WeAbrDef/WeAbrDefVersionFragments.types';
import { useWeAbrDefVersionFieldHelp } from '../../../../Abrechnungsdefinition/abrDefFieldHelpHelpers/useWeAbrDefVersionFieldHelp';
import {
  getAlreadyTakenValidFromDates,
  getNextAvailableValidFromOrToday,
  updateCommentOnTemplateChange,
} from '../../../../Abrechnungsdefinition/Version/Form/Create/abrDefVersionCreateFormHelpers';
import {
  mapObjAbrDefOutputOptionsToFormValues,
  objAbrDefGeneralSettingsFormInitialValues,
  ObjAbrDefGeneralSettingsFormValues,
} from '../../../../Abrechnungsdefinition/shared/Templates/shared/BkOrWe/ObjAbrDefGeneralSettingsForm/objAbrDefGeneralSettingsFormMapper';
import {
  mapTopAbrDefGeneralSettingsToInitialValues,
  TopAbrDefGeneralSettingsFormValues,
} from '../../../../Abrechnungsdefinition/shared/Templates/shared/BkOrWe/TopAbrDefGeneralSettingsForm/topAbrDefGeneralSettingsFormMapper';

type Props = {
  abrechnungsdefinitionVersionList: WeAbrechnungsdefinitionVersionListEntry[];
  abrechnungsdefinitionId: string;
  precedingAbrechnungsdefinitionVersion?: WeAbrechnungsdefinitionVersionFieldsFragment;
  objektAbrechnungTextbausteinList: TextbausteinModel[];
  topAbrechnungTextbausteinList: TextbausteinModel[];
};

const WeAbrDefTemplateVersionCreateForm: FC<Props> = ({
  abrechnungsdefinitionVersionList,
  abrechnungsdefinitionId,
  precedingAbrechnungsdefinitionVersion,
  objektAbrechnungTextbausteinList,
  topAbrechnungTextbausteinList,
}) => {
  const { fieldHelpAbrDefAbrechkreisList, fieldHelpNamesObjekt, fieldHelpNamesTop } = useWeAbrDefVersionFieldHelp();

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [validFromFormValue, setValidFromFormValue] = useState<string>(
    getNextAvailableValidFromOrToday(abrechnungsdefinitionVersionList, precedingAbrechnungsdefinitionVersion)
  );
  const [isValidFromValid, setIsValidFromValid] = useState<boolean>();
  const [kommentarFormValues, setKommentarFormValues] = useState<string>('');
  const [abrKreisListFormValues, setAbrKreisListFormValues] = useState<AbrDefAbrKreisListingFormValues>(
    mapAbrechnungsdefinitionToAbrKreisListFormValues(precedingAbrechnungsdefinitionVersion?.abrechdefAbrechkreisList)
  );
  const [weObjAbrDefTemplateValues, setWeObjAbrDefTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(objektAbrechnungTextbausteinList)
  );
  const [weTopAbrDefTemplateValues, setWeTopAbrDefTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(topAbrechnungTextbausteinList)
  );

  const [objAbrDefGeneralSettingsValues, setObjAbrDefGeneralSettingsValues] = useState<ObjAbrDefGeneralSettingsFormValues>(
    precedingAbrechnungsdefinitionVersion?.objektAbrechnungOutputOptions
      ? mapObjAbrDefOutputOptionsToFormValues(precedingAbrechnungsdefinitionVersion.objektAbrechnungOutputOptions)
      : objAbrDefGeneralSettingsFormInitialValues
  );

  const [topAbrDefGeneralSettingsValues, setTopAbrDefGeneralSettingsValues] = useState<TopAbrDefGeneralSettingsFormValues>(
    mapTopAbrDefGeneralSettingsToInitialValues(
      AbrechnungsdefinitionType.WeAbrechnung,
      precedingAbrechnungsdefinitionVersion?.topAbrechnungOutputOptions
    )
  );

  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  const entity = 'AbrechnungsdefinitionVersion';

  const [createWeAbrDefVersion, { loading: loadingCreate }] = useCreateWeAbrechnungsdefinitionTemplateVersionMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      navigate(URI_ANDROMEDA_SYS_SETTINGS.abrechnungsdefinitionPage);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onAbrKreisListingFormSubmit = (formValues: AbrDefAbrKreisListingFormValues) => {
    const hasAbrechnungskreisZuordnungChanged = !isEqual(
      mapAbrechnungsdefinitionToAbrKreisListFormValues(precedingAbrechnungsdefinitionVersion?.abrechdefAbrechkreisList),
      formValues
    );
    hasAbrechnungskreisZuordnungChanged && setKommentarFormValues('Abrechnungskreise geändert');
    setAbrKreisListFormValues(formValues);
    setActiveStep(1);
  };

  const onAbrDefTemplatesSubmit = () => {
    if (!validFromFormValue) {
      setIsValidFromValid(false);
    }
    if (!isFormValid) return;

    const input = mapFormValuesToBkOrWeAbrechnungsdefinitionVersionCreate(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      abrKreisListFormValues!,
      weObjAbrDefTemplateValues,
      weTopAbrDefTemplateValues,
      validFromFormValue,
      kommentarFormValues,
      objAbrDefGeneralSettingsValues,
      topAbrDefGeneralSettingsValues
    );
    createWeAbrDefVersion({
      variables: {
        abrechnungsdefinitionId,
        request: input,
      },
    });
  };

  let content;
  if (activeStep === 0) {
    content = (
      <AbrDefTemplateAbrKreisListingForm
        abrDefType={AbrechnungsdefinitionType.WeAbrechnung}
        values={abrKreisListFormValues}
        onSubmit={onAbrKreisListingFormSubmit}
        onBack={() => navigate(URI_ANDROMEDA_SYS_SETTINGS.abrechnungsdefinitionPage)}
        backBtnText="Abbrechen"
        fieldHelp={fieldHelpAbrDefAbrechkreisList}
      />
    );
  } else {
    // activeStep === 1
    content = (
      <WeAbrDefTemplates
        weObjektAbrTemplateValues={weObjAbrDefTemplateValues}
        onWeObjektAbrDefTemplateChange={(formValues) => {
          updateCommentOnTemplateChange(
            mapTextbausteinListToFormValues(objektAbrechnungTextbausteinList),
            formValues,
            'WE-Objektabrechnung',
            setKommentarFormValues
          );
          setWeObjAbrDefTemplateValues(formValues);
        }}
        weTopAbrTemplateValues={weTopAbrDefTemplateValues}
        onWeTopAbrDefTemplateChange={(formValues) => {
          updateCommentOnTemplateChange(
            mapTextbausteinListToFormValues(topAbrechnungTextbausteinList),
            formValues,
            'WE-Topabrechnung',
            setKommentarFormValues
          );
          setWeTopAbrDefTemplateValues(formValues);
        }}
        objAbrDefGeneralSettingsValues={objAbrDefGeneralSettingsValues}
        onObjAbrDefGeneralSettingsChange={setObjAbrDefGeneralSettingsValues}
        topAbrDefGeneralSettingsValues={topAbrDefGeneralSettingsValues}
        onTopAbrDefGeneralSettingsChange={(values) => setTopAbrDefGeneralSettingsValues(values)}
        setIsFormValid={setIsFormValid}
        onSubmit={onAbrDefTemplatesSubmit}
        isSubmitting={loadingCreate}
        onBack={() => setActiveStep(0)}
        fieldHelpNames={{
          objekt: fieldHelpNamesObjekt,
          top: fieldHelpNamesTop,
        }}
      />
    );
  }

  const onChangeDate = (date: Dayjs | null) => {
    if (date) {
      setIsValidFromValid(true);
      setValidFromFormValue(formatAbrechnungsdefinitionValidFromValue(date));
    } else {
      setIsValidFromValid(false);
    }
  };

  return (
    <>
      <Flex>
        <Space>
          <AbrDefFormValidFrom
            isValid={isValidFromValid}
            onChange={onChangeDate}
            initialValue={validFromFormValue ? dayjsCustom(validFromFormValue) : undefined}
            disabledDates={getAlreadyTakenValidFromDates(abrechnungsdefinitionVersionList)}
          />
        </Space>
        <Space style={{ marginLeft: '10px' }}>
          <AbrDefVersionFormKommentar onChange={setKommentarFormValues} value={kommentarFormValues} />
        </Space>
      </Flex>
      <Divider />
      <Steps type="navigation" size="small" current={activeStep} className="site-navigation-steps">
        <Steps.Step title="Abrechnungskreise" />
        <Steps.Step title="Abrechnung" />
      </Steps>
      {content}
    </>
  );
};

export default WeAbrDefTemplateVersionCreateForm;
