import { Dayjs } from 'dayjs';
import { FormFields, mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';
import { EigenbelegErstellenGenerierlaufInput } from '../../../../../types';
import { ALLE_TAG_TEXT } from '../../../../../shared/ObjektSelect/ObjektSelectWithAllTag';

export type VorschreibungAusgebenModalFormValues = {
  belegdatum: Dayjs;
  generierDatum: Dayjs;
  objektIdList?: string[];
};

export const vorschreibungAusgebenModalFormFields: FormFields<VorschreibungAusgebenModalFormValues> = {
  belegdatum: 'belegdatum',
  generierDatum: 'generierDatum',
  objektIdList: 'objektIdList',
};

export const mapFormValuesToVorschreibungAusgeben = (values: VorschreibungAusgebenModalFormValues): EigenbelegErstellenGenerierlaufInput => ({
  belegdatum: mapFormDateValueToDateString(values.belegdatum),
  generierDatum: mapFormDateValueToDateString(values.generierDatum),
  objektIdList: values.objektIdList?.includes(ALLE_TAG_TEXT) ? null : values.objektIdList,
});
