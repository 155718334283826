import { FC } from 'react';
import { Form, Input } from 'formik-antd';
import { Typography } from 'antd';
import { Formik } from 'formik';
import {
  changeToNichtDurchgefuehrtFormFields,
  changeToNichtDurchgefuehrtFormInitialValues,
  ChangeToNichtDurchgefuehrtFormValues,
  changeToNichtDurchgefuehrtFormValuesDescriptions,
  mapFormValuesToChangeToNichtDurchgefuehrt,
} from './formMapper';
import { showSuccessMsgOther } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import FormItemWithoutColon from '../../../../../../components/Form/FormItemWithoutColon';
import { useChangeToNichtDurchgefuehrtPaymentMutation } from '../../../../gql/Order/PaymentMutations.types';
import FormButtons from '../../../../../../components/Button/FormButtons';

type Props = {
  paymentId: string;
  onCancel: () => void;
  onSubmit: () => void;
};

const ChangeToNichtDurchgefuehrtForm: FC<Props> = ({ paymentId, onCancel, onSubmit }) => {
  const [runChangeToNichtDurchfuehrt] = useChangeToNichtDurchgefuehrtPaymentMutation();

  return (
    <Formik<ChangeToNichtDurchgefuehrtFormValues>
      initialValues={changeToNichtDurchgefuehrtFormInitialValues()}
      onSubmit={(values) => {
        runChangeToNichtDurchfuehrt({
          variables: { paymentId, input: mapFormValuesToChangeToNichtDurchgefuehrt(values) },
          onCompleted: () => {
            showSuccessMsgOther('Überweisung auf "Nicht durchgeführt" geändert');
            onSubmit();
          },
          onError: APOLLO_DUMMY_ERROR_HANDLER,
        });
      }}
    >
      <Form>
        <Typography.Text>{changeToNichtDurchgefuehrtFormValuesDescriptions.comment}</Typography.Text>
        <FormItemWithoutColon name={changeToNichtDurchgefuehrtFormFields.comment}>
          <Input.TextArea
            id={changeToNichtDurchgefuehrtFormFields.comment}
            name={changeToNichtDurchgefuehrtFormFields.comment}
            placeholder="Fügen Sie das Kommentar hinzu..."
            autoSize={{ minRows: 10 }}
            //INFO: Add prefix so input doesn't loses focus - https://github.com/jannikbuschke/formik-antd/issues/200
            prefix=""
          />
        </FormItemWithoutColon>
        <FormButtons onCancel={onCancel} updateMode={false} />
      </Form>
    </Formik>
  );
};

export default ChangeToNichtDurchgefuehrtForm;
