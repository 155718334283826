import React, { FC } from 'react';
import { Divider, Empty, List, Table, Typography } from 'antd';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';
import UstVomAufwandAllBuchungLink from './UstVomAufwandAllBuchungLink';
import UstVomAufwandDetailsTable from './Table/UstVomAufwandDetailsTable';
import { Spacer } from '../../../components/Grid';
import errorLogTableColumns from '../../Abrechnung/shared/errorLogTableColumns';
import { ErrorData, UstVomAufwand } from '../../../types';

type Props = {
  ustVomAufwand: UstVomAufwand;
};

const UstVomAufwandDetailsListing: FC<Props> = ({ ustVomAufwand }) => {
  const { errorList: ustVomAufwandErrorList } = ustVomAufwand;
  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <UstVomAufwandAllBuchungLink ustVomAufwand={ustVomAufwand} />
      </ButtonsAligned>
      {ustVomAufwandErrorList.length > 0 && (
        <>
          <Table<ErrorData>
            size="small"
            pagination={false}
            dataSource={ustVomAufwandErrorList}
            rowKey={(record) => record.type}
            columns={errorLogTableColumns}
            locale={{
              emptyText: <Empty description={<span>Keine Fehler vorhanden</span>} />,
            }}
          />
          <Divider />
        </>
      )}
      {ustVomAufwand.buchungErrorList?.length !== 0 && (
        <>
          <List<ErrorData>
            header={<Typography.Text>Es gab Fehler bei der Verbuchung:</Typography.Text>}
            dataSource={ustVomAufwand.buchungErrorList}
            renderItem={(item) => (
              <List.Item>
                <Typography.Text type="danger" key={item.message}>
                  {item.message}
                </Typography.Text>
              </List.Item>
            )}
          />
          <Spacer height={16} />
        </>
      )}
      <UstVomAufwandDetailsTable ustVomAufwand={ustVomAufwand} />
    </>
  );
};

export default UstVomAufwandDetailsListing;
