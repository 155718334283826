import React, { FC, PropsWithChildren } from 'react';
import { ApolloClient, ApolloLink, ApolloProvider, from, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { isEmpty } from 'lodash';
import { showWarningNotification } from '../components/message';
import httpLink from './apolloHttpLink';
import { apolloClientDefaultOptions } from './apolloClientDefaultOptions';
import errorLoggerLink from './apolloErrorLoggerLink';
import { LogoutFn, useAuth } from '../shared/Auth/authContext';
import { HEADER_EASYCLOUD_ACTIVE_FOR_FIRMENDATEN_ID, HEADER_EASYCLOUD_FIRMENDATEN_ID } from '../helpers/easyCloudWebHeaders';
import errorHandlerLink from './apolloErrorHandlerLink';
import apolloCache from './apolloCache';

// eslint-disable-next-line no-console
console.log('Websocket connection to...', process.env.REACT_APP_GRAPHQL_WS_URL);

const wsLink = new GraphQLWsLink(
  createClient({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    url: process.env.REACT_APP_GRAPHQL_WS_URL!,
    lazy: true,
  })
);

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const httpAndWslink = () =>
  split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
  );

export const firmendatenHeaderMiddleware = (mitarbeiterFirmendatenId: string | undefined, activeForFirmendatenId: string | null) =>
  new ApolloLink((operation, forward) => {
    const headers: { [HEADER_EASYCLOUD_FIRMENDATEN_ID]?: string; [HEADER_EASYCLOUD_ACTIVE_FOR_FIRMENDATEN_ID]?: string } = {};

    if (mitarbeiterFirmendatenId) {
      headers[HEADER_EASYCLOUD_FIRMENDATEN_ID] = mitarbeiterFirmendatenId;
    }
    if (activeForFirmendatenId) {
      headers[HEADER_EASYCLOUD_ACTIVE_FOR_FIRMENDATEN_ID] = activeForFirmendatenId;
    }

    if (!isEmpty(headers)) {
      operation.setContext({
        headers,
      });
    }

    return forward(operation);
  });

export const warningHandlerAfterware = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    const { data } = response;
    if (data) {
      for (const val of Object.values(data)) {
        if (val.warningList && val.warningList.length > 0) {
          showWarningNotification(val.warningList);
        }
      }
    }
    return response;
  })
);

const apolloClientLinks = (logout: LogoutFn, mitarbeiterFirmendatenId: string | undefined, activeForFirmendatenId: string | null) =>
  from([
    firmendatenHeaderMiddleware(mitarbeiterFirmendatenId, activeForFirmendatenId),
    warningHandlerAfterware,
    errorHandlerLink(logout),
    errorLoggerLink,
    httpAndWslink(),
  ]);

const mainApolloProvider = (logout: LogoutFn, mitarbeiterFirmendatenId: string | undefined, activeForFirmendatenId: string | null) =>
  new ApolloClient({
    link: apolloClientLinks(logout, mitarbeiterFirmendatenId, activeForFirmendatenId),
    cache: apolloCache,
    defaultOptions: apolloClientDefaultOptions,
  });

const MainApolloProvider: FC<PropsWithChildren> = ({ children }) => {
  const { mitarbeiter, activeForFirmendatenId, logout, user } = useAuth();
  return user ? (
    <ApolloProvider client={mainApolloProvider(logout, mitarbeiter?.firmendatenId, activeForFirmendatenId)}>{children}</ApolloProvider>
  ) : (
    <>{children}</>
  );
};

export default MainApolloProvider;
