import React from 'react';

const styleForIconAndButton: React.CSSProperties = {
  width: '30px',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
};

export { styleForIconAndButton };
