import { isEmpty } from 'lodash';
import {
  AbrechnungskreisStatus,
  AbrechnungStatus,
  AufteilungsschluesselStatus,
  AuftragStatus,
  BestandseinheitStatus,
  BudgetingStatus,
  EbicsUserStatus,
  IncomingInvoiceStatus,
  FirmendatenStatus,
  GenerierlaufStatus,
  KontoStatus,
  ObjektStatus,
  RechtstraegerStatus,
  TicketStatus,
  UserStatus,
  UstVomAufwandBuchungStatus,
  VertragStatus,
  VorsteuerkuerzungBuchungStatus,
  ReceivedBankTransactionStatus,
} from '../types';
import theme from '../theme';

export interface IStatus {
  description?: string | null;
  text: string;
  value: TCollectedStatus;
}

export type TCollectedStatus =
  | string
  | AbrechnungskreisStatus
  | AufteilungsschluesselStatus
  | AuftragStatus
  | BestandseinheitStatus
  | BudgetingStatus
  | IncomingInvoiceStatus
  | FirmendatenStatus
  | GenerierlaufStatus
  | KontoStatus
  | AbrechnungStatus
  | ObjektStatus
  | RechtstraegerStatus
  | StatusEnum
  | UserStatus
  | VertragStatus
  | VorsteuerkuerzungBuchungStatus
  | EbicsUserStatus
  | TicketStatus
  | ReceivedBankTransactionStatus;

// collected statuses of from genTypes to get overview
export enum StatusEnum {
  // Entity
  Aktiv = 'AKTIV',
  Archiviert = 'ARCHIVIERT',
  Geloescht = 'GELOESCHT',
  Deleted = 'DELETED',
  Gesperrt = 'GESPERRT',
  Blocked = 'BLOCKED',
  // --- //
  Eingeladen = 'EINGELADEN',
  Unverifiziert = 'UNVERIFIZIERT',
  // --- //
  // Buchungen
  Ausgegeben = 'AUSGEGEBEN',
  KeineBuchungen = 'KEINE_BUCHUNGEN',
  Freigegeben = 'FREIGEGEBEN',
  NichtVerbucht = 'NICHT_VERBUCHT',
  Uebertragen = 'UEBERTRAGEN',
  UebertragenFehler = 'UEBERTRAGEN_FEHLER',
  UmbuchungFreigegeben = 'UMBUCHUNG_FREIGEGEBEN',
  Verbucht = 'VERBUCHT',
  WirdVerbucht = 'WIRD_VERBUCHT',
  // --- //
  // EbicsUser
  Initialized = 'INITIALIZED',
  InitializeError = 'INITIALIZE_ERROR',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  // --- //
  // Budgeting
  Verarbeitet = 'VERARBEITET',
  // --- //
  // SystemStatus
  Valid = 'VALID',
  Invalid = 'INVALID',
  PruefungAusgesetzt = 'PRUEFUNG_AUSGESETZT',
  UnderReview = 'UNDER_REVIEW',
  ReviewError = 'REVIEW_ERROR',
  // andere see UI/UX Guidelines, das ist nicht eindeutig für die BenutzerInnen
  // Würdet ihr euch bei Jenkins auskennen, wenn ihr das liest?
  Abgebrochen = 'ABGEBROCHEN',
  BeendetFehler = 'BEENDET_FEHLER',
  ZurueckgesetztFehler = 'ZURUECKGESETZT_FEHLER',
  Fehler = 'FEHLER',
  // Verarbeitungen/Generierlauf
  AuftraegeNotFound = 'AUFTRAEGE_NOT_FOUND',
  EigenbelegeNotFound = 'EIGENBELEGE_NOT_FOUND',
  BeendetNoDataFound = 'BEENDET_NO_DATA_FOUND',
  BeendetWarning = 'BEENDET_WARNING',
  RetryFehler = 'RETRY_FEHLER',
  // AsyncStatus || Wie es die BenutzerInnen sehen/lesen?
  // 1. INITIALISIERT = GESTARTET
  // 2. BEENDET = ERSTELLT
  Gestartet = 'GESTARTET',
  Initialisiert = 'INITIALISIERT',
  Beendet = 'BEENDET',
  Erstellt = 'ERSTELLT',
  Zurueckgesetzt = 'ZURUECKGESETZT',
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  ToDo = 'TO_DO',
  // ReceivedBankTransaction
  Archived = 'ARCHIVED',
  Assigned = 'ASSIGNED',
  Created = 'CREATED',
}

export const hasStatusDescription = (status: IStatus): boolean => !isEmpty(status.description);

export const isStatusActive = (status: IStatus) => status.value === StatusEnum.Aktiv || status.value === StatusEnum.Active;

export const isStatusArchivedOrGesperrt = (status: IStatus) => isStatusGesperrt(status) || isStatusArchived(status);

export const isStatusArchived = (status: IStatus) => status.value === StatusEnum.Archiviert || status.value === StatusEnum.Archived;

export const isStatusDeleted = (status: IStatus) => status.value === StatusEnum.Geloescht || status.value === StatusEnum.Deleted;

export const isStatusGesperrt = (status: IStatus) => status.value === StatusEnum.Gesperrt || status.value === StatusEnum.Blocked;

export const isStatusErstellt = (status: IStatus) => status.value === StatusEnum.Erstellt;

export const isStatusEingeladen = (status: IStatus) => status.value === StatusEnum.Eingeladen;

export const isStatusValid = (status: IStatus) => status.value === StatusEnum.Valid;

export const isStatusInvalid = (status: IStatus) => status.value === StatusEnum.Invalid;

export const isStatusPruefungAusgesetzt = (status: IStatus) => status.value === StatusEnum.PruefungAusgesetzt;

export const isStatusReviewError = (status: IStatus) => status.value === StatusEnum.ReviewError;

export const isStatusUnderReview = (status: IStatus) => status.value === StatusEnum.UnderReview;

export const isStatusUebertragen = (status: IStatus) => status.value === StatusEnum.Uebertragen;

export const isStatusVerarbeitet = (status: IStatus) => status.value === StatusEnum.Verarbeitet;

export const isStatusAusgegeben = (status: IStatus) => status.value === StatusEnum.Ausgegeben;

export const isStatusFreigegeben = (status: IStatus) => status.value === StatusEnum.Freigegeben;

export const isStatusUebertragenFehler = (status: IStatus) => status.value === StatusEnum.UebertragenFehler;

export const isVstKuerzungBuchungStatusVerbucht = (status: IStatus) => status.value === VorsteuerkuerzungBuchungStatus.Verbucht;

export const isVstKuerzungBuchungStatusNichtVerbucht = (status: IStatus) => status.value === VorsteuerkuerzungBuchungStatus.NichtVerbucht;

export const isVstKuerzungBuchungStatusWirdVerbucht = (status: IStatus) => status.value === VorsteuerkuerzungBuchungStatus.WirdVerbucht;

export const isVstKuerzungBuchungStatusVerbuchenFehler = (status: IStatus) => status.value === VorsteuerkuerzungBuchungStatus.VerbuchenFehler;

export const isUstVomAufwandBuchungStatusVerbucht = (status: IStatus) => status.value === UstVomAufwandBuchungStatus.Verbucht;

export const isUstVomAufwandBuchungStatusNichtVerbucht = (status: IStatus) => status.value === UstVomAufwandBuchungStatus.NichtVerbucht;

export const isUstVomAufwandBuchungStatusWirdVerbucht = (status: IStatus) => status.value === UstVomAufwandBuchungStatus.WirdVerbucht;

export const isUstVomAufwandBuchungStatusVerbuchenFehler = (status: IStatus) => status.value === UstVomAufwandBuchungStatus.VerbuchenFehler;

// Badge and Tag don't inherit the font color from a wrapper component. The color has to be defined inline.
export const archivedOrNormalStyle = (status: IStatus) => {
  return { color: isStatusArchived(status) ? theme.textColor.disabled : 'inherit' };
};
