import React, { FC } from 'react';
import { Typography, Space } from 'antd';
import { Ticket } from '../../../types';
import TicketCommentListing from './Comment/TicketCommentListing';
import TicketSubTicketListing from './SubTicketListing/TicketSubTicketListing';
import { Spacer } from '../../../components/Grid';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';

type Props = {
  ticket: Ticket;
  refetch: () => Promise<unknown>;
};

const TicketDetailsOverview: FC<Props> = ({ ticket, refetch }) => {
  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }} size={48}>
        <Space direction="vertical" size={4}>
          <Typography.Title level={5}>{ticket.title}</Typography.Title>
          <Spacer height={12} />
          <Typography.Title level={5}>Beschreibung</Typography.Title>
          <Typography.Text style={{ whiteSpace: 'pre-line' }}>{ticket.description ?? <TextForEmptyValue textToShow="minus" />}</Typography.Text>
        </Space>
        <TicketSubTicketListing ticket={ticket} refetch={refetch} />
        <Space direction="vertical" size={0} style={{ width: '100%' }}>
          <Typography.Title level={5}>Kommentare</Typography.Title>
          <TicketCommentListing ticket={ticket} />
        </Space>
      </Space>
    </>
  );
};

export default TicketDetailsOverview;
