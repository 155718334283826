import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../../security/AuthRoute';
import { isAdmin } from '../../../../../security/permissionChecks';
import AndromedaSysSettingsMahndefinitionCreatePage from './AndromedaSysSettingsMahndefinitionCreatePage';
import URI_ANDROMEDA_SYS_SETTINGS_MAHNDEFINITION_PAGE from '../../../../../features/MahndefinitionTemplate/mahndefinitonTemplateUriPaths';

const andromedaSysSettingsMahndefinitionCreatePageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS_MAHNDEFINITION_PAGE.createPage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsMahndefinitionCreatePage />} />}
  />
);

export default andromedaSysSettingsMahndefinitionCreatePageRoute;
