import { BelegTextePlatzhalter } from '../../../Belegtext/Form/belegTexteFormTypes';
import { TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';
import { getBelegTexteWithTypeBild } from './belegTexteHelpers';
import {
  DocumentHeaderAndFooter,
  DocumentHeaderAndFooterImageValue,
  DocumentHeaderAndFooterType,
} from '../../../../pages/PDFTemplates/templates/shared/types';
import { findAndReplacedPlatzhalterWithoutTextbaustein } from './belegTexteWithoutTextbausteinTemplateHelper';
import { findAndReplacePlatzhalterWithTextbaustein } from './belegTexteWithTextbausteinTemplateHelper';

export const getBelegTexteForTypeTextOrBild = (
  value: string | DocumentHeaderAndFooterImageValue | undefined,
  belegTextePlatzhalter: BelegTextePlatzhalter,
  textbausteinFormValues?: TextbausteinFormValues
) => {
  function isImageData(type: DocumentHeaderAndFooterImageValue | undefined | string): type is DocumentHeaderAndFooterImageValue {
    return typeof type === 'object' && (type as DocumentHeaderAndFooterImageValue).value !== undefined;
  }

  if (typeof value === 'string') {
    return getBelegTexteWithTypeText(value, belegTextePlatzhalter, textbausteinFormValues);
  }

  if (isImageData(value)) {
    return getBelegTexteWithTypeBild(value);
  }

  return undefined;
};

const getBelegTexteWithTypeText = (
  value: string,
  belegTextePlatzhalter: BelegTextePlatzhalter,
  formValues: TextbausteinFormValues | undefined
): DocumentHeaderAndFooter => ({
  value: formValues
    ? findAndReplacePlatzhalterWithTextbaustein(value, belegTextePlatzhalter, formValues)
    : findAndReplacedPlatzhalterWithoutTextbaustein(value, belegTextePlatzhalter),
  type: DocumentHeaderAndFooterType.TEXT,
});
