import React from 'react';
import { Route } from 'react-router';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import AuthRoute from '../../security/AuthRoute';
import { URI_HE_ABRECHNUNG_DETAILS_HE_VERTRAG_DETAILS_PAGE } from '../../features/Abrechnung/He/heAbrechnungUriPaths';
import HeAbrechnungDetailsHeVertragDetailsPage from './HeAbrechnungDetailsHeVertragDetailsPage';

export type HeAbrDetailsHeVertragDetailsPageRouteParams = { objektId: string; heAbrechnungId: string; heAbrechnungHeVertragId: string };

const heAbrechnungDetailsHeVertragDetailsPageRoute = (
  <Route
    path={`${URI_HE_ABRECHNUNG_DETAILS_HE_VERTRAG_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<HeAbrechnungDetailsHeVertragDetailsPage />} />}
  />
);

export default heAbrechnungDetailsHeVertragDetailsPageRoute;
