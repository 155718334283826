import React, { FC } from 'react';
import { MenuProps } from 'antd';
import { EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { radixActionStyles } from '../../../../../../../helpers/radixIconsStyles';
import { VertragVposIndexationAgreementVorschreibungsposition } from '../../../../../../../types';
import { useToggle } from '../../../../../../../hooks/useToggle';
import ActionDropdown from '../../../../../../../components/Dropdown/ActionDropdown';
import HistoryModal, { HistoryType } from '../../../../../../History/shared/HistoryModal';
import VertragVposIndexationAgreementDeletionHistoryListingTable from './VertragVposIndexationAgreementDeletionHistoryListingTable';

type Props = {
  bestandseinheitId: string;
  objektId: string;
  vertragId: string;
  record: VertragVposIndexationAgreementVorschreibungsposition;
  toggleExpandedRowKey: (rowKey: string) => void;
  expandedRowKeys: string[];
};

const IndexationAgreementVPosTableActions: FC<Props> = ({
  record,
  bestandseinheitId,
  objektId,
  vertragId,
  toggleExpandedRowKey,
  expandedRowKeys,
}) => {
  const [isDeletionHistoryOpen, toggleIsDeletionHistoryOpen] = useToggle();

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Bearbeiten',
      onClick: () => toggleExpandedRowKey(record.vorschreibungspositionId),
      icon: <EditOutlined />,
      disabled: expandedRowKeys.includes(record.vorschreibungspositionId),
    },
    {
      key: '2',
      label: 'Löschverlauf',
      onClick: toggleIsDeletionHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
  ];

  return (
    <>
      <ActionDropdown items={items} />
      <HistoryModal
        entityName="Vorschreibungsposition"
        historyType={HistoryType.Deletion}
        open={isDeletionHistoryOpen}
        onCancel={toggleIsDeletionHistoryOpen}
      >
        <VertragVposIndexationAgreementDeletionHistoryListingTable
          objektId={objektId}
          bestandseinheitId={bestandseinheitId}
          vertragId={vertragId}
          vorschreibungspositionId={record.vorschreibungspositionId}
        />
      </HistoryModal>
    </>
  );
};

export default IndexationAgreementVPosTableActions;
