import React, { FC } from 'react';
import { Input } from 'formik-antd';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  fieldHelpNames?: {
    name1?: string | null;
    name2?: string | null;
    name3?: string | null;
  };
};

const FirmennameFormPart: FC<Props> = ({ fieldHelpNames }) => (
  <>
    <FormItemWithFieldHelp name="name1" label="Name 1" fieldHelp={fieldHelpNames?.name1}>
      <Input id="name1" name="name1" placeholder="Name 1" />
    </FormItemWithFieldHelp>
    <FormItemWithFieldHelp name="name2" label="Name 2" fieldHelp={fieldHelpNames?.name2}>
      <Input id="name2" name="name2" placeholder="Name 2" />
    </FormItemWithFieldHelp>
    <FormItemWithFieldHelp name="name3" label="Name 3" fieldHelp={fieldHelpNames?.name3}>
      <Input id="name3" name="name3" placeholder="Name 3" />
    </FormItemWithFieldHelp>
  </>
);

export default FirmennameFormPart;
