import React from 'react';
import { Helmet } from 'react-helmet-async';
import { PageLayout } from '../../components/LayoutAndromeda';
import ReceivedBankTransactionListing from '../../features/ReceivedBankTransaction/Listing/ReceivedBankTransactionListing';

const ReceivedBankTransactionPage = () => {
  return (
    <>
      <Helmet>
        <title>Empfangene Bankbuchungen</title>
      </Helmet>
      <PageLayout>
        <ReceivedBankTransactionListing />
      </PageLayout>
    </>
  );
};

export default ReceivedBankTransactionPage;
