import React, { FC } from 'react';
import { Drawer, Image, Space, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useToggle } from '../../../../../hooks/useToggle';
import { useReportLogosQuery } from './gql/ReportLogosQueries.types';
import ReportLogosForm from './Form/ReportLogosForm';

type RechnungsAusstellerReportLogosProps = {
  firmendatenId: string;
};

const RechnungsAusstellerReportLogos: FC<RechnungsAusstellerReportLogosProps> = ({ firmendatenId }) => {
  const [isDrawerOpen, toggleDrawer] = useToggle();

  const { data, refetch } = useReportLogosQuery({ variables: { firmendatenId } });
  const reportLogo = data?.getReportLogos.data;

  const onSuccess = () => {
    refetch();
    toggleDrawer();
  };

  return (
    <>
      <Space direction="vertical">
        <Logo firmendatenId={firmendatenId} fileId={reportLogo?.logoGrossFileId} title="Logo groß" onEdit={toggleDrawer} />
        <Logo firmendatenId={firmendatenId} fileId={reportLogo?.logoKleinFileId} title="Logo klein" onEdit={toggleDrawer} />
      </Space>
      <Drawer title="Bearbeiten" width={720} onClose={toggleDrawer} open={isDrawerOpen} destroyOnClose>
        <ReportLogosForm reportLogos={reportLogo} firmendatenId={firmendatenId} onSuccess={onSuccess} onCancel={toggleDrawer} />
      </Drawer>
    </>
  );
};

type LogoProps = {
  firmendatenId: string;
  fileId?: string | null;
  title: string;
  onEdit: () => void;
};

const Logo: FC<LogoProps> = ({ firmendatenId, fileId, title, onEdit }) => {
  return (
    <Space direction="vertical">
      <Space>
        <Typography.Text>{title}</Typography.Text>
        <EditOutlined onClick={onEdit} />
      </Space>
      {fileId ? <Image src={uriPathToFile(firmendatenId, fileId)} /> : <Typography.Text type="secondary">Kein Logo hinterlegt</Typography.Text>}
    </Space>
  );
};

const uriPathToFile = (firmendatenId: string, fileId: string) => `/api/firmendaten/${firmendatenId}/files/${fileId}/download`;

export default RechnungsAusstellerReportLogos;
