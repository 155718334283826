import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { RepFondsEinstellungFieldsFragmentDoc } from '../../ReparaturFonds/gql/RepFondsEinstellungFragments.types';
import { AufteilungsschluesselFieldsFragmentDoc } from '../../Aufteilungsschluessel/gql/AufteilungsschluesselFragments.types';
import { AppKontoFieldsFragmentDoc } from '../../Kontenplan/gql/KontenplanFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RepFondsEinstellungTemplateListQueryVariables = Types.Exact<{
  onlyCurrent?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type RepFondsEinstellungTemplateListQuery = {
  getRepFondsEinstellungTemplateList: {
    data: Array<{
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      kommentar?: string | null;
      lastUpdateTs: string;
      repFondsEinstellungId: string;
      updatedByMitarbeiterId?: string | null;
      validFrom: string;
      versionIndex: number;
      historicizedList?: Array<{
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        createTs: string;
        current: boolean;
        deletable?: boolean | null;
        validFrom: string;
        kommentar?: string | null;
        lastUpdateTs: string;
        repFondsEinstellungId: string;
        versionIndex: number;
        kontenZuweisungList: Array<{
          repFondsEinstellungKontenZuweisungId: string;
          aufteilungsschluessel: {
            aufteilungsschluesselId: string;
            bezeichnung: string;
            bezugsbasisEditable?: boolean | null;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            firmendatenId?: string | null;
            kurzBezeichnung: string;
            updatedByMitarbeiterId?: string | null;
            bezugsbasis: { text: string; value: Types.Bezugsbasis };
            masseinheit: { text: string; value: Types.Masseinheit };
            status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          aufwandsKontoList: Array<{ bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string }>;
          repFondsKonto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
        }>;
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      }> | null;
      kontenZuweisungList: Array<{
        repFondsEinstellungKontenZuweisungId: string;
        aufteilungsschluessel: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        aufwandsKontoList: Array<{ bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string }>;
        repFondsKonto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RepFondsEinstellungTemplateListDocument = gql`
  query RepFondsEinstellungTemplateList($onlyCurrent: Boolean) {
    getRepFondsEinstellungTemplateList(onlyCurrent: $onlyCurrent) {
      data {
        ...RepFondsEinstellungFields
        historicizedList {
          createdBy
          createdByMitarbeiterId
          createTs
          current
          deletable
          validFrom
          kommentar
          kontenZuweisungList {
            aufteilungsschluessel {
              ...AufteilungsschluesselFields
            }
            aufwandsKontoList {
              ...AppKontoFields
            }
            repFondsEinstellungKontenZuweisungId
            repFondsKonto {
              ...AppKontoFields
            }
          }
          lastUpdateTs
          repFondsEinstellungId
          versionIndex
          warningList {
            message
            type
            attribute
          }
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RepFondsEinstellungFieldsFragmentDoc}
  ${AufteilungsschluesselFieldsFragmentDoc}
  ${AppKontoFieldsFragmentDoc}
`;
export function useRepFondsEinstellungTemplateListQuery(
  baseOptions?: Apollo.QueryHookOptions<RepFondsEinstellungTemplateListQuery, RepFondsEinstellungTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RepFondsEinstellungTemplateListQuery, RepFondsEinstellungTemplateListQueryVariables>(
    RepFondsEinstellungTemplateListDocument,
    options
  );
}
export function useRepFondsEinstellungTemplateListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RepFondsEinstellungTemplateListQuery, RepFondsEinstellungTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RepFondsEinstellungTemplateListQuery, RepFondsEinstellungTemplateListQueryVariables>(
    RepFondsEinstellungTemplateListDocument,
    options
  );
}
export function useRepFondsEinstellungTemplateListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<RepFondsEinstellungTemplateListQuery, RepFondsEinstellungTemplateListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RepFondsEinstellungTemplateListQuery, RepFondsEinstellungTemplateListQueryVariables>(
    RepFondsEinstellungTemplateListDocument,
    options
  );
}
export type RepFondsEinstellungTemplateListQueryHookResult = ReturnType<typeof useRepFondsEinstellungTemplateListQuery>;
export type RepFondsEinstellungTemplateListLazyQueryHookResult = ReturnType<typeof useRepFondsEinstellungTemplateListLazyQuery>;
export type RepFondsEinstellungTemplateListSuspenseQueryHookResult = ReturnType<typeof useRepFondsEinstellungTemplateListSuspenseQuery>;
export type RepFondsEinstellungTemplateListQueryResult = Apollo.QueryResult<
  RepFondsEinstellungTemplateListQuery,
  RepFondsEinstellungTemplateListQueryVariables
>;
