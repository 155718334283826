import React, { FC } from 'react';
import { Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../hooks/useToggle';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../components/Button/ButtonsAligned';
import LinkageForm from './Form/LinkageForm';

type Props = {
  linkageEntryList: any[];
  onAction: () => void;
};

const LinkageDrawerAndButtons: FC<Props> = ({ onAction, linkageEntryList }) => {
  const [isOpen, toggleIsOpen] = useToggle();

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <Button type="primary" onClick={toggleIsOpen}>
          <PlusOutlined />
          Verknüpfung anlegen
        </Button>
      </ButtonsAligned>
      <Drawer title="Verknüpfung anlegen" width={720} open={isOpen} onClose={toggleIsOpen} destroyOnClose>
        <LinkageForm
          onCancel={toggleIsOpen}
          linkageEntryList={linkageEntryList}
          onSuccess={() => {
            toggleIsOpen();
            onAction();
          }}
        />
      </Drawer>
    </>
  );
};

export default LinkageDrawerAndButtons;
