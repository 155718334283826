import { Vorschreibung } from '../../templates/vorschreibung/vorschreibung-types';

export const mockVorschreibungEinzelAbrErgRechnungStorno: Vorschreibung = {
  type: 'VORSCHREIBUNG_EINZEL_ABR_ERGEBNIS_RECHNUNG_STORNO',
  titleText: '{Platzhalter TitleText je nach Use-Case}',
  einleitungstext: '{Platzhalter Einleitungstext je nach Use-Case}',
  zahlart: '{Platzhalter Zahlart je nach Use-Case}',
  verrechnungsweiseText: '{Platzhalter Verrechnungsweise je nach Use-Case}',
  ustRegelsetText: '{Platzhalter Ust.Regelset je nach Use-Case}',
  schlusstext: '{Platzhalter Schlusstext je nach Use-Case}',

  absender: { label: 'Abs:', value: 'andromeda software GmnH, Grillgasse 46; 1110 Wien' },
  billingAddress: {
    line1: 'Herr',
    line2: 'Max Mustermann',
    line3: '',
    line4: 'Mustergasse 124 Top 8',
    line7: '1060 Wien',
  },

  belegnummer: { label: 'Beleg-Nr.', value: '471108/2022' },
  belegOrtUndDatum: 'Wien, 21.07.2020',

  rechnungAussteller: {
    bezeichnung: { label: 'Rechnungsaussteller:', value: 'WEG Friedhofsallee, 2232 Deutsch-Wagram' },
  },
  rechnungEmpfaenger: {
    bezeichnung: { label: 'Rechnung für:', value: 'BBB Immo GmbH' },
    addressLine: 'Friedhofsalle 47',
    zipCodeAndCity: '2232 Deutsch-Wagram',
    kundennummer: { label: 'Kundennummer:', value: '47110815' },
    vatIdentificationNumber: { label: 'UID-Nr.:', value: 'ATU 2233449' },
  },
  vorschreibungspositionListHeader: {
    vorschreibungspositionText: 'Vorschreibungsposition',
    nettoText: 'Netto',
    ustSatzText: 'Ust-Satz',
    ustBetragText: 'Ust-Betrag',
    bruttoText: 'Brutto',
  },
  objektList: [
    {
      objektText: 'Objekt:',
      bezeichnungAndAddress: 'Friedhofsalle 47, 2232 Deutsch-Wagram',
      bestandseinheitList: [
        {
          bestandseinheit: 'Top 1',
          vorschreibungspositionList: [
            {
              vorschreibungsposition: 'Betriebskosten 1',
              netto: '-108,01',
              ustSatz: '10%',
              ustBetrag: '-10,80',
              brutto: '-118,81',
            },
            {
              vorschreibungsposition: 'Betriebskosten 2',
              netto: '-11,01',
              ustSatz: '10%',
              ustBetrag: '-1,10',
              brutto: '-12,11',
            },
            {
              vorschreibungsposition: 'Reparaturfonds',
              netto: '-8,06',
              ustSatz: '0%',
              ustBetrag: '-',
              brutto: '-8,06',
            },
          ],
          summary: {
            summeText: 'Summe Top 1',
            netto: '-127,08',
            ustBetrag: '-11,90',
            brutto: '-138,98',
          },
        },
        {
          bestandseinheit: 'Top 2',
          vorschreibungspositionList: [
            {
              vorschreibungsposition: 'Betriebskosten 1',
              netto: '-108,01',
              ustSatz: '10%',
              ustBetrag: '-10,80',
              brutto: '-118,81',
            },
            {
              vorschreibungsposition: 'Betriebskosten 2',
              netto: '-11,01',
              ustSatz: '10%',
              ustBetrag: '-1,10',
              brutto: '-12,11',
            },
            {
              vorschreibungsposition: 'Reparaturfonds',
              netto: '-8,06',
              ustSatz: '0%',
              ustBetrag: '-',
              brutto: '-8,06',
            },
          ],
          summary: {
            summeText: 'Summe Top 2',
            netto: '-127,08',
            ustBetrag: '-11,90',
            brutto: '-138,98',
          },
        },
      ],
      summary: { summeText: 'Summe: Friedhofsalle 47', netto: '-254,16', ustBetrag: '-23,80', brutto: '-272,96' },
    },
    {
      objektText: 'Objekt:',
      bezeichnungAndAddress: 'Friedhofsalle 50, 2232 Deutsch-Wagram',
      bestandseinheitList: [
        {
          bestandseinheit: 'Top 1',
          vorschreibungspositionList: [
            {
              vorschreibungsposition: 'Betriebskosten 1',
              netto: '-108,01',
              ustSatz: '10%',
              ustBetrag: '-10,80',
              brutto: '-118,81',
            },
            {
              vorschreibungsposition: 'Betriebskosten 2',
              netto: '-11,01',
              ustSatz: '10%',
              ustBetrag: '-1,10',
              brutto: '-12,11',
            },
            {
              vorschreibungsposition: 'Reparaturfonds',
              netto: '-8,06',
              ustSatz: '0%',
              ustBetrag: '-',
              brutto: '-8,06',
            },
          ],
          summary: {
            summeText: 'Summe Top 1',
            netto: '-127,08',
            ustBetrag: '-11,90',
            brutto: '-138,98',
          },
        },
        {
          bestandseinheit: 'Top 2',
          vorschreibungspositionList: [
            {
              vorschreibungsposition: 'Betriebskosten 1',
              netto: '-108,01',
              ustSatz: '10%',
              ustBetrag: '-10,80',
              brutto: '-118,81',
            },
            {
              vorschreibungsposition: 'Betriebskosten 2',
              netto: '-11,01',
              ustSatz: '10%',
              ustBetrag: '-1,10',
              brutto: '-12,11',
            },
            {
              vorschreibungsposition: 'Reparaturfonds',
              netto: '-8,06',
              ustSatz: '0%',
              ustBetrag: '-',
              brutto: '-8,06',
            },
          ],
          summary: {
            summeText: 'Summe Top 2',
            netto: '-127,08',
            ustBetrag: '-11,90',
            brutto: '-138,98',
          },
        },
      ],
      summary: { summeText: 'Summe: Friedhofsalle 50', netto: '-254,16', ustBetrag: '-23,80', brutto: '-272,96' },
    },
  ],
  rechnungSummary: {
    summeNetto: { label: 'Rechnungsbetrag netto', value: '-508,32' },
    summeUst: { label: 'Umsatzsteuer', value: '-71,41' },
    summeBrutto: { label: 'Rechnungsbetrag brutto', value: '-579,73' },
  },
  ustDetails: {
    ustListHeader: {
      ustSatzText: 'Ust-Satz',
      nettoText: 'Basis Netto',
      ustBetragText: 'Ust-Betrag',
      bruttoText: 'Brutto Betrag',
    },
    ustList: [
      {
        ustSatz: '0%',
        netto: '-32,24',
        ust: '-',
        brutto: '-32,24',
      },
      {
        ustSatz: '10%',
        netto: '-238,04',
        ust: '-23,80',
        brutto: '-264,84',
      },
      {
        ustSatz: '-20%',
        netto: '-238,04',
        ust: '-47,61',
        brutto: '-285,65',
      },
    ],
    summary: {
      summeNetto: '-508,32',
      summeUst: '-71,41',
      summeBrutto: '-579,73',
    },
  },
};
