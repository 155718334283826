import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { VorschreibungMailDeliveryFieldsFragmentDoc } from '../../../EmailDelivery/VorschreibungMailDelivery/gql/VorschreibungMailDeliveryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VorschreibungMailDeliverySendGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type VorschreibungMailDeliverySendGenerierlaufQuery = {
  getVorschreibungMailDeliverySendGenerierlauf: {
    data: {
      countFehler: number;
      countNichtVerarbeitet: number;
      countVerarbeitet: number;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      createTs: string;
      generierDatum: string;
      generierlaufId: string;
      errorList: Array<{ message: string; type: string }>;
      status: { description?: string | null; text: string; value: Types.GenerierlaufStatus };
      warningList: Array<{ message: string; type: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type VorschreibungMailDeliverySendGenerierlaufEntryListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  emailAddress?: Types.InputMaybe<Types.Scalars['String']['input']>;
  vertragspartnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  recipientId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  order?: Types.InputMaybe<Types.VorschreibungMailDeliverySendGenLaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type VorschreibungMailDeliverySendGenerierlaufEntryListQuery = {
  getVorschreibungMailDeliverySendGenerierlaufEntryList: {
    data: {
      contentList: Array<{
        generierlaufEntryId: string;
        infoMessage?: string | null;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { text: string; value: Types.GenerierlaufEntryExitCode } | null;
        vorschreibungMailDelivery?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          emailAddress: string;
          emailDeliveryDefinitionBezeichnung: string;
          emailDeliveryDefinitionId: string;
          mailId?: string | null;
          original: boolean;
          sender?: string | null;
          sentTs?: string | null;
          shouldNeverSend: boolean;
          shouldSend: boolean;
          updatedByMitarbeiterId?: string | null;
          vorschreibungMailDeliveryId: string;
          emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
          fakturierungsperiode: { jahr: number; monat: number };
          objektBestandseinheitVertragList: Array<{
            vertragId: string;
            bestandseinheit: {
              bestandseinheitId: string;
              bezeichnung: string;
              address: {
                addressId: string;
                city: string;
                cityAdditionalInformation?: string | null;
                countryCodeIso2: string;
                houseEntranceApartmentNumber?: string | null;
                postofficeBoxNumber?: string | null;
                postofficeBoxZipCode?: string | null;
                street?: string | null;
                type: Types.AddressType;
                zipCode?: string | null;
              };
            };
            objekt: { kurzBezeichnung: string; objektId: string };
          }>;
          recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
          vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const VorschreibungMailDeliverySendGenerierlaufDocument = gql`
  query VorschreibungMailDeliverySendGenerierlauf($generierlaufId: ID!) {
    getVorschreibungMailDeliverySendGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countNichtVerarbeitet
        countVerarbeitet
        createdBy
        createdByMitarbeiterId
        createTs
        errorList {
          message
          type
        }
        generierDatum
        generierlaufId
        status {
          description
          text
          value
        }
        warningList {
          message
          type
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useVorschreibungMailDeliverySendGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<VorschreibungMailDeliverySendGenerierlaufQuery, VorschreibungMailDeliverySendGenerierlaufQueryVariables> &
    ({ variables: VorschreibungMailDeliverySendGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VorschreibungMailDeliverySendGenerierlaufQuery, VorschreibungMailDeliverySendGenerierlaufQueryVariables>(
    VorschreibungMailDeliverySendGenerierlaufDocument,
    options
  );
}
export function useVorschreibungMailDeliverySendGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VorschreibungMailDeliverySendGenerierlaufQuery, VorschreibungMailDeliverySendGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VorschreibungMailDeliverySendGenerierlaufQuery, VorschreibungMailDeliverySendGenerierlaufQueryVariables>(
    VorschreibungMailDeliverySendGenerierlaufDocument,
    options
  );
}
export function useVorschreibungMailDeliverySendGenerierlaufSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<VorschreibungMailDeliverySendGenerierlaufQuery, VorschreibungMailDeliverySendGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VorschreibungMailDeliverySendGenerierlaufQuery, VorschreibungMailDeliverySendGenerierlaufQueryVariables>(
    VorschreibungMailDeliverySendGenerierlaufDocument,
    options
  );
}
export type VorschreibungMailDeliverySendGenerierlaufQueryHookResult = ReturnType<typeof useVorschreibungMailDeliverySendGenerierlaufQuery>;
export type VorschreibungMailDeliverySendGenerierlaufLazyQueryHookResult = ReturnType<typeof useVorschreibungMailDeliverySendGenerierlaufLazyQuery>;
export type VorschreibungMailDeliverySendGenerierlaufSuspenseQueryHookResult = ReturnType<
  typeof useVorschreibungMailDeliverySendGenerierlaufSuspenseQuery
>;
export type VorschreibungMailDeliverySendGenerierlaufQueryResult = Apollo.QueryResult<
  VorschreibungMailDeliverySendGenerierlaufQuery,
  VorschreibungMailDeliverySendGenerierlaufQueryVariables
>;
export const VorschreibungMailDeliverySendGenerierlaufEntryListDocument = gql`
  query VorschreibungMailDeliverySendGenerierlaufEntryList(
    $generierlaufId: ID!
    $objektId: String
    $emailAddress: String
    $vertragspartnerId: String
    $recipientId: String
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $order: VorschreibungMailDeliverySendGenLaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getVorschreibungMailDeliverySendGenerierlaufEntryList(
      generierlaufId: $generierlaufId
      objektId: $objektId
      emailAddress: $emailAddress
      vertragspartnerId: $vertragspartnerId
      recipientId: $recipientId
      exitCodeList: $exitCodeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          errorList {
            message
            type
          }
          exitCode {
            text
            value
          }
          generierlaufEntryId
          infoMessage
          vorschreibungMailDelivery {
            ...VorschreibungMailDeliveryFields
          }
          warningList {
            message
            type
          }
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${VorschreibungMailDeliveryFieldsFragmentDoc}
`;
export function useVorschreibungMailDeliverySendGenerierlaufEntryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    VorschreibungMailDeliverySendGenerierlaufEntryListQuery,
    VorschreibungMailDeliverySendGenerierlaufEntryListQueryVariables
  > &
    ({ variables: VorschreibungMailDeliverySendGenerierlaufEntryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VorschreibungMailDeliverySendGenerierlaufEntryListQuery, VorschreibungMailDeliverySendGenerierlaufEntryListQueryVariables>(
    VorschreibungMailDeliverySendGenerierlaufEntryListDocument,
    options
  );
}
export function useVorschreibungMailDeliverySendGenerierlaufEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VorschreibungMailDeliverySendGenerierlaufEntryListQuery,
    VorschreibungMailDeliverySendGenerierlaufEntryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VorschreibungMailDeliverySendGenerierlaufEntryListQuery,
    VorschreibungMailDeliverySendGenerierlaufEntryListQueryVariables
  >(VorschreibungMailDeliverySendGenerierlaufEntryListDocument, options);
}
export function useVorschreibungMailDeliverySendGenerierlaufEntryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VorschreibungMailDeliverySendGenerierlaufEntryListQuery,
        VorschreibungMailDeliverySendGenerierlaufEntryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    VorschreibungMailDeliverySendGenerierlaufEntryListQuery,
    VorschreibungMailDeliverySendGenerierlaufEntryListQueryVariables
  >(VorschreibungMailDeliverySendGenerierlaufEntryListDocument, options);
}
export type VorschreibungMailDeliverySendGenerierlaufEntryListQueryHookResult = ReturnType<
  typeof useVorschreibungMailDeliverySendGenerierlaufEntryListQuery
>;
export type VorschreibungMailDeliverySendGenerierlaufEntryListLazyQueryHookResult = ReturnType<
  typeof useVorschreibungMailDeliverySendGenerierlaufEntryListLazyQuery
>;
export type VorschreibungMailDeliverySendGenerierlaufEntryListSuspenseQueryHookResult = ReturnType<
  typeof useVorschreibungMailDeliverySendGenerierlaufEntryListSuspenseQuery
>;
export type VorschreibungMailDeliverySendGenerierlaufEntryListQueryResult = Apollo.QueryResult<
  VorschreibungMailDeliverySendGenerierlaufEntryListQuery,
  VorschreibungMailDeliverySendGenerierlaufEntryListQueryVariables
>;
