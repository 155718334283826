import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { VorsteuerkuerzungListEntryFragmentDoc, VorsteuerkuerzungFragmentDoc } from './VorsteuerkuerzungFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VorsteuerkuerzungListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
}>;

export type VorsteuerkuerzungListQuery = {
  getVorsteuerkuerzungList: {
    data: Array<{
      bisInklusive: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      sumVstKuerzung: number;
      vonInklusive: string;
      vorsteuerkuerzungId: string;
      buchungStatus: { description?: string | null; text: string; value: Types.VorsteuerkuerzungBuchungStatus };
      rechnungsAussteller: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      status: { description?: string | null; text: string; value: Types.VorsteuerkuerzungStatus };
      vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type VorsteuerkuerzungQueryVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  vorsteuerkuerzungId: Types.Scalars['ID']['input'];
}>;

export type VorsteuerkuerzungQuery = {
  getVorsteuerkuerzung: {
    data: {
      belegnummer?: string | null;
      bisInklusive: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      fibuBuchungIdList: Array<string>;
      lastBuchungsdatum?: string | null;
      sumBetrag: number;
      sumVstKuerzung: number;
      updatedByMitarbeiterId?: string | null;
      vonInklusive: string;
      vorsteuerkuerzungId: string;
      buchungErrorList: Array<{ message: string; type: string }>;
      buchungStatus: { description?: string | null; text: string; value: Types.VorsteuerkuerzungBuchungStatus };
      errorList: Array<{ message: string; type: string }>;
      objekt: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        objektId: string;
        updatedByMitarbeiterId?: string | null;
        rechnungsAussteller: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        status: { text: string; value: Types.ObjektStatus; description?: string | null };
        verwaltungsart: { text: string; value: Types.Verwaltungsart };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      rechnungsAussteller: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      status: { description?: string | null; text: string; value: Types.VorsteuerkuerzungStatus };
      vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
      vorsteuerkuerzungVertragList: Array<{
        fibuBuchungIdList: Array<string>;
        sumSteuer: number;
        sumVstKuerzung: number;
        bestandseinheit: {
          bestandseinheitId: string;
          bezeichnung: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          objektId: string;
          status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        vertrag: {
          bestandseinheitId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          objektId: string;
          vertragId: string;
          vertragsBeginn: string;
          status: { text: string; value: Types.VertragStatus; description?: string | null };
          vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
          vertragspartner: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        vorsteuerkuerzungKontoList: Array<{
          sumSteuer: number;
          sumVstKuerzung: number;
          vorsteuerkuerzungKontoId: string;
          aufwandsKontoList: Array<{
            aufwandsKontoId: string;
            sumSteuer: number;
            sumVstKuerzung: number;
            aufteilungList: Array<{
              anteilTopAufteilungsschluessel: number;
              belegFileId: string;
              belegId: string;
              belegnummer: string;
              buchungId: string;
              buchungsdatum: string;
              einheitskosten: number;
              gesamtAnteilAufteilungsschluessel: number;
              standingInvoicePartialAmountId?: string | null;
              steuer: number;
              vstKuerzungAnteil: number;
              aufteilungsschluessel: {
                aufteilungsschluesselId: string;
                bezeichnung: string;
                bezugsbasisEditable?: boolean | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                firmendatenId?: string | null;
                kurzBezeichnung: string;
                updatedByMitarbeiterId?: string | null;
                bezugsbasis: { text: string; value: Types.Bezugsbasis };
                masseinheit: { text: string; value: Types.Masseinheit };
                status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              lieferant: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
            }>;
          }>;
        }>;
      }>;
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type VorsteuerkuerzungBuchungStatusListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type VorsteuerkuerzungBuchungStatusListQuery = {
  getVorsteuerkuerzungBuchungStatusList: {
    data: Array<{ description?: string | null; text: string; value: Types.VorsteuerkuerzungBuchungStatus }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const VorsteuerkuerzungListDocument = gql`
  query VorsteuerkuerzungList($objektId: String!) {
    getVorsteuerkuerzungList(objektId: $objektId) {
      data {
        ...VorsteuerkuerzungListEntry
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VorsteuerkuerzungListEntryFragmentDoc}
`;
export function useVorsteuerkuerzungListQuery(
  baseOptions: Apollo.QueryHookOptions<VorsteuerkuerzungListQuery, VorsteuerkuerzungListQueryVariables> &
    ({ variables: VorsteuerkuerzungListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VorsteuerkuerzungListQuery, VorsteuerkuerzungListQueryVariables>(VorsteuerkuerzungListDocument, options);
}
export function useVorsteuerkuerzungListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VorsteuerkuerzungListQuery, VorsteuerkuerzungListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VorsteuerkuerzungListQuery, VorsteuerkuerzungListQueryVariables>(VorsteuerkuerzungListDocument, options);
}
export function useVorsteuerkuerzungListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VorsteuerkuerzungListQuery, VorsteuerkuerzungListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VorsteuerkuerzungListQuery, VorsteuerkuerzungListQueryVariables>(VorsteuerkuerzungListDocument, options);
}
export type VorsteuerkuerzungListQueryHookResult = ReturnType<typeof useVorsteuerkuerzungListQuery>;
export type VorsteuerkuerzungListLazyQueryHookResult = ReturnType<typeof useVorsteuerkuerzungListLazyQuery>;
export type VorsteuerkuerzungListSuspenseQueryHookResult = ReturnType<typeof useVorsteuerkuerzungListSuspenseQuery>;
export type VorsteuerkuerzungListQueryResult = Apollo.QueryResult<VorsteuerkuerzungListQuery, VorsteuerkuerzungListQueryVariables>;
export const VorsteuerkuerzungDocument = gql`
  query Vorsteuerkuerzung($objektId: String!, $vorsteuerkuerzungId: ID!) {
    getVorsteuerkuerzung(objektId: $objektId, vorsteuerkuerzungId: $vorsteuerkuerzungId) {
      data {
        ...Vorsteuerkuerzung
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VorsteuerkuerzungFragmentDoc}
`;
export function useVorsteuerkuerzungQuery(
  baseOptions: Apollo.QueryHookOptions<VorsteuerkuerzungQuery, VorsteuerkuerzungQueryVariables> &
    ({ variables: VorsteuerkuerzungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VorsteuerkuerzungQuery, VorsteuerkuerzungQueryVariables>(VorsteuerkuerzungDocument, options);
}
export function useVorsteuerkuerzungLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VorsteuerkuerzungQuery, VorsteuerkuerzungQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VorsteuerkuerzungQuery, VorsteuerkuerzungQueryVariables>(VorsteuerkuerzungDocument, options);
}
export function useVorsteuerkuerzungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VorsteuerkuerzungQuery, VorsteuerkuerzungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VorsteuerkuerzungQuery, VorsteuerkuerzungQueryVariables>(VorsteuerkuerzungDocument, options);
}
export type VorsteuerkuerzungQueryHookResult = ReturnType<typeof useVorsteuerkuerzungQuery>;
export type VorsteuerkuerzungLazyQueryHookResult = ReturnType<typeof useVorsteuerkuerzungLazyQuery>;
export type VorsteuerkuerzungSuspenseQueryHookResult = ReturnType<typeof useVorsteuerkuerzungSuspenseQuery>;
export type VorsteuerkuerzungQueryResult = Apollo.QueryResult<VorsteuerkuerzungQuery, VorsteuerkuerzungQueryVariables>;
export const VorsteuerkuerzungBuchungStatusListDocument = gql`
  query VorsteuerkuerzungBuchungStatusList {
    getVorsteuerkuerzungBuchungStatusList {
      data {
        description
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useVorsteuerkuerzungBuchungStatusListQuery(
  baseOptions?: Apollo.QueryHookOptions<VorsteuerkuerzungBuchungStatusListQuery, VorsteuerkuerzungBuchungStatusListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VorsteuerkuerzungBuchungStatusListQuery, VorsteuerkuerzungBuchungStatusListQueryVariables>(
    VorsteuerkuerzungBuchungStatusListDocument,
    options
  );
}
export function useVorsteuerkuerzungBuchungStatusListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VorsteuerkuerzungBuchungStatusListQuery, VorsteuerkuerzungBuchungStatusListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VorsteuerkuerzungBuchungStatusListQuery, VorsteuerkuerzungBuchungStatusListQueryVariables>(
    VorsteuerkuerzungBuchungStatusListDocument,
    options
  );
}
export function useVorsteuerkuerzungBuchungStatusListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<VorsteuerkuerzungBuchungStatusListQuery, VorsteuerkuerzungBuchungStatusListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VorsteuerkuerzungBuchungStatusListQuery, VorsteuerkuerzungBuchungStatusListQueryVariables>(
    VorsteuerkuerzungBuchungStatusListDocument,
    options
  );
}
export type VorsteuerkuerzungBuchungStatusListQueryHookResult = ReturnType<typeof useVorsteuerkuerzungBuchungStatusListQuery>;
export type VorsteuerkuerzungBuchungStatusListLazyQueryHookResult = ReturnType<typeof useVorsteuerkuerzungBuchungStatusListLazyQuery>;
export type VorsteuerkuerzungBuchungStatusListSuspenseQueryHookResult = ReturnType<typeof useVorsteuerkuerzungBuchungStatusListSuspenseQuery>;
export type VorsteuerkuerzungBuchungStatusListQueryResult = Apollo.QueryResult<
  VorsteuerkuerzungBuchungStatusListQuery,
  VorsteuerkuerzungBuchungStatusListQueryVariables
>;
