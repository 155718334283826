import React, { FC } from 'react';
import { FormikProps } from 'formik';
import {
  offenePostenListingFiltersFormFields,
  OffenePostenListingFiltersFormValues,
  offenePostenListingLabelList,
} from './Filters/offenePostenListingFiltersFormMapper';
import FormItemWithoutColon from '../../components/Form/FormItemWithoutColon';
import OPBuchungskreisSelect from './Filters/OPBuchungskreisSelect';
import OPKontoTypeSelect from './Filters/OPKontoTypeSelect';
import KontoFormSelect from '../KontoSelect/KontoFormSelect';
import { KontoType } from '../../types';
import FromToFormDatePicker from '../shared/FromToForm/FromToFormDatePicker';
import { TOffenePostenQueryParams } from './offenePostenUriPaths';
import ObjektSelect from '../ObjektSelect/ObjektSelect';
import OPBuchungsTypeSelect from './Filters/OPBuchungsTypeSelect';
import VertragsartSelect from '../VertragsartSelect/VertragsartSelect';
import FromToFormInputNumber from '../shared/FromToForm/FromToFormInputNumber';
import OPMahnstufeSelect from './Filters/OPMahnstufeSelect';
import RechtstraegerSelect from '../../shared/Rechtstraeger/RechtstraegerSelect';
import MitarbeiterSelect from '../../shared/components/MitarbeiterSelect/MitarbeiterSelect';
import BelegnummerFormSelect from '../../shared/components/Belegnummer/BelegnummerFormSelect';
import OffenePostenListingActionButtons from './OffenePostenListingActionButtons';
import FiltersWith4OrMoreFields from '../../components/Filters/FiltersWith4OrMoreFields';
import FiltersSelect from '../../components/Filters/FiltersSelect';
import { onFilterSelectChange } from '../../components/Filters/FiltersHelpers';
import useFilterWithQueryParams from '../shared/useFilterWithQueryParams';

type Props = {
  formikProps: FormikProps<OffenePostenListingFiltersFormValues>;
  setAusgeziffert: () => void;
  showAusgeziffert: boolean;
  queryParams: TOffenePostenQueryParams;
};

const OffenePostenListingFilters: FC<Props> = ({ formikProps, setAusgeziffert, showAusgeziffert, queryParams }) => {
  const defaultSelectedFilterList = [
    offenePostenListingFiltersFormFields.buchungskreisId,
    offenePostenListingFiltersFormFields.kontoType,
    offenePostenListingFiltersFormFields.dueDateFrom,
    offenePostenListingFiltersFormFields.fibuKontoId,
  ];

  const mapFilterLabelToQueryFilterList = (filter: string) => {
    if (filter === offenePostenListingFiltersFormFields.dueDateFrom || filter === offenePostenListingFiltersFormFields.dueDateTo) {
      return offenePostenListingFiltersFormFields.dueDateFrom;
    }
    if (filter === offenePostenListingFiltersFormFields.belegDatumFrom || filter === offenePostenListingFiltersFormFields.belegDatumTo) {
      return offenePostenListingFiltersFormFields.belegDatumFrom;
    }
    if (filter === offenePostenListingFiltersFormFields.buchungsdatumFrom || filter === offenePostenListingFiltersFormFields.buchungsdatumTo) {
      return offenePostenListingFiltersFormFields.buchungsdatumFrom;
    }
    if (filter === offenePostenListingFiltersFormFields.offenerBetragFrom || filter === offenePostenListingFiltersFormFields.offenerBetragTo) {
      return offenePostenListingFiltersFormFields.offenerBetragFrom;
    }
    if (filter === offenePostenListingFiltersFormFields.createTsFrom || filter === offenePostenListingFiltersFormFields.createTsTo) {
      return offenePostenListingFiltersFormFields.createTsFrom;
    }
    return filter;
  };

  const updateFilterValues = (selectedFilterList: string[]) => {
    if (!selectedFilterList.includes(offenePostenListingFiltersFormFields.dueDateFrom) && formikProps.values.dueDateTo) {
      formikProps.setFieldValue(offenePostenListingFiltersFormFields.dueDateTo, undefined);
    }
    if (!selectedFilterList.includes(offenePostenListingFiltersFormFields.belegDatumFrom) && formikProps.values.belegDatumTo) {
      formikProps.setFieldValue(offenePostenListingFiltersFormFields.belegDatumTo, undefined);
    }
    if (!selectedFilterList.includes(offenePostenListingFiltersFormFields.buchungsdatumFrom) && formikProps.values.buchungsdatumTo) {
      formikProps.setFieldValue(offenePostenListingFiltersFormFields.buchungsdatumTo, undefined);
    }
    if (!selectedFilterList.includes(offenePostenListingFiltersFormFields.offenerBetragFrom) && formikProps.values.offenerBetragTo) {
      formikProps.setFieldValue(offenePostenListingFiltersFormFields.offenerBetragTo, undefined);
    }
    if (!selectedFilterList.includes(offenePostenListingFiltersFormFields.createTsFrom) && formikProps.values.createTsTo) {
      formikProps.setFieldValue(offenePostenListingFiltersFormFields.createTsTo, undefined);
    }
    formikProps.submitForm();
  };

  const filters = (filter: string) => {
    if (filter === offenePostenListingFiltersFormFields.buchungskreisId) {
      return (
        <FormItemWithoutColon name={offenePostenListingFiltersFormFields.buchungskreisId} label="Buchungskreis" key={filter}>
          <OPBuchungskreisSelect name={offenePostenListingFiltersFormFields.buchungskreisId} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === offenePostenListingFiltersFormFields.kontoType) {
      return (
        <FormItemWithoutColon name={offenePostenListingFiltersFormFields.kontoType} label="Kreditor / Debitor" key={filter}>
          <OPKontoTypeSelect name={offenePostenListingFiltersFormFields.kontoType} onChange={formikProps.submitForm} mode="multiple" />
        </FormItemWithoutColon>
      );
    }
    if (filter === offenePostenListingFiltersFormFields.dueDateFrom) {
      return (
        <FormItemWithoutColon name="dueDate" label="Fälligkeit" key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={offenePostenListingFiltersFormFields.dueDateFrom}
            toName={offenePostenListingFiltersFormFields.dueDateTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === offenePostenListingFiltersFormFields.fibuKontoId) {
      return (
        <FormItemWithoutColon name={offenePostenListingFiltersFormFields.fibuKontoId} label="Fibu Konto" key={filter}>
          <KontoFormSelect
            name={offenePostenListingFiltersFormFields.fibuKontoId}
            onChange={formikProps.submitForm}
            kontoType={KontoType.Personenkonto}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === offenePostenListingFiltersFormFields.objektId) {
      return (
        <FormItemWithoutColon name={offenePostenListingFiltersFormFields.objektId} label="Objekt" key={filter}>
          <ObjektSelect name={offenePostenListingFiltersFormFields.objektId} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === offenePostenListingFiltersFormFields.buchungsType) {
      return (
        <FormItemWithoutColon name={offenePostenListingFiltersFormFields.buchungsType} label="Buchungstyp" key={filter}>
          <OPBuchungsTypeSelect name={offenePostenListingFiltersFormFields.buchungsType} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === offenePostenListingFiltersFormFields.vertragsartList) {
      return (
        <FormItemWithoutColon name={offenePostenListingFiltersFormFields.vertragsartList} label="Vertragsart" key={filter}>
          <VertragsartSelect name={offenePostenListingFiltersFormFields.vertragsartList} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === offenePostenListingFiltersFormFields.belegDatumFrom) {
      return (
        <FormItemWithoutColon name="belegDatum" label="Belegdatum" key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={offenePostenListingFiltersFormFields.belegDatumFrom}
            toName={offenePostenListingFiltersFormFields.belegDatumTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === offenePostenListingFiltersFormFields.buchungsdatumFrom) {
      return (
        <FormItemWithoutColon name="buchungsdatum" label="Buchungsdatum" key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={offenePostenListingFiltersFormFields.buchungsdatumFrom}
            toName={offenePostenListingFiltersFormFields.buchungsdatumTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === offenePostenListingFiltersFormFields.offenerBetragFrom) {
      return (
        <FromToFormInputNumber
          name="Offener Betrag"
          fromName={offenePostenListingFiltersFormFields.offenerBetragFrom}
          toName={offenePostenListingFiltersFormFields.offenerBetragTo}
          onChange={formikProps.submitForm}
          key={filter}
        />
      );
    }
    if (filter === offenePostenListingFiltersFormFields.letzteMahnstufe) {
      return (
        <FormItemWithoutColon name={offenePostenListingFiltersFormFields.letzteMahnstufe} label="Letzte Mahnstufe" key={filter}>
          <OPMahnstufeSelect name={offenePostenListingFiltersFormFields.letzteMahnstufe} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === offenePostenListingFiltersFormFields.vertragspartnerId) {
      return (
        <FormItemWithoutColon name={offenePostenListingFiltersFormFields.vertragspartnerId} label="Vertragspartner" key={filter}>
          <RechtstraegerSelect
            name={offenePostenListingFiltersFormFields.vertragspartnerId}
            onChange={formikProps.submitForm}
            placeholder="Vertragspartner auswählen"
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === offenePostenListingFiltersFormFields.createTsFrom) {
      return (
        <FormItemWithoutColon name="erstelltAm" label="Erstellt am" key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={offenePostenListingFiltersFormFields.createTsFrom}
            toName={offenePostenListingFiltersFormFields.createTsTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === offenePostenListingFiltersFormFields.createdByMitarbeiterIdList) {
      return (
        <FormItemWithoutColon name={offenePostenListingFiltersFormFields.createdByMitarbeiterIdList} label="Erstellter" key={filter}>
          <MitarbeiterSelect
            name={offenePostenListingFiltersFormFields.createdByMitarbeiterIdList}
            onChange={formikProps.submitForm}
            mode="multiple"
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === offenePostenListingFiltersFormFields.belegnummer) {
      return (
        <FormItemWithoutColon name={offenePostenListingFiltersFormFields.belegnummer} label="Belegnummer" key={filter}>
          <BelegnummerFormSelect
            name={offenePostenListingFiltersFormFields.belegnummer}
            belegnummer={formikProps.values.belegnummer}
            onChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    return undefined;
  };

  const { selectedFilterList, setSelectedFilterList, leftColumn, rightColumn } = useFilterWithQueryParams({
    defaultSelectedFilterList,
    updateFilterValues,
    filters,
    labelList: offenePostenListingLabelList,
    mapFilterLabelToQueryFilterList,
    queryParams,
  });

  return (
    <FiltersWith4OrMoreFields
      filtersSelect={
        <FiltersSelect
          defaultSelectedFilterList={defaultSelectedFilterList}
          selectedFilterList={selectedFilterList}
          onChange={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
          labelList={offenePostenListingLabelList}
        />
      }
      actionButtons={<OffenePostenListingActionButtons showAusgeziffert={showAusgeziffert} setAusgeziffert={setAusgeziffert} />}
      hideTitle
      leftColumn={<>{leftColumn}</>}
      rightColumn={<>{rightColumn}</>}
    />
  );
};

export default OffenePostenListingFilters;
