import React, { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { Space, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { isStatusArchivedOrGesperrt, IStatus } from '../../helpers/statusHelper';
import DataWithShortenedTextAndExtraInfo from './DataWithShortenedTextAndExtraInfo';
import LinkWithStatus from '../Link/LinkWithStatus';
import { ResponseDataWarning } from '../../types';
import EntityWarningList from '../message/EntityWarningList';
import theme from '../../theme';
import { dayjsCustom } from '../../helpers/dayjsCustom';

type Props = {
  activeFrom?: string;
  isCurrent: boolean;
  linkTo?: string;
  maxTextLength?: number;
  openLinkInNewTab?: boolean;
  status?: IStatus;
  text: string;
  warningList?: ResponseDataWarning[];
};

const CurrentBasedData: FC<Props & PropsWithChildren> = ({
  activeFrom,
  children,
  isCurrent,
  linkTo,
  maxTextLength,
  openLinkInNewTab,
  status,
  text,
  warningList,
}) => {
  const maxLength = maxTextLength || text.length;

  if (linkTo) {
    return (
      <Space size={4}>
        {!isCurrent && activeFrom && <ActiveInfo activeFrom={activeFrom} />}
        {warningList && <EntityWarningList warningList={warningList} />}
        {status && isStatusArchivedOrGesperrt(status) ? (
          <LinkWithStatus status={status} text={text} maxTextLength={maxLength} to={linkTo} openInNewTab={openLinkInNewTab} />
        ) : (
          <DataWithShortenedTextAndExtraInfo maxTextLength={maxLength} text={text}>
            {(shortenedText) => (
              <Link
                to={linkTo}
                style={{ color: isCurrent ? theme.linkColor.default : theme.linkColor.secondary }}
                target={openLinkInNewTab ? '_blank' : '_self'}
              >
                {children ?? shortenedText}
              </Link>
            )}
          </DataWithShortenedTextAndExtraInfo>
        )}
      </Space>
    );
  }

  return (
    <Space size={4}>
      {!isCurrent && activeFrom && <ActiveInfo activeFrom={activeFrom} />}
      {warningList && <EntityWarningList warningList={warningList} />}
      <DataWithShortenedTextAndExtraInfo text={text} maxTextLength={maxLength}>
        {(shortenedText) =>
          isCurrent ? (
            <Typography.Text>{children ?? shortenedText}</Typography.Text>
          ) : (
            <Typography.Text style={{ color: theme.textColor.disabled }}>{children ?? shortenedText}</Typography.Text>
          )
        }
      </DataWithShortenedTextAndExtraInfo>
    </Space>
  );
};

type ActiveInfoProps = { activeFrom: string };

const ActiveInfo: FC<ActiveInfoProps> = ({ activeFrom }) => (
  <Tooltip placement="top" title={`aktiv ${dayjsCustom(activeFrom).fromNow()}`}>
    <InfoCircleOutlined style={{ cursor: 'help' }} />
  </Tooltip>
);

export default CurrentBasedData;
