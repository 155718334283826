import styled from 'styled-components';
import { Box } from 'rebass';
import { Card, Layout } from 'antd';

type Props = {
  alignment?: 'left' | 'right' | 'center';
};

export const StyledCardWithFooter = styled(Card)`
  border-radius: 4px 4px 0 0;
  .ant-card-head {
    border-bottom: none;
  }
`;

export const StyledContentTab = styled(Layout.Content)`
  background: #fff; /* red to debug */
  padding: 0 16px 0 16px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
`;

export const StyledCol = styled(Box)<Props>`
  text-align: ${(p) => p.alignment};
`;
