import React, { FC } from 'react';
import { Tooltip, Typography } from 'antd';
import { shortenTextIfNeeded } from '../../../helpers/stringHelper';

type Props = {
  texts: string[];
  maxTextLength: number;
  textSplitWith?: string;
  addLineBreakAt?: number;
};

const TextsWithTooltipForOrderTypeList: FC<Props> = ({ maxTextLength, texts, addLineBreakAt, textSplitWith = ',' }) => {
  // EOP,STM,HTD,HKD,OTH,HAA,HAC,HPB,HPD,HVD,HVT,HVU,HVZ,H3K,HCA,HCS,HIA,HSA,HVE,HVS,INI,PUB,SPR,IDC,XCT,SDD,SCT
  const fullText = texts.join(textSplitWith);

  // EOP,STM,HTD,HKD…
  const textAsString = shortenTextIfNeeded(fullText, maxTextLength);

  // ,OTH,HAA,HAC,HPB,HPD,HVD,HVT,HVU,HVZ,H3K,HCA,HCS,HIA,HSA,HVE,HVS,INI,PUB,SPR,IDC,XCT,SDD,SCT
  const tooltipText = fullText.slice(maxTextLength);

  // OTH,HAA,HAC,HPB,HPD,HVD,HVT,HVU,HVZ,IDC,H3K,HCA,HCS,HIA,HSA,HVE,HVS,INI,PUB,SPR,XCT,SDD,SCT
  const tooltipTextWithNoSpecialCharAtStart = removeFirstSpecialCharAtStart(tooltipText, 0, textSplitWith);

  /*
  i.e addLineBreak after each 4th comma
  OTH,HAA,HAC,HPB,
  HPD,HVD,HVT,HVU,
  HVZ,H3K,HCA,HCS,
  HIA,HSA,HVE,HVS,
  INI,PUB,SPR,IDC,
  XCT,SDD,SCT
   */
  const tooltipTextWithOrWithoutLineBreaks = addLineBreaks(tooltipTextWithNoSpecialCharAtStart, addLineBreakAt);

  return (
    <Tooltip title={tooltipTextWithOrWithoutLineBreaks} styles={{ root: { whiteSpace: addLineBreakAt ? 'pre' : 'unset' } }}>
      <Typography.Text>{textAsString}</Typography.Text>
    </Tooltip>
  );
};

const addLineBreaks = (str: string, num?: number) => {
  if (!num) return str;

  const arr = str.split(',');
  return arr.reduce((acc, val, i) => {
    if (i === arr.length - 1) {
      return acc + val; // No comma for the last element
    }
    return acc + val + ((i + 1) % num === 0 ? ', \n' : ',');
  }, '');
};

const removeFirstSpecialCharAtStart = (value: string, position: number, specialChar?: string): string => {
  const char = specialChar ?? ',';
  const specificCharOfValue = value.at(position);
  if (specificCharOfValue && char === specificCharOfValue) {
    return value.slice(0, position) + value.slice(position + 1);
  }
  return value;
};

export default TextsWithTooltipForOrderTypeList;
