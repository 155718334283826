import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Empty } from 'antd';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import GenerierlaufStatusExpandedRow from '../../../shared/GenerierlaufStatusExpandedRow';
import { isVerarbeitungEntryTableRowExpandable } from '../../../verarbeitungHelpers';
import { TopFinancialPlanGenerierlauf, TopFinancialPlanGenerierlaufEntry } from '../../../../../types';
import { TopFinancialPlanVerarbeitungEntryFiltersFormValues } from '../Filters/ListingFiltersFormMapper';
import topFinancialPlanVerarbeitungEntryTableColumns from './topFinancialPlanVerarbeitungEntryTableColumns';

type Props = {
  generierlauf: TopFinancialPlanGenerierlauf;
  formikProps: FormikProps<TopFinancialPlanVerarbeitungEntryFiltersFormValues>;
};

const TopFinancialPlanVerarbeitungEntryTable: FC<Props> = ({ generierlauf, formikProps }) => {
  const { exitCodeList, objektId, bestandseinheitId, vertragspartnerId, budgetingId, rechnungsAusstellerId } = formikProps.values;
  const objektFilter = () => {
    if (!objektId) return true;
    return objektId === generierlauf.budgeting.objekt.objektId;
  };

  const beststandseinenheitFilter = (entry: TopFinancialPlanGenerierlaufEntry) => {
    if (!bestandseinheitId) return true;
    return bestandseinheitId === entry.bestandseinheit?.bestandseinheitId;
  };

  const budgetingFilter = () => {
    if (!budgetingId) return true;
    return budgetingId === generierlauf.budgeting.budgetingId;
  };

  const rechnungsAusstellerFilter = () => {
    if (!rechnungsAusstellerId) return true;
    return rechnungsAusstellerId === generierlauf.budgeting.objekt.rechnungsAussteller.rechtstraegerId;
  };

  const vertragspartnerFilter = (entry: TopFinancialPlanGenerierlaufEntry) => {
    if (!vertragspartnerId) return true;
    return vertragspartnerId === entry.vertrag?.vertragspartner.rechtstraegerId;
  };

  const exitCodeStatusFilter = (entry: TopFinancialPlanGenerierlaufEntry) => {
    if (!(exitCodeList && exitCodeList.length)) return true;
    return entry.exitCode?.text ? exitCodeList.includes(entry.exitCode.value) : false;
  };

  const filteredEntryList = generierlauf.entryList
    .filter(rechnungsAusstellerFilter)
    .filter(vertragspartnerFilter)
    .filter(budgetingFilter)
    .filter(objektFilter)
    .filter(beststandseinenheitFilter)
    .filter(exitCodeStatusFilter);

  return (
    <TableWithColSelector<TopFinancialPlanGenerierlaufEntry>
      loading={!generierlauf.entryList}
      locale={{
        emptyText: <Empty description={<span>Keine Daten vorhanden</span>} />,
      }}
      size="small"
      dataSource={filteredEntryList}
      columns={topFinancialPlanVerarbeitungEntryTableColumns(generierlauf.budgeting)}
      rowKey={(record) => record.generierlaufEntryId}
      expandable={{
        expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
        rowExpandable: isVerarbeitungEntryTableRowExpandable,
      }}
      filterIdentifier="verarbeitung-top-financial-plan"
    />
  );
};

export default TopFinancialPlanVerarbeitungEntryTable;
