import React, { FC } from 'react';
import { Empty, Table, Typography } from 'antd';
import verarbeitungErrorAndWarningTableColumns from './verarbeitungErrorAndWarningTableColumns';
import { ErrorData, ResponseDataWarning } from '../../../../types';
import { Spacer } from '../../../../components/Grid';

type Props = {
  errorOrWarningList: ErrorData[] | ResponseDataWarning[];
  type: 'error' | 'warning';
};

const VerarbeitungErrorAndWarningTable: FC<Props> = ({ errorOrWarningList, type }) => (
  <>
    <Typography.Title level={2}>{type === 'error' ? 'Fehler' : 'Warnungen'}</Typography.Title>
    <Spacer />
    <Table<ErrorData>
      size="small"
      dataSource={errorOrWarningList}
      columns={verarbeitungErrorAndWarningTableColumns}
      pagination={false}
      locale={{
        emptyText: <Empty description={<span>Keine Fehler vorhanden</span>} />,
      }}
    />
  </>
);

export default VerarbeitungErrorAndWarningTable;
