import React, { FC } from 'react';
import { MenuProps } from 'antd';
import { ReadOutlined, TruckOutlined } from '@ant-design/icons';
import { IncomingInvoiceBookingCreationGenerierlaufListEntry } from '../../../../../types';
import ActionDropdown from '../../../../../components/Dropdown/ActionDropdown';
import { generatePathToIncomingInvoiceListingPage } from '../../../../IncomingInvoice/Listing/Filters/filtersQueryParams';
import { generatePathToBookingDetailsPage } from '../../../../BookingDetails/bookingDetailsPaths';

type Props = {
  record: IncomingInvoiceBookingCreationGenerierlaufListEntry;
};

const TableActions: FC<Props> = ({ record }) => {
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Eingangsrechnung anzeigen',
      onClick: () =>
        window.open(
          generatePathToIncomingInvoiceListingPage({
            issuerRechtstraegerId: record.issuerRechtstraegerId,
            payeeRechtstraegerId: record.payeeRechtstraegerId,
            objektId: record.objektId,
            invoiceNumber: record.rechnungsNummer,
            invoiceDateFrom: record.rechnungsDatum,
            invoiceDateTo: record.rechnungsDatum,
          })
        ),
      icon: <TruckOutlined />,
    },
    {
      key: '2',
      label: 'Buchungsdetails ansehen',
      onClick: () => window.open(generatePathToBookingDetailsPage(record.buchungIdList)),
      disabled: record.buchungIdList.length === 0,
      icon: <ReadOutlined />,
    },
  ];

  return <ActionDropdown items={items} />;
};

export default TableActions;
