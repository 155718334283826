import React from 'react';
import { useFormikContext } from 'formik';
import { FieldArrayInputProps } from './FieldArrayInputProps';
import FieldArrayInputContent from './FieldArrayInputContent';

export function FieldArrayInput<T>({
  arrayName,
  arrayObjectAttribute,
  newFieldValue,
  validateOnChange = true,
  fieldValidation,
  label,
  placeholder,
}: FieldArrayInputProps) {
  const { values, errors } = useFormikContext<T>();
  return (
    <FieldArrayInputContent
      formikValues={values}
      formikErrors={errors}
      arrayName={arrayName}
      arrayObjectAttribute={arrayObjectAttribute}
      newFieldValue={newFieldValue}
      validateOnChange={validateOnChange}
      fieldValidation={fieldValidation}
      label={label}
      placeholder={placeholder}
    />
  );
}

export default FieldArrayInput;
