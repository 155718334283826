import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useDataCarrierListQuery } from '../../gql/DataCarrierQueries.types';
import { selectFilterOption } from '../../../../helpers/selectHelper';

type Props = Omit<SelectProps, 'id' | 'mode' | 'placeholder' | 'size' | 'allowClear' | 'showSearch' | 'filterOption'>;

const DataCarrierListSelect: FC<Props> = ({ ...restProps }) => {
  const { loading, data } = useDataCarrierListQuery();
  const list = data?.getDataCarrierList.data ?? [];

  return (
    <Select
      {...restProps}
      size="small"
      mode="multiple"
      id={restProps.name}
      loading={loading}
      placeholder="Datenträger auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {list.map((dataCarrier) => (
        <Select.Option key={dataCarrier.dataCarrierId} value={dataCarrier.dataCarrierId}>
          {dataCarrier.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default DataCarrierListSelect;
