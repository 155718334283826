import { FC } from 'react';
import { FormikProps } from 'formik';
import FiltersWith4OrMoreFields from '../../../../../../components/Filters/FiltersWith4OrMoreFields';
import FormItemWithoutColon from '../../../../../../components/Form/FormItemWithoutColon';
import { BelegVerarbeitungEntryFiltersFormValues, belegVerarbEntryListingFiltersFormFields } from './ListingFiltersFormMapper';
import { OrderGenerierlaufType } from '../../../../../../types';
import GenerierlaufFakturierungsperiodeFormSelect from '../../../../shared/Filters/Order/GenerierlaufFakturierungsperiodeFormSelect';
import GenerierlaufObjektFormSelect from '../../../../shared/Filters/Order/GenerierlaufObjektFormSelect';
import GenerierlaufEntryExitCodeListFormSelect from '../../../../shared/Filters/Order/GenerierlaufEntryExitCodeListFormSelect';
import GenerierlaufRechnungsAusstellerFormSelect from '../../../../shared/Filters/Order/GenerierlaufRechnungsAusstellerFormSelect';
import GenerierlaufRechnungsEmpfaengerFormSelect from '../../../../shared/Filters/Order/GenerierlaufRechnungsEmpfaengerFormSelect';
import GenerierlaufBelegnummerFormSelect from '../../../../shared/Filters/Order/GenerierlaufBelegnummerFormSelect';

type Props = {
  formikProps: FormikProps<BelegVerarbeitungEntryFiltersFormValues>;
  generierlaufId: string;
};

const ListingFilters: FC<Props> = ({ formikProps, generierlaufId }) => {
  return (
    <FiltersWith4OrMoreFields
      hideTitle
      leftColumn={
        <>
          <FormItemWithoutColon name={belegVerarbEntryListingFiltersFormFields.fakturierungsperiode} label="Zinsliste-Fakturierungsperiode">
            <GenerierlaufFakturierungsperiodeFormSelect
              name={belegVerarbEntryListingFiltersFormFields.fakturierungsperiode}
              id={belegVerarbEntryListingFiltersFormFields.fakturierungsperiode}
              generierlaufId={generierlaufId}
              generierlaufType={OrderGenerierlaufType.Belegausgabe}
              style={{ width: '100%' }}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={belegVerarbEntryListingFiltersFormFields.objektId} label="Objekt">
            <GenerierlaufObjektFormSelect
              name={belegVerarbEntryListingFiltersFormFields.objektId}
              generierlaufId={generierlaufId}
              generierlaufType={OrderGenerierlaufType.Belegausgabe}
              rechnungsAusstellerId={formikProps.values.ausstellerId}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={belegVerarbEntryListingFiltersFormFields.exitCodeList} label="Status">
            <GenerierlaufEntryExitCodeListFormSelect
              name={belegVerarbEntryListingFiltersFormFields.exitCodeList}
              generierlaufId={generierlaufId}
              generierlaufType={OrderGenerierlaufType.Belegausgabe}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
      rightColumn={
        <>
          <FormItemWithoutColon name={belegVerarbEntryListingFiltersFormFields.ausstellerId} label="Rechnungsaussteller">
            <GenerierlaufRechnungsAusstellerFormSelect
              name={belegVerarbEntryListingFiltersFormFields.ausstellerId}
              generierlaufId={generierlaufId}
              generierlaufType={OrderGenerierlaufType.Belegausgabe}
              onChange={() => {
                formikProps.setFieldValue(belegVerarbEntryListingFiltersFormFields.objektId, null);
                formikProps.submitForm();
              }}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={belegVerarbEntryListingFiltersFormFields.empfaengerId} label="Rechnungsempfänger">
            <GenerierlaufRechnungsEmpfaengerFormSelect
              name={belegVerarbEntryListingFiltersFormFields.empfaengerId}
              generierlaufId={generierlaufId}
              generierlaufType={OrderGenerierlaufType.Belegausgabe}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={belegVerarbEntryListingFiltersFormFields.belegnummer} label="Belegnummer">
            <GenerierlaufBelegnummerFormSelect
              name={belegVerarbEntryListingFiltersFormFields.belegnummer}
              generierlaufId={generierlaufId}
              generierlaufType={OrderGenerierlaufType.Belegausgabe}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
    />
  );
};

export default ListingFilters;
