import React, { FC, JSX } from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Dropdown, MenuProps, Row, Space, Typography } from 'antd';
import { Box } from 'rebass';
import { TextbausteinType } from '../../../types';
import { Spacer } from '../../Grid';
import PDFTemplatePDFViewer from './PDFTemplatePDFViewer';

type PDFTemplatePageProps = {
  textbausteinList: JSX.Element;
  renderPreview: (props: TemplatePreviewProps) => React.ReactNode;
  selectedTextbaustein: TextbausteinType | undefined;
  dimensions?: {
    width: string;
    height: string;
  };
  menuItems?: MenuProps['items'];
};

const PDFTemplatePage: FC<PDFTemplatePageProps> = ({ renderPreview, textbausteinList, selectedTextbaustein, dimensions, menuItems }) => {
  return (
    <Row gutter={8}>
      <Col span={8}>
        <Spacer />
        <Box bg="#FAFAFA" px="16px" py="16px" style={{ boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.09)' }}>
          <Space align="start">
            <Typography.Text style={{ marginBottom: 0 }} strong>
              Textbausteine
            </Typography.Text>
            {menuItems && (
              <Dropdown menu={{ items: menuItems }}>
                <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
              </Dropdown>
            )}
          </Space>
        </Box>
        <Spacer />
        {textbausteinList}
      </Col>

      <Col>
        <Divider type="vertical" style={{ height: '100%' }} />
      </Col>

      <Col span={15}>
        <PDFTemplatePDFViewer dimensions={dimensions}>{renderPreview({ selectedTextbaustein })}</PDFTemplatePDFViewer>
      </Col>
    </Row>
  );
};

export default PDFTemplatePage;

export type TemplatePreviewProps = {
  selectedTextbaustein?: TextbausteinType;
};
