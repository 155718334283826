import React, { FC } from 'react';
import { FieldArrayRenderProps, FormikProps } from 'formik';
import { auszifferungTableColumns } from '../../shared/Level2/auszifferungTableColumns';
import { AuszifferungFormValues } from '../../auszifferungFormMapper';
import {
  BookingSuggestionFormValues,
  bsOffenePostenForClearingSuggestionFormFields,
  OffenePostenForClearingSuggestion,
} from '../../../Form/buchungsmaskeFormMapper';
import { StyledTableForEntriesFromThisZahlung } from '../../../../../BookingDetails/View/shared/AuszifferungBlock/Listings/shared/Level2/StyledTableForThisZahlung.style';
import { isAuszifferungSourceStorno } from '../../../../../BookingDetails/shared/auszifferungHelpers';

type Props = {
  offenePosten: OffenePostenForClearingSuggestion;
  offenePostenIndex: number;
  arrayHelpers: FieldArrayRenderProps;
  formikProps: FormikProps<BookingSuggestionFormValues>;
  fieldNamePrefix: string;
};

const AuszifferungFromBookingSuggTable: FC<Props> = ({ offenePosten, arrayHelpers, formikProps, offenePostenIndex, fieldNamePrefix }) => {
  const auszifferungListFormFieldName = `${fieldNamePrefix}.${bsOffenePostenForClearingSuggestionFormFields.auszifferungList}`;

  return (
    <StyledTableForEntriesFromThisZahlung<AuszifferungFormValues>
      rowKey={(record) => record.auszifferungId}
      columns={auszifferungTableColumns(auszifferungListFormFieldName, offenePosten, offenePostenIndex, arrayHelpers, formikProps, false)}
      dataSource={offenePosten.auszifferungList}
      pagination={false}
      rowClassName={(record) => (!isAuszifferungSourceStorno(record.source?.value) && !record.zahlungId ? 'row-entry-from-this-zahlung' : '')}
    />
  );
};

export default AuszifferungFromBookingSuggTable;
