import React, { FC } from 'react';
import { Empty } from 'antd';
import { FieldArray, FormikProps } from 'formik';
import { useTableExpandedRowKeys } from '../../../../../../components/Table/shared/useTableExpandedRowKeys';
import TableWithColSelector from '../../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { offenePostenTableColumns } from '../../shared/Level1/offenePostenTableColumns';
import AuszifferungFromBookingSuggTable from '../Level2/AuszifferungFromBookingSuggTable';
import { bookingSuggestionFormFields, BookingSuggestionFormValues, OffenePostenForClearingSuggestion } from '../../../Form/buchungsmaskeFormMapper';

type Props = {
  dataList: OffenePostenForClearingSuggestion[];
  formikProps: FormikProps<BookingSuggestionFormValues>;
};

const OffenePostenFromBookingSuggTable: FC<Props> = ({ dataList, formikProps }) => {
  const { expandedRowKeys, toggleExpandedRowKey } = useTableExpandedRowKeys();

  const fieldNamePrefix = bookingSuggestionFormFields.offenePostenForClearingSuggestionList;
  return (
    <FieldArray
      name={fieldNamePrefix}
      render={(arrayHelpers) => {
        const toggleExpRowKey = (rowKey: string) => {
          !expandedRowKeys.includes(rowKey) && toggleExpandedRowKey(rowKey);
        };

        return (
          <TableWithColSelector<OffenePostenForClearingSuggestion>
            size="small"
            loading={!dataList}
            locale={{
              emptyText: <Empty description="Keine Auszifferungsvorschläge gefunden" />,
            }}
            dataSource={dataList}
            columns={offenePostenTableColumns(fieldNamePrefix, toggleExpRowKey, false)}
            rowKey={(record) => record.offenePostenId}
            expandable={{
              expandedRowKeys,
              onExpand: (expanded, record) => toggleExpandedRowKey(record.offenePostenId),
              expandedRowRender: (record, index) => (
                <AuszifferungFromBookingSuggTable
                  fieldNamePrefix={`${fieldNamePrefix}[${index}]`}
                  offenePosten={record}
                  offenePostenIndex={index}
                  arrayHelpers={arrayHelpers}
                  formikProps={formikProps}
                />
              ),
            }}
            filterIdentifier="main-zahlungserfassung-offene-posten"
          />
        );
      }}
    />
  );
};

export default OffenePostenFromBookingSuggTable;
