import React from 'react';
import { Row } from 'antd';
import TopAbrechnungTemplate from './templates/topAbrechnung/TopAbrechnungTemplate';
import { mockTopAbrechnungListWith1Top, mockTopAbrechnungListWith2Tops } from './mocks/topAbrechnungMocks';
import PDFPageCol from './PDFPageCol';

const PDFPageTopAbrechnung = () => (
  <Row>
    <PDFPageCol pdfTemplate={<TopAbrechnungTemplate data={mockTopAbrechnungListWith1Top} />} />
    <PDFPageCol pdfTemplate={<TopAbrechnungTemplate data={mockTopAbrechnungListWith2Tops} />} />
  </Row>
);

export default PDFPageTopAbrechnung;
