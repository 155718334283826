import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { KontoFieldsFragmentDoc, KontenplanFieldsFragmentDoc } from '../../Kontenplan/gql/KontenplanFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KontoTemplateListQueryVariables = Types.Exact<{
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  klasseList?: Types.InputMaybe<Array<Types.KontoKlasse> | Types.KontoKlasse>;
  type?: Types.InputMaybe<Types.KontoType>;
  verwendung?: Types.InputMaybe<Types.KontoVerwendung>;
}>;

export type KontoTemplateListQuery = {
  getKontoTemplateList: {
    data: Array<{
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      haupt: boolean;
      kontoId: string;
      kontoTemplateId?: string | null;
      nummer: string;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      klasse: { text: string; value: Types.KontoKlasse };
      status: { description?: string | null; text: string; value: Types.KontoStatus };
      type: { text: string; value: Types.KontoType };
      verwendung?: { text: string; value: Types.KontoVerwendung } | null;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type KontoTemplateQueryVariables = Types.Exact<{
  kontoId: Types.Scalars['ID']['input'];
}>;

export type KontoTemplateQuery = {
  getKontoTemplate: {
    data: {
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      haupt: boolean;
      kontoId: string;
      kontoTemplateId?: string | null;
      nummer: string;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      klasse: { text: string; value: Types.KontoKlasse };
      status: { description?: string | null; text: string; value: Types.KontoStatus };
      type: { text: string; value: Types.KontoType };
      verwendung?: { text: string; value: Types.KontoVerwendung } | null;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type KontoTemplateChangeHistoryListQueryVariables = Types.Exact<{
  kontoId: Types.Scalars['ID']['input'];
}>;

export type KontoTemplateChangeHistoryListQuery = {
  getKontoTemplateChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type KontenRahmenQueryVariables = Types.Exact<{ [key: string]: never }>;

export type KontenRahmenQuery = {
  getKontenrahmen: {
    data: {
      createdBy?: string | null;
      createTs: string;
      createdByMitarbeiterId?: string | null;
      kontenplanId: string;
      hauptUmsatzsteuerKonto?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        haupt: boolean;
        kontoId: string;
        kontoTemplateId?: string | null;
        nummer: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        klasse: { text: string; value: Types.KontoKlasse };
        status: { description?: string | null; text: string; value: Types.KontoStatus };
        type: { text: string; value: Types.KontoType };
        verwendung?: { text: string; value: Types.KontoVerwendung } | null;
      } | null;
      hauptVorsteuerKonto?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        haupt: boolean;
        kontoId: string;
        kontoTemplateId?: string | null;
        nummer: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        klasse: { text: string; value: Types.KontoKlasse };
        status: { description?: string | null; text: string; value: Types.KontoStatus };
        type: { text: string; value: Types.KontoType };
        verwendung?: { text: string; value: Types.KontoVerwendung } | null;
      } | null;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const KontoTemplateListDocument = gql`
  query KontoTemplateList($includeArchiviert: Boolean, $klasseList: [KontoKlasse!], $type: KontoType, $verwendung: KontoVerwendung) {
    getKontoTemplateList(includeArchiviert: $includeArchiviert, klasseList: $klasseList, type: $type, verwendung: $verwendung) {
      data {
        ...KontoFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KontoFieldsFragmentDoc}
`;
export function useKontoTemplateListQuery(baseOptions?: Apollo.QueryHookOptions<KontoTemplateListQuery, KontoTemplateListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontoTemplateListQuery, KontoTemplateListQueryVariables>(KontoTemplateListDocument, options);
}
export function useKontoTemplateListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KontoTemplateListQuery, KontoTemplateListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontoTemplateListQuery, KontoTemplateListQueryVariables>(KontoTemplateListDocument, options);
}
export function useKontoTemplateListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KontoTemplateListQuery, KontoTemplateListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontoTemplateListQuery, KontoTemplateListQueryVariables>(KontoTemplateListDocument, options);
}
export type KontoTemplateListQueryHookResult = ReturnType<typeof useKontoTemplateListQuery>;
export type KontoTemplateListLazyQueryHookResult = ReturnType<typeof useKontoTemplateListLazyQuery>;
export type KontoTemplateListSuspenseQueryHookResult = ReturnType<typeof useKontoTemplateListSuspenseQuery>;
export type KontoTemplateListQueryResult = Apollo.QueryResult<KontoTemplateListQuery, KontoTemplateListQueryVariables>;
export const KontoTemplateDocument = gql`
  query KontoTemplate($kontoId: ID!) {
    getKontoTemplate(kontoId: $kontoId) {
      data {
        ...KontoFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KontoFieldsFragmentDoc}
`;
export function useKontoTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<KontoTemplateQuery, KontoTemplateQueryVariables> &
    ({ variables: KontoTemplateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontoTemplateQuery, KontoTemplateQueryVariables>(KontoTemplateDocument, options);
}
export function useKontoTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KontoTemplateQuery, KontoTemplateQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontoTemplateQuery, KontoTemplateQueryVariables>(KontoTemplateDocument, options);
}
export function useKontoTemplateSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KontoTemplateQuery, KontoTemplateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontoTemplateQuery, KontoTemplateQueryVariables>(KontoTemplateDocument, options);
}
export type KontoTemplateQueryHookResult = ReturnType<typeof useKontoTemplateQuery>;
export type KontoTemplateLazyQueryHookResult = ReturnType<typeof useKontoTemplateLazyQuery>;
export type KontoTemplateSuspenseQueryHookResult = ReturnType<typeof useKontoTemplateSuspenseQuery>;
export type KontoTemplateQueryResult = Apollo.QueryResult<KontoTemplateQuery, KontoTemplateQueryVariables>;
export const KontoTemplateChangeHistoryListDocument = gql`
  query KontoTemplateChangeHistoryList($kontoId: ID!) {
    getKontoTemplateChangeHistoryList(kontoId: $kontoId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useKontoTemplateChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<KontoTemplateChangeHistoryListQuery, KontoTemplateChangeHistoryListQueryVariables> &
    ({ variables: KontoTemplateChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontoTemplateChangeHistoryListQuery, KontoTemplateChangeHistoryListQueryVariables>(
    KontoTemplateChangeHistoryListDocument,
    options
  );
}
export function useKontoTemplateChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KontoTemplateChangeHistoryListQuery, KontoTemplateChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontoTemplateChangeHistoryListQuery, KontoTemplateChangeHistoryListQueryVariables>(
    KontoTemplateChangeHistoryListDocument,
    options
  );
}
export function useKontoTemplateChangeHistoryListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KontoTemplateChangeHistoryListQuery, KontoTemplateChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontoTemplateChangeHistoryListQuery, KontoTemplateChangeHistoryListQueryVariables>(
    KontoTemplateChangeHistoryListDocument,
    options
  );
}
export type KontoTemplateChangeHistoryListQueryHookResult = ReturnType<typeof useKontoTemplateChangeHistoryListQuery>;
export type KontoTemplateChangeHistoryListLazyQueryHookResult = ReturnType<typeof useKontoTemplateChangeHistoryListLazyQuery>;
export type KontoTemplateChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useKontoTemplateChangeHistoryListSuspenseQuery>;
export type KontoTemplateChangeHistoryListQueryResult = Apollo.QueryResult<
  KontoTemplateChangeHistoryListQuery,
  KontoTemplateChangeHistoryListQueryVariables
>;
export const KontenRahmenDocument = gql`
  query KontenRahmen {
    getKontenrahmen {
      data {
        ...KontenplanFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${KontenplanFieldsFragmentDoc}
`;
export function useKontenRahmenQuery(baseOptions?: Apollo.QueryHookOptions<KontenRahmenQuery, KontenRahmenQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontenRahmenQuery, KontenRahmenQueryVariables>(KontenRahmenDocument, options);
}
export function useKontenRahmenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KontenRahmenQuery, KontenRahmenQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontenRahmenQuery, KontenRahmenQueryVariables>(KontenRahmenDocument, options);
}
export function useKontenRahmenSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KontenRahmenQuery, KontenRahmenQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontenRahmenQuery, KontenRahmenQueryVariables>(KontenRahmenDocument, options);
}
export type KontenRahmenQueryHookResult = ReturnType<typeof useKontenRahmenQuery>;
export type KontenRahmenLazyQueryHookResult = ReturnType<typeof useKontenRahmenLazyQuery>;
export type KontenRahmenSuspenseQueryHookResult = ReturnType<typeof useKontenRahmenSuspenseQuery>;
export type KontenRahmenQueryResult = Apollo.QueryResult<KontenRahmenQuery, KontenRahmenQueryVariables>;
