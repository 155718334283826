import React, { FC } from 'react';
import { useBudgetingListQuery } from './gql/BudgetingQueries.types';
import BudgetingBtnAndModal from './BudgetingBtnAndModal';
import BudgetingTable from './Listing/Table/BudgetingTable';
import { NotificationStatus, NotificationType } from '../../types';
import { useGetNotificationSubscription } from '../Notification/gql/NotificationSubscription.types';
import { useAuth } from '../../shared/Auth/authContext';
import { useShowArchivedQueryParamState } from '../../hooks/useShowArchivedQueryParamState';

type Props = {
  objektId: string;
};

const BudgetingListing: FC<Props> = ({ objektId }) => {
  const { user, mitarbeiter } = useAuth();
  const username = user?.username;
  const mitarbeiterId = mitarbeiter?.mitarbeiterId;
  const [showArchived, toggleShowArchived] = useShowArchivedQueryParamState();

  const { data, loading, refetch } = useBudgetingListQuery({ variables: { objektId, includeArchiviert: showArchived } });

  useGetNotificationSubscription({
    onData: (options) => {
      const notification = options.data.data?.getNotification;

      if (!notification) return;
      const { notificationType, status } = notification;
      if (notificationType !== NotificationType.Budgetierung && status !== NotificationStatus.Finished) return;
      refetch();
    },
    variables: {
      userId: username,
      mitarbeiterId,
    },
  });

  const budgetingList = data?.getBudgetingList.data ?? [];

  return (
    <>
      <BudgetingBtnAndModal objektId={objektId} onAction={refetch} showArchived={showArchived} toggleShowArchived={toggleShowArchived} />
      <BudgetingTable budgetingList={budgetingList} objektId={objektId} loading={loading} />
    </>
  );
};

export default BudgetingListing;
