import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Typography } from 'antd';
import { Comment } from '@ant-design/compatible';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { ticketCommentFormValidationSchema } from './ticketCommentFormValidationSchema';
import { TicketCommentFieldsFragment } from '../../../gql/TicketFragments.types';
import { useAuthorizedMitarbeiter } from '../../../../../shared/Auth/useAuthorized';
import { useCreateTicketCommentMutation, useUpdateTicketCommentMutation } from '../../../gql/TicketMutations.types';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import MitarbeiterTooltip from '../../../../../components/Card/MitarbeiterTooltip';
import { mapCommentToFormValues, mapFormValuesToComment, ticketCommentFormFields, TicketCommentFormValues } from './ticketCommentFormMapper';
import FormButtons from '../../../../../components/Button/FormButtons';

type Props = {
  ticketId: string;
  comment?: TicketCommentFieldsFragment;
  onSuccess: () => void;
  onCancel: () => void;
};

const TicketCommentForm: FC<Props> = ({ ticketId, comment, onSuccess, onCancel }) => {
  const { user, mitarbeiter } = useAuthorizedMitarbeiter();
  const [textAreaRows, setTextAreaRows] = useState(1);
  const [editing, setEditing] = useState(false);

  const entity = `Kommentar`;
  const isUpdate = !!comment;

  const maxCommentLength = 5000;

  const [runCreate, { loading: loadingCreate }] = useCreateTicketCommentMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateTicketCommentMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const isLoading = loadingCreate || loadingUpdate;

  const focusOnComment = useCallback(() => {
    setTextAreaRows(3);
    setEditing(true);
  }, [setEditing]);

  const unFocusOnComment = () => {
    setTextAreaRows(1);
    setEditing(false);
  };

  useEffect(() => {
    if (comment) {
      focusOnComment();
    }
  }, [comment, focusOnComment]);

  return (
    <Comment
      avatar={<MitarbeiterTooltip showsInitials userId={user.username} mitarbeiterId={mitarbeiter.mitarbeiterId} alignment="right" />}
      content={
        <Formik<TicketCommentFormValues>
          initialValues={mapCommentToFormValues(comment)}
          validationSchema={ticketCommentFormValidationSchema}
          enableReinitialize
          onSubmit={(values, formikHelpers) => {
            if (!isUpdate) {
              runCreate({ variables: { ticketId, input: mapFormValuesToComment(values, maxCommentLength) } })
                .finally(() => formikHelpers.setSubmitting(false))
                .then(() => {
                  formikHelpers.resetForm();
                  unFocusOnComment();
                });
            } else {
              runUpdate({
                variables: { ticketId, ticketCommentId: comment?.ticketCommentId, input: mapFormValuesToComment(values, maxCommentLength) },
              })
                .finally(() => formikHelpers.setSubmitting(false))
                .then(() => {
                  formikHelpers.resetForm();
                  unFocusOnComment();
                });
            }
          }}
        >
          {(formikProps) => (
            <Form layout="vertical">
              <Form.Item name={ticketCommentFormFields.text}>
                <Input.TextArea
                  maxLength={maxCommentLength}
                  size="large"
                  name={ticketCommentFormFields.text}
                  rows={textAreaRows}
                  placeholder="Neue Kommentar eingeben..."
                  onFocus={focusOnComment}
                />
                {maxCommentLength && (
                  <Typography.Text type="secondary" style={editing ? { visibility: 'visible' } : { visibility: 'hidden' }}>
                    {maxCommentLength - formikProps.values.text.length} Zeichen verbleibend
                  </Typography.Text>
                )}
              </Form.Item>
              {editing && (
                <>
                  <FormButtons
                    updateMode={isUpdate}
                    onCancel={() => {
                      formikProps.resetForm();
                      unFocusOnComment();
                      onCancel();
                    }}
                    isOkDisabled={isLoading || formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty}
                    isCancelDisabled={isLoading || formikProps.isSubmitting}
                    isOkLoading={isLoading}
                  />
                </>
              )}
            </Form>
          )}
        </Formik>
      }
    />
  );
};

export default TicketCommentForm;
