import { FormFields, mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';
import { ITimeblock } from '../../../../../components/Timeline/timelineCtx';
import { FirmendatenPauschalmieteAufwandskonto, FirmendatenPauschalmieteAufwandskontoInput } from '../../../../../types';
import { ValuesDescriptionForKommentar } from '../../../../../components/Timeline/versionTimelineHelper';

export type AufwandskontoVersionFormValues = {
  validFrom: string;
  kommentar: string;
  pauschalmieteAufwandskontoId: string;
};

export const aufwandskontoVersionFormFields: FormFields<AufwandskontoVersionFormValues> = {
  validFrom: 'validFrom',
  kommentar: 'kommentar',
  pauschalmieteAufwandskontoId: 'pauschalmieteAufwandskontoId',
};

export const mapTimeblockToFormValues = (timeblock: ITimeblock<FirmendatenPauschalmieteAufwandskonto>): AufwandskontoVersionFormValues => ({
  validFrom: timeblock.validFrom,
  kommentar: timeblock.kommentar ?? '',
  pauschalmieteAufwandskontoId: timeblock.pauschalmieteAufwandskontoId,
});

export const mapFormValuesToPauschalmieteAufwandskontoVersion = (
  values: AufwandskontoVersionFormValues
): FirmendatenPauschalmieteAufwandskontoInput => ({
  validFrom: mapFormDateValueToDateString(values.validFrom),
  kommentar: values.kommentar,
  pauschalmieteAufwandskontoId: values.pauschalmieteAufwandskontoId,
});

export const aufwandskontoVersionFormValuesDescriptionsForKommentar: ValuesDescriptionForKommentar<AufwandskontoVersionFormValues> = {
  validFrom: 'Gültigkeitsdatum',
  pauschalmieteAufwandskontoId: 'Pauschalmiete Aufwandskonto',
};
