import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Dropdown, MenuProps, Modal } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, DownOutlined, ExclamationCircleOutlined, ScheduleOutlined } from '@ant-design/icons';
import { IndexedValueListingFiltersFormValues } from '../../Filters/indexedValueListingFiltersFormMapper';
import { useToggle } from '../../../../../hooks/useToggle';
import { showSuccessMsgOther } from '../../../../../components/message';
import {
  useDoNotApplyVertragVposIndexedValueFirmendatenMutation,
  useSubmitVertragVposIndexedValueFirmendatenMutation,
} from '../../../gql/VertragVposIndexedValueMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { VertragVposIndexedValueUpdateStatusBulkActionInput } from '../../../../../types';
import { useCreateApplyVertragVposIndexedValueGenerierlaufMutation } from '../../../../Verarbeitung/gql/ApplyVertragVposIndexedValueGenerierlauf/ApplyVertragVposIndexedValueGenerierlaufMutations.types';
import { showConfirmApply, showConfirmDoNotApply, showConfirmSubmit } from './indexedValueConfirmMessages';
import { mapFormDateValueToDateStringWithEndOf, mapFormDateValueToDateStringWithStartOf } from '../../../../../helpers/formikHelper';
import UpdateVPosValidFromForm from './UpdateVPosValidFromForm';

type Props = {
  formikProps: FormikProps<IndexedValueListingFiltersFormValues>;
  onSuccess: () => void;
};

const IndexedValueTableBulkActions: FC<Props> = ({ formikProps, onSuccess }) => {
  const entity = 'Indexierte Werte';

  const [isChangeVPosValidFromModalOpen, toggleIsChangeVPosValidFromModalOpen] = useToggle();

  const input: VertragVposIndexedValueUpdateStatusBulkActionInput = {
    bestandseinheitId: formikProps.values.bestandseinheitId,
    indexSeriesId: formikProps.values.indexSeriesId,
    objektId: formikProps.values.objektId,
    rechnungsAusstellerId: formikProps.values.rechnungsAusstellerId,
    validFrom: formikProps.values.validFrom ? mapFormDateValueToDateStringWithStartOf(formikProps.values.validFrom, 'month') : null,
    validTo: formikProps.values.validTo ? mapFormDateValueToDateStringWithEndOf(formikProps.values.validTo, 'month') : null,
    valuePreservationTypeList: formikProps.values.valuePreservationTypeList,
    vertragId: formikProps.values.vertragId,
    vertragspartnerId: formikProps.values.vertragspartnerId,
    vorschreibungspositionName: formikProps.values.vorschreibungspositionName,
  };

  const [runApply] = useCreateApplyVertragVposIndexedValueGenerierlaufMutation({
    variables: { input },
    onCompleted: () => {
      showSuccessMsgOther(`${entity} werden angewendet`);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runNotApply] = useDoNotApplyVertragVposIndexedValueFirmendatenMutation({
    variables: { input },
    onCompleted: () => {
      showSuccessMsgOther(`${entity} werden nicht anwenden`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runSubmit] = useSubmitVertragVposIndexedValueFirmendatenMutation({
    variables: { input },
    onCompleted: () => {
      showSuccessMsgOther(`${entity} werden vorgelegt`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Gültig ab (VPos) ändern',
      icon: <ScheduleOutlined />,
      onClick: toggleIsChangeVPosValidFromModalOpen,
    },
    {
      key: '2',
      label: 'Indexierte Werte anwenden',
      icon: <CheckCircleOutlined />,
      onClick: () => showConfirmApply(runApply),
    },
    {
      key: '3',
      label: 'Indexierte Werte nicht anwenden',
      icon: <CloseCircleOutlined />,
      onClick: () => showConfirmDoNotApply(runNotApply),
    },
    {
      key: '4',
      label: 'Indexierte Werte vorlegen',
      icon: <ExclamationCircleOutlined />,
      onClick: () => showConfirmSubmit(runSubmit),
    },
  ];

  const onActionSuccess = () => {
    onSuccess();
    toggleIsChangeVPosValidFromModalOpen();
  };

  return (
    <>
      <Dropdown menu={{ items }}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Auf Liste anwenden <DownOutlined />
        </a>
      </Dropdown>
      <Modal
        title="Gültig ab (VPos) ändern"
        width={520}
        footer={null}
        open={isChangeVPosValidFromModalOpen}
        onCancel={toggleIsChangeVPosValidFromModalOpen}
        destroyOnClose
      >
        <UpdateVPosValidFromForm formikProps={formikProps} onCancel={toggleIsChangeVPosValidFromModalOpen} onOk={onActionSuccess} />
      </Modal>
    </>
  );
};

export default IndexedValueTableBulkActions;
