import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { ObjectOfStrings } from '../../../../shared/typeHelpers';
import { BookingFolderQueryParams } from './filtersQueryParams';
import { FibuBelegSymbol, FibuBuchungsanweisungStatus, FibuBuchungType, QueryGetFibuBuchungsanweisungListArgs } from '../../../../types';

export type FiltersFormValues = {
  belegDatumFrom?: string | null;
  belegDatumTo?: string | null;
  belegSymbolList?: FibuBelegSymbol[] | null;
  belegnummer?: string | null;
  bestandseinheitId?: string | null;
  buchungsTypeList?: FibuBuchungType[] | null;
  buchungsdatumFrom?: string | null;
  buchungsdatumTo?: string | null;
  createByMitarbeiterIdList?: string[] | null;
  createTsFrom?: string | null;
  createTsTo?: string | null;
  dueDateFrom?: string | null;
  dueDateTo?: string | null;
  fachlichePruefung?: boolean | null;
  heVertragId?: string | null;
  objektId?: string | null;
  rechtstraegerIdList?: string[] | null;
  status?: FibuBuchungsanweisungStatus | null;
  sumBruttoFrom?: number | null;
  sumBruttoTo?: number | null;
  sumSteuerFrom?: number | null;
  sumSteuerTo?: number | null;
  vertragId?: string | null;
  vertragspartnerIdList?: string[] | null;
  zuStornierendeBuchungId?: string | null;
};

export const filtersFormFields: FormFields<FiltersFormValues> = {
  belegDatumFrom: 'belegDatumFrom',
  belegDatumTo: 'belegDatumTo',
  belegSymbolList: 'belegSymbolList',
  belegnummer: 'belegnummer',
  bestandseinheitId: 'bestandseinheitId',
  buchungsTypeList: 'buchungsTypeList',
  buchungsdatumFrom: 'buchungsdatumFrom',
  buchungsdatumTo: 'buchungsdatumTo',
  createByMitarbeiterIdList: 'createByMitarbeiterIdList',
  createTsFrom: 'createTsFrom',
  createTsTo: 'createTsTo',
  dueDateFrom: 'dueDateFrom',
  dueDateTo: 'dueDateTo',
  fachlichePruefung: 'fachlichePruefung',
  heVertragId: 'heVertragId',
  objektId: 'objektId',
  rechtstraegerIdList: 'rechtstraegerIdList',
  status: 'status',
  sumBruttoFrom: 'sumBruttoFrom',
  sumBruttoTo: 'sumBruttoTo',
  sumSteuerFrom: 'sumSteuerFrom',
  sumSteuerTo: 'sumSteuerTo',
  vertragId: 'vertragId',
  vertragspartnerIdList: 'vertragspartnerIdList',
  zuStornierendeBuchungId: 'zuStornierendeBuchungId',
};

export const mapFormValuesToQueryParams = (formValues: FiltersFormValues): BookingFolderQueryParams => ({
  ...formValues,
  belegDatumFrom: formValues.belegDatumFrom ? mapFormDateValueToDateString(formValues.belegDatumFrom) : undefined,
  belegDatumTo: formValues.belegDatumTo ? mapFormDateValueToDateString(formValues.belegDatumTo) : undefined,
  buchungsdatumFrom: formValues.buchungsdatumFrom ? mapFormDateValueToDateString(formValues.buchungsdatumFrom) : undefined,
  buchungsdatumTo: formValues.buchungsdatumTo ? mapFormDateValueToDateString(formValues.buchungsdatumTo) : undefined,
  createTsFrom: formValues.createTsFrom ? mapFormDateValueToDateString(formValues.createTsFrom) : undefined,
  createTsTo: formValues.createTsTo ? mapFormDateValueToDateString(formValues.createTsTo) : undefined,
  dueDateFrom: formValues.dueDateFrom ? mapFormDateValueToDateString(formValues.dueDateFrom) : undefined,
  dueDateTo: formValues.dueDateTo ? mapFormDateValueToDateString(formValues.dueDateTo) : undefined,
});

export const initialFormValues = (queryParams: BookingFolderQueryParams): FiltersFormValues => ({
  ...queryParams,
});

export const mapQueryParamsToBuchungsanweisungVariables = (queryParams: BookingFolderQueryParams): QueryGetFibuBuchungsanweisungListArgs => {
  return {
    ...queryParams,
    belegnummer: queryParams.belegnummer?.toString(),
  };
};

export const mapFilterLabelToQueryFilterList = (filter: string) => {
  if (filter === filtersFormFields.buchungsdatumFrom || filter === filtersFormFields.buchungsdatumTo) {
    return filtersFormFields.buchungsdatumFrom;
  }
  if (filter === filtersFormFields.belegDatumFrom || filter === filtersFormFields.belegDatumTo) {
    return filtersFormFields.belegDatumFrom;
  }
  if (filter === filtersFormFields.createTsFrom || filter === filtersFormFields.createTsTo) {
    return filtersFormFields.createTsFrom;
  }
  if (filter === filtersFormFields.dueDateFrom || filter === filtersFormFields.dueDateTo) {
    return filtersFormFields.dueDateFrom;
  }
  if (filter === filtersFormFields.sumSteuerFrom || filter === filtersFormFields.sumSteuerTo) {
    return filtersFormFields.sumSteuerFrom;
  }
  if (filter === filtersFormFields.sumBruttoFrom || filter === filtersFormFields.sumBruttoTo) {
    return filtersFormFields.sumBruttoFrom;
  }
  return filter;
};

export const listingLabelList: ObjectOfStrings<
  Omit<FiltersFormValues, 'belegDatumTo' | 'buchungsdatumTo' | 'createTsTo' | 'dueDateTo' | 'sumBruttoTo' | 'sumSteuerTo'>
> = {
  [filtersFormFields.belegDatumFrom]: 'Belegdatum',
  [filtersFormFields.belegSymbolList]: 'Belegsymbol',
  [filtersFormFields.belegnummer]: 'Belegnummer',
  [filtersFormFields.bestandseinheitId]: 'Bestandseinheit',
  [filtersFormFields.buchungsTypeList]: 'Buchungstyp',
  [filtersFormFields.buchungsdatumFrom]: 'Buchungsdatum',
  [filtersFormFields.createByMitarbeiterIdList]: 'Ersteller',
  [filtersFormFields.createTsFrom]: 'Erstellt am',
  [filtersFormFields.dueDateFrom]: 'Fälligkeit',
  [filtersFormFields.fachlichePruefung]: 'Fachliche Prüfung',
  [filtersFormFields.heVertragId]: 'HE-Vertrag',
  [filtersFormFields.objektId]: 'Objekt',
  [filtersFormFields.rechtstraegerIdList]: 'Buchungskreis',
  [filtersFormFields.status]: 'Status',
  [filtersFormFields.sumBruttoFrom]: 'Brutto Betrag',
  [filtersFormFields.sumSteuerFrom]: 'Steuer',
  [filtersFormFields.vertragId]: 'Vertrag',
  [filtersFormFields.vertragspartnerIdList]: 'Vertragspartner',
  [filtersFormFields.zuStornierendeBuchungId]: 'Zu stornierende Buchung',
};
