import { useParams } from 'react-router-dom';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { useAuth } from '../../../shared/Auth/authContext';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../../shared/dataChangedSubscription';
import { DataChangedChangeType } from '../../../types';

type RouteParams = { bestandseinheitId: string };

export const useOnVertragDataChangedEvents = (key: string, callback: () => Promise<unknown>, vertragIds: string[]) => {
  const { user } = useFDAuthorized();
  const { mitarbeiter } = useAuth();

  const { bestandseinheitId } = useParams() as RouteParams;

  useOnDataChangedEvent([
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.vertrag,
        params: {
          vertragId: vertragIds,
        },
        changeType: DataChangedChangeType.Update,
      },
      notificationDescription: 'Vertrag wurde gerade verändert',
      key: `${key}-update`,
      callback,
    },
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.vertrag,
        params: {
          bestandseinheitId,
        },
        changeType: DataChangedChangeType.Create,
      },
      notificationDescription: 'Vertrag wurde gerade erstellt',
      key: `${key}-create`,
      callback,
    },
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.vertrag,
        params: {
          bestandseinheitId,
        },
        changeType: DataChangedChangeType.Delete,
      },
      notificationDescription: 'Vertrag wurde gerade gelöscht',
      key: `${key}-delete`,
      callback,
    },
  ]);
};
