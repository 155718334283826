import React, { FC, useState } from 'react';
import { Radio, Space, Typography } from 'antd';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import PaymentProposalSelect from '../../../PaymentProposal/PaymentProposalSelect';
import { PaymentProposalStatus } from '../../../../types';

type Props = {
  name: string;
  fieldLabel: string;
  onFilterChange: () => void;
  onShowOwnPaymentProposalChange: () => void;
  showNotAssignedOption: boolean;
};

const PaymentProposalFilter: FC<Props> = ({ name, fieldLabel, onFilterChange, onShowOwnPaymentProposalChange, showNotAssignedOption }) => {
  const [paymentProposalStatus, setPaymentProposalStatus] = useState<'open' | 'booked'>('open');

  const label = (
    <Space size="middle">
      <Typography.Text>{fieldLabel}</Typography.Text>
      <Radio.Group
        name="showOwnPaymentProposal"
        size="small"
        block
        value={paymentProposalStatus}
        defaultValue={paymentProposalStatus}
        onChange={(e) => {
          onShowOwnPaymentProposalChange();
          setPaymentProposalStatus(e.target.value);
        }}
        style={{
          width: '150px',
        }}
      >
        <Radio.Button value="open">Offene</Radio.Button>
        <Radio.Button value="booked">Verbuchte</Radio.Button>
      </Radio.Group>
    </Space>
  );

  return (
    <FormItemWithoutColon name={name} label={label}>
      <PaymentProposalSelect
        name={name}
        onChange={onFilterChange}
        showNotAssignedOption={showNotAssignedOption}
        statusList={
          paymentProposalStatus === 'open' ? [PaymentProposalStatus.Created, PaymentProposalStatus.Executed] : [PaymentProposalStatus.Booked]
        }
      />
    </FormItemWithoutColon>
  );
};

export default PaymentProposalFilter;
