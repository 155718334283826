import React from 'react';
import { ScissorOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../components/ContextSidebar/ContentWithSidebar';
import { Vorsteuerkuerzung, VorsteuerkuerzungStatus } from '../../types';
import { generatePathToVorsteuerkuerzungDetailsPage } from '../../features/Vorsteuerkuerzung/vorsteuerkuerzungUriPaths';
import VorsteuerkuerzungDeviationList from '../../features/Vorsteuerkuerzung/Deviation/VorsteuerkuerzungDeviationList';
import { DEVIATIONS_MENU_LIST_ITEM_TITLE } from '../../components/Deviation/deviationHelpers';
import VstKuerzungDetailsTab from '../../features/Vorsteuerkuerzung/VstKuerzungDetailsListing/VstKuerzungDetailsTab';

const menuListVorsteuerkuerzung = (objektId: string, vstKuerzung: Vorsteuerkuerzung, refetch: () => void): GroupedMenueListInterface => ({
  topMenuList: [
    {
      title: 'Berechnung',
      icon: <ScissorOutlined />,
      uri: `${generatePathToVorsteuerkuerzungDetailsPage(objektId, vstKuerzung.vorsteuerkuerzungId)}/overview`,
      content: () => <VstKuerzungDetailsTab vstKuerzung={vstKuerzung} />,
    },
    {
      state: vstKuerzung.status.value === VorsteuerkuerzungStatus.Invalid ? 'error' : undefined,
      title: DEVIATIONS_MENU_LIST_ITEM_TITLE,
      icon: <ThunderboltOutlined />,
      uri: `${generatePathToVorsteuerkuerzungDetailsPage(objektId, vstKuerzung.vorsteuerkuerzungId)}/discrepancies`,
      content: () => <VorsteuerkuerzungDeviationList objektId={objektId} vorsteuerkuerzung={vstKuerzung} onAction={refetch} />,
    },
  ],
});

export default menuListVorsteuerkuerzung;
