import React, { FC } from 'react';
import { PaperClipOutlined } from '@ant-design/icons';
import { Badge, Button, Tooltip } from 'antd';
import theme from '../../theme';

type Props = {
  onClick: () => void;
  fileIdList: string[];
};

const DocumentCount: FC<Props> = ({ fileIdList, onClick }) => {
  const icon = (
    <span style={{ padding: '4px 0' }}>
      <Button shape="circle" size="small" type="text" onClick={onClick} icon={<PaperClipOutlined style={{ color: theme.colors.blue }} />} />
    </span>
  );

  const content =
    fileIdList.length === 0 ? (
      <>{icon}</>
    ) : (
      <Badge size="small" count={fileIdList.length}>
        {icon}
      </Badge>
    );

  return <Tooltip title="Dokumente">{content}</Tooltip>;
};

export default DocumentCount;
