import { Text } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';

const TARepFondsAbrechnungBlockTitle: FC<{ titleText: string }> = ({ titleText }) => (
  <Text
    style={[
      pdfStyles.textNormal,
      {
        fontWeight: 'bold',
        marginTop: '3mm',
        marginBottom: '2mm',
      },
    ]}
  >
    {titleText}
  </Text>
);

export default TARepFondsAbrechnungBlockTitle;
