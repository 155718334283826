import React, { FC } from 'react';
import { Box } from 'rebass';
import { Space } from 'antd';
import { TDeviationColumn } from '../../deviation.model';
import DeviationColumnNotReferenceOfCauseCol from './NotReferenceOfCauseCol/DeviationColumnNotReferenceOfCauseCol';
import DeviationReferenceOfCauseCol from './ReferenceOfCauseCol/DeviationReferenceOfCauseCol';
import { isDeviationAttributeTypeUri } from '../../deviationHelpers';

type Props = {
  columns: TDeviationColumn[];
  altTextForMultipleReferenceOfCause?: string;
};

const DeviationColumns: FC<Props> = ({ columns, altTextForMultipleReferenceOfCause }) => {
  return (
    <>
      {columns.map((cellsCol, index) => {
        const columnsWithoutReferenceOfCauseCol = cellsCol.cells.filter((cell) => !isDeviationAttributeTypeUri(cell.columnType));
        const referenceOfCauseColumn = cellsCol.cells.filter((cell) => isDeviationAttributeTypeUri(cell.columnType));
        return (
          <Space key={index} style={{ width: '100%', justifyContent: 'space-between' }}>
            <DeviationColumnNotReferenceOfCauseCol cellsCol={columnsWithoutReferenceOfCauseCol} columnCount={columns.length} />
            {referenceOfCauseColumn.length > 0 && (
              <>
                <Box mx="auto" />
                <DeviationReferenceOfCauseCol
                  cellsCol={referenceOfCauseColumn}
                  altTextForMultipleReferenceOfCause={altTextForMultipleReferenceOfCause}
                  columnCount={columns.length}
                />
              </>
            )}
          </Space>
        );
      })}
    </>
  );
};

export default DeviationColumns;
