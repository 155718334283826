import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { BudgetingCreateInput } from '../../../../types';

export type BudgetingCreateFormValues = {
  bezeichnung: string;
  considerationPeriodUntil: string;
};

export const budgetingCreateFormFields: FormFields<BudgetingCreateFormValues> = {
  bezeichnung: 'bezeichnung',
  considerationPeriodUntil: 'considerationPeriodUntil',
};

export const budgetingCreateFormInitialValues: BudgetingCreateFormValues = {
  bezeichnung: new Date().getFullYear().toString(),
  considerationPeriodUntil: mapFormDateValueToDateString(dayjsCustom().endOf('month')),
};

export const mapFormValuesToBudgeting = (values: BudgetingCreateFormValues): BudgetingCreateInput => ({
  bezeichnung: values.bezeichnung,
  considerationPeriodUntil: mapFormDateValueToDateString(dayjsCustom(values.considerationPeriodUntil).endOf('month')),
});
