import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { EbicsUserFieldsFragmentDoc } from './EbicsUserFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EbicsUserListQueryVariables = Types.Exact<{
  ebicsBankConfigId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  status?: Types.InputMaybe<Types.EbicsUserStatus>;
  ibanList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  mitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  order?: Types.InputMaybe<Types.EbicsUserOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type EbicsUserListQuery = {
  getEbicsUserList: {
    data: {
      contentList: Array<{
        bankname: string;
        camtImportEnabled: boolean;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable: boolean;
        ebicsBankConfigId: string;
        ebicsUserId: string;
        editable: boolean;
        initializationDocumentFileId?: string | null;
        mitarbeiterId: string;
        mitarbeiterName: string;
        name?: string | null;
        partnerId: string;
        password: string;
        userId: string;
        orderTypeList: Array<{ description?: string | null; text: string; value: string }>;
        status: { text: string; value: Types.EbicsUserStatus };
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type EbicsUserQueryVariables = Types.Exact<{
  ebicsUserId: Types.Scalars['ID']['input'];
}>;

export type EbicsUserQuery = {
  getEbicsUser: {
    data: {
      bankname: string;
      camtImportEnabled: boolean;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable: boolean;
      ebicsBankConfigId: string;
      ebicsUserId: string;
      editable: boolean;
      initializationDocumentFileId?: string | null;
      mitarbeiterId: string;
      mitarbeiterName: string;
      name?: string | null;
      partnerId: string;
      password: string;
      userId: string;
      orderTypeList: Array<{ description?: string | null; text: string; value: string }>;
      status: { text: string; value: Types.EbicsUserStatus };
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type EbicsUserStatusListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type EbicsUserStatusListQuery = {
  getEbicsUserStatusList: {
    data: Array<{ text: string; value: Types.EbicsUserStatus }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const EbicsUserListDocument = gql`
  query EbicsUserList(
    $ebicsBankConfigId: ID
    $status: EbicsUserStatus
    $ibanList: [String!]
    $mitarbeiterIdList: [String!]
    $order: EbicsUserOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getEbicsUserList(
      ebicsBankConfigId: $ebicsBankConfigId
      status: $status
      ibanList: $ibanList
      mitarbeiterIdList: $mitarbeiterIdList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          ...EbicsUserFields
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${EbicsUserFieldsFragmentDoc}
`;
export function useEbicsUserListQuery(baseOptions?: Apollo.QueryHookOptions<EbicsUserListQuery, EbicsUserListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EbicsUserListQuery, EbicsUserListQueryVariables>(EbicsUserListDocument, options);
}
export function useEbicsUserListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EbicsUserListQuery, EbicsUserListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EbicsUserListQuery, EbicsUserListQueryVariables>(EbicsUserListDocument, options);
}
export function useEbicsUserListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EbicsUserListQuery, EbicsUserListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EbicsUserListQuery, EbicsUserListQueryVariables>(EbicsUserListDocument, options);
}
export type EbicsUserListQueryHookResult = ReturnType<typeof useEbicsUserListQuery>;
export type EbicsUserListLazyQueryHookResult = ReturnType<typeof useEbicsUserListLazyQuery>;
export type EbicsUserListSuspenseQueryHookResult = ReturnType<typeof useEbicsUserListSuspenseQuery>;
export type EbicsUserListQueryResult = Apollo.QueryResult<EbicsUserListQuery, EbicsUserListQueryVariables>;
export const EbicsUserDocument = gql`
  query EbicsUser($ebicsUserId: ID!) {
    getEbicsUser(ebicsUserId: $ebicsUserId) {
      data {
        ...EbicsUserFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${EbicsUserFieldsFragmentDoc}
`;
export function useEbicsUserQuery(
  baseOptions: Apollo.QueryHookOptions<EbicsUserQuery, EbicsUserQueryVariables> &
    ({ variables: EbicsUserQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EbicsUserQuery, EbicsUserQueryVariables>(EbicsUserDocument, options);
}
export function useEbicsUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EbicsUserQuery, EbicsUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EbicsUserQuery, EbicsUserQueryVariables>(EbicsUserDocument, options);
}
export function useEbicsUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EbicsUserQuery, EbicsUserQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EbicsUserQuery, EbicsUserQueryVariables>(EbicsUserDocument, options);
}
export type EbicsUserQueryHookResult = ReturnType<typeof useEbicsUserQuery>;
export type EbicsUserLazyQueryHookResult = ReturnType<typeof useEbicsUserLazyQuery>;
export type EbicsUserSuspenseQueryHookResult = ReturnType<typeof useEbicsUserSuspenseQuery>;
export type EbicsUserQueryResult = Apollo.QueryResult<EbicsUserQuery, EbicsUserQueryVariables>;
export const EbicsUserStatusListDocument = gql`
  query EbicsUserStatusList {
    getEbicsUserStatusList {
      data {
        text
        value
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useEbicsUserStatusListQuery(baseOptions?: Apollo.QueryHookOptions<EbicsUserStatusListQuery, EbicsUserStatusListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EbicsUserStatusListQuery, EbicsUserStatusListQueryVariables>(EbicsUserStatusListDocument, options);
}
export function useEbicsUserStatusListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EbicsUserStatusListQuery, EbicsUserStatusListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EbicsUserStatusListQuery, EbicsUserStatusListQueryVariables>(EbicsUserStatusListDocument, options);
}
export function useEbicsUserStatusListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EbicsUserStatusListQuery, EbicsUserStatusListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EbicsUserStatusListQuery, EbicsUserStatusListQueryVariables>(EbicsUserStatusListDocument, options);
}
export type EbicsUserStatusListQueryHookResult = ReturnType<typeof useEbicsUserStatusListQuery>;
export type EbicsUserStatusListLazyQueryHookResult = ReturnType<typeof useEbicsUserStatusListLazyQuery>;
export type EbicsUserStatusListSuspenseQueryHookResult = ReturnType<typeof useEbicsUserStatusListSuspenseQuery>;
export type EbicsUserStatusListQueryResult = Apollo.QueryResult<EbicsUserStatusListQuery, EbicsUserStatusListQueryVariables>;
