import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KontoKlasseListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type KontoKlasseListQuery = {
  getKontoKlasseList: {
    data: Array<{ text: string; value: Types.KontoKlasse }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const KontoKlasseListDocument = gql`
  query KontoKlasseList {
    getKontoKlasseList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useKontoKlasseListQuery(baseOptions?: Apollo.QueryHookOptions<KontoKlasseListQuery, KontoKlasseListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontoKlasseListQuery, KontoKlasseListQueryVariables>(KontoKlasseListDocument, options);
}
export function useKontoKlasseListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KontoKlasseListQuery, KontoKlasseListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontoKlasseListQuery, KontoKlasseListQueryVariables>(KontoKlasseListDocument, options);
}
export function useKontoKlasseListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KontoKlasseListQuery, KontoKlasseListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontoKlasseListQuery, KontoKlasseListQueryVariables>(KontoKlasseListDocument, options);
}
export type KontoKlasseListQueryHookResult = ReturnType<typeof useKontoKlasseListQuery>;
export type KontoKlasseListLazyQueryHookResult = ReturnType<typeof useKontoKlasseListLazyQuery>;
export type KontoKlasseListSuspenseQueryHookResult = ReturnType<typeof useKontoKlasseListSuspenseQuery>;
export type KontoKlasseListQueryResult = Apollo.QueryResult<KontoKlasseListQuery, KontoKlasseListQueryVariables>;
