import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KuendigungDurchListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type KuendigungDurchListQuery = {
  getKuendigungDurchList: {
    data: Array<{ text: string; value: Types.KuendigungDurch }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const KuendigungDurchListDocument = gql`
  query KuendigungDurchList {
    getKuendigungDurchList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useKuendigungDurchListQuery(baseOptions?: Apollo.QueryHookOptions<KuendigungDurchListQuery, KuendigungDurchListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KuendigungDurchListQuery, KuendigungDurchListQueryVariables>(KuendigungDurchListDocument, options);
}
export function useKuendigungDurchListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KuendigungDurchListQuery, KuendigungDurchListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KuendigungDurchListQuery, KuendigungDurchListQueryVariables>(KuendigungDurchListDocument, options);
}
export function useKuendigungDurchListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KuendigungDurchListQuery, KuendigungDurchListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KuendigungDurchListQuery, KuendigungDurchListQueryVariables>(KuendigungDurchListDocument, options);
}
export type KuendigungDurchListQueryHookResult = ReturnType<typeof useKuendigungDurchListQuery>;
export type KuendigungDurchListLazyQueryHookResult = ReturnType<typeof useKuendigungDurchListLazyQuery>;
export type KuendigungDurchListSuspenseQueryHookResult = ReturnType<typeof useKuendigungDurchListSuspenseQuery>;
export type KuendigungDurchListQueryResult = Apollo.QueryResult<KuendigungDurchListQuery, KuendigungDurchListQueryVariables>;
