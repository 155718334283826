import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Divider, Space, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { DeviationColumnTitleStyledText } from '../../../Styled/Deviation.style';
import DeviationChangeTypeTag from '../../../DeviationChangeTypeTag';
import { generatePathToBeVertragDetailsPage } from '../../../../../features/Vertrag/BeVertrag/beVertragUriPaths';
import { BeVertragDeviationSourceEntity, DeviationChangeTypeTuple } from '../../../../../types';
import DataWithShortenedTextAndExtraInfo from '../../../../Helpers/DataWithShortenedTextAndExtraInfo';
import CustomFormattedDate from '../../../../FormattedDate/CustomFormattedDate';

type Props = {
  changeType: DeviationChangeTypeTuple;
  sourceEntity: BeVertragDeviationSourceEntity;
};

const VertragSourceEntityHeader: FC<Props> = ({ changeType, sourceEntity }) => (
  <Space align="baseline" split={<Divider type="vertical" />}>
    <Space size={4} align="baseline" style={{ width: '130px' }}>
      <DeviationChangeTypeTag changeType={changeType} />
      <DeviationColumnTitleStyledText type="secondary">{sourceEntity.type.text}</DeviationColumnTitleStyledText>
    </Space>
    <Space size={16}>
      <Space size={4} style={{ width: '200px' }}>
        <DataWithShortenedTextAndExtraInfo
          maxTextLength={30}
          text={`${sourceEntity.objektBezeichnung} / ${sourceEntity.bestandseinheitBezeichnung}`}
        />
      </Space>
      <Space size={4} style={{ width: '304px' }}>
        {/* FIXME: Maybe use RechtstraegerName instead? */}
        {/* TODO:  Made rechnungsAusstellerKurzBezeichnung + vertragsBegin optional for testing Vst.Kuerzung Deviation  */}
        <DataWithShortenedTextAndExtraInfo maxTextLength={15} text={sourceEntity.rechnungsAusstellerKurzBezeichnung ?? ''} />
        <ArrowRightOutlined style={{ fontSize: '12px' }} />
        <DataWithShortenedTextAndExtraInfo maxTextLength={15} text={sourceEntity.vertragspartnerKurzBezeichnung} />
      </Space>
      <Space size={8}>
        <Space size={4}>
          <Typography.Text type="secondary">von</Typography.Text>
          <CustomFormattedDate value={sourceEntity.vertragsBeginn ?? ''} />
        </Space>
        {sourceEntity.vertragsEnde && (
          <Space size={4}>
            <Typography.Text type="secondary">bis</Typography.Text>
            <CustomFormattedDate value={sourceEntity.vertragsEnde} />
          </Space>
        )}
      </Space>
    </Space>
    {sourceEntity.ids && (
      <Typography.Text strong underline>
        <Link to={generatePathToBeVertragDetailsPage(sourceEntity.ids.objektId, sourceEntity.ids.bestandseinheitId, sourceEntity.ids.vertragId)}>
          {sourceEntity.ids.linkText}
        </Link>
      </Typography.Text>
    )}
  </Space>
);

export default VertragSourceEntityHeader;
