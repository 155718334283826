import React, { FC, JSX, PropsWithChildren } from 'react';
import { Col, Row, Typography } from 'antd';

type CardDetailsInfoRowProps = {
  infoRowTitle: string | JSX.Element;
} & PropsWithChildren;

const CardDetailsInfoRow: FC<CardDetailsInfoRowProps> = ({ infoRowTitle, children }) => (
  <Row style={{ width: '100%' }}>
    <Col span={12}>{typeof infoRowTitle === 'string' ? <Typography.Text type="secondary">{infoRowTitle}</Typography.Text> : infoRowTitle}</Col>
    <Col span={12}>{children}</Col>
  </Row>
);

export default CardDetailsInfoRow;
