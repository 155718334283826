import { useDeleteFirmendatenBankDetailsMutation, useDeleteRechtstraegerBankDetailsMutation } from '../gql/BankDetailsMutations.types';
import { showSuccessMsgDelete } from '../../../components/message';
import { gqlOperations } from '../../../graphql/gqlNamedOperations-generated';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';

const useDeleteBankDetails = (
  bankDetailsId: string,
  onActionSuccess: () => Record<string, unknown>,
  firmendatenId?: string,
  rechtstraegerId?: string
) => {
  const [runDeleteForRechtstraeger] = useDeleteRechtstraegerBankDetailsMutation({
    onCompleted: () => {
      showSuccessMsgDelete(`Bankverbindung`);
      onActionSuccess();
    },
    refetchQueries: () => [gqlOperations.Query.RechnungsAussteller],
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runDeleteForFirmendaten] = useDeleteFirmendatenBankDetailsMutation({
    onCompleted: () => {
      showSuccessMsgDelete(`Bankverbindung`);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return () => {
    if (rechtstraegerId) {
      runDeleteForRechtstraeger({
        variables: {
          rechtstraegerId,
          bankDetailsId,
        },
      });
    } else if (firmendatenId) {
      runDeleteForFirmendaten({
        variables: {
          firmendatenId,
          bankDetailsId,
        },
      });
    }
  };
};

export default useDeleteBankDetails;
