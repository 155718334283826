import { Modal } from 'antd';

export const showConfirmDeleteInfoFeld = (onDelete: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie das Infofeld löschen?`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return onDelete();
    },
  });
};

export const showConfirmArchiveInfoFeld = (runArchive: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie das Infofeld archivieren?`,
    okText: 'Archivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runArchive();
    },
  });
};

export const showConfirmUnarchiveInfoFeld = (runUnarchive: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie das Infofeld reaktivieren?`,
    okText: 'Reaktivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runUnarchive();
    },
  });
};
