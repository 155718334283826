import React, { FC } from 'react';
import { Button, Empty, Space, Table, Typography } from 'antd';
import { FieldArray, FormikProps } from 'formik';
import mahnpositionTableColumns from './mahnpositionTableColumns';
import { MahnpositionFormValues, mahnungUpdateFormFields, MahnungUpdateFormValues } from '../mahnungUpdateFormMapper';
import { MahnungSumVerzugszinsen } from '../../../../../types';
import HistoryModal, { HistoryType } from '../../../../History/shared/HistoryModal';
import { useToggle } from '../../../../../hooks/useToggle';
import MahnpositionDeletionHistoryListingTable from './MahnpositionDeletionHistoryListingTable';

type Props = {
  mahnungId: string;
  formikProps: FormikProps<MahnungUpdateFormValues>;
  onMahnPositionVerzugszinsenBerechnenChange: (isChecked: boolean, mahnPositionId: string) => void;
  isStatusErstellt: boolean;
  vertragspartnerId: string;
  fibuKontoId: string;
  sumVerzugszinsen?: MahnungSumVerzugszinsen;
};

const MahnpositionTable: FC<Props> = ({
  mahnungId,
  formikProps,
  onMahnPositionVerzugszinsenBerechnenChange,
  sumVerzugszinsen,
  isStatusErstellt,
  vertragspartnerId,
  fibuKontoId,
}) => {
  const [isDeletionHistoryOpen, toggleIsDeletionHistoryOpen] = useToggle();

  return (
    <>
      <Space align="start" style={{ width: '100%', justifyContent: 'space-between', marginBottom: '16px' }}>
        <Typography.Title level={5}>Mahnpositionen</Typography.Title>
        <Button onClick={toggleIsDeletionHistoryOpen}>Löschverlauf anzeigen</Button>
      </Space>
      <HistoryModal entityName="Mahnposition" historyType={HistoryType.Deletion} open={isDeletionHistoryOpen} onCancel={toggleIsDeletionHistoryOpen}>
        <MahnpositionDeletionHistoryListingTable mahnungId={mahnungId} />
      </HistoryModal>

      <FieldArray
        name={mahnungUpdateFormFields.mahnPositionList}
        render={(arrayHelpers) => {
          return (
            <Table<MahnpositionFormValues>
              locale={{
                emptyText: <Empty description={<span>Keine Mahnpositionen vorhanden</span>} />,
              }}
              size="small"
              dataSource={formikProps.values.mahnPositionList}
              columns={mahnpositionTableColumns(
                mahnungId,
                formikProps,
                arrayHelpers,
                onMahnPositionVerzugszinsenBerechnenChange,
                isStatusErstellt,
                vertragspartnerId,
                fibuKontoId,
                sumVerzugszinsen
              )}
              rowKey={(record) => record.mahnPositionId}
            />
          );
        }}
      />
    </>
  );
};

export default MahnpositionTable;
