import { round } from 'lodash';
import { isBuchungTypeRuecklastschrift, isBuchungTypeZahlung } from '../../../bookingSuggestionHelpers';
import { BookingSuggestionFormValues, OffenePostenForClearingSuggestion } from '../../../Form/buchungsmaskeFormMapper';

export const calculateInitialBetragForNewAuszifferung = (
  formikValues: BookingSuggestionFormValues,
  offenePosten: OffenePostenForClearingSuggestion
) => {
  const availableAmount = formikValues.availableAmount;

  if (isBuchungTypeZahlung(formikValues.buchungsanweisung.buchungType)) {
    const oPSaldoOffenerBetrag = offenePosten.offenerBetrag;
    const result = availableAmount > oPSaldoOffenerBetrag ? oPSaldoOffenerBetrag : availableAmount;
    return round(result, 2);
  }

  if (isBuchungTypeRuecklastschrift(formikValues.buchungsanweisung.buchungType)) {
    const possibleBetragForOP = offenePosten.offenerBetrag - offenePosten.gesamtBetrag;

    const result = availableAmount < possibleBetragForOP ? possibleBetragForOP : availableAmount;
    return round(result, 2);
  }

  return 0;
};
