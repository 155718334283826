import React, { FC } from 'react';
import { Row } from 'antd';
import PDFPageCol from './PDFPageCol';
import { mockVorschreibungEinzelStorno } from './mocks/vorschreibung/vorschreibungEinzelStornoMocks';
import VorschreibungTemplate, { VorschreibungSelectableSection } from './templates/vorschreibung/VorschreibungTemplate';

type PDFPageVorschreibungEinzelStornoProps = {
  isInEditMode?: boolean;
  selectedTextbaustein?: VorschreibungSelectableSection;
};

const PDFPageVorschreibungEinzelStorno: FC<PDFPageVorschreibungEinzelStornoProps> = ({ isInEditMode, selectedTextbaustein }) => (
  <Row>
    <PDFPageCol
      pdfTemplate={
        <VorschreibungTemplate data={mockVorschreibungEinzelStorno} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />
      }
    />
  </Row>
);

export default PDFPageVorschreibungEinzelStorno;
