import React, { FC } from 'react';
import { Select, Typography } from 'antd';
import { Zinsliste } from '../../../../types';
import { StyledSpaceFullWidthSecondElement } from '../../../../components/Space/styled/StyledSpaceFullWidthInFilter';
import VPosExportBtnAndModal from './VPosExportBtnAndModal';
import { TVPosForVertragLevel } from '../../Card/VPosForVertragLevelList/vPosForVertragLevelList.model';

type Props = {
  selectedVPosIdList: string[];
  onChange: (list: string[]) => void;
  vorschreibungspositionList: TVPosForVertragLevel[];
  zinsliste: Zinsliste;
};

const VPosFilterAndExportBtn: FC<Props> = ({ selectedVPosIdList, onChange, vorschreibungspositionList, zinsliste }) => {
  return (
    <StyledSpaceFullWidthSecondElement>
      <Typography.Text strong>Vorschreibungsposition: </Typography.Text>
      <Select
        mode="multiple"
        allowClear
        placeholder="Filtern Sie nach Vorschreibungsposition"
        style={{ width: '100%' }}
        value={selectedVPosIdList}
        onChange={(list) => {
          onChange(list);
        }}
      >
        {vorschreibungspositionList.map((vPos) => (
          <Select.Option key={vPos.bezeichnung} value={vPos.bezeichnung}>
            {vPos.bezeichnung}
          </Select.Option>
        ))}
      </Select>
      <VPosExportBtnAndModal zinsliste={zinsliste} />
    </StyledSpaceFullWidthSecondElement>
  );
};

export default VPosFilterAndExportBtn;
