import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';
import {
  mapFormValuesToPaymentListingFilters,
  mapQueryParamsToFormValues,
  mapQueryParamsToPaymentListQueryVariables,
  PaymentListingFiltersFormValues,
} from './Filters/paymentListingFiltersFormMapper';
import { TPaymentQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../hooks/useQueryWithPagingAndSorting';
import { usePaymentListQuery } from '../gql/Order/PaymentQueries.types';
import PaymentListingFilters from './Filters/PaymentListingFilters';
import PaymentListingTable from './Table/Level 1/PaymentListingTable';
import PaymentListSummary from './PaymentListSummary';
import useSelectionForPayment from './useSelectionForPayment';

const PaymentListing = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TPaymentQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(navigate, queryParams, paginationParams);
  };

  const { data, loading, refetch, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    usePaymentListQuery,
    {
      variables: {
        ...mapQueryParamsToPaymentListQueryVariables(queryParams),
      },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: paginationParams.currentPage ? Number(paginationParams.currentPage) : DEFAULT_CURRENT,
      pageSize: paginationParams.pageSize ? Number(paginationParams.pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const paymentList = data?.getPaymentList.data.contentList ?? [];
  const paymentIds = paymentList.map((payment) => payment.paymentId);

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: PaymentListingFiltersFormValues) => {
    const filters = mapFormValuesToPaymentListingFilters(values);
    updateQueryParams(navigate, filters, paginationParams);
  };

  const { excludeList, onResetFilter, rowSelection } = useSelectionForPayment(paymentIds, loading, pagination);

  return (
    <>
      <Formik<PaymentListingFiltersFormValues> initialValues={mapQueryParamsToFormValues(queryParams)} onSubmit={onChange}>
        {(formikProps) => (
          <PaymentListingFilters
            formikProps={formikProps}
            queryParams={mapFormValuesToPaymentListingFilters(formikProps.values)}
            onActionSuccess={refetch}
            excludeList={excludeList}
            onResetFilter={onResetFilter}
          />
        )}
      </Formik>
      <PaymentListSummary queryParams={queryParams} />
      <PaymentListingTable
        pagination={pagination}
        paymentList={paymentList}
        loading={loading}
        onUpdateSuccess={refetch}
        handleTableSorting={handleTableSorting}
        rowSelection={rowSelection}
      />
    </>
  );
};

export default PaymentListing;
