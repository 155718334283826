import { FC } from 'react';
import { PostIt, PostItInput } from '../../../../../types';
import PostItIconAndViewAndUpdateMode from '../../../../../components/PostIt/PostItIconAndViewAndUpdateMode';
import PostItIconAndCreateMode from '../../../../../components/PostIt/PostItIconAndCreateMode';
import { showSuccessMsgCreate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { useToggle } from '../../../../../hooks/useToggle';
import { useCreateObjektInfoFeldPostItMutation } from '../../gql/ObjektInfoFeldMutations.types';

type Props = {
  objektId: string;
  objektInfoFeldId: string;
  postIt?: PostIt | null;
  refresh: () => void;
};

const ObjektInfoFeldPostIt: FC<Props> = ({ objektId, objektInfoFeldId, postIt, refresh }) => {
  const [isPostItOpen, toggleIsPostItOpen] = useToggle(false);

  const [runCreate] = useCreateObjektInfoFeldPostItMutation({
    onCompleted: () => {
      showSuccessMsgCreate('Post-It');
      toggleIsPostItOpen();
      refresh();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const handleCreateSubmit = (values: PostItInput) => {
    runCreate({
      variables: {
        objektId,
        objekInfoFeldId: objektInfoFeldId,
        input: {
          immerAnzeigen: false,
          titel: values.titel,
          text: values.text,
        },
      },
    });
  };

  const handleCreateCancel = () => {
    toggleIsPostItOpen();
  };

  return postIt ? (
    <PostItIconAndViewAndUpdateMode postIt={postIt} refresh={refresh} showImmerAnzeigenInUpdateMode={false} />
  ) : (
    <PostItIconAndCreateMode
      isPostItOpen={isPostItOpen}
      onIconClick={toggleIsPostItOpen}
      onFormCancel={handleCreateCancel}
      onFormSubmit={handleCreateSubmit}
      showImmerAnzeigen={false}
    />
  );
};

export default ObjektInfoFeldPostIt;
