import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateKundenSystemMutationVariables = Types.Exact<{
  request: Types.CreateKundenSystemInput;
}>;

export type CreateKundenSystemMutation = {
  createKundenSystem: {
    data: { kundenSystemId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateKundenSystemMutationVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
  request: Types.UpdateKundenSystemInput;
}>;

export type UpdateKundenSystemMutation = {
  updateKundenSystem: {
    data: { kundenSystemId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteKundenSystemMutationVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
}>;

export type DeleteKundenSystemMutation = {
  deleteKundenSystem: {
    data: { kundenSystemId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateKundenSystemDocument = gql`
  mutation CreateKundenSystem($request: CreateKundenSystemInput!) {
    createKundenSystem(request: $request) {
      data {
        kundenSystemId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateKundenSystemMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateKundenSystemMutation, CreateKundenSystemMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateKundenSystemMutation, CreateKundenSystemMutationVariables>(CreateKundenSystemDocument, options);
}
export type CreateKundenSystemMutationHookResult = ReturnType<typeof useCreateKundenSystemMutation>;
export type CreateKundenSystemMutationResult = Apollo.MutationResult<CreateKundenSystemMutation>;
export type CreateKundenSystemMutationOptions = Apollo.BaseMutationOptions<CreateKundenSystemMutation, CreateKundenSystemMutationVariables>;
export const UpdateKundenSystemDocument = gql`
  mutation UpdateKundenSystem($kundenSystemId: ID!, $request: UpdateKundenSystemInput!) {
    updateKundenSystem(kundenSystemId: $kundenSystemId, request: $request) {
      data {
        kundenSystemId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateKundenSystemMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateKundenSystemMutation, UpdateKundenSystemMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateKundenSystemMutation, UpdateKundenSystemMutationVariables>(UpdateKundenSystemDocument, options);
}
export type UpdateKundenSystemMutationHookResult = ReturnType<typeof useUpdateKundenSystemMutation>;
export type UpdateKundenSystemMutationResult = Apollo.MutationResult<UpdateKundenSystemMutation>;
export type UpdateKundenSystemMutationOptions = Apollo.BaseMutationOptions<UpdateKundenSystemMutation, UpdateKundenSystemMutationVariables>;
export const DeleteKundenSystemDocument = gql`
  mutation DeleteKundenSystem($kundenSystemId: ID!) {
    deleteKundenSystem(kundenSystemId: $kundenSystemId) {
      data {
        kundenSystemId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteKundenSystemMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteKundenSystemMutation, DeleteKundenSystemMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteKundenSystemMutation, DeleteKundenSystemMutationVariables>(DeleteKundenSystemDocument, options);
}
export type DeleteKundenSystemMutationHookResult = ReturnType<typeof useDeleteKundenSystemMutation>;
export type DeleteKundenSystemMutationResult = Apollo.MutationResult<DeleteKundenSystemMutation>;
export type DeleteKundenSystemMutationOptions = Apollo.BaseMutationOptions<DeleteKundenSystemMutation, DeleteKundenSystemMutationVariables>;
