import * as Types from '../../../../types';

import { gql } from '@apollo/client';
export type UstKategorieFieldsFragment = {
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  firmendatenId?: string | null;
  kurzBezeichnung: string;
  ustKategorieId: string;
  ustVomAufwand?: number | null;
  vstKuerzung?: boolean | null;
  status?: { text: string; value: Types.UstKategorieStatus } | null;
  ustKategorieEntryList: Array<{
    additionKonto: number;
    buchungsKZ?: string | null;
    createTs?: string | null;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    rechnungskennzeichen: string;
    steuersatz: number;
    ustKategorieEntryId?: string | null;
    ustKz: {
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      kuerzel: string;
      umsatzsteuerkennzeichenId: string;
      status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }>;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type UstKategorieEntryFieldsFragment = {
  additionKonto: number;
  buchungsKZ?: string | null;
  createTs?: string | null;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  rechnungskennzeichen: string;
  steuersatz: number;
  ustKategorieEntryId?: string | null;
  ustKz: {
    bezeichnung: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    kuerzel: string;
    umsatzsteuerkennzeichenId: string;
    status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const UstKategorieEntryFieldsFragmentDoc = gql`
  fragment UstKategorieEntryFields on UstKategorieEntry {
    additionKonto
    buchungsKZ
    createTs
    createdBy
    createdByMitarbeiterId
    rechnungskennzeichen
    steuersatz
    ustKategorieEntryId
    ustKz {
      bezeichnung
      createTs
      createdBy
      createdByMitarbeiterId
      kuerzel
      status {
        text
        value
      }
      umsatzsteuerkennzeichenId
      warningList {
        attribute
        message
        type
      }
    }
    warningList {
      attribute
      message
      type
    }
  }
`;
export const UstKategorieFieldsFragmentDoc = gql`
  fragment UstKategorieFields on UstKategorie {
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    firmendatenId
    kurzBezeichnung
    status {
      text
      value
    }
    ustKategorieEntryList {
      ...UstKategorieEntryFields
    }
    ustKategorieId
    ustVomAufwand
    vstKuerzung
    warningList {
      attribute
      message
      type
    }
  }
  ${UstKategorieEntryFieldsFragmentDoc}
`;
