import React, { FC } from 'react';
import { showSuccessMsgDelete } from '../../../../../../components/message';
import { useDeleteObjektInfoFeldMutation } from '../../../gql/ObjektInfoFeldMutations.types';
import AuflageUpdateForm from '../../../Form/Auflage/Update/AuflageUpdateForm';
import { ObjektInfoFeldAuflage } from '../../../../../../types';
import useTicketCount from '../../../../../Ticket/useTicketCount';
import InfoFeldListItemActions from '../InfoFeldListItemActions';

type Props = {
  infoFeld: ObjektInfoFeldAuflage;
  objektId: string;
  onSuccess: () => void;
};

const AuflageListItemActions: FC<Props> = ({ infoFeld, objektId, onSuccess }) => {
  const { ticketCount, refetchTicketCount } = useTicketCount({ objektInfoFeldId: infoFeld.objektInfoFeldId });

  const onActionSuccess = () => {
    onSuccess();
    refetchTicketCount();
  };

  const [runDelete] = useDeleteObjektInfoFeldMutation({
    variables: { objektId, infoFeldId: infoFeld.objektInfoFeldId },
    onCompleted: () => {
      showSuccessMsgDelete(`Auflagen Infofeld`);
      onActionSuccess();
    },
  });

  return (
    <InfoFeldListItemActions
      onDelete={runDelete}
      ticketCount={ticketCount}
      objektId={objektId}
      objektInfoFeldId={infoFeld.objektInfoFeldId}
      fileIdList={infoFeld.fileIdList}
      onSuccess={onActionSuccess}
      postIt={infoFeld.postIt}
      deletable={infoFeld.deletable}
      infoFeldStatus={infoFeld.status}
      renderUpdateForm={(closeDrawer, activeKeyTab) => (
        <AuflageUpdateForm
          objektId={objektId}
          onInfoFeldAction={() => {
            onActionSuccess();
            closeDrawer();
          }}
          onTicketAction={onActionSuccess}
          onCancel={closeDrawer}
          infoFeld={infoFeld}
          activeKeyTab={activeKeyTab}
        />
      )}
    />
  );
};

export default AuflageListItemActions;
