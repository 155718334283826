import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../styles/pdfStyles';
import { Konto } from '../../../../objektAbrechnung-types';
import OAAufteilungsschluessel from './OAAufteilungsschluessel';

const OAAbrKreisKontoBezeichnung: FC<{ konto: Konto }> = ({ konto }) => (
  <View style={[pdfStyles.row]}>
    <Text
      style={[
        pdfStyles.textNormal,
        konto.buchungAusgabenList.length > 0 ? pdfStyles.displayBuchungszeilen : pdfStyles.buchungSumPerAccount,
        pdfStyles.column,
        { width: '40%' },
      ]}
    >
      {konto.kontonummer ? `${konto.bezeichnung} / ${konto.kontonummer}` : konto.bezeichnung}
    </Text>
    <View style={[pdfStyles.column, { width: '50%', marginTop: '5mm' }]}>
      {konto.aufteilungsschluessel ? <OAAufteilungsschluessel aufteilungsschluessel={konto.aufteilungsschluessel} /> : null}
    </View>

    <View style={[pdfStyles.column, { width: '10%' }]}>
      {konto.buchungAusgabenList.length === 0 && (
        <Text style={[pdfStyles.textNormal, pdfStyles.buchungSumPerAccount, { textAlign: 'right', paddingRight: '3mm' }]}>
          {konto.betragNettoSummeBuchungen}
        </Text>
      )}
    </View>
  </View>
);
export default OAAbrKreisKontoBezeichnung;
