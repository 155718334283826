import React, { FC } from 'react';
import { Button, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { DocumentRowProps, findLabelValueByLabelId } from './util';
import { ValueTitle } from '../../../../components/Helpers/ValueTitle';
import { pathsToObjektDetailsPage } from '../../../Objekt/objektUriPaths';
import { generatePathToBestandseinheitDetailsPage } from '../../../Bestandseinheit/bestandseinheitUriPaths';
import { useObjektAbrechnungQuery } from '../../../Abrechnung/BkOrWe/gql/ObjektAbrechnungQueries.types';
import { uriFileOfFirmendaten } from '../../documentApiPaths';
import { useFDAuthorized } from '../../../../shared/Auth/useAuthorized';
import { useTopAbrechnungQuery } from '../../../Abrechnung/BkOrWe/Top/gql/TopAbrechnungQueries.types';
import { DocumentInfo, DocumentRowContextProvider } from './documentRowContext';
import LinkWithStatus from '../../../../components/Link/LinkWithStatus';
import OutdatedData from '../../../../components/Helpers/OutdatedData';
import AvatarBasedOnFileInfoTypeWithTooltip from '../../../../components/Avatar/BasedOnFileInfoType/AvatarBasedOnFileInfoTypeWithTooltip';
import { isAbrechnungTypeBkAbrechnung, pathToBkOrWeAbrechnungDetails } from '../../../Abrechnung/shared/abrechnungHelpers';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { AbrechnungType } from '../../../../types';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

const RowTopAbrechnung: FC<DocumentRowProps> = ({ record, ...restProps }) => {
  const beKurzbezeichnung = findLabelValueByLabelId(record.labelList, 'beKurzbezeichnung');

  return beKurzbezeichnung !== 'Alle' ? <TopAbrechnung record={record} {...restProps} /> : <TopAbrechnungAlle record={record} {...restProps} />;
};

const TopAbrechnung: FC<DocumentRowProps> = ({ record, rechnungTyp, invoiceRecipientId, invoiceIssuerId, ...restProps }) => {
  const { activeForFirmendatenId } = useFDAuthorized();

  const objektId = findLabelValueByLabelId(record.labelList, 'objektId');
  const bestandseinheitId = findLabelValueByLabelId(record.labelList, 'bestandseinheitId');
  const objektAbrechnungId = findLabelValueByLabelId(record.labelList, 'objektAbrechnungId');
  const topAbrechnungId = findLabelValueByLabelId(record.labelList, 'topAbrechnungId');
  const beKurzbezeichnung = findLabelValueByLabelId(record.labelList, 'beKurzbezeichnung');
  const isOutdated = findLabelValueByLabelId(record.labelList, 'outdated');

  const { loading, data: maybeTopAbrechnung } = useTopAbrechnungQuery({
    variables: {
      objektId,
      objektAbrechnungId,
      topAbrechnungId,
    },
  });

  const topAbrechnung = maybeTopAbrechnung?.getTopAbrechnung.data;

  const documentInfo: DocumentInfo | undefined = topAbrechnung
    ? {
        column1: (
          <OutdatedData isOutdated={isOutdated} isAvatar>
            <AvatarBasedOnFileInfoTypeWithTooltip fileType={record.type} />
          </OutdatedData>
        ),
        column2: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle
              small
              title="Abrechnung"
              content={
                <DataWithShortenedText
                  maxTextLength={28}
                  text={getBezeichnungForTopAbr(topAbrechnung.type.value, topAbrechnung.vertragspartner.kurzBezeichnung)}
                >
                  {(shortenedText) => (
                    <Link to={pathToBkOrWeAbrechnungDetails(topAbrechnung.type.value, objektId, objektAbrechnungId).objAbr}>{shortenedText}</Link>
                  )}
                </DataWithShortenedText>
              }
            />
          </OutdatedData>
        ),
        column3: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle
              small
              title="Objekt"
              content={<Link to={pathsToObjektDetailsPage(topAbrechnung.objektId).objektDetails}>{topAbrechnung.objektKurzBezeichnung}</Link>}
            />
          </OutdatedData>
        ),
        column4: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle
              small
              title="Top"
              content={<Link to={generatePathToBestandseinheitDetailsPage(objektId, bestandseinheitId)}>{beKurzbezeichnung}</Link>}
            />
          </OutdatedData>
        ),
        column5: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle small title="von" content={<CustomFormattedDate value={topAbrechnung.fromInclusive} />} />
          </OutdatedData>
        ),
        column6: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle small title="bis" content={<CustomFormattedDate value={topAbrechnung.toInclusive} />} />
          </OutdatedData>
        ),
        column7: <OutdatedData isOutdated={isOutdated}>-</OutdatedData>,
        column8: (
          <OutdatedData isOutdated={isOutdated}>
            <Button>
              <Link to={uriFileOfFirmendaten(activeForFirmendatenId, record.fileId, record.name)} target="_blank">
                Öffnen
              </Link>
            </Button>
          </OutdatedData>
        ),
      }
    : undefined;

  return (
    <DocumentRowContextProvider value={{ isLoading: loading, documentInfo }}>
      <tr {...restProps} />
    </DocumentRowContextProvider>
  );
};

const TopAbrechnungAlle: FC<DocumentRowProps> = ({ record, rechnungTyp, invoiceRecipientId, invoiceIssuerId, ...restProps }) => {
  const { activeForFirmendatenId } = useFDAuthorized();

  const objektId = findLabelValueByLabelId(record.labelList, 'objektId');
  const objektAbrechnungId = findLabelValueByLabelId(record.labelList, 'objektAbrechnungId');
  const beKurzbezeichnung = findLabelValueByLabelId(record.labelList, 'beKurzbezeichnung');
  const isOutdated = findLabelValueByLabelId(record.labelList, 'outdated');

  const { loading, data } = useObjektAbrechnungQuery({
    variables: {
      objektId,
      objektAbrechnungId,
    },
  });

  const objektAbrechnung = data?.getObjektAbrechnung.data;

  const documentInfo: DocumentInfo | undefined = objektAbrechnung
    ? {
        column1: (
          <OutdatedData isOutdated={isOutdated} isAvatar>
            <AvatarBasedOnFileInfoTypeWithTooltip fileType={record.type} backgroundColor="#f50" />
          </OutdatedData>
        ),
        column2: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle
              small
              title="Abrechnung"
              content={
                <DataWithShortenedText
                  maxTextLength={28}
                  text={`${isAbrechnungTypeBkAbrechnung(objektAbrechnung.type.value) ? 'BK' : 'WE'} Topabrechnung`}
                >
                  {(shortenedText) => (
                    <Link to={pathToBkOrWeAbrechnungDetails(objektAbrechnung.type.value, objektId, objektAbrechnungId).topAbrTab}>
                      {shortenedText}
                    </Link>
                  )}
                </DataWithShortenedText>
              }
            />
          </OutdatedData>
        ),
        column3: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle
              small
              title="Objekt"
              content={
                objektAbrechnung.objekt ? (
                  <LinkWithStatus
                    to={pathsToObjektDetailsPage(objektId).objektDetails}
                    text={objektAbrechnung.objekt.kurzBezeichnung}
                    status={objektAbrechnung.objekt.status}
                  />
                ) : (
                  <Typography.Text>-</Typography.Text>
                )
              }
            />
          </OutdatedData>
        ),
        column4: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle small title="Top" content={<Link to={pathsToObjektDetailsPage(objektId).objektDetails}>{beKurzbezeichnung}</Link>} />
          </OutdatedData>
        ),
        column5: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle small title="von" content={<CustomFormattedDate value={objektAbrechnung.fromInclusive} />} />
          </OutdatedData>
        ),
        column6: (
          <OutdatedData isOutdated={isOutdated}>
            <ValueTitle small title="bis" content={<CustomFormattedDate value={objektAbrechnung.toInclusive} />} />
          </OutdatedData>
        ),
        column7: <OutdatedData isOutdated={isOutdated}>-</OutdatedData>,
        column8: (
          <OutdatedData isOutdated={isOutdated}>
            <Button>
              <Link to={uriFileOfFirmendaten(activeForFirmendatenId, record.fileId, record.name)} target="_blank">
                Öffnen
              </Link>
            </Button>
          </OutdatedData>
        ),
      }
    : undefined;

  return (
    <DocumentRowContextProvider value={{ isLoading: loading, documentInfo }}>
      <tr {...restProps} />
    </DocumentRowContextProvider>
  );
};

const getBezeichnungForTopAbr = (abrType: AbrechnungType, vertragspartnerKurzBezeichnung: string) => {
  if (isAbrechnungTypeBkAbrechnung(abrType)) {
    return `BK Topabrechnung - ${vertragspartnerKurzBezeichnung}`;
  }

  return `WE Topabrechnung - ${vertragspartnerKurzBezeichnung}`;
};

export default RowTopAbrechnung;
