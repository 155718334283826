import React, { FC } from 'react';
import { Empty } from 'antd';
import abrDefTemplateTableColumns from './abrDefTemplateTableColumns';
import { AbrechnungsdefinitionListEntry } from '../../../types';
import NestedTable from '../../../components/Table/NestedTable/NestedTable';

type Props = {
  abrechnungsdefinitionList?: AbrechnungsdefinitionListEntry[];
  loading: boolean;
  refetch: () => void;
};

const AbrDefTemplateTable: FC<Props> = ({ abrechnungsdefinitionList, loading, refetch }) => {
  return (
    <NestedTable<AbrechnungsdefinitionListEntry>
      locale={{
        emptyText: <Empty description={<span>Keine Abrechnungsdefinition vorhanden</span>} />,
      }}
      dataSource={abrechnungsdefinitionList}
      loading={loading}
      columns={abrDefTemplateTableColumns(refetch)}
      rowKey={(record) => record.abrechnungsdefinitionId}
      expandable={{
        rowExpandable: () => false,
      }}
      useHighlighting
    />
  );
};

export default AbrDefTemplateTable;
