import * as Yup from 'yup';
import { indAgreeCategoryFormFields } from './categoryFormMapper';
import { entityIsRequired } from '../../../../../../../../../components/message/validationMsg';

export const categoryFormValidationSchema = Yup.object().shape({
  [indAgreeCategoryFormFields.indexOld]: Yup.number().nullable(),
  [indAgreeCategoryFormFields.indexSeriesId]: Yup.string().required(entityIsRequired('Indexreihe')),
  [indAgreeCategoryFormFields.monthYearOld]: Yup.string().required(entityIsRequired('Monat und Jahr', true)),
  [indAgreeCategoryFormFields.validFrom]: Yup.string().required(entityIsRequired('Monat und Jahr', true)),
});
