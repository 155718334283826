import React, { FC } from 'react';
import { Divider } from 'antd';
import { useVorschreibungMailDeliveryQuery } from '../gql/VorschreibungMailDeliveryQueries.types';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import EmailDelivery from './EmailDelivery';

type Props = {
  vorschreibungMailDeliveryId: string;
};

const SourceDeliveryInfoRow: FC<Props> = ({ vorschreibungMailDeliveryId }) => {
  const { data } = useVorschreibungMailDeliveryQuery({ variables: { vorschreibungMailDeliveryId } });
  const vorschreibungMailDelivery = data?.getVorschreibungMailDelivery.data;

  if (!vorschreibungMailDelivery?.sourceDelivery) return null;

  return (
    <>
      <Divider />

      <CardDetailsInfoRow infoRowTitle="Versendet aus (Quelle)">
        <EmailDelivery emailDelivery={vorschreibungMailDelivery.sourceDelivery} />
      </CardDetailsInfoRow>
    </>
  );
};

export default SourceDeliveryInfoRow;
