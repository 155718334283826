import React, { FC, useState } from 'react';
import { Transfer } from 'antd';
import { showSuccessMsgOther } from '../../../../../../components/message';
import FormButtons from '../../../../../../components/Button/FormButtons';
import { useFdVorschreibungspositionListQuery } from '../../../../../Vorschreibungsposition/gql/VorschreibungspositionQueries.types';
import { useObjektVorschreibungspositionListQuery } from '../../../gql/ObjektVorschreibungPositionQueries.types';
import { useSetObjektVorschreibungspositionListMutation } from '../../../gql/ObjektVorschreibungPositionMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { useFDAuthorized } from '../../../../../../shared/Auth/useAuthorized';
import { getIdList, mapToTransferItems } from './vorschreibungPositionTransferMapper';
import DataWithStatus from '../../../../../../components/Helpers/DataWithStatus';

type VorschreibungPositionTransferProps = {
  objektId: string;
  onOk: () => void;
  onCancel: () => void;
};

const VorschreibungPositionTransfer: FC<VorschreibungPositionTransferProps> = ({ objektId, onOk, onCancel }) => {
  const { activeForFirmendatenId: firmendatenId } = useFDAuthorized();

  const { data: maybeVorschreibungspositionList } = useFdVorschreibungspositionListQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      firmendatenId,
      includeArchiviert: true,
    },
  });
  const vorschreibungspositionList = maybeVorschreibungspositionList?.getVorschreibungspositionListByFirmendatenId.data.contentList ?? [];

  const { data: maybeObjektVorschreibungspositionListList } = useObjektVorschreibungspositionListQuery({
    variables: {
      objektId,
      withDetails: true,
      includeArchiviert: true,
    },
    onCompleted: (data) => setTargetKeys(getIdList(data.getObjektVorschreibungspositionList.data)),
  });
  const objektVorschreibungspositionList = maybeObjektVorschreibungspositionListList?.getObjektVorschreibungspositionList.data ?? [];

  const [setObjektVorschreibungspositionList, { loading: isUpdateLoading }] = useSetObjektVorschreibungspositionListMutation({
    onCompleted: () => {
      showSuccessMsgOther(`Zuweisen der Vorschreibungspositionen war erfolgreich`);
      onOk();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const transferItems = mapToTransferItems(vorschreibungspositionList, objektVorschreibungspositionList);

  return (
    <>
      <Transfer
        dataSource={transferItems}
        titles={['nicht zugewiesen', 'zugewiesen']}
        locale={{ notFoundContent: 'Keine Vorschreibungsposition in dieser Liste' }}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={(nextTargetKeys) => {
          setTargetKeys(nextTargetKeys as string[]);
        }}
        onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
          setSelectedKeys([...(sourceSelectedKeys as string[]), ...(targetSelectedKeys as string[])]);
        }}
        listStyle={{
          width: 310,
          height: 300,
        }}
        render={(record) => <DataWithStatus status={record.status} text={record.bezeichnung} badgeOffset={[5, 5]} />}
      />
      <FormButtons
        onOk={() =>
          setObjektVorschreibungspositionList({
            variables: {
              objektId,
              input: targetKeys,
            },
          })
        }
        onCancel={onCancel}
        updateMode={false}
        isOkDisabled={isUpdateLoading}
        isOkLoading={isUpdateLoading}
      />
    </>
  );
};

export default VorschreibungPositionTransfer;
