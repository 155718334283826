import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { GenerierlaufAsyncStepFieldsFragmentDoc } from '../shared/generierlaufFragments.types';
import { ZinslisteFragmentDoc } from '../../../Zinsliste/gql/ZinslisteFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ZinslisteGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type ZinslisteGenerierlaufQuery = {
  getZinslisteGenerierlauf: {
    data: {
      countFehler: number;
      countNichtVerarbeitet: number;
      countVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierDatum: string;
      generierlaufId: string;
      errorList: Array<{ message: string; type: string }>;
      status: { description?: string | null; text: string; value: Types.GenerierlaufStatus };
      vorschreibungsperiodeList: Array<{ jahr: number; monat: number }>;
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ZinslisteGenerierlaufEntryListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  objektIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  order?: Types.InputMaybe<Types.ZinslisteGenerierlaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type ZinslisteGenerierlaufEntryListQuery = {
  getZinslisteGenerierlaufEntryList: {
    data: {
      contentList: Array<{
        currentStep?: number | null;
        generierlaufEntryId: string;
        infoMessage?: string | null;
        objektId: string;
        objektKurzBezeichnung: string;
        retry: boolean;
        stepCount?: number | null;
        sumBrutto?: number | null;
        sumNetto?: number | null;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { text: string; value: Types.GenerierlaufEntryExitCode } | null;
        stepList: Array<{
          designation: string;
          receivingApplication?: string | null;
          stepId: string;
          type?: string | null;
          status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
        }>;
        vorschreibungsperiode: { jahr: number; monat: number };
        warningList: Array<{ message: string; type: string }>;
        zinsliste?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          sumBrutto?: number | null;
          sumNetto?: number | null;
          sumUst?: number | null;
          bestandseinheitList: Array<{
            bestandseinheitId: string;
            bezeichnung: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            aufteilungsschluesselList: Array<{
              aufteilungsschluesselId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              wert: number;
              warningList: Array<{ message: string; type: string; attribute?: string | null }>;
            }>;
            vertragList: Array<{
              ausstellerBankDetailsId?: string | null;
              ausstellerId: string;
              ausstellerKurzBezeichnung: string;
              consolidationCriteria?: string | null;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              vertragId: string;
              vertragspartnerBankDetailsId?: string | null;
              belegform: { text: string; value: Types.BelegFormArt };
              dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
              paymentMethod: { text: string; value: Types.PaymentMethod };
              verrechnungsart: { text: string; value: Types.Verrechnungsart };
              vertragVorschreibungspositionList: Array<{
                bezeichnung: string;
                brutto?: number | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                netto?: number | null;
                performancePeriodFromInclusive: string;
                performancePeriodToInclusive: string;
                steuersatz?: number | null;
                ust?: number | null;
                vorschreibungspositionId: string;
                zinslisteVertragVorschrPosId: string;
                warningList: Array<{ message: string; type: string; attribute?: string | null }>;
              }>;
              vertragLevel: { shortName: string; text: string; value: Types.VertragLevel };
              vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
              vertragspartner: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              warningList: Array<{ message: string; type: string; attribute?: string | null }>;
            }>;
            warningList: Array<{ message: string; type: string; attribute?: string | null }>;
          }>;
          kopfdaten: {
            generierDatum: string;
            generierlaufId?: string | null;
            objektId: string;
            objektKurzBezeichnung: string;
            zinslisteId: string;
            vorschreibungsperiode: { jahr: number; monat: number };
          };
          status: { description?: string | null; text: string; value: Types.ZinslisteStatus };
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        } | null;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const ZinslisteGenerierlaufDocument = gql`
  query ZinslisteGenerierlauf($generierlaufId: ID!) {
    getZinslisteGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countNichtVerarbeitet
        countVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        generierDatum
        generierlaufId
        status {
          description
          text
          value
        }
        vorschreibungsperiodeList {
          jahr
          monat
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useZinslisteGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<ZinslisteGenerierlaufQuery, ZinslisteGenerierlaufQueryVariables> &
    ({ variables: ZinslisteGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZinslisteGenerierlaufQuery, ZinslisteGenerierlaufQueryVariables>(ZinslisteGenerierlaufDocument, options);
}
export function useZinslisteGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ZinslisteGenerierlaufQuery, ZinslisteGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZinslisteGenerierlaufQuery, ZinslisteGenerierlaufQueryVariables>(ZinslisteGenerierlaufDocument, options);
}
export function useZinslisteGenerierlaufSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ZinslisteGenerierlaufQuery, ZinslisteGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ZinslisteGenerierlaufQuery, ZinslisteGenerierlaufQueryVariables>(ZinslisteGenerierlaufDocument, options);
}
export type ZinslisteGenerierlaufQueryHookResult = ReturnType<typeof useZinslisteGenerierlaufQuery>;
export type ZinslisteGenerierlaufLazyQueryHookResult = ReturnType<typeof useZinslisteGenerierlaufLazyQuery>;
export type ZinslisteGenerierlaufSuspenseQueryHookResult = ReturnType<typeof useZinslisteGenerierlaufSuspenseQuery>;
export type ZinslisteGenerierlaufQueryResult = Apollo.QueryResult<ZinslisteGenerierlaufQuery, ZinslisteGenerierlaufQueryVariables>;
export const ZinslisteGenerierlaufEntryListDocument = gql`
  query ZinslisteGenerierlaufEntryList(
    $generierlaufId: ID!
    $objektIdList: [String!]
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $order: ZinslisteGenerierlaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getZinslisteGenerierlaufEntryList(
      generierlaufId: $generierlaufId
      objektIdList: $objektIdList
      exitCodeList: $exitCodeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          currentStep
          errorList {
            message
            type
          }
          exitCode {
            text
            value
          }
          generierlaufEntryId
          infoMessage
          objektId
          objektKurzBezeichnung
          retry
          stepCount
          stepList {
            ...GenerierlaufAsyncStepFields
          }
          sumBrutto
          sumNetto
          vorschreibungsperiode {
            jahr
            monat
          }
          warningList {
            message
            type
          }
          zinsliste {
            ...Zinsliste
          }
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${GenerierlaufAsyncStepFieldsFragmentDoc}
  ${ZinslisteFragmentDoc}
`;
export function useZinslisteGenerierlaufEntryListQuery(
  baseOptions: Apollo.QueryHookOptions<ZinslisteGenerierlaufEntryListQuery, ZinslisteGenerierlaufEntryListQueryVariables> &
    ({ variables: ZinslisteGenerierlaufEntryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZinslisteGenerierlaufEntryListQuery, ZinslisteGenerierlaufEntryListQueryVariables>(
    ZinslisteGenerierlaufEntryListDocument,
    options
  );
}
export function useZinslisteGenerierlaufEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ZinslisteGenerierlaufEntryListQuery, ZinslisteGenerierlaufEntryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZinslisteGenerierlaufEntryListQuery, ZinslisteGenerierlaufEntryListQueryVariables>(
    ZinslisteGenerierlaufEntryListDocument,
    options
  );
}
export function useZinslisteGenerierlaufEntryListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ZinslisteGenerierlaufEntryListQuery, ZinslisteGenerierlaufEntryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ZinslisteGenerierlaufEntryListQuery, ZinslisteGenerierlaufEntryListQueryVariables>(
    ZinslisteGenerierlaufEntryListDocument,
    options
  );
}
export type ZinslisteGenerierlaufEntryListQueryHookResult = ReturnType<typeof useZinslisteGenerierlaufEntryListQuery>;
export type ZinslisteGenerierlaufEntryListLazyQueryHookResult = ReturnType<typeof useZinslisteGenerierlaufEntryListLazyQuery>;
export type ZinslisteGenerierlaufEntryListSuspenseQueryHookResult = ReturnType<typeof useZinslisteGenerierlaufEntryListSuspenseQuery>;
export type ZinslisteGenerierlaufEntryListQueryResult = Apollo.QueryResult<
  ZinslisteGenerierlaufEntryListQuery,
  ZinslisteGenerierlaufEntryListQueryVariables
>;
