import React, { FC } from 'react';
import MonthPicker from '../../../../../../../../../components/DatePicker/MonthPicker/MonthPicker';
import FormItemWithFieldHelp from '../../../../../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  name: string;
  label: string;
  placeholder?: string;
  onChange?: (month: number, year: number) => void;
  fieldHelp?: string | null;
};

const MonthYearFormItem: FC<Props> = ({ name, label, placeholder, onChange, fieldHelp }) => (
  <FormItemWithFieldHelp name={name} label={label} fieldHelp={fieldHelp}>
    <MonthPicker
      name={name}
      allowClear={false}
      placeholder={placeholder}
      onChange={(value) => !!value && onChange && onChange(value.month() + 1, value.year())}
    />
  </FormItemWithFieldHelp>
);

export default MonthYearFormItem;
