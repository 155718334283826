import React, { FC } from 'react';
import { Drawer, MenuProps, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { showSuccessMsgDelete } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { EmailDeliveryDefinitionBase } from '../../../types';
import { useToggle } from '../../../hooks/useToggle';
import { radixActionStyles } from '../../../helpers/radixIconsStyles';
import HistoryModal, { HistoryType } from '../../History/shared/HistoryModal';
import EmailDeliveryDefinitionTemplateChangeHistoryListingTable from './EmailDeliveryDefinitionTemplateChangeHistoryListingTable';
import EmailDeliveryDefinitionTemplateForm from '../Form/EmailDeliveryDefinitionTemplateForm';
import { useDeleteEmailDeliveryDefinitionTemplateMutation } from '../gql/EmailDeliveryDefinitionTemplateMutations.types';
import ActionDropdown from '../../../components/Dropdown/ActionDropdown';

type Props = {
  emailDeliveryDefinition: EmailDeliveryDefinitionBase;
  onAction: () => void;
};

const EmailDeliveryDefinitionTemplateTableActions: FC<Props> = ({ emailDeliveryDefinition, onAction }) => {
  const [isDrawerOpen, toggleDrawerOpen] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();
  const entity = 'Versanddefintion';

  const [runDelete] = useDeleteEmailDeliveryDefinitionTemplateMutation({
    variables: {
      emailDeliveryDefinitionId: emailDeliveryDefinition.emailDeliveryDefinitionId,
    },
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Bearbeiten',
      onClick: toggleDrawerOpen,
      icon: <EditOutlined />,
    },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '3',
      label: 'Löschen',
      onClick: showConfirmDelete(runDelete, emailDeliveryDefinition),
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  return (
    <>
      <ActionDropdown items={items} />
      <Drawer title="E-Mail Versanddefinition anlegen" width={720} onClose={toggleDrawerOpen} open={isDrawerOpen} destroyOnClose>
        <EmailDeliveryDefinitionTemplateForm
          emailDeliveryDefinitionId={emailDeliveryDefinition.emailDeliveryDefinitionId}
          onSuccess={() => {
            toggleDrawerOpen();
            onAction();
          }}
          onCancel={toggleDrawerOpen}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <EmailDeliveryDefinitionTemplateChangeHistoryListingTable emailDeliveryDefinitionId={emailDeliveryDefinition.emailDeliveryDefinitionId} />
      </HistoryModal>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void, emailDeliveryDefinition: EmailDeliveryDefinitionBase) => () => {
  Modal.confirm({
    title: `Möchten Sie die E-Mail Versanddefinition löschen?`,
    content: `${emailDeliveryDefinition.bezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default EmailDeliveryDefinitionTemplateTableActions;
