import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input, InputNumber } from 'formik-antd';
import { kontierungstabelleFormValidationSchema } from './kontierungstabelleFormValidationSchema';
import KSFirmendatenSelect from '../../KSFirmendatenSelect/KSFirmendatenSelect';
import { Spacer } from '../../../components/Grid';
import FormButtons from '../../../components/Button/FormButtons';
import {
  useCreateFdKontierungstabelleMutation,
  useCreateKsKontierungstabelleMutation,
  useUpdateKontierungstabelleMutation,
} from '../gql/KontierungstabelleMutations.types';
import { showSuccessMsgCreate } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import {
  kontierungstabelleFormFields,
  KontierungstabelleFormValues,
  mapFormValuesToKontierungstabelle,
  mapKontierungstabelleToFormValues,
} from './kontierungstabelleFormMapper';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import { Kontierungstabelle, KontoKlasse } from '../../../types';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';
import AufwandskontenSelect from '../../shared/AufwandskontenSelect';

type Props = {
  kontierungstabelle?: Kontierungstabelle;
  onSuccess: () => void;
  onCancel: () => void;
};

const KontierungstabelleForm: FC<Props> = ({ kontierungstabelle, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'Kontierungstabelle'>('Kontierungstabelle');

  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();
  const isUpdate = !!kontierungstabelle;

  const [createKSKontierungstabelle, { loading: loadingCreateForKS }] = useCreateKsKontierungstabelleMutation({
    onCompleted: () => {
      showSuccessMsgCreate('Kontierungstabelle erfolgreich angelegt');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [createFDKontierungstabelle, { loading: loadingCreateForFD }] = useCreateFdKontierungstabelleMutation({
    onCompleted: () => {
      showSuccessMsgCreate('Kontierungstabelle erfolgreich angelegt');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [updateKontierungstabelle, { loading: loadingUpdate }] = useUpdateKontierungstabelleMutation({
    onCompleted: () => {
      showSuccessMsgCreate('Kontierungstabelle erfolgreich aktualisiert');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<KontierungstabelleFormValues>
      initialValues={mapKontierungstabelleToFormValues(kontierungstabelle)}
      validationSchema={kontierungstabelleFormValidationSchema}
      onSubmit={(formValues, { setSubmitting }) => {
        const input = mapFormValuesToKontierungstabelle(formValues);
        if (!kontierungstabelle) {
          if (formValues.firmendatenId) {
            createFDKontierungstabelle({
              variables: { firmendatenId: formValues.firmendatenId, input },
            }).finally(() => setSubmitting(false));
          } else {
            createKSKontierungstabelle({
              variables: { kundenSystemId, input },
            }).finally(() => setSubmitting(false));
          }
        } else {
          updateKontierungstabelle({
            variables: {
              kontierungstabelleId: kontierungstabelle.kontierungstabelleId,
              input,
            },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithoutColon
            name={kontierungstabelleFormFields.firmendatenId}
            label="Firma auswählen"
            help="Kontierungstabelle wird nur für diese Firma angewendet"
          >
            <KSFirmendatenSelect
              name={kontierungstabelleFormFields.firmendatenId}
              disabled={isUpdate}
              placeholder={!isUpdate ? 'Firma' : 'Keine Firma'}
            />
          </FormItemWithoutColon>

          <FormItemWithFieldHelp
            name={kontierungstabelleFormFields.bezeichnung}
            label="Bezeichnung"
            fieldHelp={getFieldHelpText('Kontierungstabelle.bezeichnung')}
          >
            <Input
              id={kontierungstabelleFormFields.bezeichnung}
              name={kontierungstabelleFormFields.bezeichnung}
              placeholder='z.B. Kontierungstabelle "leicht zu merken"'
            />
          </FormItemWithFieldHelp>

          <FormItemWithFieldHelp
            name={kontierungstabelleFormFields.basiskonto}
            label="Basiskonto Erlös"
            fieldHelp={getFieldHelpText('Kontierungstabelle.basiskonto')}
          >
            <InputNumber
              style={{ width: '100%' }}
              id={kontierungstabelleFormFields.basiskonto}
              name={kontierungstabelleFormFields.basiskonto}
              placeholder="z.B. 49990"
            />
          </FormItemWithFieldHelp>

          <FormItemWithFieldHelp
            name={kontierungstabelleFormFields.subAdministrationExpenseAccountId}
            label="Aufwandskonto (Subverwaltung)"
            fieldHelp={getFieldHelpText('Kontierungstabelle.subAdministrationExpenseAccountId')}
          >
            <AufwandskontenSelect
              name={kontierungstabelleFormFields.subAdministrationExpenseAccountId}
              showKontoNummerAsTag
              klasseList={[KontoKlasse.HandelswarenEinsatz, KontoKlasse.BetrieblicherAufwand, KontoKlasse.PersonalAufwand, KontoKlasse.FinanzAufwand]}
              placeholder="Aufwandskonto auswählen"
            />
          </FormItemWithFieldHelp>

          <Spacer height={16} />

          <FormButtons
            updateMode={isUpdate}
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={loadingCreateForKS || loadingCreateForFD || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default KontierungstabelleForm;
