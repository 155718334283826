import React from 'react';
import { Route } from 'react-router';

import AuthRoute from '../../../../security/AuthRoute';

import { isAdmin } from '../../../../security/permissionChecks';
// @ts-ignore
import VerwaltungBenutzerDetailsPage from './index';
import { URI_ANDROMEDA_SYS_SETTINGS_USER_DETAILS_PAGE } from '../../../../constants/andromedaSystemSettingsUriPaths';

const andromedaSysSettingsVerwaltungBenutzerDetailsPageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS_USER_DETAILS_PAGE}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<VerwaltungBenutzerDetailsPage />} />}
  />
);

export default andromedaSysSettingsVerwaltungBenutzerDetailsPageRoute;
