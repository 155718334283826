import React, { FC } from 'react';
import { Empty, List, Space, Typography } from 'antd';
import { BankOutlined } from '@ant-design/icons';
import ObjektBankDetailsListItem from './ListItem/ObjektBankDetailsListItem';
import { ObjektBankDetails, ObjektBankDetailsVerwendung, ResponseDataWarning } from '../../../types';
import { Spacer } from '../../../components/Grid';

type Props = {
  objektBankDetailsList: ObjektBankDetails[];
  objektId: string;
  onAction: () => void;
  verwendungListTypeInUse: ObjektBankDetailsVerwendung[];
  rechtstraegerId: string;
  weRechnungsAusstellerId?: string;
  warningList: ResponseDataWarning[];
  rechtstraegerFibuKontoCreatable?: boolean;
  weRechnungsAusstellerFibuKontoCreatable?: boolean;
};

const ObjektBankDetailsListing: FC<Props> = ({
  objektBankDetailsList,
  objektId,
  rechtstraegerId,
  onAction,
  verwendungListTypeInUse,
  weRechnungsAusstellerId,
  warningList,
  rechtstraegerFibuKontoCreatable,
  weRechnungsAusstellerFibuKontoCreatable,
}) => (
  <List<ObjektBankDetails>
    size="small"
    dataSource={objektBankDetailsList}
    locale={{
      emptyText: <>{warningList.length > 0 ? <EntityWarningList warningList={warningList} /> : <Empty styles={{ image: { height: 60 } }} />}</>,
    }}
    renderItem={(value, index) => (
      <>
        {index > 0 && <Spacer />}
        <ObjektBankDetailsListItem
          objektBankDetails={value}
          objektId={objektId}
          rechtstraegerId={rechtstraegerId}
          weRechnungsAusstellerId={weRechnungsAusstellerId}
          onAction={onAction}
          verwendungListTypeInUse={verwendungListTypeInUse}
          rechtstraegerFibuKontoCreatable={rechtstraegerFibuKontoCreatable}
          weRechnungsAusstellerFibuKontoCreatable={weRechnungsAusstellerFibuKontoCreatable}
        />
      </>
    )}
  />
);

type EntityWarningListProps = {
  warningList: ResponseDataWarning[];
};

const EntityWarningList: FC<EntityWarningListProps> = ({ warningList }) => (
  <>
    {warningList.map((warning) => (
      <Space align="baseline" size={2} key={warning.type}>
        <BankOutlined />
        <Typography.Text type="danger">{warning.message}</Typography.Text>
      </Space>
    ))}
  </>
);

export default ObjektBankDetailsListing;
