import React, { FC } from 'react';
import AusgabenKontoTable from './AusgabenKontoTable';
import { ObjAbrKonto } from '../../../../../../types';

type Props = {
  kontoList: ObjAbrKonto[];
  isAbrKreisRepFonds: boolean;
  fromInclusive: string;
  toInclusive: string;
  objektId: string;
  buchungskreisId: string;
};

const AusgabenRows: FC<Props> = ({ kontoList, isAbrKreisRepFonds, fromInclusive, toInclusive, objektId, buchungskreisId }) => {
  return (
    <>
      {kontoList
        .filter((konto) => konto.summe)
        .map((konto, index) => (
          <AusgabenKontoTable
            key={index}
            konto={konto}
            isAbrKreisRepFonds={isAbrKreisRepFonds}
            fromInclusive={fromInclusive}
            toInclusive={toInclusive}
            objektId={objektId}
            buchungskreisId={buchungskreisId}
          />
        ))}
    </>
  );
};

export default AusgabenRows;
