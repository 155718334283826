import React, { FC } from 'react';
import { Space, Switch, Typography } from 'antd';

type Props = {
  showStorno: boolean;
  setShowStorno: (shouldShowStorno: boolean) => void;
};

const BzShowStornoSwitch: FC<Props> = ({ showStorno, setShowStorno }) => (
  <Space direction="vertical">
    <Typography.Text>Stornierte und Storno-Buchungen anzeigen</Typography.Text>
    <Space>
      <Switch size="small" id="includeStorno" checked={showStorno} onChange={(value) => setShowStorno(value)} />
      <Typography.Text>{showStorno ? 'Ja' : 'Nein'}</Typography.Text>
    </Space>
  </Space>
);

export default BzShowStornoSwitch;
