import { ZinslisteBestandseinheit, ZinslisteBeVertrag } from '../../../../../types';

export type FlattenZinslisteBestandseinheit = {
  bestandseinheitId: string;
  bezeichnung: string;
  vertrag: ZinslisteBeVertrag;
};

export const flatBestandseinheitList = (bestandseinheitList: ZinslisteBestandseinheit[]) =>
  bestandseinheitList.reduce((acc, curBestandseinheit) => {
    const { vertragList, ...bestandseinheit } = curBestandseinheit;
    vertragList.forEach((vertrag) => {
      acc = acc.concat({ bestandseinheitId: bestandseinheit.bestandseinheitId, bezeichnung: bestandseinheit.bezeichnung, vertrag });
    });
    return acc;
  }, [] as FlattenZinslisteBestandseinheit[]);
