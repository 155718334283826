import React, { useEffect, useRef, useState } from 'react';
import { PaginationProps } from 'antd/lib/pagination';
import { PaymentListEntry } from '../../../types';
import { TableRowSelection } from './Table/Level 1/PaymentListingTable';

const useSelectionForPayment = (paymentIds: string[], loading: boolean, pagination: PaginationProps) => {
  const pagesAlreadyOpened = useRef<string[]>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [excludeList, setExcludeList] = useState<string[]>([]);

  // Handle select all rows on init new page
  useEffect(() => {
    const currentPaginationPage = pagination.current?.toString() ?? '1';
    if (paymentIds.some((id) => !selectedRowKeys.includes(id)) || !pagesAlreadyOpened.current?.includes(currentPaginationPage)) {
      if (pagesAlreadyOpened.current) {
        pagesAlreadyOpened.current = [...pagesAlreadyOpened.current, currentPaginationPage];
      } else {
        pagesAlreadyOpened.current = [currentPaginationPage];
      }
      const uniqueSelectedRowKeys = Array.from(new Set([...selectedRowKeys, ...paymentIds]));
      setSelectedRowKeys(uniqueSelectedRowKeys.filter((id) => !excludeList.map((excludeId) => excludeId).includes(id)));
    }
    // TODO: Check if paymentIds is needed here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(paymentIds), loading]);

  // Handle Select All Button
  const onChangeSelection = (selectionSelectedRowKeys: React.Key[]) => {
    if (selectionSelectedRowKeys.length === pagination.pageSize) {
      const uniqueSelectedRowKeys = Array.from(new Set([...selectedRowKeys, ...paymentIds]));
      setSelectedRowKeys([...uniqueSelectedRowKeys]);

      setExcludeList(excludeList.filter((id) => !paymentIds.includes(id)));
    } else if (selectionSelectedRowKeys.length === 0) {
      setSelectedRowKeys(selectedRowKeys.filter((key) => !paymentIds.includes(key)));

      const uniqueExcludeList = Array.from(new Set([...excludeList, ...paymentIds]));
      setExcludeList(uniqueExcludeList);
    }
  };

  // Handle Select Row
  const onSelect = (record: PaymentListEntry, selected: boolean) => {
    if (selected) {
      setSelectedRowKeys([...selectedRowKeys, record.paymentId]);
      setExcludeList(excludeList.filter((id) => id !== record.paymentId));
    } else {
      setSelectedRowKeys(selectedRowKeys.filter((key) => key !== record.paymentId));
      setExcludeList([...excludeList, record.paymentId]);
    }
  };

  //console.log('selectedRowKeys', selectedRowKeys, 'ex', excludeList);

  const onResetFilter = () => {
    setSelectedRowKeys([]);
    pagesAlreadyOpened.current = [];
    setExcludeList([]);
  };

  const rowSelection: TableRowSelection<PaymentListEntry> = {
    selectedRowKeys,
    onSelect,
    onChange: onChangeSelection,
  };

  return {
    excludeList,
    rowSelection,
    onResetFilter,
  };
};

export default useSelectionForPayment;
