import React, { FC, ReactNode } from 'react';
import { Form } from 'formik-antd';
import { Space } from 'antd';
import styled from 'styled-components';
import FiltersWithSelector from './FiltersWithSelector';
import { Spacer } from '../../Grid';

type Props = {
  allFilterTitles: Record<string, string>;
  onSelectedFilterTitleList: (filters: string[]) => void;
  selectedFilterList: string[];
  filters: React.ReactNode;

  /** For Action Buttons like 'Mahnungen erstellen', 'Ausgezifferte OPs anzeigen' */
  actionButtons?: ReactNode;
  /** For action or bulk actions that should be done on the filtered list, e.g. 'Auf Liste anwenden' in Mahnungen listing */
  filterActions?: ReactNode;
};

const FiltersWithSelectorWrapper: FC<Props> = ({
  allFilterTitles,
  onSelectedFilterTitleList,
  selectedFilterList,
  filters,
  filterActions,
  actionButtons,
}) => {
  return (
    <Form>
      <FilterWrapper>
        <FiltersWithSelector
          selectedFilterList={selectedFilterList}
          onSelectedFilterTitleList={onSelectedFilterTitleList}
          allFilterTitles={allFilterTitles}
        />
        <Space style={{ flexWrap: 'wrap' }}>{filters}</Space>
        <Space direction="vertical" align="end">
          <Space>{actionButtons}</Space>
          <Space>{filterActions}</Space>
        </Space>
      </FilterWrapper>
      <Spacer />
    </Form>
  );
};

export const FilterWrapper = styled('div')`
  display: flex;
  align-items: baseline;

  & > :first-child {
    margin-right: 5px;
  }

  & > :nth-child(2) {
    flex: 1;
  }

  & > :last-child {
    margin-left: 5px;
  }

  .ant-input,
  .ant-select,
  .ant-input-number-input {
    min-width: 160px;
    min-height: 20px;
  }
`;

export default FiltersWithSelectorWrapper;
