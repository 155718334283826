import React from 'react';
import UstRegelsetTable from '../../../../features/Ust/UstRegelset/Listing/UstRegelsetTable';

const SysSettingsUstRegelwerkPage = () => {
  return (
    <div style={{ paddingTop: '16px' }}>
      <UstRegelsetTable />
    </div>
  );
};

export default SysSettingsUstRegelwerkPage;
