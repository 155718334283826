import React, { FC } from 'react';
import { Select, SelectProps } from 'antd';
import { useVertragInfoFeldTypeListQuery } from '../gql/VertragInfoFeldTypeQueries.types';

const VertragInfoFeldTypeSelect: FC<SelectProps> = ({ ...restProps }) => {
  const { data, loading } = useVertragInfoFeldTypeListQuery();

  const vertragInfoFeldTypeList = data?.getVertragInfoFeldTypeList.data ?? [];

  return (
    <Select placeholder="Typ auswählen" loading={loading} allowClear size="small" style={{ minWidth: '160px' }} {...restProps}>
      {vertragInfoFeldTypeList.map((infoFeldType) => (
        <Select.Option value={infoFeldType.value} key={infoFeldType.value}>
          {infoFeldType.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default VertragInfoFeldTypeSelect;
