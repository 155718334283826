import { FC } from 'react';
import { VertragVposIndexationAgreementCategory } from '../../../../types';
import TypographyTextForTooltip from '../../../../components/Text/TypographyTextForTooltip';

type Props = {
  indexationAgreement: VertragVposIndexationAgreementCategory;
};

const CategoryTooltipTitle: FC<Props> = ({ indexationAgreement }) => {
  return <TypographyTextForTooltip text={`Berechnen auf Basis m2: ${indexationAgreement.basedOnUsableArea ? 'ja' : 'nein'}`} />;
};

export default CategoryTooltipTitle;
