import { Select, SelectProps } from 'formik-antd';
import { FC } from 'react';
import { IndexType } from '../../../types';

const IndexTypeSelect: FC<SelectProps> = ({ name, ...restProps }) => {
  const options = [
    { text: 'Index', value: IndexType.Index },
    { text: 'Kategorie', value: IndexType.Category },
    { text: 'Richtwert', value: IndexType.ReferenceValue },
  ];

  return (
    <Select size="small" name={name} id={name} placeholder="Option auswählen" {...restProps}>
      {options.map((indexType) => (
        <Select.Option key={indexType.value} value={indexType.value}>
          {indexType.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default IndexTypeSelect;
