import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { RepFondsAbrechnungDetails } from '../../../../topAbrechnung-types';
import TARepFondsAbrechnungListTitleText from '../../TARepFondsAbrechnungListTitleText';
import TARepFondsAbrAusgabenKontoEinnahme from './TARepFondsAbrAusgabenKontoEinnahme';
import TARepFondsAbrSumme from '../TARepFondsAbrSumme';

const TARepFondsAbrEinnahmenBlock: FC<{ details: RepFondsAbrechnungDetails }> = ({ details }) => (
  <View>
    <TARepFondsAbrechnungListTitleText listTitleText={details.einnahmenListText} />

    <View style={{ marginBottom: '3mm' }} />

    {details.einnahmenList.map((einnahme, index) => (
      <TARepFondsAbrAusgabenKontoEinnahme einnahme={einnahme} key={index} />
    ))}

    <TARepFondsAbrSumme summe={details.einnahmenSumme} />
  </View>
);

export default TARepFondsAbrEinnahmenBlock;
