import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { BankDetailsFieldsFragmentDoc, FirmendatenBankDetailsFieldsFragmentDoc } from './BankDetailsFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BankDetailsListQueryVariables = Types.Exact<{
  rechtstraegerId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type BankDetailsListQuery = {
  getBankDetailsList: {
    data: Array<{
      accountHolder: string;
      accountLimit?: number | null;
      assignedHeVertragZahlungsregelMap: any;
      assignedObjektMap: any;
      bankDetailsId: string;
      bankname: string;
      bic: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      ebicsCreditTransferUserList: Array<string>;
      ebicsDirectDebitUserList: Array<string>;
      fibuKontoId?: string | null;
      fibuKontonummer?: string | null;
      iban: string;
      updatedByMitarbeiterId?: string | null;
      fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
      fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
      sepaMandat?: {
        fileId?: string | null;
        fileName?: string | null;
        sepaMandatId: string;
        sepaMandatReference: string;
        signatureDate?: string | null;
        firmendaten?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firmendatenId: string;
          kundenSystemId: string;
          kurzBezeichnung: string;
          name1: string;
          name2?: string | null;
          name3?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechnungsAussteller?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
        warningList: Array<{ message: string; type: string }>;
      } | null;
      status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BankAccountChangeHistoryListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  bankDetailsId: Types.Scalars['ID']['input'];
}>;

export type BankAccountChangeHistoryListQuery = {
  getBankAccountChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type NextSepaMandatReferenceQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type NextSepaMandatReferenceQuery = {
  getNextSepaMandatReference: {
    data?: string | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FirmendatenBankDetailsQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  bankDetailsId: Types.Scalars['ID']['input'];
}>;

export type FirmendatenBankDetailsQuery = {
  getFirmendatenBankDetails: {
    data: {
      accountHolder: string;
      accountLimit?: number | null;
      allowObjektZuweisung?: boolean | null;
      assignedHeVertragZahlungsregelMap: any;
      assignedObjektMap: any;
      bankDetailsId: string;
      bankname: string;
      bic: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      ebicsCreditTransferUserList: Array<string>;
      ebicsDirectDebitUserList: Array<string>;
      fibuKontoId?: string | null;
      fibuKontonummer?: string | null;
      iban: string;
      updatedByMitarbeiterId?: string | null;
      fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
      fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
      sepaMandat?: {
        fileId?: string | null;
        fileName?: string | null;
        sepaMandatId: string;
        sepaMandatReference: string;
        signatureDate?: string | null;
        firmendaten?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firmendatenId: string;
          kundenSystemId: string;
          kurzBezeichnung: string;
          name1: string;
          name2?: string | null;
          name3?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechnungsAussteller?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
        warningList: Array<{ message: string; type: string }>;
      } | null;
      status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FirmendatenBankDetailsListQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type FirmendatenBankDetailsListQuery = {
  getFirmendatenBankDetailsList: {
    data: Array<{
      accountHolder: string;
      accountLimit?: number | null;
      allowObjektZuweisung?: boolean | null;
      assignedHeVertragZahlungsregelMap: any;
      assignedObjektMap: any;
      bankDetailsId: string;
      bankname: string;
      bic: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      ebicsCreditTransferUserList: Array<string>;
      ebicsDirectDebitUserList: Array<string>;
      fibuKontoId?: string | null;
      fibuKontonummer?: string | null;
      iban: string;
      updatedByMitarbeiterId?: string | null;
      fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
      fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
      sepaMandat?: {
        fileId?: string | null;
        fileName?: string | null;
        sepaMandatId: string;
        sepaMandatReference: string;
        signatureDate?: string | null;
        firmendaten?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firmendatenId: string;
          kundenSystemId: string;
          kurzBezeichnung: string;
          name1: string;
          name2?: string | null;
          name3?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechnungsAussteller?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
        warningList: Array<{ message: string; type: string }>;
      } | null;
      status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type RechtstraegerBankDetailsQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['String']['input'];
  bankDetailsId: Types.Scalars['ID']['input'];
}>;

export type RechtstraegerBankDetailsQuery = {
  getRechtstraegerBankDetails: {
    data: {
      accountHolder: string;
      accountLimit?: number | null;
      assignedHeVertragZahlungsregelMap: any;
      assignedObjektMap: any;
      bankDetailsId: string;
      bankname: string;
      bic: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      ebicsCreditTransferUserList: Array<string>;
      ebicsDirectDebitUserList: Array<string>;
      fibuKontoId?: string | null;
      fibuKontonummer?: string | null;
      iban: string;
      updatedByMitarbeiterId?: string | null;
      fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
      fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
      sepaMandat?: {
        fileId?: string | null;
        fileName?: string | null;
        sepaMandatId: string;
        sepaMandatReference: string;
        signatureDate?: string | null;
        firmendaten?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firmendatenId: string;
          kundenSystemId: string;
          kurzBezeichnung: string;
          name1: string;
          name2?: string | null;
          name3?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechnungsAussteller?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
        warningList: Array<{ message: string; type: string }>;
      } | null;
      status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type RechtstraegerBankDetailsListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type RechtstraegerBankDetailsListQuery = {
  getRechtstraegerBankDetailsList: {
    data: Array<{
      accountHolder: string;
      accountLimit?: number | null;
      assignedHeVertragZahlungsregelMap: any;
      assignedObjektMap: any;
      bankDetailsId: string;
      bankname: string;
      bic: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      ebicsCreditTransferUserList: Array<string>;
      ebicsDirectDebitUserList: Array<string>;
      fibuKontoId?: string | null;
      fibuKontonummer?: string | null;
      iban: string;
      updatedByMitarbeiterId?: string | null;
      fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
      fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
      sepaMandat?: {
        fileId?: string | null;
        fileName?: string | null;
        sepaMandatId: string;
        sepaMandatReference: string;
        signatureDate?: string | null;
        firmendaten?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firmendatenId: string;
          kundenSystemId: string;
          kurzBezeichnung: string;
          name1: string;
          name2?: string | null;
          name3?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechnungsAussteller?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
        warningList: Array<{ message: string; type: string }>;
      } | null;
      status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type RechtstraegerBankDetailsChangeHistoryListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  bankDetailsId: Types.Scalars['ID']['input'];
}>;

export type RechtstraegerBankDetailsChangeHistoryListQuery = {
  getRechtstraegerBankDetailsChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type FirmendatenBankDetailsChangeHistoryListQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  bankDetailsId: Types.Scalars['ID']['input'];
}>;

export type FirmendatenBankDetailsChangeHistoryListQuery = {
  getFirmendatenBankDetailsChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const BankDetailsListDocument = gql`
  query BankDetailsList($rechtstraegerId: ID, $includeArchiviert: Boolean) {
    getBankDetailsList(rechtstraegerId: $rechtstraegerId, includeArchiviert: $includeArchiviert) {
      data {
        ...BankDetailsFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BankDetailsFieldsFragmentDoc}
`;
export function useBankDetailsListQuery(baseOptions?: Apollo.QueryHookOptions<BankDetailsListQuery, BankDetailsListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BankDetailsListQuery, BankDetailsListQueryVariables>(BankDetailsListDocument, options);
}
export function useBankDetailsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BankDetailsListQuery, BankDetailsListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BankDetailsListQuery, BankDetailsListQueryVariables>(BankDetailsListDocument, options);
}
export function useBankDetailsListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BankDetailsListQuery, BankDetailsListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BankDetailsListQuery, BankDetailsListQueryVariables>(BankDetailsListDocument, options);
}
export type BankDetailsListQueryHookResult = ReturnType<typeof useBankDetailsListQuery>;
export type BankDetailsListLazyQueryHookResult = ReturnType<typeof useBankDetailsListLazyQuery>;
export type BankDetailsListSuspenseQueryHookResult = ReturnType<typeof useBankDetailsListSuspenseQuery>;
export type BankDetailsListQueryResult = Apollo.QueryResult<BankDetailsListQuery, BankDetailsListQueryVariables>;
export const BankAccountChangeHistoryListDocument = gql`
  query BankAccountChangeHistoryList($rechtstraegerId: ID!, $bankDetailsId: ID!) {
    getBankAccountChangeHistoryList(rechtstraegerId: $rechtstraegerId, bankDetailsId: $bankDetailsId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useBankAccountChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<BankAccountChangeHistoryListQuery, BankAccountChangeHistoryListQueryVariables> &
    ({ variables: BankAccountChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BankAccountChangeHistoryListQuery, BankAccountChangeHistoryListQueryVariables>(
    BankAccountChangeHistoryListDocument,
    options
  );
}
export function useBankAccountChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BankAccountChangeHistoryListQuery, BankAccountChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BankAccountChangeHistoryListQuery, BankAccountChangeHistoryListQueryVariables>(
    BankAccountChangeHistoryListDocument,
    options
  );
}
export function useBankAccountChangeHistoryListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BankAccountChangeHistoryListQuery, BankAccountChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BankAccountChangeHistoryListQuery, BankAccountChangeHistoryListQueryVariables>(
    BankAccountChangeHistoryListDocument,
    options
  );
}
export type BankAccountChangeHistoryListQueryHookResult = ReturnType<typeof useBankAccountChangeHistoryListQuery>;
export type BankAccountChangeHistoryListLazyQueryHookResult = ReturnType<typeof useBankAccountChangeHistoryListLazyQuery>;
export type BankAccountChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useBankAccountChangeHistoryListSuspenseQuery>;
export type BankAccountChangeHistoryListQueryResult = Apollo.QueryResult<
  BankAccountChangeHistoryListQuery,
  BankAccountChangeHistoryListQueryVariables
>;
export const NextSepaMandatReferenceDocument = gql`
  query NextSepaMandatReference($rechtstraegerId: ID!) {
    getNextSepaMandatReference(rechtstraegerId: $rechtstraegerId) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useNextSepaMandatReferenceQuery(
  baseOptions: Apollo.QueryHookOptions<NextSepaMandatReferenceQuery, NextSepaMandatReferenceQueryVariables> &
    ({ variables: NextSepaMandatReferenceQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NextSepaMandatReferenceQuery, NextSepaMandatReferenceQueryVariables>(NextSepaMandatReferenceDocument, options);
}
export function useNextSepaMandatReferenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NextSepaMandatReferenceQuery, NextSepaMandatReferenceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NextSepaMandatReferenceQuery, NextSepaMandatReferenceQueryVariables>(NextSepaMandatReferenceDocument, options);
}
export function useNextSepaMandatReferenceSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<NextSepaMandatReferenceQuery, NextSepaMandatReferenceQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NextSepaMandatReferenceQuery, NextSepaMandatReferenceQueryVariables>(NextSepaMandatReferenceDocument, options);
}
export type NextSepaMandatReferenceQueryHookResult = ReturnType<typeof useNextSepaMandatReferenceQuery>;
export type NextSepaMandatReferenceLazyQueryHookResult = ReturnType<typeof useNextSepaMandatReferenceLazyQuery>;
export type NextSepaMandatReferenceSuspenseQueryHookResult = ReturnType<typeof useNextSepaMandatReferenceSuspenseQuery>;
export type NextSepaMandatReferenceQueryResult = Apollo.QueryResult<NextSepaMandatReferenceQuery, NextSepaMandatReferenceQueryVariables>;
export const FirmendatenBankDetailsDocument = gql`
  query FirmendatenBankDetails($firmendatenId: String!, $bankDetailsId: ID!) {
    getFirmendatenBankDetails(firmendatenId: $firmendatenId, bankDetailsId: $bankDetailsId) {
      data {
        ...FirmendatenBankDetailsFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${FirmendatenBankDetailsFieldsFragmentDoc}
`;
export function useFirmendatenBankDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<FirmendatenBankDetailsQuery, FirmendatenBankDetailsQueryVariables> &
    ({ variables: FirmendatenBankDetailsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenBankDetailsQuery, FirmendatenBankDetailsQueryVariables>(FirmendatenBankDetailsDocument, options);
}
export function useFirmendatenBankDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenBankDetailsQuery, FirmendatenBankDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenBankDetailsQuery, FirmendatenBankDetailsQueryVariables>(FirmendatenBankDetailsDocument, options);
}
export function useFirmendatenBankDetailsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FirmendatenBankDetailsQuery, FirmendatenBankDetailsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenBankDetailsQuery, FirmendatenBankDetailsQueryVariables>(FirmendatenBankDetailsDocument, options);
}
export type FirmendatenBankDetailsQueryHookResult = ReturnType<typeof useFirmendatenBankDetailsQuery>;
export type FirmendatenBankDetailsLazyQueryHookResult = ReturnType<typeof useFirmendatenBankDetailsLazyQuery>;
export type FirmendatenBankDetailsSuspenseQueryHookResult = ReturnType<typeof useFirmendatenBankDetailsSuspenseQuery>;
export type FirmendatenBankDetailsQueryResult = Apollo.QueryResult<FirmendatenBankDetailsQuery, FirmendatenBankDetailsQueryVariables>;
export const FirmendatenBankDetailsListDocument = gql`
  query FirmendatenBankDetailsList($firmendatenId: ID!, $includeArchiviert: Boolean) {
    getFirmendatenBankDetailsList(firmendatenId: $firmendatenId, includeArchiviert: $includeArchiviert) {
      data {
        ...FirmendatenBankDetailsFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${FirmendatenBankDetailsFieldsFragmentDoc}
`;
export function useFirmendatenBankDetailsListQuery(
  baseOptions: Apollo.QueryHookOptions<FirmendatenBankDetailsListQuery, FirmendatenBankDetailsListQueryVariables> &
    ({ variables: FirmendatenBankDetailsListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenBankDetailsListQuery, FirmendatenBankDetailsListQueryVariables>(FirmendatenBankDetailsListDocument, options);
}
export function useFirmendatenBankDetailsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenBankDetailsListQuery, FirmendatenBankDetailsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenBankDetailsListQuery, FirmendatenBankDetailsListQueryVariables>(FirmendatenBankDetailsListDocument, options);
}
export function useFirmendatenBankDetailsListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FirmendatenBankDetailsListQuery, FirmendatenBankDetailsListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenBankDetailsListQuery, FirmendatenBankDetailsListQueryVariables>(
    FirmendatenBankDetailsListDocument,
    options
  );
}
export type FirmendatenBankDetailsListQueryHookResult = ReturnType<typeof useFirmendatenBankDetailsListQuery>;
export type FirmendatenBankDetailsListLazyQueryHookResult = ReturnType<typeof useFirmendatenBankDetailsListLazyQuery>;
export type FirmendatenBankDetailsListSuspenseQueryHookResult = ReturnType<typeof useFirmendatenBankDetailsListSuspenseQuery>;
export type FirmendatenBankDetailsListQueryResult = Apollo.QueryResult<FirmendatenBankDetailsListQuery, FirmendatenBankDetailsListQueryVariables>;
export const RechtstraegerBankDetailsDocument = gql`
  query RechtstraegerBankDetails($rechtstraegerId: String!, $bankDetailsId: ID!) {
    getRechtstraegerBankDetails(rechtstraegerId: $rechtstraegerId, bankDetailsId: $bankDetailsId) {
      data {
        ...BankDetailsFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BankDetailsFieldsFragmentDoc}
`;
export function useRechtstraegerBankDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<RechtstraegerBankDetailsQuery, RechtstraegerBankDetailsQueryVariables> &
    ({ variables: RechtstraegerBankDetailsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechtstraegerBankDetailsQuery, RechtstraegerBankDetailsQueryVariables>(RechtstraegerBankDetailsDocument, options);
}
export function useRechtstraegerBankDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RechtstraegerBankDetailsQuery, RechtstraegerBankDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechtstraegerBankDetailsQuery, RechtstraegerBankDetailsQueryVariables>(RechtstraegerBankDetailsDocument, options);
}
export function useRechtstraegerBankDetailsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RechtstraegerBankDetailsQuery, RechtstraegerBankDetailsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechtstraegerBankDetailsQuery, RechtstraegerBankDetailsQueryVariables>(RechtstraegerBankDetailsDocument, options);
}
export type RechtstraegerBankDetailsQueryHookResult = ReturnType<typeof useRechtstraegerBankDetailsQuery>;
export type RechtstraegerBankDetailsLazyQueryHookResult = ReturnType<typeof useRechtstraegerBankDetailsLazyQuery>;
export type RechtstraegerBankDetailsSuspenseQueryHookResult = ReturnType<typeof useRechtstraegerBankDetailsSuspenseQuery>;
export type RechtstraegerBankDetailsQueryResult = Apollo.QueryResult<RechtstraegerBankDetailsQuery, RechtstraegerBankDetailsQueryVariables>;
export const RechtstraegerBankDetailsListDocument = gql`
  query RechtstraegerBankDetailsList($rechtstraegerId: ID!, $includeArchiviert: Boolean) {
    getRechtstraegerBankDetailsList(rechtstraegerId: $rechtstraegerId, includeArchiviert: $includeArchiviert) {
      data {
        ...BankDetailsFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BankDetailsFieldsFragmentDoc}
`;
export function useRechtstraegerBankDetailsListQuery(
  baseOptions: Apollo.QueryHookOptions<RechtstraegerBankDetailsListQuery, RechtstraegerBankDetailsListQueryVariables> &
    ({ variables: RechtstraegerBankDetailsListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechtstraegerBankDetailsListQuery, RechtstraegerBankDetailsListQueryVariables>(
    RechtstraegerBankDetailsListDocument,
    options
  );
}
export function useRechtstraegerBankDetailsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RechtstraegerBankDetailsListQuery, RechtstraegerBankDetailsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechtstraegerBankDetailsListQuery, RechtstraegerBankDetailsListQueryVariables>(
    RechtstraegerBankDetailsListDocument,
    options
  );
}
export function useRechtstraegerBankDetailsListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RechtstraegerBankDetailsListQuery, RechtstraegerBankDetailsListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechtstraegerBankDetailsListQuery, RechtstraegerBankDetailsListQueryVariables>(
    RechtstraegerBankDetailsListDocument,
    options
  );
}
export type RechtstraegerBankDetailsListQueryHookResult = ReturnType<typeof useRechtstraegerBankDetailsListQuery>;
export type RechtstraegerBankDetailsListLazyQueryHookResult = ReturnType<typeof useRechtstraegerBankDetailsListLazyQuery>;
export type RechtstraegerBankDetailsListSuspenseQueryHookResult = ReturnType<typeof useRechtstraegerBankDetailsListSuspenseQuery>;
export type RechtstraegerBankDetailsListQueryResult = Apollo.QueryResult<
  RechtstraegerBankDetailsListQuery,
  RechtstraegerBankDetailsListQueryVariables
>;
export const RechtstraegerBankDetailsChangeHistoryListDocument = gql`
  query RechtstraegerBankDetailsChangeHistoryList($rechtstraegerId: ID!, $bankDetailsId: ID!) {
    getRechtstraegerBankDetailsChangeHistoryList(rechtstraegerId: $rechtstraegerId, bankDetailsId: $bankDetailsId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useRechtstraegerBankDetailsChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<RechtstraegerBankDetailsChangeHistoryListQuery, RechtstraegerBankDetailsChangeHistoryListQueryVariables> &
    ({ variables: RechtstraegerBankDetailsChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechtstraegerBankDetailsChangeHistoryListQuery, RechtstraegerBankDetailsChangeHistoryListQueryVariables>(
    RechtstraegerBankDetailsChangeHistoryListDocument,
    options
  );
}
export function useRechtstraegerBankDetailsChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RechtstraegerBankDetailsChangeHistoryListQuery, RechtstraegerBankDetailsChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechtstraegerBankDetailsChangeHistoryListQuery, RechtstraegerBankDetailsChangeHistoryListQueryVariables>(
    RechtstraegerBankDetailsChangeHistoryListDocument,
    options
  );
}
export function useRechtstraegerBankDetailsChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<RechtstraegerBankDetailsChangeHistoryListQuery, RechtstraegerBankDetailsChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechtstraegerBankDetailsChangeHistoryListQuery, RechtstraegerBankDetailsChangeHistoryListQueryVariables>(
    RechtstraegerBankDetailsChangeHistoryListDocument,
    options
  );
}
export type RechtstraegerBankDetailsChangeHistoryListQueryHookResult = ReturnType<typeof useRechtstraegerBankDetailsChangeHistoryListQuery>;
export type RechtstraegerBankDetailsChangeHistoryListLazyQueryHookResult = ReturnType<typeof useRechtstraegerBankDetailsChangeHistoryListLazyQuery>;
export type RechtstraegerBankDetailsChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useRechtstraegerBankDetailsChangeHistoryListSuspenseQuery
>;
export type RechtstraegerBankDetailsChangeHistoryListQueryResult = Apollo.QueryResult<
  RechtstraegerBankDetailsChangeHistoryListQuery,
  RechtstraegerBankDetailsChangeHistoryListQueryVariables
>;
export const FirmendatenBankDetailsChangeHistoryListDocument = gql`
  query FirmendatenBankDetailsChangeHistoryList($firmendatenId: String!, $bankDetailsId: ID!) {
    getFirmendatenBankDetailsChangeHistoryList(firmendatenId: $firmendatenId, bankDetailsId: $bankDetailsId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useFirmendatenBankDetailsChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<FirmendatenBankDetailsChangeHistoryListQuery, FirmendatenBankDetailsChangeHistoryListQueryVariables> &
    ({ variables: FirmendatenBankDetailsChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenBankDetailsChangeHistoryListQuery, FirmendatenBankDetailsChangeHistoryListQueryVariables>(
    FirmendatenBankDetailsChangeHistoryListDocument,
    options
  );
}
export function useFirmendatenBankDetailsChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenBankDetailsChangeHistoryListQuery, FirmendatenBankDetailsChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenBankDetailsChangeHistoryListQuery, FirmendatenBankDetailsChangeHistoryListQueryVariables>(
    FirmendatenBankDetailsChangeHistoryListDocument,
    options
  );
}
export function useFirmendatenBankDetailsChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FirmendatenBankDetailsChangeHistoryListQuery, FirmendatenBankDetailsChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenBankDetailsChangeHistoryListQuery, FirmendatenBankDetailsChangeHistoryListQueryVariables>(
    FirmendatenBankDetailsChangeHistoryListDocument,
    options
  );
}
export type FirmendatenBankDetailsChangeHistoryListQueryHookResult = ReturnType<typeof useFirmendatenBankDetailsChangeHistoryListQuery>;
export type FirmendatenBankDetailsChangeHistoryListLazyQueryHookResult = ReturnType<typeof useFirmendatenBankDetailsChangeHistoryListLazyQuery>;
export type FirmendatenBankDetailsChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useFirmendatenBankDetailsChangeHistoryListSuspenseQuery
>;
export type FirmendatenBankDetailsChangeHistoryListQueryResult = Apollo.QueryResult<
  FirmendatenBankDetailsChangeHistoryListQuery,
  FirmendatenBankDetailsChangeHistoryListQueryVariables
>;
