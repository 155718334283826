import { useAuth } from './authContext';
import { isAdmin, isAdminOrHasSystemAdminFm, isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../security/permissionChecks';

const usePermission = () => {
  const { mitarbeiter, user, activeForFirmendatenId } = useAuth();
  const role = user?.role;
  const funktionsModulList = mitarbeiter?.funktionsModulList;

  return {
    firmendatenId: activeForFirmendatenId,
    isAdmin: isAdmin({ role, funktionsModulList }),
    isFirmendatenSelected: !!activeForFirmendatenId,
    isAdminOrHasSystemAdminFm: isAdminOrHasSystemAdminFm({ activeForFirmendatenId, funktionsModulList, role }),
    isAdminWithSelectedFirmendatenOrHasSystemAdminFm: isAdminWithSelectedFirmendatenOrHasSystemAdminFm({
      activeForFirmendatenId,
      funktionsModulList,
      role,
    }),
  };
};

export default usePermission;
