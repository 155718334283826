import React, { FC } from 'react';
import { Typography } from 'antd';
import { FormikProps } from 'formik';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import { StyledSpaceFullWidthInFilter } from '../../../../components/Space/styled/StyledSpaceFullWidthInFilter';
import FormattedDecimalFormInput from '../../../../components/Input-antd/FormattedDecimalFormInput';
import { PaymentListingFiltersFormValues } from './paymentListingFiltersFormMapper';
import { useToggle } from '../../../../hooks/useToggle';
import { showConfirmChangeFilter } from './PaymentListingFilters';

type Props = {
  /** form item name and label */
  name: string;
  fromName: string;
  toName: string;
  formikProps: FormikProps<PaymentListingFiltersFormValues>;
  excludeList: string[];
  onResetFilter: () => void;
};

const PaymentFromToFormInputNumber: FC<Props> = ({ name, fromName, toName, excludeList, formikProps, onResetFilter }) => {
  const [isModalOpen, toggleModalOpen] = useToggle();

  const onCancel = (formikName: string) => {
    // @ts-ignore
    formikProps.setFieldValue(formikName, formikProps.values[formikName]);
    toggleModalOpen();
  };

  const onOk = () => {
    onResetFilter();
    formikProps.submitForm();
    toggleModalOpen();
  };

  const handleOnChange = (formikName: string) => {
    if (excludeList.length > 0) {
      if (!isModalOpen) {
        showConfirmChangeFilter(onOk, () => onCancel(formikName));
        toggleModalOpen();
      }
    } else {
      onResetFilter();
      formikProps.submitForm();
    }
  };

  return (
    <>
      <FormItemWithoutColon name={name} label={name}>
        <StyledSpaceFullWidthInFilter size="small">
          <FormattedDecimalFormInput
            name={fromName}
            style={{ width: '100%' }}
            onChange={() => {
              handleOnChange(fromName);
            }}
            min={0}
            placeholder={`${name} von`}
          />
          <Typography.Text>-</Typography.Text>
          <FormattedDecimalFormInput
            name={toName}
            style={{ width: '100%' }}
            onChange={() => {
              handleOnChange(toName);
            }}
            min={0}
            placeholder={`${name} bis`}
          />
        </StyledSpaceFullWidthInFilter>
      </FormItemWithoutColon>
    </>
  );
};

export default PaymentFromToFormInputNumber;
