import React from 'react';
import { Layout } from 'antd';

const { Header, Footer, Content } = Layout;

type LayoutProps = {
  children?: React.ReactNode;
};

const PageLayout = (props: LayoutProps) => (
  <Layout style={{ padding: '16px 16px', borderRadius: '4px 4px 0 0' }}>
    <Content style={{ padding: '16px', background: '#fff', borderRadius: '4px 4px 0 0' }}>{props.children}</Content>
  </Layout>
);

const ErrorPageLayout = (props: LayoutProps) => (
  <Layout style={{ padding: '0 24px 24px', borderRadius: '4px 4px 0 0' }}>
    <Header style={{ background: 'transparent' }} />
    <Content
      style={{
        background: 'transparent',
        padding: 24,
        margin: 0,
        minHeight: 280,
      }}
    >
      {props.children}
    </Content>
    <Footer style={{ background: 'transparent' }} />
  </Layout>
);

const LoginPageLayout = (props: LayoutProps) => (
  <Layout>
    <Content
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {props.children}
    </Content>
  </Layout>
);

export { PageLayout, ErrorPageLayout, LoginPageLayout };
