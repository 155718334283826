import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { ContactFieldsFragmentDoc, ContactPersonFieldsFragmentDoc } from '../../../features/Contact/gql/ContactFragments.types';
import { FirmendatenBaseFieldsFragmentDoc } from '../../../features/Firmendaten/gql/FirmendatenFragments.types';
import { MitarbeiterFragmentDoc } from '../../../features/Mitarbeiter/gql/MitarbeiterFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MeQuery = {
  getMe: {
    data: {
      createdTs: string;
      email?: string | null;
      emailUnverified?: string | null;
      firstname?: string | null;
      lastname?: string | null;
      role: Types.UserRole;
      username: string;
      contactList?: Array<{
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      status: { description?: string | null; text: string; value: Types.UserStatus };
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type LoginMitarbeiterListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type LoginMitarbeiterListQuery = {
  getOwnMitarbeiterList: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      mitarbeiterId: string;
      firmendatenBaseData: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firmendatenId: string;
        kundenSystemId: string;
        kurzBezeichnung: string;
        name1: string;
        name2?: string | null;
        name3?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type AuthMitarbeiterMeQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
}>;

export type AuthMitarbeiterMeQuery = {
  getAuthMitarbeiterMe: {
    data: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      fileStorageInitialized: boolean;
      firmendatenId: string;
      firstname: string;
      funktionsModulList: Array<string>;
      lastname: string;
      mitarbeiterId: string;
      title?: string | null;
      titleTrailing?: string | null;
      username: string;
      contactPerson?: {
        contactPersonId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firstname: string;
        lastname: string;
        tagList: Array<string>;
        title?: string | null;
        titleTrailing?: string | null;
        contactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        rechtstraeger?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rolleList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        name: string;
        rolleId: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
      status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
      user?: {
        email?: string | null;
        emailUnverified?: string | null;
        firstname?: string | null;
        lastname?: string | null;
        role: string;
        username: string;
        status: { text: string; value: string };
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
  };
};

export const MeDocument = gql`
  query Me {
    getMe {
      data {
        contactList {
          ...ContactFields
        }
        createdTs
        email
        emailUnverified
        firstname
        lastname
        role
        status {
          description
          text
          value
        }
        username
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${ContactFieldsFragmentDoc}
`;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const LoginMitarbeiterListDocument = gql`
  query LoginMitarbeiterList {
    getOwnMitarbeiterList {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
        firmendatenBaseData {
          ...FirmendatenBaseFields
        }
        mitarbeiterId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${FirmendatenBaseFieldsFragmentDoc}
`;
export function useLoginMitarbeiterListQuery(baseOptions?: Apollo.QueryHookOptions<LoginMitarbeiterListQuery, LoginMitarbeiterListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoginMitarbeiterListQuery, LoginMitarbeiterListQueryVariables>(LoginMitarbeiterListDocument, options);
}
export function useLoginMitarbeiterListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LoginMitarbeiterListQuery, LoginMitarbeiterListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoginMitarbeiterListQuery, LoginMitarbeiterListQueryVariables>(LoginMitarbeiterListDocument, options);
}
export function useLoginMitarbeiterListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LoginMitarbeiterListQuery, LoginMitarbeiterListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LoginMitarbeiterListQuery, LoginMitarbeiterListQueryVariables>(LoginMitarbeiterListDocument, options);
}
export type LoginMitarbeiterListQueryHookResult = ReturnType<typeof useLoginMitarbeiterListQuery>;
export type LoginMitarbeiterListLazyQueryHookResult = ReturnType<typeof useLoginMitarbeiterListLazyQuery>;
export type LoginMitarbeiterListSuspenseQueryHookResult = ReturnType<typeof useLoginMitarbeiterListSuspenseQuery>;
export type LoginMitarbeiterListQueryResult = Apollo.QueryResult<LoginMitarbeiterListQuery, LoginMitarbeiterListQueryVariables>;
export const AuthMitarbeiterMeDocument = gql`
  query AuthMitarbeiterMe($firmendatenId: ID!) {
    getAuthMitarbeiterMe(firmendatenId: $firmendatenId) {
      data {
        ...Mitarbeiter
      }
    }
  }
  ${MitarbeiterFragmentDoc}
`;
export function useAuthMitarbeiterMeQuery(
  baseOptions: Apollo.QueryHookOptions<AuthMitarbeiterMeQuery, AuthMitarbeiterMeQueryVariables> &
    ({ variables: AuthMitarbeiterMeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuthMitarbeiterMeQuery, AuthMitarbeiterMeQueryVariables>(AuthMitarbeiterMeDocument, options);
}
export function useAuthMitarbeiterMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthMitarbeiterMeQuery, AuthMitarbeiterMeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuthMitarbeiterMeQuery, AuthMitarbeiterMeQueryVariables>(AuthMitarbeiterMeDocument, options);
}
export function useAuthMitarbeiterMeSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AuthMitarbeiterMeQuery, AuthMitarbeiterMeQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuthMitarbeiterMeQuery, AuthMitarbeiterMeQueryVariables>(AuthMitarbeiterMeDocument, options);
}
export type AuthMitarbeiterMeQueryHookResult = ReturnType<typeof useAuthMitarbeiterMeQuery>;
export type AuthMitarbeiterMeLazyQueryHookResult = ReturnType<typeof useAuthMitarbeiterMeLazyQuery>;
export type AuthMitarbeiterMeSuspenseQueryHookResult = ReturnType<typeof useAuthMitarbeiterMeSuspenseQuery>;
export type AuthMitarbeiterMeQueryResult = Apollo.QueryResult<AuthMitarbeiterMeQuery, AuthMitarbeiterMeQueryVariables>;
