import { generatePath } from 'react-router-dom';
import { mapToQueryString, QueryParams } from '../../../../hooks/useStringQueryParams';
import { FiltersFormValues } from './filtersFormMapper';
import { PaginationQueryParamsProps } from '../../../../hooks/usePaginationQueryParams';
import { URI_INCOMING_INVOICE_PAGE } from '../../incomingInvoiceUriPaths';

enum QueryParamKey {
  BILLING_TYPE = 'billingType',
  BRUTTO_FROM = 'bruttoFrom',
  BRUTTO_TO = 'bruttoTo',
  BUCHUNGSDATUM_FROM = 'buchungsdatumFrom',
  BUCHUNGSDATUM_TO = 'buchungsdatumTo',
  CREATED_BY_MITARBEITER_ID_LIST = 'createdByMitarbeiterIdList',
  CREATE_TS_FROM = 'createTsFrom',
  CREATE_TS_TO = 'createTsTo',
  DUE_DATE_FROM = 'dueDateFrom',
  DUE_DATE_TO = 'dueDateTo',
  INCOMING_INVOICE_FILTER_STATUS = 'incomingInvoiceFilterStatus',
  INVOICE_DATE_FROM = 'invoiceDateFrom',
  INVOICE_DATE_TO = 'invoiceDateTo',
  INVOICE_NUMBER = 'invoiceNumber',
  ISSUER_BANK_DETAILS_IBAN = 'issuerBankDetailsIban',
  ISSUER_RECHTSTRAEGER_ID = 'issuerRechtstraegerId',
  KONTO_ID = 'kontoId',
  KUNDEN_NUMMER = 'kundenNummer',
  NETTO_FROM = 'nettoFrom',
  NETTO_TO = 'nettoTo',
  OBJEKT_ID = 'objektId',
  PAYEE_BANK_DETAILS_IBAN = 'payeeBankDetailsIban',
  PAYEE_RECHTSTRAEGER_ID = 'payeeRechtstraegerId',
  PAYMENT_METHOD = 'paymentMethod',
  PAYMENT_REFERENCE_TEXT = 'paymentReferenceText',
  PURPOSE_OF_USE_TEXT = 'purposeOfUseText',
  SEPA_MANDAT_REFERENCE = 'sepaMandatReference',
  VAT_FROM = 'vatFrom',
  VAT_TO = 'vatTo',
}

export type TIncomingInvoiceQueryParams = QueryParams<FiltersFormValues>;

export const updateQueryParams = (
  navigate: (path: string) => void,
  filters: TIncomingInvoiceQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToIncomingInvoiceListingPage(filters, paginationParams));

export const generatePathToIncomingInvoiceListingPage = (filters: TIncomingInvoiceQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  `${generatePath(URI_INCOMING_INVOICE_PAGE)}?${mapToQueryString({
    [QueryParamKey.BILLING_TYPE]: filters?.billingType,
    [QueryParamKey.BRUTTO_FROM]: filters?.bruttoFrom,
    [QueryParamKey.BRUTTO_TO]: filters?.bruttoTo,
    [QueryParamKey.BUCHUNGSDATUM_FROM]: filters?.buchungsdatumFrom,
    [QueryParamKey.BUCHUNGSDATUM_TO]: filters?.buchungsdatumTo,
    [QueryParamKey.CREATED_BY_MITARBEITER_ID_LIST]: filters?.createdByMitarbeiterIdList,
    [QueryParamKey.CREATE_TS_FROM]: filters?.createTsFrom,
    [QueryParamKey.CREATE_TS_TO]: filters?.createTsTo,
    [QueryParamKey.DUE_DATE_FROM]: filters?.dueDateFrom,
    [QueryParamKey.DUE_DATE_TO]: filters?.dueDateTo,
    [QueryParamKey.INCOMING_INVOICE_FILTER_STATUS]: filters?.incomingInvoiceFilterStatus,
    [QueryParamKey.INVOICE_DATE_FROM]: filters?.invoiceDateFrom,
    [QueryParamKey.INVOICE_DATE_TO]: filters?.invoiceDateTo,
    [QueryParamKey.INVOICE_NUMBER]: filters?.invoiceNumber,
    [QueryParamKey.ISSUER_BANK_DETAILS_IBAN]: filters?.issuerBankDetailsIban,
    [QueryParamKey.ISSUER_RECHTSTRAEGER_ID]: filters?.issuerRechtstraegerId,
    [QueryParamKey.KONTO_ID]: filters?.kontoId,
    [QueryParamKey.KUNDEN_NUMMER]: filters?.kundenNummer,
    [QueryParamKey.NETTO_FROM]: filters?.nettoFrom,
    [QueryParamKey.NETTO_TO]: filters?.nettoTo,
    [QueryParamKey.OBJEKT_ID]: filters?.objektId,
    [QueryParamKey.PAYEE_BANK_DETAILS_IBAN]: filters?.payeeBankDetailsIban,
    [QueryParamKey.PAYEE_RECHTSTRAEGER_ID]: filters?.payeeRechtstraegerId,
    [QueryParamKey.PAYMENT_METHOD]: filters?.paymentMethod,
    [QueryParamKey.PAYMENT_REFERENCE_TEXT]: filters?.paymentReferenceText,
    [QueryParamKey.PURPOSE_OF_USE_TEXT]: filters?.purposeOfUseText,
    [QueryParamKey.SEPA_MANDAT_REFERENCE]: filters?.sepaMandatReference,
    [QueryParamKey.VAT_FROM]: filters?.vatFrom,
    [QueryParamKey.VAT_TO]: filters?.vatTo,
    ...paginationParams,
  })}`;
