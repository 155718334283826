import { generatePath } from 'react-router';
import { generatePathToWizardUpdatePage } from '../Wizard/wizardApiPaths';
import { wizardTypes } from '../Wizard/wizardHelpers';

export const URI_EMAIL_VERSAND_PAGE = '/dashboard/emails';

export const URI_EMAIL_VERSAND_DETAILS_PAGE = '/dashboard/emails/:mailId';

export const generatePathToEmailVersandDetailsPage = (mailId: string) => generatePath(URI_EMAIL_VERSAND_DETAILS_PAGE, { mailId });

//@todo : Temporary, remove after refactoring infoMailDelivery
export const generatePathToWizardPage = (wizardId: string, step = 1) => {
  return generatePathToWizardUpdatePage(wizardTypes.InfoMailDelivery, wizardId, step);
};
