import React, { FC } from 'react';
import { Skeleton } from 'antd';
import {
  useSubAdministrationAbrechnungsdefinitionVersionListQuery,
  useSubAdministrationAbrechnungsdefinitionVersionQuery,
} from '../../../../../../features/Abrechnungsdefinition/gql/SubAdministrationAbrDef/SubAdministrationAbrDefVersionQueries.types';
import SubAbrDefVersionUpdateForm from '../../../../../../features/Abrechnungsdefinition/Version/Form/Update/SubAbrDefVersionUpdateForm';

type Props = {
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersionId: string;
};

const SysSettingsSubAbrechnungsdefinitionVersionUpdatePage: FC<Props> = ({ abrechnungsdefinitionId, abrechnungsdefinitionVersionId }) => {
  const { data: subAbrDefVersionListData } = useSubAdministrationAbrechnungsdefinitionVersionListQuery({
    variables: { abrechnungsdefinitionId, withDetails: true },
  });
  const abrechnungsdefinitionVersionList = subAbrDefVersionListData?.getSubAdministrationAbrechnungsdefinitionVersionList.data;

  const { data: subAbrDefVersion, refetch } = useSubAdministrationAbrechnungsdefinitionVersionQuery({
    variables: { abrechnungsdefinitionId, abrechnungsdefinitionVersionId },
  });
  const abrechnungsdefinitionVersion = subAbrDefVersion?.getSubAdministrationAbrechnungsdefinitionVersion.data;

  if (!abrechnungsdefinitionVersion || !abrechnungsdefinitionVersionList) return <Skeleton active />;

  const firmendatenId = abrechnungsdefinitionVersion.firmendatenId ?? '';

  return (
    <SubAbrDefVersionUpdateForm
      abrechnungsdefinitionVersionList={abrechnungsdefinitionVersionList}
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      abrechnungsdefinitionVersion={abrechnungsdefinitionVersion}
      validFrom={abrechnungsdefinitionVersion.validFrom}
      kommentar={abrechnungsdefinitionVersion.kommentar ?? ''}
      onSuccess={refetch}
      firmendatenId={firmendatenId}
    />
  );
};

export default SysSettingsSubAbrechnungsdefinitionVersionUpdatePage;
