import React, { FC, useEffect } from 'react';
import { useField } from 'formik';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { belongsToKundenSystem, belongsToSelectedFirmendaten } from '../../../helpers/selectHelper';
import { EntityStatusFormSelect } from '../../../components/Status/EntityStatus';
import {
  useUstKategorieListForSelectByFirmendatenIdQuery,
  useUstKategorieListForSelectByKundenSystemIdQuery,
} from './gql/UstKategorieSelectQueries.types';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';
import { UstKategorieWithStatus } from '../UstKategorie/ustKategorieUriHelper';

type Props = {
  disabled?: boolean;
  name: string;
  firmendatenId?: string | null;
  fieldHelp?: string | null;
};

const UstKategorieSelect: FC<Props> = ({ disabled, name, firmendatenId, fieldHelp }) => {
  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();
  const [{ value: selectedId }, , { setValue: setSelectedId }] = useField<string>(name);

  const { loading: loadingByKS, data: maybeListByKS } = useUstKategorieListForSelectByKundenSystemIdQuery({
    variables: {
      kundenSystemId,
      includeArchiviert: true,
    },
    skip: !!firmendatenId,
  });

  const { loading: loadingByFD, data: maybeListByFD } = useUstKategorieListForSelectByFirmendatenIdQuery({
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      firmendatenId: firmendatenId!,
      includeArchiviert: true,
    },
    skip: !firmendatenId,
  });

  const loading = loadingByKS || loadingByFD;
  const listByKS = (maybeListByKS?.getUstKategorieListByKundenSystemId.data ?? []) as UstKategorieWithStatus[];
  const listByFD = (maybeListByFD?.getUstKategorieListByFirmendatenId.data ?? []) as UstKategorieWithStatus[];

  const list =
    listByKS.length > 0 ? listByKS.filter(belongsToKundenSystem) : listByFD.filter((item) => belongsToSelectedFirmendaten(item, firmendatenId));

  // f_id changes => list changes => if there was already a selected entity it may not be available in the scope of newly selected f_id => clear entity
  useEffect(() => {
    if (!loading && selectedId && !list.map((item) => item.ustKategorieId).includes(selectedId)) {
      setSelectedId('');
    }
  }, [loading, list, selectedId, setSelectedId]);

  return (
    <FormItemWithFieldHelp name={name} label="USt.-Kategorie" fieldHelp={fieldHelp}>
      <EntityStatusFormSelect<UstKategorieWithStatus>
        list={list}
        getSelectItemId={(ustKategorie) => ustKategorie.ustKategorieId}
        getSelectItemContent={(ustKategorie) => ustKategorie.kurzBezeichnung}
        getSelectItemTooltipTitle={(ustKategorie) => ustKategorie.kurzBezeichnung}
        disabled={disabled}
        id={name}
        name={name}
        loading={loadingByFD || loadingByKS}
        placeholder="USt-Kategorie zuweisen"
        size="small"
        // style={{ width: 300 }}
      />
    </FormItemWithFieldHelp>
  );
};

export default UstKategorieSelect;
