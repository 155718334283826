import { FC } from 'react';
import { FormikProps } from 'formik';
import { FiltersFormValues, listingFiltersFormFields, listingLabelList } from './listingFiltersFormMapper';
import FiltersWith4OrMoreFields from '../../../../../components/Filters/FiltersWith4OrMoreFields';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import GenerierlaufDataCarrierNameListFormSelect from '../../../shared/Filters/Payment/GenerierlaufDataCarrierNameListFormSelect';
import { PaymentGenerierlaufType } from '../../../../../types';
import GenerierlaufEntryExitCodeListFormSelect from '../../../shared/Filters/Payment/GenerierlaufEntryExitCodeListFormSelect';
import GenerierlaufDataCarrierTypeListFormSelect from '../../../shared/Filters/Payment/GenerierlaufDataCarrierTypeListFormSelect';
import GenerierlaufPaymentTransactionTypeFormSelect from '../../../shared/Filters/Payment/GenerierlaufPaymentTransactionTypeFormSelect';
import GenerierlaufIbanListFormSelect from '../../../shared/Filters/Payment/GenerierlaufIbanListFormSelect';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
  generierlaufId: string;
};

const ListingFilters: FC<Props> = ({ formikProps, generierlaufId }) => {
  return (
    <FiltersWith4OrMoreFields
      hideTitle
      leftColumn={
        <>
          <FormItemWithoutColon name={listingFiltersFormFields.dataCarrierType} label={listingLabelList.dataCarrierType}>
            <GenerierlaufDataCarrierTypeListFormSelect
              name={listingFiltersFormFields.dataCarrierType}
              generierlaufId={generierlaufId}
              generierlaufType={PaymentGenerierlaufType.DataCarrierPainCreation}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={listingFiltersFormFields.name} label={listingLabelList.name}>
            <GenerierlaufDataCarrierNameListFormSelect
              name={listingFiltersFormFields.name}
              generierlaufId={generierlaufId}
              generierlaufType={PaymentGenerierlaufType.DataCarrierPainCreation}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={listingFiltersFormFields.iban} label={listingLabelList.iban}>
            <GenerierlaufIbanListFormSelect
              name={listingFiltersFormFields.iban}
              generierlaufId={generierlaufId}
              generierlaufType={PaymentGenerierlaufType.DataCarrierPainCreation}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
      rightColumn={
        <>
          <FormItemWithoutColon name={listingFiltersFormFields.paymentTransactionType} label={listingLabelList.paymentTransactionType}>
            <GenerierlaufPaymentTransactionTypeFormSelect
              name={listingFiltersFormFields.paymentTransactionType}
              generierlaufId={generierlaufId}
              generierlaufType={PaymentGenerierlaufType.DataCarrierPainCreation}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={listingFiltersFormFields.exitCodeList} label={listingLabelList.exitCodeList}>
            <GenerierlaufEntryExitCodeListFormSelect
              name={listingFiltersFormFields.exitCodeList}
              generierlaufId={generierlaufId}
              generierlaufType={PaymentGenerierlaufType.DataCarrierPainCreation}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
    />
  );
};

export default ListingFilters;
