import { FC } from 'react';
import { Typography } from 'antd';
import { ReceivedBankTransactionVerbuchenGenerierlauf } from '../../../types';
import { Spacer } from '../../../components/Grid';
import ReceivedBankTransactionVerbuchenVerarbeitungEntryListing from './EntryListing/ReceivedBankTransactionVerbuchenVerarbeitungEntryListing';
import { verarbeitungPageAndMenuListTitles } from '../verarbeitungHelpers';

type Props = {
  generierlauf: ReceivedBankTransactionVerbuchenGenerierlauf;
  verarbeitungType: string;
};

const VerarbeitungReceivedBankTransactionVerbuchen: FC<Props> = ({ generierlauf, verarbeitungType }) => (
  <>
    <Typography.Title level={4}>{verarbeitungPageAndMenuListTitles.ReceivedBankTransactionVerbuchen}</Typography.Title>
    <Spacer />
    <ReceivedBankTransactionVerbuchenVerarbeitungEntryListing generierlauf={generierlauf} verarbeitungType={verarbeitungType} />
  </>
);

export default VerarbeitungReceivedBankTransactionVerbuchen;
