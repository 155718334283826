import * as Types from '../../types';

import { gql } from '@apollo/client';
import { UserFieldsFragmentDoc } from './UserFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUserMutationVariables = Types.Exact<{
  request: Types.UserCreateInput;
}>;

export type CreateUserMutation = {
  createUser: {
    data: {
      createdTs: string;
      email?: string | null;
      emailUnverified?: string | null;
      firstname?: string | null;
      lastname?: string | null;
      role: Types.UserRole;
      username: string;
      status: { text: string; value: Types.UserStatus };
      contactList?: Array<{
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
    };
  };
};

export type ChangeEmailMeMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
}>;

export type ChangeEmailMeMutation = {
  actionTriggerChangeEmailMe: {
    data: {
      createdTs: string;
      email?: string | null;
      emailUnverified?: string | null;
      firstname?: string | null;
      lastname?: string | null;
      role: Types.UserRole;
      username: string;
      status: { text: string; value: Types.UserStatus };
      contactList?: Array<{
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
    };
  };
};

export type ChangeEmailMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
  username: Types.Scalars['String']['input'];
}>;

export type ChangeEmailMutation = {
  actionTriggerChangeEmail: {
    data: {
      createdTs: string;
      email?: string | null;
      emailUnverified?: string | null;
      firstname?: string | null;
      lastname?: string | null;
      role: Types.UserRole;
      username: string;
      status: { text: string; value: Types.UserStatus };
      contactList?: Array<{
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
    };
  };
};

export type SperrenUserMutationVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
}>;

export type SperrenUserMutation = {
  actionSperrenUser: {
    data: {
      createdTs: string;
      email?: string | null;
      emailUnverified?: string | null;
      firstname?: string | null;
      lastname?: string | null;
      role: Types.UserRole;
      username: string;
      status: { text: string; value: Types.UserStatus };
      contactList?: Array<{
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
    };
  };
};

export type EntsperrenUserMutationVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
}>;

export type EntsperrenUserMutation = {
  actionEntsperrenUser: {
    data: {
      createdTs: string;
      email?: string | null;
      emailUnverified?: string | null;
      firstname?: string | null;
      lastname?: string | null;
      role: Types.UserRole;
      username: string;
      status: { text: string; value: Types.UserStatus };
      contactList?: Array<{
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
    };
  };
};

export type ChangePasswordMeMutationVariables = Types.Exact<{
  oldPassword: Types.Scalars['String']['input'];
  newPassword: Types.Scalars['String']['input'];
}>;

export type ChangePasswordMeMutation = {
  actionChangePasswordMe: {
    data: {
      createdTs: string;
      email?: string | null;
      emailUnverified?: string | null;
      firstname?: string | null;
      lastname?: string | null;
      role: Types.UserRole;
      username: string;
      status: { text: string; value: Types.UserStatus };
      contactList?: Array<{
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
    };
  };
};

export type PasswordResetUserMutationVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
}>;

export type PasswordResetUserMutation = {
  actionTriggerPasswordResetUser: {
    data: {
      createdTs: string;
      email?: string | null;
      emailUnverified?: string | null;
      firstname?: string | null;
      lastname?: string | null;
      role: Types.UserRole;
      username: string;
      status: { text: string; value: Types.UserStatus };
      contactList?: Array<{
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
    };
  };
};

export const CreateUserDocument = gql`
  mutation CreateUser($request: UserCreateInput!) {
    createUser(request: $request) {
      data {
        ...userFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
`;
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const ChangeEmailMeDocument = gql`
  mutation ChangeEmailMe($email: String!) {
    actionTriggerChangeEmailMe(email: $email) {
      data {
        ...userFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
`;
export function useChangeEmailMeMutation(baseOptions?: Apollo.MutationHookOptions<ChangeEmailMeMutation, ChangeEmailMeMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeEmailMeMutation, ChangeEmailMeMutationVariables>(ChangeEmailMeDocument, options);
}
export type ChangeEmailMeMutationHookResult = ReturnType<typeof useChangeEmailMeMutation>;
export type ChangeEmailMeMutationResult = Apollo.MutationResult<ChangeEmailMeMutation>;
export type ChangeEmailMeMutationOptions = Apollo.BaseMutationOptions<ChangeEmailMeMutation, ChangeEmailMeMutationVariables>;
export const ChangeEmailDocument = gql`
  mutation ChangeEmail($email: String!, $username: String!) {
    actionTriggerChangeEmail(email: $email, username: $username) {
      data {
        ...userFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
`;
export function useChangeEmailMutation(baseOptions?: Apollo.MutationHookOptions<ChangeEmailMutation, ChangeEmailMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeEmailMutation, ChangeEmailMutationVariables>(ChangeEmailDocument, options);
}
export type ChangeEmailMutationHookResult = ReturnType<typeof useChangeEmailMutation>;
export type ChangeEmailMutationResult = Apollo.MutationResult<ChangeEmailMutation>;
export type ChangeEmailMutationOptions = Apollo.BaseMutationOptions<ChangeEmailMutation, ChangeEmailMutationVariables>;
export const SperrenUserDocument = gql`
  mutation SperrenUser($username: String!) {
    actionSperrenUser(username: $username) {
      data {
        ...userFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
`;
export function useSperrenUserMutation(baseOptions?: Apollo.MutationHookOptions<SperrenUserMutation, SperrenUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SperrenUserMutation, SperrenUserMutationVariables>(SperrenUserDocument, options);
}
export type SperrenUserMutationHookResult = ReturnType<typeof useSperrenUserMutation>;
export type SperrenUserMutationResult = Apollo.MutationResult<SperrenUserMutation>;
export type SperrenUserMutationOptions = Apollo.BaseMutationOptions<SperrenUserMutation, SperrenUserMutationVariables>;
export const EntsperrenUserDocument = gql`
  mutation EntsperrenUser($username: String!) {
    actionEntsperrenUser(username: $username) {
      data {
        ...userFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
`;
export function useEntsperrenUserMutation(baseOptions?: Apollo.MutationHookOptions<EntsperrenUserMutation, EntsperrenUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EntsperrenUserMutation, EntsperrenUserMutationVariables>(EntsperrenUserDocument, options);
}
export type EntsperrenUserMutationHookResult = ReturnType<typeof useEntsperrenUserMutation>;
export type EntsperrenUserMutationResult = Apollo.MutationResult<EntsperrenUserMutation>;
export type EntsperrenUserMutationOptions = Apollo.BaseMutationOptions<EntsperrenUserMutation, EntsperrenUserMutationVariables>;
export const ChangePasswordMeDocument = gql`
  mutation ChangePasswordMe($oldPassword: String!, $newPassword: String!) {
    actionChangePasswordMe(oldPassword: $oldPassword, newPassword: $newPassword) {
      data {
        ...userFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
`;
export function useChangePasswordMeMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMeMutation, ChangePasswordMeMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangePasswordMeMutation, ChangePasswordMeMutationVariables>(ChangePasswordMeDocument, options);
}
export type ChangePasswordMeMutationHookResult = ReturnType<typeof useChangePasswordMeMutation>;
export type ChangePasswordMeMutationResult = Apollo.MutationResult<ChangePasswordMeMutation>;
export type ChangePasswordMeMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMeMutation, ChangePasswordMeMutationVariables>;
export const PasswordResetUserDocument = gql`
  mutation PasswordResetUser($username: String!) {
    actionTriggerPasswordResetUser(username: $username) {
      data {
        ...userFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
`;
export function usePasswordResetUserMutation(
  baseOptions?: Apollo.MutationHookOptions<PasswordResetUserMutation, PasswordResetUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PasswordResetUserMutation, PasswordResetUserMutationVariables>(PasswordResetUserDocument, options);
}
export type PasswordResetUserMutationHookResult = ReturnType<typeof usePasswordResetUserMutation>;
export type PasswordResetUserMutationResult = Apollo.MutationResult<PasswordResetUserMutation>;
export type PasswordResetUserMutationOptions = Apollo.BaseMutationOptions<PasswordResetUserMutation, PasswordResetUserMutationVariables>;
