import React, { FC } from 'react';
import { MenuProps, Modal } from 'antd';
import { LinkBreak1Icon } from '@radix-ui/react-icons';
import { ReadOutlined } from '@ant-design/icons';
import ActionDropdown from '../../../components/Dropdown/ActionDropdown';
import BookForm from './Form/Book/BookForm';
import { useToggle } from '../../../hooks/useToggle';
import { TReceivedBankTransactionQueryParams } from './Filters/filtersQueryParams';
import { radixActionStyles } from '../../../helpers/radixIconsStyles';
import {
  useMarkAsNotedReceivedBankTransactionBulkActionMutation,
  useRemoveReceivedBankTransactionPaymentAssignmentMutation,
} from '../gql/ReceivedBankTransactionMutations.types';
import { showSuccessMsgOther } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { mapQueryParamsToPaymentAssignmentBulkActionInput } from './Filters/filtersFormMapper';

type Props = {
  onAction: () => void;
  queryParams: TReceivedBankTransactionQueryParams;
};

const ReceivedBankTransactionTableBulkActions: FC<Props> = ({ onAction, queryParams }) => {
  const [isBookModalOpen, toggleBookModalOpen] = useToggle();

  const [runMarkAsNoted] = useMarkAsNotedReceivedBankTransactionBulkActionMutation({
    variables: {
      input: mapQueryParamsToPaymentAssignmentBulkActionInput(queryParams),
    },
    onCompleted: () => {
      showSuccessMsgOther('Empfangene Buchungen wurden als gelesen markiert');
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runRemoveAssignment] = useRemoveReceivedBankTransactionPaymentAssignmentMutation({
    variables: {
      input: mapQueryParamsToPaymentAssignmentBulkActionInput(queryParams),
    },
    onCompleted: () => {
      showSuccessMsgOther('Zahlungszuweisungen wurden entfernt');
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Verbuchen',
      onClick: toggleBookModalOpen,
      icon: <ReadOutlined />,
    },
    {
      key: '2',
      label: 'Alle als gelesen markieren',
      onClick: showConfirmMarkAsNoted(runMarkAsNoted),
      icon: <LinkBreak1Icon style={radixActionStyles} />,
    },
    {
      key: '3',
      label: 'Zahlungszuweisungen entfernen',
      onClick: showConfirmRemoveAssignment(runRemoveAssignment),
      icon: <LinkBreak1Icon style={radixActionStyles} />,
    },
  ];

  const onActionSuccess = () => {
    onAction();
    toggleBookModalOpen();
  };

  return (
    <>
      <ActionDropdown items={items} title="Auf Liste anwenden" />
      <Modal
        title="Empf. Bankbuchung verbuchen"
        onCancel={toggleBookModalOpen}
        open={isBookModalOpen}
        destroyOnClose
        maskClosable={false}
        footer={false}
      >
        <BookForm onAction={onActionSuccess} queryParams={queryParams} onCancel={toggleBookModalOpen} />
      </Modal>
    </>
  );
};

const showConfirmMarkAsNoted = (runMarkAsNoted: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie die Buchungen als gelesen markieren?`,
    okText: 'Als gelesen markieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runMarkAsNoted();
    },
  });
};

const showConfirmRemoveAssignment = (runRemoveAssignment: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie die Zahlungszuweisungen entfernen?`,
    okText: 'Entfernen',
    cancelText: 'Abbrechen',
    onOk() {
      return runRemoveAssignment();
    },
  });
};

export default ReceivedBankTransactionTableBulkActions;
