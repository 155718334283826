import * as Yup from 'yup';
import 'yup-phone-lite';
import { phoneContactCreateFormFields } from './phoneContactCreateFormMapper';
import { entityIsRequired } from '../../../../../../components/message/validationMsg';

/* eslint-disable no-template-curly-in-string */
export const phoneContactValidationSchema = Yup.object().shape({
  [phoneContactCreateFormFields.wertList]: Yup.array().of(
    Yup.string().phone('AT', 'Telefonnummer ist ungültig').required(entityIsRequired('Telefonnummer'))
  ),
});
/* eslint-enable no-template-curly-in-string */
