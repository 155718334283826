import { Space } from 'antd';
import styled from 'styled-components';

export const StyledAvailableAmountSpace = styled(Space)`
  & > :first-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
