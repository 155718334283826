import React, { FC } from 'react';
import { Col, Divider, Row, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { Ticket, TicketBase, TicketChangeType } from '../../../../types';
import { TicketChangeEntryFieldsFragment } from '../../gql/TicketFragments.types';
import { TicketChangeEntryChangeDetails } from './TicketChangeEntryList';
import { getSubTicketById } from '../../ticketHelpers';
import { generatePathToTicketDetailsPage } from '../../ticketUriPaths';
import EntryChangeHeader from '../shared/EntryChangeHeader';

type Props = {
  ticket: Ticket;
  changeEntry: TicketChangeEntryFieldsFragment;
  changeDetails: TicketChangeEntryChangeDetails;
};

const TicketChangeEntry: FC<Props> = ({ ticket, changeEntry, changeDetails }) => (
  <>
    <Space size={16} direction="vertical" style={{ width: '100%', paddingInline: 8 }}>
      {entryChangeLabel(ticket, changeEntry, changeDetails)}
      {changeEntry.changeType === TicketChangeType.Update && (
        <Space size={4} direction="vertical" style={{ width: '100%' }}>
          {Object.entries(changeDetails).map(([key, { label, oldValue, newValue, render }]) => {
            return (
              <Row align="top" gutter={24} key={key} style={{ marginTop: 0 }}>
                <Col span={6}>
                  <Typography.Text type="secondary">{label}</Typography.Text>
                </Col>
                <>
                  <Col span={9}>
                    {oldValue && (
                      <>
                        <Typography.Text strong style={{ marginRight: 4 }}>
                          Alt:
                        </Typography.Text>
                        {render(oldValue)}
                      </>
                    )}
                  </Col>
                  <Col span={9}>
                    {newValue && (
                      <>
                        <Typography.Text strong style={{ marginRight: 4 }}>
                          Neu:
                        </Typography.Text>
                        {render(newValue)}
                      </>
                    )}
                  </Col>
                </>
              </Row>
            );
          })}
        </Space>
      )}
    </Space>
    <Divider style={{ marginBlock: 12 }} />
  </>
);

const entryChangeLabel = (
  ticket: Ticket,
  changeEntry: TicketChangeEntryFieldsFragment,
  changeDetails: TicketChangeEntryChangeDetails
): React.ReactNode => {
  let action: React.ReactNode = '';
  let title: React.ReactNode = null;

  const subTicket = getSubTicketById(ticket.subTicketList, changeEntry.ticketId);

  if (subTicket) {
    title = (
      <Link target="_blank" to={generatePathToTicketDetailsPage(subTicket as TicketBase)}>
        {subTicket.title}
      </Link>
    );
  }

  switch (changeEntry.changeType) {
    case TicketChangeType.Create:
      if (!changeEntry.subTicketChangeEntry || ticket.isSubTicket) {
        action = 'hat die Aufgabe erstellt';
      } else {
        action = (
          <span>
            hat eine Unteraufgabe mit dem Titel {`"`}
            {title}
            {`"`} erstellt
          </span>
        );
      }
      break;
    case TicketChangeType.Update:
      if ('comment' in changeDetails) {
        const { oldValue, newValue } = changeDetails.comment;
        if (!oldValue && newValue) {
          action = 'hat einen Kommentar hinzugefügt';
        } else if (oldValue && newValue) {
          action = 'hat einen Kommentar geändert';
        } else {
          action = 'hat einen Kommentar gelöscht';
        }
      } else if (!changeEntry.subTicketChangeEntry || ticket.isSubTicket) {
        action = 'hat Änderungen vorgenommen';
      } else {
        action = (
          <span>
            hat Änderungen in der Unteraufgabe mit dem Titel {`"`}
            {title}
            {`"`} vorgenommen
          </span>
        );
      }
      break;
  }

  return (
    <EntryChangeHeader
      userId={changeEntry.createdBy}
      mitarbeiterId={changeEntry.createdByMitarbeiterId}
      date={changeEntry.createTs}
      action={action}
    />
  );
};

export default TicketChangeEntry;
