import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { useWeAbrechnungsdefinitionVersionQuery } from '../../../../../../features/Abrechnungsdefinition/gql/WeAbrDef/WeAbrDefVersionQueries.types';
import { useFirmendatenQuery } from '../../../../../../features/KundenSystem/Firmendaten/gql/FirmendatenQueries.types';
import { useBelegTexteFirmendatenQuery } from '../../../../../../features/Belegtext/gql/BelegtexteQueries.types';
import { useFirmendatenOperatingSiteListQuery } from '../../../../../../features/KundenSystem/Firmendaten/gql/FirmendatenOperatingSiteQueries.types';
import { BelegTextePlatzhalter } from '../../../../../../features/Belegtext/Form/belegTexteFormTypes';
import {
  findBelegTexteHauptAddress,
  findBelegTexteHauptEmail,
  findBelegTextePhoneNumber,
} from '../../../../../../features/shared/operatingSiteHelpers';
import BkOrWeAbrDefVersionView from '../../../../../../features/Abrechnungsdefinition/Version/View/BkOrWeAbrDefVersionView';
import { AbrechnungsdefinitionType } from '../../../../../../types';

type Props = {
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersionId: string;
  firmendatenId: string;
  isHistoricized: boolean;
};
const SysSettingsWeAbrechnungsdefinitionVersionViewPage: FC<Props> = ({
  abrechnungsdefinitionId,
  abrechnungsdefinitionVersionId,
  firmendatenId,
  isHistoricized,
}) => {
  const { data } = useWeAbrechnungsdefinitionVersionQuery({
    variables: { abrechnungsdefinitionId, abrechnungsdefinitionVersionId, searchInHistory: isHistoricized },
  });
  const abrechnungsdefinitionVersion = data?.getWeAbrechnungsdefinitionVersion.data;

  const { data: firmendatenData } = useFirmendatenQuery({ variables: { firmendatenId } });
  const firmendaten = firmendatenData?.getFirmendaten.data;

  const { data: belegTexteData } = useBelegTexteFirmendatenQuery({ variables: { firmendatenId } });

  const belegTexte = belegTexteData?.getBelegTexteFirmendaten.data;

  const { data: firmendatenOperatingSiteListData } = useFirmendatenOperatingSiteListQuery({ variables: { firmendatenId } });
  const operatingSiteList = firmendatenOperatingSiteListData?.getFirmendatenOperatingSiteList.data ?? [];

  if (!abrechnungsdefinitionVersion || !firmendaten || !belegTexte || !operatingSiteList) return <Skeleton active />;

  const belegTextePlatzhalter: BelegTextePlatzhalter = {
    hauptAddress: findBelegTexteHauptAddress(operatingSiteList),
    vatIdentificationNumber: firmendaten.vatIdentificationNumber,
    companyRegistrationNumber: firmendaten.companyRegistrationNumber,
    phoneNumber: findBelegTextePhoneNumber(operatingSiteList),
    email: findBelegTexteHauptEmail(operatingSiteList),
  };

  return (
    <BkOrWeAbrDefVersionView
      abrDefType={AbrechnungsdefinitionType.WeAbrechnung}
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      abrechnungsdefinitionVersion={abrechnungsdefinitionVersion}
      isHistoricized={isHistoricized}
      belegTextePlatzhalter={belegTextePlatzhalter}
      belegTexte={belegTexte}
      firmendatenId={firmendatenId}
    />
  );
};

export default SysSettingsWeAbrechnungsdefinitionVersionViewPage;
