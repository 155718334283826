import React from 'react';
import { useToggle } from '../../../hooks/useToggle';
import { CellButton } from './CellButton';
import { TABLE_ALIGNED_COLS_CLASSNAMES } from './tableWithAlignedColsClassnames';
import { TableWithAlignedColsExpandedRowType } from './tableWithAlignedColsProps';

type TableWithAlignedColsRowProps<T> = {
  dataSource: T;
  rowItem: TableWithAlignedColsExpandedRowType<T>;
};

function TableWithAlignedColsRow<T>({ dataSource, rowItem }: TableWithAlignedColsRowProps<T>) {
  const [isOpen, setIsOpen] = useToggle();

  if (rowItem.table) {
    const { StyledComponent, showCellButton } = rowItem.table;

    const shouldRenderDataSource = (showCellButton && isOpen) || !showCellButton;

    const content = (
      <StyledComponent>
        <thead className={TABLE_ALIGNED_COLS_CLASSNAMES.thead}>
          <tr>
            {showCellButton && <CellButton isOpen={isOpen} onClick={setIsOpen} positionOfButton={rowItem.table.positionOfButton} isHeaderCell />}
            {rowItem.header(isOpen, setIsOpen)}
          </tr>
        </thead>
        {shouldRenderDataSource && <tbody className={TABLE_ALIGNED_COLS_CLASSNAMES.tbody}>{rowItem.body(dataSource)}</tbody>}
      </StyledComponent>
    );

    return (
      <tr className={TABLE_ALIGNED_COLS_CLASSNAMES.tableInTable}>
        <td colSpan={rowItem.table.colSpanSize[0]} style={{ padding: 0 }}>
          {content}
        </td>
        {rowItem.table.colSpanSize[1] && <td colSpan={rowItem.table.colSpanSize[1]} style={{ background: '#fafafa' }} />}
      </tr>
    );
  }

  return (
    <>
      {rowItem.header(isOpen, setIsOpen)}
      {isOpen && rowItem.body(dataSource)}
    </>
  );
}

export default TableWithAlignedColsRow;
