import * as Yup from 'yup';
import { versandFormFields } from './versandFormMapper';
import { entityMustBeSelected } from '../../../../../components/message/validationMsg';

export const versandFormValidationSchema = (isOriginal: boolean) =>
  Yup.object().shape({
    [versandFormFields.rechtstraegerId]: Yup.string().required(entityMustBeSelected('Person')),
    [versandFormFields.emailAktiv]: isOriginal ? Yup.boolean() : Yup.boolean().oneOf([true], 'E-Mail muss aktiviert sein.'),
    [versandFormFields.postAktiv]: isOriginal
      ? Yup.boolean().when([versandFormFields.emailAktiv], (emailAktiv, schema) => {
          return !emailAktiv ? schema.oneOf([true], 'Es muss zumindest eines aktiviert sein (E-Mail oder Post) ') : schema;
        })
      : Yup.boolean(),
  });
