import { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { friendlyFormatIBAN } from 'ibantools';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { useFilterReceivedBankTransactionBankstatementIbanListQuery } from '../../gql/ReceivedBankTransactionQueries.types';

type Props = Omit<SelectProps, 'id' | 'placeholder' | 'size' | 'allowClear' | 'showSearch' | 'filterOption'>;

const ReceivedBankTransactionBankstatementIbanSelect: FC<Props> = ({ name, ...restProps }) => {
  const { data, loading } = useFilterReceivedBankTransactionBankstatementIbanListQuery();
  const list = data?.getFilterReceivedBankTransactionBankstatementIbanList.data ?? [];

  return (
    <Select
      {...restProps}
      name={name}
      id={name}
      size="small"
      loading={loading}
      placeholder="IBAN auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {list.map((bankStatementIban) => (
        <Select.Option key={bankStatementIban} value={bankStatementIban}>
          {friendlyFormatIBAN(bankStatementIban)}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ReceivedBankTransactionBankstatementIbanSelect;
