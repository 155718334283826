import React, { FC } from 'react';
import { StyledListSummarySpace } from '../../shared/ListSummary/styled/StyledListSummarySpace';
import { SummaryText } from '../../shared/ListSummary/SummaryText';
import { LoadingCol } from '../../../components/Loading';
import { VertragVposIndexedValueListSummary } from '../../../types';

type Props = {
  summary?: VertragVposIndexedValueListSummary;
};

const IndexedValueListSummary: FC<Props> = ({ summary }) => {
  return (
    <>
      {summary ? (
        <StyledListSummarySpace>
          <SummaryText text="Betrag alt" value={summary.sumAmountOld} />
          <SummaryText text="Betrag neu" value={summary.sumAmountNew} />
        </StyledListSummarySpace>
      ) : (
        <LoadingCol isLoading />
      )}
    </>
  );
};

export default IndexedValueListSummary;
