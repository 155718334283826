import React from 'react';
import { useParams } from 'react-router-dom';
import menuListHeVertrag from './menuListHeVertrag';
import DetailsPageTemplate from '../../components/Template/DetailsPageTemplate';
import { HeVertragDetailsPageRouteParams } from './routes';
import { useHeVertragQuery } from '../../features/Vertrag/HeVertrag/gql/HeVertragQueries.types';
import HeVertragCard from '../../features/Vertrag/HeVertrag/Card/HeVertragCard';
import { useObjektQuery } from '../../features/Objekt/gql/ObjektQueries.types';
import { LoadingDetailsPage } from '../../components/Loading';
import { HeVertrag } from '../../types';

const HeVertragDetailsPage = () => {
  const { objektId, heVertragId } = useParams() as HeVertragDetailsPageRouteParams;

  const { data, loading, refetch } = useHeVertragQuery({
    variables: {
      objektId,
      heVertragId,
    },
  });
  const heVertrag = data?.getHeVertrag.data;

  const { data: objRechnungsausstellerData, loading: objRALoading } = useObjektQuery({ variables: { objektId } });

  const objRechnungsaussteller = objRechnungsausstellerData?.getObjekt.data;

  if (!objRechnungsaussteller) return <LoadingDetailsPage loading={objRALoading} />;

  return (
    <DetailsPageTemplate<HeVertrag>
      data={heVertrag}
      isLoading={!heVertrag}
      pageTitle={(vertrag) => `Hauseigentümervertrag von ${vertrag.vertragspartner.kurzBezeichnung}`}
      card={(vertrag) => <HeVertragCard heVertrag={vertrag} objektId={objektId} loading={loading} onActionSuccess={refetch} />}
      sidebarMenuList={(vertrag) =>
        menuListHeVertrag(objektId, vertrag.heVertragId, vertrag, objRechnungsaussteller.rechnungsAussteller.rechtstraegerId)
      }
    />
  );
};

export default HeVertragDetailsPage;
