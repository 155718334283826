import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import { RechtstraegerFieldsFragmentDoc } from '../../../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import { BankDetailsFieldsFragmentDoc } from '../../../../BankDetails/gql/BankDetailsFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ZahlungsverlaufQueryVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  heVertragId: Types.Scalars['ID']['input'];
}>;

export type ZahlungsverlaufQuery = {
  getZahlungsverlauf: {
    data: Array<{
      accountHolder: string;
      betrag?: number | null;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      durchfuehrungsdatum?: string | null;
      fibuBuchungId?: string | null;
      heVertragAuszahlungId?: string | null;
      updatedByMitarbeiterId?: string | null;
      verwendungszweck?: string | null;
      zahlungsdatum: string;
      zahlungsempfaengerBankDetails: string;
      zahlungsreferenz?: string | null;
      heVertragZahlungsregel?: {
        beendetAm?: string | null;
        bezeichnung: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable: boolean;
        durchfuehrenAm: number;
        fixbetrag?: number | null;
        heAbrechnungsdefinitionId?: string | null;
        naechsteZahlung?: string | null;
        prozentsatz?: number | null;
        updatedByMitarbeiterId?: string | null;
        verwendungszweck?: string | null;
        zahlungsreferenz?: string | null;
        zahlungsregelId: string;
        periode: { text: string; value: Types.Turnus };
        status: { description?: string | null; text: string; value: Types.HeVertragZahlungsregelStatus };
        turnus?: { text: string; value: Types.Turnus } | null;
        type: { text: string; value: Types.HeVertragZahlungsregelType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        zahlungsempfaenger: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fibuKontoCreatable: boolean;
          kundennummer: string;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          bankDetailsList: Array<{
            accountHolder: string;
            accountLimit?: number | null;
            assignedHeVertragZahlungsregelMap: any;
            assignedObjektMap: any;
            bankDetailsId: string;
            bankname: string;
            bic: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            ebicsCreditTransferUserList: Array<string>;
            ebicsDirectDebitUserList: Array<string>;
            fibuKontoId?: string | null;
            fibuKontonummer?: string | null;
            iban: string;
            updatedByMitarbeiterId?: string | null;
            fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
            fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
            sepaMandat?: {
              fileId?: string | null;
              fileName?: string | null;
              sepaMandatId: string;
              sepaMandatReference: string;
              signatureDate?: string | null;
              firmendaten?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                firmendatenId: string;
                kundenSystemId: string;
                kurzBezeichnung: string;
                name1: string;
                name2?: string | null;
                name3?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rechnungsAussteller?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
              warningList: Array<{ message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          personenbezugList: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            personenbezugId: string;
            tagList: Array<string>;
            person: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechtstraegerSteuerpflicht?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            historicizedList?: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              rechtstraegerSteuerpflichtId: string;
              validFrom: string;
              steuerpflicht: { text: string; value: Types.Steuerpflicht };
              warningList: Array<{ message: string; type: string }>;
            }> | null;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
          sprache: { text: string; value: Types.Sprache };
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { value: Types.RechtstraegerType; text: string };
          vertragSummary?: {
            heVertragCount: number;
            heVertragVerwaltungCount: number;
            mietVertragCount: number;
            mietVertragVerwaltungCount: number;
            weSubverwaltungCount: number;
            weVertragCount: number;
            weVertragVerwaltungCount: number;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        zahlungsempfaengerBankDetails?: {
          accountHolder: string;
          accountLimit?: number | null;
          assignedHeVertragZahlungsregelMap: any;
          assignedObjektMap: any;
          bankDetailsId: string;
          bankname: string;
          bic: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          ebicsCreditTransferUserList: Array<string>;
          ebicsDirectDebitUserList: Array<string>;
          fibuKontoId?: string | null;
          fibuKontonummer?: string | null;
          iban: string;
          updatedByMitarbeiterId?: string | null;
          fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
          fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
          sepaMandat?: {
            fileId?: string | null;
            fileName?: string | null;
            sepaMandatId: string;
            sepaMandatReference: string;
            signatureDate?: string | null;
            firmendaten?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firmendatenId: string;
              kundenSystemId: string;
              kurzBezeichnung: string;
              name1: string;
              name2?: string | null;
              name3?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechnungsAussteller?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
            warningList: Array<{ message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
      } | null;
      status: { text: string; value: Types.HeVertragAuszahlungStatus };
      type: { text: string; value: Types.HeVertragAuszahlungType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      zahlungsempfaenger: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fibuKontoCreatable: boolean;
        kundennummer: string;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        bankDetailsList: Array<{
          accountHolder: string;
          accountLimit?: number | null;
          assignedHeVertragZahlungsregelMap: any;
          assignedObjektMap: any;
          bankDetailsId: string;
          bankname: string;
          bic: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          ebicsCreditTransferUserList: Array<string>;
          ebicsDirectDebitUserList: Array<string>;
          fibuKontoId?: string | null;
          fibuKontonummer?: string | null;
          iban: string;
          updatedByMitarbeiterId?: string | null;
          fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
          fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
          sepaMandat?: {
            fileId?: string | null;
            fileName?: string | null;
            sepaMandatId: string;
            sepaMandatReference: string;
            signatureDate?: string | null;
            firmendaten?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firmendatenId: string;
              kundenSystemId: string;
              kurzBezeichnung: string;
              name1: string;
              name2?: string | null;
              name3?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechnungsAussteller?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
            warningList: Array<{ message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        personenbezugList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          personenbezugId: string;
          tagList: Array<string>;
          person: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechtstraegerSteuerpflicht?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          historicizedList?: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ message: string; type: string }>;
          }> | null;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
        sprache: { text: string; value: Types.Sprache };
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { value: Types.RechtstraegerType; text: string };
        vertragSummary?: {
          heVertragCount: number;
          heVertragVerwaltungCount: number;
          mietVertragCount: number;
          mietVertragVerwaltungCount: number;
          weSubverwaltungCount: number;
          weVertragCount: number;
          weVertragVerwaltungCount: number;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const ZahlungsverlaufDocument = gql`
  query Zahlungsverlauf($objektId: String!, $heVertragId: ID!) {
    getZahlungsverlauf(objektId: $objektId, heVertragId: $heVertragId) {
      data {
        accountHolder
        betrag
        createTs
        createdBy
        createdByMitarbeiterId
        durchfuehrungsdatum
        fibuBuchungId
        heVertragAuszahlungId
        heVertragZahlungsregel {
          beendetAm
          bezeichnung
          createTs
          createdBy
          createdByMitarbeiterId
          deletable
          durchfuehrenAm
          fixbetrag
          heAbrechnungsdefinitionId
          naechsteZahlung
          periode {
            text
            value
          }
          prozentsatz
          status {
            description
            text
            value
          }
          turnus {
            text
            value
          }
          type {
            text
            value
          }
          updatedByMitarbeiterId
          verwendungszweck
          warningList {
            attribute
            message
            type
          }
          zahlungsempfaenger {
            ...RechtstraegerFields
          }
          zahlungsempfaengerBankDetails {
            ...BankDetailsFields
          }
          zahlungsreferenz
          zahlungsregelId
        }
        status {
          text
          value
        }
        type {
          text
          value
        }
        updatedByMitarbeiterId
        verwendungszweck
        warningList {
          attribute
          message
          type
        }
        zahlungsdatum
        zahlungsempfaenger {
          ...RechtstraegerFields
        }
        zahlungsempfaengerBankDetails
        zahlungsreferenz
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RechtstraegerFieldsFragmentDoc}
  ${BankDetailsFieldsFragmentDoc}
`;
export function useZahlungsverlaufQuery(
  baseOptions: Apollo.QueryHookOptions<ZahlungsverlaufQuery, ZahlungsverlaufQueryVariables> &
    ({ variables: ZahlungsverlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZahlungsverlaufQuery, ZahlungsverlaufQueryVariables>(ZahlungsverlaufDocument, options);
}
export function useZahlungsverlaufLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZahlungsverlaufQuery, ZahlungsverlaufQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZahlungsverlaufQuery, ZahlungsverlaufQueryVariables>(ZahlungsverlaufDocument, options);
}
export function useZahlungsverlaufSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ZahlungsverlaufQuery, ZahlungsverlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ZahlungsverlaufQuery, ZahlungsverlaufQueryVariables>(ZahlungsverlaufDocument, options);
}
export type ZahlungsverlaufQueryHookResult = ReturnType<typeof useZahlungsverlaufQuery>;
export type ZahlungsverlaufLazyQueryHookResult = ReturnType<typeof useZahlungsverlaufLazyQuery>;
export type ZahlungsverlaufSuspenseQueryHookResult = ReturnType<typeof useZahlungsverlaufSuspenseQuery>;
export type ZahlungsverlaufQueryResult = Apollo.QueryResult<ZahlungsverlaufQuery, ZahlungsverlaufQueryVariables>;
