import React from 'react';
import { Helmet } from 'react-helmet-async';
import { PageLayout } from '../../components/LayoutAndromeda';
import IncomingInvoiceListing from '../../features/IncomingInvoice/Listing/IncomingInvoiceListing';

const IncomingInvoicePage = () => {
  return (
    <>
      <Helmet>
        <title>Eingangsrechnungen</title>
      </Helmet>
      <PageLayout>
        <IncomingInvoiceListing />
      </PageLayout>
    </>
  );
};

export default IncomingInvoicePage;
