import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AuftragsartBaseFieldsFragmentDoc, AuftragsartFieldsFragmentDoc } from '../../Auftragsart/gql/AuftragsartFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuftragsartTemplatetListQueryVariables = Types.Exact<{
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  stornoForType?: Types.InputMaybe<Types.AuftragsartType>;
}>;

export type AuftragsartTemplatetListQuery = {
  getAuftragsartTemplateList: {
    data: Array<{
      auftragsartId: string;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      showUstBlock: boolean;
      updatedByMitarbeiterId?: string | null;
      status: { text: string; value: Types.AuftragsartStatus };
      type: { text: string; value: Types.AuftragsartType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AuftragsartTemplateListForStornoSelectQueryVariables = Types.Exact<{
  stornoForType?: Types.InputMaybe<Types.AuftragsartType>;
}>;

export type AuftragsartTemplateListForStornoSelectQuery = {
  getAuftragsartTemplateList: {
    data: Array<{ auftragsartId: string; bezeichnung: string; status: { text: string; value: Types.AuftragsartStatus } }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AuftragsartTemplateQueryVariables = Types.Exact<{
  auftragsartId: Types.Scalars['ID']['input'];
}>;

export type AuftragsartTemplateQuery = {
  getAuftragsartTemplate: {
    data: {
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      auftragsartId: string;
      bezeichnung: string;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      showUstBlock: boolean;
      updatedByMitarbeiterId?: string | null;
      status: { text: string; value: Types.AuftragsartStatus };
      stornoAuftragsart?: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        auftragsartId: string;
        bezeichnung: string;
        showUstBlock: boolean;
        status: { text: string; value: Types.AuftragsartStatus };
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.AuftragsartType };
      } | null;
      textbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      type: { text: string; value: Types.AuftragsartType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AuftragsartTemplateGeneralChangeHistoryListQueryVariables = Types.Exact<{
  auftragsartId: Types.Scalars['ID']['input'];
}>;

export type AuftragsartTemplateGeneralChangeHistoryListQuery = {
  getAuftragsartTemplateGeneralChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type AuftragsartTemplateTexteChangeHistoryListQueryVariables = Types.Exact<{
  auftragsartId: Types.Scalars['ID']['input'];
}>;

export type AuftragsartTemplateTexteChangeHistoryListQuery = {
  getAuftragsartTemplateTexteChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const AuftragsartTemplatetListDocument = gql`
  query AuftragsartTemplatetList($includeArchiviert: Boolean, $stornoForType: AuftragsartType) {
    getAuftragsartTemplateList(includeArchiviert: $includeArchiviert, stornoForType: $stornoForType) {
      data {
        ...AuftragsartBaseFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AuftragsartBaseFieldsFragmentDoc}
`;
export function useAuftragsartTemplatetListQuery(
  baseOptions?: Apollo.QueryHookOptions<AuftragsartTemplatetListQuery, AuftragsartTemplatetListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuftragsartTemplatetListQuery, AuftragsartTemplatetListQueryVariables>(AuftragsartTemplatetListDocument, options);
}
export function useAuftragsartTemplatetListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuftragsartTemplatetListQuery, AuftragsartTemplatetListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuftragsartTemplatetListQuery, AuftragsartTemplatetListQueryVariables>(AuftragsartTemplatetListDocument, options);
}
export function useAuftragsartTemplatetListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AuftragsartTemplatetListQuery, AuftragsartTemplatetListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuftragsartTemplatetListQuery, AuftragsartTemplatetListQueryVariables>(AuftragsartTemplatetListDocument, options);
}
export type AuftragsartTemplatetListQueryHookResult = ReturnType<typeof useAuftragsartTemplatetListQuery>;
export type AuftragsartTemplatetListLazyQueryHookResult = ReturnType<typeof useAuftragsartTemplatetListLazyQuery>;
export type AuftragsartTemplatetListSuspenseQueryHookResult = ReturnType<typeof useAuftragsartTemplatetListSuspenseQuery>;
export type AuftragsartTemplatetListQueryResult = Apollo.QueryResult<AuftragsartTemplatetListQuery, AuftragsartTemplatetListQueryVariables>;
export const AuftragsartTemplateListForStornoSelectDocument = gql`
  query AuftragsartTemplateListForStornoSelect($stornoForType: AuftragsartType) {
    getAuftragsartTemplateList(includeArchiviert: true, stornoForType: $stornoForType) {
      data {
        auftragsartId
        bezeichnung
        status {
          text
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useAuftragsartTemplateListForStornoSelectQuery(
  baseOptions?: Apollo.QueryHookOptions<AuftragsartTemplateListForStornoSelectQuery, AuftragsartTemplateListForStornoSelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuftragsartTemplateListForStornoSelectQuery, AuftragsartTemplateListForStornoSelectQueryVariables>(
    AuftragsartTemplateListForStornoSelectDocument,
    options
  );
}
export function useAuftragsartTemplateListForStornoSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuftragsartTemplateListForStornoSelectQuery, AuftragsartTemplateListForStornoSelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuftragsartTemplateListForStornoSelectQuery, AuftragsartTemplateListForStornoSelectQueryVariables>(
    AuftragsartTemplateListForStornoSelectDocument,
    options
  );
}
export function useAuftragsartTemplateListForStornoSelectSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AuftragsartTemplateListForStornoSelectQuery, AuftragsartTemplateListForStornoSelectQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuftragsartTemplateListForStornoSelectQuery, AuftragsartTemplateListForStornoSelectQueryVariables>(
    AuftragsartTemplateListForStornoSelectDocument,
    options
  );
}
export type AuftragsartTemplateListForStornoSelectQueryHookResult = ReturnType<typeof useAuftragsartTemplateListForStornoSelectQuery>;
export type AuftragsartTemplateListForStornoSelectLazyQueryHookResult = ReturnType<typeof useAuftragsartTemplateListForStornoSelectLazyQuery>;
export type AuftragsartTemplateListForStornoSelectSuspenseQueryHookResult = ReturnType<typeof useAuftragsartTemplateListForStornoSelectSuspenseQuery>;
export type AuftragsartTemplateListForStornoSelectQueryResult = Apollo.QueryResult<
  AuftragsartTemplateListForStornoSelectQuery,
  AuftragsartTemplateListForStornoSelectQueryVariables
>;
export const AuftragsartTemplateDocument = gql`
  query AuftragsartTemplate($auftragsartId: ID!) {
    getAuftragsartTemplate(auftragsartId: $auftragsartId) {
      data {
        ...AuftragsartFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AuftragsartFieldsFragmentDoc}
`;
export function useAuftragsartTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<AuftragsartTemplateQuery, AuftragsartTemplateQueryVariables> &
    ({ variables: AuftragsartTemplateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuftragsartTemplateQuery, AuftragsartTemplateQueryVariables>(AuftragsartTemplateDocument, options);
}
export function useAuftragsartTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuftragsartTemplateQuery, AuftragsartTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuftragsartTemplateQuery, AuftragsartTemplateQueryVariables>(AuftragsartTemplateDocument, options);
}
export function useAuftragsartTemplateSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AuftragsartTemplateQuery, AuftragsartTemplateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuftragsartTemplateQuery, AuftragsartTemplateQueryVariables>(AuftragsartTemplateDocument, options);
}
export type AuftragsartTemplateQueryHookResult = ReturnType<typeof useAuftragsartTemplateQuery>;
export type AuftragsartTemplateLazyQueryHookResult = ReturnType<typeof useAuftragsartTemplateLazyQuery>;
export type AuftragsartTemplateSuspenseQueryHookResult = ReturnType<typeof useAuftragsartTemplateSuspenseQuery>;
export type AuftragsartTemplateQueryResult = Apollo.QueryResult<AuftragsartTemplateQuery, AuftragsartTemplateQueryVariables>;
export const AuftragsartTemplateGeneralChangeHistoryListDocument = gql`
  query AuftragsartTemplateGeneralChangeHistoryList($auftragsartId: ID!) {
    getAuftragsartTemplateGeneralChangeHistoryList(auftragsartId: $auftragsartId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useAuftragsartTemplateGeneralChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<AuftragsartTemplateGeneralChangeHistoryListQuery, AuftragsartTemplateGeneralChangeHistoryListQueryVariables> &
    ({ variables: AuftragsartTemplateGeneralChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuftragsartTemplateGeneralChangeHistoryListQuery, AuftragsartTemplateGeneralChangeHistoryListQueryVariables>(
    AuftragsartTemplateGeneralChangeHistoryListDocument,
    options
  );
}
export function useAuftragsartTemplateGeneralChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuftragsartTemplateGeneralChangeHistoryListQuery,
    AuftragsartTemplateGeneralChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuftragsartTemplateGeneralChangeHistoryListQuery, AuftragsartTemplateGeneralChangeHistoryListQueryVariables>(
    AuftragsartTemplateGeneralChangeHistoryListDocument,
    options
  );
}
export function useAuftragsartTemplateGeneralChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AuftragsartTemplateGeneralChangeHistoryListQuery, AuftragsartTemplateGeneralChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuftragsartTemplateGeneralChangeHistoryListQuery, AuftragsartTemplateGeneralChangeHistoryListQueryVariables>(
    AuftragsartTemplateGeneralChangeHistoryListDocument,
    options
  );
}
export type AuftragsartTemplateGeneralChangeHistoryListQueryHookResult = ReturnType<typeof useAuftragsartTemplateGeneralChangeHistoryListQuery>;
export type AuftragsartTemplateGeneralChangeHistoryListLazyQueryHookResult = ReturnType<
  typeof useAuftragsartTemplateGeneralChangeHistoryListLazyQuery
>;
export type AuftragsartTemplateGeneralChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useAuftragsartTemplateGeneralChangeHistoryListSuspenseQuery
>;
export type AuftragsartTemplateGeneralChangeHistoryListQueryResult = Apollo.QueryResult<
  AuftragsartTemplateGeneralChangeHistoryListQuery,
  AuftragsartTemplateGeneralChangeHistoryListQueryVariables
>;
export const AuftragsartTemplateTexteChangeHistoryListDocument = gql`
  query AuftragsartTemplateTexteChangeHistoryList($auftragsartId: ID!) {
    getAuftragsartTemplateTexteChangeHistoryList(auftragsartId: $auftragsartId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useAuftragsartTemplateTexteChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<AuftragsartTemplateTexteChangeHistoryListQuery, AuftragsartTemplateTexteChangeHistoryListQueryVariables> &
    ({ variables: AuftragsartTemplateTexteChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuftragsartTemplateTexteChangeHistoryListQuery, AuftragsartTemplateTexteChangeHistoryListQueryVariables>(
    AuftragsartTemplateTexteChangeHistoryListDocument,
    options
  );
}
export function useAuftragsartTemplateTexteChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuftragsartTemplateTexteChangeHistoryListQuery, AuftragsartTemplateTexteChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuftragsartTemplateTexteChangeHistoryListQuery, AuftragsartTemplateTexteChangeHistoryListQueryVariables>(
    AuftragsartTemplateTexteChangeHistoryListDocument,
    options
  );
}
export function useAuftragsartTemplateTexteChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AuftragsartTemplateTexteChangeHistoryListQuery, AuftragsartTemplateTexteChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuftragsartTemplateTexteChangeHistoryListQuery, AuftragsartTemplateTexteChangeHistoryListQueryVariables>(
    AuftragsartTemplateTexteChangeHistoryListDocument,
    options
  );
}
export type AuftragsartTemplateTexteChangeHistoryListQueryHookResult = ReturnType<typeof useAuftragsartTemplateTexteChangeHistoryListQuery>;
export type AuftragsartTemplateTexteChangeHistoryListLazyQueryHookResult = ReturnType<typeof useAuftragsartTemplateTexteChangeHistoryListLazyQuery>;
export type AuftragsartTemplateTexteChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useAuftragsartTemplateTexteChangeHistoryListSuspenseQuery
>;
export type AuftragsartTemplateTexteChangeHistoryListQueryResult = Apollo.QueryResult<
  AuftragsartTemplateTexteChangeHistoryListQuery,
  AuftragsartTemplateTexteChangeHistoryListQueryVariables
>;
