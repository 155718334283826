import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  mapTextbausteinListToFormValues,
  TextbausteinFormValues,
  TextbausteinModel,
} from '../../../../../../components/Template/PDFTemplates/templateMapper';
import { showSuccessMsgCreate } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../../../constants/andromedaSystemSettingsUriPaths';
import { useCreateWeAbrechnungsdefinitionTemplateMutation } from '../../../../gql/WeAbrDef/WeAbrDefTemplateMutations.types';
import { useCreateBkAbrechnungsdefinitionTemplateMutation } from '../../../../gql/BkAbrDef/BkAbrDefTemplateMutations.types';
import { AbrechnungsdefinitionType } from '../../../../../../types';
import { AbrDefAbrKreisListingFormValues } from '../../../../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormMapper';
import {
  BkOrWeAbrDefObjektOutputOptionsFieldHelpNames,
  BkOrWeAbrDefTopOutputOptionsFieldHelpNames,
} from '../../../../../Abrechnungsdefinition/abrDefFieldHelpHelpers/abrDefFieldHelpMapper';
import { AbrechnungsdefinitionParameterFormValues } from '../../../../../Abrechnungsdefinition/Form/Create/abrDefParameterFormMapper';
import {
  objAbrDefGeneralSettingsFormInitialValues,
  ObjAbrDefGeneralSettingsFormValues,
} from '../../../../../Abrechnungsdefinition/shared/Templates/shared/BkOrWe/ObjAbrDefGeneralSettingsForm/objAbrDefGeneralSettingsFormMapper';
import {
  mapTopAbrDefGeneralSettingsToInitialValues,
  TopAbrDefGeneralSettingsFormValues,
} from '../../../../../Abrechnungsdefinition/shared/Templates/shared/BkOrWe/TopAbrDefGeneralSettingsForm/topAbrDefGeneralSettingsFormMapper';
import { isAbrDefTypeBkAbrechnung } from '../../../../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import { mapFormValuesToBkOrWeAbrechnungsdefinitionCreate } from '../../../../../Abrechnungsdefinition/Form/Create/abrDefCreateFormMapper';
import BkAbrDefTemplates from '../../BkTemplates/BkAbrDefTemplates';
import WeAbrDefTemplates from '../../WeTemplates/WeAbrDefTemplates';

type Props = {
  parameterFormValues: AbrechnungsdefinitionParameterFormValues;
  abrKreisListFormValues: AbrDefAbrKreisListingFormValues;
  objektAbrechnungTextbausteinList: TextbausteinModel[];
  topAbrechnungTextbausteinList: TextbausteinModel[];
  validFromFormValue: string;
  setIsValidFromValid: (validFromValid: boolean) => void;
  onBack: () => void;
  fieldHelpNames?: {
    objekt?: BkOrWeAbrDefObjektOutputOptionsFieldHelpNames;
    top?: BkOrWeAbrDefTopOutputOptionsFieldHelpNames;
  };
};

const BkOrWeAbrDefTemplatesForm: FC<Props> = ({
  parameterFormValues,
  abrKreisListFormValues,
  objektAbrechnungTextbausteinList,
  topAbrechnungTextbausteinList,
  validFromFormValue,
  setIsValidFromValid,
  onBack,
  fieldHelpNames,
}) => {
  const entity = 'Abrechnungsdefinition';
  const navigate = useNavigate();

  // BK and WE
  const [objAbrDefTemplateValues, setObjAbrDefTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(objektAbrechnungTextbausteinList)
  );
  const [topAbrDefTemplateValues, setTopAbrDefTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(topAbrechnungTextbausteinList)
  );
  const [objAbrDefGeneralSettingsValues, setObjAbrDefGeneralSettingsValues] = useState<ObjAbrDefGeneralSettingsFormValues>(
    objAbrDefGeneralSettingsFormInitialValues
  );
  const [topAbrDefGeneralSettingsValues, setTopAbrDefGeneralSettingsValues] = useState<TopAbrDefGeneralSettingsFormValues>(
    mapTopAbrDefGeneralSettingsToInitialValues(
      isAbrDefTypeBkAbrechnung(parameterFormValues.type) ? AbrechnungsdefinitionType.BkAbrechnung : AbrechnungsdefinitionType.WeAbrechnung
    )
  );
  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  const baseOptions = {
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      navigate(URI_ANDROMEDA_SYS_SETTINGS.abrechnungsdefinitionPage);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  };

  const [runCreateWe, { loading: loadingCreateWe }] = useCreateWeAbrechnungsdefinitionTemplateMutation(baseOptions);
  const [runCreateBk, { loading: loadingCreateBk }] = useCreateBkAbrechnungsdefinitionTemplateMutation(baseOptions);

  if (parameterFormValues.type === AbrechnungsdefinitionType.BkAbrechnung) {
    return (
      <BkAbrDefTemplates
        bkObjektAbrTemplateValues={objAbrDefTemplateValues}
        onBkObjektAbrDefTemplateChange={setObjAbrDefTemplateValues}
        bkTopAbrTemplateValues={topAbrDefTemplateValues}
        onBkTopAbrDefTemplateChange={setTopAbrDefTemplateValues}
        objAbrDefGeneralSettingsValues={objAbrDefGeneralSettingsValues}
        onObjAbrDefGeneralSettingsChange={setObjAbrDefGeneralSettingsValues}
        topAbrDefGeneralSettingsValues={topAbrDefGeneralSettingsValues}
        onTopAbrDefGeneralSettingsChange={setTopAbrDefGeneralSettingsValues}
        setIsFormValid={setIsFormValid}
        onSubmit={() => {
          if (!validFromFormValue) {
            setIsValidFromValid(false);
            return;
          }
          if (!isFormValid) return;

          runCreateBk({
            variables: {
              input: mapFormValuesToBkOrWeAbrechnungsdefinitionCreate(
                parameterFormValues,
                abrKreisListFormValues,
                objAbrDefTemplateValues,
                topAbrDefTemplateValues,
                validFromFormValue,
                objAbrDefGeneralSettingsValues,
                topAbrDefGeneralSettingsValues
              ),
            },
          });
        }}
        isSubmitting={loadingCreateBk}
        onBack={onBack}
        fieldHelpNames={{ objekt: fieldHelpNames?.objekt, top: fieldHelpNames?.top }}
      />
    );
  }

  if (parameterFormValues.type === AbrechnungsdefinitionType.WeAbrechnung) {
    return (
      <WeAbrDefTemplates
        weObjektAbrTemplateValues={objAbrDefTemplateValues}
        onWeObjektAbrDefTemplateChange={setObjAbrDefTemplateValues}
        weTopAbrTemplateValues={topAbrDefTemplateValues}
        onWeTopAbrDefTemplateChange={setTopAbrDefTemplateValues}
        objAbrDefGeneralSettingsValues={objAbrDefGeneralSettingsValues}
        onObjAbrDefGeneralSettingsChange={setObjAbrDefGeneralSettingsValues}
        topAbrDefGeneralSettingsValues={topAbrDefGeneralSettingsValues}
        onTopAbrDefGeneralSettingsChange={setTopAbrDefGeneralSettingsValues}
        setIsFormValid={setIsFormValid}
        onSubmit={() => {
          if (!validFromFormValue) {
            setIsValidFromValid(false);
            return;
          }
          if (!isFormValid) return;

          runCreateWe({
            variables: {
              input: mapFormValuesToBkOrWeAbrechnungsdefinitionCreate(
                parameterFormValues,
                abrKreisListFormValues,
                objAbrDefTemplateValues,
                topAbrDefTemplateValues,
                validFromFormValue,
                objAbrDefGeneralSettingsValues,
                topAbrDefGeneralSettingsValues
              ),
            },
          });
        }}
        isSubmitting={loadingCreateWe}
        onBack={onBack}
        fieldHelpNames={{ objekt: fieldHelpNames?.objekt, top: fieldHelpNames?.top }}
      />
    );
  }

  return <></>;
};

export default BkOrWeAbrDefTemplatesForm;
