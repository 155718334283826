import { FormFields } from '../../../helpers/formikHelper';
import { Aufteilungsschluessel, AufteilungsschluesselCreateInput, AufteilungsschluesselUpdateInput } from '../../../types';

export interface AufteilungsschluesselFormValues {
  kurzBezeichnung: string;
  bezeichnung: string;
  masseinheit: string;
  bezugsbasis: string;
  firmendatenId: string;
}

export const aufteilungsschluesselFormInitialValues: AufteilungsschluesselFormValues = {
  kurzBezeichnung: '',
  bezeichnung: '',
  masseinheit: '',
  bezugsbasis: '',
  firmendatenId: '',
};

export const aufteilungsschluesselFormFields: FormFields<AufteilungsschluesselFormValues> = {
  bezeichnung: 'bezeichnung',
  bezugsbasis: 'bezugsbasis',
  firmendatenId: 'firmendatenId',
  kurzBezeichnung: 'kurzBezeichnung',
  masseinheit: 'masseinheit',
};

export const mapAufteilungsschluesselToFormValues = (aufteilungsschluessel?: Aufteilungsschluessel): AufteilungsschluesselFormValues => {
  if (!aufteilungsschluessel) {
    return aufteilungsschluesselFormInitialValues;
  }
  return {
    kurzBezeichnung: aufteilungsschluessel.kurzBezeichnung,
    bezeichnung: aufteilungsschluessel.bezeichnung,
    masseinheit: aufteilungsschluessel.masseinheit.value,
    bezugsbasis: aufteilungsschluessel.bezugsbasis.value,
    firmendatenId: aufteilungsschluessel.firmendatenId ?? '',
  };
};

export const mapFormValuesToAufteilungsschluessel = (
  formValues: AufteilungsschluesselFormValues
): AufteilungsschluesselCreateInput | AufteilungsschluesselUpdateInput => ({
  kurzBezeichnung: formValues.kurzBezeichnung,
  bezeichnung: formValues.bezeichnung,
  masseinheit: formValues.masseinheit,
  bezugsbasis: formValues.bezugsbasis,
});
