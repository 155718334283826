import { Dayjs } from 'dayjs';

import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { Objekt, ObjektCreateInput, ObjektUpdateInput, Verwaltungsart } from '../../../../types';
import {
  PostofficeBoxAddressFormValues,
  StreetAddressFormValues,
  StreetAddressModel,
} from '../../../../shared/components/Address/AddressSharedModel';
import {
  addressListFormPartInitialValues,
  mapAddressListToFormValues,
  mapFormValuesToAddressList,
} from '../../AddressListFormPart/addressListFormPartMapper';

export interface ObjektFormValues {
  kurzBezeichnung: string;
  verwaltungsart?: Verwaltungsart;
  rechnungsAusstellerId: string;
  sachbearbeiterId?: string;
  verwaltungSeit: string | Dayjs;
  addressList?: Array<StreetAddressFormValues | PostofficeBoxAddressFormValues>;
  tempShowAddressFormPartItem: boolean;
  tagList: string[];
  verwaltungBis?: string | Dayjs | null;
  rechnungsAusstellerBezeichnung?: string | null;
}

export const objektFormFields: FormFields<ObjektFormValues> = {
  kurzBezeichnung: 'kurzBezeichnung',
  verwaltungsart: 'verwaltungsart',
  rechnungsAusstellerId: 'rechnungsAusstellerId',
  sachbearbeiterId: 'sachbearbeiterId',
  verwaltungSeit: 'verwaltungSeit',
  addressList: 'addressList',
  tempShowAddressFormPartItem: 'tempShowAddressFormPartItem',
  tagList: 'tagList',
  verwaltungBis: 'verwaltungBis',
  rechnungsAusstellerBezeichnung: 'rechnungsAusstellerBezeichnung',
};

export const objektFormInitialValues = {
  kurzBezeichnung: '',
  verwaltungsart: undefined,
  rechnungsAusstellerId: '',
  sachbearbeiterId: '',
  verwaltungSeit: '',
  ...addressListFormPartInitialValues(true),
  tagList: [],
};

export const mapObjektToFormValues = (objekt?: Objekt): ObjektFormValues => {
  if (!objekt) {
    return objektFormInitialValues;
  }
  return {
    kurzBezeichnung: objekt.kurzBezeichnung,
    verwaltungsart: objekt.verwaltungsart.value,
    rechnungsAusstellerId: objekt.rechnungsAussteller.rechtstraegerId,
    rechnungsAusstellerBezeichnung: objekt?.rechnungsAusstellerBezeichnung ?? objekt.rechnungsAussteller.kurzBezeichnung,
    sachbearbeiterId: objekt.sachbearbeiter?.mitarbeiterId,
    // addressList: ...mapAddressListToFormValues(objekt.addressList),
    verwaltungSeit: objekt.verwaltungSeit,
    tagList: objekt.tagList,
    verwaltungBis: objekt.verwaltungBis,
    ...mapAddressListToFormValues(objekt.addressList),
  };
};

export const mapFormValuesToObjektCreate = (values: ObjektFormValues): ObjektCreateInput => ({
  kurzBezeichnung: values.kurzBezeichnung,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  verwaltungsart: values.verwaltungsart!,
  rechnungsAusstellerId: values.rechnungsAusstellerId,
  // @ts-ignore // TODO: Removce ts-ignore after gql is pushed with changes for [EC-7366] Informationsschreiben versenden
  rechnungsAusstellerBezeichnung: values.rechnungsAusstellerBezeichnung,
  sachbearbeiterId: values.sachbearbeiterId,
  addressList: mapFormValuesToAddressList(values.addressList) as StreetAddressModel[],
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  verwaltungSeit: mapFormDateValueToDateString(values.verwaltungSeit)!,
  tagList: values.tagList,
});

export const mapFormValuesToObjektUpdate = (values: ObjektFormValues, objekt: Objekt): ObjektUpdateInput => ({
  kurzBezeichnung: values.kurzBezeichnung,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  verwaltungsart: values.verwaltungsart!,
  rechnungsAusstellerId: values.rechnungsAusstellerId,
  rechnungsAusstellerBezeichnung:
    objekt.rechnungsAussteller.kurzBezeichnung !== values.rechnungsAusstellerBezeichnung ? values.rechnungsAusstellerBezeichnung : undefined,
  sachbearbeiterId: values.sachbearbeiterId,
  // @ts-ignore FIXME: ObjektUpdate.addressList requires addressId => new mappers for this case?
  addressList: mapFormValuesToAddressList(values.addressList) as StreetAddressModel[],
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  verwaltungSeit: mapFormDateValueToDateString(values.verwaltungSeit)!,
  tagList: values.tagList,
  verwaltungBis: values.verwaltungBis ? mapFormDateValueToDateString(values.verwaltungBis) : null,
});
