import { FC } from 'react';
import { Typography } from 'antd';
import { verarbeitungPageAndMenuListTitles } from '../verarbeitungHelpers';
import { Spacer } from '../../../components/Grid';
import UstVomAufwandVerarbeitungEntryListing from './EntryListing/UstVomAufwandVerarbeitungEntryListing';
import { UstVomAufwandGenerierlauf } from '../../../types';

type VerarbeitungUstVomAufwandProps = {
  generierlauf: UstVomAufwandGenerierlauf;
};

const VerarbeitungUstVomAufwand: FC<VerarbeitungUstVomAufwandProps> = ({ generierlauf }) => (
  <>
    <Typography.Title level={4}>{verarbeitungPageAndMenuListTitles.UstVomAufwand}</Typography.Title>
    <Spacer />
    <UstVomAufwandVerarbeitungEntryListing generierlauf={generierlauf} />
  </>
);

export default VerarbeitungUstVomAufwand;
