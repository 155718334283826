import { FormikProps } from 'formik';
import { filtersFormFields, FiltersFormValues } from './filtersFormMapper';
import { LISTING_FILTER_HAS_CHANGED } from '../../../../components/StickyHeader/useOnEventsSetStickyFilterSummary';

export const isBaFilterBelegSymbolListSelected = (filter: string) => filter === filtersFormFields.belegSymbolList;
export const isBaFilterBelegdatumSelected = (filter: string) => filter === filtersFormFields.belegDatumFrom;
export const isBaFilterBelegnummerSelected = (filter: string) => filter === filtersFormFields.belegnummer;
export const isBaFilterBestandseinheitSelected = (filter: string) => filter === filtersFormFields.bestandseinheitId;
export const isBaFilterBuchungsTypeListSelected = (filter: string) => filter === filtersFormFields.buchungsTypeList;
export const isBaFilterBuchungsdatumSelected = (filter: string) => filter === filtersFormFields.buchungsdatumFrom;
export const isBaFilterCreateByMitarbeiterIdListSelected = (filter: string) => filter === filtersFormFields.createByMitarbeiterIdList;
export const isBaFilterCreateTsSelected = (filter: string) => filter === filtersFormFields.createTsFrom;
export const isBaFilterDueDateSelected = (filter: string) => filter === filtersFormFields.dueDateFrom;
export const isBaFilterFachlichePruefungSelected = (filter: string) => filter === filtersFormFields.fachlichePruefung;
export const isBaFilterHeVertragSelected = (filter: string) => filter === filtersFormFields.heVertragId;
export const isBaFilterObjektSelected = (filter: string) => filter === filtersFormFields.objektId;
export const isBaFilterRechtstraegerIdListSelected = (filter: string) => filter === filtersFormFields.rechtstraegerIdList;
export const isBaFilterStatusSelected = (filter: string) => filter === filtersFormFields.status;
export const isBaFilterSumBruttoSelected = (filter: string) => filter === filtersFormFields.sumBruttoFrom;
export const isBaFilterSumSteuerSelected = (filter: string) => filter === filtersFormFields.sumSteuerFrom;
export const isBaFilterVertragSelected = (filter: string) => filter === filtersFormFields.vertragId;
export const isBaFilterVertragspartnerListSelected = (filter: string) => filter === filtersFormFields.vertragspartnerIdList;
export const isBaFilterZuStornierendeBuchungIdSelected = (filter: string) => filter === filtersFormFields.zuStornierendeBuchungId;

// update formik values when a related filter gets removed from selected filters or it's value is changed
export const updateListingFilterValues = (formikProps: FormikProps<FiltersFormValues>, selectedFilterList: string[]) => {
  if (!selectedFilterList.includes(filtersFormFields.buchungsdatumFrom) && formikProps.values.buchungsdatumTo) {
    formikProps.setFieldValue(filtersFormFields.buchungsdatumTo, undefined);
  }
  if (!selectedFilterList.includes(filtersFormFields.createTsFrom) && formikProps.values.createTsTo) {
    formikProps.setFieldValue(filtersFormFields.createTsTo, undefined);
  }
  if (!selectedFilterList.includes(filtersFormFields.dueDateFrom) && formikProps.values.dueDateTo) {
    formikProps.setFieldValue(filtersFormFields.dueDateTo, undefined);
  }
  if (!selectedFilterList.includes(filtersFormFields.sumBruttoFrom) && formikProps.values.sumBruttoTo) {
    formikProps.setFieldValue(filtersFormFields.sumBruttoTo, undefined);
  }
  if (!selectedFilterList.includes(filtersFormFields.sumSteuerFrom) && formikProps.values.sumSteuerTo) {
    formikProps.setFieldValue(filtersFormFields.sumSteuerTo, undefined);
  }
  // reset bestandseinheitId, vertragId and heVertragId if objektId is changed
  if (
    !selectedFilterList.includes(filtersFormFields.objektId) &&
    (formikProps.values.bestandseinheitId || formikProps.values.vertragId || formikProps.values.heVertragId)
  ) {
    formikProps.setFieldValue(filtersFormFields.bestandseinheitId, undefined);
    formikProps.setFieldValue(filtersFormFields.vertragId, undefined);
    formikProps.setFieldValue(filtersFormFields.heVertragId, undefined);
  }
  // reset vertragId if bestandseinheitId is changed
  if (!selectedFilterList.includes(filtersFormFields.bestandseinheitId) && formikProps.values.vertragId) {
    formikProps.setFieldValue(filtersFormFields.vertragId, undefined);
  }
  formikProps.submitForm();
  document.dispatchEvent(new CustomEvent(LISTING_FILTER_HAS_CHANGED));
};
