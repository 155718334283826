import React, { FC, useState } from 'react';
import { Divider, Radio, Space } from 'antd';
import { RechtstraegerType } from '../../../types';
import StreetAddressCreateForm from '../../Rechtstraeger/AddressesAndContacts/Form/StreetAddress/Create/StreetAddressCreateForm';
import PostofficeBoxAddressCreateForm from '../../Rechtstraeger/AddressesAndContacts/Form/PostofficeBoxAddress/Create/PostofficeBoxAddressCreateForm';

type Props = {
  onCancel: () => void;
  onSuccess: (addresseId: string) => void;
  rechtstraegerType: RechtstraegerType;
  rechtstraegerId?: string;
};

enum AddressAndContactType {
  STREET_ADDRESS,
  POSTOFFICE_BOX_ADDRESS,
}

const AddressCreateForm: FC<Props> = ({ onCancel, onSuccess, rechtstraegerId, rechtstraegerType }) => {
  const [type, setType] = useState<AddressAndContactType>();

  let content;

  if (type === AddressAndContactType.STREET_ADDRESS) {
    content = (
      <StreetAddressCreateForm rechtstraegerId={rechtstraegerId} onSuccess={onSuccess} onCancel={onCancel} rechtstraegerType={rechtstraegerType} />
    );
  }
  if (type === AddressAndContactType.POSTOFFICE_BOX_ADDRESS) {
    content = (
      <PostofficeBoxAddressCreateForm
        rechtstraegerId={rechtstraegerId}
        onSuccess={onSuccess}
        onCancel={onCancel}
        rechtstraegerType={rechtstraegerType}
      />
    );
  }

  return (
    <>
      <Space style={{ width: '100%', marginBottom: '16px', justifyContent: 'center' }}>
        <Radio.Group buttonStyle="solid" onChange={(event) => setType(event.target.value)} block>
          <Radio.Button value={AddressAndContactType.STREET_ADDRESS}>Strassenadresse</Radio.Button>
          <Radio.Button value={AddressAndContactType.POSTOFFICE_BOX_ADDRESS}>Postfachadresse</Radio.Button>
        </Radio.Group>
      </Space>
      <Divider />
      {content}
    </>
  );
};

export default AddressCreateForm;
