import React, { FC } from 'react';
import { Tooltip } from 'antd';
import { CaretDownOutlined, CaretRightOutlined, CaretUpOutlined } from '@ant-design/icons';
import { TicketPriority, TicketPriorityTuple } from '../../../types';

type Props = {
  priority: TicketPriorityTuple;
  style?: React.CSSProperties;
};

const TicketPriorityIcon: FC<Props> = ({ priority, style }) => {
  let icon = null;

  switch (priority.value) {
    case TicketPriority.Low:
      icon = <CaretDownOutlined style={{ color: '#52c41a', ...style }} />;
      break;
    case TicketPriority.Medium:
      icon = <CaretRightOutlined style={{ color: '#1677ff', ...style }} />;
      break;
    case TicketPriority.High:
      icon = <CaretUpOutlined style={{ color: '#ff4d4f', ...style }} />;
      break;
  }

  return icon ? <Tooltip title={priority.text}>{icon}</Tooltip> : null;
};

export default TicketPriorityIcon;
