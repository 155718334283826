import React, { FC } from 'react';
import { MenuProps } from 'antd';
import { DeleteOutlined, EditOutlined, FileDoneOutlined, HistoryOutlined } from '@ant-design/icons';
import { radixActionStyles } from '../../../../../helpers/radixIconsStyles';
import {
  useApprovePaymentMutation,
  useChangeToDatentraegerErzeugtPaymentMutation,
  useChangeToDurchfuehrbarPaymentMutation,
  useChangeToNichtDurchfuehrbarPaymentMutation,
  useDeletePaymentMutation,
} from '../../../gql/Order/PaymentMutations.types';
import { showSuccessMsgDelete, showSuccessMsgOther } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { PaymentListEntry } from '../../../../../types';
import { showSuccessMsgFreigegeben } from '../../../../../components/message/message';
import ActionDropdown from '../../../../../components/Dropdown/ActionDropdown';
import {
  iconForChangeStatusAction,
  IconForLinkToDetails,
  isEditingEnabled,
  labelForChangeStatusAction,
  LinkToPaymentDetails,
  onChangePaymentStatusAction,
  shouldShowChangeStatusAction,
  showConfirmApprove,
  showConfirmChangeToDatentraegerErzeugt,
  showConfirmChangeToDurchfuehrbar,
  showConfirmChangeToNichtDurchfuehrbar,
  showConfirmDelete,
} from './PaymentListingTableActionsHelpers';
import { useToggle } from '../../../../../hooks/useToggle';
import HistoryModal, { HistoryType } from '../../../../History/shared/HistoryModal';
import PaymentChangeHistoryListingTable from './PaymentChangeHistoryListingTable';
import ChangeToNichtDurchgefuehrtModal from './ChangeToNichtDurchgefuehrtModal/ChangeToNichtDurchgefuehrtModal';
//import { useEditableTable } from '../../../../../components/Table/EditableTableOld';
import { useEditableTable } from '../../../../../components/Table/EditableTableV2/editableTableContext';

type Props = {
  payment: PaymentListEntry;
  onSuccess: () => void;
};

const PaymentListingTableActions: FC<Props> = ({ payment, onSuccess }) => {
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();
  const [isChangeToNichtDurchgefuehrtModalOpen, toggleChangeToNichtDurchgefuehrtModalOpen] = useToggle();

  const { onUpdate } = useEditableTable<PaymentListEntry>();

  const entity = 'Zahlung';
  const { paymentId } = payment;

  const [runChangeToDurchfuehrbar] = useChangeToDurchfuehrbarPaymentMutation({
    variables: { paymentId },
    onCompleted: () => {
      showSuccessMsgOther('Status auf "Durchführbar" geändert');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runChangeToNichtDurchfuehrbar] = useChangeToNichtDurchfuehrbarPaymentMutation({
    variables: { paymentId },
    onCompleted: () => {
      showSuccessMsgOther('Status auf "Nicht Durchführbar" geändert');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runChangeToDatentraegerErzeugt] = useChangeToDatentraegerErzeugtPaymentMutation({
    variables: { paymentId },
    onCompleted: () => {
      showSuccessMsgOther('Status auf "Datenträger erzeugt (Onlinebanking)" geändert');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runDelete] = useDeletePaymentMutation({
    variables: { paymentId },
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runApprove] = useApprovePaymentMutation({
    variables: { paymentId },
    onCompleted: () => {
      showSuccessMsgFreigegeben(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    shouldShowChangeStatusAction(payment)
      ? {
          key: '1',
          label: labelForChangeStatusAction(payment),
          onClick: () =>
            onChangePaymentStatusAction(payment, {
              changeToDurchfuehrbar: showConfirmChangeToDurchfuehrbar(runChangeToDurchfuehrbar),
              changeToNichtDurchfuehrbar: showConfirmChangeToNichtDurchfuehrbar(runChangeToNichtDurchfuehrbar),
              changeToDatentraegerErzeugt: showConfirmChangeToDatentraegerErzeugt(runChangeToDatentraegerErzeugt),
              changeToNichtDurchgefuehrt: toggleChangeToNichtDurchgefuehrtModalOpen,
            }),
          icon: iconForChangeStatusAction(payment),
        }
      : null,
    {
      key: '2',
      label: 'Freigeben',
      onClick: showConfirmApprove(runApprove),
      icon: <FileDoneOutlined />,
      disabled: !payment.approvable,
    },
    {
      key: '3',
      label: 'Bearbeiten',
      onClick: () => onUpdate(payment),
      disabled: !isEditingEnabled(payment.status.value),
      icon: <EditOutlined />,
    },
    {
      key: '4',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '5',
      label: LinkToPaymentDetails(payment),
      icon: IconForLinkToDetails(payment.paymentType.value),
    },
    {
      key: '6',
      label: 'Löschen',
      icon: <DeleteOutlined />,
      onClick: showConfirmDelete(runDelete),
      disabled: !payment.deletable,
      danger: true,
    },
  ];

  return (
    <>
      <ActionDropdown items={items} />
      <ChangeToNichtDurchgefuehrtModal
        isOpen={isChangeToNichtDurchgefuehrtModalOpen}
        paymentId={payment.paymentId}
        onCancel={toggleChangeToNichtDurchgefuehrtModalOpen}
        onSubmit={() => {
          toggleChangeToNichtDurchgefuehrtModalOpen();
          onSuccess();
        }}
      />
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <PaymentChangeHistoryListingTable paymentId={paymentId} />
      </HistoryModal>
    </>
  );
};

export default PaymentListingTableActions;
