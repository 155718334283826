import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { KontoFieldsFragmentDoc } from '../../../../features/Kontenplan/gql/KontenplanFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VertragspartnerKontoListQueryVariables = Types.Exact<{
  buchungskreisId: Types.Scalars['ID']['input'];
}>;

export type VertragspartnerKontoListQuery = {
  getVertragspartnerKontoList: {
    data: Array<{
      kurzBezeichnung: string;
      rechtstraegerId: string;
      konto: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        haupt: boolean;
        kontoId: string;
        kontoTemplateId?: string | null;
        nummer: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        klasse: { text: string; value: Types.KontoKlasse };
        status: { description?: string | null; text: string; value: Types.KontoStatus };
        type: { text: string; value: Types.KontoType };
        verwendung?: { text: string; value: Types.KontoVerwendung } | null;
      };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const VertragspartnerKontoListDocument = gql`
  query VertragspartnerKontoList($buchungskreisId: ID!) {
    getVertragspartnerKontoList(buchungskreisId: $buchungskreisId) {
      data {
        konto {
          ...KontoFields
        }
        kurzBezeichnung
        rechtstraegerId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KontoFieldsFragmentDoc}
`;
export function useVertragspartnerKontoListQuery(
  baseOptions: Apollo.QueryHookOptions<VertragspartnerKontoListQuery, VertragspartnerKontoListQueryVariables> &
    ({ variables: VertragspartnerKontoListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VertragspartnerKontoListQuery, VertragspartnerKontoListQueryVariables>(VertragspartnerKontoListDocument, options);
}
export function useVertragspartnerKontoListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VertragspartnerKontoListQuery, VertragspartnerKontoListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VertragspartnerKontoListQuery, VertragspartnerKontoListQueryVariables>(VertragspartnerKontoListDocument, options);
}
export function useVertragspartnerKontoListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VertragspartnerKontoListQuery, VertragspartnerKontoListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VertragspartnerKontoListQuery, VertragspartnerKontoListQueryVariables>(VertragspartnerKontoListDocument, options);
}
export type VertragspartnerKontoListQueryHookResult = ReturnType<typeof useVertragspartnerKontoListQuery>;
export type VertragspartnerKontoListLazyQueryHookResult = ReturnType<typeof useVertragspartnerKontoListLazyQuery>;
export type VertragspartnerKontoListSuspenseQueryHookResult = ReturnType<typeof useVertragspartnerKontoListSuspenseQuery>;
export type VertragspartnerKontoListQueryResult = Apollo.QueryResult<VertragspartnerKontoListQuery, VertragspartnerKontoListQueryVariables>;
