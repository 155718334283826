import React, { FC } from 'react';
import { Col, Row, Tabs } from 'antd';
import NotYetTitledCard from './NotYetTitledCard';
import RechnungseinstellungenCard from './RechnungseinstellungenCard';
import MahneinstellungenCard from './MahneinstellungenCard';
import { Spacer } from '../../../components/Grid';
import TabPaneContent from '../../../components/Tabs/TabPaneContent';

type Props = {
  contact: any;
};
const Rechnungsaussteller: FC<Props> = ({ contact }) => {
  const items = [
    {
      key: '1',
      label: 'Stammdaten',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <Row justify="start">
            <NotYetTitledCard contact={contact} />
          </Row>
          <Spacer height={16} />
          <Row justify="start">
            <RechnungseinstellungenCard contact={contact} />
          </Row>
          <Spacer height={16} />
          <Row justify="start">
            <MahneinstellungenCard contact={contact} />
          </Row>
        </TabPaneContent>
      ),
    },
    {
      key: '2',
      label: 'Kontierung',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <Row justify="space-between" align="middle">
            <Col>Lorem ipsum</Col>
          </Row>
        </TabPaneContent>
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default Rechnungsaussteller;
