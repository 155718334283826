import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { friendlyFormatIBAN } from 'ibantools';
import { Typography } from 'antd';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import { useDataCarrierCamtIbanListQuery } from '../../../gql/DataCarrierQueries.types';

type Props = SelectProps;

const IbanListFormSelect: FC<Props> = ({ ...restProps }) => {
  const { loading, data } = useDataCarrierCamtIbanListQuery();
  const list = data?.getDataCarrierCamtIbanList.data ?? [];

  return (
    <Select
      {...restProps}
      size="small"
      id={restProps.name}
      loading={loading}
      placeholder="IBAN auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {list.map((iban) => (
        <Select.Option key={iban} value={iban}>
          <Typography.Text>{friendlyFormatIBAN(iban)}</Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export default IbanListFormSelect;
