import React from 'react';
import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../../components/Template/DetailsPageTemplate';
import menuListBelegErstellen from './menuListEigenbelegErstellen';
import { VerarbeitungBelegErstellenDetailsPageRouteParams } from '../routes';
import VerarbeitungDetailsPageCard from '../../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../../features/Verarbeitung/verarbeitungHelpers';
import { useEigenbelegErstellenGenerierlaufQuery } from '../../../../features/Verarbeitung/gql/BelegGenerierlauf/BelegGenerierlaufQueries.types';
import { EigenbelegErstellenGenerierlauf } from '../../../../types';
import { useRestartEigenbelegErstellenGenerierlaufMutation } from '../../../../features/Verarbeitung/gql/BelegGenerierlauf/BelegGenerierlaufMutations.types';

const BelegErstellenDetailsPage = () => {
  const { generierlaufId } = useParams() as VerarbeitungBelegErstellenDetailsPageRouteParams;

  const { data, refetch } = useEigenbelegErstellenGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getEigenbelegErstellenGenerierlauf.data;

  const [runRestart] = useRestartEigenbelegErstellenGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<EigenbelegErstellenGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.VorschreibungAusgeben}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.VorschreibungAusgeben}
          onGenerierlaufRestart={runRestart}
        />
      )}
      sidebarMenuList={(generierlauf) => menuListBelegErstellen(generierlauf)}
    />
  );
};

export default BelegErstellenDetailsPage;
