import * as Yup from 'yup';
import { entityIsRequired } from '../../../../../../components/message/validationMsg';
import { isNotNil } from '../../../../../../helpers/assertionHelper';
import { versicherungUpdateFormFields } from '../Update/versicherungUpdateFormMapper';

/* eslint-disable no-template-curly-in-string */
export const versicherungValidationSchema = Yup.object().shape(
  {
    [versicherungUpdateFormFields.versicherungsart]: Yup.string().required(entityIsRequired('Versicherungsart')),
    [versicherungUpdateFormFields.kuendigungsfristDauer]: Yup.number().when(
      [versicherungUpdateFormFields.kuendigungsfrist],
      ([kuendigungfrist], schema) => (isNotNil(kuendigungfrist) ? schema.nullable().required('Dauer auswählen') : schema.nullable())
    ),
    [versicherungUpdateFormFields.kuendigungsfrist]: Yup.string().when(
      [versicherungUpdateFormFields.kuendigungsfristDauer],
      // eslint-disable-next-line no-extra-boolean-cast
      ([kuendigungfristDauer], schema) => (!!kuendigungfristDauer ? schema.nullable().required('Frist auswählen') : schema.nullable())
    ),
  },
  [[versicherungUpdateFormFields.kuendigungsfrist, versicherungUpdateFormFields.kuendigungsfristDauer]]
);
/* eslint-enable no-template-curly-in-string */
