import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { FormikProps } from 'formik';
import { Switch } from 'formik-antd';
import { indAgreeIndexAnnualFormFields, IndexationAgreementIndexAnnualFormValues } from './indexAnnualFormMapper';
import FormItemWithFieldHelp from '../../../../../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  formikProps: FormikProps<IndexationAgreementIndexAnnualFormValues>;
  onChange: (value: boolean) => void;
  fieldHelp?: string | null;
};

const AnnualAverageFormItem: FC<Props> = ({ formikProps, onChange, fieldHelp }) => {
  const annualAverageName = indAgreeIndexAnnualFormFields.annualAverage;

  return (
    <FormItemWithFieldHelp name={annualAverageName} label="Jahresdurchschnitt" fieldHelp={fieldHelp}>
      <Space>
        <Switch id={annualAverageName} name={annualAverageName} onChange={onChange} />
        <Typography.Text>{formikProps.values.annualAverage ? 'verwenden' : 'nicht verwenden'}</Typography.Text>
      </Space>
    </FormItemWithFieldHelp>
  );
};

export default AnnualAverageFormItem;
