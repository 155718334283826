import React, { FC } from 'react';
import { Menu, MenuProps } from 'antd';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import MenuItemIconLink, { MenuItemIconLinkState } from './MenuItemIconLink/MenuItemIconLink';
import { findSelectedMenuItemPath } from './contextSiderbarHelpers';

export interface MenuItemInterface {
  title: string;
  icon?: React.ReactNode;
  uri: string;
  content: (props: any, isCardCollapsed: boolean) => React.ReactNode;
  count?: number;
  state?: MenuItemIconLinkState;
}

export interface ContextMenuItemInterface extends MenuItemInterface {
  /**
   * exact value which can appear in contextList
   */
  contextKey: string[];
}

type ContextSidebarProps = {
  defaultSelected?: string;
  topMenuList: MenuItemInterface[];
  contextMenuList: ContextMenuItemInterface[];
  contextList?: string[];
  onCardCollapse: () => void;
  isCardCollapsed: boolean;
};

const ContextSidebar: FC<ContextSidebarProps> = ({
  defaultSelected,
  topMenuList,
  contextMenuList,
  onCardCollapse,
  isCardCollapsed,
  contextList = [],
}) => {
  const { pathname } = useLocation();

  const menueItemHome = defaultSelected ? topMenuList.find((item) => item.title === defaultSelected) : undefined;
  const itemsToDelete = defaultSelected ? [defaultSelected] : [];

  const menuItemsTop = topMenuList.filter((item) => !itemsToDelete.includes(item.title));
  const menuItemsContext = contextMenuList.filter((item) => item.contextKey.some((key) => contextList.includes(key)));

  const selectedMenuItemKey = findSelectedMenuItemPath([...topMenuList, ...contextMenuList], menueItemHome, pathname);

  const menuItemsTopMapped = menuItemsTop.map((item) => ({
    key: item.uri,
    label: <MenuItemIconLink title={item.title} icon={item.icon} uri={item.uri} state={item.state} />,
    title: item.title,
  }));

  const menuItemsContextMapped = menuItemsContext.map((item) => ({
    key: item.uri,
    label: <MenuItemIconLink title={item.title} icon={item.icon} uri={item.uri} state={item.state} />,
    title: item.title,
  }));

  const items: MenuProps['items'] = [
    menueItemHome
      ? {
          key: menueItemHome.uri,
          label: (
            <>
              <div className="circle-text blue">{menueItemHome.icon}</div>
              <Link to={menueItemHome.uri} data-testid={`context-menuItem-${menueItemHome.title}`} />
            </>
          ),
          title: menueItemHome.title,
          style: styleForHome,
        }
      : null,
    { type: 'divider' },
    ...menuItemsTopMapped,
    menuItemsTop.length !== 0 ? { type: 'divider' } : null,
    ...menuItemsContextMapped,
    menuItemsContext.length !== 0 ? { type: 'divider' } : null,
    {
      key: 'details',
      label: (
        <>
          {isCardCollapsed ? (
            <RightOutlined data-testid="context-menuItem-isCollapsed" />
          ) : (
            <LeftOutlined data-testid="context-menuItem-isCollapsed" />
          )}
          <span>Allgemeine Informationen {isCardCollapsed ? 'einblenden' : 'ausblenden'}</span>
        </>
      ),
      onClick: onCardCollapse,
      style: styleForDetails,
    },
  ];

  return <Menu mode="inline" selectedKeys={[selectedMenuItemKey]} items={items} />;
};

const styleForHome: React.CSSProperties = {
  margin: '4px',
  padding: '0 !important',
  textAlign: 'center',
};

const styleForDetails: React.CSSProperties = {
  background: 'transparent',
  marginTop: 0,
  marginBottom: 0,
  height: '48px',
  color: 'rgba(0, 0, 0, 0.65)',
  lineHeight: '48px',
  textAlign: 'center',
};

export default ContextSidebar;
