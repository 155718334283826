import { dayjsCustom } from '../../helpers/dayjsCustom';
import { Verwaltungsart } from '../../types';

export const isObjektAbgegeben = (verwaltungBis?: string | null) => {
  return verwaltungBis ? dayjsCustom().isAfter(dayjsCustom(verwaltungBis), 'day') : false;
};

export const isMietObjekt = (verwaltungsart: Verwaltungsart) => verwaltungsart === Verwaltungsart.MietObjekt;

export const isWegObjekt = (verwaltungsart: Verwaltungsart) => verwaltungsart === Verwaltungsart.WegObjekt;
