import * as Yup from 'yup';
import { entityIsRequired, entityMaxCharLength } from '../../../../../../components/message/validationMsg';
import { zimmerCreateFormFields } from '../Create/zimmerCreateFormMapper';

/* eslint-disable no-template-curly-in-string */
export const zimmerValidationSchema = Yup.object().shape({
  [zimmerCreateFormFields.bezeichnung]: Yup.string().required(entityIsRequired('Bezeichnung')).max(50, entityMaxCharLength('Text', 50)),
  [zimmerCreateFormFields.bodenbelag]: Yup.string().max(50, entityMaxCharLength('Bodenbelag', 50)),
  [zimmerCreateFormFields.energiekennzahl]: Yup.string().max(10, entityMaxCharLength('Energiekennzahl', 10)),
  [zimmerCreateFormFields.nummer]: Yup.string().max(10, entityMaxCharLength('Nummer', 10)),
  [zimmerCreateFormFields.flaechentyp]: Yup.string().max(50, entityMaxCharLength('Flächentyp', 50)),
  [zimmerCreateFormFields.heizung]: Yup.string().max(50, entityMaxCharLength('Heizung', 50)),
  [zimmerCreateFormFields.anmerkung]: Yup.string().max(500, entityMaxCharLength('Anmerkung', 500)),
});
/* eslint-enable no-template-curly-in-string */
