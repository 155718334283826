import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { TopAbrechnung } from '../../topAbrechnung-types';
import TAAbrKreisDetailsBezeichnung from './TAAbrKreisDetailsBezeichnung';
import TAAbrKreisDetailsColumnTitles from './TAAbrKreisDetailsColumnTitles';
import TAAbrKreisDetailsAbrechnungskreisBlock from './AbrechnungskreisBlock/TAAbrKreisDetailsAbrechnungskreisBlock';

const TAAbrechnungskreisDetailsBlock: FC<{ data: TopAbrechnung }> = ({ data }) => (
  <View>
    <Text style={[pdfStyles.textNormal, { fontSize: '12px', textAlign: 'center' }]}>{data.abrechnungskreisDetails.aufzaehlungsText}</Text>

    {/* Abrechnungskreis name */}
    <TAAbrKreisDetailsBezeichnung data={data} />

    {/* COLUMN_NAMES */}
    <TAAbrKreisDetailsColumnTitles data={data} />

    {/* Abrechnungskreis block */}
    <TAAbrKreisDetailsAbrechnungskreisBlock data={data} />
  </View>
);

export default TAAbrechnungskreisDetailsBlock;
