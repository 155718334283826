import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Empty, List, Table, Typography } from 'antd';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';
import VstKuerzungDetailsTable from '../Table/VstKuerzungDetailsTable';
import { isVstKuerzungBuchungStatusVerbucht } from '../../../helpers/statusHelper';
import { Spacer } from '../../../components/Grid';
import errorLogTableColumns from '../../Abrechnung/shared/errorLogTableColumns';
import { ErrorData, Vorsteuerkuerzung } from '../../../types';
import { generatePathToBookingDetailsPage } from '../../BookingDetails/bookingDetailsPaths';

type Props = {
  vstKuerzung: Vorsteuerkuerzung;
};

const VstKuerzungDetailsListing: FC<Props> = ({ vstKuerzung }) => {
  const { errorList: vstKuerzungErrorList } = vstKuerzung;

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <VstKuerzungAllBuchungLink vstKuerzung={vstKuerzung} />
      </ButtonsAligned>
      {vstKuerzungErrorList.length > 0 && (
        <>
          <Table<ErrorData>
            size="small"
            pagination={false}
            dataSource={vstKuerzungErrorList}
            rowKey={(record) => record.type}
            columns={errorLogTableColumns}
            locale={{
              emptyText: <Empty description={<span>Keine Fehler vorhanden</span>} />,
            }}
          />
          <Divider />
        </>
      )}
      {vstKuerzung.buchungErrorList?.length !== 0 && (
        <>
          <List<ErrorData>
            header={<Typography.Text>Es gab Fehler bei der Verbuchung:</Typography.Text>}
            dataSource={vstKuerzung.buchungErrorList}
            renderItem={(item) => (
              <List.Item>
                <Typography.Text type="danger" key={item.message}>
                  {item.message}
                </Typography.Text>
              </List.Item>
            )}
          />
          <Spacer height={16} />
        </>
      )}
      <VstKuerzungDetailsTable vstKuerzung={vstKuerzung} />
    </>
  );
};

type VstKuerzungAllBuchungListProps = {
  vstKuerzung: Vorsteuerkuerzung;
};

const VstKuerzungAllBuchungLink: FC<VstKuerzungAllBuchungListProps> = ({ vstKuerzung }) => {
  return isVstKuerzungBuchungStatusVerbucht(vstKuerzung.buchungStatus) ? (
    <Link to={generatePathToBookingDetailsPage(vstKuerzung.fibuBuchungIdList)}>Vst-Kürzungs-Buchungen anzeigen</Link>
  ) : null;
};

export default VstKuerzungDetailsListing;
