import React, { FC, useEffect } from 'react';
import { useField } from 'formik';
import { SelectProps } from 'formik-antd';
import { excludeArts } from '../../helpers/selectHelper';
import { EntityStatusFormSelect } from '../../components/Status/EntityStatus';
import { Vorschreibungsposition, VorschreibungspositionArt } from '../../types';
import { useVorschreibungspositionTemplateListQuery } from '../VorschreibungspositionTemplate/gql/VorschreibungspositionTemplateQueries.types';

type Props = {
  exludeArts?: VorschreibungspositionArt[] | undefined;
} & SelectProps;

const VorschreibungspositionTemplateSelect: FC<Props> = ({ name, exludeArts, ...restProps }) => {
  const [{ value: selectedId }, , { setValue: setSelectedId }] = useField<string>(name);

  const exludeArtList = exludeArts ?? [];

  const { loading, data } = useVorschreibungspositionTemplateListQuery({
    variables: {
      includeArchiviert: true,
    },
  });

  const list = data?.getVorschreibungspositionTemplateList.data ?? [];

  const filteredList = list.filter((item) => excludeArts(item, exludeArtList));

  // f_id changes => list changes => if there was already a selected entity it may not be available in the scope of newly selected f_id => clear entity
  useEffect(() => {
    if (!loading && selectedId && !filteredList.map((item) => item.vorschreibungspositionId).includes(selectedId)) {
      setSelectedId('');
    }
  }, [loading, filteredList, selectedId, setSelectedId]);

  return (
    <EntityStatusFormSelect<Vorschreibungsposition>
      list={filteredList}
      getSelectItemId={(vp) => vp.vorschreibungspositionId}
      getSelectItemContent={(vp) => vp.bezeichnung}
      getSelectItemTooltipTitle={(vp) => vp.bezeichnung}
      id="vorschreibungsposition"
      name={name}
      loading={loading}
      placeholder="Vorschreibungsposition auswählen"
      {...restProps}
    />
  );
};

export default VorschreibungspositionTemplateSelect;
