import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type AbrechnungskreisFieldsFragment = {
  abrechnungskreisId: string;
  bezeichnung: string;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  firmendatenId?: string | null;
  updatedByMitarbeiterId?: string | null;
  status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
  type: { text: string; value: Types.AbrechnungskreisType };
  verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const AbrechnungskreisFieldsFragmentDoc = gql`
  fragment AbrechnungskreisFields on Abrechnungskreis {
    abrechnungskreisId
    bezeichnung
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    firmendatenId
    status {
      description
      text
      value
    }
    type {
      text
      value
    }
    updatedByMitarbeiterId
    verrechnungUstVomAufwand {
      description
      text
      value
    }
    warningList {
      attribute
      message
      type
    }
  }
`;
