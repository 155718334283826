import { FormFields, mapFormDateValueToDateString } from '../../../helpers/formikHelper';
import { KontoblattPageQueryParams } from '../kontoblattUriPaths';
import { FibuBelegSymbol, FibuBuchungType } from '../../../types';

export type KontoblattListingFiltersFormValues = {
  buchungskreisId?: string | null;
  objektId?: string | null;
  buchungsdatumFrom?: string | null;
  buchungsdatumTo?: string | null;
  kontoId?: string | null;
  belegdatumFrom?: string | null;
  belegdatumTo?: string | null;
  belegnummer?: string | null;
  belegSymbol?: FibuBelegSymbol | null;
  buchungsTypeList?: FibuBuchungType[];
  erstellerMitarbeiterIdList?: string[];
  offenerOPBetrag?: boolean | null;
  gegenKontoId?: string | null;
  vertragspartnerId?: string | null;
  includeStorno?: boolean | null;
  // for form functionality
  rechnungsAusstellerIdForBuchungskreis: string | null;
  rechnungsAusstellerIdForObjekt: string | null;
};

export const mapKontoblattListingFiltersToFormValues = (filters: KontoblattPageQueryParams): KontoblattListingFiltersFormValues => ({
  buchungskreisId: filters.buchungskreisId,
  objektId: filters.objektId,
  buchungsdatumFrom: filters.buchungsdatumFrom,
  buchungsdatumTo: filters.buchungsdatumTo,
  kontoId: filters.kontoId,
  belegdatumFrom: filters.belegdatumFrom,
  belegdatumTo: filters.belegdatumTo,
  belegnummer: filters.belegnummer,
  belegSymbol: filters.belegSymbol,
  buchungsTypeList: filters.buchungsTypeList ?? [],
  erstellerMitarbeiterIdList: filters.erstellerMitarbeiterIdList ?? [],
  offenerOPBetrag: filters.offenerOPBetrag,
  gegenKontoId: filters.gegenKontoId,
  vertragspartnerId: filters.vertragspartnerId,
  includeStorno: filters.includeStorno,
  // for form functionality
  rechnungsAusstellerIdForBuchungskreis: '',
  rechnungsAusstellerIdForObjekt: '',
});

export const mapFormValuesToKontoblattListingFilters = (formValues: KontoblattListingFiltersFormValues): KontoblattPageQueryParams => ({
  buchungskreisId: formValues.buchungskreisId || undefined,
  objektId: formValues.objektId || undefined,
  kontoId: formValues.kontoId || undefined,
  buchungsdatumFrom: formValues.buchungsdatumFrom ? mapFormDateValueToDateString(formValues.buchungsdatumFrom) : undefined,
  buchungsdatumTo: formValues.buchungsdatumTo ? mapFormDateValueToDateString(formValues.buchungsdatumTo) : undefined,
  belegdatumFrom: formValues.belegdatumFrom ? mapFormDateValueToDateString(formValues.belegdatumFrom) : undefined,
  belegdatumTo: formValues.belegdatumTo ? mapFormDateValueToDateString(formValues.belegdatumTo) : undefined,
  belegnummer: formValues.belegnummer || undefined,
  belegSymbol: formValues.belegSymbol || undefined,
  buchungsTypeList: formValues.buchungsTypeList,
  erstellerMitarbeiterIdList: formValues.erstellerMitarbeiterIdList,
  offenerOPBetrag: formValues.offenerOPBetrag,
  gegenKontoId: formValues.gegenKontoId || undefined,
  vertragspartnerId: formValues.vertragspartnerId || undefined,
  includeStorno: formValues.includeStorno || undefined,
});

export const kontoblattListingFiltersFormFields: FormFields<KontoblattListingFiltersFormValues> = {
  buchungskreisId: 'buchungskreisId',
  objektId: 'objektId',
  kontoId: 'kontoId',
  buchungsdatumFrom: 'buchungsdatumFrom',
  buchungsdatumTo: 'buchungsdatumTo',
  belegdatumFrom: 'belegdatumFrom',
  belegdatumTo: 'belegdatumTo',
  belegnummer: 'belegnummer',
  belegSymbol: 'belegSymbol',
  buchungsTypeList: 'buchungsTypeList',
  erstellerMitarbeiterIdList: 'erstellerMitarbeiterIdList',
  offenerOPBetrag: 'offenerOPBetrag',
  gegenKontoId: 'gegenKontoId',
  vertragspartnerId: 'vertragspartnerId',
  includeStorno: 'includeStorno',
  // for form functionality
  rechnungsAusstellerIdForBuchungskreis: 'rechnungsAusstellerIdForBuchungskreis',
  rechnungsAusstellerIdForObjekt: 'rechnungsAusstellerIdForObjekt',
};

export const kontoblattListingLabelList = {
  [kontoblattListingFiltersFormFields.buchungskreisId]: 'Buchungskreis',
  [kontoblattListingFiltersFormFields.objektId]: 'Objekt',
  [kontoblattListingFiltersFormFields.kontoId]: 'Konto',
  [kontoblattListingFiltersFormFields.buchungsdatumFrom]: 'Buchungsdatum',
  [kontoblattListingFiltersFormFields.belegdatumFrom]: 'Belegdatum',
  [kontoblattListingFiltersFormFields.belegnummer]: 'Belegnummer',
  [kontoblattListingFiltersFormFields.belegSymbol]: 'Belegsymbol',
  [kontoblattListingFiltersFormFields.buchungsTypeList]: 'Buchungstyp',
  [kontoblattListingFiltersFormFields.erstellerMitarbeiterIdList]: 'Ersteller',
  [kontoblattListingFiltersFormFields.offenerOPBetrag]: 'Offener OP-Betrag',
  [kontoblattListingFiltersFormFields.gegenKontoId]: 'Gegenkonto',
  [kontoblattListingFiltersFormFields.vertragspartnerId]: 'Vertragspartner',
  [kontoblattListingFiltersFormFields.includeStorno]: 'Stornierte und Storno-Buchungen berücksichtigen',
};
