import React from 'react';
import { Alert, Card } from 'antd';
import { Box } from 'rebass';
import { CardProps } from 'antd/lib/card';
import { isTimeblockInThePast, ITimeblock, IVersionable, useTimeline, VersionTimelineOnDelete } from '../timelineCtx';
import TimelineCardExtra from './TimelineCardExtra';
import TimelineCardContent from './TimelineCardContent';
import TimelineCardTitle from './TimelineCardTitle';

type TimelineCardProps<T extends IVersionable<T>> = {
  isHistoricized?: boolean;
  timeblock: ITimeblock<T>;
  renderForm?: (timeblock: ITimeblock<T>) => React.ReactNode;
  kommentarForCardTitle?: (timeblock: ITimeblock<T>) => string | null | undefined;
  renderCardContent: (timeblock: ITimeblock<T>) => React.ReactNode;
  isAllowedToDeleteInitialTimeblock?: boolean;
  onDelete?: VersionTimelineOnDelete<T>;
  editable?: boolean;
} & CardProps;

function TimelineCard<T extends IVersionable<T>>({
  isHistoricized,
  timeblock,
  renderForm,
  kommentarForCardTitle,
  renderCardContent,
  isAllowedToDeleteInitialTimeblock,
  onDelete,
  editable,
  ...restProps
}: TimelineCardProps<T>) {
  const { toggleCollapsed, toggleEdit } = useTimeline();

  const isCollapsed = timeblock.isCollapsed;
  const edit = timeblock.edit;
  const showContent = !isCollapsed && !edit;
  const showForm = !isCollapsed && edit;

  const kommentar = kommentarForCardTitle && kommentarForCardTitle(timeblock);

  return (
    <>
      {edit && isTimeblockInThePast(timeblock) && (
        <>
          <Alert message="Sie ändern gerade Werte rückwirkend in der Zeit" type="warning" showIcon closable />
          <Box mb="16px" />
        </>
      )}
      <Card
        hoverable
        title={!isCollapsed && <TimelineCardTitle titleKommentarPart={kommentar} timeblock={timeblock} isHistoricized={isHistoricized} />}
        extra={
          !isCollapsed && (
            <TimelineCardExtra<T>
              timeblock={timeblock}
              toggleEdit={toggleEdit}
              toggleCollapsed={toggleCollapsed}
              edit={edit}
              editable={editable}
              onDelete={onDelete}
              isAllowedToDeleteInitialTimeblock={isAllowedToDeleteInitialTimeblock}
            />
          )
        }
        {...restProps}
      >
        {showContent && renderCardContent(timeblock)}
        {showForm && renderForm?.(timeblock)}

        {isCollapsed && (
          <TimelineCardContent<T> toggleCollapsed={toggleCollapsed} timeblock={timeblock} title={kommentar} isHistoricized={isHistoricized} />
        )}
      </Card>
    </>
  );
}

export default TimelineCard;
