import { InfoFeldFormValues } from '../../../../../shared/InfoFeld/infoFeldFormMapper';
import { FormFields } from '../../../../../../helpers/formikHelper';

export interface PersonCreateFormValues extends InfoFeldFormValues {
  rolle: string;
  rechtstraegerId: string | undefined;
}

export const personCreateFormFields: FormFields<PersonCreateFormValues> = {
  rolle: 'rolle',
  rechtstraegerId: 'rechtstraegerId',
  documentList: 'documentList',
};

export const personCreateFormInitialValues: PersonCreateFormValues = {
  documentList: [],
  rolle: '',
  rechtstraegerId: undefined,
};
