import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { BankDetailsFieldsFragmentDoc } from '../../../BankDetails/gql/BankDetailsFragments.types';
import { PostItFragmentDoc } from '../../../Rechtstraeger/PostIt/gql/PostItFragments.types';
import { RechtstraegerFieldsFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerFragments.types';
export type BeVertragFragment = {
  applyIndexedValues: boolean;
  consolidationCriteria?: string | null;
  consolidationCriteriaEditable: boolean;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  sumBrutto: number;
  sumNetto: number;
  sumUst: number;
  tagList?: Array<string> | null;
  vertragId: string;
  vertragsBeginn: string;
  vertragsEnde?: string | null;
  vertragsartAndPartnerUpdatable?: boolean | null;
  bankDetails?: {
    accountHolder: string;
    accountLimit?: number | null;
    assignedHeVertragZahlungsregelMap: any;
    assignedObjektMap: any;
    bankDetailsId: string;
    bankname: string;
    bic: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    ebicsCreditTransferUserList: Array<string>;
    ebicsDirectDebitUserList: Array<string>;
    fibuKontoId?: string | null;
    fibuKontonummer?: string | null;
    iban: string;
    updatedByMitarbeiterId?: string | null;
    fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
    fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
    sepaMandat?: {
      fileId?: string | null;
      fileName?: string | null;
      sepaMandatId: string;
      sepaMandatReference: string;
      signatureDate?: string | null;
      firmendaten?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firmendatenId: string;
        kundenSystemId: string;
        kurzBezeichnung: string;
        name1: string;
        name2?: string | null;
        name3?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechnungsAussteller?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
      warningList: Array<{ message: string; type: string }>;
    } | null;
    status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  belegform: { text: string; value: Types.BelegFormArt };
  dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
  paymentMethod: { text: string; value: Types.PaymentMethod };
  postIt?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    immerAnzeigen: boolean;
    postItId: string;
    text: string;
    titel: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  status: { text: string; value: Types.VertragStatus; description?: string | null };
  verrechnungsart: { text: string; value: Types.Verrechnungsart };
  vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
  vertragspartner: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    fibuKontoCreatable: boolean;
    kundennummer: string;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    tagList: Array<string>;
    updatedByMitarbeiterId?: string | null;
    bankDetailsList: Array<{
      accountHolder: string;
      accountLimit?: number | null;
      assignedHeVertragZahlungsregelMap: any;
      assignedObjektMap: any;
      bankDetailsId: string;
      bankname: string;
      bic: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      ebicsCreditTransferUserList: Array<string>;
      ebicsDirectDebitUserList: Array<string>;
      fibuKontoId?: string | null;
      fibuKontonummer?: string | null;
      iban: string;
      updatedByMitarbeiterId?: string | null;
      fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
      fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
      sepaMandat?: {
        fileId?: string | null;
        fileName?: string | null;
        sepaMandatId: string;
        sepaMandatReference: string;
        signatureDate?: string | null;
        firmendaten?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firmendatenId: string;
          kundenSystemId: string;
          kurzBezeichnung: string;
          name1: string;
          name2?: string | null;
          name3?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechnungsAussteller?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
        warningList: Array<{ message: string; type: string }>;
      } | null;
      status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    personenbezugList: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      personenbezugId: string;
      tagList: Array<string>;
      person: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    postIt?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      immerAnzeigen: boolean;
      postItId: string;
      text: string;
      titel: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    rechtstraegerSteuerpflicht?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      kommentar?: string | null;
      lastUpdateTs: string;
      rechtstraegerSteuerpflichtId: string;
      validFrom: string;
      historicizedList?: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        rechtstraegerSteuerpflichtId: string;
        validFrom: string;
        steuerpflicht: { text: string; value: Types.Steuerpflicht };
        warningList: Array<{ message: string; type: string }>;
      }> | null;
      steuerpflicht: { text: string; value: Types.Steuerpflicht };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
    sprache: { text: string; value: Types.Sprache };
    status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
    type: { value: Types.RechtstraegerType; text: string };
    vertragSummary?: {
      heVertragCount: number;
      heVertragVerwaltungCount: number;
      mietVertragCount: number;
      mietVertragVerwaltungCount: number;
      weSubverwaltungCount: number;
      weVertragCount: number;
      weVertragVerwaltungCount: number;
    } | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const BeVertragFragmentDoc = gql`
  fragment BeVertrag on BeVertrag {
    applyIndexedValues
    bankDetails {
      ...BankDetailsFields
    }
    belegform {
      text
      value
    }
    consolidationCriteria
    consolidationCriteriaEditable
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    dueDateVorschreibung {
      text
      value
    }
    paymentMethod {
      text
      value
    }
    postIt {
      ...PostIt
    }
    status {
      text
      value
      description
    }
    sumBrutto
    sumNetto
    sumUst
    tagList
    verrechnungsart {
      text
      value
    }
    vertragId
    vertragsBeginn
    vertragsart {
      supportsExplicitVertragsEnde
      text
      value
    }
    vertragsEnde
    vertragsartAndPartnerUpdatable
    vertragspartner {
      ...RechtstraegerFields
    }
    warningList {
      attribute
      message
      type
    }
  }
  ${BankDetailsFieldsFragmentDoc}
  ${PostItFragmentDoc}
  ${RechtstraegerFieldsFragmentDoc}
`;
