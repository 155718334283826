import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import { URI_SUB_ADMINISTRATION_DETAILS_PAGE } from '../../features/SubAdministration/subAdministrationUriPaths';
import SubAdministrationDetailsPage from './SubAdministrationDetailsPage';

export type SubAdministrationDetailsPageRouteParams = { objektId: string; subAdministrationId: string };

const subAdministrationDetailsPageRoute = (
  <Route
    path={`${URI_SUB_ADMINISTRATION_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<SubAdministrationDetailsPage />} />}
  />
);

export default subAdministrationDetailsPageRoute;
