import React, { FC } from 'react';
import { TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';
import { MahndefinitionParamFormValues } from '../../../Mahndefinition/mahndefinitionFormMapper';
import DefinitionUpdateFormButtons from '../../../shared/DefinitionUpdateFormButtons';
import MahndefinitionTemplateTemplates from '../../Templates/MahndefinitionTemplateTemplates';

type Props = {
  mahnstufe1TemplateValues: TextbausteinFormValues[];
  onMahnstufe1TemplateValuesChange: (templateValues: TextbausteinFormValues[]) => void;
  mahnstufe2TemplateValues: TextbausteinFormValues[];
  onMahnstufe2TemplateValuesChange: (templateValues: TextbausteinFormValues[]) => void;
  mahnstufe3TemplateValues: TextbausteinFormValues[];
  onMahnstufe3TemplateValuesChange: (templateValues: TextbausteinFormValues[]) => void;
  mahnstufe4TemplateValues: TextbausteinFormValues[];
  onMahnstufe4TemplateValuesChange: (templateValues: TextbausteinFormValues[]) => void;
  onFormSubmit: (paramFormValues?: MahndefinitionParamFormValues) => void;
  isVersandEinschreiben1: boolean;
  isVersandEinschreiben2: boolean;
  isVersandEinschreiben3: boolean;
  isVersandEinschreiben4: boolean;
};

const MahndefinitionTemplateUpdateTemplateForm: FC<Props> = ({
  mahnstufe1TemplateValues,
  onMahnstufe1TemplateValuesChange,
  mahnstufe2TemplateValues,
  onMahnstufe2TemplateValuesChange,
  mahnstufe3TemplateValues,
  onMahnstufe3TemplateValuesChange,
  mahnstufe4TemplateValues,
  onMahnstufe4TemplateValuesChange,
  onFormSubmit,
  isVersandEinschreiben1,
  isVersandEinschreiben2,
  isVersandEinschreiben3,
  isVersandEinschreiben4,
}) => {
  return (
    <>
      <MahndefinitionTemplateTemplates
        mahnstufe1TemplateValues={mahnstufe1TemplateValues}
        onMahnstufe1TemplateValuesChange={onMahnstufe1TemplateValuesChange}
        mahnstufe2TemplateValues={mahnstufe2TemplateValues}
        onMahnstufe2TemplateValuesChange={onMahnstufe2TemplateValuesChange}
        mahnstufe3TemplateValues={mahnstufe3TemplateValues}
        onMahnstufe3TemplateValuesChange={onMahnstufe3TemplateValuesChange}
        mahnstufe4TemplateValues={mahnstufe4TemplateValues}
        onMahnstufe4TemplateValuesChange={onMahnstufe4TemplateValuesChange}
        isVersandEinschreiben1={isVersandEinschreiben1}
        isVersandEinschreiben2={isVersandEinschreiben2}
        isVersandEinschreiben3={isVersandEinschreiben3}
        isVersandEinschreiben4={isVersandEinschreiben4}
      />
      <DefinitionUpdateFormButtons onSubmit={onFormSubmit} pushPath={URI_ANDROMEDA_SYS_SETTINGS.mahndefinitionPage} />
    </>
  );
};

export default MahndefinitionTemplateUpdateTemplateForm;
