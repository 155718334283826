import React from 'react';
import { FormikProps } from 'formik';
import {
  indexedValueListingFiltersFormFields,
  IndexedValueListingFiltersFormValues,
  indexedValueListingLabelList,
} from './indexedValueListingFiltersFormMapper';
import FromToFormDatePicker from '../../../shared/FromToForm/FromToFormDatePicker';
import IndexedValueTableBulkActions from '../Table/BulkActions/IndexedValueTableBulkActions';
import { TIndexedValueQueryParams } from './filtersQueryParams';
import { getDefaultFilterList, onFilterSelectChange } from '../../../../components/Filters/FiltersHelpers';
import IndexedValueStatusSelect from './IndexedValueStatusSelect';
import IndexSeriesSelect from '../../../IndexSeries/shared/IndexSeriesSelect';
import RechnungsausstellerSelect from '../../../Rechnungsaussteller/RechnungsausstellerSelect';
import ValuePreservationTypeSelect from '../../../shared/ValuePreservation/ValuePreservationTypeSelect';
import ObjektVPosNameSelect from '../../../shared/ObjektVPosNameSelect';
import RechtstraegerSelect from '../../../../shared/Rechtstraeger/RechtstraegerSelect';
import IndexedValueBtnAndModal from '../../Create/IndexedValueBtnAndModal';
import VertragSelectFormItem from './VertragSelectFormItem';
import {
  isIndexedValueFilterBestandseinheitSelected,
  isIndexedValueFilterIndexSeriesSelected,
  isIndexedValueFilterObjektSelected,
  isIndexedValueFilterRechnungsAusstellerSelected,
  isIndexedValueFilterStatusSelected,
  isIndexedValueFilterValidFromSelected,
  isIndexedValueFilterValuePreservationTypeSelected,
  isIndexedValueFilterVertragSelected,
  isIndexedValueFilterVertragspartnerSelected,
  isIndexedValueFilterVorschreibungspositionNameSelected,
} from './filterHelpers';
import BestandseinheitSelectFormItem from './BestandseinheitSelectFormItem';
import ObjektSelectFormItem from './ObjektSelectFormItem';
import useFilterWithQueryParamsNew from '../../../../components/Filters/useFilterWithQueryParamsNew';
import FiltersWithSelectorWrapper from '../../../../components/Filters/FiltersWithSelector/FiltersWithSelectorWrapper';
import FormItemForFilter from '../../../../components/Form/FormItemForFilter';

type Props = {
  formikProps: FormikProps<IndexedValueListingFiltersFormValues>;
  refetch: () => void;
  queryParams: TIndexedValueQueryParams;
};

const IndexedValueListingFilters: React.FC<Props> = ({ formikProps, refetch, queryParams }) => {
  const defaultFilterList = getDefaultFilterList(indexedValueListingFiltersFormFields, formikProps);

  const mapFilterLabelToQueryFilterList = (filter: string) => {
    if (filter === indexedValueListingFiltersFormFields.validFrom || filter === indexedValueListingFiltersFormFields.validTo) {
      return indexedValueListingFiltersFormFields.validFrom;
    }
    return filter;
  };

  // update formik values when a related filter gets removed from selected filters or it's value is changed
  const updateFilterValues = (selectedFilterList: string[]) => {
    if (!selectedFilterList.includes(indexedValueListingFiltersFormFields.bestandseinheitId) && formikProps.values.vertragId) {
      formikProps.setFieldValue(indexedValueListingFiltersFormFields.vertragId, '');
    }
    if (
      !selectedFilterList.includes(indexedValueListingFiltersFormFields.objektId) &&
      (formikProps.values.bestandseinheitId || formikProps.values.vertragId)
    ) {
      formikProps.setFieldValue(indexedValueListingFiltersFormFields.bestandseinheitId, '');
      formikProps.setFieldValue(indexedValueListingFiltersFormFields.vertragId, '');
    }
    if (!selectedFilterList.includes(indexedValueListingFiltersFormFields.validFrom) && formikProps.values.validTo) {
      formikProps.setFieldValue(indexedValueListingFiltersFormFields.validTo, undefined);
    }
    formikProps.submitForm();
  };

  const filters = (filter: string, selectedFilterList: string[]) => {
    // Objekt filter
    if (isIndexedValueFilterObjektSelected(filter)) {
      return <ObjektSelectFormItem formikProps={formikProps} key={filter} />;
    }

    // if Bestandseinheit filter is chosen, then both Objekt and Bestandseinheit filter should be rendered
    if (isIndexedValueFilterBestandseinheitSelected(filter)) {
      return (
        <React.Fragment key={filter}>
          {!selectedFilterList.find(isIndexedValueFilterObjektSelected) && <ObjektSelectFormItem formikProps={formikProps} />}
          <BestandseinheitSelectFormItem formikProps={formikProps} />
        </React.Fragment>
      );
    }

    // if Vertrag filter is chosen, then Objekt, Bestandseinheit and Vertrag filter should be rendered
    if (isIndexedValueFilterVertragSelected(filter)) {
      if (!selectedFilterList.find(isIndexedValueFilterObjektSelected) && !selectedFilterList.find(isIndexedValueFilterBestandseinheitSelected)) {
        return (
          <React.Fragment key={filter}>
            <ObjektSelectFormItem formikProps={formikProps} />
            <BestandseinheitSelectFormItem formikProps={formikProps} />
            <VertragSelectFormItem formikProps={formikProps} />
          </React.Fragment>
        );
      }
      if (!!selectedFilterList.find(isIndexedValueFilterObjektSelected) && !selectedFilterList.find(isIndexedValueFilterBestandseinheitSelected)) {
        return (
          <React.Fragment key={filter}>
            <BestandseinheitSelectFormItem formikProps={formikProps} />
            <VertragSelectFormItem formikProps={formikProps} />
          </React.Fragment>
        );
      }
      return <VertragSelectFormItem formikProps={formikProps} key={filter} />;
    }

    // Status filter
    if (isIndexedValueFilterStatusSelected(filter)) {
      return (
        <FormItemForFilter name={indexedValueListingFiltersFormFields.statusList} key={filter}>
          <IndexedValueStatusSelect
            name={indexedValueListingFiltersFormFields.statusList}
            onChange={formikProps.submitForm}
            prefix="Status"
            placeholder=""
            size="middle"
          />
        </FormItemForFilter>
      );
    }

    // Vertragspartner filter
    if (isIndexedValueFilterVertragspartnerSelected(filter)) {
      return (
        <FormItemForFilter name={indexedValueListingFiltersFormFields.vertragspartnerId} key={filter}>
          <RechtstraegerSelect
            name={indexedValueListingFiltersFormFields.vertragspartnerId}
            onChange={formikProps.submitForm}
            prefix="Vertragspartner"
            placeholder=""
            size="middle"
          />
        </FormItemForFilter>
      );
    }

    // VPos gültig ab filter
    if (isIndexedValueFilterValidFromSelected(filter)) {
      return (
        <FormItemForFilter name="validFrom" key={filter}>
          <FromToFormDatePicker
            format="month"
            fromName={indexedValueListingFiltersFormFields.validFrom}
            toName={indexedValueListingFiltersFormFields.validTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
            fromPrefix="VPos gültig ab"
            toPrefix="VPos gültig bis"
            placeholder=""
          />
        </FormItemForFilter>
      );
    }

    // Wertsicherungsart filter
    if (isIndexedValueFilterValuePreservationTypeSelected(filter)) {
      return (
        <FormItemForFilter name={indexedValueListingFiltersFormFields.valuePreservationTypeList} key={filter}>
          <ValuePreservationTypeSelect
            name={indexedValueListingFiltersFormFields.valuePreservationTypeList}
            onChange={formikProps.submitForm}
            prefix="Wertsicherungsart"
            placeholder=""
            size="middle"
          />
        </FormItemForFilter>
      );
    }

    // Indexreihe filter
    if (isIndexedValueFilterIndexSeriesSelected(filter)) {
      return (
        <FormItemForFilter name={indexedValueListingFiltersFormFields.indexSeriesId} key={filter}>
          <IndexSeriesSelect
            name={indexedValueListingFiltersFormFields.indexSeriesId}
            onSelectChange={formikProps.submitForm}
            prefix="Indexreihe"
            placeholder=""
            size="middle"
          />
        </FormItemForFilter>
      );
    }

    // vorschreibungsposition (= Indexierter Wert) filter
    if (isIndexedValueFilterVorschreibungspositionNameSelected(filter)) {
      return (
        <FormItemForFilter name={indexedValueListingFiltersFormFields.vorschreibungspositionName} key={filter}>
          <ObjektVPosNameSelect
            name={indexedValueListingFiltersFormFields.vorschreibungspositionName}
            onChange={formikProps.submitForm}
            prefix="Indexierter Wert"
            placeholder=""
            size="middle"
          />
        </FormItemForFilter>
      );
    }

    // RechnungsAussteller filter
    if (isIndexedValueFilterRechnungsAusstellerSelected(filter)) {
      return (
        <FormItemForFilter name={indexedValueListingFiltersFormFields.rechnungsAusstellerId} key={filter}>
          <RechnungsausstellerSelect
            name={indexedValueListingFiltersFormFields.rechnungsAusstellerId}
            onChange={formikProps.submitForm}
            prefix="Rechnungsaussteller"
            placeholder=""
            size="middle"
          />
        </FormItemForFilter>
      );
    }
    return undefined;
  };

  const { selectedFilterList, setSelectedFilterList, filterList } = useFilterWithQueryParamsNew({
    defaultFilterList,
    updateFilterValues,
    filters,
    labelList: indexedValueListingLabelList,
    mapFilterLabelToQueryFilterList,
    queryParams,
  });

  return (
    <FiltersWithSelectorWrapper
      selectedFilterList={selectedFilterList}
      onSelectedFilterTitleList={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
      allFilterTitles={indexedValueListingLabelList}
      filters={<>{filterList}</>}
      actionButtons={<IndexedValueBtnAndModal onSuccess={refetch} />}
      filterActions={<IndexedValueTableBulkActions formikProps={formikProps} onSuccess={refetch} />}
    />
  );
};

export default IndexedValueListingFilters;
