import React from 'react';
import { Typography } from 'antd';

/**
 * <h2>Usage</h2>
 * <ul>
 *   <li>On DetailsPages in Tabs where Errors or Deviations should be rendered if they exist.</li>
 *   <li>This component should be rendered if no errors or no Deviations exist.</li>
 * </ul>
 */
const NoErrorsTabContent = () => (
  <>
    <Typography.Title level={5}>
      Gratuliere! Alles ist picobello.
      <span role="img" aria-label="thumbs up">
        👍
      </span>
    </Typography.Title>
  </>
);

export default NoErrorsTabContent;
