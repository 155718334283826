import { FormFields } from '../../../../helpers/formikHelper';
import { FieldHelpUpdateInput } from '../../../../types';
import { FieldHelpService, FieldHelpWithService } from '../../useFieldHelpList';

export type FieldHelpFormValues = {
  fieldHelpId: string;
  selector: string;
  fieldHelp?: string | null;
  service?: FieldHelpService;
};

// Define the form fields
export const fieldHelpFormFields: FormFields<FieldHelpFormValues> = {
  fieldHelpId: 'fieldHelpId',
  selector: 'selector',
  fieldHelp: 'fieldHelp',
  service: 'service',
};

// Define the mapper functions
export const mapFieldHelpToFormValues = (fieldHelpValue: FieldHelpWithService): FieldHelpFormValues => ({
  fieldHelpId: fieldHelpValue.fieldHelpId,
  selector: fieldHelpValue.selector,
  fieldHelp: fieldHelpValue.fieldHelp,
  service: fieldHelpValue.service as FieldHelpService,
});

export const mapFormValuesToFieldHelp = (values: FieldHelpFormValues): FieldHelpUpdateInput => ({
  selector: values.selector,
  fieldHelp: values.fieldHelp,
});
