import React from 'react';
import { CopyOutlined, InboxOutlined, LinkOutlined, SolutionOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { generatePathToVorschreibungDetailsPage } from '../../features/Vorschreibung/vorschreibungUriPaths';
import Todos from '../../features/Contact/Renderers/Todos';
import VorschreibungPositionListingTabs from '../../features/Vorschreibung/VorschreibungDetailsVPosListingTabs/VorschreibungPositionListingTabs';
import DocumentTab from '../../features/Document/DocumentTab';
import { GroupedMenueListInterface } from '../../components/ContextSidebar/ContentWithSidebar';
import Auftragskette from '../../features/Vorschreibung/Auftragskette/Auftragskette';
import { Auftrag, AuftragStatus } from '../../types';
import VorschreibungDeviationList from '../../features/Vorschreibung/Deviation/VorschreibungDeviationList';
import { DEVIATIONS_MENU_LIST_ITEM_TITLE } from '../../components/Deviation/deviationHelpers';

const menuListVorschreibung = (auftrag: Auftrag, loading: boolean, refetch: () => Promise<any>): GroupedMenueListInterface => ({
  topMenuList: [
    {
      title: 'Positionen',
      icon: <SolutionOutlined />,
      uri: `${generatePathToVorschreibungDetailsPage(auftrag.auftragId)}/positions`,
      content: () => <VorschreibungPositionListingTabs auftrag={auftrag} loading={loading} />,
    },
    {
      title: 'Aufgaben',
      icon: <InboxOutlined />,
      uri: `${generatePathToVorschreibungDetailsPage(auftrag.auftragId)}/todos`,
      content: () => <Todos />,
    },
    {
      title: 'Dokumente',
      icon: <CopyOutlined />,
      uri: `${generatePathToVorschreibungDetailsPage(auftrag.auftragId)}/documents`,
      content: () => <DocumentTab auftragId={auftrag.auftragId} hideFilters />,
    },
    {
      state: auftrag.status.value === AuftragStatus.Invalid ? 'error' : undefined,
      title: DEVIATIONS_MENU_LIST_ITEM_TITLE,
      icon: <ThunderboltOutlined />,
      uri: `${generatePathToVorschreibungDetailsPage(auftrag.auftragId)}/discrepancies`,
      content: () => <VorschreibungDeviationList auftragId={auftrag.auftragId} status={auftrag.status} onAction={refetch} />,
    },
  ],
  contextMenuList: [
    {
      title: 'Referenzierte Vorschreibungen',
      icon: <LinkOutlined />,
      uri: `${generatePathToVorschreibungDetailsPage(auftrag.auftragId)}/auftragskette`,
      content: () => <Auftragskette auftragId={auftrag.auftragId} />,
      contextKey: ['AUFTRAGSKETTE'],
    },
  ],
});

export default menuListVorschreibung;
