import { Firmendaten, FirmendatenUpdateInput, LegalForm } from '../../../../types';

export type FirmendatenFormValues = {
  name1: string;
  name2: string;
  name3: string;
  kurzBezeichnung: string;
  legalForm: LegalForm;
  vatIdentificationNumber: string;
  sepaCreditorId: string;
  companyRegistrationNumber: string;
};

export const mapFirmendatenToFormValues = (firmendaten: Firmendaten): FirmendatenFormValues => ({
  name1: firmendaten.name1,
  name2: firmendaten.name2 ?? '',
  name3: firmendaten.name3 ?? '',
  kurzBezeichnung: firmendaten.kurzBezeichnung,
  legalForm: firmendaten.legalForm.value,
  companyRegistrationNumber: firmendaten.companyRegistrationNumber ?? '',
  sepaCreditorId: '',
  vatIdentificationNumber: firmendaten.vatIdentificationNumber ?? '',
});

export const mapFormValuesToFirmendaten = (values: FirmendatenFormValues): FirmendatenUpdateInput => ({
  ...values,
});
