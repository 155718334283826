import React from 'react';
import { Link } from 'react-router-dom';
import { TableColumnProps, Typography } from 'antd';
import { IncomingInvoiceListEntry, IncomingInvoicePartialAmountListEntry } from '../../../../../types';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';
import { EuroAmount } from '../../../../../components/Number';
import { generatePathToOffenePostenPage } from '../../../../OffenePosten/offenePostenUriPaths';
import BuchungsdatumColumn from './BuchungsdatumColumn';

const partialAmountTableColumns = (incomingInvoice: IncomingInvoiceListEntry): TableColumnProps<IncomingInvoicePartialAmountListEntry>[] => [
  {
    title: 'Bezeichnung',
    render: (text, record) => <Typography.Text>{record.name}</Typography.Text>,
    width: 120,
  },
  {
    title: 'Fällig am',
    render: (text, record) => {
      return (
        <Link
          to={generatePathToOffenePostenPage({
            objektId: incomingInvoice.objektId,
            dueDateFrom: record.dueDate,
            dueDateTo: record.dueDate,
            belegnummer: incomingInvoice.rechnungsNummer,
            vertragspartnerId: incomingInvoice.issuerRechtstraegerId,
          })}
          target="_blank"
        >
          <CustomFormattedDate value={record.dueDate} />
        </Link>
      );
    },
  },
  {
    title: 'Buchungsdatum',
    render: (text, record) => <BuchungsdatumColumn record={record} />,
  },
  {
    title: 'Gewünschtes Zahlungsdatum',
    render: (text, record) =>
      record.requestedPaymentDate ? <CustomFormattedDate value={record.requestedPaymentDate} /> : <TextForEmptyValue textToShow="minus" />,
  },
  { title: '' },
  { title: '' },
  { title: '' },
  {
    title: 'Netto',
    align: 'right',
    render: (text, record) => <EuroAmount value={record.netto} />,
  },
  {
    title: 'USt.',
    align: 'right',
    render: (text, record) => <EuroAmount value={record.vat} />,
  },
  {
    title: 'Brutto',
    align: 'right',
    render: (text, record) => <EuroAmount value={record.brutto} />,
  },
  { title: '' },
  { title: '' },
  { title: '' },
  { title: '' },
  { title: '' },
  { title: '', width: 180 },
  { title: '' },
  { title: '', width: 80 },
  { title: '' },
  { title: '' },
];

export default partialAmountTableColumns;
