import React from 'react';
import { Link } from 'react-router-dom';

import { TableColumnProps } from 'antd';
import { BestandseinheitBase, Budgeting, BudgetingResultVertragVPosWert, VertragBase } from '../../../../../types';
import { EuroAmount } from '../../../../../components/Number';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { generatePathToBeVertragDetailsPageVertragsdatenVPosTab } from '../../../../Vertrag/BeVertrag/beVertragUriPaths';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';

const budgetingResultVertragVPosWertTableColumns = (
  budgeting: Budgeting,
  bestandseinheit: BestandseinheitBase,
  vertrag: VertragBase
): TableColumnProps<BudgetingResultVertragVPosWert>[] => [
  {
    title: 'Vorschreibungsposition-Wert',
    width: 200,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={20} text={record.vertragVorschreibungsposition.bezeichnung}>
        {(shortenedText) => (
          <Link
            to={generatePathToBeVertragDetailsPageVertragsdatenVPosTab(
              budgeting.objekt.objektId,
              bestandseinheit.bestandseinheitId,
              vertrag.vertragId,
              record.vertragVorschreibungsposition.vorschreibungspositionId,
              [record.vertragVorschreibungsposition.vorschreibungspositionId]
            )}
            target="_blank"
          >
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'gültig von',
    align: 'right',
    render: (text, record) => <CustomFormattedDate value={record.validFrom} />,
  },
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: 'Netto monatlich',
    align: 'right',
    render: (text, record) => (
      <Link
        to={generatePathToBeVertragDetailsPageVertragsdatenVPosTab(
          budgeting.objekt.objektId,
          bestandseinheit.bestandseinheitId,
          vertrag.vertragId,
          record.vertragVorschreibungsposition.vorschreibungspositionId,
          [record.vertragVorschreibungsposition.vorschreibungspositionId]
        )}
        target="_blank"
      >
        <EuroAmount value={record.monthlyNetto} />
      </Link>
    ),
  },
];

export default budgetingResultVertragVPosWertTableColumns;
