// @ts-ignore
import ValidationApi from '../../api/ValidationApi';
import { validateAsyncAndReturnErrorIfAny } from '../../../helpers/validationHelper';

export const validateNotRequiredPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) {
    return null;
  }
  return validatePhoneNumberAsync(phoneNumber);
};

export const validatePhoneNumberAsync = (phoneNumber?: string) =>
  validateAsyncAndReturnErrorIfAny(() => ValidationApi.validatePhoneNumber(phoneNumber));
