import React, { FC } from 'react';
import { Drawer, Tabs, TabsProps } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { ITimelineState } from '../../components/Timeline/useTimelineOpen';
import IndexValueCreationCronJobSettingsForm from './Form/IndexValueCreationCronJobSettingsForm';
import { IndexValueCreationCronJobSettings } from '../../types';
import TabPaneContent from '../../components/Tabs/TabPaneContent';

type Props = {
  timelineDrawer: ITimelineState;
  closeTimelineDrawer: () => void;
  onSuccess: () => void;
  indexValueCreationCronJobSettings: IndexValueCreationCronJobSettings;
};

const IndexValueCreationCronJobSettingsDrawer: FC<Props> = ({
  timelineDrawer,
  closeTimelineDrawer,
  onSuccess,
  indexValueCreationCronJobSettings,
}) => {
  const onActionSuccess = () => {
    onSuccess();
    closeTimelineDrawer();
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span>
          <EditOutlined />
          Bearbeiten
        </span>
      ),
      children: (
        <TabPaneContent distanceToTopBorder="large">
          <IndexValueCreationCronJobSettingsForm
            onSuccess={onActionSuccess}
            onCancel={closeTimelineDrawer}
            indexValueCreationCronJobSettings={indexValueCreationCronJobSettings}
          />
        </TabPaneContent>
      ),
    },
  ];

  return (
    <Drawer title="Einstellung automatische Erstellung" width={720} open={timelineDrawer.visible} onClose={closeTimelineDrawer} destroyOnClose>
      <Tabs defaultActiveKey="1" items={items} />
    </Drawer>
  );
};

export default IndexValueCreationCronJobSettingsDrawer;
