import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import { BankDetailsFieldsFragmentDoc } from '../../../../BankDetails/gql/BankDetailsFragments.types';
import { ObjektBaseFragmentDoc } from '../../../../Objekt/gql/ObjektFragments.types';
import { WeAbrechnungsdefinitionFieldsFragmentDoc } from '../../../../Abrechnungsdefinition/gql/WeAbrDef/WeAbrDefFragments.types';
import { BkAbrechnungsdefinitionFieldsFragmentDoc } from '../../../../Abrechnungsdefinition/gql/BkAbrDef/BkAbrDefFragments.types';
import { HeAbrechnungsdefinitionFieldsFragmentDoc } from '../../../../Abrechnungsdefinition/gql/HeAbrDef/HeAbrDefFragments.types';
import { MahndefinitionFieldsFragmentDoc } from '../../../../Mahndefinition/gql/MahndefinitionFragments.types';
import { SubAdministrationAbrechnungsdefinitionFieldsFragmentDoc } from '../../../../Abrechnungsdefinition/gql/SubAdministrationAbrDef/SubAdministrationAbrDefFragments.types';
export type RechnungsAusstellerFieldsFragment = {
  buchungskreis: boolean;
  mahndefinitionId?: string | null;
  bankDetailsList: Array<{
    accountHolder: string;
    accountLimit?: number | null;
    assignedHeVertragZahlungsregelMap: any;
    assignedObjektMap: any;
    bankDetailsId: string;
    bankname: string;
    bic: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    ebicsCreditTransferUserList: Array<string>;
    ebicsDirectDebitUserList: Array<string>;
    fibuKontoId?: string | null;
    fibuKontonummer?: string | null;
    iban: string;
    updatedByMitarbeiterId?: string | null;
    fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
    fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
    sepaMandat?: {
      fileId?: string | null;
      fileName?: string | null;
      sepaMandatId: string;
      sepaMandatReference: string;
      signatureDate?: string | null;
      firmendaten?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firmendatenId: string;
        kundenSystemId: string;
        kurzBezeichnung: string;
        name1: string;
        name2?: string | null;
        name3?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechnungsAussteller?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
      warningList: Array<{ message: string; type: string }>;
    } | null;
    status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }>;
  bankSettings: { bankSettingsId?: string | null; sepaCreditorId?: string | null };
  objektList: Array<{
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    objektId: string;
    updatedByMitarbeiterId?: string | null;
    rechnungsAussteller: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    status: { text: string; value: Types.ObjektStatus; description?: string | null };
    verwaltungsart: { text: string; value: Types.Verwaltungsart };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }>;
  weAbrechnungsdefinition?: {
    abrechnungsdefinitionId: string;
    bezeichnung: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    firmendatenId?: string | null;
    validFrom: string;
    abrechdefAbrechkreisList: Array<{
      abrechnungskreis: {
        abrechnungskreisId: string;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        updatedByMitarbeiterId?: string | null;
        status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
        type: { text: string; value: Types.AbrechnungskreisType };
        verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      aufteilungsschluessel?: {
        aufteilungsschluesselId: string;
        bezeichnung: string;
        bezugsbasisEditable?: boolean | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        bezugsbasis: { text: string; value: Types.Bezugsbasis };
        masseinheit: { text: string; value: Types.Masseinheit };
        status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      vorschreibungsposition?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        vorschreibungspositionId: string;
        art: { text: string; value: Types.VorschreibungspositionArt };
        kontierungstabelle?: {
          basiskonto: number;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kontierungstabelleId: string;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
          subAdministrationExpenseAccount?: {
            bezeichnung: string;
            firmendatenId?: string | null;
            kontoId: string;
            kontoKlasse: string;
            nummer: string;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
        umsatzsteuerkennzeichen?: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        } | null;
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      } | null;
    }>;
    kontoAbrechnungskreisDataList: Array<{
      abrechnen: boolean;
      buchungszeilenAnzeigen: boolean;
      expenseReducing: boolean;
      ustVomAufwand: boolean;
      abrechnungskreis?: {
        abrechnungskreisId: string;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        updatedByMitarbeiterId?: string | null;
        status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
        type: { text: string; value: Types.AbrechnungskreisType };
        verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      aufteilungsschluessel?: {
        aufteilungsschluesselId: string;
        bezeichnung: string;
        bezugsbasisEditable?: boolean | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        bezugsbasis: { text: string; value: Types.Bezugsbasis };
        masseinheit: { text: string; value: Types.Masseinheit };
        status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
    }>;
    objektAbrechnungTextbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      textbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    status: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus };
    topAbrechnungTextbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      textbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  bkAbrechnungsdefinition?: {
    abrechnungsdefinitionId: string;
    bezeichnung: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    firmendatenId?: string | null;
    validFrom: string;
    abrechdefAbrechkreisList: Array<{
      abrechnungskreis: {
        abrechnungskreisId: string;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        updatedByMitarbeiterId?: string | null;
        status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
        type: { text: string; value: Types.AbrechnungskreisType };
        verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      aufteilungsschluessel?: {
        aufteilungsschluesselId: string;
        bezeichnung: string;
        bezugsbasisEditable?: boolean | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        bezugsbasis: { text: string; value: Types.Bezugsbasis };
        masseinheit: { text: string; value: Types.Masseinheit };
        status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      vorschreibungsposition?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        vorschreibungspositionId: string;
        art: { text: string; value: Types.VorschreibungspositionArt };
        kontierungstabelle?: {
          basiskonto: number;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kontierungstabelleId: string;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
          subAdministrationExpenseAccount?: {
            bezeichnung: string;
            firmendatenId?: string | null;
            kontoId: string;
            kontoKlasse: string;
            nummer: string;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
        umsatzsteuerkennzeichen?: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        } | null;
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      } | null;
    }>;
    kontoAbrechnungskreisDataList: Array<{
      abrechnen: boolean;
      buchungszeilenAnzeigen: boolean;
      expenseReducing: boolean;
      ustVomAufwand: boolean;
      abrechnungskreis?: {
        abrechnungskreisId: string;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        updatedByMitarbeiterId?: string | null;
        status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
        type: { text: string; value: Types.AbrechnungskreisType };
        verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      aufteilungsschluessel?: {
        aufteilungsschluesselId: string;
        bezeichnung: string;
        bezugsbasisEditable?: boolean | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        bezugsbasis: { text: string; value: Types.Bezugsbasis };
        masseinheit: { text: string; value: Types.Masseinheit };
        status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
    }>;
    objektAbrechnungTextbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      textbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    status: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus };
    topAbrechnungTextbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      textbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  heAbrechnungsdefinition?: {
    abrechnungsdefinitionId: string;
    bezeichnung: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    firmendatenId?: string | null;
    updatedByMitarbeiterId?: string | null;
    validFrom: string;
    abrechdefAbrechkreisList: Array<{
      abrechnungskreis: {
        abrechnungskreisId: string;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        updatedByMitarbeiterId?: string | null;
        status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
        type: { text: string; value: Types.AbrechnungskreisType };
        verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      aufteilungsschluessel?: {
        aufteilungsschluesselId: string;
        bezeichnung: string;
        bezugsbasisEditable?: boolean | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        bezugsbasis: { text: string; value: Types.Bezugsbasis };
        masseinheit: { text: string; value: Types.Masseinheit };
        status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      vorschreibungsposition?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        vorschreibungspositionId: string;
        art: { text: string; value: Types.VorschreibungspositionArt };
        kontierungstabelle?: {
          basiskonto: number;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kontierungstabelleId: string;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
          subAdministrationExpenseAccount?: {
            bezeichnung: string;
            firmendatenId?: string | null;
            kontoId: string;
            kontoKlasse: string;
            nummer: string;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
        umsatzsteuerkennzeichen?: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        } | null;
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      } | null;
    }>;
    heAbrechnungTextbausteinList: Array<{
      index: number;
      value?: string | null;
      textbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
      }>;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    kontoAbrechnungskreisDataList: Array<{
      abrechnen: boolean;
      buchungszeilenAnzeigen: boolean;
      expenseReducing: boolean;
      ustVomAufwand: boolean;
      abrechnungskreis?: {
        abrechnungskreisId: string;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        updatedByMitarbeiterId?: string | null;
        status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
        type: { text: string; value: Types.AbrechnungskreisType };
        verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      aufteilungsschluessel?: {
        aufteilungsschluesselId: string;
        bezeichnung: string;
        bezugsbasisEditable?: boolean | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        bezugsbasis: { text: string; value: Types.Bezugsbasis };
        masseinheit: { text: string; value: Types.Masseinheit };
        status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
    }>;
    status: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  mahndefinition?: {
    bezeichnung: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    firmendatenId?: string | null;
    mahndefinitionId: string;
    mahnfreieTage: number;
    textBausteineBefuellt: boolean;
    verzugszinsen: number;
    mahnstufe1: {
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      minBetrag: number;
      naechsteMahnungNachTage: number;
      updatedByMitarbeiterId?: string | null;
      versandEinschreiben: boolean;
      verzugszinsenBerechnen: boolean;
      mahngebuehrList: Array<{
        ab: number;
        betrag: number;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        mahngebuehrId: string;
      }>;
    };
    mahnstufe1TextbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      textbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    mahnstufe2: {
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      minBetrag: number;
      naechsteMahnungNachTage: number;
      updatedByMitarbeiterId?: string | null;
      versandEinschreiben: boolean;
      verzugszinsenBerechnen: boolean;
      mahngebuehrList: Array<{
        ab: number;
        betrag: number;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        mahngebuehrId: string;
      }>;
    };
    mahnstufe2TextbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      textbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    mahnstufe3: {
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      minBetrag: number;
      naechsteMahnungNachTage: number;
      updatedByMitarbeiterId?: string | null;
      versandEinschreiben: boolean;
      verzugszinsenBerechnen: boolean;
      mahngebuehrList: Array<{
        ab: number;
        betrag: number;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        mahngebuehrId: string;
      }>;
    };
    mahnstufe3TextbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      textbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    mahnstufe4: {
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      minBetrag: number;
      naechsteMahnungNachTage: number;
      updatedByMitarbeiterId?: string | null;
      versandEinschreiben: boolean;
      verzugszinsenBerechnen: boolean;
      mahngebuehrList: Array<{
        ab: number;
        betrag: number;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        mahngebuehrId: string;
      }>;
    };
    mahnstufe4TextbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      textbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    status?: { text: string; value: Types.MahndefinitonStatus } | null;
  } | null;
  subAdministrationAbrechnungsdefinition?: {
    abrechnungsdefinitionId: string;
    bezeichnung: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    firmendatenId?: string | null;
    updatedByMitarbeiterId?: string | null;
    validFrom: string;
    abrechdefAbrechkreisList: Array<{
      abrechnungskreis: {
        abrechnungskreisId: string;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        updatedByMitarbeiterId?: string | null;
        status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
        type: { text: string; value: Types.AbrechnungskreisType };
        verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      aufteilungsschluessel?: {
        aufteilungsschluesselId: string;
        bezeichnung: string;
        bezugsbasisEditable?: boolean | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        bezugsbasis: { text: string; value: Types.Bezugsbasis };
        masseinheit: { text: string; value: Types.Masseinheit };
        status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      vorschreibungsposition?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        vorschreibungspositionId: string;
        art: { text: string; value: Types.VorschreibungspositionArt };
        kontierungstabelle?: {
          basiskonto: number;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kontierungstabelleId: string;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
          subAdministrationExpenseAccount?: {
            bezeichnung: string;
            firmendatenId?: string | null;
            kontoId: string;
            kontoKlasse: string;
            nummer: string;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
        umsatzsteuerkennzeichen?: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        } | null;
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      } | null;
    }>;
    subAdministrationAbrechnungTextbausteinList: Array<{
      index: number;
      value?: string | null;
      textbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
      }>;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    kontoAbrechnungskreisDataList: Array<{
      abrechnen: boolean;
      buchungszeilenAnzeigen: boolean;
      expenseReducing: boolean;
      ustVomAufwand: boolean;
      abrechnungskreis?: {
        abrechnungskreisId: string;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        updatedByMitarbeiterId?: string | null;
        status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
        type: { text: string; value: Types.AbrechnungskreisType };
        verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      aufteilungsschluessel?: {
        aufteilungsschluesselId: string;
        bezeichnung: string;
        bezugsbasisEditable?: boolean | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        bezugsbasis: { text: string; value: Types.Bezugsbasis };
        masseinheit: { text: string; value: Types.Masseinheit };
        status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
    }>;
    status: { description?: string | null; text: string; value: Types.AbrechnungsdefinitionStatus };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  subAdministrationSettings?: { paymentClearsIncomingInvoice: boolean; subAdministrationSettingsId: string } | null;
};

export const RechnungsAusstellerFieldsFragmentDoc = gql`
  fragment RechnungsAusstellerFields on RechnungsAussteller {
    bankDetailsList {
      ...BankDetailsFields
    }
    bankSettings {
      bankSettingsId
      sepaCreditorId
    }
    buchungskreis
    mahndefinitionId
    objektList {
      ...ObjektBase
    }
    weAbrechnungsdefinition {
      ...WeAbrechnungsdefinitionFields
    }
    bkAbrechnungsdefinition {
      ...BkAbrechnungsdefinitionFields
    }
    heAbrechnungsdefinition {
      ...HeAbrechnungsdefinitionFields
    }
    mahndefinition {
      ...MahndefinitionFields
    }
    subAdministrationAbrechnungsdefinition {
      ...SubAdministrationAbrechnungsdefinitionFields
    }
    subAdministrationSettings {
      paymentClearsIncomingInvoice
      subAdministrationSettingsId
    }
  }
  ${BankDetailsFieldsFragmentDoc}
  ${ObjektBaseFragmentDoc}
  ${WeAbrechnungsdefinitionFieldsFragmentDoc}
  ${BkAbrechnungsdefinitionFieldsFragmentDoc}
  ${HeAbrechnungsdefinitionFieldsFragmentDoc}
  ${MahndefinitionFieldsFragmentDoc}
  ${SubAdministrationAbrechnungsdefinitionFieldsFragmentDoc}
`;
