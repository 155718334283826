import React from 'react';
import { StatusEnum } from '../../../helpers/statusHelper';
import { MitarbeiterTooltip } from '../../../components/Card';
import StatusBadge from '../../../components/Status/StatusBadge';
import DataWithStatus from '../../../components/Helpers/DataWithStatus';
import { Aufteilungsschluessel } from '../../../types';
import { NestedTableColProps } from '../../../components/Table/NestedTable/NestedTable';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';
import AufteilungsschluesselTemplateTableActions from './AufteilungsschluesselTemplateTableActions';
import { compareTwoStringsForSorting } from '../../../helpers/stringHelper';

const aufteilungsschluesselTemplateTableColumns = (onAction: () => void): NestedTableColProps<Aufteilungsschluessel>[] => [
  {
    title: 'Bezeichnung',
    ellipsis: {
      showTitle: false,
    },
    sorter: (a, b) => compareTwoStringsForSorting(a.bezeichnung, b.bezeichnung),
    defaultSortOrder: 'ascend',
    render: (text, record) => (
      <DataWithStatus
        status={record.status}
        text={record.bezeichnung}
        showStatusDescription={false}
        showBadgeDot={false}
        alternativeText={record.kurzBezeichnung}
      />
    ),
  },
  {
    title: 'Bezugsbasis',
    sorter: (a, b) => compareTwoStringsForSorting(a.bezugsbasis.text, b.bezugsbasis.text),
    render: (text, record) => (
      <DataWithStatus status={record.status} text={record.bezugsbasis.text} showStatusDescription={false} showBadgeDot={false} />
    ),
  },
  {
    title: 'Maßeinheit',
    sorter: (a, b) => compareTwoStringsForSorting(a.masseinheit.text, b.masseinheit.text),
    render: (text, record) => (
      <DataWithStatus status={record.status} text={record.masseinheit.text} showStatusDescription={false} showBadgeDot={false} />
    ),
  },
  {
    title: 'Status',
    sorter: (a, b) => compareTwoStringsForSorting(a.status.value, b.status.value),
    filters: [
      { text: 'Aktiv', value: StatusEnum.Aktiv },
      { text: 'Archiviert', value: StatusEnum.Archiviert },
    ],
    onFilter: (value, record) => record.status.value.includes(value as string),
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },
  {
    title: 'Erstellt am',
    render: (text, record) => (
      <DataWithStatus status={record.status} text="" showBadgeDot={false} showStatusDescription={false}>
        {() => <FormattedDateTime createdTs={record.createTs} />}
      </DataWithStatus>
    ),
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Aktion',
    key: 'action',
    render: (text, record) => <AufteilungsschluesselTemplateTableActions aufteilungsschluessel={record} onAction={onAction} />,
  },
];

export default aufteilungsschluesselTemplateTableColumns;
