import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { Typography } from 'antd';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import { useDataCarrierCamtNameListQuery } from '../../../gql/DataCarrierQueries.types';

type Props = SelectProps;

const CamtNameListFormSelect: FC<Props> = ({ ...restProps }) => {
  const { loading, data } = useDataCarrierCamtNameListQuery();
  const list = data?.getDataCarrierCamtNameList.data ?? [];

  return (
    <Select
      {...restProps}
      size="small"
      id={restProps.name}
      loading={loading}
      placeholder="Bezeichnung (des Datenträgers) auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {list.map((dataCarrierName) => (
        <Select.Option key={dataCarrierName} value={dataCarrierName}>
          <Typography.Text>{dataCarrierName}</Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export default CamtNameListFormSelect;
