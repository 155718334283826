import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import { UstKategorieEntryFieldsFragmentDoc } from '../../gql/UstKategorieFragments.types';
export type UstKategorieVersionFieldsFragment = {
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  current: boolean;
  deletable?: boolean | null;
  lastUpdateTs: string;
  ustKategorieVersionId: string;
  ustVomAufwand: number;
  validFrom: string;
  versionIndex: number;
  vstKuerzung: boolean;
  ustKategorieEntryList: Array<{
    additionKonto: number;
    buchungsKZ?: string | null;
    createTs?: string | null;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    rechnungskennzeichen: string;
    steuersatz: number;
    ustKategorieEntryId?: string | null;
    ustKz: {
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      kuerzel: string;
      umsatzsteuerkennzeichenId: string;
      status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }>;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const UstKategorieVersionFieldsFragmentDoc = gql`
  fragment UstKategorieVersionFields on UstKategorieVersion {
    createTs
    createdBy
    createdByMitarbeiterId
    current
    deletable
    lastUpdateTs
    ustKategorieEntryList {
      ...UstKategorieEntryFields
    }
    ustKategorieVersionId
    ustVomAufwand
    validFrom
    versionIndex
    vstKuerzung
    warningList {
      attribute
      message
      type
    }
  }
  ${UstKategorieEntryFieldsFragmentDoc}
`;
