import { mapToQueryString, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { GenerierlaufEntryExitCode } from '../../../../../types';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../../verarbeitungApiPaths';

enum BudgetingVerarbeitungQueryParamKey {
  OBJEKT = 'objekt',
  EXIT_CODE_LIST = 'exitCodeList',
}

export type BudgetingVerarbeitungQueryParams = QueryParams<{
  objekt: string;
  exitCodeList: GenerierlaufEntryExitCode[];
}>;

export const updateQueryParams = (
  verarbeitungType: string,
  generierlaufId: string,
  navigate: (path: string) => void,
  filters: BudgetingVerarbeitungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => {
  navigate(generatePathToBudgetingListPage(verarbeitungType, generierlaufId, filters.objekt, filters.exitCodeList, paginationParams));
};

const generatePathToBudgetingListPage = (
  verarbeitungType: string,
  generierlaufId: string,
  objekt?: string,
  exitCodeList?: GenerierlaufEntryExitCode[],
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [BudgetingVerarbeitungQueryParamKey.OBJEKT]: objekt,
    [BudgetingVerarbeitungQueryParamKey.EXIT_CODE_LIST]: exitCodeList,
    ...paginationParams,
  })}`;
