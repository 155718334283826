import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { Typography } from 'antd';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import { useIbanFibuKontoFibuAccountNumberListQuery } from '../gql/IbanFibuKontoQueries.types';

type Props = Omit<SelectProps, 'id' | 'size' | 'placeholder' | 'filterOption'>;

const FibuAccountNumberListFormSelect: FC<Props> = ({ ...restProps }) => {
  const { loading, data } = useIbanFibuKontoFibuAccountNumberListQuery();
  const list = data?.getIbanFibuKontoFibuAccountNumberList.data ?? [];

  return (
    <Select
      {...restProps}
      size="small"
      id={restProps.name}
      loading={loading}
      placeholder="FIBU-Kontonummer auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {list.map((fibuAccountNumber) => (
        <Select.Option key={fibuAccountNumber} value={fibuAccountNumber}>
          <Typography.Text>{fibuAccountNumber}</Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export default FibuAccountNumberListFormSelect;
