import { FC } from 'react';
import { useToggle } from '../../../hooks/useToggle';
import { PostIt, PostItInput } from '../../../types';
import { showSuccessMsgCreate } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import PostItIconAndCreateMode from '../../../components/PostIt/PostItIconAndCreateMode';
import PostItIconAndViewAndUpdateMode from '../../../components/PostIt/PostItIconAndViewAndUpdateMode';
import { useCreateRechtstraegerPostItMutation } from '../gql/RechtsTraegerMutations.types';

type Props = {
  rechtstraegerId: string;
  postIt?: PostIt | null;
  refresh: () => void;
};

const RechtstraegerPostIt: FC<Props> = ({ rechtstraegerId, postIt, refresh }) => {
  const [isPostItOpen, toggleIsPostItOpen] = useToggle(false);

  const [runCreate] = useCreateRechtstraegerPostItMutation({
    onCompleted: () => {
      showSuccessMsgCreate('Post-It');
      toggleIsPostItOpen();
      refresh();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const handleCreateSubmit = (values: PostItInput) => {
    runCreate({
      variables: {
        rechtstraegerId,
        input: {
          immerAnzeigen: values.immerAnzeigen,
          titel: values.titel,
          text: values.text,
        },
      },
    });
  };

  const handleCreateCancel = () => {
    toggleIsPostItOpen();
  };

  if (postIt) {
    return <PostItIconAndViewAndUpdateMode postIt={postIt} refresh={refresh} />;
  } else {
    return (
      <PostItIconAndCreateMode
        isPostItOpen={isPostItOpen}
        onIconClick={toggleIsPostItOpen}
        onFormCancel={handleCreateCancel}
        onFormSubmit={handleCreateSubmit}
      />
    );
  }
};

export default RechtstraegerPostIt;
