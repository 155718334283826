import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerierlaufListQueryVariables = Types.Exact<{
  typeList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  statusList?: Types.InputMaybe<Array<Types.GenerierlaufStatus> | Types.GenerierlaufStatus>;
  generierDatumVonInklusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  generierDatumBisInklusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  erstellerMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  order?: Types.InputMaybe<Types.GenerierlaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type GenerierlaufListQuery = {
  getGenerierlaufList: {
    data: {
      contentList: Array<{
        countEntries: number;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        generierDatum: string;
        generierlaufId: string;
        type: string;
        status: { description?: string | null; text: string; value: Types.GenerierlaufStatus };
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type GenerierlaufStatusListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GenerierlaufStatusListQuery = {
  getGenerierlaufStatusList: {
    data: Array<{ value: Types.GenerierlaufStatus; text: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type GenerierlaufEntryExitCodeListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GenerierlaufEntryExitCodeListQuery = {
  getGenerierlaufEntryExitCodeList: {
    data: Array<{ value: Types.GenerierlaufEntryExitCode; text: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const GenerierlaufListDocument = gql`
  query GenerierlaufList(
    $typeList: [String!]
    $statusList: [GenerierlaufStatus!]
    $generierDatumVonInklusive: String
    $generierDatumBisInklusive: String
    $erstellerMitarbeiterIdList: [String!]
    $order: GenerierlaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getGenerierlaufList(
      typeList: $typeList
      statusList: $statusList
      generierDatumVonInklusive: $generierDatumVonInklusive
      generierDatumBisInklusive: $generierDatumBisInklusive
      erstellerMitarbeiterIdList: $erstellerMitarbeiterIdList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          countEntries
          createTs
          createdBy
          createdByMitarbeiterId
          generierDatum
          generierlaufId
          status {
            description
            text
            value
          }
          type
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useGenerierlaufListQuery(baseOptions?: Apollo.QueryHookOptions<GenerierlaufListQuery, GenerierlaufListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GenerierlaufListQuery, GenerierlaufListQueryVariables>(GenerierlaufListDocument, options);
}
export function useGenerierlaufListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerierlaufListQuery, GenerierlaufListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GenerierlaufListQuery, GenerierlaufListQueryVariables>(GenerierlaufListDocument, options);
}
export function useGenerierlaufListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GenerierlaufListQuery, GenerierlaufListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GenerierlaufListQuery, GenerierlaufListQueryVariables>(GenerierlaufListDocument, options);
}
export type GenerierlaufListQueryHookResult = ReturnType<typeof useGenerierlaufListQuery>;
export type GenerierlaufListLazyQueryHookResult = ReturnType<typeof useGenerierlaufListLazyQuery>;
export type GenerierlaufListSuspenseQueryHookResult = ReturnType<typeof useGenerierlaufListSuspenseQuery>;
export type GenerierlaufListQueryResult = Apollo.QueryResult<GenerierlaufListQuery, GenerierlaufListQueryVariables>;
export const GenerierlaufStatusListDocument = gql`
  query GenerierlaufStatusList {
    getGenerierlaufStatusList {
      data {
        value
        text
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useGenerierlaufStatusListQuery(
  baseOptions?: Apollo.QueryHookOptions<GenerierlaufStatusListQuery, GenerierlaufStatusListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GenerierlaufStatusListQuery, GenerierlaufStatusListQueryVariables>(GenerierlaufStatusListDocument, options);
}
export function useGenerierlaufStatusListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GenerierlaufStatusListQuery, GenerierlaufStatusListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GenerierlaufStatusListQuery, GenerierlaufStatusListQueryVariables>(GenerierlaufStatusListDocument, options);
}
export function useGenerierlaufStatusListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GenerierlaufStatusListQuery, GenerierlaufStatusListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GenerierlaufStatusListQuery, GenerierlaufStatusListQueryVariables>(GenerierlaufStatusListDocument, options);
}
export type GenerierlaufStatusListQueryHookResult = ReturnType<typeof useGenerierlaufStatusListQuery>;
export type GenerierlaufStatusListLazyQueryHookResult = ReturnType<typeof useGenerierlaufStatusListLazyQuery>;
export type GenerierlaufStatusListSuspenseQueryHookResult = ReturnType<typeof useGenerierlaufStatusListSuspenseQuery>;
export type GenerierlaufStatusListQueryResult = Apollo.QueryResult<GenerierlaufStatusListQuery, GenerierlaufStatusListQueryVariables>;
export const GenerierlaufEntryExitCodeListDocument = gql`
  query GenerierlaufEntryExitCodeList {
    getGenerierlaufEntryExitCodeList {
      data {
        value
        text
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useGenerierlaufEntryExitCodeListQuery(
  baseOptions?: Apollo.QueryHookOptions<GenerierlaufEntryExitCodeListQuery, GenerierlaufEntryExitCodeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GenerierlaufEntryExitCodeListQuery, GenerierlaufEntryExitCodeListQueryVariables>(
    GenerierlaufEntryExitCodeListDocument,
    options
  );
}
export function useGenerierlaufEntryExitCodeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GenerierlaufEntryExitCodeListQuery, GenerierlaufEntryExitCodeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GenerierlaufEntryExitCodeListQuery, GenerierlaufEntryExitCodeListQueryVariables>(
    GenerierlaufEntryExitCodeListDocument,
    options
  );
}
export function useGenerierlaufEntryExitCodeListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GenerierlaufEntryExitCodeListQuery, GenerierlaufEntryExitCodeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GenerierlaufEntryExitCodeListQuery, GenerierlaufEntryExitCodeListQueryVariables>(
    GenerierlaufEntryExitCodeListDocument,
    options
  );
}
export type GenerierlaufEntryExitCodeListQueryHookResult = ReturnType<typeof useGenerierlaufEntryExitCodeListQuery>;
export type GenerierlaufEntryExitCodeListLazyQueryHookResult = ReturnType<typeof useGenerierlaufEntryExitCodeListLazyQuery>;
export type GenerierlaufEntryExitCodeListSuspenseQueryHookResult = ReturnType<typeof useGenerierlaufEntryExitCodeListSuspenseQuery>;
export type GenerierlaufEntryExitCodeListQueryResult = Apollo.QueryResult<
  GenerierlaufEntryExitCodeListQuery,
  GenerierlaufEntryExitCodeListQueryVariables
>;
