import { FC } from 'react';
import { useBankAccountChangeHistoryListQuery } from '../../../../BankDetails/gql/BankDetailsQueries.types';
import { HistoryType } from '../../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../../History/HistoryListingTable';

type Props = {
  rechtstraegerId: string;
  bankDetailsId: string;
};

const BankAccountChangeHistoryListingTable: FC<Props> = ({ rechtstraegerId, bankDetailsId }) => {
  const { data, loading } = useBankAccountChangeHistoryListQuery({ variables: { rechtstraegerId, bankDetailsId } });
  const historyList = data?.getBankAccountChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="bankAccount" />;
};

export default BankAccountChangeHistoryListingTable;
