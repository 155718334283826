import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { VorschreibungspositionFragmentDoc } from './VorschreibungspositionFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KsVorschreibungspositionListQueryVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
  order?: Types.InputMaybe<Types.Scalars['String']['input']>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type KsVorschreibungspositionListQuery = {
  getVorschreibungspositionListByKundenSystemId: {
    data: {
      contentList: Array<{
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        vorschreibungspositionId: string;
        art: { text: string; value: Types.VorschreibungspositionArt };
        kontierungstabelle?: {
          basiskonto: number;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kontierungstabelleId: string;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
          subAdministrationExpenseAccount?: {
            bezeichnung: string;
            firmendatenId?: string | null;
            kontoId: string;
            kontoKlasse: string;
            nummer: string;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
        umsatzsteuerkennzeichen?: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        } | null;
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FdVorschreibungspositionListQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  order?: Types.InputMaybe<Types.Scalars['String']['input']>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type FdVorschreibungspositionListQuery = {
  getVorschreibungspositionListByFirmendatenId: {
    data: {
      contentList: Array<{
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        vorschreibungspositionId: string;
        art: { text: string; value: Types.VorschreibungspositionArt };
        kontierungstabelle?: {
          basiskonto: number;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kontierungstabelleId: string;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
          subAdministrationExpenseAccount?: {
            bezeichnung: string;
            firmendatenId?: string | null;
            kontoId: string;
            kontoKlasse: string;
            nummer: string;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
        umsatzsteuerkennzeichen?: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        } | null;
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type VorschreibungspositionQueryVariables = Types.Exact<{
  vorschreibungspositionId: Types.Scalars['ID']['input'];
}>;

export type VorschreibungspositionQuery = {
  getVorschreibungsposition: {
    data: {
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      vorschreibungspositionId: string;
      art: { text: string; value: Types.VorschreibungspositionArt };
      kontierungstabelle?: {
        basiskonto: number;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kontierungstabelleId: string;
        updatedByMitarbeiterId?: string | null;
        status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
        subAdministrationExpenseAccount?: {
          bezeichnung: string;
          firmendatenId?: string | null;
          kontoId: string;
          kontoKlasse: string;
          nummer: string;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
      umsatzsteuerkennzeichen?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kuerzel: string;
        umsatzsteuerkennzeichenId: string;
        status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      } | null;
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type VorschreibungspositionChangeHistoryListQueryVariables = Types.Exact<{
  vorschreibungspositionId: Types.Scalars['ID']['input'];
}>;

export type VorschreibungspositionChangeHistoryListQuery = {
  getVorschreibungspositionChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const KsVorschreibungspositionListDocument = gql`
  query KSVorschreibungspositionList(
    $kundenSystemId: ID!
    $order: String
    $orderDir: String
    $page: Int
    $pageSize: Int
    $withDetails: Boolean
    $includeArchiviert: Boolean
  ) {
    getVorschreibungspositionListByKundenSystemId(
      kundenSystemId: $kundenSystemId
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
      withDetails: $withDetails
      includeArchiviert: $includeArchiviert
    ) {
      data {
        contentList {
          ...Vorschreibungsposition
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VorschreibungspositionFragmentDoc}
`;
export function useKsVorschreibungspositionListQuery(
  baseOptions: Apollo.QueryHookOptions<KsVorschreibungspositionListQuery, KsVorschreibungspositionListQueryVariables> &
    ({ variables: KsVorschreibungspositionListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KsVorschreibungspositionListQuery, KsVorschreibungspositionListQueryVariables>(
    KsVorschreibungspositionListDocument,
    options
  );
}
export function useKsVorschreibungspositionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KsVorschreibungspositionListQuery, KsVorschreibungspositionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KsVorschreibungspositionListQuery, KsVorschreibungspositionListQueryVariables>(
    KsVorschreibungspositionListDocument,
    options
  );
}
export function useKsVorschreibungspositionListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KsVorschreibungspositionListQuery, KsVorschreibungspositionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KsVorschreibungspositionListQuery, KsVorschreibungspositionListQueryVariables>(
    KsVorschreibungspositionListDocument,
    options
  );
}
export type KsVorschreibungspositionListQueryHookResult = ReturnType<typeof useKsVorschreibungspositionListQuery>;
export type KsVorschreibungspositionListLazyQueryHookResult = ReturnType<typeof useKsVorschreibungspositionListLazyQuery>;
export type KsVorschreibungspositionListSuspenseQueryHookResult = ReturnType<typeof useKsVorschreibungspositionListSuspenseQuery>;
export type KsVorschreibungspositionListQueryResult = Apollo.QueryResult<
  KsVorschreibungspositionListQuery,
  KsVorschreibungspositionListQueryVariables
>;
export const FdVorschreibungspositionListDocument = gql`
  query FDVorschreibungspositionList(
    $firmendatenId: ID!
    $order: String
    $orderDir: String
    $page: Int
    $pageSize: Int
    $withDetails: Boolean
    $includeArchiviert: Boolean
  ) {
    getVorschreibungspositionListByFirmendatenId(
      firmendatenId: $firmendatenId
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
      withDetails: $withDetails
      includeArchiviert: $includeArchiviert
    ) {
      data {
        contentList {
          ...Vorschreibungsposition
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VorschreibungspositionFragmentDoc}
`;
export function useFdVorschreibungspositionListQuery(
  baseOptions: Apollo.QueryHookOptions<FdVorschreibungspositionListQuery, FdVorschreibungspositionListQueryVariables> &
    ({ variables: FdVorschreibungspositionListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FdVorschreibungspositionListQuery, FdVorschreibungspositionListQueryVariables>(
    FdVorschreibungspositionListDocument,
    options
  );
}
export function useFdVorschreibungspositionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FdVorschreibungspositionListQuery, FdVorschreibungspositionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FdVorschreibungspositionListQuery, FdVorschreibungspositionListQueryVariables>(
    FdVorschreibungspositionListDocument,
    options
  );
}
export function useFdVorschreibungspositionListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FdVorschreibungspositionListQuery, FdVorschreibungspositionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FdVorschreibungspositionListQuery, FdVorschreibungspositionListQueryVariables>(
    FdVorschreibungspositionListDocument,
    options
  );
}
export type FdVorschreibungspositionListQueryHookResult = ReturnType<typeof useFdVorschreibungspositionListQuery>;
export type FdVorschreibungspositionListLazyQueryHookResult = ReturnType<typeof useFdVorschreibungspositionListLazyQuery>;
export type FdVorschreibungspositionListSuspenseQueryHookResult = ReturnType<typeof useFdVorschreibungspositionListSuspenseQuery>;
export type FdVorschreibungspositionListQueryResult = Apollo.QueryResult<
  FdVorschreibungspositionListQuery,
  FdVorschreibungspositionListQueryVariables
>;
export const VorschreibungspositionDocument = gql`
  query Vorschreibungsposition($vorschreibungspositionId: ID!) {
    getVorschreibungsposition(vorschreibungspositionId: $vorschreibungspositionId) {
      data {
        ...Vorschreibungsposition
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VorschreibungspositionFragmentDoc}
`;
export function useVorschreibungspositionQuery(
  baseOptions: Apollo.QueryHookOptions<VorschreibungspositionQuery, VorschreibungspositionQueryVariables> &
    ({ variables: VorschreibungspositionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VorschreibungspositionQuery, VorschreibungspositionQueryVariables>(VorschreibungspositionDocument, options);
}
export function useVorschreibungspositionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VorschreibungspositionQuery, VorschreibungspositionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VorschreibungspositionQuery, VorschreibungspositionQueryVariables>(VorschreibungspositionDocument, options);
}
export function useVorschreibungspositionSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VorschreibungspositionQuery, VorschreibungspositionQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VorschreibungspositionQuery, VorschreibungspositionQueryVariables>(VorschreibungspositionDocument, options);
}
export type VorschreibungspositionQueryHookResult = ReturnType<typeof useVorschreibungspositionQuery>;
export type VorschreibungspositionLazyQueryHookResult = ReturnType<typeof useVorschreibungspositionLazyQuery>;
export type VorschreibungspositionSuspenseQueryHookResult = ReturnType<typeof useVorschreibungspositionSuspenseQuery>;
export type VorschreibungspositionQueryResult = Apollo.QueryResult<VorschreibungspositionQuery, VorschreibungspositionQueryVariables>;
export const VorschreibungspositionChangeHistoryListDocument = gql`
  query VorschreibungspositionChangeHistoryList($vorschreibungspositionId: ID!) {
    getVorschreibungspositionChangeHistoryList(vorschreibungspositionId: $vorschreibungspositionId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useVorschreibungspositionChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<VorschreibungspositionChangeHistoryListQuery, VorschreibungspositionChangeHistoryListQueryVariables> &
    ({ variables: VorschreibungspositionChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VorschreibungspositionChangeHistoryListQuery, VorschreibungspositionChangeHistoryListQueryVariables>(
    VorschreibungspositionChangeHistoryListDocument,
    options
  );
}
export function useVorschreibungspositionChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VorschreibungspositionChangeHistoryListQuery, VorschreibungspositionChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VorschreibungspositionChangeHistoryListQuery, VorschreibungspositionChangeHistoryListQueryVariables>(
    VorschreibungspositionChangeHistoryListDocument,
    options
  );
}
export function useVorschreibungspositionChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<VorschreibungspositionChangeHistoryListQuery, VorschreibungspositionChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VorschreibungspositionChangeHistoryListQuery, VorschreibungspositionChangeHistoryListQueryVariables>(
    VorschreibungspositionChangeHistoryListDocument,
    options
  );
}
export type VorschreibungspositionChangeHistoryListQueryHookResult = ReturnType<typeof useVorschreibungspositionChangeHistoryListQuery>;
export type VorschreibungspositionChangeHistoryListLazyQueryHookResult = ReturnType<typeof useVorschreibungspositionChangeHistoryListLazyQuery>;
export type VorschreibungspositionChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useVorschreibungspositionChangeHistoryListSuspenseQuery
>;
export type VorschreibungspositionChangeHistoryListQueryResult = Apollo.QueryResult<
  VorschreibungspositionChangeHistoryListQuery,
  VorschreibungspositionChangeHistoryListQueryVariables
>;
