import { HeOrSubAbrDefFieldHelpNames, HeOrSubAbrDefOutputOptionsFieldHelpNames } from './abrDefFieldHelpMapper';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';

export const useSubAbrDefFieldHelp = (): HeOrSubAbrDefFieldHelpNames => {
  const getFieldHelpText = useGetAppFieldHelpText<'SubAdministrationAbrechnungsdefinition'>('SubAdministrationAbrechnungsdefinition');

  const fieldHelpAbrDefAbrechkreisList = getFieldHelpText('SubAdministrationAbrechnungsdefinition.abrechdefAbrechkreisList');

  const fieldHelpNamesOutputOptions: HeOrSubAbrDefOutputOptionsFieldHelpNames = {
    displayKontonummer: getFieldHelpText('SubAdministrationAbrechnungsdefinition.outputOptions.displayKontonummer'),
    rueckstaendeAnzeigen: getFieldHelpText('SubAdministrationAbrechnungsdefinition.outputOptions.rueckstaendeAnzeigen'),
    rueckstaendeInAbrechnungsergebnis: getFieldHelpText('SubAdministrationAbrechnungsdefinition.outputOptions.rueckstaendeInAbrechnungsergebnis'),
  };

  return { fieldHelpAbrDefAbrechkreisList, fieldHelpNamesOutputOptions };
};
