import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { BookingSuggestionCreationGenerierlauf } from '../../../types';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { useBookingSuggestionCreationGenerierlaufQuery } from '../../../features/Verarbeitung/gql/BookingSuggestionCreationGenerierlauf/BookingSuggestionCreationGenerierlaufQueries.types';
import { BookingSuggestionCreationPageRouteParams } from './routes';
import menuListBookingSuggestionCreation from './menuListBookingSuggestionCreation';
import { useRestartBookingSuggestionCreationGenerierlaufMutation } from '../../../features/Verarbeitung/gql/BookingSuggestionCreationGenerierlauf/BookingSuggestionCreationGenerierlaufMutations.types';

const BookingSuggestionCreationDetailsPage = () => {
  const { generierlaufId } = useParams() as BookingSuggestionCreationPageRouteParams;

  const { data, refetch } = useBookingSuggestionCreationGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getBookingSuggestionCreationGenerierlauf.data;

  const [runRestart] = useRestartBookingSuggestionCreationGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<BookingSuggestionCreationGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.BookingSuggestionCreation}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.BookingSuggestionCreation}
          onGenerierlaufRestart={runRestart}
        />
      )}
      isLoading={false}
      sidebarMenuList={menuListBookingSuggestionCreation}
    />
  );
};

export default BookingSuggestionCreationDetailsPage;
