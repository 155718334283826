import React, { FC, useMemo } from 'react';
import { Empty } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';
import zinslisteTableColumns, { RowContextZinsliste } from './zinslisteTableColumns';
import { useZinslisteVerarbeitungsstandQuery } from '../../gql/ZinslisteQueries.types';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { ZinslisteListEntry } from '../../../../types';
import { HandleTableSorting } from '../../../../shared/typeHelpers';

type Props = {
  zinslisteList?: ZinslisteListEntry[];
  loading: boolean;
  pagination: PaginationProps;
  handleTableSorting: HandleTableSorting;
};

const ZinslisteTable: FC<Props> = ({ zinslisteList, loading, pagination, handleTableSorting }) => {
  const components = {
    body: {
      row: CustomRow,
    },
  };

  return (
    <TableWithColSelector<ZinslisteListEntry>
      size="small"
      tableLayout="auto"
      locale={{
        emptyText: <Empty description={<span>Keine Zinslisten vorhanden</span>} />,
      }}
      loading={loading}
      scroll={{ x: 900 }}
      pagination={pagination}
      components={components}
      columns={zinslisteTableColumns}
      dataSource={zinslisteList}
      onChange={handleTableSorting}
      filterIdentifier="main-zinsliste"
      rowKey={(record) => record.zinslisteId}
      // @ts-ignore
      onRow={(record, rowIndex) => ({
        rowindex: rowIndex,
        record,
        objektId: record.objektId,
      })}
    />
  );
};

const CustomRow = (props: any) => {
  if (!props.record) {
    return <tr {...props} />;
  }
  return <ZinslisteTableRow {...props} />;
};

const ZinslisteTableRow = (props: any) => {
  const { objektId, ...restProps } = props;

  const { data: dataVerarbeitungsstand, loading: isLoading } = useZinslisteVerarbeitungsstandQuery({
    variables: { objektId, zinslisteId: props.record.zinslisteId },
  });

  const verarbeitungsstand = dataVerarbeitungsstand?.getZinslisteVerarbeitungsstand.data;

  const contextValues = useMemo(
    () => ({
      isLoading,
      verarbeitungsstand,
    }),
    [isLoading, verarbeitungsstand]
  );

  return (
    <RowContextZinsliste.Provider value={contextValues}>
      <tr {...restProps} />
    </RowContextZinsliste.Provider>
  );
};

export default ZinslisteTable;
