import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestartTopAbrechnungGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartTopAbrechnungGenerierlaufMutation = {
  actionRestartTopAbrechnungGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RestartTopAbrechnungGenerierlaufDocument = gql`
  mutation RestartTopAbrechnungGenerierlauf($generierlaufId: ID!) {
    actionRestartTopAbrechnungGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartTopAbrechnungGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<RestartTopAbrechnungGenerierlaufMutation, RestartTopAbrechnungGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestartTopAbrechnungGenerierlaufMutation, RestartTopAbrechnungGenerierlaufMutationVariables>(
    RestartTopAbrechnungGenerierlaufDocument,
    options
  );
}
export type RestartTopAbrechnungGenerierlaufMutationHookResult = ReturnType<typeof useRestartTopAbrechnungGenerierlaufMutation>;
export type RestartTopAbrechnungGenerierlaufMutationResult = Apollo.MutationResult<RestartTopAbrechnungGenerierlaufMutation>;
export type RestartTopAbrechnungGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartTopAbrechnungGenerierlaufMutation,
  RestartTopAbrechnungGenerierlaufMutationVariables
>;
