import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { FormikProps } from 'formik';
import { Tag, Typography } from 'antd';
import { useObjektListQuery } from '../../features/Objekt/gql/ObjektQueries.types';
import { selectFilterOption } from '../../helpers/selectHelper';
import { AbrechnungType } from '../../types';

type Props = {
  name: string;
  formikProps: FormikProps<any>;
  abrechnungType?: AbrechnungType;
} & SelectProps;

const ObjektSelectWithAllTag: FC<Props> = ({ name, formikProps, abrechnungType, ...restProps }) => {
  // INFO: For some reason restProps contains onChange even though it is not passed via props
  const { onChange: onChangeNotToUse, ...propsWithoutOnChange } = restProps;

  const { data, loading } = useObjektListQuery({
    variables: {
      abrechnungType,
    },
  });
  const objektList = data?.getObjektList.data.contentList || [];

  const onChange = (value: string[], option: any | any[]) => {
    if (value.length === 0 || value[value.length - 1] === ALLE_TAG_TEXT) {
      formikProps.setFieldValue(name, [ALLE_TAG_TEXT]);
    } else {
      const newValue = value.filter((val: string) => val !== ALLE_TAG_TEXT);
      formikProps.setFieldValue(name, newValue);
    }
    restProps.onChange?.(value, option);
  };

  const tagRender = (props: { label: React.ReactNode; value: any; onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void }) => {
    const { label, value, onClose } = props;

    return (
      <Tag closable={value !== ALLE_TAG_TEXT} onClose={onClose}>
        {label}
      </Tag>
    );
  };

  return (
    <Select
      name={name}
      loading={loading}
      placeholder="Objekt auswählen"
      showSearch
      filterOption={selectFilterOption}
      mode="multiple"
      style={{ width: '100%' }}
      onChange={onChange}
      tagRender={tagRender}
      defaultValue={[ALLE_TAG_TEXT]}
      {...propsWithoutOnChange}
    >
      <Select.Option key={ALLE_TAG_TEXT} value={ALLE_TAG_TEXT} style={{ backgroundColor: '#f5f5f5' }}>
        <Typography.Text>{ALLE_TAG_TEXT}</Typography.Text>
      </Select.Option>
      {objektList.map((objekt) => (
        <Select.Option key={objekt.objektId} value={objekt.objektId}>
          <Typography.Text>{objekt.kurzBezeichnung}</Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export const ALLE_TAG_TEXT = 'Alle';

export default ObjektSelectWithAllTag;
