import { AddressesAndContacts, AddressType } from '../../../../../types';

export const buildPersonenbezugAddress = (addressesAndContacts?: AddressesAndContacts) => {
  const hauptAddress = addressesAndContacts?.hauptAddress;
  let address = '';
  if (hauptAddress) {
    address =
      hauptAddress.type === AddressType.Street
        ? `${hauptAddress.street} ${hauptAddress.houseEntranceApartmentNumber}, ${hauptAddress.zipCode} ${hauptAddress.city}`
        : `${hauptAddress.postofficeBoxNumber}, ${hauptAddress.postofficeBoxZipCode} ${hauptAddress.city}`;
  } else {
    const streetAddressList = addressesAndContacts?.streetAddressList;
    const postofficeBoxAddressList = addressesAndContacts?.postofficeBoxAddressList;
    if (streetAddressList && streetAddressList?.length > 0) {
      address = `${streetAddressList[0].street} ${streetAddressList[0].houseEntranceApartmentNumber}, ${streetAddressList[0].zipCode} ${streetAddressList[0].city}`;
    }
    if (postofficeBoxAddressList && postofficeBoxAddressList?.length > 0) {
      address = `${postofficeBoxAddressList[0].postofficeBoxNumber} ${postofficeBoxAddressList[0].postofficeBoxZipCode}, ${postofficeBoxAddressList[0].city} `;
    }
  }
  return address;
};
