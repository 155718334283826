import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HeAbrechnungApplyAllDeviationsMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  heAbrechnungId: Types.Scalars['ID']['input'];
}>;

export type HeAbrechnungApplyAllDeviationsMutation = {
  actionHeAbrechnungApplyAllDeviations: {
    data: { heAbrechnungId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const HeAbrechnungApplyAllDeviationsDocument = gql`
  mutation HeAbrechnungApplyAllDeviations($objektId: ID!, $heAbrechnungId: ID!) {
    actionHeAbrechnungApplyAllDeviations(objektId: $objektId, heAbrechnungId: $heAbrechnungId) {
      data {
        heAbrechnungId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useHeAbrechnungApplyAllDeviationsMutation(
  baseOptions?: Apollo.MutationHookOptions<HeAbrechnungApplyAllDeviationsMutation, HeAbrechnungApplyAllDeviationsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<HeAbrechnungApplyAllDeviationsMutation, HeAbrechnungApplyAllDeviationsMutationVariables>(
    HeAbrechnungApplyAllDeviationsDocument,
    options
  );
}
export type HeAbrechnungApplyAllDeviationsMutationHookResult = ReturnType<typeof useHeAbrechnungApplyAllDeviationsMutation>;
export type HeAbrechnungApplyAllDeviationsMutationResult = Apollo.MutationResult<HeAbrechnungApplyAllDeviationsMutation>;
export type HeAbrechnungApplyAllDeviationsMutationOptions = Apollo.BaseMutationOptions<
  HeAbrechnungApplyAllDeviationsMutation,
  HeAbrechnungApplyAllDeviationsMutationVariables
>;
