import * as Yup from 'yup';
import { indAgreeIndexThresholdFormFields } from './indexThresholdFormMapper';
import {
  entityHasToBeNonNegative,
  entityIsRequired,
  entityMustBeSelected,
  entityMustBeSmallerThanNumber,
} from '../../../../../../../../../components/message/validationMsg';
import { isThresholdTypePercent } from '../shared/indexationAgreementFormHelpers';

export const indexThresholdFormValidationSchema = Yup.object().shape({
  [indAgreeIndexThresholdFormFields.effectiveAfterMonths]: Yup.number().nullable(),
  [indAgreeIndexThresholdFormFields.indexOld]: Yup.number().nullable(),
  [indAgreeIndexThresholdFormFields.indexSeriesId]: Yup.string().required(entityIsRequired('Indexreihe')),
  [indAgreeIndexThresholdFormFields.minimumLimit]: Yup.number().nullable(),
  [indAgreeIndexThresholdFormFields.monthYearOld]: Yup.string().required(entityIsRequired('Monat und Jahr', true)),
  [indAgreeIndexThresholdFormFields.shareInPercentToApply]: Yup.number()
    .nullable()
    .min(0, entityHasToBeNonNegative('Anteil'))
    .max(100, entityMustBeSmallerThanNumber('Anteil', 100)),
  [indAgreeIndexThresholdFormFields.subsequentBillingObjektVorschreibungspositionId]: Yup.string().when(
    [indAgreeIndexThresholdFormFields.subsequentBilling],
    ([subsequentBilling], schema) => (subsequentBilling ? schema.required(entityMustBeSelected('Vorschreibungsposition')) : schema.nullable())
  ),
  [indAgreeIndexThresholdFormFields.thresholdType]: Yup.string().required(entityMustBeSelected('Typ')),
  [indAgreeIndexThresholdFormFields.threshold]: Yup.number()
    .nullable()
    .when([indAgreeIndexThresholdFormFields.thresholdType], ([thresholdType], schema) =>
      isThresholdTypePercent(thresholdType)
        ? schema.min(0, entityHasToBeNonNegative('Schwellenwert')).max(100, entityMustBeSmallerThanNumber('Schwellenwert', 100))
        : schema.min(0, entityHasToBeNonNegative('Schwellenwert'))
    ),
  [indAgreeIndexThresholdFormFields.validFrom]: Yup.string().required(entityIsRequired('Monat und Jahr', true)),
});
