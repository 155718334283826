import React, { FC, PropsWithChildren } from 'react';
import { Skeleton } from 'antd';

type Props = {
  isLoading: boolean;
  isAvatar?: boolean;
};

/**
 * <h2>Usage</h2>
 * In table columns when Data is loaded e.g. when an extra backend call is needed to show some data
 *
 * <h2>Component Info</h2>
 *  <ul>
 *    <li>Skeleton will take only one line and the width of the column</li>
 *  </ul>
 */
export const LoadingCol: FC<Props & PropsWithChildren> = ({ isLoading, isAvatar, children }) => {
  if (isLoading) {
    return <Skeleton loading={isLoading} active avatar={isAvatar} paragraph={false} />;
  }
  return <>{children}</>;
};
