import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import AnnualPeriodToDate, { AnnualPeriodToDateProps } from './AnnualPeriodToDate';

type Props = {
  text: string;
} & AnnualPeriodToDateProps;

const TableColumnHeadingWithPeriod: FC<Props> = ({ text, ...restProps }) => (
  <Space direction="vertical" size={0}>
    <Typography.Text>{text}</Typography.Text>
    <AnnualPeriodToDate showBrackets {...restProps} />
  </Space>
);

export default TableColumnHeadingWithPeriod;
