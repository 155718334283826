import { useEffect } from 'react';
import { isEqual } from 'lodash';
import { BookingSuggestionFormValues } from './buchungsmaskeFormMapper';

const useSettingFormDirty = (
  initial: BookingSuggestionFormValues,
  current: BookingSuggestionFormValues,
  setIsFormDirty: (toggle: boolean) => void
) => {
  const isAvailableAmountDirty = initial.availableAmount !== current.availableAmount;
  const isBuchungsanweisungDirty = !isEqual(initial.buchungsanweisung, current.buchungsanweisung);

  const initialOffenePostenAuszifferungAmountList = initial.offenePostenForClearingSuggestionList
    .flatMap((item) => item.auszifferungList)
    .map((item) => item.amount);

  const currentOffenePostenAuszifferungAmountList = current.offenePostenForClearingSuggestionList
    .flatMap((item) => item.auszifferungList)
    .map((item) => item.amount);

  const isClearingSuggestionsDirty =
    initial.offenePostenForClearingSuggestionList.length !== current.offenePostenForClearingSuggestionList.length ||
    JSON.stringify(initialOffenePostenAuszifferungAmountList) !== JSON.stringify(currentOffenePostenAuszifferungAmountList);

  const isDirty = isAvailableAmountDirty || isBuchungsanweisungDirty || isClearingSuggestionsDirty;

  useEffect(() => {
    setIsFormDirty(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);
};

export default useSettingFormDirty;
