import React from 'react';
import { Typography } from 'antd';
import { useParams } from 'react-router-dom';
import AdminChangeLogTable from './AdminChangeLogTable';

type RouteParams = { username: string };

const AdminChangeLogListing = () => {
  const { username } = useParams() as RouteParams;

  return (
    <>
      <Typography.Title level={4}>Changelogs</Typography.Title>
      <AdminChangeLogTable username={username} />
    </>
  );
};

export default AdminChangeLogListing;
