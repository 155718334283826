import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { IndexSeriesFieldsFragmentDoc } from '../../../IndexSeries/gql/IndexSeriesFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FirmendatenIndexSeriesListQueryVariables = Types.Exact<{
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  indexType?: Types.InputMaybe<Types.IndexType>;
}>;

export type FirmendatenIndexSeriesListQuery = {
  getFirmendatenIndexSeriesList: {
    data: Array<{
      chainingFactor?: number | null;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      csvColumnImportId?: string | null;
      csvColumnIndexPoint?: string | null;
      csvColumnMonthYear?: string | null;
      csvRowImportId?: string | null;
      deletable: boolean;
      firmendatenId?: string | null;
      importUrl?: string | null;
      indexSeriesId: string;
      name: string;
      updatedByMitarbeiterId?: string | null;
      indexSeriesEntryList: Array<{
        annualAverageFinal?: number | null;
        annualAveragePreliminary?: number | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        indexSeriesEntryId: string;
        updatedByMitarbeiterId?: string | null;
        year: number;
        indexPointList: Array<{
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          finalValue?: number | null;
          indexPointId: string;
          month: string;
          preliminaryValue?: number | null;
          updatedByMitarbeiterId?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      indexType: { text: string; value: Types.IndexType };
      sourceType: { text: string; value: Types.IndexSeriesSourceType };
      status?: { text: string; value: Types.IndexSeriesStatus } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FirmendatenIndexSeriesQueryVariables = Types.Exact<{
  indexSeriesId: Types.Scalars['ID']['input'];
}>;

export type FirmendatenIndexSeriesQuery = {
  getFirmendatenIndexSeries: {
    data: {
      chainingFactor?: number | null;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      csvColumnImportId?: string | null;
      csvColumnIndexPoint?: string | null;
      csvColumnMonthYear?: string | null;
      csvRowImportId?: string | null;
      deletable: boolean;
      firmendatenId?: string | null;
      importUrl?: string | null;
      indexSeriesId: string;
      name: string;
      updatedByMitarbeiterId?: string | null;
      indexSeriesEntryList: Array<{
        annualAverageFinal?: number | null;
        annualAveragePreliminary?: number | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        indexSeriesEntryId: string;
        updatedByMitarbeiterId?: string | null;
        year: number;
        indexPointList: Array<{
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          finalValue?: number | null;
          indexPointId: string;
          month: string;
          preliminaryValue?: number | null;
          updatedByMitarbeiterId?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      indexType: { text: string; value: Types.IndexType };
      sourceType: { text: string; value: Types.IndexSeriesSourceType };
      status?: { text: string; value: Types.IndexSeriesStatus } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FirmendatenIndexSeriesFinalValueQueryVariables = Types.Exact<{
  indexSeriesId: Types.Scalars['ID']['input'];
  month: Types.Scalars['Int']['input'];
  year: Types.Scalars['Int']['input'];
}>;

export type FirmendatenIndexSeriesFinalValueQuery = {
  getIndexSeriesFinalValue: {
    data?: number | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const FirmendatenIndexSeriesListDocument = gql`
  query FirmendatenIndexSeriesList($includeArchiviert: Boolean, $indexType: IndexType) {
    getFirmendatenIndexSeriesList(includeArchiviert: $includeArchiviert, indexType: $indexType) {
      data {
        ...IndexSeriesFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${IndexSeriesFieldsFragmentDoc}
`;
export function useFirmendatenIndexSeriesListQuery(
  baseOptions?: Apollo.QueryHookOptions<FirmendatenIndexSeriesListQuery, FirmendatenIndexSeriesListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenIndexSeriesListQuery, FirmendatenIndexSeriesListQueryVariables>(FirmendatenIndexSeriesListDocument, options);
}
export function useFirmendatenIndexSeriesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenIndexSeriesListQuery, FirmendatenIndexSeriesListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenIndexSeriesListQuery, FirmendatenIndexSeriesListQueryVariables>(FirmendatenIndexSeriesListDocument, options);
}
export function useFirmendatenIndexSeriesListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FirmendatenIndexSeriesListQuery, FirmendatenIndexSeriesListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenIndexSeriesListQuery, FirmendatenIndexSeriesListQueryVariables>(
    FirmendatenIndexSeriesListDocument,
    options
  );
}
export type FirmendatenIndexSeriesListQueryHookResult = ReturnType<typeof useFirmendatenIndexSeriesListQuery>;
export type FirmendatenIndexSeriesListLazyQueryHookResult = ReturnType<typeof useFirmendatenIndexSeriesListLazyQuery>;
export type FirmendatenIndexSeriesListSuspenseQueryHookResult = ReturnType<typeof useFirmendatenIndexSeriesListSuspenseQuery>;
export type FirmendatenIndexSeriesListQueryResult = Apollo.QueryResult<FirmendatenIndexSeriesListQuery, FirmendatenIndexSeriesListQueryVariables>;
export const FirmendatenIndexSeriesDocument = gql`
  query FirmendatenIndexSeries($indexSeriesId: ID!) {
    getFirmendatenIndexSeries(indexSeriesId: $indexSeriesId) {
      data {
        ...IndexSeriesFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${IndexSeriesFieldsFragmentDoc}
`;
export function useFirmendatenIndexSeriesQuery(
  baseOptions: Apollo.QueryHookOptions<FirmendatenIndexSeriesQuery, FirmendatenIndexSeriesQueryVariables> &
    ({ variables: FirmendatenIndexSeriesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenIndexSeriesQuery, FirmendatenIndexSeriesQueryVariables>(FirmendatenIndexSeriesDocument, options);
}
export function useFirmendatenIndexSeriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenIndexSeriesQuery, FirmendatenIndexSeriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenIndexSeriesQuery, FirmendatenIndexSeriesQueryVariables>(FirmendatenIndexSeriesDocument, options);
}
export function useFirmendatenIndexSeriesSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FirmendatenIndexSeriesQuery, FirmendatenIndexSeriesQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenIndexSeriesQuery, FirmendatenIndexSeriesQueryVariables>(FirmendatenIndexSeriesDocument, options);
}
export type FirmendatenIndexSeriesQueryHookResult = ReturnType<typeof useFirmendatenIndexSeriesQuery>;
export type FirmendatenIndexSeriesLazyQueryHookResult = ReturnType<typeof useFirmendatenIndexSeriesLazyQuery>;
export type FirmendatenIndexSeriesSuspenseQueryHookResult = ReturnType<typeof useFirmendatenIndexSeriesSuspenseQuery>;
export type FirmendatenIndexSeriesQueryResult = Apollo.QueryResult<FirmendatenIndexSeriesQuery, FirmendatenIndexSeriesQueryVariables>;
export const FirmendatenIndexSeriesFinalValueDocument = gql`
  query FirmendatenIndexSeriesFinalValue($indexSeriesId: ID!, $month: Int!, $year: Int!) {
    getIndexSeriesFinalValue(indexSeriesId: $indexSeriesId, month: $month, year: $year) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFirmendatenIndexSeriesFinalValueQuery(
  baseOptions: Apollo.QueryHookOptions<FirmendatenIndexSeriesFinalValueQuery, FirmendatenIndexSeriesFinalValueQueryVariables> &
    ({ variables: FirmendatenIndexSeriesFinalValueQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenIndexSeriesFinalValueQuery, FirmendatenIndexSeriesFinalValueQueryVariables>(
    FirmendatenIndexSeriesFinalValueDocument,
    options
  );
}
export function useFirmendatenIndexSeriesFinalValueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenIndexSeriesFinalValueQuery, FirmendatenIndexSeriesFinalValueQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenIndexSeriesFinalValueQuery, FirmendatenIndexSeriesFinalValueQueryVariables>(
    FirmendatenIndexSeriesFinalValueDocument,
    options
  );
}
export function useFirmendatenIndexSeriesFinalValueSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FirmendatenIndexSeriesFinalValueQuery, FirmendatenIndexSeriesFinalValueQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenIndexSeriesFinalValueQuery, FirmendatenIndexSeriesFinalValueQueryVariables>(
    FirmendatenIndexSeriesFinalValueDocument,
    options
  );
}
export type FirmendatenIndexSeriesFinalValueQueryHookResult = ReturnType<typeof useFirmendatenIndexSeriesFinalValueQuery>;
export type FirmendatenIndexSeriesFinalValueLazyQueryHookResult = ReturnType<typeof useFirmendatenIndexSeriesFinalValueLazyQuery>;
export type FirmendatenIndexSeriesFinalValueSuspenseQueryHookResult = ReturnType<typeof useFirmendatenIndexSeriesFinalValueSuspenseQuery>;
export type FirmendatenIndexSeriesFinalValueQueryResult = Apollo.QueryResult<
  FirmendatenIndexSeriesFinalValueQuery,
  FirmendatenIndexSeriesFinalValueQueryVariables
>;
