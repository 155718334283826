import React, { FC } from 'react';
import { Modal, Tooltip, Typography } from 'antd';
import { BankStatement } from '../../../../../../types';
import { showSuccessMsgDelete } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { useDeleteBankStatementMutation } from '../../../../gql/DataCarrierMutations.types';

type Props = {
  bankstatement: BankStatement;
  onAction: () => void;
  dataCarrierId: string;
};

const BankstatementTableActions: FC<Props> = ({ bankstatement, onAction, dataCarrierId }) => {
  const [runDelete] = useDeleteBankStatementMutation({
    onCompleted: () => {
      showSuccessMsgDelete('Datenträger');
      onAction();
    },
    variables: {
      dataCarrierId,
      bankStatementId: bankstatement.bankStatementId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return bankstatement.deletable ? (
    <a onClick={showConfirmDelete(runDelete, bankstatement)}>Löschen</a>
  ) : (
    <Tooltip title="Die empfangenen Bankbuchungen aus dem Kontoauszug wurden bereits verbucht.">
      <Typography.Text type="secondary">Löschen</Typography.Text>
    </Tooltip>
  );
};

const showConfirmDelete = (runDelete: () => void, bankstatement: BankStatement) => () => {
  Modal.confirm({
    title: `Möchten Sie den Kontoauszug löschen?`,
    content: `Kontoauszugsnummer: ${bankstatement.statementNumber}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default BankstatementTableActions;
