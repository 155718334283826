import React from 'react';
import { Typography } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { EuroAmount } from '../../../../../../components/Number';
import CustomFormattedDate from '../../../../../../components/FormattedDate/CustomFormattedDate';
import { AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttribute } from '../../../../../../types';
import EntryDetailsTableActions from './EntryDetailsTableActions';

const entryDetailsTableColumns = (rechtstreagerId: string): ColumnProps<AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttribute>[] => [
  { title: 'Auftragsart/Eingangsrechnung', render: (text, record) => <Typography.Text>{record.belegType}</Typography.Text> },
  { title: 'Beleg-Nr', render: (text, record) => <Typography.Text>{record.belegnummer}</Typography.Text> },
  { title: 'Beleg-Dat', render: (text, record) => <CustomFormattedDate value={record.belegdatum} /> },
  { title: 'Netto Summe', align: 'right', render: (text, record) => <EuroAmount value={record.sumNetto} /> },
  { title: 'Ust Summe', align: 'right', render: (text, record) => <EuroAmount value={record.sumUst} /> },
  { title: 'Brutto Summe', align: 'right', render: (text, record) => <EuroAmount value={record.sumBrutto} /> },
  { title: 'Aktion', render: (text, record) => <EntryDetailsTableActions record={record} /> },
];

export default entryDetailsTableColumns;
