import React, { FC, useState } from 'react';
import { Divider, List, Modal, ModalProps, Typography } from 'antd';

import { useAuth } from '../../shared/Auth/authContext';
import { useFileInfoListForMitarbeiterQuery } from '../../features/Document/gql/FileStorageQueries.types';
import DebouncedTextSearch from '../Input-antd/DebouncedTextSearch';
import DataWithShortenedTextAndExtraInfo from '../Helpers/DataWithShortenedTextAndExtraInfo';
import { FileInfo } from '../../types';
import CustomFormattedDate from '../FormattedDate/CustomFormattedDate';

type FileUploadModalProps = {
  onSelectFile: (data: FileInfo) => void;
} & ModalProps;

const FileUploadModal: FC<FileUploadModalProps> = ({ onSelectFile, ...restProps }) => {
  const { mitarbeiter } = useAuth();
  const mitarbeiterId = mitarbeiter?.mitarbeiterId;

  return (
    <Modal title="Datei auswählen" destroyOnClose maskClosable={false} footer={false} {...restProps}>
      {mitarbeiterId && <MitarbeiterFileList mitarbeiterId={mitarbeiterId} onSelectFile={onSelectFile} />}
    </Modal>
  );
};

type MitarbeiterFileListProps = {
  mitarbeiterId: string;
  onSelectFile: (data: FileInfo) => void;
};

const MitarbeiterFileList: FC<MitarbeiterFileListProps> = ({ mitarbeiterId, onSelectFile }) => {
  const [filenameForSearch, setFilenameForSearch] = useState<string>('');
  const { data } = useFileInfoListForMitarbeiterQuery({
    variables: {
      mitarbeiterId,
      name: filenameForSearch,
    },
  });

  const fileInfoList = data?.getFileInfoListForMitarbeiter.data?.contentList;
  const loading = !fileInfoList;

  return (
    <>
      <DebouncedTextSearch style={{ width: '100%' }} placeholder="Nach Datei suchen…" onChange={(value) => setFilenameForSearch(value.trim())} />
      <Divider />
      <List
        loading={loading}
        rowKey={(item) => item.fileId}
        size="small"
        bordered={false}
        dataSource={fileInfoList}
        renderItem={(item) => <FileView file={item} onSelectFile={onSelectFile} />}
      />
    </>
  );
};

type FileViewProps = {
  file: FileInfo;
  onSelectFile: (data: FileInfo) => void;
};

const FileView: FC<FileViewProps> = ({ file, onSelectFile }) => {
  return (
    <List.Item onClick={() => onSelectFile(file)} style={{ cursor: 'pointer' }}>
      <DataWithShortenedTextAndExtraInfo maxTextLength={40} text={file.name}>
        {(shortenedText) => <Typography.Text>{shortenedText}</Typography.Text>}
      </DataWithShortenedTextAndExtraInfo>
      <CustomFormattedDate value={file.createTs} />
    </List.Item>
  );
};
export default FileUploadModal;
