import React, { FC } from 'react';
import { Skeleton, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import CardWithFooter from '../../../components/Card';
import CardTitle from '../../../components/Card/CardTitle';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { findEmailDeliveryLabel } from '../emailDeliveryHelpers';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';
import { generatePathToVerarbeitungInfoMailDeliverySendDetailsPage } from '../../Verarbeitung/verarbeitungApiPaths';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';
import { MailData } from '../../../types';

type Props = {
  emailDelivery: MailData;
  children?: React.ReactNode;
  extra?: React.ReactNode;
};

const EmailDeliveryDetailsCard: FC<Props> = ({ emailDelivery, children, extra }) => {
  const generierlaufLabel = findEmailDeliveryLabel(emailDelivery, 'generierlaufId');

  return (
    <CardWithFooter
      loading={false}
      style={{ minWidth: '320px' }}
      size="default"
      createTs={emailDelivery.createTs}
      userId={emailDelivery.createdBy}
      mitarbeiterId={emailDelivery.createdByMitarbeiterId}
      title={<CardTitle title={emailDelivery.useCase.text} status={emailDelivery.status} />}
      extra={extra}
    >
      <Skeleton loading={false}>
        <Typography.Title level={5}>E-Mail</Typography.Title>
        <CardDetailsInfoRow infoRowTitle="von">
          <Typography.Text>{emailDelivery.sender}</Typography.Text>
        </CardDetailsInfoRow>
        <CardDetailsInfoRow infoRowTitle="an">
          <Space direction="vertical" size={2}>
            <DataWithShortenedText maxTextLength={20} text={emailDelivery.recipient}>
              {(shortenedText) => <Typography.Text>{shortenedText}</Typography.Text>}
            </DataWithShortenedText>
          </Space>
        </CardDetailsInfoRow>
        {children}
        <CardDetailsInfoRow infoRowTitle="Versendet am">
          {/* eslint-disable-next-line no-nested-ternary */}
          {emailDelivery.sentTs ? (
            generierlaufLabel ? (
              <Link to={generatePathToVerarbeitungInfoMailDeliverySendDetailsPage(generierlaufLabel.value)}>
                <FormattedDateTime createdTs={emailDelivery.createTs} />
              </Link>
            ) : (
              <FormattedDateTime createdTs={emailDelivery.createTs} />
            )
          ) : (
            <TextForEmptyValue textToShow="minus" />
          )}
        </CardDetailsInfoRow>
      </Skeleton>
    </CardWithFooter>
  );
};

export default EmailDeliveryDetailsCard;
