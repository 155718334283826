import React, { FC } from 'react';
import { Empty } from 'antd';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import zahlungsverlaufTableColumns from './zahlungsverlaufTableColumns';
import { HeVertragZahlungsverlaufListEntry } from '../../../../../types';

type Props = {
  objektId: string;
  heVertragId: string;
  onSuccess: () => void;
  loading: boolean;
  rechnungsAusstellerId: string;
  zahlungsverlaufList?: HeVertragZahlungsverlaufListEntry[];
};

const ZahlungsverlaufTable: FC<Props> = ({ objektId, heVertragId, loading, onSuccess, rechnungsAusstellerId, zahlungsverlaufList }) => {
  return (
    <TableWithColSelector<HeVertragZahlungsverlaufListEntry>
      loading={loading}
      locale={{
        emptyText: <Empty description={<span>Keine Positionen vorhanden</span>} />,
      }}
      size="small"
      pagination={{ showSizeChanger: true, defaultPageSize: 20 }}
      dataSource={zahlungsverlaufList}
      columns={zahlungsverlaufTableColumns(objektId, heVertragId, onSuccess, rechnungsAusstellerId)}
      rowKey={(record) => record.createTs}
      filterIdentifier="he-vertrag-zahlungsverlauf"
    />
  );
};

export default ZahlungsverlaufTable;
