import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Switch } from 'formik-antd';
import { Space, Typography } from 'antd';
import { SubAdministrationSettings } from '../../../../../../types';
import { useUpdateSubAdministrationSettingsMutation } from '../../gql/RechnungsAusstellerMutations.types';
import { showSuccessMsgUpdate } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import FormButtons from '../../../../../../components/Button/FormButtons';
import { subSettingsFormFields, SubSettingsFormValues, subSettingsFormValuesInitialValues } from './formMapper';
import DrawerRow from '../../../../../../components/Drawer/DrawerRow';
import FormItemWithFieldHelp from '../../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  subAdministrationSettings: SubAdministrationSettings;
  rechtstraegerId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

const SubAdministrationSettingsUpdateForm: FC<Props> = ({ subAdministrationSettings, rechtstraegerId, onCancel, onSuccess }) => {
  const [updateSettings, { loading }] = useUpdateSubAdministrationSettingsMutation({
    onCompleted: () => {
      showSuccessMsgUpdate('Subverwaltung Einstellungen');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<SubSettingsFormValues>
      initialValues={subSettingsFormValuesInitialValues(subAdministrationSettings)}
      onSubmit={(values, { setSubmitting }) => {
        updateSettings({
          variables: { rechtstraegerId, input: { paymentClearsIncomingInvoice: values.paymentClearsIncomingInvoice } },
        }).finally(() => setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <DrawerRow
            rowTitle="Subverwaltung"
            content={
              <>
                <FormItemWithFieldHelp
                  name={subSettingsFormFields.paymentClearsIncomingInvoice}
                  label="Direktzahlung an WEG ziffert Eingangsrechnung aus"
                  style={{ margin: 0 }}
                >
                  <Space>
                    <Switch id={subSettingsFormFields.paymentClearsIncomingInvoice} name={subSettingsFormFields.paymentClearsIncomingInvoice} />
                    <Typography.Text>{formikProps.values.paymentClearsIncomingInvoice ? 'Ja' : 'Nein'}</Typography.Text>
                  </Space>
                </FormItemWithFieldHelp>
              </>
            }
          />
          <FormButtons updateMode onCancel={onCancel} isOkLoading={loading} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default SubAdministrationSettingsUpdateForm;
