import React, { FC } from 'react';
import { Select } from 'formik-antd';
import { SelectProps } from 'antd';
import { useObjektInfoFeldTypeListQuery } from '../gql/ObjektInfoFeldTypeQueries.types';

type ObjektInfoFeldTypeFormSelectProps = { name: string } & SelectProps;

const ObjektInfoFeldTypeFormSelect: FC<ObjektInfoFeldTypeFormSelectProps> = ({ name, ...restProps }) => {
  const { data, loading } = useObjektInfoFeldTypeListQuery();

  const objektInfoFeldTypeList = data?.getObjektInfoFeldTypeList.data ?? [];

  return (
    <Select name={name} loading={loading} id={name} placeholder="Typ auswählen" allowClear style={{ minWidth: '160px' }} {...restProps}>
      {objektInfoFeldTypeList.map((infoFeldType) => (
        <Select.Option value={infoFeldType.value} key={infoFeldType.value}>
          {infoFeldType.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ObjektInfoFeldTypeFormSelect;
