import React, { FC } from 'react';
import { Divider, Modal, Skeleton, Space, Typography } from 'antd';

import CardWithFooter from '../../../../components/Card';
import TopAbrechnungCreateForm from '../Top/Form/Create/TopAbrechnungCreateForm';
import { useToggle } from '../../../../hooks/useToggle';
import CardTitle from '../../../../components/Card/CardTitle';
import BkOrWeAbrechnungTopAbrStatus from './BkOrWeAbrechnungTopAbrStatus';
import BkOrWeAbrechnungCardActions from './BkOrWeAbrechnungCardActions';
import { ObjektAbrechnung } from '../../../../types';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  abrechnung: ObjektAbrechnung;
  loading: boolean;
  onSuccess: () => void;
  objektId: string;
};

const BkOrWeAbrechnungCard: FC<Props> = ({ abrechnung, loading, onSuccess, objektId }) => {
  const [isCollapsed, onCollapse] = useToggle();

  return (
    <>
      <CardWithFooter
        loading={loading}
        style={{ minWidth: '320px' }}
        size="default"
        bordered={false}
        title={<CardTitle title={abrechnung.bezeichnung} status={abrechnung.status} />}
        extra={<BkOrWeAbrechnungCardActions abrechnung={abrechnung} objektId={objektId} onCollapse={onCollapse} onSuccess={onSuccess} />}
        createTs={abrechnung.createTs}
        userId={abrechnung.createdBy}
        mitarbeiterId={abrechnung.createdByMitarbeiterId}
      >
        <Skeleton active loading={loading}>
          <Space direction="horizontal">
            <Space align="baseline">
              <Typography.Text type="secondary">von</Typography.Text>
              <CustomFormattedDate value={abrechnung.fromInclusive} />
            </Space>
            <Space align="baseline">
              <Typography.Text type="secondary">bis</Typography.Text>
              <CustomFormattedDate value={abrechnung.toInclusive} />
            </Space>
          </Space>
          <Divider dashed />

          <Typography.Title level={5}>Status</Typography.Title>
          <BkOrWeAbrechnungTopAbrStatus abrechnung={abrechnung} />
        </Skeleton>
      </CardWithFooter>
      <Modal title="Abrechnung erstellen" onCancel={onCollapse} open={isCollapsed} destroyOnClose maskClosable={false} footer={false}>
        <TopAbrechnungCreateForm
          objektId={objektId}
          abrechnung={abrechnung}
          onSuccess={() => {
            onSuccess();
            onCollapse();
          }}
          onCancel={onCollapse}
        />
      </Modal>
    </>
  );
};

export default BkOrWeAbrechnungCard;
