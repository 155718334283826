import * as Types from '../../../../../../types';

import { gql } from '@apollo/client';
import { IndexSeriesFieldsFragmentDoc } from '../../../../../IndexSeries/gql/IndexSeriesFragments.types';
export type VertragVposIndexationAgreementCategoryFieldsFragment = { basedOnUsableArea: boolean };

export type VertragVposIndexationAgreementIndexAnnualFieldsFragment = {
  annualAverage: boolean;
  minimumLimit?: number | null;
  shareInPercentToApply?: number | null;
  subsequentBilling: boolean;
  threshold: number;
  effectiveWithMonth?: { text: string; value: Types.Month } | null;
  monthOfIndexPoint: { text: string; value: Types.Month };
  subsequentBillingObjektVorschreibungsposition?: { bezeichnung: string; objektVorschreibungspositionId: string; kurzBezeichnung: string } | null;
  thresholdType: { text: string; value: Types.ThresholdType };
};

export type VertragVposIndexationAgreementIndexThresholdFieldsFragment = {
  effectiveAfterMonths?: number | null;
  minimumLimit?: number | null;
  shareInPercentToApply?: number | null;
  subsequentBilling: boolean;
  threshold: number;
  subsequentBillingObjektVorschreibungsposition?: { bezeichnung: string; objektVorschreibungspositionId: string; kurzBezeichnung: string } | null;
  thresholdType: { text: string; value: Types.ThresholdType };
};

export type VertragVposIndexationAgreementReferenceValueFieldsFragment = { createTs: string };

export type VertragVposIndexationAgreementFields_VertragVposIndexationAgreementCategory_Fragment = {
  __typename: 'VertragVposIndexationAgreementCategory';
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  indexOld?: number | null;
  indexOldPreliminary: boolean;
  monthYearOld: string;
  updatedByMitarbeiterId?: string | null;
  validFrom: string;
  vertragVposIndexationAgreementId: string;
  basedOnUsableArea: boolean;
  indexSeries: {
    chainingFactor?: number | null;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    csvColumnImportId?: string | null;
    csvColumnIndexPoint?: string | null;
    csvColumnMonthYear?: string | null;
    csvRowImportId?: string | null;
    deletable: boolean;
    firmendatenId?: string | null;
    importUrl?: string | null;
    indexSeriesId: string;
    name: string;
    updatedByMitarbeiterId?: string | null;
    indexSeriesEntryList: Array<{
      annualAverageFinal?: number | null;
      annualAveragePreliminary?: number | null;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      indexSeriesEntryId: string;
      updatedByMitarbeiterId?: string | null;
      year: number;
      indexPointList: Array<{
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        finalValue?: number | null;
        indexPointId: string;
        month: string;
        preliminaryValue?: number | null;
        updatedByMitarbeiterId?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    indexType: { text: string; value: Types.IndexType };
    sourceType: { text: string; value: Types.IndexSeriesSourceType };
    status?: { text: string; value: Types.IndexSeriesStatus } | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  type: { text: string; value: Types.ValuePreservationType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type VertragVposIndexationAgreementFields_VertragVposIndexationAgreementIndexAnnual_Fragment = {
  __typename: 'VertragVposIndexationAgreementIndexAnnual';
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  indexOld?: number | null;
  indexOldPreliminary: boolean;
  monthYearOld: string;
  updatedByMitarbeiterId?: string | null;
  validFrom: string;
  vertragVposIndexationAgreementId: string;
  annualAverage: boolean;
  minimumLimit?: number | null;
  shareInPercentToApply?: number | null;
  subsequentBilling: boolean;
  threshold: number;
  indexSeries: {
    chainingFactor?: number | null;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    csvColumnImportId?: string | null;
    csvColumnIndexPoint?: string | null;
    csvColumnMonthYear?: string | null;
    csvRowImportId?: string | null;
    deletable: boolean;
    firmendatenId?: string | null;
    importUrl?: string | null;
    indexSeriesId: string;
    name: string;
    updatedByMitarbeiterId?: string | null;
    indexSeriesEntryList: Array<{
      annualAverageFinal?: number | null;
      annualAveragePreliminary?: number | null;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      indexSeriesEntryId: string;
      updatedByMitarbeiterId?: string | null;
      year: number;
      indexPointList: Array<{
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        finalValue?: number | null;
        indexPointId: string;
        month: string;
        preliminaryValue?: number | null;
        updatedByMitarbeiterId?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    indexType: { text: string; value: Types.IndexType };
    sourceType: { text: string; value: Types.IndexSeriesSourceType };
    status?: { text: string; value: Types.IndexSeriesStatus } | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  type: { text: string; value: Types.ValuePreservationType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  effectiveWithMonth?: { text: string; value: Types.Month } | null;
  monthOfIndexPoint: { text: string; value: Types.Month };
  subsequentBillingObjektVorschreibungsposition?: { bezeichnung: string; objektVorschreibungspositionId: string; kurzBezeichnung: string } | null;
  thresholdType: { text: string; value: Types.ThresholdType };
};

export type VertragVposIndexationAgreementFields_VertragVposIndexationAgreementIndexThreshold_Fragment = {
  __typename: 'VertragVposIndexationAgreementIndexThreshold';
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  indexOld?: number | null;
  indexOldPreliminary: boolean;
  monthYearOld: string;
  updatedByMitarbeiterId?: string | null;
  validFrom: string;
  vertragVposIndexationAgreementId: string;
  effectiveAfterMonths?: number | null;
  minimumLimit?: number | null;
  shareInPercentToApply?: number | null;
  subsequentBilling: boolean;
  threshold: number;
  indexSeries: {
    chainingFactor?: number | null;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    csvColumnImportId?: string | null;
    csvColumnIndexPoint?: string | null;
    csvColumnMonthYear?: string | null;
    csvRowImportId?: string | null;
    deletable: boolean;
    firmendatenId?: string | null;
    importUrl?: string | null;
    indexSeriesId: string;
    name: string;
    updatedByMitarbeiterId?: string | null;
    indexSeriesEntryList: Array<{
      annualAverageFinal?: number | null;
      annualAveragePreliminary?: number | null;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      indexSeriesEntryId: string;
      updatedByMitarbeiterId?: string | null;
      year: number;
      indexPointList: Array<{
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        finalValue?: number | null;
        indexPointId: string;
        month: string;
        preliminaryValue?: number | null;
        updatedByMitarbeiterId?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    indexType: { text: string; value: Types.IndexType };
    sourceType: { text: string; value: Types.IndexSeriesSourceType };
    status?: { text: string; value: Types.IndexSeriesStatus } | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  type: { text: string; value: Types.ValuePreservationType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  subsequentBillingObjektVorschreibungsposition?: { bezeichnung: string; objektVorschreibungspositionId: string; kurzBezeichnung: string } | null;
  thresholdType: { text: string; value: Types.ThresholdType };
};

export type VertragVposIndexationAgreementFields_VertragVposIndexationAgreementReferenceValue_Fragment = {
  __typename: 'VertragVposIndexationAgreementReferenceValue';
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  indexOld?: number | null;
  indexOldPreliminary: boolean;
  monthYearOld: string;
  updatedByMitarbeiterId?: string | null;
  validFrom: string;
  vertragVposIndexationAgreementId: string;
  indexSeries: {
    chainingFactor?: number | null;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    csvColumnImportId?: string | null;
    csvColumnIndexPoint?: string | null;
    csvColumnMonthYear?: string | null;
    csvRowImportId?: string | null;
    deletable: boolean;
    firmendatenId?: string | null;
    importUrl?: string | null;
    indexSeriesId: string;
    name: string;
    updatedByMitarbeiterId?: string | null;
    indexSeriesEntryList: Array<{
      annualAverageFinal?: number | null;
      annualAveragePreliminary?: number | null;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      indexSeriesEntryId: string;
      updatedByMitarbeiterId?: string | null;
      year: number;
      indexPointList: Array<{
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        finalValue?: number | null;
        indexPointId: string;
        month: string;
        preliminaryValue?: number | null;
        updatedByMitarbeiterId?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    indexType: { text: string; value: Types.IndexType };
    sourceType: { text: string; value: Types.IndexSeriesSourceType };
    status?: { text: string; value: Types.IndexSeriesStatus } | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  type: { text: string; value: Types.ValuePreservationType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type VertragVposIndexationAgreementFieldsFragment =
  | VertragVposIndexationAgreementFields_VertragVposIndexationAgreementCategory_Fragment
  | VertragVposIndexationAgreementFields_VertragVposIndexationAgreementIndexAnnual_Fragment
  | VertragVposIndexationAgreementFields_VertragVposIndexationAgreementIndexThreshold_Fragment
  | VertragVposIndexationAgreementFields_VertragVposIndexationAgreementReferenceValue_Fragment;

export const VertragVposIndexationAgreementCategoryFieldsFragmentDoc = gql`
  fragment VertragVposIndexationAgreementCategoryFields on VertragVposIndexationAgreementCategory {
    basedOnUsableArea
  }
`;
export const VertragVposIndexationAgreementIndexAnnualFieldsFragmentDoc = gql`
  fragment VertragVposIndexationAgreementIndexAnnualFields on VertragVposIndexationAgreementIndexAnnual {
    annualAverage
    effectiveWithMonth {
      text
      value
    }
    minimumLimit
    monthOfIndexPoint {
      text
      value
    }
    shareInPercentToApply
    subsequentBilling
    subsequentBillingObjektVorschreibungsposition {
      bezeichnung
      objektVorschreibungspositionId
      kurzBezeichnung
    }
    threshold
    thresholdType {
      text
      value
    }
  }
`;
export const VertragVposIndexationAgreementIndexThresholdFieldsFragmentDoc = gql`
  fragment VertragVposIndexationAgreementIndexThresholdFields on VertragVposIndexationAgreementIndexThreshold {
    effectiveAfterMonths
    minimumLimit
    shareInPercentToApply
    subsequentBilling
    subsequentBillingObjektVorschreibungsposition {
      bezeichnung
      objektVorschreibungspositionId
      kurzBezeichnung
    }
    threshold
    thresholdType {
      text
      value
    }
  }
`;
export const VertragVposIndexationAgreementReferenceValueFieldsFragmentDoc = gql`
  fragment VertragVposIndexationAgreementReferenceValueFields on VertragVposIndexationAgreementReferenceValue {
    createTs
  }
`;
export const VertragVposIndexationAgreementFieldsFragmentDoc = gql`
  fragment VertragVposIndexationAgreementFields on AbstractVertragVposIndexationAgreement {
    __typename
    createTs
    createdBy
    createdByMitarbeiterId
    indexOld
    indexOldPreliminary
    indexSeries {
      ...IndexSeriesFields
    }
    monthYearOld
    type {
      text
      value
    }
    updatedByMitarbeiterId
    validFrom
    vertragVposIndexationAgreementId
    warningList {
      attribute
      message
      type
    }
    ... on VertragVposIndexationAgreementCategory {
      ...VertragVposIndexationAgreementCategoryFields
    }
    ... on VertragVposIndexationAgreementIndexAnnual {
      ...VertragVposIndexationAgreementIndexAnnualFields
    }
    ... on VertragVposIndexationAgreementIndexThreshold {
      ...VertragVposIndexationAgreementIndexThresholdFields
    }
    ... on VertragVposIndexationAgreementReferenceValue {
      ...VertragVposIndexationAgreementReferenceValueFields
    }
  }
  ${IndexSeriesFieldsFragmentDoc}
  ${VertragVposIndexationAgreementCategoryFieldsFragmentDoc}
  ${VertragVposIndexationAgreementIndexAnnualFieldsFragmentDoc}
  ${VertragVposIndexationAgreementIndexThresholdFieldsFragmentDoc}
  ${VertragVposIndexationAgreementReferenceValueFieldsFragmentDoc}
`;
