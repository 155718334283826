import numbro from 'numbro';
import { TFormattedDecimal } from '../../helpers/formikHelper';

export const toFormattedDecimal = (value: any) =>
  numbro(value).format({
    thousandSeparated: true,
  });

export const parseFormattedDecimal = (stringNumber = '') => (stringNumber ? numbro.unformat(stringNumber) : 0);

export const mapFormattedDecimalOrThrowIfEmpty = (value: TFormattedDecimal): number => {
  if (value === '') {
    throw new Error('Empty string is not allowed, value should have been validated as required before submitting');
  }
  return value as number;
};

export const getFormattedDecimalValueOrZero = (value: TFormattedDecimal): number => {
  return value !== '' ? value : 0;
};
