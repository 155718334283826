import React, { FC } from 'react';
import { Col, Row } from 'antd';
import IndexSeriesSelect from '../../../../../../../../IndexSeries/shared/IndexSeriesSelect';
import { IndexType } from '../../../../../../../../../types';
import DrawerRow from '../../../../../../../../../components/Drawer/DrawerRow';
import { indAgreeReferenceValueFormFields } from './referenceValueFormMapper';
import MonthYearFormItem from '../shared/MonthYearFormItem';
import FormItemWithFieldHelp from '../../../../../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  onIndexSeriesChange: (indexSeriesId: string) => void;
  fieldHelpNames?: {
    validFrom?: string | null;
    indexSeriesId?: string | null;
  };
};

const IndexBlock: FC<Props> = ({ onIndexSeriesChange, fieldHelpNames }) => {
  const content = (
    <>
      <Row gutter={8}>
        <Col span={24}>
          <MonthYearFormItem
            name={indAgreeReferenceValueFormFields.validFrom}
            label="Gültig ab"
            placeholder="Gültig ab auswählen"
            fieldHelp={fieldHelpNames?.validFrom}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItemWithFieldHelp name={indAgreeReferenceValueFormFields.indexSeriesId} label="Indexreihe" fieldHelp={fieldHelpNames?.indexSeriesId}>
            <IndexSeriesSelect
              name={indAgreeReferenceValueFormFields.indexSeriesId}
              indexType={IndexType.ReferenceValue}
              onSelectChange={onIndexSeriesChange}
            />
          </FormItemWithFieldHelp>
        </Col>
      </Row>
    </>
  );

  return <DrawerRow rowTitle="Index" content={content} />;
};

export default IndexBlock;
