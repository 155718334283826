import React, { FC } from 'react';
import { FibuBuchungszeile } from '../../../../../../types';
import { isBuchungszeileKontoTypeSoll } from '../../bookingHelpers';
import KtoDetailsCards from './KtoDetailsCards';

type Props = {
  buchungszeile: FibuBuchungszeile;
};

const KtoDetailsCardsBlock: FC<Props> = ({ buchungszeile }) => {
  return isBuchungszeileKontoTypeSoll(buchungszeile?.sollHaben.value) ? (
    <KtoDetailsCards
      buchungskreisId={buchungszeile.buchungskreisId}
      sollKonto={buchungszeile.konto}
      habenKonto={buchungszeile.gegenKonto}
      buchungskreisRechtstraegerId={buchungszeile.buchungsKopf.buchungskreisRechtstraegerId}
    />
  ) : (
    <KtoDetailsCards
      buchungskreisId={buchungszeile.buchungskreisId}
      sollKonto={buchungszeile.gegenKonto}
      habenKonto={buchungszeile.konto}
      buchungskreisRechtstraegerId={buchungszeile.buchungsKopf.buchungskreisRechtstraegerId}
    />
  );
};

export default KtoDetailsCardsBlock;
