import React, { FC } from 'react';
import { Skeleton, Typography } from 'antd';
import { BaseType } from 'antd/es/typography/Base';
import { useRechtstraegerQuery } from '../../features/Rechtstraeger/gql/RechtsTraegerQueries.types';
import LinkWithStatus from '../Link/LinkWithStatus';
import DataWithShortenedTextAndExtraInfo from '../Helpers/DataWithShortenedTextAndExtraInfo';
import { generatePathToRechtstraegerDetailsPage } from '../../features/Rechtstraeger/rechtstraegerHelper';

type RechtstraegerNameProps = {
  rechtstraegerId: string;
  isLink?: boolean;
  textType?: BaseType;
  maxLength?: number;
};

const RechtstraegerName: FC<RechtstraegerNameProps> = ({ rechtstraegerId, isLink, textType, maxLength }) => {
  const { loading, data } = useRechtstraegerQuery({ variables: { rechtstraegerId } });

  const rechtstraeger = data?.getRechtstraeger.data;

  const text = rechtstraeger?.kurzBezeichnung ?? '';
  const maxTextLength = maxLength ?? text.length;

  const textOrLink =
    isLink && rechtstraeger ? (
      <LinkWithStatus
        status={rechtstraeger.status}
        text={text}
        maxTextLength={maxTextLength}
        to={generatePathToRechtstraegerDetailsPage(rechtstraeger.rechtstraegerId)}
      />
    ) : (
      <DataWithShortenedTextAndExtraInfo maxTextLength={maxTextLength} text={rechtstraeger?.kurzBezeichnung ?? ''}>
        {(shortenedText) => <Typography.Text type={textType}>{shortenedText}</Typography.Text>}
      </DataWithShortenedTextAndExtraInfo>
    );

  return loading ? <Skeleton.Input active size="small" style={{ width: '100px' }} /> : textOrLink;
};

export default RechtstraegerName;
