import React from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { MailBaseData, MailOrderBy } from '../../../../types';
import { getEmailDeliveryDetailsByType } from '../../emailDeliveryHelpers';
import { generatePathToEmailVersandDetailsPage } from '../../emailDeliveryUriPaths';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { getPathToVerarbeitungDetailsPage } from '../../../Verarbeitung/verarbeitungApiPaths';
import { verarbeitungTypes } from '../../../Verarbeitung/verarbeitungHelpers';
import { MitarbeiterTooltip } from '../../../../components/Card';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import RechtstraegerWithContactsTooltip from '../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

const emailDeliveryTableColumns: TableWithColSelectorColumnProps<MailBaseData>[] = [
  {
    title: 'Anwendungsfall',
    dataIndex: MailOrderBy.UseCase,
    defaultSelected: true,
    sorter: true,
    width: '150px',
    render: (text, record) => <Typography.Text>{record.useCase.text}</Typography.Text>,
  },
  {
    title: 'Empfänger',
    dataIndex: MailOrderBy.RecipientKurzBezeichnung,
    sorter: true,
    defaultSelected: true,
    width: '200px',
    render: (text, record) => {
      const recipientRechtstraeger = record.recipientRechtstraeger;
      return recipientRechtstraeger ? (
        <RechtstraegerWithContactsTooltip
          rechtstraegerId={recipientRechtstraeger.rechtstraegerId}
          rechtstraegerName={recipientRechtstraeger.kurzBezeichnung}
          maxTextLength={30}
        />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      );
    },
  },
  {
    title: 'Empfänger Email',
    dataIndex: MailOrderBy.Recipient,
    sorter: true,
    defaultSelected: true,
    width: '250px',
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={35} text={record.recipient}>
        {(shortenedText) => <Typography.Text>{shortenedText}</Typography.Text>}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Betreff',
    dataIndex: MailOrderBy.Subject,
    defaultSelected: true,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={45} text={record.subject}>
        {(shortenedText) => <Typography.Text>{shortenedText}</Typography.Text>}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Anz. Anhänge',
    defaultSelected: true,
    width: 100,
    render: (text, record) => <Typography.Text>{record.attachmentCount}</Typography.Text>,
  },
  {
    title: 'Status',
    dataIndex: MailOrderBy.Status,
    defaultSelected: true,
    sorter: true,
    width: '150px',
    render: (text, record) => <Typography.Text>{record.status.text}</Typography.Text>,
  },
  {
    title: 'Erstellt am',
    dataIndex: MailOrderBy.CreateTs,
    sorter: true,
    width: '150px',
    render: (text, record) => {
      let link;
      const generierLaufLabel = record.labelList.find((item) => item.label === 'generierlaufId');
      const emailDeliveryTypeDetails = getEmailDeliveryDetailsByType(record.useCase.value);
      if (emailDeliveryTypeDetails?.verarbeitung && generierLaufLabel) {
        const verarbeitungType: undefined | keyof typeof verarbeitungTypes = emailDeliveryTypeDetails?.verarbeitung;
        if (verarbeitungType) {
          link = getPathToVerarbeitungDetailsPage(verarbeitungTypes[verarbeitungType], generierLaufLabel.value);
        }
      }
      return link ? (
        <Link to={link}>
          <FormattedDateTime createdTs={record.createTs} />
        </Link>
      ) : (
        <FormattedDateTime createdTs={record.createTs} />
      );
    },
  },
  {
    title: 'Ersteller',
    dataIndex: MailOrderBy.CreatedByMitarbeiterId,
    sorter: true,
    width: '100px',
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    width: '100px',
    align: 'center',
    fixed: 'right',
    render: (text, record) => {
      const emailDeliveryTypeDetails = getEmailDeliveryDetailsByType(record.useCase.value);
      return !emailDeliveryTypeDetails?.skipLink ? (
        <Link to={`${generatePathToEmailVersandDetailsPage(record.mailId)}/email`}>Details</Link>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      );
    },
  },
];

export default emailDeliveryTableColumns;
