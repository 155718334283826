import React from 'react';
import { Helmet } from 'react-helmet-async';
import { PageLayout } from '../../components/LayoutAndromeda';
import IndexedValueListing from '../../features/IndexedValue/Listing/IndexedValueListing';

const IndexedValuePage = () => {
  return (
    <>
      <Helmet>
        <title>Indexierte Werte</title>
      </Helmet>
      <PageLayout>
        <IndexedValueListing />
      </PageLayout>
    </>
  );
};

export default IndexedValuePage;
