import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';
import AndromedaSysSettingsTextPage from './AndromedaSysSettingsTextPage';
import { isAdmin } from '../../../../security/permissionChecks';

const andromedaSysSettingsTextPageRoute = (
  <Route path={URI_ANDROMEDA_SYS_SETTINGS.textPage} element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsTextPage />} />} />
);

export default andromedaSysSettingsTextPageRoute;
