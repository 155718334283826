import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import { URI_VERARBEITUNG_CAMT_ONLINE_BANKING_MANUALLY_DETAILS_PAGE } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import CamtOnlineBankingManuallyDetailsPage from './CamtOnlineBankingManuallyDetailsPage';

export type CamtOnlineBankingManuallyPageRouteParams = { generierlaufId: string };

export const verarbeitungCamtOnlineBankingManuallyDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_CAMT_ONLINE_BANKING_MANUALLY_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<CamtOnlineBankingManuallyDetailsPage />} />}
  />
);
