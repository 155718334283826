import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { AbrKontenzuordnungFiltersFormValues } from '../Listing/Filters/abrKontenzuordnungFiltersMapper';
import { isNotNil } from '../../../helpers/assertionHelper';

type Props = {
  isAbrKreisRepFonds: boolean;
  buchungszeilenAnzeigen: boolean;
  isUstVomAufwand: boolean;
  aufteilungsschluesselBezeichnung?: string | null;
  filters?: AbrKontenzuordnungFiltersFormValues | null;
  isExpenseReducing: boolean;
  showExpenseReducing: boolean;
};

const ZuordnungListItemInfosViewAndEdit: FC<Props> = ({
  filters,
  isAbrKreisRepFonds,
  buchungszeilenAnzeigen,
  isUstVomAufwand,
  aufteilungsschluesselBezeichnung,
  isExpenseReducing,
  showExpenseReducing,
}) => (
  <Space direction="vertical" size={0}>
    {filters?.buchungszeilenAnzeigen && (
      <Typography.Text italic>Buchungszeile anzeigen: {buchungszeilenAnzeigen ? 'anzeigen' : 'ausblenden'}</Typography.Text>
    )}
    {isAbrKreisRepFonds && filters?.verwendungVonRepFonds && (
      <Typography.Text italic>Verwendung von Rep.Fonds: {isUstVomAufwand ? 'Ust. vom Aufwand' : 'Ausgaben'}</Typography.Text>
    )}
    {aufteilungsschluesselBezeichnung && filters?.displayAbweichenderAufteilungsschluesselId && (
      <Typography.Text italic>Abw.Auft.Schl: {aufteilungsschluesselBezeichnung}</Typography.Text>
    )}
    {showExpenseReducing && isNotNil(filters?.displayExpenseReducing) && (
      <Typography.Text italic>Aufwandsmindernd: {isExpenseReducing ? 'Ja' : 'Nein'}</Typography.Text>
    )}
  </Space>
);

export default ZuordnungListItemInfosViewAndEdit;
