import { message } from 'antd';
import Fetcher from '../../../../helpers/fetcher';
import { processAndReturnError, processResponse } from '../../../../helpers/APIUtils';
import { mapErrorToErrorMessage } from '../../../../helpers/errorAndWarningHelper';
import { TicketEmailsFormValues, TicketSyncEmailsInput } from './ticketEmailsFormMapper';

export const handleSyncEmails = (
  firmendatenId: string,
  ticketId: string | undefined,
  values: TicketEmailsFormValues,
  input: TicketSyncEmailsInput
) => {
  const formData = new FormData();
  formData.append(
    'request',
    new Blob([JSON.stringify({ data: { ...input } })], {
      type: 'application/json',
    }),
    'payload'
  );

  // Return a general message if no ticketId is available
  if (!ticketId) {
    return new Promise<void>((resolve, reject) => {
      message.error({
        content: `Fehler beim Hochladen der E-Mails.`,
      });
      reject();
    });
  }

  if (values.newEmailList.length > 0) {
    values.newEmailList.forEach((email) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      formData.append('newEmailList', email.file!, email.filename);
    });
  }

  return Fetcher.getInstance()
    .fetch(`/api/firmendaten/${firmendatenId}/tickets/${ticketId}/emails`, {
      method: 'POST',
      body: formData,
    })
    .then(processResponse)
    .catch(processAndReturnError)
    .catch((error) => {
      return new Promise<void>((resolve, reject) => {
        message.error({
          content: `Fehler beim Hochladen der E-Mails: ${mapErrorToErrorMessage(error)}`,
        });
        reject();
      });
    });
};
