import { FormFields } from '../../../../../helpers/formikHelper';
import { GenerierlaufEntryExitCode } from '../../../../../types';
import { filtersQueryParams } from './filtersQueryParams';

export type ListingFiltersFormValues = {
  emailAddress?: string;
  exitCodeList?: GenerierlaufEntryExitCode[];
  objektId?: string;
  recipientId?: string;
  vertragspartnerId?: string;
};

export const ListingFiltersFormFields: FormFields<ListingFiltersFormValues> = {
  emailAddress: 'emailAddress',
  exitCodeList: 'exitCodeList',
  objektId: 'objektId',
  recipientId: 'recipientId',
  vertragspartnerId: 'vertragspartnerId',
};

export const ListingFiltersFormInitialValues = (queryParams: filtersQueryParams): ListingFiltersFormValues => ({
  emailAddress: queryParams.emailAddress,
  exitCodeList: queryParams.exitCodeList,
  objektId: queryParams.objektId,
  recipientId: queryParams.recipientId,
  vertragspartnerId: queryParams.vertragspartnerId,
});

export const mapFormValuesToListingFilters = (formValues: ListingFiltersFormValues): filtersQueryParams => ({
  emailAddress: formValues.emailAddress,
  exitCodeList: formValues.exitCodeList,
  objektId: formValues.objektId,
  recipientId: formValues.recipientId,
  vertragspartnerId: formValues.vertragspartnerId,
});
