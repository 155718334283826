import { Dayjs } from 'dayjs';
import { FormFields } from '../../../../helpers/formikHelper';
import { AbrechnungGenerierlaufType, AbrechnungType, HeOrObjektOrSubAbrechnungGenerierlaufCreateInput } from '../../../../types';
import { ALLE_TAG_TEXT } from '../../../../shared/ObjektSelect/ObjektSelectWithAllTag';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import { isAbrechnungTypeHeAbrechnung, isAbrechnungTypeSubAbrechnung } from '../../shared/abrechnungHelpers';
import { firstDayOfLastYear, lastDayOfLastYear } from '../../../../helpers/dateHelper';

export interface AbrechnungFormValues {
  fromTo: Dayjs[] | null;
  objektIdList?: string[] | null;
}

export const abrechnungFormInitialValues: AbrechnungFormValues = {
  objektIdList: null,
  fromTo: [firstDayOfLastYear(), lastDayOfLastYear()],
};

export const abrechnungFormFields: FormFields<AbrechnungFormValues> = {
  objektIdList: 'objektIdList',
  fromTo: 'fromTo',
};

export const mapFormValuesToFirmendatenAbrechnungCreateInput = (
  abrechnungType: AbrechnungType,
  values: AbrechnungFormValues
): HeOrObjektOrSubAbrechnungGenerierlaufCreateInput => ({
  abrechnungType,
  type: getAbrechnungGenerierlaufType(abrechnungType),
  objektIdList: values.objektIdList?.includes(ALLE_TAG_TEXT) ? null : values.objektIdList,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  fromInclusive: dayjsCustom(values.fromTo![0]).format('YYYY-MM-DD'),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  toInclusive: dayjsCustom(values.fromTo![1]).format('YYYY-MM-DD'),
});

const getAbrechnungGenerierlaufType = (abrechnungType: AbrechnungType): AbrechnungGenerierlaufType => {
  if (isAbrechnungTypeHeAbrechnung(abrechnungType)) {
    return AbrechnungGenerierlaufType.HeAbrechnungGenerierlauf;
  }
  if (isAbrechnungTypeSubAbrechnung(abrechnungType)) {
    return AbrechnungGenerierlaufType.SubAbrechnungGenerierlauf;
  }
  return AbrechnungGenerierlaufType.ObjektAbrechnungGenerierlauf;
};
