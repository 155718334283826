import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { Drawer } from 'antd';
import { useToggle } from '../../hooks/useToggle';
import { useCreateNewItemInSelect } from '../../components/Select/useCreateNewItemInSelect';
import { selectFilterOption } from '../../helpers/selectHelper';
import { HAUPT_TAG_TEXT } from '../../components/Tag/tagHelpers';
import HauptTag from '../Vertrag/BeVertrag/Versand/Form/shared/HauptTag';
import VersandCardPostAddress from '../Vertrag/BeVertrag/Versand/Card/VersandCardPostAddress';
import { useRechtstraegerAddressesAndContactsQuery } from '../Rechtstraeger/AddressesAndContacts/gql/AddressesAndContactsQueries.types';
import AddressCreateForm from '../Vertrag/shared/AddressCreateForm';
import { useRechtstraegerTypeQuery } from '../Rechtstraeger/gql/RechtsTraegerQueries.types';

type Props = {
  objektId: string;
  onNewItemCreated: (addressId: string) => void;
  onNewItemCreateSelected: () => void;
  rechtstraegerId: string;
} & SelectProps;

const DeliveryAddressSelect: FC<Props> = ({ objektId, rechtstraegerId, onNewItemCreateSelected, onNewItemCreated, ...restProps }) => {
  const { data, loading, refetch } = useRechtstraegerAddressesAndContactsQuery({
    variables: {
      rechtstraegerId,
    },
    skip: !rechtstraegerId,
  });

  const contactData = data?.getRechtstraegerAddressesAndContacts.data;
  const addressListForSelect = contactData
    ? [contactData.hauptAddress, ...contactData.streetAddressList, ...contactData.postofficeBoxAddressList]
    : [];

  const { data: rechtstreagerTypeData } = useRechtstraegerTypeQuery({
    variables: { rechtstraegerId },
    skip: !rechtstraegerId,
  });

  const rechtstraegerType = rechtstreagerTypeData?.getRechtstraegerType.data.value;

  const [isCreateNewDrawerOpen, toggleCreateNewDrawer] = useToggle();

  const onNewItemCreateSelectedFn = () => {
    onNewItemCreateSelected();
    toggleCreateNewDrawer();
  };

  const { handleSelect, handleSearch, renderCreateNewItem } = useCreateNewItemInSelect(
    addressListForSelect,
    onNewItemCreateSelectedFn,
    'Neue Zustelleadresse anlegen'
  );

  const onSuccess = (addresseId: string) => {
    refetch().then(() => onNewItemCreated(addresseId));
    toggleCreateNewDrawer();
  };

  const selectOptions = (
    <Select
      id={restProps.name}
      placeholder="Adresse auswählen"
      allowClear
      showSearch
      loading={loading}
      filterOption={selectFilterOption}
      onSelect={handleSelect}
      onSearch={handleSearch}
      {...restProps}
    >
      <Select.Option key={HAUPT_TAG_TEXT} value={HAUPT_TAG_TEXT}>
        <HauptTag />
      </Select.Option>
      {addressListForSelect.map((item) => {
        return item ? (
          <Select.Option key={item.addressId} value={item.addressId}>
            <VersandCardPostAddress postAddress={item} />
          </Select.Option>
        ) : null;
      })}
      {renderCreateNewItem()}
    </Select>
  );

  return (
    <>
      {selectOptions}
      <Drawer mask={false} title="Adresse anlegen" width={720} open={isCreateNewDrawerOpen} onClose={toggleCreateNewDrawer} destroyOnClose>
        <AddressCreateForm
          onSuccess={onSuccess}
          onCancel={toggleCreateNewDrawer}
          rechtstraegerId={rechtstraegerId}
          /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
          rechtstraegerType={rechtstraegerType!}
        />
      </Drawer>
    </>
  );
};

export default DeliveryAddressSelect;
