import { FC } from 'react';
import { Col, Row } from 'antd';
import { FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { BookingSuggestion } from '../../../../types';
import GeneralInfoBlock from '../../../BookingSuggestion/shared/GeneralInfoBlock/GeneralInfoBlock';
import BookingSuggestionButtons from './BookingSuggestionButtons';
import useSettingFormDirty from '../../../BookingSuggestion/shared/Form/useSettingFormDirty';
import { BookingSuggestionFormValues, mapBSBuchungsanweisungToFormValues } from '../../../BookingSuggestion/shared/Form/buchungsmaskeFormMapper';
import { isBuchungsanweisungDirty } from '../../../BookingSuggestion/shared/Form/buchungsmaskeFormHelpers';

type Props = {
  bookingSuggestion: BookingSuggestion;
  formikProps: FormikProps<BookingSuggestionFormValues>;
  onCancel: () => void;
  onSubmit: (isBuchungsanweisungChanged: boolean) => void;
  showNotSavedWarning: boolean;
  isFormDirty: boolean;
  setIsFormDirty: (toggle: boolean) => void;
  initialValuesForTracking: BookingSuggestionFormValues;
};

const GeneralInfoWithButtonsBlock: FC<Props> = ({
  bookingSuggestion,
  formikProps,
  onCancel,
  onSubmit,
  showNotSavedWarning,
  isFormDirty,
  setIsFormDirty,
  initialValuesForTracking,
}) => {
  useSettingFormDirty(initialValuesForTracking, formikProps.values, setIsFormDirty);

  const isBuchungsanweisungChanged = isBuchungsanweisungDirty(
    mapBSBuchungsanweisungToFormValues(bookingSuggestion.buchungsanweisung),
    formikProps.values.buchungsanweisung
  );
  const isSavingBtnDisabled = !isEmpty(formikProps.errors) || (!isBuchungsanweisungChanged && !isFormDirty);

  return (
    <Row align="stretch" style={{ marginTop: '24px' }}>
      <Col span={18}>
        <GeneralInfoBlock formikProps={formikProps} fibuBuchungStatus={bookingSuggestion.buchungsanweisung.fibuBuchungStatus} />
      </Col>
      <Col span={6}>
        <BookingSuggestionButtons
          onCancel={onCancel}
          onReset={formikProps.resetForm}
          onSubmit={() => onSubmit(isBuchungsanweisungChanged)}
          showNotSavedWarning={showNotSavedWarning}
          isOkDisabled={isSavingBtnDisabled}
          isBuchungsanweisungChanged={isBuchungsanweisungChanged}
        />
      </Col>
    </Row>
  );
};

export default GeneralInfoWithButtonsBlock;
