import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ObjektInfoFeldTypeListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ObjektInfoFeldTypeListQuery = {
  getObjektInfoFeldTypeList: {
    data: Array<{ text: string; value: Types.ObjektInfoFeldType }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const ObjektInfoFeldTypeListDocument = gql`
  query ObjektInfoFeldTypeList {
    getObjektInfoFeldTypeList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useObjektInfoFeldTypeListQuery(
  baseOptions?: Apollo.QueryHookOptions<ObjektInfoFeldTypeListQuery, ObjektInfoFeldTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ObjektInfoFeldTypeListQuery, ObjektInfoFeldTypeListQueryVariables>(ObjektInfoFeldTypeListDocument, options);
}
export function useObjektInfoFeldTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ObjektInfoFeldTypeListQuery, ObjektInfoFeldTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ObjektInfoFeldTypeListQuery, ObjektInfoFeldTypeListQueryVariables>(ObjektInfoFeldTypeListDocument, options);
}
export function useObjektInfoFeldTypeListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ObjektInfoFeldTypeListQuery, ObjektInfoFeldTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ObjektInfoFeldTypeListQuery, ObjektInfoFeldTypeListQueryVariables>(ObjektInfoFeldTypeListDocument, options);
}
export type ObjektInfoFeldTypeListQueryHookResult = ReturnType<typeof useObjektInfoFeldTypeListQuery>;
export type ObjektInfoFeldTypeListLazyQueryHookResult = ReturnType<typeof useObjektInfoFeldTypeListLazyQuery>;
export type ObjektInfoFeldTypeListSuspenseQueryHookResult = ReturnType<typeof useObjektInfoFeldTypeListSuspenseQuery>;
export type ObjektInfoFeldTypeListQueryResult = Apollo.QueryResult<ObjektInfoFeldTypeListQuery, ObjektInfoFeldTypeListQueryVariables>;
