import React, { FC } from 'react';
import { Space, Tag, Typography } from 'antd';
import FormattedDateTime from '../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../components/Card';

type Props = {
  storniertByMitarbeiterId: string;
  storniertTs: string;
};

const StorniertStatus: FC<Props> = ({ storniertByMitarbeiterId, storniertTs }) => {
  return (
    <Space>
      <Tag color="red">Storniert</Tag>
      <FormattedDateTime createdTs={storniertTs} />
      <Space>
        <Typography.Text>durch</Typography.Text>
        <MitarbeiterTooltip mitarbeiterId={storniertByMitarbeiterId} showsInitials />
      </Space>
    </Space>
  );
};

export default StorniertStatus;
