import { FormFields, mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';
import { ITimeblock } from '../../../../../components/Timeline/timelineCtx';
import { ValuesDescriptionForKommentar } from '../../../../../components/Timeline/versionTimelineHelper';
import { ObjektSummeAnteile, ObjektSummeAnteileInput } from '../../../../../types';

export type ObjektSummeAnteileVersionFormValues = {
  validFrom: string;
  kommentar: string;
  summeAnteile: number;
};

export const objektSummeAnteileVersionFormFields: FormFields<ObjektSummeAnteileVersionFormValues> = {
  validFrom: 'validFrom',
  kommentar: 'kommentar',
  summeAnteile: 'summeAnteile',
};

export const mapTimeblockToFormValues = (timeblock: ITimeblock<ObjektSummeAnteile>): ObjektSummeAnteileVersionFormValues => ({
  validFrom: timeblock.validFrom,
  kommentar: timeblock.kommentar ?? '',
  summeAnteile: timeblock.summeAnteile,
});

export const mapFormValuesToObjektSummeVersion = (values: ObjektSummeAnteileVersionFormValues): ObjektSummeAnteileInput => ({
  validFrom: mapFormDateValueToDateString(values.validFrom),
  summeAnteile: values.summeAnteile,
  kommentar: values.kommentar,
});

export const summeAnteileVersionFormValuesDescriptionsForKommentar: ValuesDescriptionForKommentar<ObjektSummeAnteileVersionFormValues> = {
  validFrom: 'Gültigkeitsdatum',
  summeAnteile: 'Summe Anteile',
};
