import React, { FC } from 'react';
import { DatePicker, DatePickerProps } from 'formik-antd';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../components/DatePicker/DatePicker_formikAntD';
import { StyledSpaceFullWidthInFilter } from '../../../components/Space/styled/StyledSpaceFullWidthInFilter';
import MonthPicker from '../../../components/DatePicker/MonthPicker/MonthPicker';

type Props = {
  format: 'month' | 'day';
  fromName: string;
  toName: string;
  fromOnChange?: () => void;
  toOnChange?: () => void;
  fromPrefix?: string;
  toPrefix?: string;
} & Omit<DatePickerProps, 'name' | 'prefix' | 'onChange'>;

const FromToFormDatePicker: FC<Props> = ({ fromName, toName, format, fromOnChange, toOnChange, size, fromPrefix, toPrefix, ...restProps }) => {
  return format === 'day' ? (
    <StyledSpaceFullWidthInFilter size="small">
      <DatePicker
        id={fromName}
        name={fromName}
        format={DATE_DISPLAY_FORMAT_DEFAULT}
        style={{ width: '100%' }}
        {...restProps}
        onChange={fromOnChange}
        size={size ?? 'middle'}
        prefix={fromPrefix}
      />
      -
      <DatePicker
        id={toName}
        name={toName}
        format={DATE_DISPLAY_FORMAT_DEFAULT}
        style={{ width: '100%' }}
        {...restProps}
        onChange={toOnChange}
        size={size ?? 'middle'}
        prefix={toPrefix}
      />
    </StyledSpaceFullWidthInFilter>
  ) : (
    <StyledSpaceFullWidthInFilter size="small">
      <MonthPicker name={fromName} onChange={fromOnChange} style={{ width: '100%' }} {...restProps} size={size ?? 'middle'} prefix={fromPrefix} />
      -
      <MonthPicker name={toName} onChange={toOnChange} style={{ width: '100%' }} {...restProps} size={size ?? 'middle'} prefix={toPrefix} />
    </StyledSpaceFullWidthInFilter>
  );
};

export default FromToFormDatePicker;
