import { generatePath } from 'react-router-dom';
import { URI_OBJEKT_DETAILS_PAGE } from '../../Objekt/objektUriPaths';

const URI_BK_ABRECHNUNG_DETAILS_PAGE = `${URI_OBJEKT_DETAILS_PAGE}/miet-abrechnung/bk-abrechnung/:objektAbrechnungId`;
const URI_BK_ABRECHNUNG_TOPABRECHNUNG_DETAILS_PAGE = `${URI_BK_ABRECHNUNG_DETAILS_PAGE}/abrechnung/topabrechnungen/:topAbrechnungId`;

const generatePathToBkAbrechnungDetailsPage = (objektId: string, objektAbrechnungId: string) =>
  generatePath(URI_BK_ABRECHNUNG_DETAILS_PAGE, { objektId, objektAbrechnungId });

const generatePathToBkAbrechnungDetailsPageAbrechnungTab = (objektId: string, objektAbrechnungId: string) =>
  `${generatePathToBkAbrechnungDetailsPage(objektId, objektAbrechnungId)}/abrechnung`;

export const generatePathToBkAbrechnungDetailsTopabrechnungDetailsPage = (objektId: string, objektAbrechnungId: string, topAbrechnungId: string) =>
  generatePath(URI_BK_ABRECHNUNG_TOPABRECHNUNG_DETAILS_PAGE, { objektId, objektAbrechnungId, topAbrechnungId });

export const pathsToBkAbrechnungDetails = (objektId: string, objektAbrechnungId: string) => ({
  abrechnungDetails: generatePathToBkAbrechnungDetailsPage(objektId, objektAbrechnungId),
  objAbr: generatePathToBkAbrechnungDetailsPageAbrechnungTab(objektId, objektAbrechnungId),
  objAbrTab: `${generatePathToBkAbrechnungDetailsPageAbrechnungTab(objektId, objektAbrechnungId)}/objektabrechnung`,
  objAbrTab2: `${generatePathToBkAbrechnungDetailsPageAbrechnungTab(objektId, objektAbrechnungId)}/objektabrechnung2`,
  topAbrTab: `${generatePathToBkAbrechnungDetailsPageAbrechnungTab(objektId, objektAbrechnungId)}/topabrechnungen`,
  zahlungsrueckstandTab: `${generatePathToBkAbrechnungDetailsPageAbrechnungTab(objektId, objektAbrechnungId)}/zahlungsrueckstand`,
  deviations: `${generatePathToBkAbrechnungDetailsPage(objektId, objektAbrechnungId)}/deviations`,
});

export { generatePathToBkAbrechnungDetailsPage, URI_BK_ABRECHNUNG_DETAILS_PAGE, URI_BK_ABRECHNUNG_TOPABRECHNUNG_DETAILS_PAGE };
