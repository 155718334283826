import { FC } from 'react';
import VersionTimeline from '../../../../components/Timeline/VersionTimeline';
import { Verwaltungsart, VorsteuerkuerzungEinstellung } from '../../../../types';
import { useVorsteuerkuerzungEinstellungTemplateListQuery } from '../../gql/VorsteuerkuerzungEinstellungTemplateQueries.types';
import VstKuerzungKontenZuweisungVersionCardContent from '../../../Vorsteuerkuerzung/KontenZuweisung/Version/VstKuerzungKontenZuweisungVersionCardContent';
import VstKuerzungTemplateKontenZuweisungVersionForm from './Form/VstKuerzungTemplateKontenZuweisungVersionForm';
import { useDeleteVorsteuerkuerzungEinstellungTemplateMutation } from '../../gql/VorsteuerkuerzungEinstellungTemplateMutations.types';
import { showSuccessMsgDelete } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';

type Props = {
  verwaltungsart: Verwaltungsart;
  reloadVstKuerzungCurrentKontenZuweisung: () => void;
};

const VstKuerzungTemplateKontenZuweisungVersionTimeline: FC<Props> = ({ verwaltungsart, reloadVstKuerzungCurrentKontenZuweisung }) => {
  const {
    data,
    loading,
    refetch: reloadVersionList,
  } = useVorsteuerkuerzungEinstellungTemplateListQuery({
    variables: {
      verwaltungsart,
    },
  });

  const vorsteuerkuerzungEinstellungList = data?.getVorsteuerkuerzungEinstellungTemplateList.data ?? [];

  const onActionSuccess = () => {
    reloadVstKuerzungCurrentKontenZuweisung();
    reloadVersionList();
  };

  const [runDelete] = useDeleteVorsteuerkuerzungEinstellungTemplateMutation({
    onCompleted: () => {
      showSuccessMsgDelete('Vorsteuerkürzung-Kontenzuweisung');
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <VersionTimeline<VorsteuerkuerzungEinstellung>
      dataSource={vorsteuerkuerzungEinstellungList}
      versionIdKey="vorsteuerkuerzungEinstellungId"
      loading={loading}
      defaultNewTimeblock={{
        vorsteuerkuerzungEinstellungId: '',
        kontenZuweisungList: [],
        warningList: [],
        versionIndex: 0,
      }}
      isAllowedToDeleteInitialTimeblock
      kommentarForCardTitle={(timeblock) => timeblock.kommentar}
      onDelete={(timeblock) => runDelete({ variables: { vorsteuerkuerzungEinstellungId: timeblock.vorsteuerkuerzungEinstellungId } })}
      renderCardContent={(timeblock) => <VstKuerzungKontenZuweisungVersionCardContent verwaltungsart={verwaltungsart} timeblock={timeblock} />}
      renderForm={(timeblock) => (
        <VstKuerzungTemplateKontenZuweisungVersionForm verwaltungsart={verwaltungsart} onSubmitSuccess={onActionSuccess} timeblock={timeblock} />
      )}
    />
  );
};

export default VstKuerzungTemplateKontenZuweisungVersionTimeline;
