import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { GenerierlaufAsyncStepFieldsFragmentDoc } from '../shared/generierlaufFragments.types';
import { BudgetingBaseFieldsFragmentDoc } from '../../../Budgeting/gql/BudgetingFragments.types';
export type ObjektFinancialPlanGenerierlaufEntryFieldsFragment = {
  currentStep?: number | null;
  generierlaufEntryId: string;
  infoMessage?: string | null;
  retry: boolean;
  stepCount?: number | null;
  errorList: Array<{ message: string; type: string }>;
  exitCode?: { value: Types.GenerierlaufEntryExitCode; text: string } | null;
  stepList: Array<{
    designation: string;
    receivingApplication?: string | null;
    stepId: string;
    type?: string | null;
    status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
  }>;
  warningList: Array<{ message: string; type: string }>;
};

export type ObjektFinancialPlanGenerierlaufFieldsFragment = {
  countFehler: number;
  countVerarbeitet: number;
  countNichtVerarbeitet: number;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  generierDatum: string;
  generierlaufId: string;
  budgeting: {
    bezeichnung: string;
    budgetSumNextYear?: number | null;
    budgetingId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    currentYear: number;
    increase?: number | null;
    prevYear: number;
    updatedByMitarbeiterId?: string | null;
    vertragVPosWertDate?: string | null;
    vorschreibungCurrentYearSum: number;
    vorschreibungPrevYearSum: number;
    restOfCurrentYearSum?: number | null;
    totalCurrentYearSum?: number | null;
    objekt: {
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      objektId: string;
      updatedByMitarbeiterId?: string | null;
      rechnungsAussteller: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      status: { text: string; value: Types.ObjektStatus; description?: string | null };
      verwaltungsart: { text: string; value: Types.Verwaltungsart };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    resultSourceType?: { description?: string | null; text: string; value: Types.BudgetingResultSourceType } | null;
    status: { text: string; value: Types.BudgetingStatus };
    warningList: Array<{ message: string; type: string }>;
  };
  entryList: Array<{
    currentStep?: number | null;
    generierlaufEntryId: string;
    infoMessage?: string | null;
    retry: boolean;
    stepCount?: number | null;
    errorList: Array<{ message: string; type: string }>;
    exitCode?: { value: Types.GenerierlaufEntryExitCode; text: string } | null;
    stepList: Array<{
      designation: string;
      receivingApplication?: string | null;
      stepId: string;
      type?: string | null;
      status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
    }>;
    warningList: Array<{ message: string; type: string }>;
  }>;
  errorList: Array<{ message: string; type: string }>;
  status: { description?: string | null; value: Types.GenerierlaufStatus; text: string };
  warningList: Array<{ message: string; type: string }>;
};

export const ObjektFinancialPlanGenerierlaufEntryFieldsFragmentDoc = gql`
  fragment ObjektFinancialPlanGenerierlaufEntryFields on ObjektFinancialPlanGenerierlaufEntry {
    currentStep
    errorList {
      message
      type
    }
    exitCode {
      value
      text
    }
    generierlaufEntryId
    infoMessage
    retry
    stepCount
    stepList {
      ...GenerierlaufAsyncStepFields
    }
    warningList {
      message
      type
    }
  }
  ${GenerierlaufAsyncStepFieldsFragmentDoc}
`;
export const ObjektFinancialPlanGenerierlaufFieldsFragmentDoc = gql`
  fragment ObjektFinancialPlanGenerierlaufFields on ObjektFinancialPlanGenerierlauf {
    budgeting {
      ...BudgetingBaseFields
    }
    countFehler
    countVerarbeitet
    countNichtVerarbeitet
    createTs
    createdBy
    createdByMitarbeiterId
    entryList {
      ...ObjektFinancialPlanGenerierlaufEntryFields
    }
    errorList {
      message
      type
    }
    generierDatum
    generierlaufId
    status {
      description
      value
      text
    }
    warningList {
      message
      type
    }
  }
  ${BudgetingBaseFieldsFragmentDoc}
  ${ObjektFinancialPlanGenerierlaufEntryFieldsFragmentDoc}
`;
