import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { compareTwoStringsForSorting } from '../../../../helpers/stringHelper';
import GenerierlaufEntryStatus from '../../shared/GenerierlaufEntryStatus';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import StatusBadge from '../../../../components/Status/StatusBadge';
import { SyncEbicsUserGenerierlaufEntry } from '../../../../types';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import { generatePathToEbicsUserPage } from '../../../PaymentTransaction/EbicsUser/Listing/Filters/filtersQueryParams';
import TextsWithTooltipForOrderTypeList from '../../../PaymentTransaction/shared/TextsWithTooltipForOrderTypeList';

const syncEbicsUserVerarbeitungEntryTableColumns = (): TableWithColSelectorColumnProps<SyncEbicsUserGenerierlaufEntry>[] => [
  {
    title: 'Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.exitCode?.text, b.exitCode?.text),
    render: (_, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },
  {
    title: 'Mitarbeiter',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.ebicsUser?.mitarbeiterName, b.ebicsUser?.mitarbeiterName),
    render: (text, record) =>
      record.ebicsUser ? (
        <DataWithShortenedText text={record.ebicsUser.mitarbeiterName} maxTextLength={30} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Teilnehmer',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.ebicsUser?.name, b.ebicsUser?.name),
    render: (text, record) =>
      record.ebicsUser && record.ebicsUser.name ? (
        <DataWithShortenedText text={record.ebicsUser.name} maxTextLength={30} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Teilnehmer Berechtigungen',
    defaultSelected: false,
    render: (text, record) =>
      record.ebicsUser && record.ebicsUser.orderTypeList.length > 0 ? (
        <TextsWithTooltipForOrderTypeList
          texts={record.ebicsUser.orderTypeList.map((orderType) => orderType.text)}
          maxTextLength={25}
          addLineBreakAt={4}
        />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Teilnehmerkennung',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.ebicsUser?.userId, b.ebicsUser?.userId),
    render: (text, record) =>
      record.ebicsUser ? <DataWithShortenedText text={record.ebicsUser.userId} maxTextLength={30} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Kundenkennung',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.ebicsUser?.partnerId, b.ebicsUser?.partnerId),
    render: (text, record) =>
      record.ebicsUser ? <DataWithShortenedText text={record.ebicsUser.partnerId} maxTextLength={30} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Bankname',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.ebicsUser?.bankname, b.ebicsUser?.bankname),
    render: (text, record) =>
      record.ebicsUser ? <DataWithShortenedText text={record.ebicsUser.bankname} maxTextLength={30} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'EBICS Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.ebicsUser?.status.text, b.ebicsUser?.status.text),
    render: (text, record) => (record.ebicsUser ? <StatusBadge entityStatus={record.ebicsUser.status} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Erstellt am',
    render: (text, record) =>
      record.ebicsUser ? <FormattedDateTime createdTs={record.ebicsUser.createTs} /> : <TextForEmptyValue textToShow="NV" />,
  },
  {
    title: 'Ersteller',
    render: (text, record) =>
      record.ebicsUser ? (
        <MitarbeiterTooltip
          mitarbeiterId={record.ebicsUser.createdByMitarbeiterId}
          userId={record.ebicsUser.createdBy}
          alignment="left"
          showsInitials
        />
      ) : (
        <TextForEmptyValue textToShow="NV" />
      ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) =>
      record.ebicsUser ? (
        <Link
          to={generatePathToEbicsUserPage({
            ebicsBankConfigId: record.ebicsUser.ebicsBankConfigId,
            mitarbeiterIdList: [record.ebicsUser.mitarbeiterId],
            status: record.ebicsUser.status.value,
          })}
          target="_blank"
        >
          Details
        </Link>
      ) : (
        <Typography.Text disabled>Details</Typography.Text>
      ),
  },
];

export default syncEbicsUserVerarbeitungEntryTableColumns;
