import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { BookingInstructionFormValues } from '../../bookingInstructionFormMapper';
import { useRechtstraegerAddressesAndContactsQuery } from '../../../../Rechtstraeger/AddressesAndContacts/gql/AddressesAndContactsQueries.types';
import { useRechtstraegerTypeQuery } from '../../../../Rechtstraeger/gql/RechtsTraegerQueries.types';
import ContactPostSelect from '../../../../Rechtstraeger/AddressesAndContacts/Form/shared/ContactPostSelect';

type Props = {
  name: string;
  formikProps: FormikProps<BookingInstructionFormValues>;
  rechtstraegerId: string;
};

const BookingInstructionContactPostSelect: FC<Props> = ({ name, formikProps, rechtstraegerId }) => {
  const {
    data,
    loading: loadingContactList,
    refetch: refetchContactList,
  } = useRechtstraegerAddressesAndContactsQuery({
    variables: {
      rechtstraegerId,
    },
  });

  const contactData = data?.getRechtstraegerAddressesAndContacts.data;
  const postListForSelect = contactData ? [contactData.hauptAddress, ...contactData.streetAddressList, ...contactData.postofficeBoxAddressList] : [];

  const { data: rechtstreagerTypeData, loading: loadingRechtstraegerType } = useRechtstraegerTypeQuery({
    variables: { rechtstraegerId },
  });

  const rechtstraegerType = rechtstreagerTypeData?.getRechtstraegerType.data.value;

  return (
    <ContactPostSelect
      disabled={!rechtstraegerId}
      postList={postListForSelect}
      name={name}
      loading={loadingContactList || loadingRechtstraegerType}
      onNewItemCreated={(addressId) => refetchContactList().then(() => formikProps.setFieldValue(name, addressId))}
      onNewItemCreateSelected={() => formikProps.setFieldValue(name, undefined)}
      rechtstraegerType={rechtstraegerType}
      rechtstraegerId={rechtstraegerId}
    />
  );
};

export default BookingInstructionContactPostSelect;
