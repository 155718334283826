import React, { FC, PropsWithChildren, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Form } from 'formik-antd';
import { Steps as StepsAntD, Typography } from 'antd';
import { Step, Steps, Wizard, WizardContext } from 'react-albus';
import { isEmpty } from 'lodash';
import { WizardFormContext } from './wizardForm-context';
import { isLastStep } from './wizardHelper';
import WizardNavigation, { WizardNavigationOnCancel } from './WizardNavigation';
import { Spacer } from '../Grid';
import { FormikYupValidationSchema } from '../../helpers/formikHelper';

type WizardStepFormProps = {
  stepId: string;
  formInitialValues: Record<string, unknown>;
  formValidationSchema?: FormikYupValidationSchema;
  formValidateOnChange: boolean;
  heading?: string;
  render: (formikProps: FormikProps<any>) => React.ReactNode;
};

// Has to defined before wizardFormWrapper otherwise propTypes check for wizardFormWrapper.children fails
const WizardStepForm: FC<WizardStepFormProps> = ({ formInitialValues, formValidationSchema, formValidateOnChange = true, heading, render }) => (
  <WizardFormContext.Consumer>
    {({ savedStepFormValues, onWizardSubmit, onWizardPrevious, onWizardCancel, error }) => (
      <WizardStepFormContextConsumer
        formInitialValues={formInitialValues}
        formValidationSchema={formValidationSchema}
        formValidateOnChange={formValidateOnChange}
        heading={heading}
        render={render}
        savedStepFormValues={savedStepFormValues}
        onWizardSubmit={onWizardSubmit}
        onWizardPrevious={onWizardPrevious}
        onWizardCancel={onWizardCancel}
        error={error}
      />
    )}
  </WizardFormContext.Consumer>
);

type WizardStepFormContextConsumerProps = {
  formInitialValues: Record<string, unknown>;
  formValidationSchema?: FormikYupValidationSchema;
  formValidateOnChange: boolean;
  heading?: string;
  render: (formikProps: FormikProps<any>) => React.ReactNode;
  savedStepFormValues: Record<string, unknown>;
  onWizardSubmit: (stepFormValues: Record<string, unknown>) => any;
  onWizardPrevious: (stepFormValues: Record<string, unknown>) => void;
  onWizardCancel: WizardNavigationOnCancel;
  error?: Error;
};

const WizardStepFormContextConsumer: FC<WizardStepFormContextConsumerProps> = ({
  formInitialValues,
  formValidationSchema,
  formValidateOnChange = true,
  heading,
  render,
  savedStepFormValues,
  onWizardSubmit,
  onWizardPrevious,
  onWizardCancel,
  error,
}) => {
  return (
    <Formik
      initialValues={savedOrInitialValues(savedStepFormValues, formInitialValues)}
      validateOnChange={formValidateOnChange}
      validationSchema={formValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const success = onWizardSubmit(values);
        if (!success) {
          setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        return (
          <Form layout="vertical">
            <Typography.Title level={5}>{heading}</Typography.Title>
            {render(formikProps)}
            <WizardNavigation
              formikProps={formikProps}
              error={error}
              onPrevious={() => onWizardPrevious(formikProps.values)}
              onCancel={onWizardCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

const savedOrInitialValues = (savedStepFormValues: Record<string, unknown>, initialValues: Record<string, unknown>): Record<string, unknown> =>
  !isEmpty(savedStepFormValues) ? savedStepFormValues : initialValues;

type WizardFormWrapperProps = {
  onWizardSubmit: WizardFormOnSubmit;
  onWizardCancel: WizardNavigationOnCancel;
  error?: Error;
};

export type WizardFormOnSubmit = (values: Record<string, Record<string, unknown>>) => void;

const WizardFormWrapper: FC<WizardFormWrapperProps & PropsWithChildren> = ({ onWizardSubmit, onWizardCancel, error, children }) => {
  const [wizardValues, setWizardValues] = useState<Record<string, Record<string, unknown>>>({});

  // TODO depending on operation success a boolean should be returned and if it failed then formik should do setSubmitting(false) in order to allow submitting again (eg. after validation problem)
  const handleWizardSubmit = (stepFormValues: Record<string, unknown>, wizard: WizardContext, onWizardSubmit: WizardFormOnSubmit) => {
    const { steps, step, next } = wizard;
    const updatedWizardValues = updateWizardValues(step.id, stepFormValues);
    if (!isLastStep(steps, step)) {
      next();
    } else {
      onWizardSubmit(updatedWizardValues);
    }
    return true;
  };

  const updateWizardValues = (stepId: string, stepValues: Record<string, unknown>) => {
    const currentWizardValues = { ...wizardValues };
    currentWizardValues[stepId] = stepValues;
    setWizardValues({ ...currentWizardValues });
    return currentWizardValues;
  };

  const handleWizardPrevious = (stepId: string, stepValues: Record<string, unknown>) => updateWizardValues(stepId, stepValues);

  const stepsContent = React.Children.map(children, (child) => (
    <Step
      // @ts-ignore
      id={child.props.stepId}
      render={(wizard) => (
        <WizardFormContext.Provider
          /* eslint-disable-next-line react/jsx-no-constructed-context-values */
          value={{
            savedStepFormValues: wizardValues ? { ...wizardValues[wizard.step.id] } : {},
            onWizardSubmit: (stepFormValues) => handleWizardSubmit(stepFormValues, wizard, onWizardSubmit),
            onWizardPrevious: (stepFormValues) => handleWizardPrevious(wizard.step.id, stepFormValues),
            onWizardCancel,
            error,
          }}
        >
          {child}
        </WizardFormContext.Provider>
      )}
    />
  ));

  return (
    <Wizard
      render={({ step, steps }) => (
        <>
          <StepsAntD current={steps.indexOf(step)}>
            {steps.map((item) => (
              <StepsAntD.Step key={item.id} title={item.id} />
            ))}
          </StepsAntD>
          <Spacer />
          {stepsContent && <Steps>{stepsContent}</Steps>}
        </>
      )}
    />
  );
};

export { WizardFormWrapper, WizardStepForm };
