import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { BankDetailsFieldsFragmentDoc } from '../../../BankDetails/gql/BankDetailsFragments.types';
import { PersonenbezugFragmentDoc } from '../../../Personenbezug/gql/PersonenbezugFragments.types';
import { PostItFragmentDoc } from '../../PostIt/gql/PostItFragments.types';
import { RechtstraegerSteuerpflichtFragmentDoc } from '../../gql/RechtsTraegerFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FirmaQueryVariables = Types.Exact<{
  firmaId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type FirmaQuery = {
  getFirma: {
    data: {
      companyRegistrationNumber?: string | null;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      fibuKontoCreatable: boolean;
      kundennummer: string;
      kurzBezeichnung: string;
      name1: string;
      name2?: string | null;
      name3?: string | null;
      rechtstraegerId: string;
      tagList: Array<string>;
      vatIdentificationNumber?: string | null;
      bankDetailsList: Array<{
        accountHolder: string;
        accountLimit?: number | null;
        assignedHeVertragZahlungsregelMap: any;
        assignedObjektMap: any;
        bankDetailsId: string;
        bankname: string;
        bic: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        ebicsCreditTransferUserList: Array<string>;
        ebicsDirectDebitUserList: Array<string>;
        fibuKontoId?: string | null;
        fibuKontonummer?: string | null;
        iban: string;
        updatedByMitarbeiterId?: string | null;
        fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
        fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
        sepaMandat?: {
          fileId?: string | null;
          fileName?: string | null;
          sepaMandatId: string;
          sepaMandatReference: string;
          signatureDate?: string | null;
          firmendaten?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firmendatenId: string;
            kundenSystemId: string;
            kurzBezeichnung: string;
            name1: string;
            name2?: string | null;
            name3?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechnungsAussteller?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
          warningList: Array<{ message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      legalForm: { text: string; value: Types.LegalForm };
      personenbezugList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        personenbezugId: string;
        tagList: Array<string>;
        person: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechtstraegerSteuerpflicht?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        rechtstraegerSteuerpflichtId: string;
        validFrom: string;
        historicizedList?: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ message: string; type: string }>;
        }> | null;
        steuerpflicht: { text: string; value: Types.Steuerpflicht };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
      sprache: { value: string; text: string };
      status: { value: Types.RechtstraegerStatus; text: string; description?: string | null };
      type: { value: Types.RechtstraegerType; text: string };
      vertragSummary?: {
        heVertragCount: number;
        heVertragVerwaltungCount: number;
        mietVertragCount: number;
        mietVertragVerwaltungCount: number;
        weSubverwaltungCount: number;
        weVertragCount: number;
        weVertragVerwaltungCount: number;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FirmaChangeHistoryListQueryVariables = Types.Exact<{
  firmaId: Types.Scalars['ID']['input'];
}>;

export type FirmaChangeHistoryListQuery = {
  getFirmaChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type FirmaContactPersonDeletionHistoryListQueryVariables = Types.Exact<{
  firmaId: Types.Scalars['ID']['input'];
}>;

export type FirmaContactPersonDeletionHistoryListQuery = {
  getFirmaContactPersonDeletionHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type FirmaOperatingSiteDeletionHistoryListQueryVariables = Types.Exact<{
  firmaId: Types.Scalars['ID']['input'];
}>;

export type FirmaOperatingSiteDeletionHistoryListQuery = {
  getFirmaOperatingSiteDeletionHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const FirmaDocument = gql`
  query Firma($firmaId: ID!, $withDetails: Boolean) {
    getFirma(firmaId: $firmaId, withDetails: $withDetails) {
      data {
        bankDetailsList {
          ...BankDetailsFields
        }
        companyRegistrationNumber
        createTs
        createdBy
        createdByMitarbeiterId
        deletable
        fibuKontoCreatable
        kundennummer
        kurzBezeichnung
        legalForm {
          text
          value
        }
        name1
        name2
        name3
        personenbezugList {
          ...Personenbezug
        }
        postIt {
          ...PostIt
        }
        rechtstraegerId
        rechtstraegerSteuerpflicht {
          ...RechtstraegerSteuerpflicht
        }
        salutation {
          letterSalutation
          value
          text
        }
        sprache {
          value
          text
        }
        status {
          value
          text
          description
        }
        tagList
        type {
          value
          text
        }
        vatIdentificationNumber
        vertragSummary {
          heVertragCount
          heVertragVerwaltungCount
          mietVertragCount
          mietVertragVerwaltungCount
          weSubverwaltungCount
          weVertragCount
          weVertragVerwaltungCount
        }
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BankDetailsFieldsFragmentDoc}
  ${PersonenbezugFragmentDoc}
  ${PostItFragmentDoc}
  ${RechtstraegerSteuerpflichtFragmentDoc}
`;
export function useFirmaQuery(
  baseOptions: Apollo.QueryHookOptions<FirmaQuery, FirmaQueryVariables> & ({ variables: FirmaQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmaQuery, FirmaQueryVariables>(FirmaDocument, options);
}
export function useFirmaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FirmaQuery, FirmaQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmaQuery, FirmaQueryVariables>(FirmaDocument, options);
}
export function useFirmaSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FirmaQuery, FirmaQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmaQuery, FirmaQueryVariables>(FirmaDocument, options);
}
export type FirmaQueryHookResult = ReturnType<typeof useFirmaQuery>;
export type FirmaLazyQueryHookResult = ReturnType<typeof useFirmaLazyQuery>;
export type FirmaSuspenseQueryHookResult = ReturnType<typeof useFirmaSuspenseQuery>;
export type FirmaQueryResult = Apollo.QueryResult<FirmaQuery, FirmaQueryVariables>;
export const FirmaChangeHistoryListDocument = gql`
  query FirmaChangeHistoryList($firmaId: ID!) {
    getFirmaChangeHistoryList(firmaId: $firmaId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useFirmaChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<FirmaChangeHistoryListQuery, FirmaChangeHistoryListQueryVariables> &
    ({ variables: FirmaChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmaChangeHistoryListQuery, FirmaChangeHistoryListQueryVariables>(FirmaChangeHistoryListDocument, options);
}
export function useFirmaChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmaChangeHistoryListQuery, FirmaChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmaChangeHistoryListQuery, FirmaChangeHistoryListQueryVariables>(FirmaChangeHistoryListDocument, options);
}
export function useFirmaChangeHistoryListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FirmaChangeHistoryListQuery, FirmaChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmaChangeHistoryListQuery, FirmaChangeHistoryListQueryVariables>(FirmaChangeHistoryListDocument, options);
}
export type FirmaChangeHistoryListQueryHookResult = ReturnType<typeof useFirmaChangeHistoryListQuery>;
export type FirmaChangeHistoryListLazyQueryHookResult = ReturnType<typeof useFirmaChangeHistoryListLazyQuery>;
export type FirmaChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useFirmaChangeHistoryListSuspenseQuery>;
export type FirmaChangeHistoryListQueryResult = Apollo.QueryResult<FirmaChangeHistoryListQuery, FirmaChangeHistoryListQueryVariables>;
export const FirmaContactPersonDeletionHistoryListDocument = gql`
  query FirmaContactPersonDeletionHistoryList($firmaId: ID!) {
    getFirmaContactPersonDeletionHistoryList(firmaId: $firmaId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useFirmaContactPersonDeletionHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<FirmaContactPersonDeletionHistoryListQuery, FirmaContactPersonDeletionHistoryListQueryVariables> &
    ({ variables: FirmaContactPersonDeletionHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmaContactPersonDeletionHistoryListQuery, FirmaContactPersonDeletionHistoryListQueryVariables>(
    FirmaContactPersonDeletionHistoryListDocument,
    options
  );
}
export function useFirmaContactPersonDeletionHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmaContactPersonDeletionHistoryListQuery, FirmaContactPersonDeletionHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmaContactPersonDeletionHistoryListQuery, FirmaContactPersonDeletionHistoryListQueryVariables>(
    FirmaContactPersonDeletionHistoryListDocument,
    options
  );
}
export function useFirmaContactPersonDeletionHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FirmaContactPersonDeletionHistoryListQuery, FirmaContactPersonDeletionHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmaContactPersonDeletionHistoryListQuery, FirmaContactPersonDeletionHistoryListQueryVariables>(
    FirmaContactPersonDeletionHistoryListDocument,
    options
  );
}
export type FirmaContactPersonDeletionHistoryListQueryHookResult = ReturnType<typeof useFirmaContactPersonDeletionHistoryListQuery>;
export type FirmaContactPersonDeletionHistoryListLazyQueryHookResult = ReturnType<typeof useFirmaContactPersonDeletionHistoryListLazyQuery>;
export type FirmaContactPersonDeletionHistoryListSuspenseQueryHookResult = ReturnType<typeof useFirmaContactPersonDeletionHistoryListSuspenseQuery>;
export type FirmaContactPersonDeletionHistoryListQueryResult = Apollo.QueryResult<
  FirmaContactPersonDeletionHistoryListQuery,
  FirmaContactPersonDeletionHistoryListQueryVariables
>;
export const FirmaOperatingSiteDeletionHistoryListDocument = gql`
  query FirmaOperatingSiteDeletionHistoryList($firmaId: ID!) {
    getFirmaOperatingSiteDeletionHistoryList(firmaId: $firmaId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useFirmaOperatingSiteDeletionHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<FirmaOperatingSiteDeletionHistoryListQuery, FirmaOperatingSiteDeletionHistoryListQueryVariables> &
    ({ variables: FirmaOperatingSiteDeletionHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmaOperatingSiteDeletionHistoryListQuery, FirmaOperatingSiteDeletionHistoryListQueryVariables>(
    FirmaOperatingSiteDeletionHistoryListDocument,
    options
  );
}
export function useFirmaOperatingSiteDeletionHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmaOperatingSiteDeletionHistoryListQuery, FirmaOperatingSiteDeletionHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmaOperatingSiteDeletionHistoryListQuery, FirmaOperatingSiteDeletionHistoryListQueryVariables>(
    FirmaOperatingSiteDeletionHistoryListDocument,
    options
  );
}
export function useFirmaOperatingSiteDeletionHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FirmaOperatingSiteDeletionHistoryListQuery, FirmaOperatingSiteDeletionHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmaOperatingSiteDeletionHistoryListQuery, FirmaOperatingSiteDeletionHistoryListQueryVariables>(
    FirmaOperatingSiteDeletionHistoryListDocument,
    options
  );
}
export type FirmaOperatingSiteDeletionHistoryListQueryHookResult = ReturnType<typeof useFirmaOperatingSiteDeletionHistoryListQuery>;
export type FirmaOperatingSiteDeletionHistoryListLazyQueryHookResult = ReturnType<typeof useFirmaOperatingSiteDeletionHistoryListLazyQuery>;
export type FirmaOperatingSiteDeletionHistoryListSuspenseQueryHookResult = ReturnType<typeof useFirmaOperatingSiteDeletionHistoryListSuspenseQuery>;
export type FirmaOperatingSiteDeletionHistoryListQueryResult = Apollo.QueryResult<
  FirmaOperatingSiteDeletionHistoryListQuery,
  FirmaOperatingSiteDeletionHistoryListQueryVariables
>;
