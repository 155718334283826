import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  mapFormValuesToTopAbrVerarbeitungFilters,
  UstVomAufwandVerarbeitungEntryFiltersFormValues,
  ustVomAufwandVerarbEntryListingFiltersFormInitialValues,
} from './Filters/ListingFiltersFormMapper';
import ListingFilters from './Filters/ListingFilters';
import UstVomAufwandVerarbeitungEntryTable from '../UstVomAufwandVerarbeitungEntryTable';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { verarbeitungTypes } from '../../verarbeitungHelpers';
import { TUstVomAufwandVerarbeitungQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import { UstVomAufwandGenerierlauf } from '../../../../types';

type UstVomAufwandVerarbeitungEntryListingProps = {
  generierlauf: UstVomAufwandGenerierlauf;
};

const UstVomAufwandVerarbeitungEntryListing: FC<UstVomAufwandVerarbeitungEntryListingProps> = ({ generierlauf }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TUstVomAufwandVerarbeitungQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(verarbeitungTypes.UstVomAufwand, generierlauf.generierlaufId, navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: UstVomAufwandVerarbeitungEntryFiltersFormValues) => {
    updateQueryParams(
      verarbeitungTypes.UstVomAufwand,
      generierlauf.generierlaufId,
      navigate,
      mapFormValuesToTopAbrVerarbeitungFilters(values),
      paginationParams
    );
  };

  return (
    <Formik<UstVomAufwandVerarbeitungEntryFiltersFormValues>
      initialValues={ustVomAufwandVerarbEntryListingFiltersFormInitialValues(queryParams)}
      onSubmit={onChange}
    >
      {(formikProps) => (
        <>
          <ListingFilters formikProps={formikProps} entryList={generierlauf.entryList} />
          <UstVomAufwandVerarbeitungEntryTable entryList={generierlauf.entryList} formikProps={formikProps} />
        </>
      )}
    </Formik>
  );
};

export default UstVomAufwandVerarbeitungEntryListing;
