import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../../../styles/pdfStyles';

const TAAbrKreisUst: FC<{ ust: { text: string; betrag: string } }> = ({ ust }) => (
  <View style={[pdfStyles.row]}>
    <View style={[pdfStyles.column, { width: '60%' }]}>
      <Text style={[pdfStyles.column]}>{ust.text}</Text>
    </View>
    <View style={[pdfStyles.column, { width: '40%' }]}>
      <Text style={[pdfStyles.column, { textAlign: 'right' }]}>{ust.betrag}</Text>
    </View>
  </View>
);

export default TAAbrKreisUst;
