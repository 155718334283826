import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { EmailDeliveryDefinitionType } from '../../../../types';
import { useCreateInfoMailDeliveryWizardMutation } from '../gql/InfoMailDeliveryWizardMutations.types';
import { createFormFields, createFormInitialValues, CreateFormValues } from './createFormMapper';
import { createFormValidationSchema } from './createFormValidationSchema';
import EmailDeliveryDefinitionSelect from '../../../EmailDeliveryDefinition/Select/EmailDeliveryDefinitionSelect';
import ObjektSelect from '../../../ObjektSelect/ObjektSelect';
import FormButtons from '../../../../components/Button/FormButtons';
import { generatePathToWizardUpdatePage } from '../../wizardApiPaths';
import { wizardTypes } from '../../wizardHelpers';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';

type Props = {
  onCancel: () => void;
};

const CreateForm: FC<Props> = ({ onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'InfoMailDeliveryWizard'>('InfoMailDeliveryWizard');

  const navigate = useNavigate();

  const [runCreate] = useCreateInfoMailDeliveryWizardMutation();

  return (
    <Formik<CreateFormValues>
      initialValues={createFormInitialValues}
      validationSchema={createFormValidationSchema}
      onSubmit={(formValues) => {
        runCreate({
          variables: {
            objektId: formValues.objektId,
            emailDeliveryDefinitionId: formValues.emailDeliveryDefinitionId,
          },
        }).then((result) => {
          const { data } = result;
          const wizardId = data?.createInfoMailDeliveryWizard.data.wizardId;

          if (!wizardId) return;
          navigate(generatePathToWizardUpdatePage(wizardTypes.InfoMailDelivery, wizardId));
        });
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithFieldHelp
            name={createFormFields.emailDeliveryDefinitionId}
            label="E-Mail Versanddefinition"
            fieldHelp={getFieldHelpText('InfoMailDeliveryWizard.emailDeliveryDefinitionId')}
          >
            <EmailDeliveryDefinitionSelect
              name={createFormFields.emailDeliveryDefinitionId}
              type={EmailDeliveryDefinitionType.Informationsschreiben}
            />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp name={createFormFields.objektId} label="Objekt" fieldHelp={getFieldHelpText('InfoMailDeliveryWizard.objektId')}>
            <ObjektSelect name={createFormFields.objektId} />
          </FormItemWithFieldHelp>
          <FormButtons okText="Weiter" updateMode={false} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default CreateForm;
