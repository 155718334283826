import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { ContactFieldsFragmentDoc, ContactPersonFieldsFragmentDoc } from '../../Contact/gql/ContactFragments.types';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateObjektTicketMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  input: Types.TicketCreateInput;
}>;

export type CreateObjektTicketMutation = {
  createObjektTicket: {
    data: { ticketId: string; parent?: { ticketId: string } | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateObjektInfoFeldTicketMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  objektInfoFeldId: Types.Scalars['ID']['input'];
  input: Types.TicketCreateInput;
}>;

export type CreateObjektInfoFeldTicketMutation = {
  createObjektInfoFeldTicket: {
    data: { ticketId: string; parent?: { ticketId: string } | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateBestandseinheitTicketMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  input: Types.TicketCreateInput;
}>;

export type CreateBestandseinheitTicketMutation = {
  createBestandseinheitTicket: {
    data: { ticketId: string; parent?: { ticketId: string } | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateBestandseinheitInfoFeldTicketMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  bestandseinheitInfoFeldId: Types.Scalars['ID']['input'];
  input: Types.TicketCreateInput;
}>;

export type CreateBestandseinheitInfoFeldTicketMutation = {
  createBestandseinheitInfoFeldTicket: {
    data: { ticketId: string; parent?: { ticketId: string } | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateVertragTicketMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  input: Types.TicketCreateInput;
}>;

export type CreateVertragTicketMutation = {
  createVertragTicket: {
    data: { ticketId: string; parent?: { ticketId: string } | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateVertragInfoFeldTicketMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vertragInfoFeldId: Types.Scalars['ID']['input'];
  input: Types.TicketCreateInput;
}>;

export type CreateVertragInfoFeldTicketMutation = {
  createVertragInfoFeldTicket: {
    data: { ticketId: string; parent?: { ticketId: string } | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateRechtstraegerTicketMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  input: Types.TicketCreateInput;
}>;

export type CreateRechtstraegerTicketMutation = {
  createRechtstragerTicket: {
    data: { ticketId: string; parent?: { ticketId: string } | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateRechtstraegerInfoFeldTicketMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  rechtstraegerInfoFeldId: Types.Scalars['ID']['input'];
  input: Types.TicketCreateInput;
}>;

export type CreateRechtstraegerInfoFeldTicketMutation = {
  createRechtstragerInfoFeldTicket: {
    data: { ticketId: string; parent?: { ticketId: string } | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateTicketMutationVariables = Types.Exact<{
  ticketId: Types.Scalars['ID']['input'];
  input: Types.TicketUpdateInput;
}>;

export type UpdateTicketMutation = {
  updateTicket: {
    data: { ticketId: string; dueDate: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteTicketAttachmentMutationVariables = Types.Exact<{
  ticketId: Types.Scalars['ID']['input'];
  attachmentId: Types.Scalars['ID']['input'];
}>;

export type DeleteTicketAttachmentMutation = {
  deleteTicketAttachment: {
    data: { ticketId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ArchiveTicketMutationVariables = Types.Exact<{
  ticketId: Types.Scalars['ID']['input'];
}>;

export type ArchiveTicketMutation = {
  actionArchiveTicket: {
    data: { ticketId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveTicketMutationVariables = Types.Exact<{
  ticketId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveTicketMutation = {
  actionUnarchiveTicket: {
    data: { ticketId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type TicketUpdateStatusMutationVariables = Types.Exact<{
  ticketId: Types.Scalars['ID']['input'];
  input: Types.TicketActionUpdateStatusInput;
}>;

export type TicketUpdateStatusMutation = {
  actionTicketUpdateStatus: {
    data: { ticketId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type TicketCreateSubTicketMutationVariables = Types.Exact<{
  ticketId: Types.Scalars['ID']['input'];
  input: Types.TicketActionCreateSubTicketInput;
}>;

export type TicketCreateSubTicketMutation = {
  actionTicketCreateSubTicket: {
    data: { ticketId: string; dueDate: string; parent?: { ticketId: string } | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type TicketChangeSubTicketPositionMutationVariables = Types.Exact<{
  ticketId: Types.Scalars['ID']['input'];
  input: Types.TicketActionChangeSubTicketPositionInput;
}>;

export type TicketChangeSubTicketPositionMutation = {
  actionTicketChangeSubTicketPosition: {
    data: { ticketId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateTicketCommentMutationVariables = Types.Exact<{
  ticketId: Types.Scalars['ID']['input'];
  input: Types.TicketCommentCreateInput;
}>;

export type CreateTicketCommentMutation = {
  createTicketComment: {
    data: { ticketCommentId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateTicketCommentMutationVariables = Types.Exact<{
  ticketId: Types.Scalars['ID']['input'];
  ticketCommentId: Types.Scalars['ID']['input'];
  input: Types.TicketCommentUpdateInput;
}>;

export type UpdateTicketCommentMutation = {
  updateTicketComment: {
    data: { ticketCommentId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteTicketCommentMutationVariables = Types.Exact<{
  ticketId: Types.Scalars['ID']['input'];
  ticketCommentId: Types.Scalars['ID']['input'];
}>;

export type DeleteTicketCommentMutation = {
  deleteTicketComment: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type AssignRechtstraegerToParticipantMutationVariables = Types.Exact<{
  ticketId: Types.Scalars['ID']['input'];
  participantId: Types.Scalars['ID']['input'];
  input: Types.RechtstraegerAssignmentInput;
}>;

export type AssignRechtstraegerToParticipantMutation = {
  assignRechtstraegerToParticipant: {
    data: {
      emailAddress?: string | null;
      participantId?: string | null;
      contact?: {
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechtstraeger?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type TicketParticipantMutationVariables = Types.Exact<{
  ticketId: Types.Scalars['ID']['input'];
  input: Types.TicketParticipantActionInput;
}>;

export type TicketParticipantMutation = {
  actionTicketParticipant: {
    data: {
      emailAddress?: string | null;
      participantId?: string | null;
      contact?: {
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechtstraeger?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateObjektTicketDocument = gql`
  mutation CreateObjektTicket($objektId: ID!, $input: TicketCreateInput!) {
    createObjektTicket(objektId: $objektId, input: $input) {
      data {
        ticketId
        parent {
          ticketId
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateObjektTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateObjektTicketMutation, CreateObjektTicketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateObjektTicketMutation, CreateObjektTicketMutationVariables>(CreateObjektTicketDocument, options);
}
export type CreateObjektTicketMutationHookResult = ReturnType<typeof useCreateObjektTicketMutation>;
export type CreateObjektTicketMutationResult = Apollo.MutationResult<CreateObjektTicketMutation>;
export type CreateObjektTicketMutationOptions = Apollo.BaseMutationOptions<CreateObjektTicketMutation, CreateObjektTicketMutationVariables>;
export const CreateObjektInfoFeldTicketDocument = gql`
  mutation CreateObjektInfoFeldTicket($objektId: ID!, $objektInfoFeldId: ID!, $input: TicketCreateInput!) {
    createObjektInfoFeldTicket(objektId: $objektId, objektInfoFeldId: $objektInfoFeldId, input: $input) {
      data {
        ticketId
        parent {
          ticketId
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateObjektInfoFeldTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateObjektInfoFeldTicketMutation, CreateObjektInfoFeldTicketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateObjektInfoFeldTicketMutation, CreateObjektInfoFeldTicketMutationVariables>(
    CreateObjektInfoFeldTicketDocument,
    options
  );
}
export type CreateObjektInfoFeldTicketMutationHookResult = ReturnType<typeof useCreateObjektInfoFeldTicketMutation>;
export type CreateObjektInfoFeldTicketMutationResult = Apollo.MutationResult<CreateObjektInfoFeldTicketMutation>;
export type CreateObjektInfoFeldTicketMutationOptions = Apollo.BaseMutationOptions<
  CreateObjektInfoFeldTicketMutation,
  CreateObjektInfoFeldTicketMutationVariables
>;
export const CreateBestandseinheitTicketDocument = gql`
  mutation CreateBestandseinheitTicket($objektId: ID!, $bestandseinheitId: ID!, $input: TicketCreateInput!) {
    createBestandseinheitTicket(objektId: $objektId, bestandseinheitId: $bestandseinheitId, input: $input) {
      data {
        ticketId
        parent {
          ticketId
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateBestandseinheitTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBestandseinheitTicketMutation, CreateBestandseinheitTicketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBestandseinheitTicketMutation, CreateBestandseinheitTicketMutationVariables>(
    CreateBestandseinheitTicketDocument,
    options
  );
}
export type CreateBestandseinheitTicketMutationHookResult = ReturnType<typeof useCreateBestandseinheitTicketMutation>;
export type CreateBestandseinheitTicketMutationResult = Apollo.MutationResult<CreateBestandseinheitTicketMutation>;
export type CreateBestandseinheitTicketMutationOptions = Apollo.BaseMutationOptions<
  CreateBestandseinheitTicketMutation,
  CreateBestandseinheitTicketMutationVariables
>;
export const CreateBestandseinheitInfoFeldTicketDocument = gql`
  mutation CreateBestandseinheitInfoFeldTicket($objektId: ID!, $bestandseinheitId: ID!, $bestandseinheitInfoFeldId: ID!, $input: TicketCreateInput!) {
    createBestandseinheitInfoFeldTicket(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      bestandseinheitInfoFeldId: $bestandseinheitInfoFeldId
      input: $input
    ) {
      data {
        ticketId
        parent {
          ticketId
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateBestandseinheitInfoFeldTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBestandseinheitInfoFeldTicketMutation, CreateBestandseinheitInfoFeldTicketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBestandseinheitInfoFeldTicketMutation, CreateBestandseinheitInfoFeldTicketMutationVariables>(
    CreateBestandseinheitInfoFeldTicketDocument,
    options
  );
}
export type CreateBestandseinheitInfoFeldTicketMutationHookResult = ReturnType<typeof useCreateBestandseinheitInfoFeldTicketMutation>;
export type CreateBestandseinheitInfoFeldTicketMutationResult = Apollo.MutationResult<CreateBestandseinheitInfoFeldTicketMutation>;
export type CreateBestandseinheitInfoFeldTicketMutationOptions = Apollo.BaseMutationOptions<
  CreateBestandseinheitInfoFeldTicketMutation,
  CreateBestandseinheitInfoFeldTicketMutationVariables
>;
export const CreateVertragTicketDocument = gql`
  mutation CreateVertragTicket($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!, $input: TicketCreateInput!) {
    createVertragTicket(objektId: $objektId, bestandseinheitId: $bestandseinheitId, vertragId: $vertragId, input: $input) {
      data {
        ticketId
        parent {
          ticketId
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateVertragTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateVertragTicketMutation, CreateVertragTicketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVertragTicketMutation, CreateVertragTicketMutationVariables>(CreateVertragTicketDocument, options);
}
export type CreateVertragTicketMutationHookResult = ReturnType<typeof useCreateVertragTicketMutation>;
export type CreateVertragTicketMutationResult = Apollo.MutationResult<CreateVertragTicketMutation>;
export type CreateVertragTicketMutationOptions = Apollo.BaseMutationOptions<CreateVertragTicketMutation, CreateVertragTicketMutationVariables>;
export const CreateVertragInfoFeldTicketDocument = gql`
  mutation CreateVertragInfoFeldTicket(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vertragInfoFeldId: ID!
    $input: TicketCreateInput!
  ) {
    createVertragInfoFeldTicket(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vertragInfoFeldId: $vertragInfoFeldId
      input: $input
    ) {
      data {
        ticketId
        parent {
          ticketId
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateVertragInfoFeldTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateVertragInfoFeldTicketMutation, CreateVertragInfoFeldTicketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVertragInfoFeldTicketMutation, CreateVertragInfoFeldTicketMutationVariables>(
    CreateVertragInfoFeldTicketDocument,
    options
  );
}
export type CreateVertragInfoFeldTicketMutationHookResult = ReturnType<typeof useCreateVertragInfoFeldTicketMutation>;
export type CreateVertragInfoFeldTicketMutationResult = Apollo.MutationResult<CreateVertragInfoFeldTicketMutation>;
export type CreateVertragInfoFeldTicketMutationOptions = Apollo.BaseMutationOptions<
  CreateVertragInfoFeldTicketMutation,
  CreateVertragInfoFeldTicketMutationVariables
>;
export const CreateRechtstraegerTicketDocument = gql`
  mutation CreateRechtstraegerTicket($rechtstraegerId: ID!, $input: TicketCreateInput!) {
    createRechtstragerTicket(rechtstraegerId: $rechtstraegerId, input: $input) {
      data {
        ticketId
        parent {
          ticketId
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateRechtstraegerTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRechtstraegerTicketMutation, CreateRechtstraegerTicketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRechtstraegerTicketMutation, CreateRechtstraegerTicketMutationVariables>(
    CreateRechtstraegerTicketDocument,
    options
  );
}
export type CreateRechtstraegerTicketMutationHookResult = ReturnType<typeof useCreateRechtstraegerTicketMutation>;
export type CreateRechtstraegerTicketMutationResult = Apollo.MutationResult<CreateRechtstraegerTicketMutation>;
export type CreateRechtstraegerTicketMutationOptions = Apollo.BaseMutationOptions<
  CreateRechtstraegerTicketMutation,
  CreateRechtstraegerTicketMutationVariables
>;
export const CreateRechtstraegerInfoFeldTicketDocument = gql`
  mutation CreateRechtstraegerInfoFeldTicket($rechtstraegerId: ID!, $rechtstraegerInfoFeldId: ID!, $input: TicketCreateInput!) {
    createRechtstragerInfoFeldTicket(rechtstraegerId: $rechtstraegerId, rechtstraegerInfoFeldId: $rechtstraegerInfoFeldId, input: $input) {
      data {
        ticketId
        parent {
          ticketId
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateRechtstraegerInfoFeldTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRechtstraegerInfoFeldTicketMutation, CreateRechtstraegerInfoFeldTicketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRechtstraegerInfoFeldTicketMutation, CreateRechtstraegerInfoFeldTicketMutationVariables>(
    CreateRechtstraegerInfoFeldTicketDocument,
    options
  );
}
export type CreateRechtstraegerInfoFeldTicketMutationHookResult = ReturnType<typeof useCreateRechtstraegerInfoFeldTicketMutation>;
export type CreateRechtstraegerInfoFeldTicketMutationResult = Apollo.MutationResult<CreateRechtstraegerInfoFeldTicketMutation>;
export type CreateRechtstraegerInfoFeldTicketMutationOptions = Apollo.BaseMutationOptions<
  CreateRechtstraegerInfoFeldTicketMutation,
  CreateRechtstraegerInfoFeldTicketMutationVariables
>;
export const UpdateTicketDocument = gql`
  mutation UpdateTicket($ticketId: ID!, $input: TicketUpdateInput!) {
    updateTicket(ticketId: $ticketId, input: $input) {
      data {
        ticketId
        dueDate
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateTicketMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTicketMutation, UpdateTicketMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTicketMutation, UpdateTicketMutationVariables>(UpdateTicketDocument, options);
}
export type UpdateTicketMutationHookResult = ReturnType<typeof useUpdateTicketMutation>;
export type UpdateTicketMutationResult = Apollo.MutationResult<UpdateTicketMutation>;
export type UpdateTicketMutationOptions = Apollo.BaseMutationOptions<UpdateTicketMutation, UpdateTicketMutationVariables>;
export const DeleteTicketAttachmentDocument = gql`
  mutation DeleteTicketAttachment($ticketId: ID!, $attachmentId: ID!) {
    deleteTicketAttachment(ticketId: $ticketId, attachmentId: $attachmentId) {
      data {
        ticketId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteTicketAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTicketAttachmentMutation, DeleteTicketAttachmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTicketAttachmentMutation, DeleteTicketAttachmentMutationVariables>(DeleteTicketAttachmentDocument, options);
}
export type DeleteTicketAttachmentMutationHookResult = ReturnType<typeof useDeleteTicketAttachmentMutation>;
export type DeleteTicketAttachmentMutationResult = Apollo.MutationResult<DeleteTicketAttachmentMutation>;
export type DeleteTicketAttachmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteTicketAttachmentMutation,
  DeleteTicketAttachmentMutationVariables
>;
export const ArchiveTicketDocument = gql`
  mutation ArchiveTicket($ticketId: ID!) {
    actionArchiveTicket(ticketId: $ticketId) {
      data {
        ticketId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveTicketMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveTicketMutation, ArchiveTicketMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveTicketMutation, ArchiveTicketMutationVariables>(ArchiveTicketDocument, options);
}
export type ArchiveTicketMutationHookResult = ReturnType<typeof useArchiveTicketMutation>;
export type ArchiveTicketMutationResult = Apollo.MutationResult<ArchiveTicketMutation>;
export type ArchiveTicketMutationOptions = Apollo.BaseMutationOptions<ArchiveTicketMutation, ArchiveTicketMutationVariables>;
export const UnarchiveTicketDocument = gql`
  mutation UnarchiveTicket($ticketId: ID!) {
    actionUnarchiveTicket(ticketId: $ticketId) {
      data {
        ticketId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveTicketMutation(baseOptions?: Apollo.MutationHookOptions<UnarchiveTicketMutation, UnarchiveTicketMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveTicketMutation, UnarchiveTicketMutationVariables>(UnarchiveTicketDocument, options);
}
export type UnarchiveTicketMutationHookResult = ReturnType<typeof useUnarchiveTicketMutation>;
export type UnarchiveTicketMutationResult = Apollo.MutationResult<UnarchiveTicketMutation>;
export type UnarchiveTicketMutationOptions = Apollo.BaseMutationOptions<UnarchiveTicketMutation, UnarchiveTicketMutationVariables>;
export const TicketUpdateStatusDocument = gql`
  mutation TicketUpdateStatus($ticketId: ID!, $input: TicketActionUpdateStatusInput!) {
    actionTicketUpdateStatus(ticketId: $ticketId, input: $input) {
      data {
        ticketId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useTicketUpdateStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<TicketUpdateStatusMutation, TicketUpdateStatusMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TicketUpdateStatusMutation, TicketUpdateStatusMutationVariables>(TicketUpdateStatusDocument, options);
}
export type TicketUpdateStatusMutationHookResult = ReturnType<typeof useTicketUpdateStatusMutation>;
export type TicketUpdateStatusMutationResult = Apollo.MutationResult<TicketUpdateStatusMutation>;
export type TicketUpdateStatusMutationOptions = Apollo.BaseMutationOptions<TicketUpdateStatusMutation, TicketUpdateStatusMutationVariables>;
export const TicketCreateSubTicketDocument = gql`
  mutation TicketCreateSubTicket($ticketId: ID!, $input: TicketActionCreateSubTicketInput!) {
    actionTicketCreateSubTicket(ticketId: $ticketId, input: $input) {
      data {
        ticketId
        dueDate
        parent {
          ticketId
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useTicketCreateSubTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<TicketCreateSubTicketMutation, TicketCreateSubTicketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TicketCreateSubTicketMutation, TicketCreateSubTicketMutationVariables>(TicketCreateSubTicketDocument, options);
}
export type TicketCreateSubTicketMutationHookResult = ReturnType<typeof useTicketCreateSubTicketMutation>;
export type TicketCreateSubTicketMutationResult = Apollo.MutationResult<TicketCreateSubTicketMutation>;
export type TicketCreateSubTicketMutationOptions = Apollo.BaseMutationOptions<TicketCreateSubTicketMutation, TicketCreateSubTicketMutationVariables>;
export const TicketChangeSubTicketPositionDocument = gql`
  mutation TicketChangeSubTicketPosition($ticketId: ID!, $input: TicketActionChangeSubTicketPositionInput!) {
    actionTicketChangeSubTicketPosition(ticketId: $ticketId, input: $input) {
      data {
        ticketId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useTicketChangeSubTicketPositionMutation(
  baseOptions?: Apollo.MutationHookOptions<TicketChangeSubTicketPositionMutation, TicketChangeSubTicketPositionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TicketChangeSubTicketPositionMutation, TicketChangeSubTicketPositionMutationVariables>(
    TicketChangeSubTicketPositionDocument,
    options
  );
}
export type TicketChangeSubTicketPositionMutationHookResult = ReturnType<typeof useTicketChangeSubTicketPositionMutation>;
export type TicketChangeSubTicketPositionMutationResult = Apollo.MutationResult<TicketChangeSubTicketPositionMutation>;
export type TicketChangeSubTicketPositionMutationOptions = Apollo.BaseMutationOptions<
  TicketChangeSubTicketPositionMutation,
  TicketChangeSubTicketPositionMutationVariables
>;
export const CreateTicketCommentDocument = gql`
  mutation CreateTicketComment($ticketId: ID!, $input: TicketCommentCreateInput!) {
    createTicketComment(ticketId: $ticketId, input: $input) {
      data {
        ticketCommentId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateTicketCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTicketCommentMutation, CreateTicketCommentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTicketCommentMutation, CreateTicketCommentMutationVariables>(CreateTicketCommentDocument, options);
}
export type CreateTicketCommentMutationHookResult = ReturnType<typeof useCreateTicketCommentMutation>;
export type CreateTicketCommentMutationResult = Apollo.MutationResult<CreateTicketCommentMutation>;
export type CreateTicketCommentMutationOptions = Apollo.BaseMutationOptions<CreateTicketCommentMutation, CreateTicketCommentMutationVariables>;
export const UpdateTicketCommentDocument = gql`
  mutation UpdateTicketComment($ticketId: ID!, $ticketCommentId: ID!, $input: TicketCommentUpdateInput!) {
    updateTicketComment(ticketId: $ticketId, ticketCommentId: $ticketCommentId, input: $input) {
      data {
        ticketCommentId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateTicketCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTicketCommentMutation, UpdateTicketCommentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTicketCommentMutation, UpdateTicketCommentMutationVariables>(UpdateTicketCommentDocument, options);
}
export type UpdateTicketCommentMutationHookResult = ReturnType<typeof useUpdateTicketCommentMutation>;
export type UpdateTicketCommentMutationResult = Apollo.MutationResult<UpdateTicketCommentMutation>;
export type UpdateTicketCommentMutationOptions = Apollo.BaseMutationOptions<UpdateTicketCommentMutation, UpdateTicketCommentMutationVariables>;
export const DeleteTicketCommentDocument = gql`
  mutation DeleteTicketComment($ticketId: ID!, $ticketCommentId: ID!) {
    deleteTicketComment(ticketId: $ticketId, ticketCommentId: $ticketCommentId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteTicketCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTicketCommentMutation, DeleteTicketCommentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTicketCommentMutation, DeleteTicketCommentMutationVariables>(DeleteTicketCommentDocument, options);
}
export type DeleteTicketCommentMutationHookResult = ReturnType<typeof useDeleteTicketCommentMutation>;
export type DeleteTicketCommentMutationResult = Apollo.MutationResult<DeleteTicketCommentMutation>;
export type DeleteTicketCommentMutationOptions = Apollo.BaseMutationOptions<DeleteTicketCommentMutation, DeleteTicketCommentMutationVariables>;
export const AssignRechtstraegerToParticipantDocument = gql`
  mutation assignRechtstraegerToParticipant($ticketId: ID!, $participantId: ID!, $input: RechtstraegerAssignmentInput!) {
    assignRechtstraegerToParticipant(ticketId: $ticketId, participantId: $participantId, input: $input) {
      data {
        contact {
          ...ContactFields
        }
        emailAddress
        participantId
        rechtstraeger {
          ...RechtstraegerBaseFields
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${ContactFieldsFragmentDoc}
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
export function useAssignRechtstraegerToParticipantMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignRechtstraegerToParticipantMutation, AssignRechtstraegerToParticipantMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignRechtstraegerToParticipantMutation, AssignRechtstraegerToParticipantMutationVariables>(
    AssignRechtstraegerToParticipantDocument,
    options
  );
}
export type AssignRechtstraegerToParticipantMutationHookResult = ReturnType<typeof useAssignRechtstraegerToParticipantMutation>;
export type AssignRechtstraegerToParticipantMutationResult = Apollo.MutationResult<AssignRechtstraegerToParticipantMutation>;
export type AssignRechtstraegerToParticipantMutationOptions = Apollo.BaseMutationOptions<
  AssignRechtstraegerToParticipantMutation,
  AssignRechtstraegerToParticipantMutationVariables
>;
export const TicketParticipantDocument = gql`
  mutation TicketParticipant($ticketId: ID!, $input: TicketParticipantActionInput!) {
    actionTicketParticipant(ticketId: $ticketId, input: $input) {
      data {
        contact {
          ...ContactFields
        }
        emailAddress
        participantId
        rechtstraeger {
          ...RechtstraegerBaseFields
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${ContactFieldsFragmentDoc}
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
export function useTicketParticipantMutation(
  baseOptions?: Apollo.MutationHookOptions<TicketParticipantMutation, TicketParticipantMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TicketParticipantMutation, TicketParticipantMutationVariables>(TicketParticipantDocument, options);
}
export type TicketParticipantMutationHookResult = ReturnType<typeof useTicketParticipantMutation>;
export type TicketParticipantMutationResult = Apollo.MutationResult<TicketParticipantMutation>;
export type TicketParticipantMutationOptions = Apollo.BaseMutationOptions<TicketParticipantMutation, TicketParticipantMutationVariables>;
