import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useOffenePostenKontoTypeListQuery } from '../gql/OffenePostenQueries.types';

type OPKontoTypeSelectProps = {
  name: string;
  style?: any;
} & Omit<SelectProps, 'id'>;

const OPKontoTypeSelect: FC<OPKontoTypeSelectProps> = ({ name, onChange, style, ...restProps }) => {
  const { data, loading } = useOffenePostenKontoTypeListQuery();
  const kontotypeList = data?.getOffenePostenKontoTypeList.data || [];

  return (
    <Select
      {...restProps}
      allowClear
      name={name}
      id={name}
      loading={loading}
      placeholder="Kontotyp auswählen"
      onChange={onChange}
      style={{ width: '100%' }}
      disabled={restProps.disabled}
    >
      {kontotypeList.map((kontotype) => (
        <Select.Option key={kontotype.value} value={kontotype.value}>
          {kontotype.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default OPKontoTypeSelect;
