import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedNumber } from 'react-intl';
import { Space, TableColumnProps, Typography } from 'antd';
import { BestandseinheitBase, Budgeting, BudgetingResultAufteilungsschluessel } from '../../../../../types';
import { EuroAmount } from '../../../../../components/Number';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { pathsToObjektDetailsPage } from '../../../../Objekt/objektUriPaths';
import { generatePathToBestandseinheitDetailsPage } from '../../../../Bestandseinheit/bestandseinheitUriPaths';

const budgetingResultAufteilungsschluesselTableColumns = (
  budgeting: Budgeting,
  bestandseinheit: BestandseinheitBase
): TableColumnProps<BudgetingResultAufteilungsschluessel>[] => [
  {
    title: 'Vorschreibungsposition-Wert',
    width: 200,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={20} text={record.name}>
        {(shortenedText) => <Typography.Text>{shortenedText}</Typography.Text>}
      </DataWithShortenedText>
    ),
  },
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: 'Jahresbudget Summe',
    align: 'right',
    render: (text, record) => <EuroAmount value={record.estimateNextYear} />,
  },
  {
    title: 'Gesamt Anteil Auft.Schlüssel',
    align: 'right',
    render: (text, record) => (
      <Link to={pathsToObjektDetailsPage(budgeting.objekt.objektId).vorgabenTab} target="_blank">
        <Space size={2}>
          <FormattedNumber value={record.aufteilungsschluesselBasis} />
          {record.kurzBezeichnung}
        </Space>
      </Link>
    ),
  },
  {
    title: 'Einheitswert',
    align: 'right',
    render: (text, record) => <EuroAmount value={record.unitValue} />,
  },
  {
    title: 'Top Anteil Auft.Schlüssel',
    align: 'right',
    render: (text, record) => (
      <Link to={generatePathToBestandseinheitDetailsPage(budgeting.objekt.objektId, bestandseinheit.bestandseinheitId)} target="_blank">
        <Space size={2}>
          <FormattedNumber value={record.aufteilungsschluesselAnteil} />
          {record.kurzBezeichnung}
        </Space>
      </Link>
    ),
  },
  {
    title: 'Budgetierung Wert-Anteil jährlich',
    align: 'right',
    render: (text, record) => <EuroAmount value={record.budgetingWertAnteil} />,
  },
];

export default budgetingResultAufteilungsschluesselTableColumns;
