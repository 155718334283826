import React, { FC } from 'react';
import { DeleteOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button, Modal, Tooltip } from 'antd';
import { generatePath } from 'react-router';
import { FetchResult, MutationFunctionOptions } from '@apollo/client';
import { isStatusDeleted, isStatusGesperrt } from '../../helpers/statusHelper';
import { personToString } from '../../shared/components/Person/personHelper';
import { useGoBack } from '../../shared/GoBack/GoBackContext';
import { showSuccessMsgDelete, showSuccessMsgEntsperrt, showSuccessMsgGesperrt } from '../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../helpers/apolloHelper';
import {
  DeleteMitarbeiterMutation,
  DeleteMitarbeiterMutationVariables,
  EntsperrenMitarbeiterMutation,
  EntsperrenMitarbeiterMutationVariables,
  SperrenMitarbeiterMutation,
  SperrenMitarbeiterMutationVariables,
  useDeleteMitarbeiterMutation,
  useEntsperrenMitarbeiterMutation,
  useSperrenMitarbeiterMutation,
} from './gql/MitarbeiterMutations.types';
import { URI_MANAGEMENT_PAGE_SYSTEM_FIRMENDATEN_DETAILS_PAGE } from '../../constants/managementPageUriPaths';
import { Mitarbeiter } from '../../types';

const lockText = <span>Mitarbeiter sperren</span>;
const unlockText = <span>Mitarbeiter entsperren</span>;
const deleteText = <span>Mitarbeiter löschen</span>;

type MitarbeiterStateActionsProps = {
  firmendatenId: string;
  mitarbeiter: Mitarbeiter;
  onActionSuccess: () => void;
};

const MitarbeiterStateActions: FC<MitarbeiterStateActionsProps> = ({ firmendatenId, mitarbeiter, onActionSuccess }) => {
  const entity = `Mitarbeiter`;

  const goBack = useGoBack(
    generatePath(URI_MANAGEMENT_PAGE_SYSTEM_FIRMENDATEN_DETAILS_PAGE, {
      firmendatenId,
      // @ts-ignore
      mitarbeiterId: mitarbeiter.mitarbeiterId,
    })
  );

  const [runDelete] = useDeleteMitarbeiterMutation({
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onActionSuccess();
      goBack();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runEntsperren] = useEntsperrenMitarbeiterMutation({
    onCompleted: () => {
      showSuccessMsgEntsperrt(entity);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runSperren] = useSperrenMitarbeiterMutation({
    onCompleted: () => {
      showSuccessMsgGesperrt(entity);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Button.Group>
      <Tooltip placement="top" title={isStatusGesperrt(mitarbeiter.status) ? unlockText : lockText}>
        <Button
          disabled={isStatusDeleted(mitarbeiter.status)}
          size="middle"
          icon={isStatusGesperrt(mitarbeiter.status) ? <UnlockOutlined /> : <LockOutlined />}
          onClick={() => showConfirmLock(firmendatenId, mitarbeiter, runSperren, runEntsperren)}
        >
          {isStatusGesperrt(mitarbeiter.status) ? 'Entsperren' : 'Sperren'}
        </Button>
      </Tooltip>
      <Tooltip placement="top" title={deleteText}>
        <Button
          disabled={isStatusDeleted(mitarbeiter.status)}
          size="middle"
          icon={<DeleteOutlined />}
          onClick={() => showConfirmDelete(firmendatenId, mitarbeiter, runDelete)}
        >
          Löschen
        </Button>
      </Tooltip>
    </Button.Group>
  );
};

const showConfirmLock = (
  firmendatenId: string,
  mitarbeiter: Mitarbeiter,
  sperreMitarbeiter: (
    options?: MutationFunctionOptions<SperrenMitarbeiterMutation, SperrenMitarbeiterMutationVariables>
  ) => Promise<FetchResult<SperrenMitarbeiterMutation>>,
  entsperreMitarbeiter: (
    options?: MutationFunctionOptions<EntsperrenMitarbeiterMutation, EntsperrenMitarbeiterMutationVariables>
  ) => Promise<FetchResult<EntsperrenMitarbeiterMutation>>
) => {
  Modal.confirm({
    title: isStatusGesperrt(mitarbeiter.status) ? `Möchten Sie den Mitarbeiter entsperren?` : `Möchten Sie den Mitarbeiter sperren?`,
    content: personToString(mitarbeiter),
    okText: isStatusGesperrt(mitarbeiter.status) ? 'Entsperren' : 'Sperren',
    cancelText: 'Nein',
    onOk() {
      if (isStatusGesperrt(mitarbeiter.status)) {
        return entsperreMitarbeiter({ variables: { firmendatenId, mitarbeiterId: mitarbeiter.mitarbeiterId } });
      } else {
        return sperreMitarbeiter({ variables: { firmendatenId, mitarbeiterId: mitarbeiter.mitarbeiterId } });
      }
    },
  });
};

const showConfirmDelete = (
  firmendatenId: string,
  mitarbeiter: Mitarbeiter,
  onDelete: (
    options?: MutationFunctionOptions<DeleteMitarbeiterMutation, DeleteMitarbeiterMutationVariables>
  ) => Promise<FetchResult<DeleteMitarbeiterMutation>>
) => {
  Modal.confirm({
    title: `Möchten Sie den Mitarbeiter löschen?`,
    content: personToString(mitarbeiter),
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return onDelete({ variables: { firmendatenId, mitarbeiterId: mitarbeiter.mitarbeiterId } });
    },
  });
};

export default MitarbeiterStateActions;
