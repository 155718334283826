import React, { FC } from 'react';
import VersionTimeline from '../../../components/Timeline/VersionTimeline';
import { showSuccessMsgDelete } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { KontierungstabelleStatus, UmsatzsteuerkennzeichenStatus, Vorschreibungsposition, VorschreibungspositionVersion } from '../../../types';
import { useVorschreibungspositionTemplateVersionListQuery } from './gql/vorschreibungspositionTemplateVersionQueries.types';
import { useDeleteVorschreibungspositionTemplateVersionMutation } from './gql/vorschreibungspositionTemplateVersionMutations.types';
import VorschreibungspositionVersionCardContent from '../../Vorschreibungsposition/Version/VorschreibungspositionVersionCardContent';
import VorschreibungspositionTemplateVersionForm from './Form/VorschreibungspositionTemplateVersionForm';

type Props = {
  vorschreibungsposition: Vorschreibungsposition;
  reloadVorschreibungsPositionList: () => void;
};

const VorschreibungspositionTemplateVersionTimeline: FC<Props> = ({ vorschreibungsposition, reloadVorschreibungsPositionList }) => {
  const { data, refetch: reloadVorschreibungspositionVersionList } = useVorschreibungspositionTemplateVersionListQuery({
    variables: {
      vorschreibungspositionId: vorschreibungsposition.vorschreibungspositionId,
      withDetails: true,
    },
  });
  const vorschreibungpositionVersionListData = data?.getVorschreibungspositionTemplateVersionList.data ?? [];
  const vorschreibungspositionVersionList = vorschreibungpositionVersionListData ?? [];
  const loading = !vorschreibungpositionVersionListData;

  const onActionSuccess = () => {
    reloadVorschreibungspositionVersionList();
    reloadVorschreibungsPositionList();
  };

  const [runDelete] = useDeleteVorschreibungspositionTemplateVersionMutation({
    onCompleted: () => {
      showSuccessMsgDelete(`Vorschreibungsposition`);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <VersionTimeline<VorschreibungspositionVersion>
      dataSource={vorschreibungspositionVersionList}
      versionIdKey="vorschreibungspositionVersionId"
      loading={loading}
      defaultNewTimeblock={{
        kontierungstabelle: {
          bezeichnung: '',
          kontierungstabelleId: '',
          firmendatenId: '',
          basiskonto: 0,
          createTs: '',
          createdBy: '',
          errechnetesErloesKontoList: [],
          status: {
            text: '',
            value: KontierungstabelleStatus.Aktiv,
          },
          warningList: [],
        },
        umsatzsteuerkennzeichen: {
          bezeichnung: '',
          kuerzel: '',
          umsatzsteuerkennzeichenId: '',
          status: {
            text: '',
            value: UmsatzsteuerkennzeichenStatus.Aktiv,
          },
          createTs: '',
          createdBy: '',
          warningList: [],
        },
        vorschreibungspositionVersionId: '',
        warningList: [],
      }}
      renderForm={(timeblock) => (
        <VorschreibungspositionTemplateVersionForm
          vorschreibungspositionId={vorschreibungsposition.vorschreibungspositionId}
          timeblock={timeblock}
          onSubmitSuccess={onActionSuccess}
        />
      )}
      isAllowedToDeleteInitialTimeblock
      onDelete={(timeblock) =>
        runDelete({
          variables: {
            vorschreibungspositionId: vorschreibungsposition.vorschreibungspositionId,
            vorschreibungspositionVersionId: timeblock.vorschreibungspositionVersionId,
          },
        })
      }
      kommentarForCardTitle={(timeblock) => timeblock.kommentar}
      renderCardContent={(timeblock) => <VorschreibungspositionVersionCardContent timeblock={timeblock} />}
    />
  );
};

export default VorschreibungspositionTemplateVersionTimeline;
