import { Select, SelectProps } from 'formik-antd';
import React, { FC } from 'react';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { useFilterAuftragEmpfaengerKundennummerListQuery } from '../../gql/AuftragQueries.types';

type Props = {
  name: string;
} & Omit<SelectProps, 'id' | 'allowClear' | 'showSearch' | 'filterOption' | 'loading'>;

const EmpfaengerKundennummerListFormSelect: FC<Props> = ({ name, ...restProps }) => {
  const { loading, data } = useFilterAuftragEmpfaengerKundennummerListQuery();
  const list = data?.getFilterAuftragEmpfaengerKundennummerList.data ?? [];

  return (
    <Select
      size="middle"
      placeholder=""
      {...restProps}
      name={name}
      id={name}
      loading={loading}
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {list.map((kundennummer) => (
        <Select.Option key={kundennummer} value={kundennummer}>
          <DataWithShortenedText maxTextLength={40} text={kundennummer} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default EmpfaengerKundennummerListFormSelect;
