import { FC } from 'react';
import { FormikProps } from 'formik';
import { objAbrVerarbeitungEntryTableFormFields, ObjAbrVerarbeitungEntryTableFormValues } from './ListingFiltersFormMapper';
import FiltersWithMax3Fields from '../../../../../components/Filters/FiltersWithMax3Fields';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { AppGenerierlaufType } from '../../../../../types';
import GenerierlaufRechnungsAusstellerFormSelect from '../../../shared/Filters/App/GenerierlaufRechnungsAusstellerFormSelect';
import GenerierlaufObjektFormSelect from '../../../shared/Filters/App/GenerierlaufObjektFormSelect';
import GenerierlaufEntryExitCodeListFormSelect from '../../../shared/Filters/App/GenerierlaufEntryExitCodeListFormSelect';

type Props = {
  formikProps: FormikProps<ObjAbrVerarbeitungEntryTableFormValues>;
  generierlaufId: string;
  generierlaufType: AppGenerierlaufType;
};

const ListingFilters: FC<Props> = ({ formikProps, generierlaufType, generierlaufId }) => {
  return (
    <FiltersWithMax3Fields
      hideTitle
      filters={
        <>
          <FormItemWithoutColon name={objAbrVerarbeitungEntryTableFormFields.rechnungsausstellerId} label="Rechnungsaussteller">
            <GenerierlaufRechnungsAusstellerFormSelect
              name={objAbrVerarbeitungEntryTableFormFields.rechnungsausstellerId}
              generierlaufId={generierlaufId}
              generierlaufType={generierlaufType}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={objAbrVerarbeitungEntryTableFormFields.objektId} label="Objekt">
            <GenerierlaufObjektFormSelect
              name={objAbrVerarbeitungEntryTableFormFields.objektId}
              generierlaufId={generierlaufId}
              generierlaufType={generierlaufType}
              rechnungsAusstellerId={formikProps.values.rechnungsausstellerId}
              onChange={formikProps.submitForm}
              style={{ width: '200px' }}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={objAbrVerarbeitungEntryTableFormFields.exitCodeList} label="Exit Code Status">
            <GenerierlaufEntryExitCodeListFormSelect
              name={objAbrVerarbeitungEntryTableFormFields.exitCodeList}
              generierlaufId={generierlaufId}
              generierlaufType={generierlaufType}
              style={{ width: '200px' }}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
    />
  );
};

export default ListingFilters;
