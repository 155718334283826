import { FC } from 'react';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';
import { usePauschalmieteForderungsKontoChangeHistoryListQuery } from './gql/PaulschalmieteQueries.types';

const PauschalmieteForderungsKontoChangeHistoryListingTable: FC = () => {
  const { data, loading } = usePauschalmieteForderungsKontoChangeHistoryListQuery();
  const historyList = data?.getPauschalmieteForderungsKontoChangeHistoryList.data;

  return (
    <HistoryListingTable
      historyType={HistoryType.Change}
      historyList={historyList}
      loading={loading}
      filterIdPrefix="paulschalmiete-forderungs-konto"
    />
  );
};

export default PauschalmieteForderungsKontoChangeHistoryListingTable;
