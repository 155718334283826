import React, { FC } from 'react';
import { ObjAbrKonto } from '../../../../../../../types';
import objektAbrechnungUstVomAufwandKontoTableColumns from './objektAbrechnungUstVomAufwandKontoTableColumns';
import ObjektAbrechnungBuchungszeileTable from '../../Level3/ObjektAbrechnungBuchungszeileTable';
import NestedTableWithColSelector from '../../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import objektAbrechnungUstVomAufwandBuchungszeileTableColumns from '../../Level3/objektAbrechnungUstVomAufwandBuchungszeileTableColumns';

type Props = {
  kontoList: ObjAbrKonto[];
  fromInclusive: string;
  toInclusive: string;
  objektId: string;
  buchungskreisId: string;
  parentRowKeys?: string[];
  visible?: boolean;
};

const ObjektAbrechnungUstVomAufwandKontoTable: FC<Props> = ({
  kontoList,
  fromInclusive,
  toInclusive,
  objektId,
  buchungskreisId,
  parentRowKeys,
  visible = true,
}) => (
  <NestedTableWithColSelector<ObjAbrKonto>
    level={2}
    subLevel={2}
    parentRowKeys={parentRowKeys}
    visible={visible}
    dataSource={kontoList}
    columns={objektAbrechnungUstVomAufwandKontoTableColumns(objektId, fromInclusive, toInclusive, buchungskreisId)}
    rowKey={getRowKey}
    expandable={{
      rowExpandable: (record) => !!record.buchungszeileList.length,
      expandedRowRender: (record, index, indent, expanded) => (
        <ObjektAbrechnungBuchungszeileTable
          subLevel={2}
          parentRowKeys={[...(parentRowKeys ?? []), getRowKey(record)]}
          visible={visible && expanded}
          columns={objektAbrechnungUstVomAufwandBuchungszeileTableColumns}
          buchungszeileList={record.buchungszeileList}
        />
      ),
    }}
  />
);

const getRowKey = (record: ObjAbrKonto) => record.kontoId;

export default ObjektAbrechnungUstVomAufwandKontoTable;
