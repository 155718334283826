import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useFilterFibuBzBestandseinheitListQuery } from '../gql/FibuBuchungszeileQueries.types';
import { selectFilterOption } from '../../../helpers/selectHelper';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';

type Props = {
  rechtstraegerIdList?: string[] | null;
} & Omit<SelectProps, 'id' | 'allowClear' | 'filterOption' | 'loading' | 'mode' | 'placeholder' | 'size' | 'showSearch'>;

const FibuBzBestandseinheitListFormSelect: FC<Props> = ({ rechtstraegerIdList, ...restProps }) => {
  const { loading, data } = useFilterFibuBzBestandseinheitListQuery({
    variables: {
      rechtstraegerIdList,
    },
  });
  const list = data?.getFilterFibuBzBestandseinheitList.data ?? [];

  return (
    <Select
      {...restProps}
      size="middle"
      id={restProps.name}
      loading={loading}
      placeholder=""
      allowClear
      showSearch
      mode="multiple"
      filterOption={selectFilterOption}
    >
      {list.map((bestandseinheit) => {
        const text = bestandseinheit.objektKurzBezeichnung
          ? `${bestandseinheit.bezeichnung} - ${bestandseinheit.objektKurzBezeichnung}`
          : bestandseinheit.bezeichnung;

        return (
          <Select.Option key={bestandseinheit.bestandseinheitId} value={bestandseinheit.bestandseinheitId}>
            <DataWithShortenedText maxTextLength={48} text={text} />
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default FibuBzBestandseinheitListFormSelect;
