import React, { FC } from 'react';
import OPDebitorTableHeader from './OPDebitorTableHeader';
import { OffenePostenDebitorListData } from '../../op-list-debitor-types';
import OPDebitorTable from './OPDebitorTable';
import OPDebitorListSummary from './OPDebitorListSummary';

type Props = {
  data: OffenePostenDebitorListData;
};

const OPDebitorTableBlock: FC<Props> = ({ data }) => (
  <>
    <OPDebitorTableHeader debitorenListHeaderText={data.debitorListHeader} />
    <OPDebitorTable debitorList={data.debitorList} />
    <OPDebitorListSummary debitorListSum={data.debitorListSum} />
  </>
);

export default OPDebitorTableBlock;
