import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { AppKonto } from '../../../../../types';
import { generatePathToKontoTemplateDetailsPage } from '../../../../Kontenrahmen/kontoTemplateUriPaths';

type Props = {
  isInCardContent: boolean;
  konto: AppKonto;
};

const VstKuerzungTemplateKonto: FC<Props> = ({ isInCardContent, konto }) => {
  const title = `${konto.nummer} - ${konto.bezeichnung}`;
  return isInCardContent ? (
    <DataWithShortenedText text={title} maxTextLength={30}>
      {(shortenedText) => <Link to={generatePathToKontoTemplateDetailsPage(konto.kontoId)}>{shortenedText}</Link>}
    </DataWithShortenedText>
  ) : (
    <Link to={generatePathToKontoTemplateDetailsPage(konto.kontoId)}>{title}</Link>
  );
};

export default VstKuerzungTemplateKonto;
