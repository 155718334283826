import { useParams } from 'react-router-dom';
import { useFDAuthorized } from '../../shared/Auth/useAuthorized';
import { useAuth } from '../../shared/Auth/authContext';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../shared/dataChangedSubscription';
import { DataChangedChangeType } from '../../types';

type RouteParams = { objektId: string };

export const useOnUstVomAufwandDataChangedEvent = (key: string, callback: () => Promise<unknown>, ustVomAufwandIds?: string[]) => {
  const { user } = useFDAuthorized();
  const { mitarbeiter } = useAuth();
  const { objektId } = useParams() as RouteParams;

  useOnDataChangedEvent([
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.ustVomAufwand,
        params: {
          objektId,
          ustVomAufwandId: ustVomAufwandIds,
        },
        changeType: DataChangedChangeType.Update,
      },
      notificationDescription: 'Ust. vom Aufwand wurde gerade verändert',
      key: `${key}-update`,
      callback,
    },
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.ustVomAufwand,
        params: {
          objektId,
        },
        changeType: DataChangedChangeType.Create,
      },
      notificationDescription: 'Ust. vom Aufwand wurde gerade erstellt',
      key: `${key}-create`,
      callback,
    },
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.ustVomAufwand,
        params: {
          objektId,
        },
        changeType: DataChangedChangeType.Delete,
      },
      notificationDescription: 'Ust. vom Aufwand wurde gerade gelöscht',
      key: `${key}-delete`,
      callback,
    },
  ]);
};
