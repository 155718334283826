import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useAuftragsartTypeListQuery } from '../gql/AuftragsartQueries.types';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';

type Props = SelectProps;

const AuftragsartTypeSelect: FC<Props> = ({ name, ...restProps }) => {
  const { data } = useAuftragsartTypeListQuery();
  const auftragsartTypeList = data?.getAuftragsartTypeList.data || [];

  return (
    <Select name={name} placeholder="Auftragstyp auswählen" size="middle" {...restProps}>
      {auftragsartTypeList.map((auftragsartType) => (
        <Select.Option key={auftragsartType.value} value={auftragsartType.value}>
          <DataWithShortenedText maxTextLength={35} text={auftragsartType.text} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default AuftragsartTypeSelect;
