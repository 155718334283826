import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { OrderPaymentFieldsFragmentDoc } from './PaymentFragment.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApprovePaymentBulkActionMutationVariables = Types.Exact<{
  input: Types.PaymentBulkActionInput;
}>;

export type ApprovePaymentBulkActionMutation = {
  approvePaymentBulkAction: {
    data?: number | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ChangePaymentDatePaymentBulkActionMutationVariables = Types.Exact<{
  input: Types.ChangePaymentDateBulkActionInput;
}>;

export type ChangePaymentDatePaymentBulkActionMutation = {
  changePaymentDatePaymentBulkAction: {
    data?: number | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MarkAsNotedPaymentBulkActionMutationVariables = Types.Exact<{
  input: Types.PaymentBulkActionInput;
}>;

export type MarkAsNotedPaymentBulkActionMutation = {
  markAsNotedPaymentBulkAction: {
    data?: number | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreatePaymentMutationVariables = Types.Exact<{
  input: Types.PaymentCreateInput;
}>;

export type CreatePaymentMutation = {
  createPayment: {
    data: { paymentId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdatePaymentMutationVariables = Types.Exact<{
  paymentId: Types.Scalars['ID']['input'];
  input: Types.PaymentUpdateInput;
}>;

export type UpdatePaymentMutation = {
  updatePayment: {
    data: {
      auftragId?: string | null;
      belegFileId?: string | null;
      belegId?: string | null;
      belegdatum?: string | null;
      belegnummer?: string | null;
      amount: number;
      buchungsdatum?: string | null;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deckungspruefungErrorText?: string | null;
      heVertragId?: string | null;
      noted: boolean;
      paymentDate: string;
      paymentId: string;
      paymentProposal?: string | null;
      paymentProposalId?: string | null;
      payeeAccountHolder: string;
      payeeIban: string;
      paymentReferenceText?: string | null;
      rechnungsAusstellerAccountHolder: string;
      rechnungsAusstellerIban: string;
      sepaCreditorId?: string | null;
      sepaMandatReference?: string | null;
      transactionInstructionId?: string | null;
      updatedByMitarbeiterId?: string | null;
      verwendungszweck?: string | null;
      approvalList: Array<{ mitarbeiterId: string; text: string }>;
      objekt: { kurzBezeichnung: string; objektId: string };
      paymentApprovalStatus?: { text: string; value: Types.PaymentApprovalStatus } | null;
      payee: { kurzBezeichnung: string; kundennummer: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
      paymentTransactionType?: { text: string; value: Types.PaymentTransactionType } | null;
      rechnungsAussteller: {
        kurzBezeichnung: string;
        kundennummer: string;
        rechtstraegerId: string;
        type: { text: string; value: Types.RechtstraegerType };
      };
      rechnungsAusstellerFibuKonto: { bezeichnung: string; kontoId: string; nummer: string };
      status: { text: string; value: Types.OrderPaymentStatus };
      type: { text: string; value: Types.PaymentType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeletePaymentMutationVariables = Types.Exact<{
  paymentId: Types.Scalars['ID']['input'];
}>;

export type DeletePaymentMutation = {
  deletePayment: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type ApprovePaymentMutationVariables = Types.Exact<{
  paymentId: Types.Scalars['ID']['input'];
}>;

export type ApprovePaymentMutation = {
  actionApprovePayment: {
    data: { paymentId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ChangeToDatentraegerErzeugtPaymentMutationVariables = Types.Exact<{
  paymentId: Types.Scalars['ID']['input'];
}>;

export type ChangeToDatentraegerErzeugtPaymentMutation = {
  actionChangeToDatentraegerErzeugtPayment: {
    data: { paymentId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ChangeToDurchfuehrbarPaymentMutationVariables = Types.Exact<{
  paymentId: Types.Scalars['ID']['input'];
}>;

export type ChangeToDurchfuehrbarPaymentMutation = {
  actionChangeToDurchfuehrbarPayment: {
    data: { paymentId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ChangeToNichtDurchfuehrbarPaymentMutationVariables = Types.Exact<{
  paymentId: Types.Scalars['ID']['input'];
}>;

export type ChangeToNichtDurchfuehrbarPaymentMutation = {
  actionChangeToNichtDurchfuehrbarPayment: {
    data: { paymentId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ChangeToNichtDurchgefuehrtPaymentMutationVariables = Types.Exact<{
  paymentId: Types.Scalars['ID']['input'];
  input: Types.PaymentActionInput;
}>;

export type ChangeToNichtDurchgefuehrtPaymentMutation = {
  actionChangeToNichtDurchgefuehrtPayment: {
    data: { paymentId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const ApprovePaymentBulkActionDocument = gql`
  mutation ApprovePaymentBulkAction($input: PaymentBulkActionInput!) {
    approvePaymentBulkAction(input: $input) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useApprovePaymentBulkActionMutation(
  baseOptions?: Apollo.MutationHookOptions<ApprovePaymentBulkActionMutation, ApprovePaymentBulkActionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApprovePaymentBulkActionMutation, ApprovePaymentBulkActionMutationVariables>(ApprovePaymentBulkActionDocument, options);
}
export type ApprovePaymentBulkActionMutationHookResult = ReturnType<typeof useApprovePaymentBulkActionMutation>;
export type ApprovePaymentBulkActionMutationResult = Apollo.MutationResult<ApprovePaymentBulkActionMutation>;
export type ApprovePaymentBulkActionMutationOptions = Apollo.BaseMutationOptions<
  ApprovePaymentBulkActionMutation,
  ApprovePaymentBulkActionMutationVariables
>;
export const ChangePaymentDatePaymentBulkActionDocument = gql`
  mutation ChangePaymentDatePaymentBulkAction($input: ChangePaymentDateBulkActionInput!) {
    changePaymentDatePaymentBulkAction(input: $input) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useChangePaymentDatePaymentBulkActionMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangePaymentDatePaymentBulkActionMutation, ChangePaymentDatePaymentBulkActionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangePaymentDatePaymentBulkActionMutation, ChangePaymentDatePaymentBulkActionMutationVariables>(
    ChangePaymentDatePaymentBulkActionDocument,
    options
  );
}
export type ChangePaymentDatePaymentBulkActionMutationHookResult = ReturnType<typeof useChangePaymentDatePaymentBulkActionMutation>;
export type ChangePaymentDatePaymentBulkActionMutationResult = Apollo.MutationResult<ChangePaymentDatePaymentBulkActionMutation>;
export type ChangePaymentDatePaymentBulkActionMutationOptions = Apollo.BaseMutationOptions<
  ChangePaymentDatePaymentBulkActionMutation,
  ChangePaymentDatePaymentBulkActionMutationVariables
>;
export const MarkAsNotedPaymentBulkActionDocument = gql`
  mutation MarkAsNotedPaymentBulkAction($input: PaymentBulkActionInput!) {
    markAsNotedPaymentBulkAction(input: $input) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useMarkAsNotedPaymentBulkActionMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkAsNotedPaymentBulkActionMutation, MarkAsNotedPaymentBulkActionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkAsNotedPaymentBulkActionMutation, MarkAsNotedPaymentBulkActionMutationVariables>(
    MarkAsNotedPaymentBulkActionDocument,
    options
  );
}
export type MarkAsNotedPaymentBulkActionMutationHookResult = ReturnType<typeof useMarkAsNotedPaymentBulkActionMutation>;
export type MarkAsNotedPaymentBulkActionMutationResult = Apollo.MutationResult<MarkAsNotedPaymentBulkActionMutation>;
export type MarkAsNotedPaymentBulkActionMutationOptions = Apollo.BaseMutationOptions<
  MarkAsNotedPaymentBulkActionMutation,
  MarkAsNotedPaymentBulkActionMutationVariables
>;
export const CreatePaymentDocument = gql`
  mutation CreatePayment($input: PaymentCreateInput!) {
    createPayment(input: $input) {
      data {
        paymentId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreatePaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMutation, CreatePaymentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(CreatePaymentDocument, options);
}
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = Apollo.MutationResult<CreatePaymentMutation>;
export type CreatePaymentMutationOptions = Apollo.BaseMutationOptions<CreatePaymentMutation, CreatePaymentMutationVariables>;
export const UpdatePaymentDocument = gql`
  mutation UpdatePayment($paymentId: ID!, $input: PaymentUpdateInput!) {
    updatePayment(paymentId: $paymentId, input: $input) {
      data {
        ...OrderPaymentFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${OrderPaymentFieldsFragmentDoc}
`;
export function useUpdatePaymentMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentMutation, UpdatePaymentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePaymentMutation, UpdatePaymentMutationVariables>(UpdatePaymentDocument, options);
}
export type UpdatePaymentMutationHookResult = ReturnType<typeof useUpdatePaymentMutation>;
export type UpdatePaymentMutationResult = Apollo.MutationResult<UpdatePaymentMutation>;
export type UpdatePaymentMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentMutation, UpdatePaymentMutationVariables>;
export const DeletePaymentDocument = gql`
  mutation DeletePayment($paymentId: ID!) {
    deletePayment(paymentId: $paymentId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeletePaymentMutation(baseOptions?: Apollo.MutationHookOptions<DeletePaymentMutation, DeletePaymentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePaymentMutation, DeletePaymentMutationVariables>(DeletePaymentDocument, options);
}
export type DeletePaymentMutationHookResult = ReturnType<typeof useDeletePaymentMutation>;
export type DeletePaymentMutationResult = Apollo.MutationResult<DeletePaymentMutation>;
export type DeletePaymentMutationOptions = Apollo.BaseMutationOptions<DeletePaymentMutation, DeletePaymentMutationVariables>;
export const ApprovePaymentDocument = gql`
  mutation ApprovePayment($paymentId: ID!) {
    actionApprovePayment(paymentId: $paymentId) {
      data {
        paymentId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useApprovePaymentMutation(baseOptions?: Apollo.MutationHookOptions<ApprovePaymentMutation, ApprovePaymentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApprovePaymentMutation, ApprovePaymentMutationVariables>(ApprovePaymentDocument, options);
}
export type ApprovePaymentMutationHookResult = ReturnType<typeof useApprovePaymentMutation>;
export type ApprovePaymentMutationResult = Apollo.MutationResult<ApprovePaymentMutation>;
export type ApprovePaymentMutationOptions = Apollo.BaseMutationOptions<ApprovePaymentMutation, ApprovePaymentMutationVariables>;
export const ChangeToDatentraegerErzeugtPaymentDocument = gql`
  mutation ChangeToDatentraegerErzeugtPayment($paymentId: ID!) {
    actionChangeToDatentraegerErzeugtPayment(paymentId: $paymentId) {
      data {
        paymentId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useChangeToDatentraegerErzeugtPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeToDatentraegerErzeugtPaymentMutation, ChangeToDatentraegerErzeugtPaymentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeToDatentraegerErzeugtPaymentMutation, ChangeToDatentraegerErzeugtPaymentMutationVariables>(
    ChangeToDatentraegerErzeugtPaymentDocument,
    options
  );
}
export type ChangeToDatentraegerErzeugtPaymentMutationHookResult = ReturnType<typeof useChangeToDatentraegerErzeugtPaymentMutation>;
export type ChangeToDatentraegerErzeugtPaymentMutationResult = Apollo.MutationResult<ChangeToDatentraegerErzeugtPaymentMutation>;
export type ChangeToDatentraegerErzeugtPaymentMutationOptions = Apollo.BaseMutationOptions<
  ChangeToDatentraegerErzeugtPaymentMutation,
  ChangeToDatentraegerErzeugtPaymentMutationVariables
>;
export const ChangeToDurchfuehrbarPaymentDocument = gql`
  mutation ChangeToDurchfuehrbarPayment($paymentId: ID!) {
    actionChangeToDurchfuehrbarPayment(paymentId: $paymentId) {
      data {
        paymentId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useChangeToDurchfuehrbarPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeToDurchfuehrbarPaymentMutation, ChangeToDurchfuehrbarPaymentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeToDurchfuehrbarPaymentMutation, ChangeToDurchfuehrbarPaymentMutationVariables>(
    ChangeToDurchfuehrbarPaymentDocument,
    options
  );
}
export type ChangeToDurchfuehrbarPaymentMutationHookResult = ReturnType<typeof useChangeToDurchfuehrbarPaymentMutation>;
export type ChangeToDurchfuehrbarPaymentMutationResult = Apollo.MutationResult<ChangeToDurchfuehrbarPaymentMutation>;
export type ChangeToDurchfuehrbarPaymentMutationOptions = Apollo.BaseMutationOptions<
  ChangeToDurchfuehrbarPaymentMutation,
  ChangeToDurchfuehrbarPaymentMutationVariables
>;
export const ChangeToNichtDurchfuehrbarPaymentDocument = gql`
  mutation ChangeToNichtDurchfuehrbarPayment($paymentId: ID!) {
    actionChangeToNichtDurchfuehrbarPayment(paymentId: $paymentId) {
      data {
        paymentId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useChangeToNichtDurchfuehrbarPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeToNichtDurchfuehrbarPaymentMutation, ChangeToNichtDurchfuehrbarPaymentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeToNichtDurchfuehrbarPaymentMutation, ChangeToNichtDurchfuehrbarPaymentMutationVariables>(
    ChangeToNichtDurchfuehrbarPaymentDocument,
    options
  );
}
export type ChangeToNichtDurchfuehrbarPaymentMutationHookResult = ReturnType<typeof useChangeToNichtDurchfuehrbarPaymentMutation>;
export type ChangeToNichtDurchfuehrbarPaymentMutationResult = Apollo.MutationResult<ChangeToNichtDurchfuehrbarPaymentMutation>;
export type ChangeToNichtDurchfuehrbarPaymentMutationOptions = Apollo.BaseMutationOptions<
  ChangeToNichtDurchfuehrbarPaymentMutation,
  ChangeToNichtDurchfuehrbarPaymentMutationVariables
>;
export const ChangeToNichtDurchgefuehrtPaymentDocument = gql`
  mutation ChangeToNichtDurchgefuehrtPayment($paymentId: ID!, $input: PaymentActionInput!) {
    actionChangeToNichtDurchgefuehrtPayment(paymentId: $paymentId, input: $input) {
      data {
        paymentId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useChangeToNichtDurchgefuehrtPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeToNichtDurchgefuehrtPaymentMutation, ChangeToNichtDurchgefuehrtPaymentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeToNichtDurchgefuehrtPaymentMutation, ChangeToNichtDurchgefuehrtPaymentMutationVariables>(
    ChangeToNichtDurchgefuehrtPaymentDocument,
    options
  );
}
export type ChangeToNichtDurchgefuehrtPaymentMutationHookResult = ReturnType<typeof useChangeToNichtDurchgefuehrtPaymentMutation>;
export type ChangeToNichtDurchgefuehrtPaymentMutationResult = Apollo.MutationResult<ChangeToNichtDurchgefuehrtPaymentMutation>;
export type ChangeToNichtDurchgefuehrtPaymentMutationOptions = Apollo.BaseMutationOptions<
  ChangeToNichtDurchgefuehrtPaymentMutation,
  ChangeToNichtDurchgefuehrtPaymentMutationVariables
>;
