import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import { URI_VERARBEITUNG_OBJEKT_FINANCIAL_PLAN } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import ObjektFinancialPlanDetailsPage from './ObjektFinancialPlanDetailsPage';

export type ObjektFinancialPlanPageRouteParams = { generierlaufId: string };

export const verarbeitungObjektFinancialPlanDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_OBJEKT_FINANCIAL_PLAN}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<ObjektFinancialPlanDetailsPage />} />}
  />
);
