export const TABLE_ALIGNED_COLS_CLASSNAMES = {
  table: 'ant-table ant-table-small ant-table-scroll-horizontal',
  thead: 'ant-table-thead',
  tbody: 'ant-table-tbody',
  iconCell: 'ant-table-cell ant-table-row-expand-icon-cell',
  tableInTable: 'table-in-table',
  cell: 'ant-table-cell',
  level0: 'level-0',
  level1: 'level-1',
  level2: 'level-2',
  level3: 'level-3',
  emptyTable: 'empty-table',
};
