import * as Yup from 'yup';
import { entityIsRequired, entityMaxCharLength, entityMinCharLength } from '../../../components/message/validationMsg';

export const currentPasswordSchema = Yup.string().required(entityIsRequired('Passwort'));

/* eslint-disable no-template-curly-in-string */
export const passwordNewSchema = Yup.string()
  .required(entityIsRequired('Das neue Passwort'))
  .min(8, entityMinCharLength('Password', 8))
  .max(2147483647, entityMaxCharLength('Password', 2147483647));

export const passwordConfirmSchema = (passwordInputValue: string) =>
  Yup.string().required(entityIsRequired('Das neue Passwort')).oneOf([passwordInputValue], 'Die Passwörter müssen gleich sein.');

/* eslint-enable no-template-curly-in-string */
