import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ZinslisteApplyAllDeviationsMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  zinslisteId: Types.Scalars['ID']['input'];
}>;

export type ZinslisteApplyAllDeviationsMutation = {
  actionZinslisteApplyAllDeviations: {
    data: { kopfdaten: { zinslisteId: string } };
    warningList?: Array<{ message: string; type: string }> | null;
    errorList?: Array<{ message: string; type: string }> | null;
  };
};

export const ZinslisteApplyAllDeviationsDocument = gql`
  mutation ZinslisteApplyAllDeviations($objektId: String!, $zinslisteId: ID!) {
    actionZinslisteApplyAllDeviations(objektId: $objektId, zinslisteId: $zinslisteId) {
      data {
        kopfdaten {
          zinslisteId
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useZinslisteApplyAllDeviationsMutation(
  baseOptions?: Apollo.MutationHookOptions<ZinslisteApplyAllDeviationsMutation, ZinslisteApplyAllDeviationsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ZinslisteApplyAllDeviationsMutation, ZinslisteApplyAllDeviationsMutationVariables>(
    ZinslisteApplyAllDeviationsDocument,
    options
  );
}
export type ZinslisteApplyAllDeviationsMutationHookResult = ReturnType<typeof useZinslisteApplyAllDeviationsMutation>;
export type ZinslisteApplyAllDeviationsMutationResult = Apollo.MutationResult<ZinslisteApplyAllDeviationsMutation>;
export type ZinslisteApplyAllDeviationsMutationOptions = Apollo.BaseMutationOptions<
  ZinslisteApplyAllDeviationsMutation,
  ZinslisteApplyAllDeviationsMutationVariables
>;
