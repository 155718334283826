import { isEmpty } from 'lodash';

import validationApi from '../../../api/ValidationApi';

import { validateSyncWithSchemaAndReturnErrorIfAny } from '../../../../helpers/validationHelper';

import { vatIdNumberSchema } from './vatIdNumberSchema';

export const validateVatIdNumber = (vatIdentificationNumber: string) =>
  validateVatIdNumberAgainstSchema(vatIdentificationNumber).then(validateVatIdNumberAgainstService(vatIdentificationNumber));

export const validateVatIdNumberAgainstSchema = (vatIdentificationNumber: string) => {
  const error = validateSyncWithSchemaAndReturnErrorIfAny(vatIdNumberSchema, vatIdentificationNumber);
  return !error ? Promise.resolve() : Promise.reject(createVatIdNumberValidationError(error));
};

export const validateVatIdNumberAgainstService = (vatIdentificationNumber: string) => () => {
  if (!shouldValidateVatIdNumber(vatIdentificationNumber)) {
    return Promise.resolve();
  }
  return validationApi
    .validateVatIdNumber(vatIdentificationNumber)
    .then((dataAndWarnings) => dataAndWarnings)
    .catch(throwFirstValidationErrorOrReturnServiceUnavailableWarning);
};

const shouldValidateVatIdNumber = (vatIdentificationNumber: string) => !isEmpty(vatIdentificationNumber);

const throwFirstValidationErrorOrReturnServiceUnavailableWarning = (error: any) => {
  if (error.validationError) {
    throw createVatIdNumberValidationError(error.errorList[0].message);
  }
  if (error.serviceUnavailable) {
    return { warningList: [{ message: 'Uid Prüfservice ist derzeit nicht erreichtbar, sie wird zu einem späteren Zeitpunkt überprüft.' }] };
  }
  console.error('validateVatIdNumber - unhandled error', error);
  return {
    warningList: [
      { message: 'Während der Prüfung der UID-Nummer ist ein Fehler aufgetreten deshalb wird sie zu einem späteren Zeitpunkt überprüft.' },
    ],
  };
};

export const VAT_IDENTIFICATION_NUMBER_VALIDATION_ERROR = 'VAT_IDENTIFICATION_NUMBER_VALIDATION_ERROR';

export const isVatIdNumberValidationError = (error: any) => error && error.type === VAT_IDENTIFICATION_NUMBER_VALIDATION_ERROR;

const createVatIdNumberValidationError = (errorMessage: string) => ({ type: VAT_IDENTIFICATION_NUMBER_VALIDATION_ERROR, message: errorMessage });
