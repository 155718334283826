import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { VertragVposIndexedValueGenerierlauf } from '../../../../types';
import EntryTable from './EntryTable';
import { TVertagVposIndexedValueVerarbeitungQueryParams, updateQueryParams } from '../../shared/VertragVposIndexedValue/filtersQueryParams';
import {
  mapFormValuesToVertragVposIndexedValueVerarbeitungFilters,
  VertragVposIndexedValueVerarbeitungEntryFiltersFormValues,
  vertragVposIndexedValueVerarbEntryListingFiltersFormInitialValues,
} from '../../shared/VertragVposIndexedValue/ListingFiltersFormMapper';
import ListingFilters from './Filters/ListingFilters';

type Props = {
  generierlauf: VertragVposIndexedValueGenerierlauf;
  verarbeitungType: string;
};

const EntryListing: FC<Props> = ({ generierlauf, verarbeitungType }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TVertagVposIndexedValueVerarbeitungQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(verarbeitungType, generierlauf.generierlaufId, navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: VertragVposIndexedValueVerarbeitungEntryFiltersFormValues) => {
    updateQueryParams(
      verarbeitungType,
      generierlauf.generierlaufId,
      navigate,
      mapFormValuesToVertragVposIndexedValueVerarbeitungFilters(values),
      paginationParams
    );
  };

  return (
    <Formik<VertragVposIndexedValueVerarbeitungEntryFiltersFormValues>
      initialValues={vertragVposIndexedValueVerarbEntryListingFiltersFormInitialValues(queryParams)}
      onSubmit={onChange}
    >
      {(formikProps) => (
        <>
          <ListingFilters formikProps={formikProps} generierlaufId={generierlauf.generierlaufId} />
          <EntryTable queryParams={queryParams} generierlaufId={generierlauf.generierlaufId} />
        </>
      )}
    </Formik>
  );
};

export default EntryListing;
