import React, { FC } from 'react';
import { Typography } from 'antd';
import { Spacer } from '../../../../../components/Grid';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import FormButtons from '../../../../../components/Button/FormButtons';
import AuftragsartTemplate from '../../shared/Template/AuftragsartTemplate';
import { useFDAuthorized } from '../../../../../shared/Auth/useAuthorized';
import { useBelegTexteFirmendatenQuery } from '../../../../Belegtext/gql/BelegtexteQueries.types';
import LoadingPage from '../../../../../components/Loading/LoadingPage';
import { useFirmendatenAddressesAndContactsQuery } from '../../../../Rechtstraeger/AddressesAndContacts/gql/AddressesAndContactsQueries.types';
import { useFirmendatenQuery } from '../../../../KundenSystem/Firmendaten/gql/FirmendatenQueries.types';
import { getAuftragsartBelegKopfFussPlatzhalter } from '../../shared/auftragsartBelegKopfFussHelpers';

type Props = {
  auftragsartBezeichnung: string;
  auftragsartTemplateValues: TextbausteinFormValues[];
  setAuftragsartTemplateValues: React.Dispatch<React.SetStateAction<TextbausteinFormValues[] | undefined>>;
  showUstBlock: boolean;
  onBack: () => void;
  onSubmit: () => void;
};

const AuftragsartCreateTemplateForm: FC<Props> = ({
  auftragsartBezeichnung,
  auftragsartTemplateValues,
  setAuftragsartTemplateValues,
  showUstBlock,
  onBack,
  onSubmit,
}) => {
  const { activeForFirmendatenId } = useFDAuthorized();

  const { data: firmendatendData, loading: fdLoading } = useFirmendatenQuery({ variables: { firmendatenId: activeForFirmendatenId } });
  const firmendaten = firmendatendData?.getFirmendaten.data;

  const { data: belegTexteData, loading: belegTexteLoading } = useBelegTexteFirmendatenQuery({
    variables: { firmendatenId: activeForFirmendatenId },
  });
  const belegTexte = belegTexteData?.getBelegTexteFirmendaten.data;

  const { data: fdAddressesAndContactsData, loading: fdAddressesAndContactsLoading } = useFirmendatenAddressesAndContactsQuery();
  const addressesAndContacts = fdAddressesAndContactsData?.getFirmendatenAddressesAndContacts.data;

  const auftragsartBelegKopfFussPlatzhalter = getAuftragsartBelegKopfFussPlatzhalter(firmendaten, addressesAndContacts);

  return (
    <LoadingPage loading={fdLoading || belegTexteLoading || fdAddressesAndContactsLoading}>
      <Spacer height={16} />
      <Typography.Title level={5} style={{ textAlign: 'center' }}>
        {auftragsartBezeichnung}
      </Typography.Title>
      <Spacer height={16} />
      <AuftragsartTemplate
        templateFormValues={auftragsartTemplateValues}
        onTemplateChange={setAuftragsartTemplateValues}
        showUstBlock={showUstBlock}
        firmendatenId={activeForFirmendatenId}
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        belegTexte={belegTexte!}
        belegTextePlatzhalter={auftragsartBelegKopfFussPlatzhalter}
      />
      <FormButtons onCancel={onBack} onOk={onSubmit} updateMode={false} cancelText="Zurück" />
    </LoadingPage>
  );
};

export default AuftragsartCreateTemplateForm;
