import * as Yup from 'yup';
import { paymentProposalFormFields } from './executeFormMapper';
import { entityIsRequired } from '../../../../../components/message/validationMsg';
/* eslint-disable no-template-curly-in-string */

export const executeFormValidationSchema = Yup.object().shape({
  [paymentProposalFormFields.paymentProposalId]: Yup.string().required(entityIsRequired('Zahlungsvorschlag')),
});

/* eslint-enable no-template-curly-in-string */
