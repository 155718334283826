import React from 'react';
import { Tag } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { friendlyFormatIBAN } from 'ibantools';
import { Link } from 'react-router-dom';
import RechnungsAusstellerKontoTableActions from './RechnungsAusstellerKontoTableActions';
import { generatePathToKontoDetailsPage } from '../../../../../Kontenplan/kontoUriPaths';
import { BankDetails, Firma, NatuerlichePerson, Personengemeinschaft } from '../../../../../../types';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';
import MultipleTexts from '../../../../../../components/Text/MultipleTexts';
import { EuroAmount } from '../../../../../../components/Number';
import { isBankDetailsFibuStatusWirdAngelegt } from '../../../../../BankDetails/bankDetailsHelpers';
import TruncatedTextWithTooltip from '../../../../../../components/Helpers/TruncatedTextWithTooltip';
import { NestedTableColProps } from '../../../../../../components/Table/NestedTable/NestedTable';

const rechnungsAusstellerKontoTableColumns = (
  rechtstraeger: Firma | NatuerlichePerson | Personengemeinschaft,
  onAction: () => void
): NestedTableColProps<BankDetails>[] => [
  {
    title: 'Kontoinhaber',
    render: (text, record) => <TruncatedTextWithTooltip text={record.accountHolder} />,
  },
  {
    title: 'Bankname',
    render: (text, record) => <TruncatedTextWithTooltip text={record.bankname} />,
  },
  {
    title: 'IBAN',
    render: (text, record) => <TruncatedTextWithTooltip text={friendlyFormatIBAN(record.iban) as string} />,
  },
  {
    title: 'FIBU-Kontonummer',
    render: (text, record) =>
      record.fibuKontoId ? <Link to={generatePathToKontoDetailsPage(record.fibuKontoId)}>{record.fibuKontonummer}</Link> : <Tag>Nicht erstellt</Tag>,
  },
  {
    title: 'FIBU-Status',
    render: (text, record) =>
      isBankDetailsFibuStatusWirdAngelegt(record.fibuStatus.value) ? (
        <Tag icon={<SyncOutlined spin />} color="processing">
          {record.fibuStatus.text}
        </Tag>
      ) : (
        <Tag>{record.fibuStatus.text}</Tag>
      ),
  },
  {
    title: 'EBICS Lastschrift aktiv für',
    render: (text, record) =>
      record.ebicsDirectDebitUserList.length > 0 ? (
        <MultipleTexts texts={record.ebicsDirectDebitUserList} maxFirstTextLength={30} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'EBICS Überweisung aktiv für',
    render: (text, record) =>
      record.ebicsCreditTransferUserList.length > 0 ? (
        <MultipleTexts texts={record.ebicsCreditTransferUserList} maxFirstTextLength={30} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Überziehungsrahmen',
    render: (text, record) => {
      return record.accountLimit ? <EuroAmount value={record.accountLimit} /> : <TextForEmptyValue textToShow="minus" />;
    },
  },
  {
    title: 'Verwendung',
    render: (text, record) => <Tag>{record.fibuVerwendung.text}</Tag>,
  },
  {
    title: 'Aktion',
    width: 60,
    render: (text, record) => <RechnungsAusstellerKontoTableActions bankDetails={record} rechtstraeger={rechtstraeger} onAction={onAction} />,
  },
];

export default rechnungsAusstellerKontoTableColumns;
