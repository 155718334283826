import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VorschreibungApplyAllDeviationsMutationVariables = Types.Exact<{
  auftragId: Types.Scalars['ID']['input'];
}>;

export type VorschreibungApplyAllDeviationsMutation = {
  actionAuftragApplyAllDeviations: {
    data: { auftragId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const VorschreibungApplyAllDeviationsDocument = gql`
  mutation VorschreibungApplyAllDeviations($auftragId: ID!) {
    actionAuftragApplyAllDeviations(auftragId: $auftragId) {
      data {
        auftragId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useVorschreibungApplyAllDeviationsMutation(
  baseOptions?: Apollo.MutationHookOptions<VorschreibungApplyAllDeviationsMutation, VorschreibungApplyAllDeviationsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VorschreibungApplyAllDeviationsMutation, VorschreibungApplyAllDeviationsMutationVariables>(
    VorschreibungApplyAllDeviationsDocument,
    options
  );
}
export type VorschreibungApplyAllDeviationsMutationHookResult = ReturnType<typeof useVorschreibungApplyAllDeviationsMutation>;
export type VorschreibungApplyAllDeviationsMutationResult = Apollo.MutationResult<VorschreibungApplyAllDeviationsMutation>;
export type VorschreibungApplyAllDeviationsMutationOptions = Apollo.BaseMutationOptions<
  VorschreibungApplyAllDeviationsMutation,
  VorschreibungApplyAllDeviationsMutationVariables
>;
