import { FormFields } from '../../../../../../../helpers/formikHelper';
import {
  AbrechnungsdefinitionType,
  DueDateDisplayOption,
  DueDateDisplayOptionsInput,
  TopAbrechnungOutputOptions,
  TopAbrechnungOutputOptionsInput,
} from '../../../../../../../types';
import { isNotNil, isTruthy } from '../../../../../../../helpers/assertionHelper';
import { isAbrDefTypeWeAbrechnung } from '../../../../AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import { isDueDateOptionTypeVerrechnungsperiode } from '../dueDateHelpers';

export type TopAbrDefGeneralSettingsFormValues = {
  considerRueckstand: boolean;
  displayDueDate: boolean;
  displayRueckstandOtherPersons: boolean;
  displayZahlungsverlauf: boolean;
  dueDateDisplayOptionEigentuemerGuthaben?: DueDateDisplayOptionsInput | null;
  dueDateDisplayOptionEigentuemerNachzahlung?: DueDateDisplayOptionsInput | null;
  dueDateDisplayOptionMieter?: DueDateDisplayOptionsInput | null;
  rueckstandOtherPersonsThreshold?: number | null;
};

export const topAbrDefGeneralSettingsFormFields: FormFields<TopAbrDefGeneralSettingsFormValues> = {
  considerRueckstand: 'considerRueckstand',
  displayDueDate: 'displayDueDate',
  displayRueckstandOtherPersons: 'displayRueckstandOtherPersons',
  displayZahlungsverlauf: 'displayZahlungsverlauf',
  dueDateDisplayOptionEigentuemerGuthaben: 'dueDateDisplayOptionEigentuemerGuthaben',
  dueDateDisplayOptionEigentuemerNachzahlung: 'dueDateDisplayOptionEigentuemerNachzahlung',
  dueDateDisplayOptionMieter: 'dueDateDisplayOptionMieter',
  rueckstandOtherPersonsThreshold: 'rueckstandOtherPersonsThreshold',
};

export const mapTopAbrDefGeneralSettingsToInitialValues = (
  abrDefType: AbrechnungsdefinitionType,
  preceedingTopAbrechnungOutputOptions?: TopAbrechnungOutputOptions
): TopAbrDefGeneralSettingsFormValues =>
  preceedingTopAbrechnungOutputOptions
    ? mapTopAbrDefOutputOptionsToFormValues(preceedingTopAbrechnungOutputOptions)
    : topAbrDefGeneralSettingsFormInitialValues(abrDefType);

const topAbrDefGeneralSettingsFormInitialValues = (abrDefType: AbrechnungsdefinitionType): TopAbrDefGeneralSettingsFormValues => ({
  considerRueckstand: false,
  displayZahlungsverlauf: false,
  displayRueckstandOtherPersons: isAbrDefTypeWeAbrechnung(abrDefType),
  rueckstandOtherPersonsThreshold: isAbrDefTypeWeAbrechnung(abrDefType) ? 10 : null,
  displayDueDate: false,
  dueDateDisplayOptionEigentuemerGuthaben: null,
  dueDateDisplayOptionEigentuemerNachzahlung: null,
  dueDateDisplayOptionMieter: null,
});

const mapTopAbrDefOutputOptionsToFormValues = (topAbrechnungOutputOptions: TopAbrechnungOutputOptions): TopAbrDefGeneralSettingsFormValues => ({
  considerRueckstand: topAbrechnungOutputOptions.considerRueckstand,
  displayDueDate: topAbrechnungOutputOptions.displayDueDate,
  displayRueckstandOtherPersons: topAbrechnungOutputOptions.displayRueckstandOtherPersons,
  displayZahlungsverlauf: topAbrechnungOutputOptions.displayZahlungsverlauf,
  dueDateDisplayOptionEigentuemerGuthaben:
    topAbrechnungOutputOptions.displayDueDate && isNotNil(topAbrechnungOutputOptions.dueDateDisplayOptionEigentuemerGuthaben)
      ? mapDueDateDiplayOptionToFormValue(topAbrechnungOutputOptions.dueDateDisplayOptionEigentuemerGuthaben)
      : null,
  dueDateDisplayOptionEigentuemerNachzahlung:
    topAbrechnungOutputOptions.displayDueDate && isNotNil(topAbrechnungOutputOptions.dueDateDisplayOptionEigentuemerNachzahlung)
      ? mapDueDateDiplayOptionToFormValue(topAbrechnungOutputOptions.dueDateDisplayOptionEigentuemerNachzahlung)
      : null,
  dueDateDisplayOptionMieter:
    topAbrechnungOutputOptions.displayDueDate && isNotNil(topAbrechnungOutputOptions.dueDateDisplayOptionMieter)
      ? mapDueDateDiplayOptionToFormValue(topAbrechnungOutputOptions.dueDateDisplayOptionMieter)
      : null,
  rueckstandOtherPersonsThreshold: topAbrechnungOutputOptions.displayRueckstandOtherPersons
    ? topAbrechnungOutputOptions.rueckstandOtherPersonsThreshold
    : 0,
});

const mapDueDateDiplayOptionToFormValue = (option: DueDateDisplayOption): DueDateDisplayOptionsInput => ({
  type: option.type.value,
  month: option.month ? option.month - 1 : null,
});

export const mapTopAbrDefGenSetFormValuesToOutputOptions = (
  generalSettingsValues: TopAbrDefGeneralSettingsFormValues
): TopAbrechnungOutputOptionsInput => {
  return {
    considerRueckstand: generalSettingsValues.considerRueckstand,
    displayDueDate: generalSettingsValues.displayDueDate,
    displayRueckstandOtherPersons: generalSettingsValues.displayRueckstandOtherPersons,
    displayZahlungsverlauf: generalSettingsValues.displayZahlungsverlauf,
    dueDateDisplayOptionEigentuemerGuthaben:
      generalSettingsValues.displayDueDate && generalSettingsValues.dueDateDisplayOptionEigentuemerGuthaben
        ? mapFormValueToDueDateDiplayOption(generalSettingsValues.dueDateDisplayOptionEigentuemerGuthaben)
        : null,
    dueDateDisplayOptionEigentuemerNachzahlung:
      generalSettingsValues.displayDueDate && generalSettingsValues.dueDateDisplayOptionEigentuemerNachzahlung
        ? mapFormValueToDueDateDiplayOption(generalSettingsValues.dueDateDisplayOptionEigentuemerNachzahlung)
        : null,
    dueDateDisplayOptionMieter:
      generalSettingsValues.displayDueDate && generalSettingsValues.dueDateDisplayOptionMieter
        ? mapFormValueToDueDateDiplayOption(generalSettingsValues.dueDateDisplayOptionMieter)
        : null,
    rueckstandOtherPersonsThreshold: generalSettingsValues.displayRueckstandOtherPersons
      ? generalSettingsValues.rueckstandOtherPersonsThreshold
      : null,
  };
};

const mapFormValueToDueDateDiplayOption = (option: DueDateDisplayOptionsInput): DueDateDisplayOptionsInput => {
  return {
    type: option.type,
    month: isDueDateOptionTypeVerrechnungsperiode(option.type) && isTruthy(option.month) ? option.month + 1 : null,
  };
};
