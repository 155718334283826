import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../../shared/dataChangedSubscription';

export const useOnAuftragDataChangedEvent = (key: string, callback: () => Promise<unknown>) => {
  useOnDataChangedEvent({
    filter: {
      type: dataChangedSubscriptionTypes.auftrag,
    },
    notificationDescription: 'Vorschreibungen wurden gerade verändert',
    callback,
    key,
  });
};
