import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { UstKategorieFieldsFragmentDoc, UstKategorieEntryFieldsFragmentDoc } from '../../UstKategorie/gql/UstKategorieFragments.types';
export type UstRegelsetFieldsFragment = {
  bezeichnung: string;
  countryCodeIso2: string;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  eigennutzung: boolean;
  firmendatenId?: string | null;
  leerstehung: boolean;
  optionSteuerpflicht: boolean;
  ustRegelsetId: string;
  land?: { alpha2: string; alpha3: string; bezeichnung: string } | null;
  nutzungsArt: { text: string; value: Types.NutzungsArt };
  steuerpflicht: { text: string; value: Types.Steuerpflicht };
  ustKategorie?: {
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    firmendatenId?: string | null;
    kurzBezeichnung: string;
    ustKategorieId: string;
    ustVomAufwand?: number | null;
    vstKuerzung?: boolean | null;
    status?: { text: string; value: Types.UstKategorieStatus } | null;
    ustKategorieEntryList: Array<{
      additionKonto: number;
      buchungsKZ?: string | null;
      createTs?: string | null;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      rechnungskennzeichen: string;
      steuersatz: number;
      ustKategorieEntryId?: string | null;
      ustKz: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kuerzel: string;
        umsatzsteuerkennzeichenId: string;
        status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  ustKategorieZuweisungList: Array<{
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    current: boolean;
    ustRegelsetKategorieZuweisungId: string;
    validFrom: string;
    ustKategorie: {
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      ustKategorieId: string;
      ustVomAufwand?: number | null;
      vstKuerzung?: boolean | null;
      status?: { text: string; value: Types.UstKategorieStatus } | null;
      ustKategorieEntryList: Array<{
        additionKonto: number;
        buchungsKZ?: string | null;
        createTs?: string | null;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        rechnungskennzeichen: string;
        steuersatz: number;
        ustKategorieEntryId?: string | null;
        ustKz: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string; attribute?: string | null }>;
  }>;
  warningList: Array<{ message: string; type: string; attribute?: string | null }>;
};

export const UstRegelsetFieldsFragmentDoc = gql`
  fragment UstRegelsetFields on UstRegelset {
    bezeichnung
    countryCodeIso2
    createTs
    createdBy
    createdByMitarbeiterId
    eigennutzung
    firmendatenId
    land {
      alpha2
      alpha3
      bezeichnung
    }
    leerstehung
    nutzungsArt {
      text
      value
    }
    optionSteuerpflicht
    steuerpflicht {
      text
      value
    }
    ustKategorie {
      ...UstKategorieFields
    }
    ustKategorieZuweisungList {
      createTs
      createdBy
      createdByMitarbeiterId
      current
      ustKategorie {
        ...UstKategorieFields
      }
      ustRegelsetKategorieZuweisungId
      validFrom
      warningList {
        message
        type
        attribute
      }
    }
    ustRegelsetId
    warningList {
      message
      type
      attribute
    }
  }
  ${UstKategorieFieldsFragmentDoc}
`;
