import React, { FC } from 'react';
import { Button, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { DocumentRowProps } from './util';
import { ValueTitle } from '../../../../components/Helpers/ValueTitle';
import { DocumentInfo, DocumentRowContextProvider } from './documentRowContext';
import AvatarBasedOnFileInfoTypeWithTooltip from '../../../../components/Avatar/BasedOnFileInfoType/AvatarBasedOnFileInfoTypeWithTooltip';
import { uriFileOfFirmendaten } from '../../documentApiPaths';
import { useFDAuthorized } from '../../../../shared/Auth/useAuthorized';
import FileSize from '../../../../components/Helpers/FileSize';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

const RowMailAttachment: FC<DocumentRowProps> = ({ record, rechnungTyp, invoiceRecipientId, invoiceIssuerId, ...restProps }) => {
  const { activeForFirmendatenId } = useFDAuthorized();

  const documentInfo: DocumentInfo = {
    column1: <AvatarBasedOnFileInfoTypeWithTooltip fileType={record.type} />,
    column2: (
      <ValueTitle
        small
        title="Dateiname"
        content={
          <DataWithShortenedText maxTextLength={30} text={record.name}>
            {(shortenedText) => <Typography.Text>{shortenedText}</Typography.Text>}
          </DataWithShortenedText>
        }
      />
    ),
    column3: <ValueTitle small title="Dateigröße" content={<FileSize size={record.size} />} />,
    column4: <ValueTitle small title="-" content="-" />,
    column5: <ValueTitle small title="-" content="-" />,
    column6: <ValueTitle small title="-" content="-" />,
    column7: <ValueTitle small title="-" content="-" />,
    column8: (
      <Button>
        <Link to={uriFileOfFirmendaten(activeForFirmendatenId, record.fileId, record.name)} target="_blank">
          Öffnen
        </Link>
      </Button>
    ),
  };

  return (
    <DocumentRowContextProvider value={{ isLoading: false, documentInfo }}>
      <tr {...restProps} />
    </DocumentRowContextProvider>
  );
};

export default RowMailAttachment;
