import { mapToQueryString, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { GenerierlaufEntryExitCode } from '../../../../../types';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../../verarbeitungApiPaths';

export enum HeAbrechnungVerarbeitungQueryParamKey {
  OBJEKT_ID = 'objektId',
  HAUSEIGENTUEMER_ID = 'hauseigentuemerId',
  EXIT_CODE_LIST = 'exitCodeList',
}

export type THeAbrechnungVerarbeitungQueryParams = QueryParams<{
  objektId: string;
  hauseigentuemerId: string;
  exitCodeList: GenerierlaufEntryExitCode[];
}>;

export const updateQueryParams = (
  verarbeitungType: string,
  generierlaufId: string,
  navigate: (path: string) => void,
  filters: THeAbrechnungVerarbeitungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) =>
  navigate(
    generatePathToHeAbrechnungVerarbeitungPage(
      verarbeitungType,
      generierlaufId,
      filters.objektId,
      filters.hauseigentuemerId,
      filters.exitCodeList,
      paginationParams
    )
  );

const generatePathToHeAbrechnungVerarbeitungPage = (
  verarbeitungType: string,
  generierlaufId: string,
  objektId?: string,
  hauseigentuemerId?: string,
  exitCodeList?: GenerierlaufEntryExitCode[],
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [HeAbrechnungVerarbeitungQueryParamKey.OBJEKT_ID]: objektId,
    [HeAbrechnungVerarbeitungQueryParamKey.HAUSEIGENTUEMER_ID]: hauseigentuemerId,
    [HeAbrechnungVerarbeitungQueryParamKey.EXIT_CODE_LIST]: exitCodeList,
    ...paginationParams,
  })}`;
