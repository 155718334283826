import React from 'react';
import { message, notification } from 'antd';
import { mapErrorToErrorMessage } from '../../helpers/errorAndWarningHelper';
import ErrorNotification, { EasyErrorType } from './ErrorNotification';
import { Spacer } from '../Grid';

// MUST READ: https://www.nngroup.com/articles/error-message-guidelines/

export const messageEntityArchived = (entryOrBezeichnung?: string): string =>
  entryOrBezeichnung ? `${entryOrBezeichnung} ist archiviert` : `archiviert`;

export const messageEntityGesperrt = (entryOrBezeichnung?: string): string =>
  entryOrBezeichnung ? `${entryOrBezeichnung} ist gesperrt` : `gesperrt`;

export const showSuccessMsgArchived = (entity: string) =>
  message.success(<span data-testid="message-success">{entity} erfolgreich archiviert.</span>);
export const showSuccessMsgUnarchived = (entity: string) =>
  message.success(<span data-testid="message-success">{entity} erfolgreich reaktiviert.</span>);

export const showSuccessMsgCreate = (entity: string) => message.success(<span data-testid="message-success">{entity} erfolgreich angelegt.</span>);

export const showSuccessMsgDelete = (entity: string) => message.success(<span data-testid="message-success">{entity} erfolgreich gelöscht.</span>);

export const showSuccessMsgEntsperrt = (entity: string) =>
  message.success(<span data-testid="message-success">{entity} erfolgreich entsperrt.</span>);

export const showSuccessMsgFreigegeben = (entity: string) =>
  message.success(<span data-testid="message-success">{entity} erfolgreich freigegeben.</span>);

export const showSuccessMsgStornieren = (entity: string) =>
  message.success(<span data-testid="message-success">{entity} erfolgreich storniert.</span>);

export const showSuccessMsgVerbuchen = (entity: string) => message.success(<span data-testid="message-success">{entity} erfolgreich verbucht.</span>);

export const showSuccessMsgRestore = (entity: string) =>
  message.success(<span data-testid="message-success">{entity} erfolgreich zurückgesetzt.</span>);

export const showSuccessMsgGesperrt = (entity: string) => message.success(<span data-testid="message-success">{entity} erfolgreich gesperrt.</span>);

export const showSuccessMsgRestart = (entity: string) =>
  message.success(<span data-testid="message-success">{entity} erfolgreich wiederaufgenommen.</span>);

export const showSuccessMsgUpdate = (entity: string) =>
  message.success(<span data-testid="message-success">{entity} erfolgreich aktualisiert.</span>);

export const showSuccessMsgDeviationsUebernehmen = () =>
  message.success(<span data-testid="message-success">Alle Abweichungen sind korrigiert.</span>);

// contains all msg that do not fit into a general convention (e.g. see messages above!)
// benefit: later we pack all msg into a json and keep everything in sync
export const showSuccessMsgOther = (msg: string) => message.success(<span data-testid="message-success">{msg}</span>);

export const showWarningMessage = (error: any, warningMessage: string) =>
  error.message ? message.warning(`${warningMessage}: ${mapErrorToErrorMessage(error)}`) : message.warning(`${warningMessage}`);

export const showErrorMessage = (error: any, errorMessage: string) =>
  error.message ? message.error(`${errorMessage}: ${mapErrorToErrorMessage(error)}`) : message.error(`${errorMessage}`);

export const showErrorNotification = (titleMessage: React.ReactNode, errors: EasyErrorType[] = [], key?: string) => {
  notification.error({
    key,
    message: titleMessage,
    duration: 10,
    description: <ErrorNotification errors={errors} />,
  });
};

export const showWarningNotification = (errors: EasyErrorType[]) => {
  notification.warning({
    message: (
      <>
        Einige Warnungen sind aufgetreten
        <Spacer />
      </>
    ),
    description: <ErrorNotification errors={errors} />,
  });
};

export const showSuccessMsgCreateWithPath = (entityType: string, title: string, path: string) =>
  message.success(
    <span data-testid="message-success">
      {entityType} <a href={path}>{title}</a> erfolgreich angelegt.
    </span>
  );

export const showSuccessMsgVerarbeitungStarted = (title: string) => {
  showSuccessMsgOther(`Die Verarbeitung für ${title} wird gestartet.`);
};
