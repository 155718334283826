import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateObjektBankDetailsMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  input: Types.ObjektBankDetailsCreateInput;
}>;

export type CreateObjektBankDetailsMutation = {
  createObjektBankDetails: {
    data: { objektBankDetailsId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateObjektBankDetailsMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  objektBankDetailsId: Types.Scalars['ID']['input'];
  input: Types.ObjektBankDetailsUpdateInput;
}>;

export type UpdateObjektBankDetailsMutation = {
  updateObjektBankDetails: {
    data: { objektBankDetailsId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteObjektBankDetailsMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  objektBankDetailsId: Types.Scalars['ID']['input'];
}>;

export type DeleteObjektBankDetailsMutation = {
  deleteObjektBankDetails: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateObjektBankDetailsDocument = gql`
  mutation CreateObjektBankDetails($objektId: ID!, $input: ObjektBankDetailsCreateInput!) {
    createObjektBankDetails(objektId: $objektId, input: $input) {
      data {
        objektBankDetailsId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateObjektBankDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateObjektBankDetailsMutation, CreateObjektBankDetailsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateObjektBankDetailsMutation, CreateObjektBankDetailsMutationVariables>(CreateObjektBankDetailsDocument, options);
}
export type CreateObjektBankDetailsMutationHookResult = ReturnType<typeof useCreateObjektBankDetailsMutation>;
export type CreateObjektBankDetailsMutationResult = Apollo.MutationResult<CreateObjektBankDetailsMutation>;
export type CreateObjektBankDetailsMutationOptions = Apollo.BaseMutationOptions<
  CreateObjektBankDetailsMutation,
  CreateObjektBankDetailsMutationVariables
>;
export const UpdateObjektBankDetailsDocument = gql`
  mutation UpdateObjektBankDetails($objektId: String!, $objektBankDetailsId: ID!, $input: ObjektBankDetailsUpdateInput!) {
    updateObjektBankDetails(objektId: $objektId, objektBankDetailsId: $objektBankDetailsId, input: $input) {
      data {
        objektBankDetailsId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateObjektBankDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateObjektBankDetailsMutation, UpdateObjektBankDetailsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateObjektBankDetailsMutation, UpdateObjektBankDetailsMutationVariables>(UpdateObjektBankDetailsDocument, options);
}
export type UpdateObjektBankDetailsMutationHookResult = ReturnType<typeof useUpdateObjektBankDetailsMutation>;
export type UpdateObjektBankDetailsMutationResult = Apollo.MutationResult<UpdateObjektBankDetailsMutation>;
export type UpdateObjektBankDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateObjektBankDetailsMutation,
  UpdateObjektBankDetailsMutationVariables
>;
export const DeleteObjektBankDetailsDocument = gql`
  mutation DeleteObjektBankDetails($objektId: String!, $objektBankDetailsId: ID!) {
    deleteObjektBankDetails(objektId: $objektId, objektBankDetailsId: $objektBankDetailsId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteObjektBankDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteObjektBankDetailsMutation, DeleteObjektBankDetailsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteObjektBankDetailsMutation, DeleteObjektBankDetailsMutationVariables>(DeleteObjektBankDetailsDocument, options);
}
export type DeleteObjektBankDetailsMutationHookResult = ReturnType<typeof useDeleteObjektBankDetailsMutation>;
export type DeleteObjektBankDetailsMutationResult = Apollo.MutationResult<DeleteObjektBankDetailsMutation>;
export type DeleteObjektBankDetailsMutationOptions = Apollo.BaseMutationOptions<
  DeleteObjektBankDetailsMutation,
  DeleteObjektBankDetailsMutationVariables
>;
