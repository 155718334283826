import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { SyncEbicsUserPageRouteParams } from './routes';
import { useSyncEbicsUserGenerierlaufQuery } from '../../../features/Verarbeitung/gql/SyncEbicsUserGenerierlauf/gql/SyncEbicsUserGenerierlaufQueries.types';
import { SyncEbicsUserGenerierlauf } from '../../../types';
import menuListSyncEbicsUser from './menuListSyncEbicsUser';

const SyncEbicsUserDetailsPage = () => {
  const { generierlaufId } = useParams() as SyncEbicsUserPageRouteParams;

  const { data } = useSyncEbicsUserGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getSyncEbicsUserGenerierlauf.data;

  return (
    <DetailsPageTemplate<SyncEbicsUserGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.SyncEbicsUser}
      card={(generierlauf) => <VerarbeitungDetailsPageCard generierlauf={generierlauf} cardTitle={verarbeitungDetailsCardTitles.SyncEbicsUser} />}
      isLoading={false}
      sidebarMenuList={menuListSyncEbicsUser}
    />
  );
};

export default SyncEbicsUserDetailsPage;
