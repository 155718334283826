import { FC } from 'react';
import { Space } from 'antd';
import { FormattedNumber } from 'react-intl';
import { VertragVposIndexationAgreementIndexThreshold } from '../../../../types';
import { isThresholdTypePercent } from '../../../Vertrag/BeVertrag/Vertragsdaten/VPosIndexationAgreement/Table/Level2/CreateUpdateIndexationAgreement/shared/indexationAgreementFormHelpers';
import { EuroAmount, Percent } from '../../../../components/Number';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import TypographyTextForTooltip from '../../../../components/Text/TypographyTextForTooltip';

type Props = {
  indexationAgreement: VertragVposIndexationAgreementIndexThreshold;
};

const IndexThresholdTooltipTitle: FC<Props> = ({ indexationAgreement }) => {
  return (
    <>
      <Space size="small">
        <TypographyTextForTooltip text="Wirksam nach: " />
        {indexationAgreement.effectiveAfterMonths ? (
          <TypographyTextForTooltip text={indexationAgreement.effectiveAfterMonths} />
        ) : (
          <TextForEmptyValue textToShow="NV" color="inherit" />
        )}
      </Space>
      <br />
      <TypographyTextForTooltip text="Schwellenwert: " />
      {isThresholdTypePercent(indexationAgreement.thresholdType.value) ? (
        <Percent value={indexationAgreement.threshold / 100} />
      ) : (
        <FormattedNumber style="unit" unit="Pkt" value={indexationAgreement.threshold} />
      )}
      <br />
      <Space>
        <TypographyTextForTooltip text="Bagatellgrenze: " />
        {indexationAgreement.minimumLimit ? (
          <EuroAmount value={indexationAgreement.minimumLimit} />
        ) : (
          <TextForEmptyValue textToShow="NV" color="inherit" />
        )}
      </Space>
      <br />
      <Space>
        <TypographyTextForTooltip text="Anteil: " />
        {indexationAgreement.shareInPercentToApply ? (
          <Percent value={indexationAgreement.shareInPercentToApply} />
        ) : (
          <TextForEmptyValue textToShow="NV" color="inherit" />
        )}
      </Space>
    </>
  );
};

export default IndexThresholdTooltipTitle;
