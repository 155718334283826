import { FormFields } from '../../../../helpers/formikHelper';
import {
  IndexPoint,
  IndexPointCreateInput,
  IndexPointUpdateInput,
  IndexSeriesEntry,
  IndexSeriesEntryCreateInput,
  IndexSeriesEntryUpdateInput,
  Month,
} from '../../../../types';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import { mapEnglishMonthToGermanMonth, mapGermanMonthToEnglishMonth } from '../indexSeriesEntryHelpers';

export type IndexSeriesEntryFormValues = {
  indexPointList: IndexPointListFormValues[];
  year: string | null;
};

type IndexPointListFormValues = {
  finalValue?: number | null;
  month: Month;
  preliminaryValue?: number | null;
};

export const indexSeriesEntryFormFields: FormFields<IndexSeriesEntryFormValues> = {
  indexPointList: 'indexPointList',
  year: 'year',
};

dayjsCustom.locale('de');

const initialIndexPointListFormValues: IndexPointListFormValues[] = Array(12)
  .fill({})
  .map((value, index) => ({
    finalValue: null,
    month: dayjsCustom().month(index).format('MMMM') as Month,
    preliminaryValue: null,
  }));

export const initialIndexSeriesEntryFormValues = (indexSeriesEntry?: IndexSeriesEntry): IndexSeriesEntryFormValues => ({
  indexPointList: indexSeriesEntry?.indexPointList
    ? mapIndexPointToIndexPointFormValue(indexSeriesEntry.indexPointList)
    : initialIndexPointListFormValues,
  year: indexSeriesEntry?.year.toString() ?? null,
});

const mapIndexPointToIndexPointFormValue = (indexPointList: IndexPoint[]): IndexPointListFormValues[] =>
  indexPointList.map((indexPoint) => ({
    finalValue: indexPoint.finalValue,
    month: mapEnglishMonthToGermanMonth(indexPoint.month as Month) as Month,
    preliminaryValue: indexPoint.preliminaryValue,
  }));

export const mapFormValuesToIndexSeriesEntry = (values: IndexSeriesEntryFormValues): IndexSeriesEntryCreateInput | IndexSeriesEntryUpdateInput => ({
  indexPointList: mapFormValueIndexPointToIndexPoint(values.indexPointList),
  year: dayjsCustom(values.year).year(),
});

const mapFormValueIndexPointToIndexPoint = (indexPointList: IndexPointListFormValues[]): (IndexPointCreateInput | IndexPointUpdateInput)[] => {
  return indexPointList.map((indexPoint) => ({
    finalValue: indexPoint.finalValue,
    month: mapGermanMonthToEnglishMonth(indexPoint.month),
    preliminaryValue: indexPoint.preliminaryValue,
  }));
};
