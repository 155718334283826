import { Formik } from 'formik';
import React, { FC } from 'react';
import { Form } from 'formik-antd';
import { Dayjs } from 'dayjs';
import FormButtons from '../../../../components/Button/FormButtons';
import {
  mapFormValuesToVstKuerzung,
  vstKuerzungCreateFormFields,
  vstKuerzungCreateFormInitialValues,
  VstKuerzungCreateFormValues,
} from './vstKuerzungCreateFormMapper';
import { vstKuerzungCreateFormValidationSchema } from './vstKuerzungCreateFormValidationSchema';
import { showSuccessMsgOther } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { useCreateVorsteuerkuerzungGenerierlaufMutation } from '../../../Verarbeitung/gql/VorsteuerkuerzungGenerierlauf/VorsteuerkuerzungGenerierlaufMutations.types';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import RangePicker from '../../../../components/DatePicker/RangePicker/RangePicker';

type Props = {
  objektId: string;
  verwaltungSeit: string;
  onSuccess: () => void;
  onCancel: () => void;
};

const VstKuerzungCreateForm: FC<Props> = ({ objektId, verwaltungSeit, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'VorsteuerkuerzungGenerierlauf'>('VorsteuerkuerzungGenerierlauf');

  const [runCreateVstKuerzung, { loading }] = useCreateVorsteuerkuerzungGenerierlaufMutation({
    onCompleted: () => {
      showSuccessMsgOther(`Vorsteuerkürzungen werden für das Objekt angelegt.`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<VstKuerzungCreateFormValues>
      initialValues={vstKuerzungCreateFormInitialValues}
      validationSchema={vstKuerzungCreateFormValidationSchema}
      onSubmit={(values) => {
        const request = mapFormValuesToVstKuerzung(objektId, values);
        runCreateVstKuerzung({ variables: { request } });
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithFieldHelp
            name={vstKuerzungCreateFormFields.vonBis}
            label="Zeitraum von – bis"
            fieldHelp={getFieldHelpText('VorsteuerkuerzungGenerierlauf.vonInklusive')}
          >
            <RangePicker
              name={vstKuerzungCreateFormFields.vonBis}
              placeholder={['von', 'bis']}
              picker="month"
              style={{ width: '100%' }}
              disabledDate={(dates) => dayjsCustom(verwaltungSeit).isAfter(dayjsCustom(dates as unknown as Dayjs))}
            />
          </FormItemWithFieldHelp>
          <FormButtons
            okText="Vst.kürzung Berechnung erstellen"
            updateMode={false}
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={loading}
          />
        </Form>
      )}
    </Formik>
  );
};

export default VstKuerzungCreateForm;
