import React, { FC, useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { BookingSuggestion } from '../../../../types';
import GeneralInfoWithButtonsBlock from '../GeneralInfoWithButtonsBlock/GeneralInfoWithButtonsBlock';
import BuchungBlock from '../../shared/BuchungBlock/BuchungBlock';
import AuszifferungBlock from '../../shared/AuszifferungBlock/AuszifferungBlock';
import { BookingSuggestionFormValues, mapFormValuesToBookingSuggestionUpdateInput } from '../../shared/Form/buchungsmaskeFormMapper';
import { useUpdateBookingSuggestionMutation } from '../../gql/BookingSuggestionMutations.types';
import { showSuccessMsgUpdate } from '../../../../components/message';
import RouterPrompt from '../../../../components/Prompt/RouterPrompt';
import { isBuchungTypeZahlungOrRuecklastschrift } from '../../shared/bookingSuggestionHelpers';
import { useBookingSuggestionInitialValues } from '../../shared/Form/useBookingSuggestionInitialValues';
import DividerForBooking from '../../../BookingDetails/shared/DividerForBooking';
import SpaceForBookingBlockAndForm from '../../../BookingDetails/shared/SpaceForBookingBlockAndForm';
import { bookingSuggestionUpdateFormValidationSchema } from '../../shared/Form/bookingSuggestionUpdateFormValidationSchema';

type Props = {
  bookingSuggestion: BookingSuggestion;
  onUpdateSuccess: () => void;
  setWarningListForTabsLabels?: (hasWarning: boolean) => void;
};

const BuchungsmaskeForm: FC<Props> = ({ bookingSuggestion, onUpdateSuccess, setWarningListForTabsLabels }) => {
  const [runUpdateBookingSuggestion, { loading: loadingUpdate }] = useUpdateBookingSuggestionMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`Buchungsvorschlag`);
      onUpdateSuccess();
    },
  });

  // We need to fetch the initial values for the form, specially fetch and set the real initial value for offenePostenForPersonenkontoList. Otherwise, formik will always treat the form will as dirty already on the first render. This will trigger showing the unsaved changes warning message when the user tries to navigate away from the page.
  const { initialValues, loading } = useBookingSuggestionInitialValues(bookingSuggestion);

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [initialValuesForTracking, setInitialValuesForTracking] = useState<BookingSuggestionFormValues>(initialValues);

  useEffect(() => {
    setInitialValuesForTracking(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    setWarningListForTabsLabels && setWarningListForTabsLabels(isFormDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormDirty]);

  if (loading) return <Skeleton active />;

  return (
    <Formik<BookingSuggestionFormValues>
      initialValues={initialValues}
      validationSchema={bookingSuggestionUpdateFormValidationSchema}
      initialTouched={{
        buchungsanweisung: {
          buchungskreisId: true, // setting touched to true to show the error message on the first render
          buchungskreisRechtstraegerId: true, // setting touched to true to show the error message on the first render
        },
      }}
      onSubmit={(values, formikHelpers) => {
        const input = mapFormValuesToBookingSuggestionUpdateInput(values);
        setInitialValuesForTracking(values);

        runUpdateBookingSuggestion({
          variables: {
            bookingSuggestionId: bookingSuggestion.bookingSuggestionId,
            input,
          },
        })
          .then(() => formikHelpers.resetForm({ values })) // Reset formik initial form values on save in order to set back to proper values on clicking the "Reset" (=Zurücksetzen) button
          .finally(() => {
            formikHelpers.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => (
        <>
          <Form layout="vertical">
            <SpaceForBookingBlockAndForm>
              {/* block 1  - General Info with Buttons*/}
              <GeneralInfoWithButtonsBlock
                bookingSuggestion={bookingSuggestion}
                formikProps={formikProps}
                isOkLoading={loadingUpdate}
                onReset={formikProps.resetForm}
                onSubmit={formikProps.submitForm}
                showNotSavedWarning={isFormDirty}
                initialValuesForTracking={initialValuesForTracking}
                setIsFormDirty={setIsFormDirty}
              />
              <DividerForBooking />

              {/* block 2 - BuchungBlock and view of Errors coming from BE */}
              <BuchungBlock formikProps={formikProps} bookingSuggestion={bookingSuggestion} isUpdate />

              {/* block 3 - Auszifferung und Offene Posten listings */}
              {isBuchungTypeZahlungOrRuecklastschrift(formikProps.values.buchungsanweisung.buchungType) && (
                <>
                  <DividerForBooking />
                  <AuszifferungBlock auszifferungBlockTitle="Auszifferungsvorschlag" formikProps={formikProps} />
                </>
              )}
            </SpaceForBookingBlockAndForm>
          </Form>
          <RouterPrompt when={isFormDirty} />
        </>
      )}
    </Formik>
  );
};

export default BuchungsmaskeForm;
