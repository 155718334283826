import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteVorsteuerkuerzungMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  vorsteuerkuerzungId: Types.Scalars['ID']['input'];
}>;

export type DeleteVorsteuerkuerzungMutation = {
  deleteVorsteuerkuerzung: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type VorsteuerkuerzungFreigebenMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  vorsteuerkuerzungId: Types.Scalars['ID']['input'];
  input: Types.VorsteuerkuerzungActionFreigebenInput;
}>;

export type VorsteuerkuerzungFreigebenMutation = {
  actionVorsteuerkuerzungFreigeben: {
    data: { vorsteuerkuerzungId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type VorsteuerkuerzungMarkUnderReviewMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  vorsteuerkuerzungId: Types.Scalars['ID']['input'];
}>;

export type VorsteuerkuerzungMarkUnderReviewMutation = {
  actionVorsteuerkuerzungMarkUnderReview: {
    data: { vorsteuerkuerzungId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const DeleteVorsteuerkuerzungDocument = gql`
  mutation DeleteVorsteuerkuerzung($objektId: String!, $vorsteuerkuerzungId: ID!) {
    deleteVorsteuerkuerzung(objektId: $objektId, vorsteuerkuerzungId: $vorsteuerkuerzungId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteVorsteuerkuerzungMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteVorsteuerkuerzungMutation, DeleteVorsteuerkuerzungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVorsteuerkuerzungMutation, DeleteVorsteuerkuerzungMutationVariables>(DeleteVorsteuerkuerzungDocument, options);
}
export type DeleteVorsteuerkuerzungMutationHookResult = ReturnType<typeof useDeleteVorsteuerkuerzungMutation>;
export type DeleteVorsteuerkuerzungMutationResult = Apollo.MutationResult<DeleteVorsteuerkuerzungMutation>;
export type DeleteVorsteuerkuerzungMutationOptions = Apollo.BaseMutationOptions<
  DeleteVorsteuerkuerzungMutation,
  DeleteVorsteuerkuerzungMutationVariables
>;
export const VorsteuerkuerzungFreigebenDocument = gql`
  mutation VorsteuerkuerzungFreigeben($objektId: String!, $vorsteuerkuerzungId: ID!, $input: VorsteuerkuerzungActionFreigebenInput!) {
    actionVorsteuerkuerzungFreigeben(objektId: $objektId, vorsteuerkuerzungId: $vorsteuerkuerzungId, input: $input) {
      data {
        vorsteuerkuerzungId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useVorsteuerkuerzungFreigebenMutation(
  baseOptions?: Apollo.MutationHookOptions<VorsteuerkuerzungFreigebenMutation, VorsteuerkuerzungFreigebenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VorsteuerkuerzungFreigebenMutation, VorsteuerkuerzungFreigebenMutationVariables>(
    VorsteuerkuerzungFreigebenDocument,
    options
  );
}
export type VorsteuerkuerzungFreigebenMutationHookResult = ReturnType<typeof useVorsteuerkuerzungFreigebenMutation>;
export type VorsteuerkuerzungFreigebenMutationResult = Apollo.MutationResult<VorsteuerkuerzungFreigebenMutation>;
export type VorsteuerkuerzungFreigebenMutationOptions = Apollo.BaseMutationOptions<
  VorsteuerkuerzungFreigebenMutation,
  VorsteuerkuerzungFreigebenMutationVariables
>;
export const VorsteuerkuerzungMarkUnderReviewDocument = gql`
  mutation VorsteuerkuerzungMarkUnderReview($objektId: String!, $vorsteuerkuerzungId: ID!) {
    actionVorsteuerkuerzungMarkUnderReview(objektId: $objektId, vorsteuerkuerzungId: $vorsteuerkuerzungId) {
      data {
        vorsteuerkuerzungId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useVorsteuerkuerzungMarkUnderReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<VorsteuerkuerzungMarkUnderReviewMutation, VorsteuerkuerzungMarkUnderReviewMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VorsteuerkuerzungMarkUnderReviewMutation, VorsteuerkuerzungMarkUnderReviewMutationVariables>(
    VorsteuerkuerzungMarkUnderReviewDocument,
    options
  );
}
export type VorsteuerkuerzungMarkUnderReviewMutationHookResult = ReturnType<typeof useVorsteuerkuerzungMarkUnderReviewMutation>;
export type VorsteuerkuerzungMarkUnderReviewMutationResult = Apollo.MutationResult<VorsteuerkuerzungMarkUnderReviewMutation>;
export type VorsteuerkuerzungMarkUnderReviewMutationOptions = Apollo.BaseMutationOptions<
  VorsteuerkuerzungMarkUnderReviewMutation,
  VorsteuerkuerzungMarkUnderReviewMutationVariables
>;
