import React from 'react';
import { Tag } from 'antd';
import { archivedOrNormalStyle, StatusEnum } from '../../../../helpers/statusHelper';
import VorschreibungspositionTableActionsAndDrawer from './VorschreibungspositionTableActionsAndDrawer';
import { MitarbeiterTooltip } from '../../../../components/Card';
import StatusBadge from '../../../../components/Status/StatusBadge';
import DataWithStatus from '../../../../components/Helpers/DataWithStatus';
import { Vorschreibungsposition } from '../../../../types';
import { NestedTableColProps } from '../../../../components/Table/NestedTable/NestedTable';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import FirmendatenKurzBezeichnung from '../../../shared/FirmendatenKurzBezeichnung';

const vorschreibungspositionTableColumns = (onAction: () => void): NestedTableColProps<Vorschreibungsposition>[] => [
  {
    title: 'Bezeichnung',
    width: 250,
    render: (text, record) => (
      <DataWithStatus
        status={record.status}
        text={record.bezeichnung}
        maxTextLength={35}
        alternativeText={record.kurzBezeichnung}
        showBadgeDot={false}
        showStatusDescription={false}
      />
    ),
  },
  {
    title: 'VS-Pos. Art',
    width: 160,
    render: (text, record) => (
      <DataWithStatus status={record.status} text={record.art.text} maxTextLength={22} showBadgeDot={false} showStatusDescription={false} />
    ),
  },
  {
    title: 'Kontierungstabelle',
    width: 160,
    render: (text, record) =>
      record.kontierungstabelle ? (
        <DataWithStatus
          status={record.status}
          text={record.kontierungstabelle.bezeichnung}
          maxTextLength={22}
          showBadgeDot={false}
          showStatusDescription={false}
        />
      ) : (
        <Tag color="warning">keine Version</Tag>
      ),
  },
  {
    title: 'USt.-Kz',
    render: (text, record) =>
      record.umsatzsteuerkennzeichen ? (
        <DataWithStatus status={record.status} text={record.umsatzsteuerkennzeichen.kuerzel} showBadgeDot={false} showStatusDescription={false} />
      ) : (
        <Tag color="warning">keine Version</Tag>
      ),
  },
  {
    title: 'Merkmale',
    render: (text, record) => record.tagList.map((tag) => <Tag key={tag}>{tag}</Tag>),
  },
  {
    title: 'Firma',
    width: 140,
    render: (text, record) =>
      record.firmendatenId ? (
        <FirmendatenKurzBezeichnung firmendatenId={record.firmendatenId} />
      ) : (
        <Tag style={archivedOrNormalStyle(record.status)}>System</Tag>
      ),
  },
  {
    title: 'Status',
    filters: [
      { text: 'Aktiv', value: StatusEnum.Aktiv },
      { text: 'Archiviert', value: StatusEnum.Archiviert },
    ],
    onFilter: (value, record) => record.status.value.includes(value as string),
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },
  {
    title: 'Erstellt am',
    render: (text, record) => (
      <DataWithStatus status={record.status} text="" showBadgeDot={false} showStatusDescription={false}>
        {() => <FormattedDateTime createdTs={record.createTs} />}
      </DataWithStatus>
    ),
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Aktion',
    key: 'action',
    render: (text: string, record) => <VorschreibungspositionTableActionsAndDrawer vorschreibungsposition={record} onAction={onAction} />,
  },
];

export default vorschreibungspositionTableColumns;
