import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useKundenSystemEinstellungQuery } from './gql/SalutationQueries.types';
import { selectFilterOption } from '../../../helpers/selectHelper';
import { Salutation, SalutationTuple } from '../../../types';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';

type Props = {
  kundenSystemId?: string;
  name: string;
  salutationOptionList?: SalutationTuple[];
  fieldHelp?: string | null;
} & Omit<SelectProps, 'name' | 'id' | 'placeholder'>;

const SalutationSelect: FC<Props> = ({ kundenSystemId, name, salutationOptionList, fieldHelp, ...restProps }) => {
  const { loading, data } = useKundenSystemEinstellungQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { kundenSystemId: kundenSystemId! },
    skip: !kundenSystemId,
  });

  const salutationList = kundenSystemId
    ? data?.getKundensystemEinstellung.data.salutationList ?? []
    : salutationOptionList || defaultSalutationOptions;

  return (
    <FormItemWithFieldHelp name={name} label="Anrede" fieldHelp={fieldHelp}>
      <Select
        id={name}
        name={name}
        loading={loading}
        placeholder="Anrede auswählen"
        allowClear
        showSearch
        filterOption={selectFilterOption}
        {...restProps}
      >
        {salutationList.map((item) => (
          <Select.Option data-testid={item.text} key={item.value} value={item.value}>
            {item.text}
          </Select.Option>
        ))}
      </Select>
    </FormItemWithFieldHelp>
  );
};

const defaultSalutationOptions: SalutationTuple[] = [
  {
    value: Salutation.Sir,
    text: 'Herr',
    letterSalutation: '',
  },
  {
    value: Salutation.Madame,
    text: 'Frau',
    letterSalutation: '',
  },
];

export default SalutationSelect;
