import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Modal, Typography } from 'antd';
import { postItFormInitialValues, PostItFormValues } from './PostItFormMapper';
import { postItFormValidationSchema } from './PostItFormValidationSchema';
import { PostItInput } from '../../../types';
import PostItFormItemTitel from './FormItems/PostItFormItemTitel';
import PostItFormItemTextArea from './FormItems/PostItFormItemTextArea';
import PostItFormItemImmerAnzeigen from './FormItems/PostItFormItemImmerAnzeigen';
import { useGetAppFieldHelpText } from '../../../features/FieldHelp/useGetFieldHelpText';
import FieldHelpIconWithTooltip from '../../FieldHelpIcon/FieldHelpIconWithTooltip';

type Props = {
  isOpen: boolean;
  onSubmit: (values: PostItInput) => void;
  onCancel: () => void;
  showImmerAnzeigen?: boolean;
};

/**
 * <h2>Usage</h2>
 * Form for creation of a Post-It
 *
 * */
const PostItCreateForm: FC<Props> = ({ isOpen, onSubmit, onCancel, showImmerAnzeigen = true }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'PostIt'>('PostIt');

  return (
    <Formik<PostItFormValues>
      initialValues={postItFormInitialValues()}
      validationSchema={postItFormValidationSchema}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(formikProps) => (
        <Form>
          <Modal
            open={isOpen}
            okText="Speichern"
            cancelText="Abbrechen"
            width="500px"
            onOk={() => formikProps.handleSubmit()}
            onCancel={onCancel}
            title={<PostItFormItemTitel inputWidth="90%" fieldHelp={getFieldHelpText('PostIt.titel')} />}
          >
            <Typography.Text>
              Notiz <FieldHelpIconWithTooltip fieldHelp={getFieldHelpText('PostIt.text')} />
            </Typography.Text>
            <PostItFormItemTextArea fieldHelp={getFieldHelpText('PostIt.text')} />
            {showImmerAnzeigen && (
              <PostItFormItemImmerAnzeigen checked={formikProps.values.immerAnzeigen} fieldHelp={getFieldHelpText('PostIt.immerAnzeigen')} />
            )}
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default PostItCreateForm;
