import React, { FC, useEffect, useState } from 'react';
import { Input } from 'antd';
import { SearchProps } from 'antd/lib/input/Search';
import useDebounce from '../../hooks/useDebounce';

type Props = {
  value?: string;
  onChange: (value: string) => void;
} & Omit<SearchProps, 'onSearch' | 'onChange'>;

const DebouncedTextSearch: FC<Props> = ({ value, onChange, size, ...restProps }) => {
  const [text, setText] = useState(value ?? '');
  const debouncedText = useDebounce(text);

  // Update the text state if the value prop changes, necessary if the parent component updates the value prop
  useEffect(() => {
    setText(value ?? '');
  }, [value]);

  useEffect(() => {
    onChange(debouncedText as string);
    // if we define onChange as a dep we force the clients to use useCallback or sg like that to assure that onChange won't change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedText]);

  return (
    <Input.Search
      style={{ height: 22 }}
      size={size ?? 'middle'}
      value={text}
      onChange={(event) => setText(event.target.value)}
      allowClear
      {...restProps}
    />
  );
};

export default DebouncedTextSearch;
