import React, { FC } from 'react';
import { Divider, Space, Typography } from 'antd';
import CardWithFooter from '../../../components/Card';
import CardTitle from '../../../components/Card/CardTitle';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';
import { SubAdministration } from '../../../types';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';
import RechtstraegerWithContactsTooltip from '../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import HauptTag from '../../Vertrag/BeVertrag/Versand/Form/shared/HauptTag';
import { EuroAmount } from '../../../components/Number';
import SubAdministrationActions from './SubAdministrationActions';
import SubAdministrationPostIt from '../PostIt/SubAdministrationPostIt';

type Props = {
  subAdministration: SubAdministration;
  objektId: string;
  onAction: () => void;
};

const SubAdministrationCard: FC<Props> = ({ subAdministration, objektId, onAction }) => (
  <CardWithFooter
    loading={false}
    createTs={subAdministration.createTs}
    title={
      <Space style={{ width: '100%', justifyContent: 'space-between' }}>
        <CardTitle title="Subverwaltung" warningList={subAdministration.warningList} />
        <SubAdministrationPostIt
          objektId={objektId}
          subAdministrationId={subAdministration.subAdministrationId}
          postIt={subAdministration.postIt}
          onSuccess={onAction}
        />
      </Space>
    }
    extra={<SubAdministrationActions subAdministration={subAdministration} objektId={objektId} onAction={onAction} />}
    style={{ minWidth: '320px' }}
    size="default"
    bordered={false}
    userId={subAdministration.createdBy}
    mitarbeiterId={subAdministration.createdByMitarbeiterId}
  >
    <Typography.Title level={5}>Allgemeine Informationen</Typography.Title>
    <CardDetailsInfoRow infoRowTitle="Beginn">
      <CustomFormattedDate value={subAdministration.from} />
    </CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle="Ende">
      {subAdministration.to ? <CustomFormattedDate value={subAdministration.to} /> : <TextForEmptyValue textToShow="minus" />}
    </CardDetailsInfoRow>
    <Divider dashed />
    <CardDetailsInfoRow infoRowTitle="Eigentümer">
      <RechtstraegerWithContactsTooltip
        rechtstraegerName={subAdministration.eigentuemerBezeichnung}
        maxTextLength={22}
        rechtstraegerId={subAdministration.eigentuemerId}
      />
    </CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle="Zustelladresse">
      {subAdministration.deliveryAddress ? (
        <DataWithShortenedText maxTextLength={25} text={subAdministration.deliveryAddress}>
          {(shortenedText) => (
            <Space direction="vertical" size={2}>
              <Typography.Text>{shortenedText}</Typography.Text>
              {subAdministration.deliveryAddressHaupt && <HauptTag />}
            </Space>
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      )}
    </CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle="Erster Saldovortrag">
      <EuroAmount value={subAdministration.saldouebertrag} />
    </CardDetailsInfoRow>
  </CardWithFooter>
);

export default SubAdministrationCard;
