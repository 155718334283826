import React from 'react';
import { useParams } from 'react-router';
import { Skeleton } from 'antd';
import AndromedaSysSettingsWeAbrechnungsdefinitionVersionUpdatePage from './AndromedaSysSettingsWeAbrechnungsdefinitionVersionUpdatePage';
import {
  isAbrDefTypeHeAbrechnung,
  isAbrDefTypeSubAbrechnung,
  isAbrDefTypeWeAbrechnung,
} from '../../../../../../features/Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import AndromedaSysSettingsHeAbrechnungsdefinitionVersionUpdatePage from './AndromedaSysSettingsHeAbrechnungsdefinitionVersionUpdatePage';
import AndromedaSysSettingsBkAbrechnungsdefinitionVersionUpdatePage from './AndromedaSysSettingsBkAbrechnungsdefinitionVersionUpdatePage';
import AndromedaSysSettingsSubAbrechnungsdefinitionVersionUpdatePage from './AndromedaSysSettingsSubAbrechnungsdefinitionVersionUpdatePage';
import { AbrechnungsdefinitionVersionUpdatePageRouteParams } from '../../../../../SysSettingsPage/KundenSystem/AbrechnungsdefinitionPage/Version/Update/routes';
import { useAbrechnungsdefinitionTemplateTypeQuery } from '../../../../../../features/AbrechnungsdefinitionTemplate/gql/AbrDefTemplateQueries.types';

const AndromedaSysSettingsAbrechnungsdefinitionVersionUpdatePage = () => {
  const { abrechnungsdefinitionId, abrechnungsdefinitionVersionId } = useParams() as AbrechnungsdefinitionVersionUpdatePageRouteParams;

  const { loading, data } = useAbrechnungsdefinitionTemplateTypeQuery({ variables: { abrechnungsdefinitionId } });
  const abrDefType = data?.getAbrechnungsdefinitionTemplateType.data;

  if (!abrDefType || loading) {
    return <Skeleton active />;
  } else if (isAbrDefTypeWeAbrechnung(abrDefType.value)) {
    return (
      <AndromedaSysSettingsWeAbrechnungsdefinitionVersionUpdatePage
        abrechnungsdefinitionId={abrechnungsdefinitionId}
        abrechnungsdefinitionVersionId={abrechnungsdefinitionVersionId}
      />
    );
  } else if (isAbrDefTypeHeAbrechnung(abrDefType.value)) {
    return (
      <AndromedaSysSettingsHeAbrechnungsdefinitionVersionUpdatePage
        abrechnungsdefinitionId={abrechnungsdefinitionId}
        abrechnungsdefinitionVersionId={abrechnungsdefinitionVersionId}
      />
    );
  } else if (isAbrDefTypeSubAbrechnung(abrDefType.value)) {
    return (
      <AndromedaSysSettingsSubAbrechnungsdefinitionVersionUpdatePage
        abrechnungsdefinitionId={abrechnungsdefinitionId}
        abrechnungsdefinitionVersionId={abrechnungsdefinitionVersionId}
      />
    );
  } else {
    return (
      <AndromedaSysSettingsBkAbrechnungsdefinitionVersionUpdatePage
        abrechnungsdefinitionId={abrechnungsdefinitionId}
        abrechnungsdefinitionVersionId={abrechnungsdefinitionVersionId}
      />
    );
  }
};

export default AndromedaSysSettingsAbrechnungsdefinitionVersionUpdatePage;
