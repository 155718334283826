import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteSubAbrechnungMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  subAbrechnungId: Types.Scalars['ID']['input'];
}>;

export type DeleteSubAbrechnungMutation = {
  deleteSubAbrechnung: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const DeleteSubAbrechnungDocument = gql`
  mutation DeleteSubAbrechnung($objektId: ID!, $subAbrechnungId: ID!) {
    deleteSubAbrechnung(objektId: $objektId, subAbrechnungId: $subAbrechnungId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteSubAbrechnungMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSubAbrechnungMutation, DeleteSubAbrechnungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSubAbrechnungMutation, DeleteSubAbrechnungMutationVariables>(DeleteSubAbrechnungDocument, options);
}
export type DeleteSubAbrechnungMutationHookResult = ReturnType<typeof useDeleteSubAbrechnungMutation>;
export type DeleteSubAbrechnungMutationResult = Apollo.MutationResult<DeleteSubAbrechnungMutation>;
export type DeleteSubAbrechnungMutationOptions = Apollo.BaseMutationOptions<DeleteSubAbrechnungMutation, DeleteSubAbrechnungMutationVariables>;
