import React, { FC } from 'react';
import { Formik } from 'formik';
import { DatePicker, Form } from 'formik-antd';
import {
  ChangePaymentDateFormValues,
  formFields,
  formValuesInitialValues,
  mapQueryParamsToChangePaymentDateBulkAction,
} from './changePaymentDateFormMapper';
import { TPaymentQueryParams } from '../../Listing/Filters/filtersQueryParams';
import { useChangePaymentDatePaymentBulkActionMutation } from '../../gql/Order/PaymentMutations.types';
import { showSuccessMsgOther } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import FormButtons from '../../../../components/Button/FormButtons';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../components/DatePicker/DatePicker_formikAntD';
import { changeDateValidationSchema } from './changeDateValidationSchema';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
  queryParams: TPaymentQueryParams;
  excludeList: string[];
};

const ChangePaymentDateForm: FC<Props> = ({ onSuccess, onCancel, queryParams, excludeList }) => {
  const [runChangePaymentDate] = useChangePaymentDatePaymentBulkActionMutation({
    onCompleted: () => {
      showSuccessMsgOther('Zahlungsdatum geändert');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<ChangePaymentDateFormValues>
      initialValues={formValuesInitialValues}
      validationSchema={changeDateValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        runChangePaymentDate({
          variables: { input: mapQueryParamsToChangePaymentDateBulkAction(values, queryParams, excludeList) },
        }).finally(() => setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithoutColon name={formFields.paymentDate} label="Neues Zahlungsdatum">
            <DatePicker
              size="small"
              id={formFields.paymentDate}
              name={formFields.paymentDate}
              style={{ width: '100%' }}
              format={DATE_DISPLAY_FORMAT_DEFAULT}
            />
          </FormItemWithoutColon>
          <FormButtons onCancel={onCancel} updateMode={false} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default ChangePaymentDateForm;
