import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useMailStatusListQuery } from '../../gql/EmailDeliveryQueries.types';

const EmailDeliveryStatusSelect: FC<SelectProps> = ({ name }) => {
  const { data, loading } = useMailStatusListQuery();
  const statusList = data?.getMailStatusList.data ?? [];

  return (
    <Select size="small" id={name} name={name} loading={loading} placeholder="Status auswählen" mode="multiple" allowClear>
      {statusList.map((item) => (
        <Select.Option key={item.value} value={item.value}>
          {item.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default EmailDeliveryStatusSelect;
