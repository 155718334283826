import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import { UstKategorieVersionFieldsFragmentDoc } from '../../../UstKategorie/Version/gql/UstKategorieVersionFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UstKategorieTemplateVersionListQueryVariables = Types.Exact<{
  ustKategorieId: Types.Scalars['ID']['input'];
}>;

export type UstKategorieTemplateVersionListQuery = {
  getUstKategorieTemplateVersionList: {
    data: Array<{
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      lastUpdateTs: string;
      ustKategorieVersionId: string;
      ustVomAufwand: number;
      validFrom: string;
      versionIndex: number;
      vstKuerzung: boolean;
      historicizedList?: Array<{
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        lastUpdateTs: string;
        ustKategorieVersionId: string;
        ustVomAufwand: number;
        validFrom: string;
        versionIndex: number;
        vstKuerzung: boolean;
        ustKategorieEntryList: Array<{
          additionKonto: number;
          buchungsKZ?: string | null;
          createTs?: string | null;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          rechnungskennzeichen: string;
          steuersatz: number;
          ustKategorieEntryId?: string | null;
          ustKz: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      ustKategorieEntryList: Array<{
        additionKonto: number;
        buchungsKZ?: string | null;
        createTs?: string | null;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        rechnungskennzeichen: string;
        steuersatz: number;
        ustKategorieEntryId?: string | null;
        ustKz: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UstKategorieTemplateVersionQueryVariables = Types.Exact<{
  ustKategorieId: Types.Scalars['ID']['input'];
  ustKategorieVersionId: Types.Scalars['ID']['input'];
}>;

export type UstKategorieTemplateVersionQuery = {
  getUstKategorieTemplateVersion: {
    data: {
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      lastUpdateTs: string;
      ustKategorieVersionId: string;
      ustVomAufwand: number;
      validFrom: string;
      versionIndex: number;
      vstKuerzung: boolean;
      historicizedList?: Array<{
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        lastUpdateTs: string;
        ustKategorieVersionId: string;
        ustVomAufwand: number;
        validFrom: string;
        versionIndex: number;
        vstKuerzung: boolean;
        ustKategorieEntryList: Array<{
          additionKonto: number;
          buchungsKZ?: string | null;
          createTs?: string | null;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          rechnungskennzeichen: string;
          steuersatz: number;
          ustKategorieEntryId?: string | null;
          ustKz: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      ustKategorieEntryList: Array<{
        additionKonto: number;
        buchungsKZ?: string | null;
        createTs?: string | null;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        rechnungskennzeichen: string;
        steuersatz: number;
        ustKategorieEntryId?: string | null;
        ustKz: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kuerzel: string;
          umsatzsteuerkennzeichenId: string;
          status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const UstKategorieTemplateVersionListDocument = gql`
  query UstKategorieTemplateVersionList($ustKategorieId: ID!) {
    getUstKategorieTemplateVersionList(ustKategorieId: $ustKategorieId) {
      data {
        ...UstKategorieVersionFields
        historicizedList {
          ...UstKategorieVersionFields
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${UstKategorieVersionFieldsFragmentDoc}
`;
export function useUstKategorieTemplateVersionListQuery(
  baseOptions: Apollo.QueryHookOptions<UstKategorieTemplateVersionListQuery, UstKategorieTemplateVersionListQueryVariables> &
    ({ variables: UstKategorieTemplateVersionListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstKategorieTemplateVersionListQuery, UstKategorieTemplateVersionListQueryVariables>(
    UstKategorieTemplateVersionListDocument,
    options
  );
}
export function useUstKategorieTemplateVersionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UstKategorieTemplateVersionListQuery, UstKategorieTemplateVersionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstKategorieTemplateVersionListQuery, UstKategorieTemplateVersionListQueryVariables>(
    UstKategorieTemplateVersionListDocument,
    options
  );
}
export function useUstKategorieTemplateVersionListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UstKategorieTemplateVersionListQuery, UstKategorieTemplateVersionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstKategorieTemplateVersionListQuery, UstKategorieTemplateVersionListQueryVariables>(
    UstKategorieTemplateVersionListDocument,
    options
  );
}
export type UstKategorieTemplateVersionListQueryHookResult = ReturnType<typeof useUstKategorieTemplateVersionListQuery>;
export type UstKategorieTemplateVersionListLazyQueryHookResult = ReturnType<typeof useUstKategorieTemplateVersionListLazyQuery>;
export type UstKategorieTemplateVersionListSuspenseQueryHookResult = ReturnType<typeof useUstKategorieTemplateVersionListSuspenseQuery>;
export type UstKategorieTemplateVersionListQueryResult = Apollo.QueryResult<
  UstKategorieTemplateVersionListQuery,
  UstKategorieTemplateVersionListQueryVariables
>;
export const UstKategorieTemplateVersionDocument = gql`
  query UstKategorieTemplateVersion($ustKategorieId: ID!, $ustKategorieVersionId: ID!) {
    getUstKategorieTemplateVersion(ustKategorieId: $ustKategorieId, ustKategorieVersionId: $ustKategorieVersionId) {
      data {
        ...UstKategorieVersionFields
        historicizedList {
          ...UstKategorieVersionFields
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${UstKategorieVersionFieldsFragmentDoc}
`;
export function useUstKategorieTemplateVersionQuery(
  baseOptions: Apollo.QueryHookOptions<UstKategorieTemplateVersionQuery, UstKategorieTemplateVersionQueryVariables> &
    ({ variables: UstKategorieTemplateVersionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstKategorieTemplateVersionQuery, UstKategorieTemplateVersionQueryVariables>(UstKategorieTemplateVersionDocument, options);
}
export function useUstKategorieTemplateVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UstKategorieTemplateVersionQuery, UstKategorieTemplateVersionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstKategorieTemplateVersionQuery, UstKategorieTemplateVersionQueryVariables>(
    UstKategorieTemplateVersionDocument,
    options
  );
}
export function useUstKategorieTemplateVersionSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UstKategorieTemplateVersionQuery, UstKategorieTemplateVersionQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstKategorieTemplateVersionQuery, UstKategorieTemplateVersionQueryVariables>(
    UstKategorieTemplateVersionDocument,
    options
  );
}
export type UstKategorieTemplateVersionQueryHookResult = ReturnType<typeof useUstKategorieTemplateVersionQuery>;
export type UstKategorieTemplateVersionLazyQueryHookResult = ReturnType<typeof useUstKategorieTemplateVersionLazyQuery>;
export type UstKategorieTemplateVersionSuspenseQueryHookResult = ReturnType<typeof useUstKategorieTemplateVersionSuspenseQuery>;
export type UstKategorieTemplateVersionQueryResult = Apollo.QueryResult<UstKategorieTemplateVersionQuery, UstKategorieTemplateVersionQueryVariables>;
