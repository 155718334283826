import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import { generatePathToRechtstraegerDetailsPage } from '../../../Rechtstraeger/rechtstraegerHelper';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { useVorschreibungMailDeliveryQuery } from '../gql/VorschreibungMailDeliveryQueries.types';

type Props = {
  vorschreibungMailDeliveryId: string;
};

const RechtstraegerLink: FC<Props> = ({ vorschreibungMailDeliveryId }) => {
  const { data, loading } = useVorschreibungMailDeliveryQuery({ variables: { vorschreibungMailDeliveryId } });
  const vorschreibungMailDelivery = data?.getVorschreibungMailDelivery.data;

  return (
    <Skeleton paragraph={false} active loading={loading}>
      {vorschreibungMailDelivery && (
        <DataWithShortenedText text={vorschreibungMailDelivery.recipient.kurzBezeichnung} maxTextLength={25}>
          {(shortenedText) => (
            <Link to={generatePathToRechtstraegerDetailsPage(vorschreibungMailDelivery.recipient.rechtstraegerId)}>{shortenedText}</Link>
          )}
        </DataWithShortenedText>
      )}
    </Skeleton>
  );
};

export default RechtstraegerLink;
