import React from 'react';
import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { VerarbeitungVstKuerzungDetailsPageRouteParams } from './routes';
import { useVorsteuerkuerzungGenerierlaufQuery } from '../../../features/Verarbeitung/gql/VorsteuerkuerzungGenerierlauf/VorsteuerkuerzungGenerierlaufQueries.types';
import menuListVstKuerzung from './menuListVstKuerzung';
import { VorsteuerkuerzungGenerierlauf } from '../../../types';
import { useRestartVorsteuerkuerzungGenerierlaufMutation } from '../../../features/Verarbeitung/gql/VorsteuerkuerzungGenerierlauf/VorsteuerkuerzungGenerierlaufMutations.types';

const VstKuerzungDetailsPage = () => {
  const { generierlaufId } = useParams() as VerarbeitungVstKuerzungDetailsPageRouteParams;

  const { data, refetch } = useVorsteuerkuerzungGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getVorsteuerkuerzungGenerierlauf.data;

  const [runRestart] = useRestartVorsteuerkuerzungGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<VorsteuerkuerzungGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.VorsteuerkuerzungErstellung}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.VorsteuerkuerzungErstellung}
          onGenerierlaufRestart={runRestart}
        />
      )}
      sidebarMenuList={(generierlauf) => menuListVstKuerzung(generierlauf)}
    />
  );
};

export default VstKuerzungDetailsPage;
