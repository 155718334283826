import React from 'react';
import { Row } from 'antd';
import PDFPageCol from './PDFPageCol';
import FinancialPlanTopTemplate from './templates/financialPlan/Top/FinancialPlanTopTemplate';
import financialPlanTopMocks from './mocks/financialPlanTopMocks';

const PDFPageFinancialPlanTops = () => (
  <Row>
    <PDFPageCol pdfTemplate={<FinancialPlanTopTemplate data={financialPlanTopMocks} />} />
  </Row>
);

export default PDFPageFinancialPlanTops;
