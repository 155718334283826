import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KontoTypeListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type KontoTypeListQuery = {
  getKontoTypeList: {
    data: Array<{ text: string; value: Types.KontoType }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const KontoTypeListDocument = gql`
  query KontoTypeList {
    getKontoTypeList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useKontoTypeListQuery(baseOptions?: Apollo.QueryHookOptions<KontoTypeListQuery, KontoTypeListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontoTypeListQuery, KontoTypeListQueryVariables>(KontoTypeListDocument, options);
}
export function useKontoTypeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KontoTypeListQuery, KontoTypeListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontoTypeListQuery, KontoTypeListQueryVariables>(KontoTypeListDocument, options);
}
export function useKontoTypeListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KontoTypeListQuery, KontoTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontoTypeListQuery, KontoTypeListQueryVariables>(KontoTypeListDocument, options);
}
export type KontoTypeListQueryHookResult = ReturnType<typeof useKontoTypeListQuery>;
export type KontoTypeListLazyQueryHookResult = ReturnType<typeof useKontoTypeListLazyQuery>;
export type KontoTypeListSuspenseQueryHookResult = ReturnType<typeof useKontoTypeListSuspenseQuery>;
export type KontoTypeListQueryResult = Apollo.QueryResult<KontoTypeListQuery, KontoTypeListQueryVariables>;
