import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { useRechtstraegerKundennummerListQuery } from '../../gql/RechtsTraegerQueries.types';

type Props = Omit<SelectProps, 'id' | 'size' | 'allowClear' | 'showSearch' | 'filterOption'>;

const RechtstraegerKundennummerListFormSelect: FC<Props> = ({ name, ...restProps }) => {
  const { data, loading } = useRechtstraegerKundennummerListQuery();

  const list = data?.getRechtstraegerKundennummerList.data || [];

  return (
    <Select
      placeholder=""
      size="middle"
      style={{ minWidth: '190px' }}
      {...restProps}
      name={name}
      id={name}
      allowClear
      showSearch
      loading={loading}
      filterOption={selectFilterOption}
    >
      {list.map((kundennummer) => (
        <Select.Option key={kundennummer} value={kundennummer}>
          {kundennummer}
        </Select.Option>
      ))}
    </Select>
  );
};

export default RechtstraegerKundennummerListFormSelect;
