import React from 'react';
import { Space, Typography } from 'antd';
import { MitarbeiterTooltip } from '../../../components/Card';
import MahndefinitionTableActions from './MahndefinitionTableActions';
import DataWithStatus from '../../../components/Helpers/DataWithStatus';
import TemplateIncompleteWarning from '../../Abrechnungsdefinition/shared/TemplateIncompleteWarning';
import StatusBadge from '../../../components/Status/StatusBadge';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';
import FirmendatenKurzBezeichnung from '../../shared/FirmendatenKurzBezeichnung';
import { MahndefinitionListEntryWithStatus } from '../mahndefinitonUriPaths';

const mahndefinitionTableColumns: (onActionSuccess: () => void) => TableWithColSelectorColumnProps<MahndefinitionListEntryWithStatus>[] = (
  onActionSuccess
) => [
  {
    title: 'Bezeichnung',
    defaultSelected: true,
    render: (text, record) => (
      <Space>
        <DataWithStatus status={record.status} text={record.bezeichnung} showBadgeDot={false} showStatusDescription={false} />
        <TemplateIncompleteWarning textBausteineBefuellt={record.textBausteineBefuellt} />
      </Space>
    ),
  },
  {
    title: 'Firma',
    defaultSelected: true,
    render: (text, record) =>
      record.firmendatenId ? <FirmendatenKurzBezeichnung firmendatenId={record.firmendatenId} /> : <Typography.Text>-</Typography.Text>,
  },
  {
    title: 'Status',
    defaultSelected: true,
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },
  {
    title: 'Erstellt am',
    render: (text, record) => (
      <DataWithStatus status={record.status} text="" showBadgeDot={false} showStatusDescription={false}>
        {() => <FormattedDateTime createdTs={record.createTs} />}
      </DataWithStatus>
    ),
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => <MahndefinitionTableActions mahndefinition={record} onActionSuccess={onActionSuccess} />,
  },
];

export default mahndefinitionTableColumns;
