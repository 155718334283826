import React, { FC } from 'react';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import { useFDAuthorized } from '../../../../../../shared/Auth/useAuthorized';
import FormButtons from '../../../../../../components/Button/FormButtons';
import InfoFeldUpload from '../../../../../shared/InfoFeld/InfoFeldUpload';
import { personeninfosCreateFormFields, personeninfosCreateFormInitialValues, PersoneninfosCreateFormValues } from './personeninfosCreateFormMapper';
import PersoneninfosDatenTab from '../Tabs/PersoneninfosDatenTab';
import { RechtstraegerInfoFeldType } from '../../../../../../types';
import { handleCreateSubmit } from '../../shared/handleSubmit';
import { personeninfosValidationSchema } from '../shared/personeninfosValidationSchema';

type Props = {
  rechtstraegerId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

const PersoneninfosCreateForm: FC<Props> = ({ rechtstraegerId, onCancel, onSuccess }) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  return (
    <Formik<PersoneninfosCreateFormValues>
      initialValues={personeninfosCreateFormInitialValues}
      validationSchema={personeninfosValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleCreateSubmit(firmendatenId, rechtstraegerId, RechtstraegerInfoFeldType.Personeninfos, values, setSubmitting, onSuccess);
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <PersoneninfosDatenTab formFields={personeninfosCreateFormFields} />
          <InfoFeldUpload
            name={personeninfosCreateFormFields.documentList}
            formikProps={formikProps}
            documentList={formikProps.values.documentList}
          />
          <FormButtons updateMode={false} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default PersoneninfosCreateForm;
