import { mapToQueryString, QueryParams } from '../../../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../verarbeitungApiPaths';
import { GenerierlaufEntryExitCode, ValuePreservationType } from '../../../../types';

enum VertragVposIndexedValueVerarbeitungQueryParamKey {
  OBJEKT_ID = 'objektId',
  BESTANDSEINHEIT_ID_LIST = 'bestandseinheitIdList',
  VERTRAG_ID = 'vertragId',
  VALUE_PRESERVATION_TYPE = 'valuePreservationType',
  INDEX_SERIES_ID = 'indexSeriesId',
  EXIT_CODE_LIST = 'exitCodeList',
}

export type TVertagVposIndexedValueVerarbeitungQueryParams = QueryParams<{
  objektId: string;
  bestandseinheitIdList: string[];
  vertragId: string;
  valuePreservationType: ValuePreservationType;
  indexSeriesId: string;
  exitCodeList: GenerierlaufEntryExitCode[];
}>;

export const updateQueryParams = (
  verarbeitungType: string,
  generierlaufId: string,
  navigate: (path: string) => void,
  filters: TVertagVposIndexedValueVerarbeitungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) =>
  navigate(
    generatePathToVertragVposIndexedValueVerarbeitungPage(
      verarbeitungType,
      generierlaufId,
      filters.objektId,
      filters.bestandseinheitIdList,
      filters.vertragId,
      filters.valuePreservationType,
      filters.indexSeriesId,
      filters.exitCodeList,
      paginationParams
    )
  );

const generatePathToVertragVposIndexedValueVerarbeitungPage = (
  verarbeitungType: string,
  generierlaufId: string,
  objektId?: string,
  bestandseinheitIdList?: string[],
  vertragId?: string,
  valuePreservationType?: ValuePreservationType,
  indexSeriesId?: string,
  exitCodeList?: GenerierlaufEntryExitCode[],
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [VertragVposIndexedValueVerarbeitungQueryParamKey.OBJEKT_ID]: objektId,
    [VertragVposIndexedValueVerarbeitungQueryParamKey.BESTANDSEINHEIT_ID_LIST]: bestandseinheitIdList,
    [VertragVposIndexedValueVerarbeitungQueryParamKey.VERTRAG_ID]: vertragId,
    [VertragVposIndexedValueVerarbeitungQueryParamKey.VALUE_PRESERVATION_TYPE]: valuePreservationType,
    [VertragVposIndexedValueVerarbeitungQueryParamKey.INDEX_SERIES_ID]: indexSeriesId,
    [VertragVposIndexedValueVerarbeitungQueryParamKey.EXIT_CODE_LIST]: exitCodeList,
    ...paginationParams,
  })}`;
