import React, { FC } from 'react';
import { Empty, Table } from 'antd';
import repFondsTemplateKontenZuweisungTableColumns from './repFondsTemplateKontenZuweisungTableColumns';
import { RepFondsEinstellung, RepFondsEinstellungKontenZuweisung } from '../../../../../types';

type Props = {
  repFondsEinstellung: RepFondsEinstellung;
  isInCardContent?: boolean;
};

const RepFondsTemplateKontenZuweisungTable: FC<Props> = ({ repFondsEinstellung, isInCardContent }) => (
  <Table<RepFondsEinstellungKontenZuweisung>
    dataSource={repFondsEinstellung.kontenZuweisungList}
    columns={repFondsTemplateKontenZuweisungTableColumns(isInCardContent)}
    locale={{
      emptyText: <Empty description={<span>Keine Reparaturfonds-Kontenzuweisungen vorhanden</span>} />,
    }}
    pagination={false}
    size={isInCardContent ? 'small' : 'large'}
  />
);

export default RepFondsTemplateKontenZuweisungTable;
