import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { DocumentRowProps, findLabelValueByLabelId } from './util';
import { ValueTitle } from '../../../../components/Helpers/ValueTitle';
import { DocumentInfo, DocumentRowContextProvider } from './documentRowContext';
import AvatarBasedOnFileInfoTypeWithTooltip from '../../../../components/Avatar/BasedOnFileInfoType/AvatarBasedOnFileInfoTypeWithTooltip';
import { useBudgetingBaseQuery, useTopBudgetSumNextYearQuery } from '../../../Budgeting/gql/BudgetingQueries.types';
import { EuroAmount } from '../../../../components/Number';
import { pathsToBudgetingDetailsPage } from '../../../Budgeting/budgetingUriPaths';
import { generatePathToObjektDetailsPage, pathsToObjektDetailsPage } from '../../../Objekt/objektUriPaths';
import OutdatedData from '../../../../components/Helpers/OutdatedData';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { generatePathToBestandseinheitDetailsPage } from '../../../Bestandseinheit/bestandseinheitUriPaths';
import TopFinancialPlanActions from '../../actions/TopFinancialPlanActions';

const RowTopFinancialPlan: FC<DocumentRowProps> = ({ record, rechnungTyp, invoiceIssuerId, invoiceRecipientId, ...restProps }) => {
  const budgetingId = findLabelValueByLabelId(record.labelList, 'budgetingId');
  const budgetingResultId = findLabelValueByLabelId(record.labelList, 'budgetingResultId');
  const budgetingResultVertragId = findLabelValueByLabelId(record.labelList, 'budgetingResultVertragId');
  const objektId = findLabelValueByLabelId(record.labelList, 'objektId');
  const bestandseinheitId = findLabelValueByLabelId(record.labelList, 'bestandseinheitId');
  const beKurzbezeichnung = findLabelValueByLabelId(record.labelList, 'beKurzbezeichnung');
  const vertragId = findLabelValueByLabelId(record.labelList, 'vertragId');
  const isOutdated = findLabelValueByLabelId(record.labelList, 'outdated');

  const { loading, data } = useBudgetingBaseQuery({
    variables: { budgetingId, objektId },
  });

  const { loading: topBudgetSumNextYearLoading, data: topBudgetSumNextYearData } = useTopBudgetSumNextYearQuery({
    variables: { objektId, budgetingResultId, budgetingResultVertragId },
  });

  const budgeting = data?.getBudgetingBase.data;
  const topBudgetSumNextYear = topBudgetSumNextYearData?.getTopBudgetSumNextYear.data;

  const documentInfo: DocumentInfo | undefined =
    budgeting && topBudgetSumNextYear
      ? {
          column1: (
            <OutdatedData isOutdated={isOutdated} isAvatar>
              <AvatarBasedOnFileInfoTypeWithTooltip fileType={record.type} />
            </OutdatedData>
          ),
          column2: (
            <OutdatedData isOutdated={isOutdated}>
              <ValueTitle
                small
                title="Top-Wirtschaftsplan"
                content={
                  <DataWithShortenedText text={budgeting.bezeichnung} maxTextLength={25}>
                    {(shortenedText) => (
                      <Link target="_blank" to={pathsToBudgetingDetailsPage(objektId, budgetingId).detailsTab}>
                        {shortenedText}
                      </Link>
                    )}
                  </DataWithShortenedText>
                }
              />
            </OutdatedData>
          ),
          column3: (
            <OutdatedData isOutdated={isOutdated}>
              <ValueTitle
                small
                title="Okjekt"
                content={
                  <DataWithShortenedText text={budgeting.objekt.kurzBezeichnung} maxTextLength={25}>
                    {(shortenedText) => (
                      <Link target="_blank" to={generatePathToObjektDetailsPage(objektId)}>
                        {shortenedText}
                      </Link>
                    )}
                  </DataWithShortenedText>
                }
              />
            </OutdatedData>
          ),
          column4: (
            <OutdatedData isOutdated={isOutdated}>
              <ValueTitle
                small
                title="Top"
                content={
                  bestandseinheitId ? (
                    <DataWithShortenedText text={beKurzbezeichnung} maxTextLength={25}>
                      {(shortenedText) => (
                        <Link target="_blank" to={generatePathToBestandseinheitDetailsPage(objektId, bestandseinheitId)}>
                          {shortenedText}
                        </Link>
                      )}
                    </DataWithShortenedText>
                  ) : (
                    <Link target="_blank" to={pathsToObjektDetailsPage(objektId).topTab}>
                      {beKurzbezeichnung}
                    </Link>
                  )
                }
              />
            </OutdatedData>
          ),
          column5: (
            <OutdatedData isOutdated={isOutdated}>
              <ValueTitle small title="Budgetierungsjahr" content={<Typography.Text>{budgeting.currentYear}</Typography.Text>} />
            </OutdatedData>
          ),
          column6: (
            <OutdatedData isOutdated={isOutdated}>
              <ValueTitle small title="Jahresbudget Summe" content={<EuroAmount value={topBudgetSumNextYear} />} />
            </OutdatedData>
          ),
          column7: (
            <OutdatedData isOutdated={isOutdated}>
              <ValueTitle small title="-" content="-" />
            </OutdatedData>
          ),
          column8: (
            <OutdatedData isOutdated={isOutdated}>
              <TopFinancialPlanActions record={record} budgeting={budgeting} bestandseinheitId={bestandseinheitId} vertragId={vertragId} />
            </OutdatedData>
          ),
        }
      : undefined;

  return (
    <DocumentRowContextProvider value={{ isLoading: loading || topBudgetSumNextYearLoading, documentInfo }}>
      <tr {...restProps} />
    </DocumentRowContextProvider>
  );
};

export default RowTopFinancialPlan;
