import { FormikProps } from 'formik';

export function onFilterSelectChange<T>(
  formikProps: FormikProps<T>,
  selectedFilterList: string[],
  filterList: string[],
  setSelectedFilterList: (filterList: string[]) => void
) {
  const diffFilterList = selectedFilterList.filter((x) => !filterList.includes(x));
  if (diffFilterList.length > 0) {
    diffFilterList.forEach((filter) => {
      formikProps.setFieldValue(filter, undefined);
    });
  }
  setSelectedFilterList(filterList);
}

export function getDefaultFilterList<T>(formFields: Record<string, string>, formikProps: FormikProps<T>): string[] {
  return Object.keys(formFields).filter((key) => {
    const values = formikProps.values[key as keyof T];
    return Array.isArray(values) ? values.length > 0 : !!values;
  });
}
