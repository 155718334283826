import React, { FC } from 'react';
import { Formik } from 'formik';
import { Avatar, List, Skeleton, Typography } from 'antd';
import { Form, FormItem, Radio } from 'formik-antd';
import { RechtstraegerListEntry, RechtstraegerType } from '../../../../types';
import { useRechtstraegerListQuery } from '../../../Rechtstraeger/gql/RechtsTraegerQueries.types';
import FormButtons from '../../../../components/Button/FormButtons';
import RechtsTraegerActions from '../../../Rechtstraeger/Listing/RechtsTraegerActions';
import { StyledRadio } from './Styled/Radio.style';
import { concatFirstNameLetters } from '../../../../helpers/nameHelper';
import {
  useCreateFirmaContactMutation,
  useCreateNatuerlichePersonContactMutation,
  useCreatePersonenGemeinschaftContactMutation,
} from '../../../Rechtstraeger/AddressesAndContacts/gql/AddressesAndContactsMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../components/message';
import {
  ticketParticipantAssignFormFields,
  ticketParticipantAssignFormInitialValues,
  TicketParticipantAssignFormValues,
} from './ticketParticipantAssignFormMapper';
import { ticketParticipantAssignFormValidationSchema } from './ticketParticipantAssignFormValidationSchema';
import { useTicketParticipantMutation } from '../../gql/TicketMutations.types';

type Props = {
  ticketId: string;
  participantIds: string[];
  emailAddress?: string | null;
  onSuccess: (rechtstraegerId: string) => void;
  onCancel: () => void;
};

const TicketParticipantAssignForm: FC<Props> = ({ ticketId, participantIds, emailAddress, onSuccess, onCancel }) => {
  const { data, refetch } = useRechtstraegerListQuery({
    variables: {
      emailAddress,
    },
    skip: !emailAddress,
  });

  const rechtstraegerList = data?.getRechtstraegerList?.data.contentList;

  const [runAssign, { loading: loadingAssign }] = useTicketParticipantMutation({
    onCompleted: () => {
      showSuccessMsgUpdate('Die Beteiligten der Aufgabe');
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const handleCreateContactSuccess = () => {
    showSuccessMsgCreate('Kontakt');
    refetch();
  };

  const [runCreateNatuerlichePersonContact, { loading: loadingCreateNPContact }] = useCreateNatuerlichePersonContactMutation({
    onCompleted: handleCreateContactSuccess,
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runCreatePersonenGemeinschaftContact, { loading: loadingCreatePGContact }] = useCreatePersonenGemeinschaftContactMutation({
    onCompleted: handleCreateContactSuccess,
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runCreateFirmaContact, { loading: loadingCreateFirmaContact }] = useCreateFirmaContactMutation({
    onCompleted: handleCreateContactSuccess,
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const isLoading = loadingCreateNPContact || loadingCreatePGContact || loadingCreateFirmaContact;

  const handleCreateRechtstraegerSuccess = (rechtstraegerId: string, kurzBezeichnung: string, type: { value: RechtstraegerType }) => {
    if (!emailAddress) return;

    const input = {
      type: 'EMAIL',
      wertList: [emailAddress],
    };

    switch (type.value) {
      case RechtstraegerType.NatuerlichePerson:
        runCreateNatuerlichePersonContact({
          variables: {
            natuerlichePersonId: rechtstraegerId,
            input,
          },
        });
        break;

      case RechtstraegerType.PersonenGemeinschaft:
        runCreatePersonenGemeinschaftContact({
          variables: {
            personenGemeinschaftId: rechtstraegerId,
            input,
          },
        });
        break;

      case RechtstraegerType.Firma:
        runCreateFirmaContact({
          variables: {
            firmaId: rechtstraegerId,
            input,
          },
        });
        break;
    }
  };

  return (
    <Skeleton loading={!rechtstraegerList} title active paragraph={{ rows: 4 }}>
      <RechtsTraegerActions onSuccess={handleCreateRechtstraegerSuccess} />
      <Typography.Title level={5}>Firma/Person auswählen</Typography.Title>
      <Formik<TicketParticipantAssignFormValues>
        initialValues={ticketParticipantAssignFormInitialValues}
        validationSchema={ticketParticipantAssignFormValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          runAssign({
            variables: {
              ticketId,
              input: {
                participantIdList: participantIds,
                rechtstraegerId: values.rechtstraegerId,
              },
            },
          })
            .then(() => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onSuccess(values.rechtstraegerId!);
            })
            .finally(() => {
              setSubmitting(false);
            })
            .catch(() => {
              setSubmitting(false);
            });
        }}
      >
        {(formikProps) => {
          return (
            <Form layout="vertical">
              <FormItem name={ticketParticipantAssignFormFields.rechtstraegerId}>
                <Radio.Group name={ticketParticipantAssignFormFields.rechtstraegerId} style={{ width: '100%' }} block>
                  <List<RechtstraegerListEntry>
                    style={{ width: '100%' }}
                    itemLayout="horizontal"
                    dataSource={rechtstraegerList}
                    renderItem={(rechtstreager) => (
                      <List.Item>
                        <>
                          <StyledRadio
                            value={rechtstreager.rechtstraegerId}
                            style={{ width: '100%' }}
                            className={formikProps.errors.rechtstraegerId ? 'ant-form-item-has-error' : ''}
                          >
                            <List.Item.Meta
                              avatar={<Avatar>{concatFirstNameLetters(rechtstreager?.kurzBezeichnung)}</Avatar>}
                              title={rechtstreager.kurzBezeichnung}
                              description={emailAddress}
                            />
                          </StyledRadio>
                        </>
                      </List.Item>
                    )}
                  />
                </Radio.Group>
              </FormItem>
              <FormButtons updateMode={false} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting || isLoading} isOkLoading={loadingAssign} />
            </Form>
          );
        }}
      </Formik>
    </Skeleton>
  );
};

export default TicketParticipantAssignForm;
