import { message } from 'antd';
import Fetcher from '../../../../../../helpers/fetcher';
import { processAndReturnError, processResponse } from '../../../../../../helpers/APIUtils';
import { showSuccessMsgOther } from '../../../../../../components/message';
import { mapErrorToErrorMessage } from '../../../../../../helpers/errorAndWarningHelper';
import { ReportLogosFormValues } from './reportLogosFormMapper';

const saveReportLogosSubmit = (
  firmendatenId: string,
  values: ReportLogosFormValues,
  onSuccess: () => void,
  setSubmitting: (isSubmitting: boolean) => void
) => {
  const formData = new FormData();
  formData.append(
    'reportLogos',
    new Blob(
      [
        JSON.stringify({
          data: {
            logoGrossFileId: values.tempLogoGrossFileId ?? values.logoGrossFileId,
            logoKleinFileId: values.tempLogoKleinFileId ?? values.logoKleinFileId,
            logoGrossAlignment: values.logoGrossAlignment,
            logoKleinAlignment: values.logoKleinAlignment,
          },
        }),
      ],
      { type: 'application/json' }
    ),
    'payload'
  );

  if (values.logoGross && !values.tempLogoGrossFileId) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    formData.append('logoGross', values.logoGross.file!, values.logoGross.filename);
  }
  if (values.logoKlein && !values.tempLogoKleinFileId) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    formData.append('logoKlein', values.logoKlein.file!, values.logoKlein.filename);
  }

  setSubmitting(true);
  Fetcher.getInstance()
    .fetch(`/api/firmendaten/${firmendatenId}/report-logos`, {
      method: 'PUT',
      body: formData,
    })
    .then(processResponse)
    .then(() => {
      showSuccessMsgOther(`Report Logo wurde erfolgreich aktualisiert`);
      setSubmitting(false);
      onSuccess();
    })
    .catch(processAndReturnError)
    .catch((error) => {
      setSubmitting(false);
      return message.error({
        content: `Report Logo konnte nicht erstellt werden: ${mapErrorToErrorMessage(error)}`,
      });
    });
};

export default saveReportLogosSubmit;
