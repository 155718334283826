import { FormFields } from '../../../../../../helpers/formikHelper';
import { RepFondsErloesKontenZuweisung, RepFondsErloesKontenZuweisungInput } from '../../../../../../types';

export type RechnungsAusstellerRepFondsZuweisungFormValues = {
  erloesKontoIdList: string[];
  fibuBankAccountId: string;
};

export const mapRechnungsAusstellerRepFondsToFormValues = (
  values: RepFondsErloesKontenZuweisung
): RechnungsAusstellerRepFondsZuweisungFormValues => ({
  erloesKontoIdList: values.erloesKontoList.map((konto) => konto.kontoId),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  fibuBankAccountId: values.fibuBankAccount.fibuKontoId!,
});

export const rechnungsAusstellerRepFondsZuweisungFormValuesDefault: RechnungsAusstellerRepFondsZuweisungFormValues = {
  erloesKontoIdList: [],
  fibuBankAccountId: '',
};

export const rechnungsAusstellerRepFondsZuweisungFormFields: FormFields<RechnungsAusstellerRepFondsZuweisungFormValues> = {
  erloesKontoIdList: 'erloesKontoIdList',
  fibuBankAccountId: 'fibuBankAccountId',
};

export const mapFormValuesToRepFonds = (values: RechnungsAusstellerRepFondsZuweisungFormValues): RepFondsErloesKontenZuweisungInput => ({
  erloesKontoIdList: values.erloesKontoIdList,
  fibuBankAccountId: values.fibuBankAccountId,
});
