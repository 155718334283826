import { FC } from 'react';
import styled from 'styled-components';
import { List, ListProps, Space } from 'antd';
import { Personenbezug } from '../../../../../../types';

const PersonenbezugList: FC<ListProps<Personenbezug>> = ({ children, ...props }) => <List<Personenbezug> {...props}>{children}</List>;

export const StyledPersonenbezugList = styled(PersonenbezugList)`
  .ant-list-items {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const StyledPersonenbezugSpace = styled(Space)`
  width: 100%;
  align-items: baseline;
  .ant-space-item {
    text-align: left;
    &:first-child {
      width: 130px;
    }
    &:last-child {
      width: 100%;
    }
  }
`;

export const StyledPersonenbezugListItem = styled(List.Item)`
  display: flex;
  align-items: start;
  width: 420px;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  border: 1px solid #d9d9d9 !important;
  margin: 4px;
  padding: 0 !important;
  border-radius: 4px;
`;
