import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBeVertragInfoFeldPostItMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vertragInfoFeldId: Types.Scalars['ID']['input'];
  input: Types.PostItInput;
}>;

export type CreateBeVertragInfoFeldPostItMutation = {
  createBeVertragInfoFeldPostIt: {
    data: { postItId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteBeVertragInfoFeldMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  infoFeldId: Types.Scalars['ID']['input'];
}>;

export type DeleteBeVertragInfoFeldMutation = {
  deleteBeVertragInfoFeld: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type UnarchiveBeVertragInfoFeldMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  infoFeldId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveBeVertragInfoFeldMutation = {
  actionUnarchiveBeVertragInfoFeld: {
    data: { vertragInfoFeldId: string } | { vertragInfoFeldId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ArchiveBeVertragInfoFeldMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  infoFeldId: Types.Scalars['ID']['input'];
}>;

export type ArchiveBeVertragInfoFeldMutation = {
  actionArchiveBeVertragInfoFeld: {
    data: { vertragInfoFeldId: string } | { vertragInfoFeldId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateBeVertragInfoFeldPostItDocument = gql`
  mutation CreateBeVertragInfoFeldPostIt($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!, $vertragInfoFeldId: ID!, $input: PostItInput!) {
    createBeVertragInfoFeldPostIt(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vertragInfoFeldId: $vertragInfoFeldId
      input: $input
    ) {
      data {
        postItId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateBeVertragInfoFeldPostItMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBeVertragInfoFeldPostItMutation, CreateBeVertragInfoFeldPostItMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBeVertragInfoFeldPostItMutation, CreateBeVertragInfoFeldPostItMutationVariables>(
    CreateBeVertragInfoFeldPostItDocument,
    options
  );
}
export type CreateBeVertragInfoFeldPostItMutationHookResult = ReturnType<typeof useCreateBeVertragInfoFeldPostItMutation>;
export type CreateBeVertragInfoFeldPostItMutationResult = Apollo.MutationResult<CreateBeVertragInfoFeldPostItMutation>;
export type CreateBeVertragInfoFeldPostItMutationOptions = Apollo.BaseMutationOptions<
  CreateBeVertragInfoFeldPostItMutation,
  CreateBeVertragInfoFeldPostItMutationVariables
>;
export const DeleteBeVertragInfoFeldDocument = gql`
  mutation DeleteBeVertragInfoFeld($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!, $infoFeldId: ID!) {
    deleteBeVertragInfoFeld(objektId: $objektId, bestandseinheitId: $bestandseinheitId, vertragId: $vertragId, infoFeldId: $infoFeldId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteBeVertragInfoFeldMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBeVertragInfoFeldMutation, DeleteBeVertragInfoFeldMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBeVertragInfoFeldMutation, DeleteBeVertragInfoFeldMutationVariables>(DeleteBeVertragInfoFeldDocument, options);
}
export type DeleteBeVertragInfoFeldMutationHookResult = ReturnType<typeof useDeleteBeVertragInfoFeldMutation>;
export type DeleteBeVertragInfoFeldMutationResult = Apollo.MutationResult<DeleteBeVertragInfoFeldMutation>;
export type DeleteBeVertragInfoFeldMutationOptions = Apollo.BaseMutationOptions<
  DeleteBeVertragInfoFeldMutation,
  DeleteBeVertragInfoFeldMutationVariables
>;
export const UnarchiveBeVertragInfoFeldDocument = gql`
  mutation UnarchiveBeVertragInfoFeld($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!, $infoFeldId: ID!) {
    actionUnarchiveBeVertragInfoFeld(objektId: $objektId, bestandseinheitId: $bestandseinheitId, vertragId: $vertragId, infoFeldId: $infoFeldId) {
      data {
        vertragInfoFeldId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveBeVertragInfoFeldMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveBeVertragInfoFeldMutation, UnarchiveBeVertragInfoFeldMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveBeVertragInfoFeldMutation, UnarchiveBeVertragInfoFeldMutationVariables>(
    UnarchiveBeVertragInfoFeldDocument,
    options
  );
}
export type UnarchiveBeVertragInfoFeldMutationHookResult = ReturnType<typeof useUnarchiveBeVertragInfoFeldMutation>;
export type UnarchiveBeVertragInfoFeldMutationResult = Apollo.MutationResult<UnarchiveBeVertragInfoFeldMutation>;
export type UnarchiveBeVertragInfoFeldMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveBeVertragInfoFeldMutation,
  UnarchiveBeVertragInfoFeldMutationVariables
>;
export const ArchiveBeVertragInfoFeldDocument = gql`
  mutation ArchiveBeVertragInfoFeld($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!, $infoFeldId: ID!) {
    actionArchiveBeVertragInfoFeld(objektId: $objektId, bestandseinheitId: $bestandseinheitId, vertragId: $vertragId, infoFeldId: $infoFeldId) {
      data {
        vertragInfoFeldId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveBeVertragInfoFeldMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveBeVertragInfoFeldMutation, ArchiveBeVertragInfoFeldMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveBeVertragInfoFeldMutation, ArchiveBeVertragInfoFeldMutationVariables>(ArchiveBeVertragInfoFeldDocument, options);
}
export type ArchiveBeVertragInfoFeldMutationHookResult = ReturnType<typeof useArchiveBeVertragInfoFeldMutation>;
export type ArchiveBeVertragInfoFeldMutationResult = Apollo.MutationResult<ArchiveBeVertragInfoFeldMutation>;
export type ArchiveBeVertragInfoFeldMutationOptions = Apollo.BaseMutationOptions<
  ArchiveBeVertragInfoFeldMutation,
  ArchiveBeVertragInfoFeldMutationVariables
>;
