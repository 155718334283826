import { FormFields, mapFormDateValueToDateString } from '../../../helpers/formikHelper';
import { FibuBuchungType, OffenePostenKontoType, QueryGetOffenePostenListArgs, Vertragsart } from '../../../types';
import { TOffenePostenQueryParams } from '../offenePostenUriPaths';
import { dayjsCustom } from '../../../helpers/dayjsCustom';

export type OffenePostenListingFiltersFormValues = {
  belegDatumFrom: string | null;
  belegDatumTo: string | null;
  belegnummer?: string | null;
  buchungsType?: FibuBuchungType | null;
  buchungsdatumFrom: string | null;
  buchungsdatumTo: string | null;
  buchungskreisId: string;
  createTsFrom: string | null;
  createTsTo: string | null;
  createdByMitarbeiterIdList: string[] | null;
  dueDateFrom: string | null;
  dueDateTo: string | null;
  fibuKontoId: string | null;
  kontoType: OffenePostenKontoType[];
  letzteMahnstufe: number | null;
  objektId: string | null;
  offenerBetragFrom: number | null;
  offenerBetragTo: number | null;
  vertragsartList?: Vertragsart[] | null;
  vertragspartnerId: string | null;
};

export const offenePostenListingFiltersFormFields: FormFields<OffenePostenListingFiltersFormValues> = {
  belegDatumFrom: 'belegDatumFrom',
  belegDatumTo: 'belegDatumTo',
  belegnummer: 'belegnummer',
  buchungsType: 'buchungsType',
  buchungsdatumFrom: 'buchungsdatumFrom',
  buchungsdatumTo: 'buchungsdatumTo',
  buchungskreisId: 'buchungskreisId',
  createTsFrom: 'createTsFrom',
  createTsTo: 'createTsTo',
  createdByMitarbeiterIdList: 'createdByMitarbeiterIdList',
  dueDateFrom: 'dueDateFrom',
  dueDateTo: 'dueDateTo',
  fibuKontoId: 'fibuKontoId',
  kontoType: 'kontoType',
  letzteMahnstufe: 'letzteMahnstufe',
  objektId: 'objektId',
  offenerBetragFrom: 'offenerBetragFrom',
  offenerBetragTo: 'offenerBetragTo',
  vertragsartList: 'vertragsartList',
  vertragspartnerId: 'vertragspartnerId',
};

export const mapQueryParamsToOffenePostenQueryVariables = (
  queryParams: TOffenePostenQueryParams,
  showAusgeziffert: boolean
): QueryGetOffenePostenListArgs => {
  const { belegnummer, dueDateTo: dueDateToFilter, kontoType, fibuKontoId, ...restQueryParams } = queryParams;
  const propertyNamesOfFiltersLength = Object.getOwnPropertyNames(queryParams).length;
  const isDueDateToAvailable = dueDateToFilter ?? undefined;
  const dueDateTo = propertyNamesOfFiltersLength === 0 ? dayjsCustom().format('YYYY-MM-DD') : isDueDateToAvailable;

  return {
    includeAusgeziffert: showAusgeziffert,
    dueDateTo,
    kontoType: queryParams.kontoType?.length === 1 ? queryParams.kontoType[0] : undefined,
    belegnummer: queryParams.belegnummer ? String(queryParams.belegnummer) : undefined,
    kontoId: fibuKontoId,
    ...restQueryParams,
  };
};

export const mapQueryParamsToFormValues = (queryParams: TOffenePostenQueryParams): OffenePostenListingFiltersFormValues => ({
  belegDatumFrom: queryParams.belegDatumFrom ?? null,
  belegDatumTo: queryParams.belegDatumTo ?? null,
  belegnummer: queryParams.belegnummer ? String(queryParams.belegnummer) : '',
  buchungsType: queryParams.buchungsType,
  buchungsdatumFrom: queryParams.buchungsdatumFrom ?? null,
  buchungsdatumTo: queryParams.buchungsdatumTo ?? null,
  buchungskreisId: queryParams.buchungskreisId ?? '',
  createTsFrom: queryParams.createTsFrom ?? null,
  createTsTo: queryParams.createTsTo ?? null,
  createdByMitarbeiterIdList: queryParams.createdByMitarbeiterIdList ?? null,
  dueDateFrom: queryParams.dueDateFrom ?? null,
  dueDateTo: queryParams.dueDateTo ?? dayjsCustom().format('YYYY-MM-DD'),
  fibuKontoId: queryParams.fibuKontoId ?? '',
  kontoType: queryParams.kontoType ?? [],
  letzteMahnstufe: queryParams.letzteMahnstufe ? Number(queryParams.letzteMahnstufe) : null,
  objektId: queryParams.objektId ?? '',
  offenerBetragFrom: queryParams.offenerBetragFrom ? Number(queryParams.offenerBetragFrom) : null,
  offenerBetragTo: queryParams.offenerBetragTo ? Number(queryParams.offenerBetragTo) : null,
  vertragsartList: queryParams.vertragsartList ?? [],
  vertragspartnerId: queryParams.vertragspartnerId ?? null,
});

export const mapFormValuesToOffenePostenListingFilters = (formValues: OffenePostenListingFiltersFormValues): TOffenePostenQueryParams => ({
  belegDatumFrom: formValues.belegDatumFrom ? mapFormDateValueToDateString(formValues.belegDatumFrom) : null,
  belegDatumTo: formValues.belegDatumTo ? mapFormDateValueToDateString(formValues.belegDatumTo) : null,
  belegnummer: formValues.belegnummer || undefined,
  buchungsType: formValues.buchungsType || undefined,
  buchungsdatumFrom: formValues.buchungsdatumFrom ? mapFormDateValueToDateString(formValues.buchungsdatumFrom) : null,
  buchungsdatumTo: formValues.buchungsdatumTo ? mapFormDateValueToDateString(formValues.buchungsdatumTo) : null,
  buchungskreisId: formValues.buchungskreisId || undefined,
  createTsFrom: formValues.createTsFrom ? mapFormDateValueToDateString(formValues.createTsFrom) : null,
  createTsTo: formValues.createTsTo ? mapFormDateValueToDateString(formValues.createTsTo) : null,
  createdByMitarbeiterIdList: formValues.createdByMitarbeiterIdList || undefined,
  dueDateFrom: formValues.dueDateFrom ? mapFormDateValueToDateString(formValues.dueDateFrom) : null,
  dueDateTo: formValues.dueDateTo ? mapFormDateValueToDateString(formValues.dueDateTo) : null,
  fibuKontoId: formValues.fibuKontoId || undefined,
  kontoType: formValues.kontoType,
  letzteMahnstufe: formValues.letzteMahnstufe,
  objektId: formValues.objektId || undefined,
  offenerBetragFrom: formValues.offenerBetragFrom,
  offenerBetragTo: formValues.offenerBetragTo,
  vertragsartList: formValues.vertragsartList || undefined,
  vertragspartnerId: formValues.vertragspartnerId || undefined,
});

export const offenePostenListingLabelList = {
  [offenePostenListingFiltersFormFields.belegDatumFrom]: 'Belegdatum',
  [offenePostenListingFiltersFormFields.belegnummer]: 'Belegnummer',
  [offenePostenListingFiltersFormFields.buchungsType]: 'Buchungstyp',
  [offenePostenListingFiltersFormFields.buchungsdatumFrom]: 'Buchungsdatum',
  [offenePostenListingFiltersFormFields.buchungskreisId]: 'Buchungskreis',
  [offenePostenListingFiltersFormFields.createTsFrom]: 'Erstellt am',
  [offenePostenListingFiltersFormFields.createdByMitarbeiterIdList]: 'Erstellter',
  [offenePostenListingFiltersFormFields.dueDateFrom]: 'Fälligkeit',
  [offenePostenListingFiltersFormFields.fibuKontoId]: 'Fibu-Konto',
  [offenePostenListingFiltersFormFields.kontoType]: 'Kreditor/Debitor',
  [offenePostenListingFiltersFormFields.letzteMahnstufe]: 'Letzte Mahnstufe',
  [offenePostenListingFiltersFormFields.objektId]: 'Objekt',
  [offenePostenListingFiltersFormFields.offenerBetragFrom]: 'Offener Betrag',
  [offenePostenListingFiltersFormFields.vertragsartList]: 'Vertragsart',
  [offenePostenListingFiltersFormFields.vertragspartnerId]: 'Vertragspartner',
};
