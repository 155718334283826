import React, { FC, useState } from 'react';
import { Tabs, TabsProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  mapTextbausteinListToFormValues,
  TextbausteinFormValues,
  TextbausteinModel,
} from '../../../../../components/Template/PDFTemplates/templateMapper';
import FormButtons from '../../../../../components/Button/FormButtons';
import { showSuccessMsgCreate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { useCreateHeAbrechnungsdefinitionTemplateMutation } from '../../../gql/HeAbrDef/HeAbrDefTemplateMutations.types';
import { AbrechnungsdefinitionParameterFormValues } from '../../../../Abrechnungsdefinition/Form/Create/abrDefParameterFormMapper';
import { mapFormValuesToHeAbrechnungsdefinitionCreate } from '../../../../Abrechnungsdefinition/Form/Create/abrDefCreateFormMapper';
import { AbrDefAbrKreisListingFormValues } from '../../../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormMapper';
import { HeOrSubAbrDefOutputOptionsFieldHelpNames } from '../../../../Abrechnungsdefinition/abrDefFieldHelpHelpers/abrDefFieldHelpMapper';
import {
  heOrSubAbrDefGeneralSettingsFormInitialValues,
  HeOrSubAbrDefGeneralSettingsFormValues,
} from '../../../../Abrechnungsdefinition/shared/Templates/shared/HeOrSub/GeneralSettingForm/heOrSubAbrDefGeneralSettingsFormMapper';
import HeOrSubGeneralSettingsForm from '../../../../Abrechnungsdefinition/shared/Templates/shared/HeOrSub/GeneralSettingForm/HeOrSubGeneralSettingsForm';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../../constants/andromedaSystemSettingsUriPaths';
import TemplateHeAbrechnung from './TemplateHeAbrechnung';

type Props = {
  parameterFormValues: AbrechnungsdefinitionParameterFormValues;
  abrKreisListFormValues: AbrDefAbrKreisListingFormValues;
  heAbrechnungTextbausteinList: TextbausteinModel[];
  validFromFormValue: string;
  setIsValidFromValid: (validFromValid: boolean) => void;
  onBack: () => void;
  fieldHelpNames?: HeOrSubAbrDefOutputOptionsFieldHelpNames;
};

const HeAbrDefTemplateForm: FC<Props> = ({
  parameterFormValues,
  abrKreisListFormValues,
  heAbrechnungTextbausteinList,
  validFromFormValue,
  setIsValidFromValid,
  onBack,
  fieldHelpNames,
}) => {
  const entity = 'Abrechnungsdefinition';
  const navigate = useNavigate();

  const baseOptions = {
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      navigate(URI_ANDROMEDA_SYS_SETTINGS.abrechnungsdefinitionPage);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  };

  const [runCreate, { loading }] = useCreateHeAbrechnungsdefinitionTemplateMutation(baseOptions);

  const [heAbrDefGeneralSettingsValues, setHeAbrDefGeneralSettingsValues] = useState<HeOrSubAbrDefGeneralSettingsFormValues>(
    heOrSubAbrDefGeneralSettingsFormInitialValues
  );

  const [heAbrDefTemplateValues, setHeAbrDefTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(heAbrechnungTextbausteinList)
  );

  const onSubmit = () => {
    if (!validFromFormValue) {
      setIsValidFromValid(false);
      return;
    }

    runCreate({
      variables: {
        input: mapFormValuesToHeAbrechnungsdefinitionCreate(
          parameterFormValues,
          abrKreisListFormValues,
          heAbrDefTemplateValues,
          validFromFormValue,
          heAbrDefGeneralSettingsValues
        ),
      },
    });
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Allgemeine Einstellungen',
      children: (
        <HeOrSubGeneralSettingsForm
          values={heAbrDefGeneralSettingsValues}
          onSubmit={setHeAbrDefGeneralSettingsValues}
          fieldHelpNames={{
            displayKontonummer: fieldHelpNames?.displayKontonummer,
            rueckstaendeAnzeigen: fieldHelpNames?.rueckstaendeAnzeigen,
            rueckstaendeInAbrechnungsergebnis: fieldHelpNames?.rueckstaendeInAbrechnungsergebnis,
          }}
        />
      ),
    },
    {
      key: '2',
      label: 'Texte',
      children: (
        <TemplateHeAbrechnung
          values={heAbrDefTemplateValues}
          onTemplateChange={setHeAbrDefTemplateValues}
          abrDefGeneralSettingsValues={heAbrDefGeneralSettingsValues}
        />
      ),
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey="Allgemeine Einstellungen" items={items} />
      <FormButtons onCancel={onBack} cancelText="Zurück" updateMode={false} isOkLoading={loading} onOk={onSubmit} okText="Speichern" />
    </>
  );
};

export default HeAbrDefTemplateForm;
