import React from 'react';
import { Route } from 'react-router';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import DocumentPage from './DocumentPage';
import AuthRoute from '../../security/AuthRoute';
import { URI_DOCUMENT_PAGE } from '../../constants/documentsUriPaths';

const documentsFirmaPageRoute = (
  <Route path={URI_DOCUMENT_PAGE} element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<DocumentPage />} />} />
);

export default documentsFirmaPageRoute;
