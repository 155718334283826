import { AuszifferungColumnsStyle, ZahlungsverlaufColumnsStyle } from './zahlungsverlauf-styles-types';

export const columnWidthsAndAlignment: ZahlungsverlaufColumnsStyle = {
  dueDate: { width: '15%', textAlign: 'left' },
  belegnummer: { width: '15%', textAlign: 'left' },
  belegdatum: { width: '15%', textAlign: 'right' },
  emptyAuszifferung: { width: '25%', textAlign: 'left' },
  vorschreibung: { width: '15%', textAlign: 'right' },
  offenerBetrag: { width: '15%', textAlign: 'right' },
};

export const auszifferungColumnWidthsAndAlignment: AuszifferungColumnsStyle = {
  emptyDueDateBelegNumberDate: { width: '45%', textAlign: 'left' },
  auszifferung: { width: '25%', textAlign: 'left' },
  auszifferungValue: { width: '15%', textAlign: 'right' },
  emptyOffenerBetrag: { width: '15%', textAlign: 'right' },
};
