import React, { FC } from 'react';
import { CollapseProps, List } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import ZuordnungKontoKlasseTitle from '../shared/ZuordnungKontoKlasseTitle';
import { StyledCollapseKonto } from '../Styled/Collapse.style';
import { Konto } from '../../../types';
import { getZuordnungKontoListItemHeight, ZuordnungKontoKlasseType } from '../shared/AbrKreisKontenzuordnungHelpers';
import { AbrKontenzuordnungFiltersFormValues } from '../Listing/Filters/abrKontenzuordnungFiltersMapper';
import ZuordnungKontoListItemBezeichnung from '../shared/ZuordnungKontoListItemBezeichnung';

type Props = {
  title: string;
  zuordnungKontoKlasseType: ZuordnungKontoKlasseType;
  list: Konto[];
  isCollapsed: boolean;
  collapse: () => void;
  keyForCollapse: string;
  filters?: AbrKontenzuordnungFiltersFormValues | null;
};

const ZuordnungKontoListViewAndEdit: FC<Props> = ({ title, zuordnungKontoKlasseType, list, isCollapsed, collapse, keyForCollapse, filters }) => {
  const filterInUseAsNumber = filters ? Object.keys(filters).length : 0;

  const items: CollapseProps['items'] = [
    {
      key: keyForCollapse,
      label: <ZuordnungKontoKlasseTitle title={title} />,
      children: (
        <List
          size="small"
          bordered={false}
          dataSource={list}
          rowKey={(item) => item.kontoId}
          renderItem={(item) => (
            <List.Item style={{ height: getZuordnungKontoListItemHeight(filterInUseAsNumber) }}>
              <ZuordnungKontoListItemBezeichnung
                nummer={item.nummer}
                bezeichnung={item.bezeichnung}
                zuordnungKontoKlasseType={zuordnungKontoKlasseType}
                verwendung={item.verwendung?.value}
              />
            </List.Item>
          )}
        />
      ),
    },
  ];

  return (
    <StyledCollapseKonto
      ghost
      expandIcon={({ isActive }) => (!isActive ? <PlusOutlined /> : <MinusOutlined />)}
      onChange={collapse}
      defaultActiveKey={isCollapsed ? [keyForCollapse] : []}
      destroyInactivePanel
      items={items}
    />
  );
};

export default ZuordnungKontoListViewAndEdit;
