import { TextbausteinFormValues } from './templateMapper';
import { TextbausteinType } from '../../../types';

export const findTextbausteinByType = (textbausteinList: TextbausteinFormValues[], type: TextbausteinType): TextbausteinFormValues | undefined =>
  textbausteinList
    .flatMap((item) => (!item.textbausteinList || item.textbausteinList?.length === 0 ? item : item.textbausteinList))
    .find((item) => item?.type.value === type);

export const replacePlatzhalterValuesWithExampleValues = (textbaustein?: TextbausteinFormValues): string => {
  if (!textbaustein) {
    return '';
  }
  return textbaustein.platzhalterList.reduce(
    (accumulator, currentVal) => accumulator.replaceAll('${' + currentVal.platzhalter + '}', currentVal.example),
    textbaustein.value
  );
};

export const updateTextbausteinListValuesWithType = (
  textbausteinList: TextbausteinFormValues[],
  textbausteinType: TextbausteinType,
  value: string
): TextbausteinFormValues[] =>
  textbausteinList.map((textbausteinFormValues) => updateTextbausteinValuesWithType(textbausteinFormValues, textbausteinType, value));

export const updateTextbausteinValuesWithType = (
  textbausteinFormValues: TextbausteinFormValues,
  textbausteinType: TextbausteinType,
  value: string
): TextbausteinFormValues => {
  if (textbausteinFormValues.type.value === textbausteinType) {
    return {
      ...textbausteinFormValues,
      value,
    };
  }
  // not found but has children so look for it among them
  if (textbausteinFormValues.textbausteinList && textbausteinFormValues.textbausteinList.length > 0) {
    return {
      ...textbausteinFormValues,
      textbausteinList: updateTextbausteinListValuesWithType(textbausteinFormValues.textbausteinList, textbausteinType, value),
    };
  }
  return textbausteinFormValues;
};
