import React, { FC } from 'react';
import { Divider, TabsProps } from 'antd';
import { ObjektAufteilungsschluesselListing } from '../../features/Objekt/Aufteilungsschluessel';
import ZinslisteTable from '../../features/Zinsliste/Table/ZinslisteTable';
import { ObjektVorschreibungPositionListing } from '../../features/Objekt/VorschreibungPosition';
import WeObjektAbrechnungListing from '../../features/Abrechnung/Listing/We/WeObjektAbrechnungListing';
import { pathsToObjektDetailsPage } from '../../features/Objekt/objektUriPaths';
import VorsteuerkuerzungListing from '../../features/Vorsteuerkuerzung/Listing/VorsteuerkuerzungListing';
import TabPaneContent from '../../components/Tabs/TabPaneContent';
import UstVomAufwandListing from '../../features/UstVomAufwand/Listing/UstVomAufwandListing';
import RouteTabs from '../../components/Tabs/RouteTabs';
import { isMietObjekt, isWegObjekt } from '../../features/Objekt/objektHelper';
import HeVertragListing from '../../features/Vertrag/HeVertrag/Listing/HeVertragListing';
import MietObjektAbrechnungListing from '../../features/Abrechnung/Listing/Miet/MietObjektAbrechnungListing';
import BestandseinheitListing from '../../features/Bestandseinheit/Table/BestandseinheitListing';
import { Objekt } from '../../types';
import BudgetingListing from '../../features/Budgeting/BudgetingListing';

type Props = {
  objekt: Objekt;
  isRefetchingObjekt: boolean;
  refetchCard: () => Promise<unknown>;
};

const ObjektDetailsPageTopTabs: FC<Props> = ({ objekt, isRefetchingObjekt, refetchCard }) => {
  const paths = pathsToObjektDetailsPage(objekt.objektId);

  const items: TabsProps['items'] = [
    {
      key: paths.topTab,
      label: 'Bestandseinheiten',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <BestandseinheitListing objektId={objekt.objektId} refetchCard={refetchCard} isWegObjekt={isWegObjekt(objekt.verwaltungsart.value)} />
        </TabPaneContent>
      ),
    },
    {
      key: paths.vorgabenTab,
      label: 'Vorgaben',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <ObjektAufteilungsschluesselListing objektId={objekt.objektId} isWegObjekt={isWegObjekt(objekt.verwaltungsart.value)} />
          <Divider />
          <ObjektVorschreibungPositionListing objektId={objekt.objektId} verwaltungsart={objekt.verwaltungsart.value} />
        </TabPaneContent>
      ),
    },
    {
      key: paths.zinslistenTab,
      label: 'Zinslisten',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <ZinslisteTable objektId={objekt.objektId} isRefetchingObjekt={isRefetchingObjekt} />
        </TabPaneContent>
      ),
    },
    {
      key: paths.abrechnungenTab,
      label: 'Abrechnungen',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          {isWegObjekt(objekt.verwaltungsart.value) ? (
            <WeObjektAbrechnungListing objekt={objekt} isRefetchingObjekt={isRefetchingObjekt} />
          ) : (
            <MietObjektAbrechnungListing objekt={objekt} isRefetchingObjekt={isRefetchingObjekt} />
          )}
        </TabPaneContent>
      ),
    },
    {
      key: paths.budgeting,
      label: 'Budgetierung',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <BudgetingListing objektId={objekt.objektId} />
        </TabPaneContent>
      ),
    },
    {
      key: paths.vstKuerzung,
      label: 'Vorsteuerkürzung',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <VorsteuerkuerzungListing objektId={objekt.objektId} objektVerwaltungSeit={objekt.verwaltungSeit} />
        </TabPaneContent>
      ),
    },
    isWegObjekt(objekt.verwaltungsart.value) &&
      ({
        key: paths.ustVomAufwand,
        label: 'Ust vom Aufwand',
        children: (
          <TabPaneContent distanceToTopBorder="small">
            <UstVomAufwandListing objektId={objekt.objektId} objektVerwaltungSeit={objekt.verwaltungSeit} />
          </TabPaneContent>
        ),
      } as any),
    isMietObjekt(objekt.verwaltungsart.value) &&
      ({
        key: paths.heVertrag,
        label: 'Hauseigentümer',
        children: (
          <TabPaneContent distanceToTopBorder="small">
            <HeVertragListing objektId={objekt.objektId} objektVerwaltungSeit={objekt.verwaltungSeit} />
          </TabPaneContent>
        ),
      } as any),
  ].filter((value) => !!value);

  return <RouteTabs items={items} />;
};

export default ObjektDetailsPageTopTabs;
