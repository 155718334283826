import React, { FC } from 'react';
import { Empty } from 'antd';
import kontoTemplateTableColumns from './kontoTemplateTableColumns';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { Konto } from '../../../../types';

type Props = {
  kontoList?: Konto[];
  loading: boolean;
  refetch: () => void;
};

const KontoTemplateTable: FC<Props> = ({ kontoList, loading }) => {
  return (
    <TableWithColSelector<Konto>
      size="small"
      locale={{
        emptyText: <Empty description={<span>Keine Konten vorhanden</span>} />,
      }}
      scroll={{ x: 900 }}
      columns={kontoTemplateTableColumns}
      dataSource={kontoList}
      loading={loading}
      pagination
      rowKey={(record) => record.kontoId}
      filterIdentifier="andromedia-sys-settings-kontenrahmen-konto"
    />
  );
};

export default KontoTemplateTable;
