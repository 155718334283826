import React, { FC } from 'react';
import { Descriptions, Divider, Space, Typography } from 'antd';
import { Box } from 'rebass';
import { StandingInvoice } from '../../../types';
import theme from '../../../theme';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';
import InvoiceSumTotalContentBlock from './shared/InvoiceSumTotalContentBlock';
import BuchungPositionContentBlock from './shared/BuchungPositionContentBlock';

type Props = {
  incomingInvoice: StandingInvoice;
};

const StandingInvoicePartialAmountBlock: FC<Props> = ({ incomingInvoice }) => (
  <>
    {incomingInvoice.partialAmountList.map((partialAmount, index) => {
      return (
        <React.Fragment key={partialAmount.partialAmountId}>
          <Divider style={{ borderColor: theme.colors.black }}>Teilbetrag {index + 1}</Divider>
          <Descriptions labelStyle={{ color: theme.textColor.secondary }} bordered={false} colon={false} column={1} layout="horizontal" size="small">
            <Descriptions.Item label="Fällig am">
              <Box mx="auto" />
              <CustomFormattedDate value={partialAmount.dueDate} />
            </Descriptions.Item>
            <Descriptions.Item label="Buchungsdatum">
              <Box mx="auto" />
              <CustomFormattedDate value={partialAmount.buchungsdatum} />
            </Descriptions.Item>
            <Descriptions.Item label="Zahlungsdatum">
              <Box mx="auto" />
              {partialAmount.requestedPaymentDate ? (
                <CustomFormattedDate value={partialAmount.requestedPaymentDate} />
              ) : (
                <TextForEmptyValue textToShow="none" />
              )}
            </Descriptions.Item>
          </Descriptions>
          <BuchungPositionContentBlock buchungPositionList={partialAmount.buchungPositionList} />
          <Divider style={{ margin: '12px 0', borderColor: '#b3b3b3' }} />
          <Space direction="vertical">
            <Typography.Text strong>Summen</Typography.Text>
            <InvoiceSumTotalContentBlock netto={partialAmount.netto} brutto={partialAmount.brutto} steuerzeileList={partialAmount.steuerzeileList} />
          </Space>
        </React.Fragment>
      );
    })}
  </>
);

export default StandingInvoicePartialAmountBlock;
