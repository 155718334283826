import React from 'react';
import { useParams } from 'react-router';
import { Skeleton } from 'antd';
import { useQueryParams } from '../../../../../../hooks/useStringQueryParams';
import { AbrechnungsdefinitionViewPageQueryParam } from '../../../../../../features/Abrechnungsdefinition/abrDefUriPaths';
import {
  isAbrDefTypeHeAbrechnung,
  isAbrDefTypeSubAbrechnung,
  isAbrDefTypeWeAbrechnung,
} from '../../../../../../features/Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import AndromedaSysSettingsWeAbrechnungsdefinitionVersionViewPage from './AndromedaSysSettingsWeAbrechnungsdefinitionVersionViewPage';
import AndromedaSysSettingsHeAbrechnungsdefinitionVersionViewPage from './AndromedaSysSettingsHeAbrechnungsdefinitionVersionViewPage';
import AndromedaSysSettingsBkAbrechnungsdefinitionVersionViewPage from './AndromedaSysSettingsBkAbrechnungsdefinitionVersionViewPage';
import AndromedaSysSettingsSubAbrechnungsdefinitionVersionViewPage from './AndromedaSysSettingsSubAbrechnungsdefinitionVersionViewPage';
import { AbrechnungsdefinitionVersionViewPageRouteParams } from '../../../../../SysSettingsPage/KundenSystem/AbrechnungsdefinitionPage/Version/View/routes';
import { useAbrechnungsdefinitionTemplateTypeQuery } from '../../../../../../features/AbrechnungsdefinitionTemplate/gql/AbrDefTemplateQueries.types';

const AndromedaSysSettingsAbrechnungsdefinitionVersionViewPage = () => {
  const { abrechnungsdefinitionVersionId, abrechnungsdefinitionId } = useParams() as AbrechnungsdefinitionVersionViewPageRouteParams;

  const { isHistoricized } = useQueryParams<{ [AbrechnungsdefinitionViewPageQueryParam.IS_HISTORICIZED]: string }>();

  const { loading, data } = useAbrechnungsdefinitionTemplateTypeQuery({ variables: { abrechnungsdefinitionId } });
  const abrDefType = data?.getAbrechnungsdefinitionTemplateType.data;

  if (!abrDefType || loading) {
    return <Skeleton active />;
  } else if (isAbrDefTypeWeAbrechnung(abrDefType.value)) {
    return (
      <AndromedaSysSettingsWeAbrechnungsdefinitionVersionViewPage
        abrechnungsdefinitionId={abrechnungsdefinitionId}
        abrechnungsdefinitionVersionId={abrechnungsdefinitionVersionId}
        isHistoricized={!!isHistoricized}
      />
    );
  } else if (isAbrDefTypeHeAbrechnung(abrDefType.value)) {
    return (
      <AndromedaSysSettingsHeAbrechnungsdefinitionVersionViewPage
        abrechnungsdefinitionId={abrechnungsdefinitionId}
        abrechnungsdefinitionVersionId={abrechnungsdefinitionVersionId}
        isHistoricized={!!isHistoricized}
      />
    );
  } else if (isAbrDefTypeSubAbrechnung(abrDefType.value)) {
    return (
      <AndromedaSysSettingsSubAbrechnungsdefinitionVersionViewPage
        abrechnungsdefinitionId={abrechnungsdefinitionId}
        abrechnungsdefinitionVersionId={abrechnungsdefinitionVersionId}
        isHistoricized={!!isHistoricized}
      />
    );
  } else {
    return (
      <AndromedaSysSettingsBkAbrechnungsdefinitionVersionViewPage
        abrechnungsdefinitionId={abrechnungsdefinitionId}
        abrechnungsdefinitionVersionId={abrechnungsdefinitionVersionId}
        isHistoricized={!!isHistoricized}
      />
    );
  }
};

export default AndromedaSysSettingsAbrechnungsdefinitionVersionViewPage;
