import React, { FC } from 'react';
import { MenuProps, Modal } from 'antd';
import { EditOutlined, EuroCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { Firmendaten, FirmendatenBankDetails } from '../../../../../types';
import { useAddKontoForFirmendatenBankDetailsMutation } from '../../../../BankDetails/gql/BankDetailsMutations.types';
import { showSuccessMsgCreate } from '../../../../../components/message';

import { useToggle } from '../../../../../hooks/useToggle';
import AccountLimitFormModal from '../../../../BankDetails/AccountLimit/AccountLimitFormModal';
import ActionDropdown from '../../../../../components/Dropdown/ActionDropdown';
import { isBankDetailsFibuStatusAngelegt, isBankDetailsFibuStatusWirdAngelegt } from '../../../../BankDetails/bankDetailsHelpers';

type Props = {
  bankDetails: FirmendatenBankDetails;
  firmendaten: Firmendaten;
  onAction: () => void;
};

const FirmendatenKontoTableActions: FC<Props> = ({ bankDetails, firmendaten, onAction }) => {
  const [isAccountLimitModalOpen, toggleAccountLimitModalOpen] = useToggle();

  const [runCreateKonto] = useAddKontoForFirmendatenBankDetailsMutation({
    onCompleted: () => {
      onAction();
      showSuccessMsgCreate(`Konto`);
    },
    variables: {
      firmendatenId: firmendaten.firmendatenId,
      bankDetailsId: bankDetails.bankDetailsId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'FIBU Konto erstellen',
      onClick: () => showConfirm(bankDetails, firmendaten, runCreateKonto),
      icon: <EditOutlined />,
      disabled: isBankDetailsFibuStatusAngelegt(bankDetails.fibuStatus.value) || isBankDetailsFibuStatusWirdAngelegt(bankDetails.fibuStatus.value),
    },
    {
      key: '2',
      label: 'Überziehungsrahmen ändern',
      onClick: toggleAccountLimitModalOpen,
      disabled: !isBankDetailsFibuStatusAngelegt(bankDetails.fibuStatus.value),
      icon: <EuroCircleOutlined />,
    },
  ];

  const onActionAccountLimitSuccess = () => {
    onAction();
    toggleAccountLimitModalOpen();
  };

  return (
    <>
      <ActionDropdown items={items} />
      <AccountLimitFormModal
        isAccountLimitModalOpen={isAccountLimitModalOpen}
        onSuccess={onActionAccountLimitSuccess}
        onCancel={toggleAccountLimitModalOpen}
        bankDetailsId={bankDetails.bankDetailsId}
        accountLimit={bankDetails.accountLimit}
        firmendatenId={firmendaten.firmendatenId}
        fibuKontonummer={bankDetails.fibuKontonummer}
      />
    </>
  );
};

const showConfirm = (bankDetails: FirmendatenBankDetails, firmendaten: Firmendaten, runCreateKonto: () => void) => {
  Modal.confirm({
    title: `Möchten Sie ein FIBU Konto erstellen?`,
    icon: <ExclamationCircleOutlined />,
    content: `${firmendaten.kurzBezeichnung} – ${bankDetails.accountHolder}`,
    okText: 'Ja',
    cancelText: 'Nein',
    onOk() {
      return runCreateKonto();
    },
  });
};

export default FirmendatenKontoTableActions;
