import { rootUrlEmailVerification } from './ApiPaths';
import { processAndReturnError, processResponse, returnData } from '../../helpers/APIUtils';
import Fetcher from '../../helpers/fetcher';
import { FETCH_OPTIONS_GET_DEFAULT } from '../../helpers/fetchHelper';

export const verifyTokenAndAcceptEmailChange = async (_args: any) => {
  try {
    const response = await Fetcher.getInstance().fetch(rootUrlEmailVerification(_args.token), FETCH_OPTIONS_GET_DEFAULT as RequestInit);
    const res = await processResponse(response);
    return await returnData(res);
  } catch (error: any) {
    return await processAndReturnError(error);
  }
};
