import React, { FC, PropsWithChildren } from 'react';
import { HasPermissionFunction } from './permissionChecks';
import { useAuth } from '../shared/Auth/authContext';
import { UserRole } from '../types';

type AuthorizedProps = {
  hasPermissionFn: HasPermissionFunction;
} & PropsWithChildren;

const Authorized: FC<AuthorizedProps> = ({ hasPermissionFn, children }) => {
  const { mitarbeiter, user, activeForFirmendatenId } = useAuth();
  const role = user?.role;
  const funktionsModulList = mitarbeiter?.funktionsModulList;
  if (isNotAuthorized(hasPermissionFn, role, funktionsModulList, activeForFirmendatenId)) {
    return null;
  }
  return <>{children}</>;
};

export const isNotAuthorized = (
  hasPermissionFn: HasPermissionFunction,
  role?: UserRole,
  funktionsModulList?: string[],
  activeForFirmendatenId?: string | null
) => !hasPermissionFn || !hasPermissionFn({ role, funktionsModulList, activeForFirmendatenId: activeForFirmendatenId || undefined });

export default Authorized;
