import React, { FC } from 'react';
import { Flex, Box } from 'rebass';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../../hooks/useToggle';
import RechtsTraegerCreateDrawer from './RechtsTraegerCreateDrawer';
import { RechtstraegerType } from '../../../types';

type RechtsTraegerActionsProps = {
  onSuccess: (rechtstraegerId: string, kurzBezeichnung: string, type: { value: RechtstraegerType }) => void;
};

export const RechtsTraegerActions: FC<RechtsTraegerActionsProps> = ({ onSuccess }) => {
  const [isCollapsed, onCollapse] = useToggle();

  return (
    <>
      <Flex>
        <Box mx="auto" />
        <Button type="primary" icon={<PlusOutlined />} onClick={onCollapse}>
          Firma / Person anlegen
        </Button>
      </Flex>
      <RechtsTraegerCreateDrawer onSuccess={onSuccess} isCollapsed={isCollapsed} onCollapse={onCollapse} />
    </>
  );
};

export default RechtsTraegerActions;
