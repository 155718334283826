import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import LabelValue from '../../../shared/LabelValue';
import { TSubAdministrationAbrechnungObjekt } from '../subAdministrationAbrechnungObjekt-types';
import DetailsColumnsTitle from '../../shared/DetailsTable/DetailsColumnsTitle';
import Saldovortrag from '../../shared/DetailsTable/Saldovortrag';
import PositionBlock from '../../shared/DetailsTable/PositionBlock';
import Zwischensumme from '../../shared/DetailsTable/Zwischensumme';
import EigentuemerverrechnungBlock from '../../shared/DetailsTable/EigentuemerverrechnungBlock';

const SubAdminAbrObjDetailsBlock: FC<{ data: TSubAdministrationAbrechnungObjekt }> = ({ data }) => (
  <View style={[pdfStyles.tablePaddingLeftRight, { marginTop: '3mm' }]}>
    {/* Details Table */}
    <DetailsColumnsTitle title={data.positionListHeader.positionText} />
    <Saldovortrag data={data.saldovortrag} />
    {/* Einnahmen, Ausgaben and VstKuerzung */}
    {data.einnahmen ? <PositionBlock data={data.einnahmen} /> : null}
    {data.ausgaben ? <PositionBlock data={data.ausgaben} /> : null}
    {data.vorsteuerkuerzung ? <PositionBlock data={data.vorsteuerkuerzung} /> : null}

    {/* Zwischensumme */}
    {data.einnahmen || data.ausgaben || data.vorsteuerkuerzung ? <Zwischensumme data={data.zwischensumme} /> : null}

    {/* Eigentuemerverrechnung */}
    {data.eigentuemerverrechnung ? <EigentuemerverrechnungBlock data={data.eigentuemerverrechnung} /> : null}

    {/* ErgebnisAbrechnung */}
    <View style={[pdfStyles.column, { marginTop: '5mm' }]}>
      <LabelValue
        valueTuple={data.abrechnungErgebnis}
        viewStyle={{ ...pdfStyles.borderBottomSmall, borderBottomWidth: 2 }}
        labelStyle={{ width: '70%', fontWeight: 'bold' }}
        valueStyle={{ width: '30%', textAlign: 'right', fontWeight: 'bold' }}
      />
    </View>
  </View>
);

export default SubAdminAbrObjDetailsBlock;
