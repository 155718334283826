import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
export type ZinslisteListEntryFragment = {
  countVorschreibungsposition?: number | null;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  generierlaufId?: string | null;
  objektId: string;
  objektKurzbezeichnung: string;
  sumBrutto?: number | null;
  sumNetto?: number | null;
  sumUst?: number | null;
  zinslisteId: string;
  status: { text: string; value: Types.ZinslisteStatus; description?: string | null };
  vorschreibungsperiode: { jahr: number; monat: number };
  warningList: Array<{ message: string; type: string; attribute?: string | null }>;
};

export type ZinslisteFragment = {
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  sumBrutto?: number | null;
  sumNetto?: number | null;
  sumUst?: number | null;
  bestandseinheitList: Array<{
    bestandseinheitId: string;
    bezeichnung: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    aufteilungsschluesselList: Array<{
      aufteilungsschluesselId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      wert: number;
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
    }>;
    vertragList: Array<{
      ausstellerBankDetailsId?: string | null;
      ausstellerId: string;
      ausstellerKurzBezeichnung: string;
      consolidationCriteria?: string | null;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      vertragId: string;
      vertragspartnerBankDetailsId?: string | null;
      belegform: { text: string; value: Types.BelegFormArt };
      dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
      paymentMethod: { text: string; value: Types.PaymentMethod };
      verrechnungsart: { text: string; value: Types.Verrechnungsart };
      vertragVorschreibungspositionList: Array<{
        bezeichnung: string;
        brutto?: number | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        netto?: number | null;
        performancePeriodFromInclusive: string;
        performancePeriodToInclusive: string;
        steuersatz?: number | null;
        ust?: number | null;
        vorschreibungspositionId: string;
        zinslisteVertragVorschrPosId: string;
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      }>;
      vertragLevel: { shortName: string; text: string; value: Types.VertragLevel };
      vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
      vertragspartner: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
    }>;
    warningList: Array<{ message: string; type: string; attribute?: string | null }>;
  }>;
  kopfdaten: {
    generierDatum: string;
    generierlaufId?: string | null;
    objektId: string;
    objektKurzBezeichnung: string;
    zinslisteId: string;
    vorschreibungsperiode: { jahr: number; monat: number };
  };
  status: { description?: string | null; text: string; value: Types.ZinslisteStatus };
  warningList: Array<{ message: string; type: string; attribute?: string | null }>;
};

export const ZinslisteListEntryFragmentDoc = gql`
  fragment ZinslisteListEntry on ZinslisteListEntry {
    countVorschreibungsposition
    createTs
    createdBy
    createdByMitarbeiterId
    generierlaufId
    objektId
    objektKurzbezeichnung
    status {
      text
      value
      description
    }
    sumBrutto
    sumNetto
    sumUst
    vorschreibungsperiode {
      jahr
      monat
    }
    warningList {
      message
      type
      attribute
    }
    zinslisteId
  }
`;
export const ZinslisteFragmentDoc = gql`
  fragment Zinsliste on Zinsliste {
    bestandseinheitList {
      aufteilungsschluesselList {
        aufteilungsschluesselId
        createTs
        createdBy
        createdByMitarbeiterId
        kurzBezeichnung
        warningList {
          message
          type
          attribute
        }
        wert
      }
      bestandseinheitId
      bezeichnung
      createTs
      createdBy
      createdByMitarbeiterId
      vertragList {
        ausstellerBankDetailsId
        ausstellerId
        ausstellerKurzBezeichnung
        belegform {
          text
          value
        }
        consolidationCriteria
        createTs
        createdBy
        createdByMitarbeiterId
        dueDateVorschreibung {
          text
          value
        }
        paymentMethod {
          text
          value
        }
        verrechnungsart {
          text
          value
        }
        vertragId
        vertragVorschreibungspositionList {
          bezeichnung
          brutto
          createTs
          createdBy
          createdByMitarbeiterId
          netto
          performancePeriodFromInclusive
          performancePeriodToInclusive
          steuersatz
          ust
          vorschreibungspositionId
          warningList {
            message
            type
            attribute
          }
          zinslisteVertragVorschrPosId
        }
        vertragLevel {
          shortName
          text
          value
        }
        vertragsart {
          supportsExplicitVertragsEnde
          text
          value
        }
        vertragspartnerBankDetailsId
        vertragspartner {
          ...RechtstraegerBaseFields
        }
        warningList {
          message
          type
          attribute
        }
      }
      warningList {
        message
        type
        attribute
      }
    }
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    kopfdaten {
      generierDatum
      generierlaufId
      objektId
      objektKurzBezeichnung
      vorschreibungsperiode {
        jahr
        monat
      }
      zinslisteId
    }
    status {
      description
      text
      value
    }
    sumBrutto
    sumNetto
    sumUst
    warningList {
      message
      type
      attribute
    }
  }
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
