import React, { FC } from 'react';
import { Empty } from 'antd';
import camtEbicsEntryTableColumns from './camtEbicsEntryTableColumns';
import { isVerarbeitungEntryTableRowExpandable } from '../../../verarbeitungHelpers';
import GenerierlaufStatusExpandedRow from '../../../shared/GenerierlaufStatusExpandedRow';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { CamtGenerierlaufEntry } from '../../../../../types';
import { TCamtVerarbeitungQueryParams } from '../Filters/filtersQueryParams';
import { useQueryWithPaginationAndSortingForCamtGenLaufEntryList } from '../shared/useQueryWithPaginationAndSortingForCamtGenLaufEntryList';

type Props = {
  queryParams: TCamtVerarbeitungQueryParams;
  generierlaufId: string;
};

const CamtEbicsEntryTable: FC<Props> = ({ generierlaufId, queryParams }) => {
  const { entryList, loading, pagination, handleTableSorting } = useQueryWithPaginationAndSortingForCamtGenLaufEntryList(generierlaufId, queryParams);

  const entryListWithWarningAndError = entryList.map((entry) => {
    const mergedWarningList = entry.dataCarrierList.reduce((acc, dataCarrier) => {
      return [...acc, ...dataCarrier.warningList];
    }, entry.warningList);

    const mergedErrorList = entry.dataCarrierList.reduce((acc, dataCarrier) => {
      return [...acc, ...dataCarrier.errorList];
    }, entry.errorList);

    return {
      ...entry,
      warningList: mergedWarningList,
      errorList: mergedErrorList,
    };
  });

  return (
    <>
      <TableWithColSelector<CamtGenerierlaufEntry>
        dataSource={entryListWithWarningAndError}
        columns={camtEbicsEntryTableColumns()}
        loading={loading}
        locale={{
          emptyText: <Empty description={<span>Keine EBICS Bankteilnehmer vorhanden</span>} />,
        }}
        size="small"
        pagination={pagination}
        onChange={handleTableSorting}
        expandable={{
          expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
          rowExpandable: isVerarbeitungEntryTableRowExpandable,
        }}
        filterIdentifier="verarbeitung-sync-ebics-user"
        rowKey={(record) => record.generierlaufEntryId}
      />
    </>
  );
};

export default CamtEbicsEntryTable;
