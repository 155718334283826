import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedNumber } from 'react-intl';
import { HeVertragOverviewListEntry, HeVertragOverviewOrderBy } from '../../../../../types';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import CurrentBasedData from '../../../../../components/Helpers/CurrentBasedData';
import { isEntityCurrent } from '../../../../../helpers/dateHelper';
import { generatePathToObjektDetailsPage } from '../../../../Objekt/objektUriPaths';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { Percent } from '../../../../../components/Number';
import { generatePathToHeVertragDetailsPage } from '../../../../Vertrag/HeVertrag/heVertragUriPaths';
import { generatePathToRechtstraegerDetailsPage } from '../../../rechtstraegerHelper';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../../../../components/Card';

const heVertragTableColumns: TableWithColSelectorColumnProps<HeVertragOverviewListEntry>[] = [
  {
    title: 'Vertragsart',
    defaultSelected: true,
    render: (text, record) => (
      <CurrentBasedData text="Hauseigentümer" isCurrent={isEntityCurrent(record.vertragsEnde)} warningList={record.warningList} />
    ),
  },
  {
    title: 'Objekt',
    defaultSelected: true,
    dataIndex: HeVertragOverviewOrderBy.Objekt,
    sorter: true,
    render: (text, record) => (
      <CurrentBasedData
        text={record.objektKurzBezeichnung}
        isCurrent={isEntityCurrent(record.vertragsEnde)}
        maxTextLength={30}
        linkTo={generatePathToObjektDetailsPage(record.objektId)}
      />
    ),
  },
  {
    title: 'Rechnungsaussteller',
    defaultSelected: true,
    dataIndex: HeVertragOverviewOrderBy.Rechnungsaussteller,
    sorter: true,
    render: (text, record) => (
      <CurrentBasedData
        text={record.rechnungsausstellerKurzBezeichnung}
        isCurrent={isEntityCurrent(record.vertragsEnde)}
        maxTextLength={30}
        openLinkInNewTab
        linkTo={generatePathToRechtstraegerDetailsPage(record.rechnungsausstellerId)}
      />
    ),
  },
  {
    title: 'Vertragspartner',
    defaultSelected: true,
    dataIndex: HeVertragOverviewOrderBy.Vertragspartner,
    sorter: true,
    render: (text, record) => (
      <CurrentBasedData
        text={record.vertragspartnerKurzBezeichnung}
        isCurrent={isEntityCurrent(record.vertragsEnde)}
        maxTextLength={30}
        openLinkInNewTab
        linkTo={generatePathToRechtstraegerDetailsPage(record.vertragspartnerId)}
      />
    ),
  },
  {
    title: 'Von',
    width: 80,
    defaultSelected: true,
    dataIndex: HeVertragOverviewOrderBy.VertragsBeginn,
    sorter: true,
    render: (text, record) => (
      <CurrentBasedData text="" isCurrent={isEntityCurrent(record.vertragsEnde)}>
        <CustomFormattedDate value={record.vertragsBeginn} />
      </CurrentBasedData>
    ),
  },
  {
    title: 'Bis',
    width: 80,
    defaultSelected: true,
    dataIndex: HeVertragOverviewOrderBy.VertragsEnde,
    sorter: true,
    render: (text, record) => (
      <CurrentBasedData text="" isCurrent={isEntityCurrent(record.vertragsEnde)}>
        {record.vertragsEnde ? <CustomFormattedDate value={record.vertragsEnde} /> : <TextForEmptyValue textToShow="minus" />}
      </CurrentBasedData>
    ),
  },
  {
    title: 'Anteile %',
    defaultSelected: true,
    render: (text, record) => (
      <CurrentBasedData text="" isCurrent={isEntityCurrent(record.vertragsEnde)}>
        <Percent value={record.anteilePercent / 100} />
      </CurrentBasedData>
    ),
  },
  {
    title: 'Anteile',
    dataIndex: HeVertragOverviewOrderBy.Anteile,
    sorter: true,
    defaultSelected: true,
    render: (text, record) => (
      <CurrentBasedData text="" isCurrent={isEntityCurrent(record.vertragsEnde)}>
        <FormattedNumber value={record.anteile} />
      </CurrentBasedData>
    ),
  },
  {
    title: 'Gesamtanteil',
    dataIndex: HeVertragOverviewOrderBy.ObjektSummeAnteile,
    sorter: true,
    defaultSelected: true,
    render: (text, record) => (
      <CurrentBasedData text="" isCurrent={isEntityCurrent(record.vertragsEnde)}>
        <FormattedNumber value={record.objektSummeAnteile} />
      </CurrentBasedData>
    ),
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => <Link to={generatePathToHeVertragDetailsPage(record.objektId, record.heVertragId)}>Details</Link>,
  },
];

export default heVertragTableColumns;
