import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTextbausteinMutationVariables = Types.Exact<{
  input: Types.GeneralTextbausteinCreateInput;
}>;

export type CreateTextbausteinMutation = {
  createTextbaustein: {
    data: { textbausteinId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateTextbausteinMutationVariables = Types.Exact<{
  textbausteinId: Types.Scalars['ID']['input'];
  input: Types.GeneralTextbausteinUpdateInput;
}>;

export type UpdateTextbausteinMutation = {
  updateTextbaustein: {
    data: { textbausteinId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteTextbausteinMutationVariables = Types.Exact<{
  textbausteinId: Types.Scalars['ID']['input'];
}>;

export type DeleteTextbausteinMutation = {
  deleteTextbaustein: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateTextbausteinDocument = gql`
  mutation CreateTextbaustein($input: GeneralTextbausteinCreateInput!) {
    createTextbaustein(input: $input) {
      data {
        textbausteinId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateTextbausteinMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTextbausteinMutation, CreateTextbausteinMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTextbausteinMutation, CreateTextbausteinMutationVariables>(CreateTextbausteinDocument, options);
}
export type CreateTextbausteinMutationHookResult = ReturnType<typeof useCreateTextbausteinMutation>;
export type CreateTextbausteinMutationResult = Apollo.MutationResult<CreateTextbausteinMutation>;
export type CreateTextbausteinMutationOptions = Apollo.BaseMutationOptions<CreateTextbausteinMutation, CreateTextbausteinMutationVariables>;
export const UpdateTextbausteinDocument = gql`
  mutation UpdateTextbaustein($textbausteinId: ID!, $input: GeneralTextbausteinUpdateInput!) {
    updateTextbaustein(textbausteinId: $textbausteinId, input: $input) {
      data {
        textbausteinId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateTextbausteinMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTextbausteinMutation, UpdateTextbausteinMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTextbausteinMutation, UpdateTextbausteinMutationVariables>(UpdateTextbausteinDocument, options);
}
export type UpdateTextbausteinMutationHookResult = ReturnType<typeof useUpdateTextbausteinMutation>;
export type UpdateTextbausteinMutationResult = Apollo.MutationResult<UpdateTextbausteinMutation>;
export type UpdateTextbausteinMutationOptions = Apollo.BaseMutationOptions<UpdateTextbausteinMutation, UpdateTextbausteinMutationVariables>;
export const DeleteTextbausteinDocument = gql`
  mutation DeleteTextbaustein($textbausteinId: ID!) {
    deleteTextbaustein(textbausteinId: $textbausteinId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteTextbausteinMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTextbausteinMutation, DeleteTextbausteinMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTextbausteinMutation, DeleteTextbausteinMutationVariables>(DeleteTextbausteinDocument, options);
}
export type DeleteTextbausteinMutationHookResult = ReturnType<typeof useDeleteTextbausteinMutation>;
export type DeleteTextbausteinMutationResult = Apollo.MutationResult<DeleteTextbausteinMutation>;
export type DeleteTextbausteinMutationOptions = Apollo.BaseMutationOptions<DeleteTextbausteinMutation, DeleteTextbausteinMutationVariables>;
