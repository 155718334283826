import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { HistoryListFieldsFragmentDoc } from '../../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ObjektAufteilungsschluesselListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type ObjektAufteilungsschluesselListQuery = {
  getObjektAufteilungsschluesselList: {
    data: Array<{
      aufteilungsschluesselId: string;
      bezeichnung: string;
      bezeichnungOriginal: string;
      bezeichnungOverwritten?: string | null;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      createTs: string;
      deletable?: boolean | null;
      kurzBezeichnung: string;
      objektAufteilungsschluesselId: string;
      aufteilungsschluesselStatus: { description?: string | null; text: string; value: Types.AufteilungsschluesselStatus };
      bezugsbasis: { text: string; value: Types.Bezugsbasis };
      masseinheit: { text: string; value: Types.Masseinheit };
      objektAufteilungsschluesselStatus: { description?: string | null; text: string; value: Types.ObjektAufteilungsschluesselStatus };
      verwendung?: { text: string; value: Types.ObjektAufteilungsschluesselVerwendung } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ObjektAufteilungsschluesselChangeHistoryListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  objektAufteilungsschluesselId: Types.Scalars['ID']['input'];
}>;

export type ObjektAufteilungsschluesselChangeHistoryListQuery = {
  getObjektAufteilungsschluesselChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const ObjektAufteilungsschluesselListDocument = gql`
  query ObjektAufteilungsschluesselList($objektId: ID!, $withDetails: Boolean, $includeArchiviert: Boolean) {
    getObjektAufteilungsschluesselList(objektId: $objektId, withDetails: $withDetails, includeArchiviert: $includeArchiviert) {
      data {
        aufteilungsschluesselId
        aufteilungsschluesselStatus {
          description
          text
          value
        }
        bezeichnung
        bezeichnungOriginal
        bezeichnungOverwritten
        bezugsbasis {
          text
          value
        }
        createdBy
        createdByMitarbeiterId
        createTs
        deletable
        kurzBezeichnung
        masseinheit {
          text
          value
        }
        objektAufteilungsschluesselId
        objektAufteilungsschluesselStatus {
          description
          text
          value
        }
        verwendung {
          text
          value
        }
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useObjektAufteilungsschluesselListQuery(
  baseOptions: Apollo.QueryHookOptions<ObjektAufteilungsschluesselListQuery, ObjektAufteilungsschluesselListQueryVariables> &
    ({ variables: ObjektAufteilungsschluesselListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ObjektAufteilungsschluesselListQuery, ObjektAufteilungsschluesselListQueryVariables>(
    ObjektAufteilungsschluesselListDocument,
    options
  );
}
export function useObjektAufteilungsschluesselListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ObjektAufteilungsschluesselListQuery, ObjektAufteilungsschluesselListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ObjektAufteilungsschluesselListQuery, ObjektAufteilungsschluesselListQueryVariables>(
    ObjektAufteilungsschluesselListDocument,
    options
  );
}
export function useObjektAufteilungsschluesselListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ObjektAufteilungsschluesselListQuery, ObjektAufteilungsschluesselListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ObjektAufteilungsschluesselListQuery, ObjektAufteilungsschluesselListQueryVariables>(
    ObjektAufteilungsschluesselListDocument,
    options
  );
}
export type ObjektAufteilungsschluesselListQueryHookResult = ReturnType<typeof useObjektAufteilungsschluesselListQuery>;
export type ObjektAufteilungsschluesselListLazyQueryHookResult = ReturnType<typeof useObjektAufteilungsschluesselListLazyQuery>;
export type ObjektAufteilungsschluesselListSuspenseQueryHookResult = ReturnType<typeof useObjektAufteilungsschluesselListSuspenseQuery>;
export type ObjektAufteilungsschluesselListQueryResult = Apollo.QueryResult<
  ObjektAufteilungsschluesselListQuery,
  ObjektAufteilungsschluesselListQueryVariables
>;
export const ObjektAufteilungsschluesselChangeHistoryListDocument = gql`
  query ObjektAufteilungsschluesselChangeHistoryList($objektId: ID!, $objektAufteilungsschluesselId: ID!) {
    getObjektAufteilungsschluesselChangeHistoryList(objektId: $objektId, objektAufteilungsschluesselId: $objektAufteilungsschluesselId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useObjektAufteilungsschluesselChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    ObjektAufteilungsschluesselChangeHistoryListQuery,
    ObjektAufteilungsschluesselChangeHistoryListQueryVariables
  > &
    ({ variables: ObjektAufteilungsschluesselChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ObjektAufteilungsschluesselChangeHistoryListQuery, ObjektAufteilungsschluesselChangeHistoryListQueryVariables>(
    ObjektAufteilungsschluesselChangeHistoryListDocument,
    options
  );
}
export function useObjektAufteilungsschluesselChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ObjektAufteilungsschluesselChangeHistoryListQuery,
    ObjektAufteilungsschluesselChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ObjektAufteilungsschluesselChangeHistoryListQuery, ObjektAufteilungsschluesselChangeHistoryListQueryVariables>(
    ObjektAufteilungsschluesselChangeHistoryListDocument,
    options
  );
}
export function useObjektAufteilungsschluesselChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ObjektAufteilungsschluesselChangeHistoryListQuery, ObjektAufteilungsschluesselChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ObjektAufteilungsschluesselChangeHistoryListQuery, ObjektAufteilungsschluesselChangeHistoryListQueryVariables>(
    ObjektAufteilungsschluesselChangeHistoryListDocument,
    options
  );
}
export type ObjektAufteilungsschluesselChangeHistoryListQueryHookResult = ReturnType<typeof useObjektAufteilungsschluesselChangeHistoryListQuery>;
export type ObjektAufteilungsschluesselChangeHistoryListLazyQueryHookResult = ReturnType<
  typeof useObjektAufteilungsschluesselChangeHistoryListLazyQuery
>;
export type ObjektAufteilungsschluesselChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useObjektAufteilungsschluesselChangeHistoryListSuspenseQuery
>;
export type ObjektAufteilungsschluesselChangeHistoryListQueryResult = Apollo.QueryResult<
  ObjektAufteilungsschluesselChangeHistoryListQuery,
  ObjektAufteilungsschluesselChangeHistoryListQueryVariables
>;
