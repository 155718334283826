import React, { FC } from 'react';
import { Formik } from 'formik';
import { ITimeblock } from '../../../../../components/Timeline/timelineCtx';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { useCreateRepFondsEinstellungMutation, useUpdateRepFondsEinstellungMutation } from '../../../gql/RepFondsEinstellungMutations.types';
import {
  mapFormValuesToRepFondsKontenZuweisungVersionCreate,
  mapFormValuesToRepFondsKontenZuweisungVersionUpdate,
  mapTimeblockToFormValues,
  RepFondsKontenZuweisungVersionFormValues,
} from './repFondsKontenZuweisungVersionFormMapper';
import RepFondsKontenZuweisungVersionFormContent from './RepFondsKontenZuweisungVersionFormContent';
import { repFondsKontenZuweisungVersionFormValidationSchema } from './repFondsKontenZuweisungVersionFormValidationSchema';
import { RepFondsEinstellung } from '../../../../../types';

type Props = {
  onSubmitSuccess: () => void;
  timeblock: ITimeblock<RepFondsEinstellung>;
};

const RepFondsKontenZuweisungVersionForm: FC<Props> = ({ onSubmitSuccess, timeblock }) => {
  const isUpdate = !!timeblock.repFondsEinstellungId;
  const entity = 'Reparaturfonds-Kontenzuweisung';

  const [runCreate, { loading: loadingCreate }] = useCreateRepFondsEinstellungMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSubmitSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateRepFondsEinstellungMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSubmitSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const isLoading = loadingCreate || loadingUpdate;

  return (
    <Formik<RepFondsKontenZuweisungVersionFormValues>
      initialValues={mapTimeblockToFormValues(timeblock)}
      validationSchema={repFondsKontenZuweisungVersionFormValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (!isUpdate) {
          const input = mapFormValuesToRepFondsKontenZuweisungVersionCreate(values);
          runCreate({ variables: { input } }).finally(() => setSubmitting(false));
        } else {
          const input = mapFormValuesToRepFondsKontenZuweisungVersionUpdate(values);
          runUpdate({
            variables: { repFondsEinstellungId: timeblock.repFondsEinstellungId, input },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => <RepFondsKontenZuweisungVersionFormContent formikProps={formikProps} timeblock={timeblock} isLoading={isLoading} />}
    </Formik>
  );
};

export default RepFondsKontenZuweisungVersionForm;
