import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useBeVertragListQuery } from '../../Bestandseinheit/gql/BestandseinheitQueries.types';
import { selectFilterOption } from '../../../helpers/selectHelper';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';

type Props = {
  objektId?: string | null;
  bestandseinheitId?: string | null;
  width?: string;
  maxTextLength?: number;
} & SelectProps;

const VertragSelect: FC<Props> = ({ objektId, bestandseinheitId, width, maxTextLength, ...restProps }) => {
  const { data, loading } = useBeVertragListQuery({
    skip: !(objektId && bestandseinheitId),
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      objektId: objektId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      bestandseinheitId: bestandseinheitId!,
    },
  });

  const vertragList = data?.getBeVertragList.data ?? [];

  return (
    <Select
      size="small"
      id={restProps.name}
      loading={loading}
      placeholder="Vertrag auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
      style={{ width: width || '100%' }}
      disabled={!objektId || !bestandseinheitId}
      {...restProps}
    >
      {vertragList.map((vertrag) => (
        <Select.Option key={vertrag.vertragId} value={vertrag.vertragId}>
          <DataWithShortenedText maxTextLength={maxTextLength ?? vertrag.vertragKurzBezeichnung.length} text={vertrag.vertragKurzBezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default VertragSelect;
