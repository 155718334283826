import React, { FC } from 'react';
import { isEmpty } from 'lodash';
import { useToggle } from '../../../../hooks/useToggle';
import { useObjektAufteilungsschluesselListQuery } from '../gql/ObjektAufteilungsschluesselQueries.types';
import ObjektAufteilungsschluesselListingBtnAndModal from './ButtonsAndModal/ObjektAufteilungsschluesselListingBtnAndModal';
import ObjektAufteilungsschluesselTable from '../Table/ObjektAufteilungsschluesselTable';

type ObjektAufteilungsschluesselTableProps = {
  objektId: string;
  isWegObjekt: boolean;
};

const ObjektAufteilungsschluesselListing: FC<ObjektAufteilungsschluesselTableProps> = ({ objektId, isWegObjekt }) => {
  const [isCollapsed, onCollapse] = useToggle();
  const [showArchived, toggleShowArchived] = useToggle();

  const { data, refetch } = useObjektAufteilungsschluesselListQuery({
    variables: {
      objektId,
      includeArchiviert: showArchived,
    },
  });
  const aufteilungsschluesselList = data?.getObjektAufteilungsschluesselList.data;
  const loading = !aufteilungsschluesselList;

  return (
    <>
      <ObjektAufteilungsschluesselListingBtnAndModal
        objektId={objektId}
        isCollapsed={isCollapsed}
        onCollapse={onCollapse}
        showArchived={showArchived}
        toggleShowArchived={toggleShowArchived}
        showZuweisenBtn={!isEmpty(aufteilungsschluesselList)}
        refetch={refetch}
        isWegObjekt={isWegObjekt}
      />
      <ObjektAufteilungsschluesselTable
        aufteilungsschluesselList={aufteilungsschluesselList}
        loading={loading}
        objektId={objektId}
        onCollapse={onCollapse}
        refetch={refetch}
        isWegObjekt={isWegObjekt}
      />
    </>
  );
};

export default ObjektAufteilungsschluesselListing;
