import React, { FC } from 'react';
import { Skeleton } from 'antd';
import BuchungsmaskeForm from './Form/BuchungsmaskeForm';
import { BookingSuggestion } from '../../../types';

type Props = {
  bookingSuggestion?: BookingSuggestion;
  bookingZahlungId?: string | null;
  isBookingSuggestionLoading: boolean;
  onCancel: () => void;
  onUpdate: (isBuchungsanweisungChanged: boolean) => void;
};

const BookingDetailsEdit: FC<Props> = ({ bookingSuggestion, bookingZahlungId, isBookingSuggestionLoading, onCancel, onUpdate }) => {
  /******** render spin if no data is being loaded ********/
  if (isBookingSuggestionLoading) {
    return <Skeleton active />;
  }

  return (
    <BuchungsmaskeForm
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      bookingSuggestion={bookingSuggestion!}
      bookingZahlungId={bookingZahlungId}
      onCancel={onCancel}
      onUpdate={onUpdate}
    />
  );
};

export default BookingDetailsEdit;
