import {
  HeAbrechnungOutputOptions,
  HeAbrechnungOutputOptionsInput,
  SubAdministrationAbrechnungOutputOptions,
  SubAdministrationAbrechnungOutputOptionsInput,
} from '../../../../../../../types';
import { FormFields } from '../../../../../../../helpers/formikHelper';

export type HeOrSubAbrDefGeneralSettingsFormValues = {
  displayKontonummer: boolean;
  rueckstaendeAnzeigen: boolean;
  rueckstaendeInAbrechnungsergebnis: boolean;
};

export const heOrSubAbrDefGeneralSettingsFormFields: FormFields<HeOrSubAbrDefGeneralSettingsFormValues> = {
  displayKontonummer: 'displayKontonummer',
  rueckstaendeAnzeigen: 'rueckstaendeAnzeigen',
  rueckstaendeInAbrechnungsergebnis: 'rueckstaendeInAbrechnungsergebnis',
};

export const heOrSubAbrDefGeneralSettingsFormInitialValues: HeOrSubAbrDefGeneralSettingsFormValues = {
  displayKontonummer: false,
  rueckstaendeAnzeigen: false,
  rueckstaendeInAbrechnungsergebnis: false,
};

export const mapHeOrSubAbrDefOutputOptionsToFormValues = (
  objektAbrechnungOutputOptions: HeAbrechnungOutputOptions | SubAdministrationAbrechnungOutputOptions
): HeOrSubAbrDefGeneralSettingsFormValues => ({
  displayKontonummer: objektAbrechnungOutputOptions.displayKontonummer ?? false,
  rueckstaendeAnzeigen: objektAbrechnungOutputOptions.rueckstaendeAnzeigen,
  rueckstaendeInAbrechnungsergebnis: objektAbrechnungOutputOptions.rueckstaendeInAbrechnungsergebnis,
});

export const mapHeOrSubAbrDefGenSetFormValuesToOutputOptions = (
  generalSettingsValues: HeOrSubAbrDefGeneralSettingsFormValues
): HeAbrechnungOutputOptionsInput | SubAdministrationAbrechnungOutputOptionsInput => ({
  displayKontonummer: generalSettingsValues.displayKontonummer,
  rueckstaendeAnzeigen: generalSettingsValues.rueckstaendeAnzeigen,
  rueckstaendeInAbrechnungsergebnis: generalSettingsValues.rueckstaendeInAbrechnungsergebnis,
});
