import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { EuroAmount } from '../../../components/Number';
import { useFirmendatenSettings } from '../../../shared/FirmendatenSettings/FirmendatenSettingsProvider';
import { FibuBelegSymbol, FibuBuchungszeile } from '../../../types';
import { generatePathToBookingDetailsPage } from '../../BookingDetails/bookingDetailsPaths';

type Props = {
  buchungszeile: FibuBuchungszeile;
};

const KontoblattTableColumnBetrag: FC<Props> = ({ buchungszeile }) => {
  const { calculateBetragBasedOnSettings } = useFirmendatenSettings();

  const belegSymbolArtList = [
    FibuBelegSymbol.Mahnung,
    FibuBelegSymbol.Zahlung,
    FibuBelegSymbol.SpesenRuecklastschrift,
    FibuBelegSymbol.Ruecklastschrift,
  ];
  const incomingBelegSymbolArtList = [FibuBelegSymbol.EingangsRechnung];
  const outgoingBelegSymbolArtList = [
    FibuBelegSymbol.Vorschreibung,
    FibuBelegSymbol.Einzelrechnung,
    FibuBelegSymbol.Gutschrift,
    FibuBelegSymbol.StornoRechnung,
  ];
  const buchungszeileBelegSymbolArt = buchungszeile.buchungsKopf.belegSymbol.value;

  const betrag = calculateBetragBasedOnSettings(buchungszeile.sollHaben.value, buchungszeile.betrag);

  if (belegSymbolArtList.includes(buchungszeileBelegSymbolArt)) {
    return (
      <Link to={generatePathToBookingDetailsPage([buchungszeile.buchungId])}>
        <EuroAmount value={betrag} />
      </Link>
    );
  } else if (outgoingBelegSymbolArtList.includes(buchungszeileBelegSymbolArt) && buchungszeile.buchungsKopf.auftragId) {
    return (
      <Link to={generatePathToBookingDetailsPage([buchungszeile.buchungId], buchungszeile.buchungsKopf.auftragId)}>
        <EuroAmount value={betrag} />
      </Link>
    );
  } else if (incomingBelegSymbolArtList.includes(buchungszeileBelegSymbolArt) && buchungszeile.buchungsKopf.belegId) {
    return (
      <Link to={generatePathToBookingDetailsPage([buchungszeile.buchungId], undefined, buchungszeile.buchungsKopf.belegId)}>
        <EuroAmount value={betrag} />
      </Link>
    );
  } else {
    return <EuroAmount value={betrag} />;
  }
};

export default KontoblattTableColumnBetrag;
