import React, { FC } from 'react';
import { Empty } from 'antd';
import { useSaldenlisteQuery } from '../gql/SaldenlisteQueries.types';
import saldenlisteKontoTableColumns from './saldenlisteKontoTableColumns';
import { SaldenlisteKonto, SaldenlisteKontoType } from '../../../types';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../hooks/useQueryWithPagingAndSorting';
import TableWithColSelector from '../../../components/Table/TableWithColSelector/TableWithColSelector';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';

type SaldenlisteKontoTableProps = {
  buchungskreisId: string;
  date: string;
  objektId?: string;
  kontoType?: SaldenlisteKontoType;
};

const SaldenlisteKontoTable: FC<SaldenlisteKontoTableProps> = ({ buchungskreisId, date, objektId, kontoType }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useSaldenlisteQuery,
    {
      variables: { buchungskreisId, objektId, buchungsmonat: date, type: kontoType },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );
  const saldenliste = data?.getSaldenliste.data?.contentList;

  return (
    <TableWithColSelector<SaldenlisteKonto>
      size="small"
      locale={{
        emptyText: <Empty description={<span>Keine Saldenliste vorhanden</span>} />,
      }}
      scroll={{ x: 900 }}
      loading={loading}
      pagination={pagination}
      columns={saldenlisteKontoTableColumns(date, objektId, buchungskreisId, saldenliste?.[0])}
      dataSource={saldenliste}
      rowKey={(record) => record.konto.kontoId}
      onChange={handleTableSorting}
      filterIdentifier="main-saldenliste-konto"
    />
  );
};

export default SaldenlisteKontoTable;
