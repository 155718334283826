import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../../../shared/dataChangedSubscription';
import { DataChangedChangeType } from '../../../../types';

export const useOnBankstatementDataChangeEvents = (key: string, callback: () => Promise<unknown>) => {
  useOnDataChangedEvent([
    {
      filter: {
        type: dataChangedSubscriptionTypes.camt,
        params: {},
        changeType: DataChangedChangeType.Create,
      },
      notificationDescription: 'Datenträger wurden gerade erstellt',
      key: `${key}-create`,
      callback,
    },
  ]);
};
