import { mapToQueryString, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { GenerierlaufEntryExitCode } from '../../../../../types';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../../verarbeitungApiPaths';

enum ObjektFinancialPlanVerarbeitungQueryParamKey {
  BUDGETING_ID = 'budgetingId',
  EXIT_CODE_LIST = 'exitCodeList',
  OBJEKT_ID = 'objektId',
  RECHNUNGSAUSSTELLER_ID = 'rechnungsAusstellerId',
}

export type ObjektFinancialPlanVerarbeitungQueryParams = QueryParams<{
  budgetingId: string;
  exitCodeList: GenerierlaufEntryExitCode[];
  objektId: string;
  rechnungsAusstellerId: string;
}>;

export const updateQueryParams = (
  verarbeitungType: string,
  generierlaufId: string,
  navigate: (path: string) => void,
  filters: ObjektFinancialPlanVerarbeitungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => {
  navigate(
    generatePathToObjektFinancialPlanListPage(
      verarbeitungType,
      generierlaufId,
      filters.budgetingId,
      filters.exitCodeList,
      filters.objektId,
      filters.rechnungsAusstellerId,
      paginationParams
    )
  );
};

const generatePathToObjektFinancialPlanListPage = (
  verarbeitungType: string,
  generierlaufId: string,
  budgetingId?: string,
  exitCodeList?: GenerierlaufEntryExitCode[],
  objektId?: string,
  rechnungsausstellerId?: string,
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [ObjektFinancialPlanVerarbeitungQueryParamKey.BUDGETING_ID]: budgetingId,
    [ObjektFinancialPlanVerarbeitungQueryParamKey.EXIT_CODE_LIST]: exitCodeList,
    [ObjektFinancialPlanVerarbeitungQueryParamKey.OBJEKT_ID]: objektId,
    [ObjektFinancialPlanVerarbeitungQueryParamKey.RECHNUNGSAUSSTELLER_ID]: rechnungsausstellerId,
    ...paginationParams,
  })}`;
