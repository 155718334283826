import { FM_MITARBEITER, FM_SYSTEM_ADMIN } from './funktionsModulTypes';
import { UserRole } from '../types';

type PermissionInfo = {
  role?: UserRole;
  funktionsModulList?: string[];
  activeForFirmendatenId?: string | null;
};

export interface HasPermissionFunction {
  (permissionInfo: PermissionInfo): boolean;
}

export const isAdmin: HasPermissionFunction = ({ role }) => role === UserRole.Admin;
export const isUser: HasPermissionFunction = ({ role }) => role === UserRole.User;

export const hasSystemAdminFm: HasPermissionFunction = ({ funktionsModulList }) => hasFm({ funktionsModulList, fmToFind: FM_SYSTEM_ADMIN });

export const hasSystemAdminOrMitarbeiterFm: HasPermissionFunction = ({ funktionsModulList }) =>
  hasFm({
    funktionsModulList,
    fmToFind: FM_SYSTEM_ADMIN,
  }) || hasFm({ funktionsModulList, fmToFind: FM_MITARBEITER });

export const isAdminOrHasSystemAdminFm: HasPermissionFunction = (permissionInfo) => isAdmin(permissionInfo) || hasSystemAdminFm(permissionInfo);

export const isAdminWithSelectedFirmendatenOrHasSystemAdminFm: HasPermissionFunction = (permissionInfo) =>
  (isAdmin(permissionInfo) && isActiveForSomeFirmendaten(permissionInfo)) || hasSystemAdminFm(permissionInfo);

export const isAdminOrHasSystemAdminOrMitarbeiterFm: HasPermissionFunction = ({ role, funktionsModulList }) =>
  isAdmin({ role }) || hasSystemAdminOrMitarbeiterFm({ funktionsModulList });

export const isActiveForSomeFirmendaten: HasPermissionFunction = ({ activeForFirmendatenId }) => !!activeForFirmendatenId;

interface HasFmParameters {
  funktionsModulList?: string[];
  fmToFind: string;
}

const hasFm = ({ funktionsModulList, fmToFind }: HasFmParameters) => (funktionsModulList ? funktionsModulList.includes(fmToFind) : false);
