import React, { FC } from 'react';
import { Col, Divider, Row, Space, Typography } from 'antd';
import { Switch } from 'formik-antd';
import { FormikProps } from 'formik';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import { incomingInvoiceFormFields, IncomingInvoiceFormValues } from './incomingInvoiceFormMapper';
import PositionListFormFieldArray from './PositionListFormFieldArray';
import FormBlockRow from './shared/FormBlockRow';
import FormattedDecimalFormInput from '../../../components/Input-antd/FormattedDecimalFormInput';

type Props = {
  formikProps: FormikProps<IncomingInvoiceFormValues>;
  shouldRestore: boolean;
};

const BuchungPositionBlockForm: FC<Props> = ({ formikProps, shouldRestore }) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const isBuchungPositionListEmpty = formikProps.values.buchungPositionList!.length === 0;

  return (
    <>
      <FormBlockRow
        rowTitle="Buchungspositionen"
        content={
          <>
            <Row gutter={16} style={{ marginTop: 0, justifyContent: 'space-between' }}>
              <Col span={12}>
                <FormItemWithoutColon name={incomingInvoiceFormFields.inklusiveSteuer} label="Umsatzsteuer">
                  <Space>
                    <Switch disabled={shouldRestore} size="default" name={incomingInvoiceFormFields.inklusiveSteuer} />
                    <Typography.Text>{formikProps.values.inklusiveSteuer ? 'inklusive' : 'exklusive'}</Typography.Text>
                  </Space>
                </FormItemWithoutColon>
              </Col>
              <Col span={12}>
                <FormItemWithoutColon name={incomingInvoiceFormFields.totalAmountOfBuchungsposition} label="Gesamtbetrag Brutto">
                  <FormattedDecimalFormInput name={incomingInvoiceFormFields.totalAmountOfBuchungsposition} style={{ width: '100%' }} />
                </FormItemWithoutColon>
              </Col>
            </Row>
            <FormItemWithoutColon
              name={incomingInvoiceFormFields.buchungPositionList}
              help={
                isBuchungPositionListEmpty && formikProps.touched.buchungPositionList && formikProps.errors?.buchungPositionList
                  ? (formikProps.errors?.buchungPositionList as string)
                  : null
              }
              validateStatus={
                isBuchungPositionListEmpty && formikProps.touched.buchungPositionList && formikProps.errors.buchungPositionList ? 'error' : undefined
              }
            >
              <PositionListFormFieldArray
                name={incomingInvoiceFormFields.buchungPositionList}
                shouldRestore={shouldRestore}
                /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                buchungPositionList={formikProps.values.buchungPositionList!}
              />
            </FormItemWithoutColon>
          </>
        }
      />
      <Divider />
    </>
  );
};

export default BuchungPositionBlockForm;
