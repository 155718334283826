import { FC } from 'react';
import HistoryListingTable from '../../../History/HistoryListingTable';
import { HistoryType } from '../../../History/shared/HistoryModal';
import { useKontoChangeHistoryListQuery } from '../../gql/KontenplanQueries.types';

type Props = {
  kontoId: string;
};

const KontoChangeHistoryListingTable: FC<Props> = ({ kontoId }) => {
  const { data, loading } = useKontoChangeHistoryListQuery({ variables: { kontoId } });
  const historyList = data?.getKontoChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="konto" />;
};

export default KontoChangeHistoryListingTable;
