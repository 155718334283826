import React, { FC } from 'react';
import { MenuProps, Modal } from 'antd';
import { EyeOutlined, FormOutlined } from '@ant-design/icons';
import { LinkBreak1Icon } from '@radix-ui/react-icons';
import ActionDropdown from '../../../../../../components/Dropdown/ActionDropdown';
import {
  useMarkReceivedBankTransactionAsNotedMutation,
  useRemoveAssignmentReceivedBankTransactionMutation,
} from '../../../../../ReceivedBankTransaction/gql/ReceivedBankTransactionMutations.types';
import { showSuccessMsgOther } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { radixActionStyles } from '../../../../../../helpers/radixIconsStyles';
import { generatePathToBookingSuggestionBuchungsmaskeWithTabsPage } from '../../../../../BookingSuggestion/bookingSuggestionBuchungsmaskePaths';
import { ReceivedBankTransactionListEntry } from '../../../../../../types';
import receivedBankTransactionTableActionForBuchungDetails from '../../../../../ReceivedBankTransaction/shared/ReceivedBankTransactionTableActionForBuchungDetails';

type Props = {
  onSuccess: () => void;
  receivedBankTransactionId: string;
  receivedBankTransaction: ReceivedBankTransactionListEntry;
};

const ReceivedBankTransactionTableActions: FC<Props> = ({ receivedBankTransaction, receivedBankTransactionId, onSuccess }) => {
  const [runMarkAsNoted] = useMarkReceivedBankTransactionAsNotedMutation({
    variables: { receivedBankTransactionId },
    onCompleted: () => {
      showSuccessMsgOther('Empfangene Bankbuchung wurde als gesehen markiert');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runRemoveAssignemt] = useRemoveAssignmentReceivedBankTransactionMutation({
    variables: {
      receivedBankTransactionId,
    },
    onCompleted: () => {
      showSuccessMsgOther('Zahlungszuweisung wurde entfernt');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    !receivedBankTransaction.noted
      ? {
          key: '1',
          label: 'Als Gesehen markieren',
          icon: <EyeOutlined />,
          onClick: () => {
            runMarkAsNoted();
          },
        }
      : null,
    {
      key: '2',
      label: `Zahlungszuweisung entfernen`,
      icon: <LinkBreak1Icon style={radixActionStyles} />,
      onClick: showConfirmRemoveAssignment(runRemoveAssignemt),
    },
    receivedBankTransaction.booked
      ? { ...receivedBankTransactionTableActionForBuchungDetails('3', receivedBankTransaction) }
      : {
          key: '3',
          label: 'Buchungsvorschlag bearbeiten',
          onClick: () => window.open(generatePathToBookingSuggestionBuchungsmaskeWithTabsPage(receivedBankTransactionId)),
          icon: <FormOutlined />,
        },
  ];

  return <ActionDropdown items={items} />;
};

const showConfirmRemoveAssignment = (runRemoveAssignment: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie die Zahlungszuweisung entfernen?`,
    okText: 'Entfernen',
    cancelText: 'Abbrechen',
    onOk() {
      return runRemoveAssignment();
    },
  });
};

export default ReceivedBankTransactionTableActions;
