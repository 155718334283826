import React from 'react';
import { Avatar, Button, Card, Divider, Dropdown, MenuProps, Space, Typography } from 'antd';
import { EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { useToggle } from '../../hooks/useToggle';
import CardWithFooter from '../../components/Card';
import { useUserMeQuery } from './gql/UserQueries.types';
import { concatFirstNameLetters } from '../../helpers/nameHelper';
import { StyledSkeleton } from '../../components/Loading';
import EmailUpdateWithPasswordForm from '../../shared/components/Email/EmailUpdateForm';
import ChangePasswordForm from './Form/ChangePasswordForm';
import StatusBadge from '../../components/Status/StatusBadge';
import AdminUpdateForm from '../Admin/Form/Update/AdminUpdateForm';
import { userToString } from '../../helpers/stringHelper';
import CardTitle from '../../components/Card/CardTitle';
import ProfileCardDrawer from './ProfileCardDrawer';
import CardDetailsInfoRow from '../../components/Card/CardDetailsInfoRow';
import DataWithShortenedText from '../../components/Helpers/DataWithShortenedText';

const ProfileCardAdmin = () => {
  const [isAdminDrawerOpen, toggleAdminDrawerOpen] = useToggle();
  const [isEmailDrawerOpen, toggleEmailDrawerOpen] = useToggle();
  const [isPasswordDrawerOpen, togglPasswordDrawerOpen] = useToggle();

  const { loading, data: maybeUserData, refetch } = useUserMeQuery({ notifyOnNetworkStatusChange: true });
  const user = maybeUserData?.getMe.data;

  const items: MenuProps['items'] = [{ key: '1', label: 'Passwort ändern', onClick: togglPasswordDrawerOpen, icon: <EditOutlined /> }];

  if (!user) return <StyledSkeleton loading={loading} active paragraph={{ rows: 4 }} />;

  return (
    <>
      <CardWithFooter
        loading={loading}
        style={{ minWidth: '320px' }}
        title={<CardTitle status={user.status} title={userToString(user)} />}
        extra={
          <Dropdown menu={{ items }}>
            <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
          </Dropdown>
        }
        createTs={user.createdTs}
      >
        <Card.Meta
          avatar={<Avatar>{concatFirstNameLetters(user?.firstname, user?.lastname)}</Avatar>}
          title={user.username}
          description={<StatusBadge entityStatus={user.status} showBadgeText={false} />}
        />
        <Divider dashed />

        <Typography.Title level={5}>Persönliche Daten</Typography.Title>
        <CardDetailsInfoRow infoRowTitle="Name">
          {(user?.firstname || user?.lastname) && (
            <Typography.Text>
              {user.firstname} {user.lastname}
            </Typography.Text>
          )}
        </CardDetailsInfoRow>
        <CardDetailsInfoRow infoRowTitle="Username">{user.username}</CardDetailsInfoRow>
        <CardDetailsInfoRow infoRowTitle="E-Mail">
          <Space direction="vertical">
            <DataWithShortenedText maxTextLength={25} text={user.email ?? '-'} />
            <StatusBadge entityStatus={user.status} />
          </Space>
        </CardDetailsInfoRow>
      </CardWithFooter>
      <ProfileCardDrawer title="Personönliche Daten bearbeiten" isCollapsed={isAdminDrawerOpen} onCollapse={toggleAdminDrawerOpen}>
        <AdminUpdateForm admin={user} onSuccess={refetch} onCancel={toggleAdminDrawerOpen} />
      </ProfileCardDrawer>
      <ProfileCardDrawer title="E-Mail-Adresse bearbeiten" isCollapsed={isEmailDrawerOpen} onCollapse={toggleEmailDrawerOpen}>
        <EmailUpdateWithPasswordForm user={user} mitarbeiter={null} onCancel={toggleEmailDrawerOpen} onSuccess={refetch} />
      </ProfileCardDrawer>
      <ProfileCardDrawer title="Passwort ändern" isCollapsed={isPasswordDrawerOpen} onCollapse={togglPasswordDrawerOpen}>
        <ChangePasswordForm onCancel={togglPasswordDrawerOpen} onSuccess={refetch} />
      </ProfileCardDrawer>
    </>
  );
};

export default ProfileCardAdmin;
