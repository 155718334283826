import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KuendigungsfristListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type KuendigungsfristListQuery = {
  getKuendigungsfristList: {
    data: Array<{ text: string; value: Types.Kuendigungsfrist }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const KuendigungsfristListDocument = gql`
  query KuendigungsfristList {
    getKuendigungsfristList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useKuendigungsfristListQuery(baseOptions?: Apollo.QueryHookOptions<KuendigungsfristListQuery, KuendigungsfristListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KuendigungsfristListQuery, KuendigungsfristListQueryVariables>(KuendigungsfristListDocument, options);
}
export function useKuendigungsfristListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KuendigungsfristListQuery, KuendigungsfristListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KuendigungsfristListQuery, KuendigungsfristListQueryVariables>(KuendigungsfristListDocument, options);
}
export function useKuendigungsfristListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KuendigungsfristListQuery, KuendigungsfristListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KuendigungsfristListQuery, KuendigungsfristListQueryVariables>(KuendigungsfristListDocument, options);
}
export type KuendigungsfristListQueryHookResult = ReturnType<typeof useKuendigungsfristListQuery>;
export type KuendigungsfristListLazyQueryHookResult = ReturnType<typeof useKuendigungsfristListLazyQuery>;
export type KuendigungsfristListSuspenseQueryHookResult = ReturnType<typeof useKuendigungsfristListSuspenseQuery>;
export type KuendigungsfristListQueryResult = Apollo.QueryResult<KuendigungsfristListQuery, KuendigungsfristListQueryVariables>;
