import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateMahndefinitionTemplateMutationVariables = Types.Exact<{
  input: Types.MahndefinitionCreateInput;
}>;

export type CreateMahndefinitionTemplateMutation = {
  createMahndefinitionTemplate: {
    data: { mahndefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateMahndefinitionTemplateMutationVariables = Types.Exact<{
  mahndefinitionId: Types.Scalars['ID']['input'];
  input: Types.MahndefinitionUpdateInput;
}>;

export type UpdateMahndefinitionTemplateMutation = {
  updateMahndefinitionTemplate: {
    data: { mahndefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteMahndefinitionTemplateMutationVariables = Types.Exact<{
  mahndefinitionId: Types.Scalars['ID']['input'];
}>;

export type DeleteMahndefinitionTemplateMutation = {
  deleteMahndefinitionTemplate: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateMahndefinitionTemplateDocument = gql`
  mutation CreateMahndefinitionTemplate($input: MahndefinitionCreateInput!) {
    createMahndefinitionTemplate(input: $input) {
      data {
        mahndefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateMahndefinitionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMahndefinitionTemplateMutation, CreateMahndefinitionTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMahndefinitionTemplateMutation, CreateMahndefinitionTemplateMutationVariables>(
    CreateMahndefinitionTemplateDocument,
    options
  );
}
export type CreateMahndefinitionTemplateMutationHookResult = ReturnType<typeof useCreateMahndefinitionTemplateMutation>;
export type CreateMahndefinitionTemplateMutationResult = Apollo.MutationResult<CreateMahndefinitionTemplateMutation>;
export type CreateMahndefinitionTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateMahndefinitionTemplateMutation,
  CreateMahndefinitionTemplateMutationVariables
>;
export const UpdateMahndefinitionTemplateDocument = gql`
  mutation UpdateMahndefinitionTemplate($mahndefinitionId: ID!, $input: MahndefinitionUpdateInput!) {
    updateMahndefinitionTemplate(mahndefinitionId: $mahndefinitionId, input: $input) {
      data {
        mahndefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateMahndefinitionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMahndefinitionTemplateMutation, UpdateMahndefinitionTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMahndefinitionTemplateMutation, UpdateMahndefinitionTemplateMutationVariables>(
    UpdateMahndefinitionTemplateDocument,
    options
  );
}
export type UpdateMahndefinitionTemplateMutationHookResult = ReturnType<typeof useUpdateMahndefinitionTemplateMutation>;
export type UpdateMahndefinitionTemplateMutationResult = Apollo.MutationResult<UpdateMahndefinitionTemplateMutation>;
export type UpdateMahndefinitionTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateMahndefinitionTemplateMutation,
  UpdateMahndefinitionTemplateMutationVariables
>;
export const DeleteMahndefinitionTemplateDocument = gql`
  mutation DeleteMahndefinitionTemplate($mahndefinitionId: ID!) {
    deleteMahndefinitionTemplate(mahndefinitionId: $mahndefinitionId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteMahndefinitionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteMahndefinitionTemplateMutation, DeleteMahndefinitionTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMahndefinitionTemplateMutation, DeleteMahndefinitionTemplateMutationVariables>(
    DeleteMahndefinitionTemplateDocument,
    options
  );
}
export type DeleteMahndefinitionTemplateMutationHookResult = ReturnType<typeof useDeleteMahndefinitionTemplateMutation>;
export type DeleteMahndefinitionTemplateMutationResult = Apollo.MutationResult<DeleteMahndefinitionTemplateMutation>;
export type DeleteMahndefinitionTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteMahndefinitionTemplateMutation,
  DeleteMahndefinitionTemplateMutationVariables
>;
