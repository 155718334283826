import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetObjektAufteilungsschluesselListMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  input: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;

export type SetObjektAufteilungsschluesselListMutation = {
  setObjektAufteilungsschluesselList: {
    data: Array<{
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      createTs: string;
      aufteilungsschluesselId: string;
      kurzBezeichnung: string;
      bezeichnung: string;
      bezeichnungOriginal: string;
      bezeichnungOverwritten?: string | null;
      objektAufteilungsschluesselId: string;
      aufteilungsschluesselStatus: { description?: string | null; text: string; value: Types.AufteilungsschluesselStatus };
      verwendung?: { text: string; value: Types.ObjektAufteilungsschluesselVerwendung } | null;
      masseinheit: { text: string; value: Types.Masseinheit };
      objektAufteilungsschluesselStatus: { description?: string | null; text: string; value: Types.ObjektAufteilungsschluesselStatus };
      bezugsbasis: { text: string; value: Types.Bezugsbasis };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateObjektAufteilungsschluesselMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  objektAufteilungsschluesselId: Types.Scalars['ID']['input'];
  input: Types.ObjektAufteilungsschluesselUpdateInput;
}>;

export type UpdateObjektAufteilungsschluesselMutation = {
  updateObjektAufteilungsschluessel: {
    data: {
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      createTs: string;
      aufteilungsschluesselId: string;
      kurzBezeichnung: string;
      bezeichnung: string;
      bezeichnungOriginal: string;
      bezeichnungOverwritten?: string | null;
      objektAufteilungsschluesselId: string;
      aufteilungsschluesselStatus: { description?: string | null; text: string; value: Types.AufteilungsschluesselStatus };
      verwendung?: { text: string; value: Types.ObjektAufteilungsschluesselVerwendung } | null;
      masseinheit: { text: string; value: Types.Masseinheit };
      objektAufteilungsschluesselStatus: { description?: string | null; text: string; value: Types.ObjektAufteilungsschluesselStatus };
      bezugsbasis: { text: string; value: Types.Bezugsbasis };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ArchiveObjektAufteilungsschluesselMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  objektAufteilungsschluesselId: Types.Scalars['ID']['input'];
}>;

export type ArchiveObjektAufteilungsschluesselMutation = {
  actionArchiveObjektAufteilungsschluessel: {
    data: { objektAufteilungsschluesselId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveObjektAufteilungsschluesselMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  objektAufteilungsschluesselId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveObjektAufteilungsschluesselMutation = {
  actionUnarchiveObjektAufteilungsschluessel: {
    data: { objektAufteilungsschluesselId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const SetObjektAufteilungsschluesselListDocument = gql`
  mutation SetObjektAufteilungsschluesselList($objektId: ID!, $input: [String!]!) {
    setObjektAufteilungsschluesselList(objektId: $objektId, input: $input) {
      data {
        createdBy
        createdByMitarbeiterId
        createTs
        aufteilungsschluesselId
        aufteilungsschluesselStatus {
          description
          text
          value
        }
        kurzBezeichnung
        bezeichnung
        bezeichnungOriginal
        bezeichnungOverwritten
        verwendung {
          text
          value
        }
        masseinheit {
          text
          value
        }
        objektAufteilungsschluesselId
        objektAufteilungsschluesselStatus {
          description
          text
          value
        }
        bezugsbasis {
          text
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSetObjektAufteilungsschluesselListMutation(
  baseOptions?: Apollo.MutationHookOptions<SetObjektAufteilungsschluesselListMutation, SetObjektAufteilungsschluesselListMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetObjektAufteilungsschluesselListMutation, SetObjektAufteilungsschluesselListMutationVariables>(
    SetObjektAufteilungsschluesselListDocument,
    options
  );
}
export type SetObjektAufteilungsschluesselListMutationHookResult = ReturnType<typeof useSetObjektAufteilungsschluesselListMutation>;
export type SetObjektAufteilungsschluesselListMutationResult = Apollo.MutationResult<SetObjektAufteilungsschluesselListMutation>;
export type SetObjektAufteilungsschluesselListMutationOptions = Apollo.BaseMutationOptions<
  SetObjektAufteilungsschluesselListMutation,
  SetObjektAufteilungsschluesselListMutationVariables
>;
export const UpdateObjektAufteilungsschluesselDocument = gql`
  mutation UpdateObjektAufteilungsschluessel($objektId: ID!, $objektAufteilungsschluesselId: ID!, $input: ObjektAufteilungsschluesselUpdateInput!) {
    updateObjektAufteilungsschluessel(objektId: $objektId, objektAufteilungsschluesselId: $objektAufteilungsschluesselId, input: $input) {
      data {
        createdBy
        createdByMitarbeiterId
        createTs
        aufteilungsschluesselId
        aufteilungsschluesselStatus {
          description
          text
          value
        }
        kurzBezeichnung
        bezeichnung
        bezeichnungOriginal
        bezeichnungOverwritten
        verwendung {
          text
          value
        }
        masseinheit {
          text
          value
        }
        objektAufteilungsschluesselId
        objektAufteilungsschluesselStatus {
          description
          text
          value
        }
        bezugsbasis {
          text
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateObjektAufteilungsschluesselMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateObjektAufteilungsschluesselMutation, UpdateObjektAufteilungsschluesselMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateObjektAufteilungsschluesselMutation, UpdateObjektAufteilungsschluesselMutationVariables>(
    UpdateObjektAufteilungsschluesselDocument,
    options
  );
}
export type UpdateObjektAufteilungsschluesselMutationHookResult = ReturnType<typeof useUpdateObjektAufteilungsschluesselMutation>;
export type UpdateObjektAufteilungsschluesselMutationResult = Apollo.MutationResult<UpdateObjektAufteilungsschluesselMutation>;
export type UpdateObjektAufteilungsschluesselMutationOptions = Apollo.BaseMutationOptions<
  UpdateObjektAufteilungsschluesselMutation,
  UpdateObjektAufteilungsschluesselMutationVariables
>;
export const ArchiveObjektAufteilungsschluesselDocument = gql`
  mutation archiveObjektAufteilungsschluessel($objektId: ID!, $objektAufteilungsschluesselId: ID!) {
    actionArchiveObjektAufteilungsschluessel(objektId: $objektId, objektAufteilungsschluesselId: $objektAufteilungsschluesselId) {
      data {
        objektAufteilungsschluesselId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveObjektAufteilungsschluesselMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveObjektAufteilungsschluesselMutation, ArchiveObjektAufteilungsschluesselMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveObjektAufteilungsschluesselMutation, ArchiveObjektAufteilungsschluesselMutationVariables>(
    ArchiveObjektAufteilungsschluesselDocument,
    options
  );
}
export type ArchiveObjektAufteilungsschluesselMutationHookResult = ReturnType<typeof useArchiveObjektAufteilungsschluesselMutation>;
export type ArchiveObjektAufteilungsschluesselMutationResult = Apollo.MutationResult<ArchiveObjektAufteilungsschluesselMutation>;
export type ArchiveObjektAufteilungsschluesselMutationOptions = Apollo.BaseMutationOptions<
  ArchiveObjektAufteilungsschluesselMutation,
  ArchiveObjektAufteilungsschluesselMutationVariables
>;
export const UnarchiveObjektAufteilungsschluesselDocument = gql`
  mutation unarchiveObjektAufteilungsschluessel($objektId: ID!, $objektAufteilungsschluesselId: ID!) {
    actionUnarchiveObjektAufteilungsschluessel(objektId: $objektId, objektAufteilungsschluesselId: $objektAufteilungsschluesselId) {
      data {
        objektAufteilungsschluesselId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveObjektAufteilungsschluesselMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveObjektAufteilungsschluesselMutation, UnarchiveObjektAufteilungsschluesselMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveObjektAufteilungsschluesselMutation, UnarchiveObjektAufteilungsschluesselMutationVariables>(
    UnarchiveObjektAufteilungsschluesselDocument,
    options
  );
}
export type UnarchiveObjektAufteilungsschluesselMutationHookResult = ReturnType<typeof useUnarchiveObjektAufteilungsschluesselMutation>;
export type UnarchiveObjektAufteilungsschluesselMutationResult = Apollo.MutationResult<UnarchiveObjektAufteilungsschluesselMutation>;
export type UnarchiveObjektAufteilungsschluesselMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveObjektAufteilungsschluesselMutation,
  UnarchiveObjektAufteilungsschluesselMutationVariables
>;
