import React, { FC } from 'react';
import { Button, Drawer, Dropdown, MenuProps, Modal, Tabs, TabsProps } from 'antd';
import { DeleteOutlined, DownOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { Box } from 'rebass';
import { useToggle } from '../../../hooks/useToggle';
import { showSuccessMsgDelete } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { AbrechnungsdefinitionListEntry } from '../../../types';
import { useDeleteAbrechnungsdefinitionTemplateMutation } from '../gql/AbrDefTemplateMutations.types';
import AbrDefTemplateUpdateForm from '../Form/Update/AbrDefTemplateUpdateForm';
import AbrDefVersionTemplateTimelineWithActions from '../Version/Timeline/AbrDefVersionTemplateTimelineWithActions';

type Props = {
  abrechnungsdefinition: AbrechnungsdefinitionListEntry;
  onActionSuccess: () => void;
};

const AbrDefTemplateTableActions: FC<Props> = ({ abrechnungsdefinition, onActionSuccess }) => {
  const [isCollapsed, onCollapse] = useToggle();

  const [runDelete] = useDeleteAbrechnungsdefinitionTemplateMutation({
    variables: {
      abrechnungsdefinitionId: abrechnungsdefinition.abrechnungsdefinitionId,
    },
    onCompleted: () => {
      showSuccessMsgDelete('Abrechnunsdefinition');
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    { key: '1', label: 'Bearbeiten', onClick: onCollapse, icon: <EditOutlined /> },
    {
      key: '2',
      label: 'Löschen',
      onClick: showConfirmDelete(runDelete, abrechnungsdefinition),
      icon: <DeleteOutlined />,
      disabled: !abrechnungsdefinition.deletable,
      danger: true,
    },
  ];

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span>
          <HistoryOutlined />
          Verlauf
        </span>
      ),
      children: (
        <Box p={16}>
          <AbrDefVersionTemplateTimelineWithActions
            abrechnungsdefinitionId={abrechnungsdefinition.abrechnungsdefinitionId}
            abrDefType={abrechnungsdefinition.type.value}
          />
        </Box>
      ),
    },
    {
      key: '2',
      label: (
        <span>
          <EditOutlined />
          Bearbeiten
        </span>
      ),
      children: (
        <Box p={16}>
          <AbrDefTemplateUpdateForm
            abrechnungsdefinition={abrechnungsdefinition}
            onSuccess={() => {
              onActionSuccess();
              onCollapse();
            }}
            onCancel={onCollapse}
          />
        </Box>
      ),
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Aktion <DownOutlined />
        </a>
      </Dropdown>
      <Drawer
        title="Bearbeiten"
        width={720}
        onClose={onCollapse}
        open={isCollapsed}
        destroyOnClose
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={onCollapse} type="primary">
              Schließen
            </Button>
          </div>
        }
      >
        <Tabs defaultActiveKey="1" items={tabItems} />
      </Drawer>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void, abrechnungsdefinition: AbrechnungsdefinitionListEntry) => () => {
  Modal.confirm({
    title: `Möchten Sie die Abrechnungsdefinition löschen?`,
    content: `${abrechnungsdefinition.bezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default AbrDefTemplateTableActions;
