import { FormFields } from '../../../../../helpers/formikHelper';
import { TicketCommentFieldsFragment } from '../../../gql/TicketFragments.types';

export type TicketCommentFormValues = {
  text: string;
};

export const ticketCommentFormFields: FormFields<TicketCommentFormValues> = {
  text: 'text',
};

export const mapCommentToFormValues = (comment?: TicketCommentFieldsFragment): TicketCommentFormValues => {
  if (!comment) {
    return {
      text: '',
    };
  }

  return {
    text: comment.text,
  };
};

export const mapFormValuesToComment = (formValues: TicketCommentFormValues, maxCommentLength?: number) => ({
  text: formValues.text.slice(0, maxCommentLength),
});
