import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LegalFormListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type LegalFormListQuery = {
  getLegalFormList: {
    data: Array<{ text: string; value: Types.LegalForm }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const LegalFormListDocument = gql`
  query LegalFormList {
    getLegalFormList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useLegalFormListQuery(baseOptions?: Apollo.QueryHookOptions<LegalFormListQuery, LegalFormListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LegalFormListQuery, LegalFormListQueryVariables>(LegalFormListDocument, options);
}
export function useLegalFormListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LegalFormListQuery, LegalFormListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LegalFormListQuery, LegalFormListQueryVariables>(LegalFormListDocument, options);
}
export function useLegalFormListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LegalFormListQuery, LegalFormListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LegalFormListQuery, LegalFormListQueryVariables>(LegalFormListDocument, options);
}
export type LegalFormListQueryHookResult = ReturnType<typeof useLegalFormListQuery>;
export type LegalFormListLazyQueryHookResult = ReturnType<typeof useLegalFormListLazyQuery>;
export type LegalFormListSuspenseQueryHookResult = ReturnType<typeof useLegalFormListSuspenseQuery>;
export type LegalFormListQueryResult = Apollo.QueryResult<LegalFormListQuery, LegalFormListQueryVariables>;
