import React, { FC } from 'react';
import { Formik } from 'formik';
import { Skeleton } from 'antd';
import { Form } from 'formik-antd';

import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import FormButtons from '../../../components/Button/FormButtons';
import { mapTextbausteinToFormValues } from '../../../components/Template/PDFTemplates/templateMapper';
import { useEmailDeliveryDefinitionTemplateQuery } from '../gql/EmailDeliveryDefinitionTemplateQueries.types';
import {
  useCreateEmailDeliveryDefinitionTemplateMutation,
  useUpdateEmailDeliveryDefinitionTemplateMutation,
} from '../gql/EmailDeliveryDefinitionTemplateMutations.types';
import {
  emailDeliveryDefFormInitialValues,
  emailDeliveryDefinitionFormFields,
  EmailDeliveryDefinitionFormValues,
  mapEmailDeliveryDefinitionToFormValues,
  mapFormValuesToCreateEmailDeliveryDefinition,
  mapFormValuesToUpdateEmailDeliveryDefinition,
} from '../../EmailDeliveryDefinition/Form/emailDeliveryDefFormMapper';
import { emailDeliveryDefValidationSchema } from '../../EmailDeliveryDefinition/Form/emailDeliveryDefValidationSchema';
import EmailDeliveryDefGeneralFormItems from '../../EmailDeliveryDefinition/Form/EmailDeliveryDefGeneralFormItems';
import EmailDeliveryTextbausteinFormItem from '../../EmailDeliveryDefinition/Form/EmailDeliveryTextbausteinFormItem';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';

type Props = {
  emailDeliveryDefinitionId?: string;
  onSuccess: () => void;
  onCancel: () => void;
};

const EmailDeliveryDefinitionTemplateForm: FC<Props> = ({ emailDeliveryDefinitionId, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'EmailDeliveryDefinition'>('EmailDeliveryDefinition');

  const isUpdate = !!emailDeliveryDefinitionId;
  const entity = 'E-Mail Versanddefinition';

  const { data, loading } = useEmailDeliveryDefinitionTemplateQuery({
    variables: { emailDeliveryDefinitionId: emailDeliveryDefinitionId ?? '' },
    skip: !isUpdate,
  });

  const emailDeliveryDefinition = data?.getEmailDeliveryDefinitionTemplate.data;

  const [runCreate, { loading: loadingCreate }] = useCreateEmailDeliveryDefinitionTemplateMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateEmailDeliveryDefinitionTemplateMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Skeleton active loading={loading}>
      <Formik<EmailDeliveryDefinitionFormValues>
        initialValues={
          isUpdate && emailDeliveryDefinition ? mapEmailDeliveryDefinitionToFormValues(emailDeliveryDefinition) : emailDeliveryDefFormInitialValues
        }
        validationSchema={emailDeliveryDefValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (isUpdate) {
            const input = mapFormValuesToUpdateEmailDeliveryDefinition(values);
            runUpdate({
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              variables: { emailDeliveryDefinitionId: emailDeliveryDefinition!.emailDeliveryDefinitionId, input },
            }).finally(() => setSubmitting(false));
          } else {
            const input = mapFormValuesToCreateEmailDeliveryDefinition(values);
            runCreate({ variables: { input } }).finally(() => setSubmitting(false));
          }
        }}
      >
        {(formikProps) => (
          <Form layout="vertical">
            <EmailDeliveryDefGeneralFormItems
              onTypeChange={(template) => {
                formikProps.setFieldValue(emailDeliveryDefinitionFormFields.subject, {
                  ...mapTextbausteinToFormValues(template.subject),
                  value: formikProps.getFieldProps(emailDeliveryDefinitionFormFields.subject).value.value,
                });
                template.body &&
                  formikProps.setFieldValue(emailDeliveryDefinitionFormFields.body, {
                    ...mapTextbausteinToFormValues(template.body),
                    value: formikProps.getFieldProps(emailDeliveryDefinitionFormFields.body).value.value,
                  });
              }}
              disabledType={isUpdate}
            />
            <EmailDeliveryTextbausteinFormItem
              direction="vertical"
              name={emailDeliveryDefinitionFormFields.subject}
              id={emailDeliveryDefinitionFormFields.subject}
              label="E-Mail Betreff"
              fieldHelp={getFieldHelpText('EmailDeliveryDefinition.subject.value')}
            />
            <EmailDeliveryTextbausteinFormItem
              direction="vertical"
              name={emailDeliveryDefinitionFormFields.body}
              id={emailDeliveryDefinitionFormFields.body}
              label="Textkörper"
              fieldHelp={getFieldHelpText('EmailDeliveryDefinition.body.value')}
            />
            <FormButtons
              updateMode={isUpdate}
              onCancel={onCancel}
              isOkDisabled={formikProps.isSubmitting}
              isOkLoading={loading || loadingCreate || loadingUpdate}
            />
          </Form>
        )}
      </Formik>
    </Skeleton>
  );
};

export default EmailDeliveryDefinitionTemplateForm;
