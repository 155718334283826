import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import { URI_VERARBEITUNG_VORSCHREIBUNG_MAIL_DELIVERY_DETERMINE_RECIPIENT_LIST_DETAILS_PAGE } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import VorschreibungMailDeliveryDetermineRecipientListDetailsPage from './VorschreibungMailDeliveryDetermineRecipientListDetailsPage';

export type VerarbeitungVorschreibungMailDeliveryDetermineDetailsPageRouteParams = { generierlaufId: string };

export const verarbeitungVorschreibungMailDeliveryDetermineRecipientListDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_VORSCHREIBUNG_MAIL_DELIVERY_DETERMINE_RECIPIENT_LIST_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<VorschreibungMailDeliveryDetermineRecipientListDetailsPage />} />}
  />
);
