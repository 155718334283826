import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { FibuBuchungType } from '../../../types';

type Props = SelectProps;

const OPBuchungsTypeSelect: FC<Props> = ({ name, ...restProps }) => {
  const buchungsTypeList = [
    { value: FibuBuchungType.Abgrenzung, text: 'Abgrenzung' },
    { value: FibuBuchungType.AusgangsRechnung, text: 'Ausgangsrechnung' },
    { value: FibuBuchungType.EingangsRechnung, text: 'Eingangsrechnung' },
    { value: FibuBuchungType.Mahnung, text: 'Mahnung' },
    { value: FibuBuchungType.Intern, text: 'Intern' },
    { value: FibuBuchungType.Zahlung, text: 'Zahlung' },
  ];

  return (
    <Select allowClear name={name} id={name} placeholder="Buchungstyp auswählen" {...restProps}>
      {buchungsTypeList.map((kontotype) => (
        <Select.Option key={kontotype.value} value={kontotype.value}>
          {kontotype.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default OPBuchungsTypeSelect;
