import React, { FC } from 'react';
import { Empty } from 'antd';
import textbausteinTableColumns from './textbausteinTableColumns';
import TableWithColSelector from '../../../components/Table/TableWithColSelector/TableWithColSelector';
import { GeneralTextbausteinFieldsFragment } from '../gql/TextbausteinFragments.types';

type Props = {
  textbausteinList: GeneralTextbausteinFieldsFragment[];
  loading: boolean;
  refetch: () => void;
};

const TextbausteinTable: FC<Props> = ({ textbausteinList, loading, refetch }) => (
  <TableWithColSelector<GeneralTextbausteinFieldsFragment>
    size="small"
    locale={{ emptyText: <Empty description={<span>Keine Texte vorhanden</span>} /> }}
    loading={loading}
    dataSource={textbausteinList}
    columns={textbausteinTableColumns(refetch)}
    rowKey={(record) => record.textbausteinId}
    filterIdentifier="sys-settings-textbaustein"
  />
);

export default TextbausteinTable;
