import { Dayjs } from 'dayjs';
import { FormFields, mapFormDateValueToDateString } from '../../../../../../helpers/formikHelper';
import { ValuePreservationType, VertragVposIndexedValueGenerierlaufCreateInput } from '../../../../../../types';
import { mapDateFormValueToMonthYear } from '../Table/Level2/CreateUpdateIndexationAgreement/indexationAgreementHelpers';
import { dayjsCustom } from '../../../../../../helpers/dayjsCustom';

export interface IndexedValueFormValues {
  allowPreliminaryValues: boolean;
  validFrom?: Dayjs | string;
  valuePreservationTypeList: ValuePreservationType[] | null;
  yearMonth: Dayjs | string;
}

export const indexedValueFormInitialValues: IndexedValueFormValues = {
  allowPreliminaryValues: false,
  validFrom: '',
  valuePreservationTypeList: [ValuePreservationType.IndexThreshold, ValuePreservationType.IndexAnnual],
  yearMonth: '',
};

export const indexedValueFormFields: FormFields<IndexedValueFormValues> = {
  allowPreliminaryValues: 'allowPreliminaryValues',
  validFrom: 'validFrom',
  valuePreservationTypeList: 'valuePreservationTypeList',
  yearMonth: 'yearMonth',
};

export const mapFormValuesToIndexedValue = (
  values: IndexedValueFormValues,
  objektId: string,
  bestandseinheitId: string,
  vertragId: string
): VertragVposIndexedValueGenerierlaufCreateInput => ({
  allowPreliminaryValues: values.allowPreliminaryValues,
  bestandseinheitId,
  objektId,
  objektIdList: [], // BE expects either an array of objektIDs or an empty array, otherwise it throws an error
  validFrom: !values.validFrom ? null : mapFormDateValueToDateString(dayjsCustom(values.validFrom).startOf('month')),
  valuePreservationTypeList: values.valuePreservationTypeList,
  vertragId,
  yearMonth: mapDateFormValueToMonthYear(values.yearMonth),
});
