import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { AuszifferungSourceTuple } from '../../../types';
import { generatePathToBookingDetailsPage } from '../bookingDetailsPaths';
import { belongsAuszifferungToAnotherZahlung, isAuszifferungSourceStorno } from './auszifferungHelpers';

type Props = {
  bookingZahlungId: string;
  buchungId: string;
  source: AuszifferungSourceTuple;
  zahlungId?: string | null;
};

const AuszifferungSourceLink: FC<Props> = ({ bookingZahlungId, buchungId, source, zahlungId }) => {
  if (belongsAuszifferungToAnotherZahlung(bookingZahlungId, zahlungId) || isAuszifferungSourceStorno(source.value)) {
    const linkPath = generatePathToBookingDetailsPage([buchungId]);

    return (
      <Link to={linkPath} target="_blank">
        {source.text}
      </Link>
    );
  }
  return null;
};

export default AuszifferungSourceLink;
