import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { ObjektFragmentDoc } from './ObjektFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import { ZinslisteListEntryFragmentDoc } from '../../Zinsliste/gql/ZinslisteFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ObjektQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type ObjektQuery = {
  getObjekt: {
    data: {
      countryCodeIso2: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      fotoFileId?: string | null;
      fotoType?: Types.FotoType | null;
      kurzBezeichnung: string;
      objektId: string;
      rechnungsAusstellerBezeichnung?: string | null;
      rechnungsAusstellerChangeable?: boolean | null;
      subAdministration: boolean;
      sumNutzflaeche: number;
      sumNutzwert: number;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      verwaltungBis?: string | null;
      verwaltungSeit: string;
      verwaltungsartChangeable?: boolean | null;
      addressList: Array<{
        addressId: string;
        city: string;
        cityAdditionalInformation?: string | null;
        countryCodeIso2: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        houseEntranceApartmentNumber?: string | null;
        postofficeBoxNumber?: string | null;
        postofficeBoxZipCode?: string | null;
        street?: string | null;
        tagList: Array<string>;
        type: Types.AddressType;
        zipCode?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      objektSummeAnteile?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        kommentar?: string | null;
        lastUpdateTs: string;
        objektSummeAnteileId: string;
        summeAnteile: number;
        validFrom: string;
        historicizedList?: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          kommentar?: string | null;
          lastUpdateTs: string;
          objektSummeAnteileId: string;
          summeAnteile: number;
          validFrom: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }> | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechnungsAussteller: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fibuKontoCreatable: boolean;
        kundennummer: string;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        bankDetailsList: Array<{
          accountHolder: string;
          accountLimit?: number | null;
          assignedHeVertragZahlungsregelMap: any;
          assignedObjektMap: any;
          bankDetailsId: string;
          bankname: string;
          bic: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          ebicsCreditTransferUserList: Array<string>;
          ebicsDirectDebitUserList: Array<string>;
          fibuKontoId?: string | null;
          fibuKontonummer?: string | null;
          iban: string;
          updatedByMitarbeiterId?: string | null;
          fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
          fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
          sepaMandat?: {
            fileId?: string | null;
            fileName?: string | null;
            sepaMandatId: string;
            sepaMandatReference: string;
            signatureDate?: string | null;
            firmendaten?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firmendatenId: string;
              kundenSystemId: string;
              kurzBezeichnung: string;
              name1: string;
              name2?: string | null;
              name3?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechnungsAussteller?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
            warningList: Array<{ message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        personenbezugList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          personenbezugId: string;
          tagList: Array<string>;
          person: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechtstraegerSteuerpflicht?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          historicizedList?: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ message: string; type: string }>;
          }> | null;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
        sprache: { text: string; value: Types.Sprache };
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { value: Types.RechtstraegerType; text: string };
        vertragSummary?: {
          heVertragCount: number;
          heVertragVerwaltungCount: number;
          mietVertragCount: number;
          mietVertragVerwaltungCount: number;
          weSubverwaltungCount: number;
          weVertragCount: number;
          weVertragVerwaltungCount: number;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      sachbearbeiter?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        fileStorageInitialized: boolean;
        firmendatenId: string;
        firstname: string;
        funktionsModulList: Array<string>;
        lastname: string;
        mitarbeiterId: string;
        title?: string | null;
        titleTrailing?: string | null;
        username: string;
        contactPerson?: {
          contactPersonId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firstname: string;
          lastname: string;
          tagList: Array<string>;
          title?: string | null;
          titleTrailing?: string | null;
          contactList: Array<{
            contactId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            haupt?: boolean | null;
            tagList: Array<string>;
            type: string;
            wert: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          rechtstraeger?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rolleList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          name: string;
          rolleId: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
        status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
        user?: {
          email?: string | null;
          emailUnverified?: string | null;
          firstname?: string | null;
          lastname?: string | null;
          role: string;
          username: string;
          status: { text: string; value: string };
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { text: string; value: Types.ObjektStatus; description?: string | null };
      verwaltungsart: { text: string; value: Types.Verwaltungsart };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ObjektChangeHistoryListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
}>;

export type ObjektChangeHistoryListQuery = {
  getObjektChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type ObjektListQueryVariables = Types.Exact<{
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  kurzBezeichnung?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechnungsAusstellerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  abrechnungType?: Types.InputMaybe<Types.AbrechnungType>;
  order?: Types.InputMaybe<Types.Scalars['String']['input']>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type ObjektListQuery = {
  getObjektList: {
    data: {
      contentList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        erstelltAm: string;
        fotoFileId?: string | null;
        kurzBezeichnung: string;
        objektId: string;
        rechnungsAusstellerId: string;
        verwaltungBis?: string | null;
        id: string;
        status: { description?: string | null; text: string; value: Types.ObjektStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ObjektZinslisteQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  order?: Types.InputMaybe<Types.ZinslisteOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type ObjektZinslisteQuery = {
  getZinslisteListByObjektId: {
    data: {
      contentList: Array<{
        countVorschreibungsposition?: number | null;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        generierlaufId?: string | null;
        objektId: string;
        objektKurzbezeichnung: string;
        sumBrutto?: number | null;
        sumNetto?: number | null;
        sumUst?: number | null;
        zinslisteId: string;
        status: { text: string; value: Types.ZinslisteStatus; description?: string | null };
        vorschreibungsperiode: { jahr: number; monat: number };
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SummeAnteileQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  date: Types.Scalars['String']['input'];
}>;

export type SummeAnteileQuery = {
  getSummeAnteile: {
    data?: number | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const ObjektDocument = gql`
  query Objekt($objektId: ID!, $withDetails: Boolean) {
    getObjekt(objektId: $objektId, withDetails: $withDetails) {
      data {
        ...Objekt
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${ObjektFragmentDoc}
`;
export function useObjektQuery(
  baseOptions: Apollo.QueryHookOptions<ObjektQuery, ObjektQueryVariables> & ({ variables: ObjektQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ObjektQuery, ObjektQueryVariables>(ObjektDocument, options);
}
export function useObjektLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjektQuery, ObjektQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ObjektQuery, ObjektQueryVariables>(ObjektDocument, options);
}
export function useObjektSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ObjektQuery, ObjektQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ObjektQuery, ObjektQueryVariables>(ObjektDocument, options);
}
export type ObjektQueryHookResult = ReturnType<typeof useObjektQuery>;
export type ObjektLazyQueryHookResult = ReturnType<typeof useObjektLazyQuery>;
export type ObjektSuspenseQueryHookResult = ReturnType<typeof useObjektSuspenseQuery>;
export type ObjektQueryResult = Apollo.QueryResult<ObjektQuery, ObjektQueryVariables>;
export const ObjektChangeHistoryListDocument = gql`
  query ObjektChangeHistoryList($objektId: ID!) {
    getObjektChangeHistoryList(objektId: $objektId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useObjektChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<ObjektChangeHistoryListQuery, ObjektChangeHistoryListQueryVariables> &
    ({ variables: ObjektChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ObjektChangeHistoryListQuery, ObjektChangeHistoryListQueryVariables>(ObjektChangeHistoryListDocument, options);
}
export function useObjektChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ObjektChangeHistoryListQuery, ObjektChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ObjektChangeHistoryListQuery, ObjektChangeHistoryListQueryVariables>(ObjektChangeHistoryListDocument, options);
}
export function useObjektChangeHistoryListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ObjektChangeHistoryListQuery, ObjektChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ObjektChangeHistoryListQuery, ObjektChangeHistoryListQueryVariables>(ObjektChangeHistoryListDocument, options);
}
export type ObjektChangeHistoryListQueryHookResult = ReturnType<typeof useObjektChangeHistoryListQuery>;
export type ObjektChangeHistoryListLazyQueryHookResult = ReturnType<typeof useObjektChangeHistoryListLazyQuery>;
export type ObjektChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useObjektChangeHistoryListSuspenseQuery>;
export type ObjektChangeHistoryListQueryResult = Apollo.QueryResult<ObjektChangeHistoryListQuery, ObjektChangeHistoryListQueryVariables>;
export const ObjektListDocument = gql`
  query ObjektList(
    $includeArchiviert: Boolean
    $kurzBezeichnung: String
    $rechnungsAusstellerId: String
    $abrechnungType: AbrechnungType
    $order: String
    $orderDir: String
    $page: Int
    $pageSize: Int
    $withDetails: Boolean
  ) {
    getObjektList(
      includeArchiviert: $includeArchiviert
      kurzBezeichnung: $kurzBezeichnung
      rechnungsAusstellerId: $rechnungsAusstellerId
      abrechnungType: $abrechnungType
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
      withDetails: $withDetails
    ) {
      data {
        contentList {
          createTs
          createdBy
          createdByMitarbeiterId
          deletable
          erstelltAm
          fotoFileId
          id: objektId
          kurzBezeichnung
          objektId
          rechnungsAusstellerId
          status {
            description
            text
            value
          }
          verwaltungBis
          warningList {
            attribute
            message
            type
          }
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useObjektListQuery(baseOptions?: Apollo.QueryHookOptions<ObjektListQuery, ObjektListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ObjektListQuery, ObjektListQueryVariables>(ObjektListDocument, options);
}
export function useObjektListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjektListQuery, ObjektListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ObjektListQuery, ObjektListQueryVariables>(ObjektListDocument, options);
}
export function useObjektListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ObjektListQuery, ObjektListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ObjektListQuery, ObjektListQueryVariables>(ObjektListDocument, options);
}
export type ObjektListQueryHookResult = ReturnType<typeof useObjektListQuery>;
export type ObjektListLazyQueryHookResult = ReturnType<typeof useObjektListLazyQuery>;
export type ObjektListSuspenseQueryHookResult = ReturnType<typeof useObjektListSuspenseQuery>;
export type ObjektListQueryResult = Apollo.QueryResult<ObjektListQuery, ObjektListQueryVariables>;
export const ObjektZinslisteDocument = gql`
  query ObjektZinsliste($objektId: ID!, $order: ZinslisteOrderBy, $orderDir: String, $page: Int, $pageSize: Int) {
    getZinslisteListByObjektId(objektId: $objektId, order: $order, orderDir: $orderDir, page: $page, pageSize: $pageSize) {
      data {
        contentList {
          ...ZinslisteListEntry
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${ZinslisteListEntryFragmentDoc}
`;
export function useObjektZinslisteQuery(
  baseOptions: Apollo.QueryHookOptions<ObjektZinslisteQuery, ObjektZinslisteQueryVariables> &
    ({ variables: ObjektZinslisteQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ObjektZinslisteQuery, ObjektZinslisteQueryVariables>(ObjektZinslisteDocument, options);
}
export function useObjektZinslisteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjektZinslisteQuery, ObjektZinslisteQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ObjektZinslisteQuery, ObjektZinslisteQueryVariables>(ObjektZinslisteDocument, options);
}
export function useObjektZinslisteSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ObjektZinslisteQuery, ObjektZinslisteQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ObjektZinslisteQuery, ObjektZinslisteQueryVariables>(ObjektZinslisteDocument, options);
}
export type ObjektZinslisteQueryHookResult = ReturnType<typeof useObjektZinslisteQuery>;
export type ObjektZinslisteLazyQueryHookResult = ReturnType<typeof useObjektZinslisteLazyQuery>;
export type ObjektZinslisteSuspenseQueryHookResult = ReturnType<typeof useObjektZinslisteSuspenseQuery>;
export type ObjektZinslisteQueryResult = Apollo.QueryResult<ObjektZinslisteQuery, ObjektZinslisteQueryVariables>;
export const SummeAnteileDocument = gql`
  query SummeAnteile($objektId: ID!, $date: String!) {
    getSummeAnteile(objektId: $objektId, date: $date) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSummeAnteileQuery(
  baseOptions: Apollo.QueryHookOptions<SummeAnteileQuery, SummeAnteileQueryVariables> &
    ({ variables: SummeAnteileQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SummeAnteileQuery, SummeAnteileQueryVariables>(SummeAnteileDocument, options);
}
export function useSummeAnteileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummeAnteileQuery, SummeAnteileQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SummeAnteileQuery, SummeAnteileQueryVariables>(SummeAnteileDocument, options);
}
export function useSummeAnteileSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummeAnteileQuery, SummeAnteileQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SummeAnteileQuery, SummeAnteileQueryVariables>(SummeAnteileDocument, options);
}
export type SummeAnteileQueryHookResult = ReturnType<typeof useSummeAnteileQuery>;
export type SummeAnteileLazyQueryHookResult = ReturnType<typeof useSummeAnteileLazyQuery>;
export type SummeAnteileSuspenseQueryHookResult = ReturnType<typeof useSummeAnteileSuspenseQuery>;
export type SummeAnteileQueryResult = Apollo.QueryResult<SummeAnteileQuery, SummeAnteileQueryVariables>;
