import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import {
  mahnungCreateFormFields,
  mahnungCreateFormInitialValues,
  MahnungCreateFormValues,
  mapFormValuesToMahnungCreate,
} from './mahnungCreateFormMapper';
import { showSuccessMsgOther } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { useCreateMahnungErzeugenGenerierlaufMutation } from '../../../Verarbeitung/gql/MahnungGenerierlauf/MahnungErzeugenGenerierlaufMutations.types';
import RechnungsAusstellerRechtstraegerSelect from '../../../../shared/components/RechnungsAusstellerSelect/RechnungsAusstellerRechtstraegerSelect';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import ObjektSelect from '../../../ObjektSelect/ObjektSelect';
import FormButtons from '../../../../components/Button/FormButtons';

type MahnungCreateFormProps = {
  onAction: () => void;
};

const MahnungCreateForm: FC<MahnungCreateFormProps> = ({ onAction }) => {
  const [runCreateMahnung, { loading }] = useCreateMahnungErzeugenGenerierlaufMutation({
    onCompleted: () => {
      showSuccessMsgOther('Mahnungen werden erzeugt');
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<MahnungCreateFormValues>
      initialValues={mahnungCreateFormInitialValues}
      onSubmit={(formValues) => {
        const input = mapFormValuesToMahnungCreate(formValues);
        runCreateMahnung({ variables: { input } }).finally(onAction);
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithoutColon name={mahnungCreateFormFields.rechnungsAusstellerId} label="Rechnungsaussteller">
            <RechnungsAusstellerRechtstraegerSelect
              id={mahnungCreateFormFields.rechnungsAusstellerId}
              name={mahnungCreateFormFields.rechnungsAusstellerId}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={mahnungCreateFormFields.objektId} label="Objekt">
            <ObjektSelect
              id={mahnungCreateFormFields.objektId}
              name={mahnungCreateFormFields.objektId}
              rechnungsAusstellerId={formikProps.values.rechnungsAusstellerId}
              disabled={!formikProps.values.rechnungsAusstellerId}
            />
          </FormItemWithoutColon>
          <FormButtons onCancel={onAction} isOkLoading={loading} okText="Abschicken" updateMode={false} />
        </Form>
      )}
    </Formik>
  );
};

export default MahnungCreateForm;
