import React, { FC } from 'react';
import { Space } from 'antd';
import FromToFormInputNumber from '../../shared/FromToForm/FromToFormInputNumber';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import BelegnummerFormSelect from '../../../shared/components/Belegnummer/BelegnummerFormSelect';

type Props = {
  belegerNummerFormFieldName: string;
  totalAmountFromFormFieldName: string;
  totalAmountToFormFieldName: string;
};

const FiltersForOPListForPersonenkonto: FC<Props> = ({ belegerNummerFormFieldName, totalAmountFromFormFieldName, totalAmountToFormFieldName }) => {
  return (
    <Space size="large" style={{ width: '100%' }}>
      <FromToFormInputNumber name="Gesamtbetrag" fromName={totalAmountFromFormFieldName} toName={totalAmountToFormFieldName} />
      <FormItemWithoutColon name={belegerNummerFormFieldName} label="Belegnummer">
        <BelegnummerFormSelect name={belegerNummerFormFieldName} />
      </FormItemWithoutColon>
    </Space>
  );
};

export default FiltersForOPListForPersonenkonto;
