import React, { FC } from 'react';
import { AbrInfoAbrechnungskreis, AbrInfoKonto } from '../../../../../types';
import NestedTableWithColSelectorWrapper from '../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelectorWrapper';
import NestedTableWithColSelector from '../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import infoAbrKreisTableColumns from './infoAbrKreisTableColumns';
import EinnahmenTable from '../Level2/EinnahmenTable';
import AusgabenTable from '../Level2/AusgabenTable';

export type InfoAbrKreis = AbrInfoAbrechnungskreis;

type Props = {
  abrechnungskreisList: InfoAbrKreis[];
};

const InfoAbrKreisTable: FC<Props> = ({ abrechnungskreisList }) => {
  return (
    <NestedTableWithColSelectorWrapper filterIdentifier="info-abrechnungkreis">
      <NestedTableWithColSelector<InfoAbrKreis>
        colWidth={200}
        level={1}
        rowKey={getRowKey}
        dataSource={abrechnungskreisList}
        columns={infoAbrKreisTableColumns}
        expandable={{
          rowExpandable: isRowExpandable,
          expandedRowRender: (record, index, indent, expanded) => (
            <>
              {getFilteredKontoList(record.erloes.kontoList).length > 0 && (
                <EinnahmenTable parentRowKeys={[getRowKey(record)]} visible={expanded} kontoList={getFilteredKontoList(record.erloes.kontoList)} />
              )}
              {getFilteredKontoList(record.aufwand.kontoList).length > 0 && (
                <AusgabenTable parentRowKeys={[getRowKey(record)]} visible={expanded} kontoList={getFilteredKontoList(record.aufwand.kontoList)} />
              )}
            </>
          ),
        }}
      />
    </NestedTableWithColSelectorWrapper>
  );
};

const isRowExpandable = (record: InfoAbrKreis) =>
  getFilteredKontoList(record.aufwand.kontoList).length > 0 || getFilteredKontoList(record.erloes.kontoList).length > 0;

const getFilteredKontoList = (kontoList?: AbrInfoKonto[]) => (kontoList || []).filter((konto) => konto.summe > 0);

const getRowKey = (record: InfoAbrKreis) => record.abrechnungskreisId;

export default InfoAbrKreisTable;
