import React from 'react';
import { Avatar } from 'antd';
import { GroupedMenueListInterface } from '../../../components/ContextSidebar/ContentWithSidebar';
import { generatePathToEmailVersandDetailsPage } from '../../../features/EmailDelivery/emailDeliveryUriPaths';
import EmailDeliveryAttachmentView from '../../../features/EmailDelivery/EmailDeliveryAttachmentView';
import VorschreibungMailDeliveryEmailView from '../../../features/EmailDelivery/VorschreibungMailDelivery/VorschreibungMailDeliveryEmailView';
import { MailData } from '../../../types';

const menuListEmailDelivery = (emailDelivery: MailData): GroupedMenueListInterface => ({
  topMenuList: [
    {
      title: 'E-Mail',
      icon: <Avatar>E</Avatar>,
      uri: `${generatePathToEmailVersandDetailsPage(emailDelivery.mailId)}/email`,
      content: () => <VorschreibungMailDeliveryEmailView emailDelivery={emailDelivery} />,
    },
    {
      title: 'Anhänge',
      icon: <Avatar>Anh</Avatar>,
      uri: `${generatePathToEmailVersandDetailsPage(emailDelivery.mailId)}/attachments`,
      content: () => <EmailDeliveryAttachmentView mailId={emailDelivery.mailId} />,
    },
  ],
});

export default menuListEmailDelivery;
