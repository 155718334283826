import React, { FC } from 'react';
import { Formik } from 'formik';
import { DatePicker } from 'formik-antd';
import BuchungskreisSelect from '../sharedFilters/BuchungskreisSelect';
import ObjektSelect from '../sharedFilters/ObjektSelect';
import {
  mapFormValuesToSaldenlisteListingFilters,
  mapSaldenlisteListingFiltersToFormValues,
  saldenlisteListingFiltersFormFields,
  SaldenlisteListingFiltersFormValues,
} from './saldenlisteListingFiltersFormMapper';
import SaldenlisteKontoTypeSelect from './SaldenlisteKontoTypeSelect';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import FiltersWith4OrMoreFields from '../../../components/Filters/FiltersWith4OrMoreFields';
import { TSaldenlisteQueryParams } from './filtersQueryParams';
import { dayjsCustom } from '../../../helpers/dayjsCustom';
import MonthPicker from '../../../components/DatePicker/MonthPicker/MonthPicker';

type Props = {
  filters: TSaldenlisteQueryParams;
  onChange: (filters: TSaldenlisteQueryParams) => void;
};

const SaldenlisteListingFilters: FC<Props> = ({ filters, onChange }) => (
  <Formik<SaldenlisteListingFiltersFormValues>
    initialValues={mapSaldenlisteListingFiltersToFormValues(filters)}
    onSubmit={(values) => {
      onChange(mapFormValuesToSaldenlisteListingFilters(values));
    }}
  >
    {(formikProps) => (
      <FiltersWith4OrMoreFields
        hideTitle
        leftColumn={
          <>
            <FormItemWithoutColon name={saldenlisteListingFiltersFormFields.buchungskreisId} label="Buchungskreis">
              <BuchungskreisSelect
                name={saldenlisteListingFiltersFormFields.buchungskreisId}
                rechnungsAusstellerId={formikProps.values.rechnungsAusstellerIdForBuchungskreis}
                onChange={(rechtstraeger) => {
                  formikProps.setFieldValue(
                    saldenlisteListingFiltersFormFields.rechnungsAusstellerIdForObjekt,
                    rechtstraeger?.rechtstraegerId ?? '',
                    false
                  );
                  formikProps.submitForm();
                }}
              />
            </FormItemWithoutColon>
            <FormItemWithoutColon name={saldenlisteListingFiltersFormFields.objektId} label="Objekt">
              <ObjektSelect
                name={saldenlisteListingFiltersFormFields.objektId}
                rechnungsAusstellerId={formikProps.values.rechnungsAusstellerIdForObjekt}
                onChange={(objekt) => {
                  formikProps.setFieldValue(
                    saldenlisteListingFiltersFormFields.rechnungsAusstellerIdForBuchungskreis,
                    objekt?.rechnungsAusstellerId ?? '',
                    false
                  );
                  formikProps.submitForm();
                }}
              />
            </FormItemWithoutColon>
          </>
        }
        rightColumn={
          <>
            <FormItemWithoutColon name={saldenlisteListingFiltersFormFields.buchungsmonat} label="Monat / Jahr">
              <MonthPicker
                name={saldenlisteListingFiltersFormFields.buchungsmonat}
                style={{ width: '332px' }}
                placeholder="Monat und Jahr wählen"
                onChange={formikProps.submitForm}
                allowClear={false}
              />
            </FormItemWithoutColon>
            <FormItemWithoutColon name={saldenlisteListingFiltersFormFields.kontoType} label="Kontentyp">
              <SaldenlisteKontoTypeSelect name={saldenlisteListingFiltersFormFields.kontoType} onChange={formikProps.submitForm} />
            </FormItemWithoutColon>
          </>
        }
      />
    )}
  </Formik>
);

export default SaldenlisteListingFilters;
