import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
export type PersonenbezugFragment = {
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  personenbezugId: string;
  tagList: Array<string>;
  person: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const PersonenbezugFragmentDoc = gql`
  fragment Personenbezug on Personenbezug {
    createTs
    createdBy
    createdByMitarbeiterId
    person {
      ...RechtstraegerBaseFields
    }
    personenbezugId
    tagList
    warningList {
      attribute
      message
      type
    }
  }
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
