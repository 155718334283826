import createCtx from '../../helpers/createCtx';

interface BestandseinheitIdsContextProps {
  objektId: string;
  bestandseinheitId: string;
}

const [useBestandseinheitIds, BestandseinheitIdsProvider] = createCtx<BestandseinheitIdsContextProps>();

export { BestandseinheitIdsProvider, useBestandseinheitIds };
