import React, { FC } from 'react';
import { Divider, Space, Typography } from 'antd';
import { Box, Flex } from 'rebass';
import { BudgetingResultByVorschreibungsposition } from '../../../../types';
import DataWithShortenedTextAndExtraInfo from '../../../../components/Helpers/DataWithShortenedTextAndExtraInfo';
import { EuroAmount } from '../../../../components/Number';

type Props = {
  vertragLevelVPosList: BudgetingResultByVorschreibungsposition[];
};

const BudgetingResultByVertragLevelVPosList: FC<Props> = ({ vertragLevelVPosList }) => (
  <>
    {vertragLevelVPosList.map((vpos, index) => (
      <Box key={index} paddingLeft="8px" paddingRight="40px" backgroundColor="#fff">
        <Space direction="vertical" style={{ width: '100%', paddingTop: '8px' }}>
          <Flex width={['100%']}>
            <Box width={['70%']}>
              <DataWithShortenedTextAndExtraInfo text={vpos.vorschreibungsposition} maxTextLength={20}>
                {(shortenedText) => <Typography.Text strong>{shortenedText}</Typography.Text>}
              </DataWithShortenedTextAndExtraInfo>
            </Box>
            <Box width={['30%']} display="flex" textAlign="right">
              <Box width={['100%']}>
                <Typography.Text strong>
                  <EuroAmount value={vpos.sumNetto} />
                </Typography.Text>
              </Box>
            </Box>
          </Flex>
          <Divider style={{ marginTop: '0px', marginBottom: '0px' }} />
        </Space>
      </Box>
    ))}
  </>
);

export default BudgetingResultByVertragLevelVPosList;
