import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import ObjektVerwaltungsArtSelect from '../../Objekt/VerwaltungsArt/ObjektVerwaltungsArtSelect';
import FormButtons from '../../../components/Button/FormButtons';
import { BudgetingVPosAuftschluesselLinkage, VorschreibungspositionArt } from '../../../types';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import VertragLevelSelect from '../../VertragLevelSelect/gql/VertragLevelSelect';
import ErrorMessagePanel from '../../../components/Error/ErrorMessagePanel';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';
import {
  useCreateBudgetingVPosAuftschluesselLinkageTemplateMutation,
  useUpdateBudgetingVPosAuftschluesselLinkageTemplateMutation,
} from '../gql/budgetingVPosAuftschluesselLinkageTemplateMutations.types';
import {
  linkageFormFields,
  LinkageFormValues,
  mapFormValuesToLinkage,
  mapLinkageToFormValues,
} from '../../BudgetingVPosAuftschluesselLinkage/Form/linkageFormMapper';
import { linkageFormValidationSchema } from '../../BudgetingVPosAuftschluesselLinkage/Form/linkageFormValidationSchema';
import AufteilungsschluesselTemplateSelect from '../../AufteilungsschluesselTemplateSelect/AufteilungsschluesselTemplateSelect';
import VorschreibungspositionTemplateSelect from '../../VorschreibungspositionTemplateSelect/VorschreibungspositionTemplateSelect';

type Props = {
  linkage?: BudgetingVPosAuftschluesselLinkage;
  linkageEntryList: BudgetingVPosAuftschluesselLinkage[];
  onSuccess: () => void;
  onCancel: () => void;
};

const LinkageForm: FC<Props> = ({ onCancel, linkageEntryList, linkage, onSuccess }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'BudgetingVPosAuftschluesselLinkage'>('BudgetingVPosAuftschluesselLinkage');

  const isUpdate = !!linkage;
  const [hasError, setHasError] = useState(false);

  const handleSuccess = (isUpdate = false) => {
    const entity = 'Verknüpfung';
    if (!isUpdate) {
      showSuccessMsgCreate(entity);
    } else {
      showSuccessMsgUpdate(entity);
    }
    onSuccess();
  };

  const [runCreate, { loading: loadingCreate }] = useCreateBudgetingVPosAuftschluesselLinkageTemplateMutation({
    onCompleted: () => {
      handleSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateBudgetingVPosAuftschluesselLinkageTemplateMutation({
    onCompleted: () => {
      handleSuccess(true);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const isLoading = loadingCreate || loadingUpdate;

  return (
    <Formik<LinkageFormValues>
      initialValues={mapLinkageToFormValues(linkage)}
      validationSchema={linkageFormValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setHasError(false);
        if (combinationAlreadyExists(linkageEntryList, values, isUpdate, linkage?.budgetingVPosAuftschluesselLinkageId)) {
          setHasError(true);
          setSubmitting(false);
          return;
        }

        if (!isUpdate) {
          runCreate({ variables: { input: mapFormValuesToLinkage(values) } }).finally(() => setSubmitting(false));
        } else {
          runUpdate({
            variables: {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              budgetingVPosAuftschluesselLinkageId: linkage!.budgetingVPosAuftschluesselLinkageId,
              input: mapFormValuesToLinkage(values),
            },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          {hasError && <ErrorMessagePanel error="Mit diesen Parametern existiert bereits ein Eintrag" />}
          <FormItemWithFieldHelp
            name={linkageFormFields.objektVerwaltungsart}
            label="Verwaltungsart"
            fieldHelp={getFieldHelpText('BudgetingVPosAuftschluesselLinkage.objektVerwaltungsart')}
          >
            <ObjektVerwaltungsArtSelect name={linkageFormFields.objektVerwaltungsart} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={linkageFormFields.vertragLevel}
            label="Vertragsebene"
            fieldHelp={getFieldHelpText('BudgetingVPosAuftschluesselLinkage.vertragLevel')}
          >
            <VertragLevelSelect
              size="middle"
              name={linkageFormFields.vertragLevel}
              verwaltungsart={formikProps.values.objektVerwaltungsart ?? undefined}
            />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={linkageFormFields.vorschreibungspositionId}
            label="Vorschreibungposition"
            fieldHelp={getFieldHelpText('BudgetingVPosAuftschluesselLinkage.vorschreibungspositionId')}
          >
            <VorschreibungspositionTemplateSelect name={linkageFormFields.vorschreibungspositionId} exludeArts={[VorschreibungspositionArt.Miete]} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={linkageFormFields.aufteilungsschluesselId}
            label="Aufteilingsschlüssel"
            fieldHelp={getFieldHelpText('BudgetingVPosAuftschluesselLinkage.aufteilungsschluesselId')}
          >
            <AufteilungsschluesselTemplateSelect name={linkageFormFields.aufteilungsschluesselId} />
          </FormItemWithFieldHelp>
          <FormButtons updateMode={isUpdate} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} isOkLoading={isLoading} />
        </Form>
      )}
    </Formik>
  );
};

const combinationAlreadyExists = (
  linkageEntryList: BudgetingVPosAuftschluesselLinkage[],
  values: LinkageFormValues,
  isUpdate: boolean,
  linkageId?: string
) => {
  return linkageEntryList.find((linkage) => {
    const isDuplicate =
      values.objektVerwaltungsart === linkage.objektVerwaltungsart.value &&
      values.vertragLevel === linkage.vertragLevel.value &&
      values.vorschreibungspositionId === linkage.vorschreibungsposition.vorschreibungspositionId;

    if (!isUpdate) {
      return isDuplicate;
    } else {
      const isSame = linkageId === linkage.budgetingVPosAuftschluesselLinkageId;
      return isDuplicate && !isSame;
    }
  });
};

export default LinkageForm;
