import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import ReceivedBankTransactionPage from './ReceivedBankTransactionPage';
import { URI_RECEIVED_BANK_TRANSACTION_PAGE } from '../../features/ReceivedBankTransaction/receivedBankTransactionUriPaths';

const receivedBankTransactionPageRoute = (
  <Route
    path={URI_RECEIVED_BANK_TRANSACTION_PAGE}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<ReceivedBankTransactionPage />} />}
  />
);

export default receivedBankTransactionPageRoute;
