export const FETCH_OPTIONS_GET_DEFAULT = {
  method: 'GET',
  credentials: 'same-origin',
  headers: { Accept: 'application/json' },
} as RequestInit;

export const fetchOptionsPostWithData = (data: any) => {
  const request: RequestInit = {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ data }),
  };
  return request;
};
