import React from 'react';
import { Empty } from 'antd';
import { useUstRegelsetListQuery } from '../gql/UstRegelsetQueries.types';
import { ustRegelsetTableColumns } from './ustRegelsetTableColumns';
import { useFDAuthorized } from '../../../../shared/Auth/useAuthorized';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { UstRegelset } from '../../../../types';

const UstRegelsetTable = () => {
  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();

  const { data, refetch } = useUstRegelsetListQuery({
    variables: {
      kundenSystemId,
    },
  });
  const ustRegelsetList = data?.getUstRegelsetListByKundenSystemId.data;
  const loading = !ustRegelsetList;

  return (
    <TableWithColSelector<UstRegelset>
      size="small"
      locale={{
        emptyText: <Empty description={<span>Kein USt-Regelset vorhanden</span>} />,
      }}
      loading={loading}
      dataSource={ustRegelsetList}
      columns={ustRegelsetTableColumns(refetch)}
      rowKey={(record) => record.ustRegelsetId}
      filterIdentifier="sys-settings-ust-regelset"
    />
  );
};

export default UstRegelsetTable;
