import React from 'react';
import { Badge } from 'antd';
import { generatePath, Link } from 'react-router-dom';
import { MitarbeiterTooltip } from '../../../components/Card';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { KundenSystem } from '../../../types';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';
import { URI_ANDROMEDA_SYS_SETTINGS_KUNDEN_SYSTEM_DETAILS_PAGE } from '../../../constants/andromedaSystemSettingsUriPaths';

const kundenSystemListTableColumns: TableWithColSelectorColumnProps<KundenSystem>[] = [
  {
    title: 'Kundensystemname',
    defaultSelected: true,
    render: (text, record) => (
      <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS_KUNDEN_SYSTEM_DETAILS_PAGE, { kundenSystemId: record.kundenSystemId })}>{record.name}</Link>
    ),
  },
  {
    title: 'erzeugt von Firma',
    defaultSelected: true,
    render: (text, record) => <span>{record.erzeugtVonFirma.kurzBezeichnung}</span>,
  },
  {
    title: 'Status',
    defaultSelected: true,
    render: (text, record) => (
      <Badge status={record.status.value === 'AKTIV' ? 'success' : 'error'} text={record.status.value === 'AKTIV' ? 'aktiv' : 'inaktiv'} />
    ),
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
];

export default kundenSystemListTableColumns;
