import React, { FC } from 'react';
import { Formik } from 'formik';
import { ITimeblock } from '../../../../../components/Timeline/timelineCtx';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { RepFondsEinstellung } from '../../../../../types';
import {
  useCreateRepFondsEinstellungTemplateMutation,
  useUpdateRepFondsEinstellungTemplateMutation,
} from '../../../gql/RepFondsEinstellungTemplateMutations.types';
import {
  mapFormValuesToRepFondsKontenZuweisungVersionCreate,
  mapFormValuesToRepFondsKontenZuweisungVersionUpdate,
  mapTimeblockToFormValues,
  RepFondsKontenZuweisungVersionFormValues,
} from '../../../../ReparaturFonds/KontenZuweisung/Version/Form/repFondsKontenZuweisungVersionFormMapper';
import { repFondsKontenZuweisungVersionFormValidationSchema } from '../../../../ReparaturFonds/KontenZuweisung/Version/Form/repFondsKontenZuweisungVersionFormValidationSchema';
import RepFondsTemplateKontenZuweisungVersionFormContent from './RepFondsTemplateKontenZuweisungVersionFormContent';

type Props = {
  onSubmitSuccess: () => void;
  timeblock: ITimeblock<RepFondsEinstellung>;
};

const RepFondsTemplateKontenZuweisungVersionForm: FC<Props> = ({ onSubmitSuccess, timeblock }) => {
  const isUpdate = !!timeblock.repFondsEinstellungId;
  const entity = 'Reparaturfonds-Kontenzuweisung';

  const [runCreate, { loading: loadingCreate }] = useCreateRepFondsEinstellungTemplateMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSubmitSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateRepFondsEinstellungTemplateMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSubmitSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const isLoading = loadingCreate || loadingUpdate;

  return (
    <Formik<RepFondsKontenZuweisungVersionFormValues>
      initialValues={mapTimeblockToFormValues(timeblock)}
      validationSchema={repFondsKontenZuweisungVersionFormValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (!isUpdate) {
          const input = mapFormValuesToRepFondsKontenZuweisungVersionCreate(values);
          runCreate({ variables: { input } }).finally(() => setSubmitting(false));
        } else {
          const input = mapFormValuesToRepFondsKontenZuweisungVersionUpdate(values);
          runUpdate({
            variables: { repFondsEinstellungId: timeblock.repFondsEinstellungId, input },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => <RepFondsTemplateKontenZuweisungVersionFormContent formikProps={formikProps} timeblock={timeblock} isLoading={isLoading} />}
    </Formik>
  );
};

export default RepFondsTemplateKontenZuweisungVersionForm;
