import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSubAdministrationMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  subAdministrationId: Types.Scalars['ID']['input'];
  input: Types.SubAdministrationUpdateInput;
}>;

export type UpdateSubAdministrationMutation = {
  updateSubAdministration: {
    data: { subAdministrationId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const UpdateSubAdministrationDocument = gql`
  mutation UpdateSubAdministration($objektId: ID!, $subAdministrationId: ID!, $input: SubAdministrationUpdateInput!) {
    updateSubAdministration(objektId: $objektId, subAdministrationId: $subAdministrationId, input: $input) {
      data {
        subAdministrationId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateSubAdministrationMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSubAdministrationMutation, UpdateSubAdministrationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSubAdministrationMutation, UpdateSubAdministrationMutationVariables>(UpdateSubAdministrationDocument, options);
}
export type UpdateSubAdministrationMutationHookResult = ReturnType<typeof useUpdateSubAdministrationMutation>;
export type UpdateSubAdministrationMutationResult = Apollo.MutationResult<UpdateSubAdministrationMutation>;
export type UpdateSubAdministrationMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubAdministrationMutation,
  UpdateSubAdministrationMutationVariables
>;
