import { useParams } from 'react-router-dom';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../../shared/dataChangedSubscription';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { useAuth } from '../../../shared/Auth/authContext';
import { HeAbrDetailsPageRouteParams } from '../../../pages/HeAbrechnungDetailsPage/routes';
import { HeAbrDetailsHeVertragDetailsPageRouteParams } from '../../../pages/HeAbrechnungDetailsHeVertragDetailsPage/routes';

const useOnHeAbrechnungDataChangedEvents = (key: string, heAbrechnungId: string, callback: () => Promise<unknown>) => {
  useOnDataChangedEvent([
    {
      filter: {
        type: dataChangedSubscriptionTypes.heAbrechnung,
        params: {
          heAbrechnungId,
        },
      },
      notificationDescription: 'Hauseigentümerabrechnung wurde gerade verändert',
      key: `${key}-heAbr`,
      callback,
    },
  ]);
};

const useOnHeAbrechnungHeVertragDataChangedEvents = (key: string, callback: () => Promise<unknown>) => {
  const { user } = useFDAuthorized();
  const { mitarbeiter } = useAuth();

  const { heAbrechnungId } = useParams() as HeAbrDetailsPageRouteParams;
  useOnDataChangedEvent([
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.heAbrechnungHeVertrag,
        params: {
          heAbrechnungId,
        },
      },
      notificationDescription: 'Hauseigentümerabrechnung wurde gerade verändert',
      key: `${key}-heAbr`,
      callback,
    },
  ]);
};

const useOnHeAbrechnungHeVertragDetailsDataChangedEvents = (key: string, callback: () => Promise<unknown>) => {
  const { user } = useFDAuthorized();
  const { mitarbeiter } = useAuth();

  const { heAbrechnungId, heAbrechnungHeVertragId } = useParams() as HeAbrDetailsHeVertragDetailsPageRouteParams;
  useOnDataChangedEvent([
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.heAbrechnungHeVertrag,
        params: {
          heAbrechnungId,
          heAbrechnungHeVertragId,
        },
      },
      notificationDescription: 'Hauseigentümerabrechnung wurde gerade verändert',
      key: `${key}-heAbrHeVertrag`,
      callback,
    },
  ]);
};

export { useOnHeAbrechnungHeVertragDataChangedEvents, useOnHeAbrechnungHeVertragDetailsDataChangedEvents, useOnHeAbrechnungDataChangedEvents };
