import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateEmailDeliveryDefinitionTemplateMutationVariables = Types.Exact<{
  input: Types.EmailDeliveryDefinitionCreateInput;
}>;

export type CreateEmailDeliveryDefinitionTemplateMutation = {
  createEmailDeliveryDefinitionTemplate: {
    data: { emailDeliveryDefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateEmailDeliveryDefinitionTemplateMutationVariables = Types.Exact<{
  emailDeliveryDefinitionId: Types.Scalars['ID']['input'];
  input: Types.EmailDeliveryDefinitionUpdateInput;
}>;

export type UpdateEmailDeliveryDefinitionTemplateMutation = {
  updateEmailDeliveryDefinitionTemplate: {
    data: { emailDeliveryDefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteEmailDeliveryDefinitionTemplateMutationVariables = Types.Exact<{
  emailDeliveryDefinitionId: Types.Scalars['ID']['input'];
}>;

export type DeleteEmailDeliveryDefinitionTemplateMutation = {
  deleteEmailDeliveryDefinitionTemplate: {
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateEmailDeliveryDefinitionTemplateDocument = gql`
  mutation CreateEmailDeliveryDefinitionTemplate($input: EmailDeliveryDefinitionCreateInput!) {
    createEmailDeliveryDefinitionTemplate(input: $input) {
      data {
        emailDeliveryDefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateEmailDeliveryDefinitionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEmailDeliveryDefinitionTemplateMutation, CreateEmailDeliveryDefinitionTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEmailDeliveryDefinitionTemplateMutation, CreateEmailDeliveryDefinitionTemplateMutationVariables>(
    CreateEmailDeliveryDefinitionTemplateDocument,
    options
  );
}
export type CreateEmailDeliveryDefinitionTemplateMutationHookResult = ReturnType<typeof useCreateEmailDeliveryDefinitionTemplateMutation>;
export type CreateEmailDeliveryDefinitionTemplateMutationResult = Apollo.MutationResult<CreateEmailDeliveryDefinitionTemplateMutation>;
export type CreateEmailDeliveryDefinitionTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateEmailDeliveryDefinitionTemplateMutation,
  CreateEmailDeliveryDefinitionTemplateMutationVariables
>;
export const UpdateEmailDeliveryDefinitionTemplateDocument = gql`
  mutation UpdateEmailDeliveryDefinitionTemplate($emailDeliveryDefinitionId: ID!, $input: EmailDeliveryDefinitionUpdateInput!) {
    updateEmailDeliveryDefinitionTemplate(emailDeliveryDefinitionId: $emailDeliveryDefinitionId, input: $input) {
      data {
        emailDeliveryDefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateEmailDeliveryDefinitionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEmailDeliveryDefinitionTemplateMutation, UpdateEmailDeliveryDefinitionTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEmailDeliveryDefinitionTemplateMutation, UpdateEmailDeliveryDefinitionTemplateMutationVariables>(
    UpdateEmailDeliveryDefinitionTemplateDocument,
    options
  );
}
export type UpdateEmailDeliveryDefinitionTemplateMutationHookResult = ReturnType<typeof useUpdateEmailDeliveryDefinitionTemplateMutation>;
export type UpdateEmailDeliveryDefinitionTemplateMutationResult = Apollo.MutationResult<UpdateEmailDeliveryDefinitionTemplateMutation>;
export type UpdateEmailDeliveryDefinitionTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmailDeliveryDefinitionTemplateMutation,
  UpdateEmailDeliveryDefinitionTemplateMutationVariables
>;
export const DeleteEmailDeliveryDefinitionTemplateDocument = gql`
  mutation DeleteEmailDeliveryDefinitionTemplate($emailDeliveryDefinitionId: ID!) {
    deleteEmailDeliveryDefinitionTemplate(emailDeliveryDefinitionId: $emailDeliveryDefinitionId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteEmailDeliveryDefinitionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteEmailDeliveryDefinitionTemplateMutation, DeleteEmailDeliveryDefinitionTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEmailDeliveryDefinitionTemplateMutation, DeleteEmailDeliveryDefinitionTemplateMutationVariables>(
    DeleteEmailDeliveryDefinitionTemplateDocument,
    options
  );
}
export type DeleteEmailDeliveryDefinitionTemplateMutationHookResult = ReturnType<typeof useDeleteEmailDeliveryDefinitionTemplateMutation>;
export type DeleteEmailDeliveryDefinitionTemplateMutationResult = Apollo.MutationResult<DeleteEmailDeliveryDefinitionTemplateMutation>;
export type DeleteEmailDeliveryDefinitionTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteEmailDeliveryDefinitionTemplateMutation,
  DeleteEmailDeliveryDefinitionTemplateMutationVariables
>;
