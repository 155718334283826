import { KontoKlasse, KontoType, KontoVerwendung } from '../../../types';

export const isKontoKlasseAnlageVermoegen = (kontoKlasse?: KontoKlasse) => kontoKlasse === KontoKlasse.AnlageVermoegen;
export const isKontoKlasseBetrieblicherAufwand = (kontoKlasse?: KontoKlasse) => kontoKlasse === KontoKlasse.BetrieblicherAufwand;
export const isKontoKlasseEigenkapital = (kontoKlasse?: KontoKlasse) => kontoKlasse === KontoKlasse.Eigenkapital;
export const isKontoKlasseFinanzAufwand = (kontoKlasse?: KontoKlasse) => kontoKlasse === KontoKlasse.FinanzAufwand;
export const isKontoKlasseFinanzErloes = (kontoKlasse?: KontoKlasse) => kontoKlasse === KontoKlasse.FinanzErloes;
export const isKontoKlasseHandelswarenEinsatz = (kontoKlasse?: KontoKlasse) => kontoKlasse === KontoKlasse.HandelswarenEinsatz;
export const isKontoKlasseHandelswarenVorrat = (kontoKlasse?: KontoKlasse) => kontoKlasse === KontoKlasse.HandelswarenVorrat;
export const isKontoKlassePersonalAufwand = (kontoKlasse?: KontoKlasse) => kontoKlasse === KontoKlasse.PersonalAufwand;
export const isKontoKlasseBetrieblicherErloes = (kontoKlasse?: KontoKlasse) => kontoKlasse === KontoKlasse.BetrieblicherErloes;
export const isKontoKlasseLieferverbindlichkeit = (kontoKlasse?: KontoKlasse) => kontoKlasse === KontoKlasse.Lieferverbindlichkeit;
export const isKontoKlasseUmlaufVermoegen = (kontoKlasse?: KontoKlasse) => kontoKlasse === KontoKlasse.UmlaufVermoegen;

export const hasKontoklasseAbrechnungen = (kontoklasse: KontoKlasse) =>
  isKontoKlasseBetrieblicherAufwand(kontoklasse) ||
  isKontoKlasseBetrieblicherErloes(kontoklasse) ||
  isKontoKlasseFinanzAufwand(kontoklasse) ||
  isKontoKlasseFinanzErloes(kontoklasse) ||
  isKontoKlasseHandelswarenEinsatz(kontoklasse) ||
  isKontoKlassePersonalAufwand(kontoklasse);

export const disabledPersonenkontoAndNoKontoverwendung = (kontoklasse?: KontoKlasse) =>
  isKontoKlasseAnlageVermoegen(kontoklasse) ||
  isKontoKlasseHandelswarenVorrat(kontoklasse) ||
  isKontoKlasseBetrieblicherErloes(kontoklasse) ||
  isKontoKlasseHandelswarenEinsatz(kontoklasse) ||
  isKontoKlassePersonalAufwand(kontoklasse) ||
  isKontoKlasseBetrieblicherAufwand(kontoklasse) ||
  isKontoKlasseFinanzAufwand(kontoklasse) ||
  isKontoKlasseFinanzErloes(kontoklasse) ||
  isKontoKlasseEigenkapital(kontoklasse);

export const isKontoTypeSachkonto = (kontoType?: KontoType) => kontoType === KontoType.Sachkonto;
export const isKontoTypePersonenkonto = (kontoType?: KontoType) => kontoType === KontoType.Personenkonto;

export const isKontoVerwendungBank = (kontoVerwendung?: KontoVerwendung) => kontoVerwendung === KontoVerwendung.Bank;
export const isKontoVerwendungKassa = (kontoVerwendung?: KontoVerwendung) => kontoVerwendung === KontoVerwendung.Kassa;
export const isKontoVerwendungDebitor = (kontoVerwendung?: KontoVerwendung) => kontoVerwendung === KontoVerwendung.Debitor;
export const isKontoVerwendungKreditor = (kontoVerwendung?: KontoVerwendung) => kontoVerwendung === KontoVerwendung.Kreditor;
export const isKontoVerwendungUmsatzsteuer = (kontoVerwendung?: KontoVerwendung) => kontoVerwendung === KontoVerwendung.Umsatzsteuer;
export const isKontoVerwendungVorsteuer = (kontoVerwendung?: KontoVerwendung) => kontoVerwendung === KontoVerwendung.Vorsteuer;
export const isKontoVerwendungAllgemeineForderung = (kontoVerwendung?: KontoVerwendung) => kontoVerwendung === KontoVerwendung.AllgemeineForderung;

export const isUmlaufVermoegenAndPersonenkonto = (kontoKlasse?: KontoKlasse, kontoType?: KontoType) =>
  isKontoKlasseUmlaufVermoegen(kontoKlasse) && isKontoTypePersonenkonto(kontoType);

export const isUmlaufVermoegenAndSachkonto = (kontoKlasse?: KontoKlasse, kontoType?: KontoType) =>
  isKontoKlasseUmlaufVermoegen(kontoKlasse) && isKontoTypeSachkonto(kontoType);

export const isLieferverbindlichkeitAndPersonenkonto = (kontoKlasse?: KontoKlasse, kontoType?: KontoType) =>
  isKontoKlasseLieferverbindlichkeit(kontoKlasse) && isKontoTypePersonenkonto(kontoType);
export const isLieferverbindlichkeitAndSachkonto = (kontoKlasse?: KontoKlasse, kontoType?: KontoType) =>
  isKontoKlasseLieferverbindlichkeit(kontoKlasse) && isKontoTypeSachkonto(kontoType);

export const ktoNumberPrefix = (kontoKlasse?: KontoKlasse, kontoType?: KontoType, kontoVerwendung?: KontoVerwendung) => {
  if (!disabledPersonenkontoAndNoKontoverwendung(kontoKlasse)) {
    if (kontoType === KontoType.Personenkonto) {
      return kontoVerwendung === KontoVerwendung.Debitor ? 'D' : 'K';
    }
    if (kontoVerwendung === KontoVerwendung.Bank) {
      return 'B';
    }
  }
  return 'S';
};
