import React from 'react';
import { Link } from 'react-router-dom';
import { AbrechnungskreisTypeTuple, SubAbrBuchungszeile } from '../../../../../../../types';
import { NestedTableWithColSelectorColProps } from '../../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import DataWithShortenedText from '../../../../../../../components/Helpers/DataWithShortenedText';
import { EuroAmount } from '../../../../../../../components/Number';
import { isAbrKreisTypeVstKuerzung } from '../../../../../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import { generatePathToVorschreibungDetailsPage } from '../../../../../../Vorschreibung/vorschreibungUriPaths';
import CustomFormattedDate from '../../../../../../../components/FormattedDate/CustomFormattedDate';
import TextForEmptyValue from '../../../../../../../components/Text/TextForEmptyValue';
import BelegnummerWithLinkToPDF from '../../../../../BkOrWe/Objekt/Table/BelegnummerWithLinkToPDF';
import { generatePathToRechtstraegerDetailsPage } from '../../../../../../Rechtstraeger/rechtstraegerHelper';
import { generatePathToBookingDetailsPage } from '../../../../../../BookingDetails/bookingDetailsPaths';

const buchungszeileTableColumns = (abrechnungskreisType: AbrechnungskreisTypeTuple): NestedTableWithColSelectorColProps<SubAbrBuchungszeile>[] => [
  {
    title: 'Text',
    defaultSelected: true,
    render: (text, record) =>
      record.auftragId ? (
        <Link target="_blank" to={generatePathToVorschreibungDetailsPage(record.auftragId)}>
          <DataWithShortenedText text={record.text} maxTextLength={25} />
        </Link>
      ) : (
        <DataWithShortenedText text={record.text} maxTextLength={25} />
      ),
  },
  {
    title: 'Belegdatum',
    defaultSelected: true,
    align: 'right',
    render: (text, record) => (record.dueDate ? <CustomFormattedDate value={record.dueDate} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Beleg-Nr.',
    defaultSelected: true,
    align: 'right',
    render: (text, record) => <BelegnummerWithLinkToPDF buchungszeile={record} />,
  },
  {
    title: 'Vertragspartner',
    defaultSelected: true,
    align: 'right',
    render: (text, record) =>
      record.vertragspartner ? (
        <DataWithShortenedText maxTextLength={25} text={record.vertragspartner.kurzBezeichnung}>
          {(shortenedText) => (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <Link target="_blank" to={generatePathToRechtstraegerDetailsPage(record.vertragspartner!.rechtstraegerId)}>
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: isAbrKreisTypeVstKuerzung(abrechnungskreisType.value) ? 'Vorsteuerkürzung' : 'Zahlung',
    key: 'vrstKrzAndZahlung',
    align: 'right',
    defaultSelected: true,
    render: (text, record) => (
      <>
        {record.auftragId ? (
          <Link target="_blank" to={generatePathToBookingDetailsPage(record.buchungIdList)}>
            <EuroAmount value={record.betrag} />
          </Link>
        ) : (
          <EuroAmount value={record.betrag} />
        )}
      </>
    ),
  },
  {
    title: '',
    key: 'summe',
  },
  {
    title: '',
    key: 'ergebnis',
  },
];

export default buchungszeileTableColumns;
