import React from 'react';
import { Tag, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { NestedTableWithColSelectorColProps } from '../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import { FibuBuchungsanweisungListEntry, FibuBuchungsanweisungOrderBy } from '../../../types';
import AvatarBasedOnBelegSymbolWithTooltip from '../../../components/Avatar/BasedOnBelegSymbol/AvatarBasedOnBelegSymbolWithTooltip';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';
import { EuroAmount } from '../../../components/Number';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../../components/Card';
import LinkToBelegFile from '../../../components/Link/LinkToBelegFile/LinkToBelegFile';
import RechtstraegerWithContactsTooltip from '../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';
import { generatePathToBookingDetailsPage } from '../../BookingDetails/bookingDetailsPaths';
import BookingInstructionTableActions from './BookingInstructionTableActions';
import { isFibuBuchungsanweisungVerbucht } from '../shared/fibuBuchungsanweisungHelpers';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { generatePathToObjektDetailsPage } from '../../Objekt/objektUriPaths';

const bookingInstructionTableColumns = (onActionSuccess: () => void): NestedTableWithColSelectorColProps<FibuBuchungsanweisungListEntry>[] => [
  {
    title: 'Buchungskreis',
    defaultSelected: true,
    dataIndex: FibuBuchungsanweisungOrderBy.BuchungskreisRechtstraeger,
    sorter: true,
    render: (text, record) => (
      <RechtstraegerWithContactsTooltip
        rechtstraegerId={record.buchungskreis.rechtstraeger.rechtstraegerId}
        rechtstraegerName={record.buchungskreis.rechtstraeger.kurzBezeichnung}
        maxTextLength={20}
      />
    ),
  },
  {
    title: 'Belegsymbol',
    defaultSelected: true,
    dataIndex: FibuBuchungsanweisungOrderBy.BelegSymbol,
    sorter: true,
    render: (text, record) => <AvatarBasedOnBelegSymbolWithTooltip belegSymbol={record.buchungsKopf.belegSymbol} size="small" />,
  },
  {
    title: 'Buchungstyp',
    defaultSelected: true,
    dataIndex: FibuBuchungsanweisungOrderBy.BuchungsTyp,
    sorter: true,
    render: (text, record) => <Typography.Text>{record.buchungsKopf.buchungsType.text}</Typography.Text>,
  },
  {
    title: 'Zu stornierende Buchung',
    defaultSelected: false,
    dataIndex: FibuBuchungsanweisungOrderBy.ZuStornierendeBuchung,
    sorter: true,
    render: (text, record) => (
      <Link to={generatePathToBookingDetailsPage([record.zuStornierendeBuchung.buchungId])} target="_blank">
        {record.zuStornierendeBuchung.bezeichnung}
      </Link>
    ),
  },
  {
    title: 'Buchungsdatum',
    defaultSelected: true,
    align: 'right',
    dataIndex: FibuBuchungsanweisungOrderBy.BuchungsDatum,
    sorter: true,
    render: (text, record) => <CustomFormattedDate value={record.buchungsKopf.buchungsdatum} />,
  },
  {
    title: 'Belegdatum',
    defaultSelected: true,
    align: 'right',
    dataIndex: FibuBuchungsanweisungOrderBy.BelegDatum,
    sorter: true,
    render: (text, record) =>
      record.buchungsKopf.belegDatum ? <CustomFormattedDate value={record.buchungsKopf.belegDatum} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Belegnummer',
    defaultSelected: true,
    align: 'right',
    dataIndex: FibuBuchungsanweisungOrderBy.Belegnummer,
    sorter: true,
    render: (text, record) => {
      return record.buchungsKopf.belegFileId && record.buchungsKopf.belegnummer ? (
        <LinkToBelegFile fileId={record.buchungsKopf.belegFileId} belegnummer={record.buchungsKopf.belegnummer} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      );
    },
  },
  {
    title: 'Fälligkeit',
    defaultSelected: true,
    dataIndex: FibuBuchungsanweisungOrderBy.DueDate,
    sorter: true,
    render: (text, record) =>
      record.buchungsKopf.dueDate ? <CustomFormattedDate value={record.buchungsKopf.dueDate} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Objekt',
    sorter: false,
    render: (text, record) => {
      const objekt = record.objekt;

      return objekt ? (
        <DataWithShortenedText maxTextLength={15} text={objekt.kurzBezeichnung}>
          {(shortenedText) => (
            <Link to={generatePathToObjektDetailsPage(objekt.objektId)} target="_blank">
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      );
    },
  },
  {
    title: 'Brutto Betrag',
    defaultSelected: true,
    dataIndex: FibuBuchungsanweisungOrderBy.SumBrutto,
    sorter: true,
    render: (text, record) => <EuroAmount value={record.sumBrutto} />,
  },
  {
    title: 'Steuer',
    defaultSelected: false,
    align: 'right',
    dataIndex: FibuBuchungsanweisungOrderBy.SumSteuer,
    sorter: true,
    render: (text, record) => <EuroAmount value={record.sumSteuer} />,
  },
  {
    title: 'Fachliche Prüfung',
    defaultSelected: true,
    sorter: false,
    render: (text, record) => <Typography.Text>{record.fachlichePruefung ? 'Ja' : 'Nein'}</Typography.Text>,
  },
  {
    title: 'Status',
    defaultSelected: true,
    sorter: false,
    render: (text, record) => <Tag color={isFibuBuchungsanweisungVerbucht(record.status.value) ? 'green' : ''}>{record.status.text}</Tag>,
  },
  {
    title: 'Erstellt am',
    dataIndex: FibuBuchungsanweisungOrderBy.CreateTs,
    sorter: true,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    dataIndex: FibuBuchungsanweisungOrderBy.CreatedBy,
    sorter: true,
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    render: (text, record) => <BookingInstructionTableActions buchungsanweisung={record} onActionSuccess={onActionSuccess} />,
  },
];

export default bookingInstructionTableColumns;
