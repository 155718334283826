import { generatePath } from 'react-router-dom';
import { TicketQueryParamKey, TTicketQueryParams } from './Listing/Filters/filtersQueryParams';
import { mapToQueryString } from '../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../hooks/usePaginationQueryParams';

export const URI_TICKET_PAGE = '/dashboard/tickets';
export const URI_TICKET_DETAILS_PAGE = '/dashboard/tickets/:ticketId';
export const URI_SUB_TICKET_DETAILS_PAGE = '/dashboard/tickets/:ticketId/sub-tickets/:subTicketId';

/**
 * A minimal object required to generate the path to the ticket details page.
 * This is used in mutation response handling. see: ticketMutation.ts
 */
export type TicketAndParentId = {
  ticketId: string;
  parent?: {
    ticketId: string;
  } | null;
};

export const generatePathToTicketDetailsPage = (ticket: TicketAndParentId, tab?: string) => {
  tab = tab ? `/${tab}` : '';

  const ticketUri = ticket.parent ? URI_SUB_TICKET_DETAILS_PAGE : URI_TICKET_DETAILS_PAGE;
  const ticketId = ticket.parent?.ticketId ?? ticket.ticketId;
  const subTicketId = ticket.parent ? ticket.ticketId : null;

  return `${generatePath(ticketUri, { ticketId, subTicketId })}${tab}`;
};

export const pathsToTicketDetailsPage = (ticket: TicketAndParentId) => ({
  overviewTab: `${generatePathToTicketDetailsPage(ticket, 'overview')}`,
  activityTab: `${generatePathToTicketDetailsPage(ticket, 'activity')}`,
  activityCommon: `${generatePathToTicketDetailsPage(ticket, 'activity/common')}`,
  activityComments: `${generatePathToTicketDetailsPage(ticket, 'activity/comments')}`,
});

export const generatePathToTicketPage = (filters: TTicketQueryParams, paginationParams?: PaginationQueryParamsProps) => {
  return `${generatePath(URI_TICKET_PAGE)}?${mapToQueryString({
    [TicketQueryParamKey.DUE_DATE_FROM]: filters.dueDateFrom,
    [TicketQueryParamKey.DUE_DATE_TO]: filters.dueDateTo,
    [TicketQueryParamKey.ASSIGNEE_MITARBEITER_ID]: filters.assigneeMitarbeiterId,
    [TicketQueryParamKey.PARTICIPANT_ID]: filters.participantId,
    [TicketQueryParamKey.TYPE_NAME]: filters.typeName,
    [TicketQueryParamKey.PRIORITY]: filters.priority,
    [TicketQueryParamKey.TITLE]: filters.title,
    [TicketQueryParamKey.STATUS]: filters.status,
    [TicketQueryParamKey.SHOW_ARCHIVED]: filters.showArchived,
    [TicketQueryParamKey.SEARCH_IN_SUB_TICKETS]: filters.searchInSubTickets,
    [TicketQueryParamKey.OBJEKT_ID]: filters.objektId,
    [TicketQueryParamKey.BESTANDSEINHEIT_ID]: filters.bestandseinheitId,
    [TicketQueryParamKey.VERTRAG_ID]: filters.vertragId,
    [TicketQueryParamKey.RECHTSTRAEGER_ID]: filters.rechtstraegerId,
    ...paginationParams,
  })}`;
};
