import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  MahnungVerarbeitungEntryListingFiltersFormValues,
  mahnungVerarbEntryListingFiltersFormInitialValues,
  mapFormValuesToMahnungVerarbeitungFilters,
} from '../shared/EntryListingFilters/ListingFiltersFormMapper';
import {
  isVerarbeitungTypeMahnungAusgeben,
  MahnungAusgebenVersendenVerbuchenGenerierlaufData,
  verarbeitungPageAndMenuListTitles,
} from '../../verarbeitungHelpers';
import { Spacer } from '../../../../components/Grid';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { TMahnungVerarbeitungQueryParams, updateQueryParams } from '../shared/EntryListingFilters/filtersQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import ListingFilters from '../shared/EntryListingFilters/ListingFilters';
import { FibuGenerierlaufType } from '../../../../types';
import MahnungAusgebenEntryTable from './EntryListing/MahnungAusgebenEntryTable';
import MahnungVersendenEntryTable from './EntryListing/MahnungVersendenEntryTable';
import MahnungVerbuchenEntryTable from './EntryListing/MahnungVerbuchenEntryTable';

type Props = {
  generierlauf: MahnungAusgebenVersendenVerbuchenGenerierlaufData;
  verarbeitungType: string;
  generierlaufType: FibuGenerierlaufType;
};

const VerarbeitungMahnungAusgebenVersendenVerbuchen: FC<Props> = ({ generierlauf, verarbeitungType, generierlaufType }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TMahnungVerarbeitungQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(verarbeitungType, generierlauf.generierlaufId, navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: MahnungVerarbeitungEntryListingFiltersFormValues) => {
    updateQueryParams(verarbeitungType, generierlauf.generierlaufId, navigate, mapFormValuesToMahnungVerarbeitungFilters(values), paginationParams);
  };

  return (
    <>
      <Typography.Title level={4}>
        {isVerarbeitungTypeMahnungAusgeben(verarbeitungType)
          ? verarbeitungPageAndMenuListTitles.MahnungAusgabe
          : verarbeitungPageAndMenuListTitles.MahnungVersand}
      </Typography.Title>
      <Spacer />
      <Formik<MahnungVerarbeitungEntryListingFiltersFormValues>
        initialValues={mahnungVerarbEntryListingFiltersFormInitialValues(queryParams)}
        onSubmit={onChange}
      >
        {(formikProps) => (
          <>
            <ListingFilters formikProps={formikProps} generierlaufId={generierlauf.generierlaufId} generierlaufType={generierlaufType} />
            {generierlaufType === FibuGenerierlaufType.MahnungAusgabe && (
              <MahnungAusgebenEntryTable generierlaufId={generierlauf.generierlaufId} queryParams={queryParams} />
            )}
            {generierlaufType === FibuGenerierlaufType.MahnungVerbuchen && (
              <MahnungVerbuchenEntryTable generierlaufId={generierlauf.generierlaufId} queryParams={queryParams} />
            )}
            {generierlaufType === FibuGenerierlaufType.MahnungVersand && (
              <MahnungVersendenEntryTable generierlaufId={generierlauf.generierlaufId} queryParams={queryParams} />
            )}
          </>
        )}
      </Formik>
    </>
  );
};

export default VerarbeitungMahnungAusgebenVersendenVerbuchen;
