import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Tag, Typography } from 'antd';
import { Link } from 'react-router-dom';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { MitarbeiterTooltip } from '../../../../components/Card';
import GenerierlaufEntryStatus from '../../shared/GenerierlaufEntryStatus';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import {
  ApplyVertragVposIndexedValueGenerierlaufEntry,
  VertragVposIndexedValue,
  VertragVposIndexedValueGenerierlaufOrderBy,
} from '../../../../types';
import { generatePathToObjektDetailsPage } from '../../../Objekt/objektUriPaths';
import { generatePathToBestandseinheitDetailsPage } from '../../../Bestandseinheit/bestandseinheitUriPaths';
import { generatePathToRechtstraegerDetailsPage } from '../../../Rechtstraeger/rechtstraegerHelper';
import { EuroAmount, Percent } from '../../../../components/Number';
import { findEntryIndexByBestandseinheitId } from './entryTableHelpers';
import {
  generatePathToBeVertragDetailsPage,
  generatePathToBeVertragDetailsPageVertragsdatenVPosIndexationAgreementTab,
  generatePathToBeVertragDetailsPageVertragsdatenVPosTab,
  pathsToBeVertragDetailsPage,
} from '../../../Vertrag/BeVertrag/beVertragUriPaths';
import ValuePreservationSettings from '../../../shared/ValuePreservation/ValuePreservationSettings/ValuePreservationSettings';
import ActionDropdown from '../../../../components/Dropdown/ActionDropdown';
import { getStatusTagColor } from '../../../IndexedValue/shared/indexedValueHelpers';
import { generatePathToFirmendatenIndexSeriesDetailsPage } from '../../../Firmendaten/IndexSeries/firmendatenIndexSeriesUriPaths';
import { generatePathToIndexedValueListingPage } from '../../../IndexedValue/indexedValueUriPaths';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

const entryTableColumns = (
  entryList: ApplyVertragVposIndexedValueGenerierlaufEntry[]
): TableWithColSelectorColumnProps<VertragVposIndexedValue>[] => {
  return [
    {
      title: 'Status',
      defaultSelected: true,
      //sorter: (a, b) => compareTwoStringsForSorting(a.exitCode?.text, b.exitCode?.text),
      render: (_, record) => {
        const entry = entryList[findEntryIndexByBestandseinheitId(entryList, record.bestandseinheitId)];
        return entry ? <GenerierlaufEntryStatus generierlaufEntry={entry} /> : <TextForEmptyValue textToShow="none" />;
      },
    },
    {
      title: 'Objekt',
      defaultSelected: true,
      dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.Objekt,
      sorter: true,
      render: (text, record) => (
        <DataWithShortenedText maxTextLength={20} text={record.objektKurzBezeichnung}>
          {(shortenedText) => (
            <Link to={generatePathToObjektDetailsPage(record.objektId)} target="_blank">
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ),
    },
    {
      title: 'Bestandseinheit',
      dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.Bestandseinheit,
      sorter: true,
      render: (text, record) => {
        const entry = entryList[findEntryIndexByBestandseinheitId(entryList, record.bestandseinheitId)];

        return entry ? (
          <DataWithShortenedText maxTextLength={20} text={entry.bestandseinheitBezeichnung}>
            {(shortenedText) => (
              <Link to={generatePathToBestandseinheitDetailsPage(record.objektId, record.bestandseinheitId)} target="_blank">
                {shortenedText}
              </Link>
            )}
          </DataWithShortenedText>
        ) : (
          <TextForEmptyValue textToShow="minus" />
        );
      },
    },
    {
      title: 'Vertrag',
      dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.Vertrag,
      sorter: true,
      render: (text, record) => {
        const entry = entryList[findEntryIndexByBestandseinheitId(entryList, record.bestandseinheitId)];

        return entry ? (
          <DataWithShortenedText maxTextLength={20} text={entry.vertragBezeichnung}>
            {(shortenedText) => (
              <Link to={generatePathToBeVertragDetailsPage(record.objektId, record.bestandseinheitId, record.vertragId)} target="_blank">
                {shortenedText}
              </Link>
            )}
          </DataWithShortenedText>
        ) : (
          <TextForEmptyValue textToShow="minus" />
        );
      },
    },
    {
      title: 'Rechnungsaussteller',
      dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.RechnungsAussteller,
      sorter: true,
      render: (text, record) => (
        <DataWithShortenedText maxTextLength={20} text={record.rechnungsAusstellerKurzBezeichnung}>
          {(shortenedText) => (
            <Link to={generatePathToRechtstraegerDetailsPage(record.rechnungsAusstellerId)} target="_blank">
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ),
    },
    {
      title: 'Vertragspartner',
      dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.Vertragspartner,
      sorter: true,
      render: (text, record) => (
        <DataWithShortenedText maxTextLength={20} text={record.vertragspartnerKurzBezeichnung}>
          {(shortenedText) => (
            <Link to={generatePathToRechtstraegerDetailsPage(record.vertragspartnerId)} target="_blank">
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ),
    },
    {
      title: 'Indexierter Wert',
      defaultSelected: true,
      dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.IndexedValue,
      sorter: true,
      render: (text, record) => <Typography.Text>{record.name}</Typography.Text>,
    },
    {
      title: 'Wertstatus',
      dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.WertStatus,
      sorter: true,
      render: (text, record) => <Tag color={getStatusTagColor(record.status.value)}>{record.status.text}</Tag>,
    },
    {
      title: 'VPos Gültig ab',
      defaultSelected: true,
      dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.ValidFrom,
      sorter: true,
      render: (text, record) => <CustomFormattedDate value={record.validFrom} />,
    },
    {
      title: 'Wertsicherungsart',
      defaultSelected: true,
      // TODO: Link
      render: (text, record) => (
        <Link
          to={pathsToBeVertragDetailsPage(record.objektId, record.bestandseinheitId, record.vertragId).vertragsdatenIndexationAgreementTab}
          target="_blank"
        >
          {record.indexationAgreement.type.text}
        </Link>
      ),
    },
    {
      title: 'Indexreihe',
      dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.IndexSeries,
      sorter: true,
      render: (text, record) => (
        <DataWithShortenedText maxTextLength={20} text={record.indexSeriesName}>
          {(shortenedText) => (
            <Link to={generatePathToFirmendatenIndexSeriesDetailsPage(record.indexSeriesId)} target="_blank">
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ),
    },
    {
      title: 'Wertsicherungseinstellungen',
      dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.IndexationAgreement,
      sorter: true,
      render: (text, record) => <ValuePreservationSettings indexationAgreement={record.indexationAgreement} />,
    },
    {
      title: 'Mon./Jahr alt',
      dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.YearMonthOld,
      sorter: true,
      render: (text, record) => <Typography.Text>{record.monthYearOld}</Typography.Text>,
    },
    {
      title: 'Index alt',
      defaultSelected: true,
      dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.IndexOld,
      sorter: true,
      render: (text, record) => <FormattedNumber value={record.indexOld} />,
    },
    {
      title: 'Vorläufig Index alt',
      width: 120,
      dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.IndexOldPreliminary,
      sorter: true,
      render: (text, record) => <Typography.Text>{record.indexOldPreliminary ? 'Ja' : 'Nein'}</Typography.Text>,
    },
    {
      title: 'Mon./Jahr neu',
      dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.YearMonthNew,
      sorter: true,
      render: (text, record) => <Typography.Text>{record.monthYearNew}</Typography.Text>,
    },
    {
      title: 'Index neu',
      defaultSelected: true,
      dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.IndexNew,
      sorter: true,
      render: (text, record) => <FormattedNumber value={record.indexNew} />,
    },
    {
      title: 'Vorläufig Index neu',
      width: 120,
      dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.IndexNewPreliminary,
      sorter: true,
      render: (text, record) => <Typography.Text>{record.indexNewPreliminary ? 'Ja' : 'Nein'}</Typography.Text>,
    },
    {
      title: 'Errechneter Faktor (%)',
      dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.CalculatedFactorNew,
      sorter: true,
      render: (text, record) => <FormattedNumber value={record.calculatedFactorNew} />,
    },
    {
      title: 'Faktor (%)',
      defaultSelected: true,
      dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.FactorNew,
      sorter: true,
      render: (text, record) => <Percent value={record.calculatedFactorNewPercent / 100} />,
    },
    {
      title: 'Betrag alt',
      defaultSelected: true,
      dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.AmountOld,
      sorter: true,
      render: (text, record) =>
        record.vposWertOldId ? (
          <Link
            to={generatePathToBeVertragDetailsPageVertragsdatenVPosTab(
              record.objektId,
              record.bestandseinheitId,
              record.vertragId,
              record.vposWertOldId,
              [record.vorschreibungspositionId]
            )}
            target="_blank"
          >
            <EuroAmount value={record.amountOld} />
          </Link>
        ) : (
          <EuroAmount value={record.amountOld} />
        ),
    },
    {
      title: 'Errechneter Betrag neu',
      dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.CalculatedAmountNew,
      sorter: true,
      render: (text, record) => <EuroAmount value={record.calculatedAmountNew} />,
    },
    {
      title: 'Betrag neu',
      defaultSelected: true,
      dataIndex: VertragVposIndexedValueGenerierlaufOrderBy.AmountNew,
      sorter: true,
      render: (text, record) =>
        record.vposWertNewId ? (
          <Link
            to={generatePathToBeVertragDetailsPageVertragsdatenVPosTab(
              record.objektId,
              record.bestandseinheitId,
              record.vertragId,
              record.vposWertNewId,
              [record.vorschreibungspositionId]
            )}
            target="_blank"
          >
            <EuroAmount value={record.amountNew} />
          </Link>
        ) : (
          <EuroAmount value={record.amountNew} />
        ),
    },
    {
      title: 'Ersteller',
      render: (text, record) => (
        <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
      ),
    },
    {
      title: 'Aktion',
      defaultSelected: true,
      render: (text, record) => {
        const items = [
          {
            key: '1',
            label: 'Wert anzeigen',
            onClick: () =>
              window.open(
                generatePathToIndexedValueListingPage({
                  objektId: record.objektId,
                  vertragspartnerId: record.vertragspartnerId,
                  valuePreservationTypeList: [record.indexationAgreement.type.value],
                  validFrom: record.validFrom,
                  setDefaultStatusOnInit: false,
                }),
                '_blank'
              ),
          },
          {
            key: '2',
            label: 'WS-Vereinbarung anzeigen',
            onClick: () =>
              window.open(
                generatePathToBeVertragDetailsPageVertragsdatenVPosIndexationAgreementTab(
                  record.objektId,
                  record.bestandseinheitId,
                  record.vertragId,
                  record.indexationAgreement.vertragVposIndexationAgreementId,
                  [record.vorschreibungspositionId]
                ),
                '_blank'
              ),
          },
        ];
        return <ActionDropdown items={items} />;
      },
    },
  ];
};

export default entryTableColumns;
