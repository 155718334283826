import * as Yup from 'yup';

import {
  bruttoValidationSchema,
  nettoValidationSchema,
  steuersatzValidationSchema,
  ustValidationSchema,
} from '../../../../../../../shared/BruttoNettoUst/bruttoNettoUstSteuerValidationSchema';
import { VorschreibungspositionBasis } from '../../../../../../../types';
import { validateSyncWithSchemaAndReturnErrorIfAny } from '../../../../../../../helpers/validationHelper';
import { entityIsRequired } from '../../../../../../../components/message/validationMsg';
import { vorschreibungsPositionWertFormFields } from './vertragVorschreibungsPositionWertMapper';
import { TFormattedDecimal } from '../../../../../../../helpers/formikHelper';

export const vertragVorschreibungsPositionWertSchema = Yup.object().shape({
  [vorschreibungsPositionWertFormFields.validFrom]: Yup.date().nullable().required(entityIsRequired('Gültig ab')),
  [vorschreibungsPositionWertFormFields.basis]: Yup.string().required(entityIsRequired('Basis')),
  [vorschreibungsPositionWertFormFields.netto]: nettoValidationSchema.when(vorschreibungsPositionWertFormFields.basis, ([value], schema) =>
    value === VorschreibungspositionBasis.Netto ? nettoValidationSchema.required(entityIsRequired('Netto')) : schema
  ),
  [vorschreibungsPositionWertFormFields.brutto]: bruttoValidationSchema.when(vorschreibungsPositionWertFormFields.basis, ([value], schema) =>
    value === VorschreibungspositionBasis.Brutto ? bruttoValidationSchema.required(entityIsRequired('Brutto')) : schema
  ),
});

export const isNettoValid = (netto: TFormattedDecimal) => !validateSyncWithSchemaAndReturnErrorIfAny(nettoValidationSchema, netto);
export const isBruttoValid = (brutto: TFormattedDecimal) => !validateSyncWithSchemaAndReturnErrorIfAny(bruttoValidationSchema, brutto);
export const isSteuersatzValid = (steuersatz: number) => !validateSyncWithSchemaAndReturnErrorIfAny(steuersatzValidationSchema, steuersatz);
export const isUstValid = (ust: number) => !validateSyncWithSchemaAndReturnErrorIfAny(ustValidationSchema, ust);
