import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useWizardTypesQuery } from '../gql/wizardTypeQueries.types';
import { getWizardDetailsByType } from '../../wizardHelpers';

type Props = SelectProps;

const WizardTypeSelect: FC<Props> = ({ name, ...restProps }) => {
  const { data, loading } = useWizardTypesQuery();

  const typeList = data?.getWizardTypes.data ?? [];

  const types = typeList.filter((type) => getWizardDetailsByType(type)).map((type) => getWizardDetailsByType(type));
  return (
    <Select size="middle" placeholder="" {...restProps} id={name} name={name} loading={loading} style={{ minWidth: '250px' }} allowClear>
      {types.map(
        (type) =>
          type && (
            <Select.Option key={type.type} value={type.type}>
              {type.name}
            </Select.Option>
          )
      )}
    </Select>
  );
};
export default WizardTypeSelect;
