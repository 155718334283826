import { FormFields, mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';
import { ITimeblock } from '../../../../../components/Timeline/timelineCtx';
import { FirmendatenLeerstehungAufwandskonto, FirmendatenLeerstehungAufwandskontoInput } from '../../../../../types';
import { ValuesDescriptionForKommentar } from '../../../../../components/Timeline/versionTimelineHelper';

export type LeerstehungAufwandskontoVersionFormValues = {
  validFrom: string;
  kommentar: string;
  leerstehungAufwandskontoId: string;
};

export const leerstehungAufwandskontoVersionFormFields: FormFields<LeerstehungAufwandskontoVersionFormValues> = {
  validFrom: 'validFrom',
  kommentar: 'kommentar',
  leerstehungAufwandskontoId: 'leerstehungAufwandskontoId',
};

export const mapTimeblockToFormValues = (timeblock: ITimeblock<FirmendatenLeerstehungAufwandskonto>): LeerstehungAufwandskontoVersionFormValues => ({
  validFrom: timeblock.validFrom,
  kommentar: timeblock.kommentar ?? '',
  leerstehungAufwandskontoId: timeblock.leerstehungAufwandskontoId,
});

export const mapFormValuesToLeerstehungAufwandskontoVersion = (
  values: LeerstehungAufwandskontoVersionFormValues
): FirmendatenLeerstehungAufwandskontoInput => ({
  validFrom: mapFormDateValueToDateString(values.validFrom),
  kommentar: values.kommentar,
  leerstehungAufwandskontoId: values.leerstehungAufwandskontoId,
});

export const leerstehungAufwandskontoVersionFormValuesDescriptionsForKommentar: ValuesDescriptionForKommentar<LeerstehungAufwandskontoVersionFormValues> =
  {
    validFrom: 'Gültigkeitsdatum',
    leerstehungAufwandskontoId: 'Leerstehung Aufwandskonto',
  };
