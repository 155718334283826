import React, { FC } from 'react';
import { Formik } from 'formik';
import { Col, Row, Space, Typography } from 'antd';
import { Box } from 'rebass';
import { Form } from 'formik-antd';
import { generatePath, useNavigate } from 'react-router';
import {
  ErrorData,
  SingleInvoiceCreateInput,
  SingleInvoiceUpdateInput,
  StandingInvoiceCreateInput,
  StandingInvoiceUpdateInput,
} from '../../../types';
import ErrorMessagePanel from '../../../components/Error/ErrorMessagePanel';
import { Spacer } from '../../../components/Grid';
import BuchungErrorList from './BuchungErrorList';
import {
  IncomingInvoiceFormValues,
  mapFormValuesToInvoiceCreate,
  mapFormValuesToInvoiceUpdate,
  mapIncomingInvoiceToFormValues,
  TInvoice,
} from './incomingInvoiceFormMapper';
import IncomingInvoiceDetailsBlockForm from './IncomingInvoiceDetailsBlockForm';
import { isStatusErstellt, isStatusUebertragen, isStatusUebertragenFehler } from '../../../helpers/statusHelper';
import PaymentBlockForm from './PaymentBlockForm';
import AbgrenzungBuchungBlockForm from './AbgrenzungBuchungBlockForm';
import { isBillingTypeDauerrechnung, isBillingTypeDauerrechnungJaehrlich, isBillingTypeEinzelrechnung } from '../../Payment/paymentHelpers';
import BuchungPositionBlockForm from './BuchungPositionBlockForm';
import SummaryBlockForm from './SummaryBlockForm';
import StandingInvoiceBlockForm from './StandingInvoiceBlockForm';
import { incomingInvoiceFormCreateValidationSchema, incomingInvoiceFormUpdateValidationSchema } from './incomingInvoiceFormValidationSchema';
import IncomingInvoiceFormButtons from './IncomingInvoiceFormButtons';
import { useCreateSingleInvoiceMutation, useUpdateSingleInvoiceMutation } from '../gql/SingleInvoiceMutations.types';
import { showErrorNotification, showSuccessMsgOther, showSuccessMsgUpdate } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { useCreateStandingInvoiceMutation, useUpdateStandingInvoiceMutation } from '../gql/StandingInvoiceMutations.types';
import { useToggle } from '../../../hooks/useToggle';
import { mapFormValuesToSingleInvoiceUpdate } from './singleInvoiceFormMapper';
import { hasSingleInvoiceChanged, hasStandingInvoiceChanged } from './incomingInvoiceFormHelpers';
import StandingInvoiceConfirmModal from './StandingInvoiceConfirmModal/StandingInvoiceConfirmModal';
import SingleInvoiceStornoModal from './SingleInvoiceStornoModal/SingleInvoiceStornoModal';
import { incomingInvoicePaths } from '../incomingInvoiceUriPaths';
import DocumentStatusTag from '../../../components/Status/DocumentStatus/DocumentStatusTag';

type Props = {
  fileId: string;
  incomingInvoice?: TInvoice;
  incomingInvoiceLoading?: boolean;
  refetchIncomingInvoice?: () => Promise<unknown>;
  errorList?: ErrorData[];
};

const IncomingInvoiceForm: FC<Props> = ({ fileId, incomingInvoice, incomingInvoiceLoading, refetchIncomingInvoice, errorList }) => {
  const navigate = useNavigate();

  const [isSingleInvoiceStornoBookingModalOpen, toggleSingleInvoiceStornoBookingModal] = useToggle();
  const [isStandingInvoiceConfirmModalOpen, toggleStandingInvoiceConfirmModal] = useToggle();

  const isUpdate = !!incomingInvoice;
  const isRechnungStatusErstellt = incomingInvoice && isStatusErstellt(incomingInvoice.status);

  const initialFormValues = mapIncomingInvoiceToFormValues(fileId, incomingInvoice);

  const shouldRestore = !!incomingInvoice && isStatusUebertragenFehler(incomingInvoice.status);

  const [createSingleInvoice, { loading: createSingleInvoiceLoading }] = useCreateSingleInvoiceMutation({
    onCompleted: (data) => {
      showSuccessMsgOther('Eingangsrechnung wurde erfolgreich erfasst');
      const createdRechnung = data.createSingleInvoice.data;
      navigate(
        generatePath(incomingInvoicePaths.singleInvoiceDetails, {
          fileId: createdRechnung.fileId,
          belegId: createdRechnung.belegId,
        })
      );
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [createStandingInvoice, { loading: createStandingInvoiceLoading }] = useCreateStandingInvoiceMutation({
    onCompleted: (data) => {
      showSuccessMsgOther('Eingangsrechnung wurde erfolgreich erfasst');
      const createdRechnung = data.createStandingInvoice.data;
      navigate(
        generatePath(incomingInvoicePaths.standingInvoiceDetails, {
          fileId: createdRechnung.fileId,
          belegId: createdRechnung.belegId,
        })
      );
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [updateSingleInvoice, { loading: updateSingleInvoiceLoading }] = useUpdateSingleInvoiceMutation({
    onCompleted: (data) => {
      showSuccessMsgUpdate('Eingangsrechnung');
      const updatedRechnung = data.updateSingleInvoice.data;
      navigate(
        generatePath(incomingInvoicePaths.singleInvoiceDetails, {
          fileId: updatedRechnung.fileId,
          belegId: updatedRechnung.belegId,
        })
      );
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [updateStandingInvoice, { loading: updateStandingInvoiceLoading }] = useUpdateStandingInvoiceMutation({
    onCompleted: (data) => {
      showSuccessMsgUpdate('Eingangsrechnung');
      const updatedRechnung = data.updateStandingInvoice.data;
      navigate(
        generatePath(incomingInvoicePaths.standingInvoiceDetails, {
          fileId: updatedRechnung.fileId,
          belegId: updatedRechnung.belegId,
        })
      );
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const isSingleInvoiceLoading = createSingleInvoiceLoading || updateSingleInvoiceLoading || incomingInvoiceLoading;
  const isStandingInvoiceLoading = createStandingInvoiceLoading || updateStandingInvoiceLoading || incomingInvoiceLoading;

  return (
    <Box>
      <Row align="top">
        <Col span={6} />
        <Col span={18}>
          <Space direction="vertical" size={16} style={{ width: '100%' }}>
            <Typography.Title style={{ display: 'flex', alignItems: 'center', gap: 8 }} level={4}>
              Rechnungsdetails {incomingInvoice && <DocumentStatusTag documentStatus={incomingInvoice.status} />}
            </Typography.Title>
            <div>
              {errorList && errorList?.length > 0 && <ErrorMessagePanel error={errorList} />}
              <BuchungErrorList incomingInvoice={incomingInvoice} />
            </div>
          </Space>
        </Col>
      </Row>
      <Spacer height={16} />

      <Formik<IncomingInvoiceFormValues>
        initialValues={initialFormValues}
        validationSchema={isUpdate ? incomingInvoiceFormUpdateValidationSchema : incomingInvoiceFormCreateValidationSchema}
        onSubmit={(formValues, { setSubmitting }) => {
          if (!incomingInvoice) {
            // CREATE
            const input = mapFormValuesToInvoiceCreate(formValues);
            if (isBillingTypeEinzelrechnung(formValues.billingType)) {
              createSingleInvoice({ variables: { input: input as SingleInvoiceCreateInput } });
            } else {
              createStandingInvoice({ variables: { input: input as StandingInvoiceCreateInput } });
            }
          } else if (isStatusErstellt(incomingInvoice.status)) {
            // UPDATE
            const input = mapFormValuesToInvoiceUpdate(formValues);
            if (isBillingTypeEinzelrechnung(formValues.billingType)) {
              updateSingleInvoice({
                variables: {
                  belegId: incomingInvoice.belegId,
                  input: input as SingleInvoiceCreateInput,
                },
              });
            } else {
              updateStandingInvoice({
                variables: {
                  belegId: incomingInvoice.belegId,
                  input: input as StandingInvoiceCreateInput,
                },
              });
            }
          } else if (isStatusUebertragen(incomingInvoice.status)) {
            if (isBillingTypeEinzelrechnung(formValues.billingType)) {
              // Check if single invoice changed - Ignore zahlungs related values
              if (hasSingleInvoiceChanged(formValues, initialFormValues)) {
                toggleSingleInvoiceStornoBookingModal();
              } else {
                updateSingleInvoice({
                  variables: {
                    belegId: incomingInvoice.belegId,
                    input: mapFormValuesToInvoiceUpdate(formValues) as SingleInvoiceUpdateInput,
                  },
                });
              }
            } else {
              // eslint-disable-next-line no-lonely-if
              if (hasStandingInvoiceChanged(formValues, initialFormValues)) {
                toggleStandingInvoiceConfirmModal();
              } else {
                updateStandingInvoice({
                  variables: {
                    belegId: incomingInvoice.belegId,
                    input: mapFormValuesToInvoiceUpdate(formValues) as StandingInvoiceUpdateInput,
                  },
                });
              }
            }
          } else {
            showErrorNotification('Eingangsrechnung darf gerade nicht aktualisiert werden');
          }
        }}
      >
        {(formikProps) => (
          <>
            <Form layout="vertical" colon={false}>
              <IncomingInvoiceDetailsBlockForm
                formikProps={formikProps}
                shouldRestore={shouldRestore}
                incomingInvoice={incomingInvoice}
                isUpdate={isUpdate}
              />
              <PaymentBlockForm formikProps={formikProps} shouldRestore={shouldRestore} />
              {isBillingTypeEinzelrechnung(formikProps.values.billingType) && (!isUpdate || isRechnungStatusErstellt) && (
                <AbgrenzungBuchungBlockForm
                  formikProps={formikProps}
                  shouldRestore={shouldRestore}
                  isIncomingInvoiceVerbucht={isUpdate && !isRechnungStatusErstellt}
                />
              )}
              {isBillingTypeEinzelrechnung(formikProps.values.billingType) && (
                <BuchungPositionBlockForm formikProps={formikProps} shouldRestore={shouldRestore} />
              )}
              {(isBillingTypeDauerrechnung(formikProps.values.billingType) ||
                isBillingTypeDauerrechnungJaehrlich(formikProps.values.billingType)) && (
                <StandingInvoiceBlockForm formikProps={formikProps} shouldRestore={shouldRestore} />
              )}
              {isBillingTypeEinzelrechnung(formikProps.values.billingType) && (
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                <SummaryBlockForm inklusiveSteuer={formikProps.values.inklusiveSteuer} positionList={formikProps.values.buchungPositionList!} />
              )}
              <IncomingInvoiceFormButtons
                incomingInvoice={incomingInvoice}
                formikProps={formikProps}
                goBack={() => navigate(-1)}
                shouldRestore={shouldRestore}
                isLoading={isSingleInvoiceLoading || isStandingInvoiceLoading}
                refetchIncomingInvoice={refetchIncomingInvoice}
              />
              {incomingInvoice && (
                <>
                  <SingleInvoiceStornoModal
                    values={formikProps.values}
                    belegId={incomingInvoice.belegId}
                    isOpen={isSingleInvoiceStornoBookingModalOpen}
                    onCancel={toggleSingleInvoiceStornoBookingModal}
                    onSubmit={(stornoFormValues) => {
                      const input = mapFormValuesToSingleInvoiceUpdate(formikProps.values, stornoFormValues);
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      updateSingleInvoice({ variables: { belegId: incomingInvoice!.belegId, input } });
                    }}
                    loading={updateSingleInvoiceLoading}
                  />
                  <StandingInvoiceConfirmModal
                    belegId={incomingInvoice.belegId}
                    values={formikProps.values}
                    loading={updateStandingInvoiceLoading}
                    onCancel={toggleStandingInvoiceConfirmModal}
                    isOpen={isStandingInvoiceConfirmModalOpen}
                    onSubmit={() => {
                      updateStandingInvoice({
                        variables: {
                          belegId: incomingInvoice.belegId,
                          input: mapFormValuesToInvoiceUpdate(formikProps.values) as StandingInvoiceUpdateInput,
                        },
                      });
                    }}
                  />
                </>
              )}
            </Form>
          </>
        )}
      </Formik>
    </Box>
  );
};

export default IncomingInvoiceForm;
