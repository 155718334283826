import React from 'react';
import { Route } from 'react-router';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../security/permissionChecks';
import SysSettingsAufteilungschluesselPage from './SysSettingsAufteilungsschluesselPage';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_SYS_SETTINGS } from '../../../../constants/configurationUriPaths';

const sysSettingsAufteilungsschluesselPageRoute = (
  <Route
    path={URI_SYS_SETTINGS.aufteilungsschluesselPage}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsAufteilungschluesselPage />} />}
  />
);

export default sysSettingsAufteilungsschluesselPageRoute;
