import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { ObjektBankDetailsFieldsFragmentDoc } from './BankDetailsFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WeRechnungsAusstellerListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
}>;

export type WeRechnungsAusstellerListQuery = {
  getWeRechnungsAusstellerList: {
    data: Array<{
      weRechnungsAusstellerFibuKontoCreatable: boolean;
      weRechnungsAusstellerKurzBezeichnung: string;
      weRechnungsAusstellerRechtstraegerId: string;
      objektBankDetailsList: Array<{
        bezeichnung: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        objektBankDetailsId: string;
        updatedByMitarbeiterId?: string | null;
        bankDetails: {
          accountHolder: string;
          accountLimit?: number | null;
          assignedHeVertragZahlungsregelMap: any;
          assignedObjektMap: any;
          bankDetailsId: string;
          bankname: string;
          bic: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          ebicsCreditTransferUserList: Array<string>;
          ebicsDirectDebitUserList: Array<string>;
          fibuKontoId?: string | null;
          fibuKontonummer?: string | null;
          iban: string;
          updatedByMitarbeiterId?: string | null;
          fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
          fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
          sepaMandat?: {
            fileId?: string | null;
            fileName?: string | null;
            sepaMandatId: string;
            sepaMandatReference: string;
            signatureDate?: string | null;
            firmendaten?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firmendatenId: string;
              kundenSystemId: string;
              kurzBezeichnung: string;
              name1: string;
              name2?: string | null;
              name3?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechnungsAussteller?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
            warningList: Array<{ message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        verwendungList: Array<{ description?: string | null; text: string; value: Types.ObjektBankDetailsVerwendung }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const WeRechnungsAusstellerListDocument = gql`
  query WeRechnungsAusstellerList($objektId: ID!) {
    getWeRechnungsAusstellerList(objektId: $objektId) {
      data {
        objektBankDetailsList {
          ...ObjektBankDetailsFields
        }
        warningList {
          attribute
          message
          type
        }
        weRechnungsAusstellerFibuKontoCreatable
        weRechnungsAusstellerKurzBezeichnung
        weRechnungsAusstellerRechtstraegerId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${ObjektBankDetailsFieldsFragmentDoc}
`;
export function useWeRechnungsAusstellerListQuery(
  baseOptions: Apollo.QueryHookOptions<WeRechnungsAusstellerListQuery, WeRechnungsAusstellerListQueryVariables> &
    ({ variables: WeRechnungsAusstellerListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WeRechnungsAusstellerListQuery, WeRechnungsAusstellerListQueryVariables>(WeRechnungsAusstellerListDocument, options);
}
export function useWeRechnungsAusstellerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WeRechnungsAusstellerListQuery, WeRechnungsAusstellerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WeRechnungsAusstellerListQuery, WeRechnungsAusstellerListQueryVariables>(WeRechnungsAusstellerListDocument, options);
}
export function useWeRechnungsAusstellerListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WeRechnungsAusstellerListQuery, WeRechnungsAusstellerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<WeRechnungsAusstellerListQuery, WeRechnungsAusstellerListQueryVariables>(WeRechnungsAusstellerListDocument, options);
}
export type WeRechnungsAusstellerListQueryHookResult = ReturnType<typeof useWeRechnungsAusstellerListQuery>;
export type WeRechnungsAusstellerListLazyQueryHookResult = ReturnType<typeof useWeRechnungsAusstellerListLazyQuery>;
export type WeRechnungsAusstellerListSuspenseQueryHookResult = ReturnType<typeof useWeRechnungsAusstellerListSuspenseQuery>;
export type WeRechnungsAusstellerListQueryResult = Apollo.QueryResult<WeRechnungsAusstellerListQuery, WeRechnungsAusstellerListQueryVariables>;
