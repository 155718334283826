import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VertragLevelListQueryVariables = Types.Exact<{
  verwaltungsart?: Types.InputMaybe<Types.Verwaltungsart>;
}>;

export type VertragLevelListQuery = {
  getVertragLevelList: {
    data: Array<{ shortName: string; text: string; value: Types.VertragLevel }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const VertragLevelListDocument = gql`
  query VertragLevelList($verwaltungsart: Verwaltungsart) {
    getVertragLevelList(verwaltungsart: $verwaltungsart) {
      data {
        shortName
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useVertragLevelListQuery(baseOptions?: Apollo.QueryHookOptions<VertragLevelListQuery, VertragLevelListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VertragLevelListQuery, VertragLevelListQueryVariables>(VertragLevelListDocument, options);
}
export function useVertragLevelListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VertragLevelListQuery, VertragLevelListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VertragLevelListQuery, VertragLevelListQueryVariables>(VertragLevelListDocument, options);
}
export function useVertragLevelListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VertragLevelListQuery, VertragLevelListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VertragLevelListQuery, VertragLevelListQueryVariables>(VertragLevelListDocument, options);
}
export type VertragLevelListQueryHookResult = ReturnType<typeof useVertragLevelListQuery>;
export type VertragLevelListLazyQueryHookResult = ReturnType<typeof useVertragLevelListLazyQuery>;
export type VertragLevelListSuspenseQueryHookResult = ReturnType<typeof useVertragLevelListSuspenseQuery>;
export type VertragLevelListQueryResult = Apollo.QueryResult<VertragLevelListQuery, VertragLevelListQueryVariables>;
