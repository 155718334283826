import { FormFields } from '../../../../helpers/formikHelper';
import { AbrechnungGenerierlaufType, AbrechnungType, FakturierungsperiodeInput, TopAbrechnungGenerierlaufCreateInput } from '../../../../types';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import { ALLE_TAG_TEXT } from '../../../../shared/ObjektSelect/ObjektSelectWithAllTag';

export interface FirmendatenTopAbrechnungFormValues {
  fakturierungsperiode: string;
  objektIdList?: string[] | null;
  objektAbrechnungIdList?: string[] | null;
}

export const firmendatenTopAbrechnungFormInitialValues: FirmendatenTopAbrechnungFormValues = {
  fakturierungsperiode: dayjsCustom().format(),
  objektIdList: null,
  objektAbrechnungIdList: null,
};

export const firmendatenTopAbrechnungFormFields: FormFields<FirmendatenTopAbrechnungFormValues> = {
  fakturierungsperiode: 'fakturierungsperiode',
  objektIdList: 'objektIdList',
  objektAbrechnungIdList: 'objektAbrechnungIdList',
};

export const mapFormValuesToFirmendatenTopAbrechnungCreateInput = (
  abrechnungType: AbrechnungType,
  values: FirmendatenTopAbrechnungFormValues
): TopAbrechnungGenerierlaufCreateInput => ({
  abrechnungType,
  type: AbrechnungGenerierlaufType.TopAbrechnungGenerierlauf,
  fakturierungsperiode: mapFakturierungsperiodeToInput(values.fakturierungsperiode),
  objektIdList: values.objektIdList?.includes(ALLE_TAG_TEXT) ? null : values.objektIdList,
  objektAbrechnungIdList: values.objektAbrechnungIdList?.includes(ALLE_TAG_TEXT) ? null : values.objektAbrechnungIdList,
});

export const mapFakturierungsperiodeToInput = (fakturierungsperiode: string): FakturierungsperiodeInput => ({
  monat: dayjsCustom(fakturierungsperiode).month() + 1, // month is zero based, january = 0 in dayjs
  jahr: dayjsCustom(fakturierungsperiode).year(),
});
