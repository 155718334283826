import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { BestandseinheitAufteilungsschluesselWertFieldsFragmentDoc } from './BestandseinheitAufteilungsschluesselFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBestandseinheitAufteilungsschluesselWertMitBezugMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  aufteilungsschluesselId: Types.Scalars['ID']['input'];
  BeAufteilingsschluesselWertMitBezugInput: Types.BeAufteilingsschluesselWertMitBezugInput;
}>;

export type CreateBestandseinheitAufteilungsschluesselWertMitBezugMutation = {
  createBeAufteilungsschluesselWertMitBezug: {
    data: {
      aktiv: boolean;
      beAufteilungsschluesselWertId?: string | null;
      createTs?: string | null;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      geloescht: boolean;
      validFrom?: string | null;
      wert: number;
      verwaltungSeitRelation: { text: string; value: Types.BeAufteilingsschluesselWertVerwaltungSeitRelation };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateBestandseinheitAufteilungsschluesselWertOhneBezugMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  aufteilungsschluesselId: Types.Scalars['ID']['input'];
  BeAufteilingsschluesselWertOhneBezugInput: Types.BeAufteilingsschluesselWertOhneBezugInput;
}>;

export type CreateBestandseinheitAufteilungsschluesselWertOhneBezugMutation = {
  createBeAufteilungsschluesselWertOhneBezug: {
    data: {
      aktiv: boolean;
      beAufteilungsschluesselWertId?: string | null;
      createTs?: string | null;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      geloescht: boolean;
      validFrom?: string | null;
      wert: number;
      verwaltungSeitRelation: { text: string; value: Types.BeAufteilingsschluesselWertVerwaltungSeitRelation };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateBestandseinheitAufteilungsschluesselWertMitBezugMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  aufteilungsschluesselId: Types.Scalars['ID']['input'];
  beAufteilungsschluesselWertId: Types.Scalars['ID']['input'];
  BeAufteilingsschluesselWertMitBezugInput: Types.BeAufteilingsschluesselWertMitBezugInput;
}>;

export type UpdateBestandseinheitAufteilungsschluesselWertMitBezugMutation = {
  updateBeAufteilungsschluesselWertMitBezug: {
    data: {
      aktiv: boolean;
      beAufteilungsschluesselWertId?: string | null;
      createTs?: string | null;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      geloescht: boolean;
      validFrom?: string | null;
      wert: number;
      verwaltungSeitRelation: { text: string; value: Types.BeAufteilingsschluesselWertVerwaltungSeitRelation };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateBestandseinheitAufteilungsschluesselWertOhneBezugMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  aufteilungsschluesselId: Types.Scalars['ID']['input'];
  beAufteilungsschluesselWertId: Types.Scalars['ID']['input'];
  BeAufteilingsschluesselWertOhneBezugInput: Types.BeAufteilingsschluesselWertOhneBezugInput;
}>;

export type UpdateBestandseinheitAufteilungsschluesselWertOhneBezugMutation = {
  updateBeAufteilungsschluesselWertOhneBezug: {
    data: {
      aktiv: boolean;
      beAufteilungsschluesselWertId?: string | null;
      createTs?: string | null;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      geloescht: boolean;
      validFrom?: string | null;
      wert: number;
      verwaltungSeitRelation: { text: string; value: Types.BeAufteilingsschluesselWertVerwaltungSeitRelation };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteBestandseinheitAufteilungsschluesselWertMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  aufteilungsschluesselId: Types.Scalars['ID']['input'];
  beAufteilungsschluesselWertId: Types.Scalars['ID']['input'];
}>;

export type DeleteBestandseinheitAufteilungsschluesselWertMutation = {
  deleteBeAufteilungsschluesselWert: {
    data: { beAufteilungsschluesselWertId?: string | null; geloescht: boolean };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateBestandseinheitAufteilungsschluesselWertMitBezugDocument = gql`
  mutation CreateBestandseinheitAufteilungsschluesselWertMitBezug(
    $objektId: ID!
    $bestandseinheitId: ID!
    $aufteilungsschluesselId: ID!
    $BeAufteilingsschluesselWertMitBezugInput: BeAufteilingsschluesselWertMitBezugInput!
  ) {
    createBeAufteilungsschluesselWertMitBezug(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      aufteilungsschluesselId: $aufteilungsschluesselId
      input: $BeAufteilingsschluesselWertMitBezugInput
    ) {
      data {
        ...BestandseinheitAufteilungsschluesselWertFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BestandseinheitAufteilungsschluesselWertFieldsFragmentDoc}
`;
export function useCreateBestandseinheitAufteilungsschluesselWertMitBezugMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBestandseinheitAufteilungsschluesselWertMitBezugMutation,
    CreateBestandseinheitAufteilungsschluesselWertMitBezugMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBestandseinheitAufteilungsschluesselWertMitBezugMutation,
    CreateBestandseinheitAufteilungsschluesselWertMitBezugMutationVariables
  >(CreateBestandseinheitAufteilungsschluesselWertMitBezugDocument, options);
}
export type CreateBestandseinheitAufteilungsschluesselWertMitBezugMutationHookResult = ReturnType<
  typeof useCreateBestandseinheitAufteilungsschluesselWertMitBezugMutation
>;
export type CreateBestandseinheitAufteilungsschluesselWertMitBezugMutationResult =
  Apollo.MutationResult<CreateBestandseinheitAufteilungsschluesselWertMitBezugMutation>;
export type CreateBestandseinheitAufteilungsschluesselWertMitBezugMutationOptions = Apollo.BaseMutationOptions<
  CreateBestandseinheitAufteilungsschluesselWertMitBezugMutation,
  CreateBestandseinheitAufteilungsschluesselWertMitBezugMutationVariables
>;
export const CreateBestandseinheitAufteilungsschluesselWertOhneBezugDocument = gql`
  mutation CreateBestandseinheitAufteilungsschluesselWertOhneBezug(
    $objektId: ID!
    $bestandseinheitId: ID!
    $aufteilungsschluesselId: ID!
    $BeAufteilingsschluesselWertOhneBezugInput: BeAufteilingsschluesselWertOhneBezugInput!
  ) {
    createBeAufteilungsschluesselWertOhneBezug(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      aufteilungsschluesselId: $aufteilungsschluesselId
      input: $BeAufteilingsschluesselWertOhneBezugInput
    ) {
      data {
        ...BestandseinheitAufteilungsschluesselWertFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BestandseinheitAufteilungsschluesselWertFieldsFragmentDoc}
`;
export function useCreateBestandseinheitAufteilungsschluesselWertOhneBezugMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBestandseinheitAufteilungsschluesselWertOhneBezugMutation,
    CreateBestandseinheitAufteilungsschluesselWertOhneBezugMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBestandseinheitAufteilungsschluesselWertOhneBezugMutation,
    CreateBestandseinheitAufteilungsschluesselWertOhneBezugMutationVariables
  >(CreateBestandseinheitAufteilungsschluesselWertOhneBezugDocument, options);
}
export type CreateBestandseinheitAufteilungsschluesselWertOhneBezugMutationHookResult = ReturnType<
  typeof useCreateBestandseinheitAufteilungsschluesselWertOhneBezugMutation
>;
export type CreateBestandseinheitAufteilungsschluesselWertOhneBezugMutationResult =
  Apollo.MutationResult<CreateBestandseinheitAufteilungsschluesselWertOhneBezugMutation>;
export type CreateBestandseinheitAufteilungsschluesselWertOhneBezugMutationOptions = Apollo.BaseMutationOptions<
  CreateBestandseinheitAufteilungsschluesselWertOhneBezugMutation,
  CreateBestandseinheitAufteilungsschluesselWertOhneBezugMutationVariables
>;
export const UpdateBestandseinheitAufteilungsschluesselWertMitBezugDocument = gql`
  mutation UpdateBestandseinheitAufteilungsschluesselWertMitBezug(
    $objektId: ID!
    $bestandseinheitId: ID!
    $aufteilungsschluesselId: ID!
    $beAufteilungsschluesselWertId: ID!
    $BeAufteilingsschluesselWertMitBezugInput: BeAufteilingsschluesselWertMitBezugInput!
  ) {
    updateBeAufteilungsschluesselWertMitBezug(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      aufteilungsschluesselId: $aufteilungsschluesselId
      beAufteilungsschluesselWertId: $beAufteilungsschluesselWertId
      input: $BeAufteilingsschluesselWertMitBezugInput
    ) {
      data {
        ...BestandseinheitAufteilungsschluesselWertFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BestandseinheitAufteilungsschluesselWertFieldsFragmentDoc}
`;
export function useUpdateBestandseinheitAufteilungsschluesselWertMitBezugMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBestandseinheitAufteilungsschluesselWertMitBezugMutation,
    UpdateBestandseinheitAufteilungsschluesselWertMitBezugMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBestandseinheitAufteilungsschluesselWertMitBezugMutation,
    UpdateBestandseinheitAufteilungsschluesselWertMitBezugMutationVariables
  >(UpdateBestandseinheitAufteilungsschluesselWertMitBezugDocument, options);
}
export type UpdateBestandseinheitAufteilungsschluesselWertMitBezugMutationHookResult = ReturnType<
  typeof useUpdateBestandseinheitAufteilungsschluesselWertMitBezugMutation
>;
export type UpdateBestandseinheitAufteilungsschluesselWertMitBezugMutationResult =
  Apollo.MutationResult<UpdateBestandseinheitAufteilungsschluesselWertMitBezugMutation>;
export type UpdateBestandseinheitAufteilungsschluesselWertMitBezugMutationOptions = Apollo.BaseMutationOptions<
  UpdateBestandseinheitAufteilungsschluesselWertMitBezugMutation,
  UpdateBestandseinheitAufteilungsschluesselWertMitBezugMutationVariables
>;
export const UpdateBestandseinheitAufteilungsschluesselWertOhneBezugDocument = gql`
  mutation UpdateBestandseinheitAufteilungsschluesselWertOhneBezug(
    $objektId: ID!
    $bestandseinheitId: ID!
    $aufteilungsschluesselId: ID!
    $beAufteilungsschluesselWertId: ID!
    $BeAufteilingsschluesselWertOhneBezugInput: BeAufteilingsschluesselWertOhneBezugInput!
  ) {
    updateBeAufteilungsschluesselWertOhneBezug(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      aufteilungsschluesselId: $aufteilungsschluesselId
      beAufteilungsschluesselWertId: $beAufteilungsschluesselWertId
      input: $BeAufteilingsschluesselWertOhneBezugInput
    ) {
      data {
        ...BestandseinheitAufteilungsschluesselWertFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BestandseinheitAufteilungsschluesselWertFieldsFragmentDoc}
`;
export function useUpdateBestandseinheitAufteilungsschluesselWertOhneBezugMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBestandseinheitAufteilungsschluesselWertOhneBezugMutation,
    UpdateBestandseinheitAufteilungsschluesselWertOhneBezugMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBestandseinheitAufteilungsschluesselWertOhneBezugMutation,
    UpdateBestandseinheitAufteilungsschluesselWertOhneBezugMutationVariables
  >(UpdateBestandseinheitAufteilungsschluesselWertOhneBezugDocument, options);
}
export type UpdateBestandseinheitAufteilungsschluesselWertOhneBezugMutationHookResult = ReturnType<
  typeof useUpdateBestandseinheitAufteilungsschluesselWertOhneBezugMutation
>;
export type UpdateBestandseinheitAufteilungsschluesselWertOhneBezugMutationResult =
  Apollo.MutationResult<UpdateBestandseinheitAufteilungsschluesselWertOhneBezugMutation>;
export type UpdateBestandseinheitAufteilungsschluesselWertOhneBezugMutationOptions = Apollo.BaseMutationOptions<
  UpdateBestandseinheitAufteilungsschluesselWertOhneBezugMutation,
  UpdateBestandseinheitAufteilungsschluesselWertOhneBezugMutationVariables
>;
export const DeleteBestandseinheitAufteilungsschluesselWertDocument = gql`
  mutation DeleteBestandseinheitAufteilungsschluesselWert(
    $objektId: ID!
    $bestandseinheitId: ID!
    $aufteilungsschluesselId: ID!
    $beAufteilungsschluesselWertId: ID!
  ) {
    deleteBeAufteilungsschluesselWert(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      aufteilungsschluesselId: $aufteilungsschluesselId
      beAufteilungsschluesselWertId: $beAufteilungsschluesselWertId
    ) {
      data {
        beAufteilungsschluesselWertId
        geloescht
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteBestandseinheitAufteilungsschluesselWertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBestandseinheitAufteilungsschluesselWertMutation,
    DeleteBestandseinheitAufteilungsschluesselWertMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBestandseinheitAufteilungsschluesselWertMutation, DeleteBestandseinheitAufteilungsschluesselWertMutationVariables>(
    DeleteBestandseinheitAufteilungsschluesselWertDocument,
    options
  );
}
export type DeleteBestandseinheitAufteilungsschluesselWertMutationHookResult = ReturnType<
  typeof useDeleteBestandseinheitAufteilungsschluesselWertMutation
>;
export type DeleteBestandseinheitAufteilungsschluesselWertMutationResult =
  Apollo.MutationResult<DeleteBestandseinheitAufteilungsschluesselWertMutation>;
export type DeleteBestandseinheitAufteilungsschluesselWertMutationOptions = Apollo.BaseMutationOptions<
  DeleteBestandseinheitAufteilungsschluesselWertMutation,
  DeleteBestandseinheitAufteilungsschluesselWertMutationVariables
>;
