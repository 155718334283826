import React, { FC } from 'react';
import { List } from 'antd';
import ZuordnungKontoKlasseTitle from './ZuordnungKontoKlasseTitle';
import ZuordnungKontoListingItemViewAndEdit from '../ViewAndEdit/ZuordnungKontoListingItemViewAndEdit';
import ZuordnungKontoListingItemOnlyView from '../../Abrechnungsdefinition/Version/View/AccountAssignmentView/ZuordnungKontoListingItemOnlyView';
import { ZuordnungColumnMode, ZuordnungKontoKlasseType, ZuordnungViewModel } from './AbrKreisKontenzuordnungHelpers';
import { AbrechnungsdefinitionType } from '../../../types';
import { AbrKontenzuordnungFiltersFormValues } from '../Listing/Filters/abrKontenzuordnungFiltersMapper';

type Props = {
  kontenAbrechnungskreisList: ZuordnungViewModel[];
  zuordnungKontoKlasseType: ZuordnungKontoKlasseType;
  abrDefinitionType: AbrechnungsdefinitionType;
  isListOpen: boolean;
  keyForCollapse: string;
  mode: ZuordnungColumnMode;
  filters?: AbrKontenzuordnungFiltersFormValues | null;
  loading?: boolean;
};

const ZuordnungKontoListingViewOrEdit: FC<Props> = ({
  zuordnungKontoKlasseType,
  kontenAbrechnungskreisList,
  abrDefinitionType,
  isListOpen,
  mode,
  filters,
  loading = false,
}) => (
  <>
    <ZuordnungKontoKlasseTitle title={zuordnungKontoKlasseType} isTransparent />
    {isListOpen ? (
      <List
        rowKey={(item) => item.konto.kontoId}
        size="small"
        bordered={false}
        dataSource={kontenAbrechnungskreisList}
        renderItem={(item) =>
          mode === 'onlyView' ? (
            <ZuordnungKontoListingItemOnlyView
              zuordnung={item}
              kontoKlasseType={zuordnungKontoKlasseType}
              abrDefinitionType={abrDefinitionType}
              loading={loading}
            />
          ) : (
            <ZuordnungKontoListingItemViewAndEdit
              zuordnung={item}
              kontoKlasseType={zuordnungKontoKlasseType}
              abrDefinitionType={abrDefinitionType}
              filters={filters}
              loading={loading}
            />
          )
        }
      />
    ) : null}
  </>
);

export default ZuordnungKontoListingViewOrEdit;
