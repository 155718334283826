import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { useFirmendatenIndexSeriesListQuery } from '../../../../features/Firmendaten/IndexSeries/gql/FirmendatenIndexSeriesQueries.types';
import IndexSeriesListing from '../../../../features/Firmendaten/IndexSeries/Listing/IndexSeriesListing';
import { useToggle } from '../../../../hooks/useToggle';

const FirmendatenIndexSeriesPage: FC = () => {
  const [showArchived, toggleShowArchived] = useToggle();
  const { data, refetch } = useFirmendatenIndexSeriesListQuery({
    variables: {
      includeArchiviert: showArchived,
    },
  });

  const indexSeriesList = data?.getFirmendatenIndexSeriesList.data;

  if (!indexSeriesList) return <Skeleton active />;

  return (
    <IndexSeriesListing indexSeriesList={indexSeriesList} onAction={refetch} showArchived={showArchived} toggleShowArchived={toggleShowArchived} />
  );
};

export default FirmendatenIndexSeriesPage;
