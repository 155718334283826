import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../styles/pdfStyles';
import { ZinslisteBestandseinheitVertrag } from '../../zinsliste-types';

type Props = {
  beVertrag: ZinslisteBestandseinheitVertrag;
};

const ZBeVertragInformation: FC<Props> = ({ beVertrag }) => (
  <View style={[pdfStyles.row]}>
    <Text style={pdfStyles.textNormal}>{beVertrag.bestandseinheitBezeichnung}</Text>
    <View style={[pdfStyles.column, { flexDirection: 'row' }]}>
      <Text style={pdfStyles.textNormal}>{beVertrag.vertragsart}</Text>
      <Text style={[pdfStyles.textNormal, { marginLeft: '2mm' }]}>{beVertrag.termLimitation}</Text>
    </View>
    <Text style={pdfStyles.textNormal}>{beVertrag.nutzungsArt}</Text>
    <Text style={pdfStyles.textNormal}>
      {beVertrag.nutzflaecheText}: {beVertrag.nutzflaeche}
    </Text>
    <Text style={pdfStyles.textNormal}>{beVertrag.nutzwert ? `${beVertrag.nutzwertText}: ${beVertrag.nutzwert}` : ''}</Text>
  </View>
);

export default ZBeVertragInformation;
