import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Tabs, TabsProps } from 'antd';
import { ObjektInfoFeldAuflage, ObjektInfoFeldType } from '../../../../../../types';
import FormButtons from '../../../../../../components/Button/FormButtons';
import { useFDAuthorized } from '../../../../../../shared/Auth/useAuthorized';
import AuflageDatenTab from '../Tabs/AuflageDatenTab';
import { auflageUpdateFormFields, AuflageUpdateFormValues, mapToUpdateFormValues } from './auflageUpdateFormMapper';
import InfoFeldDocumentTab from '../../../../../shared/InfoFeld/Tabs/InfoFeldDocumentTab';
import { handleUpdateSubmit } from '../../shared/handleSubmit';
import AuflageFormErrors from '../shared/AuflageFormErrors';
import { validateAuflageUpdateForm } from './auflageUpdateFormValidation';
import InfoFeldUpload from '../../../../../shared/InfoFeld/InfoFeldUpload';
import FormButtonClose from '../../../../../../components/Button/FormButtonClose';
import ObjektInfoFeldTicketListing from '../Ticket/ObjektInfoFeldTicketListing';
import { InfoFeldUpdateFormTabKey } from '../../../../../InfoFeld/infoFeldTypes';

type Props = {
  objektId: string;
  onCancel: () => void;
  onInfoFeldAction: () => void;
  onTicketAction: () => void;
  infoFeld: ObjektInfoFeldAuflage;
  activeKeyTab: InfoFeldUpdateFormTabKey;
};

const AuflageUpdateForm: FC<Props> = ({ objektId, onCancel, onInfoFeldAction, onTicketAction, infoFeld, activeKeyTab }) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  return (
    <Formik<AuflageUpdateFormValues>
      initialValues={mapToUpdateFormValues(infoFeld)}
      validate={validateAuflageUpdateForm}
      onSubmit={(values, { setSubmitting }) => {
        handleUpdateSubmit(firmendatenId, objektId, infoFeld.objektInfoFeldId, ObjektInfoFeldType.Auflage, values, setSubmitting, onInfoFeldAction);
      }}
    >
      {(formikProps) => {
        const items: TabsProps['items'] = [
          {
            key: InfoFeldUpdateFormTabKey.DATA,
            label: 'Daten',
            children: (
              <Form>
                <AuflageDatenTab formFields={auflageUpdateFormFields} />
                <InfoFeldUpload
                  name={auflageUpdateFormFields.documentList}
                  formikProps={formikProps}
                  documentList={formikProps.values.documentList}
                />
                <AuflageFormErrors formikProps={formikProps} />
                <FormButtons updateMode onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
              </Form>
            ),
          },
          {
            key: InfoFeldUpdateFormTabKey.TICKET,
            label: 'Aufgaben',
            children: (
              <>
                <ObjektInfoFeldTicketListing objektId={objektId} infoFeld={infoFeld} onSuccess={onTicketAction} />
                <FormButtonClose onCancel={onCancel} />
              </>
            ),
          },
          {
            key: InfoFeldUpdateFormTabKey.DOCUMENT,
            label: 'Dokumente',
            children: (
              <Form>
                <InfoFeldDocumentTab
                  infoFeld={infoFeld}
                  deleteDocumentFileIdList={formikProps.values.deleteDocumentFileIdList}
                  onDocumentDelete={(value) => formikProps.setFieldValue(auflageUpdateFormFields.deleteDocumentFileIdList, value)}
                  firmendatenId={firmendatenId}
                />
                <InfoFeldUpload
                  name={auflageUpdateFormFields.documentList}
                  formikProps={formikProps}
                  documentList={formikProps.values.documentList}
                />
                <AuflageFormErrors formikProps={formikProps} />
                <FormButtons updateMode onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
              </Form>
            ),
          },
        ];

        return <Tabs defaultActiveKey={activeKeyTab} style={{ minHeight: '300px' }} items={items} />;
      }}
    </Formik>
  );
};

export default AuflageUpdateForm;
