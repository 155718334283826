import React, { FC } from 'react';
import { Space, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import CardDetailsInfoRow from '../../components/Card/CardDetailsInfoRow';
import DataWithShortenedText from '../../components/Helpers/DataWithShortenedText';
import TextForEmptyValue from '../../components/Text/TextForEmptyValue';

type Props = {
  consolidationCriteria?: string | null;
};

const ConsolidationCriteriaCardDetailsInfoRow: FC<Props> = ({ consolidationCriteria }) => (
  <CardDetailsInfoRow
    infoRowTitle={
      consolidationCriteria ? (
        'Zusammenfassungskriterium'
      ) : (
        <Space>
          <DataWithShortenedText maxTextLength={15} text="Zusammenfassungskriterium">
            {(shortenedText) => <Typography.Text type="secondary">{shortenedText}</Typography.Text>}
          </DataWithShortenedText>
          <Tooltip title={CONSOLIDATION_CRITERIA_TEXT}>
            <InfoCircleOutlined />
          </Tooltip>
        </Space>
      )
    }
  >
    {consolidationCriteria ? (
      <DataWithShortenedText maxTextLength={20} text={consolidationCriteria}>
        {(shortenedText) => shortenedText}
      </DataWithShortenedText>
    ) : (
      <TextForEmptyValue textToShow="minus" />
    )}
  </CardDetailsInfoRow>
);

const CONSOLIDATION_CRITERIA_TEXT = 'Wenn kein Zusammenfassungskriterium angegeben wird, wird die Vorschreibung immer wenn möglich zusammengefasst.';

export default ConsolidationCriteriaCardDetailsInfoRow;
