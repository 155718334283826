import { FormFields } from '../../../../../helpers/formikHelper';
import { TPaymentQueryParams } from '../../../Listing/Filters/filtersQueryParams';
import { EmployeeApproval, PaymentProposalCreationGenerierlaufCreateInput } from '../../../../../types';
import { isPaymentEmployeeApprovalAll } from '../../../paymentHelpers';

export interface PaymentProposalCreateFormValues {
  ebicsOnly?: boolean;
  paymentProposalName?: string | null;
}

export const paymentProposalInitialValues: PaymentProposalCreateFormValues = {
  ebicsOnly: undefined,
  paymentProposalName: '',
};

export const paymentProposalFormFields: FormFields<PaymentProposalCreateFormValues> = {
  ebicsOnly: 'ebicsOnly',
  paymentProposalName: 'paymentProposalName',
};

export const mapQueryParamsToPaymentProposalCreationGenerierlauf = (
  formValues: PaymentProposalCreateFormValues,
  queryParams: TPaymentQueryParams,
  excludeList: string[]
): PaymentProposalCreationGenerierlaufCreateInput => {
  // paymentProposalId and approvedByMitarbeiterIdList do not exist in the input object, hence we need to remove it from the query params
  const { paymentProposalId, approvedByMitarbeiterIdList, ...restQueryParams } = queryParams;

  return {
    ebicsOnly: formValues.ebicsOnly,
    paymentProposalName: formValues.paymentProposalName,
    ...restQueryParams,
    belegnummer: queryParams.belegnummer?.toString(),
    employeeApproval:
      isPaymentEmployeeApprovalAll(queryParams.employeeApproval) || !queryParams.employeeApproval
        ? undefined
        : (queryParams.employeeApproval as EmployeeApproval),
    excludeList,
  };
};
