import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBudgetingVPosAuftschluesselLinkageFirmendatenMutationVariables = Types.Exact<{
  firmenDatenId: Types.Scalars['ID']['input'];
  input: Types.BudgetingVPosAuftschluesselLinkageCreateInput;
}>;

export type CreateBudgetingVPosAuftschluesselLinkageFirmendatenMutation = {
  createBudgetingVPosAuftschluesselLinkageFirmendaten: {
    data: { budgetingVPosAuftschluesselLinkageId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type CreateBudgetingVPosAuftschluesselLinkageKundenSystemMutationVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
  input: Types.BudgetingVPosAuftschluesselLinkageCreateInput;
}>;

export type CreateBudgetingVPosAuftschluesselLinkageKundenSystemMutation = {
  createBudgetingVPosAuftschluesselLinkageKundenSystem: {
    data: { budgetingVPosAuftschluesselLinkageId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UpdateBudgetingVPosAuftschluesselLinkageMutationVariables = Types.Exact<{
  budgetingVPosAuftschluesselLinkageId: Types.Scalars['ID']['input'];
  input: Types.BudgetingVPosAuftschluesselLinkageUpdateInput;
}>;

export type UpdateBudgetingVPosAuftschluesselLinkageMutation = {
  updateBudgetingVPosAuftschluesselLinkage: {
    data: { budgetingVPosAuftschluesselLinkageId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type DeleteBudgetingVPosAuftschluesselLinkageMutationVariables = Types.Exact<{
  budgetingVPosAuftschluesselLinkageId: Types.Scalars['ID']['input'];
}>;

export type DeleteBudgetingVPosAuftschluesselLinkageMutation = {
  deleteBudgetingVPosAuftschluesselLinkage: {
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const CreateBudgetingVPosAuftschluesselLinkageFirmendatenDocument = gql`
  mutation CreateBudgetingVPosAuftschluesselLinkageFirmendaten($firmenDatenId: ID!, $input: BudgetingVPosAuftschluesselLinkageCreateInput!) {
    createBudgetingVPosAuftschluesselLinkageFirmendaten(firmendatenId: $firmenDatenId, input: $input) {
      data {
        budgetingVPosAuftschluesselLinkageId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreateBudgetingVPosAuftschluesselLinkageFirmendatenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBudgetingVPosAuftschluesselLinkageFirmendatenMutation,
    CreateBudgetingVPosAuftschluesselLinkageFirmendatenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBudgetingVPosAuftschluesselLinkageFirmendatenMutation,
    CreateBudgetingVPosAuftschluesselLinkageFirmendatenMutationVariables
  >(CreateBudgetingVPosAuftschluesselLinkageFirmendatenDocument, options);
}
export type CreateBudgetingVPosAuftschluesselLinkageFirmendatenMutationHookResult = ReturnType<
  typeof useCreateBudgetingVPosAuftschluesselLinkageFirmendatenMutation
>;
export type CreateBudgetingVPosAuftschluesselLinkageFirmendatenMutationResult =
  Apollo.MutationResult<CreateBudgetingVPosAuftschluesselLinkageFirmendatenMutation>;
export type CreateBudgetingVPosAuftschluesselLinkageFirmendatenMutationOptions = Apollo.BaseMutationOptions<
  CreateBudgetingVPosAuftschluesselLinkageFirmendatenMutation,
  CreateBudgetingVPosAuftschluesselLinkageFirmendatenMutationVariables
>;
export const CreateBudgetingVPosAuftschluesselLinkageKundenSystemDocument = gql`
  mutation CreateBudgetingVPosAuftschluesselLinkageKundenSystem($kundenSystemId: ID!, $input: BudgetingVPosAuftschluesselLinkageCreateInput!) {
    createBudgetingVPosAuftschluesselLinkageKundenSystem(kundenSystemId: $kundenSystemId, input: $input) {
      data {
        budgetingVPosAuftschluesselLinkageId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreateBudgetingVPosAuftschluesselLinkageKundenSystemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBudgetingVPosAuftschluesselLinkageKundenSystemMutation,
    CreateBudgetingVPosAuftschluesselLinkageKundenSystemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBudgetingVPosAuftschluesselLinkageKundenSystemMutation,
    CreateBudgetingVPosAuftschluesselLinkageKundenSystemMutationVariables
  >(CreateBudgetingVPosAuftschluesselLinkageKundenSystemDocument, options);
}
export type CreateBudgetingVPosAuftschluesselLinkageKundenSystemMutationHookResult = ReturnType<
  typeof useCreateBudgetingVPosAuftschluesselLinkageKundenSystemMutation
>;
export type CreateBudgetingVPosAuftschluesselLinkageKundenSystemMutationResult =
  Apollo.MutationResult<CreateBudgetingVPosAuftschluesselLinkageKundenSystemMutation>;
export type CreateBudgetingVPosAuftschluesselLinkageKundenSystemMutationOptions = Apollo.BaseMutationOptions<
  CreateBudgetingVPosAuftschluesselLinkageKundenSystemMutation,
  CreateBudgetingVPosAuftschluesselLinkageKundenSystemMutationVariables
>;
export const UpdateBudgetingVPosAuftschluesselLinkageDocument = gql`
  mutation UpdateBudgetingVPosAuftschluesselLinkage(
    $budgetingVPosAuftschluesselLinkageId: ID!
    $input: BudgetingVPosAuftschluesselLinkageUpdateInput!
  ) {
    updateBudgetingVPosAuftschluesselLinkage(budgetingVPosAuftschluesselLinkageId: $budgetingVPosAuftschluesselLinkageId, input: $input) {
      data {
        budgetingVPosAuftschluesselLinkageId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUpdateBudgetingVPosAuftschluesselLinkageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBudgetingVPosAuftschluesselLinkageMutation,
    UpdateBudgetingVPosAuftschluesselLinkageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBudgetingVPosAuftschluesselLinkageMutation, UpdateBudgetingVPosAuftschluesselLinkageMutationVariables>(
    UpdateBudgetingVPosAuftschluesselLinkageDocument,
    options
  );
}
export type UpdateBudgetingVPosAuftschluesselLinkageMutationHookResult = ReturnType<typeof useUpdateBudgetingVPosAuftschluesselLinkageMutation>;
export type UpdateBudgetingVPosAuftschluesselLinkageMutationResult = Apollo.MutationResult<UpdateBudgetingVPosAuftschluesselLinkageMutation>;
export type UpdateBudgetingVPosAuftschluesselLinkageMutationOptions = Apollo.BaseMutationOptions<
  UpdateBudgetingVPosAuftschluesselLinkageMutation,
  UpdateBudgetingVPosAuftschluesselLinkageMutationVariables
>;
export const DeleteBudgetingVPosAuftschluesselLinkageDocument = gql`
  mutation DeleteBudgetingVPosAuftschluesselLinkage($budgetingVPosAuftschluesselLinkageId: ID!) {
    deleteBudgetingVPosAuftschluesselLinkage(budgetingVPosAuftschluesselLinkageId: $budgetingVPosAuftschluesselLinkageId) {
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeleteBudgetingVPosAuftschluesselLinkageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBudgetingVPosAuftschluesselLinkageMutation,
    DeleteBudgetingVPosAuftschluesselLinkageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBudgetingVPosAuftschluesselLinkageMutation, DeleteBudgetingVPosAuftschluesselLinkageMutationVariables>(
    DeleteBudgetingVPosAuftschluesselLinkageDocument,
    options
  );
}
export type DeleteBudgetingVPosAuftschluesselLinkageMutationHookResult = ReturnType<typeof useDeleteBudgetingVPosAuftschluesselLinkageMutation>;
export type DeleteBudgetingVPosAuftschluesselLinkageMutationResult = Apollo.MutationResult<DeleteBudgetingVPosAuftschluesselLinkageMutation>;
export type DeleteBudgetingVPosAuftschluesselLinkageMutationOptions = Apollo.BaseMutationOptions<
  DeleteBudgetingVPosAuftschluesselLinkageMutation,
  DeleteBudgetingVPosAuftschluesselLinkageMutationVariables
>;
