import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { BankOutlined } from '@ant-design/icons';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import { OrderBankDetails } from '../../../../types';
import BankDetailsCardDetailsInfoRow from '../../../BankDetails/shared/BankDetailsCardDetailsInfoRow';

type Props = {
  bankDetails?: OrderBankDetails;
};

const RechtstraegerBankDetails: FC<Props> = ({ bankDetails }) =>
  bankDetails ? (
    <BankDetailsCardDetailsInfoRow bankDetails={bankDetails} />
  ) : (
    <CardDetailsInfoRow
      infoRowTitle={
        <Space>
          <BankOutlined />
          <Typography.Text type="secondary">-</Typography.Text>
        </Space>
      }
    >
      -
    </CardDetailsInfoRow>
  );

export default RechtstraegerBankDetails;
