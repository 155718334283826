import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AuftragListEntryFragmentDoc, AuftragFragmentDoc } from './AuftragFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuftragListQueryVariables = Types.Exact<{
  auftragsPositionLabel?: Types.InputMaybe<Types.Scalars['String']['input']>;
  auftragsPositionLabelValue?: Types.InputMaybe<Types.Scalars['String']['input']>;
  auftragsartType?: Types.InputMaybe<Types.AuftragsartType>;
  ausstellerBankDetailsId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  ausstellerRechtstraegerId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  ausstellerIban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  belegform?: Types.InputMaybe<Types.Scalars['String']['input']>;
  belegnummer?: Types.InputMaybe<Types.Scalars['String']['input']>;
  bestandseinheitId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  bruttoFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  bruttoTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  createTsFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dueDateVorschreibung?: Types.InputMaybe<Types.Scalars['String']['input']>;
  empfaengerBankDetailsId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  empfaengerBankDetailsIdIsNull?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  empfaengerRechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  empfaengerIban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  erstellerMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  fakturierungsperiodeDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  fakturierungsperiodeDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  fibuStatus?: Types.InputMaybe<Types.AuftragFibuStatus>;
  generierlaufId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  includeStorniert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeStorno?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  nettoFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  nettoTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  objektId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  order?: Types.InputMaybe<Types.AuftragOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  paymentMethod?: Types.InputMaybe<Types.Scalars['String']['input']>;
  status?: Types.InputMaybe<Types.AuftragStatus>;
  ustFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  ustTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  verrechnungsart?: Types.InputMaybe<Types.Scalars['String']['input']>;
  vertragId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vorschreibungspositionId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sepaCreditorId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sepaMandateReference?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buchungsanweisungStatus?: Types.InputMaybe<Types.OrderBuchungsanweisungStatus>;
  kundennummer?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechnungsDatumFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechnungsDatumTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dueDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dueDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  vertragsart?: Types.InputMaybe<Types.Vertragsart>;
}>;

export type AuftragListQuery = {
  getAuftragList: {
    data: {
      contentList: Array<{
        auftragId: string;
        ausstellerRechtstraegerId: string;
        belegFileId?: string | null;
        belegId?: string | null;
        belegnummer?: string | null;
        bestandseinheitWithObjektBezeichnungList: Array<string>;
        buchungskreisId?: string | null;
        buchungIdList?: Array<string> | null;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        dueDate?: string | null;
        empfaengerRechtstraegerId: string;
        generierlaufId?: string | null;
        offenerBetrag?: number | null;
        paymentCreatable: boolean;
        rechnungsDatum?: string | null;
        sepaCreditorId?: string | null;
        sepaMandatReference?: string | null;
        storniert: boolean;
        sumBrutto: number;
        sumNetto: number;
        sumUst: number;
        auftragsart: {
          auftragsartId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          showUstBlock: boolean;
          updatedByMitarbeiterId?: string | null;
          status: { text: string; value: Types.AuftragsartStatus };
          type: { text: string; value: Types.AuftragsartType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        aussteller: {
          kurzBezeichnung: string;
          kundennummer: string;
          rechtstraegerId: string;
          type: { text: string; value: Types.RechtstraegerType };
        };
        ausstellerBankDetails: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string };
        buchungsanweisungStatus?: { text: string; value: Types.OrderBuchungsanweisungStatus } | null;
        empfaenger: {
          kurzBezeichnung: string;
          kundennummer: string;
          rechtstraegerId: string;
          type: { text: string; value: Types.RechtstraegerType };
        };
        empfaengerBankDetails?: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string } | null;
        fakturierungsperiode: { jahr: number; monat: number };
        objektBestandseinheitIdDataList: Array<{ bestandseinheitId: string; objektId: string }>;
        paymentMethod: { text: string; value: Types.PaymentMethod };
        status: { text: string; value: Types.AuftragStatus; description?: string | null };
        vertragsart: { text: string; value: Types.Vertragsart };
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AuftragListSummaryQueryVariables = Types.Exact<{
  auftragsPositionLabel?: Types.InputMaybe<Types.Scalars['String']['input']>;
  auftragsPositionLabelValue?: Types.InputMaybe<Types.Scalars['String']['input']>;
  auftragsartType?: Types.InputMaybe<Types.AuftragsartType>;
  ausstellerBankDetailsId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  ausstellerRechtstraegerId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  ausstellerIban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  belegform?: Types.InputMaybe<Types.Scalars['String']['input']>;
  belegnummer?: Types.InputMaybe<Types.Scalars['String']['input']>;
  bestandseinheitId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  bruttoFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  bruttoTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  createTsFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dueDateVorschreibung?: Types.InputMaybe<Types.Scalars['String']['input']>;
  empfaengerBankDetailsId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  empfaengerBankDetailsIdIsNull?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  empfaengerRechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  empfaengerIban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  erstellerMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  fakturierungsperiodeDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  fakturierungsperiodeDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  fibuStatus?: Types.InputMaybe<Types.AuftragFibuStatus>;
  generierlaufId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  includeStorniert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeStorno?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  nettoFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  nettoTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  objektId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  paymentMethod?: Types.InputMaybe<Types.Scalars['String']['input']>;
  status?: Types.InputMaybe<Types.AuftragStatus>;
  ustFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  ustTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  verrechnungsart?: Types.InputMaybe<Types.Scalars['String']['input']>;
  vertragId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vorschreibungspositionId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sepaCreditorId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sepaMandateReference?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buchungsanweisungStatus?: Types.InputMaybe<Types.OrderBuchungsanweisungStatus>;
  kundennummer?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechnungsDatumFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechnungsDatumTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dueDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dueDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  vertragsart?: Types.InputMaybe<Types.Vertragsart>;
}>;

export type AuftragListSummaryQuery = {
  getAuftragListSummary: {
    data: { sumBrutto: number; sumNetto: number; sumUst: number };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AuftragQueryVariables = Types.Exact<{
  auftragId: Types.Scalars['ID']['input'];
}>;

export type AuftragQuery = {
  getAuftrag: {
    data: {
      auftragId: string;
      buchungIdList?: Array<string> | null;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      dueDate: string;
      generierDatum?: string | null;
      generierlaufId?: string | null;
      hasAuftragskette: boolean;
      stornierbar?: boolean | null;
      storniert: boolean;
      sumBrutto: number;
      sumNetto: number;
      sumUst: number;
      stornoAuftrag?: {
        auftragId: string;
        buchungIdList?: Array<string> | null;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        dueDate: string;
        generierDatum?: string | null;
        generierlaufId?: string | null;
        hasAuftragskette: boolean;
        stornierbar?: boolean | null;
        storniert: boolean;
        sumBrutto: number;
        sumNetto: number;
        sumUst: number;
        auftragsKopf: {
          consolidationCriteria?: string | null;
          rechnungsAusstellerBezeichnung: string;
          sepaCreditorId?: string | null;
          sepaMandatReference?: string | null;
          auftragsartType: { text: string; value: Types.AuftragsartType };
          aussteller: {
            kurzBezeichnung: string;
            kundennummer: string;
            rechtstraegerId: string;
            type: { text: string; value: Types.RechtstraegerType };
          };
          ausstellerBankDetails: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string };
          belegform: { text: string; value: Types.BelegFormArt };
          deliveryAddress: {
            addressId: string;
            city: string;
            cityAdditionalInformation?: string | null;
            countryCodeIso2: string;
            houseEntranceApartmentNumber?: string | null;
            postofficeBoxNumber?: string | null;
            postofficeBoxZipCode?: string | null;
            street?: string | null;
            type: Types.AddressType;
            zipCode?: string | null;
          };
          dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
          empfaenger: {
            kurzBezeichnung: string;
            kundennummer: string;
            rechtstraegerId: string;
            type: { text: string; value: Types.RechtstraegerType };
          };
          empfaengerBankDetails?: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string } | null;
          fakturierungsperiode: { jahr: number; monat: number };
          objekt: { kurzBezeichnung: string; objektId: string };
          paymentMethod: { text: string; value: Types.PaymentMethod };
          recipientEmailContact: { contactId: string; type: string; wert: string };
          verrechnungsart: { text: string; value: Types.Verrechnungsart };
          vertragsart: { text: string; value: Types.Vertragsart };
          zustellRechtstraeger: {
            kurzBezeichnung: string;
            kundennummer: string;
            rechtstraegerId: string;
            type: { text: string; value: Types.RechtstraegerType };
          };
        };
        auftragsPositionList: Array<{
          auftragId: string;
          auftragsPositionId: string;
          bestandseinheitId: string;
          bezeichnung: string;
          brutto: number;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          generierDatum?: string | null;
          generierlaufId?: string | null;
          netto: number;
          objektId: string;
          originId: string;
          performancePeriodFromInclusive: string;
          performancePeriodToInclusive: string;
          rechnungskennzeichen: string;
          steuersatz: number;
          ust: number;
          ustKategorieEntryId: string;
          ustKategorieId: string;
          ustKategorieVersionId: string;
          vertragBezeichnung: string;
          vertragId: string;
          vorschreibungspositionBezeichnung: string;
          vorschreibungspositionId: string;
          bestandseinheit: {
            aktivSeit?: string | null;
            bauteil?: string | null;
            bestandseinheitId: string;
            bezeichnung: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            geschoss?: string | null;
            inaktivSeit?: string | null;
            nutzflaeche?: number | null;
            nutzwert?: number | null;
            stiege?: string | null;
            tagList: Array<string>;
            address: {
              addressId: string;
              city: string;
              cityAdditionalInformation?: string | null;
              countryCodeIso2: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              haupt?: boolean | null;
              houseEntranceApartmentNumber?: string | null;
              postofficeBoxNumber?: string | null;
              postofficeBoxZipCode?: string | null;
              street?: string | null;
              tagList: Array<string>;
              type: Types.AddressType;
              zipCode?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            nutzungsArt?: { text: string; value: Types.NutzungsArt } | null;
            optionSteuerpflicht?: {
              beOptionSteuerpflichtId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              optionSteuerpflicht: boolean;
              validFrom: string;
              historicizedList?: Array<{
                beOptionSteuerpflichtId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                deletable?: boolean | null;
                kommentar?: string | null;
                lastUpdateTs: string;
                optionSteuerpflicht: boolean;
                validFrom: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }> | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            objektVerwaltungsart: { text: string; value: Types.Verwaltungsart };
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          fibuStatus?: { text: string; value: Types.AuftragFibuStatus; description?: string | null } | null;
          labelList: Array<{ label: string; value: string }>;
          objekt: {
            countryCodeIso2: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fotoFileId?: string | null;
            fotoType?: Types.FotoType | null;
            kurzBezeichnung: string;
            objektId: string;
            rechnungsAusstellerBezeichnung?: string | null;
            rechnungsAusstellerChangeable?: boolean | null;
            subAdministration: boolean;
            sumNutzflaeche: number;
            sumNutzwert: number;
            tagList: Array<string>;
            updatedByMitarbeiterId?: string | null;
            verwaltungBis?: string | null;
            verwaltungSeit: string;
            verwaltungsartChangeable?: boolean | null;
            addressList: Array<{
              addressId: string;
              city: string;
              cityAdditionalInformation?: string | null;
              countryCodeIso2: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              haupt?: boolean | null;
              houseEntranceApartmentNumber?: string | null;
              postofficeBoxNumber?: string | null;
              postofficeBoxZipCode?: string | null;
              street?: string | null;
              tagList: Array<string>;
              type: Types.AddressType;
              zipCode?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            objektSummeAnteile?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              kommentar?: string | null;
              lastUpdateTs: string;
              objektSummeAnteileId: string;
              summeAnteile: number;
              validFrom: string;
              historicizedList?: Array<{
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                kommentar?: string | null;
                lastUpdateTs: string;
                objektSummeAnteileId: string;
                summeAnteile: number;
                validFrom: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }> | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechnungsAussteller: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fibuKontoCreatable: boolean;
              kundennummer: string;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              tagList: Array<string>;
              updatedByMitarbeiterId?: string | null;
              bankDetailsList: Array<{
                accountHolder: string;
                accountLimit?: number | null;
                assignedHeVertragZahlungsregelMap: any;
                assignedObjektMap: any;
                bankDetailsId: string;
                bankname: string;
                bic: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                ebicsCreditTransferUserList: Array<string>;
                ebicsDirectDebitUserList: Array<string>;
                fibuKontoId?: string | null;
                fibuKontonummer?: string | null;
                iban: string;
                updatedByMitarbeiterId?: string | null;
                fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
                fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
                sepaMandat?: {
                  fileId?: string | null;
                  fileName?: string | null;
                  sepaMandatId: string;
                  sepaMandatReference: string;
                  signatureDate?: string | null;
                  firmendaten?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    firmendatenId: string;
                    kundenSystemId: string;
                    kurzBezeichnung: string;
                    name1: string;
                    name2?: string | null;
                    name3?: string | null;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                  rechnungsAussteller?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                  status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
                  warningList: Array<{ message: string; type: string }>;
                } | null;
                status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              personenbezugList: Array<{
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                personenbezugId: string;
                tagList: Array<string>;
                person: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rechtstraegerSteuerpflicht?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                deletable?: boolean | null;
                kommentar?: string | null;
                lastUpdateTs: string;
                rechtstraegerSteuerpflichtId: string;
                validFrom: string;
                historicizedList?: Array<{
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  current: boolean;
                  deletable?: boolean | null;
                  kommentar?: string | null;
                  lastUpdateTs: string;
                  rechtstraegerSteuerpflichtId: string;
                  validFrom: string;
                  steuerpflicht: { text: string; value: Types.Steuerpflicht };
                  warningList: Array<{ message: string; type: string }>;
                }> | null;
                steuerpflicht: { text: string; value: Types.Steuerpflicht };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
              sprache: { text: string; value: Types.Sprache };
              status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
              type: { value: Types.RechtstraegerType; text: string };
              vertragSummary?: {
                heVertragCount: number;
                heVertragVerwaltungCount: number;
                mietVertragCount: number;
                mietVertragVerwaltungCount: number;
                weSubverwaltungCount: number;
                weVertragCount: number;
                weVertragVerwaltungCount: number;
              } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            sachbearbeiter?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              fileStorageInitialized: boolean;
              firmendatenId: string;
              firstname: string;
              funktionsModulList: Array<string>;
              lastname: string;
              mitarbeiterId: string;
              title?: string | null;
              titleTrailing?: string | null;
              username: string;
              contactPerson?: {
                contactPersonId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                firstname: string;
                lastname: string;
                tagList: Array<string>;
                title?: string | null;
                titleTrailing?: string | null;
                contactList: Array<{
                  contactId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  haupt?: boolean | null;
                  tagList: Array<string>;
                  type: string;
                  wert: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                rechtstraeger?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rolleList: Array<{
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                name: string;
                rolleId: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
              status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
              user?: {
                email?: string | null;
                emailUnverified?: string | null;
                firstname?: string | null;
                lastname?: string | null;
                role: string;
                username: string;
                status: { text: string; value: string };
              } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.ObjektStatus; description?: string | null };
            verwaltungsart: { text: string; value: Types.Verwaltungsart };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
        }>;
        auftragsart: {
          auftragsartId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          showUstBlock: boolean;
          updatedByMitarbeiterId?: string | null;
          status: { text: string; value: Types.AuftragsartStatus };
          type: { text: string; value: Types.AuftragsartType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        billingAddress: {
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          type: Types.AddressType;
          zipCode?: string | null;
        };
        buchungErrorList: Array<{ message: string; type: string }>;
        copyEmailRecipientList?: Array<{
          contact: { contactId: string; type: string; wert: string };
          rechtstraeger: {
            kurzBezeichnung: string;
            kundennummer: string;
            rechtstraegerId: string;
            type: { text: string; value: Types.RechtstraegerType };
          };
        }> | null;
        copyMailDeliveryList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          emailAddress: string;
          emailDeliveryDefinitionBezeichnung: string;
          emailDeliveryDefinitionId: string;
          mailId?: string | null;
          original: boolean;
          sender?: string | null;
          sentTs?: string | null;
          shouldNeverSend: boolean;
          shouldSend: boolean;
          updatedByMitarbeiterId?: string | null;
          vorschreibungMailDeliveryId: string;
          resentDeliveryList?: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            emailAddress: string;
            emailDeliveryDefinitionBezeichnung: string;
            emailDeliveryDefinitionId: string;
            mailId?: string | null;
            original: boolean;
            sender?: string | null;
            sentTs?: string | null;
            shouldNeverSend: boolean;
            shouldSend: boolean;
            updatedByMitarbeiterId?: string | null;
            vorschreibungMailDeliveryId: string;
            emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
            fakturierungsperiode: { jahr: number; monat: number };
            objektBestandseinheitVertragList: Array<{
              vertragId: string;
              bestandseinheit: {
                bestandseinheitId: string;
                bezeichnung: string;
                address: {
                  addressId: string;
                  city: string;
                  cityAdditionalInformation?: string | null;
                  countryCodeIso2: string;
                  houseEntranceApartmentNumber?: string | null;
                  postofficeBoxNumber?: string | null;
                  postofficeBoxZipCode?: string | null;
                  street?: string | null;
                  type: Types.AddressType;
                  zipCode?: string | null;
                };
              };
              objekt: { kurzBezeichnung: string; objektId: string };
            }>;
            recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
            vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }> | null;
          sourceDelivery?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            emailAddress: string;
            emailDeliveryDefinitionBezeichnung: string;
            emailDeliveryDefinitionId: string;
            mailId?: string | null;
            original: boolean;
            sender?: string | null;
            sentTs?: string | null;
            shouldNeverSend: boolean;
            shouldSend: boolean;
            updatedByMitarbeiterId?: string | null;
            vorschreibungMailDeliveryId: string;
            emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
            fakturierungsperiode: { jahr: number; monat: number };
            objektBestandseinheitVertragList: Array<{
              vertragId: string;
              bestandseinheit: {
                bestandseinheitId: string;
                bezeichnung: string;
                address: {
                  addressId: string;
                  city: string;
                  cityAdditionalInformation?: string | null;
                  countryCodeIso2: string;
                  houseEntranceApartmentNumber?: string | null;
                  postofficeBoxNumber?: string | null;
                  postofficeBoxZipCode?: string | null;
                  street?: string | null;
                  type: Types.AddressType;
                  zipCode?: string | null;
                };
              };
              objekt: { kurzBezeichnung: string; objektId: string };
            }>;
            recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
            vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
          fakturierungsperiode: { jahr: number; monat: number };
          objektBestandseinheitVertragList: Array<{
            vertragId: string;
            bestandseinheit: {
              bestandseinheitId: string;
              bezeichnung: string;
              address: {
                addressId: string;
                city: string;
                cityAdditionalInformation?: string | null;
                countryCodeIso2: string;
                houseEntranceApartmentNumber?: string | null;
                postofficeBoxNumber?: string | null;
                postofficeBoxZipCode?: string | null;
                street?: string | null;
                type: Types.AddressType;
                zipCode?: string | null;
              };
            };
            objekt: { kurzBezeichnung: string; objektId: string };
          }>;
          recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
          vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        eigenbeleg?: {
          auftragIdList: Array<string>;
          ausstellerRechtstraegerId: string;
          belegId: string;
          belegdatum: string;
          belegnummer: string;
          bezeichnung: string;
          buchungIdList: Array<string>;
          buchungsanweisungIdList: Array<string>;
          buchungskreisId?: string | null;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          empfaengerRechtstraegerId: string;
          mailId?: string | null;
          paid: boolean;
          sumBrutto?: number | null;
          sumNetto?: number | null;
          sumUst?: number | null;
          auftragStatusDetailsList: Array<{
            auftragId: string;
            offenerBetrag?: number | null;
            paymentCreatable: boolean;
            paymentIdList: Array<string>;
            buchungsanweisungStatus?: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus } | null;
            eigenbelegStatus: { description?: string | null; text: string; value: Types.EigenbelegStatus };
            fakturierungsperiode: { jahr: number; monat: number };
          }>;
          ausstellerBankDetails: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string };
          ausstellerRechtstraeger: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fibuKontoCreatable: boolean;
            kundennummer: string;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            tagList: Array<string>;
            updatedByMitarbeiterId?: string | null;
            bankDetailsList: Array<{
              accountHolder: string;
              accountLimit?: number | null;
              assignedHeVertragZahlungsregelMap: any;
              assignedObjektMap: any;
              bankDetailsId: string;
              bankname: string;
              bic: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              ebicsCreditTransferUserList: Array<string>;
              ebicsDirectDebitUserList: Array<string>;
              fibuKontoId?: string | null;
              fibuKontonummer?: string | null;
              iban: string;
              updatedByMitarbeiterId?: string | null;
              fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
              fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
              sepaMandat?: {
                fileId?: string | null;
                fileName?: string | null;
                sepaMandatId: string;
                sepaMandatReference: string;
                signatureDate?: string | null;
                firmendaten?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  firmendatenId: string;
                  kundenSystemId: string;
                  kurzBezeichnung: string;
                  name1: string;
                  name2?: string | null;
                  name3?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                rechnungsAussteller?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
                warningList: Array<{ message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            personenbezugList: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              personenbezugId: string;
              tagList: Array<string>;
              person: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechtstraegerSteuerpflicht?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              rechtstraegerSteuerpflichtId: string;
              validFrom: string;
              historicizedList?: Array<{
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                deletable?: boolean | null;
                kommentar?: string | null;
                lastUpdateTs: string;
                rechtstraegerSteuerpflichtId: string;
                validFrom: string;
                steuerpflicht: { text: string; value: Types.Steuerpflicht };
                warningList: Array<{ message: string; type: string }>;
              }> | null;
              steuerpflicht: { text: string; value: Types.Steuerpflicht };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
            sprache: { text: string; value: Types.Sprache };
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { value: Types.RechtstraegerType; text: string };
            vertragSummary?: {
              heVertragCount: number;
              heVertragVerwaltungCount: number;
              mietVertragCount: number;
              mietVertragVerwaltungCount: number;
              weSubverwaltungCount: number;
              weVertragCount: number;
              weVertragVerwaltungCount: number;
            } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          belegform: { text: string; value: Types.BelegFormArt };
          buchungErrorList: Array<{ message: string; type: string }>;
          buchungsanweisungListEntryDataList: Array<{
            buchungsanweisungId: string;
            status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
            fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
          }>;
          buchungsanweisungStatus?: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus } | null;
          deviationDetails?: {
            umbuchungInfoList: Array<{
              fakturierungsperiode: { jahr: number; monat: number };
              kontoKeyIst: { nummer: string; klasse: { text: string; value: Types.KontoKlasse } };
              kontoKeySoll: { nummer: string; klasse: { text: string; value: Types.KontoKlasse } };
            }>;
          } | null;
          dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
          eigenbelegPositionList: Array<{
            bestandseinheitId: string;
            bezeichnung: string;
            brutto: number;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            eigenbelegsPositionId: string;
            generierDatum?: string | null;
            generierlaufId?: string | null;
            netto: number;
            objektId: string;
            performancePeriodFromInclusive: string;
            performancePeriodToInclusive: string;
            steuersatz: number;
            ust: number;
            ustKategorieEntryId: string;
            ustKategorieId: string;
            vertragId: string;
            vorschreibungspositionBezeichnung: string;
            vorschreibungspositionId: string;
            bestandseinheit?: {
              aktivSeit?: string | null;
              bauteil?: string | null;
              bestandseinheitId: string;
              bezeichnung: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              geschoss?: string | null;
              inaktivSeit?: string | null;
              nutzflaeche?: number | null;
              nutzwert?: number | null;
              stiege?: string | null;
              tagList: Array<string>;
              address: {
                addressId: string;
                city: string;
                cityAdditionalInformation?: string | null;
                countryCodeIso2: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                haupt?: boolean | null;
                houseEntranceApartmentNumber?: string | null;
                postofficeBoxNumber?: string | null;
                postofficeBoxZipCode?: string | null;
                street?: string | null;
                tagList: Array<string>;
                type: Types.AddressType;
                zipCode?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              nutzungsArt?: { text: string; value: Types.NutzungsArt } | null;
              optionSteuerpflicht?: {
                beOptionSteuerpflichtId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                deletable?: boolean | null;
                kommentar?: string | null;
                lastUpdateTs: string;
                optionSteuerpflicht: boolean;
                validFrom: string;
                historicizedList?: Array<{
                  beOptionSteuerpflichtId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  current: boolean;
                  deletable?: boolean | null;
                  kommentar?: string | null;
                  lastUpdateTs: string;
                  optionSteuerpflicht: boolean;
                  validFrom: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }> | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              objektVerwaltungsart: { text: string; value: Types.Verwaltungsart };
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            labelList: Array<{ label: string; value: string }>;
            objekt?: {
              countryCodeIso2: string;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fotoFileId?: string | null;
              fotoType?: Types.FotoType | null;
              kurzBezeichnung: string;
              objektId: string;
              rechnungsAusstellerBezeichnung?: string | null;
              rechnungsAusstellerChangeable?: boolean | null;
              subAdministration: boolean;
              sumNutzflaeche: number;
              sumNutzwert: number;
              tagList: Array<string>;
              updatedByMitarbeiterId?: string | null;
              verwaltungBis?: string | null;
              verwaltungSeit: string;
              verwaltungsartChangeable?: boolean | null;
              addressList: Array<{
                addressId: string;
                city: string;
                cityAdditionalInformation?: string | null;
                countryCodeIso2: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                haupt?: boolean | null;
                houseEntranceApartmentNumber?: string | null;
                postofficeBoxNumber?: string | null;
                postofficeBoxZipCode?: string | null;
                street?: string | null;
                tagList: Array<string>;
                type: Types.AddressType;
                zipCode?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              objektSummeAnteile?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                kommentar?: string | null;
                lastUpdateTs: string;
                objektSummeAnteileId: string;
                summeAnteile: number;
                validFrom: string;
                historicizedList?: Array<{
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  current: boolean;
                  kommentar?: string | null;
                  lastUpdateTs: string;
                  objektSummeAnteileId: string;
                  summeAnteile: number;
                  validFrom: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }> | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rechnungsAussteller: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                fibuKontoCreatable: boolean;
                kundennummer: string;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                tagList: Array<string>;
                updatedByMitarbeiterId?: string | null;
                bankDetailsList: Array<{
                  accountHolder: string;
                  accountLimit?: number | null;
                  assignedHeVertragZahlungsregelMap: any;
                  assignedObjektMap: any;
                  bankDetailsId: string;
                  bankname: string;
                  bic: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  deletable?: boolean | null;
                  ebicsCreditTransferUserList: Array<string>;
                  ebicsDirectDebitUserList: Array<string>;
                  fibuKontoId?: string | null;
                  fibuKontonummer?: string | null;
                  iban: string;
                  updatedByMitarbeiterId?: string | null;
                  fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
                  fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
                  sepaMandat?: {
                    fileId?: string | null;
                    fileName?: string | null;
                    sepaMandatId: string;
                    sepaMandatReference: string;
                    signatureDate?: string | null;
                    firmendaten?: {
                      createTs: string;
                      createdBy: string;
                      createdByMitarbeiterId?: string | null;
                      firmendatenId: string;
                      kundenSystemId: string;
                      kurzBezeichnung: string;
                      name1: string;
                      name2?: string | null;
                      name3?: string | null;
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    } | null;
                    rechnungsAussteller?: {
                      createTs: string;
                      createdBy: string;
                      createdByMitarbeiterId?: string | null;
                      kurzBezeichnung: string;
                      rechtstraegerId: string;
                      status: { text: string; value: Types.RechtstraegerStatus };
                      type: { text: string; value: Types.RechtstraegerType };
                      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                    } | null;
                    status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
                    warningList: Array<{ message: string; type: string }>;
                  } | null;
                  status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                personenbezugList: Array<{
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  personenbezugId: string;
                  tagList: Array<string>;
                  person: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                postIt?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  immerAnzeigen: boolean;
                  postItId: string;
                  text: string;
                  titel: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                rechtstraegerSteuerpflicht?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  current: boolean;
                  deletable?: boolean | null;
                  kommentar?: string | null;
                  lastUpdateTs: string;
                  rechtstraegerSteuerpflichtId: string;
                  validFrom: string;
                  historicizedList?: Array<{
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    current: boolean;
                    deletable?: boolean | null;
                    kommentar?: string | null;
                    lastUpdateTs: string;
                    rechtstraegerSteuerpflichtId: string;
                    validFrom: string;
                    steuerpflicht: { text: string; value: Types.Steuerpflicht };
                    warningList: Array<{ message: string; type: string }>;
                  }> | null;
                  steuerpflicht: { text: string; value: Types.Steuerpflicht };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
                sprache: { text: string; value: Types.Sprache };
                status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
                type: { value: Types.RechtstraegerType; text: string };
                vertragSummary?: {
                  heVertragCount: number;
                  heVertragVerwaltungCount: number;
                  mietVertragCount: number;
                  mietVertragVerwaltungCount: number;
                  weSubverwaltungCount: number;
                  weVertragCount: number;
                  weVertragVerwaltungCount: number;
                } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              sachbearbeiter?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                fileStorageInitialized: boolean;
                firmendatenId: string;
                firstname: string;
                funktionsModulList: Array<string>;
                lastname: string;
                mitarbeiterId: string;
                title?: string | null;
                titleTrailing?: string | null;
                username: string;
                contactPerson?: {
                  contactPersonId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  firstname: string;
                  lastname: string;
                  tagList: Array<string>;
                  title?: string | null;
                  titleTrailing?: string | null;
                  contactList: Array<{
                    contactId: string;
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    haupt?: boolean | null;
                    tagList: Array<string>;
                    type: string;
                    wert: string;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  }>;
                  rechtstraeger?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                  salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                rolleList: Array<{
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  name: string;
                  rolleId: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
                status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
                user?: {
                  email?: string | null;
                  emailUnverified?: string | null;
                  firstname?: string | null;
                  lastname?: string | null;
                  role: string;
                  username: string;
                  status: { text: string; value: string };
                } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { text: string; value: Types.ObjektStatus; description?: string | null };
              verwaltungsart: { text: string; value: Types.Verwaltungsart };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
          }>;
          empfaengerBankDetails?: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string } | null;
          empfaengerRechtstraeger: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fibuKontoCreatable: boolean;
            kundennummer: string;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            tagList: Array<string>;
            updatedByMitarbeiterId?: string | null;
            bankDetailsList: Array<{
              accountHolder: string;
              accountLimit?: number | null;
              assignedHeVertragZahlungsregelMap: any;
              assignedObjektMap: any;
              bankDetailsId: string;
              bankname: string;
              bic: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              ebicsCreditTransferUserList: Array<string>;
              ebicsDirectDebitUserList: Array<string>;
              fibuKontoId?: string | null;
              fibuKontonummer?: string | null;
              iban: string;
              updatedByMitarbeiterId?: string | null;
              fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
              fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
              sepaMandat?: {
                fileId?: string | null;
                fileName?: string | null;
                sepaMandatId: string;
                sepaMandatReference: string;
                signatureDate?: string | null;
                firmendaten?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  firmendatenId: string;
                  kundenSystemId: string;
                  kurzBezeichnung: string;
                  name1: string;
                  name2?: string | null;
                  name3?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                rechnungsAussteller?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
                warningList: Array<{ message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            personenbezugList: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              personenbezugId: string;
              tagList: Array<string>;
              person: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechtstraegerSteuerpflicht?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              rechtstraegerSteuerpflichtId: string;
              validFrom: string;
              historicizedList?: Array<{
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                deletable?: boolean | null;
                kommentar?: string | null;
                lastUpdateTs: string;
                rechtstraegerSteuerpflichtId: string;
                validFrom: string;
                steuerpflicht: { text: string; value: Types.Steuerpflicht };
                warningList: Array<{ message: string; type: string }>;
              }> | null;
              steuerpflicht: { text: string; value: Types.Steuerpflicht };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
            sprache: { text: string; value: Types.Sprache };
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { value: Types.RechtstraegerType; text: string };
            vertragSummary?: {
              heVertragCount: number;
              heVertragVerwaltungCount: number;
              mietVertragCount: number;
              mietVertragVerwaltungCount: number;
              weSubverwaltungCount: number;
              weVertragCount: number;
              weVertragVerwaltungCount: number;
            } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          fakturierungsperiode: { jahr: number; monat: number };
          fileInfo: { fileId: string; name: string; size: number };
          paymentMethod: { text: string; value: Types.PaymentMethod };
          status: { description?: string | null; text: string; value: Types.EigenbelegStatus };
          type: { text: string; value: Types.EigenbelegType };
          verrechnungsart: { text: string; value: Types.Verrechnungsart };
        } | null;
        empfaengerBankDetailsList: Array<{
          accountHolder: string;
          accountLimit?: number | null;
          assignedHeVertragZahlungsregelMap: any;
          assignedObjektMap: any;
          bankDetailsId: string;
          bankname: string;
          bic: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          ebicsCreditTransferUserList: Array<string>;
          ebicsDirectDebitUserList: Array<string>;
          fibuKontoId?: string | null;
          fibuKontonummer?: string | null;
          iban: string;
          updatedByMitarbeiterId?: string | null;
          fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
          fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
          sepaMandat?: {
            fileId?: string | null;
            fileName?: string | null;
            sepaMandatId: string;
            sepaMandatReference: string;
            signatureDate?: string | null;
            firmendaten?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firmendatenId: string;
              kundenSystemId: string;
              kurzBezeichnung: string;
              name1: string;
              name2?: string | null;
              name3?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechnungsAussteller?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
            warningList: Array<{ message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null>;
        fibuStatus?: { description?: string | null; text: string; value: Types.AuftragFibuStatus } | null;
        objektBestandseinheitIdDataList: Array<{ bestandseinheitId: string; objektId: string }>;
        originalMailDelivery?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          emailAddress: string;
          emailDeliveryDefinitionBezeichnung: string;
          emailDeliveryDefinitionId: string;
          mailId?: string | null;
          original: boolean;
          sender?: string | null;
          sentTs?: string | null;
          shouldNeverSend: boolean;
          shouldSend: boolean;
          updatedByMitarbeiterId?: string | null;
          vorschreibungMailDeliveryId: string;
          resentDeliveryList?: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            emailAddress: string;
            emailDeliveryDefinitionBezeichnung: string;
            emailDeliveryDefinitionId: string;
            mailId?: string | null;
            original: boolean;
            sender?: string | null;
            sentTs?: string | null;
            shouldNeverSend: boolean;
            shouldSend: boolean;
            updatedByMitarbeiterId?: string | null;
            vorschreibungMailDeliveryId: string;
            emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
            fakturierungsperiode: { jahr: number; monat: number };
            objektBestandseinheitVertragList: Array<{
              vertragId: string;
              bestandseinheit: {
                bestandseinheitId: string;
                bezeichnung: string;
                address: {
                  addressId: string;
                  city: string;
                  cityAdditionalInformation?: string | null;
                  countryCodeIso2: string;
                  houseEntranceApartmentNumber?: string | null;
                  postofficeBoxNumber?: string | null;
                  postofficeBoxZipCode?: string | null;
                  street?: string | null;
                  type: Types.AddressType;
                  zipCode?: string | null;
                };
              };
              objekt: { kurzBezeichnung: string; objektId: string };
            }>;
            recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
            vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }> | null;
          sourceDelivery?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            emailAddress: string;
            emailDeliveryDefinitionBezeichnung: string;
            emailDeliveryDefinitionId: string;
            mailId?: string | null;
            original: boolean;
            sender?: string | null;
            sentTs?: string | null;
            shouldNeverSend: boolean;
            shouldSend: boolean;
            updatedByMitarbeiterId?: string | null;
            vorschreibungMailDeliveryId: string;
            emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
            fakturierungsperiode: { jahr: number; monat: number };
            objektBestandseinheitVertragList: Array<{
              vertragId: string;
              bestandseinheit: {
                bestandseinheitId: string;
                bezeichnung: string;
                address: {
                  addressId: string;
                  city: string;
                  cityAdditionalInformation?: string | null;
                  countryCodeIso2: string;
                  houseEntranceApartmentNumber?: string | null;
                  postofficeBoxNumber?: string | null;
                  postofficeBoxZipCode?: string | null;
                  street?: string | null;
                  type: Types.AddressType;
                  zipCode?: string | null;
                };
              };
              objekt: { kurzBezeichnung: string; objektId: string };
            }>;
            recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
            vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
          fakturierungsperiode: { jahr: number; monat: number };
          objektBestandseinheitVertragList: Array<{
            vertragId: string;
            bestandseinheit: {
              bestandseinheitId: string;
              bezeichnung: string;
              address: {
                addressId: string;
                city: string;
                cityAdditionalInformation?: string | null;
                countryCodeIso2: string;
                houseEntranceApartmentNumber?: string | null;
                postofficeBoxNumber?: string | null;
                postofficeBoxZipCode?: string | null;
                street?: string | null;
                type: Types.AddressType;
                zipCode?: string | null;
              };
            };
            objekt: { kurzBezeichnung: string; objektId: string };
          }>;
          recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
          vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.AuftragStatus };
      } | null;
      auftragsKopf: {
        consolidationCriteria?: string | null;
        rechnungsAusstellerBezeichnung: string;
        sepaCreditorId?: string | null;
        sepaMandatReference?: string | null;
        auftragsartType: { text: string; value: Types.AuftragsartType };
        aussteller: {
          kurzBezeichnung: string;
          kundennummer: string;
          rechtstraegerId: string;
          type: { text: string; value: Types.RechtstraegerType };
        };
        ausstellerBankDetails: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string };
        belegform: { text: string; value: Types.BelegFormArt };
        deliveryAddress: {
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          type: Types.AddressType;
          zipCode?: string | null;
        };
        dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
        empfaenger: {
          kurzBezeichnung: string;
          kundennummer: string;
          rechtstraegerId: string;
          type: { text: string; value: Types.RechtstraegerType };
        };
        empfaengerBankDetails?: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string } | null;
        fakturierungsperiode: { jahr: number; monat: number };
        objekt: { kurzBezeichnung: string; objektId: string };
        paymentMethod: { text: string; value: Types.PaymentMethod };
        recipientEmailContact: { contactId: string; type: string; wert: string };
        verrechnungsart: { text: string; value: Types.Verrechnungsart };
        vertragsart: { text: string; value: Types.Vertragsart };
        zustellRechtstraeger: {
          kurzBezeichnung: string;
          kundennummer: string;
          rechtstraegerId: string;
          type: { text: string; value: Types.RechtstraegerType };
        };
      };
      auftragsPositionList: Array<{
        auftragId: string;
        auftragsPositionId: string;
        bestandseinheitId: string;
        bezeichnung: string;
        brutto: number;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        generierDatum?: string | null;
        generierlaufId?: string | null;
        netto: number;
        objektId: string;
        originId: string;
        performancePeriodFromInclusive: string;
        performancePeriodToInclusive: string;
        rechnungskennzeichen: string;
        steuersatz: number;
        ust: number;
        ustKategorieEntryId: string;
        ustKategorieId: string;
        ustKategorieVersionId: string;
        vertragBezeichnung: string;
        vertragId: string;
        vorschreibungspositionBezeichnung: string;
        vorschreibungspositionId: string;
        bestandseinheit: {
          aktivSeit?: string | null;
          bauteil?: string | null;
          bestandseinheitId: string;
          bezeichnung: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          geschoss?: string | null;
          inaktivSeit?: string | null;
          nutzflaeche?: number | null;
          nutzwert?: number | null;
          stiege?: string | null;
          tagList: Array<string>;
          address: {
            addressId: string;
            city: string;
            cityAdditionalInformation?: string | null;
            countryCodeIso2: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            haupt?: boolean | null;
            houseEntranceApartmentNumber?: string | null;
            postofficeBoxNumber?: string | null;
            postofficeBoxZipCode?: string | null;
            street?: string | null;
            tagList: Array<string>;
            type: Types.AddressType;
            zipCode?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          nutzungsArt?: { text: string; value: Types.NutzungsArt } | null;
          optionSteuerpflicht?: {
            beOptionSteuerpflichtId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            optionSteuerpflicht: boolean;
            validFrom: string;
            historicizedList?: Array<{
              beOptionSteuerpflichtId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              optionSteuerpflicht: boolean;
              validFrom: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }> | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          objektVerwaltungsart: { text: string; value: Types.Verwaltungsart };
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        fibuStatus?: { text: string; value: Types.AuftragFibuStatus; description?: string | null } | null;
        labelList: Array<{ label: string; value: string }>;
        objekt: {
          countryCodeIso2: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fotoFileId?: string | null;
          fotoType?: Types.FotoType | null;
          kurzBezeichnung: string;
          objektId: string;
          rechnungsAusstellerBezeichnung?: string | null;
          rechnungsAusstellerChangeable?: boolean | null;
          subAdministration: boolean;
          sumNutzflaeche: number;
          sumNutzwert: number;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          verwaltungBis?: string | null;
          verwaltungSeit: string;
          verwaltungsartChangeable?: boolean | null;
          addressList: Array<{
            addressId: string;
            city: string;
            cityAdditionalInformation?: string | null;
            countryCodeIso2: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            haupt?: boolean | null;
            houseEntranceApartmentNumber?: string | null;
            postofficeBoxNumber?: string | null;
            postofficeBoxZipCode?: string | null;
            street?: string | null;
            tagList: Array<string>;
            type: Types.AddressType;
            zipCode?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          objektSummeAnteile?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            kommentar?: string | null;
            lastUpdateTs: string;
            objektSummeAnteileId: string;
            summeAnteile: number;
            validFrom: string;
            historicizedList?: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              kommentar?: string | null;
              lastUpdateTs: string;
              objektSummeAnteileId: string;
              summeAnteile: number;
              validFrom: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }> | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechnungsAussteller: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fibuKontoCreatable: boolean;
            kundennummer: string;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            tagList: Array<string>;
            updatedByMitarbeiterId?: string | null;
            bankDetailsList: Array<{
              accountHolder: string;
              accountLimit?: number | null;
              assignedHeVertragZahlungsregelMap: any;
              assignedObjektMap: any;
              bankDetailsId: string;
              bankname: string;
              bic: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              ebicsCreditTransferUserList: Array<string>;
              ebicsDirectDebitUserList: Array<string>;
              fibuKontoId?: string | null;
              fibuKontonummer?: string | null;
              iban: string;
              updatedByMitarbeiterId?: string | null;
              fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
              fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
              sepaMandat?: {
                fileId?: string | null;
                fileName?: string | null;
                sepaMandatId: string;
                sepaMandatReference: string;
                signatureDate?: string | null;
                firmendaten?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  firmendatenId: string;
                  kundenSystemId: string;
                  kurzBezeichnung: string;
                  name1: string;
                  name2?: string | null;
                  name3?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                rechnungsAussteller?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
                warningList: Array<{ message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            personenbezugList: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              personenbezugId: string;
              tagList: Array<string>;
              person: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechtstraegerSteuerpflicht?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              rechtstraegerSteuerpflichtId: string;
              validFrom: string;
              historicizedList?: Array<{
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                deletable?: boolean | null;
                kommentar?: string | null;
                lastUpdateTs: string;
                rechtstraegerSteuerpflichtId: string;
                validFrom: string;
                steuerpflicht: { text: string; value: Types.Steuerpflicht };
                warningList: Array<{ message: string; type: string }>;
              }> | null;
              steuerpflicht: { text: string; value: Types.Steuerpflicht };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
            sprache: { text: string; value: Types.Sprache };
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { value: Types.RechtstraegerType; text: string };
            vertragSummary?: {
              heVertragCount: number;
              heVertragVerwaltungCount: number;
              mietVertragCount: number;
              mietVertragVerwaltungCount: number;
              weSubverwaltungCount: number;
              weVertragCount: number;
              weVertragVerwaltungCount: number;
            } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          sachbearbeiter?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            fileStorageInitialized: boolean;
            firmendatenId: string;
            firstname: string;
            funktionsModulList: Array<string>;
            lastname: string;
            mitarbeiterId: string;
            title?: string | null;
            titleTrailing?: string | null;
            username: string;
            contactPerson?: {
              contactPersonId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firstname: string;
              lastname: string;
              tagList: Array<string>;
              title?: string | null;
              titleTrailing?: string | null;
              contactList: Array<{
                contactId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                haupt?: boolean | null;
                tagList: Array<string>;
                type: string;
                wert: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              rechtstraeger?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rolleList: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              name: string;
              rolleId: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
            status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
            user?: {
              email?: string | null;
              emailUnverified?: string | null;
              firstname?: string | null;
              lastname?: string | null;
              role: string;
              username: string;
              status: { text: string; value: string };
            } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.ObjektStatus; description?: string | null };
          verwaltungsart: { text: string; value: Types.Verwaltungsart };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
      }>;
      auftragsart: {
        auftragsartId: string;
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        showUstBlock: boolean;
        updatedByMitarbeiterId?: string | null;
        status: { text: string; value: Types.AuftragsartStatus };
        type: { text: string; value: Types.AuftragsartType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      billingAddress: {
        addressId: string;
        city: string;
        cityAdditionalInformation?: string | null;
        countryCodeIso2: string;
        houseEntranceApartmentNumber?: string | null;
        postofficeBoxNumber?: string | null;
        postofficeBoxZipCode?: string | null;
        street?: string | null;
        type: Types.AddressType;
        zipCode?: string | null;
      };
      buchungErrorList: Array<{ message: string; type: string }>;
      copyEmailRecipientList?: Array<{
        contact: { contactId: string; type: string; wert: string };
        rechtstraeger: {
          kurzBezeichnung: string;
          kundennummer: string;
          rechtstraegerId: string;
          type: { text: string; value: Types.RechtstraegerType };
        };
      }> | null;
      copyMailDeliveryList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        emailAddress: string;
        emailDeliveryDefinitionBezeichnung: string;
        emailDeliveryDefinitionId: string;
        mailId?: string | null;
        original: boolean;
        sender?: string | null;
        sentTs?: string | null;
        shouldNeverSend: boolean;
        shouldSend: boolean;
        updatedByMitarbeiterId?: string | null;
        vorschreibungMailDeliveryId: string;
        resentDeliveryList?: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          emailAddress: string;
          emailDeliveryDefinitionBezeichnung: string;
          emailDeliveryDefinitionId: string;
          mailId?: string | null;
          original: boolean;
          sender?: string | null;
          sentTs?: string | null;
          shouldNeverSend: boolean;
          shouldSend: boolean;
          updatedByMitarbeiterId?: string | null;
          vorschreibungMailDeliveryId: string;
          emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
          fakturierungsperiode: { jahr: number; monat: number };
          objektBestandseinheitVertragList: Array<{
            vertragId: string;
            bestandseinheit: {
              bestandseinheitId: string;
              bezeichnung: string;
              address: {
                addressId: string;
                city: string;
                cityAdditionalInformation?: string | null;
                countryCodeIso2: string;
                houseEntranceApartmentNumber?: string | null;
                postofficeBoxNumber?: string | null;
                postofficeBoxZipCode?: string | null;
                street?: string | null;
                type: Types.AddressType;
                zipCode?: string | null;
              };
            };
            objekt: { kurzBezeichnung: string; objektId: string };
          }>;
          recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
          vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }> | null;
        sourceDelivery?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          emailAddress: string;
          emailDeliveryDefinitionBezeichnung: string;
          emailDeliveryDefinitionId: string;
          mailId?: string | null;
          original: boolean;
          sender?: string | null;
          sentTs?: string | null;
          shouldNeverSend: boolean;
          shouldSend: boolean;
          updatedByMitarbeiterId?: string | null;
          vorschreibungMailDeliveryId: string;
          emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
          fakturierungsperiode: { jahr: number; monat: number };
          objektBestandseinheitVertragList: Array<{
            vertragId: string;
            bestandseinheit: {
              bestandseinheitId: string;
              bezeichnung: string;
              address: {
                addressId: string;
                city: string;
                cityAdditionalInformation?: string | null;
                countryCodeIso2: string;
                houseEntranceApartmentNumber?: string | null;
                postofficeBoxNumber?: string | null;
                postofficeBoxZipCode?: string | null;
                street?: string | null;
                type: Types.AddressType;
                zipCode?: string | null;
              };
            };
            objekt: { kurzBezeichnung: string; objektId: string };
          }>;
          recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
          vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
        fakturierungsperiode: { jahr: number; monat: number };
        objektBestandseinheitVertragList: Array<{
          vertragId: string;
          bestandseinheit: {
            bestandseinheitId: string;
            bezeichnung: string;
            address: {
              addressId: string;
              city: string;
              cityAdditionalInformation?: string | null;
              countryCodeIso2: string;
              houseEntranceApartmentNumber?: string | null;
              postofficeBoxNumber?: string | null;
              postofficeBoxZipCode?: string | null;
              street?: string | null;
              type: Types.AddressType;
              zipCode?: string | null;
            };
          };
          objekt: { kurzBezeichnung: string; objektId: string };
        }>;
        recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
        vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      eigenbeleg?: {
        auftragIdList: Array<string>;
        ausstellerRechtstraegerId: string;
        belegId: string;
        belegdatum: string;
        belegnummer: string;
        bezeichnung: string;
        buchungIdList: Array<string>;
        buchungsanweisungIdList: Array<string>;
        buchungskreisId?: string | null;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        empfaengerRechtstraegerId: string;
        mailId?: string | null;
        paid: boolean;
        sumBrutto?: number | null;
        sumNetto?: number | null;
        sumUst?: number | null;
        auftragStatusDetailsList: Array<{
          auftragId: string;
          offenerBetrag?: number | null;
          paymentCreatable: boolean;
          paymentIdList: Array<string>;
          buchungsanweisungStatus?: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus } | null;
          eigenbelegStatus: { description?: string | null; text: string; value: Types.EigenbelegStatus };
          fakturierungsperiode: { jahr: number; monat: number };
        }>;
        ausstellerBankDetails: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string };
        ausstellerRechtstraeger: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fibuKontoCreatable: boolean;
          kundennummer: string;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          bankDetailsList: Array<{
            accountHolder: string;
            accountLimit?: number | null;
            assignedHeVertragZahlungsregelMap: any;
            assignedObjektMap: any;
            bankDetailsId: string;
            bankname: string;
            bic: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            ebicsCreditTransferUserList: Array<string>;
            ebicsDirectDebitUserList: Array<string>;
            fibuKontoId?: string | null;
            fibuKontonummer?: string | null;
            iban: string;
            updatedByMitarbeiterId?: string | null;
            fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
            fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
            sepaMandat?: {
              fileId?: string | null;
              fileName?: string | null;
              sepaMandatId: string;
              sepaMandatReference: string;
              signatureDate?: string | null;
              firmendaten?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                firmendatenId: string;
                kundenSystemId: string;
                kurzBezeichnung: string;
                name1: string;
                name2?: string | null;
                name3?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rechnungsAussteller?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
              warningList: Array<{ message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          personenbezugList: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            personenbezugId: string;
            tagList: Array<string>;
            person: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechtstraegerSteuerpflicht?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            historicizedList?: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              rechtstraegerSteuerpflichtId: string;
              validFrom: string;
              steuerpflicht: { text: string; value: Types.Steuerpflicht };
              warningList: Array<{ message: string; type: string }>;
            }> | null;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
          sprache: { text: string; value: Types.Sprache };
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { value: Types.RechtstraegerType; text: string };
          vertragSummary?: {
            heVertragCount: number;
            heVertragVerwaltungCount: number;
            mietVertragCount: number;
            mietVertragVerwaltungCount: number;
            weSubverwaltungCount: number;
            weVertragCount: number;
            weVertragVerwaltungCount: number;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        belegform: { text: string; value: Types.BelegFormArt };
        buchungErrorList: Array<{ message: string; type: string }>;
        buchungsanweisungListEntryDataList: Array<{
          buchungsanweisungId: string;
          status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
          fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
        }>;
        buchungsanweisungStatus?: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus } | null;
        deviationDetails?: {
          umbuchungInfoList: Array<{
            fakturierungsperiode: { jahr: number; monat: number };
            kontoKeyIst: { nummer: string; klasse: { text: string; value: Types.KontoKlasse } };
            kontoKeySoll: { nummer: string; klasse: { text: string; value: Types.KontoKlasse } };
          }>;
        } | null;
        dueDateVorschreibung: { text: string; value: Types.DueDateVorschreibungArt };
        eigenbelegPositionList: Array<{
          bestandseinheitId: string;
          bezeichnung: string;
          brutto: number;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          eigenbelegsPositionId: string;
          generierDatum?: string | null;
          generierlaufId?: string | null;
          netto: number;
          objektId: string;
          performancePeriodFromInclusive: string;
          performancePeriodToInclusive: string;
          steuersatz: number;
          ust: number;
          ustKategorieEntryId: string;
          ustKategorieId: string;
          vertragId: string;
          vorschreibungspositionBezeichnung: string;
          vorschreibungspositionId: string;
          bestandseinheit?: {
            aktivSeit?: string | null;
            bauteil?: string | null;
            bestandseinheitId: string;
            bezeichnung: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            geschoss?: string | null;
            inaktivSeit?: string | null;
            nutzflaeche?: number | null;
            nutzwert?: number | null;
            stiege?: string | null;
            tagList: Array<string>;
            address: {
              addressId: string;
              city: string;
              cityAdditionalInformation?: string | null;
              countryCodeIso2: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              haupt?: boolean | null;
              houseEntranceApartmentNumber?: string | null;
              postofficeBoxNumber?: string | null;
              postofficeBoxZipCode?: string | null;
              street?: string | null;
              tagList: Array<string>;
              type: Types.AddressType;
              zipCode?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            nutzungsArt?: { text: string; value: Types.NutzungsArt } | null;
            optionSteuerpflicht?: {
              beOptionSteuerpflichtId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              optionSteuerpflicht: boolean;
              validFrom: string;
              historicizedList?: Array<{
                beOptionSteuerpflichtId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                deletable?: boolean | null;
                kommentar?: string | null;
                lastUpdateTs: string;
                optionSteuerpflicht: boolean;
                validFrom: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }> | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            objektVerwaltungsart: { text: string; value: Types.Verwaltungsart };
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          labelList: Array<{ label: string; value: string }>;
          objekt?: {
            countryCodeIso2: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fotoFileId?: string | null;
            fotoType?: Types.FotoType | null;
            kurzBezeichnung: string;
            objektId: string;
            rechnungsAusstellerBezeichnung?: string | null;
            rechnungsAusstellerChangeable?: boolean | null;
            subAdministration: boolean;
            sumNutzflaeche: number;
            sumNutzwert: number;
            tagList: Array<string>;
            updatedByMitarbeiterId?: string | null;
            verwaltungBis?: string | null;
            verwaltungSeit: string;
            verwaltungsartChangeable?: boolean | null;
            addressList: Array<{
              addressId: string;
              city: string;
              cityAdditionalInformation?: string | null;
              countryCodeIso2: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              haupt?: boolean | null;
              houseEntranceApartmentNumber?: string | null;
              postofficeBoxNumber?: string | null;
              postofficeBoxZipCode?: string | null;
              street?: string | null;
              tagList: Array<string>;
              type: Types.AddressType;
              zipCode?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            objektSummeAnteile?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              kommentar?: string | null;
              lastUpdateTs: string;
              objektSummeAnteileId: string;
              summeAnteile: number;
              validFrom: string;
              historicizedList?: Array<{
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                kommentar?: string | null;
                lastUpdateTs: string;
                objektSummeAnteileId: string;
                summeAnteile: number;
                validFrom: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }> | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechnungsAussteller: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              fibuKontoCreatable: boolean;
              kundennummer: string;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              tagList: Array<string>;
              updatedByMitarbeiterId?: string | null;
              bankDetailsList: Array<{
                accountHolder: string;
                accountLimit?: number | null;
                assignedHeVertragZahlungsregelMap: any;
                assignedObjektMap: any;
                bankDetailsId: string;
                bankname: string;
                bic: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                ebicsCreditTransferUserList: Array<string>;
                ebicsDirectDebitUserList: Array<string>;
                fibuKontoId?: string | null;
                fibuKontonummer?: string | null;
                iban: string;
                updatedByMitarbeiterId?: string | null;
                fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
                fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
                sepaMandat?: {
                  fileId?: string | null;
                  fileName?: string | null;
                  sepaMandatId: string;
                  sepaMandatReference: string;
                  signatureDate?: string | null;
                  firmendaten?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    firmendatenId: string;
                    kundenSystemId: string;
                    kurzBezeichnung: string;
                    name1: string;
                    name2?: string | null;
                    name3?: string | null;
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                  rechnungsAussteller?: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  } | null;
                  status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
                  warningList: Array<{ message: string; type: string }>;
                } | null;
                status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              personenbezugList: Array<{
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                personenbezugId: string;
                tagList: Array<string>;
                person: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              postIt?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                immerAnzeigen: boolean;
                postItId: string;
                text: string;
                titel: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rechtstraegerSteuerpflicht?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                deletable?: boolean | null;
                kommentar?: string | null;
                lastUpdateTs: string;
                rechtstraegerSteuerpflichtId: string;
                validFrom: string;
                historicizedList?: Array<{
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  current: boolean;
                  deletable?: boolean | null;
                  kommentar?: string | null;
                  lastUpdateTs: string;
                  rechtstraegerSteuerpflichtId: string;
                  validFrom: string;
                  steuerpflicht: { text: string; value: Types.Steuerpflicht };
                  warningList: Array<{ message: string; type: string }>;
                }> | null;
                steuerpflicht: { text: string; value: Types.Steuerpflicht };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
              sprache: { text: string; value: Types.Sprache };
              status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
              type: { value: Types.RechtstraegerType; text: string };
              vertragSummary?: {
                heVertragCount: number;
                heVertragVerwaltungCount: number;
                mietVertragCount: number;
                mietVertragVerwaltungCount: number;
                weSubverwaltungCount: number;
                weVertragCount: number;
                weVertragVerwaltungCount: number;
              } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            sachbearbeiter?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              fileStorageInitialized: boolean;
              firmendatenId: string;
              firstname: string;
              funktionsModulList: Array<string>;
              lastname: string;
              mitarbeiterId: string;
              title?: string | null;
              titleTrailing?: string | null;
              username: string;
              contactPerson?: {
                contactPersonId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                firstname: string;
                lastname: string;
                tagList: Array<string>;
                title?: string | null;
                titleTrailing?: string | null;
                contactList: Array<{
                  contactId: string;
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  haupt?: boolean | null;
                  tagList: Array<string>;
                  type: string;
                  wert: string;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                }>;
                rechtstraeger?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rolleList: Array<{
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                name: string;
                rolleId: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
              status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
              user?: {
                email?: string | null;
                emailUnverified?: string | null;
                firstname?: string | null;
                lastname?: string | null;
                role: string;
                username: string;
                status: { text: string; value: string };
              } | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { text: string; value: Types.ObjektStatus; description?: string | null };
            verwaltungsart: { text: string; value: Types.Verwaltungsart };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
        }>;
        empfaengerBankDetails?: { accountHolder: string; bankDetailsId: string; bankname: string; bic: string; iban: string } | null;
        empfaengerRechtstraeger: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fibuKontoCreatable: boolean;
          kundennummer: string;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          bankDetailsList: Array<{
            accountHolder: string;
            accountLimit?: number | null;
            assignedHeVertragZahlungsregelMap: any;
            assignedObjektMap: any;
            bankDetailsId: string;
            bankname: string;
            bic: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            ebicsCreditTransferUserList: Array<string>;
            ebicsDirectDebitUserList: Array<string>;
            fibuKontoId?: string | null;
            fibuKontonummer?: string | null;
            iban: string;
            updatedByMitarbeiterId?: string | null;
            fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
            fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
            sepaMandat?: {
              fileId?: string | null;
              fileName?: string | null;
              sepaMandatId: string;
              sepaMandatReference: string;
              signatureDate?: string | null;
              firmendaten?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                firmendatenId: string;
                kundenSystemId: string;
                kurzBezeichnung: string;
                name1: string;
                name2?: string | null;
                name3?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rechnungsAussteller?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
              warningList: Array<{ message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          personenbezugList: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            personenbezugId: string;
            tagList: Array<string>;
            person: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechtstraegerSteuerpflicht?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            historicizedList?: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              rechtstraegerSteuerpflichtId: string;
              validFrom: string;
              steuerpflicht: { text: string; value: Types.Steuerpflicht };
              warningList: Array<{ message: string; type: string }>;
            }> | null;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
          sprache: { text: string; value: Types.Sprache };
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { value: Types.RechtstraegerType; text: string };
          vertragSummary?: {
            heVertragCount: number;
            heVertragVerwaltungCount: number;
            mietVertragCount: number;
            mietVertragVerwaltungCount: number;
            weSubverwaltungCount: number;
            weVertragCount: number;
            weVertragVerwaltungCount: number;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        fakturierungsperiode: { jahr: number; monat: number };
        fileInfo: { fileId: string; name: string; size: number };
        paymentMethod: { text: string; value: Types.PaymentMethod };
        status: { description?: string | null; text: string; value: Types.EigenbelegStatus };
        type: { text: string; value: Types.EigenbelegType };
        verrechnungsart: { text: string; value: Types.Verrechnungsart };
      } | null;
      empfaengerBankDetailsList: Array<{
        accountHolder: string;
        accountLimit?: number | null;
        assignedHeVertragZahlungsregelMap: any;
        assignedObjektMap: any;
        bankDetailsId: string;
        bankname: string;
        bic: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        ebicsCreditTransferUserList: Array<string>;
        ebicsDirectDebitUserList: Array<string>;
        fibuKontoId?: string | null;
        fibuKontonummer?: string | null;
        iban: string;
        updatedByMitarbeiterId?: string | null;
        fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
        fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
        sepaMandat?: {
          fileId?: string | null;
          fileName?: string | null;
          sepaMandatId: string;
          sepaMandatReference: string;
          signatureDate?: string | null;
          firmendaten?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firmendatenId: string;
            kundenSystemId: string;
            kurzBezeichnung: string;
            name1: string;
            name2?: string | null;
            name3?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechnungsAussteller?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
          warningList: Array<{ message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null>;
      fibuStatus?: { description?: string | null; text: string; value: Types.AuftragFibuStatus } | null;
      objektBestandseinheitIdDataList: Array<{ bestandseinheitId: string; objektId: string }>;
      originalMailDelivery?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        emailAddress: string;
        emailDeliveryDefinitionBezeichnung: string;
        emailDeliveryDefinitionId: string;
        mailId?: string | null;
        original: boolean;
        sender?: string | null;
        sentTs?: string | null;
        shouldNeverSend: boolean;
        shouldSend: boolean;
        updatedByMitarbeiterId?: string | null;
        vorschreibungMailDeliveryId: string;
        resentDeliveryList?: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          emailAddress: string;
          emailDeliveryDefinitionBezeichnung: string;
          emailDeliveryDefinitionId: string;
          mailId?: string | null;
          original: boolean;
          sender?: string | null;
          sentTs?: string | null;
          shouldNeverSend: boolean;
          shouldSend: boolean;
          updatedByMitarbeiterId?: string | null;
          vorschreibungMailDeliveryId: string;
          emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
          fakturierungsperiode: { jahr: number; monat: number };
          objektBestandseinheitVertragList: Array<{
            vertragId: string;
            bestandseinheit: {
              bestandseinheitId: string;
              bezeichnung: string;
              address: {
                addressId: string;
                city: string;
                cityAdditionalInformation?: string | null;
                countryCodeIso2: string;
                houseEntranceApartmentNumber?: string | null;
                postofficeBoxNumber?: string | null;
                postofficeBoxZipCode?: string | null;
                street?: string | null;
                type: Types.AddressType;
                zipCode?: string | null;
              };
            };
            objekt: { kurzBezeichnung: string; objektId: string };
          }>;
          recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
          vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }> | null;
        sourceDelivery?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          emailAddress: string;
          emailDeliveryDefinitionBezeichnung: string;
          emailDeliveryDefinitionId: string;
          mailId?: string | null;
          original: boolean;
          sender?: string | null;
          sentTs?: string | null;
          shouldNeverSend: boolean;
          shouldSend: boolean;
          updatedByMitarbeiterId?: string | null;
          vorschreibungMailDeliveryId: string;
          emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
          fakturierungsperiode: { jahr: number; monat: number };
          objektBestandseinheitVertragList: Array<{
            vertragId: string;
            bestandseinheit: {
              bestandseinheitId: string;
              bezeichnung: string;
              address: {
                addressId: string;
                city: string;
                cityAdditionalInformation?: string | null;
                countryCodeIso2: string;
                houseEntranceApartmentNumber?: string | null;
                postofficeBoxNumber?: string | null;
                postofficeBoxZipCode?: string | null;
                street?: string | null;
                type: Types.AddressType;
                zipCode?: string | null;
              };
            };
            objekt: { kurzBezeichnung: string; objektId: string };
          }>;
          recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
          vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
        fakturierungsperiode: { jahr: number; monat: number };
        objektBestandseinheitVertragList: Array<{
          vertragId: string;
          bestandseinheit: {
            bestandseinheitId: string;
            bezeichnung: string;
            address: {
              addressId: string;
              city: string;
              cityAdditionalInformation?: string | null;
              countryCodeIso2: string;
              houseEntranceApartmentNumber?: string | null;
              postofficeBoxNumber?: string | null;
              postofficeBoxZipCode?: string | null;
              street?: string | null;
              type: Types.AddressType;
              zipCode?: string | null;
            };
          };
          objekt: { kurzBezeichnung: string; objektId: string };
        }>;
        recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
        vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { description?: string | null; text: string; value: Types.AuftragStatus };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SteuertabelleQueryVariables = Types.Exact<{
  auftragId: Types.Scalars['ID']['input'];
}>;

export type SteuertabelleQuery = {
  getSteuertabelle: {
    data: {
      sumBrutto: number;
      sumNetto: number;
      sumUst: number;
      steuerzeileList: Array<{ steuersatz: number; sumBrutto: number; sumNetto: number; sumUst: number }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AuftragLinkListQueryVariables = Types.Exact<{
  auftragId: Types.Scalars['ID']['input'];
  includeCurrent?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type AuftragLinkListQuery = {
  getAuftragLinkList: {
    data: Array<{
      auftragId: string;
      ausstellerRechtstraegerId: string;
      belegFileId?: string | null;
      belegdatum?: string | null;
      belegnummer?: string | null;
      buchungIdList?: Array<string> | null;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current?: boolean | null;
      empfaengerRechtstraegerId: string;
      sumBrutto: number;
      sumNetto: number;
      sumUst: number;
      auftragsartType: { text: string; value: Types.AuftragsartType };
      dueDate: { text: string; value: Types.DueDateVorschreibungArt };
      verrechnungsart: { text: string; value: Types.Verrechnungsart };
      paymentMethod: { text: string; value: Types.PaymentMethod };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterAuftragEmpfaengerKundennummerListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FilterAuftragEmpfaengerKundennummerListQuery = {
  getFilterAuftragEmpfaengerKundennummerList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterAuftragAusstellerIbanListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FilterAuftragAusstellerIbanListQuery = {
  getFilterAuftragAusstellerIbanList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterAuftragEmpfaengerIbanListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FilterAuftragEmpfaengerIbanListQuery = {
  getFilterAuftragEmpfaengerIbanList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterAuftragBelegnummerListQueryVariables = Types.Exact<{
  belegnummer?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type FilterAuftragBelegnummerListQuery = {
  getFilterAuftragBelegnummerList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterAuftragVertragsartListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FilterAuftragVertragsartListQuery = {
  getFilterAuftragVertragsartList: {
    data: Array<{ text: string; value: Types.Vertragsart }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const AuftragListDocument = gql`
  query AuftragList(
    $auftragsPositionLabel: String
    $auftragsPositionLabelValue: String
    $auftragsartType: AuftragsartType
    $ausstellerBankDetailsId: ID
    $ausstellerRechtstraegerId: ID
    $ausstellerIban: String
    $belegform: String
    $belegnummer: String
    $bestandseinheitId: ID
    $bruttoFrom: Float
    $bruttoTo: Float
    $createTsFrom: String
    $createTsTo: String
    $dueDateVorschreibung: String
    $empfaengerBankDetailsId: String
    $empfaengerBankDetailsIdIsNull: Boolean
    $empfaengerRechtstraegerId: String
    $empfaengerIban: String
    $erstellerMitarbeiterIdList: [String!]
    $fakturierungsperiodeDateFrom: String
    $fakturierungsperiodeDateTo: String
    $fibuStatus: AuftragFibuStatus
    $generierlaufId: ID
    $includeStorniert: Boolean
    $includeStorno: Boolean
    $nettoFrom: Float
    $nettoTo: Float
    $objektId: ID
    $order: AuftragOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
    $paymentMethod: String
    $status: AuftragStatus
    $ustFrom: Float
    $ustTo: Float
    $verrechnungsart: String
    $vertragId: ID
    $vorschreibungspositionId: String
    $sepaCreditorId: String
    $sepaMandateReference: String
    $buchungsanweisungStatus: OrderBuchungsanweisungStatus
    $kundennummer: String
    $rechnungsDatumFrom: String
    $rechnungsDatumTo: String
    $dueDateFrom: String
    $dueDateTo: String
    $vertragsart: Vertragsart
  ) {
    getAuftragList(
      auftragsPositionLabel: $auftragsPositionLabel
      auftragsPositionLabelValue: $auftragsPositionLabelValue
      auftragsartType: $auftragsartType
      ausstellerBankDetailsId: $ausstellerBankDetailsId
      ausstellerRechtstraegerId: $ausstellerRechtstraegerId
      ausstellerIban: $ausstellerIban
      belegform: $belegform
      belegnummer: $belegnummer
      bestandseinheitId: $bestandseinheitId
      bruttoFrom: $bruttoFrom
      bruttoTo: $bruttoTo
      createTsFrom: $createTsFrom
      createTsTo: $createTsTo
      dueDateVorschreibung: $dueDateVorschreibung
      empfaengerBankDetailsId: $empfaengerBankDetailsId
      empfaengerBankDetailsIdIsNull: $empfaengerBankDetailsIdIsNull
      empfaengerRechtstraegerId: $empfaengerRechtstraegerId
      empfaengerIban: $empfaengerIban
      erstellerMitarbeiterIdList: $erstellerMitarbeiterIdList
      fakturierungsperiodeDateFrom: $fakturierungsperiodeDateFrom
      fakturierungsperiodeDateTo: $fakturierungsperiodeDateTo
      fibuStatus: $fibuStatus
      generierlaufId: $generierlaufId
      includeStorniert: $includeStorniert
      includeStorno: $includeStorno
      nettoFrom: $nettoFrom
      nettoTo: $nettoTo
      objektId: $objektId
      paymentMethod: $paymentMethod
      status: $status
      ustFrom: $ustFrom
      ustTo: $ustTo
      verrechnungsart: $verrechnungsart
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      sepaCreditorId: $sepaCreditorId
      sepaMandateReference: $sepaMandateReference
      buchungsanweisungStatus: $buchungsanweisungStatus
      kundennummer: $kundennummer
      rechnungsDatumFrom: $rechnungsDatumFrom
      rechnungsDatumTo: $rechnungsDatumTo
      dueDateFrom: $dueDateFrom
      dueDateTo: $dueDateTo
      vertragsart: $vertragsart
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          ...AuftragListEntry
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AuftragListEntryFragmentDoc}
`;
export function useAuftragListQuery(baseOptions?: Apollo.QueryHookOptions<AuftragListQuery, AuftragListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuftragListQuery, AuftragListQueryVariables>(AuftragListDocument, options);
}
export function useAuftragListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuftragListQuery, AuftragListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuftragListQuery, AuftragListQueryVariables>(AuftragListDocument, options);
}
export function useAuftragListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AuftragListQuery, AuftragListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuftragListQuery, AuftragListQueryVariables>(AuftragListDocument, options);
}
export type AuftragListQueryHookResult = ReturnType<typeof useAuftragListQuery>;
export type AuftragListLazyQueryHookResult = ReturnType<typeof useAuftragListLazyQuery>;
export type AuftragListSuspenseQueryHookResult = ReturnType<typeof useAuftragListSuspenseQuery>;
export type AuftragListQueryResult = Apollo.QueryResult<AuftragListQuery, AuftragListQueryVariables>;
export const AuftragListSummaryDocument = gql`
  query AuftragListSummary(
    $auftragsPositionLabel: String
    $auftragsPositionLabelValue: String
    $auftragsartType: AuftragsartType
    $ausstellerBankDetailsId: ID
    $ausstellerRechtstraegerId: ID
    $ausstellerIban: String
    $belegform: String
    $belegnummer: String
    $bestandseinheitId: ID
    $bruttoFrom: Float
    $bruttoTo: Float
    $createTsFrom: String
    $createTsTo: String
    $dueDateVorschreibung: String
    $empfaengerBankDetailsId: String
    $empfaengerBankDetailsIdIsNull: Boolean
    $empfaengerRechtstraegerId: String
    $empfaengerIban: String
    $erstellerMitarbeiterIdList: [String!]
    $fakturierungsperiodeDateFrom: String
    $fakturierungsperiodeDateTo: String
    $fibuStatus: AuftragFibuStatus
    $generierlaufId: ID
    $includeStorniert: Boolean
    $includeStorno: Boolean
    $nettoFrom: Float
    $nettoTo: Float
    $objektId: ID
    $paymentMethod: String
    $status: AuftragStatus
    $ustFrom: Float
    $ustTo: Float
    $verrechnungsart: String
    $vertragId: ID
    $vorschreibungspositionId: String
    $sepaCreditorId: String
    $sepaMandateReference: String
    $buchungsanweisungStatus: OrderBuchungsanweisungStatus
    $kundennummer: String
    $rechnungsDatumFrom: String
    $rechnungsDatumTo: String
    $dueDateFrom: String
    $dueDateTo: String
    $vertragsart: Vertragsart
  ) {
    getAuftragListSummary(
      auftragsPositionLabel: $auftragsPositionLabel
      auftragsPositionLabelValue: $auftragsPositionLabelValue
      auftragsartType: $auftragsartType
      ausstellerBankDetailsId: $ausstellerBankDetailsId
      ausstellerRechtstraegerId: $ausstellerRechtstraegerId
      ausstellerIban: $ausstellerIban
      belegform: $belegform
      belegnummer: $belegnummer
      bestandseinheitId: $bestandseinheitId
      bruttoFrom: $bruttoFrom
      bruttoTo: $bruttoTo
      createTsFrom: $createTsFrom
      createTsTo: $createTsTo
      dueDateVorschreibung: $dueDateVorschreibung
      empfaengerBankDetailsId: $empfaengerBankDetailsId
      empfaengerBankDetailsIdIsNull: $empfaengerBankDetailsIdIsNull
      empfaengerRechtstraegerId: $empfaengerRechtstraegerId
      empfaengerIban: $empfaengerIban
      erstellerMitarbeiterIdList: $erstellerMitarbeiterIdList
      fakturierungsperiodeDateFrom: $fakturierungsperiodeDateFrom
      fakturierungsperiodeDateTo: $fakturierungsperiodeDateTo
      fibuStatus: $fibuStatus
      generierlaufId: $generierlaufId
      includeStorniert: $includeStorniert
      includeStorno: $includeStorno
      nettoFrom: $nettoFrom
      nettoTo: $nettoTo
      objektId: $objektId
      paymentMethod: $paymentMethod
      status: $status
      ustFrom: $ustFrom
      ustTo: $ustTo
      verrechnungsart: $verrechnungsart
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      sepaCreditorId: $sepaCreditorId
      sepaMandateReference: $sepaMandateReference
      buchungsanweisungStatus: $buchungsanweisungStatus
      kundennummer: $kundennummer
      rechnungsDatumFrom: $rechnungsDatumFrom
      rechnungsDatumTo: $rechnungsDatumTo
      dueDateFrom: $dueDateFrom
      dueDateTo: $dueDateTo
      vertragsart: $vertragsart
    ) {
      data {
        sumBrutto
        sumNetto
        sumUst
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useAuftragListSummaryQuery(baseOptions?: Apollo.QueryHookOptions<AuftragListSummaryQuery, AuftragListSummaryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuftragListSummaryQuery, AuftragListSummaryQueryVariables>(AuftragListSummaryDocument, options);
}
export function useAuftragListSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuftragListSummaryQuery, AuftragListSummaryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuftragListSummaryQuery, AuftragListSummaryQueryVariables>(AuftragListSummaryDocument, options);
}
export function useAuftragListSummarySuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AuftragListSummaryQuery, AuftragListSummaryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuftragListSummaryQuery, AuftragListSummaryQueryVariables>(AuftragListSummaryDocument, options);
}
export type AuftragListSummaryQueryHookResult = ReturnType<typeof useAuftragListSummaryQuery>;
export type AuftragListSummaryLazyQueryHookResult = ReturnType<typeof useAuftragListSummaryLazyQuery>;
export type AuftragListSummarySuspenseQueryHookResult = ReturnType<typeof useAuftragListSummarySuspenseQuery>;
export type AuftragListSummaryQueryResult = Apollo.QueryResult<AuftragListSummaryQuery, AuftragListSummaryQueryVariables>;
export const AuftragDocument = gql`
  query Auftrag($auftragId: ID!) {
    getAuftrag(auftragId: $auftragId) {
      data {
        ...Auftrag
        stornoAuftrag {
          ...Auftrag
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AuftragFragmentDoc}
`;
export function useAuftragQuery(
  baseOptions: Apollo.QueryHookOptions<AuftragQuery, AuftragQueryVariables> &
    ({ variables: AuftragQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuftragQuery, AuftragQueryVariables>(AuftragDocument, options);
}
export function useAuftragLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuftragQuery, AuftragQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuftragQuery, AuftragQueryVariables>(AuftragDocument, options);
}
export function useAuftragSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AuftragQuery, AuftragQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuftragQuery, AuftragQueryVariables>(AuftragDocument, options);
}
export type AuftragQueryHookResult = ReturnType<typeof useAuftragQuery>;
export type AuftragLazyQueryHookResult = ReturnType<typeof useAuftragLazyQuery>;
export type AuftragSuspenseQueryHookResult = ReturnType<typeof useAuftragSuspenseQuery>;
export type AuftragQueryResult = Apollo.QueryResult<AuftragQuery, AuftragQueryVariables>;
export const SteuertabelleDocument = gql`
  query Steuertabelle($auftragId: ID!) {
    getSteuertabelle(auftragId: $auftragId) {
      data {
        steuerzeileList {
          steuersatz
          sumBrutto
          sumNetto
          sumUst
        }
        sumBrutto
        sumNetto
        sumUst
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSteuertabelleQuery(
  baseOptions: Apollo.QueryHookOptions<SteuertabelleQuery, SteuertabelleQueryVariables> &
    ({ variables: SteuertabelleQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SteuertabelleQuery, SteuertabelleQueryVariables>(SteuertabelleDocument, options);
}
export function useSteuertabelleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SteuertabelleQuery, SteuertabelleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SteuertabelleQuery, SteuertabelleQueryVariables>(SteuertabelleDocument, options);
}
export function useSteuertabelleSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SteuertabelleQuery, SteuertabelleQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SteuertabelleQuery, SteuertabelleQueryVariables>(SteuertabelleDocument, options);
}
export type SteuertabelleQueryHookResult = ReturnType<typeof useSteuertabelleQuery>;
export type SteuertabelleLazyQueryHookResult = ReturnType<typeof useSteuertabelleLazyQuery>;
export type SteuertabelleSuspenseQueryHookResult = ReturnType<typeof useSteuertabelleSuspenseQuery>;
export type SteuertabelleQueryResult = Apollo.QueryResult<SteuertabelleQuery, SteuertabelleQueryVariables>;
export const AuftragLinkListDocument = gql`
  query AuftragLinkList($auftragId: ID!, $includeCurrent: Boolean) {
    getAuftragLinkList(auftragId: $auftragId, includeCurrent: $includeCurrent) {
      data {
        auftragId
        auftragsartType {
          text
          value
        }
        ausstellerRechtstraegerId
        belegFileId
        belegdatum
        belegnummer
        buchungIdList
        createTs
        createdBy
        createdByMitarbeiterId
        current
        dueDate {
          text
          value
        }
        empfaengerRechtstraegerId
        sumBrutto
        sumNetto
        sumUst
        verrechnungsart {
          text
          value
        }
        paymentMethod {
          text
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useAuftragLinkListQuery(
  baseOptions: Apollo.QueryHookOptions<AuftragLinkListQuery, AuftragLinkListQueryVariables> &
    ({ variables: AuftragLinkListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuftragLinkListQuery, AuftragLinkListQueryVariables>(AuftragLinkListDocument, options);
}
export function useAuftragLinkListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuftragLinkListQuery, AuftragLinkListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuftragLinkListQuery, AuftragLinkListQueryVariables>(AuftragLinkListDocument, options);
}
export function useAuftragLinkListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AuftragLinkListQuery, AuftragLinkListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuftragLinkListQuery, AuftragLinkListQueryVariables>(AuftragLinkListDocument, options);
}
export type AuftragLinkListQueryHookResult = ReturnType<typeof useAuftragLinkListQuery>;
export type AuftragLinkListLazyQueryHookResult = ReturnType<typeof useAuftragLinkListLazyQuery>;
export type AuftragLinkListSuspenseQueryHookResult = ReturnType<typeof useAuftragLinkListSuspenseQuery>;
export type AuftragLinkListQueryResult = Apollo.QueryResult<AuftragLinkListQuery, AuftragLinkListQueryVariables>;
export const FilterAuftragEmpfaengerKundennummerListDocument = gql`
  query FilterAuftragEmpfaengerKundennummerList {
    getFilterAuftragEmpfaengerKundennummerList {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterAuftragEmpfaengerKundennummerListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterAuftragEmpfaengerKundennummerListQuery, FilterAuftragEmpfaengerKundennummerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterAuftragEmpfaengerKundennummerListQuery, FilterAuftragEmpfaengerKundennummerListQueryVariables>(
    FilterAuftragEmpfaengerKundennummerListDocument,
    options
  );
}
export function useFilterAuftragEmpfaengerKundennummerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterAuftragEmpfaengerKundennummerListQuery, FilterAuftragEmpfaengerKundennummerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterAuftragEmpfaengerKundennummerListQuery, FilterAuftragEmpfaengerKundennummerListQueryVariables>(
    FilterAuftragEmpfaengerKundennummerListDocument,
    options
  );
}
export function useFilterAuftragEmpfaengerKundennummerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterAuftragEmpfaengerKundennummerListQuery, FilterAuftragEmpfaengerKundennummerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterAuftragEmpfaengerKundennummerListQuery, FilterAuftragEmpfaengerKundennummerListQueryVariables>(
    FilterAuftragEmpfaengerKundennummerListDocument,
    options
  );
}
export type FilterAuftragEmpfaengerKundennummerListQueryHookResult = ReturnType<typeof useFilterAuftragEmpfaengerKundennummerListQuery>;
export type FilterAuftragEmpfaengerKundennummerListLazyQueryHookResult = ReturnType<typeof useFilterAuftragEmpfaengerKundennummerListLazyQuery>;
export type FilterAuftragEmpfaengerKundennummerListSuspenseQueryHookResult = ReturnType<
  typeof useFilterAuftragEmpfaengerKundennummerListSuspenseQuery
>;
export type FilterAuftragEmpfaengerKundennummerListQueryResult = Apollo.QueryResult<
  FilterAuftragEmpfaengerKundennummerListQuery,
  FilterAuftragEmpfaengerKundennummerListQueryVariables
>;
export const FilterAuftragAusstellerIbanListDocument = gql`
  query FilterAuftragAusstellerIbanList {
    getFilterAuftragAusstellerIbanList {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterAuftragAusstellerIbanListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterAuftragAusstellerIbanListQuery, FilterAuftragAusstellerIbanListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterAuftragAusstellerIbanListQuery, FilterAuftragAusstellerIbanListQueryVariables>(
    FilterAuftragAusstellerIbanListDocument,
    options
  );
}
export function useFilterAuftragAusstellerIbanListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterAuftragAusstellerIbanListQuery, FilterAuftragAusstellerIbanListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterAuftragAusstellerIbanListQuery, FilterAuftragAusstellerIbanListQueryVariables>(
    FilterAuftragAusstellerIbanListDocument,
    options
  );
}
export function useFilterAuftragAusstellerIbanListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterAuftragAusstellerIbanListQuery, FilterAuftragAusstellerIbanListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterAuftragAusstellerIbanListQuery, FilterAuftragAusstellerIbanListQueryVariables>(
    FilterAuftragAusstellerIbanListDocument,
    options
  );
}
export type FilterAuftragAusstellerIbanListQueryHookResult = ReturnType<typeof useFilterAuftragAusstellerIbanListQuery>;
export type FilterAuftragAusstellerIbanListLazyQueryHookResult = ReturnType<typeof useFilterAuftragAusstellerIbanListLazyQuery>;
export type FilterAuftragAusstellerIbanListSuspenseQueryHookResult = ReturnType<typeof useFilterAuftragAusstellerIbanListSuspenseQuery>;
export type FilterAuftragAusstellerIbanListQueryResult = Apollo.QueryResult<
  FilterAuftragAusstellerIbanListQuery,
  FilterAuftragAusstellerIbanListQueryVariables
>;
export const FilterAuftragEmpfaengerIbanListDocument = gql`
  query FilterAuftragEmpfaengerIbanList {
    getFilterAuftragEmpfaengerIbanList {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterAuftragEmpfaengerIbanListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterAuftragEmpfaengerIbanListQuery, FilterAuftragEmpfaengerIbanListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterAuftragEmpfaengerIbanListQuery, FilterAuftragEmpfaengerIbanListQueryVariables>(
    FilterAuftragEmpfaengerIbanListDocument,
    options
  );
}
export function useFilterAuftragEmpfaengerIbanListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterAuftragEmpfaengerIbanListQuery, FilterAuftragEmpfaengerIbanListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterAuftragEmpfaengerIbanListQuery, FilterAuftragEmpfaengerIbanListQueryVariables>(
    FilterAuftragEmpfaengerIbanListDocument,
    options
  );
}
export function useFilterAuftragEmpfaengerIbanListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterAuftragEmpfaengerIbanListQuery, FilterAuftragEmpfaengerIbanListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterAuftragEmpfaengerIbanListQuery, FilterAuftragEmpfaengerIbanListQueryVariables>(
    FilterAuftragEmpfaengerIbanListDocument,
    options
  );
}
export type FilterAuftragEmpfaengerIbanListQueryHookResult = ReturnType<typeof useFilterAuftragEmpfaengerIbanListQuery>;
export type FilterAuftragEmpfaengerIbanListLazyQueryHookResult = ReturnType<typeof useFilterAuftragEmpfaengerIbanListLazyQuery>;
export type FilterAuftragEmpfaengerIbanListSuspenseQueryHookResult = ReturnType<typeof useFilterAuftragEmpfaengerIbanListSuspenseQuery>;
export type FilterAuftragEmpfaengerIbanListQueryResult = Apollo.QueryResult<
  FilterAuftragEmpfaengerIbanListQuery,
  FilterAuftragEmpfaengerIbanListQueryVariables
>;
export const FilterAuftragBelegnummerListDocument = gql`
  query FilterAuftragBelegnummerList($belegnummer: String) {
    getFilterAuftragBelegnummerList(belegnummer: $belegnummer) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterAuftragBelegnummerListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterAuftragBelegnummerListQuery, FilterAuftragBelegnummerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterAuftragBelegnummerListQuery, FilterAuftragBelegnummerListQueryVariables>(
    FilterAuftragBelegnummerListDocument,
    options
  );
}
export function useFilterAuftragBelegnummerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterAuftragBelegnummerListQuery, FilterAuftragBelegnummerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterAuftragBelegnummerListQuery, FilterAuftragBelegnummerListQueryVariables>(
    FilterAuftragBelegnummerListDocument,
    options
  );
}
export function useFilterAuftragBelegnummerListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterAuftragBelegnummerListQuery, FilterAuftragBelegnummerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterAuftragBelegnummerListQuery, FilterAuftragBelegnummerListQueryVariables>(
    FilterAuftragBelegnummerListDocument,
    options
  );
}
export type FilterAuftragBelegnummerListQueryHookResult = ReturnType<typeof useFilterAuftragBelegnummerListQuery>;
export type FilterAuftragBelegnummerListLazyQueryHookResult = ReturnType<typeof useFilterAuftragBelegnummerListLazyQuery>;
export type FilterAuftragBelegnummerListSuspenseQueryHookResult = ReturnType<typeof useFilterAuftragBelegnummerListSuspenseQuery>;
export type FilterAuftragBelegnummerListQueryResult = Apollo.QueryResult<
  FilterAuftragBelegnummerListQuery,
  FilterAuftragBelegnummerListQueryVariables
>;
export const FilterAuftragVertragsartListDocument = gql`
  query FilterAuftragVertragsartList {
    getFilterAuftragVertragsartList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterAuftragVertragsartListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterAuftragVertragsartListQuery, FilterAuftragVertragsartListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterAuftragVertragsartListQuery, FilterAuftragVertragsartListQueryVariables>(
    FilterAuftragVertragsartListDocument,
    options
  );
}
export function useFilterAuftragVertragsartListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterAuftragVertragsartListQuery, FilterAuftragVertragsartListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterAuftragVertragsartListQuery, FilterAuftragVertragsartListQueryVariables>(
    FilterAuftragVertragsartListDocument,
    options
  );
}
export function useFilterAuftragVertragsartListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterAuftragVertragsartListQuery, FilterAuftragVertragsartListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterAuftragVertragsartListQuery, FilterAuftragVertragsartListQueryVariables>(
    FilterAuftragVertragsartListDocument,
    options
  );
}
export type FilterAuftragVertragsartListQueryHookResult = ReturnType<typeof useFilterAuftragVertragsartListQuery>;
export type FilterAuftragVertragsartListLazyQueryHookResult = ReturnType<typeof useFilterAuftragVertragsartListLazyQuery>;
export type FilterAuftragVertragsartListSuspenseQueryHookResult = ReturnType<typeof useFilterAuftragVertragsartListSuspenseQuery>;
export type FilterAuftragVertragsartListQueryResult = Apollo.QueryResult<
  FilterAuftragVertragsartListQuery,
  FilterAuftragVertragsartListQueryVariables
>;
