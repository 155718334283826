import React from 'react';
import { Typography } from 'antd';
import { useParams } from 'react-router-dom';
import AdminAuditLogTable from './AdminAuditLogTable';

type RouteParams = { username: string };

const AdminAuditLogListing = () => {
  const { username } = useParams() as RouteParams;

  return (
    <>
      <Typography.Title level={4}>AuditLogs</Typography.Title>
      <AdminAuditLogTable username={username} />
    </>
  );
};

export default AdminAuditLogListing;
