import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { InfoMailDelivery } from '../../../../types';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import { generatePathToEmailVersandDetailsPage } from '../../emailDeliveryUriPaths';

type Props = {
  emailDelivery: InfoMailDelivery;
};

const EmailDelivery: FC<Props> = ({ emailDelivery }) => {
  const content = (
    <Space direction="vertical" size={0}>
      <DataWithShortenedText text={emailDelivery.emailAddress} maxTextLength={25}>
        {(shortenedText) => shortenedText}
      </DataWithShortenedText>
      {emailDelivery.sentTs && (
        <Typography.Text style={{ color: 'inherit' }}>
          (<FormattedDateTime createdTs={emailDelivery.sentTs} />)
        </Typography.Text>
      )}
    </Space>
  );

  return emailDelivery.mailId ? (
    <Link style={{ display: 'block' }} to={generatePathToEmailVersandDetailsPage(emailDelivery.mailId)}>
      {content}
    </Link>
  ) : (
    content
  );
};

export default EmailDelivery;
