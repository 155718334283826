import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { InputNumber, Switch } from 'formik-antd';
import { useField } from 'formik';
import { mahnstufenFormFields } from '../../mahndefinitionFormMapper';
import FormattedDecimalFormInput from '../../../../components/Input-antd/FormattedDecimalFormInput';
import ParamFormItem from '../../../../components/Form/ParamFormItem';

type Props = {
  name: string;
  setIsVersandEinschreiben?: (value: boolean) => void;
};

const MahndefinitionParamFormMahnstufeCommonConfig: FC<Props> = ({ name, setIsVersandEinschreiben }) => {
  const mahnstufeFieldName = (fieldName: string) => `${name}.${fieldName}`;

  const [, { value: verzugszinsenBerechnen }] = useField<boolean>(mahnstufeFieldName(mahnstufenFormFields.verzugszinsenBerechnen));
  const [, { value: versandEinschreiben }] = useField<boolean>(mahnstufeFieldName(mahnstufenFormFields.versandEinschreiben));

  return (
    <>
      <ParamFormItem name={mahnstufeFieldName(mahnstufenFormFields.minBetrag)} label="Mindestbetrag" textAfter="€">
        <FormattedDecimalFormInput
          name={mahnstufeFieldName(mahnstufenFormFields.minBetrag)}
          placeholder="Mindestbetrag"
          size="small"
          style={{ width: '100%' }}
          min={0}
          controls={false}
        />
      </ParamFormItem>

      <ParamFormItem name={mahnstufeFieldName(mahnstufenFormFields.naechsteMahnungNachTage)} label="Nächste Mahnung nach" textAfter="Tage">
        <InputNumber
          name={mahnstufeFieldName(mahnstufenFormFields.naechsteMahnungNachTage)}
          placeholder="Nächste Mahnung nach"
          size="small"
          style={{ width: '100%' }}
          precision={0}
          prefix=""
          min={0}
          controls={false}
        />
      </ParamFormItem>

      <ParamFormItem name={mahnstufeFieldName(mahnstufenFormFields.verzugszinsenBerechnen)} label="Verzugszinsen berechnen">
        <Space>
          <Switch
            name={mahnstufeFieldName(mahnstufenFormFields.verzugszinsenBerechnen)}
            id={mahnstufeFieldName(mahnstufenFormFields.verzugszinsenBerechnen)}
            size="small"
          />
          <Typography.Text>{verzugszinsenBerechnen ? 'Ja' : 'Nein'}</Typography.Text>
        </Space>
      </ParamFormItem>

      <ParamFormItem name={mahnstufeFieldName(mahnstufenFormFields.versandEinschreiben)} label="Versand per Einschreiben">
        <Space>
          <Switch
            name={mahnstufeFieldName(mahnstufenFormFields.versandEinschreiben)}
            id={mahnstufeFieldName(mahnstufenFormFields.versandEinschreiben)}
            size="small"
            onChange={setIsVersandEinschreiben}
          />
          <Typography.Text>{versandEinschreiben ? 'Ja' : 'Nein'}</Typography.Text>
        </Space>
      </ParamFormItem>
    </>
  );
};

export default MahndefinitionParamFormMahnstufeCommonConfig;
