import React, { FC } from 'react';
import { Typography } from 'antd';
import { EuroAmount } from '../../../components/Number';

// @ts-ignore
import { ReactComponent as SigmaIcon } from './assets/sigma.svg';
import { StyledAvailableAmountSpace } from './styled/StyledAvailableAmountSpace';

type Props = {
  text: string;
  value: number;
};

export const AvailableAmountText: FC<Props> = ({ text, value }) => {
  return (
    <StyledAvailableAmountSpace>
      <SigmaIcon style={{ maxWidth: '12px', maxHeight: '12px', fill: 'rgba(0, 0, 0, 0.85)' }} />
      <Typography.Text>{text}:</Typography.Text>
      <EuroAmount value={value} />
    </StyledAvailableAmountSpace>
  );
};
