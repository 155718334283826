import { FormFields } from '../../../../../../helpers/formikHelper';
import { InfoFeldFormValues } from '../../../../../shared/InfoFeld/infoFeldFormMapper';
import { BeInfoFeld_BeInfoFeldKellerabteil_Fragment } from '../../../gql/BeInfoFeldFragments.types';

export interface KellerabteilUpdateFormValues extends InfoFeldFormValues {
  nummer: string;
  text?: string;
  deleteDocumentFileIdList: string[];
}

export const kellerabteilUpdateFormFields: FormFields<KellerabteilUpdateFormValues> = {
  nummer: 'nummer',
  text: 'text',
  documentList: 'documentList',
  deleteDocumentFileIdList: 'deleteDocumentFileIdList',
};

export const mapToUpdateFormValues = (infoFeld: BeInfoFeld_BeInfoFeldKellerabteil_Fragment): KellerabteilUpdateFormValues => ({
  documentList: [],
  deleteDocumentFileIdList: [],
  nummer: infoFeld.kellerabteilNummer,
  text: infoFeld.text ?? undefined,
});
