import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import { URI_VERARBEITUNG_WE_TOP_ABRECHNUNG_DETAILS_PAGE } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import WeTopAbrDetailsPage from './WeTopAbrDetailsPage';

export type VerarbeitungTopAbrDetailsPageRouteParams = { generierlaufId: string };

const verarbeitungTopAbrDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_WE_TOP_ABRECHNUNG_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<WeTopAbrDetailsPage />} />}
  />
);

export default verarbeitungTopAbrDetailsPageRoute;
