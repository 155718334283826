import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
export type VertragBaseFieldsFragment = {
  bestandseinheitId: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  kurzBezeichnung: string;
  objektId: string;
  vertragId: string;
  vertragsBeginn: string;
  status: { text: string; value: Types.VertragStatus; description?: string | null };
  vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
  vertragspartner: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const VertragBaseFieldsFragmentDoc = gql`
  fragment VertragBaseFields on VertragBase {
    bestandseinheitId
    createTs
    createdBy
    createdByMitarbeiterId
    kurzBezeichnung
    objektId
    status {
      text
      value
      description
    }
    vertragId
    vertragsBeginn
    vertragsart {
      supportsExplicitVertragsEnde
      text
      value
    }
    vertragspartner {
      ...RechtstraegerBaseFields
    }
    warningList {
      attribute
      message
      type
    }
  }
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
