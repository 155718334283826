import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import { useHeVertragZahlungsregelPeriodeListQuery } from '../gql/HeVertragZahlungsregelQueries.types';

const ZahlungsregelPeriodeSelect: FC<SelectProps> = ({ ...restProps }) => {
  const { data, loading } = useHeVertragZahlungsregelPeriodeListQuery();
  const zahlungsregelPeriodeList = data?.getHeVertragZahlungsregelPeriodeList.data;

  return (
    <Select loading={loading} size="small" allowClear filterOption={selectFilterOption} {...restProps}>
      {zahlungsregelPeriodeList?.map((periode) => (
        <Select.Option key={periode.value} value={periode.value}>
          {periode?.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ZahlungsregelPeriodeSelect;
