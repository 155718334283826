import React from 'react';
import { Space, Tooltip } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import { TicketAttachedEmail } from '../../ticketHelpers';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';

const emailTooltipContent = (email?: TicketAttachedEmail) =>
  email ? (
    <Space>
      <MailOutlined />
      <Space size={4}>
        Aus E-mail: <DataWithShortenedText maxTextLength={15} text={email.sender || ''} />-
        <DataWithShortenedText maxTextLength={15} text={email.subject || '(Kein Betreff)'} />-
        <Tooltip title={<FormattedDateTime createdTs={email.sentTs ?? null} />}>{dayjsCustom(email.sentTs).fromNow()}</Tooltip>
      </Space>
    </Space>
  ) : null;

export default emailTooltipContent;
