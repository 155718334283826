import React, { FC, useEffect, useRef, useState } from 'react';
import { Col, Input, Row, Space, Tag, Typography } from 'antd';
import { Spacer } from '../../../../Grid';
import useDebounce from '../../../../../hooks/useDebounce';
import { isNotNil } from '../../../../../helpers/assertionHelper';

export type TextbausteinInputTextAreaProps = {
  value: string;
  id?: string;
  onChange: (value: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  platzhalterList: { platzhalter: string; example: string }[];
  disabled?: boolean;
};

const TextbausteinInputTextArea: FC<TextbausteinInputTextAreaProps> = ({ value, id, onChange, platzhalterList, disabled, onBlur }) => {
  const textArea = useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState(value);
  const [caretPosition, setCaretPosition] = useState<number>(0);
  const debouncedText = useDebounce(text);

  const pastePlatzhalterToTextArea = (platzhalter: string) => {
    const platzhalterFormatted = `$\{${platzhalter}}`;
    const newValue = isNotNil(text) ? [text.slice(0, caretPosition), platzhalterFormatted, text.slice(caretPosition)].join('') : '';
    setText(newValue);
    textArea.current?.focus();
  };

  useEffect(() => {
    onChange(debouncedText as string);
    // if we define onChange as a dep we force the clients to use useCallback or sg like that to assure that onChange won't change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedText]);

  return (
    <Row>
      <Col style={{ width: '100%' }}>
        <Input.TextArea
          id={id}
          disabled={disabled}
          ref={textArea}
          rows={4}
          value={text}
          onChange={(e) => setText(e.target.value)}
          onSelect={(e) => setCaretPosition((e.target as any).selectionStart)}
          onBlur={onBlur}
        />
      </Col>
      <Spacer />
      <Space direction="vertical">
        <Typography.Text type="secondary">{platzhalterList.length > 0 ? 'Verfügbare Platzhalter' : 'Keine verfügbaren Platzhalter'}</Typography.Text>
        {platzhalterList.length ? (
          <Row gutter={[8, 8]}>
            {platzhalterList.map((item, index) => (
              <Col key={index}>
                <Tag
                  onClick={() => !disabled && pastePlatzhalterToTextArea(item.platzhalter)}
                  style={!disabled ? { cursor: 'pointer' } : { cursor: 'not-allowed', pointerEvents: 'none' }}
                >
                  {item.platzhalter}
                </Tag>
              </Col>
            ))}
          </Row>
        ) : (
          <></>
        )}
      </Space>
    </Row>
  );
};

export default TextbausteinInputTextArea;
