import { Form } from 'formik-antd';
import React, { FC } from 'react';
import { Formik } from 'formik';
import { ObjAbrDefGeneralSettingsFormValues } from './objAbrDefGeneralSettingsFormMapper';
import DisplayEinnahmenFormItem from './DisplayEinnahmenFormItem';
import ConsolidateEinnahmenFormItem from './ConsolidateEinnahmenFormItem';
import DisplayKontonummerFormItem from './DisplayKontonummerFormItem';
import BuchungsdetailsFormItem from './BuchungsdetailsFormItem';
import { BkOrWeAbrDefObjektOutputOptionsFieldHelpNames } from '../../../../../abrDefFieldHelpHelpers/abrDefFieldHelpMapper';
import { StyledWizardSpaceForContent } from '../../../../../../shared/styled/WizardSpace.style';

type Props = {
  values: ObjAbrDefGeneralSettingsFormValues;
  onSubmit?: (formValues: ObjAbrDefGeneralSettingsFormValues) => void;
  fieldHelpNames?: BkOrWeAbrDefObjektOutputOptionsFieldHelpNames;
};

const ObjAbrDefGeneralSettingsForm: FC<Props> = ({ values, onSubmit, fieldHelpNames }) => {
  const isDisabled = !onSubmit;
  return (
    <>
      <Formik<ObjAbrDefGeneralSettingsFormValues> initialValues={values} onSubmit={() => {}}>
        {(formikProps) => (
          <Form>
            <StyledWizardSpaceForContent direction="vertical">
              <DisplayEinnahmenFormItem
                formikProps={formikProps}
                isDisabled={isDisabled}
                onChange={onSubmit}
                fieldHelp={fieldHelpNames?.displayEinnahmen}
              />
              <ConsolidateEinnahmenFormItem
                formikProps={formikProps}
                isDisabled={isDisabled}
                onChange={onSubmit}
                fieldHelp={fieldHelpNames?.consolidateEinnahmen}
              />
              <DisplayKontonummerFormItem
                formikProps={formikProps}
                isDisabled={isDisabled}
                onChange={onSubmit}
                fieldHelp={fieldHelpNames?.displayKontonummer}
              />
              <BuchungsdetailsFormItem
                formikProps={formikProps}
                isDisabled={isDisabled}
                onChange={onSubmit}
                fieldHelp={fieldHelpNames?.buchungsdetails}
              />
            </StyledWizardSpaceForContent>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ObjAbrDefGeneralSettingsForm;
