import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { KontierungstabelleFieldsFragmentDoc } from '../../Kontierungstabelle/gql/KontierungstabelleFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateKontierungstabelleTemplateMutationVariables = Types.Exact<{
  input: Types.KontierungstabelleCreateInput;
}>;

export type CreateKontierungstabelleTemplateMutation = {
  createKontierungstabelleTemplate: {
    data: {
      basiskonto: number;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kontierungstabelleId: string;
      updatedByMitarbeiterId?: string | null;
      errechnetesErloesKontoList: Array<{ buchungsKZ?: string | null; erloeskonto: number; firmendatenId?: string | null; steuersatz: number }>;
      status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
      subAdministrationExpenseAccount?: {
        bezeichnung: string;
        firmendatenId?: string | null;
        kontoId: string;
        kontoKlasse: string;
        nummer: string;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateKontierungstabelleTemplateMutationVariables = Types.Exact<{
  kontierungstabelleId: Types.Scalars['ID']['input'];
  input: Types.KontierungstabelleUpdateInput;
}>;

export type UpdateKontierungstabelleTemplateMutation = {
  updateKontierungstabelleTemplate: {
    data: {
      basiskonto: number;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kontierungstabelleId: string;
      updatedByMitarbeiterId?: string | null;
      errechnetesErloesKontoList: Array<{ buchungsKZ?: string | null; erloeskonto: number; firmendatenId?: string | null; steuersatz: number }>;
      status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
      subAdministrationExpenseAccount?: {
        bezeichnung: string;
        firmendatenId?: string | null;
        kontoId: string;
        kontoKlasse: string;
        nummer: string;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteKontierungstabelleTemplateMutationVariables = Types.Exact<{
  kontierungstabelleId: Types.Scalars['ID']['input'];
}>;

export type DeleteKontierungstabelleTemplateMutation = {
  deleteKontierungstabelleTemplate: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type ArchiveKontierungstabelleTemplateMutationVariables = Types.Exact<{
  kontierungstabelleId: Types.Scalars['ID']['input'];
}>;

export type ArchiveKontierungstabelleTemplateMutation = {
  actionArchiveKontierungstabelleTemplate: {
    data: {
      basiskonto: number;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kontierungstabelleId: string;
      updatedByMitarbeiterId?: string | null;
      errechnetesErloesKontoList: Array<{ buchungsKZ?: string | null; erloeskonto: number; firmendatenId?: string | null; steuersatz: number }>;
      status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
      subAdministrationExpenseAccount?: {
        bezeichnung: string;
        firmendatenId?: string | null;
        kontoId: string;
        kontoKlasse: string;
        nummer: string;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveKontierungstabelleTemplateMutationVariables = Types.Exact<{
  kontierungstabelleId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveKontierungstabelleTemplateMutation = {
  actionUnarchiveKontierungstabelleTemplate: {
    data: {
      basiskonto: number;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kontierungstabelleId: string;
      updatedByMitarbeiterId?: string | null;
      errechnetesErloesKontoList: Array<{ buchungsKZ?: string | null; erloeskonto: number; firmendatenId?: string | null; steuersatz: number }>;
      status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
      subAdministrationExpenseAccount?: {
        bezeichnung: string;
        firmendatenId?: string | null;
        kontoId: string;
        kontoKlasse: string;
        nummer: string;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateKontierungstabelleTemplateDocument = gql`
  mutation CreateKontierungstabelleTemplate($input: KontierungstabelleCreateInput!) {
    createKontierungstabelleTemplate(input: $input) {
      data {
        ...KontierungstabelleFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KontierungstabelleFieldsFragmentDoc}
`;
export function useCreateKontierungstabelleTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateKontierungstabelleTemplateMutation, CreateKontierungstabelleTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateKontierungstabelleTemplateMutation, CreateKontierungstabelleTemplateMutationVariables>(
    CreateKontierungstabelleTemplateDocument,
    options
  );
}
export type CreateKontierungstabelleTemplateMutationHookResult = ReturnType<typeof useCreateKontierungstabelleTemplateMutation>;
export type CreateKontierungstabelleTemplateMutationResult = Apollo.MutationResult<CreateKontierungstabelleTemplateMutation>;
export type CreateKontierungstabelleTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateKontierungstabelleTemplateMutation,
  CreateKontierungstabelleTemplateMutationVariables
>;
export const UpdateKontierungstabelleTemplateDocument = gql`
  mutation UpdateKontierungstabelleTemplate($kontierungstabelleId: ID!, $input: KontierungstabelleUpdateInput!) {
    updateKontierungstabelleTemplate(kontierungstabelleId: $kontierungstabelleId, input: $input) {
      data {
        ...KontierungstabelleFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KontierungstabelleFieldsFragmentDoc}
`;
export function useUpdateKontierungstabelleTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateKontierungstabelleTemplateMutation, UpdateKontierungstabelleTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateKontierungstabelleTemplateMutation, UpdateKontierungstabelleTemplateMutationVariables>(
    UpdateKontierungstabelleTemplateDocument,
    options
  );
}
export type UpdateKontierungstabelleTemplateMutationHookResult = ReturnType<typeof useUpdateKontierungstabelleTemplateMutation>;
export type UpdateKontierungstabelleTemplateMutationResult = Apollo.MutationResult<UpdateKontierungstabelleTemplateMutation>;
export type UpdateKontierungstabelleTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateKontierungstabelleTemplateMutation,
  UpdateKontierungstabelleTemplateMutationVariables
>;
export const DeleteKontierungstabelleTemplateDocument = gql`
  mutation DeleteKontierungstabelleTemplate($kontierungstabelleId: ID!) {
    deleteKontierungstabelleTemplate(kontierungstabelleId: $kontierungstabelleId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteKontierungstabelleTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteKontierungstabelleTemplateMutation, DeleteKontierungstabelleTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteKontierungstabelleTemplateMutation, DeleteKontierungstabelleTemplateMutationVariables>(
    DeleteKontierungstabelleTemplateDocument,
    options
  );
}
export type DeleteKontierungstabelleTemplateMutationHookResult = ReturnType<typeof useDeleteKontierungstabelleTemplateMutation>;
export type DeleteKontierungstabelleTemplateMutationResult = Apollo.MutationResult<DeleteKontierungstabelleTemplateMutation>;
export type DeleteKontierungstabelleTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteKontierungstabelleTemplateMutation,
  DeleteKontierungstabelleTemplateMutationVariables
>;
export const ArchiveKontierungstabelleTemplateDocument = gql`
  mutation ArchiveKontierungstabelleTemplate($kontierungstabelleId: ID!) {
    actionArchiveKontierungstabelleTemplate(kontierungstabelleId: $kontierungstabelleId) {
      data {
        ...KontierungstabelleFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KontierungstabelleFieldsFragmentDoc}
`;
export function useArchiveKontierungstabelleTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveKontierungstabelleTemplateMutation, ArchiveKontierungstabelleTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveKontierungstabelleTemplateMutation, ArchiveKontierungstabelleTemplateMutationVariables>(
    ArchiveKontierungstabelleTemplateDocument,
    options
  );
}
export type ArchiveKontierungstabelleTemplateMutationHookResult = ReturnType<typeof useArchiveKontierungstabelleTemplateMutation>;
export type ArchiveKontierungstabelleTemplateMutationResult = Apollo.MutationResult<ArchiveKontierungstabelleTemplateMutation>;
export type ArchiveKontierungstabelleTemplateMutationOptions = Apollo.BaseMutationOptions<
  ArchiveKontierungstabelleTemplateMutation,
  ArchiveKontierungstabelleTemplateMutationVariables
>;
export const UnarchiveKontierungstabelleTemplateDocument = gql`
  mutation UnarchiveKontierungstabelleTemplate($kontierungstabelleId: ID!) {
    actionUnarchiveKontierungstabelleTemplate(kontierungstabelleId: $kontierungstabelleId) {
      data {
        ...KontierungstabelleFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KontierungstabelleFieldsFragmentDoc}
`;
export function useUnarchiveKontierungstabelleTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveKontierungstabelleTemplateMutation, UnarchiveKontierungstabelleTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveKontierungstabelleTemplateMutation, UnarchiveKontierungstabelleTemplateMutationVariables>(
    UnarchiveKontierungstabelleTemplateDocument,
    options
  );
}
export type UnarchiveKontierungstabelleTemplateMutationHookResult = ReturnType<typeof useUnarchiveKontierungstabelleTemplateMutation>;
export type UnarchiveKontierungstabelleTemplateMutationResult = Apollo.MutationResult<UnarchiveKontierungstabelleTemplateMutation>;
export type UnarchiveKontierungstabelleTemplateMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveKontierungstabelleTemplateMutation,
  UnarchiveKontierungstabelleTemplateMutationVariables
>;
