import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { Switch } from 'formik-antd';

type Props = {
  setShowUmbuchungen: (shouldShowUmbuchungen: boolean) => void;
  showUmbuchungen: boolean;
};

const BzShowUmbuchungenSwitch: FC<Props> = ({ setShowUmbuchungen, showUmbuchungen }) => (
  <Space direction="vertical">
    <Typography.Text>Umbuchungen anzeigen</Typography.Text>
    <Space>
      <Switch
        size="small"
        id="showUmbuchungen"
        name="showUmbuchungen"
        checked={showUmbuchungen}
        onChange={(checked) => setShowUmbuchungen(checked)}
      />
      <Typography.Text>{showUmbuchungen ? 'Ja' : 'Nein'}</Typography.Text>
    </Space>
  </Space>
);

export default BzShowUmbuchungenSwitch;
