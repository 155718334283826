import { buildBelegTexteHauptAddress } from '../../../Belegtext/Form/belegTexteFormHelpers';
import { BelegTextePlatzhalter } from '../../../Belegtext/Form/belegTexteFormTypes';
import { AddressesAndContacts, Firmendaten } from '../../../../types';

export const getAuftragsartBelegKopfFussPlatzhalter = (
  firmendaten?: Firmendaten,
  addressesAndContacts?: AddressesAndContacts
): BelegTextePlatzhalter => ({
  hauptAddress: findHauptAddress(addressesAndContacts),
  vatIdentificationNumber: firmendaten?.vatIdentificationNumber,
  companyRegistrationNumber: firmendaten?.companyRegistrationNumber,
  phoneNumber: findPhoneNumber(addressesAndContacts),
  email: findHauptEmail(addressesAndContacts),
});

const findHauptAddress = (addressesAndContacts?: AddressesAndContacts) => {
  const hauptAddress = addressesAndContacts?.hauptAddress;
  return hauptAddress ? buildBelegTexteHauptAddress(hauptAddress) : undefined;
};

const findPhoneNumber = (addressesAndContacts?: AddressesAndContacts) => {
  const hauptPhoneNumber = addressesAndContacts?.hauptPhoneContact;
  return hauptPhoneNumber?.wert;
};

const findHauptEmail = (addressesAndContacts?: AddressesAndContacts) => {
  const hauptEmail = addressesAndContacts?.hauptEmailContact;
  return hauptEmail?.wert;
};
