import { FC } from 'react';
import { Space, Typography } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import InfoFeldListItem from '../InfoFeldListItem';
import WeiterePersonListItemActions from './WeiterePersonListItemActions';
import PersonInfoWithLinkToDetails from './PersonInfoWithLinkToDetails';
import { VertragInfoFeld_VertragInfoFeldWeiterePerson_Fragment } from '../../../gql/VertragInfoFeldFragments.types';

type Props = {
  weiterePerson: VertragInfoFeld_VertragInfoFeldWeiterePerson_Fragment;
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  onSuccess: () => void;
};

const WeiterePersonListItem: FC<Props> = ({ weiterePerson, objektId, bestandseinheitId, vertragId, onSuccess }) => {
  return (
    <InfoFeldListItem
      icon={<UserAddOutlined />}
      content={{ fileIdList: weiterePerson.fileIdList, props: { height: 48 } }}
      actions={
        <WeiterePersonListItemActions
          infoFeld={weiterePerson}
          objektId={objektId}
          bestandseinheitId={bestandseinheitId}
          vertragId={vertragId}
          onSuccess={onSuccess}
        />
      }
    >
      <Space direction="vertical" style={{ width: '220px' }}>
        <Typography.Text type="secondary">Weitere Person</Typography.Text>
        <PersonInfoWithLinkToDetails
          rechtstraegerId={weiterePerson.natuerlichePerson.rechtstraegerId}
          personBezeichnung={weiterePerson.natuerlichePerson.kurzBezeichnung}
        />
      </Space>
    </InfoFeldListItem>
  );
};

export default WeiterePersonListItem;
