import React, { FC, JSX } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Typography } from 'antd';
import { postItFormInitialValues, PostItFormValues } from './PostItFormMapper';
import { postItFormValidationSchema } from './PostItFormValidationSchema';
import { PostItInput } from '../../../types';
import { StyledModal } from '../styled/Modal.style';
import PostItFormItemTitel from './FormItems/PostItFormItemTitel';
import PostItFormItemImmerAnzeigen from './FormItems/PostItFormItemImmerAnzeigen';
import PostItFormItemTextArea from './FormItems/PostItFormItemTextArea';
import { useGetAppFieldHelpText } from '../../../features/FieldHelp/useGetFieldHelpText';
import FieldHelpIconWithTooltip from '../../FieldHelpIcon/FieldHelpIconWithTooltip';

type Props = {
  postIt?: {
    immerAnzeigen: boolean;
    postItId: string;
    text: string;
    titel: string;
  };
  isOpen: boolean;
  onSubmit: (values: PostItInput) => void;
  onCancel: () => void;
  modalIcons: JSX.Element;
  showImmerAnzeigen?: boolean;
};

/**
 * <h2>Usage</h2>
 * Form for a Post-It update
 *
 * */
const PostItUpdateForm: FC<Props> = ({ postIt, isOpen, onSubmit, onCancel, modalIcons, showImmerAnzeigen = true }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'PostIt'>('PostIt');

  return (
    <Formik<PostItFormValues>
      initialValues={postItFormInitialValues(postIt)}
      validationSchema={postItFormValidationSchema}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(formikProps) => (
        <Form>
          <StyledModal
            open={isOpen}
            okText="Speichern"
            cancelText="Abbrechen"
            width="400px"
            onOk={() => formikProps.handleSubmit()}
            onCancel={onCancel}
            closeIcon={modalIcons}
            title={<PostItFormItemTitel inputWidth="80%" fieldHelp={getFieldHelpText('PostIt.titel')} />}
            maskClosable={false}
          >
            <Typography.Text>
              Notiz <FieldHelpIconWithTooltip fieldHelp={getFieldHelpText('PostIt.text')} />
            </Typography.Text>
            <PostItFormItemTextArea fieldHelp={getFieldHelpText('PostIt.text')} />
            {showImmerAnzeigen && (
              <PostItFormItemImmerAnzeigen checked={formikProps.values.immerAnzeigen} fieldHelp={getFieldHelpText('PostIt.immerAnzeigen')} />
            )}
          </StyledModal>
        </Form>
      )}
    </Formik>
  );
};

export default PostItUpdateForm;
