import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { FiltersFormValues, listingFiltersFormFields, listingLabelList } from './listingFiltersFormMapper';
import FiltersWith4OrMoreFields from '../../../../../components/Filters/FiltersWith4OrMoreFields';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { PaymentGenerierlaufType } from '../../../../../types';
import GenerierlaufEntryExitCodeListFormSelect from '../../../shared/Filters/Payment/GenerierlaufEntryExitCodeListFormSelect';
import GenerierlaufObjektFormSelect from '../../../shared/Filters/Payment/GenerierlaufObjektFormSelect';
import GenerierlaufPaymentTypeFormSelect from '../../../shared/Filters/Payment/GenerierlaufPaymentTypeFormSelect';
import GenerierlaufPaymentTransactionTypeFormSelect from '../../../shared/Filters/Payment/GenerierlaufPaymentTransactionTypeFormSelect';
import GenerierlaufBuchungskreisRechtstraegerFormSelect from '../../../shared/Filters/Payment/GenerierlaufBuchungskreisRechtstraegerFormSelect';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
  generierlaufId: string;
};

const ListingFilters: FC<Props> = ({ formikProps, generierlaufId }) => {
  return (
    <FiltersWith4OrMoreFields
      hideTitle
      leftColumn={
        <>
          <FormItemWithoutColon name={listingFiltersFormFields.objektId} label={listingLabelList.objektId}>
            <GenerierlaufObjektFormSelect
              name={listingFiltersFormFields.objektId}
              generierlaufId={generierlaufId}
              generierlaufType={PaymentGenerierlaufType.BookingSuggestionVerbuchen}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={listingFiltersFormFields.paymentType} label={listingLabelList.paymentType}>
            <GenerierlaufPaymentTypeFormSelect
              name={listingFiltersFormFields.paymentType}
              generierlaufId={generierlaufId}
              generierlaufType={PaymentGenerierlaufType.BookingSuggestionVerbuchen}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={listingFiltersFormFields.paymentTransactionType} label={listingLabelList.paymentTransactionType}>
            <GenerierlaufPaymentTransactionTypeFormSelect
              name={listingFiltersFormFields.paymentTransactionType}
              generierlaufId={generierlaufId}
              generierlaufType={PaymentGenerierlaufType.BookingSuggestionVerbuchen}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
      rightColumn={
        <>
          <FormItemWithoutColon name={listingFiltersFormFields.buchungskreisRechtstraegerId} label={listingLabelList.buchungskreisRechtstraegerId}>
            <GenerierlaufBuchungskreisRechtstraegerFormSelect
              name={listingFiltersFormFields.buchungskreisRechtstraegerId}
              generierlaufId={generierlaufId}
              generierlaufType={PaymentGenerierlaufType.BookingSuggestionVerbuchen}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={listingFiltersFormFields.exitCodeList} label={listingLabelList.exitCodeList}>
            <GenerierlaufEntryExitCodeListFormSelect
              name={listingFiltersFormFields.exitCodeList}
              generierlaufId={generierlaufId}
              generierlaufType={PaymentGenerierlaufType.BookingSuggestionVerbuchen}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
    />
  );
};

export default ListingFilters;
