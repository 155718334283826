import { Select, SelectProps } from 'formik-antd';
import { FC } from 'react';
import { useBeVertragBaseListQuery } from '../BeVertrag/gql/BeVertragQueries.types';
import { selectFilterOption } from '../../../helpers/selectHelper';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';

type Props = {
  objektId?: string | null;
  bestandseinheitId?: string | null;
  showObjekt?: boolean;
  showBestandseinheit?: boolean;
  showVertragsdauer?: boolean;
} & Omit<SelectProps, 'id' | 'allowClear' | 'filterOption' | 'loading' | 'placeholder' | 'size' | 'showSearch'>;

const BeVertragBaseSelect: FC<Props> = ({
  name,
  bestandseinheitId,
  objektId,
  showObjekt = false,
  showBestandseinheit = false,
  showVertragsdauer = false,
  ...restProps
}) => {
  const { data, loading } = useBeVertragBaseListQuery({
    skip: !(objektId && bestandseinheitId),
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      objektId: objektId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      bestandseinheitId: bestandseinheitId!,
      showObjekt,
      showBestandseinheit,
      showVertragsdauer,
    },
  });

  const vertragList = data?.getBeVertragBaseList.data ?? [];

  return (
    <Select
      {...restProps}
      name={name}
      size="small"
      id={name}
      loading={loading}
      placeholder="Vertrag auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {vertragList.map((vertrag) => (
        <Select.Option key={vertrag.vertragId} value={vertrag.vertragId}>
          <DataWithShortenedText maxTextLength={48} text={vertrag.kurzBezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default BeVertragBaseSelect;
