import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Col, Row, Typography } from 'antd';
import { IndexSeries } from '../../../../types';
import { useCreateFirmendatenIndexSeriesMutation, useUpdateFirmendatenIndexSeriesMutation } from '../gql/FirmendatenIndexSeriesMutations.types';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import IndexTypeSelect from '../../../IndexSeries/shared/IndexTypeSelect';
import { indexSeriesFormFields, IndexSeriesFormValues } from '../../../IndexSeries/Form/indexSeriesFormMapper';
import FormButtons from '../../../../components/Button/FormButtons';
import { mapFormValuesToIndexSeries, mapIndexSeriesToFormValues } from './indexSeriesFormMapper';
import { indexSeriesValidationSchema } from '../../../IndexSeries/Form/indexSeriesValidationSchema';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';

type Props = {
  indexSeries?: IndexSeries;
  onAction: () => void;
  onCancel: () => void;
};

const IndexSeriesForm: FC<Props> = ({ indexSeries, onAction, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'IndexSeries'>('IndexSeries');
  const isUpdate = !!indexSeries;

  const [runCreate, { loading: loadingCreate }] = useCreateFirmendatenIndexSeriesMutation({
    onCompleted: () => {
      showSuccessMsgCreate(`Indexreihe`);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateFirmendatenIndexSeriesMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`Indexreihe`);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<IndexSeriesFormValues>
      initialValues={mapIndexSeriesToFormValues(indexSeries)}
      onSubmit={(values, { setSubmitting }) => {
        const input = mapFormValuesToIndexSeries(values);
        if (isUpdate) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          runUpdate({ variables: { indexSeriesId: indexSeries!.indexSeriesId, input } }).finally(() => setSubmitting(false));
        } else {
          runCreate({ variables: { input } }).finally(() => setSubmitting(false));
        }
      }}
      validationSchema={indexSeriesValidationSchema}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Index</Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithFieldHelp name={indexSeriesFormFields.name} label="Bezeichnung" fieldHelp={getFieldHelpText('IndexSeries.name')}>
                <Input size="small" id={indexSeriesFormFields.name} name={indexSeriesFormFields.name} placeholder="z.B VPI 2020" />
              </FormItemWithFieldHelp>
              <FormItemWithFieldHelp name={indexSeriesFormFields.indexType} label="Typ" fieldHelp={getFieldHelpText('IndexSeries.indexType')}>
                <IndexTypeSelect name={indexSeriesFormFields.indexType} />
              </FormItemWithFieldHelp>
            </Col>
          </Row>
          <FormButtons
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            updateMode={isUpdate}
            isOkLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default IndexSeriesForm;
