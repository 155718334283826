import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { WeAbrechnungsdefinitionVersionFieldsFragmentDoc } from './WeAbrDefVersionFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WeAbrechnungsdefinitionVersionListQueryVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type WeAbrechnungsdefinitionVersionListQuery = {
  getWeAbrechnungsdefinitionVersionList: {
    data: Array<{
      abrechnungsdefinitionVersionId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      validFrom: string;
      kommentar?: string | null;
      lastUpdateTs: string;
      textBausteineBefuellt: boolean;
      historicizedList?: Array<{
        abrechnungsdefinitionVersionId: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        validFrom: string;
        kommentar?: string | null;
        lastUpdateTs: string;
        textBausteineBefuellt: boolean;
        warningList: Array<{ message: string; type: string }>;
      }> | null;
      warningList: Array<{ message: string; type: string }>;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type WeAbrechnungsdefinitionVersionQueryVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Types.Scalars['ID']['input'];
  searchInHistory?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type WeAbrechnungsdefinitionVersionQuery = {
  getWeAbrechnungsdefinitionVersion: {
    data: {
      abrechnungsdefinitionVersionId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      validFrom: string;
      kommentar?: string | null;
      lastUpdateTs: string;
      abrechdefAbrechkreisList: Array<{
        abrechnungskreis: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vorschreibungsposition?: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          vorschreibungspositionId: string;
          art: { text: string; value: Types.VorschreibungspositionArt };
          kontierungstabelle?: {
            basiskonto: number;
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kontierungstabelleId: string;
            updatedByMitarbeiterId?: string | null;
            status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
            subAdministrationExpenseAccount?: {
              bezeichnung: string;
              firmendatenId?: string | null;
              kontoId: string;
              kontoKlasse: string;
              nummer: string;
            } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
          umsatzsteuerkennzeichen?: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ message: string; type: string; attribute?: string | null }>;
          } | null;
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        } | null;
      }>;
      kontoAbrechnungskreisList: Array<{
        abrechnen: boolean;
        buchungszeilenAnzeigen: boolean;
        expenseReducing: boolean;
        ustVomAufwand: boolean;
        abrechnungskreis?: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
      objektAbrechnungOutputOptions: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        displayEinnahmen: boolean;
        displayKontonummer: boolean;
        updatedByMitarbeiterId?: string | null;
        buchungsdetails: { text: string; value: Types.BuchungsdetailsType };
        consolidateEinnahmen?: { text: string; value: Types.ConsolidateEinnahmenType } | null;
      };
      objektAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      topAbrechnungOutputOptions: {
        considerRueckstand: boolean;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        displayDueDate: boolean;
        displayRueckstandOtherPersons: boolean;
        displayZahlungsverlauf: boolean;
        rueckstandOtherPersonsThreshold?: number | null;
        updatedByMitarbeiterId?: string | null;
        dueDateDisplayOptionEigentuemerGuthaben?: { month?: number | null; type: { text: string; value: Types.DueDateDisplayOptionType } } | null;
        dueDateDisplayOptionEigentuemerNachzahlung?: { month?: number | null; type: { text: string; value: Types.DueDateDisplayOptionType } } | null;
        dueDateDisplayOptionMieter?: { month?: number | null; type: { text: string; value: Types.DueDateDisplayOptionType } } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      topAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      warningList: Array<{ message: string; type: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const WeAbrechnungsdefinitionVersionListDocument = gql`
  query WeAbrechnungsdefinitionVersionList($abrechnungsdefinitionId: ID!, $withDetails: Boolean) {
    getWeAbrechnungsdefinitionVersionList(abrechnungsdefinitionId: $abrechnungsdefinitionId, withDetails: $withDetails) {
      data {
        abrechnungsdefinitionVersionId
        createTs
        createdBy
        createdByMitarbeiterId
        current
        deletable
        firmendatenId
        validFrom
        historicizedList {
          abrechnungsdefinitionVersionId
          createTs
          createdBy
          createdByMitarbeiterId
          current
          deletable
          firmendatenId
          validFrom
          kommentar
          lastUpdateTs
          textBausteineBefuellt
          warningList {
            message
            type
          }
        }
        kommentar
        lastUpdateTs
        textBausteineBefuellt
        warningList {
          message
          type
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useWeAbrechnungsdefinitionVersionListQuery(
  baseOptions: Apollo.QueryHookOptions<WeAbrechnungsdefinitionVersionListQuery, WeAbrechnungsdefinitionVersionListQueryVariables> &
    ({ variables: WeAbrechnungsdefinitionVersionListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WeAbrechnungsdefinitionVersionListQuery, WeAbrechnungsdefinitionVersionListQueryVariables>(
    WeAbrechnungsdefinitionVersionListDocument,
    options
  );
}
export function useWeAbrechnungsdefinitionVersionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WeAbrechnungsdefinitionVersionListQuery, WeAbrechnungsdefinitionVersionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WeAbrechnungsdefinitionVersionListQuery, WeAbrechnungsdefinitionVersionListQueryVariables>(
    WeAbrechnungsdefinitionVersionListDocument,
    options
  );
}
export function useWeAbrechnungsdefinitionVersionListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<WeAbrechnungsdefinitionVersionListQuery, WeAbrechnungsdefinitionVersionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<WeAbrechnungsdefinitionVersionListQuery, WeAbrechnungsdefinitionVersionListQueryVariables>(
    WeAbrechnungsdefinitionVersionListDocument,
    options
  );
}
export type WeAbrechnungsdefinitionVersionListQueryHookResult = ReturnType<typeof useWeAbrechnungsdefinitionVersionListQuery>;
export type WeAbrechnungsdefinitionVersionListLazyQueryHookResult = ReturnType<typeof useWeAbrechnungsdefinitionVersionListLazyQuery>;
export type WeAbrechnungsdefinitionVersionListSuspenseQueryHookResult = ReturnType<typeof useWeAbrechnungsdefinitionVersionListSuspenseQuery>;
export type WeAbrechnungsdefinitionVersionListQueryResult = Apollo.QueryResult<
  WeAbrechnungsdefinitionVersionListQuery,
  WeAbrechnungsdefinitionVersionListQueryVariables
>;
export const WeAbrechnungsdefinitionVersionDocument = gql`
  query WeAbrechnungsdefinitionVersion($abrechnungsdefinitionId: ID!, $abrechnungsdefinitionVersionId: ID!, $searchInHistory: Boolean) {
    getWeAbrechnungsdefinitionVersion(
      abrechnungsdefinitionId: $abrechnungsdefinitionId
      abrechnungsdefinitionVersionId: $abrechnungsdefinitionVersionId
      searchInHistory: $searchInHistory
    ) {
      data {
        ...WeAbrechnungsdefinitionVersionFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${WeAbrechnungsdefinitionVersionFieldsFragmentDoc}
`;
export function useWeAbrechnungsdefinitionVersionQuery(
  baseOptions: Apollo.QueryHookOptions<WeAbrechnungsdefinitionVersionQuery, WeAbrechnungsdefinitionVersionQueryVariables> &
    ({ variables: WeAbrechnungsdefinitionVersionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WeAbrechnungsdefinitionVersionQuery, WeAbrechnungsdefinitionVersionQueryVariables>(
    WeAbrechnungsdefinitionVersionDocument,
    options
  );
}
export function useWeAbrechnungsdefinitionVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WeAbrechnungsdefinitionVersionQuery, WeAbrechnungsdefinitionVersionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WeAbrechnungsdefinitionVersionQuery, WeAbrechnungsdefinitionVersionQueryVariables>(
    WeAbrechnungsdefinitionVersionDocument,
    options
  );
}
export function useWeAbrechnungsdefinitionVersionSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WeAbrechnungsdefinitionVersionQuery, WeAbrechnungsdefinitionVersionQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<WeAbrechnungsdefinitionVersionQuery, WeAbrechnungsdefinitionVersionQueryVariables>(
    WeAbrechnungsdefinitionVersionDocument,
    options
  );
}
export type WeAbrechnungsdefinitionVersionQueryHookResult = ReturnType<typeof useWeAbrechnungsdefinitionVersionQuery>;
export type WeAbrechnungsdefinitionVersionLazyQueryHookResult = ReturnType<typeof useWeAbrechnungsdefinitionVersionLazyQuery>;
export type WeAbrechnungsdefinitionVersionSuspenseQueryHookResult = ReturnType<typeof useWeAbrechnungsdefinitionVersionSuspenseQuery>;
export type WeAbrechnungsdefinitionVersionQueryResult = Apollo.QueryResult<
  WeAbrechnungsdefinitionVersionQuery,
  WeAbrechnungsdefinitionVersionQueryVariables
>;
