import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EbicsBankConfigListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type EbicsBankConfigListQuery = {
  getEbicsBankConfigList: {
    data: Array<{
      bankStatementBtf?: string | null;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      creditTransferBtf?: string | null;
      creditTransferStatusBtf?: string | null;
      deletable: boolean;
      directDebitBtf?: string | null;
      directDebitStatusBtf?: string | null;
      ebicsBankConfigId: string;
      hostId: string;
      name: string;
      preApprovalBtf?: string | null;
      url: string;
      ebicsVersion: { text: string; value: Types.EbicsVersion };
      pain001Schema: { text: string; value: Types.Pain001Schema };
      pain008Schema: { text: string; value: Types.Pain008Schema };
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type EbicsBankConfigQueryVariables = Types.Exact<{
  ebicsBankConfigId: Types.Scalars['ID']['input'];
}>;

export type EbicsBankConfigQuery = {
  getEbicsBankConfig: {
    data: {
      bankStatementBtf?: string | null;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      creditTransferBtf?: string | null;
      creditTransferStatusBtf?: string | null;
      deletable: boolean;
      directDebitBtf?: string | null;
      directDebitStatusBtf?: string | null;
      ebicsBankConfigId: string;
      hostId: string;
      name: string;
      preApprovalBtf?: string | null;
      url: string;
      ebicsVersion: { text: string; value: Types.EbicsVersion };
      pain001Schema: { text: string; value: Types.Pain001Schema };
      pain008Schema: { text: string; value: Types.Pain008Schema };
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const EbicsBankConfigListDocument = gql`
  query EbicsBankConfigList {
    getEbicsBankConfigList {
      data {
        bankStatementBtf
        createTs
        createdBy
        createdByMitarbeiterId
        creditTransferBtf
        creditTransferStatusBtf
        deletable
        directDebitBtf
        directDebitStatusBtf
        ebicsBankConfigId
        ebicsVersion {
          text
          value
        }
        hostId
        name
        pain001Schema {
          text
          value
        }
        pain008Schema {
          text
          value
        }
        preApprovalBtf
        url
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useEbicsBankConfigListQuery(baseOptions?: Apollo.QueryHookOptions<EbicsBankConfigListQuery, EbicsBankConfigListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EbicsBankConfigListQuery, EbicsBankConfigListQueryVariables>(EbicsBankConfigListDocument, options);
}
export function useEbicsBankConfigListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EbicsBankConfigListQuery, EbicsBankConfigListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EbicsBankConfigListQuery, EbicsBankConfigListQueryVariables>(EbicsBankConfigListDocument, options);
}
export function useEbicsBankConfigListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EbicsBankConfigListQuery, EbicsBankConfigListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EbicsBankConfigListQuery, EbicsBankConfigListQueryVariables>(EbicsBankConfigListDocument, options);
}
export type EbicsBankConfigListQueryHookResult = ReturnType<typeof useEbicsBankConfigListQuery>;
export type EbicsBankConfigListLazyQueryHookResult = ReturnType<typeof useEbicsBankConfigListLazyQuery>;
export type EbicsBankConfigListSuspenseQueryHookResult = ReturnType<typeof useEbicsBankConfigListSuspenseQuery>;
export type EbicsBankConfigListQueryResult = Apollo.QueryResult<EbicsBankConfigListQuery, EbicsBankConfigListQueryVariables>;
export const EbicsBankConfigDocument = gql`
  query EbicsBankConfig($ebicsBankConfigId: ID!) {
    getEbicsBankConfig(ebicsBankConfigId: $ebicsBankConfigId) {
      data {
        bankStatementBtf
        createTs
        createdBy
        createdByMitarbeiterId
        creditTransferBtf
        creditTransferStatusBtf
        deletable
        directDebitBtf
        directDebitStatusBtf
        ebicsBankConfigId
        ebicsVersion {
          text
          value
        }
        hostId
        name
        pain001Schema {
          text
          value
        }
        pain008Schema {
          text
          value
        }
        preApprovalBtf
        url
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useEbicsBankConfigQuery(
  baseOptions: Apollo.QueryHookOptions<EbicsBankConfigQuery, EbicsBankConfigQueryVariables> &
    ({ variables: EbicsBankConfigQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EbicsBankConfigQuery, EbicsBankConfigQueryVariables>(EbicsBankConfigDocument, options);
}
export function useEbicsBankConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EbicsBankConfigQuery, EbicsBankConfigQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EbicsBankConfigQuery, EbicsBankConfigQueryVariables>(EbicsBankConfigDocument, options);
}
export function useEbicsBankConfigSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EbicsBankConfigQuery, EbicsBankConfigQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EbicsBankConfigQuery, EbicsBankConfigQueryVariables>(EbicsBankConfigDocument, options);
}
export type EbicsBankConfigQueryHookResult = ReturnType<typeof useEbicsBankConfigQuery>;
export type EbicsBankConfigLazyQueryHookResult = ReturnType<typeof useEbicsBankConfigLazyQuery>;
export type EbicsBankConfigSuspenseQueryHookResult = ReturnType<typeof useEbicsBankConfigSuspenseQuery>;
export type EbicsBankConfigQueryResult = Apollo.QueryResult<EbicsBankConfigQuery, EbicsBankConfigQueryVariables>;
