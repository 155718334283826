import React from 'react';
import { FireOutlined, HomeOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../../components/ContextSidebar/ContentWithSidebar';
import { verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import { generatePathToVerarbeitungZinslisteErstellungDetailsPage } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import VerarbeitungErrorAndWarningListing from '../../../features/Verarbeitung/shared/ErrorAndWarningListing/VerarbeitungErrorAndWarningListing';
import VerarbeitungZinsliste from '../../../features/Verarbeitung/Zinsliste/VerarbeitungZinsliste';
import { ZinslisteGenerierlauf } from '../../../types';

const menuListZinsliste = (generierlauf: ZinslisteGenerierlauf): GroupedMenueListInterface => {
  return {
    topMenuList: [
      {
        title: verarbeitungPageAndMenuListTitles.VorsteuerkuerzungErstellung,
        icon: <HomeOutlined />,
        uri: `${generatePathToVerarbeitungZinslisteErstellungDetailsPage(generierlauf.generierlaufId)}/entries`,
        content: () => <VerarbeitungZinsliste generierlauf={generierlauf} />,
      },
      {
        state: generierlauf.errorList.length > 0 || generierlauf.warningList.length > 0 ? 'error' : undefined,
        title: verarbeitungPageAndMenuListTitles.ErrorsAndWarnings,
        icon: <FireOutlined />,
        uri: `${generatePathToVerarbeitungZinslisteErstellungDetailsPage(generierlauf.generierlaufId)}/errors-and-warnings`,
        content: () => <VerarbeitungErrorAndWarningListing errorList={generierlauf.errorList} warningList={generierlauf.warningList} />,
      },
    ],
  };
};

export default menuListZinsliste;
