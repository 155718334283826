import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { FileInfoType } from '../../../../types';

type DocumentTypProps = SelectProps;

const DocumentTypeFormSelect: FC<DocumentTypProps> = ({ name, ...restProps }) => (
  <Select
    name={name}
    {...restProps}
    size="small"
    //style={{ width: isInDetailsTab ? '170px' : '190px' }}
    placeholder="Dokumenttyp auswählen"
    allowClear
  >
    <Select.Option value={FileInfoType.AusgangsRechnung}>Ausgangsrechnung</Select.Option>
    <Select.Option value={FileInfoType.IncomingInvoice}>Eingangsrechnung</Select.Option>
    <Select.Option value={FileInfoType.HeAbrechnung}>Hauseigentümerabrechnung</Select.Option>
    <Select.Option value={FileInfoType.Mahnung}>Mahnung</Select.Option>
    <Select.Option value={FileInfoType.BkObjektAbrechnung}>BK-Objektabrechnung</Select.Option>
    <Select.Option value={FileInfoType.WeObjektAbrechnung}>WE-Objektabrechnung</Select.Option>
    <Select.Option value={FileInfoType.BkTopAbrechnung}>BK-Topabrechnung</Select.Option>
    <Select.Option value={FileInfoType.WeTopAbrechnung}>WE-Topabrechnung</Select.Option>
    <Select.Option value={FileInfoType.ObjektFinancialPlan}>Objekt-Wirtschaftsplan</Select.Option>
    <Select.Option value={FileInfoType.TopFinancialPlan}>Top-Wirtschaftsplan</Select.Option>
    <Select.Option value={FileInfoType.SubAbrechnungObjekt}>Subverwaltungsabrechnung Objekt</Select.Option>
    <Select.Option value={FileInfoType.SubAbrechnungBestandseinheit}>Subverwaltungsabrechnung Bestandseinheit</Select.Option>
  </Select>
);

export default DocumentTypeFormSelect;
