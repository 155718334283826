import React, { FC } from 'react';
import { Flex } from 'rebass';
import { Space, Tag, Typography } from 'antd';
import { EuroAmount } from '../../../components/Number';

type Props = {
  guthaben: boolean;
  saldo: number;
};

export const GesamtergebnisWithWrapper: FC<Props> = ({ guthaben, saldo }) => {
  return (
    <Space direction="vertical" style={{ width: '100%', alignItems: 'end' }}>
      <Space direction="vertical">
        <Typography.Text>Gesamtergebnis: </Typography.Text>
        <Gesamtergebnis guthaben={guthaben} saldo={saldo} />
      </Space>
    </Space>
  );
};

export const Gesamtergebnis: FC<Props> = ({ guthaben, saldo }) => {
  return (
    <>
      {guthaben ? (
        <Flex justifyContent="space-between">
          <Tag color="green">Positiv Saldo</Tag> <EuroAmount value={saldo} />
        </Flex>
      ) : (
        <Flex justifyContent="space-between">
          <Tag color="red">Negativ Saldo</Tag> <EuroAmount value={saldo} />
        </Flex>
      )}
    </>
  );
};
