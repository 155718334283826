import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { PaymentProposalExecutionFormValues, paymentProposalFormFields, paymentProposalInitialValues } from './executeFormMapper';
import FormButtons from '../../../../../components/Button/FormButtons';
import { executeFormValidationSchema } from './executeFormValidationSchema';
import { paymentListingFiltersFormFields } from '../../../Listing/Filters/paymentListingFiltersFormMapper';
import { useCreatePaymentProposalExecutionGenerierlaufMutation } from '../../../../Verarbeitung/gql/PaymentProposalGenerierlauf/PaymentProposalGenerierlaufMutations.types';
import { showSuccessMsgOther } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import PaymentProposalFormSelect from './PaymentProposalFormSelect';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
};

const ExecuteForm: FC<Props> = ({ onSuccess, onCancel }) => {
  const [runExecutePaymentProposal] = useCreatePaymentProposalExecutionGenerierlaufMutation({
    onCompleted: () => {
      showSuccessMsgOther('Zahlungsvorschlag wird durchgeführt');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<PaymentProposalExecutionFormValues>
      initialValues={paymentProposalInitialValues}
      validationSchema={executeFormValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        runExecutePaymentProposal({
          variables: {
            input: {
              paymentProposalId: values.paymentProposalId,
            },
          },
        }).finally(() => setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <PaymentProposalFormSelect
            name={paymentProposalFormFields.paymentProposalId}
            fieldLabel="Zahlungsvorschlag"
            showNotAssignedOption={false}
            onFilterChange={formikProps.submitForm}
            onShowOwnPaymentProposalChange={() => formikProps.setFieldValue(paymentListingFiltersFormFields.paymentProposalId, undefined)}
          />

          <FormButtons onCancel={onCancel} updateMode={false} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default ExecuteForm;
