import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { isNotNil } from '../../../../../../../helpers/assertionHelper';
import { FibuBuchungszeile, FibuObjekt } from '../../../../../../../types';
import { getUniqueList } from '../../../../../../shared/getUniqueList';
import { selectFilterOption } from '../../../../../../../helpers/selectHelper';

type Props = {
  name: string;
  buchungszeileList: FibuBuchungszeile[];
} & SelectProps;

const BzObjektSelect: FC<Props> = ({ name, buchungszeileList, ...restProps }) => {
  const uniqueObjList: FibuObjekt[] = getUniqueList(buchungszeileList.map((bz) => bz.objekt).filter(isNotNil), (a, b) => a.objektId === b.objektId);

  return (
    <Select name={name} size="small" allowClear showSearch filterOption={selectFilterOption} {...restProps}>
      {uniqueObjList.map((obj) => (
        <Select.Option key={obj.objektId} value={obj.objektId} title={obj.kurzBezeichnung}>
          {obj.kurzBezeichnung}
        </Select.Option>
      ))}
    </Select>
  );
};

export default BzObjektSelect;
