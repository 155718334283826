import React from 'react';
import { useFDAuthorized } from '../../shared/Auth/useAuthorized';
import { useKontierungstabelleListQuery } from './gql/KontierungstabelleQueries.types';
import KontierungstabelleBtnAndDrawer from './KontierungstabelleBtnAndDrawer';
import KontierungstabelleTable from './Table/Level1/KontierungstabelleTable';
import { useShowArchivedQueryParamState } from '../../hooks/useShowArchivedQueryParamState';

const KontierungstabelleListing = () => {
  const [showArchived, toggleShowArchived] = useShowArchivedQueryParamState();

  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();

  const { data, refetch } = useKontierungstabelleListQuery({ variables: { kundenSystemId, includeArchiviert: showArchived, withDetails: true } });
  const kontierungstabelleList = data?.getKontierungstabelleListByKundenSystemId.data;

  return (
    <>
      <KontierungstabelleBtnAndDrawer refetch={refetch} showArchived={showArchived} toggleShowArchived={toggleShowArchived} />
      <KontierungstabelleTable kontierungstabelleList={kontierungstabelleList} refetch={refetch} />
    </>
  );
};

export default KontierungstabelleListing;
