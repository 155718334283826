import { FibuBelegSymbol, PaymentBelegSymbol } from '../../../types';

/*
    EINGANGS_RECHNUNG
    VORSCHREIBUNG
    EINZELRECHNUNG
    GUTSCHRIFT
    STORNO_RECHNUNG
    ZAHLUNG
    MAHNUNG
    VORSTEUERKUERZUNG
    UST_VOM_AUFWAND
    RUECKLASTSCHRIFT
    SPESEN_RUECKLASTSCHRIFT
    STORNO_ZAHLUNG
    STORNO_RUECKLASTSCHRIFT
    STORNO_SPESEN_RUECKLASTSCHRIFT
    SONSTIGES
 */

const avatarColorForBelegSymbol = (belegSymbol: string) => {
  switch (belegSymbol) {
    case FibuBelegSymbol.Einzelrechnung:
    case FibuBelegSymbol.Gutschrift:
    case FibuBelegSymbol.UstVomAufwand:
    case FibuBelegSymbol.Vorschreibung:
    case FibuBelegSymbol.Vorsteuerkuerzung:
    case FibuBelegSymbol.Zahlung:
    case PaymentBelegSymbol.Zahlung:
      return 'rgb(89, 126, 247)'; // = '#597EF7' = same as in avatarColorForFileInfoType
    case PaymentBelegSymbol.Ruecklastschrift:
      return 'rgb(36, 85, 244)';
    case PaymentBelegSymbol.SpesenRuecklastschrift:
      return 'rgb(11, 59, 219)'; // = '#597EF7' = same as in avatarColorForFileInfoType
    case FibuBelegSymbol.StornoRechnung:
    case FibuBelegSymbol.StornoRuecklastschrift:
    case FibuBelegSymbol.StornoSpesenRuecklastschrift:
    case FibuBelegSymbol.StornoZahlung:
      return '#E51AE2FF'; // = same as in avatarColorForFileInfoType
    case FibuBelegSymbol.EingangsRechnung:
      return '#f77e59';
    case FibuBelegSymbol.Mahnung:
      return '#24d8ea';
    default:
      return '#ccc';
  }
};

export default avatarColorForBelegSymbol;
