import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Empty } from 'antd';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import GenerierlaufStatusExpandedRow from '../../../shared/GenerierlaufStatusExpandedRow';
import { isVerarbeitungEntryTableRowExpandable } from '../../../verarbeitungHelpers';
import { BudgetingGenerierlaufEntry } from '../../../../../types';
import { BudgetingVerarbeitungEntryFiltersFormValues } from '../Filters/ListingFiltersFormMapper';
import budgetingVerarbeitungEntryTableColumns from './budgetingVerarbeitungEntryTableColumns';

type Props = {
  entryList: BudgetingGenerierlaufEntry[];
  formikProps: FormikProps<BudgetingVerarbeitungEntryFiltersFormValues>;
};

const BudgetingVerarbeitungEntryTable: FC<Props> = ({ entryList, formikProps }) => {
  const { exitCodeList, objekt } = formikProps.values;
  const objektFilter = (entry: BudgetingGenerierlaufEntry) => {
    if (!(objekt && objekt.length)) return true;
    return entry.budgeting ? objekt === entry.budgeting.objekt.kurzBezeichnung : false;
  };

  const exitCodeStatusFilter = (entry: BudgetingGenerierlaufEntry) => {
    if (!(exitCodeList && exitCodeList.length)) return true;
    return entry.exitCode?.text ? exitCodeList.includes(entry.exitCode.value) : false;
  };

  const filteredEntryList = entryList.filter(objektFilter).filter(exitCodeStatusFilter);

  return (
    <TableWithColSelector<BudgetingGenerierlaufEntry>
      loading={!entryList}
      locale={{
        emptyText: <Empty description={<span>Keine Daten vorhanden</span>} />,
      }}
      size="small"
      dataSource={filteredEntryList}
      columns={budgetingVerarbeitungEntryTableColumns}
      pagination={{ defaultPageSize: 20, showSizeChanger: true }}
      rowKey={(record) => record.generierlaufEntryId}
      expandable={{
        expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
        rowExpandable: isVerarbeitungEntryTableRowExpandable,
      }}
      filterIdentifier="verarbeitung-budgeting"
    />
  );
};

export default BudgetingVerarbeitungEntryTable;
