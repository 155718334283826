import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { BankDetailsFieldsFragmentDoc } from '../../BankDetails/gql/BankDetailsFragments.types';
import { PersonenbezugFragmentDoc } from '../../Personenbezug/gql/PersonenbezugFragments.types';
import { PostItFragmentDoc } from '../PostIt/gql/PostItFragments.types';
export type RechtstraegerListEntryFragment = {
  buchungskreisId?: string | null;
  city?: string | null;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  kundennummer: string;
  kurzBezeichnung: string;
  rechtstraegerId: string;
  tagList: Array<string>;
  zipCode?: string | null;
  salutation?: { text: string; value: Types.Salutation; letterSalutation: string } | null;
  status: { text: string; value: Types.RechtstraegerStatus };
  type: { text: string; value: Types.RechtstraegerType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type RechtstraegerFieldsFragment = {
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  fibuKontoCreatable: boolean;
  kundennummer: string;
  kurzBezeichnung: string;
  rechtstraegerId: string;
  tagList: Array<string>;
  updatedByMitarbeiterId?: string | null;
  bankDetailsList: Array<{
    accountHolder: string;
    accountLimit?: number | null;
    assignedHeVertragZahlungsregelMap: any;
    assignedObjektMap: any;
    bankDetailsId: string;
    bankname: string;
    bic: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    ebicsCreditTransferUserList: Array<string>;
    ebicsDirectDebitUserList: Array<string>;
    fibuKontoId?: string | null;
    fibuKontonummer?: string | null;
    iban: string;
    updatedByMitarbeiterId?: string | null;
    fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
    fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
    sepaMandat?: {
      fileId?: string | null;
      fileName?: string | null;
      sepaMandatId: string;
      sepaMandatReference: string;
      signatureDate?: string | null;
      firmendaten?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firmendatenId: string;
        kundenSystemId: string;
        kurzBezeichnung: string;
        name1: string;
        name2?: string | null;
        name3?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechnungsAussteller?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
      warningList: Array<{ message: string; type: string }>;
    } | null;
    status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }>;
  personenbezugList: Array<{
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    personenbezugId: string;
    tagList: Array<string>;
    person: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }>;
  postIt?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    immerAnzeigen: boolean;
    postItId: string;
    text: string;
    titel: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  rechtstraegerSteuerpflicht?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    current: boolean;
    deletable?: boolean | null;
    kommentar?: string | null;
    lastUpdateTs: string;
    rechtstraegerSteuerpflichtId: string;
    validFrom: string;
    historicizedList?: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      kommentar?: string | null;
      lastUpdateTs: string;
      rechtstraegerSteuerpflichtId: string;
      validFrom: string;
      steuerpflicht: { text: string; value: Types.Steuerpflicht };
      warningList: Array<{ message: string; type: string }>;
    }> | null;
    steuerpflicht: { text: string; value: Types.Steuerpflicht };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
  sprache: { text: string; value: Types.Sprache };
  status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
  type: { value: Types.RechtstraegerType; text: string };
  vertragSummary?: {
    heVertragCount: number;
    heVertragVerwaltungCount: number;
    mietVertragCount: number;
    mietVertragVerwaltungCount: number;
    weSubverwaltungCount: number;
    weVertragCount: number;
    weVertragVerwaltungCount: number;
  } | null;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type FibuRechtstraegerFragment = {
  kurzBezeichnung: string;
  rechtstraegerId: string;
  status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
  type: { text: string; value: Types.RechtstraegerType };
};

export type OrderRechtstraegerFragment = {
  kurzBezeichnung: string;
  kundennummer: string;
  rechtstraegerId: string;
  type: { text: string; value: Types.RechtstraegerType };
};

export type PaymentRechtstraegerFragment = { buchungskreisId?: string | null; kurzBezeichnung: string; rechtstraegerId: string };

export type RechtstraegerSteuerpflichtFragment = {
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  current: boolean;
  deletable?: boolean | null;
  kommentar?: string | null;
  lastUpdateTs: string;
  rechtstraegerSteuerpflichtId: string;
  validFrom: string;
  historicizedList?: Array<{
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    current: boolean;
    deletable?: boolean | null;
    kommentar?: string | null;
    lastUpdateTs: string;
    rechtstraegerSteuerpflichtId: string;
    validFrom: string;
    steuerpflicht: { text: string; value: Types.Steuerpflicht };
    warningList: Array<{ message: string; type: string }>;
  }> | null;
  steuerpflicht: { text: string; value: Types.Steuerpflicht };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type VorschreibungRechtstraegerFragment = {
  kurzBezeichnung: string;
  rechtstraegerId: string;
  type: { text: string; value: Types.RechtstraegerType };
};

export const RechtstraegerListEntryFragmentDoc = gql`
  fragment RechtstraegerListEntry on RechtstraegerListEntry {
    buchungskreisId
    city
    createTs
    createdBy
    createdByMitarbeiterId
    kundennummer
    kurzBezeichnung
    rechtstraegerId
    salutation {
      text
      value
      letterSalutation
    }
    status {
      text
      value
    }
    tagList
    type {
      text
      value
    }
    warningList {
      attribute
      message
      type
    }
    zipCode
  }
`;
export const RechtstraegerSteuerpflichtFragmentDoc = gql`
  fragment RechtstraegerSteuerpflicht on RechtstraegerSteuerpflicht {
    createTs
    createdBy
    createdByMitarbeiterId
    current
    deletable
    historicizedList {
      createTs
      createdBy
      createdByMitarbeiterId
      current
      deletable
      kommentar
      lastUpdateTs
      rechtstraegerSteuerpflichtId
      steuerpflicht {
        text
        value
      }
      validFrom
      warningList {
        message
        type
      }
    }
    kommentar
    lastUpdateTs
    rechtstraegerSteuerpflichtId
    steuerpflicht {
      text
      value
    }
    validFrom
    warningList {
      attribute
      message
      type
    }
  }
`;
export const RechtstraegerFieldsFragmentDoc = gql`
  fragment RechtstraegerFields on Rechtstraeger {
    bankDetailsList {
      ...BankDetailsFields
    }
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    fibuKontoCreatable
    kundennummer
    kurzBezeichnung
    personenbezugList {
      ...Personenbezug
    }
    postIt {
      ...PostIt
    }
    rechtstraegerId
    rechtstraegerSteuerpflicht {
      ...RechtstraegerSteuerpflicht
    }
    salutation {
      letterSalutation
      text
      value
    }
    sprache {
      text
      value
    }
    status {
      description
      text
      value
    }
    tagList
    type {
      value
      text
    }
    updatedByMitarbeiterId
    vertragSummary {
      heVertragCount
      heVertragVerwaltungCount
      mietVertragCount
      mietVertragVerwaltungCount
      weSubverwaltungCount
      weVertragCount
      weVertragVerwaltungCount
    }
    warningList {
      attribute
      message
      type
    }
  }
  ${BankDetailsFieldsFragmentDoc}
  ${PersonenbezugFragmentDoc}
  ${PostItFragmentDoc}
  ${RechtstraegerSteuerpflichtFragmentDoc}
`;
export const FibuRechtstraegerFragmentDoc = gql`
  fragment FibuRechtstraeger on FibuRechtstraeger {
    kurzBezeichnung
    rechtstraegerId
    status {
      description
      text
      value
    }
    type {
      text
      value
    }
  }
`;
export const OrderRechtstraegerFragmentDoc = gql`
  fragment OrderRechtstraeger on OrderRechtstraeger {
    kurzBezeichnung
    kundennummer
    rechtstraegerId
    type {
      text
      value
    }
  }
`;
export const PaymentRechtstraegerFragmentDoc = gql`
  fragment PaymentRechtstraeger on PaymentRechtstraeger {
    buchungskreisId
    kurzBezeichnung
    rechtstraegerId
  }
`;
export const VorschreibungRechtstraegerFragmentDoc = gql`
  fragment VorschreibungRechtstraeger on VorschreibungRechtstraeger {
    kurzBezeichnung
    rechtstraegerId
    type {
      text
      value
    }
  }
`;
