import React, { FC } from 'react';
import { Empty } from 'antd';
import { Aufteilungsschluessel } from '../../../types';
import NestedTable from '../../../components/Table/NestedTable/NestedTable';
import aufteilungsschluesselTemplateTableColumns from './aufteilungsschluesselTemplateTableColumns';

type Props = {
  aufteilungsschluesselList?: Aufteilungsschluessel[];
  loading: boolean;
  refetch: () => void;
};

const AufteilungsschluesselTemplateTable: FC<Props> = ({ aufteilungsschluesselList, loading, refetch }) => {
  return (
    <NestedTable<Aufteilungsschluessel>
      locale={{
        emptyText: <Empty description={<span>Keine Aufteilungsschlüssel vorhanden</span>} />,
      }}
      loading={loading}
      scroll={{ x: 900 }}
      dataSource={aufteilungsschluesselList}
      columns={aufteilungsschluesselTemplateTableColumns(refetch)}
      rowKey={(record) => record.aufteilungsschluesselId}
      expandable={{
        rowExpandable: () => false,
      }}
      pagination
      useHighlighting
    />
  );
};

export default AufteilungsschluesselTemplateTable;
