import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { FirmendatenInitializerGenerierlaufFieldsFragmentDoc } from './FirmendatenInitializerGenerierlaufFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FirmendatenInitializerGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type FirmendatenInitializerGenerierlaufQuery = {
  getFirmendatenInitializerGenerierlauf: {
    data: {
      countFehler: number;
      countVerarbeitet: number;
      countNichtVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierDatum: string;
      generierlaufId: string;
      errorList: Array<{ message: string; type: string }>;
      firmendaten: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firmendatenId: string;
        kundenSystemId: string;
        kurzBezeichnung: string;
        name1: string;
        name2?: string | null;
        name3?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      status: { description?: string | null; value: Types.GenerierlaufStatus; text: string };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const FirmendatenInitializerGenerierlaufDocument = gql`
  query FirmendatenInitializerGenerierlauf($generierlaufId: ID!) {
    getFirmendatenInitializerGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        ...FirmendatenInitializerGenerierlaufFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${FirmendatenInitializerGenerierlaufFieldsFragmentDoc}
`;
export function useFirmendatenInitializerGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<FirmendatenInitializerGenerierlaufQuery, FirmendatenInitializerGenerierlaufQueryVariables> &
    ({ variables: FirmendatenInitializerGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenInitializerGenerierlaufQuery, FirmendatenInitializerGenerierlaufQueryVariables>(
    FirmendatenInitializerGenerierlaufDocument,
    options
  );
}
export function useFirmendatenInitializerGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenInitializerGenerierlaufQuery, FirmendatenInitializerGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenInitializerGenerierlaufQuery, FirmendatenInitializerGenerierlaufQueryVariables>(
    FirmendatenInitializerGenerierlaufDocument,
    options
  );
}
export function useFirmendatenInitializerGenerierlaufSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FirmendatenInitializerGenerierlaufQuery, FirmendatenInitializerGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenInitializerGenerierlaufQuery, FirmendatenInitializerGenerierlaufQueryVariables>(
    FirmendatenInitializerGenerierlaufDocument,
    options
  );
}
export type FirmendatenInitializerGenerierlaufQueryHookResult = ReturnType<typeof useFirmendatenInitializerGenerierlaufQuery>;
export type FirmendatenInitializerGenerierlaufLazyQueryHookResult = ReturnType<typeof useFirmendatenInitializerGenerierlaufLazyQuery>;
export type FirmendatenInitializerGenerierlaufSuspenseQueryHookResult = ReturnType<typeof useFirmendatenInitializerGenerierlaufSuspenseQuery>;
export type FirmendatenInitializerGenerierlaufQueryResult = Apollo.QueryResult<
  FirmendatenInitializerGenerierlaufQuery,
  FirmendatenInitializerGenerierlaufQueryVariables
>;
