import * as Types from '../../types';

import { gql } from '@apollo/client';
import { ContactFieldsFragmentDoc, ContactPersonFieldsFragmentDoc } from '../../features/Contact/gql/ContactFragments.types';
export type UserFieldsFragment = {
  createdTs: string;
  email?: string | null;
  emailUnverified?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  role: Types.UserRole;
  username: string;
  status: { text: string; value: Types.UserStatus };
  contactList?: Array<{
    contactId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    haupt?: boolean | null;
    tagList: Array<string>;
    type: string;
    wert: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }> | null;
};

export const UserFieldsFragmentDoc = gql`
  fragment userFields on User {
    createdTs
    email
    emailUnverified
    firstname
    lastname
    role
    status {
      text
      value
    }
    username
    contactList {
      ...ContactFields
    }
  }
  ${ContactFieldsFragmentDoc}
`;
