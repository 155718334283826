import React, { FC } from 'react';
import { useField } from 'formik';
import { DatePicker } from 'formik-antd';
import { RangePickerProps } from 'formik-antd/lib/date-picker';
import { Dayjs } from 'dayjs';
import { DATE_DISPLAY_MONTH_FORMAT_DEFAULT } from '../DatePicker_formikAntD';
import { dayjsCustom } from '../../../helpers/dayjsCustom';

type Props = Omit<RangePickerProps, 'id' | 'format' | 'size'>;

/**
 * RangePicker component with display format 'DD.MM.YYYY'
 * @param restProps  DatePicker.RangePicker (formik-antd) props
 ** Omitted props: id, format, size
 ** Width is set to 100% by default.
 */
const RangePicker: FC<Props> = ({ ...restProps }) => {
  const [, { value }] = useField(restProps.name);
  const defaultValue: [Dayjs, Dayjs] | undefined = value && Array.isArray(value) ? [dayjsCustom(value[0]), dayjsCustom(value[1])] : undefined;

  return (
    <DatePicker.RangePicker
      {...restProps}
      id={restProps.name}
      defaultValue={defaultValue}
      format={DATE_DISPLAY_MONTH_FORMAT_DEFAULT}
      size="small"
      style={{ width: restProps.style?.width ?? '100%' }}
    />
  );
};

export default RangePicker;
