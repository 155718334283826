import _ from 'lodash';
import {
  repFondsKontenZuweisungDescriptionsForKommentar,
  RepFondsKontenZuweisungVersionFormValues,
} from './repFondsKontenZuweisungVersionFormMapper';
import { ITimeblock } from '../../../../../components/Timeline/timelineCtx';
import { RepFondsEinstellung } from '../../../../../types';

export const isSameAsPreviousVersion = (
  formValues: RepFondsKontenZuweisungVersionFormValues,
  previousTimeblock?: ITimeblock<RepFondsEinstellung>
) => {
  const formAufwandskonten = formValues.kontenZuweisungList.map((konto) => konto.aufwandsKontoIdList).flat();
  const prevTimeblockAufwandskonten = previousTimeblock?.kontenZuweisungList
    .map((konto) => konto.aufwandsKontoList.map((aufwandKonto) => aufwandKonto.kontoId))
    .flat();

  const formAufteilungsschluessel = formValues.kontenZuweisungList.map((konto) => konto.aufteilungsschluesselId).flat();
  const prevTimeblockAufteilungsschluessel = previousTimeblock?.kontenZuweisungList
    .map((konto) => konto.aufteilungsschluessel.aufteilungsschluesselId)
    .flat();

  const formRepFondsKonto = formValues.kontenZuweisungList.map((konto) => konto.repFondsKontoId).flat();
  const prevTimeblockRepFondsKonto = previousTimeblock?.kontenZuweisungList.map((konto) => konto.repFondsKonto.kontoId).flat();

  return (
    _.isEqual(formAufwandskonten, prevTimeblockAufwandskonten) &&
    _.isEqual(formAufteilungsschluessel, prevTimeblockAufteilungsschluessel) &&
    _.isEqual(formRepFondsKonto, prevTimeblockRepFondsKonto)
  );
};

export const buildKommentarText = (
  restInitialValues: RepFondsKontenZuweisungVersionFormValues,
  restValues: RepFondsKontenZuweisungVersionFormValues
) => {
  const changedValuesDescriptions: string[] = [];

  const initialKontenZuweisungList = restInitialValues.kontenZuweisungList;
  const kontenZuweisungList = restValues.kontenZuweisungList;

  const deleteItem = (name: string) => {
    const itemToDelete = changedValuesDescriptions.indexOf(name);
    changedValuesDescriptions.splice(itemToDelete);
  };

  if (kontenZuweisungList.length > 0 && initialKontenZuweisungList.length > 0) {
    const initialValuesAufwandskonten = initialKontenZuweisungList.map((konto) => konto.aufwandsKontoIdList);
    const valuesAufwandskonten = kontenZuweisungList.map((konto) => konto.aufwandsKontoIdList);

    const initialValuesAufteilungsschluessel = initialKontenZuweisungList.map((konto) => konto.aufteilungsschluesselId);
    const valuesAufteilungsschluessel = kontenZuweisungList.map((konto) => konto.aufteilungsschluesselId);

    const initialValuesRepFondsKonto = initialKontenZuweisungList.map((konto) => konto.repFondsKontoId);
    const valuesRepFondsKonto = kontenZuweisungList.map((konto) => konto.repFondsKontoId);

    const isAufwandskontenEqual = _.isEqual(initialValuesAufwandskonten, valuesAufwandskonten);
    const isAufteilungschluesselEqual = _.isEqual(initialValuesAufteilungsschluessel, valuesAufteilungsschluessel);
    const isRepFondsKontoEqual = _.isEqual(initialValuesRepFondsKonto, valuesRepFondsKonto);

    if (!isAufteilungschluesselEqual) {
      changedValuesDescriptions.push(repFondsKontenZuweisungDescriptionsForKommentar.aufteilungsschluesselId);
    } else if (changedValuesDescriptions.includes(repFondsKontenZuweisungDescriptionsForKommentar.aufteilungsschluesselId)) {
      deleteItem(repFondsKontenZuweisungDescriptionsForKommentar.aufteilungsschluesselId);
    }

    if (!isAufwandskontenEqual) {
      changedValuesDescriptions.push(repFondsKontenZuweisungDescriptionsForKommentar.aufwandsKontoIdList);
    } else if (changedValuesDescriptions.includes(repFondsKontenZuweisungDescriptionsForKommentar.aufwandsKontoIdList)) {
      deleteItem(repFondsKontenZuweisungDescriptionsForKommentar.aufwandsKontoIdList);
    }

    if (!isRepFondsKontoEqual) {
      changedValuesDescriptions.push(repFondsKontenZuweisungDescriptionsForKommentar.repFondsKontoId);
    } else if (changedValuesDescriptions.includes(repFondsKontenZuweisungDescriptionsForKommentar.repFondsKontoId)) {
      deleteItem(repFondsKontenZuweisungDescriptionsForKommentar.repFondsKontoId);
    }
  }
  return changedValuesDescriptions.length !== 0 ? changedValuesDescriptions.join(', ').concat(' geändert') : '';
};
