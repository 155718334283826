import React, { FC, useMemo } from 'react';
import { useBestandseinheitAufteilungsschluesselWertAenderungListQuery } from '../gql/BestandseinheitAufteilungsschluesselQueries.types';
import BeAufteilungsschluesselWertMitBezugTable from './MitBezug/BeAufteilungsschluesselWertMitBezugTable';
import BeAufteilungsschluesselWertOhneBezugTable from './OhneBezug/BeAufteilungsschluesselWertOhneBezugTable';
import { useBestandseinheitIds } from '../../bestandseinheitIdsContext';
import { BeAufteilungsschluessel, Bezugsbasis } from '../../../../types';

type BestandseinheitAufteilungsschluesselWertTableProps = {
  expanded: boolean;
  aufteilungsschluessel: BeAufteilungsschluessel;
  onAction: () => void;
};

const BestandseinheitAufteilungsschluesselWertTable: FC<BestandseinheitAufteilungsschluesselWertTableProps> = ({
  expanded,
  aufteilungsschluessel,
  onAction,
}) => {
  const { objektId, bestandseinheitId } = useBestandseinheitIds();
  const { aufteilungsschluesselId } = aufteilungsschluessel;

  const { data, loading, refetch } = useBestandseinheitAufteilungsschluesselWertAenderungListQuery({
    variables: {
      objektId,
      bestandseinheitId,
      aufteilungsschluesselId,
    },
  });

  // TODO: clarify with Backend if and why this filtering necessary
  // useMemo is necessary because subtable gets renderes after any change in its dataSource hence this wertList will be created at every render because
  // filter returns always a new array object
  const wertList = useMemo(() => data?.getBeAufteilungsschluesselWertAenderungList.data.filter((wert) => wert.validFrom) ?? [], [data]);

  const onSuccess = () => {
    refetch();
    onAction();
  };

  const isMitBezug =
    aufteilungsschluessel.bezugsbasis.value === Bezugsbasis.Nutzwert || Bezugsbasis.Nutzflaeche === aufteilungsschluessel.bezugsbasis.value;

  return isMitBezug ? (
    <BeAufteilungsschluesselWertMitBezugTable
      expanded={expanded}
      onSuccess={onSuccess}
      aufteilungsschluessel={aufteilungsschluessel}
      bestandseinheitAufteilungsschluesselWertList={wertList}
      isBestandseinheitAufteilungsschluesselWertListLoading={loading}
      objektId={objektId}
      bestandseinheitId={bestandseinheitId}
    />
  ) : (
    <BeAufteilungsschluesselWertOhneBezugTable
      expanded={expanded}
      onSuccess={onSuccess}
      aufteilungsschluessel={aufteilungsschluessel}
      bestandseinheitAufteilungsschluesselWertList={wertList}
      isBestandseinheitAufteilungsschluesselWertListLoading={loading}
      objektId={objektId}
      bestandseinheitId={bestandseinheitId}
    />
  );
};

export default BestandseinheitAufteilungsschluesselWertTable;
