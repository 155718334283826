import React, { FC } from 'react';
import { Table, Tooltip } from 'antd';
import SortCarets from './SortCarets';
import { SortOrder, tooltipTitleForSorting } from './sortingHelpers';

type TableCellTitleWithSortCaretsProps = {
  index: number;
  columnTitle: string;
  sortedColumnIndex: number;
  sortOrder: SortOrder;
  handleSorting: (index: number) => void;
  colSpan?: number;
  align?: 'left' | 'center' | 'right';
};

const TableSummaryCellTitleWithSortCarets: FC<TableCellTitleWithSortCaretsProps> = ({
  index,
  columnTitle,
  sortedColumnIndex,
  sortOrder,
  handleSorting,
  colSpan = 1,
  align = 'left',
}) => (
  <Table.Summary.Cell index={index} className="ant-table-column-has-sorters" colSpan={colSpan} align={align}>
    <Tooltip title={tooltipTitleForSorting(index, sortedColumnIndex, sortOrder)}>
      <div className="ant-table-column-sorters" onClick={() => handleSorting(index)}>
        <span className="ant-table-column-title">{columnTitle}</span>
        <SortCarets columnIndex={index} sortedColumnIndex={sortedColumnIndex} sortOrder={sortOrder} />
      </div>
    </Tooltip>
  </Table.Summary.Cell>
);

export default TableSummaryCellTitleWithSortCarets;
