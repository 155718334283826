import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Switch } from 'formik-antd';
import { Space, Typography } from 'antd';
import { heOrSubAbrDefGeneralSettingsFormFields, HeOrSubAbrDefGeneralSettingsFormValues } from './heOrSubAbrDefGeneralSettingsFormMapper';
import ParamFormItem from '../../../../../../../components/Form/ParamFormItem';

type Props = {
  formikProps: FormikProps<HeOrSubAbrDefGeneralSettingsFormValues>;
  isDisabled: boolean;
  onChange?: (formValues: HeOrSubAbrDefGeneralSettingsFormValues) => void;
  fieldHelp?: string | null;
};

const RueckstaendeAnzeigenFormItem: FC<Props> = ({ formikProps, isDisabled, onChange, fieldHelp }) => (
  <ParamFormItem
    name={heOrSubAbrDefGeneralSettingsFormFields.rueckstaendeAnzeigen}
    label="Rückstände der Abrechnungsperiode anzeigen"
    fieldHelp={fieldHelp}
  >
    <Space>
      <Switch
        name={heOrSubAbrDefGeneralSettingsFormFields.rueckstaendeAnzeigen}
        size="small"
        onChange={(value) => {
          formikProps.setFieldValue(heOrSubAbrDefGeneralSettingsFormFields.rueckstaendeInAbrechnungsergebnis, false);
          onChange?.({
            ...formikProps.values,
            rueckstaendeAnzeigen: value,
            rueckstaendeInAbrechnungsergebnis: !value ? false : formikProps.values.rueckstaendeInAbrechnungsergebnis,
          });
        }}
        disabled={isDisabled}
      />
      <Typography.Text>{formikProps.values.rueckstaendeAnzeigen ? 'Ja' : 'Nein'}</Typography.Text>
    </Space>
  </ParamFormItem>
);

export default RueckstaendeAnzeigenFormItem;
