import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBeVertragMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragInput: Types.BeVertragCreateInput;
}>;

export type CreateBeVertragMutation = {
  createBeVertrag: { data: { createTs: string; vertragId: string; vertragspartner: { kurzBezeichnung: string } } };
};

export type UpdateBeVertragMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vertragInput: Types.BeVertragUpdateInput;
}>;

export type UpdateBeVertragMutation = { updateBeVertrag: { data: { createTs: string } } };

export type DeleteBeVertragMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
}>;

export type DeleteBeVertragMutation = {
  deleteBeVertrag: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type BeVertragMarkUnderReviewMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
}>;

export type BeVertragMarkUnderReviewMutation = {
  actionBeVertragMarkUnderReview: {
    data: { vertragId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateBeVertragPostItMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  input: Types.PostItInput;
}>;

export type CreateBeVertragPostItMutation = {
  createBeVertragPostIt: {
    data: { postItId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateBeVertragDocument = gql`
  mutation CreateBeVertrag($objektId: ID!, $bestandseinheitId: ID!, $vertragInput: BeVertragCreateInput!) {
    createBeVertrag(objektId: $objektId, bestandseinheitId: $bestandseinheitId, vertragInput: $vertragInput) {
      data {
        createTs
        vertragId
        vertragspartner {
          kurzBezeichnung
        }
      }
    }
  }
`;
export function useCreateBeVertragMutation(baseOptions?: Apollo.MutationHookOptions<CreateBeVertragMutation, CreateBeVertragMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBeVertragMutation, CreateBeVertragMutationVariables>(CreateBeVertragDocument, options);
}
export type CreateBeVertragMutationHookResult = ReturnType<typeof useCreateBeVertragMutation>;
export type CreateBeVertragMutationResult = Apollo.MutationResult<CreateBeVertragMutation>;
export type CreateBeVertragMutationOptions = Apollo.BaseMutationOptions<CreateBeVertragMutation, CreateBeVertragMutationVariables>;
export const UpdateBeVertragDocument = gql`
  mutation UpdateBeVertrag($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!, $vertragInput: BeVertragUpdateInput!) {
    updateBeVertrag(objektId: $objektId, bestandseinheitId: $bestandseinheitId, vertragId: $vertragId, vertragInput: $vertragInput) {
      data {
        createTs
      }
    }
  }
`;
export function useUpdateBeVertragMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBeVertragMutation, UpdateBeVertragMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBeVertragMutation, UpdateBeVertragMutationVariables>(UpdateBeVertragDocument, options);
}
export type UpdateBeVertragMutationHookResult = ReturnType<typeof useUpdateBeVertragMutation>;
export type UpdateBeVertragMutationResult = Apollo.MutationResult<UpdateBeVertragMutation>;
export type UpdateBeVertragMutationOptions = Apollo.BaseMutationOptions<UpdateBeVertragMutation, UpdateBeVertragMutationVariables>;
export const DeleteBeVertragDocument = gql`
  mutation DeleteBeVertrag($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!) {
    deleteBeVertrag(objektId: $objektId, bestandseinheitId: $bestandseinheitId, vertragId: $vertragId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteBeVertragMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBeVertragMutation, DeleteBeVertragMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBeVertragMutation, DeleteBeVertragMutationVariables>(DeleteBeVertragDocument, options);
}
export type DeleteBeVertragMutationHookResult = ReturnType<typeof useDeleteBeVertragMutation>;
export type DeleteBeVertragMutationResult = Apollo.MutationResult<DeleteBeVertragMutation>;
export type DeleteBeVertragMutationOptions = Apollo.BaseMutationOptions<DeleteBeVertragMutation, DeleteBeVertragMutationVariables>;
export const BeVertragMarkUnderReviewDocument = gql`
  mutation BeVertragMarkUnderReview($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!) {
    actionBeVertragMarkUnderReview(objektId: $objektId, bestandseinheitId: $bestandseinheitId, vertragId: $vertragId) {
      data {
        vertragId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBeVertragMarkUnderReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<BeVertragMarkUnderReviewMutation, BeVertragMarkUnderReviewMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BeVertragMarkUnderReviewMutation, BeVertragMarkUnderReviewMutationVariables>(BeVertragMarkUnderReviewDocument, options);
}
export type BeVertragMarkUnderReviewMutationHookResult = ReturnType<typeof useBeVertragMarkUnderReviewMutation>;
export type BeVertragMarkUnderReviewMutationResult = Apollo.MutationResult<BeVertragMarkUnderReviewMutation>;
export type BeVertragMarkUnderReviewMutationOptions = Apollo.BaseMutationOptions<
  BeVertragMarkUnderReviewMutation,
  BeVertragMarkUnderReviewMutationVariables
>;
export const CreateBeVertragPostItDocument = gql`
  mutation CreateBeVertragPostIt($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!, $input: PostItInput!) {
    createBeVertragPostIt(objektId: $objektId, bestandseinheitId: $bestandseinheitId, vertragId: $vertragId, input: $input) {
      data {
        postItId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateBeVertragPostItMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBeVertragPostItMutation, CreateBeVertragPostItMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBeVertragPostItMutation, CreateBeVertragPostItMutationVariables>(CreateBeVertragPostItDocument, options);
}
export type CreateBeVertragPostItMutationHookResult = ReturnType<typeof useCreateBeVertragPostItMutation>;
export type CreateBeVertragPostItMutationResult = Apollo.MutationResult<CreateBeVertragPostItMutation>;
export type CreateBeVertragPostItMutationOptions = Apollo.BaseMutationOptions<CreateBeVertragPostItMutation, CreateBeVertragPostItMutationVariables>;
