import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../../security/AuthRoute';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../../security/permissionChecks';
import SysSettingsAuftragsartCreatePage from './SysSettingsAuftragsartCreatePage';
import URI_SYS_SETTINGS_AUFTRAGSART_PAGE from '../../../../../features/Auftragsart/auftragsartUriPaths';

const sysSettingsAuftragsartCreatePageRoute = (
  <Route
    path={URI_SYS_SETTINGS_AUFTRAGSART_PAGE.createPage}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsAuftragsartCreatePage />} />}
  />
);

export default sysSettingsAuftragsartCreatePageRoute;
