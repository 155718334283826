import React, { FC } from 'react';
import { Popconfirm, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FormikProps } from 'formik';
import { useAuszifferungStornierenMutation } from '../../../../../../../gql/AuszifferungMutations.types';
import { showSuccessMsgStornieren } from '../../../../../../../../../components/message/message';
import { AuszifferungBlockFormValues, OffenePostenForBooking } from '../../../../auszifferungFormMapper';
import { hasOPListExistingNotStorniertAuszifferung } from '../../../../auszifferungFormHelpers';
import { updateAuszifferungAsStorniert } from './stornoAuszifferungHelpers';

type Props = {
  auszifferungId: string;
  bookingZahlungId: string;
  formikProps: FormikProps<AuszifferungBlockFormValues>;
  offenePosten: OffenePostenForBooking;
  zahlungId: string;
};

const StornoExistingAuszifferung: FC<Props> = ({ auszifferungId, bookingZahlungId, formikProps, offenePosten, zahlungId }) => {
  const [runStorno] = useAuszifferungStornierenMutation({
    variables: {
      auszifferungId,
      zahlungId,
    },
    onCompleted: (data) => {
      const newAuszifferung = data.actionAuszifferungStornieren.data;

      if (newAuszifferung) {
        updateAuszifferungAsStorniert(newAuszifferung, formikProps, offenePosten);
      }
    },
  });

  const handleStorno = () => {
    runStorno()
      .then(showSuccessMsgStornieren('Auszifferung'))
      .finally(() => !hasOPListExistingNotStorniertAuszifferung(bookingZahlungId, formikProps.values.offenePostenListFromBooking));
  };

  return (
    <Popconfirm title="Sind Sie sicher?" icon={<QuestionCircleOutlined style={{ color: 'red' }} />} onConfirm={handleStorno}>
      <Typography.Text style={{ color: '#1677ff', cursor: 'pointer' }}>Stornieren</Typography.Text>
    </Popconfirm>
  );
};

export default StornoExistingAuszifferung;
