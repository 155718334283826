import React, { FC } from 'react';
import { Button, Drawer, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../../../hooks/useToggle';
import ListingTitleWithButtons from '../../../../components/Listing/ListingTitleWithButtons';
import ZahlungsregelForm from './Form/ZahlungsregelForm';
import { HeAbrechnungsdefinitionFieldsFragment } from '../../../Abrechnungsdefinition/gql/HeAbrDef/HeAbrDefFragments.types';

type Props = {
  objektId: string;
  heVertragId: string;
  onSuccess: () => void;
  showArchived: boolean;
  toggleShowArchived: () => void;
  vertragspartnerId: string;
  heAbrechnungsdefinition?: HeAbrechnungsdefinitionFieldsFragment | null;
};

const ZahlungsregelButtonsAndDrawer: FC<Props> = ({
  objektId,
  heVertragId,
  onSuccess,
  showArchived,
  toggleShowArchived,
  vertragspartnerId,
  heAbrechnungsdefinition,
}) => {
  const [isDrawerOpen, toggleDrawerOpen] = useToggle();

  const onCreateSuccess = () => {
    onSuccess();
    toggleDrawerOpen();
  };

  return (
    <>
      <ListingTitleWithButtons
        title="Zahlungsregeln"
        buttons={
          <Space>
            <Button size="small" onClick={toggleShowArchived}>
              Archivierte Regeln {showArchived ? 'ausblenden' : 'anzeigen'}
            </Button>
            <Button size="small" icon={<PlusOutlined />} type="primary" onClick={toggleDrawerOpen}>
              Neue Regel anlegen
            </Button>
          </Space>
        }
      />

      <Drawer title="Akonto Zahlungsregel anlegen" width={720} open={isDrawerOpen} onClose={toggleDrawerOpen} destroyOnClose>
        <ZahlungsregelForm
          objektId={objektId}
          heVertragId={heVertragId}
          onSuccess={onCreateSuccess}
          onCancel={toggleDrawerOpen}
          vertragspartnerId={vertragspartnerId}
          heAbrechnungsdefinition={heAbrechnungsdefinition}
        />
      </Drawer>
    </>
  );
};

export default ZahlungsregelButtonsAndDrawer;
