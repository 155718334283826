import React, { FC, useState } from 'react';
import { Flex } from 'rebass';
import { Badge, List, Tabs, Tooltip, Typography } from 'antd';
import { useMyTicketListQuery } from '../../Ticket/gql/TicketQueries.types';
import DebouncedTextSearch from '../../../components/Input-antd/DebouncedTextSearch';
import { TicketPriorityTuple } from '../../../types';
import TicketPriorityTag from '../../Ticket/shared/TicketPriorityTag';
import StatusBadge from '../../../components/Status/StatusBadge';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';
import { dayjsCustom } from '../../../helpers/dayjsCustom';
import TicketPreview from '../../Ticket/shared/TicketPreview/TicketPreview';

type Props = {
  itemsToShow?: number;
};

const MyTickets: FC<Props> = ({ itemsToShow = 4 }) => {
  const { data, loading } = useMyTicketListQuery();
  const ticketList = data?.getMyTicketList.data || [];
  const listHeight = itemsToShow * 61 - 1;
  const [title, setTitle] = useState<string>('');
  const filteredTicketList = ticketList.filter((ticket) => ticket.title.toLowerCase().includes(title.toLowerCase()));

  const tabs = [
    {
      key: '1',
      label: (
        <>
          Meine Aufgaben
          <Badge count={ticketList.length} style={{ marginLeft: '8px', backgroundColor: '#52c41a' }} />
        </>
      ),
      children: (
        <>
          <DebouncedTextSearch size="middle" placeholder="Aufgaben suchen" style={{ width: '100%' }} onChange={(value) => setTitle(value.trim())} />
          <div style={{ paddingTop: 16 }}>
            <List
              style={{ maxHeight: listHeight, overflow: 'auto' }}
              loading={loading}
              itemLayout="horizontal"
              locale={{ emptyText: 'Keine Aufgaben vorhanden' }}
              dataSource={filteredTicketList}
              renderItem={(ticket) => (
                <List.Item style={{ paddingRight: 5 }} key={ticket.ticketId}>
                  <List.Item.Meta
                    description={
                      <Flex style={{ gap: 8, flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'flex-start' }}>
                        <Flex style={{ flexShrink: 0, width: 60 }}>
                          <TicketPriorityTag priority={ticket.priority as TicketPriorityTuple} />
                        </Flex>
                        <Flex style={{ flexShrink: 1, width: '100%' }}>
                          <Typography.Text style={{ overflow: 'hidden' }}>
                            <TicketPreview ticket={ticket} showAssignee={false} />
                          </Typography.Text>
                        </Flex>

                        <Flex style={{ flexShrink: 0, width: 100 }}>
                          <StatusBadge entityStatus={ticket.ticketStatus} />
                        </Flex>
                        <Flex style={{ flexShrink: 0, width: 120, justifyContent: 'flex-start' }}>
                          <Typography.Text type="secondary">
                            Fällig <Tooltip title={<CustomFormattedDate value={ticket.dueDate} />}>{relativeTime(ticket.dueDate)}</Tooltip>
                          </Typography.Text>
                        </Flex>
                      </Flex>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        </>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" size="small" items={tabs} />;
};

const relativeTime = (dateString: string) => {
  const date = dayjsCustom(dateString);
  const today = dayjsCustom();
  return date.isSame(today, 'day') ? 'heute' : dayjsCustom(date).startOf('day').fromNow();
};

export default MyTickets;
