import React, { FC } from 'react';
import { Empty } from 'antd';
import GenerierlaufStatusExpandedRow from '../../shared/GenerierlaufStatusExpandedRow';
import { isVerarbeitungEntryTableRowExpandable } from '../../verarbeitungHelpers';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { VertragVposIndexedValueGenerierlaufEntry } from '../../../../types';
import entryTableColumns from './entryTableColumns';
import { TVertagVposIndexedValueVerarbeitungQueryParams } from '../../shared/VertragVposIndexedValue/filtersQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../hooks/useQueryWithPagingAndSorting';
import { useVertragVposIndexedValueGenerierlaufEntryListQuery } from '../../gql/VertragVposIndexedValueGenerierlauf/VertragVposIndexedValueGenerierlaufQueries.types';

type Props = {
  queryParams: TVertagVposIndexedValueVerarbeitungQueryParams;
  generierlaufId: string;
};

const EntryTable: FC<Props> = ({ queryParams, generierlaufId }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useVertragVposIndexedValueGenerierlaufEntryListQuery,
    {
      variables: {
        generierlaufId,
        ...queryParams,
      },
      fetchPolicy: 'no-cache',
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const entryList = data?.getVertragVposIndexedValueGenerierlaufEntryList.data.contentList || [];

  return (
    <TableWithColSelector<VertragVposIndexedValueGenerierlaufEntry>
      loading={loading}
      dataSource={entryList}
      pagination={pagination}
      onChange={handleTableSorting}
      locale={{
        emptyText: <Empty description={<span>Keine Positionen vorhanden</span>} />,
      }}
      size="small"
      columns={entryTableColumns}
      rowKey={(record) => record.generierlaufEntryId}
      expandable={{
        expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
        rowExpandable: isVerarbeitungEntryTableRowExpandable,
      }}
      filterIdentifier="verarbeitung-vertrag-vpos-indexed-value"
    />
  );
};

export default EntryTable;
