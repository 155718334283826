import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { VBillingAddress } from '../../vorschreibung-types';
import VTBillingAddressBlock from './VTBillingAddressBlock';
import VTBelegDetails from './VTBelegDetails';
import Absender from '../../../shared/Absender';
import { LabeledValue } from '../../../shared/types';

type Props = {
  belegnummer: LabeledValue;
  belegOrtUndDatum: string;
  billingAddress: VBillingAddress;
  absender: LabeledValue;
};

const VTAddressAndAbsenderAndBelegDetails: FC<Props> = ({ belegnummer, belegOrtUndDatum, billingAddress, absender }) => (
  <View style={[pdfStyles.row, { paddingTop: '3mm' }]}>
    <View style={[pdfStyles.column, pdfStyles.addressor]}>
      <Absender absender={absender} />
      <View style={[{ marginBottom: '7mm' }]} />
      <VTBillingAddressBlock billingAddress={billingAddress} />
    </View>
    <View style={[pdfStyles.column]}>
      <VTBelegDetails belegnummer={belegnummer} belegOrtUndDatum={belegOrtUndDatum} />
    </View>
  </View>
);

export default VTAddressAndAbsenderAndBelegDetails;
