import * as Types from '../../../../types';

import { gql } from '@apollo/client';
export type GenerierlaufAsyncStepFieldsFragment = {
  designation: string;
  receivingApplication?: string | null;
  stepId: string;
  type?: string | null;
  status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
};

export const GenerierlaufAsyncStepFieldsFragmentDoc = gql`
  fragment GenerierlaufAsyncStepFields on GenerierlaufAsyncStep {
    designation
    receivingApplication
    status {
      description
      text
      value
    }
    stepId
    type
  }
`;
