import { generatePath } from 'react-router';
import { PaginationQueryParamsProps } from '../../hooks/usePaginationQueryParams';
import { mapToQueryString } from '../../hooks/useStringQueryParams';
import { IndexedValueQueryParamKey, TIndexedValueQueryParams } from './Listing/Filters/filtersQueryParams';

export const URI_INDEXED_VALUE_PAGE = '/dashboard/indexed-values';

export const generatePathToIndexedValueListingPage = (filters?: TIndexedValueQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  `${generatePath(URI_INDEXED_VALUE_PAGE)}?${mapToQueryString({
    [IndexedValueQueryParamKey.BESTANDSEINHEIT_ID]: filters?.bestandseinheitId,
    [IndexedValueQueryParamKey.INDEX_SERIES_ID]: filters?.indexSeriesId,
    [IndexedValueQueryParamKey.OBJEKT_ID]: filters?.objektId,
    [IndexedValueQueryParamKey.RECHNUNGS_AUSSTELLER_ID]: filters?.rechnungsAusstellerId,
    [IndexedValueQueryParamKey.STATUS_LIST]: filters?.statusList,
    [IndexedValueQueryParamKey.VALID_FROM]: filters?.validFrom,
    [IndexedValueQueryParamKey.VALID_TO]: filters?.validTo,
    [IndexedValueQueryParamKey.VALUE_PRESERVATION_TYPE_LIST]: filters?.valuePreservationTypeList,
    [IndexedValueQueryParamKey.VERTRAGSPARTNER_ID]: filters?.vertragspartnerId,
    [IndexedValueQueryParamKey.VERTRAG_ID]: filters?.vertragId,
    [IndexedValueQueryParamKey.VORSCHREIBUNGSPOSITION_NAME]: filters?.vorschreibungspositionName,
    [IndexedValueQueryParamKey.SET_DEFAULT_STATUS_ON_INIT]: filters?.setDefaultStatusOnInit,
    ...paginationParams,
  })}`;
