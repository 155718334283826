import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import { URI_VERARBEITUNG_HE_ABRECHNUNG_ERSTELLEN_DETAILS_PAGE } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import HeAbrechnungDetailsPage from './HeAbrechnungDetailsPage';

export type VerarbeitungHeAbrechnungErstellenDetailsPageRouteParams = { generierlaufId: string };

export const verarbeitungHeAbrechnungErstellenDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_HE_ABRECHNUNG_ERSTELLEN_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<HeAbrechnungDetailsPage />} />}
  />
);
