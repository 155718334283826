import React, { FC } from 'react';
import { Button, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Box, Flex } from 'rebass';
import { useNavigate } from 'react-router-dom';
import { AbrechnungsdefinitionType } from '../../../../types';
import { generatePathToAbrechnungsdefinitionTemplateVersionCreatePage } from '../../abrDefTemplateUriPaths';
import AbrDefTemplateWeVersionTimeline from './AbrDefTemplateWeVersionTimeline';
import AbrDefTemplateBkVersionTimeline from './AbrDefTemplateBkVersionTimeline';
import AbrDefTemplateHeVersionTimeline from './AbrDefTemplateHeVersionTimeline';
import AbrDefTemplateSubVersionTimeline from './AbrDefTemplateSubVersionTimeline';

type Props = {
  abrechnungsdefinitionId: string;
  abrDefType: AbrechnungsdefinitionType;
};

const AbrDefVersionTemplateTimelineWithActions: FC<Props> = ({ abrechnungsdefinitionId, abrDefType }) => {
  const abrDefVersionTimeline = (abrDefType: AbrechnungsdefinitionType) => {
    switch (abrDefType) {
      case AbrechnungsdefinitionType.HeAbrechnung:
        return <AbrDefTemplateHeVersionTimeline abrechnungsdefinitionId={abrechnungsdefinitionId} />;
      case AbrechnungsdefinitionType.SubAbrechnung:
        return <AbrDefTemplateSubVersionTimeline abrechnungsdefinitionId={abrechnungsdefinitionId} />;
      case AbrechnungsdefinitionType.BkAbrechnung:
        return <AbrDefTemplateBkVersionTimeline abrechnungsdefinitionId={abrechnungsdefinitionId} />;
      case AbrechnungsdefinitionType.WeAbrechnung:
      default:
        return <AbrDefTemplateWeVersionTimeline abrechnungsdefinitionId={abrechnungsdefinitionId} />;
    }
  };

  return (
    <Box>
      <AbrDefVersionTimelineActions abrechnungsdefinitionId={abrechnungsdefinitionId} />
      <Box mt="24px" />
      {abrDefVersionTimeline(abrDefType)}
    </Box>
  );
};

type AbrDefVersionTimelineActionsProps = {
  abrechnungsdefinitionId: string;
};

const AbrDefVersionTimelineActions: FC<AbrDefVersionTimelineActionsProps> = ({ abrechnungsdefinitionId }) => {
  const navigate = useNavigate();

  return (
    <Flex width="100%">
      <Button
        block
        type="default"
        icon={<PlusOutlined />}
        onClick={() => {
          navigate(generatePathToAbrechnungsdefinitionTemplateVersionCreatePage(abrechnungsdefinitionId));
        }}
      >
        Neue Gültigkeit anlegen
      </Button>
      <Box width="16px" />
      {/* eslint-disable-next-line no-console */}
      <Input.Search placeholder="Änderung suchen" onSearch={(value) => console.log(value)} allowClear />
    </Flex>
  );
};

export default AbrDefVersionTemplateTimelineWithActions;
