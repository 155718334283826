import React, { FC } from 'react';
import { Typography } from 'antd';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { shouldTextBeShortened } from '../../../helpers/stringHelper';
import { AuftragsartBase } from '../../../types';

type Props = { auftragsartenZuweisung: AuftragsartBase[] };

const AuftragsartenZuweisung: FC<Props> = ({ auftragsartenZuweisung }) => {
  const textWillBeShortened = shouldTextBeShortened(auftragsartenZuweisung[0].bezeichnung, MAX_TEXT_LENGTH);

  const extraTexteForTooltip =
    auftragsartenZuweisung.length === 1
      ? []
      : // avoid doubling the first bezeichnung from the list in the tooltip if the rendered text will be shortened
        auftragsartenZuweisung.reduce((bezeichnungList, zuweisung, currentIndex) => {
          return currentIndex === 0 && textWillBeShortened ? bezeichnungList : [...bezeichnungList, zuweisung.bezeichnung];
        }, [] as string[]);

  return (
    <DataWithShortenedText maxTextLength={MAX_TEXT_LENGTH} text={auftragsartenZuweisung[0].bezeichnung} extraTexts={extraTexteForTooltip}>
      {(shortenedText) => (
        <Typography.Text>{!textWillBeShortened && extraTexteForTooltip.length > 1 ? `${shortenedText}...` : shortenedText}</Typography.Text>
      )}
    </DataWithShortenedText>
  );
};

const MAX_TEXT_LENGTH = 25;

export default AuftragsartenZuweisung;
