import * as Types from '../../../../types';

import { gql } from '@apollo/client';
export type KundenSystemFragment = {
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  kundenSystemId: string;
  name: string;
  erzeugtVonFirma: {
    companyRegistrationNumber?: string | null;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    firmendatenId: string;
    kundenSystemId: string;
    kurzBezeichnung: string;
    name1: string;
    name2?: string | null;
    name3?: string | null;
    sepaCreditorId?: string | null;
    vatIdentificationNumber?: string | null;
    firmeneinstellung: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      dueDateVorschreibung: { text: string; value: string };
    };
    legalForm: { text: string; value: Types.LegalForm };
    rolleList: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      name: string;
      rolleId: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    status: { description?: string | null; text: string; value: Types.FirmendatenStatus };
    warningList: Array<{ message: string; type: string; attribute?: string | null }>;
  };
  firmendatenList: Array<{
    companyRegistrationNumber?: string | null;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    firmendatenId: string;
    kundenSystemId: string;
    kurzBezeichnung: string;
    name1: string;
    name2?: string | null;
    name3?: string | null;
    sepaCreditorId?: string | null;
    vatIdentificationNumber?: string | null;
    firmeneinstellung: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      dueDateVorschreibung: { text: string; value: string };
    };
    legalForm: { text: string; value: Types.LegalForm };
    rolleList: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      name: string;
      rolleId: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    status: { description?: string | null; text: string; value: Types.FirmendatenStatus };
    warningList: Array<{ message: string; type: string; attribute?: string | null }>;
  }>;
  status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
  warningList: Array<{ message: string; type: string; attribute?: string | null }>;
};

export type FirmaForKsFragment = {
  companyRegistrationNumber?: string | null;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  firmendatenId: string;
  kundenSystemId: string;
  kurzBezeichnung: string;
  name1: string;
  name2?: string | null;
  name3?: string | null;
  sepaCreditorId?: string | null;
  vatIdentificationNumber?: string | null;
  firmeneinstellung: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    dueDateVorschreibung: { text: string; value: string };
  };
  legalForm: { text: string; value: Types.LegalForm };
  rolleList: Array<{
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    name: string;
    rolleId: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }>;
  status: { description?: string | null; text: string; value: Types.FirmendatenStatus };
  warningList: Array<{ message: string; type: string; attribute?: string | null }>;
};

export const FirmaForKsFragmentDoc = gql`
  fragment FirmaForKS on FirmaForKS {
    companyRegistrationNumber
    createTs
    createdBy
    createdByMitarbeiterId
    firmendatenId
    firmeneinstellung {
      createTs
      createdBy
      createdByMitarbeiterId
      dueDateVorschreibung {
        text
        value
      }
    }
    kundenSystemId
    kurzBezeichnung
    legalForm {
      text
      value
    }
    name1
    name2
    name3
    rolleList {
      createTs
      createdBy
      createdByMitarbeiterId
      name
      rolleId
      warningList {
        attribute
        message
        type
      }
    }
    sepaCreditorId
    status {
      description
      text
      value
    }
    vatIdentificationNumber
    warningList {
      message
      type
      attribute
    }
  }
`;
export const KundenSystemFragmentDoc = gql`
  fragment KundenSystem on KundenSystem {
    createTs
    createdBy
    createdByMitarbeiterId
    erzeugtVonFirma {
      ...FirmaForKS
    }
    firmendatenList {
      ...FirmaForKS
    }
    kundenSystemId
    name
    status {
      description
      text
      value
    }
    warningList {
      message
      type
      attribute
    }
  }
  ${FirmaForKsFragmentDoc}
`;
