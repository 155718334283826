import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTextbausteinTemplateMutationVariables = Types.Exact<{
  input: Types.GeneralTextbausteinCreateInput;
}>;

export type CreateTextbausteinTemplateMutation = {
  createTextbausteinTemplate: {
    data: { textbausteinId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateTextbausteinTemplateMutationVariables = Types.Exact<{
  textbausteinId: Types.Scalars['ID']['input'];
  input: Types.GeneralTextbausteinUpdateInput;
}>;

export type UpdateTextbausteinTemplateMutation = {
  updateTextbausteinTemplate: {
    data: { textbausteinId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteTextbausteinTemplateMutationVariables = Types.Exact<{
  textbausteinId: Types.Scalars['ID']['input'];
}>;

export type DeleteTextbausteinTemplateMutation = {
  deleteTextbausteinTemplate: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateTextbausteinTemplateDocument = gql`
  mutation CreateTextbausteinTemplate($input: GeneralTextbausteinCreateInput!) {
    createTextbausteinTemplate(input: $input) {
      data {
        textbausteinId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateTextbausteinTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTextbausteinTemplateMutation, CreateTextbausteinTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTextbausteinTemplateMutation, CreateTextbausteinTemplateMutationVariables>(
    CreateTextbausteinTemplateDocument,
    options
  );
}
export type CreateTextbausteinTemplateMutationHookResult = ReturnType<typeof useCreateTextbausteinTemplateMutation>;
export type CreateTextbausteinTemplateMutationResult = Apollo.MutationResult<CreateTextbausteinTemplateMutation>;
export type CreateTextbausteinTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateTextbausteinTemplateMutation,
  CreateTextbausteinTemplateMutationVariables
>;
export const UpdateTextbausteinTemplateDocument = gql`
  mutation UpdateTextbausteinTemplate($textbausteinId: ID!, $input: GeneralTextbausteinUpdateInput!) {
    updateTextbausteinTemplate(textbausteinId: $textbausteinId, input: $input) {
      data {
        textbausteinId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateTextbausteinTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTextbausteinTemplateMutation, UpdateTextbausteinTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTextbausteinTemplateMutation, UpdateTextbausteinTemplateMutationVariables>(
    UpdateTextbausteinTemplateDocument,
    options
  );
}
export type UpdateTextbausteinTemplateMutationHookResult = ReturnType<typeof useUpdateTextbausteinTemplateMutation>;
export type UpdateTextbausteinTemplateMutationResult = Apollo.MutationResult<UpdateTextbausteinTemplateMutation>;
export type UpdateTextbausteinTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateTextbausteinTemplateMutation,
  UpdateTextbausteinTemplateMutationVariables
>;
export const DeleteTextbausteinTemplateDocument = gql`
  mutation DeleteTextbausteinTemplate($textbausteinId: ID!) {
    deleteTextbausteinTemplate(textbausteinId: $textbausteinId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteTextbausteinTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTextbausteinTemplateMutation, DeleteTextbausteinTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTextbausteinTemplateMutation, DeleteTextbausteinTemplateMutationVariables>(
    DeleteTextbausteinTemplateDocument,
    options
  );
}
export type DeleteTextbausteinTemplateMutationHookResult = ReturnType<typeof useDeleteTextbausteinTemplateMutation>;
export type DeleteTextbausteinTemplateMutationResult = Apollo.MutationResult<DeleteTextbausteinTemplateMutation>;
export type DeleteTextbausteinTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteTextbausteinTemplateMutation,
  DeleteTextbausteinTemplateMutationVariables
>;
