import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SperrenMahnungMutationVariables = Types.Exact<{
  mahnungId: Types.Scalars['ID']['input'];
}>;

export type SperrenMahnungMutation = {
  actionSperrenMahnung: {
    data: { mahnungId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type EntsperrenMahnungMutationVariables = Types.Exact<{
  mahnungId: Types.Scalars['ID']['input'];
}>;

export type EntsperrenMahnungMutation = {
  actionEntsperrenMahnung: {
    data: { mahnungId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FreigebenMahnungMutationVariables = Types.Exact<{
  mahnungId: Types.Scalars['ID']['input'];
}>;

export type FreigebenMahnungMutation = {
  actionFreigebenMahnung: {
    data: { mahnungId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteMahnungMutationVariables = Types.Exact<{
  mahnungId: Types.Scalars['ID']['input'];
}>;

export type DeleteMahnungMutation = {
  deleteMahnung: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type UpdateMahnungMutationVariables = Types.Exact<{
  mahnungId: Types.Scalars['ID']['input'];
  request: Types.MahnungUpdateInput;
}>;

export type UpdateMahnungMutation = {
  updateMahnung: {
    data: { mahnungId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MahnungenEntsperrenMutationVariables = Types.Exact<{
  request: Types.MahnungenBulkActionFilterInput;
}>;

export type MahnungenEntsperrenMutation = {
  mahnungenEntsperren: {
    data?: number | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MahnungenFreigebenMutationVariables = Types.Exact<{
  request: Types.MahnungenBulkActionFilterInput;
}>;

export type MahnungenFreigebenMutation = {
  mahnungenFreigeben: {
    data?: number | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MahnungenLoeschenMutationVariables = Types.Exact<{
  request: Types.MahnungenBulkActionFilterInput;
}>;

export type MahnungenLoeschenMutation = {
  mahnungenLoeschen: {
    data?: number | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MahnungenSperrenMutationVariables = Types.Exact<{
  request: Types.MahnungenSperrenBulkActionFilterInput;
}>;

export type MahnungenSperrenMutation = {
  mahnungenSperren: {
    data?: number | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const SperrenMahnungDocument = gql`
  mutation SperrenMahnung($mahnungId: ID!) {
    actionSperrenMahnung(mahnungId: $mahnungId) {
      data {
        mahnungId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useSperrenMahnungMutation(baseOptions?: Apollo.MutationHookOptions<SperrenMahnungMutation, SperrenMahnungMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SperrenMahnungMutation, SperrenMahnungMutationVariables>(SperrenMahnungDocument, options);
}
export type SperrenMahnungMutationHookResult = ReturnType<typeof useSperrenMahnungMutation>;
export type SperrenMahnungMutationResult = Apollo.MutationResult<SperrenMahnungMutation>;
export type SperrenMahnungMutationOptions = Apollo.BaseMutationOptions<SperrenMahnungMutation, SperrenMahnungMutationVariables>;
export const EntsperrenMahnungDocument = gql`
  mutation EntsperrenMahnung($mahnungId: ID!) {
    actionEntsperrenMahnung(mahnungId: $mahnungId) {
      data {
        mahnungId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useEntsperrenMahnungMutation(
  baseOptions?: Apollo.MutationHookOptions<EntsperrenMahnungMutation, EntsperrenMahnungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EntsperrenMahnungMutation, EntsperrenMahnungMutationVariables>(EntsperrenMahnungDocument, options);
}
export type EntsperrenMahnungMutationHookResult = ReturnType<typeof useEntsperrenMahnungMutation>;
export type EntsperrenMahnungMutationResult = Apollo.MutationResult<EntsperrenMahnungMutation>;
export type EntsperrenMahnungMutationOptions = Apollo.BaseMutationOptions<EntsperrenMahnungMutation, EntsperrenMahnungMutationVariables>;
export const FreigebenMahnungDocument = gql`
  mutation FreigebenMahnung($mahnungId: ID!) {
    actionFreigebenMahnung(mahnungId: $mahnungId) {
      data {
        mahnungId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFreigebenMahnungMutation(baseOptions?: Apollo.MutationHookOptions<FreigebenMahnungMutation, FreigebenMahnungMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FreigebenMahnungMutation, FreigebenMahnungMutationVariables>(FreigebenMahnungDocument, options);
}
export type FreigebenMahnungMutationHookResult = ReturnType<typeof useFreigebenMahnungMutation>;
export type FreigebenMahnungMutationResult = Apollo.MutationResult<FreigebenMahnungMutation>;
export type FreigebenMahnungMutationOptions = Apollo.BaseMutationOptions<FreigebenMahnungMutation, FreigebenMahnungMutationVariables>;
export const DeleteMahnungDocument = gql`
  mutation DeleteMahnung($mahnungId: ID!) {
    deleteMahnung(mahnungId: $mahnungId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteMahnungMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMahnungMutation, DeleteMahnungMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMahnungMutation, DeleteMahnungMutationVariables>(DeleteMahnungDocument, options);
}
export type DeleteMahnungMutationHookResult = ReturnType<typeof useDeleteMahnungMutation>;
export type DeleteMahnungMutationResult = Apollo.MutationResult<DeleteMahnungMutation>;
export type DeleteMahnungMutationOptions = Apollo.BaseMutationOptions<DeleteMahnungMutation, DeleteMahnungMutationVariables>;
export const UpdateMahnungDocument = gql`
  mutation UpdateMahnung($mahnungId: ID!, $request: MahnungUpdateInput!) {
    updateMahnung(mahnungId: $mahnungId, request: $request) {
      data {
        mahnungId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateMahnungMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMahnungMutation, UpdateMahnungMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMahnungMutation, UpdateMahnungMutationVariables>(UpdateMahnungDocument, options);
}
export type UpdateMahnungMutationHookResult = ReturnType<typeof useUpdateMahnungMutation>;
export type UpdateMahnungMutationResult = Apollo.MutationResult<UpdateMahnungMutation>;
export type UpdateMahnungMutationOptions = Apollo.BaseMutationOptions<UpdateMahnungMutation, UpdateMahnungMutationVariables>;
export const MahnungenEntsperrenDocument = gql`
  mutation MahnungenEntsperren($request: MahnungenBulkActionFilterInput!) {
    mahnungenEntsperren(request: $request) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useMahnungenEntsperrenMutation(
  baseOptions?: Apollo.MutationHookOptions<MahnungenEntsperrenMutation, MahnungenEntsperrenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MahnungenEntsperrenMutation, MahnungenEntsperrenMutationVariables>(MahnungenEntsperrenDocument, options);
}
export type MahnungenEntsperrenMutationHookResult = ReturnType<typeof useMahnungenEntsperrenMutation>;
export type MahnungenEntsperrenMutationResult = Apollo.MutationResult<MahnungenEntsperrenMutation>;
export type MahnungenEntsperrenMutationOptions = Apollo.BaseMutationOptions<MahnungenEntsperrenMutation, MahnungenEntsperrenMutationVariables>;
export const MahnungenFreigebenDocument = gql`
  mutation MahnungenFreigeben($request: MahnungenBulkActionFilterInput!) {
    mahnungenFreigeben(request: $request) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useMahnungenFreigebenMutation(
  baseOptions?: Apollo.MutationHookOptions<MahnungenFreigebenMutation, MahnungenFreigebenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MahnungenFreigebenMutation, MahnungenFreigebenMutationVariables>(MahnungenFreigebenDocument, options);
}
export type MahnungenFreigebenMutationHookResult = ReturnType<typeof useMahnungenFreigebenMutation>;
export type MahnungenFreigebenMutationResult = Apollo.MutationResult<MahnungenFreigebenMutation>;
export type MahnungenFreigebenMutationOptions = Apollo.BaseMutationOptions<MahnungenFreigebenMutation, MahnungenFreigebenMutationVariables>;
export const MahnungenLoeschenDocument = gql`
  mutation MahnungenLoeschen($request: MahnungenBulkActionFilterInput!) {
    mahnungenLoeschen(request: $request) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useMahnungenLoeschenMutation(
  baseOptions?: Apollo.MutationHookOptions<MahnungenLoeschenMutation, MahnungenLoeschenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MahnungenLoeschenMutation, MahnungenLoeschenMutationVariables>(MahnungenLoeschenDocument, options);
}
export type MahnungenLoeschenMutationHookResult = ReturnType<typeof useMahnungenLoeschenMutation>;
export type MahnungenLoeschenMutationResult = Apollo.MutationResult<MahnungenLoeschenMutation>;
export type MahnungenLoeschenMutationOptions = Apollo.BaseMutationOptions<MahnungenLoeschenMutation, MahnungenLoeschenMutationVariables>;
export const MahnungenSperrenDocument = gql`
  mutation MahnungenSperren($request: MahnungenSperrenBulkActionFilterInput!) {
    mahnungenSperren(request: $request) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useMahnungenSperrenMutation(baseOptions?: Apollo.MutationHookOptions<MahnungenSperrenMutation, MahnungenSperrenMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MahnungenSperrenMutation, MahnungenSperrenMutationVariables>(MahnungenSperrenDocument, options);
}
export type MahnungenSperrenMutationHookResult = ReturnType<typeof useMahnungenSperrenMutation>;
export type MahnungenSperrenMutationResult = Apollo.MutationResult<MahnungenSperrenMutation>;
export type MahnungenSperrenMutationOptions = Apollo.BaseMutationOptions<MahnungenSperrenMutation, MahnungenSperrenMutationVariables>;
