import { useState } from 'react';
import { TimelineMode } from './timelineCtx';

export interface ITimelineState {
  visible: boolean;
  mode: TimelineMode;
}

export const useTimelineOpen = () => {
  const [timelineDrawer, setTimelineDrawer] = useState<ITimelineState>({ visible: false, mode: TimelineMode.create });
  const openTimelineCreateDrawer = () => setTimelineDrawer({ visible: true, mode: TimelineMode.create });
  const openTimelineUpdateDrawer = () => setTimelineDrawer({ visible: true, mode: TimelineMode.update });
  const closeTimelineDrawer = () => setTimelineDrawer({ visible: false, mode: TimelineMode.create });

  return {
    timelineDrawer,
    openTimelineCreateDrawer,
    openTimelineUpdateDrawer,
    closeTimelineDrawer,
  };
};
