import styled from 'styled-components';
import { List } from 'antd';
import { ListProps } from 'antd/lib/list';

export const StyledBuchungPositionListStyle = styled(List)`
  .ant-list-items {
    > .ant-list-item {
      border-bottom: 1px dashed #f0f0f0 !important;
      &:last-child {
        border-bottom: none !important;
      }
    }
  }
` as <T>(props: ListProps<T>) => React.ReactElement;
