import React, { FC } from 'react';
import { Empty } from 'antd';
import abrechnungskreisTableColumns from './abrechnungskreisTableColumns';
import TableWithColSelector from '../../../components/Table/TableWithColSelector/TableWithColSelector';
import { Abrechnungskreis } from '../../../types';

type Props = {
  loading: boolean;
  abrechnungskreisList?: Abrechnungskreis[];
  refetch: () => void;
};

const AbrechnungskreisTable: FC<Props> = ({ loading, abrechnungskreisList, refetch }) => {
  return (
    <TableWithColSelector<Abrechnungskreis>
      size="small"
      locale={{
        emptyText: <Empty description={<span>Keine Abrechnungskreise vorhanden</span>} />,
      }}
      loading={loading}
      scroll={{ x: 900 }}
      dataSource={abrechnungskreisList}
      columns={abrechnungskreisTableColumns(refetch)}
      rowKey={(record) => record.abrechnungskreisId}
      filterIdentifier="sys-settings-abrechungskreis"
    />
  );
};

export default AbrechnungskreisTable;
