import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { OffenePostenFieldsFragmentDoc } from './OffenePostenFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OffenePostenQueryVariables = Types.Exact<{
  offenePostenId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type OffenePostenQuery = {
  getOffenePosten: {
    data: {
      belegDatum: string;
      belegFileId?: string | null;
      belegnummer: string;
      buchungId: string;
      buchungsdatum: string;
      buchungskreisId: string;
      buchungskreisRechtstraegerId: string;
      buchungskreisRechtstraegerBezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      dataCarrierBelegId?: string | null;
      dueDate: string;
      gesamtBetrag: number;
      iban?: string | null;
      letzteMahnstufe: number;
      offenePostenId: string;
      offenerBetrag: number;
      statementNumber?: string | null;
      auszifferungList: Array<{
        auszifferungId: string;
        auszifferungsDatum: string;
        betrag: number;
        bookingSuggestionId?: string | null;
        buchungId: string;
        buchungsdatum: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        offenePostenId: string;
        storniert: boolean;
        storniertByMitarbeiterId?: string | null;
        storniertTs?: string | null;
        stornierteOffenePostenId?: string | null;
        stornoOffenePostenId?: string | null;
        updatedByMitarbeiterId?: string | null;
        zahlungId?: string | null;
        source: { text: string; value: Types.AuszifferungSource };
      }>;
      belegSymbol: { text: string; value: Types.FibuBelegSymbol; abbreviation: string };
      konto: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        haupt: boolean;
        kontoId: string;
        kontoTemplateId?: string | null;
        nummer: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        klasse: { text: string; value: Types.KontoKlasse };
        status: { description?: string | null; text: string; value: Types.KontoStatus };
        type: { text: string; value: Types.KontoType };
        verwendung?: { text: string; value: Types.KontoVerwendung } | null;
      };
      vertragspartner: {
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
      };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type OffenePostenListQueryVariables = Types.Exact<{
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buchungskreisId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  kontoId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  kontoType?: Types.InputMaybe<Types.OffenePostenKontoType>;
  buchungsType?: Types.InputMaybe<Types.FibuBuchungType>;
  vertragsartList?: Types.InputMaybe<Array<Types.Vertragsart> | Types.Vertragsart>;
  dueDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dueDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  belegDatumFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  belegDatumTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buchungsdatumFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buchungsdatumTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  offenerBetragFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  offenerBetragTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  gesamtBetragFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  gesamtBetragTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  letzteMahnstufe?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  vertragspartnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  belegnummer?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createdByMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  includeAusgeziffert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  order?: Types.InputMaybe<Types.OffenePostenOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type OffenePostenListQuery = {
  getOffenePostenList: {
    data: {
      contentList: Array<{
        belegDatum: string;
        belegFileId?: string | null;
        belegnummer: string;
        buchungId: string;
        buchungsdatum: string;
        buchungskreisId: string;
        buchungskreisRechtstraegerId: string;
        buchungskreisRechtstraegerBezeichnung: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        dataCarrierBelegId?: string | null;
        dueDate: string;
        gesamtBetrag: number;
        iban?: string | null;
        letzteMahnstufe: number;
        offenePostenId: string;
        offenerBetrag: number;
        statementNumber?: string | null;
        auszifferungList: Array<{
          auszifferungId: string;
          auszifferungsDatum: string;
          betrag: number;
          bookingSuggestionId?: string | null;
          buchungId: string;
          buchungsdatum: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          offenePostenId: string;
          storniert: boolean;
          storniertByMitarbeiterId?: string | null;
          storniertTs?: string | null;
          stornierteOffenePostenId?: string | null;
          stornoOffenePostenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          zahlungId?: string | null;
          source: { text: string; value: Types.AuszifferungSource };
        }>;
        belegSymbol: { text: string; value: Types.FibuBelegSymbol; abbreviation: string };
        konto: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          haupt: boolean;
          kontoId: string;
          kontoTemplateId?: string | null;
          nummer: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          klasse: { text: string; value: Types.KontoKlasse };
          status: { description?: string | null; text: string; value: Types.KontoStatus };
          type: { text: string; value: Types.KontoType };
          verwendung?: { text: string; value: Types.KontoVerwendung } | null;
        };
        vertragspartner: {
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
        };
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type OffenePostenListSummaryQueryVariables = Types.Exact<{
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buchungskreisId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  kontoId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  kontoType?: Types.InputMaybe<Types.OffenePostenKontoType>;
  buchungsType?: Types.InputMaybe<Types.FibuBuchungType>;
  vertragsartList?: Types.InputMaybe<Array<Types.Vertragsart> | Types.Vertragsart>;
  dueDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dueDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  belegDatumFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  belegDatumTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buchungsdatumFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buchungsdatumTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  offenerBetragFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  offenerBetragTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  gesamtBetragFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  gesamtBetragTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  letzteMahnstufe?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  vertragspartnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  belegnummer?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createdByMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  includeAusgeziffert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type OffenePostenListSummaryQuery = {
  getOffenePostenListSummary: {
    data: { sumBrutto: number; sumOutstandingAmount: number };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type OffenePostenKontoTypeListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type OffenePostenKontoTypeListQuery = {
  getOffenePostenKontoTypeList: {
    data: Array<{ text: string; value: Types.OffenePostenKontoType }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type OffenePostenBelegnummerListQueryVariables = Types.Exact<{
  belegnummer?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type OffenePostenBelegnummerListQuery = {
  getOffenePostenBelegnummerList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const OffenePostenDocument = gql`
  query OffenePosten($offenePostenId: ID!, $withDetails: Boolean) {
    getOffenePosten(offenePostenId: $offenePostenId, withDetails: $withDetails) {
      data {
        ...OffenePostenFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${OffenePostenFieldsFragmentDoc}
`;
export function useOffenePostenQuery(
  baseOptions: Apollo.QueryHookOptions<OffenePostenQuery, OffenePostenQueryVariables> &
    ({ variables: OffenePostenQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OffenePostenQuery, OffenePostenQueryVariables>(OffenePostenDocument, options);
}
export function useOffenePostenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OffenePostenQuery, OffenePostenQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OffenePostenQuery, OffenePostenQueryVariables>(OffenePostenDocument, options);
}
export function useOffenePostenSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OffenePostenQuery, OffenePostenQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OffenePostenQuery, OffenePostenQueryVariables>(OffenePostenDocument, options);
}
export type OffenePostenQueryHookResult = ReturnType<typeof useOffenePostenQuery>;
export type OffenePostenLazyQueryHookResult = ReturnType<typeof useOffenePostenLazyQuery>;
export type OffenePostenSuspenseQueryHookResult = ReturnType<typeof useOffenePostenSuspenseQuery>;
export type OffenePostenQueryResult = Apollo.QueryResult<OffenePostenQuery, OffenePostenQueryVariables>;
export const OffenePostenListDocument = gql`
  query OffenePostenList(
    $objektId: String
    $buchungskreisId: String
    $rechtstraegerId: String
    $kontoId: String
    $kontoType: OffenePostenKontoType
    $buchungsType: FibuBuchungType
    $vertragsartList: [Vertragsart!]
    $dueDateFrom: String
    $dueDateTo: String
    $belegDatumFrom: String
    $belegDatumTo: String
    $buchungsdatumFrom: String
    $buchungsdatumTo: String
    $offenerBetragFrom: Float
    $offenerBetragTo: Float
    $gesamtBetragFrom: Float
    $gesamtBetragTo: Float
    $letzteMahnstufe: Int
    $vertragspartnerId: String
    $belegnummer: String
    $createTsFrom: String
    $createTsTo: String
    $createdByMitarbeiterIdList: [String!]
    $includeAusgeziffert: Boolean
    $order: OffenePostenOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getOffenePostenList(
      objektId: $objektId
      buchungskreisId: $buchungskreisId
      rechtstraegerId: $rechtstraegerId
      kontoId: $kontoId
      kontoType: $kontoType
      buchungsType: $buchungsType
      vertragsartList: $vertragsartList
      dueDateFrom: $dueDateFrom
      dueDateTo: $dueDateTo
      belegDatumFrom: $belegDatumFrom
      belegDatumTo: $belegDatumTo
      buchungsdatumFrom: $buchungsdatumFrom
      buchungsdatumTo: $buchungsdatumTo
      offenerBetragFrom: $offenerBetragFrom
      offenerBetragTo: $offenerBetragTo
      gesamtBetragFrom: $gesamtBetragFrom
      gesamtBetragTo: $gesamtBetragTo
      letzteMahnstufe: $letzteMahnstufe
      vertragspartnerId: $vertragspartnerId
      belegnummer: $belegnummer
      createTsFrom: $createTsFrom
      createTsTo: $createTsTo
      createdByMitarbeiterIdList: $createdByMitarbeiterIdList
      includeAusgeziffert: $includeAusgeziffert
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          ...OffenePostenFields
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${OffenePostenFieldsFragmentDoc}
`;
export function useOffenePostenListQuery(baseOptions?: Apollo.QueryHookOptions<OffenePostenListQuery, OffenePostenListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OffenePostenListQuery, OffenePostenListQueryVariables>(OffenePostenListDocument, options);
}
export function useOffenePostenListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OffenePostenListQuery, OffenePostenListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OffenePostenListQuery, OffenePostenListQueryVariables>(OffenePostenListDocument, options);
}
export function useOffenePostenListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OffenePostenListQuery, OffenePostenListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OffenePostenListQuery, OffenePostenListQueryVariables>(OffenePostenListDocument, options);
}
export type OffenePostenListQueryHookResult = ReturnType<typeof useOffenePostenListQuery>;
export type OffenePostenListLazyQueryHookResult = ReturnType<typeof useOffenePostenListLazyQuery>;
export type OffenePostenListSuspenseQueryHookResult = ReturnType<typeof useOffenePostenListSuspenseQuery>;
export type OffenePostenListQueryResult = Apollo.QueryResult<OffenePostenListQuery, OffenePostenListQueryVariables>;
export const OffenePostenListSummaryDocument = gql`
  query OffenePostenListSummary(
    $objektId: String
    $buchungskreisId: String
    $rechtstraegerId: String
    $kontoId: String
    $kontoType: OffenePostenKontoType
    $buchungsType: FibuBuchungType
    $vertragsartList: [Vertragsart!]
    $dueDateFrom: String
    $dueDateTo: String
    $belegDatumFrom: String
    $belegDatumTo: String
    $buchungsdatumFrom: String
    $buchungsdatumTo: String
    $offenerBetragFrom: Float
    $offenerBetragTo: Float
    $gesamtBetragFrom: Float
    $gesamtBetragTo: Float
    $letzteMahnstufe: Int
    $vertragspartnerId: String
    $belegnummer: String
    $createTsFrom: String
    $createTsTo: String
    $createdByMitarbeiterIdList: [String!]
    $includeAusgeziffert: Boolean
  ) {
    getOffenePostenListSummary(
      objektId: $objektId
      buchungskreisId: $buchungskreisId
      rechtstraegerId: $rechtstraegerId
      kontoId: $kontoId
      kontoType: $kontoType
      buchungsType: $buchungsType
      vertragsartList: $vertragsartList
      dueDateFrom: $dueDateFrom
      dueDateTo: $dueDateTo
      belegDatumFrom: $belegDatumFrom
      belegDatumTo: $belegDatumTo
      buchungsdatumFrom: $buchungsdatumFrom
      buchungsdatumTo: $buchungsdatumTo
      offenerBetragFrom: $offenerBetragFrom
      offenerBetragTo: $offenerBetragTo
      gesamtBetragFrom: $gesamtBetragFrom
      gesamtBetragTo: $gesamtBetragTo
      letzteMahnstufe: $letzteMahnstufe
      vertragspartnerId: $vertragspartnerId
      belegnummer: $belegnummer
      createTsFrom: $createTsFrom
      createTsTo: $createTsTo
      createdByMitarbeiterIdList: $createdByMitarbeiterIdList
      includeAusgeziffert: $includeAusgeziffert
    ) {
      data {
        sumBrutto
        sumOutstandingAmount
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useOffenePostenListSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<OffenePostenListSummaryQuery, OffenePostenListSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OffenePostenListSummaryQuery, OffenePostenListSummaryQueryVariables>(OffenePostenListSummaryDocument, options);
}
export function useOffenePostenListSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OffenePostenListSummaryQuery, OffenePostenListSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OffenePostenListSummaryQuery, OffenePostenListSummaryQueryVariables>(OffenePostenListSummaryDocument, options);
}
export function useOffenePostenListSummarySuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OffenePostenListSummaryQuery, OffenePostenListSummaryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OffenePostenListSummaryQuery, OffenePostenListSummaryQueryVariables>(OffenePostenListSummaryDocument, options);
}
export type OffenePostenListSummaryQueryHookResult = ReturnType<typeof useOffenePostenListSummaryQuery>;
export type OffenePostenListSummaryLazyQueryHookResult = ReturnType<typeof useOffenePostenListSummaryLazyQuery>;
export type OffenePostenListSummarySuspenseQueryHookResult = ReturnType<typeof useOffenePostenListSummarySuspenseQuery>;
export type OffenePostenListSummaryQueryResult = Apollo.QueryResult<OffenePostenListSummaryQuery, OffenePostenListSummaryQueryVariables>;
export const OffenePostenKontoTypeListDocument = gql`
  query OffenePostenKontoTypeList {
    getOffenePostenKontoTypeList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useOffenePostenKontoTypeListQuery(
  baseOptions?: Apollo.QueryHookOptions<OffenePostenKontoTypeListQuery, OffenePostenKontoTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OffenePostenKontoTypeListQuery, OffenePostenKontoTypeListQueryVariables>(OffenePostenKontoTypeListDocument, options);
}
export function useOffenePostenKontoTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OffenePostenKontoTypeListQuery, OffenePostenKontoTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OffenePostenKontoTypeListQuery, OffenePostenKontoTypeListQueryVariables>(OffenePostenKontoTypeListDocument, options);
}
export function useOffenePostenKontoTypeListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OffenePostenKontoTypeListQuery, OffenePostenKontoTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OffenePostenKontoTypeListQuery, OffenePostenKontoTypeListQueryVariables>(OffenePostenKontoTypeListDocument, options);
}
export type OffenePostenKontoTypeListQueryHookResult = ReturnType<typeof useOffenePostenKontoTypeListQuery>;
export type OffenePostenKontoTypeListLazyQueryHookResult = ReturnType<typeof useOffenePostenKontoTypeListLazyQuery>;
export type OffenePostenKontoTypeListSuspenseQueryHookResult = ReturnType<typeof useOffenePostenKontoTypeListSuspenseQuery>;
export type OffenePostenKontoTypeListQueryResult = Apollo.QueryResult<OffenePostenKontoTypeListQuery, OffenePostenKontoTypeListQueryVariables>;
export const OffenePostenBelegnummerListDocument = gql`
  query OffenePostenBelegnummerList($belegnummer: String) {
    getOffenePostenBelegnummerList(belegnummer: $belegnummer) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useOffenePostenBelegnummerListQuery(
  baseOptions?: Apollo.QueryHookOptions<OffenePostenBelegnummerListQuery, OffenePostenBelegnummerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OffenePostenBelegnummerListQuery, OffenePostenBelegnummerListQueryVariables>(OffenePostenBelegnummerListDocument, options);
}
export function useOffenePostenBelegnummerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OffenePostenBelegnummerListQuery, OffenePostenBelegnummerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OffenePostenBelegnummerListQuery, OffenePostenBelegnummerListQueryVariables>(
    OffenePostenBelegnummerListDocument,
    options
  );
}
export function useOffenePostenBelegnummerListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OffenePostenBelegnummerListQuery, OffenePostenBelegnummerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OffenePostenBelegnummerListQuery, OffenePostenBelegnummerListQueryVariables>(
    OffenePostenBelegnummerListDocument,
    options
  );
}
export type OffenePostenBelegnummerListQueryHookResult = ReturnType<typeof useOffenePostenBelegnummerListQuery>;
export type OffenePostenBelegnummerListLazyQueryHookResult = ReturnType<typeof useOffenePostenBelegnummerListLazyQuery>;
export type OffenePostenBelegnummerListSuspenseQueryHookResult = ReturnType<typeof useOffenePostenBelegnummerListSuspenseQuery>;
export type OffenePostenBelegnummerListQueryResult = Apollo.QueryResult<OffenePostenBelegnummerListQuery, OffenePostenBelegnummerListQueryVariables>;
