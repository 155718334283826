import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import {
  WeAbrechnungsdefinitionVersionListEntryFieldsFragmentDoc,
  WeAbrechnungsdefinitionVersionFieldsFragmentDoc,
} from '../../../Abrechnungsdefinition/gql/WeAbrDef/WeAbrDefVersionFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WeAbrechnungsdefinitionTemplateVersionListQueryVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
}>;

export type WeAbrechnungsdefinitionTemplateVersionListQuery = {
  getWeAbrechnungsdefinitionTemplateVersionList: {
    data: Array<{
      abrechnungsdefinitionVersionId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kommentar?: string | null;
      lastUpdateTs: string;
      textBausteineBefuellt: boolean;
      validFrom: string;
      historicizedList?: Array<{
        abrechnungsdefinitionVersionId: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        textBausteineBefuellt: boolean;
        validFrom: string;
        warningList: Array<{ message: string; type: string }>;
      }> | null;
      warningList: Array<{ message: string; type: string }>;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type WeAbrechnungsdefinitionTemplateVersionQueryVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Types.Scalars['ID']['input'];
  searchInHistory?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type WeAbrechnungsdefinitionTemplateVersionQuery = {
  getWeAbrechnungsdefinitionTemplateVersion: {
    data: {
      abrechnungsdefinitionVersionId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      validFrom: string;
      kommentar?: string | null;
      lastUpdateTs: string;
      abrechdefAbrechkreisList: Array<{
        abrechnungskreis: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vorschreibungsposition?: {
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          vorschreibungspositionId: string;
          art: { text: string; value: Types.VorschreibungspositionArt };
          kontierungstabelle?: {
            basiskonto: number;
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kontierungstabelleId: string;
            updatedByMitarbeiterId?: string | null;
            status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
            subAdministrationExpenseAccount?: {
              bezeichnung: string;
              firmendatenId?: string | null;
              kontoId: string;
              kontoKlasse: string;
              nummer: string;
            } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.VorschreibungspositionStatus; description?: string | null };
          umsatzsteuerkennzeichen?: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ message: string; type: string; attribute?: string | null }>;
          } | null;
          warningList: Array<{ message: string; type: string; attribute?: string | null }>;
        } | null;
      }>;
      kontoAbrechnungskreisList: Array<{
        abrechnen: boolean;
        buchungszeilenAnzeigen: boolean;
        expenseReducing: boolean;
        ustVomAufwand: boolean;
        abrechnungskreis?: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
      objektAbrechnungOutputOptions: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        displayEinnahmen: boolean;
        displayKontonummer: boolean;
        updatedByMitarbeiterId?: string | null;
        buchungsdetails: { text: string; value: Types.BuchungsdetailsType };
        consolidateEinnahmen?: { text: string; value: Types.ConsolidateEinnahmenType } | null;
      };
      objektAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      topAbrechnungOutputOptions: {
        considerRueckstand: boolean;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        displayDueDate: boolean;
        displayRueckstandOtherPersons: boolean;
        displayZahlungsverlauf: boolean;
        rueckstandOtherPersonsThreshold?: number | null;
        updatedByMitarbeiterId?: string | null;
        dueDateDisplayOptionEigentuemerGuthaben?: { month?: number | null; type: { text: string; value: Types.DueDateDisplayOptionType } } | null;
        dueDateDisplayOptionEigentuemerNachzahlung?: { month?: number | null; type: { text: string; value: Types.DueDateDisplayOptionType } } | null;
        dueDateDisplayOptionMieter?: { month?: number | null; type: { text: string; value: Types.DueDateDisplayOptionType } } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      topAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      warningList: Array<{ message: string; type: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const WeAbrechnungsdefinitionTemplateVersionListDocument = gql`
  query WeAbrechnungsdefinitionTemplateVersionList($abrechnungsdefinitionId: ID!) {
    getWeAbrechnungsdefinitionTemplateVersionList(abrechnungsdefinitionId: $abrechnungsdefinitionId) {
      data {
        ...WeAbrechnungsdefinitionVersionListEntryFields
        historicizedList {
          ...WeAbrechnungsdefinitionVersionListEntryFields
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${WeAbrechnungsdefinitionVersionListEntryFieldsFragmentDoc}
`;
export function useWeAbrechnungsdefinitionTemplateVersionListQuery(
  baseOptions: Apollo.QueryHookOptions<WeAbrechnungsdefinitionTemplateVersionListQuery, WeAbrechnungsdefinitionTemplateVersionListQueryVariables> &
    ({ variables: WeAbrechnungsdefinitionTemplateVersionListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WeAbrechnungsdefinitionTemplateVersionListQuery, WeAbrechnungsdefinitionTemplateVersionListQueryVariables>(
    WeAbrechnungsdefinitionTemplateVersionListDocument,
    options
  );
}
export function useWeAbrechnungsdefinitionTemplateVersionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WeAbrechnungsdefinitionTemplateVersionListQuery, WeAbrechnungsdefinitionTemplateVersionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WeAbrechnungsdefinitionTemplateVersionListQuery, WeAbrechnungsdefinitionTemplateVersionListQueryVariables>(
    WeAbrechnungsdefinitionTemplateVersionListDocument,
    options
  );
}
export function useWeAbrechnungsdefinitionTemplateVersionListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<WeAbrechnungsdefinitionTemplateVersionListQuery, WeAbrechnungsdefinitionTemplateVersionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<WeAbrechnungsdefinitionTemplateVersionListQuery, WeAbrechnungsdefinitionTemplateVersionListQueryVariables>(
    WeAbrechnungsdefinitionTemplateVersionListDocument,
    options
  );
}
export type WeAbrechnungsdefinitionTemplateVersionListQueryHookResult = ReturnType<typeof useWeAbrechnungsdefinitionTemplateVersionListQuery>;
export type WeAbrechnungsdefinitionTemplateVersionListLazyQueryHookResult = ReturnType<typeof useWeAbrechnungsdefinitionTemplateVersionListLazyQuery>;
export type WeAbrechnungsdefinitionTemplateVersionListSuspenseQueryHookResult = ReturnType<
  typeof useWeAbrechnungsdefinitionTemplateVersionListSuspenseQuery
>;
export type WeAbrechnungsdefinitionTemplateVersionListQueryResult = Apollo.QueryResult<
  WeAbrechnungsdefinitionTemplateVersionListQuery,
  WeAbrechnungsdefinitionTemplateVersionListQueryVariables
>;
export const WeAbrechnungsdefinitionTemplateVersionDocument = gql`
  query WeAbrechnungsdefinitionTemplateVersion($abrechnungsdefinitionId: ID!, $abrechnungsdefinitionVersionId: ID!, $searchInHistory: Boolean) {
    getWeAbrechnungsdefinitionTemplateVersion(
      abrechnungsdefinitionId: $abrechnungsdefinitionId
      abrechnungsdefinitionVersionId: $abrechnungsdefinitionVersionId
      searchInHistory: $searchInHistory
    ) {
      data {
        ...WeAbrechnungsdefinitionVersionFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${WeAbrechnungsdefinitionVersionFieldsFragmentDoc}
`;
export function useWeAbrechnungsdefinitionTemplateVersionQuery(
  baseOptions: Apollo.QueryHookOptions<WeAbrechnungsdefinitionTemplateVersionQuery, WeAbrechnungsdefinitionTemplateVersionQueryVariables> &
    ({ variables: WeAbrechnungsdefinitionTemplateVersionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WeAbrechnungsdefinitionTemplateVersionQuery, WeAbrechnungsdefinitionTemplateVersionQueryVariables>(
    WeAbrechnungsdefinitionTemplateVersionDocument,
    options
  );
}
export function useWeAbrechnungsdefinitionTemplateVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WeAbrechnungsdefinitionTemplateVersionQuery, WeAbrechnungsdefinitionTemplateVersionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WeAbrechnungsdefinitionTemplateVersionQuery, WeAbrechnungsdefinitionTemplateVersionQueryVariables>(
    WeAbrechnungsdefinitionTemplateVersionDocument,
    options
  );
}
export function useWeAbrechnungsdefinitionTemplateVersionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<WeAbrechnungsdefinitionTemplateVersionQuery, WeAbrechnungsdefinitionTemplateVersionQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<WeAbrechnungsdefinitionTemplateVersionQuery, WeAbrechnungsdefinitionTemplateVersionQueryVariables>(
    WeAbrechnungsdefinitionTemplateVersionDocument,
    options
  );
}
export type WeAbrechnungsdefinitionTemplateVersionQueryHookResult = ReturnType<typeof useWeAbrechnungsdefinitionTemplateVersionQuery>;
export type WeAbrechnungsdefinitionTemplateVersionLazyQueryHookResult = ReturnType<typeof useWeAbrechnungsdefinitionTemplateVersionLazyQuery>;
export type WeAbrechnungsdefinitionTemplateVersionSuspenseQueryHookResult = ReturnType<typeof useWeAbrechnungsdefinitionTemplateVersionSuspenseQuery>;
export type WeAbrechnungsdefinitionTemplateVersionQueryResult = Apollo.QueryResult<
  WeAbrechnungsdefinitionTemplateVersionQuery,
  WeAbrechnungsdefinitionTemplateVersionQueryVariables
>;
