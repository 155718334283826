import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { Drawer } from 'antd';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import HauptTag from '../../../../Vertrag/BeVertrag/Versand/Form/shared/HauptTag';
import { useToggle } from '../../../../../hooks/useToggle';
import { HAUPT_TAG_TEXT } from '../../../../../components/Tag/tagHelpers';
import EmailContactCreateForm from '../EmailContact/Create/EmailContactCreateForm';
import { Contact, RechtstraegerType } from '../../../../../types';
import { useCreateNewItemInSelect } from '../../../../../components/Select/useCreateNewItemInSelect';

type Props = {
  disabled: boolean;
  emailList: (Contact | null | undefined)[];
  onNewItemCreated: (emailContactId: string) => void;
  onNewItemCreateSelected: () => void;
  rechtstraegerType: RechtstraegerType | undefined;
  rechtstraegerId: string;
} & SelectProps;

const ContactEmailSelect: FC<Props> = ({
  disabled,
  emailList,
  onNewItemCreated,
  onNewItemCreateSelected,
  rechtstraegerType,
  rechtstraegerId,
  ...restProps
}) => {
  const [isCreateNewDrawerOpen, toggleCreateNewDrawer] = useToggle();

  const onNewItemCreateSelectedFn = () => {
    onNewItemCreateSelected();
    toggleCreateNewDrawer();
  };

  const { handleSelect, handleSearch, renderCreateNewItem } = useCreateNewItemInSelect(emailList, onNewItemCreateSelectedFn, 'Neue E-Mail anlegen');

  const onSuccess = (contactId: string) => {
    onNewItemCreated(contactId);
    toggleCreateNewDrawer();
  };
  const selectOptions = (
    <Select
      id={restProps.name}
      placeholder="E-Mail-Adresse auswählen"
      allowClear
      showSearch
      loading={restProps.loading}
      filterOption={selectFilterOption}
      onSelect={handleSelect}
      onSearch={handleSearch}
      disabled={disabled}
      {...restProps}
    >
      <Select.Option key={HAUPT_TAG_TEXT} value={HAUPT_TAG_TEXT}>
        <HauptTag />
      </Select.Option>
      {emailList.map((item) => {
        return item ? (
          <Select.Option key={item.contactId} value={item.contactId}>
            {item.wert}
          </Select.Option>
        ) : null;
      })}
      {renderCreateNewItem()}
    </Select>
  );
  return (
    <>
      {selectOptions}
      {rechtstraegerType && (
        <Drawer mask={false} title="E-Mail anlegen" width={720} open={isCreateNewDrawerOpen} onClose={toggleCreateNewDrawer} destroyOnClose>
          <EmailContactCreateForm
            onSuccess={(contactIdList) => onSuccess(contactIdList[0])}
            onCancel={toggleCreateNewDrawer}
            rechtstraegerType={rechtstraegerType}
            rechtstraegerId={rechtstraegerId}
            allowCreateMultipleEmailContact={false}
          />
        </Drawer>
      )}
    </>
  );
};

export default ContactEmailSelect;
