import React, { FC } from 'react';
import { Button, Space, Spin, Typography } from 'antd';
import { HistoryOutlined, PlusOutlined } from '@ant-design/icons';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';
import { useRepFondsEinstellungListQuery } from '../gql/RepFondsEinstellungQueries.types';

type Props = {
  openTimelineCreateDrawer: () => void;
  openTimelineUpdateDrawer: () => void;
};

const RepFondsKontenZuweisungListingEmpty: FC<Props> = ({ openTimelineCreateDrawer, openTimelineUpdateDrawer }) => {
  const { data, loading } = useRepFondsEinstellungListQuery();

  const futureRepFondsEinstellung = data?.getRepFondsEinstellungList.data;

  return (
    <Space direction="vertical" align="center" style={{ width: '100%' }}>
      {loading ? (
        <Spin />
      ) : (
        <>
          <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
            <Button onClick={openTimelineCreateDrawer} size="small">
              <PlusOutlined />
              Neue Gültigkeit anlegen
            </Button>
          </ButtonsAligned>

          {futureRepFondsEinstellung && futureRepFondsEinstellung.length > 0 && (
            <Space align="baseline">
              <Typography.Title level={5}>Es existieren in der Zukunft liegende Reparatur Fonds Kontenzuweisungen</Typography.Title>
              <HistoryOutlined onClick={openTimelineUpdateDrawer} />
            </Space>
          )}
        </>
      )}
    </Space>
  );
};

export default RepFondsKontenZuweisungListingEmpty;
