import React, { FC } from 'react';
import { Box } from 'rebass';
import { Skeleton } from 'antd';
import MediaCard from './MediaCard';
import { useFileInfoForFirmendatenQuery } from '../../Document/gql/FileStorageQueries.types';
import { useGetPreviewForFirmendaten } from '../../Document/useFileDownloadAsyncForDocument';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';

type Props = {
  fileId: string;
};

const IncomingInvoicePreviewSaved: FC<Props> = ({ fileId }) => {
  const { activeForFirmendatenId } = useFDAuthorized();

  const { data: maybeFileInfo, loading: isFileInfoLoading } = useFileInfoForFirmendatenQuery({
    variables: { firmendatenId: activeForFirmendatenId, fileId },
  });
  const fileInfo = maybeFileInfo?.getFileInfoForFirmendaten.data;

  const { data: maybePreview, isLoading: isPreviewLoading } = useGetPreviewForFirmendaten(activeForFirmendatenId, fileId);
  const mediaUrl = maybePreview ? URL.createObjectURL(maybePreview as any) : undefined;

  return isPreviewLoading || isFileInfoLoading ? (
    <Box p={48}>
      <Skeleton.Image />
    </Box>
  ) : (
    <MediaCard mediaUrl={mediaUrl} fileInfo={fileInfo} />
  );
};

export default IncomingInvoicePreviewSaved;
