import { FormFields } from '../../../../../helpers/formikHelper';
import { ContactCreateInput, ContactPerson, ContactPersonCreateInput, ContactPersonUpdateInput, ContactUpdateInput } from '../../../../../types';
import { ContactType } from '../../../../../shared/components/Contact/contactHelper';
import { ContactModel, mapFormValuesToContactList } from '../../../../../shared/components/Contact/contactMapper';

export type ContactPersonFormValues = {
  contactPersonId?: string;
  salutation?: string | null;
  firstname: string;
  lastname: string;
  title?: string | null;
  titleTrailing?: string | null;
  phoneList: ContactModel[];
  emailList: ContactModel[];
  rechtstraegerId?: string;
};

export const contactPersonFormFields: FormFields<ContactPersonFormValues> = {
  contactPersonId: 'contactPersonId',
  salutation: 'salutation',
  firstname: 'firstname',
  lastname: 'lastname',
  title: 'title',
  titleTrailing: 'titleTrailing',
  phoneList: 'phoneList',
  emailList: 'emailList',
  rechtstraegerId: 'rechtstraegerId',
};

export const contactPersonFormInitialValues: ContactPersonFormValues = {
  salutation: undefined,
  firstname: '',
  lastname: '',
  phoneList: [],
  emailList: [],
};

export const mapContactPersonToFormValues = (contactPerson: ContactPerson): ContactPersonFormValues => ({
  contactPersonId: contactPerson.contactPersonId,
  salutation: contactPerson.salutation?.value,
  firstname: contactPerson.firstname,
  lastname: contactPerson.lastname,
  title: contactPerson.title,
  titleTrailing: contactPerson.titleTrailing,
  phoneList: contactPerson.contactList.filter((contact) => contact.type === ContactType.PHONE),
  emailList: contactPerson.contactList.filter((contact) => contact.type === ContactType.EMAIL),
  rechtstraegerId: contactPerson.rechtstraeger?.rechtstraegerId,
});

export const mapFormValuesToContactPersonUpdate = (values: ContactPersonFormValues): ContactPersonUpdateInput => {
  const contactList: ContactUpdateInput[] = mapFormValuesToContactList(values.emailList, values.phoneList);

  return {
    contactPersonId: values.contactPersonId,
    salutation: values.salutation,
    title: values.title,
    firstname: values.firstname,
    lastname: values.lastname,
    titleTrailing: values.titleTrailing,
    rechtstraegerId: values.rechtstraegerId,
    contactList,
  };
};

export const mapFormValuesToContactPersonCreate = (values: ContactPersonFormValues, operatingSiteId: string): ContactPersonCreateInput => {
  const contactList: ContactCreateInput[] = [
    ...mapContactFormValuesToContactList(ContactType.EMAIL, operatingSiteId, values.emailList),
    ...mapContactFormValuesToContactList(ContactType.PHONE, operatingSiteId, values.phoneList),
  ];

  return {
    salutation: values.salutation,
    title: values.title,
    firstname: values.firstname,
    lastname: values.lastname,
    titleTrailing: values.titleTrailing,
    rechtstraegerId: values.rechtstraegerId,
    contactList,
    operatingSiteId,
  };
};

const mapContactFormValuesToContactList = (type: ContactType, operatingSiteId: string, contactList?: ContactModel[]): ContactCreateInput[] => {
  return (
    contactList
      ?.filter((contact) => contact.wert)
      .map((contact) => ({
        type,
        wert: contact.wert,
        operatingSiteId,
      })) ?? []
  );
};
