import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { KundenSystemFragmentDoc } from './KundenSystemFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KundenSystemListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type KundenSystemListQuery = {
  getKundenSystemList: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kundenSystemId: string;
      name: string;
      erzeugtVonFirma: {
        companyRegistrationNumber?: string | null;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firmendatenId: string;
        kundenSystemId: string;
        kurzBezeichnung: string;
        name1: string;
        name2?: string | null;
        name3?: string | null;
        sepaCreditorId?: string | null;
        vatIdentificationNumber?: string | null;
        firmeneinstellung: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          dueDateVorschreibung: { text: string; value: string };
        };
        legalForm: { text: string; value: Types.LegalForm };
        rolleList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          name: string;
          rolleId: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        status: { description?: string | null; text: string; value: Types.FirmendatenStatus };
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      };
      firmendatenList: Array<{
        companyRegistrationNumber?: string | null;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firmendatenId: string;
        kundenSystemId: string;
        kurzBezeichnung: string;
        name1: string;
        name2?: string | null;
        name3?: string | null;
        sepaCreditorId?: string | null;
        vatIdentificationNumber?: string | null;
        firmeneinstellung: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          dueDateVorschreibung: { text: string; value: string };
        };
        legalForm: { text: string; value: Types.LegalForm };
        rolleList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          name: string;
          rolleId: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        status: { description?: string | null; text: string; value: Types.FirmendatenStatus };
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      }>;
      status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type KundenSystemQueryVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
}>;

export type KundenSystemQuery = {
  getKundenSystem: {
    data: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kundenSystemId: string;
      name: string;
      erzeugtVonFirma: {
        companyRegistrationNumber?: string | null;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firmendatenId: string;
        kundenSystemId: string;
        kurzBezeichnung: string;
        name1: string;
        name2?: string | null;
        name3?: string | null;
        sepaCreditorId?: string | null;
        vatIdentificationNumber?: string | null;
        firmeneinstellung: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          dueDateVorschreibung: { text: string; value: string };
        };
        legalForm: { text: string; value: Types.LegalForm };
        rolleList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          name: string;
          rolleId: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        status: { description?: string | null; text: string; value: Types.FirmendatenStatus };
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      };
      firmendatenList: Array<{
        companyRegistrationNumber?: string | null;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firmendatenId: string;
        kundenSystemId: string;
        kurzBezeichnung: string;
        name1: string;
        name2?: string | null;
        name3?: string | null;
        sepaCreditorId?: string | null;
        vatIdentificationNumber?: string | null;
        firmeneinstellung: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          dueDateVorschreibung: { text: string; value: string };
        };
        legalForm: { text: string; value: Types.LegalForm };
        rolleList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          name: string;
          rolleId: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        status: { description?: string | null; text: string; value: Types.FirmendatenStatus };
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      }>;
      status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const KundenSystemListDocument = gql`
  query KundenSystemList {
    getKundenSystemList {
      data {
        ...KundenSystem
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KundenSystemFragmentDoc}
`;
export function useKundenSystemListQuery(baseOptions?: Apollo.QueryHookOptions<KundenSystemListQuery, KundenSystemListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KundenSystemListQuery, KundenSystemListQueryVariables>(KundenSystemListDocument, options);
}
export function useKundenSystemListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KundenSystemListQuery, KundenSystemListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KundenSystemListQuery, KundenSystemListQueryVariables>(KundenSystemListDocument, options);
}
export function useKundenSystemListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KundenSystemListQuery, KundenSystemListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KundenSystemListQuery, KundenSystemListQueryVariables>(KundenSystemListDocument, options);
}
export type KundenSystemListQueryHookResult = ReturnType<typeof useKundenSystemListQuery>;
export type KundenSystemListLazyQueryHookResult = ReturnType<typeof useKundenSystemListLazyQuery>;
export type KundenSystemListSuspenseQueryHookResult = ReturnType<typeof useKundenSystemListSuspenseQuery>;
export type KundenSystemListQueryResult = Apollo.QueryResult<KundenSystemListQuery, KundenSystemListQueryVariables>;
export const KundenSystemDocument = gql`
  query KundenSystem($kundenSystemId: ID!) {
    getKundenSystem(kundenSystemId: $kundenSystemId) {
      data {
        ...KundenSystem
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KundenSystemFragmentDoc}
`;
export function useKundenSystemQuery(
  baseOptions: Apollo.QueryHookOptions<KundenSystemQuery, KundenSystemQueryVariables> &
    ({ variables: KundenSystemQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KundenSystemQuery, KundenSystemQueryVariables>(KundenSystemDocument, options);
}
export function useKundenSystemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KundenSystemQuery, KundenSystemQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KundenSystemQuery, KundenSystemQueryVariables>(KundenSystemDocument, options);
}
export function useKundenSystemSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KundenSystemQuery, KundenSystemQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KundenSystemQuery, KundenSystemQueryVariables>(KundenSystemDocument, options);
}
export type KundenSystemQueryHookResult = ReturnType<typeof useKundenSystemQuery>;
export type KundenSystemLazyQueryHookResult = ReturnType<typeof useKundenSystemLazyQuery>;
export type KundenSystemSuspenseQueryHookResult = ReturnType<typeof useKundenSystemSuspenseQuery>;
export type KundenSystemQueryResult = Apollo.QueryResult<KundenSystemQuery, KundenSystemQueryVariables>;
