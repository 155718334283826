import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { mahnungListingFiltersFormFields, MahnungListingFiltersFormValues, mahnungListingLabelList } from './mahnungListingFiltersFormMapper';
import FiltersWith4OrMoreFields from '../../../components/Filters/FiltersWith4OrMoreFields';
import MahnungCreateButtonAndModal from '../Form/Create/MahnungCreateButtonAndModal';
import MahnungTableBulkActions from './Table/MahnungTableBulkActions';
import { TMahnungQueryParams } from './Filters/filtersQueryParams';
import FiltersSelect from '../../../components/Filters/FiltersSelect';
import { onFilterSelectChange } from '../../../components/Filters/FiltersHelpers';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import ObjektSelect from '../../ObjektSelect/ObjektSelect';
import FromToFormDatePicker from '../../shared/FromToForm/FromToFormDatePicker';
import MitarbeiterSelect from '../../../shared/components/MitarbeiterSelect/MitarbeiterSelect';
import RechnungsAusstellerBuchungskreisSelect from '../../../shared/components/RechnungsAusstellerSelect/RechnungsAusstellerBuchungskreisSelect';
import VertragspartnerKontoFormSelect from '../../../shared/components/Vertragspartner/VertragspartnerKontoFormSelect';
import MahnungStatusSelect from './Filters/MahnungStatusSelect';
import FromToFormInputNumber from '../../shared/FromToForm/FromToFormInputNumber';
import useFilterWithQueryParams from '../../shared/useFilterWithQueryParams';
import YesOrNoFormSelect from '../../shared/YesOrNoFormSelect';

type Props = {
  formikProps: FormikProps<MahnungListingFiltersFormValues>;
  refetch: () => void;
  queryParams: TMahnungQueryParams;
};

const MahnungListingFilters: FC<Props> = ({ formikProps, refetch, queryParams }) => {
  const defaultSelectedFilterList = [
    mahnungListingFiltersFormFields.buchungskreisId,
    mahnungListingFiltersFormFields.objektId,
    mahnungListingFiltersFormFields.vertragspartnerId,
    mahnungListingFiltersFormFields.mahnstichtagVonInklusive,
    mahnungListingFiltersFormFields.statusList,
    mahnungListingFiltersFormFields.erstellerMitarbeiterIdList,
  ];

  const mapFilterLabelToQueryFilterList = (filter: string) => {
    if (filter === mahnungListingFiltersFormFields.mahnstichtagVonInklusive || filter === mahnungListingFiltersFormFields.mahnstichtagBisInklusive) {
      return mahnungListingFiltersFormFields.mahnstichtagVonInklusive;
    }
    if (
      filter === mahnungListingFiltersFormFields.sumRechnungsbetragVonInklusive ||
      filter === mahnungListingFiltersFormFields.sumRechnungsbetragBisInklusive
    ) {
      return mahnungListingFiltersFormFields.sumRechnungsbetragVonInklusive;
    }
    if (
      filter === mahnungListingFiltersFormFields.sumMahnbetragVonInklusive ||
      filter === mahnungListingFiltersFormFields.sumMahnbetragBisInklusive
    ) {
      return mahnungListingFiltersFormFields.sumMahnbetragVonInklusive;
    }
    if (filter === mahnungListingFiltersFormFields.mahngebuehrVonInklusive || filter === mahnungListingFiltersFormFields.mahngebuehrBisInklusive) {
      return mahnungListingFiltersFormFields.mahngebuehrVonInklusive;
    }
    if (
      filter === mahnungListingFiltersFormFields.verzugszinsenVonInklusive ||
      filter === mahnungListingFiltersFormFields.verzugszinsenBisInklusive
    ) {
      return mahnungListingFiltersFormFields.verzugszinsenVonInklusive;
    }
    if (filter === mahnungListingFiltersFormFields.createTsFrom || filter === mahnungListingFiltersFormFields.createTsTo) {
      return mahnungListingFiltersFormFields.createTsFrom;
    }
    return filter;
  };

  const filters = (filter: string) => {
    if (filter === mahnungListingFiltersFormFields.buchungskreisId) {
      return (
        <FormItemWithoutColon name={mahnungListingFiltersFormFields.buchungskreisId} label="Buchungskreis" key={filter}>
          <RechnungsAusstellerBuchungskreisSelect
            name={mahnungListingFiltersFormFields.buchungskreisId}
            onChange={(rechtstraeger) => {
              formikProps.setFieldValue(mahnungListingFiltersFormFields.rechnungsAusstellerId, rechtstraeger?.rechtstraegerId ?? '', false);
              formikProps.setFieldValue(mahnungListingFiltersFormFields.objektId, '');
              formikProps.setFieldValue(mahnungListingFiltersFormFields.vertragspartnerId, '');
              formikProps.submitForm();
            }}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === mahnungListingFiltersFormFields.objektId) {
      return (
        <FormItemWithoutColon name={mahnungListingFiltersFormFields.objektId} label="Objekt" key={filter}>
          <ObjektSelect
            name={mahnungListingFiltersFormFields.objektId}
            rechnungsAusstellerId={formikProps.values.rechnungsAusstellerId}
            disabled={!formikProps.values.buchungskreisId}
            onChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === mahnungListingFiltersFormFields.vertragspartnerId) {
      return (
        <FormItemWithoutColon name={mahnungListingFiltersFormFields.vertragspartnerId} label="Vertragspartner" key={filter}>
          <VertragspartnerKontoFormSelect
            name={mahnungListingFiltersFormFields.vertragspartnerId}
            buchungskreisId={formikProps.values.buchungskreisId}
            onChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === mahnungListingFiltersFormFields.statusList) {
      return (
        <FormItemWithoutColon name={mahnungListingFiltersFormFields.statusList} label="Status" key={filter}>
          <MahnungStatusSelect name={mahnungListingFiltersFormFields.statusList} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === mahnungListingFiltersFormFields.mahnstichtagVonInklusive) {
      return (
        <FormItemWithoutColon name="mahnstichtag" label="Mahnstichtag" key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={mahnungListingFiltersFormFields.mahnstichtagVonInklusive}
            toName={mahnungListingFiltersFormFields.mahnstichtagBisInklusive}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === mahnungListingFiltersFormFields.erstellerMitarbeiterIdList) {
      return (
        <FormItemWithoutColon name={mahnungListingFiltersFormFields.erstellerMitarbeiterIdList} label="Ersteller" key={filter}>
          <MitarbeiterSelect mode="multiple" name={mahnungListingFiltersFormFields.erstellerMitarbeiterIdList} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === mahnungListingFiltersFormFields.sumRechnungsbetragVonInklusive) {
      return (
        <FromToFormInputNumber
          name="Summe Rechnungsbetrag"
          fromName={mahnungListingFiltersFormFields.sumRechnungsbetragVonInklusive}
          toName={mahnungListingFiltersFormFields.sumRechnungsbetragBisInklusive}
          onChange={formikProps.submitForm}
          key={filter}
        />
      );
    }
    if (filter === mahnungListingFiltersFormFields.sumMahnbetragVonInklusive) {
      return (
        <FromToFormInputNumber
          name="Summe Mahnbetrag"
          fromName={mahnungListingFiltersFormFields.sumMahnbetragVonInklusive}
          toName={mahnungListingFiltersFormFields.sumMahnbetragBisInklusive}
          onChange={formikProps.submitForm}
          key={filter}
        />
      );
    }
    if (filter === mahnungListingFiltersFormFields.mahngebuehrVonInklusive) {
      return (
        <FromToFormInputNumber
          name="Mahngebühr"
          fromName={mahnungListingFiltersFormFields.mahngebuehrVonInklusive}
          toName={mahnungListingFiltersFormFields.mahngebuehrBisInklusive}
          onChange={formikProps.submitForm}
          key={filter}
        />
      );
    }
    if (filter === mahnungListingFiltersFormFields.verzugszinsenVonInklusive) {
      return (
        <FromToFormInputNumber
          name="Verzugszinsen"
          fromName={mahnungListingFiltersFormFields.verzugszinsenVonInklusive}
          toName={mahnungListingFiltersFormFields.verzugszinsenBisInklusive}
          onChange={formikProps.submitForm}
          key={filter}
        />
      );
    }
    if (filter === mahnungListingFiltersFormFields.versandEinschreiben) {
      return (
        <FormItemWithoutColon name={mahnungListingFiltersFormFields.versandEinschreiben} label="Versand per Einschreiben" key={filter}>
          <YesOrNoFormSelect name={mahnungListingFiltersFormFields.versandEinschreiben} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === mahnungListingFiltersFormFields.createTsFrom) {
      return (
        <FormItemWithoutColon name="erstelltAm" label="Erstellt am" key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={mahnungListingFiltersFormFields.createTsFrom}
            toName={mahnungListingFiltersFormFields.createTsTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    return undefined;
  };

  const updateFilterValues = (selectedFilterList: string[]) => {
    const { buchungskreisId, objektId, vertragspartnerId } = formikProps.values;
    if (!buchungskreisId) {
      objektId && formikProps.setFieldValue(mahnungListingFiltersFormFields.objektId, '');
      vertragspartnerId && formikProps.setFieldValue(mahnungListingFiltersFormFields.vertragspartnerId, '');
    }
    if (!selectedFilterList.includes(mahnungListingFiltersFormFields.mahnstichtagVonInklusive) && formikProps.values.mahnstichtagBisInklusive) {
      formikProps.setFieldValue(mahnungListingFiltersFormFields.mahnstichtagBisInklusive, undefined);
    }
    if (
      !selectedFilterList.includes(mahnungListingFiltersFormFields.sumRechnungsbetragVonInklusive) &&
      formikProps.values.sumRechnungsbetragBisInklusive
    ) {
      formikProps.setFieldValue(mahnungListingFiltersFormFields.sumRechnungsbetragBisInklusive, undefined);
    }
    if (!selectedFilterList.includes(mahnungListingFiltersFormFields.sumMahnbetragVonInklusive) && formikProps.values.sumMahnbetragBisInklusive) {
      formikProps.setFieldValue(mahnungListingFiltersFormFields.sumMahnbetragBisInklusive, undefined);
    }
    if (!selectedFilterList.includes(mahnungListingFiltersFormFields.mahngebuehrVonInklusive) && formikProps.values.mahngebuehrBisInklusive) {
      formikProps.setFieldValue(mahnungListingFiltersFormFields.mahngebuehrBisInklusive, undefined);
    }
    if (!selectedFilterList.includes(mahnungListingFiltersFormFields.verzugszinsenVonInklusive) && formikProps.values.verzugszinsenBisInklusive) {
      formikProps.setFieldValue(mahnungListingFiltersFormFields.verzugszinsenBisInklusive, undefined);
    }
    if (!selectedFilterList.includes(mahnungListingFiltersFormFields.createTsFrom) && formikProps.values.createTsTo) {
      formikProps.setFieldValue(mahnungListingFiltersFormFields.createTsTo, undefined);
    }
    formikProps.submitForm();
  };

  const { selectedFilterList, setSelectedFilterList, leftColumn, rightColumn } = useFilterWithQueryParams({
    defaultSelectedFilterList,
    updateFilterValues,
    filters,
    labelList: mahnungListingLabelList,
    mapFilterLabelToQueryFilterList,
    queryParams,
  });

  return (
    <FiltersWith4OrMoreFields
      hideTitle
      filtersSelect={
        <FiltersSelect
          defaultSelectedFilterList={defaultSelectedFilterList}
          selectedFilterList={selectedFilterList}
          onChange={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
          labelList={mahnungListingLabelList}
        />
      }
      actionButtons={<MahnungCreateButtonAndModal />}
      filterActions={<MahnungTableBulkActions formikProps={formikProps} onSuccess={refetch} />}
      leftColumn={<>{leftColumn}</>}
      rightColumn={<>{rightColumn}</>}
    />
  );
};

export default MahnungListingFilters;
