import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Checkbox, CheckboxProps } from 'formik-antd';
import { Checkbox as CheckboxAntd, Skeleton, Space } from 'antd';
import { useValuePreservationTypeListQuery } from './gql/ValuePreservationQueries.types';
import {
  indexedValueFormFields,
  IndexedValueFormValues,
} from '../../Vertrag/BeVertrag/Vertragsdaten/VPosIndexationAgreement/ListingButtonsAndModal/indexedValuesCreateFormMapper';
import {
  isValuePreservationTypeIndexAnnual,
  isValuePreservationTypeIndexThreshold,
} from '../../Vertrag/BeVertrag/Vertragsdaten/VPosIndexationAgreement/Table/Level2/CreateUpdateIndexationAgreement/indexationAgreementHelpers';
import { ValuePreservationType } from '../../../types';

type Props = {
  name: string;
  formikProps: FormikProps<IndexedValueFormValues>;
} & CheckboxProps;

const ValuePreservationTypeCheckbox: FC<Props> = ({ name, formikProps }) => {
  const { data, loading } = useValuePreservationTypeListQuery();

  const valuePreservationTypeList = data?.getValuePreservationTypeList.data;

  // allowPreliminaryValues should be set to false if no value preservation of type IndexAnnual or Index Threshold is selected
  const handleChange = (valueList: any[]) => {
    if (
      !valueList.some(
        (value) =>
          isValuePreservationTypeIndexAnnual(value as ValuePreservationType) || isValuePreservationTypeIndexThreshold(value as ValuePreservationType)
      )
    ) {
      formikProps.setFieldValue(indexedValueFormFields.allowPreliminaryValues, false);
    }
  };

  return (
    <Skeleton active loading={loading}>
      <Checkbox.Group name={name} onChange={(checkedValue) => handleChange(checkedValue)}>
        <Space direction="vertical">
          {valuePreservationTypeList?.map((valuePreservationType) => {
            return (
              <CheckboxAntd key={valuePreservationType.value} value={valuePreservationType.value}>
                {valuePreservationType.text}
              </CheckboxAntd>
            );
          })}
        </Space>
      </Checkbox.Group>
    </Skeleton>
  );
};

export default ValuePreservationTypeCheckbox;
