import React, { FC } from 'react';
import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useCreateBuchungskreisForRechtstraegerMutation } from '../gql/RechnungsAusstellerMutations.types';
import { showSuccessMsgCreate } from '../../../../../components/message';
import ListingTitleWithButtons from '../../../../../components/Listing/ListingTitleWithButtons';
import { Firma, NatuerlichePerson, Personengemeinschaft } from '../../../../../types';
import { RechnungsAusstellerFieldsFragment } from '../gql/RechnungsAusstellerFragments.types';

type Props = {
  rechnungsAussteller: RechnungsAusstellerFieldsFragment;
  rechtstraeger: Firma | NatuerlichePerson | Personengemeinschaft;
  onSuccess: () => void;
};

const RechnungsAusstellerBuchungskreisAnlegenBtn: FC<Props> = ({ rechnungsAussteller, rechtstraeger, onSuccess }) => {
  const [runCreate] = useCreateBuchungskreisForRechtstraegerMutation({
    onCompleted: () => {
      showSuccessMsgCreate(`Buchungskreis`);
      onSuccess();
    },
  });

  return (
    <ListingTitleWithButtons
      title="Bankkonto"
      buttons={
        <Button
          icon={<PlusOutlined />}
          type="primary"
          size="small"
          disabled={rechnungsAussteller.buchungskreis}
          onClick={() => showConfirm(rechtstraeger, runCreate)}
        >
          Buchungskreis anlegen
        </Button>
      }
    />
  );
};

const showConfirm = (
  rechtstraeger: Firma | NatuerlichePerson | Personengemeinschaft,
  runCreate: (rechtstraeger: { variables: { rechtstraegerId: string } }) => void
) => {
  Modal.confirm({
    title: 'Möchten Sie Buchungskreis für diesen Rechnungsaussteller anlegen?',
    content: `${rechtstraeger.kurzBezeichnung}`,
    icon: <ExclamationCircleOutlined />,
    okText: 'Ja',
    cancelText: 'Nein',
    onOk() {
      return runCreate({ variables: { rechtstraegerId: rechtstraeger.rechtstraegerId } });
    },
  });
};

export default RechnungsAusstellerBuchungskreisAnlegenBtn;
