import { FC } from 'react';
import HistoryListingTable from '../History/HistoryListingTable';
import { HistoryType } from '../History/shared/HistoryModal';
import { useSubAdministrationSettingsChangeHistoryQuery } from '../Rechtstraeger/Rolle/RechnungsAussteller/gql/RechnungsAusstellerQueries.types';

type Props = {
  rechtstraegerId: string;
};

const SubAdministrationSettingsChangeHistoryListingTable: FC<Props> = ({ rechtstraegerId }) => {
  const { data, loading } = useSubAdministrationSettingsChangeHistoryQuery({ variables: { rechtstraegerId } });
  const historyList = data?.getSubAdministrationSettingsChangeHistory.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="sub-administration" />;
};

export default SubAdministrationSettingsChangeHistoryListingTable;
