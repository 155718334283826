import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import PaymentPage from './PaymentPage';
import { URI_PAYMENT_PAGE } from '../../features/Payment/paymentUriPaths';

const paymentPageRoute = (
  <Route path={URI_PAYMENT_PAGE} element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<PaymentPage />} />} />
);

export default paymentPageRoute;
