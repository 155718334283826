import React, { FC } from 'react';
import { Form, Input } from 'formik-antd';
import { Formik } from 'formik';
import FormButtons from '../../../../components/Button/FormButtons';
import { showSuccessMsgCreate, showSuccessMsgDelete, showSuccessMsgUpdate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import {
  mapFormValuesToOperatingSite,
  mapOperatingSiteToFormValues,
  operatingSiteFormFields,
  operatingSiteFormInitialValues,
  OperatingSiteFormValues,
} from './operatingSiteFormMapper';
import { operatingSiteFormValidationSchema } from './operatingSiteFormValidationSchema';
import { TOperatingSiteList } from '../List/OperatingSiteList';
import {
  useCreateFirmaOperatingSiteMutation,
  useUpdateFirmaOperatingSiteMutation,
} from '../../../../features/Rechtstraeger/Firma/gql/FirmaMutations.types';
import {
  useCreateFirmendatenOperatingSiteMutation,
  useUpdateFirmendatenOperatingSiteMutation,
} from '../../../../features/KundenSystem/Firmendaten/gql/FirmendatenOperatingSiteMutations.types';
import { useGetAppFieldHelpText } from '../../../../features/FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  firmendatenId?: string;
  firmaId?: string;
  operatingSite?: TOperatingSiteList;
  onCancel: () => void;
  onSuccess: () => void;
};

const OperatingSiteForm: FC<Props> = ({ firmendatenId, firmaId, operatingSite, onCancel, onSuccess }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'OperatingSite'>('OperatingSite');

  const entity = 'Betriebsstätte';
  const [runCreateFirmaBs, { loading: loadingFirmaBs }] = useCreateFirmaOperatingSiteMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runCreateFirmendatenBs, { loading: loadingFirmadatenBs }] = useCreateFirmendatenOperatingSiteMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runFirmaBsUpdate] = useUpdateFirmaOperatingSiteMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });
  const [runFirmendatenBsUpdate] = useUpdateFirmendatenOperatingSiteMutation({
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<OperatingSiteFormValues>
      initialValues={operatingSite ? mapOperatingSiteToFormValues(operatingSite) : operatingSiteFormInitialValues}
      validationSchema={operatingSiteFormValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const request = mapFormValuesToOperatingSite(values);
        if (firmaId) {
          operatingSite
            ? runFirmaBsUpdate({
                variables: {
                  firmaId,
                  operatingSiteId: operatingSite.operatingSiteId,
                  request,
                },
              }).finally(() => setSubmitting(false))
            : runCreateFirmaBs({ variables: { firmaId, request } }).finally(() => setSubmitting(false));
        } else if (firmendatenId) {
          operatingSite
            ? runFirmendatenBsUpdate({
                variables: {
                  firmendatenId,
                  operatingSiteId: operatingSite.operatingSiteId,
                  request,
                },
              }).finally(() => setSubmitting(false))
            : runCreateFirmendatenBs({ variables: { firmendatenId, request } }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form>
          <FormItemWithFieldHelp
            name={operatingSiteFormFields.bezeichnung}
            label="Bezeichnung"
            fieldHelp={getFieldHelpText('OperatingSite.bezeichnung')}
          >
            <Input id={operatingSiteFormFields.bezeichnung} name={operatingSiteFormFields.bezeichnung} placeholder="Bezeichnung" />
          </FormItemWithFieldHelp>
          <FormButtons
            updateMode={!!operatingSite}
            onCancel={onCancel}
            isOkLoading={loadingFirmaBs || loadingFirmadatenBs}
            isOkDisabled={formikProps.isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default OperatingSiteForm;
