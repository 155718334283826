import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../styles/pdfStyles';
import { ZVorschreibungspositionSummaryHeaderText } from '../../zinsliste-types';
import { zVposColumnWidthsAndAlignment } from '../styles/zVPos-table-styles';

type Props = {
  vPosSummaryHeaderText: ZVorschreibungspositionSummaryHeaderText;
};

const ZVPosListSummaryListHeader: FC<Props> = ({ vPosSummaryHeaderText }) => {
  return (
    <View style={[pdfStyles.row, pdfStyles.borderBottomSmall, { marginTop: '10mm' }]}>
      <View style={{ width: '40%' }}>
        <Text style={[pdfStyles.textNormal]}>{vPosSummaryHeaderText.vertragsartText}</Text>
      </View>
      <View style={[pdfStyles.row, { width: '60%' }]}>
        <Text style={[pdfStyles.textNormal, { ...zVposColumnWidthsAndAlignment.netto }]}>{vPosSummaryHeaderText.nettoSumText}</Text>
        <Text style={[{ width: zVposColumnWidthsAndAlignment.ustSatz.width }]} />
        <Text style={[{ width: zVposColumnWidthsAndAlignment.ustBetrag.width }]} />
        <Text style={[pdfStyles.textNormal, { ...zVposColumnWidthsAndAlignment.brutto }]}>{vPosSummaryHeaderText.bruttoSumText}</Text>
      </View>
    </View>
  );
};

export default ZVPosListSummaryListHeader;
