import { FC } from 'react';
import { Typography } from 'antd';
import { Spacer } from '../../../components/Grid';
import { IncomingInvoiceBookingCreationGenerierlauf } from '../../../types';
import EntryListing from './EntryListing/EntryListing';

type Props = {
  generierlauf: IncomingInvoiceBookingCreationGenerierlauf;
  title: string;
  verarbeitungType: string;
};

const IncomingInvoiceBookingCreation: FC<Props> = ({ generierlauf, title, verarbeitungType }) => (
  <>
    <Typography.Title level={4}>{title}</Typography.Title>
    <Spacer />
    <EntryListing generierlauf={generierlauf} verarbeitungType={verarbeitungType} />
  </>
);

export default IncomingInvoiceBookingCreation;
