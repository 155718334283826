import { FC } from 'react';
import HistoryListingTable from '../../../../features/History/HistoryListingTable';
import { useFirmendatenEmailContactDeletionHistoryListQuery } from '../../../../features/KundenSystem/Firmendaten/gql/FirmendatenQueries.types';
import { HistoryType } from '../../../../features/History/shared/HistoryModal';

type Props = {
  firmendatenId: string;
};

const FirmendatenEmailContactDeletionHistoryListingTable: FC<Props> = ({ firmendatenId }) => {
  const { data, loading } = useFirmendatenEmailContactDeletionHistoryListQuery({ variables: { firmendatenId } });
  const historyList = data?.getFirmendatenEmailContactDeletionHistoryList.data;

  return (
    <HistoryListingTable historyType={HistoryType.Deletion} historyList={historyList} loading={loading} filterIdPrefix="firmendaten-email-contact" />
  );
};

export default FirmendatenEmailContactDeletionHistoryListingTable;
