import React, { FC, useState } from 'react';
import { Button, List, Space, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useField } from 'formik';
import { useToggle } from '../../../../hooks/useToggle';
import { AbrechdefAbrechkreis, AbrechnungsdefinitionType, Konto } from '../../../../types';
import {
  isAbrDefTypeBkAbrechnung,
  isAbrDefTypeWeAbrechnung,
  isAbrKreisTypeBewirtschaftungskosten,
  isAbrKreisTypeRepFonds,
} from '../../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';

import theme from '../../../../theme';
import { ZuordnungFormValues, ZuordnungKontoVerwendung } from '../../../AbrKontenzuordnung/Form/zuordnungKontoListingFormMapper';
import {
  getBuchungszeilenAnzeigenText,
  getVerwendungVonRepFondsText,
  getZuordnungAbrKreisName,
  isZuordnungKontoKlasseTypeAusgaben,
  isZuordnungKontoKlasseTypeEinnahmen,
  isZuordnungKontoKlasseTypeVerrechnung,
  zuordnungFormFieldArrayListItemStyle,
  ZuordnungKontoKlasseType,
} from '../../../AbrKontenzuordnung/shared/AbrKreisKontenzuordnungHelpers';
import { AbrKontenzuordnungFiltersFormValues } from '../../../AbrKontenzuordnung/Listing/Filters/abrKontenzuordnungFiltersMapper';
import ZuordnungListItemInfosViewAndEdit from '../../../AbrKontenzuordnung/ViewAndEdit/ZuordnungListItemInfosViewAndEdit';
import ZuordnungFormModal from '../ZuordnungFormModal/ZuordnungFormModal';

type Props = {
  item: ZuordnungFormValues;
  abrDefinitionType: AbrechnungsdefinitionType;
  onItemChange: (
    abrechnungskreisId: string,
    ustVomAufwand: ZuordnungKontoVerwendung,
    buchungszeilenAnzeigen: boolean,
    expenseReducing: boolean,
    aufteilungsschluesselId?: string | null
  ) => void;
  nameForAbrKreisSelect: string;
  nameForKontoVerwendung: string;
  nameForBuchungszeilenAnzeigen: string;
  nameForAufteilungsschluesselId: string;
  nameForAufteilungsschluesselBezeichnung: string;
  nameForExpenseReducing: string;
  zuordnungKontoKlasseType: ZuordnungKontoKlasseType;
  abrDefVersionAbrechkreisList: AbrechdefAbrechkreis[];
  filters?: AbrKontenzuordnungFiltersFormValues | null;
  konto?: Konto;
};

const ZuordnungFormFieldArrayListItemAndModal: FC<Props> = ({
  item,
  abrDefinitionType,
  zuordnungKontoKlasseType,
  nameForAbrKreisSelect,
  nameForKontoVerwendung,
  nameForBuchungszeilenAnzeigen,
  nameForAufteilungsschluesselId,
  nameForAufteilungsschluesselBezeichnung,
  nameForExpenseReducing,
  onItemChange,
  filters,
  konto,
  abrDefVersionAbrechkreisList,
}) => {
  const [isModalOpen, toggleIsModalOpen] = useToggle(false);
  const [, { value: abrKreisId }] = useField(nameForAbrKreisSelect);
  const [, { value: ustVomAufwandValue }] = useField(nameForKontoVerwendung);
  const [, { value: buchungszeilenAnzeigen }] = useField(nameForBuchungszeilenAnzeigen);
  const [, { value: aufteilungsschluesselId }] = useField(nameForAufteilungsschluesselId);
  const [, { value: aufteilungsschluesselBezeichnung }] = useField(nameForAufteilungsschluesselBezeichnung);
  const [, { value: expenseReducing }] = useField(nameForExpenseReducing);

  const isAufwandskonto = isZuordnungKontoKlasseTypeAusgaben(zuordnungKontoKlasseType);
  const isAbrKreisRepFonds = isAbrKreisTypeRepFonds(item.abrechnungskreis.abrechnungskreisType);
  const isUstVomAufwand = item.ustVomAufwand === ZuordnungKontoVerwendung.UST_VOM_AUFWAND;
  const initialAbrKreisName = getZuordnungAbrKreisName(item.abrechnungskreis.bezeichnung, isAufwandskonto, isAbrKreisRepFonds, isUstVomAufwand);
  const [abrKreisName, setAbrKreisName] = useState(initialAbrKreisName);

  const filterInUseAsNumber = filters ? Object.keys(filters).length : 0;
  const isActiveBuchungszeilen = filters?.buchungszeilenAnzeigen === getBuchungszeilenAnzeigenText(buchungszeilenAnzeigen);
  const isActiveRepFonds = isAbrKreisRepFonds && filters?.verwendungVonRepFonds === getVerwendungVonRepFondsText(isUstVomAufwand);
  const isActiveDivergentDistributionKey =
    !!filters?.displayAbweichenderAufteilungsschluesselId &&
    filters.displayAbweichenderAufteilungsschluesselId === item.aufteilungsschluessel?.aufteilungsschluesselId;

  const isActiveExpenseReducing =
    isAbrKreisTypeBewirtschaftungskosten(item.abrechnungskreis.abrechnungskreisType) &&
    isZuordnungKontoKlasseTypeEinnahmen(zuordnungKontoKlasseType) &&
    filters?.displayExpenseReducing === item.expenseReducing;

  const showExpenseReducing =
    isAbrKreisTypeBewirtschaftungskosten(item.abrechnungskreis.abrechnungskreisType) &&
    isZuordnungKontoKlasseTypeEinnahmen(zuordnungKontoKlasseType) &&
    zuordnungKontoKlasseType === ZuordnungKontoKlasseType.EINNAHMEN;

  return (isAbrDefTypeWeAbrechnung(abrDefinitionType) || isAbrDefTypeBkAbrechnung(abrDefinitionType)) &&
    isZuordnungKontoKlasseTypeVerrechnung(zuordnungKontoKlasseType) ? (
    <List.Item
      style={zuordnungFormFieldArrayListItemStyle(
        filterInUseAsNumber,
        isActiveBuchungszeilen,
        isActiveRepFonds,
        isActiveDivergentDistributionKey,
        isActiveExpenseReducing
      )}
    >
      <Space direction="vertical">
        <Typography.Text>Nicht auswählbar</Typography.Text>
        <ZuordnungListItemInfosViewAndEdit
          filters={filters}
          isAbrKreisRepFonds={isAbrKreisRepFonds}
          buchungszeilenAnzeigen={buchungszeilenAnzeigen}
          isUstVomAufwand={isUstVomAufwand}
          aufteilungsschluesselBezeichnung={aufteilungsschluesselBezeichnung}
          isExpenseReducing={item.expenseReducing}
          showExpenseReducing={showExpenseReducing}
        />
      </Space>
    </List.Item>
  ) : (
    <List.Item
      style={zuordnungFormFieldArrayListItemStyle(
        filterInUseAsNumber,
        isActiveBuchungszeilen,
        isActiveRepFonds,
        isActiveDivergentDistributionKey,
        isActiveExpenseReducing
      )}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Typography.Text style={{ color: isAbrKreisNameKeineZuordnung(abrKreisName) ? theme.textColor.danger : 'initial' }}>
            {abrKreisName}
          </Typography.Text>
          <Button type="text" icon={<EditOutlined />} onClick={() => toggleIsModalOpen()} shape="circle" />
        </Space>
        <ZuordnungListItemInfosViewAndEdit
          filters={filters}
          isAbrKreisRepFonds={isAbrKreisRepFonds}
          buchungszeilenAnzeigen={buchungszeilenAnzeigen}
          isUstVomAufwand={isUstVomAufwand}
          aufteilungsschluesselBezeichnung={aufteilungsschluesselBezeichnung}
          isExpenseReducing={item.expenseReducing}
          showExpenseReducing={showExpenseReducing}
        />
      </Space>
      <ZuordnungFormModal
        modalTitle={`${konto ? `${konto.nummer} - ${konto.bezeichnung}` : 'Konto'} bearbeiten`}
        item={item}
        nameForAbrKreisSelect={nameForAbrKreisSelect}
        nameForKontoVerwendung={nameForKontoVerwendung}
        nameForBuchungszeilenAnzeigen={nameForBuchungszeilenAnzeigen}
        nameForAufteilungsschluessel={nameForAufteilungsschluesselId}
        nameForAufteilungsschluesselBezeichnung={nameForAufteilungsschluesselBezeichnung}
        expenseReducing={expenseReducing}
        nameForExpenseReducing={nameForExpenseReducing}
        abrDefinitionType={abrDefinitionType}
        isModalOpen={isModalOpen}
        zuordnungKontoKlasseType={zuordnungKontoKlasseType}
        onCancel={toggleIsModalOpen}
        onSubmit={(selectedAbrKreisName) => {
          selectedAbrKreisName !== nameForAbrKreisSelect && setAbrKreisName(selectedAbrKreisName);
          onItemChange(abrKreisId, ustVomAufwandValue, buchungszeilenAnzeigen, expenseReducing, aufteilungsschluesselId);
          toggleIsModalOpen();
        }}
        abrechnungskreisId={abrKreisId}
        abrDefVersionAbrechkreisList={abrDefVersionAbrechkreisList}
      />
    </List.Item>
  );
};

const isAbrKreisNameKeineZuordnung = (abrKreisName: string) => abrKreisName === 'Keine Zuordnung';

export default ZuordnungFormFieldArrayListItemAndModal;
