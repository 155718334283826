import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { friendlyFormatIBAN } from 'ibantools';
import { useIncomingInvoicePayeeBankDetailsListQuery } from '../../gql/IncomingInvoiceFilterQueries.types';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = Omit<SelectProps, 'id' | 'placeholder' | 'size' | 'allowClear' | 'showSearch' | 'filterOption'>;

const PayeeBankDetailsListFormSelect: FC<Props> = ({ ...restProps }) => {
  const { loading, data } = useIncomingInvoicePayeeBankDetailsListQuery();
  const bankDetailsList = data?.getIncomingInvoicePayeeBankDetailsList.data;

  return (
    <Select
      {...restProps}
      id={restProps.name}
      loading={loading}
      size="small"
      placeholder="Bankverbindung auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {bankDetailsList?.map((bankDetails) => {
        return (
          <Select.Option key={bankDetails.bankDetailsId} value={bankDetails.iban}>
            <DataWithShortenedText maxTextLength={40} text={`${bankDetails.accountHolder} ${friendlyFormatIBAN(bankDetails.iban)}`} />
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default PayeeBankDetailsListFormSelect;
