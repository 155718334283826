import { Firma, NatuerlichePerson, ResponseDataWarning, Personengemeinschaft } from '../types';

export function getEntityWarningMsgByAttribute<Entity extends { warningList: ResponseDataWarning[] }>(entity: Entity, attribute: keyof Entity) {
  return entity.warningList.find((warning) => warning.attribute === attribute)?.message;
}

export const getVertragspartnerWarningState = (rechtstraeger: Firma | NatuerlichePerson | Personengemeinschaft) => {
  return rechtstraeger?.warningList.some((warning) => isVertragspartnerRelatedWarning(warning.type)) ? 'warning' : undefined;
};

const isVertragspartnerRelatedWarning = (warning: string) =>
  warning === 'RECHNUNGSADDRESS_MISSING_FOR_VERTRAGSPARTNER' || warning === 'MISSING_POST_ADDRESS';
