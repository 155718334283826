import React from 'react';
import { Link } from 'react-router-dom';
import { Space, Typography } from 'antd';
import { TableWithAlignedColsColumnType } from '../../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';
import { EuroAmount } from '../../../../../../components/Number';
import { HeAbrKonto } from '../../../../../../types';
import { generatePathToKontoblattPage } from '../../../../../Kontoblatt/kontoblattUriPaths';

const heAbrHeVertragKontoRowColumns = (
  objektId: string,
  abrechnungszeitraumVon: string,
  abrechnungszeitraumBis: string,
  buchungskreisId: string
): TableWithAlignedColsColumnType<HeAbrKonto>[] => [
  {
    title: '',
    align: 'left',
    render: (konto) => (
      <Space>
        <Typography.Text>{konto.bezeichnung}</Typography.Text>
        {konto.amountReducingType && <Typography.Text type="secondary"> ({konto.amountReducingType.text})</Typography.Text>}
      </Space>
    ),
  },
  {
    title: '',
    render: (konto) => (
      <Link
        to={generatePathToKontoblattPage({
          kontoId: konto.kontoId,
          objektId,
          buchungsdatumFrom: abrechnungszeitraumVon,
          buchungsdatumTo: abrechnungszeitraumBis,
          buchungskreisId,
        })}
        target="_blank"
      >
        <EuroAmount value={konto.summe} />
      </Link>
    ),
  },
  {
    title: '',
    colSpan: 2,
    render: () => <TextForEmptyValue textToShow="none" />,
  },
];

export default heAbrHeVertragKontoRowColumns;
