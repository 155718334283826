import { Select, SelectProps } from 'formik-antd';
import React, { FC } from 'react';

type Props = Omit<SelectProps, 'id' | 'allowClear'>;

const YesOrNoFormSelect: FC<Props> = ({ name, ...restProps }) => {
  const options = [
    { label: 'Ja', value: true },
    { label: 'Nein', value: false },
  ];

  return (
    <Select placeholder="Ja / Nein auswählen" size="middle" {...restProps} id={name} name={name} allowClear>
      {options.map((option) => (
        <Select.Option key={option.label} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default YesOrNoFormSelect;
