import React from 'react';
import { Navigate } from 'react-router-dom';
import { URI_LOGIN_PAGE } from '../../constants/loginUriPaths';

type ErrorBoundaryForwardToLoginState = {
  hasError: boolean;
};

type ErrorBoundaryForwardToLoginProps = {
  children?: React.ReactNode;
};

class ErrorBoundaryForwardToLogin extends React.Component<ErrorBoundaryForwardToLoginProps, ErrorBoundaryForwardToLoginState> {
  constructor(props: ErrorBoundaryForwardToLoginProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    return hasError ? <Navigate to={URI_LOGIN_PAGE} /> : children;
  }
}

export default ErrorBoundaryForwardToLogin;
