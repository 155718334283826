import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Space, Typography } from 'antd';
import { Notification, NotificationCategory, NotificationStatus } from '../../../../types';
import { findParameterValue, NotificationParameterKeyTypes } from '../../Shared/parameterHelper';
import TaskProgress from '../../Shared/TaskProgress';
import TaskButtons from '../../Shared/TaskButtons';
import { mapStatusToStatusSpecs } from '../../Shared/notificationHelpers';

type NotificationTableColumnsNachrichtProps = {
  notification: Notification;
  style?: React.CSSProperties;
  link?: string;
};

const NotificationTableColumnsNachricht: FC<NotificationTableColumnsNachrichtProps> = ({ notification, style, link }) => {
  const isError = notification.notificationCategory === NotificationCategory.Error;
  const message = findParameterValue(notification.notificationInfo.parameterList, NotificationParameterKeyTypes.message);

  return isError ? (
    <ErrorCollapse notification={notification} />
  ) : (
    <Space align="center" size="middle" style={{ paddingRight: message && '8px' }}>
      {message ? (
        <Typography.Text>{message}</Typography.Text>
      ) : (
        <>
          <TaskProgress notification={notification} style={style} />
          <TaskButtons notification={notification} />
          {notification.progress.total > 0 && (
            <Typography.Text>
              ({notification.progress.processed}/{notification.progress.total})
            </Typography.Text>
          )}
          {notification.status === NotificationStatus.FinishedError &&
            (link ? (
              <Link to={link}>{mapStatusToStatusSpecs(notification.status).text}</Link>
            ) : (
              <Typography.Text strong>{mapStatusToStatusSpecs(notification.status).text}</Typography.Text>
            ))}
        </>
      )}
    </Space>
  );
};

type ErrorPanelProps = {
  notification: Notification;
};

const ErrorCollapse: FC<ErrorPanelProps> = ({ notification }) => {
  const errors = notification.notificationInfo.parameterList.filter(({ key }) => key.includes('errorMessage'));
  return (
    <Collapse ghost>
      <Collapse.Panel key="1" header={`${notification.status} ${notification.notificationCategory}`}>
        {errors.map(({ key, value }) => (
          <Typography.Paragraph key={key}>{value}</Typography.Paragraph>
        ))}
      </Collapse.Panel>
    </Collapse>
  );
};

export default NotificationTableColumnsNachricht;
