import { Document, Page, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { ObjektAbrechnung } from './objektAbrechnung-types';
import pdfStyles from '../styles/pdfStyles';
import OATitleText from './components/OATitleText';
import OAAbrechnungskreisBlock from './components/ObjektAbrechnungTableBlock/AbrechnungskreisBlock/OAAbrechnungskreisBlock';
import Header from '../shared/Header';
import Footer from '../shared/Footer';
import InfoAbrechnungskreisBlock from '../shared/Abrechnungskreis/InfoAbrechnungskreisBlock';
import OAAdressList from './components/OAAdressList';
import OAZeitraum from './components/OAZeitraum';
import OATableColumnTitles from './components/ObjektAbrechnungTableBlock/OATableColumnTitles';

type ObjektAbrechnungTemplateProps = {
  data: ObjektAbrechnung;
  isInEditMode?: boolean;
  selectedTextbaustein?: ObjektAbrechnungSelectableSection;
};

export type ObjektAbrechnungSelectableSection = 'ABRECHNUNGSTITEL';

const ObjektAbrechnungTemplate: FC<ObjektAbrechnungTemplateProps> = ({ data, isInEditMode, selectedTextbaustein }) => (
  <Document>
    {/* TODO: metadata
      following props: should also come from the BE and be specific to the Objekt, Firma, Creator, Keywords (optional)
      * title
      * author
      * subject
      * keywords
      * creator
      * producer
      */}
    <Page size="A4" style={[pdfStyles.body, { paddingBottom: data.footer?.value ? '32mm' : pdfStyles.body.paddingBottom }]}>
      <View>
        {/* header */}
        <Header header={data.header} />
      </View>

      {/* Titeltext with Textbaustein */}
      <OATitleText data={data} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />

      {/* Address list and Zeitraum */}
      <View style={{ paddingLeft: '10mm', paddingRight: '10mm' }} fixed>
        <OAAdressList data={data} />
        <OAZeitraum data={data} />
      </View>

      {/* COLUMN_NAMES */}
      <OATableColumnTitles data={data} />

      {/* Abrechnungskreisblock */}
      <OAAbrechnungskreisBlock data={data} />

      {/* InfoAbrechnungskreisblock */}
      {data.informationAbrechnungskreiseBlock && data.informationAbrechnungskreiseBlock.abrechnungskreisList.length > 0 && (
        <InfoAbrechnungskreisBlock informationAbrechnungskreiseBlock={data.informationAbrechnungskreiseBlock} />
      )}
      {/* Footer */}
      {data.footer?.value && <Footer footer={data.footer} />}
    </Page>
  </Document>
);

export default ObjektAbrechnungTemplate;
