import { generatePath } from 'react-router';
import { FiltersFormValues } from './filtersFormMapper';
import { mapToQueryString, QueryParams } from '../../../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../../../hooks/usePaginationQueryParams';
import { URI_EMAIL_VERSAND_PAGE } from '../../emailDeliveryUriPaths';

export enum QueryParamKey {
  CREATED_BY_MITARBEITER_ID_LIST = 'createdByMitarbeiterIdList',
  CREATE_TS_FROM_INCLUSIVE = 'createTsFromInclusive',
  CREATE_TS_TO_INCLUSIVE = 'createTsToInclusive',
  RECIPIENT = 'recipient',
  RECIPIENT_RECHTSTRAEGER_ID = 'recipientRechtstraegerId',
  STATUS_LIST = 'statusList',
  USE_CASE_LIST = 'useCaseList',
}

export type TEmailDeliveryListingQueryParams = QueryParams<FiltersFormValues>;

export const updateQueryParams = (
  navigate: (path: string) => void,
  filters: TEmailDeliveryListingQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToEmailDeliveryListingPage(filters, paginationParams));

export const generatePathToEmailDeliveryListingPage = (filters: TEmailDeliveryListingQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  `${generatePath(URI_EMAIL_VERSAND_PAGE)}?${mapToQueryString({
    [QueryParamKey.CREATED_BY_MITARBEITER_ID_LIST]: filters?.createdByMitarbeiterIdList,
    [QueryParamKey.CREATE_TS_FROM_INCLUSIVE]: filters?.createTsFromInclusive,
    [QueryParamKey.CREATE_TS_TO_INCLUSIVE]: filters?.createTsToInclusive,
    [QueryParamKey.RECIPIENT]: filters?.recipient,
    [QueryParamKey.RECIPIENT_RECHTSTRAEGER_ID]: filters?.recipientRechtstraegerId,
    [QueryParamKey.STATUS_LIST]: filters?.statusList,
    [QueryParamKey.USE_CASE_LIST]: filters?.useCaseList,
    ...paginationParams,
  })}`;
