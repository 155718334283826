import React, { FC, useEffect, useRef } from 'react';
import { useField } from 'formik';
import { Col, Row, Space, Tag, Typography } from 'antd';
import { $generateHtmlFromNodes } from '@lexical/html';
import { $getSelection, EditorState, LexicalEditor as LexicalEditorType } from 'lexical';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { Spacer } from '../../../../../components/Grid';
import LexicalEditor, { onError } from '../../Lexical/LexicalEditor';
import LexicalTheme from '../../Lexical/LexicalTheme';

type Props = {
  name: string;
  nameForHtml: string;
  platzhalterList: { platzhalter: string; example: string }[];
  disabled?: boolean;
};

const TextbausteinEditor: FC<Props> = ({ name, nameForHtml, platzhalterList, disabled }) => {
  const [field, , helpers] = useField<EditorState>(name);
  const [, , helpersForHtml] = useField<string>(nameForHtml);
  const editorRef = useRef<LexicalEditorType>(null);

  useEffect(() => {
    editorRef.current?.setEditable(!disabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const onChange = () => {
    if (disabled === false) {
      if (editorRef.current) {
        const html = $generateHtmlFromNodes(editorRef.current);
        helpersForHtml.setValue(addLineBreakAfterParagraph(html));
        helpers.setValue(editorRef.current.getEditorState());
      }
    }
  };

  const initialConfig: InitialConfigType = {
    namespace: 'editor' + name,
    theme: LexicalTheme,
    editorState: field.value,
    editable: !disabled,
    onError,
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <Row>
        <Col style={{ width: '100%', marginBottom: '8px' }}>
          <LexicalEditor disabled={disabled} onChange={onChange} editorRef={editorRef} />
        </Col>
        <Spacer />
        <Space direction="vertical">
          <Typography.Text type="secondary">
            {platzhalterList.length > 0 ? 'Verfügbare Platzhalter' : 'Keine verfügbaren Platzhalter'}
          </Typography.Text>

          <Row gutter={[8, 8]}>
            {platzhalterList.map((item, index) => (
              <Col key={index}>
                <Tag onClick={() => pastePlatzhalterToTextArea(item.platzhalter, editorRef.current)} style={{ cursor: 'pointer' }}>
                  {item.platzhalter}
                </Tag>
              </Col>
            ))}
          </Row>
        </Space>
      </Row>
    </LexicalComposer>
  );
};

const pastePlatzhalterToTextArea = (platzhalter: string, editor?: LexicalEditorType | null) => {
  const platzhalterFormatted = `$\{${platzhalter}}`;

  editor?.update(() => {
    const selection = $getSelection();
    if (selection) {
      selection.insertText(platzhalterFormatted);
    }
  });
};

// Add line break after each closing p tag
const addLineBreakAfterParagraph = (html: string) => {
  return html.replace(/<\/p>(?=\s*<p[^>]*>)/g, '</p><br />');
};

export default TextbausteinEditor;
