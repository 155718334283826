import React, { FC } from 'react';
import { Card } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import AddressText from '../../AddressText';
import { Address } from '../../../../../types';

type Props = {
  address: Address;
  removeAddress: () => void; // (fieldNamePrefix: string, formikProps: FormikProps<unknown>) => void;
  getEditFocusOnAddress: () => void; // (fieldNamePrefix: string, formikProps: FormikProps<unknown>, hauptAddress: Record<string, any>) => void;
};

export const AddressWithActionsCard: FC<Props> = ({ address, removeAddress, getEditFocusOnAddress, ...restProps }) => {
  if (address) {
    return (
      <Card
        title="Adresse"
        size="small"
        actions={[
          <OptionalDelete key="delete" removeAddress={removeAddress} />,
          <OptionalEdit key="edit" getEditFocusOnAddress={getEditFocusOnAddress} />,
        ]}
        style={{ width: '100%' }}
        {...restProps}
      >
        <AddressText address={address} oneLine />
      </Card>
    );
  }
  return null;
};

export const OptionalDelete = (props: { removeAddress: () => void }) =>
  props.removeAddress ? <DeleteOutlined onClick={props.removeAddress} /> : null;

export const OptionalEdit = (props: { getEditFocusOnAddress: () => void }) =>
  props.getEditFocusOnAddress ? <EditOutlined onClick={props.getEditFocusOnAddress} /> : null;

export default AddressWithActionsCard;
