import React, { FC } from 'react';
import { Text } from '@react-pdf/renderer';
import pdfStyles, { selectableStyles } from '../../styles/pdfStyles';
import { MahndefinitionSelectableSection } from '../MahnungTemplate';

const MahnungTitleText: FC<{ titleText: string; isInEditMode?: boolean; selectedTextbaustein?: MahndefinitionSelectableSection }> = ({
  titleText,
  isInEditMode,
  selectedTextbaustein,
}) => {
  // if in edit mode then we need to render the platzhalter also if it is an empty string so that the user see where the textbaustein will occur in the PDF
  if (isInEditMode) {
    return (
      <Text
        style={[
          pdfStyles.textNormal,
          {
            marginTop: '10mm',
            textAlign: 'center',
            fontWeight: 'bold',
          },
          selectableStyles(isInEditMode, selectedTextbaustein === 'TITELTEXT'),
        ]}
      >
        {titleText}
      </Text>
    );
  }

  return titleText === '' ? null : (
    <Text
      style={[
        pdfStyles.textNormal,
        {
          marginTop: '10mm',
          textAlign: 'center',
          fontWeight: 'bold',
        },
      ]}
    >
      {titleText}
    </Text>
  );
};

export default MahnungTitleText;
