import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import KontoFormSelect from '../../../KontoSelect/KontoFormSelect';
import { useUpdateFirmendatenBuchhaltungEinstellungMutation } from '../../gql/FirmendatenBuchhaltungEinstellungMutations.types';
import { showSuccessMsgUpdate } from '../../../../components/message';
import { KontoKlasse } from '../../../../types';
import FormButtons from '../../../../components/Button/FormButtons';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import { FilterFormValues, formFormFields, formInitialValues, mapFormValuesToInput } from './billingAccountFormMapper';

type Props = {
  heAbrechnungVerrechnungskontoId?: string | null;
  subAdministrationBillingAccountId?: string | null;
  onSuccess: () => void;
  onCancel: () => void;
};

const BillingAccountForm: FC<Props> = ({ heAbrechnungVerrechnungskontoId, subAdministrationBillingAccountId, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'FirmendatenBuchhaltungEinstellung'>('FirmendatenBuchhaltungEinstellung');

  const [runUpdate] = useUpdateFirmendatenBuchhaltungEinstellungMutation({
    onCompleted: () => {
      showSuccessMsgUpdate('Allgemein Firmendaten Einstellungen');
      onSuccess();
    },
  });

  return (
    <>
      <Formik<FilterFormValues>
        initialValues={formInitialValues(heAbrechnungVerrechnungskontoId, subAdministrationBillingAccountId)}
        onSubmit={(values, { setSubmitting }) => {
          const input = mapFormValuesToInput(values);
          runUpdate({ variables: { input } }).finally(() => setSubmitting(false));
        }}
      >
        {(formikProps) => (
          <Form layout="vertical">
            <FormItemWithFieldHelp
              name={formFormFields.heAbrechnungVerrechnungskontoId}
              label="Verrechnungskonto Hauseigentümer"
              fieldHelp={getFieldHelpText('FirmendatenBuchhaltungEinstellung.heAbrechnungVerrechnungskontoId')}
            >
              <KontoFormSelect name={formFormFields.heAbrechnungVerrechnungskontoId} kontoKlasse={[KontoKlasse.Eigenkapital]} />
            </FormItemWithFieldHelp>
            <FormItemWithFieldHelp
              name={formFormFields.subAdministrationBillingAccountId}
              label="Verrechnungskonto Subverwaltung"
              fieldHelp={getFieldHelpText('FirmendatenBuchhaltungEinstellung.subAdministrationBillingAccountId')}
            >
              <KontoFormSelect name={formFormFields.subAdministrationBillingAccountId} kontoKlasse={[KontoKlasse.Eigenkapital]} />
            </FormItemWithFieldHelp>
            <FormButtons updateMode onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default BillingAccountForm;
