import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUstKategorieTemplateMutationVariables = Types.Exact<{
  input: Types.UstKategorieCreateInput;
}>;

export type CreateUstKategorieTemplateMutation = {
  createUstKategorieTemplate: {
    data: { ustKategorieId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateUstKategorieTemplateMutationVariables = Types.Exact<{
  ustKategorieId: Types.Scalars['ID']['input'];
  input: Types.UstKategorieUpdateInput;
}>;

export type UpdateUstKategorieTemplateMutation = {
  updateUstKategorieTemplate: {
    data: { ustKategorieId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteUstKategorieTemplateMutationVariables = Types.Exact<{
  ustKategorieId: Types.Scalars['ID']['input'];
}>;

export type DeleteUstKategorieTemplateMutation = {
  deleteUstKategorieTemplate: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateUstKategorieTemplateDocument = gql`
  mutation CreateUstKategorieTemplate($input: UstKategorieCreateInput!) {
    createUstKategorieTemplate(input: $input) {
      data {
        ustKategorieId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateUstKategorieTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUstKategorieTemplateMutation, CreateUstKategorieTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUstKategorieTemplateMutation, CreateUstKategorieTemplateMutationVariables>(
    CreateUstKategorieTemplateDocument,
    options
  );
}
export type CreateUstKategorieTemplateMutationHookResult = ReturnType<typeof useCreateUstKategorieTemplateMutation>;
export type CreateUstKategorieTemplateMutationResult = Apollo.MutationResult<CreateUstKategorieTemplateMutation>;
export type CreateUstKategorieTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateUstKategorieTemplateMutation,
  CreateUstKategorieTemplateMutationVariables
>;
export const UpdateUstKategorieTemplateDocument = gql`
  mutation UpdateUstKategorieTemplate($ustKategorieId: ID!, $input: UstKategorieUpdateInput!) {
    updateUstKategorieTemplate(ustKategorieId: $ustKategorieId, input: $input) {
      data {
        ustKategorieId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateUstKategorieTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUstKategorieTemplateMutation, UpdateUstKategorieTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUstKategorieTemplateMutation, UpdateUstKategorieTemplateMutationVariables>(
    UpdateUstKategorieTemplateDocument,
    options
  );
}
export type UpdateUstKategorieTemplateMutationHookResult = ReturnType<typeof useUpdateUstKategorieTemplateMutation>;
export type UpdateUstKategorieTemplateMutationResult = Apollo.MutationResult<UpdateUstKategorieTemplateMutation>;
export type UpdateUstKategorieTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateUstKategorieTemplateMutation,
  UpdateUstKategorieTemplateMutationVariables
>;
export const DeleteUstKategorieTemplateDocument = gql`
  mutation DeleteUstKategorieTemplate($ustKategorieId: ID!) {
    deleteUstKategorieTemplate(ustKategorieId: $ustKategorieId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteUstKategorieTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUstKategorieTemplateMutation, DeleteUstKategorieTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUstKategorieTemplateMutation, DeleteUstKategorieTemplateMutationVariables>(
    DeleteUstKategorieTemplateDocument,
    options
  );
}
export type DeleteUstKategorieTemplateMutationHookResult = ReturnType<typeof useDeleteUstKategorieTemplateMutation>;
export type DeleteUstKategorieTemplateMutationResult = Apollo.MutationResult<DeleteUstKategorieTemplateMutation>;
export type DeleteUstKategorieTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteUstKategorieTemplateMutation,
  DeleteUstKategorieTemplateMutationVariables
>;
