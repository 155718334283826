import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { ListingFiltersFormFields, ListingFiltersFormValues } from './listingFiltersFormMapper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import FiltersWithMax3Fields from '../../../../../components/Filters/FiltersWithMax3Fields';
import { AppGenerierlaufType } from '../../../../../types';
import GenerierlaufObjektFormSelect from '../../../shared/Filters/App/GenerierlaufObjektFormSelect';
import GenerierlaufEntryExitCodeListFormSelect from '../../../shared/Filters/App/GenerierlaufEntryExitCodeListFormSelect';

type Props = {
  formikProps: FormikProps<ListingFiltersFormValues>;
  generierlaufId: string;
};

const ListingFilters: FC<Props> = ({ formikProps, generierlaufId }) => {
  return (
    <FiltersWithMax3Fields
      hideTitle
      filters={
        <>
          <FormItemWithoutColon name={ListingFiltersFormFields.objektId} label="Objekt">
            <GenerierlaufObjektFormSelect
              name={ListingFiltersFormFields.objektId}
              generierlaufId={generierlaufId}
              generierlaufType={AppGenerierlaufType.ZinslisteErstellung}
              style={{ width: '250px' }}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>

          <FormItemWithoutColon name={ListingFiltersFormFields.exitCodeList} label="Exit Code Status">
            <GenerierlaufEntryExitCodeListFormSelect
              name={ListingFiltersFormFields.exitCodeList}
              generierlaufId={generierlaufId}
              generierlaufType={AppGenerierlaufType.ZinslisteErstellung}
              style={{ width: '250px' }}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
    />
  );
};

export default ListingFilters;
