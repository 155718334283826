import React, { FC, useState } from 'react';
import { Space, Tabs, TabsProps, Typography } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';
import TabPaneContent from '../../../components/Tabs/TabPaneContent';
import { BookingSuggestion } from '../../../types';
import BuchungsmaskeForm from '../Edit/Form/BuchungsmaskeForm';
import theme from '../../../theme';

type Props = {
  bookingSuggestionList: BookingSuggestion[];
  onUpdateSuccess: () => void;
};

const BookingSuggestionBuchungsmaskeTabs: FC<Props> = ({ bookingSuggestionList, onUpdateSuccess }) => {
  const items: TabsProps['items'] = [];
  const [warningListForTabsLabels, setWarningListForTabsLabels] = useState<boolean[]>(new Array(bookingSuggestionList.length).fill(false));

  const onFormDirtyChange = (index: number, hasWarning: boolean) =>
    setWarningListForTabsLabels((prev) => {
      const newWarningList = [...prev];
      newWarningList[index] = hasWarning;
      return newWarningList;
    });

  bookingSuggestionList.forEach((bookingSuggestion, index) =>
    items.push({
      key: String(index),
      label: (
        <Space size="small">
          {warningListForTabsLabels[index] && <WarningTwoTone twoToneColor={theme.colors.warning} />}
          <Typography.Text>{bookingSuggestion.name}</Typography.Text>
        </Space>
      ),
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <BuchungsmaskeForm
            bookingSuggestion={bookingSuggestion}
            onUpdateSuccess={onUpdateSuccess}
            // eslint-disable-next-line no-return-assign
            setWarningListForTabsLabels={(hasWarning: boolean) => onFormDirtyChange(index, hasWarning)}
          />
        </TabPaneContent>
      ),
    })
  );

  return <Tabs items={items} />;
};

export default BookingSuggestionBuchungsmaskeTabs;
