import React from 'react';
import { Helmet } from 'react-helmet-async';
import { PageLayout } from '../../components/LayoutAndromeda';
import TicketListing from '../../features/Ticket/Listing/TicketListing';
import TicketActionButtonsAndDrawer from '../../features/Ticket/TicketActionButtonsAndDrawer';
import TicketListingFilters from '../../features/Ticket/Listing/TicketListingFilters';
import ticketTableColumns from '../../features/Ticket/Listing/Table/ticketTableColumns';
import ticketTableRechtstraegerColumn from '../../features/Ticket/Listing/Table/ticketTableRechtstraegerColumn';
import ticketTableInfoFeldColumn from '../../features/Ticket/Listing/Table/ticketTableInfoFeldColumn';
import ticketTableObjektColumns from '../../features/Ticket/Listing/Table/ticketTableObjektColumns';
import { URI_TICKET_PAGE } from '../../features/Ticket/ticketUriPaths';

const TicketPage = () => {
  return (
    <>
      <Helmet>
        <title>Aufgaben</title>
      </Helmet>
      <PageLayout>
        <TicketListing
          tableFilterIdentifier="main-ticket"
          pageUri={URI_TICKET_PAGE}
          tableColumns={(onAction) =>
            ticketTableColumns(onAction, [
              ...ticketTableObjektColumns({ isVertagSelected: true, isBestandseinheitSelected: true, isObjektSelected: true }),
              ticketTableRechtstraegerColumn(true),
              ticketTableInfoFeldColumn(true),
            ])
          }
        >
          {({ formikProps, queryParams }) => (
            <TicketListingFilters
              formikProps={formikProps}
              extraFilters={{ objektFilter: true, bestandseinheitFilter: true, vertragFilter: true, rechtstraegerFilter: true }}
              actionButtons={<TicketActionButtonsAndDrawer formikProps={formikProps} />}
              queryParams={queryParams}
            />
          )}
        </TicketListing>
      </PageLayout>
    </>
  );
};

export default TicketPage;
