import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { BankDetailsFieldsFragmentDoc } from '../../../BankDetails/gql/BankDetailsFragments.types';
import { PersonenbezugFragmentDoc } from '../../../Personenbezug/gql/PersonenbezugFragments.types';
import { PostItFragmentDoc } from '../../PostIt/gql/PostItFragments.types';
import { RechtstraegerSteuerpflichtFragmentDoc } from '../../gql/RechtsTraegerFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NatuerlichePersonQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type NatuerlichePersonQuery = {
  getNatuerlichePerson: {
    data: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      fibuKontoCreatable: boolean;
      firstname: string;
      kundennummer: string;
      kurzBezeichnung: string;
      lastname: string;
      rechtstraegerId: string;
      title?: string | null;
      titleTrailing?: string | null;
      tagList: Array<string>;
      bankDetailsList: Array<{
        accountHolder: string;
        accountLimit?: number | null;
        assignedHeVertragZahlungsregelMap: any;
        assignedObjektMap: any;
        bankDetailsId: string;
        bankname: string;
        bic: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        ebicsCreditTransferUserList: Array<string>;
        ebicsDirectDebitUserList: Array<string>;
        fibuKontoId?: string | null;
        fibuKontonummer?: string | null;
        iban: string;
        updatedByMitarbeiterId?: string | null;
        fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
        fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
        sepaMandat?: {
          fileId?: string | null;
          fileName?: string | null;
          sepaMandatId: string;
          sepaMandatReference: string;
          signatureDate?: string | null;
          firmendaten?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firmendatenId: string;
            kundenSystemId: string;
            kurzBezeichnung: string;
            name1: string;
            name2?: string | null;
            name3?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechnungsAussteller?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
          warningList: Array<{ message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null>;
      personenbezugList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        personenbezugId: string;
        tagList: Array<string>;
        person: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechtstraegerSteuerpflicht?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        rechtstraegerSteuerpflichtId: string;
        validFrom: string;
        historicizedList?: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ message: string; type: string }>;
        }> | null;
        steuerpflicht: { text: string; value: Types.Steuerpflicht };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      salutation?: { value: Types.Salutation; text: string; letterSalutation: string } | null;
      sprache: { value: string; text: string };
      status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
      type: { value: Types.RechtstraegerType; text: string };
      vertragSummary?: {
        heVertragCount: number;
        heVertragVerwaltungCount: number;
        mietVertragCount: number;
        mietVertragVerwaltungCount: number;
        weSubverwaltungCount: number;
        weVertragCount: number;
        weVertragVerwaltungCount: number;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type NatuerlichePersonChangeHistoryListQueryVariables = Types.Exact<{
  natuerlichePersonId: Types.Scalars['ID']['input'];
}>;

export type NatuerlichePersonChangeHistoryListQuery = {
  getNatuerlichePersonChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const NatuerlichePersonDocument = gql`
  query NatuerlichePerson($rechtstraegerId: ID!, $withDetails: Boolean) {
    getNatuerlichePerson(rechtstraegerId: $rechtstraegerId, withDetails: $withDetails) {
      data {
        bankDetailsList {
          ...BankDetailsFields
        }
        createTs
        createdBy
        createdByMitarbeiterId
        deletable
        fibuKontoCreatable
        firstname
        kundennummer
        kurzBezeichnung
        lastname
        personenbezugList {
          ...Personenbezug
        }
        postIt {
          ...PostIt
        }
        rechtstraegerId
        rechtstraegerSteuerpflicht {
          ...RechtstraegerSteuerpflicht
        }
        salutation {
          value
          text
          letterSalutation
        }
        sprache {
          value
          text
        }
        status {
          description
          text
          value
        }
        title
        titleTrailing
        tagList
        type {
          value
          text
        }
        vertragSummary {
          heVertragCount
          heVertragVerwaltungCount
          mietVertragCount
          mietVertragVerwaltungCount
          weSubverwaltungCount
          weVertragCount
          weVertragVerwaltungCount
        }
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BankDetailsFieldsFragmentDoc}
  ${PersonenbezugFragmentDoc}
  ${PostItFragmentDoc}
  ${RechtstraegerSteuerpflichtFragmentDoc}
`;
export function useNatuerlichePersonQuery(
  baseOptions: Apollo.QueryHookOptions<NatuerlichePersonQuery, NatuerlichePersonQueryVariables> &
    ({ variables: NatuerlichePersonQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NatuerlichePersonQuery, NatuerlichePersonQueryVariables>(NatuerlichePersonDocument, options);
}
export function useNatuerlichePersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NatuerlichePersonQuery, NatuerlichePersonQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NatuerlichePersonQuery, NatuerlichePersonQueryVariables>(NatuerlichePersonDocument, options);
}
export function useNatuerlichePersonSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<NatuerlichePersonQuery, NatuerlichePersonQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NatuerlichePersonQuery, NatuerlichePersonQueryVariables>(NatuerlichePersonDocument, options);
}
export type NatuerlichePersonQueryHookResult = ReturnType<typeof useNatuerlichePersonQuery>;
export type NatuerlichePersonLazyQueryHookResult = ReturnType<typeof useNatuerlichePersonLazyQuery>;
export type NatuerlichePersonSuspenseQueryHookResult = ReturnType<typeof useNatuerlichePersonSuspenseQuery>;
export type NatuerlichePersonQueryResult = Apollo.QueryResult<NatuerlichePersonQuery, NatuerlichePersonQueryVariables>;
export const NatuerlichePersonChangeHistoryListDocument = gql`
  query NatuerlichePersonChangeHistoryList($natuerlichePersonId: ID!) {
    getNatuerlichePersonChangeHistoryList(natuerlichePersonId: $natuerlichePersonId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useNatuerlichePersonChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<NatuerlichePersonChangeHistoryListQuery, NatuerlichePersonChangeHistoryListQueryVariables> &
    ({ variables: NatuerlichePersonChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NatuerlichePersonChangeHistoryListQuery, NatuerlichePersonChangeHistoryListQueryVariables>(
    NatuerlichePersonChangeHistoryListDocument,
    options
  );
}
export function useNatuerlichePersonChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NatuerlichePersonChangeHistoryListQuery, NatuerlichePersonChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NatuerlichePersonChangeHistoryListQuery, NatuerlichePersonChangeHistoryListQueryVariables>(
    NatuerlichePersonChangeHistoryListDocument,
    options
  );
}
export function useNatuerlichePersonChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<NatuerlichePersonChangeHistoryListQuery, NatuerlichePersonChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NatuerlichePersonChangeHistoryListQuery, NatuerlichePersonChangeHistoryListQueryVariables>(
    NatuerlichePersonChangeHistoryListDocument,
    options
  );
}
export type NatuerlichePersonChangeHistoryListQueryHookResult = ReturnType<typeof useNatuerlichePersonChangeHistoryListQuery>;
export type NatuerlichePersonChangeHistoryListLazyQueryHookResult = ReturnType<typeof useNatuerlichePersonChangeHistoryListLazyQuery>;
export type NatuerlichePersonChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useNatuerlichePersonChangeHistoryListSuspenseQuery>;
export type NatuerlichePersonChangeHistoryListQueryResult = Apollo.QueryResult<
  NatuerlichePersonChangeHistoryListQuery,
  NatuerlichePersonChangeHistoryListQueryVariables
>;
