import React, { FC, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { isNotNil } from '../../../../../helpers/assertionHelper';
import RecipientTable from './Table/RecipientTable';
import AttachmentPart from './AttachmentPart';
import { usePaginationQueryParams } from '../../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, useQueryWithPagingAndSorting } from '../../../../../hooks/useQueryWithPagingAndSorting';
import { showSuccessMsgOther } from '../../../../../components/message';
import {
  useVorschreibungMailDeliveryWizardRecipientListQuery,
  useVorschreibungMailDeliveryWizardRecipientListStepQuery,
} from '../../gql/VorschreibungMailDeliveryWizardQueries.types';
import { useSendVorschreibungMailMutation } from '../../gql/VorschreibungMailDeliveryWizardMutations.types';
import { DeleteWizard } from '../../../wizardHelpers';
import StepButtons from '../../../Shared/StepButtons';
import { VorschreibungMailDelivery } from '../../../../../types';

type Props = {
  wizardId: string;
  onBack: () => void;
  onCancel: () => void;
  onDelete: DeleteWizard;
  onComplete: () => void;
};

const RecipientListStep: FC<Props> = ({ onCancel, wizardId, onBack, onComplete, onDelete }) => {
  const [selectedRow, setSelectedRow] = useState<VorschreibungMailDelivery>();
  const { currentPage, pageSize } = usePaginationQueryParams();
  const [inProgress, setInProgress] = useState(true);

  const { data, pagination, handleTableSorting, loading, refetch } = useQueryWithPagingAndSorting(
    useVorschreibungMailDeliveryWizardRecipientListQuery,
    {
      variables: {
        vorschreibungMailDeliveryWizardId: wizardId,
      },
    },
    {
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : 10,
    }
  );

  useEffect(() => {
    setInProgress(loading);
  }, [loading]);

  const { data: stepData, loading: stepLoading } = useVorschreibungMailDeliveryWizardRecipientListStepQuery({
    variables: {
      vorschreibungMailDeliveryWizardId: wizardId,
    },
  });

  const completed = Boolean(stepData?.getVorschreibungMailDeliveryWizardRecipientListStep.data.completed);

  const [runSendMail] = useSendVorschreibungMailMutation({
    variables: {
      vorschreibungMailWizardId: wizardId,
    },
  });

  const recipientListData = data?.getVorschreibungMailDeliveryWizardRecipientList.data;

  useEffect(() => {
    if (!recipientListData?.contentList || !selectedRow) {
      setSelectedRow(undefined);
      return;
    }

    const inactive = recipientListData.contentList
      .filter((mailDelivery) => !mailDelivery.shouldSend)
      .find((mailDelivery) => mailDelivery.vorschreibungMailDeliveryId === selectedRow.vorschreibungMailDeliveryId);
    if (inactive) {
      setSelectedRow(undefined);
    }
  }, [recipientListData, selectedRow]);

  if (!recipientListData) {
    return <Spin />;
  }

  const onNext = () => {
    if (completed) return;
    setInProgress(true);
    runSendMail()
      .then(() => {
        showSuccessMsgOther(`E-Mails erfolgreich gesendet.`);
        onComplete();
      })
      .catch(() => {})
      .finally(() => {
        setInProgress(false);
      });
  };

  return (
    <>
      <RecipientTable
        loading={loading || stepLoading}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        pagination={pagination}
        vorschreibungMailDeliveryList={recipientListData?.contentList}
        onUpdate={refetch}
        onChange={handleTableSorting}
        wizardId={wizardId}
        isCompleted={completed}
      />
      {isNotNil(selectedRow) && <AttachmentPart isCompleted={completed} wizardId={wizardId} selectedRow={selectedRow} />}

      <StepButtons
        isDropdownHidden={completed}
        isDropdownDisabled={inProgress}
        isBackDisabled={inProgress}
        onNext={onNext}
        onCancel={onCancel}
        isSaveDisabled
        onDelete={() => onDelete(completed, setInProgress)}
        onBack={onBack}
        isNextDisabled={inProgress}
        isNextHidden={completed}
      />
    </>
  );
};

export default RecipientListStep;
