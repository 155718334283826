import React from 'react';
import FieldArrayError from './FieldArrayError';
import { FieldArrayInputRowsProps } from './FieldArrayInputProps';
import FieldArrayInputRow from './FieldArrayInputRow';
import { addNewFieldArrayInputRow, buildFieldArrayInputRowName, isAddNewFieldArrayInputRowAllowed, removeFieldArrayInputRow } from './fieldArrayUtil';

export function FieldArrayInputRows<T>({
  arrayForInput = [],
  arrayName,
  arrayObjectAttribute,
  label,
  placeholder,
  fieldValidation,
  arrayValidationError,
  newFieldValue,
  arrayHelpers,
}: FieldArrayInputRowsProps<T>) {
  return (
    <>
      {arrayForInput.map((_, index) => (
        <FieldArrayInputRow
          key={index}
          name={buildFieldArrayInputRowName(arrayName, index, arrayObjectAttribute)}
          validate={fieldValidation}
          label={label}
          placeholder={placeholder}
          isAddAllowed={isAddNewFieldArrayInputRowAllowed(arrayForInput, index)}
          add={addNewFieldArrayInputRow(arrayHelpers, arrayForInput, newFieldValue)}
          remove={removeFieldArrayInputRow(arrayHelpers, arrayForInput, index, newFieldValue)}
        />
      ))}
      <FieldArrayError fieldArrayError={arrayValidationError} />
    </>
  );
}

export default FieldArrayInputRows;
