import React, { FC } from 'react';
import { Flex } from 'rebass';
import { Tag } from 'antd';
import { EuroAmount } from '../../../../../components/Number';

type Props = {
  brutto: number;
};

const AbrechnungsErgebnis: FC<Props> = ({ brutto }) => {
  return brutto > 0 ? (
    <Flex justifyContent="space-between">
      <Tag color="red">Nachzahlung</Tag> <EuroAmount value={brutto} />
    </Flex>
  ) : (
    <Flex justifyContent="space-between">
      <Tag color="green">Guthaben</Tag> <EuroAmount value={brutto} />
    </Flex>
  );
};

export default AbrechnungsErgebnis;
