import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateFirmendatenIndexSeriesEntryMutationVariables = Types.Exact<{
  indexSeriesId: Types.Scalars['ID']['input'];
  input: Types.IndexSeriesEntryCreateInput;
}>;

export type CreateFirmendatenIndexSeriesEntryMutation = {
  createFirmendatenIndexSeriesEntry: {
    data: { indexSeriesEntryId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateFirmendatenIndexSeriesEntryMutationVariables = Types.Exact<{
  indexSeriesId: Types.Scalars['String']['input'];
  indexSeriesEntryId: Types.Scalars['ID']['input'];
  input: Types.IndexSeriesEntryUpdateInput;
}>;

export type UpdateFirmendatenIndexSeriesEntryMutation = {
  updateFirmendatenIndexSeriesEntry: {
    data: { indexSeriesEntryId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteFirmendatenIndexSeriesEntryMutationVariables = Types.Exact<{
  indexSeriesId: Types.Scalars['String']['input'];
  indexSeriesEntryId: Types.Scalars['ID']['input'];
}>;

export type DeleteFirmendatenIndexSeriesEntryMutation = {
  deleteFirmendatenIndexSeriesEntry: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateFirmendatenIndexSeriesEntryDocument = gql`
  mutation CreateFirmendatenIndexSeriesEntry($indexSeriesId: ID!, $input: IndexSeriesEntryCreateInput!) {
    createFirmendatenIndexSeriesEntry(indexSeriesId: $indexSeriesId, input: $input) {
      data {
        indexSeriesEntryId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateFirmendatenIndexSeriesEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFirmendatenIndexSeriesEntryMutation, CreateFirmendatenIndexSeriesEntryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFirmendatenIndexSeriesEntryMutation, CreateFirmendatenIndexSeriesEntryMutationVariables>(
    CreateFirmendatenIndexSeriesEntryDocument,
    options
  );
}
export type CreateFirmendatenIndexSeriesEntryMutationHookResult = ReturnType<typeof useCreateFirmendatenIndexSeriesEntryMutation>;
export type CreateFirmendatenIndexSeriesEntryMutationResult = Apollo.MutationResult<CreateFirmendatenIndexSeriesEntryMutation>;
export type CreateFirmendatenIndexSeriesEntryMutationOptions = Apollo.BaseMutationOptions<
  CreateFirmendatenIndexSeriesEntryMutation,
  CreateFirmendatenIndexSeriesEntryMutationVariables
>;
export const UpdateFirmendatenIndexSeriesEntryDocument = gql`
  mutation UpdateFirmendatenIndexSeriesEntry($indexSeriesId: String!, $indexSeriesEntryId: ID!, $input: IndexSeriesEntryUpdateInput!) {
    updateFirmendatenIndexSeriesEntry(indexSeriesId: $indexSeriesId, indexSeriesEntryId: $indexSeriesEntryId, input: $input) {
      data {
        indexSeriesEntryId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateFirmendatenIndexSeriesEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFirmendatenIndexSeriesEntryMutation, UpdateFirmendatenIndexSeriesEntryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFirmendatenIndexSeriesEntryMutation, UpdateFirmendatenIndexSeriesEntryMutationVariables>(
    UpdateFirmendatenIndexSeriesEntryDocument,
    options
  );
}
export type UpdateFirmendatenIndexSeriesEntryMutationHookResult = ReturnType<typeof useUpdateFirmendatenIndexSeriesEntryMutation>;
export type UpdateFirmendatenIndexSeriesEntryMutationResult = Apollo.MutationResult<UpdateFirmendatenIndexSeriesEntryMutation>;
export type UpdateFirmendatenIndexSeriesEntryMutationOptions = Apollo.BaseMutationOptions<
  UpdateFirmendatenIndexSeriesEntryMutation,
  UpdateFirmendatenIndexSeriesEntryMutationVariables
>;
export const DeleteFirmendatenIndexSeriesEntryDocument = gql`
  mutation DeleteFirmendatenIndexSeriesEntry($indexSeriesId: String!, $indexSeriesEntryId: ID!) {
    deleteFirmendatenIndexSeriesEntry(indexSeriesId: $indexSeriesId, indexSeriesEntryId: $indexSeriesEntryId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteFirmendatenIndexSeriesEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFirmendatenIndexSeriesEntryMutation, DeleteFirmendatenIndexSeriesEntryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFirmendatenIndexSeriesEntryMutation, DeleteFirmendatenIndexSeriesEntryMutationVariables>(
    DeleteFirmendatenIndexSeriesEntryDocument,
    options
  );
}
export type DeleteFirmendatenIndexSeriesEntryMutationHookResult = ReturnType<typeof useDeleteFirmendatenIndexSeriesEntryMutation>;
export type DeleteFirmendatenIndexSeriesEntryMutationResult = Apollo.MutationResult<DeleteFirmendatenIndexSeriesEntryMutation>;
export type DeleteFirmendatenIndexSeriesEntryMutationOptions = Apollo.BaseMutationOptions<
  DeleteFirmendatenIndexSeriesEntryMutation,
  DeleteFirmendatenIndexSeriesEntryMutationVariables
>;
