import React from 'react';
import { Link } from 'react-router-dom';
import { EuroAmount } from '../../../components/Number';
import { generatePathToKontoblattPage } from '../../Kontoblatt/kontoblattUriPaths';
import { SaldenlisteKonto, SaldenlisteOrderBy } from '../../../types';
import { dayjsCustom } from '../../../helpers/dayjsCustom';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import KontoLinkToKontoblatt from '../../../shared/components/Konto/KontoLinkToKontoblatt';

const saldenlisteKontoTableColumns = (
  date: string,
  objektId?: string,
  buchungskreisId?: string,
  saldenlisteKonto?: SaldenlisteKonto
): TableWithColSelectorColumnProps<SaldenlisteKonto>[] => {
  return [
    {
      title: 'Kto-Info',
      defaultSelected: true,
      dataIndex: SaldenlisteOrderBy.KontoNummer,
      sorter: true,
      render: (text, record) => {
        return (
          <KontoLinkToKontoblatt
            konto={record.konto}
            buchungskreisId={buchungskreisId}
            objektId={objektId}
            buchungsdatumFrom={record.saldoGesamt.von}
            buchungsdatumTo={record.saldoGesamt.bis}
            maxBezeichnungLength={35}
          />
        );
      },
    },
    {
      title: 'EB-Saldo',
      defaultSelected: true,
      dataIndex: SaldenlisteOrderBy.SaldoEb,
      sorter: true,
      align: 'right',
      render: (text, record) => <EuroAmount value={record.saldoEB} />,
    },
    {
      title: buildTitleGesamt(saldenlisteKonto),
      defaultSelected: true,
      dataIndex: SaldenlisteOrderBy.SaldoGesamt,
      sorter: true,
      align: 'right',
      render: (text, record) => <EuroAmount value={record.saldoGesamt.saldo} />,
    },
    {
      title: buildTitleMonat(saldenlisteKonto),
      defaultSelected: true,
      dataIndex: SaldenlisteOrderBy.SaldoMonat,
      sorter: true,
      align: 'right',
      render: (text, record) => <EuroAmount value={record.saldoMonat.saldo} />,
    },
    {
      title: 'Endsaldo',
      defaultSelected: true,
      dataIndex: SaldenlisteOrderBy.SaldoEnd,
      sorter: true,
      align: 'right',
      render: (text, record) => <EuroAmount value={record.saldoEnd} />,
    },
    {
      title: 'Aktion',
      defaultSelected: true,
      render: (_, record) => (
        <Link
          to={generatePathToKontoblattPage({
            buchungskreisId,
            kontoId: record.konto.kontoId,
            buchungsdatumFrom: date,
            objektId,
          })}
        >
          Details
        </Link>
      ),
    },
  ];
};

const buildTitleGesamt = (saldenlisteKonto?: SaldenlisteKonto): string => {
  return saldenlisteKonto
    ? `Saldo Gesamt ${getMonth(saldenlisteKonto.saldoGesamt.von)} - ${getMonth(saldenlisteKonto.saldoGesamt.bis)}`
    : 'Saldo Gesamt';
};

const buildTitleMonat = (saldenlisteKonto?: SaldenlisteKonto): string => {
  return saldenlisteKonto ? `Saldo Monat ${getMonth(saldenlisteKonto.saldoMonat.bis)}` : 'Saldo Monat';
};

const getMonth = (date: string) => dayjsCustom(date).format('MM');

export default saldenlisteKontoTableColumns;
