import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../styles/pdfStyles';
import { RepFondsAbrechnungEinnahme } from '../../../../topAbrechnung-types';

const TARepFondsAbrAusgabenKontoEinnahme: FC<{ einnahme: RepFondsAbrechnungEinnahme }> = ({ einnahme }) => (
  <View style={[pdfStyles.row, pdfStyles.tablePaddingLeftRight]}>
    <Text style={[pdfStyles.column, pdfStyles.textBulletinSmall, { width: '50%' }]}>{einnahme.bezeichnung}</Text>
    <Text
      style={[
        pdfStyles.column,
        pdfStyles.textBulletinSmall,
        {
          width: '50%',
          alignItems: 'flex-end',
          textAlign: 'right',
        },
      ]}
    >
      {einnahme.betragNetto}
    </Text>
  </View>
);

export default TARepFondsAbrAusgabenKontoEinnahme;
