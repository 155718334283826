import React from 'react';
import { Link } from 'react-router-dom';
import { FilePdfOutlined } from '@ant-design/icons';
import { TableWithAlignedColsExpandedRowType } from '../../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { createCells, createRowWithCells, TextWithStyling } from '../../../shared/abrechnungTableHelper';
import { StyledObjAbrKontoAusgabenDetailsTable } from '../../Styled/ObjObjektAbrechnungTable.style';
import DataWithShortenedText from '../../../../../../components/Helpers/DataWithShortenedText';
import { EuroAmount } from '../../../../../../components/Number';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';
import { ObjAbrKonto } from '../../../../../../types';
import CustomFormattedDate from '../../../../../../components/FormattedDate/CustomFormattedDate';
import { generatePathToStandingOrSingleInvoiceDetailsPage } from '../../../../../IncomingInvoice/incomingInvoiceUriPaths';
import SollHabenTag from '../../../../../shared/SollHabenTag';
import { generatePathToBookingDetailsPage } from '../../../../../BookingDetails/bookingDetailsPaths';
import RechtstraegerWithContactsTooltip from '../../../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

const headerTexts: TextWithStyling[] = [
  { render: 'Text' },
  { render: 'Fälligkeit' },
  { render: 'Belegdatum' },
  { render: 'Beleg-Nr.' },
  { render: 'Vertragspartner' },
  { render: 'Ausgaben' },
  { render: 'Soll/Haben' },
  { render: '' },
];

const ausgabenKontoTableExpandedRow: TableWithAlignedColsExpandedRowType<ObjAbrKonto>[] = [
  {
    header: () => createCells(headerTexts, true),
    table: {
      colSpanSize: [8],
      StyledComponent: StyledObjAbrKontoAusgabenDetailsTable,
      positionOfButton: 2,
      showCellButton: false,
    },
    body: (konto) => {
      return (
        <>
          {konto.buchungszeileList.map((buchungszeile) => {
            const { text, dueDate, belegDatum, vertragspartner, betrag } = buchungszeile;

            const belegnummerLinkToER = buchungszeile.belegFileId ? (
              <Link
                target="_blank"
                to={generatePathToStandingOrSingleInvoiceDetailsPage(
                  buchungszeile.belegFileId,
                  buchungszeile.belegId,
                  buchungszeile.standingInvoicePartialAmountId
                )}
              >
                {buchungszeile.belegnummer} <FilePdfOutlined />
              </Link>
            ) : (
              <TextForEmptyValue textToShow="minus" />
            );

            const texts: TextWithStyling[] = [
              { render: <DataWithShortenedText text={text} maxTextLength={20} />, style: { justifyContent: 'left', textAlign: 'left' } },
              { render: dueDate ? <CustomFormattedDate value={dueDate} /> : <TextForEmptyValue textToShow="minus" /> },
              { render: belegDatum ? <CustomFormattedDate value={belegDatum} /> : <TextForEmptyValue textToShow="minus" /> },
              { render: belegnummerLinkToER },
              {
                render: vertragspartner ? (
                  <RechtstraegerWithContactsTooltip
                    rechtstraegerId={vertragspartner.rechtstraegerId}
                    rechtstraegerName={vertragspartner.kurzBezeichnung}
                    maxTextLength={18}
                  />
                ) : (
                  <TextForEmptyValue textToShow="minus" />
                ),
              },
              {
                render: (
                  <Link target="_blank" to={generatePathToBookingDetailsPage(buchungszeile.buchungIdList)}>
                    <EuroAmount value={betrag} />
                  </Link>
                ),
              },
              { render: <SollHabenTag sollHaben={buchungszeile.sollHaben} /> },
              { render: '' },
            ];
            return createRowWithCells(texts, false);
          })}
        </>
      );
    },
  },
];

export default ausgabenKontoTableExpandedRow;
