import React, { FC } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

type Props = {
  fieldHelp?: string | null;
};

/**
 * <h2>Usage</h2>
 * Used to display field help separately in case FormItemWithFieldHelp can't be used, e.g. <br />
 ** When a checkbox is used with a label next to it
 ** When field help should be displayed next to the title for a list of fields of same type
 *
 */

const FieldHelpIconWithTooltip: FC<Props> = ({ fieldHelp }) => {
  return fieldHelp ? (
    <Tooltip title={fieldHelp}>
      <InfoCircleOutlined
        style={{
          marginLeft: '4px',
          color: 'rgba(0, 0, 0, 0.45)',
          cursor: 'help',
        }}
      />
    </Tooltip>
  ) : null;
};

export default FieldHelpIconWithTooltip;
