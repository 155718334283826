import { FC } from 'react';
import { FormikProps } from 'formik';
import { RechtstraegerInfoFeldListingFiltersFormValues } from './rechtstraegerInfoFeldListingFiltersFormMapper';
import RechtstraegerInfoFeldListingFilters from './RechtstraegerInfoFeldListingFilters';
import RechtstraegerInfoFeldList from './List/RechtstraegerInfoFeldList';
import { useRechtstraegerInfoFeldListQuery } from './gql/RechtstraegerInfoFeldQueries.types';
import { RechtstraegerInfoFeldFragment } from './gql/rechtstraegerInfoFeldFragments.types';
import { useToggle } from '../../../hooks/useToggle';

type RechtstraegerInfoFeldListingFormProps = {
  formikProps: FormikProps<RechtstraegerInfoFeldListingFiltersFormValues>;
  rechtstraegerId: string;
};

const RechtstraegerInfoFeldListingForm: FC<RechtstraegerInfoFeldListingFormProps> = ({ formikProps, rechtstraegerId }) => {
  const [showArchived, toggleShowArchived] = useToggle();

  const { data, loading, refetch } = useRechtstraegerInfoFeldListQuery({
    variables: {
      rechtstraegerId,
      includeArchiviert: showArchived,
    },
  });
  const infoFeldList = data?.getRechtstraegerInfoFeldList.data ?? [];
  const infoFeldListForType = filterInfoFeldListByType(infoFeldList, formikProps);

  return (
    <>
      <RechtstraegerInfoFeldListingFilters
        rechtstraegerId={rechtstraegerId}
        onSuccess={refetch}
        showArchived={showArchived}
        toggleShowArchived={toggleShowArchived}
      />
      <RechtstraegerInfoFeldList infoFeldList={infoFeldListForType} loading={loading} rechtstraegerId={rechtstraegerId} onSuccess={refetch} />
    </>
  );
};

const filterInfoFeldListByType = (
  rechtstraegerInfoFeldList: RechtstraegerInfoFeldFragment[],
  formikProps: FormikProps<RechtstraegerInfoFeldListingFiltersFormValues>
) => rechtstraegerInfoFeldList.filter((infoFeld) => (formikProps.values.type ? infoFeld.type.value === formikProps.values.type : true));

export default RechtstraegerInfoFeldListingForm;
