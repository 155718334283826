import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../styles/pdfStyles';
import RechnungsEmpfaengerBlock from './RechnungsEmpfaengerBlock';
import Sachbearbeiter from './Sachbearbeiter';
import { LabeledValue, RechnungsEmpfaengerShippingLabel, SachbearbeiterType } from './types';
import Absender from './Absender';
import { isNotNil } from '../helpers/assertionHelper';

type Props = {
  rechnungsEmpfaengerShippingLabel: RechnungsEmpfaengerShippingLabel;
  sachbearbeiter: SachbearbeiterType;
  absender: LabeledValue;
  einschreibenText?: string | null;
};
const RechnungempfaengerSachbearbeiterBlock: FC<Props> = ({ absender, rechnungsEmpfaengerShippingLabel, sachbearbeiter, einschreibenText }) => (
  <View style={[pdfStyles.row, { marginTop: '3mm' }]}>
    <View style={[pdfStyles.column, pdfStyles.addressor]}>
      <Absender absender={absender} />
      {isNotNil(einschreibenText) ? (
        <Text style={[pdfStyles.textUppercase, { fontSize: 8, marginTop: '1mm', textDecoration: 'underline' }]}>{einschreibenText}</Text>
      ) : (
        <View style={[{ marginBottom: '7mm' }]} />
      )}
      <RechnungsEmpfaengerBlock rechnungsEmpfaengerShippingLabel={rechnungsEmpfaengerShippingLabel} />
    </View>
    <Sachbearbeiter sachbearbeiter={sachbearbeiter} />
  </View>
);

export default RechnungempfaengerSachbearbeiterBlock;
