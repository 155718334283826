import React, { FC } from 'react';
import { TabsProps } from 'antd';
import { SubAbrechnungEigentuemer } from '../../../../../types';
import { pathsToSubAbrechnungEigentuemerDetails } from '../../subAbrechnungEigentumerUriPaths';
import RouteTabs from '../../../../../components/Tabs/RouteTabs';
import SubAbrTab from './SubAbrTab';
import ZahlungsrueckstandTab from './ZahlungsrueckstandTab';

type Props = {
  subAbrechnungId: string;
  subAbrechnungEigentuemer: SubAbrechnungEigentuemer;
  objektId: string;
};

const SubAbrEigentuemerDetailsTabs: FC<Props> = ({ subAbrechnungId, subAbrechnungEigentuemer, objektId }) => {
  const paths = pathsToSubAbrechnungEigentuemerDetails(objektId, subAbrechnungId, subAbrechnungEigentuemer.subAbrechnungEigentuemerId);

  const items: TabsProps['items'] = [
    {
      key: paths.subAbrTab,
      label: 'Subverwaltungsabrechnung',
      children: <SubAbrTab objektId={objektId} subAbrechnungId={subAbrechnungId} subAbrechnungEigentuemer={subAbrechnungEigentuemer} />,
    },
    {
      key: paths.zahlungsreuckstandTab,
      label: 'Zahlungsrückstande',
      children: <ZahlungsrueckstandTab objektId={objektId} subAbrechnungEigentuemer={subAbrechnungEigentuemer} />,
    },
  ];
  return <RouteTabs items={items} />;
};

export default SubAbrEigentuemerDetailsTabs;
