import { Route } from 'react-router';
import { URI_VERARBEITUNG_BOOKING_SUGGESTION_CREATION_DETAILS_PAGE } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import AuthRoute from '../../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import BookingSuggestionCreationDetailsPage from './BookingSuggestionCreationDetailsPage';

export type BookingSuggestionCreationPageRouteParams = { generierlaufId: string };

export const verarbeitungBookingSuggestionCreationDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_BOOKING_SUGGESTION_CREATION_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<BookingSuggestionCreationDetailsPage />} />}
  />
);
