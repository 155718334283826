import React, { FC } from 'react';
import { Button, Col, Drawer, Row, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { PlusOutlined } from '@ant-design/icons';
import { Spacer } from '../../../../components/Grid';
import vertragTableColumns from './vertragTableColumns';
import { useToggle } from '../../../../hooks/useToggle';
import VertragCreateForm from '../Forms/VertragCreateForm';
import { useBeVertragListQuery } from '../../../Bestandseinheit/gql/BestandseinheitQueries.types';
import TableListEmpty from '../../../../components/Helpers/TableListEmpty';
import { useOnVertragDataChangedEvents } from '../useOnVertragDataChangedEvents';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { Bestandseinheit, BeVertragListEntry } from '../../../../types';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  bestandseinheit: Bestandseinheit;
  rechnungsausstellerId: string;
};

const VertragTable: FC<Props> = ({ objektId, bestandseinheitId, bestandseinheit, rechnungsausstellerId }) => {
  const [isCollapsed, onCollapse] = useToggle();

  const { data, refetch } = useBeVertragListQuery({
    variables: {
      objektId,
      bestandseinheitId,
    },
  });
  const vertragList = data?.getBeVertragList.data;
  const vertragListIds = vertragList ? vertragList.map((vertrag) => vertrag.vertragId) : [];
  const loading = !vertragList;

  useOnVertragDataChangedEvents('vertragInBestandseinheit', refetch, vertragListIds);

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <Typography.Title level={5}>Verträge</Typography.Title>
        </Col>
        <Col>
          {!isEmpty(vertragList) ? (
            <Button type="primary" icon={<PlusOutlined />} size="small" onClick={onCollapse}>
              Vertrag erstellen
            </Button>
          ) : null}
        </Col>
      </Row>
      <Spacer />
      <TableWithColSelector<BeVertragListEntry>
        pagination={false}
        loading={loading}
        scroll={{ x: 900 }}
        size="small"
        dataSource={vertragList}
        locale={{
          emptyText: <TableListEmpty onClick={onCollapse} emptyTxt="Kein Vertrag vorhanden" btnTxt="Vertrag erstellen" />,
        }}
        columns={vertragTableColumns(objektId, bestandseinheitId)}
        rowKey={(record) => record.vertragId}
        filterIdentifier="bestandeinheit-vertrag"
      />
      <Drawer title={`Vertrag zum ${bestandseinheit.bezeichnung} anlegen`} width={720} open={isCollapsed} onClose={onCollapse} destroyOnClose>
        <VertragCreateForm
          onSuccess={() => {
            onCollapse();
            refetch();
          }}
          onCancel={onCollapse}
          objektId={objektId}
          bestandseinheitId={bestandseinheitId}
          objektVerwaltungsart={bestandseinheit.objektVerwaltungsart.value}
          rechnungsausstellerId={rechnungsausstellerId}
        />
      </Drawer>
    </>
  );
};

export default VertragTable;
