import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../styles/pdfStyles';
import { RepFondsAufwandAufteilung } from '../../../../topAbrechnung-types';
import ColumnTitles from './ColumnTitles';
import AufwandAufteilungList from './AufwandAufteilungList';
import SummaryRow from './SummaryRow';

const TARepFondsAufwandAufteilungTableBlock: FC<{ aufwandAufteilung: RepFondsAufwandAufteilung }> = ({ aufwandAufteilung }) => (
  <View style={[pdfStyles.column, pdfStyles.tablePaddingLeftRight]}>
    <ColumnTitles titles={aufwandAufteilung.aufwandAufteilungTableTitles} />

    <AufwandAufteilungList aufwandAufteilungList={aufwandAufteilung.aufwandAufteilungTableRowList} />

    <SummaryRow summaryData={aufwandAufteilung.aufwandAufteilungSummary} />
  </View>
);

export default TARepFondsAufwandAufteilungTableBlock;
