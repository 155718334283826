import { FormFields } from '../../../../helpers/formikHelper';

export type CreateFormValues = {
  emailDeliveryDefinitionId: string;
  objektId: string;
};

export const createFormInitialValues: CreateFormValues = {
  emailDeliveryDefinitionId: '',
  objektId: '',
};

export const createFormFields: FormFields<CreateFormValues> = {
  emailDeliveryDefinitionId: 'emailDeliveryDefinitionId',
  objektId: 'objektId',
};
