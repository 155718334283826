import { FC } from 'react';
import { useContactPersonChangeHistorListQuery } from '../../../../gql/RechtsTraegerQueries.types';
import { HistoryType } from '../../../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../../../History/HistoryListingTable';

type Props = {
  contactPersonId: string;
};

const ContactPersonChangeHistoryListingTable: FC<Props> = ({ contactPersonId }) => {
  const { data, loading } = useContactPersonChangeHistorListQuery({ variables: { contactPersonId } });
  const historyList = data?.getContactPersonChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="contactPerson" />;
};

export default ContactPersonChangeHistoryListingTable;
