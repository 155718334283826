import { MitarbeiterCreateInput } from '../../../../types';

export interface MitarbeiterCreateFormValues {
  userEmail: string;
  salutation?: string | null;
  firstname: string;
  lastname: string;
  title?: string | null;
  titleTrailing?: string | null;
}

export const mitarbeiterCreateFormInitialValues: MitarbeiterCreateFormValues = {
  userEmail: '',
  firstname: '',
  lastname: '',
};

export const mapFormValuesToMitarbeiterCreate = (values: MitarbeiterCreateFormValues): MitarbeiterCreateInput => ({
  userEmail: values.userEmail,
  salutation: values.salutation,
  firstname: values.firstname,
  lastname: values.lastname,
  title: values.title,
  titleTrailing: values.titleTrailing,
});
