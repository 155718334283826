import React from 'react';
import NotificationTableColumnsNachricht from './NotificationTableColumnsNachricht';
import { Notification, NotificationOrderBy } from '../../../../types';
import NotificationTypeLink from '../../Shared/NotificationTypeLink';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import MitarbeiterTooltip from '../../../../components/Card/MitarbeiterTooltip';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';

const notificationTableColumns = (
  style?: React.CSSProperties,
  errorLink?: (record: Notification) => string | undefined
): TableWithColSelectorColumnProps<Notification>[] => [
  {
    title: 'Typ',
    defaultSelected: true,
    width: 240,
    sorter: true,
    dataIndex: NotificationOrderBy.Type,
    render: (value, record) => <NotificationTypeLink notification={record} />,
  },
  {
    title: 'Nachricht',
    defaultSelected: true,
    render: (value, record) => {
      return <NotificationTableColumnsNachricht link={errorLink?.(record)} notification={record} style={style} />;
    },
  },
  {
    title: 'Erstellt am',
    sorter: true,
    dataIndex: NotificationOrderBy.CreateTs,
    render: (value, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
];

export default notificationTableColumns;
