import React, { FC } from 'react';
import VersionTimeline from '../../../../components/Timeline/VersionTimeline';
import { showSuccessMsgDelete } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import UstKategorieVersionForm from './Form/UstKategorieVersionForm';
import UstKategorieVersionCardContent from './UstKategorieVersionCardContent';
import { useUstKategorieVersionListQuery } from './gql/ustKategorieVersionQueries.types';
import { useDeleteUstKategorieVersionMutation } from './gql/UstKategorieVersionMutations.types';
import { UstKategorie, UstKategorieVersion } from '../../../../types';

type Props = {
  ustKategorie: UstKategorie;
  reloadUstKategorieList: () => void;
};

const UstKategorieVersionTimeline: FC<Props> = ({ ustKategorie, reloadUstKategorieList }) => {
  const { data, refetch: reloadUstKategorieVersionList } = useUstKategorieVersionListQuery({
    variables: {
      ustKategorieId: ustKategorie.ustKategorieId,
      withDetails: true,
    },
  });
  const ustKategorieVersionListData = data?.getUstKategorieVersionList.data;
  const ustKategorieVersionList = ustKategorieVersionListData ?? [];
  const loading = !ustKategorieVersionListData;

  const onActionSuccess = () => {
    reloadUstKategorieList();
    reloadUstKategorieVersionList();
  };

  const [runDelete] = useDeleteUstKategorieVersionMutation({
    onCompleted: () => {
      showSuccessMsgDelete(`USt.-Kategorie`);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <VersionTimeline<UstKategorieVersion>
      dataSource={ustKategorieVersionList}
      versionIdKey="ustKategorieVersionId"
      loading={loading}
      defaultNewTimeblock={{
        ustKategorieVersionId: '',
        ustVomAufwand: 0,
        ustKategorieEntryList: [],
        vstKuerzung: false,
        warningList: [],
        versionIndex: 0,
      }}
      renderForm={(timeblock) => {
        return <UstKategorieVersionForm timeblock={timeblock} ustKategorieId={ustKategorie.ustKategorieId} onSubmitSuccess={onActionSuccess} />;
      }}
      isAllowedToDeleteInitialTimeblock
      onDelete={(timeblock) =>
        runDelete({
          variables: {
            ustKategorieId: ustKategorie.ustKategorieId,
            ustKategorieVersionId: timeblock.ustKategorieVersionId,
          },
        })
      }
      renderCardContent={(timeblock) => <UstKategorieVersionCardContent timeblock={timeblock} />}
    />
  );
};

export default UstKategorieVersionTimeline;
