import React, { FC } from 'react';
import { Empty } from 'antd';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { FirmendatenBase, FirmendatenInitializerGenerierlauf } from '../../../../types';
import firmendatenInitializerVerarbeitungTableColumns from './firmendatenInitializerVerarbeitungTableColumns';

type Props = {
  generierlauf: FirmendatenInitializerGenerierlauf;
};

// Since 'FirmendatenInitializerGenerierlauf' lacks an 'entryList', we create one from the 'firmendaten' to display the data in a table.
const FirmendatenInitializerVerarbeitungTable: FC<Props> = ({ generierlauf }) => {
  const list: FirmendatenBase[] = [generierlauf.firmendaten];

  return (
    <TableWithColSelector<FirmendatenBase>
      loading={!generierlauf}
      locale={{
        emptyText: <Empty description={<span>Keine Daten vorhanden</span>} />,
      }}
      size="small"
      dataSource={list}
      columns={firmendatenInitializerVerarbeitungTableColumns}
      pagination={false}
      rowKey={(record: FirmendatenBase) => record.firmendatenId}
      filterIdentifier="verarbeitung-firmendaten-initializer"
    />
  );
};

export default FirmendatenInitializerVerarbeitungTable;
