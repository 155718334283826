import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { TextbausteinFieldsFragmentDoc } from '../../../../components/Template/PDFTemplates/gql/TemplateFragments.types';
import { VorschreibungMailDeliveryFieldsFragmentDoc } from '../../../EmailDelivery/VorschreibungMailDelivery/gql/VorschreibungMailDeliveryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VorschreibungMailDeliveryWizardTemplateStepQueryVariables = Types.Exact<{
  vorschreibungMailDeliveryWizardId: Types.Scalars['ID']['input'];
}>;

export type VorschreibungMailDeliveryWizardTemplateStepQuery = {
  getVorschreibungMailDeliveryWizardTemplateStep: {
    data: {
      completed: boolean;
      currentStep: number;
      emailDeliveryDefinitionBezeichnung: string;
      emailDeliveryDefinitionId: string;
      stepCount: number;
      wizardId: string;
      attachmentInfoList: Array<{ fileId: string; name: string; size: number }>;
      body?: {
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      } | null;
      subject: {
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type VorschreibungMailDeliveryWizardDetermineRecipientListStepQueryVariables = Types.Exact<{
  vorschreibungMailDeliveryWizardId: Types.Scalars['ID']['input'];
}>;

export type VorschreibungMailDeliveryWizardDetermineRecipientListStepQuery = {
  getVorschreibungMailDeliveryWizardDetermineRecipientListStep: {
    data: { completed: boolean; currentStep: number; wizardId: string; generierlaufId: string; stepCount: number };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type VorschreibungMailDeliveryWizardRecipientListQueryVariables = Types.Exact<{
  vorschreibungMailDeliveryWizardId: Types.Scalars['ID']['input'];
  order?: Types.InputMaybe<Types.VorschreibungMailDeliveryRecipientListStepOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type VorschreibungMailDeliveryWizardRecipientListQuery = {
  getVorschreibungMailDeliveryWizardRecipientList: {
    data: {
      contentList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        emailAddress: string;
        emailDeliveryDefinitionBezeichnung: string;
        emailDeliveryDefinitionId: string;
        mailId?: string | null;
        original: boolean;
        sender?: string | null;
        sentTs?: string | null;
        shouldNeverSend: boolean;
        shouldSend: boolean;
        updatedByMitarbeiterId?: string | null;
        vorschreibungMailDeliveryId: string;
        emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
        fakturierungsperiode: { jahr: number; monat: number };
        objektBestandseinheitVertragList: Array<{
          vertragId: string;
          bestandseinheit: {
            bestandseinheitId: string;
            bezeichnung: string;
            address: {
              addressId: string;
              city: string;
              cityAdditionalInformation?: string | null;
              countryCodeIso2: string;
              houseEntranceApartmentNumber?: string | null;
              postofficeBoxNumber?: string | null;
              postofficeBoxZipCode?: string | null;
              street?: string | null;
              type: Types.AddressType;
              zipCode?: string | null;
            };
          };
          objekt: { kurzBezeichnung: string; objektId: string };
        }>;
        recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
        vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type VorschreibungMailDeliveryWizardRecipientListStepQueryVariables = Types.Exact<{
  vorschreibungMailDeliveryWizardId: Types.Scalars['ID']['input'];
}>;

export type VorschreibungMailDeliveryWizardRecipientListStepQuery = {
  getVorschreibungMailDeliveryWizardRecipientListStep: {
    data: { completed: boolean; currentStep: number; wizardId: string; sendGenerierlaufId?: string | null; stepCount: number };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type VorschreibungMailDeliveryAttachmentListQueryVariables = Types.Exact<{
  vorschreibungMailDeliveryWizardId: Types.Scalars['ID']['input'];
  vorschreibungMailDeliveryId: Types.Scalars['ID']['input'];
}>;

export type VorschreibungMailDeliveryAttachmentListQuery = {
  getVorschreibungMailDeliveryAttachmentList: {
    data: Array<{ fileId: string; name: string; size: number; deletable: boolean }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const VorschreibungMailDeliveryWizardTemplateStepDocument = gql`
  query VorschreibungMailDeliveryWizardTemplateStep($vorschreibungMailDeliveryWizardId: ID!) {
    getVorschreibungMailDeliveryWizardTemplateStep(vorschreibungMailDeliveryWizardId: $vorschreibungMailDeliveryWizardId) {
      data {
        attachmentInfoList {
          fileId
          name
          size
        }
        completed
        currentStep
        body {
          ...TextbausteinFields
        }
        emailDeliveryDefinitionBezeichnung
        emailDeliveryDefinitionId
        stepCount
        subject {
          ...TextbausteinFields
        }
        wizardId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${TextbausteinFieldsFragmentDoc}
`;
export function useVorschreibungMailDeliveryWizardTemplateStepQuery(
  baseOptions: Apollo.QueryHookOptions<VorschreibungMailDeliveryWizardTemplateStepQuery, VorschreibungMailDeliveryWizardTemplateStepQueryVariables> &
    ({ variables: VorschreibungMailDeliveryWizardTemplateStepQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VorschreibungMailDeliveryWizardTemplateStepQuery, VorschreibungMailDeliveryWizardTemplateStepQueryVariables>(
    VorschreibungMailDeliveryWizardTemplateStepDocument,
    options
  );
}
export function useVorschreibungMailDeliveryWizardTemplateStepLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VorschreibungMailDeliveryWizardTemplateStepQuery,
    VorschreibungMailDeliveryWizardTemplateStepQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VorschreibungMailDeliveryWizardTemplateStepQuery, VorschreibungMailDeliveryWizardTemplateStepQueryVariables>(
    VorschreibungMailDeliveryWizardTemplateStepDocument,
    options
  );
}
export function useVorschreibungMailDeliveryWizardTemplateStepSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<VorschreibungMailDeliveryWizardTemplateStepQuery, VorschreibungMailDeliveryWizardTemplateStepQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VorschreibungMailDeliveryWizardTemplateStepQuery, VorschreibungMailDeliveryWizardTemplateStepQueryVariables>(
    VorschreibungMailDeliveryWizardTemplateStepDocument,
    options
  );
}
export type VorschreibungMailDeliveryWizardTemplateStepQueryHookResult = ReturnType<typeof useVorschreibungMailDeliveryWizardTemplateStepQuery>;
export type VorschreibungMailDeliveryWizardTemplateStepLazyQueryHookResult = ReturnType<
  typeof useVorschreibungMailDeliveryWizardTemplateStepLazyQuery
>;
export type VorschreibungMailDeliveryWizardTemplateStepSuspenseQueryHookResult = ReturnType<
  typeof useVorschreibungMailDeliveryWizardTemplateStepSuspenseQuery
>;
export type VorschreibungMailDeliveryWizardTemplateStepQueryResult = Apollo.QueryResult<
  VorschreibungMailDeliveryWizardTemplateStepQuery,
  VorschreibungMailDeliveryWizardTemplateStepQueryVariables
>;
export const VorschreibungMailDeliveryWizardDetermineRecipientListStepDocument = gql`
  query VorschreibungMailDeliveryWizardDetermineRecipientListStep($vorschreibungMailDeliveryWizardId: ID!) {
    getVorschreibungMailDeliveryWizardDetermineRecipientListStep(vorschreibungMailDeliveryWizardId: $vorschreibungMailDeliveryWizardId) {
      data {
        completed
        currentStep
        wizardId
        generierlaufId
        stepCount
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useVorschreibungMailDeliveryWizardDetermineRecipientListStepQuery(
  baseOptions: Apollo.QueryHookOptions<
    VorschreibungMailDeliveryWizardDetermineRecipientListStepQuery,
    VorschreibungMailDeliveryWizardDetermineRecipientListStepQueryVariables
  > &
    ({ variables: VorschreibungMailDeliveryWizardDetermineRecipientListStepQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VorschreibungMailDeliveryWizardDetermineRecipientListStepQuery,
    VorschreibungMailDeliveryWizardDetermineRecipientListStepQueryVariables
  >(VorschreibungMailDeliveryWizardDetermineRecipientListStepDocument, options);
}
export function useVorschreibungMailDeliveryWizardDetermineRecipientListStepLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VorschreibungMailDeliveryWizardDetermineRecipientListStepQuery,
    VorschreibungMailDeliveryWizardDetermineRecipientListStepQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VorschreibungMailDeliveryWizardDetermineRecipientListStepQuery,
    VorschreibungMailDeliveryWizardDetermineRecipientListStepQueryVariables
  >(VorschreibungMailDeliveryWizardDetermineRecipientListStepDocument, options);
}
export function useVorschreibungMailDeliveryWizardDetermineRecipientListStepSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VorschreibungMailDeliveryWizardDetermineRecipientListStepQuery,
        VorschreibungMailDeliveryWizardDetermineRecipientListStepQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    VorschreibungMailDeliveryWizardDetermineRecipientListStepQuery,
    VorschreibungMailDeliveryWizardDetermineRecipientListStepQueryVariables
  >(VorschreibungMailDeliveryWizardDetermineRecipientListStepDocument, options);
}
export type VorschreibungMailDeliveryWizardDetermineRecipientListStepQueryHookResult = ReturnType<
  typeof useVorschreibungMailDeliveryWizardDetermineRecipientListStepQuery
>;
export type VorschreibungMailDeliveryWizardDetermineRecipientListStepLazyQueryHookResult = ReturnType<
  typeof useVorschreibungMailDeliveryWizardDetermineRecipientListStepLazyQuery
>;
export type VorschreibungMailDeliveryWizardDetermineRecipientListStepSuspenseQueryHookResult = ReturnType<
  typeof useVorschreibungMailDeliveryWizardDetermineRecipientListStepSuspenseQuery
>;
export type VorschreibungMailDeliveryWizardDetermineRecipientListStepQueryResult = Apollo.QueryResult<
  VorschreibungMailDeliveryWizardDetermineRecipientListStepQuery,
  VorschreibungMailDeliveryWizardDetermineRecipientListStepQueryVariables
>;
export const VorschreibungMailDeliveryWizardRecipientListDocument = gql`
  query VorschreibungMailDeliveryWizardRecipientList(
    $vorschreibungMailDeliveryWizardId: ID!
    $order: VorschreibungMailDeliveryRecipientListStepOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getVorschreibungMailDeliveryWizardRecipientList(
      vorschreibungMailDeliveryWizardId: $vorschreibungMailDeliveryWizardId
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          ...VorschreibungMailDeliveryFields
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VorschreibungMailDeliveryFieldsFragmentDoc}
`;
export function useVorschreibungMailDeliveryWizardRecipientListQuery(
  baseOptions: Apollo.QueryHookOptions<
    VorschreibungMailDeliveryWizardRecipientListQuery,
    VorschreibungMailDeliveryWizardRecipientListQueryVariables
  > &
    ({ variables: VorschreibungMailDeliveryWizardRecipientListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VorschreibungMailDeliveryWizardRecipientListQuery, VorschreibungMailDeliveryWizardRecipientListQueryVariables>(
    VorschreibungMailDeliveryWizardRecipientListDocument,
    options
  );
}
export function useVorschreibungMailDeliveryWizardRecipientListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VorschreibungMailDeliveryWizardRecipientListQuery,
    VorschreibungMailDeliveryWizardRecipientListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VorschreibungMailDeliveryWizardRecipientListQuery, VorschreibungMailDeliveryWizardRecipientListQueryVariables>(
    VorschreibungMailDeliveryWizardRecipientListDocument,
    options
  );
}
export function useVorschreibungMailDeliveryWizardRecipientListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<VorschreibungMailDeliveryWizardRecipientListQuery, VorschreibungMailDeliveryWizardRecipientListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VorschreibungMailDeliveryWizardRecipientListQuery, VorschreibungMailDeliveryWizardRecipientListQueryVariables>(
    VorschreibungMailDeliveryWizardRecipientListDocument,
    options
  );
}
export type VorschreibungMailDeliveryWizardRecipientListQueryHookResult = ReturnType<typeof useVorschreibungMailDeliveryWizardRecipientListQuery>;
export type VorschreibungMailDeliveryWizardRecipientListLazyQueryHookResult = ReturnType<
  typeof useVorschreibungMailDeliveryWizardRecipientListLazyQuery
>;
export type VorschreibungMailDeliveryWizardRecipientListSuspenseQueryHookResult = ReturnType<
  typeof useVorschreibungMailDeliveryWizardRecipientListSuspenseQuery
>;
export type VorschreibungMailDeliveryWizardRecipientListQueryResult = Apollo.QueryResult<
  VorschreibungMailDeliveryWizardRecipientListQuery,
  VorschreibungMailDeliveryWizardRecipientListQueryVariables
>;
export const VorschreibungMailDeliveryWizardRecipientListStepDocument = gql`
  query VorschreibungMailDeliveryWizardRecipientListStep($vorschreibungMailDeliveryWizardId: ID!) {
    getVorschreibungMailDeliveryWizardRecipientListStep(vorschreibungMailDeliveryWizardId: $vorschreibungMailDeliveryWizardId) {
      data {
        completed
        currentStep
        wizardId
        sendGenerierlaufId
        stepCount
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useVorschreibungMailDeliveryWizardRecipientListStepQuery(
  baseOptions: Apollo.QueryHookOptions<
    VorschreibungMailDeliveryWizardRecipientListStepQuery,
    VorschreibungMailDeliveryWizardRecipientListStepQueryVariables
  > &
    ({ variables: VorschreibungMailDeliveryWizardRecipientListStepQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VorschreibungMailDeliveryWizardRecipientListStepQuery, VorschreibungMailDeliveryWizardRecipientListStepQueryVariables>(
    VorschreibungMailDeliveryWizardRecipientListStepDocument,
    options
  );
}
export function useVorschreibungMailDeliveryWizardRecipientListStepLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VorschreibungMailDeliveryWizardRecipientListStepQuery,
    VorschreibungMailDeliveryWizardRecipientListStepQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VorschreibungMailDeliveryWizardRecipientListStepQuery, VorschreibungMailDeliveryWizardRecipientListStepQueryVariables>(
    VorschreibungMailDeliveryWizardRecipientListStepDocument,
    options
  );
}
export function useVorschreibungMailDeliveryWizardRecipientListStepSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VorschreibungMailDeliveryWizardRecipientListStepQuery,
        VorschreibungMailDeliveryWizardRecipientListStepQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    VorschreibungMailDeliveryWizardRecipientListStepQuery,
    VorschreibungMailDeliveryWizardRecipientListStepQueryVariables
  >(VorschreibungMailDeliveryWizardRecipientListStepDocument, options);
}
export type VorschreibungMailDeliveryWizardRecipientListStepQueryHookResult = ReturnType<
  typeof useVorschreibungMailDeliveryWizardRecipientListStepQuery
>;
export type VorschreibungMailDeliveryWizardRecipientListStepLazyQueryHookResult = ReturnType<
  typeof useVorschreibungMailDeliveryWizardRecipientListStepLazyQuery
>;
export type VorschreibungMailDeliveryWizardRecipientListStepSuspenseQueryHookResult = ReturnType<
  typeof useVorschreibungMailDeliveryWizardRecipientListStepSuspenseQuery
>;
export type VorschreibungMailDeliveryWizardRecipientListStepQueryResult = Apollo.QueryResult<
  VorschreibungMailDeliveryWizardRecipientListStepQuery,
  VorschreibungMailDeliveryWizardRecipientListStepQueryVariables
>;
export const VorschreibungMailDeliveryAttachmentListDocument = gql`
  query VorschreibungMailDeliveryAttachmentList($vorschreibungMailDeliveryWizardId: ID!, $vorschreibungMailDeliveryId: ID!) {
    getVorschreibungMailDeliveryAttachmentList(
      vorschreibungMailDeliveryWizardId: $vorschreibungMailDeliveryWizardId
      vorschreibungMailDeliveryId: $vorschreibungMailDeliveryId
    ) {
      data {
        fileId
        name
        size
        deletable
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useVorschreibungMailDeliveryAttachmentListQuery(
  baseOptions: Apollo.QueryHookOptions<VorschreibungMailDeliveryAttachmentListQuery, VorschreibungMailDeliveryAttachmentListQueryVariables> &
    ({ variables: VorschreibungMailDeliveryAttachmentListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VorschreibungMailDeliveryAttachmentListQuery, VorschreibungMailDeliveryAttachmentListQueryVariables>(
    VorschreibungMailDeliveryAttachmentListDocument,
    options
  );
}
export function useVorschreibungMailDeliveryAttachmentListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VorschreibungMailDeliveryAttachmentListQuery, VorschreibungMailDeliveryAttachmentListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VorschreibungMailDeliveryAttachmentListQuery, VorschreibungMailDeliveryAttachmentListQueryVariables>(
    VorschreibungMailDeliveryAttachmentListDocument,
    options
  );
}
export function useVorschreibungMailDeliveryAttachmentListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<VorschreibungMailDeliveryAttachmentListQuery, VorschreibungMailDeliveryAttachmentListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VorschreibungMailDeliveryAttachmentListQuery, VorschreibungMailDeliveryAttachmentListQueryVariables>(
    VorschreibungMailDeliveryAttachmentListDocument,
    options
  );
}
export type VorschreibungMailDeliveryAttachmentListQueryHookResult = ReturnType<typeof useVorschreibungMailDeliveryAttachmentListQuery>;
export type VorschreibungMailDeliveryAttachmentListLazyQueryHookResult = ReturnType<typeof useVorschreibungMailDeliveryAttachmentListLazyQuery>;
export type VorschreibungMailDeliveryAttachmentListSuspenseQueryHookResult = ReturnType<
  typeof useVorschreibungMailDeliveryAttachmentListSuspenseQuery
>;
export type VorschreibungMailDeliveryAttachmentListQueryResult = Apollo.QueryResult<
  VorschreibungMailDeliveryAttachmentListQuery,
  VorschreibungMailDeliveryAttachmentListQueryVariables
>;
