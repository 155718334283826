import {
  ObjektInfoFeld_ObjektInfoFeldAuflage_Fragment,
  ObjektInfoFeld_ObjektInfoFeldGrundstuecksdaten_Fragment,
  ObjektInfoFeld_ObjektInfoFeldHausbetreuung_Fragment,
  ObjektInfoFeld_ObjektInfoFeldPerson_Fragment,
  ObjektInfoFeld_ObjektInfoFeldVersicherung_Fragment,
  ObjektInfoFeldFragment,
} from '../gql/objektInfoFeldFragments.types';
import { ObjektInfoFeldAuflage, ObjektInfoFeldGrundstuecksdaten, ObjektInfoFeldVersicherung } from '../../../../types';
import { createAuflageFieldList } from './ListItem/Auflage/AuflageListItem';

export type TObjektInfoFeldData =
  | ObjektInfoFeldVersicherung
  | ObjektInfoFeldAuflage
  | ObjektInfoFeldGrundstuecksdaten
  | ObjektInfoFeld_ObjektInfoFeldHausbetreuung_Fragment
  | ObjektInfoFeld_ObjektInfoFeldPerson_Fragment;

export const isObjektInfoFeldAuflage = (attribute: ObjektInfoFeldFragment): attribute is ObjektInfoFeld_ObjektInfoFeldAuflage_Fragment =>
  attribute.__typename === 'ObjektInfoFeldAuflage';

export const isObjektInfoFeldGrundstuecksdaten = (
  attribute: ObjektInfoFeldFragment
): attribute is ObjektInfoFeld_ObjektInfoFeldGrundstuecksdaten_Fragment => attribute.__typename === 'ObjektInfoFeldGrundstuecksdaten';

export const isObjektInfoFeldHausbetreuung = (attribute: ObjektInfoFeldFragment): attribute is ObjektInfoFeld_ObjektInfoFeldHausbetreuung_Fragment =>
  attribute.__typename === 'ObjektInfoFeldHausbetreuung';

export const isObjektInfoFeldVersicherung = (attribute: ObjektInfoFeldFragment): attribute is ObjektInfoFeld_ObjektInfoFeldVersicherung_Fragment =>
  attribute.__typename === 'ObjektInfoFeldVersicherung';

export const isObjektInfoFeldPerson = (attribute: ObjektInfoFeldFragment): attribute is ObjektInfoFeld_ObjektInfoFeldPerson_Fragment =>
  attribute.__typename === 'ObjektInfoFeldPerson';

export const getObjektInfoFeldShortName = (objektInfoFeld: ObjektInfoFeldFragment): string => {
  if (isObjektInfoFeldAuflage(objektInfoFeld)) {
    return createAuflageFieldList(objektInfoFeld)[0];
  } else if (isObjektInfoFeldGrundstuecksdaten(objektInfoFeld)) {
    return objektInfoFeld.grundstuecksnummer;
  } else if (isObjektInfoFeldHausbetreuung(objektInfoFeld)) {
    return objektInfoFeld.beauftragungsgrund;
  } else if (isObjektInfoFeldPerson(objektInfoFeld)) {
    return objektInfoFeld.personRechtstraeger.kurzBezeichnung;
  } else if (isObjektInfoFeldVersicherung(objektInfoFeld)) {
    return objektInfoFeld.versicherungsart;
  }
  return '';
};
