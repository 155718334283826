import { useApolloClient } from '@apollo/client';
import useOnPaymentDataChangedEvents from './useOnPaymentDataChangedEvents';
import { PaymentListDocument, PaymentListSummaryDocument } from './gql/Order/PaymentQueries.types';
import { PaymentProposalListDocument } from '../PaymentProposal/gql/PaymentProposalQueries.types';
import { KontoSelectListDocument } from '../KontoSelect/gql/KontoSelectQueries.types';
import {
  FilterPaymentApprovedByMitarbeiterListDocument,
  FilterPaymentInvoiceIssuerIbanListDocument,
  FilterPaymentPayeeIbanListDocument,
  FilterPaymentSepaCreditorIdListDocument,
  FilterPaymentSepaMandateReferenceListDocument,
  FilterPaymentStatusListDocument,
} from './Listing/Filters/gql/PaymentFilterQueries.types';
import { DataCarrierListDocument } from '../DataCarrier/gql/DataCarrierQueries.types';
import { ReceivedBankTransactionListForPaymentDocument } from '../ReceivedBankTransaction/gql/ReceivedBankTransactionQueries.types';
import { TransactionInstructionListDocument } from './gql/Order/TransactionInstructionQueries.types';

const useOnPaymentDataChangeEventsWithFilterRefetch = (key: string) => {
  const client = useApolloClient();

  useOnPaymentDataChangedEvents(key, async () => {
    await client.refetchQueries({
      include: [
        // Payment listing
        PaymentListDocument,
        // Summe Betrag (for entire listing)
        PaymentListSummaryDocument,
        // Filter: Zahlungsvorschlag
        PaymentProposalListDocument,
        // Filter: SEPA Mandatsreferenz => Suche mit 3 Zeichen
        FilterPaymentSepaMandateReferenceListDocument,
        // Filter: SEPA Creditor-ID
        FilterPaymentSepaCreditorIdListDocument,
        // Filter: Freigabe durch Mitarbeiter
        FilterPaymentApprovedByMitarbeiterListDocument,
        // Filter: Zahlender/Zahlungsempfänger Bankverbindung
        FilterPaymentPayeeIbanListDocument,
        // Filter: Buchungskreis Bankverbindung
        FilterPaymentInvoiceIssuerIbanListDocument,
        // Filter: Datenträger
        DataCarrierListDocument,
        // Filter: Zahlung Status
        FilterPaymentStatusListDocument,
        // Filter: Fibu-Bankkonto
        KontoSelectListDocument,
        // Received Bank Transaction Expandable Table
        ReceivedBankTransactionListForPaymentDocument,
        // Transaction Instruction Expandable Table
        TransactionInstructionListDocument,
      ],
    });
  });
};

export default useOnPaymentDataChangeEventsWithFilterRefetch;
