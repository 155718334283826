import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
export type ContactFieldsFragment = {
  contactId: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  haupt?: boolean | null;
  tagList: Array<string>;
  type: string;
  wert: string;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type OrderContactFieldsFragment = { contactId: string; type: string; wert: string };

export type ContactPersonFieldsFragment = {
  contactPersonId: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  firstname: string;
  lastname: string;
  tagList: Array<string>;
  title?: string | null;
  titleTrailing?: string | null;
  contactList: Array<{
    contactId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    haupt?: boolean | null;
    tagList: Array<string>;
    type: string;
    wert: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }>;
  rechtstraeger?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const OrderContactFieldsFragmentDoc = gql`
  fragment OrderContactFields on OrderContact {
    contactId
    type
    wert
  }
`;
export const ContactFieldsFragmentDoc = gql`
  fragment ContactFields on Contact {
    contactId
    createTs
    createdBy
    createdByMitarbeiterId
    haupt
    tagList
    type
    warningList {
      attribute
      message
      type
    }
    wert
  }
`;
export const ContactPersonFieldsFragmentDoc = gql`
  fragment ContactPersonFields on ContactPerson {
    contactList {
      ...ContactFields
    }
    contactPersonId
    createTs
    createdBy
    createdByMitarbeiterId
    firstname
    lastname
    rechtstraeger {
      ...RechtstraegerBaseFields
    }
    salutation {
      letterSalutation
      value
      text
    }
    tagList
    title
    titleTrailing
    warningList {
      attribute
      message
      type
    }
  }
  ${ContactFieldsFragmentDoc}
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
