import React, { FC } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Dayjs } from 'dayjs';
import { Form } from 'formik-antd';
import * as Yup from 'yup';
import { IndexedValueListingFiltersFormValues } from '../../Filters/indexedValueListingFiltersFormMapper';
import { useUpdateValidFromVertragVposIndexedValueFirmendatenMutation } from '../../../gql/VertragVposIndexedValueMutations.types';
import { showSuccessMsgOther } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { entityIsRequired } from '../../../../../components/message/validationMsg';
import { indexedValueFormFields } from '../../../../Vertrag/BeVertrag/Vertragsdaten/VPosIndexationAgreement/ListingButtonsAndModal/indexedValuesCreateFormMapper';
import MonthPicker from '../../../../../components/DatePicker/MonthPicker/MonthPicker';
import FormButtons from '../../../../../components/Button/FormButtons';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import { VertragVposIndexedValueUpdateValidFromBulkActionInput } from '../../../../../types';
import { useGetAppFieldHelpText } from '../../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  formikProps: FormikProps<IndexedValueListingFiltersFormValues>;
  onCancel: () => void;
  onOk: () => void;
};

const UpdateVPosValidFromForm: FC<Props> = ({ formikProps, onCancel, onOk }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'VertragVposIndexedValueUpdateValidFromBulkActionRequest'>(
    'VertragVposIndexedValueUpdateValidFromBulkActionRequest'
  );

  const [runUpdate, { loading }] = useUpdateValidFromVertragVposIndexedValueFirmendatenMutation({
    onCompleted: () => {
      showSuccessMsgOther('VPos Gültig ab für indexierte Werte geändert');
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const handleUpdate = (validFrom: Dayjs | string, actions: FormikHelpers<{ validFrom: Dayjs | string }>) => {
    const input: VertragVposIndexedValueUpdateValidFromBulkActionInput = {
      bestandseinheitId: formikProps.values.bestandseinheitId,
      indexSeriesId: formikProps.values.indexSeriesId,
      objektId: formikProps.values.objektId,
      rechnungsAusstellerId: formikProps.values.rechnungsAusstellerId,
      statusList: formikProps.values.statusList,
      validFrom: formikProps.values.validFrom,
      validFromInputMonth: dayjsCustom(validFrom).format('MM'),
      validFromInputYear: dayjsCustom(validFrom).format('YYYY'),
      validTo: formikProps.values.validTo,
      valuePreservationTypeList: formikProps.values.valuePreservationTypeList,
      vertragId: formikProps.values.vertragId,
      vertragspartnerId: formikProps.values.vertragspartnerId,
      vorschreibungspositionName: formikProps.values.vorschreibungspositionName,
    };

    runUpdate({ variables: { input } })
      .then(onOk)
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <Formik<{ validFrom: Dayjs | string }>
      initialValues={{ validFrom: '' }}
      validationSchema={Yup.date().required(entityIsRequired('Gültig ab'))}
      onSubmit={(values, formikHelpers) => handleUpdate(values.validFrom, formikHelpers)}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithFieldHelp
            name={indexedValueFormFields.validFrom}
            label="Gültig ab (VPos)"
            fieldHelp={getFieldHelpText('VertragVposIndexedValueUpdateValidFromBulkActionRequest.validFrom')}
          >
            <MonthPicker name={indexedValueFormFields.validFrom} placeholder="Gültig ab auswählen" allowClear={false} />
          </FormItemWithFieldHelp>
          <FormButtons updateMode={false} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} isOkLoading={loading} />
        </Form>
      )}
    </Formik>
  );
};

export default UpdateVPosValidFromForm;
