import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArchiveBestandseinheitMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
}>;

export type ArchiveBestandseinheitMutation = {
  actionArchiveBestandseinheit: {
    data: { bestandseinheitId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveBestandseinheitMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveBestandseinheitMutation = {
  actionUnarchiveBestandseinheit: {
    data: { bestandseinheitId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ChangePositionBestandseinheitMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  newPosition: Types.Scalars['Int']['input'];
}>;

export type ChangePositionBestandseinheitMutation = {
  actionChangePositionBestandseinheit: {
    data: { bestandseinheitId: string; bezeichnung: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateBestandseinheitMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheit: Types.BestandseinheitCreateInput;
}>;

export type CreateBestandseinheitMutation = {
  createBestandseinheit: {
    data: { bestandseinheitId: string; bezeichnung: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateBestandseinheitMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  bestandseinheit: Types.BestandseinheitUpdateInput;
}>;

export type UpdateBestandseinheitMutation = {
  updateBestandseinheit: {
    data: { bestandseinheitId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteBestandseinheitMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
}>;

export type DeleteBestandseinheitMutation = {
  deleteBestandseinheit: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type CreateBePostItMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  input: Types.PostItInput;
}>;

export type CreateBePostItMutation = {
  createBePostIt: {
    data: { postItId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const ArchiveBestandseinheitDocument = gql`
  mutation ArchiveBestandseinheit($objektId: ID!, $bestandseinheitId: ID!) {
    actionArchiveBestandseinheit(objektId: $objektId, bestandseinheitId: $bestandseinheitId) {
      data {
        bestandseinheitId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveBestandseinheitMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveBestandseinheitMutation, ArchiveBestandseinheitMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveBestandseinheitMutation, ArchiveBestandseinheitMutationVariables>(ArchiveBestandseinheitDocument, options);
}
export type ArchiveBestandseinheitMutationHookResult = ReturnType<typeof useArchiveBestandseinheitMutation>;
export type ArchiveBestandseinheitMutationResult = Apollo.MutationResult<ArchiveBestandseinheitMutation>;
export type ArchiveBestandseinheitMutationOptions = Apollo.BaseMutationOptions<
  ArchiveBestandseinheitMutation,
  ArchiveBestandseinheitMutationVariables
>;
export const UnarchiveBestandseinheitDocument = gql`
  mutation UnarchiveBestandseinheit($objektId: ID!, $bestandseinheitId: ID!) {
    actionUnarchiveBestandseinheit(objektId: $objektId, bestandseinheitId: $bestandseinheitId) {
      data {
        bestandseinheitId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveBestandseinheitMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveBestandseinheitMutation, UnarchiveBestandseinheitMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveBestandseinheitMutation, UnarchiveBestandseinheitMutationVariables>(UnarchiveBestandseinheitDocument, options);
}
export type UnarchiveBestandseinheitMutationHookResult = ReturnType<typeof useUnarchiveBestandseinheitMutation>;
export type UnarchiveBestandseinheitMutationResult = Apollo.MutationResult<UnarchiveBestandseinheitMutation>;
export type UnarchiveBestandseinheitMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveBestandseinheitMutation,
  UnarchiveBestandseinheitMutationVariables
>;
export const ChangePositionBestandseinheitDocument = gql`
  mutation ChangePositionBestandseinheit($objektId: ID!, $bestandseinheitId: ID!, $newPosition: Int!) {
    actionChangePositionBestandseinheit(objektId: $objektId, bestandseinheitId: $bestandseinheitId, newPosition: $newPosition) {
      data {
        bestandseinheitId
        bezeichnung
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useChangePositionBestandseinheitMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangePositionBestandseinheitMutation, ChangePositionBestandseinheitMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangePositionBestandseinheitMutation, ChangePositionBestandseinheitMutationVariables>(
    ChangePositionBestandseinheitDocument,
    options
  );
}
export type ChangePositionBestandseinheitMutationHookResult = ReturnType<typeof useChangePositionBestandseinheitMutation>;
export type ChangePositionBestandseinheitMutationResult = Apollo.MutationResult<ChangePositionBestandseinheitMutation>;
export type ChangePositionBestandseinheitMutationOptions = Apollo.BaseMutationOptions<
  ChangePositionBestandseinheitMutation,
  ChangePositionBestandseinheitMutationVariables
>;
export const CreateBestandseinheitDocument = gql`
  mutation CreateBestandseinheit($objektId: ID!, $bestandseinheit: BestandseinheitCreateInput!) {
    createBestandseinheit(objektId: $objektId, bestandseinheit: $bestandseinheit) {
      data {
        bestandseinheitId
        bezeichnung
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateBestandseinheitMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBestandseinheitMutation, CreateBestandseinheitMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBestandseinheitMutation, CreateBestandseinheitMutationVariables>(CreateBestandseinheitDocument, options);
}
export type CreateBestandseinheitMutationHookResult = ReturnType<typeof useCreateBestandseinheitMutation>;
export type CreateBestandseinheitMutationResult = Apollo.MutationResult<CreateBestandseinheitMutation>;
export type CreateBestandseinheitMutationOptions = Apollo.BaseMutationOptions<CreateBestandseinheitMutation, CreateBestandseinheitMutationVariables>;
export const UpdateBestandseinheitDocument = gql`
  mutation UpdateBestandseinheit($objektId: ID!, $bestandseinheitId: ID!, $bestandseinheit: BestandseinheitUpdateInput!) {
    updateBestandseinheit(objektId: $objektId, bestandseinheitId: $bestandseinheitId, bestandseinheit: $bestandseinheit) {
      data {
        bestandseinheitId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateBestandseinheitMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBestandseinheitMutation, UpdateBestandseinheitMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBestandseinheitMutation, UpdateBestandseinheitMutationVariables>(UpdateBestandseinheitDocument, options);
}
export type UpdateBestandseinheitMutationHookResult = ReturnType<typeof useUpdateBestandseinheitMutation>;
export type UpdateBestandseinheitMutationResult = Apollo.MutationResult<UpdateBestandseinheitMutation>;
export type UpdateBestandseinheitMutationOptions = Apollo.BaseMutationOptions<UpdateBestandseinheitMutation, UpdateBestandseinheitMutationVariables>;
export const DeleteBestandseinheitDocument = gql`
  mutation DeleteBestandseinheit($objektId: ID!, $bestandseinheitId: ID!) {
    deleteBestandseinheit(objektId: $objektId, bestandseinheitId: $bestandseinheitId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteBestandseinheitMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBestandseinheitMutation, DeleteBestandseinheitMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBestandseinheitMutation, DeleteBestandseinheitMutationVariables>(DeleteBestandseinheitDocument, options);
}
export type DeleteBestandseinheitMutationHookResult = ReturnType<typeof useDeleteBestandseinheitMutation>;
export type DeleteBestandseinheitMutationResult = Apollo.MutationResult<DeleteBestandseinheitMutation>;
export type DeleteBestandseinheitMutationOptions = Apollo.BaseMutationOptions<DeleteBestandseinheitMutation, DeleteBestandseinheitMutationVariables>;
export const CreateBePostItDocument = gql`
  mutation CreateBePostIt($objektId: ID!, $bestandseinheitId: ID!, $input: PostItInput!) {
    createBePostIt(objektId: $objektId, bestandseinheitId: $bestandseinheitId, input: $input) {
      data {
        postItId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateBePostItMutation(baseOptions?: Apollo.MutationHookOptions<CreateBePostItMutation, CreateBePostItMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBePostItMutation, CreateBePostItMutationVariables>(CreateBePostItDocument, options);
}
export type CreateBePostItMutationHookResult = ReturnType<typeof useCreateBePostItMutation>;
export type CreateBePostItMutationResult = Apollo.MutationResult<CreateBePostItMutation>;
export type CreateBePostItMutationOptions = Apollo.BaseMutationOptions<CreateBePostItMutation, CreateBePostItMutationVariables>;
