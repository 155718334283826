import React, { FC } from 'react';
import { Spin } from 'antd';
import { useCountryCodeQuery } from '../CountryCode/gql/CountryCodeQueries.types';

type Props = {
  countryCodeIso2: string;
};

const CountryCodeName: FC<Props> = ({ countryCodeIso2 }) => {
  const { loading, data } = useCountryCodeQuery({
    variables: {
      alpha2: countryCodeIso2,
    },
  });

  const countryCode = data?.getCountryCode.data ?? {};

  // @ts-ignore
  return loading ? <Spin size="small" spinning /> : <>{countryCode.bezeichnung}</>;
};

export default CountryCodeName;
