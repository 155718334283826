import React from 'react';
import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../../components/Template/DetailsPageTemplate';
import VerarbeitungDetailsPageCard from '../../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import menuListMahnungErstellen from './menuListMahnungErstellen';
import { useMahnungErzeugenGenerierlaufQuery } from '../../../../features/Verarbeitung/gql/MahnungGenerierlauf/MahnungErzeugenGenerierlaufQueries.types';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../../features/Verarbeitung/verarbeitungHelpers';
import { VerarbeitungMahnungDetailsPageRouteParams } from '../routes';
import { MahnungErzeugenGenerierlauf } from '../../../../types';
import { useRestartMahnungErzeugenGenerierlaufMutation } from '../../../../features/Verarbeitung/gql/MahnungGenerierlauf/MahnungErzeugenGenerierlaufMutations.types';

const MahnungErstellenDetailsPage = () => {
  const { generierlaufId } = useParams() as VerarbeitungMahnungDetailsPageRouteParams;

  const { data, refetch } = useMahnungErzeugenGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getMahnungErzeugenGenerierlauf.data;

  const [runRestart] = useRestartMahnungErzeugenGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<MahnungErzeugenGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.MahnungErstellung}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.MahnungErstellung}
          onGenerierlaufRestart={runRestart}
        />
      )}
      sidebarMenuList={(generierlauf) => menuListMahnungErstellen(generierlauf)}
    />
  );
};

export default MahnungErstellenDetailsPage;
