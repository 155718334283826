import { AbrechnungType } from '../../../types';
import { pathsToBkAbrechnungDetails } from '../BkOrWe/bkAbrechnungUriPaths';
import { pathsToWeAbrechnungDetails } from '../BkOrWe/weAbrechnungUriPaths';

export const isAbrechnungTypeWeAbrechnung = (abrType?: AbrechnungType) => abrType === AbrechnungType.WeAbrechnung;
export const isAbrechnungTypeHeAbrechnung = (abrType?: AbrechnungType) => abrType === AbrechnungType.HeAbrechnung;
export const isAbrechnungTypeBkAbrechnung = (abrType?: AbrechnungType) => abrType === AbrechnungType.BkAbrechnung;
export const isAbrechnungTypeSubAbrechnung = (abrType?: AbrechnungType) => abrType === AbrechnungType.SubAbrechnung;
export const isAbrechnungTypeWeTopAbrechnung = (abrType?: AbrechnungType) => abrType === AbrechnungType.WeTopAbrechnung;
export const isAbrechnungTypeBkTopAbrechnung = (abrType?: AbrechnungType) => abrType === AbrechnungType.BkTopAbrechnung;

export const getAbrTypeName = (abrType: AbrechnungType) => {
  switch (abrType) {
    case AbrechnungType.BkAbrechnung:
      return 'Betriebskostenabrechnung';
    case AbrechnungType.HeAbrechnung:
      return 'Hauseigentümerabrechnung';
    case AbrechnungType.WeAbrechnung:
      return 'Wohnungseigentümerabrechnung';
    case AbrechnungType.SubAbrechnung:
      return 'Subverwaltungsabrechnung';
    case AbrechnungType.BkTopAbrechnung:
      return 'Betriebskosten-Topabrechnung';
    case AbrechnungType.WeTopAbrechnung:
      return 'Wohnungseigentümer-Topabrechnung';
    default:
      return 'Abrechnung';
  }
};

export const pathToBkOrWeAbrechnungDetails = (abrType: AbrechnungType, objektId: string, objektAbrechnungId: string) =>
  isAbrechnungTypeBkAbrechnung(abrType)
    ? pathsToBkAbrechnungDetails(objektId, objektAbrechnungId)
    : pathsToWeAbrechnungDetails(objektId, objektAbrechnungId);
