import React, { FC } from 'react';
import { Drawer, Dropdown, MenuProps, Modal } from 'antd';
import { DeleteOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import { IndexSeries, IndexSeriesEntry } from '../../../../../types';
import { useToggle } from '../../../../../hooks/useToggle';
import { showSuccessMsgDelete } from '../../../../../components/message';
import { useDeleteIndexSeriesEntryMutation } from '../../../../IndexSeriesEntry/gql/IndexSeriesEntryMutations.types';
import IndexSeriesEntryForm from '../../../../IndexSeriesEntry/IndexSeriesEntryForm';
import { isIndexSeriesSourceTypeManual } from '../../../indexSeriesHelpers';
import { useDeleteFirmendatenIndexSeriesEntryMutation } from '../../../../Firmendaten/IndexSeriesEntry/gql/FirmendatenIndexSeriesEntryMutations.types';
import FirmendatenIndexSeriesEntryForm from '../../../../Firmendaten/IndexSeriesEntry/FirmendatenIndexSeriesEntryForm';

type Props = {
  indexSeries: IndexSeries;
  indexSeriesEntry: IndexSeriesEntry;
  onAction: () => void;
  isAndromedaAdmin: boolean;
  firmendatenId?: string | null;
};

const IndexSeriesDetailsTableActions: FC<Props> = ({ indexSeries, indexSeriesEntry, onAction, firmendatenId, isAndromedaAdmin }) => {
  const [isUpdateDrawerOpen, toggleUpdateDrawer] = useToggle();

  const [runIndexSeriesEntryDelete] = useDeleteIndexSeriesEntryMutation({
    onCompleted: () => {
      showSuccessMsgDelete('Indexwert');
      onAction();
    },
    variables: {
      indexSeriesId: indexSeries.indexSeriesId,
      indexSeriesEntryId: indexSeriesEntry.indexSeriesEntryId,
    },
  });

  const [runFDIndexSeriesEntryDelete] = useDeleteFirmendatenIndexSeriesEntryMutation({
    onCompleted: () => {
      showSuccessMsgDelete('Indexwert');
      onAction();
    },
    variables: {
      indexSeriesId: indexSeries.indexSeriesId,
      indexSeriesEntryId: indexSeriesEntry.indexSeriesEntryId,
    },
  });

  const items: MenuProps['items'] = [
    ...[
      isIndexSeriesSourceTypeManual(indexSeries.sourceType.value)
        ? {
            key: '1',
            label: 'Indexwerte bearbeiten',
            onClick: () => toggleUpdateDrawer(),
            icon: <EditOutlined />,
          }
        : null,
    ],
    {
      key: '2',
      label: 'Löschen',
      onClick: () => showConfirmDelete(firmendatenId ? runFDIndexSeriesEntryDelete : runIndexSeriesEntryDelete, indexSeriesEntry),
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  const onActionSuccess = () => {
    toggleUpdateDrawer();
    onAction();
  };

  return (
    <>
      {(firmendatenId || isAndromedaAdmin) && (
        <Dropdown menu={{ items }}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            Aktion <DownOutlined />
          </a>
        </Dropdown>
      )}
      <Drawer title="Indexreihe bearbeiten" width={720} open={isUpdateDrawerOpen} onClose={toggleUpdateDrawer} destroyOnClose>
        {firmendatenId ? (
          <FirmendatenIndexSeriesEntryForm
            onAction={onActionSuccess}
            indexSeriesId={indexSeries.indexSeriesId}
            indexSeriesType={indexSeries.indexType.value}
            indexSeriesEntry={indexSeriesEntry}
          />
        ) : (
          <IndexSeriesEntryForm
            indexSeriesId={indexSeries.indexSeriesId}
            indexSeriesType={indexSeries.indexType.value}
            onAction={onActionSuccess}
            indexSeriesEntry={indexSeriesEntry}
          />
        )}
      </Drawer>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void, indexSeriesEntry: IndexSeriesEntry) =>
  Modal.confirm({
    title: `Möchten Sie den Indexwert löschen?`,
    content: `${indexSeriesEntry.year}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });

export default IndexSeriesDetailsTableActions;
