import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { isNotNull } from '../../../../../../../helpers/assertionHelper';
import { FibuBuchungszeile } from '../../../../../../../types';
import { getUniqueList } from '../../../../../../shared/getUniqueList';
import { selectFilterOption } from '../../../../../../../helpers/selectHelper';

type EnumTuple = { text: string; value: string };

type Props = {
  name: string;
  buchungszeileList: FibuBuchungszeile[];
} & SelectProps;

const BzBuchungskreisSelect: FC<Props> = ({ name, buchungszeileList, ...restProps }) => {
  const uniqueList: EnumTuple[] = getUniqueList(
    buchungszeileList
      .map((bz) => ({
        text: bz.buchungskreisBezeichnung,
        value: bz.buchungskreisId,
      }))
      .filter(isNotNull),
    (a, b) => a.value === b.value
  );

  return (
    <Select name={name} size="small" allowClear showSearch filterOption={selectFilterOption} {...restProps}>
      {uniqueList.map((status) => (
        <Select.Option key={status.value} value={status.value}>
          {status.text}
        </Select.Option>
      ))}
    </Select>
  );
};
export default BzBuchungskreisSelect;
