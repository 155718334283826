import { Space, Typography } from 'antd';
import { FC } from 'react';
import { ContainerOutlined } from '@ant-design/icons';
import InfoFeldListItem from '../InfoFeldListItem';
import KuendigungsinfosListItemActions from './KuendigungsinfosListItemActions';
import { StyledSpace } from './styled/Space.style';
import { VertragInfoFeld_VertragInfoFeldKuendigungsinfos_Fragment } from '../../../gql/VertragInfoFeldFragments.types';
import CustomFormattedDate from '../../../../../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  kuendigungsinfos: VertragInfoFeld_VertragInfoFeldKuendigungsinfos_Fragment;
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  onSuccess: () => void;
};

const KuendigungsinfosListItem: FC<Props> = ({ kuendigungsinfos, objektId, bestandseinheitId, vertragId, onSuccess }) => {
  return (
    <InfoFeldListItem
      icon={<ContainerOutlined />}
      content={{ fileIdList: kuendigungsinfos.fileIdList, props: { height: 48 } }}
      actions={
        <KuendigungsinfosListItemActions
          infoFeld={kuendigungsinfos}
          objektId={objektId}
          bestandseinheitId={bestandseinheitId}
          vertragId={vertragId}
          onSuccess={onSuccess}
        />
      }
    >
      <Space direction="vertical" style={{ width: '220px' }}>
        <Typography.Text type="secondary">Kündigungsinfos</Typography.Text>
        <CustomFormattedDate value={kuendigungsinfos.kuendigungBis} />
      </Space>
      <StyledSpace>
        {kuendigungsinfos.kuendigungMoeglichZum ? (
          <Typography>Kündigung möglich zum {kuendigungsinfos.kuendigungMoeglichZum}</Typography>
        ) : (
          <Typography.Text />
        )}
        {kuendigungsinfos.kuendigungDurch ? <Typography>Kündigung durch {kuendigungsinfos.kuendigungDurch.text}</Typography> : <Typography.Text />}
      </StyledSpace>
    </InfoFeldListItem>
  );
};

export default KuendigungsinfosListItem;
