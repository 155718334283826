import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { VertragVposIndexationAgreementFieldsFragmentDoc } from '../../Vertrag/BeVertrag/Vertragsdaten/VPosIndexationAgreement/gql/VertragVposIndexationAgreementFragments.types';
export type VertragVposIndexedValueFieldsFragment = {
  amountNew: number;
  amountOld: number;
  bestandseinheitBezeichnung: string;
  bestandseinheitId: string;
  calculatedAmountNew: number;
  calculatedFactorNew: number;
  calculatedFactorNewPercent: number;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  factorNew: number;
  factorNewPercent: number;
  indexNew: number;
  indexNewPreliminary: boolean;
  indexOld: number;
  indexOldPreliminary: boolean;
  indexSeriesId: string;
  indexSeriesName: string;
  monthYearNew: string;
  monthYearOld: string;
  name: string;
  objektId: string;
  objektKurzBezeichnung: string;
  rechnungsAusstellerId: string;
  rechnungsAusstellerKurzBezeichnung: string;
  updatedByMitarbeiterId?: string | null;
  validFrom: string;
  vertragId: string;
  vertragKurzBezeichnung: string;
  vertragVposIndexedValueId: string;
  vertragspartnerId: string;
  vertragspartnerKurzBezeichnung: string;
  vorschreibungspositionId: string;
  vposWertNewId?: string | null;
  vposWertOldId?: string | null;
  indexationAgreement:
    | {
        __typename: 'VertragVposIndexationAgreementCategory';
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        indexOld?: number | null;
        indexOldPreliminary: boolean;
        monthYearOld: string;
        updatedByMitarbeiterId?: string | null;
        validFrom: string;
        vertragVposIndexationAgreementId: string;
        basedOnUsableArea: boolean;
        indexSeries: {
          chainingFactor?: number | null;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          csvColumnImportId?: string | null;
          csvColumnIndexPoint?: string | null;
          csvColumnMonthYear?: string | null;
          csvRowImportId?: string | null;
          deletable: boolean;
          firmendatenId?: string | null;
          importUrl?: string | null;
          indexSeriesId: string;
          name: string;
          updatedByMitarbeiterId?: string | null;
          indexSeriesEntryList: Array<{
            annualAverageFinal?: number | null;
            annualAveragePreliminary?: number | null;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            indexSeriesEntryId: string;
            updatedByMitarbeiterId?: string | null;
            year: number;
            indexPointList: Array<{
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              finalValue?: number | null;
              indexPointId: string;
              month: string;
              preliminaryValue?: number | null;
              updatedByMitarbeiterId?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          indexType: { text: string; value: Types.IndexType };
          sourceType: { text: string; value: Types.IndexSeriesSourceType };
          status?: { text: string; value: Types.IndexSeriesStatus } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        type: { text: string; value: Types.ValuePreservationType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }
    | {
        __typename: 'VertragVposIndexationAgreementIndexAnnual';
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        indexOld?: number | null;
        indexOldPreliminary: boolean;
        monthYearOld: string;
        updatedByMitarbeiterId?: string | null;
        validFrom: string;
        vertragVposIndexationAgreementId: string;
        annualAverage: boolean;
        minimumLimit?: number | null;
        shareInPercentToApply?: number | null;
        subsequentBilling: boolean;
        threshold: number;
        indexSeries: {
          chainingFactor?: number | null;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          csvColumnImportId?: string | null;
          csvColumnIndexPoint?: string | null;
          csvColumnMonthYear?: string | null;
          csvRowImportId?: string | null;
          deletable: boolean;
          firmendatenId?: string | null;
          importUrl?: string | null;
          indexSeriesId: string;
          name: string;
          updatedByMitarbeiterId?: string | null;
          indexSeriesEntryList: Array<{
            annualAverageFinal?: number | null;
            annualAveragePreliminary?: number | null;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            indexSeriesEntryId: string;
            updatedByMitarbeiterId?: string | null;
            year: number;
            indexPointList: Array<{
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              finalValue?: number | null;
              indexPointId: string;
              month: string;
              preliminaryValue?: number | null;
              updatedByMitarbeiterId?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          indexType: { text: string; value: Types.IndexType };
          sourceType: { text: string; value: Types.IndexSeriesSourceType };
          status?: { text: string; value: Types.IndexSeriesStatus } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        type: { text: string; value: Types.ValuePreservationType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        effectiveWithMonth?: { text: string; value: Types.Month } | null;
        monthOfIndexPoint: { text: string; value: Types.Month };
        subsequentBillingObjektVorschreibungsposition?: {
          bezeichnung: string;
          objektVorschreibungspositionId: string;
          kurzBezeichnung: string;
        } | null;
        thresholdType: { text: string; value: Types.ThresholdType };
      }
    | {
        __typename: 'VertragVposIndexationAgreementIndexThreshold';
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        indexOld?: number | null;
        indexOldPreliminary: boolean;
        monthYearOld: string;
        updatedByMitarbeiterId?: string | null;
        validFrom: string;
        vertragVposIndexationAgreementId: string;
        effectiveAfterMonths?: number | null;
        minimumLimit?: number | null;
        shareInPercentToApply?: number | null;
        subsequentBilling: boolean;
        threshold: number;
        indexSeries: {
          chainingFactor?: number | null;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          csvColumnImportId?: string | null;
          csvColumnIndexPoint?: string | null;
          csvColumnMonthYear?: string | null;
          csvRowImportId?: string | null;
          deletable: boolean;
          firmendatenId?: string | null;
          importUrl?: string | null;
          indexSeriesId: string;
          name: string;
          updatedByMitarbeiterId?: string | null;
          indexSeriesEntryList: Array<{
            annualAverageFinal?: number | null;
            annualAveragePreliminary?: number | null;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            indexSeriesEntryId: string;
            updatedByMitarbeiterId?: string | null;
            year: number;
            indexPointList: Array<{
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              finalValue?: number | null;
              indexPointId: string;
              month: string;
              preliminaryValue?: number | null;
              updatedByMitarbeiterId?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          indexType: { text: string; value: Types.IndexType };
          sourceType: { text: string; value: Types.IndexSeriesSourceType };
          status?: { text: string; value: Types.IndexSeriesStatus } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        type: { text: string; value: Types.ValuePreservationType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        subsequentBillingObjektVorschreibungsposition?: {
          bezeichnung: string;
          objektVorschreibungspositionId: string;
          kurzBezeichnung: string;
        } | null;
        thresholdType: { text: string; value: Types.ThresholdType };
      }
    | {
        __typename: 'VertragVposIndexationAgreementReferenceValue';
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        indexOld?: number | null;
        indexOldPreliminary: boolean;
        monthYearOld: string;
        updatedByMitarbeiterId?: string | null;
        validFrom: string;
        vertragVposIndexationAgreementId: string;
        indexSeries: {
          chainingFactor?: number | null;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          csvColumnImportId?: string | null;
          csvColumnIndexPoint?: string | null;
          csvColumnMonthYear?: string | null;
          csvRowImportId?: string | null;
          deletable: boolean;
          firmendatenId?: string | null;
          importUrl?: string | null;
          indexSeriesId: string;
          name: string;
          updatedByMitarbeiterId?: string | null;
          indexSeriesEntryList: Array<{
            annualAverageFinal?: number | null;
            annualAveragePreliminary?: number | null;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            indexSeriesEntryId: string;
            updatedByMitarbeiterId?: string | null;
            year: number;
            indexPointList: Array<{
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              finalValue?: number | null;
              indexPointId: string;
              month: string;
              preliminaryValue?: number | null;
              updatedByMitarbeiterId?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          indexType: { text: string; value: Types.IndexType };
          sourceType: { text: string; value: Types.IndexSeriesSourceType };
          status?: { text: string; value: Types.IndexSeriesStatus } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        type: { text: string; value: Types.ValuePreservationType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
  status: { text: string; value: Types.VertragVposIndexedValueStatus };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const VertragVposIndexedValueFieldsFragmentDoc = gql`
  fragment VertragVposIndexedValueFields on VertragVposIndexedValue {
    amountNew
    amountOld
    bestandseinheitBezeichnung
    bestandseinheitId
    calculatedAmountNew
    calculatedFactorNew
    calculatedFactorNewPercent
    createTs
    createdBy
    createdByMitarbeiterId
    factorNew
    factorNewPercent
    indexNew
    indexNewPreliminary
    indexOld
    indexOldPreliminary
    indexSeriesId
    indexSeriesName
    indexationAgreement {
      ...VertragVposIndexationAgreementFields
    }
    monthYearNew
    monthYearOld
    name
    objektId
    objektKurzBezeichnung
    rechnungsAusstellerId
    rechnungsAusstellerKurzBezeichnung
    status {
      text
      value
    }
    updatedByMitarbeiterId
    validFrom
    vertragId
    vertragKurzBezeichnung
    vertragVposIndexedValueId
    vertragspartnerId
    vertragspartnerKurzBezeichnung
    vorschreibungspositionId
    vposWertNewId
    vposWertOldId
    warningList {
      attribute
      message
      type
    }
  }
  ${VertragVposIndexationAgreementFieldsFragmentDoc}
`;
