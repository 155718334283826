import React, { FC } from 'react';
import { Box } from 'rebass';
import { Button, Drawer, MenuProps, Modal, Tabs, TabsProps } from 'antd';
import { DeleteOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { useToggle } from '../../../../../hooks/useToggle';
import { showSuccessMsgDelete } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { radixActionStyles } from '../../../../../helpers/radixIconsStyles';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../../../components/Button/ButtonsAligned';
import { UstKategorie } from '../../../../../types';
import HistoryModal, { HistoryType } from '../../../../History/shared/HistoryModal';
import { useDeleteUstKategorieTemplateMutation } from '../../gql/UstKategorieTemplateMutations.types';
import UstKategorieTemplateForm from '../../Form/UstKategorieTemplateForm';
import UstKategorieTemplateChangeHistoryListingTable from './UstKategorieTemplateChangeHistoryListingTable';
import ActionDropdown from '../../../../../components/Dropdown/ActionDropdown';
import UstKategorieTemplateVersionTimeline from '../../Version/UstKategorieTemplateVersionTimeline';

type Props = {
  onActionSuccess: () => void;
  ustKategorie: UstKategorie;
};

const UstKategorieTemplateTableActions: FC<Props> = ({ onActionSuccess, ustKategorie }) => {
  const [isCollapsed, onCollapse] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();
  const entity = 'USt.-Kategorie';

  const [runDelete] = useDeleteUstKategorieTemplateMutation({
    variables: {
      ustKategorieId: ustKategorie.ustKategorieId,
    },
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const menuItems: MenuProps['items'] = [
    { key: '1', label: 'Bearbeiten', onClick: onCollapse, icon: <EditOutlined /> },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '3',
      label: 'Löschen',
      onClick: showConfirmDelete(runDelete, ustKategorie),
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span>
          <HistoryOutlined />
          Verlauf
        </span>
      ),
      children: (
        <Box p={16}>
          <UstKategorieTemplateVersionTimeline reloadUstKategorieList={onActionSuccess} ustKategorie={ustKategorie} />
        </Box>
      ),
    },
    {
      key: '2',
      label: (
        <span>
          <EditOutlined />
          Bearbeiten
        </span>
      ),
      children: (
        <Box p={16}>
          <UstKategorieTemplateForm
            ustKategorie={ustKategorie}
            onSuccess={() => {
              onActionSuccess();
              onCollapse();
            }}
            onCancel={onCollapse}
          />
        </Box>
      ),
    },
  ];

  return (
    <>
      <ActionDropdown items={menuItems} />
      <Drawer
        title="USt.-Kategorie bearbeiten"
        width={720}
        onClose={onCollapse}
        open={isCollapsed}
        destroyOnClose
        footer={
          <ButtonsAligned usage={ButtonsAlignedUsage.drawerFooter}>
            <Button onClick={onCollapse} type="primary">
              Schließen
            </Button>
          </ButtonsAligned>
        }
      >
        <Tabs defaultActiveKey="2" items={tabItems} />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <UstKategorieTemplateChangeHistoryListingTable ustKategorieId={ustKategorie.ustKategorieId} />
      </HistoryModal>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void, ustKategorie: UstKategorie) => () => {
  Modal.confirm({
    title: `Möchten Sie die USt.-Kategorie löschen?`,
    content: `${ustKategorie.kurzBezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default UstKategorieTemplateTableActions;
