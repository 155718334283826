import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestartIncomingInvoiceBookingCreationGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartIncomingInvoiceBookingCreationGenerierlaufMutation = {
  actionRestartIncomingInvoiceBookingCreationGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateIncomingInvoiceBookingCreationGenerierlaufMutationVariables = Types.Exact<{
  input: Types.IncomingInvoiceBookingCreationGenerierlaufCreateInput;
}>;

export type CreateIncomingInvoiceBookingCreationGenerierlaufMutation = {
  createIncomingInvoiceBookingCreationGenerierlauf: { data: { generierlaufId: string }; warningList: Array<{ message: string; type: string }> };
};

export const RestartIncomingInvoiceBookingCreationGenerierlaufDocument = gql`
  mutation RestartIncomingInvoiceBookingCreationGenerierlauf($generierlaufId: ID!) {
    actionRestartIncomingInvoiceBookingCreationGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartIncomingInvoiceBookingCreationGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestartIncomingInvoiceBookingCreationGenerierlaufMutation,
    RestartIncomingInvoiceBookingCreationGenerierlaufMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestartIncomingInvoiceBookingCreationGenerierlaufMutation,
    RestartIncomingInvoiceBookingCreationGenerierlaufMutationVariables
  >(RestartIncomingInvoiceBookingCreationGenerierlaufDocument, options);
}
export type RestartIncomingInvoiceBookingCreationGenerierlaufMutationHookResult = ReturnType<
  typeof useRestartIncomingInvoiceBookingCreationGenerierlaufMutation
>;
export type RestartIncomingInvoiceBookingCreationGenerierlaufMutationResult =
  Apollo.MutationResult<RestartIncomingInvoiceBookingCreationGenerierlaufMutation>;
export type RestartIncomingInvoiceBookingCreationGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartIncomingInvoiceBookingCreationGenerierlaufMutation,
  RestartIncomingInvoiceBookingCreationGenerierlaufMutationVariables
>;
export const CreateIncomingInvoiceBookingCreationGenerierlaufDocument = gql`
  mutation CreateIncomingInvoiceBookingCreationGenerierlauf($input: IncomingInvoiceBookingCreationGenerierlaufCreateInput!) {
    createIncomingInvoiceBookingCreationGenerierlauf(input: $input) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreateIncomingInvoiceBookingCreationGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateIncomingInvoiceBookingCreationGenerierlaufMutation,
    CreateIncomingInvoiceBookingCreationGenerierlaufMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateIncomingInvoiceBookingCreationGenerierlaufMutation,
    CreateIncomingInvoiceBookingCreationGenerierlaufMutationVariables
  >(CreateIncomingInvoiceBookingCreationGenerierlaufDocument, options);
}
export type CreateIncomingInvoiceBookingCreationGenerierlaufMutationHookResult = ReturnType<
  typeof useCreateIncomingInvoiceBookingCreationGenerierlaufMutation
>;
export type CreateIncomingInvoiceBookingCreationGenerierlaufMutationResult =
  Apollo.MutationResult<CreateIncomingInvoiceBookingCreationGenerierlaufMutation>;
export type CreateIncomingInvoiceBookingCreationGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  CreateIncomingInvoiceBookingCreationGenerierlaufMutation,
  CreateIncomingInvoiceBookingCreationGenerierlaufMutationVariables
>;
