import React, { FC } from 'react';
import TableWithAlignedCols from '../../../../../components/Table/TableWithAlignedCols/TableWithAlignedCols';
import objektAbrechnungTableColumns from './objektAbrechnungTableColumns';
import objektAbrechnungTableExpandedRow from './objektAbrechnungTableExpandedRow';
import { AbrechnungTypeTuple, ObjektAbrechnungkreis } from '../../../../../types';

type Props = {
  abrechnungskreisList: ObjektAbrechnungkreis[];
  fromInclusive: string;
  toInclusive: string;
  objektId: string;
  buchungskreisId: string;
  abrechnungType: AbrechnungTypeTuple;
};

const ObjektAbrechnungTable: FC<Props> = ({ abrechnungskreisList, fromInclusive, toInclusive, objektId, buchungskreisId, abrechnungType }) => (
  <TableWithAlignedCols<ObjektAbrechnungkreis>
    dataSource={abrechnungskreisList}
    columns={objektAbrechnungTableColumns}
    expandedRow={(abrKreis) => objektAbrechnungTableExpandedRow(abrKreis, fromInclusive, toInclusive, objektId, buchungskreisId, abrechnungType)}
    rowKey={(data) => data.abrechnungskreisId}
  />
);

export default ObjektAbrechnungTable;
