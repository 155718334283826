import React, { FC, JSX } from 'react';
import { Badge, Tooltip, Typography } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import { BadgeProps } from 'antd/lib/badge';
import { PresetStatusColorType } from 'antd/lib/_util/colors';
import { hasStatusDescription, IStatus, StatusEnum, TCollectedStatus } from '../../helpers/statusHelper';
import { isNil } from '../../helpers/assertionHelper';

type StatusBadgeProps = {
  /**
   * Handles only entity, Generierlauf or system status at the moment
   */
  entityStatus: IStatus;
  showBadgeText?: boolean;
  showStatusDescriptionInTooltip?: boolean;
  extraTextForBadgeTooltip?: JSX.Element | string;
  tooltipPlacement?: TooltipPlacement;
  onBadgeTooltipOpen?: (open: boolean) => void;
  badgeTextStyle?: React.CSSProperties;
} & BadgeProps;

/**
 * <h2>Usage</h2>
 * Used to show an entity, Generierlauf or system status as a Badge
 */
const StatusBadge: FC<StatusBadgeProps> = ({
  entityStatus,
  showBadgeText = true,
  badgeTextStyle,
  showStatusDescriptionInTooltip = true,
  tooltipPlacement,
  extraTextForBadgeTooltip,
  onBadgeTooltipOpen,
  ...restProps
}) => {
  const capitalizeText = (text: string) => {
    return text ? text.charAt(0).toUpperCase() + text.slice(1) : text;
  };

  const badge = (
    <Badge
      status={mapSystemStatusToBadge(entityStatus.value)}
      text={showBadgeText ? <Typography.Text style={badgeTextStyle}>{capitalizeText(entityStatus.text)}</Typography.Text> : undefined}
      style={{ fontSize: 'inherit' }}
      {...restProps}
    />
  );

  const shouldShowStatusDescrInTooltip = showStatusDescriptionInTooltip && hasStatusDescription(entityStatus);
  const isExtraTextForTooltipEmpty = isNil(extraTextForBadgeTooltip);

  const titleForTooltip = (
    <>
      {shouldShowStatusDescrInTooltip && <div>{entityStatus.description}</div>}
      {!isExtraTextForTooltipEmpty && <div>{extraTextForBadgeTooltip}</div>}
    </>
  );

  return shouldShowStatusDescrInTooltip || !isExtraTextForTooltipEmpty ? (
    <Tooltip title={titleForTooltip} placement={tooltipPlacement} onOpenChange={onBadgeTooltipOpen}>
      {badge}
    </Tooltip>
  ) : (
    <>{badge}</>
  );
};

const mapSystemStatusToBadge = (status: TCollectedStatus): PresetStatusColorType => {
  switch (status) {
    // Entity
    case StatusEnum.Aktiv:
    case StatusEnum.Active:
    case StatusEnum.Assigned:
      return 'success';
    case StatusEnum.Archiviert:
    case StatusEnum.Archived:
    case StatusEnum.Gesperrt:
    case StatusEnum.Geloescht:
    case StatusEnum.Inactive:
      return 'error';
    case StatusEnum.BeendetWarning:
    case StatusEnum.Eingeladen:
      return 'warning';
    case StatusEnum.Unverifiziert:
      return 'processing';
    // --- //
    // Generierlauf
    case StatusEnum.Initialized:
    case StatusEnum.Initialisiert:
      return 'processing';
    case StatusEnum.Erstellt:
    case StatusEnum.Ausgegeben:
    case StatusEnum.Beendet:
      return 'success';
    case StatusEnum.KeineBuchungen:
      return 'error';
    // SystemStatus
    case StatusEnum.Valid:
      return 'success';
    case StatusEnum.UnderReview:
      return 'processing';
    case StatusEnum.PruefungAusgesetzt:
      return 'warning';
    case StatusEnum.Invalid:
      return 'error';
    // Ticket
    case StatusEnum.Done:
      return 'success';
    case StatusEnum.InProgress:
      return 'processing';
    default:
      return 'default';
  }
};

export default StatusBadge;
