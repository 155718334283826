import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../helpers/selectHelper';
import { useRechnungsAusstellerBestandseinheitListQuery } from './gql/RechnungsAusstellerBestandseinheitQueries.types';

type Props = { rechnungsAusstellerId?: string | null; objektId?: string | null } & SelectProps;

const RechnungsAusstellerBestandseinheitListFormSelect: FC<Props> = ({ rechnungsAusstellerId, objektId, ...restProps }) => {
  const { loading, data } = useRechnungsAusstellerBestandseinheitListQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { rechnungsAusstellerId: rechnungsAusstellerId!, objektId: objektId! },
    skip: !rechnungsAusstellerId || !objektId,
  });
  const list = data?.getRechnungsAusstellerBestandseinheitList?.data ?? [];

  return (
    <Select loading={loading} allowClear showSearch filterOption={selectFilterOption} placeholder="Bestandseinheit auswählen" {...restProps}>
      {list.map((value) => (
        <Select.Option key={value.bestandseinheitId} value={value.bestandseinheitId}>
          {value.bezeichnung}
        </Select.Option>
      ))}
    </Select>
  );
};

export default RechnungsAusstellerBestandseinheitListFormSelect;
