import React from 'react';
import styled from 'styled-components';
import { Table, TableProps } from 'antd';

export const StyledTableForEntriesFromThisZahlung = styled(Table)`
  .row-entry-from-this-zahlung {
    background-color: #e6f7ff;
  }

  tr.ant-table-row:hover > td {
    background: none !important;
  }
` as <T>(props: TableProps<T>) => React.ReactElement;
