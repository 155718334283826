import { FC } from 'react';
import { List, Space } from 'antd';
import {
  isObjektInfoFeldAuflage,
  isObjektInfoFeldGrundstuecksdaten,
  isObjektInfoFeldHausbetreuung,
  isObjektInfoFeldPerson,
  isObjektInfoFeldVersicherung,
} from './objektInfoFeldListHelpers';
import AuflageListItem from './ListItem/Auflage/AuflageListItem';
import GrundstuecksdatenListItem from './ListItem/Grundstuecksdaten/GrundstuecksdatenListItem';
import HausbetreuungListItem from './ListItem/Hausbetreuung/HausbetreuungListItem';
import VersicherungListItem from './ListItem/Versicherung/VersicherungListItem';
import PersonListItem from './ListItem/Person/PersonListItem';
import {
  ObjektInfoFeld_ObjektInfoFeldHausbetreuung_Fragment,
  ObjektInfoFeld_ObjektInfoFeldPerson_Fragment,
  ObjektInfoFeldFragment,
} from '../gql/objektInfoFeldFragments.types';
import { ObjektInfoFeldAuflage, ObjektInfoFeldGrundstuecksdaten, ObjektInfoFeldVersicherung } from '../../../../types';

type Props = {
  loading: boolean;
  infoFeldList: ObjektInfoFeldFragment[];
  objektId: string;
  onSuccess: () => void;
};

const ObjektInfoFeldList: FC<Props> = ({ infoFeldList, loading, objektId, onSuccess }) => {
  const auflagenList = infoFeldList.filter(isObjektInfoFeldAuflage);
  const grundstuecksdatenList = infoFeldList.filter(isObjektInfoFeldGrundstuecksdaten);
  const hausbetreuungList = infoFeldList.filter(isObjektInfoFeldHausbetreuung);
  const versicherungList = infoFeldList.filter(isObjektInfoFeldVersicherung);
  const personList = infoFeldList.filter(isObjektInfoFeldPerson);

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={16}>
      {auflagenList && auflagenList.length > 0 && (
        <List<ObjektInfoFeldFragment>
          bordered
          loading={loading}
          dataSource={auflagenList}
          renderItem={(item) => <AuflageListItem auflage={item as ObjektInfoFeldAuflage} objektId={objektId} onSuccess={onSuccess} />}
        />
      )}
      {grundstuecksdatenList && grundstuecksdatenList.length > 0 && (
        <List<ObjektInfoFeldFragment>
          bordered
          loading={loading}
          dataSource={grundstuecksdatenList}
          renderItem={(item) => (
            <GrundstuecksdatenListItem grundstuecksdaten={item as ObjektInfoFeldGrundstuecksdaten} objektId={objektId} onSuccess={onSuccess} />
          )}
        />
      )}
      {hausbetreuungList && hausbetreuungList.length > 0 && (
        <List<ObjektInfoFeldFragment>
          bordered
          loading={loading}
          dataSource={hausbetreuungList}
          renderItem={(item) => (
            <HausbetreuungListItem
              hausbetreuung={item as ObjektInfoFeld_ObjektInfoFeldHausbetreuung_Fragment}
              objektId={objektId}
              onSuccess={onSuccess}
            />
          )}
        />
      )}
      {versicherungList && versicherungList.length > 0 && (
        <List<ObjektInfoFeldFragment>
          bordered
          loading={loading}
          dataSource={versicherungList}
          renderItem={(item) => <VersicherungListItem versicherung={item as ObjektInfoFeldVersicherung} objektId={objektId} onSuccess={onSuccess} />}
        />
      )}
      {personList && personList.length > 0 && (
        <List<ObjektInfoFeldFragment>
          bordered
          loading={loading}
          dataSource={personList}
          renderItem={(item) => (
            /* eslint-disable-next-line camelcase */
            <PersonListItem person={item as ObjektInfoFeld_ObjektInfoFeldPerson_Fragment} objektId={objektId} onSuccess={onSuccess} />
          )}
        />
      )}
    </Space>
  );
};

export default ObjektInfoFeldList;
