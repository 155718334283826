import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useTicketPriorityListQuery } from '../gql/TicketQueries.types';

type Props = SelectProps;

const TicketPrioritySelect: FC<Props> = ({ ...props }) => {
  const { data, loading } = useTicketPriorityListQuery();

  const priorityList = data?.getTicketPriorityList.data || [];

  return (
    <Select loading={loading} size="small" id={props.name} placeholder="Priorität auswählen" {...props}>
      {priorityList.map(({ value, text }) => {
        return (
          <Select.Option key={value} value={value}>
            {text}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default TicketPrioritySelect;
