import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Switch } from 'formik-antd';
import { Space, Typography } from 'antd';
import { topAbrDefGeneralSettingsFormFields, TopAbrDefGeneralSettingsFormValues } from '../../topAbrDefGeneralSettingsFormMapper';
import { StyledParamFormItemForWizard } from '../../../../../../../../shared/styled/WizardSpace.style';

type Props = {
  formikProps: FormikProps<TopAbrDefGeneralSettingsFormValues>;
  isDisabled: boolean;
  onChange: (displayRueckstandOtherPersons: boolean, rueckstandOtherPersonsThreshold: number | null) => void;
  fieldHelp?: string | null;
};

const DisplayRueckstandOtherPersonsFormItem: FC<Props> = ({ formikProps, isDisabled, onChange, fieldHelp }) => (
  <StyledParamFormItemForWizard
    name={topAbrDefGeneralSettingsFormFields.displayRueckstandOtherPersons}
    label="Rückstand anderer Personen ausgeben"
    marginBottom="8px"
    fieldHelp={fieldHelp}
  >
    <Space>
      <Switch
        name={topAbrDefGeneralSettingsFormFields.displayRueckstandOtherPersons}
        size="small"
        onChange={(value) => {
          if (value && !formikProps.values.rueckstandOtherPersonsThreshold) {
            formikProps.setFieldValue(topAbrDefGeneralSettingsFormFields.rueckstandOtherPersonsThreshold, 10);
          }
          onChange(value, null);
        }}
        disabled={isDisabled}
      />
      <Typography.Text>{formikProps.values.displayRueckstandOtherPersons ? 'Ja' : 'Nein'}</Typography.Text>
    </Space>
  </StyledParamFormItemForWizard>
);

export default DisplayRueckstandOtherPersonsFormItem;
