import { FC } from 'react';
import { FormikProps } from 'formik';
import { getUniqueList } from '../../../../shared/getUniqueList';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { syncEbicsUserVerarbeitungEntryFiltersFormFields, SyncEbicsUserVerarbeitungEntryFiltersFormValues } from './listingFiltersFormMapper';
import { SyncEbicsUserGenerierlaufEntry } from '../../../../../types';
import SyncEbicsUserMitarbeiterFormSelect, { SyncEbicsUserMitarbeiterFormSelectProps } from './SyncEbicsUserMitarbeiterFormSelect';
import FiltersWith4OrMoreFields from '../../../../../components/Filters/FiltersWith4OrMoreFields';
import SyncEbicsUserTeilnehmerFormSelect, { SyncEbicsUserTeilnehmerFormSelectProps } from './SyncEbicsUserTeilnehmerFormSelect';
import SyncEbicsUserTeilnehmerkennungFormSelect, { SyncEbicsUserTeilmnehmerkennungFormSelectProps } from './SyncEbicsUserTeilnehmerkennungFormSelect';
import SyncEbicsUserKundenkennungFormSelect, { SyncEbicsUserKundenkennungFormSelectProps } from './SyncEbicsUserKundenkennungFormSelect';
import SyncEbicsUserBanknameFormSelect, { SyncEbicsUserBanknameFormSelectProps } from './SyncEbicsUserBanknameFormSelect';
import SyncEbicsUserEbicsStatusFormSelect, { SyncEbicsUserEbicsStatusFormSelectProps } from './SyncEbicsUserEbicsStatusFormSelect';
import GenerierlaufEntryExitCodeListSelect from '../../../shared/Filters/GenerierlaufEntryExitCodeListSelect';

type Props = {
  formikProps: FormikProps<SyncEbicsUserVerarbeitungEntryFiltersFormValues>;
  entryList: SyncEbicsUserGenerierlaufEntry[];
};

const ListingFilters: FC<Props> = ({ formikProps, entryList }) => {
  const mitarbeiterList = entryList
    .map((entry) => ({
      mitarbeiterId: entry.ebicsUser?.mitarbeiterId ?? '',
      kurzBezeichnung: entry.ebicsUser?.mitarbeiterName ?? '',
    }))
    .filter((rt) => rt.mitarbeiterId) as SyncEbicsUserMitarbeiterFormSelectProps[];
  const uniqueMitarbeiterList = getUniqueList(mitarbeiterList, (a, b) => a.mitarbeiterId === b.mitarbeiterId);

  const teilnehmerList = entryList
    .map((entry) => ({
      bezeichnung: entry.ebicsUser?.name ?? '',
    }))
    .filter((rt) => rt.bezeichnung) as SyncEbicsUserTeilnehmerFormSelectProps[];
  const uniqueTeilnehmerList = getUniqueList(teilnehmerList, (a, b) => a.bezeichnung === b.bezeichnung);

  const teilnehmerkennungList = entryList
    .map((entry) => ({
      userId: entry.ebicsUser?.userId ?? '',
    }))
    .filter((rt) => rt.userId) as SyncEbicsUserTeilmnehmerkennungFormSelectProps[];
  const uniqueTeilnehmerkennungList = getUniqueList(teilnehmerkennungList, (a, b) => a.userId === b.userId);

  const kundenkennungList = entryList
    .map((entry) => ({
      partnerId: entry.ebicsUser?.partnerId ?? '',
    }))
    .filter((rt) => rt.partnerId) as SyncEbicsUserKundenkennungFormSelectProps[];
  const uniqueKundenkennungList = getUniqueList(kundenkennungList, (a, b) => a.partnerId === b.partnerId);

  const banknameList = entryList
    .map((entry) => ({
      bankname: entry.ebicsUser?.bankname ?? '',
    }))
    .filter((rt) => rt.bankname) as SyncEbicsUserBanknameFormSelectProps[];
  const uniqueBanknameList = getUniqueList(banknameList, (a, b) => a.bankname === b.bankname);

  const ebicsStatusList = entryList
    .map((entry) => ({
      value: entry.ebicsUser?.status.value ?? '',
      text: entry.ebicsUser?.status.text ?? '',
    }))
    .filter((rt) => rt.value) as SyncEbicsUserEbicsStatusFormSelectProps[];
  const uniqueEbicsStatusList = getUniqueList(ebicsStatusList, (a, b) => a.value === b.value);

  return (
    <FiltersWith4OrMoreFields
      hideTitle
      leftColumn={
        <>
          <FormItemWithoutColon name={syncEbicsUserVerarbeitungEntryFiltersFormFields.mitarbeiterId} label="Mitarbeiter">
            <SyncEbicsUserMitarbeiterFormSelect
              name={syncEbicsUserVerarbeitungEntryFiltersFormFields.mitarbeiterId}
              onChange={formikProps.submitForm}
              list={uniqueMitarbeiterList}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={syncEbicsUserVerarbeitungEntryFiltersFormFields.teilnehmer} label="Teilnehmer">
            <SyncEbicsUserTeilnehmerFormSelect
              name={syncEbicsUserVerarbeitungEntryFiltersFormFields.teilnehmer}
              onChange={formikProps.submitForm}
              list={uniqueTeilnehmerList}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={syncEbicsUserVerarbeitungEntryFiltersFormFields.userId} label="Teilnehmerkennung">
            <SyncEbicsUserTeilnehmerkennungFormSelect
              name={syncEbicsUserVerarbeitungEntryFiltersFormFields.userId}
              onChange={formikProps.submitForm}
              list={uniqueTeilnehmerkennungList}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={syncEbicsUserVerarbeitungEntryFiltersFormFields.partnerId} label="Kundenkennung">
            <SyncEbicsUserKundenkennungFormSelect
              name={syncEbicsUserVerarbeitungEntryFiltersFormFields.partnerId}
              onChange={formikProps.submitForm}
              list={uniqueKundenkennungList}
            />
          </FormItemWithoutColon>
        </>
      }
      rightColumn={
        <>
          <FormItemWithoutColon name={syncEbicsUserVerarbeitungEntryFiltersFormFields.bankname} label="Bankname">
            <SyncEbicsUserBanknameFormSelect
              name={syncEbicsUserVerarbeitungEntryFiltersFormFields.bankname}
              onChange={formikProps.submitForm}
              list={uniqueBanknameList}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={syncEbicsUserVerarbeitungEntryFiltersFormFields.ebicsStatus} label="EBICS Status">
            <SyncEbicsUserEbicsStatusFormSelect
              name={syncEbicsUserVerarbeitungEntryFiltersFormFields.ebicsStatus}
              onChange={formikProps.submitForm}
              list={uniqueEbicsStatusList}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={syncEbicsUserVerarbeitungEntryFiltersFormFields.exitCodeList} label="Exit Code Status">
            <GenerierlaufEntryExitCodeListSelect
              name={syncEbicsUserVerarbeitungEntryFiltersFormFields.exitCodeList}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
    />
  );
};

export default ListingFilters;
