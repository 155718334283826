import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Row, Typography } from 'antd';
import { VorschreibungMailDeliveryFieldsFragment } from '../../../EmailDelivery/VorschreibungMailDelivery/gql/VorschreibungMailDeliveryFragments.types';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { generatePathToEmailVersandDetailsPage } from '../../../EmailDelivery/emailDeliveryUriPaths';

type Props = {
  email: string;
  emailDelivery: VorschreibungMailDeliveryFieldsFragment | null | undefined;
};

const VorschreibungEmailDelivery: FC<Props> = ({ email, emailDelivery }) => (
  <>
    <Row>
      <DataWithShortenedText maxTextLength={25} text={email}>
        {(shortenedText) => <Typography.Text>{shortenedText}</Typography.Text>}
      </DataWithShortenedText>
    </Row>

    {emailDelivery && (
      <Row>
        {emailDelivery.mailId ? (
          <Link style={{ fontStyle: 'italic' }} to={generatePathToEmailVersandDetailsPage(emailDelivery.mailId)}>
            ({emailDelivery.emailDeliveryStatus.text})
          </Link>
        ) : (
          <Typography.Text type="secondary" style={{ fontStyle: 'italic' }}>
            ({emailDelivery.emailDeliveryStatus.text})
          </Typography.Text>
        )}
      </Row>
    )}
  </>
);

export default VorschreibungEmailDelivery;
