import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useRechtstraegerListQuery } from '../../../features/Rechtstraeger/gql/RechtsTraegerQueries.types';
import { selectFilterOption } from '../../../helpers/selectHelper';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { RechtstraegerListEntry } from '../../../types';

type RechnungsAusstellerBuchungskreisSelectProps = {
  onChange?: (rechtstraeger?: RechtstraegerListEntry) => void;
  maxTextLength?: number;
} & Omit<SelectProps, 'onChange'>;

const RechnungsAusstellerBuchungskreisSelect: FC<RechnungsAusstellerBuchungskreisSelectProps> = ({ onChange, maxTextLength, ...restProps }) => {
  const { data, loading } = useRechtstraegerListQuery({
    variables: {
      hasBuchungskreis: true,
    },
  });
  const rechtstraegerWithBuchungskreisList = data?.getRechtstraegerList.data.contentList || [];

  return (
    <Select
      id={restProps.name}
      loading={loading}
      placeholder="Buchungskreis auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
      onChange={(buchungskreisId) => callOnChange(rechtstraegerWithBuchungskreisList, buchungskreisId, onChange)}
      {...restProps}
    >
      {rechtstraegerWithBuchungskreisList.map((rechtstrager) => (
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        <Select.Option key={rechtstrager.buchungskreisId!} value={rechtstrager.buchungskreisId!}>
          <DataWithShortenedText maxTextLength={maxTextLength ?? rechtstrager.kurzBezeichnung.length} text={rechtstrager.kurzBezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

const callOnChange = (
  rechtstraegerList: RechtstraegerListEntry[],
  buchungskreisId: string,
  onChange?: (rechtstraeger?: RechtstraegerListEntry) => void
) => {
  const rechtstrager = rechtstraegerList.find((rechtstrager) => rechtstrager.buchungskreisId === buchungskreisId);
  onChange?.(rechtstrager);
};

export default RechnungsAusstellerBuchungskreisSelect;
