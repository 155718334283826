import React, { FC } from 'react';
import { Tag } from 'antd';
import { TicketPriority, TicketPriorityTuple } from '../../../types';

type Props = {
  priority: TicketPriorityTuple;
};

const TicketPriorityTag: FC<Props> = ({ priority }) => {
  const colors = {
    [TicketPriority.Low]: 'green',
    [TicketPriority.Medium]: 'blue',
    [TicketPriority.High]: 'red',
  };

  return <Tag color={colors[priority.value]}>{priority.text}</Tag>;
};

export default TicketPriorityTag;
