import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PostItFragmentDoc } from '../../../Rechtstraeger/PostIt/gql/PostItFragments.types';
export type BeInfoFeldKellerabteilFragment = { text?: string | null; kellerabteilNummer: string };

export type BeInfoFeldParkplatzFragment = {
  text?: string | null;
  parkplatzNummer: string;
  bestandseinheit?: { bestandseinheitId: string; bezeichnung: string } | null;
};

export type BeInfoFeldZimmerFragment = {
  anmerkung?: string | null;
  bezeichnung: string;
  bodenbelag?: string | null;
  breite?: number | null;
  energiekennzahl?: string | null;
  flaechentyp?: string | null;
  heizung?: string | null;
  hoehe?: number | null;
  initialQuadratmeterBetrag?: number | null;
  initialQuadratmeterPreis?: number | null;
  kubikmeter?: number | null;
  laenge?: number | null;
  nutzflaeche?: number | null;
  nutzung?: boolean | null;
  quadratmeter?: number | null;
  quadratmeterBetrag?: number | null;
  quadratmeterPreis?: number | null;
  zimmerNummer?: string | null;
  vorschreibungsposition?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    kurzBezeichnung: string;
    objektVorschreibungspositionId: string;
    vorschreibungspositionId: string;
    art: { text: string; value: Types.VorschreibungspositionArt };
    objektVorschreibungspositionStatus: { text: string; value: Types.ObjektVorschreibungspositionStatus };
    vorschreibungspositionStatus: { text: string; value: Types.VorschreibungspositionStatus };
  } | null;
};

export type BeInfoFeld_BeInfoFeldKellerabteil_Fragment = {
  __typename: 'BeInfoFeldKellerabteil';
  beInfoFeldId: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  fileIdList: Array<string>;
  text?: string | null;
  kellerabteilNummer: string;
  postIt?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    immerAnzeigen: boolean;
    postItId: string;
    text: string;
    titel: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  status: { text: string; value: Types.InfoFeldStatus };
  type: { text: string; value: Types.BeInfoFeldType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type BeInfoFeld_BeInfoFeldParkplatz_Fragment = {
  __typename: 'BeInfoFeldParkplatz';
  beInfoFeldId: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  fileIdList: Array<string>;
  text?: string | null;
  parkplatzNummer: string;
  postIt?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    immerAnzeigen: boolean;
    postItId: string;
    text: string;
    titel: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  status: { text: string; value: Types.InfoFeldStatus };
  type: { text: string; value: Types.BeInfoFeldType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  bestandseinheit?: { bestandseinheitId: string; bezeichnung: string } | null;
};

export type BeInfoFeld_BeInfoFeldZimmer_Fragment = {
  __typename: 'BeInfoFeldZimmer';
  beInfoFeldId: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  fileIdList: Array<string>;
  anmerkung?: string | null;
  bezeichnung: string;
  bodenbelag?: string | null;
  breite?: number | null;
  energiekennzahl?: string | null;
  flaechentyp?: string | null;
  heizung?: string | null;
  hoehe?: number | null;
  initialQuadratmeterBetrag?: number | null;
  initialQuadratmeterPreis?: number | null;
  kubikmeter?: number | null;
  laenge?: number | null;
  nutzflaeche?: number | null;
  nutzung?: boolean | null;
  quadratmeter?: number | null;
  quadratmeterBetrag?: number | null;
  quadratmeterPreis?: number | null;
  zimmerNummer?: string | null;
  postIt?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    immerAnzeigen: boolean;
    postItId: string;
    text: string;
    titel: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  status: { text: string; value: Types.InfoFeldStatus };
  type: { text: string; value: Types.BeInfoFeldType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  vorschreibungsposition?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    kurzBezeichnung: string;
    objektVorschreibungspositionId: string;
    vorschreibungspositionId: string;
    art: { text: string; value: Types.VorschreibungspositionArt };
    objektVorschreibungspositionStatus: { text: string; value: Types.ObjektVorschreibungspositionStatus };
    vorschreibungspositionStatus: { text: string; value: Types.VorschreibungspositionStatus };
  } | null;
};

export type BeInfoFeldFragment =
  | BeInfoFeld_BeInfoFeldKellerabteil_Fragment
  | BeInfoFeld_BeInfoFeldParkplatz_Fragment
  | BeInfoFeld_BeInfoFeldZimmer_Fragment;

export const BeInfoFeldKellerabteilFragmentDoc = gql`
  fragment BeInfoFeldKellerabteil on BeInfoFeldKellerabteil {
    kellerabteilNummer: nummer
    text
  }
`;
export const BeInfoFeldParkplatzFragmentDoc = gql`
  fragment BeInfoFeldParkplatz on BeInfoFeldParkplatz {
    bestandseinheit {
      bestandseinheitId
      bezeichnung
    }
    parkplatzNummer: nummer
    text
  }
`;
export const BeInfoFeldZimmerFragmentDoc = gql`
  fragment BeInfoFeldZimmer on BeInfoFeldZimmer {
    anmerkung
    bezeichnung
    bodenbelag
    breite
    energiekennzahl
    flaechentyp
    heizung
    hoehe
    initialQuadratmeterBetrag
    initialQuadratmeterPreis
    kubikmeter
    laenge
    zimmerNummer: nummer
    nutzflaeche
    nutzung
    quadratmeter
    quadratmeterBetrag
    quadratmeterPreis
    vorschreibungsposition {
      art {
        text
        value
      }
      createTs
      createdBy
      createdByMitarbeiterId
      deletable
      kurzBezeichnung
      objektVorschreibungspositionId
      objektVorschreibungspositionStatus {
        text
        value
      }
      vorschreibungspositionId
      vorschreibungspositionStatus {
        text
        value
      }
    }
  }
`;
export const BeInfoFeldFragmentDoc = gql`
  fragment BeInfoFeld on AbstractBeInfoFeld {
    __typename
    beInfoFeldId
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    fileIdList
    postIt {
      ...PostIt
    }
    status {
      text
      value
    }
    type {
      text
      value
    }
    warningList {
      attribute
      message
      type
    }
    ... on BeInfoFeldKellerabteil {
      ...BeInfoFeldKellerabteil
    }
    ... on BeInfoFeldParkplatz {
      ...BeInfoFeldParkplatz
    }
    ... on BeInfoFeldZimmer {
      ...BeInfoFeldZimmer
    }
  }
  ${PostItFragmentDoc}
  ${BeInfoFeldKellerabteilFragmentDoc}
  ${BeInfoFeldParkplatzFragmentDoc}
  ${BeInfoFeldZimmerFragmentDoc}
`;
