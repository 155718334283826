import {
  getBuchungszeilenAnzeigenText,
  isZuordnungKontoKlasseTypeEinnahmen,
  ZuordnungKontoKlasseType,
  ZuordnungViewModel,
} from '../../../../AbrKontenzuordnung/shared/AbrKreisKontenzuordnungHelpers';
import {
  isAbrKreisTypeBewirtschaftungskosten,
  isAbrKreisTypeRepFonds,
} from '../../../shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import { KontoAbrechnungskreis } from '../../../../../types';

export const getInformationNumber = (zuordnung: ZuordnungViewModel | KontoAbrechnungskreis, kontoKlasseType: ZuordnungKontoKlasseType) => {
  const buchungszeilenAnzeigenText = getBuchungszeilenAnzeigenText(zuordnung.buchungszeilenAnzeigen);
  const isActiveRepFonds = zuordnung.abrechnungskreis && isAbrKreisTypeRepFonds(zuordnung.abrechnungskreis.type.value);
  const hasDivergentDistributionKey = !!zuordnung.aufteilungsschluessel;
  const hasExpenseReducing =
    !!zuordnung.abrechnungskreis &&
    isAbrKreisTypeBewirtschaftungskosten(zuordnung.abrechnungskreis.type.value) &&
    isZuordnungKontoKlasseTypeEinnahmen(kontoKlasseType);

  return [buchungszeilenAnzeigenText, isActiveRepFonds, hasDivergentDistributionKey, hasExpenseReducing].filter((information) => !!information)
    .length;
};
