import { mapToQueryString, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { GenerierlaufEntryExitCode, PaymentTransactionType, PaymentType } from '../../../../../types';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../../verarbeitungApiPaths';

enum QueryParamKey {
  OBJEKT_ID_LIST = 'objektIdList',
  EXIT_CODE_LIST = 'exitCodeList',
  RECHNUNGSAUSSTELLER_ID = 'rechnungsAusstellerId',
  PAYMENT_TYPE_LIST = 'paymentTypeList',
  PAYMENT_TRANSACTION_TYPE = 'paymentTransactionType',
}

export type TPaymentProposalVerarbeitungQueryParams = QueryParams<{
  objektIdList: string[];
  exitCodeList: GenerierlaufEntryExitCode[];
  rechnungsAusstellerId: string;
  paymentTypeList: PaymentType[];
  paymentTransactionType: PaymentTransactionType;
}>;

export const updateQueryParams = (
  verarbeitungType: string,
  generierlaufId: string,
  navigate: (path: string) => void,
  filters: TPaymentProposalVerarbeitungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToPaymentProposalVerarbeitungPage(verarbeitungType, generierlaufId, filters, paginationParams));

const generatePathToPaymentProposalVerarbeitungPage = (
  verarbeitungType: string,
  generierlaufId: string,
  filters: TPaymentProposalVerarbeitungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [QueryParamKey.OBJEKT_ID_LIST]: filters?.objektIdList,
    [QueryParamKey.EXIT_CODE_LIST]: filters?.exitCodeList,
    [QueryParamKey.RECHNUNGSAUSSTELLER_ID]: filters?.rechnungsAusstellerId,
    [QueryParamKey.PAYMENT_TYPE_LIST]: filters?.paymentTypeList,
    [QueryParamKey.PAYMENT_TRANSACTION_TYPE]: filters?.paymentTransactionType,
    ...paginationParams,
  })}`;
