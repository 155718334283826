import React from 'react';
import { CopyOutlined, HomeOutlined, InboxOutlined } from '@ant-design/icons';
import { Box } from 'rebass';
import { GroupedMenueListInterface } from '../../components/ContextSidebar/ContentWithSidebar';
import DocumentUpload from '../../features/Document/Upload/DocumentUpload';
import { generatePathToProfileDetails } from '../../features/Profile/profileUriPaths';

const menuListProfile = (): GroupedMenueListInterface => {
  return {
    topMenuList: [
      {
        title: 'Inbox',
        icon: <HomeOutlined />,
        uri: `${generatePathToProfileDetails()}/inbox`,
        content: () => <Box p={24}>@TODO</Box>,
      },
      {
        title: 'Dateien',
        icon: <InboxOutlined />,
        uri: `${generatePathToProfileDetails()}/dateien`,
        content: () => <DocumentUpload />,
      },
      {
        title: 'Dokumente',
        icon: <CopyOutlined />,
        uri: `${generatePathToProfileDetails()}/documents`,
        content: () => <Box p={24}>@TODO</Box>,
      },
    ],
  };
};

export default menuListProfile;
