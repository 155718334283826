import { FormFields } from '../../../../../../helpers/formikHelper';
import { TVorschreibungErstellenVerarbeitungQueryParams } from './filtersQueryParams';
import { GenerierlaufEntryExitCode } from '../../../../../../types';

export type VorschreibungErstellenVerarbeitungEntryFiltersFormValues = {
  vorschreibungsperiode?: string;
  ausstellerId?: string;
  empfaengerId?: string;
  objektId?: string;
  exitCodeList?: GenerierlaufEntryExitCode[];
};

export const vorschreibungErstellenVerarbEntryListingFiltersFormFields: FormFields<VorschreibungErstellenVerarbeitungEntryFiltersFormValues> = {
  vorschreibungsperiode: 'vorschreibungsperiode',
  ausstellerId: 'ausstellerId',
  empfaengerId: 'empfaengerId',
  objektId: 'objektId',
  exitCodeList: 'exitCodeList',
};

export const vorschreibungErstellenVerarbEntryListingFiltersFormInitialValues = (
  queryParams: TVorschreibungErstellenVerarbeitungQueryParams
): VorschreibungErstellenVerarbeitungEntryFiltersFormValues => ({
  vorschreibungsperiode: queryParams.vorschreibungsperiode,
  ausstellerId: queryParams.ausstellerId,
  empfaengerId: queryParams.empfaengerId,
  objektId: queryParams.objektId,
  exitCodeList: queryParams.exitCodeList,
});

export const mapFormValuesToVorschreibungErstellenVerarbeitungFilters = (
  formValues: VorschreibungErstellenVerarbeitungEntryFiltersFormValues
): TVorschreibungErstellenVerarbeitungQueryParams => ({
  vorschreibungsperiode: formValues.vorschreibungsperiode,
  ausstellerId: formValues.ausstellerId,
  empfaengerId: formValues.empfaengerId,
  objektId: formValues.objektId,
  exitCodeList: formValues.exitCodeList,
});
