import { InvoiceType, StandingInvoiceCreateInput, StandingInvoiceUpdateInput } from '../../../types';
import { calculateBruttoNettoUst } from '../incomingInvoiceHelpers';
import { mapFormDateValueToDateString } from '../../../helpers/formikHelper';
import {
  IncomingInvoiceFormValues,
  mapFormValuesForSharedValuesToInvoice,
  mapFormValuesToBuchungPositionCreate,
  mapFormValuesToBuchungPositionUpdate,
} from './incomingInvoiceFormMapper';

export const mapFormValuesToStandingInvoiceCreate = (values: IncomingInvoiceFormValues): StandingInvoiceCreateInput => {
  return {
    ...mapFormValuesForSharedValuesToInvoice(values),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    billingType: values.billingType!,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    partialAmountList: values.partialAmountList!.map((partialAmount) => {
      const { brutto, netto, steuerzeileList } = calculateBruttoNettoUst(partialAmount.buchungPositionList, values.inklusiveSteuer);
      return {
        brutto,
        buchungPositionList: partialAmount.buchungPositionList.map(mapFormValuesToBuchungPositionCreate),
        buchungsdatum: partialAmount.buchungsdatum && mapFormDateValueToDateString(partialAmount.buchungsdatum),
        dueDate: mapFormDateValueToDateString(partialAmount.dueDate),
        netto,
        requestedPaymentDate: partialAmount.requestedPaymentDate && mapFormDateValueToDateString(partialAmount.requestedPaymentDate),
        steuerzeileList,
      };
    }),
    type: InvoiceType.StandingInvoice,
  };
};

export const mapFormValuesToStandingInvoiceUpdate = (values: IncomingInvoiceFormValues): StandingInvoiceUpdateInput => {
  return {
    ...mapFormValuesForSharedValuesToInvoice(values),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    partialAmountList: values.partialAmountList!.map((partialAmount) => {
      const { brutto, netto, steuerzeileList } = calculateBruttoNettoUst(partialAmount.buchungPositionList, values.inklusiveSteuer);
      return {
        partialAmountId: partialAmount.partialAmountId,
        brutto,
        buchungPositionList: partialAmount.buchungPositionList.map(mapFormValuesToBuchungPositionUpdate),
        buchungsdatum: partialAmount.buchungsdatum && mapFormDateValueToDateString(partialAmount.buchungsdatum),
        dueDate: mapFormDateValueToDateString(partialAmount.dueDate),
        netto,
        requestedPaymentDate: partialAmount.requestedPaymentDate && mapFormDateValueToDateString(partialAmount.requestedPaymentDate),
        steuerzeileList,
      };
    }),
    type: InvoiceType.StandingInvoice,
  };
};
