import React, { FC } from 'react';
import { UstKategorieTableExpandedRowTableColumns } from './ustKategorieTableExpandedRowTableColumns';
import { UstKategorie, UstKategorieEntry } from '../../../../../types';
import NestedTable from '../../../../../components/Table/NestedTable/NestedTable';

type Props = {
  loading: boolean;
  record: UstKategorie;
};

const UstKategorieTableExpandedRowTable: FC<Props> = ({ loading, record }) => {
  const dataSource = record.ustKategorieEntryList;

  return (
    <NestedTable<UstKategorieEntry>
      level={2}
      loading={loading}
      dataSource={dataSource}
      columns={UstKategorieTableExpandedRowTableColumns}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      rowKey={(record) => record.ustKategorieEntryId!}
      useHighlighting
    />
  );
};

export default UstKategorieTableExpandedRowTable;
