import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { SelectableSelection } from './types';
import pdfStyles, { selectableStyles } from '../styles/pdfStyles';

type Props = {
  text?: string | null;
  isInEditMode?: boolean;
  selectedTextbaustein?: SelectableSelection;
};

const Ergebnistext: FC<Props> = ({ text, isInEditMode, selectedTextbaustein }) => {
  // if in edit mode then we need to render the platzhalter also if it is an empty string so that the user see where the textbaustein will occur in the PDF
  if (isInEditMode) {
    return (
      <View>
        <Text
          wrap={false}
          style={[
            pdfStyles.textNormal,
            {
              marginTop: '10mm',
              textAlign: 'left',
            },
            selectableStyles(isInEditMode, selectedTextbaustein === 'ERGEBNISTEXT'),
          ]}
        >
          {text}
        </Text>
      </View>
    );
  }

  return text === '' ? null : (
    <View>
      <Text
        wrap={false}
        style={[
          pdfStyles.textNormal,
          {
            marginTop: '10mm',
            textAlign: 'left',
          },
        ]}
      >
        {text}
      </Text>
    </View>
  );
};

export default Ergebnistext;
