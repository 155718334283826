import styled from 'styled-components';
import { Space } from 'antd';

export const StyledSpace = styled(Space)`
  .ant-space-item {
    overflow: hidden;

    &:first-of-type {
      flex-shrink: 0;
    }
  }
`;
