import React, { FC } from 'react';
import { EmailDeliveryDetailsPageProps } from '../../../features/EmailDelivery/emailDeliveryHelpers';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import InfoMailDeliveryDetailsCard from '../../../features/EmailDelivery/InfoMailDelivery/Card/InfoMailDeliveryDetailsCard';
import menuListEmailDelivery from './menuListEmailDelivery';
import { MailData } from '../../../types';

const InfoMailDeliveryDetailsPage: FC<EmailDeliveryDetailsPageProps> = ({ mail, loading, refetch }) => {
  return (
    <DetailsPageTemplate<MailData>
      data={mail}
      pageTitle={() => 'E-mail Versand'}
      isLoading={loading}
      card={(emailDelivery) => <InfoMailDeliveryDetailsCard emailDelivery={emailDelivery} refetch={refetch} />}
      sidebarMenuList={(emailDelivery) => menuListEmailDelivery(emailDelivery)}
    />
  );
};

export default InfoMailDeliveryDetailsPage;
