import React, { FC } from 'react';
import { Space } from 'antd';
import { DeviationCellStyledText, deviationCellTextColor, DeviationColumnTitleStyledText } from '../../../Styled/Deviation.style';
import { TDeviationCell } from '../../../deviation.model';
import DeviationFormattedCellValueBasedOnType from './DeviationFormattedCellValueBasedOnType';
import { cellsNewAltOrder, isDeviationAttributeTypeAmount, isDeviationAttributeTypePercent } from '../../../deviationHelpers';

type Props = {
  cellsCol: TDeviationCell[];
  columnCount: number;
};

const DeviationColumnNotReferenceOfCauseCol: FC<Props> = ({ cellsCol, columnCount }) => {
  const allCellsInNeuAltOrder = cellsNewAltOrder(cellsCol);
  const cellsHeader = allCellsInNeuAltOrder.filter((cell) => cell.header);

  const shouldAlignToRight =
    cellsCol[0] && (isDeviationAttributeTypeAmount(cellsCol[0].columnType) || isDeviationAttributeTypePercent(cellsCol[0].columnType));

  return (
    <Space direction="vertical" align={shouldAlignToRight ? 'end' : 'start'}>
      {cellsHeader[0] ? (
        <DeviationColumnTitleStyledText key={0} type="secondary">
          {cellsHeader[0].header.text}
        </DeviationColumnTitleStyledText>
      ) : null}
      {allCellsInNeuAltOrder.map((cellNeu, index) => {
        return (
          <React.Fragment key={index}>
            <DeviationCellStyledText style={{ color: deviationCellTextColor(cellNeu) }} strong={cellNeu.hasChanged}>
              <DeviationFormattedCellValueBasedOnType cell={cellNeu} columnCount={columnCount} />
            </DeviationCellStyledText>
          </React.Fragment>
        );
      })}
    </Space>
  );
};

export default DeviationColumnNotReferenceOfCauseCol;
