import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import menuListWeTopAbr from './menuListWeTopAbr';
import { VerarbeitungTopAbrDetailsPageRouteParams } from './routes';
import { useTopAbrechnungGenerierlaufQuery } from '../../../features/Verarbeitung/gql/TopAbrechnungGenerierlauf/TopAbrGenerierlaufQueries.types';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import { TopAbrechnungGenerierlauf } from '../../../types';
import { useRestartTopAbrechnungGenerierlaufMutation } from '../../../features/Verarbeitung/gql/TopAbrechnungGenerierlauf/TopAbrGenerierlaufMutations.types';

const WeTopAbrDetailsPage = () => {
  const { generierlaufId } = useParams() as VerarbeitungTopAbrDetailsPageRouteParams;

  const { data, refetch } = useTopAbrechnungGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getTopAbrechnungGenerierlauf.data;

  const [runRestart] = useRestartTopAbrechnungGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<TopAbrechnungGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.WeTopAbrechnung}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.WeTopAbrechnung}
          onGenerierlaufRestart={runRestart}
        />
      )}
      sidebarMenuList={(generierlauf) => menuListWeTopAbr(generierlauf.generierlaufId, generierlauf)}
    />
  );
};

export default WeTopAbrDetailsPage;
