import React, { FC, JSX } from 'react';
import { Formik } from 'formik';
import {
  kontoListingFiltersFormFieldNames,
  KontoListingFiltersFormValues,
  mapFormValuesToKontoListingFilters,
  mapQueryParamsToFormValues,
} from './Filters/kontoListingFiltersFormMapper';
import FiltersWithMax3Fields from '../../../components/Filters/FiltersWithMax3Fields';
import { TKontenplanQueryParams } from '../../../pages/SysSettingsPage/KundenSystem/KontenplanPage/routes';
import KontoKlasse from './Filters/KontoKlasse';
import KontoType from './Filters/KontoType';
import KontoVerwendung from './Filters/KontoVerwendung';

type Props = {
  filters: TKontenplanQueryParams;
  onChange: (filters: TKontenplanQueryParams) => void;
  actionButtons: JSX.Element;
};

const KontoListingFilters: FC<Props> = ({ onChange, actionButtons, filters }) => {
  return (
    <Formik<KontoListingFiltersFormValues>
      initialValues={mapQueryParamsToFormValues(filters)}
      onSubmit={(values) => {
        const filters = mapFormValuesToKontoListingFilters(values);
        onChange(filters);
      }}
    >
      {(formikProps) => (
        <FiltersWithMax3Fields
          actionButtons={actionButtons}
          filters={
            <>
              <KontoKlasse name={kontoListingFiltersFormFieldNames.klasse} onChange={formikProps.submitForm} style={{ width: '200px' }} />
              <KontoType name={kontoListingFiltersFormFieldNames.type} onChange={formikProps.submitForm} style={{ width: '220px' }} />
              <KontoVerwendung name={kontoListingFiltersFormFieldNames.verwendung} onChange={formikProps.submitForm} style={{ width: '200px' }} />
            </>
          }
        />
      )}
    </Formik>
  );
};

export default KontoListingFilters;
