import React, { FC, PropsWithChildren, useCallback } from 'react';
import createCtx from '../../helpers/createCtx';
import { useFibuEinstellungQuery } from '../../features/AllgemeineEinstellung/gql/FibuEinstellungQueries.types';
import { SollHaben } from '../../types';
import { useOnFirmendatenSettingsDataChangedEvents } from './useOnFirmendatenSettingsDataChangedEvents';

type FirmendatenSettingContextProviderType = {
  calculateBetragBasedOnSettings: (sollHaben: SollHaben, betrag: number) => number;
};

const useFirmendatenSettingsFibuEinstellung = () => {
  const { data, refetch } = useFibuEinstellungQuery();
  const fibuEinstellung = data?.getFibuEinstellung.data;
  const negierteHabenBetraege = fibuEinstellung?.negierteHabenBetraege ?? false;

  useOnFirmendatenSettingsDataChangedEvents(refetch);

  const calculateBetragBasedOnSettings = useCallback(
    (sollHaben: SollHaben, betrag: number) => {
      const calculateHabenBetrag = (betrag: number) => {
        return negierteHabenBetraege ? betrag * -1 : betrag;
      };
      return sollHaben === SollHaben.Soll ? betrag : calculateHabenBetrag(betrag);
    },
    [negierteHabenBetraege]
  );

  return { calculateBetragBasedOnSettings };
};

const [useFirmendatenSettings, FirmendatenSettingsContextProvider] = createCtx<FirmendatenSettingContextProviderType>();

const FirmendatenSettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { calculateBetragBasedOnSettings } = useFirmendatenSettingsFibuEinstellung();

  return <FirmendatenSettingsContextProvider value={{ calculateBetragBasedOnSettings }}>{children}</FirmendatenSettingsContextProvider>;
};

export { useFirmendatenSettings, FirmendatenSettingsProvider };
