import React from 'react';
import { Typography } from 'antd';
import EmailDeliveryDefinitionTableActions from './EmailDeliveryDefinitionTableActions';
import { MitarbeiterTooltip } from '../../../components/Card';
import { compareTwoStringsForSorting } from '../../../helpers/stringHelper';
import { compareTwoDatesForSorting } from '../../../helpers/dateHelper';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { EmailDeliveryDefinitionBase } from '../../../types';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';

const emailDeliveryDefinitionTableColumns = (onAction: () => void): TableWithColSelectorColumnProps<EmailDeliveryDefinitionBase>[] => [
  {
    title: 'Bezeichnung',
    sorter: (a, b) => compareTwoStringsForSorting(a.bezeichnung, b.bezeichnung),
    render: (text, record) => <Typography.Text>{record.bezeichnung}</Typography.Text>,
  },
  {
    title: 'Typ',
    sorter: (a, b) => compareTwoStringsForSorting(a.type.text, b.type.text),
    render: (text, record) => <Typography.Text>{record.type.text}</Typography.Text>,
  },
  {
    title: 'Erstellt am',
    sorter: (a, b) => compareTwoDatesForSorting(a.createTs, b.createTs),
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    render: (text, record) => <EmailDeliveryDefinitionTableActions emailDeliveryDefinition={record} onAction={onAction} />,
  },
];

export default emailDeliveryDefinitionTableColumns;
