import * as Yup from 'yup';
import { entityIsRequired } from '../../../../components/message/validationMsg';
import { abrechnungsCreateFormFields } from './abrechnungCreateFormMapper';

/* eslint-disable no-template-curly-in-string */
export const namedObjAbrCreateValidationSchema = Yup.object().shape({
  [abrechnungsCreateFormFields.bezeichnung]: Yup.string().required(entityIsRequired('Bezeichnung')),
  [abrechnungsCreateFormFields.fromTo]: Yup.array().nullable().required(entityIsRequired('Zeitraum')).min(2, entityIsRequired('Zeitraum')),
});
/* eslint-enable no-template-curly-in-string */
