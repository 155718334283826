import React from 'react';
import { Col, Row, Space, Tag, Typography } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { Link } from 'react-router-dom';
import DataWithShortenedText from '../../../../../../components/Helpers/DataWithShortenedText';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';
import { generatePathToRechtstraegerDetailsPage } from '../../../../../Rechtstraeger/rechtstraegerHelper';
import SendCheckboxColumn from './SendCheckboxColumn';
import { VorschreibungMailDelivery, VorschreibungMailDeliveryRecipientListStepOrderBy } from '../../../../../../types';
import { generatePathToBeVertragDetailsPage } from '../../../../../Vertrag/BeVertrag/beVertragUriPaths';
import { getObjektVertragList } from '../../../../../EmailDelivery/VorschreibungMailDelivery/helpers';
import SendSwitchColumn from './SendDefaultSwitchColumn';

const recipientTableColumns = (onUpdate: () => void, wizardId: string, isCompleted?: boolean): ColumnProps<VorschreibungMailDelivery>[] => [
  {
    title: 'Objekt-Top',
    render: (_, record) => {
      return getObjektVertragList(record.objektBestandseinheitVertragList).map((vertrag) => {
        return (
          <Row style={{ width: '100%' }} key={record.vorschreibungMailDeliveryId + vertrag.vertragId}>
            <Col>
              <DataWithShortenedText text={vertrag.topName} maxTextLength={30}>
                {(shortenedText) => (
                  <Link to={generatePathToBeVertragDetailsPage(vertrag.objektId, vertrag.bestandseinheitId, vertrag.vertragId)}>{shortenedText}</Link>
                )}
              </DataWithShortenedText>
            </Col>
          </Row>
        );
      });
    },
  },
  {
    title: 'Beleg',
    dataIndex: VorschreibungMailDeliveryRecipientListStepOrderBy.OriginalKopie,
    sorter: true,
    render: (_, record) => (record.original ? <Tag>Original</Tag> : <Tag>Kopie</Tag>),
  },
  {
    title: 'Vorschreibungsperiode',
    dataIndex: VorschreibungMailDeliveryRecipientListStepOrderBy.Fakturierungsperiode,
    sorter: true,
    render: (_, record) => (
      <Typography.Text>
        {record.fakturierungsperiode.monat} / {record.fakturierungsperiode.jahr}
      </Typography.Text>
    ),
  },
  {
    title: 'Vertragspartner',
    dataIndex: VorschreibungMailDeliveryRecipientListStepOrderBy.Vertragspartner,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText text={record.vertragspartner.kurzBezeichnung} maxTextLength={25}>
        {(shortenedText) => <Link to={generatePathToRechtstraegerDetailsPage(record.vertragspartner.rechtstraegerId)}>{shortenedText}</Link>}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Empfänger',
    dataIndex: VorschreibungMailDeliveryRecipientListStepOrderBy.Recipient,
    sorter: true,
    render: (text, record) =>
      record.vertragspartner.rechtstraegerId !== record.recipient.rechtstraegerId ? (
        <DataWithShortenedText text={record.recipient.kurzBezeichnung} maxTextLength={20}>
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          {(shortenedText) => <Link to={generatePathToRechtstraegerDetailsPage(record.recipient!.rechtstraegerId)}>{shortenedText}</Link>}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'E-Mail',
    dataIndex: VorschreibungMailDeliveryRecipientListStepOrderBy.Email,
    sorter: true,
    render: (text, record) => <Typography.Text>{record.emailAddress}</Typography.Text>,
  },
  {
    title: 'Senden',
    align: 'right',
    width: 150,
    render: (text, record) => {
      const checkbox = <SendCheckboxColumn vorschreibungMailDelivery={record} isCompleted={isCompleted} onUpdate={onUpdate} wizardId={wizardId} />;
      return record.shouldSend ? (
        checkbox
      ) : (
        <Space direction="horizontal" align="end">
          <SendSwitchColumn vorschreibungMailDelivery={record} isCompleted={isCompleted} onUpdate={onUpdate} wizardId={wizardId} />
          {checkbox}
        </Space>
      );
    },
  },
];

export default recipientTableColumns;
