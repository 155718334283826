import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../styles/pdfStyles';
import { Abrechnungskreis } from '../../../../objektAbrechnung-types';
import OAAbrKreisKontoBuchungenAusgabe from './AusgabenKonto/OAAbrKreisKontoBuchungenAusgabe';
import OAAbrKreisKontoZwischensumme from './AusgabenKonto/OAAbrKreisKontoZwischensumme';
import OAAbrKreisKontoBezeichnung from './OAAbrKreisKontoBezeichnung';

const OAAbrKreisKontoList: FC<{ abrechKreis: Abrechnungskreis }> = ({ abrechKreis }) => (
  <>
    {abrechKreis.kontoList.map((konto, index) => (
      <View style={[pdfStyles.column]} key={index} wrap={false}>
        {/* Kontobezeichnung */}
        <OAAbrKreisKontoBezeichnung konto={konto} />

        {konto.buchungAusgabenList.length > 0 && (
          <>
            {/* Buchungen Ausgabe */}
            {konto.buchungAusgabenList.map((buchungAusgabe, index) => (
              <OAAbrKreisKontoBuchungenAusgabe buchungAusgabe={buchungAusgabe} key={index} />
            ))}
            {/* Zwischensumme */}
            <OAAbrKreisKontoZwischensumme konto={konto} />
          </>
        )}
      </View>
    ))}
  </>
);

export default OAAbrKreisKontoList;
