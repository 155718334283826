import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Col, Row, Space, Switch, Typography } from 'antd';
import { mapFormValuesToDokumentenversand, versandFormFields, versandFormInitialValues, VersandFormValues } from './versandFormMapper';
import FormButtons from '../../../../../components/Button/FormButtons';
import {
  useCreateBeVertragDokumentenversandMutation,
  useUpdateBeVertragDokumentenversandMutation,
} from '../gql/BeVertragDokumentenversandMutations.types';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { DokumentenversandEntryFragment } from '../gql/BeVertragDokumentenVersandFragments.types';
import { versandFormValidationSchema } from './versandFormValidationSchema';
import RechtstraegerAndContactSelects from './RechtstraegerAndContactSelects';
import { useGetAppFieldHelpText } from '../../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../../components/Form/FormItemWithFieldHelp';
import FieldHelpIconWithTooltip from '../../../../../components/FieldHelpIcon/FieldHelpIconWithTooltip';

type VersandFormProps = {
  onSuccess: () => void;
  onCancel: () => void;
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  isOriginal?: boolean;
  dokumentenversandEntry?: DokumentenversandEntryFragment;
};

const VersandForm: FC<VersandFormProps> = ({
  onSuccess,
  onCancel,
  objektId,
  bestandseinheitId,
  dokumentenversandEntry,
  vertragId,
  isOriginal = false,
}) => {
  const getFieldHelpText = useGetAppFieldHelpText<'BeVertragDokumentenversand'>('BeVertragDokumentenversand');

  const entity = 'Dokumentenversand';
  const isUpdate = !!dokumentenversandEntry;

  const [runCreate] = useCreateBeVertragDokumentenversandMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate] = useUpdateBeVertragDokumentenversandMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<VersandFormValues>
      initialValues={versandFormInitialValues(dokumentenversandEntry)}
      validationSchema={versandFormValidationSchema(isOriginal)}
      onSubmit={(values, { setSubmitting }) => {
        const input = mapFormValuesToDokumentenversand(values);
        if (isUpdate) {
          runUpdate({
            variables: {
              objektId,
              bestandseinheitId,
              vertragId,
              dokumentenversandEntryId: dokumentenversandEntry.dokumentenversandEntryId,
              input,
            },
          }).finally(() => setSubmitting(false));
        } else {
          runCreate({
            variables: {
              objektId,
              bestandseinheitId,
              vertragId,
              input,
            },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Dokumentenversand</Typography.Title>
            </Col>
            <Col span={18}>
              <Row>
                <FormItemWithFieldHelp name={versandFormFields.emailAktiv}>
                  <Space>
                    <Switch
                      id={versandFormFields.emailAktiv}
                      defaultChecked={versandFormInitialValues(dokumentenversandEntry).emailAktiv}
                      onChange={(checked) => formikProps.setFieldValue(versandFormFields.emailAktiv, checked)}
                      size="small"
                    />
                    <Typography.Text>{`E-Mail ${formikProps.values.emailAktiv ? 'aktiviert' : 'deaktiviert'}`}</Typography.Text>
                    <FieldHelpIconWithTooltip fieldHelp={getFieldHelpText('BeVertragDokumentenversand.emailAktiv')} />
                  </Space>
                </FormItemWithFieldHelp>
              </Row>
              {isOriginal && (
                <Row>
                  <FormItemWithFieldHelp name={versandFormFields.postAktiv} fieldHelp={getFieldHelpText('BeVertragDokumentenversand.postAktiv')}>
                    <Space>
                      <Switch
                        id={versandFormFields.postAktiv}
                        defaultChecked={versandFormInitialValues(dokumentenversandEntry).postAktiv}
                        onChange={(checked) => formikProps.setFieldValue(versandFormFields.postAktiv, checked)}
                        size="small"
                      />
                      <Typography.Text>{`Post ${formikProps.values.postAktiv ? 'aktiviert' : 'deaktiviert'}`}</Typography.Text>
                      <FieldHelpIconWithTooltip fieldHelp={getFieldHelpText('BeVertragDokumentenversand.postAktiv')} />
                    </Space>
                  </FormItemWithFieldHelp>
                </Row>
              )}
              <RechtstraegerAndContactSelects
                formikProps={formikProps}
                isOriginal={isOriginal}
                fieldHelpNames={{
                  rechtstraegerId: getFieldHelpText('BeVertragDokumentenversand.rechtstraegerId'),
                  emailContactId: getFieldHelpText('BeVertragDokumentenversand.emailContactId'),
                  postAddressId: getFieldHelpText('BeVertragDokumentenversand.postAddressId'),
                }}
              />
            </Col>
          </Row>
          <FormButtons isOkDisabled={formikProps.isSubmitting} onCancel={onCancel} updateMode={isUpdate} />
        </Form>
      )}
    </Formik>
  );
};

export default VersandForm;
