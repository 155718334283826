import React, { FC } from 'react';
import { Typography } from 'antd';
import { FileInfoType } from '../../types';
import DocumentTab from '../Document/DocumentTab';

type Props = {
  mailId: string;
};

const EmailDeliveryAttachmentView: FC<Props> = ({ mailId }) => {
  return (
    <>
      <Typography.Title level={5}>Anhänge</Typography.Title>
      <DocumentTab mailId={mailId} hideFilters typeList={[FileInfoType.MailAttachment, FileInfoType.AusgangsRechnung, FileInfoType.Mahnung]} />
    </>
  );
};

export default EmailDeliveryAttachmentView;
