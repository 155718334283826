import { FormFields } from '../../../../../helpers/formikHelper';
import {
  BudgetingConfigureFinancialPlanTextsExpenseInput,
  BudgetingActionConfigureFinancialPlanTextsInput,
  BudgetingVPos,
  FinancialPlanTexts,
} from '../../../../../types';

export type TextinfoWirtschaftsplanFormValues = {
  budgetingVPosId: string;
  einleitungsText: string;
  expenseList: FinancialPlanTextsExpenseListFormValues[];
  schlussText: string;
};

export type FinancialPlanTextsExpenseListFormValues = {
  amount: number | string;
  name: string;
};

export const textinfoWirtschaftsplanFormValuesFormFields: FormFields<TextinfoWirtschaftsplanFormValues> = {
  budgetingVPosId: 'budgetingVPosId',
  einleitungsText: 'einleitungsText',
  expenseList: 'expenseList',
  schlussText: 'schlussText',
};

export const financialPlanTextsExpenseListFormValues: FormFields<FinancialPlanTextsExpenseListFormValues> = {
  amount: 'amount',
  name: 'name',
};

export const createFinancialPlanTextsExpenseListFormValues = (): FinancialPlanTextsExpenseListFormValues => ({
  amount: '',
  name: '',
});

export const textinfoWirtschaftsplanFormInitialValues: TextinfoWirtschaftsplanFormValues = {
  budgetingVPosId: '',
  einleitungsText: '',
  expenseList: [],
  schlussText: '',
};

export const mapTextinfoWirtschaftsplanToFormValues = (
  budgetingVPos: BudgetingVPos,
  isVPosRepfonds: boolean,
  financialPlanTexts?: FinancialPlanTexts
): TextinfoWirtschaftsplanFormValues => {
  if (!financialPlanTexts) {
    return {
      ...textinfoWirtschaftsplanFormInitialValues,
      budgetingVPosId: budgetingVPos.budgetingVPosId,
    };
  }
  return {
    budgetingVPosId: budgetingVPos.budgetingVPosId,
    einleitungsText: financialPlanTexts.einleitungsText ?? '',
    expenseList: isVPosRepfonds ? financialPlanTexts.expenseList.map(({ amount, name }) => ({ amount, name })) : [],
    schlussText: financialPlanTexts.schlussText ?? '',
  };
};

export const mapFormValuesToTextinfoWirtschaftsplan = (
  formValues: TextinfoWirtschaftsplanFormValues,
  isVPosRepfonds: boolean
): BudgetingActionConfigureFinancialPlanTextsInput => ({
  budgetingVPosId: formValues.budgetingVPosId,
  einleitungsText: formValues.einleitungsText,
  expenseList: isVPosRepfonds ? mapFormValuesToFinancialPlanTextsExpenseList(formValues.expenseList) : [],
  schlussText: formValues.schlussText,
});

export const mapFormValuesToFinancialPlanTextsExpenseList = (
  expenseList: FinancialPlanTextsExpenseListFormValues[]
): BudgetingConfigureFinancialPlanTextsExpenseInput[] => {
  return expenseList.map(({ name, amount }) => ({
    name,
    amount: Number(amount) || 0,
  }));
};
