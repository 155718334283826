import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import KontoBezugTab from './KontoBezug/KontoBezugTab';
import { Konto } from '../../../types';

export const KontoTabs = {
  BEZUEGE: { name: 'Bezüge', key: '1' },
} as const;

type Props = {
  konto: Konto;
};

const KontoEinstellungenTabs: FC<Props> = ({ konto }) => {
  const { name: label, key } = KontoTabs.BEZUEGE;

  const items: TabsProps['items'] = [
    {
      key,
      label,
      children: <KontoBezugTab konto={konto} />,
    },
  ];

  return <Tabs items={items} />;
};

export default KontoEinstellungenTabs;
