import { filter } from 'lodash';
import { Table } from 'antd';
import React from 'react';
import { objektList } from '../../Contact/mockObjekt';

// TODO: delete dataIndex as soon as TS is here
const NestedTable = () => {
  const expandedRowRender = (row) => {
    const columns = [
      { title: 'Top', dataIndex: 'bezeichnung' },
      { title: 'BT', dataIndex: 'bauteil' },
      { title: 'Stiege', dataIndex: 'stiege' },
      { title: 'Geschoss', dataIndex: 'geschoss' },
      { title: 'Nutzung', dataIndex: ['nutzungsArt', 'text'] },
      { title: 'Eigentümer', dataIndex: 'eigentuemer' },
      { title: 'Mieter', dataIndex: 'mieter' },
      { title: 'Untermieter', dataIndex: 'untermieter', render: (text) => (text ? <span>{text}</span> : <span>-</span>) },
    ];

    // the filtered value will be a prop in future implementations, eigentuemerID could be a router param
    const filterByEigentuemerId = (data, eigentuemerId) => filter(data, { eigentuemerId });
    const filteredData = filterByEigentuemerId(row.bestandseinheitList, '1000');

    return (
      <Table rowKey={(filteredData) => filteredData.bezeichnung} bordered={false} columns={columns} dataSource={filteredData} pagination={false} />
    );
  };

  const data = objektList.slice(0, 2, objektList.length);
  const columns = [{ title: 'Objekt', dataIndex: 'kurzBezeichnung' }];

  return (
    <Table
      rowKey="objektId"
      showHeader={false}
      defaultExpandAllRows
      expandedRowRender={expandedRowRender}
      columns={columns}
      dataSource={data}
      header={() => ''}
      pagination={false}
    />
  );
};

export default NestedTable;
