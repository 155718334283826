import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useMailUseCaseListQuery } from '../../gql/EmailDeliveryQueries.types';

const UseCaseListFormSelect: FC<SelectProps> = ({ name, ...restProps }) => {
  const { data, loading } = useMailUseCaseListQuery();
  const list = data?.getMailUseCaseList.data ?? [];

  return (
    <Select {...restProps} size="small" id={name} name={name} loading={loading} placeholder="Anwendungsfall auswählen" mode="multiple" allowClear>
      {list.map((useCase) => (
        <Select.Option key={useCase.value} value={useCase.value}>
          {useCase.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default UseCaseListFormSelect;
