import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { OrderBestandseinheitFragmentDoc } from '../../../Bestandseinheit/gql/BestandseinheitFragments.types';
import { OrderObjektFragmentDoc, ObjektFragmentDoc } from '../../../Objekt/gql/ObjektFragments.types';
import { OrderRechtstraegerFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FilterGenLaufOrderBelegnummerListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.OrderGenerierlaufType;
}>;

export type FilterGenLaufOrderBelegnummerListQuery = {
  getFilterGenLaufOrderBelegnummerList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufOrderBestandseinheitListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.OrderGenerierlaufType;
  objektId: Types.Scalars['ID']['input'];
}>;

export type FilterGenLaufOrderBestandseinheitListQuery = {
  getFilterGenLaufOrderBestandseinheitList: {
    data: Array<{
      bestandseinheitId: string;
      bezeichnung: string;
      address: {
        addressId: string;
        city: string;
        cityAdditionalInformation?: string | null;
        countryCodeIso2: string;
        houseEntranceApartmentNumber?: string | null;
        postofficeBoxNumber?: string | null;
        postofficeBoxZipCode?: string | null;
        street?: string | null;
        type: Types.AddressType;
        zipCode?: string | null;
      };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufOrderFakturierungsperiodeListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.OrderGenerierlaufType;
}>;

export type FilterGenLaufOrderFakturierungsperiodeListQuery = {
  getFilterGenLaufOrderFakturierungsperiodeList: {
    data: Array<{ jahr: number; monat: number }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufOrderGenerierlaufEntryStatusListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.OrderGenerierlaufType;
}>;

export type FilterGenLaufOrderGenerierlaufEntryStatusListQuery = {
  getFilterGenLaufOrderGenerierlaufEntryStatusList: {
    data: Array<{ text: string; value: Types.GenerierlaufEntryExitCode }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufOrderObjektListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.OrderGenerierlaufType;
  rechnungsAusstellerId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type FilterGenLaufOrderObjektListQuery = {
  getFilterGenLaufOrderObjektList: {
    data: Array<{ kurzBezeichnung: string; objektId: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufOrderRechnungsAusstellerListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.OrderGenerierlaufType;
}>;

export type FilterGenLaufOrderRechnungsAusstellerListQuery = {
  getFilterGenLaufOrderRechnungsAusstellerList: {
    data: Array<{ kurzBezeichnung: string; kundennummer: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufOrderRechnungsEmpfaengerListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.OrderGenerierlaufType;
}>;

export type FilterGenLaufOrderRechnungsEmpfaengerListQuery = {
  getFilterGenLaufOrderRechnungsEmpfaengerList: {
    data: Array<{ kurzBezeichnung: string; kundennummer: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufOrderPaymentTransactionTypeListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.OrderGenerierlaufType;
}>;

export type FilterGenLaufOrderPaymentTransactionTypeListQuery = {
  getFilterGenLaufOrderPaymentTransactionTypeList: {
    data: Array<{ text: string; value: Types.PaymentTransactionType }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufOrderPaymentTypeListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.OrderGenerierlaufType;
}>;

export type FilterGenLaufOrderPaymentTypeListQuery = {
  getFilterGenLaufOrderPaymentTypeList: {
    data: Array<{ text: string; value: Types.PaymentType }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufOrderRecipientEmailAddressListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.OrderGenerierlaufType;
}>;

export type FilterGenLaufOrderRecipientEmailAddressListQuery = {
  getFilterGenLaufOrderRecipientEmailAddressList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufOrderRecipientListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.OrderGenerierlaufType;
}>;

export type FilterGenLaufOrderRecipientListQuery = {
  getFilterGenLaufOrderRecipientList: {
    data: Array<{ kurzBezeichnung: string; kundennummer: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufOrderVertragspartnerListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.OrderGenerierlaufType;
}>;

export type FilterGenLaufOrderVertragspartnerListQuery = {
  getFilterGenLaufOrderVertragspartnerList: {
    data: Array<{ kurzBezeichnung: string; kundennummer: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const FilterGenLaufOrderBelegnummerListDocument = gql`
  query FilterGenLaufOrderBelegnummerList($generierlaufId: ID!, $generierlaufType: OrderGenerierlaufType!) {
    getFilterGenLaufOrderBelegnummerList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufOrderBelegnummerListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufOrderBelegnummerListQuery, FilterGenLaufOrderBelegnummerListQueryVariables> &
    ({ variables: FilterGenLaufOrderBelegnummerListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufOrderBelegnummerListQuery, FilterGenLaufOrderBelegnummerListQueryVariables>(
    FilterGenLaufOrderBelegnummerListDocument,
    options
  );
}
export function useFilterGenLaufOrderBelegnummerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufOrderBelegnummerListQuery, FilterGenLaufOrderBelegnummerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufOrderBelegnummerListQuery, FilterGenLaufOrderBelegnummerListQueryVariables>(
    FilterGenLaufOrderBelegnummerListDocument,
    options
  );
}
export function useFilterGenLaufOrderBelegnummerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufOrderBelegnummerListQuery, FilterGenLaufOrderBelegnummerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufOrderBelegnummerListQuery, FilterGenLaufOrderBelegnummerListQueryVariables>(
    FilterGenLaufOrderBelegnummerListDocument,
    options
  );
}
export type FilterGenLaufOrderBelegnummerListQueryHookResult = ReturnType<typeof useFilterGenLaufOrderBelegnummerListQuery>;
export type FilterGenLaufOrderBelegnummerListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufOrderBelegnummerListLazyQuery>;
export type FilterGenLaufOrderBelegnummerListSuspenseQueryHookResult = ReturnType<typeof useFilterGenLaufOrderBelegnummerListSuspenseQuery>;
export type FilterGenLaufOrderBelegnummerListQueryResult = Apollo.QueryResult<
  FilterGenLaufOrderBelegnummerListQuery,
  FilterGenLaufOrderBelegnummerListQueryVariables
>;
export const FilterGenLaufOrderBestandseinheitListDocument = gql`
  query FilterGenLaufOrderBestandseinheitList($generierlaufId: ID!, $generierlaufType: OrderGenerierlaufType!, $objektId: ID!) {
    getFilterGenLaufOrderBestandseinheitList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType, objektId: $objektId) {
      data {
        ...OrderBestandseinheit
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${OrderBestandseinheitFragmentDoc}
`;
export function useFilterGenLaufOrderBestandseinheitListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufOrderBestandseinheitListQuery, FilterGenLaufOrderBestandseinheitListQueryVariables> &
    ({ variables: FilterGenLaufOrderBestandseinheitListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufOrderBestandseinheitListQuery, FilterGenLaufOrderBestandseinheitListQueryVariables>(
    FilterGenLaufOrderBestandseinheitListDocument,
    options
  );
}
export function useFilterGenLaufOrderBestandseinheitListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufOrderBestandseinheitListQuery, FilterGenLaufOrderBestandseinheitListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufOrderBestandseinheitListQuery, FilterGenLaufOrderBestandseinheitListQueryVariables>(
    FilterGenLaufOrderBestandseinheitListDocument,
    options
  );
}
export function useFilterGenLaufOrderBestandseinheitListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufOrderBestandseinheitListQuery, FilterGenLaufOrderBestandseinheitListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufOrderBestandseinheitListQuery, FilterGenLaufOrderBestandseinheitListQueryVariables>(
    FilterGenLaufOrderBestandseinheitListDocument,
    options
  );
}
export type FilterGenLaufOrderBestandseinheitListQueryHookResult = ReturnType<typeof useFilterGenLaufOrderBestandseinheitListQuery>;
export type FilterGenLaufOrderBestandseinheitListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufOrderBestandseinheitListLazyQuery>;
export type FilterGenLaufOrderBestandseinheitListSuspenseQueryHookResult = ReturnType<typeof useFilterGenLaufOrderBestandseinheitListSuspenseQuery>;
export type FilterGenLaufOrderBestandseinheitListQueryResult = Apollo.QueryResult<
  FilterGenLaufOrderBestandseinheitListQuery,
  FilterGenLaufOrderBestandseinheitListQueryVariables
>;
export const FilterGenLaufOrderFakturierungsperiodeListDocument = gql`
  query FilterGenLaufOrderFakturierungsperiodeList($generierlaufId: ID!, $generierlaufType: OrderGenerierlaufType!) {
    getFilterGenLaufOrderFakturierungsperiodeList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        jahr
        monat
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufOrderFakturierungsperiodeListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufOrderFakturierungsperiodeListQuery, FilterGenLaufOrderFakturierungsperiodeListQueryVariables> &
    ({ variables: FilterGenLaufOrderFakturierungsperiodeListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufOrderFakturierungsperiodeListQuery, FilterGenLaufOrderFakturierungsperiodeListQueryVariables>(
    FilterGenLaufOrderFakturierungsperiodeListDocument,
    options
  );
}
export function useFilterGenLaufOrderFakturierungsperiodeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufOrderFakturierungsperiodeListQuery, FilterGenLaufOrderFakturierungsperiodeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufOrderFakturierungsperiodeListQuery, FilterGenLaufOrderFakturierungsperiodeListQueryVariables>(
    FilterGenLaufOrderFakturierungsperiodeListDocument,
    options
  );
}
export function useFilterGenLaufOrderFakturierungsperiodeListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufOrderFakturierungsperiodeListQuery, FilterGenLaufOrderFakturierungsperiodeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufOrderFakturierungsperiodeListQuery, FilterGenLaufOrderFakturierungsperiodeListQueryVariables>(
    FilterGenLaufOrderFakturierungsperiodeListDocument,
    options
  );
}
export type FilterGenLaufOrderFakturierungsperiodeListQueryHookResult = ReturnType<typeof useFilterGenLaufOrderFakturierungsperiodeListQuery>;
export type FilterGenLaufOrderFakturierungsperiodeListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufOrderFakturierungsperiodeListLazyQuery>;
export type FilterGenLaufOrderFakturierungsperiodeListSuspenseQueryHookResult = ReturnType<
  typeof useFilterGenLaufOrderFakturierungsperiodeListSuspenseQuery
>;
export type FilterGenLaufOrderFakturierungsperiodeListQueryResult = Apollo.QueryResult<
  FilterGenLaufOrderFakturierungsperiodeListQuery,
  FilterGenLaufOrderFakturierungsperiodeListQueryVariables
>;
export const FilterGenLaufOrderGenerierlaufEntryStatusListDocument = gql`
  query FilterGenLaufOrderGenerierlaufEntryStatusList($generierlaufId: ID!, $generierlaufType: OrderGenerierlaufType!) {
    getFilterGenLaufOrderGenerierlaufEntryStatusList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufOrderGenerierlaufEntryStatusListQuery(
  baseOptions: Apollo.QueryHookOptions<
    FilterGenLaufOrderGenerierlaufEntryStatusListQuery,
    FilterGenLaufOrderGenerierlaufEntryStatusListQueryVariables
  > &
    ({ variables: FilterGenLaufOrderGenerierlaufEntryStatusListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufOrderGenerierlaufEntryStatusListQuery, FilterGenLaufOrderGenerierlaufEntryStatusListQueryVariables>(
    FilterGenLaufOrderGenerierlaufEntryStatusListDocument,
    options
  );
}
export function useFilterGenLaufOrderGenerierlaufEntryStatusListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterGenLaufOrderGenerierlaufEntryStatusListQuery,
    FilterGenLaufOrderGenerierlaufEntryStatusListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufOrderGenerierlaufEntryStatusListQuery, FilterGenLaufOrderGenerierlaufEntryStatusListQueryVariables>(
    FilterGenLaufOrderGenerierlaufEntryStatusListDocument,
    options
  );
}
export function useFilterGenLaufOrderGenerierlaufEntryStatusListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufOrderGenerierlaufEntryStatusListQuery, FilterGenLaufOrderGenerierlaufEntryStatusListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufOrderGenerierlaufEntryStatusListQuery, FilterGenLaufOrderGenerierlaufEntryStatusListQueryVariables>(
    FilterGenLaufOrderGenerierlaufEntryStatusListDocument,
    options
  );
}
export type FilterGenLaufOrderGenerierlaufEntryStatusListQueryHookResult = ReturnType<typeof useFilterGenLaufOrderGenerierlaufEntryStatusListQuery>;
export type FilterGenLaufOrderGenerierlaufEntryStatusListLazyQueryHookResult = ReturnType<
  typeof useFilterGenLaufOrderGenerierlaufEntryStatusListLazyQuery
>;
export type FilterGenLaufOrderGenerierlaufEntryStatusListSuspenseQueryHookResult = ReturnType<
  typeof useFilterGenLaufOrderGenerierlaufEntryStatusListSuspenseQuery
>;
export type FilterGenLaufOrderGenerierlaufEntryStatusListQueryResult = Apollo.QueryResult<
  FilterGenLaufOrderGenerierlaufEntryStatusListQuery,
  FilterGenLaufOrderGenerierlaufEntryStatusListQueryVariables
>;
export const FilterGenLaufOrderObjektListDocument = gql`
  query FilterGenLaufOrderObjektList($generierlaufId: ID!, $generierlaufType: OrderGenerierlaufType!, $rechnungsAusstellerId: ID) {
    getFilterGenLaufOrderObjektList(
      generierlaufId: $generierlaufId
      generierlaufType: $generierlaufType
      rechnungsAusstellerId: $rechnungsAusstellerId
    ) {
      data {
        ...OrderObjekt
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${OrderObjektFragmentDoc}
`;
export function useFilterGenLaufOrderObjektListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufOrderObjektListQuery, FilterGenLaufOrderObjektListQueryVariables> &
    ({ variables: FilterGenLaufOrderObjektListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufOrderObjektListQuery, FilterGenLaufOrderObjektListQueryVariables>(
    FilterGenLaufOrderObjektListDocument,
    options
  );
}
export function useFilterGenLaufOrderObjektListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufOrderObjektListQuery, FilterGenLaufOrderObjektListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufOrderObjektListQuery, FilterGenLaufOrderObjektListQueryVariables>(
    FilterGenLaufOrderObjektListDocument,
    options
  );
}
export function useFilterGenLaufOrderObjektListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterGenLaufOrderObjektListQuery, FilterGenLaufOrderObjektListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufOrderObjektListQuery, FilterGenLaufOrderObjektListQueryVariables>(
    FilterGenLaufOrderObjektListDocument,
    options
  );
}
export type FilterGenLaufOrderObjektListQueryHookResult = ReturnType<typeof useFilterGenLaufOrderObjektListQuery>;
export type FilterGenLaufOrderObjektListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufOrderObjektListLazyQuery>;
export type FilterGenLaufOrderObjektListSuspenseQueryHookResult = ReturnType<typeof useFilterGenLaufOrderObjektListSuspenseQuery>;
export type FilterGenLaufOrderObjektListQueryResult = Apollo.QueryResult<
  FilterGenLaufOrderObjektListQuery,
  FilterGenLaufOrderObjektListQueryVariables
>;
export const FilterGenLaufOrderRechnungsAusstellerListDocument = gql`
  query FilterGenLaufOrderRechnungsAusstellerList($generierlaufId: ID!, $generierlaufType: OrderGenerierlaufType!) {
    getFilterGenLaufOrderRechnungsAusstellerList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        ...OrderRechtstraeger
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${OrderRechtstraegerFragmentDoc}
`;
export function useFilterGenLaufOrderRechnungsAusstellerListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufOrderRechnungsAusstellerListQuery, FilterGenLaufOrderRechnungsAusstellerListQueryVariables> &
    ({ variables: FilterGenLaufOrderRechnungsAusstellerListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufOrderRechnungsAusstellerListQuery, FilterGenLaufOrderRechnungsAusstellerListQueryVariables>(
    FilterGenLaufOrderRechnungsAusstellerListDocument,
    options
  );
}
export function useFilterGenLaufOrderRechnungsAusstellerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufOrderRechnungsAusstellerListQuery, FilterGenLaufOrderRechnungsAusstellerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufOrderRechnungsAusstellerListQuery, FilterGenLaufOrderRechnungsAusstellerListQueryVariables>(
    FilterGenLaufOrderRechnungsAusstellerListDocument,
    options
  );
}
export function useFilterGenLaufOrderRechnungsAusstellerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufOrderRechnungsAusstellerListQuery, FilterGenLaufOrderRechnungsAusstellerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufOrderRechnungsAusstellerListQuery, FilterGenLaufOrderRechnungsAusstellerListQueryVariables>(
    FilterGenLaufOrderRechnungsAusstellerListDocument,
    options
  );
}
export type FilterGenLaufOrderRechnungsAusstellerListQueryHookResult = ReturnType<typeof useFilterGenLaufOrderRechnungsAusstellerListQuery>;
export type FilterGenLaufOrderRechnungsAusstellerListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufOrderRechnungsAusstellerListLazyQuery>;
export type FilterGenLaufOrderRechnungsAusstellerListSuspenseQueryHookResult = ReturnType<
  typeof useFilterGenLaufOrderRechnungsAusstellerListSuspenseQuery
>;
export type FilterGenLaufOrderRechnungsAusstellerListQueryResult = Apollo.QueryResult<
  FilterGenLaufOrderRechnungsAusstellerListQuery,
  FilterGenLaufOrderRechnungsAusstellerListQueryVariables
>;
export const FilterGenLaufOrderRechnungsEmpfaengerListDocument = gql`
  query FilterGenLaufOrderRechnungsEmpfaengerList($generierlaufId: ID!, $generierlaufType: OrderGenerierlaufType!) {
    getFilterGenLaufOrderRechnungsEmpfaengerList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        ...OrderRechtstraeger
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${OrderRechtstraegerFragmentDoc}
`;
export function useFilterGenLaufOrderRechnungsEmpfaengerListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufOrderRechnungsEmpfaengerListQuery, FilterGenLaufOrderRechnungsEmpfaengerListQueryVariables> &
    ({ variables: FilterGenLaufOrderRechnungsEmpfaengerListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufOrderRechnungsEmpfaengerListQuery, FilterGenLaufOrderRechnungsEmpfaengerListQueryVariables>(
    FilterGenLaufOrderRechnungsEmpfaengerListDocument,
    options
  );
}
export function useFilterGenLaufOrderRechnungsEmpfaengerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufOrderRechnungsEmpfaengerListQuery, FilterGenLaufOrderRechnungsEmpfaengerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufOrderRechnungsEmpfaengerListQuery, FilterGenLaufOrderRechnungsEmpfaengerListQueryVariables>(
    FilterGenLaufOrderRechnungsEmpfaengerListDocument,
    options
  );
}
export function useFilterGenLaufOrderRechnungsEmpfaengerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufOrderRechnungsEmpfaengerListQuery, FilterGenLaufOrderRechnungsEmpfaengerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufOrderRechnungsEmpfaengerListQuery, FilterGenLaufOrderRechnungsEmpfaengerListQueryVariables>(
    FilterGenLaufOrderRechnungsEmpfaengerListDocument,
    options
  );
}
export type FilterGenLaufOrderRechnungsEmpfaengerListQueryHookResult = ReturnType<typeof useFilterGenLaufOrderRechnungsEmpfaengerListQuery>;
export type FilterGenLaufOrderRechnungsEmpfaengerListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufOrderRechnungsEmpfaengerListLazyQuery>;
export type FilterGenLaufOrderRechnungsEmpfaengerListSuspenseQueryHookResult = ReturnType<
  typeof useFilterGenLaufOrderRechnungsEmpfaengerListSuspenseQuery
>;
export type FilterGenLaufOrderRechnungsEmpfaengerListQueryResult = Apollo.QueryResult<
  FilterGenLaufOrderRechnungsEmpfaengerListQuery,
  FilterGenLaufOrderRechnungsEmpfaengerListQueryVariables
>;
export const FilterGenLaufOrderPaymentTransactionTypeListDocument = gql`
  query FilterGenLaufOrderPaymentTransactionTypeList($generierlaufId: ID!, $generierlaufType: OrderGenerierlaufType!) {
    getFilterGenLaufOrderPaymentTransactionTypeList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufOrderPaymentTransactionTypeListQuery(
  baseOptions: Apollo.QueryHookOptions<
    FilterGenLaufOrderPaymentTransactionTypeListQuery,
    FilterGenLaufOrderPaymentTransactionTypeListQueryVariables
  > &
    ({ variables: FilterGenLaufOrderPaymentTransactionTypeListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufOrderPaymentTransactionTypeListQuery, FilterGenLaufOrderPaymentTransactionTypeListQueryVariables>(
    FilterGenLaufOrderPaymentTransactionTypeListDocument,
    options
  );
}
export function useFilterGenLaufOrderPaymentTransactionTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterGenLaufOrderPaymentTransactionTypeListQuery,
    FilterGenLaufOrderPaymentTransactionTypeListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufOrderPaymentTransactionTypeListQuery, FilterGenLaufOrderPaymentTransactionTypeListQueryVariables>(
    FilterGenLaufOrderPaymentTransactionTypeListDocument,
    options
  );
}
export function useFilterGenLaufOrderPaymentTransactionTypeListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufOrderPaymentTransactionTypeListQuery, FilterGenLaufOrderPaymentTransactionTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufOrderPaymentTransactionTypeListQuery, FilterGenLaufOrderPaymentTransactionTypeListQueryVariables>(
    FilterGenLaufOrderPaymentTransactionTypeListDocument,
    options
  );
}
export type FilterGenLaufOrderPaymentTransactionTypeListQueryHookResult = ReturnType<typeof useFilterGenLaufOrderPaymentTransactionTypeListQuery>;
export type FilterGenLaufOrderPaymentTransactionTypeListLazyQueryHookResult = ReturnType<
  typeof useFilterGenLaufOrderPaymentTransactionTypeListLazyQuery
>;
export type FilterGenLaufOrderPaymentTransactionTypeListSuspenseQueryHookResult = ReturnType<
  typeof useFilterGenLaufOrderPaymentTransactionTypeListSuspenseQuery
>;
export type FilterGenLaufOrderPaymentTransactionTypeListQueryResult = Apollo.QueryResult<
  FilterGenLaufOrderPaymentTransactionTypeListQuery,
  FilterGenLaufOrderPaymentTransactionTypeListQueryVariables
>;
export const FilterGenLaufOrderPaymentTypeListDocument = gql`
  query FilterGenLaufOrderPaymentTypeList($generierlaufId: ID!, $generierlaufType: OrderGenerierlaufType!) {
    getFilterGenLaufOrderPaymentTypeList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufOrderPaymentTypeListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufOrderPaymentTypeListQuery, FilterGenLaufOrderPaymentTypeListQueryVariables> &
    ({ variables: FilterGenLaufOrderPaymentTypeListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufOrderPaymentTypeListQuery, FilterGenLaufOrderPaymentTypeListQueryVariables>(
    FilterGenLaufOrderPaymentTypeListDocument,
    options
  );
}
export function useFilterGenLaufOrderPaymentTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufOrderPaymentTypeListQuery, FilterGenLaufOrderPaymentTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufOrderPaymentTypeListQuery, FilterGenLaufOrderPaymentTypeListQueryVariables>(
    FilterGenLaufOrderPaymentTypeListDocument,
    options
  );
}
export function useFilterGenLaufOrderPaymentTypeListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufOrderPaymentTypeListQuery, FilterGenLaufOrderPaymentTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufOrderPaymentTypeListQuery, FilterGenLaufOrderPaymentTypeListQueryVariables>(
    FilterGenLaufOrderPaymentTypeListDocument,
    options
  );
}
export type FilterGenLaufOrderPaymentTypeListQueryHookResult = ReturnType<typeof useFilterGenLaufOrderPaymentTypeListQuery>;
export type FilterGenLaufOrderPaymentTypeListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufOrderPaymentTypeListLazyQuery>;
export type FilterGenLaufOrderPaymentTypeListSuspenseQueryHookResult = ReturnType<typeof useFilterGenLaufOrderPaymentTypeListSuspenseQuery>;
export type FilterGenLaufOrderPaymentTypeListQueryResult = Apollo.QueryResult<
  FilterGenLaufOrderPaymentTypeListQuery,
  FilterGenLaufOrderPaymentTypeListQueryVariables
>;
export const FilterGenLaufOrderRecipientEmailAddressListDocument = gql`
  query FilterGenLaufOrderRecipientEmailAddressList($generierlaufId: ID!, $generierlaufType: OrderGenerierlaufType!) {
    getFilterGenLaufOrderRecipientEmailAddressList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufOrderRecipientEmailAddressListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufOrderRecipientEmailAddressListQuery, FilterGenLaufOrderRecipientEmailAddressListQueryVariables> &
    ({ variables: FilterGenLaufOrderRecipientEmailAddressListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufOrderRecipientEmailAddressListQuery, FilterGenLaufOrderRecipientEmailAddressListQueryVariables>(
    FilterGenLaufOrderRecipientEmailAddressListDocument,
    options
  );
}
export function useFilterGenLaufOrderRecipientEmailAddressListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterGenLaufOrderRecipientEmailAddressListQuery,
    FilterGenLaufOrderRecipientEmailAddressListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufOrderRecipientEmailAddressListQuery, FilterGenLaufOrderRecipientEmailAddressListQueryVariables>(
    FilterGenLaufOrderRecipientEmailAddressListDocument,
    options
  );
}
export function useFilterGenLaufOrderRecipientEmailAddressListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufOrderRecipientEmailAddressListQuery, FilterGenLaufOrderRecipientEmailAddressListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufOrderRecipientEmailAddressListQuery, FilterGenLaufOrderRecipientEmailAddressListQueryVariables>(
    FilterGenLaufOrderRecipientEmailAddressListDocument,
    options
  );
}
export type FilterGenLaufOrderRecipientEmailAddressListQueryHookResult = ReturnType<typeof useFilterGenLaufOrderRecipientEmailAddressListQuery>;
export type FilterGenLaufOrderRecipientEmailAddressListLazyQueryHookResult = ReturnType<
  typeof useFilterGenLaufOrderRecipientEmailAddressListLazyQuery
>;
export type FilterGenLaufOrderRecipientEmailAddressListSuspenseQueryHookResult = ReturnType<
  typeof useFilterGenLaufOrderRecipientEmailAddressListSuspenseQuery
>;
export type FilterGenLaufOrderRecipientEmailAddressListQueryResult = Apollo.QueryResult<
  FilterGenLaufOrderRecipientEmailAddressListQuery,
  FilterGenLaufOrderRecipientEmailAddressListQueryVariables
>;
export const FilterGenLaufOrderRecipientListDocument = gql`
  query FilterGenLaufOrderRecipientList($generierlaufId: ID!, $generierlaufType: OrderGenerierlaufType!) {
    getFilterGenLaufOrderRecipientList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        ...OrderRechtstraeger
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${OrderRechtstraegerFragmentDoc}
`;
export function useFilterGenLaufOrderRecipientListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufOrderRecipientListQuery, FilterGenLaufOrderRecipientListQueryVariables> &
    ({ variables: FilterGenLaufOrderRecipientListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufOrderRecipientListQuery, FilterGenLaufOrderRecipientListQueryVariables>(
    FilterGenLaufOrderRecipientListDocument,
    options
  );
}
export function useFilterGenLaufOrderRecipientListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufOrderRecipientListQuery, FilterGenLaufOrderRecipientListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufOrderRecipientListQuery, FilterGenLaufOrderRecipientListQueryVariables>(
    FilterGenLaufOrderRecipientListDocument,
    options
  );
}
export function useFilterGenLaufOrderRecipientListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufOrderRecipientListQuery, FilterGenLaufOrderRecipientListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufOrderRecipientListQuery, FilterGenLaufOrderRecipientListQueryVariables>(
    FilterGenLaufOrderRecipientListDocument,
    options
  );
}
export type FilterGenLaufOrderRecipientListQueryHookResult = ReturnType<typeof useFilterGenLaufOrderRecipientListQuery>;
export type FilterGenLaufOrderRecipientListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufOrderRecipientListLazyQuery>;
export type FilterGenLaufOrderRecipientListSuspenseQueryHookResult = ReturnType<typeof useFilterGenLaufOrderRecipientListSuspenseQuery>;
export type FilterGenLaufOrderRecipientListQueryResult = Apollo.QueryResult<
  FilterGenLaufOrderRecipientListQuery,
  FilterGenLaufOrderRecipientListQueryVariables
>;
export const FilterGenLaufOrderVertragspartnerListDocument = gql`
  query FilterGenLaufOrderVertragspartnerList($generierlaufId: ID!, $generierlaufType: OrderGenerierlaufType!) {
    getFilterGenLaufOrderVertragspartnerList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        ...OrderRechtstraeger
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${OrderRechtstraegerFragmentDoc}
`;
export function useFilterGenLaufOrderVertragspartnerListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufOrderVertragspartnerListQuery, FilterGenLaufOrderVertragspartnerListQueryVariables> &
    ({ variables: FilterGenLaufOrderVertragspartnerListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufOrderVertragspartnerListQuery, FilterGenLaufOrderVertragspartnerListQueryVariables>(
    FilterGenLaufOrderVertragspartnerListDocument,
    options
  );
}
export function useFilterGenLaufOrderVertragspartnerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufOrderVertragspartnerListQuery, FilterGenLaufOrderVertragspartnerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufOrderVertragspartnerListQuery, FilterGenLaufOrderVertragspartnerListQueryVariables>(
    FilterGenLaufOrderVertragspartnerListDocument,
    options
  );
}
export function useFilterGenLaufOrderVertragspartnerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufOrderVertragspartnerListQuery, FilterGenLaufOrderVertragspartnerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufOrderVertragspartnerListQuery, FilterGenLaufOrderVertragspartnerListQueryVariables>(
    FilterGenLaufOrderVertragspartnerListDocument,
    options
  );
}
export type FilterGenLaufOrderVertragspartnerListQueryHookResult = ReturnType<typeof useFilterGenLaufOrderVertragspartnerListQuery>;
export type FilterGenLaufOrderVertragspartnerListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufOrderVertragspartnerListLazyQuery>;
export type FilterGenLaufOrderVertragspartnerListSuspenseQueryHookResult = ReturnType<typeof useFilterGenLaufOrderVertragspartnerListSuspenseQuery>;
export type FilterGenLaufOrderVertragspartnerListQueryResult = Apollo.QueryResult<
  FilterGenLaufOrderVertragspartnerListQuery,
  FilterGenLaufOrderVertragspartnerListQueryVariables
>;
