import { FC } from 'react';
import { BookingSuggestionCreationGenerierlaufEntry } from '../../../../types';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import receivedBankTransactionTableColumns from './receivedBankTransactionTableColumns';
import { StyledTableReceivedBankTransaction } from '../../shared/styled/ReceivedBankTransactionTable.style';

type Props = {
  bookingSuggestionEntry: BookingSuggestionCreationGenerierlaufEntry;
};

const ReceivedBankTransactionTableColumn: FC<Props> = ({ bookingSuggestionEntry }) => {
  const { receivedBankTransactionZahlung, receivedBankTransactionSpesen, receivedBankTransactionRuecklastschrift } = bookingSuggestionEntry;

  const receivedBankTransactionList = [receivedBankTransactionZahlung, receivedBankTransactionSpesen, receivedBankTransactionRuecklastschrift].filter(
    (item) => !!item
  );

  if (receivedBankTransactionList.length === 0) {
    return <TextForEmptyValue textToShow="minus" />;
  }

  return (
    <StyledTableReceivedBankTransaction
      dataSource={receivedBankTransactionList}
      columns={receivedBankTransactionTableColumns(bookingSuggestionEntry)}
      pagination={false}
      expandable={{ rowExpandable: () => false }}
    />
  );
};

export default ReceivedBankTransactionTableColumn;
