import { FieldArrayRenderProps, FormikProps } from 'formik';
import { BookingSuggestionFormValues, bSBuchungszeileFormFields, createNewBSBuchungszeileFormValues } from '../../Form/buchungsmaskeFormMapper';
import { BookingSuggestionBuchungszeile } from '../../../../../types';

export const handleAddBuchungszeile = (
  arrayHelpers: FieldArrayRenderProps,
  arrayFieldName: string,
  buchungszeileOriginal: BookingSuggestionBuchungszeile,
  formikProps: FormikProps<BookingSuggestionFormValues>
) => {
  arrayHelpers.push(createNewBSBuchungszeileFormValues(buchungszeileOriginal, formikProps.values.buchungsanweisung.buchungszeileList.length === 1));
  const index = formikProps.values.buchungsanweisung.buchungszeileList.length;
  formikProps.setFieldTouched(`${arrayFieldName}[${index}].${bSBuchungszeileFormFields.kontoId}`, true);
  formikProps.setFieldTouched(`${arrayFieldName}[${index}].${bSBuchungszeileFormFields.text}`, true);
};
