import { Text } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { AbrechnungskreisDetailsAbrechnungskreis } from '../../../../topAbrechnung-types';

const TAAbrKreisTitle: FC<{ abrechKreis: AbrechnungskreisDetailsAbrechnungskreis }> = ({ abrechKreis }) => (
  <Text
    style={{
      fontSize: '11px',
      fontWeight: 'bold',
      marginBottom: '2mm',
    }}
  >
    {abrechKreis.abrechnungskreisTitelText}
  </Text>
);

export default TAAbrKreisTitle;
