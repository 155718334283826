import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AufteilungsschluesselFieldsFragmentDoc } from '../../Aufteilungsschluessel/gql/AufteilungsschluesselFragments.types';
import { AppKontoFieldsFragmentDoc } from '../../Kontenplan/gql/KontenplanFragments.types';
export type RepFondsEinstellungFieldsFragment = {
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  current: boolean;
  deletable?: boolean | null;
  kommentar?: string | null;
  lastUpdateTs: string;
  repFondsEinstellungId: string;
  updatedByMitarbeiterId?: string | null;
  validFrom: string;
  versionIndex: number;
  kontenZuweisungList: Array<{
    repFondsEinstellungKontenZuweisungId: string;
    aufteilungsschluessel: {
      aufteilungsschluesselId: string;
      bezeichnung: string;
      bezugsbasisEditable?: boolean | null;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kurzBezeichnung: string;
      updatedByMitarbeiterId?: string | null;
      bezugsbasis: { text: string; value: Types.Bezugsbasis };
      masseinheit: { text: string; value: Types.Masseinheit };
      status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    aufwandsKontoList: Array<{ bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string }>;
    repFondsKonto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
  }>;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const RepFondsEinstellungFieldsFragmentDoc = gql`
  fragment RepFondsEinstellungFields on RepFondsEinstellung {
    createTs
    createdBy
    createdByMitarbeiterId
    current
    deletable
    kommentar
    kontenZuweisungList {
      aufteilungsschluessel {
        ...AufteilungsschluesselFields
      }
      aufwandsKontoList {
        ...AppKontoFields
      }
      repFondsEinstellungKontenZuweisungId
      repFondsKonto {
        ...AppKontoFields
      }
    }
    lastUpdateTs
    repFondsEinstellungId
    updatedByMitarbeiterId
    validFrom
    versionIndex
    warningList {
      attribute
      message
      type
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
  ${AppKontoFieldsFragmentDoc}
`;
