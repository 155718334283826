import { VorschreibungspositionArt } from '../types';

export const CREATE_NEW_ITEM_VALUE = 'create-new-select-item-value';

/* filter case insensitive in selects with normal options and with option groups */
export const selectFilterOption = (input: string, option: any) => {
  const isGroup = Array.isArray(option.options);
  if (isGroup) {
    return false;
  }
  const optionTextValue = getOptionTextContent(option);
  if (option.value === CREATE_NEW_ITEM_VALUE) {
    return true;
  }
  if (optionTextValue) {
    const textValueWithoutSpaces = optionTextValue.replace(/\s/g, '');
    const inputWithoutSpaces = input.replace(/\s/g, '');
    return textValueWithoutSpaces.toLowerCase().includes(inputWithoutSpaces.toLowerCase());
  }
  return false;
};

const getOptionTextContent = (option: any): string | null => {
  if (!option) {
    return null;
  } else if (typeof option === 'string') {
    return option;
  } else if (typeof option.children === 'string') {
    return option.children;
  } else if (typeof option.text === 'string') {
    return option.text;
  } else if (typeof option.textContent === 'string' && typeof option.tagContent === 'string') {
    // HANDLE TextWithTag component
    return option.textContent + ' ' + option.tagContent;
  } else if (Array.isArray(option.children)) {
    const allTextInOption = option.children.reduce((acc: string, item: any) => {
      const textValue = getOptionTextContent(item);
      return textValue ? acc.concat(textValue) : acc;
    }, '');
    return allTextInOption ?? null;
  } else if (Array.isArray(option.options)) {
    const allTextInOption = option.options.reduce((acc: string, item: any) => {
      const textValue = getOptionTextContent(item);
      return textValue ? acc.concat(textValue) : acc;
    }, '');
    return allTextInOption ?? null;
  } else if (option.children) {
    return getOptionTextContent(option.children);
  } else if (option.props) {
    return getOptionTextContent(option.props);
  } else if (typeof option.title === 'string') {
    return option.title;
  } else {
    return null;
  }
};

// useful in selects where elements are depending on firmendaten-context ---
export const belongsToKundenSystem = (entity: { firmendatenId?: string | null }) => entity.firmendatenId === null;
export const belongsToSelectedFirmendaten = (entity: { firmendatenId?: string | null }, selectedFirmendatenId?: string | null) =>
  entity.firmendatenId === selectedFirmendatenId;
// useful in selects where elements are depending on firmendaten-context ---

export const excludeArts = (entity: { art: { value: string } }, excludeArtList: VorschreibungspositionArt[]) => {
  return !excludeArtList.find((art) => art.includes(entity.art.value));
};
