import React, { FC, JSX } from 'react';
import { Button, Typography } from 'antd';
import { StyledModal } from '../styled/Modal.style';
import ModalCreateInfo from '../../Modal/ModalCreateInfo';
import { PostIt } from '../../../types';

type PostItViewModeProps = {
  postIt: PostIt;
  isOpen: boolean;
  closeModal: () => void;
  modalIcons: JSX.Element;
};

/**
 * <h2>Usage</h2>
 * For viewing a Post-It for different components like Objekt, Bestandseinheit etc.
 *
 * */
const PostItViewMode: FC<PostItViewModeProps> = ({ postIt, closeModal, modalIcons, isOpen }) => (
  <StyledModal
    title={postIt.titel}
    open={isOpen}
    footer={[
      <ModalCreateInfo createdTs={postIt.createTs} mitarbeiterId={postIt.createdByMitarbeiterId} />,
      <Button type="primary" onClick={closeModal}>
        Schließen
      </Button>,
    ]}
    closeIcon={modalIcons}
    width="400px"
  >
    <Typography.Text>
      <div style={{ whiteSpace: 'pre' }}>{postIt.text}</div>
    </Typography.Text>
  </StyledModal>
);

export default PostItViewMode;
