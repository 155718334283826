import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type DeviationEntryFieldsFragment = {
  attributeList: Array<
    | {
        __typename: 'DeviationAttributeAmount';
        hasChanged: boolean;
        attributeId: number;
        type: Types.DeviationAttributeType;
        valueFloat?: number | null;
        name: { text: string; value: Types.DeviationAttributeNameType };
      }
    | {
        __typename: 'DeviationAttributeDate';
        hasChanged: boolean;
        attributeId: number;
        type: Types.DeviationAttributeType;
        valueDate?: string | null;
        name: { text: string; value: Types.DeviationAttributeNameType };
      }
    | {
        __typename: 'DeviationAttributeNumber';
        hasChanged: boolean;
        attributeId: number;
        type: Types.DeviationAttributeType;
        valueFloat?: number | null;
        name: { text: string; value: Types.DeviationAttributeNameType };
      }
    | {
        __typename: 'DeviationAttributePercent';
        hasChanged: boolean;
        attributeId: number;
        type: Types.DeviationAttributeType;
        valuePercent?: number | null;
        name: { text: string; value: Types.DeviationAttributeNameType };
      }
    | {
        __typename: 'DeviationAttributeString';
        hasChanged: boolean;
        attributeId: number;
        type: Types.DeviationAttributeType;
        valueString?: string | null;
        name: { text: string; value: Types.DeviationAttributeNameType };
      }
    | {
        __typename: 'DeviationAttributeUriList';
        hasChanged: boolean;
        attributeId: number;
        type: Types.DeviationAttributeType;
        uriList: Array<{
          text: string;
          ids?:
            | { __typename: 'DeviationUriIdAuftrag'; auftragId: string; uriType: Types.DeviationUriIdType }
            | { __typename: 'DeviationUriIdBeNutzungsArt'; bestandseinheitId: string; objektId: string; uriType: Types.DeviationUriIdType }
            | {
                __typename: 'DeviationUriIdBeOptionSteuerpflicht';
                objektId: string;
                beOptionSteuerpflichtBestandseinheitId?: string | null;
                uriType: Types.DeviationUriIdType;
              }
            | {
                __typename: 'DeviationUriIdBeVertrag';
                bestandseinheitId: string;
                objektId: string;
                vertragId: string;
                uriType: Types.DeviationUriIdType;
              }
            | {
                __typename: 'DeviationUriIdBeVertragVorschreibungsposition';
                bestandseinheitId: string;
                vertragId: string;
                objektId: string;
                vorschreibungspositionId: string;
                uriType: Types.DeviationUriIdType;
              }
            | { __typename: 'DeviationUriIdBestandseinheit'; objektId: string; bestandseinheitId: string; uriType: Types.DeviationUriIdType }
            | {
                __typename: 'DeviationUriIdBkAbrechnungsdefinitionVersion';
                bkAbrechnungsdefinitionId: string;
                bkAbrechnungsdefinitionVersionId: string;
                uriType: Types.DeviationUriIdType;
              }
            | { __typename: 'DeviationUriIdEingangsrechnung'; belegFileId: string; belegId: string; uriType: Types.DeviationUriIdType }
            | {
                __typename: 'DeviationUriIdHeAbrechnungHeVertragVersion';
                heAbrechnungId: string;
                heAbrechnungHeVertragId: string;
                objektId: string;
                uriType: Types.DeviationUriIdType;
              }
            | {
                __typename: 'DeviationUriIdHeAbrechnungsdefinitionVersion';
                heAbrechnungsdefinitionId: string;
                heAbrechnungsdefinitionVersionId: string;
                uriType: Types.DeviationUriIdType;
              }
            | { __typename: 'DeviationUriIdHeVertrag'; objektId: string; heVertragId: string; uriType: Types.DeviationUriIdType }
            | { __typename: 'DeviationUriIdKontierungstabelle'; kontierungstabelleId: string; uriType: Types.DeviationUriIdType }
            | {
                __typename: 'DeviationUriIdLeerstehungAufwandskonto';
                firmendatenId: string;
                firmendatenLeerstehungAufwandskontoId: string;
                uriType: Types.DeviationUriIdType;
              }
            | {
                __typename: 'DeviationUriIdPauschalmieteAufwandskonto';
                firmendatenId: string;
                firmendatenPauschalmieteAufwandskontoId: string;
                uriType: Types.DeviationUriIdType;
              }
            | {
                __typename: 'DeviationUriIdRechtstraegerSteuerpflicht';
                rechtstraegerId: string;
                uriType: Types.DeviationUriIdType;
                rechtstraegerType: { text: string; value: Types.RechtstraegerType };
              }
            | { __typename: 'DeviationUriIdRepFondsEinstellung'; repFondsEinstellungId: string; uriType: Types.DeviationUriIdType }
            | {
                __typename: 'DeviationUriIdTopAbrechnung';
                objektAbrechnungId: string;
                objektId: string;
                topAbrechnungId: string;
                uriType: Types.DeviationUriIdType;
              }
            | {
                __typename: 'DeviationUriIdUstKategorie';
                ustKategorieId: string;
                ustKategorieDeviationUstKategorieEntryId?: string | null;
                uriType: Types.DeviationUriIdType;
                ustKategorieStatus: { text: string; value: Types.UstKategorieStatus };
              }
            | {
                __typename: 'DeviationUriIdUstKategorieEntry';
                ustKategorieId: string;
                ustKategorieVersionId: string;
                ustKategorieEntryDeviationUstKategorieEntryId: string;
                uriType: Types.DeviationUriIdType;
              }
            | { __typename: 'DeviationUriIdUstRegelset'; ustRegelsetId: string; uriType: Types.DeviationUriIdType }
            | { __typename: 'DeviationUriIdUstVomAufwand'; objektId: string; ustVomAufwandId: string; uriType: Types.DeviationUriIdType }
            | {
                __typename: 'DeviationUriIdVertragsart';
                objektId: string;
                bestandseinheitId: string;
                vertragId: string;
                uriType: Types.DeviationUriIdType;
              }
            | {
                __typename: 'DeviationUriIdVertragspartner';
                objektId: string;
                bestandseinheitId: string;
                vertragId: string;
                uriType: Types.DeviationUriIdType;
              }
            | {
                __typename: 'DeviationUriIdVorschreibungsposition';
                vorschreibungspositionId: string;
                uriType: Types.DeviationUriIdType;
                vorschreibungspositionStatus: { description?: string | null; text: string; value: Types.VorschreibungspositionStatus };
              }
            | {
                __typename: 'DeviationUriIdVorschreibungspositionVersion';
                vorschreibungspositionId: string;
                vorschreibungspositionVersionId: string;
                uriType: Types.DeviationUriIdType;
              }
            | { __typename: 'DeviationUriIdVorsteuerkuerzung'; objektId: string; vorsteuerkuerzungId: string; uriType: Types.DeviationUriIdType }
            | { __typename: 'DeviationUriIdVorsteuerkuerzungEinstellung'; vorsteuerkuerzungEinstellungId: string; uriType: Types.DeviationUriIdType }
            | {
                __typename: 'DeviationUriIdWeAbrechnungsdefinitionVersion';
                weAbrechnungsdefinitionId: string;
                weAbrechnungsdefinitionVersionId: string;
                uriType: Types.DeviationUriIdType;
              }
            | { __typename: 'DeviationUriIdZinsliste'; objektId: string; zinslisteId: string; uriType: Types.DeviationUriIdType }
            | null;
        }>;
        name: { text: string; value: Types.DeviationAttributeNameType };
      }
  >;
  rowType: { text: string; value: Types.DeviationEntryType };
};

export type DeviationEntryAttributeFields_DeviationAttributeAmount_Fragment = {
  __typename: 'DeviationAttributeAmount';
  hasChanged: boolean;
  attributeId: number;
  type: Types.DeviationAttributeType;
  valueFloat?: number | null;
  name: { text: string; value: Types.DeviationAttributeNameType };
};

export type DeviationEntryAttributeFields_DeviationAttributeDate_Fragment = {
  __typename: 'DeviationAttributeDate';
  hasChanged: boolean;
  attributeId: number;
  type: Types.DeviationAttributeType;
  valueDate?: string | null;
  name: { text: string; value: Types.DeviationAttributeNameType };
};

export type DeviationEntryAttributeFields_DeviationAttributeNumber_Fragment = {
  __typename: 'DeviationAttributeNumber';
  hasChanged: boolean;
  attributeId: number;
  type: Types.DeviationAttributeType;
  valueFloat?: number | null;
  name: { text: string; value: Types.DeviationAttributeNameType };
};

export type DeviationEntryAttributeFields_DeviationAttributePercent_Fragment = {
  __typename: 'DeviationAttributePercent';
  hasChanged: boolean;
  attributeId: number;
  type: Types.DeviationAttributeType;
  valuePercent?: number | null;
  name: { text: string; value: Types.DeviationAttributeNameType };
};

export type DeviationEntryAttributeFields_DeviationAttributeString_Fragment = {
  __typename: 'DeviationAttributeString';
  hasChanged: boolean;
  attributeId: number;
  type: Types.DeviationAttributeType;
  valueString?: string | null;
  name: { text: string; value: Types.DeviationAttributeNameType };
};

export type DeviationEntryAttributeFields_DeviationAttributeUriList_Fragment = {
  __typename: 'DeviationAttributeUriList';
  hasChanged: boolean;
  attributeId: number;
  type: Types.DeviationAttributeType;
  uriList: Array<{
    text: string;
    ids?:
      | { __typename: 'DeviationUriIdAuftrag'; auftragId: string; uriType: Types.DeviationUriIdType }
      | { __typename: 'DeviationUriIdBeNutzungsArt'; bestandseinheitId: string; objektId: string; uriType: Types.DeviationUriIdType }
      | {
          __typename: 'DeviationUriIdBeOptionSteuerpflicht';
          objektId: string;
          beOptionSteuerpflichtBestandseinheitId?: string | null;
          uriType: Types.DeviationUriIdType;
        }
      | { __typename: 'DeviationUriIdBeVertrag'; bestandseinheitId: string; objektId: string; vertragId: string; uriType: Types.DeviationUriIdType }
      | {
          __typename: 'DeviationUriIdBeVertragVorschreibungsposition';
          bestandseinheitId: string;
          vertragId: string;
          objektId: string;
          vorschreibungspositionId: string;
          uriType: Types.DeviationUriIdType;
        }
      | { __typename: 'DeviationUriIdBestandseinheit'; objektId: string; bestandseinheitId: string; uriType: Types.DeviationUriIdType }
      | {
          __typename: 'DeviationUriIdBkAbrechnungsdefinitionVersion';
          bkAbrechnungsdefinitionId: string;
          bkAbrechnungsdefinitionVersionId: string;
          uriType: Types.DeviationUriIdType;
        }
      | { __typename: 'DeviationUriIdEingangsrechnung'; belegFileId: string; belegId: string; uriType: Types.DeviationUriIdType }
      | {
          __typename: 'DeviationUriIdHeAbrechnungHeVertragVersion';
          heAbrechnungId: string;
          heAbrechnungHeVertragId: string;
          objektId: string;
          uriType: Types.DeviationUriIdType;
        }
      | {
          __typename: 'DeviationUriIdHeAbrechnungsdefinitionVersion';
          heAbrechnungsdefinitionId: string;
          heAbrechnungsdefinitionVersionId: string;
          uriType: Types.DeviationUriIdType;
        }
      | { __typename: 'DeviationUriIdHeVertrag'; objektId: string; heVertragId: string; uriType: Types.DeviationUriIdType }
      | { __typename: 'DeviationUriIdKontierungstabelle'; kontierungstabelleId: string; uriType: Types.DeviationUriIdType }
      | {
          __typename: 'DeviationUriIdLeerstehungAufwandskonto';
          firmendatenId: string;
          firmendatenLeerstehungAufwandskontoId: string;
          uriType: Types.DeviationUriIdType;
        }
      | {
          __typename: 'DeviationUriIdPauschalmieteAufwandskonto';
          firmendatenId: string;
          firmendatenPauschalmieteAufwandskontoId: string;
          uriType: Types.DeviationUriIdType;
        }
      | {
          __typename: 'DeviationUriIdRechtstraegerSteuerpflicht';
          rechtstraegerId: string;
          uriType: Types.DeviationUriIdType;
          rechtstraegerType: { text: string; value: Types.RechtstraegerType };
        }
      | { __typename: 'DeviationUriIdRepFondsEinstellung'; repFondsEinstellungId: string; uriType: Types.DeviationUriIdType }
      | {
          __typename: 'DeviationUriIdTopAbrechnung';
          objektAbrechnungId: string;
          objektId: string;
          topAbrechnungId: string;
          uriType: Types.DeviationUriIdType;
        }
      | {
          __typename: 'DeviationUriIdUstKategorie';
          ustKategorieId: string;
          ustKategorieDeviationUstKategorieEntryId?: string | null;
          uriType: Types.DeviationUriIdType;
          ustKategorieStatus: { text: string; value: Types.UstKategorieStatus };
        }
      | {
          __typename: 'DeviationUriIdUstKategorieEntry';
          ustKategorieId: string;
          ustKategorieVersionId: string;
          ustKategorieEntryDeviationUstKategorieEntryId: string;
          uriType: Types.DeviationUriIdType;
        }
      | { __typename: 'DeviationUriIdUstRegelset'; ustRegelsetId: string; uriType: Types.DeviationUriIdType }
      | { __typename: 'DeviationUriIdUstVomAufwand'; objektId: string; ustVomAufwandId: string; uriType: Types.DeviationUriIdType }
      | { __typename: 'DeviationUriIdVertragsart'; objektId: string; bestandseinheitId: string; vertragId: string; uriType: Types.DeviationUriIdType }
      | {
          __typename: 'DeviationUriIdVertragspartner';
          objektId: string;
          bestandseinheitId: string;
          vertragId: string;
          uriType: Types.DeviationUriIdType;
        }
      | {
          __typename: 'DeviationUriIdVorschreibungsposition';
          vorschreibungspositionId: string;
          uriType: Types.DeviationUriIdType;
          vorschreibungspositionStatus: { description?: string | null; text: string; value: Types.VorschreibungspositionStatus };
        }
      | {
          __typename: 'DeviationUriIdVorschreibungspositionVersion';
          vorschreibungspositionId: string;
          vorschreibungspositionVersionId: string;
          uriType: Types.DeviationUriIdType;
        }
      | { __typename: 'DeviationUriIdVorsteuerkuerzung'; objektId: string; vorsteuerkuerzungId: string; uriType: Types.DeviationUriIdType }
      | { __typename: 'DeviationUriIdVorsteuerkuerzungEinstellung'; vorsteuerkuerzungEinstellungId: string; uriType: Types.DeviationUriIdType }
      | {
          __typename: 'DeviationUriIdWeAbrechnungsdefinitionVersion';
          weAbrechnungsdefinitionId: string;
          weAbrechnungsdefinitionVersionId: string;
          uriType: Types.DeviationUriIdType;
        }
      | { __typename: 'DeviationUriIdZinsliste'; objektId: string; zinslisteId: string; uriType: Types.DeviationUriIdType }
      | null;
  }>;
  name: { text: string; value: Types.DeviationAttributeNameType };
};

export type DeviationEntryAttributeFieldsFragment =
  | DeviationEntryAttributeFields_DeviationAttributeAmount_Fragment
  | DeviationEntryAttributeFields_DeviationAttributeDate_Fragment
  | DeviationEntryAttributeFields_DeviationAttributeNumber_Fragment
  | DeviationEntryAttributeFields_DeviationAttributePercent_Fragment
  | DeviationEntryAttributeFields_DeviationAttributeString_Fragment
  | DeviationEntryAttributeFields_DeviationAttributeUriList_Fragment;

export type DeviationAttributeUriListFieldsFragment = {
  text: string;
  ids?:
    | { __typename: 'DeviationUriIdAuftrag'; auftragId: string; uriType: Types.DeviationUriIdType }
    | { __typename: 'DeviationUriIdBeNutzungsArt'; bestandseinheitId: string; objektId: string; uriType: Types.DeviationUriIdType }
    | {
        __typename: 'DeviationUriIdBeOptionSteuerpflicht';
        objektId: string;
        beOptionSteuerpflichtBestandseinheitId?: string | null;
        uriType: Types.DeviationUriIdType;
      }
    | { __typename: 'DeviationUriIdBeVertrag'; bestandseinheitId: string; objektId: string; vertragId: string; uriType: Types.DeviationUriIdType }
    | {
        __typename: 'DeviationUriIdBeVertragVorschreibungsposition';
        bestandseinheitId: string;
        vertragId: string;
        objektId: string;
        vorschreibungspositionId: string;
        uriType: Types.DeviationUriIdType;
      }
    | { __typename: 'DeviationUriIdBestandseinheit'; objektId: string; bestandseinheitId: string; uriType: Types.DeviationUriIdType }
    | {
        __typename: 'DeviationUriIdBkAbrechnungsdefinitionVersion';
        bkAbrechnungsdefinitionId: string;
        bkAbrechnungsdefinitionVersionId: string;
        uriType: Types.DeviationUriIdType;
      }
    | { __typename: 'DeviationUriIdEingangsrechnung'; belegFileId: string; belegId: string; uriType: Types.DeviationUriIdType }
    | {
        __typename: 'DeviationUriIdHeAbrechnungHeVertragVersion';
        heAbrechnungId: string;
        heAbrechnungHeVertragId: string;
        objektId: string;
        uriType: Types.DeviationUriIdType;
      }
    | {
        __typename: 'DeviationUriIdHeAbrechnungsdefinitionVersion';
        heAbrechnungsdefinitionId: string;
        heAbrechnungsdefinitionVersionId: string;
        uriType: Types.DeviationUriIdType;
      }
    | { __typename: 'DeviationUriIdHeVertrag'; objektId: string; heVertragId: string; uriType: Types.DeviationUriIdType }
    | { __typename: 'DeviationUriIdKontierungstabelle'; kontierungstabelleId: string; uriType: Types.DeviationUriIdType }
    | {
        __typename: 'DeviationUriIdLeerstehungAufwandskonto';
        firmendatenId: string;
        firmendatenLeerstehungAufwandskontoId: string;
        uriType: Types.DeviationUriIdType;
      }
    | {
        __typename: 'DeviationUriIdPauschalmieteAufwandskonto';
        firmendatenId: string;
        firmendatenPauschalmieteAufwandskontoId: string;
        uriType: Types.DeviationUriIdType;
      }
    | {
        __typename: 'DeviationUriIdRechtstraegerSteuerpflicht';
        rechtstraegerId: string;
        uriType: Types.DeviationUriIdType;
        rechtstraegerType: { text: string; value: Types.RechtstraegerType };
      }
    | { __typename: 'DeviationUriIdRepFondsEinstellung'; repFondsEinstellungId: string; uriType: Types.DeviationUriIdType }
    | {
        __typename: 'DeviationUriIdTopAbrechnung';
        objektAbrechnungId: string;
        objektId: string;
        topAbrechnungId: string;
        uriType: Types.DeviationUriIdType;
      }
    | {
        __typename: 'DeviationUriIdUstKategorie';
        ustKategorieId: string;
        ustKategorieDeviationUstKategorieEntryId?: string | null;
        uriType: Types.DeviationUriIdType;
        ustKategorieStatus: { text: string; value: Types.UstKategorieStatus };
      }
    | {
        __typename: 'DeviationUriIdUstKategorieEntry';
        ustKategorieId: string;
        ustKategorieVersionId: string;
        ustKategorieEntryDeviationUstKategorieEntryId: string;
        uriType: Types.DeviationUriIdType;
      }
    | { __typename: 'DeviationUriIdUstRegelset'; ustRegelsetId: string; uriType: Types.DeviationUriIdType }
    | { __typename: 'DeviationUriIdUstVomAufwand'; objektId: string; ustVomAufwandId: string; uriType: Types.DeviationUriIdType }
    | { __typename: 'DeviationUriIdVertragsart'; objektId: string; bestandseinheitId: string; vertragId: string; uriType: Types.DeviationUriIdType }
    | {
        __typename: 'DeviationUriIdVertragspartner';
        objektId: string;
        bestandseinheitId: string;
        vertragId: string;
        uriType: Types.DeviationUriIdType;
      }
    | {
        __typename: 'DeviationUriIdVorschreibungsposition';
        vorschreibungspositionId: string;
        uriType: Types.DeviationUriIdType;
        vorschreibungspositionStatus: { description?: string | null; text: string; value: Types.VorschreibungspositionStatus };
      }
    | {
        __typename: 'DeviationUriIdVorschreibungspositionVersion';
        vorschreibungspositionId: string;
        vorschreibungspositionVersionId: string;
        uriType: Types.DeviationUriIdType;
      }
    | { __typename: 'DeviationUriIdVorsteuerkuerzung'; objektId: string; vorsteuerkuerzungId: string; uriType: Types.DeviationUriIdType }
    | { __typename: 'DeviationUriIdVorsteuerkuerzungEinstellung'; vorsteuerkuerzungEinstellungId: string; uriType: Types.DeviationUriIdType }
    | {
        __typename: 'DeviationUriIdWeAbrechnungsdefinitionVersion';
        weAbrechnungsdefinitionId: string;
        weAbrechnungsdefinitionVersionId: string;
        uriType: Types.DeviationUriIdType;
      }
    | { __typename: 'DeviationUriIdZinsliste'; objektId: string; zinslisteId: string; uriType: Types.DeviationUriIdType }
    | null;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdAuftrag_Fragment = {
  __typename: 'DeviationUriIdAuftrag';
  auftragId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdBeNutzungsArt_Fragment = {
  __typename: 'DeviationUriIdBeNutzungsArt';
  bestandseinheitId: string;
  objektId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdBeOptionSteuerpflicht_Fragment = {
  __typename: 'DeviationUriIdBeOptionSteuerpflicht';
  objektId: string;
  beOptionSteuerpflichtBestandseinheitId?: string | null;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdBeVertrag_Fragment = {
  __typename: 'DeviationUriIdBeVertrag';
  bestandseinheitId: string;
  objektId: string;
  vertragId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdBeVertragVorschreibungsposition_Fragment = {
  __typename: 'DeviationUriIdBeVertragVorschreibungsposition';
  bestandseinheitId: string;
  vertragId: string;
  objektId: string;
  vorschreibungspositionId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdBestandseinheit_Fragment = {
  __typename: 'DeviationUriIdBestandseinheit';
  objektId: string;
  bestandseinheitId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdBkAbrechnungsdefinitionVersion_Fragment = {
  __typename: 'DeviationUriIdBkAbrechnungsdefinitionVersion';
  bkAbrechnungsdefinitionId: string;
  bkAbrechnungsdefinitionVersionId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdEingangsrechnung_Fragment = {
  __typename: 'DeviationUriIdEingangsrechnung';
  belegFileId: string;
  belegId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdHeAbrechnungHeVertragVersion_Fragment = {
  __typename: 'DeviationUriIdHeAbrechnungHeVertragVersion';
  heAbrechnungId: string;
  heAbrechnungHeVertragId: string;
  objektId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdHeAbrechnungsdefinitionVersion_Fragment = {
  __typename: 'DeviationUriIdHeAbrechnungsdefinitionVersion';
  heAbrechnungsdefinitionId: string;
  heAbrechnungsdefinitionVersionId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdHeVertrag_Fragment = {
  __typename: 'DeviationUriIdHeVertrag';
  objektId: string;
  heVertragId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdKontierungstabelle_Fragment = {
  __typename: 'DeviationUriIdKontierungstabelle';
  kontierungstabelleId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdLeerstehungAufwandskonto_Fragment = {
  __typename: 'DeviationUriIdLeerstehungAufwandskonto';
  firmendatenId: string;
  firmendatenLeerstehungAufwandskontoId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdPauschalmieteAufwandskonto_Fragment = {
  __typename: 'DeviationUriIdPauschalmieteAufwandskonto';
  firmendatenId: string;
  firmendatenPauschalmieteAufwandskontoId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdRechtstraegerSteuerpflicht_Fragment = {
  __typename: 'DeviationUriIdRechtstraegerSteuerpflicht';
  rechtstraegerId: string;
  uriType: Types.DeviationUriIdType;
  rechtstraegerType: { text: string; value: Types.RechtstraegerType };
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdRepFondsEinstellung_Fragment = {
  __typename: 'DeviationUriIdRepFondsEinstellung';
  repFondsEinstellungId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdTopAbrechnung_Fragment = {
  __typename: 'DeviationUriIdTopAbrechnung';
  objektAbrechnungId: string;
  objektId: string;
  topAbrechnungId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdUstKategorie_Fragment = {
  __typename: 'DeviationUriIdUstKategorie';
  ustKategorieId: string;
  ustKategorieDeviationUstKategorieEntryId?: string | null;
  uriType: Types.DeviationUriIdType;
  ustKategorieStatus: { text: string; value: Types.UstKategorieStatus };
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdUstKategorieEntry_Fragment = {
  __typename: 'DeviationUriIdUstKategorieEntry';
  ustKategorieId: string;
  ustKategorieVersionId: string;
  ustKategorieEntryDeviationUstKategorieEntryId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdUstRegelset_Fragment = {
  __typename: 'DeviationUriIdUstRegelset';
  ustRegelsetId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdUstVomAufwand_Fragment = {
  __typename: 'DeviationUriIdUstVomAufwand';
  objektId: string;
  ustVomAufwandId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVertragsart_Fragment = {
  __typename: 'DeviationUriIdVertragsart';
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVertragspartner_Fragment = {
  __typename: 'DeviationUriIdVertragspartner';
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVorschreibungsposition_Fragment = {
  __typename: 'DeviationUriIdVorschreibungsposition';
  vorschreibungspositionId: string;
  uriType: Types.DeviationUriIdType;
  vorschreibungspositionStatus: { description?: string | null; text: string; value: Types.VorschreibungspositionStatus };
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVorschreibungspositionVersion_Fragment = {
  __typename: 'DeviationUriIdVorschreibungspositionVersion';
  vorschreibungspositionId: string;
  vorschreibungspositionVersionId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVorsteuerkuerzung_Fragment = {
  __typename: 'DeviationUriIdVorsteuerkuerzung';
  objektId: string;
  vorsteuerkuerzungId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVorsteuerkuerzungEinstellung_Fragment = {
  __typename: 'DeviationUriIdVorsteuerkuerzungEinstellung';
  vorsteuerkuerzungEinstellungId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdWeAbrechnungsdefinitionVersion_Fragment = {
  __typename: 'DeviationUriIdWeAbrechnungsdefinitionVersion';
  weAbrechnungsdefinitionId: string;
  weAbrechnungsdefinitionVersionId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdZinsliste_Fragment = {
  __typename: 'DeviationUriIdZinsliste';
  objektId: string;
  zinslisteId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationEntryAttributeDeviationAttributeUriListIdsFieldsFragment =
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdAuftrag_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdBeNutzungsArt_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdBeOptionSteuerpflicht_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdBeVertrag_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdBeVertragVorschreibungsposition_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdBestandseinheit_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdBkAbrechnungsdefinitionVersion_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdEingangsrechnung_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdHeAbrechnungHeVertragVersion_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdHeAbrechnungsdefinitionVersion_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdHeVertrag_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdKontierungstabelle_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdLeerstehungAufwandskonto_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdPauschalmieteAufwandskonto_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdRechtstraegerSteuerpflicht_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdRepFondsEinstellung_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdTopAbrechnung_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdUstKategorie_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdUstKategorieEntry_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdUstRegelset_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdUstVomAufwand_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVertragsart_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVertragspartner_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVorschreibungsposition_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVorschreibungspositionVersion_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVorsteuerkuerzung_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdVorsteuerkuerzungEinstellung_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdWeAbrechnungsdefinitionVersion_Fragment
  | DeviationEntryAttributeDeviationAttributeUriListIdsFields_DeviationUriIdZinsliste_Fragment;

export type BuchungsanweisungDeviationSourceEntityFragment = {
  belegnummer: string;
  gesamtBetrag: number;
  auftragsartType?: { text: string; value: Types.AuftragsartType } | null;
  gegenKonto?: { nummer: string; klasse: { text: string; value: Types.KontoKlasse } } | null;
};

export type ObjektAbrechnungDeviationSourceEntityFragment = {
  abrechnungBezeichnung?: string | null;
  fromInclusive: string;
  objektBezeichnung: string;
  toInclusive: string;
  abrechnungType: { description?: string | null; text: string; value: Types.AbrechnungType };
  ids?: { linkText: string; objektAbrechnungId: string; objektId: string } | null;
};

export type HeAbrechnungHeVertragDeviationSourceEntityFragment = {
  objektBezeichnung: string;
  vertragspartnerKurzBezeichnung: string;
  vertragsEnde?: string | null;
  vertragsBeginn: string;
  ids?: { heVertragId: string; linkText: string; objektId: string } | null;
};

export type TopAbrechnungDeviationSourceEntityFragment = {
  fromInclusive: string;
  bestandseinheitBezeichnung: string;
  objektBezeichnung: string;
  toInclusive: string;
  abrechnungType: { description?: string | null; text: string; value: Types.AbrechnungType };
  ids?: { objektId: string; linkText: string; objektAbrechnungId: string; topAbrechnungId: string } | null;
};

export type UstVomAufwandDeviationSourceEntityFragment = {
  bestandseinheitBezeichnung: string;
  objektBezeichnung: string;
  rechnungsAusstellerKurzBezeichnung: string;
  vertragsBeginn: string;
  vertragsEnde?: string | null;
  vertragspartnerKurzBezeichnung: string;
  ids?: { bestandseinheitId: string; vertragId: string; linkText: string; objektId: string } | null;
};

export type BeVertragDeviationSourceEntityFragment = {
  bestandseinheitBezeichnung: string;
  objektBezeichnung: string;
  rechnungsAusstellerKurzBezeichnung: string;
  vertragsBeginn: string;
  vertragsEnde?: string | null;
  vertragspartnerKurzBezeichnung: string;
  ids?: { bestandseinheitId: string; vertragId: string; linkText: string; objektId: string } | null;
};

export type VorschreibungDeviationSourceEntityFragment = {
  belegDatum: string;
  belegnummer: string;
  rechnungsausstellerId: string;
  rechnungsempfaengerId: string;
  sumBrutto: number;
  sumNetto: number;
};

export type VorsteuerkuerzungDeviationSourceEntityFragment = {
  bestandseinheitBezeichnung: string;
  objektBezeichnung: string;
  rechnungsAusstellerKurzBezeichnung: string;
  vertragsBeginn: string;
  vertragsEnde?: string | null;
  vertragspartnerKurzBezeichnung: string;
  ids?: { bestandseinheitId: string; vertragId: string; linkText: string; objektId: string } | null;
};

export type DeviationSourceEntity_AuftragDeviationSourceEntity_Fragment = {
  __typename: 'AuftragDeviationSourceEntity';
  entityId: string;
  belegDatum: string;
  belegnummer: string;
  rechnungsausstellerId: string;
  rechnungsempfaengerId: string;
  sumBrutto: number;
  sumNetto: number;
  type: { text: string; value: Types.DeviationSourceEntityType };
};

export type DeviationSourceEntity_BeVertragDeviationSourceEntity_Fragment = {
  __typename: 'BeVertragDeviationSourceEntity';
  entityId: string;
  bestandseinheitBezeichnung: string;
  objektBezeichnung: string;
  rechnungsAusstellerKurzBezeichnung: string;
  vertragsBeginn: string;
  vertragsEnde?: string | null;
  vertragspartnerKurzBezeichnung: string;
  type: { text: string; value: Types.DeviationSourceEntityType };
  ids?: { bestandseinheitId: string; vertragId: string; linkText: string; objektId: string } | null;
};

export type DeviationSourceEntity_BuchungsanweisungDeviationSourceEntity_Fragment = {
  __typename: 'BuchungsanweisungDeviationSourceEntity';
  entityId: string;
  belegnummer: string;
  gesamtBetrag: number;
  type: { text: string; value: Types.DeviationSourceEntityType };
  auftragsartType?: { text: string; value: Types.AuftragsartType } | null;
  gegenKonto?: { nummer: string; klasse: { text: string; value: Types.KontoKlasse } } | null;
};

export type DeviationSourceEntity_HeAbrechnungHeVertragDeviationSourceEntity_Fragment = {
  __typename: 'HeAbrechnungHeVertragDeviationSourceEntity';
  entityId: string;
  objektBezeichnung: string;
  vertragspartnerKurzBezeichnung: string;
  vertragsEnde?: string | null;
  vertragsBeginn: string;
  type: { text: string; value: Types.DeviationSourceEntityType };
  ids?: { heVertragId: string; linkText: string; objektId: string } | null;
};

export type DeviationSourceEntity_ObjektAbrechnungDeviationSourceEntity_Fragment = {
  __typename: 'ObjektAbrechnungDeviationSourceEntity';
  entityId: string;
  abrechnungBezeichnung?: string | null;
  fromInclusive: string;
  objektBezeichnung: string;
  toInclusive: string;
  type: { text: string; value: Types.DeviationSourceEntityType };
  abrechnungType: { description?: string | null; text: string; value: Types.AbrechnungType };
  ids?: { linkText: string; objektAbrechnungId: string; objektId: string } | null;
};

export type DeviationSourceEntity_TopAbrechnungDeviationSourceEntity_Fragment = {
  __typename: 'TopAbrechnungDeviationSourceEntity';
  entityId: string;
  fromInclusive: string;
  bestandseinheitBezeichnung: string;
  objektBezeichnung: string;
  toInclusive: string;
  type: { text: string; value: Types.DeviationSourceEntityType };
  abrechnungType: { description?: string | null; text: string; value: Types.AbrechnungType };
  ids?: { objektId: string; linkText: string; objektAbrechnungId: string; topAbrechnungId: string } | null;
};

export type DeviationSourceEntity_UstVomAufwandDeviationSourceEntity_Fragment = {
  __typename: 'UstVomAufwandDeviationSourceEntity';
  entityId: string;
  bestandseinheitBezeichnung: string;
  objektBezeichnung: string;
  rechnungsAusstellerKurzBezeichnung: string;
  vertragsBeginn: string;
  vertragsEnde?: string | null;
  vertragspartnerKurzBezeichnung: string;
  type: { text: string; value: Types.DeviationSourceEntityType };
  ids?: { bestandseinheitId: string; vertragId: string; linkText: string; objektId: string } | null;
};

export type DeviationSourceEntity_VorsteuerkuerzungDeviationSourceEntity_Fragment = {
  __typename: 'VorsteuerkuerzungDeviationSourceEntity';
  entityId: string;
  bestandseinheitBezeichnung: string;
  objektBezeichnung: string;
  rechnungsAusstellerKurzBezeichnung: string;
  vertragsBeginn: string;
  vertragsEnde?: string | null;
  vertragspartnerKurzBezeichnung: string;
  type: { text: string; value: Types.DeviationSourceEntityType };
  ids?: { bestandseinheitId: string; vertragId: string; linkText: string; objektId: string } | null;
};

export type DeviationSourceEntityFragment =
  | DeviationSourceEntity_AuftragDeviationSourceEntity_Fragment
  | DeviationSourceEntity_BeVertragDeviationSourceEntity_Fragment
  | DeviationSourceEntity_BuchungsanweisungDeviationSourceEntity_Fragment
  | DeviationSourceEntity_HeAbrechnungHeVertragDeviationSourceEntity_Fragment
  | DeviationSourceEntity_ObjektAbrechnungDeviationSourceEntity_Fragment
  | DeviationSourceEntity_TopAbrechnungDeviationSourceEntity_Fragment
  | DeviationSourceEntity_UstVomAufwandDeviationSourceEntity_Fragment
  | DeviationSourceEntity_VorsteuerkuerzungDeviationSourceEntity_Fragment;

export type DeviationLinkListFragment = {
  text: string;
  ids?:
    | { __typename: 'DeviationUriIdAuftrag'; auftragId: string; uriType: Types.DeviationUriIdType }
    | { __typename: 'DeviationUriIdBeNutzungsArt'; uriType: Types.DeviationUriIdType }
    | { __typename: 'DeviationUriIdBeOptionSteuerpflicht'; uriType: Types.DeviationUriIdType }
    | { __typename: 'DeviationUriIdBeVertrag'; bestandseinheitId: string; vertragId: string; objektId: string; uriType: Types.DeviationUriIdType }
    | { __typename: 'DeviationUriIdBeVertragVorschreibungsposition'; uriType: Types.DeviationUriIdType }
    | { __typename: 'DeviationUriIdBestandseinheit'; uriType: Types.DeviationUriIdType }
    | {
        __typename: 'DeviationUriIdBkAbrechnungsdefinitionVersion';
        bkAbrechnungsdefinitionId: string;
        bkAbrechnungsdefinitionVersionId: string;
        uriType: Types.DeviationUriIdType;
      }
    | { __typename: 'DeviationUriIdEingangsrechnung'; belegFileId: string; belegId: string; uriType: Types.DeviationUriIdType }
    | {
        __typename: 'DeviationUriIdHeAbrechnungHeVertragVersion';
        heAbrechnungId: string;
        heAbrechnungHeVertragId: string;
        objektId: string;
        uriType: Types.DeviationUriIdType;
      }
    | {
        __typename: 'DeviationUriIdHeAbrechnungsdefinitionVersion';
        heAbrechnungsdefinitionId: string;
        heAbrechnungsdefinitionVersionId: string;
        uriType: Types.DeviationUriIdType;
      }
    | { __typename: 'DeviationUriIdHeVertrag'; objektId: string; heVertragId: string; uriType: Types.DeviationUriIdType }
    | { __typename: 'DeviationUriIdKontierungstabelle'; kontierungstabelleId: string; uriType: Types.DeviationUriIdType }
    | {
        __typename: 'DeviationUriIdLeerstehungAufwandskonto';
        firmendatenId: string;
        firmendatenLeerstehungAufwandskontoId: string;
        uriType: Types.DeviationUriIdType;
      }
    | { __typename: 'DeviationUriIdPauschalmieteAufwandskonto'; uriType: Types.DeviationUriIdType }
    | { __typename: 'DeviationUriIdRechtstraegerSteuerpflicht'; uriType: Types.DeviationUriIdType }
    | { __typename: 'DeviationUriIdRepFondsEinstellung'; repFondsEinstellungId: string; uriType: Types.DeviationUriIdType }
    | {
        __typename: 'DeviationUriIdTopAbrechnung';
        objektAbrechnungId: string;
        objektId: string;
        topAbrechnungId: string;
        uriType: Types.DeviationUriIdType;
      }
    | { __typename: 'DeviationUriIdUstKategorie'; uriType: Types.DeviationUriIdType }
    | {
        __typename: 'DeviationUriIdUstKategorieEntry';
        ustKategorieEntryId: string;
        ustKategorieId: string;
        ustKategorieVersionId: string;
        uriType: Types.DeviationUriIdType;
      }
    | { __typename: 'DeviationUriIdUstRegelset'; uriType: Types.DeviationUriIdType }
    | { __typename: 'DeviationUriIdUstVomAufwand'; objektId: string; ustVomAufwandId: string; uriType: Types.DeviationUriIdType }
    | { __typename: 'DeviationUriIdVertragsart'; objektId: string; bestandseinheitId: string; vertragId: string; uriType: Types.DeviationUriIdType }
    | {
        __typename: 'DeviationUriIdVertragspartner';
        objektId: string;
        bestandseinheitId: string;
        vertragId: string;
        uriType: Types.DeviationUriIdType;
      }
    | { __typename: 'DeviationUriIdVorschreibungsposition'; uriType: Types.DeviationUriIdType }
    | {
        __typename: 'DeviationUriIdVorschreibungspositionVersion';
        vorschreibungspositionId: string;
        vorschreibungspositionVersionId: string;
        uriType: Types.DeviationUriIdType;
      }
    | { __typename: 'DeviationUriIdVorsteuerkuerzung'; objektId: string; vorsteuerkuerzungId: string; uriType: Types.DeviationUriIdType }
    | { __typename: 'DeviationUriIdVorsteuerkuerzungEinstellung'; vorsteuerkuerzungEinstellungId: string; uriType: Types.DeviationUriIdType }
    | {
        __typename: 'DeviationUriIdWeAbrechnungsdefinitionVersion';
        weAbrechnungsdefinitionId: string;
        weAbrechnungsdefinitionVersionId: string;
        uriType: Types.DeviationUriIdType;
      }
    | { __typename: 'DeviationUriIdZinsliste'; objektId: string; zinslisteId: string; uriType: Types.DeviationUriIdType }
    | null;
};

export type DeviationLinkListIdsFields_DeviationUriIdAuftrag_Fragment = {
  __typename: 'DeviationUriIdAuftrag';
  auftragId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdBeNutzungsArt_Fragment = {
  __typename: 'DeviationUriIdBeNutzungsArt';
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdBeOptionSteuerpflicht_Fragment = {
  __typename: 'DeviationUriIdBeOptionSteuerpflicht';
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdBeVertrag_Fragment = {
  __typename: 'DeviationUriIdBeVertrag';
  bestandseinheitId: string;
  vertragId: string;
  objektId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdBeVertragVorschreibungsposition_Fragment = {
  __typename: 'DeviationUriIdBeVertragVorschreibungsposition';
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdBestandseinheit_Fragment = {
  __typename: 'DeviationUriIdBestandseinheit';
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdBkAbrechnungsdefinitionVersion_Fragment = {
  __typename: 'DeviationUriIdBkAbrechnungsdefinitionVersion';
  bkAbrechnungsdefinitionId: string;
  bkAbrechnungsdefinitionVersionId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdEingangsrechnung_Fragment = {
  __typename: 'DeviationUriIdEingangsrechnung';
  belegFileId: string;
  belegId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdHeAbrechnungHeVertragVersion_Fragment = {
  __typename: 'DeviationUriIdHeAbrechnungHeVertragVersion';
  heAbrechnungId: string;
  heAbrechnungHeVertragId: string;
  objektId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdHeAbrechnungsdefinitionVersion_Fragment = {
  __typename: 'DeviationUriIdHeAbrechnungsdefinitionVersion';
  heAbrechnungsdefinitionId: string;
  heAbrechnungsdefinitionVersionId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdHeVertrag_Fragment = {
  __typename: 'DeviationUriIdHeVertrag';
  objektId: string;
  heVertragId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdKontierungstabelle_Fragment = {
  __typename: 'DeviationUriIdKontierungstabelle';
  kontierungstabelleId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdLeerstehungAufwandskonto_Fragment = {
  __typename: 'DeviationUriIdLeerstehungAufwandskonto';
  firmendatenId: string;
  firmendatenLeerstehungAufwandskontoId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdPauschalmieteAufwandskonto_Fragment = {
  __typename: 'DeviationUriIdPauschalmieteAufwandskonto';
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdRechtstraegerSteuerpflicht_Fragment = {
  __typename: 'DeviationUriIdRechtstraegerSteuerpflicht';
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdRepFondsEinstellung_Fragment = {
  __typename: 'DeviationUriIdRepFondsEinstellung';
  repFondsEinstellungId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdTopAbrechnung_Fragment = {
  __typename: 'DeviationUriIdTopAbrechnung';
  objektAbrechnungId: string;
  objektId: string;
  topAbrechnungId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdUstKategorie_Fragment = {
  __typename: 'DeviationUriIdUstKategorie';
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdUstKategorieEntry_Fragment = {
  __typename: 'DeviationUriIdUstKategorieEntry';
  ustKategorieEntryId: string;
  ustKategorieId: string;
  ustKategorieVersionId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdUstRegelset_Fragment = {
  __typename: 'DeviationUriIdUstRegelset';
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdUstVomAufwand_Fragment = {
  __typename: 'DeviationUriIdUstVomAufwand';
  objektId: string;
  ustVomAufwandId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdVertragsart_Fragment = {
  __typename: 'DeviationUriIdVertragsart';
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdVertragspartner_Fragment = {
  __typename: 'DeviationUriIdVertragspartner';
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdVorschreibungsposition_Fragment = {
  __typename: 'DeviationUriIdVorschreibungsposition';
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdVorschreibungspositionVersion_Fragment = {
  __typename: 'DeviationUriIdVorschreibungspositionVersion';
  vorschreibungspositionId: string;
  vorschreibungspositionVersionId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdVorsteuerkuerzung_Fragment = {
  __typename: 'DeviationUriIdVorsteuerkuerzung';
  objektId: string;
  vorsteuerkuerzungId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdVorsteuerkuerzungEinstellung_Fragment = {
  __typename: 'DeviationUriIdVorsteuerkuerzungEinstellung';
  vorsteuerkuerzungEinstellungId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdWeAbrechnungsdefinitionVersion_Fragment = {
  __typename: 'DeviationUriIdWeAbrechnungsdefinitionVersion';
  weAbrechnungsdefinitionId: string;
  weAbrechnungsdefinitionVersionId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFields_DeviationUriIdZinsliste_Fragment = {
  __typename: 'DeviationUriIdZinsliste';
  objektId: string;
  zinslisteId: string;
  uriType: Types.DeviationUriIdType;
};

export type DeviationLinkListIdsFieldsFragment =
  | DeviationLinkListIdsFields_DeviationUriIdAuftrag_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdBeNutzungsArt_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdBeOptionSteuerpflicht_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdBeVertrag_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdBeVertragVorschreibungsposition_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdBestandseinheit_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdBkAbrechnungsdefinitionVersion_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdEingangsrechnung_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdHeAbrechnungHeVertragVersion_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdHeAbrechnungsdefinitionVersion_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdHeVertrag_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdKontierungstabelle_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdLeerstehungAufwandskonto_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdPauschalmieteAufwandskonto_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdRechtstraegerSteuerpflicht_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdRepFondsEinstellung_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdTopAbrechnung_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdUstKategorie_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdUstKategorieEntry_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdUstRegelset_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdUstVomAufwand_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdVertragsart_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdVertragspartner_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdVorschreibungsposition_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdVorschreibungspositionVersion_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdVorsteuerkuerzung_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdVorsteuerkuerzungEinstellung_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdWeAbrechnungsdefinitionVersion_Fragment
  | DeviationLinkListIdsFields_DeviationUriIdZinsliste_Fragment;

export type DeviationDataFragment = {
  order?: number | null;
  type: Types.DeviationType;
  entryList: Array<{
    attributeList: Array<
      | {
          __typename: 'DeviationAttributeAmount';
          hasChanged: boolean;
          attributeId: number;
          type: Types.DeviationAttributeType;
          valueFloat?: number | null;
          name: { text: string; value: Types.DeviationAttributeNameType };
        }
      | {
          __typename: 'DeviationAttributeDate';
          hasChanged: boolean;
          attributeId: number;
          type: Types.DeviationAttributeType;
          valueDate?: string | null;
          name: { text: string; value: Types.DeviationAttributeNameType };
        }
      | {
          __typename: 'DeviationAttributeNumber';
          hasChanged: boolean;
          attributeId: number;
          type: Types.DeviationAttributeType;
          valueFloat?: number | null;
          name: { text: string; value: Types.DeviationAttributeNameType };
        }
      | {
          __typename: 'DeviationAttributePercent';
          hasChanged: boolean;
          attributeId: number;
          type: Types.DeviationAttributeType;
          valuePercent?: number | null;
          name: { text: string; value: Types.DeviationAttributeNameType };
        }
      | {
          __typename: 'DeviationAttributeString';
          hasChanged: boolean;
          attributeId: number;
          type: Types.DeviationAttributeType;
          valueString?: string | null;
          name: { text: string; value: Types.DeviationAttributeNameType };
        }
      | {
          __typename: 'DeviationAttributeUriList';
          hasChanged: boolean;
          attributeId: number;
          type: Types.DeviationAttributeType;
          uriList: Array<{
            text: string;
            ids?:
              | { __typename: 'DeviationUriIdAuftrag'; auftragId: string; uriType: Types.DeviationUriIdType }
              | { __typename: 'DeviationUriIdBeNutzungsArt'; bestandseinheitId: string; objektId: string; uriType: Types.DeviationUriIdType }
              | {
                  __typename: 'DeviationUriIdBeOptionSteuerpflicht';
                  objektId: string;
                  beOptionSteuerpflichtBestandseinheitId?: string | null;
                  uriType: Types.DeviationUriIdType;
                }
              | {
                  __typename: 'DeviationUriIdBeVertrag';
                  bestandseinheitId: string;
                  objektId: string;
                  vertragId: string;
                  uriType: Types.DeviationUriIdType;
                }
              | {
                  __typename: 'DeviationUriIdBeVertragVorschreibungsposition';
                  bestandseinheitId: string;
                  vertragId: string;
                  objektId: string;
                  vorschreibungspositionId: string;
                  uriType: Types.DeviationUriIdType;
                }
              | { __typename: 'DeviationUriIdBestandseinheit'; objektId: string; bestandseinheitId: string; uriType: Types.DeviationUriIdType }
              | {
                  __typename: 'DeviationUriIdBkAbrechnungsdefinitionVersion';
                  bkAbrechnungsdefinitionId: string;
                  bkAbrechnungsdefinitionVersionId: string;
                  uriType: Types.DeviationUriIdType;
                }
              | { __typename: 'DeviationUriIdEingangsrechnung'; belegFileId: string; belegId: string; uriType: Types.DeviationUriIdType }
              | {
                  __typename: 'DeviationUriIdHeAbrechnungHeVertragVersion';
                  heAbrechnungId: string;
                  heAbrechnungHeVertragId: string;
                  objektId: string;
                  uriType: Types.DeviationUriIdType;
                }
              | {
                  __typename: 'DeviationUriIdHeAbrechnungsdefinitionVersion';
                  heAbrechnungsdefinitionId: string;
                  heAbrechnungsdefinitionVersionId: string;
                  uriType: Types.DeviationUriIdType;
                }
              | { __typename: 'DeviationUriIdHeVertrag'; objektId: string; heVertragId: string; uriType: Types.DeviationUriIdType }
              | { __typename: 'DeviationUriIdKontierungstabelle'; kontierungstabelleId: string; uriType: Types.DeviationUriIdType }
              | {
                  __typename: 'DeviationUriIdLeerstehungAufwandskonto';
                  firmendatenId: string;
                  firmendatenLeerstehungAufwandskontoId: string;
                  uriType: Types.DeviationUriIdType;
                }
              | {
                  __typename: 'DeviationUriIdPauschalmieteAufwandskonto';
                  firmendatenId: string;
                  firmendatenPauschalmieteAufwandskontoId: string;
                  uriType: Types.DeviationUriIdType;
                }
              | {
                  __typename: 'DeviationUriIdRechtstraegerSteuerpflicht';
                  rechtstraegerId: string;
                  uriType: Types.DeviationUriIdType;
                  rechtstraegerType: { text: string; value: Types.RechtstraegerType };
                }
              | { __typename: 'DeviationUriIdRepFondsEinstellung'; repFondsEinstellungId: string; uriType: Types.DeviationUriIdType }
              | {
                  __typename: 'DeviationUriIdTopAbrechnung';
                  objektAbrechnungId: string;
                  objektId: string;
                  topAbrechnungId: string;
                  uriType: Types.DeviationUriIdType;
                }
              | {
                  __typename: 'DeviationUriIdUstKategorie';
                  ustKategorieId: string;
                  ustKategorieDeviationUstKategorieEntryId?: string | null;
                  uriType: Types.DeviationUriIdType;
                  ustKategorieStatus: { text: string; value: Types.UstKategorieStatus };
                }
              | {
                  __typename: 'DeviationUriIdUstKategorieEntry';
                  ustKategorieId: string;
                  ustKategorieVersionId: string;
                  ustKategorieEntryDeviationUstKategorieEntryId: string;
                  uriType: Types.DeviationUriIdType;
                }
              | { __typename: 'DeviationUriIdUstRegelset'; ustRegelsetId: string; uriType: Types.DeviationUriIdType }
              | { __typename: 'DeviationUriIdUstVomAufwand'; objektId: string; ustVomAufwandId: string; uriType: Types.DeviationUriIdType }
              | {
                  __typename: 'DeviationUriIdVertragsart';
                  objektId: string;
                  bestandseinheitId: string;
                  vertragId: string;
                  uriType: Types.DeviationUriIdType;
                }
              | {
                  __typename: 'DeviationUriIdVertragspartner';
                  objektId: string;
                  bestandseinheitId: string;
                  vertragId: string;
                  uriType: Types.DeviationUriIdType;
                }
              | {
                  __typename: 'DeviationUriIdVorschreibungsposition';
                  vorschreibungspositionId: string;
                  uriType: Types.DeviationUriIdType;
                  vorschreibungspositionStatus: { description?: string | null; text: string; value: Types.VorschreibungspositionStatus };
                }
              | {
                  __typename: 'DeviationUriIdVorschreibungspositionVersion';
                  vorschreibungspositionId: string;
                  vorschreibungspositionVersionId: string;
                  uriType: Types.DeviationUriIdType;
                }
              | { __typename: 'DeviationUriIdVorsteuerkuerzung'; objektId: string; vorsteuerkuerzungId: string; uriType: Types.DeviationUriIdType }
              | {
                  __typename: 'DeviationUriIdVorsteuerkuerzungEinstellung';
                  vorsteuerkuerzungEinstellungId: string;
                  uriType: Types.DeviationUriIdType;
                }
              | {
                  __typename: 'DeviationUriIdWeAbrechnungsdefinitionVersion';
                  weAbrechnungsdefinitionId: string;
                  weAbrechnungsdefinitionVersionId: string;
                  uriType: Types.DeviationUriIdType;
                }
              | { __typename: 'DeviationUriIdZinsliste'; objektId: string; zinslisteId: string; uriType: Types.DeviationUriIdType }
              | null;
          }>;
          name: { text: string; value: Types.DeviationAttributeNameType };
        }
    >;
    rowType: { text: string; value: Types.DeviationEntryType };
  }>;
  group?: { id: string; text: string } | null;
  linkList?: Array<{
    text: string;
    ids?:
      | { __typename: 'DeviationUriIdAuftrag'; auftragId: string; uriType: Types.DeviationUriIdType }
      | { __typename: 'DeviationUriIdBeNutzungsArt'; uriType: Types.DeviationUriIdType }
      | { __typename: 'DeviationUriIdBeOptionSteuerpflicht'; uriType: Types.DeviationUriIdType }
      | { __typename: 'DeviationUriIdBeVertrag'; bestandseinheitId: string; vertragId: string; objektId: string; uriType: Types.DeviationUriIdType }
      | { __typename: 'DeviationUriIdBeVertragVorschreibungsposition'; uriType: Types.DeviationUriIdType }
      | { __typename: 'DeviationUriIdBestandseinheit'; uriType: Types.DeviationUriIdType }
      | {
          __typename: 'DeviationUriIdBkAbrechnungsdefinitionVersion';
          bkAbrechnungsdefinitionId: string;
          bkAbrechnungsdefinitionVersionId: string;
          uriType: Types.DeviationUriIdType;
        }
      | { __typename: 'DeviationUriIdEingangsrechnung'; belegFileId: string; belegId: string; uriType: Types.DeviationUriIdType }
      | {
          __typename: 'DeviationUriIdHeAbrechnungHeVertragVersion';
          heAbrechnungId: string;
          heAbrechnungHeVertragId: string;
          objektId: string;
          uriType: Types.DeviationUriIdType;
        }
      | {
          __typename: 'DeviationUriIdHeAbrechnungsdefinitionVersion';
          heAbrechnungsdefinitionId: string;
          heAbrechnungsdefinitionVersionId: string;
          uriType: Types.DeviationUriIdType;
        }
      | { __typename: 'DeviationUriIdHeVertrag'; objektId: string; heVertragId: string; uriType: Types.DeviationUriIdType }
      | { __typename: 'DeviationUriIdKontierungstabelle'; kontierungstabelleId: string; uriType: Types.DeviationUriIdType }
      | {
          __typename: 'DeviationUriIdLeerstehungAufwandskonto';
          firmendatenId: string;
          firmendatenLeerstehungAufwandskontoId: string;
          uriType: Types.DeviationUriIdType;
        }
      | { __typename: 'DeviationUriIdPauschalmieteAufwandskonto'; uriType: Types.DeviationUriIdType }
      | { __typename: 'DeviationUriIdRechtstraegerSteuerpflicht'; uriType: Types.DeviationUriIdType }
      | { __typename: 'DeviationUriIdRepFondsEinstellung'; repFondsEinstellungId: string; uriType: Types.DeviationUriIdType }
      | {
          __typename: 'DeviationUriIdTopAbrechnung';
          objektAbrechnungId: string;
          objektId: string;
          topAbrechnungId: string;
          uriType: Types.DeviationUriIdType;
        }
      | { __typename: 'DeviationUriIdUstKategorie'; uriType: Types.DeviationUriIdType }
      | {
          __typename: 'DeviationUriIdUstKategorieEntry';
          ustKategorieEntryId: string;
          ustKategorieId: string;
          ustKategorieVersionId: string;
          uriType: Types.DeviationUriIdType;
        }
      | { __typename: 'DeviationUriIdUstRegelset'; uriType: Types.DeviationUriIdType }
      | { __typename: 'DeviationUriIdUstVomAufwand'; objektId: string; ustVomAufwandId: string; uriType: Types.DeviationUriIdType }
      | { __typename: 'DeviationUriIdVertragsart'; objektId: string; bestandseinheitId: string; vertragId: string; uriType: Types.DeviationUriIdType }
      | {
          __typename: 'DeviationUriIdVertragspartner';
          objektId: string;
          bestandseinheitId: string;
          vertragId: string;
          uriType: Types.DeviationUriIdType;
        }
      | { __typename: 'DeviationUriIdVorschreibungsposition'; uriType: Types.DeviationUriIdType }
      | {
          __typename: 'DeviationUriIdVorschreibungspositionVersion';
          vorschreibungspositionId: string;
          vorschreibungspositionVersionId: string;
          uriType: Types.DeviationUriIdType;
        }
      | { __typename: 'DeviationUriIdVorsteuerkuerzung'; objektId: string; vorsteuerkuerzungId: string; uriType: Types.DeviationUriIdType }
      | { __typename: 'DeviationUriIdVorsteuerkuerzungEinstellung'; vorsteuerkuerzungEinstellungId: string; uriType: Types.DeviationUriIdType }
      | {
          __typename: 'DeviationUriIdWeAbrechnungsdefinitionVersion';
          weAbrechnungsdefinitionId: string;
          weAbrechnungsdefinitionVersionId: string;
          uriType: Types.DeviationUriIdType;
        }
      | { __typename: 'DeviationUriIdZinsliste'; objektId: string; zinslisteId: string; uriType: Types.DeviationUriIdType }
      | null;
  }> | null;
  sourceEntity:
    | {
        __typename: 'AuftragDeviationSourceEntity';
        entityId: string;
        belegDatum: string;
        belegnummer: string;
        rechnungsausstellerId: string;
        rechnungsempfaengerId: string;
        sumBrutto: number;
        sumNetto: number;
        type: { text: string; value: Types.DeviationSourceEntityType };
      }
    | {
        __typename: 'BeVertragDeviationSourceEntity';
        entityId: string;
        bestandseinheitBezeichnung: string;
        objektBezeichnung: string;
        rechnungsAusstellerKurzBezeichnung: string;
        vertragsBeginn: string;
        vertragsEnde?: string | null;
        vertragspartnerKurzBezeichnung: string;
        type: { text: string; value: Types.DeviationSourceEntityType };
        ids?: { bestandseinheitId: string; vertragId: string; linkText: string; objektId: string } | null;
      }
    | {
        __typename: 'BuchungsanweisungDeviationSourceEntity';
        entityId: string;
        belegnummer: string;
        gesamtBetrag: number;
        type: { text: string; value: Types.DeviationSourceEntityType };
        auftragsartType?: { text: string; value: Types.AuftragsartType } | null;
        gegenKonto?: { nummer: string; klasse: { text: string; value: Types.KontoKlasse } } | null;
      }
    | {
        __typename: 'HeAbrechnungHeVertragDeviationSourceEntity';
        entityId: string;
        objektBezeichnung: string;
        vertragspartnerKurzBezeichnung: string;
        vertragsEnde?: string | null;
        vertragsBeginn: string;
        type: { text: string; value: Types.DeviationSourceEntityType };
        ids?: { heVertragId: string; linkText: string; objektId: string } | null;
      }
    | {
        __typename: 'ObjektAbrechnungDeviationSourceEntity';
        entityId: string;
        abrechnungBezeichnung?: string | null;
        fromInclusive: string;
        objektBezeichnung: string;
        toInclusive: string;
        type: { text: string; value: Types.DeviationSourceEntityType };
        abrechnungType: { description?: string | null; text: string; value: Types.AbrechnungType };
        ids?: { linkText: string; objektAbrechnungId: string; objektId: string } | null;
      }
    | {
        __typename: 'TopAbrechnungDeviationSourceEntity';
        entityId: string;
        fromInclusive: string;
        bestandseinheitBezeichnung: string;
        objektBezeichnung: string;
        toInclusive: string;
        type: { text: string; value: Types.DeviationSourceEntityType };
        abrechnungType: { description?: string | null; text: string; value: Types.AbrechnungType };
        ids?: { objektId: string; linkText: string; objektAbrechnungId: string; topAbrechnungId: string } | null;
      }
    | {
        __typename: 'UstVomAufwandDeviationSourceEntity';
        entityId: string;
        bestandseinheitBezeichnung: string;
        objektBezeichnung: string;
        rechnungsAusstellerKurzBezeichnung: string;
        vertragsBeginn: string;
        vertragsEnde?: string | null;
        vertragspartnerKurzBezeichnung: string;
        type: { text: string; value: Types.DeviationSourceEntityType };
        ids?: { bestandseinheitId: string; vertragId: string; linkText: string; objektId: string } | null;
      }
    | {
        __typename: 'VorsteuerkuerzungDeviationSourceEntity';
        entityId: string;
        bestandseinheitBezeichnung: string;
        objektBezeichnung: string;
        rechnungsAusstellerKurzBezeichnung: string;
        vertragsBeginn: string;
        vertragsEnde?: string | null;
        vertragspartnerKurzBezeichnung: string;
        type: { text: string; value: Types.DeviationSourceEntityType };
        ids?: { bestandseinheitId: string; vertragId: string; linkText: string; objektId: string } | null;
      };
  changeType: { text: string; value: Types.DeviationChangeType };
  ursache: { text: string; value: Types.DeviationUrsacheType };
};

export const DeviationEntryAttributeDeviationAttributeUriListIdsFieldsFragmentDoc = gql`
  fragment DeviationEntryAttributeDeviationAttributeUriListIdsFields on AbstractDeviationUriId {
    __typename
    uriType: type
    ... on DeviationUriIdAuftrag {
      auftragId
    }
    ... on DeviationUriIdBeNutzungsArt {
      bestandseinheitId
      objektId
    }
    ... on DeviationUriIdBeVertrag {
      bestandseinheitId
      objektId
      vertragId
    }
    ... on DeviationUriIdBeVertragVorschreibungsposition {
      bestandseinheitId
      vertragId
      objektId
      vorschreibungspositionId
    }
    ... on DeviationUriIdBestandseinheit {
      objektId
      bestandseinheitId
    }
    ... on DeviationUriIdBeOptionSteuerpflicht {
      beOptionSteuerpflichtBestandseinheitId: bestandseinheitId
      objektId
    }
    ... on DeviationUriIdBkAbrechnungsdefinitionVersion {
      bkAbrechnungsdefinitionId
      bkAbrechnungsdefinitionVersionId
    }
    ... on DeviationUriIdEingangsrechnung {
      belegFileId
      belegId
    }
    ... on DeviationUriIdHeAbrechnungHeVertragVersion {
      heAbrechnungId
      heAbrechnungHeVertragId
      objektId
    }
    ... on DeviationUriIdHeAbrechnungsdefinitionVersion {
      heAbrechnungsdefinitionId
      heAbrechnungsdefinitionVersionId
    }
    ... on DeviationUriIdHeVertrag {
      objektId
      heVertragId
    }
    ... on DeviationUriIdKontierungstabelle {
      kontierungstabelleId
    }
    ... on DeviationUriIdLeerstehungAufwandskonto {
      firmendatenId
      firmendatenLeerstehungAufwandskontoId
    }
    ... on DeviationUriIdPauschalmieteAufwandskonto {
      firmendatenId
      firmendatenPauschalmieteAufwandskontoId
    }
    ... on DeviationUriIdRechtstraegerSteuerpflicht {
      rechtstraegerId
      rechtstraegerType {
        text
        value
      }
    }
    ... on DeviationUriIdRepFondsEinstellung {
      repFondsEinstellungId
    }
    ... on DeviationUriIdTopAbrechnung {
      objektAbrechnungId
      objektId
      topAbrechnungId
    }
    ... on DeviationUriIdUstKategorie {
      ustKategorieDeviationUstKategorieEntryId: ustKategorieEntryId
      ustKategorieId
      ustKategorieStatus: status {
        text
        value
      }
    }
    ... on DeviationUriIdUstKategorieEntry {
      ustKategorieEntryDeviationUstKategorieEntryId: ustKategorieEntryId
      ustKategorieId
      ustKategorieVersionId
    }
    ... on DeviationUriIdUstRegelset {
      ustRegelsetId
    }
    ... on DeviationUriIdUstVomAufwand {
      objektId
      ustVomAufwandId
    }
    ... on DeviationUriIdVertragsart {
      objektId
      bestandseinheitId
      vertragId
    }
    ... on DeviationUriIdVertragspartner {
      objektId
      bestandseinheitId
      vertragId
    }
    ... on DeviationUriIdVorschreibungsposition {
      vorschreibungspositionStatus: status {
        description
        text
        value
      }
      vorschreibungspositionId
    }
    ... on DeviationUriIdVorschreibungspositionVersion {
      vorschreibungspositionId
      vorschreibungspositionVersionId
    }
    ... on DeviationUriIdVorsteuerkuerzung {
      objektId
      vorsteuerkuerzungId
    }
    ... on DeviationUriIdVorsteuerkuerzungEinstellung {
      vorsteuerkuerzungEinstellungId
    }
    ... on DeviationUriIdWeAbrechnungsdefinitionVersion {
      weAbrechnungsdefinitionId
      weAbrechnungsdefinitionVersionId
    }
    ... on DeviationUriIdZinsliste {
      objektId
      zinslisteId
    }
  }
`;
export const DeviationAttributeUriListFieldsFragmentDoc = gql`
  fragment DeviationAttributeUriListFields on DeviationAttributeUri {
    ids {
      ...DeviationEntryAttributeDeviationAttributeUriListIdsFields
    }
    text
  }
  ${DeviationEntryAttributeDeviationAttributeUriListIdsFieldsFragmentDoc}
`;
export const DeviationEntryAttributeFieldsFragmentDoc = gql`
  fragment DeviationEntryAttributeFields on AbstractDeviationAttribute {
    __typename
    hasChanged
    attributeId
    name {
      text
      value
    }
    type
    ... on DeviationAttributeString {
      valueString: value
    }
    ... on DeviationAttributeDate {
      valueDate: value
    }
    ... on DeviationAttributeAmount {
      valueFloat: value
    }
    ... on DeviationAttributePercent {
      valuePercent: value
    }
    ... on DeviationAttributeNumber {
      valueFloat: value
    }
    ... on DeviationAttributeUriList {
      uriList {
        ...DeviationAttributeUriListFields
      }
    }
  }
  ${DeviationAttributeUriListFieldsFragmentDoc}
`;
export const DeviationEntryFieldsFragmentDoc = gql`
  fragment DeviationEntryFields on DeviationEntry {
    attributeList {
      ...DeviationEntryAttributeFields
    }
    rowType: type {
      text
      value
    }
  }
  ${DeviationEntryAttributeFieldsFragmentDoc}
`;
export const DeviationLinkListIdsFieldsFragmentDoc = gql`
  fragment DeviationLinkListIdsFields on AbstractDeviationUriId {
    __typename
    uriType: type
    ... on DeviationUriIdAuftrag {
      auftragId
    }
    ... on DeviationUriIdEingangsrechnung {
      belegFileId
      belegId
    }
    ... on DeviationUriIdKontierungstabelle {
      kontierungstabelleId
    }
    ... on DeviationUriIdTopAbrechnung {
      objektAbrechnungId
      objektId
      topAbrechnungId
    }
    ... on DeviationUriIdUstKategorieEntry {
      ustKategorieEntryId
      ustKategorieId
      ustKategorieVersionId
    }
    ... on DeviationUriIdBeVertrag {
      bestandseinheitId
      vertragId
      objektId
    }
    ... on DeviationUriIdVorschreibungspositionVersion {
      vorschreibungspositionId
      vorschreibungspositionVersionId
    }
    ... on DeviationUriIdVorsteuerkuerzungEinstellung {
      vorsteuerkuerzungEinstellungId
    }
    ... on DeviationUriIdVorsteuerkuerzung {
      objektId
      vorsteuerkuerzungId
    }
    ... on DeviationUriIdWeAbrechnungsdefinitionVersion {
      weAbrechnungsdefinitionId
      weAbrechnungsdefinitionVersionId
    }
    ... on DeviationUriIdBkAbrechnungsdefinitionVersion {
      bkAbrechnungsdefinitionId
      bkAbrechnungsdefinitionVersionId
    }
    ... on DeviationUriIdZinsliste {
      objektId
      zinslisteId
    }
    ... on DeviationUriIdRepFondsEinstellung {
      repFondsEinstellungId
    }
    ... on DeviationUriIdUstVomAufwand {
      objektId
      ustVomAufwandId
    }
    ... on DeviationUriIdVertragsart {
      objektId
      bestandseinheitId
      vertragId
    }
    ... on DeviationUriIdVertragspartner {
      objektId
      bestandseinheitId
      vertragId
    }
    ... on DeviationUriIdHeAbrechnungHeVertragVersion {
      heAbrechnungId
      heAbrechnungHeVertragId
      objektId
    }
    ... on DeviationUriIdHeAbrechnungsdefinitionVersion {
      heAbrechnungsdefinitionId
      heAbrechnungsdefinitionVersionId
    }
    ... on DeviationUriIdLeerstehungAufwandskonto {
      firmendatenId
      firmendatenLeerstehungAufwandskontoId
    }
    ... on DeviationUriIdHeVertrag {
      objektId
      heVertragId
    }
  }
`;
export const DeviationLinkListFragmentDoc = gql`
  fragment DeviationLinkList on DeviationAttributeUri {
    ids {
      ...DeviationLinkListIdsFields
    }
    text
  }
  ${DeviationLinkListIdsFieldsFragmentDoc}
`;
export const VorschreibungDeviationSourceEntityFragmentDoc = gql`
  fragment VorschreibungDeviationSourceEntity on AuftragDeviationSourceEntity {
    belegDatum
    belegnummer
    rechnungsausstellerId
    rechnungsempfaengerId
    sumBrutto
    sumNetto
  }
`;
export const BuchungsanweisungDeviationSourceEntityFragmentDoc = gql`
  fragment BuchungsanweisungDeviationSourceEntity on BuchungsanweisungDeviationSourceEntity {
    auftragsartType {
      text
      value
    }
    belegnummer
    gegenKonto {
      nummer
      klasse {
        text
        value
      }
    }
    gesamtBetrag
  }
`;
export const HeAbrechnungHeVertragDeviationSourceEntityFragmentDoc = gql`
  fragment HeAbrechnungHeVertragDeviationSourceEntity on HeAbrechnungHeVertragDeviationSourceEntity {
    ids {
      heVertragId
      linkText
      objektId
    }
    objektBezeichnung
    vertragspartnerKurzBezeichnung
    vertragsEnde
    vertragsBeginn
  }
`;
export const ObjektAbrechnungDeviationSourceEntityFragmentDoc = gql`
  fragment ObjektAbrechnungDeviationSourceEntity on ObjektAbrechnungDeviationSourceEntity {
    abrechnungType {
      description
      text
      value
    }
    abrechnungBezeichnung
    fromInclusive
    ids {
      linkText
      objektAbrechnungId
      objektId
    }
    objektBezeichnung
    toInclusive
  }
`;
export const TopAbrechnungDeviationSourceEntityFragmentDoc = gql`
  fragment TopAbrechnungDeviationSourceEntity on TopAbrechnungDeviationSourceEntity {
    abrechnungType {
      description
      text
      value
    }
    fromInclusive
    bestandseinheitBezeichnung
    ids {
      objektId
      linkText
      objektAbrechnungId
      topAbrechnungId
    }
    objektBezeichnung
    toInclusive
  }
`;
export const UstVomAufwandDeviationSourceEntityFragmentDoc = gql`
  fragment UstVomAufwandDeviationSourceEntity on UstVomAufwandDeviationSourceEntity {
    bestandseinheitBezeichnung
    ids {
      bestandseinheitId
      vertragId
      linkText
      objektId
    }
    objektBezeichnung
    rechnungsAusstellerKurzBezeichnung
    vertragsBeginn
    vertragsEnde
    vertragspartnerKurzBezeichnung
  }
`;
export const BeVertragDeviationSourceEntityFragmentDoc = gql`
  fragment BeVertragDeviationSourceEntity on BeVertragDeviationSourceEntity {
    bestandseinheitBezeichnung
    ids {
      bestandseinheitId
      vertragId
      linkText
      objektId
    }
    objektBezeichnung
    rechnungsAusstellerKurzBezeichnung
    vertragsBeginn
    vertragsEnde
    vertragspartnerKurzBezeichnung
  }
`;
export const VorsteuerkuerzungDeviationSourceEntityFragmentDoc = gql`
  fragment VorsteuerkuerzungDeviationSourceEntity on VorsteuerkuerzungDeviationSourceEntity {
    bestandseinheitBezeichnung
    ids {
      bestandseinheitId
      vertragId
      linkText
      objektId
    }
    objektBezeichnung
    rechnungsAusstellerKurzBezeichnung
    vertragsBeginn
    vertragsEnde
    vertragspartnerKurzBezeichnung
  }
`;
export const DeviationSourceEntityFragmentDoc = gql`
  fragment DeviationSourceEntity on AbstractDeviationSourceEntity {
    __typename
    entityId
    type {
      text
      value
    }
    ... on AuftragDeviationSourceEntity {
      ...VorschreibungDeviationSourceEntity
    }
    ... on BuchungsanweisungDeviationSourceEntity {
      ...BuchungsanweisungDeviationSourceEntity
    }
    ... on HeAbrechnungHeVertragDeviationSourceEntity {
      ...HeAbrechnungHeVertragDeviationSourceEntity
    }
    ... on ObjektAbrechnungDeviationSourceEntity {
      ...ObjektAbrechnungDeviationSourceEntity
    }
    ... on TopAbrechnungDeviationSourceEntity {
      ...TopAbrechnungDeviationSourceEntity
    }
    ... on UstVomAufwandDeviationSourceEntity {
      ...UstVomAufwandDeviationSourceEntity
    }
    ... on BeVertragDeviationSourceEntity {
      ...BeVertragDeviationSourceEntity
    }
    ... on VorsteuerkuerzungDeviationSourceEntity {
      ...VorsteuerkuerzungDeviationSourceEntity
    }
  }
  ${VorschreibungDeviationSourceEntityFragmentDoc}
  ${BuchungsanweisungDeviationSourceEntityFragmentDoc}
  ${HeAbrechnungHeVertragDeviationSourceEntityFragmentDoc}
  ${ObjektAbrechnungDeviationSourceEntityFragmentDoc}
  ${TopAbrechnungDeviationSourceEntityFragmentDoc}
  ${UstVomAufwandDeviationSourceEntityFragmentDoc}
  ${BeVertragDeviationSourceEntityFragmentDoc}
  ${VorsteuerkuerzungDeviationSourceEntityFragmentDoc}
`;
export const DeviationDataFragmentDoc = gql`
  fragment DeviationData on Deviation {
    entryList {
      ...DeviationEntryFields
    }
    group {
      id
      text
    }
    linkList {
      ...DeviationLinkList
    }
    order
    sourceEntity {
      ...DeviationSourceEntity
    }
    changeType {
      text
      value
    }
    type
    ursache {
      text
      value
    }
  }
  ${DeviationEntryFieldsFragmentDoc}
  ${DeviationLinkListFragmentDoc}
  ${DeviationSourceEntityFragmentDoc}
`;
