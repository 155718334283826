import React from 'react';
import { Path } from 'history';
import { TableWithColSelectorColumnProps } from './TableWithColSelectorProps';
import { PAGINATION_QUERY_PARAM_KEY } from '../../../hooks/usePaginationQueryParams';

export type TableWithColSelectorType = 'user' | 'system';

export type ColumnSelectorSetting = {
  title: string;
  selected: boolean;
};

export const getSelectedColumnTitles = (columns: ColumnSelectorSetting[]) => {
  return columns.filter((col) => col.selected).map((col) => getColumnTitle(col));
};

export function getColumnTitles<T>(columns: TableWithColSelectorColumnProps<T>[]) {
  return columns.map(getColumnTitle);
}

export function getUniqueColumnTitles<T>(columns: TableWithColSelectorColumnProps<T>[]) {
  return columns
    .map(getColumnTitle)
    .filter((col) => !!col)
    .filter((item, index, array) => array.indexOf(item) === index);
}

export function getColumnTitle<T>(column: TableWithColSelectorColumnProps<T>) {
  if (typeof column.colSelectorTitle === 'string') {
    return column.colSelectorTitle;
  }
  if (typeof column.title === 'function') {
    return getTitle(column.title({}), 0);
  }
  if (typeof column.title === 'object' && React.isValidElement(column.title)) {
    return Object.values(column.title.props).join('-');
  }
  return column.title as string;
}

const getTitle = (value: any, startIndex: number): string => {
  if (typeof value === 'object') {
    if (value.props?.children && Array.isArray(value.props.children)) {
      const next = startIndex + 1;
      const textList = value.props.children.map((v: any) => {
        if (Array.isArray(v.props.children)) {
          return getTitle(v.props.children, next);
        } else {
          return v.props.children;
        }
      });
      return textList[0];
    } else {
      return value.props.children;
    }
  }
  return value;
};

type PaginationQueryParams = {
  currentPage: string;
  pageSize: string;
};

export const buildPaginationQueryParams = (paginationQueryParams: PaginationQueryParams, uri: string, navigate: (path: Path) => void) => {
  const searchParams = new URLSearchParams(uri);
  if (
    uri.includes(PAGINATION_QUERY_PARAM_KEY.CURRENT_PAGE) &&
    searchParams.get(PAGINATION_QUERY_PARAM_KEY.CURRENT_PAGE) !== paginationQueryParams.currentPage
  ) {
    pushToHistory(uri, PAGINATION_QUERY_PARAM_KEY.CURRENT_PAGE, paginationQueryParams.currentPage, navigate);
  } else if (
    uri.includes(PAGINATION_QUERY_PARAM_KEY.PAGE_SIZE) &&
    searchParams.get(PAGINATION_QUERY_PARAM_KEY.PAGE_SIZE) !== paginationQueryParams.pageSize
  ) {
    pushToHistory(uri, PAGINATION_QUERY_PARAM_KEY.PAGE_SIZE, paginationQueryParams.pageSize, navigate);
  } else {
    const pagURLQueryParams = new URLSearchParams(paginationQueryParams);
    navigate(uri ? `${uri}&${pagURLQueryParams}` : `?${pagURLQueryParams}`);
  }
};

const pushToHistory = (search: string, name: string, value: string, push: (path: Path) => void) => {
  const searchParams = replaceQueryParams(search, name, value);
  push(searchParams);
};

const replaceQueryParams = (uri: string, name: string, value: string) => uri.replace(new RegExp(name + '=[1-9][0-9]*', 'g'), `${name}=${value}`);
