import { AbrechnungsdefinitionKontoAbrechnungskreisZuordnungInput } from '../../../types';
import { AbrechnungskreisNichtAbrechnen } from '../../AbrKontenzuordnung/Form/zuordnungKontoListingFormMapper';
import { AbrechnungsdefinitionVersion } from './abrDefTypes';

export const mapAbrechnungsdefinitionToKontenZuordnungFormValues = (
  abrechnungsdefinitionId: string,
  abrechnungsdefinitionVersion: AbrechnungsdefinitionVersion
): AbrechnungsdefinitionKontoAbrechnungskreisZuordnungInput[] =>
  abrechnungsdefinitionVersion.kontoAbrechnungskreisList.map((kontoAbrKreis) => {
    return {
      abrechnungsdefinitionId,
      abrechnungsdefinitionVersionId: abrechnungsdefinitionVersion.abrechnungsdefinitionVersionId,
      abrechnungskreisId: kontoAbrKreis.abrechnen ? kontoAbrKreis.abrechnungskreis?.abrechnungskreisId : AbrechnungskreisNichtAbrechnen.id,
      expenseReducing: kontoAbrKreis.expenseReducing,
      kontoId: kontoAbrKreis.konto.kontoId,
      ustVomAufwand: kontoAbrKreis.ustVomAufwand,
      buchungszeilenAnzeigen: kontoAbrKreis.buchungszeilenAnzeigen,
      aufteilungsschluesselId: kontoAbrKreis.aufteilungsschluessel?.aufteilungsschluesselId,
    };
  });
