import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBeVertragDokumentenversandMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  input: Types.BeVertragDokumentenversandInput;
}>;

export type CreateBeVertragDokumentenversandMutation = {
  createBeVertragDokumentenversandEntry: {
    data: { dokumentenversandEntryId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateBeVertragDokumentenversandMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  dokumentenversandEntryId: Types.Scalars['ID']['input'];
  input: Types.BeVertragDokumentenversandInput;
}>;

export type UpdateBeVertragDokumentenversandMutation = {
  updateBeVertragDokumentenversandEntry: {
    data: { dokumentenversandEntryId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteBeVertragDokumentenversandEntryMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  dokumentenversandEntryId: Types.Scalars['ID']['input'];
}>;

export type DeleteBeVertragDokumentenversandEntryMutation = {
  deleteBeVertragDokumentenversandEntry: {
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateBeVertragDokumentenversandDocument = gql`
  mutation CreateBeVertragDokumentenversand($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!, $input: BeVertragDokumentenversandInput!) {
    createBeVertragDokumentenversandEntry(objektId: $objektId, bestandseinheitId: $bestandseinheitId, vertragId: $vertragId, input: $input) {
      data {
        dokumentenversandEntryId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateBeVertragDokumentenversandMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBeVertragDokumentenversandMutation, CreateBeVertragDokumentenversandMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBeVertragDokumentenversandMutation, CreateBeVertragDokumentenversandMutationVariables>(
    CreateBeVertragDokumentenversandDocument,
    options
  );
}
export type CreateBeVertragDokumentenversandMutationHookResult = ReturnType<typeof useCreateBeVertragDokumentenversandMutation>;
export type CreateBeVertragDokumentenversandMutationResult = Apollo.MutationResult<CreateBeVertragDokumentenversandMutation>;
export type CreateBeVertragDokumentenversandMutationOptions = Apollo.BaseMutationOptions<
  CreateBeVertragDokumentenversandMutation,
  CreateBeVertragDokumentenversandMutationVariables
>;
export const UpdateBeVertragDokumentenversandDocument = gql`
  mutation UpdateBeVertragDokumentenversand(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $dokumentenversandEntryId: ID!
    $input: BeVertragDokumentenversandInput!
  ) {
    updateBeVertragDokumentenversandEntry(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      dokumentenversandEntryId: $dokumentenversandEntryId
      input: $input
    ) {
      data {
        dokumentenversandEntryId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateBeVertragDokumentenversandMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBeVertragDokumentenversandMutation, UpdateBeVertragDokumentenversandMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBeVertragDokumentenversandMutation, UpdateBeVertragDokumentenversandMutationVariables>(
    UpdateBeVertragDokumentenversandDocument,
    options
  );
}
export type UpdateBeVertragDokumentenversandMutationHookResult = ReturnType<typeof useUpdateBeVertragDokumentenversandMutation>;
export type UpdateBeVertragDokumentenversandMutationResult = Apollo.MutationResult<UpdateBeVertragDokumentenversandMutation>;
export type UpdateBeVertragDokumentenversandMutationOptions = Apollo.BaseMutationOptions<
  UpdateBeVertragDokumentenversandMutation,
  UpdateBeVertragDokumentenversandMutationVariables
>;
export const DeleteBeVertragDokumentenversandEntryDocument = gql`
  mutation DeleteBeVertragDokumentenversandEntry($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!, $dokumentenversandEntryId: ID!) {
    deleteBeVertragDokumentenversandEntry(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      dokumentenversandEntryId: $dokumentenversandEntryId
    ) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteBeVertragDokumentenversandEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBeVertragDokumentenversandEntryMutation, DeleteBeVertragDokumentenversandEntryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBeVertragDokumentenversandEntryMutation, DeleteBeVertragDokumentenversandEntryMutationVariables>(
    DeleteBeVertragDokumentenversandEntryDocument,
    options
  );
}
export type DeleteBeVertragDokumentenversandEntryMutationHookResult = ReturnType<typeof useDeleteBeVertragDokumentenversandEntryMutation>;
export type DeleteBeVertragDokumentenversandEntryMutationResult = Apollo.MutationResult<DeleteBeVertragDokumentenversandEntryMutation>;
export type DeleteBeVertragDokumentenversandEntryMutationOptions = Apollo.BaseMutationOptions<
  DeleteBeVertragDokumentenversandEntryMutation,
  DeleteBeVertragDokumentenversandEntryMutationVariables
>;
