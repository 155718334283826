import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRechtstraegerInfoFeldPostItMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  rechtstraegerInfoFeldId: Types.Scalars['ID']['input'];
  input: Types.PostItInput;
}>;

export type CreateRechtstraegerInfoFeldPostItMutation = {
  createRechtstraegerInfoFeldPostIt: {
    data: { postItId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteRechtstraegerInfoFeldMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  infoFeldId: Types.Scalars['ID']['input'];
}>;

export type DeleteRechtstraegerInfoFeldMutation = {
  deleteRechtstraegerInfoFeld: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type UnarchiveRechtstraegerInfoFeldMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  infoFeldId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveRechtstraegerInfoFeldMutation = {
  actionUnarchiveRechtstraegerInfoFeld: {
    data: { rechtstraegerInfoFeldId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ArchiveRechtstraegerInfoFeldMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  infoFeldId: Types.Scalars['ID']['input'];
}>;

export type ArchiveRechtstraegerInfoFeldMutation = {
  actionArchiveRechtstraegerInfoFeld: {
    data: { rechtstraegerInfoFeldId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateRechtstraegerInfoFeldPostItDocument = gql`
  mutation CreateRechtstraegerInfoFeldPostIt($rechtstraegerId: ID!, $rechtstraegerInfoFeldId: ID!, $input: PostItInput!) {
    createRechtstraegerInfoFeldPostIt(rechtstraegerId: $rechtstraegerId, rechtstraegerInfoFeldId: $rechtstraegerInfoFeldId, input: $input) {
      data {
        postItId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateRechtstraegerInfoFeldPostItMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRechtstraegerInfoFeldPostItMutation, CreateRechtstraegerInfoFeldPostItMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRechtstraegerInfoFeldPostItMutation, CreateRechtstraegerInfoFeldPostItMutationVariables>(
    CreateRechtstraegerInfoFeldPostItDocument,
    options
  );
}
export type CreateRechtstraegerInfoFeldPostItMutationHookResult = ReturnType<typeof useCreateRechtstraegerInfoFeldPostItMutation>;
export type CreateRechtstraegerInfoFeldPostItMutationResult = Apollo.MutationResult<CreateRechtstraegerInfoFeldPostItMutation>;
export type CreateRechtstraegerInfoFeldPostItMutationOptions = Apollo.BaseMutationOptions<
  CreateRechtstraegerInfoFeldPostItMutation,
  CreateRechtstraegerInfoFeldPostItMutationVariables
>;
export const DeleteRechtstraegerInfoFeldDocument = gql`
  mutation DeleteRechtstraegerInfoFeld($rechtstraegerId: ID!, $infoFeldId: ID!) {
    deleteRechtstraegerInfoFeld(rechtstraegerId: $rechtstraegerId, infoFeldId: $infoFeldId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteRechtstraegerInfoFeldMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRechtstraegerInfoFeldMutation, DeleteRechtstraegerInfoFeldMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRechtstraegerInfoFeldMutation, DeleteRechtstraegerInfoFeldMutationVariables>(
    DeleteRechtstraegerInfoFeldDocument,
    options
  );
}
export type DeleteRechtstraegerInfoFeldMutationHookResult = ReturnType<typeof useDeleteRechtstraegerInfoFeldMutation>;
export type DeleteRechtstraegerInfoFeldMutationResult = Apollo.MutationResult<DeleteRechtstraegerInfoFeldMutation>;
export type DeleteRechtstraegerInfoFeldMutationOptions = Apollo.BaseMutationOptions<
  DeleteRechtstraegerInfoFeldMutation,
  DeleteRechtstraegerInfoFeldMutationVariables
>;
export const UnarchiveRechtstraegerInfoFeldDocument = gql`
  mutation UnarchiveRechtstraegerInfoFeld($rechtstraegerId: ID!, $infoFeldId: ID!) {
    actionUnarchiveRechtstraegerInfoFeld(rechtstraegerId: $rechtstraegerId, infoFeldId: $infoFeldId) {
      data {
        rechtstraegerInfoFeldId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveRechtstraegerInfoFeldMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveRechtstraegerInfoFeldMutation, UnarchiveRechtstraegerInfoFeldMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveRechtstraegerInfoFeldMutation, UnarchiveRechtstraegerInfoFeldMutationVariables>(
    UnarchiveRechtstraegerInfoFeldDocument,
    options
  );
}
export type UnarchiveRechtstraegerInfoFeldMutationHookResult = ReturnType<typeof useUnarchiveRechtstraegerInfoFeldMutation>;
export type UnarchiveRechtstraegerInfoFeldMutationResult = Apollo.MutationResult<UnarchiveRechtstraegerInfoFeldMutation>;
export type UnarchiveRechtstraegerInfoFeldMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveRechtstraegerInfoFeldMutation,
  UnarchiveRechtstraegerInfoFeldMutationVariables
>;
export const ArchiveRechtstraegerInfoFeldDocument = gql`
  mutation ArchiveRechtstraegerInfoFeld($rechtstraegerId: ID!, $infoFeldId: ID!) {
    actionArchiveRechtstraegerInfoFeld(rechtstraegerId: $rechtstraegerId, infoFeldId: $infoFeldId) {
      data {
        rechtstraegerInfoFeldId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveRechtstraegerInfoFeldMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveRechtstraegerInfoFeldMutation, ArchiveRechtstraegerInfoFeldMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveRechtstraegerInfoFeldMutation, ArchiveRechtstraegerInfoFeldMutationVariables>(
    ArchiveRechtstraegerInfoFeldDocument,
    options
  );
}
export type ArchiveRechtstraegerInfoFeldMutationHookResult = ReturnType<typeof useArchiveRechtstraegerInfoFeldMutation>;
export type ArchiveRechtstraegerInfoFeldMutationResult = Apollo.MutationResult<ArchiveRechtstraegerInfoFeldMutation>;
export type ArchiveRechtstraegerInfoFeldMutationOptions = Apollo.BaseMutationOptions<
  ArchiveRechtstraegerInfoFeldMutation,
  ArchiveRechtstraegerInfoFeldMutationVariables
>;
