import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import { FormikProps } from 'formik';
import TabPaneContent from '../../../../components/Tabs/TabPaneContent';
import AuftragsartUpdateParamForm from './ParameterForm/AuftragsartUpdateParamForm';
import AuftragsartUpdateTemplateForm from './Template/AuftragsartUpdateTemplateForm';
import { AuftragsartUpdateParamFormValues } from './ParameterForm/auftragsartUpdateParamFormMapper';
import { TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';
import { AuftragsartFieldsFragment } from '../../gql/AuftragsartFragments.types';
import { URI_SYS_SETTINGS } from '../../../../constants/configurationUriPaths';

type Props = {
  auftragsart: AuftragsartFieldsFragment;
  auftragsartTemplateValues: TextbausteinFormValues[];
  setAuftragsartTemplateValues: React.Dispatch<React.SetStateAction<TextbausteinFormValues[]>>;
  formikProps: FormikProps<AuftragsartUpdateParamFormValues>;
  onSubmit: (values: AuftragsartUpdateParamFormValues) => void;
};

const AuftragsartUpdateFormContent: FC<Props> = ({ auftragsart, formikProps, auftragsartTemplateValues, setAuftragsartTemplateValues, onSubmit }) => {
  const runOnSubmit = () => {
    onSubmit(formikProps.values);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Allgemeine Parameter',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <AuftragsartUpdateParamForm
            auftragsart={auftragsart}
            formikProps={formikProps}
            onSubmit={runOnSubmit}
            closePath={URI_SYS_SETTINGS.auftragsartPage}
          />
        </TabPaneContent>
      ),
    },
    {
      key: '2',
      label: auftragsart.type.text,
      children: (
        <AuftragsartUpdateTemplateForm
          auftragsart={auftragsart}
          auftragsartTemplateValues={auftragsartTemplateValues}
          setAuftragsartTemplateValues={setAuftragsartTemplateValues}
          onSubmit={runOnSubmit}
          closePath={URI_SYS_SETTINGS.auftragsartPage}
        />
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default AuftragsartUpdateFormContent;
