import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useSteuerpflichtListQuery } from './gql/SteuerpflichtQueries.types';
import { SteuerpflichtTuple } from '../../../types';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';

type Props = {
  fieldHelp?: string | null;
} & Omit<SelectProps, 'name' | 'id' | 'placeholder'>;

const SteuerpflichtSelect: FC<Props> = ({ fieldHelp, ...restProps }) => {
  const { loading, data } = useSteuerpflichtListQuery();
  const steuerpflichtList = data?.getSteuerpflichtList.data ?? [];

  return (
    <FormItemWithFieldHelp name="steuerpflicht" label="Steuerpflicht" fieldHelp={fieldHelp}>
      <Select id="steuerpflicht" name="steuerpflicht" placeholder="Steuerpflicht auswählen" showSearch {...restProps} loading={loading}>
        {steuerpflichtList.map((steuerpflicht: SteuerpflichtTuple) => (
          <Select.Option key={steuerpflicht.value} value={steuerpflicht.value}>
            {steuerpflicht.text}
          </Select.Option>
        ))}
      </Select>
    </FormItemWithFieldHelp>
  );
};

export default SteuerpflichtSelect;
