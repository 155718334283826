import { Dropdown, MenuProps } from 'antd';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ReadOutlined } from '@ant-design/icons';
import { uriFileOfFirmendaten } from '../documentApiPaths';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { FileInfo, Mahnung } from '../../../types';
import { generatePathToBookingDetailsPage } from '../../BookingDetails/bookingDetailsPaths';

type MahnungActionsProps = {
  record: FileInfo;
  mahnung: Mahnung;
};

const MahnungActions: FC<MahnungActionsProps> = ({ record, mahnung }) => {
  const { activeForFirmendatenId } = useFDAuthorized();

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Buchungsdetails ansehen',
      onClick: () =>
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        window.open(generatePathToBookingDetailsPage([mahnung.buchungId!])),
      disabled: !mahnung.buchungId,
      icon: <ReadOutlined />,
    },
  ];

  return (
    <>
      <Dropdown.Button menu={{ items }}>
        <Link to={uriFileOfFirmendaten(activeForFirmendatenId, record.fileId, record.name)} target="_blank">
          Öffnen
        </Link>
      </Dropdown.Button>
    </>
  );
};

export default MahnungActions;
