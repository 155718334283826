import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import FormButtons from '../../../components/Button/FormButtons';
import KSFirmendatenSelect from '../../KSFirmendatenSelect/KSFirmendatenSelect';
import { AbrechnungsdefinitionType, BudgetingAbrDefVPosLinkage } from '../../../types';
import { linkageFormValidationSchema } from './linkageFormValidationSchema';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import {
  useCreateBudgetingAbrDefVPosLinkageFirmendatenMutation,
  useCreateBudgetingAbrDefVPosLinkageKundenSystemMutation,
  useUpdateBudgetingAbrDefVPosLinkageMutation,
} from '../gql/budgetingAbrDefVPosLinkageMutations.types';
import { linkageFormFields, LinkageFormValues, mapFormValuesToLinkage, mapLinkageToFormValues } from './linkageFormMapper';
import AbrechnungsdefinitionSelect from '../../AbrechnungsdefinitionSelect/AbrechnungsdefinitionSelect';
import AbrKreisVPosLinkage from './AbrKreisVPosLinkage';
import ErrorMessagePanel from '../../../components/Error/ErrorMessagePanel';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';

type Props = {
  linkage?: BudgetingAbrDefVPosLinkage;
  linkageEntryList: BudgetingAbrDefVPosLinkage[];
  onSuccess: () => void;
  onCancel: () => void;
};

const LinkageForm: FC<Props> = ({ onCancel, linkageEntryList, linkage, onSuccess }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'BudgetingAbrDefVPosLinkage'>('BudgetingAbrDefVPosLinkage');

  const isUpdate = !!linkage;
  const [hasError, setHasError] = useState(false);

  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();

  const handleSuccess = (isUpdate = false) => {
    const entity = 'Verknüpfung';
    if (!isUpdate) {
      showSuccessMsgCreate(entity);
    } else {
      showSuccessMsgUpdate(entity);
    }
    onSuccess();
  };

  const [runCreateForFD, { loading: loadingCreateForFD }] = useCreateBudgetingAbrDefVPosLinkageFirmendatenMutation({
    onCompleted: () => {
      handleSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runCreateForKS, { loading: loadingCreateForKS }] = useCreateBudgetingAbrDefVPosLinkageKundenSystemMutation({
    onCompleted: () => {
      handleSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateBudgetingAbrDefVPosLinkageMutation({
    onCompleted: () => {
      handleSuccess(true);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const isLoading = loadingCreateForFD || loadingCreateForKS || loadingUpdate;

  return (
    <Formik<LinkageFormValues>
      initialValues={mapLinkageToFormValues(linkage)}
      validationSchema={linkageFormValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setHasError(false);
        if (combinationAlreadyExists(linkageEntryList, values, isUpdate, linkage?.budgetingAbrDefVPosLinkageId)) {
          setHasError(true);
          setSubmitting(false);
          return;
        }

        if (!isUpdate) {
          if (values.firmendatenId) {
            runCreateForFD({
              variables: {
                firmenDatenId: values.firmendatenId,
                input: mapFormValuesToLinkage(values),
              },
            }).finally(() => setSubmitting(false));
          } else {
            runCreateForKS({
              variables: {
                kundenSystemId,
                input: mapFormValuesToLinkage(values),
              },
            }).finally(() => setSubmitting(false));
          }
        } else {
          runUpdate({
            variables: {
              // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
              budgetingAbrDefVPosLinkageId: linkage!.budgetingAbrDefVPosLinkageId,
              input: mapFormValuesToLinkage(values),
            },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          {hasError && <ErrorMessagePanel error="Mit diesen Parametern existiert bereits ein Eintrag" />}
          <FormItemWithoutColon name={linkageFormFields.firmendatenId} label="Firma auswählen">
            <KSFirmendatenSelect
              size="middle"
              name={linkageFormFields.firmendatenId}
              disabled={isUpdate}
              placeholder={!isUpdate ? 'Firma' : 'Keine Firma'}
            />
          </FormItemWithoutColon>
          <FormItemWithFieldHelp
            name={linkageFormFields.abrechnungsdefinitionId}
            label="Abrechnungsdefinition"
            fieldHelp={getFieldHelpText('BudgetingAbrDefVPosLinkage.abrechnungsdefinitionId')}
          >
            <AbrechnungsdefinitionSelect
              disabled={isUpdate}
              allowClear={false}
              excludeTypes={[AbrechnungsdefinitionType.HeAbrechnung]}
              onAbrDefSelect={() => {}}
              name={linkageFormFields.abrechnungsdefinitionId}
            />
          </FormItemWithFieldHelp>

          {formikProps.values.abrechnungsdefinitionId && (
            <AbrKreisVPosLinkage
              isUpdate={isUpdate}
              key={formikProps.values.abrechnungsdefinitionId}
              abrechnungsdefinitionId={formikProps.values.abrechnungsdefinitionId}
              formikProps={formikProps}
              fieldHelp={getFieldHelpText('BudgetingAbrDefVPosLinkage.abrKreisVPosList')}
            />
          )}

          <FormButtons updateMode={isUpdate} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} isOkLoading={isLoading} />
        </Form>
      )}
    </Formik>
  );
};

const combinationAlreadyExists = (
  linkageEntryList: BudgetingAbrDefVPosLinkage[],
  values: LinkageFormValues,
  isUpdate: boolean,
  linkageId?: string
) => {
  return linkageEntryList.find((linkage) => {
    const isDuplicate = values.abrechnungsdefinitionId === linkage.abrechnungsdefinition.abrechnungsdefinitionId;

    if (!isUpdate) {
      return isDuplicate;
    } else {
      const isSame = linkageId === linkage.budgetingAbrDefVPosLinkageId;
      return isDuplicate && !isSame;
    }
  });
};

export default LinkageForm;
