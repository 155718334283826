import { FC } from 'react';
import HistoryListingTable from '../../../History/HistoryListingTable';
import { HistoryType } from '../../../History/shared/HistoryModal';
import { useObjektAufteilungsschluesselChangeHistoryListQuery } from '../gql/ObjektAufteilungsschluesselQueries.types';

type Props = {
  objektId: string;
  objektAufteilungsschluesselId: string;
};

const ObjektAufteliungsschluesselChangeHistoryListingTable: FC<Props> = ({ objektId, objektAufteilungsschluesselId }) => {
  const { data, loading } = useObjektAufteilungsschluesselChangeHistoryListQuery({ variables: { objektId, objektAufteilungsschluesselId } });
  const historyList = data?.getObjektAufteilungsschluesselChangeHistoryList.data;

  return (
    <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="objekt-aufteilungsschluessel" />
  );
};

export default ObjektAufteliungsschluesselChangeHistoryListingTable;
