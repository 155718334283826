import React from 'react';
import TicketTemplateTemplateMutationHookResultProvider from './TicketTemplateTemplateMutationHookResultProvider';
import { useTicketTemplateTemplateListQuery } from './gql/TicketTemplateTemplateQueries.types';
import TicketTemplateTemplateTable from './Table/TicketTemplateTemplateTable';
import TicketTemplateTemplateActionButtonsAndDrawer from './TicketTemplateTemplateActionButtonsAndDrawer';

const TicketTemplateTemplateListing = () => {
  const { data, loading, refetch } = useTicketTemplateTemplateListQuery();

  const ticketTemplateList = data?.getTicketTemplateTemplateList.data;

  return (
    <TicketTemplateTemplateMutationHookResultProvider refetch={refetch}>
      <TicketTemplateTemplateActionButtonsAndDrawer ticketTemplateList={ticketTemplateList} loading={loading} />
      <TicketTemplateTemplateTable ticketTemplateList={ticketTemplateList} loading={loading} />
    </TicketTemplateTemplateMutationHookResultProvider>
  );
};

export default TicketTemplateTemplateListing;
