const URI_APP_ROUTE = '/';

const URI_NOT_FOUND = '/404';

const uri = {
  home: '/',
  error: '/error',
  notFound: '/404',
  dashboard: '/dashboard',
};

const URI_DASHBOARD_WORKSPACE_PAGE = `${uri.dashboard}/workspace`;

const URI_DASHBOARD_CONTACTS = `${uri.dashboard}/contact`;
const URI_CONTACT_DETAILS_PAGE = `${URI_DASHBOARD_CONTACTS}/:rechtstraegerId`;

const URI_VERARBEITUNG_PAGE = `${uri.dashboard}/verarbeitungen`;

export { URI_APP_ROUTE, URI_NOT_FOUND, uri, URI_DASHBOARD_WORKSPACE_PAGE, URI_DASHBOARD_CONTACTS, URI_CONTACT_DETAILS_PAGE, URI_VERARBEITUNG_PAGE };
