import { Konto, KontoAbrechnungskreis } from '../../../types';
import { ZuordnungViewModel } from './AbrKreisKontenzuordnungHelpers';

export const mapToZuordnungViewModelList = (
  abrechnungsdefinitionId: string,
  kontoList: Konto[],
  zuordnungList: KontoAbrechnungskreis[]
): ZuordnungViewModel[] =>
  kontoList.map((konto) => {
    const zuordnung = zuordnungList.find((zuordnung) => zuordnung.konto.kontoId === konto.kontoId);
    if (zuordnung) {
      return {
        abrechnungsdefinitionId,
        abrechnungskreis: zuordnung.abrechnungskreis,
        aufteilungsschluessel: zuordnung.aufteilungsschluessel,
        expenseReducing: zuordnung.expenseReducing,
        konto,
        ustVomAufwand: zuordnung.ustVomAufwand,
        buchungszeilenAnzeigen: zuordnung.buchungszeilenAnzeigen,
      };
    } else {
      return { abrechnungsdefinitionId, konto, buchungszeilenAnzeigen: false, ustVomAufwand: false, expenseReducing: false };
    }
  });
