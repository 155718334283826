import React, { FC } from 'react';
import { Formik } from 'formik';
import { DatePicker, Form, Select } from 'formik-antd';
import { Col, Row, Typography } from 'antd';
import {
  heVertragFormFields,
  HeVertragFormValues,
  mapFormValuesToCreateHeVertrag,
  mapFormValuesToUpdateHeVertrag,
  mapHeVertragToFormValues,
} from './heVertragFormMapper';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { useCreateHeVertragMutation, useUpdateHeVertragMutation } from '../gql/HeVertragMutations.types';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../components/DatePicker/DatePicker_formikAntD';
import RechtstraegerSelect from '../../../../shared/Rechtstraeger/RechtstraegerSelect';
import { heVertragFormValidationSchema } from './heVertragFormValidationSchema';
import FormButtons from '../../../../components/Button/FormButtons';
import HeVertragSummeAnteileInput from './HeVertragSummeAnteileInput';
import DeliveryAddressSelect from '../../../shared/DeliveryAddressSelect';
import HeVertragSaldouebertrag from './HeVertragSaldouebertrag';
import { HeVertrag } from '../../../../types';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';

type Props = {
  objektId: string;
  onSuccess: () => void;
  onCancel: () => void;
  heVertrag?: HeVertrag;
  objektVerwaltungSeit: string;
};

const HeVertragForm: FC<Props> = ({ objektId, onSuccess, onCancel, heVertrag, objektVerwaltungSeit }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'HeVertrag'>('HeVertrag');

  const isUpdate = !!heVertrag;
  const entity = 'Hauseigentümervertrag';

  const [runCreate] = useCreateHeVertragMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate] = useUpdateHeVertragMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<HeVertragFormValues>
      initialValues={mapHeVertragToFormValues(heVertrag)}
      validationSchema={heVertragFormValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (isUpdate) {
          const input = mapFormValuesToUpdateHeVertrag(values);
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          runUpdate({ variables: { objektId, heVertragId: heVertrag!.heVertragId, input } }).finally(() => setSubmitting(false));
        } else {
          const input = mapFormValuesToCreateHeVertrag(values);
          runCreate({ variables: { objektId, input } }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Allgemein</Typography.Title>
            </Col>
            <Col span={18}>
              <Row align="top" gutter={8}>
                <Col span={24}>
                  <FormItemWithFieldHelp
                    name={heVertragFormFields.vertragsBeginn}
                    label="Vertragsbeginn"
                    fieldHelp={getFieldHelpText('HeVertrag.vertragsBeginn')}
                  >
                    <DatePicker
                      size="small"
                      id={heVertragFormFields.vertragsBeginn}
                      name={heVertragFormFields.vertragsBeginn}
                      style={{ width: '100%' }}
                      format={DATE_DISPLAY_FORMAT_DEFAULT}
                    />
                  </FormItemWithFieldHelp>
                </Col>
              </Row>
              <Row align="top" gutter={8}>
                <Col span={24}>
                  <FormItemWithFieldHelp
                    name={heVertragFormFields.vertragsEnde}
                    label="Vertragsende"
                    fieldHelp={getFieldHelpText('HeVertrag.vertragsEnde')}
                  >
                    <DatePicker
                      size="small"
                      id={heVertragFormFields.vertragsEnde}
                      name={heVertragFormFields.vertragsEnde}
                      style={{ width: '100%' }}
                      format={DATE_DISPLAY_FORMAT_DEFAULT}
                    />
                  </FormItemWithFieldHelp>
                </Col>
              </Row>
              <Row align="top">
                <Col span={24}>
                  <FormItemWithFieldHelp label="Merkmale" name={heVertragFormFields.tagList} fieldHelp={getFieldHelpText('HeVertrag.tagList')}>
                    <Select id={heVertragFormFields.tagList} name={heVertragFormFields.tagList} mode="tags" placeholder="z.B. WE, BK" />
                  </FormItemWithFieldHelp>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Vertragspartner</Typography.Title>
            </Col>
            <Col span={18}>
              <Row align="top" gutter={8}>
                <Col span={24}>
                  <FormItemWithFieldHelp
                    name={heVertragFormFields.vertragspartnerId}
                    label="Vertragspartner"
                    fieldHelp={getFieldHelpText('HeVertrag.vertragspartnerId')}
                  >
                    <RechtstraegerSelect
                      name={heVertragFormFields.vertragspartnerId}
                      disabled={isUpdate}
                      placeholder="Vertragspartner auswählen"
                      onNewItemCreated={(rechtstraegerId) => formikProps.setFieldValue(heVertragFormFields.vertragspartnerId, rechtstraegerId)}
                      onNewItemCreateSelected={() => formikProps.setFieldValue(heVertragFormFields.vertragspartnerId, '')}
                    />
                  </FormItemWithFieldHelp>
                </Col>
              </Row>
              <Row align="top" gutter={8}>
                <Col span={24}>
                  <FormItemWithFieldHelp
                    name={heVertragFormFields.deliveryAddressId}
                    label="Zustelladresse"
                    fieldHelp={getFieldHelpText('HeVertrag.deliveryAddressId')}
                  >
                    <DeliveryAddressSelect
                      name={heVertragFormFields.deliveryAddressId}
                      objektId={objektId}
                      rechtstraegerId={formikProps.values.vertragspartnerId}
                      disabled={!formikProps.values.vertragspartnerId}
                      onNewItemCreated={(addressId) => formikProps.setFieldValue(heVertragFormFields.deliveryAddressId, addressId)}
                      onNewItemCreateSelected={() => formikProps.setFieldValue(heVertragFormFields.deliveryAddressId, undefined)}
                    />
                  </FormItemWithFieldHelp>
                </Col>
              </Row>
              <Row align="top" gutter={8}>
                <Col span={24}>
                  <HeVertragSummeAnteileInput
                    formikProps={formikProps}
                    name={heVertragFormFields.anteile}
                    objektId={objektId}
                    getFieldHelpText={getFieldHelpText('HeVertrag.anteile')}
                  />
                </Col>
              </Row>
              <Row align="top" gutter={8}>
                <Col span={24}>
                  <FormItemWithFieldHelp
                    name={heVertragFormFields.saldouebertrag}
                    label="Saldoübertrag"
                    fieldHelp={getFieldHelpText('HeVertrag.saldouebertrag')}
                  >
                    <HeVertragSaldouebertrag
                      name={heVertragFormFields.saldouebertrag}
                      vertragsBeginn={formikProps.values.vertragsBeginn}
                      objektVerwaltungSeit={objektVerwaltungSeit}
                    />
                  </FormItemWithFieldHelp>
                </Col>
              </Row>
            </Col>
          </Row>
          <FormButtons updateMode={isUpdate} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default HeVertragForm;
