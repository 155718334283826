import {
  TDeviation,
  TDeviationCell,
  TDeviationGroupBySourceEntity,
  TDeviationGroupByUrsache,
  TDeviationListGroupedByUrsacheAndSourceEntity,
  TDeviationReference,
} from './deviation.model';
import { DeviationChangeTypeTuple, DeviationEntryType, DeviationType } from '../../types';
import {
  DeviationAttributeUriListFieldsFragment,
  DeviationDataFragment,
  DeviationEntryAttributeFieldsFragment,
  DeviationEntryFieldsFragment,
  DeviationLinkListFragment,
} from './gql/deviationFragments.types';
import {
  DEVIATION_CELL_EMPTY_VALUE,
  isDeviationAttributeAmount,
  isDeviationAttributeDate,
  isDeviationAttributeNumber,
  isDeviationAttributePercent,
  isDeviationAttributeString,
  isDeviationAttributeUri,
  isDeviationEntryTypeAlt,
} from './deviationHelpers';
import { allColumnsForDeviationType } from './deviationAllColumnsForDeviationTypeHelper';

const mapDeviationListResponseToGroupedList = (deviationList: DeviationDataFragment[]): TDeviationListGroupedByUrsacheAndSourceEntity[] => {
  return deviationList.reduce(groupDeviationListByUrsache, [] as TDeviationGroupByUrsache[]).map((deviationGroupByUrsache) => {
    return {
      changeType: deviationGroupByUrsache.changeType,
      ursache: deviationGroupByUrsache.ursache,
      deviationListGroupedBySourceEntity: deviationGroupByUrsache.deviationList
        .reduce(groupDeviationListBySourceEntity, [] as TDeviationGroupBySourceEntity[])
        .map((deviationGroupBySourceEntity) => ({
          sourceEntity: deviationGroupBySourceEntity.sourceEntity,
          changeType: deviationGroupBySourceEntity.changeType,
          deviationList: sortDeviationListByOrder(addMissingColumnsForDeviationType(mapDeviationListWithEmptyRows(deviationGroupBySourceEntity))),
        })),
    };
  });
};

const mapDeviationListWithEmptyRows = (deviationGroupBySourceEntity: TDeviationGroupBySourceEntity): TDeviation[] => {
  return deviationGroupBySourceEntity.deviationList.map((deviation) => {
    const mappedDeviation = mapAttributesToDeviationCells(deviation);
    return addEmptyRowIfNeeded(mappedDeviation);
  });
};

const sortDeviationListByOrder = (deviationList: TDeviation[]): TDeviation[] => {
  return deviationList.sort((a, b) => a.order - b.order);
};

const groupDeviationListByUrsache = (acc: TDeviationGroupByUrsache[], deviation: DeviationDataFragment): TDeviationGroupByUrsache[] => {
  let deviationByUrsache = acc.find((accDeviation) => accDeviation.ursache.value === deviation.ursache.value);
  if (!deviationByUrsache) {
    deviationByUrsache = {
      changeType: deviation.changeType,
      ursache: deviation.ursache,
      sourceEntity: deviation.sourceEntity,
      deviationList: [],
    };
    acc.push(deviationByUrsache);
  }
  deviationByUrsache.deviationList.push(deviation);
  return acc;
};

const groupDeviationListBySourceEntity = (
  acc: TDeviationGroupBySourceEntity[],
  deviation: DeviationDataFragment
): TDeviationGroupBySourceEntity[] => {
  let deviationBySourceEntity = acc.find((accDeviation) => accDeviation.sourceEntity.entityId === deviation.sourceEntity.entityId);
  if (!deviationBySourceEntity) {
    deviationBySourceEntity = {
      sourceEntity: deviation.sourceEntity,
      changeType: deviation.changeType,
      deviationList: [],
    };
    acc.push(deviationBySourceEntity);
  }
  deviationBySourceEntity.deviationList.push(deviation);
  return acc;
};

const mapAttributesToDeviationCells = (deviation: DeviationDataFragment): TDeviation => {
  const linkList = deviation.linkList ? mapLinks(deviation.linkList) : undefined;
  const mappedDeviation: TDeviation = {
    columns: [],
    order: deviation.order ?? 0,
    linkList,
    group: deviation.group ?? undefined,
    DeviationType: deviation.type,
  };

  deviation.entryList.forEach((entry) => {
    entry.attributeList.forEach((attribute) => {
      const foundColumn = mappedDeviation.columns.find((col) => col.columnId === attribute.attributeId);
      const isNewColumn = !foundColumn;
      if (isNewColumn) {
        mappedDeviation.columns.push(createColumn(deviation.changeType, entry, attribute, deviation.type));
      } else {
        foundColumn.cells.push(createCell(deviation.changeType, entry, attribute, deviation.type));
      }
    });
  });
  return mappedDeviation;
};

const createColumn = (
  changeType: DeviationChangeTypeTuple,
  DeviationEntry: DeviationEntryFieldsFragment,
  deviationAttribute: DeviationEntryAttributeFieldsFragment,
  DeviationType: DeviationType
) => ({
  columnId: deviationAttribute.attributeId,
  cells: [createCell(changeType, DeviationEntry, deviationAttribute, DeviationType)],
});

const createCell = (
  changeType: DeviationChangeTypeTuple,
  DeviationEntry: DeviationEntryFieldsFragment,
  deviationAttribute: DeviationEntryAttributeFieldsFragment,
  DeviationType: DeviationType
) => ({
  header: deviationAttribute.name,
  changeType,
  rowType: DeviationEntry.rowType.value,
  columnType: deviationAttribute.type,
  value: mapAttributeToValueBasedOnType(deviationAttribute),
  hasChanged: deviationAttribute.hasChanged,
  DeviationType,
});

const addEmptyRowIfNeeded = (deviationWithoutEmptyRows: TDeviation): TDeviation => {
  return {
    ...deviationWithoutEmptyRows,
    columns: deviationWithoutEmptyRows.columns.map((col) => {
      if (col.cells.length === 1) {
        const cellsLength = deviationWithoutEmptyRows.columns[0].cells.length;
        const firstCell = col.cells[0];
        let columnWithNewCell: TDeviationCell[];

        if (cellsLength > 1) {
          const newCells = new Array(cellsLength - 1).fill(createNewEmptyCell(firstCell));
          columnWithNewCell = firstCell.rowType === DeviationEntryType.Alt ? [...newCells, firstCell] : [firstCell, ...newCells];
        } else {
          const newCell = createNewEmptyCell(firstCell);
          columnWithNewCell = firstCell.rowType === DeviationEntryType.Alt ? [newCell, firstCell] : [firstCell, newCell];
        }

        return {
          ...col,
          cells: columnWithNewCell,
        };
      } else return col;
    }),
  };
};

const createNewEmptyCell = (cell: TDeviationCell): TDeviationCell => ({
  header: cell.header,
  changeType: cell.changeType,
  rowType: isDeviationEntryTypeAlt(cell.rowType) ? DeviationEntryType.Neu : DeviationEntryType.Alt,
  columnType: cell.columnType,
  value: DEVIATION_CELL_EMPTY_VALUE,
  hasChanged: cell.hasChanged,
  DeviationType: cell.DeviationType,
});

const mapAttributeToValueBasedOnType = (attribute: DeviationEntryAttributeFieldsFragment): string | number | TDeviationReference[] => {
  let value;
  if (isDeviationAttributeString(attribute)) {
    value = attribute.valueString;
  }
  if (isDeviationAttributeDate(attribute)) {
    value = attribute.valueDate;
  }
  if (isDeviationAttributeAmount(attribute)) {
    value = attribute.valueFloat;
  }
  if (isDeviationAttributeUri(attribute)) {
    value = mapLinks(attribute.uriList);
  }
  if (isDeviationAttributePercent(attribute)) {
    value = attribute.valuePercent;
  }
  if (isDeviationAttributeNumber(attribute)) {
    value = attribute.valueFloat;
  }
  return value ?? DEVIATION_CELL_EMPTY_VALUE;
};

const mapLinks = (links: DeviationLinkListFragment[] | DeviationAttributeUriListFieldsFragment[]) =>
  links.map((uri) => ({
    text: uri.text,
    uriParams: uri.ids,
  }));

/*************
 * Relevant for deviations which have same DeviationType and different amount of columns
 *
 * Example:
 * Deviation 1: columns: [col1, col2, col4, col5]
 * Deviation 2: columns: [col1, col2, col3]
 *
 * Result:
 *  Deviation 1 : columns: [col1, col2, col3, col4, col5]
 *  Deviation 2 : columns: [col1, col2, col3, col4, col5]
 **************/
const addMissingColumnsForDeviationType = (deviationList: TDeviation[]) => {
  const allColumns = allColumnsForDeviationType(deviationList);

  return deviationList.map((deviation) => {
    const foundDeviationType = allColumns.find((column) => column.DeviationType === deviation.DeviationType);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const allColumnsForDeviation = foundDeviationType!.columns.map((column) => {
      const foundColumn = deviation.columns.find((deviationColumn) => deviationColumn.columnId === column.columnId);
      return foundColumn ?? column;
    });

    return { ...deviation, columns: allColumnsForDeviation };
  });
};

export { mapDeviationListResponseToGroupedList, addMissingColumnsForDeviationType };
