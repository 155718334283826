import React, { FC } from 'react';
import { Skeleton, Typography } from 'antd';

import MultipleTexts from '../../../components/Text/MultipleTexts';
import { FibuBuchungszeile, Konto } from '../../../types';
import { useFibuBuchungszeileListQuery } from '../../BookingJournal/gql/FibuBuchungszeileQueries.types';

type KontoblattTableColumnGegenKontoProps = {
  buchungId: string;
  gegenKonto: Konto | undefined | null;
};

const KontoblattTableColumnGegenKonto: FC<KontoblattTableColumnGegenKontoProps> = ({ buchungId, gegenKonto }) => {
  const { data, loading } = useFibuBuchungszeileListQuery({
    variables: {
      buchungId,
    },
    skip: !!gegenKonto,
  });
  const buchungszeileList = data?.getFibuBuchungszeileList.data.contentList ?? [];
  const uniqueGegenKontoNummerList = getUniqueGegenKontoNummerList(buchungszeileList);

  if (gegenKonto) {
    return <Typography.Text>{gegenKonto.nummer}</Typography.Text>;
  }

  return uniqueGegenKontoNummerList.length > 0 ? (
    <Skeleton paragraph={false} active loading={loading}>
      <MultipleTexts texts={uniqueGegenKontoNummerList} maxFirstTextLength={30} />
    </Skeleton>
  ) : (
    <Typography.Text>-</Typography.Text>
  );
};

const getUniqueGegenKontoNummerList = (buchungszeileList: FibuBuchungszeile[]) => {
  return buchungszeileList
    .filter((buchungszeile) => !!buchungszeile.gegenKonto)
    .map((buchungszeile) => buchungszeile.konto.nummer)
    .reduce(distinct, [] as string[]);
};

const distinct = (uniqueKontoNummerList: string[], currentKontoNummer: string) => {
  if (uniqueKontoNummerList.includes(currentKontoNummer)) {
    return uniqueKontoNummerList;
  } else {
    return [...uniqueKontoNummerList, currentKontoNummer];
  }
};

export default KontoblattTableColumnGegenKonto;
