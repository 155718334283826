import { FC } from 'react';
import { Space } from 'antd';
import KontoWithQuery from '../../../shared/components/Konto/KontoWithQuery';
import { UstVomAufwand } from '../../../types';

type Props = {
  ustVomAufwand: UstVomAufwand;
  objektId: string;
};

const RepFondsKontoList: FC<Props> = ({ ustVomAufwand, objektId }) => {
  const repFondsKontoList = ustVomAufwand.ustVomAufwandVertragList
    .map((vertrag) => vertrag.repFondsKontoList.map((repFond) => repFond.repFondsKontoId))
    .flat();

  const uniqueRepFondsKontoList = Array.from(new Set(repFondsKontoList));

  return (
    <Space direction="vertical">
      {uniqueRepFondsKontoList.map((kontoId) => (
        <KontoWithQuery key={kontoId} kontoId={kontoId} objektId={objektId} />
      ))}
    </Space>
  );
};

export default RepFondsKontoList;
