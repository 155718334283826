import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateIndexSeriesEntryMutationVariables = Types.Exact<{
  indexSeriesId: Types.Scalars['ID']['input'];
  input: Types.IndexSeriesEntryCreateInput;
}>;

export type CreateIndexSeriesEntryMutation = {
  createIndexSeriesEntry: {
    data: { indexSeriesEntryId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateIndexSeriesEntryMutationVariables = Types.Exact<{
  indexSeriesId: Types.Scalars['String']['input'];
  indexSeriesEntryId: Types.Scalars['ID']['input'];
  input: Types.IndexSeriesEntryUpdateInput;
}>;

export type UpdateIndexSeriesEntryMutation = {
  updateIndexSeriesEntry: {
    data: { indexSeriesEntryId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteIndexSeriesEntryMutationVariables = Types.Exact<{
  indexSeriesId: Types.Scalars['String']['input'];
  indexSeriesEntryId: Types.Scalars['ID']['input'];
}>;

export type DeleteIndexSeriesEntryMutation = {
  deleteIndexSeriesEntry: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateIndexSeriesEntryDocument = gql`
  mutation CreateIndexSeriesEntry($indexSeriesId: ID!, $input: IndexSeriesEntryCreateInput!) {
    createIndexSeriesEntry(indexSeriesId: $indexSeriesId, input: $input) {
      data {
        indexSeriesEntryId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateIndexSeriesEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateIndexSeriesEntryMutation, CreateIndexSeriesEntryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateIndexSeriesEntryMutation, CreateIndexSeriesEntryMutationVariables>(CreateIndexSeriesEntryDocument, options);
}
export type CreateIndexSeriesEntryMutationHookResult = ReturnType<typeof useCreateIndexSeriesEntryMutation>;
export type CreateIndexSeriesEntryMutationResult = Apollo.MutationResult<CreateIndexSeriesEntryMutation>;
export type CreateIndexSeriesEntryMutationOptions = Apollo.BaseMutationOptions<
  CreateIndexSeriesEntryMutation,
  CreateIndexSeriesEntryMutationVariables
>;
export const UpdateIndexSeriesEntryDocument = gql`
  mutation UpdateIndexSeriesEntry($indexSeriesId: String!, $indexSeriesEntryId: ID!, $input: IndexSeriesEntryUpdateInput!) {
    updateIndexSeriesEntry(indexSeriesId: $indexSeriesId, indexSeriesEntryId: $indexSeriesEntryId, input: $input) {
      data {
        indexSeriesEntryId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateIndexSeriesEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateIndexSeriesEntryMutation, UpdateIndexSeriesEntryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateIndexSeriesEntryMutation, UpdateIndexSeriesEntryMutationVariables>(UpdateIndexSeriesEntryDocument, options);
}
export type UpdateIndexSeriesEntryMutationHookResult = ReturnType<typeof useUpdateIndexSeriesEntryMutation>;
export type UpdateIndexSeriesEntryMutationResult = Apollo.MutationResult<UpdateIndexSeriesEntryMutation>;
export type UpdateIndexSeriesEntryMutationOptions = Apollo.BaseMutationOptions<
  UpdateIndexSeriesEntryMutation,
  UpdateIndexSeriesEntryMutationVariables
>;
export const DeleteIndexSeriesEntryDocument = gql`
  mutation DeleteIndexSeriesEntry($indexSeriesId: String!, $indexSeriesEntryId: ID!) {
    deleteIndexSeriesEntry(indexSeriesId: $indexSeriesId, indexSeriesEntryId: $indexSeriesEntryId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteIndexSeriesEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteIndexSeriesEntryMutation, DeleteIndexSeriesEntryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteIndexSeriesEntryMutation, DeleteIndexSeriesEntryMutationVariables>(DeleteIndexSeriesEntryDocument, options);
}
export type DeleteIndexSeriesEntryMutationHookResult = ReturnType<typeof useDeleteIndexSeriesEntryMutation>;
export type DeleteIndexSeriesEntryMutationResult = Apollo.MutationResult<DeleteIndexSeriesEntryMutation>;
export type DeleteIndexSeriesEntryMutationOptions = Apollo.BaseMutationOptions<
  DeleteIndexSeriesEntryMutation,
  DeleteIndexSeriesEntryMutationVariables
>;
