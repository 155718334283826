import React, { FC } from 'react';
import { Routes } from 'react-router-dom';
import { Layout } from 'antd';
import { Route } from 'react-router';
import { ContentTabs } from '../LayoutAndromeda/Styled/Layout.style';
import ContextSidebar, { ContextMenuItemInterface, MenuItemInterface } from './ContextSidebar';

export interface GroupedMenueListInterface {
  topMenuList?: MenuItemInterface[];
  contextMenuList?: ContextMenuItemInterface[];
}

type ContentWithSidebarProps = {
  menuList: GroupedMenueListInterface;
  /**
   * provide only selectedMenuItem from menuList.topMenuList (defaultSelected from contextMenu is not supported)
   */
  defaultSelected?: string;
  onCardCollapse: () => void;
  isCardCollapsed: boolean;
  contextList?: string[];
};

const ContentWithSidebar: FC<ContentWithSidebarProps> = ({
  menuList = { topMenuList: [], contextMenuList: [] },
  defaultSelected,
  onCardCollapse,
  isCardCollapsed,
  contextList,
  ...restProps
}) => {
  const topMenuList = menuList.topMenuList ?? [];
  const contextMenuList = menuList.contextMenuList ?? [];
  const selected = defaultSelected || topMenuList.length > 0 ? topMenuList[0].title : undefined;
  const defaultSelectedMenu = topMenuList.find((menu) => menu.title === selected);

  return (
    <Layout {...restProps} data-testid="details-content">
      <Layout.Sider width={48} defaultCollapsed theme="light">
        <ContextSidebar
          defaultSelected={selected}
          onCardCollapse={onCardCollapse}
          isCardCollapsed={isCardCollapsed}
          contextList={contextList}
          topMenuList={topMenuList}
          contextMenuList={contextMenuList}
        />
      </Layout.Sider>
      <ContentTabs>
        <Routes>
          {topMenuList.map((item) => {
            return <Route key={item.title} path={`${getLastPathSegment(item.uri)}/*`} element={item.content({}, isCardCollapsed)} />;
          })}
          {contextMenuList.map((item) => {
            if (item.contextKey.some((key) => contextList?.includes(key))) {
              return <Route key={item.title} path={`${getLastPathSegment(item.uri)}/*`} element={item.content({}, isCardCollapsed)} />;
            }
            return null;
          })}

          {defaultSelectedMenu && <Route key={defaultSelectedMenu.title} index element={defaultSelectedMenu.content({}, isCardCollapsed)} />}
        </Routes>
      </ContentTabs>
    </Layout>
  );
};

const getLastPathSegment = (url: string): string => {
  const segments = url.split('/');
  return segments[segments.length - 1];
};

export default ContentWithSidebar;
