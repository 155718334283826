import { generatePath } from 'react-router';
import { WizardDetails } from './wizardHelpers';

export const URI_WIZARD_PAGE = '/dashboard/wizards';

export const URI_WIZARD_UPDATE_PAGE = `/dashboard/wizards/:type/:wizardId/:step`;

export const generatePathToWizardUpdatePage = (type: WizardDetails, wizardId: string, step = 1) => {
  return generatePath(URI_WIZARD_UPDATE_PAGE, { type: type.uri, wizardId, step: `step${step}` });
};
