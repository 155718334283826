import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { OrderGenerierlaufType } from '../../../../../types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import { useFilterGenLaufOrderRecipientListQuery } from '../../../gql/GenerierlaufFilter/OrderGenerierlaufFilterQueries.types';

type Props = {
  name: string;
  generierlaufId: string;
  generierlaufType: OrderGenerierlaufType;
  maxTextLength?: number;
} & SelectProps;

const GenerierlaufRecipientFormSelect: FC<Props> = ({ name, generierlaufId, generierlaufType, maxTextLength, ...restProps }) => {
  const { data, loading } = useFilterGenLaufOrderRecipientListQuery({
    variables: {
      generierlaufId,
      generierlaufType,
    },
  });

  const list = data?.getFilterGenLaufOrderRecipientList.data ?? [];

  return (
    <Select
      size="small"
      loading={loading}
      id={name}
      name={name}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      placeholder="Empfänger auswählen"
      {...restProps}
    >
      {list.map((recipient) => (
        <Select.Option key={recipient.rechtstraegerId} value={recipient.rechtstraegerId}>
          <DataWithShortenedText maxTextLength={maxTextLength ?? recipient.kurzBezeichnung.length} text={recipient.kurzBezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default GenerierlaufRecipientFormSelect;
