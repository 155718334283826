import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { MitarbeiterBaseFieldsFragmentDoc } from '../../../Mitarbeiter/gql/MitarbeiterFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MitarbeiterTicketCountListQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  dueDate?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type MitarbeiterTicketCountListQuery = {
  getMitarbeiterTicketCountList: {
    data: Array<{
      ticketCount?: number | null;
      mitarbeiter: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firstname: string;
        lastname: string;
        mitarbeiterId: string;
        updatedByMitarbeiterId?: string | null;
        firmendatenBaseData: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firmendatenId: string;
          kundenSystemId: string;
          kurzBezeichnung: string;
          name1: string;
          name2?: string | null;
          name3?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const MitarbeiterTicketCountListDocument = gql`
  query MitarbeiterTicketCountList($firmendatenId: String!, $dueDate: String) {
    getMitarbeiterTicketCountList(firmendatenId: $firmendatenId, dueDate: $dueDate) {
      data {
        mitarbeiter {
          ...MitarbeiterBaseFields
        }
        ticketCount
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${MitarbeiterBaseFieldsFragmentDoc}
`;
export function useMitarbeiterTicketCountListQuery(
  baseOptions: Apollo.QueryHookOptions<MitarbeiterTicketCountListQuery, MitarbeiterTicketCountListQueryVariables> &
    ({ variables: MitarbeiterTicketCountListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MitarbeiterTicketCountListQuery, MitarbeiterTicketCountListQueryVariables>(MitarbeiterTicketCountListDocument, options);
}
export function useMitarbeiterTicketCountListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MitarbeiterTicketCountListQuery, MitarbeiterTicketCountListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MitarbeiterTicketCountListQuery, MitarbeiterTicketCountListQueryVariables>(MitarbeiterTicketCountListDocument, options);
}
export function useMitarbeiterTicketCountListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MitarbeiterTicketCountListQuery, MitarbeiterTicketCountListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MitarbeiterTicketCountListQuery, MitarbeiterTicketCountListQueryVariables>(
    MitarbeiterTicketCountListDocument,
    options
  );
}
export type MitarbeiterTicketCountListQueryHookResult = ReturnType<typeof useMitarbeiterTicketCountListQuery>;
export type MitarbeiterTicketCountListLazyQueryHookResult = ReturnType<typeof useMitarbeiterTicketCountListLazyQuery>;
export type MitarbeiterTicketCountListSuspenseQueryHookResult = ReturnType<typeof useMitarbeiterTicketCountListSuspenseQuery>;
export type MitarbeiterTicketCountListQueryResult = Apollo.QueryResult<MitarbeiterTicketCountListQuery, MitarbeiterTicketCountListQueryVariables>;
