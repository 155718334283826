import React from 'react';
import { Route } from 'react-router';
import { isAdmin } from '../../../../security/permissionChecks';
import AuthRoute from '../../../../security/AuthRoute';
import AndromedaSysSettingsBudgetingVerknuepfungAbrDefVPosPage from './AndromedaSysSettingsBudgetingVerknuepfungAbrDefVPosPage';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';

const andromedaSysSettingsBudgetingVerknuepfungAbrDefVPosPageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS.budgetingVerknuepfungAbrDefVPosPage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsBudgetingVerknuepfungAbrDefVPosPage />} />}
  />
);

export default andromedaSysSettingsBudgetingVerknuepfungAbrDefVPosPageRoute;
