import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../shared/dataChangedSubscription';
import { DataChangedChangeType } from '../../types';

const useOnEmailDeliveryListDataChangedEvents = (
  key: string,
  callback: () => Promise<unknown>,
  firmendatenId: string,
  mailId?: string | string[]
) => {
  useOnDataChangedEvent([
    {
      filter: {
        type: dataChangedSubscriptionTypes.mail,
        changeType: DataChangedChangeType.Create,
        params: {
          firmendatenId,
        },
      },
      notificationDescription: 'E-mail wurde gerade erstellt',
      key: `${key}-create`,
      callback,
    },
    {
      filter: {
        type: dataChangedSubscriptionTypes.mail,
        changeType: DataChangedChangeType.Update,
        params: {
          mailId,
          firmendatenId,
        },
      },
      notificationDescription: 'E-mail wurde gerade verändert',
      key: `${key}-update`,
      callback,
    },
  ]);
};

const useOnEmailDeliveryDetailsDataChangedEvents = (key: string, callback: () => Promise<unknown>, mailId: string) => {
  useOnDataChangedEvent([
    {
      filter: {
        type: dataChangedSubscriptionTypes.mail,
        changeType: DataChangedChangeType.Update,
        params: {
          mailId,
        },
      },
      notificationDescription: 'E-mail wurde gerade verändert',
      key: `${key}-update`,
      callback,
    },
  ]);
};

export { useOnEmailDeliveryListDataChangedEvents, useOnEmailDeliveryDetailsDataChangedEvents };
