import React, { FC, useEffect, useState } from 'react';
import { Progress } from 'antd';
import { isNotificationStatusFinishedError, mapStatusToStatusSpecs } from './notificationHelpers';
import { Notification, NotificationCategory, PubSubNotification } from '../../../types';

type Props = { notification: Notification | PubSubNotification; style?: any };

const TaskProgress: FC<Props> = ({ notification, style }) => {
  const [currentProcessed, setCurrentProcessed] = useState<number>(notification.progress.processed ?? 0);
  const statusIcon = mapStatusToStatusSpecs(notification.status);

  useEffect(() => {
    setCurrentProcessed(notification.progress.processed);
  }, [notification.progress.processed, currentProcessed, setCurrentProcessed]);

  const percent = isNotificationStatusFinishedError(notification.status) ? 100 : Math.trunc((currentProcessed / notification.progress.total) * 100);

  return notification.notificationCategory === NotificationCategory.Task ? (
    <Progress
      strokeColor={statusIcon.backgroundColor}
      style={style ?? { paddingTop: '12px', width: '100%' }}
      percent={percent}
      status={statusIcon.progressStatus}
    />
  ) : null;
};

export default TaskProgress;
