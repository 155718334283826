import React from 'react';
import { Link } from 'react-router-dom';
import { compareTwoStringsForSorting } from '../../../../helpers/stringHelper';
import GenerierlaufEntryStatus from '../../shared/GenerierlaufEntryStatus';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { compareTwoNumbersForSorting } from '../../../../helpers/numberHelper';
import { EuroAmount } from '../../../../components/Number';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import DocumentStatusTag from '../../../../components/Status/DocumentStatus/DocumentStatusTag';
import { generatePathToUstVomAufwandDetailsPage } from '../../../UstVomAufwand/ustVomAufwandUriPaths';
import { compareTwoDatesForSorting } from '../../../../helpers/dateHelper';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { UstVomAufwandGenerierlaufEntry } from '../../../../types';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

const textWithNaValue = <TextForEmptyValue textToShow="NV" />;

const ustVomAufwandVerarbeitungEntryTableColumns: TableWithColSelectorColumnProps<UstVomAufwandGenerierlaufEntry>[] = [
  {
    title: 'Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.exitCode?.text, b.exitCode?.text),
    render: (_, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },
  {
    title: 'Monat',
    defaultSelected: true,
    sorter: (a, b) => compareTwoDatesForSorting(a.monat, b.monat),
    render: (text, record) => <CustomFormattedDate year="numeric" month="2-digit" value={record.monat} />,
  },
  {
    title: 'Objekt',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.objekt.kurzBezeichnung, b.objekt.kurzBezeichnung),
    render: (text, record) => <DataWithShortenedText text={record.objekt.kurzBezeichnung} maxTextLength={30} />,
  },

  {
    title: 'ER Netto Summe',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.ustVomAufwand?.sumNetto, b.ustVomAufwand?.sumNetto),
    render: (text, record) => (record.ustVomAufwand ? <EuroAmount value={record.ustVomAufwand.sumNetto} /> : textWithNaValue),
  },
  {
    title: 'Ust. vom Aufwand Summe',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.ustVomAufwand?.sumUstVomAufwand, b.ustVomAufwand?.sumUstVomAufwand),
    render: (text, record) => (record.ustVomAufwand ? <EuroAmount value={record.ustVomAufwand.sumUstVomAufwand} /> : textWithNaValue),
  },
  {
    title: 'Ust.-vom-Aufwand-Status',
    defaultSelected: true,
    width: 180,
    render: (text, record) => {
      if (record.ustVomAufwand) {
        return <DocumentStatusTag documentStatus={record.ustVomAufwand.buchungStatus} />;
      } else return textWithNaValue;
    },
  },
  {
    title: 'Erstellt am',
    render: (text, record) => (record.ustVomAufwand ? <FormattedDateTime createdTs={record.ustVomAufwand.createTs} /> : textWithNaValue),
  },
  {
    title: 'Ersteller',
    render: (text, record) =>
      record.ustVomAufwand ? (
        <MitarbeiterTooltip
          mitarbeiterId={record.ustVomAufwand.createdByMitarbeiterId}
          userId={record.ustVomAufwand.createdBy}
          alignment="left"
          showsInitials
        />
      ) : (
        textWithNaValue
      ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) =>
      record.ustVomAufwand ? (
        <Link to={generatePathToUstVomAufwandDetailsPage(record.objekt.objektId, record.ustVomAufwand.ustVomAufwandId)} target="_blank">
          Details
        </Link>
      ) : (
        <TextForEmptyValue textToShow="none" />
      ),
  },
];

export default ustVomAufwandVerarbeitungEntryTableColumns;
