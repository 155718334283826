import { FC } from 'react';
import { usePersonenbezugChangeHistorListQuery } from '../../gql/PersonenbezugQueries.types';
import { HistoryType } from '../../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../../History/HistoryListingTable';

type Props = {
  rechtstraegerId: string;
  personenbezugId: string;
};

const PersonenbezugChangeHistoryListingTable: FC<Props> = ({ rechtstraegerId, personenbezugId }) => {
  const { data, loading } = usePersonenbezugChangeHistorListQuery({ variables: { rechtstraegerId, personenbezugId } });
  const historyList = data?.getPersonenbezugChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="personenbuzug" />;
};

export default PersonenbezugChangeHistoryListingTable;
