import { FieldPolicy } from '@apollo/client';
import { ErrorData, Page } from '../types';

type EasyCloudResponseWithPagination = {
  data: {
    contentList: unknown[];
    page: Page;
  };
  errorList: ErrorData[];
  warningList: ErrorData[];
};

type KeyArgs = FieldPolicy['keyArgs'];

/** is used for Infinite Lists for RechtstraegerContactList */
const easyCloudOffsetLimitPaginationForRechtstraegerContactList = (keyArgs: KeyArgs = false): FieldPolicy<EasyCloudResponseWithPagination> => ({
  keyArgs,
  merge(existing, incoming, { args }) {
    // NOTE: if pageSize changed during pagination it would not work probably right. But it is used for infinite lists hence changing pageSize during
    // pagination (scrolling) is not foreseen
    const offset = (page: number, pageSize: number) => page * pageSize;

    // filter is used for search
    const filter = args?.filter;

    const incomingContentList = incoming.data.contentList;
    // Don't merge existing data if filter is applied otherwise it will merge filter results with existing data
    const mergedContentList = existing && !filter ? existing.data.contentList.slice(0) : [];
    const start = args ? offset(args.page, args.pageSize) : mergedContentList.length;
    const end = start + incoming.data.contentList.length;

    for (let i = start; i < end; i += 1) {
      mergedContentList[i] = incomingContentList[i - start];
    }

    return {
      data: {
        contentList: mergedContentList,
        page: incoming.data.page,
      },
      errorList: incoming.errorList,
      warningList: incoming.warningList,
    };
  },
});

export default easyCloudOffsetLimitPaginationForRechtstraegerContactList;
