import { FC } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Space, Tag, Typography } from 'antd';
import { chunk } from 'lodash';
import InfoFeldListItem from '../InfoFeldListItem';
import PersoneninfosListItemActions from './PersoneninfosListItemActions';
import { RechtstraegerInfoFeldFragment } from '../../../gql/rechtstraegerInfoFeldFragments.types';

type Props = {
  personeninfos: RechtstraegerInfoFeldFragment;
  rechtstraegerId: string;
  onSuccess: () => void;
};

const PersoneninfosListItem: FC<Props> = ({ personeninfos, rechtstraegerId, onSuccess }) => {
  return (
    <InfoFeldListItem
      icon={<UserOutlined />}
      content={{ fileIdList: personeninfos.fileIdList, props: { direction: 'vertical' } }}
      actions={<PersoneninfosListItemActions infoFeld={personeninfos} rechtstraegerId={rechtstraegerId} onSuccess={onSuccess} />}
    >
      <Typography.Text type="secondary">Personeninfos</Typography.Text>
      <Space>
        <Space direction="vertical">
          {chunk(personeninfos.merkmalList, 6).map((merkmal, index) => (
            <Space key={index}>
              {merkmal.map((a) => (
                <Tag key={a}>{a}</Tag>
              ))}
            </Space>
          ))}
        </Space>
      </Space>
    </InfoFeldListItem>
  );
};

export default PersoneninfosListItem;
