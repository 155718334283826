import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { useFilterPaymentClearingVertragspartnerListQuery } from '../../gql/PaymentClearingQueries.types';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = Omit<SelectProps, 'id' | 'placeholder' | 'size' | 'loading' | 'allowClear' | 'showSearch' | 'filterOption'>;

const PaymentClearingVertragspartnerListFormSelect: FC<Props> = ({ name, ...restProps }) => {
  const { data, loading } = useFilterPaymentClearingVertragspartnerListQuery();

  const list = data?.getFilterPaymentClearingVertragspartnerList.data ?? [];

  return (
    <Select
      size="small"
      placeholder="Vertragspartner auswählen"
      loading={loading}
      name={name}
      id={name}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      {...restProps}
    >
      {list.map((vertragspartner) => (
        <Select.Option key={vertragspartner.rechtstraegerId} value={vertragspartner.rechtstraegerId}>
          <DataWithShortenedText maxTextLength={40} text={vertragspartner.kurzBezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default PaymentClearingVertragspartnerListFormSelect;
