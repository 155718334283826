import React, { FC, useState } from 'react';
import { Affix, List } from 'antd';
import { PaginationConfig } from 'antd/lib/pagination';
import CallLogComment from './CallLogComment';
import { CallLog } from '../../../types';
import CallLogUpdateForm from './Form/CallLogUpdateForm';
import { useCallLogListQuery } from '../../CallLog/gql/CallLogQueries.types';

type Props = {
  rechtstraegerId: string;
};

const CallLogListing: FC<Props> = ({ rechtstraegerId }) => {
  const [pagination, setPagination] = useState({ page: 0, pageSize: 10 });
  const [callLogToBeEdited, setCallLogToBeEdited] = useState<CallLog>();

  const { data, loading, refetch } = useCallLogListQuery({
    variables: {
      rechtstraegerId,
      ...pagination,
    },
  });

  const callLogList = data?.getCallLogList.data.contentList ?? [];
  const page = data?.getCallLogList.data.page;

  const onSuccess = () => {
    setCallLogToBeEdited(undefined);
    refetch();
  };

  const paginationProps: PaginationConfig | false =
    callLogList.length === 0
      ? false
      : {
          size: 'small',
          current: pagination.page + 1,
          pageSize: pagination.pageSize,
          total: page?.totalElements,
          showSizeChanger: true,
          onChange: (page: number, pageSize: number) => {
            setPagination({ page: page - 1, pageSize });
          },
          pageSizeOptions: [10, 20, 30, 50, 100],
        };

  return (
    <>
      <List<CallLog>
        size="small"
        loading={loading}
        dataSource={callLogList}
        pagination={paginationProps}
        itemLayout="horizontal"
        renderItem={(callLog) => (
          <List.Item>
            <CallLogComment rechtstraegerId={rechtstraegerId} callLog={callLog} onEdit={setCallLogToBeEdited} />
          </List.Item>
        )}
        locale={{
          emptyText: 'Keine Anrufprotokolle vorhanden',
        }}
      />
      {callLogToBeEdited && (
        <Affix offsetBottom={0}>
          <div
            style={{
              backgroundColor: '#fafafa',
              padding: '12px 20px',
              borderTop: '1px solid #f0f0f0',
              borderBlock: '16px solid #ffffff',
            }}
          >
            <CallLogUpdateForm
              rechtstraegerId={rechtstraegerId}
              callLog={callLogToBeEdited}
              onCancel={() => setCallLogToBeEdited(undefined)}
              onSuccess={onSuccess}
            />
          </div>
        </Affix>
      )}
    </>
  );
};

export default CallLogListing;
