import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { useAbrechnungsdefinitionTemplateQuery } from '../../../../../../features/Abrechnungsdefinition/gql/AbrDefQueries.types';
import { AbrechnungsdefinitionTemplateType } from '../../../../../../types';
import { findPrecedingVersionId, getFirmendatenId, hasPrecedingVersion, isPrecedingVersionAvailable } from './createPageHelpers';
import {
  useHeAbrechnungsdefinitionVersionQuery,
  useHeAbrechnungsdefinitionVersionListQuery,
} from '../../../../../../features/Abrechnungsdefinition/gql/HeAbrDef/HeAbrDefVersionQueries.types';
import HeAbrDefVersionCreateForm from '../../../../../../features/Abrechnungsdefinition/Version/Form/Create/HeAbrDefVersionCreateForm';

/*
When creating new version the forms have to be prefilled with the values of preceding version for the next available date from today
ex. 1: today: 01.01.2022., version 1: 01.01.2021. version 2: 01.01.2023 => preceding version: version 1
ex. 2: today: 01.01.2022., version 1: 01.01.2021. version 2: 02.01.2022 => preceding version: version 2
 */

type Props = {
  abrechnungsdefinitionId: string;
};
const SysSettingsHeAbrechnungsdefinitionVersionCreatePage: FC<Props> = ({ abrechnungsdefinitionId }) => {
  const { data: heAbrDefVersionListData } = useHeAbrechnungsdefinitionVersionListQuery({
    variables: { abrechnungsdefinitionId },
  });
  const versionList = heAbrDefVersionListData?.getHeAbrechnungsdefinitionVersionList.data;
  const precedingVersionId = versionList ? findPrecedingVersionId(versionList) : undefined;

  const { data: heAbrDefVersionData } = useHeAbrechnungsdefinitionVersionQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { abrechnungsdefinitionId, abrechnungsdefinitionVersionId: precedingVersionId! },
    skip: !precedingVersionId,
  });
  const precedingVersion = heAbrDefVersionData?.getHeAbrechnungsdefinitionVersion.data;

  const { data: templateHeData } = useAbrechnungsdefinitionTemplateQuery({
    variables: { type: AbrechnungsdefinitionTemplateType.ObjektAbrechnung },
    skip: isPrecedingVersionAvailable(versionList, precedingVersion),
  });
  const heAbrechnungTextbausteinList = templateHeData?.getAbrechnungsdefinitionTemplate.data.textbausteinList;

  // Show loading state until initial data is available: if there is a precedingVersion we get the textbausteinLists from it otherwise we have to load default templates
  if (
    !versionList ||
    (hasPrecedingVersion(versionList) && !precedingVersion) ||
    (hasPrecedingVersion(versionList) === false && !heAbrechnungTextbausteinList)
  ) {
    return <Skeleton active />;
  }

  return (
    <HeAbrDefVersionCreateForm
      abrechnungsdefinitionVersionList={versionList}
      precedingAbrechnungsdefinitionVersion={precedingVersion}
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      firmendatenId={getFirmendatenId(versionList)}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      heAbrechnungTextbausteinList={precedingVersion?.heAbrechnungTextbausteinList ?? heAbrechnungTextbausteinList!}
    />
  );
};

export default SysSettingsHeAbrechnungsdefinitionVersionCreatePage;
