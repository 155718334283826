import React, { FC, useRef } from 'react';
import { Popover } from 'antd';
import { TicketBase } from '../../../../types';
import { pathsToTicketDetailsPage } from '../../ticketUriPaths';
import TruncatedText from '../../../../components/Helpers/TruncatedText';
import { isTicketArchived } from '../../ticketHelpers';
import OutdatedData from '../../../../components/Helpers/OutdatedData';
import TicketPreviewTitle from './TicketPreviewTitle';
import TicketPreviewContent from './TicketPreviewContent';
import TicketPreviewLink from './TicketPreviewLink';

export type TicketPreviewProps = {
  ticket: TicketBase;
  openLinkInNewTab?: boolean;
  showAssignee?: boolean;
};

const TicketPreview: FC<TicketPreviewProps> = ({ ticket, openLinkInNewTab, showAssignee = true }) => {
  const { title } = ticket;
  const linkRef = useRef(null);

  return (
    <Popover
      destroyTooltipOnHide
      styles={{ root: { maxWidth: '300px' } }}
      placement="topLeft"
      title={<TicketPreviewTitle ticket={ticket} openLinkInNewTab={openLinkInNewTab} showAssignee={showAssignee} />}
      content={<TicketPreviewContent ticket={ticket} />}
    >
      <TicketPreviewLink ref={linkRef} to={pathsToTicketDetailsPage(ticket).overviewTab} openLinkInNewTab={openLinkInNewTab}>
        <OutdatedData isOutdated={isTicketArchived(ticket)}>
          <TruncatedText text={title} />
        </OutdatedData>
      </TicketPreviewLink>
    </Popover>
  );
};

export default TicketPreview;
