import React from 'react';
import { Route } from 'react-router';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../security/permissionChecks';

import AuthRoute from '../../../../security/AuthRoute';
import { URI_SYS_SETTINGS } from '../../../../constants/configurationUriPaths';
import SysSettingsVorschreibungspositionPage from './SysSettingsVorschreibungspositionPage';

const sysSettingsVorschreibungspositionPageRoute = (
  <Route
    path={URI_SYS_SETTINGS.vorschreibungspositionPage}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsVorschreibungspositionPage />} />}
  />
);

export default sysSettingsVorschreibungspositionPageRoute;
