import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { FiltersFormValues } from '../filtersFormMapper';
import FromToFormDatePicker from '../../../../shared/FromToForm/FromToFormDatePicker';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';

type Props = {
  name: string;
  label: string;
  fromName: string;
  toName: string;
  formikProps: FormikProps<FiltersFormValues>;
};

const DateSelectFormItem: FC<Props> = ({ formikProps, name, label, fromName, toName }) => {
  return (
    <FormItemWithoutColon name={name} label={label}>
      <FromToFormDatePicker
        format="day"
        fromName={fromName}
        toName={toName}
        fromOnChange={formikProps.submitForm}
        toOnChange={formikProps.submitForm}
      />
    </FormItemWithoutColon>
  );
};

export default DateSelectFormItem;
