import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import VstKuerzungKontenZuweisungTab from './VstKuerzungKontenZuweisungTab';
import { Verwaltungsart } from '../../../types';

const VorsteuerkuerzungKontenZuweisungListing: FC = () => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'WE-Objekt',
      children: <VstKuerzungKontenZuweisungTab verwaltungsart={Verwaltungsart.WegObjekt} />,
    },
    {
      key: '2',
      label: 'Miet-Objekt',
      children: <VstKuerzungKontenZuweisungTab verwaltungsart={Verwaltungsart.MietObjekt} />,
    },
  ];

  return <Tabs items={items} defaultActiveKey="1" destroyInactiveTabPane />;
};

export default VorsteuerkuerzungKontenZuweisungListing;
