import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import MahnsperreCard from './Mahnsperre/MahnsperreCard';
import { Firma, NatuerlichePerson, Personengemeinschaft } from '../../../../types';

type VertragspartnerProps = {
  rechtstraeger: Firma | NatuerlichePerson | Personengemeinschaft;
  onSuccess: () => void;
};

const Vertragspartner: FC<VertragspartnerProps> = ({ rechtstraeger, onSuccess }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Allgemeine Einstellungen',
      children: <MahnsperreCard rechtstraegerId={rechtstraeger.rechtstraegerId} onSuccess={onSuccess} />,
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
};

export default Vertragspartner;
