const URI_CONFIGURATION = '/dashboard/configuration';

const URI_SYS_SETTINGS = {
  // abrechnungKontenZuordnung: `${configuration}/abrechnungskontenzuordnung`,
  abrechnungKontenZuordnungPage: `${URI_CONFIGURATION}/abrechnungskontenzuordnung/:abrechnungsdefinitionId?/:kontoType?`,
  abrechnungsdefinitionPage: `${URI_CONFIGURATION}/abrechnungsdefinitionen`,
  abrechnungskreisPage: `${URI_CONFIGURATION}/abrechnungskreise`,
  allgemeineEinstellungPage: `${URI_CONFIGURATION}/allgemeine-einstellungen`,
  aufteilungsschluesselPage: `${URI_CONFIGURATION}/aufteilungsschluesselconfiguration`,
  aufgabenartPage: `${URI_CONFIGURATION}/aufgabenarten`,
  auftragsartPage: `${URI_CONFIGURATION}/auftragsarten`,
  budgetingVerknuepfungAbrDefVPosPage: `${URI_CONFIGURATION}/budgetierung-verknuepfung-abrdef-vpos`,
  budgetingVerknuepfungVPosAuftSchluesselPage: `${URI_CONFIGURATION}/budgetierung-verknuepfung-vpos-auftschluessel`,
  emailDeliveryDefinitionPage: `${URI_CONFIGURATION}/email-delivery-definition`,
  emailIntegrationPage: `${URI_CONFIGURATION}/email-integration`,
  firmendatenIndexSeries: `${URI_CONFIGURATION}/indexreihen`,
  indexValueCreationCronJobSettings: `${URI_CONFIGURATION}/index-value-creation-cron-job-settings`,
  kontenplanPage: `${URI_CONFIGURATION}/kontenplan`,
  kontierungstabellePage: `${URI_CONFIGURATION}/account-distribution`,
  mahndefinitionPage: `${URI_CONFIGURATION}/mahndefinition`,
  ebicsUserPage: `${URI_CONFIGURATION}/ebics-user`,
  repFondsPage: `${URI_CONFIGURATION}/reparaturFonds`,
  textPage: `${URI_CONFIGURATION}/texte`,
  ustKategoriePage: `${URI_CONFIGURATION}/ust-category`,
  ustRegelsetPage: `${URI_CONFIGURATION}/ust-ruleset`,
  vorschreibungspositionPage: `${URI_CONFIGURATION}/vorschreibungspositionen`,
  vorsteuerkuerzungPage: `${URI_CONFIGURATION}/vorsteuerkuerzung`,
};

export { URI_CONFIGURATION, URI_SYS_SETTINGS };

//  URI_SYS_SETTINGS.
