import React from 'react';
import { capitalizeString } from '../../helpers/stringHelper';

export const HAUPT_TAG_TEXT = 'Haupt';

export const isTagHauptTag = (tag: string | React.ReactNode) => tag === HAUPT_TAG_TEXT;

export const containsHauptTag = (tagList: string[]) => !!tagList.find((tag) => isTagHauptTag(tag));

export const findHauptTagIndex = (tagList: string[]) => tagList.indexOf(HAUPT_TAG_TEXT);

export const getTagColor = (tag: string | React.ReactNode) => (isTagHauptTag(tag) ? 'blue' : 'default');

export const addHauptToTagsIfNeccessary = (condition: boolean, tagList: string[]): string[] => {
  return condition ? [HAUPT_TAG_TEXT, ...tagList] : tagList;
};

export const removeHauptTagDuplicatesFromTags = (tagList: string[], canBeHaupt: boolean) => {
  if (!canBeHaupt) {
    return tagList.filter((tag) => !isTagHauptTag(capitalizeString(tag)));
  } else if (findHauptTagIndex(tagList) !== -1) {
    return tagList.filter(
      (tag, index) => !isTagHauptTag(capitalizeString(tag)) || (isTagHauptTag(capitalizeString(tag)) && index === findHauptTagIndex(tagList))
    );
  } else {
    return tagList.map((tag) => (isTagHauptTag(capitalizeString(tag)) ? capitalizeString(tag) : tag));
  }
};
