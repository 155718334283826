import React, { FC, useContext, useState } from 'react';
import { Skeleton } from 'antd';
import { IndexationAgreementContext } from '../IndexationAgreementContext';
import { useVertragVposIndexationAgreementQuery } from '../../../gql/VPosIndexationAgreementQueries.types';
import {
  isValuePreservationTypeCategory,
  isValuePreservationTypeIndexAnnual,
  isValuePreservationTypeIndexThreshold,
  isValuePreservationTypeReferenceValue,
} from './indexationAgreementHelpers';
import IndexThresholdForm from './IndexThreshold/IndexThresholdForm';
import {
  ValuePreservationType,
  VertragVposIndexationAgreementCategory,
  VertragVposIndexationAgreementIndexAnnual,
  VertragVposIndexationAgreementIndexThreshold,
  VertragVposIndexationAgreementReferenceValue,
} from '../../../../../../../../types';
import IndexAnnualForm from './IndexAnnual/IndexAnnualForm';
import CategoryForm from './Category/CategoryForm';
import ReferenceValueForm from './ReferenceValue/ReferenceValueForm';

type Props = {
  valuePreservationType: ValuePreservationType;
  indexationAgreementId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

const UpdateIndexationAgreementDrawerContent: FC<Props> = ({ valuePreservationType, indexationAgreementId, onSuccess, onCancel }) => {
  const [type] = useState<ValuePreservationType | undefined>(valuePreservationType);
  const { objektId, bestandseinheitId, vertragId, vorschreibungspositionId } = useContext(IndexationAgreementContext);

  const { data, loading } = useVertragVposIndexationAgreementQuery({
    variables: {
      objektId,
      bestandseinheitId,
      vertragId,
      vorschreibungspositionId,
      indexationAgreementId,
    },
    fetchPolicy: 'no-cache',
  });

  const indexationAgreement = data?.getVertragVposIndexationAgreement?.data;

  return loading ? (
    <Skeleton />
  ) : (
    <>
      {isValuePreservationTypeIndexThreshold(type) && (
        <IndexThresholdForm
          indexationAgreement={indexationAgreement as VertragVposIndexationAgreementIndexThreshold}
          onCancel={onCancel}
          onSuccess={onSuccess}
        />
      )}
      {isValuePreservationTypeIndexAnnual(type) && (
        <IndexAnnualForm
          indexationAgreement={indexationAgreement as VertragVposIndexationAgreementIndexAnnual}
          onCancel={onCancel}
          onSuccess={onSuccess}
        />
      )}
      {isValuePreservationTypeCategory(type) && (
        <CategoryForm indexationAgreement={indexationAgreement as VertragVposIndexationAgreementCategory} onCancel={onCancel} onSuccess={onSuccess} />
      )}
      {isValuePreservationTypeReferenceValue(type) && (
        <ReferenceValueForm
          indexationAgreement={indexationAgreement as VertragVposIndexationAgreementReferenceValue}
          onCancel={onCancel}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};

export default UpdateIndexationAgreementDrawerContent;
