import { Button, Dropdown, MenuProps, Modal } from 'antd';
import React, { FC } from 'react';
import { BankOutlined, DeleteOutlined, DownOutlined, ReadOutlined } from '@ant-design/icons';
import PaymentProposalExecutionModal from './PaymentProposalExecutionModal';
import { useToggle } from '../../../hooks/useToggle';
import DeleteForm from './Form/Delete/DeleteForm';
import BookingSuggestionVerbuchenForm from '../../BookingSuggestionVerbuchen/Form/BookingSuggestionVerbuchenForm';

type Props = {
  label: string;
};

const PaymentProposalActionButtons: FC<Props> = ({ label }) => {
  const [isExecutionModalOpen, toggleExecutionModalOpen] = useToggle();
  const [isVerbuchenModalOpen, toggleVerbuchenModalOpen] = useToggle();
  const [isDeleteModalOpen, toggleDeleteModalOpen] = useToggle();

  const items: MenuProps['items'] = [
    { key: '1', label: 'Zahlungsvorschlag durchführen', onClick: toggleExecutionModalOpen, icon: <BankOutlined /> },
    { key: '2', label: 'Zahlungsvorschlag verbuchen', onClick: toggleVerbuchenModalOpen, icon: <ReadOutlined /> },
    { key: '3', label: 'Zahlungsvorschlag löschen', onClick: toggleDeleteModalOpen, icon: <DeleteOutlined /> },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <Button type="primary">
          {label}
          <DownOutlined />
        </Button>
      </Dropdown>
      <PaymentProposalExecutionModal onAction={toggleExecutionModalOpen} open={isExecutionModalOpen} />
      <Modal
        title="Zahlungsvorschlag löschen"
        onCancel={toggleDeleteModalOpen}
        open={isDeleteModalOpen}
        destroyOnClose
        maskClosable={false}
        footer={false}
      >
        <DeleteForm onCancel={toggleDeleteModalOpen} onSuccess={toggleDeleteModalOpen} />
      </Modal>
      <Modal
        title="Zahlungsvorschlag verbuchen"
        onCancel={toggleVerbuchenModalOpen}
        open={isVerbuchenModalOpen}
        destroyOnClose
        maskClosable={false}
        footer={false}
      >
        <BookingSuggestionVerbuchenForm onAction={toggleVerbuchenModalOpen} />
      </Modal>
    </>
  );
};

export default PaymentProposalActionButtons;
