import { message } from 'antd';
import { EditorState } from 'lexical';
import Fetcher from '../../../helpers/fetcher';
import { processAndReturnError, processResponse } from '../../../helpers/APIUtils';
import { showSuccessMsgOther } from '../../../components/message';
import { mapErrorToErrorMessage } from '../../../helpers/errorAndWarningHelper';
import { BelegTexteFormValues } from './belegTexteFormMapper';
import { isEditorStateEmpty } from './belegTexteFormHelpers';

export const handleCreateRechtstraegerSubmit = (
  firmendatenId: string,
  rechtstraegerId: string,
  values: BelegTexteFormValues,
  setSubmitting: (isSubmitting: boolean) => void,
  onSuccess: () => void
) => {
  setSubmitting(true);
  Fetcher.getInstance()
    .fetch(`/api/firmendaten/${firmendatenId}/rechtstraeger/${rechtstraegerId}/beleg-texte`, {
      method: 'PUT',
      body: getFormDataForBody(values),
    })
    .then(processResponse)
    .then(() => {
      showSuccessMsgOther(`Belegtexte wurden erfolgreich aktualisiert`);
      setSubmitting(false);
      onSuccess();
    })
    .catch(processAndReturnError)
    .catch((error) => {
      setSubmitting(false);
      return message.error({
        content: `Belegtexte konnte nicht erstellt werden: ${mapErrorToErrorMessage(error)}`,
      });
    });
};

export const handleCreateFirmendatenSubmit = (
  firmendatenId: string,
  values: BelegTexteFormValues,
  setSubmitting: (isSubmitting: boolean) => void,
  onSuccess: () => void
) => {
  setSubmitting(true);
  Fetcher.getInstance()
    .fetch(`/api/firmendaten/${firmendatenId}/beleg-texte`, {
      method: 'PUT',
      body: getFormDataForBody(values),
    })
    .then(processResponse)
    .then(() => {
      showSuccessMsgOther(`Belegtexte wurden erfolgreich aktualisiert`);
      setSubmitting(false);
      onSuccess();
    })
    .catch(processAndReturnError)
    .catch((error) => {
      setSubmitting(false);
      return message.error({
        content: `Belegtexte konnte nicht erstellt werden: ${mapErrorToErrorMessage(error)}`,
      });
    });
};

const getFormDataForBody = (values: BelegTexteFormValues) => {
  const formData = new FormData();

  formData.append(
    'belegTexte',
    new Blob(
      [
        JSON.stringify({
          data: {
            ...getBelegText(true, values.belegKopfText, values.belegKopfTextAsHtml),
            ...getBelegText(false, values.belegFussText, values.belegFussTextAsHtml),
            belegKopfBildAlignment: values.belegKopfBildAlignment,
            belegKopfBildFileId: values.tempBelegKopfBildFileId ?? values.belegKopfBildFileId,
            belegFussBildAlignment: values.belegFussBildAlignment,
            belegFussBildFileId: values.tempBelegFussBildFileId ?? values.belegFussBildFileId,
          },
        }),
      ],
      {
        type: 'application/json',
      }
    ),
    'payload'
  );

  if (values.belegKopf && !values.tempBelegKopfBildFileId) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    formData.append('belegKopfBild', values.belegKopf.file!, values.belegKopf.filename);
  }
  if (values.belegFuss && !values.tempBelegFussBildFileId) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    formData.append('belegFussBild', values.belegFuss.file!, values.belegFuss.filename);
  }

  return formData;
};

const getBelegText = (isBelegKopf: boolean, belegText?: EditorState | string, belegTextAsHtml?: string | null) => {
  const keyForText = isBelegKopf ? 'belegKopfText' : 'belegFussText';
  const keyForHtml = isBelegKopf ? 'belegKopfTextAsHtml' : 'belegFussTextAsHtml';

  if (belegText) {
    if (typeof belegText === 'object' && !isEditorStateEmpty(belegText as EditorState)) {
      return {
        [keyForText]: JSON.stringify((belegText as EditorState).toJSON()),
        [keyForHtml]: belegTextAsHtml,
      };
    } else if (typeof belegText === 'string') {
      return {
        [keyForText]: belegText,
        [keyForHtml]: belegTextAsHtml,
      };
    } else {
      return undefined;
    }
  }
  return undefined;
};
