import React, { FC } from 'react';
import { Formik } from 'formik';
import { Typography } from 'antd';
import { Form } from 'formik-antd';
import * as Yup from 'yup';
import NewEmailFormPart from './NewEmailFormPart';
import { emailUpdateFormInitialValues, EmailUpdateFormValues } from './emailUpdateFormMapper';
import { useChangeEmailMutation, useChangeEmailMeMutation } from '../../../graphql/Authentication/UserMutation.types';
import { showSuccessMsgOther } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import FormButtons from '../../../components/Button/FormButtons';
import { Mitarbeiter, User } from '../../../types';

type EmailUpdateFormProps = {
  user: User | null; // can also be admin
  mitarbeiter: Mitarbeiter | null;
  onCancel: () => void;
  onSuccess: () => void;
};

const EmailUpdateForm: FC<EmailUpdateFormProps> = ({ user, mitarbeiter, onSuccess, onCancel }) => {
  /*
   * MitarbeiterCard           / useChangeEmailMe,
   * ProfileCardAdmin          / useChangeEmailMe, useChangePasswordMe
   * AdminCard                 / useChangeEmail,
   * ProfileCardMitar.         / useChangeEmailMe, useChangePasswordMe
   * ProfileCardUserNoMitarber / useChangeEmailMe, useChangePasswordMe
   * */

  const formInitialValues = (user: User | null, mitarbeiter: Mitarbeiter | null): EmailUpdateFormValues => {
    if (mitarbeiter) {
      return {
        ...emailUpdateFormInitialValues,
        currentEmail: mitarbeiter.user?.email,
      } as EmailUpdateFormValues;
    }
    if (user) {
      return {
        ...emailUpdateFormInitialValues,
        currentEmail: user.email,
      } as EmailUpdateFormValues;
    }
    return emailUpdateFormInitialValues;
  };

  const [changeEmailMe, { loading: loadingChangeEmailMe }] = useChangeEmailMeMutation({
    onCompleted: () => {
      showSuccessMsgOther(`Ein Bestätigungsemail wurde verschickt.`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  // for admin
  const [changeEmailAdmin, { loading: loadingChangeEmailAdmin }] = useChangeEmailMutation({
    onCompleted: () => {
      showSuccessMsgOther(`Ein Bestätigungsemail wurde verschickt.`);
      onSuccess();
    },
  });

  return (
    <Formik<EmailUpdateFormValues>
      initialValues={formInitialValues(user, mitarbeiter)}
      validationSchema={changeEmailFormValidationSchema}
      onSubmit={(values, formikHelpers) => {
        const { email } = values;
        if (email) {
          changeEmailMe({ variables: { email } }).finally(() => formikHelpers.setSubmitting(false));
        }
        if (email && user && user.username) {
          changeEmailAdmin({
            variables: {
              email,
              username: user.username,
            },
          }).finally(() => formikHelpers.setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Typography.Title level={5}>Bestehende E-Mail-Adresse</Typography.Title>
          <p>{formikProps.values.currentEmail}</p>
          <span style={{ display: 'none' }}>
            <input readOnly value={formikProps.values.currentEmail} type="email" name="emailToCheck" placeholder="E-Mail" />
          </span>
          <NewEmailFormPart emailInputName="email" emailInputPlaceholder="E-Mail-Adresse" emailInputLabel="Neue E-Mail-Adresse" />
          <FormButtons
            onCancel={onCancel}
            updateMode
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={loadingChangeEmailMe || loadingChangeEmailAdmin}
          />
        </Form>
      )}
    </Formik>
  );
};

const changeEmailFormValidationSchema = Yup.object().shape({
  email: Yup.string().notOneOf([Yup.ref('currentEmail')], 'Stimmt mit der bestehenden E-Mail-Adresse überein.'),
});

export default EmailUpdateForm;
