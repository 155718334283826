import React, { FC } from 'react';
import { Space, Spin, Typography } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import { Spacer } from '../../../components/Grid';
import { useTimelineOpen } from '../../../components/Timeline/useTimelineOpen';
import theme from '../../../theme';
import RepFondsTemplateKontenZuweisungListingEmpty from './RepFondsTemplateKontenZuweisungListingEmpty';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';
import { useRepFondsEinstellungTemplateListQuery } from '../gql/RepFondsEinstellungTemplateQueries.types';
import RepFondsTemplateKontenZuweisungTable from './Table/Read/RepFondsTemplateKontenZuweisungTable';
import RepFondsTemplateKontenZuweisungDrawer from './RepFondsTemplateKontenZuweisungDrawer';

const RepFondsTemplateKontenZuweisungListing: FC = () => {
  const { timelineDrawer, openTimelineUpdateDrawer, closeTimelineDrawer, openTimelineCreateDrawer } = useTimelineOpen();

  const {
    data,
    refetch: reloadRepFondsCurrentKontenZuweisung,
    loading,
  } = useRepFondsEinstellungTemplateListQuery({ variables: { onlyCurrent: true } });

  const repFondsEinstellungList = data?.getRepFondsEinstellungTemplateList.data;

  const repFondsEinstellung = repFondsEinstellungList && repFondsEinstellungList.length > 0 ? repFondsEinstellungList[0] : undefined;
  const isLoading = !repFondsEinstellung && loading;

  if (isLoading)
    return (
      <Space style={{ width: '100%', justifyContent: 'center' }}>
        <Spin />
      </Space>
    );

  return (
    <>
      {repFondsEinstellung ? (
        <>
          <Space align="baseline">
            <Typography.Title level={5}>
              Reparaturfonds-Kontenzuweisung ab dem <CustomFormattedDate value={repFondsEinstellung.validFrom} />
            </Typography.Title>
            <HistoryOutlined onClick={openTimelineUpdateDrawer} style={{ color: theme.colors.blue }} />
          </Space>
          <Spacer height={16} />
          <RepFondsTemplateKontenZuweisungTable repFondsEinstellung={repFondsEinstellung} />
        </>
      ) : (
        <RepFondsTemplateKontenZuweisungListingEmpty
          openTimelineCreateDrawer={openTimelineCreateDrawer}
          openTimelineUpdateDrawer={openTimelineUpdateDrawer}
        />
      )}
      <RepFondsTemplateKontenZuweisungDrawer
        timelineDrawer={timelineDrawer}
        closeTimelineDrawer={closeTimelineDrawer}
        reloadRepFondsCurrentKontenZuweisung={reloadRepFondsCurrentKontenZuweisung}
      />
    </>
  );
};

export default RepFondsTemplateKontenZuweisungListing;
