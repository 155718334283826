import React, { FC } from 'react';
import { Formik } from 'formik';
import { Space, Typography } from 'antd';
import { Form, Input, Switch } from 'formik-antd';
import FormItemWithoutColon from '../../../../../../components/Form/FormItemWithoutColon';
import { showSuccessMsgUpdate } from '../../../../../../components/message';
import { useSetMahnsperreForRechtstraegerMutation } from '../../gql/VertragspartnerMutations.types';
import { mahnsperreFormFields, MahnsperreFormValues, mapFormValuesToMahnsperre, mapMahnsperreToFormValues } from './mahnsperreFormMapper';
import FormButtons from '../../../../../../components/Button/FormButtons';
import { RechtstraegerVertragspartner } from '../../../../../../types';

type MahnsperreFormProps = {
  rechtstraegerId: string;
  vertragspartner: RechtstraegerVertragspartner;
  onSuccess: () => void;
  onCancel: () => void;
};

const MahnsperreForm: FC<MahnsperreFormProps> = ({ rechtstraegerId, vertragspartner, onSuccess, onCancel }) => {
  const [setMahnsperre] = useSetMahnsperreForRechtstraegerMutation({
    onCompleted: () => {
      showSuccessMsgUpdate('Mahnsperre');
      onSuccess();
    },
  });

  return (
    <Formik<MahnsperreFormValues>
      initialValues={mapMahnsperreToFormValues(vertragspartner)}
      onSubmit={(formValues, formikHelpers) => {
        const request = mapFormValuesToMahnsperre(formValues);
        setMahnsperre({ variables: { rechtstraegerId, request } }).finally(() => formikHelpers.setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithoutColon name={mahnsperreFormFields.mahnsperre} label="Mahnsperre">
            <Space>
              <Switch name={mahnsperreFormFields.mahnsperre} defaultChecked={vertragspartner.mahnsperre} />
              <Typography.Text>{formikProps.values.mahnsperre ? 'Aktiviert' : 'Deaktiviert'}</Typography.Text>
            </Space>
          </FormItemWithoutColon>
          <FormItemWithoutColon name={mahnsperreFormFields.mahnsperreKommentar} label="Kommentar">
            <Input.TextArea
              id={mahnsperreFormFields.mahnsperreKommentar}
              name={mahnsperreFormFields.mahnsperreKommentar}
              style={{ width: '80%' }}
              autoSize={{ minRows: 6 }}
            />
          </FormItemWithoutColon>
          <FormButtons onCancel={onCancel} updateMode={false} />
        </Form>
      )}
    </Formik>
  );
};

export default MahnsperreForm;
