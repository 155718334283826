import React, { FC } from 'react';
import { Row } from 'antd';
import PDFPageCol from './PDFPageCol';
import OffenePostenDebitorTemplate from './templates/offenePosten/Debitor/OffenePostenDebitorTemplate';
import { mockOpDebitorList } from './mocks/mockOpDebitorList';

const PDFPageOPListDebitoren: FC = () => (
  <Row>
    <PDFPageCol pdfTemplate={<OffenePostenDebitorTemplate data={mockOpDebitorList} />} />
  </Row>
);

export default PDFPageOPListDebitoren;
