import { Drawer } from 'antd';
import React, { FC } from 'react';
import { ValuePreservationType } from '../../../../../../../../types';
import UpdateIndexationAgreementDrawerContent from './UpdateIndexationAgreementDrawerContent';

type Props = {
  indexationAgreementId: string;
  valuePreservationType: ValuePreservationType;
  isDrawerOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
};

const UpdateIndexationAgreementDrawer: FC<Props> = ({ indexationAgreementId, valuePreservationType, isDrawerOpen, onCancel, onSuccess }) => {
  return (
    <Drawer title={drawerTitleForType(valuePreservationType)} width={720} open={isDrawerOpen} onClose={onCancel} destroyOnClose>
      <UpdateIndexationAgreementDrawerContent
        indexationAgreementId={indexationAgreementId}
        valuePreservationType={valuePreservationType}
        onCancel={onCancel}
        onSuccess={onSuccess}
      />
    </Drawer>
  );
};

const drawerTitleForType = (tyoe: ValuePreservationType) => {
  const typeName = () => {
    switch (tyoe) {
      case ValuePreservationType.IndexThreshold:
        return 'Index Schwellenwert';
      case ValuePreservationType.IndexAnnual:
        return 'Index jährlich';
      case ValuePreservationType.Category:
        return 'Kategorie';
      case ValuePreservationType.ReferenceValue:
      default:
        return 'Richtwert';
    }
  };

  return `${typeName()} bearbeiten`;
};
export default UpdateIndexationAgreementDrawer;
