import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { useNavigate } from 'react-router-dom';
import KSFirmendatenSelect from '../../../KSFirmendatenSelect/KSFirmendatenSelect';
import { abrDefParameterFormValidationSchema } from './abrDefParameterFormValidationSchema';
import { URI_SYS_SETTINGS } from '../../../../constants/configurationUriPaths';
import { abrechnungsdefinitionParameterFormFields, AbrechnungsdefinitionParameterFormValues } from './abrDefParameterFormMapper';
import FormButtons from '../../../../components/Button/FormButtons';
import { AbrechnungsdefinitionType } from '../../../../types';
import ParamFormItem from '../../../../components/Form/ParamFormItem';
import { StyledWizardSpaceForContent } from '../../../shared/styled/WizardSpace.style';

type Props = {
  values: AbrechnungsdefinitionParameterFormValues;
  onSubmit: (formValues: AbrechnungsdefinitionParameterFormValues) => void;
};

const AbrDefParameterForm: FC<Props> = ({ values, onSubmit }) => {
  const navigate = useNavigate();

  return (
    <>
      <Formik<AbrechnungsdefinitionParameterFormValues>
        initialValues={values}
        validationSchema={abrDefParameterFormValidationSchema}
        onSubmit={(formValues) => {
          onSubmit(formValues);
        }}
      >
        {(formikProps) => (
          <Form>
            <StyledWizardSpaceForContent direction="vertical">
              <ParamFormItem name={abrechnungsdefinitionParameterFormFields.type} label="Abrechnungstyp">
                <Select
                  name={abrechnungsdefinitionParameterFormFields.type}
                  placeholder="Abrechnungstyp auswählen"
                  id={abrechnungsdefinitionParameterFormFields.type}
                  size="small"
                >
                  <Select.Option key="1" value={AbrechnungsdefinitionType.WeAbrechnung}>
                    WE-Abrechnung
                  </Select.Option>
                  <Select.Option key="2" value={AbrechnungsdefinitionType.HeAbrechnung}>
                    HE-Abrechnung
                  </Select.Option>
                  <Select.Option key="3" value={AbrechnungsdefinitionType.BkAbrechnung}>
                    BK-Abrechnung
                  </Select.Option>
                  <Select.Option key="4" value={AbrechnungsdefinitionType.SubAbrechnung}>
                    SUB-Abrechnung
                  </Select.Option>
                </Select>
              </ParamFormItem>

              <ParamFormItem name={abrechnungsdefinitionParameterFormFields.bezeichnung} label="Bezeichnung">
                <Input
                  name={abrechnungsdefinitionParameterFormFields.bezeichnung}
                  id={abrechnungsdefinitionParameterFormFields.bezeichnung}
                  placeholder="z.B. WE Abrechnung “Organisation”"
                  size="small"
                />
              </ParamFormItem>

              <ParamFormItem name={abrechnungsdefinitionParameterFormFields.firmendatenId} label="Firma auswählen">
                <KSFirmendatenSelect
                  name={abrechnungsdefinitionParameterFormFields.firmendatenId}
                  id={abrechnungsdefinitionParameterFormFields.firmendatenId}
                  placeholder="Firma"
                />
              </ParamFormItem>
            </StyledWizardSpaceForContent>

            <FormButtons
              onCancel={() => navigate(URI_SYS_SETTINGS.abrechnungsdefinitionPage)}
              cancelText="Abbrechen"
              isOkDisabled={formikProps.isSubmitting}
              updateMode={false}
              okText="Weiter"
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AbrDefParameterForm;
