import { FormFields } from '../../../../../helpers/formikHelper';
import { IncomingInvoiceFormValues } from '../../incomingInvoiceFormMapper';

export type StornoFormValues = {
  abgrenzungsBuchung: boolean;
  stornoDirekt: {
    stornoBuchungsdatum: string;
    korrekturBuchungsdatum: string | null;
  };
  stornoAbgrenzung: {
    stornoBuchungsdatum: string | null;
    stornoAbgrenzungsBuchungsdatum: string;
    korrekturBuchungsdatum: string | null;
    korrekturAbgrenzungsBuchungsdatum: string;
  };
};

export const stornoFormInitialValues = (incomingInvoiceFormValues: IncomingInvoiceFormValues): StornoFormValues => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const buchungsdatum = incomingInvoiceFormValues.buchungsdatum!;

  return {
    abgrenzungsBuchung: false,
    stornoDirekt: {
      stornoBuchungsdatum: buchungsdatum,
      korrekturBuchungsdatum: null,
    },
    stornoAbgrenzung: {
      stornoBuchungsdatum: null,
      stornoAbgrenzungsBuchungsdatum: buchungsdatum,
      korrekturBuchungsdatum: null,
      korrekturAbgrenzungsBuchungsdatum: buchungsdatum,
    },
  };
};

export const stornoFormFields: FormFields<StornoFormValues> = {
  abgrenzungsBuchung: 'abgrenzungsBuchung',
  stornoDirekt: 'stornoDirekt',
  stornoAbgrenzung: 'stornoAbgrenzung',
};

export const stornoDirektFields: FormFields<Pick<StornoFormValues['stornoDirekt'], keyof StornoFormValues['stornoDirekt']>> = {
  stornoBuchungsdatum: 'stornoBuchungsdatum',
  korrekturBuchungsdatum: 'korrekturBuchungsdatum',
};

export const stornoAbgrenzungFields: FormFields<Pick<StornoFormValues['stornoAbgrenzung'], keyof StornoFormValues['stornoAbgrenzung']>> = {
  stornoBuchungsdatum: 'stornoBuchungsdatum',
  korrekturBuchungsdatum: 'korrekturBuchungsdatum',
  stornoAbgrenzungsBuchungsdatum: 'stornoAbgrenzungsBuchungsdatum',
  korrekturAbgrenzungsBuchungsdatum: 'korrekturAbgrenzungsBuchungsdatum',
};
