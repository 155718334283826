import { FC, ReactElement } from 'react';
import { Row, Space } from 'antd';

type Props = {
  buttonsWithActions: ReactElement;
};

const NestedTableActionButtons: FC<Props> = ({ buttonsWithActions }) => {
  return (
    <Row justify="end" align="middle" style={{ padding: '12px 8px 8px' }}>
      <Space style={{ position: 'sticky', right: '8px' }}>{buttonsWithActions}</Space>
    </Row>
  );
};

export default NestedTableActionButtons;
