import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../styles/pdfStyles';
import { Summe } from '../../../topAbrechnung-types';

const TARepFondsAbrSumme: FC<{ summe: Summe }> = ({ summe }) => (
  <View style={[pdfStyles.row, pdfStyles.tablePaddingLeftRight]}>
    <View style={[pdfStyles.column, { width: '55%' }]} />
    <View style={[pdfStyles.row, pdfStyles.borderTopSmall, { width: '45%' }]}>
      <Text style={[pdfStyles.column, pdfStyles.textBulletinSmall, { width: '80%', marginTop: '2mm' }]}>{summe.text}</Text>
      <Text
        style={[
          pdfStyles.column,
          pdfStyles.textBulletinSmall,
          {
            width: '20%',
            marginTop: '2mm',
            alignItems: 'flex-end',
            textAlign: 'right',
          },
        ]}
      >
        {summe.betrag}
      </Text>
    </View>
  </View>
);

export default TARepFondsAbrSumme;
