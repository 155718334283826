import { FC } from 'react';
import HistoryListingTable from '../../../History/HistoryListingTable';
import { HistoryType } from '../../../History/shared/HistoryModal';
import { useVorschreibungspositionChangeHistoryListQuery } from '../../gql/VorschreibungspositionQueries.types';

type Props = {
  vorschreibungspositionId: string;
};

const VorschreibungspositionChangeHistoryListingTable: FC<Props> = ({ vorschreibungspositionId }) => {
  const { data, loading } = useVorschreibungspositionChangeHistoryListQuery({ variables: { vorschreibungspositionId } });
  const historyList = data?.getVorschreibungspositionChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="vorschreibungsposition" />;
};

export default VorschreibungspositionChangeHistoryListingTable;
