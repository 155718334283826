import { Dayjs } from 'dayjs';
import { message } from 'antd';
import Fetcher from '../../../helpers/fetcher';
import { processAndReturnError, processResponse } from '../../../helpers/APIUtils';
import { mapErrorToErrorMessage } from '../../../helpers/errorAndWarningHelper';
import { Vertragsart } from '../../../types';
import { isVertragsartTypeLeerstehung, isVertragsartTypeWegLeerstehung } from './vertragsartHelpers';
import { mapFormDateValueToDateString } from '../../../helpers/formikHelper';
import { buildUri } from '../../../shared/uriHelpers';

export const getRechnungsAusstellerSubadministration = (
  firmendatenId: string,
  objektId: string,
  bestandseinheitId: string,
  onSuccess: (rechtstraegerId?: string) => void,
  vertragsart?: Vertragsart,
  vertragsBeginn?: Dayjs | string | null
) => {
  if (!vertragsart || !vertragsBeginn) {
    return;
  }
  if (isVertragsartTypeWegLeerstehung(vertragsart) || isVertragsartTypeLeerstehung(vertragsart)) {
    Fetcher.getInstance()
      .fetch(
        buildUri(`/api/firmendaten/${firmendatenId}/objekte/${objektId}/bestandseinheiten/${bestandseinheitId}/rechnungs-aussteller`, {
          vertragsart,
          vertragsBeginn: mapFormDateValueToDateString(vertragsBeginn),
        }),
        {
          method: 'GET',
        }
      )
      .then(processResponse)
      .then((response) => {
        // @ts-ignore
        onSuccess(response.data.data.rechtstraegerId);
      })
      .catch(processAndReturnError)
      .catch((error) => {
        return message.error({
          content: `Rechnungsaussteller konnte nicht gefunden werden: ${mapErrorToErrorMessage(error)}`,
        });
      });
  }
};
