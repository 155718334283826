import React, { FC, useState } from 'react';
import { Space, Typography } from 'antd';
import { Objekt, SubAdministrationListEntry } from '../../../../types';
import { useSubAdministrationListQuery } from '../../../SubAdministration/gql/SubAdministrationQueries.types';
import ObjSubAdministrationEigentuemerSelect from './ObjSubAdministrationEigentuemerSelect';
import ObjSubAdministrationTable from './ObjSubAdministrationTable';
import { useOnSubAdministrationDataChangeEvent } from '../useOnSubAdministrationDataChangeEvent';

type Props = {
  objekt: Objekt;
};

const ObjSubAdministrationListing: FC<Props> = ({ objekt }) => {
  const [selectedEigentuemerId, setSelectedEigentuemerId] = useState<string>();

  const { data, loading, refetch } = useSubAdministrationListQuery({ variables: { objektId: objekt.objektId } });

  const subAdministrationListData = data?.getSubAdministrationList.data ?? [];

  const filterByEigentuemerId = (sub: SubAdministrationListEntry) => (selectedEigentuemerId ? sub.eigentuemerId === selectedEigentuemerId : true);

  const eigentuemerList = subAdministrationListData.map((sub) => ({
    rechtstraegerId: sub.eigentuemerId,
    kurzBezeichnung: sub.eigentuemerBezeichnung,
  }));

  const subAdministrationListFiltered = subAdministrationListData?.filter(filterByEigentuemerId);

  useOnSubAdministrationDataChangeEvent(`sub-administration-${objekt.objektId}`, refetch);

  return (
    <>
      <Space>
        <Typography.Text>Eigentümer</Typography.Text>
        <ObjSubAdministrationEigentuemerSelect
          selectedEigentuemerId={selectedEigentuemerId}
          setSelectedEigentuemerId={setSelectedEigentuemerId}
          eigentuemerList={eigentuemerList}
        />
      </Space>
      <ObjSubAdministrationTable subAdministrationList={subAdministrationListFiltered} loading={loading} objektId={objekt.objektId} />
    </>
  );
};

export default ObjSubAdministrationListing;
