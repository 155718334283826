import React, { FC } from 'react';
import { SollHaben } from '../../../../../../types';
import KontoDetails, { KontoForCard } from '../../KontoDetailsCard/KontoDetails';
import { StyledSpaceForCardContent, StyledSpaceForCardContentAndIcon } from '../../KontoDetailsCard/KontoDetailsCardStyleHelpers';
import KontoSaldoForBooking from './KontoSaldoForBooking';
import KontoDetailsCardIcon from '../../KontoDetailsCard/KontoDetailsCardIcon';

type Props = {
  konto: KontoForCard;
  kontoType: SollHaben;
  objektId?: string;
  buchungskreisRechtstraegerId: string;
  buchungskreisId: string;
};

const KtoDetailsCard: FC<Props> = ({ konto, kontoType, objektId, buchungskreisId, buchungskreisRechtstraegerId }) => {
  return (
    <StyledSpaceForCardContentAndIcon>
      <KontoDetailsCardIcon konto={konto} />
      <StyledSpaceForCardContent>
        <KontoDetails kontoType={kontoType} konto={konto} />
        <KontoSaldoForBooking
          kontoId={konto.kontoId}
          objektId={objektId}
          buchungskreisId={buchungskreisId}
          buchungskreisRechtstraegerId={buchungskreisRechtstraegerId}
        />
      </StyledSpaceForCardContent>
    </StyledSpaceForCardContentAndIcon>
  );
};

export default KtoDetailsCard;
