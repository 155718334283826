import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Button, Modal } from 'antd';
import { Flex } from 'rebass';
import { IncomingInvoiceFormValues, TInvoice } from './incomingInvoiceFormMapper';
import { useToggle } from '../../../hooks/useToggle';
import BookingPreview from './Preview/BookingPreview/BookingPreview';
import { useSingleInvoiceRestoreMutation } from '../gql/SingleInvoiceMutations.types';
import { showSuccessMsgRestore } from '../../../components/message/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { useStandingInvoiceRestoreMutation } from '../gql/StandingInvoiceMutations.types';
import { isInvoiceTypeSingleInvoice } from './incomingInvoiceFormHelpers';
import { isStatusErstellt } from '../../../helpers/statusHelper';

type Props = {
  incomingInvoice?: TInvoice;
  formikProps: FormikProps<IncomingInvoiceFormValues>;
  goBack: () => void;
  shouldRestore: boolean;
  isLoading?: boolean;
  refetchIncomingInvoice?: () => Promise<unknown>;
};

const IncomingInvoiceFormButtons: FC<Props> = ({ formikProps, incomingInvoice, goBack, shouldRestore, isLoading, refetchIncomingInvoice }) => {
  const [isBookingPreviewModalOpen, toggleBookingPreviewModal] = useToggle();

  const [restoreSingleInvoice, { loading: restoreSingleInvoiceLoading }] = useSingleInvoiceRestoreMutation({
    onCompleted: () => {
      showSuccessMsgRestore('Eingangsrechnung');
      refetchIncomingInvoice?.();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [restoreStandingInvoice, { loading: restoreStandingInvoiceLoading }] = useStandingInvoiceRestoreMutation({
    onCompleted: () => {
      showSuccessMsgRestore('Eingangsrechnung');
      refetchIncomingInvoice?.();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const loading = isLoading || restoreSingleInvoiceLoading || restoreStandingInvoiceLoading;

  return (
    <>
      <Flex justifyContent="space-between" width="100%" style={{ gap: 8 }}>
        {(!incomingInvoice || (incomingInvoice && isStatusErstellt(incomingInvoice.status))) && (
          <Button type="link" disabled={!formikProps.isValid} onClick={toggleBookingPreviewModal} style={{ paddingInline: 0 }}>
            Buchungen Vorschau
          </Button>
        )}

        <Button htmlType="button" onClick={goBack} style={{ marginLeft: 'auto' }}>
          Abbrechen
        </Button>
        {!shouldRestore ? (
          <Button loading={loading} type="primary" htmlType="submit">
            Speichern
          </Button>
        ) : (
          <Button
            loading={loading}
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const invoice = incomingInvoice!;
              if (isInvoiceTypeSingleInvoice(invoice.type)) {
                restoreSingleInvoice({ variables: { belegId: invoice.belegId } });
              } else {
                restoreStandingInvoice({ variables: { belegId: invoice.belegId } });
              }
            }}
            type="primary"
            htmlType="button"
          >
            Zurücksetzen
          </Button>
        )}
      </Flex>
      <Modal
        title="Eingangsrechnung Buchungsvorschau"
        open={isBookingPreviewModalOpen}
        onCancel={toggleBookingPreviewModal}
        footer={null}
        destroyOnClose
        maskClosable={false}
        width={976}
      >
        <BookingPreview incomingInvoiceFormValues={formikProps.values} toggleBookingPreviewModal={toggleBookingPreviewModal} />
      </Modal>
    </>
  );
};

export default IncomingInvoiceFormButtons;
