import { BeVertragCreateInput, Verrechnungsart, Vertragsart } from '../../../../types';
import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';

export type VertragCreateFormValues = {
  bankDetailsId: string;
  vertragsBeginn?: string | null;
  vertragsEnde?: string | null;
  vertragspartnerId: string;
  paymentMethod: string | null;
  verrechnungsart: Verrechnungsart | null;
  vertragsart: Vertragsart;
  supportsExplicitVertragsEnde: boolean | null;
  tagList?: string[] | null;
};

export const vertragCreateFormFields: FormFields<VertragCreateFormValues> = {
  bankDetailsId: 'bankDetailsId',
  verrechnungsart: 'verrechnungsart',
  vertragsart: 'vertragsart',
  vertragsBeginn: 'vertragsBeginn',
  vertragsEnde: 'vertragsEnde',
  vertragspartnerId: 'vertragspartnerId',
  paymentMethod: 'paymentMethod',
  supportsExplicitVertragsEnde: 'supportsExplicitVertragsEnde',
  tagList: 'tagList',
};

export const vertragCreateFormInitialValues: VertragCreateFormValues = {
  bankDetailsId: '',
  vertragsBeginn: null,
  vertragsEnde: null,
  vertragspartnerId: '',
  paymentMethod: null,
  verrechnungsart: null,
  vertragsart: '' as any,
  supportsExplicitVertragsEnde: null,
};

export const mapFormValuesToVertrag = (formValues: VertragCreateFormValues): BeVertragCreateInput => ({
  bankDetailsId: formValues.bankDetailsId,
  verrechnungsart: formValues.verrechnungsart,
  vertragsart: formValues.vertragsart,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  vertragsBeginn: mapFormDateValueToDateString(formValues.vertragsBeginn!),
  vertragsEnde: formValues.vertragsEnde ? mapFormDateValueToDateString(formValues.vertragsEnde) : null,
  vertragspartnerId: formValues.vertragspartnerId,
  paymentMethod: formValues.paymentMethod,
  tagList: formValues.tagList,
});
