import React, { FC } from 'react';
import { Drawer, MenuProps, Space, Tooltip, Typography } from 'antd';
import { EditOutlined, GoldOutlined, HistoryOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { SubAdministrationSettings } from '../../../../types';
import { useToggle } from '../../../../hooks/useToggle';
import { radixActionStyles } from '../../../../helpers/radixIconsStyles';
import theme from '../../../../theme';
import { styleForIconAndButton } from '../../../../components/Dropdown/SettingsItemStyleHelpers';
import SettingsItemDropdown from '../../../../components/Dropdown/SettingsItemDropdown';
import HistoryModal, { HistoryType } from '../../../History/shared/HistoryModal';
import SubAdministrationSettingsChangeHistoryListingTable from '../../../SubAdministration/SubAdministrationSettingsChangeHistoryListingTable';
import { StyledSpaceForItemContent, StyledSpaceForSettingsDetails } from '../../../../components/Card/Styled/SettingsCard.style';
import SubAdministrationSettingsUpdateForm from './SubAdministration/Form/SubAdministrationSettingsUpdateForm';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';

type Props = {
  subAdministrationSettings: SubAdministrationSettings;
  rechtstraegerId: string;
  onAction: () => void;
};

const SubAdministrationSettingsCard: FC<Props> = ({ subAdministrationSettings, rechtstraegerId, onAction }) => {
  const [isUpdateModalOpen, toggleUpdateModalOpan] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const getFieldHelpText = useGetAppFieldHelpText<'RechnungsAusstellerActionDataUpdateSubAdministrationSettings'>(
    'RechnungsAusstellerActionDataUpdateSubAdministrationSettings'
  );

  const menuItems: MenuProps['items'] = [
    { key: '1', label: 'Bearbeiten', onClick: toggleUpdateModalOpan, icon: <EditOutlined /> },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
  ];

  const settingsDetails = (
    <StyledSpaceForSettingsDetails direction="vertical" size="large">
      <Typography.Text>Subverwaltung</Typography.Text>
      <Space size="large">
        <Tooltip title={getFieldHelpText('RechnungsAusstellerActionDataUpdateSubAdministrationSettings.paymentClearsIncomingInvoice')}>
          <Space>
            <Typography.Text>
              Direktzahlung an WEG ziffert Eingangsrechnung aus <InfoCircleOutlined />
            </Typography.Text>
            <Typography.Text>{subAdministrationSettings.paymentClearsIncomingInvoice ? 'Ja' : 'Nein'}</Typography.Text>
          </Space>
        </Tooltip>
      </Space>
    </StyledSpaceForSettingsDetails>
  );

  return (
    <>
      <StyledSpaceForItemContent align="start">
        <div
          style={{
            backgroundColor: theme.cardBackgroundColors.grey,
            padding: '12px 0',
            ...styleForIconAndButton,
          }}
        >
          <GoldOutlined />
        </div>
        {settingsDetails}
        <SettingsItemDropdown menuItems={menuItems} />
      </StyledSpaceForItemContent>
      <Drawer title="Subverwaltung bearbeiten" open={isUpdateModalOpen} onClose={toggleUpdateModalOpan} width={720} destroyOnClose>
        <SubAdministrationSettingsUpdateForm
          subAdministrationSettings={subAdministrationSettings}
          rechtstraegerId={rechtstraegerId}
          onCancel={toggleUpdateModalOpan}
          onSuccess={() => {
            onAction();
            toggleUpdateModalOpan();
          }}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <SubAdministrationSettingsChangeHistoryListingTable rechtstraegerId={rechtstraegerId} />
      </HistoryModal>
    </>
  );
};

export default SubAdministrationSettingsCard;
