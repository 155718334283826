import React, { FC } from 'react';
import { useRechtstraegerBankDetailsListQuery } from '../gql/BankDetailsQueries.types';
import BankDetailsListing from '../BankDetailsListing';
import { useToggle } from '../../../hooks/useToggle';
import { useOnRechtstraegerBankDetailsDataChangeEvent } from './useOnRechtstraegerBankDetailsDataChangeEvent';

type Props = {
  rechtstraegerId: string;
  fibuKontoCreatable?: boolean;
};

const RechtstraegerBankDetails: FC<Props> = ({ rechtstraegerId, fibuKontoCreatable }) => {
  const [showArchived, toggleShowArchived] = useToggle();
  const { loading, data, refetch } = useRechtstraegerBankDetailsListQuery({
    variables: {
      rechtstraegerId,
      includeArchiviert: showArchived,
    },
  });
  const bankDetailsList = data?.getRechtstraegerBankDetailsList.data ?? [];

  useOnRechtstraegerBankDetailsDataChangeEvent('rechtstraegerBankDetails', refetch, rechtstraegerId);
  return (
    <BankDetailsListing
      rechtstraegerId={rechtstraegerId}
      loading={loading}
      bankDetailsList={bankDetailsList}
      onSuccess={refetch}
      fibuKontoCreatable={fibuKontoCreatable}
      showArchived={showArchived}
      toggleShowArchived={toggleShowArchived}
    />
  );
};

export default RechtstraegerBankDetails;
