import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../../styles/pdfStyles';

const TARepFondsAbrAusgabenKontoBezeichnung: FC<{ bezeichnung: string }> = ({ bezeichnung }) => (
  <View style={[pdfStyles.row, { marginTop: '3mm' }]}>
    <Text
      style={[
        pdfStyles.textNormal,
        {
          textDecoration: 'underline',
          width: '30%',
          textAlign: 'left',
        },
      ]}
    >
      {bezeichnung}
    </Text>
  </View>
);

export default TARepFondsAbrAusgabenKontoBezeichnung;
