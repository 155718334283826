import { AbrechdefAbrechkreis, AbrechdefAbrechkreisInput, AbrechnungskreisType } from '../../../../types';
import { FormFields } from '../../../../helpers/formikHelper';

export type AbrDefAbrKreisListingFormValues = {
  abrechdefAbrechkreisList: AbrDefAbrKreisFormValues[];
};

export const abrDefAbrKreisListingFormFields: FormFields<AbrDefAbrKreisListingFormValues> = {
  abrechdefAbrechkreisList: 'abrechdefAbrechkreisList',
};

export type AbrDefAbrKreisFormValues = {
  abrechnungskreisId: string;
  abrechnungskreisType?: AbrechnungskreisType;
  aufteilungsschluesselId?: string;
  vorschreibungspositionId?: string;
};

export const abrDefAbrKreisFormFields: FormFields<AbrDefAbrKreisFormValues> = {
  abrechnungskreisId: 'abrechnungskreisId',
  abrechnungskreisType: 'abrechnungskreisType',
  aufteilungsschluesselId: 'aufteilungsschluesselId',
  vorschreibungspositionId: 'vorschreibungspositionId',
};

export const abrDefAbrKreisFormInitialValues: AbrDefAbrKreisFormValues = {
  abrechnungskreisId: '',
  aufteilungsschluesselId: '',
  vorschreibungspositionId: '',
};

const abrDefAbrKreisListingFormInitialValues: AbrDefAbrKreisListingFormValues = {
  abrechdefAbrechkreisList: [],
};

export const mapAbrechnungsdefinitionToAbrKreisListFormValues = (abrechdefAbrechkreisList?: AbrechdefAbrechkreis[]) => {
  if (!abrechdefAbrechkreisList) return abrDefAbrKreisListingFormInitialValues;
  return {
    abrechdefAbrechkreisList: mapAbrDefAbrKreisListToFormValues(abrechdefAbrechkreisList),
  };
};

const mapAbrDefAbrKreisListToFormValues = (abrechdefAbrechkreisList: AbrechdefAbrechkreis[] = []): AbrDefAbrKreisFormValues[] =>
  abrechdefAbrechkreisList.map((abrechdefAbrechkreis) => {
    return {
      abrechnungskreisId: abrechdefAbrechkreis.abrechnungskreis.abrechnungskreisId,
      aufteilungsschluesselId: abrechdefAbrechkreis.aufteilungsschluessel?.aufteilungsschluesselId,
      vorschreibungspositionId: abrechdefAbrechkreis.vorschreibungsposition?.vorschreibungspositionId,
      abrechnungskreisType: abrechdefAbrechkreis.abrechnungskreis.type.value,
    };
  });

export const mapFormValuesToAbrDefAbrKreisList = (abrechdefAbrechkreisListFormValues: AbrDefAbrKreisFormValues[]): AbrechdefAbrechkreisInput[] => {
  const abrechdefAbrechkreisList: AbrechdefAbrechkreisInput[] = [];
  abrechdefAbrechkreisListFormValues.forEach((abrechdefAbrechkreis) => {
    const abrechnungskreisId = abrechdefAbrechkreis.abrechnungskreisId;
    abrechdefAbrechkreisList.push({
      abrechnungskreisId,
      aufteilungsschluesselId: abrechdefAbrechkreis.aufteilungsschluesselId,
      vorschreibungspositionId: abrechdefAbrechkreis.vorschreibungspositionId,
    });
  });
  return abrechdefAbrechkreisList;
};
