import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateHeAbrechnungsdefinitionTemplateMutationVariables = Types.Exact<{
  input: Types.HeAbrechnungsdefinitionCreateInput;
}>;

export type CreateHeAbrechnungsdefinitionTemplateMutation = {
  createHeAbrechnungsdefinitionTemplate: {
    data: { abrechnungsdefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateHeAbrechnungsdefinitionTemplateMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  input: Types.HeAbrechnungsdefinitionUpdateInput;
}>;

export type UpdateHeAbrechnungsdefinitionTemplateMutation = {
  updateHeAbrechnungsdefinitionTemplate: {
    data: { abrechnungsdefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateHeAbrechnungsdefinitionTemplateDocument = gql`
  mutation CreateHeAbrechnungsdefinitionTemplate($input: HeAbrechnungsdefinitionCreateInput!) {
    createHeAbrechnungsdefinitionTemplate(input: $input) {
      data {
        abrechnungsdefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateHeAbrechnungsdefinitionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateHeAbrechnungsdefinitionTemplateMutation, CreateHeAbrechnungsdefinitionTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateHeAbrechnungsdefinitionTemplateMutation, CreateHeAbrechnungsdefinitionTemplateMutationVariables>(
    CreateHeAbrechnungsdefinitionTemplateDocument,
    options
  );
}
export type CreateHeAbrechnungsdefinitionTemplateMutationHookResult = ReturnType<typeof useCreateHeAbrechnungsdefinitionTemplateMutation>;
export type CreateHeAbrechnungsdefinitionTemplateMutationResult = Apollo.MutationResult<CreateHeAbrechnungsdefinitionTemplateMutation>;
export type CreateHeAbrechnungsdefinitionTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateHeAbrechnungsdefinitionTemplateMutation,
  CreateHeAbrechnungsdefinitionTemplateMutationVariables
>;
export const UpdateHeAbrechnungsdefinitionTemplateDocument = gql`
  mutation UpdateHeAbrechnungsdefinitionTemplate($abrechnungsdefinitionId: ID!, $input: HeAbrechnungsdefinitionUpdateInput!) {
    updateHeAbrechnungsdefinitionTemplate(abrechnungsdefinitionId: $abrechnungsdefinitionId, input: $input) {
      data {
        abrechnungsdefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateHeAbrechnungsdefinitionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateHeAbrechnungsdefinitionTemplateMutation, UpdateHeAbrechnungsdefinitionTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateHeAbrechnungsdefinitionTemplateMutation, UpdateHeAbrechnungsdefinitionTemplateMutationVariables>(
    UpdateHeAbrechnungsdefinitionTemplateDocument,
    options
  );
}
export type UpdateHeAbrechnungsdefinitionTemplateMutationHookResult = ReturnType<typeof useUpdateHeAbrechnungsdefinitionTemplateMutation>;
export type UpdateHeAbrechnungsdefinitionTemplateMutationResult = Apollo.MutationResult<UpdateHeAbrechnungsdefinitionTemplateMutation>;
export type UpdateHeAbrechnungsdefinitionTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateHeAbrechnungsdefinitionTemplateMutation,
  UpdateHeAbrechnungsdefinitionTemplateMutationVariables
>;
