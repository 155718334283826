import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { DatePicker, Form, Input, Select, Switch } from 'formik-antd';
import { Col, Divider, Row, Skeleton, Space, Typography } from 'antd';
import FormButtons from '../../../../components/Button/FormButtons';
import ObjektAddressSelect from '../../shared/ObjektAddressSelect';
import {
  bestandseinheitCreateFormFields,
  BestandseinheitCreateFormValues,
  mapFormValuesToBestandseinheitCreate,
  mapObjektForBestandseinheitToFormValues,
} from './bestandseinheitCreateFormMapper';
import { bestandseinheitCreateFormValidationSchema } from '../../shared/bestandseinheitFormValidationSchema';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../components/DatePicker/DatePicker_formikAntD';
import { Address as AdresseModel } from '../../../../types';
import { useCreateBestandseinheitMutation } from '../../gql/BestandseinheitMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { showSuccessMsgCreateWithPath } from '../../../../components/message/message';
import { generatePathToBestandseinheitDetailsPage } from '../../bestandseinheitUriPaths';
import FormattedDecimalFormInput from '../../../../components/Input-antd/FormattedDecimalFormInput';
import { isWegObjekt } from '../../../Objekt/objektHelper';
import BestandseinheitFormSelect from '../../../shared/Bestandseinheit/BestandseinheitFormSelect';
import { useObjektQuery } from '../../../Objekt/gql/ObjektQueries.types';
import NutzungsartSelect from '../../../Vertrag/BeVertrag/Forms/NutzungsartSelect/NutzungsartSelect';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';

type Props = {
  objektId: string;
  onSubmit: () => void;
  onCancel: () => void;
};

const BestandseinheitCreateForm: FC<Props> = ({ objektId, onSubmit, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'Bestandseinheit'>('Bestandseinheit');

  const entity = `Bestandseinheit`;

  const { data: maybeObjekt, loading: loadingObjekt, refetch } = useObjektQuery({ variables: { objektId } });
  const objekt = maybeObjekt?.getObjekt.data;

  const [runCreateBestandseinheit, { loading: isCreateBestandseinheitLoading }] = useCreateBestandseinheitMutation({
    onCompleted: (data) => {
      const { bestandseinheitId, bezeichnung } = data.createBestandseinheit.data;
      showSuccessMsgCreateWithPath(entity, bezeichnung, generatePathToBestandseinheitDetailsPage(objektId, bestandseinheitId));
      onSubmit();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });
  const [selectedAddress, setSelectedAddress] = useState<string>(objekt?.addressList.length === 1 ? objekt.addressList[0].addressId : '');

  return (
    <Skeleton active paragraph loading={loadingObjekt}>
      {objekt ? (
        <Formik<BestandseinheitCreateFormValues>
          initialValues={mapObjektForBestandseinheitToFormValues(selectedAddress, objekt)}
          validationSchema={bestandseinheitCreateFormValidationSchema}
          onSubmit={(values, formikHelpers) => {
            runCreateBestandseinheit({ variables: { objektId, bestandseinheit: mapFormValuesToBestandseinheitCreate(values) } }).finally(() =>
              formikHelpers.setSubmitting(false)
            );
          }}
        >
          {(formikProps) => (
            <Form layout="vertical">
              <Row align="top">
                <Col span={6}>
                  <Typography.Title level={5}>Adresse</Typography.Title>
                </Col>
                <Col span={18}>
                  <FormItemWithFieldHelp
                    name={bestandseinheitCreateFormFields.addressId}
                    fieldHelp={getFieldHelpText('Bestandseinheit.addressId')}
                    label="Adresse"
                  >
                    <ObjektAddressSelect
                      name={bestandseinheitCreateFormFields.addressId}
                      objektId={objektId}
                      addressList={objekt.addressList as AdresseModel[]}
                      onNewItemCreateSelected={() => formikProps.setFieldValue(bestandseinheitCreateFormFields.addressId, '')}
                      onNewItemCreated={(addressId) => {
                        setSelectedAddress(addressId);
                        formikProps.setFieldValue(bestandseinheitCreateFormFields.addressId, addressId);
                        refetch();
                      }}
                    />
                  </FormItemWithFieldHelp>
                </Col>
              </Row>
              <Divider />
              <Row align="top">
                <Col span={6}>
                  <Typography.Title level={5}>Bestandseinheit</Typography.Title>
                </Col>
                <Col span={18}>
                  <Row align="top" gutter={8}>
                    <Col span={24}>
                      <FormItemWithFieldHelp
                        name={bestandseinheitCreateFormFields.aktivSeit}
                        fieldHelp={getFieldHelpText('Bestandseinheit.aktivSeit')}
                        label="Aktiv ab (optional)"
                      >
                        <DatePicker
                          size="small"
                          id={bestandseinheitCreateFormFields.aktivSeit}
                          name={bestandseinheitCreateFormFields.aktivSeit}
                          style={{ width: '100%' }}
                          format={DATE_DISPLAY_FORMAT_DEFAULT}
                        />
                      </FormItemWithFieldHelp>
                    </Col>
                  </Row>
                  <FormItemWithFieldHelp
                    name={bestandseinheitCreateFormFields.bezeichnung}
                    fieldHelp={getFieldHelpText('Bestandseinheit.bezeichnung')}
                    label="Bezeichnung"
                  >
                    <Input
                      size="small"
                      id={bestandseinheitCreateFormFields.bezeichnung}
                      name={bestandseinheitCreateFormFields.bezeichnung}
                      placeholder="z.B. Top 1"
                    />
                  </FormItemWithFieldHelp>
                  <Space>
                    <FormItemWithFieldHelp
                      name={bestandseinheitCreateFormFields.geschoss}
                      fieldHelp={getFieldHelpText('Bestandseinheit.geschoss')}
                      label="Geschoss (optional)"
                    >
                      <Input
                        size="small"
                        id={bestandseinheitCreateFormFields.geschoss}
                        name={bestandseinheitCreateFormFields.geschoss}
                        placeholder="z.B. 3"
                      />
                    </FormItemWithFieldHelp>
                    <FormItemWithFieldHelp
                      name={bestandseinheitCreateFormFields.stiege}
                      fieldHelp={getFieldHelpText('Bestandseinheit.stiege')}
                      label="Stiege (optional)"
                    >
                      <Input
                        size="small"
                        id={bestandseinheitCreateFormFields.stiege}
                        name={bestandseinheitCreateFormFields.stiege}
                        placeholder="z.B. 2"
                      />
                    </FormItemWithFieldHelp>
                    <FormItemWithFieldHelp
                      name={bestandseinheitCreateFormFields.bauteil}
                      fieldHelp={getFieldHelpText('Bestandseinheit.bauteil')}
                      label="Bauteil"
                    >
                      <Input
                        size="small"
                        id={bestandseinheitCreateFormFields.bauteil}
                        name={bestandseinheitCreateFormFields.bauteil}
                        placeholder="z.B. 2B"
                      />
                    </FormItemWithFieldHelp>
                  </Space>
                  <FormItemWithFieldHelp
                    name={bestandseinheitCreateFormFields.bestandseinheitInsertBeforeId}
                    fieldHelp={getFieldHelpText('Bestandseinheit.bestandseinheitInsertBeforeId')}
                    label="Einfügen vor..."
                  >
                    <BestandseinheitFormSelect name={bestandseinheitCreateFormFields.bestandseinheitInsertBeforeId} objektId={objektId} />
                  </FormItemWithFieldHelp>
                  <FormItemWithFieldHelp
                    label="Merkmale"
                    name={bestandseinheitCreateFormFields.tagList}
                    fieldHelp={getFieldHelpText('Bestandseinheit.tagList')}
                  >
                    <Select
                      id={bestandseinheitCreateFormFields.tagList}
                      name={bestandseinheitCreateFormFields.tagList}
                      mode="tags"
                      placeholder="z.B. WE, BK"
                    />
                  </FormItemWithFieldHelp>
                </Col>
              </Row>

              <Divider />
              <Row align="top">
                <Col span={6}>
                  <Typography.Title level={5}>Nutzung</Typography.Title>
                </Col>
                <Col span={18}>
                  <FormItemWithFieldHelp
                    name={bestandseinheitCreateFormFields.nutzungsArt}
                    label="Nutzungsart"
                    fieldHelp={getFieldHelpText('Bestandseinheit.nutzung.nutzungsArt')}
                  >
                    <NutzungsartSelect
                      size="small"
                      id={bestandseinheitCreateFormFields.nutzungsArt}
                      name={bestandseinheitCreateFormFields.nutzungsArt}
                      allowClear={false}
                    />
                  </FormItemWithFieldHelp>
                  <FormItemWithFieldHelp
                    name={bestandseinheitCreateFormFields.nutzungsValidFrom}
                    label="Gültig ab"
                    fieldHelp={getFieldHelpText('Bestandseinheit.nutzung.validFrom')}
                  >
                    <DatePicker
                      size="small"
                      id={bestandseinheitCreateFormFields.nutzungsValidFrom}
                      name={bestandseinheitCreateFormFields.nutzungsValidFrom}
                      style={{ width: '100%' }}
                      format={DATE_DISPLAY_FORMAT_DEFAULT}
                      allowClear={false}
                    />
                  </FormItemWithFieldHelp>
                  <Row align="top" gutter={8}>
                    <Col span={isWegObjekt(objekt?.verwaltungsart.value) ? 12 : 24}>
                      <FormItemWithFieldHelp
                        name={bestandseinheitCreateFormFields.nutzflaeche}
                        label="Nutzfläche"
                        style={{ margin: 0 }}
                        fieldHelp={getFieldHelpText('Bestandseinheit.nutzung.nutzflaeche')}
                      >
                        <FormattedDecimalFormInput
                          id={bestandseinheitCreateFormFields.nutzflaeche}
                          name={bestandseinheitCreateFormFields.nutzflaeche}
                          size="small"
                          fractionDigits={2}
                          placeholder="z.B. 123"
                          style={{ width: '100%' }}
                        />
                      </FormItemWithFieldHelp>
                    </Col>
                    {isWegObjekt(objekt?.verwaltungsart.value) && (
                      <Col span={12}>
                        <FormItemWithFieldHelp
                          name={bestandseinheitCreateFormFields.nutzwert}
                          label="Nutzwert"
                          style={{ margin: 0 }}
                          fieldHelp={getFieldHelpText('Bestandseinheit.nutzung.nutzwert')}
                        >
                          <FormattedDecimalFormInput
                            id={bestandseinheitCreateFormFields.nutzwert}
                            name={bestandseinheitCreateFormFields.nutzwert}
                            size="small"
                            placeholder="z.B. 87"
                            style={{ width: '100%' }}
                            fractionDigits={0}
                          />
                        </FormItemWithFieldHelp>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
              <Divider />
              <Row align="top">
                <Col span={6}>
                  <Typography.Title level={5}>Steueroption</Typography.Title>
                </Col>
                <Col span={18}>
                  <FormItemWithFieldHelp
                    name={bestandseinheitCreateFormFields.steuerpflichtValidFrom}
                    label="Gültig ab"
                    fieldHelp={getFieldHelpText('Bestandseinheit.optionSteuerpflicht.validFrom')}
                  >
                    <DatePicker
                      size="small"
                      id={bestandseinheitCreateFormFields.steuerpflichtValidFrom}
                      name={bestandseinheitCreateFormFields.steuerpflichtValidFrom}
                      style={{ width: '100%' }}
                      format={DATE_DISPLAY_FORMAT_DEFAULT}
                      allowClear={false}
                    />
                  </FormItemWithFieldHelp>
                  <FormItemWithFieldHelp
                    name={bestandseinheitCreateFormFields.steuerpflichtOption}
                    label="Option zur Steuerpflicht"
                    fieldHelp={getFieldHelpText('Bestandseinheit.optionSteuerpflicht.optionSteuerpflicht')}
                  >
                    <Switch name={bestandseinheitCreateFormFields.steuerpflichtOption} id={bestandseinheitCreateFormFields.steuerpflichtOption} />
                  </FormItemWithFieldHelp>
                </Col>
              </Row>

              <FormButtons
                isOkLoading={isCreateBestandseinheitLoading}
                updateMode={false}
                isOkDisabled={formikProps.isSubmitting}
                onCancel={onCancel}
              />
            </Form>
          )}
        </Formik>
      ) : null}
    </Skeleton>
  );
};

export default BestandseinheitCreateForm;
