import React, { FC } from 'react';
import { Spacer } from '../../../components/Grid';
import PersonenbezugBtnAndDrawer from './PersonenbezugBtnAndDrawer';
import PersonenbezugList from './List/PersonenbezugList';
import { Personenbezug } from '../../../types';
import { useOnRechtstraegerDataChangeEvent } from '../useOnRechtstraegerDataChangeEvent';

type Props = {
  rechtstraegerId: string;
  personenbezugList: Personenbezug[];
  onSuccess: () => void;
};

const PersonenbezugListing: FC<Props> = ({ rechtstraegerId, personenbezugList, onSuccess }) => {
  useOnRechtstraegerDataChangeEvent('personenbezugList', onSuccess as () => Promise<unknown>, rechtstraegerId, true);

  return (
    <>
      <PersonenbezugBtnAndDrawer rechtstraegerId={rechtstraegerId} personenbezugList={personenbezugList} onSuccess={onSuccess} />
      <Spacer height={16} />
      <PersonenbezugList rechtstraegerId={rechtstraegerId} personenbezugList={personenbezugList} onSuccess={onSuccess} />
    </>
  );
};

export default PersonenbezugListing;
