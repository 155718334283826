import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import FormButtons from '../../../components/Button/FormButtons';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { Abrechnungskreis, AbrechnungskreisType } from '../../../types';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';
import {
  abrechnungskreisFormFields,
  AbrechnungskreisFormValues,
  mapAbrechnungskreisToFormValues,
  mapFormValuesToAbrechnungskreis,
} from '../../Abrechnungskreis/Form/abrechnungskreisFormMapper';
import { abrechnungskreisValidationSchema } from '../../Abrechnungskreis/Form/abrechnungskreisValidationSchema';
import AbrechnungskreisTypeSelect from '../../Abrechnungskreis/Form/AbrechnungskreisTypeSelect';
import VerrechnungsUstVomAufwandSelect from '../../Abrechnungskreis/Form/VerrechnungsUstVomAufwandSelect';
import { useCreateAbrechnungskreisTemplateMutation, useUpdateAbrechnungskreisTemplateMutation } from '../gql/AbrechnungskreisTemplateMutations.types';

type Props = {
  abrechnungskreis?: Abrechnungskreis;
  onSuccess: () => void;
  onCancel: () => void;
};

const AbrechnungskreisTemplateForm: FC<Props> = ({ abrechnungskreis, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'Abrechnungskreis'>('Abrechnungskreis');

  const initialValues = mapAbrechnungskreisToFormValues(abrechnungskreis);

  const isUpdate = !!abrechnungskreis;
  const entity = 'Abrechungskreis';

  const [runCreate, { loading: loadingCreate }] = useCreateAbrechnungskreisTemplateMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateAbrechnungskreisTemplateMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<AbrechnungskreisFormValues>
      initialValues={initialValues}
      validationSchema={abrechnungskreisValidationSchema}
      onSubmit={(formValues, formikHelpers) => {
        const input = mapFormValuesToAbrechnungskreis(formValues);
        if (!abrechnungskreis) {
          runCreate({ variables: { input } }).finally(() => formikHelpers.setSubmitting(false));
        } else {
          runUpdate({
            variables: { abrechnungskreisId: abrechnungskreis.abrechnungskreisId, input },
          }).finally(() => formikHelpers.setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithFieldHelp
            name={abrechnungskreisFormFields.bezeichnung}
            label="Bezeichnung"
            fieldHelp={getFieldHelpText('Abrechnungskreis.bezeichnung')}
          >
            <Input
              id={abrechnungskreisFormFields.bezeichnung}
              name={abrechnungskreisFormFields.bezeichnung}
              placeholder='z.B. Abrechnungskreis "leicht zu merken"'
            />
          </FormItemWithFieldHelp>

          <FormItemWithFieldHelp name={abrechnungskreisFormFields.type} label="Typ" fieldHelp={getFieldHelpText('Abrechnungskreis.type')}>
            <AbrechnungskreisTypeSelect name={abrechnungskreisFormFields.type} disabled={abrechnungskreis && !abrechnungskreis.deletable} />
          </FormItemWithFieldHelp>

          {formikProps.values.type === AbrechnungskreisType.ReparaturFonds && (
            <FormItemWithFieldHelp
              name={abrechnungskreisFormFields.verrechnungUstVomAufwand}
              label="Verrechnung Umsatzsteuer vom Aufwand"
              fieldHelp={getFieldHelpText('Abrechnungskreis.verrechnungUstVomAufwand')}
            >
              <VerrechnungsUstVomAufwandSelect
                name={abrechnungskreisFormFields.verrechnungUstVomAufwand}
                disabled={abrechnungskreis && !abrechnungskreis.deletable}
              />
            </FormItemWithFieldHelp>
          )}

          <FormButtons
            updateMode={isUpdate}
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AbrechnungskreisTemplateForm;
