import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import { useUstVomAufwandDeviationListQuery } from './gql/UstVomAufwandDeviationQueries.types';
import { useOnUstVomAufwandDataChangedEvent } from '../useOnUstVomAufwandDataChangedEvent';
import { useUstVomAufwandApplyAllDeviationsMutation } from './gql/UstVomAufwandDeviationMutations.types';
import { showSuccessMsgDeviationsUebernehmen } from '../../../components/message/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { DEVIATIONS_TAB_TITLE } from '../../../components/Deviation/deviationHelpers';
import DeviationTab from '../../../components/Deviation/Tab/DeviationTab';
import { useToggle } from '../../../hooks/useToggle';
import { isUstVomAufwandBuchungStatusNichtVerbucht } from '../../../helpers/statusHelper';
import UstVomAufwandApplyDeviationsModal from './ApplyDeviationsModal/UstVomAufwandApplyDeviationsModal';
import { generatePathToUstVomAufwandDetailsPage } from '../ustVomAufwandUriPaths';
import { UstVomAufwand } from '../../../types';

type Props = {
  objektId: string;
  ustVomAufwand: UstVomAufwand;
  onAction: () => void;
};

const UstVomAufwandDeviationList: FC<Props> = ({ objektId, ustVomAufwand, onAction }) => {
  const { ustVomAufwandId, status, buchungStatus, lastBuchungsdatum } = ustVomAufwand;

  const { data, refetch } = useUstVomAufwandDeviationListQuery({
    variables: {
      objektId,
      ustVomAufwandId,
    },
  });

  const ustVomAufwandDeviationList = data?.getUstVomAufwandDeviationList.data;
  const loading = !ustVomAufwandDeviationList;

  useOnUstVomAufwandDataChangedEvent('ustVomAufwandDeviationList', refetch);

  const [runApplyAllDeviations] = useUstVomAufwandApplyAllDeviationsMutation({
    onCompleted: () => {
      showSuccessMsgDeviationsUebernehmen();
      refetch();
      onAction();
    },
    variables: {
      objektId,
      ustVomAufwandId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [isModalOpen, toggleIsModalOpen] = useToggle();

  const onSuccess = () => {
    showSuccessMsgDeviationsUebernehmen();
    refetch();
    onAction();
    toggleIsModalOpen();
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: DEVIATIONS_TAB_TITLE,
      children: (
        <>
          <DeviationTab
            dataSource={ustVomAufwandDeviationList}
            loading={loading}
            status={status}
            showSourceEntity
            runApplyAllDeviations={
              !isUstVomAufwandBuchungStatusNichtVerbucht(buchungStatus) ? () => toggleIsModalOpen() : () => runApplyAllDeviations()
            }
            reviewErrorUriPath={generatePathToUstVomAufwandDetailsPage(objektId, ustVomAufwandId)}
          />
          <UstVomAufwandApplyDeviationsModal
            objektId={objektId}
            ustVomAufwandId={ustVomAufwandId}
            lastBuchungsdatum={lastBuchungsdatum}
            visible={isModalOpen}
            onSuccess={onSuccess}
            onCancel={toggleIsModalOpen}
          />
        </>
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default UstVomAufwandDeviationList;
