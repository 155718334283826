import React, { FC, useState } from 'react';
import GeneralInfoBlockWithEditButtonOrWarning from '../GeneralInfoBlockWithButtons/GeneralInfoBlockWithEditButtonOrWarning';
import BuchungszeileListBlock from './BuchungszeileListBlock/BuchungszeileListBlock';
import AuszifferungBlock from '../shared/AuszifferungBlock/AuszifferungBlock';
import { FibuBelegSymbolTuple, FibuBuchungszeile, FibuBuchungTypeTuple } from '../../../../types';
import DividerForBooking from '../../shared/DividerForBooking';
import { isFibuBuchungTypeSpesen } from '../shared/bookingHelpers';

type Props = {
  amountFromPersonenkonto: number;
  belegSymbol: FibuBelegSymbolTuple;
  bookingCorrectionInProgress: boolean | 'done';
  bookingSuggestionId: string;
  bookingZahlungId?: string | null;
  buchungType: FibuBuchungTypeTuple;
  buchungskreisRechtstraegerId: string;
  buchungszeileList: FibuBuchungszeile[];
  isEditAllowedBasedOnBuchungType: boolean;
  personenkontoId?: string;
  toggleIsUpdate: () => void;
};

const BookingDetailsWithBookingSuggestionView: FC<Props> = ({
  amountFromPersonenkonto,
  belegSymbol,
  bookingCorrectionInProgress,
  bookingSuggestionId,
  bookingZahlungId,
  buchungType,
  buchungskreisRechtstraegerId,
  buchungszeileList,
  isEditAllowedBasedOnBuchungType,
  personenkontoId,
  toggleIsUpdate,
}) => {
  // hasBookingExistingAuszifferung is responsible for rendering edit button for different booking types in relation to Auszifferung listing:
  // - for Spesen - show the btn always, as it has no Auszifferung listing
  // - for Zahlung and Rücklastschrift - hide by default, show only if there are no existing Auszifferungen or all Auszifferungen are cancelled (storniert)
  // Setting the state depending on the booking type allows to show the edit btn immediately for Spesen and hides it by default for Zahlung and Rücklastschrift (prevents the flash for shortly showing and hiding the btn) unless the Auszifferung listing contains no existing entries or all entries are cancelled (storniert)
  const [hasBookingExistingAuszifferung, setHasBookingExistingAuszifferung] = useState<boolean>(!isFibuBuchungTypeSpesen(buchungType?.value));

  return (
    <>
      {/* Block 1 - General Info with Bearbeitem Button if all Auszifferungen were cancelled (= storniert) */}
      <GeneralInfoBlockWithEditButtonOrWarning
        belegSymbol={belegSymbol}
        bookingSuggestionId={bookingSuggestionId}
        bookingCorrectionInProgress={bookingCorrectionInProgress}
        buchungType={buchungType}
        buchungskreisRechtstraegerId={buchungskreisRechtstraegerId}
        isEditAllowed={isEditAllowedBasedOnBuchungType && !hasBookingExistingAuszifferung}
        toggleIsUpdate={toggleIsUpdate}
      />
      <DividerForBooking />

      {/* Block 2 - Buchungszeilen with Umbuchungen */}
      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
      <BuchungszeileListBlock belegSymbol={belegSymbol?.value} buchungszeileList={buchungszeileList} />

      {/* Block 2 - Auszifferung listing - render ONLY if BuchungType = Zahlung or Rücklastschrift, each Auszifferung can be cancelled (=storniert) => synch function */}
      {bookingZahlungId && (
        <>
          <DividerForBooking />
          <AuszifferungBlock
            amountFromPersonenkonto={amountFromPersonenkonto}
            bookingCorrectionInProgress={bookingCorrectionInProgress}
            bookingZahlungId={bookingZahlungId}
            buchungskreisRechtstraegerId={buchungskreisRechtstraegerId}
            buchungType={buchungType.value}
            setHasBookingExistingAuszifferung={setHasBookingExistingAuszifferung}
            personenkontoId={personenkontoId}
          />
        </>
      )}
    </>
  );
};

export default BookingDetailsWithBookingSuggestionView;
