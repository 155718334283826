import { FormFields, mapFormDateValueToDateString } from '../../../helpers/formikHelper';
import {
  Period,
  PeriodUnit,
  Ticket,
  TicketActionCreateSubTicketInput,
  TicketAttachment,
  TicketBase,
  TicketCreateInput,
  TicketPriority,
  TicketTemplatePeriodInput,
  TicketUpdateInput,
} from '../../../types';

export type TicketFormValues = {
  assigneeMitarbeiterId: string | null;
  attachments: TicketAttachment[];
  description?: string | null;
  dueDate?: string | null;
  participantList: TicketParticipantFormValues[];
  priority: TicketPriority;
  reminder: PeriodFormValues;
  title: string;
  ticketTemplateId?: string | null;
  typeName?: string | null;
};

export type TicketParticipantFormValues = {
  contactId?: string | null;
  emailAddress?: string | null;
  participantId?: string | null;
};

type PeriodFormValues = {
  amount: number | null;
  unit: PeriodUnit | null;
};

export const ticketFormFields: FormFields<TicketFormValues> = {
  attachments: 'attachments',
  assigneeMitarbeiterId: 'assigneeMitarbeiterId',
  description: 'description',
  participantList: 'participantList',
  priority: 'priority',
  dueDate: 'dueDate',
  reminder: 'reminder',
  title: 'title',
  ticketTemplateId: 'ticketTemplateId',
  // Name of the template, used to display in template name in readonly select when updating ticket
  typeName: 'typeName',
};

export const ticketFormInitialValues: TicketFormValues = {
  attachments: [],
  assigneeMitarbeiterId: null,
  dueDate: null,
  reminder: {
    amount: null,
    unit: null,
  },
  participantList: [],
  priority: TicketPriority.Low,
  title: '',
  ticketTemplateId: null,
  typeName: null,
};

export const mapTicketToFormValues = (isSubticket: boolean, ticket?: Ticket, parent?: TicketBase): TicketFormValues => {
  if (!ticket) {
    return {
      ...ticketFormInitialValues,
      ...(parent ? { priority: parent.priority.value, dueDate: parent.dueDate, reminder: mapPeriodToFormValues(parent.reminder) } : {}),
    };
  }

  return {
    attachments: ticket.attachments,
    assigneeMitarbeiterId: ticket.assignee.mitarbeiterId,
    dueDate: ticket.dueDate,
    description: ticket.description,
    participantList: ticket.participantList.map((participant) => ({
      contactId: participant.participant.contact?.contactId,
      emailAddress: participant.participant.emailAddress,
      participantId: participant.participant.participantId,
    })),
    priority: ticket.priority.value,
    reminder: mapPeriodToFormValues(ticket.reminder),
    ticketTemplateId: !isSubticket ? ticket.type?.ticketTemplateId : undefined,
    title: ticket.title,
    typeName: ticket.typeName,
  };
};

export const mapFormValuesToTicketCreateInput = (formValues: TicketFormValues): TicketCreateInput => ({
  ...mapFormValuesToTicketUpdateInput(formValues),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  ticketTemplateId: formValues.ticketTemplateId!,
});

export const mapFormValuesToTicketUpdateInput = (formValues: TicketFormValues): TicketUpdateInput => ({
  assigneeMitarbeiterId: formValues.assigneeMitarbeiterId ?? '',
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  dueDate: mapFormDateValueToDateString(formValues.dueDate!),
  description: formValues.description,
  participantList: formValues.participantList.map((participant) => ({
    contactId: participant.contactId,
    emailAddress: participant.emailAddress,
    participantId: participant.participantId,
  })),
  priority: formValues.priority,
  reminder: mapPeriodValuesToPeriodUnit(formValues.reminder) ?? { amount: 0, unit: PeriodUnit.Days },
  title: formValues.title,
});

export const mapFormValuesToSubTicketCreateInput = (formValues: TicketFormValues): TicketActionCreateSubTicketInput => ({
  ...mapFormValuesToTicketUpdateInput(formValues),
});

export const mapFormValuesToAttachments = (formValues: TicketFormValues) => ({
  attachments: formValues.attachments,
});

const mapPeriodToFormValues = (period?: Period | null) => {
  if (!period) return { amount: null, unit: null };

  const { amount = null, unit } = period;
  return { amount, unit: unit.value };
};

export const mapPeriodValuesToPeriodUnit = (period?: PeriodFormValues): TicketTemplatePeriodInput | null => {
  const { amount = null, unit = null } = period ?? {};
  if (amount === null || unit === null) return null;

  return {
    amount,
    unit,
  };
};
