import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { FilePdfOutlined } from '@ant-design/icons';
import { uriFileOfFirmendaten } from '../../../features/Document/documentApiPaths';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';

type Props = {
  fileId: string;
  belegnummer: string;
  hideIcon?: boolean;
  fontWeight?: 'normal' | 'bold';
};

const LinkToBelegFile: FC<Props> = ({ fileId, belegnummer, hideIcon, fontWeight = 'normal' }) => {
  const { activeForFirmendatenId } = useFDAuthorized();

  return (
    <Link to={uriFileOfFirmendaten(activeForFirmendatenId, fileId, '')} target="_blank" style={{ fontWeight }}>
      {belegnummer} {!hideIcon && <FilePdfOutlined />}
    </Link>
  );
};

export default LinkToBelegFile;
