import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';
import { isAdmin } from '../../../../security/permissionChecks';
import AndromedaSysSettingsUstRegelwerkPage from './AndromedaSysSettingsUstRegelwerkPage';

const andromedaSysSettingsUstRegelwerkPageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS.ustRegelsetPage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsUstRegelwerkPage />} />}
  />
);

export default andromedaSysSettingsUstRegelwerkPageRoute;
