import { Drawer, Typography } from 'antd';
import React, { FC, useState } from 'react';
import IndexThresholdForm from './IndexThreshold/IndexThresholdForm';
import { ValuePreservationType } from '../../../../../../../../types';
import ValuePreservationTypeSelection from '../../../../../../../shared/ValuePreservation/ValuePreservationTypeSelection';
import { Spacer } from '../../../../../../../../components/Grid';
import {
  isValuePreservationTypeCategory,
  isValuePreservationTypeIndexAnnual,
  isValuePreservationTypeIndexThreshold,
  isValuePreservationTypeReferenceValue,
} from './indexationAgreementHelpers';
import IndexAnnualForm from './IndexAnnual/IndexAnnualForm';
import CategoryForm from './Category/CategoryForm';
import ReferenceValueForm from './ReferenceValue/ReferenceValueForm';
import DrawerRow from '../../../../../../../../components/Drawer/DrawerRow';

type Props = {
  isDrawerOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
};

const CreateIndexationAgreementDrawer: FC<Props> = ({ isDrawerOpen, onCancel, onSuccess }) => {
  const [valuePreservationType, setValuePreservationType] = useState<ValuePreservationType | undefined>(undefined);

  const IndexationAgreementTypeSelectionRow = () => (
    <>
      <DrawerRow
        rowTitle="Wertsicherungswert"
        content={
          <>
            <ValuePreservationTypeSelection activeType={valuePreservationType} onSelectionChange={setValuePreservationType} />
            <Spacer height={16} />
            {!valuePreservationType && <Typography.Title level={5}>Bitte wählen Sie einen Typ von Wertsicherungswert aus.</Typography.Title>}
          </>
        }
      />
      <Spacer height={16} />
    </>
  );

  return (
    <Drawer title="Wertsicherungsvereinbarung anlegen" width={720} open={isDrawerOpen} onClose={onCancel} destroyOnClose>
      <IndexationAgreementTypeSelectionRow />
      {isValuePreservationTypeIndexThreshold(valuePreservationType) && <IndexThresholdForm onCancel={onCancel} onSuccess={onSuccess} />}
      {isValuePreservationTypeIndexAnnual(valuePreservationType) && <IndexAnnualForm onCancel={onCancel} onSuccess={onSuccess} />}
      {isValuePreservationTypeCategory(valuePreservationType) && <CategoryForm onCancel={onCancel} onSuccess={onSuccess} />}
      {isValuePreservationTypeReferenceValue(valuePreservationType) && <ReferenceValueForm onCancel={onCancel} onSuccess={onSuccess} />}
    </Drawer>
  );
};

export default CreateIndexationAgreementDrawer;
