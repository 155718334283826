import { Card } from 'antd';
import '../../../components/Cards/styles/cardStyles.css';
import MyTickets from '../MyTickets/MyTickets';

const InboxWorkSpace = () => {
  return (
    <Card size="small" title="Inbox - von mir verfolgte Arbeit">
      <MyTickets />
    </Card>
  );
};

const WorkSpace = () => <InboxWorkSpace />;

export default WorkSpace;
