import { TransferItem } from 'antd/lib/transfer';
import {
  ObjektVorschreibungsposition,
  ObjektVorschreibungspositionStatus,
  Vorschreibungsposition,
  VorschreibungspositionStatus,
} from '../../../../../../types';

export const getIdList = (objektVorschreibungspositionList: ObjektVorschreibungsposition[]) =>
  objektVorschreibungspositionList.map((position) => position.vorschreibungspositionId);

type VorschreibungspositionTransferItem = TransferItem & Vorschreibungsposition;

export const mapToTransferItems = (
  vorschreibungspositionList: Vorschreibungsposition[],
  objektVorschreibungspositionList: ObjektVorschreibungsposition[]
): VorschreibungspositionTransferItem[] => {
  return vorschreibungspositionList
    .filter((vorschreibungsposition) => isVPosNotArchivedOrArchivedAndAssignedToObjekt(vorschreibungsposition, objektVorschreibungspositionList))
    .map((vorschreibungsposition) => addDisabledAndKeyAttr(vorschreibungsposition, objektVorschreibungspositionList));
};

const isVPosNotArchivedOrArchivedAndAssignedToObjekt = (
  vorschreibungsposition: Vorschreibungsposition,
  objektVorschreibungspositionList: ObjektVorschreibungsposition[]
) =>
  vorschreibungsposition.status.value !== VorschreibungspositionStatus.Archiviert ||
  (vorschreibungsposition.status.value === VorschreibungspositionStatus.Archiviert &&
    getIdList(objektVorschreibungspositionList).includes(vorschreibungsposition.vorschreibungspositionId));

const addDisabledAndKeyAttr = (vorschreibungsposition: Vorschreibungsposition, objektVorschreibungspositionList: ObjektVorschreibungsposition[]) => {
  // add disabled key and value to each VPos based on deletable value of available ObjektVPos
  const sameObjectVPosSchluessel = objektVorschreibungspositionList.findIndex(
    (objektVorschreibungsposition) => objektVorschreibungsposition.vorschreibungspositionId === vorschreibungsposition.vorschreibungspositionId
  );

  const isObjektVorschreibungsPositionDisabled =
    sameObjectVPosSchluessel >= 0 &&
    objektVorschreibungspositionList[sameObjectVPosSchluessel].objektVorschreibungspositionStatus.value === ObjektVorschreibungspositionStatus.Aktiv
      ? !objektVorschreibungspositionList[sameObjectVPosSchluessel].deletable
      : true;

  const vorschreibungspositionWithDisabled =
    sameObjectVPosSchluessel >= 0
      ? { disabled: isObjektVorschreibungsPositionDisabled, ...vorschreibungsposition }
      : { disabled: false, ...vorschreibungsposition };

  return {
    key: vorschreibungsposition.vorschreibungspositionId,
    ...vorschreibungspositionWithDisabled,
  };
};
