import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useTicketTypeNameListQuery } from '../gql/TicketQueries.types';

type Props = SelectProps;

const TicketTypeNameSelect: FC<Props> = ({ ...props }) => {
  const { data, loading } = useTicketTypeNameListQuery();

  const typeNameList = data?.getTicketTypeNameList.data || [];

  return (
    <Select loading={loading} size="small" id={props.name} placeholder="Art auswählen" {...props}>
      {typeNameList.map((typeName) => {
        return (
          <Select.Option key={typeName} value={typeName}>
            {typeName}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default TicketTypeNameSelect;
