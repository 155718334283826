import { FC } from 'react';
import HistoryListingTable from '../../../History/HistoryListingTable';
import { HistoryType } from '../../../History/shared/HistoryModal';
import { useMahnungChangeHistoryListQuery } from '../../gql/MahnungQueries.types';

type Props = {
  mahnungId: string;
};

const MahnungChangeHistoryListingTable: FC<Props> = ({ mahnungId }) => {
  const { data, loading } = useMahnungChangeHistoryListQuery({ variables: { mahnungId } });
  const historyList = data?.getMahnungChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="mahnung" />;
};

export default MahnungChangeHistoryListingTable;
