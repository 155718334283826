import { Select, SelectProps } from 'formik-antd';
import React, { FC } from 'react';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';

type Props = {
  list: SyncEbicsUserEbicsStatusFormSelectProps[];
  width?: string;
} & SelectProps;

export interface SyncEbicsUserEbicsStatusFormSelectProps {
  value: string;
  text: string;
}

const SyncEbicsUserEbicsStatusFormSelect: FC<Props> = ({ list, name, width, ...restProps }) => {
  return (
    <Select
      size="small"
      placeholder="EBICS Status auswählen"
      name={name}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      style={{ width: width || '100%' }}
      {...restProps}
    >
      {list.map((bank) => (
        <Select.Option key={bank.value} value={bank.value}>
          <DataWithShortenedText maxTextLength={25} text={bank.text} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default SyncEbicsUserEbicsStatusFormSelect;
