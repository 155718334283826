import { IVersionable } from './timelineCtx';

export type TimelineDataSourceAlwaysDeletableEntry<T extends IVersionable<T>> = AlwaysDeletableEntry<T> & {
  historicizedList?: AlwaysDeletableEntry<T>[] | null;
};

type AlwaysDeletableEntry<T extends IVersionable<T>> = Omit<T, 'historicizedList'> &
  Required<Pick<IVersionable<T>, 'deletable'>> & { deletable: true };

export function mapToVersionTimelineDataSourceWithAlwaysDeletableEntries<T extends IVersionable<T>>(
  versionList: T[]
): TimelineDataSourceAlwaysDeletableEntry<T>[] {
  return versionList.map((item) => ({
    ...item,
    deletable: true,
    historicizedList: item.historicizedList ? mapToVersionTimelineDataSourceWithAlwaysDeletableEntries(item.historicizedList) : [],
  }));
}

export type ValuesDescriptionForKommentar<FormValues, KommentarFieldName extends string = 'kommentar'> = Record<
  keyof Omit<FormValues, KommentarFieldName>,
  string
>;

export type ValuesDescriptions<FormValues> = Record<keyof FormValues, string>;
