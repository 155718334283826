import { FC } from 'react';
import { useMahnsperreChangeHistoryListQuery } from '../gql/VertragspartnerQueries.types';
import { HistoryType } from '../../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../../History/HistoryListingTable';

type Props = {
  rechtstraegerId: string;
};

const MahnsperreChangeHistoryListingTable: FC<Props> = ({ rechtstraegerId }) => {
  const { data, loading } = useMahnsperreChangeHistoryListQuery({ variables: { rechtstraegerId } });
  const historyList = data?.getMahnsperreChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="mahnsperre" />;
};

export default MahnsperreChangeHistoryListingTable;
