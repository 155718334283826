import React, { FC, useEffect, useState } from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import TableListEmpty from '../../../components/Helpers/TableListEmpty';
import { bestandseinheitTableColumns } from './bestandseinheitTableColumns';
import BestandseinheitVertragDetails from './VertragDetails/BestandseinheitVertragDetails';
import TableWithColSelector from '../../../components/Table/TableWithColSelector/TableWithColSelector';
import { useChangePositionBestandseinheitMutation } from '../gql/BestandseinheitMutations.types';
import { showSuccessMsgOther } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import TableDragAndDropSortableRow from '../../../components/Table/TableDragAndDropSortableRow';
import { BestandseinheitListEntry } from '../../../types';
import { StyledBeTable } from './styled/StyledBeTable.style';

type Props = {
  bestandseinheitListData: BestandseinheitListEntry[];
  loading: boolean;
  isWegObjekt: boolean;
  refetch: () => void;
  objektId: string;
  onCollapse: () => void;
  onActionSuccess: () => Promise<unknown>;
};

const BestandseinheitTable: FC<Props> = ({
  bestandseinheitListData,
  loading,
  objektId,
  isWegObjekt,
  refetch,
  onCollapse,
  onActionSuccess,
  ...restProps
}) => {
  const [bestandseinheitList, setBestandseinheitList] = useState(bestandseinheitListData);

  useEffect(() => {
    !loading && setBestandseinheitList(bestandseinheitListData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const [runChangePosition] = useChangePositionBestandseinheitMutation({
    onCompleted: (data) => {
      const bezeichnung = data.actionChangePositionBestandseinheit.data.bezeichnung;
      showSuccessMsgOther(`Die Position der Bestandseinheit ${bezeichnung} hat sich geändert`);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setBestandseinheitList((previous) => {
        const current = bestandseinheitList.find((i) => i.bestandseinheitId === active.id);
        const activeIndex = previous.findIndex((i) => i.bestandseinheitId === active.id);
        const overIndex = previous.findIndex((i) => i.bestandseinheitId === over?.id);

        if (overIndex >= 0) {
          runChangePosition({
            variables: {
              objektId,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              bestandseinheitId: current!.bestandseinheitId,
              newPosition: overIndex,
            },
          });
          return arrayMove(previous, activeIndex, overIndex);
        }
        return previous;
      });
    }
  };

  return bestandseinheitList.length > 0 ? (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        // rowKey array
        items={bestandseinheitList.map((i) => i.bestandseinheitId)}
        strategy={verticalListSortingStrategy}
      >
        <StyledBeTable<BestandseinheitListEntry>
          size="small"
          scroll={{ x: 900 }}
          dataSource={bestandseinheitList}
          components={{
            body: {
              row: TableDragAndDropSortableRow,
            },
          }}
          columns={bestandseinheitTableColumns(objektId, isWegObjekt)}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 20,
            pageSizeOptions: [10, 20, 50, 100, bestandseinheitList.length],
          }}
          rowKey={(record) => record.bestandseinheitId}
          expandable={{
            expandedRowRender: (record) => (
              <BestandseinheitVertragDetails
                objektId={objektId}
                bestandseinheit={record}
                onActionSuccess={onActionSuccess}
                isWegObjekt={isWegObjekt}
              />
            ),
          }}
          filterIdentifier="objekt-bestandseinheit"
          {...restProps}
        />
      </SortableContext>
    </DndContext>
  ) : (
    <TableWithColSelector<BestandseinheitListEntry>
      size="small"
      locale={{
        emptyText: <TableListEmpty onClick={onCollapse} emptyTxt="Keine Bestandseinheit vorhanden" btnTxt="Bestandseinheit erstellen" />,
      }}
      columns={bestandseinheitTableColumns(objektId, isWegObjekt)}
      filterIdentifier="objekt-bestandseinheit"
      dataSource={[]}
    />
  );
};

export default BestandseinheitTable;
