import React, { FC } from 'react';
import { Drawer, Dropdown, MenuProps, Modal } from 'antd';
import { DeleteOutlined, DownOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { useToggle } from '../../../hooks/useToggle';
import { showSuccessMsgDelete } from '../../../components/message';
import TextbausteinForm from '../Form/TextbausteinForm';
import { useDeleteTextbausteinMutation } from '../gql/TextbausteinMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { GeneralTextbausteinFieldsFragment } from '../gql/TextbausteinFragments.types';
import { radixActionStyles } from '../../../helpers/radixIconsStyles';
import HistoryModal, { HistoryType } from '../../History/shared/HistoryModal';
import TextbausteinChangeHistoryListingTable from './TextbausteinChangeHistoryListingTable';

type Props = {
  textbaustein: GeneralTextbausteinFieldsFragment;
  onAction: () => void;
};

const TextbausteinTableActions: FC<Props> = ({ textbaustein, onAction }) => {
  const [isDrawerOpen, toggleDrawerOpen] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();
  const entity = `Text`;

  const [runDelete] = useDeleteTextbausteinMutation({
    variables: {
      textbausteinId: textbaustein.textbausteinId,
    },
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    { key: '1', label: 'Bearbeiten', onClick: toggleDrawerOpen, icon: <EditOutlined /> },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '3',
      label: 'Löschen',
      onClick: showConfirmDelete(runDelete, textbaustein),
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Aktion <DownOutlined />
        </a>
      </Dropdown>
      <Drawer title="Text bearbeiten" width={720} open={isDrawerOpen} onClose={toggleDrawerOpen} destroyOnClose>
        <TextbausteinForm
          textbaustein={textbaustein}
          onSubmit={() => {
            onAction();
            toggleDrawerOpen();
          }}
          onCancel={toggleDrawerOpen}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <TextbausteinChangeHistoryListingTable textbausteinId={textbaustein.textbausteinId} />
      </HistoryModal>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void, textbaustein: GeneralTextbausteinFieldsFragment) => () => {
  Modal.confirm({
    title: `Möchten Sie den Text löschen?`,
    content: textbaustein.bezeichnung,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default TextbausteinTableActions;
