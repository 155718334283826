import { Formik } from 'formik';
import React, { FC } from 'react';
import { Form } from 'formik-antd';
import { Box } from 'rebass';
import { Divider } from 'antd';
import { getPreviousTimeblockBefore, ITimeblock, useTimeline } from '../../../../../components/Timeline/timelineCtx';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import TimelineFormDatePicker from '../../../../../components/Timeline/Form/TimelineFormDatePicker';
import TimelineFormButtons from '../../../../../components/Timeline/Form/TimelineFormButtons';
import { layoutTimelineForm } from '../../../../../components/Input-antd/formLayoutHelper';
import {
  mapFormValuesToBestandseinheitVersion,
  mapTimeblockToFormValues,
  nutzwertVersionFormFields,
  NutzwertVersionFormValues,
  nutzwertVersionFormValuesDescriptionsForKommentar,
} from './nutzwertVersionFormMapper';
import { BENutzwertVersionTimelineDataSourceEntry } from '../nutzwertVersionTimelineMapper';
import TimelineFormKommentar from '../../../../../components/Timeline/Form/TimelineFormKommentar';
import {
  useCreateBestandseinheitNutzwertVersionMutation,
  useUpdateBestandseinheitNutzwertVersionMutation,
} from '../gql/NutzwertVersionMutations.types';
import { nutzwertVersionFormValidationSchema } from './nutzwertVersionFormValidationSchema';
import { gqlOperations } from '../../../../../graphql/gqlNamedOperations-generated';
import FormattedDecimalFormInput from '../../../../../components/Input-antd/FormattedDecimalFormInput';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import { useGetAppFieldHelpText } from '../../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../../components/Form/FormItemWithFieldHelp';

type NutzwertVersionFormProps = {
  timeblock: ITimeblock<BENutzwertVersionTimelineDataSourceEntry>;
  objektId: string;
  bestandseinheitId: string;
  onSubmitSuccess: () => void;
};

const NutzwertVersionForm: FC<NutzwertVersionFormProps> = ({ timeblock, objektId, bestandseinheitId, onSubmitSuccess }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'BeNutzwert'>('BeNutzwert');

  const { dataSource, toggleEdit, changeToUpdateMode } = useTimeline<BENutzwertVersionTimelineDataSourceEntry>();
  const initialValues = mapTimeblockToFormValues(timeblock);
  const isUpdate = !!timeblock.beNutzwertId;

  const entity = 'Nutzwert';

  const [runCreate, { loading: loadingCreate }] = useCreateBestandseinheitNutzwertVersionMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      changeToUpdateMode(timeblock.uuid);
      onSubmitSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
    refetchQueries: () => [
      gqlOperations.Query.BestandseinheitAufteilungsschluesselList,
      gqlOperations.Query.BestandseinheitAufteilungsschluesselWertAenderungList,
    ],
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateBestandseinheitNutzwertVersionMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      toggleEdit(timeblock.uuid);
      onSubmitSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
    refetchQueries: () => [
      gqlOperations.Query.BestandseinheitAufteilungsschluesselList,
      gqlOperations.Query.BestandseinheitAufteilungsschluesselWertAenderungList,
    ],
  });

  return (
    <Formik<NutzwertVersionFormValues>
      initialValues={initialValues}
      validationSchema={nutzwertVersionFormValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (isUpdate) {
          runUpdate({
            variables: {
              objektId,
              bestandseinheitId,
              nutzwertVersionId: timeblock.beNutzwertId,
              nutzwertVersion: mapFormValuesToBestandseinheitVersion(values),
            },
          }).finally(() => setSubmitting(false));
        } else {
          runCreate({
            variables: {
              objektId,
              bestandseinheitId,
              nutzwertVersion: mapFormValuesToBestandseinheitVersion(values),
            },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form {...layoutTimelineForm} layout="horizontal" labelAlign="left">
          <Box width={2 / 3}>
            <FormItemWithFieldHelp name={nutzwertVersionFormFields.validFrom} label="Gültig ab" fieldHelp={getFieldHelpText('BeNutzwert.validFrom')}>
              <TimelineFormDatePicker<BENutzwertVersionTimelineDataSourceEntry> name={nutzwertVersionFormFields.validFrom} timeblock={timeblock} />
            </FormItemWithFieldHelp>

            <FormItemWithFieldHelp
              name={nutzwertVersionFormFields.nutzwert}
              label="Nutzwert"
              style={{ margin: 0 }}
              fieldHelp={getFieldHelpText('BeNutzwert.nutzwert')}
            >
              <FormattedDecimalFormInput
                style={{ width: '100%' }}
                size="small"
                id={nutzwertVersionFormFields.nutzwert}
                name={nutzwertVersionFormFields.nutzwert}
                placeholder="z.B. 87"
                fractionDigits={0}
              />
            </FormItemWithFieldHelp>
          </Box>

          <Divider style={{ padding: 0, marginTop: 0, marginBottom: '16px' }} />

          <TimelineFormKommentar
            name={nutzwertVersionFormFields.kommentar}
            timeblock={timeblock}
            valuesDescriptions={nutzwertVersionFormValuesDescriptionsForKommentar}
          />

          <TimelineFormButtons<BENutzwertVersionTimelineDataSourceEntry>
            timeblock={timeblock}
            isSubmitting={formikProps.isSubmitting}
            isSameAsPreviousVersion={isSameAsPreviousVersion(
              formikProps.values,
              getPreviousTimeblockBefore(dataSource, dayjsCustom(formikProps.values.validFrom))
            )}
            submitBtnLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

const isSameAsPreviousVersion = (formValues: NutzwertVersionFormValues, previousTimeblock?: ITimeblock<BENutzwertVersionTimelineDataSourceEntry>) =>
  formValues.nutzwert === previousTimeblock?.nutzwert;

export default NutzwertVersionForm;
