import { Divider } from 'antd';
import React, { FC } from 'react';
import { SingleInvoice } from '../../../types';
import BuchungPositionContentBlock from './shared/BuchungPositionContentBlock';
import theme from '../../../theme';

type Props = {
  incomingInvoice: SingleInvoice;
};

const SingleInvoiceBuchungPositionBlock: FC<Props> = ({ incomingInvoice }) => {
  return (
    <>
      <Divider style={{ borderColor: theme.colors.black }}>Buchungspositionen</Divider>
      <BuchungPositionContentBlock buchungPositionList={incomingInvoice.buchungPositionList} />
    </>
  );
};
export default SingleInvoiceBuchungPositionBlock;
