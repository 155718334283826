import React, { FC } from 'react';
import { Skeleton } from 'antd';
import {
  useBkAbrechnungsdefinitionVersionListQuery,
  useBkAbrechnungsdefinitionVersionQuery,
} from '../../../../../../features/Abrechnungsdefinition/gql/BkAbrDef/BkAbrDefVersionQueries.types';
import BkAbrDefVersionUpdateForm from '../../../../../../features/Abrechnungsdefinition/Version/Form/Update/BkAbrDefVersionUpdateForm';

type Props = {
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersionId: string;
};
const SysSettingsBkAbrechnungsdefinitionVersionUpdatePage: FC<Props> = ({ abrechnungsdefinitionId, abrechnungsdefinitionVersionId }) => {
  const { data: bkAbrDefVersionListData } = useBkAbrechnungsdefinitionVersionListQuery({
    variables: { abrechnungsdefinitionId, withDetails: true },
  });
  const abrechnungsdefinitionVersionList = bkAbrDefVersionListData?.getBkAbrechnungsdefinitionVersionList.data;

  const { data: bkAbrDefVersion, refetch } = useBkAbrechnungsdefinitionVersionQuery({
    variables: { abrechnungsdefinitionId, abrechnungsdefinitionVersionId },
  });
  const abrechnungsdefinitionVersion = bkAbrDefVersion?.getBkAbrechnungsdefinitionVersion.data;

  if (!abrechnungsdefinitionVersion || !abrechnungsdefinitionVersionList) return <Skeleton active />;

  const firmendatenId = abrechnungsdefinitionVersion.firmendatenId ?? '';

  return (
    <BkAbrDefVersionUpdateForm
      abrechnungsdefinitionVersionList={abrechnungsdefinitionVersionList}
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      abrechnungsdefinitionVersion={abrechnungsdefinitionVersion}
      validFrom={abrechnungsdefinitionVersion.validFrom}
      kommentar={abrechnungsdefinitionVersion.kommentar ?? ''}
      onSuccess={refetch}
      firmendatenId={firmendatenId}
    />
  );
};

export default SysSettingsBkAbrechnungsdefinitionVersionUpdatePage;
