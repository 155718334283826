import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { NestedTableColProps } from '../../../../../components/Table/NestedTable/NestedTable';
import { PaymentClearingBooking } from '../../../../../types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { EuroAmount } from '../../../../../components/Number';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../../../../components/Card';
import AssignedPaymentColumn from './AssignedPaymentColumn';
import { generatePathToBankstatementListingPage } from '../../../../DataCarrier/Listing/Bankstatement/Filters/filtersQueryParams';
import LinkToBelegFile from '../../../../../components/Link/LinkToBelegFile/LinkToBelegFile';
import { generatePathToBookingDetailsPage } from '../../../../BookingDetails/bookingDetailsPaths';

const bookingTableColumns = (vertragspartnerId: string, vertragspartnerName: string): NestedTableColProps<PaymentClearingBooking>[] => [
  {
    title: 'Buchungstyp',
    render: (text, record) => <DataWithShortenedText maxTextLength={20} text={record.buchungsType.text} />,
  },
  {
    title: 'Beleg-Nr',
    render: (text, record) => {
      if (record.dataCarrierBelegId) {
        return (
          <DataWithShortenedText maxTextLength={15} text={record.belegnummer}>
            {(shortenedText) => (
              <Link
                to={generatePathToBankstatementListingPage({
                  iban: record.iban,
                  statementNumber: record.statementNumber,
                })}
                target="_blank"
              >
                {shortenedText}
              </Link>
            )}
          </DataWithShortenedText>
        );
      }

      if (record.belegFileId) {
        return <LinkToBelegFile fileId={record.belegFileId} belegnummer={record.belegnummer} />;
      }

      return <Typography.Text>{record.belegnummer}</Typography.Text>;
    },
  },
  {
    title: 'Beleg-Dat',
    render: (text, record) => <CustomFormattedDate value={record.belegDatum} />,
  },
  {
    title: 'Buchungs-Dat',
    render: (text, record) => <CustomFormattedDate value={record.buchungsdatum} />,
  },
  {
    title: 'Betrag',
    render: (text, record) => (
      <Link to={generatePathToBookingDetailsPage([record.buchungId])} target="_blank">
        <EuroAmount value={record.amount} />
      </Link>
    ),
  },
  {
    title: 'Ausgezifferter Betrag',
    render: (text, record) => <EuroAmount value={record.clearedAmount} />,
  },
  {
    title: 'Verfügbarer Zahlungsbetrag',
    render: (text, record) => <EuroAmount value={record.availableAmount} />,
  },
  {
    title: 'Zugewiesene Zahlung',
    render: (text, record) => (
      <AssignedPaymentColumn buchungId={record.buchungId} vertragspartnerId={vertragspartnerId} vertragspartnerName={vertragspartnerName} />
    ),
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    render: (text, record) => (
      <Link to={generatePathToBookingDetailsPage([record.buchungId])} target="_blank">
        Buchungsdetails
      </Link>
    ),
  },
];

export default bookingTableColumns;
