import styled from 'styled-components';
import { Typography } from 'antd';

type Props = {
  isAvatar?: boolean;
};

const StyledTypographyText = styled(Typography.Text)<Props>`
  &:not(a, .ant-tag) {
    color: ${(props) => (!props.isAvatar ? 'rgba(0, 0, 0, 0.25) !important' : '')};
  }
  * {
    &.ant-tag {
      opacity: 0.45;
    }
    &:not(a, .ant-tag) {
      color: ${(props) => (!props.isAvatar ? 'rgba(0, 0, 0, 0.25) !important' : '')};
    }

    &.ant-badge-count {
      opacity: 0.45;
      * {
        color: white !important;
      }
    }

    &.ant-avatar {
      background-color: rgba(0, 0, 0, 0.25) !important;

      & > span {
        color: white !important;
      }
    }
    & svg {
      fill: currentColor;
    }
  }
`;

export default StyledTypographyText;
