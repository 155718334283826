import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { BookingSuggestionVerbuchenGenerierlauf } from '../../../types';
import { BookingSuggestionVerbuchenPageRouteParams } from './routes';
import menuListBookingSuggestionVerbuchen from './menuListBookingSuggestionVerbuchen';
import { useBookingSuggestionVerbuchenGenerierlaufQuery } from '../../../features/Verarbeitung/gql/BookingSuggestionVerbuchenGenerierlauf/BookingSuggestionVerbuchenGenerierlaufQueries.types';

const BookingSuggestionVerbuchenDetailsPage = () => {
  const { generierlaufId } = useParams() as BookingSuggestionVerbuchenPageRouteParams;

  const { data } = useBookingSuggestionVerbuchenGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getBookingSuggestionVerbuchenGenerierlauf.data;

  return (
    <DetailsPageTemplate<BookingSuggestionVerbuchenGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.BookingSuggestionVerbuchen}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard generierlauf={generierlauf} cardTitle={verarbeitungDetailsCardTitles.BookingSuggestionVerbuchen} />
      )}
      isLoading={false}
      sidebarMenuList={menuListBookingSuggestionVerbuchen}
    />
  );
};

export default BookingSuggestionVerbuchenDetailsPage;
