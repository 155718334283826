import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { Input } from 'formik-antd';
import { PostofficeBoxAddressCreateFormValues } from '../Create/postofficeBoxAddressCreateFormMapper';
import CountryCodeSelect from '../../../../../../shared/components/CountryCode/CountryCodeSelect';
import { FormFields } from '../../../../../../helpers/formikHelper';
import { useGetAppFieldHelpText } from '../../../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  formFields: FormFields<PostofficeBoxAddressCreateFormValues>;
};

const StreetAddressFormInputs: FC<Props> = ({ formFields }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'AddressPostofficeBox'>('AddressPostofficeBox');

  return (
    <>
      <FormItemWithFieldHelp
        name={formFields.postofficeBoxNumber}
        label="Postfachnummer"
        fieldHelp={getFieldHelpText('AddressPostofficeBox.postofficeBoxNumber')}
      >
        <Input id={formFields.postofficeBoxNumber} name={formFields.postofficeBoxNumber} placeholder="Postfachnummer" />
      </FormItemWithFieldHelp>
      <Row gutter={8}>
        <Col span={10}>
          <FormItemWithFieldHelp
            name={formFields.zipCode}
            label="Postleitzahl"
            fieldHelp={getFieldHelpText('AddressPostofficeBox.postofficeBoxZipCode')}
          >
            <Input id={formFields.zipCode} name={formFields.zipCode} type="text" placeholder="Postleitzahl" />
          </FormItemWithFieldHelp>
        </Col>
        <Col span={14}>
          <FormItemWithFieldHelp name={formFields.city} label="Ort" fieldHelp={getFieldHelpText('AddressPostofficeBox.city')}>
            <Input id={formFields.city} name={formFields.city} type="text" placeholder="Ort" />
          </FormItemWithFieldHelp>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={10}>
          <FormItemWithFieldHelp
            name={formFields.cityAdditionalInformation}
            label="Ortzusatz"
            fieldHelp={getFieldHelpText('AddressPostofficeBox.cityAdditionalInformation')}
          >
            <Input id={formFields.cityAdditionalInformation} name={formFields.cityAdditionalInformation} type="text" placeholder="Ortzusatz" />
          </FormItemWithFieldHelp>
        </Col>
        <Col span={14}>
          <CountryCodeSelect name={formFields.countryCodeIso2} fieldHelp={getFieldHelpText('AddressPostofficeBox.countryCodeIso2')} />
        </Col>
      </Row>
    </>
  );
};

export default StreetAddressFormInputs;
