import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import { useKontoTypeListQuery } from '../../gql/KontoTypeQueries.types';

type Props = {
  onChange: () => void;
  name: string;
} & SelectProps;

const KontoType: FC<Props> = ({ name, onChange, ...restProps }) => {
  const { loading, data } = useKontoTypeListQuery();

  const kontotypeList = data?.getKontoTypeList?.data || [];

  return (
    <FormItemWithoutColon name={name} label="Typ">
      <Select mode="multiple" allowClear id={name} name={name} loading={loading} placeholder="Kontotyp auswählen" onChange={onChange} {...restProps}>
        {kontotypeList.map((kontotype) => (
          <Select.Option key={kontotype.value} value={kontotype.value}>
            {kontotype.text}
          </Select.Option>
        ))}
      </Select>
    </FormItemWithoutColon>
  );
};

export default KontoType;
