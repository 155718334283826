import React from 'react';
import { Link } from 'react-router-dom';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { MitarbeiterTooltip } from '../../../../../components/Card';
import GenerierlaufEntryStatus from '../../../shared/GenerierlaufEntryStatus';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { CamtGenerierlaufEntry, CamtGenerierlaufOrderBy } from '../../../../../types';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import EbicsDataCarrierDetailsTable from './EbicsDataCarrierDetailsTable';
import { generatePathToEbicsUserPage } from '../../../../PaymentTransaction/EbicsUser/Listing/Filters/filtersQueryParams';

const camtEbicsEntryTableColumns = (): TableWithColSelectorColumnProps<CamtGenerierlaufEntry>[] => [
  {
    title: 'Status',
    defaultSelected: true,
    dataIndex: CamtGenerierlaufOrderBy.Status,
    sorter: true,
    render: (_, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },
  {
    title: 'Mitarbeiter',
    defaultSelected: true,
    dataIndex: CamtGenerierlaufOrderBy.MitarbeiterName,
    sorter: true,
    render: (text, record) =>
      record.ebicsUser ? (
        <DataWithShortenedText text={record.ebicsUser.mitarbeiterName} maxTextLength={15}>
          {(shortenedText) => (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <Link to={generatePathToEbicsUserPage({ highlightedRowKey: record.ebicsUser!.ebicsUserId }, undefined)} target="_blank">
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Teilnehmer',
    defaultSelected: true,
    dataIndex: CamtGenerierlaufOrderBy.EbicsUserName,
    sorter: true,
    render: (text, record) =>
      record.ebicsUser && record.ebicsUser.name ? (
        <DataWithShortenedText text={record.ebicsUser.name} maxTextLength={30} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Teilnehmerkennung',
    defaultSelected: true,
    dataIndex: CamtGenerierlaufOrderBy.EbicsUserUserId,
    sorter: true,
    render: (text, record) =>
      record.ebicsUser ? <DataWithShortenedText text={record.ebicsUser.userId} maxTextLength={30} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Datenträger',
    defaultSelected: true,
    render: (_, record) =>
      record.dataCarrierList.length > 0 ? (
        <EbicsDataCarrierDetailsTable dataCarrierList={record.dataCarrierList} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
];

export default camtEbicsEntryTableColumns;
