import React from 'react';
import { ColumnProps } from 'antd/es/table';
import { Typography } from 'antd';
import { MitarbeiterTooltip } from '../../../../../../../components/Card';
import { Belegnummernkreis } from '../../../../../../../types';
import FormattedDateTime from '../../../../../../../components/FormattedDateTime/FormattedDateTime';

const belegnummernkreisTableColumns: ColumnProps<Belegnummernkreis>[] = [
  {
    title: 'Bezeichnung',
    render: (text, record) => <Typography.Text>{record.bezeichnung}</Typography.Text>,
  },
  {
    title: 'Jahr',
    render: (text, record) => <Typography.Text>{record.jahr}</Typography.Text>,
  },
  {
    title: 'Nächste zu vergebende Nummmer',
    render: (text, record) => <Typography.Text>{record.nextBelegnummer}</Typography.Text>,
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
];

export default belegnummernkreisTableColumns;
