import React, { FC } from 'react';
import { View } from '@react-pdf/renderer';
import ZVPosListSummaryList from './ZVPosListSummaryList';
import ZVPosListSummaryListHeader from './ZVPosListSummaryListHeader';
import { Zinsliste } from '../../zinsliste-types';

type Props = {
  zinsliste: Zinsliste;
};

const ZVPosListSummaryBlock: FC<Props> = ({ zinsliste }) => {
  return (
    <View>
      <ZVPosListSummaryListHeader vPosSummaryHeaderText={zinsliste.vorschreibungspositionSummaryHeaderText} />
      <ZVPosListSummaryList
        vPosVertragsartListSummary={zinsliste.vorschreibungspositionSummary}
        vPosHeaderText={zinsliste.vorschreibungspositionHeaderText}
      />
    </View>
  );
};

export default ZVPosListSummaryBlock;
