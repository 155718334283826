import React, { FC } from 'react';
import { newContactObject } from './contactMapper';
import EmailFieldArrayInput from './EmailFieldArrayInput';
import PhoneNumberFieldArrayInput from './PhoneNumberFieldArrayInput';

type Props = {
  fieldNamePrefix?: string;
};

const ContactListFormPart: FC<Props> = ({ fieldNamePrefix = '' }) => (
  <>
    <EmailFieldArrayInput
      arrayName={`${fieldNamePrefix}emailList`}
      arrayObjectAttribute="wert"
      newFieldValue={newContactObject}
      validateOnChange={false}
    />

    <PhoneNumberFieldArrayInput
      arrayName={`${fieldNamePrefix}phoneList`}
      arrayObjectAttribute="wert"
      newFieldValue={newContactObject}
      validateOnChange={false}
    />
  </>
);

export default ContactListFormPart;
