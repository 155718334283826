import styled from 'styled-components';
import { Form } from 'formik-antd';
import { Space } from 'antd';

export const StyledFiltersFormWithMax3Fields = styled(Form)`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const StyledFiltersFormWithMoreThan4Fields = styled(Form)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const StyledFiltersSelectSpace = styled(Space)`
  min-width: 688px;
  width: 688px;
  > .ant-space-item:nth-child(2) {
    flex: 1;
  }
`;
