import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSubAdministrationAbrechnungsdefinitionTemplateMutationVariables = Types.Exact<{
  input: Types.SubAdministrationAbrechnungsdefinitionCreateInput;
}>;

export type CreateSubAdministrationAbrechnungsdefinitionTemplateMutation = {
  createSubAdministrationAbrechnungsdefinitionTemplate: {
    data: { abrechnungsdefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateSubAdministrationAbrechnungsdefinitionTemplateMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  input: Types.SubAdministrationAbrechnungsdefinitionUpdateInput;
}>;

export type UpdateSubAdministrationAbrechnungsdefinitionTemplateMutation = {
  updateSubAdministrationAbrechnungsdefinitionTemplate: {
    data: { abrechnungsdefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateSubAdministrationAbrechnungsdefinitionTemplateDocument = gql`
  mutation CreateSubAdministrationAbrechnungsdefinitionTemplate($input: SubAdministrationAbrechnungsdefinitionCreateInput!) {
    createSubAdministrationAbrechnungsdefinitionTemplate(input: $input) {
      data {
        abrechnungsdefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateSubAdministrationAbrechnungsdefinitionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSubAdministrationAbrechnungsdefinitionTemplateMutation,
    CreateSubAdministrationAbrechnungsdefinitionTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSubAdministrationAbrechnungsdefinitionTemplateMutation,
    CreateSubAdministrationAbrechnungsdefinitionTemplateMutationVariables
  >(CreateSubAdministrationAbrechnungsdefinitionTemplateDocument, options);
}
export type CreateSubAdministrationAbrechnungsdefinitionTemplateMutationHookResult = ReturnType<
  typeof useCreateSubAdministrationAbrechnungsdefinitionTemplateMutation
>;
export type CreateSubAdministrationAbrechnungsdefinitionTemplateMutationResult =
  Apollo.MutationResult<CreateSubAdministrationAbrechnungsdefinitionTemplateMutation>;
export type CreateSubAdministrationAbrechnungsdefinitionTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateSubAdministrationAbrechnungsdefinitionTemplateMutation,
  CreateSubAdministrationAbrechnungsdefinitionTemplateMutationVariables
>;
export const UpdateSubAdministrationAbrechnungsdefinitionTemplateDocument = gql`
  mutation UpdateSubAdministrationAbrechnungsdefinitionTemplate(
    $abrechnungsdefinitionId: ID!
    $input: SubAdministrationAbrechnungsdefinitionUpdateInput!
  ) {
    updateSubAdministrationAbrechnungsdefinitionTemplate(abrechnungsdefinitionId: $abrechnungsdefinitionId, input: $input) {
      data {
        abrechnungsdefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateSubAdministrationAbrechnungsdefinitionTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSubAdministrationAbrechnungsdefinitionTemplateMutation,
    UpdateSubAdministrationAbrechnungsdefinitionTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSubAdministrationAbrechnungsdefinitionTemplateMutation,
    UpdateSubAdministrationAbrechnungsdefinitionTemplateMutationVariables
  >(UpdateSubAdministrationAbrechnungsdefinitionTemplateDocument, options);
}
export type UpdateSubAdministrationAbrechnungsdefinitionTemplateMutationHookResult = ReturnType<
  typeof useUpdateSubAdministrationAbrechnungsdefinitionTemplateMutation
>;
export type UpdateSubAdministrationAbrechnungsdefinitionTemplateMutationResult =
  Apollo.MutationResult<UpdateSubAdministrationAbrechnungsdefinitionTemplateMutation>;
export type UpdateSubAdministrationAbrechnungsdefinitionTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubAdministrationAbrechnungsdefinitionTemplateMutation,
  UpdateSubAdministrationAbrechnungsdefinitionTemplateMutationVariables
>;
