import { FormikProps } from 'formik';
import { getFirmennameFields } from '../../../../shared/components/Firmenname/form/firmennameFormPartHelper';
import { URL_KURZBEZEICHNUNG_VORSCHLAG_FIRMENDATEN } from '../../../../shared/api/ApiPaths';
import getKurzBezeichnungVorschlagText from '../../../../shared/api/KurzBezeichnungVorschlagApi';
import { FirmendatenFormValues } from './fimendatenFormMapper';

export const getKurzBezeichnungVorschlagFieldsToBeDebounced = (formikProps: FormikProps<FirmendatenFormValues>) => ({
  ...getFirmennameFields(formikProps),
});

export const getFirmendatenKurzBezeichnungVorschlag = (kurzBezeichnungVorschlagFields: FirmendatenFormValues) =>
  getKurzBezeichnungVorschlagText(URL_KURZBEZEICHNUNG_VORSCHLAG_FIRMENDATEN, kurzBezeichnungVorschlagFields);
