import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateVorsteuerkuerzungEinstellungTemplateMutationVariables = Types.Exact<{
  input: Types.VorsteuerkuerzungEinstellungCreateInput;
}>;

export type CreateVorsteuerkuerzungEinstellungTemplateMutation = {
  createVorsteuerkuerzungEinstellungTemplate: {
    data: { validFrom: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateVorsteuerkuerzungEinstellungTemplateMutationVariables = Types.Exact<{
  vorsteuerkuerzungEinstellungId: Types.Scalars['ID']['input'];
  input: Types.VorsteuerkuerzungEinstellungUpdateInput;
}>;

export type UpdateVorsteuerkuerzungEinstellungTemplateMutation = {
  updateVorsteuerkuerzungEinstellungTemplate: {
    data: { validFrom: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteVorsteuerkuerzungEinstellungTemplateMutationVariables = Types.Exact<{
  vorsteuerkuerzungEinstellungId: Types.Scalars['ID']['input'];
}>;

export type DeleteVorsteuerkuerzungEinstellungTemplateMutation = {
  deleteVorsteuerkuerzungEinstellungTemplate: {
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateVorsteuerkuerzungEinstellungTemplateDocument = gql`
  mutation CreateVorsteuerkuerzungEinstellungTemplate($input: VorsteuerkuerzungEinstellungCreateInput!) {
    createVorsteuerkuerzungEinstellungTemplate(input: $input) {
      data {
        validFrom
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateVorsteuerkuerzungEinstellungTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVorsteuerkuerzungEinstellungTemplateMutation,
    CreateVorsteuerkuerzungEinstellungTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVorsteuerkuerzungEinstellungTemplateMutation, CreateVorsteuerkuerzungEinstellungTemplateMutationVariables>(
    CreateVorsteuerkuerzungEinstellungTemplateDocument,
    options
  );
}
export type CreateVorsteuerkuerzungEinstellungTemplateMutationHookResult = ReturnType<typeof useCreateVorsteuerkuerzungEinstellungTemplateMutation>;
export type CreateVorsteuerkuerzungEinstellungTemplateMutationResult = Apollo.MutationResult<CreateVorsteuerkuerzungEinstellungTemplateMutation>;
export type CreateVorsteuerkuerzungEinstellungTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateVorsteuerkuerzungEinstellungTemplateMutation,
  CreateVorsteuerkuerzungEinstellungTemplateMutationVariables
>;
export const UpdateVorsteuerkuerzungEinstellungTemplateDocument = gql`
  mutation UpdateVorsteuerkuerzungEinstellungTemplate($vorsteuerkuerzungEinstellungId: ID!, $input: VorsteuerkuerzungEinstellungUpdateInput!) {
    updateVorsteuerkuerzungEinstellungTemplate(vorsteuerkuerzungEinstellungId: $vorsteuerkuerzungEinstellungId, input: $input) {
      data {
        validFrom
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateVorsteuerkuerzungEinstellungTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVorsteuerkuerzungEinstellungTemplateMutation,
    UpdateVorsteuerkuerzungEinstellungTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateVorsteuerkuerzungEinstellungTemplateMutation, UpdateVorsteuerkuerzungEinstellungTemplateMutationVariables>(
    UpdateVorsteuerkuerzungEinstellungTemplateDocument,
    options
  );
}
export type UpdateVorsteuerkuerzungEinstellungTemplateMutationHookResult = ReturnType<typeof useUpdateVorsteuerkuerzungEinstellungTemplateMutation>;
export type UpdateVorsteuerkuerzungEinstellungTemplateMutationResult = Apollo.MutationResult<UpdateVorsteuerkuerzungEinstellungTemplateMutation>;
export type UpdateVorsteuerkuerzungEinstellungTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateVorsteuerkuerzungEinstellungTemplateMutation,
  UpdateVorsteuerkuerzungEinstellungTemplateMutationVariables
>;
export const DeleteVorsteuerkuerzungEinstellungTemplateDocument = gql`
  mutation DeleteVorsteuerkuerzungEinstellungTemplate($vorsteuerkuerzungEinstellungId: ID!) {
    deleteVorsteuerkuerzungEinstellungTemplate(vorsteuerkuerzungEinstellungId: $vorsteuerkuerzungEinstellungId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteVorsteuerkuerzungEinstellungTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVorsteuerkuerzungEinstellungTemplateMutation,
    DeleteVorsteuerkuerzungEinstellungTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVorsteuerkuerzungEinstellungTemplateMutation, DeleteVorsteuerkuerzungEinstellungTemplateMutationVariables>(
    DeleteVorsteuerkuerzungEinstellungTemplateDocument,
    options
  );
}
export type DeleteVorsteuerkuerzungEinstellungTemplateMutationHookResult = ReturnType<typeof useDeleteVorsteuerkuerzungEinstellungTemplateMutation>;
export type DeleteVorsteuerkuerzungEinstellungTemplateMutationResult = Apollo.MutationResult<DeleteVorsteuerkuerzungEinstellungTemplateMutation>;
export type DeleteVorsteuerkuerzungEinstellungTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteVorsteuerkuerzungEinstellungTemplateMutation,
  DeleteVorsteuerkuerzungEinstellungTemplateMutationVariables
>;
