import React, { FC, PropsWithChildren } from 'react';
import { Space } from 'antd';
import { Spacer } from '../Grid';

export enum ButtonsAlignedUsage {
  beforeListing = 'beforeListing',
  drawerFooter = 'drawerFooter',
  drawerCardFooter = 'drawerCardFooter',
}

type ButtonsAlignedProps = {
  /**
   * Has influence over the spacing after the component
   *
   * Use ButtonsAlignedUsage enums as value
   *
   * Use ButtonsAlignedUsage.beforeListing, if the component is followed by a table or list
   */
  usage: ButtonsAlignedUsage;
  align?: 'center' | 'end' | 'space-between';
};

/**
 * <h2>Usage</h2>
 * For any button that has to be aligned to the right or center.
 *    <ul>
 *      <li>On any Page where Buttons are rendered on the top of the page and there are no filters.</li>
 *      <li>When Buttons are followed by a table or list (and there are no filters).</li>
 *      <li>As drawer buttons, e.g. to close the drawer</li>
 *      <li>In drawer card footer, e.g. to save the data or abort the update action in the card</li>
 *    </ul>
 *
 * <h2>Component info</h2>
 * The usage prop controls spacing after this component, so there is no need to add any additional padding etc. manually.
 *
 * <h4>Children</h4>
 * - Buttons are provided as children.
 * - If a button creates a new entity, use icon={\<PlusOutlined /\>} type="primary" in Button props.
 *
 */
const ButtonsAligned: FC<ButtonsAlignedProps & PropsWithChildren> = ({ usage, align = 'end', children }) => {
  return (
    <>
      <Space style={{ width: '100%', justifyContent: align }}>{children}</Space>
      {(usage === ButtonsAlignedUsage.drawerCardFooter || usage === ButtonsAlignedUsage.beforeListing) && <Spacer height={16} />}
    </>
  );
};

export default ButtonsAligned;
