import React from 'react';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { Box } from 'rebass';
import { StyledContentTab } from '../../../components/Card/Styled/Card.style';
import Breadcrumbs from '../../../features/Breadcrumb';
import { ContentMain } from '../../../components/LayoutAndromeda/Styled/Layout.style';
import { LoadPreviewForMitarbeiterResponse, useGetPreviewForMitarbeiter } from '../../../features/Document/useFileDownloadAsyncForDocument';
import MediaCard from '../../../features/IncomingInvoice/shared/MediaCard';
import { useAuthorizedMitarbeiter } from '../../../shared/Auth/useAuthorized';
import { useFileInfoForMitarbeiterQuery } from '../../../features/Document/gql/FileStorageQueries.types';
import IncomingInvoiceForm from '../../../features/IncomingInvoice/Form/IncomingInvoiceForm';
import { StyledSpaceInvoicePage } from '../../../shared/Invoice/styled/StyledInvoicePage.styled';

type RouteParams = { fileId: string };

const RechnungCreatePage = () => {
  const { mitarbeiter } = useAuthorizedMitarbeiter();
  const mitarbeiterId = mitarbeiter.mitarbeiterId;
  const { fileId } = useParams() as RouteParams;

  const { data: maybeFileInfo, loading: isFileInfoLoading } = useFileInfoForMitarbeiterQuery({
    variables: { fileId, mitarbeiterId },
    skip: !mitarbeiterId,
  });
  const fileInfo = maybeFileInfo?.getFileInfoForMitarbeiter.data;

  const { data: maybePreview, isLoading: isPreviewLoading } = useGetPreviewForMitarbeiter(mitarbeiterId, fileId);
  const mediaUrl = maybePreview ? URL.createObjectURL((maybePreview as LoadPreviewForMitarbeiterResponse).blob) : undefined;

  return (
    <>
      <Breadcrumbs />
      <ContentMain style={{ borderRadius: '4px' }}>
        <StyledContentTab>
          <StyledSpaceInvoicePage>
            {isPreviewLoading || isFileInfoLoading ? (
              <Box p={48}>
                <Spin />
              </Box>
            ) : (
              <MediaCard mediaUrl={mediaUrl} fileInfo={fileInfo} />
            )}
            <IncomingInvoiceForm fileId={fileId} />
          </StyledSpaceInvoicePage>
        </StyledContentTab>
      </ContentMain>
    </>
  );
};

export default RechnungCreatePage;
