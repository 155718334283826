import React, { FC } from 'react';
import { Badge, Button, Tooltip } from 'antd';
import { CalendarTwoTone } from '@ant-design/icons';
import theme from '../../theme';

type Props = {
  onClick: () => void;
  ticketCount?: number;
};

const TicketCount: FC<Props> = ({ ticketCount, onClick }) => {
  const icon = (
    <span style={{ padding: '4px 0' }}>
      <Button shape="circle" size="small" type="text" onClick={onClick} icon={<CalendarTwoTone twoToneColor={theme.colors.blue} />} />
    </span>
  );
  const content = ticketCount ? (
    <Badge size="small" count={ticketCount}>
      {icon}
    </Badge>
  ) : (
    <>{icon}</>
  );

  return <Tooltip title="Aufgaben">{content}</Tooltip>;
};

export default TicketCount;
