import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { VObjekt, VVorschreibungspositionListHeader } from '../../vorschreibung-types';
import VTBestandseinheitTableBlock from './BeTableBlock/VTBestandseinheitTableBlock';
import VTObjektSummary from './VTObjektSummary';
import ValuePreservationInformationBlock from '../ValuePreservationInformationBlock/ValuePreservationInformationBlock';

const VTObjektList: FC<{ objektList: VObjekt[]; vPosListHeader: VVorschreibungspositionListHeader }> = ({ objektList, vPosListHeader }) => (
  <View>
    {objektList.map((objekt, index) => (
      <View key={index} style={[pdfStyles.column, { paddingTop: '10mm' }]}>
        {objekt.valuePreservationInformation && (
          <ValuePreservationInformationBlock valuePreservationInformation={objekt.valuePreservationInformation} />
        )}

        {/* Objekt name */}
        <Text style={[pdfStyles.textNormal, { fontWeight: 'bold', textDecoration: 'underline' }]}>
          {objekt.objektText} {objekt.bezeichnungAndAddress}
        </Text>

        {/*  Bestandseinheit table block */}
        <VTBestandseinheitTableBlock bestandseinheitList={objekt.bestandseinheitList} vPosListHeader={vPosListHeader} />

        {/*  Objekt Summary */}
        <VTObjektSummary data={objekt.summary} />
      </View>
    ))}
  </View>
);

export default VTObjektList;
