import React from 'react';
import { useUstRegelsetTemplateListQuery } from '../gql/UstRegelsetTemplateQueries.types';
import UstRegelsetTemplateTable from './Table/UstRegelsetTemplateTable';
import UstRegelsetTemplateTableButtonsAndDrawer from './UstRegelsetTemplateButtonsAndDrawer';

const UstRegelsetTemplateListing = () => {
  const { data, loading, refetch } = useUstRegelsetTemplateListQuery();
  const ustRegelsetList = data?.getUstRegelsetTemplateList.data;

  return (
    <>
      <UstRegelsetTemplateTableButtonsAndDrawer refetch={refetch} />
      <UstRegelsetTemplateTable loading={loading} refetch={refetch} ustRegelsetList={ustRegelsetList} />
    </>
  );
};

export default UstRegelsetTemplateListing;
