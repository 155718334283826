import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../styles/pdfStyles';
import { TopAbrechnung } from '../../../topAbrechnung-types';
import TAAbrechnungskreis from './TAAbrechnungskreis';
import { TopAbrechnungSelectableSection } from '../../../TopAbrechnungTemplate';
import TAAbrKreisSummeRow from './TAAbrKreisSummeRow';
import TAAbrKreisErgebnis from './TAAbrKreisErgebnis';
import TAAbrKreisColumnTitles from './TAAbrKreisColumnTitles';
import Ergebnistext from '../../../../shared/Ergebnistext';
import Schlusstext from '../../../../shared/Schlusstext';

type TADeckblattAbrechnungskreisBlockProps = {
  data: TopAbrechnung;
  isInEditMode?: boolean;
  selectedTextbaustein?: TopAbrechnungSelectableSection;
};

const TADeckblattAbrechnungskreisBlock: FC<TADeckblattAbrechnungskreisBlockProps> = ({ data, isInEditMode, selectedTextbaustein }) => (
  <View>
    {/* COLUMN_NAMES */}
    <TAAbrKreisColumnTitles data={data} />

    {/* Abrechnungskreis listing  */}
    <View style={[pdfStyles.column, { marginTop: '2mm' }]}>
      {data.deckblatt.abrechnungskreisSummary.abrechnungskreisList.map((abrechKreis, index) => (
        <TAAbrechnungskreis abrechKreis={abrechKreis} key={index} />
      ))}

      <View style={[pdfStyles.row, pdfStyles.borderBottomSmall, { marginBottom: '2mm' }]} />

      {/* Summe */}
      <TAAbrKreisSummeRow data={data} />

      <View style={[pdfStyles.row, pdfStyles.borderBottomSmall]} />

      {/* Ergebnis - Guthaben or Nachzahlung */}
      <TAAbrKreisErgebnis data={data} />

      {/* ErgebnisText with Textbaustein */}
      <Ergebnistext text={data.deckblatt.ergebnisText} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />

      {/* Schlusstext with Textbaustein */}
      <Schlusstext text={data.deckblatt.schlussText} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />
    </View>
  </View>
);

export default TADeckblattAbrechnungskreisBlock;
