import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { callLogout } from './loginApi';
import Fetcher from '../../helpers/fetcher';
import { LogoutFn, useAuth } from './authContext';
import { URI_DASHBOARD_WORKSPACE_PAGE } from '../../constants/appUriPaths';

const useLogout = () => {
  const { dispatch, apolloClient } = useAuth();
  const navigate = useNavigate();

  const logout = useCallback<LogoutFn>(
    (reason) => {
      callLogout().finally(() => {
        dispatch({ type: 'CLEAR_STATE' });
        // FIXME test it!
        dispatch({ type: 'RESET_APP_STATE' });
        Fetcher.getInstance().firmendatenId = undefined;
        apolloClient?.clearStore();
        if (reason === 'user_logout') {
          navigate(URI_DASHBOARD_WORKSPACE_PAGE, { replace: true });
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apolloClient]
  );

  return { logout };
};

export default useLogout;
