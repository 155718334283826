import React, { FC } from 'react';
import { Button, Card, Drawer, Dropdown, MenuProps, Space, Spin, Typography } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined, EllipsisOutlined, HistoryOutlined } from '@ant-design/icons';
import { useRechtsTraegerVertragspartnerQuery } from '../gql/VertragspartnerQueries.types';
import { useToggle } from '../../../../../hooks/useToggle';
import MahnsperreForm from './Form/MahnsperreForm';
import { isNotNil } from '../../../../../helpers/assertionHelper';
import theme from '../../../../../theme';
import HistoryModal, { HistoryType } from '../../../../History/shared/HistoryModal';
import MahnsperreChangeHistoryListingTable from './MahnsperreHistoryListingTable';
import { radixActionStyles } from '../../../../../helpers/radixIconsStyles';

type MahnsperreCardProps = {
  rechtstraegerId: string;
  onSuccess: () => void;
};

const MahnsperreCard: FC<MahnsperreCardProps> = ({ rechtstraegerId, onSuccess }) => {
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const { data, refetch } = useRechtsTraegerVertragspartnerQuery({
    variables: {
      rechtstraegerId,
    },
  });
  const vertragspartner = data?.getVertragspartner.data;
  const loading = !vertragspartner;

  const onCreateSuccess = () => {
    onSuccess();
    refetch();
  };

  const [isCollapsed, onCollapse] = useToggle();

  const isMahnsperre = vertragspartner?.mahnsperre;

  const items: MenuProps['items'] = [
    { key: '1', label: 'Bearbeiten', onClick: onCollapse, icon: <EditOutlined /> },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
  ];

  return loading ? (
    <Spin />
  ) : (
    <>
      <Card
        style={{ width: '450px' }}
        headStyle={{ backgroundColor: theme.cardBackgroundColors.grey }}
        size="default"
        bordered
        title="Mahnung"
        extra={
          <Dropdown menu={{ items }}>
            <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
          </Dropdown>
        }
      >
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <Space>
            <Typography.Text>Mahnsperre</Typography.Text>
            <Space>
              {isMahnsperre ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
              <Typography.Text type={isMahnsperre ? 'success' : 'danger'}>{isMahnsperre ? 'Aktiviert' : 'Deaktiviert'}</Typography.Text>
            </Space>
          </Space>
          <Space direction="vertical">
            <Typography.Text>Kommentar</Typography.Text>
            {isNotNil(vertragspartner.mahnsperreKommentar) && <div style={{ whiteSpace: 'pre' }}>{vertragspartner.mahnsperreKommentar}</div>}
          </Space>
        </Space>
      </Card>
      <Drawer title="Mahnung bearbeiten" width={720} open={isCollapsed} onClose={onCollapse} destroyOnClose>
        <MahnsperreForm
          vertragspartner={vertragspartner}
          rechtstraegerId={rechtstraegerId}
          onSuccess={() => {
            onCreateSuccess();
            onCollapse();
          }}
          onCancel={onCollapse}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <MahnsperreChangeHistoryListingTable rechtstraegerId={rechtstraegerId} />
      </HistoryModal>
    </>
  );
};

export default MahnsperreCard;
