import React from 'react';
import { Typography } from 'antd';
import { MitarbeiterTooltip } from '../../../components/Card';
import { TicketTemplate } from '../../../types';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';
import { compareTwoStringsForSorting } from '../../../helpers/stringHelper';
import TicketTemplateTemplateTableActions from './TicketTemplateTemplateTableActions';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';
import { compareTwoPeriodsForSorting } from '../../TicketTemplate/ticketTemplateHelpers';

const ticketTemplateTemplateTableColumns = (ticketTemplateList?: TicketTemplate[]): TableWithColSelectorColumnProps<TicketTemplate>[] => [
  {
    title: 'Bezeichnung',
    defaultSortOrder: 'ascend',
    defaultSelected: true,
    width: 200,
    sorter: (a, b) => compareTwoStringsForSorting(a.name, b.name),
    render: (text, record) => <Typography.Text>{record.name}</Typography.Text>,
  },
  {
    title: 'Priorität',
    defaultSelected: true,
    width: 100,
    sorter: (a, b) => compareTwoStringsForSorting(a.priority.text, b.priority.text),
    render: (text, record) => <Typography.Text>{record.priority.text}</Typography.Text>,
  },
  {
    title: 'Fälligkeit',
    defaultSelected: true,
    width: 150,
    sorter: (a, b) => compareTwoPeriodsForSorting(a.dueDate, b.dueDate),
    render: (text, record) => <Typography.Text>{record.dueDate ? record.dueDateText : <TextForEmptyValue textToShow="minus" />}</Typography.Text>,
  },
  {
    title: 'Erinnerung',
    defaultSelected: true,
    width: 150,
    sorter: (a, b) => compareTwoPeriodsForSorting(a.reminder, b.reminder),
    render: (text, record) => <Typography.Text>{record.reminder ? record.reminderText : <TextForEmptyValue textToShow="minus" />}</Typography.Text>,
  },
  {
    title: 'Erstellt am',
    width: 150,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    width: 80,
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    width: 100,
    key: 'action',
    render: (text, record) => <TicketTemplateTemplateTableActions ticketTemplate={record} ticketTemplateList={ticketTemplateList} />,
  },
];

export default ticketTemplateTemplateTableColumns;
