import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IbanFibuKontoListQueryVariables = Types.Exact<{
  iban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  bic?: Types.InputMaybe<Types.Scalars['String']['input']>;
  accountHolder?: Types.InputMaybe<Types.Scalars['String']['input']>;
  bankname?: Types.InputMaybe<Types.Scalars['String']['input']>;
  fibuAccountNumber?: Types.InputMaybe<Types.Scalars['String']['input']>;
  paymentTransactionType?: Types.InputMaybe<Types.PaymentTransactionType>;
  lastBankStatementFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  lastBankStatementTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  order?: Types.InputMaybe<Types.IbanFibuKontoOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type IbanFibuKontoListQuery = {
  getIbanFibuKontoList: {
    data: {
      contentList: Array<{
        accountHolder: string;
        bankname: string;
        bic: string;
        createTs?: string | null;
        dataCarrierId?: string | null;
        ebicsUserList: Array<string>;
        fibuAccountNumber: string;
        iban: string;
        lastBankStatementCreatedOn?: string | null;
        statementNumber?: string | null;
        bankDetailsList: Array<{
          bankDetailsId: string;
          firmendatenId: string;
          firmendatenKurzBezeichnung: string;
          rechtstraegerId?: string | null;
          rechtstraegerKurzBezeichnung?: string | null;
        }>;
        paymentTransactionType: { text: string; value: Types.PaymentTransactionType };
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type IbanFibuKontoAccountHolderListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IbanFibuKontoAccountHolderListQuery = {
  getIbanFibuKontoAccountHolderList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type IbanFibuKontoBanknameListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IbanFibuKontoBanknameListQuery = {
  getIbanFibuKontoBanknameList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type IbanFibuKontoBicListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IbanFibuKontoBicListQuery = {
  getIbanFibuKontoBicList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type IbanFibuKontoFibuAccountNumberListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IbanFibuKontoFibuAccountNumberListQuery = {
  getIbanFibuKontoFibuAccountNumberList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type IbanFibuKontoIbanListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IbanFibuKontoIbanListQuery = {
  getIbanFibuKontoIbanList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const IbanFibuKontoListDocument = gql`
  query IbanFibuKontoList(
    $iban: String
    $bic: String
    $accountHolder: String
    $bankname: String
    $fibuAccountNumber: String
    $paymentTransactionType: PaymentTransactionType
    $lastBankStatementFrom: String
    $lastBankStatementTo: String
    $order: IbanFibuKontoOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getIbanFibuKontoList(
      iban: $iban
      bic: $bic
      accountHolder: $accountHolder
      bankname: $bankname
      fibuAccountNumber: $fibuAccountNumber
      paymentTransactionType: $paymentTransactionType
      lastBankStatementFrom: $lastBankStatementFrom
      lastBankStatementTo: $lastBankStatementTo
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          accountHolder
          bankDetailsList {
            bankDetailsId
            firmendatenId
            firmendatenKurzBezeichnung
            rechtstraegerId
            rechtstraegerKurzBezeichnung
          }
          bankname
          bic
          createTs
          dataCarrierId
          ebicsUserList
          fibuAccountNumber
          iban
          lastBankStatementCreatedOn
          paymentTransactionType {
            text
            value
          }
          statementNumber
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useIbanFibuKontoListQuery(baseOptions?: Apollo.QueryHookOptions<IbanFibuKontoListQuery, IbanFibuKontoListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IbanFibuKontoListQuery, IbanFibuKontoListQueryVariables>(IbanFibuKontoListDocument, options);
}
export function useIbanFibuKontoListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IbanFibuKontoListQuery, IbanFibuKontoListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IbanFibuKontoListQuery, IbanFibuKontoListQueryVariables>(IbanFibuKontoListDocument, options);
}
export function useIbanFibuKontoListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IbanFibuKontoListQuery, IbanFibuKontoListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IbanFibuKontoListQuery, IbanFibuKontoListQueryVariables>(IbanFibuKontoListDocument, options);
}
export type IbanFibuKontoListQueryHookResult = ReturnType<typeof useIbanFibuKontoListQuery>;
export type IbanFibuKontoListLazyQueryHookResult = ReturnType<typeof useIbanFibuKontoListLazyQuery>;
export type IbanFibuKontoListSuspenseQueryHookResult = ReturnType<typeof useIbanFibuKontoListSuspenseQuery>;
export type IbanFibuKontoListQueryResult = Apollo.QueryResult<IbanFibuKontoListQuery, IbanFibuKontoListQueryVariables>;
export const IbanFibuKontoAccountHolderListDocument = gql`
  query IbanFibuKontoAccountHolderList {
    getIbanFibuKontoAccountHolderList {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useIbanFibuKontoAccountHolderListQuery(
  baseOptions?: Apollo.QueryHookOptions<IbanFibuKontoAccountHolderListQuery, IbanFibuKontoAccountHolderListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IbanFibuKontoAccountHolderListQuery, IbanFibuKontoAccountHolderListQueryVariables>(
    IbanFibuKontoAccountHolderListDocument,
    options
  );
}
export function useIbanFibuKontoAccountHolderListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IbanFibuKontoAccountHolderListQuery, IbanFibuKontoAccountHolderListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IbanFibuKontoAccountHolderListQuery, IbanFibuKontoAccountHolderListQueryVariables>(
    IbanFibuKontoAccountHolderListDocument,
    options
  );
}
export function useIbanFibuKontoAccountHolderListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IbanFibuKontoAccountHolderListQuery, IbanFibuKontoAccountHolderListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IbanFibuKontoAccountHolderListQuery, IbanFibuKontoAccountHolderListQueryVariables>(
    IbanFibuKontoAccountHolderListDocument,
    options
  );
}
export type IbanFibuKontoAccountHolderListQueryHookResult = ReturnType<typeof useIbanFibuKontoAccountHolderListQuery>;
export type IbanFibuKontoAccountHolderListLazyQueryHookResult = ReturnType<typeof useIbanFibuKontoAccountHolderListLazyQuery>;
export type IbanFibuKontoAccountHolderListSuspenseQueryHookResult = ReturnType<typeof useIbanFibuKontoAccountHolderListSuspenseQuery>;
export type IbanFibuKontoAccountHolderListQueryResult = Apollo.QueryResult<
  IbanFibuKontoAccountHolderListQuery,
  IbanFibuKontoAccountHolderListQueryVariables
>;
export const IbanFibuKontoBanknameListDocument = gql`
  query IbanFibuKontoBanknameList {
    getIbanFibuKontoBanknameList {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useIbanFibuKontoBanknameListQuery(
  baseOptions?: Apollo.QueryHookOptions<IbanFibuKontoBanknameListQuery, IbanFibuKontoBanknameListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IbanFibuKontoBanknameListQuery, IbanFibuKontoBanknameListQueryVariables>(IbanFibuKontoBanknameListDocument, options);
}
export function useIbanFibuKontoBanknameListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IbanFibuKontoBanknameListQuery, IbanFibuKontoBanknameListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IbanFibuKontoBanknameListQuery, IbanFibuKontoBanknameListQueryVariables>(IbanFibuKontoBanknameListDocument, options);
}
export function useIbanFibuKontoBanknameListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IbanFibuKontoBanknameListQuery, IbanFibuKontoBanknameListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IbanFibuKontoBanknameListQuery, IbanFibuKontoBanknameListQueryVariables>(IbanFibuKontoBanknameListDocument, options);
}
export type IbanFibuKontoBanknameListQueryHookResult = ReturnType<typeof useIbanFibuKontoBanknameListQuery>;
export type IbanFibuKontoBanknameListLazyQueryHookResult = ReturnType<typeof useIbanFibuKontoBanknameListLazyQuery>;
export type IbanFibuKontoBanknameListSuspenseQueryHookResult = ReturnType<typeof useIbanFibuKontoBanknameListSuspenseQuery>;
export type IbanFibuKontoBanknameListQueryResult = Apollo.QueryResult<IbanFibuKontoBanknameListQuery, IbanFibuKontoBanknameListQueryVariables>;
export const IbanFibuKontoBicListDocument = gql`
  query IbanFibuKontoBicList {
    getIbanFibuKontoBicList {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useIbanFibuKontoBicListQuery(baseOptions?: Apollo.QueryHookOptions<IbanFibuKontoBicListQuery, IbanFibuKontoBicListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IbanFibuKontoBicListQuery, IbanFibuKontoBicListQueryVariables>(IbanFibuKontoBicListDocument, options);
}
export function useIbanFibuKontoBicListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IbanFibuKontoBicListQuery, IbanFibuKontoBicListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IbanFibuKontoBicListQuery, IbanFibuKontoBicListQueryVariables>(IbanFibuKontoBicListDocument, options);
}
export function useIbanFibuKontoBicListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IbanFibuKontoBicListQuery, IbanFibuKontoBicListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IbanFibuKontoBicListQuery, IbanFibuKontoBicListQueryVariables>(IbanFibuKontoBicListDocument, options);
}
export type IbanFibuKontoBicListQueryHookResult = ReturnType<typeof useIbanFibuKontoBicListQuery>;
export type IbanFibuKontoBicListLazyQueryHookResult = ReturnType<typeof useIbanFibuKontoBicListLazyQuery>;
export type IbanFibuKontoBicListSuspenseQueryHookResult = ReturnType<typeof useIbanFibuKontoBicListSuspenseQuery>;
export type IbanFibuKontoBicListQueryResult = Apollo.QueryResult<IbanFibuKontoBicListQuery, IbanFibuKontoBicListQueryVariables>;
export const IbanFibuKontoFibuAccountNumberListDocument = gql`
  query IbanFibuKontoFibuAccountNumberList {
    getIbanFibuKontoFibuAccountNumberList {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useIbanFibuKontoFibuAccountNumberListQuery(
  baseOptions?: Apollo.QueryHookOptions<IbanFibuKontoFibuAccountNumberListQuery, IbanFibuKontoFibuAccountNumberListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IbanFibuKontoFibuAccountNumberListQuery, IbanFibuKontoFibuAccountNumberListQueryVariables>(
    IbanFibuKontoFibuAccountNumberListDocument,
    options
  );
}
export function useIbanFibuKontoFibuAccountNumberListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IbanFibuKontoFibuAccountNumberListQuery, IbanFibuKontoFibuAccountNumberListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IbanFibuKontoFibuAccountNumberListQuery, IbanFibuKontoFibuAccountNumberListQueryVariables>(
    IbanFibuKontoFibuAccountNumberListDocument,
    options
  );
}
export function useIbanFibuKontoFibuAccountNumberListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IbanFibuKontoFibuAccountNumberListQuery, IbanFibuKontoFibuAccountNumberListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IbanFibuKontoFibuAccountNumberListQuery, IbanFibuKontoFibuAccountNumberListQueryVariables>(
    IbanFibuKontoFibuAccountNumberListDocument,
    options
  );
}
export type IbanFibuKontoFibuAccountNumberListQueryHookResult = ReturnType<typeof useIbanFibuKontoFibuAccountNumberListQuery>;
export type IbanFibuKontoFibuAccountNumberListLazyQueryHookResult = ReturnType<typeof useIbanFibuKontoFibuAccountNumberListLazyQuery>;
export type IbanFibuKontoFibuAccountNumberListSuspenseQueryHookResult = ReturnType<typeof useIbanFibuKontoFibuAccountNumberListSuspenseQuery>;
export type IbanFibuKontoFibuAccountNumberListQueryResult = Apollo.QueryResult<
  IbanFibuKontoFibuAccountNumberListQuery,
  IbanFibuKontoFibuAccountNumberListQueryVariables
>;
export const IbanFibuKontoIbanListDocument = gql`
  query IbanFibuKontoIbanList {
    getIbanFibuKontoIbanList {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useIbanFibuKontoIbanListQuery(
  baseOptions?: Apollo.QueryHookOptions<IbanFibuKontoIbanListQuery, IbanFibuKontoIbanListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IbanFibuKontoIbanListQuery, IbanFibuKontoIbanListQueryVariables>(IbanFibuKontoIbanListDocument, options);
}
export function useIbanFibuKontoIbanListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IbanFibuKontoIbanListQuery, IbanFibuKontoIbanListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IbanFibuKontoIbanListQuery, IbanFibuKontoIbanListQueryVariables>(IbanFibuKontoIbanListDocument, options);
}
export function useIbanFibuKontoIbanListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IbanFibuKontoIbanListQuery, IbanFibuKontoIbanListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IbanFibuKontoIbanListQuery, IbanFibuKontoIbanListQueryVariables>(IbanFibuKontoIbanListDocument, options);
}
export type IbanFibuKontoIbanListQueryHookResult = ReturnType<typeof useIbanFibuKontoIbanListQuery>;
export type IbanFibuKontoIbanListLazyQueryHookResult = ReturnType<typeof useIbanFibuKontoIbanListLazyQuery>;
export type IbanFibuKontoIbanListSuspenseQueryHookResult = ReturnType<typeof useIbanFibuKontoIbanListSuspenseQuery>;
export type IbanFibuKontoIbanListQueryResult = Apollo.QueryResult<IbanFibuKontoIbanListQuery, IbanFibuKontoIbanListQueryVariables>;
