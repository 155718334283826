import React, { FC } from 'react';
import { WizardFormWrapper } from '../../../components/Wizard';
import FirmendatenBaseWizardStepForm from '../Firmendaten/wizard/FirmendatenBaseWizardStepForm';
import FirmensitzWizardStepForm from '../Firmendaten/wizard/FirmensitzWizardStepForm';
import KundenSystemBaseWizardStepForm from './KundenSystemBaseWizardStepForm';
import { mapFirmendatenWizardValuesToFirmendaten } from '../Firmendaten/wizard/firmendatenCreateWizardMapper';
import { mapFormValuesToKundenSystem } from './Form/kundenSystemMapper';
import { CreateKundenSystemInput } from '../../../types';

const WIZARD_STEP_ID_KUNDENSYSTEM = 'Kundensystem';
const WIZARD_STEP_ID_FIRMENDATEN = 'Firmendaten';
const WIZARD_STEP_ID_OPERATING_SITE = 'Firmensitz';

type KundenSystemCreateWizardProps = {
  onCreate: (values: CreateKundenSystemInput) => void;
  onCancel: () => void;
};

const KundenSystemCreateWizard: FC<KundenSystemCreateWizardProps> = ({ onCreate, onCancel }) => (
  <WizardFormWrapper onWizardSubmit={(wizardValues) => handleWizardSubmit(wizardValues, onCreate)} onWizardCancel={onCancel}>
    <KundenSystemBaseWizardStepForm stepId={WIZARD_STEP_ID_KUNDENSYSTEM} />
    <FirmendatenBaseWizardStepForm stepId={WIZARD_STEP_ID_FIRMENDATEN} fieldHelpSelectorEntityName="KundenSystem" />
    <FirmensitzWizardStepForm stepId={WIZARD_STEP_ID_OPERATING_SITE} fieldHelpSelectorEntityName="KundenSystem" />
  </WizardFormWrapper>
);

const handleWizardSubmit = (wizardValues: Record<string, Record<string, unknown>>, onCreate: (values: CreateKundenSystemInput) => void) => {
  const kundenSystemToCreate = mapWizardValuesToKundenSystem(wizardValues);
  onCreate(kundenSystemToCreate);
};

const mapWizardValuesToKundenSystem = (wizardValues: Record<string, Record<string, unknown>>): CreateKundenSystemInput => {
  const kundenSystemBaseValues = wizardValues[WIZARD_STEP_ID_KUNDENSYSTEM];
  const firmendatenBaseValues = wizardValues[WIZARD_STEP_ID_FIRMENDATEN];
  const mainOperatingSiteValues = wizardValues[WIZARD_STEP_ID_OPERATING_SITE];
  return {
    // @ts-ignore FIXME => could be solved by refactoring Wizard to accept generics
    ...mapFormValuesToKundenSystem(kundenSystemBaseValues),
    erstelltVonFirma: mapFirmendatenWizardValuesToFirmendaten(firmendatenBaseValues, mainOperatingSiteValues),
  };
};

export default KundenSystemCreateWizard;
