import React, { FC } from 'react';
import { FieldArray, FormikProps } from 'formik';
import { Space, TabsProps, Typography } from 'antd';
import { mahndefinitionParamFormFields, MahndefinitionParamFormValues, mahnstufenFormFields } from '../../mahndefinitionFormMapper';
import MahndefinitionParamFormMahngebuehren from './MahndefinitionParamFormMahngebuehren';
import MahndefinitionParamFormMahnstufeCommonConfig from './MahndefinitionParamFormMahnstufeCommonConfig';
import { StyledMahnstufeTabs } from '../Styled/Mahndefinition.style';

type Props = {
  formikProps: FormikProps<MahndefinitionParamFormValues>;
  setIsVersandEinschreiben1?: (value: boolean) => void;
  setIsVersandEinschreiben2?: (value: boolean) => void;
  setIsVersandEinschreiben3?: (value: boolean) => void;
  setIsVersandEinschreiben4?: (value: boolean) => void;
};

const MahndefinitionParamFormMahnstufen: FC<Props> = ({
  formikProps,
  setIsVersandEinschreiben1,
  setIsVersandEinschreiben2,
  setIsVersandEinschreiben3,
  setIsVersandEinschreiben4,
}) => {
  const onSetIsVersandEinschreiben = [setIsVersandEinschreiben1, setIsVersandEinschreiben2, setIsVersandEinschreiben3, setIsVersandEinschreiben4];

  const items: TabsProps['items'] = formikProps.values.mahnstufeList.map((mahnstufe, index) => {
    const currentMahnstufeFieldName = `${mahndefinitionParamFormFields.mahnstufeList}.${index}`;
    const hasErrors = hasMahnstufeErrors(formikProps.errors?.mahnstufeList?.[index]);
    const mahnstufeTabTitle = <Typography.Text style={{ color: hasErrors ? 'red' : 'inherit' }}>Mahnstufe {index + 1}</Typography.Text>;

    return {
      key: index.toString(),
      label: mahnstufeTabTitle,
      children: (
        <Space direction="vertical" size="middle" style={{ width: '100%', marginTop: '8px' }}>
          <MahndefinitionParamFormMahngebuehren
            name={`${currentMahnstufeFieldName}.${mahnstufenFormFields.mahngebuehrList}`}
            mahngebuehrenList={mahnstufe.mahngebuehrList}
            // @ts-ignore
            error={formikProps.errors?.mahnstufeList?.[index]?.mahngebuehrList}
          />
          <MahndefinitionParamFormMahnstufeCommonConfig
            name={currentMahnstufeFieldName}
            setIsVersandEinschreiben={onSetIsVersandEinschreiben[index]}
          />
        </Space>
      ),
    };
  });

  return (
    <FieldArray
      name={mahndefinitionParamFormFields.mahnstufeList}
      render={() => {
        return <StyledMahnstufeTabs items={items} />;
      }}
    />
  );
};

const hasMahnstufeErrors = (mahnstufeError: unknown) => {
  return !!mahnstufeError;
};
export default MahndefinitionParamFormMahnstufen;
