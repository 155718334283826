import React, { FC } from 'react';
import { Spin } from 'antd';
import { useVorschreibungMailDeliveryWizardTemplateStepQuery } from '../../gql/VorschreibungMailDeliveryWizardQueries.types';
import { EmailTemplateStepFormValues } from './emailTemplateStepFormMapper';
import EmailTemplateStepForm from './EmailTemplateStepForm';
import { DeleteWizard } from '../../../wizardHelpers';

type Props = {
  onCancel: () => void;
  onComplete: () => void;
  onDelete: DeleteWizard;
  wizardId: string;
};

const EmailTemplateStep: FC<Props> = ({ onCancel, onComplete, wizardId, onDelete }) => {
  const { data, loading } = useVorschreibungMailDeliveryWizardTemplateStepQuery({
    variables: { vorschreibungMailDeliveryWizardId: wizardId },
    // disable cache otherwise user can work on deprecated data when navigating back and forth
    fetchPolicy: 'no-cache',
  });
  const templeStep = data?.getVorschreibungMailDeliveryWizardTemplateStep.data;

  if (!templeStep) {
    return <Spin />;
  }

  const { attachmentInfoList, body, subject, emailDeliveryDefinitionId, emailDeliveryDefinitionBezeichnung } = templeStep;

  // TODO: EC-7366 CR: we could pass templateStep as prop to WizardTextAnhaengeStepForm (wait for send vorschreibungen story to decide)
  const formData: EmailTemplateStepFormValues = {
    body,
    subject,
    newAttachmentList: [],
    deletableAttachmentIds: [],
  };

  const completed = templeStep.completed;

  return (
    <EmailTemplateStepForm
      attachmentInfoList={attachmentInfoList}
      emailDeliveryDefinitionBezeichnung={emailDeliveryDefinitionBezeichnung}
      emailDeliveryDefinitionId={emailDeliveryDefinitionId}
      formData={formData}
      isCompleted={completed}
      onCancel={onCancel}
      onSubmit={onComplete}
      onDelete={onDelete}
      wizardId={wizardId}
      loading={loading}
    />
  );
};

export default EmailTemplateStep;
