import { useEffect, useRef } from 'react';

export function useDidUpdateEffect(fn, inputs) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      fn();
    } else {
      didMountRef.current = true;
    }
    // FIXME fix problem with exhaustive-deps and then remove eslint-disable command (!! if you add the fn to the deps, kurzBezeichnungVorschlag feautre goes nuts and debouncing does not work (help for fixing it: https://github.com/facebook/create-react-app/issues/6880)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputs);
}
