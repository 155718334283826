import React, { FC } from 'react';
import { Empty } from 'antd';
import auftragsartTemplateTableColumns from './auftragsartTemplateTableColumns';
import TableWithColSelector from '../../../components/Table/TableWithColSelector/TableWithColSelector';
import { AuftragsartBase } from '../../../types';
import { useAuftragsartTemplatetListQuery } from '../gql/AuftragsartTemplateQueries.types';

type Props = {
  showArchived: boolean;
};

const AuftragsartTemplateTable: FC<Props> = ({ showArchived }) => {
  const { data, loading, refetch } = useAuftragsartTemplatetListQuery({
    variables: {
      includeArchiviert: showArchived,
    },
  });
  const auftragsartList = data?.getAuftragsartTemplateList.data;

  return (
    <TableWithColSelector<AuftragsartBase>
      size="small"
      locale={{ emptyText: <Empty description={<span>Keine Auftragsarten vorhanden</span>} /> }}
      loading={loading}
      dataSource={auftragsartList}
      columns={auftragsartTemplateTableColumns(refetch)}
      rowKey={(record) => record.auftragsartId}
      filterIdentifier="sys-settings-auftragsart-template"
    />
  );
};

export default AuftragsartTemplateTable;
