export interface ChangePasswordFormValues {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export const changePasswordFormInitialValues: ChangePasswordFormValues = {
  oldPassword: '',
  newPassword: '',
  newPasswordConfirm: '',
};
