import React, { FC, useEffect } from 'react';
import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import SaldenlisteListingFilters from './Filters/SaldenlisteListingFilters';
import SaldenlisteTables from './Tables/SaldenlisteTables';
import { useQueryParams } from '../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../hooks/usePaginationQueryParams';
import { TSaldenlisteQueryParams, updateQueryParams } from './Filters/filtersQueryParams';

const SaldenlisteListing: FC = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TSaldenlisteQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const { buchungskreisId, objektId, buchungsmonat, kontoType } = queryParams;

  const setInitialQueryParams = () => {
    updateQueryParams(navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (filters: TSaldenlisteQueryParams) => {
    updateQueryParams(navigate, filters, paginationParams);
  };

  let result;
  if (buchungskreisId && buchungsmonat) {
    result = <SaldenlisteTables buchungskreisId={buchungskreisId} objektId={objektId} date={buchungsmonat} kontoType={kontoType} />;
  } else if (!buchungskreisId && objektId) {
    result = (
      <Typography.Title level={3} style={{ textAlign: 'center' }}>
        Keine Saldenliste gefunden
      </Typography.Title>
    );
  } else {
    result = (
      <Typography.Title level={3} style={{ textAlign: 'center' }}>
        Buchungskreis oder Objekt auswählen
      </Typography.Title>
    );
  }

  return (
    <>
      <SaldenlisteListingFilters filters={queryParams} onChange={onChange} />
      {result}
    </>
  );
};

export default SaldenlisteListing;
