import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../../security/AuthRoute';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../../constants/andromedaSystemSettingsUriPaths';
import AndromedaSysSettingsAuftragsartPage from './AndromedaSysSettingsAuftragsartPage';
import { isAdmin } from '../../../../../security/permissionChecks';

const andromedaSysSettingsAuftragsartPageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS.auftragsartPage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsAuftragsartPage />} />}
  />
);

export default andromedaSysSettingsAuftragsartPageRoute;
