import React from 'react';
import { Link } from 'react-router-dom';
import { SubAbrAbrechnungskreis } from '../../../../../../../types';
import { NestedTableWithColSelectorColProps } from '../../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import { EuroAmount } from '../../../../../../../components/Number';
import { generatePathToAbrechnungsdefinitionVersionViewPage } from '../../../../../../Abrechnungsdefinition/abrDefUriPaths';

const abrKreisTableColumns: NestedTableWithColSelectorColProps<SubAbrAbrechnungskreis>[] = [
  {
    title: 'Abrechnungskreis',
    key: 'abrechnungskreis',
    defaultSelected: true,
    render: (text, record) => (
      <Link
        to={generatePathToAbrechnungsdefinitionVersionViewPage(record.subAbrechnungsdefinitionId, record.subAbrechnungsdefinitionVersionId, true)}
        target="_blank"
      >
        {record.bezeichnung}
      </Link>
    ),
  },
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: '',
    key: 'vrstKrzAndZahlung',
  },
  {
    title: 'Summe',
    key: 'summe',
    align: 'right',
    defaultSelected: true,
    render: (text, record) => <EuroAmount value={record.summe} />,
  },
  {
    title: '',
    key: 'ergebnis',
  },
];

export default abrKreisTableColumns;
