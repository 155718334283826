import React from 'react';
import { Avatar } from 'antd';
import { AuditOutlined, ContainerOutlined, HistoryOutlined, InboxOutlined, UserOutlined } from '@ant-design/icons';
import Todos from '../../features/Contact/Renderers/Todos';
import Historie from '../../features/Contact/Renderers/Historie';
import Archiv from '../../features/Contact/Renderers/Archiv';
import Mieter from '../../features/Personenbezug/Renderers/Mieter';
import Eigentuemer from '../../features/Personenbezug/Renderers/Eigentuemer';
import Rechnungsaussteller from '../../features/Personenbezug/Renderers/Rechnungsaussteller';
import { GroupedMenueListInterface } from '../../components/ContextSidebar/ContentWithSidebar';
import { URI_PART_CONTEXT_TYPES } from '../../features/Contact/contactUriPaths';
import TabPaneContent from '../../components/Tabs/TabPaneContent';

const menuListContact = (contact: any): GroupedMenueListInterface => ({
  topMenuList: [
    {
      title: 'Informationen',
      icon: <UserOutlined />,
      uri: 'information',
      content: () => <TabPaneContent distanceToTopBorder="small">Stammdaten</TabPaneContent>,
    },
    {
      title: 'Aufgaben',
      icon: <InboxOutlined />,
      uri: 'todos',
      content: () => <Todos />,
    },
    {
      title: 'Historie',
      icon: <HistoryOutlined />,
      uri: 'history',
      content: () => <Historie />,
    },
    {
      title: 'Archiv',
      icon: <ContainerOutlined />,
      uri: 'archive',
      content: () => <Archiv />,
    },
  ],
  contextMenuList: [
    {
      title: 'Mieter',
      icon: <Avatar>M</Avatar>,
      uri: URI_PART_CONTEXT_TYPES.RENTER,
      content: (props) => (
        // @ts-ignore
        <Mieter {...props} />
      ),
      contextKey: ['MIETER'],
    },
    {
      title: 'Eigentümer',
      icon: <Avatar>E</Avatar>,
      uri: URI_PART_CONTEXT_TYPES.OWNER,
      content: (props) => (
        // @ts-ignore
        <Eigentuemer {...props} />
      ),
      contextKey: ['EIGENTUEMER'],
    },
    {
      title: 'Rechnungsaussteller',
      icon: <AuditOutlined />,
      uri: URI_PART_CONTEXT_TYPES.INVOICE_WRITER,
      content: () => (
        // @ts-ignore
        <Rechnungsaussteller contact={contact} />
      ),
      contextKey: ['RECHNUNGS_AUSSTELLER'],
    },
  ],
});

export default menuListContact;
