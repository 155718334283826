import React, { FC } from 'react';
import { Button, Dropdown, MenuProps, Modal } from 'antd';
import { DownOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { showSuccessMsgOther, showWarningMessage } from '../../../../components/message';
import { runExportZinslisteAsPdf, useExportZinslisteAsExcel } from '../../useZinslisteAsync';
import { Zinsliste } from '../../../../types';
import { useToggle } from '../../../../hooks/useToggle';
import { useFDAuthorized } from '../../../../shared/Auth/useAuthorized';
import { downloadURL } from '../../../../helpers/fileHelper';
import PDFCreatingStateView from '../../../shared/PDFCreatingStateView';

type Props = {
  zinsliste: Zinsliste;
};

// Create an AbortController instance
let zinslisteAsPdfAbortController: AbortController;

const VPosExportBtnAndModal: FC<Props> = ({ zinsliste }) => {
  const { activeForFirmendatenId } = useFDAuthorized();

  const onExportSuccess = (response: any) => {
    downloadURL(response.url);
    showSuccessMsgOther(`Zinsliste erfolgreich exportiert.`);
  };

  // Note: If the function doeasn't use {} to wrap the fn body, the modal won't be closed after "Abbrechen" button is clicked
  const onExportError = (error: any) => {
    showWarningMessage(error, 'Zinsliste wurde nicht exportiert');
  };

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { run: runExportAsExcel } = useExportZinslisteAsExcel(
    zinsliste.kopfdaten.objektId,
    zinsliste.kopfdaten.zinslisteId,
    onExportSuccess,
    onExportError
  );

  const [showExportAsPdfModal, toggleExportAsPdfModal] = useToggle();

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Excel exportieren',
      icon: <FileExcelOutlined />,
      onClick: () => showConfirmExportAsExcel(zinsliste, runExportAsExcel),
    },
    {
      key: '2',
      label: 'PDF exportieren',
      icon: <FilePdfOutlined />,
      onClick: () => {
        zinslisteAsPdfAbortController = new AbortController();
        toggleExportAsPdfModal();
        runExportZinslisteAsPdf(
          {
            firmendatenId: activeForFirmendatenId,
            objektId: zinsliste.kopfdaten.objektId,
            zinslisteId: zinsliste.kopfdaten.zinslisteId,
          },
          zinslisteAsPdfAbortController
        )
          .then(onExportSuccess)
          .catch(onExportError)
          .finally(() => toggleExportAsPdfModal());
      },
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <Button type="primary">
          Export
          <DownOutlined />
        </Button>
      </Dropdown>
      <Modal
        open={showExportAsPdfModal}
        title="Zinslisten PDF exportieren"
        footer={
          <Button onClick={() => zinslisteAsPdfAbortController.abort()} type="primary">
            Abbrechen
          </Button>
        }
      >
        <PDFCreatingStateView />
      </Modal>
    </>
  );
};

const showConfirmExportAsExcel = (zinsliste: Zinsliste, handleExport: (zinsliste: Zinsliste) => void) => {
  Modal.confirm({
    title: 'Möchten Sie die Zinsliste als Excel exportieren?',
    content: `Zinsliste ${zinsliste.kopfdaten.vorschreibungsperiode.monat}/${zinsliste.kopfdaten.vorschreibungsperiode.jahr}`,
    okText: 'Ok',
    cancelText: 'Nein',
    onOk() {
      return handleExport(zinsliste);
    },
  });
};

export default VPosExportBtnAndModal;
