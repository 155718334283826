import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import { UstRegelsetUstKategorieZuweisungFieldsFragmentDoc } from './UstRegelseUstKategorieFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UstRegelsetUstKategorieZuweisungListQueryVariables = Types.Exact<{
  ustRegelsetId: Types.Scalars['ID']['input'];
}>;

export type UstRegelsetUstKategorieZuweisungListQuery = {
  getUstRegelsetUstKategorieZuweisungList: {
    data: Array<{
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      validFrom: string;
      ustRegelsetKategorieZuweisungId: string;
      lastUpdateTs: string;
      ustKategorie: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        ustKategorieId: string;
        ustVomAufwand?: number | null;
        vstKuerzung?: boolean | null;
        status?: { text: string; value: Types.UstKategorieStatus } | null;
        ustKategorieEntryList: Array<{
          additionKonto: number;
          buchungsKZ?: string | null;
          createTs?: string | null;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          rechnungskennzeichen: string;
          steuersatz: number;
          ustKategorieEntryId?: string | null;
          ustKz: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ message: string; attribute?: string | null; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UstRegelsetUstKategorieZuweisungQueryVariables = Types.Exact<{
  ustRegelsetId: Types.Scalars['ID']['input'];
  ustRegelsetUstKategorieZuweisungId: Types.Scalars['ID']['input'];
}>;

export type UstRegelsetUstKategorieZuweisungQuery = {
  getUstRegelsetUstKategorieZuweisung: {
    data: {
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      validFrom: string;
      ustRegelsetKategorieZuweisungId: string;
      ustKategorie: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        firmendatenId?: string | null;
        kurzBezeichnung: string;
        ustKategorieId: string;
        ustVomAufwand?: number | null;
        vstKuerzung?: boolean | null;
        status?: { text: string; value: Types.UstKategorieStatus } | null;
        ustKategorieEntryList: Array<{
          additionKonto: number;
          buchungsKZ?: string | null;
          createTs?: string | null;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          rechnungskennzeichen: string;
          steuersatz: number;
          ustKategorieEntryId?: string | null;
          ustKz: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kuerzel: string;
            umsatzsteuerkennzeichenId: string;
            status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ message: string; attribute?: string | null; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const UstRegelsetUstKategorieZuweisungListDocument = gql`
  query UstRegelsetUstKategorieZuweisungList($ustRegelsetId: ID!) {
    getUstRegelsetUstKategorieZuweisungList(ustRegelsetId: $ustRegelsetId) {
      data {
        ...UstRegelsetUstKategorieZuweisungFields
        lastUpdateTs: createTs
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${UstRegelsetUstKategorieZuweisungFieldsFragmentDoc}
`;
export function useUstRegelsetUstKategorieZuweisungListQuery(
  baseOptions: Apollo.QueryHookOptions<UstRegelsetUstKategorieZuweisungListQuery, UstRegelsetUstKategorieZuweisungListQueryVariables> &
    ({ variables: UstRegelsetUstKategorieZuweisungListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstRegelsetUstKategorieZuweisungListQuery, UstRegelsetUstKategorieZuweisungListQueryVariables>(
    UstRegelsetUstKategorieZuweisungListDocument,
    options
  );
}
export function useUstRegelsetUstKategorieZuweisungListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UstRegelsetUstKategorieZuweisungListQuery, UstRegelsetUstKategorieZuweisungListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstRegelsetUstKategorieZuweisungListQuery, UstRegelsetUstKategorieZuweisungListQueryVariables>(
    UstRegelsetUstKategorieZuweisungListDocument,
    options
  );
}
export function useUstRegelsetUstKategorieZuweisungListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UstRegelsetUstKategorieZuweisungListQuery, UstRegelsetUstKategorieZuweisungListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstRegelsetUstKategorieZuweisungListQuery, UstRegelsetUstKategorieZuweisungListQueryVariables>(
    UstRegelsetUstKategorieZuweisungListDocument,
    options
  );
}
export type UstRegelsetUstKategorieZuweisungListQueryHookResult = ReturnType<typeof useUstRegelsetUstKategorieZuweisungListQuery>;
export type UstRegelsetUstKategorieZuweisungListLazyQueryHookResult = ReturnType<typeof useUstRegelsetUstKategorieZuweisungListLazyQuery>;
export type UstRegelsetUstKategorieZuweisungListSuspenseQueryHookResult = ReturnType<typeof useUstRegelsetUstKategorieZuweisungListSuspenseQuery>;
export type UstRegelsetUstKategorieZuweisungListQueryResult = Apollo.QueryResult<
  UstRegelsetUstKategorieZuweisungListQuery,
  UstRegelsetUstKategorieZuweisungListQueryVariables
>;
export const UstRegelsetUstKategorieZuweisungDocument = gql`
  query UstRegelsetUstKategorieZuweisung($ustRegelsetId: ID!, $ustRegelsetUstKategorieZuweisungId: ID!) {
    getUstRegelsetUstKategorieZuweisung(ustRegelsetId: $ustRegelsetId, ustRegelsetUstKategorieZuweisungId: $ustRegelsetUstKategorieZuweisungId) {
      data {
        ...UstRegelsetUstKategorieZuweisungFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${UstRegelsetUstKategorieZuweisungFieldsFragmentDoc}
`;
export function useUstRegelsetUstKategorieZuweisungQuery(
  baseOptions: Apollo.QueryHookOptions<UstRegelsetUstKategorieZuweisungQuery, UstRegelsetUstKategorieZuweisungQueryVariables> &
    ({ variables: UstRegelsetUstKategorieZuweisungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstRegelsetUstKategorieZuweisungQuery, UstRegelsetUstKategorieZuweisungQueryVariables>(
    UstRegelsetUstKategorieZuweisungDocument,
    options
  );
}
export function useUstRegelsetUstKategorieZuweisungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UstRegelsetUstKategorieZuweisungQuery, UstRegelsetUstKategorieZuweisungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstRegelsetUstKategorieZuweisungQuery, UstRegelsetUstKategorieZuweisungQueryVariables>(
    UstRegelsetUstKategorieZuweisungDocument,
    options
  );
}
export function useUstRegelsetUstKategorieZuweisungSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UstRegelsetUstKategorieZuweisungQuery, UstRegelsetUstKategorieZuweisungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstRegelsetUstKategorieZuweisungQuery, UstRegelsetUstKategorieZuweisungQueryVariables>(
    UstRegelsetUstKategorieZuweisungDocument,
    options
  );
}
export type UstRegelsetUstKategorieZuweisungQueryHookResult = ReturnType<typeof useUstRegelsetUstKategorieZuweisungQuery>;
export type UstRegelsetUstKategorieZuweisungLazyQueryHookResult = ReturnType<typeof useUstRegelsetUstKategorieZuweisungLazyQuery>;
export type UstRegelsetUstKategorieZuweisungSuspenseQueryHookResult = ReturnType<typeof useUstRegelsetUstKategorieZuweisungSuspenseQuery>;
export type UstRegelsetUstKategorieZuweisungQueryResult = Apollo.QueryResult<
  UstRegelsetUstKategorieZuweisungQuery,
  UstRegelsetUstKategorieZuweisungQueryVariables
>;
