import React, { FC } from 'react';
import { Modal, Typography } from 'antd';
import { showSuccessMsgOther } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { useSetMahndefinitionForRechtstraegerMutation } from '../../gql/RechnungsAusstellerMutations.types';
import { TableWithColSelectorColumnProps } from '../../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import MitarbeiterTooltip from '../../../../../../components/Card/MitarbeiterTooltip';
import { MahndefinitionFieldsFragment } from '../../../../../Mahndefinition/gql/MahndefinitionFragments.types';
import FormattedDateTime from '../../../../../../components/FormattedDateTime/FormattedDateTime';

const rechnungsAusstellerMahnDefTableColumns = (
  rechtstraegerId: string,
  onSuccess: () => void
): TableWithColSelectorColumnProps<MahndefinitionFieldsFragment>[] => [
  {
    title: 'Bezeichnung',
    defaultSelected: true,
    render: (text, record) => <Typography.Text>{record.bezeichnung}</Typography.Text>,
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: () => <RechnungsAusstellerMahnDefActions onSuccess={onSuccess} rechtstraegerId={rechtstraegerId} />,
  },
];

type Props = {
  onSuccess: () => void;
  rechtstraegerId: string;
};

const RechnungsAusstellerMahnDefActions: FC<Props> = ({ onSuccess, rechtstraegerId }) => {
  const [deleteMahnDefZuweisung] = useSetMahndefinitionForRechtstraegerMutation({
    variables: { rechtstraegerId, input: { mahndefinitionId: '' } },
    onCompleted: () => {
      showSuccessMsgOther(`Mahndefinitionzuweisung erfolgreich gelöscht.`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return <a onClick={showConfirmDelete(deleteMahnDefZuweisung)}>Löschen</a>;
};

const showConfirmDelete = (runDelete: () => void) => () => {
  Modal.confirm({
    title: 'Möchten Sie die Zuweisung löschen?',
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default rechnungsAusstellerMahnDefTableColumns;
