import React, { FC } from 'react';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import HauseigentuemerAbrechnungTemplate from '../../../../../pages/PDFTemplates/templates/hauseigentuemerAbrechnung/HauseigentuemerAbrechnungTemplate';
import { HeOrSubAbrDefGeneralSettingsFormValues } from '../../../../Abrechnungsdefinition/shared/Templates/shared/HeOrSub/GeneralSettingForm/heOrSubAbrDefGeneralSettingsFormMapper';
import TemplateAbrechnung from '../../../../Abrechnungsdefinition/shared/Templates/TemplateAbrechnung';
import {
  createHeAbrechnungPDFTemplateData,
  mapTextbausteinToHeAbrechnungSelectableSection,
} from '../../../../Abrechnungsdefinition/shared/Templates/HeTemplates/heAbrDefTemplateFormMapper';

type Props = {
  values: TextbausteinFormValues[];
  onTemplateChange: (templateValues: TextbausteinFormValues[]) => void;
  abrDefGeneralSettingsValues: HeOrSubAbrDefGeneralSettingsFormValues;
};

const TemplateHeAbrechnung: FC<Props> = ({ values, onTemplateChange, abrDefGeneralSettingsValues }) => (
  <TemplateAbrechnung
    values={values}
    onTemplateChange={onTemplateChange}
    renderPreview={({ selectedTextbaustein }) => (
      <HauseigentuemerAbrechnungTemplate
        data={createHeAbrechnungPDFTemplateData(values, abrDefGeneralSettingsValues)}
        isInEditMode
        selectedTextbaustein={mapTextbausteinToHeAbrechnungSelectableSection(selectedTextbaustein)}
      />
    )}
  />
);

export default TemplateHeAbrechnung;
