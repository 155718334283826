import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { versandFormFields, VersandFormValues } from './versandFormMapper';
import { useRechtstraegerAddressesAndContactsQuery } from '../../../../Rechtstraeger/AddressesAndContacts/gql/AddressesAndContactsQueries.types';
import RechtstraegerSelect from '../../../../../shared/Rechtstraeger/RechtstraegerSelect';
import ContactEmailSelect from '../../../../Rechtstraeger/AddressesAndContacts/Form/shared/ContactEmailSelect';
import ContactPostSelect from '../../../../Rechtstraeger/AddressesAndContacts/Form/shared/ContactPostSelect';
import { useRechtstraegerTypeQuery } from '../../../../Rechtstraeger/gql/RechtsTraegerQueries.types';
import FormItemWithFieldHelp from '../../../../../components/Form/FormItemWithFieldHelp';

type RechtstraegerAndContactSelectsProps = {
  formikProps: FormikProps<VersandFormValues>;
  isOriginal: boolean;
  fieldHelpNames?: {
    rechtstraegerId?: string | null;
    emailContactId?: string | null;
    postAddressId?: string | null;
  };
};

const RechtstraegerAndContactSelects: FC<RechtstraegerAndContactSelectsProps> = ({ formikProps, isOriginal, fieldHelpNames }) => {
  const rechtstraegerId = formikProps.values.rechtstraegerId;

  const {
    data,
    loading: loadingContactList,
    refetch: refetchContactList,
  } = useRechtstraegerAddressesAndContactsQuery({
    variables: {
      rechtstraegerId,
    },
    skip: !rechtstraegerId,
  });

  const contactData = data?.getRechtstraegerAddressesAndContacts.data;
  const emailListForSelect = contactData ? [contactData.hauptEmailContact, ...contactData.emailContactList] : [];
  const postListForSelect = contactData ? [contactData.hauptAddress, ...contactData.streetAddressList, ...contactData.postofficeBoxAddressList] : [];

  const { data: rechtstreagerTypeData, loading: loadingRechtstraegerType } = useRechtstraegerTypeQuery({
    variables: { rechtstraegerId },
    skip: !rechtstraegerId,
  });

  const rechtstraegerType = rechtstreagerTypeData?.getRechtstraegerType.data.value;

  return (
    <>
      <FormItemWithFieldHelp name={versandFormFields.rechtstraegerId} label="An" fieldHelp={fieldHelpNames?.rechtstraegerId}>
        <RechtstraegerSelect
          name={versandFormFields.rechtstraegerId}
          placeholder="Person auswählen"
          onNewItemCreated={(rechtstraegerId) => formikProps.setFieldValue(versandFormFields.rechtstraegerId, rechtstraegerId)}
          onNewItemCreateSelected={() => formikProps.setFieldValue(versandFormFields.rechtstraegerId, '')}
          onChange={() => {
            formikProps.setFieldValue(versandFormFields.emailContactId, undefined);
            formikProps.setFieldValue(versandFormFields.postAddressId, undefined);
          }}
          onClear={() => {
            formikProps.setFieldTouched(versandFormFields.rechtstraegerId, true);
          }}
        />
      </FormItemWithFieldHelp>

      <FormItemWithFieldHelp name={versandFormFields.emailContactId} label="E-Mail an" fieldHelp={fieldHelpNames?.emailContactId}>
        <ContactEmailSelect
          disabled={!rechtstraegerId}
          emailList={emailListForSelect}
          name={versandFormFields.emailContactId}
          loading={loadingContactList || loadingRechtstraegerType}
          onNewItemCreated={(emailContactId) =>
            refetchContactList().then(() => formikProps.setFieldValue(versandFormFields.emailContactId, emailContactId))
          }
          onNewItemCreateSelected={() => formikProps.setFieldValue(versandFormFields.emailContactId, undefined)}
          rechtstraegerType={rechtstraegerType}
          rechtstraegerId={rechtstraegerId}
        />
      </FormItemWithFieldHelp>

      {isOriginal && (
        <FormItemWithFieldHelp name={versandFormFields.postAddressId} label="Post an" fieldHelp={fieldHelpNames?.postAddressId}>
          <ContactPostSelect
            disabled={!rechtstraegerId}
            postList={postListForSelect}
            name={versandFormFields.postAddressId}
            loading={loadingContactList || loadingRechtstraegerType}
            onNewItemCreated={(addressId) => refetchContactList().then(() => formikProps.setFieldValue(versandFormFields.postAddressId, addressId))}
            onNewItemCreateSelected={() => formikProps.setFieldValue(versandFormFields.postAddressId, undefined)}
            rechtstraegerType={rechtstraegerType}
            rechtstraegerId={rechtstraegerId}
          />
        </FormItemWithFieldHelp>
      )}
    </>
  );
};

export default RechtstraegerAndContactSelects;
