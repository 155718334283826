import React from 'react';
import { Link } from 'react-router-dom';

import { Tag, Typography } from 'antd';
import { Flex } from 'rebass';
import { compareTwoStringsForSorting } from '../../../../../helpers/stringHelper';
import { MitarbeiterTooltip } from '../../../../../components/Card';
import GenerierlaufEntryStatus from '../../../shared/GenerierlaufEntryStatus';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { generatePathToObjektDetailsPage } from '../../../../Objekt/objektUriPaths';
import { generatePathToRechtstraegerDetailsPage } from '../../../../Rechtstraeger/rechtstraegerHelper';
import { generatePathToHeAbrechnungDetailsHeVertragDetailsPage } from '../../../../Abrechnung/He/heAbrechnungUriPaths';
import { EuroAmount } from '../../../../../components/Number';
import StatusBadge from '../../../../../components/Status/StatusBadge';
import { HeAbrechnungGenerierlaufEntry, HeAbrechnungGenerierlaufOrderBy, HeAbrechnungHeVertrag } from '../../../../../types';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';

const heAbrechnungErstellenVerarbeitungEntryTableColumns = (
  heAbrechnungId?: string | null
): TableWithColSelectorColumnProps<HeAbrechnungGenerierlaufEntry>[] => [
  {
    title: 'Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.exitCode?.text, b.exitCode?.text),
    render: (text, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },
  {
    title: 'Objekt',
    defaultSelected: true,
    dataIndex: HeAbrechnungGenerierlaufOrderBy.Objekt,
    sorter: true,
    render: (text, record) =>
      record.heAbrechnungHeVertrag ? (
        <DataWithShortenedText maxTextLength={20} text={record.objektKurzBezeichnung}>
          {(shortenedText) => (
            <Link
              to={generatePathToObjektDetailsPage((record.heAbrechnungHeVertrag as Required<HeAbrechnungHeVertrag>).objekt.objektId)}
              target="_blank"
            >
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ) : (
        <Typography.Text>{record.objektKurzBezeichnung}</Typography.Text>
      ),
  },
  {
    title: 'Hauseigentümer',
    defaultSelected: true,
    dataIndex: HeAbrechnungGenerierlaufOrderBy.Hauseigentuemer,
    sorter: true,
    render: (text, record) =>
      record.heAbrechnungHeVertrag ? (
        <DataWithShortenedText maxTextLength={20} text={record.heAbrechnungHeVertrag.hauseigentuemer.kurzBezeichnung}>
          {(shortenedText) => (
            <Link
              to={generatePathToRechtstraegerDetailsPage(
                (record.heAbrechnungHeVertrag as Required<HeAbrechnungHeVertrag>).hauseigentuemer.rechtstraegerId
              )}
              target="_blank"
            >
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Zeitraum',
    defaultSelected: true,
    dataIndex: HeAbrechnungGenerierlaufOrderBy.Zeitraum,
    sorter: true,
    render: (text, record) =>
      record.heAbrechnungHeVertrag ? (
        <>
          <CustomFormattedDate value={record.heAbrechnungHeVertrag.abrechnungszeitraumVon} /> –
          <CustomFormattedDate value={record.heAbrechnungHeVertrag.abrechnungszeitraumBis} />
        </>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Abrechnungsergebnis',
    defaultSelected: true,
    render: (text, record) =>
      record.heAbrechnungHeVertrag ? (
        <>
          {record.heAbrechnungHeVertrag.saldo > 0 ? (
            <Flex justifyContent="space-between">
              <Tag color="green">Guthaben</Tag> <EuroAmount value={record.heAbrechnungHeVertrag.saldo} />
            </Flex>
          ) : (
            <Flex justifyContent="space-between">
              <Tag color="red">Nachzahlung</Tag> <EuroAmount value={record.heAbrechnungHeVertrag.saldo} />
            </Flex>
          )}
        </>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Erstellt am',
    render: (text, record) =>
      record.heAbrechnungHeVertrag ? (
        <FormattedDateTime createdTs={record.heAbrechnungHeVertrag.createTs} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Ersteller',
    render: (text, record) =>
      record.heAbrechnungHeVertrag ? (
        <MitarbeiterTooltip
          mitarbeiterId={record.heAbrechnungHeVertrag.createdByMitarbeiterId}
          userId={record.heAbrechnungHeVertrag.createdBy}
          alignment="left"
          showsInitials
        />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Status',
    defaultSelected: true,
    render: (text, record) =>
      record.heAbrechnungHeVertrag ? <StatusBadge entityStatus={record.heAbrechnungHeVertrag.status} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    width: '75px',
    render: (text, record) =>
      record.heAbrechnungHeVertrag && heAbrechnungId ? (
        <Link
          to={generatePathToHeAbrechnungDetailsHeVertragDetailsPage(
            record.heAbrechnungHeVertrag.objekt.objektId,
            heAbrechnungId,
            record.heAbrechnungHeVertrag.heAbrechnungHeVertragId
          )}
        >
          Details
        </Link>
      ) : (
        <TextForEmptyValue textToShow="none" />
      ),
  },
];

export default heAbrechnungErstellenVerarbeitungEntryTableColumns;
