import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Button, Switch, Form as FormAntD, Space } from 'antd';
import { CloseCircleOutlined, CheckOutlined } from '@ant-design/icons';
import CurrentEmailFormPart from '../../shared/components/Email/CurrentEmailFormPart';
import CurrentPasswordFormPart from '../../shared/components/Password/CurrentPasswordFormPart';
import { URI_PASSWORD_RESET } from '../../constants/passwordResetUriPaths';
import { Spacer } from '../../components/Grid';

type LoginFormProps = {
  onSubmit: (values: LoginFormValues, onCompleted: () => void) => void;
  isLoading: boolean;
};

const LoginForm: FC<LoginFormProps> = ({ onSubmit, isLoading }) => {
  const initialValues = {
    email: '',
    password: '',
  };

  return (
    <Formik<LoginFormValues>
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        const onCompleted = () => setSubmitting(false);
        onSubmit(values, onCompleted);
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <CurrentEmailFormPart emailInputName="email" emailInputLabel="E-Mail" emailInputPlaceholder="z.B. johndoe@andromeda.software" />
          <CurrentPasswordFormPart currentPasswordInputName="password" currentPasswordLabel="Passwort" currentPasswordPlaceholder="Passwort" />
          <FormAntD.Item>
            <div style={{ float: 'right' }}>
              <Space>
                Testsystem <Switch size="default" checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseCircleOutlined />} />
              </Space>
            </div>
            <Spacer height={32} />
            <Button type="primary" block htmlType="submit" disabled={formikProps.isSubmitting || isLoading} loading={isLoading}>
              Einloggen
            </Button>
            oder <Link to={URI_PASSWORD_RESET}>Passwort vergessen?</Link>
          </FormAntD.Item>
        </Form>
      )}
    </Formik>
  );
};

export type LoginFormValues = {
  email: string;
  password: string;
};

export default LoginForm;
