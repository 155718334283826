import {
  BkAbrechnungsdefinitionVersionCreateInput,
  HeAbrechnungsdefinitionVersionCreateInput,
  SubAdministrationAbrechnungsdefinitionVersionCreateInput,
  WeAbrechnungsdefinitionVersionCreateInput,
} from '../../../../../types';
import {
  AbrDefAbrKreisListingFormValues,
  mapFormValuesToAbrDefAbrKreisList,
} from '../../../shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormMapper';
import { mapFormValuesToTextbaustein } from '../../../shared/Templates/shared/abrDefTemplateFormMapper';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import {
  mapObjAbrDefGenSetFormValuesToOutputOptions,
  ObjAbrDefGeneralSettingsFormValues,
} from '../../../shared/Templates/shared/BkOrWe/ObjAbrDefGeneralSettingsForm/objAbrDefGeneralSettingsFormMapper';
import {
  mapTopAbrDefGenSetFormValuesToOutputOptions,
  TopAbrDefGeneralSettingsFormValues,
} from '../../../shared/Templates/shared/BkOrWe/TopAbrDefGeneralSettingsForm/topAbrDefGeneralSettingsFormMapper';
import {
  HeOrSubAbrDefGeneralSettingsFormValues,
  mapHeOrSubAbrDefGenSetFormValuesToOutputOptions,
} from '../../../shared/Templates/shared/HeOrSub/GeneralSettingForm/heOrSubAbrDefGeneralSettingsFormMapper';

export const mapFormValuesToHeAbrechnungsdefinitionVersionCreate = (
  abrKreisListFormValues: AbrDefAbrKreisListingFormValues,
  heTemplateFormValues: TextbausteinFormValues[],
  validFromFormValue: string,
  kommentar: string,
  outputOptions: HeOrSubAbrDefGeneralSettingsFormValues
): HeAbrechnungsdefinitionVersionCreateInput => ({
  abrechdefAbrechkreisList: mapFormValuesToAbrDefAbrKreisList(abrKreisListFormValues.abrechdefAbrechkreisList),
  validFrom: validFromFormValue,
  kommentar,
  heAbrechnungTextbausteinList: mapFormValuesToTextbaustein(heTemplateFormValues),
  outputOptions: mapHeOrSubAbrDefGenSetFormValuesToOutputOptions(outputOptions),
});

export const mapFormValuesToBkOrWeAbrechnungsdefinitionVersionCreate = (
  abrKreisListFormValues: AbrDefAbrKreisListingFormValues,
  objektTemplateFormValues: TextbausteinFormValues[],
  topTemplateFormValues: TextbausteinFormValues[],
  validFromFormValue: string,
  kommentar: string,
  objAbrDefGeneralSettingsValues: ObjAbrDefGeneralSettingsFormValues,
  topAbrDefGeneralSettingsValues: TopAbrDefGeneralSettingsFormValues
): WeAbrechnungsdefinitionVersionCreateInput | BkAbrechnungsdefinitionVersionCreateInput => ({
  abrechdefAbrechkreisList: mapFormValuesToAbrDefAbrKreisList(abrKreisListFormValues.abrechdefAbrechkreisList),
  validFrom: validFromFormValue,
  kommentar,
  objektAbrechnungTextbausteinList: mapFormValuesToTextbaustein(objektTemplateFormValues),
  topAbrechnungTextbausteinList: mapFormValuesToTextbaustein(topTemplateFormValues),
  objektAbrechnungOutputOptions: mapObjAbrDefGenSetFormValuesToOutputOptions(objAbrDefGeneralSettingsValues),
  topAbrechnungOutputOptions: mapTopAbrDefGenSetFormValuesToOutputOptions(topAbrDefGeneralSettingsValues),
});

export const mapFormValuesToSubAdministrationAbrechnungsdefinitionVersionCreate = (
  abrKreisListFormValues: AbrDefAbrKreisListingFormValues,
  heTemplateFormValues: TextbausteinFormValues[],
  validFromFormValue: string,
  kommentar: string,
  outputOptions: HeOrSubAbrDefGeneralSettingsFormValues
): SubAdministrationAbrechnungsdefinitionVersionCreateInput => ({
  abrechdefAbrechkreisList: mapFormValuesToAbrDefAbrKreisList(abrKreisListFormValues.abrechdefAbrechkreisList),
  validFrom: validFromFormValue,
  kommentar,
  subAdministrationAbrechnungTextbausteinList: mapFormValuesToTextbaustein(heTemplateFormValues),
  outputOptions: mapHeOrSubAbrDefGenSetFormValuesToOutputOptions(outputOptions),
});
