import React, { FC } from 'react';
import { Box, Flex } from 'rebass';
import { Divider, Typography } from 'antd';
import ZuordnungTableHeader from '../../../../AbrKontenzuordnung/shared/ZuordnungTableHeader';
import { AbrechnungsdefinitionType, Konto } from '../../../../../types';
import { ZuordnungKontoKlasseType, zuordnungListHeight } from '../../../../AbrKontenzuordnung/shared/AbrKreisKontenzuordnungHelpers';
import { AbrechnungsdefinitionVersion } from '../../../shared/abrDefTypes';
import ZuordnungColumnOnlyView from './ZuordnungColumnOnlyView';
import ZuordnungKontoListOnlyView from './ZuordnungKontoListOnlyView';
import { mapToZuordnungViewModelList } from '../../../../AbrKontenzuordnung/shared/zuordnungColumnHelpers';

type Props = {
  abrechnungsdefinitionVersion: AbrechnungsdefinitionVersion;
  abrechnungsdefinitionId: string;
  abrDefinitionType: AbrechnungsdefinitionType;
  einnahmenKontoList: Konto[];
  ausgabenKontoList: Konto[];
  verrechnungKontoList: Konto[];
  isEinnahmenListOpen: boolean;
  toggleEinnahmenKontoList: () => void;
  isAusgabenListOpen: boolean;
  toggleAusgabenList: () => void;
  isVerrechnungListOpen: boolean;
  toggleVerrechnungList: () => void;
};

const AbrDefKontenzuordnungOnlyView: FC<Props> = ({
  abrechnungsdefinitionVersion,
  abrechnungsdefinitionId,
  abrDefinitionType,
  einnahmenKontoList,
  ausgabenKontoList,
  verrechnungKontoList,
  isEinnahmenListOpen,
  isAusgabenListOpen,
  isVerrechnungListOpen,
  toggleEinnahmenKontoList,
  toggleAusgabenList,
  toggleVerrechnungList,
}) => {
  const zuordnungList = abrechnungsdefinitionVersion.kontoAbrechnungskreisList;

  return (
    <Flex flexDirection="row" flexWrap="wrap">
      <Flex>
        <Box width={zuordnungListHeight.left}>
          <ZuordnungTableHeader>
            <Typography.Title level={5} style={{ paddingLeft: '16px' }}>
              Konto
            </Typography.Title>
          </ZuordnungTableHeader>
          <ZuordnungKontoListOnlyView
            title="Einnahmen"
            zuordnungKontoKlasseType={ZuordnungKontoKlasseType.EINNAHMEN}
            list={mapToZuordnungViewModelList(abrechnungsdefinitionId, einnahmenKontoList, zuordnungList)}
            isCollapsed={isEinnahmenListOpen}
            collapse={toggleEinnahmenKontoList}
            keyForCollapse="1"
          />
          <Box height="16px" />
          <ZuordnungKontoListOnlyView
            zuordnungKontoKlasseType={ZuordnungKontoKlasseType.AUSGABEN}
            title="Ausgaben"
            list={mapToZuordnungViewModelList(abrechnungsdefinitionId, ausgabenKontoList, zuordnungList)}
            isCollapsed={isAusgabenListOpen}
            collapse={toggleAusgabenList}
            keyForCollapse="2"
          />
          <Box height="16px" />
          <ZuordnungKontoListOnlyView
            title="Verrechnung"
            zuordnungKontoKlasseType={ZuordnungKontoKlasseType.VERRECHNUNG}
            list={mapToZuordnungViewModelList(abrechnungsdefinitionId, verrechnungKontoList, zuordnungList)}
            isCollapsed={isVerrechnungListOpen}
            collapse={toggleVerrechnungList}
            keyForCollapse="3"
          />
        </Box>
        <Divider style={{ height: '100%', padding: 0, margin: 0 }} type="vertical" />
      </Flex>
      <Flex key={abrechnungsdefinitionVersion.abrechnungsdefinitionVersionId}>
        <ZuordnungColumnOnlyView
          abrechnungsdefinitionId={abrechnungsdefinitionId}
          abrDefinitionType={abrDefinitionType}
          einnahmenKontoList={einnahmenKontoList}
          ausgabenKontoList={ausgabenKontoList}
          verrechnungList={verrechnungKontoList}
          isEinnahmenListOpen={isEinnahmenListOpen}
          isAusgabenListOpen={isAusgabenListOpen}
          isVerrechnungListOpen={isVerrechnungListOpen}
          zuordnungList={zuordnungList}
        />
      </Flex>
    </Flex>
  );
};

export default AbrDefKontenzuordnungOnlyView;
