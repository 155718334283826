import { FC } from 'react';
import { useRechtstraegerStreetAddressDeletionHistoryListQuery } from '../gql/RechtsTraegerQueries.types';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';

type Props = {
  rechtstraegerId: string;
};

const RechstraegerStreetAddressDeletionHistoryListingTable: FC<Props> = ({ rechtstraegerId }) => {
  const { data, loading } = useRechtstraegerStreetAddressDeletionHistoryListQuery({ variables: { rechtstraegerId } });
  const historyList = data?.getRechtstraegerStreetAddressDeletionHistoryList.data;

  return (
    <HistoryListingTable
      historyType={HistoryType.Deletion}
      historyList={historyList}
      loading={loading}
      filterIdPrefix="rechtstraeger-street-address"
    />
  );
};

export default RechstraegerStreetAddressDeletionHistoryListingTable;
