import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { friendlyFormatIBAN } from 'ibantools';
import { BankDetails } from '../../../types';
import LinkToBelegFile from '../../../components/Link/LinkToBelegFile/LinkToBelegFile';
import { isSepaMandatStatusInactive, isSepaMandatStatusUnsigned } from '../SepaMandat/sepaMandatHelpers';
import SepaMandatLinkToRechnungsaussteller from '../SepaMandat/SepaMandatLinkToRechnungsaussteller';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';

type Props = {
  bankDetails: Pick<BankDetails, 'bankname' | 'accountHolder' | 'bic' | 'iban' | 'sepaMandat'>;
};

const BankDetailsCardContent: FC<Props> = ({ bankDetails }) => {
  const { sepaMandat } = bankDetails;

  return (
    <Space style={{ paddingLeft: 20 }} direction="vertical">
      {friendlyFormatIBAN(bankDetails.iban) ? (
        <Typography.Text>{friendlyFormatIBAN(bankDetails.iban)}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      )}

      {sepaMandat && !isSepaMandatStatusInactive(sepaMandat.status.value) && (
        <Typography.Text>
          SEPA{' '}
          {sepaMandat.fileId ? (
            <LinkToBelegFile fileId={sepaMandat.fileId} belegnummer={sepaMandat.sepaMandatReference} />
          ) : (
            <Typography.Text>{sepaMandat.sepaMandatReference}</Typography.Text>
          )}{' '}
          {isSepaMandatStatusUnsigned(sepaMandat.status.value) ? '(nicht unterschrieben) ' : '(unterschrieben) '}
          <br />
          für <SepaMandatLinkToRechnungsaussteller sepaMandat={sepaMandat} />
        </Typography.Text>
      )}
    </Space>
  );
};

export default BankDetailsCardContent;
