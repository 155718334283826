import { ValuePreservationType, VertragVposIndexedValueStatus } from '../../../../types';
import { TIndexedValueQueryParams } from './filtersQueryParams';
import { FormFields, mapFormDateValueToDateStringWithEndOf, mapFormDateValueToDateStringWithStartOf } from '../../../../helpers/formikHelper';

export type IndexedValueListingFiltersFormValues = {
  bestandseinheitId?: string | null;
  indexSeriesId?: string | null;
  objektId?: string | null;
  rechnungsAusstellerId?: string | null;
  statusList?: VertragVposIndexedValueStatus[] | null;
  validFrom?: string | null;
  validTo?: string | null;
  valuePreservationTypeList?: ValuePreservationType[] | null;
  vertragId?: string | null;
  vertragspartnerId?: string | null;
  vorschreibungspositionName?: string | null;
  setDefaultStatusOnInit?: boolean | null;
};

export const indexedValueListingFiltersFormFields: FormFields<IndexedValueListingFiltersFormValues> = {
  bestandseinheitId: 'bestandseinheitId',
  indexSeriesId: 'indexSeriesId',
  objektId: 'objektId',
  rechnungsAusstellerId: 'rechnungsAusstellerId',
  statusList: 'statusList',
  validFrom: 'validFrom',
  validTo: 'validTo',
  valuePreservationTypeList: 'valuePreservationTypeList',
  vertragId: 'vertragId',
  vertragspartnerId: 'vertragspartnerId',
  vorschreibungspositionName: 'vorschreibungspositionName',
  setDefaultStatusOnInit: 'setDefaultStatusOnInit',
};

export const indexedValueListingFiltersFormInitialValues = (filters: TIndexedValueQueryParams): IndexedValueListingFiltersFormValues => {
  const setDefaultStatusOnInit = filters.setDefaultStatusOnInit ?? true;

  return {
    bestandseinheitId: filters.bestandseinheitId,
    indexSeriesId: filters.indexSeriesId,
    objektId: filters.objektId,
    rechnungsAusstellerId: filters.rechnungsAusstellerId,
    statusList: filters.statusList ?? (setDefaultStatusOnInit ? [VertragVposIndexedValueStatus.Submitted] : []),
    validFrom: filters.validFrom,
    validTo: filters.validTo,
    valuePreservationTypeList: filters.valuePreservationTypeList ?? [],
    vertragId: filters.vertragId,
    vertragspartnerId: filters.vertragspartnerId,
    vorschreibungspositionName: filters.vorschreibungspositionName,
  };
};

export const mapFormValuesToIndexedValueListingFilters = (formValues: IndexedValueListingFiltersFormValues): TIndexedValueQueryParams => ({
  bestandseinheitId: formValues.bestandseinheitId,
  indexSeriesId: formValues.indexSeriesId,
  objektId: formValues.objektId,
  rechnungsAusstellerId: formValues.rechnungsAusstellerId,
  statusList: formValues.statusList ?? [],
  validFrom: formValues.validFrom ? mapFormDateValueToDateStringWithStartOf(formValues.validFrom, 'month') : '',
  validTo: formValues.validTo ? mapFormDateValueToDateStringWithEndOf(formValues.validTo, 'month') : '',
  valuePreservationTypeList: formValues.valuePreservationTypeList ?? [],
  vertragId: formValues.vertragId,
  vertragspartnerId: formValues.vertragspartnerId,
  vorschreibungspositionName: formValues.vorschreibungspositionName,
});

// sorted alphabetically based on the UI labels
export const indexedValueListingLabelList = {
  [indexedValueListingFiltersFormFields.bestandseinheitId]: 'Bestandseinheit',
  [indexedValueListingFiltersFormFields.vorschreibungspositionName]: 'Indexierter Wert',
  [indexedValueListingFiltersFormFields.indexSeriesId]: 'Indexreihe',
  [indexedValueListingFiltersFormFields.objektId]: 'Objekt',
  [indexedValueListingFiltersFormFields.rechnungsAusstellerId]: 'Rechnungsaussteller',
  [indexedValueListingFiltersFormFields.statusList]: 'Status',
  [indexedValueListingFiltersFormFields.vertragId]: 'Vertrag',
  [indexedValueListingFiltersFormFields.vertragspartnerId]: 'Vertragspartner',
  [indexedValueListingFiltersFormFields.validFrom]: 'VPos Gültig ab',
  [indexedValueListingFiltersFormFields.valuePreservationTypeList]: 'Wertsicherungsart',
};
