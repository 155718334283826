import { generatePath } from 'react-router-dom';
import { URI_SYS_SETTINGS } from '../../constants/configurationUriPaths';
import { mapToQueryString, QUERY_PARAM_KEY } from '../../hooks/useStringQueryParams';

export const generatePathToEmailDeliveryDefinitionPage = (highlightedRowKey?: string) => {
  const queryString = mapToQueryString({
    [QUERY_PARAM_KEY.HIGHLIGHTED_ROW_KEY]: highlightedRowKey,
  });
  return `${generatePath(URI_SYS_SETTINGS.emailDeliveryDefinitionPage)}${queryString ? `?${queryString}` : ''}`;
};
