import React, { FC } from 'react';
import { Divider } from 'antd';
import { SingleInvoice } from '../../../types';
import InvoiceSumTotalContentBlock from './shared/InvoiceSumTotalContentBlock';

type Props = {
  incomingInvoice: SingleInvoice;
};

const SingleInvoiceSummeBlock: FC<Props> = ({ incomingInvoice }) => {
  return (
    <>
      <Divider>Summen</Divider>
      <InvoiceSumTotalContentBlock netto={incomingInvoice.netto} brutto={incomingInvoice.brutto} steuerzeileList={incomingInvoice.steuerzeileList} />
    </>
  );
};

export default SingleInvoiceSummeBlock;
