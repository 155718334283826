import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AufteilungsschluesselFieldsFragmentDoc } from '../../../Aufteilungsschluessel/gql/AufteilungsschluesselFragments.types';
import { KontoAbrechnungskreisFieldsFragmentDoc } from '../../../Abrechnungsdefinition/gql/AbrDefFragments.types';
import { TextbausteinFieldsFragmentDoc } from '../../../../components/Template/PDFTemplates/gql/TemplateFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBkAbrechnungsdefinitionTemplateVersionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  input: Types.BkAbrechnungsdefinitionVersionCreateInput;
}>;

export type CreateBkAbrechnungsdefinitionTemplateVersionMutation = {
  createBkAbrechnungsdefinitionTemplateVersion: {
    data: {
      abrechnungsdefinitionVersionId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      validFrom: string;
      kommentar?: string | null;
      abrechdefAbrechkreisList: Array<{
        abrechnungskreis: { abrechnungskreisId: string };
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vorschreibungsposition?: { vorschreibungspositionId: string } | null;
      }>;
      kontoAbrechnungskreisList: Array<{
        abrechnen: boolean;
        buchungszeilenAnzeigen: boolean;
        expenseReducing: boolean;
        ustVomAufwand: boolean;
        abrechnungskreis?: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
      objektAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      topAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateBkAbrechnungsdefinitionTemplateVersionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Types.Scalars['ID']['input'];
  input: Types.BkAbrechnungsdefinitionVersionUpdateInput;
}>;

export type UpdateBkAbrechnungsdefinitionTemplateVersionMutation = {
  updateBkAbrechnungsdefinitionTemplateVersion: {
    data: {
      abrechnungsdefinitionVersionId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      validFrom: string;
      kommentar?: string | null;
      abrechdefAbrechkreisList: Array<{
        abrechnungskreis: { abrechnungskreisId: string };
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vorschreibungsposition?: { vorschreibungspositionId: string } | null;
      }>;
      kontoAbrechnungskreisList: Array<{
        abrechnen: boolean;
        buchungszeilenAnzeigen: boolean;
        expenseReducing: boolean;
        ustVomAufwand: boolean;
        abrechnungskreis?: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
      objektAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      topAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteBkAbrechnungsdefinitionTemplateVersionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Types.Scalars['ID']['input'];
}>;

export type DeleteBkAbrechnungsdefinitionTemplateVersionMutation = {
  deleteBkAbrechnungsdefinitionTemplateVersion: {
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateBkAbrechnungsdefinitionTemplateVersionDocument = gql`
  mutation CreateBkAbrechnungsdefinitionTemplateVersion($abrechnungsdefinitionId: ID!, $input: BkAbrechnungsdefinitionVersionCreateInput!) {
    createBkAbrechnungsdefinitionTemplateVersion(abrechnungsdefinitionId: $abrechnungsdefinitionId, input: $input) {
      data {
        abrechdefAbrechkreisList {
          abrechnungskreis {
            abrechnungskreisId
          }
          aufteilungsschluessel {
            ...AufteilungsschluesselFields
          }
          vorschreibungsposition {
            vorschreibungspositionId
          }
        }
        abrechnungsdefinitionVersionId
        createTs
        createdBy
        createdByMitarbeiterId
        current
        deletable
        validFrom
        kommentar
        kontoAbrechnungskreisList {
          ...KontoAbrechnungskreisFields
        }
        objektAbrechnungTextbausteinList {
          ...TextbausteinFields
          textbausteinList {
            ...TextbausteinFields
          }
        }
        topAbrechnungTextbausteinList {
          ...TextbausteinFields
          textbausteinList {
            ...TextbausteinFields
          }
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
  ${KontoAbrechnungskreisFieldsFragmentDoc}
  ${TextbausteinFieldsFragmentDoc}
`;
export function useCreateBkAbrechnungsdefinitionTemplateVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBkAbrechnungsdefinitionTemplateVersionMutation,
    CreateBkAbrechnungsdefinitionTemplateVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBkAbrechnungsdefinitionTemplateVersionMutation, CreateBkAbrechnungsdefinitionTemplateVersionMutationVariables>(
    CreateBkAbrechnungsdefinitionTemplateVersionDocument,
    options
  );
}
export type CreateBkAbrechnungsdefinitionTemplateVersionMutationHookResult = ReturnType<
  typeof useCreateBkAbrechnungsdefinitionTemplateVersionMutation
>;
export type CreateBkAbrechnungsdefinitionTemplateVersionMutationResult = Apollo.MutationResult<CreateBkAbrechnungsdefinitionTemplateVersionMutation>;
export type CreateBkAbrechnungsdefinitionTemplateVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateBkAbrechnungsdefinitionTemplateVersionMutation,
  CreateBkAbrechnungsdefinitionTemplateVersionMutationVariables
>;
export const UpdateBkAbrechnungsdefinitionTemplateVersionDocument = gql`
  mutation UpdateBkAbrechnungsdefinitionTemplateVersion(
    $abrechnungsdefinitionId: ID!
    $abrechnungsdefinitionVersionId: ID!
    $input: BkAbrechnungsdefinitionVersionUpdateInput!
  ) {
    updateBkAbrechnungsdefinitionTemplateVersion(
      abrechnungsdefinitionId: $abrechnungsdefinitionId
      abrechnungsdefinitionVersionId: $abrechnungsdefinitionVersionId
      input: $input
    ) {
      data {
        abrechdefAbrechkreisList {
          abrechnungskreis {
            abrechnungskreisId
          }
          aufteilungsschluessel {
            ...AufteilungsschluesselFields
          }
          vorschreibungsposition {
            vorschreibungspositionId
          }
        }
        abrechnungsdefinitionVersionId
        createTs
        createdBy
        createdByMitarbeiterId
        current
        deletable
        validFrom
        kommentar
        kontoAbrechnungskreisList {
          ...KontoAbrechnungskreisFields
        }
        objektAbrechnungTextbausteinList {
          ...TextbausteinFields
          textbausteinList {
            ...TextbausteinFields
          }
        }
        topAbrechnungTextbausteinList {
          ...TextbausteinFields
          textbausteinList {
            ...TextbausteinFields
          }
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
  ${KontoAbrechnungskreisFieldsFragmentDoc}
  ${TextbausteinFieldsFragmentDoc}
`;
export function useUpdateBkAbrechnungsdefinitionTemplateVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBkAbrechnungsdefinitionTemplateVersionMutation,
    UpdateBkAbrechnungsdefinitionTemplateVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBkAbrechnungsdefinitionTemplateVersionMutation, UpdateBkAbrechnungsdefinitionTemplateVersionMutationVariables>(
    UpdateBkAbrechnungsdefinitionTemplateVersionDocument,
    options
  );
}
export type UpdateBkAbrechnungsdefinitionTemplateVersionMutationHookResult = ReturnType<
  typeof useUpdateBkAbrechnungsdefinitionTemplateVersionMutation
>;
export type UpdateBkAbrechnungsdefinitionTemplateVersionMutationResult = Apollo.MutationResult<UpdateBkAbrechnungsdefinitionTemplateVersionMutation>;
export type UpdateBkAbrechnungsdefinitionTemplateVersionMutationOptions = Apollo.BaseMutationOptions<
  UpdateBkAbrechnungsdefinitionTemplateVersionMutation,
  UpdateBkAbrechnungsdefinitionTemplateVersionMutationVariables
>;
export const DeleteBkAbrechnungsdefinitionTemplateVersionDocument = gql`
  mutation DeleteBkAbrechnungsdefinitionTemplateVersion($abrechnungsdefinitionId: ID!, $abrechnungsdefinitionVersionId: ID!) {
    deleteBkAbrechnungsdefinitionTemplateVersion(
      abrechnungsdefinitionId: $abrechnungsdefinitionId
      abrechnungsdefinitionVersionId: $abrechnungsdefinitionVersionId
    ) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteBkAbrechnungsdefinitionTemplateVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBkAbrechnungsdefinitionTemplateVersionMutation,
    DeleteBkAbrechnungsdefinitionTemplateVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBkAbrechnungsdefinitionTemplateVersionMutation, DeleteBkAbrechnungsdefinitionTemplateVersionMutationVariables>(
    DeleteBkAbrechnungsdefinitionTemplateVersionDocument,
    options
  );
}
export type DeleteBkAbrechnungsdefinitionTemplateVersionMutationHookResult = ReturnType<
  typeof useDeleteBkAbrechnungsdefinitionTemplateVersionMutation
>;
export type DeleteBkAbrechnungsdefinitionTemplateVersionMutationResult = Apollo.MutationResult<DeleteBkAbrechnungsdefinitionTemplateVersionMutation>;
export type DeleteBkAbrechnungsdefinitionTemplateVersionMutationOptions = Apollo.BaseMutationOptions<
  DeleteBkAbrechnungsdefinitionTemplateVersionMutation,
  DeleteBkAbrechnungsdefinitionTemplateVersionMutationVariables
>;
