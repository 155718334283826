import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useKuendigungsfristListQuery } from './gql/KuendigungsfristQueries.types';

type KuendigungfristSelectProps = SelectProps;

const KuendigungfristSelect: FC<KuendigungfristSelectProps> = ({ name, ...restProps }) => {
  const { data, loading } = useKuendigungsfristListQuery();

  const kuendigungsfristList = data?.getKuendigungsfristList.data ?? [];

  return (
    <Select name={name} loading={loading} id={name} allowClear size="small" style={{ minWidth: '160px' }} {...restProps}>
      {kuendigungsfristList.map((kuendigungsfrist) => (
        <Select.Option value={kuendigungsfrist.value} key={kuendigungsfrist.value}>
          {kuendigungsfrist.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default KuendigungfristSelect;
