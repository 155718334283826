import React from 'react';
import { useToggle } from '../../../../hooks/useToggle';
import { useUstKategorieTemplateListQuery } from '../gql/UstKategorieTemplateQueries.types';
import UstKategorieTemplateButtonsAndDrawer from './UstKategorieTemplateButtonsAndDrawer';
import UstKategorieTemplateTable from './Table/UstKategorieTemplateTable';

const UstKategorieTemplateListing = () => {
  const [isCollapsed, onCollapse] = useToggle();

  const { data, loading, refetch } = useUstKategorieTemplateListQuery();
  const ustKategorieList = data?.getUstKategorieTemplateList.data;

  return (
    <>
      <UstKategorieTemplateButtonsAndDrawer isCollapsed={isCollapsed} onCollapse={onCollapse} refetch={refetch} />
      <UstKategorieTemplateTable loading={loading} onCollapse={onCollapse} refetch={refetch} ustKategorieList={ustKategorieList} />
    </>
  );
};

export default UstKategorieTemplateListing;
