import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { AbrechnungskreisDetailsAbrechnungskreisAufteilungTop } from '../../../../../../topAbrechnung-types';
import TAAbrKreisAkontovorschreibung from './TAAbrKreisAkontovorschreibung';
import TAAbrKreisSaldo from './TAAbrKreisSaldo';
import TAAbrKreisUst from './TAAbrKreisUst';
import TAAbrKreisAbrechnungskreisergebnis from './TAAbrKreisAbrechnungskreisergebnis';

const TAAbrKreisAufteilungTopBlock: FC<{ aufteilungTop: AbrechnungskreisDetailsAbrechnungskreisAufteilungTop }> = ({ aufteilungTop }) => (
  <View>
    {/* Akontovorschreibung */}
    {aufteilungTop.akontoVorschreibung ? <TAAbrKreisAkontovorschreibung akontoVorschreibung={aufteilungTop.akontoVorschreibung} /> : null}

    {/* Saldo */}
    {aufteilungTop.saldo ? <TAAbrKreisSaldo saldo={aufteilungTop.saldo} /> : null}

    {/* Ust */}
    {aufteilungTop.ust ? <TAAbrKreisUst ust={aufteilungTop.ust} /> : null}

    {/* Abrechnungskreisergebnis */}
    <TAAbrKreisAbrechnungskreisergebnis abrechnungskreisErgebnis={aufteilungTop.abrechnungskreisErgebnis} />
  </View>
);

export default TAAbrKreisAufteilungTopBlock;
