import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Space } from 'antd';
import { IndexPoint, IndexSeriesEntry, IndexSeriesSourceType, IndexType } from '../../types';
import TextForEmptyValue from '../../components/Text/TextForEmptyValue';
import { isNotNil } from '../../helpers/assertionHelper';

export const isIndexSeriesSourceTypeImport = (sourceType?: IndexSeriesSourceType | null) => sourceType === IndexSeriesSourceType.Import;
export const isIndexSeriesSourceTypeManual = (sourceType?: IndexSeriesSourceType | null) => sourceType === IndexSeriesSourceType.Manual;

export const isIndexSeriesIndexTypeIndex = (indexType: IndexType) => indexType === IndexType.Index;
export const isIndexSeriesIndexTypeCategory = (indexType: IndexType) => indexType === IndexType.Category;
export const isIndexSeriesIndexTypeReferenceValue = (indexType: IndexType) => indexType === IndexType.ReferenceValue;

export const shortedMonths = ['Jän.', 'Feb.', 'Mär.', 'Apr.', 'Mai', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Okt.', 'Nov.', 'Dez.'];

export const createAllMonthsForIndexSeriesEntry = (indexSeriesSourceType: IndexSeriesSourceType, showPreliminary: boolean) => {
  return Array(12)
    .fill({})
    .map((_, index) => {
      return {
        title: shortedMonths[index],
        render: (text: string, record: IndexSeriesEntry) => {
          return createMonthRenderColumn(indexSeriesSourceType, record.indexPointList[index], showPreliminary);
        },
      };
    });
};

const createMonthRenderColumn = (indexSeriesSourceType: IndexSeriesSourceType, indexPoint: IndexPoint, showPreliminary: boolean) => {
  let preliminary;

  if (showPreliminary && isIndexSeriesSourceTypeImport(indexSeriesSourceType)) {
    preliminary = isNotNil(indexPoint?.preliminaryValue) ? (
      <FormattedNumber value={indexPoint.preliminaryValue} minimumFractionDigits={2} maximumFractionDigits={8} />
    ) : (
      <TextForEmptyValue textToShow="minus" />
    );
  }

  const finalValue = isNotNil(indexPoint?.finalValue) ? (
    <FormattedNumber value={indexPoint.finalValue} minimumFractionDigits={2} maximumFractionDigits={8} />
  ) : (
    <TextForEmptyValue textToShow="minus" />
  );

  return (
    <Space direction="vertical" size={0}>
      {finalValue}
      {preliminary}
    </Space>
  );
};
