import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Flex } from 'rebass';
import { Divider, Spin } from 'antd';
import { AbrKontenzuordnungPageRouteParams } from '../../../pages/SysSettingsPage/KundenSystem/AbrKontenzuordnungPage/routes';
import { useToggle } from '../../../hooks/useToggle';
import { KontoKlasse, KontoVerwendung } from '../../../types';
import { isNotNil } from '../../../helpers/assertionHelper';
import { Spacer } from '../../../components/Grid';
import ErrorMessagePanel from '../../../components/Error/ErrorMessagePanel';
import { useAbrechnungsdefinitionTemplateListQuery } from '../../AbrechnungsdefinitionTemplate/gql/AbrDefTemplateQueries.types';
import AbrKontenzuordnungColumnSelect from '../../AbrKontenzuordnung/Listing/AbrKontenzuordnungColumnSelect';
import AbrKontenzuordnungTemplateFormFilters from './Filters/AbrKontenzuordnungTemplateFormFilters';
import AbrKontenzuordnungTemplateZuordnungColumn from './AbrKontenzuordnungTemplateZuordnungColumn';
import { useKontoTemplateListQuery } from '../../Kontenrahmen/gql/KontenrahmenQueries.types';
import { AbrKontenzuordnungFiltersFormValues } from '../../AbrKontenzuordnung/Listing/Filters/abrKontenzuordnungFiltersMapper';
import AbrKontenzuordnungKontoListingColumn from '../../AbrKontenzuordnung/Listing/AbrKontenzuordnungKontoListingColumn';

const AbrKontenzuordnungTemplateListing: FC = () => {
  const { abrechnungsdefinitionId } = useParams() as AbrKontenzuordnungPageRouteParams;
  const [isEinnahmenListOpen, toggleEinnahmenKontoList] = useToggle();
  const [isAusgabenListOpen, toggleAusgabenList] = useToggle();
  const [isVerrechnungListOpen, toggleVerrechnungList] = useToggle();
  const [selectedAbrechnungsdefinitionVersionIdList, setSelectedAbrechnungsDefinitionVersionIdList] = useState<string[]>([]);
  const [filters, setFilters] = useState<AbrKontenzuordnungFiltersFormValues>();
  const [error, setError] = useState(false);

  const { data: maybeAbrechDefListData } = useAbrechnungsdefinitionTemplateListQuery({
    onCompleted: (data) => {
      if (abrechnungsdefinitionId) {
        const searchedAbrechDef = data.getAbrechnungsdefinitionTemplateList.data.find(
          (abrechDef) => abrechDef.abrechnungsdefinitionId === abrechnungsdefinitionId
        );
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setSelectedAbrechnungsDefinitionVersionIdList([searchedAbrechDef!.abrechnungsdefinitionVersion!.abrechnungsdefinitionVersionId]);
      } else {
        const abrechDefVersionIds = data.getAbrechnungsdefinitionTemplateList.data
          .map((abrechDef) => abrechDef.abrechnungsdefinitionVersion?.abrechnungsdefinitionVersionId)
          .filter(isNotNil);
        setSelectedAbrechnungsDefinitionVersionIdList(abrechDefVersionIds);
      }
    },
  });
  const abrechnungsdefinitionList = maybeAbrechDefListData?.getAbrechnungsdefinitionTemplateList.data;

  /* EINNAHMEN */
  /* Erloeskonto */
  const { data: maybeErloesKontoListData } = useKontoTemplateListQuery({
    variables: { klasseList: [KontoKlasse.BetrieblicherErloes, KontoKlasse.FinanzErloes] },
  });
  const erloesKontoList = maybeErloesKontoListData?.getKontoTemplateList.data;
  /* Umsatzsteuerkonto */
  const { data: maybeUmsatzsteuerKontoListData } = useKontoTemplateListQuery({
    variables: { klasseList: [KontoKlasse.Lieferverbindlichkeit], verwendung: KontoVerwendung.Umsatzsteuer },
  });
  const umsatzsteuerKontoList = maybeUmsatzsteuerKontoListData?.getKontoTemplateList.data;

  /* AUSGABEN */
  /* Aufwandkonto */
  const { data: maybeAufwandKontoListData } = useKontoTemplateListQuery({
    variables: {
      klasseList: [KontoKlasse.HandelswarenEinsatz, KontoKlasse.PersonalAufwand, KontoKlasse.BetrieblicherAufwand, KontoKlasse.FinanzAufwand],
    },
  });
  const aufwandKontoList = maybeAufwandKontoListData?.getKontoTemplateList.data;
  /* Vorsteuerkonto */
  const { data: maybeVorsteuerKontoListData } = useKontoTemplateListQuery({
    variables: {
      klasseList: [KontoKlasse.UmlaufVermoegen],
      verwendung: KontoVerwendung.Vorsteuer,
    },
  });
  const vorsteuerKontoList = maybeVorsteuerKontoListData?.getKontoTemplateList.data;

  /* EIGENTUEMERVERRECHNUNG */
  const { data: maybeEigentuemerVerrechnungListData } = useKontoTemplateListQuery({
    variables: {
      klasseList: [KontoKlasse.Eigenkapital],
    },
  });
  const eigentuemerVerrechnungList = maybeEigentuemerVerrechnungListData?.getKontoTemplateList.data;

  const loading =
    !abrechnungsdefinitionList ||
    !erloesKontoList ||
    !umsatzsteuerKontoList ||
    !aufwandKontoList ||
    !vorsteuerKontoList ||
    !eigentuemerVerrechnungList;

  return (
    <>
      <AbrKontenzuordnungColumnSelect
        abrechnungsdefinitionList={abrechnungsdefinitionList}
        selectedAbrechnungsdefinitionVersionIdList={selectedAbrechnungsdefinitionVersionIdList}
        setSelectedAbrechnungsDefinitionVersionIdList={setSelectedAbrechnungsDefinitionVersionIdList}
      />
      <Spacer height={16} />
      <AbrKontenzuordnungTemplateFormFilters onSubmit={(val) => setFilters(val)} />
      {error && (
        <ErrorMessagePanel error="Ein Fehler ist beim Laden der Seite aufgetreten. Bitte laden Sie die Seite erneut. /nSollte der Fehler weiterhin auftreten, kontaktieren Sie bitte den Support." />
      )}
      <Spacer height={16} />
      {loading ? (
        <Spin spinning={loading} />
      ) : (
        <Flex flexDirection="row" flexWrap="wrap">
          <AbrKontenzuordnungKontoListingColumn
            einnahmenKontoList={[...erloesKontoList, ...umsatzsteuerKontoList]}
            ausgabenKontoList={[...aufwandKontoList, ...vorsteuerKontoList]}
            verrechnungList={[...eigentuemerVerrechnungList]}
            isEinnahmenListOpen={isEinnahmenListOpen}
            isAusgabenListOpen={isAusgabenListOpen}
            isVerrechnungListOpen={isVerrechnungListOpen}
            toggleEinnahmenKontoList={toggleEinnahmenKontoList}
            toggleAusgabenList={toggleAusgabenList}
            toggleVerrechnungList={toggleVerrechnungList}
            filters={filters}
          />

          {abrechnungsdefinitionList
            .filter((abrechDef) =>
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              selectedAbrechnungsdefinitionVersionIdList.includes(abrechDef.abrechnungsdefinitionVersion!.abrechnungsdefinitionVersionId)
            )
            .map((abrechDef) => (
              <Flex key={abrechDef.abrechnungsdefinitionId}>
                <AbrKontenzuordnungTemplateZuordnungColumn
                  setError={setError}
                  abrechDef={abrechDef}
                  einnahmenKontoList={[...erloesKontoList, ...umsatzsteuerKontoList]}
                  ausgabenKontoList={[...aufwandKontoList, ...vorsteuerKontoList]}
                  verrechnungList={[...eigentuemerVerrechnungList]}
                  isEinnahmenListOpen={isEinnahmenListOpen}
                  isAusgabenListOpen={isAusgabenListOpen}
                  isVerrechnungListOpen={isVerrechnungListOpen}
                  filters={filters}
                />
                <Divider style={{ height: '100%', padding: 0, margin: 0 }} type="vertical" />
              </Flex>
            ))}
        </Flex>
      )}
    </>
  );
};

export default AbrKontenzuordnungTemplateListing;
