import React from 'react';
import { Link } from 'react-router-dom';
import { TableColumnProps, Typography } from 'antd';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { generatePathToBestandseinheitDetailsPage } from '../../../../Bestandseinheit/bestandseinheitUriPaths';
import { IncomingInvoiceBookingPositionListEntry, IncomingInvoiceListEntry } from '../../../../../types';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { EuroAmount, Percent } from '../../../../../components/Number';
import KontoLinkToKontoblatt from '../../../../../shared/components/Konto/KontoLinkToKontoblatt';

const bookingPositionTableColumns = (
  incomingInvoice: IncomingInvoiceListEntry,
  buchungsdatum?: string | null
): TableColumnProps<IncomingInvoiceBookingPositionListEntry>[] => [
  { title: '', width: 120 },
  { title: '' },
  { title: '' },
  { title: '' },
  { title: '' },
  {
    title: 'Bestandseinheit',
    render: (text, record) =>
      record.bestandseinheitId && record.bestandseinheitKurzBezeichnung && incomingInvoice.objektId ? (
        <DataWithShortenedText maxTextLength={20} text={record.bestandseinheitKurzBezeichnung}>
          {(shortenedText) => (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <Link to={generatePathToBestandseinheitDetailsPage(incomingInvoice.objektId!, record.bestandseinheitId!)} target="_blank">
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Konto',
    render: (text, record) => {
      return (
        <KontoLinkToKontoblatt
          konto={{
            kontoId: record.kontoId,
            nummer: record.kontoNummer,
            bezeichnung: record.kontoBezeichnung,
          }}
          buchungsdatumFrom={buchungsdatum}
          buchungsdatumTo={buchungsdatum}
          buchungskreisId={incomingInvoice.buchungskreisId}
          vertragspartnerId={incomingInvoice.issuerRechtstraegerId}
          maxBezeichnungLength={12}
        />
      );
    },
  },
  {
    title: 'Betrag',
    align: 'right',
    render: (text, record) => <EuroAmount value={record.betrag} />,
  },
  {
    title: 'Steuersatz',
    align: 'right',
    render: (text, record) => <Percent value={record.steuersatz / 100} />,
  },
  { title: '' },
  {
    title: 'Buchungstext',
    render: (text, record) => <Typography.Text>{record.buchungstext}</Typography.Text>,
  },
  { title: '' },
  { title: '' },
  { title: '' },
  { title: '' },
  { title: '', width: 180 },
  { title: '' },
  { title: '', width: 80 },
  { title: '' },
  { title: '' },
];

export default bookingPositionTableColumns;
