import React from 'react';
import { Row } from 'antd';
import PDFPageCol from './PDFPageCol';
import SubAdministrationAbrechnungObjektTemplate from './templates/subAdministrationAbrechnung/Objekt/SubAdministrationAbrechnungObjektTemplate';
import { mockSubAdministrationAbrechnungObjekt } from './mocks/subAdminAbrechnungObjektMocks';

const PDFPageSubAdminAbrechnungObjekt = () => (
  <Row>
    <PDFPageCol pdfTemplate={<SubAdministrationAbrechnungObjektTemplate data={mockSubAdministrationAbrechnungObjekt} />} />
  </Row>
);

export default PDFPageSubAdminAbrechnungObjekt;
