import React, { FC } from 'react';
import { Table } from 'antd';
import { FormikProps } from 'formik';
import { Verwaltungsart } from '../../../../../types';
import {
  VorsteuerkuerzungKontenZuweisungFormValues,
  VorsteuerkuerzungKontenZuweisungVersionFormValues,
} from '../../../../Vorsteuerkuerzung/KontenZuweisung/Version/Form/vstKuerzungKontenZuweisungVersionFormMapper';
import vstKuerzungTemplateKontenZuweisungTableColumns from './vstKuerzungTemplateKontenZuweisungTableColumns';

type VstKuerzungKontenZuweisungTableProps = {
  formikProps: FormikProps<VorsteuerkuerzungKontenZuweisungVersionFormValues>;
  onDelete: (index: number) => void;
  verwaltungsart: Verwaltungsart;
};

const VstKuerzungTemplateKontenZuweisungTable: FC<VstKuerzungKontenZuweisungTableProps> = ({ formikProps, onDelete, verwaltungsart }) => {
  return (
    <Table<VorsteuerkuerzungKontenZuweisungFormValues>
      dataSource={formikProps.values.kontenZuweisungList}
      //name={vstKuerzungKontenZuordnungVersionFormFields.kontenZuweisungList}
      columns={vstKuerzungTemplateKontenZuweisungTableColumns(formikProps, onDelete, verwaltungsart)}
      size="small"
      tableLayout="fixed"
      pagination={false}
    />
  );
};

export default VstKuerzungTemplateKontenZuweisungTable;
