import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { TextbausteinFieldsFragmentDoc } from '../../../components/Template/PDFTemplates/gql/TemplateFragments.types';
export type MahndefinitionFieldsFragment = {
  bezeichnung: string;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  firmendatenId?: string | null;
  mahndefinitionId: string;
  mahnfreieTage: number;
  textBausteineBefuellt: boolean;
  verzugszinsen: number;
  mahnstufe1: {
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    minBetrag: number;
    naechsteMahnungNachTage: number;
    updatedByMitarbeiterId?: string | null;
    versandEinschreiben: boolean;
    verzugszinsenBerechnen: boolean;
    mahngebuehrList: Array<{
      ab: number;
      betrag: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      mahngebuehrId: string;
    }>;
  };
  mahnstufe1TextbausteinList: Array<{
    index: number;
    value?: string | null;
    platzhalterList: Array<{ example: string; platzhalter: string }>;
    textbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    type: { text: string; value: Types.TextbausteinType };
  }>;
  mahnstufe2: {
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    minBetrag: number;
    naechsteMahnungNachTage: number;
    updatedByMitarbeiterId?: string | null;
    versandEinschreiben: boolean;
    verzugszinsenBerechnen: boolean;
    mahngebuehrList: Array<{
      ab: number;
      betrag: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      mahngebuehrId: string;
    }>;
  };
  mahnstufe2TextbausteinList: Array<{
    index: number;
    value?: string | null;
    platzhalterList: Array<{ example: string; platzhalter: string }>;
    textbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    type: { text: string; value: Types.TextbausteinType };
  }>;
  mahnstufe3: {
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    minBetrag: number;
    naechsteMahnungNachTage: number;
    updatedByMitarbeiterId?: string | null;
    versandEinschreiben: boolean;
    verzugszinsenBerechnen: boolean;
    mahngebuehrList: Array<{
      ab: number;
      betrag: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      mahngebuehrId: string;
    }>;
  };
  mahnstufe3TextbausteinList: Array<{
    index: number;
    value?: string | null;
    platzhalterList: Array<{ example: string; platzhalter: string }>;
    textbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    type: { text: string; value: Types.TextbausteinType };
  }>;
  mahnstufe4: {
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    minBetrag: number;
    naechsteMahnungNachTage: number;
    updatedByMitarbeiterId?: string | null;
    versandEinschreiben: boolean;
    verzugszinsenBerechnen: boolean;
    mahngebuehrList: Array<{
      ab: number;
      betrag: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      mahngebuehrId: string;
    }>;
  };
  mahnstufe4TextbausteinList: Array<{
    index: number;
    value?: string | null;
    platzhalterList: Array<{ example: string; platzhalter: string }>;
    textbausteinList: Array<{
      index: number;
      value?: string | null;
      platzhalterList: Array<{ example: string; platzhalter: string }>;
      type: { text: string; value: Types.TextbausteinType };
    }>;
    type: { text: string; value: Types.TextbausteinType };
  }>;
  status?: { text: string; value: Types.MahndefinitonStatus } | null;
};

export type MahnstufeFieldsFragment = {
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  minBetrag: number;
  naechsteMahnungNachTage: number;
  updatedByMitarbeiterId?: string | null;
  versandEinschreiben: boolean;
  verzugszinsenBerechnen: boolean;
  mahngebuehrList: Array<{
    ab: number;
    betrag: number;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    mahngebuehrId: string;
  }>;
};

export const MahnstufeFieldsFragmentDoc = gql`
  fragment MahnstufeFields on Mahnstufe {
    createTs
    createdBy
    createdByMitarbeiterId
    mahngebuehrList {
      ab
      betrag
      createTs
      createdBy
      createdByMitarbeiterId
      mahngebuehrId
    }
    minBetrag
    naechsteMahnungNachTage
    updatedByMitarbeiterId
    versandEinschreiben
    verzugszinsenBerechnen
  }
`;
export const MahndefinitionFieldsFragmentDoc = gql`
  fragment MahndefinitionFields on Mahndefinition {
    bezeichnung
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    firmendatenId
    mahndefinitionId
    mahnfreieTage
    mahnstufe1 {
      ...MahnstufeFields
    }
    mahnstufe1TextbausteinList {
      ...TextbausteinFields
    }
    mahnstufe2 {
      ...MahnstufeFields
    }
    mahnstufe2TextbausteinList {
      ...TextbausteinFields
    }
    mahnstufe3 {
      ...MahnstufeFields
    }
    mahnstufe3TextbausteinList {
      ...TextbausteinFields
    }
    mahnstufe4 {
      ...MahnstufeFields
    }
    mahnstufe4TextbausteinList {
      ...TextbausteinFields
    }
    textBausteineBefuellt
    status {
      text
      value
    }
    verzugszinsen
  }
  ${MahnstufeFieldsFragmentDoc}
  ${TextbausteinFieldsFragmentDoc}
`;
