import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MasseinheitListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MasseinheitListQuery = {
  getMasseinheitList: {
    data: Array<{ text: string; value: Types.Masseinheit }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const MasseinheitListDocument = gql`
  query MasseinheitList {
    getMasseinheitList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useMasseinheitListQuery(baseOptions?: Apollo.QueryHookOptions<MasseinheitListQuery, MasseinheitListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MasseinheitListQuery, MasseinheitListQueryVariables>(MasseinheitListDocument, options);
}
export function useMasseinheitListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MasseinheitListQuery, MasseinheitListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MasseinheitListQuery, MasseinheitListQueryVariables>(MasseinheitListDocument, options);
}
export function useMasseinheitListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MasseinheitListQuery, MasseinheitListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MasseinheitListQuery, MasseinheitListQueryVariables>(MasseinheitListDocument, options);
}
export type MasseinheitListQueryHookResult = ReturnType<typeof useMasseinheitListQuery>;
export type MasseinheitListLazyQueryHookResult = ReturnType<typeof useMasseinheitListLazyQuery>;
export type MasseinheitListSuspenseQueryHookResult = ReturnType<typeof useMasseinheitListSuspenseQuery>;
export type MasseinheitListQueryResult = Apollo.QueryResult<MasseinheitListQuery, MasseinheitListQueryVariables>;
