import React, { FC } from 'react';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import AbrDefVersionTimeline from './AbrDefVersionTimeline';
import { useDeleteBkAbrechnungsdefinitionVersionMutation } from '../../gql/BkAbrDef/BkAbrDefVersionMutations.types';
import { useBkAbrechnungsdefinitionVersionListQuery } from '../../gql/BkAbrDef/BkAbrDefVersionQueries.types';
import { BkAbrechnungsdefinitionVersionListEntry } from '../../../../types';

type Props = {
  abrechnungsdefinitionId: string;
};

const AbrDefBkVersionTimeline: FC<Props> = ({ abrechnungsdefinitionId }) => {
  const { data, loading, refetch } = useBkAbrechnungsdefinitionVersionListQuery({ variables: { abrechnungsdefinitionId, withDetails: true } });
  const versionList = data?.getBkAbrechnungsdefinitionVersionList.data;

  const [deleteBkAbrDefVersion] = useDeleteBkAbrechnungsdefinitionVersionMutation({
    onCompleted: () => {
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onDelete = (version: BkAbrechnungsdefinitionVersionListEntry): Promise<unknown> => {
    return deleteBkAbrDefVersion({
      variables: {
        abrechnungsdefinitionId,
        abrechnungsdefinitionVersionId: version.abrechnungsdefinitionVersionId,
      },
    });
  };

  return <AbrDefVersionTimeline abrechnungsdefinitionId={abrechnungsdefinitionId} loading={loading} versionList={versionList} onDelete={onDelete} />;
};

export default AbrDefBkVersionTimeline;
