import { useAsync } from 'react-async';
import Fetcher from '../../helpers/fetcher';
import { FETCH_OPTIONS_GET_DEFAULT } from '../../helpers/fetchHelper';
import { HTTP_STATUS_CODE, processAndReturnError } from '../../helpers/APIUtils';
import { uriFilePreviewForFirmendaten, uriFilePreviewForMitarbeiter, uriFileThumbnailForMitarbeiter } from './documentApiPaths';

const useGetPreviewForFirmendaten = (firmendatenId: string, fileId: string) =>
  useAsync({
    promiseFn: loadPreviewForFirmendaten,
    firmendatenId,
    fileId,
    watch: fileId,
  });

const loadPreviewForFirmendaten = async (_args: any) => {
  try {
    const res = await Fetcher.getInstance().fetch(
      uriFilePreviewForFirmendaten(_args.firmendatenId, _args.fileId),
      FETCH_OPTIONS_GET_DEFAULT as RequestInit
    );
    return await res.blob();
  } catch (error: any) {
    return await processAndReturnError(error);
  }
};

const useGetPreviewForMitarbeiter = (mitarbeiterId: string, fileId: string) =>
  useAsync({
    promiseFn: loadPreviewForMitarbeiter,
    mitarbeiterId,
    fileId,
    watch: fileId,
  });

export type LoadPreviewForMitarbeiterResponse = {
  blob: Blob;
  headers: Headers;
};

export const loadPreviewForMitarbeiter = async (_args: any) => {
  try {
    const res = await Fetcher.getInstance().fetch(
      uriFilePreviewForMitarbeiter(_args.mitarbeiterId, _args.fileId),
      FETCH_OPTIONS_GET_DEFAULT as RequestInit
    );
    const blob = await res.blob();
    return { blob, headers: res.headers } as LoadPreviewForMitarbeiterResponse;
  } catch (error: any) {
    return await processAndReturnError(error);
  }
};

const useLazyGetPreviewForMitarbeiter = (mitarbeiterId: string, fileId: string) =>
  useAsync({
    deferFn: loadLazyPreviewForMitarbeiter,
    mitarbeiterId,
    fileId,
    watch: fileId,
  });

const loadLazyPreviewForMitarbeiter = async (_args: any, props: any) => {
  try {
    const res = await Fetcher.getInstance().fetch(
      uriFilePreviewForMitarbeiter(props.mitarbeiterId, props.fileId),
      FETCH_OPTIONS_GET_DEFAULT as RequestInit
    );
    return await res.blob();
    // return URL.createObjectURL(blob);
  } catch (error: any) {
    return await processAndReturnError(error);
  }
};

const useGetThumbnailOrOriginalForMitarbeiter = (mitarbeiterId: string, fileId: string) =>
  useAsync<string | undefined>({
    promiseFn: loadThumbnailOrOriginalForMitarbeiter,
    mitarbeiterId,
    fileId,
    watch: fileId,
  });

const loadThumbnailOrOriginalForMitarbeiter = async (args: any): Promise<string | undefined> => {
  try {
    let res = await Fetcher.getInstance().fetch(
      uriFileThumbnailForMitarbeiter(args.mitarbeiterId, args.fileId),
      FETCH_OPTIONS_GET_DEFAULT as RequestInit
    );
    // thumnail not created yet so download original as a fallback
    if (res.status === HTTP_STATUS_CODE.NOT_FOUND) {
      res = await Fetcher.getInstance().fetch(
        uriFilePreviewForMitarbeiter(args.mitarbeiterId, args.fileId),
        FETCH_OPTIONS_GET_DEFAULT as RequestInit
      );
    }
    if (!res.ok) {
      return Promise.resolve(undefined);
    }
    const blob = await res.blob();
    return URL.createObjectURL(blob);
  } catch (error) {
    // return await processAndReturnError(error);
    console.error('error while loadThumbnailOrOriginalForMitarbeiter', error);
    // just ignore the error because if error occurs we just can't show the image but it is not critical
    return Promise.resolve(undefined);
  }
};

export { useGetThumbnailOrOriginalForMitarbeiter, useGetPreviewForMitarbeiter, useGetPreviewForFirmendaten, useLazyGetPreviewForMitarbeiter };
