import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type FirmendatenFieldsFragment = {
  companyRegistrationNumber?: string | null;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  firmendatenId: string;
  kundenSystemId: string;
  kurzBezeichnung: string;
  name1: string;
  name2?: string | null;
  name3?: string | null;
  sepaCreditorId?: string | null;
  vatIdentificationNumber?: string | null;
  firmeneinstellung: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    dueDateVorschreibung: { text: string; value: string };
  };
  legalForm: { text: string; value: Types.LegalForm };
  rolleList: Array<{
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    name: string;
    rolleId: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }>;
  status: { text: string; value: Types.FirmendatenStatus; description?: string | null };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type FirmendatenBaseFieldsFragment = {
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  firmendatenId: string;
  kundenSystemId: string;
  kurzBezeichnung: string;
  name1: string;
  name2?: string | null;
  name3?: string | null;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const FirmendatenFieldsFragmentDoc = gql`
  fragment FirmendatenFields on Firmendaten {
    companyRegistrationNumber
    createTs
    createdBy
    createdByMitarbeiterId
    firmendatenId
    firmeneinstellung {
      createTs
      createdBy
      createdByMitarbeiterId
      dueDateVorschreibung {
        text
        value
      }
    }
    kundenSystemId
    kurzBezeichnung
    legalForm {
      text
      value
    }
    name1
    name2
    name3
    rolleList {
      createTs
      createdBy
      createdByMitarbeiterId
      name
      rolleId
      warningList {
        attribute
        message
        type
      }
    }
    sepaCreditorId
    status {
      text
      value
      description
    }
    vatIdentificationNumber
    warningList {
      attribute
      message
      type
    }
  }
`;
export const FirmendatenBaseFieldsFragmentDoc = gql`
  fragment FirmendatenBaseFields on FirmendatenBase {
    createTs
    createdBy
    createdByMitarbeiterId
    firmendatenId
    kundenSystemId
    kurzBezeichnung
    name1
    name2
    name3
    warningList {
      attribute
      message
      type
    }
  }
`;
