import { ComponentType, Dispatch } from 'react';
import InfoMailDeliveryWizard from './InfoMailDelivery/InfoMailDeliveryWizard';
import VorschreibungMailDeliveryWizard from './VorschrebungMailDelivery/VorschreibungMailDeliveryWizard';
import InfoMailDeliveryListingTableActions from './InfoMailDelivery/ListingTableActions';
import VorschreibungListingTableActions from './VorschrebungMailDelivery/ListingTableActions';
import { Wizard } from '../../types';

export type WizardPageProps = {
  wizardId: string;
  activeStep: number;
  onCancel: () => void;
};

export type DeleteWizard = (isCompleted: boolean, setInProgress?: Dispatch<boolean>) => void;

export type WizardListingTableActionsProps = {
  wizard: Wizard;
  onAction: () => void;
};

export type WizardDetails = {
  type: string;
  name: string;
  uri: string;
  stepCount: number;
  page: ComponentType<WizardPageProps>;
  listingTableActions: ComponentType<WizardListingTableActionsProps>;
};

export const wizardTypes: { InfoMailDelivery: WizardDetails; VorschreibungMailDelivery: WizardDetails } = {
  InfoMailDelivery: {
    type: 'INFO_MAIL_DELIVERY',
    name: 'Informationsschreiben versenden',
    uri: 'info-mail',
    stepCount: 3,
    page: InfoMailDeliveryWizard,
    listingTableActions: InfoMailDeliveryListingTableActions,
  },
  VorschreibungMailDelivery: {
    type: 'VORSCHREIBUNG_MAIL_DELIVERY',
    name: 'Vorschreibungen versenden',
    uri: 'vorschreibung-mail',
    stepCount: 3,
    page: VorschreibungMailDeliveryWizard,
    listingTableActions: VorschreibungListingTableActions,
  },
};

export type WizardTypeList = typeof wizardTypes;

export const getWizardKeyByType = (type: string): keyof WizardTypeList | null => {
  for (const key in wizardTypes) {
    if (wizardTypes[key as keyof WizardTypeList].type === type) {
      return key as keyof WizardTypeList;
    }
  }
  return null;
};

export const getWizardDetailsByType = (type: string): WizardDetails | null => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return wizardTypes[getWizardKeyByType(type)!];
};

export const getWizardKeyByUri = (uri: string): keyof WizardTypeList | null => {
  for (const key in wizardTypes) {
    if (wizardTypes[key as keyof WizardTypeList].uri === uri) {
      return key as keyof WizardTypeList;
    }
  }
  return null;
};

export const getWizardDetailsByUri = (uri: string): WizardDetails | null => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const wizardType = wizardTypes[getWizardKeyByUri(uri)!];
  if (!wizardType) {
    console.error(`Wizard with type ${uri} not found`);
  }
  return wizardType;
};
