import { Typography } from 'antd';
import { FC } from 'react';

/** Used for rendering Typography.Text in a tooltip if a React.Node should be passed as a Tooltip title
 *
 * E.g.: If a Text and a FormattedNumber should be rendered in a Tooltip, and they are wrapped with a div => use this component for the text.
 *
 * Reason: We shouldn't render any text without wrapping it at least with Typography.Text component.
 * Since antd Tooltip has a dark background and Typography.Text uses black font by default,
 * this component uses the font color inherited from Tooltip styles to keep the color consistent */

const TypographyTextForTooltip: FC<{ text: string | number }> = ({ text }) => {
  return <Typography.Text style={{ color: 'inherit' }}>{text}</Typography.Text>;
};

export default TypographyTextForTooltip;
