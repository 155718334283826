import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { SingleInvoiceFieldsFragmentDoc } from './SingleInvoiceFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SingleInvoiceQueryVariables = Types.Exact<{
  belegId: Types.Scalars['String']['input'];
}>;

export type SingleInvoiceQuery = {
  getSingleInvoice: {
    data: {
      abgrenzungsBuchung: boolean;
      abgrenzungsBuchungsdatum?: string | null;
      ausstellerBezeichnung: string;
      ausstellerRechtstraegerId: string;
      belegId: string;
      brutto: number;
      buchungIdList: Array<string>;
      buchungsanweisungIdList: Array<string>;
      buchungskreisId?: string | null;
      buchungsdatum: string;
      dueDate: string;
      fileId: string;
      issuerBankDetailsIban: string;
      issuerBankDetailsId: string;
      kundenNummer?: string | null;
      netto: number;
      offenerBetrag?: number | null;
      objektBezeichnung?: string | null;
      objektId?: string | null;
      paid: boolean;
      payeeBezeichnung: string;
      payeeBankDetailsIban: string;
      payeeBankDetailsId: string;
      payeeRechtstraegerId: string;
      paymentCreatable: boolean;
      paymentIdList: Array<string>;
      paymentReferenceText?: string | null;
      purposeOfUseText?: string | null;
      rechnungsDatum: string;
      rechnungsNummer: string;
      requestedPaymentDate?: string | null;
      sepaMandatReference?: string | null;
      type: Types.InvoiceType;
      eingangsRechnungId: string;
      basis: { text: string; value: Types.IncomingInvoiceBasis };
      billingType: { text: string; value: Types.Verrechnungsart };
      buchungErrorList: Array<{ message: string; type: string }>;
      buchungsanweisungListEntryDataList: Array<{
        buchungsanweisungId: string;
        status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
        fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
      }>;
      buchungsanweisungStatus?: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus } | null;
      paymentMethod: { text: string; value: Types.PaymentMethod };
      buchungPositionList: Array<{
        betrag: number;
        bestandseinheitId?: string | null;
        bestandseinheitBezeichnung?: string | null;
        buchungstext: string;
        incomingInvoiceBuchungPositionId: string;
        kontoBezeichnung: string;
        kontoId: string;
        kontoNummer: string;
        steuersatz: number;
      }>;
      status: { text: string; value: Types.IncomingInvoiceStatus };
      steuerzeileList: Array<{ betrag: number; steuersatz: number }>;
      warningList: Array<{ message: string; type: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type SingleInvoiceForDetailsAndUpdatePageQueryVariables = Types.Exact<{
  belegId: Types.Scalars['String']['input'];
}>;

export type SingleInvoiceForDetailsAndUpdatePageQuery = {
  getSingleInvoiceForDetailsAndUpdatePage: {
    data: {
      abgrenzungsBuchung: boolean;
      abgrenzungsBuchungsdatum?: string | null;
      ausstellerBezeichnung: string;
      ausstellerRechtstraegerId: string;
      belegId: string;
      brutto: number;
      buchungIdList: Array<string>;
      buchungsanweisungIdList: Array<string>;
      buchungskreisId?: string | null;
      buchungsdatum: string;
      dueDate: string;
      fileId: string;
      issuerBankDetailsIban: string;
      issuerBankDetailsId: string;
      kundenNummer?: string | null;
      netto: number;
      offenerBetrag?: number | null;
      objektBezeichnung?: string | null;
      objektId?: string | null;
      paid: boolean;
      payeeBezeichnung: string;
      payeeBankDetailsIban: string;
      payeeBankDetailsId: string;
      payeeRechtstraegerId: string;
      paymentCreatable: boolean;
      paymentIdList: Array<string>;
      paymentReferenceText?: string | null;
      purposeOfUseText?: string | null;
      rechnungsDatum: string;
      rechnungsNummer: string;
      requestedPaymentDate?: string | null;
      sepaMandatReference?: string | null;
      type: Types.InvoiceType;
      eingangsRechnungId: string;
      basis: { text: string; value: Types.IncomingInvoiceBasis };
      billingType: { text: string; value: Types.Verrechnungsart };
      buchungErrorList: Array<{ message: string; type: string }>;
      buchungsanweisungListEntryDataList: Array<{
        buchungsanweisungId: string;
        status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
        fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
      }>;
      buchungsanweisungStatus?: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus } | null;
      paymentMethod: { text: string; value: Types.PaymentMethod };
      buchungPositionList: Array<{
        betrag: number;
        bestandseinheitId?: string | null;
        bestandseinheitBezeichnung?: string | null;
        buchungstext: string;
        incomingInvoiceBuchungPositionId: string;
        kontoBezeichnung: string;
        kontoId: string;
        kontoNummer: string;
        steuersatz: number;
      }>;
      status: { text: string; value: Types.IncomingInvoiceStatus };
      steuerzeileList: Array<{ betrag: number; steuersatz: number }>;
      warningList: Array<{ message: string; type: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type SingleInvoiceChangeHistoryQueryVariables = Types.Exact<{
  belegId: Types.Scalars['ID']['input'];
}>;

export type SingleInvoiceChangeHistoryQuery = {
  getSingleInvoiceChangeHistory: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const SingleInvoiceDocument = gql`
  query SingleInvoice($belegId: String!) {
    getSingleInvoice(belegId: $belegId) {
      data {
        eingangsRechnungId: belegId
        ...SingleInvoiceFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${SingleInvoiceFieldsFragmentDoc}
`;
export function useSingleInvoiceQuery(
  baseOptions: Apollo.QueryHookOptions<SingleInvoiceQuery, SingleInvoiceQueryVariables> &
    ({ variables: SingleInvoiceQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SingleInvoiceQuery, SingleInvoiceQueryVariables>(SingleInvoiceDocument, options);
}
export function useSingleInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SingleInvoiceQuery, SingleInvoiceQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SingleInvoiceQuery, SingleInvoiceQueryVariables>(SingleInvoiceDocument, options);
}
export function useSingleInvoiceSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SingleInvoiceQuery, SingleInvoiceQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SingleInvoiceQuery, SingleInvoiceQueryVariables>(SingleInvoiceDocument, options);
}
export type SingleInvoiceQueryHookResult = ReturnType<typeof useSingleInvoiceQuery>;
export type SingleInvoiceLazyQueryHookResult = ReturnType<typeof useSingleInvoiceLazyQuery>;
export type SingleInvoiceSuspenseQueryHookResult = ReturnType<typeof useSingleInvoiceSuspenseQuery>;
export type SingleInvoiceQueryResult = Apollo.QueryResult<SingleInvoiceQuery, SingleInvoiceQueryVariables>;
export const SingleInvoiceForDetailsAndUpdatePageDocument = gql`
  query SingleInvoiceForDetailsAndUpdatePage($belegId: String!) {
    getSingleInvoiceForDetailsAndUpdatePage(belegId: $belegId) {
      data {
        eingangsRechnungId: belegId
        ...SingleInvoiceFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${SingleInvoiceFieldsFragmentDoc}
`;
export function useSingleInvoiceForDetailsAndUpdatePageQuery(
  baseOptions: Apollo.QueryHookOptions<SingleInvoiceForDetailsAndUpdatePageQuery, SingleInvoiceForDetailsAndUpdatePageQueryVariables> &
    ({ variables: SingleInvoiceForDetailsAndUpdatePageQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SingleInvoiceForDetailsAndUpdatePageQuery, SingleInvoiceForDetailsAndUpdatePageQueryVariables>(
    SingleInvoiceForDetailsAndUpdatePageDocument,
    options
  );
}
export function useSingleInvoiceForDetailsAndUpdatePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SingleInvoiceForDetailsAndUpdatePageQuery, SingleInvoiceForDetailsAndUpdatePageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SingleInvoiceForDetailsAndUpdatePageQuery, SingleInvoiceForDetailsAndUpdatePageQueryVariables>(
    SingleInvoiceForDetailsAndUpdatePageDocument,
    options
  );
}
export function useSingleInvoiceForDetailsAndUpdatePageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SingleInvoiceForDetailsAndUpdatePageQuery, SingleInvoiceForDetailsAndUpdatePageQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SingleInvoiceForDetailsAndUpdatePageQuery, SingleInvoiceForDetailsAndUpdatePageQueryVariables>(
    SingleInvoiceForDetailsAndUpdatePageDocument,
    options
  );
}
export type SingleInvoiceForDetailsAndUpdatePageQueryHookResult = ReturnType<typeof useSingleInvoiceForDetailsAndUpdatePageQuery>;
export type SingleInvoiceForDetailsAndUpdatePageLazyQueryHookResult = ReturnType<typeof useSingleInvoiceForDetailsAndUpdatePageLazyQuery>;
export type SingleInvoiceForDetailsAndUpdatePageSuspenseQueryHookResult = ReturnType<typeof useSingleInvoiceForDetailsAndUpdatePageSuspenseQuery>;
export type SingleInvoiceForDetailsAndUpdatePageQueryResult = Apollo.QueryResult<
  SingleInvoiceForDetailsAndUpdatePageQuery,
  SingleInvoiceForDetailsAndUpdatePageQueryVariables
>;
export const SingleInvoiceChangeHistoryDocument = gql`
  query SingleInvoiceChangeHistory($belegId: ID!) {
    getSingleInvoiceChangeHistory(belegId: $belegId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useSingleInvoiceChangeHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<SingleInvoiceChangeHistoryQuery, SingleInvoiceChangeHistoryQueryVariables> &
    ({ variables: SingleInvoiceChangeHistoryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SingleInvoiceChangeHistoryQuery, SingleInvoiceChangeHistoryQueryVariables>(SingleInvoiceChangeHistoryDocument, options);
}
export function useSingleInvoiceChangeHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SingleInvoiceChangeHistoryQuery, SingleInvoiceChangeHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SingleInvoiceChangeHistoryQuery, SingleInvoiceChangeHistoryQueryVariables>(SingleInvoiceChangeHistoryDocument, options);
}
export function useSingleInvoiceChangeHistorySuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SingleInvoiceChangeHistoryQuery, SingleInvoiceChangeHistoryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SingleInvoiceChangeHistoryQuery, SingleInvoiceChangeHistoryQueryVariables>(
    SingleInvoiceChangeHistoryDocument,
    options
  );
}
export type SingleInvoiceChangeHistoryQueryHookResult = ReturnType<typeof useSingleInvoiceChangeHistoryQuery>;
export type SingleInvoiceChangeHistoryLazyQueryHookResult = ReturnType<typeof useSingleInvoiceChangeHistoryLazyQuery>;
export type SingleInvoiceChangeHistorySuspenseQueryHookResult = ReturnType<typeof useSingleInvoiceChangeHistorySuspenseQuery>;
export type SingleInvoiceChangeHistoryQueryResult = Apollo.QueryResult<SingleInvoiceChangeHistoryQuery, SingleInvoiceChangeHistoryQueryVariables>;
