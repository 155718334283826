import React, { FC } from 'react';
import beAufteilungsschluesselWertMitBezugTableColumns from './BeAufteilungsschluesselWertMitBezugTableColumns';
import { bestandseinheitAufteilungsschluesselWertFormValidationSchema } from '../../bestandseinheitAufteilungsschluesselWertFormValidationSchema';
import { showSuccessMsgCreate, showSuccessMsgUpdate, showWarningMessage } from '../../../../../components/message';
import {
  useCreateBestandseinheitAufteilungsschluesselWertMitBezugMutation,
  useUpdateBestandseinheitAufteilungsschluesselWertMitBezugMutation,
} from '../../gql/BestandseinheitAufteilungsschluesselMutations.types';
import {
  BeAufteilungsschluesselWertMitBezugFormValues,
  createDefaultNewEntryForWertMitBezug,
  mapFormValuesToWertMitBezug,
  mapWertMitBezugToFormValues,
} from './BeAufteilungsschluesselWertMitBezugMapper';
import { BeAufteilungsschluesselWertTableProps } from '../shared/BeAufteilungsschluesselWertTableProps';
import { bestandseinheitAufteilungsschluesselEditableTableGetRowKey } from '../shared/beAufteilungsschluesselWertHelper';
import { BeAufteilingsschluesselWert } from '../../../../../types';
import EditableTable from '../../../../../components/Table/EditableTableV2/EditableTable';

const BeAufteilungsschluesselWertMitBezugTable: FC<BeAufteilungsschluesselWertTableProps> = ({
  expanded,
  aufteilungsschluessel,
  onSuccess,
  objektId,
  bestandseinheitId,
  bestandseinheitAufteilungsschluesselWertList,
  isBestandseinheitAufteilungsschluesselWertListLoading,
}) => {
  const { aufteilungsschluesselId, bezugsbasis } = aufteilungsschluessel;

  const entity = 'Wert';
  const errorMsg = 'Wert wurde nicht aktualisiert';

  const [runUpdateWertMitBezug] = useUpdateBestandseinheitAufteilungsschluesselWertMitBezugMutation();
  const [runCreateWertMitBezug] = useCreateBestandseinheitAufteilungsschluesselWertMitBezugMutation();

  return (
    <EditableTable<BeAufteilingsschluesselWert, BeAufteilungsschluesselWertMitBezugFormValues>
      pagination={false}
      expanded={expanded}
      getRowKey={bestandseinheitAufteilungsschluesselEditableTableGetRowKey}
      columns={beAufteilungsschluesselWertMitBezugTableColumns(
        aufteilungsschluesselId,
        bestandseinheitAufteilungsschluesselWertList,
        bezugsbasis,
        onSuccess
      )}
      loading={isBestandseinheitAufteilungsschluesselWertListLoading}
      dataSource={bestandseinheitAufteilungsschluesselWertList}
      newEntryRowToBeAdded={{
        addButtonText: 'Wert hinzufügen',
        defaultNewEntry: createDefaultNewEntryForWertMitBezug(bestandseinheitAufteilungsschluesselWertList),
      }}
      mapToFormValues={(selectedRecord) => mapWertMitBezugToFormValues(bezugsbasis, bestandseinheitAufteilungsschluesselWertList, selectedRecord)}
      validationSchema={bestandseinheitAufteilungsschluesselWertFormValidationSchema}
      onSubmit={(formValues) => {
        const wert = mapFormValuesToWertMitBezug(formValues);
        if (!formValues.beAufteilungsschluesselWertId) {
          return runCreateWertMitBezug({
            variables: {
              objektId,
              bestandseinheitId,
              aufteilungsschluesselId,
              BeAufteilingsschluesselWertMitBezugInput: wert,
            },
          })
            .then((response) => {
              showSuccessMsgCreate(entity);
              onSuccess();
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              return response.data!.createBeAufteilungsschluesselWertMitBezug.data;
            })
            .catch((error) => {
              showWarningMessage(error, errorMsg);
              throw error;
            });
        } else {
          return runUpdateWertMitBezug({
            variables: {
              objektId,
              bestandseinheitId,
              aufteilungsschluesselId,
              beAufteilungsschluesselWertId: formValues.beAufteilungsschluesselWertId,
              BeAufteilingsschluesselWertMitBezugInput: wert,
            },
          })
            .then((response) => {
              showSuccessMsgUpdate(entity);
              onSuccess();
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              return response.data!.updateBeAufteilungsschluesselWertMitBezug.data;
            })
            .catch((error) => {
              showWarningMessage(error, errorMsg);
              throw error;
            });
        }
      }}
    />
  );
};

export default BeAufteilungsschluesselWertMitBezugTable;
