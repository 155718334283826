import React from 'react';
import { FormattedTime } from 'react-intl';
import { Space, Typography } from 'antd';
import DataWithShortenedTextAndExtraInfo from '../../Helpers/DataWithShortenedTextAndExtraInfo';
import MitarbeiterTooltip from '../../Card/MitarbeiterTooltip';
import { ITimeblock, IVersionable } from '../timelineCtx';
import CustomFormattedDate from '../../FormattedDate/CustomFormattedDate';

type TimelineCardTitleProps<T extends IVersionable<T>> = {
  isHistoricized?: boolean;
  titleKommentarPart?: string | null;
  timeblock: ITimeblock<T>;
};

function TimelineCardTitle<T extends IVersionable<T>>({ isHistoricized, titleKommentarPart, timeblock }: TimelineCardTitleProps<T>) {
  return (
    <Space direction="vertical">
      {titleKommentarPart && (
        <DataWithShortenedTextAndExtraInfo maxTextLength={80} text={titleKommentarPart ?? ''}>
          {(shortenedText) => (
            <Typography.Text strong type={isHistoricized ? 'secondary' : undefined}>
              {shortenedText}
            </Typography.Text>
          )}
        </DataWithShortenedTextAndExtraInfo>
      )}
      <Space size={4}>
        <MitarbeiterTooltip showsInitials userId={timeblock.createdBy} mitarbeiterId={timeblock.createdByMitarbeiterId} alignment="right" />
        <Typography.Text style={{ fontWeight: 'normal' }} type={isHistoricized ? 'secondary' : undefined}>
          hat eine Änderung am <CustomFormattedDate value={timeblock.lastUpdateTs} /> um <FormattedTime value={timeblock.lastUpdateTs} /> Uhr gemacht
        </Typography.Text>
      </Space>
    </Space>
  );
}

export default TimelineCardTitle;
