import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AddressFieldsFragmentDoc } from '../../../Address/gql/AddressFragments.types';
import { RechtstraegerFieldsFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import { PostItFragmentDoc } from '../../../Rechtstraeger/PostIt/gql/PostItFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HeVertragOverviewListQueryVariables = Types.Exact<{
  rechnungsAusstellerId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  vertragspartnerId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  order?: Types.InputMaybe<Types.HeVertragOverviewOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type HeVertragOverviewListQuery = {
  getHeVertragOverviewList: {
    data: {
      contentList: Array<{
        anteile: number;
        anteilePercent: number;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        heVertragId: string;
        objektId: string;
        objektKurzBezeichnung: string;
        objektSummeAnteile: number;
        rechnungsausstellerId: string;
        rechnungsausstellerKurzBezeichnung: string;
        updatedByMitarbeiterId?: string | null;
        vertragsBeginn: string;
        vertragsEnde?: string | null;
        vertragspartnerId: string;
        vertragspartnerKurzBezeichnung: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type HeVertragBaseListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  showObjekt?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  showVertragsdauer?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type HeVertragBaseListQuery = {
  getHeVertragBaseList: {
    data: Array<{ heVertragId: string; kurzBezeichnung: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type HeVertragListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
}>;

export type HeVertragListQuery = {
  getHeVertragList: {
    data: {
      heVertragList: Array<{
        aktivIn?: number | null;
        anteile: string;
        anteilePercent: number;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        heVertragId: string;
        inaktivSeit?: number | null;
        vertragsBeginn: string;
        vertragsEnde?: string | null;
        aktivInUnit?: { text: string; value: Types.ChronoUnit } | null;
        deliveryAddress?: {
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        inaktivSeitUnit?: { text: string; value: Types.ChronoUnit } | null;
        vertragspartner: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fibuKontoCreatable: boolean;
          kundennummer: string;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          bankDetailsList: Array<{
            accountHolder: string;
            accountLimit?: number | null;
            assignedHeVertragZahlungsregelMap: any;
            assignedObjektMap: any;
            bankDetailsId: string;
            bankname: string;
            bic: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            ebicsCreditTransferUserList: Array<string>;
            ebicsDirectDebitUserList: Array<string>;
            fibuKontoId?: string | null;
            fibuKontonummer?: string | null;
            iban: string;
            updatedByMitarbeiterId?: string | null;
            fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
            fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
            sepaMandat?: {
              fileId?: string | null;
              fileName?: string | null;
              sepaMandatId: string;
              sepaMandatReference: string;
              signatureDate?: string | null;
              firmendaten?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                firmendatenId: string;
                kundenSystemId: string;
                kurzBezeichnung: string;
                name1: string;
                name2?: string | null;
                name3?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rechnungsAussteller?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
              warningList: Array<{ message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          personenbezugList: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            personenbezugId: string;
            tagList: Array<string>;
            person: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechtstraegerSteuerpflicht?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            historicizedList?: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              rechtstraegerSteuerpflichtId: string;
              validFrom: string;
              steuerpflicht: { text: string; value: Types.Steuerpflicht };
              warningList: Array<{ message: string; type: string }>;
            }> | null;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
          sprache: { text: string; value: Types.Sprache };
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { value: Types.RechtstraegerType; text: string };
          vertragSummary?: {
            heVertragCount: number;
            heVertragVerwaltungCount: number;
            mietVertragCount: number;
            mietVertragVerwaltungCount: number;
            weSubverwaltungCount: number;
            weVertragCount: number;
            weVertragVerwaltungCount: number;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      errorList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type HeVertragQueryVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  heVertragId: Types.Scalars['ID']['input'];
}>;

export type HeVertragQuery = {
  getHeVertrag: {
    data: {
      anteile: number;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable: boolean;
      deliveryAddressHaupt: boolean;
      heVertragId: string;
      kurzBezeichnung: string;
      objektSummeAnteile: number;
      objektVerwaltungSeit: string;
      saldouebertrag: number;
      tagList?: Array<string> | null;
      updatedByMitarbeiterId?: string | null;
      vertragsBeginn: string;
      vertragsEnde?: string | null;
      deliveryAddress?: {
        addressId: string;
        city: string;
        cityAdditionalInformation?: string | null;
        countryCodeIso2: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        houseEntranceApartmentNumber?: string | null;
        postofficeBoxNumber?: string | null;
        postofficeBoxZipCode?: string | null;
        street?: string | null;
        tagList: Array<string>;
        type: Types.AddressType;
        zipCode?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      vertragspartner: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fibuKontoCreatable: boolean;
        kundennummer: string;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        bankDetailsList: Array<{
          accountHolder: string;
          accountLimit?: number | null;
          assignedHeVertragZahlungsregelMap: any;
          assignedObjektMap: any;
          bankDetailsId: string;
          bankname: string;
          bic: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          ebicsCreditTransferUserList: Array<string>;
          ebicsDirectDebitUserList: Array<string>;
          fibuKontoId?: string | null;
          fibuKontonummer?: string | null;
          iban: string;
          updatedByMitarbeiterId?: string | null;
          fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
          fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
          sepaMandat?: {
            fileId?: string | null;
            fileName?: string | null;
            sepaMandatId: string;
            sepaMandatReference: string;
            signatureDate?: string | null;
            firmendaten?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firmendatenId: string;
              kundenSystemId: string;
              kurzBezeichnung: string;
              name1: string;
              name2?: string | null;
              name3?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechnungsAussteller?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
            warningList: Array<{ message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        personenbezugList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          personenbezugId: string;
          tagList: Array<string>;
          person: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechtstraegerSteuerpflicht?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          historicizedList?: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ message: string; type: string }>;
          }> | null;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
        sprache: { text: string; value: Types.Sprache };
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { value: Types.RechtstraegerType; text: string };
        vertragSummary?: {
          heVertragCount: number;
          heVertragVerwaltungCount: number;
          mietVertragCount: number;
          mietVertragVerwaltungCount: number;
          weSubverwaltungCount: number;
          weVertragCount: number;
          weVertragVerwaltungCount: number;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type HeVertragChangeHistoryListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  heVertragId: Types.Scalars['ID']['input'];
}>;

export type HeVertragChangeHistoryListQuery = {
  getHeVertragChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type HeVertragZahlungsregelChangeHistoryListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  heVertragId: Types.Scalars['String']['input'];
  zahlungsregelId: Types.Scalars['ID']['input'];
}>;

export type HeVertragZahlungsregelChangeHistoryListQuery = {
  getHeVertragZahlungsregelChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const HeVertragOverviewListDocument = gql`
  query HeVertragOverviewList(
    $rechnungsAusstellerId: ID
    $vertragspartnerId: ID
    $order: HeVertragOverviewOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getHeVertragOverviewList(
      rechnungsAusstellerId: $rechnungsAusstellerId
      vertragspartnerId: $vertragspartnerId
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          anteile
          anteilePercent
          createTs
          createdBy
          createdByMitarbeiterId
          heVertragId
          objektId
          objektKurzBezeichnung
          objektSummeAnteile
          rechnungsausstellerId
          rechnungsausstellerKurzBezeichnung
          updatedByMitarbeiterId
          vertragsBeginn
          vertragsEnde
          vertragspartnerId
          vertragspartnerKurzBezeichnung
          warningList {
            attribute
            message
            type
          }
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useHeVertragOverviewListQuery(
  baseOptions?: Apollo.QueryHookOptions<HeVertragOverviewListQuery, HeVertragOverviewListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HeVertragOverviewListQuery, HeVertragOverviewListQueryVariables>(HeVertragOverviewListDocument, options);
}
export function useHeVertragOverviewListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HeVertragOverviewListQuery, HeVertragOverviewListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HeVertragOverviewListQuery, HeVertragOverviewListQueryVariables>(HeVertragOverviewListDocument, options);
}
export function useHeVertragOverviewListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HeVertragOverviewListQuery, HeVertragOverviewListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HeVertragOverviewListQuery, HeVertragOverviewListQueryVariables>(HeVertragOverviewListDocument, options);
}
export type HeVertragOverviewListQueryHookResult = ReturnType<typeof useHeVertragOverviewListQuery>;
export type HeVertragOverviewListLazyQueryHookResult = ReturnType<typeof useHeVertragOverviewListLazyQuery>;
export type HeVertragOverviewListSuspenseQueryHookResult = ReturnType<typeof useHeVertragOverviewListSuspenseQuery>;
export type HeVertragOverviewListQueryResult = Apollo.QueryResult<HeVertragOverviewListQuery, HeVertragOverviewListQueryVariables>;
export const HeVertragBaseListDocument = gql`
  query HeVertragBaseList($objektId: ID!, $showObjekt: Boolean, $showVertragsdauer: Boolean) {
    getHeVertragBaseList(objektId: $objektId, showObjekt: $showObjekt, showVertragsdauer: $showVertragsdauer) {
      data {
        heVertragId
        kurzBezeichnung
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useHeVertragBaseListQuery(
  baseOptions: Apollo.QueryHookOptions<HeVertragBaseListQuery, HeVertragBaseListQueryVariables> &
    ({ variables: HeVertragBaseListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HeVertragBaseListQuery, HeVertragBaseListQueryVariables>(HeVertragBaseListDocument, options);
}
export function useHeVertragBaseListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeVertragBaseListQuery, HeVertragBaseListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HeVertragBaseListQuery, HeVertragBaseListQueryVariables>(HeVertragBaseListDocument, options);
}
export function useHeVertragBaseListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HeVertragBaseListQuery, HeVertragBaseListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HeVertragBaseListQuery, HeVertragBaseListQueryVariables>(HeVertragBaseListDocument, options);
}
export type HeVertragBaseListQueryHookResult = ReturnType<typeof useHeVertragBaseListQuery>;
export type HeVertragBaseListLazyQueryHookResult = ReturnType<typeof useHeVertragBaseListLazyQuery>;
export type HeVertragBaseListSuspenseQueryHookResult = ReturnType<typeof useHeVertragBaseListSuspenseQuery>;
export type HeVertragBaseListQueryResult = Apollo.QueryResult<HeVertragBaseListQuery, HeVertragBaseListQueryVariables>;
export const HeVertragListDocument = gql`
  query HeVertragList($objektId: ID!) {
    getHeVertragList(objektId: $objektId) {
      data {
        heVertragList {
          aktivIn
          aktivInUnit {
            text
            value
          }
          anteile
          anteilePercent
          createTs
          createdBy
          createdByMitarbeiterId
          current
          deliveryAddress {
            ...AddressFields
          }
          heVertragId
          inaktivSeit
          inaktivSeitUnit {
            text
            value
          }
          vertragspartner {
            ...RechtstraegerFields
          }
          vertragsBeginn
          vertragsEnde
          warningList {
            attribute
            message
            type
          }
        }
        errorList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AddressFieldsFragmentDoc}
  ${RechtstraegerFieldsFragmentDoc}
`;
export function useHeVertragListQuery(
  baseOptions: Apollo.QueryHookOptions<HeVertragListQuery, HeVertragListQueryVariables> &
    ({ variables: HeVertragListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HeVertragListQuery, HeVertragListQueryVariables>(HeVertragListDocument, options);
}
export function useHeVertragListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeVertragListQuery, HeVertragListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HeVertragListQuery, HeVertragListQueryVariables>(HeVertragListDocument, options);
}
export function useHeVertragListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HeVertragListQuery, HeVertragListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HeVertragListQuery, HeVertragListQueryVariables>(HeVertragListDocument, options);
}
export type HeVertragListQueryHookResult = ReturnType<typeof useHeVertragListQuery>;
export type HeVertragListLazyQueryHookResult = ReturnType<typeof useHeVertragListLazyQuery>;
export type HeVertragListSuspenseQueryHookResult = ReturnType<typeof useHeVertragListSuspenseQuery>;
export type HeVertragListQueryResult = Apollo.QueryResult<HeVertragListQuery, HeVertragListQueryVariables>;
export const HeVertragDocument = gql`
  query HeVertrag($objektId: String!, $heVertragId: ID!) {
    getHeVertrag(objektId: $objektId, heVertragId: $heVertragId) {
      data {
        anteile
        createTs
        createdBy
        createdByMitarbeiterId
        deletable
        deliveryAddress {
          ...AddressFields
        }
        deliveryAddressHaupt
        heVertragId
        kurzBezeichnung
        objektSummeAnteile
        objektVerwaltungSeit
        postIt {
          ...PostIt
        }
        saldouebertrag
        tagList
        updatedByMitarbeiterId
        vertragsBeginn
        vertragsEnde
        vertragspartner {
          ...RechtstraegerFields
        }
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AddressFieldsFragmentDoc}
  ${PostItFragmentDoc}
  ${RechtstraegerFieldsFragmentDoc}
`;
export function useHeVertragQuery(
  baseOptions: Apollo.QueryHookOptions<HeVertragQuery, HeVertragQueryVariables> &
    ({ variables: HeVertragQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HeVertragQuery, HeVertragQueryVariables>(HeVertragDocument, options);
}
export function useHeVertragLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeVertragQuery, HeVertragQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HeVertragQuery, HeVertragQueryVariables>(HeVertragDocument, options);
}
export function useHeVertragSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HeVertragQuery, HeVertragQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HeVertragQuery, HeVertragQueryVariables>(HeVertragDocument, options);
}
export type HeVertragQueryHookResult = ReturnType<typeof useHeVertragQuery>;
export type HeVertragLazyQueryHookResult = ReturnType<typeof useHeVertragLazyQuery>;
export type HeVertragSuspenseQueryHookResult = ReturnType<typeof useHeVertragSuspenseQuery>;
export type HeVertragQueryResult = Apollo.QueryResult<HeVertragQuery, HeVertragQueryVariables>;
export const HeVertragChangeHistoryListDocument = gql`
  query HeVertragChangeHistoryList($objektId: String!, $heVertragId: ID!) {
    getHeVertragChangeHistoryList(objektId: $objektId, heVertragId: $heVertragId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useHeVertragChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<HeVertragChangeHistoryListQuery, HeVertragChangeHistoryListQueryVariables> &
    ({ variables: HeVertragChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HeVertragChangeHistoryListQuery, HeVertragChangeHistoryListQueryVariables>(HeVertragChangeHistoryListDocument, options);
}
export function useHeVertragChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HeVertragChangeHistoryListQuery, HeVertragChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HeVertragChangeHistoryListQuery, HeVertragChangeHistoryListQueryVariables>(HeVertragChangeHistoryListDocument, options);
}
export function useHeVertragChangeHistoryListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HeVertragChangeHistoryListQuery, HeVertragChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HeVertragChangeHistoryListQuery, HeVertragChangeHistoryListQueryVariables>(
    HeVertragChangeHistoryListDocument,
    options
  );
}
export type HeVertragChangeHistoryListQueryHookResult = ReturnType<typeof useHeVertragChangeHistoryListQuery>;
export type HeVertragChangeHistoryListLazyQueryHookResult = ReturnType<typeof useHeVertragChangeHistoryListLazyQuery>;
export type HeVertragChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useHeVertragChangeHistoryListSuspenseQuery>;
export type HeVertragChangeHistoryListQueryResult = Apollo.QueryResult<HeVertragChangeHistoryListQuery, HeVertragChangeHistoryListQueryVariables>;
export const HeVertragZahlungsregelChangeHistoryListDocument = gql`
  query HeVertragZahlungsregelChangeHistoryList($objektId: String!, $heVertragId: String!, $zahlungsregelId: ID!) {
    getHeVertragZahlungsregelChangeHistoryList(objektId: $objektId, heVertragId: $heVertragId, zahlungsregelId: $zahlungsregelId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useHeVertragZahlungsregelChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<HeVertragZahlungsregelChangeHistoryListQuery, HeVertragZahlungsregelChangeHistoryListQueryVariables> &
    ({ variables: HeVertragZahlungsregelChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HeVertragZahlungsregelChangeHistoryListQuery, HeVertragZahlungsregelChangeHistoryListQueryVariables>(
    HeVertragZahlungsregelChangeHistoryListDocument,
    options
  );
}
export function useHeVertragZahlungsregelChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HeVertragZahlungsregelChangeHistoryListQuery, HeVertragZahlungsregelChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HeVertragZahlungsregelChangeHistoryListQuery, HeVertragZahlungsregelChangeHistoryListQueryVariables>(
    HeVertragZahlungsregelChangeHistoryListDocument,
    options
  );
}
export function useHeVertragZahlungsregelChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<HeVertragZahlungsregelChangeHistoryListQuery, HeVertragZahlungsregelChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HeVertragZahlungsregelChangeHistoryListQuery, HeVertragZahlungsregelChangeHistoryListQueryVariables>(
    HeVertragZahlungsregelChangeHistoryListDocument,
    options
  );
}
export type HeVertragZahlungsregelChangeHistoryListQueryHookResult = ReturnType<typeof useHeVertragZahlungsregelChangeHistoryListQuery>;
export type HeVertragZahlungsregelChangeHistoryListLazyQueryHookResult = ReturnType<typeof useHeVertragZahlungsregelChangeHistoryListLazyQuery>;
export type HeVertragZahlungsregelChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useHeVertragZahlungsregelChangeHistoryListSuspenseQuery
>;
export type HeVertragZahlungsregelChangeHistoryListQueryResult = Apollo.QueryResult<
  HeVertragZahlungsregelChangeHistoryListQuery,
  HeVertragZahlungsregelChangeHistoryListQueryVariables
>;
