import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import KSFirmendatenSelect from '../../../KSFirmendatenSelect/KSFirmendatenSelect';
import { showSuccessMsgUpdate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import FormButtons from '../../../../components/Button/FormButtons';
import { abrDefUpdateFormValidationSchema } from './abrDefUpdateFormValidationSchema';
import {
  abrechnungsdefinitionUpdateFormFields,
  AbrechnungsdefinitionUpdateFormValues,
  mapAbrechnunsdefinitionToFormValues,
  mapFormValuesToAbrechnungsdefinitonUpdate,
} from './abrDefUpdateFormMapper';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import {
  isAbrDefTypeHeAbrechnung,
  isAbrDefTypeSubAbrechnung,
  isAbrDefTypeWeAbrechnung,
} from '../../shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import { useUpdateWeAbrechnungsdefinitionMutation } from '../../gql/WeAbrDef/WeAbrDefMutations.types';
import { useUpdateHeAbrechnungsdefinitionMutation } from '../../gql/HeAbrDef/HeAbrDefMutations.types';
import { useUpdateBkAbrechnungsdefinitionMutation } from '../../gql/BkAbrDef/BkAbrDefMutations.types';
import { AbrechnungsdefinitionListEntry } from '../../../../types';
import { useUpdateSubAdministrationAbrechnungsdefinitionMutation } from '../../gql/SubAdministrationAbrDef/SubAdministrationAbrDefMutations.types';

type Props = {
  abrechnungsdefinition: AbrechnungsdefinitionListEntry;
  onSuccess: () => void;
  onCancel: () => void;
};

const AbrDefUpdateForm: FC<Props> = ({ abrechnungsdefinition, onSuccess, onCancel }) => {
  const entity = 'Abrechnungsdefinition';
  const baseOptions = {
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  };

  const { abrechnungsdefinitionId } = abrechnungsdefinition;

  const [updateWeAbrechnungsdefinition, { loading: loadingWeAbrechnungsdefinition }] = useUpdateWeAbrechnungsdefinitionMutation(baseOptions);
  const [updateHeAbrechnungsdefinition, { loading: loadingHeAbrechnungsdefinition }] = useUpdateHeAbrechnungsdefinitionMutation(baseOptions);
  const [updateBkAbrechnungsdefinition, { loading: loadingBkAbrechnungsdefinition }] = useUpdateBkAbrechnungsdefinitionMutation(baseOptions);
  const [updateSubAbrechnungsdefinition, { loading: loadingSubAbrechnungsdefinition }] =
    useUpdateSubAdministrationAbrechnungsdefinitionMutation(baseOptions);

  const onSubmit = (formValues: AbrechnungsdefinitionUpdateFormValues) => {
    const input = mapFormValuesToAbrechnungsdefinitonUpdate(formValues);
    if (isAbrDefTypeWeAbrechnung(abrechnungsdefinition.type.value)) {
      return updateWeAbrechnungsdefinition({ variables: { abrechnungsdefinitionId, input } });
    } else if (isAbrDefTypeHeAbrechnung(abrechnungsdefinition.type.value)) {
      return updateHeAbrechnungsdefinition({ variables: { abrechnungsdefinitionId, input } });
    } else if (isAbrDefTypeSubAbrechnung(abrechnungsdefinition.type.value)) {
      return updateSubAbrechnungsdefinition({ variables: { abrechnungsdefinitionId, input } });
    } else {
      return updateBkAbrechnungsdefinition({ variables: { abrechnungsdefinitionId, input } });
    }
  };

  return (
    <Formik<AbrechnungsdefinitionUpdateFormValues>
      initialValues={mapAbrechnunsdefinitionToFormValues(abrechnungsdefinition)}
      validationSchema={abrDefUpdateFormValidationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithoutColon
            name={abrechnungsdefinitionUpdateFormFields.firmendatenId}
            label="Firma auswählen"
            help="Abrechnungsdefinition wird nur für diese Firma angewendet"
          >
            <KSFirmendatenSelect
              name={abrechnungsdefinitionUpdateFormFields.firmendatenId}
              id={abrechnungsdefinitionUpdateFormFields.firmendatenId}
              disabled
              placeholder="Keine Firma"
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={abrechnungsdefinitionUpdateFormFields.bezeichnung} label="Bezeichnung">
            <Input id={abrechnungsdefinitionUpdateFormFields.bezeichnung} name={abrechnungsdefinitionUpdateFormFields.bezeichnung} />
          </FormItemWithoutColon>

          <FormButtons
            updateMode
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={
              loadingWeAbrechnungsdefinition || loadingBkAbrechnungsdefinition || loadingHeAbrechnungsdefinition || loadingSubAbrechnungsdefinition
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export default AbrDefUpdateForm;
