import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import {
  HeAbrechnungListEntryFieldsFragmentDoc,
  HeAbrechnungHeVertragListEntryFieldsFragmentDoc,
  HeAbrAbrechnungskreisListFieldFragmentDoc,
} from './HeAbrechnungFragments.types';
import { RechtstraegerFieldsFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
import { ObjektFragmentDoc } from '../../../Objekt/gql/ObjektFragments.types';
import { BestandseinheitBaseFieldsFragmentDoc, BestandseinheitFieldsFragmentDoc } from '../../../Bestandseinheit/gql/BestandseinheitFragments.types';
import { AppKontoFieldsFragmentDoc } from '../../../Kontenplan/gql/KontenplanFragments.types';
import { AddressFieldsFragmentDoc } from '../../../Address/gql/AddressFragments.types';
import { PostItFragmentDoc } from '../../../Rechtstraeger/PostIt/gql/PostItFragments.types';
import { AbrInfoAbrechnungskreisFieldsFragmentDoc } from '../../gql/AbrechnungFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HeAbrechnungListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
}>;

export type HeAbrechnungListQuery = {
  getHeAbrechnungList: {
    data: Array<{
      abrechnungszeitraumBis: string;
      abrechnungszeitraumVon: string;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      firmendatenId: string;
      generierlaufId: string;
      heAbrechnungId: string;
      updatedByMitarbeiterId?: string | null;
      abrechnungsdefinitionType: { text: string; value: Types.AbrechnungsdefinitionType };
      status: { text: string; value: Types.AbrechnungStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type HeAbrechnungQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  heAbrechnungId: Types.Scalars['ID']['input'];
}>;

export type HeAbrechnungQuery = {
  getHeAbrechnung: {
    data: {
      abrechnungszeitraumBis: string;
      abrechnungszeitraumVon: string;
      bezeichnung: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      createTs: string;
      deletable: boolean;
      heAbrechnungId: string;
      rechnungsausstellerId: string;
      updatedByMitarbeiterId?: string | null;
      errorList: Array<{ message: string; type: string }>;
      heAbrechnungHeVertragList: Array<{
        heAbrechnungHeVertragId: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        createTs: string;
        anteile: string;
        anteilePercent: number;
        abrechnungszeitraumBis: string;
        abrechnungszeitraumVon: string;
        guthaben: boolean;
        fileId?: string | null;
        saldo: number;
        updatedByMitarbeiterId?: string | null;
        abrechnungsdefinitionType: { text: string; value: Types.AbrechnungsdefinitionType };
        hauseigentuemer: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fibuKontoCreatable: boolean;
          kundennummer: string;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          bankDetailsList: Array<{
            accountHolder: string;
            accountLimit?: number | null;
            assignedHeVertragZahlungsregelMap: any;
            assignedObjektMap: any;
            bankDetailsId: string;
            bankname: string;
            bic: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            ebicsCreditTransferUserList: Array<string>;
            ebicsDirectDebitUserList: Array<string>;
            fibuKontoId?: string | null;
            fibuKontonummer?: string | null;
            iban: string;
            updatedByMitarbeiterId?: string | null;
            fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
            fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
            sepaMandat?: {
              fileId?: string | null;
              fileName?: string | null;
              sepaMandatId: string;
              sepaMandatReference: string;
              signatureDate?: string | null;
              firmendaten?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                firmendatenId: string;
                kundenSystemId: string;
                kurzBezeichnung: string;
                name1: string;
                name2?: string | null;
                name3?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rechnungsAussteller?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
              warningList: Array<{ message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          personenbezugList: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            personenbezugId: string;
            tagList: Array<string>;
            person: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechtstraegerSteuerpflicht?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            historicizedList?: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              rechtstraegerSteuerpflichtId: string;
              validFrom: string;
              steuerpflicht: { text: string; value: Types.Steuerpflicht };
              warningList: Array<{ message: string; type: string }>;
            }> | null;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
          sprache: { text: string; value: Types.Sprache };
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { value: Types.RechtstraegerType; text: string };
          vertragSummary?: {
            heVertragCount: number;
            heVertragVerwaltungCount: number;
            mietVertragCount: number;
            mietVertragVerwaltungCount: number;
            weSubverwaltungCount: number;
            weVertragCount: number;
            weVertragVerwaltungCount: number;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        status: { description?: string | null; text: string; value: Types.HeAbrechnungHeVertragStatus };
        warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      }>;
      objekt: {
        countryCodeIso2: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fotoFileId?: string | null;
        fotoType?: Types.FotoType | null;
        kurzBezeichnung: string;
        objektId: string;
        rechnungsAusstellerBezeichnung?: string | null;
        rechnungsAusstellerChangeable?: boolean | null;
        subAdministration: boolean;
        sumNutzflaeche: number;
        sumNutzwert: number;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        verwaltungBis?: string | null;
        verwaltungSeit: string;
        verwaltungsartChangeable?: boolean | null;
        addressList: Array<{
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        objektSummeAnteile?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          kommentar?: string | null;
          lastUpdateTs: string;
          objektSummeAnteileId: string;
          summeAnteile: number;
          validFrom: string;
          historicizedList?: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            kommentar?: string | null;
            lastUpdateTs: string;
            objektSummeAnteileId: string;
            summeAnteile: number;
            validFrom: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }> | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechnungsAussteller: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fibuKontoCreatable: boolean;
          kundennummer: string;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          bankDetailsList: Array<{
            accountHolder: string;
            accountLimit?: number | null;
            assignedHeVertragZahlungsregelMap: any;
            assignedObjektMap: any;
            bankDetailsId: string;
            bankname: string;
            bic: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            ebicsCreditTransferUserList: Array<string>;
            ebicsDirectDebitUserList: Array<string>;
            fibuKontoId?: string | null;
            fibuKontonummer?: string | null;
            iban: string;
            updatedByMitarbeiterId?: string | null;
            fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
            fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
            sepaMandat?: {
              fileId?: string | null;
              fileName?: string | null;
              sepaMandatId: string;
              sepaMandatReference: string;
              signatureDate?: string | null;
              firmendaten?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                firmendatenId: string;
                kundenSystemId: string;
                kurzBezeichnung: string;
                name1: string;
                name2?: string | null;
                name3?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rechnungsAussteller?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
              warningList: Array<{ message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          personenbezugList: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            personenbezugId: string;
            tagList: Array<string>;
            person: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechtstraegerSteuerpflicht?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            historicizedList?: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              rechtstraegerSteuerpflichtId: string;
              validFrom: string;
              steuerpflicht: { text: string; value: Types.Steuerpflicht };
              warningList: Array<{ message: string; type: string }>;
            }> | null;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
          sprache: { text: string; value: Types.Sprache };
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { value: Types.RechtstraegerType; text: string };
          vertragSummary?: {
            heVertragCount: number;
            heVertragVerwaltungCount: number;
            mietVertragCount: number;
            mietVertragVerwaltungCount: number;
            weSubverwaltungCount: number;
            weVertragCount: number;
            weVertragVerwaltungCount: number;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        sachbearbeiter?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          fileStorageInitialized: boolean;
          firmendatenId: string;
          firstname: string;
          funktionsModulList: Array<string>;
          lastname: string;
          mitarbeiterId: string;
          title?: string | null;
          titleTrailing?: string | null;
          username: string;
          contactPerson?: {
            contactPersonId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firstname: string;
            lastname: string;
            tagList: Array<string>;
            title?: string | null;
            titleTrailing?: string | null;
            contactList: Array<{
              contactId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              haupt?: boolean | null;
              tagList: Array<string>;
              type: string;
              wert: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            rechtstraeger?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rolleList: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            name: string;
            rolleId: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
          status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
          user?: {
            email?: string | null;
            emailUnverified?: string | null;
            firstname?: string | null;
            lastname?: string | null;
            role: string;
            username: string;
            status: { text: string; value: string };
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.ObjektStatus; description?: string | null };
        verwaltungsart: { text: string; value: Types.Verwaltungsart };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      status: { description?: string | null; text: string; value: Types.AbrechnungStatus };
      warningList: Array<{ message: string; type: string; attribute?: string | null }>;
      zahlungsrueckstandList: Array<{
        offenerBetrag: number;
        letzteBuchungAm?: string | null;
        bestandseinheit: {
          bestandseinheitId: string;
          bezeichnung: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          objektId: string;
          status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        rechtstraeger: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type HeAbrechnungHeVertragListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  heAbrechnungId: Types.Scalars['ID']['input'];
}>;

export type HeAbrechnungHeVertragListQuery = {
  getHeAbrechnungHeVertragList: {
    data: Array<{
      abrechnungszeitraumBis: string;
      abrechnungszeitraumVon: string;
      anteile: string;
      anteilePercent: number;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      fileId?: string | null;
      guthaben: boolean;
      heAbrechnungHeVertragId: string;
      saldo: number;
      updatedByMitarbeiterId?: string | null;
      abrechnungsdefinitionType: { text: string; value: Types.AbrechnungsdefinitionType };
      hauseigentuemer: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fibuKontoCreatable: boolean;
        kundennummer: string;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        bankDetailsList: Array<{
          accountHolder: string;
          accountLimit?: number | null;
          assignedHeVertragZahlungsregelMap: any;
          assignedObjektMap: any;
          bankDetailsId: string;
          bankname: string;
          bic: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          ebicsCreditTransferUserList: Array<string>;
          ebicsDirectDebitUserList: Array<string>;
          fibuKontoId?: string | null;
          fibuKontonummer?: string | null;
          iban: string;
          updatedByMitarbeiterId?: string | null;
          fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
          fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
          sepaMandat?: {
            fileId?: string | null;
            fileName?: string | null;
            sepaMandatId: string;
            sepaMandatReference: string;
            signatureDate?: string | null;
            firmendaten?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firmendatenId: string;
              kundenSystemId: string;
              kurzBezeichnung: string;
              name1: string;
              name2?: string | null;
              name3?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechnungsAussteller?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
            warningList: Array<{ message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        personenbezugList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          personenbezugId: string;
          tagList: Array<string>;
          person: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechtstraegerSteuerpflicht?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          historicizedList?: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ message: string; type: string }>;
          }> | null;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
        sprache: { text: string; value: Types.Sprache };
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { value: Types.RechtstraegerType; text: string };
        vertragSummary?: {
          heVertragCount: number;
          heVertragVerwaltungCount: number;
          mietVertragCount: number;
          mietVertragVerwaltungCount: number;
          weSubverwaltungCount: number;
          weVertragCount: number;
          weVertragVerwaltungCount: number;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      status: { text: string; value: Types.HeAbrechnungHeVertragStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type HeAbrechnungHeVertragQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  heAbrechnungId: Types.Scalars['ID']['input'];
  heAbrechnungHeVertragId: Types.Scalars['ID']['input'];
}>;

export type HeAbrechnungHeVertragQuery = {
  getHeAbrechnungHeVertrag: {
    data: {
      abrechnungszeitraumBis: string;
      abrechnungszeitraumVon: string;
      anteile: string;
      anteilePercent: number;
      bezeichnung: string;
      buchungskreisId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      fileId?: string | null;
      guthaben: boolean;
      heAbrechnungHeVertragId: string;
      saldo: number;
      saldovortrag: number;
      updatedByMitarbeiterId?: string | null;
      abrechnungsdefinitionType: { text: string; value: Types.AbrechnungsdefinitionType };
      ausgaben?: {
        anteil: number;
        anteile?: string | null;
        anteilePercent?: number | null;
        summe: number;
        abrechnungskreisType: { value: Types.AbrechnungskreisType; text: string };
        abrechnungskreisList: Array<{
          abrechnungskreisId: string;
          bezeichnung: string;
          heAbrechnungsdefinitionId: string;
          heAbrechnungsdefinitionVersionId: string;
          summe: number;
          abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
          kontoList: Array<{
            bezeichnung: string;
            kontoId: string;
            summe: number;
            amountReducingType?: { text: string; value: Types.AmountReducingType } | null;
            buchungszeileList: Array<{
              auftragId?: string | null;
              belegDatum?: string | null;
              belegFileId?: string | null;
              belegId: string;
              belegnummer?: string | null;
              betrag: number;
              buchungIdList: Array<string>;
              dueDate?: string | null;
              standingInvoicePartialAmountId?: string | null;
              text: string;
              umbuchung?: boolean | null;
              umgebucht?: boolean | null;
              ustVomAufwandSteuersatz?: number | null;
              buchungskreisRechtstraeger: {
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                createTs: string;
                rechtstraegerId: string;
                kurzBezeichnung: string;
                warningList: Array<{ message: string; type: string }>;
                type: { text: string; value: Types.RechtstraegerType };
                status: { text: string; value: Types.RechtstraegerStatus; description?: string | null };
              };
              sollHaben: { text: string; value: Types.SollHaben };
              vertragspartner?: {
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                createTs: string;
                rechtstraegerId: string;
                kurzBezeichnung: string;
                warningList: Array<{ message: string; type: string }>;
                type: { text: string; value: Types.RechtstraegerType };
                status: { text: string; value: Types.RechtstraegerStatus; description?: string | null };
              } | null;
            }>;
          }>;
        }>;
      } | null;
      errorList: Array<{ message: string; type: string }>;
      eigentuemerverrechnung?: {
        anteil: number;
        anteile?: string | null;
        anteilePercent?: number | null;
        summe: number;
        abrechnungskreisType: { value: Types.AbrechnungskreisType; text: string };
        abrechnungskreisList: Array<{
          abrechnungskreisId: string;
          bezeichnung: string;
          heAbrechnungsdefinitionId: string;
          heAbrechnungsdefinitionVersionId: string;
          summe: number;
          abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
          kontoList: Array<{
            bezeichnung: string;
            kontoId: string;
            summe: number;
            amountReducingType?: { text: string; value: Types.AmountReducingType } | null;
            buchungszeileList: Array<{
              auftragId?: string | null;
              belegDatum?: string | null;
              belegFileId?: string | null;
              belegId: string;
              belegnummer?: string | null;
              betrag: number;
              buchungIdList: Array<string>;
              dueDate?: string | null;
              standingInvoicePartialAmountId?: string | null;
              text: string;
              umbuchung?: boolean | null;
              umgebucht?: boolean | null;
              ustVomAufwandSteuersatz?: number | null;
              buchungskreisRechtstraeger: {
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                createTs: string;
                rechtstraegerId: string;
                kurzBezeichnung: string;
                warningList: Array<{ message: string; type: string }>;
                type: { text: string; value: Types.RechtstraegerType };
                status: { text: string; value: Types.RechtstraegerStatus; description?: string | null };
              };
              sollHaben: { text: string; value: Types.SollHaben };
              vertragspartner?: {
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                createTs: string;
                rechtstraegerId: string;
                kurzBezeichnung: string;
                warningList: Array<{ message: string; type: string }>;
                type: { text: string; value: Types.RechtstraegerType };
                status: { text: string; value: Types.RechtstraegerStatus; description?: string | null };
              } | null;
            }>;
          }>;
        }>;
      } | null;
      einnahmen?: {
        anteil: number;
        anteile?: string | null;
        anteilePercent?: number | null;
        summe: number;
        abrechnungskreisType: { value: Types.AbrechnungskreisType; text: string };
        abrechnungskreisList: Array<{
          abrechnungskreisId: string;
          bezeichnung: string;
          heAbrechnungsdefinitionId: string;
          heAbrechnungsdefinitionVersionId: string;
          summe: number;
          abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
          kontoList: Array<{
            bezeichnung: string;
            kontoId: string;
            summe: number;
            amountReducingType?: { text: string; value: Types.AmountReducingType } | null;
            buchungszeileList: Array<{
              auftragId?: string | null;
              belegDatum?: string | null;
              belegFileId?: string | null;
              belegId: string;
              belegnummer?: string | null;
              betrag: number;
              buchungIdList: Array<string>;
              dueDate?: string | null;
              standingInvoicePartialAmountId?: string | null;
              text: string;
              umbuchung?: boolean | null;
              umgebucht?: boolean | null;
              ustVomAufwandSteuersatz?: number | null;
              buchungskreisRechtstraeger: {
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                createTs: string;
                rechtstraegerId: string;
                kurzBezeichnung: string;
                warningList: Array<{ message: string; type: string }>;
                type: { text: string; value: Types.RechtstraegerType };
                status: { text: string; value: Types.RechtstraegerStatus; description?: string | null };
              };
              sollHaben: { text: string; value: Types.SollHaben };
              vertragspartner?: {
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                createTs: string;
                rechtstraegerId: string;
                kurzBezeichnung: string;
                warningList: Array<{ message: string; type: string }>;
                type: { text: string; value: Types.RechtstraegerType };
                status: { text: string; value: Types.RechtstraegerStatus; description?: string | null };
              } | null;
            }>;
          }>;
        }>;
      } | null;
      heVertrag: {
        anteile: number;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable: boolean;
        deliveryAddressHaupt: boolean;
        heVertragId: string;
        kurzBezeichnung: string;
        objektSummeAnteile: number;
        objektVerwaltungSeit: string;
        saldouebertrag: number;
        tagList?: Array<string> | null;
        updatedByMitarbeiterId?: string | null;
        vertragsBeginn: string;
        vertragsEnde?: string | null;
        deliveryAddress?: {
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vertragspartner: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fibuKontoCreatable: boolean;
          kundennummer: string;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          bankDetailsList: Array<{
            accountHolder: string;
            accountLimit?: number | null;
            assignedHeVertragZahlungsregelMap: any;
            assignedObjektMap: any;
            bankDetailsId: string;
            bankname: string;
            bic: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            ebicsCreditTransferUserList: Array<string>;
            ebicsDirectDebitUserList: Array<string>;
            fibuKontoId?: string | null;
            fibuKontonummer?: string | null;
            iban: string;
            updatedByMitarbeiterId?: string | null;
            fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
            fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
            sepaMandat?: {
              fileId?: string | null;
              fileName?: string | null;
              sepaMandatId: string;
              sepaMandatReference: string;
              signatureDate?: string | null;
              firmendaten?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                firmendatenId: string;
                kundenSystemId: string;
                kurzBezeichnung: string;
                name1: string;
                name2?: string | null;
                name3?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rechnungsAussteller?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
              warningList: Array<{ message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          personenbezugList: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            personenbezugId: string;
            tagList: Array<string>;
            person: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechtstraegerSteuerpflicht?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            historicizedList?: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              rechtstraegerSteuerpflichtId: string;
              validFrom: string;
              steuerpflicht: { text: string; value: Types.Steuerpflicht };
              warningList: Array<{ message: string; type: string }>;
            }> | null;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
          sprache: { text: string; value: Types.Sprache };
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { value: Types.RechtstraegerType; text: string };
          vertragSummary?: {
            heVertragCount: number;
            heVertragVerwaltungCount: number;
            mietVertragCount: number;
            mietVertragVerwaltungCount: number;
            weSubverwaltungCount: number;
            weVertragCount: number;
            weVertragVerwaltungCount: number;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      hauseigentuemer: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fibuKontoCreatable: boolean;
        kundennummer: string;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        bankDetailsList: Array<{
          accountHolder: string;
          accountLimit?: number | null;
          assignedHeVertragZahlungsregelMap: any;
          assignedObjektMap: any;
          bankDetailsId: string;
          bankname: string;
          bic: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          ebicsCreditTransferUserList: Array<string>;
          ebicsDirectDebitUserList: Array<string>;
          fibuKontoId?: string | null;
          fibuKontonummer?: string | null;
          iban: string;
          updatedByMitarbeiterId?: string | null;
          fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
          fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
          sepaMandat?: {
            fileId?: string | null;
            fileName?: string | null;
            sepaMandatId: string;
            sepaMandatReference: string;
            signatureDate?: string | null;
            firmendaten?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firmendatenId: string;
              kundenSystemId: string;
              kurzBezeichnung: string;
              name1: string;
              name2?: string | null;
              name3?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechnungsAussteller?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
            warningList: Array<{ message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        personenbezugList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          personenbezugId: string;
          tagList: Array<string>;
          person: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechtstraegerSteuerpflicht?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          historicizedList?: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ message: string; type: string }>;
          }> | null;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
        sprache: { text: string; value: Types.Sprache };
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { value: Types.RechtstraegerType; text: string };
        vertragSummary?: {
          heVertragCount: number;
          heVertragVerwaltungCount: number;
          mietVertragCount: number;
          mietVertragVerwaltungCount: number;
          weSubverwaltungCount: number;
          weVertragCount: number;
          weVertragVerwaltungCount: number;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      infoAbrechnungskreisList: Array<{
        abrechnungsdefinitionId: string;
        abrechnungsdefinitionVersionId: string;
        abrechnungskreisId: string;
        bezeichnung: string;
        saldo: number;
        sumAufwand: number;
        vorsaldo: number;
        aufwand: {
          summe: number;
          kontoList: Array<{
            bezeichnung: string;
            kontoId: string;
            summe: number;
            buchungszeileList: Array<{
              auftragId?: string | null;
              belegDatum?: string | null;
              belegFileId?: string | null;
              belegId: string;
              belegnummer?: string | null;
              betrag: number;
              buchungIdList: Array<string>;
              dueDate?: string | null;
              standingInvoicePartialAmountId?: string | null;
              text: string;
              umbuchung?: boolean | null;
              umgebucht?: boolean | null;
              buchungskreisRechtstraeger: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              sollHaben: { text: string; value: Types.SollHaben };
              vertragspartner?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
            }>;
          }>;
        };
        erloes: {
          summe: number;
          kontoList: Array<{
            bezeichnung: string;
            kontoId: string;
            summe: number;
            buchungszeileList: Array<{
              auftragId?: string | null;
              belegDatum?: string | null;
              belegFileId?: string | null;
              belegId: string;
              belegnummer?: string | null;
              betrag: number;
              buchungIdList: Array<string>;
              dueDate?: string | null;
              standingInvoicePartialAmountId?: string | null;
              text: string;
              umbuchung?: boolean | null;
              umgebucht?: boolean | null;
              buchungskreisRechtstraeger: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              sollHaben: { text: string; value: Types.SollHaben };
              vertragspartner?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
            }>;
          }>;
        };
      }>;
      objekt: {
        countryCodeIso2: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fotoFileId?: string | null;
        fotoType?: Types.FotoType | null;
        kurzBezeichnung: string;
        objektId: string;
        rechnungsAusstellerBezeichnung?: string | null;
        rechnungsAusstellerChangeable?: boolean | null;
        subAdministration: boolean;
        sumNutzflaeche: number;
        sumNutzwert: number;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        verwaltungBis?: string | null;
        verwaltungSeit: string;
        verwaltungsartChangeable?: boolean | null;
        addressList: Array<{
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        objektSummeAnteile?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          kommentar?: string | null;
          lastUpdateTs: string;
          objektSummeAnteileId: string;
          summeAnteile: number;
          validFrom: string;
          historicizedList?: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            kommentar?: string | null;
            lastUpdateTs: string;
            objektSummeAnteileId: string;
            summeAnteile: number;
            validFrom: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }> | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechnungsAussteller: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fibuKontoCreatable: boolean;
          kundennummer: string;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          tagList: Array<string>;
          updatedByMitarbeiterId?: string | null;
          bankDetailsList: Array<{
            accountHolder: string;
            accountLimit?: number | null;
            assignedHeVertragZahlungsregelMap: any;
            assignedObjektMap: any;
            bankDetailsId: string;
            bankname: string;
            bic: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            ebicsCreditTransferUserList: Array<string>;
            ebicsDirectDebitUserList: Array<string>;
            fibuKontoId?: string | null;
            fibuKontonummer?: string | null;
            iban: string;
            updatedByMitarbeiterId?: string | null;
            fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
            fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
            sepaMandat?: {
              fileId?: string | null;
              fileName?: string | null;
              sepaMandatId: string;
              sepaMandatReference: string;
              signatureDate?: string | null;
              firmendaten?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                firmendatenId: string;
                kundenSystemId: string;
                kurzBezeichnung: string;
                name1: string;
                name2?: string | null;
                name3?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              rechnungsAussteller?: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
              warningList: Array<{ message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          personenbezugList: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            personenbezugId: string;
            tagList: Array<string>;
            person: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechtstraegerSteuerpflicht?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            historicizedList?: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              rechtstraegerSteuerpflichtId: string;
              validFrom: string;
              steuerpflicht: { text: string; value: Types.Steuerpflicht };
              warningList: Array<{ message: string; type: string }>;
            }> | null;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
          sprache: { text: string; value: Types.Sprache };
          status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
          type: { value: Types.RechtstraegerType; text: string };
          vertragSummary?: {
            heVertragCount: number;
            heVertragVerwaltungCount: number;
            mietVertragCount: number;
            mietVertragVerwaltungCount: number;
            weSubverwaltungCount: number;
            weVertragCount: number;
            weVertragVerwaltungCount: number;
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        sachbearbeiter?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          fileStorageInitialized: boolean;
          firmendatenId: string;
          firstname: string;
          funktionsModulList: Array<string>;
          lastname: string;
          mitarbeiterId: string;
          title?: string | null;
          titleTrailing?: string | null;
          username: string;
          contactPerson?: {
            contactPersonId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firstname: string;
            lastname: string;
            tagList: Array<string>;
            title?: string | null;
            titleTrailing?: string | null;
            contactList: Array<{
              contactId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              haupt?: boolean | null;
              tagList: Array<string>;
              type: string;
              wert: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            rechtstraeger?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rolleList: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            name: string;
            rolleId: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
          status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
          user?: {
            email?: string | null;
            emailUnverified?: string | null;
            firstname?: string | null;
            lastname?: string | null;
            role: string;
            username: string;
            status: { text: string; value: string };
          } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.ObjektStatus; description?: string | null };
        verwaltungsart: { text: string; value: Types.Verwaltungsart };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      status: { description?: string | null; text: string; value: Types.HeAbrechnungHeVertragStatus };
      vorsteuerkuerzung?: {
        anteil: number;
        anteile?: string | null;
        anteilePercent?: number | null;
        summe: number;
        abrechnungskreisType: { value: Types.AbrechnungskreisType; text: string };
        abrechnungskreisList: Array<{
          abrechnungskreisId: string;
          bezeichnung: string;
          heAbrechnungsdefinitionId: string;
          heAbrechnungsdefinitionVersionId: string;
          summe: number;
          abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
          kontoList: Array<{
            bezeichnung: string;
            kontoId: string;
            summe: number;
            amountReducingType?: { text: string; value: Types.AmountReducingType } | null;
            buchungszeileList: Array<{
              auftragId?: string | null;
              belegDatum?: string | null;
              belegFileId?: string | null;
              belegId: string;
              belegnummer?: string | null;
              betrag: number;
              buchungIdList: Array<string>;
              dueDate?: string | null;
              standingInvoicePartialAmountId?: string | null;
              text: string;
              umbuchung?: boolean | null;
              umgebucht?: boolean | null;
              ustVomAufwandSteuersatz?: number | null;
              buchungskreisRechtstraeger: {
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                createTs: string;
                rechtstraegerId: string;
                kurzBezeichnung: string;
                warningList: Array<{ message: string; type: string }>;
                type: { text: string; value: Types.RechtstraegerType };
                status: { text: string; value: Types.RechtstraegerStatus; description?: string | null };
              };
              sollHaben: { text: string; value: Types.SollHaben };
              vertragspartner?: {
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                createTs: string;
                rechtstraegerId: string;
                kurzBezeichnung: string;
                warningList: Array<{ message: string; type: string }>;
                type: { text: string; value: Types.RechtstraegerType };
                status: { text: string; value: Types.RechtstraegerStatus; description?: string | null };
              } | null;
            }>;
          }>;
        }>;
      } | null;
      offenePosten?: {
        anteil?: number | null;
        summe: number;
        offenePostenList: Array<{
          dueDate: string;
          fibuKontoId: string;
          fibuKontonummer: string;
          letzteBuchungAm?: string | null;
          offenePostenId: string;
          offenerBetrag: number;
          bestandseinheitList: Array<{
            aktivSeit?: string | null;
            bauteil?: string | null;
            bestandseinheitId: string;
            bezeichnung: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            geschoss?: string | null;
            inaktivSeit?: string | null;
            nutzflaeche?: number | null;
            nutzwert?: number | null;
            stiege?: string | null;
            tagList: Array<string>;
            address: {
              addressId: string;
              city: string;
              cityAdditionalInformation?: string | null;
              countryCodeIso2: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              haupt?: boolean | null;
              houseEntranceApartmentNumber?: string | null;
              postofficeBoxNumber?: string | null;
              postofficeBoxZipCode?: string | null;
              street?: string | null;
              tagList: Array<string>;
              type: Types.AddressType;
              zipCode?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            nutzungsArt?: { text: string; value: Types.NutzungsArt } | null;
            optionSteuerpflicht?: {
              beOptionSteuerpflichtId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              optionSteuerpflicht: boolean;
              validFrom: string;
              historicizedList?: Array<{
                beOptionSteuerpflichtId: string;
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                deletable?: boolean | null;
                kommentar?: string | null;
                lastUpdateTs: string;
                optionSteuerpflicht: boolean;
                validFrom: string;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }> | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            objektVerwaltungsart: { text: string; value: Types.Verwaltungsart };
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          vertragspartner: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            fibuKontoCreatable: boolean;
            kundennummer: string;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            tagList: Array<string>;
            updatedByMitarbeiterId?: string | null;
            bankDetailsList: Array<{
              accountHolder: string;
              accountLimit?: number | null;
              assignedHeVertragZahlungsregelMap: any;
              assignedObjektMap: any;
              bankDetailsId: string;
              bankname: string;
              bic: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              deletable?: boolean | null;
              ebicsCreditTransferUserList: Array<string>;
              ebicsDirectDebitUserList: Array<string>;
              fibuKontoId?: string | null;
              fibuKontonummer?: string | null;
              iban: string;
              updatedByMitarbeiterId?: string | null;
              fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
              fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
              sepaMandat?: {
                fileId?: string | null;
                fileName?: string | null;
                sepaMandatId: string;
                sepaMandatReference: string;
                signatureDate?: string | null;
                firmendaten?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  firmendatenId: string;
                  kundenSystemId: string;
                  kurzBezeichnung: string;
                  name1: string;
                  name2?: string | null;
                  name3?: string | null;
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                rechnungsAussteller?: {
                  createTs: string;
                  createdBy: string;
                  createdByMitarbeiterId?: string | null;
                  kurzBezeichnung: string;
                  rechtstraegerId: string;
                  status: { text: string; value: Types.RechtstraegerStatus };
                  type: { text: string; value: Types.RechtstraegerType };
                  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                } | null;
                status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
                warningList: Array<{ message: string; type: string }>;
              } | null;
              status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            personenbezugList: Array<{
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              personenbezugId: string;
              tagList: Array<string>;
              person: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            postIt?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              immerAnzeigen: boolean;
              postItId: string;
              text: string;
              titel: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechtstraegerSteuerpflicht?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              current: boolean;
              deletable?: boolean | null;
              kommentar?: string | null;
              lastUpdateTs: string;
              rechtstraegerSteuerpflichtId: string;
              validFrom: string;
              historicizedList?: Array<{
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                current: boolean;
                deletable?: boolean | null;
                kommentar?: string | null;
                lastUpdateTs: string;
                rechtstraegerSteuerpflichtId: string;
                validFrom: string;
                steuerpflicht: { text: string; value: Types.Steuerpflicht };
                warningList: Array<{ message: string; type: string }>;
              }> | null;
              steuerpflicht: { text: string; value: Types.Steuerpflicht };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
            sprache: { text: string; value: Types.Sprache };
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { value: Types.RechtstraegerType; text: string };
            vertragSummary?: {
              heVertragCount: number;
              heVertragVerwaltungCount: number;
              mietVertragCount: number;
              mietVertragVerwaltungCount: number;
              weSubverwaltungCount: number;
              weVertragCount: number;
              weVertragVerwaltungCount: number;
            } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
        }>;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const HeAbrechnungListDocument = gql`
  query HeAbrechnungList($objektId: ID!) {
    getHeAbrechnungList(objektId: $objektId) {
      data {
        ...HeAbrechnungListEntryFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${HeAbrechnungListEntryFieldsFragmentDoc}
`;
export function useHeAbrechnungListQuery(
  baseOptions: Apollo.QueryHookOptions<HeAbrechnungListQuery, HeAbrechnungListQueryVariables> &
    ({ variables: HeAbrechnungListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HeAbrechnungListQuery, HeAbrechnungListQueryVariables>(HeAbrechnungListDocument, options);
}
export function useHeAbrechnungListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeAbrechnungListQuery, HeAbrechnungListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HeAbrechnungListQuery, HeAbrechnungListQueryVariables>(HeAbrechnungListDocument, options);
}
export function useHeAbrechnungListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HeAbrechnungListQuery, HeAbrechnungListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HeAbrechnungListQuery, HeAbrechnungListQueryVariables>(HeAbrechnungListDocument, options);
}
export type HeAbrechnungListQueryHookResult = ReturnType<typeof useHeAbrechnungListQuery>;
export type HeAbrechnungListLazyQueryHookResult = ReturnType<typeof useHeAbrechnungListLazyQuery>;
export type HeAbrechnungListSuspenseQueryHookResult = ReturnType<typeof useHeAbrechnungListSuspenseQuery>;
export type HeAbrechnungListQueryResult = Apollo.QueryResult<HeAbrechnungListQuery, HeAbrechnungListQueryVariables>;
export const HeAbrechnungDocument = gql`
  query HeAbrechnung($objektId: ID!, $heAbrechnungId: ID!) {
    getHeAbrechnung(objektId: $objektId, heAbrechnungId: $heAbrechnungId) {
      data {
        abrechnungszeitraumBis
        abrechnungszeitraumVon
        bezeichnung
        createdBy
        createdByMitarbeiterId
        createTs
        deletable
        errorList {
          message
          type
        }
        heAbrechnungId
        heAbrechnungHeVertragList {
          heAbrechnungHeVertragId
          createdBy
          createdByMitarbeiterId
          createTs
          anteile
          anteilePercent
          abrechnungszeitraumBis
          abrechnungszeitraumVon
          guthaben
          abrechnungsdefinitionType {
            text
            value
          }
          fileId
          hauseigentuemer {
            ...RechtstraegerFields
          }
          saldo
          status {
            description
            text
            value
          }
          updatedByMitarbeiterId
          warningList {
            message
            type
            attribute
          }
        }
        objekt {
          ...Objekt
        }
        rechnungsausstellerId
        status {
          description
          text
          value
        }
        updatedByMitarbeiterId
        warningList {
          message
          type
          attribute
        }
        zahlungsrueckstandList {
          bestandseinheit {
            ...BestandseinheitBaseFields
          }
          offenerBetrag
          rechtstraeger {
            ...RechtstraegerBaseFields
          }
          konto {
            ...AppKontoFields
          }
          letzteBuchungAm
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RechtstraegerFieldsFragmentDoc}
  ${ObjektFragmentDoc}
  ${BestandseinheitBaseFieldsFragmentDoc}
  ${RechtstraegerBaseFieldsFragmentDoc}
  ${AppKontoFieldsFragmentDoc}
`;
export function useHeAbrechnungQuery(
  baseOptions: Apollo.QueryHookOptions<HeAbrechnungQuery, HeAbrechnungQueryVariables> &
    ({ variables: HeAbrechnungQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HeAbrechnungQuery, HeAbrechnungQueryVariables>(HeAbrechnungDocument, options);
}
export function useHeAbrechnungLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeAbrechnungQuery, HeAbrechnungQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HeAbrechnungQuery, HeAbrechnungQueryVariables>(HeAbrechnungDocument, options);
}
export function useHeAbrechnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HeAbrechnungQuery, HeAbrechnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HeAbrechnungQuery, HeAbrechnungQueryVariables>(HeAbrechnungDocument, options);
}
export type HeAbrechnungQueryHookResult = ReturnType<typeof useHeAbrechnungQuery>;
export type HeAbrechnungLazyQueryHookResult = ReturnType<typeof useHeAbrechnungLazyQuery>;
export type HeAbrechnungSuspenseQueryHookResult = ReturnType<typeof useHeAbrechnungSuspenseQuery>;
export type HeAbrechnungQueryResult = Apollo.QueryResult<HeAbrechnungQuery, HeAbrechnungQueryVariables>;
export const HeAbrechnungHeVertragListDocument = gql`
  query HeAbrechnungHeVertragList($objektId: ID!, $heAbrechnungId: ID!) {
    getHeAbrechnungHeVertragList(objektId: $objektId, heAbrechnungId: $heAbrechnungId) {
      data {
        ...HeAbrechnungHeVertragListEntryFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${HeAbrechnungHeVertragListEntryFieldsFragmentDoc}
`;
export function useHeAbrechnungHeVertragListQuery(
  baseOptions: Apollo.QueryHookOptions<HeAbrechnungHeVertragListQuery, HeAbrechnungHeVertragListQueryVariables> &
    ({ variables: HeAbrechnungHeVertragListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HeAbrechnungHeVertragListQuery, HeAbrechnungHeVertragListQueryVariables>(HeAbrechnungHeVertragListDocument, options);
}
export function useHeAbrechnungHeVertragListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HeAbrechnungHeVertragListQuery, HeAbrechnungHeVertragListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HeAbrechnungHeVertragListQuery, HeAbrechnungHeVertragListQueryVariables>(HeAbrechnungHeVertragListDocument, options);
}
export function useHeAbrechnungHeVertragListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HeAbrechnungHeVertragListQuery, HeAbrechnungHeVertragListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HeAbrechnungHeVertragListQuery, HeAbrechnungHeVertragListQueryVariables>(HeAbrechnungHeVertragListDocument, options);
}
export type HeAbrechnungHeVertragListQueryHookResult = ReturnType<typeof useHeAbrechnungHeVertragListQuery>;
export type HeAbrechnungHeVertragListLazyQueryHookResult = ReturnType<typeof useHeAbrechnungHeVertragListLazyQuery>;
export type HeAbrechnungHeVertragListSuspenseQueryHookResult = ReturnType<typeof useHeAbrechnungHeVertragListSuspenseQuery>;
export type HeAbrechnungHeVertragListQueryResult = Apollo.QueryResult<HeAbrechnungHeVertragListQuery, HeAbrechnungHeVertragListQueryVariables>;
export const HeAbrechnungHeVertragDocument = gql`
  query HeAbrechnungHeVertrag($objektId: ID!, $heAbrechnungId: ID!, $heAbrechnungHeVertragId: ID!) {
    getHeAbrechnungHeVertrag(objektId: $objektId, heAbrechnungId: $heAbrechnungId, heAbrechnungHeVertragId: $heAbrechnungHeVertragId) {
      data {
        abrechnungsdefinitionType {
          text
          value
        }
        abrechnungszeitraumBis
        abrechnungszeitraumVon
        ausgaben {
          ...HeAbrAbrechnungskreisListField
        }
        anteile
        anteilePercent
        bezeichnung
        buchungskreisId
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        eigentuemerverrechnung {
          ...HeAbrAbrechnungskreisListField
        }
        einnahmen {
          ...HeAbrAbrechnungskreisListField
        }
        fileId
        guthaben
        heVertrag {
          anteile
          createTs
          createdBy
          createdByMitarbeiterId
          deletable
          deliveryAddress {
            ...AddressFields
          }
          deliveryAddressHaupt
          heVertragId
          kurzBezeichnung
          objektSummeAnteile
          objektVerwaltungSeit
          postIt {
            ...PostIt
          }
          saldouebertrag
          tagList
          updatedByMitarbeiterId
          vertragsBeginn
          vertragsEnde
          vertragspartner {
            ...RechtstraegerFields
          }
          warningList {
            attribute
            message
            type
          }
        }
        heAbrechnungHeVertragId
        hauseigentuemer {
          ...RechtstraegerFields
        }
        infoAbrechnungskreisList {
          ...AbrInfoAbrechnungskreisFields
        }
        objekt {
          ...Objekt
        }
        saldo
        saldovortrag
        status {
          description
          text
          value
        }
        vorsteuerkuerzung {
          ...HeAbrAbrechnungskreisListField
        }
        offenePosten {
          anteil
          offenePostenList {
            bestandseinheitList {
              ...BestandseinheitFields
            }
            dueDate
            fibuKontoId
            fibuKontonummer
            letzteBuchungAm
            offenePostenId
            offenerBetrag
            vertragspartner {
              ...RechtstraegerFields
            }
          }
          summe
        }
        updatedByMitarbeiterId
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${HeAbrAbrechnungskreisListFieldFragmentDoc}
  ${AddressFieldsFragmentDoc}
  ${PostItFragmentDoc}
  ${RechtstraegerFieldsFragmentDoc}
  ${AbrInfoAbrechnungskreisFieldsFragmentDoc}
  ${ObjektFragmentDoc}
  ${BestandseinheitFieldsFragmentDoc}
`;
export function useHeAbrechnungHeVertragQuery(
  baseOptions: Apollo.QueryHookOptions<HeAbrechnungHeVertragQuery, HeAbrechnungHeVertragQueryVariables> &
    ({ variables: HeAbrechnungHeVertragQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HeAbrechnungHeVertragQuery, HeAbrechnungHeVertragQueryVariables>(HeAbrechnungHeVertragDocument, options);
}
export function useHeAbrechnungHeVertragLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HeAbrechnungHeVertragQuery, HeAbrechnungHeVertragQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HeAbrechnungHeVertragQuery, HeAbrechnungHeVertragQueryVariables>(HeAbrechnungHeVertragDocument, options);
}
export function useHeAbrechnungHeVertragSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HeAbrechnungHeVertragQuery, HeAbrechnungHeVertragQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HeAbrechnungHeVertragQuery, HeAbrechnungHeVertragQueryVariables>(HeAbrechnungHeVertragDocument, options);
}
export type HeAbrechnungHeVertragQueryHookResult = ReturnType<typeof useHeAbrechnungHeVertragQuery>;
export type HeAbrechnungHeVertragLazyQueryHookResult = ReturnType<typeof useHeAbrechnungHeVertragLazyQuery>;
export type HeAbrechnungHeVertragSuspenseQueryHookResult = ReturnType<typeof useHeAbrechnungHeVertragSuspenseQuery>;
export type HeAbrechnungHeVertragQueryResult = Apollo.QueryResult<HeAbrechnungHeVertragQuery, HeAbrechnungHeVertragQueryVariables>;
