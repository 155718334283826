import React, { FC } from 'react';
import { Empty } from 'antd';
import { Budgeting, BudgetingBuchungszeile } from '../../../../../types';
import budgetingBuchungszeileTableColumns from './budgetingBuchungszeileTableColumns';
import { useFDAuthorized } from '../../../../../shared/Auth/useAuthorized';
import { IStatus } from '../../../../../helpers/statusHelper';
import NestedTableWithColSelector from '../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';

type Props = {
  budgeting: Budgeting;
  buchungszeileList: BudgetingBuchungszeile[];
  loading: boolean;
  refetch: () => void;
  isUpdating: boolean;
  budgetingVPosStatus: IStatus;
  parentRowKeys?: string[];
  visible?: boolean;
};

const BudgetingBuchungszeileTable: FC<Props> = ({ budgeting, buchungszeileList, budgetingVPosStatus, parentRowKeys, visible = true }) => {
  const { activeForFirmendatenId } = useFDAuthorized();

  return (
    <NestedTableWithColSelector<BudgetingBuchungszeile>
      visible={visible}
      parentRowKeys={parentRowKeys}
      level={4}
      locale={{
        emptyText: <Empty description={<span>Keine Budgetierung vorhanden</span>} />,
      }}
      dataSource={buchungszeileList}
      columns={budgetingBuchungszeileTableColumns(budgeting, activeForFirmendatenId, budgetingVPosStatus)}
      rowKey={(record) => record.belegnummer ?? record.belegDatum}
    />
  );
};
export default BudgetingBuchungszeileTable;
