import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedNumber } from 'react-intl';
import { Typography } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { VertragVposIndexationAgreementVorschreibungsposition } from '../../../../../../../types';
import LinkWithStatus from '../../../../../../../components/Link/LinkWithStatus';
import DataWithStatus from '../../../../../../../components/Helpers/DataWithStatus';
import { compareTwoStringsForSorting } from '../../../../../../../helpers/stringHelper';
import { compareTwoNumbersForSorting } from '../../../../../../../helpers/numberHelper';
import { generatePathToBeVertragDetailsPageVertragsdatenVPosTab } from '../../../../beVertragUriPaths';
import { MitarbeiterTooltip } from '../../../../../../../components/Card';
import DataWithShortenedText from '../../../../../../../components/Helpers/DataWithShortenedText';
import { generatePathToFirmendatenIndexSeriesDetailsPage } from '../../../../../../Firmendaten/IndexSeries/firmendatenIndexSeriesUriPaths';
import TextForEmptyValue from '../../../../../../../components/Text/TextForEmptyValue';
import FormattedDateTime from '../../../../../../../components/FormattedDateTime/FormattedDateTime';
import IndexationAgreementVPosTableActions from './IndexationAgreementVPosTableActions';
import CustomFormattedDate from '../../../../../../../components/FormattedDate/CustomFormattedDate';

const indexationAgreementVPosTableColumns = (
  bestandseinheitId: string,
  objektId: string,
  vertragId: string,
  toggleExpandedRowKey: (rowKey: string) => void,
  expandedRowKeys: string[]
): ColumnProps<VertragVposIndexationAgreementVorschreibungsposition>[] => [
  {
    title: 'Vorschreibungsposition',
    dataIndex: 'vorschreibungsposition',
    key: 'vorschreibungsposition',
    sorter: (a, b) => compareTwoStringsForSorting(a.name, b.name),
    render: (text, record) => (
      <LinkWithStatus
        text={record.name}
        to={generatePathToBeVertragDetailsPageVertragsdatenVPosTab(objektId, bestandseinheitId, vertragId, record.vorschreibungspositionId, [
          record.vorschreibungspositionId,
        ])}
        status={record.objektVorschreibungspositionStatus}
        showBadgeDot={false}
        openInNewTab
      />
    ),
  },
  {
    title: 'Wertsicherungsart',
    dataIndex: 'wertsicherungsart',
    key: 'wertsicherungsart',
    sorter: (a, b) => compareTwoStringsForSorting(a.type?.text, b.type?.text),
    render: (text, record) =>
      record.type ? <DataWithStatus text={record.type.text} status={record.objektVorschreibungspositionStatus} showBadgeDot={false} /> : null,
  },
  {
    title: 'Indexreihe',
    dataIndex: 'indexSeried',
    key: 'indexSeries',
    sorter: true,
    width: 110,
    render: (text, record) =>
      record.indexSeriesName ? (
        <DataWithShortenedText maxTextLength={20} text={record.indexSeriesName}>
          {(shortenedText) =>
            record.indexSeriesId ? (
              <Link to={generatePathToFirmendatenIndexSeriesDetailsPage(record.indexSeriesId)} target="_blank">
                {shortenedText}
              </Link>
            ) : (
              <Typography.Text>{shortenedText}</Typography.Text>
            )
          }
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  { title: '', colSpan: 1, width: 155 },
  {
    title: 'WS gültig ab',
    dataIndex: 'wsGueltigAb',
    key: 'wsGueltigAb',
    width: 110,
    sorter: (a, b) => compareTwoStringsForSorting(a.validFrom, b.validFrom),
    render: (text, record) =>
      record.validFrom ? (
        <DataWithStatus text="Gültigkeit" status={record.objektVorschreibungspositionStatus} showBadgeDot={false}>
          {() => (
            /*eslint-disable-next-line @typescript-eslint/no-non-null-assertion*/
            <CustomFormattedDate value={record.validFrom!} />
          )}
        </DataWithStatus>
      ) : null,
  },
  {
    title: 'Mon/Jahr alt',
    dataIndex: 'monJahrAlt',
    key: 'monJahrAlt',
    width: 110,
    sorter: (a, b) => compareTwoStringsForSorting(a.monthYearOld, b.monthYearOld),
    render: (text, record) =>
      record.monthYearOld ? (
        <DataWithStatus status={record.objektVorschreibungspositionStatus} text={record.monthYearOld} showBadgeDot={false} />
      ) : null,
  },
  {
    title: 'Index alt',
    dataIndex: 'indexAlt',
    key: 'indexAlt',
    align: 'right',
    width: 100,
    sorter: (a, b) => compareTwoNumbersForSorting(a.indexOld, b.indexOld),
    render: (text, record) =>
      record.indexOld ? (
        <DataWithStatus text="Index alt" status={record.objektVorschreibungspositionStatus} showBadgeDot={false}>
          {() => (
            /*eslint-disable-next-line @typescript-eslint/no-non-null-assertion*/
            <FormattedNumber value={record.indexOld!} />
          )}
        </DataWithStatus>
      ) : null,
  },
  {
    title: 'Vorläufig Index alt',
    dataIndex: 'vorlaeufigIndexAlt',
    key: 'vorlaeufigIndexAlt',
    width: 120,
    render: (text, record) => <Typography.Text>{record.indexOldPreliminary ? 'Ja' : 'Nein'}</Typography.Text>,
  },
  { title: '', colSpan: 0, width: 100 },
  { title: '', colSpan: 0, width: 100 },
  { title: '', colSpan: 0, width: 100 },
  { title: '', colSpan: 0, width: 100 },
  { title: '', colSpan: 0, width: 100 },
  { title: '', colSpan: 0, width: 100 },
  { title: '', colSpan: 0, width: 100 },
  {
    title: 'Anzahl Wertsicherungsvereinbarungen',
    dataIndex: 'anzahlWertsicherungsvereinbarungen',
    key: 'anzahlWertsicherungsvereinbarungen',
    width: 100,
    align: 'right',
    colSpan: 8,
    sorter: (a, b) => compareTwoNumbersForSorting(a.indexationAgreementCount, b.indexationAgreementCount),
    render: (text, record) => (
      <DataWithStatus text="Wertsicherungsvereinbarungen-Anzahl" status={record.objektVorschreibungspositionStatus} showBadgeDot={false}>
        {() => <Typography.Text>{record.indexationAgreementCount}</Typography.Text>}
      </DataWithStatus>
    ),
  },
  {
    title: 'Erstellt am',
    width: 80,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    width: 80,
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    key: 'action',
    width: 180,
    render: (text, record) => (
      <IndexationAgreementVPosTableActions
        record={record}
        objektId={objektId}
        bestandseinheitId={bestandseinheitId}
        vertragId={vertragId}
        expandedRowKeys={expandedRowKeys}
        toggleExpandedRowKey={toggleExpandedRowKey}
      />
    ),
  },
];

export default indexationAgreementVPosTableColumns;
