import React, { FC, useState } from 'react';
import { FormikProps } from 'formik';
import { chunk } from 'lodash';
import FormItemWithoutColon from '../../../../../../../components/Form/FormItemWithoutColon';
import { filtersFormFields, FiltersFormValues, listingLabelList } from './buchungszeileListingFiltersFormMapper';
import BzGeneralSelect from './BzGeneralSelect';
import FromToFormDatePicker from '../../../../../../shared/FromToForm/FromToFormDatePicker';
import BzObjektSelect from './BzObjektSelect';
import BzBestandseinheitSelect from './BzBestandseinheitSelect';
import BzKontoSelect from './BzKontoSelect';
import BzEnumSelect from './BzEnumSelect';
import BzVertragSelect from './BzVertragSelect';
import FiltersWith4OrMoreFields from '../../../../../../../components/Filters/FiltersWith4OrMoreFields';
import FiltersSelect from '../../../../../../../components/Filters/FiltersSelect';
import { FibuBuchungszeile } from '../../../../../../../types';
import { onFilterSelectChange } from '../../../../../../../components/Filters/FiltersHelpers';
import BzBuchungskreisSelect from './BzBuchungskreisSelect';
import FibuBuchungTypeFormSelect from '../../../../../../BuchungType/Fibu/FibuBuchungTypeFormSelect';

type Props = {
  buchungszeileList: FibuBuchungszeile[];
  formikProps: FormikProps<FiltersFormValues>;
};

const ListingFilters: FC<Props> = ({ buchungszeileList, formikProps }) => {
  const defaultSelectedFilterList = [
    filtersFormFields.buchungsdatumVon,
    filtersFormFields.bestandseinheitId,
    filtersFormFields.kontoId,
    filtersFormFields.sollHaben,
  ];

  const [selectedFilterList, setSelectedFilterList] = useState<string[]>(defaultSelectedFilterList);

  const filterList = selectedFilterList.map((filter, index) => {
    if (filter === filtersFormFields.buchungkreisId) {
      return (
        <FormItemWithoutColon name={filtersFormFields.buchungkreisId} label={listingLabelList.buchungkreisId} key={index}>
          <BzBuchungskreisSelect name={filtersFormFields.buchungkreisId} buchungszeileList={buchungszeileList} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.belegsymbol) {
      return (
        <FormItemWithoutColon name={filtersFormFields.belegsymbol} label={listingLabelList.belegsymbol} key={index}>
          <BzEnumSelect
            name={filtersFormFields.belegsymbol}
            buchungszeileList={buchungszeileList}
            getItemForSelect={(data) => data.buchungsKopf.belegSymbol}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.belegnummer) {
      return (
        <FormItemWithoutColon name={filtersFormFields.belegnummer} label={listingLabelList.belegnummer} key={index}>
          <BzGeneralSelect
            name={filtersFormFields.belegnummer}
            buchungszeileList={buchungszeileList}
            getItemForSelect={(data) => data.buchungsKopf.belegnummer}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.belegdatumVon) {
      return (
        <FormItemWithoutColon name="belegdatum" label={listingLabelList.belegdatumVon} key={index}>
          <FromToFormDatePicker
            format="day"
            fromName={filtersFormFields.belegdatumVon}
            toName={filtersFormFields.belegdatumBis}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.buchungsdatumVon) {
      return (
        <FormItemWithoutColon name="buchungsdatum" label={listingLabelList.buchungsdatumVon} key={index}>
          <FromToFormDatePicker
            format="day"
            fromName={filtersFormFields.buchungsdatumVon}
            toName={filtersFormFields.buchungsdatumBis}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.buchungskennzeichen) {
      return (
        <FormItemWithoutColon name={filtersFormFields.buchungskennzeichen} label={listingLabelList.buchungskennzeichen} key={index}>
          <BzGeneralSelect
            name={filtersFormFields.buchungskennzeichen}
            buchungszeileList={buchungszeileList}
            getItemForSelect={(data) => data.buchungsKZ}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.objektId) {
      return (
        <FormItemWithoutColon name={filtersFormFields.objektId} label={listingLabelList.objektId} key={index}>
          <BzObjektSelect name={filtersFormFields.objektId} buchungszeileList={buchungszeileList} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.bestandseinheitId) {
      return (
        <FormItemWithoutColon name={filtersFormFields.bestandseinheitId} label={listingLabelList.bestandseinheitId} key={index}>
          <BzBestandseinheitSelect name={filtersFormFields.bestandseinheitId} buchungszeileList={buchungszeileList} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.vertragId) {
      return (
        <FormItemWithoutColon name={filtersFormFields.vertragId} label={listingLabelList.vertragId} key={index}>
          <BzVertragSelect name={filtersFormFields.vertragId} buchungszeileList={buchungszeileList} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.kontoId) {
      return (
        <FormItemWithoutColon name={filtersFormFields.kontoId} label={listingLabelList.kontoId} key={index}>
          <BzKontoSelect name={filtersFormFields.kontoId} buchungszeileList={buchungszeileList} getItemForSelect={(data) => data.konto} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.gegenKontoId) {
      return (
        <FormItemWithoutColon name={filtersFormFields.gegenKontoId} label={listingLabelList.gegenKontoId} key={index}>
          <BzKontoSelect name={filtersFormFields.gegenKontoId} buchungszeileList={buchungszeileList} getItemForSelect={(data) => data.gegenKonto} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.sollHaben) {
      return (
        <FormItemWithoutColon name={filtersFormFields.sollHaben} label={listingLabelList.sollHaben} key={index}>
          <BzEnumSelect name={filtersFormFields.sollHaben} buchungszeileList={buchungszeileList} getItemForSelect={(data) => data.sollHaben} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.ersteller) {
      return (
        <FormItemWithoutColon name={filtersFormFields.ersteller} label={listingLabelList.ersteller} key={index}>
          <BzGeneralSelect name={filtersFormFields.ersteller} buchungszeileList={buchungszeileList} getItemForSelect={(data) => data.createdBy} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.buchungsTypeList) {
      return (
        <FormItemWithoutColon name={filtersFormFields.buchungsTypeList} label={listingLabelList.buchungsTypeList} key={index}>
          <FibuBuchungTypeFormSelect name={filtersFormFields.buchungsTypeList} />
        </FormItemWithoutColon>
      );
    }
    return '';
  });

  const chunkArray = chunk(filterList, Math.round(filterList.length / 2));

  return (
    <FiltersWith4OrMoreFields
      filtersSelect={
        <FiltersSelect
          defaultSelectedFilterList={defaultSelectedFilterList}
          selectedFilterList={selectedFilterList}
          onChange={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
          labelList={listingLabelList}
        />
      }
      hideTitle
      leftColumn={<>{chunkArray[0]}</>}
      rightColumn={<>{chunkArray[1]}</>}
    />
  );
};

export default ListingFilters;
