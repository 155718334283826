import { generatePath } from 'react-router';
import { mapToQueryString, QueryParams } from '../../../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../../../hooks/usePaginationQueryParams';
import { FiltersFormValues } from './listingFiltersFormMapper';
import { URI_VERARBEITUNG_PAGE } from '../../../../constants/appUriPaths';

export enum QueryParamKey {
  ERSTELLER_MITARBEITER_ID_LIST = 'erstellerMitarbeiterIdList',
  GENERIER_DATUM_BIS_INKLUSIVE = 'generierDatumBisInklusive',
  GENERIER_DATUM_VON_INKLUSIVE = 'generierDatumVonInklusive',
  STATUS_LIST = 'statusList',
  TYPE_LIST = 'typeList',
}

export type TVerarbeitungQueryParams = QueryParams<FiltersFormValues>;

export const updateQueryParams = (
  navigate: (path: string) => void,
  filters: TVerarbeitungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToVerarbeitungListingPage(filters, paginationParams));

export const generatePathToVerarbeitungListingPage = (filters: TVerarbeitungQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  `${generatePath(URI_VERARBEITUNG_PAGE)}?${mapToQueryString({
    [QueryParamKey.ERSTELLER_MITARBEITER_ID_LIST]: filters?.erstellerMitarbeiterIdList,
    [QueryParamKey.GENERIER_DATUM_BIS_INKLUSIVE]: filters?.generierDatumBisInklusive,
    [QueryParamKey.GENERIER_DATUM_VON_INKLUSIVE]: filters?.generierDatumVonInklusive,
    [QueryParamKey.STATUS_LIST]: filters?.statusList,
    [QueryParamKey.TYPE_LIST]: filters?.typeList,
    ...paginationParams,
  })}`;
