import { MenuProps, Modal } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { TAddressOrContactListItem } from '../AddressAndContactList';
import { addHauptToTagsIfNeccessary } from '../../../../../components/Tag/tagHelpers';
import { showSuccessMsgUpdate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import AddressOrContactListItemTags from './AddressOrContactListItemTags';
import { AddressAndContactTagListInput } from '../../../../../types';
import { useSetAdresseTagListForAddressMutation, useSetContactTagListForContactMutation } from '../../gql/AddressesAndContactsMutations.types';
import { StyledSpaceForDetails } from './shared/ContactListItemStyleHelpers';
import ContactListItem from './shared/ContactListItem';
import AuditFooter from '../../../../../components/Footer/AuditFooter';

type Props = {
  firmendatenId: string;
  item: TAddressOrContactListItem;
  icon: React.ReactNode;
  content: React.ReactNode;
  menuItems: MenuProps['items'];
  onSuccess: () => void;
  isDraggable: boolean;
  isHauptInUse?: boolean;
  canBeHaupt?: boolean;
};

const AddressOrContactListItem: FC<Props> = ({ firmendatenId, item, icon, content, menuItems, onSuccess, isDraggable, isHauptInUse, canBeHaupt }) => {
  const isHaupt = item.haupt ?? false;
  const [tags, setTags] = useState(item.tagList);

  const revertHauptTag = () => {
    setTags(tags);
  };

  useEffect(() => {
    setTags(addHauptToTagsIfNeccessary(isHaupt, item.tagList));
  }, [isHaupt, item.tagList]);

  const [runSetAdresseTagList, { loading: isAdresseSaveLoading }] = useSetAdresseTagListForAddressMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`Tag-Liste`);
      onSuccess();
    },
    onError: () => APOLLO_DUMMY_ERROR_HANDLER,
  });

  const setAdresseTagList = (addressId: string, input: AddressAndContactTagListInput) => {
    const setAdresseTagList = () =>
      runSetAdresseTagList({
        variables: {
          firmendatenId,
          addressId,
          input,
        },
      });

    const isChangingCurrentCurrentHaupt = isHauptInUse && input.haupt && !isHaupt;
    isChangingCurrentCurrentHaupt ? showConfirmHaupt(true, setAdresseTagList, revertHauptTag) : setAdresseTagList();
  };

  const [runSetContactTagsList, { loading: isContactSaveLoading }] = useSetContactTagListForContactMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`Tag-Liste`);
      onSuccess();
    },
    onError: () => APOLLO_DUMMY_ERROR_HANDLER,
  });

  const setContactTagList = (contactId: string, input: AddressAndContactTagListInput) => {
    const run = () =>
      runSetContactTagsList({
        variables: {
          firmendatenId,
          contactId,
          input,
        },
      });

    isHauptInUse && input.haupt && !isHaupt ? showConfirmHaupt(false, run, revertHauptTag) : run();
  };

  const setTagList = (input: AddressAndContactTagListInput) => {
    if ('addressId' in item && item.addressId) {
      setAdresseTagList(item.addressId, input);
    }
    if ('contactId' in item && item.contactId) {
      setContactTagList(item.contactId, input);
    }
  };

  const contactDetails = (
    <StyledSpaceForDetails>
      {content}
      <AddressOrContactListItemTags
        tags={tags}
        setTags={setTags}
        setTagList={setTagList}
        loading={isAdresseSaveLoading || isContactSaveLoading}
        canBeHaupt={canBeHaupt}
      />
    </StyledSpaceForDetails>
  );

  const footer = <AuditFooter createTs={item.createTs} userId={item.createdBy} mitarbeiterId={item.createdByMitarbeiterId} />;

  return (
    <ContactListItem contactDetails={contactDetails} isHaupt={isHaupt} icon={icon} menuItems={menuItems} isDraggable={isDraggable} footer={footer} />
  );
};

const MAIN_CONTACT_TITLE_TEXT = 'Ein anderer Kontakt ist als Haupt markiert. Möchten Sie hiermit den Kontakt wechseln?';
const MAIN_CONTACT_CONTENT_TEXT = 'EasyCloud ändert den Kontakt für Sie.';

const MAIN_ADDRESS_TITLE_TEXT = 'Eine andere Strasse ist als Haupt markiert. Möchten Sie hiermit die Hauptadresse wechseln?';
const MAIN_ADDRESS_CONTENT_TEXT = 'EasyCloud ändert die Hauptadresse für Sie.';

const showConfirmHaupt = (isAddress: boolean, setTagsList: () => void, revert: () => void) => {
  Modal.confirm({
    title: isAddress ? MAIN_ADDRESS_TITLE_TEXT : MAIN_CONTACT_TITLE_TEXT,
    content: isAddress ? MAIN_ADDRESS_CONTENT_TEXT : MAIN_CONTACT_CONTENT_TEXT,
    okText: 'Ja',
    cancelText: 'Nein',
    onOk() {
      return setTagsList();
    },
    onCancel() {
      return revert();
    },
  });
};

export default AddressOrContactListItem;
