import { UstKategorie, UstKategorieCreateInput, UstKategorieUpdateInput } from '../../../../types';
import { FormFields } from '../../../../helpers/formikHelper';

export interface UstKategorieFormValues {
  kurzBezeichnung: string;
  firmendatenId: string;
}

const ustKategorieFormInitialValues: UstKategorieFormValues = {
  kurzBezeichnung: '',
  firmendatenId: '',
};

export const mapUstKategorieToFormValues = (ustKategorie?: UstKategorie): UstKategorieFormValues => {
  if (!ustKategorie) {
    return ustKategorieFormInitialValues;
  }
  return {
    kurzBezeichnung: ustKategorie.kurzBezeichnung,
    firmendatenId: ustKategorie.firmendatenId ?? '',
  };
};

export const mapFormValuesToUstKategorie = (values: UstKategorieFormValues): UstKategorieCreateInput | UstKategorieUpdateInput => ({
  kurzBezeichnung: values.kurzBezeichnung,
});

export const ustKategorieFormFields: FormFields<UstKategorieFormValues> = {
  kurzBezeichnung: 'kurzBezeichnung',
  firmendatenId: 'firmendatenId',
};
