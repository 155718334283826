import React from 'react';
import { Link } from 'react-router-dom';
import { TableColumnProps } from 'antd';
import { EbicsUser, EbicsUserOrderBy } from '../../../../../../types';
import DataWithShortenedText from '../../../../../../components/Helpers/DataWithShortenedText';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';
import FormattedDateTime from '../../../../../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../../../../../components/Card';
import StatusBadge from '../../../../../../components/Status/StatusBadge';
import { uriFileOfFirmendaten } from '../../../../../Document/documentApiPaths';
import EbicsUserActions from './EbicsUserActions';
import TextsWithTooltipForOrderTypeList from '../../../../shared/TextsWithTooltipForOrderTypeList';

const ebicsUserTableColumns = (firmendatenId: string, onAction: () => void): TableColumnProps<EbicsUser>[] => {
  return [
    {
      title: 'Mitarbeiter',
      dataIndex: EbicsUserOrderBy.Mitarbeiter,
      sorter: true,
      render: (text, record) => <DataWithShortenedText text={record.mitarbeiterName} maxTextLength={30} />,
    },
    {
      title: 'Teilnehmer',
      dataIndex: EbicsUserOrderBy.Teilnehmer,
      sorter: true,
      render: (text, record) =>
        record.name ? <DataWithShortenedText text={record.name} maxTextLength={30} /> : <TextForEmptyValue textToShow="minus" />,
    },
    {
      title: 'Teilnehmer Auftragsarten Berechtigungen',
      sorter: true,
      render: (text, record) =>
        record.orderTypeList.length > 0 ? (
          <TextsWithTooltipForOrderTypeList texts={record.orderTypeList.map((orderType) => orderType.text)} maxTextLength={15} addLineBreakAt={4} />
        ) : (
          <TextForEmptyValue textToShow="minus" />
        ),
    },
    {
      title: 'Teilnehmerkennung',
      dataIndex: EbicsUserOrderBy.User,
      sorter: true,
      render: (text, record) => <DataWithShortenedText text={record.userId} maxTextLength={30} />,
    },
    {
      title: 'Kundenkennung',
      dataIndex: EbicsUserOrderBy.Partner,
      sorter: true,
      render: (text, record) => <DataWithShortenedText text={record.partnerId} maxTextLength={30} />,
    },
    {
      title: 'Bankname',
      dataIndex: EbicsUserOrderBy.Bankname,
      sorter: true,
      render: (text, record) => <DataWithShortenedText text={record.bankname} maxTextLength={30} />,
    },
    {
      title: 'EBICS Initialisierungsbrief',
      render: (text, record) => {
        const name = `initbrief_${record.bankname}_${record.userId}.pdf`;
        return record.initializationDocumentFileId ? (
          <Link to={uriFileOfFirmendaten(firmendatenId, record.initializationDocumentFileId, name)} target="_blank">
            {name}
          </Link>
        ) : (
          <TextForEmptyValue textToShow="minus" />
        );
      },
    },
    {
      title: 'EBICS Status',
      dataIndex: EbicsUserOrderBy.Status,
      sorter: true,
      render: (text, record) => {
        return <StatusBadge entityStatus={record.status} />;
      },
    },
    {
      title: 'Erstellt am',
      width: 80,
      render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
    },
    {
      title: 'Ersteller',
      width: 80,
      render: (text, record) => (
        <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
      ),
    },
    {
      title: 'Aktion',
      width: 80,
      render: (text, record) => {
        return <EbicsUserActions ebicsUser={record} onAction={onAction} />;
      },
    },
  ];
};

export default ebicsUserTableColumns;
