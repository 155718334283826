import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { useAbrechnungsdefinitionTemplateQuery } from '../../../../../../features/Abrechnungsdefinition/gql/AbrDefQueries.types';
import { AbrechnungsdefinitionTemplateType } from '../../../../../../types';
import { findPrecedingVersionId, getFirmendatenId, hasPrecedingVersion, isPrecedingVersionAvailable } from './createPageHelpers';
import {
  useBkAbrechnungsdefinitionVersionListQuery,
  useBkAbrechnungsdefinitionVersionQuery,
} from '../../../../../../features/Abrechnungsdefinition/gql/BkAbrDef/BkAbrDefVersionQueries.types';
import BkAbrDefVersionCreateForm from '../../../../../../features/Abrechnungsdefinition/Version/Form/Create/BkAbrDefVersionCreateForm';

type Props = {
  abrechnungsdefinitionId: string;
};
const SysSettingsBkAbrechnungsdefinitionVersionCreatePage: FC<Props> = ({ abrechnungsdefinitionId }) => {
  const { data: bkAbrDefVersionListData } = useBkAbrechnungsdefinitionVersionListQuery({
    variables: { abrechnungsdefinitionId },
  });
  const versionList = bkAbrDefVersionListData?.getBkAbrechnungsdefinitionVersionList.data;
  const precedingVersionId = versionList ? findPrecedingVersionId(versionList) : undefined;

  const { data: bkAbrDefVersionData } = useBkAbrechnungsdefinitionVersionQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { abrechnungsdefinitionId, abrechnungsdefinitionVersionId: precedingVersionId! },
    skip: !precedingVersionId,
  });
  const precedingVersion = bkAbrDefVersionData?.getBkAbrechnungsdefinitionVersion.data;

  const { data: templateObjektData } = useAbrechnungsdefinitionTemplateQuery({
    variables: { type: AbrechnungsdefinitionTemplateType.ObjektAbrechnung },
    skip: isPrecedingVersionAvailable(versionList, precedingVersion),
  });
  const objektAbrechnungTextbausteinList = templateObjektData?.getAbrechnungsdefinitionTemplate.data.textbausteinList;

  const { data: templateTopData } = useAbrechnungsdefinitionTemplateQuery({
    variables: { type: AbrechnungsdefinitionTemplateType.TopAbrechnung },
    skip: isPrecedingVersionAvailable(versionList, precedingVersion),
  });
  const topAbrechnungTextbausteinList = templateTopData?.getAbrechnungsdefinitionTemplate.data.textbausteinList;

  // Show loading state until initial data is available: if there is a precedingVersion we get the textbausteinLists from it otherwise we have to load default templates
  if (
    !versionList ||
    (hasPrecedingVersion(versionList) && !precedingVersion) ||
    (hasPrecedingVersion(versionList) === false && (!objektAbrechnungTextbausteinList || !topAbrechnungTextbausteinList))
  ) {
    return <Skeleton active />;
  }

  return (
    <BkAbrDefVersionCreateForm
      abrechnungsdefinitionVersionList={versionList}
      precedingAbrechnungsdefinitionVersion={precedingVersion}
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      firmendatenId={getFirmendatenId(versionList)}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      objektAbrechnungTextbausteinList={precedingVersion?.objektAbrechnungTextbausteinList ?? objektAbrechnungTextbausteinList!}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      topAbrechnungTextbausteinList={precedingVersion?.topAbrechnungTextbausteinList ?? topAbrechnungTextbausteinList!}
    />
  );
};

export default SysSettingsBkAbrechnungsdefinitionVersionCreatePage;
