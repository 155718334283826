import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { Typography } from 'antd';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import { useDataCarrierPainPaymentProposalNameListQuery } from '../../../gql/DataCarrierQueries.types';

type Props = SelectProps;

const PainPaymentProposalNameListFormSelect: FC<Props> = ({ ...restProps }) => {
  const { loading, data } = useDataCarrierPainPaymentProposalNameListQuery();
  const list = data?.getDataCarrierPainPaymentProposalNameList.data ?? [];

  return (
    <Select
      {...restProps}
      size="small"
      id={restProps.name}
      loading={loading}
      placeholder="Zahlungsvorschlag auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {list.map((paymentProposalName) => (
        <Select.Option key={paymentProposalName} value={paymentProposalName}>
          <Typography.Text>{paymentProposalName}</Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export default PainPaymentProposalNameListFormSelect;
