import { Space } from 'antd';
import { FC } from 'react';
import KontoWithQuery from '../../../shared/components/Konto/KontoWithQuery';
import { Vorsteuerkuerzung } from '../../../types';

type Props = {
  vstKuerzung: Vorsteuerkuerzung;
  objektId: string;
};

const AufwandKontoList: FC<Props> = ({ vstKuerzung, objektId }) => {
  const aufwandKontoList = vstKuerzung.vorsteuerkuerzungVertragList
    .map((vertrag) =>
      vertrag.vorsteuerkuerzungKontoList
        .map((vstKuerzung) => vstKuerzung.aufwandsKontoList.map((aufwandKonto) => aufwandKonto.aufwandsKontoId))
        .flat()
    )
    .flat();
  const uniqueAufwandKontoList = Array.from(new Set(aufwandKontoList));

  return (
    <Space direction="vertical">
      {uniqueAufwandKontoList.map((kontoId, index) => (
        <KontoWithQuery kontoId={kontoId} key={index} objektId={objektId} />
      ))}
    </Space>
  );
};

export default AufwandKontoList;
