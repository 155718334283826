import React from 'react';
import { Route } from 'react-router';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../security/permissionChecks';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_SYS_SETTINGS } from '../../../../constants/configurationUriPaths';
import SysSettingsVorsteuerkuerzungPage from './SysSettingsVorsteuerkuerzungPage';

const sysSettingsVorsteuerkuerzungPageRoute = (
  <Route
    path={URI_SYS_SETTINGS.vorsteuerkuerzungPage}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsVorsteuerkuerzungPage />} />}
  />
);

export default sysSettingsVorsteuerkuerzungPageRoute;
