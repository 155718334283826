import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { BankDetailsFieldsFragmentDoc } from '../../../BankDetails/gql/BankDetailsFragments.types';
import { PersonenbezugFragmentDoc } from '../../../Personenbezug/gql/PersonenbezugFragments.types';
import { PostItFragmentDoc } from '../../PostIt/gql/PostItFragments.types';
import { RechtstraegerSteuerpflichtFragmentDoc } from '../../gql/RechtsTraegerFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PersonenGemeinschaftQueryVariables = Types.Exact<{
  personenGemeinschaftId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type PersonenGemeinschaftQuery = {
  getPersonenGemeinschaft: {
    data: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      fibuKontoCreatable: boolean;
      kundennummer: string;
      kurzBezeichnung: string;
      name1: string;
      name2?: string | null;
      name3?: string | null;
      rechtstraegerId: string;
      tagList: Array<string>;
      vatIdentificationNumber?: string | null;
      bankDetailsList: Array<{
        accountHolder: string;
        accountLimit?: number | null;
        assignedHeVertragZahlungsregelMap: any;
        assignedObjektMap: any;
        bankDetailsId: string;
        bankname: string;
        bic: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        ebicsCreditTransferUserList: Array<string>;
        ebicsDirectDebitUserList: Array<string>;
        fibuKontoId?: string | null;
        fibuKontonummer?: string | null;
        iban: string;
        updatedByMitarbeiterId?: string | null;
        fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
        fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
        sepaMandat?: {
          fileId?: string | null;
          fileName?: string | null;
          sepaMandatId: string;
          sepaMandatReference: string;
          signatureDate?: string | null;
          firmendaten?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firmendatenId: string;
            kundenSystemId: string;
            kurzBezeichnung: string;
            name1: string;
            name2?: string | null;
            name3?: string | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          rechnungsAussteller?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
          warningList: Array<{ message: string; type: string }>;
        } | null;
        status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null>;
      communityType: { text: string; value: Types.PersonsCommunityType };
      personenbezugList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        personenbezugId: string;
        tagList: Array<string>;
        person: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechtstraegerSteuerpflicht?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        rechtstraegerSteuerpflichtId: string;
        validFrom: string;
        historicizedList?: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ message: string; type: string }>;
        }> | null;
        steuerpflicht: { text: string; value: Types.Steuerpflicht };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
      sprache: { text: string; value: string };
      status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      vertragSummary?: {
        heVertragCount: number;
        heVertragVerwaltungCount: number;
        mietVertragCount: number;
        mietVertragVerwaltungCount: number;
        weSubverwaltungCount: number;
        weVertragCount: number;
        weVertragVerwaltungCount: number;
      } | null;
      warningList: Array<{ type: string; attribute?: string | null; message: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type PersonenGemeinschaftChangeHistoryListQueryVariables = Types.Exact<{
  personenGemeinschaftId: Types.Scalars['ID']['input'];
}>;

export type PersonenGemeinschaftChangeHistoryListQuery = {
  getPersonenGemeinschaftChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const PersonenGemeinschaftDocument = gql`
  query PersonenGemeinschaft($personenGemeinschaftId: ID!, $withDetails: Boolean) {
    getPersonenGemeinschaft(personenGemeinschaftId: $personenGemeinschaftId, withDetails: $withDetails) {
      data {
        bankDetailsList {
          ...BankDetailsFields
        }
        communityType {
          text
          value
        }
        createTs
        createdBy
        createdByMitarbeiterId
        deletable
        fibuKontoCreatable
        kundennummer
        kurzBezeichnung
        name1
        name2
        name3
        personenbezugList {
          ...Personenbezug
        }
        postIt {
          ...PostIt
        }
        rechtstraegerId
        rechtstraegerSteuerpflicht {
          ...RechtstraegerSteuerpflicht
        }
        salutation {
          letterSalutation
          text
          value
        }
        sprache {
          text
          value
        }
        status {
          description
          text
          value
        }
        tagList
        type {
          text
          value
        }
        vatIdentificationNumber
        vertragSummary {
          heVertragCount
          heVertragVerwaltungCount
          mietVertragCount
          mietVertragVerwaltungCount
          weSubverwaltungCount
          weVertragCount
          weVertragVerwaltungCount
        }
        warningList {
          type
          attribute
          message
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BankDetailsFieldsFragmentDoc}
  ${PersonenbezugFragmentDoc}
  ${PostItFragmentDoc}
  ${RechtstraegerSteuerpflichtFragmentDoc}
`;
export function usePersonenGemeinschaftQuery(
  baseOptions: Apollo.QueryHookOptions<PersonenGemeinschaftQuery, PersonenGemeinschaftQueryVariables> &
    ({ variables: PersonenGemeinschaftQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PersonenGemeinschaftQuery, PersonenGemeinschaftQueryVariables>(PersonenGemeinschaftDocument, options);
}
export function usePersonenGemeinschaftLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PersonenGemeinschaftQuery, PersonenGemeinschaftQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PersonenGemeinschaftQuery, PersonenGemeinschaftQueryVariables>(PersonenGemeinschaftDocument, options);
}
export function usePersonenGemeinschaftSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PersonenGemeinschaftQuery, PersonenGemeinschaftQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PersonenGemeinschaftQuery, PersonenGemeinschaftQueryVariables>(PersonenGemeinschaftDocument, options);
}
export type PersonenGemeinschaftQueryHookResult = ReturnType<typeof usePersonenGemeinschaftQuery>;
export type PersonenGemeinschaftLazyQueryHookResult = ReturnType<typeof usePersonenGemeinschaftLazyQuery>;
export type PersonenGemeinschaftSuspenseQueryHookResult = ReturnType<typeof usePersonenGemeinschaftSuspenseQuery>;
export type PersonenGemeinschaftQueryResult = Apollo.QueryResult<PersonenGemeinschaftQuery, PersonenGemeinschaftQueryVariables>;
export const PersonenGemeinschaftChangeHistoryListDocument = gql`
  query PersonenGemeinschaftChangeHistoryList($personenGemeinschaftId: ID!) {
    getPersonenGemeinschaftChangeHistoryList(personenGemeinschaftId: $personenGemeinschaftId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function usePersonenGemeinschaftChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<PersonenGemeinschaftChangeHistoryListQuery, PersonenGemeinschaftChangeHistoryListQueryVariables> &
    ({ variables: PersonenGemeinschaftChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PersonenGemeinschaftChangeHistoryListQuery, PersonenGemeinschaftChangeHistoryListQueryVariables>(
    PersonenGemeinschaftChangeHistoryListDocument,
    options
  );
}
export function usePersonenGemeinschaftChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PersonenGemeinschaftChangeHistoryListQuery, PersonenGemeinschaftChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PersonenGemeinschaftChangeHistoryListQuery, PersonenGemeinschaftChangeHistoryListQueryVariables>(
    PersonenGemeinschaftChangeHistoryListDocument,
    options
  );
}
export function usePersonenGemeinschaftChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<PersonenGemeinschaftChangeHistoryListQuery, PersonenGemeinschaftChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PersonenGemeinschaftChangeHistoryListQuery, PersonenGemeinschaftChangeHistoryListQueryVariables>(
    PersonenGemeinschaftChangeHistoryListDocument,
    options
  );
}
export type PersonenGemeinschaftChangeHistoryListQueryHookResult = ReturnType<typeof usePersonenGemeinschaftChangeHistoryListQuery>;
export type PersonenGemeinschaftChangeHistoryListLazyQueryHookResult = ReturnType<typeof usePersonenGemeinschaftChangeHistoryListLazyQuery>;
export type PersonenGemeinschaftChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof usePersonenGemeinschaftChangeHistoryListSuspenseQuery>;
export type PersonenGemeinschaftChangeHistoryListQueryResult = Apollo.QueryResult<
  PersonenGemeinschaftChangeHistoryListQuery,
  PersonenGemeinschaftChangeHistoryListQueryVariables
>;
