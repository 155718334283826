import { Space, Typography } from 'antd';
import { FC, PropsWithChildren } from 'react';
import { BorderOutlined } from '@ant-design/icons';
import InfoFeldListItem from '../InfoFeldListItem';
import { BeInfoFeld_BeInfoFeldZimmer_Fragment } from '../../../gql/BeInfoFeldFragments.types';
import ZimmerListItemActions from './ZimmerListItemActions';
import { StyledSpaceSplitA30 } from '../../../../../../components/Space/styled/Space.style';

type Props = {
  zimmer: BeInfoFeld_BeInfoFeldZimmer_Fragment;
  objektId: string;
  bestandseinheitId: string;
  onSuccess: () => void;
};

const ZimmerListItem: FC<Props & PropsWithChildren> = ({ zimmer, objektId, bestandseinheitId, onSuccess }) => {
  return (
    <InfoFeldListItem
      icon={<BorderOutlined />}
      content={{ fileIdList: zimmer.fileIdList, props: { height: 48 } }}
      actions={<ZimmerListItemActions infoFeld={zimmer} objektId={objektId} bestandseinheitId={bestandseinheitId} onSuccess={onSuccess} />}
    >
      <Space direction="vertical" style={{ width: '220px' }}>
        <Typography.Text type="secondary">Zimmer</Typography.Text>
        <Typography.Text>Bezeichnung {zimmer.bezeichnung}</Typography.Text>
      </Space>
      <StyledSpaceSplitA30>{zimmer?.nutzflaeche && <Typography.Text>Nutzfläche {zimmer.nutzflaeche}</Typography.Text>}</StyledSpaceSplitA30>
    </InfoFeldListItem>
  );
};

export default ZimmerListItem;
