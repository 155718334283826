import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateKsAufteilungsschluesselMutationVariables = Types.Exact<{
  input: Types.AufteilungsschluesselCreateInput;
  kundenSystemId: Types.Scalars['ID']['input'];
}>;

export type CreateKsAufteilungsschluesselMutation = {
  createKSAufteilungsschluessel: {
    data: {
      aufteilungsschluesselId: string;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      bezugsbasis: { text: string; value: Types.Bezugsbasis };
      masseinheit: { text: string; value: Types.Masseinheit };
      status: { text: string; value: Types.AufteilungsschluesselStatus };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateFdAufteilungsschluesselMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  input: Types.AufteilungsschluesselCreateInput;
}>;

export type CreateFdAufteilungsschluesselMutation = {
  createFDAufteilungsschluessel: {
    data: {
      aufteilungsschluesselId: string;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      bezugsbasis: { text: string; value: Types.Bezugsbasis };
      masseinheit: { text: string; value: Types.Masseinheit };
      status: { text: string; value: Types.AufteilungsschluesselStatus };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateAufteilungsschluesselMutationVariables = Types.Exact<{
  input: Types.AufteilungsschluesselUpdateInput;
  aufteilungsschluesselId: Types.Scalars['ID']['input'];
}>;

export type UpdateAufteilungsschluesselMutation = {
  updateAufteilungsschluessel: {
    data: {
      aufteilungsschluesselId: string;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      bezugsbasis: { text: string; value: Types.Bezugsbasis };
      masseinheit: { text: string; value: Types.Masseinheit };
      status: { text: string; value: Types.AufteilungsschluesselStatus };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteAufteilungsschluesselMutationVariables = Types.Exact<{
  aufteilungsschluesselId: Types.Scalars['ID']['input'];
}>;

export type DeleteAufteilungsschluesselMutation = {
  deleteAufteilungsschluessel: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type UnarchiveAufteilungsschluesselMutationVariables = Types.Exact<{
  aufteilungsschluesselId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveAufteilungsschluesselMutation = {
  actionUnarchiveAufteilungsschluessel: {
    data: {
      aufteilungsschluesselId: string;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      bezugsbasis: { text: string; value: Types.Bezugsbasis };
      masseinheit: { text: string; value: Types.Masseinheit };
      status: { text: string; value: Types.AufteilungsschluesselStatus };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ArchiveAufteilungsschluesselMutationVariables = Types.Exact<{
  aufteilungsschluesselId: Types.Scalars['ID']['input'];
}>;

export type ArchiveAufteilungsschluesselMutation = {
  actionArchiveAufteilungsschluessel: {
    data: {
      aufteilungsschluesselId: string;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      bezugsbasis: { text: string; value: Types.Bezugsbasis };
      masseinheit: { text: string; value: Types.Masseinheit };
      status: { text: string; value: Types.AufteilungsschluesselStatus };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateKsAufteilungsschluesselDocument = gql`
  mutation createKSAufteilungsschluessel($input: AufteilungsschluesselCreateInput!, $kundenSystemId: ID!) {
    createKSAufteilungsschluessel(input: $input, kundenSystemId: $kundenSystemId) {
      data {
        aufteilungsschluesselId
        bezeichnung
        bezugsbasis {
          text
          value
        }
        createTs
        createdBy
        createdByMitarbeiterId
        kurzBezeichnung
        masseinheit {
          text
          value
        }
        status {
          text
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateKsAufteilungsschluesselMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateKsAufteilungsschluesselMutation, CreateKsAufteilungsschluesselMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateKsAufteilungsschluesselMutation, CreateKsAufteilungsschluesselMutationVariables>(
    CreateKsAufteilungsschluesselDocument,
    options
  );
}
export type CreateKsAufteilungsschluesselMutationHookResult = ReturnType<typeof useCreateKsAufteilungsschluesselMutation>;
export type CreateKsAufteilungsschluesselMutationResult = Apollo.MutationResult<CreateKsAufteilungsschluesselMutation>;
export type CreateKsAufteilungsschluesselMutationOptions = Apollo.BaseMutationOptions<
  CreateKsAufteilungsschluesselMutation,
  CreateKsAufteilungsschluesselMutationVariables
>;
export const CreateFdAufteilungsschluesselDocument = gql`
  mutation createFDAufteilungsschluessel($firmendatenId: ID!, $input: AufteilungsschluesselCreateInput!) {
    createFDAufteilungsschluessel(firmendatenId: $firmendatenId, input: $input) {
      data {
        aufteilungsschluesselId
        bezeichnung
        bezugsbasis {
          text
          value
        }
        createTs
        createdBy
        createdByMitarbeiterId
        kurzBezeichnung
        masseinheit {
          text
          value
        }
        status {
          text
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateFdAufteilungsschluesselMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFdAufteilungsschluesselMutation, CreateFdAufteilungsschluesselMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFdAufteilungsschluesselMutation, CreateFdAufteilungsschluesselMutationVariables>(
    CreateFdAufteilungsschluesselDocument,
    options
  );
}
export type CreateFdAufteilungsschluesselMutationHookResult = ReturnType<typeof useCreateFdAufteilungsschluesselMutation>;
export type CreateFdAufteilungsschluesselMutationResult = Apollo.MutationResult<CreateFdAufteilungsschluesselMutation>;
export type CreateFdAufteilungsschluesselMutationOptions = Apollo.BaseMutationOptions<
  CreateFdAufteilungsschluesselMutation,
  CreateFdAufteilungsschluesselMutationVariables
>;
export const UpdateAufteilungsschluesselDocument = gql`
  mutation updateAufteilungsschluessel($input: AufteilungsschluesselUpdateInput!, $aufteilungsschluesselId: ID!) {
    updateAufteilungsschluessel(input: $input, aufteilungsschluesselId: $aufteilungsschluesselId) {
      data {
        aufteilungsschluesselId
        bezeichnung
        bezugsbasis {
          text
          value
        }
        createTs
        createdBy
        createdByMitarbeiterId
        kurzBezeichnung
        masseinheit {
          text
          value
        }
        status {
          text
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateAufteilungsschluesselMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAufteilungsschluesselMutation, UpdateAufteilungsschluesselMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAufteilungsschluesselMutation, UpdateAufteilungsschluesselMutationVariables>(
    UpdateAufteilungsschluesselDocument,
    options
  );
}
export type UpdateAufteilungsschluesselMutationHookResult = ReturnType<typeof useUpdateAufteilungsschluesselMutation>;
export type UpdateAufteilungsschluesselMutationResult = Apollo.MutationResult<UpdateAufteilungsschluesselMutation>;
export type UpdateAufteilungsschluesselMutationOptions = Apollo.BaseMutationOptions<
  UpdateAufteilungsschluesselMutation,
  UpdateAufteilungsschluesselMutationVariables
>;
export const DeleteAufteilungsschluesselDocument = gql`
  mutation deleteAufteilungsschluessel($aufteilungsschluesselId: ID!) {
    deleteAufteilungsschluessel(aufteilungsschluesselId: $aufteilungsschluesselId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteAufteilungsschluesselMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAufteilungsschluesselMutation, DeleteAufteilungsschluesselMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAufteilungsschluesselMutation, DeleteAufteilungsschluesselMutationVariables>(
    DeleteAufteilungsschluesselDocument,
    options
  );
}
export type DeleteAufteilungsschluesselMutationHookResult = ReturnType<typeof useDeleteAufteilungsschluesselMutation>;
export type DeleteAufteilungsschluesselMutationResult = Apollo.MutationResult<DeleteAufteilungsschluesselMutation>;
export type DeleteAufteilungsschluesselMutationOptions = Apollo.BaseMutationOptions<
  DeleteAufteilungsschluesselMutation,
  DeleteAufteilungsschluesselMutationVariables
>;
export const UnarchiveAufteilungsschluesselDocument = gql`
  mutation UnarchiveAufteilungsschluessel($aufteilungsschluesselId: ID!) {
    actionUnarchiveAufteilungsschluessel(aufteilungsschluesselId: $aufteilungsschluesselId) {
      data {
        aufteilungsschluesselId
        bezeichnung
        bezugsbasis {
          text
          value
        }
        createTs
        createdBy
        createdByMitarbeiterId
        kurzBezeichnung
        masseinheit {
          text
          value
        }
        status {
          text
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveAufteilungsschluesselMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveAufteilungsschluesselMutation, UnarchiveAufteilungsschluesselMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveAufteilungsschluesselMutation, UnarchiveAufteilungsschluesselMutationVariables>(
    UnarchiveAufteilungsschluesselDocument,
    options
  );
}
export type UnarchiveAufteilungsschluesselMutationHookResult = ReturnType<typeof useUnarchiveAufteilungsschluesselMutation>;
export type UnarchiveAufteilungsschluesselMutationResult = Apollo.MutationResult<UnarchiveAufteilungsschluesselMutation>;
export type UnarchiveAufteilungsschluesselMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveAufteilungsschluesselMutation,
  UnarchiveAufteilungsschluesselMutationVariables
>;
export const ArchiveAufteilungsschluesselDocument = gql`
  mutation ArchiveAufteilungsschluessel($aufteilungsschluesselId: ID!) {
    actionArchiveAufteilungsschluessel(aufteilungsschluesselId: $aufteilungsschluesselId) {
      data {
        aufteilungsschluesselId
        bezeichnung
        bezugsbasis {
          text
          value
        }
        createTs
        createdBy
        createdByMitarbeiterId
        kurzBezeichnung
        masseinheit {
          text
          value
        }
        status {
          text
          value
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveAufteilungsschluesselMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveAufteilungsschluesselMutation, ArchiveAufteilungsschluesselMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveAufteilungsschluesselMutation, ArchiveAufteilungsschluesselMutationVariables>(
    ArchiveAufteilungsschluesselDocument,
    options
  );
}
export type ArchiveAufteilungsschluesselMutationHookResult = ReturnType<typeof useArchiveAufteilungsschluesselMutation>;
export type ArchiveAufteilungsschluesselMutationResult = Apollo.MutationResult<ArchiveAufteilungsschluesselMutation>;
export type ArchiveAufteilungsschluesselMutationOptions = Apollo.BaseMutationOptions<
  ArchiveAufteilungsschluesselMutation,
  ArchiveAufteilungsschluesselMutationVariables
>;
