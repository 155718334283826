import { Route } from 'react-router';
import { URI_VERARBEITUNG_INCOMING_INVOICE_BOOKING_CREATION_DETAILS_PAGE } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import AuthRoute from '../../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import IncomingInvoiceBookingCreationDetailsPage from './IncomingInvoiceBookingCreationDetailsPage';

export type IncomingInvoiceBookingCreationPageRouteParams = { generierlaufId: string };

export const verarbeitungIncomingInvoiceBookingCreationDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_INCOMING_INVOICE_BOOKING_CREATION_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<IncomingInvoiceBookingCreationDetailsPage />} />}
  />
);
