import { AbrechnungsdefinitionParameterFormValues } from './abrDefParameterFormMapper';
import {
  AbrDefAbrKreisListingFormValues,
  mapFormValuesToAbrDefAbrKreisList,
} from '../../shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormMapper';
import { mapFormValuesToTextbaustein } from '../../shared/Templates/shared/abrDefTemplateFormMapper';
import {
  BkAbrechnungsdefinitionCreateInput,
  HeAbrechnungsdefinitionCreateInput,
  SubAdministrationAbrechnungsdefinitionCreateInput,
  WeAbrechnungsdefinitionCreateInput,
} from '../../../../types';
import { TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';
import { ObjAbrDefGeneralSettingsFormValues } from '../../shared/Templates/shared/BkOrWe/ObjAbrDefGeneralSettingsForm/objAbrDefGeneralSettingsFormMapper';
import { TopAbrDefGeneralSettingsFormValues } from '../../shared/Templates/shared/BkOrWe/TopAbrDefGeneralSettingsForm/topAbrDefGeneralSettingsFormMapper';
import { HeOrSubAbrDefGeneralSettingsFormValues } from '../../shared/Templates/shared/HeOrSub/GeneralSettingForm/heOrSubAbrDefGeneralSettingsFormMapper';

export const mapFormValuesToBkOrWeAbrechnungsdefinitionCreate = (
  parameterFormValues: AbrechnungsdefinitionParameterFormValues,
  abrKreisListFormValues: AbrDefAbrKreisListingFormValues,
  objektTemplateFormValues: TextbausteinFormValues[],
  topTemplateFormValues: TextbausteinFormValues[],
  validFromFormValue: string,
  objAbrDefGeneralSettingsValues: ObjAbrDefGeneralSettingsFormValues,
  topAbrDefGeneralSettingsValues: TopAbrDefGeneralSettingsFormValues
): WeAbrechnungsdefinitionCreateInput | BkAbrechnungsdefinitionCreateInput => ({
  abrechdefAbrechkreisList: mapFormValuesToAbrDefAbrKreisList(abrKreisListFormValues.abrechdefAbrechkreisList),
  bezeichnung: parameterFormValues.bezeichnung,
  validFrom: validFromFormValue,
  objektAbrechnungTextbausteinList: mapFormValuesToTextbaustein(objektTemplateFormValues),
  topAbrechnungTextbausteinList: mapFormValuesToTextbaustein(topTemplateFormValues),
  objektAbrechnungOutputOptions: objAbrDefGeneralSettingsValues,
  topAbrechnungOutputOptions: topAbrDefGeneralSettingsValues,
});

export const mapFormValuesToHeAbrechnungsdefinitionCreate = (
  parameterFormValues: AbrechnungsdefinitionParameterFormValues,
  abrKreisListFormValues: AbrDefAbrKreisListingFormValues,
  heTemplateFormValues: TextbausteinFormValues[],
  validFromFormValue: string,
  outputOptions: HeOrSubAbrDefGeneralSettingsFormValues
): HeAbrechnungsdefinitionCreateInput => ({
  abrechdefAbrechkreisList: mapFormValuesToAbrDefAbrKreisList(abrKreisListFormValues.abrechdefAbrechkreisList),
  bezeichnung: parameterFormValues.bezeichnung,
  validFrom: validFromFormValue,
  heAbrechnungTextbausteinList: mapFormValuesToTextbaustein(heTemplateFormValues),
  outputOptions,
});

export const mapFormValuesToSubAbrechnungsdefinitionCreate = (
  parameterFormValues: AbrechnungsdefinitionParameterFormValues,
  abrKreisListFormValues: AbrDefAbrKreisListingFormValues,
  heTemplateFormValues: TextbausteinFormValues[],
  validFromFormValue: string,
  outputOptions: HeOrSubAbrDefGeneralSettingsFormValues
): SubAdministrationAbrechnungsdefinitionCreateInput => ({
  abrechdefAbrechkreisList: mapFormValuesToAbrDefAbrKreisList(abrKreisListFormValues.abrechdefAbrechkreisList),
  bezeichnung: parameterFormValues.bezeichnung,
  validFrom: validFromFormValue,
  subAdministrationAbrechnungTextbausteinList: mapFormValuesToTextbaustein(heTemplateFormValues),
  outputOptions,
});
