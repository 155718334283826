import React, { FC } from 'react';
import { Space, Tag, Tooltip, Typography } from 'antd';
import theme from '../../../theme';
import DataWithStatus from '../../../components/Helpers/DataWithStatus';
import { AbrechnungsdefinitionType, Abrechnungskreis } from '../../../types';
import {
  isAbrKreisTypeBewirtschaftungskosten,
  isAbrKreisTypeRepFonds,
} from '../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import { repFondVerwendungText, ZuordnungKontoKlasseType, ZuordnungViewModel } from '../shared/AbrKreisKontenzuordnungHelpers';
import ZuordnungListItemInfosViewAndEdit from './ZuordnungListItemInfosViewAndEdit';
import { AbrechnungskreisNichtAbrechnen } from '../Form/zuordnungKontoListingFormMapper';
import { AbrKontenzuordnungFiltersFormValues } from '../Listing/Filters/abrKontenzuordnungFiltersMapper';
import AbrKreisOfVerrechnungTypeName from '../shared/AbrKreisOfVerrechnungTypeName';

type Props = {
  zuordnung: ZuordnungViewModel;
  kontoKlasseType: ZuordnungKontoKlasseType;
  abrDefinitionType: AbrechnungsdefinitionType;
  filters?: AbrKontenzuordnungFiltersFormValues | null;
};

const ZuordnungKontoItemContentViewAndEdit: FC<Props> = ({ zuordnung, kontoKlasseType, abrDefinitionType, filters }) => {
  if (zuordnung.abrechnungskreis) {
    return (
      <DataWithStatus status={zuordnung.abrechnungskreis.status} text="">
        {() => (
          <>
            <Tag>
              {(zuordnung.abrechnungskreis as Required<Abrechnungskreis>).bezeichnung}
              {isAbrKreisTypeRepFonds((zuordnung.abrechnungskreis as Required<Abrechnungskreis>).type.value) &&
                kontoKlasseType === ZuordnungKontoKlasseType.AUSGABEN && (
                  <Typography.Text style={{ color: theme.textColor.secondary }}>{repFondVerwendungText(zuordnung.ustVomAufwand)}</Typography.Text>
                )}
            </Tag>
            <ZuordnungListItemInfosViewAndEdit
              filters={filters}
              isAbrKreisRepFonds={isAbrKreisTypeRepFonds(zuordnung.abrechnungskreis?.type.value)}
              isUstVomAufwand={zuordnung.ustVomAufwand}
              buchungszeilenAnzeigen={zuordnung.buchungszeilenAnzeigen}
              aufteilungsschluesselBezeichnung={zuordnung.aufteilungsschluessel?.kurzBezeichnung}
              isExpenseReducing={zuordnung.expenseReducing}
              showExpenseReducing={
                isAbrKreisTypeBewirtschaftungskosten(zuordnung.abrechnungskreis?.type.value) && kontoKlasseType === ZuordnungKontoKlasseType.EINNAHMEN
              }
            />
          </>
        )}
      </DataWithStatus>
    );
  }
  if (zuordnung.abrechnungskreis === null) {
    return (
      <Space direction="vertical">
        <Tooltip title="Wird bei der Abrechnung nicht berücksichtigt">
          <Tag color="orange">{AbrechnungskreisNichtAbrechnen.text}</Tag>
        </Tooltip>
        {filters?.buchungszeilenAnzeigen && (
          <Typography.Text italic>Buchungszeile anzeigen: {zuordnung.buchungszeilenAnzeigen ? 'anzeigen' : 'ausblenden'}</Typography.Text>
        )}
      </Space>
    );
  }
  return <AbrKreisOfVerrechnungTypeName abrDefinitionType={abrDefinitionType} zuordnungKontoKlasseType={kontoKlasseType} />;
};

export default ZuordnungKontoItemContentViewAndEdit;
