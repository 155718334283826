import { FormikProps } from 'formik';
import {
  bookingSuggestionFormFields,
  BookingSuggestionFormValues,
  BSBuchungszeileForTable,
  mapBSClearingSuggestionToOPForClearingSuggestion,
} from '../../Form/buchungsmaskeFormMapper';
import { isKontoTypePersonenkonto } from '../../../../Kontenplan/Form/kontoFormHelpers';
import { isChosenKontoDifferentFromKontoFromBookingSuggestion } from '../../Form/buchungsmaskeFormHelpers';
import { BookingSuggestion } from '../../../../../types';

export const handleRowDelete = (
  index: number,
  record: BSBuchungszeileForTable,
  bookingSuggestion: BookingSuggestion,
  formikProps: FormikProps<BookingSuggestionFormValues>,
  onRowDelete: (index: number) => void
) => {
  // if the konto is a Personenkonto, check if there are 2 Personenkontos in the list.
  // if there are 2 Personenkontos, and the konto which will stay on the list is same as default konto from the booking suggestion initial data, then set the offenePostenForClearingSuggestionList to the default clearing suggestion list
  const personenkontoList = formikProps.values.buchungsanweisung.buchungszeileList.filter((bz) => isKontoTypePersonenkonto(bz.konto?.type.value));

  if (personenkontoList.length === 2) {
    const personenkontoDifferentFromDeletedKonto = personenkontoList.filter((bz) => bz.konto.kontoId !== record.konto.kontoId)[0].konto;
    !isChosenKontoDifferentFromKontoFromBookingSuggestion(
      personenkontoDifferentFromDeletedKonto.kontoId,
      bookingSuggestion.buchungsanweisung.buchungszeileList
    ) &&
      formikProps.setFieldValue(
        `${bookingSuggestionFormFields.offenePostenForClearingSuggestionList}`,
        bookingSuggestion.clearingSuggestionList.map((clearingSuggestion) => mapBSClearingSuggestionToOPForClearingSuggestion(clearingSuggestion))
      );
  }

  // delete the row from the list
  onRowDelete(index);
};
