import Maybe from '../../../Maybe';
import { DevListDataType } from '../../Verarbeitung/DescriptionDevLog';
import { AuftragsPosition } from '../../../types';

export const generateVOPDetailSections = (auftragVOPDetailSections?: AuftragsPosition): DevListDataType => {
  if (!auftragVOPDetailSections) {
    return [];
  }

  return [
    {
      title: 'Attribute Vorschreibungsposition',
      descriptionItemList: [
        {
          title: 'Vorschreibungsposition (von diesen Daten)',
          content: auftragVOPDetailSections.vorschreibungspositionBezeichnung,
        },
        { title: 'VOP-ID', content: auftragVOPDetailSections.auftragsPositionId },
        { title: 'GenerierDat (von diesen Daten)', content: auftragVOPDetailSections.generierDatum },
        { title: 'GenerierTS (von diesen Daten)', content: auftragVOPDetailSections.createTs },
        { title: 'GenerierUser (von diesen Daten)', content: auftragVOPDetailSections.createdBy },
        { title: 'MitarbeiterID (von diesen Daten)', content: auftragVOPDetailSections.createdByMitarbeiterId },
        { title: 'GenerierLaufID (von diesen Daten)', content: auftragVOPDetailSections.generierlaufId },
        { title: 'OriginID (von diesen Daten)', content: auftragVOPDetailSections.originId },
        { title: 'Letzte Änderung Am', content: null },
        {
          title: auftragVOPDetailSections.objektId,
          content: Maybe.fromValue(auftragVOPDetailSections.objekt).map((objekt) => objekt.kurzBezeichnung),
        },
        {
          title: auftragVOPDetailSections.bestandseinheitId,
          content: Maybe.fromValue(auftragVOPDetailSections.bestandseinheit).map((bestandseinheit) => bestandseinheit.bezeichnung),
        },
        {
          title: 'BeVertragId',
          content: auftragVOPDetailSections.vertragId,
        },
        {
          title: 'AuftragsPositionId',
          content: auftragVOPDetailSections.auftragsPositionId,
        },
        { title: 'VS-Pos_KurzBez', content: auftragVOPDetailSections.bezeichnung },
        { title: 'VS-Betrag Steuersatz', content: auftragVOPDetailSections.steuersatz },
        { title: 'VS-Betrag_netto', content: auftragVOPDetailSections.netto.toString() },
        { title: 'VS-Betrag_UST-Satz', content: auftragVOPDetailSections.ust.toString() },
        { title: 'VS-Betrag_brutto', content: auftragVOPDetailSections.brutto.toString() },
        { title: 'VS-Betrag Leistungs-Zeitraum_von', content: auftragVOPDetailSections.performancePeriodFromInclusive },
        { title: 'VS-Betrag Leistungs-Zeitraum_bis', content: auftragVOPDetailSections.performancePeriodToInclusive },
        {
          title: '',
          content: Maybe.fromValue(auftragVOPDetailSections.labelList)
            .valueOr([])
            .filter((label) => label.label !== 'bestandseinheitId')
            .filter((label) => label.label !== 'objektId')
            .filter((label) => label.label !== 'vorschreibungspositionId')
            .flatMap((label, index) => ({
              title: 'labelList, index: ' + index,
              descriptionItemList: [
                { title: 'label', content: label.label },
                { title: 'valueCountMap', content: label.value },
              ],
            })),
        },
      ],
    },
  ];
};
