import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Spacer } from '../../../../../components/Grid';
import FormButtons from '../../../../../components/Button/FormButtons';
import { auftragsartTypeSelectFormValidationSchema } from './auftragsartTypeSelectFormValidationSchema';
import { AuftragsartType } from '../../../../../types';
import AuftragsartTypeSelect from '../../../shared/AuftragsartTypeSelect';
import { StyledWizardSpaceForContent } from '../../../../shared/styled/WizardSpace.style';
import ParamFormItem from '../../../../../components/Form/ParamFormItem';

type Props = {
  auftragsartTypeValue: AuftragsartType | undefined;
  onSubmit: (formValues: AuftragsartType | undefined) => void;
  onCancel: () => void;
};

const AuftragsartTypeSelectForm: FC<Props> = ({ auftragsartTypeValue, onSubmit, onCancel }) => (
  <>
    <Formik<{ type: AuftragsartType | undefined }>
      initialValues={{ type: auftragsartTypeValue }}
      validationSchema={auftragsartTypeSelectFormValidationSchema}
      onSubmit={(formValues) => onSubmit(formValues.type)}
    >
      {(formikProps) => (
        <Form>
          <StyledWizardSpaceForContent direction="vertical">
            <ParamFormItem name="type" label="Auftragsart-Typ">
              <AuftragsartTypeSelect name="type" id="type" size="small" />
            </ParamFormItem>
          </StyledWizardSpaceForContent>
          <Spacer height={48} />
          <FormButtons onCancel={onCancel} updateMode={false} okText="Weiter" isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  </>
);

export default AuftragsartTypeSelectForm;
