import { generatePath } from 'react-router';
import { mapToQueryString, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { uriToDataCarrierDebitCardOrBankWire } from '../../../uriDataCarrierPaths';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { FiltersFormValues } from './filtersFormMapper';

export enum QueryParamKey {
  DATA_CARRIER_TYPE = 'dataCarrierType',
  NAME = 'name',
  PAYMENT_PROPOSAL_NAME = 'paymentProposalName',
  PAYMENT_TRANSACTION_TYPE = 'paymentTransactionType',
  IBAN = 'iban',
  CREATE_TS_FROM = 'createTsFrom',
  CREATE_TS_TO = 'createTsTo',
  CREATED_BY_MITARBEITER_ID_LIST = 'createdByMitarbeiterIdList',
  STATUS = 'status',
}

export type TDirectDebitOrBankWireQueryParams = QueryParams<FiltersFormValues>;

export const updateQueryParams = (
  navigate: (path: string) => void,
  filters: TDirectDebitOrBankWireQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToDirectDebitOrBankWireListingPage(filters, paginationParams));

export const generatePathToDirectDebitOrBankWireListingPage = (
  filters: TDirectDebitOrBankWireQueryParams,
  paginationParams?: PaginationQueryParamsProps
) =>
  `${generatePath(uriToDataCarrierDebitCardOrBankWire)}?${mapToQueryString({
    [QueryParamKey.DATA_CARRIER_TYPE]: filters?.dataCarrierType,
    [QueryParamKey.NAME]: filters?.name,
    [QueryParamKey.PAYMENT_PROPOSAL_NAME]: filters?.paymentProposalName,
    [QueryParamKey.PAYMENT_TRANSACTION_TYPE]: filters?.paymentTransactionType,
    [QueryParamKey.IBAN]: filters?.iban,
    [QueryParamKey.CREATE_TS_FROM]: filters?.createTsFrom,
    [QueryParamKey.CREATE_TS_TO]: filters?.createTsTo,
    [QueryParamKey.CREATED_BY_MITARBEITER_ID_LIST]: filters?.createdByMitarbeiterIdList,
    [QueryParamKey.STATUS]: filters?.status,
    ...paginationParams,
  })}`;
