import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import BookingSuggestionBuchungsmaskeWithTabsPage from './BookingSuggestionBuchungsmaskeWithTabsPage';
import { URI_DASHBOARD_BOOKING_SUGGESTION_BUCHUNGSMASKE_WITH_TABS_PAGE } from '../../features/BookingSuggestion/bookingSuggestionBuchungsmaskePaths';

export type BookingSuggestionBuchnungsmaskeWithTabsPageRouteParams = { receivedBankTransactionId: string };

const bookingSuggestionBuchungsmaskeWithTabsPageRoute = (
  <Route
    path={URI_DASHBOARD_BOOKING_SUGGESTION_BUCHUNGSMASKE_WITH_TABS_PAGE}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<BookingSuggestionBuchungsmaskeWithTabsPage />} />}
  />
);

export default bookingSuggestionBuchungsmaskeWithTabsPageRoute;
