import React, { FC } from 'react';
import { Button, Tooltip } from 'antd';
import { CheckCircleOutlined, WarningTwoTone } from '@ant-design/icons';
import theme from '../../../../../theme';

type Props = {
  isTextbausteinComplete: boolean;
  title: string;
  onClick: React.MouseEventHandler<HTMLElement>;
  isVariant?: boolean;
  warningText?: string;
};

export const TextbausteinCollapsePanelHeader: FC<Props> = ({ isTextbausteinComplete, title, isVariant, onClick, warningText }) => (
  <Button block onClick={onClick}>
    {isTextbausteinComplete ? <CheckCircleOutlined /> : <WarningWithTooltip isVariant={isVariant} warningText={warningText} />}
    {title}
  </Button>
);

type WarningWithTooltipProps = {
  isVariant?: boolean;
  warningText?: string;
};

const WarningWithTooltip: FC<WarningWithTooltipProps> = ({ isVariant, warningText }) => {
  const title = warningText || `Sie müssen  ${isVariant ? 'die Variante' : 'den Textbaustein'} noch befüllen`;

  return (
    <Tooltip title={title}>
      <WarningTwoTone style={{ cursor: 'help' }} twoToneColor={theme.colors.warning} />
    </Tooltip>
  );
};
