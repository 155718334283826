import React from 'react';
import { Tag } from 'antd';
import { ErrechnetesErloesKonto } from '../../../../types';
import FirmendatenKurzBezeichnung from '../../../shared/FirmendatenKurzBezeichnung';
import { NestedTableWithColSelectorColProps } from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';

const erloesKontoTableColumns: NestedTableWithColSelectorColProps<ErrechnetesErloesKonto>[] = [
  {
    title: 'USt %',
    key: 'bezeichnung',
    width: 200,
    render: (value, record) => record.steuersatz,
    defaultSelected: true,
  },
  {
    title: 'Erlöskonto',
    key: 'basiskonto',
    defaultSelected: true,
    render: (value, record) => <Tag>{`S${record.erloeskonto}`}</Tag>,
  },
  {
    title: 'Firma',
    key: 'firma',
    defaultSelected: true,
    render: (text, record) => <>{record.firmendatenId ? <FirmendatenKurzBezeichnung firmendatenId={record.firmendatenId} /> : <Tag>System</Tag>}</>,
  },
  {
    title: 'Buchungskennzeichen',
    key: 'aufwandskonto',
    width: 215,
    defaultSelected: true,
    render: (value, record) => record.buchungsKZ ?? '',
  },
  {
    title: '',
  },
  {
    title: '',
    key: 'erstelltAm',
  },
  {
    title: '',
    key: 'ersteller',
  },
  {
    title: '',
    key: 'action',
  },
];

export default erloesKontoTableColumns;
