import { Dayjs } from 'dayjs';
import { FormFields } from '../../../../../../../../../helpers/formikHelper';
import {
  ValuePreservationType,
  VertragVposIndexationAgreementCategory,
  VertragVposIndexationAgreementCategoryCreateInput,
  VertragVposIndexationAgreementCategoryUpdateInput,
} from '../../../../../../../../../types';
import { mapDateFormValueToMonthYear, mapMonthYearToDateFormat } from '../indexationAgreementHelpers';

export interface IndexationAgreementCategoryFormValues {
  basedOnUsableArea: boolean;
  indexOld?: number | null;
  indexSeriesId: string;
  monthYearOld: Dayjs | string;
  type: ValuePreservationType;
  validFrom: Dayjs | string;
}

export const indAgreeCategoryFormFields: FormFields<IndexationAgreementCategoryFormValues> = {
  basedOnUsableArea: 'basedOnUsableArea',
  indexOld: 'indexOld',
  indexSeriesId: 'indexSeriesId',
  monthYearOld: 'monthYearOld',
  type: 'type',
  validFrom: 'validFrom',
};

export const categoryFormInitialValues: IndexationAgreementCategoryFormValues = {
  basedOnUsableArea: false,
  indexOld: null,
  indexSeriesId: '',
  monthYearOld: '',
  type: ValuePreservationType.ReferenceValue,
  validFrom: '',
};

export const mapCategoryToFormValues = (referenceValue?: VertragVposIndexationAgreementCategory): IndexationAgreementCategoryFormValues => {
  if (!referenceValue) return categoryFormInitialValues;
  return {
    basedOnUsableArea: referenceValue.basedOnUsableArea,
    indexOld: referenceValue.indexOld,
    indexSeriesId: referenceValue.indexSeries?.indexSeriesId,
    monthYearOld: mapMonthYearToDateFormat(referenceValue.monthYearOld),
    type: ValuePreservationType.ReferenceValue,
    validFrom: mapMonthYearToDateFormat(referenceValue.validFrom),
  };
};

export const mapFormValuesToCategory = (
  formValues: IndexationAgreementCategoryFormValues
): VertragVposIndexationAgreementCategoryCreateInput | VertragVposIndexationAgreementCategoryUpdateInput => ({
  basedOnUsableArea: formValues.basedOnUsableArea,
  indexOld: formValues.indexOld,
  indexSeriesId: formValues.indexSeriesId,
  monthYearOld: mapDateFormValueToMonthYear(formValues.monthYearOld),
  type: ValuePreservationType.Category,
  validFrom: mapDateFormValueToMonthYear(formValues.validFrom),
});
