import { Space } from 'antd';
import styled from 'styled-components';

export const StyledSpace = styled(Space)`
  width: 100%;
  > .ant-space-item {
    display: flex;
    justify-content: flex-start;
    width: 50%;
  }
`;
