import React, { FC } from 'react';
import TableWithAlignedCols from '../../../components/Table/TableWithAlignedCols/TableWithAlignedCols';
import vstKuerzungDetailsTableColumns from './vstKuerzungDetailsTableColumns';
import vstKuerzungKontoRow from './vstKuerzungKontoRow';
import { Vorsteuerkuerzung, VorsteuerkuerzungVertrag } from '../../../types';

type Props = {
  vstKuerzung: Vorsteuerkuerzung;
};

const VstKuerzungDetailsTable: FC<Props> = ({ vstKuerzung }) => (
  <TableWithAlignedCols<VorsteuerkuerzungVertrag>
    dataSource={vstKuerzung.vorsteuerkuerzungVertragList}
    columns={vstKuerzungDetailsTableColumns(vstKuerzung.objekt.objektId, vstKuerzung.objekt.rechnungsAussteller.rechtstraegerId)}
    expandedRow={() =>
      vstKuerzungKontoRow(
        vstKuerzung.objekt.objektId,
        vstKuerzung.objekt.rechnungsAussteller.rechtstraegerId,
        vstKuerzung.vonInklusive,
        vstKuerzung.bisInklusive
      )
    }
    rowKey={(data) => data.bestandseinheit.bestandseinheitId}
  />
);

export default VstKuerzungDetailsTable;
