import React from 'react';
import { useShowArchivedQueryParamState } from '../../hooks/useShowArchivedQueryParamState';
import { useAbrechnungskreisTemplateListQuery } from './gql/AbrechnungskreisTemplateQueries.types';
import AbrechnungskreisTemplateButtonsAndDrawer from './AbrechnungskreisTemplateButtonsAndDrawer';
import AbrechnungskreisTemplateTable from './Table/AbrechnungskreisTemplateTable';

const AbrechnungskreisTemplateListing = () => {
  const [showArchived, toggleShowArchived] = useShowArchivedQueryParamState();

  const { data, loading, refetch } = useAbrechnungskreisTemplateListQuery({
    variables: { withDetails: true, includeArchiviert: showArchived },
  });
  const abrechnungskreisList = data?.getAbrechnungskreisTemplateList.data;

  return (
    <>
      <AbrechnungskreisTemplateButtonsAndDrawer refetch={refetch} showArchived={showArchived} toggleShowArchived={toggleShowArchived} />
      <AbrechnungskreisTemplateTable loading={loading} abrechnungskreisList={abrechnungskreisList} refetch={refetch} />
    </>
  );
};

export default AbrechnungskreisTemplateListing;
