import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { isStatusArchivedOrGesperrt, IStatus } from '../../helpers/statusHelper';
import DataWithStatus, { DataWithStatusProps } from '../Helpers/DataWithStatus';
import usePermission from '../../shared/Auth/usePermission';
import theme from '../../theme';

type Props = {
  /** Should be set to true if the link appears in a listing and points to the details of the list entry which it belongs to */
  linkToSelfDetails?: boolean;
  openInNewTab?: boolean;
  /** In some cases the text should be a link only for admins because only they are permitted to see the linked page  */
  isLinkOnlyForAdmin?: boolean;
  caseSpecificLinkColor?: string;
} & Omit<DataWithStatusProps, 'children'> &
  LinkProps;

/**
 * <h2>Usage</h2>
 * For links which have to be status-aware meaning the entity which the link points to, can have different states
 * and the appearance of the link indicates this state.
 *
 * The component is also aware of the role of the logged-in user, and can be configured to show the text as link only for admins and
 * sysadmins but as normal text for normal employees.
 *
 * It uses internally the DataWithStatus component, so it has all its features as well.
 */
const LinkWithStatus: FC<Props> = ({
  status,
  text,
  maxTextLength,
  alternativeText,
  linkToSelfDetails = false,
  openInNewTab = false,
  isLinkOnlyForAdmin = false,
  showBadgeDot,
  showStatusDescription,
  badgeOffset,
  to,
  children,
  caseSpecificLinkColor,
  tooltipFooter,
}) => {
  const { isAdminOrHasSystemAdminFm } = usePermission();

  return isLinkOnlyForAdmin && !isAdminOrHasSystemAdminFm ? (
    <DataWithStatus
      status={status}
      text={text}
      maxTextLength={maxTextLength}
      alternativeText={alternativeText}
      showBadgeDot={showBadgeDot}
      showStatusDescription={showStatusDescription}
      badgeOffset={badgeOffset}
      tooltipFooter={tooltipFooter}
    >
      {(shortenedText) => children ?? shortenedText}
    </DataWithStatus>
  ) : (
    <DataWithStatus
      text={text}
      maxTextLength={maxTextLength}
      status={status}
      showBadgeDot={showBadgeDot}
      showStatusDescription={showStatusDescription}
      alternativeText={alternativeText}
      badgeOffset={badgeOffset}
      tooltipFooter={tooltipFooter}
    >
      {(shortenedText) => (
        <Link to={to} style={{ color: caseSpecificLinkColor ?? linkColor(status, linkToSelfDetails) }} target={openInNewTab ? '_blank' : '_self'}>
          {/*<Link to={to} style={{ color: linkColor(status, linkToSelfDetails) }} target={openInNewTab ? '_blank' : '_self'}>*/}
          {children ?? shortenedText}
        </Link>
      )}
    </DataWithStatus>
  );
};

const linkColor = (status: IStatus, linkToSelfDetails?: boolean) => {
  if (isStatusArchivedOrGesperrt(status)) {
    return theme.linkColor.secondary;
  }
  if (linkToSelfDetails) {
    return theme.linkColor.primary;
  }
  return undefined;
};

export default LinkWithStatus;
