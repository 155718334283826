import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AufteilungsschluesselFieldsFragmentDoc } from '../../../Aufteilungsschluessel/gql/AufteilungsschluesselFragments.types';
import { KontoAbrechnungskreisFieldsFragmentDoc } from '../AbrDefFragments.types';
import { TextbausteinFieldsFragmentDoc } from '../../../../components/Template/PDFTemplates/gql/TemplateFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateWeAbrechnungsdefinitionVersionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  request: Types.WeAbrechnungsdefinitionVersionCreateInput;
}>;

export type CreateWeAbrechnungsdefinitionVersionMutation = {
  createWeAbrechnungsdefinitionVersion: {
    data: {
      abrechnungsdefinitionVersionId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      validFrom: string;
      kommentar?: string | null;
      abrechdefAbrechkreisList: Array<{
        abrechnungskreis: { abrechnungskreisId: string };
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vorschreibungsposition?: { vorschreibungspositionId: string } | null;
      }>;
      kontoAbrechnungskreisList: Array<{
        abrechnen: boolean;
        buchungszeilenAnzeigen: boolean;
        expenseReducing: boolean;
        ustVomAufwand: boolean;
        abrechnungskreis?: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
      objektAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      topAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateWeAbrechnungsdefinitionVersionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Types.Scalars['ID']['input'];
  request: Types.WeAbrechnungsdefinitionVersionUpdateInput;
}>;

export type UpdateWeAbrechnungsdefinitionVersionMutation = {
  updateWeAbrechnungsdefinitionVersion: {
    data: {
      abrechnungsdefinitionVersionId: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      validFrom: string;
      kommentar?: string | null;
      abrechdefAbrechkreisList: Array<{
        abrechnungskreis: { abrechnungskreisId: string };
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        vorschreibungsposition?: { vorschreibungspositionId: string } | null;
      }>;
      kontoAbrechnungskreisList: Array<{
        abrechnen: boolean;
        buchungszeilenAnzeigen: boolean;
        expenseReducing: boolean;
        ustVomAufwand: boolean;
        abrechnungskreis?: {
          abrechnungskreisId: string;
          bezeichnung: string;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          updatedByMitarbeiterId?: string | null;
          status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
          type: { text: string; value: Types.AbrechnungskreisType };
          verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        aufteilungsschluessel?: {
          aufteilungsschluesselId: string;
          bezeichnung: string;
          bezugsbasisEditable?: boolean | null;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          firmendatenId?: string | null;
          kurzBezeichnung: string;
          updatedByMitarbeiterId?: string | null;
          bezugsbasis: { text: string; value: Types.Bezugsbasis };
          masseinheit: { text: string; value: Types.Masseinheit };
          status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        konto: { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };
      }>;
      objektAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      topAbrechnungTextbausteinList: Array<{
        index: number;
        value?: string | null;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
          textbausteinList: Array<{
            index: number;
            value?: string | null;
            platzhalterList: Array<{ example: string; platzhalter: string }>;
            type: { text: string; value: Types.TextbausteinType };
          }>;
        }>;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteWeAbrechnungsdefinitionVersionMutationVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Types.Scalars['ID']['input'];
}>;

export type DeleteWeAbrechnungsdefinitionVersionMutation = {
  deleteWeAbrechnungsdefinitionVersion: {
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateWeAbrechnungsdefinitionVersionDocument = gql`
  mutation CreateWeAbrechnungsdefinitionVersion($abrechnungsdefinitionId: ID!, $request: WeAbrechnungsdefinitionVersionCreateInput!) {
    createWeAbrechnungsdefinitionVersion(abrechnungsdefinitionId: $abrechnungsdefinitionId, input: $request) {
      data {
        abrechdefAbrechkreisList {
          abrechnungskreis {
            abrechnungskreisId
          }
          aufteilungsschluessel {
            ...AufteilungsschluesselFields
          }
          vorschreibungsposition {
            vorschreibungspositionId
          }
        }
        abrechnungsdefinitionVersionId
        createTs
        createdBy
        createdByMitarbeiterId
        current
        deletable
        validFrom
        kommentar
        kontoAbrechnungskreisList {
          ...KontoAbrechnungskreisFields
        }
        objektAbrechnungTextbausteinList {
          ...TextbausteinFields
          textbausteinList {
            ...TextbausteinFields
          }
        }
        topAbrechnungTextbausteinList {
          ...TextbausteinFields
          textbausteinList {
            ...TextbausteinFields
          }
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
  ${KontoAbrechnungskreisFieldsFragmentDoc}
  ${TextbausteinFieldsFragmentDoc}
`;
export function useCreateWeAbrechnungsdefinitionVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateWeAbrechnungsdefinitionVersionMutation, CreateWeAbrechnungsdefinitionVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateWeAbrechnungsdefinitionVersionMutation, CreateWeAbrechnungsdefinitionVersionMutationVariables>(
    CreateWeAbrechnungsdefinitionVersionDocument,
    options
  );
}
export type CreateWeAbrechnungsdefinitionVersionMutationHookResult = ReturnType<typeof useCreateWeAbrechnungsdefinitionVersionMutation>;
export type CreateWeAbrechnungsdefinitionVersionMutationResult = Apollo.MutationResult<CreateWeAbrechnungsdefinitionVersionMutation>;
export type CreateWeAbrechnungsdefinitionVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateWeAbrechnungsdefinitionVersionMutation,
  CreateWeAbrechnungsdefinitionVersionMutationVariables
>;
export const UpdateWeAbrechnungsdefinitionVersionDocument = gql`
  mutation UpdateWeAbrechnungsdefinitionVersion(
    $abrechnungsdefinitionId: ID!
    $abrechnungsdefinitionVersionId: ID!
    $request: WeAbrechnungsdefinitionVersionUpdateInput!
  ) {
    updateWeAbrechnungsdefinitionVersion(
      abrechnungsdefinitionId: $abrechnungsdefinitionId
      abrechnungsdefinitionVersionId: $abrechnungsdefinitionVersionId
      input: $request
    ) {
      data {
        abrechdefAbrechkreisList {
          abrechnungskreis {
            abrechnungskreisId
          }
          aufteilungsschluessel {
            ...AufteilungsschluesselFields
          }
          vorschreibungsposition {
            vorschreibungspositionId
          }
        }
        abrechnungsdefinitionVersionId
        createTs
        createdBy
        createdByMitarbeiterId
        current
        deletable
        validFrom
        kommentar
        kontoAbrechnungskreisList {
          ...KontoAbrechnungskreisFields
        }
        objektAbrechnungTextbausteinList {
          ...TextbausteinFields
          textbausteinList {
            ...TextbausteinFields
          }
        }
        topAbrechnungTextbausteinList {
          ...TextbausteinFields
          textbausteinList {
            ...TextbausteinFields
          }
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AufteilungsschluesselFieldsFragmentDoc}
  ${KontoAbrechnungskreisFieldsFragmentDoc}
  ${TextbausteinFieldsFragmentDoc}
`;
export function useUpdateWeAbrechnungsdefinitionVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateWeAbrechnungsdefinitionVersionMutation, UpdateWeAbrechnungsdefinitionVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWeAbrechnungsdefinitionVersionMutation, UpdateWeAbrechnungsdefinitionVersionMutationVariables>(
    UpdateWeAbrechnungsdefinitionVersionDocument,
    options
  );
}
export type UpdateWeAbrechnungsdefinitionVersionMutationHookResult = ReturnType<typeof useUpdateWeAbrechnungsdefinitionVersionMutation>;
export type UpdateWeAbrechnungsdefinitionVersionMutationResult = Apollo.MutationResult<UpdateWeAbrechnungsdefinitionVersionMutation>;
export type UpdateWeAbrechnungsdefinitionVersionMutationOptions = Apollo.BaseMutationOptions<
  UpdateWeAbrechnungsdefinitionVersionMutation,
  UpdateWeAbrechnungsdefinitionVersionMutationVariables
>;
export const DeleteWeAbrechnungsdefinitionVersionDocument = gql`
  mutation DeleteWeAbrechnungsdefinitionVersion($abrechnungsdefinitionId: ID!, $abrechnungsdefinitionVersionId: ID!) {
    deleteWeAbrechnungsdefinitionVersion(
      abrechnungsdefinitionId: $abrechnungsdefinitionId
      abrechnungsdefinitionVersionId: $abrechnungsdefinitionVersionId
    ) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteWeAbrechnungsdefinitionVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteWeAbrechnungsdefinitionVersionMutation, DeleteWeAbrechnungsdefinitionVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteWeAbrechnungsdefinitionVersionMutation, DeleteWeAbrechnungsdefinitionVersionMutationVariables>(
    DeleteWeAbrechnungsdefinitionVersionDocument,
    options
  );
}
export type DeleteWeAbrechnungsdefinitionVersionMutationHookResult = ReturnType<typeof useDeleteWeAbrechnungsdefinitionVersionMutation>;
export type DeleteWeAbrechnungsdefinitionVersionMutationResult = Apollo.MutationResult<DeleteWeAbrechnungsdefinitionVersionMutation>;
export type DeleteWeAbrechnungsdefinitionVersionMutationOptions = Apollo.BaseMutationOptions<
  DeleteWeAbrechnungsdefinitionVersionMutation,
  DeleteWeAbrechnungsdefinitionVersionMutationVariables
>;
