import React, { FC } from 'react';
import { Typography } from 'antd';
import { FibuBelegSymbol, FibuBuchungszeile } from '../../../../../types';
import { Spacer } from '../../../../../components/Grid';
import BuchungszeileListing from './Listing/BuchungszeileListing';

type Props = {
  belegId?: string | null;
  belegSymbol: FibuBelegSymbol;
  buchungszeileList: FibuBuchungszeile[];
  hasOnlyOneBuchung: boolean;
  includeDeviations: boolean;
  onAction: () => void;
};

const BuchungszeileListBlock: FC<Props> = ({ belegId, belegSymbol, buchungszeileList, hasOnlyOneBuchung, includeDeviations, onAction }) => {
  return (
    <>
      <Typography.Title level={5}>Buchung</Typography.Title>
      <Spacer height={16} />
      {/* Listing with: filters, buchungszeile table with/without Umbuchungen entries and marked deviations entries, buchungszeile related konto details cards, and deviations table */}
      <BuchungszeileListing
        buchungszeileList={buchungszeileList}
        belegId={belegId}
        belegSymbol={belegSymbol}
        hasOnlyOneBuchung={hasOnlyOneBuchung}
        includeDeviations={includeDeviations}
        onAction={onAction}
      />
    </>
  );
};

export default BuchungszeileListBlock;
