import React from 'react';
import { Button, Space, Typography, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { FormikProps } from 'formik';
import FileUploadModal from './FileUploadModal';
import { useToggle } from '../../hooks/useToggle';
import usePermission from '../../shared/Auth/usePermission';
import { FileInfo } from '../../types';

type Props<T> = {
  formikProps: FormikProps<T>;
  name: string;
  validateFile: (file: ValidateFileProps) => boolean;
  onSelectFileSetFieldValue: (data: FileInfo) => void;
  disabled?: boolean;
  onBeforeUpload?: () => void;
};

export type ValidateFileProps = {
  name: string;
  type: string;
  size: number;
};

function FileUploadArea<T>({ formikProps, name, validateFile, onSelectFileSetFieldValue, disabled, onBeforeUpload }: Props<T>) {
  const [isFileModalOpen, toggleFileModal] = useToggle();
  const { isAdmin } = usePermission();

  const onSelectFile = (data: FileInfo) => {
    toggleFileModal();
    onSelectFileSetFieldValue(data);
  };

  return (
    <>
      <Space>
        <Upload
          beforeUpload={(file) => {
            formikProps.setFieldTouched(name, true, false);
            if (validateFile(file)) {
              formikProps.setFieldValue(name, { filename: file.name, file, isUpload: true });
              onBeforeUpload?.();
            }
            return false;
          }}
          showUploadList={false}
        >
          <Button icon={<UploadOutlined />} disabled={disabled}>
            Datei hochladen
          </Button>
        </Upload>
        {!isAdmin && (
          <>
            <Typography.Text>oder</Typography.Text>
            <Button onClick={toggleFileModal} disabled={disabled}>
              Datei auswählen
            </Button>
          </>
        )}
      </Space>
      <FileUploadModal open={isFileModalOpen} onCancel={toggleFileModal} onSelectFile={onSelectFile} />
    </>
  );
}

export default FileUploadArea;
