import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, MenuProps, Modal } from 'antd';
import { DeleteOutlined, DownOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { ArchiveIcon } from '@radix-ui/react-icons';
import { showSuccessMsgArchived, showSuccessMsgDelete, showSuccessMsgUnarchived } from '../../../components/message';
import { generatePathToAuftragsartUpdatePage } from '../auftragsartUriPaths';
import { useArchiveAuftragsartMutation, useDeleteAuftragsartMutation, useUnarchiveAuftragsartMutation } from '../gql/AuftragsartMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { radixActionStyles } from '../../../helpers/radixIconsStyles';
import { isStatusArchived } from '../../../helpers/statusHelper';
import { AuftragsartBase } from '../../../types';
import { useToggle } from '../../../hooks/useToggle';
import AuftragsartChangeHistoryModal from '../ChangeHistory/AuftragsartChangeHistoryModal';

type Props = {
  auftragsart: AuftragsartBase;
  onAction: () => void;
};

const AuftragsartTableActions: FC<Props> = ({ auftragsart, onAction }) => {
  const entity = `Auftragsart`;
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const isArchived = isStatusArchived(auftragsart.status);

  const [runArchive] = useArchiveAuftragsartMutation({
    variables: {
      auftragsartId: auftragsart.auftragsartId,
    },
    onCompleted: () => {
      showSuccessMsgArchived(entity);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });
  const [runUnarchive] = useUnarchiveAuftragsartMutation({
    variables: {
      auftragsartId: auftragsart.auftragsartId,
    },
    onCompleted: () => {
      showSuccessMsgUnarchived(entity);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runDelete] = useDeleteAuftragsartMutation({
    variables: {
      auftragsartId: auftragsart.auftragsartId,
    },
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <Link to={generatePathToAuftragsartUpdatePage(auftragsart.auftragsartId)}>Bearbeiten</Link>,
      icon: <EditOutlined />,
    },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '3',
      label: isArchived ? 'Reaktivieren' : 'Archivieren',
      disabled: !!auftragsart.deletable,
      onClick: isArchived ? showConfirmUnarchive(runUnarchive, auftragsart) : showConfirmArchive(runArchive, auftragsart),
      icon: <ArchiveIcon style={radixActionStyles} />,
    },
    {
      key: '4',
      label: 'Löschen',
      disabled: !auftragsart.deletable,
      onClick: showConfirmDelete(runDelete, auftragsart),
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Aktion <DownOutlined />
        </a>
      </Dropdown>
      <AuftragsartChangeHistoryModal auftragsartId={auftragsart.auftragsartId} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen} />
    </>
  );
};

const showConfirmArchive = (runArchive: () => void, auftragsart: AuftragsartBase) => () => {
  Modal.confirm({
    title: `Möchten Sie die Auftragsart archivieren?`,
    content: auftragsart.bezeichnung,
    okText: 'Archivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runArchive();
    },
  });
};

const showConfirmUnarchive = (runUnarchive: () => void, auftragsart: AuftragsartBase) => () => {
  Modal.confirm({
    title: `Möchten Sie die Auftragsart reaktivieren?`,
    content: auftragsart.bezeichnung,
    okText: 'Reaktivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runUnarchive();
    },
  });
};

const showConfirmDelete = (runDelete: () => void, auftragsart: AuftragsartBase) => () => {
  Modal.confirm({
    title: `Möchten Sie die Auftragsart löschen?`,
    content: auftragsart.bezeichnung,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default AuftragsartTableActions;
