import { useParams } from 'react-router-dom';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../shared/dataChangedSubscription';
import { useFDAuthorized } from '../../shared/Auth/useAuthorized';
import { useAuth } from '../../shared/Auth/authContext';
import { DataChangedChangeType } from '../../types';
import { ZinslisteDetailsPageRouteParams } from '../../pages/ZinslisteDetailsPage/routes';

const useOnZinslisteDataChangedEvents = (key: string, callback: () => Promise<unknown>, zinslisteIds?: string[]) => {
  const { user } = useFDAuthorized();
  const { mitarbeiter } = useAuth();
  const { objektId, zinslisteId } = useParams() as ZinslisteDetailsPageRouteParams;

  useOnDataChangedEvent([
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.zinsliste,
        params: {
          zinslisteId: zinslisteIds || zinslisteId,
        },
        changeType: DataChangedChangeType.Update,
      },
      notificationDescription: 'Zinsliste wurde gerade verändert',
      key: `${key}-update`,
      callback,
    },
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.zinsliste,
        params: {
          objektId,
        },
        changeType: DataChangedChangeType.Create,
      },
      notificationDescription: 'Zinsliste wurde gerade erstellt',
      key: `${key}-create`,
      callback,
    },
    {
      filter: {
        userId: user.username,
        mitarbeiterId: mitarbeiter?.mitarbeiterId,
        type: dataChangedSubscriptionTypes.zinsliste,
        params: {
          objektId,
        },
        changeType: DataChangedChangeType.Delete,
      },
      notificationDescription: 'Zinsliste wurde gerade gelöscht',
      key: `${key}-delete`,
      callback,
    },
  ]);
};

export { useOnZinslisteDataChangedEvents };
