import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateFirmendatenBuchhaltungEinstellungMutationVariables = Types.Exact<{
  input: Types.FirmendatenBuchhaltungEinstellungUpdateInput;
}>;

export type UpdateFirmendatenBuchhaltungEinstellungMutation = {
  updateFirmendatenBuchhaltungEinstellung: {
    data: { heAbrechnungVerrechnungskontoId?: string | null; subAdministrationBillingAccountId?: string | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateLeerstehungAufwandskontoMutationVariables = Types.Exact<{
  input: Types.FirmendatenLeerstehungAufwandskontoInput;
}>;

export type CreateLeerstehungAufwandskontoMutation = {
  createLeerstehungAufwandskonto: {
    data: { firmendatenLeerstehungAufwandskontoId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateLeerstehungAufwandskontoMutationVariables = Types.Exact<{
  firmendatenLeerstehungAufwandskontoId: Types.Scalars['ID']['input'];
  input: Types.FirmendatenLeerstehungAufwandskontoInput;
}>;

export type UpdateLeerstehungAufwandskontoMutation = {
  updateLeerstehungAufwandskonto: {
    data: { firmendatenLeerstehungAufwandskontoId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteLeerstehungAufwandskontoMutationVariables = Types.Exact<{
  firmendatenLeerstehungAufwandskontoId: Types.Scalars['ID']['input'];
}>;

export type DeleteLeerstehungAufwandskontoMutation = {
  deleteLeerstehungAufwandskonto: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type UpdateLeerstehungForderungskontoMutationVariables = Types.Exact<{
  input: Types.FirmendatenLeerstehungForderungkontoInput;
}>;

export type UpdateLeerstehungForderungskontoMutation = {
  updateLeerstehungForderungskonto: {
    data: { heAbrechnungVerrechnungskontoId?: string | null };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateAufwandAbgrenzungskontoMutationVariables = Types.Exact<{
  input: Types.FirmendatenAufwandAbgrenzungskontoInput;
}>;

export type UpdateAufwandAbgrenzungskontoMutation = {
  updateAufwandAbgrenzungskonto: {
    data: { aufwandAbgrenzungskontoId?: string | null };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const UpdateFirmendatenBuchhaltungEinstellungDocument = gql`
  mutation updateFirmendatenBuchhaltungEinstellung($input: FirmendatenBuchhaltungEinstellungUpdateInput!) {
    updateFirmendatenBuchhaltungEinstellung(input: $input) {
      data {
        heAbrechnungVerrechnungskontoId
        subAdministrationBillingAccountId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateFirmendatenBuchhaltungEinstellungMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFirmendatenBuchhaltungEinstellungMutation, UpdateFirmendatenBuchhaltungEinstellungMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFirmendatenBuchhaltungEinstellungMutation, UpdateFirmendatenBuchhaltungEinstellungMutationVariables>(
    UpdateFirmendatenBuchhaltungEinstellungDocument,
    options
  );
}
export type UpdateFirmendatenBuchhaltungEinstellungMutationHookResult = ReturnType<typeof useUpdateFirmendatenBuchhaltungEinstellungMutation>;
export type UpdateFirmendatenBuchhaltungEinstellungMutationResult = Apollo.MutationResult<UpdateFirmendatenBuchhaltungEinstellungMutation>;
export type UpdateFirmendatenBuchhaltungEinstellungMutationOptions = Apollo.BaseMutationOptions<
  UpdateFirmendatenBuchhaltungEinstellungMutation,
  UpdateFirmendatenBuchhaltungEinstellungMutationVariables
>;
export const CreateLeerstehungAufwandskontoDocument = gql`
  mutation createLeerstehungAufwandskonto($input: FirmendatenLeerstehungAufwandskontoInput!) {
    createLeerstehungAufwandskonto(input: $input) {
      data {
        firmendatenLeerstehungAufwandskontoId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateLeerstehungAufwandskontoMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateLeerstehungAufwandskontoMutation, CreateLeerstehungAufwandskontoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateLeerstehungAufwandskontoMutation, CreateLeerstehungAufwandskontoMutationVariables>(
    CreateLeerstehungAufwandskontoDocument,
    options
  );
}
export type CreateLeerstehungAufwandskontoMutationHookResult = ReturnType<typeof useCreateLeerstehungAufwandskontoMutation>;
export type CreateLeerstehungAufwandskontoMutationResult = Apollo.MutationResult<CreateLeerstehungAufwandskontoMutation>;
export type CreateLeerstehungAufwandskontoMutationOptions = Apollo.BaseMutationOptions<
  CreateLeerstehungAufwandskontoMutation,
  CreateLeerstehungAufwandskontoMutationVariables
>;
export const UpdateLeerstehungAufwandskontoDocument = gql`
  mutation updateLeerstehungAufwandskonto($firmendatenLeerstehungAufwandskontoId: ID!, $input: FirmendatenLeerstehungAufwandskontoInput!) {
    updateLeerstehungAufwandskonto(firmendatenLeerstehungAufwandskontoId: $firmendatenLeerstehungAufwandskontoId, input: $input) {
      data {
        firmendatenLeerstehungAufwandskontoId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateLeerstehungAufwandskontoMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateLeerstehungAufwandskontoMutation, UpdateLeerstehungAufwandskontoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLeerstehungAufwandskontoMutation, UpdateLeerstehungAufwandskontoMutationVariables>(
    UpdateLeerstehungAufwandskontoDocument,
    options
  );
}
export type UpdateLeerstehungAufwandskontoMutationHookResult = ReturnType<typeof useUpdateLeerstehungAufwandskontoMutation>;
export type UpdateLeerstehungAufwandskontoMutationResult = Apollo.MutationResult<UpdateLeerstehungAufwandskontoMutation>;
export type UpdateLeerstehungAufwandskontoMutationOptions = Apollo.BaseMutationOptions<
  UpdateLeerstehungAufwandskontoMutation,
  UpdateLeerstehungAufwandskontoMutationVariables
>;
export const DeleteLeerstehungAufwandskontoDocument = gql`
  mutation deleteLeerstehungAufwandskonto($firmendatenLeerstehungAufwandskontoId: ID!) {
    deleteLeerstehungAufwandskonto(firmendatenLeerstehungAufwandskontoId: $firmendatenLeerstehungAufwandskontoId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteLeerstehungAufwandskontoMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteLeerstehungAufwandskontoMutation, DeleteLeerstehungAufwandskontoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteLeerstehungAufwandskontoMutation, DeleteLeerstehungAufwandskontoMutationVariables>(
    DeleteLeerstehungAufwandskontoDocument,
    options
  );
}
export type DeleteLeerstehungAufwandskontoMutationHookResult = ReturnType<typeof useDeleteLeerstehungAufwandskontoMutation>;
export type DeleteLeerstehungAufwandskontoMutationResult = Apollo.MutationResult<DeleteLeerstehungAufwandskontoMutation>;
export type DeleteLeerstehungAufwandskontoMutationOptions = Apollo.BaseMutationOptions<
  DeleteLeerstehungAufwandskontoMutation,
  DeleteLeerstehungAufwandskontoMutationVariables
>;
export const UpdateLeerstehungForderungskontoDocument = gql`
  mutation updateLeerstehungForderungskonto($input: FirmendatenLeerstehungForderungkontoInput!) {
    updateLeerstehungForderungskonto(input: $input) {
      data {
        heAbrechnungVerrechnungskontoId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateLeerstehungForderungskontoMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateLeerstehungForderungskontoMutation, UpdateLeerstehungForderungskontoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLeerstehungForderungskontoMutation, UpdateLeerstehungForderungskontoMutationVariables>(
    UpdateLeerstehungForderungskontoDocument,
    options
  );
}
export type UpdateLeerstehungForderungskontoMutationHookResult = ReturnType<typeof useUpdateLeerstehungForderungskontoMutation>;
export type UpdateLeerstehungForderungskontoMutationResult = Apollo.MutationResult<UpdateLeerstehungForderungskontoMutation>;
export type UpdateLeerstehungForderungskontoMutationOptions = Apollo.BaseMutationOptions<
  UpdateLeerstehungForderungskontoMutation,
  UpdateLeerstehungForderungskontoMutationVariables
>;
export const UpdateAufwandAbgrenzungskontoDocument = gql`
  mutation updateAufwandAbgrenzungskonto($input: FirmendatenAufwandAbgrenzungskontoInput!) {
    updateAufwandAbgrenzungskonto(input: $input) {
      data {
        aufwandAbgrenzungskontoId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUpdateAufwandAbgrenzungskontoMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAufwandAbgrenzungskontoMutation, UpdateAufwandAbgrenzungskontoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAufwandAbgrenzungskontoMutation, UpdateAufwandAbgrenzungskontoMutationVariables>(
    UpdateAufwandAbgrenzungskontoDocument,
    options
  );
}
export type UpdateAufwandAbgrenzungskontoMutationHookResult = ReturnType<typeof useUpdateAufwandAbgrenzungskontoMutation>;
export type UpdateAufwandAbgrenzungskontoMutationResult = Apollo.MutationResult<UpdateAufwandAbgrenzungskontoMutation>;
export type UpdateAufwandAbgrenzungskontoMutationOptions = Apollo.BaseMutationOptions<
  UpdateAufwandAbgrenzungskontoMutation,
  UpdateAufwandAbgrenzungskontoMutationVariables
>;
