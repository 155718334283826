import { FormikHelpers } from 'formik';
import { MahndefinitionParamFormValues } from './mahndefinitionFormMapper';

export const validateMahngebuehren = (data: MahndefinitionParamFormValues, formikHelpers: FormikHelpers<MahndefinitionParamFormValues>) => {
  const isNextRowHigherThanLastList = data.mahnstufeList.map((mahnstufe) =>
    mahnstufe.mahngebuehrList.map((mahngebuehr, index) => {
      const nextElement = mahnstufe.mahngebuehrList[index + 1];
      return nextElement ? mahngebuehr.ab < nextElement.ab : true;
    })
  );
  let isError = false;
  data.mahnstufeList.forEach((mahnstufe, index) => {
    if (isNextRowHigherThanLastList[index].includes(false)) {
      mahnstufe.mahngebuehrList.forEach((mahngebuehr, indexMahngebuehr) => {
        if (!isNextRowHigherThanLastList[index][indexMahngebuehr]) {
          isError = true;
          formikHelpers.setFieldError(`mahnstufeList.${index}.mahngebuehrList`, 'Mahngebühr Staffelung muss aufsteigend sein');
        }
      });
    }
  });
  return isError;
};

export const validateMahngebuehren2 = (data: MahndefinitionParamFormValues) => {
  const errors: any = {};
  errors.mahnstufeList = new Array(data.mahnstufeList.length).fill(undefined);

  const isNextRowHigherThanLastList = data.mahnstufeList.map((mahnstufe) =>
    mahnstufe.mahngebuehrList.map((mahngebuehr, index) => {
      const nextElement = mahnstufe.mahngebuehrList[index + 1];
      return nextElement ? mahngebuehr.ab < nextElement.ab : true;
    })
  );

  let isError = false;
  data.mahnstufeList.forEach((mahnstufe, index) => {
    if (isNextRowHigherThanLastList[index].includes(false)) {
      for (const mahngebuehr of mahnstufe.mahngebuehrList) {
        const indexMahngebuehr = mahnstufe.mahngebuehrList.indexOf(mahngebuehr);
        if (!isNextRowHigherThanLastList[index][indexMahngebuehr]) {
          isError = true;
          errors.mahnstufeList[index] = { mahngebuehrList: 'Mahngebühr Staffelung muss aufsteigend sein' };
          break;
        }
      }
    }
  });

  if (!isError) {
    return {};
  }

  return errors;
};
