import { Dropdown, MenuProps, Modal } from 'antd';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { uriFileOfFirmendaten } from '../documentApiPaths';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { BudgetingBase, FileInfo } from '../../../types';
import { showSuccessMsgDelete } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { useDeleteTopFinancialPlanMutation } from '../../Budgeting/gql/BudgetingMutations.types';
import { gqlOperations } from '../../../graphql/gqlNamedOperations-generated';

type Props = {
  record: FileInfo;
  budgeting: BudgetingBase;
  bestandseinheitId?: string;
  vertragId?: string;
};

const TopFinancialPlanActions: FC<Props> = ({ record, budgeting, bestandseinheitId, vertragId }) => {
  const { activeForFirmendatenId } = useFDAuthorized();

  const [runDelete] = useDeleteTopFinancialPlanMutation({
    variables: {
      budgetingId: budgeting.budgetingId,
      objektId: budgeting.objekt.objektId,
      input: { fileId: record.fileId, bestandseinheitId, vertragId },
    },
    onCompleted: () => {
      showSuccessMsgDelete('Top-Wirtschaftsplan');
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
    refetchQueries: () => [gqlOperations.Query.FileInfoListForFirmendaten],
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Löschen',
      onClick: () => showConfirmDelete(runDelete, budgeting),
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  return (
    <>
      <Dropdown.Button menu={{ items }}>
        <Link to={uriFileOfFirmendaten(activeForFirmendatenId, record.fileId, record.name)} target="_blank">
          Öffnen
        </Link>
      </Dropdown.Button>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void, budgeting: BudgetingBase) => {
  Modal.confirm({
    title: 'Möchten Sie das Top-Wirtschaftsplan löschen?',
    content: `${budgeting.bezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default TopFinancialPlanActions;
