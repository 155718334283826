import { useCallback, useState } from 'react';

const useToggle = (initialOn = false): [boolean, (nextOn?: unknown) => void] => {
  const [on, setOn] = useState(initialOn);
  const toggle = useCallback(
    (nextOn?: unknown) => {
      setOn((currentOn) => (typeof nextOn === 'boolean' ? nextOn : !currentOn));
    },
    [setOn]
  );
  return [on, toggle];
};

export { useToggle };
