import { RechtstraegerInfoFeldFragment } from '../gql/rechtstraegerInfoFeldFragments.types';
import { RechtstraegerInfoFeldPersoneninfos } from '../../../../types';

export type TRechtstraegerInfoFeldData = RechtstraegerInfoFeldPersoneninfos;

export const isRechtstraegerInfoFeldPersoneninfos = (attribute: RechtstraegerInfoFeldFragment): attribute is RechtstraegerInfoFeldFragment =>
  attribute.__typename === 'RechtstraegerInfoFeldPersoneninfos';

export const getRechtstraegerInfoFeldShortName = (rechtstraegerInfoFeld: RechtstraegerInfoFeldFragment): string => {
  if (isRechtstraegerInfoFeldPersoneninfos(rechtstraegerInfoFeld)) {
    return rechtstraegerInfoFeld.merkmalList[0];
  }
  return '';
};
