import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { IncomingInvoiceBookingCreationGenerierlauf } from '../../../../types';
import { TIncomingInvoiceBookingCreationQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import {
  FiltersFormValues,
  mapFormValuesToQueryParams,
  mapQueryParamsToFormValues,
  mapQueryParamsToListQueryVariables,
} from './Filters/listingFiltersFormMapper';
import ListingFilters from './Filters/ListingFilters';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../hooks/useQueryWithPagingAndSorting';
import { useIncomingInvoiceBookingCreationGenerierlaufEntryListQuery } from '../../gql/IncomingInvoiceBookingCreationGenerierlauf/IncomingInvoiceBookingCreationGenerierlaufQueries.types';
import EntryTable from './Table/EntryTable';

type Props = {
  generierlauf: IncomingInvoiceBookingCreationGenerierlauf;
  verarbeitungType: string;
};

const EntryListing: FC<Props> = ({ generierlauf, verarbeitungType }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TIncomingInvoiceBookingCreationQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(navigate, verarbeitungType, generierlauf.generierlaufId, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: TIncomingInvoiceBookingCreationQueryParams) => {
    updateQueryParams(navigate, verarbeitungType, generierlauf.generierlaufId, mapFormValuesToQueryParams(values), paginationParams);
  };

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useIncomingInvoiceBookingCreationGenerierlaufEntryListQuery,
    {
      variables: {
        ...mapQueryParamsToListQueryVariables(generierlauf.generierlaufId, queryParams),
      },
      fetchPolicy: 'no-cache',
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: paginationParams.currentPage ? Number(paginationParams.currentPage) : DEFAULT_CURRENT,
      pageSize: paginationParams.pageSize ? Number(paginationParams.pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const entryList = data?.getIncomingInvoiceBookingCreationGenerierlaufEntryList.data.contentList || [];

  return (
    <>
      <Formik<FiltersFormValues> initialValues={mapQueryParamsToFormValues(queryParams)} onSubmit={onChange}>
        {(formikProps) => <ListingFilters formikProps={formikProps} generierlaufId={generierlauf.generierlaufId} />}
      </Formik>
      <EntryTable entryList={entryList} pagination={pagination} loading={loading} handleTableSorting={handleTableSorting} />
    </>
  );
};

export default EntryListing;
