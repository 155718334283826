import { FormFields } from '../../../../../../helpers/formikHelper';
import { ContactType } from '../../../../../../shared/components/Contact/contactHelper';
import { Contact } from '../../../../../../types';

export type EmailContactUpdateFormValues = {
  contactId: string;
  type: ContactType.EMAIL;
  wert: string;
};

export const emailContactUpdateFormFields: FormFields<EmailContactUpdateFormValues> = {
  contactId: 'contactId',
  type: 'type',
  wert: 'wert',
};

export const mapEmailContactToFormValues = (values: Contact): EmailContactUpdateFormValues => ({
  contactId: values.contactId,
  type: ContactType.EMAIL,
  wert: values.wert,
});
