import { FormFields } from '../../../../../../helpers/formikHelper';
import { RechtstraegerVertragspartner, SetMahnsperreForRechtstraegerInput } from '../../../../../../types';

export interface MahnsperreFormValues {
  mahnsperre: boolean;
  mahnsperreKommentar?: string;
}

export const mahnsperreFormFields: FormFields<MahnsperreFormValues> = {
  mahnsperre: 'mahnsperre',
  mahnsperreKommentar: 'mahnsperreKommentar',
};

export const mapMahnsperreToFormValues = (vertragspartner: RechtstraegerVertragspartner): MahnsperreFormValues => ({
  mahnsperre: vertragspartner.mahnsperre,
  mahnsperreKommentar: vertragspartner.mahnsperreKommentar ?? undefined,
});

export const mapFormValuesToMahnsperre = (formValues: MahnsperreFormValues): SetMahnsperreForRechtstraegerInput => ({
  mahnsperre: formValues.mahnsperre,
  kommentar: formValues.mahnsperreKommentar,
});
