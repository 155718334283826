import React, { FC } from 'react';
import { Space, Tag, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import DataWithShortenedTextAndExtraInfo from '../../components/Helpers/DataWithShortenedTextAndExtraInfo';
import FileSize from '../../components/Helpers/FileSize';
import { uriFileOfFirmendaten } from '../Document/documentApiPaths';
import { useFDAuthorized } from '../../shared/Auth/useAuthorized';

type UploadedFileViewProps = {
  disableDelete?: boolean;
  filename: string;
  filesize?: number;
  isNewFile?: boolean;
  onDelete?: () => void;
  fileId?: string;
  tooltipFooter?: React.ReactNode;
  icon?: React.ReactNode;
};

export const UploadedFileView: FC<UploadedFileViewProps> = ({
  filename,
  filesize,
  onDelete,
  isNewFile,
  disableDelete,
  tooltipFooter,
  fileId,
  icon = <PaperClipOutlined />,
}) => {
  const { activeForFirmendatenId } = useFDAuthorized();

  return (
    <Space>
      {icon}
      <DataWithShortenedTextAndExtraInfo text={filename} maxTextLength={30} tooltipFooter={tooltipFooter}>
        {(shortenedText) =>
          fileId ? (
            <Link to={uriFileOfFirmendaten(activeForFirmendatenId, fileId, filename)} target="_blank">
              {shortenedText}
            </Link>
          ) : (
            <Typography.Text>{shortenedText}</Typography.Text>
          )
        }
      </DataWithShortenedTextAndExtraInfo>
      {filesize && (
        <Typography.Text>
          (<FileSize size={filesize} />)
        </Typography.Text>
      )}
      {isNewFile && <Tag color="green">Neu</Tag>}
      {!disableDelete && onDelete && <DeleteOutlined onClick={onDelete} />}
    </Space>
  );
};
