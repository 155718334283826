import React, { FC } from 'react';
import { Col, Row, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import { generatePathToBestandseinheitDetailsPage } from '../../../Bestandseinheit/bestandseinheitUriPaths';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { useVorschreibungMailDeliveryQuery } from '../gql/VorschreibungMailDeliveryQueries.types';
import { getObjektVertragList } from '../helpers';

type Props = {
  vorschreibungMailDeliveryId: string;
};

const ObjektTopLink: FC<Props> = ({ vorschreibungMailDeliveryId }) => {
  const { data, loading } = useVorschreibungMailDeliveryQuery({ variables: { vorschreibungMailDeliveryId } });
  const vorschreibungMailDelivery = data?.getVorschreibungMailDelivery.data;
  const vertragList = vorschreibungMailDelivery ? getObjektVertragList(vorschreibungMailDelivery?.objektBestandseinheitVertragList) : [];

  return (
    <Skeleton paragraph={false} active loading={loading}>
      {vertragList.length &&
        vertragList.map((vertrag) => (
          <Row style={{ width: '100%' }} key={vorschreibungMailDeliveryId + vertrag.vertragId}>
            <Col>
              <DataWithShortenedText text={vertrag.topName} maxTextLength={25}>
                {(shortenedText) => (
                  <Link to={generatePathToBestandseinheitDetailsPage(vertrag.objektId, vertrag.bestandseinheitId)}>{shortenedText}</Link>
                )}
              </DataWithShortenedText>
            </Col>
          </Row>
        ))}
    </Skeleton>
  );
};

export default ObjektTopLink;
