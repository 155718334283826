import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { TopAbrechnung } from '../../topAbrechnung-types';
import RechnungsEmpfaengerBlock from '../../../shared/RechnungsEmpfaengerBlock';
import Absender from '../../../shared/Absender';

const TAAbsender: FC<{ data: TopAbrechnung }> = ({ data }) => (
  <View style={[pdfStyles.row, { paddingTop: '3mm' }]}>
    <View style={[pdfStyles.column, pdfStyles.addressor]}>
      <Absender absender={data.deckblatt.absender} />
      <View style={[{ marginBottom: '7mm' }]} />
      {/* Rechnungsempfänger */}
      <RechnungsEmpfaengerBlock rechnungsEmpfaengerShippingLabel={data.deckblatt.rechnungsEmpfaengerShippingLabel} />
    </View>
    {/* Kundennummer */}
    <View style={[pdfStyles.column]}>
      <Text style={pdfStyles.textNormal}>
        {data.deckblatt.kundennummer.text} {data.deckblatt.kundennummer.nummer}
      </Text>
      <Text style={pdfStyles.textNormal}>{data.deckblatt.abrechnungsDatumLine}</Text>
    </View>
  </View>
);

export default TAAbsender;
