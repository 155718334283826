import React, { FC } from 'react';
import { Badge, Tooltip } from 'antd';
import { BankDetailsFibuVerwendung, BankDetailsFibuVerwendungTuple } from '../../../../../../types';
import KontoLinkToKontoblatt from '../../../../../../shared/components/Konto/KontoLinkToKontoblatt';

type KontoLinkWithNonRepFondsVerwendungBadgeProps = {
  konto: {
    kontoId: string;
    bezeichnung: string;
    nummer: string;
    verwendung: BankDetailsFibuVerwendungTuple;
  };
  /** mandatpry as bank names can be very long */
  maxBankNameLength: number;
};

const KontoLinkWithNonRepFondsVerwendungBadge: FC<KontoLinkWithNonRepFondsVerwendungBadgeProps> = ({ konto, maxBankNameLength }) => {
  return konto.verwendung.value === BankDetailsFibuVerwendung.RepFonds ? (
    <KontoLinkToKontoblatt konto={konto} maxBezeichnungLength={maxBankNameLength} />
  ) : (
    <Tooltip title="Konto nicht für Rep.Fonds-Verwendung freigegeben">
      <Badge dot status="warning">
        <KontoLinkToKontoblatt konto={konto} maxBezeichnungLength={maxBankNameLength} />
      </Badge>
    </Tooltip>
  );
};

export default KontoLinkWithNonRepFondsVerwendungBadge;
