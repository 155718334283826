import React, { FC } from 'react';
import { Empty } from 'antd';
import ustVomAufwandTableColumns from './ustVomAufwandTableColumns';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { UstVomAufwandListEntry } from '../../../../types';

type Props = {
  ustVomAufwandList: UstVomAufwandListEntry[];
  objektId: string;
  loading: boolean;
};

const UstVomAufwandTable: FC<Props> = ({ ustVomAufwandList, objektId, loading }) => (
  <TableWithColSelector<UstVomAufwandListEntry>
    size="small"
    locale={{
      emptyText: <Empty description={<span>Keine Rep.-Fonds-Berechnung vorhanden</span>} />,
    }}
    loading={loading}
    dataSource={ustVomAufwandList}
    columns={ustVomAufwandTableColumns(objektId)}
    pagination={{ showSizeChanger: true, defaultPageSize: 20 }}
    rowKey={(record) => record.ustVomAufwandId}
    filterIdentifier="objekt-ust-vom-aufwand"
  />
);

export default UstVomAufwandTable;
