import React, { FC } from 'react';
import { Select, SelectProps } from 'antd';
import { useObjektInfoFeldTypeListQuery } from '../gql/ObjektInfoFeldTypeQueries.types';

const ObjektInfoFeldTypeSelect: FC<SelectProps> = ({ ...restProps }) => {
  const { data, loading } = useObjektInfoFeldTypeListQuery();

  const objektInfoFeldTypeList = data?.getObjektInfoFeldTypeList.data ?? [];

  return (
    <Select loading={loading} placeholder="Typ auswählen" allowClear style={{ minWidth: '160px' }} size="small" {...restProps}>
      {objektInfoFeldTypeList.map((infoFeldType) => (
        <Select.Option value={infoFeldType.value} key={infoFeldType.value}>
          {infoFeldType.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ObjektInfoFeldTypeSelect;
