import * as Types from '../../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BestandseinheitNutzungsArtListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type BestandseinheitNutzungsArtListQuery = {
  getBestandseinheitNutzungsArtList: {
    data: Array<{ text: string; value: Types.NutzungsArt }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const BestandseinheitNutzungsArtListDocument = gql`
  query BestandseinheitNutzungsArtList {
    getBestandseinheitNutzungsArtList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBestandseinheitNutzungsArtListQuery(
  baseOptions?: Apollo.QueryHookOptions<BestandseinheitNutzungsArtListQuery, BestandseinheitNutzungsArtListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BestandseinheitNutzungsArtListQuery, BestandseinheitNutzungsArtListQueryVariables>(
    BestandseinheitNutzungsArtListDocument,
    options
  );
}
export function useBestandseinheitNutzungsArtListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BestandseinheitNutzungsArtListQuery, BestandseinheitNutzungsArtListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BestandseinheitNutzungsArtListQuery, BestandseinheitNutzungsArtListQueryVariables>(
    BestandseinheitNutzungsArtListDocument,
    options
  );
}
export function useBestandseinheitNutzungsArtListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BestandseinheitNutzungsArtListQuery, BestandseinheitNutzungsArtListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BestandseinheitNutzungsArtListQuery, BestandseinheitNutzungsArtListQueryVariables>(
    BestandseinheitNutzungsArtListDocument,
    options
  );
}
export type BestandseinheitNutzungsArtListQueryHookResult = ReturnType<typeof useBestandseinheitNutzungsArtListQuery>;
export type BestandseinheitNutzungsArtListLazyQueryHookResult = ReturnType<typeof useBestandseinheitNutzungsArtListLazyQuery>;
export type BestandseinheitNutzungsArtListSuspenseQueryHookResult = ReturnType<typeof useBestandseinheitNutzungsArtListSuspenseQuery>;
export type BestandseinheitNutzungsArtListQueryResult = Apollo.QueryResult<
  BestandseinheitNutzungsArtListQuery,
  BestandseinheitNutzungsArtListQueryVariables
>;
