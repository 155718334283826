import React from 'react';
import { Link } from 'react-router-dom';

import { TableWithAlignedColsExpandedRowType } from '../../../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { createCells, createRowWithCells, TextWithStyling } from '../../../../../BkOrWe/shared/abrechnungTableHelper';
import { StyledHeAbrHeVertragKontoRowTable } from '../heAbrHeVertragKontoRowTable.styled';
import DataWithShortenedText from '../../../../../../../components/Helpers/DataWithShortenedText';
import { generatePathToRechtstraegerDetailsPage } from '../../../../../../Rechtstraeger/rechtstraegerHelper';
import TextForEmptyValue from '../../../../../../../components/Text/TextForEmptyValue';
import { generatePathToOffenePostenPage } from '../../../../../../OffenePosten/offenePostenUriPaths';
import { EuroAmount } from '../../../../../../../components/Number';
import { generatePathToKontoblattPage } from '../../../../../../Kontoblatt/kontoblattUriPaths';
import OffenePostenBestandseinheitColumn from '../../../../../shared/OffenePostenBestandseinheitColumn';
import { HeAbrOffenePostenList } from '../../../../../../../types';
import CustomFormattedDate from '../../../../../../../components/FormattedDate/CustomFormattedDate';

const headerTexts: TextWithStyling[] = [
  { render: 'Vertragspartner' },
  { render: 'Bestandseinheit' },
  { render: 'Fibu-Konto' },
  { render: 'Letzte Zahlungsbuchung am' },
  { render: 'Fällig am' },
  { render: 'Offener OP-Betrag' },
];

const offenePostenZahlungsrueckstaendeRowTable = (
  objektId: string,
  abrechnungszeitraumVon: string,
  abrechnungszeitraumBis: string
): TableWithAlignedColsExpandedRowType<HeAbrOffenePostenList>[] => {
  return [
    {
      header: () => createCells(headerTexts, true),
      table: {
        colSpanSize: [3, 1],
        StyledComponent: StyledHeAbrHeVertragKontoRowTable,
        positionOfButton: 0,
        showCellButton: false,
      },
      body: (konto) => (
        <>
          {konto.offenePostenList.map((op) => {
            const texts: TextWithStyling[] = [
              {
                render: (
                  <DataWithShortenedText maxTextLength={20} text={op.vertragspartner.kurzBezeichnung}>
                    {(shortenedText) => (
                      <Link to={generatePathToRechtstraegerDetailsPage(op.vertragspartner.rechtstraegerId)} target="_blank">
                        {shortenedText}
                      </Link>
                    )}
                  </DataWithShortenedText>
                ),
              },
              {
                render: <OffenePostenBestandseinheitColumn objektId={objektId} bestandseinheitList={op.bestandseinheitList} />,
              },
              {
                render: (
                  <Link
                    to={generatePathToKontoblattPage({
                      objektId,
                      kontoId: op.fibuKontoId,
                      buchungsdatumFrom: abrechnungszeitraumVon,
                      buchungsdatumTo: abrechnungszeitraumBis,
                    })}
                    target="_blank"
                  >
                    {op.fibuKontonummer}
                  </Link>
                ),
              },
              {
                render: op.letzteBuchungAm ? <CustomFormattedDate value={op.letzteBuchungAm} /> : <TextForEmptyValue textToShow="minus" />,
              },
              {
                render: op.dueDate ? <CustomFormattedDate value={op.dueDate} /> : <TextForEmptyValue textToShow="minus" />,
              },
              {
                render: (
                  <Link
                    to={generatePathToOffenePostenPage({
                      dueDateTo: op.dueDate,
                      dueDateFrom: op.dueDate,
                      vertragspartnerId: op.vertragspartner.rechtstraegerId,
                      fibuKontoId: op.fibuKontoId,
                      objektId,
                    })}
                    target="_blank"
                  >
                    <EuroAmount value={op.offenerBetrag} />
                  </Link>
                ),
              },
            ];

            return createRowWithCells(texts, false);
          })}
        </>
      ),
    },
  ];
};

export default offenePostenZahlungsrueckstaendeRowTable;
