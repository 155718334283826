import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import AttachmentUpload from './Upload/AttachmentUpload';
import AttachmentPartUploadView from './Upload/AttachmentPartUploadView';
import { useVorschreibungMailDeliveryAttachmentListQuery } from '../../gql/VorschreibungMailDeliveryWizardQueries.types';
import { getObjektVertragList } from '../../../../EmailDelivery/VorschreibungMailDelivery/helpers';
import MultipleTexts from '../../../../../components/Text/MultipleTexts';
import { VorschreibungMailDelivery } from '../../../../../types';

type Props = {
  wizardId: string;
  isCompleted?: boolean;
  selectedRow: VorschreibungMailDelivery;
};

const AttachmentPart: FC<Props> = ({ selectedRow, wizardId, isCompleted }) => {
  const { data, refetch } = useVorschreibungMailDeliveryAttachmentListQuery({
    variables: {
      vorschreibungMailDeliveryWizardId: wizardId,
      vorschreibungMailDeliveryId: selectedRow.vorschreibungMailDeliveryId,
    },
  });

  const attachmentList = data?.getVorschreibungMailDeliveryAttachmentList.data ?? [];
  const objektVertragTopNames = getObjektVertragList(selectedRow.objektBestandseinheitVertragList).map((vertrag) => vertrag.topName);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Typography.Text>
        Anhänge von <MultipleTexts texts={objektVertragTopNames} maxFirstTextLength={30} /> - {selectedRow.vertragspartner.kurzBezeichnung} (
        {selectedRow.emailAddress})
        {/*Anhänge von {selectedRow.objektTopVertrag.name} - {selectedRow.vertragspartner.kurzBezeichnung} ({selectedRow.emailAddress})*/}
      </Typography.Text>
      <Space direction="vertical" style={{ width: '40%' }}>
        <AttachmentUpload
          key={selectedRow.vorschreibungMailDeliveryId}
          disabled={isCompleted}
          attachmentList={attachmentList}
          onAction={refetch}
          wizardId={wizardId}
          vorschreibungMailDeliveryId={selectedRow.vorschreibungMailDeliveryId}
        />
        <AttachmentPartUploadView
          isCompleted={isCompleted}
          attachmentList={attachmentList}
          onAction={refetch}
          vorschreibungMailDeliveryWizardId={wizardId}
          vorschreibungMailDeliveryId={selectedRow.vorschreibungMailDeliveryId}
        />
      </Space>
    </Space>
  );
};

export default AttachmentPart;
