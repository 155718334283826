import React, { FC } from 'react';
import { Box } from 'rebass';
import { Descriptions, Typography } from 'antd';
import { IncomingInvoiceBuchungPositionFormValues } from './incomingInvoiceFormMapper';
import { calculateBruttoNettoUst } from '../incomingInvoiceHelpers';
import { EuroAmount, Percent } from '../../../components/Number';
import FormBlockRow from './shared/FormBlockRow';

type Props = {
  inklusiveSteuer: boolean;
  positionList: IncomingInvoiceBuchungPositionFormValues[];
};

const SummaryBlockForm: FC<Props> = ({ inklusiveSteuer, positionList }) => {
  const { netto: sumNetto, brutto: sumBrutto, steuerzeileList } = calculateBruttoNettoUst(positionList, inklusiveSteuer);

  return (
    <FormBlockRow
      rowTitle="Gesamt"
      content={
        <Descriptions bordered={false} colon={false} column={1} layout="horizontal" title="" size="small">
          <Descriptions.Item label="Gesamt Netto">
            <Box mx="auto" />
            <EuroAmount value={sumNetto} />
          </Descriptions.Item>
          {steuerzeileList.map((steuerzeile, index) => (
            <Descriptions.Item
              key={index}
              label={
                <Typography.Text>
                  Umsatzsteuer <Percent value={steuerzeile.steuersatz / 100} />
                </Typography.Text>
              }
            >
              <Box mx="auto" />
              <EuroAmount value={steuerzeile.betrag} />
            </Descriptions.Item>
          ))}
          <Descriptions.Item label="Gesamt Brutto">
            <Box mx="auto" />
            <EuroAmount value={sumBrutto} />
          </Descriptions.Item>
        </Descriptions>
      }
    />
  );
};

export default SummaryBlockForm;
