import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';
import { useQueryParams } from '../../../hooks/useStringQueryParams';
import { FiltersFormValues, initialFormValues, mapFormValuesToQueryParams } from './Filters/filtersFormMapper';
import { TBookingJournalQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import ListingFilters from './Filters/ListingFilters';
import BookingJournalTable from './Table/BookingJournalTable';
import BookingJournalListSummary from './BookingJournalListSummary';
import useOnEventsSetStickyFilterSummary from '../../../components/StickyHeader/useOnEventsSetStickyFilterSummary';

const BookingJournalListing = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TBookingJournalQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: FiltersFormValues) => {
    const filters = mapFormValuesToQueryParams(values);
    updateQueryParams(navigate, filters, paginationParams);
  };

  const filterRef = useRef<HTMLDivElement>(null);
  const summaryRef = useRef<HTMLDivElement>(null);
  const columnSelectorRef = useRef<HTMLDivElement>(null);
  useOnEventsSetStickyFilterSummary([filterRef, summaryRef, columnSelectorRef]);

  return (
    <>
      <Formik<FiltersFormValues> initialValues={initialFormValues(queryParams)} onSubmit={onChange}>
        {(formikProps) => <ListingFilters formikProps={formikProps} queryParams={queryParams} filterRef={filterRef} />}
      </Formik>
      <BookingJournalListSummary queryParams={queryParams} summaryRef={summaryRef} />
      <BookingJournalTable queryParams={queryParams} filterRef={filterRef} summaryRef={summaryRef} columnSelectorRef={columnSelectorRef} />
    </>
  );
};

export default BookingJournalListing;
