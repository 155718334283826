import { Space, Typography } from 'antd';
import React, { FC } from 'react';

export interface ValueTitleProps {
  testidContent?: string;
  title: string | React.ReactNode;
  content: React.ReactNode;
  heightSpace?: number;
  small?: boolean;
  align?: 'start' | 'end' | 'center' | 'baseline' | undefined;
  width?: string;
}

const ValueTitle: FC<ValueTitleProps> = ({ testidContent, title, content, small = false, heightSpace = 0, align, width }) => (
  <Space direction="vertical" size={heightSpace} align={align} style={{ position: 'relative', width: width ?? 'auto' }}>
    <Typography.Text style={{ fontSize: 12 }} type="secondary">
      {title}
    </Typography.Text>
    <Typography.Text
      data-testid={testidContent ? `content-${testidContent}` : null}
      style={{ fontSize: small ? 14 : 18, fontWeight: small ? 400 : 500 }}
    >
      {content}
    </Typography.Text>
  </Space>
);

export { ValueTitle };
