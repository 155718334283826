import { FC } from 'react';
import { Table, TableColumnProps, Typography } from 'antd';
import { SubAbrechnungEigentuemer } from '../../../../../../types';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';
import { EuroAmount } from '../../../../../../components/Number';

type Props = {
  subAbrechnungEigentuemer: SubAbrechnungEigentuemer;
};

const SubAbrTabSaldovortragTable: FC<Props> = ({ subAbrechnungEigentuemer }) => {
  return (
    <Table<SubAbrechnungEigentuemer>
      size="small"
      dataSource={[subAbrechnungEigentuemer]}
      columns={tableColumns}
      rowKey={(data) => data.createTs}
      pagination={false}
    />
  );
};

const tableColumns: TableColumnProps<SubAbrechnungEigentuemer>[] = [
  {
    title: '',
    width: '32px',
    render: () => <TextForEmptyValue textToShow="none" />,
  },
  {
    title: 'Position',
    render: () => <Typography.Text>Saldovortrag</Typography.Text>,
  },
  {
    title: 'Ergebnis',
    align: 'right',
    render: (heVertrag) => <EuroAmount value={heVertrag.saldovortrag} />,
  },
];

export default SubAbrTabSaldovortragTable;
