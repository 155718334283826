import { generatePath } from 'react-router-dom';
import { mapToQueryString, QueryParams } from '../../../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../../../hooks/usePaginationQueryParams';
import { URI_ABRECHNUNG_PAGE } from '../../abrechnungUriPaths';
import { FiltersFormValues } from './filtersFormMapper';

enum AbrechnungQueryParamKey {
  ABRECHNUNGSZEITRAUM_FROM_INCLUSIVE = 'abrechnungszeitraumFromInclusive',
  ABRECHNUNGSZEITRAUM_TO_INCLUSIVE = 'abrechnungszeitraumToInclusive',
  BEZEICHNUNG = 'bezeichnung',
  ERSTELLER_MITARBEITER_ID_LIST = 'erstellerMitarbeiterIdList',
  ERSTELL_DATUM_FROM_INCLUSIVE = 'erstellDatumFromInclusive',
  ERSTELL_DATUM_TO_INCLUSIVE = 'erstellDatumToInclusive',
  OBJEKT_ID_LIST = 'objektIdList',
  STATUS_LIST = 'statusList',
  TYPE_LIST = 'typeList',
}

export type TAbrechnungQueryParams = QueryParams<FiltersFormValues>;

export const updateQueryParams = (navigate: (path: string) => void, filters: TAbrechnungQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  navigate(generatePathToAbrechnungPage(filters, paginationParams));

const generatePathToAbrechnungPage = (filters: TAbrechnungQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  `${generatePath(URI_ABRECHNUNG_PAGE)}?${mapToQueryString({
    [AbrechnungQueryParamKey.ABRECHNUNGSZEITRAUM_FROM_INCLUSIVE]: filters?.abrechnungszeitraumFromInclusive,
    [AbrechnungQueryParamKey.ABRECHNUNGSZEITRAUM_TO_INCLUSIVE]: filters?.abrechnungszeitraumToInclusive,
    [AbrechnungQueryParamKey.BEZEICHNUNG]: filters?.bezeichnung,
    [AbrechnungQueryParamKey.ERSTELLER_MITARBEITER_ID_LIST]: filters?.erstellerMitarbeiterIdList,
    [AbrechnungQueryParamKey.ERSTELL_DATUM_FROM_INCLUSIVE]: filters?.erstellDatumFromInclusive,
    [AbrechnungQueryParamKey.ERSTELL_DATUM_TO_INCLUSIVE]: filters?.erstellDatumToInclusive,
    [AbrechnungQueryParamKey.OBJEKT_ID_LIST]: filters?.objektIdList,
    [AbrechnungQueryParamKey.STATUS_LIST]: filters?.statusList,
    [AbrechnungQueryParamKey.TYPE_LIST]: filters?.typeList,
    ...paginationParams,
  })}`;
