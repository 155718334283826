import React from 'react';
import { Route } from 'react-router';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../security/permissionChecks';
import SysSettingsUstKategoriePage from './SysSettingsUstKategoriePage';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_SYS_SETTINGS } from '../../../../constants/configurationUriPaths';

export type UstKategoriePageRouteParams = { ustKategorieId?: string };

const sysSettingsUstKategoriePageRoute = (
  <Route
    path={URI_SYS_SETTINGS.ustKategoriePage}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsUstKategoriePage />} />}
  />
);

export default sysSettingsUstKategoriePageRoute;
