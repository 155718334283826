import React from 'react';
import { Tag, Typography } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { Link } from 'react-router-dom';
import DataWithShortenedText from '../../../../../../components/Helpers/DataWithShortenedText';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';
import { generatePathToRechtstraegerDetailsPage } from '../../../../../Rechtstraeger/rechtstraegerHelper';
import SendCheckboxColumn from './SendCheckboxColumn';
import { InfoMailDelivery, InfoMailDeliveryRecipientListStepOrderBy } from '../../../../../../types';
import { generatePathToBeVertragDetailsPage } from '../../../../../Vertrag/BeVertrag/beVertragUriPaths';

const recipientTableColumns = (onUpdate: () => void, infoMailDeliveryWizardId: string, isCompleted?: boolean): ColumnProps<InfoMailDelivery>[] => [
  {
    title: 'Objekt-Top',
    dataIndex: InfoMailDeliveryRecipientListStepOrderBy.ObjektTop,
    sorter: true,
    render(text, record) {
      const vertragId = record.objektTopVertrag.vertrag.vertragId;

      return (
        <DataWithShortenedText text={record.objektTopVertrag.name} maxTextLength={30}>
          {(shortenedText) => (
            <Link
              to={generatePathToBeVertragDetailsPage(
                record.objektTopVertrag.bestandseinheit.objektId,
                record.objektTopVertrag.bestandseinheit.bestandseinheitId,
                vertragId
              )}
            >
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      );
    },
  },
  {
    title: 'Original/Kopie',
    dataIndex: InfoMailDeliveryRecipientListStepOrderBy.OriginalKopie,
    sorter: true,
    render: (_, record) => (record.original ? <Tag>Original</Tag> : <Tag>Kopie</Tag>),
  },
  {
    title: 'Vertragspartner',
    dataIndex: InfoMailDeliveryRecipientListStepOrderBy.Vertragspartner,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText text={record.vertragspartner.kurzBezeichnung} maxTextLength={25}>
        {(shortenedText) => <Link to={generatePathToRechtstraegerDetailsPage(record.vertragspartner.rechtstraegerId)}>{shortenedText}</Link>}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Empfänger',
    dataIndex: InfoMailDeliveryRecipientListStepOrderBy.Recipient,
    sorter: true,
    render: (text, record) =>
      record.vertragspartner.rechtstraegerId !== record.recipient.rechtstraegerId ? (
        <DataWithShortenedText text={record.recipient.kurzBezeichnung} maxTextLength={20}>
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          {(shortenedText) => <Link to={generatePathToRechtstraegerDetailsPage(record.recipient.rechtstraegerId)}>{shortenedText}</Link>}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'E-Mail',
    dataIndex: InfoMailDeliveryRecipientListStepOrderBy.Email,
    sorter: true,
    render: (text, record) => <Typography.Text>{record.emailAddress}</Typography.Text>,
  },
  {
    title: 'Senden',
    align: 'center',
    render: (text, record) => (
      <SendCheckboxColumn infoMailDelivery={record} isCompleted={isCompleted} onUpdate={onUpdate} wizardId={infoMailDeliveryWizardId} />
    ),
  },
];

export default recipientTableColumns;
