import { FC } from 'react';
import VersionTimeline from '../../../../components/Timeline/VersionTimeline';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { showSuccessMsgDelete } from '../../../../components/message';
import { RepFondsEinstellung } from '../../../../types';
import { useRepFondsEinstellungTemplateListQuery } from '../../gql/RepFondsEinstellungTemplateQueries.types';
import { useDeleteRepFondsEinstellungTemplateMutation } from '../../gql/RepFondsEinstellungTemplateMutations.types';
import RepFondsTemplateKontenZuweisungVersionCardContent from './RepFondsTemplateKontenZuweisungVersionCardContent';
import RepFondsTemplateKontenZuweisungVersionForm from './Form/RepFondsTemplateKontenZuweisungVersionForm';

type Props = {
  reloadRepFondsCurrentKontenZuweisung: () => void;
};

const RepFondsTemplateKontenZuweisungVersionTimeline: FC<Props> = ({ reloadRepFondsCurrentKontenZuweisung }) => {
  const { data, loading, refetch: reloadVersionList } = useRepFondsEinstellungTemplateListQuery({});

  const repFondsEinstellungList = data?.getRepFondsEinstellungTemplateList.data ?? [];

  const onActionSuccess = () => {
    reloadRepFondsCurrentKontenZuweisung();
    reloadVersionList();
  };

  const [runDelete] = useDeleteRepFondsEinstellungTemplateMutation({
    onCompleted: () => {
      showSuccessMsgDelete('Reparaturfonds-Kontenzuweisung');
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <VersionTimeline<RepFondsEinstellung>
      dataSource={repFondsEinstellungList}
      versionIdKey="repFondsEinstellungId"
      loading={loading}
      defaultNewTimeblock={{
        repFondsEinstellungId: '',
        kontenZuweisungList: [],
        warningList: [],
        versionIndex: 0,
      }}
      isAllowedToDeleteInitialTimeblock
      kommentarForCardTitle={(timeblock) => timeblock.kommentar}
      onDelete={(timeblock) => runDelete({ variables: { repFondsEinstellungId: timeblock.repFondsEinstellungId } })}
      renderCardContent={(timeblock) => <RepFondsTemplateKontenZuweisungVersionCardContent timeblock={timeblock} />}
      renderForm={(timeblock) => <RepFondsTemplateKontenZuweisungVersionForm timeblock={timeblock} onSubmitSuccess={onActionSuccess} />}
    />
  );
};

export default RepFondsTemplateKontenZuweisungVersionTimeline;
