import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, MenuProps } from 'antd';
import { DeleteOutlined, DownOutlined, EyeOutlined } from '@ant-design/icons';
import { generatePathToWizardPage } from '../../EmailDelivery/emailDeliveryUriPaths';
import confirmDeleteWizard from '../Shared/confirmDeleteWizard';
import { useDeleteInfoMailDeliveryWizardMutation } from './gql/InfoMailDeliveryWizardMutations.types';
import { showSuccessMsgDelete } from '../../../components/message';
import { WizardStatus } from '../../../types';
import { WizardListingTableActionsProps } from '../wizardHelpers';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';

const ListingTableActions: FC<WizardListingTableActionsProps> = ({ wizard, onAction }) => {
  const navigate = useNavigate();
  const [runDelete] = useDeleteInfoMailDeliveryWizardMutation({
    onCompleted: () => {
      showSuccessMsgDelete('Assistent');
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onConfirm = () => {
    runDelete({ variables: { infoMailDeliveryWizardId: wizard.wizardId } });
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Details',
      onClick: () => navigate(generatePathToWizardPage(wizard.wizardId, wizard.currentStep)),
      icon: <EyeOutlined />,
    },
    {
      key: '2',
      label: 'Löschen',
      onClick: () => confirmDeleteWizard(onConfirm),
      icon: <DeleteOutlined />,
      disabled: wizard.status.value === WizardStatus.Completed,
      danger: true,
    },
  ];

  return (
    <Dropdown menu={{ items }}>
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        Aktion <DownOutlined />
      </a>
    </Dropdown>
  );
};
export default ListingTableActions;
