import { FormFields } from '../../../../../../helpers/formikHelper';
import { AddressType } from '../../../../../../types';

export interface StreetAddressCreateFormValues {
  type: AddressType.Street;
  countryCodeIso2: string;
  city: string;
  cityAdditionalInformation: string;
  street: string;
  houseEntranceApartmentNumber: string;
  zipCode: string;
}

export const streetAddressCreateFormFields: FormFields<StreetAddressCreateFormValues> = {
  type: 'type',
  countryCodeIso2: 'countryCodeIso2',
  city: 'city',
  cityAdditionalInformation: 'cityAdditionalInformation',
  street: 'street',
  houseEntranceApartmentNumber: 'houseEntranceApartmentNumber',
  zipCode: 'zipCode',
};

export const streetAddressCreateFormInitialValues: StreetAddressCreateFormValues = {
  type: AddressType.Street,
  countryCodeIso2: '',
  city: '',
  cityAdditionalInformation: '',
  street: '',
  houseEntranceApartmentNumber: '',
  zipCode: '',
};
