import React from 'react';
import { Route } from 'react-router';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import RechtsTraegerDashboardPage from './RechtstraegerPage';
import AuthRoute from '../../security/AuthRoute';
import { URI_RECHTSTRAEGER_PAGE } from '../../features/Rechtstraeger/rechtstraegerUriPaths';

const rechtstraegerPageRoute = (
  <Route
    path={`${URI_RECHTSTRAEGER_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<RechtsTraegerDashboardPage />} />}
  />
);

export default rechtstraegerPageRoute;
