import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type KontoFieldsFragment = {
  bezeichnung: string;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  haupt: boolean;
  kontoId: string;
  kontoTemplateId?: string | null;
  nummer: string;
  tagList: Array<string>;
  updatedByMitarbeiterId?: string | null;
  klasse: { text: string; value: Types.KontoKlasse };
  status: { description?: string | null; text: string; value: Types.KontoStatus };
  type: { text: string; value: Types.KontoType };
  verwendung?: { text: string; value: Types.KontoVerwendung } | null;
};

export type OrderKontoFragment = { bezeichnung: string; kontoId: string; nummer: string };

export type AppKontoFieldsFragment = { bezeichnung: string; firmendatenId?: string | null; kontoId: string; kontoKlasse: string; nummer: string };

export type FibuKontoFieldsFragment = {
  bezeichnung: string;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  haupt: boolean;
  kontoId: string;
  kontoTemplateId?: string | null;
  nummer: string;
  tagList: Array<string>;
  updatedByMitarbeiterId?: string | null;
  klasse: { text: string; value: Types.KontoKlasse };
  status: { description?: string | null; text: string; value: Types.KontoStatus };
  type: { text: string; value: Types.KontoType };
  verwendung?: { text: string; value: Types.KontoVerwendung } | null;
};

export type KontenplanFieldsFragment = {
  createdBy?: string | null;
  createTs: string;
  createdByMitarbeiterId?: string | null;
  kontenplanId: string;
  hauptUmsatzsteuerKonto?: {
    bezeichnung: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    haupt: boolean;
    kontoId: string;
    kontoTemplateId?: string | null;
    nummer: string;
    tagList: Array<string>;
    updatedByMitarbeiterId?: string | null;
    klasse: { text: string; value: Types.KontoKlasse };
    status: { description?: string | null; text: string; value: Types.KontoStatus };
    type: { text: string; value: Types.KontoType };
    verwendung?: { text: string; value: Types.KontoVerwendung } | null;
  } | null;
  hauptVorsteuerKonto?: {
    bezeichnung: string;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    haupt: boolean;
    kontoId: string;
    kontoTemplateId?: string | null;
    nummer: string;
    tagList: Array<string>;
    updatedByMitarbeiterId?: string | null;
    klasse: { text: string; value: Types.KontoKlasse };
    status: { description?: string | null; text: string; value: Types.KontoStatus };
    type: { text: string; value: Types.KontoType };
    verwendung?: { text: string; value: Types.KontoVerwendung } | null;
  } | null;
};

export const OrderKontoFragmentDoc = gql`
  fragment OrderKonto on OrderKonto {
    bezeichnung
    kontoId
    nummer
  }
`;
export const AppKontoFieldsFragmentDoc = gql`
  fragment AppKontoFields on AppKonto {
    bezeichnung
    firmendatenId
    kontoId
    kontoKlasse
    nummer
  }
`;
export const FibuKontoFieldsFragmentDoc = gql`
  fragment FibuKontoFields on FibuKonto {
    bezeichnung
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    haupt
    klasse {
      text
      value
    }
    kontoId
    kontoTemplateId
    nummer
    status {
      description
      text
      value
    }
    tagList
    type {
      text
      value
    }
    updatedByMitarbeiterId
    verwendung {
      text
      value
    }
  }
`;
export const KontoFieldsFragmentDoc = gql`
  fragment KontoFields on Konto {
    bezeichnung
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    haupt
    klasse {
      text
      value
    }
    kontoId
    kontoTemplateId
    nummer
    status {
      description
      text
      value
    }
    tagList
    type {
      text
      value
    }
    updatedByMitarbeiterId
    verwendung {
      text
      value
    }
  }
`;
export const KontenplanFieldsFragmentDoc = gql`
  fragment KontenplanFields on Kontenplan {
    createdBy
    createTs
    createdByMitarbeiterId
    hauptUmsatzsteuerKonto {
      ...KontoFields
    }
    hauptVorsteuerKonto {
      ...KontoFields
    }
    kontenplanId
  }
  ${KontoFieldsFragmentDoc}
`;
