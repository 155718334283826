import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';
import {
  BkOrWeAbrDefFieldHelpNames,
  BkOrWeAbrDefObjektOutputOptionsFieldHelpNames,
  BkOrWeAbrDefTopOutputOptionsFieldHelpNames,
} from './abrDefFieldHelpMapper';

export const useBkAbrDefFieldHelp = (): BkOrWeAbrDefFieldHelpNames => {
  const getFieldHelpText = useGetAppFieldHelpText<'BkAbrechnungsdefinition'>('BkAbrechnungsdefinition');

  const fieldHelpAbrDefAbrechkreisList = getFieldHelpText('BkAbrechnungsdefinition.abrechdefAbrechkreisList');

  const fieldHelpNamesObjekt: BkOrWeAbrDefObjektOutputOptionsFieldHelpNames = {
    buchungsdetails: getFieldHelpText('BkAbrechnungsdefinition.objektAbrechnungOutputOptions.buchungsdetails'),
    consolidateEinnahmen: getFieldHelpText('BkAbrechnungsdefinition.objektAbrechnungOutputOptions.consolidateEinnahmen'),
    displayEinnahmen: getFieldHelpText('BkAbrechnungsdefinition.objektAbrechnungOutputOptions.displayEinnahmen'),
    displayKontonummer: getFieldHelpText('BkAbrechnungsdefinition.objektAbrechnungOutputOptions.displayKontonummer'),
  };

  const fieldHelpNamesTop: BkOrWeAbrDefTopOutputOptionsFieldHelpNames = {
    considerRueckstand: getFieldHelpText('BkAbrechnungsdefinition.topAbrechnungOutputOptions.considerRueckstand'),
    displayDueDate: getFieldHelpText('BkAbrechnungsdefinition.topAbrechnungOutputOptions.displayDueDate'),
    displayRueckstandOtherPersons: getFieldHelpText('BkAbrechnungsdefinition.topAbrechnungOutputOptions.displayRueckstandOtherPersons'),
    displayZahlungsverlauf: getFieldHelpText('BkAbrechnungsdefinition.topAbrechnungOutputOptions.displayZahlungsverlauf'),
    dueDateDisplayOptionMieter: {
      month: getFieldHelpText('BkAbrechnungsdefinition.topAbrechnungOutputOptions.dueDateDisplayOptionMieter.month'),
      type: getFieldHelpText('BkAbrechnungsdefinition.topAbrechnungOutputOptions.dueDateDisplayOptionMieter.type'),
    },
    dueDateDisplayOptionEigentuemerGuthaben: {
      month: getFieldHelpText('BkAbrechnungsdefinition.topAbrechnungOutputOptions.dueDateDisplayOptionEigentuemerGuthaben.month'),
      type: getFieldHelpText('BkAbrechnungsdefinition.topAbrechnungOutputOptions.dueDateDisplayOptionEigentuemerGuthaben.type'),
    },
    dueDateDisplayOptionEigentuemerNachzahlung: {
      month: getFieldHelpText('BkAbrechnungsdefinition.topAbrechnungOutputOptions.dueDateDisplayOptionEigentuemerNachzahlung.month'),
      type: getFieldHelpText('BkAbrechnungsdefinition.topAbrechnungOutputOptions.dueDateDisplayOptionEigentuemerNachzahlung.type'),
    },
    rueckstandOtherPersonsThreshold: getFieldHelpText('BkAbrechnungsdefinition.topAbrechnungOutputOptions.rueckstandOtherPersonsThreshold'),
  };

  return { fieldHelpAbrDefAbrechkreisList, fieldHelpNamesObjekt, fieldHelpNamesTop };
};
