import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BezugsbasisListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type BezugsbasisListQuery = {
  getBezugsbasisList: {
    data: Array<{ text: string; value: Types.Bezugsbasis }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const BezugsbasisListDocument = gql`
  query BezugsbasisList {
    getBezugsbasisList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBezugsbasisListQuery(baseOptions?: Apollo.QueryHookOptions<BezugsbasisListQuery, BezugsbasisListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BezugsbasisListQuery, BezugsbasisListQueryVariables>(BezugsbasisListDocument, options);
}
export function useBezugsbasisListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BezugsbasisListQuery, BezugsbasisListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BezugsbasisListQuery, BezugsbasisListQueryVariables>(BezugsbasisListDocument, options);
}
export function useBezugsbasisListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BezugsbasisListQuery, BezugsbasisListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BezugsbasisListQuery, BezugsbasisListQueryVariables>(BezugsbasisListDocument, options);
}
export type BezugsbasisListQueryHookResult = ReturnType<typeof useBezugsbasisListQuery>;
export type BezugsbasisListLazyQueryHookResult = ReturnType<typeof useBezugsbasisListLazyQuery>;
export type BezugsbasisListSuspenseQueryHookResult = ReturnType<typeof useBezugsbasisListSuspenseQuery>;
export type BezugsbasisListQueryResult = Apollo.QueryResult<BezugsbasisListQuery, BezugsbasisListQueryVariables>;
