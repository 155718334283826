import React, { FC } from 'react';
import { Empty } from 'antd';
import syncEbicsUserVerarbeitungEntryTableColumns from './syncEbicsUserVerarbeitungEntryTableColumns';
import { isVerarbeitungEntryTableRowExpandable } from '../../verarbeitungHelpers';
import GenerierlaufStatusExpandedRow from '../../shared/GenerierlaufStatusExpandedRow';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { SyncEbicsUserGenerierlauf, SyncEbicsUserGenerierlaufEntry } from '../../../../types';
import { TSyncEbicsUserVerarbeitungQueryParams } from './Filters/filtersQueryParams';

type Props = {
  queryParams: TSyncEbicsUserVerarbeitungQueryParams;
  generierlauf: SyncEbicsUserGenerierlauf;
};

const SyncEbicsUserVerarbeitungEntryTable: FC<Props> = ({ queryParams, generierlauf }) => {
  const { entryList } = generierlauf;

  const { mitarbeiterId, teilnehmer, userId, partnerId, bankname, ebicsStatus, exitCodeList } = queryParams;

  const filterForMitarbeiterId = (entry: SyncEbicsUserGenerierlaufEntry) =>
    mitarbeiterId ? entry.ebicsUser?.mitarbeiterId === mitarbeiterId : entry;

  const filterForTeilnehmer = (entry: SyncEbicsUserGenerierlaufEntry) => (teilnehmer ? entry.ebicsUser?.name === teilnehmer : entry);

  const filterForTeilnehmerkennung = (entry: SyncEbicsUserGenerierlaufEntry) => (userId ? entry.ebicsUser?.userId === userId : entry);

  const filterForKundenkennung = (entry: SyncEbicsUserGenerierlaufEntry) => (partnerId ? entry.ebicsUser?.partnerId === partnerId : entry);

  const filterForBankname = (entry: SyncEbicsUserGenerierlaufEntry) => (bankname ? entry.ebicsUser?.bankname === bankname : entry);

  const filterForEbicsStatus = (entry: SyncEbicsUserGenerierlaufEntry) => (ebicsStatus ? entry.ebicsUser?.status.value === ebicsStatus : entry);

  const filterForExitCode = (entry: SyncEbicsUserGenerierlaufEntry) =>
    entry.exitCode && exitCodeList && exitCodeList.length > 0 ? exitCodeList.includes(entry.exitCode.value) : entry;

  const filteredEntryList = entryList
    .filter(filterForMitarbeiterId)
    .filter(filterForTeilnehmer)
    .filter(filterForTeilnehmerkennung)
    .filter(filterForKundenkennung)
    .filter(filterForBankname)
    .filter(filterForEbicsStatus)
    .filter(filterForExitCode);

  return (
    <>
      <TableWithColSelector<SyncEbicsUserGenerierlaufEntry>
        dataSource={filteredEntryList}
        columns={syncEbicsUserVerarbeitungEntryTableColumns()}
        loading={!entryList}
        locale={{
          emptyText: <Empty description={<span>Keine EBICS Bankteilnehmer vorhanden</span>} />,
        }}
        size="small"
        pagination={{ showSizeChanger: true, defaultPageSize: 20 }}
        expandable={{
          expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
          rowExpandable: isVerarbeitungEntryTableRowExpandable,
        }}
        filterIdentifier="verarbeitung-sync-ebics-user"
        rowKey={(record) => record.generierlaufEntryId}
      />
    </>
  );
};

export default SyncEbicsUserVerarbeitungEntryTable;
