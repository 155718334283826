import React from 'react';
import { Skeleton } from 'antd';
import { useParams } from 'react-router-dom';
import { StyledContentTab } from '../../../components/Card/Styled/Card.style';
import Breadcrumbs from '../../../features/Breadcrumb';
import { ContentMain } from '../../../components/LayoutAndromeda/Styled/Layout.style';
import IncomingInvoicePreviewSaved from '../../../features/IncomingInvoice/shared/IncomingInvoicePreviewSaved';
import { useStandingInvoiceForDetailsAndUpdatePageQuery } from '../../../features/IncomingInvoice/gql/StandingInvoiceQueries.types';
import IncomingInvoiceForm from '../../../features/IncomingInvoice/Form/IncomingInvoiceForm';
import { StyledSpaceInvoicePage } from '../../../shared/Invoice/styled/StyledInvoicePage.styled';

type RouteParams = { fileId: string; belegId: string };

const StandingInvoiceUpdatePage = () => {
  const { fileId, belegId } = useParams() as RouteParams;

  const { loading, data, refetch } = useStandingInvoiceForDetailsAndUpdatePageQuery({
    variables: { belegId },
  });

  const standingInvoice = data?.getStandingInvoiceForDetailsAndUpdatePage.data;
  const standingInvoiceErrorList = data?.getStandingInvoiceForDetailsAndUpdatePage.errorList;

  return (
    <>
      <Breadcrumbs />
      <ContentMain style={{ borderRadius: '4px' }}>
        <StyledContentTab>
          <StyledSpaceInvoicePage>
            <IncomingInvoicePreviewSaved fileId={fileId} />
            <Skeleton active paragraph loading={loading}>
              {standingInvoice ? (
                <IncomingInvoiceForm
                  fileId={fileId}
                  incomingInvoice={standingInvoice}
                  incomingInvoiceLoading={loading}
                  refetchIncomingInvoice={refetch}
                  errorList={standingInvoiceErrorList}
                />
              ) : null}
            </Skeleton>
          </StyledSpaceInvoicePage>
        </StyledContentTab>
      </ContentMain>
    </>
  );
};

export default StandingInvoiceUpdatePage;
