import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { TopAbrechnung } from '../../topAbrechnung-types';

const TAAbrKreisDetailsColumnTitles: FC<{ data: TopAbrechnung }> = ({ data }) => (
  <View fixed>
    <View style={[pdfStyles.row, pdfStyles.textNormal, pdfStyles.textUppercase, { paddingTop: '10mm' }]}>
      <Text style={[pdfStyles.column, { width: '30%' }]}>{data.abrechnungskreisDetails.header.abrechnungsKreisText}</Text>
      <Text style={[pdfStyles.column, { width: '30%', textAlign: 'right' }]}>{data.abrechnungskreisDetails.header.betragText}</Text>
      <Text style={[pdfStyles.column, { width: '40%', textAlign: 'right' }]}>{data.abrechnungskreisDetails.header.summeText}</Text>
    </View>

    <View style={[pdfStyles.row, pdfStyles.borderBottomSmall, { marginTop: '2mm', borderBottomStyle: 'dotted' }]} />
  </View>
);

export default TAAbrKreisDetailsColumnTitles;
