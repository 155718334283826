import React, { FC } from 'react';
import { Empty } from 'antd';
import TableWithColSelector from '../../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { FibuBuchungszeile } from '../../../../../../types';
import buchungszeileTableColumns from './buchungszeileTableColumns';

type Props = {
  buchungszeileList: FibuBuchungszeile[];
  selectedRow: FibuBuchungszeile | undefined;
  setSelectedRow: React.Dispatch<React.SetStateAction<FibuBuchungszeile | undefined>>;
};

const BuchungszeileTable: FC<Props> = ({ buchungszeileList, selectedRow, setSelectedRow }) => {
  return (
    <TableWithColSelector<FibuBuchungszeile>
      size="small"
      dataSource={buchungszeileList}
      rowKey={(record) => record.buchungszeileId}
      columns={buchungszeileTableColumns()}
      filterIdentifier="buchungsmaske-buchungszeile"
      rowSelection={{
        type: 'radio',
        selectedRowKeys: selectedRow ? [selectedRow.buchungszeileId] : [],
        onSelect: (record) => setSelectedRow(record),
      }}
      locale={{
        emptyText: <Empty description={<span>Keine Buchungen vorhanden</span>} />,
      }}
      pagination={{ showSizeChanger: true, defaultPageSize: 30, pageSizeOptions: [10, 20, 30, 50, 100] }}
    />
  );
};

export default BuchungszeileTable;
