import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { LabeledValue } from './types';
import pdfStyles from '../styles/pdfStyles';

const LabelValue: FC<{ valueTuple: LabeledValue; viewStyle?: Style; labelStyle?: Style; valueStyle?: Style }> = ({
  valueTuple,
  viewStyle,
  labelStyle,
  valueStyle,
}) => (
  <View style={[pdfStyles.row, { ...viewStyle }]}>
    <Text style={[pdfStyles.textNormal, { ...labelStyle }]}>{valueTuple.label}</Text>
    <Text style={[pdfStyles.textNormal, { ...valueStyle }]}>{valueTuple.value}</Text>
  </View>
);

export default LabelValue;
