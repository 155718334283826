import React from 'react';
import { Link } from 'react-router-dom';
import { VertragListEntry, VertragOrderBy } from '../../../../../types';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import CurrentBasedData from '../../../../../components/Helpers/CurrentBasedData';
import { isEntityCurrent } from '../../../../../helpers/dateHelper';
import { generatePathToObjektDetailsPage } from '../../../../Objekt/objektUriPaths';
import { generatePathToBestandseinheitDetailsPage } from '../../../../Bestandseinheit/bestandseinheitUriPaths';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { EuroAmount } from '../../../../../components/Number';
import StatusBadge from '../../../../../components/Status/StatusBadge';
import { generatePathToBeVertragDetailsPage } from '../../../../Vertrag/BeVertrag/beVertragUriPaths';
import { generatePathToRechtstraegerDetailsPage } from '../../../rechtstraegerHelper';
import { generatePathToKontoblattPage } from '../../../../Kontoblatt/kontoblattUriPaths';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import { isVertragsartTypeLeerstehung } from '../../../../Vertrag/shared/vertragsartHelpers';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../../../../components/Card';

const weAndMietVertragTableColumns = (leerstehungForderungskontoId?: string | null): TableWithColSelectorColumnProps<VertragListEntry>[] => [
  {
    title: 'Vertragsart',
    defaultSelected: true,
    dataIndex: VertragOrderBy.Vertragsart,
    sorter: true,
    render: (text, record) => (
      <CurrentBasedData text={record.vertragsart.text} isCurrent={isEntityCurrent(record.vertragsEnde)} warningList={record.warningList} />
    ),
  },
  {
    title: 'Objekt',
    defaultSelected: true,
    dataIndex: VertragOrderBy.Objekt,
    sorter: true,
    render: (text, record) => (
      <CurrentBasedData
        text={record.objektKurzBezeichnung}
        isCurrent={isEntityCurrent(record.vertragsEnde)}
        maxTextLength={30}
        openLinkInNewTab
        linkTo={generatePathToObjektDetailsPage(record.objektId)}
      />
    ),
  },
  {
    title: 'Bestandeinheit',
    defaultSelected: true,
    dataIndex: VertragOrderBy.Bestandseinheit,
    sorter: true,
    render: (text, record) => (
      <CurrentBasedData
        text={record.bestandseinheitBezeichnung}
        isCurrent={isEntityCurrent(record.vertragsEnde)}
        maxTextLength={30}
        openLinkInNewTab
        linkTo={generatePathToBestandseinheitDetailsPage(record.objektId, record.bestandseinheitId)}
      />
    ),
  },
  {
    title: 'Rechnungsaussteller',
    defaultSelected: true,
    render: (text, record) => {
      const rechnungsausstellerList = record.rechnungsausstellerList.map((rechnungsaussteller) => {
        return `${rechnungsaussteller.rechtstraeger.kurzBezeichnung} ab ${dayjsCustom(rechnungsaussteller.date).format('DD.MM.YYYY')}`;
      });

      const firstRechnungsaussteller = rechnungsausstellerList[0];
      const remainingRechnungsausstellerList = rechnungsausstellerList.slice(1);

      return (
        <CurrentBasedData text="" isCurrent={isEntityCurrent(record.vertragsEnde)}>
          <DataWithShortenedText maxTextLength={20} text={firstRechnungsaussteller} extraTexts={remainingRechnungsausstellerList} />
        </CurrentBasedData>
      );
    },
  },
  {
    title: 'Vertragspartner',
    defaultSelected: true,
    dataIndex: VertragOrderBy.Vertragspartner,
    sorter: true,
    render: (text, record) => (
      <CurrentBasedData
        text={record.vertragspartnerKurzBezeichnung}
        isCurrent={isEntityCurrent(record.vertragsEnde)}
        maxTextLength={30}
        openLinkInNewTab
        linkTo={generatePathToRechtstraegerDetailsPage(record.vertragspartnerId)}
      />
    ),
  },
  {
    title: 'Von',
    width: 80,
    defaultSelected: true,
    dataIndex: VertragOrderBy.VertragsBeginn,
    sorter: true,
    render: (text, record) => (
      <CurrentBasedData text="" isCurrent={isEntityCurrent(record.vertragsEnde)}>
        <CustomFormattedDate value={record.vertragsBeginn} />
      </CurrentBasedData>
    ),
  },
  {
    title: 'Bis',
    width: 80,
    defaultSelected: true,
    dataIndex: VertragOrderBy.VertragsEnde,
    sorter: true,
    render: (text, record) => (
      <CurrentBasedData text="" isCurrent={isEntityCurrent(record.vertragsEnde)}>
        {record.vertragsEnde ? <CustomFormattedDate value={record.vertragsEnde} /> : <TextForEmptyValue textToShow="minus" />}
      </CurrentBasedData>
    ),
  },
  {
    title: 'Netto',
    defaultSelected: true,
    dataIndex: VertragOrderBy.Netto,
    sorter: true,
    render: (text, record) => {
      let linkTo = record.buchungskreisId
        ? generatePathToKontoblattPage({
            buchungskreisId: record.buchungskreisId,
            objektId: record.objektId,
            kontoId: record.debitorKontoId,
          })
        : undefined;

      if (isVertragsartTypeLeerstehung(record.vertragsart.value) && leerstehungForderungskontoId) {
        linkTo = generatePathToKontoblattPage({
          buchungskreisId: record.buchungskreisId,
          objektId: record.objektId,
          kontoId: leerstehungForderungskontoId,
        });
      }

      return (
        <CurrentBasedData text="" isCurrent={isEntityCurrent(record.vertragsEnde)} openLinkInNewTab linkTo={linkTo}>
          <EuroAmount value={record.netto} />
        </CurrentBasedData>
      );
    },
  },
  {
    title: 'Ust',
    defaultSelected: true,
    dataIndex: VertragOrderBy.Ust,
    sorter: true,
    render: (text, record) => (
      <CurrentBasedData text="" isCurrent={isEntityCurrent(record.vertragsEnde)}>
        <EuroAmount value={record.ust} />
      </CurrentBasedData>
    ),
  },
  {
    title: 'Brutto',
    defaultSelected: true,
    dataIndex: VertragOrderBy.Brutto,
    sorter: true,
    render: (text, record) => (
      <CurrentBasedData text="" isCurrent={isEntityCurrent(record.vertragsEnde)}>
        <EuroAmount value={record.brutto} />
      </CurrentBasedData>
    ),
  },
  {
    title: 'Status',
    defaultSelected: true,
    dataIndex: VertragOrderBy.Status,
    sorter: true,
    render: (text, record) => (
      <CurrentBasedData text="" isCurrent={isEntityCurrent(record.vertragsEnde)}>
        <StatusBadge entityStatus={record.status} />
      </CurrentBasedData>
    ),
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => (
      <Link to={generatePathToBeVertragDetailsPage(record.objektId, record.bestandseinheitId, record.vertragId)} target="_blank">
        Details
      </Link>
    ),
  },
];

export default weAndMietVertragTableColumns;
