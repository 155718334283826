import React, { FC } from 'react';
import { Breadcrumb } from 'antd';
import { Spacer } from '../../components/Grid';
import { contactList } from '../../features/Contact/mockContact';
import ContactCard from '../../features/Contact/Renderers/ContactCard';
import DetailsPageContentMain from '../../components/Template/DetailsPageContentMain';
import menuListContact from './menuListContact';

const contactSollingergasse = contactList[0];
const contactBarthgasse = contactList[1];

// @TODO desired behaviour first is rendered acutally on intial rendering of BestandseinheitDetailsPage

type Props = {
  rechtstraegerId?: string;
  contact?: any;
};

export const ContactDetailsPage: FC<Props> = ({ rechtstraegerId, contact }) => {
  //FIXME: rechtstraegerId is not provided/not matching 1000 nor 2002
  // (the following line is a temporary solution)
  contact = contactSollingergasse;

  if (rechtstraegerId === '1000') {
    // eslint-disable-next-line no-param-reassign
    contact = contactSollingergasse;
  }
  if (rechtstraegerId === '2002') {
    // eslint-disable-next-line no-param-reassign
    contact = contactBarthgasse;
  }

  return (
    <>
      <Breadcrumb style={{ padding: '16px 16px', background: 'white' }}>
        <Breadcrumb.Item>{contact.kurzBezeichnung}</Breadcrumb.Item>
      </Breadcrumb>
      <Spacer />
      <DetailsPageContentMain
        card={<ContactCard contact={contactSollingergasse} />}
        sidebarMenuList={menuListContact(contact)}
        contentDefaultSelected="Personenbezüge"
        contentContextList={['EIGENTUEMER', 'MIETER', 'RECHNUNGS_AUSSTELLER']}
      />
    </>
  );
};

export default ContactDetailsPage;
