import React from 'react';
import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../components/Template/DetailsPageTemplate';
import { SubAbrechnungDetailsPageRouteParams } from './routes';
import { useSubAbrechnungQuery } from '../../features/Abrechnung/Sub/gql/SubAbrechnungQueries.types';
import { SubAbrechnung } from '../../types';
import menuListSubAbrechnung from './menuListSubAbrechnung';
import SubAbrechnungCard from '../../features/Abrechnung/Sub/Card/SubAbrechnungCard';

const SubAbrechnungDetailsPage = () => {
  const { objektId, subAbrechnungId } = useParams() as SubAbrechnungDetailsPageRouteParams;
  const { data, loading } = useSubAbrechnungQuery({ variables: { objektId, subAbrechnungId } });
  const subAbrechnung = data?.getSubAbrechnung.data;

  return (
    <DetailsPageTemplate<SubAbrechnung>
      pageTitle={() => 'Subverwaltungsabrechnung'}
      data={subAbrechnung}
      card={(subAbrechnung) => <SubAbrechnungCard abrechnung={subAbrechnung} objektId={objektId} loading={loading} />}
      sidebarMenuList={(subAbrechnung) => menuListSubAbrechnung(objektId, subAbrechnung)}
    />
  );
};

export default SubAbrechnungDetailsPage;
