import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../styles/pdfStyles';
import { AbrechnungskreisSummaryAbrechnungskreis } from '../../../topAbrechnung-types';

const TAAbrechnungskreis: FC<{ abrechKreis: AbrechnungskreisSummaryAbrechnungskreis }> = ({ abrechKreis }) => (
  <View style={[pdfStyles.row]}>
    <Text
      style={[
        pdfStyles.textNormal,
        {
          textAlign: 'left',
          fontWeight: 'bold',
          width: '15%',
        },
      ]}
    >
      {abrechKreis.bezeichnung}
    </Text>
    <Text style={[pdfStyles.textBulletin, { textAlign: 'right', width: '15%' }]}>{abrechKreis.aufwandBetrag}</Text>
    <Text
      style={[
        pdfStyles.textBulletin,
        {
          textAlign: 'right',
          width: '15%',
        },
      ]}
    >
      {abrechKreis.vorschreibungBetrag}
    </Text>
    <Text style={[pdfStyles.textBulletin, { textAlign: 'right', width: '15%' }]}>{abrechKreis.nettoBetrag}</Text>
    <Text style={[pdfStyles.textBulletin, { textAlign: 'right', width: '15%' }]}>{abrechKreis.ustBetrag}</Text>
    <Text style={[pdfStyles.textBulletin, { textAlign: 'right', width: '15%' }]}>{abrechKreis.saldoBetrag}</Text>
  </View>
);

export default TAAbrechnungskreis;
