import React from 'react';
import { FormattedNumber } from 'react-intl';
import { ColumnProps } from 'antd/es/table';
import { Percent } from '../../../../components/Number';
import { BestandseinheitNutzung } from '../../../../types';

export const bestandseinheitSumsTableColumns = (isWegObjekt: boolean): ColumnProps<BestandseinheitNutzung>[] => [
  {
    render: (text, record) => (
      <span>
        Nutzfläche <FormattedNumber value={record.nutzflaeche ?? 0} /> &emsp;&emsp; Anteil <Percent value={record.nutzflaecheAnteil / 100} />
      </span>
    ),
  },
  {
    render: (text, record) =>
      isWegObjekt ? (
        <span>
          Nutzwert <FormattedNumber value={record.nutzwert ?? 0} /> &emsp;&emsp; Anteil <Percent value={record.nutzwertAnteil / 100} />
        </span>
      ) : undefined,
  },
];
