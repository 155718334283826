import React, { FC } from 'react';
import { Divider, Space, Typography } from 'antd';
import { Budgeting } from '../../../types';
import CardWithFooter from '../../../components/Card';
import CardTitle from '../../../components/Card/CardTitle';
import { EuroAmount, Percent } from '../../../components/Number';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import BudgetingStatusTag from './BudgetingStatusTag';
import { mapFormDateValueToDateString } from '../../../helpers/formikHelper';
import { dayjsCustom } from '../../../helpers/dayjsCustom';
import BudgetingActions from './BudgetingActions';
import BudgetingAbrDefInfoRows from './BudgetingAbrDefInfoRows';
import BudgetingResultByVertragLevelDetails from './BudgetingResultByVertragLevelDetails/BudgetingResultByVertragLevelDetails';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';
import { isNotNil } from '../../../helpers/assertionHelper';

type Props = {
  budgeting: Budgeting;
  objektId: string;
  refetch: () => Promise<unknown>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isUpdating: boolean;
  setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>;
};

const BudgetingCard: FC<Props> = ({ budgeting, objektId, refetch, isUpdating, setIsUpdating, isLoading, setIsLoading }) => (
  <CardWithFooter
    loading={false}
    createTs={budgeting.createTs}
    title={<CardTitle title={budgeting.bezeichnung} />}
    style={{ minWidth: '320px' }}
    size="default"
    bordered={false}
    userId={budgeting.createdBy}
    mitarbeiterId={budgeting.createdByMitarbeiterId}
    extra={
      <BudgetingActions
        budgeting={budgeting}
        objektId={objektId}
        onAction={refetch}
        isUpdating={isUpdating}
        setIsUpdating={setIsUpdating}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    }
  >
    <CardDetailsInfoRow infoRowTitle="Status">
      <BudgetingStatusTag documentStatus={budgeting.status} />
    </CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle="Budgetierungsjahr">{budgeting.currentYear}</CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle="Berachtungszeitraum">
      <Space size={4}>
        <CustomFormattedDate value={mapFormDateValueToDateString(dayjsCustom(budgeting.considerationPeriodUntil).startOf('year'))} />
        <Typography.Text>-</Typography.Text>
        <CustomFormattedDate value={mapFormDateValueToDateString(dayjsCustom(budgeting.considerationPeriodUntil).endOf('month'))} />
      </Space>
    </CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle="Vorjahr">{budgeting.prevYear}</CardDetailsInfoRow>
    <BudgetingAbrDefInfoRows budgeting={budgeting} />
    <Divider dashed />
    <Typography.Title level={5}>Vertragswerte</Typography.Title>
    <CardDetailsInfoRow infoRowTitle="Vertagswert-Datum">
      {budgeting.vertragVPosWertDate ? <CustomFormattedDate value={budgeting.vertragVPosWertDate} /> : <TextForEmptyValue textToShow="minus" />}
    </CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle="Verarbeitet aus">
      {budgeting.resultSourceType ? <Typography.Text>{budgeting.resultSourceType.text}</Typography.Text> : <TextForEmptyValue textToShow="minus" />}
    </CardDetailsInfoRow>
    <Divider dashed />
    <Typography.Title level={5}>Berechnung</Typography.Title>
    <CardDetailsInfoRow infoRowTitle="Vorschreibung Vorjahr Summe">
      <EuroAmount value={budgeting.vorschreibungPrevYearSum} />
    </CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle="Vorschreibung aktuell Summe">
      <EuroAmount value={budgeting.vorschreibungCurrentYearSum} />
    </CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle="Hochrechnung Restjahr">
      {isNotNil(budgeting.restOfCurrentYearSum) ? <EuroAmount value={budgeting.restOfCurrentYearSum} /> : <TextForEmptyValue textToShow="minus" />}
    </CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle="Basisbetrag">
      {isNotNil(budgeting.totalCurrentYearSum) ? <EuroAmount value={budgeting.totalCurrentYearSum} /> : <TextForEmptyValue textToShow="minus" />}
    </CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle="Erhöhung %">
      {isNotNil(budgeting.increase) ? <Percent value={budgeting.increase / 100} /> : <TextForEmptyValue textToShow="minus" />}
    </CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle="Jahresbudget Summe">
      {isNotNil(budgeting.budgetSumNextYear) ? <EuroAmount value={budgeting.budgetSumNextYear} /> : <TextForEmptyValue textToShow="minus" />}
    </CardDetailsInfoRow>
    {budgeting.budgetingResultId && (
      <>
        <Divider dashed />
        <BudgetingResultByVertragLevelDetails budgetingResultId={budgeting.budgetingResultId} objektId={budgeting.objekt.objektId} />
      </>
    )}
  </CardWithFooter>
);

export default BudgetingCard;
