import React, { FC } from 'react';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import AbrDefVersionTimeline from './AbrDefVersionTimeline';
import { SubAdministrationAbrechnungsdefinitionVersionListEntry } from '../../../../types';
import { useSubAdministrationAbrechnungsdefinitionVersionListQuery } from '../../gql/SubAdministrationAbrDef/SubAdministrationAbrDefVersionQueries.types';
import { useDeleteSubAdministrationAbrechnungsdefinitionVersionMutation } from '../../gql/SubAdministrationAbrDef/SubAdministrationAbrDefVersionMutations.types';

type Props = {
  abrechnungsdefinitionId: string;
};

const AbrDefSubVersionTimeline: FC<Props> = ({ abrechnungsdefinitionId }) => {
  const { data, loading, refetch } = useSubAdministrationAbrechnungsdefinitionVersionListQuery({
    variables: { abrechnungsdefinitionId, withDetails: true },
  });
  const versionList = data?.getSubAdministrationAbrechnungsdefinitionVersionList.data;

  const [deleteSubAbrDefVersion] = useDeleteSubAdministrationAbrechnungsdefinitionVersionMutation({
    onCompleted: () => {
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onDelete = (version: SubAdministrationAbrechnungsdefinitionVersionListEntry): Promise<unknown> => {
    return deleteSubAbrDefVersion({
      variables: {
        abrechnungsdefinitionId,
        abrechnungsdefinitionVersionId: version.abrechnungsdefinitionVersionId,
      },
    });
  };

  return <AbrDefVersionTimeline abrechnungsdefinitionId={abrechnungsdefinitionId} loading={loading} versionList={versionList} onDelete={onDelete} />;
};

export default AbrDefSubVersionTimeline;
