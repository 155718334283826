import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { ObjectOfStrings } from '../../../../shared/typeHelpers';
import { TBookingJournalQueryParams } from './filtersQueryParams';
import { BuchungSource, FibuBelegSymbol, FibuBuchungType, QueryGetFibuBuchungszeileListArgs, SollHaben, Waehrung } from '../../../../types';

export type FiltersFormValues = {
  belegSymbolList?: FibuBelegSymbol[] | null;
  belegdatumFrom?: string | null;
  belegdatumTo?: string | null;
  belegnummer?: string | null;
  bestandseinheitIdList?: string[] | null;
  betragFrom?: number | null;
  betragTo?: number | null;
  buchungsKZ?: string | null;
  buchungsTypeList?: FibuBuchungType[] | null;
  buchungsdatumFrom?: string | null;
  buchungsdatumTo?: string | null;
  createTsFrom?: string | null;
  createTsTo?: string | null;
  dueDateFrom?: string | null;
  dueDateTo?: string | null;
  erstellerMitarbeiterIdList?: string[] | null;
  fachlichePruefung?: boolean | null;
  gegenKontoId?: string | null;
  kontoId?: string | null;
  objektId?: string | null;
  rechtstraegerIdList?: string[] | null;
  sollHaben?: SollHaben | null;
  source?: BuchungSource | null;
  steuerFrom?: number | null;
  steuerTo?: number | null;
  steuersatzFrom?: number | null;
  steuersatzTo?: number | null;
  stornierteBuchungBezeichnung?: string | null;
  stornierteBuchungId?: string | null;
  storno?: boolean | null;
  text?: string | null;
  umbuchung?: boolean | null;
  ustVomAufwandSteuersatzFrom?: number | null;
  ustVomAufwandSteuersatzTo?: number | null;
  vertragspartnerId?: string | null;
  waehrung?: Waehrung | null;
};

export const filtersFormFields: FormFields<FiltersFormValues> = {
  belegSymbolList: 'belegSymbolList',
  belegdatumFrom: 'belegdatumFrom',
  belegdatumTo: 'belegdatumTo',
  belegnummer: 'belegnummer',
  bestandseinheitIdList: 'bestandseinheitIdList',
  betragFrom: 'betragFrom',
  betragTo: 'betragTo',
  buchungsKZ: 'buchungsKZ',
  buchungsTypeList: 'buchungsTypeList',
  buchungsdatumFrom: 'buchungsdatumFrom',
  buchungsdatumTo: 'buchungsdatumTo',
  createTsFrom: 'createTsFrom',
  createTsTo: 'createTsTo',
  dueDateFrom: 'dueDateFrom',
  dueDateTo: 'dueDateTo',
  erstellerMitarbeiterIdList: 'erstellerMitarbeiterIdList',
  fachlichePruefung: 'fachlichePruefung',
  gegenKontoId: 'gegenKontoId',
  kontoId: 'kontoId',
  objektId: 'objektId',
  rechtstraegerIdList: 'rechtstraegerIdList',
  sollHaben: 'sollHaben',
  source: 'source',
  steuerFrom: 'steuerFrom',
  steuerTo: 'steuerTo',
  steuersatzFrom: 'steuersatzFrom',
  steuersatzTo: 'steuersatzTo',
  stornierteBuchungBezeichnung: 'stornierteBuchungBezeichnung',
  stornierteBuchungId: 'stornierteBuchungId',
  storno: 'storno',
  text: 'text',
  umbuchung: 'umbuchung',
  ustVomAufwandSteuersatzFrom: 'ustVomAufwandSteuersatzFrom',
  ustVomAufwandSteuersatzTo: 'ustVomAufwandSteuersatzTo',
  vertragspartnerId: 'vertragspartnerId',
  waehrung: 'waehrung',
};

export const mapFormValuesToQueryParams = (formValues: FiltersFormValues): TBookingJournalQueryParams => ({
  ...formValues,
  belegdatumFrom: formValues.belegdatumFrom ? mapFormDateValueToDateString(formValues.belegdatumFrom) : undefined,
  belegdatumTo: formValues.belegdatumTo ? mapFormDateValueToDateString(formValues.belegdatumTo) : undefined,
  buchungsdatumFrom: formValues.buchungsdatumFrom ? mapFormDateValueToDateString(formValues.buchungsdatumFrom) : undefined,
  buchungsdatumTo: formValues.buchungsdatumTo ? mapFormDateValueToDateString(formValues.buchungsdatumTo) : undefined,
  createTsFrom: formValues.createTsFrom ? mapFormDateValueToDateString(formValues.createTsFrom) : undefined,
  createTsTo: formValues.createTsTo ? mapFormDateValueToDateString(formValues.createTsTo) : undefined,
  dueDateFrom: formValues.dueDateFrom ? mapFormDateValueToDateString(formValues.dueDateFrom) : undefined,
  dueDateTo: formValues.dueDateTo ? mapFormDateValueToDateString(formValues.dueDateTo) : undefined,
});

export const initialFormValues = (queryParams: TBookingJournalQueryParams): FiltersFormValues => ({
  ...queryParams,
});

export const mapQueryParamsToBuchungszeileVariables = (queryParams: TBookingJournalQueryParams): QueryGetFibuBuchungszeileListArgs => {
  return {
    ...queryParams,
    belegnummer: queryParams.belegnummer?.toString(),
    buchungsKZ: queryParams.buchungsKZ?.toString(),
  };
};

export const listingLabelList: ObjectOfStrings<
  Omit<
    FiltersFormValues,
    | 'belegdatumTo'
    | 'betragTo'
    | 'buchungsdatumTo'
    | 'buchungskreisRechtstraegerIdList'
    | 'createTsTo'
    | 'dueDateTo'
    | 'steuerTo'
    | 'steuersatzTo'
    | 'stornierteBuchungId'
    | 'ustVomAufwandSteuersatzTo'
  >
> = {
  [filtersFormFields.belegSymbolList]: 'Belegsymbol',
  [filtersFormFields.belegdatumFrom]: 'Belegdatum',
  [filtersFormFields.belegnummer]: 'Belegnummer',
  [filtersFormFields.bestandseinheitIdList]: 'Bestandseinheit',
  [filtersFormFields.betragFrom]: 'Betrag',
  [filtersFormFields.buchungsKZ]: 'Buchungskennzeichen',
  [filtersFormFields.buchungsTypeList]: 'Buchungstyp',
  [filtersFormFields.buchungsdatumFrom]: 'Buchungsdatum',
  [filtersFormFields.createTsFrom]: 'Erstellt am',
  [filtersFormFields.dueDateFrom]: 'Fälligkeit',
  [filtersFormFields.erstellerMitarbeiterIdList]: 'Ersteller',
  [filtersFormFields.fachlichePruefung]: 'Fachliche Prüfung',
  [filtersFormFields.gegenKontoId]: 'Gegenkonto',
  [filtersFormFields.kontoId]: 'Konto',
  [filtersFormFields.objektId]: 'Objekt',
  [filtersFormFields.rechtstraegerIdList]: 'Buchungskreis',
  [filtersFormFields.sollHaben]: 'Soll/Haben',
  [filtersFormFields.source]: 'Quelle',
  [filtersFormFields.steuerFrom]: 'Steuer',
  [filtersFormFields.steuersatzFrom]: 'Steuer %',
  [filtersFormFields.stornierteBuchungBezeichnung]: 'Stornierte Buchung',
  [filtersFormFields.storno]: 'Stornierte und Storno-Buchungen berücksichtigen',
  [filtersFormFields.text]: 'Buchungstext',
  [filtersFormFields.umbuchung]: 'Umbuchung',
  [filtersFormFields.ustVomAufwandSteuersatzFrom]: 'Ust. vom Aufwand Steuersatz',
  [filtersFormFields.vertragspartnerId]: 'Vertragspartner',
  [filtersFormFields.waehrung]: 'Währung',
};
