import React, { FC } from 'react';
import { Button, Divider, Dropdown, MenuProps, Modal, Typography } from 'antd';
import { AuditOutlined, DeleteOutlined, EllipsisOutlined, FileSearchOutlined } from '@ant-design/icons';
import { showSuccessMsgDelete, showSuccessMsgOther, showWarningMessage } from '../../../components/message';
import { pathsToObjektDetailsPage } from '../../Objekt/objektUriPaths';
import CardWithFooter from '../../../components/Card';
import { useGoBack } from '../../../shared/GoBack/GoBackContext';
import { useDeleteZinslisteMutation, useZinslisteMarkUnderReviewMutation } from '../gql/ZinslisteMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import VerarbeitungInfo from './VerarbeitungInfo/VerarbeitungInfo';
import VPosForVertragLevelListSummary from './VPosForVertragLevelList/VPosForVertragLevelListSummary';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import CardTitle from '../../../components/Card/CardTitle';
import { Zinsliste, ZinslisteVerarbeitungsstand } from '../../../types';
import { useCreateAuftragGenerierlaufMutation } from '../../Verarbeitung/gql/AuftragGenerierlauf/AuftragGenerierlaufMutation.types';
import { showSuccessMsgVerarbeitungStarted } from '../../../components/message/message';
import { mapValuesForZinslisteCard } from './VPosForVertragLevelList/zinslisteCardHelpers';

type Props = {
  zinsliste: Zinsliste;
  isLoading: boolean;
  verarbeitungsstand: ZinslisteVerarbeitungsstand;
};

const ZinslisteCard: FC<Props> = ({ zinsliste, isLoading, verarbeitungsstand }) => {
  const zinslisteId = zinsliste.kopfdaten.zinslisteId;
  const objektId = zinsliste.kopfdaten.objektId;
  const goBack = useGoBack(pathsToObjektDetailsPage(objektId).objektDetails);

  const [runCreateGenerierlauf] = useCreateAuftragGenerierlaufMutation({
    variables: { auftragsGenerierlaufInput: mapValuesForZinslisteCard(objektId, zinsliste.kopfdaten.vorschreibungsperiode) },
    onCompleted: () => {
      showSuccessMsgVerarbeitungStarted(`Vorschreibungerstellung`);
    },
    onError: (error) => showWarningMessage(error, 'Zinsliste wurde nicht verarbeitet'),
  });

  const [runDelete] = useDeleteZinslisteMutation({
    variables: {
      objektId,
      zinslisteId,
    },
    onCompleted: () => {
      showSuccessMsgDelete(`Zinsliste`);
      goBack();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runMarkUnderReview] = useZinslisteMarkUnderReviewMutation({
    variables: {
      objektId,
      zinslisteId,
    },
    onCompleted: () => {
      showSuccessMsgOther('Zinsliste wird überprüft');
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    { key: '1', label: 'Verarbeiten', onClick: () => showConfirmCreate(zinsliste, runCreateGenerierlauf), icon: <AuditOutlined /> },
    { key: '2', label: 'Prüfen', onClick: () => showConfirmMarkUnderReview(zinsliste, runMarkUnderReview), icon: <FileSearchOutlined /> },
    {
      key: '3',
      label: 'Löschen',
      onClick: () => showConfirmDelete(zinsliste, runDelete),
      disabled: !zinsliste.deletable,
      danger: true,
      icon: <DeleteOutlined />,
    },
  ];

  return (
    <>
      <CardWithFooter
        loading={isLoading}
        mitarbeiterId={zinsliste.createdByMitarbeiterId}
        userId={zinsliste.createdBy}
        createTs={zinsliste.createTs}
        size="default"
        bordered={false}
        title={<CardTitle title="Zinsliste" status={zinsliste.status} />}
        extra={
          <Dropdown menu={{ items }}>
            <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
          </Dropdown>
        }
      >
        <Typography.Title level={5}>{zinsliste.kopfdaten.objektKurzBezeichnung}</Typography.Title>
        <CardDetailsInfoRow infoRowTitle="Vorschreibungsperiode">
          <Typography.Text>
            {zinsliste.kopfdaten.vorschreibungsperiode.monat}/{zinsliste.kopfdaten.vorschreibungsperiode.jahr}
          </Typography.Text>
        </CardDetailsInfoRow>
        <Divider dashed />
        <Typography.Title level={5}>Verarbeitungsinformation</Typography.Title>
        <VerarbeitungInfo verarbeitungsstand={verarbeitungsstand} />
        <Divider dashed />
        <VPosForVertragLevelListSummary zinsliste={zinsliste} />
      </CardWithFooter>
    </>
  );
};

const showConfirmCreate = (zinsliste: Zinsliste, runCreateGenerierlauf: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie die Zinsliste verarbeiten?',
    content: `${zinsliste.kopfdaten.objektKurzBezeichnung} - Zinsliste ${zinsliste.kopfdaten.vorschreibungsperiode.monat}/${zinsliste.kopfdaten.vorschreibungsperiode.jahr}`,
    okText: 'Ja',
    cancelText: 'Nein',
    onOk() {
      return runCreateGenerierlauf();
    },
  });
};

const showConfirmDelete = (zinsliste: Zinsliste, handleDelete: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie die Zinsliste löschen?',
    content: `${zinsliste.kopfdaten.objektKurzBezeichnung} - Zinsliste ${zinsliste.kopfdaten.vorschreibungsperiode.monat}/${zinsliste.kopfdaten.vorschreibungsperiode.jahr}`,
    okText: 'Ja',
    okButtonProps: { danger: true },
    cancelText: 'Nein',
    onOk() {
      return handleDelete();
    },
  });
};

const showConfirmMarkUnderReview = (zinsliste: Zinsliste, runMarkUnderReview: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie die Zinsliste prüfen?',
    content: `${zinsliste.kopfdaten.objektKurzBezeichnung} - Zinsliste ${zinsliste.kopfdaten.vorschreibungsperiode.monat}/${zinsliste.kopfdaten.vorschreibungsperiode.jahr}`,
    okText: 'Prüfen',
    cancelText: 'Abbrechen',
    onOk() {
      return runMarkUnderReview();
    },
  });
};

export default ZinslisteCard;
