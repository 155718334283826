import React, { FC } from 'react';
import { Input } from 'formik-antd';
import { validateCurrentPassword } from './passwordValidation';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';

type CurrentPasswordFormPartProps = {
  currentPasswordInputName?: string;
  currentPasswordPlaceholder?: string;
  currentPasswordLabel?: string;
};

const CurrentPasswordFormPart: FC<CurrentPasswordFormPartProps> = ({
  currentPasswordInputName = 'currentPassword',
  currentPasswordLabel = 'Aktuelles Passwort',
  currentPasswordPlaceholder = 'Passwort',
  ...restProps
}) => {
  return (
    <FormItemWithoutColon name={currentPasswordInputName} label={currentPasswordPlaceholder} {...restProps}>
      <Input.Password
        id={currentPasswordInputName}
        name={currentPasswordInputName}
        placeholder={currentPasswordLabel}
        validate={validateCurrentPassword}
      />
    </FormItemWithoutColon>
  );
};

export default CurrentPasswordFormPart;
