import React, { FC } from 'react';
import { Divider, Space, Tooltip, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { DeviationColumnTitleStyledText } from '../../../Styled/Deviation.style';
import DeviationChangeTypeTag from '../../../DeviationChangeTypeTag';
import { EuroAmount } from '../../../../Number';
import RechtstraegerName from '../../../../Rechtstraeger/RechtstraegerName';
import { AuftragDeviationSourceEntity, DeviationChangeTypeTuple } from '../../../../../types';

type VorschreibungSourceEntityHeaderProps = {
  changeType: DeviationChangeTypeTuple;
  sourceEntity: AuftragDeviationSourceEntity;
};

const VorschreibungSourceEntityHeader: FC<VorschreibungSourceEntityHeaderProps> = ({ changeType, sourceEntity }) => (
  <Space align="baseline" style={{ width: '100%', justifyContent: 'space-between' }}>
    <Space size={8} split={<Divider type="vertical" />}>
      <Space size={4} align="baseline">
        <DeviationChangeTypeTag changeType={changeType} />
        <DeviationColumnTitleStyledText type="secondary">{sourceEntity.type.text}</DeviationColumnTitleStyledText>
      </Space>
      <Space size={16}>
        <Space size={4} style={{ width: '300px' }}>
          <RechtstraegerName rechtstraegerId={sourceEntity.rechnungsausstellerId} isLink maxLength={30} />
          <ArrowRightOutlined style={{ fontSize: '12px' }} />
          <RechtstraegerName rechtstraegerId={sourceEntity.rechnungsempfaengerId} isLink maxLength={30} />
        </Space>
        <Tooltip title="Belegnummer">
          <Typography.Text>{sourceEntity.belegnummer}</Typography.Text>
        </Tooltip>
        <EuroAmount value={sourceEntity.sumBrutto} />
      </Space>
    </Space>
  </Space>
);

export default VorschreibungSourceEntityHeader;
