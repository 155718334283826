import React from 'react';
import { Tag } from 'antd';

import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { EuroAmount } from '../../../../../components/Number';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { generatePathToRechtstraegerDetailsPage } from '../../../../Rechtstraeger/rechtstraegerHelper';
import { MitarbeiterTooltip } from '../../../../../components/Card';
import ZahlungsregelActions from './ZahlungsregelActions';
import { compareTwoStringsForSorting } from '../../../../../helpers/stringHelper';
import { compareTwoDatesForSorting, isEntityCurrent } from '../../../../../helpers/dateHelper';
import { compareTwoNumbersForSorting } from '../../../../../helpers/numberHelper';
import CurrentBasedData from '../../../../../components/Helpers/CurrentBasedData';
import { HeVertragZahlungsregel } from '../../../../../types';
import { HeAbrechnungsdefinitionFieldsFragment } from '../../../../Abrechnungsdefinition/gql/HeAbrDef/HeAbrDefFragments.types';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';

const zahlungsregelTableColumns = (
  objektId: string,
  heVertragId: string,
  onSuccess: () => void,
  heAbrechnungsdefinition?: HeAbrechnungsdefinitionFieldsFragment | null
): TableWithColSelectorColumnProps<HeVertragZahlungsregel>[] => {
  return [
    {
      title: 'Bezeichnung',
      defaultSelected: true,
      sorter: (a, b) => compareTwoStringsForSorting(a.bezeichnung, b.bezeichnung),
      render: (text, record) => (
        <CurrentBasedData
          text={record.bezeichnung}
          isCurrent={isEntityCurrent(record.beendetAm)}
          warningList={record.warningList}
          maxTextLength={20}
        />
      ),
    },
    {
      title: 'Regeltyp',
      defaultSelected: true,
      sorter: (a, b) => compareTwoStringsForSorting(a.type.value, b.type.value),
      render: (text, record) => <Tag>{record.type.value}</Tag>,
    },
    {
      title: 'Durchführen am',
      defaultSelected: true,
      sorter: (a, b) => compareTwoNumbersForSorting(a.durchfuehrenAm, b.durchfuehrenAm),
      render: (text, record) => {
        return <CurrentBasedData text={`${record.periode.text} am ${record.durchfuehrenAm}ten`} isCurrent={isEntityCurrent(record.beendetAm)} />;
      },
    },
    {
      title: 'Betrag',
      defaultSelected: true,
      align: 'right',
      sorter: (a, b) => compareTwoNumbersForSorting(a.fixbetrag, b.fixbetrag),
      render: (text, record) => {
        return (
          <CurrentBasedData text="" isCurrent={isEntityCurrent(record.beendetAm)}>
            {record.fixbetrag ? <EuroAmount value={record.fixbetrag} /> : <TextForEmptyValue textToShow="minus" />}
          </CurrentBasedData>
        );
      },
    },
    {
      title: 'Empfänger',
      defaultSelected: true,
      sorter: (a, b) => compareTwoStringsForSorting(a.zahlungsempfaenger.kurzBezeichnung, b.zahlungsempfaenger.kurzBezeichnung),
      render: (text, record) => {
        return (
          <CurrentBasedData
            text={record.zahlungsempfaenger.kurzBezeichnung}
            isCurrent={isEntityCurrent(record.beendetAm)}
            linkTo={generatePathToRechtstraegerDetailsPage(record.zahlungsempfaenger.rechtstraegerId)}
            openLinkInNewTab
          />
        );
      },
    },
    {
      title: 'Nächste Zahlung am',
      defaultSelected: true,
      sorter: (a, b) => compareTwoDatesForSorting(a.naechsteZahlung, b.naechsteZahlung),
      render: (text, record) => {
        return (
          <CurrentBasedData text="" isCurrent={isEntityCurrent(record.beendetAm)}>
            {record.naechsteZahlung ? <CustomFormattedDate value={record.naechsteZahlung} /> : <TextForEmptyValue textToShow="minus" />}
          </CurrentBasedData>
        );
      },
    },
    {
      title: 'beendet am',
      defaultSelected: true,
      sorter: (a, b) => compareTwoDatesForSorting(a.beendetAm, b.beendetAm),
      render: (text, record) => {
        return (
          <CurrentBasedData text="" isCurrent={isEntityCurrent(record.beendetAm)}>
            {record.beendetAm ? <CustomFormattedDate value={record.beendetAm} /> : <TextForEmptyValue textToShow="minus" />}
          </CurrentBasedData>
        );
      },
    },
    {
      title: 'Erstellt am',
      render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
    },
    {
      title: 'Ersteller',
      render: (text, record) => (
        <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
      ),
    },
    {
      title: 'Aktion',
      defaultSelected: true,
      render: (text, record) => (
        <ZahlungsregelActions
          objektId={objektId}
          heVertragId={heVertragId}
          zahlungsregel={record}
          onSuccess={onSuccess}
          heAbrechnungsdefinition={heAbrechnungsdefinition}
        />
      ),
    },
  ];
};

export default zahlungsregelTableColumns;
