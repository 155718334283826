import React, { FC } from 'react';
import { Button, Col, Modal, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import firmendatenListTableColumns from '../KundenSystem/firmendatenListTableColumns';
import { isStatusDeleted } from '../../../helpers/statusHelper';
import FirmendatenCreateWizard from './wizard/FirmendatenCreateWizard';
import { useToggle } from '../../../hooks/useToggle';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { useCreateKsFirmendatenMutation } from './gql/KundenSystemMutations.types';
import { showSuccessMsgCreate } from '../../../components/message';
import { Spacer } from '../../../components/Grid';
import TableWithColSelector from '../../../components/Table/TableWithColSelector/TableWithColSelector';
import { FirmaForKs, KundenSystem } from '../../../types';

type FirmendatenListVerwaltungProps = {
  kundenSystem: KundenSystem;
  loading: boolean;
  onSuccess: () => void;
};

const FirmendatenListVerwaltung: FC<FirmendatenListVerwaltungProps> = ({ kundenSystem, loading, onSuccess }) => {
  const [isCollapsed, onCollapse] = useToggle();

  const [createKSFirmendaten, { error, loading: createKSFirmendatenLoading }] = useCreateKsFirmendatenMutation({
    onCompleted: () => {
      showSuccessMsgCreate(`Firmendaten`);
      onSuccess();
      onCollapse();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <>
      <Row>
        <Col flex="auto" />
        <Col>
          <Button size="small" type="primary" disabled={isStatusDeleted(kundenSystem.status)} onClick={onCollapse}>
            <PlusOutlined />
            Neue Firma anlegen
          </Button>
        </Col>
      </Row>
      <Spacer />

      <TableWithColSelector<FirmaForKs>
        tableLayout="auto"
        size="small"
        loading={loading}
        dataSource={kundenSystem.firmendatenList}
        rowKey={(record) => record.firmendatenId}
        columns={firmendatenListTableColumns}
        pagination={false}
        filterIdentifier="verwaltung-kundensystem-firmendaten"
      />

      <Modal
        title="Neue Firma anlegen"
        open={isCollapsed}
        onCancel={onCollapse}
        footer={null}
        destroyOnClose
        maskClosable={false}
        keyboard={!createKSFirmendatenLoading}
        closable={!createKSFirmendatenLoading}
      >
        <FirmendatenCreateWizard
          kundenSystemId={kundenSystem.kundenSystemId}
          onCreate={(firmendaten) =>
            createKSFirmendaten({
              variables: {
                kundenSystemId: kundenSystem.kundenSystemId,
                request: firmendaten,
              },
            })
          }
          error={error}
          onCancel={onCollapse}
        />
      </Modal>
    </>
  );
};

export default FirmendatenListVerwaltung;
