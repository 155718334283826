import { FC } from 'react';
import { useTextbausteinTemplateListQuery } from './gql/TextbausteinTemplateQueries.types';
import TextbausteinTemplateTable from './Table/TextbausteinTemplateTable';
import TextbausteinTemplateActionButtonAndDrawer from './TextbausteinTemplateActionButtonAndDrawer';

const TextbausteinTemplateListing: FC = () => {
  const { data, loading, refetch } = useTextbausteinTemplateListQuery();
  const textbausteinList = data?.getTextbausteinTemplateList.data ?? [];

  return (
    <>
      <TextbausteinTemplateActionButtonAndDrawer refetch={refetch} />
      <TextbausteinTemplateTable textbausteinList={textbausteinList} loading={loading} refetch={refetch} />
    </>
  );
};

export default TextbausteinTemplateListing;
