import { FC, PropsWithChildren } from 'react';
import { Space } from 'antd';

const SpaceForBookingBlockAndForm: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Space direction="vertical" size={0} style={{ width: '100%' }}>
      {children}
    </Space>
  );
};

export default SpaceForBookingBlockAndForm;
