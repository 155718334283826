import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { DatePicker, Form } from 'formik-antd';
import { Col, Row, Space, Tooltip } from 'antd';
import { Dayjs } from 'dayjs';
import TicketCalendar, { TicketListGroupedByDueDate } from '../../../TicketCalender/TicketCalendar';
import { TicketListQueryVariables } from '../../gql/TicketQueries.types';
import { Spacer } from '../../../../components/Grid';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import TicketPrioritySelect from '../../shared/TicketPrioritySelect';
import TicketStatusSelect from '../../shared/TicketStatusSelect';
import { TicketBase, TicketStatus } from '../../../../types';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import MitarbeiterSelect from '../../../../shared/components/MitarbeiterSelect/MitarbeiterSelect';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../components/DatePicker/DatePicker_formikAntD';
import { mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import TicketCalendarDateCell from '../../../TicketCalender/TicketCalendarDateCell';
import { StyledSpace } from '../../../TicketCalender/syled/Space.style';
import { MitarbeiterTooltip } from '../../../../components/Card';
import MitarbeiterOrUserName from '../../../../components/Card/MitarbeiterOrUserName';
import TruncatedText from '../../../../components/Helpers/TruncatedText';
import { generatePathToTicketPage } from '../../ticketUriPaths';
import { displayTicketCount } from '../../MitarbeiterSelectWithTicketCount/chooseAssigneeHelper';
import { isNotNil } from '../../../../helpers/assertionHelper';

type Props = {
  status: TicketListQueryVariables['status'];
  setStatus: Dispatch<SetStateAction<TicketListQueryVariables['status']>>;
  priority: TicketListQueryVariables['priority'];
  setPriority: Dispatch<SetStateAction<TicketListQueryVariables['priority']>>;
  assigneeMitarbeiterIds: string[];
  setAssignees: Dispatch<SetStateAction<string[]>>;
  dueDate: string | undefined | null;
  setDueDate: Dispatch<SetStateAction<string | undefined | null>>;
};

const TicketFormCalendar: FC<Props> = ({ status, setStatus, priority, setPriority, assigneeMitarbeiterIds, setAssignees, dueDate, setDueDate }) => {
  const [dueDateFrom, setDueDateFrom] = useState<TicketListQueryVariables['dueDateFrom']>();
  const [dueDateTo, setDueDateTo] = useState<TicketListQueryVariables['dueDateTo']>();

  useEffect(() => {
    setDueDateFrom(dayjsCustom(dueDate).startOf('month').format('YYYY-MM-DD'));
    setDueDateTo(dayjsCustom(dueDate).endOf('month').format('YYYY-MM-DD'));
  }, [dueDate]);

  const queryProps: TicketListQueryVariables = {
    assigneeMitarbeiterId: assigneeMitarbeiterIds,
    searchInSubTickets: true,
    priority,
    dueDateFrom,
    dueDateTo,
    status: status?.length ? status : [TicketStatus.InProgress, TicketStatus.ToDo], // set default status if none selected
  };

  return (
    <TicketCalendar
      header={{
        bottom: () => headerBottom(status, setStatus, priority, setPriority, assigneeMitarbeiterIds, setAssignees, dueDate, setDueDate),
      }}
      dueDate={dueDate}
      dateCellRender={(date, ticketList) => cellRender(date, ticketList, assigneeMitarbeiterIds)}
      onDateCellClick={(date) => setDueDate(mapFormDateValueToDateString(date))}
      {...queryProps}
    />
  );
};

const headerBottom = (
  status: TicketListQueryVariables['status'],
  setStatus: Dispatch<SetStateAction<TicketListQueryVariables['status']>>,
  priority: TicketListQueryVariables['priority'],
  setPriority: Dispatch<SetStateAction<TicketListQueryVariables['priority']>>,
  assigneeMitarbeiterIds: string[],
  setAssignees: Dispatch<SetStateAction<string[]>>,
  dueDate: string | undefined | null,
  setDueDate: Dispatch<SetStateAction<string | undefined | null>>
) => {
  return (
    <>
      <Spacer height={12} />
      <Formik initialValues={{}} onSubmit={() => {}}>
        <Form>
          <Row justify="end" gutter={16}>
            <Col>
              <FormItemWithoutColon name="dueDate" label="Fälligkeit">
                <DatePicker
                  id="dueDate"
                  name="dueDate"
                  placeholder="Beteiligte auswählen"
                  size="small"
                  format={DATE_DISPLAY_FORMAT_DEFAULT}
                  allowClear={false}
                  style={{ width: 150 }}
                  value={dueDate ? dayjsCustom(dueDate) : undefined}
                  onChange={(value) => {
                    value && setDueDate(dayjsCustom(value).format('YYYY-MM-DD'));
                  }}
                />
              </FormItemWithoutColon>
            </Col>
            <Col>
              <FormItemWithoutColon name="priority" label="Priorität">
                <TicketPrioritySelect
                  allowClear
                  mode="multiple"
                  name="priority"
                  style={{ width: 200 }}
                  value={priority}
                  onChange={(value) => setPriority(value)}
                />
              </FormItemWithoutColon>
            </Col>
            <Col>
              <FormItemWithoutColon name="status" label="Status">
                <TicketStatusSelect mode="multiple" name="status" style={{ width: 250 }} value={status} onChange={(value) => setStatus(value)} />
              </FormItemWithoutColon>
            </Col>
          </Row>
          <Row justify="end" gutter={16}>
            <Col>
              <FormItemWithoutColon name="bearbeiter" label="Bearbeiter">
                <MitarbeiterSelect
                  name="bearbeiter"
                  placeholder="Bearbeiter auswählen"
                  mode="multiple"
                  value={assigneeMitarbeiterIds || undefined}
                  onMitarbeiterChange={(value) => {
                    if (!value) {
                      setAssignees([]);
                    } else {
                      const assigneeList = (Array.isArray(value) ? value : [value]).filter(isNotNil).map((mitabeiter) => mitabeiter.mitarbeiterId);
                      setAssignees(assigneeList);
                    }
                  }}
                  style={{ minWidth: 250 }}
                />
              </FormItemWithoutColon>
            </Col>
          </Row>
        </Form>
      </Formik>
      <div style={{ marginBottom: -16 }} />
    </>
  );
};

const cellRender = (date: Dayjs, ticketList: TicketListGroupedByDueDate, assigneeMitarbeiterIds: string[]) => {
  const ticketListGroupedByAssignee: Partial<Record<string, Partial<Record<string, TicketBase[]>>>> = {};
  for (const [key, value] of Object.entries(ticketList)) {
    ticketListGroupedByAssignee[key] = Object.groupBy(value ?? [], ({ assignee }) => assignee.mitarbeiterId);
  }

  if (assigneeMitarbeiterIds.length === 1) {
    const current = ticketList[date.format('YYYY-MM-DD')];
    return current && <TicketCalendarDateCell date={date} ticketListByDueDate={ticketList} openLinkInNewTab />;
  } else {
    const current = ticketListGroupedByAssignee[date.format('YYYY-MM-DD')];
    return (
      <Space direction="vertical" size={4} style={{ display: 'flex', maxWidth: 'fit-content' }}>
        {Object.entries(current ?? {}).map(([assigneeId, tickets]) => (
          <StyledSpace direction="horizontal" size={4} style={{ display: 'flex', maxWidth: 'fit-content' }}>
            <MitarbeiterTooltip mitarbeiterId={assigneeId} showsInitials alignment="left" />
            <Link
              to={generatePathToTicketPage({
                dueDateTo: mapFormDateValueToDateString(date),
                dueDateFrom: mapFormDateValueToDateString(date),
                assigneeMitarbeiterId: [assigneeId],
                searchInSubTickets: true,
              })}
              target="_blank"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <TruncatedText
                text={
                  <Tooltip
                    title={
                      <>
                        <MitarbeiterOrUserName mitarbeiterId={assigneeId} /> - {displayTicketCount(tickets?.length ?? 0)}
                      </>
                    }
                  >
                    <MitarbeiterOrUserName mitarbeiterId={assigneeId} /> ({tickets?.length})
                  </Tooltip>
                }
              />
            </Link>
          </StyledSpace>
        ))}
      </Space>
    );
  }
};

export default TicketFormCalendar;
