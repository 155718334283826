import React, { FC } from 'react';
import { View } from '@react-pdf/renderer';
import pdfStyles from '../../styles/pdfStyles';
import { LabeledValue } from '../types';
import LabelValue from '../LabelValue';

const ZrSummary: FC<{ data: LabeledValue }> = ({ data }) => (
  <View style={[pdfStyles.column, pdfStyles.borderBottomSmall, { marginLeft: '40%', marginTop: '2mm', borderBottomWidth: 2 }]}>
    <LabelValue valueTuple={data} />
  </View>
);

export default ZrSummary;
