import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { indexedValueListingFiltersFormFields, IndexedValueListingFiltersFormValues } from './indexedValueListingFiltersFormMapper';
import VertragSelect from '../../../Vertrag/shared/VertragSelect';
import FormItemForFilter from '../../../../components/Form/FormItemForFilter';

type Props = {
  formikProps: FormikProps<IndexedValueListingFiltersFormValues>;
};

const VertragSelectFormItem: FC<Props> = ({ formikProps }) => (
  <FormItemForFilter
    name={indexedValueListingFiltersFormFields.vertragId}
    extraInfo={
      !formikProps.values.objektId || !formikProps.values.bestandseinheitId ? (
        <Tooltip title="Bitte wählen Sie zuerst ein Objekt und eine Bestandseinheit aus">
          <InfoCircleOutlined />
        </Tooltip>
      ) : null
    }
  >
    <VertragSelect
      name={indexedValueListingFiltersFormFields.vertragId}
      objektId={formikProps.values.objektId}
      bestandseinheitId={formikProps.values.bestandseinheitId}
      onChange={formikProps.submitForm}
      maxTextLength={35}
      prefix="Vertrag"
      placeholder=""
      size="middle"
    />
  </FormItemForFilter>
);

export default VertragSelectFormItem;
