import { FC } from 'react';
import { useFirmendatenBankDetailsChangeHistoryListQuery } from '../gql/BankDetailsQueries.types';
import { HistoryType } from '../../History/shared/HistoryModal';
import HistoryListingTable from '../../History/HistoryListingTable';

type Props = {
  firmendatenId: string;
  bankDetailsId: string;
};

const FirmendatenBankDetailsChangeHistoryListingTable: FC<Props> = ({ firmendatenId, bankDetailsId }) => {
  const { data, loading } = useFirmendatenBankDetailsChangeHistoryListQuery({ variables: { firmendatenId, bankDetailsId } });
  const historyList = data?.getFirmendatenBankDetailsChangeHistoryList.data;

  return (
    <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="firmendaten-bank-details" />
  );
};

export default FirmendatenBankDetailsChangeHistoryListingTable;
