import styled from 'styled-components';
import TableWithColSelector from '../../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { TableWithColSelectorProps } from '../../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';

interface Props extends TableWithColSelectorProps<any> {
  isCardCollapsed?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledTableVorschreibungVerbuchen = (props: Props) => {
  return <StyledTableVorschreibungVerbuchenTableWithColSelector {...props} />;
};

const StyledTableVorschreibungVerbuchenTableWithColSelector = styled(TableWithColSelector)<Props>`
  & .ant-table-tbody .ant-table-row .ant-table-cell {
    height: 0;
    & > div:not(.ant-table-wrapper) {
      display: flex;
      width: 100%;
      height: 100%;
      padding-top: ${(props) => (props.isCardCollapsed ? '4px' : '15px')};
    }
  }
`;
