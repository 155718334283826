import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { ObjektFragmentDoc } from './ObjektFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateObjektMutationVariables = Types.Exact<{
  input: Types.ObjektCreateInput;
}>;

export type CreateObjektMutation = {
  createObjekt: {
    data: {
      countryCodeIso2: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      fotoFileId?: string | null;
      fotoType?: Types.FotoType | null;
      kurzBezeichnung: string;
      objektId: string;
      rechnungsAusstellerBezeichnung?: string | null;
      rechnungsAusstellerChangeable?: boolean | null;
      subAdministration: boolean;
      sumNutzflaeche: number;
      sumNutzwert: number;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      verwaltungBis?: string | null;
      verwaltungSeit: string;
      verwaltungsartChangeable?: boolean | null;
      addressList: Array<{
        addressId: string;
        city: string;
        cityAdditionalInformation?: string | null;
        countryCodeIso2: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        houseEntranceApartmentNumber?: string | null;
        postofficeBoxNumber?: string | null;
        postofficeBoxZipCode?: string | null;
        street?: string | null;
        tagList: Array<string>;
        type: Types.AddressType;
        zipCode?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      objektSummeAnteile?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        kommentar?: string | null;
        lastUpdateTs: string;
        objektSummeAnteileId: string;
        summeAnteile: number;
        validFrom: string;
        historicizedList?: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          kommentar?: string | null;
          lastUpdateTs: string;
          objektSummeAnteileId: string;
          summeAnteile: number;
          validFrom: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }> | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechnungsAussteller: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fibuKontoCreatable: boolean;
        kundennummer: string;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        bankDetailsList: Array<{
          accountHolder: string;
          accountLimit?: number | null;
          assignedHeVertragZahlungsregelMap: any;
          assignedObjektMap: any;
          bankDetailsId: string;
          bankname: string;
          bic: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          ebicsCreditTransferUserList: Array<string>;
          ebicsDirectDebitUserList: Array<string>;
          fibuKontoId?: string | null;
          fibuKontonummer?: string | null;
          iban: string;
          updatedByMitarbeiterId?: string | null;
          fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
          fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
          sepaMandat?: {
            fileId?: string | null;
            fileName?: string | null;
            sepaMandatId: string;
            sepaMandatReference: string;
            signatureDate?: string | null;
            firmendaten?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firmendatenId: string;
              kundenSystemId: string;
              kurzBezeichnung: string;
              name1: string;
              name2?: string | null;
              name3?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechnungsAussteller?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
            warningList: Array<{ message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        personenbezugList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          personenbezugId: string;
          tagList: Array<string>;
          person: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechtstraegerSteuerpflicht?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          historicizedList?: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ message: string; type: string }>;
          }> | null;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
        sprache: { text: string; value: Types.Sprache };
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { value: Types.RechtstraegerType; text: string };
        vertragSummary?: {
          heVertragCount: number;
          heVertragVerwaltungCount: number;
          mietVertragCount: number;
          mietVertragVerwaltungCount: number;
          weSubverwaltungCount: number;
          weVertragCount: number;
          weVertragVerwaltungCount: number;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      sachbearbeiter?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        fileStorageInitialized: boolean;
        firmendatenId: string;
        firstname: string;
        funktionsModulList: Array<string>;
        lastname: string;
        mitarbeiterId: string;
        title?: string | null;
        titleTrailing?: string | null;
        username: string;
        contactPerson?: {
          contactPersonId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firstname: string;
          lastname: string;
          tagList: Array<string>;
          title?: string | null;
          titleTrailing?: string | null;
          contactList: Array<{
            contactId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            haupt?: boolean | null;
            tagList: Array<string>;
            type: string;
            wert: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          rechtstraeger?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rolleList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          name: string;
          rolleId: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
        status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
        user?: {
          email?: string | null;
          emailUnverified?: string | null;
          firstname?: string | null;
          lastname?: string | null;
          role: string;
          username: string;
          status: { text: string; value: string };
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { text: string; value: Types.ObjektStatus; description?: string | null };
      verwaltungsart: { text: string; value: Types.Verwaltungsart };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ArchiveObjektMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
}>;

export type ArchiveObjektMutation = {
  actionArchiveObjekt: {
    data: { objektId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveObjektMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveObjektMutation = {
  actionUnarchiveObjekt: {
    data: { objektId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateObjektMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  input: Types.ObjektUpdateInput;
}>;

export type UpdateObjektMutation = {
  updateObjekt: {
    data: {
      countryCodeIso2: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      fotoFileId?: string | null;
      fotoType?: Types.FotoType | null;
      kurzBezeichnung: string;
      objektId: string;
      rechnungsAusstellerBezeichnung?: string | null;
      rechnungsAusstellerChangeable?: boolean | null;
      subAdministration: boolean;
      sumNutzflaeche: number;
      sumNutzwert: number;
      tagList: Array<string>;
      updatedByMitarbeiterId?: string | null;
      verwaltungBis?: string | null;
      verwaltungSeit: string;
      verwaltungsartChangeable?: boolean | null;
      addressList: Array<{
        addressId: string;
        city: string;
        cityAdditionalInformation?: string | null;
        countryCodeIso2: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        houseEntranceApartmentNumber?: string | null;
        postofficeBoxNumber?: string | null;
        postofficeBoxZipCode?: string | null;
        street?: string | null;
        tagList: Array<string>;
        type: Types.AddressType;
        zipCode?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      objektSummeAnteile?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        kommentar?: string | null;
        lastUpdateTs: string;
        objektSummeAnteileId: string;
        summeAnteile: number;
        validFrom: string;
        historicizedList?: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          kommentar?: string | null;
          lastUpdateTs: string;
          objektSummeAnteileId: string;
          summeAnteile: number;
          validFrom: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }> | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      postIt?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        immerAnzeigen: boolean;
        postItId: string;
        text: string;
        titel: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechnungsAussteller: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fibuKontoCreatable: boolean;
        kundennummer: string;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        bankDetailsList: Array<{
          accountHolder: string;
          accountLimit?: number | null;
          assignedHeVertragZahlungsregelMap: any;
          assignedObjektMap: any;
          bankDetailsId: string;
          bankname: string;
          bic: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          ebicsCreditTransferUserList: Array<string>;
          ebicsDirectDebitUserList: Array<string>;
          fibuKontoId?: string | null;
          fibuKontonummer?: string | null;
          iban: string;
          updatedByMitarbeiterId?: string | null;
          fibuStatus: { text: string; value: Types.BankDetailsFibuStatus };
          fibuVerwendung: { text: string; value: Types.BankDetailsFibuVerwendung };
          sepaMandat?: {
            fileId?: string | null;
            fileName?: string | null;
            sepaMandatId: string;
            sepaMandatReference: string;
            signatureDate?: string | null;
            firmendaten?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              firmendatenId: string;
              kundenSystemId: string;
              kurzBezeichnung: string;
              name1: string;
              name2?: string | null;
              name3?: string | null;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            rechnungsAussteller?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            status: { description?: string | null; text: string; value: Types.SepaMandatStatus };
            warningList: Array<{ message: string; type: string }>;
          } | null;
          status: { description?: string | null; text: string; value: Types.BankDetailsStatus };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        personenbezugList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          personenbezugId: string;
          tagList: Array<string>;
          person: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechtstraegerSteuerpflicht?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          current: boolean;
          deletable?: boolean | null;
          kommentar?: string | null;
          lastUpdateTs: string;
          rechtstraegerSteuerpflichtId: string;
          validFrom: string;
          historicizedList?: Array<{
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            current: boolean;
            deletable?: boolean | null;
            kommentar?: string | null;
            lastUpdateTs: string;
            rechtstraegerSteuerpflichtId: string;
            validFrom: string;
            steuerpflicht: { text: string; value: Types.Steuerpflicht };
            warningList: Array<{ message: string; type: string }>;
          }> | null;
          steuerpflicht: { text: string; value: Types.Steuerpflicht };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; text: string; value: Types.Salutation } | null;
        sprache: { text: string; value: Types.Sprache };
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        type: { value: Types.RechtstraegerType; text: string };
        vertragSummary?: {
          heVertragCount: number;
          heVertragVerwaltungCount: number;
          mietVertragCount: number;
          mietVertragVerwaltungCount: number;
          weSubverwaltungCount: number;
          weVertragCount: number;
          weVertragVerwaltungCount: number;
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      sachbearbeiter?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        fileStorageInitialized: boolean;
        firmendatenId: string;
        firstname: string;
        funktionsModulList: Array<string>;
        lastname: string;
        mitarbeiterId: string;
        title?: string | null;
        titleTrailing?: string | null;
        username: string;
        contactPerson?: {
          contactPersonId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firstname: string;
          lastname: string;
          tagList: Array<string>;
          title?: string | null;
          titleTrailing?: string | null;
          contactList: Array<{
            contactId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            haupt?: boolean | null;
            tagList: Array<string>;
            type: string;
            wert: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          rechtstraeger?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rolleList: Array<{
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          name: string;
          rolleId: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
        status: { description?: string | null; text: string; value: Types.MitarbeiterStatus };
        user?: {
          email?: string | null;
          emailUnverified?: string | null;
          firstname?: string | null;
          lastname?: string | null;
          role: string;
          username: string;
          status: { text: string; value: string };
        } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      status: { text: string; value: Types.ObjektStatus; description?: string | null };
      verwaltungsart: { text: string; value: Types.Verwaltungsart };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteObjektMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
}>;

export type DeleteObjektMutation = {
  deleteObjekt: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type DeleteObjektFotoMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
}>;

export type DeleteObjektFotoMutation = {
  deleteObjektFoto: {
    data: { fotoFileId?: string | null };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type CreateObjektAddressMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  input: Types.AddressStreetCreateInput;
}>;

export type CreateObjektAddressMutation = {
  createObjektAddress: {
    data: { addressId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type CreateObjektPostItMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  input: Types.PostItInput;
}>;

export type CreateObjektPostItMutation = {
  createObjektPostIt: {
    data: { postItId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateObjektDocument = gql`
  mutation CreateObjekt($input: ObjektCreateInput!) {
    createObjekt(input: $input) {
      data {
        ...Objekt
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${ObjektFragmentDoc}
`;
export function useCreateObjektMutation(baseOptions?: Apollo.MutationHookOptions<CreateObjektMutation, CreateObjektMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateObjektMutation, CreateObjektMutationVariables>(CreateObjektDocument, options);
}
export type CreateObjektMutationHookResult = ReturnType<typeof useCreateObjektMutation>;
export type CreateObjektMutationResult = Apollo.MutationResult<CreateObjektMutation>;
export type CreateObjektMutationOptions = Apollo.BaseMutationOptions<CreateObjektMutation, CreateObjektMutationVariables>;
export const ArchiveObjektDocument = gql`
  mutation ArchiveObjekt($objektId: ID!) {
    actionArchiveObjekt(objektId: $objektId) {
      data {
        objektId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveObjektMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveObjektMutation, ArchiveObjektMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveObjektMutation, ArchiveObjektMutationVariables>(ArchiveObjektDocument, options);
}
export type ArchiveObjektMutationHookResult = ReturnType<typeof useArchiveObjektMutation>;
export type ArchiveObjektMutationResult = Apollo.MutationResult<ArchiveObjektMutation>;
export type ArchiveObjektMutationOptions = Apollo.BaseMutationOptions<ArchiveObjektMutation, ArchiveObjektMutationVariables>;
export const UnarchiveObjektDocument = gql`
  mutation UnarchiveObjekt($objektId: ID!) {
    actionUnarchiveObjekt(objektId: $objektId) {
      data {
        objektId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveObjektMutation(baseOptions?: Apollo.MutationHookOptions<UnarchiveObjektMutation, UnarchiveObjektMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveObjektMutation, UnarchiveObjektMutationVariables>(UnarchiveObjektDocument, options);
}
export type UnarchiveObjektMutationHookResult = ReturnType<typeof useUnarchiveObjektMutation>;
export type UnarchiveObjektMutationResult = Apollo.MutationResult<UnarchiveObjektMutation>;
export type UnarchiveObjektMutationOptions = Apollo.BaseMutationOptions<UnarchiveObjektMutation, UnarchiveObjektMutationVariables>;
export const UpdateObjektDocument = gql`
  mutation UpdateObjekt($objektId: ID!, $input: ObjektUpdateInput!) {
    updateObjekt(objektId: $objektId, input: $input) {
      data {
        ...Objekt
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${ObjektFragmentDoc}
`;
export function useUpdateObjektMutation(baseOptions?: Apollo.MutationHookOptions<UpdateObjektMutation, UpdateObjektMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateObjektMutation, UpdateObjektMutationVariables>(UpdateObjektDocument, options);
}
export type UpdateObjektMutationHookResult = ReturnType<typeof useUpdateObjektMutation>;
export type UpdateObjektMutationResult = Apollo.MutationResult<UpdateObjektMutation>;
export type UpdateObjektMutationOptions = Apollo.BaseMutationOptions<UpdateObjektMutation, UpdateObjektMutationVariables>;
export const DeleteObjektDocument = gql`
  mutation DeleteObjekt($objektId: ID!) {
    deleteObjekt(objektId: $objektId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteObjektMutation(baseOptions?: Apollo.MutationHookOptions<DeleteObjektMutation, DeleteObjektMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteObjektMutation, DeleteObjektMutationVariables>(DeleteObjektDocument, options);
}
export type DeleteObjektMutationHookResult = ReturnType<typeof useDeleteObjektMutation>;
export type DeleteObjektMutationResult = Apollo.MutationResult<DeleteObjektMutation>;
export type DeleteObjektMutationOptions = Apollo.BaseMutationOptions<DeleteObjektMutation, DeleteObjektMutationVariables>;
export const DeleteObjektFotoDocument = gql`
  mutation DeleteObjektFoto($objektId: ID!) {
    deleteObjektFoto(objektId: $objektId) {
      data {
        fotoFileId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeleteObjektFotoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteObjektFotoMutation, DeleteObjektFotoMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteObjektFotoMutation, DeleteObjektFotoMutationVariables>(DeleteObjektFotoDocument, options);
}
export type DeleteObjektFotoMutationHookResult = ReturnType<typeof useDeleteObjektFotoMutation>;
export type DeleteObjektFotoMutationResult = Apollo.MutationResult<DeleteObjektFotoMutation>;
export type DeleteObjektFotoMutationOptions = Apollo.BaseMutationOptions<DeleteObjektFotoMutation, DeleteObjektFotoMutationVariables>;
export const CreateObjektAddressDocument = gql`
  mutation CreateObjektAddress($objektId: ID!, $input: AddressStreetCreateInput!) {
    createObjektAddress(objektId: $objektId, input: $input) {
      data {
        addressId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreateObjektAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateObjektAddressMutation, CreateObjektAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateObjektAddressMutation, CreateObjektAddressMutationVariables>(CreateObjektAddressDocument, options);
}
export type CreateObjektAddressMutationHookResult = ReturnType<typeof useCreateObjektAddressMutation>;
export type CreateObjektAddressMutationResult = Apollo.MutationResult<CreateObjektAddressMutation>;
export type CreateObjektAddressMutationOptions = Apollo.BaseMutationOptions<CreateObjektAddressMutation, CreateObjektAddressMutationVariables>;
export const CreateObjektPostItDocument = gql`
  mutation CreateObjektPostIt($objektId: ID!, $input: PostItInput!) {
    createObjektPostIt(objektId: $objektId, input: $input) {
      data {
        postItId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateObjektPostItMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateObjektPostItMutation, CreateObjektPostItMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateObjektPostItMutation, CreateObjektPostItMutationVariables>(CreateObjektPostItDocument, options);
}
export type CreateObjektPostItMutationHookResult = ReturnType<typeof useCreateObjektPostItMutation>;
export type CreateObjektPostItMutationResult = Apollo.MutationResult<CreateObjektPostItMutation>;
export type CreateObjektPostItMutationOptions = Apollo.BaseMutationOptions<CreateObjektPostItMutation, CreateObjektPostItMutationVariables>;
