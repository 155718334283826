import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FilterGenLaufPaymentBuchungskreisRechtstraegerListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.PaymentGenerierlaufType;
}>;

export type FilterGenLaufPaymentBuchungskreisRechtstraegerListQuery = {
  getFilterGenLaufPaymentBuchungskreisRechtstraegerList: {
    data: Array<{ kurzBezeichnung: string; rechtstraegerId: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufPaymentCreditorListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.PaymentGenerierlaufType;
}>;

export type FilterGenLaufPaymentCreditorListQuery = {
  getFilterGenLaufPaymentCreditorList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufPaymentDataCarrierNameListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.PaymentGenerierlaufType;
}>;

export type FilterGenLaufPaymentDataCarrierNameListQuery = {
  getFilterGenLaufPaymentDataCarrierNameList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufPaymentDataCarrierTypeListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.PaymentGenerierlaufType;
}>;

export type FilterGenLaufPaymentDataCarrierTypeListQuery = {
  getFilterGenLaufPaymentDataCarrierTypeList: {
    data: Array<{ text: string; value: Types.DataCarrierType }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufPaymentDebtorListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.PaymentGenerierlaufType;
}>;

export type FilterGenLaufPaymentDebtorListQuery = {
  getFilterGenLaufPaymentDebtorList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufPaymentEbicsUserNameListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.PaymentGenerierlaufType;
}>;

export type FilterGenLaufPaymentEbicsUserNameListQuery = {
  getFilterGenLaufPaymentEbicsUserNameList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufPaymentEbicsUserUserIdListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.PaymentGenerierlaufType;
}>;

export type FilterGenLaufPaymentEbicsUserUserIdListQuery = {
  getFilterGenLaufPaymentEbicsUserUserIdList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufPaymentGenerierlaufEntryStatusListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.PaymentGenerierlaufType;
}>;

export type FilterGenLaufPaymentGenerierlaufEntryStatusListQuery = {
  getFilterGenLaufPaymentGenerierlaufEntryStatusList: {
    data: Array<{ text: string; value: Types.GenerierlaufEntryExitCode }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufPaymentIbanListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.PaymentGenerierlaufType;
}>;

export type FilterGenLaufPaymentIbanListQuery = {
  getFilterGenLaufPaymentIbanList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufPaymentMitarbeiterListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.PaymentGenerierlaufType;
}>;

export type FilterGenLaufPaymentMitarbeiterListQuery = {
  getFilterGenLaufPaymentMitarbeiterList: {
    data: Array<{ firmendatenId: string; firstname: string; lastname: string; mitarbeiterId: string; username: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufPaymentObjektListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.PaymentGenerierlaufType;
}>;

export type FilterGenLaufPaymentObjektListQuery = {
  getFilterGenLaufPaymentObjektList: {
    data: Array<{ kurzBezeichnung: string; objektId: string }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufPaymentTransactionTypeListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.PaymentGenerierlaufType;
}>;

export type FilterGenLaufPaymentTransactionTypeListQuery = {
  getFilterGenLaufPaymentTransactionTypeList: {
    data: Array<{ text: string; value: Types.PaymentTransactionType }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufPaymentTypeListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.PaymentGenerierlaufType;
}>;

export type FilterGenLaufPaymentTypeListQuery = {
  getFilterGenLaufPaymentTypeList: {
    data: Array<{ text: string; value: Types.PaymentType }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufPaymentStatementNumberListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.PaymentGenerierlaufType;
}>;

export type FilterGenLaufPaymentStatementNumberListQuery = {
  getFilterGenLaufPaymentStatementNumberList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const FilterGenLaufPaymentBuchungskreisRechtstraegerListDocument = gql`
  query FilterGenLaufPaymentBuchungskreisRechtstraegerList($generierlaufId: ID!, $generierlaufType: PaymentGenerierlaufType!) {
    getFilterGenLaufPaymentBuchungskreisRechtstraegerList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        kurzBezeichnung
        rechtstraegerId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufPaymentBuchungskreisRechtstraegerListQuery(
  baseOptions: Apollo.QueryHookOptions<
    FilterGenLaufPaymentBuchungskreisRechtstraegerListQuery,
    FilterGenLaufPaymentBuchungskreisRechtstraegerListQueryVariables
  > &
    ({ variables: FilterGenLaufPaymentBuchungskreisRechtstraegerListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufPaymentBuchungskreisRechtstraegerListQuery, FilterGenLaufPaymentBuchungskreisRechtstraegerListQueryVariables>(
    FilterGenLaufPaymentBuchungskreisRechtstraegerListDocument,
    options
  );
}
export function useFilterGenLaufPaymentBuchungskreisRechtstraegerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterGenLaufPaymentBuchungskreisRechtstraegerListQuery,
    FilterGenLaufPaymentBuchungskreisRechtstraegerListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FilterGenLaufPaymentBuchungskreisRechtstraegerListQuery,
    FilterGenLaufPaymentBuchungskreisRechtstraegerListQueryVariables
  >(FilterGenLaufPaymentBuchungskreisRechtstraegerListDocument, options);
}
export function useFilterGenLaufPaymentBuchungskreisRechtstraegerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FilterGenLaufPaymentBuchungskreisRechtstraegerListQuery,
        FilterGenLaufPaymentBuchungskreisRechtstraegerListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FilterGenLaufPaymentBuchungskreisRechtstraegerListQuery,
    FilterGenLaufPaymentBuchungskreisRechtstraegerListQueryVariables
  >(FilterGenLaufPaymentBuchungskreisRechtstraegerListDocument, options);
}
export type FilterGenLaufPaymentBuchungskreisRechtstraegerListQueryHookResult = ReturnType<
  typeof useFilterGenLaufPaymentBuchungskreisRechtstraegerListQuery
>;
export type FilterGenLaufPaymentBuchungskreisRechtstraegerListLazyQueryHookResult = ReturnType<
  typeof useFilterGenLaufPaymentBuchungskreisRechtstraegerListLazyQuery
>;
export type FilterGenLaufPaymentBuchungskreisRechtstraegerListSuspenseQueryHookResult = ReturnType<
  typeof useFilterGenLaufPaymentBuchungskreisRechtstraegerListSuspenseQuery
>;
export type FilterGenLaufPaymentBuchungskreisRechtstraegerListQueryResult = Apollo.QueryResult<
  FilterGenLaufPaymentBuchungskreisRechtstraegerListQuery,
  FilterGenLaufPaymentBuchungskreisRechtstraegerListQueryVariables
>;
export const FilterGenLaufPaymentCreditorListDocument = gql`
  query FilterGenLaufPaymentCreditorList($generierlaufId: ID!, $generierlaufType: PaymentGenerierlaufType!) {
    getFilterGenLaufPaymentCreditorList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufPaymentCreditorListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufPaymentCreditorListQuery, FilterGenLaufPaymentCreditorListQueryVariables> &
    ({ variables: FilterGenLaufPaymentCreditorListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufPaymentCreditorListQuery, FilterGenLaufPaymentCreditorListQueryVariables>(
    FilterGenLaufPaymentCreditorListDocument,
    options
  );
}
export function useFilterGenLaufPaymentCreditorListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufPaymentCreditorListQuery, FilterGenLaufPaymentCreditorListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufPaymentCreditorListQuery, FilterGenLaufPaymentCreditorListQueryVariables>(
    FilterGenLaufPaymentCreditorListDocument,
    options
  );
}
export function useFilterGenLaufPaymentCreditorListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufPaymentCreditorListQuery, FilterGenLaufPaymentCreditorListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufPaymentCreditorListQuery, FilterGenLaufPaymentCreditorListQueryVariables>(
    FilterGenLaufPaymentCreditorListDocument,
    options
  );
}
export type FilterGenLaufPaymentCreditorListQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentCreditorListQuery>;
export type FilterGenLaufPaymentCreditorListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentCreditorListLazyQuery>;
export type FilterGenLaufPaymentCreditorListSuspenseQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentCreditorListSuspenseQuery>;
export type FilterGenLaufPaymentCreditorListQueryResult = Apollo.QueryResult<
  FilterGenLaufPaymentCreditorListQuery,
  FilterGenLaufPaymentCreditorListQueryVariables
>;
export const FilterGenLaufPaymentDataCarrierNameListDocument = gql`
  query FilterGenLaufPaymentDataCarrierNameList($generierlaufId: ID!, $generierlaufType: PaymentGenerierlaufType!) {
    getFilterGenLaufPaymentDataCarrierNameList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufPaymentDataCarrierNameListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufPaymentDataCarrierNameListQuery, FilterGenLaufPaymentDataCarrierNameListQueryVariables> &
    ({ variables: FilterGenLaufPaymentDataCarrierNameListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufPaymentDataCarrierNameListQuery, FilterGenLaufPaymentDataCarrierNameListQueryVariables>(
    FilterGenLaufPaymentDataCarrierNameListDocument,
    options
  );
}
export function useFilterGenLaufPaymentDataCarrierNameListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufPaymentDataCarrierNameListQuery, FilterGenLaufPaymentDataCarrierNameListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufPaymentDataCarrierNameListQuery, FilterGenLaufPaymentDataCarrierNameListQueryVariables>(
    FilterGenLaufPaymentDataCarrierNameListDocument,
    options
  );
}
export function useFilterGenLaufPaymentDataCarrierNameListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufPaymentDataCarrierNameListQuery, FilterGenLaufPaymentDataCarrierNameListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufPaymentDataCarrierNameListQuery, FilterGenLaufPaymentDataCarrierNameListQueryVariables>(
    FilterGenLaufPaymentDataCarrierNameListDocument,
    options
  );
}
export type FilterGenLaufPaymentDataCarrierNameListQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentDataCarrierNameListQuery>;
export type FilterGenLaufPaymentDataCarrierNameListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentDataCarrierNameListLazyQuery>;
export type FilterGenLaufPaymentDataCarrierNameListSuspenseQueryHookResult = ReturnType<
  typeof useFilterGenLaufPaymentDataCarrierNameListSuspenseQuery
>;
export type FilterGenLaufPaymentDataCarrierNameListQueryResult = Apollo.QueryResult<
  FilterGenLaufPaymentDataCarrierNameListQuery,
  FilterGenLaufPaymentDataCarrierNameListQueryVariables
>;
export const FilterGenLaufPaymentDataCarrierTypeListDocument = gql`
  query FilterGenLaufPaymentDataCarrierTypeList($generierlaufId: ID!, $generierlaufType: PaymentGenerierlaufType!) {
    getFilterGenLaufPaymentDataCarrierTypeList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufPaymentDataCarrierTypeListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufPaymentDataCarrierTypeListQuery, FilterGenLaufPaymentDataCarrierTypeListQueryVariables> &
    ({ variables: FilterGenLaufPaymentDataCarrierTypeListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufPaymentDataCarrierTypeListQuery, FilterGenLaufPaymentDataCarrierTypeListQueryVariables>(
    FilterGenLaufPaymentDataCarrierTypeListDocument,
    options
  );
}
export function useFilterGenLaufPaymentDataCarrierTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufPaymentDataCarrierTypeListQuery, FilterGenLaufPaymentDataCarrierTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufPaymentDataCarrierTypeListQuery, FilterGenLaufPaymentDataCarrierTypeListQueryVariables>(
    FilterGenLaufPaymentDataCarrierTypeListDocument,
    options
  );
}
export function useFilterGenLaufPaymentDataCarrierTypeListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufPaymentDataCarrierTypeListQuery, FilterGenLaufPaymentDataCarrierTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufPaymentDataCarrierTypeListQuery, FilterGenLaufPaymentDataCarrierTypeListQueryVariables>(
    FilterGenLaufPaymentDataCarrierTypeListDocument,
    options
  );
}
export type FilterGenLaufPaymentDataCarrierTypeListQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentDataCarrierTypeListQuery>;
export type FilterGenLaufPaymentDataCarrierTypeListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentDataCarrierTypeListLazyQuery>;
export type FilterGenLaufPaymentDataCarrierTypeListSuspenseQueryHookResult = ReturnType<
  typeof useFilterGenLaufPaymentDataCarrierTypeListSuspenseQuery
>;
export type FilterGenLaufPaymentDataCarrierTypeListQueryResult = Apollo.QueryResult<
  FilterGenLaufPaymentDataCarrierTypeListQuery,
  FilterGenLaufPaymentDataCarrierTypeListQueryVariables
>;
export const FilterGenLaufPaymentDebtorListDocument = gql`
  query FilterGenLaufPaymentDebtorList($generierlaufId: ID!, $generierlaufType: PaymentGenerierlaufType!) {
    getFilterGenLaufPaymentDebtorList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufPaymentDebtorListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufPaymentDebtorListQuery, FilterGenLaufPaymentDebtorListQueryVariables> &
    ({ variables: FilterGenLaufPaymentDebtorListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufPaymentDebtorListQuery, FilterGenLaufPaymentDebtorListQueryVariables>(
    FilterGenLaufPaymentDebtorListDocument,
    options
  );
}
export function useFilterGenLaufPaymentDebtorListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufPaymentDebtorListQuery, FilterGenLaufPaymentDebtorListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufPaymentDebtorListQuery, FilterGenLaufPaymentDebtorListQueryVariables>(
    FilterGenLaufPaymentDebtorListDocument,
    options
  );
}
export function useFilterGenLaufPaymentDebtorListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterGenLaufPaymentDebtorListQuery, FilterGenLaufPaymentDebtorListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufPaymentDebtorListQuery, FilterGenLaufPaymentDebtorListQueryVariables>(
    FilterGenLaufPaymentDebtorListDocument,
    options
  );
}
export type FilterGenLaufPaymentDebtorListQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentDebtorListQuery>;
export type FilterGenLaufPaymentDebtorListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentDebtorListLazyQuery>;
export type FilterGenLaufPaymentDebtorListSuspenseQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentDebtorListSuspenseQuery>;
export type FilterGenLaufPaymentDebtorListQueryResult = Apollo.QueryResult<
  FilterGenLaufPaymentDebtorListQuery,
  FilterGenLaufPaymentDebtorListQueryVariables
>;
export const FilterGenLaufPaymentEbicsUserNameListDocument = gql`
  query FilterGenLaufPaymentEbicsUserNameList($generierlaufId: ID!, $generierlaufType: PaymentGenerierlaufType!) {
    getFilterGenLaufPaymentEbicsUserNameList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufPaymentEbicsUserNameListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufPaymentEbicsUserNameListQuery, FilterGenLaufPaymentEbicsUserNameListQueryVariables> &
    ({ variables: FilterGenLaufPaymentEbicsUserNameListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufPaymentEbicsUserNameListQuery, FilterGenLaufPaymentEbicsUserNameListQueryVariables>(
    FilterGenLaufPaymentEbicsUserNameListDocument,
    options
  );
}
export function useFilterGenLaufPaymentEbicsUserNameListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufPaymentEbicsUserNameListQuery, FilterGenLaufPaymentEbicsUserNameListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufPaymentEbicsUserNameListQuery, FilterGenLaufPaymentEbicsUserNameListQueryVariables>(
    FilterGenLaufPaymentEbicsUserNameListDocument,
    options
  );
}
export function useFilterGenLaufPaymentEbicsUserNameListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufPaymentEbicsUserNameListQuery, FilterGenLaufPaymentEbicsUserNameListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufPaymentEbicsUserNameListQuery, FilterGenLaufPaymentEbicsUserNameListQueryVariables>(
    FilterGenLaufPaymentEbicsUserNameListDocument,
    options
  );
}
export type FilterGenLaufPaymentEbicsUserNameListQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentEbicsUserNameListQuery>;
export type FilterGenLaufPaymentEbicsUserNameListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentEbicsUserNameListLazyQuery>;
export type FilterGenLaufPaymentEbicsUserNameListSuspenseQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentEbicsUserNameListSuspenseQuery>;
export type FilterGenLaufPaymentEbicsUserNameListQueryResult = Apollo.QueryResult<
  FilterGenLaufPaymentEbicsUserNameListQuery,
  FilterGenLaufPaymentEbicsUserNameListQueryVariables
>;
export const FilterGenLaufPaymentEbicsUserUserIdListDocument = gql`
  query FilterGenLaufPaymentEbicsUserUserIdList($generierlaufId: ID!, $generierlaufType: PaymentGenerierlaufType!) {
    getFilterGenLaufPaymentEbicsUserUserIdList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufPaymentEbicsUserUserIdListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufPaymentEbicsUserUserIdListQuery, FilterGenLaufPaymentEbicsUserUserIdListQueryVariables> &
    ({ variables: FilterGenLaufPaymentEbicsUserUserIdListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufPaymentEbicsUserUserIdListQuery, FilterGenLaufPaymentEbicsUserUserIdListQueryVariables>(
    FilterGenLaufPaymentEbicsUserUserIdListDocument,
    options
  );
}
export function useFilterGenLaufPaymentEbicsUserUserIdListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufPaymentEbicsUserUserIdListQuery, FilterGenLaufPaymentEbicsUserUserIdListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufPaymentEbicsUserUserIdListQuery, FilterGenLaufPaymentEbicsUserUserIdListQueryVariables>(
    FilterGenLaufPaymentEbicsUserUserIdListDocument,
    options
  );
}
export function useFilterGenLaufPaymentEbicsUserUserIdListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufPaymentEbicsUserUserIdListQuery, FilterGenLaufPaymentEbicsUserUserIdListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufPaymentEbicsUserUserIdListQuery, FilterGenLaufPaymentEbicsUserUserIdListQueryVariables>(
    FilterGenLaufPaymentEbicsUserUserIdListDocument,
    options
  );
}
export type FilterGenLaufPaymentEbicsUserUserIdListQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentEbicsUserUserIdListQuery>;
export type FilterGenLaufPaymentEbicsUserUserIdListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentEbicsUserUserIdListLazyQuery>;
export type FilterGenLaufPaymentEbicsUserUserIdListSuspenseQueryHookResult = ReturnType<
  typeof useFilterGenLaufPaymentEbicsUserUserIdListSuspenseQuery
>;
export type FilterGenLaufPaymentEbicsUserUserIdListQueryResult = Apollo.QueryResult<
  FilterGenLaufPaymentEbicsUserUserIdListQuery,
  FilterGenLaufPaymentEbicsUserUserIdListQueryVariables
>;
export const FilterGenLaufPaymentGenerierlaufEntryStatusListDocument = gql`
  query FilterGenLaufPaymentGenerierlaufEntryStatusList($generierlaufId: ID!, $generierlaufType: PaymentGenerierlaufType!) {
    getFilterGenLaufPaymentGenerierlaufEntryStatusList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufPaymentGenerierlaufEntryStatusListQuery(
  baseOptions: Apollo.QueryHookOptions<
    FilterGenLaufPaymentGenerierlaufEntryStatusListQuery,
    FilterGenLaufPaymentGenerierlaufEntryStatusListQueryVariables
  > &
    ({ variables: FilterGenLaufPaymentGenerierlaufEntryStatusListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufPaymentGenerierlaufEntryStatusListQuery, FilterGenLaufPaymentGenerierlaufEntryStatusListQueryVariables>(
    FilterGenLaufPaymentGenerierlaufEntryStatusListDocument,
    options
  );
}
export function useFilterGenLaufPaymentGenerierlaufEntryStatusListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterGenLaufPaymentGenerierlaufEntryStatusListQuery,
    FilterGenLaufPaymentGenerierlaufEntryStatusListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufPaymentGenerierlaufEntryStatusListQuery, FilterGenLaufPaymentGenerierlaufEntryStatusListQueryVariables>(
    FilterGenLaufPaymentGenerierlaufEntryStatusListDocument,
    options
  );
}
export function useFilterGenLaufPaymentGenerierlaufEntryStatusListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FilterGenLaufPaymentGenerierlaufEntryStatusListQuery,
        FilterGenLaufPaymentGenerierlaufEntryStatusListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufPaymentGenerierlaufEntryStatusListQuery, FilterGenLaufPaymentGenerierlaufEntryStatusListQueryVariables>(
    FilterGenLaufPaymentGenerierlaufEntryStatusListDocument,
    options
  );
}
export type FilterGenLaufPaymentGenerierlaufEntryStatusListQueryHookResult = ReturnType<
  typeof useFilterGenLaufPaymentGenerierlaufEntryStatusListQuery
>;
export type FilterGenLaufPaymentGenerierlaufEntryStatusListLazyQueryHookResult = ReturnType<
  typeof useFilterGenLaufPaymentGenerierlaufEntryStatusListLazyQuery
>;
export type FilterGenLaufPaymentGenerierlaufEntryStatusListSuspenseQueryHookResult = ReturnType<
  typeof useFilterGenLaufPaymentGenerierlaufEntryStatusListSuspenseQuery
>;
export type FilterGenLaufPaymentGenerierlaufEntryStatusListQueryResult = Apollo.QueryResult<
  FilterGenLaufPaymentGenerierlaufEntryStatusListQuery,
  FilterGenLaufPaymentGenerierlaufEntryStatusListQueryVariables
>;
export const FilterGenLaufPaymentIbanListDocument = gql`
  query FilterGenLaufPaymentIbanList($generierlaufId: ID!, $generierlaufType: PaymentGenerierlaufType!) {
    getFilterGenLaufPaymentIbanList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufPaymentIbanListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufPaymentIbanListQuery, FilterGenLaufPaymentIbanListQueryVariables> &
    ({ variables: FilterGenLaufPaymentIbanListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufPaymentIbanListQuery, FilterGenLaufPaymentIbanListQueryVariables>(
    FilterGenLaufPaymentIbanListDocument,
    options
  );
}
export function useFilterGenLaufPaymentIbanListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufPaymentIbanListQuery, FilterGenLaufPaymentIbanListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufPaymentIbanListQuery, FilterGenLaufPaymentIbanListQueryVariables>(
    FilterGenLaufPaymentIbanListDocument,
    options
  );
}
export function useFilterGenLaufPaymentIbanListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterGenLaufPaymentIbanListQuery, FilterGenLaufPaymentIbanListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufPaymentIbanListQuery, FilterGenLaufPaymentIbanListQueryVariables>(
    FilterGenLaufPaymentIbanListDocument,
    options
  );
}
export type FilterGenLaufPaymentIbanListQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentIbanListQuery>;
export type FilterGenLaufPaymentIbanListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentIbanListLazyQuery>;
export type FilterGenLaufPaymentIbanListSuspenseQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentIbanListSuspenseQuery>;
export type FilterGenLaufPaymentIbanListQueryResult = Apollo.QueryResult<
  FilterGenLaufPaymentIbanListQuery,
  FilterGenLaufPaymentIbanListQueryVariables
>;
export const FilterGenLaufPaymentMitarbeiterListDocument = gql`
  query FilterGenLaufPaymentMitarbeiterList($generierlaufId: ID!, $generierlaufType: PaymentGenerierlaufType!) {
    getFilterGenLaufPaymentMitarbeiterList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        firmendatenId
        firstname
        lastname
        mitarbeiterId
        username
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufPaymentMitarbeiterListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufPaymentMitarbeiterListQuery, FilterGenLaufPaymentMitarbeiterListQueryVariables> &
    ({ variables: FilterGenLaufPaymentMitarbeiterListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufPaymentMitarbeiterListQuery, FilterGenLaufPaymentMitarbeiterListQueryVariables>(
    FilterGenLaufPaymentMitarbeiterListDocument,
    options
  );
}
export function useFilterGenLaufPaymentMitarbeiterListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufPaymentMitarbeiterListQuery, FilterGenLaufPaymentMitarbeiterListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufPaymentMitarbeiterListQuery, FilterGenLaufPaymentMitarbeiterListQueryVariables>(
    FilterGenLaufPaymentMitarbeiterListDocument,
    options
  );
}
export function useFilterGenLaufPaymentMitarbeiterListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufPaymentMitarbeiterListQuery, FilterGenLaufPaymentMitarbeiterListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufPaymentMitarbeiterListQuery, FilterGenLaufPaymentMitarbeiterListQueryVariables>(
    FilterGenLaufPaymentMitarbeiterListDocument,
    options
  );
}
export type FilterGenLaufPaymentMitarbeiterListQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentMitarbeiterListQuery>;
export type FilterGenLaufPaymentMitarbeiterListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentMitarbeiterListLazyQuery>;
export type FilterGenLaufPaymentMitarbeiterListSuspenseQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentMitarbeiterListSuspenseQuery>;
export type FilterGenLaufPaymentMitarbeiterListQueryResult = Apollo.QueryResult<
  FilterGenLaufPaymentMitarbeiterListQuery,
  FilterGenLaufPaymentMitarbeiterListQueryVariables
>;
export const FilterGenLaufPaymentObjektListDocument = gql`
  query FilterGenLaufPaymentObjektList($generierlaufId: ID!, $generierlaufType: PaymentGenerierlaufType!) {
    getFilterGenLaufPaymentObjektList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        kurzBezeichnung
        objektId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufPaymentObjektListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufPaymentObjektListQuery, FilterGenLaufPaymentObjektListQueryVariables> &
    ({ variables: FilterGenLaufPaymentObjektListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufPaymentObjektListQuery, FilterGenLaufPaymentObjektListQueryVariables>(
    FilterGenLaufPaymentObjektListDocument,
    options
  );
}
export function useFilterGenLaufPaymentObjektListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufPaymentObjektListQuery, FilterGenLaufPaymentObjektListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufPaymentObjektListQuery, FilterGenLaufPaymentObjektListQueryVariables>(
    FilterGenLaufPaymentObjektListDocument,
    options
  );
}
export function useFilterGenLaufPaymentObjektListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterGenLaufPaymentObjektListQuery, FilterGenLaufPaymentObjektListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufPaymentObjektListQuery, FilterGenLaufPaymentObjektListQueryVariables>(
    FilterGenLaufPaymentObjektListDocument,
    options
  );
}
export type FilterGenLaufPaymentObjektListQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentObjektListQuery>;
export type FilterGenLaufPaymentObjektListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentObjektListLazyQuery>;
export type FilterGenLaufPaymentObjektListSuspenseQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentObjektListSuspenseQuery>;
export type FilterGenLaufPaymentObjektListQueryResult = Apollo.QueryResult<
  FilterGenLaufPaymentObjektListQuery,
  FilterGenLaufPaymentObjektListQueryVariables
>;
export const FilterGenLaufPaymentTransactionTypeListDocument = gql`
  query FilterGenLaufPaymentTransactionTypeList($generierlaufId: ID!, $generierlaufType: PaymentGenerierlaufType!) {
    getFilterGenLaufPaymentTransactionTypeList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufPaymentTransactionTypeListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufPaymentTransactionTypeListQuery, FilterGenLaufPaymentTransactionTypeListQueryVariables> &
    ({ variables: FilterGenLaufPaymentTransactionTypeListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufPaymentTransactionTypeListQuery, FilterGenLaufPaymentTransactionTypeListQueryVariables>(
    FilterGenLaufPaymentTransactionTypeListDocument,
    options
  );
}
export function useFilterGenLaufPaymentTransactionTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufPaymentTransactionTypeListQuery, FilterGenLaufPaymentTransactionTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufPaymentTransactionTypeListQuery, FilterGenLaufPaymentTransactionTypeListQueryVariables>(
    FilterGenLaufPaymentTransactionTypeListDocument,
    options
  );
}
export function useFilterGenLaufPaymentTransactionTypeListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufPaymentTransactionTypeListQuery, FilterGenLaufPaymentTransactionTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufPaymentTransactionTypeListQuery, FilterGenLaufPaymentTransactionTypeListQueryVariables>(
    FilterGenLaufPaymentTransactionTypeListDocument,
    options
  );
}
export type FilterGenLaufPaymentTransactionTypeListQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentTransactionTypeListQuery>;
export type FilterGenLaufPaymentTransactionTypeListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentTransactionTypeListLazyQuery>;
export type FilterGenLaufPaymentTransactionTypeListSuspenseQueryHookResult = ReturnType<
  typeof useFilterGenLaufPaymentTransactionTypeListSuspenseQuery
>;
export type FilterGenLaufPaymentTransactionTypeListQueryResult = Apollo.QueryResult<
  FilterGenLaufPaymentTransactionTypeListQuery,
  FilterGenLaufPaymentTransactionTypeListQueryVariables
>;
export const FilterGenLaufPaymentTypeListDocument = gql`
  query FilterGenLaufPaymentTypeList($generierlaufId: ID!, $generierlaufType: PaymentGenerierlaufType!) {
    getFilterGenLaufPaymentTypeList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufPaymentTypeListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufPaymentTypeListQuery, FilterGenLaufPaymentTypeListQueryVariables> &
    ({ variables: FilterGenLaufPaymentTypeListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufPaymentTypeListQuery, FilterGenLaufPaymentTypeListQueryVariables>(
    FilterGenLaufPaymentTypeListDocument,
    options
  );
}
export function useFilterGenLaufPaymentTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufPaymentTypeListQuery, FilterGenLaufPaymentTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufPaymentTypeListQuery, FilterGenLaufPaymentTypeListQueryVariables>(
    FilterGenLaufPaymentTypeListDocument,
    options
  );
}
export function useFilterGenLaufPaymentTypeListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterGenLaufPaymentTypeListQuery, FilterGenLaufPaymentTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufPaymentTypeListQuery, FilterGenLaufPaymentTypeListQueryVariables>(
    FilterGenLaufPaymentTypeListDocument,
    options
  );
}
export type FilterGenLaufPaymentTypeListQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentTypeListQuery>;
export type FilterGenLaufPaymentTypeListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentTypeListLazyQuery>;
export type FilterGenLaufPaymentTypeListSuspenseQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentTypeListSuspenseQuery>;
export type FilterGenLaufPaymentTypeListQueryResult = Apollo.QueryResult<
  FilterGenLaufPaymentTypeListQuery,
  FilterGenLaufPaymentTypeListQueryVariables
>;
export const FilterGenLaufPaymentStatementNumberListDocument = gql`
  query FilterGenLaufPaymentStatementNumberList($generierlaufId: ID!, $generierlaufType: PaymentGenerierlaufType!) {
    getFilterGenLaufPaymentStatementNumberList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufPaymentStatementNumberListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufPaymentStatementNumberListQuery, FilterGenLaufPaymentStatementNumberListQueryVariables> &
    ({ variables: FilterGenLaufPaymentStatementNumberListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufPaymentStatementNumberListQuery, FilterGenLaufPaymentStatementNumberListQueryVariables>(
    FilterGenLaufPaymentStatementNumberListDocument,
    options
  );
}
export function useFilterGenLaufPaymentStatementNumberListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufPaymentStatementNumberListQuery, FilterGenLaufPaymentStatementNumberListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufPaymentStatementNumberListQuery, FilterGenLaufPaymentStatementNumberListQueryVariables>(
    FilterGenLaufPaymentStatementNumberListDocument,
    options
  );
}
export function useFilterGenLaufPaymentStatementNumberListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufPaymentStatementNumberListQuery, FilterGenLaufPaymentStatementNumberListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufPaymentStatementNumberListQuery, FilterGenLaufPaymentStatementNumberListQueryVariables>(
    FilterGenLaufPaymentStatementNumberListDocument,
    options
  );
}
export type FilterGenLaufPaymentStatementNumberListQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentStatementNumberListQuery>;
export type FilterGenLaufPaymentStatementNumberListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufPaymentStatementNumberListLazyQuery>;
export type FilterGenLaufPaymentStatementNumberListSuspenseQueryHookResult = ReturnType<
  typeof useFilterGenLaufPaymentStatementNumberListSuspenseQuery
>;
export type FilterGenLaufPaymentStatementNumberListQueryResult = Apollo.QueryResult<
  FilterGenLaufPaymentStatementNumberListQuery,
  FilterGenLaufPaymentStatementNumberListQueryVariables
>;
