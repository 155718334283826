import { v4 as uuid4 } from 'uuid';
import { FormFields, TFormattedDecimal } from '../../helpers/formikHelper';
import {
  MahndefinitionCreateInput,
  MahndefinitionUpdateInput,
  MahngebuehrCreateInput,
  MahngebuehrUpdateInput,
  Mahnstufe,
  MahnstufeCreateInput,
  MahnstufeUpdateInput,
} from '../../types';
import { mapFormValuesToTextbaustein } from '../Abrechnungsdefinition/shared/Templates/shared/abrDefTemplateFormMapper';
import { TextbausteinFormValues } from '../../components/Template/PDFTemplates/templateMapper';
import { mapFormattedDecimalOrThrowIfEmpty } from '../../components/Input-antd/formattedDecimalHelper';
import { MahndefinitionFieldsFragment } from './gql/MahndefinitionFragments.types';

export type MahndefinitionParamFormValues = {
  bezeichnung: string;
  mahnfreieTage: number;
  verzugszinsen: TFormattedDecimal;
  mahnstufeList: MahnstufeFormValues[];
};

export type MahnstufeFormValues = {
  mahngebuehrList: MahngebuehrFormValues[];
  minBetrag: TFormattedDecimal;
  naechsteMahnungNachTage: number;
  verzugszinsenBerechnen: boolean;
  versandEinschreiben: boolean;
};

export type MahngebuehrFormValues = {
  ab: TFormattedDecimal;
  betrag: TFormattedDecimal;
  uuid?: string;
  mahngebuehrId?: string;
};

const DEFAULT_MAHNSTUFENLIST: MahnstufeFormValues[] = [
  {
    minBetrag: 0,
    mahngebuehrList: [],
    verzugszinsenBerechnen: false,
    versandEinschreiben: false,
    naechsteMahnungNachTage: 0,
  },
  {
    minBetrag: 0,
    mahngebuehrList: [],
    verzugszinsenBerechnen: false,
    versandEinschreiben: false,
    naechsteMahnungNachTage: 0,
  },
  {
    minBetrag: 0,
    mahngebuehrList: [],
    verzugszinsenBerechnen: false,
    versandEinschreiben: false,
    naechsteMahnungNachTage: 0,
  },
  {
    minBetrag: 0,
    mahngebuehrList: [],
    verzugszinsenBerechnen: false,
    versandEinschreiben: true,
    naechsteMahnungNachTage: 0,
  },
];

export const createNewMahngebuehr = (): MahngebuehrFormValues => ({
  ab: 0,
  betrag: 0,
  uuid: uuid4(),
});

export const mapMahndefinitionToParamFormValues = (mahndefinition?: MahndefinitionFieldsFragment): MahndefinitionParamFormValues => {
  return {
    bezeichnung: mahndefinition?.bezeichnung ?? '',
    verzugszinsen: mahndefinition?.verzugszinsen ? mahndefinition.verzugszinsen : 0,
    mahnfreieTage: mahndefinition?.mahnfreieTage ?? 0,
    mahnstufeList: mahndefinition
      ? mapMahnstufenToFormValues([mahndefinition.mahnstufe1, mahndefinition.mahnstufe2, mahndefinition.mahnstufe3, mahndefinition.mahnstufe4])
      : DEFAULT_MAHNSTUFENLIST,
  };
};

const mapMahnstufenToFormValues = (mahnstufenList: Mahnstufe[]): MahnstufeFormValues[] => {
  return mahnstufenList.map((mahnstufe) => ({
    minBetrag: mahnstufe.minBetrag,
    verzugszinsenBerechnen: mahnstufe.verzugszinsenBerechnen,
    versandEinschreiben: mahnstufe.versandEinschreiben,
    naechsteMahnungNachTage: mahnstufe.naechsteMahnungNachTage,
    mahngebuehrList: mapMahngebuehrListToFormValues(mahnstufe.mahngebuehrList),
  }));
};

const mapMahngebuehrListToFormValues = (mahngebuehrList: MahngebuehrCreateInput[] | MahngebuehrUpdateInput[]): MahngebuehrFormValues[] => {
  return mahngebuehrList.map((mahngebuehr) => {
    return isMahngebuehrUpdate(mahngebuehr)
      ? {
          ab: mahngebuehr.ab,
          betrag: mahngebuehr.betrag,
          mahngebuehrId: mahngebuehr.mahngebuehrId ? mahngebuehr.mahngebuehrId : undefined,
        }
      : {
          ab: mahngebuehr.ab,
          betrag: mahngebuehr.betrag,
        };
  });
};

const isMahngebuehrUpdate = (mahngebuehr: MahngebuehrCreateInput | MahngebuehrUpdateInput): mahngebuehr is MahngebuehrUpdateInput =>
  Object.prototype.hasOwnProperty.call(mahngebuehr, 'mahngebuehrId');

export const mapFormValuesToMahndefinition = (
  mahndefinitionen: MahndefinitionParamFormValues,
  mahnstufe1TextbausteinList: TextbausteinFormValues[],
  mahnstufe2TextbausteinList: TextbausteinFormValues[],
  mahnstufe3TextbausteinList: TextbausteinFormValues[],
  mahnstufe4TextbausteinList: TextbausteinFormValues[]
): MahndefinitionCreateInput | MahndefinitionUpdateInput => {
  return {
    bezeichnung: mahndefinitionen.bezeichnung,
    mahnfreieTage: mahndefinitionen.mahnfreieTage,
    verzugszinsen: mapFormattedDecimalOrThrowIfEmpty(mahndefinitionen.verzugszinsen),
    mahnstufe1: mapMahngebuehrListToMahnstufe(mahndefinitionen.mahnstufeList[0]),
    mahnstufe1TextbausteinList: mapFormValuesToTextbaustein(mahnstufe1TextbausteinList),
    mahnstufe2: mapMahngebuehrListToMahnstufe(mahndefinitionen.mahnstufeList[1]),
    mahnstufe2TextbausteinList: mapFormValuesToTextbaustein(mahnstufe2TextbausteinList),
    mahnstufe3: mapMahngebuehrListToMahnstufe(mahndefinitionen.mahnstufeList[2]),
    mahnstufe3TextbausteinList: mapFormValuesToTextbaustein(mahnstufe3TextbausteinList),
    mahnstufe4: mapMahngebuehrListToMahnstufe(mahndefinitionen.mahnstufeList[3]),
    mahnstufe4TextbausteinList: mapFormValuesToTextbaustein(mahnstufe4TextbausteinList),
  };
};

const mapMahngebuehrListToMahnstufe = (mahnstufeList: MahnstufeFormValues): MahnstufeCreateInput | MahnstufeUpdateInput => ({
  minBetrag: mapFormattedDecimalOrThrowIfEmpty(mahnstufeList.minBetrag),
  mahngebuehrList: mapMahngebuehrListToMahngebuehrList(mahnstufeList.mahngebuehrList),
  verzugszinsenBerechnen: mahnstufeList.verzugszinsenBerechnen,
  versandEinschreiben: mahnstufeList.versandEinschreiben,
  naechsteMahnungNachTage: mahnstufeList.naechsteMahnungNachTage,
});

const mapMahngebuehrListToMahngebuehrList = (mahngebuehrList: MahngebuehrFormValues[]): MahngebuehrCreateInput[] | MahngebuehrUpdateInput[] => {
  return mahngebuehrList.map((mahngebuehr) => ({
    ab: mapFormattedDecimalOrThrowIfEmpty(mahngebuehr.ab),
    betrag: mapFormattedDecimalOrThrowIfEmpty(mahngebuehr.betrag),
    mahngebuehrId: mahngebuehr.mahngebuehrId,
  }));
};

export const mahndefinitionParamFormFields: FormFields<MahndefinitionParamFormValues> = {
  bezeichnung: 'bezeichnung',
  verzugszinsen: 'verzugszinsen',
  mahnstufeList: 'mahnstufeList',
  mahnfreieTage: 'mahnfreieTage',
};

export const mahnstufenFormFields: FormFields<MahnstufeFormValues> = {
  mahngebuehrList: 'mahngebuehrList',
  naechsteMahnungNachTage: 'naechsteMahnungNachTage',
  verzugszinsenBerechnen: 'verzugszinsenBerechnen',
  versandEinschreiben: 'versandEinschreiben',
  minBetrag: 'minBetrag',
};

export const mahngebuehrenFormFields: FormFields<MahngebuehrFormValues> = {
  ab: 'ab',
  betrag: 'betrag',
  uuid: 'uuid',
  mahngebuehrId: 'mahngebuehrId',
};
