import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Space, Typography } from 'antd';

import DeviationChangeTypeTag from '../../../DeviationChangeTypeTag';
import { DeviationColumnTitleStyledText } from '../../../Styled/Deviation.style';
import DataWithShortenedTextAndExtraInfo from '../../../../Helpers/DataWithShortenedTextAndExtraInfo';
import { DeviationChangeTypeTuple, VorsteuerkuerzungDeviationSourceEntity } from '../../../../../types';
import { generatePathToBestandseinheitDetailsPage } from '../../../../../features/Bestandseinheit/bestandseinheitUriPaths';
import CustomFormattedDate from '../../../../FormattedDate/CustomFormattedDate';

type VorsteuerkuerzungSourceEntityHeaderProps = {
  changeType: DeviationChangeTypeTuple;
  sourceEntity: VorsteuerkuerzungDeviationSourceEntity;
};

const VorsteuerkuerzungSourceEntityHeader: FC<VorsteuerkuerzungSourceEntityHeaderProps> = ({ changeType, sourceEntity }) => {
  return (
    <Space align="baseline" size={8} split={<Divider type="vertical" />}>
      <Space size={4} align="baseline">
        <DeviationChangeTypeTag changeType={changeType} />
        <DeviationColumnTitleStyledText type="secondary">{sourceEntity.type.text}</DeviationColumnTitleStyledText>
      </Space>
      <Space size="large">
        <Space size={4} style={{ width: '200px' }}>
          <DataWithShortenedTextAndExtraInfo maxTextLength={30} text={sourceEntity.objektBezeichnung} />
        </Space>
        <Space size={4} style={{ width: '150px' }}>
          <DataWithShortenedTextAndExtraInfo maxTextLength={20} text={sourceEntity.bestandseinheitBezeichnung}>
            {(shortenedText) => (
              // ids in VorsteuerkuerzungSourceEntity werden vom BE immer geliefert
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              <Link to={generatePathToBestandseinheitDetailsPage(sourceEntity.ids!.objektId, sourceEntity.ids!.bestandseinheitId)}>
                {shortenedText}
              </Link>
            )}
          </DataWithShortenedTextAndExtraInfo>
        </Space>
        <Space size={8}>
          <Space size={4}>
            <Typography.Text type="secondary">von</Typography.Text>
            <CustomFormattedDate value={sourceEntity.vertragsBeginn} />
          </Space>
          {sourceEntity.vertragsEnde && (
            <Space size={4}>
              <Typography.Text type="secondary">bis</Typography.Text>
              <CustomFormattedDate value={sourceEntity.vertragsEnde} />
            </Space>
          )}
        </Space>
      </Space>
    </Space>
  );
};

export default VorsteuerkuerzungSourceEntityHeader;
