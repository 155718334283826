import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { useFirmendatenFileInfoInvoiceRecipientListQuery } from '../../gql/FirmendatenFileInfoFilterQueries.types';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = SelectProps;

const DocumentInvoiceRecipientListFormSelect: FC<Props> = ({ ...restProps }) => {
  const { loading, data } = useFirmendatenFileInfoInvoiceRecipientListQuery();
  const list = data?.getFirmendatenFileInfoInvoiceRecipientList.data ?? [];

  return (
    <Select
      {...restProps}
      size="small"
      id={restProps.name}
      loading={loading}
      placeholder="Rechnungsempfänger auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {list.map((invoiceRecipient) => (
        <Select.Option key={invoiceRecipient.rechtstraegerId} value={invoiceRecipient.rechtstraegerId}>
          <DataWithShortenedText maxTextLength={35} text={invoiceRecipient.kurzBezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default DocumentInvoiceRecipientListFormSelect;
