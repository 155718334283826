import styled from 'styled-components';

export const StyledVstKuerzungKontoDetailsTable = styled('table')`
  thead {
    th {
      width: 15.5%;
      &:not(:nth-child(3)) {
        text-align: right !important;
      }
      &:not(:nth-child(3), :nth-child(4)) {
        width: 11.5%;
      }
    }
  }
  tbody {
    tr {
      td {
        &:nth-child(3) {
          text-align: left;
        }
      }
      &:last-child td {
        border: 0;
      }
    }
  }
`;
