import React from 'react';
import { FireOutlined, HomeOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../../components/ContextSidebar/ContentWithSidebar';
import { verarbeitungPageAndMenuListTitles, verarbeitungTypes } from '../../../features/Verarbeitung/verarbeitungHelpers';
import { generatePathToVerarbeitungDataCarrierPainCreationDetailsPage } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import VerarbeitungErrorAndWarningListing from '../../../features/Verarbeitung/shared/ErrorAndWarningListing/VerarbeitungErrorAndWarningListing';
import VerarbeitungDataCarrierPainCreation from '../../../features/Verarbeitung/DataCarrierPainCreation/VerarbeitungDataCarrierPainCreation';
import { DataCarrierPainCreationGenerierlauf } from '../../../types';

const menuListDataCarrierPainCreation = (generierlauf: DataCarrierPainCreationGenerierlauf): GroupedMenueListInterface => {
  return {
    topMenuList: [
      {
        title: verarbeitungPageAndMenuListTitles.DataCarrierPainCreation,
        icon: <HomeOutlined />,
        uri: `${generatePathToVerarbeitungDataCarrierPainCreationDetailsPage(generierlauf.generierlaufId)}/entries`,
        content: () => (
          <VerarbeitungDataCarrierPainCreation
            generierlauf={generierlauf}
            title={verarbeitungPageAndMenuListTitles.DataCarrierPainCreation}
            verarbeitungType={verarbeitungTypes.DataCarrierPainCreation}
          />
        ),
      },
      {
        state: generierlauf.errorList.length > 0 || generierlauf.warningList.length > 0 ? 'error' : undefined,
        title: verarbeitungPageAndMenuListTitles.ErrorsAndWarnings,
        icon: <FireOutlined />,
        uri: `${generatePathToVerarbeitungDataCarrierPainCreationDetailsPage(generierlauf.generierlaufId)}/errors-and-warnings`,
        content: () => <VerarbeitungErrorAndWarningListing errorList={generierlauf.errorList} warningList={generierlauf.warningList} />,
      },
    ],
  };
};

export default menuListDataCarrierPainCreation;
