import * as Types from '../../../../../../types';

import { gql } from '@apollo/client';
import { VertragVposIndexationAgreementFieldsFragmentDoc } from './VertragVposIndexationAgreementFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateVertragVposIndexationAgreementCategoryMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  input: Types.VertragVposIndexationAgreementCategoryCreateInput;
}>;

export type CreateVertragVposIndexationAgreementCategoryMutation = {
  createVertragVposIndexationAgreementCategory: {
    data:
      | {
          __typename: 'VertragVposIndexationAgreementCategory';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          basedOnUsableArea: boolean;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }
      | {
          __typename: 'VertragVposIndexationAgreementIndexAnnual';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          annualAverage: boolean;
          minimumLimit?: number | null;
          shareInPercentToApply?: number | null;
          subsequentBilling: boolean;
          threshold: number;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          effectiveWithMonth?: { text: string; value: Types.Month } | null;
          monthOfIndexPoint: { text: string; value: Types.Month };
          subsequentBillingObjektVorschreibungsposition?: {
            bezeichnung: string;
            objektVorschreibungspositionId: string;
            kurzBezeichnung: string;
          } | null;
          thresholdType: { text: string; value: Types.ThresholdType };
        }
      | {
          __typename: 'VertragVposIndexationAgreementIndexThreshold';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          effectiveAfterMonths?: number | null;
          minimumLimit?: number | null;
          shareInPercentToApply?: number | null;
          subsequentBilling: boolean;
          threshold: number;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          subsequentBillingObjektVorschreibungsposition?: {
            bezeichnung: string;
            objektVorschreibungspositionId: string;
            kurzBezeichnung: string;
          } | null;
          thresholdType: { text: string; value: Types.ThresholdType };
        }
      | {
          __typename: 'VertragVposIndexationAgreementReferenceValue';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type CreateVertragVposIndexationAgreementIndexAnnualMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  input: Types.VertragVposIndexationAgreementIndexAnnualCreateInput;
}>;

export type CreateVertragVposIndexationAgreementIndexAnnualMutation = {
  createVertragVposIndexationAgreementIndexAnnual: {
    data:
      | {
          __typename: 'VertragVposIndexationAgreementCategory';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          basedOnUsableArea: boolean;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }
      | {
          __typename: 'VertragVposIndexationAgreementIndexAnnual';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          annualAverage: boolean;
          minimumLimit?: number | null;
          shareInPercentToApply?: number | null;
          subsequentBilling: boolean;
          threshold: number;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          effectiveWithMonth?: { text: string; value: Types.Month } | null;
          monthOfIndexPoint: { text: string; value: Types.Month };
          subsequentBillingObjektVorschreibungsposition?: {
            bezeichnung: string;
            objektVorschreibungspositionId: string;
            kurzBezeichnung: string;
          } | null;
          thresholdType: { text: string; value: Types.ThresholdType };
        }
      | {
          __typename: 'VertragVposIndexationAgreementIndexThreshold';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          effectiveAfterMonths?: number | null;
          minimumLimit?: number | null;
          shareInPercentToApply?: number | null;
          subsequentBilling: boolean;
          threshold: number;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          subsequentBillingObjektVorschreibungsposition?: {
            bezeichnung: string;
            objektVorschreibungspositionId: string;
            kurzBezeichnung: string;
          } | null;
          thresholdType: { text: string; value: Types.ThresholdType };
        }
      | {
          __typename: 'VertragVposIndexationAgreementReferenceValue';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type CreateVertragVposIndexationAgreementIndexThresholdMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  input: Types.VertragVposIndexationAgreementIndexThresholdCreateInput;
}>;

export type CreateVertragVposIndexationAgreementIndexThresholdMutation = {
  createVertragVposIndexationAgreementIndexThreshold: {
    data:
      | {
          __typename: 'VertragVposIndexationAgreementCategory';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          basedOnUsableArea: boolean;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }
      | {
          __typename: 'VertragVposIndexationAgreementIndexAnnual';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          annualAverage: boolean;
          minimumLimit?: number | null;
          shareInPercentToApply?: number | null;
          subsequentBilling: boolean;
          threshold: number;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          effectiveWithMonth?: { text: string; value: Types.Month } | null;
          monthOfIndexPoint: { text: string; value: Types.Month };
          subsequentBillingObjektVorschreibungsposition?: {
            bezeichnung: string;
            objektVorschreibungspositionId: string;
            kurzBezeichnung: string;
          } | null;
          thresholdType: { text: string; value: Types.ThresholdType };
        }
      | {
          __typename: 'VertragVposIndexationAgreementIndexThreshold';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          effectiveAfterMonths?: number | null;
          minimumLimit?: number | null;
          shareInPercentToApply?: number | null;
          subsequentBilling: boolean;
          threshold: number;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          subsequentBillingObjektVorschreibungsposition?: {
            bezeichnung: string;
            objektVorschreibungspositionId: string;
            kurzBezeichnung: string;
          } | null;
          thresholdType: { text: string; value: Types.ThresholdType };
        }
      | {
          __typename: 'VertragVposIndexationAgreementReferenceValue';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type CreateVertragVposIndexationAgreementReferenceValueMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  input: Types.VertragVposIndexationAgreementReferenceValueCreateInput;
}>;

export type CreateVertragVposIndexationAgreementReferenceValueMutation = {
  createVertragVposIndexationAgreementReferenceValue: {
    data:
      | {
          __typename: 'VertragVposIndexationAgreementCategory';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          basedOnUsableArea: boolean;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }
      | {
          __typename: 'VertragVposIndexationAgreementIndexAnnual';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          annualAverage: boolean;
          minimumLimit?: number | null;
          shareInPercentToApply?: number | null;
          subsequentBilling: boolean;
          threshold: number;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          effectiveWithMonth?: { text: string; value: Types.Month } | null;
          monthOfIndexPoint: { text: string; value: Types.Month };
          subsequentBillingObjektVorschreibungsposition?: {
            bezeichnung: string;
            objektVorschreibungspositionId: string;
            kurzBezeichnung: string;
          } | null;
          thresholdType: { text: string; value: Types.ThresholdType };
        }
      | {
          __typename: 'VertragVposIndexationAgreementIndexThreshold';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          effectiveAfterMonths?: number | null;
          minimumLimit?: number | null;
          shareInPercentToApply?: number | null;
          subsequentBilling: boolean;
          threshold: number;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          subsequentBillingObjektVorschreibungsposition?: {
            bezeichnung: string;
            objektVorschreibungspositionId: string;
            kurzBezeichnung: string;
          } | null;
          thresholdType: { text: string; value: Types.ThresholdType };
        }
      | {
          __typename: 'VertragVposIndexationAgreementReferenceValue';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UpdateVertragVposIndexationAgreementCategoryMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  indexationAgreementId: Types.Scalars['ID']['input'];
  input: Types.VertragVposIndexationAgreementCategoryUpdateInput;
}>;

export type UpdateVertragVposIndexationAgreementCategoryMutation = {
  updateVertragVposIndexationAgreementCategory: {
    data:
      | {
          __typename: 'VertragVposIndexationAgreementCategory';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          basedOnUsableArea: boolean;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }
      | {
          __typename: 'VertragVposIndexationAgreementIndexAnnual';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          annualAverage: boolean;
          minimumLimit?: number | null;
          shareInPercentToApply?: number | null;
          subsequentBilling: boolean;
          threshold: number;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          effectiveWithMonth?: { text: string; value: Types.Month } | null;
          monthOfIndexPoint: { text: string; value: Types.Month };
          subsequentBillingObjektVorschreibungsposition?: {
            bezeichnung: string;
            objektVorschreibungspositionId: string;
            kurzBezeichnung: string;
          } | null;
          thresholdType: { text: string; value: Types.ThresholdType };
        }
      | {
          __typename: 'VertragVposIndexationAgreementIndexThreshold';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          effectiveAfterMonths?: number | null;
          minimumLimit?: number | null;
          shareInPercentToApply?: number | null;
          subsequentBilling: boolean;
          threshold: number;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          subsequentBillingObjektVorschreibungsposition?: {
            bezeichnung: string;
            objektVorschreibungspositionId: string;
            kurzBezeichnung: string;
          } | null;
          thresholdType: { text: string; value: Types.ThresholdType };
        }
      | {
          __typename: 'VertragVposIndexationAgreementReferenceValue';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UpdateVertragVposIndexationAgreementIndexAnnualMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  indexationAgreementId: Types.Scalars['ID']['input'];
  input: Types.VertragVposIndexationAgreementIndexAnnualUpdateInput;
}>;

export type UpdateVertragVposIndexationAgreementIndexAnnualMutation = {
  updateVertragVposIndexationAgreementIndexAnnual: {
    data:
      | {
          __typename: 'VertragVposIndexationAgreementCategory';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          basedOnUsableArea: boolean;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }
      | {
          __typename: 'VertragVposIndexationAgreementIndexAnnual';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          annualAverage: boolean;
          minimumLimit?: number | null;
          shareInPercentToApply?: number | null;
          subsequentBilling: boolean;
          threshold: number;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          effectiveWithMonth?: { text: string; value: Types.Month } | null;
          monthOfIndexPoint: { text: string; value: Types.Month };
          subsequentBillingObjektVorschreibungsposition?: {
            bezeichnung: string;
            objektVorschreibungspositionId: string;
            kurzBezeichnung: string;
          } | null;
          thresholdType: { text: string; value: Types.ThresholdType };
        }
      | {
          __typename: 'VertragVposIndexationAgreementIndexThreshold';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          effectiveAfterMonths?: number | null;
          minimumLimit?: number | null;
          shareInPercentToApply?: number | null;
          subsequentBilling: boolean;
          threshold: number;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          subsequentBillingObjektVorschreibungsposition?: {
            bezeichnung: string;
            objektVorschreibungspositionId: string;
            kurzBezeichnung: string;
          } | null;
          thresholdType: { text: string; value: Types.ThresholdType };
        }
      | {
          __typename: 'VertragVposIndexationAgreementReferenceValue';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UpdateVertragVposIndexationAgreementIndexThresholdMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  indexationAgreementId: Types.Scalars['ID']['input'];
  input: Types.VertragVposIndexationAgreementIndexThresholdUpdateInput;
}>;

export type UpdateVertragVposIndexationAgreementIndexThresholdMutation = {
  updateVertragVposIndexationAgreementIndexThreshold: {
    data:
      | {
          __typename: 'VertragVposIndexationAgreementCategory';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          basedOnUsableArea: boolean;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }
      | {
          __typename: 'VertragVposIndexationAgreementIndexAnnual';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          annualAverage: boolean;
          minimumLimit?: number | null;
          shareInPercentToApply?: number | null;
          subsequentBilling: boolean;
          threshold: number;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          effectiveWithMonth?: { text: string; value: Types.Month } | null;
          monthOfIndexPoint: { text: string; value: Types.Month };
          subsequentBillingObjektVorschreibungsposition?: {
            bezeichnung: string;
            objektVorschreibungspositionId: string;
            kurzBezeichnung: string;
          } | null;
          thresholdType: { text: string; value: Types.ThresholdType };
        }
      | {
          __typename: 'VertragVposIndexationAgreementIndexThreshold';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          effectiveAfterMonths?: number | null;
          minimumLimit?: number | null;
          shareInPercentToApply?: number | null;
          subsequentBilling: boolean;
          threshold: number;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          subsequentBillingObjektVorschreibungsposition?: {
            bezeichnung: string;
            objektVorschreibungspositionId: string;
            kurzBezeichnung: string;
          } | null;
          thresholdType: { text: string; value: Types.ThresholdType };
        }
      | {
          __typename: 'VertragVposIndexationAgreementReferenceValue';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UpdateVertragVposIndexationAgreementReferenceValueMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  indexationAgreementId: Types.Scalars['ID']['input'];
  input: Types.VertragVposIndexationAgreementReferenceValueUpdateInput;
}>;

export type UpdateVertragVposIndexationAgreementReferenceValueMutation = {
  updateVertragVposIndexationAgreementReferenceValue: {
    data:
      | {
          __typename: 'VertragVposIndexationAgreementCategory';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          basedOnUsableArea: boolean;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }
      | {
          __typename: 'VertragVposIndexationAgreementIndexAnnual';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          annualAverage: boolean;
          minimumLimit?: number | null;
          shareInPercentToApply?: number | null;
          subsequentBilling: boolean;
          threshold: number;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          effectiveWithMonth?: { text: string; value: Types.Month } | null;
          monthOfIndexPoint: { text: string; value: Types.Month };
          subsequentBillingObjektVorschreibungsposition?: {
            bezeichnung: string;
            objektVorschreibungspositionId: string;
            kurzBezeichnung: string;
          } | null;
          thresholdType: { text: string; value: Types.ThresholdType };
        }
      | {
          __typename: 'VertragVposIndexationAgreementIndexThreshold';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          effectiveAfterMonths?: number | null;
          minimumLimit?: number | null;
          shareInPercentToApply?: number | null;
          subsequentBilling: boolean;
          threshold: number;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          subsequentBillingObjektVorschreibungsposition?: {
            bezeichnung: string;
            objektVorschreibungspositionId: string;
            kurzBezeichnung: string;
          } | null;
          thresholdType: { text: string; value: Types.ThresholdType };
        }
      | {
          __typename: 'VertragVposIndexationAgreementReferenceValue';
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          indexOld?: number | null;
          indexOldPreliminary: boolean;
          monthYearOld: string;
          updatedByMitarbeiterId?: string | null;
          validFrom: string;
          vertragVposIndexationAgreementId: string;
          indexSeries: {
            chainingFactor?: number | null;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            csvColumnImportId?: string | null;
            csvColumnIndexPoint?: string | null;
            csvColumnMonthYear?: string | null;
            csvRowImportId?: string | null;
            deletable: boolean;
            firmendatenId?: string | null;
            importUrl?: string | null;
            indexSeriesId: string;
            name: string;
            updatedByMitarbeiterId?: string | null;
            indexSeriesEntryList: Array<{
              annualAverageFinal?: number | null;
              annualAveragePreliminary?: number | null;
              createTs: string;
              createdBy?: string | null;
              createdByMitarbeiterId?: string | null;
              indexSeriesEntryId: string;
              updatedByMitarbeiterId?: string | null;
              year: number;
              indexPointList: Array<{
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                finalValue?: number | null;
                indexPointId: string;
                month: string;
                preliminaryValue?: number | null;
                updatedByMitarbeiterId?: string | null;
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              }>;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            indexType: { text: string; value: Types.IndexType };
            sourceType: { text: string; value: Types.IndexSeriesSourceType };
            status?: { text: string; value: Types.IndexSeriesStatus } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          type: { text: string; value: Types.ValuePreservationType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type DeleteVertragVposIndexationAgreementMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
  vorschreibungspositionId: Types.Scalars['ID']['input'];
  indexationAgreementId: Types.Scalars['ID']['input'];
}>;

export type DeleteVertragVposIndexationAgreementMutation = {
  deleteVertragVposIndexationAgreement: {
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const CreateVertragVposIndexationAgreementCategoryDocument = gql`
  mutation CreateVertragVposIndexationAgreementCategory(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vorschreibungspositionId: ID!
    $input: VertragVposIndexationAgreementCategoryCreateInput!
  ) {
    createVertragVposIndexationAgreementCategory(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      input: $input
    ) {
      data {
        ...VertragVposIndexationAgreementFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${VertragVposIndexationAgreementFieldsFragmentDoc}
`;
export function useCreateVertragVposIndexationAgreementCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVertragVposIndexationAgreementCategoryMutation,
    CreateVertragVposIndexationAgreementCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVertragVposIndexationAgreementCategoryMutation, CreateVertragVposIndexationAgreementCategoryMutationVariables>(
    CreateVertragVposIndexationAgreementCategoryDocument,
    options
  );
}
export type CreateVertragVposIndexationAgreementCategoryMutationHookResult = ReturnType<
  typeof useCreateVertragVposIndexationAgreementCategoryMutation
>;
export type CreateVertragVposIndexationAgreementCategoryMutationResult = Apollo.MutationResult<CreateVertragVposIndexationAgreementCategoryMutation>;
export type CreateVertragVposIndexationAgreementCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateVertragVposIndexationAgreementCategoryMutation,
  CreateVertragVposIndexationAgreementCategoryMutationVariables
>;
export const CreateVertragVposIndexationAgreementIndexAnnualDocument = gql`
  mutation CreateVertragVposIndexationAgreementIndexAnnual(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vorschreibungspositionId: ID!
    $input: VertragVposIndexationAgreementIndexAnnualCreateInput!
  ) {
    createVertragVposIndexationAgreementIndexAnnual(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      input: $input
    ) {
      data {
        ...VertragVposIndexationAgreementFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${VertragVposIndexationAgreementFieldsFragmentDoc}
`;
export function useCreateVertragVposIndexationAgreementIndexAnnualMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVertragVposIndexationAgreementIndexAnnualMutation,
    CreateVertragVposIndexationAgreementIndexAnnualMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateVertragVposIndexationAgreementIndexAnnualMutation,
    CreateVertragVposIndexationAgreementIndexAnnualMutationVariables
  >(CreateVertragVposIndexationAgreementIndexAnnualDocument, options);
}
export type CreateVertragVposIndexationAgreementIndexAnnualMutationHookResult = ReturnType<
  typeof useCreateVertragVposIndexationAgreementIndexAnnualMutation
>;
export type CreateVertragVposIndexationAgreementIndexAnnualMutationResult =
  Apollo.MutationResult<CreateVertragVposIndexationAgreementIndexAnnualMutation>;
export type CreateVertragVposIndexationAgreementIndexAnnualMutationOptions = Apollo.BaseMutationOptions<
  CreateVertragVposIndexationAgreementIndexAnnualMutation,
  CreateVertragVposIndexationAgreementIndexAnnualMutationVariables
>;
export const CreateVertragVposIndexationAgreementIndexThresholdDocument = gql`
  mutation CreateVertragVposIndexationAgreementIndexThreshold(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vorschreibungspositionId: ID!
    $input: VertragVposIndexationAgreementIndexThresholdCreateInput!
  ) {
    createVertragVposIndexationAgreementIndexThreshold(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      input: $input
    ) {
      data {
        ...VertragVposIndexationAgreementFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${VertragVposIndexationAgreementFieldsFragmentDoc}
`;
export function useCreateVertragVposIndexationAgreementIndexThresholdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVertragVposIndexationAgreementIndexThresholdMutation,
    CreateVertragVposIndexationAgreementIndexThresholdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateVertragVposIndexationAgreementIndexThresholdMutation,
    CreateVertragVposIndexationAgreementIndexThresholdMutationVariables
  >(CreateVertragVposIndexationAgreementIndexThresholdDocument, options);
}
export type CreateVertragVposIndexationAgreementIndexThresholdMutationHookResult = ReturnType<
  typeof useCreateVertragVposIndexationAgreementIndexThresholdMutation
>;
export type CreateVertragVposIndexationAgreementIndexThresholdMutationResult =
  Apollo.MutationResult<CreateVertragVposIndexationAgreementIndexThresholdMutation>;
export type CreateVertragVposIndexationAgreementIndexThresholdMutationOptions = Apollo.BaseMutationOptions<
  CreateVertragVposIndexationAgreementIndexThresholdMutation,
  CreateVertragVposIndexationAgreementIndexThresholdMutationVariables
>;
export const CreateVertragVposIndexationAgreementReferenceValueDocument = gql`
  mutation CreateVertragVposIndexationAgreementReferenceValue(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vorschreibungspositionId: ID!
    $input: VertragVposIndexationAgreementReferenceValueCreateInput!
  ) {
    createVertragVposIndexationAgreementReferenceValue(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      input: $input
    ) {
      data {
        ...VertragVposIndexationAgreementFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${VertragVposIndexationAgreementFieldsFragmentDoc}
`;
export function useCreateVertragVposIndexationAgreementReferenceValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVertragVposIndexationAgreementReferenceValueMutation,
    CreateVertragVposIndexationAgreementReferenceValueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateVertragVposIndexationAgreementReferenceValueMutation,
    CreateVertragVposIndexationAgreementReferenceValueMutationVariables
  >(CreateVertragVposIndexationAgreementReferenceValueDocument, options);
}
export type CreateVertragVposIndexationAgreementReferenceValueMutationHookResult = ReturnType<
  typeof useCreateVertragVposIndexationAgreementReferenceValueMutation
>;
export type CreateVertragVposIndexationAgreementReferenceValueMutationResult =
  Apollo.MutationResult<CreateVertragVposIndexationAgreementReferenceValueMutation>;
export type CreateVertragVposIndexationAgreementReferenceValueMutationOptions = Apollo.BaseMutationOptions<
  CreateVertragVposIndexationAgreementReferenceValueMutation,
  CreateVertragVposIndexationAgreementReferenceValueMutationVariables
>;
export const UpdateVertragVposIndexationAgreementCategoryDocument = gql`
  mutation UpdateVertragVposIndexationAgreementCategory(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vorschreibungspositionId: ID!
    $indexationAgreementId: ID!
    $input: VertragVposIndexationAgreementCategoryUpdateInput!
  ) {
    updateVertragVposIndexationAgreementCategory(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      indexationAgreementId: $indexationAgreementId
      input: $input
    ) {
      data {
        ...VertragVposIndexationAgreementFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${VertragVposIndexationAgreementFieldsFragmentDoc}
`;
export function useUpdateVertragVposIndexationAgreementCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVertragVposIndexationAgreementCategoryMutation,
    UpdateVertragVposIndexationAgreementCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateVertragVposIndexationAgreementCategoryMutation, UpdateVertragVposIndexationAgreementCategoryMutationVariables>(
    UpdateVertragVposIndexationAgreementCategoryDocument,
    options
  );
}
export type UpdateVertragVposIndexationAgreementCategoryMutationHookResult = ReturnType<
  typeof useUpdateVertragVposIndexationAgreementCategoryMutation
>;
export type UpdateVertragVposIndexationAgreementCategoryMutationResult = Apollo.MutationResult<UpdateVertragVposIndexationAgreementCategoryMutation>;
export type UpdateVertragVposIndexationAgreementCategoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateVertragVposIndexationAgreementCategoryMutation,
  UpdateVertragVposIndexationAgreementCategoryMutationVariables
>;
export const UpdateVertragVposIndexationAgreementIndexAnnualDocument = gql`
  mutation UpdateVertragVposIndexationAgreementIndexAnnual(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vorschreibungspositionId: ID!
    $indexationAgreementId: ID!
    $input: VertragVposIndexationAgreementIndexAnnualUpdateInput!
  ) {
    updateVertragVposIndexationAgreementIndexAnnual(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      indexationAgreementId: $indexationAgreementId
      input: $input
    ) {
      data {
        ...VertragVposIndexationAgreementFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${VertragVposIndexationAgreementFieldsFragmentDoc}
`;
export function useUpdateVertragVposIndexationAgreementIndexAnnualMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVertragVposIndexationAgreementIndexAnnualMutation,
    UpdateVertragVposIndexationAgreementIndexAnnualMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVertragVposIndexationAgreementIndexAnnualMutation,
    UpdateVertragVposIndexationAgreementIndexAnnualMutationVariables
  >(UpdateVertragVposIndexationAgreementIndexAnnualDocument, options);
}
export type UpdateVertragVposIndexationAgreementIndexAnnualMutationHookResult = ReturnType<
  typeof useUpdateVertragVposIndexationAgreementIndexAnnualMutation
>;
export type UpdateVertragVposIndexationAgreementIndexAnnualMutationResult =
  Apollo.MutationResult<UpdateVertragVposIndexationAgreementIndexAnnualMutation>;
export type UpdateVertragVposIndexationAgreementIndexAnnualMutationOptions = Apollo.BaseMutationOptions<
  UpdateVertragVposIndexationAgreementIndexAnnualMutation,
  UpdateVertragVposIndexationAgreementIndexAnnualMutationVariables
>;
export const UpdateVertragVposIndexationAgreementIndexThresholdDocument = gql`
  mutation UpdateVertragVposIndexationAgreementIndexThreshold(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vorschreibungspositionId: ID!
    $indexationAgreementId: ID!
    $input: VertragVposIndexationAgreementIndexThresholdUpdateInput!
  ) {
    updateVertragVposIndexationAgreementIndexThreshold(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      indexationAgreementId: $indexationAgreementId
      input: $input
    ) {
      data {
        ...VertragVposIndexationAgreementFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${VertragVposIndexationAgreementFieldsFragmentDoc}
`;
export function useUpdateVertragVposIndexationAgreementIndexThresholdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVertragVposIndexationAgreementIndexThresholdMutation,
    UpdateVertragVposIndexationAgreementIndexThresholdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVertragVposIndexationAgreementIndexThresholdMutation,
    UpdateVertragVposIndexationAgreementIndexThresholdMutationVariables
  >(UpdateVertragVposIndexationAgreementIndexThresholdDocument, options);
}
export type UpdateVertragVposIndexationAgreementIndexThresholdMutationHookResult = ReturnType<
  typeof useUpdateVertragVposIndexationAgreementIndexThresholdMutation
>;
export type UpdateVertragVposIndexationAgreementIndexThresholdMutationResult =
  Apollo.MutationResult<UpdateVertragVposIndexationAgreementIndexThresholdMutation>;
export type UpdateVertragVposIndexationAgreementIndexThresholdMutationOptions = Apollo.BaseMutationOptions<
  UpdateVertragVposIndexationAgreementIndexThresholdMutation,
  UpdateVertragVposIndexationAgreementIndexThresholdMutationVariables
>;
export const UpdateVertragVposIndexationAgreementReferenceValueDocument = gql`
  mutation UpdateVertragVposIndexationAgreementReferenceValue(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vorschreibungspositionId: ID!
    $indexationAgreementId: ID!
    $input: VertragVposIndexationAgreementReferenceValueUpdateInput!
  ) {
    updateVertragVposIndexationAgreementReferenceValue(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      indexationAgreementId: $indexationAgreementId
      input: $input
    ) {
      data {
        ...VertragVposIndexationAgreementFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${VertragVposIndexationAgreementFieldsFragmentDoc}
`;
export function useUpdateVertragVposIndexationAgreementReferenceValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVertragVposIndexationAgreementReferenceValueMutation,
    UpdateVertragVposIndexationAgreementReferenceValueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVertragVposIndexationAgreementReferenceValueMutation,
    UpdateVertragVposIndexationAgreementReferenceValueMutationVariables
  >(UpdateVertragVposIndexationAgreementReferenceValueDocument, options);
}
export type UpdateVertragVposIndexationAgreementReferenceValueMutationHookResult = ReturnType<
  typeof useUpdateVertragVposIndexationAgreementReferenceValueMutation
>;
export type UpdateVertragVposIndexationAgreementReferenceValueMutationResult =
  Apollo.MutationResult<UpdateVertragVposIndexationAgreementReferenceValueMutation>;
export type UpdateVertragVposIndexationAgreementReferenceValueMutationOptions = Apollo.BaseMutationOptions<
  UpdateVertragVposIndexationAgreementReferenceValueMutation,
  UpdateVertragVposIndexationAgreementReferenceValueMutationVariables
>;
export const DeleteVertragVposIndexationAgreementDocument = gql`
  mutation DeleteVertragVposIndexationAgreement(
    $objektId: ID!
    $bestandseinheitId: ID!
    $vertragId: ID!
    $vorschreibungspositionId: ID!
    $indexationAgreementId: ID!
  ) {
    deleteVertragVposIndexationAgreement(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      vertragId: $vertragId
      vorschreibungspositionId: $vorschreibungspositionId
      indexationAgreementId: $indexationAgreementId
    ) {
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeleteVertragVposIndexationAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteVertragVposIndexationAgreementMutation, DeleteVertragVposIndexationAgreementMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVertragVposIndexationAgreementMutation, DeleteVertragVposIndexationAgreementMutationVariables>(
    DeleteVertragVposIndexationAgreementDocument,
    options
  );
}
export type DeleteVertragVposIndexationAgreementMutationHookResult = ReturnType<typeof useDeleteVertragVposIndexationAgreementMutation>;
export type DeleteVertragVposIndexationAgreementMutationResult = Apollo.MutationResult<DeleteVertragVposIndexationAgreementMutation>;
export type DeleteVertragVposIndexationAgreementMutationOptions = Apollo.BaseMutationOptions<
  DeleteVertragVposIndexationAgreementMutation,
  DeleteVertragVposIndexationAgreementMutationVariables
>;
