type WidthAndTextAlignment = {
  width: string;
  textAlign: 'center' | 'left' | 'right' | 'justify' | undefined;
};

export type ValuePreservationInfoColumnsStyle = {
  bestandseinheit: WidthAndTextAlignment;
  position: WidthAndTextAlignment;
  valueOld: WidthAndTextAlignment;
  valueNew: WidthAndTextAlignment;
  valueFactor: WidthAndTextAlignment;
  amountOld: WidthAndTextAlignment;
  amountNew: WidthAndTextAlignment;
  amountFactor: WidthAndTextAlignment;
  amountDifference: WidthAndTextAlignment;
};

export const valuePreservationInfoColumnWidthsAndAlignment: ValuePreservationInfoColumnsStyle = {
  bestandseinheit: { width: '10%', textAlign: 'left' },
  position: { width: '15%', textAlign: 'left' },
  valueOld: { width: '10%', textAlign: 'right' },
  valueNew: { width: '10%', textAlign: 'right' },
  valueFactor: { width: '10%', textAlign: 'right' },
  amountOld: { width: '10%', textAlign: 'right' },
  amountNew: { width: '10%', textAlign: 'right' },
  amountFactor: { width: '10%', textAlign: 'right' },
  amountDifference: { width: '15%', textAlign: 'right' },
};
