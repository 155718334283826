import React, { FC, useState } from 'react';
import { Flex } from 'rebass';
import { useNavigate } from 'react-router-dom';

import { Button, Divider, Space, Tabs, TabsProps, Typography } from 'antd';
import { URI_SYS_SETTINGS } from '../../../../constants/configurationUriPaths';
import AbrDefVersionViewAbrKreisList from './AbrDefVersionViewAbrKreisList';
import { Spacer } from '../../../../components/Grid';
import { generatePathToAbrechnungsdefinitionVersionUpdatePage } from '../../abrDefUriPaths';
import { mapTextbausteinListToFormValues, TextbausteinFormValues } from '../../../../components/Template/PDFTemplates/templateMapper';
import { BelegTextePlatzhalter } from '../../../Belegtext/Form/belegTexteFormTypes';
import {
  createHeAbrechnungPDFTemplateData,
  mapTextbausteinToHeAbrechnungSelectableSection,
} from '../../shared/Templates/HeTemplates/heAbrDefTemplateFormMapper';
import HauseigentuemerAbrechnungTemplate from '../../../../pages/PDFTemplates/templates/hauseigentuemerAbrechnung/HauseigentuemerAbrechnungTemplate';
import { AbrechnungsdefinitionType, BelegTexte } from '../../../../types';
import AbrDefKontenzuordnung from '../../shared/AbrDefKontenzuordnung';
import HeAbrDefVersionViewTabWithTemplate from '../../shared/Templates/HeTemplates/HeAbrDefVersionViewTabWithTemplate';
import { HeAbrechnungsdefinitionVersionFieldsFragment } from '../../gql/HeAbrDef/HeAbrDefVersionFragments.types';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersion: HeAbrechnungsdefinitionVersionFieldsFragment;
  firmendatenId: string;
  isHistoricized: boolean;
  belegTexte: BelegTexte;
  belegTextePlatzhalter: BelegTextePlatzhalter;
};

const HeAbrDefVersionView: FC<Props> = ({
  abrechnungsdefinitionId,
  abrechnungsdefinitionVersion,
  isHistoricized,
  belegTexte,
  belegTextePlatzhalter,
  firmendatenId,
}) => {
  const navigate = useNavigate();
  const [heAbrDefTemplateValues] = useState<TextbausteinFormValues[]>(
    mapTextbausteinListToFormValues(abrechnungsdefinitionVersion.heAbrechnungTextbausteinList)
  );

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Abrechnungskreise',
      children: (
        <>
          <Spacer height={16} />
          <AbrDefVersionViewAbrKreisList
            abrechdefAbrechkreisList={abrechnungsdefinitionVersion.abrechdefAbrechkreisList}
            abrDefType={AbrechnungsdefinitionType.HeAbrechnung}
          />
        </>
      ),
    },
    {
      key: '2',
      label: 'Hauseigentümerabrechnung',
      children: (
        <HeAbrDefVersionViewTabWithTemplate
          outputOptions={abrechnungsdefinitionVersion.outputOptions}
          heAbrDefTemplateValues={heAbrDefTemplateValues}
          renderTemplatePreview={({ selectedTextbaustein }) => (
            <HauseigentuemerAbrechnungTemplate
              data={createHeAbrechnungPDFTemplateData(
                heAbrDefTemplateValues,
                abrechnungsdefinitionVersion.outputOptions,
                belegTexte,
                belegTextePlatzhalter,
                firmendatenId
              )}
              selectedTextbaustein={mapTextbausteinToHeAbrechnungSelectableSection(selectedTextbaustein)}
            />
          )}
        />
      ),
    },
    {
      key: '4',
      label: 'Kontenzuordnung',
      children: (
        <AbrDefKontenzuordnung
          abrechnungsdefinitionVersion={abrechnungsdefinitionVersion}
          abrechnungsdefinitionId={abrechnungsdefinitionId}
          abrDefinitionType={AbrechnungsdefinitionType.HeAbrechnung}
          mode="onlyView"
        />
      ),
    },
  ];

  return (
    <>
      <Flex>
        <Space>
          <Typography.Text strong>Gültig ab</Typography.Text>
          <Typography.Text>
            <CustomFormattedDate value={abrechnungsdefinitionVersion.validFrom} />
          </Typography.Text>
        </Space>
        <Space style={{ marginLeft: '10px' }}>
          <Typography.Text strong>Kommentar</Typography.Text>
          <Typography.Text>{abrechnungsdefinitionVersion.kommentar}</Typography.Text>
        </Space>
      </Flex>
      <Divider />
      <Tabs items={items} />
      <Spacer height={16} />
      <Space style={{ width: '100%', justifyContent: 'end' }}>
        <Button onClick={() => navigate(URI_SYS_SETTINGS.abrechnungsdefinitionPage)}>Schließen</Button>
        <Button
          type="primary"
          disabled={isHistoricized}
          onClick={() =>
            navigate(
              generatePathToAbrechnungsdefinitionVersionUpdatePage(
                abrechnungsdefinitionId,
                abrechnungsdefinitionVersion.abrechnungsdefinitionVersionId
              )
            )
          }
        >
          Bearbeiten
        </Button>
      </Space>
    </>
  );
};

export default HeAbrDefVersionView;
