import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Col, Row, Space, Typography } from 'antd';
import FormButtons from '../../../../../../components/Button/FormButtons';
import { showSuccessMsgCreate } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import {
  useCreateFirmaContactMutation,
  useCreateFirmendatenContactMutation,
  useCreateNatuerlichePersonContactMutation,
  useCreatePersonenGemeinschaftContactMutation,
} from '../../../gql/AddressesAndContactsMutations.types';
import { emailContactCreateFormFields, emailContactCreateFormInitialValues, EmailContactCreateFormValues } from './emailContactCreateFormMapper';
import { RechtstraegerType } from '../../../../../../types';
import { emailContactValidationSchema } from './emailContactValidationSchema';
import Errors from '../../../../../shared/Errors';
import ContactFormPart from '../../../../../../shared/components/Contact/ContactFormPart';
import { ContactType } from '../../../../../../shared/components/Contact/contactHelper';
import { useGetAppFieldHelpText } from '../../../../../FieldHelp/useGetFieldHelpText';
import FieldHelpIconWithTooltip from '../../../../../../components/FieldHelpIcon/FieldHelpIconWithTooltip';

type Props = {
  onSuccess: (addressIdList: string[]) => void;
  onCancel: () => void;
  rechtstraegerType: RechtstraegerType;
  rechtstraegerId?: string;
  operatingSiteId?: string;
  allowCreateMultipleEmailContact?: boolean;
};

const EmailContactCreateForm: FC<Props> = ({
  rechtstraegerId,
  onSuccess,
  onCancel,
  rechtstraegerType,
  operatingSiteId,
  allowCreateMultipleEmailContact,
}) => {
  const getFieldHelpText = useGetAppFieldHelpText<'ContactEmail'>('ContactEmail');

  const entity = 'Kontakt';

  const [createNatuerlichePersonContact, { loading: loadingNatuerlichePersonContact }] = useCreateNatuerlichePersonContactMutation({
    onCompleted: (data) => {
      showSuccessMsgCreate(entity);
      onSuccess(data.createNatuerlichePersonContact.data.map((contact) => contact.contactId));
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [createPersonenGemeinschaftContact, { loading: loadingPersonenGemeinschaftContact }] = useCreatePersonenGemeinschaftContactMutation({
    onCompleted: (data) => {
      showSuccessMsgCreate(entity);
      onSuccess(data.createPersonenGemeinschaftContact.data.map((contact) => contact.contactId));
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [createFirmaContact, { loading: loadingFirmaContact }] = useCreateFirmaContactMutation({
    onCompleted: (data) => {
      showSuccessMsgCreate(entity);
      onSuccess(data.createFirmaContact.data.map((contact) => contact.contactId));
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [createFirmendatenContact, { loading: loadingFirmendatenContact }] = useCreateFirmendatenContactMutation({
    onCompleted: (data) => {
      showSuccessMsgCreate(entity);
      onSuccess(data.createFirmendatenContact.data.map((contact) => contact.contactId));
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<EmailContactCreateFormValues>
      initialValues={emailContactCreateFormInitialValues}
      validationSchema={emailContactValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (rechtstraegerId) {
          if (rechtstraegerType === RechtstraegerType.PersonenGemeinschaft) {
            createPersonenGemeinschaftContact({
              variables: { personenGemeinschaftId: rechtstraegerId, input: values },
            }).finally(() => setSubmitting(false));
          } else if (rechtstraegerType === RechtstraegerType.NatuerlichePerson) {
            createNatuerlichePersonContact({
              variables: { natuerlichePersonId: rechtstraegerId, input: values },
            }).finally(() => setSubmitting(false));
          } else if (rechtstraegerType === RechtstraegerType.Firma) {
            createFirmaContact({
              variables: { firmaId: rechtstraegerId, input: { ...values, operatingSiteId } },
            }).finally(() => setSubmitting(false));
          }
        } else {
          createFirmendatenContact({
            variables: { input: { ...values, operatingSiteId } },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>
                E-Mail-Adresse <FieldHelpIconWithTooltip fieldHelp={getFieldHelpText('ContactEmail.wert')} />
              </Typography.Title>
            </Col>
            <Col span={18}>
              <Space direction="vertical">
                <ContactFormPart
                  name={emailContactCreateFormFields.wertList}
                  type={ContactType.EMAIL}
                  wertList={formikProps.values.wertList}
                  onRemove={(name) => formikProps.setFieldValue(name, '')}
                  allowCreateMultipleContact={allowCreateMultipleEmailContact}
                  errors={formikProps.errors.wertList as string[]}
                />
                <Errors errors={formikProps.errors.wertList as string[]} values={[]} />
              </Space>
            </Col>
          </Row>
          <FormButtons
            updateMode={false}
            onCancel={onCancel}
            isOkLoading={loadingNatuerlichePersonContact || loadingPersonenGemeinschaftContact || loadingFirmaContact || loadingFirmendatenContact}
            isOkDisabled={formikProps.isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EmailContactCreateForm;
