import React, { FC, useEffect } from 'react';
import { FormikProps, useField } from 'formik';
import { Space } from 'antd';
import { useFileInfoForFirmendatenQuery } from '../../../../../Document/gql/FileStorageQueries.types';
import { ReportLogosFormValues } from './reportLogosFormMapper';
import FileUploadView from '../../../../../../components/FileUpload/FileUploadView';
import FileUploadArea, { ValidateFileProps } from '../../../../../../components/FileUpload/FileUploadArea';
import { loadPreviewForMitarbeiter, LoadPreviewForMitarbeiterResponse } from '../../../../../Document/useFileDownloadAsyncForDocument';
import usePermission from '../../../../../../shared/Auth/usePermission';
import { useAuth } from '../../../../../../shared/Auth/authContext';
import { FormFileReference } from '../../../../../../components/FileUpload/formHelpers';
import { DocumentHeaderAndFooterImageAlignment, FileInfo } from '../../../../../../types';
import ReportLogosImageAlignment from './ReportLogosImageAlignment';

type ReportLogosFormUploadProps = {
  firmendatenId: string;
  formikProps: FormikProps<ReportLogosFormValues>;
  name: string; // formik: name of the file which gets uploaded
  fileId?: string | null; // formik: fileId of already saved file coming from backend
  nameForTempBildFileId: string; // formik: name of the fileId of mitarbeiter file after selected from modal
  validateFile: (file: ValidateFileProps) => boolean;
  disabled?: boolean;
  fieldHelpForAlignment?: string | null;
};

const ReportLogosFormUpload: FC<ReportLogosFormUploadProps> = ({
  formikProps,
  firmendatenId,
  name,
  nameForTempBildFileId,
  fileId,
  disabled,
  validateFile,
  fieldHelpForAlignment,
}) => {
  const { isAdmin } = usePermission();
  const { mitarbeiter } = useAuth();
  const [{ value: file }] = useField<FormFileReference | undefined>(name);
  const [{ value: tempFileId }] = useField<string | undefined>(nameForTempBildFileId);

  const { data } = useFileInfoForFirmendatenQuery({
    variables: {
      firmendatenId,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      fileId: fileId!,
    },
    skip: !fileId,
  });
  const fileFromFileStorage = data?.getFileInfoForFirmendaten.data;

  const onDelete = () => {
    formikProps.setFieldValue(name, undefined);
    formikProps.setFieldValue(nameForTempBildFileId, undefined);
    formikProps.setFieldValue(`${name}FileId`, undefined);
    formikProps.setFieldValue(`${name}Alignment`, undefined);
  };

  const onSelectFile = (data: FileInfo) => {
    formikProps.setFieldValue(nameForTempBildFileId, data.fileId);
    formikProps.setFieldValue(`${name}Alignment`, DocumentHeaderAndFooterImageAlignment.Left);
  };

  useEffect(() => {
    if (tempFileId && !isAdmin) {
      loadPreviewForMitarbeiter({ mitarbeiterId: mitarbeiter?.mitarbeiterId, fileId: tempFileId }).then((maybePreview) => {
        const preview = maybePreview as LoadPreviewForMitarbeiterResponse;
        const headerContentDisposition = preview.headers.get('Content-Disposition');
        const regexPattern = /filename="([^"]+)"/;
        const matchFilename = headerContentDisposition?.match(regexPattern);

        const filename = matchFilename?.[1] ?? 'filename';
        if (validateFile({ name: filename, size: preview.blob.size, type: preview.blob.type })) {
          formikProps.setFieldValue(name, { filename, file: preview.blob, isUpload: true });
        } else {
          formikProps.setFieldValue(nameForTempBildFileId, undefined, false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempFileId]);

  let fileView;
  if ((file && file?.filename) || (fileFromFileStorage && fileFromFileStorage?.name)) {
    const filename = file?.filename || fileFromFileStorage?.name;
    fileView = (
      <Space direction="vertical">
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        <FileUploadView filename={filename!} onDelete={onDelete} />
        <ReportLogosImageAlignment name={name} fieldHelp={fieldHelpForAlignment} />
      </Space>
    );
  }

  return fileView ? (
    <>{fileView}</>
  ) : (
    <FileUploadArea<ReportLogosFormValues>
      formikProps={formikProps}
      name={name}
      disabled={disabled}
      validateFile={(file) => validateFile(file)}
      onSelectFileSetFieldValue={onSelectFile}
      onBeforeUpload={() => formikProps.setFieldValue(`${name}Alignment`, DocumentHeaderAndFooterImageAlignment.Left)}
    />
  );
};

export default ReportLogosFormUpload;
