import { documentListingFiltersFormFields } from './documentListingFiltersFormMapper';

export const documentLabelList = {
  [documentListingFiltersFormFields.documentType]: 'Dokumenttyp',
  [documentListingFiltersFormFields.invoiceIssuerId]: 'Rechnungsaussteller',
  [documentListingFiltersFormFields.invoiceRecipientId]: 'Rechnungsemfänger',
  [documentListingFiltersFormFields.invoiceNumber]: 'Belegnummer',
  [documentListingFiltersFormFields.invoiceDateFromInclusive]: 'Rechnungsdatum',
  [documentListingFiltersFormFields.dunningReferenceDateFromInclusive]: 'Mahnstichtag',
  [documentListingFiltersFormFields.homeownerId]: 'Hauseigentümer',
  [documentListingFiltersFormFields.objektId]: 'Objekt',
  [documentListingFiltersFormFields.bestandseinheitId]: 'Bestandseinheit',
  [documentListingFiltersFormFields.budgetingYear]: 'Budgetierungsjahr',
};

export const documentListingLabelList = (disabledFilters?: string[]) => {
  if (!disabledFilters) {
    return documentLabelList;
  }

  return Object.keys(documentLabelList)
    .filter((key) => !disabledFilters.includes(key))
    .reduce(
      (obj, key) => {
        // @ts-ignore
        obj[key] = documentLabelList[key];
        return obj;
      },
      {} as Record<string, string>
    );
};

type DocumentFiltersSettings = {
  default: string[];
  disabled: string[];
};

type DocumentFiltersSettingsMap = {
  objekt: DocumentFiltersSettings;
  bestandseinheit: DocumentFiltersSettings;
  objAbrechnung: DocumentFiltersSettings;
  heAbrechnung: DocumentFiltersSettings;
  budgeting: DocumentFiltersSettings;
  vertrag: DocumentFiltersSettings;
  heVertrag: DocumentFiltersSettings;
  rechtstraeger: DocumentFiltersSettings;
  mahnung: DocumentFiltersSettings;
  emailDelivery: DocumentFiltersSettings;
  vorschreibung: DocumentFiltersSettings;
  topAbrechnung: DocumentFiltersSettings;
  subAbrechnung: DocumentFiltersSettings;
};

export const DOCUMENT_FILTERS_SETTINGS: DocumentFiltersSettingsMap = {
  objekt: {
    default: [
      documentListingFiltersFormFields.documentType,
      documentListingFiltersFormFields.invoiceRecipientId,
      documentListingFiltersFormFields.invoiceNumber,
      documentListingFiltersFormFields.bestandseinheitId,
    ],
    disabled: [
      documentListingFiltersFormFields.invoiceIssuerId,
      documentListingFiltersFormFields.objektId,
      documentListingFiltersFormFields.homeownerId,
      documentListingFiltersFormFields.budgetingYear,
    ],
  },
  bestandseinheit: {
    default: [
      documentListingFiltersFormFields.documentType,
      documentListingFiltersFormFields.invoiceRecipientId,
      documentListingFiltersFormFields.invoiceNumber,
      documentListingFiltersFormFields.invoiceDateFromInclusive,
    ],
    disabled: [
      documentListingFiltersFormFields.invoiceIssuerId,
      documentListingFiltersFormFields.objektId,
      documentListingFiltersFormFields.bestandseinheitId,
      documentListingFiltersFormFields.homeownerId,
      documentListingFiltersFormFields.budgetingYear,
    ],
  },
  objAbrechnung: {
    default: [documentListingFiltersFormFields.invoiceRecipientId, documentListingFiltersFormFields.bestandseinheitId],
    disabled: [
      documentListingFiltersFormFields.documentType,
      documentListingFiltersFormFields.invoiceIssuerId,
      documentListingFiltersFormFields.objektId,
      documentListingFiltersFormFields.invoiceNumber,
      documentListingFiltersFormFields.homeownerId,
      documentListingFiltersFormFields.budgetingYear,
      documentListingFiltersFormFields.invoiceDateFromInclusive,
      documentListingFiltersFormFields.dunningReferenceDateFromInclusive,
    ],
  },
  heAbrechnung: {
    default: [],
    disabled: [
      documentListingFiltersFormFields.documentType,
      documentListingFiltersFormFields.invoiceIssuerId,
      documentListingFiltersFormFields.invoiceRecipientId,
      documentListingFiltersFormFields.objektId,
      documentListingFiltersFormFields.bestandseinheitId,
      documentListingFiltersFormFields.invoiceNumber,
      documentListingFiltersFormFields.budgetingYear,
      documentListingFiltersFormFields.invoiceDateFromInclusive,
      documentListingFiltersFormFields.dunningReferenceDateFromInclusive,
    ],
  },
  budgeting: {
    default: [documentListingFiltersFormFields.documentType, documentListingFiltersFormFields.bestandseinheitId],
    disabled: [
      documentListingFiltersFormFields.invoiceIssuerId,
      documentListingFiltersFormFields.invoiceRecipientId,
      documentListingFiltersFormFields.objektId,
      documentListingFiltersFormFields.invoiceNumber,
      documentListingFiltersFormFields.homeownerId,
      documentListingFiltersFormFields.budgetingYear,
      documentListingFiltersFormFields.invoiceDateFromInclusive,
      documentListingFiltersFormFields.dunningReferenceDateFromInclusive,
    ],
  },
  vertrag: {
    default: [documentListingFiltersFormFields.documentType, documentListingFiltersFormFields.invoiceNumber],
    disabled: [
      documentListingFiltersFormFields.invoiceIssuerId,
      documentListingFiltersFormFields.invoiceRecipientId,
      documentListingFiltersFormFields.objektId,
      documentListingFiltersFormFields.bestandseinheitId,
      documentListingFiltersFormFields.homeownerId,
      documentListingFiltersFormFields.budgetingYear,
    ],
  },
  heVertrag: {
    default: [documentListingFiltersFormFields.documentType, documentListingFiltersFormFields.invoiceNumber],
    disabled: [
      documentListingFiltersFormFields.invoiceIssuerId,
      documentListingFiltersFormFields.invoiceRecipientId,
      documentListingFiltersFormFields.objektId,
      documentListingFiltersFormFields.bestandseinheitId,
      documentListingFiltersFormFields.invoiceNumber,
      documentListingFiltersFormFields.homeownerId,
      documentListingFiltersFormFields.budgetingYear,
      documentListingFiltersFormFields.invoiceDateFromInclusive,
      documentListingFiltersFormFields.dunningReferenceDateFromInclusive,
    ],
  },
  // INFO: Everything is allowed no need to pass DocumentTab
  rechtstraeger: {
    default: [
      documentListingFiltersFormFields.documentType,
      documentListingFiltersFormFields.invoiceIssuerId,
      documentListingFiltersFormFields.invoiceRecipientId,
      documentListingFiltersFormFields.invoiceNumber,
    ],
    disabled: [],
  },
  // hideFilters attribute is used to hide all filters
  mahnung: { default: [], disabled: [] },
  emailDelivery: { default: [], disabled: [] },
  vorschreibung: { default: [], disabled: [] },
  topAbrechnung: { default: [], disabled: [] },
  subAbrechnung: {
    default: [documentListingFiltersFormFields.documentType],
    disabled: [
      documentListingFiltersFormFields.invoiceNumber,
      documentListingFiltersFormFields.invoiceIssuerId,
      documentListingFiltersFormFields.invoiceRecipientId,
      documentListingFiltersFormFields.objektId,
      documentListingFiltersFormFields.bestandseinheitId,
      documentListingFiltersFormFields.invoiceNumber,
      documentListingFiltersFormFields.homeownerId,
      documentListingFiltersFormFields.budgetingYear,
      documentListingFiltersFormFields.invoiceDateFromInclusive,
      documentListingFiltersFormFields.dunningReferenceDateFromInclusive,
    ],
  },
};
