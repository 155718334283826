import React from 'react';
import KontierungstabelleTemplateTable from './Table/Level1/KontierungstabelleTemplateTable';
import { useShowArchivedQueryParamState } from '../../hooks/useShowArchivedQueryParamState';
import { useKontierungstabelleTemplateListQuery } from './gql/KontierungstabelleTemplateQueries.types';
import KontierungstabelleTemplateBtnAndDrawer from './KontierungstabelleTemplateBtnAndDrawer';

const KontierungstabelleTemplateListing = () => {
  const [showArchived, toggleShowArchived] = useShowArchivedQueryParamState();

  const { data, refetch } = useKontierungstabelleTemplateListQuery({
    variables: { includeArchiviert: showArchived },
  });
  const kontierungstabelleList = data?.getKontierungstabelleTemplateList.data;

  return (
    <>
      <KontierungstabelleTemplateBtnAndDrawer refetch={refetch} showArchived={showArchived} toggleShowArchived={toggleShowArchived} />
      <KontierungstabelleTemplateTable kontierungstabelleList={kontierungstabelleList} refetch={refetch} />
    </>
  );
};

export default KontierungstabelleTemplateListing;
