import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { AppGenerierlaufType } from '../../../../../types';
import { useFilterGenLaufAppBestandseinheitListQuery } from '../../../gql/GenerierlaufFilter/AppGenerierlaufFilterQueries.types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { selectFilterOption } from '../../../../../helpers/selectHelper';

type Props = {
  name: string;
  generierlaufId: string;
  generierlaufType: AppGenerierlaufType;
  objektId?: string;
  maxTextLength?: number;
} & SelectProps;

const GenerierlaufBestandseinheitFormSelect: FC<Props> = ({ name, generierlaufId, generierlaufType, maxTextLength, objektId, ...restProps }) => {
  const { data, loading } = useFilterGenLaufAppBestandseinheitListQuery({
    variables: {
      generierlaufId,
      generierlaufType,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      objektId: objektId!,
    },
    skip: !objektId,
  });

  const list = data?.getFilterGenLaufAppBestandseinheitList.data ?? [];

  return (
    <Select
      size="small"
      loading={loading}
      id={name}
      name={name}
      showSearch
      allowClear
      disabled={!objektId}
      filterOption={selectFilterOption}
      placeholder="Bestandseinheit auswählen"
      {...restProps}
    >
      {list.map((bestandseinheit) => (
        <Select.Option key={bestandseinheit.bestandseinheitId} value={bestandseinheit.bestandseinheitId}>
          <DataWithShortenedText maxTextLength={maxTextLength ?? bestandseinheit.bezeichnung.length} text={bestandseinheit.bezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default GenerierlaufBestandseinheitFormSelect;
