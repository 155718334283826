import { FC } from 'react';
import { useFirmaOperatingSiteDeletionHistoryListQuery } from '../Firma/gql/FirmaQueries.types';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';

type Props = {
  firmaId: string;
};

const FirmaOperatingSiteDeletionHistoryListingTable: FC<Props> = ({ firmaId }) => {
  const { data, loading } = useFirmaOperatingSiteDeletionHistoryListQuery({ variables: { firmaId } });
  const historyList = data?.getFirmaOperatingSiteDeletionHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Deletion} historyList={historyList} loading={loading} filterIdPrefix="firma-operating-site" />;
};

export default FirmaOperatingSiteDeletionHistoryListingTable;
