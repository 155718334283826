import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { IndexSeriesEntryFieldsFragmentDoc } from '../../IndexSeriesEntry/gql/IndexSeriesEntryFragments.types';
export type IndexSeriesFieldsFragment = {
  chainingFactor?: number | null;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  csvColumnImportId?: string | null;
  csvColumnIndexPoint?: string | null;
  csvColumnMonthYear?: string | null;
  csvRowImportId?: string | null;
  deletable: boolean;
  firmendatenId?: string | null;
  importUrl?: string | null;
  indexSeriesId: string;
  name: string;
  updatedByMitarbeiterId?: string | null;
  indexSeriesEntryList: Array<{
    annualAverageFinal?: number | null;
    annualAveragePreliminary?: number | null;
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    indexSeriesEntryId: string;
    updatedByMitarbeiterId?: string | null;
    year: number;
    indexPointList: Array<{
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      finalValue?: number | null;
      indexPointId: string;
      month: string;
      preliminaryValue?: number | null;
      updatedByMitarbeiterId?: string | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  }>;
  indexType: { text: string; value: Types.IndexType };
  sourceType: { text: string; value: Types.IndexSeriesSourceType };
  status?: { text: string; value: Types.IndexSeriesStatus } | null;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const IndexSeriesFieldsFragmentDoc = gql`
  fragment IndexSeriesFields on IndexSeries {
    chainingFactor
    createTs
    createdBy
    createdByMitarbeiterId
    csvColumnImportId
    csvColumnIndexPoint
    csvColumnMonthYear
    csvRowImportId
    deletable
    firmendatenId
    importUrl
    indexSeriesEntryList {
      ...IndexSeriesEntryFields
    }
    indexSeriesId
    indexType {
      text
      value
    }
    name
    sourceType {
      text
      value
    }
    status {
      text
      value
    }
    updatedByMitarbeiterId
    warningList {
      attribute
      message
      type
    }
  }
  ${IndexSeriesEntryFieldsFragmentDoc}
`;
