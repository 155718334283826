import React, { FC, useEffect } from 'react';
import { FormikProps } from 'formik';
import { AuszifferungBlockFormValues, auszifferungFormFields } from './auszifferungFormMapper';
import { calculateAvailableAmountFromFormik } from './calculateAvailableAmount';
import { AvailableAmountText } from '../../../../shared/AvailableAmount/AvailableAmountText';

type Props = {
  amountFromPersonenkonto: number;
  bookingZahlungId: string;
  formikProps: FormikProps<AuszifferungBlockFormValues>;
};

const AvailableAmount: FC<Props> = ({ amountFromPersonenkonto, bookingZahlungId, formikProps }) => {
  const { values: formikValues } = formikProps;
  const availableAmount = calculateAvailableAmountFromFormik(amountFromPersonenkonto, bookingZahlungId, formikValues);

  useEffect(() => {
    formikProps.setFieldValue(auszifferungFormFields.availableAmount, availableAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableAmount, formikValues.offenePostenListFromBooking]);

  return <AvailableAmountText text="Verfügbarer Betrag" value={formikValues.availableAmount} />;
};

export default AvailableAmount;
