import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Skeleton, Space, Typography } from 'antd';

import CardWithFooter from '../../../../../../components/Card';
import CardTitle from '../../../../../../components/Card/CardTitle';
import { generatePathToBestandseinheitDetailsPage } from '../../../../../Bestandseinheit/bestandseinheitUriPaths';
import { generatePathToRechtstraegerDetailsPage } from '../../../../../Rechtstraeger/rechtstraegerHelper';
import CardDetailsInfoRow from '../../../../../../components/Card/CardDetailsInfoRow';
import TopAbrechnungDueDate from './TopAbrechnungDueDate';
import { TopAbrechnung } from '../../../../../../types';
import CustomFormattedDate from '../../../../../../components/FormattedDate/CustomFormattedDate';

type TopAbrechnungCardProps = {
  topAbrechnung: TopAbrechnung;
  objektId: string;
  loading: boolean;
};

const TopAbrechnungCard: FC<TopAbrechnungCardProps> = ({ topAbrechnung, loading, objektId }) => {
  return (
    <CardWithFooter
      loading={loading}
      style={{ minWidth: '320px' }}
      size="default"
      bordered={false}
      title={<CardTitle title={topAbrechnung.bezeichnung} status={topAbrechnung.status} />}
      createTs={topAbrechnung.createTs}
      userId={topAbrechnung.createdBy}
      mitarbeiterId={topAbrechnung.createdByMitarbeiterId}
    >
      <Skeleton active loading={loading}>
        <Space>
          <Space align="baseline">
            <Typography.Text type="secondary">von</Typography.Text>
            <CustomFormattedDate value={topAbrechnung.fromInclusive} />
          </Space>
          <Space align="baseline">
            <Typography.Text type="secondary">bis</Typography.Text>
            <CustomFormattedDate value={topAbrechnung.toInclusive} />
          </Space>
        </Space>
        <Divider dashed />

        <Typography.Title level={5}>Allgemeine Informationen</Typography.Title>
        <CardDetailsInfoRow infoRowTitle="Objekt">
          <Typography.Text>{topAbrechnung.objektKurzBezeichnung}</Typography.Text>
        </CardDetailsInfoRow>
        <CardDetailsInfoRow infoRowTitle="Bestandseinheit">
          <Link to={generatePathToBestandseinheitDetailsPage(objektId, topAbrechnung.bestandseinheitId)}>{topAbrechnung.bezeichnung}</Link>
        </CardDetailsInfoRow>
        <CardDetailsInfoRow infoRowTitle="Rechnungsaussteller">
          <Link to={generatePathToRechtstraegerDetailsPage(topAbrechnung.rechnungsAussteller.rechtstraegerId)}>
            {topAbrechnung.rechnungsAussteller.kurzBezeichnung}
          </Link>
        </CardDetailsInfoRow>
        <CardDetailsInfoRow infoRowTitle="Vertragspartner">
          <Link to={generatePathToRechtstraegerDetailsPage(topAbrechnung.vertragspartner.rechtstraegerId)}>
            {topAbrechnung.vertragspartner.kurzBezeichnung}
          </Link>
        </CardDetailsInfoRow>
        <CardDetailsInfoRow infoRowTitle="Fälligkeit">
          <TopAbrechnungDueDate topAbrechnung={topAbrechnung} />
        </CardDetailsInfoRow>
      </Skeleton>
    </CardWithFooter>
  );
};

export default TopAbrechnungCard;
