import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { KontoFieldsFragmentDoc } from '../../Kontenplan/gql/KontenplanFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KontoListForAbrKontenzuordnungQueryVariables = Types.Exact<{
  klasseList?: Types.InputMaybe<Array<Types.KontoKlasse> | Types.KontoKlasse>;
  verwendung?: Types.InputMaybe<Types.KontoVerwendung>;
}>;

export type KontoListForAbrKontenzuordnungQuery = {
  getKontoList: {
    data: {
      contentList: Array<{
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        haupt: boolean;
        kontoId: string;
        kontoTemplateId?: string | null;
        nummer: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        klasse: { text: string; value: Types.KontoKlasse };
        status: { description?: string | null; text: string; value: Types.KontoStatus };
        type: { text: string; value: Types.KontoType };
        verwendung?: { text: string; value: Types.KontoVerwendung } | null;
      }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type SaldoQueryVariables = Types.Exact<{
  kontoId: Types.Scalars['ID']['input'];
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type SaldoQuery = {
  getSaldo: {
    data: {
      saldo: number;
      konto: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        haupt: boolean;
        kontoId: string;
        kontoTemplateId?: string | null;
        nummer: string;
        tagList: Array<string>;
        updatedByMitarbeiterId?: string | null;
        klasse: { text: string; value: Types.KontoKlasse };
        status: { description?: string | null; text: string; value: Types.KontoStatus };
        type: { text: string; value: Types.KontoType };
        verwendung?: { text: string; value: Types.KontoVerwendung } | null;
      };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const KontoListForAbrKontenzuordnungDocument = gql`
  query KontoListForAbrKontenzuordnung($klasseList: [KontoKlasse!], $verwendung: KontoVerwendung) {
    getKontoList(klasseList: $klasseList, verwendung: $verwendung) {
      data {
        contentList {
          ...KontoFields
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KontoFieldsFragmentDoc}
`;
export function useKontoListForAbrKontenzuordnungQuery(
  baseOptions?: Apollo.QueryHookOptions<KontoListForAbrKontenzuordnungQuery, KontoListForAbrKontenzuordnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontoListForAbrKontenzuordnungQuery, KontoListForAbrKontenzuordnungQueryVariables>(
    KontoListForAbrKontenzuordnungDocument,
    options
  );
}
export function useKontoListForAbrKontenzuordnungLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KontoListForAbrKontenzuordnungQuery, KontoListForAbrKontenzuordnungQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontoListForAbrKontenzuordnungQuery, KontoListForAbrKontenzuordnungQueryVariables>(
    KontoListForAbrKontenzuordnungDocument,
    options
  );
}
export function useKontoListForAbrKontenzuordnungSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KontoListForAbrKontenzuordnungQuery, KontoListForAbrKontenzuordnungQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontoListForAbrKontenzuordnungQuery, KontoListForAbrKontenzuordnungQueryVariables>(
    KontoListForAbrKontenzuordnungDocument,
    options
  );
}
export type KontoListForAbrKontenzuordnungQueryHookResult = ReturnType<typeof useKontoListForAbrKontenzuordnungQuery>;
export type KontoListForAbrKontenzuordnungLazyQueryHookResult = ReturnType<typeof useKontoListForAbrKontenzuordnungLazyQuery>;
export type KontoListForAbrKontenzuordnungSuspenseQueryHookResult = ReturnType<typeof useKontoListForAbrKontenzuordnungSuspenseQuery>;
export type KontoListForAbrKontenzuordnungQueryResult = Apollo.QueryResult<
  KontoListForAbrKontenzuordnungQuery,
  KontoListForAbrKontenzuordnungQueryVariables
>;
export const SaldoDocument = gql`
  query Saldo($kontoId: ID!, $rechtstraegerId: ID!) {
    getSaldo(kontoId: $kontoId, rechtstraegerId: $rechtstraegerId) {
      data {
        saldo
        konto {
          ...KontoFields
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KontoFieldsFragmentDoc}
`;
export function useSaldoQuery(
  baseOptions: Apollo.QueryHookOptions<SaldoQuery, SaldoQueryVariables> & ({ variables: SaldoQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SaldoQuery, SaldoQueryVariables>(SaldoDocument, options);
}
export function useSaldoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SaldoQuery, SaldoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SaldoQuery, SaldoQueryVariables>(SaldoDocument, options);
}
export function useSaldoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SaldoQuery, SaldoQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SaldoQuery, SaldoQueryVariables>(SaldoDocument, options);
}
export type SaldoQueryHookResult = ReturnType<typeof useSaldoQuery>;
export type SaldoLazyQueryHookResult = ReturnType<typeof useSaldoLazyQuery>;
export type SaldoSuspenseQueryHookResult = ReturnType<typeof useSaldoSuspenseQuery>;
export type SaldoQueryResult = Apollo.QueryResult<SaldoQuery, SaldoQueryVariables>;
