import * as Yup from 'yup';
import { entityIsRequired } from '../../../../../../components/message/validationMsg';
import { hausbetreuungCreateFormFields } from '../Create/hausbetreuungCreateFormMapper';
import { isNotNil } from '../../../../../../helpers/assertionHelper';

/* eslint-disable no-template-curly-in-string */
export const hausbetreuungValidationSchema = Yup.object().shape(
  {
    [hausbetreuungCreateFormFields.beauftragungsgrund]: Yup.string().required(entityIsRequired('Beauftragunsgrund')),
    [hausbetreuungCreateFormFields.kuendigungsfrist]: Yup.string().when(
      [hausbetreuungCreateFormFields.kuendigungsfristDauer],
      // eslint-disable-next-line no-extra-boolean-cast
      ([kuendigungfristDauer], schema) => (!!kuendigungfristDauer ? schema.nullable().required('Frist auswählen') : schema.nullable())
    ),
    [hausbetreuungCreateFormFields.kuendigungsfristDauer]: Yup.number().when(
      [hausbetreuungCreateFormFields.kuendigungsfrist],
      ([kuendigungfrist], schema) => {
        return isNotNil(kuendigungfrist) ? schema.nullable().required('Dauer auswählen') : schema.nullable();
      }
    ),
  },
  [[hausbetreuungCreateFormFields.kuendigungsfrist, hausbetreuungCreateFormFields.kuendigungsfristDauer]]
);
/* eslint-enable no-template-curly-in-string */
