import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { AuftragsartType, AuftragStatus, PaymentMethod, QueryGetAuftragListArgs, Vertragsart } from '../../../../types';
import { TVorschreibungQueryParams } from './filtersQueryParams';
import { ObjectOfStrings } from '../../../../shared/typeHelpers';

export type VorschreibungListingFiltersFormValues = {
  auftragsartType?: AuftragsartType;
  ausstellerIban?: string | null;
  ausstellerRechtstraegerId?: string | null;
  belegnummer?: string | null;
  bruttoFrom?: number | null;
  bruttoTo?: number | null;
  createTsFrom?: string | null;
  createTsTo?: string | null;
  dueDateFrom?: string | null;
  dueDateTo?: string | null;
  empfaengerIban?: string | null;
  empfaengerRechtstraegerId?: string | null;
  erstellerMitarbeiterIdList?: string[];
  fakturierungsperiodeDateFrom?: string | null;
  fakturierungsperiodeDateTo?: string | null;
  kundennummer?: string | null;
  nettoFrom?: number | null;
  nettoTo?: number | null;
  objektId?: string | null;
  paymentMethod?: PaymentMethod | null;
  rechnungsDatumFrom?: string | null;
  rechnungsDatumTo?: string | null;
  sepaCreditorId?: string | null;
  sepaMandateReference?: string | null;
  status?: AuftragStatus | null;
  ustFrom?: number | null;
  ustTo?: number | null;
  vertragsart?: Vertragsart | null;
};

export const vorschreibungListingFiltersFormFields: FormFields<VorschreibungListingFiltersFormValues> = {
  auftragsartType: 'auftragsartType',
  ausstellerIban: 'ausstellerIban',
  ausstellerRechtstraegerId: 'ausstellerRechtstraegerId',
  belegnummer: 'belegnummer',
  bruttoFrom: 'bruttoFrom',
  bruttoTo: 'bruttoTo',
  createTsFrom: 'createTsFrom',
  createTsTo: 'createTsTo',
  dueDateFrom: 'dueDateFrom',
  dueDateTo: 'dueDateTo',
  empfaengerIban: 'empfaengerIban',
  empfaengerRechtstraegerId: 'empfaengerRechtstraegerId',
  erstellerMitarbeiterIdList: 'erstellerMitarbeiterIdList',
  fakturierungsperiodeDateFrom: 'fakturierungsperiodeDateFrom',
  fakturierungsperiodeDateTo: 'fakturierungsperiodeDateTo',
  kundennummer: 'kundennummer',
  nettoFrom: 'nettoFrom',
  nettoTo: 'nettoTo',
  objektId: 'objektId',
  paymentMethod: 'paymentMethod',
  rechnungsDatumFrom: 'rechnungsDatumFrom',
  rechnungsDatumTo: 'rechnungsDatumTo',
  sepaCreditorId: 'sepaCreditorId',
  sepaMandateReference: 'sepaMandateReference',
  status: 'status',
  ustFrom: 'ustFrom',
  ustTo: 'ustTo',
  vertragsart: 'vertragsart',
};

export const mapQueryParamsToFormValues = (queryParams: TVorschreibungQueryParams): VorschreibungListingFiltersFormValues => ({
  ...queryParams,
});

export const mapFormValuesToVorschreibungListingFilters = (formValues: VorschreibungListingFiltersFormValues): TVorschreibungQueryParams => ({
  ...formValues,
  createTsFrom: formValues.createTsFrom ? mapFormDateValueToDateString(formValues.createTsFrom) : null,
  createTsTo: formValues.createTsTo ? mapFormDateValueToDateString(formValues.createTsTo) : null,
  dueDateFrom: formValues.dueDateFrom ? mapFormDateValueToDateString(formValues.dueDateFrom) : null,
  dueDateTo: formValues.dueDateTo ? mapFormDateValueToDateString(formValues.dueDateTo) : null,
  fakturierungsperiodeDateFrom: formValues.fakturierungsperiodeDateFrom
    ? mapFormDateValueToDateString(formValues.fakturierungsperiodeDateFrom, 'YYYY-M')
    : undefined,
  fakturierungsperiodeDateTo: formValues.fakturierungsperiodeDateTo
    ? mapFormDateValueToDateString(formValues.fakturierungsperiodeDateTo, 'YYYY-M')
    : undefined,
  rechnungsDatumFrom: formValues.rechnungsDatumFrom ? mapFormDateValueToDateString(formValues.rechnungsDatumFrom) : null,
  rechnungsDatumTo: formValues.rechnungsDatumTo ? mapFormDateValueToDateString(formValues.rechnungsDatumTo) : null,
});

export const mapQueryParamsToAuftragQueryVariables = (queryParams: TVorschreibungQueryParams): QueryGetAuftragListArgs => {
  const { belegnummer, kundennummer, ...restQueryParams } = queryParams;

  return {
    ...restQueryParams,
    belegnummer: belegnummer?.toString(),
    includeStorniert: true,
    includeStorno: true,
    kundennummer: kundennummer?.toString(),
  };
};

export const vorschreibungListingLabelList: ObjectOfStrings<
  Omit<
    VorschreibungListingFiltersFormValues,
    'nettoTo' | 'bruttoTo' | 'createTsTo' | 'ustTo' | 'fakturierungsperiodeDateTo' | 'rechnungsDatumTo' | 'dueDateTo'
  >
> = {
  [vorschreibungListingFiltersFormFields.auftragsartType]: 'Vorschreibung-Typ',
  [vorschreibungListingFiltersFormFields.ausstellerIban]: 'Rechnungsaussteller Bankverbindung',
  [vorschreibungListingFiltersFormFields.ausstellerRechtstraegerId]: 'Rechnungsaussteller',
  [vorschreibungListingFiltersFormFields.belegnummer]: 'Rechnungsnummer',
  [vorschreibungListingFiltersFormFields.bruttoFrom]: 'Brutto',
  [vorschreibungListingFiltersFormFields.createTsFrom]: 'Erstellt am',
  [vorschreibungListingFiltersFormFields.dueDateFrom]: 'Fälligkeit',
  [vorschreibungListingFiltersFormFields.empfaengerIban]: 'Rechnungsempfänger Bankverbindung',
  [vorschreibungListingFiltersFormFields.empfaengerRechtstraegerId]: 'Rechnungsempfänger',
  [vorschreibungListingFiltersFormFields.erstellerMitarbeiterIdList]: 'Ersteller',
  [vorschreibungListingFiltersFormFields.fakturierungsperiodeDateFrom]: 'Zinsliste',
  [vorschreibungListingFiltersFormFields.kundennummer]: 'Kundennummer',
  [vorschreibungListingFiltersFormFields.nettoFrom]: 'Netto',
  [vorschreibungListingFiltersFormFields.objektId]: 'Objekt',
  [vorschreibungListingFiltersFormFields.paymentMethod]: 'Zahlungsart',
  [vorschreibungListingFiltersFormFields.rechnungsDatumFrom]: 'Rechnungsdatum',
  [vorschreibungListingFiltersFormFields.sepaCreditorId]: 'SEPA Creditor-ID',
  [vorschreibungListingFiltersFormFields.sepaMandateReference]: 'SEPA Mandatsreferenz',
  [vorschreibungListingFiltersFormFields.status]: 'Vorschreibung Status',
  [vorschreibungListingFiltersFormFields.ustFrom]: 'Ust.',
  [vorschreibungListingFiltersFormFields.vertragsart]: 'Vertragsart',
};
