import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Dropdown, Row, Space, Tooltip, Typography } from 'antd';
import { DownloadOutlined, EllipsisOutlined, MailOutlined } from '@ant-design/icons';
import { Ticket } from '../../../types';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';
import { UploadedFileView } from '../../shared/UploadedFileView';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';
import { dayjsCustom } from '../../../helpers/dayjsCustom';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { uriFileOfFirmendaten } from '../../Document/documentApiPaths';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { downloadURL } from '../../../helpers/fileHelper';
import subTicketTooltipContent from './Tooltip/subTicketTooltipContent';
import { generatePathToRechtstraegerDetailsPage } from '../../Rechtstraeger/rechtstraegerHelper';

type Props = {
  ticket: Ticket;
  refetch: () => void;
};

const TicketCommunicationInfoRow: FC<Props> = ({ ticket }) => {
  const { activeForFirmendatenId } = useFDAuthorized();

  return ticket.emailList.length === 0 ? (
    <CardDetailsInfoRow infoRowTitle="Kommunikation">
      <TextForEmptyValue textToShow="minus" />
    </CardDetailsInfoRow>
  ) : (
    <>
      <Row style={{ width: '100%' }}>
        <Typography.Text type="secondary">Kommunikation</Typography.Text>
      </Row>
      <Space direction="vertical" style={{ width: '100%' }}>
        {[...ticket.emailList]
          .sort((a, b) => (a.sentTs && b.sentTs ? dayjsCustom(b.sentTs).diff(dayjsCustom(a.sentTs)) : 0))
          .map((email, index) => (
            <Card
              size="small"
              key={email.fileInfo.fileId}
              type="inner"
              extra={
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: '1',
                        label: 'Kommunikation herunterladen',
                        onClick: () => {
                          downloadURL(uriFileOfFirmendaten(activeForFirmendatenId, email.fileInfo.fileId, email.fileInfo.name));
                        },
                        icon: <DownloadOutlined />,
                      },
                    ],
                  }}
                >
                  <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
                </Dropdown>
              }
              title={
                <UploadedFileView
                  key={index}
                  disableDelete
                  filename={email.fileInfo.name}
                  icon={<MailOutlined />}
                  tooltipFooter={subTicketTooltipContent(ticket.subTicketList, email.ticketId)}
                />
              }
            >
              <Row>
                <Col span={16}>
                  {email.ticketParticipant.participant.rechtstraeger ? (
                    <DataWithShortenedText
                      tooltipFooter={email.sender ?? ''}
                      maxTextLength={35}
                      text={email.ticketParticipant.participant.rechtstraeger.kurzBezeichnung}
                    >
                      {(shortenedText) => (
                        <Link
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          to={generatePathToRechtstraegerDetailsPage(email.ticketParticipant.participant.rechtstraeger!.rechtstraegerId)}
                          target="blank"
                        >
                          {shortenedText}
                        </Link>
                      )}
                    </DataWithShortenedText>
                  ) : (
                    <DataWithShortenedText maxTextLength={35} text={email.sender ?? ''} />
                  )}
                </Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  <Typography.Text type="secondary">
                    <Tooltip title={<FormattedDateTime createdTs={email.sentTs ?? null} />}>{dayjsCustom(email.sentTs).fromNow()}</Tooltip>
                  </Typography.Text>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <DataWithShortenedText maxTextLength={35} text={email.subject || '(Kein Betreff)'} />
                </Col>
              </Row>
            </Card>
          ))}
      </Space>
    </>
  );
};

export default TicketCommunicationInfoRow;
