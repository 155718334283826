import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KontoVerwendungListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type KontoVerwendungListQuery = {
  getKontoVerwendungList: {
    data: Array<{ text: string; value: Types.KontoVerwendung }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const KontoVerwendungListDocument = gql`
  query KontoVerwendungList {
    getKontoVerwendungList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useKontoVerwendungListQuery(baseOptions?: Apollo.QueryHookOptions<KontoVerwendungListQuery, KontoVerwendungListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KontoVerwendungListQuery, KontoVerwendungListQueryVariables>(KontoVerwendungListDocument, options);
}
export function useKontoVerwendungListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KontoVerwendungListQuery, KontoVerwendungListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KontoVerwendungListQuery, KontoVerwendungListQueryVariables>(KontoVerwendungListDocument, options);
}
export function useKontoVerwendungListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KontoVerwendungListQuery, KontoVerwendungListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<KontoVerwendungListQuery, KontoVerwendungListQueryVariables>(KontoVerwendungListDocument, options);
}
export type KontoVerwendungListQueryHookResult = ReturnType<typeof useKontoVerwendungListQuery>;
export type KontoVerwendungListLazyQueryHookResult = ReturnType<typeof useKontoVerwendungListLazyQuery>;
export type KontoVerwendungListSuspenseQueryHookResult = ReturnType<typeof useKontoVerwendungListSuspenseQuery>;
export type KontoVerwendungListQueryResult = Apollo.QueryResult<KontoVerwendungListQuery, KontoVerwendungListQueryVariables>;
