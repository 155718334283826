import React from 'react';
import { TableWithColSelectorColumnProps } from '../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import FormattedDateTime from '../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../components/Card';
import historyListingTableAttributeColumns from './shared/historyListingTableAttributeColumns';
import { HistoryFieldsFragment } from './gql/HistoryFragments.types';

const deletionHistoryListingTableColumns = (historyList?: HistoryFieldsFragment[]): TableWithColSelectorColumnProps<HistoryFieldsFragment>[] => [
  {
    title: 'Gelöscht am',
    render: (text, record) => {
      return <FormattedDateTime createdTs={record.updateTs} />;
    },
    defaultSelected: true,
    fixed: true,
    width: 150,
  },
  {
    title: 'Gelöscht von',
    render: (text, record) => {
      return <MitarbeiterTooltip userId={record.updatedBy} mitarbeiterId={record.updatedByMitarbeiterId} showsInitials alignment="left" />;
    },
    defaultSelected: true,
    fixed: true,
    width: 100,
  },
  ...historyListingTableAttributeColumns(historyList),
];

export default deletionHistoryListingTableColumns;
