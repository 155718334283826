import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import StandingInvoiceDetailsPage from './StandingInvoiceDetailsPage';
import { incomingInvoicePaths } from '../../../features/IncomingInvoice/incomingInvoiceUriPaths';

const standingInvoiceDetailsPageRoute = (
  <Route
    path={incomingInvoicePaths.standingInvoiceDetails}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<StandingInvoiceDetailsPage />} />}
  />
);

export default standingInvoiceDetailsPageRoute;
