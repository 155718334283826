import React, { FC } from 'react';
import { FormikProps } from 'formik';
import {
  useVertragVposIndexedValueListFirmendatenQuery,
  useVertragVposIndexedValueListSummaryQuery,
} from '../gql/VertragVposIndexedValueQueries.types';
import IndexedValueTable from './Table/IndexedValueTable';
import { IndexedValueListingFiltersFormValues } from './Filters/indexedValueListingFiltersFormMapper';
import { TIndexedValueQueryParams } from './Filters/filtersQueryParams';
import IndexedValueListingFilters from './Filters/IndexedValueListingFilters';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../hooks/useQueryWithPagingAndSorting';
import { useOnIndexedValueDataChangedEvents } from '../useOnIndexedValueDataChangedEvents';
import IndexedValueListSummary from './IndexedValueListSummary';

type Props = {
  formikProps: FormikProps<IndexedValueListingFiltersFormValues>;
  queryParams: TIndexedValueQueryParams;
};

const IndexedValueListingForm: FC<Props> = ({ formikProps, queryParams }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, refetch, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useVertragVposIndexedValueListFirmendatenQuery,
    {
      variables: {
        ...queryParams,
      },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const indexedValueList = data?.getVertragVposIndexedValueListFirmendaten.data.contentList ?? [];

  const { data: summaryData, refetch: summaryRefetch } = useVertragVposIndexedValueListSummaryQuery({
    variables: {
      ...queryParams,
    },
  });

  const summary = summaryData?.getVertragVposIndexedValueListSummary.data;

  useOnIndexedValueDataChangedEvents('indexedValueList', () => refetch().then(() => summaryRefetch()));

  return (
    <>
      <IndexedValueListingFilters formikProps={formikProps} refetch={refetch} queryParams={queryParams} />
      <IndexedValueListSummary summary={summary} />
      <IndexedValueTable
        indexedValueList={indexedValueList}
        refetch={refetch}
        loading={loading}
        pagination={pagination}
        onChange={handleTableSorting}
      />
    </>
  );
};

export default IndexedValueListingForm;
