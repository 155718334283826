import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Space, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import FiltersWith4OrMoreFields from '../../../components/Filters/FiltersWith4OrMoreFields';
import { ticketListingFiltersFormFields, TicketListingFiltersFormValues, ticketListingLabelList } from './ticketListingFormMapper';
import TicketStatusSelect from '../shared/TicketStatusSelect';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import TicketPrioritySelect from '../shared/TicketPrioritySelect';
import FromToFormDatePicker from '../../shared/FromToForm/FromToFormDatePicker';
import { StyledDebouncedTextSearch } from '../Styled/StyledDebouncedTextSearch';
import MitarbeiterSelect from '../../../shared/components/MitarbeiterSelect/MitarbeiterSelect';
import RechtstraegerSelect from '../../../shared/Rechtstraeger/RechtstraegerSelect';
import FiltersSelect from '../../../components/Filters/FiltersSelect';
import { onFilterSelectChange } from '../../../components/Filters/FiltersHelpers';
import TicketTypeNameSelect from '../shared/TicketTypeNameSelect';
import useFilterWithQueryParams from '../../shared/useFilterWithQueryParams';
import { TTicketQueryParams } from './Filters/filtersQueryParams';
import { TicketListingFilterProps } from './TicketListing';
import ObjektSelect from '../../ObjektSelect/ObjektSelect';
import VertragSelect from '../../Vertrag/shared/VertragSelect';
import BestandseinheitFormSelect from '../../shared/Bestandseinheit/BestandseinheitFormSelect';

type ExtraFilters = {
  objektFilter?: boolean;
  bestandseinheitFilter?: boolean;
  vertragFilter?: boolean;
  rechtstraegerFilter?: boolean;
};

type Props = {
  formikProps: FormikProps<TicketListingFiltersFormValues>;
  actionButtons?: React.ReactNode;
  queryParams: TTicketQueryParams;
  extraFilters?: ExtraFilters;
} & TicketListingFilterProps;

const TicketListingFilters: FC<Props> = ({ formikProps, actionButtons, queryParams, extraFilters = {}, objektId, bestandseinheitId }) => {
  const defaultSelectedFilterList = [ticketListingFiltersFormFields.status];

  const { objektFilter = false, bestandseinheitFilter = false, vertragFilter = false, rechtstraegerFilter = false } = extraFilters;

  const labelList = ticketListingLabelList({
    includeObjekt: objektFilter,
    includeBestandseinheit: bestandseinheitFilter,
    includeVertrag: vertragFilter,
    includeRechtstraeger: rechtstraegerFilter,
  });

  const mapFilterLabelToQueryFilterList = (filter: string) => {
    if (filter === ticketListingFiltersFormFields.dueDateFrom || filter === ticketListingFiltersFormFields.dueDateTo) {
      return ticketListingFiltersFormFields.dueDateFrom;
    }
    return filter;
  };

  const filters = (filter: string) => {
    switch (filter) {
      case ticketListingFiltersFormFields.objektId:
        return (
          <FormItemWithoutColon name={ticketListingFiltersFormFields.objektId} label="Objekt" key={filter}>
            <ObjektSelect
              name={ticketListingFiltersFormFields.objektId}
              onChange={(value) => {
                if (!value) {
                  formikProps.setFieldValue(ticketListingFiltersFormFields.bestandseinheitId, undefined);
                  formikProps.setFieldValue(ticketListingFiltersFormFields.vertragId, undefined);
                }
                formikProps.submitForm();
              }}
            />
          </FormItemWithoutColon>
        );

      case ticketListingFiltersFormFields.bestandseinheitId:
        return (
          <FormItemWithoutColon
            name={ticketListingFiltersFormFields.bestandseinheitId}
            label={
              !formikProps.values.objektId ? (
                <Space size={4}>
                  <Typography.Text>Bestandseinheit</Typography.Text>
                  <Tooltip title="Bitte wählen Sie zuerst ein Objekt aus">
                    <InfoCircleOutlined />
                  </Tooltip>
                </Space>
              ) : (
                'Bestandseinheit'
              )
            }
            key={filter}
          >
            <BestandseinheitFormSelect
              name={ticketListingFiltersFormFields.bestandseinheitId}
              objektId={objektId ?? formikProps.values.objektId}
              onChange={(value) => {
                if (!value) {
                  formikProps.setFieldValue(ticketListingFiltersFormFields.vertragId, undefined);
                }
                formikProps.submitForm();
              }}
            />
          </FormItemWithoutColon>
        );

      case ticketListingFiltersFormFields.vertragId:
        return (
          <FormItemWithoutColon
            name={ticketListingFiltersFormFields.vertragId}
            label={
              !formikProps.values.bestandseinheitId ? (
                <Space size={4}>
                  <Typography.Text>Vertrag</Typography.Text>
                  <Tooltip title="Bitte wählen Sie zuerst eine Bestandseinheit aus">
                    <InfoCircleOutlined />
                  </Tooltip>
                </Space>
              ) : (
                'Vertrag'
              )
            }
            key={filter}
          >
            <VertragSelect
              name={ticketListingFiltersFormFields.vertragId}
              objektId={objektId ?? formikProps.values.objektId}
              bestandseinheitId={bestandseinheitId ?? formikProps.values.bestandseinheitId}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        );

      case ticketListingFiltersFormFields.rechtstraegerId:
        return (
          <FormItemWithoutColon name={ticketListingFiltersFormFields.rechtstraegerId} label="Rechtsträger" key={filter}>
            <RechtstraegerSelect name={ticketListingFiltersFormFields.rechtstraegerId} onChange={formikProps.submitForm} />
          </FormItemWithoutColon>
        );

      case ticketListingFiltersFormFields.typeName:
        return (
          <FormItemWithoutColon name={ticketListingFiltersFormFields.typeName} label="Art" key={filter}>
            <TicketTypeNameSelect mode="multiple" name={ticketListingFiltersFormFields.typeName} onChange={formikProps.submitForm} />
          </FormItemWithoutColon>
        );

      case ticketListingFiltersFormFields.title:
        return (
          <FormItemWithoutColon name={ticketListingFiltersFormFields.title} label="Titel" key={filter}>
            <StyledDebouncedTextSearch
              name={ticketListingFiltersFormFields.title}
              id={ticketListingFiltersFormFields.title}
              value={formikProps.values[ticketListingFiltersFormFields.title]}
              onChange={(value) => {
                formikProps.setFieldValue(ticketListingFiltersFormFields.title, value);
                formikProps.submitForm();
              }}
            />
          </FormItemWithoutColon>
        );

      case ticketListingFiltersFormFields.assigneeMitarbeiterId:
        return (
          <FormItemWithoutColon name={ticketListingFiltersFormFields.assigneeMitarbeiterId} label="Bearbeiter" key={filter}>
            <MitarbeiterSelect
              mode="multiple"
              name={ticketListingFiltersFormFields.assigneeMitarbeiterId}
              placeholder="Bearbeiter auswählen"
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        );

      case ticketListingFiltersFormFields.status:
        return (
          <FormItemWithoutColon name={ticketListingFiltersFormFields.status} label="Status" key={filter}>
            <TicketStatusSelect mode="multiple" name={ticketListingFiltersFormFields.status} onChange={formikProps.submitForm} />
          </FormItemWithoutColon>
        );

      case ticketListingFiltersFormFields.priority:
        return (
          <FormItemWithoutColon name={ticketListingFiltersFormFields.priority} label="Priorität" key={filter}>
            <TicketPrioritySelect mode="multiple" name={ticketListingFiltersFormFields.priority} onChange={formikProps.submitForm} />
          </FormItemWithoutColon>
        );

      case ticketListingFiltersFormFields.dueDateFrom:
      case ticketListingFiltersFormFields.dueDateTo:
        return (
          <FormItemWithoutColon name={ticketListingFiltersFormFields.dueDateFrom} label="Fälligkeit" key={filter}>
            <FromToFormDatePicker
              format="day"
              fromName={ticketListingFiltersFormFields.dueDateFrom}
              toName={ticketListingFiltersFormFields.dueDateTo}
              fromOnChange={formikProps.submitForm}
              toOnChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        );

      case ticketListingFiltersFormFields.participantId:
        return (
          <FormItemWithoutColon name={ticketListingFiltersFormFields.participantId} label="Beteiligte" key={filter}>
            <RechtstraegerSelect
              mode="multiple"
              name={ticketListingFiltersFormFields.participantId}
              placeholder="Beteiligte auswählen"
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        );

      default:
        return undefined;
    }
  };

  const { selectedFilterList, setSelectedFilterList, leftColumn, rightColumn } = useFilterWithQueryParams({
    defaultSelectedFilterList,
    filters,
    labelList,
    mapFilterLabelToQueryFilterList,
    updateFilterValues: (list) => {
      // Manually reset dueDateTo form field value when Fälligkeit label is removed from top filter
      if (!list.includes(ticketListingFiltersFormFields.dueDateFrom) && formikProps.values.dueDateTo) {
        formikProps.setFieldValue(ticketListingFiltersFormFields.dueDateTo, '');
      }
      if (labelList[ticketListingFiltersFormFields.objektId] && !list.includes(ticketListingFiltersFormFields.objektId)) {
        formikProps.setFieldValue(ticketListingFiltersFormFields.vertragId, undefined);
        formikProps.setFieldValue(ticketListingFiltersFormFields.bestandseinheitId, undefined);
      }

      if (labelList[ticketListingFiltersFormFields.bestandseinheitId] && !list.includes(ticketListingFiltersFormFields.bestandseinheitId)) {
        formikProps.setFieldValue(ticketListingFiltersFormFields.vertragId, undefined);
      }
      // needed to set initial query params in the uri
      formikProps.submitForm();
    },
    queryParams,
  });

  return (
    <FiltersWith4OrMoreFields
      actionButtons={actionButtons}
      hideTitle
      filtersSelect={
        <FiltersSelect
          defaultSelectedFilterList={defaultSelectedFilterList}
          selectedFilterList={selectedFilterList}
          onChange={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
          labelList={labelList}
        />
      }
      leftColumn={<>{leftColumn}</>}
      rightColumn={<>{rightColumn}</>}
    />
  );
};

export default TicketListingFilters;
