import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FirmendatenListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FirmendatenListQuery = {
  getFirmendatenList: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      firmendatenId: string;
      kundenSystemId: string;
      kurzBezeichnung: string;
      status: { description?: string | null; text: string; value: Types.FirmendatenStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FirmendatenObjektAbrechnungListQueryVariables = Types.Exact<{
  objektIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  abrechnungTypeList?: Types.InputMaybe<Array<Types.AbrechnungType> | Types.AbrechnungType>;
}>;

export type FirmendatenObjektAbrechnungListQuery = {
  getFirmendatenObjektAbrechnungList: {
    data: Array<{
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      fileId?: string | null;
      firmendatenId: string;
      fromInclusive: string;
      generierlaufId: string;
      objektAbrechnungId: string;
      toInclusive: string;
      topAbrechnungenExist: boolean;
      abrechnungsdefinitionType: { text: string; value: Types.AbrechnungsdefinitionType };
      errorList: Array<{ message: string; type: string }>;
      status: { text: string; value: Types.AbrechnungStatus };
      warningList: Array<{ message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const FirmendatenListDocument = gql`
  query FirmendatenList {
    getFirmendatenList {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
        firmendatenId
        kundenSystemId
        kurzBezeichnung
        status {
          description
          text
          value
        }
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFirmendatenListQuery(baseOptions?: Apollo.QueryHookOptions<FirmendatenListQuery, FirmendatenListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenListQuery, FirmendatenListQueryVariables>(FirmendatenListDocument, options);
}
export function useFirmendatenListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenListQuery, FirmendatenListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenListQuery, FirmendatenListQueryVariables>(FirmendatenListDocument, options);
}
export function useFirmendatenListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FirmendatenListQuery, FirmendatenListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenListQuery, FirmendatenListQueryVariables>(FirmendatenListDocument, options);
}
export type FirmendatenListQueryHookResult = ReturnType<typeof useFirmendatenListQuery>;
export type FirmendatenListLazyQueryHookResult = ReturnType<typeof useFirmendatenListLazyQuery>;
export type FirmendatenListSuspenseQueryHookResult = ReturnType<typeof useFirmendatenListSuspenseQuery>;
export type FirmendatenListQueryResult = Apollo.QueryResult<FirmendatenListQuery, FirmendatenListQueryVariables>;
export const FirmendatenObjektAbrechnungListDocument = gql`
  query FirmendatenObjektAbrechnungList($objektIdList: [String!], $abrechnungTypeList: [AbrechnungType!]) {
    getFirmendatenObjektAbrechnungList(objektIdList: $objektIdList, abrechnungTypeList: $abrechnungTypeList) {
      data {
        abrechnungsdefinitionType {
          text
          value
        }
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        fileId
        firmendatenId
        fromInclusive
        generierlaufId
        objektAbrechnungId
        status {
          text
          value
        }
        toInclusive
        topAbrechnungenExist
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFirmendatenObjektAbrechnungListQuery(
  baseOptions?: Apollo.QueryHookOptions<FirmendatenObjektAbrechnungListQuery, FirmendatenObjektAbrechnungListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenObjektAbrechnungListQuery, FirmendatenObjektAbrechnungListQueryVariables>(
    FirmendatenObjektAbrechnungListDocument,
    options
  );
}
export function useFirmendatenObjektAbrechnungListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenObjektAbrechnungListQuery, FirmendatenObjektAbrechnungListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenObjektAbrechnungListQuery, FirmendatenObjektAbrechnungListQueryVariables>(
    FirmendatenObjektAbrechnungListDocument,
    options
  );
}
export function useFirmendatenObjektAbrechnungListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FirmendatenObjektAbrechnungListQuery, FirmendatenObjektAbrechnungListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenObjektAbrechnungListQuery, FirmendatenObjektAbrechnungListQueryVariables>(
    FirmendatenObjektAbrechnungListDocument,
    options
  );
}
export type FirmendatenObjektAbrechnungListQueryHookResult = ReturnType<typeof useFirmendatenObjektAbrechnungListQuery>;
export type FirmendatenObjektAbrechnungListLazyQueryHookResult = ReturnType<typeof useFirmendatenObjektAbrechnungListLazyQuery>;
export type FirmendatenObjektAbrechnungListSuspenseQueryHookResult = ReturnType<typeof useFirmendatenObjektAbrechnungListSuspenseQuery>;
export type FirmendatenObjektAbrechnungListQueryResult = Apollo.QueryResult<
  FirmendatenObjektAbrechnungListQuery,
  FirmendatenObjektAbrechnungListQueryVariables
>;
