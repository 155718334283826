import React from 'react';
import { MenuProps, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { FormOutlined, ReadOutlined } from '@ant-design/icons';
import { friendlyFormatIBAN } from 'ibantools';
import { BookingSuggestionCreationGenerierlaufEntry, ReceivedBankTransaction } from '../../../../types';
import { EuroAmount } from '../../../../components/Number';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import { generatePathToPaymentPage } from '../../../Payment/Listing/Filters/filtersQueryParams';
import { NestedTableColProps } from '../../../../components/Table/NestedTable/NestedTable';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import ActionDropdown from '../../../../components/Dropdown/ActionDropdown';
import { generatePathToBookingSuggestionBuchungsmaskeWithTabsPage } from '../../../BookingSuggestion/bookingSuggestionBuchungsmaskePaths';
import { isNotNil } from '../../../../helpers/assertionHelper';
import { generatePathToBookingDetailsPage } from '../../../BookingDetails/bookingDetailsPaths';

const receivedBankTransactionTableColumns = (entry: BookingSuggestionCreationGenerierlaufEntry): NestedTableColProps<ReceivedBankTransaction>[] => [
  {
    title: 'Gesehen',
    render: (text, record) => <Typography.Text>{record.noted ? 'JA' : 'NEIN'}</Typography.Text>,
  },
  {
    title: 'Kreditor',
    //defaultSelected: true,
    //dataIndex: BookingSuggestionCreationGenerierlaufOrderBy.Creditor,
    sorter: true,
    render: (text, record) =>
      record.creditor ? <DataWithShortenedText text={record.creditor} maxTextLength={20} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Kreditor Bankverbindung',
    render: (text, record) =>
      record.creditorIban ? (
        <DataWithShortenedText text={friendlyFormatIBAN(record.creditorIban) as string} maxTextLength={20} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Debitor',
    //defaultSelected: true,
    //dataIndex: BookingSuggestionCreationGenerierlaufOrderBy.Debtor,
    sorter: true,
    render: (text, record) =>
      record.debtor ? <DataWithShortenedText text={record.debtor} maxTextLength={20} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Debitor Bankverbindung',
    render: (text, record) =>
      record.debtorIban ? (
        <DataWithShortenedText text={friendlyFormatIBAN(record.debtorIban) as string} maxTextLength={20} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'TransaktionsanweisungsId',
    render: (text, record) => (record.endToEndId ? <Typography.Text>{record.endToEndId}</Typography.Text> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Transaktionsart',
    render: (text, record) => <Typography.Text>{record.transactionType.text}</Typography.Text>,
  },
  {
    title: 'Valutadatum',
    render: (text, record) => (record.valueDate ? <CustomFormattedDate value={record.valueDate} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Rücklastschrift',
    render: (text, record) =>
      isNotNil(record.reversal) ? <Typography.Text>{record.reversal ? 'Ja' : 'Nein'}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Betrag',
    render: (text, record) => <EuroAmount value={record.amount} />,
  },
  {
    title: 'Spesen',
    render: (text, record) => (record.charges ? <EuroAmount value={record.charges} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Spesen in eigener Bankbuchung',
    render: (text, record) =>
      isNotNil(record.chargesInSeparateEntry) ? (
        <Typography.Text>{record.chargesInSeparateEntry ? 'Ja' : 'Nein'}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Verwendungszweck',
    render: (text, record) =>
      record.purposeOfUseText ? (
        <DataWithShortenedText text={record.purposeOfUseText} maxTextLength={20} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Zahlungsreferenz',
    render: (text, record) =>
      record.paymentReferenceText ? (
        <DataWithShortenedText text={record.paymentReferenceText} maxTextLength={20} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Verbucht',
    render: (text, record) => <Typography.Text>{record.booked ? 'Ja' : 'Nein'}</Typography.Text>,
  },
  {
    title: 'Emp. Bankb. Status',
    render: (text, record) => <Typography.Text>{record.status.text}</Typography.Text>,
  },
  {
    title: 'Zugewiesene Zahlung',
    render: (_, record) => {
      if (!record.paymentType) return <TextForEmptyValue textToShow="minus" />;

      const text =
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        `${record.paymentType!.text} - ${dayjsCustom(record.valueDate!).format('DD.MM.YYYY')}` +
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ` - ${record.buchungskreisRechtstraeger!.kurzBezeichnung} - ${record.payee!.kurzBezeichnung} - ${record.amount}€ `;

      return (
        <DataWithShortenedText text={text} maxTextLength={20}>
          {(shortenedText) => (
            <Link
              to={generatePathToPaymentPage({
                paymentTypeList: record.paymentType ? [record.paymentType.value] : undefined,
                paymentDateFrom: record.valueDate ?? undefined,
                paymentDateTo: record.valueDate ?? undefined,
                rechnungsAusstellerId: record.buchungskreisRechtstraeger?.rechtstraegerId ?? undefined,
                payeeId: record.payee?.rechtstraegerId ?? undefined,
                amountFrom: record.amount,
                amountTo: record.amount,
              })}
              target="_blank"
            >
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      );
    },
  },
  {
    title: 'Aktion',
    render: (text, record) => {
      const items: MenuProps['items'] = [
        {
          key: '1',
          label: (
            <Link to={generatePathToBookingSuggestionBuchungsmaskeWithTabsPage(record.receivedBankTransactionId)} target="_blank">
              Buchungsvorschlag bearbeiten
            </Link>
          ),
          icon: <FormOutlined />,
          disabled: record.booked,
        },
        {
          key: '2',
          label: (
            <Link to={generatePathToBookingDetailsPage(record.bookingSuggestionIdList)} target="_blank">
              Buchungsdetails ansehen
            </Link>
          ),
          icon: <ReadOutlined />,
          disabled: !record.booked,
        },
      ];

      return <ActionDropdown items={items} />;
    },
  },
];

export default receivedBankTransactionTableColumns;
