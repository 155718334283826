import * as Yup from 'yup';
import { entityIsRequired } from '../../../../../../components/message/validationMsg';
import { rechnungsAusstellerAbrDefZuweisenFormFields } from './rechnungsAusstellerAbrDefZuweisenFormMapper';

/* eslint-disable no-template-curly-in-string */
export const rechnungsAusstellerAbrDefZuweisenFormValidationSchema = Yup.object().shape({
  [rechnungsAusstellerAbrDefZuweisenFormFields.abrechnungsdefinitionId]: Yup.string().required(entityIsRequired('Abrechnungsdefinition')),
});

/* eslint-enable no-template-curly-in-string */
