import { generatePath } from 'react-router-dom';
import { mapToQueryString, QueryParams } from '../../../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../../../hooks/usePaginationQueryParams';
import { URI_VORSCHREIBUNG_PAGE } from '../../vorschreibungUriPaths';
import { VorschreibungListingFiltersFormValues } from './vorschreibungListingFiltersFormMapper';

enum VorschreibungQueryParamKey {
  AUFTRAGSART_TYPE = 'auftragsartType',
  AUSSTELLER_IBAN = 'ausstellerIban',
  AUSSTELLER_RECHTSTRAEGER_ID = 'ausstellerRechtstraegerId',
  BELEGNUMMER = 'belegnummer',
  BRUTTO_FROM = 'bruttoFrom',
  BRUTTO_TO = 'bruttoTo',
  CREATE_TS_FROM = 'createTsFrom',
  CREATE_TS_TO = 'createTsTo',
  DUE_DATE_FROM = 'dueDateFrom',
  DUE_DATE_TO = 'dueDateTo',
  EMPFAENGER_IBAN = 'empfaengerIban',
  EMPFAENGER_RECHTSTRAEGER_ID = 'empfaengerRechtstraegerId',
  ERSTELLER_MITARBEITER_ID_LIST = 'erstellerMitarbeiterIdList',
  FAKTURIERUNGSPERIODE_DATE_FROM = 'fakturierungsperiodeDateFrom',
  FAKTURIERUNGSPERIODE_DATE_TO = 'fakturierungsperiodeDateTo',
  KUNDENNUMMER = 'kundennummer',
  NETTO_FROM = 'nettoFrom',
  NETTO_TO = 'nettoTo',
  OBJEKT_ID = 'objektId',
  PAYMENT_METHOD = 'paymentMethod',
  RECHNUNGS_DATUM_FROM = 'rechnungsDatumFrom',
  RECHNUNGS_DATUM_TO = 'rechnungsDatumTo',
  SEPA_CREDITOR_ID = 'sepaCreditorId',
  SEPA_MANDATE_REFERENCE = 'sepaMandateReference',
  STATUS = 'status',
  UST_FROM = 'ustFrom',
  UST_TO = 'ustTo',
  VERTRAGSART = 'vertragsart',
}

export type TVorschreibungQueryParams = QueryParams<VorschreibungListingFiltersFormValues>;

export const updateQueryParams = (
  navigate: (path: string) => void,
  filters: TVorschreibungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToVorschreibungListingPage(filters, paginationParams));

const generatePathToVorschreibungListingPage = (queryParams: TVorschreibungQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  `${generatePath(URI_VORSCHREIBUNG_PAGE)}?${mapToQueryString({
    [VorschreibungQueryParamKey.AUFTRAGSART_TYPE]: queryParams.auftragsartType,
    [VorschreibungQueryParamKey.AUSSTELLER_IBAN]: queryParams.ausstellerIban,
    [VorschreibungQueryParamKey.AUSSTELLER_RECHTSTRAEGER_ID]: queryParams.ausstellerRechtstraegerId,
    [VorschreibungQueryParamKey.BELEGNUMMER]: queryParams.belegnummer,
    [VorschreibungQueryParamKey.BRUTTO_FROM]: queryParams.bruttoFrom,
    [VorschreibungQueryParamKey.BRUTTO_TO]: queryParams.bruttoTo,
    [VorschreibungQueryParamKey.CREATE_TS_FROM]: queryParams.createTsFrom,
    [VorschreibungQueryParamKey.CREATE_TS_TO]: queryParams.createTsTo,
    [VorschreibungQueryParamKey.DUE_DATE_FROM]: queryParams.dueDateFrom,
    [VorschreibungQueryParamKey.DUE_DATE_TO]: queryParams.dueDateTo,
    [VorschreibungQueryParamKey.EMPFAENGER_IBAN]: queryParams.empfaengerIban,
    [VorschreibungQueryParamKey.EMPFAENGER_RECHTSTRAEGER_ID]: queryParams.empfaengerRechtstraegerId,
    [VorschreibungQueryParamKey.ERSTELLER_MITARBEITER_ID_LIST]: queryParams.erstellerMitarbeiterIdList,
    [VorschreibungQueryParamKey.FAKTURIERUNGSPERIODE_DATE_FROM]: queryParams.fakturierungsperiodeDateFrom,
    [VorschreibungQueryParamKey.FAKTURIERUNGSPERIODE_DATE_TO]: queryParams.fakturierungsperiodeDateTo,
    [VorschreibungQueryParamKey.KUNDENNUMMER]: queryParams.kundennummer,
    [VorschreibungQueryParamKey.NETTO_FROM]: queryParams.nettoFrom,
    [VorschreibungQueryParamKey.NETTO_TO]: queryParams.nettoTo,
    [VorschreibungQueryParamKey.OBJEKT_ID]: queryParams.objektId,
    [VorschreibungQueryParamKey.PAYMENT_METHOD]: queryParams.paymentMethod,
    [VorschreibungQueryParamKey.RECHNUNGS_DATUM_FROM]: queryParams.rechnungsDatumFrom,
    [VorschreibungQueryParamKey.RECHNUNGS_DATUM_TO]: queryParams.rechnungsDatumTo,
    [VorschreibungQueryParamKey.SEPA_CREDITOR_ID]: queryParams.sepaCreditorId,
    [VorschreibungQueryParamKey.SEPA_MANDATE_REFERENCE]: queryParams.sepaMandateReference,
    [VorschreibungQueryParamKey.STATUS]: queryParams.status,
    [VorschreibungQueryParamKey.UST_FROM]: queryParams.ustFrom,
    [VorschreibungQueryParamKey.UST_TO]: queryParams.ustTo,
    [VorschreibungQueryParamKey.VERTRAGSART]: queryParams.vertragsart,
    ...paginationParams,
  })}`;
