import React, { FC } from 'react';
import { Empty, Table } from 'antd';
import { v4 as uuid4 } from 'uuid';
import zahlungsrueckstandTableColumns from './zahlungsrueckstandTableColumns';
import { SubAbrechnungEigentuemer, SubAbrZahlungsrueckstand } from '../../../../../types';
import { Spacer } from '../../../../../components/Grid';

type Props = {
  subAbrechnungEigentuemer: SubAbrechnungEigentuemer;
  objektId: string;
};

const ZahlungsrueckstandTab: FC<Props> = ({ subAbrechnungEigentuemer, objektId }) => {
  const zahlungsrueckstandList = subAbrechnungEigentuemer.zahlungsrueckstandList || [];

  return (
    <>
      <Spacer height={16} />
      <Table<SubAbrZahlungsrueckstand>
        size="small"
        locale={{
          emptyText: <Empty description={<span>Keine Zahlungsrückstände vorhanden</span>} />,
        }}
        columns={zahlungsrueckstandTableColumns(subAbrechnungEigentuemer, objektId)}
        dataSource={zahlungsrueckstandList}
        rowKey={() => uuid4()}
      />
    </>
  );
};

export default ZahlungsrueckstandTab;
