import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import { URI_BOOKING_JOURNAL_PAGE } from '../../features/BookingJournal/bookingJournalUriPaths';
import BookingJournalPage from './BookingJournalPage';

const bookingJournalPageRoute = (
  <Route path={URI_BOOKING_JOURNAL_PAGE} element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<BookingJournalPage />} />} />
);

export default bookingJournalPageRoute;
