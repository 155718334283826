import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { ObjektBaseFragmentDoc } from '../../../Objekt/gql/ObjektFragments.types';
import { UstVomAufwandFieldsFragmentDoc } from '../../../UstVomAufwand/gql/UstVomAufwandFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UstVomAufwandGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type UstVomAufwandGenerierlaufQuery = {
  getUstVomAufwandGenerierlauf: {
    data: {
      bisInklusive: string;
      countFehler: number;
      countNichtVerarbeitet: number;
      countVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierDatum: string;
      generierlaufId: string;
      vonInklusive: string;
      entryList: Array<{
        currentStep?: number | null;
        generierlaufEntryId: string;
        infoMessage?: string | null;
        monat: string;
        stepCount?: number | null;
        retry: boolean;
        ustVomAufwandByMitarbeiterId?: string | null;
        ustVomAufwandDeleted: boolean;
        ustVomAufwandDeletedBy?: string | null;
        ustVomAufwandDeletedTs?: string | null;
        errorList: Array<{ type: string; message: string }>;
        exitCode?: { text: string; value: Types.GenerierlaufEntryExitCode } | null;
        objekt: {
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          objektId: string;
          updatedByMitarbeiterId?: string | null;
          rechnungsAussteller: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          status: { text: string; value: Types.ObjektStatus; description?: string | null };
          verwaltungsart: { text: string; value: Types.Verwaltungsart };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        ustVomAufwand?: {
          belegnummer?: string | null;
          bisInklusive: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          fibuBuchungIdList: Array<string>;
          lastBuchungsdatum?: string | null;
          sumNetto: number;
          sumSteuer: number;
          sumUstVomAufwand: number;
          updatedByMitarbeiterId?: string | null;
          ustVomAufwandId: string;
          vonInklusive: string;
          buchungErrorList: Array<{ message: string; type: string }>;
          buchungStatus: { description?: string | null; text: string; value: Types.UstVomAufwandBuchungStatus };
          errorList: Array<{ message: string; type: string }>;
          objekt: {
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            objektId: string;
            updatedByMitarbeiterId?: string | null;
            rechnungsAussteller: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            status: { text: string; value: Types.ObjektStatus; description?: string | null };
            verwaltungsart: { text: string; value: Types.Verwaltungsart };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          status: { description?: string | null; text: string; value: Types.UstVomAufwandStatus };
          ustVomAufwandVertragList: Array<{
            fibuBuchungIdList: Array<string>;
            sumNetto: number;
            sumSteuer: number;
            sumUstVomAufwand: number;
            bestandseinheit: {
              bestandseinheitId: string;
              bezeichnung: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              objektId: string;
              status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
            repFondsKontoList: Array<{
              repFondsKontoId: string;
              sumNetto: number;
              sumSteuer: number;
              sumUstVomAufwand: number;
              aufwandsKontoList: Array<{
                aufwandsKontoId: string;
                sumNetto: number;
                sumSteuer: number;
                sumUstVomAufwand: number;
                aufteilungList: Array<{
                  anteilTopAufteilungsschluessel: number;
                  belegFileId: string;
                  belegId: string;
                  belegnummer: string;
                  buchungId: string;
                  buchungsdatum: string;
                  einheitskosten: number;
                  gesamtAnteilAufteilungsschluessel: number;
                  netto: number;
                  standingInvoicePartialAmountId?: string | null;
                  steuer: number;
                  ustVomAufwandAnteil: number;
                  ustVomAufwandSteuersatz: number;
                  aufteilungsschluessel: {
                    aufteilungsschluesselId: string;
                    bezeichnung: string;
                    bezugsbasisEditable?: boolean | null;
                    createTs: string;
                    createdBy?: string | null;
                    createdByMitarbeiterId?: string | null;
                    deletable?: boolean | null;
                    firmendatenId?: string | null;
                    kurzBezeichnung: string;
                    updatedByMitarbeiterId?: string | null;
                    bezugsbasis: { text: string; value: Types.Bezugsbasis };
                    masseinheit: { text: string; value: Types.Masseinheit };
                    status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  };
                  lieferant: {
                    createTs: string;
                    createdBy: string;
                    createdByMitarbeiterId?: string | null;
                    kurzBezeichnung: string;
                    rechtstraegerId: string;
                    status: { text: string; value: Types.RechtstraegerStatus };
                    type: { text: string; value: Types.RechtstraegerType };
                    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
                  };
                }>;
              }>;
            }>;
            vertrag: {
              bestandseinheitId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              objektId: string;
              vertragId: string;
              vertragsBeginn: string;
              status: { text: string; value: Types.VertragStatus; description?: string | null };
              vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
              vertragspartner: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            };
          }>;
          vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        warningList: Array<{ message: string; type: string }>;
      }>;
      errorList: Array<{ type: string; message: string }>;
      objekt: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        objektId: string;
        updatedByMitarbeiterId?: string | null;
        rechnungsAussteller: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        status: { text: string; value: Types.ObjektStatus; description?: string | null };
        verwaltungsart: { text: string; value: Types.Verwaltungsart };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      status: { text: string; value: Types.GenerierlaufStatus };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const UstVomAufwandGenerierlaufDocument = gql`
  query UstVomAufwandGenerierlauf($generierlaufId: ID!) {
    getUstVomAufwandGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        bisInklusive
        countFehler
        countNichtVerarbeitet
        countVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        entryList {
          currentStep
          errorList {
            type
            message
          }
          exitCode {
            text
            value
          }
          generierlaufEntryId
          infoMessage
          monat
          objekt {
            ...ObjektBase
          }
          stepCount
          retry
          ustVomAufwand {
            ...UstVomAufwandFields
          }
          ustVomAufwandByMitarbeiterId
          ustVomAufwandDeleted
          ustVomAufwandDeletedBy
          ustVomAufwandDeletedTs
          warningList {
            message
            type
          }
        }
        errorList {
          type
          message
        }
        generierDatum
        generierlaufId
        objekt {
          ...ObjektBase
        }
        status {
          text
          value
        }
        vonInklusive
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${ObjektBaseFragmentDoc}
  ${UstVomAufwandFieldsFragmentDoc}
`;
export function useUstVomAufwandGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<UstVomAufwandGenerierlaufQuery, UstVomAufwandGenerierlaufQueryVariables> &
    ({ variables: UstVomAufwandGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstVomAufwandGenerierlaufQuery, UstVomAufwandGenerierlaufQueryVariables>(UstVomAufwandGenerierlaufDocument, options);
}
export function useUstVomAufwandGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UstVomAufwandGenerierlaufQuery, UstVomAufwandGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstVomAufwandGenerierlaufQuery, UstVomAufwandGenerierlaufQueryVariables>(UstVomAufwandGenerierlaufDocument, options);
}
export function useUstVomAufwandGenerierlaufSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UstVomAufwandGenerierlaufQuery, UstVomAufwandGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstVomAufwandGenerierlaufQuery, UstVomAufwandGenerierlaufQueryVariables>(UstVomAufwandGenerierlaufDocument, options);
}
export type UstVomAufwandGenerierlaufQueryHookResult = ReturnType<typeof useUstVomAufwandGenerierlaufQuery>;
export type UstVomAufwandGenerierlaufLazyQueryHookResult = ReturnType<typeof useUstVomAufwandGenerierlaufLazyQuery>;
export type UstVomAufwandGenerierlaufSuspenseQueryHookResult = ReturnType<typeof useUstVomAufwandGenerierlaufSuspenseQuery>;
export type UstVomAufwandGenerierlaufQueryResult = Apollo.QueryResult<UstVomAufwandGenerierlaufQuery, UstVomAufwandGenerierlaufQueryVariables>;
