import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import { URI_DASHBOARD_BOOKING_DETAILS_PAGE } from '../../features/BookingDetails/bookingDetailsPaths';
import BookingDetailsPage from './BookingDetailsPage';

const bookingDetailsPageRoute = (
  <Route
    path={URI_DASHBOARD_BOOKING_DETAILS_PAGE}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<BookingDetailsPage />} />}
  />
);

export default bookingDetailsPageRoute;
