import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestartBookingSuggestionCreationGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartBookingSuggestionCreationGenerierlaufMutation = {
  actionRestartBookingSuggestionCreationGenerierlauf: {
    data: { generierlaufId: string };
    errorList: Array<{ type: string; message: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const RestartBookingSuggestionCreationGenerierlaufDocument = gql`
  mutation RestartBookingSuggestionCreationGenerierlauf($generierlaufId: ID!) {
    actionRestartBookingSuggestionCreationGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      errorList {
        type
        message
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useRestartBookingSuggestionCreationGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestartBookingSuggestionCreationGenerierlaufMutation,
    RestartBookingSuggestionCreationGenerierlaufMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestartBookingSuggestionCreationGenerierlaufMutation, RestartBookingSuggestionCreationGenerierlaufMutationVariables>(
    RestartBookingSuggestionCreationGenerierlaufDocument,
    options
  );
}
export type RestartBookingSuggestionCreationGenerierlaufMutationHookResult = ReturnType<
  typeof useRestartBookingSuggestionCreationGenerierlaufMutation
>;
export type RestartBookingSuggestionCreationGenerierlaufMutationResult = Apollo.MutationResult<RestartBookingSuggestionCreationGenerierlaufMutation>;
export type RestartBookingSuggestionCreationGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartBookingSuggestionCreationGenerierlaufMutation,
  RestartBookingSuggestionCreationGenerierlaufMutationVariables
>;
