import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import FormButtons from '../../../../components/Button/FormButtons';
import { accountLimitFormFields, accountLimitFormInitialValue, AccountLimitFormValues, mapFormValuesToAccountLimit } from './accountLimitFormMapper';
import { showSuccessMsgOther } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import {
  useChangeAccountLimitForFirmendatenBankDetailsMutation,
  useChangeAccountLimitForRechtstraegerBankDetailsMutation,
} from '../../gql/BankDetailsMutations.types';
import FormattedDecimalFormInput from '../../../../components/Input-antd/FormattedDecimalFormInput';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  bankDetailsId: string;
  firmendatenId?: string;
  rechtstraegerId?: string;
  accountLimit?: number | null;
};

const AccountLimitForm: FC<Props> = ({ onCancel, onSuccess, bankDetailsId, accountLimit, firmendatenId, rechtstraegerId }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'BankDetailsActionDataChangeAccountLimit'>('BankDetailsActionDataChangeAccountLimit');

  const msg = 'Überziehungsrahmen wurde geändert';

  const [runFDChangeAccountLimit, { loading: fdLoading }] = useChangeAccountLimitForFirmendatenBankDetailsMutation({
    onCompleted: () => {
      showSuccessMsgOther(msg);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runRAChangeAccountLimit, { loading: raLoading }] = useChangeAccountLimitForRechtstraegerBankDetailsMutation({
    onCompleted: () => {
      showSuccessMsgOther(msg);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<AccountLimitFormValues>
      initialValues={accountLimitFormInitialValue(accountLimit)}
      onSubmit={(values, { setSubmitting }) => {
        const input = mapFormValuesToAccountLimit(values);
        if (rechtstraegerId) {
          runRAChangeAccountLimit({
            variables: {
              rechtstraegerId,
              bankDetailsId,
              input,
            },
          }).finally(() => setSubmitting(false));
        } else if (firmendatenId) {
          runFDChangeAccountLimit({
            variables: {
              firmendatenId,
              bankDetailsId,
              input,
            },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithFieldHelp
            name={accountLimitFormFields.accountLimit}
            label="Überziehungsrahmen"
            fieldHelp={getFieldHelpText('BankDetailsActionDataChangeAccountLimit.accountLimit')}
          >
            <FormattedDecimalFormInput name={accountLimitFormFields.accountLimit} isCurrency min={0} style={{ width: '100%' }} />
          </FormItemWithFieldHelp>
          <FormButtons updateMode={false} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} isOkLoading={fdLoading || raLoading} />
        </Form>
      )}
    </Formik>
  );
};

export default AccountLimitForm;
