import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AddressesAndContactsFragmentDoc } from '../../../Rechtstraeger/AddressesAndContacts/gql/AddressesAndContactsFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FirmendatenOperatingSiteListQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
}>;

export type FirmendatenOperatingSiteListQuery = {
  getFirmendatenOperatingSiteList: {
    data: Array<{
      operatingSiteId: string;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      addressesAndContacts: {
        contactPersonList: Array<{
          contactPersonId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firstname: string;
          lastname: string;
          tagList: Array<string>;
          title?: string | null;
          titleTrailing?: string | null;
          contactList: Array<{
            contactId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            haupt?: boolean | null;
            tagList: Array<string>;
            type: string;
            wert: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          rechtstraeger?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        emailContactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        hauptAddress?: {
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        hauptEmailContact?: {
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        hauptPhoneContact?: {
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        postofficeBoxAddressList: Array<{
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        streetAddressList: Array<{
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        phoneContactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FirmendatenOperatingSiteQueryVariables = Types.Exact<{
  firmendatenId: Types.Scalars['String']['input'];
  operatingSiteId: Types.Scalars['ID']['input'];
}>;

export type FirmendatenOperatingSiteQuery = {
  getFirmendatenOperatingSite: {
    data: {
      operatingSiteId: string;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      addressesAndContacts: {
        contactPersonList: Array<{
          contactPersonId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firstname: string;
          lastname: string;
          tagList: Array<string>;
          title?: string | null;
          titleTrailing?: string | null;
          contactList: Array<{
            contactId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            haupt?: boolean | null;
            tagList: Array<string>;
            type: string;
            wert: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          rechtstraeger?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        emailContactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        hauptAddress?: {
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        hauptEmailContact?: {
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        hauptPhoneContact?: {
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        postofficeBoxAddressList: Array<{
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        streetAddressList: Array<{
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        phoneContactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const FirmendatenOperatingSiteListDocument = gql`
  query FirmendatenOperatingSiteList($firmendatenId: ID!) {
    getFirmendatenOperatingSiteList(firmendatenId: $firmendatenId) {
      data {
        addressesAndContacts {
          ...AddressesAndContacts
        }
        operatingSiteId
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AddressesAndContactsFragmentDoc}
`;
export function useFirmendatenOperatingSiteListQuery(
  baseOptions: Apollo.QueryHookOptions<FirmendatenOperatingSiteListQuery, FirmendatenOperatingSiteListQueryVariables> &
    ({ variables: FirmendatenOperatingSiteListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenOperatingSiteListQuery, FirmendatenOperatingSiteListQueryVariables>(
    FirmendatenOperatingSiteListDocument,
    options
  );
}
export function useFirmendatenOperatingSiteListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenOperatingSiteListQuery, FirmendatenOperatingSiteListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenOperatingSiteListQuery, FirmendatenOperatingSiteListQueryVariables>(
    FirmendatenOperatingSiteListDocument,
    options
  );
}
export function useFirmendatenOperatingSiteListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FirmendatenOperatingSiteListQuery, FirmendatenOperatingSiteListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenOperatingSiteListQuery, FirmendatenOperatingSiteListQueryVariables>(
    FirmendatenOperatingSiteListDocument,
    options
  );
}
export type FirmendatenOperatingSiteListQueryHookResult = ReturnType<typeof useFirmendatenOperatingSiteListQuery>;
export type FirmendatenOperatingSiteListLazyQueryHookResult = ReturnType<typeof useFirmendatenOperatingSiteListLazyQuery>;
export type FirmendatenOperatingSiteListSuspenseQueryHookResult = ReturnType<typeof useFirmendatenOperatingSiteListSuspenseQuery>;
export type FirmendatenOperatingSiteListQueryResult = Apollo.QueryResult<
  FirmendatenOperatingSiteListQuery,
  FirmendatenOperatingSiteListQueryVariables
>;
export const FirmendatenOperatingSiteDocument = gql`
  query FirmendatenOperatingSite($firmendatenId: String!, $operatingSiteId: ID!) {
    getFirmendatenOperatingSite(firmendatenId: $firmendatenId, operatingSiteId: $operatingSiteId) {
      data {
        addressesAndContacts {
          ...AddressesAndContacts
        }
        operatingSiteId
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AddressesAndContactsFragmentDoc}
`;
export function useFirmendatenOperatingSiteQuery(
  baseOptions: Apollo.QueryHookOptions<FirmendatenOperatingSiteQuery, FirmendatenOperatingSiteQueryVariables> &
    ({ variables: FirmendatenOperatingSiteQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenOperatingSiteQuery, FirmendatenOperatingSiteQueryVariables>(FirmendatenOperatingSiteDocument, options);
}
export function useFirmendatenOperatingSiteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenOperatingSiteQuery, FirmendatenOperatingSiteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenOperatingSiteQuery, FirmendatenOperatingSiteQueryVariables>(FirmendatenOperatingSiteDocument, options);
}
export function useFirmendatenOperatingSiteSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FirmendatenOperatingSiteQuery, FirmendatenOperatingSiteQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenOperatingSiteQuery, FirmendatenOperatingSiteQueryVariables>(FirmendatenOperatingSiteDocument, options);
}
export type FirmendatenOperatingSiteQueryHookResult = ReturnType<typeof useFirmendatenOperatingSiteQuery>;
export type FirmendatenOperatingSiteLazyQueryHookResult = ReturnType<typeof useFirmendatenOperatingSiteLazyQuery>;
export type FirmendatenOperatingSiteSuspenseQueryHookResult = ReturnType<typeof useFirmendatenOperatingSiteSuspenseQuery>;
export type FirmendatenOperatingSiteQueryResult = Apollo.QueryResult<FirmendatenOperatingSiteQuery, FirmendatenOperatingSiteQueryVariables>;
