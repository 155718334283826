import { FC } from 'react';
import { useBookingSuggestionBuchungszeileChangeHistoryListQuery } from '../gql/BookingSuggestionQueries.types';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';

type Props = {
  bookingSuggestionId: string;
  buchungszeileId: string;
};

const BuchungszeileChangeHistoryListingTable: FC<Props> = ({ bookingSuggestionId, buchungszeileId }) => {
  const { data, loading } = useBookingSuggestionBuchungszeileChangeHistoryListQuery({
    variables: {
      bookingSuggestionId,
      buchungszeileId,
    },
  });
  const historyList = data?.getBookingSuggestionBuchungszeileChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="aufteilungsschluessel" />;
};

export default BuchungszeileChangeHistoryListingTable;
