import React, { FC, useEffect } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { FieldArray, FormikProps } from 'formik';
import { Form } from 'formik-antd';
import { Spacer } from '../../../../components/Grid';
import { FormikFieldHookProps } from '../../../../helpers/formikHelper';
import Errors from '../../../shared/Errors';
import FormButtons from '../../../../components/Button/FormButtons';
import { AbrechnungsdefinitionType } from '../../../../types';
import FieldHelpIconWithTooltip from '../../../../components/FieldHelpIcon/FieldHelpIconWithTooltip';
import {
  abrDefAbrKreisFormInitialValues,
  AbrDefAbrKreisFormValues,
  abrDefAbrKreisListingFormFields,
  AbrDefAbrKreisListingFormValues,
} from '../../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormMapper';
import AbrDefTemplateAbrKreisTable from './AbrDefTemplateAbrKreisTable';

type Props = {
  abrDefType: AbrechnungsdefinitionType;
  formikProps: FormikProps<AbrDefAbrKreisListingFormValues>;
  onBack: () => void;
  backBtnText?: string;
  formButtons?: (formikProps: FormikProps<AbrDefAbrKreisListingFormValues>) => React.ReactNode;
  onValuesChange?: (isDirty: boolean) => void;
  onAbrechnungskreisChange?: (newAbrechnungskreisId: string, fieldProps: FormikFieldHookProps<string>, onSuccess: () => void) => void;
  onZuweisungDelete?: (abrechnungskreisId: string, onDelete: () => void) => void;
  fieldHelp?: string | null;
};

const AbrDefTemplateAbrKreisListingFormContent: FC<Props> = ({
  abrDefType,
  formikProps,
  onBack,
  backBtnText,
  formButtons,
  onValuesChange,
  onAbrechnungskreisChange,
  onZuweisungDelete,
  fieldHelp,
}) => {
  useEffect(() => {
    // formikProps.dirty ? onValuesChange?.(formikProps.dirty) : onValuesChange?.(undefined);
    onValuesChange?.(formikProps.dirty);
  }, [formikProps.dirty, onValuesChange]);

  return (
    <Form layout="vertical">
      <Row style={{ width: '100%', flexFlow: 'column' }}>
        <Col>
          <FieldArray
            name={abrDefAbrKreisListingFormFields.abrechdefAbrechkreisList}
            render={(arrayHelpers) => (
              <>
                <Typography.Title level={5}>
                  Abrechnungskreis-Zuweisung
                  <FieldHelpIconWithTooltip fieldHelp={fieldHelp} />
                </Typography.Title>
                <Button
                  style={{ marginBottom: 12, float: 'right' }}
                  onClick={() => {
                    arrayHelpers.push({
                      ...abrDefAbrKreisFormInitialValues,
                    });
                  }}
                >
                  Abrechnungskreis hinzufügen
                </Button>
                <AbrDefTemplateAbrKreisTable
                  abrDefType={abrDefType}
                  formikProps={formikProps}
                  arrayHelpers={arrayHelpers}
                  onAbrechnungskreisChange={onAbrechnungskreisChange}
                  onZuweisungDelete={onZuweisungDelete}
                />
                {!!formikProps.errors.abrechdefAbrechkreisList && !!formikProps.touched.abrechdefAbrechkreisList && (
                  <Errors<AbrDefAbrKreisFormValues>
                    errors={formikProps.errors.abrechdefAbrechkreisList}
                    values={['abrechnungskreisId', 'aufteilungsschluesselId', 'vorschreibungspositionId']}
                  />
                )}
              </>
            )}
          />
        </Col>
      </Row>

      <Spacer height={48} />

      {!formButtons ? (
        <FormButtons
          onCancel={onBack}
          cancelText={backBtnText || 'Zurück'}
          isOkDisabled={formikProps.isSubmitting}
          updateMode={false}
          okText="Weiter"
        />
      ) : (
        formButtons(formikProps)
      )}
    </Form>
  );
};

export default AbrDefTemplateAbrKreisListingFormContent;
