import { AbrechnungsdefinitionType } from '../../../types';

export type HeOrSubAbrDefOutputOptionsFieldHelpNames = {
  rueckstaendeInAbrechnungsergebnis?: string | null;
  displayKontonummer?: string | null;
  rueckstaendeAnzeigen?: string | null;
};

export type BkOrWeAbrDefTopOutputOptionsFieldHelpNames = {
  considerRueckstand?: string | null;
  displayDueDate?: string | null;
  displayRueckstandOtherPersons?: string | null;
  displayZahlungsverlauf?: string | null;
  dueDateDisplayOptionMieter?: {
    type?: string | null;
    month?: string | null;
  };
  dueDateDisplayOptionEigentuemerGuthaben?: {
    type?: string | null;
    month?: string | null;
  };
  dueDateDisplayOptionEigentuemerNachzahlung?: {
    type?: string | null;
    month?: string | null;
  };
  rueckstandOtherPersonsThreshold?: string | null;
};

export type BkOrWeAbrDefObjektOutputOptionsFieldHelpNames = {
  consolidateEinnahmen?: string | null;
  buchungsdetails?: string | null;
  displayEinnahmen?: string | null;
  displayKontonummer?: string | null;
};

export type BkOrWeAbrDefFieldHelpNames = {
  fieldHelpAbrDefAbrechkreisList?: string | null;
  fieldHelpAbrDefKontoAbrechkreisList?: string | null;
  fieldHelpNamesObjekt: BkOrWeAbrDefObjektOutputOptionsFieldHelpNames;
  fieldHelpNamesTop: BkOrWeAbrDefTopOutputOptionsFieldHelpNames;
};

export type HeOrSubAbrDefFieldHelpNames = {
  fieldHelpAbrDefAbrechkreisList?: string | null;
  fieldHelpAbrDefKontoAbrechkreisList?: string | null;
  fieldHelpNamesOutputOptions: HeOrSubAbrDefOutputOptionsFieldHelpNames;
};

export const getFieldHelpTextBaseOnAbrDefType = (
  type: AbrechnungsdefinitionType | null,
  fieldHelpTextForHe?: string | null,
  fieldHelpTextForBk?: string | null,
  fieldHelpTextForWe?: string | null,
  fieldHelpTextForSub?: string | null
) => {
  switch (type) {
    case AbrechnungsdefinitionType.HeAbrechnung:
      return fieldHelpTextForHe;
    case AbrechnungsdefinitionType.BkAbrechnung:
      return fieldHelpTextForBk;
    case AbrechnungsdefinitionType.WeAbrechnung:
      return fieldHelpTextForWe;
    case AbrechnungsdefinitionType.SubAbrechnung:
      return fieldHelpTextForSub;
    default:
      return null;
  }
};
