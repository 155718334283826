import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { useMitarbeiterListByFirmendatenIdQuery } from '../gql/MitarbeiterQueries.types';
import MitarbeiterActions from './MitarbeiterActions';
import { Spacer } from '../../../components/Grid';
import mitarbeiterTableColumns from './mitarbeiterTableColumns';
import TableWithColSelector from '../../../components/Table/TableWithColSelector/TableWithColSelector';
import { MitarbeiterFragment } from '../gql/MitarbeiterFragments.types';

type MitarbeiterTableProps = {
  kundenSystemId: string;
  firmendatenId: string;
  filterIdentifier?: string;
};

const MitarbeiterTable: FC<MitarbeiterTableProps> = ({ kundenSystemId, firmendatenId, filterIdentifier }) => {
  const { data, refetch } = useMitarbeiterListByFirmendatenIdQuery({
    variables: {
      firmendatenId,
      includeUser: true,
    },
  });
  const mitarbeiterList = data?.getMitarbeiterListByFirmendatenId.data;
  const loading = !mitarbeiterList;

  return (
    <>
      <Row>
        <Col flex="auto" />
        <Col>
          <MitarbeiterActions kundenSystemId={kundenSystemId} firmendatenId={firmendatenId} refetch={refetch} />
        </Col>
      </Row>
      <Spacer />
      <TableWithColSelector<MitarbeiterFragment>
        size="small"
        loading={loading}
        columns={mitarbeiterTableColumns}
        dataSource={mitarbeiterList}
        rowKey={(record) => record.mitarbeiterId}
        filterIdentifier={filterIdentifier ?? 'verwaltung-employees'}
      />
    </>
  );
};

export default MitarbeiterTable;
