import React, { FC } from 'react';
import { Collapse } from 'antd';
import { DeviationStyledPanel } from '../../Styled/Deviation.style';
import { TDeviationListGroupedByUrsacheAndSourceEntity } from '../../deviation.model';
import DeviationListGroupedBySourceEntity from '../GroupedBySourceEntity/DeviationListGroupedBySourceEntity';

type Props = {
  deviationUrsacheGroupList: TDeviationListGroupedByUrsacheAndSourceEntity[];
  loading: boolean;
  showSourceEntity?: boolean;
  showUrsache?: boolean;
  altTextForMultipleReferenceOfCause?: string;
};

const DeviationListGroupedByUrsache: FC<Props> = ({
  deviationUrsacheGroupList,
  loading,
  showSourceEntity,
  showUrsache = true,
  altTextForMultipleReferenceOfCause,
}) => {
  return (
    <Collapse bordered={false}>
      {deviationUrsacheGroupList.map((deviationUrsacheGroup) => {
        const { ursache } = deviationUrsacheGroup;

        return showUrsache ? (
          <DeviationStyledPanel key={ursache.value} header={ursache.text}>
            <DeviationListGroupedBySourceEntity
              deviationSourceEntityGroupList={deviationUrsacheGroup.deviationListGroupedBySourceEntity}
              loading={loading}
              showSourceEntity={showSourceEntity}
              altTextForMultipleReferenceOfCause={altTextForMultipleReferenceOfCause}
            />
          </DeviationStyledPanel>
        ) : (
          <DeviationListGroupedBySourceEntity
            deviationSourceEntityGroupList={deviationUrsacheGroup.deviationListGroupedBySourceEntity}
            loading={loading}
            showSourceEntity={showSourceEntity}
            altTextForMultipleReferenceOfCause={altTextForMultipleReferenceOfCause}
          />
        );
      })}
    </Collapse>
  );
};

export default DeviationListGroupedByUrsache;
