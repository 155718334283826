import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import { PostItFragmentDoc } from '../../../../Rechtstraeger/PostIt/gql/PostItFragments.types';
export type VertragInfoFeldWeiterePersonFragment = {
  text?: string | null;
  natuerlichePerson: { rechtstraegerId: string; kurzBezeichnung: string; lastname: string; firstname: string };
};

export type VertragInfoFeldKuendigungsinfosFragment = {
  kuendigungBis: string;
  kuendigungsverzichtBestandgeberBis?: string | null;
  kuendigungsverzichtBestandnehmerBis?: string | null;
  bestandnehmerKuendigungsfrist?: number | null;
  kuendigungJeweilsZum?: string | null;
  kuendigungMoeglichZum?: string | null;
  gerichtlicheKuendigung?: boolean | null;
  kuendigungErfolgtAm?: string | null;
  kuendigungWirksamZum?: string | null;
  kuendigungDurch?: { text: string; value: Types.KuendigungDurch } | null;
};

export type VertragInfoFeld_VertragInfoFeldKuendigungsinfos_Fragment = {
  __typename: 'VertragInfoFeldKuendigungsinfos';
  vertragInfoFeldId: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  fileIdList: Array<string>;
  kuendigungBis: string;
  kuendigungsverzichtBestandgeberBis?: string | null;
  kuendigungsverzichtBestandnehmerBis?: string | null;
  bestandnehmerKuendigungsfrist?: number | null;
  kuendigungJeweilsZum?: string | null;
  kuendigungMoeglichZum?: string | null;
  gerichtlicheKuendigung?: boolean | null;
  kuendigungErfolgtAm?: string | null;
  kuendigungWirksamZum?: string | null;
  postIt?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    immerAnzeigen: boolean;
    postItId: string;
    text: string;
    titel: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  status: { text: string; value: Types.InfoFeldStatus };
  type: { text: string; value: Types.VertragInfoFeldType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  kuendigungDurch?: { text: string; value: Types.KuendigungDurch } | null;
};

export type VertragInfoFeld_VertragInfoFeldWeiterePerson_Fragment = {
  __typename: 'VertragInfoFeldWeiterePerson';
  vertragInfoFeldId: string;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  fileIdList: Array<string>;
  text?: string | null;
  postIt?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    immerAnzeigen: boolean;
    postItId: string;
    text: string;
    titel: string;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  status: { text: string; value: Types.InfoFeldStatus };
  type: { text: string; value: Types.VertragInfoFeldType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  natuerlichePerson: { rechtstraegerId: string; kurzBezeichnung: string; lastname: string; firstname: string };
};

export type VertragInfoFeldFragment =
  | VertragInfoFeld_VertragInfoFeldKuendigungsinfos_Fragment
  | VertragInfoFeld_VertragInfoFeldWeiterePerson_Fragment;

export const VertragInfoFeldWeiterePersonFragmentDoc = gql`
  fragment VertragInfoFeldWeiterePerson on VertragInfoFeldWeiterePerson {
    natuerlichePerson {
      rechtstraegerId
      kurzBezeichnung
      lastname
      firstname
    }
    text
  }
`;
export const VertragInfoFeldKuendigungsinfosFragmentDoc = gql`
  fragment VertragInfoFeldKuendigungsinfos on VertragInfoFeldKuendigungsinfos {
    kuendigungBis
    kuendigungsverzichtBestandgeberBis
    kuendigungsverzichtBestandnehmerBis
    bestandnehmerKuendigungsfrist
    kuendigungJeweilsZum
    kuendigungMoeglichZum
    gerichtlicheKuendigung
    kuendigungErfolgtAm
    kuendigungWirksamZum
    kuendigungDurch {
      text
      value
    }
  }
`;
export const VertragInfoFeldFragmentDoc = gql`
  fragment VertragInfoFeld on AbstractVertragInfoFeld {
    __typename
    vertragInfoFeldId
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    fileIdList
    postIt {
      ...PostIt
    }
    status {
      text
      value
    }
    type {
      text
      value
    }
    warningList {
      attribute
      message
      type
    }
    ... on VertragInfoFeldWeiterePerson {
      ...VertragInfoFeldWeiterePerson
    }
    ... on VertragInfoFeldKuendigungsinfos {
      ...VertragInfoFeldKuendigungsinfos
    }
  }
  ${PostItFragmentDoc}
  ${VertragInfoFeldWeiterePersonFragmentDoc}
  ${VertragInfoFeldKuendigungsinfosFragmentDoc}
`;
