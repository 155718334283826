import React, { FC } from 'react';
import { Input } from 'formik-antd';
import { postItFormFields } from '../PostItFormMapper';
import FormItemWithFieldHelp from '../../../Form/FormItemWithFieldHelp';

type Props = {
  inputWidth: string;
  fieldHelp?: string | null;
};

const PostItFormItemTitel: FC<Props> = ({ inputWidth, fieldHelp }) => (
  <FormItemWithFieldHelp name={postItFormFields.titel} style={{ width: inputWidth, paddingBottom: 0 }} fieldHelp={fieldHelp} label="Notiz-Titel">
    <Input id={postItFormFields.titel} name={postItFormFields.titel} placeholder="Fügen Sie den Notiz-Titel hinzu..." maxLength={100} />
  </FormItemWithFieldHelp>
);

export default PostItFormItemTitel;
