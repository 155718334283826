import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { VorschreibungMailDeliveryFieldsFragmentDoc } from '../../../EmailDelivery/VorschreibungMailDelivery/gql/VorschreibungMailDeliveryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VorschreibungMailDeliveryDetermineRecipientListGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type VorschreibungMailDeliveryDetermineRecipientListGenerierlaufQuery = {
  getVorschreibungMailDeliveryDetermineRecipientListGenerierlauf: {
    data: {
      countFehler: number;
      countNichtVerarbeitet: number;
      countVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierlaufId: string;
      generierDatum: string;
      errorList: Array<{ message: string; type: string }>;
      status: { text: string; value: Types.GenerierlaufStatus; description?: string | null };
      warningList: Array<{ message: string; type: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  emailAddress?: Types.InputMaybe<Types.Scalars['String']['input']>;
  vertragspartnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  recipientId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  order?: Types.InputMaybe<Types.VorschreibungMailDeliveryDetermineRecipientGenLaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListQuery = {
  getVorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryList: {
    data: {
      contentList: Array<{
        generierlaufEntryId: string;
        infoMessage?: string | null;
        retry: boolean;
        errorList: Array<{ message: string; type: string }>;
        exitCode?: { text: string; value: Types.GenerierlaufEntryExitCode } | null;
        vorschreibungMailDelivery?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          emailAddress: string;
          emailDeliveryDefinitionBezeichnung: string;
          emailDeliveryDefinitionId: string;
          mailId?: string | null;
          original: boolean;
          sender?: string | null;
          sentTs?: string | null;
          shouldNeverSend: boolean;
          shouldSend: boolean;
          updatedByMitarbeiterId?: string | null;
          vorschreibungMailDeliveryId: string;
          emailDeliveryStatus: { text: string; value: Types.EmailDeliveryStatus; description?: string | null };
          fakturierungsperiode: { jahr: number; monat: number };
          objektBestandseinheitVertragList: Array<{
            vertragId: string;
            bestandseinheit: {
              bestandseinheitId: string;
              bezeichnung: string;
              address: {
                addressId: string;
                city: string;
                cityAdditionalInformation?: string | null;
                countryCodeIso2: string;
                houseEntranceApartmentNumber?: string | null;
                postofficeBoxNumber?: string | null;
                postofficeBoxZipCode?: string | null;
                street?: string | null;
                type: Types.AddressType;
                zipCode?: string | null;
              };
            };
            objekt: { kurzBezeichnung: string; objektId: string };
          }>;
          recipient: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
          vertragspartner: { kurzBezeichnung: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const VorschreibungMailDeliveryDetermineRecipientListGenerierlaufDocument = gql`
  query VorschreibungMailDeliveryDetermineRecipientListGenerierlauf($generierlaufId: ID!) {
    getVorschreibungMailDeliveryDetermineRecipientListGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countNichtVerarbeitet
        countVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        generierlaufId
        generierDatum
        status {
          text
          value
          description
        }
        warningList {
          message
          type
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useVorschreibungMailDeliveryDetermineRecipientListGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<
    VorschreibungMailDeliveryDetermineRecipientListGenerierlaufQuery,
    VorschreibungMailDeliveryDetermineRecipientListGenerierlaufQueryVariables
  > &
    ({ variables: VorschreibungMailDeliveryDetermineRecipientListGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VorschreibungMailDeliveryDetermineRecipientListGenerierlaufQuery,
    VorschreibungMailDeliveryDetermineRecipientListGenerierlaufQueryVariables
  >(VorschreibungMailDeliveryDetermineRecipientListGenerierlaufDocument, options);
}
export function useVorschreibungMailDeliveryDetermineRecipientListGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VorschreibungMailDeliveryDetermineRecipientListGenerierlaufQuery,
    VorschreibungMailDeliveryDetermineRecipientListGenerierlaufQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VorschreibungMailDeliveryDetermineRecipientListGenerierlaufQuery,
    VorschreibungMailDeliveryDetermineRecipientListGenerierlaufQueryVariables
  >(VorschreibungMailDeliveryDetermineRecipientListGenerierlaufDocument, options);
}
export function useVorschreibungMailDeliveryDetermineRecipientListGenerierlaufSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VorschreibungMailDeliveryDetermineRecipientListGenerierlaufQuery,
        VorschreibungMailDeliveryDetermineRecipientListGenerierlaufQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    VorschreibungMailDeliveryDetermineRecipientListGenerierlaufQuery,
    VorschreibungMailDeliveryDetermineRecipientListGenerierlaufQueryVariables
  >(VorschreibungMailDeliveryDetermineRecipientListGenerierlaufDocument, options);
}
export type VorschreibungMailDeliveryDetermineRecipientListGenerierlaufQueryHookResult = ReturnType<
  typeof useVorschreibungMailDeliveryDetermineRecipientListGenerierlaufQuery
>;
export type VorschreibungMailDeliveryDetermineRecipientListGenerierlaufLazyQueryHookResult = ReturnType<
  typeof useVorschreibungMailDeliveryDetermineRecipientListGenerierlaufLazyQuery
>;
export type VorschreibungMailDeliveryDetermineRecipientListGenerierlaufSuspenseQueryHookResult = ReturnType<
  typeof useVorschreibungMailDeliveryDetermineRecipientListGenerierlaufSuspenseQuery
>;
export type VorschreibungMailDeliveryDetermineRecipientListGenerierlaufQueryResult = Apollo.QueryResult<
  VorschreibungMailDeliveryDetermineRecipientListGenerierlaufQuery,
  VorschreibungMailDeliveryDetermineRecipientListGenerierlaufQueryVariables
>;
export const VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListDocument = gql`
  query VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryList(
    $generierlaufId: ID!
    $objektId: String
    $emailAddress: String
    $vertragspartnerId: String
    $recipientId: String
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $order: VorschreibungMailDeliveryDetermineRecipientGenLaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getVorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryList(
      generierlaufId: $generierlaufId
      objektId: $objektId
      emailAddress: $emailAddress
      vertragspartnerId: $vertragspartnerId
      recipientId: $recipientId
      exitCodeList: $exitCodeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          errorList {
            message
            type
          }
          exitCode {
            text
            value
          }
          generierlaufEntryId
          infoMessage
          retry
          vorschreibungMailDelivery {
            ...VorschreibungMailDeliveryFields
          }
          warningList {
            message
            type
          }
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${VorschreibungMailDeliveryFieldsFragmentDoc}
`;
export function useVorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListQuery,
    VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListQueryVariables
  > &
    ({ variables: VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListQuery,
    VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListQueryVariables
  >(VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListDocument, options);
}
export function useVorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListQuery,
    VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListQuery,
    VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListQueryVariables
  >(VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListDocument, options);
}
export function useVorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListQuery,
        VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListQuery,
    VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListQueryVariables
  >(VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListDocument, options);
}
export type VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListQueryHookResult = ReturnType<
  typeof useVorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListQuery
>;
export type VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListLazyQueryHookResult = ReturnType<
  typeof useVorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListLazyQuery
>;
export type VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListSuspenseQueryHookResult = ReturnType<
  typeof useVorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListSuspenseQuery
>;
export type VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListQueryResult = Apollo.QueryResult<
  VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListQuery,
  VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryListQueryVariables
>;
