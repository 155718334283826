import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Form, Select, Switch } from 'formik-antd';
import { Col, Divider, Row, Space, Typography } from 'antd';
import DebouncedTextSearch from '../../../../components/Input-antd/DebouncedTextSearch';
import FieldHelpDownloadButton from '../FieldHelpDownloadButton';
import { FieldHelpService } from '../../useFieldHelpList';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import { fieldHelpListingFiltersFormFields, FieldHelpListingFiltersFormValues } from './fieldHelpListingFiltersFormMapper';

type Props = {
  formikProps: FormikProps<FieldHelpListingFiltersFormValues>;
};

const FieldHelpListingFilters: FC<Props> = ({ formikProps }) => (
  <>
    <Space direction="horizontal" size={32} style={{ justifyContent: 'space-between', alignItems: 'baseline', width: '100%' }}>
      <Form>
        <Row justify="end" gutter={16}>
          <Col>
            <FormItemWithoutColon name={fieldHelpListingFiltersFormFields.selector} label="Feld">
              <DebouncedTextSearch
                name={fieldHelpListingFiltersFormFields.selector}
                onChange={(value) => {
                  formikProps.setFieldValue(fieldHelpListingFiltersFormFields.selector, value);
                  formikProps.submitForm();
                }}
                value={formikProps.values.selector}
              />
            </FormItemWithoutColon>
          </Col>
          <Col>
            <FormItemWithoutColon name={fieldHelpListingFiltersFormFields.fieldHelp} label="Feldhilfe">
              <DebouncedTextSearch
                name={fieldHelpListingFiltersFormFields.fieldHelp}
                onChange={(value) => {
                  formikProps.setFieldValue(fieldHelpListingFiltersFormFields.fieldHelp, value);
                  formikProps.submitForm();
                }}
                value={formikProps.values.fieldHelp}
              />
            </FormItemWithoutColon>
          </Col>
          <Col>
            <FormItemWithoutColon name={fieldHelpListingFiltersFormFields.deprecated} label="Nicht mehr in Verwendung">
              <Space size={5}>
                <Switch name={fieldHelpListingFiltersFormFields.deprecated} size="small" onChange={formikProps.submitForm} />
                <Typography.Text>{formikProps.values.deprecated ? 'Ja' : 'Alle'}</Typography.Text>
              </Space>
            </FormItemWithoutColon>
          </Col>
          <Col>
            <FormItemWithoutColon name={fieldHelpListingFiltersFormFields.services} label="Service">
              <>
                <Select
                  name={fieldHelpListingFiltersFormFields.services}
                  mode="multiple"
                  size="small"
                  style={{ width: '280px' }}
                  onChange={formikProps.submitForm}
                >
                  {Object.entries(FieldHelpService).map(([key, value]) => (
                    <Select.Option key={value} value={value}>
                      {key}
                    </Select.Option>
                  ))}
                </Select>
              </>
            </FormItemWithoutColon>
          </Col>
        </Row>
      </Form>
      <FieldHelpDownloadButton />
    </Space>
    <Divider style={{ marginTop: '8px', marginBottom: '24px' }} />
  </>
);

export default FieldHelpListingFilters;
