import React, { FC } from 'react';
import { Button, Drawer, Dropdown, MenuProps, Modal } from 'antd';
import { EditOutlined, EllipsisOutlined, SendOutlined } from '@ant-design/icons';
import { ArchiveIcon } from '@radix-ui/react-icons';
import { useToggle } from '../../../hooks/useToggle';
import { Ticket, TicketBase } from '../../../types';
import TicketForm from '../Form/TicketForm';
import useTicketStatusMenuItems from '../shared/useTicketStatusMenuItems';
import { radixActionStyles } from '../../../helpers/radixIconsStyles';
import { useArchiveTicketMutation, useUnarchiveTicketMutation } from '../gql/TicketMutations.types';
import { showSuccessMsgArchived, showSuccessMsgUnarchived } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { isTicketArchived, isTicketParerentArchived } from '../ticketHelpers';
import TicketSendEmailForm from '../Form/TicketSendEmailForm';

type Props = {
  ticket: Ticket;
  onAction: () => void;
};

const TicketCardActions: FC<Props> = ({ ticket, onAction }) => {
  const [isUpdateDrawerOpen, toggleIsUpdateDrawerOpen] = useToggle();
  const [isSendEmailDrawerOpen, toggleIsSendEmailDrawerOpen] = useToggle();
  const { statusMenuItems } = useTicketStatusMenuItems(ticket, onAction);
  const entity = `Aufgabe`;

  const isArchived = isTicketArchived(ticket);
  const isParentArchived = isTicketParerentArchived(ticket);

  const [runArchive] = useArchiveTicketMutation({
    onCompleted: () => {
      showSuccessMsgArchived(entity);
      onAction();
    },
    variables: {
      ticketId: ticket.ticketId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUnarchive] = useUnarchiveTicketMutation({
    onCompleted: () => {
      showSuccessMsgUnarchived(entity);
      onAction();
    },
    variables: {
      ticketId: ticket.ticketId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Bearbeiten',
      onClick: toggleIsUpdateDrawerOpen,
      icon: <EditOutlined />,
      disabled: isArchived,
    },
    {
      key: '2',
      label: 'E-Mail senden',
      onClick: toggleIsSendEmailDrawerOpen,
      icon: <SendOutlined />,
      disabled: false, // TODO: if no email integration present
    },
    {
      key: '3',
      label: isArchived ? 'Reaktivieren' : 'Archivieren',
      onClick: isArchived ? showConfirmUnarchive(runUnarchive, ticket) : showConfirmArchive(runArchive, ticket),
      icon: <ArchiveIcon style={radixActionStyles} />,
      disabled: isParentArchived,
    },
    ...statusMenuItems,
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
      </Dropdown>
      <Drawer title="Aufgabe bearbeiten" width={720} onClose={toggleIsUpdateDrawerOpen} open={isUpdateDrawerOpen} destroyOnClose>
        <TicketForm
          ticketId={ticket.ticketId}
          isSubticket={ticket.isSubTicket}
          onSuccess={() => {
            toggleIsUpdateDrawerOpen(false);
            onAction();
          }}
          onCancel={toggleIsUpdateDrawerOpen}
        />
      </Drawer>
      <Drawer title="E-Mail senden" width={720} onClose={toggleIsSendEmailDrawerOpen} open={isSendEmailDrawerOpen} destroyOnClose>
        <TicketSendEmailForm
          ticket={ticket}
          isSubticket={ticket.isSubTicket}
          onSuccess={() => {
            toggleIsSendEmailDrawerOpen(false);
            onAction();
          }}
          onCancel={toggleIsSendEmailDrawerOpen}
        />
      </Drawer>
    </>
  );
};

const showConfirmArchive = (runArchive: () => void, ticket: TicketBase) => () => {
  Modal.confirm({
    title: `Möchten Sie die Aufgabe archivieren?`,
    content: `${ticket.title}`,
    okText: 'Archivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runArchive();
    },
  });
};

const showConfirmUnarchive = (runUnarchive: () => void, ticket: TicketBase) => () => {
  Modal.confirm({
    title: `Möchten Sie die Aufgabe reaktivieren?`,
    content: `${ticket.title}`,
    okText: 'Reaktivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runUnarchive();
    },
  });
};

export default TicketCardActions;
