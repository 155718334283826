import { generatePath } from 'react-router';
import { URI_VERARBEITUNG_PAGE } from '../../constants/appUriPaths';
import {
  isVerarbeitungTypeApplyVertragVposIndexedValue,
  isVerarbeitungTypeBkObjektAbrechnung,
  isVerarbeitungTypeBkTopAbrechnung,
  isVerarbeitungTypeBookingSuggestionCreation,
  isVerarbeitungTypeBookingSuggestionVerbuchen,
  isVerarbeitungTypeBudgeting,
  isVerarbeitungTypeCamt,
  isVerarbeitungTypeCamtEbicsManually,
  isVerarbeitungTypeCamtOnlineBankingManually,
  isVerarbeitungTypeDataCarrierPainCreation,
  isVerarbeitungTypeFirmendatenInitializer,
  isVerarbeitungTypeHeAbrechnung,
  isVerarbeitungTypeIncomingInvoiceBookingCreation,
  isVerarbeitungTypeInfoMailDeliveryDetermineRecipientList,
  isVerarbeitungTypeInfoMailDeliverySend,
  isVerarbeitungTypeMahnungAusgeben,
  isVerarbeitungTypeMahnungErzeugen,
  isVerarbeitungTypeMahnungVerbuchen,
  isVerarbeitungTypeMahnungVersenden,
  isVerarbeitungTypeObjektFinancialPlan,
  isVerarbeitungTypePaymentProposalCreate,
  isVerarbeitungTypePaymentProposalExecute,
  isVerarbeitungTypeReceivedBankTransactionVerbuchen,
  isVerarbeitungTypeSubAbrechnung,
  isVerarbeitungTypeSyncEbicsUser,
  isVerarbeitungTypeTopFinancialPlan,
  isVerarbeitungTypeUstVomAufwand,
  isVerarbeitungTypeVertragVposIndexedValueAutomatically,
  isVerarbeitungTypeVertragVposIndexedValueManually,
  isVerarbeitungTypeVorschreibungAusgeben,
  isVerarbeitungTypeVorschreibungErstellen,
  isVerarbeitungTypeVorschreibungMailDeliveryDetermineRecipientList,
  isVerarbeitungTypeVorschreibungMailDeliverySend,
  isVerarbeitungTypeVorschreibungVerbuchen,
  isVerarbeitungTypeVorsteuerkuerzungErzeugen,
  isVerarbeitungTypeWeObjektAbrechnung,
  isVerarbeitungTypeWeTopAbrechnung,
  isVerarbeitungTypeZinsliste,
} from './verarbeitungHelpers';
import { GenerierlaufStatus } from '../../types';

export const URI_VERARBEITUNG_BELEG_ERSTELLEN_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/beleg-erstellen/:generierlaufId`;
export const URI_VERARBEITUNG_HE_ABRECHNUNG_ERSTELLEN_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/he-abrechnung-erstellen/:generierlaufId`;
export const URI_VERARBEITUNG_INFO_MAIL_DELIVERY_DETERMINE_RECIPIENT_LIST_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/info-mail-delivery-determine-recipient-list-generierlauf/:generierlaufId`;
export const URI_VERARBEITUNG_INFO_MAIL_DELIVERY_SEND_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/info-mail-delivery-send-generierlauf/:generierlaufId`;
export const URI_VERARBEITUNG_OBJEKT_FINANCIAL_PLAN = `${URI_VERARBEITUNG_PAGE}/objekt-wirtschaftsplan-ausgeben/:generierlaufId`;
export const URI_VERARBEITUNG_TOP_FINANCIAL_PLAN = `${URI_VERARBEITUNG_PAGE}/top-wirtschaftsplan-ausgeben/:generierlaufId`;
export const URI_VERARBEITUNG_VORSCHREIBUNG_MAIL_DELIVERY_DETERMINE_RECIPIENT_LIST_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/vorschreibung-mail-delivery-determine-recipient-list-generierlauf/:generierlaufId`;
export const URI_VERARBEITUNG_VORSCHREIBUNG_MAIL_DELIVERY_SEND_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/vorschreibung-mail-delivery-send-generierlauf/:generierlaufId`;
export const URI_VERARBEITUNG_MAHNUNG_AUSGEBEN_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/mahnungen-ausgeben/:generierlaufId`;
export const URI_VERARBEITUNG_MAHNUNG_ERSTELLEN_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/mahnungen-erstellen/:generierlaufId`;
export const URI_VERARBEITUNG_MAHNUNG_VERSENDEN_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/mahnungen-versenden/:generierlaufId`;
export const URI_VERARBEITUNG_MAHNUNG_VERBUCHEN_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/mahnungen-verbuchen/:generierlaufId`;
export const URI_VERARBEITUNG_VORSCHREIBUNG_ERSTELLEN_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/vorschreibungen-erstellen/:generierlaufId`;
export const URI_VERARBEITUNG_VORSCHREIBUNG_VERBUCHEN_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/vorschreibungen-verbuchen/:generierlaufId`;
export const URI_VERARBEITUNG_UST_VOM_AUFWAND_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/ust-vom-aufwand/:generierlaufId`;
export const URI_VERARBEITUNG_VORSTEUERKUERZUNG_ERSTELLEN_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/vorsteuerkuerzung-erstellen/:generierlaufId`;
export const URI_VERARBEITUNG_PAYMENT_PROPOSAL_CREATE_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/zahlungsvorschlag-erstellen/:generierlaufId`;
export const URI_VERARBEITUNG_PAYMENT_PROPOSAL_EXECUTE_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/zahlungsvorschlag-durchfuehren/:generierlaufId`;
export const URI_VERARBEITUNG_DATA_CARRIER_PAIN_CREATION_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/datentraeger-erstellen/:generierlaufId`;
export const URI_VERARBEITUNG_ZINSLISTE_ERSTELLEN_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/zinsliste-erstellung/:generierlaufId`;
export const URI_VERARBEITUNG_BK_OBJEKT_ABRECHNUNGEN_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/bk-objektabrechnungen/:generierlaufId`;
export const URI_VERARBEITUNG_BK_TOP_ABRECHNUNGEN_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/bk-topabrechnungen/:generierlaufId`;
export const URI_VERARBEITUNG_WE_OBJEKT_ABRECHNUNG_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/we-objektabrechnung/:generierlaufId`;
export const URI_VERARBEITUNG_WE_TOP_ABRECHNUNG_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/we-topabrechnungen/:generierlaufId`;
export const URI_VERARBEITUNG_BUDGETING_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/budgetierung/:generierlaufId`;
export const URI_VERARBEITUNG_VERTRAG_VPOS_INDEXED_VALUE_MANUALLY_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/indexierte-werte/manuell/:generierlaufId`;
export const URI_VERARBEITUNG_VERTRAG_VPOS_INDEXED_VALUE_AUTOMATICALLY_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/indexierte-werte/automatisch/:generierlaufId`;
export const URI_VERARBEITUNG_APPLY_VERTRAG_VPOS_INDEXED_VALUE_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/indexierte-werte/anwenden/:generierlaufId`;
export const URI_VERARBEITUNG_SYNC_EBICS_USER_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/sync-ebics-user/:generierlaufId`;
export const URI_VERARBEITUNG_CAMT_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/camt/automatisch/:generierlaufId`;
export const URI_VERARBEITUNG_CAMT_EBICS_MANUALLY_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/camt/manuell/ebics/:generierlaufId`;
export const URI_VERARBEITUNG_CAMT_ONLINE_BANKING_MANUALLY_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/camt/manuell/online-banking/:generierlaufId`;
export const URI_VERARBEITUNG_BOOKING_SUGGESTION_CREATION_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/buchungsvorschlag-erstellen/:generierlaufId`;
export const URI_VERARBEITUNG_BOOKING_SUGGESTION_VERBUCHEN_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/zahlungsvorschlag-verbuchen/:generierlaufId`;
export const URI_VERARBEITUNG_INCOMING_INVOICE_BOOKING_CREATION_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/eingangsrechnung-verbuchen/:generierlaufId`;
export const URI_VERARBEITUNG_FIRMENDATEN_INITIALIZER_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/firmendaten-initialisieren/:generierlaufId`;
export const URI_VERARBEITUNG_RECEIVED_BANK_TRANSACTION_VERBUCHEN_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/empfangene-bankbuchungen-verbuchen/:generierlaufId`;
export const URI_VERARBEITUNG_SUB_ABRECHNUNG_DETAILS_PAGE = `${URI_VERARBEITUNG_PAGE}/sub-abrechnung/:generierlaufId`;

export const generatePathToVerarbeitungBelegErstellenDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_BELEG_ERSTELLEN_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungHeAbrechnungErstellenDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_HE_ABRECHNUNG_ERSTELLEN_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungInfoMailDeliveryDetermineRecipientListDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_INFO_MAIL_DELIVERY_DETERMINE_RECIPIENT_LIST_DETAILS_PAGE, { generierlaufId });
export const generatePathToVerarbeitungInfoMailDeliverySendDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_INFO_MAIL_DELIVERY_SEND_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungVorschreibungMailDeliveryDetermineRecipientListDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_VORSCHREIBUNG_MAIL_DELIVERY_DETERMINE_RECIPIENT_LIST_DETAILS_PAGE, { generierlaufId });
export const generatePathToVerarbeitungVorschreibungMailDeliverySendDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_VORSCHREIBUNG_MAIL_DELIVERY_SEND_DETAILS_PAGE, { generierlaufId });
export const generatePathToVerarbeitungObjektFinancialPlanDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_OBJEKT_FINANCIAL_PLAN, { generierlaufId });
export const generatePathToVerarbeitungTopFinancialPlanDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_TOP_FINANCIAL_PLAN, { generierlaufId });

export const generatePathToVerarbeitungMahnungErstellenDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_MAHNUNG_ERSTELLEN_DETAILS_PAGE, { generierlaufId });
export const generatePathToVerarbeitungMahnungAusgebenDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_MAHNUNG_AUSGEBEN_DETAILS_PAGE, { generierlaufId });
export const generatePathToVerarbeitungMahnungVersendenDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_MAHNUNG_VERSENDEN_DETAILS_PAGE, { generierlaufId });
export const generatePathToVerarbeitungMahnungVerbuchenDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_MAHNUNG_VERBUCHEN_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungWeObjAbrDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_WE_OBJEKT_ABRECHNUNG_DETAILS_PAGE, { generierlaufId });
export const generatePathToVerarbeitungWeTopAbrDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_WE_TOP_ABRECHNUNG_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungUstVomAufwandErstellenDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_UST_VOM_AUFWAND_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungVorschreibungErstellenDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_VORSCHREIBUNG_ERSTELLEN_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungVorschreibungVerbuchenDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_VORSCHREIBUNG_VERBUCHEN_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungVstKuerzungErstellenDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_VORSTEUERKUERZUNG_ERSTELLEN_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungPaymentProposalCreateDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_PAYMENT_PROPOSAL_CREATE_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungPaymentProposalExecuteDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_PAYMENT_PROPOSAL_EXECUTE_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungDataCarrierPainCreationDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_DATA_CARRIER_PAIN_CREATION_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungZinslisteErstellungDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_ZINSLISTE_ERSTELLEN_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungBkObjAbrDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_BK_OBJEKT_ABRECHNUNGEN_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungBkTopAbrDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_BK_TOP_ABRECHNUNGEN_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungBudgetingDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_BUDGETING_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungVertragVposIndexedValueManuallyDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_VERTRAG_VPOS_INDEXED_VALUE_MANUALLY_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungVertragVposIndexedValueAutomaticallyDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_VERTRAG_VPOS_INDEXED_VALUE_AUTOMATICALLY_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungApplyVertragVposIndexedValueDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_APPLY_VERTRAG_VPOS_INDEXED_VALUE_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungSyncEbicsUserDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_SYNC_EBICS_USER_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungCamtDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_CAMT_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungCamtEbicsManuallyDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_CAMT_EBICS_MANUALLY_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungCamtOnlineBankingManuallyDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_CAMT_ONLINE_BANKING_MANUALLY_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungBookingSuggestionCreationDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_BOOKING_SUGGESTION_CREATION_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungBookingSuggestionVerbuchenDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_BOOKING_SUGGESTION_VERBUCHEN_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungIncomingInvoiceBookingCreationDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_INCOMING_INVOICE_BOOKING_CREATION_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungReceivedBankTransactionVerbuchenDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_RECEIVED_BANK_TRANSACTION_VERBUCHEN_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungFirmendatenInitializerDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_FIRMENDATEN_INITIALIZER_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungSubAbrechnungDetailsPage = (generierlaufId: string) =>
  generatePath(URI_VERARBEITUNG_SUB_ABRECHNUNG_DETAILS_PAGE, { generierlaufId });

export const generatePathToVerarbeitungDetailsPage = (verarbeitungType: string, id: string, status: GenerierlaufStatus, countEntries: number) =>
  status === GenerierlaufStatus.BeendetFehler && countEntries === 0
    ? `${getPathToVerarbeitungDetailsPage(verarbeitungType, id)}/fehler`
    : getPathToVerarbeitungDetailsPage(verarbeitungType, id);

export const getPathToVerarbeitungDetailsPage = (verarbeitungType: string, id: string) => {
  if (isVerarbeitungTypeMahnungAusgeben(verarbeitungType)) {
    return generatePathToVerarbeitungMahnungAusgebenDetailsPage(id);
  } else if (isVerarbeitungTypeMahnungErzeugen(verarbeitungType)) {
    return generatePathToVerarbeitungMahnungErstellenDetailsPage(id);
  } else if (isVerarbeitungTypeMahnungVersenden(verarbeitungType)) {
    return generatePathToVerarbeitungMahnungVersendenDetailsPage(id);
  } else if (isVerarbeitungTypeMahnungVerbuchen(verarbeitungType)) {
    return generatePathToVerarbeitungMahnungVerbuchenDetailsPage(id);
  } else if (isVerarbeitungTypeVorschreibungAusgeben(verarbeitungType)) {
    return generatePathToVerarbeitungBelegErstellenDetailsPage(id);
  } else if (isVerarbeitungTypeWeObjektAbrechnung(verarbeitungType)) {
    return generatePathToVerarbeitungWeObjAbrDetailsPage(id);
  } else if (isVerarbeitungTypeWeTopAbrechnung(verarbeitungType)) {
    return generatePathToVerarbeitungWeTopAbrDetailsPage(id);
  } else if (isVerarbeitungTypeUstVomAufwand(verarbeitungType)) {
    return generatePathToVerarbeitungUstVomAufwandErstellenDetailsPage(id);
  } else if (isVerarbeitungTypeVorsteuerkuerzungErzeugen(verarbeitungType)) {
    return generatePathToVerarbeitungVstKuerzungErstellenDetailsPage(id);
  } else if (isVerarbeitungTypeVorschreibungErstellen(verarbeitungType)) {
    return generatePathToVerarbeitungVorschreibungErstellenDetailsPage(id);
  } else if (isVerarbeitungTypeVorschreibungVerbuchen(verarbeitungType)) {
    return generatePathToVerarbeitungVorschreibungVerbuchenDetailsPage(id);
  } else if (isVerarbeitungTypeInfoMailDeliveryDetermineRecipientList(verarbeitungType)) {
    return generatePathToVerarbeitungInfoMailDeliveryDetermineRecipientListDetailsPage(id);
  } else if (isVerarbeitungTypeInfoMailDeliverySend(verarbeitungType)) {
    return generatePathToVerarbeitungInfoMailDeliverySendDetailsPage(id);
  } else if (isVerarbeitungTypeVorschreibungMailDeliveryDetermineRecipientList(verarbeitungType)) {
    return generatePathToVerarbeitungVorschreibungMailDeliveryDetermineRecipientListDetailsPage(id);
  } else if (isVerarbeitungTypeVorschreibungMailDeliverySend(verarbeitungType)) {
    return generatePathToVerarbeitungVorschreibungMailDeliverySendDetailsPage(id);
  } else if (isVerarbeitungTypeDataCarrierPainCreation(verarbeitungType)) {
    return generatePathToVerarbeitungDataCarrierPainCreationDetailsPage(id);
  } else if (isVerarbeitungTypePaymentProposalCreate(verarbeitungType)) {
    return generatePathToVerarbeitungPaymentProposalCreateDetailsPage(id);
  } else if (isVerarbeitungTypePaymentProposalExecute(verarbeitungType)) {
    return generatePathToVerarbeitungPaymentProposalExecuteDetailsPage(id);
  } else if (isVerarbeitungTypeHeAbrechnung(verarbeitungType)) {
    return generatePathToVerarbeitungHeAbrechnungErstellenDetailsPage(id);
  } else if (isVerarbeitungTypeZinsliste(verarbeitungType)) {
    return generatePathToVerarbeitungZinslisteErstellungDetailsPage(id);
  } else if (isVerarbeitungTypeBkObjektAbrechnung(verarbeitungType)) {
    return generatePathToVerarbeitungBkObjAbrDetailsPage(id);
  } else if (isVerarbeitungTypeBkTopAbrechnung(verarbeitungType)) {
    return generatePathToVerarbeitungBkTopAbrDetailsPage(id);
  } else if (isVerarbeitungTypeBudgeting(verarbeitungType)) {
    return generatePathToVerarbeitungBudgetingDetailsPage(id);
  } else if (isVerarbeitungTypeVertragVposIndexedValueManually(verarbeitungType)) {
    return generatePathToVerarbeitungVertragVposIndexedValueManuallyDetailsPage(id);
  } else if (isVerarbeitungTypeVertragVposIndexedValueAutomatically(verarbeitungType)) {
    return generatePathToVerarbeitungVertragVposIndexedValueAutomaticallyDetailsPage(id);
  } else if (isVerarbeitungTypeApplyVertragVposIndexedValue(verarbeitungType)) {
    return generatePathToVerarbeitungApplyVertragVposIndexedValueDetailsPage(id);
  } else if (isVerarbeitungTypeObjektFinancialPlan(verarbeitungType)) {
    return generatePathToVerarbeitungObjektFinancialPlanDetailsPage(id);
  } else if (isVerarbeitungTypeTopFinancialPlan(verarbeitungType)) {
    return generatePathToVerarbeitungTopFinancialPlanDetailsPage(id);
  } else if (isVerarbeitungTypeSyncEbicsUser(verarbeitungType)) {
    return generatePathToVerarbeitungSyncEbicsUserDetailsPage(id);
  } else if (isVerarbeitungTypeCamt(verarbeitungType)) {
    return generatePathToVerarbeitungCamtDetailsPage(id);
  } else if (isVerarbeitungTypeCamtEbicsManually(verarbeitungType)) {
    return generatePathToVerarbeitungCamtEbicsManuallyDetailsPage(id);
  } else if (isVerarbeitungTypeCamtOnlineBankingManually(verarbeitungType)) {
    return generatePathToVerarbeitungCamtOnlineBankingManuallyDetailsPage(id);
  } else if (isVerarbeitungTypeBookingSuggestionCreation(verarbeitungType)) {
    return generatePathToVerarbeitungBookingSuggestionCreationDetailsPage(id);
  } else if (isVerarbeitungTypeIncomingInvoiceBookingCreation(verarbeitungType)) {
    return generatePathToVerarbeitungIncomingInvoiceBookingCreationDetailsPage(id);
  } else if (isVerarbeitungTypeBookingSuggestionVerbuchen(verarbeitungType)) {
    return generatePathToVerarbeitungBookingSuggestionVerbuchenDetailsPage(id);
  } else if (isVerarbeitungTypeReceivedBankTransactionVerbuchen(verarbeitungType)) {
    return generatePathToVerarbeitungReceivedBankTransactionVerbuchenDetailsPage(id);
  } else if (isVerarbeitungTypeFirmendatenInitializer(verarbeitungType)) {
    return generatePathToVerarbeitungFirmendatenInitializerDetailsPage(id);
  } else if (isVerarbeitungTypeSubAbrechnung(verarbeitungType)) {
    return generatePathToVerarbeitungSubAbrechnungDetailsPage(id);
  } else {
    return '';
  }
};
