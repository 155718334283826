import React from 'react';
import { Space, Tag, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { TableWithAlignedColsColumnType } from '../../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { generatePathToKontoblattPage } from '../../../../../Kontoblatt/kontoblattUriPaths';
import { EuroAmount } from '../../../../../../components/Number';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';
import { ObjAbrKonto } from '../../../../../../types';

const ausgabenKontoTableColumns = (
  objektId: string,
  isAbrKreisRepFonds: boolean,
  fromInclusive: string,
  toInclusive: string,
  buchungskreisId: string
): TableWithAlignedColsColumnType<ObjAbrKonto>[] => [
  {
    title: '',
    width: '250px',
    render: (konto) => (
      <Space>
        <Typography.Text>{konto.bezeichnung}</Typography.Text>
        {konto.expenseReducing && <Typography.Text type="secondary"> (aufwandsmindernd)</Typography.Text>}
      </Space>
    ),
    align: 'left',
  },
  {
    title: '',
    align: 'center',
    render: (konto) => {
      if (isAbrKreisRepFonds) {
        return <TextForEmptyValue textToShow="none" />;
      }

      return konto.abweichenderAufteilungsschluesselBezeichnung ? (
        <Tag>{konto.abweichenderAufteilungsschluesselBezeichnung}</Tag>
      ) : (
        <Typography.Text italic>aus Abrechnungskreis</Typography.Text>
      );
    },
  },
  {
    title: '',
    colSpan: 2,
    render: (konto) => (
      <Link
        target="_blank"
        to={generatePathToKontoblattPage({
          kontoId: konto.kontoId,
          objektId,
          buchungsdatumFrom: fromInclusive,
          buchungsdatumTo: toInclusive,
          buchungskreisId,
        })}
      >
        <EuroAmount value={konto.summe} />
      </Link>
    ),
  },
  {
    title: '',
    colSpan: 2,
    render: () => <TextForEmptyValue textToShow="none" />,
  },
  {
    title: '',
    render: () => <TextForEmptyValue textToShow="none" />,
  },
];

export default ausgabenKontoTableColumns;
