import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import { URI_VERARBEITUNG_VERTRAG_VPOS_INDEXED_VALUE_AUTOMATICALLY_DETAILS_PAGE } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import VertragVposIndexedValueAutomaticallyDetailsPage from './VertragVposIndexedValueAutomaticallyDetailsPage';

export type VertragVposIndexedValueAutomaticallyDetailsPageRouteParams = { generierlaufId: string };

export const verarbeitungVertragVposIndexedValueAutomaticallyDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_VERTRAG_VPOS_INDEXED_VALUE_AUTOMATICALLY_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<VertragVposIndexedValueAutomaticallyDetailsPage />} />}
  />
);
