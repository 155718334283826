import React from 'react';
import { Link } from 'react-router-dom';
import { TableColumnProps, Typography } from 'antd';
import { Budgeting, BudgetingResultBudgetingVPos } from '../../../../../types';
import { EuroAmount, Percent } from '../../../../../components/Number';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { pathsToBudgetingDetailsPage } from '../../../budgetingUriPaths';

const budgetingResultBudgetingVPosTableColumns = (budgeting: Budgeting): TableColumnProps<BudgetingResultBudgetingVPos>[] => [
  {
    title: (
      <>
        <Typography.Text>Quelle: </Typography.Text>
        <Typography.Text style={{ fontWeight: 'bold' }}>Vorschreibungs-Werte</Typography.Text>
      </>
    ),
    width: 200,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={20} text={record.budgetingVPos.bezeichnung}>
        {(shortenedText) => (
          <Link
            to={pathsToBudgetingDetailsPage(budgeting.objekt.objektId, budgeting.budgetingId, record.budgetingVPos.budgetingVPosId).detailsTab}
            target="_blank"
          >
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: '',
  },
  {
    title: 'Basisbetrag',
    align: 'right',
    render: (text, record) => <EuroAmount value={record.vorschreibungCurrentYear} />,
  },
  {
    title: 'Erhöhung %',
    align: 'right',
    render: (text, record) => <Percent value={record.increase / 100} />,
  },
  {
    title: 'Jahresbudget Summe',
    align: 'right',
    render: (text, record) => <EuroAmount value={record.estimateNextYear} />,
  },
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: '',
  },
  {
    title: '',
  },
];

export default budgetingResultBudgetingVPosTableColumns;
