import { generatePath } from 'react-router';
import { FiltersFormValues } from './filtersFormMapper';
import { mapToQueryString, QueryParams } from '../../../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../../../hooks/usePaginationQueryParams';
import { URI_RECHTSTRAEGER_PAGE } from '../../rechtstraegerUriPaths';

export enum QueryParamKey {
  CREATE_TS_FROM = 'createTsFrom',
  CREATE_TS_TO = 'createTsTo',
  ERSTELLER_MITARBEITER_ID_LIST = 'erstellerMitarbeiterIdList',
  KUNDENNUMMER = 'kundennummer',
  KURZ_BEZEICHNUNG = 'kurzBezeichnung',
  SHOW_ARCHIVED = 'showArchived',
  TAG_LIST = 'tagList',
  TYPE_LIST = 'typeList',
}

export type TRechtstraegerQueryParams = QueryParams<FiltersFormValues>;

export const updateQueryParams = (
  navigate: (path: string) => void,
  filters: TRechtstraegerQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToRechtstraegerListingPage(filters, paginationParams));

export const generatePathToRechtstraegerListingPage = (filters: TRechtstraegerQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  `${generatePath(URI_RECHTSTRAEGER_PAGE)}?${mapToQueryString({
    [QueryParamKey.CREATE_TS_FROM]: filters?.createTsFrom,
    [QueryParamKey.CREATE_TS_TO]: filters?.createTsTo,
    [QueryParamKey.ERSTELLER_MITARBEITER_ID_LIST]: filters?.erstellerMitarbeiterIdList,
    [QueryParamKey.KUNDENNUMMER]: filters?.kundennummer,
    [QueryParamKey.KURZ_BEZEICHNUNG]: filters?.kurzBezeichnung,
    [QueryParamKey.SHOW_ARCHIVED]: filters?.showArchived,
    [QueryParamKey.TAG_LIST]: filters?.tagList,
    [QueryParamKey.TYPE_LIST]: filters?.typeList,
    ...paginationParams,
  })}`;
