import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import TemplateHeAbrechnung from './TemplateHeAbrechnung';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import { HeOrSubAbrDefOutputOptionsFieldHelpNames } from '../../../abrDefFieldHelpHelpers/abrDefFieldHelpMapper';
import HeOrSubGeneralSettingsForm from '../shared/HeOrSub/GeneralSettingForm/HeOrSubGeneralSettingsForm';
import { HeOrSubAbrDefGeneralSettingsFormValues } from '../shared/HeOrSub/GeneralSettingForm/heOrSubAbrDefGeneralSettingsFormMapper';

type Props = {
  heAbrDefGeneralSettingsValues: HeOrSubAbrDefGeneralSettingsFormValues;
  setHeAbrDefGeneralSettingsValues: (values: HeOrSubAbrDefGeneralSettingsFormValues) => void;
  heAbrDefTemplateValues: TextbausteinFormValues[];
  onTemplateChange: (templateValues: TextbausteinFormValues[]) => void;
  fieldHelpNames?: HeOrSubAbrDefOutputOptionsFieldHelpNames;
};

const HeAbrDefVersionUpdateTabWithTemplate: FC<Props> = ({
  heAbrDefGeneralSettingsValues,
  setHeAbrDefGeneralSettingsValues,
  heAbrDefTemplateValues,
  onTemplateChange,
  fieldHelpNames,
}) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Allgemeine Einstellungen',
      children: (
        <HeOrSubGeneralSettingsForm
          values={heAbrDefGeneralSettingsValues}
          onSubmit={setHeAbrDefGeneralSettingsValues}
          fieldHelpNames={fieldHelpNames}
        />
      ),
    },
    {
      key: '2',
      label: 'Texte',
      children: (
        <TemplateHeAbrechnung
          values={heAbrDefTemplateValues}
          onTemplateChange={onTemplateChange}
          abrDefGeneralSettingsValues={heAbrDefGeneralSettingsValues}
        />
      ),
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey="Allgemeine Einstellungen" items={items} />
    </>
  );
};

export default HeAbrDefVersionUpdateTabWithTemplate;
