import { FormFields } from '../../../../helpers/formikHelper';
import { VertragInfoFeldType } from '../../../../types';

export type VertragInfoFeldListingFiltersFormValues = {
  type?: VertragInfoFeldType;
};

export const vertragInfoFeldListingFiltersFormFields: FormFields<VertragInfoFeldListingFiltersFormValues> = {
  type: 'type',
};
