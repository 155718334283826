import React, { FC } from 'react';
import AdminCreateForm from './AdminCreateForm';
import { UserCreateInput } from '../../../../types';
import { AdminCreateFormValues, mapValuesToAdminCreate } from './adminCreateFormMapper';

interface AdminCreateInterface {
  loading: boolean;
  onCreate: (adminToCreate: UserCreateInput) => void;
  onCancel: () => void;
}

const AdminCreate: FC<AdminCreateInterface> = ({ loading, onCreate, onCancel }) => (
  <AdminCreateForm
    loading={loading}
    onSubmit={(values: AdminCreateFormValues) => {
      const adminToCreate = mapValuesToAdminCreate(values);
      onCreate(adminToCreate);
    }}
    onCancel={onCancel}
  />
);

export default AdminCreate;
