import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { bookingInstructionFormInitialValues, BookingInstructionFormValues } from './bookingInstructionFormMapper';
import DividerForBooking from '../../BookingDetails/shared/DividerForBooking';
import SpaceForBookingBlockAndForm from '../../BookingDetails/shared/SpaceForBookingBlockAndForm';
import { bookingSuggestionUpdateFormValidationSchema } from '../../BookingSuggestion/shared/Form/bookingSuggestionUpdateFormValidationSchema';
import GeneralInfoWithButtonsBlock from './GeneralInfoBlock/GeneralInfoWithButtonsBlock';

const BookingInstructionForm: FC = () => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  return (
    <Formik<BookingInstructionFormValues>
      initialValues={bookingInstructionFormInitialValues}
      validationSchema={bookingSuggestionUpdateFormValidationSchema}
      onSubmit={(values) => {
        //  handleCreateSubmit(firmendatenId, values);
        console.log(values);
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <SpaceForBookingBlockAndForm>
            {/*  Block 1 - Buchungskopf with buttons */}
            Block 1 - Buchungskopf with buttons
            <GeneralInfoWithButtonsBlock firmendatenId={firmendatenId} formikProps={formikProps} />
            <DividerForBooking />
            {/*Block 2 - Buchungszeilen */}
            Block 2 - Buchungszeilen
            <DividerForBooking />
            {/*  Block 3 - Auszifferung list and Offene Posten list (as source for Auszifferung list) */}
            Block 3 - Auszifferung list and Offene Posten list (as source for Auszifferung list)
          </SpaceForBookingBlockAndForm>
        </Form>
      )}
    </Formik>
  );
};

export default BookingInstructionForm;
