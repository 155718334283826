import React, { FC } from 'react';
import { Divider, Skeleton, Space, Switch, Typography, Table } from 'antd';
import { Box } from 'rebass';
import { Formik } from 'formik';
import { Form, InputNumber } from 'formik-antd';
import { v4 as uuid4 } from 'uuid';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../../components/message';
import { Spacer } from '../../../../../components/Grid';
import { ITimeblock, TimelineMode, useTimeline } from '../../../../../components/Timeline/timelineCtx';
import TimelineFormButtons from '../../../../../components/Timeline/Form/TimelineFormButtons';
import TimelineFormDatePicker from '../../../../../components/Timeline/Form/TimelineFormDatePicker';
import { layoutTimelineForm } from '../../../../../components/Input-antd/formLayoutHelper';
import { useCreateUstKategorieVersionMutation, useUpdateUstKategorieVersionMutation } from '../gql/UstKategorieVersionMutations.types';
import {
  mapFormValuesToUstKategorieVersionCreate,
  mapFormValuesToUstKategorieVersionUpdate,
  mapUstKategorieTimeblockToFormValues,
  TUstKategorieVersionFormValues,
  UstKategorieVersionCreateFormValues,
  ustKategorieVersionFormFields,
  ustKategorieVersionFormFieldsDescriptions,
  UstKategorieVersionUpdateFormValues,
} from './ustKategorieVersionMapper';
import { ustKategorieEntryVersionTableColumnsEdit } from '../columns/ustKategorieEntryVersionTableColumnsEdit';
import { ustKategorieVersionFormValidationSchema } from '../../shared/ustKategorieFormValidationSchema';
import { UstKategorieEntry, UstKategorieVersion } from '../../../../../types';
import FormItemWithFieldHelp from '../../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../../FieldHelp/useGetFieldHelpText';
import FieldHelpIconWithTooltip from '../../../../../components/FieldHelpIcon/FieldHelpIconWithTooltip';
import { useUstKategorieEntryInitialListQuery } from '../../gql/UstKategorieQueries.types';

type Props = {
  ustKategorieId: string;
  timeblock: ITimeblock<UstKategorieVersion>;
  onSubmitSuccess: () => void;
};

const UstKategorieVersionForm: FC<Props> = ({ ustKategorieId, timeblock, onSubmitSuccess }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'UstKategorieVersion'>('UstKategorieVersion');
  const { toggleEdit, changeToUpdateMode, timelineMode } = useTimeline<UstKategorieVersion>();
  const isUpdate = !!timeblock.ustKategorieVersionId;
  const entity = 'USt-Kategorie';

  const { loading: loadingEntryList, data: maybeUstKategorieEntryList } = useUstKategorieEntryInitialListQuery({
    skip: timelineMode === TimelineMode.update,
  });
  const ustKategorieEntryInitialList = maybeUstKategorieEntryList?.getUstKategorieEntryInitialList.data ?? [];

  const [runUpdate, { loading: loadingUpdate }] = useUpdateUstKategorieVersionMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      toggleEdit(timeblock.uuid);
      onSubmitSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runCreate, { loading: loadingCreate }] = useCreateUstKategorieVersionMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      changeToUpdateMode(timeblock.uuid);
      onSubmitSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const initalValues = mapUstKategorieTimeblockToFormValues(isUpdate, ustKategorieEntryInitialList, timeblock);

  return loadingEntryList ? (
    <Skeleton paragraph />
  ) : (
    <Formik<TUstKategorieVersionFormValues>
      initialValues={initalValues}
      validationSchema={ustKategorieVersionFormValidationSchema}
      onSubmit={(formValues, { setSubmitting }) => {
        if (isUpdate) {
          runUpdate({
            variables: {
              ustKategorieId,
              ustKategorieVersionId: timeblock.ustKategorieVersionId,
              input: mapFormValuesToUstKategorieVersionUpdate(formValues as UstKategorieVersionUpdateFormValues),
            },
          }).finally(() => setSubmitting(false));
        } else {
          runCreate({
            variables: {
              ustKategorieId,
              input: mapFormValuesToUstKategorieVersionCreate(formValues as UstKategorieVersionCreateFormValues),
            },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form {...layoutTimelineForm} layout="horizontal" labelAlign="left">
          <Box width={2 / 3}>
            <FormItemWithFieldHelp
              name={ustKategorieVersionFormFields.validFrom}
              label={ustKategorieVersionFormFieldsDescriptions.validFrom}
              fieldHelp={getFieldHelpText('UstKategorieVersion.validFrom')}
            >
              <TimelineFormDatePicker<UstKategorieVersion>
                id={ustKategorieVersionFormFields.validFrom}
                name={ustKategorieVersionFormFields.validFrom}
                timeblock={timeblock}
              />
            </FormItemWithFieldHelp>

            <FormItemWithFieldHelp
              name={ustKategorieVersionFormFields.ustVomAufwand}
              label={ustKategorieVersionFormFieldsDescriptions.ustVomAufwand}
              fieldHelp={getFieldHelpText('UstKategorieVersion.ustVomAufwand')}
            >
              <InputNumber
                key={1}
                min={0}
                step={1}
                name={ustKategorieVersionFormFields.ustVomAufwand}
                size="small"
                formatter={(value) => `${value}%`}
                parser={(value) => (value ? value.replace('%', '') : '')}
              />
            </FormItemWithFieldHelp>

            <FormItemWithFieldHelp
              name={ustKategorieVersionFormFields.vstKuerzung}
              label={ustKategorieVersionFormFieldsDescriptions.vstKuerzung}
              fieldHelp={getFieldHelpText('UstKategorieVersion.vstKuerzung')}
            >
              <Space>
                <Switch
                  id={ustKategorieVersionFormFields.vstKuerzung}
                  defaultChecked={initalValues.vstKuerzung}
                  onChange={(checked) => formikProps.setFieldValue(ustKategorieVersionFormFields.vstKuerzung, checked)}
                  size="small"
                />
                <Typography.Text>{formikProps.values.vstKuerzung ? 'Ja' : 'Nein'}</Typography.Text>
              </Space>
            </FormItemWithFieldHelp>
          </Box>

          <Divider style={{ padding: 0, marginTop: 0, marginBottom: '16px' }} />
          <Typography.Title level={5}>
            Ust-Kategorie Einträge
            <FieldHelpIconWithTooltip fieldHelp={getFieldHelpText('UstKategorieVersion.ustKategorieEntryList')} />
          </Typography.Title>
          <Table<UstKategorieEntry>
            size="small"
            dataSource={!isUpdate ? ustKategorieEntryInitialList : timeblock.ustKategorieEntryList}
            rowKey={!isUpdate ? () => uuid4() : 'ustKategorieEntryId'}
            //name="ustKategorieEntryList"
            columns={ustKategorieEntryVersionTableColumnsEdit}
            pagination={false}
          />

          <Spacer height={16} />

          <TimelineFormButtons<UstKategorieVersion>
            timeblock={timeblock}
            isSubmitting={formikProps.isSubmitting}
            isSameAsPreviousVersion={false}
            submitBtnLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default UstKategorieVersionForm;
