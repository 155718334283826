import React, { FC } from 'react';
import { Empty } from 'antd';
import { VertragVposIndexationAgreementVorschreibungsposition } from '../../../../../../../types';
import indexationAgreementVPosTableColumns from './indexationAgreementVPosTableColumns';
import { useTableExpandedRowKeys } from '../../../../../../../components/Table/shared/useTableExpandedRowKeys';
import IndexationAgreementTable from '../Level2/IndexationAgreementTable';
import { IndexationAgreementProvider } from '../Level2/IndexationAgreementContext';
import NestedTable from '../../../../../../../components/Table/NestedTable/NestedTable';
import { QUERY_PARAM_KEY, useQueryParams } from '../../../../../../../hooks/useStringQueryParams';

type Props = {
  indexationAgreementVPosList?: VertragVposIndexationAgreementVorschreibungsposition[];
  loading: boolean;
  bestandseinheitId: string;
  objektId: string;
  vertragId: string;
  onListChange: () => void;
};

const IndexationAgreementVPosTable: FC<Props> = ({ indexationAgreementVPosList, loading, bestandseinheitId, objektId, vertragId, onListChange }) => {
  const { expRowIdLvl1: vorschreibungspositionId } = useQueryParams<{ [QUERY_PARAM_KEY.EXP_ROW_ID_LVL1]: string }>();

  const { expandedRowKeys, toggleExpandedRowKey } = useTableExpandedRowKeys(vorschreibungspositionId ? [vorschreibungspositionId] : undefined);

  return (
    <NestedTable<VertragVposIndexationAgreementVorschreibungsposition>
      colWidth={150}
      level={1}
      locale={{
        emptyText: <Empty description="Keine Wertsicherungsvereinbarungen vorhanden" />,
      }}
      pagination={false}
      loading={loading}
      dataSource={indexationAgreementVPosList}
      columns={indexationAgreementVPosTableColumns(bestandseinheitId, objektId, vertragId, toggleExpandedRowKey, expandedRowKeys)}
      rowKey={(record) => record.vorschreibungspositionId}
      expandable={{
        expandedRowKeys,
        onExpand: (expanded, record) => toggleExpandedRowKey(record.vorschreibungspositionId),
        expandedRowRender: (record) => (
          <IndexationAgreementProvider
            bestandseinheitId={bestandseinheitId}
            objektId={objektId}
            vertragId={vertragId}
            vorschreibungspositionId={record.vorschreibungspositionId}
          >
            <IndexationAgreementTable onListChange={onListChange} />
          </IndexationAgreementProvider>
        ),
      }}
    />
  );
};

export default IndexationAgreementVPosTable;
