import { Box } from 'rebass';
import React, { FC } from 'react';

type Props = { title: string; isTransparent?: boolean };

const ZuordnungKontoKlasseTitle: FC<Props> = ({ title, isTransparent }) => (
  <Box pt="8px" pb="8px" px="12px" bg="#fafafa" height="36px">
    {isTransparent ? (
      <h4 style={{ margin: 0, padding: 0, color: '#fafafa', userSelect: 'none' }}>{title}</h4>
    ) : (
      <h4 style={{ margin: 0, padding: 0 }}>{title}</h4>
    )}
  </Box>
);

export default ZuordnungKontoKlasseTitle;
