import React, { FC, ReactNode } from 'react';
import { Col, Row, Typography } from 'antd';

type Props = {
  rowTitle: string;
  content: ReactNode;
};

const FormBlockRow: FC<Props> = ({ rowTitle, content }) => {
  return (
    <Row align="top">
      <Col span={6}>
        <Typography.Title level={5}>{rowTitle}</Typography.Title>
      </Col>
      <Col span={18} style={{ width: '100%' }}>
        {content}
      </Col>
    </Row>
  );
};

export default FormBlockRow;
