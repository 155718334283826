import React, { PropsWithChildren } from 'react';

interface IndexationAgreementContextInterface {
  bestandseinheitId: string;
  objektId: string;
  vertragId: string;
  vorschreibungspositionId: string;
}

export const IndexationAgreementContext = React.createContext<IndexationAgreementContextInterface>({
  bestandseinheitId: '',
  objektId: '',
  vertragId: '',
  vorschreibungspositionId: '',
});

export const IndexationAgreementProvider: React.FC<IndexationAgreementContextInterface & PropsWithChildren> = (props) => {
  return <IndexationAgreementContext.Provider value={props}>{props.children}</IndexationAgreementContext.Provider>;
};
