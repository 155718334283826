import React, { FC } from 'react';
import { PaginationProps } from 'antd/lib/pagination';
import { FilterValue, SorterResult, TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface';
import recipientTableColumns from './recipientTableColumns';
import { StyledRecipientTable } from './styled/Table.style';
import { InfoMailDelivery } from '../../../../../../types';

type Props = {
  infoMailDeliveryList?: InfoMailDelivery[];
  wizardId: string;
  isCompleted?: boolean;
  loading: boolean;
  onChange: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<InfoMailDelivery> | SorterResult<InfoMailDelivery>[]
  ) => void;
  onUpdate: () => void;
  pagination: PaginationProps;
  selectedRow?: InfoMailDelivery;
  setSelectedRow: (record: InfoMailDelivery) => void;
};

const RecipientTable: FC<Props> = ({
  infoMailDeliveryList,
  wizardId,
  isCompleted,
  loading,
  onChange,
  onUpdate,
  pagination,
  selectedRow,
  setSelectedRow,
}) => {
  const rowSelection: TableRowSelection<InfoMailDelivery> = {
    selectedRowKeys: selectedRow ? [selectedRow.infoMailDeliveryId] : [],
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRow(selectedRows[0]);
    },
    getCheckboxProps: (record) => {
      return {
        disabled: !record.shouldSend,
        name: record.infoMailDeliveryId,
      };
    },
    type: 'radio',
  };

  return (
    <StyledRecipientTable<InfoMailDelivery>
      size="small"
      pagination={pagination}
      dataSource={infoMailDeliveryList}
      loading={loading}
      columns={recipientTableColumns(onUpdate, wizardId, isCompleted)}
      onChange={onChange}
      rowKey={(record) => record.infoMailDeliveryId}
      filterIdentifier="main-wizard-recipient"
      rowSelection={rowSelection}
    />
  );
};

export default RecipientTable;
