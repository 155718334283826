import React from 'react';
import { Link } from 'react-router-dom';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { compareTwoStringsForSorting } from '../../../../../helpers/stringHelper';
import GenerierlaufEntryStatus from '../../../shared/GenerierlaufEntryStatus';
import { BudgetingBase, TopFinancialPlanGenerierlaufEntry } from '../../../../../types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { generatePathToRechtstraegerDetailsPage } from '../../../../Rechtstraeger/rechtstraegerHelper';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { pathsToBudgetingDetailsPage } from '../../../../Budgeting/budgetingUriPaths';
import { generatePathToObjektDetailsPage, pathsToObjektDetailsPage } from '../../../../Objekt/objektUriPaths';
import { EuroAmount } from '../../../../../components/Number';
import { generatePathToBestandseinheitDetailsPage } from '../../../../Bestandseinheit/bestandseinheitUriPaths';
import { compareTwoNumbersForSorting } from '../../../../../helpers/numberHelper';

const topFinancialPlanVerarbeitungEntryTableColumns = (
  budgeting: BudgetingBase
): TableWithColSelectorColumnProps<TopFinancialPlanGenerierlaufEntry>[] => [
  {
    title: 'Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.exitCode?.text, b.exitCode?.text),
    render: (_, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },
  {
    title: 'Budgetierung',
    defaultSelected: true,
    render: () => (
      <DataWithShortenedText text={budgeting.bezeichnung} maxTextLength={25}>
        {(shortenedText) => (
          <Link target="_blank" to={pathsToBudgetingDetailsPage(budgeting.objekt.objektId, budgeting.objekt.objektId).detailsTab}>
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Rechnungsaussteller',
    defaultSelected: true,
    render: () => (
      <DataWithShortenedText text={budgeting.objekt.rechnungsAussteller.kurzBezeichnung} maxTextLength={25}>
        {(shortenedText) => (
          <Link target="_blank" to={generatePathToRechtstraegerDetailsPage(budgeting.objekt.rechnungsAussteller.rechtstraegerId)}>
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Vertragspartner',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.vertrag?.vertragspartner.kurzBezeichnung, b.vertrag?.vertragspartner.kurzBezeichnung),
    render: (_, record) =>
      record.vertrag ? (
        <DataWithShortenedText text={record.vertrag?.vertragspartner.kurzBezeichnung} maxTextLength={25}>
          {(shortenedText) => (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <Link target="_blank" to={generatePathToRechtstraegerDetailsPage(record.vertrag!.vertragspartner.rechtstraegerId)}>
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Objekt',
    defaultSelected: true,
    render: () => (
      <DataWithShortenedText text={budgeting.objekt.kurzBezeichnung} maxTextLength={25}>
        {(shortenedText) => (
          <Link to={generatePathToObjektDetailsPage(budgeting.objekt.objektId)} target="_blank">
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Bestandseinheit',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.bestandseinheit?.bezeichnung, b.bestandseinheit?.bezeichnung),
    render: (_, record) =>
      record.bestandseinheit ? (
        <DataWithShortenedText text={record.bestandseinheit.bezeichnung} maxTextLength={25}>
          {(shortenedText) => (
            <Link
              target="_blank"
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              to={generatePathToBestandseinheitDetailsPage(record.bestandseinheit!.objektId, record.bestandseinheit!.bestandseinheitId)}
            >
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ) : (
        <Link target="_blank" to={pathsToObjektDetailsPage(budgeting.objekt.objektId).topTab}>
          Alle
        </Link>
      ),
  },
  {
    title: 'Jahresbudget Summe',
    align: 'right',
    defaultSelected: true,
    sorter: (a, b) => compareTwoNumbersForSorting(a.budgetSumNextYear, b.budgetSumNextYear),
    render: (_, record) => <EuroAmount value={record.budgetSumNextYear} />,
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (_, record) => {
      let highlightedRowKey: string | undefined;
      let expandedRowKeys: Record<string, string | string[]> | undefined;

      if (record.vertrag && record.bestandseinheit) {
        highlightedRowKey = record.vertrag.vertragId;
        expandedRowKeys = {
          bestandseinheitId: record.bestandseinheit.bestandseinheitId,
          vertragId: record.vertrag.vertragId,
        };
      }

      return (
        <Link
          to={pathsToBudgetingDetailsPage(budgeting.objekt.objektId, budgeting.budgetingId, highlightedRowKey, expandedRowKeys).resultsTab}
          target="_blank"
        >
          Details
        </Link>
      );
    },
  },
];
export default topFinancialPlanVerarbeitungEntryTableColumns;
