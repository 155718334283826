export const gqlOperations = {
  Query: {
    AbrechnungsdefinitionAbrechnungskreisSelectList: 'AbrechnungsdefinitionAbrechnungskreisSelectList',
    AbrechnungsdefinitionTemplateAbrechnungskreisSelectList: 'AbrechnungsdefinitionTemplateAbrechnungskreisSelectList',
    AbrechnungsdefinitionKontoAbrechnungskreisZuordnungList: 'AbrechnungsdefinitionKontoAbrechnungskreisZuordnungList',
    AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenId: 'AbrechnungsdefinitionListForAbrKontenzuordnungByFirmendatenId',
    KontoListForAbrKontenzuordnung: 'KontoListForAbrKontenzuordnung',
    Saldo: 'Saldo',
    KontoTemplateListForAbrKontenzuordnung: 'KontoTemplateListForAbrKontenzuordnung',
    TopAbrechnung: 'TopAbrechnung',
    ObjektAbrechnung: 'ObjektAbrechnung',
    ObjektAbrechnungDeviationList: 'ObjektAbrechnungDeviationList',
    TopAbrechnungDeviationList: 'TopAbrechnungDeviationList',
    HeAbrechnungDeviationList: 'HeAbrechnungDeviationList',
    HeAbrechnungList: 'HeAbrechnungList',
    HeAbrechnung: 'HeAbrechnung',
    HeAbrechnungHeVertragList: 'HeAbrechnungHeVertragList',
    HeAbrechnungHeVertrag: 'HeAbrechnungHeVertrag',
    SubAbrechnungEigentuemer: 'SubAbrechnungEigentuemer',
    SubAbrechnung: 'SubAbrechnung',
    AbrechnungBezeichnung: 'AbrechnungBezeichnung',
    AbrechnungList: 'AbrechnungList',
    AbrechnungTypeList: 'AbrechnungTypeList',
    AbrechnungsdefinitionList: 'AbrechnungsdefinitionList',
    AbrechnungsdefinitionTemplate: 'AbrechnungsdefinitionTemplate',
    AbrechnungsdefinitionType: 'AbrechnungsdefinitionType',
    AbrechnungsdefinitionVersionAbrechnungskreisList: 'AbrechnungsdefinitionVersionAbrechnungskreisList',
    BkAbrechnungsdefinition: 'BkAbrechnungsdefinition',
    BkAbrechnungsdefinitionVersionList: 'BkAbrechnungsdefinitionVersionList',
    BkAbrechnungsdefinitionVersion: 'BkAbrechnungsdefinitionVersion',
    HeAbrechnungsdefinition: 'HeAbrechnungsdefinition',
    HeAbrechnungsdefinitionVersionList: 'HeAbrechnungsdefinitionVersionList',
    HeAbrechnungsdefinitionVersion: 'HeAbrechnungsdefinitionVersion',
    SubAdministrationAbrechnungsdefinition: 'SubAdministrationAbrechnungsdefinition',
    SubAdministrationAbrechnungsdefinitionVersionList: 'SubAdministrationAbrechnungsdefinitionVersionList',
    SubAdministrationAbrechnungsdefinitionVersion: 'SubAdministrationAbrechnungsdefinitionVersion',
    WeAbrechnungsdefinition: 'WeAbrechnungsdefinition',
    WeAbrechnungsdefinitionVersionList: 'WeAbrechnungsdefinitionVersionList',
    WeAbrechnungsdefinitionVersion: 'WeAbrechnungsdefinitionVersion',
    AbrechnungsdefinitionTemplateList: 'AbrechnungsdefinitionTemplateList',
    AbrechnungsdefinitionKontoAbrechnungskreisTemplateList: 'AbrechnungsdefinitionKontoAbrechnungskreisTemplateList',
    AbrechnungsdefinitionTemplateType: 'AbrechnungsdefinitionTemplateType',
    AbrechnungsdefinitionTemplateVersionAbrechnungskreisList: 'AbrechnungsdefinitionTemplateVersionAbrechnungskreisList',
    BkAbrechnungsdefinitionTemplate: 'BkAbrechnungsdefinitionTemplate',
    BkAbrechnungsdefinitionTemplateVersionList: 'BkAbrechnungsdefinitionTemplateVersionList',
    BkAbrechnungsdefinitionTemplateVersion: 'BkAbrechnungsdefinitionTemplateVersion',
    HeAbrechnungsdefinitionTemplate: 'HeAbrechnungsdefinitionTemplate',
    HeAbrechnungsdefinitionTemplateVersionList: 'HeAbrechnungsdefinitionTemplateVersionList',
    HeAbrechnungsdefinitionTemplateVersion: 'HeAbrechnungsdefinitionTemplateVersion',
    SubAdministrationAbrechnungsdefinitionTemplate: 'SubAdministrationAbrechnungsdefinitionTemplate',
    SubAdministrationAbrechnungsdefinitionTemplateVersionList: 'SubAdministrationAbrechnungsdefinitionTemplateVersionList',
    SubAdministrationAbrechnungsdefinitionTemplateVersion: 'SubAdministrationAbrechnungsdefinitionTemplateVersion',
    WeAbrechnungsdefinitionTemplate: 'WeAbrechnungsdefinitionTemplate',
    WeAbrechnungsdefinitionTemplateVersionList: 'WeAbrechnungsdefinitionTemplateVersionList',
    WeAbrechnungsdefinitionTemplateVersion: 'WeAbrechnungsdefinitionTemplateVersion',
    AbrechnungskreisListByKundenSystemId: 'AbrechnungskreisListByKundenSystemId',
    Abrechnungskreis: 'Abrechnungskreis',
    AbrechnungskreisChangeHistoryList: 'AbrechnungskreisChangeHistoryList',
    AbrechnungskreisTypeList: 'AbrechnungskreisTypeList',
    VerrechnungUstVomAufwandList: 'VerrechnungUstVomAufwandList',
    AbrechnungskreisListForSelectByFirmendatenId: 'AbrechnungskreisListForSelectByFirmendatenId',
    AbrechnungskreisListForSelectByKundenSystemId: 'AbrechnungskreisListForSelectByKundenSystemId',
    AbrechnungskreisTemplateList: 'AbrechnungskreisTemplateList',
    AbrechnungskreisTemplate: 'AbrechnungskreisTemplate',
    AbrechnungskreisTemplateChangeHistoryList: 'AbrechnungskreisTemplateChangeHistoryList',
    AdminList: 'AdminList',
    AuditLogListForAdmin: 'AuditLogListForAdmin',
    UserChangeLogListForAdmin: 'UserChangeLogListForAdmin',
    PauschalmieteAufwandskontoList: 'PauschalmieteAufwandskontoList',
    PauschalmieteAufwandskonto: 'PauschalmieteAufwandskonto',
    PauschalmieteKontenplan: 'PauschalmieteKontenplan',
    PauschalmieteForderungsKontoChangeHistoryList: 'PauschalmieteForderungsKontoChangeHistoryList',
    FibuEinstellung: 'FibuEinstellung',
    FirmendatenBuchhaltungEinstellung: 'FirmendatenBuchhaltungEinstellung',
    LeerstehungAufwandskonto: 'LeerstehungAufwandskonto',
    LeerstehungAufwandskontoList: 'LeerstehungAufwandskontoList',
    LeerstehungForderungsKontoChangeHistoryList: 'LeerstehungForderungsKontoChangeHistoryList',
    BillingChangeHistory: 'BillingChangeHistory',
    BezugsbasisList: 'BezugsbasisList',
    MasseinheitList: 'MasseinheitList',
    KSAufteilungsschluesselList: 'KSAufteilungsschluesselList',
    FDAufteilungsschluesselList: 'FDAufteilungsschluesselList',
    Aufteilungsschluessel: 'Aufteilungsschluessel',
    AufteilungsschluesselChangeHistoryList: 'AufteilungsschluesselChangeHistoryList',
    AufteilungsschluesselListForSelectByFirmendatenId: 'AufteilungsschluesselListForSelectByFirmendatenId',
    AufteilungsschluesselListForSelectByKundenSystemId: 'AufteilungsschluesselListForSelectByKundenSystemId',
    AufteilungsschluesselTemplateList: 'AufteilungsschluesselTemplateList',
    AufteilungsschluesselTemplate: 'AufteilungsschluesselTemplate',
    AufteilungsschluesselTemplateChangeHistoryList: 'AufteilungsschluesselTemplateChangeHistoryList',
    AuftragsartTemplates: 'AuftragsartTemplates',
    AuftragsartList: 'AuftragsartList',
    AuftragsartListForStornoSelect: 'AuftragsartListForStornoSelect',
    Auftragsart: 'Auftragsart',
    AuftragsartKurzbezeichnung: 'AuftragsartKurzbezeichnung',
    AuftragsartTypeList: 'AuftragsartTypeList',
    AuftragsartGeneralChangeHistoryList: 'AuftragsartGeneralChangeHistoryList',
    AuftragsartTexteChangeHistoryList: 'AuftragsartTexteChangeHistoryList',
    AuftragsartTemplatetList: 'AuftragsartTemplatetList',
    AuftragsartTemplateListForStornoSelect: 'AuftragsartTemplateListForStornoSelect',
    AuftragsartTemplate: 'AuftragsartTemplate',
    AuftragsartTemplateGeneralChangeHistoryList: 'AuftragsartTemplateGeneralChangeHistoryList',
    AuftragsartTemplateTexteChangeHistoryList: 'AuftragsartTemplateTexteChangeHistoryList',
    SepaMandatChangeHistoryList: 'SepaMandatChangeHistoryList',
    BankDetailsList: 'BankDetailsList',
    BankAccountChangeHistoryList: 'BankAccountChangeHistoryList',
    NextSepaMandatReference: 'NextSepaMandatReference',
    FirmendatenBankDetails: 'FirmendatenBankDetails',
    FirmendatenBankDetailsList: 'FirmendatenBankDetailsList',
    RechtstraegerBankDetails: 'RechtstraegerBankDetails',
    RechtstraegerBankDetailsList: 'RechtstraegerBankDetailsList',
    RechtstraegerBankDetailsChangeHistoryList: 'RechtstraegerBankDetailsChangeHistoryList',
    FirmendatenBankDetailsChangeHistoryList: 'FirmendatenBankDetailsChangeHistoryList',
    WeRechnungsAusstellerList: 'WeRechnungsAusstellerList',
    FirmendatenBankSettings: 'FirmendatenBankSettings',
    FirmendatenBankSettingsChangeHistoryList: 'FirmendatenBankSettingsChangeHistoryList',
    FibuBelegSymbolList: 'FibuBelegSymbolList',
    PaymentBelegSymbolList: 'PaymentBelegSymbolList',
    BelegnummernkreisDefinitionList: 'BelegnummernkreisDefinitionList',
    BelegnummernkreisDefinition: 'BelegnummernkreisDefinition',
    BelegnummernkreisDefinitionTemplates: 'BelegnummernkreisDefinitionTemplates',
    BelegnummernkreisDefinitionChangeHistoryList: 'BelegnummernkreisDefinitionChangeHistoryList',
    BelegTexteRechtstraeger: 'BelegTexteRechtstraeger',
    BelegTexteFirmendaten: 'BelegTexteFirmendaten',
    BelegTexteRechtstraegerTemplates: 'BelegTexteRechtstraegerTemplates',
    BelegTexteFirmendatenTemplates: 'BelegTexteFirmendatenTemplates',
    BelegTexteRechtstraegerChangeHistoryList: 'BelegTexteRechtstraegerChangeHistoryList',
    BestandseinheitAufteilungsschluesselList: 'BestandseinheitAufteilungsschluesselList',
    BestandseinheitAufteilungsschluesselWertAenderungList: 'BestandseinheitAufteilungsschluesselWertAenderungList',
    BestandseinheitAufteilungsschluesselWertChangeHistoryList: 'BestandseinheitAufteilungsschluesselWertChangeHistoryList',
    BeInfoFeldList: 'BeInfoFeldList',
    BeInfoFeld: 'BeInfoFeld',
    BeInfoFeldTypeList: 'BeInfoFeldTypeList',
    BeOptionSteuerpflichtVersionList: 'BeOptionSteuerpflichtVersionList',
    BeOptionSteuerpflichtVersion: 'BeOptionSteuerpflichtVersion',
    BestandseinheitNutzflaecheVersionList: 'BestandseinheitNutzflaecheVersionList',
    BestandseinheitNutzflaecheVersion: 'BestandseinheitNutzflaecheVersion',
    BeNutzungsartVersionList: 'BeNutzungsartVersionList',
    BeNutzungsArtVersion: 'BeNutzungsArtVersion',
    BestandseinheitNutzwertVersionList: 'BestandseinheitNutzwertVersionList',
    BestandseinheitNutzwertVersion: 'BestandseinheitNutzwertVersion',
    Bestandseinheit: 'Bestandseinheit',
    BestandseinheitChangeHistoryList: 'BestandseinheitChangeHistoryList',
    BestandseinheitList: 'BestandseinheitList',
    BestandseinheitBezeichnung: 'BestandseinheitBezeichnung',
    BestandseinheitNutzflaecheByDate: 'BestandseinheitNutzflaecheByDate',
    BestandseinheitNutzwertByDate: 'BestandseinheitNutzwertByDate',
    BestandseinheitNutzung: 'BestandseinheitNutzung',
    BeVertragList: 'BeVertragList',
    AuszifferungList: 'AuszifferungList',
    AuszifferungListWithOffenePosten: 'AuszifferungListWithOffenePosten',
    Buchung: 'Buchung',
    BuchungList: 'BuchungList',
    BuchungszeilenWithDeviations: 'BuchungszeilenWithDeviations',
    BuchungszeileListWithUmbuchungen: 'BuchungszeileListWithUmbuchungen',
    FilterBuchungBelegnummerList: 'FilterBuchungBelegnummerList',
    FilterBuchungVertragspartnerList: 'FilterBuchungVertragspartnerList',
    BuchungsanweisungDeviationList: 'BuchungsanweisungDeviationList',
    FibuBuchungsanweisungList: 'FibuBuchungsanweisungList',
    FilterFibuBuchungsanweisungBelegnummerList: 'FilterFibuBuchungsanweisungBelegnummerList',
    FilterFibuBuchungsanweisungBestandeinheitList: 'FilterFibuBuchungsanweisungBestandeinheitList',
    FilterFibuBuchungsanweisungBuchungskreisRechtstraegerList: 'FilterFibuBuchungsanweisungBuchungskreisRechtstraegerList',
    FilterFibuBuchungsanweisungObjektList: 'FilterFibuBuchungsanweisungObjektList',
    FilterFibuBuchungsanweisungVertragspartnerList: 'FilterFibuBuchungsanweisungVertragspartnerList',
    FilterFibuBuchungsanweisungZuStornierendeBuchungList: 'FilterFibuBuchungsanweisungZuStornierendeBuchungList',
    FibuBuchungszeileList: 'FibuBuchungszeileList',
    FibuBuchungszeileListSummary: 'FibuBuchungszeileListSummary',
    FilterFibuBzBestandseinheitList: 'FilterFibuBzBestandseinheitList',
    FilterFibuBzBuchungKZList: 'FilterFibuBzBuchungKZList',
    FilterFibuBzGegenKontoList: 'FilterFibuBzGegenKontoList',
    FilterFibuBzKontoList: 'FilterFibuBzKontoList',
    FilterFibuBzObjektList: 'FilterFibuBzObjektList',
    FilterFibuBzTexteList: 'FilterFibuBzTexteList',
    BookingSuggestion: 'BookingSuggestion',
    BookingSuggestionWithCurrentBookingData: 'BookingSuggestionWithCurrentBookingData',
    BookingSuggestionList: 'BookingSuggestionList',
    BookingSuggestionBuchungszeileChangeHistoryList: 'BookingSuggestionBuchungszeileChangeHistoryList',
    AbrechnungsdefinitionKurzBezeichnung: 'AbrechnungsdefinitionKurzBezeichnung',
    AbrechnungsdefinitionTemplateKurzBezeichnung: 'AbrechnungsdefinitionTemplateKurzBezeichnung',
    ObjektKurzBezeichnung: 'ObjektKurzBezeichnung',
    BestandseinheitKurzBezeichnung: 'BestandseinheitKurzBezeichnung',
    BeVertragKurzBezeichnung: 'BeVertragKurzBezeichnung',
    HeVertragKurzBezeichnung: 'HeVertragKurzBezeichnung',
    KundenSystemKurzBezeichnung: 'KundenSystemKurzBezeichnung',
    FirmendatenKurzBezeichnung: 'FirmendatenKurzBezeichnung',
    KontoKurzBezeichnung: 'KontoKurzBezeichnung',
    KontoTemplateKurzBezeichnung: 'KontoTemplateKurzBezeichnung',
    WeAbrechnungsdefinitionKurzBezeichnung: 'WeAbrechnungsdefinitionKurzBezeichnung',
    SingleInvoiceKurzBezeichnung: 'SingleInvoiceKurzBezeichnung',
    StandingInvoiceKurzBezeichnung: 'StandingInvoiceKurzBezeichnung',
    ObjektAbrKurzBezeichnung: 'ObjektAbrKurzBezeichnung',
    RechtstraegerKurzBezeichnung: 'RechtstraegerKurzBezeichnung',
    AuftragKurzBezeichnung: 'AuftragKurzBezeichnung',
    TopAbrechnungKurzBezeichnung: 'TopAbrechnungKurzBezeichnung',
    HeAbrechnungKurzBezeichnung: 'HeAbrechnungKurzBezeichnung',
    HeAbrechnungHeVertragKurzBezeichnung: 'HeAbrechnungHeVertragKurzBezeichnung',
    FibuBuchungTypeList: 'FibuBuchungTypeList',
    PaymentBuchungTypeList: 'PaymentBuchungTypeList',
    BudgetingList: 'BudgetingList',
    Budgeting: 'Budgeting',
    BudgetingBase: 'BudgetingBase',
    BudgetingResult: 'BudgetingResult',
    BudgetingResultByVertragLevel: 'BudgetingResultByVertragLevel',
    FinancialPlanTexts: 'FinancialPlanTexts',
    TopBudgetSumNextYear: 'TopBudgetSumNextYear',
    BudgetingAbrDefVPosLinkageList: 'BudgetingAbrDefVPosLinkageList',
    BudgetingAbrDefVPosLinkage: 'BudgetingAbrDefVPosLinkage',
    BudgetingAbrDefVPosLinkageChangeHistoryList: 'BudgetingAbrDefVPosLinkageChangeHistoryList',
    BudgetingAbrDefVPosLinkageTemplateList: 'BudgetingAbrDefVPosLinkageTemplateList',
    BudgetingAbrDefVPosLinkageTemplate: 'BudgetingAbrDefVPosLinkageTemplate',
    BudgetingAbrDefVPosLinkageTemplateChangeHistoryList: 'BudgetingAbrDefVPosLinkageTemplateChangeHistoryList',
    BudgetingVPosAuftschluesselLinkageList: 'BudgetingVPosAuftschluesselLinkageList',
    BudgetingVPosAuftschluesselLinkage: 'BudgetingVPosAuftschluesselLinkage',
    BudgetingVPosAuftschluesselLinkageChangeHistoryList: 'BudgetingVPosAuftschluesselLinkageChangeHistoryList',
    BudgetingVPosAuftschluesselLinkageTemplateList: 'BudgetingVPosAuftschluesselLinkageTemplateList',
    BudgetingVPosAuftschluesselLinkageTemplate: 'BudgetingVPosAuftschluesselLinkageTemplate',
    BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryList: 'BudgetingVPosAuftschluesselLinkageTemplateChangeHistoryList',
    CallLogList: 'CallLogList',
    Last5CallLogList: 'Last5CallLogList',
    IbanFibuKontoList: 'IbanFibuKontoList',
    IbanFibuKontoAccountHolderList: 'IbanFibuKontoAccountHolderList',
    IbanFibuKontoBanknameList: 'IbanFibuKontoBanknameList',
    IbanFibuKontoBicList: 'IbanFibuKontoBicList',
    IbanFibuKontoFibuAccountNumberList: 'IbanFibuKontoFibuAccountNumberList',
    IbanFibuKontoIbanList: 'IbanFibuKontoIbanList',
    DataCarrierList: 'DataCarrierList',
    DataCarrierPainList: 'DataCarrierPainList',
    DataCarrierPainIbanList: 'DataCarrierPainIbanList',
    DataCarrierPainNameList: 'DataCarrierPainNameList',
    DataCarrierPainPaymentProposalNameList: 'DataCarrierPainPaymentProposalNameList',
    DataCarrierCamtList: 'DataCarrierCamtList',
    DataCarrierCamtIbanList: 'DataCarrierCamtIbanList',
    DataCarrierCamtNameList: 'DataCarrierCamtNameList',
    DataCarrierCamtStatementNumberList: 'DataCarrierCamtStatementNumberList',
    DataCarrierPainStatusList: 'DataCarrierPainStatusList',
    DateUnitList: 'DateUnitList',
    FileInfoListForFirmendaten: 'FileInfoListForFirmendaten',
    FileInfoForFirmendaten: 'FileInfoForFirmendaten',
    FileInfoListForMitarbeiter: 'FileInfoListForMitarbeiter',
    FileInfoForMitarbeiter: 'FileInfoForMitarbeiter',
    FirmendatenFileInfoBestandseinheitList: 'FirmendatenFileInfoBestandseinheitList',
    FirmendatenFileInfoInvoiceNumberList: 'FirmendatenFileInfoInvoiceNumberList',
    FirmendatenFileInfoHomeownerList: 'FirmendatenFileInfoHomeownerList',
    FirmendatenFileInfoInvoiceIssuerList: 'FirmendatenFileInfoInvoiceIssuerList',
    FirmendatenFileInfoInvoiceRecipientList: 'FirmendatenFileInfoInvoiceRecipientList',
    FirmendatenFileInfoObjektList: 'FirmendatenFileInfoObjektList',
    InfoMailDelivery: 'InfoMailDelivery',
    VorschreibungMailDelivery: 'VorschreibungMailDelivery',
    MailList: 'MailList',
    MailStatusList: 'MailStatusList',
    Mail: 'Mail',
    MailUseCaseList: 'MailUseCaseList',
    EmailDeliveryDefinitionList: 'EmailDeliveryDefinitionList',
    EmailDeliveryDefinition: 'EmailDeliveryDefinition',
    EmailDeliveryDefinitionTemplates: 'EmailDeliveryDefinitionTemplates',
    EmailDeliveryDefinitionKurzbezeichnung: 'EmailDeliveryDefinitionKurzbezeichnung',
    EmailDeliveryTypeList: 'EmailDeliveryTypeList',
    EmailDeliveryDefinitionChangeHistoryList: 'EmailDeliveryDefinitionChangeHistoryList',
    EmailDeliveryDefinitionTemplateList: 'EmailDeliveryDefinitionTemplateList',
    EmailDeliveryDefinitionTemplate: 'EmailDeliveryDefinitionTemplate',
    EmailDeliveryDefinitionTemplateChangeHistoryList: 'EmailDeliveryDefinitionTemplateChangeHistoryList',
    EmailIntegration: 'EmailIntegration',
    AuthenticateForEmailIntegration: 'AuthenticateForEmailIntegration',
    AppFieldHelpList: 'AppFieldHelpList',
    FibuFieldHelpList: 'FibuFieldHelpList',
    OrderFieldHelpList: 'OrderFieldHelpList',
    PaymentFieldHelpList: 'PaymentFieldHelpList',
    FirmendatenIndexSeriesList: 'FirmendatenIndexSeriesList',
    FirmendatenIndexSeries: 'FirmendatenIndexSeries',
    FirmendatenIndexSeriesFinalValue: 'FirmendatenIndexSeriesFinalValue',
    FirmendatenList: 'FirmendatenList',
    FirmendatenObjektAbrechnungList: 'FirmendatenObjektAbrechnungList',
    IncomingInvoiceAusstellerList: 'IncomingInvoiceAusstellerList',
    IncomingInvoiceAusstellerBankDetailsList: 'IncomingInvoiceAusstellerBankDetailsList',
    IncomingInvoiceKontoList: 'IncomingInvoiceKontoList',
    IncomingInvoicePayeeList: 'IncomingInvoicePayeeList',
    IncomingInvoicePayeeBankDetailsList: 'IncomingInvoicePayeeBankDetailsList',
    IncomingInvoiceKundennummerList: 'IncomingInvoiceKundennummerList',
    IncomingInvoicePaymentReferenceTextList: 'IncomingInvoicePaymentReferenceTextList',
    IncomingInvoicePurposeOfUseTextList: 'IncomingInvoicePurposeOfUseTextList',
    IncomingInvoiceSepaMandateReferenceList: 'IncomingInvoiceSepaMandateReferenceList',
    IncomingInvoiceInvoiceNumberList: 'IncomingInvoiceInvoiceNumberList',
    IncomingInvoiceList: 'IncomingInvoiceList',
    IncomingInvoiceListSummary: 'IncomingInvoiceListSummary',
    IncomingInvoice: 'IncomingInvoice',
    SingleInvoice: 'SingleInvoice',
    SingleInvoiceForDetailsAndUpdatePage: 'SingleInvoiceForDetailsAndUpdatePage',
    SingleInvoiceChangeHistory: 'SingleInvoiceChangeHistory',
    StandingInvoice: 'StandingInvoice',
    StandingInvoiceForDetailsAndUpdatePage: 'StandingInvoiceForDetailsAndUpdatePage',
    StandingInvoiceChangeHistory: 'StandingInvoiceChangeHistory',
    EingangsRechnungVerrechnungsartList: 'EingangsRechnungVerrechnungsartList',
    IndexSeriesList: 'IndexSeriesList',
    IndexSeries: 'IndexSeries',
    IndexSeriesEntryList: 'IndexSeriesEntryList',
    IndexSeriesEntry: 'IndexSeriesEntry',
    IndexValueCreationCronJobSettings: 'IndexValueCreationCronJobSettings',
    IndexValueCreationCronJobSettingsChangeHistoryList: 'IndexValueCreationCronJobSettingsChangeHistoryList',
    VertragVposIndexedValueListFirmendaten: 'VertragVposIndexedValueListFirmendaten',
    VertragVposIndexedValueListSummary: 'VertragVposIndexedValueListSummary',
    VertragVposIndexedValueList: 'VertragVposIndexedValueList',
    CalculateVertragVposIndexedValueAmount: 'CalculateVertragVposIndexedValueAmount',
    CalculateVertragVposIndexedValueFactor: 'CalculateVertragVposIndexedValueFactor',
    VertragVposIndexedValueChangeHistoryList: 'VertragVposIndexedValueChangeHistoryList',
    VertragVposIndexedValueStatusList: 'VertragVposIndexedValueStatusList',
    KSFirmendatenSelectList: 'KSFirmendatenSelectList',
    Kontenplan: 'Kontenplan',
    KontoList: 'KontoList',
    Konto: 'Konto',
    KontoChangeHistoryList: 'KontoChangeHistoryList',
    KontoKlasseList: 'KontoKlasseList',
    KontoTypeList: 'KontoTypeList',
    KontoVerwendungList: 'KontoVerwendungList',
    KontoTemplateList: 'KontoTemplateList',
    KontoTemplate: 'KontoTemplate',
    KontoTemplateChangeHistoryList: 'KontoTemplateChangeHistoryList',
    KontenRahmen: 'KontenRahmen',
    KontierungstabelleListForSelectByKundenSystemId: 'KontierungstabelleListForSelectByKundenSystemId',
    KontierungstabelleListForSelectByFirmendatenId: 'KontierungstabelleListForSelectByFirmendatenId',
    KontierungstabelleList: 'KontierungstabelleList',
    KontierungstabelleChangeHistoryList: 'KontierungstabelleChangeHistoryList',
    KontierungstabelleTemplateList: 'KontierungstabelleTemplateList',
    KontierungstabelleTemplate: 'KontierungstabelleTemplate',
    KontierungstabelleTemplateChangeHistoryList: 'KontierungstabelleTemplateChangeHistoryList',
    KontoSelectList: 'KontoSelectList',
    ReportLogos: 'ReportLogos',
    FirmendatenOperatingSiteList: 'FirmendatenOperatingSiteList',
    FirmendatenOperatingSite: 'FirmendatenOperatingSite',
    Firmendaten: 'Firmendaten',
    Firmeneinstellungen: 'Firmeneinstellungen',
    FirmendatenContactPersonDeletionHistoryList: 'FirmendatenContactPersonDeletionHistoryList',
    FirmendatenEmailContactDeletionHistoryList: 'FirmendatenEmailContactDeletionHistoryList',
    FirmendatenOperatingSiteDeletionHistoryList: 'FirmendatenOperatingSiteDeletionHistoryList',
    FirmendatenPhoneContactDeletionHistoryList: 'FirmendatenPhoneContactDeletionHistoryList',
    FirmendatenPostofficeBoxAddressDeletionHistoryList: 'FirmendatenPostofficeBoxAddressDeletionHistoryList',
    FirmendatenStreetAddressDeletionHistoryList: 'FirmendatenStreetAddressDeletionHistoryList',
    FirmendatenObjektVorschreibungspositionNameList: 'FirmendatenObjektVorschreibungspositionNameList',
    KundenSystemList: 'KundenSystemList',
    KundenSystem: 'KundenSystem',
    MahndefinitionList: 'MahndefinitionList',
    Mahndefinition: 'Mahndefinition',
    MahndefinitionTemplate: 'MahndefinitionTemplate',
    MahndefinitionChangeHistoryList: 'MahndefinitionChangeHistoryList',
    MahnstufeChangeHistoryList: 'MahnstufeChangeHistoryList',
    MahnungTemplateChangeHistoryList: 'MahnungTemplateChangeHistoryList',
    MahndefinitionTemplateList: 'MahndefinitionTemplateList',
    MahndefinitionTemplateSingle: 'MahndefinitionTemplateSingle',
    MahndefinitionTemplateChangeHistoryList: 'MahndefinitionTemplateChangeHistoryList',
    MahnstufeTemplateChangeHistoryList: 'MahnstufeTemplateChangeHistoryList',
    MahnungTemplateTemplateChangeHistoryList: 'MahnungTemplateTemplateChangeHistoryList',
    MahnungList: 'MahnungList',
    MahnungListSummary: 'MahnungListSummary',
    MahnungObjektList: 'MahnungObjektList',
    MahnungStatusList: 'MahnungStatusList',
    Mahnung: 'Mahnung',
    SumVerzugszinsen: 'SumVerzugszinsen',
    MahnungChangeHistoryList: 'MahnungChangeHistoryList',
    MahnPositionChangeHistoryList: 'MahnPositionChangeHistoryList',
    MahnPositionDeletionHistoryList: 'MahnPositionDeletionHistoryList',
    MitarbeiterListByFirmendatenId: 'MitarbeiterListByFirmendatenId',
    MitarbeiterByFirmendatenId: 'MitarbeiterByFirmendatenId',
    NotificationList: 'NotificationList',
    ObjektAufteilungsschluesselList: 'ObjektAufteilungsschluesselList',
    ObjektAufteilungsschluesselChangeHistoryList: 'ObjektAufteilungsschluesselChangeHistoryList',
    ObjektBankDetailsChangeHistoryList: 'ObjektBankDetailsChangeHistoryList',
    ObjektBankDetailsList: 'ObjektBankDetailsList',
    ObjektBankDetailsVerwendungList: 'ObjektBankDetailsVerwendungList',
    ObjektInfoFeldList: 'ObjektInfoFeldList',
    ObjektInfoFeld: 'ObjektInfoFeld',
    ObjektInfoFeldTypeList: 'ObjektInfoFeldTypeList',
    ObjektSummeAnteileList: 'ObjektSummeAnteileList',
    ObjektSummeAnteile: 'ObjektSummeAnteile',
    ObjektVorschreibungspositionList: 'ObjektVorschreibungspositionList',
    ObjektVorschreibungspositionChangeHistoryList: 'ObjektVorschreibungspositionChangeHistoryList',
    Objekt: 'Objekt',
    ObjektChangeHistoryList: 'ObjektChangeHistoryList',
    ObjektList: 'ObjektList',
    ObjektZinsliste: 'ObjektZinsliste',
    SummeAnteile: 'SummeAnteile',
    VerwaltungsartList: 'VerwaltungsartList',
    OffenePosten: 'OffenePosten',
    OffenePostenList: 'OffenePostenList',
    OffenePostenListSummary: 'OffenePostenListSummary',
    OffenePostenKontoTypeList: 'OffenePostenKontoTypeList',
    OffenePostenBelegnummerList: 'OffenePostenBelegnummerList',
    FilterPaymentApprovedByMitarbeiterList: 'FilterPaymentApprovedByMitarbeiterList',
    FilterPaymentBelegnummerList: 'FilterPaymentBelegnummerList',
    FilterPaymentInvoiceIssuerIbanList: 'FilterPaymentInvoiceIssuerIbanList',
    FilterPaymentPayeeIbanList: 'FilterPaymentPayeeIbanList',
    FilterPaymentSepaCreditorIdList: 'FilterPaymentSepaCreditorIdList',
    FilterPaymentSepaMandateReferenceList: 'FilterPaymentSepaMandateReferenceList',
    FilterPaymentStatusList: 'FilterPaymentStatusList',
    FilterPaymentTransactionTypeList: 'FilterPaymentTransactionTypeList',
    FilterPaymentTypeList: 'FilterPaymentTypeList',
    FilterPaymentReferenceTextList: 'FilterPaymentReferenceTextList',
    FilterPaymentPurposeOfUseTextList: 'FilterPaymentPurposeOfUseTextList',
    PaymentApprovalStatusList: 'PaymentApprovalStatusList',
    PaymentChangeHistoryList: 'PaymentChangeHistoryList',
    PaymentList: 'PaymentList',
    PaymentListSummary: 'PaymentListSummary',
    TransactionInstructionList: 'TransactionInstructionList',
    PaymentClearingList: 'PaymentClearingList',
    PaymentClearingListSummary: 'PaymentClearingListSummary',
    PaymentClearingBookingList: 'PaymentClearingBookingList',
    FilterPaymentClearingBuchungskreisList: 'FilterPaymentClearingBuchungskreisList',
    FilterPaymentClearingKontoList: 'FilterPaymentClearingKontoList',
    FilterPaymentClearingKontoVerwendungList: 'FilterPaymentClearingKontoVerwendungList',
    FilterPaymentClearingVertragspartnerList: 'FilterPaymentClearingVertragspartnerList',
    PaymentMethodList: 'PaymentMethodList',
    PaymentProposalList: 'PaymentProposalList',
    PaymentProposalName: 'PaymentProposalName',
    EbicsBankConfigList: 'EbicsBankConfigList',
    EbicsBankConfig: 'EbicsBankConfig',
    EbicsVersionList: 'EbicsVersionList',
    ActiveEbicsUserIbanList: 'ActiveEbicsUserIbanList',
    EbicsUserIbanList: 'EbicsUserIbanList',
    FirmendatenEbicsUserIbanList: 'FirmendatenEbicsUserIbanList',
    EbicsUserIbanChangeHistory: 'EbicsUserIbanChangeHistory',
    EbicsUserList: 'EbicsUserList',
    EbicsUser: 'EbicsUser',
    EbicsUserStatusList: 'EbicsUserStatusList',
    GeneratePassword: 'GeneratePassword',
    Pain001SchemaList: 'Pain001SchemaList',
    Pain008SchemaList: 'Pain008SchemaList',
    UserMe: 'UserMe',
    ReceivedBankTransactionList: 'ReceivedBankTransactionList',
    ReceivedBankTransactionListSummary: 'ReceivedBankTransactionListSummary',
    ReceivedBankTransactionListForPayment: 'ReceivedBankTransactionListForPayment',
    FilterReceivedBankTransactionCreditorList: 'FilterReceivedBankTransactionCreditorList',
    FilterReceivedBankTransactionCreditorIbanList: 'FilterReceivedBankTransactionCreditorIbanList',
    FilterReceivedBankTransactionDebtorList: 'FilterReceivedBankTransactionDebtorList',
    FilterReceivedBankTransactionDebtorIbanList: 'FilterReceivedBankTransactionDebtorIbanList',
    FilterReceivedBankTransactionPaymentReferenceTextList: 'FilterReceivedBankTransactionPaymentReferenceTextList',
    FilterReceivedBankTransactionPurposeOfUseTextList: 'FilterReceivedBankTransactionPurposeOfUseTextList',
    FilterReceivedBankTransactionStatementNumberList: 'FilterReceivedBankTransactionStatementNumberList',
    FilterReceivedBankTransactionEndToEndIdList: 'FilterReceivedBankTransactionEndToEndIdList',
    FilterReceivedBankTransactionStatusList: 'FilterReceivedBankTransactionStatusList',
    FilterReceivedBankTransactionBankstatementIbanList: 'FilterReceivedBankTransactionBankstatementIbanList',
    TransactionTypeList: 'TransactionTypeList',
    RechnungsAusstellerBestandseinheitList: 'RechnungsAusstellerBestandseinheitList',
    RechnungsAusstellerList: 'RechnungsAusstellerList',
    RechtstraegerAddressesAndContacts: 'RechtstraegerAddressesAndContacts',
    FirmendatenAddressesAndContacts: 'FirmendatenAddressesAndContacts',
    AddressChangeHistorList: 'AddressChangeHistorList',
    ContactChangeHistorList: 'ContactChangeHistorList',
    FirmaOperatingSiteList: 'FirmaOperatingSiteList',
    FirmaOperatingSite: 'FirmaOperatingSite',
    FirmaOperatingSiteChangeHistoryList: 'FirmaOperatingSiteChangeHistoryList',
    Firma: 'Firma',
    FirmaChangeHistoryList: 'FirmaChangeHistoryList',
    FirmaContactPersonDeletionHistoryList: 'FirmaContactPersonDeletionHistoryList',
    FirmaOperatingSiteDeletionHistoryList: 'FirmaOperatingSiteDeletionHistoryList',
    RechtstraegerInfoFeldList: 'RechtstraegerInfoFeldList',
    RechtstraegerInfoFeld: 'RechtstraegerInfoFeld',
    NatuerlichePerson: 'NatuerlichePerson',
    NatuerlichePersonChangeHistoryList: 'NatuerlichePersonChangeHistoryList',
    PersonsCommunityTypeList: 'PersonsCommunityTypeList',
    PersonenGemeinschaft: 'PersonenGemeinschaft',
    PersonenGemeinschaftChangeHistoryList: 'PersonenGemeinschaftChangeHistoryList',
    PersonenbezugChangeHistorList: 'PersonenbezugChangeHistorList',
    RechnungsAussteller: 'RechnungsAussteller',
    RechnungsAusstellerMahndefinitionChangeHistoryList: 'RechnungsAusstellerMahndefinitionChangeHistoryList',
    AbrechnungsdefinitionChangeHistoryList: 'AbrechnungsdefinitionChangeHistoryList',
    RechnungsAusstellerBankSettingsChangeHistoryList: 'RechnungsAusstellerBankSettingsChangeHistoryList',
    RepFondsErloesKontenZuweisungDeletionHistoryList: 'RepFondsErloesKontenZuweisungDeletionHistoryList',
    SubAdministrationSettingsChangeHistory: 'SubAdministrationSettingsChangeHistory',
    RechtsTraegerVertragspartner: 'RechtsTraegerVertragspartner',
    MahnsperreChangeHistoryList: 'MahnsperreChangeHistoryList',
    RechtsTraegerRolleList: 'RechtsTraegerRolleList',
    SpracheList: 'SpracheList',
    RechtstraegerSteuerpflichtList: 'RechtstraegerSteuerpflichtList',
    Rechtstraeger: 'Rechtstraeger',
    RechtstraegerList: 'RechtstraegerList',
    RechtstraegerType: 'RechtstraegerType',
    ContactPersonChangeHistorList: 'ContactPersonChangeHistorList',
    RechtstraegerEmailContactDeletionHistoryList: 'RechtstraegerEmailContactDeletionHistoryList',
    RechtstraegerPhoneContactDeletionHistoryList: 'RechtstraegerPhoneContactDeletionHistoryList',
    RechtstraegerPostofficeBoxAddressDeletionHistoryList: 'RechtstraegerPostofficeBoxAddressDeletionHistoryList',
    RechtstraegerStreetAddressDeletionHistoryList: 'RechtstraegerStreetAddressDeletionHistoryList',
    RechtstraegerAndEmailList: 'RechtstraegerAndEmailList',
    RechtstraegerTagList: 'RechtstraegerTagList',
    RechtstraegerKundennummerList: 'RechtstraegerKundennummerList',
    RechtstraegerContactList: 'RechtstraegerContactList',
    RepFondsEinstellungList: 'RepFondsEinstellungList',
    RepFondsErloesKontenZuweisungList: 'RepFondsErloesKontenZuweisungList',
    RepFondsErloesKontenZuweisung: 'RepFondsErloesKontenZuweisung',
    RepFondsErloesKontenZuweisungChangeHistoryList: 'RepFondsErloesKontenZuweisungChangeHistoryList',
    RepFondsEinstellungTemplateList: 'RepFondsEinstellungTemplateList',
    Saldenliste: 'Saldenliste',
    SaldenlisteSummary: 'SaldenlisteSummary',
    SaldenlisteKontoTypeList: 'SaldenlisteKontoTypeList',
    SubAdministrationList: 'SubAdministrationList',
    SubAdministration: 'SubAdministration',
    SubAdministrationChangeHistory: 'SubAdministrationChangeHistory',
    GeneralTextbausteinTypeList: 'GeneralTextbausteinTypeList',
    TextbausteinList: 'TextbausteinList',
    TextbausteinTemplates: 'TextbausteinTemplates',
    TextbausteinChangeHistoryList: 'TextbausteinChangeHistoryList',
    TextbausteinTemplateList: 'TextbausteinTemplateList',
    TextbausteinTemplateChangeHistoryList: 'TextbausteinTemplateChangeHistoryList',
    MitarbeiterTicketCountList: 'MitarbeiterTicketCountList',
    TicketList: 'TicketList',
    Ticket: 'Ticket',
    MyTicketList: 'MyTicketList',
    TicketChangeEntryList: 'TicketChangeEntryList',
    TicketTypeNameList: 'TicketTypeNameList',
    SimilarTicketList: 'SimilarTicketList',
    TicketStatusList: 'TicketStatusList',
    TicketPriorityList: 'TicketPriorityList',
    TicketCommentList: 'TicketCommentList',
    TicketTemplateList: 'TicketTemplateList',
    TicketTemplate: 'TicketTemplate',
    TicketTemplateChangeHistoryList: 'TicketTemplateChangeHistoryList',
    TicketTemplateTemplateList: 'TicketTemplateTemplateList',
    TicketTemplateTemplate: 'TicketTemplateTemplate',
    TicketTemplateTemplateChangeHistoryList: 'TicketTemplateTemplateChangeHistoryList',
    ColumnsSettingsList: 'ColumnsSettingsList',
    UstKategorieVersionList: 'UstKategorieVersionList',
    UstKategorieVersion: 'UstKategorieVersion',
    UstKategorieList: 'UstKategorieList',
    UstKategorie: 'UstKategorie',
    UstKategorieEntryInitialList: 'UstKategorieEntryInitialList',
    UstKategorieChangeHistoryList: 'UstKategorieChangeHistoryList',
    UstKategorieListForSelectByFirmendatenId: 'UstKategorieListForSelectByFirmendatenId',
    UstKategorieListForSelectByKundenSystemId: 'UstKategorieListForSelectByKundenSystemId',
    UstKategorieTemplateVersionList: 'UstKategorieTemplateVersionList',
    UstKategorieTemplateVersion: 'UstKategorieTemplateVersion',
    UstKategorieTemplateList: 'UstKategorieTemplateList',
    UstKategorieTemplate: 'UstKategorieTemplate',
    UstKategorieEntryTemplateInitialList: 'UstKategorieEntryTemplateInitialList',
    UstKategorieTemplateChangeHistoryList: 'UstKategorieTemplateChangeHistoryList',
    UstRegelsetUstKategorieZuweisungList: 'UstRegelsetUstKategorieZuweisungList',
    UstRegelsetUstKategorieZuweisung: 'UstRegelsetUstKategorieZuweisung',
    UstRegelsetList: 'UstRegelsetList',
    UstRegelsetTemplateUstKategorieZuweisungList: 'UstRegelsetTemplateUstKategorieZuweisungList',
    UstRegelsetTemplateUstKategorieZuweisung: 'UstRegelsetTemplateUstKategorieZuweisung',
    UstRegelsetTemplateList: 'UstRegelsetTemplateList',
    UstRegelsetTemplate: 'UstRegelsetTemplate',
    UstRegelsetTemplateChangeHistoryList: 'UstRegelsetTemplateChangeHistoryList',
    UstVomAufwandDeviationList: 'UstVomAufwandDeviationList',
    UstVomAufwandList: 'UstVomAufwandList',
    UstVomAufwand: 'UstVomAufwand',
    UstVomAufwandBuchungStatusList: 'UstVomAufwandBuchungStatusList',
    ApplyVertragVposIndexedValueGenerierlauf: 'ApplyVertragVposIndexedValueGenerierlauf',
    ApplyVertragVposIndexedValueGenerierlaufEntryList: 'ApplyVertragVposIndexedValueGenerierlaufEntryList',
    AuftragGenerierlauf: 'AuftragGenerierlauf',
    AuftragGenerierlaufEntryList: 'AuftragGenerierlaufEntryList',
    AuftragVerbuchenGenerierlauf: 'AuftragVerbuchenGenerierlauf',
    AuftragVerbuchenGenerierlaufEntryList: 'AuftragVerbuchenGenerierlaufEntryList',
    EigenbelegDetails: 'EigenbelegDetails',
    EigenbelegErstellenGenerierlauf: 'EigenbelegErstellenGenerierlauf',
    EigenbelegErstellenGenerierlaufEntryList: 'EigenbelegErstellenGenerierlaufEntryList',
    BookingSuggestionCreationGenerierlauf: 'BookingSuggestionCreationGenerierlauf',
    BookingSuggestionCreationGenerierlaufEntry: 'BookingSuggestionCreationGenerierlaufEntry',
    BookingSuggestionVerbuchenGenerierlauf: 'BookingSuggestionVerbuchenGenerierlauf',
    BookingSuggestionVerbuchenGenerierlaufEntry: 'BookingSuggestionVerbuchenGenerierlaufEntry',
    BudgetingGenerierlauf: 'BudgetingGenerierlauf',
    CamtGenerierlauf: 'CamtGenerierlauf',
    CamtGenerierlaufEntryList: 'CamtGenerierlaufEntryList',
    DataCarrierPainCreationGenerierlauf: 'DataCarrierPainCreationGenerierlauf',
    DataCarrierPainCreationGenerierlaufEntryList: 'DataCarrierPainCreationGenerierlaufEntryList',
    FirmendatenInitializerGenerierlauf: 'FirmendatenInitializerGenerierlauf',
    FilterGenLaufAppBestandseinheitList: 'FilterGenLaufAppBestandseinheitList',
    FilterGenLaufAppGenerierlaufEntryStatusList: 'FilterGenLaufAppGenerierlaufEntryStatusList',
    FilterGenLaufAppHauseigentuemerList: 'FilterGenLaufAppHauseigentuemerList',
    FilterGenLaufAppIndexSeriesList: 'FilterGenLaufAppIndexSeriesList',
    FilterGenLaufAppObjektList: 'FilterGenLaufAppObjektList',
    FilterGenLaufAppRechnungsAusstellerList: 'FilterGenLaufAppRechnungsAusstellerList',
    FilterGenLaufAppRechnungsEmpfaengerList: 'FilterGenLaufAppRechnungsEmpfaengerList',
    FilterGenLaufAppValuePreservationTypeList: 'FilterGenLaufAppValuePreservationTypeList',
    FilterGenLaufAppVertragList: 'FilterGenLaufAppVertragList',
    FilterGenLaufAppVorschreibungsperiodeList: 'FilterGenLaufAppVorschreibungsperiodeList',
    FilterGenLaufAppRecipientEmailAddressList: 'FilterGenLaufAppRecipientEmailAddressList',
    FilterGenLaufAppRecipientList: 'FilterGenLaufAppRecipientList',
    FilterGenLaufAppVertragspartnerList: 'FilterGenLaufAppVertragspartnerList',
    FilterGenLaufAppEigentuemerList: 'FilterGenLaufAppEigentuemerList',
    FilterGenLaufAppWeRechnungsAusstellerList: 'FilterGenLaufAppWeRechnungsAusstellerList',
    FilterGenLaufFibuGenerierlaufEntryStatusList: 'FilterGenLaufFibuGenerierlaufEntryStatusList',
    FilterGenLaufFibuObjektList: 'FilterGenLaufFibuObjektList',
    FilterGenLaufFibuRechnungsAusstellerList: 'FilterGenLaufFibuRechnungsAusstellerList',
    FilterGenLaufOrderBelegnummerList: 'FilterGenLaufOrderBelegnummerList',
    FilterGenLaufOrderBestandseinheitList: 'FilterGenLaufOrderBestandseinheitList',
    FilterGenLaufOrderFakturierungsperiodeList: 'FilterGenLaufOrderFakturierungsperiodeList',
    FilterGenLaufOrderGenerierlaufEntryStatusList: 'FilterGenLaufOrderGenerierlaufEntryStatusList',
    FilterGenLaufOrderObjektList: 'FilterGenLaufOrderObjektList',
    FilterGenLaufOrderRechnungsAusstellerList: 'FilterGenLaufOrderRechnungsAusstellerList',
    FilterGenLaufOrderRechnungsEmpfaengerList: 'FilterGenLaufOrderRechnungsEmpfaengerList',
    FilterGenLaufOrderPaymentTransactionTypeList: 'FilterGenLaufOrderPaymentTransactionTypeList',
    FilterGenLaufOrderPaymentTypeList: 'FilterGenLaufOrderPaymentTypeList',
    FilterGenLaufOrderRecipientEmailAddressList: 'FilterGenLaufOrderRecipientEmailAddressList',
    FilterGenLaufOrderRecipientList: 'FilterGenLaufOrderRecipientList',
    FilterGenLaufOrderVertragspartnerList: 'FilterGenLaufOrderVertragspartnerList',
    FilterGenLaufPaymentBuchungskreisRechtstraegerList: 'FilterGenLaufPaymentBuchungskreisRechtstraegerList',
    FilterGenLaufPaymentCreditorList: 'FilterGenLaufPaymentCreditorList',
    FilterGenLaufPaymentDataCarrierNameList: 'FilterGenLaufPaymentDataCarrierNameList',
    FilterGenLaufPaymentDataCarrierTypeList: 'FilterGenLaufPaymentDataCarrierTypeList',
    FilterGenLaufPaymentDebtorList: 'FilterGenLaufPaymentDebtorList',
    FilterGenLaufPaymentEbicsUserNameList: 'FilterGenLaufPaymentEbicsUserNameList',
    FilterGenLaufPaymentEbicsUserUserIdList: 'FilterGenLaufPaymentEbicsUserUserIdList',
    FilterGenLaufPaymentGenerierlaufEntryStatusList: 'FilterGenLaufPaymentGenerierlaufEntryStatusList',
    FilterGenLaufPaymentIbanList: 'FilterGenLaufPaymentIbanList',
    FilterGenLaufPaymentMitarbeiterList: 'FilterGenLaufPaymentMitarbeiterList',
    FilterGenLaufPaymentObjektList: 'FilterGenLaufPaymentObjektList',
    FilterGenLaufPaymentTransactionTypeList: 'FilterGenLaufPaymentTransactionTypeList',
    FilterGenLaufPaymentTypeList: 'FilterGenLaufPaymentTypeList',
    FilterGenLaufPaymentStatementNumberList: 'FilterGenLaufPaymentStatementNumberList',
    GenerierlaufList: 'GenerierlaufList',
    GenerierlaufStatusList: 'GenerierlaufStatusList',
    GenerierlaufEntryExitCodeList: 'GenerierlaufEntryExitCodeList',
    HeAbrechnungGenerierlauf: 'HeAbrechnungGenerierlauf',
    HeAbrechnungGenerierlaufEntryList: 'HeAbrechnungGenerierlaufEntryList',
    IncomingInvoiceBookingCreationGenerierlauf: 'IncomingInvoiceBookingCreationGenerierlauf',
    IncomingInvoiceBookingCreationGenerierlaufEntryList: 'IncomingInvoiceBookingCreationGenerierlaufEntryList',
    InfoMailDeliveryDetermineRecipientListGenerierlauf: 'InfoMailDeliveryDetermineRecipientListGenerierlauf',
    InfoMailDeliveryDetermineRecipientListGenerierlaufEntryList: 'InfoMailDeliveryDetermineRecipientListGenerierlaufEntryList',
    InfoMailDeliverySendGenerierlauf: 'InfoMailDeliverySendGenerierlauf',
    InfoMailDeliverySendGenerierlaufEntryList: 'InfoMailDeliverySendGenerierlaufEntryList',
    MahnungAusgebenGenerierlauf: 'MahnungAusgebenGenerierlauf',
    MahnungAusgebenGenerierlaufEntryList: 'MahnungAusgebenGenerierlaufEntryList',
    MahnungErzeugenGenerierlauf: 'MahnungErzeugenGenerierlauf',
    MahnungErzeugenGenerierlaufEntryList: 'MahnungErzeugenGenerierlaufEntryList',
    MahnungVerbuchenGenerierlauf: 'MahnungVerbuchenGenerierlauf',
    MahnungVerbuchenGenerierlaufEntryList: 'MahnungVerbuchenGenerierlaufEntryList',
    MahnungVersendenGenerierlauf: 'MahnungVersendenGenerierlauf',
    MahnungVersendenGenerierlaufEntryList: 'MahnungVersendenGenerierlaufEntryList',
    ObjektAbrechnungGenerierlauf: 'ObjektAbrechnungGenerierlauf',
    ObjektAbrechnungGenerierlaufEntryList: 'ObjektAbrechnungGenerierlaufEntryList',
    ObjektFinancialPlanGenerierlauf: 'ObjektFinancialPlanGenerierlauf',
    PaymentProposalCreationGenerierlauf: 'PaymentProposalCreationGenerierlauf',
    PaymentProposalCreationGenerierlaufEntryList: 'PaymentProposalCreationGenerierlaufEntryList',
    PaymentProposalExecutionGenerierlaufEntryList: 'PaymentProposalExecutionGenerierlaufEntryList',
    PaymentProposalExecutionGenerierlauf: 'PaymentProposalExecutionGenerierlauf',
    ReceivedBankTransactionVerbuchenGenerierlauf: 'ReceivedBankTransactionVerbuchenGenerierlauf',
    ReceivedBankTransactionVerbuchenGenerierlaufEntry: 'ReceivedBankTransactionVerbuchenGenerierlaufEntry',
    SubAbrechnungGenerierlauf: 'SubAbrechnungGenerierlauf',
    SubAbrechnungGenerierlaufEntryList: 'SubAbrechnungGenerierlaufEntryList',
    SyncEbicsUserGenerierlauf: 'SyncEbicsUserGenerierlauf',
    TopAbrechnungGenerierlauf: 'TopAbrechnungGenerierlauf',
    TopAbrechnungGenerierlaufEntryList: 'TopAbrechnungGenerierlaufEntryList',
    TopFinancialPlanGenerierlauf: 'TopFinancialPlanGenerierlauf',
    UstVomAufwandGenerierlauf: 'UstVomAufwandGenerierlauf',
    VertragVposIndexedValueGenerierlauf: 'VertragVposIndexedValueGenerierlauf',
    VertragVposIndexedValueGenerierlaufEntryList: 'VertragVposIndexedValueGenerierlaufEntryList',
    VorschreibungMailDeliveryDetermineRecipientListGenerierlauf: 'VorschreibungMailDeliveryDetermineRecipientListGenerierlauf',
    VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryList: 'VorschreibungMailDeliveryDetermineRecipientListGenerierlaufEntryList',
    VorschreibungMailDeliverySendGenerierlauf: 'VorschreibungMailDeliverySendGenerierlauf',
    VorschreibungMailDeliverySendGenerierlaufEntryList: 'VorschreibungMailDeliverySendGenerierlaufEntryList',
    VorsteuerkuerzungGenerierlauf: 'VorsteuerkuerzungGenerierlauf',
    ZinslisteGenerierlauf: 'ZinslisteGenerierlauf',
    ZinslisteGenerierlaufEntryList: 'ZinslisteGenerierlaufEntryList',
    BeVertragDeviationList: 'BeVertragDeviationList',
    BestandseinheitNutzungsArtList: 'BestandseinheitNutzungsArtList',
    KuendigungDurchList: 'KuendigungDurchList',
    BeVertragInfoFeldList: 'BeVertragInfoFeldList',
    BeVertragInfoFeld: 'BeVertragInfoFeld',
    VertragInfoFeldTypeList: 'VertragInfoFeldTypeList',
    BeVertragDokumentenversand: 'BeVertragDokumentenversand',
    BeVertragVersionList: 'BeVertragVersionList',
    VertragVposIndexationAgreementVorschreibungspositionList: 'VertragVposIndexationAgreementVorschreibungspositionList',
    VertragVposIndexationAgreementList: 'VertragVposIndexationAgreementList',
    VertragVposIndexationAgreement: 'VertragVposIndexationAgreement',
    VertragVposIndexationAgreementChangeHistoryList: 'VertragVposIndexationAgreementChangeHistoryList',
    BeVertragVorschreibungspositionList: 'BeVertragVorschreibungspositionList',
    BeVertragVorschreibungspositionWertList: 'BeVertragVorschreibungspositionWertList',
    BeVertragVorschreibungspositionWertChangeHistoryList: 'BeVertragVorschreibungspositionWertChangeHistoryList',
    BeVertragVorschreibungspositionUstKategorieEntry: 'BeVertragVorschreibungspositionUstKategorieEntry',
    BeVertragVorschreibungspositionWertDeletionHistoryList: 'BeVertragVorschreibungspositionWertDeletionHistoryList',
    BeVertragVposIndexationAgreementDeletionHistory: 'BeVertragVposIndexationAgreementDeletionHistory',
    WeAndMietVertragList: 'WeAndMietVertragList',
    BeVertrag: 'BeVertrag',
    BeVertragBaseList: 'BeVertragBaseList',
    BeVertragChangeHistoryList: 'BeVertragChangeHistoryList',
    BeVertragKurzbezeichnung: 'BeVertragKurzbezeichnung',
    VertragPaymentMethodList: 'VertragPaymentMethodList',
    VertragVerrechnungsartList: 'VertragVerrechnungsartList',
    HeVertragZahlungsregelList: 'HeVertragZahlungsregelList',
    HeVertragZahlungsregel: 'HeVertragZahlungsregel',
    HeVertragZahlungsregelPeriodeList: 'HeVertragZahlungsregelPeriodeList',
    Zahlungsverlauf: 'Zahlungsverlauf',
    HeVertragOverviewList: 'HeVertragOverviewList',
    HeVertragBaseList: 'HeVertragBaseList',
    HeVertragList: 'HeVertragList',
    HeVertrag: 'HeVertrag',
    HeVertragChangeHistoryList: 'HeVertragChangeHistoryList',
    HeVertragZahlungsregelChangeHistoryList: 'HeVertragZahlungsregelChangeHistoryList',
    VertragLevelList: 'VertragLevelList',
    VertragsartList: 'VertragsartList',
    VorschreibungDeviationList: 'VorschreibungDeviationList',
    AuftragList: 'AuftragList',
    AuftragListSummary: 'AuftragListSummary',
    Auftrag: 'Auftrag',
    Steuertabelle: 'Steuertabelle',
    AuftragLinkList: 'AuftragLinkList',
    FilterAuftragEmpfaengerKundennummerList: 'FilterAuftragEmpfaengerKundennummerList',
    FilterAuftragAusstellerIbanList: 'FilterAuftragAusstellerIbanList',
    FilterAuftragEmpfaengerIbanList: 'FilterAuftragEmpfaengerIbanList',
    FilterAuftragBelegnummerList: 'FilterAuftragBelegnummerList',
    FilterAuftragVertragsartList: 'FilterAuftragVertragsartList',
    VorschreibungspositionArtList: 'VorschreibungspositionArtList',
    VorschreibungspositionVersionList: 'VorschreibungspositionVersionList',
    KSVorschreibungspositionList: 'KSVorschreibungspositionList',
    FDVorschreibungspositionList: 'FDVorschreibungspositionList',
    Vorschreibungsposition: 'Vorschreibungsposition',
    VorschreibungspositionChangeHistoryList: 'VorschreibungspositionChangeHistoryList',
    VorschreibungspositionListForSelectByFirmendatenId: 'VorschreibungspositionListForSelectByFirmendatenId',
    VorschreibungspositionListForSelectByKundenSystemId: 'VorschreibungspositionListForSelectByKundenSystemId',
    VorschreibungspositionTemplateVersionList: 'VorschreibungspositionTemplateVersionList',
    VorschreibungspositionTemplateVersion: 'VorschreibungspositionTemplateVersion',
    VorschreibungspositionTemplateList: 'VorschreibungspositionTemplateList',
    VorschreibungspositionTemplate: 'VorschreibungspositionTemplate',
    VorschreibungspositionTemplateChangeHistoryList: 'VorschreibungspositionTemplateChangeHistoryList',
    VorsteuerkuerzungDeviationList: 'VorsteuerkuerzungDeviationList',
    VorsteuerkuerzungEinstellungList: 'VorsteuerkuerzungEinstellungList',
    VorsteuerkuerzungList: 'VorsteuerkuerzungList',
    Vorsteuerkuerzung: 'Vorsteuerkuerzung',
    VorsteuerkuerzungBuchungStatusList: 'VorsteuerkuerzungBuchungStatusList',
    VorsteuerkuerzungEinstellungTemplateList: 'VorsteuerkuerzungEinstellungTemplateList',
    InfoMailDeliveryWizardTemplateStep: 'InfoMailDeliveryWizardTemplateStep',
    InfoMailDeliveryWizardDetermineRecipientListStep: 'InfoMailDeliveryWizardDetermineRecipientListStep',
    InfoMailDeliveryWizardRecipientList: 'InfoMailDeliveryWizardRecipientList',
    InfoMailDeliveryWizardRecipientListStep: 'InfoMailDeliveryWizardRecipientListStep',
    InfoMailDeliveryAttachmentList: 'InfoMailDeliveryAttachmentList',
    WizardList: 'WizardList',
    WizardTypes: 'WizardTypes',
    VorschreibungMailDeliveryWizardTemplateStep: 'VorschreibungMailDeliveryWizardTemplateStep',
    VorschreibungMailDeliveryWizardDetermineRecipientListStep: 'VorschreibungMailDeliveryWizardDetermineRecipientListStep',
    VorschreibungMailDeliveryWizardRecipientList: 'VorschreibungMailDeliveryWizardRecipientList',
    VorschreibungMailDeliveryWizardRecipientListStep: 'VorschreibungMailDeliveryWizardRecipientListStep',
    VorschreibungMailDeliveryAttachmentList: 'VorschreibungMailDeliveryAttachmentList',
    ZinslisteDeviationList: 'ZinslisteDeviationList',
    ZinslisteList: 'ZinslisteList',
    Zinsliste: 'Zinsliste',
    ZinslisteSelect: 'ZinslisteSelect',
    ZinslisteVerarbeitungsstand: 'ZinslisteVerarbeitungsstand',
    MonthList: 'MonthList',
    UmsatzsteuerkennzeichenList: 'UmsatzsteuerkennzeichenList',
    UmsatzsteuerkennzeichenTemplateList: 'UmsatzsteuerkennzeichenTemplateList',
    ValuePreservationTypeList: 'ValuePreservationTypeList',
    User: 'User',
    UserName: 'UserName',
    Eigenbeleg: 'Eigenbeleg',
    MitarbeiterTooltip: 'MitarbeiterTooltip',
    MitarbeiterSelectList: 'MitarbeiterSelectList',
    Mitarbeiter: 'Mitarbeiter',
    TestRechtstraegerList: 'TestRechtstraegerList',
    EigenbelegForBuchungsmaske: 'EigenbelegForBuchungsmaske',
    OwnMitarbeiterList: 'OwnMitarbeiterList',
    Me: 'Me',
    LoginMitarbeiterList: 'LoginMitarbeiterList',
    AuthMitarbeiterMe: 'AuthMitarbeiterMe',
    CountryCode: 'CountryCode',
    CountryCodeList: 'CountryCodeList',
    KuendigungsfristList: 'KuendigungsfristList',
    KuendigungsfristJeweilsZumList: 'KuendigungsfristJeweilsZumList',
    LegalFormList: 'LegalFormList',
    KundenSystemEinstellung: 'KundenSystemEinstellung',
    SteuerpflichtList: 'SteuerpflichtList',
    VertragspartnerKontoList: 'VertragspartnerKontoList',
    ZahlungsperiodeList: 'ZahlungsperiodeList',
  },
  Mutation: {
    UpdatePostIt: 'UpdatePostIt',
    DeletePostIt: 'DeletePostIt',
    AssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungList: 'AssignAbrechnungsdefinitionKontoAbrechnungskreisZuordnungList',
    AssignAbrechnungsdefinitionKontoAbrechnungskreisTemplateList: 'AssignAbrechnungsdefinitionKontoAbrechnungskreisTemplateList',
    DeleteTopAbrechnungen: 'DeleteTopAbrechnungen',
    DeleteObjektAbrechnung: 'DeleteObjektAbrechnung',
    ObjektAbrechnungMarkUnderReview: 'ObjektAbrechnungMarkUnderReview',
    ObjektAbrechnungApplyAllDeviations: 'ObjektAbrechnungApplyAllDeviations',
    TopAbrechnungApplyAllDeviations: 'TopAbrechnungApplyAllDeviations',
    HeAbrechnungApplyAllDeviations: 'HeAbrechnungApplyAllDeviations',
    DeleteHeAbrechnung: 'DeleteHeAbrechnung',
    HeAbrechnungMarkUnderReview: 'HeAbrechnungMarkUnderReview',
    DeleteSubAbrechnung: 'DeleteSubAbrechnung',
    CreateFirmendatenHeOrObjektOrSubAbrechnungGenerierlauf: 'CreateFirmendatenHeOrObjektOrSubAbrechnungGenerierlauf',
    CreateFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlauf: 'CreateFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlauf',
    CreateFirmendatenTopAbrechnungGenerierlauf: 'CreateFirmendatenTopAbrechnungGenerierlauf',
    DeleteAbrechnungsdefinition: 'DeleteAbrechnungsdefinition',
    ArchiveAbrechnungsdefinition: 'ArchiveAbrechnungsdefinition',
    UnarchiveAbrechnungsdefinition: 'UnarchiveAbrechnungsdefinition',
    CreateBkAbrechnungsdefinitionForFD: 'CreateBkAbrechnungsdefinitionForFD',
    CreateBkAbrechnungsdefinitionForKS: 'CreateBkAbrechnungsdefinitionForKS',
    UpdateBkAbrechnungsdefinition: 'UpdateBkAbrechnungsdefinition',
    CreateBkAbrechnungsdefinitionVersion: 'CreateBkAbrechnungsdefinitionVersion',
    UpdateBkAbrechnungsdefinitionVersion: 'UpdateBkAbrechnungsdefinitionVersion',
    DeleteBkAbrechnungsdefinitionVersion: 'DeleteBkAbrechnungsdefinitionVersion',
    CreateHeAbrechnungsdefinitionForKS: 'CreateHeAbrechnungsdefinitionForKS',
    CreateHeAbrechnungsdefinitionForFD: 'CreateHeAbrechnungsdefinitionForFD',
    UpdateHeAbrechnungsdefinition: 'UpdateHeAbrechnungsdefinition',
    CreateHeAbrechnungsdefinitionVersion: 'CreateHeAbrechnungsdefinitionVersion',
    UpdateHeAbrechnungsdefinitionVersion: 'UpdateHeAbrechnungsdefinitionVersion',
    DeleteHeAbrechnungsdefinitionVersion: 'DeleteHeAbrechnungsdefinitionVersion',
    CreateSubAdministrationAbrechnungsdefinitionForKS: 'CreateSubAdministrationAbrechnungsdefinitionForKS',
    CreateSubAdministrationAbrechnungsdefinitionForFD: 'CreateSubAdministrationAbrechnungsdefinitionForFD',
    UpdateSubAdministrationAbrechnungsdefinition: 'UpdateSubAdministrationAbrechnungsdefinition',
    CreateSubAdministrationAbrechnungsdefinitionVersion: 'CreateSubAdministrationAbrechnungsdefinitionVersion',
    UpdateSubAdministrationAbrechnungsdefinitionVersion: 'UpdateSubAdministrationAbrechnungsdefinitionVersion',
    DeleteSubAdministrationAbrechnungsdefinitionVersion: 'DeleteSubAdministrationAbrechnungsdefinitionVersion',
    CreateWeAbrechnungsdefinitionForKS: 'CreateWeAbrechnungsdefinitionForKS',
    CreateWeAbrechnungsdefinitionForFD: 'CreateWeAbrechnungsdefinitionForFD',
    UpdateWeAbrechnungsdefinition: 'UpdateWeAbrechnungsdefinition',
    CreateWeAbrechnungsdefinitionVersion: 'CreateWeAbrechnungsdefinitionVersion',
    UpdateWeAbrechnungsdefinitionVersion: 'UpdateWeAbrechnungsdefinitionVersion',
    DeleteWeAbrechnungsdefinitionVersion: 'DeleteWeAbrechnungsdefinitionVersion',
    DeleteAbrechnungsdefinitionTemplate: 'DeleteAbrechnungsdefinitionTemplate',
    CreateBkAbrechnungsdefinitionTemplate: 'CreateBkAbrechnungsdefinitionTemplate',
    UpdateBkAbrechnungsdefinitionTemplate: 'UpdateBkAbrechnungsdefinitionTemplate',
    CreateBkAbrechnungsdefinitionTemplateVersion: 'CreateBkAbrechnungsdefinitionTemplateVersion',
    UpdateBkAbrechnungsdefinitionTemplateVersion: 'UpdateBkAbrechnungsdefinitionTemplateVersion',
    DeleteBkAbrechnungsdefinitionTemplateVersion: 'DeleteBkAbrechnungsdefinitionTemplateVersion',
    CreateHeAbrechnungsdefinitionTemplate: 'CreateHeAbrechnungsdefinitionTemplate',
    UpdateHeAbrechnungsdefinitionTemplate: 'UpdateHeAbrechnungsdefinitionTemplate',
    CreateHeAbrechnungsdefinitionTemplateVersion: 'CreateHeAbrechnungsdefinitionTemplateVersion',
    UpdateHeAbrechnungsdefinitionTemplateVersion: 'UpdateHeAbrechnungsdefinitionTemplateVersion',
    DeleteHeAbrechnungsdefinitionTemplateVersion: 'DeleteHeAbrechnungsdefinitionTemplateVersion',
    CreateSubAdministrationAbrechnungsdefinitionTemplate: 'CreateSubAdministrationAbrechnungsdefinitionTemplate',
    UpdateSubAdministrationAbrechnungsdefinitionTemplate: 'UpdateSubAdministrationAbrechnungsdefinitionTemplate',
    CreateSubAdministrationAbrechnungsdefinitionTemplateVersion: 'CreateSubAdministrationAbrechnungsdefinitionTemplateVersion',
    UpdateSubAdministrationAbrechnungsdefinitionTemplateVersion: 'UpdateSubAdministrationAbrechnungsdefinitionTemplateVersion',
    DeleteSubAdministrationAbrechnungsdefinitionTemplateVersion: 'DeleteSubAdministrationAbrechnungsdefinitionTemplateVersion',
    createWeAbrechnungsdefinitionTemplate: 'createWeAbrechnungsdefinitionTemplate',
    UpdateWeAbrechnungsdefinitionTemplate: 'UpdateWeAbrechnungsdefinitionTemplate',
    CreateWeAbrechnungsdefinitionTemplateVersion: 'CreateWeAbrechnungsdefinitionTemplateVersion',
    UpdateWeAbrechnungsdefinitionTemplateVersion: 'UpdateWeAbrechnungsdefinitionTemplateVersion',
    DeleteWeAbrechnungsdefinitionTemplateVersion: 'DeleteWeAbrechnungsdefinitionTemplateVersion',
    CreateFDAbrechnungskreis: 'CreateFDAbrechnungskreis',
    createKSAbrechnungskreis: 'createKSAbrechnungskreis',
    UpdateAbrechnungskreis: 'UpdateAbrechnungskreis',
    DeleteAbrechnungskreis: 'DeleteAbrechnungskreis',
    ArchiveAbrechnungskreis: 'ArchiveAbrechnungskreis',
    UnarchiveAbrechnungskreis: 'UnarchiveAbrechnungskreis',
    CreateAbrechnungskreisTemplate: 'CreateAbrechnungskreisTemplate',
    UpdateAbrechnungskreisTemplate: 'UpdateAbrechnungskreisTemplate',
    DeleteAbrechnungskreisTemplate: 'DeleteAbrechnungskreisTemplate',
    ArchiveAbrechnungskreisTemplate: 'ArchiveAbrechnungskreisTemplate',
    UnarchiveAbrechnungskreisTemplate: 'UnarchiveAbrechnungskreisTemplate',
    CreateAdmin: 'CreateAdmin',
    UpdateAdmin: 'UpdateAdmin',
    DeleteAdmin: 'DeleteAdmin',
    CreatePauschalmieteAufwandskonto: 'CreatePauschalmieteAufwandskonto',
    UpdatePauschalmieteAufwandskonto: 'UpdatePauschalmieteAufwandskonto',
    UpdatePauschalmieteForderungskonto: 'UpdatePauschalmieteForderungskonto',
    DeletePauschalmieteAufwandskonto: 'DeletePauschalmieteAufwandskonto',
    updateFibuEinstellung: 'updateFibuEinstellung',
    updateFirmendatenBuchhaltungEinstellung: 'updateFirmendatenBuchhaltungEinstellung',
    createLeerstehungAufwandskonto: 'createLeerstehungAufwandskonto',
    updateLeerstehungAufwandskonto: 'updateLeerstehungAufwandskonto',
    deleteLeerstehungAufwandskonto: 'deleteLeerstehungAufwandskonto',
    updateLeerstehungForderungskonto: 'updateLeerstehungForderungskonto',
    updateAufwandAbgrenzungskonto: 'updateAufwandAbgrenzungskonto',
    createKSAufteilungsschluessel: 'createKSAufteilungsschluessel',
    createFDAufteilungsschluessel: 'createFDAufteilungsschluessel',
    updateAufteilungsschluessel: 'updateAufteilungsschluessel',
    deleteAufteilungsschluessel: 'deleteAufteilungsschluessel',
    UnarchiveAufteilungsschluessel: 'UnarchiveAufteilungsschluessel',
    ArchiveAufteilungsschluessel: 'ArchiveAufteilungsschluessel',
    createAufteilungsschluesselTemplate: 'createAufteilungsschluesselTemplate',
    updateAufteilungsschluesselTemplate: 'updateAufteilungsschluesselTemplate',
    deleteAufteilungsschluesselTemplate: 'deleteAufteilungsschluesselTemplate',
    UnarchiveAufteilungsschluesselTemplate: 'UnarchiveAufteilungsschluesselTemplate',
    ArchiveAufteilungsschluesselTemplate: 'ArchiveAufteilungsschluesselTemplate',
    createAuftragsart: 'createAuftragsart',
    updateAuftragsart: 'updateAuftragsart',
    deleteAuftragsart: 'deleteAuftragsart',
    ArchiveAuftragsart: 'ArchiveAuftragsart',
    UnarchiveAuftragsart: 'UnarchiveAuftragsart',
    CreateAuftragsartTemplate: 'CreateAuftragsartTemplate',
    UpdateAuftragsartTemplate: 'UpdateAuftragsartTemplate',
    DeleteAuftragsartTemplate: 'DeleteAuftragsartTemplate',
    createSepaMandat: 'createSepaMandat',
    CreateFirmendatenBankDetails: 'CreateFirmendatenBankDetails',
    UpdateFirmendatenBankDetails: 'UpdateFirmendatenBankDetails',
    DeleteFirmendatenBankDetails: 'DeleteFirmendatenBankDetails',
    ArchiveFirmendatenBankDetails: 'ArchiveFirmendatenBankDetails',
    UnarchiveFirmendatenBankDetails: 'UnarchiveFirmendatenBankDetails',
    CreateRechtstraegerBankDetails: 'CreateRechtstraegerBankDetails',
    updateRechtstraegerBankDetails: 'updateRechtstraegerBankDetails',
    DeleteRechtstraegerBankDetails: 'DeleteRechtstraegerBankDetails',
    ArchiveRechtstraegerBankDetails: 'ArchiveRechtstraegerBankDetails',
    UnarchiveRechtstraegerBankDetails: 'UnarchiveRechtstraegerBankDetails',
    AddKontoForFirmendatenBankDetails: 'AddKontoForFirmendatenBankDetails',
    ChangeAccountLimitForFirmendatenBankDetails: 'ChangeAccountLimitForFirmendatenBankDetails',
    ChangeAccountLimitForRechtstraegerBankDetails: 'ChangeAccountLimitForRechtstraegerBankDetails',
    ValidateIban: 'ValidateIban',
    UpdateFirmendatenBankSettings: 'UpdateFirmendatenBankSettings',
    UpdateRechtstreagerBankSettings: 'UpdateRechtstreagerBankSettings',
    createBelegnummernkreisDefinition: 'createBelegnummernkreisDefinition',
    updateBelegnummernkreisDefinition: 'updateBelegnummernkreisDefinition',
    deleteBelegnummernkreisDefinition: 'deleteBelegnummernkreisDefinition',
    ArchiveBelegnummernkreisDefinition: 'ArchiveBelegnummernkreisDefinition',
    UnarchiveBelegnummernkreisDefinition: 'UnarchiveBelegnummernkreisDefinition',
    DeleteBelegTexteRechtstraeger: 'DeleteBelegTexteRechtstraeger',
    deleteBelegTexteFirmendaten: 'deleteBelegTexteFirmendaten',
    CreateBestandseinheitAufteilungsschluesselWertMitBezug: 'CreateBestandseinheitAufteilungsschluesselWertMitBezug',
    CreateBestandseinheitAufteilungsschluesselWertOhneBezug: 'CreateBestandseinheitAufteilungsschluesselWertOhneBezug',
    UpdateBestandseinheitAufteilungsschluesselWertMitBezug: 'UpdateBestandseinheitAufteilungsschluesselWertMitBezug',
    UpdateBestandseinheitAufteilungsschluesselWertOhneBezug: 'UpdateBestandseinheitAufteilungsschluesselWertOhneBezug',
    DeleteBestandseinheitAufteilungsschluesselWert: 'DeleteBestandseinheitAufteilungsschluesselWert',
    CreateBeInfoFeldPostIt: 'CreateBeInfoFeldPostIt',
    DeleteBeInfoFeld: 'DeleteBeInfoFeld',
    UnarchiveBeInfoFeld: 'UnarchiveBeInfoFeld',
    ArchiveBeInfoFeld: 'ArchiveBeInfoFeld',
    CreateBeOptionSteuerpflichtVersion: 'CreateBeOptionSteuerpflichtVersion',
    UpdateBeOptionSteuerpflichtVersion: 'UpdateBeOptionSteuerpflichtVersion',
    DeleteBeOptionSteuerpflichtVersion: 'DeleteBeOptionSteuerpflichtVersion',
    CreateBestandseinheitNutzflaecheVersion: 'CreateBestandseinheitNutzflaecheVersion',
    UpdateBestandseinheitNutzflaecheVersion: 'UpdateBestandseinheitNutzflaecheVersion',
    DeleteBestandseinheitNutzflaecheVersion: 'DeleteBestandseinheitNutzflaecheVersion',
    CreateBeNutzungsArt: 'CreateBeNutzungsArt',
    UpdateBeNutzungsArt: 'UpdateBeNutzungsArt',
    DeleteBeNutzungsArt: 'DeleteBeNutzungsArt',
    CreateBestandseinheitNutzwertVersion: 'CreateBestandseinheitNutzwertVersion',
    UpdateBestandseinheitNutzwertVersion: 'UpdateBestandseinheitNutzwertVersion',
    DeleteBestandseinheitNutzwertVersion: 'DeleteBestandseinheitNutzwertVersion',
    ArchiveBestandseinheit: 'ArchiveBestandseinheit',
    UnarchiveBestandseinheit: 'UnarchiveBestandseinheit',
    ChangePositionBestandseinheit: 'ChangePositionBestandseinheit',
    CreateBestandseinheit: 'CreateBestandseinheit',
    UpdateBestandseinheit: 'UpdateBestandseinheit',
    DeleteBestandseinheit: 'DeleteBestandseinheit',
    CreateBePostIt: 'CreateBePostIt',
    createAuszifferungList: 'createAuszifferungList',
    AuszifferungStornieren: 'AuszifferungStornieren',
    BuchungsanweisungApplyAllDeviations: 'BuchungsanweisungApplyAllDeviations',
    DeleteFibuBuchungsanweisung: 'DeleteFibuBuchungsanweisung',
    UpdateBookingSuggestion: 'UpdateBookingSuggestion',
    CreateBudgeting: 'CreateBudgeting',
    BudgetingAdjustAufteilungsschluessel: 'BudgetingAdjustAufteilungsschluessel',
    BudgetingConfigureFinancialPlanTexts: 'BudgetingConfigureFinancialPlanTexts',
    BudgetingCreateVPosValuesFromVPos: 'BudgetingCreateVPosValuesFromVPos',
    BudgetingCreateVPosValuesFromAufwand: 'BudgetingCreateVPosValuesFromAufwand',
    BudgetingResetKontoEstimate: 'BudgetingResetKontoEstimate',
    BudgetingTransferToVPos: 'BudgetingTransferToVPos',
    BudgetingUpdateKontoEstimate: 'BudgetingUpdateKontoEstimate',
    BudgetingUpdateVPosEstimate: 'BudgetingUpdateVPosEstimate',
    BudgetingDeleteVPosValues: 'BudgetingDeleteVPosValues',
    BudgetingArchivieren: 'BudgetingArchivieren',
    BudgetingReaktivieren: 'BudgetingReaktivieren',
    DeleteBudgeting: 'DeleteBudgeting',
    DeleteObjektFinancialPlan: 'DeleteObjektFinancialPlan',
    DeleteTopFinancialPlan: 'DeleteTopFinancialPlan',
    CreateBudgetingAbrDefVPosLinkageFirmendaten: 'CreateBudgetingAbrDefVPosLinkageFirmendaten',
    CreateBudgetingAbrDefVPosLinkageKundenSystem: 'CreateBudgetingAbrDefVPosLinkageKundenSystem',
    UpdateBudgetingAbrDefVPosLinkage: 'UpdateBudgetingAbrDefVPosLinkage',
    DeleteBudgetingAbrDefVPosLinkage: 'DeleteBudgetingAbrDefVPosLinkage',
    CreateBudgetingAbrDefVPosLinkageTemplate: 'CreateBudgetingAbrDefVPosLinkageTemplate',
    UpdateBudgetingAbrDefVPosLinkageTemplate: 'UpdateBudgetingAbrDefVPosLinkageTemplate',
    DeleteBudgetingAbrDefVPosLinkageTemplate: 'DeleteBudgetingAbrDefVPosLinkageTemplate',
    CreateBudgetingVPosAuftschluesselLinkageFirmendaten: 'CreateBudgetingVPosAuftschluesselLinkageFirmendaten',
    CreateBudgetingVPosAuftschluesselLinkageKundenSystem: 'CreateBudgetingVPosAuftschluesselLinkageKundenSystem',
    UpdateBudgetingVPosAuftschluesselLinkage: 'UpdateBudgetingVPosAuftschluesselLinkage',
    DeleteBudgetingVPosAuftschluesselLinkage: 'DeleteBudgetingVPosAuftschluesselLinkage',
    CreateBudgetingVPosAuftschluesselLinkageTemplate: 'CreateBudgetingVPosAuftschluesselLinkageTemplate',
    UpdateBudgetingVPosAuftschluesselLinkageTemplate: 'UpdateBudgetingVPosAuftschluesselLinkageTemplate',
    DeleteBudgetingVPosAuftschluesselLinkageTemplate: 'DeleteBudgetingVPosAuftschluesselLinkageTemplate',
    CreateIncomingCallLog: 'CreateIncomingCallLog',
    CreateOutgoingCallLog: 'CreateOutgoingCallLog',
    UpdateCallLog: 'UpdateCallLog',
    ReimportDataCarrierCamt: 'ReimportDataCarrierCamt',
    DeleteDataCarrierCamt: 'DeleteDataCarrierCamt',
    DeleteBankStatement: 'DeleteBankStatement',
    UploadedDataCarrierPain: 'UploadedDataCarrierPain',
    UploadManuallyDataCarrierPain: 'UploadManuallyDataCarrierPain',
    DeleteFileForMitarbeiter: 'DeleteFileForMitarbeiter',
    ResendInfoMailDelivery: 'ResendInfoMailDelivery',
    ResendVorschreibungMailDelivery: 'ResendVorschreibungMailDelivery',
    CreateEmailDeliveryDefinition: 'CreateEmailDeliveryDefinition',
    UpdateEmailDeliveryDefinition: 'UpdateEmailDeliveryDefinition',
    DeleteEmailDeliveryDefinition: 'DeleteEmailDeliveryDefinition',
    CreateEmailDeliveryDefinitionTemplate: 'CreateEmailDeliveryDefinitionTemplate',
    UpdateEmailDeliveryDefinitionTemplate: 'UpdateEmailDeliveryDefinitionTemplate',
    DeleteEmailDeliveryDefinitionTemplate: 'DeleteEmailDeliveryDefinitionTemplate',
    DeleteEmailIntegration: 'DeleteEmailIntegration',
    updateAppFieldHelp: 'updateAppFieldHelp',
    deleteAppFieldHelp: 'deleteAppFieldHelp',
    updateFibuFieldHelp: 'updateFibuFieldHelp',
    deleteFibuFieldHelp: 'deleteFibuFieldHelp',
    updateOrderFieldHelp: 'updateOrderFieldHelp',
    deleteOrderFieldHelp: 'deleteOrderFieldHelp',
    updatePaymentFieldHelp: 'updatePaymentFieldHelp',
    deletePaymentFieldHelp: 'deletePaymentFieldHelp',
    CreateFirmendatenIndexSeries: 'CreateFirmendatenIndexSeries',
    UpdateFirmendatenIndexSeries: 'UpdateFirmendatenIndexSeries',
    DeleteFirmendatenIndexSeries: 'DeleteFirmendatenIndexSeries',
    ArchiveFirmendatenIndexSeries: 'ArchiveFirmendatenIndexSeries',
    UnarchiveFirmendatenIndexSeries: 'UnarchiveFirmendatenIndexSeries',
    CreateFirmendatenIndexSeriesEntry: 'CreateFirmendatenIndexSeriesEntry',
    UpdateFirmendatenIndexSeriesEntry: 'UpdateFirmendatenIndexSeriesEntry',
    DeleteFirmendatenIndexSeriesEntry: 'DeleteFirmendatenIndexSeriesEntry',
    CreateSingleInvoice: 'CreateSingleInvoice',
    UpdateSingleInvoice: 'UpdateSingleInvoice',
    DeleteSingleInvoice: 'DeleteSingleInvoice',
    SingleInvoiceFreigeben: 'SingleInvoiceFreigeben',
    SingleInvoiceRestore: 'SingleInvoiceRestore',
    SingleInvoiceAbgrenzungsBuchungPreview: 'SingleInvoiceAbgrenzungsBuchungPreview',
    SingleInvoiceAbgrenzungsStornoBuchungPreview: 'SingleInvoiceAbgrenzungsStornoBuchungPreview',
    SingleInvoiceBuchungPreview: 'SingleInvoiceBuchungPreview',
    SingleInvoiceStornoBuchungPreview: 'SingleInvoiceStornoBuchungPreview',
    CreateStandingInvoice: 'CreateStandingInvoice',
    UpdateStandingInvoice: 'UpdateStandingInvoice',
    DeleteStandingInvoice: 'DeleteStandingInvoice',
    StandingInvoiceFreigeben: 'StandingInvoiceFreigeben',
    StandingInvoiceRestore: 'StandingInvoiceRestore',
    StandingInvoiceBuchungPreview: 'StandingInvoiceBuchungPreview',
    StandingInvoiceStornoBuchungPreview: 'StandingInvoiceStornoBuchungPreview',
    StandingInvoiceUpdatePreview: 'StandingInvoiceUpdatePreview',
    CreateIndexSeries: 'CreateIndexSeries',
    UpdateIndexSeries: 'UpdateIndexSeries',
    DeleteIndexSeries: 'DeleteIndexSeries',
    CreateIndexSeriesEntry: 'CreateIndexSeriesEntry',
    UpdateIndexSeriesEntry: 'UpdateIndexSeriesEntry',
    DeleteIndexSeriesEntry: 'DeleteIndexSeriesEntry',
    UpdateIndexValueCreationCronJobSettings: 'UpdateIndexValueCreationCronJobSettings',
    UpdateVertragVposIndexedValue: 'UpdateVertragVposIndexedValue',
    DeleteVertragVposIndexedValue: 'DeleteVertragVposIndexedValue',
    ApplyVertragVposIndexedValue: 'ApplyVertragVposIndexedValue',
    DoNotApplyVertragVposIndexedValue: 'DoNotApplyVertragVposIndexedValue',
    SubmitVertragVposIndexedValue: 'SubmitVertragVposIndexedValue',
    DoNotApplyVertragVposIndexedValueFirmendaten: 'DoNotApplyVertragVposIndexedValueFirmendaten',
    SubmitVertragVposIndexedValueFirmendaten: 'SubmitVertragVposIndexedValueFirmendaten',
    UpdateValidFromVertragVposIndexedValueFirmendaten: 'UpdateValidFromVertragVposIndexedValueFirmendaten',
    CreateKonto: 'CreateKonto',
    UpdateKonto: 'UpdateKonto',
    DeleteKonto: 'DeleteKonto',
    ArchiveKonto: 'ArchiveKonto',
    UnarchiveKonto: 'UnarchiveKonto',
    SetKontoTagList: 'SetKontoTagList',
    CreateKontoTemplate: 'CreateKontoTemplate',
    UpdateKontoTemplate: 'UpdateKontoTemplate',
    DeleteKontoTemplate: 'DeleteKontoTemplate',
    ArchiveKontoTemplate: 'ArchiveKontoTemplate',
    UnarchiveKontoTemplate: 'UnarchiveKontoTemplate',
    SetKontoTemplateTagList: 'SetKontoTemplateTagList',
    CreateKSKontierungstabelle: 'CreateKSKontierungstabelle',
    CreateFDKontierungstabelle: 'CreateFDKontierungstabelle',
    UpdateKontierungstabelle: 'UpdateKontierungstabelle',
    DeleteKontierungstabelle: 'DeleteKontierungstabelle',
    ArchiveKontierungstabelle: 'ArchiveKontierungstabelle',
    UnarchiveKontierungstabelle: 'UnarchiveKontierungstabelle',
    CreateKontierungstabelleTemplate: 'CreateKontierungstabelleTemplate',
    UpdateKontierungstabelleTemplate: 'UpdateKontierungstabelleTemplate',
    DeleteKontierungstabelleTemplate: 'DeleteKontierungstabelleTemplate',
    ArchiveKontierungstabelleTemplate: 'ArchiveKontierungstabelleTemplate',
    UnarchiveKontierungstabelleTemplate: 'UnarchiveKontierungstabelleTemplate',
    UpdateFirmendaten: 'UpdateFirmendaten',
    SperrenFirmendaten: 'SperrenFirmendaten',
    EntsperrenFirmendaten: 'EntsperrenFirmendaten',
    DeleteFirmendaten: 'DeleteFirmendaten',
    RestoreDefaultFirmeneinstellung: 'RestoreDefaultFirmeneinstellung',
    UpdateFirmeneinstellung: 'UpdateFirmeneinstellung',
    CreateFirmendatenOperatingSite: 'CreateFirmendatenOperatingSite',
    UpdateFirmendatenOperatingSite: 'UpdateFirmendatenOperatingSite',
    DeleteFirmendatenOperatingSite: 'DeleteFirmendatenOperatingSite',
    CreateKSFirmendaten: 'CreateKSFirmendaten',
    CreateKundenSystem: 'CreateKundenSystem',
    UpdateKundenSystem: 'UpdateKundenSystem',
    DeleteKundenSystem: 'DeleteKundenSystem',
    ArchiveMahndefinition: 'ArchiveMahndefinition',
    UnarchiveMahndefinition: 'UnarchiveMahndefinition',
    CreateMahndefinition: 'CreateMahndefinition',
    UpdateMahndefinition: 'UpdateMahndefinition',
    DeleteMahndefinition: 'DeleteMahndefinition',
    CreateMahndefinitionTemplate: 'CreateMahndefinitionTemplate',
    UpdateMahndefinitionTemplate: 'UpdateMahndefinitionTemplate',
    DeleteMahndefinitionTemplate: 'DeleteMahndefinitionTemplate',
    SperrenMahnung: 'SperrenMahnung',
    EntsperrenMahnung: 'EntsperrenMahnung',
    FreigebenMahnung: 'FreigebenMahnung',
    DeleteMahnung: 'DeleteMahnung',
    UpdateMahnung: 'UpdateMahnung',
    MahnungenEntsperren: 'MahnungenEntsperren',
    MahnungenFreigeben: 'MahnungenFreigeben',
    MahnungenLoeschen: 'MahnungenLoeschen',
    MahnungenSperren: 'MahnungenSperren',
    CreateMitarbeiter: 'CreateMitarbeiter',
    UpdateMitarbeiter: 'UpdateMitarbeiter',
    DeleteMitarbeiter: 'DeleteMitarbeiter',
    SperrenMitarbeiter: 'SperrenMitarbeiter',
    EntsperrenMitarbeiter: 'EntsperrenMitarbeiter',
    ResendEinladungMitarbeiter: 'ResendEinladungMitarbeiter',
    doNotificationAction: 'doNotificationAction',
    dismissNotification: 'dismissNotification',
    SetObjektAufteilungsschluesselList: 'SetObjektAufteilungsschluesselList',
    UpdateObjektAufteilungsschluessel: 'UpdateObjektAufteilungsschluessel',
    archiveObjektAufteilungsschluessel: 'archiveObjektAufteilungsschluessel',
    unarchiveObjektAufteilungsschluessel: 'unarchiveObjektAufteilungsschluessel',
    CreateObjektBankDetails: 'CreateObjektBankDetails',
    UpdateObjektBankDetails: 'UpdateObjektBankDetails',
    DeleteObjektBankDetails: 'DeleteObjektBankDetails',
    CreateObjektInfoFeldPostIt: 'CreateObjektInfoFeldPostIt',
    DeleteObjektInfoFeld: 'DeleteObjektInfoFeld',
    ArchiveObjektInfoFeld: 'ArchiveObjektInfoFeld',
    UnarchiveObjektInfoFeld: 'UnarchiveObjektInfoFeld',
    CreateObjektSummeAnteile: 'CreateObjektSummeAnteile',
    UpdateObjektSummeAnteile: 'UpdateObjektSummeAnteile',
    DeleteObjektSummeAnteile: 'DeleteObjektSummeAnteile',
    SetObjektVorschreibungspositionList: 'SetObjektVorschreibungspositionList',
    UpdateObjektVorschreibungsposition: 'UpdateObjektVorschreibungsposition',
    archiveObjektVorschreibungsposition: 'archiveObjektVorschreibungsposition',
    unarchiveObjektVorschreibungsposition: 'unarchiveObjektVorschreibungsposition',
    SetObjektVorschreibungspositionVertragsartList: 'SetObjektVorschreibungspositionVertragsartList',
    CreateObjekt: 'CreateObjekt',
    ArchiveObjekt: 'ArchiveObjekt',
    UnarchiveObjekt: 'UnarchiveObjekt',
    UpdateObjekt: 'UpdateObjekt',
    DeleteObjekt: 'DeleteObjekt',
    DeleteObjektFoto: 'DeleteObjektFoto',
    CreateObjektAddress: 'CreateObjektAddress',
    CreateObjektPostIt: 'CreateObjektPostIt',
    ApprovePaymentBulkAction: 'ApprovePaymentBulkAction',
    ChangePaymentDatePaymentBulkAction: 'ChangePaymentDatePaymentBulkAction',
    MarkAsNotedPaymentBulkAction: 'MarkAsNotedPaymentBulkAction',
    CreatePayment: 'CreatePayment',
    UpdatePayment: 'UpdatePayment',
    DeletePayment: 'DeletePayment',
    ApprovePayment: 'ApprovePayment',
    ChangeToDatentraegerErzeugtPayment: 'ChangeToDatentraegerErzeugtPayment',
    ChangeToDurchfuehrbarPayment: 'ChangeToDurchfuehrbarPayment',
    ChangeToNichtDurchfuehrbarPayment: 'ChangeToNichtDurchfuehrbarPayment',
    ChangeToNichtDurchgefuehrtPayment: 'ChangeToNichtDurchgefuehrtPayment',
    deletePaymentProposal: 'deletePaymentProposal',
    CreateEbicsBankConfig: 'CreateEbicsBankConfig',
    UpdateEbicsBankConfig: 'UpdateEbicsBankConfig',
    DeleteEbicsBankConfig: 'DeleteEbicsBankConfig',
    ActivateEbicsUserIban: 'ActivateEbicsUserIban',
    DeactivateEbicsUserIban: 'DeactivateEbicsUserIban',
    CreateEbicsUser: 'CreateEbicsUser',
    updateEbicsUser: 'updateEbicsUser',
    DeleteEbicsUser: 'DeleteEbicsUser',
    ActivateEbicsUser: 'ActivateEbicsUser',
    DeactivateEbicsUser: 'DeactivateEbicsUser',
    CreateReceivedBankTransactionVerbuchenGenerierlauf: 'CreateReceivedBankTransactionVerbuchenGenerierlauf',
    MarkReceivedBankTransactionAsNoted: 'MarkReceivedBankTransactionAsNoted',
    AssignReceivedBankTransaction: 'AssignReceivedBankTransaction',
    RemoveAssignmentReceivedBankTransaction: 'RemoveAssignmentReceivedBankTransaction',
    ArchiveReceivedBankTransaction: 'ArchiveReceivedBankTransaction',
    UnarchiveReceivedBankTransaction: 'UnarchiveReceivedBankTransaction',
    BookReceivedBankTransaction: 'BookReceivedBankTransaction',
    MarkAsNotedReceivedBankTransactionBulkAction: 'MarkAsNotedReceivedBankTransactionBulkAction',
    RemoveReceivedBankTransactionPaymentAssignment: 'RemoveReceivedBankTransactionPaymentAssignment',
    CreateNatuerlichePersonAddress: 'CreateNatuerlichePersonAddress',
    CreateNatuerlichePersonContact: 'CreateNatuerlichePersonContact',
    CreatePersonenGemeinschaftAddress: 'CreatePersonenGemeinschaftAddress',
    CreatePersonenGemeinschaftContact: 'CreatePersonenGemeinschaftContact',
    CreateFirmaAddress: 'CreateFirmaAddress',
    CreateFirmaContact: 'CreateFirmaContact',
    CreateFirmaContactPerson: 'CreateFirmaContactPerson',
    CreateFirmendatenAddress: 'CreateFirmendatenAddress',
    createFirmendatenContact: 'createFirmendatenContact',
    CreateFirmendatenContactPerson: 'CreateFirmendatenContactPerson',
    UpdateFirmendatenContactPerson: 'UpdateFirmendatenContactPerson',
    updateFirmaContactPerson: 'updateFirmaContactPerson',
    deleteContactPerson: 'deleteContactPerson',
    UpdateAddress: 'UpdateAddress',
    deleteAddress: 'deleteAddress',
    UpdateContact: 'UpdateContact',
    DeleteContact: 'DeleteContact',
    SetAdresseTagListForAddress: 'SetAdresseTagListForAddress',
    SetContactTagListForContact: 'SetContactTagListForContact',
    SetOperatingSiteForAddress: 'SetOperatingSiteForAddress',
    SetOperatingSiteForContact: 'SetOperatingSiteForContact',
    SetOperatingSiteForFirmendatenContactPerson: 'SetOperatingSiteForFirmendatenContactPerson',
    SetOperatingSiteForFirmaContactPerson: 'SetOperatingSiteForFirmaContactPerson',
    SetContactPersonTagListForFirmendatenContactPerson: 'SetContactPersonTagListForFirmendatenContactPerson',
    SetContactPersonTagListForFirmaContactPerson: 'SetContactPersonTagListForFirmaContactPerson',
    CreateFirma: 'CreateFirma',
    UpdateFirma: 'UpdateFirma',
    DeleteFirma: 'DeleteFirma',
    SperrenFirma: 'SperrenFirma',
    EntsperrenFirma: 'EntsperrenFirma',
    ArchiveFirma: 'ArchiveFirma',
    UnarchiveFirma: 'UnarchiveFirma',
    CreateFirmaOperatingSite: 'CreateFirmaOperatingSite',
    UpdateFirmaOperatingSite: 'UpdateFirmaOperatingSite',
    DeleteFirmaOperatingSite: 'DeleteFirmaOperatingSite',
    CreateRechtstraegerInfoFeldPostIt: 'CreateRechtstraegerInfoFeldPostIt',
    DeleteRechtstraegerInfoFeld: 'DeleteRechtstraegerInfoFeld',
    UnarchiveRechtstraegerInfoFeld: 'UnarchiveRechtstraegerInfoFeld',
    ArchiveRechtstraegerInfoFeld: 'ArchiveRechtstraegerInfoFeld',
    CreateNatuerlichePerson: 'CreateNatuerlichePerson',
    UpdateNatuerlichePerson: 'UpdateNatuerlichePerson',
    DeleteNatuerlichePerson: 'DeleteNatuerlichePerson',
    SperrenNatuerlichePerson: 'SperrenNatuerlichePerson',
    EntsperrenNatuerlichePerson: 'EntsperrenNatuerlichePerson',
    ArchiveNatuerlichePerson: 'ArchiveNatuerlichePerson',
    UnarchiveNatuerlichePerson: 'UnarchiveNatuerlichePerson',
    UpdateNatuerlichePersonAddress: 'UpdateNatuerlichePersonAddress',
    CreatePersonenGemeinschaft: 'CreatePersonenGemeinschaft',
    UpdatePersonenGemeinschaft: 'UpdatePersonenGemeinschaft',
    DeletePersonenGemeinschaft: 'DeletePersonenGemeinschaft',
    SperrenPersonenGemeinschaft: 'SperrenPersonenGemeinschaft',
    EntsperrenPersonenGemeinschaft: 'EntsperrenPersonenGemeinschaft',
    ArchivePersonenGemeinschaft: 'ArchivePersonenGemeinschaft',
    UnarchivePersonenGemeinschaft: 'UnarchivePersonenGemeinschaft',
    UpdatePersonenGemeinschaftAddress: 'UpdatePersonenGemeinschaftAddress',
    CreatePersonenbezug: 'CreatePersonenbezug',
    SetPersonenbezugTagList: 'SetPersonenbezugTagList',
    deletePersonenbezug: 'deletePersonenbezug',
    CreateBuchungskreisForRechtstraeger: 'CreateBuchungskreisForRechtstraeger',
    AddKontoForRechtstraegerBankDetails: 'AddKontoForRechtstraegerBankDetails',
    setAbrechnungsdefinitionForRechnungsaussteller: 'setAbrechnungsdefinitionForRechnungsaussteller',
    SetMahndefinitionForRechtstraeger: 'SetMahndefinitionForRechtstraeger',
    UpdateSubAdministrationSettings: 'UpdateSubAdministrationSettings',
    SetVerwendungForKontoForRechtstraegerBankDetails: 'SetVerwendungForKontoForRechtstraegerBankDetails',
    SetMahnsperreForRechtstraeger: 'SetMahnsperreForRechtstraeger',
    CreateRechtstraegerSteuerpflicht: 'CreateRechtstraegerSteuerpflicht',
    UpdateRechtstraegerSteuerpflicht: 'UpdateRechtstraegerSteuerpflicht',
    DeleteRechtstraegerSteuerpflicht: 'DeleteRechtstraegerSteuerpflicht',
    CreateRechtstraegerPostIt: 'CreateRechtstraegerPostIt',
    CreateRepFondsEinstellung: 'CreateRepFondsEinstellung',
    UpdateRepFondsEinstellung: 'UpdateRepFondsEinstellung',
    DeleteRepFondsEinstellung: 'DeleteRepFondsEinstellung',
    CreateRepFondsErloesKontenZuweisung: 'CreateRepFondsErloesKontenZuweisung',
    UpdateRepFondsErloesKontenZuweisung: 'UpdateRepFondsErloesKontenZuweisung',
    DeleteRepFondsErloesKontenZuweisung: 'DeleteRepFondsErloesKontenZuweisung',
    CreateRepFondsEinstellungTemplate: 'CreateRepFondsEinstellungTemplate',
    UpdateRepFondsEinstellungTemplate: 'UpdateRepFondsEinstellungTemplate',
    DeleteRepFondsEinstellungTemplate: 'DeleteRepFondsEinstellungTemplate',
    createSubAdministrationPostIt: 'createSubAdministrationPostIt',
    UpdateSubAdministration: 'UpdateSubAdministration',
    CreateTextbaustein: 'CreateTextbaustein',
    UpdateTextbaustein: 'UpdateTextbaustein',
    DeleteTextbaustein: 'DeleteTextbaustein',
    CreateTextbausteinTemplate: 'CreateTextbausteinTemplate',
    UpdateTextbausteinTemplate: 'UpdateTextbausteinTemplate',
    DeleteTextbausteinTemplate: 'DeleteTextbausteinTemplate',
    CreateObjektTicket: 'CreateObjektTicket',
    CreateObjektInfoFeldTicket: 'CreateObjektInfoFeldTicket',
    CreateBestandseinheitTicket: 'CreateBestandseinheitTicket',
    CreateBestandseinheitInfoFeldTicket: 'CreateBestandseinheitInfoFeldTicket',
    CreateVertragTicket: 'CreateVertragTicket',
    CreateVertragInfoFeldTicket: 'CreateVertragInfoFeldTicket',
    CreateRechtstraegerTicket: 'CreateRechtstraegerTicket',
    CreateRechtstraegerInfoFeldTicket: 'CreateRechtstraegerInfoFeldTicket',
    UpdateTicket: 'UpdateTicket',
    DeleteTicketAttachment: 'DeleteTicketAttachment',
    ArchiveTicket: 'ArchiveTicket',
    UnarchiveTicket: 'UnarchiveTicket',
    TicketUpdateStatus: 'TicketUpdateStatus',
    TicketCreateSubTicket: 'TicketCreateSubTicket',
    TicketChangeSubTicketPosition: 'TicketChangeSubTicketPosition',
    CreateTicketComment: 'CreateTicketComment',
    UpdateTicketComment: 'UpdateTicketComment',
    DeleteTicketComment: 'DeleteTicketComment',
    assignRechtstraegerToParticipant: 'assignRechtstraegerToParticipant',
    TicketParticipant: 'TicketParticipant',
    CreateTicketTemplate: 'CreateTicketTemplate',
    UpdateTicketTemplate: 'UpdateTicketTemplate',
    DeleteTicketTemplate: 'DeleteTicketTemplate',
    TicketTemplateCreateSubTicketTemplate: 'TicketTemplateCreateSubTicketTemplate',
    TicketTemplateChangePosition: 'TicketTemplateChangePosition',
    CreateTicketTemplateTemplate: 'CreateTicketTemplateTemplate',
    UpdateTicketTemplateTemplate: 'UpdateTicketTemplateTemplate',
    DeleteTicketTemplateTemplate: 'DeleteTicketTemplateTemplate',
    TicketTemplateTemplateCreateSubTicketTemplate: 'TicketTemplateTemplateCreateSubTicketTemplate',
    TicketTemplateTemplateChangePosition: 'TicketTemplateTemplateChangePosition',
    createOrUpdateColumnSettings: 'createOrUpdateColumnSettings',
    deleteColumnSettings: 'deleteColumnSettings',
    CreateUstKategorieVersion: 'CreateUstKategorieVersion',
    UpdateUstKategorieVersion: 'UpdateUstKategorieVersion',
    DeleteUstKategorieVersion: 'DeleteUstKategorieVersion',
    CreateKSUstKategorie: 'CreateKSUstKategorie',
    CreateFDUstKategorie: 'CreateFDUstKategorie',
    UpdateUstKategorie: 'UpdateUstKategorie',
    ArchiveUstKategorie: 'ArchiveUstKategorie',
    UnarchiveUstKategorie: 'UnarchiveUstKategorie',
    DeleteUstKategorie: 'DeleteUstKategorie',
    CreateUstKategorieTemplateVersion: 'CreateUstKategorieTemplateVersion',
    UpdateUstKategorieTemplateVersion: 'UpdateUstKategorieTemplateVersion',
    DeleteUstKategorieTemplateVersion: 'DeleteUstKategorieTemplateVersion',
    CreateUstKategorieTemplate: 'CreateUstKategorieTemplate',
    UpdateUstKategorieTemplate: 'UpdateUstKategorieTemplate',
    DeleteUstKategorieTemplate: 'DeleteUstKategorieTemplate',
    CreateUstRegelsetUstKategorieZuweisung: 'CreateUstRegelsetUstKategorieZuweisung',
    UpdateUstRegelsetUstKategorieZuweisung: 'UpdateUstRegelsetUstKategorieZuweisung',
    DeleteUstRegelsetUstKategorieZuweisung: 'DeleteUstRegelsetUstKategorieZuweisung',
    CreateUstRegelsetTemplateUstKategorieZuweisung: 'CreateUstRegelsetTemplateUstKategorieZuweisung',
    UpdateUstRegelsetTemplateUstKategorieZuweisung: 'UpdateUstRegelsetTemplateUstKategorieZuweisung',
    DeleteUstRegelsetTemplateUstKategorieZuweisung: 'DeleteUstRegelsetTemplateUstKategorieZuweisung',
    CreateUstRegelsetTemplate: 'CreateUstRegelsetTemplate',
    UpdateUstRegelsetTemplate: 'UpdateUstRegelsetTemplate',
    DeleteUstRegelsetTemplate: 'DeleteUstRegelsetTemplate',
    UstVomAufwandApplyAllDeviations: 'UstVomAufwandApplyAllDeviations',
    DeleteUstVomAufwand: 'DeleteUstVomAufwand',
    UstVomAufwandFreigeben: 'UstVomAufwandFreigeben',
    UstVomAufwandMarkUnderReview: 'UstVomAufwandMarkUnderReview',
    CreateApplyVertragVposIndexedValueGenerierlauf: 'CreateApplyVertragVposIndexedValueGenerierlauf',
    RestartApplyVertragVposIndexedValueGenerierlauf: 'RestartApplyVertragVposIndexedValueGenerierlauf',
    RestartAuftragGenerierlauf: 'RestartAuftragGenerierlauf',
    CreateAuftragGenerierlauf: 'CreateAuftragGenerierlauf',
    RestartAuftragVerbuchenGenerierlauf: 'RestartAuftragVerbuchenGenerierlauf',
    CreateAuftragVerbuchenGenerierlauf: 'CreateAuftragVerbuchenGenerierlauf',
    RestartEigenbelegErstellenGenerierlauf: 'RestartEigenbelegErstellenGenerierlauf',
    CreateEigenbelegErstellenGenerierlauf: 'CreateEigenbelegErstellenGenerierlauf',
    RestartBookingSuggestionCreationGenerierlauf: 'RestartBookingSuggestionCreationGenerierlauf',
    CreateBookingSuggestionVerbuchenGenerierlauf: 'CreateBookingSuggestionVerbuchenGenerierlauf',
    RestartBudgetingGenerierlauf: 'RestartBudgetingGenerierlauf',
    CreateCamtGenerierlauf: 'CreateCamtGenerierlauf',
    RestartFirmendatenInitializerGenerierlauf: 'RestartFirmendatenInitializerGenerierlauf',
    RestartHeAbrechnungGenerierlauf: 'RestartHeAbrechnungGenerierlauf',
    RestartIncomingInvoiceBookingCreationGenerierlauf: 'RestartIncomingInvoiceBookingCreationGenerierlauf',
    CreateIncomingInvoiceBookingCreationGenerierlauf: 'CreateIncomingInvoiceBookingCreationGenerierlauf',
    RestartInfoMailDeliveryDetermineRecipientListGenerierlauf: 'RestartInfoMailDeliveryDetermineRecipientListGenerierlauf',
    RestartInfoMailDeliverySendGenerierlauf: 'RestartInfoMailDeliverySendGenerierlauf',
    RestartMahnungAusgebenGenerierlauf: 'RestartMahnungAusgebenGenerierlauf',
    CreateMahnungAusgebenGenerierlauf: 'CreateMahnungAusgebenGenerierlauf',
    RestartMahnungErzeugenGenerierlauf: 'RestartMahnungErzeugenGenerierlauf',
    CreateMahnungErzeugenGenerierlauf: 'CreateMahnungErzeugenGenerierlauf',
    RestartMahnungVerbuchenGenerierlauf: 'RestartMahnungVerbuchenGenerierlauf',
    CreateMahnungVerbuchenGenerierlauf: 'CreateMahnungVerbuchenGenerierlauf',
    RestartMahnungVersendenGenerierlauf: 'RestartMahnungVersendenGenerierlauf',
    CreateMahnungVersendenGenerierlauf: 'CreateMahnungVersendenGenerierlauf',
    RestartObjektAbrechnungGenerierlauf: 'RestartObjektAbrechnungGenerierlauf',
    CreateObjektFinancialPlanGenerierlauf: 'CreateObjektFinancialPlanGenerierlauf',
    RestartObjektFinancialPlanGenerierlauf: 'RestartObjektFinancialPlanGenerierlauf',
    RestartPaymentProposalCreationGenerierlauf: 'RestartPaymentProposalCreationGenerierlauf',
    RestartPaymentProposalExecutionGenerierlauf: 'RestartPaymentProposalExecutionGenerierlauf',
    CreatePaymentProposalCreationGenerierlauf: 'CreatePaymentProposalCreationGenerierlauf',
    CreatePaymentProposalExecutionGenerierlauf: 'CreatePaymentProposalExecutionGenerierlauf',
    RestartSubAbrechnungGenerierlauf: 'RestartSubAbrechnungGenerierlauf',
    RestartTopAbrechnungGenerierlauf: 'RestartTopAbrechnungGenerierlauf',
    CreateTopFinancialPlanGenerierlauf: 'CreateTopFinancialPlanGenerierlauf',
    RestartTopFinancialPlanGenerierlauf: 'RestartTopFinancialPlanGenerierlauf',
    RestartUstVomAufwandGenerierlauf: 'RestartUstVomAufwandGenerierlauf',
    CreateUstVomAufwandGenerierlauf: 'CreateUstVomAufwandGenerierlauf',
    CreateVertragVposIndexedValueGenerierlauf: 'CreateVertragVposIndexedValueGenerierlauf',
    RestartVertragVposIndexedValueGenerierlauf: 'RestartVertragVposIndexedValueGenerierlauf',
    RestartVorschreibungMailDeliveryDetermineRecipientListGenerierlauf: 'RestartVorschreibungMailDeliveryDetermineRecipientListGenerierlauf',
    RestartVorschreibungMailDeliverySendGenerierlauf: 'RestartVorschreibungMailDeliverySendGenerierlauf',
    RestartVorsteuerkuerzungGenerierlauf: 'RestartVorsteuerkuerzungGenerierlauf',
    CreateVorsteuerkuerzungGenerierlauf: 'CreateVorsteuerkuerzungGenerierlauf',
    RestartZinslisteGenerierlauf: 'RestartZinslisteGenerierlauf',
    CreateZinslisteGenerierlauf: 'CreateZinslisteGenerierlauf',
    BeVertragApplyAllDeviations: 'BeVertragApplyAllDeviations',
    BeVertragPruefungAussetzen: 'BeVertragPruefungAussetzen',
    BeVertragPruefungAktivieren: 'BeVertragPruefungAktivieren',
    CreateBeVertragInfoFeldPostIt: 'CreateBeVertragInfoFeldPostIt',
    DeleteBeVertragInfoFeld: 'DeleteBeVertragInfoFeld',
    UnarchiveBeVertragInfoFeld: 'UnarchiveBeVertragInfoFeld',
    ArchiveBeVertragInfoFeld: 'ArchiveBeVertragInfoFeld',
    CreateBeVertragDokumentenversand: 'CreateBeVertragDokumentenversand',
    UpdateBeVertragDokumentenversand: 'UpdateBeVertragDokumentenversand',
    DeleteBeVertragDokumentenversandEntry: 'DeleteBeVertragDokumentenversandEntry',
    CreateBeVertragVersion: 'CreateBeVertragVersion',
    UpdateBeVertragVersion: 'UpdateBeVertragVersion',
    DeleteBeVertragVersion: 'DeleteBeVertragVersion',
    CreateVertragVposIndexationAgreementCategory: 'CreateVertragVposIndexationAgreementCategory',
    CreateVertragVposIndexationAgreementIndexAnnual: 'CreateVertragVposIndexationAgreementIndexAnnual',
    CreateVertragVposIndexationAgreementIndexThreshold: 'CreateVertragVposIndexationAgreementIndexThreshold',
    CreateVertragVposIndexationAgreementReferenceValue: 'CreateVertragVposIndexationAgreementReferenceValue',
    UpdateVertragVposIndexationAgreementCategory: 'UpdateVertragVposIndexationAgreementCategory',
    UpdateVertragVposIndexationAgreementIndexAnnual: 'UpdateVertragVposIndexationAgreementIndexAnnual',
    UpdateVertragVposIndexationAgreementIndexThreshold: 'UpdateVertragVposIndexationAgreementIndexThreshold',
    UpdateVertragVposIndexationAgreementReferenceValue: 'UpdateVertragVposIndexationAgreementReferenceValue',
    DeleteVertragVposIndexationAgreement: 'DeleteVertragVposIndexationAgreement',
    CreateBeVertragVorschreibungspositionWert: 'CreateBeVertragVorschreibungspositionWert',
    UpdateBeVertragVorschreibungspositionWert: 'UpdateBeVertragVorschreibungspositionWert',
    DeleteBeVertragVorschreibunspositionWert: 'DeleteBeVertragVorschreibunspositionWert',
    CreateBeVertrag: 'CreateBeVertrag',
    UpdateBeVertrag: 'UpdateBeVertrag',
    DeleteBeVertrag: 'DeleteBeVertrag',
    BeVertragMarkUnderReview: 'BeVertragMarkUnderReview',
    CreateBeVertragPostIt: 'CreateBeVertragPostIt',
    CreateHeVertragZahlungsregel: 'CreateHeVertragZahlungsregel',
    UpdateHeVertragZahlungsregel: 'UpdateHeVertragZahlungsregel',
    DeleteHeVertragZahlungsregel: 'DeleteHeVertragZahlungsregel',
    ArchiveHeVertragZahlungsregel: 'ArchiveHeVertragZahlungsregel',
    UnarchiveHeVertragZahlungsregel: 'UnarchiveHeVertragZahlungsregel',
    CalculateHeVertragZahlungsregelSumVposNetto: 'CalculateHeVertragZahlungsregelSumVposNetto',
    CreateEinmalzahlung: 'CreateEinmalzahlung',
    DeleteAuszahlung: 'DeleteAuszahlung',
    CreateHeVertrag: 'CreateHeVertrag',
    UpdateHeVertrag: 'UpdateHeVertrag',
    DeleteHeVertrag: 'DeleteHeVertrag',
    CreateHeVertragPostIt: 'CreateHeVertragPostIt',
    VorschreibungApplyAllDeviations: 'VorschreibungApplyAllDeviations',
    AuftragStornieren: 'AuftragStornieren',
    CreateVorschreibungspositionVersion: 'CreateVorschreibungspositionVersion',
    UpdateVorschreibungspositionVersion: 'UpdateVorschreibungspositionVersion',
    DeleteVorschreibungspositionVersion: 'DeleteVorschreibungspositionVersion',
    CreateKSVorschreibungsposition: 'CreateKSVorschreibungsposition',
    CreateFDVorschreibungsposition: 'CreateFDVorschreibungsposition',
    UpdateVorschreibungsposition: 'UpdateVorschreibungsposition',
    DeleteVorschreibungsposition: 'DeleteVorschreibungsposition',
    UnarchiveVorschreibungsposition: 'UnarchiveVorschreibungsposition',
    ArchiveVorschreibungsposition: 'ArchiveVorschreibungsposition',
    CreateVorschreibungspositionTemplateVersion: 'CreateVorschreibungspositionTemplateVersion',
    UpdateVorschreibungspositionTemplateVersion: 'UpdateVorschreibungspositionTemplateVersion',
    DeleteVorschreibungspositionTemplateVersion: 'DeleteVorschreibungspositionTemplateVersion',
    CreateVorschreibungspositionTemplate: 'CreateVorschreibungspositionTemplate',
    UpdateVorschreibungspositionTemplate: 'UpdateVorschreibungspositionTemplate',
    DeleteVorschreibungspositionTemplate: 'DeleteVorschreibungspositionTemplate',
    UnarchiveVorschreibungspositionTemplate: 'UnarchiveVorschreibungspositionTemplate',
    ArchiveVorschreibungspositionTemplate: 'ArchiveVorschreibungspositionTemplate',
    VorsteuerkuerzungApplyAllDeviations: 'VorsteuerkuerzungApplyAllDeviations',
    CreateVorsteuerkuerzungEinstellung: 'CreateVorsteuerkuerzungEinstellung',
    UpdateVorsteuerkuerzungEinstellung: 'UpdateVorsteuerkuerzungEinstellung',
    DeleteVorsteuerkuerzungEinstellung: 'DeleteVorsteuerkuerzungEinstellung',
    DeleteVorsteuerkuerzung: 'DeleteVorsteuerkuerzung',
    VorsteuerkuerzungFreigeben: 'VorsteuerkuerzungFreigeben',
    VorsteuerkuerzungMarkUnderReview: 'VorsteuerkuerzungMarkUnderReview',
    CreateVorsteuerkuerzungEinstellungTemplate: 'CreateVorsteuerkuerzungEinstellungTemplate',
    UpdateVorsteuerkuerzungEinstellungTemplate: 'UpdateVorsteuerkuerzungEinstellungTemplate',
    DeleteVorsteuerkuerzungEinstellungTemplate: 'DeleteVorsteuerkuerzungEinstellungTemplate',
    createInfoMailDeliveryWizard: 'createInfoMailDeliveryWizard',
    updateInfoMailDelivery: 'updateInfoMailDelivery',
    deleteInfoMailDeliveryWizard: 'deleteInfoMailDeliveryWizard',
    deleteInfoMailDeliveryAttachment: 'deleteInfoMailDeliveryAttachment',
    CompleteInfoMailDetermineRecipientListStep: 'CompleteInfoMailDetermineRecipientListStep',
    sendInfoMail: 'sendInfoMail',
    createVorschreibungMailDeliveryWizard: 'createVorschreibungMailDeliveryWizard',
    updateVorschreibungMailDelivery: 'updateVorschreibungMailDelivery',
    deleteVorschreibungMailDeliveryWizard: 'deleteVorschreibungMailDeliveryWizard',
    deleteVorschreibungMailDeliveryAttachment: 'deleteVorschreibungMailDeliveryAttachment',
    CompleteVorschreibungMailDetermineRecipientListStep: 'CompleteVorschreibungMailDetermineRecipientListStep',
    sendVorschreibungMail: 'sendVorschreibungMail',
    ZinslisteApplyAllDeviations: 'ZinslisteApplyAllDeviations',
    CreateZinsliste: 'CreateZinsliste',
    DeleteZinsliste: 'DeleteZinsliste',
    ZinslisteMarkUnderReview: 'ZinslisteMarkUnderReview',
    CreateUser: 'CreateUser',
    ChangeEmailMe: 'ChangeEmailMe',
    ChangeEmail: 'ChangeEmail',
    SperrenUser: 'SperrenUser',
    EntsperrenUser: 'EntsperrenUser',
    ChangePasswordMe: 'ChangePasswordMe',
    PasswordResetUser: 'PasswordResetUser',
    EigenbelegMarkUnderReview: 'EigenbelegMarkUnderReview',
  },
  Subscription: {
    getNotification: 'getNotification',
    OnDataChanged: 'OnDataChanged',
  },
  Fragment: {
    DeviationEntryFields: 'DeviationEntryFields',
    DeviationEntryAttributeFields: 'DeviationEntryAttributeFields',
    DeviationAttributeUriListFields: 'DeviationAttributeUriListFields',
    DeviationEntryAttributeDeviationAttributeUriListIdsFields: 'DeviationEntryAttributeDeviationAttributeUriListIdsFields',
    BuchungsanweisungDeviationSourceEntity: 'BuchungsanweisungDeviationSourceEntity',
    ObjektAbrechnungDeviationSourceEntity: 'ObjektAbrechnungDeviationSourceEntity',
    HeAbrechnungHeVertragDeviationSourceEntity: 'HeAbrechnungHeVertragDeviationSourceEntity',
    TopAbrechnungDeviationSourceEntity: 'TopAbrechnungDeviationSourceEntity',
    UstVomAufwandDeviationSourceEntity: 'UstVomAufwandDeviationSourceEntity',
    BeVertragDeviationSourceEntity: 'BeVertragDeviationSourceEntity',
    VorschreibungDeviationSourceEntity: 'VorschreibungDeviationSourceEntity',
    VorsteuerkuerzungDeviationSourceEntity: 'VorsteuerkuerzungDeviationSourceEntity',
    DeviationSourceEntity: 'DeviationSourceEntity',
    DeviationLinkList: 'DeviationLinkList',
    DeviationLinkListIdsFields: 'DeviationLinkListIdsFields',
    DeviationData: 'DeviationData',
    TextbausteinFields: 'TextbausteinFields',
    TopAbrechnungFields: 'TopAbrechnungFields',
    TopAbrechnungOverviewFields: 'TopAbrechnungOverviewFields',
    TopAbrechnungOutputOptionsFields: 'TopAbrechnungOutputOptionsFields',
    ObjektAbrechnungFields: 'ObjektAbrechnungFields',
    ObjAbrKontoFields: 'ObjAbrKontoFields',
    ObjAbrBuchungszeileFields: 'ObjAbrBuchungszeileFields',
    ObjektAbrechnungOutputOptionsFields: 'ObjektAbrechnungOutputOptionsFields',
    HeAbrAbrechnungskreisListField: 'HeAbrAbrechnungskreisListField',
    HeAbrechnungListEntryFields: 'HeAbrechnungListEntryFields',
    HeAbrechnungHeVertragListEntryFields: 'HeAbrechnungHeVertragListEntryFields',
    SubAbrAbrechnungskreisListFields: 'SubAbrAbrechnungskreisListFields',
    SubAbrKontoFields: 'SubAbrKontoFields',
    AbrInfoAbrechnungskreisFields: 'AbrInfoAbrechnungskreisFields',
    AbrInfoKontoFields: 'AbrInfoKontoFields',
    AbrechnungsdefinitionListEntryFields: 'AbrechnungsdefinitionListEntryFields',
    AbrechDefAbrechKreisFields: 'AbrechDefAbrechKreisFields',
    KontoAbrechnungskreisFields: 'KontoAbrechnungskreisFields',
    BkAbrechnungsdefinitionFields: 'BkAbrechnungsdefinitionFields',
    BkAbrechnungsdefinitionVersionFields: 'BkAbrechnungsdefinitionVersionFields',
    BkAbrechnungsdefinitionVersionListEntryFields: 'BkAbrechnungsdefinitionVersionListEntryFields',
    HeAbrechnungsdefinitionFields: 'HeAbrechnungsdefinitionFields',
    HeAbrechnungsdefinitionVersionFields: 'HeAbrechnungsdefinitionVersionFields',
    HeAbrechnungsdefinitionVersionListEntryFields: 'HeAbrechnungsdefinitionVersionListEntryFields',
    SubAdministrationAbrechnungsdefinitionFields: 'SubAdministrationAbrechnungsdefinitionFields',
    SubAdministrationAbrechnungsdefinitionVersionFields: 'SubAdministrationAbrechnungsdefinitionVersionFields',
    SubAdministrationAbrechnungsdefinitionVersionListEntryFields: 'SubAdministrationAbrechnungsdefinitionVersionListEntryFields',
    WeAbrechnungsdefinitionFields: 'WeAbrechnungsdefinitionFields',
    WeAbrechnungsdefinitionVersionFields: 'WeAbrechnungsdefinitionVersionFields',
    WeAbrechnungsdefinitionVersionListEntryFields: 'WeAbrechnungsdefinitionVersionListEntryFields',
    AbrechnungskreisFields: 'AbrechnungskreisFields',
    AddressFields: 'AddressFields',
    OrderAddress: 'OrderAddress',
    FibuAddress: 'FibuAddress',
    AufteilungsschluesselFields: 'AufteilungsschluesselFields',
    AuftragsartFields: 'AuftragsartFields',
    AuftragsartBaseFields: 'AuftragsartBaseFields',
    SepaMandatFields: 'SepaMandatFields',
    BankDetailsFields: 'BankDetailsFields',
    FirmendatenBankDetailsFields: 'FirmendatenBankDetailsFields',
    ObjektBankDetailsFields: 'ObjektBankDetailsFields',
    OrderBankDetailsFields: 'OrderBankDetailsFields',
    BelegnummernkreisDefinitionFields: 'BelegnummernkreisDefinitionFields',
    BelegnummernkreisDefinitionTemplatesFields: 'BelegnummernkreisDefinitionTemplatesFields',
    BelegTexteTemplateFields: 'BelegTexteTemplateFields',
    BestandseinheitAufteilungsschluesselWertFields: 'BestandseinheitAufteilungsschluesselWertFields',
    BeInfoFeldKellerabteil: 'BeInfoFeldKellerabteil',
    BeInfoFeldParkplatz: 'BeInfoFeldParkplatz',
    BeInfoFeldZimmer: 'BeInfoFeldZimmer',
    BeInfoFeld: 'BeInfoFeld',
    BeOptionSteuerpflichtVersion: 'BeOptionSteuerpflichtVersion',
    NutzungsArtFields: 'NutzungsArtFields',
    BestandseinheitListEntryFields: 'BestandseinheitListEntryFields',
    BestandseinheitFields: 'BestandseinheitFields',
    BestandseinheitBaseFields: 'BestandseinheitBaseFields',
    OrderBestandseinheit: 'OrderBestandseinheit',
    FibuBuchungszeileFields: 'FibuBuchungszeileFields',
    BudgetingKontoBaseFields: 'BudgetingKontoBaseFields',
    BudgetingKontoFields: 'BudgetingKontoFields',
    BudgetingVPosBaseFields: 'BudgetingVPosBaseFields',
    BudgetingVPosFields: 'BudgetingVPosFields',
    BudgetingBaseFields: 'BudgetingBaseFields',
    BudgetingFields: 'BudgetingFields',
    BudgetingResultFields: 'BudgetingResultFields',
    BudgetingAbrDefVPosLinkageFields: 'BudgetingAbrDefVPosLinkageFields',
    BudgetingVPosAuftschluesselLinkageFields: 'BudgetingVPosAuftschluesselLinkageFields',
    ContactFields: 'ContactFields',
    OrderContactFields: 'OrderContactFields',
    ContactPersonFields: 'ContactPersonFields',
    FileInfoFields: 'FileInfoFields',
    VorschreibungMailDeliveryFields: 'VorschreibungMailDeliveryFields',
    EmailDeliveryDefinitionFields: 'EmailDeliveryDefinitionFields',
    EmailDeliveryDefinitionBaseFields: 'EmailDeliveryDefinitionBaseFields',
    EmailDeliveryDefinitionTemplatesFields: 'EmailDeliveryDefinitionTemplatesFields',
    FirmendatenFields: 'FirmendatenFields',
    FirmendatenBaseFields: 'FirmendatenBaseFields',
    HistoryListFields: 'HistoryListFields',
    HistoryFields: 'HistoryFields',
    HistoryAttributeFields: 'HistoryAttributeFields',
    SingleInvoiceFields: 'SingleInvoiceFields',
    OrderBuchungsanweisungFields: 'OrderBuchungsanweisungFields',
    StandingInvoiceFields: 'StandingInvoiceFields',
    StandingInvoicePartialAmountFields: 'StandingInvoicePartialAmountFields',
    IndexSeriesFields: 'IndexSeriesFields',
    IndexSeriesEntryFields: 'IndexSeriesEntryFields',
    VertragVposIndexedValueFields: 'VertragVposIndexedValueFields',
    KontoFields: 'KontoFields',
    OrderKonto: 'OrderKonto',
    AppKontoFields: 'AppKontoFields',
    FibuKontoFields: 'FibuKontoFields',
    KontenplanFields: 'KontenplanFields',
    KontierungstabelleFields: 'KontierungstabelleFields',
    KontierungstabelleBaseFields: 'KontierungstabelleBaseFields',
    KundenSystem: 'KundenSystem',
    FirmaForKS: 'FirmaForKS',
    MahndefinitionFields: 'MahndefinitionFields',
    MahnstufeFields: 'MahnstufeFields',
    MahnungFields: 'MahnungFields',
    Mitarbeiter: 'Mitarbeiter',
    MitarbeiterUser: 'MitarbeiterUser',
    MitarbeiterBaseFields: 'MitarbeiterBaseFields',
    ObjektInfoFeldAuflage: 'ObjektInfoFeldAuflage',
    ObjektInfoFeldGrundstuecksdaten: 'ObjektInfoFeldGrundstuecksdaten',
    ObjektInfoFeldHausbetreuung: 'ObjektInfoFeldHausbetreuung',
    ObjektInfoFeldVersicherung: 'ObjektInfoFeldVersicherung',
    ObjektInfoFeldPerson: 'ObjektInfoFeldPerson',
    ObjektInfoFeld: 'ObjektInfoFeld',
    ObjektListEntry: 'ObjektListEntry',
    Objekt: 'Objekt',
    OrderObjekt: 'OrderObjekt',
    FibuObjekt: 'FibuObjekt',
    PaymentObjekt: 'PaymentObjekt',
    ObjektBase: 'ObjektBase',
    OffenePostenFields: 'OffenePostenFields',
    OrderPaymentFields: 'OrderPaymentFields',
    EbicsUserFields: 'EbicsUserFields',
    EbicsUserIbanFields: 'EbicsUserIbanFields',
    Personenbezug: 'Personenbezug',
    ReceivedBankTransactionFields: 'ReceivedBankTransactionFields',
    AddressesAndContacts: 'AddressesAndContacts',
    RechtstraegerInfoFeldPersoneninfos: 'RechtstraegerInfoFeldPersoneninfos',
    RechtstraegerInfoFeld: 'RechtstraegerInfoFeld',
    PostIt: 'PostIt',
    RechnungsAusstellerFields: 'RechnungsAusstellerFields',
    RechtstraegerBaseFields: 'RechtstraegerBaseFields',
    RechtstraegerListEntry: 'RechtstraegerListEntry',
    RechtstraegerFields: 'RechtstraegerFields',
    FibuRechtstraeger: 'FibuRechtstraeger',
    OrderRechtstraeger: 'OrderRechtstraeger',
    PaymentRechtstraeger: 'PaymentRechtstraeger',
    RechtstraegerSteuerpflicht: 'RechtstraegerSteuerpflicht',
    VorschreibungRechtstraeger: 'VorschreibungRechtstraeger',
    RepFondsEinstellungFields: 'RepFondsEinstellungFields',
    GeneralTextbausteinFields: 'GeneralTextbausteinFields',
    TicketBaseFields: 'TicketBaseFields',
    TicketFields: 'TicketFields',
    TicketChangeEntryFields: 'TicketChangeEntryFields',
    TicketCommentFields: 'TicketCommentFields',
    TicketTemplateFields: 'TicketTemplateFields',
    UstKategorieVersionFields: 'UstKategorieVersionFields',
    UstKategorieFields: 'UstKategorieFields',
    UstKategorieEntryFields: 'UstKategorieEntryFields',
    UstRegelsetUstKategorieZuweisungFields: 'UstRegelsetUstKategorieZuweisungFields',
    UstRegelsetFields: 'UstRegelsetFields',
    UstVomAufwandFields: 'UstVomAufwandFields',
    ApplyVertragVposIndexedValueGenerierlaufEntryFields: 'ApplyVertragVposIndexedValueGenerierlaufEntryFields',
    AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttributeFields: 'AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttributeFields',
    BudgetingGenerierlaufEntryFields: 'BudgetingGenerierlaufEntryFields',
    BudgetingGenerierlaufFields: 'BudgetingGenerierlaufFields',
    FirmendatenInitializerGenerierlaufFields: 'FirmendatenInitializerGenerierlaufFields',
    ObjektFinancialPlanGenerierlaufEntryFields: 'ObjektFinancialPlanGenerierlaufEntryFields',
    ObjektFinancialPlanGenerierlaufFields: 'ObjektFinancialPlanGenerierlaufFields',
    TopFinancialPlanGenerierlaufEntryFields: 'TopFinancialPlanGenerierlaufEntryFields',
    TopFinancialPlanGenerierlaufFields: 'TopFinancialPlanGenerierlaufFields',
    VertragVposIndexedValueGenerierlaufEntryFields: 'VertragVposIndexedValueGenerierlaufEntryFields',
    GenerierlaufAsyncStepFields: 'GenerierlaufAsyncStepFields',
    VertragInfoFeldWeiterePerson: 'VertragInfoFeldWeiterePerson',
    VertragInfoFeldKuendigungsinfos: 'VertragInfoFeldKuendigungsinfos',
    VertragInfoFeld: 'VertragInfoFeld',
    dokumentenversandEntry: 'dokumentenversandEntry',
    VertragVposIndexationAgreementCategoryFields: 'VertragVposIndexationAgreementCategoryFields',
    VertragVposIndexationAgreementIndexAnnualFields: 'VertragVposIndexationAgreementIndexAnnualFields',
    VertragVposIndexationAgreementIndexThresholdFields: 'VertragVposIndexationAgreementIndexThresholdFields',
    VertragVposIndexationAgreementReferenceValueFields: 'VertragVposIndexationAgreementReferenceValueFields',
    VertragVposIndexationAgreementFields: 'VertragVposIndexationAgreementFields',
    VPosWertChangeReasonFields: 'VPosWertChangeReasonFields',
    BeVertragVorschreibungspositionBaseFields: 'BeVertragVorschreibungspositionBaseFields',
    BeVertragVorschreibungspositionFields: 'BeVertragVorschreibungspositionFields',
    BeVertragVorschreibungsPositionWertFields: 'BeVertragVorschreibungsPositionWertFields',
    BeVertrag: 'BeVertrag',
    VertragBaseFields: 'VertragBaseFields',
    AuftragListEntry: 'AuftragListEntry',
    Auftrag: 'Auftrag',
    AppAuftragFields: 'AppAuftragFields',
    VorschreibungspositionVersion: 'VorschreibungspositionVersion',
    Vorschreibungsposition: 'Vorschreibungsposition',
    VorsteuerkuerzungEinstellungFields: 'VorsteuerkuerzungEinstellungFields',
    VorsteuerkuerzungListEntry: 'VorsteuerkuerzungListEntry',
    Vorsteuerkuerzung: 'Vorsteuerkuerzung',
    InfoMailDeliveryFields: 'InfoMailDeliveryFields',
    ZinslisteListEntry: 'ZinslisteListEntry',
    Zinsliste: 'Zinsliste',
    userFields: 'userFields',
    eigenbelegFields: 'eigenbelegFields',
    Eigenbeleg: 'Eigenbeleg',
    EigenbelegsPosition: 'EigenbelegsPosition',
  },
};
