import React, { FC } from 'react';
import { Empty } from 'antd';
import { NestedTableColProps } from '../../../../../../components/Table/NestedTable/NestedTable';
import { ObjAbrBuchungszeile } from '../../../../../../types';
import NestedTableWithColSelector from '../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';

type Props = {
  buchungszeileList: ObjAbrBuchungszeile[];
  columns: NestedTableColProps<ObjAbrBuchungszeile>[];
  parentRowKeys?: string[];
  visible?: boolean;
  subLevel?: number;
};

const ObjektAbrechnungBuchungszeileTable: FC<Props> = ({ buchungszeileList, columns, parentRowKeys, subLevel, visible = true }) => {
  return (
    <NestedTableWithColSelector<ObjAbrBuchungszeile>
      visible={visible}
      parentRowKeys={parentRowKeys}
      level={3}
      subLevel={subLevel}
      locale={{
        emptyText: <Empty description={<span>Keine Daten vorhanden</span>} />,
      }}
      dataSource={buchungszeileList}
      columns={columns}
    />
  );
};
export default ObjektAbrechnungBuchungszeileTable;
