import { FormFields } from '../../../../../../../helpers/formikHelper';
import { InfoFeldFormValues } from '../../../../../../shared/InfoFeld/infoFeldFormMapper';

import { VertragInfoFeld_VertragInfoFeldWeiterePerson_Fragment } from '../../../gql/VertragInfoFeldFragments.types';

export interface WeiterePersonUpdateFormValues extends InfoFeldFormValues {
  natuerlichePersonId: string;
  text?: string;
  deleteDocumentFileIdList: string[];
}

export const weiterePersonUpdateFormFields: FormFields<WeiterePersonUpdateFormValues> = {
  natuerlichePersonId: 'natuerlichePersonId',
  text: 'text',
  documentList: 'documentList',
  deleteDocumentFileIdList: 'deleteDocumentFileIdList',
};

export const mapToUpdateFormValues = (infoFeld: VertragInfoFeld_VertragInfoFeldWeiterePerson_Fragment): WeiterePersonUpdateFormValues => ({
  documentList: [],
  deleteDocumentFileIdList: [],
  natuerlichePersonId: infoFeld.natuerlichePerson.rechtstraegerId,
  text: infoFeld.text ?? undefined,
});
