import React, { FC } from 'react';
import { Divider, Empty, Table } from 'antd';
import { Spacer } from '../../../../components/Grid';
import errorLogTableColumns from '../../shared/errorLogTableColumns';
import InfoAbrKreisTable from '../../shared/InfoAbrKreisTable/Level1/InfoAbrKreisTable';
import { ErrorData, ObjektAbrechnung } from '../../../../types';
import ObjektAbrechnungTable from './Table/Level1/ObjektAbrechnungTable';
import NestedTableWithColSelectorWrapper from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelectorWrapper';

type Props = {
  objektAbrErrorList: ErrorData[];
  loading: boolean;
  abrechnung: ObjektAbrechnung;
};

const ObjektAbrechnungTab2: FC<Props> = ({ objektAbrErrorList, abrechnung }) => {
  return (
    <>
      <Spacer height={16} />
      {objektAbrErrorList.length > 0 && (
        <>
          <Table<ErrorData>
            size="small"
            pagination={false}
            dataSource={objektAbrErrorList}
            rowKey={(record) => record.type}
            columns={errorLogTableColumns}
            locale={{
              emptyText: <Empty description={<span>Keine Fehler vorhanden</span>} />,
            }}
          />
          <Divider />
        </>
      )}
      <NestedTableWithColSelectorWrapper filterIdentifier="objekt-abrechnung-main5">
        <ObjektAbrechnungTable
          abrechnungskreisList={abrechnung.abrechnungskreisList}
          buchungskreisId={abrechnung.buchungskreisId}
          fromInclusive={abrechnung.fromInclusive}
          toInclusive={abrechnung.toInclusive}
          objektId={abrechnung.objekt.objektId}
          abrechnungType={abrechnung.type}
        />
      </NestedTableWithColSelectorWrapper>
      {abrechnung.infoAbrechnungskreisList.length > 0 && (
        <>
          <Spacer height={16} />
          <InfoAbrKreisTable abrechnungskreisList={abrechnung.infoAbrechnungskreisList} />
        </>
      )}
    </>
  );
};

export default ObjektAbrechnungTab2;
