import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBeInfoFeldPostItMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  beInfoFeldId: Types.Scalars['ID']['input'];
  input: Types.PostItInput;
}>;

export type CreateBeInfoFeldPostItMutation = {
  createBeInfoFeldPostIt: {
    data: { postItId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteBeInfoFeldMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  infoFeldId: Types.Scalars['ID']['input'];
}>;

export type DeleteBeInfoFeldMutation = {
  deleteBeInfoFeld: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type UnarchiveBeInfoFeldMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  infoFeldId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveBeInfoFeldMutation = {
  actionUnarchiveBeInfoFeld: {
    data: { beInfoFeldId: string } | { beInfoFeldId: string } | { beInfoFeldId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ArchiveBeInfoFeldMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  infoFeldId: Types.Scalars['ID']['input'];
}>;

export type ArchiveBeInfoFeldMutation = {
  actionArchiveBeInfoFeld: {
    data: { beInfoFeldId: string } | { beInfoFeldId: string } | { beInfoFeldId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateBeInfoFeldPostItDocument = gql`
  mutation CreateBeInfoFeldPostIt($objektId: ID!, $bestandseinheitId: ID!, $beInfoFeldId: ID!, $input: PostItInput!) {
    createBeInfoFeldPostIt(objektId: $objektId, bestandseinheitId: $bestandseinheitId, beInfoFeldId: $beInfoFeldId, input: $input) {
      data {
        postItId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateBeInfoFeldPostItMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBeInfoFeldPostItMutation, CreateBeInfoFeldPostItMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBeInfoFeldPostItMutation, CreateBeInfoFeldPostItMutationVariables>(CreateBeInfoFeldPostItDocument, options);
}
export type CreateBeInfoFeldPostItMutationHookResult = ReturnType<typeof useCreateBeInfoFeldPostItMutation>;
export type CreateBeInfoFeldPostItMutationResult = Apollo.MutationResult<CreateBeInfoFeldPostItMutation>;
export type CreateBeInfoFeldPostItMutationOptions = Apollo.BaseMutationOptions<
  CreateBeInfoFeldPostItMutation,
  CreateBeInfoFeldPostItMutationVariables
>;
export const DeleteBeInfoFeldDocument = gql`
  mutation DeleteBeInfoFeld($objektId: ID!, $bestandseinheitId: ID!, $infoFeldId: ID!) {
    deleteBeInfoFeld(objektId: $objektId, bestandseinheitId: $bestandseinheitId, infoFeldId: $infoFeldId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteBeInfoFeldMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBeInfoFeldMutation, DeleteBeInfoFeldMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBeInfoFeldMutation, DeleteBeInfoFeldMutationVariables>(DeleteBeInfoFeldDocument, options);
}
export type DeleteBeInfoFeldMutationHookResult = ReturnType<typeof useDeleteBeInfoFeldMutation>;
export type DeleteBeInfoFeldMutationResult = Apollo.MutationResult<DeleteBeInfoFeldMutation>;
export type DeleteBeInfoFeldMutationOptions = Apollo.BaseMutationOptions<DeleteBeInfoFeldMutation, DeleteBeInfoFeldMutationVariables>;
export const UnarchiveBeInfoFeldDocument = gql`
  mutation UnarchiveBeInfoFeld($objektId: ID!, $bestandseinheitId: ID!, $infoFeldId: ID!) {
    actionUnarchiveBeInfoFeld(objektId: $objektId, bestandseinheitId: $bestandseinheitId, infoFeldId: $infoFeldId) {
      data {
        beInfoFeldId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveBeInfoFeldMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveBeInfoFeldMutation, UnarchiveBeInfoFeldMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveBeInfoFeldMutation, UnarchiveBeInfoFeldMutationVariables>(UnarchiveBeInfoFeldDocument, options);
}
export type UnarchiveBeInfoFeldMutationHookResult = ReturnType<typeof useUnarchiveBeInfoFeldMutation>;
export type UnarchiveBeInfoFeldMutationResult = Apollo.MutationResult<UnarchiveBeInfoFeldMutation>;
export type UnarchiveBeInfoFeldMutationOptions = Apollo.BaseMutationOptions<UnarchiveBeInfoFeldMutation, UnarchiveBeInfoFeldMutationVariables>;
export const ArchiveBeInfoFeldDocument = gql`
  mutation ArchiveBeInfoFeld($objektId: ID!, $bestandseinheitId: ID!, $infoFeldId: ID!) {
    actionArchiveBeInfoFeld(objektId: $objektId, bestandseinheitId: $bestandseinheitId, infoFeldId: $infoFeldId) {
      data {
        beInfoFeldId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveBeInfoFeldMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveBeInfoFeldMutation, ArchiveBeInfoFeldMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveBeInfoFeldMutation, ArchiveBeInfoFeldMutationVariables>(ArchiveBeInfoFeldDocument, options);
}
export type ArchiveBeInfoFeldMutationHookResult = ReturnType<typeof useArchiveBeInfoFeldMutation>;
export type ArchiveBeInfoFeldMutationResult = Apollo.MutationResult<ArchiveBeInfoFeldMutation>;
export type ArchiveBeInfoFeldMutationOptions = Apollo.BaseMutationOptions<ArchiveBeInfoFeldMutation, ArchiveBeInfoFeldMutationVariables>;
