import React, { FC } from 'react';
import VorschreibungEmailDelivery from './VorschreibungEmailDelivery';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { Auftrag } from '../../../../types';

type Props = {
  auftrag: Auftrag;
};

const VorschreibungEmailDeliveryInfoRow: FC<Props> = ({ auftrag }) => {
  const originalEmail = auftrag.originalMailDelivery?.emailAddress ?? auftrag.auftragsKopf.recipientEmailContact.wert;

  return (
    <>
      <CardDetailsInfoRow infoRowTitle="Zustell-E-Mail-Adresse">
        <VorschreibungEmailDelivery email={originalEmail} emailDelivery={auftrag.originalMailDelivery} />
      </CardDetailsInfoRow>

      <CardDetailsInfoRow infoRowTitle="E-Mail Kopien">
        {auftrag.copyMailDeliveryList.length > 0 ? (
          auftrag.copyMailDeliveryList.map((emailDelivery, index) => (
            <VorschreibungEmailDelivery key={index} email={emailDelivery.emailAddress} emailDelivery={emailDelivery} />
          ))
        ) : (
          <TextForEmptyValue textToShow="minus" />
        )}
      </CardDetailsInfoRow>
    </>
  );
};

export default VorschreibungEmailDeliveryInfoRow;
