import React from 'react';
import { Route } from 'react-router';
import { isAdminOrHasSystemAdminFm } from '../../../security/permissionChecks';
import AuthRoute from '../../../security/AuthRoute';
import VerwaltungMitarbeiterDetailsPage from './index';
import { URI_MANAGEMENT_PAGE_SYSTEM_EMPLOYEE_DETAILS_PAGE } from '../../../constants/managementPageUriPaths';

export type MitarbeiterPageRouteParams = { firmendatenId: string; mitarbeiterId: string };

const verwaltungMitarbeiterDetailsPageRoute = (
  <Route
    path={URI_MANAGEMENT_PAGE_SYSTEM_EMPLOYEE_DETAILS_PAGE}
    element={<AuthRoute hasPermissionFn={isAdminOrHasSystemAdminFm} element={<VerwaltungMitarbeiterDetailsPage />} />}
  />
);

export default verwaltungMitarbeiterDetailsPageRoute;
