import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssignAbrechnungsdefinitionKontoAbrechnungskreisTemplateListMutationVariables = Types.Exact<{
  input: Types.AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListInput;
}>;

export type AssignAbrechnungsdefinitionKontoAbrechnungskreisTemplateListMutation = {
  assignAbrechnungsdefinitionKontoAbrechnungskreisTemplateList: {
    data: Array<{ abrechnungsdefinition: { abrechnungsdefinitionId: string } }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const AssignAbrechnungsdefinitionKontoAbrechnungskreisTemplateListDocument = gql`
  mutation AssignAbrechnungsdefinitionKontoAbrechnungskreisTemplateList($input: AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListInput!) {
    assignAbrechnungsdefinitionKontoAbrechnungskreisTemplateList(input: $input) {
      data {
        abrechnungsdefinition {
          abrechnungsdefinitionId
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useAssignAbrechnungsdefinitionKontoAbrechnungskreisTemplateListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignAbrechnungsdefinitionKontoAbrechnungskreisTemplateListMutation,
    AssignAbrechnungsdefinitionKontoAbrechnungskreisTemplateListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignAbrechnungsdefinitionKontoAbrechnungskreisTemplateListMutation,
    AssignAbrechnungsdefinitionKontoAbrechnungskreisTemplateListMutationVariables
  >(AssignAbrechnungsdefinitionKontoAbrechnungskreisTemplateListDocument, options);
}
export type AssignAbrechnungsdefinitionKontoAbrechnungskreisTemplateListMutationHookResult = ReturnType<
  typeof useAssignAbrechnungsdefinitionKontoAbrechnungskreisTemplateListMutation
>;
export type AssignAbrechnungsdefinitionKontoAbrechnungskreisTemplateListMutationResult =
  Apollo.MutationResult<AssignAbrechnungsdefinitionKontoAbrechnungskreisTemplateListMutation>;
export type AssignAbrechnungsdefinitionKontoAbrechnungskreisTemplateListMutationOptions = Apollo.BaseMutationOptions<
  AssignAbrechnungsdefinitionKontoAbrechnungskreisTemplateListMutation,
  AssignAbrechnungsdefinitionKontoAbrechnungskreisTemplateListMutationVariables
>;
