import React from 'react';
import { TableWithAlignedColsExpandedRowType } from '../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import TableWithAlignedColsHeaderRow from '../../../components/Table/TableWithAlignedCols/TableWithAlignedColsHeaderRow';
import TableWithAlignedColsBody from '../../../components/Table/TableWithAlignedCols/TableWithAlignedColsBody';
import aufwandsKontoRow from './aufwandsKontoRow';
import vstKuerzungKontoRowColumns from './vstKuerzungKontoRowColumns';
import { VorsteuerkuerzungVertrag, VorsteuerkuerzungVstKrzKonto } from '../../../types';

const vstKuerzungKontoRow = (
  objektId: string,
  rechtstraegerId: string,
  vonInklusive: string,
  bisInklusive: string
): TableWithAlignedColsExpandedRowType<VorsteuerkuerzungVertrag>[] => [
  {
    header: (isOpen, setIsOpen) => (
      <TableWithAlignedColsHeaderRow
        isOpen={isOpen}
        bezeichnungHeaderText="Vst.Kürzung-Konto"
        sumHeaderText={['Summe Vst-Buchungsbetrag', 'Summe-Vst.Kürzung']}
        cellToUseForSumHeaderText={[5, 7]}
        onClick={setIsOpen}
        startCell={3}
        endCell={7}
        colSpan={[4, 3]}
      />
    ),
    body: (vstKuerzung) => (
      <>
        {vstKuerzung.vorsteuerkuerzungKontoList.map((konto, index) => (
          <TableWithAlignedColsBody<VorsteuerkuerzungVstKrzKonto>
            key={index}
            dataSource={konto}
            columns={vstKuerzungKontoRowColumns(objektId)}
            expandedRow={() => aufwandsKontoRow(objektId, rechtstraegerId, vstKuerzung.bestandseinheit.bestandseinheitId, vonInklusive, bisInklusive)}
            positionOfButton={2}
          />
        ))}
      </>
    ),
  },
];

export default vstKuerzungKontoRow;
