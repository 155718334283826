import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { BestandseinheitAufteilungsschluesselWertFieldsFragmentDoc } from './BestandseinheitAufteilungsschluesselFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BestandseinheitAufteilungsschluesselListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type BestandseinheitAufteilungsschluesselListQuery = {
  getBeAufteilungsschluesselList: {
    data: Array<{
      anteil: number;
      aufteilungsschluesselId: string;
      basis: number;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      wert?: number | null;
      bezugsbasis: { text: string; value: Types.Bezugsbasis };
      objektAufteilungsschluesselStatus: { description?: string | null; text: string; value: Types.ObjektAufteilungsschluesselStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BestandseinheitAufteilungsschluesselWertAenderungListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  aufteilungsschluesselId: Types.Scalars['ID']['input'];
}>;

export type BestandseinheitAufteilungsschluesselWertAenderungListQuery = {
  getBeAufteilungsschluesselWertAenderungList: {
    data: Array<{
      aktiv: boolean;
      beAufteilungsschluesselWertId?: string | null;
      createTs?: string | null;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      geloescht: boolean;
      validFrom?: string | null;
      wert: number;
      verwaltungSeitRelation: { text: string; value: Types.BeAufteilingsschluesselWertVerwaltungSeitRelation };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BestandseinheitAufteilungsschluesselWertChangeHistoryListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  aufteilungsschluesselId: Types.Scalars['ID']['input'];
  beAufteilungsschluesselWertId: Types.Scalars['ID']['input'];
}>;

export type BestandseinheitAufteilungsschluesselWertChangeHistoryListQuery = {
  getBeAufteilungsschluesselWertChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const BestandseinheitAufteilungsschluesselListDocument = gql`
  query BestandseinheitAufteilungsschluesselList($objektId: ID!, $bestandseinheitId: ID!, $includeArchiviert: Boolean) {
    getBeAufteilungsschluesselList(objektId: $objektId, bestandseinheitId: $bestandseinheitId, includeArchiviert: $includeArchiviert) {
      data {
        anteil
        aufteilungsschluesselId
        basis
        bezeichnung
        bezugsbasis {
          text
          value
        }
        createTs
        createdBy
        createdByMitarbeiterId
        objektAufteilungsschluesselStatus {
          description
          text
          value
        }
        warningList {
          attribute
          message
          type
        }
        wert
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBestandseinheitAufteilungsschluesselListQuery(
  baseOptions: Apollo.QueryHookOptions<BestandseinheitAufteilungsschluesselListQuery, BestandseinheitAufteilungsschluesselListQueryVariables> &
    ({ variables: BestandseinheitAufteilungsschluesselListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BestandseinheitAufteilungsschluesselListQuery, BestandseinheitAufteilungsschluesselListQueryVariables>(
    BestandseinheitAufteilungsschluesselListDocument,
    options
  );
}
export function useBestandseinheitAufteilungsschluesselListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BestandseinheitAufteilungsschluesselListQuery, BestandseinheitAufteilungsschluesselListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BestandseinheitAufteilungsschluesselListQuery, BestandseinheitAufteilungsschluesselListQueryVariables>(
    BestandseinheitAufteilungsschluesselListDocument,
    options
  );
}
export function useBestandseinheitAufteilungsschluesselListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BestandseinheitAufteilungsschluesselListQuery, BestandseinheitAufteilungsschluesselListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BestandseinheitAufteilungsschluesselListQuery, BestandseinheitAufteilungsschluesselListQueryVariables>(
    BestandseinheitAufteilungsschluesselListDocument,
    options
  );
}
export type BestandseinheitAufteilungsschluesselListQueryHookResult = ReturnType<typeof useBestandseinheitAufteilungsschluesselListQuery>;
export type BestandseinheitAufteilungsschluesselListLazyQueryHookResult = ReturnType<typeof useBestandseinheitAufteilungsschluesselListLazyQuery>;
export type BestandseinheitAufteilungsschluesselListSuspenseQueryHookResult = ReturnType<
  typeof useBestandseinheitAufteilungsschluesselListSuspenseQuery
>;
export type BestandseinheitAufteilungsschluesselListQueryResult = Apollo.QueryResult<
  BestandseinheitAufteilungsschluesselListQuery,
  BestandseinheitAufteilungsschluesselListQueryVariables
>;
export const BestandseinheitAufteilungsschluesselWertAenderungListDocument = gql`
  query BestandseinheitAufteilungsschluesselWertAenderungList($objektId: ID!, $bestandseinheitId: ID!, $aufteilungsschluesselId: ID!) {
    getBeAufteilungsschluesselWertAenderungList(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      aufteilungsschluesselId: $aufteilungsschluesselId
    ) {
      data {
        ...BestandseinheitAufteilungsschluesselWertFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BestandseinheitAufteilungsschluesselWertFieldsFragmentDoc}
`;
export function useBestandseinheitAufteilungsschluesselWertAenderungListQuery(
  baseOptions: Apollo.QueryHookOptions<
    BestandseinheitAufteilungsschluesselWertAenderungListQuery,
    BestandseinheitAufteilungsschluesselWertAenderungListQueryVariables
  > &
    ({ variables: BestandseinheitAufteilungsschluesselWertAenderungListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BestandseinheitAufteilungsschluesselWertAenderungListQuery,
    BestandseinheitAufteilungsschluesselWertAenderungListQueryVariables
  >(BestandseinheitAufteilungsschluesselWertAenderungListDocument, options);
}
export function useBestandseinheitAufteilungsschluesselWertAenderungListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BestandseinheitAufteilungsschluesselWertAenderungListQuery,
    BestandseinheitAufteilungsschluesselWertAenderungListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BestandseinheitAufteilungsschluesselWertAenderungListQuery,
    BestandseinheitAufteilungsschluesselWertAenderungListQueryVariables
  >(BestandseinheitAufteilungsschluesselWertAenderungListDocument, options);
}
export function useBestandseinheitAufteilungsschluesselWertAenderungListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BestandseinheitAufteilungsschluesselWertAenderungListQuery,
        BestandseinheitAufteilungsschluesselWertAenderungListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BestandseinheitAufteilungsschluesselWertAenderungListQuery,
    BestandseinheitAufteilungsschluesselWertAenderungListQueryVariables
  >(BestandseinheitAufteilungsschluesselWertAenderungListDocument, options);
}
export type BestandseinheitAufteilungsschluesselWertAenderungListQueryHookResult = ReturnType<
  typeof useBestandseinheitAufteilungsschluesselWertAenderungListQuery
>;
export type BestandseinheitAufteilungsschluesselWertAenderungListLazyQueryHookResult = ReturnType<
  typeof useBestandseinheitAufteilungsschluesselWertAenderungListLazyQuery
>;
export type BestandseinheitAufteilungsschluesselWertAenderungListSuspenseQueryHookResult = ReturnType<
  typeof useBestandseinheitAufteilungsschluesselWertAenderungListSuspenseQuery
>;
export type BestandseinheitAufteilungsschluesselWertAenderungListQueryResult = Apollo.QueryResult<
  BestandseinheitAufteilungsschluesselWertAenderungListQuery,
  BestandseinheitAufteilungsschluesselWertAenderungListQueryVariables
>;
export const BestandseinheitAufteilungsschluesselWertChangeHistoryListDocument = gql`
  query BestandseinheitAufteilungsschluesselWertChangeHistoryList(
    $objektId: ID!
    $bestandseinheitId: ID!
    $aufteilungsschluesselId: ID!
    $beAufteilungsschluesselWertId: ID!
  ) {
    getBeAufteilungsschluesselWertChangeHistoryList(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      aufteilungsschluesselId: $aufteilungsschluesselId
      beAufteilungsschluesselWertId: $beAufteilungsschluesselWertId
    ) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useBestandseinheitAufteilungsschluesselWertChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    BestandseinheitAufteilungsschluesselWertChangeHistoryListQuery,
    BestandseinheitAufteilungsschluesselWertChangeHistoryListQueryVariables
  > &
    ({ variables: BestandseinheitAufteilungsschluesselWertChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BestandseinheitAufteilungsschluesselWertChangeHistoryListQuery,
    BestandseinheitAufteilungsschluesselWertChangeHistoryListQueryVariables
  >(BestandseinheitAufteilungsschluesselWertChangeHistoryListDocument, options);
}
export function useBestandseinheitAufteilungsschluesselWertChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BestandseinheitAufteilungsschluesselWertChangeHistoryListQuery,
    BestandseinheitAufteilungsschluesselWertChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BestandseinheitAufteilungsschluesselWertChangeHistoryListQuery,
    BestandseinheitAufteilungsschluesselWertChangeHistoryListQueryVariables
  >(BestandseinheitAufteilungsschluesselWertChangeHistoryListDocument, options);
}
export function useBestandseinheitAufteilungsschluesselWertChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BestandseinheitAufteilungsschluesselWertChangeHistoryListQuery,
        BestandseinheitAufteilungsschluesselWertChangeHistoryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BestandseinheitAufteilungsschluesselWertChangeHistoryListQuery,
    BestandseinheitAufteilungsschluesselWertChangeHistoryListQueryVariables
  >(BestandseinheitAufteilungsschluesselWertChangeHistoryListDocument, options);
}
export type BestandseinheitAufteilungsschluesselWertChangeHistoryListQueryHookResult = ReturnType<
  typeof useBestandseinheitAufteilungsschluesselWertChangeHistoryListQuery
>;
export type BestandseinheitAufteilungsschluesselWertChangeHistoryListLazyQueryHookResult = ReturnType<
  typeof useBestandseinheitAufteilungsschluesselWertChangeHistoryListLazyQuery
>;
export type BestandseinheitAufteilungsschluesselWertChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useBestandseinheitAufteilungsschluesselWertChangeHistoryListSuspenseQuery
>;
export type BestandseinheitAufteilungsschluesselWertChangeHistoryListQueryResult = Apollo.QueryResult<
  BestandseinheitAufteilungsschluesselWertChangeHistoryListQuery,
  BestandseinheitAufteilungsschluesselWertChangeHistoryListQueryVariables
>;
