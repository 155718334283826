import React from 'react';
import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { EuroAmount } from '../../../../../components/Number';
import { generatePathToMahnungDetailsPage } from '../../../../Mahnung/mahnungUriPaths';
import MultipleTexts from '../../../../../components/Text/MultipleTexts';
import { MahnungAusgebenVersendenVerbuchenGenerierlaufEntryList } from '../../../verarbeitungHelpers';
import GenerierlaufEntryStatus from '../../../shared/GenerierlaufEntryStatus';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { MitarbeiterTooltip } from '../../../../../components/Card';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import { MahnungGenerierlaufOrderBy } from '../../../../../types';
import GenerierlaufEntryStep from '../../../shared/GenerierlaufEntryStep';
import RechtstraegerWithContactsTooltip from '../../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

export const mahnungAusgVersVerbVerarbEntryTableColumns = (
  showStepsColumn: boolean
): TableWithColSelectorColumnProps<MahnungAusgebenVersendenVerbuchenGenerierlaufEntryList>[] => [
  {
    title: 'Status',
    defaultSelected: true,
    dataIndex: MahnungGenerierlaufOrderBy.Status,
    sorter: true,
    render: (_, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },
  ...(showStepsColumn
    ? [
        {
          title: 'Schritte',
          defaultSelected: false,
          render: (_: any, record: MahnungAusgebenVersendenVerbuchenGenerierlaufEntryList) => (
            <GenerierlaufEntryStep currentStep={record.currentStep} stepCount={record.stepCount} stepList={record.stepList} />
          ),
        },
      ]
    : []),
  {
    title: 'Rechnungsaussteller',
    defaultSelected: true,
    dataIndex: MahnungGenerierlaufOrderBy.Rechnungsaussteller,
    sorter: true,
    render: (text, record) => <DataWithShortenedText maxTextLength={16} text={record.mahnung.rechnungsAussteller.kurzBezeichnung} />,
  },
  {
    title: 'Objekt',
    defaultSelected: true,
    width: '90px',
    render(_, record) {
      const texts = record.mahnung?.objektList.map((objekt) => objekt.kurzBezeichnung).filter(Boolean) ?? [];
      return texts.length > 0 ? <MultipleTexts texts={texts as string[]} maxFirstTextLength={10} /> : <TextForEmptyValue textToShow="minus" />;
    },
  },
  {
    title: 'Vertragspartner',
    defaultSelected: true,
    dataIndex: MahnungGenerierlaufOrderBy.Vertragspartner,
    sorter: true,
    render: (text, record) => (
      <RechtstraegerWithContactsTooltip
        rechtstraegerId={record.mahnung.vertragspartner.rechtstraegerId}
        rechtstraegerName={record.mahnung.vertragspartner.kurzBezeichnung}
        status={record.mahnung.vertragspartner.status}
        maxTextLength={25}
      />
    ),
  },
  {
    title: 'Rechnungsbetrag-Summe',
    defaultSelected: true,
    align: 'right',
    dataIndex: MahnungGenerierlaufOrderBy.RechnungsbetragSumme,
    sorter: true,
    render: (text, record) =>
      record.mahnung?.sumRechnungsbetrag ? <EuroAmount value={record.mahnung.sumRechnungsbetrag} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Mahnbetrag-Summe',
    defaultSelected: true,
    align: 'right',
    dataIndex: MahnungGenerierlaufOrderBy.MahnbetragSumme,
    sorter: true,
    render: (text, record) =>
      record.mahnung?.sumMahnbetrag ? <EuroAmount value={record.mahnung.sumMahnbetrag} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Mahnung-Status',
    defaultSelected: true,
    dataIndex: MahnungGenerierlaufOrderBy.MahnungStatus,
    sorter: true,
    render: (text, record) => <Tag>{record.mahnung.status.value}</Tag>,
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.mahnung.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.mahnung.createdByMitarbeiterId} userId={record.mahnung.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) =>
      record.mahnung && record.mahnung.mahnungId ? (
        <Link to={generatePathToMahnungDetailsPage(record.mahnung.mahnungId)} target="_blank">
          Details
        </Link>
      ) : null,
  },
];
