import React from 'react';
import { useParams } from 'react-router-dom';
import { useBestandseinheitQuery } from '../../features/Bestandseinheit/gql/BestandseinheitQueries.types';
import menuListBeVertrag from './menuListBeVertrag';
import VertragCard from '../../features/Vertrag/BeVertrag/Card/VertragCard';
import { useBeVertragQuery } from '../../features/Vertrag/BeVertrag/gql/BeVertragQueries.types';
import { useOnBeVertragDataChangedEvent } from '../../features/Vertrag/BeVertrag/useOnBeVertragDataChangedEvent';
import DetailsPageTemplate from '../../components/Template/DetailsPageTemplate';
import { BeVertragDetailsPageRouteParams } from './routes';
import { useBeVertragDokumentenversandQuery } from '../../features/Vertrag/BeVertrag/Versand/gql/BeVertragDokumentenVersandQueries.types';
import { useObjektQuery } from '../../features/Objekt/gql/ObjektQueries.types';
import { BeVertragFragment } from '../../features/Vertrag/BeVertrag/gql/BeVertragFragments.types';

const BeVertragDetailsPage = () => {
  const { objektId, bestandseinheitId, vertragId } = useParams() as BeVertragDetailsPageRouteParams;

  const { data: bestandseinheitData } = useBestandseinheitQuery({
    variables: {
      objektId,
      bestandseinheitId,
    },
  });
  const bestandseinheit = bestandseinheitData?.getBestandseinheit.data;
  const loading = !bestandseinheitData;

  const { data: vertragData, refetch: refetchBeVertrag } = useBeVertragQuery({
    variables: {
      objektId,
      bestandseinheitId,
      vertragId,
      withDetails: true,
    },
  });
  const vertrag = vertragData?.getBeVertrag.data;
  const loadingVertrag = !vertrag;

  const { data: beEVertragDokumentversand, refetch: refetchBeEVertragDokumentversand } = useBeVertragDokumentenversandQuery({
    variables: {
      objektId,
      bestandseinheitId,
      vertragId,
    },
  });

  const dokumentenversand = beEVertragDokumentversand?.getBeVertragDokumentenversand.data;

  const { data: objRechnungsausstellerData, loading: objRaLoading } = useObjektQuery({ variables: { objektId } });
  const rechnungsausstellerId = objRechnungsausstellerData?.getObjekt.data.rechnungsAussteller.rechtstraegerId;

  useOnBeVertragDataChangedEvent('BeVertrag', refetchBeVertrag);

  return (
    <DetailsPageTemplate<BeVertragFragment>
      data={vertrag}
      isLoading={!vertrag || !bestandseinheit}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      pageTitle={() => `${bestandseinheit!.address.street} ${bestandseinheit!.address.houseEntranceApartmentNumber} / Vertrag`}
      card={(vertrag) => (
        <VertragCard
          vertragId={vertragId}
          bestandseinheitId={bestandseinheitId}
          objektId={objektId}
          vertrag={vertrag}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          bestandseinheit={bestandseinheit!}
          loading={loading || loadingVertrag || objRaLoading}
          refetch={refetchBeVertrag}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          rechnungsausstellerId={rechnungsausstellerId!}
        />
      )}
      sidebarMenuList={(vertrag) =>
        menuListBeVertrag(objektId, bestandseinheitId, vertragId, vertrag, refetchBeVertrag, refetchBeEVertragDokumentversand, dokumentenversand)
      }
    />
  );
};

export default BeVertragDetailsPage;
