import React from 'react';
import { Typography } from 'antd';
import { MitarbeiterTooltip } from '../../../../../../../components/Card';
import BelegnummernkreisDefinitionTableActions from './BelegnummernkreisDefinitionTableActions';
import { TableWithColSelectorColumnProps } from '../../../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { BelegnummernkreisDefinitionFieldsFragment } from '../../../../../../BelegnummernkreisDefinition/gql/BelegnummernkreisDefinitionFragments.types';
import FormattedDateTime from '../../../../../../../components/FormattedDateTime/FormattedDateTime';

const belegnummernkreisDefinitionTableColumns = (
  rechtstraegerId: string,
  onAction: () => void
): TableWithColSelectorColumnProps<BelegnummernkreisDefinitionFieldsFragment>[] => [
  {
    title: 'Bezeichnung',
    defaultSelected: true,
    render: (text, record) => <Typography.Text>{record.bezeichnung}</Typography.Text>,
  },
  {
    title: 'jährlich neu',
    defaultSelected: true,
    render: (text, record) => <Typography.Text>{record.jaehrlichNeu ? 'JA' : 'NEIN'}</Typography.Text>,
  },
  {
    title: 'Prefix',
    defaultSelected: true,
    render: (text, record) => <Typography.Text>{record.prefix?.value}</Typography.Text>,
  },
  {
    title: 'Nummernteil',
    defaultSelected: true,
    render: (text, record) => <Typography.Text>{record.nummernteil}</Typography.Text>,
  },
  {
    title: 'Postfix',
    defaultSelected: true,
    render: (text, record) => <Typography.Text>{record.postfix?.value}</Typography.Text>,
  },
  {
    title: 'Erstellt am',
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => (
      <BelegnummernkreisDefinitionTableActions belegnummernkreisDefinition={record} rechtstraegerId={rechtstraegerId} onAction={onAction} />
    ),
  },
];

export default belegnummernkreisDefinitionTableColumns;
