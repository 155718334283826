import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Empty } from 'antd';
import { FiltersFormValues, mapFormValuesToListQueryVariables } from '../paymentAssignmentFormMapper';
import { usePaymentListQuery } from '../../../../../Payment/gql/Order/PaymentQueries.types';
import { useQueryWithPagingAndSorting } from '../../../../../../hooks/useQueryWithPagingAndSorting';
import NestedTable from '../../../../../../components/Table/NestedTable/NestedTable';
import { PaymentListEntry } from '../../../../../../types';
import paymentAssignmentTableColumns from './paymentAssignmentTableColumns';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
  setPaymentId: (paymentId: string) => void;
};

const PaymentAssignmentTable: FC<Props> = ({ formikProps, setPaymentId }) => {
  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(usePaymentListQuery, {
    variables: {
      ...mapFormValuesToListQueryVariables(formikProps.values),
    },
  });

  const paymentList = data?.getPaymentList.data.contentList ?? [];

  return (
    <NestedTable<PaymentListEntry>
      level={1}
      colWidth={150}
      loading={loading}
      pagination={{ ...pagination, syncWithUri: false }}
      onChange={handleTableSorting}
      locale={{
        emptyText: <Empty description={<span>Keine Zahlungen vorhanden</span>} />,
      }}
      expandable={{
        rowExpandable: () => false,
      }}
      rowSelection={{
        type: 'radio',
        onSelect: (record) => setPaymentId(record.paymentId),
        columnWidth: 25,
      }}
      dataSource={paymentList}
      columns={paymentAssignmentTableColumns}
      rowKey={(record) => record.paymentId}
    />
  );
};

export default PaymentAssignmentTable;
