import { ComponentType } from 'react';
import VorschreibungMailDeliveryDetailsPage from '../../pages/EmailVersandDetailsPage/VorschreibungMailDelivery/VorschreibungMailDeliveryDetailsPage';
import InfoMailDeliveryDetailsPage from '../../pages/EmailVersandDetailsPage/InfoMailDelivery/InfoMailDeliveryDetailsPage';
import { verarbeitungTypes } from '../Verarbeitung/verarbeitungHelpers';
import { LabelData, MailData } from '../../types';

type EmailDeliveryLabel = 'infoMailDeliveryId' | 'generierlaufId' | 'vorschreibungMailDeliveryId';

export type EmailDeliveryType = 'InfoMailDelivery' | 'VorschreibungMailDelivery' | 'Mahnung' | 'Invitation' | 'PasswordReset' | 'EmailChange';

export type EmailDeliveryDetails = {
  type: string;
  name: string;
  emailMailDeliveryLabel?: Exclude<EmailDeliveryLabel, 'generierlaufId'>;
  verarbeitung?: keyof typeof verarbeitungTypes; // if it has verarbeitung
  page?: ComponentType<EmailDeliveryDetailsPageProps>; // custom page component (do not set to use the default)
  skipLink?: true; // do not show link to details page on email delivery listing table
};

export const emailDeliveryTypes: Record<EmailDeliveryType, EmailDeliveryDetails> = {
  InfoMailDelivery: {
    type: 'INFO_MAIL',
    name: 'Informationsschreiben',
    emailMailDeliveryLabel: 'infoMailDeliveryId',
    verarbeitung: 'InfoMailDeliverySend',
    page: InfoMailDeliveryDetailsPage,
  },
  VorschreibungMailDelivery: {
    type: 'VORSCHREIBUNG_MAIL',
    name: 'Vorschreibung',
    emailMailDeliveryLabel: 'vorschreibungMailDeliveryId',
    verarbeitung: 'VorschreibungMailDeliverySend',
    page: VorschreibungMailDeliveryDetailsPage,
  },
  Mahnung: {
    type: 'MAHNUNG',
    name: 'Mahnung',
    verarbeitung: 'MahnungVersenden',
  },
  Invitation: {
    type: 'INVITATION',
    name: 'Einladung',
  },
  PasswordReset: {
    type: 'PASSWORD_RESET',
    name: 'Passwort zurücksetzen',
    skipLink: true,
  },
  EmailChange: {
    type: 'EMAIL_CHANGE',
    name: 'E-Mail-Änderung',
    skipLink: true,
  },
};

export type EmailDeliveryDetailsPageProps = {
  mail: MailData;
  loading: boolean;
  refetch: () => void;
};

export const getEmailDeliveryKeyByType = (type: string): EmailDeliveryType | null => {
  for (const key in emailDeliveryTypes) {
    if (emailDeliveryTypes[key as EmailDeliveryType].type === type) {
      return key as EmailDeliveryType;
    }
  }
  return null;
};

export const getEmailDeliveryDetailsByType = (type: string): EmailDeliveryDetails | null => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return emailDeliveryTypes[getEmailDeliveryKeyByType(type)!];
};

export const getEmailDeliveryKeyByEmailMailDeliveryLabel = (emailDeliveryLabel: string): EmailDeliveryType | null => {
  for (const key in emailDeliveryTypes) {
    if (emailDeliveryTypes[key as EmailDeliveryType].emailMailDeliveryLabel === emailDeliveryLabel) {
      return key as EmailDeliveryType;
    }
  }
  return null;
};

export const findEmailDeliveryLabel = (emailDelivery: MailData, emailDeliveryLabel: EmailDeliveryLabel) => {
  return emailDelivery.labelList.find((label) => label.label === emailDeliveryLabel);
};

export const getEmailDeliveryLabels = (labelList: LabelData[]) => {
  return labelList.map((label) => label.label);
};
