import React, { FC } from 'react';
import { Button } from 'antd';
import { FormikProps } from 'formik';
import { filtersFormFields, FiltersFormValues } from './Filters/listingFiltersFormMapper';
import EmailDeliveryActionButtons from '../../EmailDelivery/Listing/EmailDeliveryActionButtons';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
};

const WizardBtnAndModal: FC<Props> = ({ formikProps }) => {
  return (
    <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
      <Button
        onClick={() => {
          formikProps.setFieldValue(filtersFormFields.showCompleted, !formikProps.values.showCompleted);
          formikProps.submitForm();
        }}
      >
        Verarbeitete Einträge {formikProps.values.showCompleted ? 'ausblenden' : 'anzeigen'}
      </Button>
      <EmailDeliveryActionButtons label="Assistent starten" />
    </ButtonsAligned>
  );
};

export default WizardBtnAndModal;
