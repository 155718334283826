import React, { FC } from 'react';
import { ArchiveIcon } from '@radix-ui/react-icons';
import { Drawer, MenuProps, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { isStatusArchived } from '../../../../../helpers/statusHelper';
import { showSuccessMsgArchived, showSuccessMsgDelete, showSuccessMsgUnarchived } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { radixActionStyles } from '../../../../../helpers/radixIconsStyles';
import {
  useArchiveHeVertragZahlungsregelMutation,
  useDeleteHeVertragZahlungsregelMutation,
  useUnarchiveHeVertragZahlungsregelMutation,
} from '../gql/HeVertragZahlungsregelMutations.types';
import { useToggle } from '../../../../../hooks/useToggle';
import ZahlungsregelForm from '../Form/ZahlungsregelForm';
import { gqlOperations } from '../../../../../graphql/gqlNamedOperations-generated';
import { HeVertragZahlungsregel } from '../../../../../types';
import { HeAbrechnungsdefinitionFieldsFragment } from '../../../../Abrechnungsdefinition/gql/HeAbrDef/HeAbrDefFragments.types';
import HistoryModal, { HistoryType } from '../../../../History/shared/HistoryModal';
import ZahlungsregelChangeHistoryListingTable from './ZahlungsregelChangeHistoryListingTable';
import ActionDropdown from '../../../../../components/Dropdown/ActionDropdown';

type Props = {
  objektId: string;
  heVertragId: string;
  zahlungsregel: HeVertragZahlungsregel;
  onSuccess: () => void;
  heAbrechnungsdefinition?: HeAbrechnungsdefinitionFieldsFragment | null;
};

const ZahlungsregelActions: FC<Props> = ({ objektId, heVertragId, zahlungsregel, onSuccess, heAbrechnungsdefinition }) => {
  const [isDrawerOpen, toggleDrawerOpen] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const onUpdateSuccess = () => {
    onSuccess();
    toggleDrawerOpen();
  };

  const entity = 'Zahlungsregel';

  const isArchived = isStatusArchived(zahlungsregel.status);

  const [runDelete] = useDeleteHeVertragZahlungsregelMutation({
    variables: {
      objektId,
      heVertragId,
      zahlungsregelId: zahlungsregel.zahlungsregelId,
    },
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onSuccess();
    },
    refetchQueries: () => [gqlOperations.Query.Zahlungsverlauf],
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runArchive] = useArchiveHeVertragZahlungsregelMutation({
    variables: {
      objektId,
      heVertragId,
      zahlungsregelId: zahlungsregel.zahlungsregelId,
    },
    onCompleted: () => {
      showSuccessMsgArchived(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUnarchive] = useUnarchiveHeVertragZahlungsregelMutation({
    variables: {
      objektId,
      heVertragId,
      zahlungsregelId: zahlungsregel.zahlungsregelId,
    },
    onCompleted: () => {
      showSuccessMsgUnarchived(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    { key: '1', label: 'Bearbeiten', onClick: toggleDrawerOpen, icon: <EditOutlined /> },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    {
      key: '3',
      label: isArchived ? 'Reaktivieren' : 'Archivieren',
      onClick: isArchived ? showConfirmUnarchive(runUnarchive, zahlungsregel) : showConfirmArchive(runArchive, zahlungsregel),
      icon: <ArchiveIcon style={radixActionStyles} />,
    },
    {
      key: '4',
      label: 'Löschen',
      onClick: showConfirmDelete(runDelete, zahlungsregel),
      disabled: !zahlungsregel.deletable,
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  return (
    <>
      <ActionDropdown items={items} />
      <Drawer
        title={`Akonto Zahlungsregel für ${zahlungsregel.type.text} bearbeiten`}
        width={720}
        open={isDrawerOpen}
        onClose={toggleDrawerOpen}
        destroyOnClose
      >
        <ZahlungsregelForm
          objektId={objektId}
          heVertragId={heVertragId}
          onSuccess={onUpdateSuccess}
          onCancel={toggleDrawerOpen}
          zahlungsregel={zahlungsregel}
          heAbrechnungsdefinition={heAbrechnungsdefinition}
        />
      </Drawer>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <ZahlungsregelChangeHistoryListingTable objektId={objektId} heVertragId={heVertragId} zahlungsregelId={zahlungsregel.zahlungsregelId} />
      </HistoryModal>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void, zahlungsregel: HeVertragZahlungsregel) => () => {
  Modal.confirm({
    title: `Möchten Sie die Zahlungsregel löschen?`,
    content: `${zahlungsregel.bezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

const showConfirmArchive = (runArchive: () => void, zahlungsregel: HeVertragZahlungsregel) => () => {
  Modal.confirm({
    title: `Möchten Sie die Zahlungsregel archivieren?`,
    content: `${zahlungsregel.bezeichnung}`,
    okText: 'Archivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runArchive();
    },
  });
};

const showConfirmUnarchive = (runUnarchive: () => void, zahlungsregel: HeVertragZahlungsregel) => () => {
  Modal.confirm({
    title: `Möchten Sie die Zahlungsregel reaktivieren?`,
    content: `${zahlungsregel.bezeichnung}`,
    okText: 'Reaktivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runUnarchive();
    },
  });
};

export default ZahlungsregelActions;
