import React, { FC } from 'react';
import { Card, Space } from 'antd';
import theme from '../../../theme';
import AllgemeineEinstellungKonto from '../shared/AllgemeineEinstellungKonto';
import { useTimelineOpen } from '../../../components/Timeline/useTimelineOpen';
import LeerstehungDrawer from './LeerstehungDrawer';
import { FirmendatenBuchhaltungEinstellung } from '../../../types';
import HistoryModal, { HistoryType } from '../../History/shared/HistoryModal';
import LeerstehungForderungsKontoChangeHistoryListingTable from './LeerstehungForderungsKontoChangeHistoryListingTable';
import { useToggle } from '../../../hooks/useToggle';
import AllgemeineEinstellungenCardActions from '../shared/AllgemeineEinstellungenCardActions';

type Props = {
  firmendatenBuchhaltungEinstellung: FirmendatenBuchhaltungEinstellung;
  onSuccess: () => void;
};

const LeerstehungCard: FC<Props> = ({ firmendatenBuchhaltungEinstellung, onSuccess }) => {
  const { timelineDrawer, openTimelineUpdateDrawer, closeTimelineDrawer } = useTimelineOpen();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  return (
    <>
      <Card
        style={{ width: '450px', border: '1px solid #d9d9d9' }}
        headStyle={{ backgroundColor: theme.cardBackgroundColors.grey }}
        size="default"
        bordered
        title="Leerstehung"
        extra={<AllgemeineEinstellungenCardActions onClickEdit={openTimelineUpdateDrawer} onClickChangeHistoryOpen={toggleChangeHistoryOpen} />}
      >
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <AllgemeineEinstellungKonto kontoId={firmendatenBuchhaltungEinstellung?.leerstehungForderungskontoId} text="Forderung-Konto" />
          <AllgemeineEinstellungKonto kontoId={firmendatenBuchhaltungEinstellung?.leerstehungAufwandskontoId} text="Aufwand-Konto" />
        </Space>
      </Card>
      <LeerstehungDrawer
        timelineDrawer={timelineDrawer}
        closeTimelineDrawer={closeTimelineDrawer}
        leerstehungForderungskontoId={firmendatenBuchhaltungEinstellung?.leerstehungForderungskontoId}
        onSuccess={onSuccess}
      />
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <LeerstehungForderungsKontoChangeHistoryListingTable />
      </HistoryModal>
    </>
  );
};

export default LeerstehungCard;
