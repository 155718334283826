import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { TZinslisteQueryParams } from './filtersQueryParams';
import { ZinslisteStatus } from '../../../../types';
import { ObjectOfStrings } from '../../../../shared/typeHelpers';

export type ZinslisteListingFiltersFormValues = {
  vorschreibungsperiodeFrom?: string | undefined;
  vorschreibungsperiodeTo?: string | undefined;
  objektId?: string | null;
  erstellerList?: string[] | null;
  erstellDatumBisInklusive?: string | null;
  erstellDatumVonInklusive?: string | null;
  bruttoFrom?: number | null;
  bruttoTo?: number | null;
  nettoFrom?: number | null;
  nettoTo?: number | null;
  status?: ZinslisteStatus;
};

export const mapQueryParamsToFormValues = (filters: TZinslisteQueryParams): ZinslisteListingFiltersFormValues => ({
  ...filters,
});

export const zinslisteListingFiltersFormFields: FormFields<ZinslisteListingFiltersFormValues> = {
  vorschreibungsperiodeFrom: 'vorschreibungsperiodeFrom',
  vorschreibungsperiodeTo: 'vorschreibungsperiodeTo',
  objektId: 'objektId',
  erstellerList: 'erstellerList',
  erstellDatumBisInklusive: 'erstellDatumBisInklusive',
  erstellDatumVonInklusive: 'erstellDatumVonInklusive',
  bruttoFrom: 'bruttoFrom',
  bruttoTo: 'bruttoTo',
  nettoFrom: 'nettoFrom',
  nettoTo: 'nettoTo',
  status: 'status',
};

export const mapFormValuesToQueryParams = (formValues: ZinslisteListingFiltersFormValues): TZinslisteQueryParams => ({
  vorschreibungsperiodeFrom: formValues.vorschreibungsperiodeFrom
    ? mapFormDateValueToDateString(formValues.vorschreibungsperiodeFrom, 'YYYY-M')
    : undefined,
  vorschreibungsperiodeTo: formValues.vorschreibungsperiodeTo
    ? mapFormDateValueToDateString(formValues.vorschreibungsperiodeTo, 'YYYY-M')
    : undefined,
  objektId: formValues.objektId,
  erstellerList: formValues.erstellerList,
  erstellDatumBisInklusive: formValues.erstellDatumBisInklusive ? mapFormDateValueToDateString(formValues.erstellDatumBisInklusive) : undefined,
  erstellDatumVonInklusive: formValues.erstellDatumVonInklusive ? mapFormDateValueToDateString(formValues.erstellDatumVonInklusive) : undefined,
  bruttoFrom: formValues.bruttoFrom,
  bruttoTo: formValues.bruttoTo,
  nettoFrom: formValues.nettoFrom,
  nettoTo: formValues.nettoTo,
  status: formValues.status,
});

export const zinslisteListingLabelList: ObjectOfStrings<
  Omit<ZinslisteListingFiltersFormValues, 'vorschreibungsperiodeTo' | 'erstellDatumBisInklusive' | 'nettoTo' | 'bruttoTo'>
> = {
  [zinslisteListingFiltersFormFields.vorschreibungsperiodeFrom]: 'Vorschreibungsperiode',
  [zinslisteListingFiltersFormFields.erstellerList]: 'Ersteller',
  [zinslisteListingFiltersFormFields.erstellDatumVonInklusive]: 'Erstelldatum',
  [zinslisteListingFiltersFormFields.objektId]: 'Objekt',
  [zinslisteListingFiltersFormFields.bruttoFrom]: 'Brutto',
  [zinslisteListingFiltersFormFields.nettoFrom]: 'Netto',
  [zinslisteListingFiltersFormFields.status]: 'Status',
};
