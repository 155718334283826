import React from 'react';
import { Row } from 'antd';
import PDFPageCol from './PDFPageCol';
import MahnungTemplate from './templates/mahnung/MahnungTemplate';
import { mockMahnungData } from './mocks/mahnungMocks';

const PDFPageMahnung = () => (
  <Row>
    <PDFPageCol pdfTemplate={<MahnungTemplate data={mockMahnungData} />} />
  </Row>
);

export default PDFPageMahnung;
