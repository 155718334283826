import { ExpandableConfig } from 'rc-table/lib/interface';
import { QUERY_PARAM_KEY, useQueryParams } from '../../../hooks/useStringQueryParams';
import { useTableExpandedRowKeys } from './useTableExpandedRowKeys';
import { NestedTableProps } from '../NestedTable/NestedTable';

export const useQueryTableExpandedRowKeys = <T>(
  expandedRowKey: string | undefined,
  expandable: NestedTableProps<T>['expandable'],
  rowKey: NestedTableProps<T>['rowKey']
) => {
  const { expandedRowKeys: expandedRowKeysInQuery } = useQueryParams<{ [QUERY_PARAM_KEY.EXPANDED_ROW_KEYS]: string }>();
  const expandedKeys: Record<string, string[]> = {};
  if (expandedRowKey && expandedRowKeysInQuery) {
    try {
      const parsedKeys = JSON.parse(expandedRowKeysInQuery);
      Object.keys(parsedKeys).forEach((key) => {
        expandedKeys[key] = Array.isArray(parsedKeys[key]) ? parsedKeys[key] : [parsedKeys[key]];
      });
    } catch (e) {
      /**/
    }
  }

  const defaultRowKeys = expandedRowKey && expandedRowKey in expandedKeys ? expandedKeys[expandedRowKey] : undefined;
  // Avoid overriding the original "expandedRowKeys" prop
  const keys = [...(expandable?.expandedRowKeys ?? []), ...(defaultRowKeys ?? [])].map((key) => String(key));
  const { expandedRowKeys, toggleExpandedRowKey } = useTableExpandedRowKeys(keys);
  return getExpandableProps(expandable, rowKey, expandedRowKeys, toggleExpandedRowKey);
};

// Build the expandable props object by extending it with the expand functionality.
// This allows all the props to be used in the implementation tables without conflicts.
function getExpandableProps<T>(
  expandable: NestedTableProps<T>['expandable'],
  rowKey: NestedTableProps<T>['rowKey'],
  expandedRowKeys: string[],
  toggleExpandedRowKey: (rowKey: string) => void
): ExpandableConfig<T> {
  return {
    ...expandable,
    onExpand: (expanded, record) => {
      // Avoid overriding the original "onExpand" prop
      expandable?.onExpand?.(expanded, record);
      const key = typeof rowKey === 'function' ? rowKey(record) : rowKey;
      if (key) toggleExpandedRowKey(String(key));
    },
    expandedRowKeys: [...expandedRowKeys, ...(expandable?.expandedRowKeys ?? [])],
  };
}
