import {
  AbrechnungsdefinitionKontoAbrechnungskreisZuordnungInput,
  AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListInput,
  AbrechnungskreisType,
  Konto,
  KontoAbrechnungskreis,
  KontoKlasse,
  KontoVerwendung,
} from '../../../types';
import { DEFAULT_AUFTEILUNGSSCHLUESSEL } from '../shared/AbrKontenzuordnungAufteilungsschluesselSelect';

export enum ZuordnungKontoVerwendung {
  NOT_UST_VOM_AUFWAND = 'notUstVomAufwand',
  UST_VOM_AUFWAND = 'UstVomAufwand',
}

export type ZuordnungKontoListingFormValues = {
  zuordnungListForErloesKontoList: ZuordnungFormValues[];
  zuordnungListForAufwandKontoList: ZuordnungFormValues[];
  zuordnungListForVerrechnungList: ZuordnungFormValues[];
};

export type ZuordnungFormValues = {
  abrechnungskreis: {
    bezeichnung: string;
    abrechnungskreisId?: string;
    abrechnungskreisType?: AbrechnungskreisType;
  };
  abrechnungsdefinitionId: string;
  abrechnungsdefinitionVersionId: string;
  aufteilungsschluessel?: {
    aufteilungsschluesselId: string;
    kurzBezeichnung: string;
  };
  expenseReducing: boolean;
  kontoId: string;
  abrechnungsdefinitionKontoKlasse?: KontoKlasse;
  abrechnungsdefinitionKontoVerwendung?: KontoVerwendung;
  buchungszeilenAnzeigen: boolean;
  ustVomAufwand: ZuordnungKontoVerwendung;
};

export const AbrechnungskreisNichtAbrechnen = {
  id: 'NICHT_ABRECHNEN',
  text: 'Nicht abrechnen',
};

export const mapZuordnungListToFormValues = (
  abrechnungsdefinitionId: string,
  abrechnungsdefinitionVersionId: string,
  erloesKontoList: Konto[],
  aufwandKontoList: Konto[],
  verrechnungList: Konto[],
  zuordnungList: KontoAbrechnungskreis[]
): ZuordnungKontoListingFormValues => ({
  zuordnungListForErloesKontoList: mapZuordnungToFormValues(abrechnungsdefinitionId, abrechnungsdefinitionVersionId, erloesKontoList, zuordnungList),
  zuordnungListForAufwandKontoList: mapZuordnungToFormValues(
    abrechnungsdefinitionId,
    abrechnungsdefinitionVersionId,
    aufwandKontoList,
    zuordnungList
  ),
  zuordnungListForVerrechnungList: mapZuordnungToFormValues(abrechnungsdefinitionId, abrechnungsdefinitionVersionId, verrechnungList, zuordnungList),
});

export const mapZuordnungToFormValues = (
  abrechnungsdefinitionId: string,
  abrechnungsdefinitionVersionId: string,
  kontoList: Konto[],
  zuordnungList: KontoAbrechnungskreis[]
): ZuordnungFormValues[] =>
  kontoList.map((konto) => {
    const zuordnung = zuordnungList.find((zuordnung) => zuordnung.konto.kontoId === konto.kontoId);
    // keine Zuordnung
    if (!zuordnung) {
      return {
        abrechnungskreis: {
          bezeichnung: 'Keine Zuordnung',
          abrechnungskreisId: '',
          abrechnungskreisType: undefined,
        },
        abrechnungsdefinitionId,
        abrechnungsdefinitionVersionId,
        expenseReducing: false,
        kontoId: konto.kontoId,
        abrechnungsdefinitionKontoKlasse: konto.klasse.value,
        abrechnungsdefinitionKontoVerwendung: konto.verwendung?.value,
        ustVomAufwand: ZuordnungKontoVerwendung.NOT_UST_VOM_AUFWAND,
        buchungszeilenAnzeigen: false,
      };
    }
    // Nicht abrechnen
    if (!zuordnung.abrechnen) {
      return {
        abrechnungskreis: {
          bezeichnung: 'Nicht abrechnen',
          abrechnungskreisId: AbrechnungskreisNichtAbrechnen.id,
          abrechnungskreisType: undefined,
        },
        abrechnungsdefinitionId,
        abrechnungsdefinitionVersionId,
        expenseReducing: false,
        kontoId: konto.kontoId,
        ustVomAufwand: ZuordnungKontoVerwendung.NOT_UST_VOM_AUFWAND,
        buchungszeilenAnzeigen: false,
      };
    }
    // real Zuordnung
    return {
      abrechnungskreis: {
        bezeichnung: zuordnung.abrechnungskreis?.bezeichnung ?? '',
        abrechnungskreisId: zuordnung.abrechnungskreis?.abrechnungskreisId,
        abrechnungskreisType: zuordnung?.abrechnungskreis?.type.value,
      },
      abrechnungsdefinitionId,
      abrechnungsdefinitionVersionId,
      kontoId: konto.kontoId,
      expenseReducing: zuordnung.expenseReducing,
      abrechnungsdefinitionKontoKlasse: konto.klasse.value,
      abrechnungsdefinitionKontoVerwendung: konto.verwendung?.value,
      ustVomAufwand: zuordnung.ustVomAufwand ? ZuordnungKontoVerwendung.UST_VOM_AUFWAND : ZuordnungKontoVerwendung.NOT_UST_VOM_AUFWAND,
      buchungszeilenAnzeigen: zuordnung.buchungszeilenAnzeigen,
      ...(zuordnung.aufteilungsschluessel
        ? {
            aufteilungsschluessel: {
              aufteilungsschluesselId: zuordnung.aufteilungsschluessel.aufteilungsschluesselId,
              kurzBezeichnung: zuordnung.aufteilungsschluessel.kurzBezeichnung,
            },
          }
        : null),
    };
  });

export const mapFormValuesToZuordnungList = (
  formValues: ZuordnungKontoListingFormValues
): AbrechnungsdefinitionKontoAbrechnungskreisZuordnungListInput => {
  const onlyChangedZuordnungList: AbrechnungsdefinitionKontoAbrechnungskreisZuordnungInput[] = [
    ...formValues.zuordnungListForErloesKontoList,
    ...formValues.zuordnungListForAufwandKontoList,
    ...formValues.zuordnungListForVerrechnungList,
  ]
    .filter((zuordnungFormValues) => zuordnungFormValues.abrechnungskreis?.abrechnungskreisId)
    .map((zuordnungFormValues) => ({
      abrechnungsdefinitionId: zuordnungFormValues.abrechnungsdefinitionId,
      abrechnungsdefinitionVersionId: zuordnungFormValues.abrechnungsdefinitionVersionId,
      abrechnungskreisId: zuordnungFormValues.abrechnungskreis?.abrechnungskreisId,
      expenseReducing: zuordnungFormValues.expenseReducing,
      kontoId: zuordnungFormValues.kontoId,
      ustVomAufwand: zuordnungFormValues.ustVomAufwand === ZuordnungKontoVerwendung.UST_VOM_AUFWAND,
      buchungszeilenAnzeigen: zuordnungFormValues.buchungszeilenAnzeigen,
      aufteilungsschluesselId:
        DEFAULT_AUFTEILUNGSSCHLUESSEL === zuordnungFormValues.aufteilungsschluessel?.aufteilungsschluesselId
          ? null
          : zuordnungFormValues.aufteilungsschluessel?.aufteilungsschluesselId,
    }));
  return {
    zuordnungList: onlyChangedZuordnungList,
  };
};
