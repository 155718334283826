import { ObjektAbrechnung } from '../../../../../pages/PDFTemplates/templates/objektAbrechnung/objektAbrechnung-types';
import { BelegTexte, BuchungsdetailsType, ConsolidateEinnahmenType, TextbausteinType } from '../../../../../types';
import { ObjektAbrechnungSelectableSection } from '../../../../../pages/PDFTemplates/templates/objektAbrechnung/ObjektAbrechnungTemplate';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import { findTextbausteinByType, replacePlatzhalterValuesWithExampleValues } from '../../../../../components/Template/PDFTemplates/templateHelpers';
import { BelegTextePlatzhalter } from '../../../../Belegtext/Form/belegTexteFormTypes';
import { ObjAbrDefGeneralSettingsFormValues } from '../shared/BkOrWe/ObjAbrDefGeneralSettingsForm/objAbrDefGeneralSettingsFormMapper';

import {
  getDocumentFooterWithoutTextbaustein,
  getDocumentHeaderWithoutTextbaustein,
} from '../../../../shared/Belegtext/Template/belegTexteWithoutTextbausteinTemplateHelper';

export const createObjektAbrechnungTemplatePDFData = (
  textbausteinList: TextbausteinFormValues[],
  objAbrDefGeneralSettingsValues: ObjAbrDefGeneralSettingsFormValues,
  belegTexte?: BelegTexte | undefined,
  belegTextePlatzhalter?: BelegTextePlatzhalter | undefined,
  firmendatenId?: string | undefined
): ObjektAbrechnung => {
  const abrechnungsTitel = findTextbausteinByType(textbausteinList, TextbausteinType.Abrechnungstitel);

  return {
    ...objektAbrechnungExample(
      objAbrDefGeneralSettingsValues.displayEinnahmen,
      objAbrDefGeneralSettingsValues.buchungsdetails,
      objAbrDefGeneralSettingsValues.displayKontonummer,
      objAbrDefGeneralSettingsValues.consolidateEinnahmen
    ),
    abrechnungsTitel: replacePlatzhalterValuesWithExampleValues(abrechnungsTitel),
    header:
      firmendatenId && belegTexte && belegTextePlatzhalter
        ? getDocumentHeaderWithoutTextbaustein(firmendatenId, belegTexte, belegTextePlatzhalter)
        : null,
    footer:
      firmendatenId && belegTexte && belegTextePlatzhalter
        ? getDocumentFooterWithoutTextbaustein(firmendatenId, belegTexte, belegTextePlatzhalter)
        : null,
  };
};

export const mapTextbausteinToObjektAbrechnungSelectableSection = (type?: TextbausteinType): ObjektAbrechnungSelectableSection | undefined => {
  if (!type) {
    return undefined;
  }
  switch (type) {
    case TextbausteinType.Abrechnungstitel:
      return 'ABRECHNUNGSTITEL';
    default:
      return undefined;
  }
};

const objektAbrechnungExample = (
  displayEinnahmen: boolean,
  buchungsdetails: BuchungsdetailsType,
  displayKontonummer: boolean,
  consolidateEinnahmen?: ConsolidateEinnahmenType | null
): ObjektAbrechnung => {
  let buchungEinnahmenList1 =
    !consolidateEinnahmen || consolidateEinnahmen === ConsolidateEinnahmenType.MonthlyTotal
      ? [
          {
            bezeichnung: 'Betriebskosten VS 1/2019',
            betragNetto: '1000,00',
          },
          {
            bezeichnung: 'Betriebskosten VS 2/2019',
            betragNetto: '1000,00',
          },
          {
            bezeichnung: 'Betriebskosten VS 3/2019',
            betragNetto: '1000,00',
          },
          {
            bezeichnung: 'Betriebskosten VS 4/2019',
            betragNetto: '1000,00',
          },
          {
            bezeichnung: 'Betriebskosten VS 5/2019',
            betragNetto: '1000,00',
          },
          {
            bezeichnung: 'Betriebskosten VS 6/2019',
            betragNetto: '1000,00',
          },
          {
            bezeichnung: 'Betriebskosten VS 7/2019',
            betragNetto: '1000,00',
          },
          {
            bezeichnung: 'Betriebskosten VS 8/2019',
            betragNetto: '1000,00',
          },
          {
            bezeichnung: 'Betriebskosten VS 9/2019',
            betragNetto: '1000,00',
          },
          {
            bezeichnung: 'Betriebskosten VS 10/2019',
            betragNetto: '1000,00',
          },
          {
            bezeichnung: 'Betriebskosten VS 11/2019',
            betragNetto: '1000,00',
          },
          {
            bezeichnung: 'Betriebskosten VS 12/2019',
            betragNetto: '1000,00',
          },
        ]
      : [
          {
            bezeichnung: 'Betriebskosten 2019',
            betragNetto: '12.000,00',
          },
        ];

  let buchungEinnahmenList2 =
    !consolidateEinnahmen || consolidateEinnahmen === ConsolidateEinnahmenType.MonthlyTotal
      ? [
          {
            bezeichnung: '2023/11',
            betragNetto: '100,00',
          },
          {
            bezeichnung: '2023/12',
            betragNetto: '100,00',
          },
        ]
      : [
          {
            bezeichnung: 'Betriebskosten 2023',
            betragNetto: '200,00',
          },
        ];

  if (!displayEinnahmen) {
    buchungEinnahmenList1 = [];
    buchungEinnahmenList2 = [];
  }

  return {
    type: 'OBJEKT_ABRECHNUNG',
    abrechnungsTitel: 'Abrechnung von Betriebskosten, besonderen Aufwendungen und sonstigen Kosten der Bewirtschaftung',
    kopf: {
      addressListText: 'für die Abrechnungseinheit:',
      addressList: [
        {
          countryCodeIso2: 'AT',
          city: 'Wien',
          street: 'Mustergasse',
          houseEntranceApartmentNumber: '11',
          zipCode: '1100',
        },
      ],
      zeitraumText: 'für den Zeitraum:',
      zeitraumVon: '01.01.2019',
      zeitraumBis: '31.12.2019',
      erstellungsDatum: '01.07.2020',
    },
    abrechnungskreiseBlock: {
      abrechnungskreisList: [
        {
          type: 'BEWIRTSCHAFTUNGSKOSTEN',
          bezeichnung: 'Abrechnungskreis 1',
          aufteilungsschluessel: {
            aufteilungsschluesselText: 'Aufteilungsschlüssel:',
            aufteilungsschluessel: 'WE: Nutzfläche, MI: Nutzfläche',
          },
          kontoList: [
            {
              bezeichnung: 'Bsp. Wasser-/Abwassergebühr',
              kontonummer: displayKontonummer ? 'S1000' : null,
              buchungAusgabenList:
                buchungsdetails === BuchungsdetailsType.DisplayBuchungszeilen
                  ? [
                      {
                        bezeichnung: 'Wasser-/Abwassergebühr 01-03/2019',
                        buchungsDatum: '28.01.2019',
                        belegnummer: 'ER136/2019',
                        kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                        betragNetto: '2.156,49',
                      },
                      {
                        bezeichnung: 'Wasser-/Abwassergebühr 04-06/2019',
                        buchungsDatum: '29.04.2019',
                        belegnummer: 'ER611/2019',
                        kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                        betragNetto: '2.156,49',
                      },
                      {
                        bezeichnung: 'Wasser-/Abwassergebühr 07-09/2019',
                        buchungsDatum: '21.07.2019',
                        belegnummer: 'ER1267/2019',
                        kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                        betragNetto: '2.156,49',
                      },
                      {
                        bezeichnung: 'Wasser-/Abwassergebühr 10-12/2019',
                        buchungsDatum: '14.10.2019',
                        belegnummer: 'ER1389/2019',
                        kurzbezeichnungVertragsparthner: 'Stadtgemeinde Deutsch-Wagram',
                        betragNetto: '4.226,42',
                      },
                    ]
                  : [],
              betragNettoSummeBuchungenText: 'Zwischensumme',
              betragNettoSummeBuchungen: '10.695,89',
            },
          ],
          summeNettoAusgabenText: 'Summe Ausgaben',
          summeNettoAusgaben: '10.695,89',
          buchungEinnahmenListText: 'Einnahmen:',
          buchungEinnahmenList: buchungEinnahmenList1,
          summeNettoEinnahmenText: 'Summe Einnahmen',
          summeNettoEinnahmen: '12.000,00',
          ergebnisNettoText: 'Ergebnis: Saldo',
          ergebnisNetto: '1304,11',
          summeNettoExpenseReducingText: 'Zwischensumme Aufwandsmindernd',
          summeNettoExpenseReducing: '60,80',
          expenseReducingKontoList: [
            {
              bezeichnung: 'Wegerecht Huber',
              kontonummer: null,
              aufteilungsschluessel: null,
              betragNettoSummeBuchungenText: null,
              betragNettoSummeBuchungen: '60,80',
              buchungAusgabenList: [],
            },
          ],
        },
      ],
      abrechnungskreisTableBezeichnungText: 'Bezeichnung / Text',
      abrechnungskreisTableBelegDatumText: 'Datum',
      abrechnungskreisTableBelegNummerText: 'Beleg',
      abrechnungskreisTableVertragspartnerText: 'Vertragspartner',
    },
    informationAbrechnungskreiseBlock: {
      abrechnungskreisTableBezeichnungText: null,
      abrechnungskreisTableBelegDatumText: null,
      abrechnungskreisTableBelegNummerText: null,
      abrechnungskreisTableVertragspartnerText: null,
      abrechnungskreisList: [
        {
          type: 'INFORMATION',
          bezeichnung: 'Information',
          aufteilungsschluessel: null,
          summeNettoAusgabenText: 'Summe Ausgaben',
          summeNettoAusgaben: '64,99',
          summeNettoEinnahmenText: 'Summe Einnahmen',
          summeNettoEinnahmen: '95,62',
          ergebnisNettoText: 'Ergebnis: Saldo',
          ergebnisNetto: '-30,63',
          buchungEinnahmenListText: 'Einnahmen',
          saldoVortragText: 'Vorsaldo:',
          saldoVortrag: '0,00',
          ausgabenDetailsTitleText: null,
          kontoList: [
            {
              bezeichnung: 'Waschmaschine',
              kontonummer: displayKontonummer ? 'S1000' : null,
              aufteilungsschluessel: null,
              betragNettoSummeBuchungenText: 'Zwischensumme',
              betragNettoSummeBuchungen: '64,99',
              buchungAusgabenList:
                buchungsdetails === BuchungsdetailsType.DisplayBuchungszeilen
                  ? [
                      {
                        bezeichnung: 'Vorschreibung 2023/12',
                        buchungsDatum: '13.12.2023',
                        belegnummer: '1',
                        kurzbezeichnungVertragsparthner: 'Kraker L. u.Heiner G.',
                        betragNetto: '64,99',
                      },
                    ]
                  : [],
            },
          ],
          buchungEinnahmenList: buchungEinnahmenList2,
          buchungUstVomAufwandList: [],
          summeNettoExpenseReducingText: 'Zwischensumme Aufwandsmindernd',
          summeNettoExpenseReducing: '2350,00',
        },
      ],
    },
  };
};
