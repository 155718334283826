import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { TIncomingInvoiceQueryParams } from './filtersQueryParams';
import { filtersFormFields, FiltersFormValues, listingLabelList } from './filtersFormMapper';
import useFilterWithQueryParams from '../../../shared/useFilterWithQueryParams';
import FiltersWith4OrMoreFields from '../../../../components/Filters/FiltersWith4OrMoreFields';
import FiltersSelect from '../../../../components/Filters/FiltersSelect';
import { onFilterSelectChange } from '../../../../components/Filters/FiltersHelpers';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import InvoiceAusstellerListFormSelect from './InvoiceAusstellerListFormSelect';
import KontoFormSelect from './KontoFormSelect';
import InvoicePayeeListFormSelect from './InvoicePayeeListFormSelect';
import ObjektSelect from '../../../ObjektSelect/ObjektSelect';
import FromToFormDatePicker from '../../../shared/FromToForm/FromToFormDatePicker';
import FromToFormInputNumber from '../../../shared/FromToForm/FromToFormInputNumber';
import AusstellerBankDetailsListFormSelect from './AusstellerBankDetailsListFormSelect';
import PayeeBankDetailsListFormSelect from './PayeeBankDetailsListFormSelect';
import IncomingInvoiceFilterStatusListFormSelect from './IncomingInvoiceFilterStatusListFormSelect';
import IncomingInvoiceListingActionButtons from '../IncomingInvoiceListingActionButtons';
import IncomingInvoiceTableBulkActions from '../IncomingInvoiceTableBulkActions';
import KundennummerListFormSelect from './KundennummerListFormSelect';
import PaymentReferenceTextListFormSelect from './PaymentReferenceTextListFormSelect';
import PurposeOfUseTextListFormSelect from './PurposeOfUseTextListFormSelect';
import SepaMandateReferenceListFormSelect from './SepaMandateReferenceListFormSelect';
import InvoiceNumberListFormSelect from './InvoiceNumberListFormSelect';
import MitarbeiterSelect from '../../../../shared/components/MitarbeiterSelect/MitarbeiterSelect';
import VerrechnungsartListFormSelect from './VerrechnungsartListFormSelect';
import PaymentMethodListFormSelect from '../../../PaymentMethodSelect/PaymentMethodListFormSelect';

type Props = {
  queryParams: TIncomingInvoiceQueryParams;
  formikProps: FormikProps<FiltersFormValues>;
  onAction: () => void;
};

const ListingFilters: FC<Props> = ({ queryParams, formikProps, onAction }) => {
  const defaultSelectedFilterList = [
    filtersFormFields.issuerRechtstraegerId,
    filtersFormFields.payeeRechtstraegerId,
    filtersFormFields.objektId,
    filtersFormFields.invoiceNumber,
    filtersFormFields.invoiceDateFrom,
    filtersFormFields.dueDateFrom,
  ];

  const mapFilterLabelToQueryFilterList = (filter: string) => {
    if (filter === filtersFormFields.invoiceDateFrom || filter === filtersFormFields.invoiceDateTo) {
      return filtersFormFields.invoiceDateFrom;
    }
    if (filter === filtersFormFields.dueDateFrom || filter === filtersFormFields.dueDateTo) {
      return filtersFormFields.dueDateFrom;
    }
    if (filter === filtersFormFields.bruttoFrom || filter === filtersFormFields.bruttoTo) {
      return filtersFormFields.bruttoFrom;
    }
    if (filter === filtersFormFields.nettoFrom || filter === filtersFormFields.nettoTo) {
      return filtersFormFields.nettoFrom;
    }
    if (filter === filtersFormFields.vatFrom || filter === filtersFormFields.vatTo) {
      return filtersFormFields.vatFrom;
    }
    if (filter === filtersFormFields.buchungsdatumFrom || filter === filtersFormFields.buchungsdatumTo) {
      return filtersFormFields.buchungsdatumFrom;
    }
    if (filter === filtersFormFields.createTsFrom || filter === filtersFormFields.createTsTo) {
      return filtersFormFields.createTsFrom;
    }
    return filter;
  };

  const updateFilterValues = (selectedFilterList: string[]) => {
    if (!selectedFilterList.includes(filtersFormFields.invoiceDateFrom) && formikProps.values.invoiceDateTo) {
      formikProps.setFieldValue(filtersFormFields.invoiceDateTo, undefined);
    }
    if (!selectedFilterList.includes(filtersFormFields.dueDateFrom) && formikProps.values.dueDateTo) {
      formikProps.setFieldValue(filtersFormFields.dueDateTo, undefined);
    }
    if (!selectedFilterList.includes(filtersFormFields.bruttoFrom) && formikProps.values.bruttoTo) {
      formikProps.setFieldValue(filtersFormFields.bruttoTo, undefined);
    }
    if (!selectedFilterList.includes(filtersFormFields.nettoFrom) && formikProps.values.nettoTo) {
      formikProps.setFieldValue(filtersFormFields.nettoTo, undefined);
    }
    if (!selectedFilterList.includes(filtersFormFields.vatFrom) && formikProps.values.vatTo) {
      formikProps.setFieldValue(filtersFormFields.vatTo, undefined);
    }
    if (!selectedFilterList.includes(filtersFormFields.buchungsdatumFrom) && formikProps.values.buchungsdatumTo) {
      formikProps.setFieldValue(filtersFormFields.buchungsdatumTo, undefined);
    }
    if (!selectedFilterList.includes(filtersFormFields.createTsFrom) && formikProps.values.createTsTo) {
      formikProps.setFieldValue(filtersFormFields.createTsTo, undefined);
    }
    formikProps.submitForm();
  };
  // BE - Kundennummer, purposeOfUseText, paymentReferenceText, sepaMandatReference

  const filters = (filter: string) => {
    if (filter === filtersFormFields.issuerRechtstraegerId) {
      return (
        <FormItemWithoutColon name={filtersFormFields.issuerRechtstraegerId} label={listingLabelList.issuerRechtstraegerId} key={filter}>
          <InvoiceAusstellerListFormSelect name={filtersFormFields.issuerRechtstraegerId} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.payeeRechtstraegerId) {
      return (
        <FormItemWithoutColon name={filtersFormFields.payeeRechtstraegerId} label={listingLabelList.payeeRechtstraegerId} key={filter}>
          <InvoicePayeeListFormSelect name={filtersFormFields.payeeRechtstraegerId} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.objektId) {
      return (
        <FormItemWithoutColon name={filtersFormFields.objektId} label={listingLabelList.objektId} key={filter}>
          <ObjektSelect name={filtersFormFields.objektId} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.invoiceNumber) {
      return (
        <FormItemWithoutColon name={filtersFormFields.invoiceNumber} label={listingLabelList.invoiceNumber} key={filter}>
          <InvoiceNumberListFormSelect
            name={filtersFormFields.invoiceNumber}
            invoiceNumber={formikProps.values.invoiceNumber}
            onChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.invoiceDateFrom) {
      return (
        <FormItemWithoutColon name="rechnungsdatum" label={listingLabelList.invoiceDateFrom} key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={filtersFormFields.invoiceDateFrom}
            toName={filtersFormFields.invoiceDateTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.dueDateFrom) {
      return (
        <FormItemWithoutColon name="faelligAm" label={listingLabelList.dueDateFrom} key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={filtersFormFields.dueDateFrom}
            toName={filtersFormFields.dueDateTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.issuerBankDetailsIban) {
      return (
        <FormItemWithoutColon name={filtersFormFields.issuerBankDetailsIban} label={listingLabelList.issuerBankDetailsIban} key={filter}>
          <AusstellerBankDetailsListFormSelect name={filtersFormFields.issuerBankDetailsIban} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.payeeBankDetailsIban) {
      return (
        <FormItemWithoutColon name={filtersFormFields.payeeBankDetailsIban} label={listingLabelList.payeeBankDetailsIban} key={filter}>
          <PayeeBankDetailsListFormSelect name={filtersFormFields.payeeBankDetailsIban} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.bruttoFrom) {
      return (
        <FromToFormInputNumber
          name="Brutto"
          fromName={filtersFormFields.bruttoFrom}
          toName={filtersFormFields.bruttoTo}
          onChange={formikProps.submitForm}
        />
      );
    }
    if (filter === filtersFormFields.nettoFrom) {
      return (
        <FromToFormInputNumber
          name="Netto"
          fromName={filtersFormFields.nettoFrom}
          toName={filtersFormFields.nettoTo}
          onChange={formikProps.submitForm}
        />
      );
    }
    if (filter === filtersFormFields.vatFrom) {
      return (
        <FromToFormInputNumber name="Ust." fromName={filtersFormFields.vatFrom} toName={filtersFormFields.vatTo} onChange={formikProps.submitForm} />
      );
    }
    if (filter === filtersFormFields.kontoId) {
      return (
        <FormItemWithoutColon name={filtersFormFields.kontoId} label={listingLabelList.kontoId} key={filter}>
          <KontoFormSelect name={filtersFormFields.kontoId} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.buchungsdatumFrom) {
      return (
        <FormItemWithoutColon name="buchungsdatum" label={listingLabelList.buchungsdatumFrom} key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={filtersFormFields.buchungsdatumFrom}
            toName={filtersFormFields.buchungsdatumTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.billingType) {
      return (
        <FormItemWithoutColon name={filtersFormFields.billingType} label={listingLabelList.billingType} key={filter}>
          <VerrechnungsartListFormSelect name={filtersFormFields.billingType} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.paymentMethod) {
      return (
        <FormItemWithoutColon name={filtersFormFields.paymentMethod} label={listingLabelList.paymentMethod} key={filter}>
          <PaymentMethodListFormSelect name={filtersFormFields.paymentMethod} onChange={formikProps.submitForm} selection />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.incomingInvoiceFilterStatus) {
      return (
        <FormItemWithoutColon name={filtersFormFields.incomingInvoiceFilterStatus} label={listingLabelList.incomingInvoiceFilterStatus} key={filter}>
          <IncomingInvoiceFilterStatusListFormSelect name={filtersFormFields.incomingInvoiceFilterStatus} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.kundenNummer) {
      return (
        <FormItemWithoutColon name={filtersFormFields.kundenNummer} label={listingLabelList.kundenNummer} key={filter}>
          <KundennummerListFormSelect name={filtersFormFields.kundenNummer} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.paymentReferenceText) {
      return (
        <FormItemWithoutColon name={filtersFormFields.paymentReferenceText} label={listingLabelList.paymentReferenceText} key={filter}>
          <PaymentReferenceTextListFormSelect
            name={filtersFormFields.paymentReferenceText}
            paymentReferenceText={formikProps.values.paymentReferenceText}
            onChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.purposeOfUseText) {
      return (
        <FormItemWithoutColon name={filtersFormFields.purposeOfUseText} label={listingLabelList.purposeOfUseText} key={filter}>
          <PurposeOfUseTextListFormSelect
            name={filtersFormFields.purposeOfUseText}
            purposeOfUseText={formikProps.values.purposeOfUseText}
            onChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.sepaMandatReference) {
      return (
        <FormItemWithoutColon name={filtersFormFields.sepaMandatReference} label={listingLabelList.sepaMandatReference} key={filter}>
          <SepaMandateReferenceListFormSelect
            name={filtersFormFields.sepaMandatReference}
            sepaMandateReference={formikProps.values.sepaMandatReference}
            onChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.createTsFrom) {
      return (
        <FormItemWithoutColon name="erstelltAm" label={listingLabelList.createTsFrom} key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={filtersFormFields.createTsFrom}
            toName={filtersFormFields.createTsTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.createdByMitarbeiterIdList) {
      return (
        <FormItemWithoutColon name={filtersFormFields.createdByMitarbeiterIdList} label={listingLabelList.createdByMitarbeiterIdList} key={filter}>
          <MitarbeiterSelect name={filtersFormFields.createdByMitarbeiterIdList} onChange={formikProps.submitForm} mode="multiple" />
        </FormItemWithoutColon>
      );
    }
    return undefined;
  };

  const { selectedFilterList, setSelectedFilterList, leftColumn, rightColumn } = useFilterWithQueryParams({
    defaultSelectedFilterList,
    updateFilterValues,
    filters,
    labelList: listingLabelList,
    mapFilterLabelToQueryFilterList,
    queryParams,
  });

  return (
    <FiltersWith4OrMoreFields
      filtersSelect={
        <FiltersSelect
          defaultSelectedFilterList={defaultSelectedFilterList}
          selectedFilterList={selectedFilterList}
          onChange={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
          labelList={listingLabelList}
        />
      }
      actionButtons={<IncomingInvoiceListingActionButtons />}
      filterActions={<IncomingInvoiceTableBulkActions onAction={onAction} formikProps={formikProps} />}
      hideTitle
      leftColumn={<>{leftColumn}</>}
      rightColumn={<>{rightColumn}</>}
    />
  );
};

export default ListingFilters;
