import { FormFields, mapFormDateValueToDateString } from '../../../helpers/formikHelper';
import { BookingSuggestionVerbuchenGenerierlaufCreateInput } from '../../../types';
import { dayjsCustom } from '../../../helpers/dayjsCustom';

export interface BookingSuggestionVerbuchenFormValues {
  paymentProposalId?: string | null;
  buchungsdatum: string;
}

export const bookingSuggestionVerbuchenInitialValues: BookingSuggestionVerbuchenFormValues = {
  buchungsdatum: dayjsCustom().format(),
};

export const bookingSuggestionVerbuchenFormFields: FormFields<BookingSuggestionVerbuchenFormValues> = {
  paymentProposalId: 'paymentProposalId',
  buchungsdatum: 'buchungsdatum',
};

export const mapFormValuesToBookingSuggestionCreateInput = (
  values: BookingSuggestionVerbuchenFormValues
): BookingSuggestionVerbuchenGenerierlaufCreateInput => ({
  buchungsdatum: mapFormDateValueToDateString(values.buchungsdatum),
  paymentProposalId: values.paymentProposalId,
});
