import React, { FC } from 'react';
import { Modal } from 'antd';
import { useBudgetingCreateVPosValuesFromVPosMutation } from '../gql/BudgetingMutations.types';
import { showSuccessMsgOther } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { Budgeting } from '../../../types';
import { mapFormValuesToBudgetingCreateVPosValuesForm } from './budgetingCreateVPosValuesFormMapper';
import BudgetingCreateVPosValuesForm from './BudgetingCreateVPosValuesForm';

type Props = {
  budgeting: Budgeting;
  open: boolean;
  onSuccess: () => void;
  onCancel: () => void;
};

const BudgetingCreateVPosValuesFromVPosModal: FC<Props> = ({ budgeting, open, onSuccess, onCancel }) => {
  const [runCreateVPosValues, { loading }] = useBudgetingCreateVPosValuesFromVPosMutation({
    onCompleted: () => {
      showSuccessMsgOther('VPos-Werte wurden erfolgreich aus den Budgetierungs-Vorschreibungs-Werten erstellt');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Modal
      open={open}
      title="VPos-Werte aus den Budgetierungs-Vorschreibungs-Werten erstellen"
      footer={null}
      onCancel={onCancel}
      destroyOnClose
      maskClosable={false}
    >
      <BudgetingCreateVPosValuesForm
        loading={loading}
        onCancel={onCancel}
        onSubmit={(values, { setSubmitting }) => {
          const input = mapFormValuesToBudgetingCreateVPosValuesForm(values);
          runCreateVPosValues({
            variables: {
              budgetingId: budgeting.budgetingId,
              objektId: budgeting.objekt.objektId,
              input,
            },
          }).finally(() => {
            setSubmitting(false);
          });
        }}
        fieldHelpSelectorEntityName="BudgetingCreateVPosWerteFromVPosActionData"
      />
    </Modal>
  );
};

export default BudgetingCreateVPosValuesFromVPosModal;
