import { ReadOutlined } from '@ant-design/icons';
import { generatePathToBookingDetailsWithBookingSuggestionPage } from '../../BookingDetails/bookingDetailsPaths';
import { ReceivedBankTransactionListEntry } from '../../../types';

const receivedBankTransactionTableActionForBuchungDetails = (key: string | number, receivedBankTransaction: ReceivedBankTransactionListEntry) => {
  return receivedBankTransaction.bookingSuggestionFibuInfoList.length === 1
    ? {
        key,
        label: 'Buchungsdetails ansehen',
        icon: <ReadOutlined />,
        onClick: () =>
          window.open(
            generatePathToBookingDetailsWithBookingSuggestionPage(
              receivedBankTransaction.bookingSuggestionFibuInfoList[0].bookingSuggestionId,
              receivedBankTransaction.bookingSuggestionFibuInfoList[0].fibuBuchungIdList
            )
          ),
      }
    : {
        key,
        label: 'Buchungsdetails ansehen',
        icon: <ReadOutlined />,
        children: receivedBankTransaction.bookingSuggestionFibuInfoList.map((bookingSuggestionFibuInfo, index) => ({
          key: `${bookingSuggestionFibuInfo.bookingSuggestionId}-${index}`,
          label: bookingSuggestionFibuInfo.name,
          onClick: () =>
            window.open(
              generatePathToBookingDetailsWithBookingSuggestionPage(
                bookingSuggestionFibuInfo.bookingSuggestionId,
                bookingSuggestionFibuInfo.fibuBuchungIdList
              )
            ),
        })),
      };
};

export default receivedBankTransactionTableActionForBuchungDetails;
