import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { pathsToWeAbrechnungDetails } from '../../weAbrechnungUriPaths';
import { EuroAmount, Percent } from '../../../../../components/Number';
import AbrechnungsErgebnis from './AbrechnungsErgebnis';
import SteuersatzAdminLink from '../../../../Vertrag/BeVertrag/Vertragsdaten/VorschreibungspositionTab/VorschreibungspositionWert/SteuersatzAdminLink';
import { TableWithAlignedColsColumnType } from '../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { TopAbrAbrechnungkreis } from '../../../../../types';

const topAbrechnungWithoutRepFondsDetailsTableColumns = (
  objektId: string,
  objektAbrechnungId: string
): TableWithAlignedColsColumnType<TopAbrAbrechnungkreis>[] => [
  {
    title: 'Abrechnungskreis',
    render: (abrKreis) => <Typography.Text>{abrKreis.bezeichnung}</Typography.Text>,
    align: 'left',
  },
  {
    title: 'Aufwand Gesamt',
    align: 'center',
    render: (abrKreis) => (
      <Link target="_blank" to={pathsToWeAbrechnungDetails(objektId, objektAbrechnungId).objAbrTab}>
        <EuroAmount value={abrKreis.aufwandGesamt} />
      </Link>
    ),
  },
  {
    title: 'Anteil %',
    align: 'right',
    render: (abrKreis) => (abrKreis.aufwandProzent ? <Percent value={abrKreis.aufwandProzent / 100} /> : null),
  },
  {
    title: 'Anteil vom Aufwand',
    align: 'right',
    render: (abrKreis) => <EuroAmount value={abrKreis.aufwand} />,
  },
  {
    title: 'Vorschreibung (Netto)',
    align: 'right',
    render: (abrKreis) => <EuroAmount value={abrKreis.einnahmen} />,
  },
  {
    title: 'Saldo',
    align: 'right',
    render: (abrKreis) => <EuroAmount value={abrKreis.netto} />,
  },
  {
    title: 'Ust',
    align: 'right',
    render: (abrKreis) => <EuroAmount value={abrKreis.ust} />,
  },
  {
    title: 'Steuersatz',
    align: 'right',
    render: (abrKreis) => (
      <div style={{ justifyContent: 'right' }}>
        <SteuersatzAdminLink
          steuersatz={abrKreis.steuersatz}
          ustKategorieEntryId={abrKreis.ustKategorieEntryId}
          ustKategorieId={abrKreis.ustKategorieId}
          openInNewTab
        />
      </div>
    ),
  },
  {
    title: 'Abrechnungsergebnis',
    align: 'right',
    render: (abrKreis) => <AbrechnungsErgebnis brutto={abrKreis.brutto} />,
  },
];

export default topAbrechnungWithoutRepFondsDetailsTableColumns;
