import React, { FC } from 'react';
import { Button, Dropdown, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { useToggle } from '../../../../../hooks/useToggle';
import VersandDrawer from '../Drawer/VersandDrawer';
import { useDeleteBeVertragDokumentenversandEntryMutation } from '../gql/BeVertragDokumentenversandMutations.types';
import { showSuccessMsgDelete } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { DokumentenversandEntryFragment } from '../gql/BeVertragDokumentenVersandFragments.types';

type VersandCardExtraProps = {
  isOriginal: boolean;
  onActionSuccess: () => void;
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  dokumentenversandEntry: DokumentenversandEntryFragment;
  rechtstraegerKurzbezeichnung: string;
};

const VersandCardExtra: FC<VersandCardExtraProps> = ({
  isOriginal,
  onActionSuccess,
  dokumentenversandEntry,
  bestandseinheitId,
  vertragId,
  objektId,
  rechtstraegerKurzbezeichnung,
}) => {
  const [isDrawerOpen, toggleDrawerOpen] = useToggle();

  const [runDelete] = useDeleteBeVertragDokumentenversandEntryMutation({
    variables: {
      objektId,
      bestandseinheitId,
      dokumentenversandEntryId: dokumentenversandEntry.dokumentenversandEntryId,
      vertragId,
    },
    onCompleted: () => {
      showSuccessMsgDelete(`Versandkopie an ${rechtstraegerKurzbezeichnung}`);
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <>
      <Dropdown menu={{ items: menuItems(isOriginal, runDelete, toggleDrawerOpen, rechtstraegerKurzbezeichnung) }}>
        <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
      </Dropdown>
      <VersandDrawer
        title="Dokumentenversand bearbeiten"
        isDrawerOpen={isDrawerOpen}
        toggleDrawerOpen={toggleDrawerOpen}
        onSuccess={onActionSuccess}
        objektId={objektId}
        bestandseinheitId={bestandseinheitId}
        dokumentenversandEntry={dokumentenversandEntry}
        vertragId={vertragId}
        isOriginal={isOriginal}
      />
    </>
  );
};

const menuItems = (
  isOriginal: boolean,
  runDeleteDokumentenVersand: () => void,
  toggleDrawerOpen: () => void,
  rechtstraegerKurzbezeichnung: string
) => [
  {
    key: '1',
    label: 'Bearbeiten',
    onClick: () => toggleDrawerOpen(),
    icon: <EditOutlined />,
  },
  {
    key: '2',
    label: 'Löschen',
    onClick: () => showConfirmDelete(runDeleteDokumentenVersand, rechtstraegerKurzbezeichnung),
    icon: <DeleteOutlined />,
    disabled: isOriginal,
    danger: true,
  },
];

const showConfirmDelete = (runDelete: () => void, rechtstraegerKurzbezeichnung: string) => {
  Modal.confirm({
    title: 'Möchten Sie diese Versandkopie löschen?',
    content: `Versandkopie an ${rechtstraegerKurzbezeichnung}`,
    okText: 'Löschen',
    okButtonProps: { danger: true },
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default VersandCardExtra;
