import { FC } from 'react';
import { Space } from 'antd';
import KontoWithQuery from '../../../shared/components/Konto/KontoWithQuery';
import { Vorsteuerkuerzung } from '../../../types';

type Props = {
  vstKuerzung: Vorsteuerkuerzung;
  objektId: string;
};

const VstKuerzungKontoList: FC<Props> = ({ vstKuerzung, objektId }) => {
  const vstKuerzungKontoList = vstKuerzung.vorsteuerkuerzungVertragList
    .map((vertrag) => vertrag.vorsteuerkuerzungKontoList.map((vstKuerzung) => vstKuerzung.vorsteuerkuerzungKontoId))
    .flat();

  const uniquevstKuerzungKontoList = Array.from(new Set(vstKuerzungKontoList));

  return (
    <Space direction="vertical">
      {uniquevstKuerzungKontoList.map((kontoId, index) => (
        <KontoWithQuery kontoId={kontoId} key={index} objektId={objektId} />
      ))}
    </Space>
  );
};

export default VstKuerzungKontoList;
