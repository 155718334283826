import { Dayjs } from 'dayjs';
import { FormFields } from '../../../../../../../helpers/formikHelper';
import { FibuBuchungType, SollHaben } from '../../../../../../../types';
import { ObjectOfStrings } from '../../../../../../../shared/typeHelpers';

export type FiltersFormValues = {
  belegdatumBis?: Dayjs;
  belegdatumVon?: Dayjs;
  belegnummer?: string;
  belegsymbol?: string;
  bestandseinheitId?: string;
  buchungkreisId?: string;
  buchungsTypeList?: FibuBuchungType[];
  buchungsdatumBis?: Dayjs;
  buchungsdatumVon?: Dayjs;
  buchungskennzeichen?: string;
  ersteller?: string;
  gegenKontoId?: string;
  kontoId?: string;
  objektId?: string;
  sollHaben?: SollHaben;
  vertragId?: string;
};

export const filtersFormFields: FormFields<FiltersFormValues> = {
  belegdatumBis: 'belegdatumBis',
  belegdatumVon: 'belegdatumVon',
  belegnummer: 'belegnummer',
  belegsymbol: 'belegsymbol',
  bestandseinheitId: 'bestandseinheitId',
  buchungkreisId: 'buchungkreisId',
  buchungsTypeList: 'buchungsTypeList',
  buchungsdatumBis: 'buchungsdatumBis',
  buchungsdatumVon: 'buchungsdatumVon',
  buchungskennzeichen: 'buchungskennzeichen',
  ersteller: 'ersteller',
  gegenKontoId: 'gegenKontoId',
  kontoId: 'kontoId',
  objektId: 'objektId',
  sollHaben: 'sollHaben',
  vertragId: 'vertragId',
};

export const listingLabelList: ObjectOfStrings<Omit<FiltersFormValues, 'belegdatumBis' | 'buchungsdatumBis'>> = {
  [filtersFormFields.belegdatumVon]: 'Belegdatum',
  [filtersFormFields.belegnummer]: 'Belegnummer',
  [filtersFormFields.belegsymbol]: 'Belegsymbol',
  [filtersFormFields.bestandseinheitId]: 'Bestandseinheit',
  [filtersFormFields.buchungkreisId]: 'Buchungskreis',
  [filtersFormFields.buchungsTypeList]: 'Buchungstyp',
  [filtersFormFields.buchungsdatumVon]: 'Buchungsdatum',
  [filtersFormFields.buchungskennzeichen]: 'Buchungskennzeichen',
  [filtersFormFields.ersteller]: 'Ersteller',
  [filtersFormFields.gegenKontoId]: 'Gegenkonto',
  [filtersFormFields.kontoId]: 'Konto',
  [filtersFormFields.objektId]: 'Objekt',
  [filtersFormFields.sollHaben]: 'Soll/Haben',
  [filtersFormFields.vertragId]: 'Vertrag',
};
