import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import { DokumentenversandEntryFragmentDoc } from './BeVertragDokumentenVersandFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BeVertragDokumentenversandQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
}>;

export type BeVertragDokumentenversandQuery = {
  getBeVertragDokumentenversand: {
    data: {
      original: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        dokumentenversandEntryId: string;
        emailAktiv: boolean;
        emailHaupt: boolean;
        postAktiv: boolean;
        postHaupt: boolean;
        emailContact?: {
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        missingEmailContactReason?: { text: string; value: Types.MissingContactReason } | null;
        missingPostAddressReason?: { text: string; value: Types.MissingContactReason } | null;
        postAddress?: {
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechtstraeger: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      kopieList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        dokumentenversandEntryId: string;
        emailAktiv: boolean;
        emailHaupt: boolean;
        postAktiv: boolean;
        postHaupt: boolean;
        emailContact?: {
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        missingEmailContactReason?: { text: string; value: Types.MissingContactReason } | null;
        missingPostAddressReason?: { text: string; value: Types.MissingContactReason } | null;
        postAddress?: {
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechtstraeger: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const BeVertragDokumentenversandDocument = gql`
  query BeVertragDokumentenversand($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!) {
    getBeVertragDokumentenversand(objektId: $objektId, bestandseinheitId: $bestandseinheitId, vertragId: $vertragId) {
      data {
        original {
          ...dokumentenversandEntry
        }
        kopieList {
          ...dokumentenversandEntry
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${DokumentenversandEntryFragmentDoc}
`;
export function useBeVertragDokumentenversandQuery(
  baseOptions: Apollo.QueryHookOptions<BeVertragDokumentenversandQuery, BeVertragDokumentenversandQueryVariables> &
    ({ variables: BeVertragDokumentenversandQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BeVertragDokumentenversandQuery, BeVertragDokumentenversandQueryVariables>(BeVertragDokumentenversandDocument, options);
}
export function useBeVertragDokumentenversandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BeVertragDokumentenversandQuery, BeVertragDokumentenversandQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BeVertragDokumentenversandQuery, BeVertragDokumentenversandQueryVariables>(BeVertragDokumentenversandDocument, options);
}
export function useBeVertragDokumentenversandSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BeVertragDokumentenversandQuery, BeVertragDokumentenversandQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BeVertragDokumentenversandQuery, BeVertragDokumentenversandQueryVariables>(
    BeVertragDokumentenversandDocument,
    options
  );
}
export type BeVertragDokumentenversandQueryHookResult = ReturnType<typeof useBeVertragDokumentenversandQuery>;
export type BeVertragDokumentenversandLazyQueryHookResult = ReturnType<typeof useBeVertragDokumentenversandLazyQuery>;
export type BeVertragDokumentenversandSuspenseQueryHookResult = ReturnType<typeof useBeVertragDokumentenversandSuspenseQuery>;
export type BeVertragDokumentenversandQueryResult = Apollo.QueryResult<BeVertragDokumentenversandQuery, BeVertragDokumentenversandQueryVariables>;
