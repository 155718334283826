import React, { FC } from 'react';
import { Space, Typography } from 'antd';
import { FormikProps } from 'formik';
import { round } from 'lodash';
import { heVertragFormFields, HeVertragFormValues } from './heVertragFormMapper';
import { useSummeAnteileQuery } from '../../../Objekt/gql/ObjektQueries.types';
import { mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import FormattedDecimalFormInput from '../../../../components/Input-antd/FormattedDecimalFormInput';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  formikProps: FormikProps<HeVertragFormValues>;
  name: string;
  objektId: string;
  getFieldHelpText?: string | null;
};

const HeVertragSummeAnteileInput: FC<Props> = ({ formikProps, name, objektId, getFieldHelpText }) => {
  const date = formikProps.values.vertragsBeginn && mapFormDateValueToDateString(formikProps.values.vertragsBeginn);

  const { data } = useSummeAnteileQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { objektId, date: date! },
    skip: !formikProps.values.vertragsBeginn,
  });

  const summeAnteile = data?.getSummeAnteile.data ?? 0;
  const summeAnteilePercentage = formikProps.values.anteile ? (formikProps.values.anteile / summeAnteile) * 100 : 0;

  return (
    <>
      <FormItemWithFieldHelp name={heVertragFormFields.anteile} fieldHelp={getFieldHelpText} label="Anteile">
        <Space direction="vertical" style={{ width: '100%', display: 'flex' }}>
          <Typography>{`Gesamtanteile: ${summeAnteile} -> ${
            isFinite(summeAnteilePercentage) && summeAnteilePercentage > 0 ? `${round(summeAnteilePercentage, 2)}%` : 0
          }`}</Typography>
          <FormattedDecimalFormInput name={name} size="small" controls={false} min={0} style={{ width: '100%' }} precision={2} />
        </Space>
      </FormItemWithFieldHelp>
    </>
  );
};

export default HeVertragSummeAnteileInput;
