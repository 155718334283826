import React, { ChangeEvent, FC, PropsWithChildren } from 'react';
import { FormItem, Input } from 'formik-antd';
import numbro from 'numbro';
import { useField } from 'formik';
import { min } from 'lodash';
import { FormikFieldProps } from 'formik-antd/lib/FieldProps';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { parseFormattedDecimal } from './formattedDecimalHelper';
import { StyledFormItem } from './Styled/FormItem.style';

interface DeprecatedFormattedDecimalProps extends FormikFieldProps {
  fractionDigits?: number;
  label?: string | undefined;
  name: string;
  size?: SizeType;
  disabled?: boolean;
  placeholder?: string;
  style?: any;
}

const DeprecatedFormattedDecimal: FC<DeprecatedFormattedDecimalProps & PropsWithChildren> = ({
  name,
  label,
  size,
  disabled,
  fractionDigits = 2,
  ...props
}) => {
  const { style, ...propsWithoutStyle } = props;
  const { children, ...propsWithoutChildren } = props;
  const [{ value: fieldValue }, , { setValue, setTouched }] = useField(name);
  const runAfterUpdate = useRunAfterUpdate();
  const decimalSeparator = numbro.languageData().delimiters.decimal;
  // const errorMessage = get(touched, field.name) && get(errors, field.name);

  const updateFormikFieldValue = (value: string) => {
    setValue(value);
    setTouched(true);
  };

  const numberFormatOptions = {
    thousandSeparated: true,
    mantissa: fractionDigits,
    trimMantissa: true,
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const input = event.target;
    const { value, selectionStart } = input;

    if (value === undefined || value === null) {
      return;
    }

    if (value === '' || value === '-') {
      updateFormikFieldValue(value);
      return;
    }

    /* number is a decimal and decimal separator has been entered, update the formik field with the string, do not let
       numbro unformatting take place because it would trim the leading decimal separator */
    if (numberFormatOptions.mantissa > 0 && value.endsWith(decimalSeparator) && numbro.validate(value, numberFormatOptions)) {
      updateFormikFieldValue(value);
      return;
    }

    let parsedNumber: number;
    let formattedValue: string;

    if (isValidNumber(value, numberFormatOptions)) {
      parsedNumber = parseFormattedDecimal(value);

      if (isNumberOutOfRange(parsedNumber)) {
        handleOutOfRangeNumber(parsedNumber, numberFormatOptions, updateFormikFieldValue);
        return;
      }

      if (isMantissaTooLong(parsedNumber, numberFormatOptions)) {
        parsedNumber = trimMantissaWithoutRounding(parsedNumber, numberFormatOptions);
      }

      const currentMantissaCount = getMantissaCount(value, decimalSeparator);

      // if value has digits on decimal places we should always show as many of them as user typed in or the allowed maximal if user already typed more then allowed
      const format: numbro.Format =
        currentMantissaCount === 0
          ? numberFormatOptions
          : { ...numberFormatOptions, mantissa: min([currentMantissaCount, numberFormatOptions.mantissa]), trimMantissa: false };

      formattedValue = numbro(parsedNumber).format(format);
      updateFormikFieldValue(formattedValue);

      // updating caretposition
      let caretPosition = selectionStart || 0;
      // user typed: 1111=>1.111
      if (formattedValue && formattedValue.length > value.length) {
        caretPosition += 1;
      }

      // 1.234.567 => remove 4 => value lenght: 9, formatted: 123.567, length: 7
      if (formattedValue && formattedValue.length < value.length && caretPosition !== 0) {
        caretPosition -= 1;
      }

      const updateSelection = () => {
        input.selectionStart = caretPosition;
        input.selectionEnd = caretPosition;
      };

      runAfterUpdate(updateSelection);
    } else {
      // not a valid number (probably letter in the input) => take the current (not updated) values of field (change does not take effect)
      updateFormikFieldValue(fieldValue);
    }
  };

  let componentToRender;
  if (size === 'small') {
    componentToRender = (
      <StyledFormItem colon={false} htmlFor={name} label={label} name={name} {...propsWithoutStyle}>
        <Input
          disabled={disabled}
          id={name}
          name={name}
          onChange={handleChange}
          style={{ textAlign: 'right' }}
          size="small"
          {...propsWithoutChildren}
        />{' '}
        {children}
      </StyledFormItem>
    );
  } else {
    componentToRender = (
      <FormItem colon={false} htmlFor={name} label={label} name={name} {...propsWithoutStyle}>
        <Input
          disabled={disabled}
          id={name}
          name={name}
          onChange={handleChange}
          style={{ textAlign: 'right' }}
          size={size}
          {...propsWithoutChildren}
        />{' '}
        {children}
      </FormItem>
    );
  }

  return <>{componentToRender}</>;
};

// Source: https://egghead.io/lessons/react-preserve-cursor-position-when-filtering-out-characters-from-a-react-input?pl=working-with-forms-in-react-c82c8503
function useRunAfterUpdate() {
  const afterPaintRef = React.useRef<(() => void) | null>(null);
  React.useLayoutEffect(() => {
    if (afterPaintRef.current) {
      afterPaintRef.current();
      afterPaintRef.current = null;
    }
  });
  // eslint-disable-next-line no-return-assign
  return (fn: () => void) => (afterPaintRef.current = fn);
}

const isValidNumber = (stringValue: string, numberFormatOptions: numbro.Format) => numbro.validate(stringValue, numberFormatOptions);

const isNumberOutOfRange = (numberValue: number) => numberValue > Number.MAX_SAFE_INTEGER || numberValue < Number.MIN_SAFE_INTEGER;

// if input number is too big or too small for JS, take the max or min number that can be handled: bad UX but really edge case, so for right now it is ok
const handleOutOfRangeNumber = (parsedNumber: number, numberFormatOptions: numbro.Format, updateFormikFieldValue: (value: string) => void) => {
  if (parsedNumber > Number.MAX_SAFE_INTEGER) {
    const formattedValue = numbro(Number.MAX_SAFE_INTEGER).format(numberFormatOptions);
    updateFormikFieldValue(formattedValue);
  }
  if (parsedNumber <= Number.MIN_SAFE_INTEGER) {
    const formattedValue = numbro(Number.MIN_SAFE_INTEGER).format(numberFormatOptions);
    updateFormikFieldValue(formattedValue);
  }
};

const isMantissaTooLong = (numberValue: number, numberFormatOptions: numbro.Format) => {
  if (!numberFormatOptions.mantissa) {
    return false;
  }
  return getMantissaCount(numberValue, '.') > numberFormatOptions.mantissa;
};

const getMantissaCount = (numberValue: number | string, decimalSeparator: string) => {
  const parts = numberValue.toString().split(decimalSeparator);
  if (parts.length === 2) {
    return parts[1].length;
  }
  return 0;
};

const trimMantissaWithoutRounding = (numberValue: number, numberFormatOptions: numbro.Format) => {
  const parts = numberValue.toString().split('.');
  const decimalPart = parts[1].slice(0, numberFormatOptions.mantissa);
  return parseFloat(`${parts[0]}.${decimalPart}`);
};

export default DeprecatedFormattedDecimal;
