import React, { FC, useContext } from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, HistoryOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import HistoryModal, { HistoryType } from '../../../../../../History/shared/HistoryModal';
import { useToggle } from '../../../../../../../hooks/useToggle';
import { useDeleteVertragVposIndexationAgreementMutation } from '../../gql/VPosIndexationAgreementMutations.types';
import { showSuccessMsgDelete } from '../../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../../helpers/apolloHelper';
import { IndexationAgreementContext } from './IndexationAgreementContext';
import IndexationAgreementChangeHistoryListingTable from './IndexationAgreementChangeHistoryListingTable';
import { ValuePreservationType } from '../../../../../../../types';
import UpdateIndexationAgreementDrawer from './CreateUpdateIndexationAgreement/UpdateIndexationAgreementDrawer';

type Props = {
  indexationAgreementId: string;
  valuePreservationType: ValuePreservationType;
  onSuccess: () => void;
};

const IndexationAgreementTableActions: FC<Props> = ({ indexationAgreementId, valuePreservationType, onSuccess }) => {
  const { objektId, bestandseinheitId, vertragId, vorschreibungspositionId } = useContext(IndexationAgreementContext);
  const [isUpdateDrawerOpen, toggleUpdateDrawerOpen] = useToggle();
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const [runDelete] = useDeleteVertragVposIndexationAgreementMutation({
    onCompleted: () => {
      showSuccessMsgDelete(`Wertsicherungsvereinbarung`);
      onSuccess();
    },
    variables: {
      objektId,
      bestandseinheitId,
      vertragId,
      vorschreibungspositionId,
      indexationAgreementId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <>
      <Tooltip title="Bearbeiten">
        <Button type="link" icon={<EditOutlined />} onClick={toggleUpdateDrawerOpen} />
      </Tooltip>
      <Popconfirm title="Sind Sie sicher?" icon={<QuestionCircleOutlined style={{ color: 'red' }} />} onConfirm={() => runDelete()}>
        <Tooltip title="Löschen">
          <Button type="link" icon={<DeleteOutlined />} />
        </Tooltip>
      </Popconfirm>
      <Tooltip title="Änderungsverlauf">
        <Button type="link" icon={<HistoryOutlined />} onClick={toggleChangeHistoryOpen} data-testid="aufteilungsschluesselWert-btn-history" />
      </Tooltip>
      <UpdateIndexationAgreementDrawer
        indexationAgreementId={indexationAgreementId}
        valuePreservationType={valuePreservationType}
        isDrawerOpen={isUpdateDrawerOpen}
        onCancel={toggleUpdateDrawerOpen}
        onSuccess={() => {
          onSuccess();
          toggleUpdateDrawerOpen();
        }}
      />
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <IndexationAgreementChangeHistoryListingTable indexationAgreementId={indexationAgreementId} />
      </HistoryModal>
    </>
  );
};

export default IndexationAgreementTableActions;
