import { FC } from 'react';
import { Empty } from 'antd';
import { FibuBuchungsanweisungListEntry } from '../../../types';
import NestedTableWithColSelector from '../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import NestedTableWithColSelectorWrapper from '../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelectorWrapper';
import bookingInstructionTableColumns from './bookingInstructionTableColumns';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../hooks/useQueryWithPagingAndSorting';
import { useFibuBuchungsanweisungListQuery } from '../gql/BuchungsanweisungQueries.types';
import { mapQueryParamsToBuchungsanweisungVariables } from '../../BookingFolder/Listing/Filters/filtersFormMapper';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';
import { BookingFolderQueryParams } from '../../BookingFolder/Listing/Filters/filtersQueryParams';

type Props = {
  queryParams: BookingFolderQueryParams;
};

const BookingInstructionTable: FC<Props> = ({ queryParams }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();
  const { data, refetch, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useFibuBuchungsanweisungListQuery,
    {
      variables: {
        ...mapQueryParamsToBuchungsanweisungVariables(queryParams),
      },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const buchungsanweisungList = data?.getFibuBuchungsanweisungList.data.contentList ?? [];

  return (
    <NestedTableWithColSelectorWrapper filterIdentifier="objekt-sub-abrechnung">
      <NestedTableWithColSelector<FibuBuchungsanweisungListEntry>
        colWidth={150}
        level={1}
        locale={{
          emptyText: <Empty description={<span>Keine Buchungsanweisungen vorhanden</span>} />,
        }}
        loading={loading}
        dataSource={buchungsanweisungList}
        columns={bookingInstructionTableColumns(refetch)}
        onChange={handleTableSorting}
        pagination={pagination}
        rowKey={(record) => record.buchungsanweisungId}
        expandable={{
          rowExpandable: () => false,
        }}
      />
    </NestedTableWithColSelectorWrapper>
  );
};

export default BookingInstructionTable;
