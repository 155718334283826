import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../styles/pdfStyles';
import { UstVomAufwand } from '../../../../topAbrechnung-types';

const TARepFondsAbrUstVomAufwandList: FC<{ ustVomAufwandList: UstVomAufwand[] }> = ({ ustVomAufwandList }) => (
  <View style={[pdfStyles.tablePaddingLeftRight, { marginTop: '3mm' }]}>
    <View style={[pdfStyles.column, { marginBottom: '2mm' }]}>
      {ustVomAufwandList.map((ustVomAufwand, index) => (
        <View style={[pdfStyles.row]} key={index}>
          <Text style={[pdfStyles.textBulletinSmall, { width: '80%' }]}>{ustVomAufwand.bezeichnung}</Text>
          <Text style={[pdfStyles.textBulletinSmall, { width: '20%', alignItems: 'flex-end', textAlign: 'right' }]}>{ustVomAufwand.betragNetto}</Text>
        </View>
      ))}
    </View>
  </View>
);

export default TARepFondsAbrUstVomAufwandList;
