import React, { FC } from 'react';
import { Dropdown, MenuProps } from 'antd';
import {
  DeleteOutlined,
  DownOutlined,
  FileDoneOutlined,
  FilePdfOutlined,
  LockOutlined,
  ReadOutlined,
  SendOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { FormikProps } from 'formik';
import { MahnungListingFiltersFormValues } from '../mahnungListingFiltersFormMapper';
import { showSuccessMsgDelete, showSuccessMsgEntsperrt, showSuccessMsgGesperrt, showSuccessMsgOther } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import {
  useMahnungenEntsperrenMutation,
  useMahnungenFreigebenMutation,
  useMahnungenLoeschenMutation,
  useMahnungenSperrenMutation,
} from '../../gql/MahnungMutations.types';
import { useCreateMahnungAusgebenGenerierlaufMutation } from '../../../Verarbeitung/gql/MahnungGenerierlauf/MahnungAusgebenGenerierlaufMutations.types';
import { showSuccessMsgFreigegeben } from '../../../../components/message/message';
import { useCreateMahnungVersendenGenerierlaufMutation } from '../../../Verarbeitung/gql/MahnungGenerierlauf/MahnungVersendenGenerierlaufMutations.types';
import {
  showConfirmAusgeben,
  showConfirmDelete,
  showConfirmEntsperren,
  showConfirmFreigeben,
  showConfirmSperren,
  showConfirmVerbuchen,
  showConfirmVersenden,
} from '../../shared/mahnungConfirmMessages';
import { useCreateMahnungVerbuchenGenerierlaufMutation } from '../../../Verarbeitung/gql/MahnungGenerierlauf/MahnungVerbuchenGenerierlaufMutations.types';

type Props = {
  formikProps: FormikProps<MahnungListingFiltersFormValues>;
  onSuccess: () => void;
};

const MahnungTableBulkActions: FC<Props> = ({ formikProps, onSuccess }) => {
  const entity = 'Mahnungen';

  const {
    erstellerMitarbeiterIdList,
    mahnstichtagBisInklusive,
    mahnstichtagVonInklusive,
    objektId,
    rechnungsAusstellerId,
    vertragspartnerId,
    statusList,
  } = formikProps.values;

  const [runAusgeben] = useCreateMahnungAusgebenGenerierlaufMutation({
    variables: {
      input: { erstellerMitarbeiterIdList, mahnstichtagBisInklusive, mahnstichtagVonInklusive, objektId, rechnungsAusstellerId, vertragspartnerId },
    },
    onCompleted: () => {
      showSuccessMsgOther(`${entity} werden ausgegeben.`);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runEntsperren] = useMahnungenEntsperrenMutation({
    variables: {
      request: { erstellerMitarbeiterIdList, mahnstichtagBisInklusive, mahnstichtagVonInklusive, objektId, rechnungsAusstellerId, vertragspartnerId },
    },
    onCompleted: () => {
      showSuccessMsgEntsperrt(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runFreigeben] = useMahnungenFreigebenMutation({
    variables: {
      request: { erstellerMitarbeiterIdList, mahnstichtagBisInklusive, mahnstichtagVonInklusive, objektId, rechnungsAusstellerId, vertragspartnerId },
    },
    onCompleted: () => {
      showSuccessMsgFreigegeben(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runLoeschen] = useMahnungenLoeschenMutation({
    variables: {
      request: { erstellerMitarbeiterIdList, mahnstichtagBisInklusive, mahnstichtagVonInklusive, objektId, rechnungsAusstellerId, vertragspartnerId },
    },
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runSperren] = useMahnungenSperrenMutation({
    variables: {
      request: {
        erstellerMitarbeiterIdList,
        mahnstichtagBisInklusive,
        mahnstichtagVonInklusive,
        objektId,
        rechnungsAusstellerId,
        vertragspartnerId,
        statusList,
      },
    },
    onCompleted: () => {
      showSuccessMsgGesperrt(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runVersenden] = useCreateMahnungVersendenGenerierlaufMutation({
    variables: {
      input: {
        erstellerMitarbeiterIdList,
        mahnstichtagBisInklusive,
        mahnstichtagVonInklusive,
        objektId,
        rechnungsAusstellerId,
        vertragspartnerId,
        startVerbuchenGenerierlauf: true,
      },
    },
    onCompleted: () => {
      showSuccessMsgOther(`${entity} werden versandt.`);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runVerbuchen] = useCreateMahnungVerbuchenGenerierlaufMutation({
    variables: {
      input: {
        erstellerMitarbeiterIdList,
        mahnstichtagBisInklusive,
        mahnstichtagVonInklusive,
        objektId,
        rechnungsAusstellerId,
        vertragspartnerId,
      },
    },
    onCompleted: () => {
      showSuccessMsgOther(`${entity} werden verbucht.`);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    { key: '1', label: 'Mahnungen freigeben', onClick: () => showConfirmFreigeben(runFreigeben), icon: <FileDoneOutlined /> },
    { key: '2', label: 'Mahnungen ausgeben', onClick: () => showConfirmAusgeben(runAusgeben), icon: <FilePdfOutlined /> },
    { key: '3', label: 'Mahnungen versenden', onClick: () => showConfirmVersenden(runVersenden), icon: <SendOutlined /> },
    { key: '4', label: 'Mahnungen verbuchen', onClick: () => showConfirmVerbuchen(runVerbuchen), icon: <ReadOutlined /> },
    { key: '5', label: 'Mahnungen sperren', onClick: () => showConfirmSperren(runSperren), danger: true, icon: <LockOutlined /> },
    { key: '6', label: 'Mahnungen entsperren', onClick: () => showConfirmEntsperren(runEntsperren), icon: <UnlockOutlined /> },
    { key: '7', label: 'Mahnungen löschen', onClick: () => showConfirmDelete(runLoeschen), danger: true, icon: <DeleteOutlined /> },
  ];

  return (
    <Dropdown menu={{ items }}>
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        Auf Liste anwenden <DownOutlined />
      </a>
    </Dropdown>
  );
};

export default MahnungTableBulkActions;
