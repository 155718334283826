import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { ObjektListEntryFragmentDoc } from '../../../Objekt/gql/ObjektFragments.types';
export type InfoMailDeliveryFieldsFragment = {
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  emailAddress: string;
  emailDeliveryDefinitionBezeichnung: string;
  emailDeliveryDefinitionId: string;
  infoMailDeliveryId: string;
  mailId?: string | null;
  original: boolean;
  sender?: string | null;
  sentTs?: string | null;
  shouldSend: boolean;
  objektTopVertrag: {
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    createTs: string;
    name: string;
    bestandseinheit: {
      bestandseinheitId: string;
      bezeichnung: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      createTs: string;
      objektId: string;
      status: { description?: string | null; text: string; value: Types.BestandseinheitStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    objekt: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      erstelltAm: string;
      fotoFileId?: string | null;
      fotoType?: Types.FotoType | null;
      kurzBezeichnung: string;
      objektId: string;
      rechnungsAusstellerId: string;
      verwaltungBis?: string | null;
      status: { text: string; value: Types.ObjektStatus; description?: string | null };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    vertrag: {
      bestandseinheitId: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      createTs: string;
      kurzBezeichnung: string;
      objektId: string;
      vertragsBeginn: string;
      vertragId: string;
      vertragspartner: {
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        createTs: string;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        type: { text: string; value: Types.RechtstraegerType };
        status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
      status: { description?: string | null; text: string; value: Types.VertragStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  recipient: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  vertragspartner: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const InfoMailDeliveryFieldsFragmentDoc = gql`
  fragment InfoMailDeliveryFields on InfoMailDelivery {
    createTs
    createdBy
    createdByMitarbeiterId
    emailAddress
    emailDeliveryDefinitionBezeichnung
    emailDeliveryDefinitionId
    infoMailDeliveryId
    mailId
    objektTopVertrag {
      bestandseinheit {
        bestandseinheitId
        bezeichnung
        createdBy
        createdByMitarbeiterId
        createTs
        objektId
        status {
          description
          text
          value
        }
        warningList {
          attribute
          message
          type
        }
      }
      createdBy
      createdByMitarbeiterId
      createTs
      name
      objekt {
        ...ObjektListEntry
      }
      vertrag {
        bestandseinheitId
        createdBy
        createdByMitarbeiterId
        createTs
        kurzBezeichnung
        objektId
        vertragsBeginn
        vertragId
        vertragspartner {
          createdBy
          createdByMitarbeiterId
          createTs
          kurzBezeichnung
          rechtstraegerId
          type {
            text
            value
          }
          status {
            description
            text
            value
          }
          warningList {
            attribute
            message
            type
          }
        }
        vertragsart {
          supportsExplicitVertragsEnde
          text
          value
        }
        status {
          description
          text
          value
        }
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        attribute
        message
        type
      }
    }
    original
    recipient {
      createTs
      createdBy
      createdByMitarbeiterId
      kurzBezeichnung
      rechtstraegerId
      status {
        description
        text
        value
      }
      type {
        text
        value
      }
      warningList {
        attribute
        message
        type
      }
    }
    sender
    sentTs
    shouldSend
    vertragspartner {
      createTs
      createdBy
      createdByMitarbeiterId
      kurzBezeichnung
      rechtstraegerId
      status {
        description
        text
        value
      }
      type {
        text
        value
      }
      warningList {
        attribute
        message
        type
      }
    }
    warningList {
      attribute
      message
      type
    }
  }
  ${ObjektListEntryFragmentDoc}
`;
