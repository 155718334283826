import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import KontoFormSelect from '../../../KontoSelect/KontoFormSelect';
import { showSuccessMsgUpdate } from '../../../../components/message';
import { KontoKlasse, KontoVerwendung } from '../../../../types';
import FormButtons from '../../../../components/Button/FormButtons';
import { useUpdatePauschalmieteForderungskontoMutation } from '../gql/PaulschalmieteMutations.types';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  pauschalmieteForderungskontoId?: string | null;
  onSuccess: () => void;
  onCancel: () => void;
};

const ForderungskontoForm: FC<Props> = ({ pauschalmieteForderungskontoId, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'FirmendatenPauschalmieteForderungskontoRequest'>('FirmendatenPauschalmieteForderungskontoRequest');

  const [runUpdate] = useUpdatePauschalmieteForderungskontoMutation({
    onCompleted: () => {
      showSuccessMsgUpdate('Forderungskonto');
      onSuccess();
    },
  });

  return (
    <>
      <Formik<{ pauschalmieteForderungskontoId?: string | null }>
        initialValues={{ pauschalmieteForderungskontoId }}
        onSubmit={(values) => {
          runUpdate({ variables: { firmendatenPauschalmieteForderungskontoId: values.pauschalmieteForderungskontoId } });
        }}
      >
        {(formikProps) => (
          <Form layout="vertical">
            <FormItemWithFieldHelp
              name="pauschalmieteForderungskontoId"
              label="Paulschalmiete Forderungs-Konto"
              fieldHelp={getFieldHelpText('FirmendatenPauschalmieteForderungskontoRequest.firmendatenPauschalmieteForderungskontoId')}
            >
              <KontoFormSelect
                name="pauschalmieteForderungskontoId"
                kontoKlasse={[KontoKlasse.UmlaufVermoegen]}
                kontoVerwendung={KontoVerwendung.AllgemeineForderung}
              />
            </FormItemWithFieldHelp>
            <FormButtons updateMode onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ForderungskontoForm;
