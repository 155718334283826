import { FormFields, mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';
import { ObjectOfStrings } from '../../../../../shared/typeHelpers';
import { OrderPaymentStatus, PaymentType, QueryGetPaymentListArgs, ReceivedBankTransactionListEntry, TransactionType } from '../../../../../types';
import { ALLOWED_STATUS_LIST_FOR_PAYMENT_ASSIGMENT } from './PaymentAssignmentForm';

export type FiltersFormValues = {
  amountFrom?: number | null;
  amountTo?: number | null;
  belegnummer?: string | null;
  payeeIban?: string | null;
  payeeId?: string | null;
  paymentDateFrom?: string | null;
  paymentDateTo?: string | null;
  paymentProposalId?: string | null;
  paymentReferenceText?: string | null;
  paymentTypeList?: PaymentType[];
  purposeOfUseText?: string | null;
  rechnungsAusstellerFibuKontoId?: string | null;
  rechnungsAusstellerIban?: string | null;
  rechnungsAusstellerId?: string | null;
  statusList?: OrderPaymentStatus[] | null;
};

export const filtersFormFields: FormFields<FiltersFormValues> = {
  amountFrom: 'amountFrom',
  amountTo: 'amountTo',
  belegnummer: 'belegnummer',
  payeeIban: 'payeeIban',
  payeeId: 'payeeId',
  paymentDateFrom: 'paymentDateFrom',
  paymentDateTo: 'paymentDateTo',
  paymentProposalId: 'paymentProposalId',
  paymentReferenceText: 'paymentReferenceText',
  paymentTypeList: 'paymentTypeList',
  purposeOfUseText: 'purposeOfUseText',
  rechnungsAusstellerFibuKontoId: 'rechnungsAusstellerFibuKontoId',
  rechnungsAusstellerIban: 'rechnungsAusstellerIban',
  rechnungsAusstellerId: 'rechnungsAusstellerId',
  statusList: 'statusList',
};

export const mapReceivedBankTransactionToFormValues = (receivedBankTransaction: ReceivedBankTransactionListEntry): FiltersFormValues => {
  const directDebit = [TransactionType.DirectDebitIncoming, TransactionType.DirectDebitOutgoing];
  const creditTransfer = [TransactionType.CreditTransferIncoming, TransactionType.CreditTransferOutgoing];

  let rechnungsAusstellerIban;
  let payeeIban;
  if (directDebit.includes(receivedBankTransaction.transactionType.value)) {
    rechnungsAusstellerIban = receivedBankTransaction.creditorIban;
    payeeIban = receivedBankTransaction.debtorIban;
  } else if (creditTransfer.includes(receivedBankTransaction.transactionType.value)) {
    rechnungsAusstellerIban = receivedBankTransaction.debtorIban;
    payeeIban = receivedBankTransaction.creditorIban;
  }

  return {
    rechnungsAusstellerIban,
    payeeIban,
    amountFrom: receivedBankTransaction.amount,
    amountTo: receivedBankTransaction.amount,
    paymentDateFrom: receivedBankTransaction.valueDate,
  };
};

export const mapFormValuesToListQueryVariables = (values: FiltersFormValues): QueryGetPaymentListArgs => ({
  ...values,
  belegnummer: values.belegnummer?.toString(),
  paymentDateFrom: values.paymentDateFrom ? mapFormDateValueToDateString(values.paymentDateFrom) : undefined,
  paymentDateTo: values.paymentDateTo ? mapFormDateValueToDateString(values.paymentDateTo) : undefined,
  statusList: values.statusList && values.statusList.length >= 1 ? values.statusList : ALLOWED_STATUS_LIST_FOR_PAYMENT_ASSIGMENT,
});

export const listingLabelList: ObjectOfStrings<Omit<FiltersFormValues, 'paymentId' | 'amountTo' | 'paymentDateTo'>> = {
  amountFrom: 'Betrag',
  belegnummer: 'Belegnummer',
  payeeIban: 'Zahlender/Zahlungsempfänger Bankverbindung',
  payeeId: 'Zahlender/Zahlungsempfänger',
  paymentDateFrom: 'Zahlungsdatum',
  paymentProposalId: 'Zahlungsvorschlag',
  paymentReferenceText: 'Zahlungsreferenz',
  paymentTypeList: 'Zahlungstyp',
  purposeOfUseText: 'Verwendungszweck',
  rechnungsAusstellerFibuKontoId: 'Fibu-Bankkonto',
  rechnungsAusstellerIban: 'Buchungskreis Bankverbindung',
  rechnungsAusstellerId: 'Buchungskreis',
  statusList: 'Zahlung Status',
};
