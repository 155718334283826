import { Box, BoxProps } from 'rebass';
import React, { FC } from 'react';

type Props = BoxProps;

const ZuordnungTableHeader: FC<Props> = ({ children, ...restProps }) => (
  <Box height={32} {...restProps}>
    {children}
  </Box>
);

export default ZuordnungTableHeader;
