import { Button, Empty } from 'antd';
import { FC } from 'react';

type TableListEmptyProps = {
  onClick: () => void;
  emptyTxt: string;
  btnTxt: string;
};

export const TableListEmpty: FC<TableListEmptyProps> = ({ onClick, emptyTxt, btnTxt, ...restProps }) => {
  return (
    <Empty description={<span>{emptyTxt}</span>} {...restProps}>
      <Button type="dashed" onClick={onClick}>
        <span>{btnTxt}</span>
      </Button>
    </Empty>
  );
};

export default TableListEmpty;
