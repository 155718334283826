import React, { FC } from 'react';
import { Document, Page, View } from '@react-pdf/renderer';
import pdfStyles from '../styles/pdfStyles';
import Header from '../shared/Header';
import { HauseigentuemerAbrechnung } from './hauseigentuemerAbrechnung-types';
import HeAHauseigentuemerAndDateBlock from './components/HeAHauseigentuemerAndDatumBlock';
import HeADetailsBlock from './components/HeADetailsBlock/HeADetailsBlock';
import { isNotNil } from '../helpers/assertionHelper';
import HeASummary from './components/HeASummary';
import ObjektZahlungsrueckstaendeTable from '../shared/ObjektZahlungsrueckstaendeTable/ObjektZahlungsrueckstaendeTable';
import Footer from '../shared/Footer';
import InfoAbrechnungskreisBlock from '../shared/Abrechnungskreis/InfoAbrechnungskreisBlock';
import Ergebnistext from '../shared/Ergebnistext';
import Einleitungstext from '../shared/Einleitungstext';
import Schlusstext from '../shared/Schlusstext';

type HauseigentuemerAbrechnungTemplateProps = {
  data: HauseigentuemerAbrechnung;
  isInEditMode?: boolean;
  selectedTextbaustein?: HauseigentuemerAbrechnungSelectableSection;
};

export type HauseigentuemerAbrechnungSelectableSection = 'EINLEITUNGSTEXT' | 'ERGEBNISTEXT' | 'SCHLUSSTEXT';

const HauseigentuemerAbrechnungTemplate: FC<HauseigentuemerAbrechnungTemplateProps> = ({ data, isInEditMode, selectedTextbaustein }) => (
  <Document>
    <Page size="A4" style={[pdfStyles.body, { paddingBottom: data.footer?.value ? '32mm' : pdfStyles.body.paddingBottom }]}>
      <View>
        {/* header */}
        <Header header={data.header} />
      </View>

      {/* HauseigentuemerBlock: (Absender + Rechnungsempfänger + Kunden-Nr + Anteile) + Datum + City */}
      <HeAHauseigentuemerAndDateBlock data={data} />

      {/* Einleitungstext with Textbaustein */}
      {isNotNil(data.einleitungstext) ? (
        <Einleitungstext text={data.einleitungstext} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />
      ) : null}

      {/* He-Abrechnung Details */}
      <HeADetailsBlock data={data} />

      {/* Summary */}
      <HeASummary zahlungsrueckstaendeAnteilHe={data.zahlungsrueckstaendeAnteilHauseigentuemer} endErgebnis={data.endErgebnis} />

      {/* Objekt Zahlungsrueckstände Table */}
      {data.objektZahlungsrueckstaendeBlock ? <ObjektZahlungsrueckstaendeTable data={data.objektZahlungsrueckstaendeBlock} /> : null}

      {/* Ergebnistext with Textbaustein */}
      {isNotNil(data.ergebnistext) ? (
        <Ergebnistext text={data.ergebnistext} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />
      ) : null}

      {/* Schlusstext with Textbaustein */}
      {isNotNil(data.schlusstext) ? (
        <Schlusstext text={data.schlusstext} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />
      ) : null}

      {/* InfoAbrechnungskreisblock */}
      {data.informationAbrechnungskreiseBlock && data.informationAbrechnungskreiseBlock.abrechnungskreisList.length > 0 && (
        <InfoAbrechnungskreisBlock informationAbrechnungskreiseBlock={data.informationAbrechnungskreiseBlock} />
      )}

      {/* Footer */}
      {data.footer?.value && <Footer footer={data.footer} />}
    </Page>
  </Document>
);

export default HauseigentuemerAbrechnungTemplate;
