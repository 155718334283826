import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../styles/pdfStyles';
import { ObjektAbrechnung } from '../objektAbrechnung-types';

const OAAdressList: FC<{ data: ObjektAbrechnung }> = ({ data }) => (
  <View style={[pdfStyles.row, { paddingTop: '3mm' }]}>
    <Text style={[pdfStyles.textList, pdfStyles.column, { width: '50%' }]}>{data.kopf.addressListText}</Text>
    <View style={[pdfStyles.column, { width: '30%' }]}>
      {data.kopf.addressList.map((address, index) => (
        <View style={[pdfStyles.column, { width: '100%', textAlign: 'left' }]} key={index}>
          <Text style={pdfStyles.textList}>
            {address.zipCode} {address.city}
          </Text>
          <Text style={pdfStyles.textList}>
            {address.street} {address.houseEntranceApartmentNumber}
          </Text>
        </View>
      ))}
    </View>
    <Text style={[pdfStyles.textList, pdfStyles.column, { width: '10%' }]} />
  </View>
);
export default OAAdressList;
