import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import { URI_VERARBEITUNG_VORSCHREIBUNG_MAIL_DELIVERY_SEND_DETAILS_PAGE } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import VorschreibungMailDeliverySendDetailsPage from './VorschreibungMailDeliverySendDetailsPage';

export type VerarbeitungVorschreibungMailDeliverySendDetailsPageRouteParams = { generierlaufId: string };

export const verarbeitungVorschreibungMailDeliverySendDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_VORSCHREIBUNG_MAIL_DELIVERY_SEND_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<VorschreibungMailDeliverySendDetailsPage />} />}
  />
);
