import React, { FC } from 'react';
import { RcFile } from 'antd/lib/upload/interface';
import { message, Spin } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { Box, Image } from 'rebass';
import Fetcher from '../../../../helpers/fetcher';
import { StyledDragger } from '../../../Document/Styled/Document.style';
import { processAndReturnError, processResponse } from '../../../../helpers/APIUtils';
import { useGetObjektFotoThumbnailOrOriginal } from '../../useFileDownloadAsyncForObjekt';
import { mapErrorToErrorMessage } from '../../../../helpers/errorAndWarningHelper';
import { useAuth } from '../../../../shared/Auth/authContext';
import { RcCustomRequestOptions } from '../../../Document/Upload/DocumentUpload';

type Props = {
  objektId: string;
  fotoFileId?: string | null;
  onUploadSuccess: () => void;
};

const ObjektFotoUpload: FC<Props> = ({ objektId, fotoFileId, onUploadSuccess }) => {
  const { activeForFirmendatenId } = useAuth();

  const handleUpload = (options: RcCustomRequestOptions) => {
    const formData = new FormData();
    formData.append(
      'objektFoto',
      options.file as RcFile,
      // @ts-ignore
      options.file.name
    );

    // @ts-ignore
    const msgKey = `msg-upload-${options.file.name}`;
    const MSG_LOADING_DURATION = 120; // message about loading will be removed after response comes so 120 sec should be long enough to get the response
    message.loading({
      // @ts-ignore
      content: `'${options.file.name}' wird hochgeladen`,
      key: msgKey,
      duration: MSG_LOADING_DURATION,
    });
    Fetcher.getInstance()
      .fetch(`/api/firmendaten/${activeForFirmendatenId}/objekte/${objektId}/foto`, {
        method: 'POST',
        body: formData,
      })
      .then((response) => {
        // FIXME ISSUE: https://github.com/ant-design/ant-design/issues/28713
        // @ts-ignore
        options.onSuccess(response, options.file);
        message.success({
          // @ts-ignore
          content: `'${options.file.name}' wurde erfolgreich hochgeladen`,
          key: msgKey,
        });
        processResponse(response).then(onUploadSuccess);
      })
      .catch((error) => {
        // FIXME ISSUE: https://github.com/ant-design/ant-design/issues/28713
        // @ts-ignore
        options.onError(error);
        return processAndReturnError(error);
      })
      .catch((error) => {
        message.error({
          // @ts-ignore
          content: `'${options.file.name}' konnte nicht hochgeladen werden: ${mapErrorToErrorMessage(error)}`,
          key: msgKey,
        });
      });
  };

  const uploadProps = {
    multiple: false,
    showUploadList: false,
    customRequest: handleUpload,
    beforeUpload: (file: RcFile) => {
      const hasNoType = !file.type;
      if (hasNoType) {
        message.error(`'${file.name}' hat keinen Dateityp. Ordner dürfen nicht hochgeladen werden.`);
      }
      const fileSizeInMB = file.size / 1024 / 1024;
      const isTooLarge = fileSizeInMB > 20;
      if (isTooLarge) {
        message.error(`'${file.name}' ist zu groß. Datei muss kleiner als 20 MB sein.`);
      }
      return !hasNoType && !isTooLarge;
    },
  };

  return (
    <Box px="12px">
      <StyledDragger {...uploadProps} openFileDialogOnClick>
        {!fotoFileId ? (
          <>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Foto hinzufügen</p>
            <p className="ant-upload-hint">Foto hier ablegen</p>
          </>
        ) : (
          <ObjektFoto objektId={objektId} fotoFileId={fotoFileId} />
        )}
      </StyledDragger>
    </Box>
  );
};

type ObjektFotoProps = {
  objektId: string;
  fotoFileId: string;
};

const ObjektFoto: FC<ObjektFotoProps> = ({ objektId, fotoFileId }) => {
  const { data, isLoading } = useGetObjektFotoThumbnailOrOriginal(objektId, fotoFileId);

  return isLoading ? (
    <Box p={48}>
      <Spin />
    </Box>
  ) : (
    <Image src={data} alt="Vorschaubild" />
  );
};

export default ObjektFotoUpload;
