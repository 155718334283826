import React, { FC } from 'react';
import { Empty, PaginationProps } from 'antd';
import kontoTableColumns from './kontoTableColumns';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { Konto } from '../../../../types';
import { HandleTableSorting } from '../../../../shared/typeHelpers';

type Props = {
  kontoList?: Konto[];
  handleTableSorting: HandleTableSorting;
  loading: boolean;
  pagination: PaginationProps;
  refetch: () => void;
};

const KontoTable: FC<Props> = ({ kontoList, loading, handleTableSorting, pagination }) => {
  return (
    <TableWithColSelector<Konto>
      size="small"
      locale={{
        emptyText: <Empty description={<span>Keine Konten vorhanden</span>} />,
      }}
      scroll={{ x: 900 }}
      columns={kontoTableColumns}
      dataSource={kontoList}
      loading={loading}
      onChange={handleTableSorting}
      pagination={pagination}
      rowKey={(record) => record.kontoId}
      filterIdentifier="sys-settings-kontenplan-konto"
    />
  );
};

export default KontoTable;
