import React, { FC } from 'react';
import { Select } from 'formik-antd';
import ParamFormItem from '../../../../../../components/Form/ParamFormItem';
import { useAuftragsartListForStornoSelectQuery } from '../../../../gql/AuftragsartQueries.types';
import { AuftragsartType } from '../../../../../../types';

type Props = {
  name: string;
  auftragsartType: AuftragsartType;
  isUpdate?: boolean;
};
const ParamFormItemStornoAuftragsartSelect: FC<Props> = ({ name, auftragsartType, isUpdate = false }) => {
  const { data, loading } = useAuftragsartListForStornoSelectQuery({ variables: { stornoForType: auftragsartType } });
  const stornoAuftragsartListForSelect = data?.getAuftragsartList.data ?? [];

  return (
    <ParamFormItem name={name} label="Storno-Auftragsart-Vorlage">
      <Select name={name} id={name} loading={loading} allowClear placeholder="Vorlage für Storno Auftragsart auswählen" size="small">
        {stornoAuftragsartListForSelect.map((stornoAuftragsart) => (
          <Select.Option key={stornoAuftragsart.auftragsartId} value={stornoAuftragsart.auftragsartId}>
            {stornoAuftragsart.bezeichnung}
          </Select.Option>
        ))}
      </Select>
    </ParamFormItem>
  );
};
export default ParamFormItemStornoAuftragsartSelect;
