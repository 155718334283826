import React, { FC } from 'react';
import { Button, Divider, Space } from 'antd';
import SingleInvoiceDirectBookingPreviewDetails from './SingleInvoiceDirectBookingPreviewDetails';
import SingleInvoiceAccrualBookingPreviewDetails from './SingleInvoiceAccrualBookingPreviewDetails';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../../../components/Button/ButtonsAligned';
import { TInvoice } from '../../incomingInvoiceFormMapper';
import { SingleInvoice, SingleInvoiceCreateInput, StandingInvoiceCreateInput } from '../../../../../types';
import { isBillingTypeEinzelrechnung } from '../../../../Payment/paymentHelpers';
import StandingInvoiceBookingPreviewDetails from './StandingInvoiceBookingPreviewDetails';
import { mapIncomingInvoiceToInvoiceCreate } from './bookingPreviewMapper';

type Props = {
  incomingInvoice: TInvoice;
  toggleBookingPreviewModal: () => void;
};

const BookingPreviewForDetailsPage: FC<Props> = ({ incomingInvoice, toggleBookingPreviewModal }) => {
  const input = mapIncomingInvoiceToInvoiceCreate(incomingInvoice);

  const content = isBillingTypeEinzelrechnung(incomingInvoice.billingType.value) ? (
    <>
      {!(incomingInvoice as SingleInvoice).abgrenzungsBuchung ? (
        <SingleInvoiceDirectBookingPreviewDetails input={input as SingleInvoiceCreateInput} />
      ) : (
        <SingleInvoiceAccrualBookingPreviewDetails input={input as SingleInvoiceCreateInput} />
      )}
    </>
  ) : (
    <StandingInvoiceBookingPreviewDetails input={input as StandingInvoiceCreateInput} />
  );

  return (
    <>
      {content}
      <Space direction="vertical" style={{ width: '100%' }}>
        <Divider style={{ margin: 8 }} />
        <ButtonsAligned usage={ButtonsAlignedUsage.drawerFooter}>
          <Button type="primary" onClick={toggleBookingPreviewModal}>
            Schließen
          </Button>
        </ButtonsAligned>
      </Space>
    </>
  );
};

export default BookingPreviewForDetailsPage;
