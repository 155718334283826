import React, { FC } from 'react';
import { FieldArray, Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Button, Col, Collapse, Row, Skeleton, Space } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import FormButtons from '../../../../../components/Button/FormButtons';
import { Budgeting, BudgetingVPos, VorschreibungspositionArt } from '../../../../../types';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import { Spacer } from '../../../../../components/Grid';
import { useBudgetingConfigureFinancialPlanTextsMutation } from '../../../gql/BudgetingMutations.types';
import { showSuccessMsgOther } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import {
  createFinancialPlanTextsExpenseListFormValues,
  financialPlanTextsExpenseListFormValues,
  mapFormValuesToTextinfoWirtschaftsplan,
  mapTextinfoWirtschaftsplanToFormValues,
  TextinfoWirtschaftsplanFormValues,
  textinfoWirtschaftsplanFormValuesFormFields,
} from './textinfoWirtschaftsplanFormMapper';
import FormattedDecimalFormInput from '../../../../../components/Input-antd/FormattedDecimalFormInput';
import { StyledCollapsePanel } from '../../../../Abrechnungsdefinition/styled/Collapse.styles';
import { useFinancialPlanTextsQuery } from '../../../gql/BudgetingQueries.types';
import { TextbausteinCollapsePanelHeader } from '../../../../../components/Template/PDFTemplates/TextbausteinList/TextbausteinListElements/TextbausteinCollapsePanelHeader';
import { textinfoWirtschaftsplanFormValidationSchema } from './textinfoWirtschaftsplanFormValidationSchema';

type Props = {
  budgeting: Budgeting;
  budgetingVPos: BudgetingVPos;
  onSuccess: () => void;
  onCancel: () => void;
};

const TextinfoWirtschaftsplanForm: FC<Props> = ({ budgeting, budgetingVPos, onSuccess, onCancel }) => {
  const {
    budgetingId,
    objekt: { objektId },
  } = budgeting;

  const {
    budgetingVPosId,
    art: { value: budgetingVPosArt },
  } = budgetingVPos;

  const isVPosRepfonds = budgetingVPosArt === VorschreibungspositionArt.DotRepFond;

  const { data, loading } = useFinancialPlanTextsQuery({
    variables: {
      budgetingId,
      objektId,
      budgetingVPosId,
    },
  });

  const financialPlanTexts = data?.getFinancialPlanTexts.data;

  const [runAction, { loading: loadingAction }] = useBudgetingConfigureFinancialPlanTextsMutation({
    onCompleted: () => {
      showSuccessMsgOther('Textinfo Wirtschaftsplan aktualisiert');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Skeleton active paragraph loading={loading}>
      <Formik<TextinfoWirtschaftsplanFormValues>
        initialValues={mapTextinfoWirtschaftsplanToFormValues(budgetingVPos, isVPosRepfonds, financialPlanTexts)}
        validationSchema={textinfoWirtschaftsplanFormValidationSchema}
        onSubmit={(value, { setSubmitting }) => {
          runAction({
            variables: {
              budgetingId,
              objektId,
              input: mapFormValuesToTextinfoWirtschaftsplan(value, isVPosRepfonds),
            },
          }).finally(() => setSubmitting(false));
        }}
      >
        {(formikProps) => (
          <Form layout="vertical">
            <Collapse bordered ghost defaultActiveKey={[1, 2, 3]}>
              <StyledCollapsePanel
                key={1}
                header={
                  <TextbausteinCollapsePanelHeader
                    isTextbausteinComplete={!!formikProps.values.einleitungsText.length}
                    title="Einleitungstext"
                    onClick={() => {}}
                  />
                }
                showArrow={false}
              >
                <Spacer />
                <FormItemWithoutColon name={textinfoWirtschaftsplanFormValuesFormFields.einleitungsText}>
                  <Input.TextArea
                    id={textinfoWirtschaftsplanFormValuesFormFields.einleitungsText}
                    name={textinfoWirtschaftsplanFormValuesFormFields.einleitungsText}
                    rows={4}
                  />
                </FormItemWithoutColon>
              </StyledCollapsePanel>
              {isVPosRepfonds && (
                <StyledCollapsePanel
                  key={2}
                  header={
                    <TextbausteinCollapsePanelHeader
                      isTextbausteinComplete={!!formikProps.values.expenseList.length}
                      title="Ausgabetabelle"
                      onClick={() => {}}
                      warningText="Sie müssen die Ausgabetabelle noch befüllen"
                    />
                  }
                  showArrow={false}
                >
                  <FieldArray
                    name={textinfoWirtschaftsplanFormValuesFormFields.expenseList}
                    render={(arrayHelpers) => {
                      return (
                        <>
                          {!formikProps.values.expenseList.length && (
                            <>
                              <Spacer />
                              <Button
                                icon={<PlusOutlined />}
                                type="dashed"
                                htmlType="button"
                                block
                                onClick={() => {
                                  arrayHelpers.push(createFinancialPlanTextsExpenseListFormValues());
                                }}
                              >
                                Ausgabe hinzufügen
                              </Button>
                            </>
                          )}
                          {formikProps.values.expenseList.map((pos, index) => (
                            <Row gutter={16} key={index}>
                              <Col span={10}>
                                <FormItemWithoutColon
                                  name={`${textinfoWirtschaftsplanFormValuesFormFields.expenseList}[${index}].${financialPlanTextsExpenseListFormValues.name}`}
                                  label="Bezeichnung"
                                  labelAlign="left"
                                >
                                  <Input
                                    id={`${textinfoWirtschaftsplanFormValuesFormFields.expenseList}[${index}].${financialPlanTextsExpenseListFormValues.name}`}
                                    name={`${textinfoWirtschaftsplanFormValuesFormFields.expenseList}[${index}].${financialPlanTextsExpenseListFormValues.name}`}
                                  />
                                </FormItemWithoutColon>
                              </Col>
                              <Col span={10}>
                                <FormItemWithoutColon
                                  name={`${textinfoWirtschaftsplanFormValuesFormFields.expenseList}[${index}].${financialPlanTextsExpenseListFormValues.amount}`}
                                  label="Betrag"
                                  labelAlign="left"
                                >
                                  <FormattedDecimalFormInput
                                    id={`${textinfoWirtschaftsplanFormValuesFormFields.expenseList}[${index}].${financialPlanTextsExpenseListFormValues.amount}`}
                                    name={`${textinfoWirtschaftsplanFormValuesFormFields.expenseList}[${index}].${financialPlanTextsExpenseListFormValues.amount}`}
                                    isCurrency
                                    size="middle"
                                    style={{ width: '100%' }}
                                  />
                                </FormItemWithoutColon>
                              </Col>
                              <Col span={4}>
                                <Space style={{ marginTop: 27 }}>
                                  <Button
                                    size="small"
                                    shape="circle"
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                      arrayHelpers.insert(index + 1, createFinancialPlanTextsExpenseListFormValues());
                                    }}
                                  />
                                  <Button
                                    size="small"
                                    shape="circle"
                                    icon={<MinusOutlined />}
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                    }}
                                  />
                                </Space>
                              </Col>
                            </Row>
                          ))}
                        </>
                      );
                    }}
                  />
                </StyledCollapsePanel>
              )}
              <StyledCollapsePanel
                key={3}
                header={
                  <TextbausteinCollapsePanelHeader
                    isTextbausteinComplete={!!formikProps.values.schlussText.length}
                    title="Schlusstext"
                    onClick={() => {}}
                  />
                }
                showArrow={false}
              >
                <Spacer />
                <FormItemWithoutColon name={textinfoWirtschaftsplanFormValuesFormFields.schlussText}>
                  <Input.TextArea
                    id={textinfoWirtschaftsplanFormValuesFormFields.schlussText}
                    name={textinfoWirtschaftsplanFormValuesFormFields.schlussText}
                    rows={4}
                  />
                </FormItemWithoutColon>
              </StyledCollapsePanel>
            </Collapse>
            <FormButtons updateMode onCancel={onCancel} isOkLoading={loadingAction} />
          </Form>
        )}
      </Formik>
    </Skeleton>
  );
};

export default TextinfoWirtschaftsplanForm;
