import React from 'react';
import { Layout, Row, Space } from 'antd';
import { Outlet } from 'react-router';
import { Link } from 'react-router-dom';
import { Spacer } from '../../components/Grid';
import { registerFonts } from './helpers/assetHelper';
import {
  URI_APP_PDF_FINANCIAL_PLAN_OBJEKT,
  URI_APP_PDF_FINANCIAL_PLAN_TOPS,
  URI_APP_PDF_HAUSEIGENTUMER_ABRECHNUNG,
  URI_APP_PDF_MAHNUNG,
  URI_APP_PDF_OBJEKT_ABRECHNUNG,
  URI_APP_PDF_OFFENE_POSTEN_LIST_DEBITOREN,
  URI_APP_PDF_SEPA_LASTSCHRIFT_MANDAT,
  URI_APP_PDF_SUB_ADMINISTRATION_OBJEKT_ABRECHNUNG,
  URI_APP_PDF_SUB_ADMINISTRATION_TOP_ABRECHNUNG,
  URI_APP_PDF_TOP_ABRECHNUNG,
  URI_APP_PDF_VORSCHREIBUNG_DAUERRECHNUNG,
  URI_APP_PDF_VORSCHREIBUNG_DAUERRECHNUNG_STORNO,
  URI_APP_PDF_VORSCHREIBUNG_EINZEL,
  URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_GUTSCHRIFT,
  URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_GUTSCHRIFT_STORNO,
  URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_RECHNUNG,
  URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_RECHNUNG_STORNO,
  URI_APP_PDF_VORSCHREIBUNG_EINZEL_STORNO,
  URI_APP_PDF_ZINSLISTE,
} from '../../constants/pdfUriPaths';

registerFonts();

const PDFPage = () => (
  <Layout style={{ height: '100%' }}>
    <Layout.Content>
      <Spacer />
      <Row>
        <Space direction="vertical" size={4}>
          <Space>
            <Link to={URI_APP_PDF_OBJEKT_ABRECHNUNG}>Objektabrechnung</Link>
            <Link to={URI_APP_PDF_TOP_ABRECHNUNG}>Topabrechnung</Link>
            <Link to={URI_APP_PDF_MAHNUNG}>Mahnung</Link>
            <Link to={URI_APP_PDF_HAUSEIGENTUMER_ABRECHNUNG}>Hauseigentümerabrechnung</Link>
            <Link to={URI_APP_PDF_SUB_ADMINISTRATION_OBJEKT_ABRECHNUNG}>Subverwaltungsabrechnung Objekt</Link>
            <Link to={URI_APP_PDF_SUB_ADMINISTRATION_TOP_ABRECHNUNG}>Subverwaltungsabrechnung Top</Link>
          </Space>
          <Space>
            <Link to={URI_APP_PDF_VORSCHREIBUNG_DAUERRECHNUNG}>VorschreibungDauerRg</Link>
            <Link to={URI_APP_PDF_VORSCHREIBUNG_DAUERRECHNUNG_STORNO}>VorschreibungDauerRgStorno</Link>
            <Link to={URI_APP_PDF_VORSCHREIBUNG_EINZEL}>VorschreibungEinzel</Link>
            <Link to={URI_APP_PDF_VORSCHREIBUNG_EINZEL_STORNO}>VorschreibungEinzelStorno</Link>
            <Link to={URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_RECHNUNG}>VorschreibungEinzelAbrErgRechnung</Link>
            <Link to={URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_RECHNUNG_STORNO}>VorschreibungEinzelAbrErgRechnungStorno</Link>
            <Link to={URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_GUTSCHRIFT}>VorschreibungEinzelAbrErgGutschrift</Link>
            <Link to={URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_GUTSCHRIFT_STORNO}>VorschreibungEinzelAbrErgGutschriftStorno</Link>
          </Space>
          <Space>
            <Link to={URI_APP_PDF_ZINSLISTE}>Zinsliste</Link>
            <Link to={URI_APP_PDF_FINANCIAL_PLAN_OBJEKT}>Wirtschaftsplan Objekt</Link>
            <Link to={URI_APP_PDF_FINANCIAL_PLAN_TOPS}>Wirtschaftsplan Tops</Link>
            <Link to={URI_APP_PDF_OFFENE_POSTEN_LIST_DEBITOREN}>Offene Posten List Debitoren</Link>
            <Link to={URI_APP_PDF_SEPA_LASTSCHRIFT_MANDAT}>SEPA Mandat (Lastschrift)</Link>
          </Space>
        </Space>
      </Row>
      <Spacer />
      <Outlet />
    </Layout.Content>
  </Layout>
);

export default PDFPage;
