import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../styles/pdfStyles';
import { Abrechnungskreis } from '../../../../objektAbrechnung-types';

const OAAbrKreisSummeEinnahmen: FC<{ abrechKreis: Abrechnungskreis; isRepFonds?: boolean }> = ({ abrechKreis, isRepFonds = false }) => (
  <View style={[pdfStyles.row, pdfStyles.tablePaddingLeftRight, { marginBottom: '2mm' }]}>
    {isRepFonds ? null : <View style={[pdfStyles.column, { width: '55%' }]} />}

    <View style={[pdfStyles.row, pdfStyles.borderTopSmall, pdfStyles.borderBottomSmall, { width: '45%' }]}>
      <Text style={[pdfStyles.column, pdfStyles.textBulletin, { width: '80%', marginTop: '2mm', fontWeight: 'bold' }]}>
        {abrechKreis.summeNettoEinnahmenText}
      </Text>
      <Text
        style={[
          pdfStyles.column,
          pdfStyles.textBulletin,
          { width: '20%', marginTop: '2mm', alignItems: 'flex-end', textAlign: 'right', fontWeight: 'bold' },
        ]}
      >
        {abrechKreis.summeNettoEinnahmen}
      </Text>
    </View>
    {isRepFonds ? <View style={[pdfStyles.column, { width: '55%' }]} /> : null}
  </View>
);

export default OAAbrKreisSummeEinnahmen;
