import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttributeFieldsFragmentDoc } from './AuftragVerbuchenGenerierlaufEntryFragments.types';
import { GenerierlaufAsyncStepFieldsFragmentDoc } from '../shared/generierlaufFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuftragVerbuchenGenerierlaufQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type AuftragVerbuchenGenerierlaufQuery = {
  getAuftragVerbuchenGenerierlauf: {
    data: {
      countFehler: number;
      countVerarbeitet: number;
      countNichtVerarbeitet: number;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      generierDatum: string;
      generierlaufId: string;
      errorList: Array<{ message: string; type: string }>;
      status: { description?: string | null; value: Types.GenerierlaufStatus; text: string };
      warningList: Array<{ message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AuftragVerbuchenGenerierlaufEntryListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  fakturierungsperiode?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechnungsausstellerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechnungsempfaengerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  exitCodeList?: Types.InputMaybe<Array<Types.GenerierlaufEntryExitCode> | Types.GenerierlaufEntryExitCode>;
  order?: Types.InputMaybe<Types.AuftragVerbuchenGenerierlaufOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type AuftragVerbuchenGenerierlaufEntryListQuery = {
  getAuftragVerbuchenGenerierlaufEntryList: {
    data: {
      contentList: Array<{
        buchungsdatum?: string | null;
        currentStep?: number | null;
        generierlaufEntryId: string;
        infoMessage?: string | null;
        retry: boolean;
        stepCount?: number | null;
        entryDetailsList: Array<
          | {
              auftragId: string;
              belegId: string;
              belegFileId: string;
              belegType: string;
              belegdatum: string;
              belegnummer: string;
              buchungIdList: Array<string>;
              sumBrutto: number;
              sumNetto: number;
              sumUst: number;
              type: Types.AbstractAuftragVerbuchenGenerierlaufEntryDetailsType;
            }
          | {
              belegId: string;
              belegFileId: string;
              belegType: string;
              belegdatum: string;
              belegnummer: string;
              buchungIdList: Array<string>;
              sumBrutto: number;
              sumNetto: number;
              sumUst: number;
              type: Types.AbstractAuftragVerbuchenGenerierlaufEntryDetailsType;
            }
        >;
        errorList: Array<{ type: string; message: string }>;
        exitCode?: { value: Types.GenerierlaufEntryExitCode; text: string } | null;
        fakturierungsperiode: { jahr: number; monat: number };
        rechnungsaussteller: { rechtstraegerId: string; kurzBezeichnung: string; type: { value: Types.RechtstraegerType; text: string } };
        rechnungsempfaenger: { rechtstraegerId: string; kurzBezeichnung: string; type: { value: Types.RechtstraegerType; text: string } };
        stepList: Array<{
          designation: string;
          receivingApplication?: string | null;
          stepId: string;
          type?: string | null;
          status: { description?: string | null; text: string; value: Types.GenerierlaufAsyncStepStatus };
        }>;
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { size: number; number: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const AuftragVerbuchenGenerierlaufDocument = gql`
  query AuftragVerbuchenGenerierlauf($generierlaufId: ID!) {
    getAuftragVerbuchenGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        countFehler
        countVerarbeitet
        countNichtVerarbeitet
        createTs
        createdBy
        createdByMitarbeiterId
        errorList {
          message
          type
        }
        generierDatum
        generierlaufId
        status {
          description
          value
          text
        }
        warningList {
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useAuftragVerbuchenGenerierlaufQuery(
  baseOptions: Apollo.QueryHookOptions<AuftragVerbuchenGenerierlaufQuery, AuftragVerbuchenGenerierlaufQueryVariables> &
    ({ variables: AuftragVerbuchenGenerierlaufQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuftragVerbuchenGenerierlaufQuery, AuftragVerbuchenGenerierlaufQueryVariables>(
    AuftragVerbuchenGenerierlaufDocument,
    options
  );
}
export function useAuftragVerbuchenGenerierlaufLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuftragVerbuchenGenerierlaufQuery, AuftragVerbuchenGenerierlaufQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuftragVerbuchenGenerierlaufQuery, AuftragVerbuchenGenerierlaufQueryVariables>(
    AuftragVerbuchenGenerierlaufDocument,
    options
  );
}
export function useAuftragVerbuchenGenerierlaufSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AuftragVerbuchenGenerierlaufQuery, AuftragVerbuchenGenerierlaufQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuftragVerbuchenGenerierlaufQuery, AuftragVerbuchenGenerierlaufQueryVariables>(
    AuftragVerbuchenGenerierlaufDocument,
    options
  );
}
export type AuftragVerbuchenGenerierlaufQueryHookResult = ReturnType<typeof useAuftragVerbuchenGenerierlaufQuery>;
export type AuftragVerbuchenGenerierlaufLazyQueryHookResult = ReturnType<typeof useAuftragVerbuchenGenerierlaufLazyQuery>;
export type AuftragVerbuchenGenerierlaufSuspenseQueryHookResult = ReturnType<typeof useAuftragVerbuchenGenerierlaufSuspenseQuery>;
export type AuftragVerbuchenGenerierlaufQueryResult = Apollo.QueryResult<
  AuftragVerbuchenGenerierlaufQuery,
  AuftragVerbuchenGenerierlaufQueryVariables
>;
export const AuftragVerbuchenGenerierlaufEntryListDocument = gql`
  query AuftragVerbuchenGenerierlaufEntryList(
    $generierlaufId: ID!
    $fakturierungsperiode: String
    $rechnungsausstellerId: String
    $rechnungsempfaengerId: String
    $exitCodeList: [GenerierlaufEntryExitCode!]
    $order: AuftragVerbuchenGenerierlaufOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getAuftragVerbuchenGenerierlaufEntryList(
      generierlaufId: $generierlaufId
      fakturierungsperiode: $fakturierungsperiode
      rechnungsausstellerId: $rechnungsausstellerId
      rechnungsempfaengerId: $rechnungsempfaengerId
      exitCodeList: $exitCodeList
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          entryDetailsList {
            ...AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttributeFields
          }
          buchungsdatum
          currentStep
          errorList {
            type
            message
          }
          exitCode {
            value
            text
          }
          fakturierungsperiode {
            jahr
            monat
          }
          generierlaufEntryId
          infoMessage
          retry
          rechnungsaussteller {
            rechtstraegerId
            type {
              value
              text
            }
            kurzBezeichnung
          }
          rechnungsempfaenger {
            rechtstraegerId
            type {
              value
              text
            }
            kurzBezeichnung
          }
          stepCount
          stepList {
            ...GenerierlaufAsyncStepFields
          }
          warningList {
            message
            type
          }
        }
        page {
          size
          number
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AbstractAuftragVerbuchenGenerierlaufEntryDetailsAttributeFieldsFragmentDoc}
  ${GenerierlaufAsyncStepFieldsFragmentDoc}
`;
export function useAuftragVerbuchenGenerierlaufEntryListQuery(
  baseOptions: Apollo.QueryHookOptions<AuftragVerbuchenGenerierlaufEntryListQuery, AuftragVerbuchenGenerierlaufEntryListQueryVariables> &
    ({ variables: AuftragVerbuchenGenerierlaufEntryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuftragVerbuchenGenerierlaufEntryListQuery, AuftragVerbuchenGenerierlaufEntryListQueryVariables>(
    AuftragVerbuchenGenerierlaufEntryListDocument,
    options
  );
}
export function useAuftragVerbuchenGenerierlaufEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuftragVerbuchenGenerierlaufEntryListQuery, AuftragVerbuchenGenerierlaufEntryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuftragVerbuchenGenerierlaufEntryListQuery, AuftragVerbuchenGenerierlaufEntryListQueryVariables>(
    AuftragVerbuchenGenerierlaufEntryListDocument,
    options
  );
}
export function useAuftragVerbuchenGenerierlaufEntryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AuftragVerbuchenGenerierlaufEntryListQuery, AuftragVerbuchenGenerierlaufEntryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuftragVerbuchenGenerierlaufEntryListQuery, AuftragVerbuchenGenerierlaufEntryListQueryVariables>(
    AuftragVerbuchenGenerierlaufEntryListDocument,
    options
  );
}
export type AuftragVerbuchenGenerierlaufEntryListQueryHookResult = ReturnType<typeof useAuftragVerbuchenGenerierlaufEntryListQuery>;
export type AuftragVerbuchenGenerierlaufEntryListLazyQueryHookResult = ReturnType<typeof useAuftragVerbuchenGenerierlaufEntryListLazyQuery>;
export type AuftragVerbuchenGenerierlaufEntryListSuspenseQueryHookResult = ReturnType<typeof useAuftragVerbuchenGenerierlaufEntryListSuspenseQuery>;
export type AuftragVerbuchenGenerierlaufEntryListQueryResult = Apollo.QueryResult<
  AuftragVerbuchenGenerierlaufEntryListQuery,
  AuftragVerbuchenGenerierlaufEntryListQueryVariables
>;
