import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { TopAbrechnung } from '../../topAbrechnung-types';

const TAAbrKreisDetailsBezeichnung: FC<{ data: TopAbrechnung }> = ({ data }) => (
  <View>
    {data.deckblatt.abrechnungskreisSummary.abrechnungskreisList.map((abrechKreis, index) => (
      <Text style={[pdfStyles.textNormal, { fontWeight: 'bold', textAlign: 'center' }]} key={index}>
        {abrechKreis.bezeichnung}
      </Text>
    ))}
  </View>
);

export default TAAbrKreisDetailsBezeichnung;
