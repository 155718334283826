import React, { FC } from 'react';
import { Modal } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import { Form } from 'formik-antd';
import FormButtons from '../../../../components/Button/FormButtons';
import { useCreateZinslisteMutation } from '../../gql/ZinslisteMutations.types';
import { showSuccessMsgOther } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { generatePathToZinslisteDetailsPage } from '../../zinslisteUriPaths';
import { showSuccessMsgCreateWithPath } from '../../../../components/message/message';
import {
  mapVorschreibungFormValueToObjektZinslisteCreate,
  ObjektZinslisteCreateFormValues,
  objektZinslisteFormFields,
  objektZinslisteInitialValues,
} from './objektZinslisteCreateFormMapper';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import { zinslisteCreateForObjektCreateValidationSchema } from './zinslisteCreateValidationSchema';
import MonthPicker from '../../../../components/DatePicker/MonthPicker/MonthPicker';

type Props = {
  objektId: string;
  onCreate: () => void;
  onCancel: () => void;
  visible: boolean;
};

const ObjektZinslisteCreateModal: FC<Props> = ({ objektId, onCreate, onCancel, visible }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'ZinslisteGenerierlauf'>('ZinslisteGenerierlauf');

  const [runCreateZinsliste, { loading }] = useCreateZinslisteMutation({
    onCompleted: (data) => {
      const { zinslisteId, vorschreibungsperiode, objektKurzBezeichnung } = data.createZinsliste.data.kopfdaten;
      const entity = 'Zinsliste';
      const title = `${objektKurzBezeichnung} zur Periode ${vorschreibungsperiode.jahr}/${vorschreibungsperiode.monat}`;
      objektId
        ? showSuccessMsgCreateWithPath(entity, title, generatePathToZinslisteDetailsPage(objektId, zinslisteId))
        : showSuccessMsgOther(`${entity} für ${title} erfolgreich angelegt.`);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const handleCreate = (values: ObjektZinslisteCreateFormValues, actions: FormikHelpers<ObjektZinslisteCreateFormValues>) => {
    runCreateZinsliste({
      variables: { objektId, vorschreibungsPeriode: mapVorschreibungFormValueToObjektZinslisteCreate(values) },
    })
      .then(onCreate)
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <Modal title="Zinsliste erstellen" open={visible} footer={null} onCancel={onCancel} destroyOnClose maskClosable={false}>
      <Formik<ObjektZinslisteCreateFormValues>
        initialValues={objektZinslisteInitialValues(objektId)}
        validationSchema={zinslisteCreateForObjektCreateValidationSchema}
        onSubmit={handleCreate}
      >
        {(formikProps) => (
          <Form layout="vertical">
            <FormItemWithFieldHelp
              name={objektZinslisteFormFields.vorschreibungsperiode}
              label="Vorschreibungsperiode"
              fieldHelp={getFieldHelpText('ZinslisteGenerierlauf.vorschreibungsperiodeList')}
            >
              <MonthPicker placeholder="Periode auswählen" name={objektZinslisteFormFields.vorschreibungsperiode} style={{ width: '100%' }} />
            </FormItemWithFieldHelp>
            <FormButtons updateMode={false} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} isOkLoading={loading} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ObjektZinslisteCreateModal;
