import React, { FC } from 'react';
import { ObjektBestandseinheitId } from '../../../types';
import { useBestandseinheitBezeichnungQuery } from '../../Bestandseinheit/gql/BestandseinheitQueries.types';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';

type ObjektTopContentProps = {
  objektBestandseinheit: ObjektBestandseinheitId;
  maxTextLength?: number;
  showCompleteText?: boolean;
  useInTooltipTitle?: boolean;
};

const ObjektTopBezeichnung: FC<ObjektTopContentProps> = ({ objektBestandseinheit, maxTextLength, useInTooltipTitle, showCompleteText = false }) => {
  const { data } = useBestandseinheitBezeichnungQuery({
    variables: {
      objektId: objektBestandseinheit.objektId,
      bestandseinheitId: objektBestandseinheit.bestandseinheitId,
      showObjekt: true,
    },
  });

  const bestandseinheitBezeichnung = data?.getBestandseinheitBezeichnung.data;

  if (bestandseinheitBezeichnung) {
    return showCompleteText ? (
      <div>{bestandseinheitBezeichnung}</div>
    ) : (
      <DataWithShortenedText
        text={bestandseinheitBezeichnung}
        maxTextLength={maxTextLength ?? bestandseinheitBezeichnung.length}
        useInTooltip={useInTooltipTitle}
      />
    );
  } else return <TextForEmptyValue textToShow="NV" />;
};

export default ObjektTopBezeichnung;
