import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Dayjs } from 'dayjs';
import { Mitarbeiter, useAuth, User } from '../../shared/Auth/authContext';
import { getNextAvailableDateFromToday } from '../../helpers/dateHelper';
import { dayjsCustom } from '../../helpers/dayjsCustom';

// Backend entities will be mapped into entities of this type. Basically backend types will be extended with specific UI attributes needed for UI interaction
export type ITimeblock<T extends IVersionable<T>> = Omit<T, 'historicizedList'> & { historicizedList: ITimeblock<T>[] } & TimeblockUIAttributes;

// Specific backend types which will be used in the versionTimeline feature has to satisfy this contract
// historicizedList: has to be always defined as optional in GQL because otherwise recursion will not work for TS type
export interface IVersionable<T extends IVersionable<T>> {
  validFrom: string;
  historicizedList?: T[] | null;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  current: boolean;
  deletable?: boolean | null;
  lastUpdateTs: string;
}

// UI attributes needed for UI interaction
type TimeblockUIAttributes = {
  uuid: string;
  isCollapsed: boolean;
  edit: boolean;
};

export type NewTimeblock<T extends IVersionable<T>> = Omit<T, keyof IVersionable<T>>;

export type VersionTimelineOnDelete<T extends IVersionable<T>> = (timeblock: ITimeblock<T>) => Promise<unknown>;

export interface IVersionableFormValues {
  validFrom: string;
}

interface TimelineProviderProps<T extends IVersionable<T>> {
  dataSource: T[];
  versionIdKey: keyof T;
  defaultNewTimeblock: NewTimeblock<T>;
  children: React.ReactNode;
}

export enum TimelineMode {
  create,
  update,
}

function TimelineProvider<T extends IVersionable<T>>({ dataSource, versionIdKey, defaultNewTimeblock, children }: TimelineProviderProps<T>) {
  const [timeline, setTimeline] = useState<ITimeblock<T>[]>([]);
  const [timelineMode, setTimelineMode] = useState<TimelineMode>(TimelineMode.update);
  const { user, mitarbeiter } = useAuth();

  if (!user) {
    throw new Error(`TimelineProvider requires a user who is already logged in. user: ${user}`);
  }

  const sortedTimeline = sortByDateDesc(timeline);

  useEffect(() => {
    const timelineDataSource = mapToTimelineDataSource(dataSource);
    const mappedDataSource =
      timelineMode === TimelineMode.create
        ? [createNewTimeblock(timelineDataSource, versionIdKey, defaultNewTimeblock, user, mitarbeiter), ...timelineDataSource]
        : timelineDataSource;
    setTimeline(mappedDataSource);
  }, [dataSource, timelineMode, versionIdKey, defaultNewTimeblock, user, mitarbeiter]);

  const toggleCollapsed = (uuid: string) => {
    const toggleCollapsedRecursive = (timeline: ITimeblock<T>[], uuid: string): ITimeblock<T>[] =>
      timeline.map((item) => {
        if (item.uuid === uuid) {
          return { ...item, isCollapsed: !item.isCollapsed };
        } else {
          if (item.historicizedList && item.historicizedList.length > 0) {
            return {
              ...item,
              historicizedList: toggleCollapsedRecursive(item.historicizedList, uuid),
            };
          }
          return item;
        }
      });

    setTimeline((prevState) => toggleCollapsedRecursive(prevState, uuid));
  };

  const toggleEdit = (uuid: string) => {
    setTimeline((prevState) => prevState.map((item) => (item.uuid === uuid ? { ...item, edit: !item.edit } : item)));
  };

  const onValidFromChange = (uuid: string, validFrom: Dayjs) => {
    setTimeline((prevState) =>
      prevState.map((item) => (item.uuid === uuid ? { ...item, validFrom: dayjsCustom(validFrom).format('YYYY-MM-DD') } : item))
    );
  };

  const changeToUpdateMode = (uuid: string) => {
    setTimeline((prevState) => prevState.filter((item) => item.uuid !== uuid));
    setTimelineMode(TimelineMode.update);
  };

  const changeToCreateMode = () => {
    setTimelineMode(0);
  };

  return (
    <TimelineContextProvider
      value={{
        dataSource: sortedTimeline,
        toggleCollapsed,
        toggleEdit,
        onValidFromChange,
        changeToUpdateMode,
        changeToCreateMode,
        timelineMode,
      }}
    >
      {children}
    </TimelineContextProvider>
  );
}

type TimelineContextType<T extends IVersionable<T>> = {
  dataSource: ITimeblock<T>[];
  toggleCollapsed: (uuid: string) => void;
  toggleEdit: (uuid: string) => void;
  onValidFromChange: (uuid: string, validFrom: Dayjs) => void;
  changeToUpdateMode: (uuid: string) => void;
  changeToCreateMode: () => void;
  timelineMode: TimelineMode;
};

const TimelineContext = React.createContext<TimelineContextType<any>>(undefined as any);

export const TimelineContextProvider = TimelineContext.Provider;

export function useTimeline<T extends IVersionable<T>>() {
  const c = React.useContext<TimelineContextType<T>>(TimelineContext);
  if (!c) throw new Error('useCtx must be inside a Provider with a value');
  return c;
}

function mapToTimelineDataSource<T extends IVersionable<T>>(dataSource: T[]): ITimeblock<T>[] {
  return dataSource.map((item) => ({
    ...item,
    historicizedList: item.historicizedList ? mapToTimelineDataSource(item.historicizedList) : [],
    ...{ uuid: uuidv4(), isCollapsed: !item.current, edit: false },
  }));
}

const NEW_TIMEBLOCK_UUID = 'new-timeblock';

function createNewTimeblock<T extends IVersionable<T>>(
  timeline: ITimeblock<T>[],
  versionIdKey: keyof T,
  defaultNewTimeblock: NewTimeblock<T>,
  currentUser: User,
  currentMitarbeiter?: Mitarbeiter
): ITimeblock<T> {
  const newTimeblockValidFrom = getNextAvailableDateFromToday(getNotAvailableDates(timeline));
  const previousTimeblock = getPreviousTimeblockBefore(timeline, newTimeblockValidFrom);

  const versionableObjectAttrs: IVersionable<T> = {
    createTs: '',
    createdBy: currentUser.username,
    createdByMitarbeiterId: currentMitarbeiter?.mitarbeiterId,
    current: false,
    validFrom: dayjsCustom(newTimeblockValidFrom).format('YYYY-MM-DD'),
    historicizedList: [],
    lastUpdateTs: dayjsCustom().format('YYYY-MM-DD HH:mm:ss'),
    deletable: false, // new entry cannot be deleted because it does not exist yet
  };

  const versionTimelineUIAttrs: TimeblockUIAttributes = {
    uuid: NEW_TIMEBLOCK_UUID,
    isCollapsed: false,
    edit: true,
  };

  // if creating new entry than its versionId should be cleared in order to make a create instead of an update
  const domainSpecificAttrs = previousTimeblock ? ({ ...previousTimeblock, [versionIdKey]: '' } as ITimeblock<T>) : { ...defaultNewTimeblock };

  return {
    ...domainSpecificAttrs,
    ...versionableObjectAttrs, // has to be after domainSpecificAttrs to overwrite common timeblock attributes
    ...versionTimelineUIAttrs,
  } as ITimeblock<T>;
}

// ------- helpers

function getNotAvailableDates<T extends IVersionable<T>>(timeline: ITimeblock<T>[]): Dayjs[] {
  return timeline.map((item) => item.validFrom).map((item) => dayjsCustom(item));
}

function getPreviousTimeblockBefore<T extends IVersionable<T>>(timeline: ITimeblock<T>[], before: Dayjs): ITimeblock<T> | undefined {
  const prevTimeblocks = timeline.filter((item) => dayjsCustom(item.validFrom).isBefore(before, 'day'));
  const prevTimeblocksSortedDesc = sortByDateDesc<T>(prevTimeblocks);
  return prevTimeblocksSortedDesc.length > 0 ? prevTimeblocksSortedDesc[0] : undefined;
}

function getFirstTimeblock<T extends IVersionable<T>>(timeline: ITimeblock<T>[]): ITimeblock<T> {
  const timelineSortedDesc = sortByDateDesc<T>(timeline);
  // timeline should always contain at least 1 element
  return timelineSortedDesc[timelineSortedDesc.length - 1];
}

function sortByDateDesc<T extends IVersionable<T>>(timeline: ITimeblock<T>[]): ITimeblock<T>[] {
  return timeline.sort((a, b) => Date.parse(b.validFrom) - Date.parse(a.validFrom));
}

function isNewTimeblock<T extends IVersionable<T>>(timeblock: ITimeblock<T>): boolean {
  return timeblock.uuid === NEW_TIMEBLOCK_UUID;
}

function isTimeblockInThePast<T extends IVersionable<T>>(timeblock: ITimeblock<IVersionable<T>>) {
  return dayjsCustom(timeblock.validFrom).isBefore(dayjsCustom(), 'day');
}

export { TimelineProvider, getPreviousTimeblockBefore, getFirstTimeblock, isNewTimeblock, isTimeblockInThePast };
