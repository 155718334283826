import React, { FC } from 'react';
import { Button, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../hooks/useToggle';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../components/Button/ButtonsAligned';
import BudgetingCreateForm from './Form/Create/BudgetingCreateForm';

type Props = {
  objektId: string;
  onAction: () => void;
  showArchived: boolean;
  toggleShowArchived: () => void;
};

const BudgetingBtnAndModal: FC<Props> = ({ objektId, onAction, showArchived, toggleShowArchived }) => {
  const [isOpen, toggleIsOpen] = useToggle();

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <Button size="small" onClick={toggleShowArchived}>
          Archivierte Budgetierung {showArchived ? 'ausblenden' : 'anzeigen'}
        </Button>
        <Button type="primary" size="small" icon={<PlusOutlined />} onClick={toggleIsOpen}>
          Budgetierung erstellen
        </Button>
      </ButtonsAligned>
      <Modal title="Budgetierung anlegen" onCancel={toggleIsOpen} open={isOpen} destroyOnClose maskClosable={false} footer={false}>
        <BudgetingCreateForm
          objektId={objektId}
          onCancel={toggleIsOpen}
          onSuccess={() => {
            toggleIsOpen();
            onAction();
          }}
        />
      </Modal>
    </>
  );
};

export default BudgetingBtnAndModal;
