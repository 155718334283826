import React, { FC } from 'react';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import { showSuccessMsgCreate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import FormButtons from '../../../../../components/Button/FormButtons';
import { personenbezugCreateFormFields, personenbezugCreateFormInitialValues, PersonenbezugCreateFormValues } from './personenbezugCreateFormMapper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import RechtstraegerSelect from '../../../../../shared/Rechtstraeger/RechtstraegerSelect';
import { personenbezugValidationSchema } from './personenbezugValidationSchema';
import { useCreatePersonenbezugMutation } from '../../gql/PersonenbezugMutations.types';

type PersonenbezugCreateFormProps = {
  rechtstraegerId: string;
  onAction: () => void;
  rechtstraegerIdList: string[];
};

const PersonenbezugCreateForm: FC<PersonenbezugCreateFormProps> = ({ rechtstraegerId, rechtstraegerIdList, onAction }) => {
  const [createPersonenbezug, { loading }] = useCreatePersonenbezugMutation({
    onCompleted: () => {
      showSuccessMsgCreate(`Personenbezug`);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const isRechtstraegerIdInUse = (id: string) => rechtstraegerIdList.includes(id);

  return (
    <Formik<PersonenbezugCreateFormValues>
      initialValues={personenbezugCreateFormInitialValues}
      validationSchema={personenbezugValidationSchema}
      validate={(values) => {
        return isRechtstraegerIdInUse(values.rechtstraegerId)
          ? { rechtstraegerId: 'Der Personenbezug für diesen Rechtsträger existiert bereits' }
          : {};
      }}
      onSubmit={(values, { setSubmitting }) => {
        createPersonenbezug({
          variables: {
            rechtstraegerId,
            input: {
              personId: values.rechtstraegerId,
            },
          },
        }).finally(() => {
          setSubmitting(false);
        });
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithoutColon name={personenbezugCreateFormFields.rechtstraegerId} label="Person" labelAlign="left">
            <RechtstraegerSelect
              name={personenbezugCreateFormFields.rechtstraegerId}
              placeholder="Person auswählen"
              excludeRechtstraegerId={rechtstraegerId}
              onNewItemCreateSelected={() => formikProps.setFieldValue(personenbezugCreateFormFields.rechtstraegerId, '')}
              onNewItemCreated={(id) => formikProps.setFieldValue(personenbezugCreateFormFields.rechtstraegerId, id)}
              size="small"
            />
          </FormItemWithoutColon>
          <FormButtons updateMode={false} onCancel={onAction} isOkLoading={loading} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default PersonenbezugCreateForm;
