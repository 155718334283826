import styled from 'styled-components';
import { Card, Row } from 'antd';

export const StyledObjBankDetailsCard = styled(Card)`
  padding: 0;
  & > .ant-card-body {
    padding: 0 !important;
  }
`;

export const StyledObjBankDetailsRow = styled(Row)`
  display: flex;
  width: 100%;
  & .ant-col {
    padding: 8px;
  }
  & > :first-child {
    display: flex;
    align-items: center;
  }
  & > :nth-child(2) {
    flex: 1;
  }
`;
