import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Col, Row, Typography } from 'antd';
import FormButtons from '../../../../../../components/Button/FormButtons';
import { streetAddressCreateFormFields, streetAddressCreateFormInitialValues, StreetAddressCreateFormValues } from './streetAddressCreateFormMapper';
import { streetAddressValidationSchema } from '../../../../../../shared/components/Address/form/addressFieldsValidationSchema';
import { showSuccessMsgCreate } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import {
  useCreateFirmaAddressMutation,
  useCreateFirmendatenAddressMutation,
  useCreateNatuerlichePersonAddressMutation,
  useCreatePersonenGemeinschaftAddressMutation,
} from '../../../gql/AddressesAndContactsMutations.types';
import { mapFormValuesToAddress } from '../../../../../../shared/components/Address/form/addressMapper';
import StreetAddressInputs from '../shared/StreetAddressInputs';
import { RechtstraegerType } from '../../../../../../types';

type Props = {
  onSuccess: (addresseId: string) => void;
  onCancel: () => void;
  rechtstraegerType: RechtstraegerType;
  rechtstraegerId?: string;
  operatingSiteId?: string;
};

const StreetAddressCreateForm: FC<Props> = ({ rechtstraegerId, onSuccess, onCancel, rechtstraegerType, operatingSiteId }) => {
  const entity = 'Adresse';

  const [createNatuerlichePersonAddress, { loading: loadingNatuerlichePersonAddress }] = useCreateNatuerlichePersonAddressMutation({
    onCompleted: (data) => {
      showSuccessMsgCreate(entity);
      onSuccess(data.createNatuerlichePersonAddress.data.addressId);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [createPersonenGemeinschaftAddress, { loading: loadingPersonenGemeinschaftAddress }] = useCreatePersonenGemeinschaftAddressMutation({
    onCompleted: (data) => {
      showSuccessMsgCreate(entity);
      onSuccess(data.createPersonenGemeinschaftAddress.data.addressId);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [createFirmaAddress, { loading: loadingFirmaAddress }] = useCreateFirmaAddressMutation({
    onCompleted: (data) => {
      showSuccessMsgCreate(entity);
      onSuccess(data.createFirmaAddress.data.addressId);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [createFirmendatenAddress, { loading: loadingFirmendatenAddress }] = useCreateFirmendatenAddressMutation({
    onCompleted: (data) => {
      showSuccessMsgCreate(entity);
      onSuccess(data.createFirmendatenAddress.data.addressId);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<StreetAddressCreateFormValues>
      initialValues={streetAddressCreateFormInitialValues}
      validationSchema={streetAddressValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const input = mapFormValuesToAddress(values, operatingSiteId);
        if (rechtstraegerId) {
          if (rechtstraegerType === RechtstraegerType.PersonenGemeinschaft) {
            createPersonenGemeinschaftAddress({
              variables: { personenGemeinschaftId: rechtstraegerId, input },
            }).finally(() => setSubmitting(false));
          } else if (rechtstraegerType === RechtstraegerType.NatuerlichePerson) {
            createNatuerlichePersonAddress({
              variables: { natuerlichePersonId: rechtstraegerId, input },
            }).finally(() => setSubmitting(false));
          } else if (rechtstraegerType === RechtstraegerType.Firma) {
            createFirmaAddress({
              variables: { firmaId: rechtstraegerId, input },
            }).finally(() => setSubmitting(false));
          }
        } else {
          createFirmendatenAddress({
            variables: { input },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Adresse</Typography.Title>
            </Col>
            <Col span={18}>
              <StreetAddressInputs formFields={streetAddressCreateFormFields} />
            </Col>
          </Row>
          <FormButtons
            updateMode={false}
            onCancel={onCancel}
            isOkLoading={loadingNatuerlichePersonAddress || loadingPersonenGemeinschaftAddress || loadingFirmaAddress || loadingFirmendatenAddress}
            isOkDisabled={formikProps.isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default StreetAddressCreateForm;
