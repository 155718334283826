import React, { FC } from 'react';
import { Checkbox } from 'antd';
import { useUpdateInfoMailDeliveryMutation } from '../../../gql/InfoMailDeliveryWizardMutations.types';
import { showSuccessMsgUpdate } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { InfoMailDelivery } from '../../../../../../types';

type Props = {
  infoMailDelivery: InfoMailDelivery;
  onUpdate: () => void;
  wizardId: string;
  isCompleted?: boolean;
};

const SendCheckboxColumn: FC<Props> = ({ infoMailDelivery, onUpdate, wizardId, isCompleted }) => {
  const [runUpdate, { loading }] = useUpdateInfoMailDeliveryMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`E-Mail Versand`);
      onUpdate();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Checkbox
      name="shouldSend"
      id="shouldSend"
      onChange={(e) => {
        if (isCompleted) return;
        runUpdate({
          variables: {
            infoMailDeliveryWizardId: wizardId,
            infoMailDeliveryId: infoMailDelivery.infoMailDeliveryId,
            shouldSend: e.target.checked,
          },
        });
      }}
      checked={infoMailDelivery.shouldSend}
      disabled={loading || isCompleted}
    />
  );
};

export default SendCheckboxColumn;
