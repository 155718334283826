import React, { FC } from 'react';
import VstKuerzungErstellenBtnAndModal from './VstKuerzungErstellenBtnAndModal';
import VstKuerzungTable from './Table/VstKuerzungTable';
import { useVorsteuerkuerzungListQuery } from '../gql/VorsteuerkuerzungQueries.types';
import { useAuth } from '../../../shared/Auth/authContext';
import { useGetNotificationSubscription } from '../../Notification/gql/NotificationSubscription.types';
import { NotificationStatus, NotificationType } from '../../../types';
import { useOnVstKuerzungDataChangedEvent } from '../useOnVstKuerzungDataChangedEvent';

type Props = {
  objektId: string;
  objektVerwaltungSeit: string;
};

const VorsteuerkuerzungListing: FC<Props> = ({ objektId, objektVerwaltungSeit }) => {
  const { user, mitarbeiter } = useAuth();
  const username = user?.username;
  const mitarbeiterId = mitarbeiter?.mitarbeiterId;

  const { data, loading, refetch } = useVorsteuerkuerzungListQuery({ variables: { objektId } });

  useGetNotificationSubscription({
    onData: (options) => {
      const notification = options.data.data?.getNotification;

      if (!notification) return;
      const { notificationType, status } = notification;
      if (notificationType !== NotificationType.VorsteuerkuerzungErstellung && status !== NotificationStatus.Finished) return;
      refetch();
    },
    variables: {
      userId: username,
      mitarbeiterId,
    },
  });

  const vstKuerzungList = data?.getVorsteuerkuerzungList.data ?? [];

  useOnVstKuerzungDataChangedEvent(
    'vorsteuerkuerzungList',
    refetch,
    vstKuerzungList.map((vstKuerzung) => vstKuerzung.vorsteuerkuerzungId)
  );

  return (
    <>
      <VstKuerzungErstellenBtnAndModal objektId={objektId} objektVerwaltungSeit={objektVerwaltungSeit} />
      <VstKuerzungTable objektId={objektId} vstKuerzungList={vstKuerzungList} loading={loading} />
    </>
  );
};

export default VorsteuerkuerzungListing;
