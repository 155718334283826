import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { InfoCircleOutlined, LinkOutlined } from '@ant-design/icons';
import { AbstractVPosWertChangeReason, VPosWertChangeReasonBudgeting, VPosWertChangeReasonIndexedValue } from '../../types';
import { pathsToBudgetingDetailsPage } from '../../features/Budgeting/budgetingUriPaths';
import { generatePathToBeVertragDetailsPageVertragsdatenVPosIndexationAgreementTab } from '../../features/Vertrag/BeVertrag/beVertragUriPaths';

type Props = {
  reasonChange?: AbstractVPosWertChangeReason | null;
};

const EntityReasonChange: FC<Props> = ({ reasonChange }) =>
  reasonChange ? (
    <Tooltip placement="top" title={changeReasonDescription(reasonChange)} styles={{ root: { minWidth: '200px', maxWidth: '400px' } }}>
      <InfoCircleOutlined style={{ cursor: 'help' }} />
    </Tooltip>
  ) : null;

const changeReasonDescription = (reasonChange: AbstractVPosWertChangeReason): React.ReactNode => {
  if (isVPosWertChangeReasonBudgeting(reasonChange)) {
    return (
      <Link
        to={
          pathsToBudgetingDetailsPage(reasonChange.objektId, reasonChange.budgetingId, reasonChange.vorschreibungspositionId, {
            bestandseinheitId: reasonChange.bestandseinheitId,
            vertragId: reasonChange.vertragId,
            vorschreibungspositionId: reasonChange.vorschreibungspositionId,
          }).resultsTab
        }
        target="_blank"
        style={{ textDecoration: 'underline', color: 'inherit' }}
      >
        <LinkOutlined /> {reasonChange.text}
      </Link>
    );
  }
  if (isVPosWertChangeReasonIndexedValue(reasonChange)) {
    return (
      <Link
        to={generatePathToBeVertragDetailsPageVertragsdatenVPosIndexationAgreementTab(
          reasonChange.objektId,
          reasonChange.bestandseinheitId,
          reasonChange.vertragId,
          reasonChange.vertragVposIndexedValueId,
          [reasonChange.vorschreibungspositionId, reasonChange.indexationAgreementId]
        )}
        target="_blank"
        style={{ textDecoration: 'underline', color: 'inherit' }}
      >
        <LinkOutlined /> {reasonChange.text}
      </Link>
    );
  }
  return reasonChange.text;
};

const isVPosWertChangeReasonBudgeting = (reasonChange: AbstractVPosWertChangeReason): reasonChange is VPosWertChangeReasonBudgeting => {
  return 'budgetingId' in reasonChange && 'objektId' in reasonChange;
};

const isVPosWertChangeReasonIndexedValue = (reasonChange: AbstractVPosWertChangeReason): reasonChange is VPosWertChangeReasonIndexedValue => {
  return 'vertragVposIndexedValueId' in reasonChange;
};

export default EntityReasonChange;
