import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { VerarbeitungInfoMailDeliverySendDetailsPageRouteParams } from './routes';
import { useInfoMailDeliverySendGenerierlaufQuery } from '../../../features/Verarbeitung/gql/InfoMailDeliverySendGenerierLauf/InfoMailDeliverySendGenerierlaufQueries.types';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import menuListInfoMailDeliverySend from './menuListInfoMailDeliverySend';
import { InfoMailDeliverySendGenerierlauf } from '../../../types';
import { useRestartInfoMailDeliverySendGenerierlaufMutation } from '../../../features/Verarbeitung/gql/InfoMailDeliverySendGenerierLauf/InfoMailDeliverySendGenerierlaufMutations.types';

const InfoMailDeliverySendDetailsPage = () => {
  const { generierlaufId } = useParams() as VerarbeitungInfoMailDeliverySendDetailsPageRouteParams;

  const { data, refetch } = useInfoMailDeliverySendGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getInfoMailDeliverySendGenerierlauf.data;

  const [runRestart] = useRestartInfoMailDeliverySendGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<InfoMailDeliverySendGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.InfoMailDeliverySend}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.InfoMailDeliverySend}
          onGenerierlaufRestart={runRestart}
        />
      )}
      isLoading={false}
      sidebarMenuList={menuListInfoMailDeliverySend}
    />
  );
};

export default InfoMailDeliverySendDetailsPage;
