import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Dropdown, MenuProps, Modal, Space, Typography } from 'antd';
import { DeleteOutlined, EllipsisOutlined, HistoryOutlined } from '@ant-design/icons';
import { showSuccessMsgDelete, showSuccessMsgUpdate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { useDeletePersonenbezugMutation, useSetPersonenbezugTagListMutation } from '../../gql/PersonenbezugMutations.types';
import { generatePathToRechtstraegerDetailsPage } from '../../../rechtstraegerHelper';
import { StyledPersonenbezugListItem, StyledPersonenbezugSpace } from './styled/PersonenbezugStyledElements.style';
import { useRechtstraegerAddressesAndContactsQuery } from '../../../AddressesAndContacts/gql/AddressesAndContactsQueries.types';
import { buildPersonenbezugAddress } from './personenbezugHelpers';
import PersonenbezugListItemTags from './PersonenbezugListItemTags';
import { LoadingCol } from '../../../../../components/Loading';
import { removeHauptTagDuplicatesFromTags } from '../../../../../components/Tag/tagHelpers';
import AuditFooter from '../../../../../components/Footer/AuditFooter';
import { radixActionStyles } from '../../../../../helpers/radixIconsStyles';
import HistoryModal, { HistoryType } from '../../../../History/shared/HistoryModal';
import { useToggle } from '../../../../../hooks/useToggle';
import PersonenbezugChangeHistoryListingTable from './PersonenbezugChangeHistoryListingTable';
import { Personenbezug } from '../../../../../types';

type Props = {
  rechtstraegerId: string;
  item: Personenbezug;
  onSuccess: () => void;
};

const PersonenbezugListItem: FC<Props> = ({ rechtstraegerId, item, onSuccess }) => {
  const [tags, setTags] = useState<string[]>(item.tagList);
  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const { data, loading, refetch } = useRechtstraegerAddressesAndContactsQuery({ variables: { rechtstraegerId: item.person.rechtstraegerId } });
  const addressesAndContacts = data?.getRechtstraegerAddressesAndContacts.data ?? undefined;

  const address = buildPersonenbezugAddress(addressesAndContacts);
  const email = addressesAndContacts?.hauptEmailContact || addressesAndContacts?.emailContactList[0];

  const [runDelete] = useDeletePersonenbezugMutation({
    variables: {
      rechtstraegerId,
      personenbezugId: item.personenbezugId,
    },
    onCompleted: () => {
      showSuccessMsgDelete('Personbezug');
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onDelete = () => {
    runDelete();
    onSuccess();
  };

  const [runSetTagList, { loading: isSetTagListLoading }] = useSetPersonenbezugTagListMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`Tag-Liste`).then(() => {
        refetch();
      });
    },
    onError: () => APOLLO_DUMMY_ERROR_HANDLER,
  });

  const handleTagListChange = (tagList: string[]) => {
    const tagListWithoutHauptTagDuplicates = removeHauptTagDuplicatesFromTags(tagList, false);
    setTags(tagListWithoutHauptTagDuplicates);
    runSetTagList({
      variables: {
        rechtstraegerId,
        personenbezugId: item.personenbezugId,
        input: { tagList: tagListWithoutHauptTagDuplicates },
      },
    });
  };

  const items: MenuProps['items'] = [
    { key: '1', label: 'Löschen', onClick: () => showConfirmDelete(onDelete), icon: <DeleteOutlined />, danger: true },
    {
      key: '2',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
  ];

  const footer = <AuditFooter createTs={item.createTs} userId={item.createdBy} mitarbeiterId={item.createdByMitarbeiterId} />;

  return (
    <StyledPersonenbezugListItem>
      <Space direction="vertical" style={{ width: '100%' }} size={0}>
        <Space style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between' }}>
          <Space style={{ display: 'flex', flex: 1, padding: '8px' }} direction="vertical">
            <StyledPersonenbezugSpace>
              <Typography.Text>Personenbezug:</Typography.Text>
              <Link to={generatePathToRechtstraegerDetailsPage(item.person.rechtstraegerId)}>{item.person.kurzBezeichnung}</Link>
            </StyledPersonenbezugSpace>
            <StyledPersonenbezugSpace>
              <Typography.Text />
              <PersonenbezugListItemTags tags={tags} onTagListChange={handleTagListChange} loading={isSetTagListLoading} />
            </StyledPersonenbezugSpace>
            <StyledPersonenbezugSpace>
              <Typography.Text>E-Mail an:</Typography.Text>
              <LoadingCol isLoading={loading}>
                {email ? <Typography.Text>{email.wert}</Typography.Text> : <Typography.Text type="danger">{NICHT_VORHANDEN}</Typography.Text>}
              </LoadingCol>
            </StyledPersonenbezugSpace>
            <StyledPersonenbezugSpace>
              <Typography.Text>Post an:</Typography.Text>
              <LoadingCol isLoading={loading}>
                {address ? <Typography.Text>{address}</Typography.Text> : <Typography.Text type="secondary">{NICHT_VORHANDEN}</Typography.Text>}
              </LoadingCol>
            </StyledPersonenbezugSpace>
          </Space>
          <div style={{ padding: '8px' }}>
            <Dropdown menu={{ items }}>
              <Button data-testid="card-menu-dropdown" icon={<EllipsisOutlined />} shape="circle" size="small" />
            </Dropdown>
          </div>
        </Space>
        {footer}
      </Space>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <PersonenbezugChangeHistoryListingTable rechtstraegerId={rechtstraegerId} personenbezugId={item.personenbezugId} />
      </HistoryModal>
    </StyledPersonenbezugListItem>
  );
};

const NICHT_VORHANDEN = 'nicht vorhanden';

const showConfirmDelete = (runDelete: () => void) => {
  Modal.confirm({
    title: 'Sind Sie sicher, dass Sie diesen Personenbezug löschen möchten?',
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default PersonenbezugListItem;
