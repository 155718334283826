import React, { FC } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Checkbox, Form, Input } from 'formik-antd';
import { Col, Row, Typography } from 'antd';
import { bankDetailsFormValidationSchema, banknameValidationSchema, bicValidationSchema } from './bankDetailsFormValidationSchema';
import FormButtons from '../../../components/Button/FormButtons';
import {
  bankDetailsFormFields,
  BankDetailsFormValues,
  FDAndRechtstrBankDetailsInput,
  mapBankDetailsToFormValues,
  mapFormValuesToBankDetails,
} from './bankDetailsFormMapper';
import IbanFormPart from './IbanFormPart';
import { validateSyncWithSchemaAndReturnErrorIfAny } from '../../../helpers/validationHelper';
import { BankDetails, FirmendatenBankDetails } from '../../../types';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';
import FieldHelpIconWithTooltip from '../../../components/FieldHelpIcon/FieldHelpIconWithTooltip';

type Props = {
  firmendatenId?: string;
  bankDetails?: BankDetails | FirmendatenBankDetails;
  onSubmit: (bankDetails: FDAndRechtstrBankDetailsInput) => void;
  onCancel: () => void;
  isUpdate: boolean;
  loading: boolean;
  fieldHelpSelectorEntityName: 'BankDetails' | 'FirmendatenBankDetails'; //Important to narrow down the type
  fibuKontoCreatable?: boolean;
};

const BankDetailsForm: FC<Props> = ({
  firmendatenId,
  bankDetails,
  onSubmit,
  onCancel,
  isUpdate,
  loading,
  fieldHelpSelectorEntityName,
  fibuKontoCreatable,
}) => {
  /* FIELD HELP */
  const getFieldHelpTextBankDetails = useGetAppFieldHelpText<'BankDetails'>('BankDetails');
  const getFieldHelpTextFirmendatenBankDetails = useGetAppFieldHelpText<'FirmendatenBankDetails'>('FirmendatenBankDetails');
  const isFieldHelpSelectorEntityNameBankDetails = fieldHelpSelectorEntityName === 'BankDetails';
  /* END OF FIELD HELP */

  const initialValues = mapBankDetailsToFormValues(bankDetails);

  return (
    <Formik<BankDetailsFormValues> initialValues={initialValues} validationSchema={bankDetailsFormValidationSchema} onSubmit={mapAndSubmit(onSubmit)}>
      {(formikProps) => (
        <Form>
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Bankverbindung</Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithFieldHelp
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name={bankDetailsFormFields.accountHolder}
                label="Kontoinhaber"
                fieldHelp={
                  isFieldHelpSelectorEntityNameBankDetails
                    ? getFieldHelpTextBankDetails('BankDetails.accountHolder')
                    : getFieldHelpTextFirmendatenBankDetails('FirmendatenBankDetails.accountHolder')
                }
              >
                <Input id={bankDetailsFormFields.accountHolder} name={bankDetailsFormFields.accountHolder} placeholder="z.B. Fritz Fitzke" />
              </FormItemWithFieldHelp>
              <IbanFormPart
                onValidateIban={(bankData) => {
                  if (bankData && bankData.bic && bankData.bankname) {
                    setFieldValueAndTouchedAndValidate(formikProps, 'bic', bankData.bic, true);
                    setFieldValueAndTouchedAndValidate(formikProps, 'bankname', bankData.bankname, true);
                  } else {
                    setFieldValueAndTouchedAndValidate(formikProps, 'bic', '', false);
                    setFieldValueAndTouchedAndValidate(formikProps, 'bankname', '', false);
                  }
                }}
                fieldHelp={
                  isFieldHelpSelectorEntityNameBankDetails
                    ? getFieldHelpTextBankDetails('BankDetails.iban')
                    : getFieldHelpTextFirmendatenBankDetails('FirmendatenBankDetails.iban')
                }
              />
              <FormItemWithFieldHelp
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name={bankDetailsFormFields.bic}
                label="BIC"
                hasFeedback
                showValidateSuccess
                validate={(value: string) => validateSyncWithSchemaAndReturnErrorIfAny(bicValidationSchema, value)}
                fieldHelp={
                  isFieldHelpSelectorEntityNameBankDetails
                    ? getFieldHelpTextBankDetails('BankDetails.bic')
                    : getFieldHelpTextFirmendatenBankDetails('FirmendatenBankDetails.bic')
                }
              >
                <Input id={bankDetailsFormFields.bic} name={bankDetailsFormFields.bic} placeholder="z.B. GIBAATWWXXX" />
              </FormItemWithFieldHelp>
              <FormItemWithFieldHelp
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name={bankDetailsFormFields.bankname}
                label="Bankname"
                hasFeedback
                showValidateSuccess
                validate={(value: string) => validateSyncWithSchemaAndReturnErrorIfAny(banknameValidationSchema, value)}
                fieldHelp={
                  isFieldHelpSelectorEntityNameBankDetails
                    ? getFieldHelpTextBankDetails('BankDetails.bankname')
                    : getFieldHelpTextFirmendatenBankDetails('FirmendatenBankDetails.bankname')
                }
              >
                <Input id={bankDetailsFormFields.bankname} name={bankDetailsFormFields.bankname} placeholder="z.B. Weltbank" />
              </FormItemWithFieldHelp>
              {
                // if firmendatenId is present, that means that we show this form for an objekt, and therefore we show the allowObjektZuweisung checkbox
                firmendatenId && (
                  <FormItemWithFieldHelp labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name={bankDetailsFormFields.allowObjektZuweisung}>
                    <Checkbox
                      id={bankDetailsFormFields.allowObjektZuweisung}
                      name={bankDetailsFormFields.allowObjektZuweisung}
                      checked={formikProps.values.allowObjektZuweisung}
                    >
                      <Typography.Text>Zuweisung im Objekt erlauben</Typography.Text>
                      <FieldHelpIconWithTooltip
                        fieldHelp={
                          isFieldHelpSelectorEntityNameBankDetails
                            ? null // This field is not present in Rechtstraeger-BankDetails, so no fieldhelp is needed
                            : getFieldHelpTextFirmendatenBankDetails('FirmendatenBankDetails.allowObjektZuweisung')
                        }
                      />
                    </Checkbox>
                  </FormItemWithFieldHelp>
                )
              }
              {fibuKontoCreatable && (
                <FormItemWithFieldHelp labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name={bankDetailsFormFields.createFibuKonto}>
                  <Checkbox
                    id={bankDetailsFormFields.createFibuKonto}
                    name={bankDetailsFormFields.createFibuKonto}
                    checked={formikProps.values.createFibuKonto}
                  >
                    <Typography.Text>Fibukonto anlegen</Typography.Text>
                    <FieldHelpIconWithTooltip
                      fieldHelp={
                        isFieldHelpSelectorEntityNameBankDetails
                          ? getFieldHelpTextBankDetails('BankDetails.createFibuKonto')
                          : getFieldHelpTextFirmendatenBankDetails('FirmendatenBankDetails.createFibuKonto')
                      }
                    />
                  </Checkbox>
                </FormItemWithFieldHelp>
              )}
            </Col>
          </Row>
          <FormButtons updateMode={isUpdate} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} isOkLoading={loading} />
        </Form>
      )}
    </Formik>
  );
};

const mapAndSubmit =
  (onSubmit: (bankDetails: FDAndRechtstrBankDetailsInput) => void) =>
  (values: BankDetailsFormValues, formikHelpers: FormikHelpers<BankDetailsFormValues>) => {
    const bankDetails = mapFormValuesToBankDetails(values);
    onSubmit(bankDetails);
    formikHelpers.setSubmitting(false);
  };

const setFieldValueAndTouchedAndValidate = (
  formikProps: FormikProps<BankDetailsFormValues>,
  fieldName: string,
  fieldValue: string,
  touched: boolean
) => {
  formikProps.setFieldValue(fieldName, fieldValue, false);
  formikProps.setFieldTouched(fieldName, touched, false);
  formikProps.validateField(fieldName);
};

export default BankDetailsForm;
