import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { verarbeitungTypes } from '../../verarbeitungHelpers';
import { InfoMailDeliverySendVerarbeitungQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import {
  infoMailDeliverySendVerarbeitungEntryFiltersFormInitialValues,
  InfoMailDeliverySendVerarbeitungEntryFiltersFormValues,
  mapFormValuesToInfoMailDeliverySendVerarbeitungFilters,
} from './Filters/ListingFiltersFormMapper';
import ListingFilters from './Filters/ListingFilters';
import InfoMailDeliverySendVerarbeitungEntryTable from './Table/InfoMailDeliverySendVerarbeitungEntryTable';
import { InfoMailDeliverySendGenerierlauf } from '../../../../types';

type Props = {
  generierlauf: InfoMailDeliverySendGenerierlauf;
};

const InfoMailDeliverySendVerarbeitungEntryListing: FC<Props> = ({ generierlauf }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<InfoMailDeliverySendVerarbeitungQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(verarbeitungTypes.InfoMailDeliverySend, generierlauf.generierlaufId, navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: InfoMailDeliverySendVerarbeitungEntryFiltersFormValues) => {
    updateQueryParams(
      verarbeitungTypes.InfoMailDeliverySend,
      generierlauf.generierlaufId,
      navigate,
      mapFormValuesToInfoMailDeliverySendVerarbeitungFilters(values),
      paginationParams
    );
  };

  return (
    <Formik<InfoMailDeliverySendVerarbeitungEntryFiltersFormValues>
      initialValues={infoMailDeliverySendVerarbeitungEntryFiltersFormInitialValues(queryParams)}
      onSubmit={onChange}
    >
      {(formikProps) => (
        <>
          <ListingFilters formikProps={formikProps} generierlaufId={generierlauf.generierlaufId} />
          <InfoMailDeliverySendVerarbeitungEntryTable queryParams={queryParams} generierlaufId={generierlauf.generierlaufId} />
        </>
      )}
    </Formik>
  );
};

export default InfoMailDeliverySendVerarbeitungEntryListing;
