import React from 'react';
import { Button, Spin, Typography } from 'antd';
import { useAuthenticateForEmailIntegrationLazyQuery, useEmailIntegrationQuery } from './gql/EmailIntegrationQueries.types';
import CardWithFooter from '../../components/Card';
import CardTitle from '../../components/Card/CardTitle';
import CardDetailsInfoRow from '../../components/Card/CardDetailsInfoRow';
import EmailIntegrationActions from './EmailIntegrationActions';

const EmailIntegration = () => {
  const { data: emailIntegrationData, loading: isEmailIntegrationLoading, refetch } = useEmailIntegrationQuery();
  const [runAuthenticate, { loading: isAuthenticationLoading }] = useAuthenticateForEmailIntegrationLazyQuery({
    onCompleted: (data) => {
      const authUrl = data?.authenticateForEmailIntegration;
      if (authUrl) {
        window.open(authUrl);
      }
    },
  });
  const emailIntegration = emailIntegrationData?.getEmailIntegration.data;

  if (!emailIntegration && isEmailIntegrationLoading) {
    return <Spin />;
  } else if (!emailIntegration) {
    return (
      <Button onClick={() => runAuthenticate()} loading={isAuthenticationLoading} type="primary">
        Office 365 Konto verbinden
      </Button>
    );
  }

  return (
    <>
      <CardWithFooter
        loading={isEmailIntegrationLoading}
        createTs={emailIntegration.createTs}
        title={<CardTitle title="E-Mail Integration" />}
        style={{ minWidth: '320px' }}
        size="default"
        bordered={false}
        userId={emailIntegration.createdBy}
        mitarbeiterId={emailIntegration.createdByMitarbeiterId}
        extra={<EmailIntegrationActions onAction={refetch} />}
      >
        <CardDetailsInfoRow infoRowTitle="E-Mail Adresse">
          <Typography.Text>{emailIntegration.emailAddress}</Typography.Text>
        </CardDetailsInfoRow>
        <CardDetailsInfoRow infoRowTitle="Name">
          <Typography.Text>{emailIntegration.name}</Typography.Text>
        </CardDetailsInfoRow>
      </CardWithFooter>
    </>
  );
};

export default EmailIntegration;
