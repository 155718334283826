import { FC } from 'react';
import { TabsProps } from 'antd';
import VertragVorschreibungspositionTable from './VorschreibungspositionTab/VorschreibungspositionWert/VertragVorschreibungspositionTable';
import VPosIndexationAgreementListing from './VPosIndexationAgreement/VPosIndexationAgreementListing';
import { pathsToBeVertragDetailsPage } from '../beVertragUriPaths';
import RouteTabs from '../../../../components/Tabs/RouteTabs';

type Props = {
  bestandseinheitId: string;
  objektId: string;
  vertragId: string;
  vertragsBeginn: string;
};

const VertragVertragsdatenTabs: FC<Props> = ({ bestandseinheitId, objektId, vertragId, vertragsBeginn }) => {
  const paths = pathsToBeVertragDetailsPage(objektId, bestandseinheitId, vertragId);

  const items: TabsProps['items'] = [
    {
      key: paths.vertragsdatenVPosTab,
      label: 'Vorschreibungsposition',
      children: (
        <VertragVorschreibungspositionTable
          bestandseinheitId={bestandseinheitId}
          objektId={objektId}
          vertragId={vertragId}
          vertragsBeginn={vertragsBeginn}
        />
      ),
    },
    {
      key: paths.vertragsdatenIndexationAgreementTab,
      label: 'Wertsicherungsvereinbarungen',
      children: <VPosIndexationAgreementListing bestandseinheitId={bestandseinheitId} objektId={objektId} vertragId={vertragId} />,
    },
  ];

  return <RouteTabs items={items} />;
};

export default VertragVertragsdatenTabs;
