import React, { FC } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import pdfStyles from '../styles/pdfStyles';
import { Mahnung } from './mahnung-types';
import MahnungTitleText from './components/MahnungTitleText';
import RechnungempfaengerSachbearbeiterBlock from '../shared/RechnungempfaengerSachbearbeiterBlock';
import MahnungTableBlock from './components/MahnungTableBlock/MahnungTableBlock';
import Header from '../shared/Header';
import Footer from '../shared/Footer';
import Ergebnistext from '../shared/Ergebnistext';
import Einleitungstext from '../shared/Einleitungstext';
import Schlusstext from '../shared/Schlusstext';

type MahnungTemplateProps = {
  data: Mahnung;
  isInEditMode?: boolean;
  selectedTextbaustein?: MahndefinitionSelectableSection;
};

export type MahndefinitionSelectableSection = 'TITELTEXT' | 'EINLEITUNGSTEXT' | 'ERGEBNISTEXT' | 'SCHLUSSTEXT';

const MahnungTemplate: FC<MahnungTemplateProps> = ({ data, isInEditMode, selectedTextbaustein }) => (
  <Document>
    <Page size="A4" style={[pdfStyles.body, { paddingBottom: data.footer?.value ? '32mm' : pdfStyles.body.paddingBottom }]}>
      <View>
        {/* header */}
        <Header header={data.header} />
      </View>

      {/* Rechnungsempfänger + Sachbearbeiter, City+Datum */}
      <RechnungempfaengerSachbearbeiterBlock
        rechnungsEmpfaengerShippingLabel={data.rechnungsEmpfaenger.rechnungsEmpfaengerShippingLabel}
        sachbearbeiter={data.sachbearbeiter}
        absender={data.absender}
        einschreibenText={data.einschreibenText}
      />

      <View style={[pdfStyles.textNormal, { textAlign: 'right' }]}>
        {data.rechnungsEmpfaenger.kundennummer && (
          <Text>
            {data.rechnungsEmpfaenger.kundennummer.text} {data.rechnungsEmpfaenger.kundennummer.nummer}
          </Text>
        )}
        <Text>{data.mahnungDatumLine}</Text>
      </View>

      {/* Titeltext with Textbaustein */}
      <MahnungTitleText titleText={data.titelText} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />

      {/* Einleitungstext with Textbaustein */}
      <Einleitungstext text={data.einleitungstext} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />

      {/* MahnungDetails */}
      <Text style={[pdfStyles.textNormal, { marginTop: '10mm' }]}>
        {data.gebuchtInfo.text} {data.gebuchtInfo.bis}
      </Text>
      <MahnungTableBlock data={data} />

      {/* Ergebnistext with Textbaustein */}
      <Ergebnistext text={data.ergebnistext} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />

      {/* Schlusstext with Textbaustein */}
      <Schlusstext text={data.schlusstext} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />

      {/* Footer */}
      {data.footer?.value && <Footer footer={data.footer} />}
    </Page>
  </Document>
);

export default MahnungTemplate;
