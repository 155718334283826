import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { DatePicker, Form } from 'formik-antd';
import FormButtons from '../../../../components/Button/FormButtons';
import { pdfExportOPListFormFields, pdfExportOPListFormInitialValues, PdfExportOPListFormValues } from './pdfExportOPListFormMapper';
import RechnungsausstellerSelect from '../../../Rechnungsaussteller/RechnungsausstellerSelect';
import RechtstraegerSelect from '../../../../shared/Rechtstraeger/RechtstraegerSelect';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../components/DatePicker/DatePicker_formikAntD';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import { downloadURL } from '../../../../helpers/fileHelper';
import { showSuccessMsgOther, showWarningMessage } from '../../../../components/message';
import { useFDAuthorized } from '../../../../shared/Auth/useAuthorized';
import { runExportOPDebitorListAsPdf } from './runExportOPDebitorListAsPdf';
import OPKontoTypeSelect from '../../Filters/OPKontoTypeSelect';
import PDFCreatingStateView from '../../../shared/PDFCreatingStateView';
import { mapFormDateValueToDateString } from '../../../../helpers/formikHelper';

type Props = {
  onAction: () => void;
};
// Create an AbortController instance
let opDebitorListAsPdfAbortController: AbortController;

const PDFExportOPListForm: FC<Props> = ({ onAction }) => {
  const { activeForFirmendatenId: firmendatenId } = useFDAuthorized();
  const [isLoading, setIsLoading] = useState(false);

  opDebitorListAsPdfAbortController = new AbortController();

  const onExportSuccess = (response: any) => {
    downloadURL(response.url);
    showSuccessMsgOther(`Offene Posten Debitor Liste erfolgreich exportiert.`);
    setIsLoading(false);
    onAction();
  };

  const onExportError = (error: any) => {
    setIsLoading(false);
    showWarningMessage(error, 'Offene Posten Debitor Liste wurde nicht exportiert');
  };

  return (
    <Formik<PdfExportOPListFormValues>
      initialValues={pdfExportOPListFormInitialValues}
      onSubmit={(values) => {
        // run export
        setIsLoading(true);
        runExportOPDebitorListAsPdf(
          {
            firmendatenId,
            rechnungsAusstellerId: values.rechnungsausstellerId,
            vertragspartnerId: values.vertragspartnerId,
            dueDateTo: values.dueDateTo ? mapFormDateValueToDateString(values.dueDateTo) : undefined,
          },
          opDebitorListAsPdfAbortController
        )
          .then(onExportSuccess)
          .catch(onExportError);
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          {isLoading ? (
            <PDFCreatingStateView />
          ) : (
            <>
              <FormItemWithoutColon name={pdfExportOPListFormFields.rechnungsausstellerId} label="Für Rechnungsaussteller">
                <RechnungsausstellerSelect name={pdfExportOPListFormFields.rechnungsausstellerId} size="small" />
              </FormItemWithoutColon>
              <FormItemWithoutColon name={pdfExportOPListFormFields.vertragspartnerId} label="Für Firma/Person">
                <RechtstraegerSelect name={pdfExportOPListFormFields.vertragspartnerId} size="small" placeholder="Rechnungsempfänger auswählen" />
              </FormItemWithoutColon>
              <FormItemWithoutColon name={pdfExportOPListFormFields.kontotyp} label="Kontentyp">
                <OPKontoTypeSelect name={pdfExportOPListFormFields.kontotyp} disabled />
              </FormItemWithoutColon>
              <FormItemWithoutColon name={pdfExportOPListFormFields.dueDateTo} label="Fälligkeit bis">
                <DatePicker name={pdfExportOPListFormFields.dueDateTo} format={DATE_DISPLAY_FORMAT_DEFAULT} />
              </FormItemWithoutColon>
            </>
          )}
          <FormButtons
            okText="Exportieren"
            isOkDisabled={formikProps.isSubmitting || isLoading}
            onCancel={() => {
              opDebitorListAsPdfAbortController.abort();
              onAction();
            }}
            updateMode={false}
          />
        </Form>
      )}
    </Formik>
  );
};

export default PDFExportOPListForm;
