import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { PaymentGenerierlaufType } from '../../../../../types';
import { useFilterGenLaufPaymentDataCarrierTypeListQuery } from '../../../gql/GenerierlaufFilter/PaymentGenerierlaufFilterQueries.types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';

type Props = {
  name: string;
  generierlaufId: string;
  generierlaufType: PaymentGenerierlaufType;
} & SelectProps;

const GenerierlaufDataCarrierTypeListFormSelect: FC<Props> = ({ name, generierlaufId, generierlaufType, ...restProps }) => {
  const { data, loading } = useFilterGenLaufPaymentDataCarrierTypeListQuery({
    variables: {
      generierlaufId,
      generierlaufType,
    },
  });

  const list = data?.getFilterGenLaufPaymentDataCarrierTypeList.data ?? [];

  return (
    <Select size="small" loading={loading} id={name} name={name} allowClear placeholder="Datenträgertyp auswählen" {...restProps}>
      {list.map((dataCarrierType) => (
        <Select.Option key={dataCarrierType.value} value={dataCarrierType.value}>
          <DataWithShortenedText maxTextLength={30} text={dataCarrierType.text} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default GenerierlaufDataCarrierTypeListFormSelect;
