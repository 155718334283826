import { FormikProps } from 'formik';
import { BelegTexteFormValues } from '../../belegTexteFormMapper';
import { IMAGE_SUPPORTED_FORMATS } from '../../../../../helpers/fileHelper';
import { TextbausteinType } from '../../../../../types';
import { ValidateFileProps } from '../../../../../components/FileUpload/FileUploadArea';

export const validateFile = (
  file: ValidateFileProps,
  formikProps: FormikProps<BelegTexteFormValues>,
  name: string,
  textbausteinType: TextbausteinType
): boolean => {
  const allowedFileContentTypes = [...IMAGE_SUPPORTED_FORMATS];
  if (!allowedFileContentTypes.includes(file.type)) {
    formikProps.setFieldError(
      name,
      `Das Format der Beleg Datei ${file.type} wird nicht unterstützt, unterstützte Formate sind: ${allowedFileContentTypes}`
    );
    return false;
  }
  const fileSizeInMB = file.size / 1024 / 1024;

  if (isTooLarge(fileSizeInMB, textbausteinType)) {
    formikProps.setFieldError(
      name,
      `${file.name} ist zu groß. Dateien müssen kleiner als ${
        textbausteinType === TextbausteinType.BelegFuss ? BELEGFUSS_IMAGE_MAX_SIZE : BELEGKOPF_IMAGE_MAX_SIZE
      }mb sein.`
    );
    return false;
  }
  return true;
};

const isTooLarge = (fileSizeInMB: number, textbausteinType: TextbausteinType) => {
  if (textbausteinType === TextbausteinType.BelegFuss) {
    return fileSizeInMB > BELEGFUSS_IMAGE_MAX_SIZE;
  }
  return fileSizeInMB > BELEGKOPF_IMAGE_MAX_SIZE;
};

const BELEGKOPF_IMAGE_MAX_SIZE = 0.512;
const BELEGFUSS_IMAGE_MAX_SIZE = 0.256;
