import React, { FC } from 'react';
import { Empty } from 'antd';
import { Budgeting, BudgetingResultBestandseinheit, BudgetingResultVertrag } from '../../../../../types';
import NestedTable from '../../../../../components/Table/NestedTable/NestedTable';
import budgetingResultVertragTableColumns from './budgetingResultVertragTableColumns';
import BudgetingResultVertragVPosWertTable from '../Level3/BudgetingResultVertragVPosWertTable';

type Props = {
  budgeting: Budgeting;
  budgetingResultBestandseiheit: BudgetingResultBestandseinheit;
};

const BudgetingResultVertragTable: FC<Props> = ({ budgeting, budgetingResultBestandseiheit }) => {
  const { budgetingResultVertragList, bestandseinheit } = budgetingResultBestandseiheit;

  return (
    <NestedTable<BudgetingResultVertrag>
      colWidth={100}
      level={2}
      locale={{
        emptyText: <Empty description={<span>Keine Vertragswerte vorhanden</span>} />,
      }}
      dataSource={budgetingResultVertragList}
      columns={budgetingResultVertragTableColumns(budgeting, bestandseinheit)}
      rowKey={(record) => record.vertrag.vertragId}
      useHighlighting
      expandedRowKey="vertragId"
      expandable={{
        rowExpandable: (record) => !!record.budgetingResultVertragVPosWertList.length,
        expandedRowRender: (record) => {
          return <BudgetingResultVertragVPosWertTable budgeting={budgeting} bestandseinheit={bestandseinheit} budgetingResultVertrag={record} />;
        },
      }}
    />
  );
};
export default BudgetingResultVertragTable;
