import React, { FC } from 'react';
import { Spin } from 'antd';
import { useToggle } from '../../../hooks/useToggle';
import { AbrechnungsdefinitionType, KontoKlasse, KontoVerwendung } from '../../../types';
import { useKontoListForAbrKontenzuordnungQuery } from '../../AbrKontenzuordnung/gql/KontoQueries.types';
import { ZuordnungKontoListingFormValues } from '../../AbrKontenzuordnung/Form/zuordnungKontoListingFormMapper';
import { AbrechnungsdefinitionVersion } from './abrDefTypes';
import AbrDefKontenzuordnungOnlyView from '../Version/View/AccountAssignmentView/AbrDefKontenzuordnungOnlyView';
import AbrDefKontenzuordnungViewAndEdit from '../../AbrKontenzuordnung/ViewAndEdit/AbrDefKontenzuordnungViewAndEdit';
import { ZuordnungColumnMode } from '../../AbrKontenzuordnung/shared/AbrKreisKontenzuordnungHelpers';

type Props = {
  abrechnungsdefinitionVersion: AbrechnungsdefinitionVersion;
  abrechnungsdefinitionId: string;
  abrDefinitionType: AbrechnungsdefinitionType;
  onValuesChange?: (isDirty: boolean) => void;
  onSubmit?: (formValues: ZuordnungKontoListingFormValues) => Promise<unknown>;
  mode: ZuordnungColumnMode;
  fieldHelp?: string | null;
};

const AbrDefKontenzuordnung: FC<Props> = ({
  abrechnungsdefinitionVersion,
  abrechnungsdefinitionId,
  abrDefinitionType,
  onValuesChange,
  onSubmit,
  mode,
  fieldHelp,
}) => {
  const [isEinnahmenListOpen, toggleEinnahmenKontoList] = useToggle();
  const [isAusgabenListOpen, toggleAusgabenList] = useToggle();
  const [isVerrechnungListOpen, toggleVerrechnungList] = useToggle();

  /* EINNAHMEN */
  /* Erloeskonto */
  const { data: maybeErloesKontoList } = useKontoListForAbrKontenzuordnungQuery({
    variables: { klasseList: [KontoKlasse.BetrieblicherErloes, KontoKlasse.FinanzErloes] },
  });
  const erloesKontoList = maybeErloesKontoList?.getKontoList.data.contentList;
  /* Umsatzsteuerkonto */
  const { data: maybeUmsatzsteuerKontoListData } = useKontoListForAbrKontenzuordnungQuery({
    variables: { klasseList: [KontoKlasse.Lieferverbindlichkeit], verwendung: KontoVerwendung.Umsatzsteuer },
  });
  const umsatzsteuerKontoList = maybeUmsatzsteuerKontoListData?.getKontoList.data.contentList;

  /* AUSGABEN */
  /* Aufwandkonto */
  const { data: maybeAufwandKontoList } = useKontoListForAbrKontenzuordnungQuery({
    variables: {
      klasseList: [KontoKlasse.HandelswarenEinsatz, KontoKlasse.PersonalAufwand, KontoKlasse.BetrieblicherAufwand, KontoKlasse.FinanzAufwand],
    },
  });
  const aufwandKontoList = maybeAufwandKontoList?.getKontoList.data.contentList;
  /* Vorsteuerkonto */
  const { data: maybeVorsteuerKontoListData } = useKontoListForAbrKontenzuordnungQuery({
    variables: {
      klasseList: [KontoKlasse.UmlaufVermoegen],
      verwendung: KontoVerwendung.Vorsteuer,
    },
  });
  const vorsteuerKontoList = maybeVorsteuerKontoListData?.getKontoList.data.contentList;

  /* EIGENTUEMERVERRECHNUNG */
  const { data: maybeEigentuemerVerrechnungListData } = useKontoListForAbrKontenzuordnungQuery({
    variables: {
      klasseList: [KontoKlasse.Eigenkapital],
    },
  });
  const eigentuemerVerrechnungList = maybeEigentuemerVerrechnungListData?.getKontoList.data.contentList;

  const loading = !erloesKontoList || !umsatzsteuerKontoList || !aufwandKontoList || !vorsteuerKontoList || !eigentuemerVerrechnungList;

  if (loading) {
    return <Spin spinning={loading} />;
  }

  const einnahmenKontoList = [...erloesKontoList, ...umsatzsteuerKontoList];
  const ausgabenKontoList = [...aufwandKontoList, ...vorsteuerKontoList];
  const verrechnungKontoList = [...eigentuemerVerrechnungList];

  return mode === 'onlyView' ? (
    <AbrDefKontenzuordnungOnlyView
      abrechnungsdefinitionVersion={abrechnungsdefinitionVersion}
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      abrDefinitionType={abrDefinitionType}
      einnahmenKontoList={einnahmenKontoList}
      ausgabenKontoList={ausgabenKontoList}
      verrechnungKontoList={verrechnungKontoList}
      isEinnahmenListOpen={isEinnahmenListOpen}
      isAusgabenListOpen={isAusgabenListOpen}
      isVerrechnungListOpen={isVerrechnungListOpen}
      toggleEinnahmenKontoList={toggleEinnahmenKontoList}
      toggleAusgabenList={toggleAusgabenList}
      toggleVerrechnungList={toggleVerrechnungList}
    />
  ) : (
    <AbrDefKontenzuordnungViewAndEdit
      mode={mode}
      abrechnungsdefinitionVersion={abrechnungsdefinitionVersion}
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      abrDefinitionType={abrDefinitionType}
      einnahmenKontoList={einnahmenKontoList}
      ausgabenKontoList={ausgabenKontoList}
      verrechnungKontoList={verrechnungKontoList}
      isEinnahmenListOpen={isEinnahmenListOpen}
      isAusgabenListOpen={isAusgabenListOpen}
      isVerrechnungListOpen={isVerrechnungListOpen}
      toggleEinnahmenKontoList={toggleEinnahmenKontoList}
      toggleAusgabenList={toggleAusgabenList}
      toggleVerrechnungList={toggleVerrechnungList}
      onValuesChange={onValuesChange}
      onSubmit={onSubmit}
      fieldHelp={fieldHelp}
    />
  );
};

export default AbrDefKontenzuordnung;
