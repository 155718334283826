import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import { EuroAmount } from '../../../../components/Number';
import { generatePathToBestandseinheitDetailsPage } from '../../../Bestandseinheit/bestandseinheitUriPaths';
import { generatePathToBeVertragDetailsPage } from '../../../Vertrag/BeVertrag/beVertragUriPaths';
import DataWithShortenedTextAndExtraInfo from '../../../../components/Helpers/DataWithShortenedTextAndExtraInfo';
import { sortVPosTableDetailsDataSource } from './helpers/vPosTableDetailsSortingHelpers';
import { setSortOrder, SortOrder } from '../../../../components/Table/TableSorting/sortingHelpers';
import TableSummaryCellContentWithSorting from '../../../../components/Table/TableSorting/TableSummaryCellContentWithSorting';
import TableSummaryCellTitleWithSortCarets from '../../../../components/Table/TableSorting/TableSummaryCellTitleWithSorting';
import { paginationStartAndEndIndexes } from '../../../../helpers/paginationHelper';
import { flatBestandseinheitList } from './helpers/bestandseinheitListFlattingHelpers';
import { Zinsliste } from '../../../../types';
import { TVPosForVertragLevel } from '../../Card/VPosForVertragLevelList/vPosForVertragLevelList.model';
import RechtstraegerWithContactsTooltip from '../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

type Props = {
  objektId: string;
  zinsliste: Zinsliste;
  vorschreibungspositionList: TVPosForVertragLevel[];
  pagination: { page: number; pageSize: number };
};

const VPosTableDetails: FC<Props> = ({ objektId, zinsliste, vorschreibungspositionList, pagination }) => {
  const { bestandseinheitList: datasource } = zinsliste;

  const bestandseinheitList = flatBestandseinheitList(datasource);

  const [sorting, setSorting] = useState({
    sortedColumnIndex: 0,
    sortOrder: SortOrder.NoSorting,
  });

  const handleSorting = (index: number) => {
    setSorting({
      sortedColumnIndex: index,
      sortOrder: setSortOrder(index, sorting.sortOrder, sorting.sortedColumnIndex),
    });
  };

  const sortedDataSource = sortVPosTableDetailsDataSource(
    bestandseinheitList,
    vorschreibungspositionList,
    sorting.sortOrder,
    sorting.sortedColumnIndex
  );

  const paginationIndexes = paginationStartAndEndIndexes(pagination);
  const paginatedDataSource = sortedDataSource.slice(paginationIndexes.indexStart, paginationIndexes.indexEnd);

  return (
    <Table.Summary>
      <Table.Summary.Row>
        <TableSummaryCellTitleWithSortCarets
          index={0}
          columnTitle="Top"
          sortedColumnIndex={sorting.sortedColumnIndex}
          sortOrder={sorting.sortOrder}
          handleSorting={handleSorting}
        />
        <TableSummaryCellTitleWithSortCarets
          index={1}
          columnTitle="Vertragsart"
          sortedColumnIndex={sorting.sortedColumnIndex}
          sortOrder={sorting.sortOrder}
          handleSorting={handleSorting}
        />
        <TableSummaryCellTitleWithSortCarets
          index={2}
          columnTitle="Vertragspartner"
          sortedColumnIndex={sorting.sortedColumnIndex}
          sortOrder={sorting.sortOrder}
          handleSorting={handleSorting}
        />
        {vorschreibungspositionList.map((col, index) => (
          <TableSummaryCellTitleWithSortCarets
            key={index}
            index={3 + index}
            columnTitle="Netto"
            colSpan={2}
            align="right"
            sortedColumnIndex={sorting.sortedColumnIndex}
            sortOrder={sorting.sortOrder}
            handleSorting={handleSorting}
          />
        ))}
      </Table.Summary.Row>

      {paginatedDataSource.map((beVertragRow, index) => {
        return (
          <Table.Summary.Row key={index}>
            <TableSummaryCellContentWithSorting index={0} colSpan={1} sortedColumnIndex={sorting.sortedColumnIndex} sortOrder={sorting.sortOrder}>
              <DataWithShortenedTextAndExtraInfo maxTextLength={15} text={beVertragRow.bezeichnung}>
                {(shortenedText) => (
                  <Link to={generatePathToBestandseinheitDetailsPage(objektId, beVertragRow.bestandseinheitId)}>{shortenedText}</Link>
                )}
              </DataWithShortenedTextAndExtraInfo>
            </TableSummaryCellContentWithSorting>
            <TableSummaryCellContentWithSorting index={1} colSpan={1} sortedColumnIndex={sorting.sortedColumnIndex} sortOrder={sorting.sortOrder}>
              <DataWithShortenedTextAndExtraInfo maxTextLength={15} text={beVertragRow.vertrag.vertragsart.text}>
                {(shortenedText) => (
                  <Link to={generatePathToBeVertragDetailsPage(objektId, beVertragRow.bestandseinheitId, beVertragRow.vertrag.vertragId)}>
                    {shortenedText}
                  </Link>
                )}
              </DataWithShortenedTextAndExtraInfo>
            </TableSummaryCellContentWithSorting>
            <TableSummaryCellContentWithSorting index={2} colSpan={1} sortedColumnIndex={sorting.sortedColumnIndex} sortOrder={sorting.sortOrder}>
              <RechtstraegerWithContactsTooltip
                rechtstraegerId={beVertragRow.vertrag.vertragspartner.rechtstraegerId}
                rechtstraegerName={beVertragRow.vertrag.vertragspartner.kurzBezeichnung}
                maxTextLength={15}
                status={beVertragRow.vertrag.vertragspartner.status}
              />
            </TableSummaryCellContentWithSorting>
            {vorschreibungspositionList.map((vPos, index) => {
              const beVertragVPosForCell = beVertragRow.vertrag.vertragVorschreibungspositionList.find(
                (beVertragVPos) => beVertragVPos.bezeichnung === vPos.bezeichnung
              );
              const nettoBetrag = beVertragVPosForCell?.netto ?? 0;
              return (
                <TableSummaryCellContentWithSorting
                  key={index}
                  index={3 + index}
                  colSpan={2}
                  align="right"
                  sortedColumnIndex={sorting.sortedColumnIndex}
                  sortOrder={sorting.sortOrder}
                >
                  <EuroAmount value={nettoBetrag} />
                </TableSummaryCellContentWithSorting>
              );
            })}
          </Table.Summary.Row>
        );
      })}
    </Table.Summary>
  );
};

export default VPosTableDetails;
