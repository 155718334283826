import React, { FC } from 'react';
import { Empty } from 'antd';
import { useOffenePostenListQuery } from '../../gql/OffenePostenQueries.types';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../hooks/useQueryWithPagingAndSorting';
import { TOffenePostenQueryParams } from '../../offenePostenUriPaths';
import { offenePostenTableColumns } from './offenePostenTableColumns';
import OffenePostenAuszifferungTable from '../Level2/OffenePostenAuszifferungTable';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { PaginationQueryParamsProps } from '../../../../hooks/usePaginationQueryParams';
import { OffenePosten } from '../../../../types';
import { mapQueryParamsToOffenePostenQueryVariables } from '../../Filters/offenePostenListingFiltersFormMapper';
import { useOnOffenePostenDataChangedEvents } from '../../useOnOffenePostenDataChangedEvents';

type Props = {
  queryParams: TOffenePostenQueryParams;
  showAusgeziffert: boolean;
  paginationParams: PaginationQueryParamsProps;
};

const OffenePostenTable: FC<Props> = ({ queryParams, showAusgeziffert, paginationParams }) => {
  const { currentPage, pageSize } = paginationParams;

  const { data, loading, refetch, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useOffenePostenListQuery,
    {
      variables: {
        ...mapQueryParamsToOffenePostenQueryVariables(queryParams, showAusgeziffert),
      },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const offenePostenList = data?.getOffenePostenList.data?.contentList ?? [];

  useOnOffenePostenDataChangedEvents('offenePostenList', refetch);

  return (
    <TableWithColSelector<OffenePosten>
      size="small"
      locale={{
        emptyText: <Empty description="Keine Offene Posten vorhanden" />,
      }}
      dataSource={offenePostenList}
      loading={loading}
      pagination={pagination}
      columns={offenePostenTableColumns}
      onChange={handleTableSorting}
      rowKey={(record) => record.offenePostenId}
      expandable={{
        expandedRowRender: (record) => <OffenePostenAuszifferungTable offenePosten={record} onAction={refetch} />,
      }}
      filterIdentifier="main-offene-posten"
    />
  );
};

export default OffenePostenTable;
