import styled from 'styled-components';
import { Layout } from 'antd';

const StyledContentTabs = styled(Layout.Content)`
  background: #fff;
  padding: 14px 8px;
`;

const StyledContentMain = styled(Layout.Content)`
  padding: 16px 16px;
`;

export { StyledContentTabs as ContentTabs, StyledContentMain as ContentMain };
