import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedNumber } from 'react-intl';
import { Tag, Typography } from 'antd';
import { VertragVposIndexedValue, VertragVposIndexedValueOrderBy } from '../../../../../../../types';
import IndexedValueTableActions from './TableActions/IndexedValueTableActions';
import { compareTwoStringsForSorting } from '../../../../../../../helpers/stringHelper';
import { compareTwoNumbersForSorting } from '../../../../../../../helpers/numberHelper';
import { EuroAmount, Percent } from '../../../../../../../components/Number';
import { vertragVposIndexedValueFormFields, VertragVposIndexedValueFormValues } from './indexedValueMapper';
import { EditableTableColumnProps } from '../../../../../../../components/Table/EditableTableOld/EditableTableProps';
import FormItemWithoutColon from '../../../../../../../components/Form/FormItemWithoutColon';
import ActionButtonsInEditMode from '../../../../../../../components/Table/EditableTableOld/ActionButtonsInEditMode';
import MonthPicker from '../../../../../../../components/DatePicker/MonthPicker/MonthPicker';
import { generatePathToBeVertragDetailsPageVertragsdatenVPosTab } from '../../../../beVertragUriPaths';
import FactorPercentFormItem from './TableColumnsInEditMode/FactorPercentFormItem';
import AmountFormItem from './TableColumnsInEditMode/AmountFormItem';
import { getStatusTagColor, isVertragVposIndexedValueStatusApplied } from '../../../../../../IndexedValue/shared/indexedValueHelpers';
import { MitarbeiterTooltip } from '../../../../../../../components/Card';
import DataWithShortenedText from '../../../../../../../components/Helpers/DataWithShortenedText';
import { generatePathToFirmendatenIndexSeriesDetailsPage } from '../../../../../../Firmendaten/IndexSeries/firmendatenIndexSeriesUriPaths';
import FormattedDateTime from '../../../../../../../components/FormattedDateTime/FormattedDateTime';
import ValuePreservationSettings from '../../../../../../shared/ValuePreservation/ValuePreservationSettings/ValuePreservationSettings';
import CustomFormattedDate from '../../../../../../../components/FormattedDate/CustomFormattedDate';

const indexedValueTableColumns = (
  vorschreibungspositionId: string,
  onAction: () => void
): EditableTableColumnProps<VertragVposIndexedValue, VertragVposIndexedValueFormValues>[] => {
  return [
    {
      title: 'Indexierter Wert',
      dataIndex: 'indexierterWert',
      key: 'indexierterWert',
      editable: false,
      sorter: (a, b) => compareTwoStringsForSorting(a.name, b.name),
      render: (text, record) => <Typography.Text>{record.name}</Typography.Text>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      editable: false,
      sorter: (a, b) => compareTwoStringsForSorting(a.status.text, b.status.text),
      render: (text, record) => <Tag color={getStatusTagColor(record.status.value)}>{record.status.text}</Tag>,
    },
    {
      title: 'Indexreihe',
      dataIndex: VertragVposIndexedValueOrderBy.IndexSeries,
      key: VertragVposIndexedValueOrderBy.IndexSeries,
      editable: false,
      sorter: true,
      width: 110,
      render: (text, record) => (
        <DataWithShortenedText maxTextLength={20} text={record.indexationAgreement.indexSeries.name}>
          {(shortenedText) => (
            <Link to={generatePathToFirmendatenIndexSeriesDetailsPage(record.indexationAgreement.indexSeries.indexSeriesId)} target="_blank">
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ),
    },
    {
      title: 'WS-Einst.',
      key: 'valuePreservationSettings',
      editable: false,
      width: 155,
      sorter: false,
      render: (text, record) => <ValuePreservationSettings indexationAgreement={record.indexationAgreement} />,
    },
    {
      title: 'VPos gültig ab',
      dataIndex: 'vPosGueltigAb',
      key: 'wsGueltigAb',
      editable: true,
      width: 110,
      sorter: (a, b) => compareTwoStringsForSorting(a.validFrom, b.validFrom),
      render: (text, record) => <CustomFormattedDate value={record.validFrom} month="2-digit" year="numeric" />,
      renderInEditMode: () => (
        <FormItemWithoutColon name={vertragVposIndexedValueFormFields.validFrom} style={{ width: '100%', marginBottom: '0px' }}>
          <MonthPicker name={vertragVposIndexedValueFormFields.validFrom} placeholder="Gültig ab auswählen" allowClear={false} />
        </FormItemWithoutColon>
      ),
    },
    {
      title: 'Mon/Jahr alt',
      dataIndex: 'monJahrAlt',
      key: 'monJahrAlt',
      editable: false,
      width: 110,
      sorter: (a, b) => compareTwoStringsForSorting(a.monthYearOld, b.monthYearOld),
      render: (text, record) => <Typography.Text>{record.monthYearOld}</Typography.Text>,
    },
    {
      title: 'Index alt',
      dataIndex: 'indexAlt',
      key: 'indexAlt',
      align: 'right',
      editable: false,
      width: 100,
      sorter: (a, b) => compareTwoNumbersForSorting(a.indexOld, b.indexOld),
      render: (text, record) => <FormattedNumber value={record.indexOld} />,
    },
    {
      title: 'Vorläufig Index alt',
      dataIndex: 'vorlaeufigIndexAlt',
      key: 'vorlaeufigIndexAlt',
      width: 120,
      editable: false,
      render: (text, record) => <Typography.Text>{record.indexOldPreliminary ? 'Ja' : 'Nein'}</Typography.Text>,
    },
    {
      title: 'Mon/Jahr neu',
      dataIndex: 'monJahrNeu',
      key: 'monJahrNeu',
      width: 100,
      editable: false,
      sorter: (a, b) => compareTwoStringsForSorting(a.monthYearNew, b.monthYearNew),
      render: (text, record) => <Typography.Text>{record.monthYearNew}</Typography.Text>,
    },
    {
      title: 'Index neu',
      dataIndex: 'indexNeu',
      key: 'indexNeu',
      width: 100,
      align: 'right',
      editable: false,
      sorter: (a, b) => compareTwoNumbersForSorting(a.indexNew, b.indexNew),
      render: (text, record) => <FormattedNumber value={record.indexNew} />,
    },
    {
      title: 'Vorläufig Index neu',
      dataIndex: 'vorlaeufigIndexNeu',
      key: 'vorlaeufigIndexNeu',
      width: 100,
      editable: false,
      render: (text, record) => <Typography.Text>{record.indexNewPreliminary ? 'Ja' : 'Nein'}</Typography.Text>,
    },
    {
      title: 'Faktor (%)',
      dataIndex: 'faktor',
      key: 'faktor',
      width: 100,
      align: 'right',
      editable: true,
      render: (text, record) => (
        <>
          <FormattedNumber value={record.factorNew} /> (<Percent value={record.factorNewPercent / 100} />)
        </>
      ),
      renderInEditMode: ({ selectedRecord }, formikProps) => (
        <FactorPercentFormItem
          name={vertragVposIndexedValueFormFields.factorPercent}
          indexationAgreementId={selectedRecord.indexationAgreement.vertragVposIndexationAgreementId}
          indexedValueId={selectedRecord.vertragVposIndexedValueId}
          formikProps={formikProps}
        />
      ),
    },
    {
      title: 'Errechneter Faktor (%)',
      dataIndex: 'errechneterFaktor',
      key: 'errechneterFaktor',
      width: 100,
      align: 'right',
      editable: false,
      render: (text, record) => (
        <>
          <FormattedNumber value={record.calculatedFactorNew} /> (<Percent value={record.calculatedFactorNewPercent / 100} />)
        </>
      ),
    },
    {
      title: 'Betrag alt',
      dataIndex: 'betragAlt',
      key: 'betragAlt',
      width: 100,
      align: 'right',
      editable: false,
      sorter: (a, b) => compareTwoNumbersForSorting(a.amountOld, b.amountOld),
      render: (text, record) => {
        return record.vposWertOldId ? (
          <Link
            target="_blank"
            to={generatePathToBeVertragDetailsPageVertragsdatenVPosTab(
              record.objektId,
              record.bestandseinheitId,
              record.vertragId,
              record.vposWertOldId,
              [vorschreibungspositionId]
            )}
          >
            <EuroAmount value={record.amountOld} />
          </Link>
        ) : (
          <EuroAmount value={record.amountOld} />
        );
      },
    },
    {
      title: 'Errechneter Betrag neu',
      dataIndex: 'errechneterBetragNeu',
      key: 'errechneterBetragNeu',
      width: 100,
      align: 'right',
      editable: false,
      render: (text, record) => <EuroAmount value={record.calculatedAmountNew} />,
    },
    {
      title: 'Betrag neu',
      dataIndex: 'betragNeu',
      key: 'betragNeu',
      width: 100,
      align: 'right',
      editable: true,
      sorter: (a, b) => compareTwoNumbersForSorting(a.amountNew, b.amountNew),
      render: (text, record) =>
        isVertragVposIndexedValueStatusApplied(record.status.value) && record.vposWertNewId ? (
          <Link
            target="_blank"
            to={generatePathToBeVertragDetailsPageVertragsdatenVPosTab(
              record.objektId,
              record.bestandseinheitId,
              record.vertragId,
              record.vposWertNewId,
              [vorschreibungspositionId]
            )}
          >
            <EuroAmount value={record.amountNew} />
          </Link>
        ) : (
          <EuroAmount value={record.amountNew} />
        ),
      renderInEditMode: ({ selectedRecord }, formikProps) => (
        <AmountFormItem
          name={vertragVposIndexedValueFormFields.amount}
          indexationAgreementId={selectedRecord.indexationAgreement.vertragVposIndexationAgreementId}
          indexedValueId={selectedRecord.vertragVposIndexedValueId}
          formikProps={formikProps}
        />
      ),
    },
    {
      title: 'Erstellt am',
      width: 80,
      render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
    },
    {
      title: 'Ersteller',
      width: 80,
      render: (text, record) => (
        <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
      ),
    },
    {
      title: 'Aktion',
      key: 'action',
      width: 180,
      editable: true,
      render: (text, record) => <IndexedValueTableActions indexedValue={record} onAction={onAction} />,
      renderInEditMode: ({ cancelEdit, isSubmitLoading }) => <ActionButtonsInEditMode cancelEdit={cancelEdit} isSubmitLoading={isSubmitLoading} />,
    },
  ];
};

export default indexedValueTableColumns;
