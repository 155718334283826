import React, { FC } from 'react';
import { Empty } from 'antd';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import GenerierlaufStatusExpandedRow from '../../../shared/GenerierlaufStatusExpandedRow';
import { isVerarbeitungEntryTableRowExpandable } from '../../../verarbeitungHelpers';
import infoMailDeliveryDetermineRecipientListVerarbeitungEntryTableColumns from './InfoMailDeliveryDetermineRecipientListVerarbeitungEntryTableColumns';
import { InfoMailDeliveryDetermineRecipientListGenerierlaufEntry } from '../../../../../types';
import { filtersQueryParams } from '../../../VorschreibungMailDeliveryDetermineRecipientList/EntryListing/Filters/filtersQueryParams';
import { usePaginationQueryParams } from '../../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../../hooks/useQueryWithPagingAndSorting';
import { useInfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQuery } from '../../../gql/InfoMailDeliveryDetermineRecipientListGenerierLauf/InfoMailDeliveryDetermineRecipientListQueries.types';

type InfoMailDeliveryDetermineRecipientListVerarbeitungEntryTableProps = {
  queryParams: filtersQueryParams;
  generierlaufId: string;
};

const InfoMailDeliveryDetermineRecipientListVerarbeitungEntryTable: FC<InfoMailDeliveryDetermineRecipientListVerarbeitungEntryTableProps> = ({
  queryParams,
  generierlaufId,
}) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useInfoMailDeliveryDetermineRecipientListGenerierlaufEntryListQuery,
    {
      variables: {
        generierlaufId,
        ...queryParams,
      },
      fetchPolicy: 'no-cache',
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const entryList = data?.getInfoMailDeliveryDetermineRecipientListGenerierlaufEntryList.data.contentList || [];

  return (
    <TableWithColSelector<InfoMailDeliveryDetermineRecipientListGenerierlaufEntry>
      loading={loading}
      locale={{
        emptyText: <Empty description={<span>Keine Empfänger vorhanden</span>} />,
      }}
      size="small"
      dataSource={entryList}
      columns={infoMailDeliveryDetermineRecipientListVerarbeitungEntryTableColumns}
      pagination={pagination}
      onChange={handleTableSorting}
      rowKey={(record) => record.generierlaufEntryId}
      expandable={{
        expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
        rowExpandable: isVerarbeitungEntryTableRowExpandable,
      }}
      filterIdentifier="verarbeitung-info-mail-delivery-determine"
    />
  );
};

export default InfoMailDeliveryDetermineRecipientListVerarbeitungEntryTable;
