import React, { FC } from 'react';
import { Formik } from 'formik';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { DatePicker, Form, Input, Select } from 'formik-antd';
import { Col, Divider, Row, Space, Tooltip, Typography } from 'antd';
import { objektFormValidationSchema } from './objektFormValidationSchema';
import {
  mapFormValuesToObjektCreate,
  mapFormValuesToObjektUpdate,
  mapObjektToFormValues,
  objektFormFields,
  ObjektFormValues,
} from './objektFormMapper';
import FormButtons from '../../../../components/Button/FormButtons';
import ObjektVerwaltungsArtSelect from '../../VerwaltungsArt/ObjektVerwaltungsArtSelect';
import ObjektSachbearbeiterSelect from '../../Sachbearbeiter/ObjektSachbearbeiterSelect';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../components/DatePicker/DatePicker_formikAntD';
import AdresseListFormPart from '../../AddressListFormPart/AddressListFormPart';
import { showSuccessMsgUpdate } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { useCreateObjektMutation, useUpdateObjektMutation } from '../../gql/ObjektMutations.types';
import KurzBezeichnungFormPart from '../../../../shared/components/KurzBezeichnung/form/KurzBezeichnungFormPart';
import { showSuccessMsgCreateWithPath } from '../../../../components/message/message';
import { generatePathToObjektDetailsPage } from '../../objektUriPaths';
import RechtstraegerSelect from '../../../../shared/Rechtstraeger/RechtstraegerSelect';
import { isNotNil } from '../../../../helpers/assertionHelper';
import { Objekt } from '../../../../types';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';

type Props = {
  objekt?: Objekt;
  onSuccess: () => void;
  onCancel: () => void;
};

const ObjektForm: FC<Props> = ({ objekt, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'Objekt'>('Objekt');

  const initialValues = mapObjektToFormValues(objekt);

  const entity = `Objekt`;

  const [runCreate, { loading: loadingCreate }] = useCreateObjektMutation({
    onCompleted: (data) => {
      const { objektId, kurzBezeichnung } = data.createObjekt.data;
      showSuccessMsgCreateWithPath(entity, kurzBezeichnung, generatePathToObjektDetailsPage(objektId));
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateObjektMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const isRechnungsausstellerDisabled = isNotNil(objekt?.rechnungsAusstellerChangeable) ? !objekt!.rechnungsAusstellerChangeable : false;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const isVerwaltungsartDisabled = isNotNil(objekt?.verwaltungsartChangeable) ? !objekt!.verwaltungsartChangeable : false;

  return (
    <Formik<ObjektFormValues>
      initialValues={initialValues}
      validationSchema={objektFormValidationSchema}
      onSubmit={(values, formikHelpers) => {
        if (!objekt) {
          runCreate({ variables: { input: mapFormValuesToObjektCreate(values) } }).finally(() => formikHelpers.setSubmitting(false));
        } else {
          runUpdate({
            variables: {
              objektId: objekt.objektId,
              input: mapFormValuesToObjektUpdate(values, objekt),
            },
          }).finally(() => formikHelpers.setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Bezeichnung</Typography.Title>
            </Col>
            <Col span={18}>
              <KurzBezeichnungFormPart<ObjektFormValues>
                placeholder="Kurzbezeichnung"
                formikProps={formikProps}
                fieldHelp={getFieldHelpText('Objekt.kurzBezeichnung')}
              />
              <FormItemWithFieldHelp label="Merkmale" name={objektFormFields.tagList} fieldHelp={getFieldHelpText('Objekt.tagList')}>
                <Select id={objektFormFields.tagList} name={objektFormFields.tagList} mode="tags" placeholder="z.B. WE, BK" />
              </FormItemWithFieldHelp>
            </Col>
          </Row>
          <Divider />
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Adresse</Typography.Title>
            </Col>
            <Col span={18}>
              <AdresseListFormPart fieldNamePrefix="" formikProps={formikProps} fieldHelp={getFieldHelpText('Objekt.addressList')} />
            </Col>
          </Row>
          <Divider />
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Verwaltung</Typography.Title>
            </Col>
            <Col span={18}>
              <Row gutter={16}>
                <Col span={12}>
                  <FormItemWithFieldHelp
                    name={objektFormFields.verwaltungsart}
                    label="Verwaltungsart"
                    fieldHelp={getFieldHelpText('Objekt.verwaltungsart')}
                  >
                    <ObjektVerwaltungsArtSelect name={objektFormFields.verwaltungsart} disabled={isVerwaltungsartDisabled} />
                  </FormItemWithFieldHelp>
                </Col>
                <Col span={12}>
                  <FormItemWithFieldHelp
                    name={objektFormFields.verwaltungSeit}
                    label="Verwaltet seit"
                    fieldHelp={getFieldHelpText('Objekt.verwaltungSeit')}
                  >
                    <DatePicker
                      id={objektFormFields.verwaltungSeit}
                      name={objektFormFields.verwaltungSeit}
                      style={{ width: '100%' }}
                      format={DATE_DISPLAY_FORMAT_DEFAULT}
                    />
                  </FormItemWithFieldHelp>
                  {objekt && (
                    <FormItemWithFieldHelp
                      name={objektFormFields.verwaltungBis}
                      label="Verwaltet bis"
                      fieldHelp={getFieldHelpText('Objekt.verwaltungBis')}
                    >
                      <DatePicker
                        id={objektFormFields.verwaltungBis}
                        name={objektFormFields.verwaltungBis}
                        style={{ width: '100%' }}
                        format={DATE_DISPLAY_FORMAT_DEFAULT}
                      />
                    </FormItemWithFieldHelp>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Rechnungsaussteller</Typography.Title>
            </Col>
            <Col span={18}>
              <Row gutter={16}>
                <Col span={24}>
                  <FormItemWithFieldHelp
                    name={objektFormFields.rechnungsAusstellerId}
                    label="Rechnungsaussteller"
                    fieldHelp={getFieldHelpText('Objekt.rechnungsAusstellerId')}
                  >
                    <RechtstraegerSelect
                      size="middle"
                      name={objektFormFields.rechnungsAusstellerId}
                      placeholder="Rechnungsaussteller auswählen"
                      onChange={() => {
                        formikProps.setFieldValue(objektFormFields.rechnungsAusstellerBezeichnung, undefined);
                      }}
                      onNewItemCreated={(rechnungsAusstellerId) =>
                        formikProps.setFieldValue(objektFormFields.rechnungsAusstellerId, rechnungsAusstellerId)
                      }
                      onNewItemCreateSelected={() => formikProps.setFieldValue(objektFormFields.rechnungsAusstellerId, '')}
                      disabled={isRechnungsausstellerDisabled}
                    />
                  </FormItemWithFieldHelp>
                </Col>
                <Col span={24}>
                  <FormItemWithFieldHelp
                    name={objektFormFields.rechnungsAusstellerBezeichnung}
                    label={
                      <Space size={4}>
                        <Typography.Text>Bezeichnung</Typography.Text>
                        <Tooltip
                          title="Wenn keine alternative Bezeichnung angegeben wird, wird die Kurzbezeichnung vom ausgewählten Rechnungsaussteller
                              herangezogen (z.B in den Vorschreibungen)"
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </Space>
                    }
                    fieldHelp={getFieldHelpText('Objekt.rechnungsAusstellerBezeichnung')}
                  >
                    <Input id={objektFormFields.rechnungsAusstellerBezeichnung} name={objektFormFields.rechnungsAusstellerBezeichnung} />
                  </FormItemWithFieldHelp>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Sachbearbeiter</Typography.Title>
            </Col>
            <Col flex={18}>
              <Row gutter={16}>
                <Col span={24}>
                  <FormItemWithFieldHelp
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name={objektFormFields.sachbearbeiterId}
                    label="Sachbearbeiter"
                    fieldHelp={getFieldHelpText('Objekt.sachbearbeiterId')}
                  >
                    <ObjektSachbearbeiterSelect />
                  </FormItemWithFieldHelp>
                </Col>
              </Row>
            </Col>
          </Row>
          <FormButtons
            updateMode={!!objekt}
            isOkDisabled={formikProps.isSubmitting}
            onCancel={onCancel}
            isOkLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ObjektForm;
