import React, { FC } from 'react';
import { Typography } from 'antd';
import { EuroAmount } from '../../../components/Number';
import { StyledListSummaryText } from './styled/StyledListSummarySpace';

type Props = {
  text: string;
  value: number;
};

export const SummaryText: FC<Props> = ({ text, value }) => {
  return (
    <StyledListSummaryText>
      <Typography.Text>Summe {text}:</Typography.Text>
      <EuroAmount value={value} />
    </StyledListSummaryText>
  );
};
