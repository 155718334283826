import React, { FC } from 'react';
import { Button, Drawer, Space, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../../../hooks/useToggle';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../../components/Button/ButtonsAligned';
import HeVertragForm from '../Form/HeVertragForm';
import HeVertragRechtstraegerSelect, { HeVertragRechtstraegerAttributes } from './Filters/HeVertragRechtstraegerSelect';

type Props = {
  objektId: string;
  onSuccess: () => void;
  showPastContract: boolean;
  togglePastContract: () => void;
  selectedVertragspartnerId?: string;
  setSelectedVertragspartnerId: React.Dispatch<React.SetStateAction<string | undefined>>;
  objektVerwaltungSeit: string;
  vertragspartnerList: HeVertragRechtstraegerAttributes[];
};

const HeVertragButtonsAndDrawer: FC<Props> = ({
  objektId,
  onSuccess,
  showPastContract,
  togglePastContract,
  selectedVertragspartnerId,
  setSelectedVertragspartnerId,
  objektVerwaltungSeit,
  vertragspartnerList,
}) => {
  const [isDrawerOpen, toggleDrawerOpen] = useToggle();

  const onCreateSuccess = () => {
    onSuccess();
    toggleDrawerOpen();
  };

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing} align="space-between">
        <Space>
          <Typography.Text>Vertragspartner</Typography.Text>
          <HeVertragRechtstraegerSelect
            selectedRechtstraegerId={selectedVertragspartnerId}
            setSelectedRechtstraegerId={setSelectedVertragspartnerId}
            vertragspartnerList={vertragspartnerList}
          />
        </Space>
        <Space>
          <Button size="small" onClick={togglePastContract}>
            {showPastContract ? 'Inaktive Verträge ausblenden' : 'Alle Verträge anzeigen'}
          </Button>
          <Button size="small" icon={<PlusOutlined />} type="primary" onClick={toggleDrawerOpen}>
            Vertrag anlegen
          </Button>
        </Space>
      </ButtonsAligned>

      <Drawer title="Hauseigentümervertrag anlegen" width={720} open={isDrawerOpen} onClose={toggleDrawerOpen} destroyOnClose>
        <HeVertragForm objektId={objektId} objektVerwaltungSeit={objektVerwaltungSeit} onSuccess={onCreateSuccess} onCancel={toggleDrawerOpen} />
      </Drawer>
    </>
  );
};

export default HeVertragButtonsAndDrawer;
