import React, { FC } from 'react';
import { Empty } from 'antd';
import vorschreibungErstellenVerarbeitungEntryTableColumns from './vorschreibungErstellenVerarbeitungEntryTableColumns';
import GenerierlaufStatusExpandedRow from '../../../shared/GenerierlaufStatusExpandedRow';
import { isVerarbeitungEntryTableRowExpandable } from '../../../verarbeitungHelpers';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { AuftragGenerierlauf, AuftragGenerierlaufEntry } from '../../../../../types';
import { useAuftragGenerierlaufEntryListQuery } from '../../../gql/AuftragGenerierlauf/AuftragGenerierlaufQueries.types';
import { TVorschreibungErstellenVerarbeitungQueryParams } from './Filters/filtersQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../../hooks/useQueryWithPagingAndSorting';
import { usePaginationQueryParams } from '../../../../../hooks/usePaginationQueryParams';

type Props = {
  generierlauf: AuftragGenerierlauf;
  queryParams: TVorschreibungErstellenVerarbeitungQueryParams;
};

const VorschreibungErstellenVerarbeitungEntryTable: FC<Props> = ({ generierlauf, queryParams }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useAuftragGenerierlaufEntryListQuery,
    {
      variables: {
        generierlaufId: generierlauf.generierlaufId,
        ...queryParams,
      },
      fetchPolicy: 'no-cache',
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const entryList = data?.getAuftragGenerierlaufEntryList.data.contentList || [];

  return (
    <TableWithColSelector<AuftragGenerierlaufEntry>
      loading={loading}
      locale={{
        emptyText: <Empty description={<span>Keine Positionen vorhanden</span>} />,
      }}
      size="small"
      dataSource={entryList}
      pagination={pagination}
      onChange={handleTableSorting}
      columns={vorschreibungErstellenVerarbeitungEntryTableColumns}
      rowKey={(record) => record.generierlaufEntryId}
      expandable={{
        expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
        rowExpandable: isVerarbeitungEntryTableRowExpandable,
      }}
      filterIdentifier="verarbeitung-vorschreibung"
    />
  );
};

export default VorschreibungErstellenVerarbeitungEntryTable;
