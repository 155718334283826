import React, { ComponentType } from 'react';
import { useParams } from 'react-router-dom';
import { EmailVersandDetailsPageRouteParams } from './routes';
import { useMailQuery } from '../../features/EmailDelivery/gql/EmailDeliveryQueries.types';
import { useOnEmailDeliveryDetailsDataChangedEvents } from '../../features/EmailDelivery/useOnEmailDeliveryDataChangedEvents';
import {
  EmailDeliveryDetailsPageProps,
  emailDeliveryTypes,
  getEmailDeliveryKeyByEmailMailDeliveryLabel,
  getEmailDeliveryLabels,
} from '../../features/EmailDelivery/emailDeliveryHelpers';
import EmailDeliveryDetailsPage from './EmailDelivery/EmailDeliveryDetailsPage';

const EmailVersandDetailsPage = () => {
  const { mailId } = useParams() as EmailVersandDetailsPageRouteParams;

  const { data, loading, refetch } = useMailQuery({
    variables: {
      mailId,
    },
  });
  const mail = data?.getMail.data;

  useOnEmailDeliveryDetailsDataChangedEvents('emailDeliveryDetails', refetch, mailId);

  let DetailsPage: ComponentType<EmailDeliveryDetailsPageProps> = EmailDeliveryDetailsPage;

  if (mail?.labelList) {
    const labelList = getEmailDeliveryLabels(mail.labelList);
    labelList.find((label) => {
      const result = getEmailDeliveryKeyByEmailMailDeliveryLabel(label);
      if (!result) return false;
      const { page } = emailDeliveryTypes[result];
      if (page) DetailsPage = page;
      return true;
    });
  }

  return mail ? <DetailsPage mail={mail} loading={loading} refetch={refetch} /> : null;
};

export default EmailVersandDetailsPage;
