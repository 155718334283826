import React, { FC } from 'react';
import { Timeline, TimelineProps } from 'antd';
import AbrDefVersionTimelineDateTitle from './AbrDefVersionTimelineDateTitle';
import { sortVersionsDescendingByValidFrom } from '../../shared/abrDefHelpers';
import { StyledSkeleton } from '../../../../components/Loading';
import { AbrechnungsdefinitionVersionListData } from '../../shared/abrDefTypes';
import AbrDefVersionTimelineCard from './AbrDefVersionTimelineCard';

type AbrDefVersionTimelineProps = {
  abrechnungsdefinitionId: string;
  loading: boolean;
  versionList?: AbrechnungsdefinitionVersionListData[];
  onDelete: (version: AbrechnungsdefinitionVersionListData) => Promise<unknown>;
};

const AbrDefVersionTimeline: FC<AbrDefVersionTimelineProps> = ({ abrechnungsdefinitionId, loading, versionList, onDelete }) => {
  if (!versionList) return <StyledSkeleton loading={loading} active paragraph={{ rows: 4 }} />;
  const sortedVersionList = sortVersionsDescendingByValidFrom(versionList);

  const items: TimelineProps['items'] = sortedVersionList.map((version) => ({
    key: version.abrechnungsdefinitionVersionId,
    children: (
      <>
        <AbrDefVersionTimelineDateTitle version={version} />
        <AbrDefVersionTimelineCard
          abrechnungsdefinitionId={abrechnungsdefinitionId}
          version={version}
          isDeleteAllowed={isDeleteAllowed(versionList)}
          onDelete={onDelete}
        />
        {version.historicizedList?.map((versionHistoryItem) => (
          <AbrDefVersionTimelineCard
            key={versionHistoryItem.abrechnungsdefinitionVersionId}
            abrechnungsdefinitionId={abrechnungsdefinitionId}
            version={versionHistoryItem}
            isHistoricized
          />
        ))}
      </>
    ),
  }));

  return <Timeline items={items} />;
};

const isDeleteAllowed = (versionList: AbrechnungsdefinitionVersionListData[]) => versionList.length > 1;

export default AbrDefVersionTimeline;
