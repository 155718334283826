import antDesignLocalData_de_DE from 'antd/lib/locale/de_DE';

import antDesignLocalData_en_US from 'antd/lib/locale/en_US';

import messages_de from '../../translations/de.json';

import messages_en from '../../translations/en.json';
import { Locale } from './locale.model';

export const DEFAULT_LOCALE = process.env.NODE_ENV === 'test' ? Locale.US : Locale.DE;

// ant-design
const mapLocaleToAntDesignLocale = (locale: Locale) => {
  switch (locale) {
    case Locale.DE:
      return antDesignLocalData_de_DE;
    case Locale.US:
      return antDesignLocalData_en_US;
    default:
      return antDesignLocalData_de_DE;
  }
};

// react-intl
const mapLocaleToTranslations = (locale: Locale) => {
  switch (locale) {
    case Locale.DE:
      return messages_de;
    case Locale.US:
      return messages_en;
    default:
      return messages_de;
  }
};

export { mapLocaleToAntDesignLocale, mapLocaleToTranslations };
