import { compareTwoStringsForSorting } from '../../../../../helpers/stringHelper';
import { isSortingAsc, isSortingDesc, SortOrder } from '../../../../../components/Table/TableSorting/sortingHelpers';
import { FlattenZinslisteBestandseinheit } from './bestandseinheitListFlattingHelpers';
import { TVPosForVertragLevel } from '../../../Card/VPosForVertragLevelList/vPosForVertragLevelList.model';

export const sortVPosTableDetailsDataSource = (
  dataSource: FlattenZinslisteBestandseinheit[],
  vorschreibungspositionList: TVPosForVertragLevel[],
  sortOrder: SortOrder,
  sortedColumnIndex: number
) => {
  if (isSortingAsc(sortOrder)) {
    return [...dataSource].sort((a, b) => sortZinslisteBEList(a, b, sortedColumnIndex, vorschreibungspositionList));
  }

  if (isSortingDesc(sortOrder)) {
    return [...dataSource].sort((a, b) => sortZinslisteBEList(b, a, sortedColumnIndex, vorschreibungspositionList));
  }

  return dataSource;
};

function sortZinslisteBEList(
  dataA: FlattenZinslisteBestandseinheit,
  dataB: FlattenZinslisteBestandseinheit,
  sortedColumnIndex: number,
  vorschreibungspositionList: TVPosForVertragLevel[]
) {
  if (sortedColumnIndex === 0) {
    return compareBezeichnung(dataA, dataB);
  }
  if (sortedColumnIndex === 1) {
    return compareVertragsart(dataA, dataB);
  }

  if (sortedColumnIndex === 2) {
    return compareVertragspartner(dataA, dataB);
  }

  if (sortedColumnIndex > 2) {
    return compareVPosNetto(dataA, dataB, vorschreibungspositionList, sortedColumnIndex);
  }

  return 0;
}

const compareBezeichnung = (dataA: FlattenZinslisteBestandseinheit, dataB: FlattenZinslisteBestandseinheit) =>
  compareTwoStringsForSorting(dataA.bezeichnung, dataB.bezeichnung);
const compareVertragsart = (dataA: FlattenZinslisteBestandseinheit, dataB: FlattenZinslisteBestandseinheit) =>
  compareTwoStringsForSorting(dataA.vertrag.vertragsart.text, dataB.vertrag.vertragsart.text);
const compareVertragspartner = (dataA: FlattenZinslisteBestandseinheit, dataB: FlattenZinslisteBestandseinheit) =>
  compareTwoStringsForSorting(dataA.vertrag.vertragspartner.kurzBezeichnung, dataB.vertrag.vertragspartner.kurzBezeichnung);
const compareVPosNetto = (
  dataA: FlattenZinslisteBestandseinheit,
  dataB: FlattenZinslisteBestandseinheit,
  vorschreibungspositionList: TVPosForVertragLevel[],
  sortedColumnIndex: number
) => {
  const beVertragVPosForCellA =
    dataA.vertrag.vertragVorschreibungspositionList.find(
      (beVertragVPos) => beVertragVPos.bezeichnung === vorschreibungspositionList[sortedColumnIndex - 3].bezeichnung
    )?.netto ?? 0;
  const beVertragVPosForCellB =
    dataB.vertrag.vertragVorschreibungspositionList.find(
      (beVertragVPos) => beVertragVPos.bezeichnung === vorschreibungspositionList[sortedColumnIndex - 3].bezeichnung
    )?.netto ?? 0;

  return beVertragVPosForCellA - beVertragVPosForCellB;
};
