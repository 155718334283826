import React, { FC, useState } from 'react';
import StatusBadge from '../../../../components/Status/StatusBadge';
import { useRechtsTraegerVertragspartnerLazyQuery } from '../../../Rechtstraeger/Rolle/Vertragspartner/gql/VertragspartnerQueries.types';
import { MahnungListEntry } from '../../../../types';

type MahnungGesperrtStatusProps = {
  record: MahnungListEntry;
};

const MahnungGesperrtStatus: FC<MahnungGesperrtStatusProps> = ({ record }) => {
  const [kommentar, setKommentar] = useState<string>('');
  const [runQuery] = useRechtsTraegerVertragspartnerLazyQuery({
    variables: {
      rechtstraegerId: record.vertragspartner.rechtstraegerId,
    },
  });

  const onTooltipOpenChange = (open: boolean) => {
    if (open) {
      runQuery().then((res) => {
        const mahnsperreKommentar = res.data?.getVertragspartner.data.mahnsperreKommentar;
        setKommentar(mahnsperreKommentar ?? 'Kein Grund für Sperre angegeben');
      });
    }
  };

  const kommentarForTooltip = <div style={{ whiteSpace: 'pre' }}>{kommentar !== '' ? kommentar : 'Laden...'}</div>;

  return (
    <StatusBadge
      entityStatus={record.status}
      showStatusDescriptionInTooltip={false}
      extraTextForBadgeTooltip={kommentarForTooltip}
      onBadgeTooltipOpen={onTooltipOpenChange}
    />
  );
};

export default MahnungGesperrtStatus;
