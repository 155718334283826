import { FC } from 'react';
import { Typography } from 'antd';
import { Spacer } from '../../../components/Grid';
import { verarbeitungPageAndMenuListTitles } from '../verarbeitungHelpers';
import InfoMailDeliveryDetermineRecipientListVerarbeitungEntryListing from './EntryListing/InfoMailDeliveryDetermineRecipientListVerarbeitungEntryListing';
import { InfoMailDeliveryDetermineRecipientListGenerierlauf } from '../../../types';

type VerarbeitungInfoMailDeliveryDetermineRecipientListProps = {
  generierlauf: InfoMailDeliveryDetermineRecipientListGenerierlauf;
};

const VerarbeitungInfoMailDeliveryDetermineRecipientList: FC<VerarbeitungInfoMailDeliveryDetermineRecipientListProps> = ({ generierlauf }) => {
  return (
    <>
      <Typography.Title level={4}>{verarbeitungPageAndMenuListTitles.InfoMailDeliveryDetermineRecipientList}</Typography.Title>
      <Spacer />
      <InfoMailDeliveryDetermineRecipientListVerarbeitungEntryListing generierlauf={generierlauf} />
    </>
  );
};

export default VerarbeitungInfoMailDeliveryDetermineRecipientList;
