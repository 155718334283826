import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { useBookingSuggestionListQuery } from '../gql/BookingSuggestionQueries.types';
import BookingSuggestionBuchungsmaskeTabs from './BookingSuggestionBuchungsmaskeTabs';

type Props = {
  receivedBankTransactionId: string;
};

const BookingSuggestionBuchungsmaskeWithTabs: FC<Props> = ({ receivedBankTransactionId }) => {
  const { data, refetch } = useBookingSuggestionListQuery({
    variables: {
      receivedBankTransactionId,
    },
  });

  const bookingSuggestionList = data?.getBookingSuggestionList.data;

  if (!bookingSuggestionList) return <Skeleton active />;

  return <BookingSuggestionBuchungsmaskeTabs bookingSuggestionList={bookingSuggestionList} onUpdateSuccess={refetch} />;
};

export default BookingSuggestionBuchungsmaskeWithTabs;
