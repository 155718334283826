import React, { FC } from 'react';
import { Row } from 'antd';
import PDFPageCol from './PDFPageCol';
import SepaLastschriftMandatTemplate from './templates/sepaLastschriftMandat/SepaLastschriftMandatTemplate';
import { mockSepaLastschriftMandatWithoutValues, mockSepaLastschriftMandatWithValues } from './mocks/sepaLastschriftMandatMocks';

const PDFPageSEPALastschriftMandat: FC = () => (
  <Row>
    <PDFPageCol pdfTemplate={<SepaLastschriftMandatTemplate data={mockSepaLastschriftMandatWithValues} />} />
    <PDFPageCol pdfTemplate={<SepaLastschriftMandatTemplate data={mockSepaLastschriftMandatWithoutValues} />} />
  </Row>
);

export default PDFPageSEPALastschriftMandat;
