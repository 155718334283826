import { FormFields } from '../../../../../../helpers/formikHelper';
import { ContactType } from '../../../../../../shared/components/Contact/contactHelper';
import { Contact } from '../../../../../../types';

export type PhoneContactUpdateFormValues = {
  contactId: string;
  type: ContactType.PHONE;
  wert: string;
};

export const phoneContactUpdateFormFields: FormFields<PhoneContactUpdateFormValues> = {
  contactId: 'contactId',
  type: 'type',
  wert: 'wert',
};

export const mapPhoneContactToFormValues = (values: Contact): PhoneContactUpdateFormValues => ({
  contactId: values.contactId,
  type: ContactType.PHONE,
  wert: values.wert,
});
