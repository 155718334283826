import React, { FC, useState } from 'react';
import { TextbausteinType } from '../../../types';
import PDFTemplatePage, { TemplatePreviewProps } from '../../../components/Template/PDFTemplates/PDFTemplatePage';
import { TextbausteinList } from '../../../components/Template/PDFTemplates/TextbausteinList/TextbausteinList';
import { TextbausteinFormValues } from '../../../components/Template/PDFTemplates/templateMapper';
import { updateTextbausteinListValuesWithType } from '../../../components/Template/PDFTemplates/templateHelpers';

type Props = {
  templateFormValues: TextbausteinFormValues[];
  onTemplateChange: (templateValues: TextbausteinFormValues[]) => void;
  renderPreview: (props: TemplatePreviewProps) => React.ReactNode;
};

const MahnstufeTemplate: FC<Props> = ({ templateFormValues, onTemplateChange, renderPreview }) => {
  const [selectedTextbaustein, setSelectedTextbaustein] = useState<TextbausteinType>();

  const setSelectedTextbausteinToNewOrDefaultValue = (newValue: TextbausteinType) => {
    setSelectedTextbaustein((currentSelected) => (currentSelected !== newValue ? newValue : undefined));
  };

  return (
    <PDFTemplatePage
      textbausteinList={
        <TextbausteinList
          textbausteinList={templateFormValues}
          onTemplateChange={onTemplateChange}
          onSelectTextbaustein={setSelectedTextbausteinToNewOrDefaultValue}
          onChange={(textbausteinType, newValue) =>
            onTemplateChange(updateTextbausteinListValuesWithType(templateFormValues, textbausteinType, newValue))
          }
          level={0}
        />
      }
      renderPreview={renderPreview}
      selectedTextbaustein={selectedTextbaustein}
    />
  );
};

export default MahnstufeTemplate;
