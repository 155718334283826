import React, { FC } from 'react';
import { Skeleton } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import { useTicketQuery } from '../../gql/TicketQueries.types';
import { mapFormValuesToTicketUpdateInput, mapTicketToFormValues, ticketFormFields, TicketFormValues } from '../ticketFormMapper';
import { useUpdateTicketMutation } from '../../gql/TicketMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import FormButtons from '../../../../components/Button/FormButtons';
import { showSuccessMsgUpdate } from '../../../../components/message';
import ParticipantSelect from '../ParticipantSelect/ParticipantSelect';
import { useOnTicketDetailsDataChangedEvents } from '../../useOnTicketDataChangedEvents';
import { ticketParticipantsUpdateFormValidationSchema } from './ticketParticipantsUpdateFormValidationSchema';

type Props = {
  ticketId: string;
  onSuccess: () => void;
  onCancel: () => void;
};

const TicketParticipantsUpdateForm: FC<Props> = ({ ticketId, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'Ticket'>('Ticket');

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data, loading, refetch } = useTicketQuery({ variables: { ticketId: ticketId! }, skip: !ticketId });

  const ticket = data?.getTicket.data;
  const isUpdate = !!ticket;

  useOnTicketDetailsDataChangedEvents('ticket-participants', refetch, ticketId);

  const [runUpdate, { loading: loadingUpdate }] = useUpdateTicketMutation({
    onCompleted: () => {
      showSuccessMsgUpdate('Die Beteiligten der Aufgabe');
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const isLoading = loadingUpdate || loading;

  return (
    <Skeleton active paragraph={{ rows: 10 }} loading={loading}>
      <Formik<TicketFormValues>
        initialValues={mapTicketToFormValues(false, ticket)}
        validationSchema={ticketParticipantsUpdateFormValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (!isUpdate) return;
          setSubmitting(true);
          runUpdate({
            variables: { ticketId: ticket.ticketId, input: mapFormValuesToTicketUpdateInput(values) },
          }).finally(() => {
            setSubmitting(false);
          });
        }}
      >
        {(formikProps) => (
          <Form layout="vertical">
            <FormItemWithFieldHelp
              name={ticketFormFields.participantList}
              label="Beteiligte"
              fieldHelp={getFieldHelpText('Ticket.participantList')}
              help={formikProps.errors.participantList && <>E-Mail-Adresse muss gültig sein</>}
              validateStatus={formikProps.errors.participantList ? 'error' : undefined}
            >
              <ParticipantSelect id={ticketFormFields.participantList} name={ticketFormFields.participantList} />
            </FormItemWithFieldHelp>
            <FormButtons updateMode={isUpdate} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} isOkLoading={isLoading} />
          </Form>
        )}
      </Formik>
    </Skeleton>
  );
};

export default TicketParticipantsUpdateForm;
