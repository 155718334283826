import React, { FC } from 'react';
import { Formik } from 'formik';
import { Col, Row, Typography } from 'antd';
import { Form } from 'formik-antd';
import { STREET_ADDRESS_FORM_INITIAL_VALUES_DEFAULT } from './addressMapper';
import { streetAddressValidationSchema } from './addressFieldsValidationSchema';
import StreetAddressFormPart from './StreetAddressFormPart';
import FormButtons from '../../../../components/Button/FormButtons';
import { StreetAddressFormValues } from '../AddressSharedModel';
import { FormikYupValidationSchema } from '../../../../helpers/formikHelper';
import { useGetAppFieldHelpText } from '../../../../features/FieldHelp/useGetFieldHelpText';

type Props = {
  formInitialValues?: StreetAddressFormValues;
  formValidationSchema?: FormikYupValidationSchema;
  onSubmit: (formValues: StreetAddressFormValues) => void;
  onCancel: () => void;
  isUpdate: boolean;
  loading?: boolean;
};

const StreetAddressForm: FC<Props> = ({
  formInitialValues = STREET_ADDRESS_FORM_INITIAL_VALUES_DEFAULT,
  formValidationSchema = streetAddressValidationSchema,
  onSubmit,
  onCancel,
  isUpdate,
  loading,
}) => {
  const getFieldHelpText = useGetAppFieldHelpText<'AddressStreet'>('AddressStreet');

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={formValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Adresse</Typography.Title>
            </Col>
            <Col span={18}>
              <StreetAddressFormPart
                fieldHelpNames={{
                  street: getFieldHelpText('AddressStreet.street'),
                  houseEntranceApartmentNumber: getFieldHelpText('AddressStreet.houseEntranceApartmentNumber'),
                  zipCode: getFieldHelpText('AddressStreet.zipCode'),
                  city: getFieldHelpText('AddressStreet.city'),
                  cityAdditionalInformation: getFieldHelpText('AddressStreet.cityAdditionalInformation'),
                  countryCodeIso2: getFieldHelpText('AddressStreet.countryCodeIso2'),
                }}
              />
            </Col>
          </Row>
          <FormButtons updateMode={isUpdate} isOkLoading={loading} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default StreetAddressForm;
