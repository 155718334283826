import { FC, useContext } from 'react';
import { useVertragVposIndexedValueChangeHistoryListQuery } from '../../../../../../../IndexedValue/gql/VertragVposIndexedValueQueries.types';
import { IndexationAgreementContext } from '../../Level2/IndexationAgreementContext';
import { VertragVposIndexedValue } from '../../../../../../../../types';
import HistoryListingTable from '../../../../../../../History/HistoryListingTable';
import { HistoryType } from '../../../../../../../History/shared/HistoryModal';

type Props = { indexedValue: VertragVposIndexedValue };

const IndexedValueChangeHistoryListingTable: FC<Props> = ({ indexedValue }) => {
  const { bestandseinheitId, objektId, vertragId, vorschreibungspositionId } = useContext(IndexationAgreementContext);

  const { data, loading } = useVertragVposIndexedValueChangeHistoryListQuery({
    variables: {
      objektId,
      bestandseinheitId,
      vertragId,
      vorschreibungspositionId,
      indexationAgreementId: indexedValue.indexationAgreement.vertragVposIndexationAgreementId,
      indexedValueId: indexedValue.vertragVposIndexedValueId,
    },
  });

  const historyList = data?.getVertragVposIndexedValueChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="indexedValue" />;
};

export default IndexedValueChangeHistoryListingTable;
