import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { BestandseinheitBaseFieldsFragmentDoc } from '../../../Bestandseinheit/gql/BestandseinheitFragments.types';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
import { IndexSeriesFieldsFragmentDoc } from '../../../IndexSeries/gql/IndexSeriesFragments.types';
import { ObjektBaseFragmentDoc } from '../../../Objekt/gql/ObjektFragments.types';
import { VertragBaseFieldsFragmentDoc } from '../../../Vertrag/gql/VertragFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FilterGenLaufAppBestandseinheitListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.AppGenerierlaufType;
  objektId: Types.Scalars['ID']['input'];
}>;

export type FilterGenLaufAppBestandseinheitListQuery = {
  getFilterGenLaufAppBestandseinheitList: {
    data: Array<{
      bestandseinheitId: string;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      objektId: string;
      status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufAppGenerierlaufEntryStatusListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.AppGenerierlaufType;
}>;

export type FilterGenLaufAppGenerierlaufEntryStatusListQuery = {
  getFilterGenLaufAppGenerierlaufEntryStatusList: {
    data: Array<{ text: string; value: Types.GenerierlaufEntryExitCode }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufAppHauseigentuemerListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.AppGenerierlaufType;
}>;

export type FilterGenLaufAppHauseigentuemerListQuery = {
  getFilterGenLaufAppHauseigentuemerList: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufAppIndexSeriesListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.AppGenerierlaufType;
}>;

export type FilterGenLaufAppIndexSeriesListQuery = {
  getFilterGenLaufAppIndexSeriesList: {
    data: Array<{
      chainingFactor?: number | null;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      csvColumnImportId?: string | null;
      csvColumnIndexPoint?: string | null;
      csvColumnMonthYear?: string | null;
      csvRowImportId?: string | null;
      deletable: boolean;
      firmendatenId?: string | null;
      importUrl?: string | null;
      indexSeriesId: string;
      name: string;
      updatedByMitarbeiterId?: string | null;
      indexSeriesEntryList: Array<{
        annualAverageFinal?: number | null;
        annualAveragePreliminary?: number | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        indexSeriesEntryId: string;
        updatedByMitarbeiterId?: string | null;
        year: number;
        indexPointList: Array<{
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          finalValue?: number | null;
          indexPointId: string;
          month: string;
          preliminaryValue?: number | null;
          updatedByMitarbeiterId?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      indexType: { text: string; value: Types.IndexType };
      sourceType: { text: string; value: Types.IndexSeriesSourceType };
      status?: { text: string; value: Types.IndexSeriesStatus } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufAppObjektListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.AppGenerierlaufType;
  rechnungsAusstellerId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type FilterGenLaufAppObjektListQuery = {
  getFilterGenLaufAppObjektList: {
    data: Array<{
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      objektId: string;
      updatedByMitarbeiterId?: string | null;
      rechnungsAussteller: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      status: { text: string; value: Types.ObjektStatus; description?: string | null };
      verwaltungsart: { text: string; value: Types.Verwaltungsart };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufAppRechnungsAusstellerListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.AppGenerierlaufType;
}>;

export type FilterGenLaufAppRechnungsAusstellerListQuery = {
  getFilterGenLaufAppRechnungsAusstellerList: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufAppRechnungsEmpfaengerListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.AppGenerierlaufType;
}>;

export type FilterGenLaufAppRechnungsEmpfaengerListQuery = {
  getFilterGenLaufAppRechnungsEmpfaengerList: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufAppValuePreservationTypeListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.AppGenerierlaufType;
}>;

export type FilterGenLaufAppValuePreservationTypeListQuery = {
  getFilterGenLaufAppValuePreservationTypeList: {
    data: Array<{ text: string; value: Types.ValuePreservationType }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufAppVertragListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.AppGenerierlaufType;
}>;

export type FilterGenLaufAppVertragListQuery = {
  getFilterGenLaufAppVertragList: {
    data: Array<{
      bestandseinheitId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      objektId: string;
      vertragId: string;
      vertragsBeginn: string;
      status: { text: string; value: Types.VertragStatus; description?: string | null };
      vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
      vertragspartner: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufAppVorschreibungsperiodeListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.AppGenerierlaufType;
}>;

export type FilterGenLaufAppVorschreibungsperiodeListQuery = {
  getFilterGenLaufAppVorschreibungsperiodeList: {
    data: Array<{ jahr: number; monat: number }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufAppRecipientEmailAddressListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.AppGenerierlaufType;
}>;

export type FilterGenLaufAppRecipientEmailAddressListQuery = {
  getFilterGenLaufAppRecipientEmailAddressList: {
    data?: Array<string> | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufAppRecipientListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.AppGenerierlaufType;
}>;

export type FilterGenLaufAppRecipientListQuery = {
  getFilterGenLaufAppRecipientList: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufAppVertragspartnerListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.AppGenerierlaufType;
}>;

export type FilterGenLaufAppVertragspartnerListQuery = {
  getFilterGenLaufAppVertragspartnerList: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufAppEigentuemerListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.AppGenerierlaufType;
}>;

export type FilterGenLaufAppEigentuemerListQuery = {
  getFilterGenLaufAppEigentuemerList: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FilterGenLaufAppWeRechnungsAusstellerListQueryVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
  generierlaufType: Types.AppGenerierlaufType;
}>;

export type FilterGenLaufAppWeRechnungsAusstellerListQuery = {
  getFilterGenLaufAppWeRechnungsAusstellerList: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const FilterGenLaufAppBestandseinheitListDocument = gql`
  query FilterGenLaufAppBestandseinheitList($generierlaufId: ID!, $generierlaufType: AppGenerierlaufType!, $objektId: ID!) {
    getFilterGenLaufAppBestandseinheitList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType, objektId: $objektId) {
      data {
        ...BestandseinheitBaseFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BestandseinheitBaseFieldsFragmentDoc}
`;
export function useFilterGenLaufAppBestandseinheitListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufAppBestandseinheitListQuery, FilterGenLaufAppBestandseinheitListQueryVariables> &
    ({ variables: FilterGenLaufAppBestandseinheitListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufAppBestandseinheitListQuery, FilterGenLaufAppBestandseinheitListQueryVariables>(
    FilterGenLaufAppBestandseinheitListDocument,
    options
  );
}
export function useFilterGenLaufAppBestandseinheitListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufAppBestandseinheitListQuery, FilterGenLaufAppBestandseinheitListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufAppBestandseinheitListQuery, FilterGenLaufAppBestandseinheitListQueryVariables>(
    FilterGenLaufAppBestandseinheitListDocument,
    options
  );
}
export function useFilterGenLaufAppBestandseinheitListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufAppBestandseinheitListQuery, FilterGenLaufAppBestandseinheitListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufAppBestandseinheitListQuery, FilterGenLaufAppBestandseinheitListQueryVariables>(
    FilterGenLaufAppBestandseinheitListDocument,
    options
  );
}
export type FilterGenLaufAppBestandseinheitListQueryHookResult = ReturnType<typeof useFilterGenLaufAppBestandseinheitListQuery>;
export type FilterGenLaufAppBestandseinheitListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufAppBestandseinheitListLazyQuery>;
export type FilterGenLaufAppBestandseinheitListSuspenseQueryHookResult = ReturnType<typeof useFilterGenLaufAppBestandseinheitListSuspenseQuery>;
export type FilterGenLaufAppBestandseinheitListQueryResult = Apollo.QueryResult<
  FilterGenLaufAppBestandseinheitListQuery,
  FilterGenLaufAppBestandseinheitListQueryVariables
>;
export const FilterGenLaufAppGenerierlaufEntryStatusListDocument = gql`
  query FilterGenLaufAppGenerierlaufEntryStatusList($generierlaufId: ID!, $generierlaufType: AppGenerierlaufType!) {
    getFilterGenLaufAppGenerierlaufEntryStatusList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufAppGenerierlaufEntryStatusListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufAppGenerierlaufEntryStatusListQuery, FilterGenLaufAppGenerierlaufEntryStatusListQueryVariables> &
    ({ variables: FilterGenLaufAppGenerierlaufEntryStatusListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufAppGenerierlaufEntryStatusListQuery, FilterGenLaufAppGenerierlaufEntryStatusListQueryVariables>(
    FilterGenLaufAppGenerierlaufEntryStatusListDocument,
    options
  );
}
export function useFilterGenLaufAppGenerierlaufEntryStatusListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterGenLaufAppGenerierlaufEntryStatusListQuery,
    FilterGenLaufAppGenerierlaufEntryStatusListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufAppGenerierlaufEntryStatusListQuery, FilterGenLaufAppGenerierlaufEntryStatusListQueryVariables>(
    FilterGenLaufAppGenerierlaufEntryStatusListDocument,
    options
  );
}
export function useFilterGenLaufAppGenerierlaufEntryStatusListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufAppGenerierlaufEntryStatusListQuery, FilterGenLaufAppGenerierlaufEntryStatusListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufAppGenerierlaufEntryStatusListQuery, FilterGenLaufAppGenerierlaufEntryStatusListQueryVariables>(
    FilterGenLaufAppGenerierlaufEntryStatusListDocument,
    options
  );
}
export type FilterGenLaufAppGenerierlaufEntryStatusListQueryHookResult = ReturnType<typeof useFilterGenLaufAppGenerierlaufEntryStatusListQuery>;
export type FilterGenLaufAppGenerierlaufEntryStatusListLazyQueryHookResult = ReturnType<
  typeof useFilterGenLaufAppGenerierlaufEntryStatusListLazyQuery
>;
export type FilterGenLaufAppGenerierlaufEntryStatusListSuspenseQueryHookResult = ReturnType<
  typeof useFilterGenLaufAppGenerierlaufEntryStatusListSuspenseQuery
>;
export type FilterGenLaufAppGenerierlaufEntryStatusListQueryResult = Apollo.QueryResult<
  FilterGenLaufAppGenerierlaufEntryStatusListQuery,
  FilterGenLaufAppGenerierlaufEntryStatusListQueryVariables
>;
export const FilterGenLaufAppHauseigentuemerListDocument = gql`
  query FilterGenLaufAppHauseigentuemerList($generierlaufId: ID!, $generierlaufType: AppGenerierlaufType!) {
    getFilterGenLaufAppHauseigentuemerList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        ...RechtstraegerBaseFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
export function useFilterGenLaufAppHauseigentuemerListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufAppHauseigentuemerListQuery, FilterGenLaufAppHauseigentuemerListQueryVariables> &
    ({ variables: FilterGenLaufAppHauseigentuemerListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufAppHauseigentuemerListQuery, FilterGenLaufAppHauseigentuemerListQueryVariables>(
    FilterGenLaufAppHauseigentuemerListDocument,
    options
  );
}
export function useFilterGenLaufAppHauseigentuemerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufAppHauseigentuemerListQuery, FilterGenLaufAppHauseigentuemerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufAppHauseigentuemerListQuery, FilterGenLaufAppHauseigentuemerListQueryVariables>(
    FilterGenLaufAppHauseigentuemerListDocument,
    options
  );
}
export function useFilterGenLaufAppHauseigentuemerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufAppHauseigentuemerListQuery, FilterGenLaufAppHauseigentuemerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufAppHauseigentuemerListQuery, FilterGenLaufAppHauseigentuemerListQueryVariables>(
    FilterGenLaufAppHauseigentuemerListDocument,
    options
  );
}
export type FilterGenLaufAppHauseigentuemerListQueryHookResult = ReturnType<typeof useFilterGenLaufAppHauseigentuemerListQuery>;
export type FilterGenLaufAppHauseigentuemerListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufAppHauseigentuemerListLazyQuery>;
export type FilterGenLaufAppHauseigentuemerListSuspenseQueryHookResult = ReturnType<typeof useFilterGenLaufAppHauseigentuemerListSuspenseQuery>;
export type FilterGenLaufAppHauseigentuemerListQueryResult = Apollo.QueryResult<
  FilterGenLaufAppHauseigentuemerListQuery,
  FilterGenLaufAppHauseigentuemerListQueryVariables
>;
export const FilterGenLaufAppIndexSeriesListDocument = gql`
  query FilterGenLaufAppIndexSeriesList($generierlaufId: ID!, $generierlaufType: AppGenerierlaufType!) {
    getFilterGenLaufAppIndexSeriesList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        ...IndexSeriesFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${IndexSeriesFieldsFragmentDoc}
`;
export function useFilterGenLaufAppIndexSeriesListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufAppIndexSeriesListQuery, FilterGenLaufAppIndexSeriesListQueryVariables> &
    ({ variables: FilterGenLaufAppIndexSeriesListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufAppIndexSeriesListQuery, FilterGenLaufAppIndexSeriesListQueryVariables>(
    FilterGenLaufAppIndexSeriesListDocument,
    options
  );
}
export function useFilterGenLaufAppIndexSeriesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufAppIndexSeriesListQuery, FilterGenLaufAppIndexSeriesListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufAppIndexSeriesListQuery, FilterGenLaufAppIndexSeriesListQueryVariables>(
    FilterGenLaufAppIndexSeriesListDocument,
    options
  );
}
export function useFilterGenLaufAppIndexSeriesListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufAppIndexSeriesListQuery, FilterGenLaufAppIndexSeriesListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufAppIndexSeriesListQuery, FilterGenLaufAppIndexSeriesListQueryVariables>(
    FilterGenLaufAppIndexSeriesListDocument,
    options
  );
}
export type FilterGenLaufAppIndexSeriesListQueryHookResult = ReturnType<typeof useFilterGenLaufAppIndexSeriesListQuery>;
export type FilterGenLaufAppIndexSeriesListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufAppIndexSeriesListLazyQuery>;
export type FilterGenLaufAppIndexSeriesListSuspenseQueryHookResult = ReturnType<typeof useFilterGenLaufAppIndexSeriesListSuspenseQuery>;
export type FilterGenLaufAppIndexSeriesListQueryResult = Apollo.QueryResult<
  FilterGenLaufAppIndexSeriesListQuery,
  FilterGenLaufAppIndexSeriesListQueryVariables
>;
export const FilterGenLaufAppObjektListDocument = gql`
  query FilterGenLaufAppObjektList($generierlaufId: ID!, $generierlaufType: AppGenerierlaufType!, $rechnungsAusstellerId: ID) {
    getFilterGenLaufAppObjektList(
      generierlaufId: $generierlaufId
      generierlaufType: $generierlaufType
      rechnungsAusstellerId: $rechnungsAusstellerId
    ) {
      data {
        ...ObjektBase
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${ObjektBaseFragmentDoc}
`;
export function useFilterGenLaufAppObjektListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufAppObjektListQuery, FilterGenLaufAppObjektListQueryVariables> &
    ({ variables: FilterGenLaufAppObjektListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufAppObjektListQuery, FilterGenLaufAppObjektListQueryVariables>(FilterGenLaufAppObjektListDocument, options);
}
export function useFilterGenLaufAppObjektListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufAppObjektListQuery, FilterGenLaufAppObjektListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufAppObjektListQuery, FilterGenLaufAppObjektListQueryVariables>(FilterGenLaufAppObjektListDocument, options);
}
export function useFilterGenLaufAppObjektListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterGenLaufAppObjektListQuery, FilterGenLaufAppObjektListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufAppObjektListQuery, FilterGenLaufAppObjektListQueryVariables>(
    FilterGenLaufAppObjektListDocument,
    options
  );
}
export type FilterGenLaufAppObjektListQueryHookResult = ReturnType<typeof useFilterGenLaufAppObjektListQuery>;
export type FilterGenLaufAppObjektListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufAppObjektListLazyQuery>;
export type FilterGenLaufAppObjektListSuspenseQueryHookResult = ReturnType<typeof useFilterGenLaufAppObjektListSuspenseQuery>;
export type FilterGenLaufAppObjektListQueryResult = Apollo.QueryResult<FilterGenLaufAppObjektListQuery, FilterGenLaufAppObjektListQueryVariables>;
export const FilterGenLaufAppRechnungsAusstellerListDocument = gql`
  query FilterGenLaufAppRechnungsAusstellerList($generierlaufId: ID!, $generierlaufType: AppGenerierlaufType!) {
    getFilterGenLaufAppRechnungsAusstellerList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        ...RechtstraegerBaseFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
export function useFilterGenLaufAppRechnungsAusstellerListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufAppRechnungsAusstellerListQuery, FilterGenLaufAppRechnungsAusstellerListQueryVariables> &
    ({ variables: FilterGenLaufAppRechnungsAusstellerListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufAppRechnungsAusstellerListQuery, FilterGenLaufAppRechnungsAusstellerListQueryVariables>(
    FilterGenLaufAppRechnungsAusstellerListDocument,
    options
  );
}
export function useFilterGenLaufAppRechnungsAusstellerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufAppRechnungsAusstellerListQuery, FilterGenLaufAppRechnungsAusstellerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufAppRechnungsAusstellerListQuery, FilterGenLaufAppRechnungsAusstellerListQueryVariables>(
    FilterGenLaufAppRechnungsAusstellerListDocument,
    options
  );
}
export function useFilterGenLaufAppRechnungsAusstellerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufAppRechnungsAusstellerListQuery, FilterGenLaufAppRechnungsAusstellerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufAppRechnungsAusstellerListQuery, FilterGenLaufAppRechnungsAusstellerListQueryVariables>(
    FilterGenLaufAppRechnungsAusstellerListDocument,
    options
  );
}
export type FilterGenLaufAppRechnungsAusstellerListQueryHookResult = ReturnType<typeof useFilterGenLaufAppRechnungsAusstellerListQuery>;
export type FilterGenLaufAppRechnungsAusstellerListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufAppRechnungsAusstellerListLazyQuery>;
export type FilterGenLaufAppRechnungsAusstellerListSuspenseQueryHookResult = ReturnType<
  typeof useFilterGenLaufAppRechnungsAusstellerListSuspenseQuery
>;
export type FilterGenLaufAppRechnungsAusstellerListQueryResult = Apollo.QueryResult<
  FilterGenLaufAppRechnungsAusstellerListQuery,
  FilterGenLaufAppRechnungsAusstellerListQueryVariables
>;
export const FilterGenLaufAppRechnungsEmpfaengerListDocument = gql`
  query FilterGenLaufAppRechnungsEmpfaengerList($generierlaufId: ID!, $generierlaufType: AppGenerierlaufType!) {
    getFilterGenLaufAppRechnungsEmpfaengerList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        ...RechtstraegerBaseFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
export function useFilterGenLaufAppRechnungsEmpfaengerListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufAppRechnungsEmpfaengerListQuery, FilterGenLaufAppRechnungsEmpfaengerListQueryVariables> &
    ({ variables: FilterGenLaufAppRechnungsEmpfaengerListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufAppRechnungsEmpfaengerListQuery, FilterGenLaufAppRechnungsEmpfaengerListQueryVariables>(
    FilterGenLaufAppRechnungsEmpfaengerListDocument,
    options
  );
}
export function useFilterGenLaufAppRechnungsEmpfaengerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufAppRechnungsEmpfaengerListQuery, FilterGenLaufAppRechnungsEmpfaengerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufAppRechnungsEmpfaengerListQuery, FilterGenLaufAppRechnungsEmpfaengerListQueryVariables>(
    FilterGenLaufAppRechnungsEmpfaengerListDocument,
    options
  );
}
export function useFilterGenLaufAppRechnungsEmpfaengerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufAppRechnungsEmpfaengerListQuery, FilterGenLaufAppRechnungsEmpfaengerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufAppRechnungsEmpfaengerListQuery, FilterGenLaufAppRechnungsEmpfaengerListQueryVariables>(
    FilterGenLaufAppRechnungsEmpfaengerListDocument,
    options
  );
}
export type FilterGenLaufAppRechnungsEmpfaengerListQueryHookResult = ReturnType<typeof useFilterGenLaufAppRechnungsEmpfaengerListQuery>;
export type FilterGenLaufAppRechnungsEmpfaengerListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufAppRechnungsEmpfaengerListLazyQuery>;
export type FilterGenLaufAppRechnungsEmpfaengerListSuspenseQueryHookResult = ReturnType<
  typeof useFilterGenLaufAppRechnungsEmpfaengerListSuspenseQuery
>;
export type FilterGenLaufAppRechnungsEmpfaengerListQueryResult = Apollo.QueryResult<
  FilterGenLaufAppRechnungsEmpfaengerListQuery,
  FilterGenLaufAppRechnungsEmpfaengerListQueryVariables
>;
export const FilterGenLaufAppValuePreservationTypeListDocument = gql`
  query FilterGenLaufAppValuePreservationTypeList($generierlaufId: ID!, $generierlaufType: AppGenerierlaufType!) {
    getFilterGenLaufAppValuePreservationTypeList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufAppValuePreservationTypeListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufAppValuePreservationTypeListQuery, FilterGenLaufAppValuePreservationTypeListQueryVariables> &
    ({ variables: FilterGenLaufAppValuePreservationTypeListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufAppValuePreservationTypeListQuery, FilterGenLaufAppValuePreservationTypeListQueryVariables>(
    FilterGenLaufAppValuePreservationTypeListDocument,
    options
  );
}
export function useFilterGenLaufAppValuePreservationTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufAppValuePreservationTypeListQuery, FilterGenLaufAppValuePreservationTypeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufAppValuePreservationTypeListQuery, FilterGenLaufAppValuePreservationTypeListQueryVariables>(
    FilterGenLaufAppValuePreservationTypeListDocument,
    options
  );
}
export function useFilterGenLaufAppValuePreservationTypeListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufAppValuePreservationTypeListQuery, FilterGenLaufAppValuePreservationTypeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufAppValuePreservationTypeListQuery, FilterGenLaufAppValuePreservationTypeListQueryVariables>(
    FilterGenLaufAppValuePreservationTypeListDocument,
    options
  );
}
export type FilterGenLaufAppValuePreservationTypeListQueryHookResult = ReturnType<typeof useFilterGenLaufAppValuePreservationTypeListQuery>;
export type FilterGenLaufAppValuePreservationTypeListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufAppValuePreservationTypeListLazyQuery>;
export type FilterGenLaufAppValuePreservationTypeListSuspenseQueryHookResult = ReturnType<
  typeof useFilterGenLaufAppValuePreservationTypeListSuspenseQuery
>;
export type FilterGenLaufAppValuePreservationTypeListQueryResult = Apollo.QueryResult<
  FilterGenLaufAppValuePreservationTypeListQuery,
  FilterGenLaufAppValuePreservationTypeListQueryVariables
>;
export const FilterGenLaufAppVertragListDocument = gql`
  query FilterGenLaufAppVertragList($generierlaufId: ID!, $generierlaufType: AppGenerierlaufType!) {
    getFilterGenLaufAppVertragList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        ...VertragBaseFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${VertragBaseFieldsFragmentDoc}
`;
export function useFilterGenLaufAppVertragListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufAppVertragListQuery, FilterGenLaufAppVertragListQueryVariables> &
    ({ variables: FilterGenLaufAppVertragListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufAppVertragListQuery, FilterGenLaufAppVertragListQueryVariables>(FilterGenLaufAppVertragListDocument, options);
}
export function useFilterGenLaufAppVertragListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufAppVertragListQuery, FilterGenLaufAppVertragListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufAppVertragListQuery, FilterGenLaufAppVertragListQueryVariables>(
    FilterGenLaufAppVertragListDocument,
    options
  );
}
export function useFilterGenLaufAppVertragListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterGenLaufAppVertragListQuery, FilterGenLaufAppVertragListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufAppVertragListQuery, FilterGenLaufAppVertragListQueryVariables>(
    FilterGenLaufAppVertragListDocument,
    options
  );
}
export type FilterGenLaufAppVertragListQueryHookResult = ReturnType<typeof useFilterGenLaufAppVertragListQuery>;
export type FilterGenLaufAppVertragListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufAppVertragListLazyQuery>;
export type FilterGenLaufAppVertragListSuspenseQueryHookResult = ReturnType<typeof useFilterGenLaufAppVertragListSuspenseQuery>;
export type FilterGenLaufAppVertragListQueryResult = Apollo.QueryResult<FilterGenLaufAppVertragListQuery, FilterGenLaufAppVertragListQueryVariables>;
export const FilterGenLaufAppVorschreibungsperiodeListDocument = gql`
  query FilterGenLaufAppVorschreibungsperiodeList($generierlaufId: ID!, $generierlaufType: AppGenerierlaufType!) {
    getFilterGenLaufAppVorschreibungsperiodeList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        jahr
        monat
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufAppVorschreibungsperiodeListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufAppVorschreibungsperiodeListQuery, FilterGenLaufAppVorschreibungsperiodeListQueryVariables> &
    ({ variables: FilterGenLaufAppVorschreibungsperiodeListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufAppVorschreibungsperiodeListQuery, FilterGenLaufAppVorschreibungsperiodeListQueryVariables>(
    FilterGenLaufAppVorschreibungsperiodeListDocument,
    options
  );
}
export function useFilterGenLaufAppVorschreibungsperiodeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufAppVorschreibungsperiodeListQuery, FilterGenLaufAppVorschreibungsperiodeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufAppVorschreibungsperiodeListQuery, FilterGenLaufAppVorschreibungsperiodeListQueryVariables>(
    FilterGenLaufAppVorschreibungsperiodeListDocument,
    options
  );
}
export function useFilterGenLaufAppVorschreibungsperiodeListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufAppVorschreibungsperiodeListQuery, FilterGenLaufAppVorschreibungsperiodeListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufAppVorschreibungsperiodeListQuery, FilterGenLaufAppVorschreibungsperiodeListQueryVariables>(
    FilterGenLaufAppVorschreibungsperiodeListDocument,
    options
  );
}
export type FilterGenLaufAppVorschreibungsperiodeListQueryHookResult = ReturnType<typeof useFilterGenLaufAppVorschreibungsperiodeListQuery>;
export type FilterGenLaufAppVorschreibungsperiodeListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufAppVorschreibungsperiodeListLazyQuery>;
export type FilterGenLaufAppVorschreibungsperiodeListSuspenseQueryHookResult = ReturnType<
  typeof useFilterGenLaufAppVorschreibungsperiodeListSuspenseQuery
>;
export type FilterGenLaufAppVorschreibungsperiodeListQueryResult = Apollo.QueryResult<
  FilterGenLaufAppVorschreibungsperiodeListQuery,
  FilterGenLaufAppVorschreibungsperiodeListQueryVariables
>;
export const FilterGenLaufAppRecipientEmailAddressListDocument = gql`
  query FilterGenLaufAppRecipientEmailAddressList($generierlaufId: ID!, $generierlaufType: AppGenerierlaufType!) {
    getFilterGenLaufAppRecipientEmailAddressList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useFilterGenLaufAppRecipientEmailAddressListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufAppRecipientEmailAddressListQuery, FilterGenLaufAppRecipientEmailAddressListQueryVariables> &
    ({ variables: FilterGenLaufAppRecipientEmailAddressListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufAppRecipientEmailAddressListQuery, FilterGenLaufAppRecipientEmailAddressListQueryVariables>(
    FilterGenLaufAppRecipientEmailAddressListDocument,
    options
  );
}
export function useFilterGenLaufAppRecipientEmailAddressListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufAppRecipientEmailAddressListQuery, FilterGenLaufAppRecipientEmailAddressListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufAppRecipientEmailAddressListQuery, FilterGenLaufAppRecipientEmailAddressListQueryVariables>(
    FilterGenLaufAppRecipientEmailAddressListDocument,
    options
  );
}
export function useFilterGenLaufAppRecipientEmailAddressListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufAppRecipientEmailAddressListQuery, FilterGenLaufAppRecipientEmailAddressListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufAppRecipientEmailAddressListQuery, FilterGenLaufAppRecipientEmailAddressListQueryVariables>(
    FilterGenLaufAppRecipientEmailAddressListDocument,
    options
  );
}
export type FilterGenLaufAppRecipientEmailAddressListQueryHookResult = ReturnType<typeof useFilterGenLaufAppRecipientEmailAddressListQuery>;
export type FilterGenLaufAppRecipientEmailAddressListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufAppRecipientEmailAddressListLazyQuery>;
export type FilterGenLaufAppRecipientEmailAddressListSuspenseQueryHookResult = ReturnType<
  typeof useFilterGenLaufAppRecipientEmailAddressListSuspenseQuery
>;
export type FilterGenLaufAppRecipientEmailAddressListQueryResult = Apollo.QueryResult<
  FilterGenLaufAppRecipientEmailAddressListQuery,
  FilterGenLaufAppRecipientEmailAddressListQueryVariables
>;
export const FilterGenLaufAppRecipientListDocument = gql`
  query FilterGenLaufAppRecipientList($generierlaufId: ID!, $generierlaufType: AppGenerierlaufType!) {
    getFilterGenLaufAppRecipientList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        ...RechtstraegerBaseFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
export function useFilterGenLaufAppRecipientListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufAppRecipientListQuery, FilterGenLaufAppRecipientListQueryVariables> &
    ({ variables: FilterGenLaufAppRecipientListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufAppRecipientListQuery, FilterGenLaufAppRecipientListQueryVariables>(
    FilterGenLaufAppRecipientListDocument,
    options
  );
}
export function useFilterGenLaufAppRecipientListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufAppRecipientListQuery, FilterGenLaufAppRecipientListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufAppRecipientListQuery, FilterGenLaufAppRecipientListQueryVariables>(
    FilterGenLaufAppRecipientListDocument,
    options
  );
}
export function useFilterGenLaufAppRecipientListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilterGenLaufAppRecipientListQuery, FilterGenLaufAppRecipientListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufAppRecipientListQuery, FilterGenLaufAppRecipientListQueryVariables>(
    FilterGenLaufAppRecipientListDocument,
    options
  );
}
export type FilterGenLaufAppRecipientListQueryHookResult = ReturnType<typeof useFilterGenLaufAppRecipientListQuery>;
export type FilterGenLaufAppRecipientListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufAppRecipientListLazyQuery>;
export type FilterGenLaufAppRecipientListSuspenseQueryHookResult = ReturnType<typeof useFilterGenLaufAppRecipientListSuspenseQuery>;
export type FilterGenLaufAppRecipientListQueryResult = Apollo.QueryResult<
  FilterGenLaufAppRecipientListQuery,
  FilterGenLaufAppRecipientListQueryVariables
>;
export const FilterGenLaufAppVertragspartnerListDocument = gql`
  query FilterGenLaufAppVertragspartnerList($generierlaufId: ID!, $generierlaufType: AppGenerierlaufType!) {
    getFilterGenLaufAppVertragspartnerList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        ...RechtstraegerBaseFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
export function useFilterGenLaufAppVertragspartnerListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufAppVertragspartnerListQuery, FilterGenLaufAppVertragspartnerListQueryVariables> &
    ({ variables: FilterGenLaufAppVertragspartnerListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufAppVertragspartnerListQuery, FilterGenLaufAppVertragspartnerListQueryVariables>(
    FilterGenLaufAppVertragspartnerListDocument,
    options
  );
}
export function useFilterGenLaufAppVertragspartnerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufAppVertragspartnerListQuery, FilterGenLaufAppVertragspartnerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufAppVertragspartnerListQuery, FilterGenLaufAppVertragspartnerListQueryVariables>(
    FilterGenLaufAppVertragspartnerListDocument,
    options
  );
}
export function useFilterGenLaufAppVertragspartnerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufAppVertragspartnerListQuery, FilterGenLaufAppVertragspartnerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufAppVertragspartnerListQuery, FilterGenLaufAppVertragspartnerListQueryVariables>(
    FilterGenLaufAppVertragspartnerListDocument,
    options
  );
}
export type FilterGenLaufAppVertragspartnerListQueryHookResult = ReturnType<typeof useFilterGenLaufAppVertragspartnerListQuery>;
export type FilterGenLaufAppVertragspartnerListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufAppVertragspartnerListLazyQuery>;
export type FilterGenLaufAppVertragspartnerListSuspenseQueryHookResult = ReturnType<typeof useFilterGenLaufAppVertragspartnerListSuspenseQuery>;
export type FilterGenLaufAppVertragspartnerListQueryResult = Apollo.QueryResult<
  FilterGenLaufAppVertragspartnerListQuery,
  FilterGenLaufAppVertragspartnerListQueryVariables
>;
export const FilterGenLaufAppEigentuemerListDocument = gql`
  query FilterGenLaufAppEigentuemerList($generierlaufId: ID!, $generierlaufType: AppGenerierlaufType!) {
    getFilterGenLaufAppEigentuemerList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        ...RechtstraegerBaseFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
export function useFilterGenLaufAppEigentuemerListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufAppEigentuemerListQuery, FilterGenLaufAppEigentuemerListQueryVariables> &
    ({ variables: FilterGenLaufAppEigentuemerListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufAppEigentuemerListQuery, FilterGenLaufAppEigentuemerListQueryVariables>(
    FilterGenLaufAppEigentuemerListDocument,
    options
  );
}
export function useFilterGenLaufAppEigentuemerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufAppEigentuemerListQuery, FilterGenLaufAppEigentuemerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufAppEigentuemerListQuery, FilterGenLaufAppEigentuemerListQueryVariables>(
    FilterGenLaufAppEigentuemerListDocument,
    options
  );
}
export function useFilterGenLaufAppEigentuemerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufAppEigentuemerListQuery, FilterGenLaufAppEigentuemerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufAppEigentuemerListQuery, FilterGenLaufAppEigentuemerListQueryVariables>(
    FilterGenLaufAppEigentuemerListDocument,
    options
  );
}
export type FilterGenLaufAppEigentuemerListQueryHookResult = ReturnType<typeof useFilterGenLaufAppEigentuemerListQuery>;
export type FilterGenLaufAppEigentuemerListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufAppEigentuemerListLazyQuery>;
export type FilterGenLaufAppEigentuemerListSuspenseQueryHookResult = ReturnType<typeof useFilterGenLaufAppEigentuemerListSuspenseQuery>;
export type FilterGenLaufAppEigentuemerListQueryResult = Apollo.QueryResult<
  FilterGenLaufAppEigentuemerListQuery,
  FilterGenLaufAppEigentuemerListQueryVariables
>;
export const FilterGenLaufAppWeRechnungsAusstellerListDocument = gql`
  query FilterGenLaufAppWeRechnungsAusstellerList($generierlaufId: ID!, $generierlaufType: AppGenerierlaufType!) {
    getFilterGenLaufAppWeRechnungsAusstellerList(generierlaufId: $generierlaufId, generierlaufType: $generierlaufType) {
      data {
        ...RechtstraegerBaseFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
export function useFilterGenLaufAppWeRechnungsAusstellerListQuery(
  baseOptions: Apollo.QueryHookOptions<FilterGenLaufAppWeRechnungsAusstellerListQuery, FilterGenLaufAppWeRechnungsAusstellerListQueryVariables> &
    ({ variables: FilterGenLaufAppWeRechnungsAusstellerListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterGenLaufAppWeRechnungsAusstellerListQuery, FilterGenLaufAppWeRechnungsAusstellerListQueryVariables>(
    FilterGenLaufAppWeRechnungsAusstellerListDocument,
    options
  );
}
export function useFilterGenLaufAppWeRechnungsAusstellerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterGenLaufAppWeRechnungsAusstellerListQuery, FilterGenLaufAppWeRechnungsAusstellerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterGenLaufAppWeRechnungsAusstellerListQuery, FilterGenLaufAppWeRechnungsAusstellerListQueryVariables>(
    FilterGenLaufAppWeRechnungsAusstellerListDocument,
    options
  );
}
export function useFilterGenLaufAppWeRechnungsAusstellerListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterGenLaufAppWeRechnungsAusstellerListQuery, FilterGenLaufAppWeRechnungsAusstellerListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterGenLaufAppWeRechnungsAusstellerListQuery, FilterGenLaufAppWeRechnungsAusstellerListQueryVariables>(
    FilterGenLaufAppWeRechnungsAusstellerListDocument,
    options
  );
}
export type FilterGenLaufAppWeRechnungsAusstellerListQueryHookResult = ReturnType<typeof useFilterGenLaufAppWeRechnungsAusstellerListQuery>;
export type FilterGenLaufAppWeRechnungsAusstellerListLazyQueryHookResult = ReturnType<typeof useFilterGenLaufAppWeRechnungsAusstellerListLazyQuery>;
export type FilterGenLaufAppWeRechnungsAusstellerListSuspenseQueryHookResult = ReturnType<
  typeof useFilterGenLaufAppWeRechnungsAusstellerListSuspenseQuery
>;
export type FilterGenLaufAppWeRechnungsAusstellerListQueryResult = Apollo.QueryResult<
  FilterGenLaufAppWeRechnungsAusstellerListQuery,
  FilterGenLaufAppWeRechnungsAusstellerListQueryVariables
>;
