import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteTopAbrechnungenMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  objektAbrechnungId: Types.Scalars['ID']['input'];
}>;

export type DeleteTopAbrechnungenMutation = {
  deleteTopAbrechnungen: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const DeleteTopAbrechnungenDocument = gql`
  mutation DeleteTopAbrechnungen($objektId: ID!, $objektAbrechnungId: ID!) {
    deleteTopAbrechnungen(objektId: $objektId, objektAbrechnungId: $objektAbrechnungId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteTopAbrechnungenMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTopAbrechnungenMutation, DeleteTopAbrechnungenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTopAbrechnungenMutation, DeleteTopAbrechnungenMutationVariables>(DeleteTopAbrechnungenDocument, options);
}
export type DeleteTopAbrechnungenMutationHookResult = ReturnType<typeof useDeleteTopAbrechnungenMutation>;
export type DeleteTopAbrechnungenMutationResult = Apollo.MutationResult<DeleteTopAbrechnungenMutation>;
export type DeleteTopAbrechnungenMutationOptions = Apollo.BaseMutationOptions<DeleteTopAbrechnungenMutation, DeleteTopAbrechnungenMutationVariables>;
