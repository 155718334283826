import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AddressesAndContactsFragmentDoc } from './AddressesAndContactsFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RechtstraegerAddressesAndContactsQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
}>;

export type RechtstraegerAddressesAndContactsQuery = {
  getRechtstraegerAddressesAndContacts: {
    data: {
      contactPersonList: Array<{
        contactPersonId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firstname: string;
        lastname: string;
        tagList: Array<string>;
        title?: string | null;
        titleTrailing?: string | null;
        contactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        rechtstraeger?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      emailContactList: Array<{
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      hauptAddress?: {
        addressId: string;
        city: string;
        cityAdditionalInformation?: string | null;
        countryCodeIso2: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        houseEntranceApartmentNumber?: string | null;
        postofficeBoxNumber?: string | null;
        postofficeBoxZipCode?: string | null;
        street?: string | null;
        tagList: Array<string>;
        type: Types.AddressType;
        zipCode?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      hauptEmailContact?: {
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      hauptPhoneContact?: {
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      postofficeBoxAddressList: Array<{
        addressId: string;
        city: string;
        cityAdditionalInformation?: string | null;
        countryCodeIso2: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        houseEntranceApartmentNumber?: string | null;
        postofficeBoxNumber?: string | null;
        postofficeBoxZipCode?: string | null;
        street?: string | null;
        tagList: Array<string>;
        type: Types.AddressType;
        zipCode?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      streetAddressList: Array<{
        addressId: string;
        city: string;
        cityAdditionalInformation?: string | null;
        countryCodeIso2: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        houseEntranceApartmentNumber?: string | null;
        postofficeBoxNumber?: string | null;
        postofficeBoxZipCode?: string | null;
        street?: string | null;
        tagList: Array<string>;
        type: Types.AddressType;
        zipCode?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      phoneContactList: Array<{
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FirmendatenAddressesAndContactsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FirmendatenAddressesAndContactsQuery = {
  getFirmendatenAddressesAndContacts: {
    data: {
      contactPersonList: Array<{
        contactPersonId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        firstname: string;
        lastname: string;
        tagList: Array<string>;
        title?: string | null;
        titleTrailing?: string | null;
        contactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        rechtstraeger?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      emailContactList: Array<{
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      hauptAddress?: {
        addressId: string;
        city: string;
        cityAdditionalInformation?: string | null;
        countryCodeIso2: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        houseEntranceApartmentNumber?: string | null;
        postofficeBoxNumber?: string | null;
        postofficeBoxZipCode?: string | null;
        street?: string | null;
        tagList: Array<string>;
        type: Types.AddressType;
        zipCode?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      hauptEmailContact?: {
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      hauptPhoneContact?: {
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      postofficeBoxAddressList: Array<{
        addressId: string;
        city: string;
        cityAdditionalInformation?: string | null;
        countryCodeIso2: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        houseEntranceApartmentNumber?: string | null;
        postofficeBoxNumber?: string | null;
        postofficeBoxZipCode?: string | null;
        street?: string | null;
        tagList: Array<string>;
        type: Types.AddressType;
        zipCode?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      streetAddressList: Array<{
        addressId: string;
        city: string;
        cityAdditionalInformation?: string | null;
        countryCodeIso2: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        houseEntranceApartmentNumber?: string | null;
        postofficeBoxNumber?: string | null;
        postofficeBoxZipCode?: string | null;
        street?: string | null;
        tagList: Array<string>;
        type: Types.AddressType;
        zipCode?: string | null;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      phoneContactList: Array<{
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type AddressChangeHistorListQueryVariables = Types.Exact<{
  addressId: Types.Scalars['ID']['input'];
}>;

export type AddressChangeHistorListQuery = {
  getAddressChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type ContactChangeHistorListQueryVariables = Types.Exact<{
  contactId: Types.Scalars['ID']['input'];
}>;

export type ContactChangeHistorListQuery = {
  getContactChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const RechtstraegerAddressesAndContactsDocument = gql`
  query RechtstraegerAddressesAndContacts($rechtstraegerId: ID!) {
    getRechtstraegerAddressesAndContacts(rechtstraegerId: $rechtstraegerId) {
      data {
        ...AddressesAndContacts
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AddressesAndContactsFragmentDoc}
`;
export function useRechtstraegerAddressesAndContactsQuery(
  baseOptions: Apollo.QueryHookOptions<RechtstraegerAddressesAndContactsQuery, RechtstraegerAddressesAndContactsQueryVariables> &
    ({ variables: RechtstraegerAddressesAndContactsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechtstraegerAddressesAndContactsQuery, RechtstraegerAddressesAndContactsQueryVariables>(
    RechtstraegerAddressesAndContactsDocument,
    options
  );
}
export function useRechtstraegerAddressesAndContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RechtstraegerAddressesAndContactsQuery, RechtstraegerAddressesAndContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechtstraegerAddressesAndContactsQuery, RechtstraegerAddressesAndContactsQueryVariables>(
    RechtstraegerAddressesAndContactsDocument,
    options
  );
}
export function useRechtstraegerAddressesAndContactsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<RechtstraegerAddressesAndContactsQuery, RechtstraegerAddressesAndContactsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechtstraegerAddressesAndContactsQuery, RechtstraegerAddressesAndContactsQueryVariables>(
    RechtstraegerAddressesAndContactsDocument,
    options
  );
}
export type RechtstraegerAddressesAndContactsQueryHookResult = ReturnType<typeof useRechtstraegerAddressesAndContactsQuery>;
export type RechtstraegerAddressesAndContactsLazyQueryHookResult = ReturnType<typeof useRechtstraegerAddressesAndContactsLazyQuery>;
export type RechtstraegerAddressesAndContactsSuspenseQueryHookResult = ReturnType<typeof useRechtstraegerAddressesAndContactsSuspenseQuery>;
export type RechtstraegerAddressesAndContactsQueryResult = Apollo.QueryResult<
  RechtstraegerAddressesAndContactsQuery,
  RechtstraegerAddressesAndContactsQueryVariables
>;
export const FirmendatenAddressesAndContactsDocument = gql`
  query FirmendatenAddressesAndContacts {
    getFirmendatenAddressesAndContacts {
      data {
        ...AddressesAndContacts
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AddressesAndContactsFragmentDoc}
`;
export function useFirmendatenAddressesAndContactsQuery(
  baseOptions?: Apollo.QueryHookOptions<FirmendatenAddressesAndContactsQuery, FirmendatenAddressesAndContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmendatenAddressesAndContactsQuery, FirmendatenAddressesAndContactsQueryVariables>(
    FirmendatenAddressesAndContactsDocument,
    options
  );
}
export function useFirmendatenAddressesAndContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmendatenAddressesAndContactsQuery, FirmendatenAddressesAndContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmendatenAddressesAndContactsQuery, FirmendatenAddressesAndContactsQueryVariables>(
    FirmendatenAddressesAndContactsDocument,
    options
  );
}
export function useFirmendatenAddressesAndContactsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FirmendatenAddressesAndContactsQuery, FirmendatenAddressesAndContactsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmendatenAddressesAndContactsQuery, FirmendatenAddressesAndContactsQueryVariables>(
    FirmendatenAddressesAndContactsDocument,
    options
  );
}
export type FirmendatenAddressesAndContactsQueryHookResult = ReturnType<typeof useFirmendatenAddressesAndContactsQuery>;
export type FirmendatenAddressesAndContactsLazyQueryHookResult = ReturnType<typeof useFirmendatenAddressesAndContactsLazyQuery>;
export type FirmendatenAddressesAndContactsSuspenseQueryHookResult = ReturnType<typeof useFirmendatenAddressesAndContactsSuspenseQuery>;
export type FirmendatenAddressesAndContactsQueryResult = Apollo.QueryResult<
  FirmendatenAddressesAndContactsQuery,
  FirmendatenAddressesAndContactsQueryVariables
>;
export const AddressChangeHistorListDocument = gql`
  query AddressChangeHistorList($addressId: ID!) {
    getAddressChangeHistoryList(addressId: $addressId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useAddressChangeHistorListQuery(
  baseOptions: Apollo.QueryHookOptions<AddressChangeHistorListQuery, AddressChangeHistorListQueryVariables> &
    ({ variables: AddressChangeHistorListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AddressChangeHistorListQuery, AddressChangeHistorListQueryVariables>(AddressChangeHistorListDocument, options);
}
export function useAddressChangeHistorListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AddressChangeHistorListQuery, AddressChangeHistorListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AddressChangeHistorListQuery, AddressChangeHistorListQueryVariables>(AddressChangeHistorListDocument, options);
}
export function useAddressChangeHistorListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AddressChangeHistorListQuery, AddressChangeHistorListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AddressChangeHistorListQuery, AddressChangeHistorListQueryVariables>(AddressChangeHistorListDocument, options);
}
export type AddressChangeHistorListQueryHookResult = ReturnType<typeof useAddressChangeHistorListQuery>;
export type AddressChangeHistorListLazyQueryHookResult = ReturnType<typeof useAddressChangeHistorListLazyQuery>;
export type AddressChangeHistorListSuspenseQueryHookResult = ReturnType<typeof useAddressChangeHistorListSuspenseQuery>;
export type AddressChangeHistorListQueryResult = Apollo.QueryResult<AddressChangeHistorListQuery, AddressChangeHistorListQueryVariables>;
export const ContactChangeHistorListDocument = gql`
  query ContactChangeHistorList($contactId: ID!) {
    getContactChangeHistoryList(contactId: $contactId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useContactChangeHistorListQuery(
  baseOptions: Apollo.QueryHookOptions<ContactChangeHistorListQuery, ContactChangeHistorListQueryVariables> &
    ({ variables: ContactChangeHistorListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContactChangeHistorListQuery, ContactChangeHistorListQueryVariables>(ContactChangeHistorListDocument, options);
}
export function useContactChangeHistorListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContactChangeHistorListQuery, ContactChangeHistorListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContactChangeHistorListQuery, ContactChangeHistorListQueryVariables>(ContactChangeHistorListDocument, options);
}
export function useContactChangeHistorListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ContactChangeHistorListQuery, ContactChangeHistorListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ContactChangeHistorListQuery, ContactChangeHistorListQueryVariables>(ContactChangeHistorListDocument, options);
}
export type ContactChangeHistorListQueryHookResult = ReturnType<typeof useContactChangeHistorListQuery>;
export type ContactChangeHistorListLazyQueryHookResult = ReturnType<typeof useContactChangeHistorListLazyQuery>;
export type ContactChangeHistorListSuspenseQueryHookResult = ReturnType<typeof useContactChangeHistorListSuspenseQuery>;
export type ContactChangeHistorListQueryResult = Apollo.QueryResult<ContactChangeHistorListQuery, ContactChangeHistorListQueryVariables>;
