import { ContactFormValues, ContactModel, mapFormValuesToContactList, newContactObject } from '../../../../shared/components/Contact/contactMapper';
import { STREET_ADDRESS_FORM_INITIAL_VALUES_DEFAULT } from '../../../../shared/components/Address/form/addressMapper';
import { StreetAddressFormValues } from '../../../../shared/components/Address/AddressSharedModel';

export type FirmensitzFormValues = {
  address: StreetAddressFormValues;
  contactPersonList: ContactPersonFormValues[];
  emailList: ContactFormValues[];
  phoneList: ContactFormValues[];
};

export type ContactPersonFormValues = {
  salutation: string;
  firstname: string;
  lastname: string;
  title: string;
  titleTrailing: string;
  emailList: ContactFormValues[];
  phoneList: ContactFormValues[];
};

export type ContactPersonModel = {
  salutation: string;
  firstname: string;
  lastname: string;
  title?: string | null;
  titleTrailing?: string | null;
  contactList?: ContactModel[];
};

export const FIRMENSITZ_FORM_INITIAL_VALUES_DEFAULT: FirmensitzFormValues = {
  address: { ...STREET_ADDRESS_FORM_INITIAL_VALUES_DEFAULT },
  emailList: [{ ...newContactObject }],
  phoneList: [{ ...newContactObject }],
  contactPersonList: [],
};

export const mapContactPersonListToFormValues = (contactPersonList: ContactPersonFormValues[] = []): ContactPersonModel[] =>
  contactPersonList.map((contactPerson) => ({
    salutation: contactPerson.salutation,
    title: contactPerson.title ?? '',
    firstname: contactPerson.firstname,
    lastname: contactPerson.lastname,
    titleTrailing: contactPerson.titleTrailing ?? '',
    contactList: mapFormValuesToContactList(contactPerson.emailList, contactPerson.phoneList),
  }));
