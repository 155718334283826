import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { DatePicker } from 'formik-antd';
import FiltersWith4OrMoreFields from '../../../../components/Filters/FiltersWith4OrMoreFields';
import FiltersSelect from '../../../../components/Filters/FiltersSelect';
import { onFilterSelectChange } from '../../../../components/Filters/FiltersHelpers';
import { documentListingFiltersFormFields, DocumentListingFiltersFormValues, TQueryParams } from './documentListingFiltersFormMapper';
import DocumentTypeFormSelect from './DocumentTypeFormSelect';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import DocumentInvoiceIssuerListFormSelect from './DocumentInvoiceIssuerListFormSelect';
import DocumentInvoiceRecipientListFormSelect from './DocumentInvoiceRecipientListFormSelect';
import DocumentHomeownerListFormSelect from './DocumentHomeownerListFormSelect';
import DocumentObjektListFormSelect from './DocumentObjektListFormSelect';
import DocumentBestandseinheitListFormSelect from './DocumentBestandseinheitListFormSelect';
import DocumentInvoiceNumberFormSelect from './DocumentInvoiceNumberFormSelect';
import FromToFormDatePicker from '../../../shared/FromToForm/FromToFormDatePicker';
import { documentListingLabelList } from './listingFiltersHelper';
import useFilterWithQueryParams from '../../../shared/useFilterWithQueryParams';

type Props = {
  formikProps: FormikProps<DocumentListingFiltersFormValues>;
  queryParams: TQueryParams;
  defaultFilters?: string[];
  disabledFilters?: string[];
  objektId?: string | null;
};

const ListingFilters: FC<Props> = ({ formikProps, queryParams, defaultFilters, disabledFilters, objektId }) => {
  const defaultSelectedFilterList =
    defaultFilters ??
    [
      documentListingFiltersFormFields.documentType,
      documentListingFiltersFormFields.invoiceIssuerId,
      documentListingFiltersFormFields.invoiceRecipientId,
      documentListingFiltersFormFields.invoiceNumber,
    ].filter((filter) => !disabledFilters?.includes(filter));

  const mapFilterLabelToQueryFilterList = (filter: string) => {
    if (filter === documentListingFiltersFormFields.invoiceDateFromInclusive || filter === documentListingFiltersFormFields.invoiceDateToInclusive) {
      return documentListingFiltersFormFields.invoiceDateFromInclusive;
    }
    if (
      filter === documentListingFiltersFormFields.dunningReferenceDateFromInclusive ||
      filter === documentListingFiltersFormFields.dunningReferenceDateToInclusive
    ) {
      return documentListingFiltersFormFields.dunningReferenceDateFromInclusive;
    }
    return filter;
  };

  const filters = (filter: string) => {
    if (filter === documentListingFiltersFormFields.documentType) {
      return (
        <FormItemWithoutColon name={documentListingFiltersFormFields.documentType} label="Dokumenttyp" key={filter}>
          <DocumentTypeFormSelect name={documentListingFiltersFormFields.documentType} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === documentListingFiltersFormFields.invoiceIssuerId) {
      return (
        <FormItemWithoutColon name={documentListingFiltersFormFields.invoiceIssuerId} label="Rechnungsaussteller" key={filter}>
          <DocumentInvoiceIssuerListFormSelect name={documentListingFiltersFormFields.invoiceIssuerId} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === documentListingFiltersFormFields.invoiceRecipientId) {
      return (
        <FormItemWithoutColon name={documentListingFiltersFormFields.invoiceRecipientId} label="Rechnungsempfänger" key={filter}>
          <DocumentInvoiceRecipientListFormSelect name={documentListingFiltersFormFields.invoiceRecipientId} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === documentListingFiltersFormFields.invoiceNumber) {
      return (
        <FormItemWithoutColon name={documentListingFiltersFormFields.invoiceNumber} label="Belegnummer" key={filter}>
          <DocumentInvoiceNumberFormSelect
            name={documentListingFiltersFormFields.invoiceNumber}
            invoiceNumber={formikProps.values.invoiceNumber}
            onChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === documentListingFiltersFormFields.invoiceDateFromInclusive) {
      return (
        <FormItemWithoutColon name="Rechnungsdatum" label="Rechnungsdatum" key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={documentListingFiltersFormFields.invoiceDateFromInclusive}
            toName={documentListingFiltersFormFields.invoiceDateToInclusive}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === documentListingFiltersFormFields.dunningReferenceDateFromInclusive) {
      return (
        <FormItemWithoutColon name="Mahnstichtag" label="Mahnstichtag" key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={documentListingFiltersFormFields.dunningReferenceDateFromInclusive}
            toName={documentListingFiltersFormFields.dunningReferenceDateToInclusive}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === documentListingFiltersFormFields.homeownerId) {
      return (
        <FormItemWithoutColon name={documentListingFiltersFormFields.homeownerId} label="Hauseigentümer" key={filter}>
          <DocumentHomeownerListFormSelect name={documentListingFiltersFormFields.homeownerId} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === documentListingFiltersFormFields.objektId) {
      return (
        <FormItemWithoutColon name={documentListingFiltersFormFields.objektId} label="Objekt" key={filter}>
          <DocumentObjektListFormSelect
            name={documentListingFiltersFormFields.objektId}
            onChange={() => {
              formikProps.setFieldValue(documentListingFiltersFormFields.bestandseinheitId, undefined);
              formikProps.submitForm();
            }}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === documentListingFiltersFormFields.bestandseinheitId) {
      return (
        <FormItemWithoutColon name={documentListingFiltersFormFields.bestandseinheitId} label="Bestandseinheit" key={filter}>
          <DocumentBestandseinheitListFormSelect
            name={documentListingFiltersFormFields.bestandseinheitId}
            objektId={objektId ?? formikProps.values.objektId}
            onChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === documentListingFiltersFormFields.budgetingYear) {
      return (
        <FormItemWithoutColon name={documentListingFiltersFormFields.budgetingYear} label="Budgetierungsjahr" key={filter}>
          <DatePicker
            name={documentListingFiltersFormFields.budgetingYear}
            picker="year"
            format="YYYY"
            onChange={formikProps.submitForm}
            style={{ width: '100%' }}
          />
        </FormItemWithoutColon>
      );
    }
    return undefined;
  };

  const updateFilterValues = (selectedFilterList: string[]) => {
    if (!selectedFilterList.includes(documentListingFiltersFormFields.invoiceDateFromInclusive)) {
      formikProps.setFieldValue(documentListingFiltersFormFields.invoiceDateToInclusive, undefined);
    }
    if (!selectedFilterList.includes(documentListingFiltersFormFields.dunningReferenceDateFromInclusive)) {
      formikProps.setFieldValue(documentListingFiltersFormFields.dunningReferenceDateToInclusive, undefined);
    }
    if (!formikProps.values.objektId && formikProps.values.bestandseinheitId) {
      formikProps.setFieldValue(documentListingFiltersFormFields.bestandseinheitId, undefined);
    }
    formikProps.submitForm();
  };

  const { selectedFilterList, setSelectedFilterList, leftColumn, rightColumn } = useFilterWithQueryParams({
    defaultSelectedFilterList,
    updateFilterValues,
    filters,
    labelList: documentListingLabelList(disabledFilters),
    mapFilterLabelToQueryFilterList,
    queryParams,
  });

  return (
    <FiltersWith4OrMoreFields
      filtersSelect={
        <FiltersSelect
          defaultSelectedFilterList={defaultSelectedFilterList}
          selectedFilterList={selectedFilterList}
          onChange={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
          labelList={documentListingLabelList(disabledFilters)}
        />
      }
      hideTitle
      leftColumn={<>{leftColumn}</>}
      rightColumn={<>{rightColumn}</>}
    />
  );
};

export default ListingFilters;
