import React, { FC, useState } from 'react';
import { Switch } from 'formik-antd';
import { Button, Divider, Space, Typography } from 'antd';
import VerwendungButtons from './VerwendungButtons';
import { StyledModal } from './styled/Modal.style';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../../components/Button/ButtonsAligned';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import { AbrechdefAbrechkreis, AbrechnungsdefinitionType } from '../../../../types';
import { isZuordnungKontoKlasseTypeAusgaben, ZuordnungKontoKlasseType } from '../../shared/AbrKreisKontenzuordnungHelpers';
import {
  isAbrDefTypeWeAbrechnung,
  isAbrKreisTypeBewirtschaftungskosten,
  isAbrKreisTypeRepFonds,
} from '../../../Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import AbrDefAbrechnungskreisSelect from '../../../AbrDefAbrechnungskreisSelect/AbrDefAbrechnungskreisSelect';
import { ZuordnungFormValues, ZuordnungKontoVerwendung } from '../zuordnungKontoListingFormMapper';
import BewirtschaftungskostenFormItems from './BewirtschaftungskostenFormItems';

type Props = {
  item: ZuordnungFormValues;
  modalTitle: string;
  isModalOpen: boolean;
  nameForAbrKreisSelect: string;
  nameForKontoVerwendung: string;
  nameForBuchungszeilenAnzeigen: string;
  nameForAufteilungsschluessel: string;
  nameForAufteilungsschluesselBezeichnung: string;
  nameForExpenseReducing: string;
  expenseReducing: boolean;
  onSubmit: (selectedAbrKreisName: string) => void;
  onCancel: () => void;
  abrDefinitionType: AbrechnungsdefinitionType;
  zuordnungKontoKlasseType: ZuordnungKontoKlasseType;
  abrechnungskreisId: string;
  abrDefVersionAbrechkreisList: AbrechdefAbrechkreis[];
};

const ZuordnungFormModal: FC<Props> = ({
  item,
  modalTitle,
  isModalOpen,
  nameForAbrKreisSelect,
  nameForKontoVerwendung,
  nameForBuchungszeilenAnzeigen,
  nameForAufteilungsschluessel,
  nameForAufteilungsschluesselBezeichnung,
  nameForExpenseReducing,
  expenseReducing,
  onCancel,
  onSubmit,
  abrDefinitionType,
  zuordnungKontoKlasseType,
  abrechnungskreisId,
  abrDefVersionAbrechkreisList,
}) => {
  const [isAbrKreisRepFonds, setIsAbrKreisRepFond] = useState(isAbrKreisTypeRepFonds(item.abrechnungskreis.abrechnungskreisType));
  const [isAbrKreisBewirtschaftungskosten, setIsAbrKreisBewirtschaftungskosten] = useState(
    isAbrKreisTypeBewirtschaftungskosten(item.abrechnungskreis.abrechnungskreisType)
  );
  // This state is used instead of passing a function prop from the parent component in order to update the AbrKreis name in the Zuordnung listing only after the modal is closed (not right after the AbrKreis was selected
  const [selectedAbrKreisName, setSelectedAbrKreisName] = useState(nameForAbrKreisSelect);

  const isAufwandskonto = isZuordnungKontoKlasseTypeAusgaben(zuordnungKontoKlasseType);

  return (
    <StyledModal
      key={`item.abrechnungsdefinitionVersionId-${item.abrechnungsdefinitionVersionId}-${nameForAbrKreisSelect}`}
      closable={false}
      open={isModalOpen}
      onCancel={onCancel}
      title={modalTitle}
      footer={[
        <ButtonsAligned usage={ButtonsAlignedUsage.drawerFooter} key={`footer-${nameForAbrKreisSelect}`}>
          <Button onClick={onCancel}>Abbrechen</Button>
          <Button type="primary" onClick={() => onSubmit(selectedAbrKreisName)}>
            Speichern
          </Button>
        </ButtonsAligned>,
      ]}
    >
      <FormItemWithoutColon name="abrechnungskreis" label="Abrechnungskreis">
        <AbrDefAbrechnungskreisSelect
          name={nameForAbrKreisSelect}
          isUstVomAufwand={item.ustVomAufwand === ZuordnungKontoVerwendung.UST_VOM_AUFWAND}
          isAufwandskonto={isAufwandskonto}
          abrechnungsdefinitionId={item.abrechnungsdefinitionId}
          abrechnungsdefinitionVersionId={item.abrechnungsdefinitionVersionId}
          abrDefKontoKlasseAndVerwendung={{ klasse: item.abrechnungsdefinitionKontoKlasse, verwendung: item.abrechnungsdefinitionKontoVerwendung }}
          onChange={(isAbrKreisRepFonds, isAbrKreisBewirtschaftungskosten, selectedAbrKreisName) => {
            setSelectedAbrKreisName(selectedAbrKreisName);
            setIsAbrKreisRepFond(isAbrKreisRepFonds);
            setIsAbrKreisBewirtschaftungskosten(isAbrKreisBewirtschaftungskosten);
          }}
        />
      </FormItemWithoutColon>

      {isAbrKreisRepFonds && isAufwandskonto ? (
        <>
          <Divider>Rep.Fonds Einstellung</Divider>
          <FormItemWithoutColon name={nameForKontoVerwendung} label="Verwendung von Rep.Fonds">
            <VerwendungButtons name={nameForKontoVerwendung} verwendung={item.ustVomAufwand} />
          </FormItemWithoutColon>
        </>
      ) : null}

      {isAbrKreisBewirtschaftungskosten ? (
        <BewirtschaftungskostenFormItems
          isAbrKreisBewirtschaftungskosten={isAbrKreisBewirtschaftungskosten}
          abrDefVersionAbrechkreisList={abrDefVersionAbrechkreisList}
          zuordnungKontoKlasseType={zuordnungKontoKlasseType}
          nameForAufteilungsschluessel={nameForAufteilungsschluessel}
          nameForAufteilungsschluesselBezeichnung={nameForAufteilungsschluesselBezeichnung}
          nameForExpenseReducing={nameForExpenseReducing}
          expenseReducing={expenseReducing}
          abrechnungskreisId={abrechnungskreisId}
        />
      ) : null}

      <Divider>Allgemeine Einstellung</Divider>
      <Space direction="vertical">
        <FormItemWithoutColon
          name={nameForBuchungszeilenAnzeigen}
          label={`Buchungszeilen anzeigen${isAbrDefTypeWeAbrechnung(abrDefinitionType) ? ' (Topabrechnung)' : ''}`}
        >
          <Space>
            <Switch
              name={nameForBuchungszeilenAnzeigen}
              defaultChecked={item.buchungszeilenAnzeigen ?? zuordnungKontoKlasseType !== ZuordnungKontoKlasseType.EINNAHMEN}
              size="small"
            />
            <Typography.Text>{item.buchungszeilenAnzeigen ? 'anzeigen' : 'ausblenden'}</Typography.Text>
          </Space>
        </FormItemWithoutColon>
      </Space>
    </StyledModal>
  );
};

export default ZuordnungFormModal;
