import React, { FC, useState } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useFilterFibuBuchungsanweisungBestandeinheitListQuery } from '../../gql/BuchungsanweisungQueries.types';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import useDebounce from '../../../../hooks/useDebounce';
import { isTextEqualOrLongerThanThreeCharacters } from '../../../../helpers/stringHelper';
import SelectNotFoundContent from '../../../shared/SelectNotFoundContent';

type Props = {
  rechtstraegerIdList?: string[] | null;
} & Omit<SelectProps, 'id' | 'allowClear' | 'filterOption' | 'loading' | 'mode' | 'placeholder' | 'size' | 'showSearch'>;

const FibuBaBestandseinheitSelect: FC<Props> = ({ rechtstraegerIdList, ...restProps }) => {
  const [bezeichnungText, setBezeichnungText] = useState<string>('');
  const debouncedText = useDebounce(bezeichnungText);

  const { loading, data } = useFilterFibuBuchungsanweisungBestandeinheitListQuery({
    variables: {
      rechtstraegerIdList,
      bezeichnung: debouncedText as string,
    },
    skip: debouncedText ? !isTextEqualOrLongerThanThreeCharacters(debouncedText as string) : true,
  });

  const list = data?.getFilterFibuBuchungsanweisungBestandeinheitList.data ?? [];

  return (
    <Select
      {...restProps}
      size="small"
      id={restProps.name}
      loading={loading}
      placeholder="Bestandseinheit auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
      onSelect={setBezeichnungText}
      onSearch={(value) => {
        bezeichnungText.length === 1 && !value && setBezeichnungText(''); // Checking the length of the belegnummerText makes the search component editable
        value && setBezeichnungText(value);
      }}
      onClear={() => setBezeichnungText('')}
      searchValue={bezeichnungText}
      notFoundContent={<SelectNotFoundContent loading={loading} text={debouncedText as string} />}
    >
      {list.map((bestandseinheit) => {
        const text = bestandseinheit.objektKurzBezeichnung
          ? `${bestandseinheit.bezeichnung} - ${bestandseinheit.objektKurzBezeichnung}`
          : bestandseinheit.bezeichnung;

        return (
          <Select.Option key={bestandseinheit.bestandseinheitId} value={bestandseinheit.bestandseinheitId}>
            {/* bestandseinheit.objektKurzBezeichnung in case of Buchungsanweisung is always provided by Back-end */}
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            <DataWithShortenedText maxTextLength={48} text={text} extraTexts={[bestandseinheit.objektKurzBezeichnung!]} />
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default FibuBaBestandseinheitSelect;
