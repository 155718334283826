import React, { FC } from 'react';
import { Col, Empty, Row, Table } from 'antd';
import { ErrorData } from '../../../../../types';
import buchungsmaskeErrorLogTableColumns from './buchungsmaskeErrorLogTableColumns';

type BuchungsErrorLogTableProps = {
  errorList: ErrorData[];
};

const BuchungsErrorLogTable: FC<BuchungsErrorLogTableProps> = ({ errorList }) => (
  <Row style={{ padding: '16px', background: 'white' }}>
    <Col flex="auto">
      <Table<ErrorData>
        size="small"
        pagination={false}
        dataSource={errorList}
        rowKey={(record) => record.type}
        columns={buchungsmaskeErrorLogTableColumns}
        locale={{
          emptyText: <Empty description={<span>Keine Fehler vorhanden</span>} />,
        }}
      />
    </Col>
  </Row>
);

export default BuchungsErrorLogTable;
