import { FC } from 'react';
import { useObjektBankDetailsChangeHistoryListQuery } from './gql/ObjektBankDetailsQueries.types';
import { HistoryType } from '../../History/shared/HistoryModal';
import HistoryListingTable from '../../History/HistoryListingTable';

type Props = {
  objektId: string;
  bankDetailsId: string;
};

const ObjektBankDetailsChangeHistoryListingTable: FC<Props> = ({ objektId, bankDetailsId }) => {
  const { data, loading } = useObjektBankDetailsChangeHistoryListQuery({ variables: { objektId, bankDetailsId } });
  const historyList = data?.getObjektBankDetailsChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="objekt-bankDetails" />;
};

export default ObjektBankDetailsChangeHistoryListingTable;
