import { FetchResult, MutationFunctionOptions } from '@apollo/client';
import { FormikProps, getIn, useFormikContext } from 'formik';
import { get } from 'lodash';
import { Input } from 'formik-antd';
import React, { useState } from 'react';
import { electronicFormatIBAN, friendlyFormatIBAN, getCountrySpecifications } from 'ibantools';
import { mapWarningListToWarningMessageList } from '../../../helpers/errorAndWarningHelper';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { useValidateIbanMutation, ValidateIbanMutation, ValidateIbanMutationVariables } from '../gql/BankDetailsMutations.types';
import { entityIsRequired } from '../../../components/message/validationMsg';
import { FormikAntDValidateStatuses } from '../../../helpers/formikHelper';
import { IbanValidation } from '../../../types';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';

const IbanFormPart = (props: { onValidateIban: ValidateIbanCallback; fieldHelp?: string | null }) => {
  const formikProps = useFormikContext<{ iban?: string }>();
  const [lastValidatedIban, setLastValidatedIban] = useState<string | null>(electronicFormatIBAN(get(formikProps.values, fieldNameIban, '')));

  const [validateIban, { loading }] = useValidateIbanMutation({
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const { isTouched, hasError, error, isValid } = getFormikAntDFormItemHelpers(formikProps, fieldNameIban);

  const [warning, setWarning] = useState<string | undefined>();
  const hasWarning = warning !== undefined && isTouched;

  // TODO: local state validateStatus and var ibanValidateStatus could be replaced, so that validateStatus could be based on formik errors (see comment section in the function
  // and our warnings but  because of formik field level async validation bug right now it cannot be tested or replaced. try it after bug is resolved:
  // https://github.com/jaredpalmer/formik/issues/2206
  const [validateStatus, setValidateStatus] = useState<FormikAntDValidateStatuses>('');
  const ibanValidateStatus = calculateIbanValidateStatus(validateStatus, loading, isTouched);

  const [errorTemp, setErrorTemp] = useState<string | undefined>();

  return (
    <FormItemWithFieldHelp
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      fieldHelp={props.fieldHelp}
      name={fieldNameIban}
      label="IBAN"
      hasFeedback
      validateStatus={ibanValidateStatus}
      help={<>{(hasError && <li>{error}</li>) || (hasWarning && <li>{warning}</li>)}</> || (isValid && '')}
      validate={(iban: string) => {
        const unformattedIban = electronicFormatIBAN(iban);

        // FIXME begin should be deleted: https://github.com/jaredpalmer/formik/issues/2206
        if (lastValidatedIban === unformattedIban && errorTemp) {
          return errorTemp;
        }
        setErrorTemp(undefined);
        // FIXME end

        // synchron validation: return string if invalid (or undefined if valid, but in this case if sync val is successful async follows)
        if (!unformattedIban) {
          setValidateStatus('error');
          if (lastValidatedIban !== iban) {
            callOnValidateIban(props.onValidateIban, undefined);
          }
          setLastValidatedIban(unformattedIban);
          return entityIsRequired('IBAN');
        }

        if (shouldNotValidateDueSameIbanAndNoRunningAsyncValidation(unformattedIban, lastValidatedIban, setLastValidatedIban, loading)) {
          return error;
        }

        // if user is currently typing IBAN it should not show IBAN errors only if min length requirement of IBAN is met
        // that's why field is set to be untouched
        formikProps.setFieldTouched(fieldNameIban, false, false);
        setWarning(undefined);

        // synchron validation: return string if invalid (or undefined if valid, but in this case if sync val is successful async follows)
        if (isIbanTooShort(unformattedIban)) {
          setValidateStatus('error');
          callOnValidateIban(props.onValidateIban, undefined);
          return 'Ungültige IBAN';
        }

        // min length requirement of IBAN has been met so field is touched to better UX
        formikProps.setFieldTouched(fieldNameIban, true, false);

        // async validation: throw string if invalid or return undefined if valid
        return handleValidateIban(unformattedIban, props.onValidateIban, setValidateStatus, setWarning, validateIban, setErrorTemp);
      }}
    >
      <Input
        id={fieldNameIban}
        name={fieldNameIban}
        placeholder="z.B. AT02 2050 3021 0102 3600"
        onChange={(input) => formikProps.setFieldValue(fieldNameIban, friendlyFormatIBAN(input.target.value), false)}
      />
    </FormItemWithFieldHelp>
  );
};

// prevent unnecessary API calls
// check for loading is necessary because same field level validation can run multiple times at the same time
// example: type a value in this field input and quickly change the focus on the next field (e.g. by pressing tab). and in this case the promise
// from the first run can be ignored in favor of the second run: can and does lead the wrong fields error state
const shouldNotValidateDueSameIbanAndNoRunningAsyncValidation = (
  newIban: string,
  lastValidatedIban: string | null,
  setLastValidatedIban: (iban: string) => void,
  loading: boolean
) => {
  const lastIban = lastValidatedIban;
  setLastValidatedIban(newIban);
  return lastIban === newIban && !loading;
};

const callOnValidateIban = (onValidateIban: ValidateIbanCallback, bankData?: IbanValidation | null) => {
  // workaround to get validation taking place before a new validation would be triggered due to setFieldValue or setFieldTouched in callback
  setTimeout(() => onValidateIban(bankData), 1);
};

const handleValidateIban = (
  iban: string,
  onValidateIban: ValidateIbanCallback,
  setStatus: (value: ((prevState: FormikAntDValidateStatuses) => FormikAntDValidateStatuses) | FormikAntDValidateStatuses) => void,
  setWarning: (value: ((prevState: string | undefined) => string | undefined) | string | undefined) => void,
  validateIban: (
    options?: MutationFunctionOptions<ValidateIbanMutation, ValidateIbanMutationVariables>
  ) => Promise<FetchResult<ValidateIbanMutation>>,
  setErrorTemp: (value: ((prevState: string | undefined) => string | undefined) | string | undefined) => void
) =>
  validateIban({ variables: { iban } }).then((data) => {
    const bankData = data.data?.validateIban.data;
    const warningList = data.data?.validateIban.warningList ?? [];

    callOnValidateIban(onValidateIban, bankData);

    if (!bankData) {
      setStatus('warning');
      const warningMsg = 'IBAN konnte nicht validiert werden. Bitte geben Sie die Bankverbindungdaten selber ein!';
      setWarning(warningMsg);
      return undefined;
    }
    if (warningList.length > 0) {
      setStatus('warning');
      const warningMsg = mapWarningListToWarningMessageList(warningList).join();
      setWarning(warningMsg);
      return undefined;
    }

    if (bankData.valid) {
      if (bankData.bankname && bankData.bic) {
        setStatus('success');
        return undefined;
      } else {
        setStatus('warning');
        const warningMsg = 'Die Bankdaten konnten nicht ermittelt werden. Bitte geben Sie sie selber ein!';
        setWarning(warningMsg);
        return undefined;
      }
    } else {
      setStatus('error');
      // FIXME will be not show on UI: https://github.com/jaredpalmer/formik/issues/2206
      setErrorTemp('Während der Validierung ist ein unerwarteter Fehler aufgetreten.');
      throw 'Während der Validierung ist ein unerwarteter Fehler aufgetreten.';
    }
  });

const fieldNameIban = 'iban';

type ValidateIbanCallback = (data?: IbanValidation | null) => void;

export const getFormikAntDFormItemHelpers = (formikProps: FormikProps<any>, fieldName: string) => {
  const error = getIn(formikProps.errors, fieldName, undefined);
  let isTouched = getIn(formikProps.touched, fieldName, false) as boolean | boolean[];
  if (Array.isArray(isTouched)) {
    isTouched = isTouched.reduce((acc, value) => acc || value, false);
  }
  const hasError = error !== undefined && isTouched;
  const isValid = !error && isTouched;
  return {
    hasError,
    error,
    isTouched,
    isValid,
  };
};

const calculateIbanValidateStatus = (validateStatus: FormikAntDValidateStatuses, loading: boolean, isTouched: boolean) => {
  let validateStatusToShow: FormikAntDValidateStatuses;
  if (loading) {
    validateStatusToShow = 'validating';
  } else {
    validateStatusToShow = isTouched ? validateStatus : '';
  }
  return validateStatusToShow;
  // calculate validateStatus
  // let validateStatus: FormikAntDValidateStatuses;
  // if (loading) {
  //   validateStatus = 'validating';
  // } else if (hasError) {
  //   validateStatus = 'error';
  // } else if (hasWarning) {
  //   validateStatus = 'warning';
  // } else {
  //   const showValidateSuccess = true;
  //   validateStatus = isValid && showValidateSuccess ? 'success' : undefined;
  // }
};

const isIbanTooShort = (iban: string) => {
  const IBAN_COUNTRY_CODE_LENGTH = 2;
  if (iban.length < IBAN_COUNTRY_CODE_LENGTH) {
    return true;
  }

  const countryCode = iban.slice(0, IBAN_COUNTRY_CODE_LENGTH);
  const countrySpec = getCountrySpecifications()[countryCode];

  return countrySpec && countrySpec.chars !== null && iban.length < countrySpec.chars;
};

export default IbanFormPart;
