import { Typography } from 'antd';
import React, { FC } from 'react';
import { isStatusArchivedOrGesperrt, IStatus } from '../../helpers/statusHelper';
import StatusBadge from '../Status/StatusBadge';
import DataWithShortenedTextAndExtraInfo from './DataWithShortenedTextAndExtraInfo';
import theme from '../../theme';

/******************

 Usage for components different than text:
 * When a component is imported from 'react-intl' library, then use it as children (wrap the component with DataWithStatus)
      <DataWithStatus ...>
          <CustomFormattedDate ... />
      </DataWithStatus>
 * When a component is imported from 'antd' library, then use DataWithStatus as children (wrap DataWithStatus with this component)
     <Tag>
          <DataWithStatus ... />
     </Tag>

 ******************/

export type DataWithStatusProps = {
  status: IStatus;
  text: string;
  maxTextLength?: number;
  /** Extra information provided about the shown data which will be presented in a tooltip (e.g. longer or alternative names of the data  */
  alternativeText?: string;
  /** should be set true if we want wo show a red badge indicating that the data has a archived or locked (gesperrt) state (the check for the state happens
   * in this component internally you only need to specify if you want it show it or not if applicable */
  showBadgeDot?: boolean;
  badgeOffset?: [number | string, number | string];
  showStatusDescription?: boolean;
  children?: (shortenedText: string) => React.ReactNode;
  tooltipFooter?: React.ReactNode;
};

const DataWithStatus: FC<DataWithStatusProps> = ({
  status,
  maxTextLength,
  text,
  alternativeText,
  showBadgeDot = true,
  badgeOffset,
  showStatusDescription = true,
  children,
  tooltipFooter,
}) => {
  return isStatusArchivedOrGesperrt(status) ? (
    <StatusBadge dot={showBadgeDot ?? false} entityStatus={status} showBadgeText={false} showStatusDescriptionInTooltip={false} offset={badgeOffset}>
      <DataWithShortenedTextAndExtraInfo
        maxTextLength={maxLength(text, maxTextLength)}
        text={text}
        statusDescription={showStatusDescription ? status.description : undefined}
        alternativeText={alternativeText}
        tooltipFooter={tooltipFooter}
      >
        {(shortenedText) => (
          <Typography.Text style={{ color: theme.textColor.disabled }}>{children ? children(shortenedText) : shortenedText}</Typography.Text>
        )}
      </DataWithShortenedTextAndExtraInfo>
    </StatusBadge>
  ) : (
    <DataWithShortenedTextAndExtraInfo
      text={text}
      maxTextLength={maxLength(text, maxTextLength)}
      statusDescription={showStatusDescription ? status.description : undefined}
      alternativeText={alternativeText}
      tooltipFooter={tooltipFooter}
    >
      {(shortenedText) => <Typography.Text>{children ? children(shortenedText) : shortenedText}</Typography.Text>}
    </DataWithShortenedTextAndExtraInfo>
  );
};

const maxLength = (text: string, maxLength?: number) => maxLength ?? text.length;

export default DataWithStatus;
