import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import { DatePicker, Form } from 'formik-antd';
import {
  budgetingCreateVPosValuesFormFields,
  budgetingCreateVPosValuesFormInitialValues,
  BudgetingCreateVPosValuesFormValues,
} from './budgetingCreateVPosValuesFormMapper';
import { budgetingCreateVPosValuesFormValidationSchema } from './budgetingCreateVPosValuesFormValidationSchema';
import { DATE_DISPLAY_MONTH_FORMAT_DEFAULT } from '../../../components/DatePicker/DatePicker_formikAntD';
import FormButtons from '../../../components/Button/FormButtons';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';

type Props = {
  loading: boolean;
  onSubmit: (values: BudgetingCreateVPosValuesFormValues, formikHelpers: FormikHelpers<BudgetingCreateVPosValuesFormValues>) => void;
  onCancel: () => void;
  fieldHelpSelectorEntityName: 'BudgetingCreateVPosWerteFromVPosActionData' | 'BudgetingCreateVPosWerteFromAufwandActionData';
};

const BudgetingCreateVPosValuesForm: FC<Props> = ({ loading, onSubmit, onCancel, fieldHelpSelectorEntityName }) => {
  const getFieldHelpTextVPos = useGetAppFieldHelpText<'BudgetingCreateVPosWerteFromVPosActionData'>('BudgetingCreateVPosWerteFromVPosActionData');
  const getFieldHelpTextAufwand = useGetAppFieldHelpText<'BudgetingCreateVPosWerteFromAufwandActionData'>(
    'BudgetingCreateVPosWerteFromAufwandActionData'
  );
  const isFieldHelpSelectorEntityNameVPos = fieldHelpSelectorEntityName === 'BudgetingCreateVPosWerteFromVPosActionData';

  return (
    <Formik<BudgetingCreateVPosValuesFormValues>
      initialValues={budgetingCreateVPosValuesFormInitialValues}
      validationSchema={budgetingCreateVPosValuesFormValidationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <>
          <Form layout="vertical">
            <Row>
              <Col style={{ width: '100%' }}>
                <FormItemWithFieldHelp
                  name={budgetingCreateVPosValuesFormFields.validFrom}
                  label="Datum für neue Vertragswerte"
                  fieldHelp={
                    isFieldHelpSelectorEntityNameVPos
                      ? getFieldHelpTextVPos('BudgetingCreateVPosWerteFromVPosActionData.validFrom')
                      : getFieldHelpTextAufwand('BudgetingCreateVPosWerteFromAufwandActionData.validFrom')
                  }
                >
                  <DatePicker
                    id={budgetingCreateVPosValuesFormFields.validFrom}
                    name={budgetingCreateVPosValuesFormFields.validFrom}
                    placeholder="Datum wählen"
                    format={DATE_DISPLAY_MONTH_FORMAT_DEFAULT}
                    allowClear={false}
                    size="middle"
                    picker="month"
                    style={{ width: '180px' }}
                  />
                </FormItemWithFieldHelp>
              </Col>
            </Row>
            <FormButtons okText="Übernehmen" onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} isOkLoading={loading} updateMode={false} />
          </Form>
        </>
      )}
    </Formik>
  );
};

export default BudgetingCreateVPosValuesForm;
