import * as Yup from 'yup';
import { entityHasToBeAnInt, entityHasToBeNonNegative, entityIsRequired } from '../../../components/message/validationMsg';
import { ticketTemplateFormFields } from './ticketTemplateFormMapper';

const createPeriodSchemaValidator = (prop: string) => {
  return Yup.object().shape({
    amount: Yup.number()
      .test({
        test: (value, context) => {
          const unit = context.options.context?.[prop]?.unit;
          if (value === null && unit === null) return true;
          return value !== null;
        },
        message: entityIsRequired('Anzahl'),
      })
      .min(0, entityHasToBeNonNegative('Anzahl'))
      .integer(entityHasToBeAnInt('Anzahl'))
      .nullable(),
    unit: Yup.string()
      .test({
        test: (value, context) => {
          const amount = context.options.context?.[prop]?.amount;
          if (value === null && amount === null) return true;
          return value !== null;
        },
        message: entityIsRequired('Einheit'),
      })
      .nullable(),
  });
};

export const ticketTemplateFormValidationSchema = Yup.object().shape({
  [ticketTemplateFormFields.name]: Yup.string().required(entityIsRequired('Bezeichnung')),
  [ticketTemplateFormFields.priority]: Yup.string().required(entityIsRequired('Priorität')),
  [ticketTemplateFormFields.dueDate]: createPeriodSchemaValidator(ticketTemplateFormFields.dueDate),
  [ticketTemplateFormFields.reminder]: createPeriodSchemaValidator(ticketTemplateFormFields.reminder),
});
