import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { useQueryWithPagingAndSorting } from '../../../../../../../hooks/useQueryWithPagingAndSorting';
import { useOffenePostenListQuery } from '../../../../../../OffenePosten/gql/OffenePostenQueries.types';
import OffenePostenForPersonenkontoTable from './Table/Level1/OffenePostenForPersonenkontoTable';
import {
  AuszifferungBlockFormValues,
  auszifferungFormFields,
  mapOffenePostenAuszifferungListToFormValues,
  offenePostenFilterFormFields,
} from '../../auszifferungFormMapper';
import { isFibuBuchungTypeRuecklastschrift } from '../../../bookingHelpers';
import { FibuBuchungType } from '../../../../../../../types';
import SpaceForBookingBlockAndForm from '../../../../../shared/SpaceForBookingBlockAndForm';
import FiltersForOPListForPersonenkonto from '../../../../../shared/FiltersForOPListForPersonenkonto';

type Props = {
  bookingZahlungId: string;
  buchungType: FibuBuchungType;
  formikProps: FormikProps<AuszifferungBlockFormValues>;
  personenkontoId: string;
  buchungskreisRechtstraegerId: string;
};

const OffenePostenForPersonenkontoListing: FC<Props> = ({
  bookingZahlungId,
  buchungskreisRechtstraegerId,
  buchungType,
  formikProps,
  personenkontoId,
}) => {
  const { loading, pagination } = useQueryWithPagingAndSorting(
    useOffenePostenListQuery,
    {
      variables: {
        includeAusgeziffert: isFibuBuchungTypeRuecklastschrift(buchungType), //Rücklastschrift => true, Zahlung => false
        kontoId: personenkontoId,
        belegnummer: formikProps.values.offenePostenFilter?.belegnummer,
        gesamtBetragFrom: formikProps.values.offenePostenFilter?.totalAmountFrom,
        gesamtBetragTo: formikProps.values.offenePostenFilter?.totalAmountTo,
        rechtstraegerId: buchungskreisRechtstraegerId,
      },
      // set formik values here instead of using useEffect to avoid endless loop while changing the pagination
      onCompleted: (data) => {
        formikProps.setFieldValue(
          auszifferungFormFields.offenePostenForPersonenkontoList,
          mapOffenePostenAuszifferungListToFormValues(data?.getOffenePostenList.data?.contentList ?? [])
        );
      },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
    }
  );

  return (
    <SpaceForBookingBlockAndForm>
      <FiltersForOPListForPersonenkonto
        belegerNummerFormFieldName={`${auszifferungFormFields.offenePostenFilter}.${offenePostenFilterFormFields.belegnummer}`}
        totalAmountFromFormFieldName={`${auszifferungFormFields.offenePostenFilter}.${offenePostenFilterFormFields.totalAmountFrom}`}
        totalAmountToFormFieldName={`${auszifferungFormFields.offenePostenFilter}.${offenePostenFilterFormFields.totalAmountTo}`}
      />
      <OffenePostenForPersonenkontoTable
        bookingZahlungId={bookingZahlungId}
        buchungType={buchungType}
        formikProps={formikProps}
        loading={loading}
        pagination={pagination}
      />
    </SpaceForBookingBlockAndForm>
  );
};

export default OffenePostenForPersonenkontoListing;
