import React, { FC, useState } from 'react';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import PDFTemplatePage from '../../../../../components/Template/PDFTemplates/PDFTemplatePage';
import { BelegTexte, TextbausteinType } from '../../../../../types';
import { TextbausteinList } from '../../../../../components/Template/PDFTemplates/TextbausteinList/TextbausteinList';
import { updateTextbausteinListValuesWithType } from '../../../../../components/Template/PDFTemplates/templateHelpers';
import VorschreibungTemplate from '../../../../../pages/PDFTemplates/templates/vorschreibung/VorschreibungTemplate';
import { createAuftragsartPDFTemplateData, mapTextbausteinToAuftragsartSelectableSection } from './auftragsartTemplateFormMapper';
import { BelegTextePlatzhalter } from '../../../../Belegtext/Form/belegTexteFormTypes';

type Props = {
  firmendatenId?: string;
  templateFormValues: TextbausteinFormValues[];
  showUstBlock: boolean;
  onTemplateChange: (templateValues: TextbausteinFormValues[]) => void;
  belegTexte?: BelegTexte;
  belegTextePlatzhalter?: BelegTextePlatzhalter;
};

const AuftragsartTemplate: FC<Props> = ({ firmendatenId, templateFormValues, showUstBlock, onTemplateChange, belegTexte, belegTextePlatzhalter }) => {
  const [selectedTextbaustein, setSelectedTextbaustein] = useState<TextbausteinType>();

  const setSelectedTextbausteinToNewOrDefaultValue = (newValue: TextbausteinType) => {
    setSelectedTextbaustein((currentSelected) => (currentSelected !== newValue ? newValue : undefined));
  };

  return (
    <PDFTemplatePage
      textbausteinList={
        <TextbausteinList
          textbausteinList={templateFormValues}
          onTemplateChange={onTemplateChange}
          onSelectTextbaustein={setSelectedTextbausteinToNewOrDefaultValue}
          onChange={(textbausteinType, newValue) =>
            onTemplateChange(updateTextbausteinListValuesWithType(templateFormValues, textbausteinType, newValue))
          }
          level={0}
        />
      }
      renderPreview={() => (
        <VorschreibungTemplate
          data={createAuftragsartPDFTemplateData(firmendatenId, templateFormValues, showUstBlock, belegTexte, belegTextePlatzhalter)}
          isInEditMode
          selectedTextbaustein={mapTextbausteinToAuftragsartSelectableSection(selectedTextbaustein)}
        />
      )}
      selectedTextbaustein={selectedTextbaustein}
    />
  );
};

export default AuftragsartTemplate;
