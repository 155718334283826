import { FC } from 'react';
import { HistoryType } from '../../../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../../../History/HistoryListingTable';
import { useEbicsUserIbanChangeHistoryQuery } from '../../../gql/EbicsUserIbanQueries.types';

type Props = {
  ebicsUserId: string;
  ebicsUserIbanId: string;
};

const EbicsUserIbanChangeHistoryListingTable: FC<Props> = ({ ebicsUserId, ebicsUserIbanId }) => {
  const { data, loading } = useEbicsUserIbanChangeHistoryQuery({ variables: { ebicsUserId, ebicsUserIbanId } });
  const historyList = data?.getEbicsUserIbanChangeHistory.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="ebics-user-iban" />;
};

export default EbicsUserIbanChangeHistoryListingTable;
