import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { DocumentRowProps, findLabelValueByLabelId } from './util';
import { useSubAbrechnungQuery } from '../../../Abrechnung/Sub/gql/SubAbrechnungQueries.types';
import { DocumentInfo, DocumentRowContextProvider } from './documentRowContext';
import AvatarBasedOnFileInfoTypeWithTooltip from '../../../../components/Avatar/BasedOnFileInfoType/AvatarBasedOnFileInfoTypeWithTooltip';
import { ValueTitle } from '../../../../components/Helpers/ValueTitle';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { pathsToObjektDetailsPage } from '../../../Objekt/objektUriPaths';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import { uriFileOfFirmendaten } from '../../documentApiPaths';
import { useFDAuthorized } from '../../../../shared/Auth/useAuthorized';
import { pathsToSubAbrechnungDetails } from '../../../Abrechnung/Sub/subAbrechnungUriPaths';

const RowSubAbrechnung: FC<DocumentRowProps> = ({ record, rechnungTyp, invoiceRecipientId, invoiceIssuerId, ...restProps }) => {
  const { activeForFirmendatenId } = useFDAuthorized();

  const objektId = findLabelValueByLabelId(record.labelList, 'objektId');
  const subAbrechnungId = findLabelValueByLabelId(record.labelList, 'subAbrechnungId');

  const { loading, data: maybeSubAbrechnung } = useSubAbrechnungQuery({ variables: { objektId, subAbrechnungId } });

  const subAbrechnung = maybeSubAbrechnung?.getSubAbrechnung.data;

  const documentInfo: DocumentInfo | undefined = subAbrechnung
    ? {
        column1: <AvatarBasedOnFileInfoTypeWithTooltip fileType={record.type} />,
        column2: (
          <ValueTitle
            small
            title="Abrechnung"
            content={
              <DataWithShortenedText text={subAbrechnung.bezeichnung} maxTextLength={25}>
                {(shortenedText) => <Link to={pathsToSubAbrechnungDetails(objektId, subAbrechnungId).subAbr}>{shortenedText}</Link>}
              </DataWithShortenedText>
            }
          />
        ),
        column3: (
          <ValueTitle
            small
            title="Objekt"
            content={
              <DataWithShortenedText maxTextLength={25} text={subAbrechnung.objekt.kurzBezeichnung}>
                {(shortenedText) => <Link to={pathsToObjektDetailsPage(subAbrechnung.objekt.objektId).objektDetails}>{shortenedText}</Link>}
              </DataWithShortenedText>
            }
          />
        ),
        column4: (
          <ValueTitle
            small
            title="Eigentümer"
            content={
              <></>
              // <RechtstraegerWithContactsTooltip
              //   rechtstraegerId={subAbrechnung.ei.rechtstraegerId}
              //   rechtstraegerName={heAbrechnungHeVertrag.hauseigentuemer.kurzBezeichnung}
              //   maxTextLength={25}
              // />
            }
          />
        ),
        column5: <ValueTitle small title="von" content={<CustomFormattedDate value={subAbrechnung.abrechnungszeitraumVon} />} />,
        column6: <ValueTitle small title="bis" content={<CustomFormattedDate value={subAbrechnung.abrechnungszeitraumBis} />} />,
        column7: <ValueTitle small title="-" content="-" />,
        column8: (
          <Button>
            <Link to={uriFileOfFirmendaten(activeForFirmendatenId, record.fileId, record.name)} target="_blank">
              Öffnen
            </Link>
          </Button>
        ),
      }
    : undefined;

  return (
    <DocumentRowContextProvider value={{ isLoading: loading, documentInfo }}>
      <tr {...restProps} />
    </DocumentRowContextProvider>
  );
};

export default RowSubAbrechnung;
