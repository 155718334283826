import { FormFields, mapFormDateValueToDateString } from '../../../helpers/formikHelper';
import { dayjsCustom } from '../../../helpers/dayjsCustom';

export type BudgetingCreateVPosValuesFormValues = {
  validFrom: string;
};

export const budgetingCreateVPosValuesFormFields: FormFields<BudgetingCreateVPosValuesFormValues> = {
  validFrom: 'validFrom',
};

export const budgetingCreateVPosValuesFormInitialValues = {
  validFrom: dayjsCustom().add(1, 'year').startOf('year').format('YYYY-MM-DD'),
};

export const mapFormValuesToBudgetingCreateVPosValuesForm = (values: BudgetingCreateVPosValuesFormValues) => ({
  validFrom: mapFormDateValueToDateString(values.validFrom),
});
