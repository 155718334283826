import { FC } from 'react';
import { FormikProps } from 'formik';
import { Select, SelectProps } from 'formik-antd';
import { UstVomAufwandVerarbeitungEntryFiltersFormValues } from './ListingFiltersFormMapper';
import { useUstVomAufwandBuchungStatusListQuery } from '../../../../UstVomAufwand/gql/UstVomAufwandQueries.types';

type UstVomAufwandBuchungStatusSelectProps = {
  formikProps: FormikProps<UstVomAufwandVerarbeitungEntryFiltersFormValues>;
  width?: string;
} & SelectProps;

const UstVomAufwandBuchungStatusSelect: FC<UstVomAufwandBuchungStatusSelectProps> = ({ formikProps, width, ...restProps }) => {
  const { data, loading } = useUstVomAufwandBuchungStatusListQuery();

  const ustVomAufwandBuchungStatusList = data?.getUstVomAufwandBuchungStatusList.data ?? [];

  return (
    <Select
      id={restProps.name}
      allowClear
      loading={loading}
      mode="multiple"
      placeholder={restProps.placeholder ?? 'Buchungstatus auswählen'}
      {...restProps}
      style={{ width: width || '100%' }}
    >
      {ustVomAufwandBuchungStatusList.map((buchungStatus) => (
        <Select.Option key={buchungStatus.value} value={buchungStatus.value}>
          {buchungStatus.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default UstVomAufwandBuchungStatusSelect;
