import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { ObjektInfoFeldFragmentDoc } from './objektInfoFeldFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ObjektInfoFeldListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type ObjektInfoFeldListQuery = {
  getObjektInfoFeldList: {
    data: Array<
      | {
          __typename: 'ObjektInfoFeldAuflage';
          objektInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          amtlicheQuadratmeterFestsetzung: boolean;
          assanierungsgebiet: boolean;
          bebaut: boolean;
          denkmalschutz: boolean;
          gruenderzeitviertel: boolean;
          naturschutz: boolean;
          schutzzone: boolean;
          vollanwendungMRG: boolean;
          wohnzone: boolean;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.ObjektInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }
      | {
          __typename: 'ObjektInfoFeldGrundstuecksdaten';
          objektInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          anzahlGeschosseUeberNiveau?: number | null;
          anzahlGeschosseUnterNiveau?: number | null;
          bauflaeche?: number | null;
          baujahr?: number | null;
          bebauteFlaeche?: number | null;
          benuetzungsbewilligung?: string | null;
          einlagezahl?: string | null;
          grundstuecksnummer: string;
          katastralgemeinde?: string | null;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.ObjektInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }
      | {
          __typename: 'ObjektInfoFeldHausbetreuung';
          objektInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          beauftragungsgrund: string;
          beginn?: string | null;
          ende?: string | null;
          kuendigungsfristDauer?: number | null;
          vertragsdaten?: string | null;
          wertsicherung?: string | null;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.ObjektInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          contactPerson?: {
            contactPersonId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            lastname: string;
            title?: string | null;
            firstname: string;
            salutation?: { text: string; value: Types.Salutation } | null;
            contactList: Array<{
              contactId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              haupt?: boolean | null;
              tagList: Array<string>;
              type: string;
              wert: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            warningList: Array<{ message: string; type: string }>;
          } | null;
          kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
          kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
          hausbetreuungRechtstraeger?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
        }
      | {
          __typename: 'ObjektInfoFeldPerson';
          objektInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          rolle: string;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.ObjektInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          personRechtstraeger: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
        }
      | {
          __typename: 'ObjektInfoFeldVersicherung';
          objektInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          beginn?: string | null;
          datumSchaetzung?: string | null;
          ende?: string | null;
          erstmalsFaellig?: string | null;
          validFrom?: string | null;
          hauptDueDate?: string | null;
          kuendigungsfristDauer?: number | null;
          neubauwert?: number | null;
          polizze?: string | null;
          versicherungsart: string;
          versicherungssumme?: number | null;
          wertsicherung?: string | null;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.ObjektInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          contactPerson?: {
            contactPersonId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            lastname: string;
            title?: string | null;
            firstname: string;
            salutation?: { text: string; value: Types.Salutation } | null;
            contactList: Array<{
              contactId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              haupt?: boolean | null;
              tagList: Array<string>;
              type: string;
              wert: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            warningList: Array<{ message: string; type: string }>;
          } | null;
          firma?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            name1: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ message: string; type: string }>;
          } | null;
          kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
          kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
          makler?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            rechtstraegerId: string;
            kurzBezeichnung: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ message: string; type: string }>;
          } | null;
          sachbearbeiter?: {
            contactPersonId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firstname: string;
            lastname: string;
            tagList: Array<string>;
            title?: string | null;
            titleTrailing?: string | null;
            contactList: Array<{
              contactId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              haupt?: boolean | null;
              tagList: Array<string>;
              type: string;
              wert: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            rechtstraeger?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          zahlungsperiode?: { text: string; value: Types.Zahlungsperiode } | null;
        }
    >;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ObjektInfoFeldQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  infoFeldId: Types.Scalars['ID']['input'];
}>;

export type ObjektInfoFeldQuery = {
  getObjektInfoFeld: {
    data:
      | {
          __typename: 'ObjektInfoFeldAuflage';
          objektInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          amtlicheQuadratmeterFestsetzung: boolean;
          assanierungsgebiet: boolean;
          bebaut: boolean;
          denkmalschutz: boolean;
          gruenderzeitviertel: boolean;
          naturschutz: boolean;
          schutzzone: boolean;
          vollanwendungMRG: boolean;
          wohnzone: boolean;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.ObjektInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }
      | {
          __typename: 'ObjektInfoFeldGrundstuecksdaten';
          objektInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          anzahlGeschosseUeberNiveau?: number | null;
          anzahlGeschosseUnterNiveau?: number | null;
          bauflaeche?: number | null;
          baujahr?: number | null;
          bebauteFlaeche?: number | null;
          benuetzungsbewilligung?: string | null;
          einlagezahl?: string | null;
          grundstuecksnummer: string;
          katastralgemeinde?: string | null;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.ObjektInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }
      | {
          __typename: 'ObjektInfoFeldHausbetreuung';
          objektInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          beauftragungsgrund: string;
          beginn?: string | null;
          ende?: string | null;
          kuendigungsfristDauer?: number | null;
          vertragsdaten?: string | null;
          wertsicherung?: string | null;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.ObjektInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          contactPerson?: {
            contactPersonId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            lastname: string;
            title?: string | null;
            firstname: string;
            salutation?: { text: string; value: Types.Salutation } | null;
            contactList: Array<{
              contactId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              haupt?: boolean | null;
              tagList: Array<string>;
              type: string;
              wert: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            warningList: Array<{ message: string; type: string }>;
          } | null;
          kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
          kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
          hausbetreuungRechtstraeger?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
        }
      | {
          __typename: 'ObjektInfoFeldPerson';
          objektInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          rolle: string;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.ObjektInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          personRechtstraeger: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
        }
      | {
          __typename: 'ObjektInfoFeldVersicherung';
          objektInfoFeldId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          fileIdList: Array<string>;
          beginn?: string | null;
          datumSchaetzung?: string | null;
          ende?: string | null;
          erstmalsFaellig?: string | null;
          validFrom?: string | null;
          hauptDueDate?: string | null;
          kuendigungsfristDauer?: number | null;
          neubauwert?: number | null;
          polizze?: string | null;
          versicherungsart: string;
          versicherungssumme?: number | null;
          wertsicherung?: string | null;
          postIt?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            immerAnzeigen: boolean;
            postItId: string;
            text: string;
            titel: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          status: { text: string; value: Types.InfoFeldStatus };
          type: { text: string; value: Types.ObjektInfoFeldType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          contactPerson?: {
            contactPersonId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            lastname: string;
            title?: string | null;
            firstname: string;
            salutation?: { text: string; value: Types.Salutation } | null;
            contactList: Array<{
              contactId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              haupt?: boolean | null;
              tagList: Array<string>;
              type: string;
              wert: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            warningList: Array<{ message: string; type: string }>;
          } | null;
          firma?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            name1: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ message: string; type: string }>;
          } | null;
          kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
          kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
          makler?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            rechtstraegerId: string;
            kurzBezeichnung: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ message: string; type: string }>;
          } | null;
          sachbearbeiter?: {
            contactPersonId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            firstname: string;
            lastname: string;
            tagList: Array<string>;
            title?: string | null;
            titleTrailing?: string | null;
            contactList: Array<{
              contactId: string;
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              haupt?: boolean | null;
              tagList: Array<string>;
              type: string;
              wert: string;
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            }>;
            rechtstraeger?: {
              createTs: string;
              createdBy: string;
              createdByMitarbeiterId?: string | null;
              kurzBezeichnung: string;
              rechtstraegerId: string;
              status: { text: string; value: Types.RechtstraegerStatus };
              type: { text: string; value: Types.RechtstraegerType };
              warningList: Array<{ attribute?: string | null; message: string; type: string }>;
            } | null;
            salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          zahlungsperiode?: { text: string; value: Types.Zahlungsperiode } | null;
        };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const ObjektInfoFeldListDocument = gql`
  query ObjektInfoFeldList($objektId: ID!, $includeArchiviert: Boolean) {
    getObjektInfoFeldList(objektId: $objektId, includeArchiviert: $includeArchiviert) {
      data {
        ...ObjektInfoFeld
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${ObjektInfoFeldFragmentDoc}
`;
export function useObjektInfoFeldListQuery(
  baseOptions: Apollo.QueryHookOptions<ObjektInfoFeldListQuery, ObjektInfoFeldListQueryVariables> &
    ({ variables: ObjektInfoFeldListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ObjektInfoFeldListQuery, ObjektInfoFeldListQueryVariables>(ObjektInfoFeldListDocument, options);
}
export function useObjektInfoFeldListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjektInfoFeldListQuery, ObjektInfoFeldListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ObjektInfoFeldListQuery, ObjektInfoFeldListQueryVariables>(ObjektInfoFeldListDocument, options);
}
export function useObjektInfoFeldListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ObjektInfoFeldListQuery, ObjektInfoFeldListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ObjektInfoFeldListQuery, ObjektInfoFeldListQueryVariables>(ObjektInfoFeldListDocument, options);
}
export type ObjektInfoFeldListQueryHookResult = ReturnType<typeof useObjektInfoFeldListQuery>;
export type ObjektInfoFeldListLazyQueryHookResult = ReturnType<typeof useObjektInfoFeldListLazyQuery>;
export type ObjektInfoFeldListSuspenseQueryHookResult = ReturnType<typeof useObjektInfoFeldListSuspenseQuery>;
export type ObjektInfoFeldListQueryResult = Apollo.QueryResult<ObjektInfoFeldListQuery, ObjektInfoFeldListQueryVariables>;
export const ObjektInfoFeldDocument = gql`
  query ObjektInfoFeld($objektId: ID!, $infoFeldId: ID!) {
    getObjektInfoFeld(objektId: $objektId, infoFeldId: $infoFeldId) {
      data {
        ...ObjektInfoFeld
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${ObjektInfoFeldFragmentDoc}
`;
export function useObjektInfoFeldQuery(
  baseOptions: Apollo.QueryHookOptions<ObjektInfoFeldQuery, ObjektInfoFeldQueryVariables> &
    ({ variables: ObjektInfoFeldQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ObjektInfoFeldQuery, ObjektInfoFeldQueryVariables>(ObjektInfoFeldDocument, options);
}
export function useObjektInfoFeldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjektInfoFeldQuery, ObjektInfoFeldQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ObjektInfoFeldQuery, ObjektInfoFeldQueryVariables>(ObjektInfoFeldDocument, options);
}
export function useObjektInfoFeldSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ObjektInfoFeldQuery, ObjektInfoFeldQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ObjektInfoFeldQuery, ObjektInfoFeldQueryVariables>(ObjektInfoFeldDocument, options);
}
export type ObjektInfoFeldQueryHookResult = ReturnType<typeof useObjektInfoFeldQuery>;
export type ObjektInfoFeldLazyQueryHookResult = ReturnType<typeof useObjektInfoFeldLazyQuery>;
export type ObjektInfoFeldSuspenseQueryHookResult = ReturnType<typeof useObjektInfoFeldSuspenseQuery>;
export type ObjektInfoFeldQueryResult = Apollo.QueryResult<ObjektInfoFeldQuery, ObjektInfoFeldQueryVariables>;
