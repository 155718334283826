import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useKontoQuery } from '../../Kontenplan/gql/KontenplanQueries.types';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';
import DataWithShortenedText from '../../../components/Helpers/DataWithShortenedText';
import { generatePathToKontoDetailsPage } from '../../Kontenplan/kontoUriPaths';

type Props = {
  kontoId: string;
};

const InnerCardContentAufwandskonto: FC<Props> = ({ kontoId }) => {
  const { data } = useKontoQuery({ variables: { kontoId } });

  const konto = data?.getKonto.data;

  return konto ? (
    <DataWithShortenedText text={`${konto.nummer} - ${konto.bezeichnung}`} maxTextLength={40} key={konto.kontoId}>
      {(shortenedText) => <Link to={generatePathToKontoDetailsPage(konto.kontoId)}>{shortenedText}</Link>}
    </DataWithShortenedText>
  ) : (
    <TextForEmptyValue textToShow="minus" />
  );
};

export default InnerCardContentAufwandskonto;
