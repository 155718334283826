import { Col, Row, Tabs, TabsProps } from 'antd';
import React from 'react';
import TabPaneContent from '../../../components/Tabs/TabPaneContent';

const Archiv = () => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Archiv',
      children: (
        <TabPaneContent distanceToTopBorder="small">
          <Row justify="space-between" align="middle">
            <Col>@TODO Archiv</Col>
          </Row>
        </TabPaneContent>
      ),
    },
  ];

  return <Tabs items={items} />;
};

export default Archiv;
