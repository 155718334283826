import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteUstVomAufwandMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  ustVomAufwandId: Types.Scalars['ID']['input'];
}>;

export type DeleteUstVomAufwandMutation = {
  deleteUstVomAufwand: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type UstVomAufwandFreigebenMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  ustVomAufwandId: Types.Scalars['ID']['input'];
  input: Types.UstVomAufwandActionFreigebenInput;
}>;

export type UstVomAufwandFreigebenMutation = {
  actionUstVomAufwandFreigeben: {
    data: { ustVomAufwandId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UstVomAufwandMarkUnderReviewMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  ustVomAufwandId: Types.Scalars['ID']['input'];
}>;

export type UstVomAufwandMarkUnderReviewMutation = {
  actionUstVomAufwandMarkUnderReview: {
    data: { ustVomAufwandId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const DeleteUstVomAufwandDocument = gql`
  mutation DeleteUstVomAufwand($objektId: ID!, $ustVomAufwandId: ID!) {
    deleteUstVomAufwand(objektId: $objektId, ustVomAufwandId: $ustVomAufwandId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteUstVomAufwandMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUstVomAufwandMutation, DeleteUstVomAufwandMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUstVomAufwandMutation, DeleteUstVomAufwandMutationVariables>(DeleteUstVomAufwandDocument, options);
}
export type DeleteUstVomAufwandMutationHookResult = ReturnType<typeof useDeleteUstVomAufwandMutation>;
export type DeleteUstVomAufwandMutationResult = Apollo.MutationResult<DeleteUstVomAufwandMutation>;
export type DeleteUstVomAufwandMutationOptions = Apollo.BaseMutationOptions<DeleteUstVomAufwandMutation, DeleteUstVomAufwandMutationVariables>;
export const UstVomAufwandFreigebenDocument = gql`
  mutation UstVomAufwandFreigeben($objektId: ID!, $ustVomAufwandId: ID!, $input: UstVomAufwandActionFreigebenInput!) {
    actionUstVomAufwandFreigeben(objektId: $objektId, ustVomAufwandId: $ustVomAufwandId, input: $input) {
      data {
        ustVomAufwandId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUstVomAufwandFreigebenMutation(
  baseOptions?: Apollo.MutationHookOptions<UstVomAufwandFreigebenMutation, UstVomAufwandFreigebenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UstVomAufwandFreigebenMutation, UstVomAufwandFreigebenMutationVariables>(UstVomAufwandFreigebenDocument, options);
}
export type UstVomAufwandFreigebenMutationHookResult = ReturnType<typeof useUstVomAufwandFreigebenMutation>;
export type UstVomAufwandFreigebenMutationResult = Apollo.MutationResult<UstVomAufwandFreigebenMutation>;
export type UstVomAufwandFreigebenMutationOptions = Apollo.BaseMutationOptions<
  UstVomAufwandFreigebenMutation,
  UstVomAufwandFreigebenMutationVariables
>;
export const UstVomAufwandMarkUnderReviewDocument = gql`
  mutation UstVomAufwandMarkUnderReview($objektId: ID!, $ustVomAufwandId: ID!) {
    actionUstVomAufwandMarkUnderReview(objektId: $objektId, ustVomAufwandId: $ustVomAufwandId) {
      data {
        ustVomAufwandId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUstVomAufwandMarkUnderReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<UstVomAufwandMarkUnderReviewMutation, UstVomAufwandMarkUnderReviewMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UstVomAufwandMarkUnderReviewMutation, UstVomAufwandMarkUnderReviewMutationVariables>(
    UstVomAufwandMarkUnderReviewDocument,
    options
  );
}
export type UstVomAufwandMarkUnderReviewMutationHookResult = ReturnType<typeof useUstVomAufwandMarkUnderReviewMutation>;
export type UstVomAufwandMarkUnderReviewMutationResult = Apollo.MutationResult<UstVomAufwandMarkUnderReviewMutation>;
export type UstVomAufwandMarkUnderReviewMutationOptions = Apollo.BaseMutationOptions<
  UstVomAufwandMarkUnderReviewMutation,
  UstVomAufwandMarkUnderReviewMutationVariables
>;
