import React, { FC } from 'react';

import { Divider, Typography } from 'antd';
import CardWithFooter from '../../../components/Card';
import CardTitle from '../../../components/Card/CardTitle';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import DocumentStatusTag from '../../../components/Status/DocumentStatus/DocumentStatusTag';
import { EuroAmount } from '../../../components/Number';
import AufwandKontoList from './AufwandKontoList';
import UstVomAufwandActions from './UstVomAufwandActions';
import RepFondsKontoList from './RepFondsKontoList';
import { UstVomAufwand } from '../../../types';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';

type Props = {
  ustVomAufwand: UstVomAufwand;
  objektId: string;
  onAction: () => void;
};

const UstVomAufwandCard: FC<Props> = ({ ustVomAufwand, objektId, onAction }) => (
  <CardWithFooter
    loading={false}
    createTs={ustVomAufwand.createTs}
    title={<CardTitle title="Ust vom Aufwand" status={ustVomAufwand.status} />}
    extra={<UstVomAufwandActions ustVomAufwand={ustVomAufwand} objektId={objektId} onAction={onAction} />}
    style={{ minWidth: '320px' }}
    size="default"
    bordered={false}
    userId={ustVomAufwand.createdBy}
    mitarbeiterId={ustVomAufwand.createdByMitarbeiterId}
  >
    <Typography.Title level={5}>Allgemeine Informationen</Typography.Title>
    <CardDetailsInfoRow infoRowTitle="Zeitraum">
      <CustomFormattedDate value={ustVomAufwand.vonInklusive} year="numeric" month="2-digit" />
    </CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle="Fibu Status">
      <DocumentStatusTag documentStatus={ustVomAufwand.buchungStatus} />
      {ustVomAufwand.buchungErrorList.length > 0 && (
        // eslint-disable-next-line react/no-unescaped-entities
        <Typography.Text type="danger">Nach der Korrektur, starten Sie bitte erneut die Operation 'verbuchen'</Typography.Text>
      )}
    </CardDetailsInfoRow>
    <Divider dashed />
    <CardDetailsInfoRow infoRowTitle="Summe ER Netto">
      <EuroAmount value={ustVomAufwand.sumNetto} />
    </CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle="Summe Ust. vom Aufwand">
      <EuroAmount value={ustVomAufwand.sumUstVomAufwand} />
    </CardDetailsInfoRow>
    <Divider dashed />
    <Typography.Title level={5}>Aufwandskonten</Typography.Title>
    <AufwandKontoList ustVomAufwand={ustVomAufwand} objektId={objektId} />
    <Divider dashed />
    <Typography.Title level={5}>RepFonds-Konten</Typography.Title>
    <RepFondsKontoList ustVomAufwand={ustVomAufwand} objektId={objektId} />
  </CardWithFooter>
);

export default UstVomAufwandCard;
