import styled from 'styled-components';

export const StyledSpaceInvoicePage = styled('div')`
  padding: 32px;
  display: flex;

  > div {
    padding: 0 16px;
    &:first-child {
      width: 54%;
      & > div {
        position: sticky !important;
        top: 0;
      }
      & > iframe {
        position: sticky;
        top: 0;
      }
      & img {
        position: sticky;
        top: 0;
      }
    }
    &:last-child {
      width: 45%;
      border-left: 1px solid rgba(0, 0, 0, 0.06);
      & > div:last-child {
        width: 100%;
      }
    }
  }
`;
