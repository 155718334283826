import React, { FC } from 'react';
import { Button, Modal, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../../../../hooks/useToggle';
import ListingTitleWithButtons from '../../../../../components/Listing/ListingTitleWithButtons';
import RechnAusstellerRepFondsKontoZuweisungForm from './KontoZuweisungForm/RechnAusstellerRepFondsKontoZuweisungForm';
import { RepFondsErloesKontenZuweisung } from '../../../../../types';
import HistoryModal, { HistoryType } from '../../../../History/shared/HistoryModal';
import RepFondsKontenZuweisungDeletionHistoryListingTable from './RepFondsKontenZuweisungDeletionHistoryListingTable';

type RechnungsAusstellerRepFondsListingBtnAndModalProps = {
  rechtstraegerId: string;
  onSuccess: () => void;
  repFondsErloesKontenZuweisungList?: RepFondsErloesKontenZuweisung[];
};

const RechnungsAusstellerRepFondsListingBtnAndModal: FC<RechnungsAusstellerRepFondsListingBtnAndModalProps> = ({
  rechtstraegerId,
  onSuccess,
  repFondsErloesKontenZuweisungList,
}) => {
  const [isCollapsed, onCollapse] = useToggle();
  const [isDeletionHistoryOpen, toggleIsDeletionHistoryOpen] = useToggle();

  return (
    <>
      <ListingTitleWithButtons
        title="Reparaturfonds-Erlöskontenzuweisung"
        buttons={
          <Space direction="horizontal">
            <Button onClick={toggleIsDeletionHistoryOpen}>Löschverlauf anzeigen</Button>
            <Button type="primary" onClick={onCollapse}>
              <PlusOutlined />
              Zuweisung hinzufügen
            </Button>
          </Space>
        }
      />
      <Modal title="Erlöskonten-Zuweisung anlegen" footer={null} open={isCollapsed} onCancel={onCollapse} destroyOnClose maskClosable={false}>
        <RechnAusstellerRepFondsKontoZuweisungForm
          rechtstraegerId={rechtstraegerId}
          onSuccess={() => {
            onSuccess();
            onCollapse();
          }}
          onCancel={onCollapse}
          repFondsErloesKontenZuweisungList={repFondsErloesKontenZuweisungList}
        />
      </Modal>
      <HistoryModal
        entityName="Reparaturfonds-Erlöskontenzuweisung"
        historyType={HistoryType.Deletion}
        open={isDeletionHistoryOpen}
        onCancel={toggleIsDeletionHistoryOpen}
      >
        <RepFondsKontenZuweisungDeletionHistoryListingTable rechtstraegerId={rechtstraegerId} />
      </HistoryModal>
    </>
  );
};

export default RechnungsAusstellerRepFondsListingBtnAndModal;
