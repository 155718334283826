import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NotificationListQueryVariables = Types.Exact<{
  createTsBisInklusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsVonInklusive?: Types.InputMaybe<Types.Scalars['String']['input']>;
  type?: Types.InputMaybe<Types.NotificationType>;
  dismissed?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  order?: Types.InputMaybe<Types.NotificationOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type NotificationListQuery = {
  getNotificationList: {
    data: {
      contentList: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        dismissed: boolean;
        notificationCategory: Types.NotificationCategory;
        notificationId: string;
        notificationType: Types.NotificationType;
        status: Types.NotificationStatus;
        actionList: Array<{ body?: any | null; link: string; method: string; typeOperation: Types.NotificationTypeOperation }>;
        notificationInfo: { titleType: Types.NotificationTitleType; parameterList: Array<{ key: string; value: string }> };
        progress: { processed: number; total: number };
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const NotificationListDocument = gql`
  query NotificationList(
    $createTsBisInklusive: String
    $createTsVonInklusive: String
    $type: NotificationType
    $dismissed: Boolean
    $order: NotificationOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getNotificationList(
      createTsBisInklusive: $createTsBisInklusive
      createTsVonInklusive: $createTsVonInklusive
      type: $type
      dismissed: $dismissed
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          actionList {
            body
            link
            method
            typeOperation
          }
          createTs
          createdBy
          createdByMitarbeiterId
          dismissed
          notificationCategory
          notificationId
          notificationInfo {
            parameterList {
              key
              value
            }
            titleType
          }
          notificationType
          progress {
            processed
            total
          }
          status
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useNotificationListQuery(baseOptions?: Apollo.QueryHookOptions<NotificationListQuery, NotificationListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotificationListQuery, NotificationListQueryVariables>(NotificationListDocument, options);
}
export function useNotificationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationListQuery, NotificationListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotificationListQuery, NotificationListQueryVariables>(NotificationListDocument, options);
}
export function useNotificationListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<NotificationListQuery, NotificationListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NotificationListQuery, NotificationListQueryVariables>(NotificationListDocument, options);
}
export type NotificationListQueryHookResult = ReturnType<typeof useNotificationListQuery>;
export type NotificationListLazyQueryHookResult = ReturnType<typeof useNotificationListLazyQuery>;
export type NotificationListSuspenseQueryHookResult = ReturnType<typeof useNotificationListSuspenseQuery>;
export type NotificationListQueryResult = Apollo.QueryResult<NotificationListQuery, NotificationListQueryVariables>;
