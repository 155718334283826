import { generatePath } from 'react-router';
import { ParsedQueryValueType } from '../../hooks/useStringQueryParams';
import { URI_RECHTSTRAEGER_DETAILS_PAGE } from './rechtstraegerUriPaths';
import { generateQueryStringsForDocumentFilter } from '../Document/helpers/documentHelper';

export const generatePathToRechtstraegerDetailsPage = (rechtstraegerId: string): string =>
  generatePath(URI_RECHTSTRAEGER_DETAILS_PAGE, { rechtstraegerId });

export const generatePathToRechtstraegerDocuments = (
  rechtstraegerId: string,
  rechnungTyp: ParsedQueryValueType,
  ausstellerRechtstraegerId: ParsedQueryValueType,
  empfaengerRechtstraegerId: ParsedQueryValueType
) => {
  const path = generatePathToRechtstraegerDetailsPage(rechtstraegerId);
  const query = generateQueryStringsForDocumentFilter(rechnungTyp, ausstellerRechtstraegerId, empfaengerRechtstraegerId);
  return `${path}/documents${query}`;
};

export const generatePathToRechtstraegerDetailsRechnungAusstellerMenu = (rechtstraegerId: string): string => {
  return `${generatePathToRechtstraegerDetailsPage(rechtstraegerId)}/invoice-issuer`;
};

export const generatePathToRechtstraegerDetailsVertragspartnerMenu = (rechtstraegerId: string): string => {
  return `${generatePathToRechtstraegerDetailsPage(rechtstraegerId)}/vertragspartner`;
};

export const generatePathToRechtstraegerDetailsVertraegeMenu = (rechtstraegerId: string): string => {
  return `${generatePathToRechtstraegerDetailsPage(rechtstraegerId)}/vertraege`;
};

const generatePathToRechtstraegerDetailsRechnungAusstellerMenuGeneralTab = (rechtstraegerId: string): string => {
  return `${generatePathToRechtstraegerDetailsRechnungAusstellerMenu(rechtstraegerId)}/general`;
};
const generatePathToRechtstraegerDetailsRechnungAusstellerMenuKontenTab = (rechtstraegerId: string): string => {
  return `${generatePathToRechtstraegerDetailsRechnungAusstellerMenu(rechtstraegerId)}/accounts`;
};

const generatePathToRechtstraegerDetailsRechnungAusstellerMenuAbrechDefTab = (rechtstraegerId: string): string => {
  return `${generatePathToRechtstraegerDetailsRechnungAusstellerMenu(rechtstraegerId)}/abrechnungsdefinitionen`;
};

const generatePathToRechtstraegerDetailsRechnungAusstellerMenuMahnDefTab = (rechtstraegerId: string): string => {
  return `${generatePathToRechtstraegerDetailsRechnungAusstellerMenu(rechtstraegerId)}/mahndefinition`;
};

const generatePathToRechtstraegerDetailsRechnungAusstellerMenuRepFondsDefTab = (rechtstraegerId: string): string => {
  return `${generatePathToRechtstraegerDetailsRechnungAusstellerMenu(rechtstraegerId)}/repfonds`;
};

const generatePathToRechtstraegerDetailsRechnungAusstellerMenuBelegnummernkreisDefTab = (rechtstraegerId: string): string => {
  return `${generatePathToRechtstraegerDetailsRechnungAusstellerMenu(rechtstraegerId)}/belegnummernkreis`;
};

const generatePathToRechtstraegerDetailsRechnungAusstellerMenuBelegtextTab = (rechtstraegerId: string): string => {
  return `${generatePathToRechtstraegerDetailsRechnungAusstellerMenu(rechtstraegerId)}/belegtext`;
};

const generatePathToRechtstraegerDetailsInfoFeldMenu = (rechtstraegerId: string): string => {
  return `${generatePathToRechtstraegerDetailsPage(rechtstraegerId)}/info-felder`;
};

const generatePathToRechtstraegerDetailsCallLogMenu = (rechtstraegerId: string): string => {
  return `${generatePathToRechtstraegerDetailsPage(rechtstraegerId)}/call-log`;
};

export const pathsToRechtstraegerDetails = (rechtstraegerId: string) => {
  return {
    rechtstraegerDetailsPage: generatePathToRechtstraegerDetailsPage(rechtstraegerId),
    rechnungAusstellerMenu: generatePathToRechtstraegerDetailsRechnungAusstellerMenu(rechtstraegerId),
    rechnungAusstellerGeneralTab: generatePathToRechtstraegerDetailsRechnungAusstellerMenuGeneralTab(rechtstraegerId),
    rechnungAusstellerKontenTab: generatePathToRechtstraegerDetailsRechnungAusstellerMenuKontenTab(rechtstraegerId),
    rechnungAusstellerAbrDefTab: generatePathToRechtstraegerDetailsRechnungAusstellerMenuAbrechDefTab(rechtstraegerId),
    rechnungAusstellerMahnDefTab: generatePathToRechtstraegerDetailsRechnungAusstellerMenuMahnDefTab(rechtstraegerId),
    rechnungAustellerRepFondsDefTab: generatePathToRechtstraegerDetailsRechnungAusstellerMenuRepFondsDefTab(rechtstraegerId),
    rechnungAustellerBelegnummernkreisDefTab: generatePathToRechtstraegerDetailsRechnungAusstellerMenuBelegnummernkreisDefTab(rechtstraegerId),
    rechnungAustellerBelegtextTab: generatePathToRechtstraegerDetailsRechnungAusstellerMenuBelegtextTab(rechtstraegerId),
    infoFeldMenu: generatePathToRechtstraegerDetailsInfoFeldMenu(rechtstraegerId),
    callLogMenu: generatePathToRechtstraegerDetailsCallLogMenu(rechtstraegerId),
  };
};
