import React, { FC } from 'react';
import { Box, Flex } from 'rebass';
import { Button, Tooltip } from 'antd';
import { EditOutlined, HistoryOutlined } from '@ant-design/icons';
import theme from '../../theme';

type Props = {
  openDrawerCreate: () => void;
  openDrawerUpdate?: () => void;
  withHistory?: boolean;
  disabledUpdate?: boolean;
  disabledHistory?: boolean;
};

const ContextMenu: FC<Props> = ({ openDrawerCreate, openDrawerUpdate, withHistory = false, disabledUpdate, disabledHistory }) => (
  <Flex mb="8px">
    <Box mx="auto" />
    <Box>
      {withHistory ? (
        <Tooltip title="Historie">
          <Button
            type="text"
            shape="circle"
            onClick={openDrawerUpdate || openDrawerCreate}
            icon={<HistoryOutlined style={{ color: theme.colors.blue }} />}
            size="small"
            disabled={disabledHistory}
          />
        </Tooltip>
      ) : (
        <Tooltip title="Bearbeiten">
          <Button
            type="text"
            shape="circle"
            disabled={disabledUpdate}
            icon={<EditOutlined style={{ color: theme.colors.blue }} />}
            onClick={openDrawerCreate}
            size="small"
          />
        </Tooltip>
      )}
    </Box>
  </Flex>
);

export default ContextMenu;
