import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useZahlungsperiodeListQuery } from './qgl/ZahlungsperiodeQueries.types';

type ZahlungsperiodeSelectProps = SelectProps;

const ZahlungsperiodeSelect: FC<ZahlungsperiodeSelectProps> = ({ name, ...restProps }) => {
  const { data, loading } = useZahlungsperiodeListQuery();

  const zahlungsperiodeList = data?.getZahlungsperiodeList.data ?? [];

  return (
    <Select name={name} loading={loading} id={name} allowClear size="small" style={{ minWidth: '160px' }} {...restProps}>
      {zahlungsperiodeList.map((zahlungsperiode) => (
        <Select.Option value={zahlungsperiode.value} key={zahlungsperiode.value}>
          {zahlungsperiode.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ZahlungsperiodeSelect;
