import React from 'react';
import { Link } from 'react-router-dom';
import EmailVerified from '../../shared/components/Email/EmailVerified';
import StatusBadge from '../../components/Status/StatusBadge';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../constants/andromedaSystemSettingsUriPaths';

const columnsUserTable = [
  {
    title: 'Benutzername',
    key: 'username',
    render: (text, record) => <Link to={{ pathname: `${URI_ANDROMEDA_SYS_SETTINGS.user}/${record.userId}` }}>{record.username}</Link>,
  },
  {
    title: 'E-Mail-Adresse',
    key: 'email',
    render: (text, record) => (
      <span>
        <EmailVerified state="table" email={record.email} />
      </span>
    ),
  },
  {
    title: 'Vorname',
    key: 'firstname',
    render: (text, record) => <span>{record.firstname ? record.firstname : 'nicht angegeben'}</span>,
  },
  {
    title: 'Nachname',
    key: 'lastname',
    render: (text, record) => <span>{record.lastname ? record.lastname : 'nicht angegeben'}</span>,
  },
  {
    title: 'Firma',
    key: 'firmen',
    render: (text, record) => <span>{record.firmen.map((firma) => `${firma}, `)}</span>,
  },
  {
    title: 'Status',
    key: 'status',
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },
];

export default columnsUserTable;
