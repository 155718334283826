import React from 'react';
import { isEqual } from 'lodash';
import { getNextAvailableDateFromToday } from '../../../../../helpers/dateHelper';
import { abrechnungsdefinitionValidFromFormattedDate } from '../../../shared/AbrDefFormValidFrom';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import { AbrechnungsdefinitionVersion, AbrechnungsdefinitionVersionListData } from '../../../shared/abrDefTypes';

export const getNextAvailableValidFromOrToday = (
  versionList: AbrechnungsdefinitionVersionListData[],
  precedingVersion?: AbrechnungsdefinitionVersion
) => {
  const nextAvailableDate = getNextAvailableDateFromToday(getAlreadyTakenValidFromDates(versionList));

  return precedingVersion?.validFrom
    ? nextAvailableDate.format(abrechnungsdefinitionValidFromFormattedDate).toString()
    : dayjsCustom().format(abrechnungsdefinitionValidFromFormattedDate).toString();
};

export const getAlreadyTakenValidFromDates = (versionList: AbrechnungsdefinitionVersionListData[]) => {
  return versionList.map((version) => dayjsCustom(version.validFrom));
};

export const updateCommentOnTemplateChange = (
  initialValue: TextbausteinFormValues[],
  formValues: TextbausteinFormValues[],
  textForComment: string,
  setKommentarFormValues: React.Dispatch<React.SetStateAction<string>>
) => {
  const hasTemplateValuesChanged = !isEqual(initialValue, formValues);
  hasTemplateValuesChanged
    ? setKommentarFormValues((prevState) => {
        const text = `${textForComment} geändert`;
        return !prevState.includes(textForComment) ? `${prevState ? prevState + ', ' + text : text}` : prevState;
      })
    : setKommentarFormValues((prevState) => {
        const isTextAlreadyAdded = prevState.includes(textForComment);
        if (isTextAlreadyAdded) {
          const positionOfText = prevState.indexOf(textForComment);
          const isTextContainingComma = prevState.includes(',');
          // -2 is used to remove comma and whitespace
          return prevState.slice(0, isTextContainingComma ? positionOfText - 2 : positionOfText);
        }
        return prevState;
      });
};
