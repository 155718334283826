import { Space, Typography } from 'antd';
import { FC } from 'react';
import { TableOutlined } from '@ant-design/icons';
import InfoFeldListItem from '../InfoFeldListItem';
import KellerabteilListItemActions from './KellerabteilListItemActions';
import { BeInfoFeld_BeInfoFeldKellerabteil_Fragment } from '../../../gql/BeInfoFeldFragments.types';
import { StyledSpaceSplitA30 } from '../../../../../../components/Space/styled/Space.style';

type Props = {
  kellerabteil: BeInfoFeld_BeInfoFeldKellerabteil_Fragment;
  objektId: string;
  bestandseinheitId: string;
  onSuccess: () => void;
};

const KellerabteilListItem: FC<Props> = ({ kellerabteil, objektId, bestandseinheitId, onSuccess }) => {
  return (
    <InfoFeldListItem
      icon={<TableOutlined />}
      content={{ fileIdList: kellerabteil.fileIdList, props: { height: 48 } }}
      actions={
        <KellerabteilListItemActions infoFeld={kellerabteil} objektId={objektId} bestandseinheitId={bestandseinheitId} onSuccess={onSuccess} />
      }
    >
      <Space direction="vertical" style={{ width: '220px' }}>
        <Typography.Text type="secondary">Kellerabteil</Typography.Text>
        <Typography.Text>Nummer {kellerabteil.kellerabteilNummer}</Typography.Text>
      </Space>
      <StyledSpaceSplitA30>{kellerabteil.text && <Typography.Text>Text {kellerabteil.text}</Typography.Text>}</StyledSpaceSplitA30>
    </InfoFeldListItem>
  );
};

export default KellerabteilListItem;
