import React from 'react';
import { Route } from 'react-router';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../security/permissionChecks';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_SYS_SETTINGS } from '../../../../constants/configurationUriPaths';
import SysSettingsBudgetingVerknuepfungVPosAuftSchluesselPage from './SysSettingsBudgetingVerknuepfungVPosAuftSchluesselPage';

const sysSettingsBudgetingVerknuepfungVPosAuftSchluesselPageRoute = (
  <Route
    path={URI_SYS_SETTINGS.budgetingVerknuepfungVPosAuftSchluesselPage}
    element={
      <AuthRoute
        hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm}
        element={<SysSettingsBudgetingVerknuepfungVPosAuftSchluesselPage />}
      />
    }
  />
);

export default sysSettingsBudgetingVerknuepfungVPosAuftSchluesselPageRoute;
