import React, { FC } from 'react';
import { Empty } from 'antd';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { BudgetingAbrDefVPosLinkage } from '../../../../types';
import entryListingTableColumns from './entryListingTableColumns';

type Props = {
  linkageEntryList: BudgetingAbrDefVPosLinkage[];
  onAction: () => void;
  loading: boolean;
};

const EntryListingTable: FC<Props> = ({ linkageEntryList, onAction, loading }) => {
  return (
    <TableWithColSelector<BudgetingAbrDefVPosLinkage>
      size="small"
      locale={{
        emptyText: <Empty description={<span>Keine Verknüpfungen vorhanden</span>} />,
      }}
      loading={loading}
      columns={entryListingTableColumns(onAction, linkageEntryList)}
      dataSource={linkageEntryList}
      rowKey={(record) => record.budgetingAbrDefVPosLinkageId}
      filterIdentifier="main-budgeting-abr-def-vpos-linkage-template"
    />
  );
};

export default EntryListingTable;
