import React from 'react';
import { Modal } from 'antd';
import { isEqual } from 'lodash';
import {
  AbrechnungsdefinitionKontoAbrechnungskreisZuordnungInput,
  AbrechnungsdefinitionType,
  DueDateDisplayOptionsInput,
  DueDateDisplayOptionType,
  KontoAbrechnungskreis,
} from '../../../../../../types';
import { dayjsCustom } from '../../../../../../helpers/dayjsCustom';
import { TextbausteinFormValues } from '../../../../../../components/Template/PDFTemplates/templateMapper';
import { FormikFieldHookProps } from '../../../../../../helpers/formikHelper';
import { AbrechnungsdefinitionVersion, AbrechnungsdefinitionVersionListData } from '../../../../shared/abrDefTypes';
import {
  isDueDateOptionTypeUebernaechsterZinstermin,
  isDueDateOptionTypeVerrechnungsperiode,
} from '../../../../shared/Templates/shared/BkOrWe/dueDateHelpers';
import { isAbrDefTypeBkAbrechnung, isAbrDefTypeWeAbrechnung } from '../../../../shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import { isNotNil } from '../../../../../../helpers/assertionHelper';

export const getAlreadyTakenValidFromDates = (versionList: AbrechnungsdefinitionVersionListData[], versionToUpdate: AbrechnungsdefinitionVersion) => {
  return versionList
    .filter((abrDef) => abrDef.abrechnungsdefinitionVersionId !== versionToUpdate.abrechnungsdefinitionVersionId)
    .map((abrDef) => dayjsCustom(abrDef.validFrom));
};

export const updateActiveTabIfTemplateChanged = (
  originalValues: TextbausteinFormValues[],
  currentValues: TextbausteinFormValues[],
  setActiveTab: React.Dispatch<React.SetStateAction<string | undefined>>,
  tabKey: string
) => {
  if (isEqual(originalValues, currentValues)) {
    setActiveTab(undefined);
  } else {
    setActiveTab(tabKey);
  }
};

export const onAbrechnungskreisChange = (
  kontoAbrechnungskreisList: KontoAbrechnungskreis[],
  setKontoAbrechnungskreisValues: React.Dispatch<React.SetStateAction<AbrechnungsdefinitionKontoAbrechnungskreisZuordnungInput[]>>,
  newAbrechnungskreisId: string,
  abrechnungskreisFieldProps: FormikFieldHookProps<string>,
  onSuccess: () => void
) => {
  const [{ value: currentAbrKreisId }, , { setValue: setAbrKreisId }] = abrechnungskreisFieldProps;
  const kontozuordnungInUse = kontoAbrechnungskreisList.find(
    (kontoAbrKreis) => kontoAbrKreis.abrechnungskreis?.abrechnungskreisId === currentAbrKreisId
  )?.abrechnungskreis;

  if (kontozuordnungInUse && newAbrechnungskreisId !== currentAbrKreisId) {
    confirmChangingAbrKreis(
      kontozuordnungInUse.bezeichnung,
      () => {
        onSuccess();
        setKontoAbrechnungskreisValues((prevState) => prevState.filter((kontoAbr) => kontoAbr.abrechnungskreisId !== currentAbrKreisId));
      },
      () => {
        setAbrKreisId(currentAbrKreisId);
      }
    );
  } else {
    onSuccess();
  }
};

export const confirmChangingAbrKreis = (abrKreisBezeichnung: string, onOk: () => void, onCancel: () => void) => {
  Modal.confirm({
    title: `Kontenzuordnungen werden mit diesen Abrechnungskreis "${abrKreisBezeichnung}" gelöscht`,
    content: `Möchten Sie den Abrechnungskreis "${abrKreisBezeichnung}" trotzdem ändern?`,
    okText: 'Ok',
    cancelText: 'Nein',
    onOk() {
      onOk();
    },
    onCancel() {
      onCancel();
    },
  });
};

export const onAbrechnungskreisZuweisungDelete = (
  kontoAbrechnungskreisList: KontoAbrechnungskreis[],
  setKontoAbrechnungskreisValues: React.Dispatch<React.SetStateAction<AbrechnungsdefinitionKontoAbrechnungskreisZuordnungInput[]>>,
  abrechnungskreisToDeleteId: string,
  onDelete: () => void
) => {
  const kontozuordnungInUse = kontoAbrechnungskreisList?.find(
    (kontoAbrKreis) => kontoAbrKreis.abrechnungskreis?.abrechnungskreisId === abrechnungskreisToDeleteId
  )?.abrechnungskreis;

  if (kontozuordnungInUse) {
    confirmDeletingAbrechnungskreisZuweisung(() => {
      setKontoAbrechnungskreisValues((prevState) => prevState.filter((abrKreis) => abrKreis.abrechnungskreisId !== abrechnungskreisToDeleteId));
      onDelete();
    });
  } else {
    onDelete();
  }
};

const confirmDeletingAbrechnungskreisZuweisung = (onSubmit: () => void) => {
  Modal.confirm({
    title: 'Kontenzuordnungen werden mit diesem Abrechnungskreis gelöscht',
    content: 'Möchten Sie den Abrechnungskreis trotzdem löschen?',
    okText: 'Ok',
    cancelText: 'Nein',
    onOk() {
      onSubmit();
    },
  });
};

export const validateDueDateOptions = (
  abrDefType: AbrechnungsdefinitionType,
  dueDateDisplayOptionEigentuemerGuthaben?: DueDateDisplayOptionsInput | null,
  dueDateDisplayOptionEigentuemerNachzahlung?: DueDateDisplayOptionsInput | null,
  dueDateDisplayOptionMieter?: DueDateDisplayOptionsInput | null
) => {
  const isTypeVerrechnungsperiodeAndMonthChosen = (type?: DueDateDisplayOptionType | null, month?: number | null) =>
    isDueDateOptionTypeVerrechnungsperiode(type) && isNotNil(month);

  if (isAbrDefTypeBkAbrechnung(abrDefType)) {
    return (
      isDueDateOptionTypeUebernaechsterZinstermin(dueDateDisplayOptionMieter?.type) ||
      isTypeVerrechnungsperiodeAndMonthChosen(dueDateDisplayOptionMieter?.type, dueDateDisplayOptionMieter?.month)
    );
  }

  if (isAbrDefTypeWeAbrechnung(abrDefType)) {
    const isEigentuemerGuthabenValid =
      isDueDateOptionTypeUebernaechsterZinstermin(dueDateDisplayOptionEigentuemerGuthaben?.type) ||
      isTypeVerrechnungsperiodeAndMonthChosen(dueDateDisplayOptionEigentuemerGuthaben?.type, dueDateDisplayOptionEigentuemerGuthaben?.month);

    const isEigentuemerNachzahlungValid =
      isDueDateOptionTypeUebernaechsterZinstermin(dueDateDisplayOptionEigentuemerNachzahlung?.type) ||
      isTypeVerrechnungsperiodeAndMonthChosen(dueDateDisplayOptionEigentuemerNachzahlung?.type, dueDateDisplayOptionEigentuemerNachzahlung?.month);

    return isEigentuemerGuthabenValid && isEigentuemerNachzahlungValid;
  }

  return true;
};
