import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { PaymentRechtstraegerFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import { OffenePostenFieldsFragmentDoc } from '../../OffenePosten/gql/OffenePostenFragments.types';
import { HistoryFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BookingSuggestionQueryVariables = Types.Exact<{
  bookingSuggestionId: Types.Scalars['String']['input'];
}>;

export type BookingSuggestionQuery = {
  getBookingSuggestion: {
    data: {
      bookingSuggestionId: string;
      fibuBuchungIdList: Array<string>;
      name: string;
      buchungsanweisung: {
        belegDatum: string;
        belegnummer: string;
        buchungsanweisungId: string;
        dataCarrierBelegId: string;
        dueDate?: string | null;
        erstelltAm: string;
        fibuBuchungId?: string | null;
        iban?: string | null;
        statementNumber: string;
        valid: boolean;
        belegSymbol: { abbreviation: string; text: string; value: Types.PaymentBelegSymbol };
        buchungType: { text: string; value: Types.PaymentBuchungType };
        buchungskreisRechtstraeger?: { buchungskreisId?: string | null; kurzBezeichnung: string; rechtstraegerId: string } | null;
        buchungszeileList: Array<{
          betrag: number;
          buchungsKZ?: string | null;
          buchungszeileId: string;
          dataCarrierName: string;
          deletable: boolean;
          editable: boolean;
          heVertragId?: string | null;
          steuer?: number | null;
          steuercode?: string | null;
          text: string;
          ustVomAufwandSteuersatz?: number | null;
          gegenKonto?: {
            bezeichnung: string;
            firmendatenId: string;
            kontoId: string;
            nummer: string;
            klasse: { text: string; value: Types.KontoKlasse };
            type: { value: Types.KontoType; text: string };
            verwendung?: { text: string; value: Types.KontoVerwendung } | null;
          } | null;
          konto: {
            bezeichnung: string;
            firmendatenId: string;
            kontoId: string;
            nummer: string;
            klasse: { text: string; value: Types.KontoKlasse };
            type: { value: Types.KontoType; text: string };
            verwendung?: { text: string; value: Types.KontoVerwendung } | null;
          };
          objekt?: { kurzBezeichnung: string; objektId: string } | null;
          sollHaben: { text: string; value: Types.SollHaben };
        }>;
        currency: { text: string; value: Types.Currency };
        fibuBuchungStatus: { text: string; value: Types.PaymentFibuBuchungStatus };
        objekt?: { kurzBezeichnung: string; objektId: string } | null;
      };
      clearingSuggestionList: Array<{
        amount: number;
        clearingSuggestionId: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        dueDate: string;
        offenePostenId: string;
        offenePosten: {
          belegDatum: string;
          belegFileId?: string | null;
          belegnummer: string;
          buchungId: string;
          buchungsdatum: string;
          buchungskreisId: string;
          buchungskreisRechtstraegerId: string;
          buchungskreisRechtstraegerBezeichnung: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          dataCarrierBelegId?: string | null;
          dueDate: string;
          gesamtBetrag: number;
          iban?: string | null;
          letzteMahnstufe: number;
          offenePostenId: string;
          offenerBetrag: number;
          statementNumber?: string | null;
          auszifferungList: Array<{
            auszifferungId: string;
            auszifferungsDatum: string;
            betrag: number;
            bookingSuggestionId?: string | null;
            buchungId: string;
            buchungsdatum: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            offenePostenId: string;
            storniert: boolean;
            storniertByMitarbeiterId?: string | null;
            storniertTs?: string | null;
            stornierteOffenePostenId?: string | null;
            stornoOffenePostenId?: string | null;
            updatedByMitarbeiterId?: string | null;
            zahlungId?: string | null;
            source: { text: string; value: Types.AuszifferungSource };
          }>;
          belegSymbol: { text: string; value: Types.FibuBelegSymbol; abbreviation: string };
          konto: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            haupt: boolean;
            kontoId: string;
            kontoTemplateId?: string | null;
            nummer: string;
            tagList: Array<string>;
            updatedByMitarbeiterId?: string | null;
            klasse: { text: string; value: Types.KontoKlasse };
            status: { description?: string | null; text: string; value: Types.KontoStatus };
            type: { text: string; value: Types.KontoType };
            verwendung?: { text: string; value: Types.KontoVerwendung } | null;
          };
          vertragspartner: {
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
          };
        };
      }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type BookingSuggestionWithCurrentBookingDataQueryVariables = Types.Exact<{
  bookingSuggestionId: Types.Scalars['String']['input'];
}>;

export type BookingSuggestionWithCurrentBookingDataQuery = {
  getBookingSuggestion: {
    data: { bookingSuggestionId: string; fibuBuchungIdList: Array<string>; buchungsanweisung: { fibuBuchungId?: string | null } };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type BookingSuggestionListQueryVariables = Types.Exact<{
  receivedBankTransactionId: Types.Scalars['String']['input'];
}>;

export type BookingSuggestionListQuery = {
  getBookingSuggestionList: {
    data: Array<{
      bookingSuggestionId: string;
      fibuBuchungIdList: Array<string>;
      name: string;
      buchungsanweisung: {
        belegDatum: string;
        belegnummer: string;
        buchungsanweisungId: string;
        dataCarrierBelegId: string;
        dueDate?: string | null;
        erstelltAm: string;
        fibuBuchungId?: string | null;
        iban?: string | null;
        statementNumber: string;
        valid: boolean;
        belegSymbol: { abbreviation: string; text: string; value: Types.PaymentBelegSymbol };
        buchungType: { text: string; value: Types.PaymentBuchungType };
        buchungskreisRechtstraeger?: { buchungskreisId?: string | null; kurzBezeichnung: string; rechtstraegerId: string } | null;
        buchungszeileList: Array<{
          betrag: number;
          buchungsKZ?: string | null;
          buchungszeileId: string;
          dataCarrierName: string;
          deletable: boolean;
          editable: boolean;
          heVertragId?: string | null;
          steuer?: number | null;
          steuercode?: string | null;
          text: string;
          ustVomAufwandSteuersatz?: number | null;
          gegenKonto?: {
            bezeichnung: string;
            firmendatenId: string;
            kontoId: string;
            nummer: string;
            klasse: { text: string; value: Types.KontoKlasse };
            type: { value: Types.KontoType; text: string };
            verwendung?: { text: string; value: Types.KontoVerwendung } | null;
          } | null;
          konto: {
            bezeichnung: string;
            firmendatenId: string;
            kontoId: string;
            nummer: string;
            klasse: { text: string; value: Types.KontoKlasse };
            type: { value: Types.KontoType; text: string };
            verwendung?: { text: string; value: Types.KontoVerwendung } | null;
          };
          objekt?: { kurzBezeichnung: string; objektId: string } | null;
          sollHaben: { text: string; value: Types.SollHaben };
        }>;
        currency: { text: string; value: Types.Currency };
        fibuBuchungStatus: { text: string; value: Types.PaymentFibuBuchungStatus };
        objekt?: { kurzBezeichnung: string; objektId: string } | null;
      };
      clearingSuggestionList: Array<{
        amount: number;
        clearingSuggestionId: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        dueDate: string;
        offenePostenId: string;
        offenePosten: {
          belegDatum: string;
          belegFileId?: string | null;
          belegnummer: string;
          buchungId: string;
          buchungsdatum: string;
          buchungskreisId: string;
          buchungskreisRechtstraegerId: string;
          buchungskreisRechtstraegerBezeichnung: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          dataCarrierBelegId?: string | null;
          dueDate: string;
          gesamtBetrag: number;
          iban?: string | null;
          letzteMahnstufe: number;
          offenePostenId: string;
          offenerBetrag: number;
          statementNumber?: string | null;
          auszifferungList: Array<{
            auszifferungId: string;
            auszifferungsDatum: string;
            betrag: number;
            bookingSuggestionId?: string | null;
            buchungId: string;
            buchungsdatum: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            offenePostenId: string;
            storniert: boolean;
            storniertByMitarbeiterId?: string | null;
            storniertTs?: string | null;
            stornierteOffenePostenId?: string | null;
            stornoOffenePostenId?: string | null;
            updatedByMitarbeiterId?: string | null;
            zahlungId?: string | null;
            source: { text: string; value: Types.AuszifferungSource };
          }>;
          belegSymbol: { text: string; value: Types.FibuBelegSymbol; abbreviation: string };
          konto: {
            bezeichnung: string;
            createTs: string;
            createdBy?: string | null;
            createdByMitarbeiterId?: string | null;
            deletable?: boolean | null;
            haupt: boolean;
            kontoId: string;
            kontoTemplateId?: string | null;
            nummer: string;
            tagList: Array<string>;
            updatedByMitarbeiterId?: string | null;
            klasse: { text: string; value: Types.KontoKlasse };
            status: { description?: string | null; text: string; value: Types.KontoStatus };
            type: { text: string; value: Types.KontoType };
            verwendung?: { text: string; value: Types.KontoVerwendung } | null;
          };
          vertragspartner: {
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { description?: string | null; text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
          };
        };
      }>;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type BookingSuggestionBuchungszeileChangeHistoryListQueryVariables = Types.Exact<{
  bookingSuggestionId: Types.Scalars['String']['input'];
  buchungszeileId: Types.Scalars['String']['input'];
}>;

export type BookingSuggestionBuchungszeileChangeHistoryListQuery = {
  getBookingSuggestionBuchungszeileChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const BookingSuggestionDocument = gql`
  query BookingSuggestion($bookingSuggestionId: String!) {
    getBookingSuggestion(bookingSuggestionId: $bookingSuggestionId) {
      data {
        bookingSuggestionId
        buchungsanweisung {
          belegDatum
          belegSymbol {
            abbreviation
            text
            value
          }
          belegnummer
          buchungType {
            text
            value
          }
          buchungsanweisungId
          buchungskreisRechtstraeger {
            ...PaymentRechtstraeger
          }
          buchungszeileList {
            betrag
            buchungsKZ
            buchungszeileId
            dataCarrierName
            deletable
            editable
            gegenKonto {
              bezeichnung
              firmendatenId
              kontoId
              klasse {
                text
                value
              }
              nummer
              type {
                value
                text
              }
              verwendung {
                text
                value
              }
            }
            heVertragId
            konto {
              bezeichnung
              firmendatenId
              kontoId
              klasse {
                text
                value
              }
              nummer
              type {
                value
                text
              }
              verwendung {
                text
                value
              }
            }
            objekt {
              kurzBezeichnung
              objektId
            }
            sollHaben {
              text
              value
            }
            steuer
            steuercode
            text
            ustVomAufwandSteuersatz
          }
          currency {
            text
            value
          }
          dataCarrierBelegId
          dueDate
          erstelltAm
          fibuBuchungId
          fibuBuchungStatus {
            text
            value
          }
          iban
          objekt {
            kurzBezeichnung
            objektId
          }
          statementNumber
          valid
        }
        clearingSuggestionList {
          amount
          clearingSuggestionId
          createTs
          createdBy
          createdByMitarbeiterId
          dueDate
          offenePostenId
          offenePosten {
            ...OffenePostenFields
          }
        }
        fibuBuchungIdList
        name
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${PaymentRechtstraegerFragmentDoc}
  ${OffenePostenFieldsFragmentDoc}
`;
export function useBookingSuggestionQuery(
  baseOptions: Apollo.QueryHookOptions<BookingSuggestionQuery, BookingSuggestionQueryVariables> &
    ({ variables: BookingSuggestionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingSuggestionQuery, BookingSuggestionQueryVariables>(BookingSuggestionDocument, options);
}
export function useBookingSuggestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingSuggestionQuery, BookingSuggestionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingSuggestionQuery, BookingSuggestionQueryVariables>(BookingSuggestionDocument, options);
}
export function useBookingSuggestionSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BookingSuggestionQuery, BookingSuggestionQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BookingSuggestionQuery, BookingSuggestionQueryVariables>(BookingSuggestionDocument, options);
}
export type BookingSuggestionQueryHookResult = ReturnType<typeof useBookingSuggestionQuery>;
export type BookingSuggestionLazyQueryHookResult = ReturnType<typeof useBookingSuggestionLazyQuery>;
export type BookingSuggestionSuspenseQueryHookResult = ReturnType<typeof useBookingSuggestionSuspenseQuery>;
export type BookingSuggestionQueryResult = Apollo.QueryResult<BookingSuggestionQuery, BookingSuggestionQueryVariables>;
export const BookingSuggestionWithCurrentBookingDataDocument = gql`
  query BookingSuggestionWithCurrentBookingData($bookingSuggestionId: String!) {
    getBookingSuggestion(bookingSuggestionId: $bookingSuggestionId) {
      data {
        bookingSuggestionId
        buchungsanweisung {
          fibuBuchungId
        }
        fibuBuchungIdList
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useBookingSuggestionWithCurrentBookingDataQuery(
  baseOptions: Apollo.QueryHookOptions<BookingSuggestionWithCurrentBookingDataQuery, BookingSuggestionWithCurrentBookingDataQueryVariables> &
    ({ variables: BookingSuggestionWithCurrentBookingDataQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingSuggestionWithCurrentBookingDataQuery, BookingSuggestionWithCurrentBookingDataQueryVariables>(
    BookingSuggestionWithCurrentBookingDataDocument,
    options
  );
}
export function useBookingSuggestionWithCurrentBookingDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingSuggestionWithCurrentBookingDataQuery, BookingSuggestionWithCurrentBookingDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingSuggestionWithCurrentBookingDataQuery, BookingSuggestionWithCurrentBookingDataQueryVariables>(
    BookingSuggestionWithCurrentBookingDataDocument,
    options
  );
}
export function useBookingSuggestionWithCurrentBookingDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BookingSuggestionWithCurrentBookingDataQuery, BookingSuggestionWithCurrentBookingDataQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BookingSuggestionWithCurrentBookingDataQuery, BookingSuggestionWithCurrentBookingDataQueryVariables>(
    BookingSuggestionWithCurrentBookingDataDocument,
    options
  );
}
export type BookingSuggestionWithCurrentBookingDataQueryHookResult = ReturnType<typeof useBookingSuggestionWithCurrentBookingDataQuery>;
export type BookingSuggestionWithCurrentBookingDataLazyQueryHookResult = ReturnType<typeof useBookingSuggestionWithCurrentBookingDataLazyQuery>;
export type BookingSuggestionWithCurrentBookingDataSuspenseQueryHookResult = ReturnType<
  typeof useBookingSuggestionWithCurrentBookingDataSuspenseQuery
>;
export type BookingSuggestionWithCurrentBookingDataQueryResult = Apollo.QueryResult<
  BookingSuggestionWithCurrentBookingDataQuery,
  BookingSuggestionWithCurrentBookingDataQueryVariables
>;
export const BookingSuggestionListDocument = gql`
  query BookingSuggestionList($receivedBankTransactionId: String!) {
    getBookingSuggestionList(receivedBankTransactionId: $receivedBankTransactionId) {
      data {
        bookingSuggestionId
        buchungsanweisung {
          belegDatum
          belegSymbol {
            abbreviation
            text
            value
          }
          belegnummer
          buchungType {
            text
            value
          }
          buchungsanweisungId
          buchungskreisRechtstraeger {
            ...PaymentRechtstraeger
          }
          buchungszeileList {
            betrag
            buchungsKZ
            buchungszeileId
            dataCarrierName
            deletable
            editable
            gegenKonto {
              bezeichnung
              firmendatenId
              kontoId
              klasse {
                text
                value
              }
              nummer
              type {
                value
                text
              }
              verwendung {
                text
                value
              }
            }
            heVertragId
            konto {
              bezeichnung
              firmendatenId
              kontoId
              klasse {
                text
                value
              }
              nummer
              type {
                value
                text
              }
              verwendung {
                text
                value
              }
            }
            objekt {
              kurzBezeichnung
              objektId
            }
            sollHaben {
              text
              value
            }
            steuer
            steuercode
            text
            ustVomAufwandSteuersatz
          }
          currency {
            text
            value
          }
          dataCarrierBelegId
          dueDate
          erstelltAm
          fibuBuchungId
          fibuBuchungStatus {
            text
            value
          }
          iban
          objekt {
            kurzBezeichnung
            objektId
          }
          statementNumber
          valid
        }
        clearingSuggestionList {
          amount
          clearingSuggestionId
          createTs
          createdBy
          createdByMitarbeiterId
          dueDate
          offenePostenId
          offenePosten {
            ...OffenePostenFields
          }
        }
        fibuBuchungIdList
        name
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${PaymentRechtstraegerFragmentDoc}
  ${OffenePostenFieldsFragmentDoc}
`;
export function useBookingSuggestionListQuery(
  baseOptions: Apollo.QueryHookOptions<BookingSuggestionListQuery, BookingSuggestionListQueryVariables> &
    ({ variables: BookingSuggestionListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingSuggestionListQuery, BookingSuggestionListQueryVariables>(BookingSuggestionListDocument, options);
}
export function useBookingSuggestionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingSuggestionListQuery, BookingSuggestionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingSuggestionListQuery, BookingSuggestionListQueryVariables>(BookingSuggestionListDocument, options);
}
export function useBookingSuggestionListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BookingSuggestionListQuery, BookingSuggestionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BookingSuggestionListQuery, BookingSuggestionListQueryVariables>(BookingSuggestionListDocument, options);
}
export type BookingSuggestionListQueryHookResult = ReturnType<typeof useBookingSuggestionListQuery>;
export type BookingSuggestionListLazyQueryHookResult = ReturnType<typeof useBookingSuggestionListLazyQuery>;
export type BookingSuggestionListSuspenseQueryHookResult = ReturnType<typeof useBookingSuggestionListSuspenseQuery>;
export type BookingSuggestionListQueryResult = Apollo.QueryResult<BookingSuggestionListQuery, BookingSuggestionListQueryVariables>;
export const BookingSuggestionBuchungszeileChangeHistoryListDocument = gql`
  query BookingSuggestionBuchungszeileChangeHistoryList($bookingSuggestionId: String!, $buchungszeileId: String!) {
    getBookingSuggestionBuchungszeileChangeHistoryList(bookingSuggestionId: $bookingSuggestionId, buchungszeileId: $buchungszeileId) {
      data {
        ...HistoryFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${HistoryFieldsFragmentDoc}
`;
export function useBookingSuggestionBuchungszeileChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    BookingSuggestionBuchungszeileChangeHistoryListQuery,
    BookingSuggestionBuchungszeileChangeHistoryListQueryVariables
  > &
    ({ variables: BookingSuggestionBuchungszeileChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookingSuggestionBuchungszeileChangeHistoryListQuery, BookingSuggestionBuchungszeileChangeHistoryListQueryVariables>(
    BookingSuggestionBuchungszeileChangeHistoryListDocument,
    options
  );
}
export function useBookingSuggestionBuchungszeileChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BookingSuggestionBuchungszeileChangeHistoryListQuery,
    BookingSuggestionBuchungszeileChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookingSuggestionBuchungszeileChangeHistoryListQuery, BookingSuggestionBuchungszeileChangeHistoryListQueryVariables>(
    BookingSuggestionBuchungszeileChangeHistoryListDocument,
    options
  );
}
export function useBookingSuggestionBuchungszeileChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BookingSuggestionBuchungszeileChangeHistoryListQuery,
        BookingSuggestionBuchungszeileChangeHistoryListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BookingSuggestionBuchungszeileChangeHistoryListQuery, BookingSuggestionBuchungszeileChangeHistoryListQueryVariables>(
    BookingSuggestionBuchungszeileChangeHistoryListDocument,
    options
  );
}
export type BookingSuggestionBuchungszeileChangeHistoryListQueryHookResult = ReturnType<
  typeof useBookingSuggestionBuchungszeileChangeHistoryListQuery
>;
export type BookingSuggestionBuchungszeileChangeHistoryListLazyQueryHookResult = ReturnType<
  typeof useBookingSuggestionBuchungszeileChangeHistoryListLazyQuery
>;
export type BookingSuggestionBuchungszeileChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useBookingSuggestionBuchungszeileChangeHistoryListSuspenseQuery
>;
export type BookingSuggestionBuchungszeileChangeHistoryListQueryResult = Apollo.QueryResult<
  BookingSuggestionBuchungszeileChangeHistoryListQuery,
  BookingSuggestionBuchungszeileChangeHistoryListQueryVariables
>;
