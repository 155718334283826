import React, { FC } from 'react';
import { MenuProps, Spin } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import { radixActionStyles } from '../../../../../helpers/radixIconsStyles';
import BelegTexteForm from '../../../../Belegtext/Form/BelegTexteForm';
import { useBelegTexteRechtstraegerQuery, useBelegTexteRechtstraegerTemplatesQuery } from '../../../../Belegtext/gql/BelegtexteQueries.types';
import { useFDAuthorized } from '../../../../../shared/Auth/useAuthorized';
import { useRechtstraegerAddressesAndContactsQuery } from '../../../AddressesAndContacts/gql/AddressesAndContactsQueries.types';
import { BelegTextePlatzhalter } from '../../../../Belegtext/Form/belegTexteFormTypes';
import { buildBelegTexteHauptAddress } from '../../../../Belegtext/Form/belegTexteFormHelpers';
import HistoryModal, { HistoryType } from '../../../../History/shared/HistoryModal';
import { useToggle } from '../../../../../hooks/useToggle';
import RechnungAusstellerBelegTextChangeHistoryListingTable from './RechnungAusstellerBelegTextChangeHistoryListingTable';
import { Firma, NatuerlichePerson, Personengemeinschaft } from '../../../../../types';

type RechnungsAusstellerBelegtextListingProps = {
  rechtstraegerId: string;
  rechtstraeger: Firma | NatuerlichePerson | Personengemeinschaft;
};

const RechnungsAusstellerBelegtextListing: FC<RechnungsAusstellerBelegtextListingProps> = ({ rechtstraegerId, rechtstraeger }) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();

  const { data: belegTexteData, loading, refetch } = useBelegTexteRechtstraegerQuery({ variables: { rechtstraegerId } });
  const belegTexteRT = belegTexteData?.getBelegTexteRechtstraeger.data;

  const { data: belegTexteTemplateData } = useBelegTexteRechtstraegerTemplatesQuery();
  const belegTexteTemplate = belegTexteTemplateData?.getBelegTexteRechtstraegerTemplates.data;

  const { data: rechtstreagerAddressesAndContactsData } = useRechtstraegerAddressesAndContactsQuery({ variables: { rechtstraegerId } });
  const addressesAndContacts = rechtstreagerAddressesAndContactsData?.getRechtstraegerAddressesAndContacts.data ?? undefined;

  if (!belegTexteTemplate || !belegTexteRT || !addressesAndContacts) {
    return <Spin />;
  }

  const belegTextePlatzhalter: BelegTextePlatzhalter = {
    hauptAddress: buildBelegTexteHauptAddress(addressesAndContacts.hauptAddress),
    vatIdentificationNumber: (rechtstraeger as Personengemeinschaft | Firma)?.vatIdentificationNumber,
    phoneNumber: addressesAndContacts.hauptPhoneContact?.wert,
    email: addressesAndContacts.hauptEmailContact?.wert,
  };

  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      label: 'Änderungsverlauf',
      onClick: toggleChangeHistoryOpen,
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
  ];

  return (
    <>
      <BelegTexteForm
        firmendatenId={firmendatenId}
        rechtstraegerId={rechtstraegerId}
        belegTexte={belegTexteRT}
        loading={loading}
        onSuccess={refetch}
        belegTexteTemplate={belegTexteTemplate}
        belegTextePlatzhalter={belegTextePlatzhalter}
        menuItems={menuItems}
      />
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <RechnungAusstellerBelegTextChangeHistoryListingTable rechtstraegerId={rechtstraegerId} />
      </HistoryModal>
    </>
  );
};

export default RechnungsAusstellerBelegtextListing;
