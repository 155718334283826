import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateEmailDeliveryDefinitionMutationVariables = Types.Exact<{
  input: Types.EmailDeliveryDefinitionCreateInput;
}>;

export type CreateEmailDeliveryDefinitionMutation = {
  createEmailDeliveryDefinition: {
    data: { emailDeliveryDefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateEmailDeliveryDefinitionMutationVariables = Types.Exact<{
  emailDeliveryDefinitionId: Types.Scalars['ID']['input'];
  input: Types.EmailDeliveryDefinitionUpdateInput;
}>;

export type UpdateEmailDeliveryDefinitionMutation = {
  updateEmailDeliveryDefinition: {
    data: { emailDeliveryDefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteEmailDeliveryDefinitionMutationVariables = Types.Exact<{
  emailDeliveryDefinitionId: Types.Scalars['ID']['input'];
}>;

export type DeleteEmailDeliveryDefinitionMutation = {
  deleteEmailDeliveryDefinition: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateEmailDeliveryDefinitionDocument = gql`
  mutation CreateEmailDeliveryDefinition($input: EmailDeliveryDefinitionCreateInput!) {
    createEmailDeliveryDefinition(input: $input) {
      data {
        emailDeliveryDefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateEmailDeliveryDefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEmailDeliveryDefinitionMutation, CreateEmailDeliveryDefinitionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEmailDeliveryDefinitionMutation, CreateEmailDeliveryDefinitionMutationVariables>(
    CreateEmailDeliveryDefinitionDocument,
    options
  );
}
export type CreateEmailDeliveryDefinitionMutationHookResult = ReturnType<typeof useCreateEmailDeliveryDefinitionMutation>;
export type CreateEmailDeliveryDefinitionMutationResult = Apollo.MutationResult<CreateEmailDeliveryDefinitionMutation>;
export type CreateEmailDeliveryDefinitionMutationOptions = Apollo.BaseMutationOptions<
  CreateEmailDeliveryDefinitionMutation,
  CreateEmailDeliveryDefinitionMutationVariables
>;
export const UpdateEmailDeliveryDefinitionDocument = gql`
  mutation UpdateEmailDeliveryDefinition($emailDeliveryDefinitionId: ID!, $input: EmailDeliveryDefinitionUpdateInput!) {
    updateEmailDeliveryDefinition(emailDeliveryDefinitionId: $emailDeliveryDefinitionId, input: $input) {
      data {
        emailDeliveryDefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateEmailDeliveryDefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEmailDeliveryDefinitionMutation, UpdateEmailDeliveryDefinitionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEmailDeliveryDefinitionMutation, UpdateEmailDeliveryDefinitionMutationVariables>(
    UpdateEmailDeliveryDefinitionDocument,
    options
  );
}
export type UpdateEmailDeliveryDefinitionMutationHookResult = ReturnType<typeof useUpdateEmailDeliveryDefinitionMutation>;
export type UpdateEmailDeliveryDefinitionMutationResult = Apollo.MutationResult<UpdateEmailDeliveryDefinitionMutation>;
export type UpdateEmailDeliveryDefinitionMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmailDeliveryDefinitionMutation,
  UpdateEmailDeliveryDefinitionMutationVariables
>;
export const DeleteEmailDeliveryDefinitionDocument = gql`
  mutation DeleteEmailDeliveryDefinition($emailDeliveryDefinitionId: ID!) {
    deleteEmailDeliveryDefinition(emailDeliveryDefinitionId: $emailDeliveryDefinitionId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteEmailDeliveryDefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteEmailDeliveryDefinitionMutation, DeleteEmailDeliveryDefinitionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEmailDeliveryDefinitionMutation, DeleteEmailDeliveryDefinitionMutationVariables>(
    DeleteEmailDeliveryDefinitionDocument,
    options
  );
}
export type DeleteEmailDeliveryDefinitionMutationHookResult = ReturnType<typeof useDeleteEmailDeliveryDefinitionMutation>;
export type DeleteEmailDeliveryDefinitionMutationResult = Apollo.MutationResult<DeleteEmailDeliveryDefinitionMutation>;
export type DeleteEmailDeliveryDefinitionMutationOptions = Apollo.BaseMutationOptions<
  DeleteEmailDeliveryDefinitionMutation,
  DeleteEmailDeliveryDefinitionMutationVariables
>;
