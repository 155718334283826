import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import BookingFolderPage from './BookingFolderPage';
import { URI_BOOKING_FOLDER_PAGE } from '../../features/BookingFolder/bookingFolderUriPaths';

const bookingFolderPageRoute = (
  <Route path={URI_BOOKING_FOLDER_PAGE} element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<BookingFolderPage />} />} />
);

export default bookingFolderPageRoute;
