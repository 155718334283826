import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateFirmendatenHeOrObjektOrSubAbrechnungGenerierlaufMutationVariables = Types.Exact<{
  input: Types.HeOrObjektOrSubAbrechnungGenerierlaufCreateInput;
}>;

export type CreateFirmendatenHeOrObjektOrSubAbrechnungGenerierlaufMutation = {
  createFirmendatenHeOrObjektOrSubAbrechnungGenerierlauf: {
    data?: string | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlaufMutationVariables = Types.Exact<{
  input: Types.HeOrObjektOrSubNamedAbrechnungGenerierlaufCreateInput;
}>;

export type CreateFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlaufMutation = {
  createFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlauf: {
    data?: string | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateFirmendatenTopAbrechnungGenerierlaufMutationVariables = Types.Exact<{
  input: Types.TopAbrechnungGenerierlaufCreateInput;
}>;

export type CreateFirmendatenTopAbrechnungGenerierlaufMutation = {
  createFirmendatenTopAbrechnungGenerierlauf: {
    data?: string | null;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateFirmendatenHeOrObjektOrSubAbrechnungGenerierlaufDocument = gql`
  mutation CreateFirmendatenHeOrObjektOrSubAbrechnungGenerierlauf($input: HeOrObjektOrSubAbrechnungGenerierlaufCreateInput!) {
    createFirmendatenHeOrObjektOrSubAbrechnungGenerierlauf(input: $input) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateFirmendatenHeOrObjektOrSubAbrechnungGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFirmendatenHeOrObjektOrSubAbrechnungGenerierlaufMutation,
    CreateFirmendatenHeOrObjektOrSubAbrechnungGenerierlaufMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFirmendatenHeOrObjektOrSubAbrechnungGenerierlaufMutation,
    CreateFirmendatenHeOrObjektOrSubAbrechnungGenerierlaufMutationVariables
  >(CreateFirmendatenHeOrObjektOrSubAbrechnungGenerierlaufDocument, options);
}
export type CreateFirmendatenHeOrObjektOrSubAbrechnungGenerierlaufMutationHookResult = ReturnType<
  typeof useCreateFirmendatenHeOrObjektOrSubAbrechnungGenerierlaufMutation
>;
export type CreateFirmendatenHeOrObjektOrSubAbrechnungGenerierlaufMutationResult =
  Apollo.MutationResult<CreateFirmendatenHeOrObjektOrSubAbrechnungGenerierlaufMutation>;
export type CreateFirmendatenHeOrObjektOrSubAbrechnungGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  CreateFirmendatenHeOrObjektOrSubAbrechnungGenerierlaufMutation,
  CreateFirmendatenHeOrObjektOrSubAbrechnungGenerierlaufMutationVariables
>;
export const CreateFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlaufDocument = gql`
  mutation CreateFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlauf($input: HeOrObjektOrSubNamedAbrechnungGenerierlaufCreateInput!) {
    createFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlauf(input: $input) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlaufMutation,
    CreateFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlaufMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlaufMutation,
    CreateFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlaufMutationVariables
  >(CreateFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlaufDocument, options);
}
export type CreateFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlaufMutationHookResult = ReturnType<
  typeof useCreateFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlaufMutation
>;
export type CreateFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlaufMutationResult =
  Apollo.MutationResult<CreateFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlaufMutation>;
export type CreateFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  CreateFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlaufMutation,
  CreateFirmendatenHeOrObjektOrSubNamedAbrechnungGenerierlaufMutationVariables
>;
export const CreateFirmendatenTopAbrechnungGenerierlaufDocument = gql`
  mutation CreateFirmendatenTopAbrechnungGenerierlauf($input: TopAbrechnungGenerierlaufCreateInput!) {
    createFirmendatenTopAbrechnungGenerierlauf(input: $input) {
      data
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateFirmendatenTopAbrechnungGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFirmendatenTopAbrechnungGenerierlaufMutation,
    CreateFirmendatenTopAbrechnungGenerierlaufMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFirmendatenTopAbrechnungGenerierlaufMutation, CreateFirmendatenTopAbrechnungGenerierlaufMutationVariables>(
    CreateFirmendatenTopAbrechnungGenerierlaufDocument,
    options
  );
}
export type CreateFirmendatenTopAbrechnungGenerierlaufMutationHookResult = ReturnType<typeof useCreateFirmendatenTopAbrechnungGenerierlaufMutation>;
export type CreateFirmendatenTopAbrechnungGenerierlaufMutationResult = Apollo.MutationResult<CreateFirmendatenTopAbrechnungGenerierlaufMutation>;
export type CreateFirmendatenTopAbrechnungGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  CreateFirmendatenTopAbrechnungGenerierlaufMutation,
  CreateFirmendatenTopAbrechnungGenerierlaufMutationVariables
>;
