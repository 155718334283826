import React, { FC } from 'react';
import { Button, Dropdown, MenuProps, Modal } from 'antd';
import { DeleteOutlined, EllipsisOutlined } from '@ant-design/icons';
import { useDeleteEmailIntegrationMutation } from './gql/EmailIntegrationMutations.types';
import { showSuccessMsgDelete } from '../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../helpers/apolloHelper';

type Props = {
  onAction: () => Promise<unknown>;
};

const EmailIntegrationActions: FC<Props> = ({ onAction }) => {
  const entity = `E-Mail Anbindung`;

  const [runDelete, { loading }] = useDeleteEmailIntegrationMutation({
    onCompleted: () => {
      showSuccessMsgDelete(entity);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Löschen',
      onClick: () => showConfirmDelete(runDelete),
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }} disabled={loading}>
        <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
      </Dropdown>
    </>
  );
};

const showConfirmDelete = (runDelete: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie die E-Mail Anbindung aufheben?',
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

export default EmailIntegrationActions;
