import { useBuchungszeileListWithUmbuchungenQuery } from '../../../gql/BuchungQueries.types';

export const useBuchungszeileListWithOrWithoutUmbuchungen = (buchungIdList: string[], includeStorno?: boolean | null) => {
  const { loading, data, refetch } = useBuchungszeileListWithUmbuchungenQuery({
    variables: {
      buchungIdList,
      includeStorno: includeStorno ?? true,
    },
  });

  const buchungszeileList = data?.getBuchungszeileListWithUmbuchung.data || [];
  return { isBuchungszeileLoading: loading, buchungszeileList, refetchBuchungszeile: refetch };
};
