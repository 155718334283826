import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Box } from 'rebass';
import { Divider } from 'antd';
import { getPreviousTimeblockBefore, ITimeblock, useTimeline } from '../../../../../components/Timeline/timelineCtx';
import {
  mapFormValuesToObjektSummeVersion,
  mapTimeblockToFormValues,
  objektSummeAnteileVersionFormFields,
  ObjektSummeAnteileVersionFormValues,
  summeAnteileVersionFormValuesDescriptionsForKommentar,
} from './objektSummeAnteileVersionFormMapper';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { useCreateObjektSummeAnteileMutation, useUpdateObjektSummeAnteileMutation } from '../../gql/ObjektSummeAnteileMutations.types';
import { layoutTimelineForm } from '../../../../../components/Input-antd/formLayoutHelper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import TimelineFormDatePicker from '../../../../../components/Timeline/Form/TimelineFormDatePicker';
import TimelineFormKommentar from '../../../../../components/Timeline/Form/TimelineFormKommentar';
import TimelineFormButtons from '../../../../../components/Timeline/Form/TimelineFormButtons';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import { objektSummeAnteileFormValidationSchema } from './objektSummeAnteileFormValidationSchema';
import FormattedDecimalFormInput from '../../../../../components/Input-antd/FormattedDecimalFormInput';
import { gqlOperations } from '../../../../../graphql/gqlNamedOperations-generated';
import { ObjektSummeAnteile } from '../../../../../types';

type ObjektSummeAnteileVersionFormProps = {
  timeblock: ITimeblock<ObjektSummeAnteile>;
  objektId: string;
  onSubmitSuccess: () => void;
};

const ObjektSummeAnteileVersionForm: FC<ObjektSummeAnteileVersionFormProps> = ({ timeblock, objektId, onSubmitSuccess }) => {
  const { dataSource, toggleEdit, changeToUpdateMode } = useTimeline<ObjektSummeAnteile>();
  const initialValues = mapTimeblockToFormValues(timeblock);
  const isUpdate = !!timeblock.objektSummeAnteileId;

  const entity = 'Summe Anteile';

  const [runCreate, { loading: loadingCreate }] = useCreateObjektSummeAnteileMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      changeToUpdateMode(timeblock.uuid);
      onSubmitSuccess();
    },
    refetchQueries: [gqlOperations.Query.HeVertragList, gqlOperations.Query.ObjektSummeAnteile],
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateObjektSummeAnteileMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      toggleEdit(timeblock.uuid);
      onSubmitSuccess();
    },
    refetchQueries: [gqlOperations.Query.HeVertragList, gqlOperations.Query.ObjektSummeAnteile],
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<ObjektSummeAnteileVersionFormValues>
      initialValues={initialValues}
      validationSchema={objektSummeAnteileFormValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const input = mapFormValuesToObjektSummeVersion(values);
        if (isUpdate) {
          runUpdate({ variables: { objektId, summeAnteileId: timeblock.objektSummeAnteileId, input } }).finally(() => setSubmitting(false));
        } else {
          runCreate({ variables: { objektId, input } }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form {...layoutTimelineForm} layout="horizontal" labelAlign="left">
          <Box width={2 / 3}>
            <FormItemWithoutColon name={objektSummeAnteileVersionFormFields.validFrom} label="Gültig ab">
              <TimelineFormDatePicker<ObjektSummeAnteile>
                id={objektSummeAnteileVersionFormFields.validFrom}
                name={objektSummeAnteileVersionFormFields.validFrom}
                timeblock={timeblock}
              />
            </FormItemWithoutColon>

            <FormItemWithoutColon name={objektSummeAnteileVersionFormFields.summeAnteile} label="Summe Anteile" style={{ margin: 0 }}>
              <FormattedDecimalFormInput
                size="small"
                id={objektSummeAnteileVersionFormFields.summeAnteile}
                name={objektSummeAnteileVersionFormFields.summeAnteile}
                placeholder="z.B. 123"
                style={{ width: '100%' }}
              />
            </FormItemWithoutColon>
          </Box>

          <Divider style={{ padding: 0, marginTop: 0, marginBottom: '16px' }} />

          <TimelineFormKommentar
            name={objektSummeAnteileVersionFormFields.kommentar}
            timeblock={timeblock}
            valuesDescriptions={summeAnteileVersionFormValuesDescriptionsForKommentar}
          />

          <TimelineFormButtons<ObjektSummeAnteile>
            timeblock={timeblock}
            isSubmitting={formikProps.isSubmitting}
            isSameAsPreviousVersion={isSameAsPreviousVersion(
              formikProps.values,
              getPreviousTimeblockBefore(dataSource, dayjsCustom(formikProps.values.validFrom))
            )}
            submitBtnLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

const isSameAsPreviousVersion = (formValues: ObjektSummeAnteileVersionFormValues, previousTimeblock?: ITimeblock<ObjektSummeAnteile>) =>
  formValues.summeAnteile === previousTimeblock?.summeAnteile;

export default ObjektSummeAnteileVersionForm;
