import { Route } from 'react-router';
import { URI_VERARBEITUNG_BOOKING_SUGGESTION_VERBUCHEN_DETAILS_PAGE } from '../../../features/Verarbeitung/verarbeitungApiPaths';
import AuthRoute from '../../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import BookingSuggestionVerbuchenDetailsPage from './BookingSuggestionVerbuchenDetailsPage';

export type BookingSuggestionVerbuchenPageRouteParams = { generierlaufId: string };

export const verarbeitungBookingSuggestionVerbuchenDetailsPageRoute = (
  <Route
    path={`${URI_VERARBEITUNG_BOOKING_SUGGESTION_VERBUCHEN_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<BookingSuggestionVerbuchenDetailsPage />} />}
  />
);
