import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRepFondsEinstellungTemplateMutationVariables = Types.Exact<{
  input: Types.RepFondsEinstellungCreateInput;
}>;

export type CreateRepFondsEinstellungTemplateMutation = {
  createRepFondsEinstellungTemplate: {
    data: { validFrom: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateRepFondsEinstellungTemplateMutationVariables = Types.Exact<{
  repFondsEinstellungId: Types.Scalars['ID']['input'];
  input: Types.RepFondsEinstellungUpdateInput;
}>;

export type UpdateRepFondsEinstellungTemplateMutation = {
  updateRepFondsEinstellungTemplate: {
    data: { validFrom: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteRepFondsEinstellungTemplateMutationVariables = Types.Exact<{
  repFondsEinstellungId: Types.Scalars['ID']['input'];
}>;

export type DeleteRepFondsEinstellungTemplateMutation = {
  deleteRepFondsEinstellungTemplate: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateRepFondsEinstellungTemplateDocument = gql`
  mutation CreateRepFondsEinstellungTemplate($input: RepFondsEinstellungCreateInput!) {
    createRepFondsEinstellungTemplate(input: $input) {
      data {
        validFrom
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateRepFondsEinstellungTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRepFondsEinstellungTemplateMutation, CreateRepFondsEinstellungTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRepFondsEinstellungTemplateMutation, CreateRepFondsEinstellungTemplateMutationVariables>(
    CreateRepFondsEinstellungTemplateDocument,
    options
  );
}
export type CreateRepFondsEinstellungTemplateMutationHookResult = ReturnType<typeof useCreateRepFondsEinstellungTemplateMutation>;
export type CreateRepFondsEinstellungTemplateMutationResult = Apollo.MutationResult<CreateRepFondsEinstellungTemplateMutation>;
export type CreateRepFondsEinstellungTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateRepFondsEinstellungTemplateMutation,
  CreateRepFondsEinstellungTemplateMutationVariables
>;
export const UpdateRepFondsEinstellungTemplateDocument = gql`
  mutation UpdateRepFondsEinstellungTemplate($repFondsEinstellungId: ID!, $input: RepFondsEinstellungUpdateInput!) {
    updateRepFondsEinstellungTemplate(repFondsEinstellungId: $repFondsEinstellungId, input: $input) {
      data {
        validFrom
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateRepFondsEinstellungTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRepFondsEinstellungTemplateMutation, UpdateRepFondsEinstellungTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRepFondsEinstellungTemplateMutation, UpdateRepFondsEinstellungTemplateMutationVariables>(
    UpdateRepFondsEinstellungTemplateDocument,
    options
  );
}
export type UpdateRepFondsEinstellungTemplateMutationHookResult = ReturnType<typeof useUpdateRepFondsEinstellungTemplateMutation>;
export type UpdateRepFondsEinstellungTemplateMutationResult = Apollo.MutationResult<UpdateRepFondsEinstellungTemplateMutation>;
export type UpdateRepFondsEinstellungTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateRepFondsEinstellungTemplateMutation,
  UpdateRepFondsEinstellungTemplateMutationVariables
>;
export const DeleteRepFondsEinstellungTemplateDocument = gql`
  mutation DeleteRepFondsEinstellungTemplate($repFondsEinstellungId: ID!) {
    deleteRepFondsEinstellungTemplate(repFondsEinstellungId: $repFondsEinstellungId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteRepFondsEinstellungTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRepFondsEinstellungTemplateMutation, DeleteRepFondsEinstellungTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRepFondsEinstellungTemplateMutation, DeleteRepFondsEinstellungTemplateMutationVariables>(
    DeleteRepFondsEinstellungTemplateDocument,
    options
  );
}
export type DeleteRepFondsEinstellungTemplateMutationHookResult = ReturnType<typeof useDeleteRepFondsEinstellungTemplateMutation>;
export type DeleteRepFondsEinstellungTemplateMutationResult = Apollo.MutationResult<DeleteRepFondsEinstellungTemplateMutation>;
export type DeleteRepFondsEinstellungTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteRepFondsEinstellungTemplateMutation,
  DeleteRepFondsEinstellungTemplateMutationVariables
>;
