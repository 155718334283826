import React, { FC } from 'react';
import { TabsProps } from 'antd';
import RouteTabs from '../../../../components/Tabs/RouteTabs';
import { pathsToHeAbrechnungHeVertragDetails } from '../heAbrechnungUriPaths';
import HeAbrHeVertragDetailsListingTab from './Listing/HeAbrHeVertragDetailsListingTab';
import { Spacer } from '../../../../components/Grid';
import InfoAbrKreisTable from '../../shared/InfoAbrKreisTable/Level1/InfoAbrKreisTable';
import { HeAbrechnungHeVertrag } from '../../../../types';
import { useOnHeAbrechnungDataChangedEvents } from '../useOnHeAbrechnungDataChangeEvent';

type Props = {
  abrechnung: HeAbrechnungHeVertrag;
  heAbrechnungId: string;
  loading: boolean;
  onAction: () => Promise<any>;
};

const HeAbrHeVertragDetailsTabs: FC<Props> = ({ abrechnung, heAbrechnungId, loading, onAction }) => {
  const paths = pathsToHeAbrechnungHeVertragDetails(abrechnung.objekt.objektId, heAbrechnungId, abrechnung.heAbrechnungHeVertragId);

  useOnHeAbrechnungDataChangedEvents('heAbrHeVertragDetailsTabs', heAbrechnungId, onAction);

  const items: TabsProps['items'] = [
    {
      key: paths.heAbr,
      label: 'Hauseigentümerabrechnung',
      children: (
        <>
          <HeAbrHeVertragDetailsListingTab abrechnung={abrechnung} loading={loading} />
          {abrechnung.infoAbrechnungskreisList.length > 0 && (
            <>
              <Spacer height={16} />
              <InfoAbrKreisTable abrechnungskreisList={abrechnung.infoAbrechnungskreisList} />
            </>
          )}
        </>
      ),
    },
  ];
  return <RouteTabs items={items} />;
};

export default HeAbrHeVertragDetailsTabs;
