import { useArchiveFirmendatenBankDetailsMutation, useArchiveRechtstraegerBankDetailsMutation } from '../gql/BankDetailsMutations.types';
import { showSuccessMsgArchived } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';

export const useArchiveBankDetails = (
  bankDetailsId: string,
  onActionSuccess: () => Record<string, unknown>,
  firmendatenId?: string,
  rechtstraegerId?: string
) => {
  const [runArchiveFirmendatenBankDetails] = useArchiveFirmendatenBankDetailsMutation({
    onCompleted: () => {
      showSuccessMsgArchived('Bankverbindung');
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runArchiveRechtstraegerBankDetails] = useArchiveRechtstraegerBankDetailsMutation({
    onCompleted: () => {
      showSuccessMsgArchived('Bankverbindung');
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return () => {
    if (rechtstraegerId) {
      runArchiveRechtstraegerBankDetails({
        variables: {
          rechtstraegerId,
          bankDetailsId,
        },
      });
    } else if (firmendatenId) {
      runArchiveFirmendatenBankDetails({
        variables: {
          firmendatenId,
          bankDetailsId,
        },
      });
    }
  };
};
