import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
export type SubAbrAbrechnungskreisListFieldsFragment = {
  summe: number;
  abrechnungskreisList: Array<{
    abrechnungskreisId: string;
    bezeichnung: string;
    subAbrechnungsdefinitionId: string;
    subAbrechnungsdefinitionVersionId: string;
    summe: number;
    abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
    kontoList: Array<{
      bezeichnung: string;
      kontoId: string;
      summe: number;
      amountReducingType?: { text: string; value: Types.AmountReducingType } | null;
      buchungszeileList: Array<{
        auftragId?: string | null;
        belegDatum?: string | null;
        belegFileId?: string | null;
        belegId: string;
        belegnummer?: string | null;
        betrag: number;
        buchungIdList: Array<string>;
        dueDate?: string | null;
        standingInvoicePartialAmountId?: string | null;
        text: string;
        umbuchung?: boolean | null;
        umgebucht?: boolean | null;
        ustVomAufwandSteuersatz?: number | null;
        buchungskreisRechtstraeger: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        sollHaben: { text: string; value: Types.SollHaben };
        vertragspartner?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
      }>;
    }>;
  }>;
  abrechnungskreisType: { text: string; value: Types.AbrechnungskreisType };
};

export type SubAbrKontoFieldsFragment = {
  bezeichnung: string;
  kontoId: string;
  summe: number;
  amountReducingType?: { text: string; value: Types.AmountReducingType } | null;
  buchungszeileList: Array<{
    auftragId?: string | null;
    belegDatum?: string | null;
    belegFileId?: string | null;
    belegId: string;
    belegnummer?: string | null;
    betrag: number;
    buchungIdList: Array<string>;
    dueDate?: string | null;
    standingInvoicePartialAmountId?: string | null;
    text: string;
    umbuchung?: boolean | null;
    umgebucht?: boolean | null;
    ustVomAufwandSteuersatz?: number | null;
    buchungskreisRechtstraeger: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    sollHaben: { text: string; value: Types.SollHaben };
    vertragspartner?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
  }>;
};

export const SubAbrKontoFieldsFragmentDoc = gql`
  fragment SubAbrKontoFields on SubAbrKonto {
    amountReducingType {
      text
      value
    }
    bezeichnung
    buchungszeileList {
      auftragId
      belegDatum
      belegFileId
      belegId
      belegnummer
      betrag
      buchungIdList
      buchungskreisRechtstraeger {
        ...RechtstraegerBaseFields
      }
      dueDate
      sollHaben {
        text
        value
      }
      standingInvoicePartialAmountId
      text
      umbuchung
      umgebucht
      ustVomAufwandSteuersatz
      vertragspartner {
        ...RechtstraegerBaseFields
      }
    }
    kontoId
    summe
  }
  ${RechtstraegerBaseFieldsFragmentDoc}
`;
export const SubAbrAbrechnungskreisListFieldsFragmentDoc = gql`
  fragment SubAbrAbrechnungskreisListFields on SubAbrAbrechnungskreisList {
    abrechnungskreisList {
      abrechnungskreisId
      abrechnungskreisType {
        text
        value
      }
      bezeichnung
      kontoList {
        ...SubAbrKontoFields
      }
      subAbrechnungsdefinitionId
      subAbrechnungsdefinitionVersionId
      summe
    }
    abrechnungskreisType {
      text
      value
    }
    summe
  }
  ${SubAbrKontoFieldsFragmentDoc}
`;
