import { FormFields } from '../../../../../../helpers/formikHelper';
import { FormFileReference } from '../../../../../../components/FileUpload/formHelpers';
import { DocumentHeaderAndFooterImageAlignment, ReportLogos } from '../../../../../../types';

export type ReportLogosFormValues = {
  logoGross?: FormFileReference;
  logoGrossAlignment?: DocumentHeaderAndFooterImageAlignment | null;
  logoKlein?: FormFileReference;
  logoKleinAlignment?: DocumentHeaderAndFooterImageAlignment | null;
  logoGrossFileId?: string | null;
  logoKleinFileId?: string | null;
  tempLogoGrossFileId?: string;
  tempLogoKleinFileId?: string;
};

export const reportLogosFormFields: FormFields<ReportLogosFormValues> = {
  logoGross: 'logoGross',
  logoGrossAlignment: 'logoGrossAlignment',
  logoKlein: 'logoKlein',
  logoKleinAlignment: 'logoKleinAlignment',
  logoGrossFileId: 'logoGrossFileId',
  logoKleinFileId: 'logoKleinFileId',
  tempLogoGrossFileId: 'tempLogoGrossFileId',
  tempLogoKleinFileId: 'tempLogoKleinFileId',
};

export const mapReportLogosToFormValues = (reportLogos?: ReportLogos): ReportLogosFormValues => ({
  logoGrossFileId: reportLogos?.logoGrossFileId,
  logoGrossAlignment: reportLogos?.logoGrossAlignment?.value,
  logoKleinFileId: reportLogos?.logoKleinFileId,
  logoKleinAlignment: reportLogos?.logoKleinAlignment?.value,
});
