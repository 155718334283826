import React, { FC } from 'react';
import { Space } from 'antd';
import CardTitle from '../../../../components/Card/CardTitle';
import ObjektPostIt from '../../PostIt/ObjektPostIt';
import { Objekt, ObjektStatus } from '../../../../types';
import { isObjektAbgegeben } from '../../objektHelper';

type Props = {
  objekt: Objekt;
  onSuccess: () => void;
};

const InnerCardTitle: FC<Props> = ({ objekt, onSuccess }) => {
  const isObjektArchived = objekt.status.value === ObjektStatus.Archiviert;
  const abgegebenText = isObjektAbgegeben(objekt.verwaltungBis) ? '(abgegeben)' : undefined;

  return (
    <Space style={{ width: '100%', justifyContent: 'space-between' }}>
      {isObjektArchived ? (
        <CardTitle title={objekt.kurzBezeichnung} status={objekt.status} extraInfo={abgegebenText} warningList={objekt.warningList} /> //no maxLength as objekt.kurzBezeichnung can only have 25 characters
      ) : (
        <CardTitle title={objekt.kurzBezeichnung} extraInfo={abgegebenText} warningList={objekt.warningList} />
      )}
      <ObjektPostIt objektId={objekt.objektId} postIt={objekt.postIt} refresh={onSuccess} />
    </Space>
  );
};

export default InnerCardTitle;
