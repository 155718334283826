import { Kontierungstabelle, KontierungstabelleCreateInput, KontierungstabelleUpdateInput } from '../../../types';
import { FormFields } from '../../../helpers/formikHelper';

export type KontierungstabelleFormValues = {
  bezeichnung: string;
  basiskonto: number;
  firmendatenId: string;
  subAdministrationExpenseAccountId?: string | null;
};

const kontierungstabelleFormInitialValues: KontierungstabelleFormValues = {
  bezeichnung: '',
  basiskonto: 0,
  firmendatenId: '',
};

export const kontierungstabelleFormFields: FormFields<KontierungstabelleFormValues> = {
  bezeichnung: 'bezeichnung',
  basiskonto: 'basiskonto',
  firmendatenId: 'firmendatenId',
  subAdministrationExpenseAccountId: 'subAdministrationExpenseAccountId',
};

export const mapKontierungstabelleToFormValues = (kontierungstabelle?: Kontierungstabelle): KontierungstabelleFormValues => {
  if (!kontierungstabelle) {
    return kontierungstabelleFormInitialValues;
  }
  return {
    bezeichnung: kontierungstabelle.bezeichnung,
    basiskonto: kontierungstabelle.basiskonto,
    firmendatenId: kontierungstabelle.firmendatenId ?? '',
    subAdministrationExpenseAccountId: kontierungstabelle.subAdministrationExpenseAccount?.kontoId,
  };
};

export const mapFormValuesToKontierungstabelle = (
  formValues: KontierungstabelleFormValues
): KontierungstabelleCreateInput | KontierungstabelleUpdateInput => ({
  bezeichnung: formValues.bezeichnung,
  basiskonto: formValues.basiskonto,
  subAdministrationExpenseAccountId: formValues.subAdministrationExpenseAccountId,
});
