import React from 'react';
import { v4 as uuid4 } from 'uuid';

export type TextWithStyling = {
  render: string | React.ReactNode;
  style?: React.CSSProperties;
};

export const createRowWithCells = (texts: TextWithStyling[], useHeaderCell: boolean) => {
  return <tr key={uuid4()}>{createCells(texts, useHeaderCell)}</tr>;
};

export const createCells = (texts: TextWithStyling[], useHeaderCell: boolean) => {
  return (
    <>
      {texts.map((data, index) => {
        return useHeaderCell ? (
          <th key={index} className={!data.render ? 'ant-table-row-expand-icon-cell' : ''} style={data.style}>
            {data.render}
          </th>
        ) : (
          <td key={index} style={data.style}>
            {data.render}
          </td>
        );
      })}
    </>
  );
};
