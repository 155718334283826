import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../../../styles/pdfStyles';
import { columnWidthsAndAlignment } from './aufwandAufteilung-table-styles';
import { RepFondsAufwandAufteilungSummary } from '../../../../topAbrechnung-types';

const SummaryRow: FC<{ summaryData: RepFondsAufwandAufteilungSummary }> = ({ summaryData }) => (
  <View style={pdfStyles.row}>
    <Text
      style={[
        pdfStyles.textBulletin,
        {
          width: columnWidthsAndAlignment.basis.width,
          textAlign: columnWidthsAndAlignment.basis.textAlign,
        },
      ]}
    >
      {summaryData.basis}
    </Text>
    <Text
      style={[
        pdfStyles.textBulletin,
        {
          width: columnWidthsAndAlignment.anteilNetto.width,
          textAlign: columnWidthsAndAlignment.anteilNetto.textAlign,
        },
      ]}
    >
      {summaryData.anteilNetto}
    </Text>
    <Text style={[pdfStyles.textBulletin, { width: columnWidthsAndAlignment.ustProzent.width }]} />
    <Text
      style={[
        pdfStyles.textBulletin,
        {
          width: columnWidthsAndAlignment.ust.width,
          textAlign: columnWidthsAndAlignment.ust.textAlign,
        },
      ]}
    >
      {summaryData.ust}
    </Text>
    <Text
      style={[
        pdfStyles.textBulletin,
        {
          width: columnWidthsAndAlignment.summe.width,
          textAlign: columnWidthsAndAlignment.summe.textAlign,
        },
      ]}
    >
      {summaryData.summe}
    </Text>
    <Text style={[pdfStyles.textBulletin, { width: columnWidthsAndAlignment.grundlage.width }]} />
    <Text
      style={[
        pdfStyles.textBulletin,
        {
          width: columnWidthsAndAlignment.belastung.width,
          textAlign: columnWidthsAndAlignment.belastung.textAlign,
        },
      ]}
    >
      {summaryData.belastung}
    </Text>
  </View>
);

export default SummaryRow;
