import React, { FC } from 'react';
import { FormikProps } from 'formik';
import FiltersWith4OrMoreFields from '../../../../../components/Filters/FiltersWith4OrMoreFields';
import FiltersSelect from '../../../../../components/Filters/FiltersSelect';
import { onFilterSelectChange } from '../../../../../components/Filters/FiltersHelpers';
import { filtersFormFields, FiltersFormValues, listingLabelList } from './filtersFormMapper';
import { TDirectDebitOrBankWireQueryParams } from './filtersQueryParams';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import IbanListFormSelect from './IbanListFormSelect';
import PainNameListFormSelect from './PainNameListFormSelect';
import PainPaymentProposalNameListFormSelect from './PainPaymentProposalNameListFormSelect';
import FromToFormDatePicker from '../../../../shared/FromToForm/FromToFormDatePicker';
import MitarbeiterSelect from '../../../../../shared/components/MitarbeiterSelect/MitarbeiterSelect';
import DataCarrierTypeFormSelect from './DataCarrierTypeFormSelect';
import PaymentTransactionTypeFormSelect from '../../../../Payment/shared/PaymentTransactionTypeFormSelect';
import useFilterWithQueryParams from '../../../../shared/useFilterWithQueryParams';
import PainStatusListFormSelect from './PainStatusListFormSelect';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
  queryParams: TDirectDebitOrBankWireQueryParams;
};

const ListingFilters: FC<Props> = ({ formikProps, queryParams }) => {
  const defaultSelectedFilterList = [filtersFormFields.paymentProposalName, filtersFormFields.name];

  const mapFilterLabelToQueryFilterList = (filter: string) => {
    if (filter === filtersFormFields.createTsFrom || filter === filtersFormFields.createTsTo) {
      return filtersFormFields.createTsFrom;
    }
    return filter;
  };

  const filters = (filter: string) => {
    if (filter === filtersFormFields.iban) {
      return (
        <FormItemWithoutColon name={filtersFormFields.iban} label={listingLabelList.iban} key={filter}>
          <IbanListFormSelect name={filtersFormFields.iban} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.name) {
      return (
        <FormItemWithoutColon name={filtersFormFields.name} label={listingLabelList.name} key={filter}>
          <PainNameListFormSelect name={filtersFormFields.name} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.paymentProposalName) {
      return (
        <FormItemWithoutColon name={filtersFormFields.paymentProposalName} label={listingLabelList.paymentProposalName} key={filter}>
          <PainPaymentProposalNameListFormSelect name={filtersFormFields.paymentProposalName} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.createTsFrom) {
      return (
        <FormItemWithoutColon name="erstelltAm" label={listingLabelList.createTsFrom} key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={filtersFormFields.createTsFrom}
            toName={filtersFormFields.createTsTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.createdByMitarbeiterIdList) {
      return (
        <FormItemWithoutColon name={filtersFormFields.createdByMitarbeiterIdList} label={listingLabelList.createdByMitarbeiterIdList} key={filter}>
          <MitarbeiterSelect name={filtersFormFields.createdByMitarbeiterIdList} onChange={formikProps.submitForm} mode="multiple" />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.dataCarrierType) {
      return (
        <FormItemWithoutColon name={filtersFormFields.dataCarrierType} label={listingLabelList.dataCarrierType} key={filter}>
          <DataCarrierTypeFormSelect name={filtersFormFields.dataCarrierType} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.paymentTransactionType) {
      return (
        <FormItemWithoutColon name={filtersFormFields.paymentTransactionType} label={listingLabelList.paymentTransactionType} key={filter}>
          <PaymentTransactionTypeFormSelect name={filtersFormFields.paymentTransactionType} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.status) {
      return (
        <FormItemWithoutColon name={filtersFormFields.status} label={listingLabelList.status} key={filter}>
          <PainStatusListFormSelect name={filtersFormFields.status} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    return undefined;
  };

  const updateFilterValues = (selectedFilterList: string[]) => {
    if (!selectedFilterList.includes(filtersFormFields.createTsFrom) && formikProps.values.createTsTo) {
      formikProps.setFieldValue(filtersFormFields.createTsTo, undefined);
    }
    formikProps.submitForm();
  };

  const { selectedFilterList, setSelectedFilterList, leftColumn, rightColumn } = useFilterWithQueryParams({
    defaultSelectedFilterList,
    updateFilterValues,
    filters,
    labelList: listingLabelList,
    mapFilterLabelToQueryFilterList,
    queryParams,
  });

  return (
    <FiltersWith4OrMoreFields
      filtersSelect={
        <FiltersSelect
          defaultSelectedFilterList={defaultSelectedFilterList}
          selectedFilterList={selectedFilterList}
          onChange={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
          labelList={listingLabelList}
        />
      }
      hideTitle
      leftColumn={<>{leftColumn}</>}
      rightColumn={<>{rightColumn}</>}
    />
  );
};

export default ListingFilters;
