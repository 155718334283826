import { SelectProps } from 'formik-antd';
import React, { FC } from 'react';
import { EntityStatusFormSelect } from '../../components/Status/EntityStatus';
import { AbrechnungsdefinitionType } from '../../types';
import { useAbrechnungsdefinitionTemplateListQuery } from '../AbrechnungsdefinitionTemplate/gql/AbrDefTemplateQueries.types';

type Props = {
  onAbrDefSelect: (type: AbrechnungsdefinitionType) => void;
  excludeTypes?: AbrechnungsdefinitionType[];
} & SelectProps;

const AbrechnungsdefinitionTemplateSelect: FC<Props> = ({ onAbrDefSelect, excludeTypes, ...restProps }) => {
  const { loading, data } = useAbrechnungsdefinitionTemplateListQuery();
  const abrechnungsdefinitionList = data?.getAbrechnungsdefinitionTemplateList.data || [];

  const filteredList = abrechnungsdefinitionList.filter((abrDef) => (excludeTypes ? !excludeTypes.includes(abrDef.type.value) : true));

  return (
    <EntityStatusFormSelect
      list={filteredList}
      getSelectItemId={(item) => item.abrechnungsdefinitionId}
      getSelectItemContent={(item) => item.bezeichnung}
      getSelectItemTooltipTitle={(item) => item.bezeichnung}
      loading={loading || !filteredList}
      placeholder="Abrechnungsdefinition auswählen"
      onChange={(value: string) => {
        if (filteredList.length > 0) {
          const selectedAbrDef = filteredList.find((abrDef) => value === abrDef.abrechnungsdefinitionId);
          selectedAbrDef && onAbrDefSelect(selectedAbrDef.type.value);
        }
      }}
      {...restProps}
    />
  );
};

export default AbrechnungsdefinitionTemplateSelect;
