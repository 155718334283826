import { FormFileReference } from '../../../../components/FileUpload/formHelpers';
import { FormFields } from '../../../../helpers/formikHelper';

export type FiltersFormValues = {
  incomingInvoice?: FormFileReference | null;
};

export const filterFormFields: FormFields<FiltersFormValues> = {
  incomingInvoice: 'incomingInvoice',
};
