import { Dayjs } from 'dayjs';
import { FormFields, mapFormDateValueToDateString } from '../../../../helpers/formikHelper';
import { VorsteuerkuerzungGenerierlaufInput } from '../../../../types';
import { firstDayOfCurrentYear, lastDayOfCurrentYear } from '../../../../helpers/dateHelper';

export type VstKuerzungCreateFormValues = {
  vonBis: Dayjs[];
};

export const vstKuerzungCreateFormInitialValues: VstKuerzungCreateFormValues = {
  vonBis: [firstDayOfCurrentYear(), lastDayOfCurrentYear()],
};

export const mapFormValuesToVstKuerzung = (objektId: string, values: VstKuerzungCreateFormValues): VorsteuerkuerzungGenerierlaufInput => ({
  objektId,
  vonInklusive: mapFormDateValueToDateString(values.vonBis[0].startOf('month')),
  bisInklusive: mapFormDateValueToDateString(values.vonBis[1].endOf('month')),
});

export const vstKuerzungCreateFormFields: FormFields<VstKuerzungCreateFormValues> = {
  vonBis: 'vonBis',
};
