import React, { FC } from 'react';
import { Collapse, Empty } from 'antd';
import { useSteuertabelleQuery } from '../../gql/AuftragQueries.types';
import { EuroAmount } from '../../../../components/Number';
import { StyledSmallTable } from '../../styled/Table.style';
import steuertabelleColumns from './steuertabelleColumns';
import { Steuerzeile } from '../../../../types';

type SteuertabelleProps = {
  auftragId: string;
};

const Steuertabelle: FC<SteuertabelleProps> = ({ auftragId }) => {
  const { loading, data } = useSteuertabelleQuery({
    variables: {
      auftragId,
    },
  });
  const steuertabelle = data?.getSteuertabelle.data;

  const SteuertabelleBodyWrapper = (
    props: { children: React.ReactNode } & JSX.IntrinsicAttributes &
      React.ClassAttributes<HTMLTableSectionElement> &
      React.HTMLAttributes<HTMLTableSectionElement>
  ) => (
    <tbody {...props}>
      <>
        {props.children}
        {renderSummary()}
      </>
    </tbody>
  );

  const renderSummary = () => (
    <tr className="ant-table-row">
      <td />
      <td style={{ textAlign: 'right' }}>{steuertabelle && <EuroAmount value={steuertabelle && steuertabelle.sumNetto} />}</td>
      <td style={{ textAlign: 'right' }}>{steuertabelle && <EuroAmount value={steuertabelle && steuertabelle.sumUst} />}</td>
      <td style={{ textAlign: 'right' }}>{steuertabelle && <EuroAmount value={steuertabelle && steuertabelle.sumBrutto} />}</td>
    </tr>
  );

  return (
    <Collapse bordered={false} defaultActiveKey={['1']}>
      <Collapse.Panel showArrow={false} header="Steuertabelle" key="1">
        <StyledSmallTable<Steuerzeile>
          pagination={false}
          loading={loading}
          bordered={false}
          locale={{
            emptyText: <Empty description="Keine Daten vorhanden" />,
          }}
          components={{
            body: {
              wrapper: SteuertabelleBodyWrapper,
            },
          }}
          size="small"
          dataSource={steuertabelle && steuertabelle.steuerzeileList}
          columns={steuertabelleColumns}
          rowKey={(record: any) => record.steuersatz.toString()}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export default Steuertabelle;
