import React, { FC } from 'react';
import { Formik } from 'formik';
import { Button, Typography } from 'antd';
import { Form, Input } from 'formik-antd';
import { adminUpdateFormValidationSchema } from '../../adminFormValidationSchema';
import { AdminUpdateFormValues, mapAdminToUpdateFormValues } from './adminUpdateFormMapper';
import { useUpdateAdminMutation } from '../../gql/AdminMutations.types';
import { showSuccessMsgCreate } from '../../../../components/message';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import ContactListFormPart from '../../../../shared/components/Contact/ContactListFormPart';
import { User } from '../../../../types';

type AdminUpdateFormProps = {
  admin: User;
  onSuccess: () => void;
  onCancel: () => void;
};

// FIXME: for update EmailArr and PhoneArr doesn't work

const AdminUpdateForm: FC<AdminUpdateFormProps> = ({ admin, onSuccess, onCancel }) => {
  const initalValues = mapAdminToUpdateFormValues(admin);
  const entity = 'Admin';

  const [runUpdate, { loading: loadingUpdate }] = useUpdateAdminMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onCancel();
      onSuccess();
    },
  });

  return (
    <Formik<AdminUpdateFormValues>
      initialValues={initalValues}
      validateOnChange={false}
      validationSchema={adminUpdateFormValidationSchema}
      onSubmit={(values, formikHelpers) => {
        runUpdate({
          variables: {
            username: admin.username,
            request: values,
          },
        }).finally(() => formikHelpers.setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form>
          <FormItemWithoutColon name="firstname" label="Vorname">
            <Input id="firstname" name="firstname" placeholder="Vorname" />
          </FormItemWithoutColon>
          <FormItemWithoutColon name="lastname" label="Nachname">
            <Input id="lastname" name="lastname" placeholder="Nachname" />
          </FormItemWithoutColon>
          <Typography.Title level={5}>Kontakt</Typography.Title>
          <ContactListFormPart />
          <Button block type="primary" htmlType="submit" disabled={formikProps.isSubmitting} loading={loadingUpdate}>
            Person aktualisieren
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default AdminUpdateForm;
