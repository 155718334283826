import React, { FC } from 'react';
import { Button, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../../hooks/useToggle';
import MitarbeiterCreateForm from '../Form/Create/MitarbeiterCreateForm';

type MitarbeiterActionsProps = {
  kundenSystemId: string;
  firmendatenId: string;
  refetch: () => void;
};

const MitarbeiterActions: FC<MitarbeiterActionsProps> = ({ kundenSystemId, firmendatenId, refetch }) => {
  const [isCollapsed, onCollapse] = useToggle();

  return (
    <>
      <Button icon={<PlusOutlined />} size="small" type="primary" onClick={onCollapse}>
        Mitarbeiter anlegen
      </Button>
      <Modal title="Mitarbeiter anlegen" open={isCollapsed} onCancel={onCollapse} footer={null} destroyOnClose maskClosable={false}>
        <MitarbeiterCreateForm
          kundenSystemId={kundenSystemId}
          firmendatenId={firmendatenId}
          onCancel={onCollapse}
          onSuccess={() => {
            onCollapse();
            refetch();
          }}
        />
      </Modal>
    </>
  );
};

export default MitarbeiterActions;
