import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { Abrechnungskreis } from '../../../../objektAbrechnung-types';
import OAAbrKreisRepFondsSaldovortrag from './OAAbrKreisRepFondsSaldovortrag';
import OAAbrKreisRepFondsEinnahmen from './OAAbrKreisRepFondsEinnahmen';
import OAAbrKreisKontoList from '../shared/OAAbrKreisKontoList';
import OAAbrKreisSummeAusgaben from '../shared/OAAbrKreisSummeAusgaben';
import OAAbrKreisSummeErgebnis from '../shared/OAAbrKreisSummeErgebnis';
import OAAbrKreisRepFondsUstVomAufwandList from './OAAbrKreisRepFondsUstVomAufwandList';
import OAAbrKreisAusgabenDetailsTitle from './OAAbrKreisAusgabenDetailsTitle';

const OAAbrKreisRepFonds: FC<{ abrechKreis: Abrechnungskreis }> = ({ abrechKreis }) => (
  <View>
    {/* Saldovortrag */}
    <OAAbrKreisRepFondsSaldovortrag abrechKreis={abrechKreis} />

    {/* Einnahmen - Summe und Details */}
    <OAAbrKreisRepFondsEinnahmen abrechKreis={abrechKreis} />

    {/* Summe Ausgaben */}
    <OAAbrKreisSummeAusgaben abrechKreis={abrechKreis} isRepFonds />

    {/* Ust. vom Aufwand block */}
    {abrechKreis.buchungUstVomAufwandList ? (
      <OAAbrKreisRepFondsUstVomAufwandList buchungUstVomAufwandList={abrechKreis.buchungUstVomAufwandList} />
    ) : null}

    {/* Summe Ergebnis */}
    <OAAbrKreisSummeErgebnis abrechKreis={abrechKreis} isRepFonds />

    {/* Ausgaben detailliert: */}
    <OAAbrKreisAusgabenDetailsTitle titleText={abrechKreis.ausgabenDetailsTitleText} />

    {/* Ausgaben-Konten-Auflistung */}
    <OAAbrKreisKontoList abrechKreis={abrechKreis} />
  </View>
);

export default OAAbrKreisRepFonds;
