import React, { FC } from 'react';
import { Skeleton, Typography } from 'antd';
import { useMahnungObjektListQuery } from '../../gql/MahnungQueries.types';
import MultipleTexts from '../../../../components/Text/MultipleTexts';
import { FibuObjekt } from '../../../../types';

type Props = {
  mahnungId: string;
};

const MahnungTableColumnsObjekt: FC<Props> = ({ mahnungId }) => {
  const { data, loading } = useMahnungObjektListQuery({
    variables: {
      mahnungId,
    },
  });
  const mahnungObjektList = data?.getMahnungObjektList.data ?? [];

  return mahnungObjektList.length > 0 ? (
    <Skeleton paragraph={false} active loading={loading}>
      <MultipleTexts texts={getObjektTexts(mahnungObjektList)} maxFirstTextLength={20} />
    </Skeleton>
  ) : (
    <Typography.Text>-</Typography.Text>
  );
};

const getObjektTexts = (mahnungObjektList: FibuObjekt[]) => mahnungObjektList.map((objekt) => objekt.kurzBezeichnung);

export default MahnungTableColumnsObjekt;
