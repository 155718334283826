import React, { FC } from 'react';
import { Table } from 'antd';
import { useUserChangeLogListForAdminQuery } from '../gql/AdminQueries.types';
import adminChangeLogTableColumns from './adminChangeLogTableColumns';
import { ChangeLog } from '../../../types';

type AdminChangeLogTableProps = {
  username: string;
};
const AdminChangeLogTable: FC<AdminChangeLogTableProps> = ({ username }) => {
  const { loading, data: maybeData } = useUserChangeLogListForAdminQuery({ variables: { username } });

  const changeLogData = maybeData?.getUserChangeLog.data;
  const contentList = changeLogData?.contentList ?? [];

  return (
    <Table<ChangeLog>
      size="small"
      locale={{
        emptyText: 'Keine Daten',
      }}
      loading={loading}
      columns={adminChangeLogTableColumns}
      dataSource={contentList}
      pagination={false}
      rowKey={(record) => `${username}-${record.changedTs}`}
    />
  );
};

export default AdminChangeLogTable;
