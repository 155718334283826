import React from 'react';
import { Route } from 'react-router';
import { generatePath } from 'react-router-dom';
import { isAdmin } from '../../../../security/permissionChecks';
import AndromedaSysSettingsKontenrahmenPage from './AndromedaSysSettingsKontenrahmenPage';
import AuthRoute from '../../../../security/AuthRoute';
import { KontoType } from '../../../../types';
import { mapToQueryString } from '../../../../hooks/useStringQueryParams';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';
import { KontenplanQueryParams, TKontenplanQueryParams } from '../../../SysSettingsPage/KundenSystem/KontenplanPage/routes';

const andromedaSysSettingsKontenrahmenPageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS.kontenRahmenPage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsKontenrahmenPage />} />}
  />
);

export const updateKontenrahmenQueryParams = (navigate: (path: string) => void, filters: TKontenplanQueryParams) =>
  navigate(generatePathToKontenrahmenPage(filters.klasse, filters.type, filters.verwendung));

export const generatePathToKontenrahmenPage = (kontoklasse?: string, kontotype?: KontoType[], kontoverwendung?: string) =>
  `${generatePath(URI_ANDROMEDA_SYS_SETTINGS.kontenRahmenPage)}?${mapToQueryString({
    [KontenplanQueryParams.KLASSE]: kontoklasse,
    [KontenplanQueryParams.TYPE]: kontotype,
    [KontenplanQueryParams.VERWENDUNG]: kontoverwendung,
  })}`;

export default andromedaSysSettingsKontenrahmenPageRoute;
