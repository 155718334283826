import React from 'react';
import { CopyOutlined, HomeOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../components/ContextSidebar/ContentWithSidebar';
import DocumentTab from '../../features/Document/DocumentTab';
import { generatePathToWeAbrechnungDetailsTopabrechnungDetailsPage } from '../../features/Abrechnung/BkOrWe/weAbrechnungUriPaths';
import TopAbrechnungDetailsTab from '../../features/Abrechnung/BkOrWe/Top/Details/TopAbrechnungDetailsTab';
import { TopAbrechnung } from '../../types';

const menuListTopAbr = (topAbrechnung: TopAbrechnung, objektId: string, objektAbrechnungId: string): GroupedMenueListInterface => ({
  topMenuList: [
    {
      title: 'Abrechnungskreise',
      icon: <HomeOutlined />,
      uri: `${generatePathToWeAbrechnungDetailsTopabrechnungDetailsPage(
        objektId,
        objektAbrechnungId,
        topAbrechnung.topAbrechnungId
      )}/abrechnungskreise`,
      content: (_) => <TopAbrechnungDetailsTab topAbrechnung={topAbrechnung} objektId={objektId} objektAbrechnungId={objektAbrechnungId} />,
    },
    {
      title: 'Dokumente der Topabrechnung',
      icon: <CopyOutlined />,
      uri: `${generatePathToWeAbrechnungDetailsTopabrechnungDetailsPage(objektId, objektAbrechnungId, topAbrechnung.topAbrechnungId)}/documents`,
      content: () => <DocumentTab objektAbrechnungId={objektAbrechnungId} topAbrechnungId={topAbrechnung.topAbrechnungId} hideFilters />,
    },
  ],
});

export default menuListTopAbr;
