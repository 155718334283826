import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../styles/pdfStyles';
import { RueckstandBlock } from '../topAbrechnung-types';
import LabelValue from '../../shared/LabelValue';

const TARueckstandBlock: FC<{ data: RueckstandBlock }> = ({ data }) => (
  <View style={[pdfStyles.column, { marginTop: '10mm', paddingRight: '3mm' }]}>
    <LabelValue valueTuple={data.rueckstand} labelStyle={{ width: '70%' }} valueStyle={{ width: '30%', textAlign: 'right' }} />
    <LabelValue
      valueTuple={data.rueckstandGesamtErgebnis}
      labelStyle={{ width: '70%', fontSize: '12px', fontWeight: 'bold' }}
      valueStyle={{ width: '30%', textAlign: 'right', fontSize: '12px', fontWeight: 'bold' }}
    />
    <LabelValue valueTuple={data.rueckstandGesamtErgebnis} />
  </View>
);

export default TARueckstandBlock;
