import React from 'react';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { TicketBase } from '../../../../types';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import RechtstraegerWithContactsTooltip from '../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

const ticketTableRechtstraegerColumn = (isSelected = false): TableWithColSelectorColumnProps<TicketBase> => ({
  title: 'Firma/Person',
  defaultSelected: isSelected,
  render: (text, record) =>
    record.rechtstraeger ? (
      <RechtstraegerWithContactsTooltip
        rechtstraegerId={record.rechtstraeger.rechtstraegerId}
        rechtstraegerName={record.rechtstraeger.kurzBezeichnung}
        maxTextLength={25}
        status={record.rechtstraeger.status}
      />
    ) : (
      <TextForEmptyValue textToShow="minus" />
    ),
});

export default ticketTableRechtstraegerColumn;
