import React, { FC } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../../hooks/useToggle';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';
import OperatingSiteModal from './OperatingSiteModal';
import DeletionHistoryDropdown from './DeletionHistory/DeletionHistoryDropdown';

type Props = {
  firmendatenId?: string;
  firmaId?: string;
  onSuccess: () => void;
};

const OperatingSiteBtnAndModal: FC<Props> = ({ firmendatenId, firmaId, onSuccess }) => {
  const [isDrawerOpen, toggleDrawerOpen] = useToggle();

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <DeletionHistoryDropdown firmendatenId={firmendatenId} firmaId={firmaId} />
        <Button icon={<PlusOutlined />} type="primary" onClick={toggleDrawerOpen}>
          Betriebsstätte anlegen
        </Button>
      </ButtonsAligned>
      <OperatingSiteModal
        firmendatenId={firmendatenId}
        firmaId={firmaId}
        isModalOpen={isDrawerOpen}
        toggleModalOpen={toggleDrawerOpen}
        onSuccess={onSuccess}
      />
    </>
  );
};

export default OperatingSiteBtnAndModal;
