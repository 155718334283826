import React, { FC } from 'react';
import { Text } from '@react-pdf/renderer';
import { Aufteilungsschluessel } from '../../../../objektAbrechnung-types';
import pdfStyles from '../../../../../styles/pdfStyles';

const OAAufteilungsschluessel: FC<{ aufteilungsschluessel: Aufteilungsschluessel }> = ({ aufteilungsschluessel }) => (
  <Text style={pdfStyles.textNormal}>
    ({aufteilungsschluessel.aufteilungsschluesselText} {aufteilungsschluessel.aufteilungsschluessel})
  </Text>
);

export default OAAufteilungsschluessel;
