import { FC } from 'react';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';
import { useEmailDeliveryDefinitionChangeHistoryListQuery } from '../gql/EmailDeliveryDefinitionQueries.types';

type Props = {
  emailDeliveryDefinitionId: string;
};

const EmailDeliveryDefinitionChangeHistoryListingTable: FC<Props> = ({ emailDeliveryDefinitionId }) => {
  const { data, loading } = useEmailDeliveryDefinitionChangeHistoryListQuery({ variables: { emailDeliveryDefinitionId } });
  const historyList = data?.getEmailDeliveryDefinitionChangeHistoryList.data;

  return (
    <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="email-delivery-definition" />
  );
};

export default EmailDeliveryDefinitionChangeHistoryListingTable;
