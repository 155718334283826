import React, { FC, useState } from 'react';
import { Radio, Space, Typography } from 'antd';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import PaymentProposalSelect from '../../../../PaymentProposal/PaymentProposalSelect';

type Props = {
  name: string;
  fieldLabel: string;
  onFilterChange: () => void;
  onShowOwnPaymentProposalChange: () => void;
  showNotAssignedOption: boolean;
};

const PaymentProposalFormSelect: FC<Props> = ({ name, fieldLabel, onFilterChange, onShowOwnPaymentProposalChange, showNotAssignedOption }) => {
  const [showOnlyOwm, setShowOnlyOwn] = useState(true);

  const label = (
    <Space size="middle">
      <Typography.Text>{fieldLabel}</Typography.Text>
      <Radio.Group
        name="showOwnPaymentProposal"
        size="small"
        block
        value={showOnlyOwm}
        defaultValue={showOnlyOwm}
        onChange={(e) => {
          onShowOwnPaymentProposalChange();
          setShowOnlyOwn(e.target.value);
        }}
        style={{
          width: '150px',
        }}
      >
        <Radio.Button value>Eigene</Radio.Button>
        <Radio.Button value={false}>Alle</Radio.Button>
      </Radio.Group>
    </Space>
  );

  return (
    <FormItemWithoutColon name={name} label={label}>
      <PaymentProposalSelect
        name={name}
        onChange={onFilterChange}
        showOnlyForMitarbeiter={showOnlyOwm}
        showNotAssignedOption={showNotAssignedOption}
      />
    </FormItemWithoutColon>
  );
};

export default PaymentProposalFormSelect;
