import React, { FC, ReactNode, useState } from 'react';
import { Button, Dropdown, MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import FirmendatenStreetAddressDeletionHistoryListingTable from './FirmendatenStreetAddressDeletionHistoryListingTable';
import FirmendatenEmailContactDeletionHistoryListingTable from './FirmendatenEmailContactDeletionHistoryListingTable';
import FirmendatenPhoneContactDeletionHistoryListingTable from './FirmendatenPhoneContactDeletionHistoryListingTable';
import FirmendatenOperatingSiteDeletionHistoryListingTable from './FirmendatenOperatingSiteDeletionHistoryListingTable';
import FirmendatenContactPersonDeletionHistoryListingTable from './FirmendatenContactPersonDeletionHistoryListingTable';
import FirmendatenPostofficeBoxAddressDeletionHistoryListingTable from './FirmendatenPostofficeBoxAddressDeletionHistoryListingTable';
import HistoryModal, { HistoryType } from '../../../../features/History/shared/HistoryModal';

type Props = {
  firmendatenId: string;
};

type DeletionHistory = {
  type: DeletionHistoryType;
  title: string;
  modalContent: ReactNode;
};

type DeletionHistoryType = 'streetAddress' | 'postofficeBoxAddress' | 'emailContact' | 'phoneContact' | 'operatingSite' | 'contactPerson';

const FirmendatenDeletionHistoryDropdown: FC<Props> = ({ firmendatenId }) => {
  const deletionHistoryTypes: Record<DeletionHistoryType, DeletionHistory> = {
    streetAddress: {
      type: 'streetAddress',
      title: 'Strassenadresse',
      modalContent: <FirmendatenStreetAddressDeletionHistoryListingTable firmendatenId={firmendatenId} />,
    },
    postofficeBoxAddress: {
      type: 'postofficeBoxAddress',
      title: 'Postfachadresse',
      modalContent: <FirmendatenPostofficeBoxAddressDeletionHistoryListingTable firmendatenId={firmendatenId} />,
    },
    emailContact: {
      type: 'emailContact',
      title: 'E-Mail',
      modalContent: <FirmendatenEmailContactDeletionHistoryListingTable firmendatenId={firmendatenId} />,
    },
    phoneContact: {
      type: 'phoneContact',
      title: 'Telefon',
      modalContent: <FirmendatenPhoneContactDeletionHistoryListingTable firmendatenId={firmendatenId} />,
    },
    operatingSite: {
      type: 'operatingSite',
      title: 'Betriebstätte',
      modalContent: <FirmendatenOperatingSiteDeletionHistoryListingTable firmendatenId={firmendatenId} />,
    },
    contactPerson: {
      type: 'contactPerson',
      title: 'Ansprechpartner',
      modalContent: <FirmendatenContactPersonDeletionHistoryListingTable firmendatenId={firmendatenId} />,
    },
  } as const;

  const [historyModal, setHistoryModal] = useState<DeletionHistoryType | undefined>();

  const items: MenuProps['items'] = Object.values(deletionHistoryTypes).map((entry, index) => {
    return {
      key: index,
      label: entry.title,
      onClick: () => {
        setHistoryModal(entry.type);
      },
    };
  });

  let modalTitle = '';
  let modalContent: ReactNode = null;

  if (historyModal) {
    modalTitle = deletionHistoryTypes[historyModal].title ?? '';
    modalContent = deletionHistoryTypes[historyModal].modalContent ?? null;
  }

  return (
    <>
      <Dropdown menu={{ items }}>
        <Button>
          Löschverlauf anzeigen
          <DownOutlined />
        </Button>
      </Dropdown>
      <HistoryModal historyType={HistoryType.Deletion} entityName={modalTitle} open={!!historyModal} onCancel={() => setHistoryModal(undefined)}>
        {modalContent}
      </HistoryModal>
    </>
  );
};

export default FirmendatenDeletionHistoryDropdown;
