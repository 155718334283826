import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../security/AuthRoute';
import RechnungCreatePage from './RechnungCreatePage';
import { isActiveForSomeFirmendaten } from '../../../security/permissionChecks';
import { URI_INCOMING_INVOICE_CREATE_INVOICE_PAGE } from '../../../features/IncomingInvoice/incomingInvoiceUriPaths';

const rechnungCreatePageRoute = (
  <Route
    path={URI_INCOMING_INVOICE_CREATE_INVOICE_PAGE}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<RechnungCreatePage />} />}
  />
);

export default rechnungCreatePageRoute;
