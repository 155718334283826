import React, { FC } from 'react';
import { Input } from 'formik-antd';
import { FormFields } from '../../../../../../helpers/formikHelper';
import { KellerabteilCreateFormValues } from '../Create/kellerabteilCreateFormMapper';
import DatenTabColumnsLayout from '../../../../../Objekt/InfoFeld/Form/shared/DatenTabColumnsLayout';
import FormItemWithFieldHelp from '../../../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../../../FieldHelp/useGetFieldHelpText';

type Props = {
  formFields: FormFields<KellerabteilCreateFormValues>;
};

const KellerabteilDatenTab: FC<Props> = ({ formFields }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'BeInfoFeldKellerabteil'>('BeInfoFeldKellerabteil');

  return (
    <DatenTabColumnsLayout
      leftColumn={
        <FormItemWithFieldHelp name={formFields.nummer} label="Nummer" fieldHelp={getFieldHelpText('BeInfoFeldKellerabteil.nummer')}>
          <Input id={formFields.nummer} name={formFields.nummer} size="small" />
        </FormItemWithFieldHelp>
      }
      rightColumn={
        <FormItemWithFieldHelp name={formFields.text} label="Text" fieldHelp={getFieldHelpText('BeInfoFeldKellerabteil.text')}>
          <Input id={formFields.text} name={formFields.text} size="small" />
        </FormItemWithFieldHelp>
      }
    />
  );
};

export default KellerabteilDatenTab;
