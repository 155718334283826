import React from 'react';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import { ObjAbrBuchungszeile } from '../../../../../../types';
import DataWithShortenedText from '../../../../../../components/Helpers/DataWithShortenedText';
import CustomFormattedDate from '../../../../../../components/FormattedDate/CustomFormattedDate';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';
import { generatePathToRechtstraegerDetailsPage } from '../../../../../Rechtstraeger/rechtstraegerHelper';
import { EuroAmount } from '../../../../../../components/Number';
import SollHabenTag from '../../../../../shared/SollHabenTag';
import { generatePathToVorschreibungDetailsPage } from '../../../../../Vorschreibung/vorschreibungUriPaths';
import BelegnummerWithLinkToPDF from '../BelegnummerWithLinkToPDF';
import { NestedTableWithColSelectorColProps } from '../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import { generatePathToBookingDetailsPage } from '../../../../../BookingDetails/bookingDetailsPaths';

const objektAbrechnungEinnahmenBuchungszeileTableColumns: NestedTableWithColSelectorColProps<ObjAbrBuchungszeile>[] = [
  {
    title: 'Text',
    defaultSelected: true,
    width: 200,
    key: 'abrechnungskreis',
    render: (text, record) =>
      record.auftragId ? (
        <Link target="_blank" to={generatePathToVorschreibungDetailsPage(record.auftragId)}>
          <DataWithShortenedText text={record.text} maxTextLength={25} />
        </Link>
      ) : (
        <DataWithShortenedText text={record.text} maxTextLength={25} />
      ),
  },
  {
    title: 'Fälligkeit',
    defaultSelected: true,
    align: 'right',
    width: 100,
    render: (record: ObjAbrBuchungszeile) =>
      record.dueDate ? <CustomFormattedDate value={record.dueDate} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Belegdatum',
    defaultSelected: true,
    align: 'right',
    width: 100,
    render: (text, record) => (record.dueDate ? <CustomFormattedDate value={record.dueDate} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Beleg-Nr.',
    defaultSelected: true,
    align: 'right',
    width: 100,
    render: (text, record) => <BelegnummerWithLinkToPDF buchungszeile={record} />,
  },
  {
    title: 'Vertragspartner',
    defaultSelected: true,
    width: 200,
    align: 'right',
    render: (text, record) =>
      record.vertragspartner ? (
        <DataWithShortenedText maxTextLength={25} text={record.vertragspartner.kurzBezeichnung}>
          {(shortenedText) => (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <Link target="_blank" to={generatePathToRechtstraegerDetailsPage(record.vertragspartner!.rechtstraegerId)}>
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedText>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: '',
    key: 'ausgaben',
    align: 'right',
    width: 180,
  },
  {
    title: 'Einnahmen',
    key: 'einnahmen',
    align: 'right',
    width: 180,
    render: (text, record) => (
      <Space size={0}>
        <SollHabenTag sollHaben={record.sollHaben} />
        <div style={{ width: '100px', display: 'inline-flex', justifyContent: 'end' }}>
          {record.auftragId ? (
            <Link target="_blank" to={generatePathToBookingDetailsPage(record.buchungIdList)}>
              <EuroAmount value={record.betrag} />
            </Link>
          ) : (
            <EuroAmount value={record.betrag} />
          )}
        </div>
      </Space>
    ),
  },
  {
    title: '',
    key: 'saldo',
    width: 120,
  },
];

export default objektAbrechnungEinnahmenBuchungszeileTableColumns;
