import { FC, Fragment } from 'react';
import { Space, Tag, Typography } from 'antd';
import { BudgetingAufteilungsschluessel } from '../../../types';
import theme from '../../../theme';

type Props = {
  aufteilungsschluesselList: BudgetingAufteilungsschluessel[];
  disabled?: boolean;
};

const DistributionKeyList: FC<Props> = ({ aufteilungsschluesselList, disabled }) => {
  return (
    <Space>
      {aufteilungsschluesselList.map(({ aufteilungsschluessel, vertragLevel }) => {
        return (
          <Fragment key={aufteilungsschluessel.aufteilungsschluesselId}>
            <Typography style={disabled ? { color: theme.textColor.disabled } : {}}>{vertragLevel.shortName}:</Typography>
            <Tag style={disabled ? { opacity: 0.5 } : {}}>{aufteilungsschluessel.kurzBezeichnung}</Tag>
          </Fragment>
        );
      })}
    </Space>
  );
};

export default DistributionKeyList;
