import React, { FC, PropsWithChildren } from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import StyledTypographyText from './styled/StyledTypographyText.style';

type Props = {
  isAvatar?: boolean;
};

const DeletedData: FC<Props & PropsWithChildren> = ({ isAvatar, children }) => {
  return (
    <StyledTypographyText isAvatar={isAvatar}>
      {children} {isAvatar && <InfoCirlce />}
    </StyledTypographyText>
  );
};

const InfoCirlce = () => (
  <Tooltip placement="top" title="gelöscht">
    <InfoCircleOutlined style={{ cursor: 'help' }} />
  </Tooltip>
);

export default DeletedData;
