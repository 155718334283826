import { Select, SelectProps } from 'formik-antd';
import React, { FC } from 'react';

type Props = Omit<SelectProps, 'id' | 'placeholder' | 'allowClear'>;

const ValidOrInvalidFormSelect: FC<Props> = ({ name, ...restProps }) => {
  const options = [
    { label: 'Gültig', value: true },
    { label: 'Ungültig', value: false },
  ];

  return (
    <Select {...restProps} id={name} name={name} placeholder="Gültig / Ungültig auswählen" allowClear>
      {options.map((option) => (
        <Select.Option key={option.label} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ValidOrInvalidFormSelect;
