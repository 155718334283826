import { FC } from 'react';
import { Space } from 'antd';
import { VertragVposIndexationAgreementIndexAnnual } from '../../../../types';
import { isThresholdTypePercent } from '../../../Vertrag/BeVertrag/Vertragsdaten/VPosIndexationAgreement/Table/Level2/CreateUpdateIndexationAgreement/shared/indexationAgreementFormHelpers';
import { EuroAmount, Percent } from '../../../../components/Number';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import TypographyTextForTooltip from '../../../../components/Text/TypographyTextForTooltip';

type Props = {
  indexationAgreement: VertragVposIndexationAgreementIndexAnnual;
};

const IndexAnnualTooltipTitle: FC<Props> = ({ indexationAgreement }) => {
  return (
    <>
      <TypographyTextForTooltip text={`Indexwert aus Monat: ${indexationAgreement.monthOfIndexPoint.text}`} />
      <br />
      <Space size="small">
        <TypographyTextForTooltip text="Wirksam mit: " />
        {indexationAgreement.effectiveWithMonth ? (
          <TypographyTextForTooltip text={indexationAgreement.effectiveWithMonth.text} />
        ) : (
          <TextForEmptyValue textToShow="NV" color="inherit" />
        )}
      </Space>
      <br />
      <TypographyTextForTooltip text="Schwellenwert: " />
      {isThresholdTypePercent(indexationAgreement.thresholdType.value) ? (
        <Percent value={indexationAgreement.threshold} />
      ) : (
        <TypographyTextForTooltip text={`${indexationAgreement.threshold} Pkt.`} />
      )}
      <br />
      <Space>
        <TypographyTextForTooltip text="Bagatellgrenze: " />
        {indexationAgreement.minimumLimit ? (
          <EuroAmount value={indexationAgreement.minimumLimit} />
        ) : (
          <TextForEmptyValue textToShow="NV" color="inherit" />
        )}
      </Space>
      <br />
      <Space>
        <TypographyTextForTooltip text="Anteil: " />
        {indexationAgreement.shareInPercentToApply ? (
          <Percent value={indexationAgreement.shareInPercentToApply / 100} />
        ) : (
          <TextForEmptyValue textToShow="NV" color="inherit" />
        )}
      </Space>
    </>
  );
};

export default IndexAnnualTooltipTitle;
