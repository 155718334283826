import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../../security/AuthRoute';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../../security/permissionChecks';
import { URI_SYS_SETTINGS } from '../../../../../constants/configurationUriPaths';
import SysSettingsMahndefinitionPage from './SysSettingsMahndefinitionPage';

const sysSettingsMahndefinitionPageRoute = (
  <Route
    path={URI_SYS_SETTINGS.mahndefinitionPage}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsMahndefinitionPage />} />}
  />
);

export default sysSettingsMahndefinitionPageRoute;
