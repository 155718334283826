import React from 'react';
import { Route } from 'react-router';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../security/permissionChecks';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_SYS_SETTINGS } from '../../../../constants/configurationUriPaths';
import SysSettingsEmailIntegrationPage from './SysSettingsEmailIntegrationPage';

const sysSettingsEmailIntegrationPageRoute = (
  <Route
    path={URI_SYS_SETTINGS.emailIntegrationPage}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsEmailIntegrationPage />} />}
  />
);

export default sysSettingsEmailIntegrationPageRoute;
