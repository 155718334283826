import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { filtersFormFields, FiltersFormValues, listingLabelList } from './filtersFormMapper';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import FiltersWith4OrMoreFields from '../../../../components/Filters/FiltersWith4OrMoreFields';
import EmailDeliveryStatusSelect from './EmailDeliveryStatusSelect';
import RechtstraegerSelect from '../../../../shared/Rechtstraeger/RechtstraegerSelect';
import FromToFormDatePicker from '../../../shared/FromToForm/FromToFormDatePicker';
import { TEmailDeliveryListingQueryParams } from './filtersQueryParams';
import MitarbeiterSelect from '../../../../shared/components/MitarbeiterSelect/MitarbeiterSelect';
import FiltersSelect from '../../../../components/Filters/FiltersSelect';
import { onFilterSelectChange } from '../../../../components/Filters/FiltersHelpers';
import DebouncedTextSearch from '../../../../components/Input-antd/DebouncedTextSearch';
import UseCaseListFormSelect from './UseCaseListFormSelect';
import EmailDeliveryActionButtons from '../EmailDeliveryActionButtons';
import useFilterWithQueryParams from '../../../shared/useFilterWithQueryParams';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
  queryParams: TEmailDeliveryListingQueryParams;
};

const ListingFilters: FC<Props> = ({ formikProps, queryParams }) => {
  const defaultSelectedFilterList = [
    filtersFormFields.useCaseList,
    filtersFormFields.recipient,
    filtersFormFields.recipientRechtstraegerId,
    filtersFormFields.statusList,
  ];

  const mapFilterLabelToQueryFilterList = (filter: string) => {
    if (filter === filtersFormFields.createTsFromInclusive || filter === filtersFormFields.createTsToInclusive) {
      return filtersFormFields.createTsFromInclusive;
    }
    return filter;
  };

  const filters = (filter: string) => {
    if (filter === filtersFormFields.useCaseList) {
      return (
        <FormItemWithoutColon name={filtersFormFields.useCaseList} label="Anwendungsfall">
          <UseCaseListFormSelect name={filtersFormFields.useCaseList} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.recipientRechtstraegerId) {
      return (
        <FormItemWithoutColon name={filtersFormFields.recipientRechtstraegerId} label="Empfänger">
          <RechtstraegerSelect
            name={filtersFormFields.recipientRechtstraegerId}
            onChange={formikProps.submitForm}
            placeholder="Empfänger auswählen"
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.statusList) {
      return (
        <FormItemWithoutColon name={filtersFormFields.statusList} label="Status">
          <EmailDeliveryStatusSelect size="small" name={filtersFormFields.statusList} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.createTsFromInclusive) {
      return (
        <FormItemWithoutColon name="Erstelldatum" label="Erstelldatum">
          <FromToFormDatePicker
            format="day"
            fromName={filtersFormFields.createTsFromInclusive}
            toName={filtersFormFields.createTsToInclusive}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.createdByMitarbeiterIdList) {
      return (
        <FormItemWithoutColon name={filtersFormFields.createdByMitarbeiterIdList} label="Ersteller">
          <MitarbeiterSelect name={filtersFormFields.createdByMitarbeiterIdList} mode="multiple" onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.recipient) {
      return (
        <FormItemWithoutColon name={filtersFormFields.recipient} label="Empfänger Email">
          <DebouncedTextSearch
            name={filtersFormFields.recipient}
            onChange={(value) => {
              formikProps.setFieldValue(filtersFormFields.recipient, value);
              formikProps.submitForm();
            }}
            value={formikProps.values.recipient || ''}
          />
        </FormItemWithoutColon>
      );
    }
    return undefined;
  };

  const updateFilterValues = (selectedFilterList: string[]) => {
    if (!selectedFilterList.includes(filtersFormFields.createTsFromInclusive) && formikProps.values.createTsToInclusive) {
      formikProps.setFieldValue(filtersFormFields.createTsToInclusive, undefined);
    }
    formikProps.submitForm();
  };

  const { selectedFilterList, setSelectedFilterList, leftColumn, rightColumn } = useFilterWithQueryParams({
    defaultSelectedFilterList,
    updateFilterValues,
    filters,
    labelList: listingLabelList,
    mapFilterLabelToQueryFilterList,
    queryParams,
  });

  return (
    <FiltersWith4OrMoreFields
      actionButtons={<EmailDeliveryActionButtons label="E-mail Versand erstellen" />}
      filtersSelect={
        <FiltersSelect
          defaultSelectedFilterList={defaultSelectedFilterList}
          selectedFilterList={selectedFilterList}
          onChange={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
          labelList={listingLabelList}
        />
      }
      hideTitle
      leftColumn={<>{leftColumn}</>}
      rightColumn={<>{rightColumn}</>}
    />
  );
};

export default ListingFilters;
