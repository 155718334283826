import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../styles/pdfStyles';
import { SachbearbeiterType } from './types';

type SachbearbeiterProps = {
  sachbearbeiter: SachbearbeiterType;
};

const Sachbearbeiter: FC<SachbearbeiterProps> = ({ sachbearbeiter }) => (
  <View style={[pdfStyles.column, { width: '100%', textAlign: 'right' }]}>
    <Text style={[pdfStyles.textNormal, { fontWeight: 'bold' }]}>{sachbearbeiter.heading}:</Text>
    <Text style={pdfStyles.textNormal}>
      {sachbearbeiter.title} {sachbearbeiter.firstname} {sachbearbeiter.lastname} {sachbearbeiter.titleTrailing}
    </Text>
    {sachbearbeiter.phone ? (
      <Text style={pdfStyles.textNormal}>
        {sachbearbeiter.phoneText}: {sachbearbeiter.phone}
      </Text>
    ) : null}
    {sachbearbeiter.email ? (
      <Text style={pdfStyles.textNormal}>
        {sachbearbeiter.emailText}: {sachbearbeiter.email}
      </Text>
    ) : null}
  </View>
);

export default Sachbearbeiter;
