import React, { FC } from 'react';
import { Alert, Button, Space } from 'antd';

type Props = {
  isOkDisabled?: boolean;
  isOkLoading?: boolean;
  onReset: () => void;
  onSubmit: () => void;
  showNotSavedWarning: boolean;
};

const BookingSuggestionButtons: FC<Props> = ({ onReset, onSubmit, showNotSavedWarning, isOkLoading, isOkDisabled }) => {
  return (
    <Space direction="vertical" size="middle" align="end" style={{ width: '100%', height: '100%', justifyContent: 'flex-start' }}>
      <Space size="small">
        <Button
          onClick={(e) => {
            e.preventDefault();
            onReset();
          }}
        >
          Zurücksetzen
        </Button>
        <Button type="primary" onClick={onSubmit} htmlType="submit" disabled={isOkDisabled} loading={isOkLoading}>
          Speichern
        </Button>
      </Space>
      <Space style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        {showNotSavedWarning ? <Alert message="Daten wurden noch nicht gespeichert" type="warning" showIcon /> : null}
      </Space>
    </Space>
  );
};

export default BookingSuggestionButtons;
