import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VerwaltungsartListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type VerwaltungsartListQuery = {
  getVerwaltungsartList: {
    data: Array<{ text: string; value: Types.Verwaltungsart }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const VerwaltungsartListDocument = gql`
  query VerwaltungsartList {
    getVerwaltungsartList {
      data {
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useVerwaltungsartListQuery(baseOptions?: Apollo.QueryHookOptions<VerwaltungsartListQuery, VerwaltungsartListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VerwaltungsartListQuery, VerwaltungsartListQueryVariables>(VerwaltungsartListDocument, options);
}
export function useVerwaltungsartListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerwaltungsartListQuery, VerwaltungsartListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VerwaltungsartListQuery, VerwaltungsartListQueryVariables>(VerwaltungsartListDocument, options);
}
export function useVerwaltungsartListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VerwaltungsartListQuery, VerwaltungsartListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VerwaltungsartListQuery, VerwaltungsartListQueryVariables>(VerwaltungsartListDocument, options);
}
export type VerwaltungsartListQueryHookResult = ReturnType<typeof useVerwaltungsartListQuery>;
export type VerwaltungsartListLazyQueryHookResult = ReturnType<typeof useVerwaltungsartListLazyQuery>;
export type VerwaltungsartListSuspenseQueryHookResult = ReturnType<typeof useVerwaltungsartListSuspenseQuery>;
export type VerwaltungsartListQueryResult = Apollo.QueryResult<VerwaltungsartListQuery, VerwaltungsartListQueryVariables>;
