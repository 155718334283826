import React, { FC } from 'react';
import { Space, Tag, Tooltip, Typography } from 'antd';
import theme from '../../../../../theme';
import {
  isAbrKreisTypeBewirtschaftungskosten,
  isAbrKreisTypeRepFonds,
} from '../../../shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import {
  repFondVerwendungText,
  ZuordnungKontoKlasseType,
  ZuordnungViewModel,
} from '../../../../AbrKontenzuordnung/shared/AbrKreisKontenzuordnungHelpers';
import DataWithStatus from '../../../../../components/Helpers/DataWithStatus';
import { AbrechnungsdefinitionType, Abrechnungskreis } from '../../../../../types';
import { AbrechnungskreisNichtAbrechnen } from '../../../../AbrKontenzuordnung/Form/zuordnungKontoListingFormMapper';
import AbrKreisOfVerrechnungTypeName from '../../../../AbrKontenzuordnung/shared/AbrKreisOfVerrechnungTypeName';

type Props = {
  zuordnung: ZuordnungViewModel;
  kontoKlasseType: ZuordnungKontoKlasseType;
  abrDefinitionType: AbrechnungsdefinitionType;
};

const ZuordnungKontoItemContentOnlyView: FC<Props> = ({ zuordnung, kontoKlasseType, abrDefinitionType }) => {
  const isAbrKreisRepFonds = isAbrKreisTypeRepFonds(zuordnung.abrechnungskreis?.type.value);
  const isUstVomAufwand = zuordnung.ustVomAufwand;
  const buchungszeilenAnzeigen = zuordnung.buchungszeilenAnzeigen;
  const aufteilungsschluesselBezeichnung = zuordnung.aufteilungsschluessel?.kurzBezeichnung;
  const isExpenseReducing = zuordnung.expenseReducing;
  const showExpenseReducing =
    isAbrKreisTypeBewirtschaftungskosten(zuordnung.abrechnungskreis?.type.value) && kontoKlasseType === ZuordnungKontoKlasseType.EINNAHMEN;

  if (zuordnung.abrechnungskreis) {
    return (
      <DataWithStatus status={zuordnung.abrechnungskreis.status} text="">
        {() => (
          <Space direction="vertical">
            <Tag>
              {(zuordnung.abrechnungskreis as Required<Abrechnungskreis>).bezeichnung}
              {isAbrKreisTypeRepFonds((zuordnung.abrechnungskreis as Required<Abrechnungskreis>).type.value) &&
                kontoKlasseType === ZuordnungKontoKlasseType.AUSGABEN && (
                  <Typography.Text style={{ color: theme.textColor.secondary }}>{repFondVerwendungText(zuordnung.ustVomAufwand)}</Typography.Text>
                )}
            </Tag>

            {/* Additional Infos */}
            <Space direction="vertical" size={0}>
              {buchungszeilenAnzeigen && (
                <Typography.Text italic>Buchungszeile anzeigen: {buchungszeilenAnzeigen ? 'anzeigen' : 'ausblenden'}</Typography.Text>
              )}
              {isAbrKreisRepFonds && (
                <Typography.Text italic>Verwendung von Rep.Fonds: {isUstVomAufwand ? 'Ust. vom Aufwand' : 'Ausgaben'}</Typography.Text>
              )}
              {aufteilungsschluesselBezeichnung && <Typography.Text italic>Abw.Auft.Schl: {aufteilungsschluesselBezeichnung}</Typography.Text>}
              {showExpenseReducing && <Typography.Text italic>Aufwandsmindernd: {isExpenseReducing ? 'Ja' : 'Nein'}</Typography.Text>}
            </Space>
          </Space>
        )}
      </DataWithStatus>
    );
  }
  if (zuordnung.abrechnungskreis === null) {
    return (
      <Space direction="vertical">
        <Tooltip title="Wird bei der Abrechnung nicht berücksichtigt">
          <Tag color="orange">{AbrechnungskreisNichtAbrechnen.text}</Tag>
        </Tooltip>
        <Typography.Text italic>Buchungszeile anzeigen: {zuordnung.buchungszeilenAnzeigen ? 'anzeigen' : 'ausblenden'}</Typography.Text>
      </Space>
    );
  }
  return <AbrKreisOfVerrechnungTypeName abrDefinitionType={abrDefinitionType} zuordnungKontoKlasseType={kontoKlasseType} />;
};

export default ZuordnungKontoItemContentOnlyView;
