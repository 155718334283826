import React, { FC } from 'react';
import { Divider, Typography } from 'antd';
import { AbstractVertragInfoFeld } from '../../../../../types';
import { useCreateVertragInfoFeldTicketMutation } from '../../../../Ticket/gql/TicketMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { mapFormValuesToTicketCreateInput, TicketFormValues } from '../../../../Ticket/Form/ticketFormMapper';
import TicketListing from '../../../../Ticket/Listing/TicketListing';
import TicketListingFilters from '../../../../Ticket/Listing/TicketListingFilters';
import TicketActionButtonsAndDrawer from '../../../../Ticket/TicketActionButtonsAndDrawer';
import { gqlOperations } from '../../../../../graphql/gqlNamedOperations-generated';
import TicketListingCalendar from '../../../../Ticket/Listing/TicketListingCalendar';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  infoFeld: AbstractVertragInfoFeld;
  onSuccess: () => void;
};

const VertragInfoFeldTicketListing: FC<Props> = ({ objektId, bestandseinheitId, vertragId, infoFeld, onSuccess }) => {
  const [runCreate, { loading: loadingCreate }] = useCreateVertragInfoFeldTicketMutation({
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onSubmitCreate = async (values: TicketFormValues) => {
    const response = await runCreate({
      variables: {
        objektId,
        bestandseinheitId,
        vertragId,
        vertragInfoFeldId: infoFeld.vertragInfoFeldId,
        input: mapFormValuesToTicketCreateInput(values),
      },
      refetchQueries: () => [gqlOperations.Query.TicketList],
    });
    return response.data?.createVertragInfoFeldTicket.data;
  };

  return (
    <TicketListing
      tableFilterIdentifier={`vertrag-info-feld-${infoFeld.type.value}-ticket}`}
      vertragInfoFeldId={infoFeld.vertragInfoFeldId}
      onSuccess={onSuccess}
      bottom={(formikProps) => (
        <>
          <Divider />
          <Typography.Title level={4} style={{ marginTop: 16 }}>
            Kalender
          </Typography.Title>
          <TicketListingCalendar formikProps={formikProps} vertragInfoFeldId={infoFeld.vertragInfoFeldId} />
        </>
      )}
    >
      {({ formikProps, refetch, queryParams }) => (
        <TicketListingFilters
          formikProps={formikProps}
          queryParams={queryParams}
          actionButtons={
            <TicketActionButtonsAndDrawer
              refetch={refetch}
              allowCreate
              loadingCreate={loadingCreate}
              onFormSubmitCreate={onSubmitCreate}
              formikProps={formikProps}
            />
          }
        />
      )}
    </TicketListing>
  );
};

export default VertragInfoFeldTicketListing;
