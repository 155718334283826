import { Formik } from 'formik';
import React, { FC } from 'react';
import { Form } from 'formik-antd';
import { Box } from 'rebass';
import { Divider } from 'antd';
import { getPreviousTimeblockBefore, ITimeblock, useTimeline } from '../../../../../components/Timeline/timelineCtx';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import TimelineFormDatePicker from '../../../../../components/Timeline/Form/TimelineFormDatePicker';
import TimelineFormButtons from '../../../../../components/Timeline/Form/TimelineFormButtons';
import { layoutTimelineForm } from '../../../../../components/Input-antd/formLayoutHelper';
import TimelineFormKommentar from '../../../../../components/Timeline/Form/TimelineFormKommentar';
import { nutzungsartVersionFormValidationSchema } from './nutzungsartVersionFormValidationSchema';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import { BeNutzungsArt } from '../../../../../types';
import {
  mapFormValuesToBestandseinheitVersion,
  mapTimeblockToFormValues,
  nutzungsArtVersionFormFields,
  NutzungsArtVersionFormValues,
  nutzungsArtVersionFormValuesDescriptionsForKommentar,
} from './nutzungsartVersionFormMapper';
import { useCreateBeNutzungsArtMutation, useUpdateBeNutzungsArtMutation } from '../gql/NutzungsartVersionMutations.types';
import NutzungsartSelect from '../../../../Vertrag/BeVertrag/Forms/NutzungsartSelect/NutzungsartSelect';
import { gqlOperations } from '../../../../../graphql/gqlNamedOperations-generated';
import { useGetAppFieldHelpText } from '../../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../../components/Form/FormItemWithFieldHelp';

type NutzflaecheVersionFormProps = {
  timeblock: ITimeblock<BeNutzungsArt>;
  objektId: string;
  bestandseinheitId: string;
  onSubmitSuccess: () => void;
};

const NutzungsartVersionForm: FC<NutzflaecheVersionFormProps> = ({ timeblock, objektId, bestandseinheitId, onSubmitSuccess }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'BeNutzungsArt'>('BeNutzungsArt');

  const { dataSource, toggleEdit, changeToUpdateMode } = useTimeline<BeNutzungsArt>();
  const initialValues = mapTimeblockToFormValues(timeblock);
  const isUpdate = !!timeblock.beNutzungsArtId;

  const entity = 'Nutzfläche';

  const [runCreate, { loading: loadingCreate }] = useCreateBeNutzungsArtMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      changeToUpdateMode(timeblock.uuid);
      onSubmitSuccess();
    },
    refetchQueries: () => [
      gqlOperations.Query.BestandseinheitAufteilungsschluesselList,
      gqlOperations.Query.BestandseinheitAufteilungsschluesselWertAenderungList,
    ],
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateBeNutzungsArtMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      toggleEdit(timeblock.uuid);
      onSubmitSuccess();
    },
    refetchQueries: () => [
      gqlOperations.Query.BestandseinheitAufteilungsschluesselList,
      gqlOperations.Query.BestandseinheitAufteilungsschluesselWertAenderungList,
    ],
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<NutzungsArtVersionFormValues>
      initialValues={initialValues}
      validationSchema={nutzungsartVersionFormValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (isUpdate) {
          runUpdate({
            variables: {
              objektId,
              bestandseinheitId,
              beNutzungsArtId: timeblock.beNutzungsArtId,
              input: mapFormValuesToBestandseinheitVersion(values),
            },
          }).finally(() => setSubmitting(false));
        } else {
          runCreate({
            variables: {
              objektId,
              bestandseinheitId,
              input: mapFormValuesToBestandseinheitVersion(values),
            },
          }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form {...layoutTimelineForm} layout="horizontal" labelAlign="left">
          <Box width={2 / 3}>
            <FormItemWithFieldHelp
              name={nutzungsArtVersionFormFields.validFrom}
              label="Gültig ab"
              fieldHelp={getFieldHelpText('BeNutzungsArt.validFrom')}
            >
              <TimelineFormDatePicker<BeNutzungsArt>
                id={nutzungsArtVersionFormFields.validFrom}
                name={nutzungsArtVersionFormFields.validFrom}
                timeblock={timeblock}
              />
            </FormItemWithFieldHelp>

            <FormItemWithFieldHelp
              name={nutzungsArtVersionFormFields.nutzungsArt}
              label="Nutzungsart"
              style={{ margin: 0 }}
              fieldHelp={getFieldHelpText('BeNutzungsArt.nutzungsArt')}
            >
              <NutzungsartSelect
                size="small"
                id={nutzungsArtVersionFormFields.nutzungsArt}
                name={nutzungsArtVersionFormFields.nutzungsArt}
                placeholder="z.B. 123"
                style={{ width: '100%' }}
              />
            </FormItemWithFieldHelp>
          </Box>

          <Divider style={{ padding: 0, marginTop: 0, marginBottom: '16px' }} />

          <TimelineFormKommentar
            name={nutzungsArtVersionFormFields.kommentar}
            timeblock={timeblock}
            valuesDescriptions={nutzungsArtVersionFormValuesDescriptionsForKommentar}
          />

          <TimelineFormButtons<BeNutzungsArt>
            timeblock={timeblock}
            isSubmitting={formikProps.isSubmitting}
            isSameAsPreviousVersion={isSameAsPreviousVersion(
              formikProps.values,
              getPreviousTimeblockBefore(dataSource, dayjsCustom(formikProps.values.validFrom))
            )}
            submitBtnLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

const isSameAsPreviousVersion = (formValues: NutzungsArtVersionFormValues, previousTimeblock?: ITimeblock<BeNutzungsArt>) =>
  formValues.nutzungsArt === previousTimeblock?.nutzungsArt.value;

export default NutzungsartVersionForm;
