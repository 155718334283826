import { isEmpty } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumb, Col, Layout, Row, Spin } from 'antd';
import { userList } from '../../../../features/User/dummyData';
import UserCard from '../../../../features/User/UserCard';
import UserStateActions from '../../../../features/User/UserStateActions-antd';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';
import withRouter from '../../../../shared/withRouter';

const { Content } = Layout;

// TODO: loadMitarbeiterByID, deleteAndRefreshMitarbeiter

class VerwaltungBenutzerDetailsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
    };
  }

  componentDidMount() {
    const userIdUpdate = this.props.match.params.userId;
    const userUpdate = this.loadUserByID(userIdUpdate);
    this.setState({ user: userUpdate });
  }

  loadUserByID = (userId) => {
    this;
    return userList.filter((item) => item.userId === userId)[0];
  };

  render() {
    const { user } = this.state;

    if (isEmpty(user)) {
      return <Spin />;
    }
    return (
      <>
        <Content style={{ margin: '0 16px', minHeight: 280 }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>
              <Link to={URI_ANDROMEDA_SYS_SETTINGS.user}>Benutzer</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{user.username}</Breadcrumb.Item>
          </Breadcrumb>
          <Row gutter={16}>
            <Col span={12}>
              <UserCard user={user} />
            </Col>
            <Col span={12}>
              <UserStateActions user={user} />
            </Col>
          </Row>
        </Content>
      </>
    );
  }
}

VerwaltungBenutzerDetailsPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(VerwaltungBenutzerDetailsPage);
