import React from 'react';
import { useFDAuthorized } from '../../shared/Auth/useAuthorized';
import { useShowArchivedQueryParamState } from '../../hooks/useShowArchivedQueryParamState';
import { useAbrechnungskreisListByKundenSystemIdQuery } from './gql/AbrechnungskreisQueries.types';
import AbrechnungskreisButtonsAndDrawer from './AbrechnungskreisButtonsAndDrawer';
import AbrechnungskreisTable from './Table/AbrechnungskreisTable';

const AbrechnungskreisListing = () => {
  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();
  const [showArchived, toggleShowArchived] = useShowArchivedQueryParamState();

  const { data, loading, refetch } = useAbrechnungskreisListByKundenSystemIdQuery({
    variables: { kundenSystemId, withDetails: true, includeArchiviert: showArchived },
  });
  const abrechnungskreisList = data?.getAbrechnungskreisListByKundenSystemId.data;

  return (
    <>
      <AbrechnungskreisButtonsAndDrawer refetch={refetch} showArchived={showArchived} toggleShowArchived={toggleShowArchived} />
      <AbrechnungskreisTable loading={loading} abrechnungskreisList={abrechnungskreisList} refetch={refetch} />
    </>
  );
};

export default AbrechnungskreisListing;
