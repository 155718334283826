import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { SingleInvoiceFieldsFragmentDoc } from './SingleInvoiceFragments.types';
import { StandingInvoiceFieldsFragmentDoc } from './StandingInvoiceFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IncomingInvoiceListQueryVariables = Types.Exact<{
  billingType?: Types.InputMaybe<Types.Verrechnungsart>;
  bruttoFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  bruttoTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  buchungsdatumFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buchungsdatumTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buchungstext?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createdByMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  dueDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dueDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  incomingInvoiceFilterStatus?: Types.InputMaybe<Types.IncomingInvoiceFilterStatus>;
  invoiceDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  invoiceDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  invoiceNumber?: Types.InputMaybe<Types.Scalars['String']['input']>;
  issuerBankDetailsIban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  issuerRechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  kontoId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  kundenNummer?: Types.InputMaybe<Types.Scalars['String']['input']>;
  nettoFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  nettoTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  objektIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  paid?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  payeeBankDetailsIban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  payeeRechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  paymentMethod?: Types.InputMaybe<Types.PaymentMethod>;
  paymentReferenceText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  purposeOfUseText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sepaMandatReference?: Types.InputMaybe<Types.Scalars['String']['input']>;
  status?: Types.InputMaybe<Types.IncomingInvoiceStatus>;
  vatFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  vatTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  order?: Types.InputMaybe<Types.IncomingInvoiceOrderAttribute>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type IncomingInvoiceListQuery = {
  getIncomingInvoiceList: {
    data: {
      contentList: Array<{
        belegId: string;
        brutto?: number | null;
        buchungIdList: Array<string>;
        buchungskreisId?: string | null;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        fileId: string;
        issuerBankDetailsIban: string;
        issuerBankDetailsId: string;
        issuerName: string;
        issuerRechtstraegerId: string;
        kundenNummer?: string | null;
        netto?: number | null;
        objektBezeichnung?: string | null;
        objektId?: string | null;
        offenerBetrag?: number | null;
        paid: boolean;
        payeeBankDetailsIban: string;
        payeeBankDetailsId: string;
        payeeName: string;
        payeeRechtstraegerId: string;
        paymentIdList: Array<string>;
        paymentCreatable: boolean;
        paymentReferenceText?: string | null;
        purposeOfUseText?: string | null;
        rechnungsDatum: string;
        rechnungsNummer: string;
        sepaMandatReference?: string | null;
        vat?: number | null;
        billingType: { text: string; value: Types.Verrechnungsart };
        buchungsanweisungStatus?: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus } | null;
        partialAmountList: Array<{
          abgrenzungsBuchung?: boolean | null;
          abgrenzungsBuchungsdatum?: string | null;
          brutto: number;
          buchungsdatum?: string | null;
          dueDate: string;
          name: string;
          netto: number;
          offenerBetrag: number;
          paid: boolean;
          paymentCreatable: boolean;
          requestedPaymentDate?: string | null;
          standingInvoicePartialAmountId?: string | null;
          vat: number;
          bookingPositionList: Array<{
            bestandseinheitId?: string | null;
            bestandseinheitKurzBezeichnung?: string | null;
            betrag: number;
            buchungstext: string;
            kontoBezeichnung: string;
            kontoId: string;
            kontoNummer: string;
            steuersatz: number;
          }>;
        }>;
        paymentMethod: { text: string; value: Types.PaymentMethod };
        status: { text: string; value: Types.IncomingInvoiceStatus };
        warningList: Array<{ message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalPages: number; totalElements: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type IncomingInvoiceListSummaryQueryVariables = Types.Exact<{
  billingType?: Types.InputMaybe<Types.Verrechnungsart>;
  bruttoFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  bruttoTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  buchungsdatumFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buchungsdatumTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buchungstext?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createdByMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  dueDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dueDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  incomingInvoiceFilterStatus?: Types.InputMaybe<Types.IncomingInvoiceFilterStatus>;
  invoiceDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  invoiceDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  invoiceNumber?: Types.InputMaybe<Types.Scalars['String']['input']>;
  issuerBankDetailsIban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  issuerRechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  kontoId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  kundenNummer?: Types.InputMaybe<Types.Scalars['String']['input']>;
  nettoFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  nettoTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  objektIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  paid?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  payeeBankDetailsIban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  payeeRechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  paymentMethod?: Types.InputMaybe<Types.PaymentMethod>;
  paymentReferenceText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  purposeOfUseText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sepaMandatReference?: Types.InputMaybe<Types.Scalars['String']['input']>;
  status?: Types.InputMaybe<Types.IncomingInvoiceStatus>;
  vatFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  vatTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
}>;

export type IncomingInvoiceListSummaryQuery = {
  getIncomingInvoiceListSummary: {
    data: { brutto: number; netto: number; vat: number };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type IncomingInvoiceQueryVariables = Types.Exact<{
  belegId: Types.Scalars['String']['input'];
}>;

export type IncomingInvoiceQuery = {
  getIncomingInvoice: {
    data:
      | {
          abgrenzungsBuchung: boolean;
          abgrenzungsBuchungsdatum?: string | null;
          ausstellerBezeichnung: string;
          ausstellerRechtstraegerId: string;
          belegId: string;
          brutto: number;
          buchungIdList: Array<string>;
          buchungsanweisungIdList: Array<string>;
          buchungskreisId?: string | null;
          buchungsdatum: string;
          dueDate: string;
          fileId: string;
          issuerBankDetailsIban: string;
          issuerBankDetailsId: string;
          kundenNummer?: string | null;
          netto: number;
          offenerBetrag?: number | null;
          objektBezeichnung?: string | null;
          objektId?: string | null;
          paid: boolean;
          payeeBezeichnung: string;
          payeeBankDetailsIban: string;
          payeeBankDetailsId: string;
          payeeRechtstraegerId: string;
          paymentCreatable: boolean;
          paymentIdList: Array<string>;
          paymentReferenceText?: string | null;
          purposeOfUseText?: string | null;
          rechnungsDatum: string;
          rechnungsNummer: string;
          requestedPaymentDate?: string | null;
          sepaMandatReference?: string | null;
          type: Types.InvoiceType;
          basis: { text: string; value: Types.IncomingInvoiceBasis };
          billingType: { text: string; value: Types.Verrechnungsart };
          buchungErrorList: Array<{ message: string; type: string }>;
          buchungsanweisungListEntryDataList: Array<{
            buchungsanweisungId: string;
            status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
            fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
          }>;
          buchungsanweisungStatus?: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus } | null;
          paymentMethod: { text: string; value: Types.PaymentMethod };
          buchungPositionList: Array<{
            betrag: number;
            bestandseinheitId?: string | null;
            bestandseinheitBezeichnung?: string | null;
            buchungstext: string;
            incomingInvoiceBuchungPositionId: string;
            kontoBezeichnung: string;
            kontoId: string;
            kontoNummer: string;
            steuersatz: number;
          }>;
          status: { text: string; value: Types.IncomingInvoiceStatus };
          steuerzeileList: Array<{ betrag: number; steuersatz: number }>;
          warningList: Array<{ message: string; type: string }>;
        }
      | {
          ausstellerBezeichnung: string;
          ausstellerRechtstraegerId: string;
          belegId: string;
          buchungIdList: Array<string>;
          brutto: number;
          buchungskreisId?: string | null;
          fileId: string;
          issuerBankDetailsIban: string;
          issuerBankDetailsId: string;
          kundenNummer?: string | null;
          netto: number;
          objektBezeichnung?: string | null;
          objektId?: string | null;
          offenerBetrag?: number | null;
          paid: boolean;
          payeeBezeichnung: string;
          payeeBankDetailsIban: string;
          payeeBankDetailsId: string;
          payeeRechtstraegerId: string;
          paymentCreatable: boolean;
          paymentIdList: Array<string>;
          paymentReferenceText?: string | null;
          purposeOfUseText?: string | null;
          rechnungsDatum: string;
          rechnungsNummer: string;
          sepaMandatReference?: string | null;
          type: Types.InvoiceType;
          vat: number;
          basis: { text: string; value: Types.IncomingInvoiceBasis };
          billingType: { text: string; value: Types.Verrechnungsart };
          partialAmountList: Array<{
            brutto: number;
            buchungIdList: Array<string>;
            buchungsanweisungIdList: Array<string>;
            buchungsdatum: string;
            dueDate: string;
            netto: number;
            offenerBetrag?: number | null;
            paid: boolean;
            partialAmountId: string;
            paymentCreatable: boolean;
            paymentIdList: Array<string>;
            requestedPaymentDate?: string | null;
            buchungErrorList: Array<{ message: string; type: string }>;
            buchungPositionList: Array<{
              betrag: number;
              bestandseinheitId?: string | null;
              bestandseinheitBezeichnung?: string | null;
              buchungstext: string;
              incomingInvoiceBuchungPositionId: string;
              kontoBezeichnung: string;
              kontoId: string;
              kontoNummer: string;
              steuersatz: number;
            }>;
            buchungsanweisungListEntryDataList: Array<{
              buchungsanweisungId: string;
              fibuStatus: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungFibuStatus };
              status: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus };
            }>;
            buchungsanweisungStatus?: { description?: string | null; text: string; value: Types.OrderBuchungsanweisungStatus } | null;
            steuerzeileList: Array<{ betrag: number; steuersatz: number }>;
          }>;
          paymentMethod: { text: string; value: Types.PaymentMethod };
          status: { text: string; value: Types.IncomingInvoiceStatus };
          warningList: Array<{ message: string; type: string }>;
        };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const IncomingInvoiceListDocument = gql`
  query IncomingInvoiceList(
    $billingType: Verrechnungsart
    $bruttoFrom: Float
    $bruttoTo: Float
    $buchungsdatumFrom: String
    $buchungsdatumTo: String
    $buchungstext: String
    $createTsFrom: String
    $createTsTo: String
    $createdByMitarbeiterIdList: [String!]
    $dueDateFrom: String
    $dueDateTo: String
    $incomingInvoiceFilterStatus: IncomingInvoiceFilterStatus
    $invoiceDateFrom: String
    $invoiceDateTo: String
    $invoiceNumber: String
    $issuerBankDetailsIban: String
    $issuerRechtstraegerId: String
    $kontoId: String
    $kundenNummer: String
    $nettoFrom: Float
    $nettoTo: Float
    $objektIdList: [String!]
    $paid: Boolean
    $payeeBankDetailsIban: String
    $payeeRechtstraegerId: String
    $paymentMethod: PaymentMethod
    $paymentReferenceText: String
    $purposeOfUseText: String
    $sepaMandatReference: String
    $status: IncomingInvoiceStatus
    $vatFrom: Float
    $vatTo: Float
    $page: Int
    $pageSize: Int
    $order: IncomingInvoiceOrderAttribute
    $orderDir: String
  ) {
    getIncomingInvoiceList(
      billingType: $billingType
      bruttoFrom: $bruttoFrom
      bruttoTo: $bruttoTo
      buchungsdatumFrom: $buchungsdatumFrom
      buchungsdatumTo: $buchungsdatumTo
      buchungstext: $buchungstext
      createTsFrom: $createTsFrom
      createTsTo: $createTsTo
      createdByMitarbeiterIdList: $createdByMitarbeiterIdList
      dueDateFrom: $dueDateFrom
      dueDateTo: $dueDateTo
      incomingInvoiceFilterStatus: $incomingInvoiceFilterStatus
      invoiceDateFrom: $invoiceDateFrom
      invoiceDateTo: $invoiceDateTo
      invoiceNumber: $invoiceNumber
      issuerBankDetailsIban: $issuerBankDetailsIban
      issuerRechtstraegerId: $issuerRechtstraegerId
      kontoId: $kontoId
      kundenNummer: $kundenNummer
      nettoFrom: $nettoFrom
      nettoTo: $nettoTo
      objektIdList: $objektIdList
      paid: $paid
      payeeBankDetailsIban: $payeeBankDetailsIban
      payeeRechtstraegerId: $payeeRechtstraegerId
      paymentMethod: $paymentMethod
      paymentReferenceText: $paymentReferenceText
      purposeOfUseText: $purposeOfUseText
      sepaMandatReference: $sepaMandatReference
      status: $status
      vatFrom: $vatFrom
      vatTo: $vatTo
      page: $page
      pageSize: $pageSize
      order: $order
      orderDir: $orderDir
    ) {
      data {
        contentList {
          belegId
          billingType {
            text
            value
          }
          brutto
          buchungIdList
          buchungsanweisungStatus {
            description
            text
            value
          }
          buchungskreisId
          createTs
          createdBy
          createdByMitarbeiterId
          fileId
          issuerBankDetailsIban
          issuerBankDetailsId
          issuerName
          issuerRechtstraegerId
          kundenNummer
          netto
          objektBezeichnung
          objektId
          offenerBetrag
          paid
          partialAmountList {
            abgrenzungsBuchung
            abgrenzungsBuchungsdatum
            bookingPositionList {
              bestandseinheitId
              bestandseinheitKurzBezeichnung
              betrag
              buchungstext
              kontoBezeichnung
              kontoId
              kontoNummer
              steuersatz
            }
            brutto
            buchungsdatum
            dueDate
            name
            netto
            offenerBetrag
            paid
            paymentCreatable
            requestedPaymentDate
            standingInvoicePartialAmountId
            vat
          }
          payeeBankDetailsIban
          payeeBankDetailsId
          payeeName
          payeeRechtstraegerId
          paymentIdList
          paymentCreatable
          paymentMethod {
            text
            value
          }
          paymentReferenceText
          purposeOfUseText
          rechnungsDatum
          rechnungsNummer
          sepaMandatReference
          status {
            text
            value
          }
          vat
          warningList {
            message
            type
          }
        }
        page {
          number
          size
          totalPages
          totalElements
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useIncomingInvoiceListQuery(baseOptions?: Apollo.QueryHookOptions<IncomingInvoiceListQuery, IncomingInvoiceListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IncomingInvoiceListQuery, IncomingInvoiceListQueryVariables>(IncomingInvoiceListDocument, options);
}
export function useIncomingInvoiceListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IncomingInvoiceListQuery, IncomingInvoiceListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IncomingInvoiceListQuery, IncomingInvoiceListQueryVariables>(IncomingInvoiceListDocument, options);
}
export function useIncomingInvoiceListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IncomingInvoiceListQuery, IncomingInvoiceListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IncomingInvoiceListQuery, IncomingInvoiceListQueryVariables>(IncomingInvoiceListDocument, options);
}
export type IncomingInvoiceListQueryHookResult = ReturnType<typeof useIncomingInvoiceListQuery>;
export type IncomingInvoiceListLazyQueryHookResult = ReturnType<typeof useIncomingInvoiceListLazyQuery>;
export type IncomingInvoiceListSuspenseQueryHookResult = ReturnType<typeof useIncomingInvoiceListSuspenseQuery>;
export type IncomingInvoiceListQueryResult = Apollo.QueryResult<IncomingInvoiceListQuery, IncomingInvoiceListQueryVariables>;
export const IncomingInvoiceListSummaryDocument = gql`
  query IncomingInvoiceListSummary(
    $billingType: Verrechnungsart
    $bruttoFrom: Float
    $bruttoTo: Float
    $buchungsdatumFrom: String
    $buchungsdatumTo: String
    $buchungstext: String
    $createTsFrom: String
    $createTsTo: String
    $createdByMitarbeiterIdList: [String!]
    $dueDateFrom: String
    $dueDateTo: String
    $incomingInvoiceFilterStatus: IncomingInvoiceFilterStatus
    $invoiceDateFrom: String
    $invoiceDateTo: String
    $invoiceNumber: String
    $issuerBankDetailsIban: String
    $issuerRechtstraegerId: String
    $kontoId: String
    $kundenNummer: String
    $nettoFrom: Float
    $nettoTo: Float
    $objektIdList: [String!]
    $paid: Boolean
    $payeeBankDetailsIban: String
    $payeeRechtstraegerId: String
    $paymentMethod: PaymentMethod
    $paymentReferenceText: String
    $purposeOfUseText: String
    $sepaMandatReference: String
    $status: IncomingInvoiceStatus
    $vatFrom: Float
    $vatTo: Float
  ) {
    getIncomingInvoiceListSummary(
      billingType: $billingType
      bruttoFrom: $bruttoFrom
      bruttoTo: $bruttoTo
      buchungsdatumFrom: $buchungsdatumFrom
      buchungsdatumTo: $buchungsdatumTo
      buchungstext: $buchungstext
      createTsFrom: $createTsFrom
      createTsTo: $createTsTo
      createdByMitarbeiterIdList: $createdByMitarbeiterIdList
      dueDateFrom: $dueDateFrom
      dueDateTo: $dueDateTo
      incomingInvoiceFilterStatus: $incomingInvoiceFilterStatus
      invoiceDateFrom: $invoiceDateFrom
      invoiceDateTo: $invoiceDateTo
      invoiceNumber: $invoiceNumber
      issuerBankDetailsIban: $issuerBankDetailsIban
      issuerRechtstraegerId: $issuerRechtstraegerId
      kontoId: $kontoId
      kundenNummer: $kundenNummer
      nettoFrom: $nettoFrom
      nettoTo: $nettoTo
      objektIdList: $objektIdList
      paid: $paid
      payeeBankDetailsIban: $payeeBankDetailsIban
      payeeRechtstraegerId: $payeeRechtstraegerId
      paymentMethod: $paymentMethod
      paymentReferenceText: $paymentReferenceText
      purposeOfUseText: $purposeOfUseText
      sepaMandatReference: $sepaMandatReference
      status: $status
      vatFrom: $vatFrom
      vatTo: $vatTo
    ) {
      data {
        brutto
        netto
        vat
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useIncomingInvoiceListSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<IncomingInvoiceListSummaryQuery, IncomingInvoiceListSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IncomingInvoiceListSummaryQuery, IncomingInvoiceListSummaryQueryVariables>(IncomingInvoiceListSummaryDocument, options);
}
export function useIncomingInvoiceListSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IncomingInvoiceListSummaryQuery, IncomingInvoiceListSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IncomingInvoiceListSummaryQuery, IncomingInvoiceListSummaryQueryVariables>(IncomingInvoiceListSummaryDocument, options);
}
export function useIncomingInvoiceListSummarySuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IncomingInvoiceListSummaryQuery, IncomingInvoiceListSummaryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IncomingInvoiceListSummaryQuery, IncomingInvoiceListSummaryQueryVariables>(
    IncomingInvoiceListSummaryDocument,
    options
  );
}
export type IncomingInvoiceListSummaryQueryHookResult = ReturnType<typeof useIncomingInvoiceListSummaryQuery>;
export type IncomingInvoiceListSummaryLazyQueryHookResult = ReturnType<typeof useIncomingInvoiceListSummaryLazyQuery>;
export type IncomingInvoiceListSummarySuspenseQueryHookResult = ReturnType<typeof useIncomingInvoiceListSummarySuspenseQuery>;
export type IncomingInvoiceListSummaryQueryResult = Apollo.QueryResult<IncomingInvoiceListSummaryQuery, IncomingInvoiceListSummaryQueryVariables>;
export const IncomingInvoiceDocument = gql`
  query IncomingInvoice($belegId: String!) {
    getIncomingInvoice(belegId: $belegId) {
      data {
        ... on SingleInvoice {
          ...SingleInvoiceFields
        }
        ... on StandingInvoice {
          ...StandingInvoiceFields
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${SingleInvoiceFieldsFragmentDoc}
  ${StandingInvoiceFieldsFragmentDoc}
`;
export function useIncomingInvoiceQuery(
  baseOptions: Apollo.QueryHookOptions<IncomingInvoiceQuery, IncomingInvoiceQueryVariables> &
    ({ variables: IncomingInvoiceQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IncomingInvoiceQuery, IncomingInvoiceQueryVariables>(IncomingInvoiceDocument, options);
}
export function useIncomingInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IncomingInvoiceQuery, IncomingInvoiceQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IncomingInvoiceQuery, IncomingInvoiceQueryVariables>(IncomingInvoiceDocument, options);
}
export function useIncomingInvoiceSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IncomingInvoiceQuery, IncomingInvoiceQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IncomingInvoiceQuery, IncomingInvoiceQueryVariables>(IncomingInvoiceDocument, options);
}
export type IncomingInvoiceQueryHookResult = ReturnType<typeof useIncomingInvoiceQuery>;
export type IncomingInvoiceLazyQueryHookResult = ReturnType<typeof useIncomingInvoiceLazyQuery>;
export type IncomingInvoiceSuspenseQueryHookResult = ReturnType<typeof useIncomingInvoiceSuspenseQuery>;
export type IncomingInvoiceQueryResult = Apollo.QueryResult<IncomingInvoiceQuery, IncomingInvoiceQueryVariables>;
