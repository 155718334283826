import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { HistoryListFieldsFragmentDoc } from '../../../History/gql/HistoryFragments.types';
import { OrderObjektFragmentDoc, ObjektFragmentDoc } from '../../../Objekt/gql/ObjektFragments.types';
import { OrderRechtstraegerFragmentDoc } from '../../../Rechtstraeger/gql/RechtsTraegerFragments.types';
import { OrderKontoFragmentDoc } from '../../../Kontenplan/gql/KontenplanFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PaymentApprovalStatusListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type PaymentApprovalStatusListQuery = {
  getPaymentApprovalStatusList: {
    data: Array<{ text: string; value: Types.PaymentApprovalStatus }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type PaymentChangeHistoryListQueryVariables = Types.Exact<{
  paymentId: Types.Scalars['ID']['input'];
}>;

export type PaymentChangeHistoryListQuery = {
  getPaymentChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type PaymentListQueryVariables = Types.Exact<{
  amountFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  amountTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  approvedByMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  assignedToPaymentProposal?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  belegnummer?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buchungsdatumBis?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buchungsdatumVon?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dataCarrierIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  dueDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dueDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  employeeApproval?: Types.InputMaybe<Types.EmployeeApproval>;
  erstellerMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  invoiceDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  invoiceDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  noted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  objektIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  payeeIban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  payeeId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  paymentApprovalStatusList?: Types.InputMaybe<Array<Types.PaymentApprovalStatus> | Types.PaymentApprovalStatus>;
  paymentDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  paymentDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  paymentProposalId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  paymentTransactionType?: Types.InputMaybe<Types.PaymentTransactionType>;
  paymentTypeList?: Types.InputMaybe<Array<Types.PaymentType> | Types.PaymentType>;
  rechnungsAusstellerFibuKontoId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechnungsAusstellerIban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechnungsAusstellerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sepaCreditorId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sepaMandateReference?: Types.InputMaybe<Types.Scalars['String']['input']>;
  statusList?: Types.InputMaybe<Array<Types.OrderPaymentStatus> | Types.OrderPaymentStatus>;
  purposeOfUseText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  paymentReferenceText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  order?: Types.InputMaybe<Types.PaymentOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type PaymentListQuery = {
  getPaymentList: {
    data: {
      contentList: Array<{
        approvable: boolean;
        auftragId?: string | null;
        belegId?: string | null;
        amount: number;
        belegFileId?: string | null;
        belegdatum?: string | null;
        belegnummer?: string | null;
        bookingDate?: string | null;
        buchungId?: string | null;
        changeableToDatentraegerErzeugt: boolean;
        changeableToDurchfuehrbar: boolean;
        changeableToNichtDurchfuehrbar: boolean;
        changeableToNichtDurchgefuehrt: boolean;
        comment?: string | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        deletable: boolean;
        dueDate?: string | null;
        heVertragId?: string | null;
        noted: boolean;
        payeeAccountHolder: string;
        payeeIban: string;
        paymentDate: string;
        paymentId: string;
        paymentProposal?: string | null;
        paymentProposalId?: string | null;
        paymentReferenceText?: string | null;
        rechnungsAusstellerAccountHolder: string;
        rechnungsAusstellerIban: string;
        sepaCreditorId?: string | null;
        sepaMandatReference?: string | null;
        statusReasonList: Array<string>;
        standingInvoicePartialAmountId?: string | null;
        transactionInstructionId?: string | null;
        updatedByMitarbeiterId?: string | null;
        verwendungszweck?: string | null;
        approvalList: Array<{ mitarbeiterId: string; text: string }>;
        objekt: { kurzBezeichnung: string; objektId: string };
        payee: { kurzBezeichnung: string; kundennummer: string; rechtstraegerId: string; type: { text: string; value: Types.RechtstraegerType } };
        paymentApprovalStatus?: { text: string; value: Types.PaymentApprovalStatus } | null;
        paymentMethod: { text: string; value: Types.PaymentMethod };
        paymentTransactionType?: { text: string; value: Types.PaymentTransactionType } | null;
        paymentType: { text: string; value: Types.PaymentType };
        rechnungsAussteller: {
          kurzBezeichnung: string;
          kundennummer: string;
          rechtstraegerId: string;
          type: { text: string; value: Types.RechtstraegerType };
        };
        rechnungsAusstellerFibuKonto: { bezeichnung: string; kontoId: string; nummer: string };
        status: { text: string; value: Types.OrderPaymentStatus };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type PaymentListSummaryQueryVariables = Types.Exact<{
  assignedToPaymentProposal?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  belegnummer?: Types.InputMaybe<Types.Scalars['String']['input']>;
  amountTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  amountFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  buchungsdatumBis?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buchungsdatumVon?: Types.InputMaybe<Types.Scalars['String']['input']>;
  employeeApproval?: Types.InputMaybe<Types.EmployeeApproval>;
  erstellerMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  invoiceDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  invoiceDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  noted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  objektIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  payeeIban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  payeeId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  paymentApprovalStatusList?: Types.InputMaybe<Array<Types.PaymentApprovalStatus> | Types.PaymentApprovalStatus>;
  paymentDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  paymentDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  paymentProposalId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  paymentTransactionType?: Types.InputMaybe<Types.PaymentTransactionType>;
  paymentTypeList?: Types.InputMaybe<Array<Types.PaymentType> | Types.PaymentType>;
  rechnungsAusstellerFibuKontoId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechnungsAusstellerIban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rechnungsAusstellerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sepaCreditorId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sepaMandateReference?: Types.InputMaybe<Types.Scalars['String']['input']>;
  statusList?: Types.InputMaybe<Array<Types.OrderPaymentStatus> | Types.OrderPaymentStatus>;
  purposeOfUseText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  paymentReferenceText?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type PaymentListSummaryQuery = {
  getPaymentListSummary: {
    data: { sumAmount: number };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const PaymentApprovalStatusListDocument = gql`
  query PaymentApprovalStatusList {
    getPaymentApprovalStatusList {
      data {
        text
        value
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function usePaymentApprovalStatusListQuery(
  baseOptions?: Apollo.QueryHookOptions<PaymentApprovalStatusListQuery, PaymentApprovalStatusListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentApprovalStatusListQuery, PaymentApprovalStatusListQueryVariables>(PaymentApprovalStatusListDocument, options);
}
export function usePaymentApprovalStatusListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentApprovalStatusListQuery, PaymentApprovalStatusListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentApprovalStatusListQuery, PaymentApprovalStatusListQueryVariables>(PaymentApprovalStatusListDocument, options);
}
export function usePaymentApprovalStatusListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PaymentApprovalStatusListQuery, PaymentApprovalStatusListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaymentApprovalStatusListQuery, PaymentApprovalStatusListQueryVariables>(PaymentApprovalStatusListDocument, options);
}
export type PaymentApprovalStatusListQueryHookResult = ReturnType<typeof usePaymentApprovalStatusListQuery>;
export type PaymentApprovalStatusListLazyQueryHookResult = ReturnType<typeof usePaymentApprovalStatusListLazyQuery>;
export type PaymentApprovalStatusListSuspenseQueryHookResult = ReturnType<typeof usePaymentApprovalStatusListSuspenseQuery>;
export type PaymentApprovalStatusListQueryResult = Apollo.QueryResult<PaymentApprovalStatusListQuery, PaymentApprovalStatusListQueryVariables>;
export const PaymentChangeHistoryListDocument = gql`
  query PaymentChangeHistoryList($paymentId: ID!) {
    getPaymentChangeHistoryList(paymentId: $paymentId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function usePaymentChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<PaymentChangeHistoryListQuery, PaymentChangeHistoryListQueryVariables> &
    ({ variables: PaymentChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentChangeHistoryListQuery, PaymentChangeHistoryListQueryVariables>(PaymentChangeHistoryListDocument, options);
}
export function usePaymentChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentChangeHistoryListQuery, PaymentChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentChangeHistoryListQuery, PaymentChangeHistoryListQueryVariables>(PaymentChangeHistoryListDocument, options);
}
export function usePaymentChangeHistoryListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PaymentChangeHistoryListQuery, PaymentChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaymentChangeHistoryListQuery, PaymentChangeHistoryListQueryVariables>(PaymentChangeHistoryListDocument, options);
}
export type PaymentChangeHistoryListQueryHookResult = ReturnType<typeof usePaymentChangeHistoryListQuery>;
export type PaymentChangeHistoryListLazyQueryHookResult = ReturnType<typeof usePaymentChangeHistoryListLazyQuery>;
export type PaymentChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof usePaymentChangeHistoryListSuspenseQuery>;
export type PaymentChangeHistoryListQueryResult = Apollo.QueryResult<PaymentChangeHistoryListQuery, PaymentChangeHistoryListQueryVariables>;
export const PaymentListDocument = gql`
  query PaymentList(
    $amountFrom: Float
    $amountTo: Float
    $approvedByMitarbeiterIdList: [String!]
    $assignedToPaymentProposal: Boolean
    $belegnummer: String
    $buchungsdatumBis: String
    $buchungsdatumVon: String
    $dataCarrierIdList: [String!]
    $dueDateFrom: String
    $dueDateTo: String
    $employeeApproval: EmployeeApproval
    $erstellerMitarbeiterIdList: [String!]
    $invoiceDateFrom: String
    $invoiceDateTo: String
    $noted: Boolean
    $objektIdList: [String!]
    $payeeIban: String
    $payeeId: String
    $paymentApprovalStatusList: [PaymentApprovalStatus!]
    $paymentDateFrom: String
    $paymentDateTo: String
    $paymentProposalId: String
    $paymentTransactionType: PaymentTransactionType
    $paymentTypeList: [PaymentType!]
    $rechnungsAusstellerFibuKontoId: String
    $rechnungsAusstellerIban: String
    $rechnungsAusstellerId: String
    $sepaCreditorId: String
    $sepaMandateReference: String
    $statusList: [OrderPaymentStatus!]
    $purposeOfUseText: String
    $paymentReferenceText: String
    $order: PaymentOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getPaymentList(
      approvedByMitarbeiterIdList: $approvedByMitarbeiterIdList
      assignedToPaymentProposal: $assignedToPaymentProposal
      belegnummer: $belegnummer
      amountTo: $amountTo
      amountFrom: $amountFrom
      buchungsdatumBis: $buchungsdatumBis
      buchungsdatumVon: $buchungsdatumVon
      dataCarrierIdList: $dataCarrierIdList
      dueDateFrom: $dueDateFrom
      dueDateTo: $dueDateTo
      employeeApproval: $employeeApproval
      invoiceDateFrom: $invoiceDateFrom
      invoiceDateTo: $invoiceDateTo
      erstellerMitarbeiterIdList: $erstellerMitarbeiterIdList
      noted: $noted
      objektIdList: $objektIdList
      payeeIban: $payeeIban
      payeeId: $payeeId
      paymentApprovalStatusList: $paymentApprovalStatusList
      paymentDateFrom: $paymentDateFrom
      paymentDateTo: $paymentDateTo
      paymentProposalId: $paymentProposalId
      paymentTransactionType: $paymentTransactionType
      paymentTypeList: $paymentTypeList
      rechnungsAusstellerFibuKontoId: $rechnungsAusstellerFibuKontoId
      rechnungsAusstellerIban: $rechnungsAusstellerIban
      rechnungsAusstellerId: $rechnungsAusstellerId
      sepaCreditorId: $sepaCreditorId
      sepaMandateReference: $sepaMandateReference
      statusList: $statusList
      purposeOfUseText: $purposeOfUseText
      paymentReferenceText: $paymentReferenceText
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          approvable
          approvalList {
            mitarbeiterId
            text
          }
          auftragId
          belegId
          amount
          belegFileId
          belegdatum
          belegnummer
          bookingDate
          buchungId
          changeableToDatentraegerErzeugt
          changeableToDurchfuehrbar
          changeableToNichtDurchfuehrbar
          changeableToNichtDurchgefuehrt
          comment
          createTs
          createdBy
          createdByMitarbeiterId
          deletable
          dueDate
          heVertragId
          noted
          objekt {
            ...OrderObjekt
          }
          payee {
            ...OrderRechtstraeger
          }
          payeeAccountHolder
          payeeIban
          paymentApprovalStatus {
            text
            value
          }
          paymentDate
          paymentId
          paymentMethod {
            text
            value
          }
          paymentProposal
          paymentProposalId
          paymentReferenceText
          paymentTransactionType {
            text
            value
          }
          paymentType {
            text
            value
          }
          rechnungsAussteller {
            ...OrderRechtstraeger
          }
          rechnungsAusstellerAccountHolder
          rechnungsAusstellerFibuKonto {
            ...OrderKonto
          }
          rechnungsAusstellerIban
          sepaCreditorId
          sepaMandatReference
          status {
            text
            value
          }
          statusReasonList
          standingInvoicePartialAmountId
          transactionInstructionId
          updatedByMitarbeiterId
          verwendungszweck
          warningList {
            attribute
            message
            type
          }
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${OrderObjektFragmentDoc}
  ${OrderRechtstraegerFragmentDoc}
  ${OrderKontoFragmentDoc}
`;
export function usePaymentListQuery(baseOptions?: Apollo.QueryHookOptions<PaymentListQuery, PaymentListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentListQuery, PaymentListQueryVariables>(PaymentListDocument, options);
}
export function usePaymentListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentListQuery, PaymentListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentListQuery, PaymentListQueryVariables>(PaymentListDocument, options);
}
export function usePaymentListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PaymentListQuery, PaymentListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaymentListQuery, PaymentListQueryVariables>(PaymentListDocument, options);
}
export type PaymentListQueryHookResult = ReturnType<typeof usePaymentListQuery>;
export type PaymentListLazyQueryHookResult = ReturnType<typeof usePaymentListLazyQuery>;
export type PaymentListSuspenseQueryHookResult = ReturnType<typeof usePaymentListSuspenseQuery>;
export type PaymentListQueryResult = Apollo.QueryResult<PaymentListQuery, PaymentListQueryVariables>;
export const PaymentListSummaryDocument = gql`
  query PaymentListSummary(
    $assignedToPaymentProposal: Boolean
    $belegnummer: String
    $amountTo: Float
    $amountFrom: Float
    $buchungsdatumBis: String
    $buchungsdatumVon: String
    $employeeApproval: EmployeeApproval
    $erstellerMitarbeiterIdList: [String!]
    $invoiceDateFrom: String
    $invoiceDateTo: String
    $noted: Boolean
    $objektIdList: [String!]
    $payeeIban: String
    $payeeId: String
    $paymentApprovalStatusList: [PaymentApprovalStatus!]
    $paymentDateFrom: String
    $paymentDateTo: String
    $paymentProposalId: String
    $paymentTransactionType: PaymentTransactionType
    $paymentTypeList: [PaymentType!]
    $rechnungsAusstellerFibuKontoId: String
    $rechnungsAusstellerIban: String
    $rechnungsAusstellerId: String
    $sepaCreditorId: String
    $sepaMandateReference: String
    $statusList: [OrderPaymentStatus!]
    $purposeOfUseText: String
    $paymentReferenceText: String
  ) {
    getPaymentListSummary(
      assignedToPaymentProposal: $assignedToPaymentProposal
      belegnummer: $belegnummer
      amountTo: $amountTo
      amountFrom: $amountFrom
      buchungsdatumBis: $buchungsdatumBis
      buchungsdatumVon: $buchungsdatumVon
      employeeApproval: $employeeApproval
      invoiceDateFrom: $invoiceDateFrom
      invoiceDateTo: $invoiceDateTo
      erstellerMitarbeiterIdList: $erstellerMitarbeiterIdList
      noted: $noted
      objektIdList: $objektIdList
      payeeIban: $payeeIban
      payeeId: $payeeId
      paymentApprovalStatusList: $paymentApprovalStatusList
      paymentDateFrom: $paymentDateFrom
      paymentDateTo: $paymentDateTo
      paymentProposalId: $paymentProposalId
      paymentTransactionType: $paymentTransactionType
      paymentTypeList: $paymentTypeList
      rechnungsAusstellerFibuKontoId: $rechnungsAusstellerFibuKontoId
      rechnungsAusstellerIban: $rechnungsAusstellerIban
      rechnungsAusstellerId: $rechnungsAusstellerId
      sepaCreditorId: $sepaCreditorId
      sepaMandateReference: $sepaMandateReference
      statusList: $statusList
      purposeOfUseText: $purposeOfUseText
      paymentReferenceText: $paymentReferenceText
    ) {
      data {
        sumAmount
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function usePaymentListSummaryQuery(baseOptions?: Apollo.QueryHookOptions<PaymentListSummaryQuery, PaymentListSummaryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentListSummaryQuery, PaymentListSummaryQueryVariables>(PaymentListSummaryDocument, options);
}
export function usePaymentListSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentListSummaryQuery, PaymentListSummaryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentListSummaryQuery, PaymentListSummaryQueryVariables>(PaymentListSummaryDocument, options);
}
export function usePaymentListSummarySuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PaymentListSummaryQuery, PaymentListSummaryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaymentListSummaryQuery, PaymentListSummaryQueryVariables>(PaymentListSummaryDocument, options);
}
export type PaymentListSummaryQueryHookResult = ReturnType<typeof usePaymentListSummaryQuery>;
export type PaymentListSummaryLazyQueryHookResult = ReturnType<typeof usePaymentListSummaryLazyQuery>;
export type PaymentListSummarySuspenseQueryHookResult = ReturnType<typeof usePaymentListSummarySuspenseQuery>;
export type PaymentListSummaryQueryResult = Apollo.QueryResult<PaymentListSummaryQuery, PaymentListSummaryQueryVariables>;
