import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../../security/AuthRoute';
import { isAdmin } from '../../../../../security/permissionChecks';
import AndromedaSysSettingsMahndefinitionPage from './AndromedaSysSettingsMahndefinitionPage';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../../constants/andromedaSystemSettingsUriPaths';

const andromedaSysSettingsMahndefinitionPageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS.mahndefinitionPage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsMahndefinitionPage />} />}
  />
);

export default andromedaSysSettingsMahndefinitionPageRoute;
