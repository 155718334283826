import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { RepFondsAbrechnungDetails } from '../../../../../topAbrechnung-types';
import TARepFondsAbrechnungListTitleText from '../../../TARepFondsAbrechnungListTitleText';
import TARepFondsAbrechnungAusgabenKontoList from './TARepFondsAbrechnungAusgabenKontoList';
import TARepFondsAbrSumme from '../../TARepFondsAbrSumme';

const TARepFondsAbrAusgabenBlock: FC<{ details: RepFondsAbrechnungDetails }> = ({ details }) => (
  <View>
    {/* Ausgaben konto list title */}
    <TARepFondsAbrechnungListTitleText listTitleText={details.ausgabenKontoListText} />

    {/* Ausgaben konto list: */}
    <TARepFondsAbrechnungAusgabenKontoList ausgabenKontoList={details.ausgabenKontoList} />

    {/* Ausgaben gesamt */}
    <TARepFondsAbrSumme summe={details.ausgabenNettoGesamt} />
  </View>
);

export default TARepFondsAbrAusgabenBlock;
