import { rootUrlMitarbeiter } from '../../shared/api/ApiPaths';
import { processAndReturnError, processResponse, returnData } from '../../helpers/APIUtils';
import URLBuilder from '../../helpers/URLBuilder';
import Fetcher from '../../helpers/fetcher';

class MitarbeiterApi {
  static getMitarbeiterListWithUserDetails(firmendatenId, email) {
    const url = new URLBuilder(rootUrlMitarbeiter(firmendatenId))
      .withQueryParam('includeUser', true)
      .withQueryParamOptional('userEmail', email)
      .build();
    return Fetcher.getInstance()
      .fetch(url, {
        method: 'GET',
        credentials: 'same-origin',
      })
      .then(processResponse)
      .then(returnData)
      .catch(processAndReturnError);
  }
}

export default MitarbeiterApi;
