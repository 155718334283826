import React, { FC } from 'react';
import { Avatar, List, Space } from 'antd';
import { Link } from 'react-router-dom';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { generatePathToRechtstraegerDetailsPage } from '../Rechtstraeger/rechtstraegerHelper';
import { toTitleCase } from '../../helpers/nameHelper';
import ContactList from './ContactList';
import { RechtstraegerContactListEntry } from '../../types';
import { useToggle } from '../../hooks/useToggle';
import theme from '../../theme';

type Props = {
  rechtstraeger: RechtstraegerContactListEntry;
};

const RechtstraegerContactListItem: FC<Props> = ({ rechtstraeger }) => {
  const [isOpen, toggleContactList] = useToggle();

  return (
    <List.Item key={rechtstraeger.rechtstraegerId}>
      <List.Item.Meta
        avatar={
          <Link to={generatePathToRechtstraegerDetailsPage(rechtstraeger.rechtstraegerId)} target="_blank">
            <Avatar shape="circle" size="large" style={{ backgroundColor: 'grey' }}>
              {toTitleCase(rechtstraeger.kurzBezeichnung)}
            </Avatar>
          </Link>
        }
        title={
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <Link
              to={generatePathToRechtstraegerDetailsPage(rechtstraeger.rechtstraegerId)}
              target="_blank"
              style={{ color: theme.linkColor.primary }}
            >
              {rechtstraeger.kurzBezeichnung}
            </Link>
            <>{isOpen ? <UpOutlined onClick={toggleContactList} /> : <DownOutlined onClick={toggleContactList} />}</>
          </Space>
        }
        description={<ContactList rechtstraegerId={rechtstraeger.rechtstraegerId} contactList={rechtstraeger.contactList} isOpen={isOpen} />}
      />
    </List.Item>
  );
};

export default RechtstraegerContactListItem;
