import React from 'react';
import { Space } from 'antd';
import { isNotNil } from '../../../../helpers/assertionHelper';

const tooltipFooterContent = (content: React.ReactNode | React.ReactNode[]) => {
  const contents = (Array.isArray(content) ? content : [content]).filter(isNotNil);

  return contents.length > 1
    ? contents.map((content, index) => (
        <Space key={index} style={{ display: 'flex', width: '100%' }}>
          {content}
        </Space>
      ))
    : contents[0];
};

export default tooltipFooterContent;
