import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { AppGenerierlaufType } from '../../../../../types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import { useFilterGenLaufAppWeRechnungsAusstellerListQuery } from '../../../gql/GenerierlaufFilter/AppGenerierlaufFilterQueries.types';

type Props = {
  name: string;
  generierlaufId: string;
  generierlaufType: AppGenerierlaufType;
  maxTextLength?: number;
} & SelectProps;

const GenerierlaufWeRechnungsAusstellerFormSelect: FC<Props> = ({ name, generierlaufId, generierlaufType, maxTextLength, ...restProps }) => {
  const { data, loading } = useFilterGenLaufAppWeRechnungsAusstellerListQuery({
    variables: {
      generierlaufId,
      generierlaufType,
    },
  });

  const list = data?.getFilterGenLaufAppWeRechnungsAusstellerList.data ?? [];

  return (
    <Select
      size="small"
      loading={loading}
      id={name}
      name={name}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      placeholder="WE-Rechnungsaussteller auswählen"
      {...restProps}
    >
      {list.map((rechnungsaussteller) => (
        <Select.Option key={rechnungsaussteller.rechtstraegerId} value={rechnungsaussteller.rechtstraegerId}>
          <DataWithShortenedText
            maxTextLength={maxTextLength ?? rechnungsaussteller.kurzBezeichnung.length}
            text={rechnungsaussteller.kurzBezeichnung}
          />
        </Select.Option>
      ))}
    </Select>
  );
};

export default GenerierlaufWeRechnungsAusstellerFormSelect;
