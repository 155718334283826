import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RestartInfoMailDeliverySendGenerierlaufMutationVariables = Types.Exact<{
  generierlaufId: Types.Scalars['ID']['input'];
}>;

export type RestartInfoMailDeliverySendGenerierlaufMutation = {
  actionRestartInfoMailDeliverySendGenerierlauf: {
    data: { generierlaufId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RestartInfoMailDeliverySendGenerierlaufDocument = gql`
  mutation RestartInfoMailDeliverySendGenerierlauf($generierlaufId: ID!) {
    actionRestartInfoMailDeliverySendGenerierlauf(generierlaufId: $generierlaufId) {
      data {
        generierlaufId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRestartInfoMailDeliverySendGenerierlaufMutation(
  baseOptions?: Apollo.MutationHookOptions<RestartInfoMailDeliverySendGenerierlaufMutation, RestartInfoMailDeliverySendGenerierlaufMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestartInfoMailDeliverySendGenerierlaufMutation, RestartInfoMailDeliverySendGenerierlaufMutationVariables>(
    RestartInfoMailDeliverySendGenerierlaufDocument,
    options
  );
}
export type RestartInfoMailDeliverySendGenerierlaufMutationHookResult = ReturnType<typeof useRestartInfoMailDeliverySendGenerierlaufMutation>;
export type RestartInfoMailDeliverySendGenerierlaufMutationResult = Apollo.MutationResult<RestartInfoMailDeliverySendGenerierlaufMutation>;
export type RestartInfoMailDeliverySendGenerierlaufMutationOptions = Apollo.BaseMutationOptions<
  RestartInfoMailDeliverySendGenerierlaufMutation,
  RestartInfoMailDeliverySendGenerierlaufMutationVariables
>;
