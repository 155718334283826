import React from 'react';
import { Route } from 'react-router';
import URI_SYS_SETTINGS_MAHNDEFINITION_PAGE from '../../../../../features/Mahndefinition/mahndefinitonUriPaths';
import AuthRoute from '../../../../../security/AuthRoute';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../../security/permissionChecks';
import SysSettingsMahndefinitionCreatePage from './SysSettingsMahndefinitionCreatePage';

const sysSettingsMahndefinitionCreatePageRoute = (
  <Route
    path={URI_SYS_SETTINGS_MAHNDEFINITION_PAGE.createPage}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsMahndefinitionCreatePage />} />}
  />
);

export default sysSettingsMahndefinitionCreatePageRoute;
