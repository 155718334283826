import React, { FC } from 'react';
import { View } from '@react-pdf/renderer';
import { FPAufwandSumExpectedCostBlockData } from '../../Objekt/financialPlanObjekt-types';
import LabelValue from '../../../shared/LabelValue';

type Props = {
  aufwandSumExpectedCostBlock: FPAufwandSumExpectedCostBlockData;
  width?: string;
};

const FPAufwandSumExpectedCostBlock: FC<Props> = ({ aufwandSumExpectedCostBlock, width }) => (
  <View style={{ width, marginTop: '5mm' }}>
    <LabelValue valueTuple={aufwandSumExpectedCostBlock.sumAufwand} />
    <LabelValue valueTuple={aufwandSumExpectedCostBlock.expectedCostIncrease} />
  </View>
);

export default FPAufwandSumExpectedCostBlock;
