import React from 'react';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import { DatePicker } from 'formik-antd';
import { friendlyFormatIBAN } from 'ibantools';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { EuroAmount } from '../../../../../components/Number';
import { MitarbeiterTooltip } from '../../../../../components/Card';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';
import { generatePathToObjektDetailsPage } from '../../../../Objekt/objektUriPaths';
import PaymentStatusColumn from './PaymentStatusColumn';
import PaymentListingTableActions from './PaymentListingTableActions';
import KontoLinkToKontoblatt from '../../../../../shared/components/Konto/KontoLinkToKontoblatt';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import { PaymentListEntry, PaymentOrderBy } from '../../../../../types';
import { isPaymentTransactionTypeEbics, isPaymentTypeAusgangsrechnung } from '../../../paymentHelpers';
import PaymentMitarbeiterApprovalColumn from '../../../shared/PaymentMitarbeiterApprovalColumn';
import LinkToBelegFile from '../../../../../components/Link/LinkToBelegFile/LinkToBelegFile';
import { fontWeightForNotedRecord, TextAndFontWeightForNotedRecord } from '../../../shared/TextAndFontWeightForNotedRecord';
import FormattedDecimalFormInput from '../../../../../components/Input-antd/FormattedDecimalFormInput';
import { EditableTableColumnProps } from '../../../../../components/Table/EditableTableOld/EditableTableProps';
import { paymentUpdateFormFields, PaymentUpdateFormValues } from './Edit/paymentUpdateMapper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import ActionButtonsInEditMode from '../../../../../components/Table/EditableTableOld/ActionButtonsInEditMode';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../../components/DatePicker/DatePicker_formikAntD';
import CustomFormattedDate from '../../../../../components/FormattedDate/CustomFormattedDate';
import RechtstraegerWithContactsTooltip from '../../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

const paymentListingTableColumns = (onSuccess: () => void): EditableTableColumnProps<PaymentListEntry, PaymentUpdateFormValues>[] => [
  {
    title: 'Zahlungstyp',
    defaultSelected: true,
    dataIndex: PaymentOrderBy.PaymentType,
    sorter: true,
    render: (text, record) => (
      <Space>
        {isPaymentTypeAusgangsrechnung(record.paymentType.value) ? (
          <ArrowDownOutlined style={{ color: 'green' }} />
        ) : (
          <ArrowUpOutlined style={{ color: 'red' }} />
        )}
        <TextAndFontWeightForNotedRecord noted={record.noted} content={record.paymentType.text} />
      </Space>
    ),
  },
  {
    title: 'Zahlungsvorschlag',
    defaultSelected: true,
    dataIndex: PaymentOrderBy.PaymentProposal,
    sorter: true,
    render: (text, record) =>
      record.paymentProposal ? (
        <DataWithShortenedText maxTextLength={20} text={record.paymentProposal} fontWeight={fontWeightForNotedRecord(record.noted)} />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />
      ),
  },
  {
    title: 'Belegnummer',
    defaultSelected: true,
    dataIndex: PaymentOrderBy.Belegnummer,
    sorter: true,
    render: (text, record) => {
      if (record.belegnummer) {
        return record.belegFileId ? (
          <LinkToBelegFile fileId={record.belegFileId} belegnummer={record.belegnummer} fontWeight={fontWeightForNotedRecord(record.noted)} />
        ) : (
          <TextAndFontWeightForNotedRecord noted={record.noted} content={record.belegnummer} />
        );
      }
      return <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />;
    },
  },
  {
    title: 'Belegdatum',
    defaultSelected: true,
    dataIndex: PaymentOrderBy.Belegdatum,
    sorter: true,
    render: (text, record) =>
      record.belegdatum ? (
        <TextAndFontWeightForNotedRecord noted={record.noted} content={<CustomFormattedDate value={record.belegdatum} />} />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />
      ),
  },
  {
    title: 'Zahlungsverkehrsart',
    defaultSelected: true,
    dataIndex: PaymentOrderBy.PaymentTransactionType,
    sorter: true,
    render: (text, record) =>
      record.paymentTransactionType ? (
        <TextAndFontWeightForNotedRecord noted={record.noted} content={record.paymentTransactionType.text} />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />
      ),
  },
  {
    title: 'Gesehen',
    defaultSelected: true,
    dataIndex: PaymentOrderBy.Noted,
    sorter: true,
    render: (text, record) => <TextAndFontWeightForNotedRecord noted={record.noted} content={record.noted ? 'Gesehen' : 'Neu'} />,
  },
  {
    title: 'Objekt',
    defaultSelected: true,
    dataIndex: PaymentOrderBy.Objekt,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={20} text={record.objekt.kurzBezeichnung}>
        {(shortenedText) => (
          <Link
            target="_blank"
            to={generatePathToObjektDetailsPage(record.objekt.objektId)}
            style={{ fontWeight: fontWeightForNotedRecord(record.noted) }}
          >
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Freigabe Status',
    defaultSelected: true,
    dataIndex: PaymentOrderBy.PaymentApprovalStatus,
    sorter: true,
    render: (text, record) =>
      record.paymentApprovalStatus ? (
        <TextAndFontWeightForNotedRecord noted={record.noted} content={record.paymentApprovalStatus?.text} />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />
      ),
  },
  {
    title: 'Freigabe Mitarbeiter',
    defaultSelected: true,
    sorter: false,
    render: (text, record) =>
      // The tooltip with approval list should be shown only if the payment is an EBICS payment -> EC-13327
      !isPaymentTransactionTypeEbics(record.paymentTransactionType?.value) || record.approvalList.length === 0 ? (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />
      ) : (
        <PaymentMitarbeiterApprovalColumn approvalList={record.approvalList} fontWeight={fontWeightForNotedRecord(record.noted)} />
      ),
  },
  {
    title: 'SEPA Mandatsreferenz',
    defaultSelected: true,
    dataIndex: PaymentOrderBy.SepaMandateReference,
    sorter: true,
    render: (text, record) =>
      record.sepaMandatReference ? (
        <TextAndFontWeightForNotedRecord noted={record.noted} content={record.sepaMandatReference} />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />
      ),
  },
  {
    title: 'SEPA Creditor-ID',
    defaultSelected: true,
    dataIndex: PaymentOrderBy.SepaCreditorId,
    sorter: true,
    render: (text, record) =>
      record.sepaCreditorId ? (
        <TextAndFontWeightForNotedRecord noted={record.noted} content={record.sepaCreditorId} />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />
      ),
  },

  {
    title: 'Buchungskreis',
    defaultSelected: true,
    dataIndex: PaymentOrderBy.RechnungsAussteller,
    sorter: true,
    render: (text, record) => (
      <RechtstraegerWithContactsTooltip
        rechtstraegerId={record.rechnungsAussteller.rechtstraegerId}
        rechtstraegerName={record.rechnungsAussteller.kurzBezeichnung}
        maxTextLength={20}
        fontWeight={fontWeightForNotedRecord(record.noted)}
      />
    ),
  },
  {
    title: 'Fälligkeit',
    defaultSelected: true,
    dataIndex: PaymentOrderBy.DueDate,
    sorter: true,
    render: (text, record) =>
      record.dueDate ? (
        <TextAndFontWeightForNotedRecord noted={record.noted} content={<CustomFormattedDate value={record.dueDate} />} />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />
      ),
  },
  {
    title: 'Zahlungsdatum',
    defaultSelected: true,
    dataIndex: PaymentOrderBy.PaymentDate,
    sorter: true,
    editable: true,
    render: (text, record) => <TextAndFontWeightForNotedRecord noted={record.noted} content={<CustomFormattedDate value={record.paymentDate} />} />,
    renderInEditMode: ({ selectedRecord }) => (
      <FormItemWithoutColon name={paymentUpdateFormFields.paymentDate} style={{ width: '100%', marginBottom: '0px' }}>
        <DatePicker
          id={paymentUpdateFormFields.paymentDate}
          name={paymentUpdateFormFields.paymentDate}
          placeholder="Zahlungsdatum auswählen"
          allowClear={false}
          format={DATE_DISPLAY_FORMAT_DEFAULT}
        />
      </FormItemWithoutColon>
    ),
  },
  {
    title: 'Buchungskreis Bankverb.',
    defaultSelected: true,
    dataIndex: PaymentOrderBy.RechnungsAusstellerIban,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText
        maxTextLength={25}
        text={`${record.rechnungsAusstellerAccountHolder} - ${friendlyFormatIBAN(record.rechnungsAusstellerIban)}`}
        fontWeight={fontWeightForNotedRecord(record.noted)}
      />
    ),
  },
  {
    title: 'Zahlender/Zahlungsempfänger',
    defaultSelected: true,
    dataIndex: PaymentOrderBy.Payee,
    sorter: true,
    render: (text, record) => (
      <RechtstraegerWithContactsTooltip
        rechtstraegerId={record.payee.rechtstraegerId}
        rechtstraegerName={record.payee.kurzBezeichnung}
        maxTextLength={20}
        fontWeight={fontWeightForNotedRecord(record.noted)}
      />
    ),
  },
  {
    title: 'Zahlender/Zahlungsempfänger Bankverb.',
    defaultSelected: true,
    dataIndex: PaymentOrderBy.PayeeIban,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText
        maxTextLength={20}
        text={`${record.payeeAccountHolder} - ${friendlyFormatIBAN(record.payeeIban)}`}
        fontWeight={fontWeightForNotedRecord(record.noted)}
      />
    ),
  },
  {
    title: 'Betrag',
    defaultSelected: true,
    dataIndex: PaymentOrderBy.Amount,
    sorter: true,
    align: 'right',
    editable: true,
    render: (text, record) => <TextAndFontWeightForNotedRecord noted={record.noted} content={<EuroAmount value={record.amount} />} />,
    renderInEditMode: ({ selectedRecord }) => (
      <FormItemWithoutColon name={paymentUpdateFormFields.amount} style={{ width: '100%', marginBottom: '0px' }}>
        <FormattedDecimalFormInput name={paymentUpdateFormFields.amount} isCurrency />
      </FormItemWithoutColon>
    ),
  },
  {
    title: 'Verwendungszweck',
    dataIndex: PaymentOrderBy.Verwendungszweck,
    sorter: true,
    render: (text, record) =>
      record.verwendungszweck ? (
        <TextAndFontWeightForNotedRecord noted={record.noted} content={record.verwendungszweck} />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />
      ),
  },
  {
    title: 'Zahlungsreferenz',
    dataIndex: PaymentOrderBy.PaymentReferenceText,
    sorter: true,
    render: (text, record) =>
      record.paymentReferenceText ? (
        <TextAndFontWeightForNotedRecord noted={record.noted} content={record.paymentReferenceText} />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />
      ),
  },
  {
    title: 'Fibu-Bankkonto',
    defaultSelected: true,
    dataIndex: PaymentOrderBy.RechnungsAusstellerFibuBankAccount,
    sorter: true,
    width: 200,
    render: (text, record) => (
      <KontoLinkToKontoblatt
        maxBezeichnungLength={15}
        konto={{
          kontoId: record.rechnungsAusstellerFibuKonto.kontoId,
          nummer: record.rechnungsAusstellerFibuKonto.nummer,
          bezeichnung: record.rechnungsAusstellerFibuKonto.bezeichnung,
        }}
        buchungsdatumFrom={record.bookingDate}
        objektId={record.objekt.objektId}
        fontWeight={fontWeightForNotedRecord(record.noted)}
      />
    ),
  },
  {
    title: 'Buchungsdatum',
    defaultSelected: true,
    dataIndex: PaymentOrderBy.Buchungsdatum,
    sorter: true,
    render: (text, record) =>
      record.bookingDate ? (
        <TextAndFontWeightForNotedRecord noted={record.noted} content={<CustomFormattedDate value={record.bookingDate} />} />
      ) : (
        <TextForEmptyValue textToShow="minus" fontWeight={fontWeightForNotedRecord(record.noted)} />
      ),
  },
  {
    title: 'Zahlung Status',
    defaultSelected: true,
    dataIndex: PaymentOrderBy.Status,
    sorter: true,
    render: (text, record) => <PaymentStatusColumn payment={record} fontWeightOfTagText={fontWeightForNotedRecord(record.noted)} />,
  },
  {
    title: 'Erstellt am',
    dataIndex: PaymentOrderBy.CreateTs,
    sorter: true,
    render: (text, record) => <TextAndFontWeightForNotedRecord noted={record.noted} content={<FormattedDateTime createdTs={record.createTs} />} />,
  },
  {
    title: 'Ersteller',
    dataIndex: PaymentOrderBy.CreatedBy,
    sorter: true,
    render: (text, record) => (
      <MitarbeiterTooltip
        mitarbeiterId={record.createdByMitarbeiterId}
        userId={record.createdBy}
        showsInitials
        alignment="left"
        fontWeight={fontWeightForNotedRecord(record.noted)}
      />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    editable: true,
    render: (text, record) => <PaymentListingTableActions payment={record} onSuccess={onSuccess} />,
    renderInEditMode: ({ cancelEdit, isSubmitLoading }) => <ActionButtonsInEditMode cancelEdit={cancelEdit} isSubmitLoading={isSubmitLoading} />,
  },
];

export default paymentListingTableColumns;
