import React, { FC } from 'react';
import { Modal } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import { Form } from 'formik-antd';
import {
  mapFormValuesToZinslisteCreateInput,
  zinslisteFormFields,
  zinslisteFormInitialValues,
  ZinslisteFormValues,
} from './zinslisteCreateFormMapper';
import FormButtons from '../../../../components/Button/FormButtons';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { useCreateZinslisteGenerierlaufMutation } from '../../../Verarbeitung/gql/ZinslisteGenerierlauf/ZinslisteGenerierlaufMutations.types';
import { showSuccessMsgOther } from '../../../../components/message';
import ObjektSelectWithAllTag from '../../../../shared/ObjektSelect/ObjektSelectWithAllTag';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import { zinslisteCreateValidationSchema } from './zinslisteCreateValidationSchema';
import RangePicker from '../../../../components/DatePicker/RangePicker/RangePicker';

type Props = {
  onCreate: () => void;
  onCancel: () => void;
  visible: boolean;
};
const ZinslisteCreateModal: FC<Props> = ({ onCreate, onCancel, visible }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'ZinslisteGenerierlauf'>('ZinslisteGenerierlauf');

  const [runCreate, { loading }] = useCreateZinslisteGenerierlaufMutation({
    onCompleted: (data) => {
      const { vorschreibungsperiodeList } = data.createZinslisteGenerierlauf.data;
      const title = `Periode ${vorschreibungsperiodeList[0].jahr}/${vorschreibungsperiodeList[0].monat} - ${
        vorschreibungsperiodeList[vorschreibungsperiodeList.length - 1].jahr
      }/${vorschreibungsperiodeList[vorschreibungsperiodeList.length - 1].monat}`;

      showSuccessMsgOther(`Zinslisten für ${title} werden angelegt.`);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const handleCreate = (values: ZinslisteFormValues, actions: FormikHelpers<ZinslisteFormValues>) => {
    const input = mapFormValuesToZinslisteCreateInput(values);
    runCreate({ variables: { input } })
      .then(onCreate)
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <Modal title="Zinsliste erstellen" open={visible} footer={null} onCancel={onCancel} destroyOnClose maskClosable={false}>
      <Formik<ZinslisteFormValues>
        initialValues={zinslisteFormInitialValues}
        validationSchema={zinslisteCreateValidationSchema}
        onSubmit={handleCreate}
      >
        {(formikProps) => (
          <Form layout="vertical">
            <FormItemWithFieldHelp
              name={zinslisteFormFields.objektIdList}
              label="Objekt"
              fieldHelp={getFieldHelpText('ZinslisteGenerierlauf.objektIdList')}
            >
              <ObjektSelectWithAllTag name={zinslisteFormFields.objektIdList} formikProps={formikProps} />
            </FormItemWithFieldHelp>
            <FormItemWithFieldHelp
              name={zinslisteFormFields.vorschreibungsperiodeList}
              label="Vorschreibungsperiode"
              fieldHelp={getFieldHelpText('ZinslisteGenerierlauf.vorschreibungsperiodeList')}
            >
              <RangePicker name={zinslisteFormFields.vorschreibungsperiodeList} style={{ width: '100%' }} picker="month" />
            </FormItemWithFieldHelp>
            <FormButtons updateMode={false} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} isOkLoading={loading} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ZinslisteCreateModal;
