import React from 'react';
import { Link } from 'react-router-dom';
import { TableWithAlignedColsExpandedRowType } from '../../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { createCells, createRowWithCells, TextWithStyling } from '../../../shared/abrechnungTableHelper';
import { StyledObjAbrKontoAusgabenDetailsTable } from '../../Styled/ObjObjektAbrechnungTable.style';
import DataWithShortenedText from '../../../../../../components/Helpers/DataWithShortenedText';
import { EuroAmount, Percent } from '../../../../../../components/Number';
import { ObjAbrKonto } from '../../../../../../types';
import SollHabenTag from '../../../../../shared/SollHabenTag';
import { generatePathToBookingDetailsPage } from '../../../../../BookingDetails/bookingDetailsPaths';

const headerTexts: TextWithStyling[] = [
  { render: 'Text' },
  { render: 'Ust. vom Aufwand %' },
  { render: 'Ausgaben' },
  { render: 'Soll/Haben' },
  { render: '' },
];

const ustVomAufwandKontoTableExpandedRow: TableWithAlignedColsExpandedRowType<ObjAbrKonto>[] = [
  {
    header: () => createCells(headerTexts, true),
    table: {
      colSpanSize: [8],
      StyledComponent: StyledObjAbrKontoAusgabenDetailsTable,
      positionOfButton: 2,
      showCellButton: false,
    },
    body: (konto) => (
      <>
        {konto.buchungszeileList.map((buchungszeile) => {
          const texts: TextWithStyling[] = [
            { render: <DataWithShortenedText text={buchungszeile.text} maxTextLength={18} />, style: { justifyContent: 'left', textAlign: 'left' } },
            {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              render: <Percent value={buchungszeile.ustVomAufwandSteuersatz! / 100} />,
            },
            {
              render: (
                <Link target="_blank" to={generatePathToBookingDetailsPage(buchungszeile.buchungIdList)}>
                  <EuroAmount value={buchungszeile.betrag} />
                </Link>
              ),
            },
            { render: <SollHabenTag sollHaben={buchungszeile.sollHaben} /> },
            { render: '' },
          ];
          return createRowWithCells(texts, false);
        })}
      </>
    ),
  },
];

export default ustVomAufwandKontoTableExpandedRow;
