import { NotificationStatus, NotificationType } from '../../../types';
import { capitalizeString } from '../../../helpers/stringHelper';
import { NotificationStatusSpecs } from '../NotificationArea/notificationTypes';

const NOTIFICATION_TYPE_APPLY_VERTRAG_VPOS_INDEXED_VALUES = 'Indexierte Werte anwenden';
const NOTIFICATION_TYPE_AUFTRAG_ERSTELLUNG_TEXT = 'Vorschreibungen erstellen';
const NOTIFICATION_TYPE_AUFTRAG_VERBUCHEN_TEXT = 'Vorschreibungen verbuchen';
const NOTIFICATION_TYPE_BELEG_AUSGABE_TEXT = 'Vorschreibungen ausgeben';
const NOTIFICATION_TYPE_BK_OBJEKT_ABRECHNUNG = 'BK-Objektabrechnung';
const NOTIFICATION_TYPE_BK_OBJEKT_ABRECHNUNG_ERSTELLUNG = 'BK-Objektabrechnung erstellen';
const NOTIFICATION_TYPE_BK_TOP_ABRECHNUNG = 'BK-Top-Abrechnung';
const NOTIFICATION_TYPE_BOOKING_SUGGESTION_CREATION = 'Buchungsvorschläge erstellen';
const NOTIFICATION_TYPE_BUDGETING = 'Budgetierung Erstellen';
const NOTIFICATION_TYPE_DATA_CARRIER_PAIN_CREATION = 'Datenträger erstellen und über EBICS versenden';
const NOTIFICATION_TYPE_HE_ABRECHNUNG_ERSTELLUNG = 'HE-Abrechnung erstellen';
const NOTIFICATION_TYPE_INFOMAIL_DELIVERY_DETERMINERECIPIENT_LIST = 'Infoschreiben Empfänger ermitteln';
const NOTIFICATION_TYPE_INFOMAIL_DELIVERY_SEND = 'Infoschreiben versenden';
const NOTIFICATION_TYPE_MAHNUNG_ERSTELLUNG = 'Mahnungen erstellen';
const NOTIFICATION_TYPE_OBJEKT_FINANCIAL_PLAN_ERSTELLUNG = 'Objekt-Wirtschaftsplan ausgeben';
const NOTIFICATION_TYPE_PAYMENT_PROPOSAL_CREATION_TEXT = 'Zahlungsvorschlag erstellen';
const NOTIFICATION_TYPE_PAYMENT_PROPOSAL_EXECUTION_TEXT = 'Zahlungsvorschlag durchführen';
const NOTIFICATION_TYPE_SEPA_LASTSCHRIFT_MANDAT = 'SEPA-Lastschrift-Mandat';
const NOTIFICATION_TYPE_TOP_FINANCIAL_PLAN_ERSTELLUNG = 'Top-Wirtschaftsplan ausgeben';
const NOTIFICATION_TYPE_UST_VOM_AUFWAND_ERSTELLUNG_TEXT = 'Ust. vom Aufwand erstellen';
const NOTIFICATION_TYPE_VERTRAG_VPOS_INDEXED_VALUES_ERSTELLUNG = 'Indexierte Werte erstellen';
const NOTIFICATION_TYPE_VORSCHREIBUNG_MAIL_DELIVERY_DETERMINE_RECIPIENT_LIST = 'Vorschreibungen Empfänger ermitteln';
const NOTIFICATION_TYPE_VORSCHREIBUNG_MAIL_DELIVERY_SEND = 'Vorschreibungen versenden';
const NOTIFICATION_TYPE_VORSTEUERKUERZUNG_ERSTELLUNG = 'Vorsteuerkürzung erstellen';
const NOTIFICATION_TYPE_WE_OBJEKT_ABRECHNUNG = 'WE-Objektabrechnung';
const NOTIFICATION_TYPE_WE_OBJEKT_ABRECHNUNG_ERSTELLUNG = 'WE-Objektabrechnung erstellen';
const NOTIFICATION_TYPE_WE_TOP_ABRECHNUNG = 'WE-Top-Abrechnung';
const NOTIFICATION_TYPE_ZINSLISTE_ERSTELLUNG = 'Zinslisten erstellen';
const NOTIFICATION_TYPE_CAMT_EBICS_MANUALLY = 'Kontoauszug über EBICS importieren';
const NOTIFICATION_TYPE_CAMT_ONLINE_BANKING_IMPORT_MANUALLY = 'Kontoauszug über Onlinebanking importieren';
const NOTIFICATION_TYPE_INCOMING_INVOICE_BOOKING_CREATION = 'Eingangsrechnung verbuchen';
const NOTIFICATION_TYPE_ZAHLUNGSVORSCHLAG_VERBUCHEN = 'Zahlungsvorschlag verbuchen';
const NOTIFICATION_TYPE_EMPFANGENE_BANKBUCHUNGEN_VERBUCHEN = 'Empf.Bankbuchungen verbuchen';
const NOTIFICATION_TYPE_FIRMENDATEN_INITIALIZER = 'Firmendaten initialisieren';
const NOTIFICATION_TYPE_HE_ABRECHNUNG = 'HE-Abrechnung';
const NOTIFICATION_TYPE_SUB_ABRECHNUNG_ERSTELLUNG = 'SUB-Abrechnung erstellen';

export const isNotificationTypeBuchungsanweisung = (type: NotificationType) => type === NotificationType.Buchungsanweisung;
export const isNotificationStatusFinishedError = (status: NotificationStatus) => status === NotificationStatus.FinishedError;

export const capitalizedNotificationType = (notificationType: NotificationType | 'FILE_UPLOAD') => {
  switch (notificationType) {
    case NotificationType.Budgetierung:
      return NOTIFICATION_TYPE_BUDGETING;
    case NotificationType.Auftragserstellung:
      return NOTIFICATION_TYPE_AUFTRAG_ERSTELLUNG_TEXT;
    case NotificationType.AuftragVerbuchen:
      return NOTIFICATION_TYPE_AUFTRAG_VERBUCHEN_TEXT;
    case NotificationType.Belegausgabe:
      return NOTIFICATION_TYPE_BELEG_AUSGABE_TEXT;
    case NotificationType.DataCarrierPainCreation:
      return NOTIFICATION_TYPE_DATA_CARRIER_PAIN_CREATION;
    case NotificationType.InfoMailDeliveryDetermineRecipientList:
      return NOTIFICATION_TYPE_INFOMAIL_DELIVERY_DETERMINERECIPIENT_LIST;
    case NotificationType.InfoMailDeliverySend:
      return NOTIFICATION_TYPE_INFOMAIL_DELIVERY_SEND;
    case NotificationType.VorschreibungMailDeliveryDetermineRecipientList:
      return NOTIFICATION_TYPE_VORSCHREIBUNG_MAIL_DELIVERY_DETERMINE_RECIPIENT_LIST;
    case NotificationType.VorschreibungMailDeliverySend:
      return NOTIFICATION_TYPE_VORSCHREIBUNG_MAIL_DELIVERY_SEND;
    case NotificationType.HeAbrechnungErstellung:
      return NOTIFICATION_TYPE_HE_ABRECHNUNG_ERSTELLUNG;
    case NotificationType.BkObjektAbrechnung:
      return NOTIFICATION_TYPE_BK_OBJEKT_ABRECHNUNG;
    case NotificationType.BkObjektAbrechnungErstellung:
      return NOTIFICATION_TYPE_BK_OBJEKT_ABRECHNUNG_ERSTELLUNG;
    case NotificationType.WeObjektAbrechnung:
      return NOTIFICATION_TYPE_WE_OBJEKT_ABRECHNUNG;
    case NotificationType.WeObjektAbrechnungErstellung:
      return NOTIFICATION_TYPE_WE_OBJEKT_ABRECHNUNG_ERSTELLUNG;
    case NotificationType.BkTopAbrechnung:
      return NOTIFICATION_TYPE_BK_TOP_ABRECHNUNG;
    case NotificationType.WeTopAbrechnung:
      return NOTIFICATION_TYPE_WE_TOP_ABRECHNUNG;
    case NotificationType.VorsteuerkuerzungErstellung:
      return NOTIFICATION_TYPE_VORSTEUERKUERZUNG_ERSTELLUNG;
    case NotificationType.MahnungErstellung:
      return NOTIFICATION_TYPE_MAHNUNG_ERSTELLUNG;
    case NotificationType.PaymentProposalCreation:
      return NOTIFICATION_TYPE_PAYMENT_PROPOSAL_CREATION_TEXT;
    case NotificationType.PaymentProposalExecution:
      return NOTIFICATION_TYPE_PAYMENT_PROPOSAL_EXECUTION_TEXT;
    case NotificationType.SepaLastschriftMandat:
      return NOTIFICATION_TYPE_SEPA_LASTSCHRIFT_MANDAT;
    case NotificationType.UstVomAufwandErstellung:
      return NOTIFICATION_TYPE_UST_VOM_AUFWAND_ERSTELLUNG_TEXT;
    case NotificationType.ApplyVertragVposIndexedValues:
      return NOTIFICATION_TYPE_APPLY_VERTRAG_VPOS_INDEXED_VALUES;
    case NotificationType.VertragVposIndexedValuesErstellung:
      return NOTIFICATION_TYPE_VERTRAG_VPOS_INDEXED_VALUES_ERSTELLUNG;
    case NotificationType.ObjektWirtschaftsplanErstellung:
      return NOTIFICATION_TYPE_OBJEKT_FINANCIAL_PLAN_ERSTELLUNG;
    case NotificationType.TopWirtschaftsplanErstellung:
      return NOTIFICATION_TYPE_TOP_FINANCIAL_PLAN_ERSTELLUNG;
    case NotificationType.ZinslisteErstellung:
      return NOTIFICATION_TYPE_ZINSLISTE_ERSTELLUNG;
    case NotificationType.KontoauszugEbicsDownloadManuell:
      return NOTIFICATION_TYPE_CAMT_EBICS_MANUALLY;
    case NotificationType.KontoauszugOnlinebankingImportierenManuell:
      return NOTIFICATION_TYPE_CAMT_ONLINE_BANKING_IMPORT_MANUALLY;
    case NotificationType.IncomingInvoiceBookingCreation:
      return NOTIFICATION_TYPE_INCOMING_INVOICE_BOOKING_CREATION;
    case NotificationType.BookingSuggestionCreation:
      return NOTIFICATION_TYPE_BOOKING_SUGGESTION_CREATION;
    case NotificationType.ZahlungsvorschlagVerbuchen:
      return NOTIFICATION_TYPE_ZAHLUNGSVORSCHLAG_VERBUCHEN;
    case NotificationType.EmpfangeneBankbuchungenVerbuchen:
      return NOTIFICATION_TYPE_EMPFANGENE_BANKBUCHUNGEN_VERBUCHEN;
    case NotificationType.FirmendatenInitializer:
      return NOTIFICATION_TYPE_FIRMENDATEN_INITIALIZER;
    case NotificationType.HeAbrechnung:
      return NOTIFICATION_TYPE_HE_ABRECHNUNG;
    case NotificationType.SubAbrechnungErstellung:
      return NOTIFICATION_TYPE_SUB_ABRECHNUNG_ERSTELLUNG;
    case 'FILE_UPLOAD':
      return 'Datei für "Kontoauszug importieren" wird hochgeladen';
    default:
      return capitalizeString(notificationType);
  }
};

export const mapStatusToStatusSpecs = (status: NotificationStatus): NotificationStatusSpecs => {
  switch (status) {
    case NotificationStatus.Pending:
      return NotificationStatusValue.pending;
    case NotificationStatus.Running:
      return NotificationStatusValue.running;
    case NotificationStatus.Finished:
      return NotificationStatusValue.finished;
    case NotificationStatus.FinishedError:
      return NotificationStatusValue.finishedError;
    case NotificationStatus.FinishedWarning:
      return NotificationStatusValue.finishedWarning;
    default:
      return NotificationStatusValue.pending;
  }
};

const NotificationStatusValue: Record<string, NotificationStatusSpecs> = {
  pending: {
    backgroundColor: '#95a5a6',
    progressStatus: 'normal',
    text: 'Nicht gestartet',
  },
  running: {
    backgroundColor: '#1677ff',
    progressStatus: 'active',
    text: 'In Arbeit',
  },
  finished: {
    backgroundColor: '#52c41a',
    progressStatus: 'success',
    text: 'Abgeschlossen',
  },
  finishedError: {
    backgroundColor: '#F5222D',
    progressStatus: 'exception',
    text: 'Abgeschlossen mit Fehlern',
  },
  finishedWarning: {
    // Same as Verarbeitung Status tag color
    backgroundColor: '#ffd591',
    progressStatus: 'success',
    text: 'Abgeschlossen mit Warning',
  },
};
