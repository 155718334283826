import React, { FC } from 'react';
import { Empty } from 'antd';
import { SubAbrAbrechnungskreisList, SubAbrechnungEigentuemer } from '../../../../../../../types';
import NestedTableWithColSelectorWrapper from '../../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelectorWrapper';
import NestedTableWithColSelector from '../../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import subAbrTabObjektTableColumns from './subAbrTabObjektTableColumns';
import AbrechnungskreisTable from '../Level2/AbrechnungskreisTable';

type Props = {
  subAbrechnungEigentuemer: SubAbrechnungEigentuemer;
};

const SubAbrTabObjektTable: FC<Props> = ({ subAbrechnungEigentuemer }) => {
  const list: SubAbrAbrechnungskreisList[] = [
    subAbrechnungEigentuemer.subAbrechnungEigentuemerObjekt.vorsteuerkuerzung as SubAbrAbrechnungskreisList,
    subAbrechnungEigentuemer.subAbrechnungEigentuemerObjekt.eigentuemerverrechnung as SubAbrAbrechnungskreisList,
  ].filter((abrechnung) => !!abrechnung);

  return (
    <NestedTableWithColSelectorWrapper filterIdentifier="sub-abr-tab-be">
      <NestedTableWithColSelector<SubAbrAbrechnungskreisList>
        colWidth={150}
        level={1}
        locale={{
          emptyText: <Empty description={<span>Keine Daten vorhanden</span>} />,
        }}
        columns={subAbrTabObjektTableColumns}
        dataSource={list}
        rowKey={getRowKey}
        expandable={{
          rowExpandable: isRowExpandable,
          expandedRowRender: (record, index, indent, expanded) => (
            <AbrechnungskreisTable
              parentRowKeys={[getRowKey(record)]}
              visible={expanded}
              abrechnungskreisList={record.abrechnungskreisList}
              buchungskreisId={subAbrechnungEigentuemer.buchungskreisId}
              abrechnungszeitraumVon={subAbrechnungEigentuemer.abrechnungszeitraumVon}
              abrechnungszeitraumBis={subAbrechnungEigentuemer.abrechnungszeitraumBis}
              objektId={subAbrechnungEigentuemer.objekt.objektId}
            />
          ),
        }}
      />
    </NestedTableWithColSelectorWrapper>
  );
};

const getRowKey = (record: SubAbrAbrechnungskreisList) => record.abrechnungskreisType.text + record.summe;

const isRowExpandable = (record: SubAbrAbrechnungskreisList) => !!record.abrechnungskreisList.length;

export default SubAbrTabObjektTable;
