import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Switch } from 'formik-antd';
import { Space, Typography } from 'antd';
import { objAbrDefGeneralSettingsFormFields, ObjAbrDefGeneralSettingsFormValues } from './objAbrDefGeneralSettingsFormMapper';
import ParamFormItem from '../../../../../../../components/Form/ParamFormItem';

type Props = {
  formikProps: FormikProps<ObjAbrDefGeneralSettingsFormValues>;
  isDisabled: boolean;
  onChange?: (formValues: ObjAbrDefGeneralSettingsFormValues) => void;
  fieldHelp?: string | null;
};

const DisplayKontonummerFormItem: FC<Props> = ({ formikProps, isDisabled, onChange, fieldHelp }) => (
  <ParamFormItem name={objAbrDefGeneralSettingsFormFields.displayKontonummer} label="Kontonummer ausgeben" marginBottom="16px" fieldHelp={fieldHelp}>
    <Space>
      <Switch
        name={objAbrDefGeneralSettingsFormFields.displayKontonummer}
        size="small"
        onChange={(value) => onChange?.({ ...formikProps.values, displayKontonummer: value })}
        disabled={isDisabled}
      />
      <Typography.Text>{formikProps.values.displayKontonummer ? 'Ja' : 'Nein'}</Typography.Text>
    </Space>
  </ParamFormItem>
);

export default DisplayKontonummerFormItem;
