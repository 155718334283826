import React from 'react';
import { useParams } from 'react-router-dom';
import menuListKonto from './menuListKonto';
import { KontoDetailsPageRouteParams } from './routes';
import { Konto } from '../../../../types';
import { useKontoTemplateQuery } from '../../../../features/Kontenrahmen/gql/KontenrahmenQueries.types';
import DetailsPageTemplate from '../../../../components/Template/DetailsPageTemplate';
import { hasKontoklasseAbrechnungen } from '../../../../features/Kontenplan/Form/kontoFormHelpers';
import KontoTemplateCard from '../../../../features/Kontenrahmen/KontoTemplateDetails/KontoTemplateCard';

const AndromedaSysSettingsKontoDetailsPage = () => {
  const { kontoId } = useParams() as KontoDetailsPageRouteParams;

  const { data, refetch, loading } = useKontoTemplateQuery({
    variables: {
      kontoId,
    },
    notifyOnNetworkStatusChange: true,
  });
  const konto = data?.getKontoTemplate.data;
  // const loading = !konto;

  return (
    <DetailsPageTemplate<Konto>
      data={konto}
      isLoading={loading}
      pageTitle={(konto) => konto.bezeichnung}
      card={(konto) => <KontoTemplateCard konto={konto} loading={loading} onSuccess={refetch} />}
      sidebarMenuList={(konto) => menuListKonto(konto)}
      contentContextList={(konto) => createKontoplanContextList(konto)}
    />
  );
};

const createKontoplanContextList = (konto: Konto) => (hasKontoklasseAbrechnungen(konto.klasse.value) ? ['ABRECHNUNGEN'] : []);

export default AndromedaSysSettingsKontoDetailsPage;
