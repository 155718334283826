import * as Types from '../../types';
import {
  GenerierlaufEntryExitCode,
  MahnungAusgebenGenerierlauf,
  MahnungAusgebenGenerierlaufEntry,
  MahnungVerbuchenGenerierlauf,
  MahnungVerbuchenGenerierlaufEntry,
  MahnungVersendenGenerierlauf,
  MahnungVersendenGenerierlaufEntry,
  PaymentProposalCreationGenerierlauf,
  PaymentProposalCreationGenerierlaufEntry,
  PaymentProposalExecutionGenerierlauf,
  PaymentProposalExecutionGenerierlaufEntry,
  WarningData,
} from '../../types';

export type MahnungAusgebenVersendenVerbuchenGenerierlaufData =
  | MahnungAusgebenGenerierlauf
  | MahnungVersendenGenerierlauf
  | MahnungVerbuchenGenerierlauf;
export type MahnungAusgebenVersendenVerbuchenGenerierlaufEntryList =
  | MahnungAusgebenGenerierlaufEntry
  | MahnungVersendenGenerierlaufEntry
  | MahnungVerbuchenGenerierlaufEntry;

export type PaymentProposalGenerierlauf = PaymentProposalCreationGenerierlauf | PaymentProposalExecutionGenerierlauf;
export type PaymentProposalGenerierlaufEntryList = PaymentProposalCreationGenerierlaufEntry | PaymentProposalExecutionGenerierlaufEntry;

export type TGenerierlauf = {
  countFehler: number;
  countNichtVerarbeitet: number;
  countVerarbeitet: number;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  errorList: Array<{ message: string; type: string }>;
  generierlaufId: string;
  status: { value: Types.GenerierlaufStatus; text: string };
};

export type TGenerierlaufEntry = {
  generierlaufEntryId: string;
  infoMessage?: string | null;
  errorList: Array<{ message: string; type: string }>;
  exitCode?: { value: Types.GenerierlaufEntryExitCode; text: string } | null;
  warningList: Array<WarningData>;
};

export const verarbeitungDetailsCardTitles = {
  ApplyVertragVposIndexedValue: 'Indexierte Werte anwenden',
  AuftraegeVerbuchen: 'Vorschreibungen verbuchen',
  Auftragserstellung: 'Vorschreibungen erstellen',
  BkObjektAbrechnung: 'BK-Objektabrechnungen erstellen',
  BkTopAbrechnung: 'BK-Topabrechnungen erstellen',
  Budgeting: 'Budgetierung erstellen',
  BookingSuggestionCreation: 'Buchungsvorschläge erstellen',
  BookingSuggestionVerbuchen: 'Zahlungsvorschlag verbuchen',
  Camt: 'Kontoauszug automatisch über EBICS importieren',
  CamtEbicsManually: 'Kontoauszug manuell über EBICS importieren',
  CamtOnlineBankingManually: 'Kontoauszug manuell über Onlinebanking importieren',
  DataCarrierPainCreation: 'Datenträger erstellen und über EBICS versenden',
  FirmendatenInitializer: 'Firmendaten initialisieren',
  HeAbrechnungErstellung: 'Hauseigentümerabrechnungen erstellen',
  IncomingInvoiceBookingCreation: 'Eingangsrechnungen verbuchen',
  InfoMailDeliveryDetermineRecipientList: 'Infoschreiben empfänger ermitteln',
  InfoMailDeliverySend: 'Infoschreiben versenden',
  MahnungAusgabe: 'Mahnung ausgeben',
  MahnungErstellung: 'Mahnung erstellen',
  MahnungVerbuchen: 'Mahnung verbuchen',
  MahnungVersand: 'Mahnung versenden',
  ObjektFinancialPlan: 'Objekt-Wirtschaftsplan ausgeben',
  PaymentProposalCreate: 'Zahlungsvorschlag erstellen',
  PaymentProposalExecute: 'Zahlungsvorschlag durchführen',
  ReceivedBankTransactionVerbuchen: 'Empfangene Bankbuchungen verbuchen',
  SubAbrechnung: 'Subverwaltungsabrechnungen erstellen',
  SyncEbicsUser: 'EBICS Bankteilnehmer aktualisieren automatisch',
  TopFinancialPlan: 'Top-Wirtschaftsplan ausgeben',
  UstVomAufwand: 'Ust. vom Aufwand erstellen',
  VertragVposIndexedValueAutomatically: 'Indexierte Werte erstellen (automatisch)',
  VertragVposIndexedValueManually: 'Indexierte Werte erstellen (manuell)',
  VorschreibungAusgeben: 'Vorschreibungen ausgeben',
  VorschreibungMailDeliveryDetermineRecipientList: 'Vorschreibungen Empfänger ermitteln',
  VorschreibungMailDeliverySend: 'Vorschreibungen versenden',
  VorsteuerkuerzungErstellung: 'Vorsteuerkürzung erstellen',
  WeObjektAbrechnung: 'WE-Objektabrechnung erstellen',
  WeTopAbrechnung: 'WE-Topabrechnungen erstellen',
  ZinslisteErstellung: 'Zinslisten erstellen',
};

export const verarbeitungPageAndMenuListTitles = {
  ApplyVertragVposIndexedValue: 'Indexierte Werte anwenden',
  AuftraegeVerbuchen: 'Verbuchte Vorschreibungen',
  Auftragserstellung: 'Erstellte Vorschreibungen',
  BkObjektAbrechnung: 'Erstellte BK-Objektabrechnungen',
  BkTopAbrechnung: 'Erstellte BK-Topabrechnungen',
  Budgeting: 'Erstellte Budgetierung',
  BookingSuggestionCreation: 'Erstellte Buchungsvorschläge',
  BookingSuggestionVerbuchen: 'Verbuchte Zahlungen',
  Camt: 'Automatisch über EBICS importierte Kontoauszüge',
  CamtEbicsManually: 'Manuell über EBICS importierte Kontoauszüge',
  CamtOnlineBankingManually: 'Manuell über Onlinebanking importierte Kontoauszüge',
  DataCarrierPainCreation: 'Erstellte und über EBICS versandte Datenträger',
  ErrorsAndWarnings: 'Allgemeine Fehler und Warnungen',
  FirmendatenInitializer: 'Initialisierte Firmendaten',
  HeAbrechnung: 'Erstellte Hauseigentümerabrechnungen',
  IncomingInvoiceBookingCreation: 'Verbuchte Eingangsrechnungen',
  InfoMailDeliveryDetermineRecipientList: 'Ermittelte Infoschreiben Empfänger',
  InfoMailDeliverySend: 'Versandte Infoschreiben',
  MahnungAusgabe: 'Ausgegebene Mahnungen',
  MahnungErstellung: 'Erstellte Mahnungen',
  MahnungVerbuchen: 'Verbuchte Mahnungen',
  MahnungVersand: 'Versandte Mahnungen',
  ObjektFinancialPlan: 'Ausgegebene Objekt-Wirtschaftsplan',
  PaymentProposalCreate: 'Hinzugefügte Zahlungen',
  PaymentProposalExecute: 'Durchgeführte Zahlungen',
  PaymentProposalVerbuchen: 'Verbuchte Zahlungen',
  ReceivedBankTransactionVerbuchen: 'Verbuchte Empf. Bankbuchungen',
  SubAbrechnung: 'Erstellte Subverwaltungsabrechnungen',
  SyncEbicsUser: 'Erstellte EBICS Bankteilnehmer (automatisch)',
  TopFinancialPlan: 'Ausgegebene Top-Wirtschaftspläne',
  UstVomAufwand: 'Erstellte Ust. vom Aufwand-Buchungen',
  VertragVposIndexedValueAutomatically: 'Erstellte Indexierte Werte (automatisch)',
  VertragVposIndexedValueManually: 'Erstellte Indexierte Werte (manuell)',
  VorschreibungAusgeben: 'Ausgegebene Vorschreibungen',
  VorschreibungMailDeliveryDetermineRecipientList: 'Ermittelte Vorschreibungen Empfänger',
  VorschreibungMailDeliverySend: 'Versandte Vorschreibungen',
  VorsteuerkuerzungErstellung: 'Erstellte Vorsteuerkürzungen',
  WeObjektAbrechnung: 'Erstellte WE-Objektabrechnungen',
  WeTopAbrechnung: 'Erstellte WE-Topabrechnungen',
  ZinslisteErstellung: 'Erstellte Zinslisten',
};

export const verarbeitungTypes = {
  ApplyVertragVposIndexedValue: 'APPLY_VERTRAG_VPOS_INDEXED_VALUE',
  BkObjektAbrechnung: 'BK_OBJEKT_ABRECHNUNG',
  BkTopAbrechnung: 'BK_TOP_ABRECHNUNG',
  Budgeting: 'BUDGETING',
  BookingSuggestionCreation: 'BOOKING_SUGGESTION_CREATION',
  BookingSuggestionVerbuchen: 'ZAHLUNGSVORSCHLAG_VERBUCHEN',
  Camt: 'KONTOAUSZUG_EBICS_DOWNLOAD_AUTOMATISCH',
  CamtEbicsManually: 'KONTOAUSZUG_EBICS_DOWNLOAD_MANUELL',
  CamtOnlineBankingManually: 'KONTOAUSZUG_ONLINEBANKING_IMPORTIEREN_MANUELL',
  DataCarrierPainCreation: 'DATA_CARRIER_PAIN_CREATION',
  FirmendatenInitializer: 'FIRMENDATEN_INITIALIZER',
  HeAbrechnung: 'HE_ABRECHNUNG',
  IncomingInvoiceBookingCreation: 'INCOMING_INVOICE_BOOKING_CREATION',
  InfoMailDeliveryDetermineRecipientList: 'INFO_MAIL_DELIVERY_DETERMINE_RECIPIENT_LIST',
  InfoMailDeliverySend: 'INFO_MAIL_DELIVERY_SEND',
  MahnungAusgeben: 'MAHNUNG_AUSGEBEN',
  MahnungErzeugen: 'MAHNUNG_ERZEUGEN',
  MahnungVerbuchen: 'MAHNUNG_VERBUCHEN',
  MahnungVersenden: 'MAHNUNG_VERSENDEN',
  ObjektFinancialPlan: 'FINANCIAL_PLAN_OBJEKT',
  PaymentProposalCreate: 'PAYMENT_PROPOSAL_CREATION',
  PaymentProposalExecute: 'PAYMENT_PROPOSAL_EXECUTION',
  ReceivedBankTransactionVerbuchen: 'RECEIVED_BANK_TRANSACTION_VERBUCHEN',
  SubAbrechnung: 'SUB_ABRECHNUNG',
  SyncEbicsUser: 'SYNC_EBICS_USER',
  TopFinancialPlan: 'FINANCIAL_PLAN_TOP',
  UstVomAufwand: 'UST_VOM_AUFWAND',
  VertragVposIndexedValueAutomatically: 'VERTRAG_VPOS_INDEXED_VALUE_AUTOMATICALLY',
  VertragVposIndexedValueManually: 'VERTRAG_VPOS_INDEXED_VALUE_MANUALLY',
  VorschreibungAusgeben: 'EIGENBELEG_ERSTELLEN',
  VorschreibungErstellen: 'AUFTRAG',
  VorschreibungMailDeliveryDetermineRecipientList: 'VORSCHREIBUNG_MAIL_DELIVERY_DETERMINE_RECIPIENT_LIST',
  VorschreibungMailDeliverySend: 'VORSCHREIBUNG_MAIL_DELIVERY_SEND',
  VorschreibungVerbuchen: 'AUFTRAG_VERBUCHEN',
  VorsteuerkuerzungErzeugen: 'VORSTEUERKUERZUNG',
  WeObjektAbrechnung: 'WE_OBJEKT_ABRECHNUNG',
  WeTopAbrechnung: 'WE_TOP_ABRECHNUNG',
  Zinsliste: 'ZINSLISTE',
};

export const isVerarbeitungTypeFirmendatenInitializer = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.FirmendatenInitializer;
export const isVerarbeitungTypeMahnungAusgeben = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.MahnungAusgeben;
export const isVerarbeitungTypeMahnungErzeugen = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.MahnungErzeugen;
export const isVerarbeitungTypeMahnungVersenden = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.MahnungVersenden;
export const isVerarbeitungTypeMahnungVerbuchen = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.MahnungVerbuchen;
export const isVerarbeitungTypeVorschreibungAusgeben = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.VorschreibungAusgeben;
export const isVerarbeitungTypeUstVomAufwand = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.UstVomAufwand;
export const isVerarbeitungTypeVorschreibungErstellen = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.VorschreibungErstellen;
export const isVerarbeitungTypeVorschreibungVerbuchen = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.VorschreibungVerbuchen;
export const isVerarbeitungTypeVorsteuerkuerzungErzeugen = (verarbeitungType: string) =>
  verarbeitungType === verarbeitungTypes.VorsteuerkuerzungErzeugen;
export const isVerarbeitungTypePaymentProposalCreate = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.PaymentProposalCreate;
export const isVerarbeitungTypePaymentProposalExecute = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.PaymentProposalExecute;

export const isVerarbeitungTypeDataCarrierPainCreation = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.DataCarrierPainCreation;

export const isVerarbeitungTypeInfoMailDeliveryDetermineRecipientList = (verarbeitungType: string) =>
  verarbeitungType === verarbeitungTypes.InfoMailDeliveryDetermineRecipientList;
export const isVerarbeitungTypeInfoMailDeliverySend = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.InfoMailDeliverySend;

export const isVerarbeitungTypeVorschreibungMailDeliveryDetermineRecipientList = (verarbeitungType: string) =>
  verarbeitungType === verarbeitungTypes.VorschreibungMailDeliveryDetermineRecipientList;
export const isVerarbeitungTypeVorschreibungMailDeliverySend = (verarbeitungType: string) =>
  verarbeitungType === verarbeitungTypes.VorschreibungMailDeliverySend;

export const isVerarbeitungTypeZinsliste = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.Zinsliste;

export const isVerarbeitungTypeHeAbrechnung = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.HeAbrechnung;
export const isVerarbeitungTypeBkObjektAbrechnung = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.BkObjektAbrechnung;
export const isVerarbeitungTypeBkTopAbrechnung = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.BkTopAbrechnung;
export const isVerarbeitungTypeWeObjektAbrechnung = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.WeObjektAbrechnung;
export const isVerarbeitungTypeWeTopAbrechnung = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.WeTopAbrechnung;
export const isVerarbeitungTypeSubAbrechnung = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.SubAbrechnung;

export const isVerarbeitungTypeBudgeting = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.Budgeting;
export const isVerarbeitungTypeObjektFinancialPlan = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.ObjektFinancialPlan;
export const isVerarbeitungTypeTopFinancialPlan = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.TopFinancialPlan;

export const isVerarbeitungTypeCamt = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.Camt;
export const isVerarbeitungTypeCamtEbicsManually = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.CamtEbicsManually;
export const isVerarbeitungTypeCamtOnlineBankingManually = (verarbeitungType: string) =>
  verarbeitungType === verarbeitungTypes.CamtOnlineBankingManually;
export const isVerarbeitungTypeSyncEbicsUser = (verarbeitungType: string) => verarbeitungType === verarbeitungTypes.SyncEbicsUser;

export const isVerarbeitungTypeVertragVposIndexedValueManually = (verarbeitungType: string) =>
  verarbeitungType === verarbeitungTypes.VertragVposIndexedValueManually;

export const isVerarbeitungTypeVertragVposIndexedValueAutomatically = (verarbeitungType: string) =>
  verarbeitungType === verarbeitungTypes.VertragVposIndexedValueAutomatically;

export const isVerarbeitungTypeApplyVertragVposIndexedValue = (verarbeitungType: string) =>
  verarbeitungType === verarbeitungTypes.ApplyVertragVposIndexedValue;

export const isVerarbeitungTypeBookingSuggestionCreation = (verarbeitungType: string) =>
  verarbeitungType === verarbeitungTypes.BookingSuggestionCreation;

export const isVerarbeitungTypeBookingSuggestionVerbuchen = (verarbeitungType: string) =>
  verarbeitungType === verarbeitungTypes.BookingSuggestionVerbuchen;

export const isVerarbeitungTypeIncomingInvoiceBookingCreation = (verarbeitungType: string) =>
  verarbeitungType === verarbeitungTypes.IncomingInvoiceBookingCreation;

export const isVerarbeitungTypeReceivedBankTransactionVerbuchen = (verarbeitungType: string) =>
  verarbeitungType === verarbeitungTypes.ReceivedBankTransactionVerbuchen;

export const isExitCodeOk = (exitCode: GenerierlaufEntryExitCode) => exitCode === GenerierlaufEntryExitCode.Ok;
export const isExitCodeFehler = (exitCode: GenerierlaufEntryExitCode) => exitCode === GenerierlaufEntryExitCode.Fehler;
export const isExitCodeRetry = (exitCode: GenerierlaufEntryExitCode) => exitCode === GenerierlaufEntryExitCode.Retry;

export const getVerarbeitungTypeTitles = (verarbeitungType: string) => {
  switch (verarbeitungType) {
    case verarbeitungTypes.InfoMailDeliveryDetermineRecipientList:
      return verarbeitungDetailsCardTitles.InfoMailDeliveryDetermineRecipientList;
    case verarbeitungTypes.InfoMailDeliverySend:
      return verarbeitungDetailsCardTitles.InfoMailDeliverySend;
    case verarbeitungTypes.Camt:
      return verarbeitungDetailsCardTitles.Camt;
    case verarbeitungTypes.DataCarrierPainCreation:
      return verarbeitungDetailsCardTitles.DataCarrierPainCreation;
    case verarbeitungTypes.VorschreibungMailDeliveryDetermineRecipientList:
      return verarbeitungDetailsCardTitles.VorschreibungMailDeliveryDetermineRecipientList;
    case verarbeitungTypes.VorschreibungMailDeliverySend:
      return verarbeitungDetailsCardTitles.VorschreibungMailDeliverySend;
    case verarbeitungTypes.VorschreibungAusgeben:
      return verarbeitungDetailsCardTitles.VorschreibungAusgeben;
    case verarbeitungTypes.MahnungAusgeben:
      return verarbeitungDetailsCardTitles.MahnungAusgabe;
    case verarbeitungTypes.MahnungErzeugen:
      return verarbeitungDetailsCardTitles.MahnungErstellung;
    case verarbeitungTypes.MahnungVersenden:
      return verarbeitungDetailsCardTitles.MahnungVersand;
    case verarbeitungTypes.MahnungVerbuchen:
      return verarbeitungDetailsCardTitles.MahnungVerbuchen;
    case verarbeitungTypes.WeObjektAbrechnung:
      return verarbeitungDetailsCardTitles.WeObjektAbrechnung;
    case verarbeitungTypes.WeTopAbrechnung:
      return verarbeitungDetailsCardTitles.WeTopAbrechnung;
    case verarbeitungTypes.UstVomAufwand:
      return verarbeitungDetailsCardTitles.UstVomAufwand;
    case verarbeitungTypes.VorschreibungErstellen:
      return verarbeitungDetailsCardTitles.Auftragserstellung;
    case verarbeitungTypes.VorsteuerkuerzungErzeugen:
      return verarbeitungDetailsCardTitles.VorsteuerkuerzungErstellung;
    case verarbeitungTypes.VorschreibungVerbuchen:
      return verarbeitungDetailsCardTitles.AuftraegeVerbuchen;
    case verarbeitungTypes.PaymentProposalCreate:
      return verarbeitungDetailsCardTitles.PaymentProposalCreate;
    case verarbeitungTypes.PaymentProposalExecute:
      return verarbeitungDetailsCardTitles.PaymentProposalExecute;
    case verarbeitungTypes.HeAbrechnung:
      return verarbeitungDetailsCardTitles.HeAbrechnungErstellung;
    case verarbeitungTypes.Zinsliste:
      return verarbeitungDetailsCardTitles.ZinslisteErstellung;
    case verarbeitungTypes.BkObjektAbrechnung:
      return verarbeitungDetailsCardTitles.BkObjektAbrechnung;
    case verarbeitungTypes.BkTopAbrechnung:
      return verarbeitungDetailsCardTitles.BkTopAbrechnung;
    case verarbeitungTypes.Budgeting:
      return verarbeitungDetailsCardTitles.Budgeting;
    case verarbeitungTypes.VertragVposIndexedValueManually:
      return verarbeitungDetailsCardTitles.VertragVposIndexedValueManually;
    case verarbeitungTypes.VertragVposIndexedValueAutomatically:
      return verarbeitungDetailsCardTitles.VertragVposIndexedValueAutomatically;
    case verarbeitungTypes.ApplyVertragVposIndexedValue:
      return verarbeitungDetailsCardTitles.ApplyVertragVposIndexedValue;
    case verarbeitungTypes.ObjektFinancialPlan:
      return verarbeitungDetailsCardTitles.ObjektFinancialPlan;
    case verarbeitungTypes.TopFinancialPlan:
      return verarbeitungDetailsCardTitles.TopFinancialPlan;
    case verarbeitungTypes.SyncEbicsUser:
      return verarbeitungDetailsCardTitles.SyncEbicsUser;
    case verarbeitungTypes.CamtEbicsManually:
      return verarbeitungDetailsCardTitles.CamtEbicsManually;
    case verarbeitungTypes.CamtOnlineBankingManually:
      return verarbeitungDetailsCardTitles.CamtOnlineBankingManually;
    case verarbeitungTypes.BookingSuggestionCreation:
      return verarbeitungDetailsCardTitles.BookingSuggestionCreation;
    case verarbeitungTypes.IncomingInvoiceBookingCreation:
      return verarbeitungDetailsCardTitles.IncomingInvoiceBookingCreation;
    case verarbeitungTypes.BookingSuggestionVerbuchen:
      return verarbeitungDetailsCardTitles.BookingSuggestionVerbuchen;
    case verarbeitungTypes.ReceivedBankTransactionVerbuchen:
      return verarbeitungDetailsCardTitles.ReceivedBankTransactionVerbuchen;
    case verarbeitungTypes.FirmendatenInitializer:
      return verarbeitungDetailsCardTitles.FirmendatenInitializer;
    case verarbeitungTypes.SubAbrechnung:
      return verarbeitungDetailsCardTitles.SubAbrechnung;
    default:
      return '?';
  }
};

export const isVerarbeitungEntryTableRowExpandable = (entry: TGenerierlaufEntry) => {
  const hasError = entry.errorList.length > 0;
  const hasWarning = entry.warningList.length > 0;

  return hasError || hasWarning || !!entry.infoMessage;
};
