import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../shared/dataChangedSubscription';
import { DataChangedChangeType } from '../../types';

const useOnOffenePostenDataChangedEvents = (key: string, callback: () => Promise<unknown>, offenePostenId?: string) => {
  useOnDataChangedEvent([
    {
      filter: {
        type: dataChangedSubscriptionTypes.offenePosten,
        params: {
          offenePostenId,
        },
        changeType: DataChangedChangeType.Update,
      },
      notificationDescription: 'Offene Posten Liste wurde gerade verändert',
      key: `${key}-update`,
      callback,
    },
  ]);
};

export { useOnOffenePostenDataChangedEvents };
