import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../security/AuthRoute';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import RechtstraegerDetailsPage from './RechtstraegerDetailsPage';
import { URI_RECHTSTRAEGER_DETAILS_PAGE } from '../../features/Rechtstraeger/rechtstraegerUriPaths';

export type RechtstraegerDetailsPageRouteParams = { rechtstraegerId: string };

const rechtstraegerDetailsPageRoute = (
  <Route
    path={`${URI_RECHTSTRAEGER_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<RechtstraegerDetailsPage />} />}
  />
);

export default rechtstraegerDetailsPageRoute;
