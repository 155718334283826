import React from 'react';
import { Typography } from 'antd';
import { EbicsBankConfig } from '../../../../../types';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { compareTwoStringsForSorting } from '../../../../../helpers/stringHelper';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import MitarbeiterTooltip from '../../../../../components/Card/MitarbeiterTooltip';
import { compareTwoDatesForSorting } from '../../../../../helpers/dateHelper';
import EbicsBankConfigTableActions from './EbicsBankConfigTableActions';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';

const ebicsBankConfigTableColumns = (onAction: () => void): TableWithColSelectorColumnProps<EbicsBankConfig>[] => [
  {
    title: 'Bankname',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.name, b.name),
    render: (text, record) => <DataWithShortenedText maxTextLength={20} text={record.name} />,
  },
  {
    title: 'Host ID',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.hostId, b.hostId),
    render: (text, record) => <Typography.Text>{record.hostId}</Typography.Text>,
  },
  {
    title: 'Url',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.url, b.url),
    render: (text, record) => <DataWithShortenedText maxTextLength={20} text={record.url} />,
  },
  {
    title: 'EBICS Version',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.ebicsVersion.text, b.ebicsVersion.text),
    render: (text, record) => <Typography.Text>{record.ebicsVersion.text}</Typography.Text>,
  },
  {
    title: 'EBICS 3.0 BTF - Pain001',
    sorter: (a, b) => compareTwoStringsForSorting(a.creditTransferBtf, b.creditTransferBtf),
    render: (text, record) =>
      record.creditTransferBtf ? <Typography.Text>{record.creditTransferBtf}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'EBICS 3.0 BTF - Pain008',
    sorter: (a, b) => compareTwoStringsForSorting(a.directDebitBtf, b.directDebitBtf),
    render: (text, record) =>
      record.directDebitBtf ? <Typography.Text>{record.directDebitBtf}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'EBICS 3.0 BTF - REP mit Pain002 für Lastschrift',
    sorter: (a, b) => compareTwoStringsForSorting(a.directDebitStatusBtf, b.directDebitStatusBtf),
    render: (text, record) =>
      record.directDebitStatusBtf ? <Typography.Text>{record.directDebitStatusBtf}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'EBICS 3.0 BTF - REP mit Pain002 für Überweisung',
    sorter: (a, b) => compareTwoStringsForSorting(a.creditTransferStatusBtf, b.creditTransferStatusBtf),
    render: (text, record) =>
      record.creditTransferStatusBtf ? <Typography.Text>{record.creditTransferStatusBtf}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'EBICS 3.0 BTF - Camt52',
    sorter: (a, b) => compareTwoStringsForSorting(a.preApprovalBtf, b.preApprovalBtf),
    render: (text, record) =>
      record.preApprovalBtf ? <Typography.Text>{record.preApprovalBtf}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'EBICS 3.0 BTF - Camt53',
    sorter: (a, b) => compareTwoStringsForSorting(a.bankStatementBtf, b.bankStatementBtf),
    render: (text, record) =>
      record.bankStatementBtf ? <Typography.Text>{record.bankStatementBtf}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'XML Schema Überweisung',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.pain001Schema.text, b.pain001Schema.text),
    render: (text, record) => <Typography.Text>{record.pain001Schema.text}</Typography.Text>,
  },
  {
    title: 'XML Schema Lastschrift',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.pain008Schema.text, b.pain008Schema.text),
    render: (text, record) => <Typography.Text>{record.pain008Schema.text}</Typography.Text>,
  },
  {
    title: 'Erstellt am',
    width: 100,
    sorter: (a, b) => compareTwoDatesForSorting(a.createTs, b.createTs),
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip alignment="left" mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => <EbicsBankConfigTableActions ebicsBankConfig={record} onAction={onAction} />,
  },
];

export default ebicsBankConfigTableColumns;
