import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AbrechnungskreisFieldsFragmentDoc } from '../../Abrechnungskreis/gql/AbrechnungskreisFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AbrechnungsdefinitionVersionAbrechnungskreisListQueryVariables = Types.Exact<{
  abrechnungsdefinitionId: Types.Scalars['ID']['input'];
  abrechnungsdefinitionVersionId: Types.Scalars['ID']['input'];
}>;

export type AbrechnungsdefinitionVersionAbrechnungskreisListQuery = {
  getAbrechnungsdefinitionVersionAbrechnungskreisList: {
    data: Array<{
      abrechnungskreisId: string;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      updatedByMitarbeiterId?: string | null;
      status: { description?: string | null; text: string; value: Types.AbrechnungskreisStatus };
      type: { text: string; value: Types.AbrechnungskreisType };
      verrechnungUstVomAufwand?: { description: string; text: string; value: Types.VerrechnungsUstVomAufwand } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const AbrechnungsdefinitionVersionAbrechnungskreisListDocument = gql`
  query AbrechnungsdefinitionVersionAbrechnungskreisList($abrechnungsdefinitionId: ID!, $abrechnungsdefinitionVersionId: ID!) {
    getAbrechnungsdefinitionVersionAbrechnungskreisList(
      abrechnungsdefinitionId: $abrechnungsdefinitionId
      abrechnungsdefinitionVersionId: $abrechnungsdefinitionVersionId
    ) {
      data {
        ...AbrechnungskreisFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AbrechnungskreisFieldsFragmentDoc}
`;
export function useAbrechnungsdefinitionVersionAbrechnungskreisListQuery(
  baseOptions: Apollo.QueryHookOptions<
    AbrechnungsdefinitionVersionAbrechnungskreisListQuery,
    AbrechnungsdefinitionVersionAbrechnungskreisListQueryVariables
  > &
    ({ variables: AbrechnungsdefinitionVersionAbrechnungskreisListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbrechnungsdefinitionVersionAbrechnungskreisListQuery, AbrechnungsdefinitionVersionAbrechnungskreisListQueryVariables>(
    AbrechnungsdefinitionVersionAbrechnungskreisListDocument,
    options
  );
}
export function useAbrechnungsdefinitionVersionAbrechnungskreisListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AbrechnungsdefinitionVersionAbrechnungskreisListQuery,
    AbrechnungsdefinitionVersionAbrechnungskreisListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbrechnungsdefinitionVersionAbrechnungskreisListQuery, AbrechnungsdefinitionVersionAbrechnungskreisListQueryVariables>(
    AbrechnungsdefinitionVersionAbrechnungskreisListDocument,
    options
  );
}
export function useAbrechnungsdefinitionVersionAbrechnungskreisListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AbrechnungsdefinitionVersionAbrechnungskreisListQuery,
        AbrechnungsdefinitionVersionAbrechnungskreisListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AbrechnungsdefinitionVersionAbrechnungskreisListQuery,
    AbrechnungsdefinitionVersionAbrechnungskreisListQueryVariables
  >(AbrechnungsdefinitionVersionAbrechnungskreisListDocument, options);
}
export type AbrechnungsdefinitionVersionAbrechnungskreisListQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionVersionAbrechnungskreisListQuery
>;
export type AbrechnungsdefinitionVersionAbrechnungskreisListLazyQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionVersionAbrechnungskreisListLazyQuery
>;
export type AbrechnungsdefinitionVersionAbrechnungskreisListSuspenseQueryHookResult = ReturnType<
  typeof useAbrechnungsdefinitionVersionAbrechnungskreisListSuspenseQuery
>;
export type AbrechnungsdefinitionVersionAbrechnungskreisListQueryResult = Apollo.QueryResult<
  AbrechnungsdefinitionVersionAbrechnungskreisListQuery,
  AbrechnungsdefinitionVersionAbrechnungskreisListQueryVariables
>;
