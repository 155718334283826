import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../hooks/useQueryWithPagingAndSorting';
import { useAuftragListQuery } from '../../../Vorschreibung/gql/AuftragQueries.types';
import VorschreibungTable from '../../../Vorschreibung/Listing/Table/VorschreibungTable';
import TabPaneContent from '../../../../components/Tabs/TabPaneContent';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';

type ZinslisteVorschreibungProps = {
  zinslisteId: string;
} & TabsProps;

const ZinslisteVorschreibung: FC<ZinslisteVorschreibungProps> = ({ zinslisteId, ...restProps }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();
  const { data, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useAuftragListQuery,
    {
      variables: {
        auftragsPositionLabel: 'zinslisteId',
        auftragsPositionLabelValue: zinslisteId,
        includeStorniert: true,
        includeStorno: true,
      },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );
  const auftragList = data?.getAuftragList.data?.contentList;
  const loading = !auftragList;

  const items: TabsProps['items'] = [
    {
      key: 'Vorschreibungen',
      label: 'Vorschreibungen',
      children: (
        <TabPaneContent distanceToTopBorder="large">
          <VorschreibungTable
            loading={loading}
            pagination={pagination}
            handleTableSorting={handleTableSorting}
            auftragList={auftragList}
            useDefaultTextLength={false}
            filterIdentifier="zinsliste-vorschreibung"
          />
        </TabPaneContent>
      ),
    },
  ];

  return <Tabs {...restProps} items={items} />;
};

export default ZinslisteVorschreibung;
