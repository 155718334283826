import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import { NutzungsArtFieldsFragmentDoc } from './NutzungsartFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BeNutzungsartVersionListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
}>;

export type BeNutzungsartVersionListQuery = {
  getBeNutzungsArtVersionList: {
    data: Array<{
      beNutzungsArtId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      kommentar?: string | null;
      lastUpdateTs: string;
      vertragId?: string | null;
      vertragKurzBezeichnung?: string | null;
      validFrom: string;
      historicizedList?: Array<{
        beNutzungsArtId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        kommentar?: string | null;
        lastUpdateTs: string;
        validFrom: string;
        vertragId?: string | null;
        vertragKurzBezeichnung?: string | null;
        nutzungsArt: { text: string; value: Types.NutzungsArt };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      nutzungsArt: { text: string; value: Types.NutzungsArt };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type BeNutzungsArtVersionQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  beNutzungsArtId: Types.Scalars['ID']['input'];
}>;

export type BeNutzungsArtVersionQuery = {
  getBeNutzungsArtVersion: {
    data: {
      beNutzungsArtId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      kommentar?: string | null;
      lastUpdateTs: string;
      vertragId?: string | null;
      vertragKurzBezeichnung?: string | null;
      validFrom: string;
      historicizedList?: Array<{
        beNutzungsArtId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        kommentar?: string | null;
        lastUpdateTs: string;
        validFrom: string;
        vertragId?: string | null;
        vertragKurzBezeichnung?: string | null;
        nutzungsArt: { text: string; value: Types.NutzungsArt };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      nutzungsArt: { text: string; value: Types.NutzungsArt };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const BeNutzungsartVersionListDocument = gql`
  query BeNutzungsartVersionList($objektId: ID!, $bestandseinheitId: ID!) {
    getBeNutzungsArtVersionList(objektId: $objektId, bestandseinheitId: $bestandseinheitId) {
      data {
        ...NutzungsArtFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${NutzungsArtFieldsFragmentDoc}
`;
export function useBeNutzungsartVersionListQuery(
  baseOptions: Apollo.QueryHookOptions<BeNutzungsartVersionListQuery, BeNutzungsartVersionListQueryVariables> &
    ({ variables: BeNutzungsartVersionListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BeNutzungsartVersionListQuery, BeNutzungsartVersionListQueryVariables>(BeNutzungsartVersionListDocument, options);
}
export function useBeNutzungsartVersionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BeNutzungsartVersionListQuery, BeNutzungsartVersionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BeNutzungsartVersionListQuery, BeNutzungsartVersionListQueryVariables>(BeNutzungsartVersionListDocument, options);
}
export function useBeNutzungsartVersionListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BeNutzungsartVersionListQuery, BeNutzungsartVersionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BeNutzungsartVersionListQuery, BeNutzungsartVersionListQueryVariables>(BeNutzungsartVersionListDocument, options);
}
export type BeNutzungsartVersionListQueryHookResult = ReturnType<typeof useBeNutzungsartVersionListQuery>;
export type BeNutzungsartVersionListLazyQueryHookResult = ReturnType<typeof useBeNutzungsartVersionListLazyQuery>;
export type BeNutzungsartVersionListSuspenseQueryHookResult = ReturnType<typeof useBeNutzungsartVersionListSuspenseQuery>;
export type BeNutzungsartVersionListQueryResult = Apollo.QueryResult<BeNutzungsartVersionListQuery, BeNutzungsartVersionListQueryVariables>;
export const BeNutzungsArtVersionDocument = gql`
  query BeNutzungsArtVersion($objektId: ID!, $bestandseinheitId: ID!, $beNutzungsArtId: ID!) {
    getBeNutzungsArtVersion(objektId: $objektId, bestandseinheitId: $bestandseinheitId, beNutzungsArtId: $beNutzungsArtId) {
      data {
        ...NutzungsArtFields
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
  ${NutzungsArtFieldsFragmentDoc}
`;
export function useBeNutzungsArtVersionQuery(
  baseOptions: Apollo.QueryHookOptions<BeNutzungsArtVersionQuery, BeNutzungsArtVersionQueryVariables> &
    ({ variables: BeNutzungsArtVersionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BeNutzungsArtVersionQuery, BeNutzungsArtVersionQueryVariables>(BeNutzungsArtVersionDocument, options);
}
export function useBeNutzungsArtVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BeNutzungsArtVersionQuery, BeNutzungsArtVersionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BeNutzungsArtVersionQuery, BeNutzungsArtVersionQueryVariables>(BeNutzungsArtVersionDocument, options);
}
export function useBeNutzungsArtVersionSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BeNutzungsArtVersionQuery, BeNutzungsArtVersionQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BeNutzungsArtVersionQuery, BeNutzungsArtVersionQueryVariables>(BeNutzungsArtVersionDocument, options);
}
export type BeNutzungsArtVersionQueryHookResult = ReturnType<typeof useBeNutzungsArtVersionQuery>;
export type BeNutzungsArtVersionLazyQueryHookResult = ReturnType<typeof useBeNutzungsArtVersionLazyQuery>;
export type BeNutzungsArtVersionSuspenseQueryHookResult = ReturnType<typeof useBeNutzungsArtVersionSuspenseQuery>;
export type BeNutzungsArtVersionQueryResult = Apollo.QueryResult<BeNutzungsArtVersionQuery, BeNutzungsArtVersionQueryVariables>;
