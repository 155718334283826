import React from 'react';
import { Typography } from 'antd';
import { Percent } from '../../../../../components/Number';
import { UstKategorieEntry } from '../../../../../types';
import { NestedTableColProps } from '../../../../../components/Table/NestedTable/NestedTable';

export const UstKategorieTableExpandedRowTableColumns: NestedTableColProps<UstKategorieEntry>[] = [
  {
    title: 'USt.-Kennzeichen',
    dataIndex: ['ustKz', 'bezeichnung'],
  },
  {
    title: 'Kennzeichen für Rechnung',
    dataIndex: 'rechnungskennzeichen',
  },
  {
    title: 'USt %',
    dataIndex: 'steuersatz',
    render: (text) => <Percent value={text / 100} />,
  },
  {
    title: 'Addition Konto',
    dataIndex: 'additionKonto',
  },
  {
    title: 'Buchungskennzeichen',
    dataIndex: ['buchungsKZ', 'text'],
    render: (text, record) => <Typography.Text>{record.buchungsKZ}</Typography.Text>,
  },
];
