import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { budgetingVerarbeitungEntryFiltersFormFields, BudgetingVerarbeitungEntryFiltersFormValues } from './ListingFiltersFormMapper';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import FiltersWithMax3Fields from '../../../../../components/Filters/FiltersWithMax3Fields';
import GenerierlaufEntryExitCodeListSelect from '../../../shared/Filters/GenerierlaufEntryExitCodeListSelect';
import { BudgetingGenerierlaufEntry } from '../../../../../types';
import ObjektSelect from './ObjektSelect';
import { isNotNil } from '../../../../../helpers/assertionHelper';

type Props = {
  formikProps: FormikProps<BudgetingVerarbeitungEntryFiltersFormValues>;
  entryList: BudgetingGenerierlaufEntry[];
};

const ListingFilters: FC<Props> = ({ formikProps, entryList }) => {
  const objektList = entryList
    .map((entry) => entry.budgeting?.objekt.kurzBezeichnung)
    .filter(isNotNil)
    .filter((item, index, items) => items.indexOf(item) === index)
    .sort();

  return (
    <FiltersWithMax3Fields
      hideTitle
      filters={
        <>
          <FormItemWithoutColon name={budgetingVerarbeitungEntryFiltersFormFields.objekt} label="Objekt">
            <ObjektSelect
              name={budgetingVerarbeitungEntryFiltersFormFields.objekt}
              id={budgetingVerarbeitungEntryFiltersFormFields.objekt}
              items={objektList}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>

          <FormItemWithoutColon name={budgetingVerarbeitungEntryFiltersFormFields.exitCodeList} label="Exit Code Status">
            <GenerierlaufEntryExitCodeListSelect
              name={budgetingVerarbeitungEntryFiltersFormFields.exitCodeList}
              width="200px"
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
    />
  );
};

export default ListingFilters;
