import React, { FC } from 'react';
import { Empty } from 'antd';
import { Budgeting, BudgetingKonto } from '../../../../../types';
import budgetingKontoTableColumns from './budgetingKontoTableColumns';
import BudgetingBuchungszeileTable from '../Level4/BudgetingBuchungszeileTable';
import { IStatus } from '../../../../../helpers/statusHelper';
import NestedTableWithColSelector from '../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';

type Props = {
  budgeting: Budgeting;
  kontoList: BudgetingKonto[];
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: () => Promise<unknown>;
  isUpdating: boolean;
  updatingRowKey: string | null;
  setUpdatingRowKey: React.Dispatch<React.SetStateAction<string | null>>;
  budgetingVPosStatus: IStatus;
  parentRowKeys?: string[];
  visible?: boolean;
};

const BudgetingKontoTable: FC<Props> = ({
  budgeting,
  kontoList,
  isLoading,
  setIsLoading,
  refetch,
  isUpdating,
  updatingRowKey,
  setUpdatingRowKey,
  budgetingVPosStatus,
  parentRowKeys,
  visible = true,
}) => {
  const getRowKey = (record: BudgetingKonto) => record.budgetingKontoId;

  return (
    <NestedTableWithColSelector<BudgetingKonto>
      parentRowKeys={parentRowKeys}
      visible={visible}
      level={3}
      locale={{
        emptyText: <Empty description={<span>Keine Budgetierung vorhanden</span>} />,
      }}
      dataSource={kontoList}
      columns={budgetingKontoTableColumns(
        budgeting,
        isLoading,
        setIsLoading,
        refetch,
        isUpdating,
        updatingRowKey,
        setUpdatingRowKey,
        budgetingVPosStatus
      )}
      rowKey={getRowKey}
      expandable={{
        rowExpandable: (record) => !!record.budgetingBuchungszeileList.length,
        expandedRowRender: (record, index, indent, expanded) => {
          return (
            <BudgetingBuchungszeileTable
              parentRowKeys={[...(parentRowKeys ?? []), getRowKey(record)]}
              visible={visible && expanded}
              budgeting={budgeting}
              buchungszeileList={record.budgetingBuchungszeileList}
              loading={isLoading}
              refetch={refetch}
              isUpdating={isUpdating}
              budgetingVPosStatus={budgetingVPosStatus}
            />
          );
        },
      }}
    />
  );
};
export default BudgetingKontoTable;
