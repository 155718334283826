import React from 'react';
import { Button, Card, Divider, Drawer, Dropdown, Modal, Skeleton, Switch, Typography } from 'antd';
import {
  CheckOutlined,
  CloseCircleOutlined,
  EllipsisOutlined,
  HomeOutlined,
  IdcardOutlined,
  NumberOutlined,
  UserDeleteOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Spacer } from '../../../components/Grid';
import EditableTagGroup from './EditableTagGroup';
import { useToggle } from '../../../hooks/useToggle';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import VatIdentificationNumber from '../../../shared/components/VatIdentificationNumber/VatIdentificationNumber';
import CardTitle from '../../../components/Card/CardTitle';
import ContactList from '../../../shared/components/Contact/ContactList';
import BankDetailsCardDetailsInfoRow from '../../BankDetails/shared/BankDetailsCardDetailsInfoRow';

export const ContactCard = ({ contact }) => {
  const [isCollapsed, onCollapse] = useToggle();

  const items = [
    {
      key: '1',
      label: 'Rechtstraeger bearbeiten',
      onClick: onCollapse,
    },
    {
      key: '2',
      label: 'Rechtstraeger sperren',
      onClick: () => showConfirmLock(contact),
    },
    {
      key: '3',
      label: 'Rechtstraeger löschen',
      onClick: () => showConfirmDelete(contact),
      danger: true,
    },
  ];

  return (
    <Card
      style={{ minWidth: '320px' }}
      size="default"
      variant="borderless"
      title={<CardTitle title={contact.kurzBezeichnung ? contact.kurzBezeichnung : 'keine Bezeichnung'} status={contact.status} />}
      extra={
        <Dropdown menu={{ items }}>
          <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
        </Dropdown>
      }
    >
      <Skeleton avatar active loading={false}>
        {contact.vatIdentificationNumber ? (
          <CardDetailsInfoRow
            infoRowTitle={
              <>
                <IdcardOutlined style={{ marginRight: '16px' }} /> <Typography.Text>UID</Typography.Text>
              </>
            }
          >
            <VatIdentificationNumber vatIdentificationNumber={contact.vatIdentificationNumber} />
          </CardDetailsInfoRow>
        ) : null}
        {contact.geburtsdatum ? (
          <CardDetailsInfoRow
            infoRowTitle={
              <>
                <UserOutlined style={{ marginRight: '16px' }} />
                <Typography.Text>Geburtsdatum</Typography.Text>
              </>
            }
          >
            {/* FIXME: Use FormattedDate component once the contact data is not mocked anymore */}
            {contact.geburtsdatum}
          </CardDetailsInfoRow>
        ) : null}
        {contact.svaNr ? (
          <CardDetailsInfoRow
            infoRowTitle={
              <>
                <NumberOutlined style={{ marginRight: '16px' }} />
                <Typography.Text>SVA-Nr.</Typography.Text>
              </>
            }
          >
            {/* FIXME: Build and use SvaNr component once the contact data is not mocked anymore */}
            {contact.svaNr}
          </CardDetailsInfoRow>
        ) : null}
        {contact.sterbedatum ? (
          <CardDetailsInfoRow
            infoRowTitle={
              <>
                <UserDeleteOutlined style={{ marginRight: '16px' }} />
                <Typography.Text>Sterbedatum</Typography.Text>
              </>
            }
          >
            {/* FIXME: Use FormattedDate component once the contact data is not mocked anymore */}
            {contact.sterbedatum}
          </CardDetailsInfoRow>
        ) : null}
      </Skeleton>
      <Divider dashed />
      <Button.Group size="small" style={{ width: '100%' }}>
        <Button style={{ width: '50%' }}>Hauptkontakt</Button>
        <Button style={{ width: '50%' }}>Weitere Kontakte</Button>
      </Button.Group>
      <Spacer height={16} />
      <span>
        <HomeOutlined style={{ marginRight: '16px' }} />
        {contact.mainOperatingSite.street} {contact.mainOperatingSite.houseEntranceApartmentNumber}, {contact.mainOperatingSite.city},{' '}
        {contact.mainOperatingSite.zipCodeCity} {contact.mainOperatingSite.bezirk}
      </span>
      <br />
      <ContactList margin contactList={contact.contactList ?? []} />
      {contact.rechtstraegerId !== '2002' ? (
        <>
          <Divider dashed />
          <Typography.Title level={5}>Merkmale</Typography.Title>
          <EditableTagGroup />
        </>
      ) : null}
      <Divider dashed />
      <Typography.Title level={5}>Bankverbindung</Typography.Title>
      <BankDetailsCardDetailsInfoRow bankDetails={contact.bankDetails} />
      <br />
      <Divider dashed />
      <Typography.Title level={5}>Zuständiges Finanzamt</Typography.Title>
      FA{contact.finanzamt.Referat} {contact.finanzamt.city} {contact.finanzamt.nr} {contact.finanzamt.bezirk}
      <br />
      {/* FIXME: Build and use Steuernummer component once the contact data is not mocked anymore */}
      <CardDetailsInfoRow infoRowTitle="Steuernummer">
        <Typography.Text>{contact.finanzamt.steuernummer}</Typography.Text>
      </CardDetailsInfoRow>
      <CardDetailsInfoRow infoRowTitle="Referat">
        <Typography.Text>{contact.finanzamt.Referat}</Typography.Text>
      </CardDetailsInfoRow>
      {contact.finanzamt.finanzamtzahlungen ? (
        <CardDetailsInfoRow infoRowTitle="Kundendaten für Zahlungen">
          <Typography.Text>{contact.finanzamt.finanzamtzahlungen}</Typography.Text>
        </CardDetailsInfoRow>
      ) : null}
      <Divider dashed />
      <Typography.Title level={5}>Steuerbefreiung</Typography.Title>
      <Switch size="small" checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseCircleOutlined />} defaultChecked={false} />
      <span> {contact.finanzamt.steuerbefreiung}</span>
      <br />
      <Drawer title="Rechtstraeger bearbeiten" width={720} onClose={onCollapse} open={isCollapsed} destroyOnClose>
        @TODO Form
      </Drawer>
    </Card>
  );
};

export const showConfirmLock = (contact) => {
  Modal.confirm({
    title: 'Möchten Sie die Firma sperren?',
    content: `${contact.kurzBezeichnung}`,
    okText: 'Sperren',
    cancelText: 'Nein',
  });
};

export const showConfirmDelete = (contact) => {
  Modal.confirm({
    title: 'Möchten Sie die Firma löschen?',
    content: `${contact.kurzBezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
  });
};

export default ContactCard;
