import { FC } from 'react';
import { Skeleton } from 'antd';
import { useHeVertragZahlungsregelListQuery } from './gql/HeVertragZahlungsregelQueries.types';
import ZahlungsregelButtonsAndDrawer from './ZahlungsregelButtonsAndDrawer';
import { useToggle } from '../../../../hooks/useToggle';
import ZahlungsregelTable from './Table/ZahlungsregelTable';
import { useRechnungsAusstellerQuery } from '../../../Rechtstraeger/Rolle/RechnungsAussteller/gql/RechnungsAusstellerQueries.types';
import { HeVertrag } from '../../../../types';

type Props = {
  objektId: string;
  heVertragId: string;
  vertrag: HeVertrag;
  rechnungsAusstellerId: string;
};

const ZahlungsregelListing: FC<Props> = ({ objektId, heVertragId, vertrag, rechnungsAusstellerId }) => {
  const [showArchived, toggleShowArchived] = useToggle();

  const { data, loading, refetch } = useHeVertragZahlungsregelListQuery({ variables: { objektId, heVertragId, includeArchiviert: showArchived } });
  const zahlungsregelList = data?.getHeVertragZahlungsregelList.data;

  const { data: raData, loading: rtLoading } = useRechnungsAusstellerQuery({ variables: { rechtstraegerId: rechnungsAusstellerId } });
  const rechnungsAussteller = raData?.getRechnungsAussteller.data;

  if (!rechnungsAussteller) {
    return <Skeleton />;
  }

  const heAbrechnungsdefinition = rechnungsAussteller.heAbrechnungsdefinition;

  return (
    <>
      <ZahlungsregelButtonsAndDrawer
        objektId={objektId}
        heVertragId={heVertragId}
        onSuccess={refetch}
        showArchived={showArchived}
        toggleShowArchived={toggleShowArchived}
        vertragspartnerId={vertrag.vertragspartner.rechtstraegerId}
        heAbrechnungsdefinition={heAbrechnungsdefinition}
      />
      <ZahlungsregelTable
        objektId={objektId}
        heVertragId={heVertragId}
        loading={loading || rtLoading}
        zahlungsregelList={zahlungsregelList}
        heAbrechnungsdefinition={heAbrechnungsdefinition}
        onSuccess={refetch}
      />
    </>
  );
};

export default ZahlungsregelListing;
