import React, { FC } from 'react';
import { Button } from 'antd';

type Props = {
  cancelEdit: () => void;
  isSubmitLoading: boolean;
};

const ActionButtonsInEditMode: FC<Props> = ({ cancelEdit, isSubmitLoading }) => {
  return (
    <span>
      <Button htmlType="submit" type="link" loading={isSubmitLoading}>
        Speichern
      </Button>
      <Button size="small" type="link" onClick={cancelEdit}>
        Abbrechen
      </Button>
    </span>
  );
};

export default ActionButtonsInEditMode;
