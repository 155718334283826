import { Empty } from 'antd';
import React, { FC, RefObject } from 'react';
import bookingJournalTableColumns from './bookingJournalTableColumns';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../hooks/useQueryWithPagingAndSorting';
import { TBookingJournalQueryParams } from '../Filters/filtersQueryParams';
import { mapQueryParamsToBuchungszeileVariables } from '../Filters/filtersFormMapper';
import { useFibuBuchungszeileListQuery } from '../../gql/FibuBuchungszeileQueries.types';
import useOnEventsSetStickyTableHeader from '../../../../components/StickyHeader/useOnEventsSetStickyTableHeader';
import { StyledNestedTableNoBorderWhenScrollY } from './BookingJournalTable.style';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import NestedTableWithColSelectorWrapperStickyHeader from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelectorWrapperStickyHeader';

type Props = {
  queryParams: TBookingJournalQueryParams;
  filterRef: RefObject<HTMLDivElement>;
  summaryRef: RefObject<HTMLDivElement>;
  columnSelectorRef: RefObject<HTMLDivElement>;
};

const BookingJournalTable: FC<Props> = ({ queryParams, filterRef, summaryRef, columnSelectorRef }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useFibuBuchungszeileListQuery,
    {
      variables: {
        ...mapQueryParamsToBuchungszeileVariables(queryParams),
      },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const buchungszeileList = data?.getFibuBuchungszeileList.data?.contentList ?? [];

  useOnEventsSetStickyTableHeader(filterRef, summaryRef, columnSelectorRef);

  return (
    <NestedTableWithColSelectorWrapperStickyHeader filterIdentifier="booking-journal-main" columnSelectorRef={columnSelectorRef}>
      <StyledNestedTableNoBorderWhenScrollY
        sticky
        loading={loading}
        level={1}
        colWidth={150}
        locale={{
          emptyText: <Empty description={<span>Keine Buchungsjournale vorhanden</span>} />,
        }}
        dataSource={buchungszeileList}
        rowClassName={(record, index) => {
          if (index) {
            const previousRecord = buchungszeileList[index - 1];
            const nextRecord = buchungszeileList[index + 1];
            const classNames = [];
            if (record.buchungId !== previousRecord?.buchungId) {
              classNames.push('ant-table-cell-border-top');
            }

            if (record.buchungId !== nextRecord?.buchungId) {
              classNames.push('ant-table-cell-border-bottom');
            }

            return classNames.join(' ');
          }
          return '';
        }}
        pagination={pagination}
        onChange={handleTableSorting}
        columns={bookingJournalTableColumns(buchungszeileList)}
        rowKey={(record) => record.buchungszeileId}
      />
    </NestedTableWithColSelectorWrapperStickyHeader>
  );
};

export default BookingJournalTable;
