import React from 'react';
import { Space, Tag, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { AbrechnungTypeTuple, ObjAbrKonto } from '../../../../../../../types';
import TextForEmptyValue from '../../../../../../../components/Text/TextForEmptyValue';
import { generatePathToKontoblattPage } from '../../../../../../Kontoblatt/kontoblattUriPaths';
import { EuroAmount } from '../../../../../../../components/Number';
import { NestedTableWithColSelectorColProps } from '../../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';
import { isAbrechnungTypeBkAbrechnung } from '../../../../../shared/abrechnungHelpers';

const objektAbrechnungeinnahmenKontoTableColumns = (
  objektId: string,
  fromInclusive: string,
  toInclusive: string,
  buchungskreisId: string,
  abrechnungType: AbrechnungTypeTuple
): NestedTableWithColSelectorColProps<ObjAbrKonto>[] => [
  {
    title: isAbrechnungTypeBkAbrechnung(abrechnungType.value) ? (
      <Space>
        <Typography.Text>Erlöse</Typography.Text>
        <Typography.Text type="secondary" italic>
          (aus Subverwaltung)
        </Typography.Text>
      </Space>
    ) : (
      'Erlöse'
    ),
    colSelectorTitle: 'Erlöse',
    key: 'abrechnungskreis',
    width: 200,
    render: (text, record) => <Typography.Text>{record.bezeichnung}</Typography.Text>,
  },
  {
    title: '',
    width: 100,
  },
  {
    title: '',
    width: 100,
  },
  {
    title: '',
    width: 100,
  },

  {
    title: 'Aufteilungsschlüssel',
    key: 'aufteilungsschluessel',
    width: 200,
    align: 'right',
    render: (text, record) =>
      record.aufteilungsschluesselList.length > 0 ? (
        <Space style={{ width: '100%', justifyContent: 'center' }} wrap>
          {record.aufteilungsschluesselList.map((aufteilungsschluessel, index) => (
            <Tag key={index}>{aufteilungsschluessel}</Tag>
          ))}
        </Space>
      ) : (
        <TextForEmptyValue textToShow="none" />
      ),
  },
  {
    title: '',
    key: 'ausgaben',
    width: 180,
  },
  {
    title: 'Einnahmen',
    key: 'einnahmen',
    align: 'right',
    width: 180,
    defaultSelected: true,
    render: (konto) => (
      <Link
        target="_blank"
        to={generatePathToKontoblattPage({
          kontoId: konto.kontoId,
          objektId,
          buchungsdatumFrom: fromInclusive,
          buchungsdatumTo: toInclusive,
          buchungskreisId,
        })}
      >
        <EuroAmount value={konto.summe} />
      </Link>
    ),
  },
  {
    title: '',
    key: 'saldo',
    width: 120,
  },
];

export default objektAbrechnungeinnahmenKontoTableColumns;
