import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Empty } from 'antd';
import TableWithColSelector from '../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import GenerierlaufStatusExpandedRow from '../../../shared/GenerierlaufStatusExpandedRow';
import { isVerarbeitungEntryTableRowExpandable } from '../../../verarbeitungHelpers';
import { ObjektFinancialPlanGenerierlauf, ObjektFinancialPlanGenerierlaufEntry } from '../../../../../types';
import { ObjektFinancialPlanVerarbeitungEntryFiltersFormValues } from '../Filters/ListingFiltersFormMapper';
import objektFinancialPlanVerarbeitungEntryTableColumns from './objektFinancialPlanVerarbeitungEntryTableColumns';

type Props = {
  generierlauf: ObjektFinancialPlanGenerierlauf;
  formikProps: FormikProps<ObjektFinancialPlanVerarbeitungEntryFiltersFormValues>;
};

const ObjektFinancialPlanVerarbeitungEntryTable: FC<Props> = ({ generierlauf, formikProps }) => {
  const { exitCodeList, objektId, budgetingId, rechnungsAusstellerId } = formikProps.values;

  const objektFilter = () => {
    if (!objektId) return true;
    return objektId === generierlauf.budgeting.objekt.objektId;
  };

  const budgetingFilter = () => {
    if (!budgetingId) return true;
    return budgetingId === generierlauf.budgeting.budgetingId;
  };

  const rechnungsAusstellerFilter = () => {
    if (!rechnungsAusstellerId) return true;
    return rechnungsAusstellerId === generierlauf.budgeting.objekt.rechnungsAussteller.rechtstraegerId;
  };

  const exitCodeStatusFilter = (entry: ObjektFinancialPlanGenerierlaufEntry) => {
    if (!(exitCodeList && exitCodeList.length)) return true;
    return entry.exitCode?.text ? exitCodeList.includes(entry.exitCode.value) : false;
  };

  const filteredEntryList = generierlauf.entryList
    .filter(rechnungsAusstellerFilter)
    .filter(budgetingFilter)
    .filter(objektFilter)
    .filter(exitCodeStatusFilter);

  return (
    <TableWithColSelector<ObjektFinancialPlanGenerierlaufEntry>
      loading={!generierlauf.entryList}
      locale={{
        emptyText: <Empty description={<span>Keine Daten vorhanden</span>} />,
      }}
      size="small"
      dataSource={filteredEntryList}
      columns={objektFinancialPlanVerarbeitungEntryTableColumns(generierlauf.budgeting)}
      rowKey={(record) => record.generierlaufEntryId}
      expandable={{
        expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
        rowExpandable: isVerarbeitungEntryTableRowExpandable,
      }}
      filterIdentifier="verarbeitung-objekt-financial-plan"
    />
  );
};

export default ObjektFinancialPlanVerarbeitungEntryTable;
