import React from 'react';
import { Link } from 'react-router-dom';
import { FirmendatenBase } from '../../../../types';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import MitarbeiterTooltip from '../../../../components/Card/MitarbeiterTooltip';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { generatePathToFirmendatenDetails } from '../../../KundenSystem/Firmendaten/firmendatenUriPaths';

const firmendatenInitializerVerarbeitungTableColumns: TableWithColSelectorColumnProps<FirmendatenBase>[] = [
  {
    title: 'Bezeichnung',
    defaultSelected: true,
    render: (text, record) => <DataWithShortenedText text={record.kurzBezeichnung} maxTextLength={30} />,
  },
  {
    title: 'Erstellt am',
    defaultSelected: true,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    defaultSelected: true,
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => (
      <Link to={generatePathToFirmendatenDetails(record.firmendatenId)} target="_blank">
        Details
      </Link>
    ),
  },
];
export default firmendatenInitializerVerarbeitungTableColumns;
