import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReceivedBankTransactionListQueryVariables = Types.Exact<{
  amountFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  amountTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  bankStatementIban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  booked?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  bookingDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  bookingDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buchungskreisRechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  chargesFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  chargesInSeparateEntry?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  chargesTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  createTsFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createdByMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  creditor?: Types.InputMaybe<Types.Scalars['String']['input']>;
  creditorIban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dataCarrierName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  debtor?: Types.InputMaybe<Types.Scalars['String']['input']>;
  debtorIban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  endToEndId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  hasWarning?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  noted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  paymentAssigned?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  paymentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  paymentReferenceText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  purposeOfUseText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  reversal?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  statementNumber?: Types.InputMaybe<Types.Scalars['String']['input']>;
  status?: Types.InputMaybe<Types.ReceivedBankTransactionStatus>;
  transactionType?: Types.InputMaybe<Types.TransactionType>;
  valid?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  valueDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  valueDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  order?: Types.InputMaybe<Types.ReceivedBankTransactionOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type ReceivedBankTransactionListQuery = {
  getReceivedBankTransactionList: {
    data: {
      contentList: Array<{
        amount: number;
        availableAmount?: number | null;
        assignPaymentAllowed: boolean;
        bankStatementIban: string;
        booked: boolean;
        bookingDate?: string | null;
        charges?: number | null;
        chargesInSeparateEntry?: boolean | null;
        countBookingSuggestionClearingSuggestion?: number | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        creditor?: string | null;
        creditorIban?: string | null;
        dataCarrierId: string;
        dataCarrierName: string;
        debtor?: string | null;
        debtorIban?: string | null;
        endToEndId?: string | null;
        entryReference?: string | null;
        noted: boolean;
        index?: number | null;
        paymentReferenceText?: string | null;
        purposeOfUseText?: string | null;
        receivedBankTransactionId: string;
        reversal: boolean;
        statementNumber: string;
        valid?: boolean | null;
        valueDate?: string | null;
        warning?: string | null;
        bookingSuggestionFibuInfoList: Array<{
          bookingSuggestionId: string;
          currentFibuBuchungId?: string | null;
          fibuBuchungIdList: Array<string>;
          name: string;
        }>;
        payment?: {
          amount: number;
          payeeId: string;
          paymentDate: string;
          paymentLinkText: string;
          rechnungsAusstellerId: string;
          paymentType: { text: string; value: Types.PaymentType };
        } | null;
        status: { text: string; value: Types.ReceivedBankTransactionStatus };
        transactionType: { text: string; value: Types.TransactionType };
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    errorList: Array<{ type: string; message: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type ReceivedBankTransactionListSummaryQueryVariables = Types.Exact<{
  amountFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  amountTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  booked?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  bookingDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  bookingDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  buchungskreisRechtstraegerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  chargesFrom?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  chargesInSeparateEntry?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  chargesTo?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  createTsFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createTsTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createdByMitarbeiterIdList?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  creditor?: Types.InputMaybe<Types.Scalars['String']['input']>;
  creditorIban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  dataCarrierName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  debtor?: Types.InputMaybe<Types.Scalars['String']['input']>;
  debtorIban?: Types.InputMaybe<Types.Scalars['String']['input']>;
  endToEndId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  hasWarning?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  noted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  objektId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  paymentAssigned?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  paymentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  paymentReferenceText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  purposeOfUseText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  reversal?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  statementNumber?: Types.InputMaybe<Types.Scalars['String']['input']>;
  status?: Types.InputMaybe<Types.ReceivedBankTransactionStatus>;
  transactionType?: Types.InputMaybe<Types.TransactionType>;
  valid?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  valueDateFrom?: Types.InputMaybe<Types.Scalars['String']['input']>;
  valueDateTo?: Types.InputMaybe<Types.Scalars['String']['input']>;
  order?: Types.InputMaybe<Types.ReceivedBankTransactionOrderBy>;
  orderDir?: Types.InputMaybe<Types.Scalars['String']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type ReceivedBankTransactionListSummaryQuery = {
  getReceivedBankTransactionListSummary: {
    data: { sumAmount?: number | null; sumCharges?: number | null };
    errorList: Array<{ type: string; message: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type ReceivedBankTransactionListForPaymentQueryVariables = Types.Exact<{
  paymentId: Types.Scalars['ID']['input'];
}>;

export type ReceivedBankTransactionListForPaymentQuery = {
  getReceivedBankTransactionList: {
    data: {
      contentList: Array<{
        amount: number;
        availableAmount?: number | null;
        bankStatementIban: string;
        booked: boolean;
        bookingDate?: string | null;
        assignPaymentAllowed: boolean;
        charges?: number | null;
        chargesInSeparateEntry?: boolean | null;
        countBookingSuggestionClearingSuggestion?: number | null;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        creditor?: string | null;
        creditorIban?: string | null;
        dataCarrierId: string;
        dataCarrierName: string;
        debtor?: string | null;
        debtorIban?: string | null;
        endToEndId?: string | null;
        entryReference?: string | null;
        noted: boolean;
        paymentReferenceText?: string | null;
        purposeOfUseText?: string | null;
        receivedBankTransactionId: string;
        reversal: boolean;
        statementNumber: string;
        valid?: boolean | null;
        valueDate?: string | null;
        bookingSuggestionFibuInfoList: Array<{ bookingSuggestionId: string; fibuBuchungIdList: Array<string>; name: string }>;
        payment?: {
          amount: number;
          payeeId: string;
          paymentDate: string;
          paymentLinkText: string;
          rechnungsAusstellerId: string;
          paymentType: { text: string; value: Types.PaymentType };
        } | null;
        status: { text: string; value: Types.ReceivedBankTransactionStatus };
        transactionType: { text: string; value: Types.TransactionType };
      }>;
      page: { number: number; size: number; totalElements: number; totalPages: number };
    };
    errorList: Array<{ type: string; message: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterReceivedBankTransactionCreditorListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FilterReceivedBankTransactionCreditorListQuery = {
  getFilterReceivedBankTransactionCreditorList: {
    data?: Array<string> | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterReceivedBankTransactionCreditorIbanListQueryVariables = Types.Exact<{
  creditor?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type FilterReceivedBankTransactionCreditorIbanListQuery = {
  getFilterReceivedBankTransactionCreditorIbanList: {
    data?: Array<string> | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterReceivedBankTransactionDebtorListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FilterReceivedBankTransactionDebtorListQuery = {
  getFilterReceivedBankTransactionDebtorList: {
    data?: Array<string> | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterReceivedBankTransactionDebtorIbanListQueryVariables = Types.Exact<{
  debtor?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type FilterReceivedBankTransactionDebtorIbanListQuery = {
  getFilterReceivedBankTransactionDebtorIbanList: {
    data?: Array<string> | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterReceivedBankTransactionPaymentReferenceTextListQueryVariables = Types.Exact<{
  paymentReferenceText?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type FilterReceivedBankTransactionPaymentReferenceTextListQuery = {
  getFilterReceivedBankTransactionPaymentReferenceTextList: {
    data?: Array<string> | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterReceivedBankTransactionPurposeOfUseTextListQueryVariables = Types.Exact<{
  purposeOfUseText?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type FilterReceivedBankTransactionPurposeOfUseTextListQuery = {
  getFilterReceivedBankTransactionPurposeOfUseTextList: {
    data?: Array<string> | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterReceivedBankTransactionStatementNumberListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FilterReceivedBankTransactionStatementNumberListQuery = {
  getFilterReceivedBankTransactionStatementNumberList: {
    data?: Array<string> | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterReceivedBankTransactionEndToEndIdListQueryVariables = Types.Exact<{
  endToEndId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type FilterReceivedBankTransactionEndToEndIdListQuery = {
  getFilterReceivedBankTransactionEndToEndIdList: {
    data?: Array<string> | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterReceivedBankTransactionStatusListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FilterReceivedBankTransactionStatusListQuery = {
  getFilterReceivedBankTransactionStatusList: {
    data: Array<{ text: string; value: Types.ReceivedBankTransactionStatus }>;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type FilterReceivedBankTransactionBankstatementIbanListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FilterReceivedBankTransactionBankstatementIbanListQuery = {
  getFilterReceivedBankTransactionBankstatementIbanList: {
    data?: Array<string> | null;
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const ReceivedBankTransactionListDocument = gql`
  query ReceivedBankTransactionList(
    $amountFrom: Float
    $amountTo: Float
    $bankStatementIban: String
    $booked: Boolean
    $bookingDateFrom: String
    $bookingDateTo: String
    $buchungskreisRechtstraegerId: String
    $chargesFrom: Float
    $chargesInSeparateEntry: Boolean
    $chargesTo: Float
    $createTsFrom: String
    $createTsTo: String
    $createdByMitarbeiterIdList: [String!]
    $creditor: String
    $creditorIban: String
    $dataCarrierName: String
    $debtor: String
    $debtorIban: String
    $endToEndId: String
    $hasWarning: Boolean
    $includeArchiviert: Boolean
    $noted: Boolean
    $objektId: String
    $paymentAssigned: Boolean
    $paymentId: ID
    $paymentReferenceText: String
    $purposeOfUseText: String
    $reversal: Boolean
    $statementNumber: String
    $status: ReceivedBankTransactionStatus
    $transactionType: TransactionType
    $valid: Boolean
    $valueDateFrom: String
    $valueDateTo: String
    $order: ReceivedBankTransactionOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getReceivedBankTransactionList(
      amountFrom: $amountFrom
      amountTo: $amountTo
      bankStatementIban: $bankStatementIban
      booked: $booked
      bookingDateFrom: $bookingDateFrom
      bookingDateTo: $bookingDateTo
      buchungskreisRechtstraegerId: $buchungskreisRechtstraegerId
      chargesFrom: $chargesFrom
      chargesInSeparateEntry: $chargesInSeparateEntry
      chargesTo: $chargesTo
      createTsFrom: $createTsFrom
      createTsTo: $createTsTo
      createdByMitarbeiterIdList: $createdByMitarbeiterIdList
      creditor: $creditor
      creditorIban: $creditorIban
      dataCarrierName: $dataCarrierName
      debtor: $debtor
      debtorIban: $debtorIban
      endToEndId: $endToEndId
      hasWarning: $hasWarning
      includeArchiviert: $includeArchiviert
      noted: $noted
      objektId: $objektId
      paymentAssigned: $paymentAssigned
      paymentId: $paymentId
      paymentReferenceText: $paymentReferenceText
      purposeOfUseText: $purposeOfUseText
      reversal: $reversal
      statementNumber: $statementNumber
      status: $status
      transactionType: $transactionType
      valid: $valid
      valueDateFrom: $valueDateFrom
      valueDateTo: $valueDateTo
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        contentList {
          amount
          availableAmount
          assignPaymentAllowed
          bankStatementIban
          booked
          bookingDate
          bookingSuggestionFibuInfoList {
            bookingSuggestionId
            currentFibuBuchungId
            fibuBuchungIdList
            name
          }
          charges
          chargesInSeparateEntry
          countBookingSuggestionClearingSuggestion
          createTs
          createdBy
          createdByMitarbeiterId
          creditor
          creditorIban
          dataCarrierId
          dataCarrierName
          debtor
          debtorIban
          endToEndId
          entryReference
          noted
          index
          payment {
            amount
            payeeId
            paymentDate
            paymentLinkText
            paymentType {
              text
              value
            }
            rechnungsAusstellerId
          }
          paymentReferenceText
          purposeOfUseText
          receivedBankTransactionId
          reversal
          statementNumber
          status {
            text
            value
          }
          transactionType {
            text
            value
          }
          valid
          valueDate
          warning
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      errorList {
        type
        message
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useReceivedBankTransactionListQuery(
  baseOptions?: Apollo.QueryHookOptions<ReceivedBankTransactionListQuery, ReceivedBankTransactionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReceivedBankTransactionListQuery, ReceivedBankTransactionListQueryVariables>(ReceivedBankTransactionListDocument, options);
}
export function useReceivedBankTransactionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReceivedBankTransactionListQuery, ReceivedBankTransactionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReceivedBankTransactionListQuery, ReceivedBankTransactionListQueryVariables>(
    ReceivedBankTransactionListDocument,
    options
  );
}
export function useReceivedBankTransactionListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReceivedBankTransactionListQuery, ReceivedBankTransactionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ReceivedBankTransactionListQuery, ReceivedBankTransactionListQueryVariables>(
    ReceivedBankTransactionListDocument,
    options
  );
}
export type ReceivedBankTransactionListQueryHookResult = ReturnType<typeof useReceivedBankTransactionListQuery>;
export type ReceivedBankTransactionListLazyQueryHookResult = ReturnType<typeof useReceivedBankTransactionListLazyQuery>;
export type ReceivedBankTransactionListSuspenseQueryHookResult = ReturnType<typeof useReceivedBankTransactionListSuspenseQuery>;
export type ReceivedBankTransactionListQueryResult = Apollo.QueryResult<ReceivedBankTransactionListQuery, ReceivedBankTransactionListQueryVariables>;
export const ReceivedBankTransactionListSummaryDocument = gql`
  query ReceivedBankTransactionListSummary(
    $amountFrom: Float
    $amountTo: Float
    $booked: Boolean
    $bookingDateFrom: String
    $bookingDateTo: String
    $buchungskreisRechtstraegerId: String
    $chargesFrom: Float
    $chargesInSeparateEntry: Boolean
    $chargesTo: Float
    $createTsFrom: String
    $createTsTo: String
    $createdByMitarbeiterIdList: [String!]
    $creditor: String
    $creditorIban: String
    $dataCarrierName: String
    $debtor: String
    $debtorIban: String
    $endToEndId: String
    $hasWarning: Boolean
    $includeArchiviert: Boolean
    $noted: Boolean
    $objektId: String
    $paymentAssigned: Boolean
    $paymentId: ID
    $paymentReferenceText: String
    $purposeOfUseText: String
    $reversal: Boolean
    $statementNumber: String
    $status: ReceivedBankTransactionStatus
    $transactionType: TransactionType
    $valid: Boolean
    $valueDateFrom: String
    $valueDateTo: String
    $order: ReceivedBankTransactionOrderBy
    $orderDir: String
    $page: Int
    $pageSize: Int
  ) {
    getReceivedBankTransactionListSummary(
      amountFrom: $amountFrom
      amountTo: $amountTo
      booked: $booked
      bookingDateFrom: $bookingDateFrom
      bookingDateTo: $bookingDateTo
      buchungskreisRechtstraegerId: $buchungskreisRechtstraegerId
      chargesFrom: $chargesFrom
      chargesInSeparateEntry: $chargesInSeparateEntry
      chargesTo: $chargesTo
      createTsFrom: $createTsFrom
      createTsTo: $createTsTo
      createdByMitarbeiterIdList: $createdByMitarbeiterIdList
      creditor: $creditor
      creditorIban: $creditorIban
      dataCarrierName: $dataCarrierName
      debtor: $debtor
      debtorIban: $debtorIban
      endToEndId: $endToEndId
      hasWarning: $hasWarning
      includeArchiviert: $includeArchiviert
      noted: $noted
      objektId: $objektId
      paymentAssigned: $paymentAssigned
      paymentId: $paymentId
      paymentReferenceText: $paymentReferenceText
      purposeOfUseText: $purposeOfUseText
      reversal: $reversal
      statementNumber: $statementNumber
      status: $status
      transactionType: $transactionType
      valid: $valid
      valueDateFrom: $valueDateFrom
      valueDateTo: $valueDateTo
      order: $order
      orderDir: $orderDir
      page: $page
      pageSize: $pageSize
    ) {
      data {
        sumAmount
        sumCharges
      }
      errorList {
        type
        message
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useReceivedBankTransactionListSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<ReceivedBankTransactionListSummaryQuery, ReceivedBankTransactionListSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReceivedBankTransactionListSummaryQuery, ReceivedBankTransactionListSummaryQueryVariables>(
    ReceivedBankTransactionListSummaryDocument,
    options
  );
}
export function useReceivedBankTransactionListSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReceivedBankTransactionListSummaryQuery, ReceivedBankTransactionListSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReceivedBankTransactionListSummaryQuery, ReceivedBankTransactionListSummaryQueryVariables>(
    ReceivedBankTransactionListSummaryDocument,
    options
  );
}
export function useReceivedBankTransactionListSummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ReceivedBankTransactionListSummaryQuery, ReceivedBankTransactionListSummaryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ReceivedBankTransactionListSummaryQuery, ReceivedBankTransactionListSummaryQueryVariables>(
    ReceivedBankTransactionListSummaryDocument,
    options
  );
}
export type ReceivedBankTransactionListSummaryQueryHookResult = ReturnType<typeof useReceivedBankTransactionListSummaryQuery>;
export type ReceivedBankTransactionListSummaryLazyQueryHookResult = ReturnType<typeof useReceivedBankTransactionListSummaryLazyQuery>;
export type ReceivedBankTransactionListSummarySuspenseQueryHookResult = ReturnType<typeof useReceivedBankTransactionListSummarySuspenseQuery>;
export type ReceivedBankTransactionListSummaryQueryResult = Apollo.QueryResult<
  ReceivedBankTransactionListSummaryQuery,
  ReceivedBankTransactionListSummaryQueryVariables
>;
export const ReceivedBankTransactionListForPaymentDocument = gql`
  query ReceivedBankTransactionListForPayment($paymentId: ID!) {
    getReceivedBankTransactionList(paymentId: $paymentId) {
      data {
        contentList {
          amount
          availableAmount
          bankStatementIban
          booked
          bookingDate
          assignPaymentAllowed
          bookingSuggestionFibuInfoList {
            bookingSuggestionId
            fibuBuchungIdList
            name
          }
          charges
          chargesInSeparateEntry
          countBookingSuggestionClearingSuggestion
          createTs
          createdBy
          createdByMitarbeiterId
          creditor
          creditorIban
          dataCarrierId
          dataCarrierName
          debtor
          debtorIban
          endToEndId
          entryReference
          noted
          payment {
            amount
            payeeId
            paymentDate
            paymentLinkText
            paymentType {
              text
              value
            }
            rechnungsAusstellerId
          }
          paymentReferenceText
          purposeOfUseText
          receivedBankTransactionId
          reversal
          statementNumber
          status {
            text
            value
          }
          transactionType {
            text
            value
          }
          valid
          valueDate
        }
        page {
          number
          size
          totalElements
          totalPages
        }
      }
      errorList {
        type
        message
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useReceivedBankTransactionListForPaymentQuery(
  baseOptions: Apollo.QueryHookOptions<ReceivedBankTransactionListForPaymentQuery, ReceivedBankTransactionListForPaymentQueryVariables> &
    ({ variables: ReceivedBankTransactionListForPaymentQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReceivedBankTransactionListForPaymentQuery, ReceivedBankTransactionListForPaymentQueryVariables>(
    ReceivedBankTransactionListForPaymentDocument,
    options
  );
}
export function useReceivedBankTransactionListForPaymentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReceivedBankTransactionListForPaymentQuery, ReceivedBankTransactionListForPaymentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReceivedBankTransactionListForPaymentQuery, ReceivedBankTransactionListForPaymentQueryVariables>(
    ReceivedBankTransactionListForPaymentDocument,
    options
  );
}
export function useReceivedBankTransactionListForPaymentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ReceivedBankTransactionListForPaymentQuery, ReceivedBankTransactionListForPaymentQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ReceivedBankTransactionListForPaymentQuery, ReceivedBankTransactionListForPaymentQueryVariables>(
    ReceivedBankTransactionListForPaymentDocument,
    options
  );
}
export type ReceivedBankTransactionListForPaymentQueryHookResult = ReturnType<typeof useReceivedBankTransactionListForPaymentQuery>;
export type ReceivedBankTransactionListForPaymentLazyQueryHookResult = ReturnType<typeof useReceivedBankTransactionListForPaymentLazyQuery>;
export type ReceivedBankTransactionListForPaymentSuspenseQueryHookResult = ReturnType<typeof useReceivedBankTransactionListForPaymentSuspenseQuery>;
export type ReceivedBankTransactionListForPaymentQueryResult = Apollo.QueryResult<
  ReceivedBankTransactionListForPaymentQuery,
  ReceivedBankTransactionListForPaymentQueryVariables
>;
export const FilterReceivedBankTransactionCreditorListDocument = gql`
  query FilterReceivedBankTransactionCreditorList {
    getFilterReceivedBankTransactionCreditorList {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterReceivedBankTransactionCreditorListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterReceivedBankTransactionCreditorListQuery, FilterReceivedBankTransactionCreditorListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterReceivedBankTransactionCreditorListQuery, FilterReceivedBankTransactionCreditorListQueryVariables>(
    FilterReceivedBankTransactionCreditorListDocument,
    options
  );
}
export function useFilterReceivedBankTransactionCreditorListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterReceivedBankTransactionCreditorListQuery, FilterReceivedBankTransactionCreditorListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterReceivedBankTransactionCreditorListQuery, FilterReceivedBankTransactionCreditorListQueryVariables>(
    FilterReceivedBankTransactionCreditorListDocument,
    options
  );
}
export function useFilterReceivedBankTransactionCreditorListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterReceivedBankTransactionCreditorListQuery, FilterReceivedBankTransactionCreditorListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterReceivedBankTransactionCreditorListQuery, FilterReceivedBankTransactionCreditorListQueryVariables>(
    FilterReceivedBankTransactionCreditorListDocument,
    options
  );
}
export type FilterReceivedBankTransactionCreditorListQueryHookResult = ReturnType<typeof useFilterReceivedBankTransactionCreditorListQuery>;
export type FilterReceivedBankTransactionCreditorListLazyQueryHookResult = ReturnType<typeof useFilterReceivedBankTransactionCreditorListLazyQuery>;
export type FilterReceivedBankTransactionCreditorListSuspenseQueryHookResult = ReturnType<
  typeof useFilterReceivedBankTransactionCreditorListSuspenseQuery
>;
export type FilterReceivedBankTransactionCreditorListQueryResult = Apollo.QueryResult<
  FilterReceivedBankTransactionCreditorListQuery,
  FilterReceivedBankTransactionCreditorListQueryVariables
>;
export const FilterReceivedBankTransactionCreditorIbanListDocument = gql`
  query FilterReceivedBankTransactionCreditorIbanList($creditor: String) {
    getFilterReceivedBankTransactionCreditorIbanList(creditor: $creditor) {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterReceivedBankTransactionCreditorIbanListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FilterReceivedBankTransactionCreditorIbanListQuery,
    FilterReceivedBankTransactionCreditorIbanListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterReceivedBankTransactionCreditorIbanListQuery, FilterReceivedBankTransactionCreditorIbanListQueryVariables>(
    FilterReceivedBankTransactionCreditorIbanListDocument,
    options
  );
}
export function useFilterReceivedBankTransactionCreditorIbanListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterReceivedBankTransactionCreditorIbanListQuery,
    FilterReceivedBankTransactionCreditorIbanListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterReceivedBankTransactionCreditorIbanListQuery, FilterReceivedBankTransactionCreditorIbanListQueryVariables>(
    FilterReceivedBankTransactionCreditorIbanListDocument,
    options
  );
}
export function useFilterReceivedBankTransactionCreditorIbanListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterReceivedBankTransactionCreditorIbanListQuery, FilterReceivedBankTransactionCreditorIbanListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterReceivedBankTransactionCreditorIbanListQuery, FilterReceivedBankTransactionCreditorIbanListQueryVariables>(
    FilterReceivedBankTransactionCreditorIbanListDocument,
    options
  );
}
export type FilterReceivedBankTransactionCreditorIbanListQueryHookResult = ReturnType<typeof useFilterReceivedBankTransactionCreditorIbanListQuery>;
export type FilterReceivedBankTransactionCreditorIbanListLazyQueryHookResult = ReturnType<
  typeof useFilterReceivedBankTransactionCreditorIbanListLazyQuery
>;
export type FilterReceivedBankTransactionCreditorIbanListSuspenseQueryHookResult = ReturnType<
  typeof useFilterReceivedBankTransactionCreditorIbanListSuspenseQuery
>;
export type FilterReceivedBankTransactionCreditorIbanListQueryResult = Apollo.QueryResult<
  FilterReceivedBankTransactionCreditorIbanListQuery,
  FilterReceivedBankTransactionCreditorIbanListQueryVariables
>;
export const FilterReceivedBankTransactionDebtorListDocument = gql`
  query FilterReceivedBankTransactionDebtorList {
    getFilterReceivedBankTransactionDebtorList {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterReceivedBankTransactionDebtorListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterReceivedBankTransactionDebtorListQuery, FilterReceivedBankTransactionDebtorListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterReceivedBankTransactionDebtorListQuery, FilterReceivedBankTransactionDebtorListQueryVariables>(
    FilterReceivedBankTransactionDebtorListDocument,
    options
  );
}
export function useFilterReceivedBankTransactionDebtorListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterReceivedBankTransactionDebtorListQuery, FilterReceivedBankTransactionDebtorListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterReceivedBankTransactionDebtorListQuery, FilterReceivedBankTransactionDebtorListQueryVariables>(
    FilterReceivedBankTransactionDebtorListDocument,
    options
  );
}
export function useFilterReceivedBankTransactionDebtorListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterReceivedBankTransactionDebtorListQuery, FilterReceivedBankTransactionDebtorListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterReceivedBankTransactionDebtorListQuery, FilterReceivedBankTransactionDebtorListQueryVariables>(
    FilterReceivedBankTransactionDebtorListDocument,
    options
  );
}
export type FilterReceivedBankTransactionDebtorListQueryHookResult = ReturnType<typeof useFilterReceivedBankTransactionDebtorListQuery>;
export type FilterReceivedBankTransactionDebtorListLazyQueryHookResult = ReturnType<typeof useFilterReceivedBankTransactionDebtorListLazyQuery>;
export type FilterReceivedBankTransactionDebtorListSuspenseQueryHookResult = ReturnType<
  typeof useFilterReceivedBankTransactionDebtorListSuspenseQuery
>;
export type FilterReceivedBankTransactionDebtorListQueryResult = Apollo.QueryResult<
  FilterReceivedBankTransactionDebtorListQuery,
  FilterReceivedBankTransactionDebtorListQueryVariables
>;
export const FilterReceivedBankTransactionDebtorIbanListDocument = gql`
  query FilterReceivedBankTransactionDebtorIbanList($debtor: String) {
    getFilterReceivedBankTransactionDebtorIbanList(debtor: $debtor) {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterReceivedBankTransactionDebtorIbanListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterReceivedBankTransactionDebtorIbanListQuery, FilterReceivedBankTransactionDebtorIbanListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterReceivedBankTransactionDebtorIbanListQuery, FilterReceivedBankTransactionDebtorIbanListQueryVariables>(
    FilterReceivedBankTransactionDebtorIbanListDocument,
    options
  );
}
export function useFilterReceivedBankTransactionDebtorIbanListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterReceivedBankTransactionDebtorIbanListQuery,
    FilterReceivedBankTransactionDebtorIbanListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterReceivedBankTransactionDebtorIbanListQuery, FilterReceivedBankTransactionDebtorIbanListQueryVariables>(
    FilterReceivedBankTransactionDebtorIbanListDocument,
    options
  );
}
export function useFilterReceivedBankTransactionDebtorIbanListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterReceivedBankTransactionDebtorIbanListQuery, FilterReceivedBankTransactionDebtorIbanListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterReceivedBankTransactionDebtorIbanListQuery, FilterReceivedBankTransactionDebtorIbanListQueryVariables>(
    FilterReceivedBankTransactionDebtorIbanListDocument,
    options
  );
}
export type FilterReceivedBankTransactionDebtorIbanListQueryHookResult = ReturnType<typeof useFilterReceivedBankTransactionDebtorIbanListQuery>;
export type FilterReceivedBankTransactionDebtorIbanListLazyQueryHookResult = ReturnType<
  typeof useFilterReceivedBankTransactionDebtorIbanListLazyQuery
>;
export type FilterReceivedBankTransactionDebtorIbanListSuspenseQueryHookResult = ReturnType<
  typeof useFilterReceivedBankTransactionDebtorIbanListSuspenseQuery
>;
export type FilterReceivedBankTransactionDebtorIbanListQueryResult = Apollo.QueryResult<
  FilterReceivedBankTransactionDebtorIbanListQuery,
  FilterReceivedBankTransactionDebtorIbanListQueryVariables
>;
export const FilterReceivedBankTransactionPaymentReferenceTextListDocument = gql`
  query FilterReceivedBankTransactionPaymentReferenceTextList($paymentReferenceText: String) {
    getFilterReceivedBankTransactionPaymentReferenceTextList(paymentReferenceText: $paymentReferenceText) {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterReceivedBankTransactionPaymentReferenceTextListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FilterReceivedBankTransactionPaymentReferenceTextListQuery,
    FilterReceivedBankTransactionPaymentReferenceTextListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FilterReceivedBankTransactionPaymentReferenceTextListQuery,
    FilterReceivedBankTransactionPaymentReferenceTextListQueryVariables
  >(FilterReceivedBankTransactionPaymentReferenceTextListDocument, options);
}
export function useFilterReceivedBankTransactionPaymentReferenceTextListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterReceivedBankTransactionPaymentReferenceTextListQuery,
    FilterReceivedBankTransactionPaymentReferenceTextListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FilterReceivedBankTransactionPaymentReferenceTextListQuery,
    FilterReceivedBankTransactionPaymentReferenceTextListQueryVariables
  >(FilterReceivedBankTransactionPaymentReferenceTextListDocument, options);
}
export function useFilterReceivedBankTransactionPaymentReferenceTextListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FilterReceivedBankTransactionPaymentReferenceTextListQuery,
        FilterReceivedBankTransactionPaymentReferenceTextListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FilterReceivedBankTransactionPaymentReferenceTextListQuery,
    FilterReceivedBankTransactionPaymentReferenceTextListQueryVariables
  >(FilterReceivedBankTransactionPaymentReferenceTextListDocument, options);
}
export type FilterReceivedBankTransactionPaymentReferenceTextListQueryHookResult = ReturnType<
  typeof useFilterReceivedBankTransactionPaymentReferenceTextListQuery
>;
export type FilterReceivedBankTransactionPaymentReferenceTextListLazyQueryHookResult = ReturnType<
  typeof useFilterReceivedBankTransactionPaymentReferenceTextListLazyQuery
>;
export type FilterReceivedBankTransactionPaymentReferenceTextListSuspenseQueryHookResult = ReturnType<
  typeof useFilterReceivedBankTransactionPaymentReferenceTextListSuspenseQuery
>;
export type FilterReceivedBankTransactionPaymentReferenceTextListQueryResult = Apollo.QueryResult<
  FilterReceivedBankTransactionPaymentReferenceTextListQuery,
  FilterReceivedBankTransactionPaymentReferenceTextListQueryVariables
>;
export const FilterReceivedBankTransactionPurposeOfUseTextListDocument = gql`
  query FilterReceivedBankTransactionPurposeOfUseTextList($purposeOfUseText: String) {
    getFilterReceivedBankTransactionPurposeOfUseTextList(purposeOfUseText: $purposeOfUseText) {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterReceivedBankTransactionPurposeOfUseTextListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FilterReceivedBankTransactionPurposeOfUseTextListQuery,
    FilterReceivedBankTransactionPurposeOfUseTextListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterReceivedBankTransactionPurposeOfUseTextListQuery, FilterReceivedBankTransactionPurposeOfUseTextListQueryVariables>(
    FilterReceivedBankTransactionPurposeOfUseTextListDocument,
    options
  );
}
export function useFilterReceivedBankTransactionPurposeOfUseTextListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterReceivedBankTransactionPurposeOfUseTextListQuery,
    FilterReceivedBankTransactionPurposeOfUseTextListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterReceivedBankTransactionPurposeOfUseTextListQuery, FilterReceivedBankTransactionPurposeOfUseTextListQueryVariables>(
    FilterReceivedBankTransactionPurposeOfUseTextListDocument,
    options
  );
}
export function useFilterReceivedBankTransactionPurposeOfUseTextListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FilterReceivedBankTransactionPurposeOfUseTextListQuery,
        FilterReceivedBankTransactionPurposeOfUseTextListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FilterReceivedBankTransactionPurposeOfUseTextListQuery,
    FilterReceivedBankTransactionPurposeOfUseTextListQueryVariables
  >(FilterReceivedBankTransactionPurposeOfUseTextListDocument, options);
}
export type FilterReceivedBankTransactionPurposeOfUseTextListQueryHookResult = ReturnType<
  typeof useFilterReceivedBankTransactionPurposeOfUseTextListQuery
>;
export type FilterReceivedBankTransactionPurposeOfUseTextListLazyQueryHookResult = ReturnType<
  typeof useFilterReceivedBankTransactionPurposeOfUseTextListLazyQuery
>;
export type FilterReceivedBankTransactionPurposeOfUseTextListSuspenseQueryHookResult = ReturnType<
  typeof useFilterReceivedBankTransactionPurposeOfUseTextListSuspenseQuery
>;
export type FilterReceivedBankTransactionPurposeOfUseTextListQueryResult = Apollo.QueryResult<
  FilterReceivedBankTransactionPurposeOfUseTextListQuery,
  FilterReceivedBankTransactionPurposeOfUseTextListQueryVariables
>;
export const FilterReceivedBankTransactionStatementNumberListDocument = gql`
  query FilterReceivedBankTransactionStatementNumberList {
    getFilterReceivedBankTransactionStatementNumberList {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterReceivedBankTransactionStatementNumberListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FilterReceivedBankTransactionStatementNumberListQuery,
    FilterReceivedBankTransactionStatementNumberListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterReceivedBankTransactionStatementNumberListQuery, FilterReceivedBankTransactionStatementNumberListQueryVariables>(
    FilterReceivedBankTransactionStatementNumberListDocument,
    options
  );
}
export function useFilterReceivedBankTransactionStatementNumberListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterReceivedBankTransactionStatementNumberListQuery,
    FilterReceivedBankTransactionStatementNumberListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterReceivedBankTransactionStatementNumberListQuery, FilterReceivedBankTransactionStatementNumberListQueryVariables>(
    FilterReceivedBankTransactionStatementNumberListDocument,
    options
  );
}
export function useFilterReceivedBankTransactionStatementNumberListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FilterReceivedBankTransactionStatementNumberListQuery,
        FilterReceivedBankTransactionStatementNumberListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FilterReceivedBankTransactionStatementNumberListQuery,
    FilterReceivedBankTransactionStatementNumberListQueryVariables
  >(FilterReceivedBankTransactionStatementNumberListDocument, options);
}
export type FilterReceivedBankTransactionStatementNumberListQueryHookResult = ReturnType<
  typeof useFilterReceivedBankTransactionStatementNumberListQuery
>;
export type FilterReceivedBankTransactionStatementNumberListLazyQueryHookResult = ReturnType<
  typeof useFilterReceivedBankTransactionStatementNumberListLazyQuery
>;
export type FilterReceivedBankTransactionStatementNumberListSuspenseQueryHookResult = ReturnType<
  typeof useFilterReceivedBankTransactionStatementNumberListSuspenseQuery
>;
export type FilterReceivedBankTransactionStatementNumberListQueryResult = Apollo.QueryResult<
  FilterReceivedBankTransactionStatementNumberListQuery,
  FilterReceivedBankTransactionStatementNumberListQueryVariables
>;
export const FilterReceivedBankTransactionEndToEndIdListDocument = gql`
  query FilterReceivedBankTransactionEndToEndIdList($endToEndId: String) {
    getFilterReceivedBankTransactionEndToEndIdList(endToEndId: $endToEndId) {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterReceivedBankTransactionEndToEndIdListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterReceivedBankTransactionEndToEndIdListQuery, FilterReceivedBankTransactionEndToEndIdListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterReceivedBankTransactionEndToEndIdListQuery, FilterReceivedBankTransactionEndToEndIdListQueryVariables>(
    FilterReceivedBankTransactionEndToEndIdListDocument,
    options
  );
}
export function useFilterReceivedBankTransactionEndToEndIdListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterReceivedBankTransactionEndToEndIdListQuery,
    FilterReceivedBankTransactionEndToEndIdListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterReceivedBankTransactionEndToEndIdListQuery, FilterReceivedBankTransactionEndToEndIdListQueryVariables>(
    FilterReceivedBankTransactionEndToEndIdListDocument,
    options
  );
}
export function useFilterReceivedBankTransactionEndToEndIdListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterReceivedBankTransactionEndToEndIdListQuery, FilterReceivedBankTransactionEndToEndIdListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterReceivedBankTransactionEndToEndIdListQuery, FilterReceivedBankTransactionEndToEndIdListQueryVariables>(
    FilterReceivedBankTransactionEndToEndIdListDocument,
    options
  );
}
export type FilterReceivedBankTransactionEndToEndIdListQueryHookResult = ReturnType<typeof useFilterReceivedBankTransactionEndToEndIdListQuery>;
export type FilterReceivedBankTransactionEndToEndIdListLazyQueryHookResult = ReturnType<
  typeof useFilterReceivedBankTransactionEndToEndIdListLazyQuery
>;
export type FilterReceivedBankTransactionEndToEndIdListSuspenseQueryHookResult = ReturnType<
  typeof useFilterReceivedBankTransactionEndToEndIdListSuspenseQuery
>;
export type FilterReceivedBankTransactionEndToEndIdListQueryResult = Apollo.QueryResult<
  FilterReceivedBankTransactionEndToEndIdListQuery,
  FilterReceivedBankTransactionEndToEndIdListQueryVariables
>;
export const FilterReceivedBankTransactionStatusListDocument = gql`
  query FilterReceivedBankTransactionStatusList {
    getFilterReceivedBankTransactionStatusList {
      data {
        text
        value
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterReceivedBankTransactionStatusListQuery(
  baseOptions?: Apollo.QueryHookOptions<FilterReceivedBankTransactionStatusListQuery, FilterReceivedBankTransactionStatusListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterReceivedBankTransactionStatusListQuery, FilterReceivedBankTransactionStatusListQueryVariables>(
    FilterReceivedBankTransactionStatusListDocument,
    options
  );
}
export function useFilterReceivedBankTransactionStatusListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterReceivedBankTransactionStatusListQuery, FilterReceivedBankTransactionStatusListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterReceivedBankTransactionStatusListQuery, FilterReceivedBankTransactionStatusListQueryVariables>(
    FilterReceivedBankTransactionStatusListDocument,
    options
  );
}
export function useFilterReceivedBankTransactionStatusListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FilterReceivedBankTransactionStatusListQuery, FilterReceivedBankTransactionStatusListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FilterReceivedBankTransactionStatusListQuery, FilterReceivedBankTransactionStatusListQueryVariables>(
    FilterReceivedBankTransactionStatusListDocument,
    options
  );
}
export type FilterReceivedBankTransactionStatusListQueryHookResult = ReturnType<typeof useFilterReceivedBankTransactionStatusListQuery>;
export type FilterReceivedBankTransactionStatusListLazyQueryHookResult = ReturnType<typeof useFilterReceivedBankTransactionStatusListLazyQuery>;
export type FilterReceivedBankTransactionStatusListSuspenseQueryHookResult = ReturnType<
  typeof useFilterReceivedBankTransactionStatusListSuspenseQuery
>;
export type FilterReceivedBankTransactionStatusListQueryResult = Apollo.QueryResult<
  FilterReceivedBankTransactionStatusListQuery,
  FilterReceivedBankTransactionStatusListQueryVariables
>;
export const FilterReceivedBankTransactionBankstatementIbanListDocument = gql`
  query FilterReceivedBankTransactionBankstatementIbanList {
    getFilterReceivedBankTransactionBankstatementIbanList {
      data
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useFilterReceivedBankTransactionBankstatementIbanListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FilterReceivedBankTransactionBankstatementIbanListQuery,
    FilterReceivedBankTransactionBankstatementIbanListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterReceivedBankTransactionBankstatementIbanListQuery, FilterReceivedBankTransactionBankstatementIbanListQueryVariables>(
    FilterReceivedBankTransactionBankstatementIbanListDocument,
    options
  );
}
export function useFilterReceivedBankTransactionBankstatementIbanListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterReceivedBankTransactionBankstatementIbanListQuery,
    FilterReceivedBankTransactionBankstatementIbanListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FilterReceivedBankTransactionBankstatementIbanListQuery,
    FilterReceivedBankTransactionBankstatementIbanListQueryVariables
  >(FilterReceivedBankTransactionBankstatementIbanListDocument, options);
}
export function useFilterReceivedBankTransactionBankstatementIbanListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FilterReceivedBankTransactionBankstatementIbanListQuery,
        FilterReceivedBankTransactionBankstatementIbanListQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FilterReceivedBankTransactionBankstatementIbanListQuery,
    FilterReceivedBankTransactionBankstatementIbanListQueryVariables
  >(FilterReceivedBankTransactionBankstatementIbanListDocument, options);
}
export type FilterReceivedBankTransactionBankstatementIbanListQueryHookResult = ReturnType<
  typeof useFilterReceivedBankTransactionBankstatementIbanListQuery
>;
export type FilterReceivedBankTransactionBankstatementIbanListLazyQueryHookResult = ReturnType<
  typeof useFilterReceivedBankTransactionBankstatementIbanListLazyQuery
>;
export type FilterReceivedBankTransactionBankstatementIbanListSuspenseQueryHookResult = ReturnType<
  typeof useFilterReceivedBankTransactionBankstatementIbanListSuspenseQuery
>;
export type FilterReceivedBankTransactionBankstatementIbanListQueryResult = Apollo.QueryResult<
  FilterReceivedBankTransactionBankstatementIbanListQuery,
  FilterReceivedBankTransactionBankstatementIbanListQueryVariables
>;
