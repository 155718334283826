import React, { FC } from 'react';
import { Modal } from 'antd';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import { showWarningMessage } from '../../../../../components/message';
import { vorschreibungErstellenValidationSchema } from './vorschreibungErstellenValidationSchema';
import FormButtons from '../../../../../components/Button/FormButtons';
import { useCreateAuftragGenerierlaufMutation } from '../../../gql/AuftragGenerierlauf/AuftragGenerierlaufMutation.types';
import { showSuccessMsgVerarbeitungStarted } from '../../../../../components/message/message';
import {
  mapFormValuesToVorschreibung,
  vorschreibungErstellenFormFields,
  VorschreibungErstellenFormValues,
  vorschreibungErstellenVerarbeitungInitialValues,
} from './formMapper';
import ObjektSelectWithAllTag from '../../../../../shared/ObjektSelect/ObjektSelectWithAllTag';
import { useGetAppFieldHelpText } from '../../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../../components/Form/FormItemWithFieldHelp';
import RangePicker from '../../../../../components/DatePicker/RangePicker/RangePicker';

type Props = {
  onCreate(): void;
  onCancel(): void;
  visible: boolean;
};

const VorschreibungErstellenModal: FC<Props> = ({ onCreate, onCancel, visible, ...props }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'AuftragGenerierlauf'>('AuftragGenerierlauf');

  const [runCreateGenerierlauf] = useCreateAuftragGenerierlaufMutation({
    onCompleted: () => {
      showSuccessMsgVerarbeitungStarted(`Vorschreibungerstellung`);
      onCreate();
    },
    onError: (error) => showWarningMessage(error, 'Zinsliste wurde nicht verarbeitet'),
  });

  return (
    <Modal open={visible} title="Vorschreibung erstellen" footer={null} onCancel={onCancel} destroyOnClose maskClosable={false} {...props}>
      <Formik<VorschreibungErstellenFormValues>
        validationSchema={vorschreibungErstellenValidationSchema}
        initialValues={vorschreibungErstellenVerarbeitungInitialValues}
        onSubmit={(values, actions) => {
          const auftragsGenerierlaufInput = mapFormValuesToVorschreibung(values);
          runCreateGenerierlauf({ variables: { auftragsGenerierlaufInput } }).finally(() => actions.setSubmitting(false));
        }}
      >
        {(formikProps) => (
          <Form layout="vertical">
            <FormItemWithFieldHelp
              name={vorschreibungErstellenFormFields.objektIdList}
              label="Objekt"
              fieldHelp={getFieldHelpText('AuftragGenerierlauf.objektIdList')}
            >
              <ObjektSelectWithAllTag name={vorschreibungErstellenFormFields.objektIdList} formikProps={formikProps} />
            </FormItemWithFieldHelp>
            <FormItemWithFieldHelp
              name={vorschreibungErstellenFormFields.vorschreibungsperiodeList}
              label="Vorschreibungsperiode"
              fieldHelp={getFieldHelpText('AuftragGenerierlauf.vorschreibungsperiodeList')}
            >
              <RangePicker name={vorschreibungErstellenFormFields.vorschreibungsperiodeList} style={{ width: '100%' }} picker="month" />
            </FormItemWithFieldHelp>
            <FormButtons updateMode={false} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default VorschreibungErstellenModal;
