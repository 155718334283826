import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { MitarbeiterBaseFieldsFragmentDoc } from '../../Mitarbeiter/gql/MitarbeiterFragments.types';
import { BeInfoFeldFragmentDoc } from '../../Bestandseinheit/InfoFeld/gql/BeInfoFeldFragments.types';
import { BestandseinheitBaseFieldsFragmentDoc } from '../../Bestandseinheit/gql/BestandseinheitFragments.types';
import { ObjektBaseFragmentDoc } from '../../Objekt/gql/ObjektFragments.types';
import { RechtstraegerBaseFieldsFragmentDoc } from '../../Rechtstraeger/gql/RechtsTraegerBaseFragments.types';
import { ObjektInfoFeldFragmentDoc } from '../../Objekt/InfoFeld/gql/objektInfoFeldFragments.types';
import { RechtstraegerInfoFeldFragmentDoc } from '../../Rechtstraeger/InfoFeld/gql/rechtstraegerInfoFeldFragments.types';
import { TicketTemplateFieldsFragmentDoc } from '../../TicketTemplate/gql/TicketTemplateFragments.types';
import { VertragBaseFieldsFragmentDoc } from '../../Vertrag/gql/VertragFragments.types';
import { VertragInfoFeldFragmentDoc } from '../../Vertrag/BeVertrag/InfoFeld/gql/VertragInfoFeldFragments.types';
import { ContactFieldsFragmentDoc, ContactPersonFieldsFragmentDoc } from '../../Contact/gql/ContactFragments.types';
export type TicketBaseFieldsFragment = {
  attachmentCount: number;
  commentCount: number;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  description?: string | null;
  dueDate: string;
  emailCount: number;
  isSubTicket: boolean;
  number: number;
  participantCount: number;
  position: number;
  reminderText: string;
  ticketId: string;
  title: string;
  typeName?: string | null;
  updatedByMitarbeiterId?: string | null;
  assignee: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    firstname: string;
    lastname: string;
    mitarbeiterId: string;
    updatedByMitarbeiterId?: string | null;
    firmendatenBaseData: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      firmendatenId: string;
      kundenSystemId: string;
      kurzBezeichnung: string;
      name1: string;
      name2?: string | null;
      name3?: string | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  beInfoFeld?:
    | {
        __typename: 'BeInfoFeldKellerabteil';
        beInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        text?: string | null;
        kellerabteilNummer: string;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.BeInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }
    | {
        __typename: 'BeInfoFeldParkplatz';
        beInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        text?: string | null;
        parkplatzNummer: string;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.BeInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        bestandseinheit?: { bestandseinheitId: string; bezeichnung: string } | null;
      }
    | {
        __typename: 'BeInfoFeldZimmer';
        beInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        anmerkung?: string | null;
        bezeichnung: string;
        bodenbelag?: string | null;
        breite?: number | null;
        energiekennzahl?: string | null;
        flaechentyp?: string | null;
        heizung?: string | null;
        hoehe?: number | null;
        initialQuadratmeterBetrag?: number | null;
        initialQuadratmeterPreis?: number | null;
        kubikmeter?: number | null;
        laenge?: number | null;
        nutzflaeche?: number | null;
        nutzung?: boolean | null;
        quadratmeter?: number | null;
        quadratmeterBetrag?: number | null;
        quadratmeterPreis?: number | null;
        zimmerNummer?: string | null;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.BeInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        vorschreibungsposition?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          kurzBezeichnung: string;
          objektVorschreibungspositionId: string;
          vorschreibungspositionId: string;
          art: { text: string; value: Types.VorschreibungspositionArt };
          objektVorschreibungspositionStatus: { text: string; value: Types.ObjektVorschreibungspositionStatus };
          vorschreibungspositionStatus: { text: string; value: Types.VorschreibungspositionStatus };
        } | null;
      }
    | null;
  bestandseinheit?: {
    bestandseinheitId: string;
    bezeichnung: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    objektId: string;
    status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  objekt?: {
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    objektId: string;
    updatedByMitarbeiterId?: string | null;
    rechnungsAussteller: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    status: { text: string; value: Types.ObjektStatus; description?: string | null };
    verwaltungsart: { text: string; value: Types.Verwaltungsart };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  objektInfoFeld?:
    | {
        __typename: 'ObjektInfoFeldAuflage';
        objektInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        amtlicheQuadratmeterFestsetzung: boolean;
        assanierungsgebiet: boolean;
        bebaut: boolean;
        denkmalschutz: boolean;
        gruenderzeitviertel: boolean;
        naturschutz: boolean;
        schutzzone: boolean;
        vollanwendungMRG: boolean;
        wohnzone: boolean;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.ObjektInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }
    | {
        __typename: 'ObjektInfoFeldGrundstuecksdaten';
        objektInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        anzahlGeschosseUeberNiveau?: number | null;
        anzahlGeschosseUnterNiveau?: number | null;
        bauflaeche?: number | null;
        baujahr?: number | null;
        bebauteFlaeche?: number | null;
        benuetzungsbewilligung?: string | null;
        einlagezahl?: string | null;
        grundstuecksnummer: string;
        katastralgemeinde?: string | null;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.ObjektInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }
    | {
        __typename: 'ObjektInfoFeldHausbetreuung';
        objektInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        beauftragungsgrund: string;
        beginn?: string | null;
        ende?: string | null;
        kuendigungsfristDauer?: number | null;
        vertragsdaten?: string | null;
        wertsicherung?: string | null;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.ObjektInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        contactPerson?: {
          contactPersonId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          lastname: string;
          title?: string | null;
          firstname: string;
          salutation?: { text: string; value: Types.Salutation } | null;
          contactList: Array<{
            contactId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            haupt?: boolean | null;
            tagList: Array<string>;
            type: string;
            wert: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          warningList: Array<{ message: string; type: string }>;
        } | null;
        kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
        kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
        hausbetreuungRechtstraeger?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
      }
    | {
        __typename: 'ObjektInfoFeldPerson';
        objektInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        rolle: string;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.ObjektInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        personRechtstraeger: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
      }
    | {
        __typename: 'ObjektInfoFeldVersicherung';
        objektInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        beginn?: string | null;
        datumSchaetzung?: string | null;
        ende?: string | null;
        erstmalsFaellig?: string | null;
        validFrom?: string | null;
        hauptDueDate?: string | null;
        kuendigungsfristDauer?: number | null;
        neubauwert?: number | null;
        polizze?: string | null;
        versicherungsart: string;
        versicherungssumme?: number | null;
        wertsicherung?: string | null;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.ObjektInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        contactPerson?: {
          contactPersonId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          lastname: string;
          title?: string | null;
          firstname: string;
          salutation?: { text: string; value: Types.Salutation } | null;
          contactList: Array<{
            contactId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            haupt?: boolean | null;
            tagList: Array<string>;
            type: string;
            wert: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          warningList: Array<{ message: string; type: string }>;
        } | null;
        firma?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          name1: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ message: string; type: string }>;
        } | null;
        kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
        kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
        makler?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          rechtstraegerId: string;
          kurzBezeichnung: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ message: string; type: string }>;
        } | null;
        sachbearbeiter?: {
          contactPersonId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firstname: string;
          lastname: string;
          tagList: Array<string>;
          title?: string | null;
          titleTrailing?: string | null;
          contactList: Array<{
            contactId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            haupt?: boolean | null;
            tagList: Array<string>;
            type: string;
            wert: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          rechtstraeger?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        zahlungsperiode?: { text: string; value: Types.Zahlungsperiode } | null;
      }
    | null;
  priority: { text: string; value: Types.TicketPriority };
  rechtstraeger?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  rechtstraegerInfoFeld?: {
    __typename: 'RechtstraegerInfoFeldPersoneninfos';
    rechtstraegerInfoFeldId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    fileIdList: Array<string>;
    geburtsort?: string | null;
    identitaetsnachweis?: string | null;
    kundengruppeList?: Array<string> | null;
    merkmalList: Array<string>;
    staatsbuergerschaft?: string | null;
    postIt?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      immerAnzeigen: boolean;
      postItId: string;
      text: string;
      titel: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    status: { text: string; value: Types.InfoFeldStatus };
    type: { text: string; value: Types.RechtstraegerInfoFeldType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  reminder: { amount: number; unit: { text: string; value: Types.PeriodUnit } };
  ticketStatus: { text: string; value: Types.TicketStatus };
  type?: {
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    dueDateText?: string | null;
    firmendatenId?: string | null;
    isSubTicketTemplate: boolean;
    name: string;
    parentId?: string | null;
    position: number;
    reminderText?: string | null;
    ticketTemplateId: string;
    updatedByMitarbeiterId?: string | null;
    subTicketTemplateList?: Array<{
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      dueDateText?: string | null;
      firmendatenId?: string | null;
      isSubTicketTemplate: boolean;
      name: string;
      parentId?: string | null;
      position: number;
      reminderText?: string | null;
      ticketTemplateId: string;
      updatedByMitarbeiterId?: string | null;
      dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
      priority: { text: string; value: Types.TicketPriority };
      reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }> | null;
    dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
    priority: { text: string; value: Types.TicketPriority };
    reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  vertrag?: {
    bestandseinheitId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    objektId: string;
    vertragId: string;
    vertragsBeginn: string;
    status: { text: string; value: Types.VertragStatus; description?: string | null };
    vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
    vertragspartner: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  vertragInfoFeld?:
    | {
        __typename: 'VertragInfoFeldKuendigungsinfos';
        vertragInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        kuendigungBis: string;
        kuendigungsverzichtBestandgeberBis?: string | null;
        kuendigungsverzichtBestandnehmerBis?: string | null;
        bestandnehmerKuendigungsfrist?: number | null;
        kuendigungJeweilsZum?: string | null;
        kuendigungMoeglichZum?: string | null;
        gerichtlicheKuendigung?: boolean | null;
        kuendigungErfolgtAm?: string | null;
        kuendigungWirksamZum?: string | null;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.VertragInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        kuendigungDurch?: { text: string; value: Types.KuendigungDurch } | null;
      }
    | {
        __typename: 'VertragInfoFeldWeiterePerson';
        vertragInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        text?: string | null;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.VertragInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        natuerlichePerson: { rechtstraegerId: string; kurzBezeichnung: string; lastname: string; firstname: string };
      }
    | null;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type TicketFieldsFragment = {
  attachmentCount: number;
  commentCount: number;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  description?: string | null;
  dueDate: string;
  emailCount: number;
  isSubTicket: boolean;
  number: number;
  participantCount: number;
  position: number;
  reminderText: string;
  ticketId: string;
  title: string;
  typeName?: string | null;
  updatedByMitarbeiterId?: string | null;
  assignee: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    firstname: string;
    lastname: string;
    mitarbeiterId: string;
    updatedByMitarbeiterId?: string | null;
    firmendatenBaseData: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      firmendatenId: string;
      kundenSystemId: string;
      kurzBezeichnung: string;
      name1: string;
      name2?: string | null;
      name3?: string | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  };
  attachments: Array<{ ticketId: string; ticketTitle: string; fileInfo: { fileId: string; name: string; size: number } }>;
  beInfoFeld?:
    | {
        __typename: 'BeInfoFeldKellerabteil';
        beInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        text?: string | null;
        kellerabteilNummer: string;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.BeInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }
    | {
        __typename: 'BeInfoFeldParkplatz';
        beInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        text?: string | null;
        parkplatzNummer: string;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.BeInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        bestandseinheit?: { bestandseinheitId: string; bezeichnung: string } | null;
      }
    | {
        __typename: 'BeInfoFeldZimmer';
        beInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        anmerkung?: string | null;
        bezeichnung: string;
        bodenbelag?: string | null;
        breite?: number | null;
        energiekennzahl?: string | null;
        flaechentyp?: string | null;
        heizung?: string | null;
        hoehe?: number | null;
        initialQuadratmeterBetrag?: number | null;
        initialQuadratmeterPreis?: number | null;
        kubikmeter?: number | null;
        laenge?: number | null;
        nutzflaeche?: number | null;
        nutzung?: boolean | null;
        quadratmeter?: number | null;
        quadratmeterBetrag?: number | null;
        quadratmeterPreis?: number | null;
        zimmerNummer?: string | null;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.BeInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        vorschreibungsposition?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          deletable?: boolean | null;
          kurzBezeichnung: string;
          objektVorschreibungspositionId: string;
          vorschreibungspositionId: string;
          art: { text: string; value: Types.VorschreibungspositionArt };
          objektVorschreibungspositionStatus: { text: string; value: Types.ObjektVorschreibungspositionStatus };
          vorschreibungspositionStatus: { text: string; value: Types.VorschreibungspositionStatus };
        } | null;
      }
    | null;
  bestandseinheit?: {
    bestandseinheitId: string;
    bezeichnung: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    objektId: string;
    status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  emailList: Array<{
    sender?: string | null;
    sentTs?: string | null;
    subject?: string | null;
    ticketId: string;
    ticketTitle: string;
    attachments: Array<{ ticketId: string; ticketTitle: string; fileInfo: { fileId: string; name: string; size: number } }>;
    fileInfo: { fileId: string; name: string; size: number };
    ticketParticipant: {
      ticketId: string;
      ticketTitle: string;
      participant: {
        emailAddress?: string | null;
        participantId?: string | null;
        contact?: {
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        rechtstraeger?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
      };
    };
  }>;
  objekt?: {
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    objektId: string;
    updatedByMitarbeiterId?: string | null;
    rechnungsAussteller: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    status: { text: string; value: Types.ObjektStatus; description?: string | null };
    verwaltungsart: { text: string; value: Types.Verwaltungsart };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  objektInfoFeld?:
    | {
        __typename: 'ObjektInfoFeldAuflage';
        objektInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        amtlicheQuadratmeterFestsetzung: boolean;
        assanierungsgebiet: boolean;
        bebaut: boolean;
        denkmalschutz: boolean;
        gruenderzeitviertel: boolean;
        naturschutz: boolean;
        schutzzone: boolean;
        vollanwendungMRG: boolean;
        wohnzone: boolean;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.ObjektInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }
    | {
        __typename: 'ObjektInfoFeldGrundstuecksdaten';
        objektInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        anzahlGeschosseUeberNiveau?: number | null;
        anzahlGeschosseUnterNiveau?: number | null;
        bauflaeche?: number | null;
        baujahr?: number | null;
        bebauteFlaeche?: number | null;
        benuetzungsbewilligung?: string | null;
        einlagezahl?: string | null;
        grundstuecksnummer: string;
        katastralgemeinde?: string | null;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.ObjektInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }
    | {
        __typename: 'ObjektInfoFeldHausbetreuung';
        objektInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        beauftragungsgrund: string;
        beginn?: string | null;
        ende?: string | null;
        kuendigungsfristDauer?: number | null;
        vertragsdaten?: string | null;
        wertsicherung?: string | null;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.ObjektInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        contactPerson?: {
          contactPersonId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          lastname: string;
          title?: string | null;
          firstname: string;
          salutation?: { text: string; value: Types.Salutation } | null;
          contactList: Array<{
            contactId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            haupt?: boolean | null;
            tagList: Array<string>;
            type: string;
            wert: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          warningList: Array<{ message: string; type: string }>;
        } | null;
        kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
        kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
        hausbetreuungRechtstraeger?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
      }
    | {
        __typename: 'ObjektInfoFeldPerson';
        objektInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        rolle: string;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.ObjektInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        personRechtstraeger: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
      }
    | {
        __typename: 'ObjektInfoFeldVersicherung';
        objektInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        beginn?: string | null;
        datumSchaetzung?: string | null;
        ende?: string | null;
        erstmalsFaellig?: string | null;
        validFrom?: string | null;
        hauptDueDate?: string | null;
        kuendigungsfristDauer?: number | null;
        neubauwert?: number | null;
        polizze?: string | null;
        versicherungsart: string;
        versicherungssumme?: number | null;
        wertsicherung?: string | null;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.ObjektInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        contactPerson?: {
          contactPersonId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          lastname: string;
          title?: string | null;
          firstname: string;
          salutation?: { text: string; value: Types.Salutation } | null;
          contactList: Array<{
            contactId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            haupt?: boolean | null;
            tagList: Array<string>;
            type: string;
            wert: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          warningList: Array<{ message: string; type: string }>;
        } | null;
        firma?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          name1: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ message: string; type: string }>;
        } | null;
        kuendigungsfrist?: { text: string; value: Types.Kuendigungsfrist } | null;
        kuendigungsfristJeweilsZum?: { text: string; value: Types.KuendigungsfristJeweilsZum } | null;
        makler?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          rechtstraegerId: string;
          kurzBezeichnung: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ message: string; type: string }>;
        } | null;
        sachbearbeiter?: {
          contactPersonId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firstname: string;
          lastname: string;
          tagList: Array<string>;
          title?: string | null;
          titleTrailing?: string | null;
          contactList: Array<{
            contactId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            haupt?: boolean | null;
            tagList: Array<string>;
            type: string;
            wert: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          rechtstraeger?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        zahlungsperiode?: { text: string; value: Types.Zahlungsperiode } | null;
      }
    | null;
  participantList: Array<{
    ticketId: string;
    ticketTitle: string;
    participant: {
      emailAddress?: string | null;
      participantId?: string | null;
      contact?: {
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      rechtstraeger?: {
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        rechtstraegerId: string;
        status: { text: string; value: Types.RechtstraegerStatus };
        type: { text: string; value: Types.RechtstraegerType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
    };
  }>;
  priority: { text: string; value: Types.TicketPriority };
  rechtstraeger?: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    rechtstraegerId: string;
    status: { text: string; value: Types.RechtstraegerStatus };
    type: { text: string; value: Types.RechtstraegerType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  rechtstraegerInfoFeld?: {
    __typename: 'RechtstraegerInfoFeldPersoneninfos';
    rechtstraegerInfoFeldId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    deletable?: boolean | null;
    fileIdList: Array<string>;
    geburtsort?: string | null;
    identitaetsnachweis?: string | null;
    kundengruppeList?: Array<string> | null;
    merkmalList: Array<string>;
    staatsbuergerschaft?: string | null;
    postIt?: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      immerAnzeigen: boolean;
      postItId: string;
      text: string;
      titel: string;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    } | null;
    status: { text: string; value: Types.InfoFeldStatus };
    type: { text: string; value: Types.RechtstraegerInfoFeldType };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  reminder: { amount: number; unit: { text: string; value: Types.PeriodUnit } };
  ticketStatus: { text: string; value: Types.TicketStatus };
  type?: {
    createTs: string;
    createdBy?: string | null;
    createdByMitarbeiterId?: string | null;
    dueDateText?: string | null;
    firmendatenId?: string | null;
    isSubTicketTemplate: boolean;
    name: string;
    parentId?: string | null;
    position: number;
    reminderText?: string | null;
    ticketTemplateId: string;
    updatedByMitarbeiterId?: string | null;
    subTicketTemplateList?: Array<{
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      dueDateText?: string | null;
      firmendatenId?: string | null;
      isSubTicketTemplate: boolean;
      name: string;
      parentId?: string | null;
      position: number;
      reminderText?: string | null;
      ticketTemplateId: string;
      updatedByMitarbeiterId?: string | null;
      dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
      priority: { text: string; value: Types.TicketPriority };
      reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }> | null;
    dueDate?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
    priority: { text: string; value: Types.TicketPriority };
    reminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  vertrag?: {
    bestandseinheitId: string;
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    kurzBezeichnung: string;
    objektId: string;
    vertragId: string;
    vertragsBeginn: string;
    status: { text: string; value: Types.VertragStatus; description?: string | null };
    vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
    vertragspartner: {
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      kurzBezeichnung: string;
      rechtstraegerId: string;
      status: { text: string; value: Types.RechtstraegerStatus };
      type: { text: string; value: Types.RechtstraegerType };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ attribute?: string | null; message: string; type: string }>;
  } | null;
  vertragInfoFeld?:
    | {
        __typename: 'VertragInfoFeldKuendigungsinfos';
        vertragInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        kuendigungBis: string;
        kuendigungsverzichtBestandgeberBis?: string | null;
        kuendigungsverzichtBestandnehmerBis?: string | null;
        bestandnehmerKuendigungsfrist?: number | null;
        kuendigungJeweilsZum?: string | null;
        kuendigungMoeglichZum?: string | null;
        gerichtlicheKuendigung?: boolean | null;
        kuendigungErfolgtAm?: string | null;
        kuendigungWirksamZum?: string | null;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.VertragInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        kuendigungDurch?: { text: string; value: Types.KuendigungDurch } | null;
      }
    | {
        __typename: 'VertragInfoFeldWeiterePerson';
        vertragInfoFeldId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        deletable?: boolean | null;
        fileIdList: Array<string>;
        text?: string | null;
        postIt?: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          immerAnzeigen: boolean;
          postItId: string;
          text: string;
          titel: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        status: { text: string; value: Types.InfoFeldStatus };
        type: { text: string; value: Types.VertragInfoFeldType };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        natuerlichePerson: { rechtstraegerId: string; kurzBezeichnung: string; lastname: string; firstname: string };
      }
    | null;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type TicketChangeEntryFieldsFragment = {
  changeType: Types.TicketChangeType;
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  newAssigneeKurzBezeichnung?: string | null;
  newAttachmentList?: Array<string> | null;
  newComment?: string | null;
  newDescription?: string | null;
  newDueDate?: string | null;
  newParticipantList?: Array<string> | null;
  newReminderText?: string | null;
  newTitle?: string | null;
  oldAssigneeKurzBezeichnung?: string | null;
  oldAttachmentList?: Array<string> | null;
  oldComment?: string | null;
  oldDescription?: string | null;
  oldDueDate?: string | null;
  oldParticipantList?: Array<string> | null;
  oldReminderText?: string | null;
  oldTitle?: string | null;
  subTicketChangeEntry: boolean;
  ticketId: string;
  updatedByMitarbeiterId?: string | null;
  newEmailList?: Array<{ sender: string; subject: string }> | null;
  newPriority?: { text: string; value: Types.TicketPriority } | null;
  newReminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
  newStatus?: { description?: string | null; text: string; value: Types.TicketStatus } | null;
  oldEmailList?: Array<{ sender: string; subject: string }> | null;
  oldPriority?: { text: string; value: Types.TicketPriority } | null;
  oldReminder?: { amount: number; unit: { text: string; value: Types.PeriodUnit } } | null;
  oldStatus?: { description?: string | null; text: string; value: Types.TicketStatus } | null;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export type TicketCommentFieldsFragment = {
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  text: string;
  ticketCommentId: string;
  updatedByMitarbeiterId?: string | null;
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const TicketBaseFieldsFragmentDoc = gql`
  fragment TicketBaseFields on TicketBase {
    assignee {
      ...MitarbeiterBaseFields
    }
    attachmentCount
    beInfoFeld {
      ...BeInfoFeld
    }
    bestandseinheit {
      ...BestandseinheitBaseFields
    }
    commentCount
    createTs
    createdBy
    createdByMitarbeiterId
    description
    dueDate
    emailCount
    isSubTicket
    number
    objekt {
      ...ObjektBase
    }
    objektInfoFeld {
      ...ObjektInfoFeld
    }
    participantCount
    position
    priority {
      text
      value
    }
    rechtstraeger {
      ...RechtstraegerBaseFields
    }
    rechtstraegerInfoFeld {
      ...RechtstraegerInfoFeld
    }
    reminder {
      amount
      unit {
        text
        value
      }
    }
    reminderText
    ticketId
    ticketStatus {
      text
      value
    }
    title
    type {
      ...TicketTemplateFields
      subTicketTemplateList {
        ...TicketTemplateFields
      }
    }
    typeName
    updatedByMitarbeiterId
    vertrag {
      ...VertragBaseFields
    }
    vertragInfoFeld {
      ...VertragInfoFeld
    }
    warningList {
      attribute
      message
      type
    }
  }
  ${MitarbeiterBaseFieldsFragmentDoc}
  ${BeInfoFeldFragmentDoc}
  ${BestandseinheitBaseFieldsFragmentDoc}
  ${ObjektBaseFragmentDoc}
  ${ObjektInfoFeldFragmentDoc}
  ${RechtstraegerBaseFieldsFragmentDoc}
  ${RechtstraegerInfoFeldFragmentDoc}
  ${TicketTemplateFieldsFragmentDoc}
  ${VertragBaseFieldsFragmentDoc}
  ${VertragInfoFeldFragmentDoc}
`;
export const TicketFieldsFragmentDoc = gql`
  fragment TicketFields on Ticket {
    assignee {
      ...MitarbeiterBaseFields
    }
    attachmentCount
    attachments {
      fileInfo {
        fileId
        name
        size
      }
      ticketId
      ticketTitle
    }
    beInfoFeld {
      ...BeInfoFeld
    }
    bestandseinheit {
      ...BestandseinheitBaseFields
    }
    commentCount
    createTs
    createdBy
    createdByMitarbeiterId
    description
    dueDate
    emailCount
    emailList {
      attachments {
        fileInfo {
          fileId
          name
          size
        }
        ticketId
        ticketTitle
      }
      fileInfo {
        fileId
        name
        size
      }
      sender
      sentTs
      subject
      ticketId
      ticketParticipant {
        participant {
          contact {
            ...ContactFields
          }
          emailAddress
          participantId
          rechtstraeger {
            ...RechtstraegerBaseFields
          }
        }
        ticketId
        ticketTitle
      }
      ticketTitle
    }
    isSubTicket
    objekt {
      ...ObjektBase
    }
    objektInfoFeld {
      ...ObjektInfoFeld
    }
    number
    participantCount
    participantList {
      participant {
        contact {
          ...ContactFields
        }
        emailAddress
        participantId
        rechtstraeger {
          ...RechtstraegerBaseFields
        }
      }
      ticketId
      ticketTitle
    }
    position
    priority {
      text
      value
    }
    rechtstraeger {
      ...RechtstraegerBaseFields
    }
    rechtstraegerInfoFeld {
      ...RechtstraegerInfoFeld
    }
    reminder {
      amount
      unit {
        text
        value
      }
    }
    reminderText
    ticketStatus {
      text
      value
    }
    ticketId
    title
    type {
      ...TicketTemplateFields
      subTicketTemplateList {
        ...TicketTemplateFields
      }
    }
    typeName
    updatedByMitarbeiterId
    vertrag {
      ...VertragBaseFields
    }
    vertragInfoFeld {
      ...VertragInfoFeld
    }
    warningList {
      attribute
      message
      type
    }
  }
  ${MitarbeiterBaseFieldsFragmentDoc}
  ${BeInfoFeldFragmentDoc}
  ${BestandseinheitBaseFieldsFragmentDoc}
  ${ContactFieldsFragmentDoc}
  ${RechtstraegerBaseFieldsFragmentDoc}
  ${ObjektBaseFragmentDoc}
  ${ObjektInfoFeldFragmentDoc}
  ${RechtstraegerInfoFeldFragmentDoc}
  ${TicketTemplateFieldsFragmentDoc}
  ${VertragBaseFieldsFragmentDoc}
  ${VertragInfoFeldFragmentDoc}
`;
export const TicketChangeEntryFieldsFragmentDoc = gql`
  fragment TicketChangeEntryFields on TicketChangeEntry {
    changeType
    createTs
    createdBy
    createdByMitarbeiterId
    newAssigneeKurzBezeichnung
    newAttachmentList
    newComment
    newDescription
    newDueDate
    newEmailList {
      sender
      subject
    }
    newParticipantList
    newPriority {
      text
      value
    }
    newReminder {
      amount
      unit {
        text
        value
      }
    }
    newReminderText
    newStatus {
      description
      text
      value
    }
    newTitle
    oldAssigneeKurzBezeichnung
    oldAttachmentList
    oldComment
    oldDescription
    oldDueDate
    oldEmailList {
      sender
      subject
    }
    oldParticipantList
    oldPriority {
      text
      value
    }
    oldReminder {
      amount
      unit {
        text
        value
      }
    }
    oldReminderText
    oldStatus {
      description
      text
      value
    }
    oldTitle
    subTicketChangeEntry
    ticketId
    updatedByMitarbeiterId
    warningList {
      attribute
      message
      type
    }
  }
`;
export const TicketCommentFieldsFragmentDoc = gql`
  fragment TicketCommentFields on TicketComment {
    createTs
    createdBy
    createdByMitarbeiterId
    text
    ticketCommentId
    updatedByMitarbeiterId
    warningList {
      attribute
      message
      type
    }
  }
`;
