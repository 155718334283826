import styled from 'styled-components';
import { Button, Card, Upload } from 'antd';
import theme from '../../../theme';

export const StyledCard = styled(Card)`
  &&& {
    transition: all 0.3s;
    cursor: auto;
  }
`;

export const StyledDragger = styled(Upload.Dragger)`
  &.ant-upload.ant-upload-drag {
    transition: all 0.3s;
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: #fff;
    cursor: auto;
    &.ant-upload.ant-upload-drag:hover {
      cursor: copy;
      background: #fafafa;
      border-width: 1px;
      border-style: solid;
      border-color: #40a9ff;
    }
  }
`;

export const StyledBtn = styled(Button)`
  color: ${theme.textColor.secondary};
  border-width: 0;
  border-style: none;
  box-shadow: none;
`;
