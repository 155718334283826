import { generatePath } from 'react-router-dom';
import { URI_OBJEKT_DETAILS_PAGE } from '../../Objekt/objektUriPaths';

export const URI_SUB_ABRECHNUNG_EIGENTUEMER_DETAILS_PAGE = `${URI_OBJEKT_DETAILS_PAGE}/sub-abrechnung/:subAbrechnungId/eigentuemer/:subAbrechnungEigentuemerId`;

export const generatePathToSubAbrechnungEigentuemerDetailsPage = (objektId: string, subAbrechnungId: string, subAbrechnungEigentuemerId: string) =>
  generatePath(URI_SUB_ABRECHNUNG_EIGENTUEMER_DETAILS_PAGE, { objektId, subAbrechnungId, subAbrechnungEigentuemerId });

const generatePathToSubAbrechnungEigentuemerDetailsPageAbrechnung = (objektId: string, subAbrechnungId: string, subAbrechnungEigentuemerId: string) =>
  `${generatePathToSubAbrechnungEigentuemerDetailsPage(objektId, subAbrechnungId, subAbrechnungEigentuemerId)}/abrechnungen`;

export const pathsToSubAbrechnungEigentuemerDetails = (objektId: string, subAbrechnungId: string, subAbrechnungEigentuemerId: string) => ({
  subAbr: generatePathToSubAbrechnungEigentuemerDetailsPageAbrechnung(objektId, subAbrechnungId, subAbrechnungEigentuemerId),
  subAbrTab: `${generatePathToSubAbrechnungEigentuemerDetailsPageAbrechnung(objektId, subAbrechnungId, subAbrechnungEigentuemerId)}/subverwaltungsabrechnung`,
  zahlungsreuckstandTab: `${generatePathToSubAbrechnungEigentuemerDetailsPageAbrechnung(objektId, subAbrechnungId, subAbrechnungEigentuemerId)}/zahlungsrueckstand`,
  documents: `${generatePathToSubAbrechnungEigentuemerDetailsPage(objektId, subAbrechnungId, subAbrechnungEigentuemerId)}/documents`,
});
