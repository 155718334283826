import { FormikProps } from 'formik';
import { get, isEmpty } from 'lodash';
import useDebounce from '../../../hooks/useDebounce';

export type StringKeyObject = {
  [key: string]: string | unknown;
};

export type KurzBezeichnungVorschlagConfigProps<T> = {
  getFieldsToBeDebounced?: (formikProps: FormikProps<T>) => StringKeyObject;
  getFieldsNotToBeDebounced?: (formikProps: FormikProps<T>) => StringKeyObject;
  getKurzBezeichnungVorschlagFn: (kurzBezeichnungVorschlagFields: any) => Promise<any>;
};

export const toValuesForUpdateCondition = (fieldsToBeDebounced: StringKeyObject, fieldsNotToBeDebounced: StringKeyObject) => {
  const debouncedFieldValues = Object.values(fieldsToBeDebounced).map((fieldValue) => useDebounce(fieldValue as string, 250));
  const notDebouncedFieldValues = Object.values(fieldsNotToBeDebounced);
  return [...debouncedFieldValues, ...notDebouncedFieldValues];
};

export const getKurzBezeichnungVorschlag =
  <T>(
    getKurzBezeichnungVorschlagFn: (kurzBezeichnungVorschlagFields: StringKeyObject) => Promise<string>,
    kurzBezeichnungVorschlagFields: StringKeyObject,
    formikProps: FormikProps<T>
  ) =>
  () => {
    if (hasAtLeastOneValidPair(kurzBezeichnungVorschlagFields)) {
      getKurzBezeichnungVorschlagFn(kurzBezeichnungVorschlagFields)
        .then(setKurzBezeichnungIfNotEmptyAndChanged(formikProps))
        .catch(consoleError(`could not fetched kurzBezeichnung for fields: ${kurzBezeichnungVorschlagFields}.`));
    }
  };

const hasAtLeastOneValidPair = (obj: StringKeyObject) => {
  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
      return true; // Return true as soon as one valid key-value pair is found
    }
  }
  return false; // Return false if no valid pairs are found
};

const consoleError = (errorMessage: string) => (error: string) => console.error(errorMessage, `error: ${error}`);

const setKurzBezeichnungIfNotEmptyAndChanged =
  <T>(formikProps: FormikProps<T>) =>
  (newKurzBezeichnung: string) => {
    if (kurzBezeichnungNotEmptyAndChanged(newKurzBezeichnung, formikProps)) {
      formikProps.setFieldValue('kurzBezeichnung', newKurzBezeichnung);
    }
  };

const kurzBezeichnungNotEmptyAndChanged = <T>(newKurzBezeichnung: string, formikProps: FormikProps<T>) => {
  const currentKurzBezeichnung = get(formikProps.values, 'kurzBezeichnung');
  return currentKurzBezeichnung !== newKurzBezeichnung && !isEmpty(newKurzBezeichnung);
};
