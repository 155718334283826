import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { Input } from 'formik-antd';
import { StreetAddressCreateFormValues } from '../Create/streetAddressCreateFormMapper';
import CountryCodeSelect from '../../../../../../shared/components/CountryCode/CountryCodeSelect';
import { FormFields } from '../../../../../../helpers/formikHelper';
import { StreetAddressUpdateFormValues } from '../Update/streetAddressUpdateFormMapper';
import { useGetAppFieldHelpText } from '../../../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  formFields: FormFields<StreetAddressCreateFormValues> | FormFields<StreetAddressUpdateFormValues>;
};

const StreetAddressInputs: FC<Props> = ({ formFields }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'AddressStreet'>('AddressStreet');

  return (
    <>
      <Row gutter={8}>
        <Col span={12}>
          <FormItemWithFieldHelp name={formFields.street} label="Straße" fieldHelp={getFieldHelpText('AddressStreet.street')}>
            <Input id={formFields.street} name={formFields.street} type="text" placeholder="Straße" />
          </FormItemWithFieldHelp>
        </Col>
        <Col span={12}>
          <FormItemWithFieldHelp
            name={formFields.houseEntranceApartmentNumber}
            label="Hausnummer / Stiege / Tür"
            fieldHelp={getFieldHelpText('AddressStreet.houseEntranceApartmentNumber')}
          >
            <Input
              id={formFields.houseEntranceApartmentNumber}
              name={formFields.houseEntranceApartmentNumber}
              type="text"
              placeholder="Hausnummer / Stiege / Tür"
            />
          </FormItemWithFieldHelp>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={10}>
          <FormItemWithFieldHelp name={formFields.zipCode} label="Postleitzahl" fieldHelp={getFieldHelpText('AddressStreet.zipCode')}>
            <Input id={formFields.zipCode} name={formFields.zipCode} type="text" placeholder="Postleitzahl" />
          </FormItemWithFieldHelp>
        </Col>
        <Col span={14}>
          <FormItemWithFieldHelp name={formFields.city} label="Ort" fieldHelp={getFieldHelpText('AddressStreet.city')}>
            <Input id={formFields.city} name={formFields.city} type="text" placeholder="Ort" />
          </FormItemWithFieldHelp>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={10}>
          <FormItemWithFieldHelp
            name={formFields.cityAdditionalInformation}
            label="Ortzusatz"
            fieldHelp={getFieldHelpText('AddressStreet.cityAdditionalInformation')}
          >
            <Input id={formFields.cityAdditionalInformation} name={formFields.cityAdditionalInformation} type="text" placeholder="Ortzusatz" />
          </FormItemWithFieldHelp>
        </Col>
        <Col span={14}>
          <CountryCodeSelect name={formFields.countryCodeIso2} fieldHelp={getFieldHelpText('AddressStreet.countryCodeIso2')} />
        </Col>
      </Row>
    </>
  );
};

export default StreetAddressInputs;
