import React from 'react';
import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { VerarbeitungZinslisteDetailsPageRouteParams } from './routes';
import menuListZinsliste from './menuListZinsliste';
import { useZinslisteGenerierlaufQuery } from '../../../features/Verarbeitung/gql/ZinslisteGenerierlauf/ZinslisteGenerierlaufQueries.types';
import { ZinslisteGenerierlauf } from '../../../types';
import { useRestartZinslisteGenerierlaufMutation } from '../../../features/Verarbeitung/gql/ZinslisteGenerierlauf/ZinslisteGenerierlaufMutations.types';

const ZinslisteDetailsPage = () => {
  const { generierlaufId } = useParams() as VerarbeitungZinslisteDetailsPageRouteParams;

  const { data, refetch } = useZinslisteGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getZinslisteGenerierlauf.data;

  const [runRestart] = useRestartZinslisteGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<ZinslisteGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.ZinslisteErstellung}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.ZinslisteErstellung}
          onGenerierlaufRestart={runRestart}
        />
      )}
      sidebarMenuList={(generierlauf) => menuListZinsliste(generierlauf)}
    />
  );
};

export default ZinslisteDetailsPage;
