import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import CurrentPasswordFormPart from '../../../shared/components/Password/CurrentPasswordFormPart';
import NewPasswordFormPart from '../../../shared/components/Password/NewPasswordFormPart';
import { useChangePasswordMeMutation } from '../../../graphql/Authentication/UserMutation.types';
import { showSuccessMsgOther } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { changePasswordFormInitialValues, ChangePasswordFormValues } from './changePasswordFormMapper';
import FormButtons from '../../../components/Button/FormButtons';

type ChangePasswordFormProps = {
  onCancel: () => void;
  onSuccess: () => void;
};

const ChangePasswordForm: FC<ChangePasswordFormProps> = ({ onCancel, onSuccess }) => {
  const [changePassword, { loading }] = useChangePasswordMeMutation({
    onCompleted: () => {
      showSuccessMsgOther(`Wir haben Ihr Passwort geändert`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<ChangePasswordFormValues>
      initialValues={changePasswordFormInitialValues}
      onSubmit={(values, formikHelpers) => {
        const { newPassword, oldPassword } = values;
        changePassword({ variables: { newPassword, oldPassword } }).finally(() => formikHelpers.setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <CurrentPasswordFormPart currentPasswordInputName="oldPassword" />
          <NewPasswordFormPart newPasswordLabel="Neues Passwort" />
          <FormButtons onCancel={onCancel} updateMode isOkDisabled={formikProps.isSubmitting} isOkLoading={loading} />
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
