import React, { FC, useEffect } from 'react';
import { FormikProps } from 'formik';
import { bookingSuggestionFormFields, BookingSuggestionFormValues } from '../Form/buchungsmaskeFormMapper';
import { AvailableAmountText } from '../../../shared/AvailableAmount/AvailableAmountText';
import { calculateAvailableAmountFromFormik } from './bookingSuggestionAuszifferungBlockHelpers';

type Props = {
  formikProps: FormikProps<BookingSuggestionFormValues>;
};

const AvailableAmount: FC<Props> = ({ formikProps }) => {
  const { values: formikValues } = formikProps;

  const availableAmount = calculateAvailableAmountFromFormik(formikValues);

  useEffect(() => {
    formikProps.setFieldValue(bookingSuggestionFormFields.availableAmount, availableAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableAmount, formikValues.buchungsanweisung.buchungszeileList]);

  return <AvailableAmountText text="Verfügbarer Betrag" value={formikValues.availableAmount} />;
};

export default AvailableAmount;
