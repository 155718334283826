import React, { FC } from 'react';
import { Button, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../../hooks/useToggle';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';
import UstVomAufwandCreateForm from '../Form/Create/UstVomAufwandCreateForm';

type Props = {
  objektId: string;
  objektVerwaltungSeit: string;
};

const UstVomAufwandErstellenBtnAndModal: FC<Props> = ({ objektId, objektVerwaltungSeit }) => {
  const [isCollapsed, onCollapse] = useToggle();

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <Button type="primary" size="small" icon={<PlusOutlined />} onClick={onCollapse}>
          Ust. vom Aufwand berechnen
        </Button>
      </ButtonsAligned>
      <Modal title="Ust. vom Aufwand berechnen" onCancel={onCollapse} open={isCollapsed} destroyOnClose maskClosable={false} footer={false}>
        <UstVomAufwandCreateForm objektId={objektId} onSuccess={onCollapse} onCancel={onCollapse} verwaltungSeit={objektVerwaltungSeit} />
      </Modal>
    </>
  );
};

export default UstVomAufwandErstellenBtnAndModal;
