import React, { FC, useState } from 'react';
import { Button, Modal, Space } from 'antd';
import { DeleteOutlined, EditOutlined, FileExclamationTwoTone, FileTextTwoTone } from '@ant-design/icons';
import { useDeletePostItMutation, useUpdatePostItMutation } from './gql/PostItMutations.types';
import { showSuccessMsgDelete, showSuccessMsgUpdate } from '../message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../helpers/apolloHelper';
import { PostIt, PostItInput } from '../../types';
import { useToggle } from '../../hooks/useToggle';
import PostItUpdateForm from './Form/PostItUpdateForm';
import PostItViewMode from './ViewMode/PostItViewMode';

type Props = {
  postIt: PostIt;
  refresh: () => void;
  showImmerAnzeigenInUpdateMode?: boolean;
};

const PostItIconAndViewAndUpdateMode: FC<Props> = ({ postIt, refresh, showImmerAnzeigenInUpdateMode }) => {
  const [isModalOpen, setIsModalOpen] = useState(postIt.immerAnzeigen);
  const [isInUpdateMode, toggleUpdateMode] = useToggle();

  const [runDelete] = useDeletePostItMutation({
    variables: {
      postItId: postIt.postItId,
    },
    onCompleted: () => {
      showSuccessMsgDelete('Post-It');
      closeModal();
      refresh();
    },
  });

  const [runUpdate] = useUpdatePostItMutation({
    onCompleted: () => {
      showSuccessMsgUpdate('Post-It');
      setIsModalOpen(false);
      toggleUpdateMode();
      refresh();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const handleUpdate = (values: PostItInput) => {
    runUpdate({
      variables: {
        postItId: postIt.postItId,
        input: {
          immerAnzeigen: values.immerAnzeigen,
          titel: values.titel,
          text: values.text,
        },
      },
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const modalIcons = (
    <Space>
      <Button
        size="small"
        shape="circle"
        disabled={isInUpdateMode}
        icon={<EditOutlined />}
        onClick={(e) => {
          e.stopPropagation();
          toggleUpdateMode();
        }}
      />
      <Button
        size="small"
        shape="circle"
        disabled={isInUpdateMode}
        icon={<DeleteOutlined />}
        onClick={(e) => {
          e.stopPropagation();
          showConfirmDeletePostIt(runDelete);
        }}
      />
    </Space>
  );

  return (
    <>
      {postIt.immerAnzeigen ? (
        <FileExclamationTwoTone onClick={showModal} twoToneColor="#FAAD14" />
      ) : (
        <FileTextTwoTone onClick={showModal} twoToneColor="#FAAD14" />
      )}
      {isInUpdateMode ? (
        <PostItUpdateForm
          postIt={postIt}
          isOpen={isModalOpen && isInUpdateMode}
          onSubmit={(values) => handleUpdate(values)}
          onCancel={() => {
            toggleUpdateMode();
          }}
          modalIcons={modalIcons}
          showImmerAnzeigen={showImmerAnzeigenInUpdateMode}
        />
      ) : (
        <PostItViewMode postIt={postIt} isOpen={isModalOpen && !isInUpdateMode} closeModal={closeModal} modalIcons={modalIcons} />
      )}
    </>
  );
};

const showConfirmDeletePostIt = (runDelete: () => Promise<any>) => {
  Modal.confirm({
    title: 'Möchten Sie das Post-It löschen?',
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};
export default PostItIconAndViewAndUpdateMode;
