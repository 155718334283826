import React, { FC, useEffect, useState } from 'react';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { DndContext, DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import TicketSubTicketListingCollapsePanel from './TicketSubTicketListingCollapsePanel';
import { useTicketChangeSubTicketPositionMutation } from '../../gql/TicketMutations.types';
import { showSuccessMsgOther } from '../../../../components/message';
import { TicketBase } from '../../../../types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { StyledCollapse } from './styled/StyledCollapse.style';

type Props = {
  subTicketList: TicketBase[];
  refetch: () => Promise<unknown>;
};

const TicketSubTicketListingCollapse: FC<Props> = ({ subTicketList, refetch }) => {
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const [subTickets, setsubTickets] = useState(subTicketList);

  const [runChangePosition, loading] = useTicketChangeSubTicketPositionMutation({
    onCompleted: (data) => {
      const title = subTickets.find((i) => i.ticketId === data.actionTicketChangeSubTicketPosition.data.ticketId)?.title || '';
      showSuccessMsgOther(`Die Position der Unteraufgabe ${title} hat sich geändert.`);
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  useEffect(() => {
    !loading && setsubTickets(subTicketList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    setsubTickets(subTicketList);
  }, [subTicketList]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setsubTickets((previous) => {
        const current = subTickets.find((i) => i.ticketId === active.id);
        const activeIndex = previous.findIndex((i) => i.ticketId === active.id);
        const overIndex = previous.findIndex((i) => i.ticketId === over?.id);

        if (overIndex >= 0) {
          runChangePosition({
            variables: {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              ticketId: current!.ticketId,
              input: {
                position: overIndex,
              },
            },
          });
          return arrayMove(previous, activeIndex, overIndex);
        }
        return previous;
      });
    }
  };

  const onDragStart = ({ active }: DragStartEvent) => {
    const activeId = active.id as string;
    if (!activeKeys.includes(activeId)) return;
    setActiveKeys((keys) => {
      if (!keys.includes(activeId)) return keys;
      return keys.filter((key) => key !== activeId);
    });
  };

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <SortableContext items={subTickets.map((i) => i.ticketId)} strategy={verticalListSortingStrategy} disabled={!loading || subTickets.length < 2}>
        <StyledCollapse ghost activeKey={activeKeys}>
          {subTickets.map((ticket, index) => (
            <TicketSubTicketListingCollapsePanel
              key={ticket.ticketId}
              index={index}
              ticket={ticket}
              activeKeys={activeKeys}
              setActiveKeys={setActiveKeys}
              refetch={refetch}
            />
          ))}
        </StyledCollapse>
      </SortableContext>
    </DndContext>
  );
};

export default TicketSubTicketListingCollapse;
