import { Dispatch, FC, PropsWithChildren, useReducer } from 'react';
import { ConfigProvider as ConfigProviderAntD, theme } from 'antd';
import { IntlProvider } from 'react-intl';
import { localeReducer } from './localeReducer';
import { Locale, LocaleAction } from './locale.model';
import { DEFAULT_LOCALE, mapLocaleToAntDesignLocale, mapLocaleToTranslations } from './localeMapper';
import createCtx from '../../helpers/createCtx';
import { useLocaleSync } from './useLocaleSync';

type LocaleProviderContextType = {
  locale: Locale;
  dispatch: Dispatch<LocaleAction>;
};

const [useLocale, LocaleContextProvider] = createCtx<LocaleProviderContextType>();

const LocaleProvider: FC<PropsWithChildren> = ({ children }) => {
  const [{ locale }, dispatch] = useReducer(localeReducer, { locale: DEFAULT_LOCALE });
  useLocaleSync(locale);

  return (
    <LocaleContextProvider value={{ locale, dispatch }}>
      <ConfigProviderAntD
        locale={mapLocaleToAntDesignLocale(locale)}
        theme={{
          hashed: false,
          algorithm: theme.compactAlgorithm,
        }}
      >
        <IntlProvider locale={locale} messages={mapLocaleToTranslations(locale)}>
          {children}
        </IntlProvider>
      </ConfigProviderAntD>
    </LocaleContextProvider>
  );
};

const changeLocale = (dispatch: React.Dispatch<LocaleAction>, locale: Locale) => {
  dispatch({ type: 'SET_LOCALE', payload: locale });
};

export { LocaleProvider, useLocale, LocaleContextProvider, changeLocale };
