/* PDF */
const URI_APP_PDF = '/pdf';
const URI_APP_PDF_HAUSEIGENTUMER_ABRECHNUNG = '/pdf/hauseigentumer-abrechnung';
const URI_APP_PDF_MAHNUNG = '/pdf/mahnung';
const URI_APP_PDF_OBJEKT_ABRECHNUNG = '/pdf/objekt-abrechnung';
const URI_APP_PDF_TOP_ABRECHNUNG = '/pdf/top-abrechnung';
const URI_APP_PDF_TEMPLATE = '/pdf-template';
const URI_APP_PDF_VORSCHREIBUNG_DAUERRECHNUNG = '/pdf/vorschreibung-dauerrechnung';
const URI_APP_PDF_VORSCHREIBUNG_DAUERRECHNUNG_STORNO = '/pdf/vorschreibung-dauerrechnung-storno';
const URI_APP_PDF_VORSCHREIBUNG_EINZEL = '/pdf/vorschreibung-einzel';
const URI_APP_PDF_VORSCHREIBUNG_EINZEL_STORNO = '/pdf/vorschreibung-einzel-storno';
const URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_RECHNUNG = '/pdf/vorschreibung-einzel-abrechnungergebnis-rechnung';
const URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_RECHNUNG_STORNO = '/pdf/vorschreibung-einzel-abrechnungergebnis-rechnung-storno';
const URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_GUTSCHRIFT = '/pdf/vorschreibung-einzel-abrechnungergebnis-gutschrift';
const URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_GUTSCHRIFT_STORNO = '/pdf/vorschreibung-einzel-abrechnungergebnis-gutschrift-storno';
const URI_APP_PDF_ZINSLISTE = '/pdf/zinliste';
const URI_APP_PDF_FINANCIAL_PLAN_OBJEKT = '/pdf/wirtschaftsplan-objekt';
const URI_APP_PDF_FINANCIAL_PLAN_TOPS = '/pdf/wirtschaftsplan-tops';
const URI_APP_PDF_OFFENE_POSTEN_LIST_DEBITOREN = '/pdf/offene-posten-list-debitoren';
const URI_APP_PDF_SEPA_LASTSCHRIFT_MANDAT = '/pdf/sepa-lastschrift-mandat';
const URI_APP_PDF_SUB_ADMINISTRATION_OBJEKT_ABRECHNUNG = '/pdf/sub-administration-objekt-abrechnung';
const URI_APP_PDF_SUB_ADMINISTRATION_TOP_ABRECHNUNG = '/pdf/sub-administration-top-abrechnung';

export {
  URI_APP_PDF,
  URI_APP_PDF_MAHNUNG,
  URI_APP_PDF_HAUSEIGENTUMER_ABRECHNUNG,
  URI_APP_PDF_OBJEKT_ABRECHNUNG,
  URI_APP_PDF_TOP_ABRECHNUNG,
  URI_APP_PDF_TEMPLATE,
  URI_APP_PDF_VORSCHREIBUNG_DAUERRECHNUNG,
  URI_APP_PDF_VORSCHREIBUNG_DAUERRECHNUNG_STORNO,
  URI_APP_PDF_VORSCHREIBUNG_EINZEL,
  URI_APP_PDF_VORSCHREIBUNG_EINZEL_STORNO,
  URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_RECHNUNG,
  URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_RECHNUNG_STORNO,
  URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_GUTSCHRIFT,
  URI_APP_PDF_VORSCHREIBUNG_EINZEL_ABR_ERG_GUTSCHRIFT_STORNO,
  URI_APP_PDF_ZINSLISTE,
  URI_APP_PDF_FINANCIAL_PLAN_OBJEKT,
  URI_APP_PDF_FINANCIAL_PLAN_TOPS,
  URI_APP_PDF_OFFENE_POSTEN_LIST_DEBITOREN,
  URI_APP_PDF_SEPA_LASTSCHRIFT_MANDAT,
  URI_APP_PDF_SUB_ADMINISTRATION_OBJEKT_ABRECHNUNG,
  URI_APP_PDF_SUB_ADMINISTRATION_TOP_ABRECHNUNG,
};
