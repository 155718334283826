import React from 'react';
import { Route } from 'react-router';
import { isActiveForSomeFirmendaten } from '../../security/permissionChecks';
import AuthRoute from '../../security/AuthRoute';
import NotificationPage from './NotificationPage';
import { URI_NOTIFICATION_PAGE } from '../../features/Notification/notificationUriPaths';

const notificationPageRoute = (
  <Route path={URI_NOTIFICATION_PAGE} element={<AuthRoute hasPermissionFn={isActiveForSomeFirmendaten} element={<NotificationPage />} />} />
);

export default notificationPageRoute;
