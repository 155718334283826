import { FC } from 'react';
import { FormikProps } from 'formik';
import {
  VorschreibungVerbuchenVerarbeitungEntryFiltersFormValues,
  vorschreibungVerbuchenVerarbEntryListingFiltersFormFields,
} from './ListingFiltersFormMapper';
import FormItemWithoutColon from '../../../../../../components/Form/FormItemWithoutColon';
import GenerierlaufFakturierungsperiodeFormSelect from '../../../../shared/Filters/Order/GenerierlaufFakturierungsperiodeFormSelect';
import { OrderGenerierlaufType } from '../../../../../../types';
import GenerierlaufEntryExitCodeListFormSelect from '../../../../shared/Filters/Order/GenerierlaufEntryExitCodeListFormSelect';
import GenerierlaufRechnungsAusstellerFormSelect from '../../../../shared/Filters/Order/GenerierlaufRechnungsAusstellerFormSelect';
import GenerierlaufRechnungsEmpfaengerFormSelect from '../../../../shared/Filters/Order/GenerierlaufRechnungsEmpfaengerFormSelect';
import FiltersWith4OrMoreFields from '../../../../../../components/Filters/FiltersWith4OrMoreFields';

type Props = {
  formikProps: FormikProps<VorschreibungVerbuchenVerarbeitungEntryFiltersFormValues>;
  generierlaufId: string;
};

const ListingFilters: FC<Props> = ({ formikProps, generierlaufId }) => {
  return (
    <FiltersWith4OrMoreFields
      hideTitle
      leftColumn={
        <>
          <FormItemWithoutColon name={vorschreibungVerbuchenVerarbEntryListingFiltersFormFields.fakturierungsperiode} label="Fakturierungsperiode">
            <GenerierlaufFakturierungsperiodeFormSelect
              name={vorschreibungVerbuchenVerarbEntryListingFiltersFormFields.fakturierungsperiode}
              id={vorschreibungVerbuchenVerarbEntryListingFiltersFormFields.fakturierungsperiode}
              generierlaufId={generierlaufId}
              generierlaufType={OrderGenerierlaufType.AuftragVerbuchen}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={vorschreibungVerbuchenVerarbEntryListingFiltersFormFields.exitCodeList} label="Exit Code Status">
            <GenerierlaufEntryExitCodeListFormSelect
              name={vorschreibungVerbuchenVerarbEntryListingFiltersFormFields.exitCodeList}
              generierlaufId={generierlaufId}
              generierlaufType={OrderGenerierlaufType.AuftragVerbuchen}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
      rightColumn={
        <>
          <FormItemWithoutColon name={vorschreibungVerbuchenVerarbEntryListingFiltersFormFields.rechnungsausstellerId} label="Rechnungsaussteller">
            <GenerierlaufRechnungsAusstellerFormSelect
              name={vorschreibungVerbuchenVerarbEntryListingFiltersFormFields.rechnungsausstellerId}
              generierlaufId={generierlaufId}
              generierlaufType={OrderGenerierlaufType.AuftragVerbuchen}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={vorschreibungVerbuchenVerarbEntryListingFiltersFormFields.rechnungsempfaengerId} label="Rechnungsempfänger">
            <GenerierlaufRechnungsEmpfaengerFormSelect
              name={vorschreibungVerbuchenVerarbEntryListingFiltersFormFields.rechnungsempfaengerId}
              generierlaufId={generierlaufId}
              generierlaufType={OrderGenerierlaufType.AuftragVerbuchen}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
    />
  );
};

export default ListingFilters;
