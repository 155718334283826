import React from 'react';
import { Route } from 'react-router';
import IndexSeriesDetailsPage from './IndexSeriesDetailsPage';
import AuthRoute from '../../../security/AuthRoute';
import { URI_ANDROMEDA_SYS_SETTINGS_INDEX_SERIES_DETAILS_PAGE } from '../../../features/IndexSeries/indexSeriesUriPaths';

export type IndexSeriesEntryDetailsRouteParams = { indexSeriesId: string };

const andromedaSysSettingsIndexSeriesDetailsPageRoute = (
  <Route path={URI_ANDROMEDA_SYS_SETTINGS_INDEX_SERIES_DETAILS_PAGE} element={<AuthRoute element={<IndexSeriesDetailsPage />} />} />
);

export default andromedaSysSettingsIndexSeriesDetailsPageRoute;
