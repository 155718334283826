import { FormFields } from '../../../../../helpers/formikHelper';
import { ValuesDescriptionForKommentar, ValuesDescriptions } from '../../../../../components/Timeline/versionTimelineHelper';
import {
  Verwaltungsart,
  VorsteuerkuerzungEinstellung,
  VorsteuerkuerzungEinstellungCreateInput,
  VorsteuerkuerzungEinstellungUpdateInput,
  VorsteuerkuerzungKontenZuweisung,
  VorsteuerkuerzungKontenZuweisungCreateInput,
  VorsteuerkuerzungKontenZuweisungUpdateInput,
} from '../../../../../types';
import { ITimeblock } from '../../../../../components/Timeline/timelineCtx';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import { isWegObjekt } from '../../../../Objekt/objektHelper';

export type VorsteuerkuerzungKontenZuweisungVersionFormValues = {
  validFrom: string;
  kontenZuweisungList: VorsteuerkuerzungKontenZuweisungFormValues[];
  kommentar?: string | null;
};

export type VorsteuerkuerzungKontenZuweisungFormValues = {
  aufteilungsschluesselId: string;
  aufwandsKontoIdList: string[];
  vorsteuerkuerzungKontenZuweisungId?: string;
  vorsteuerkuerzungKontoId: string;
  vorsteuerkuerzungEigennutzungKontoId?: string | null;
  vorsteuerkuerzungPauschalmieterKontoId?: string | null;
  vorsteuerkuerzungWeMieterKontoId?: string | null;
};

export const vstKuerzungKontenZuordnungVersionFormFields: FormFields<VorsteuerkuerzungKontenZuweisungVersionFormValues> = {
  validFrom: 'validFrom',
  kontenZuweisungList: 'kontenZuweisungList',
  kommentar: 'kommentar',
};

export const vstKuerzungKontenZuweisungFormFields: FormFields<VorsteuerkuerzungKontenZuweisungFormValues> = {
  aufteilungsschluesselId: 'aufteilungsschluesselId',
  aufwandsKontoIdList: 'aufwandsKontoIdList',
  vorsteuerkuerzungKontenZuweisungId: 'vorsteuerkuerzungKontenZuweisungId',
  vorsteuerkuerzungKontoId: 'vorsteuerkuerzungKontoId',
  vorsteuerkuerzungEigennutzungKontoId: 'vorsteuerkuerzungEigennutzungKontoId',
  vorsteuerkuerzungPauschalmieterKontoId: 'vorsteuerkuerzungPauschalmieterKontoId',
  vorsteuerkuerzungWeMieterKontoId: 'vorsteuerkuerzungWeMieterKontoId',
};

export const vstKuerzungKontenZuweisungFormValuesDescriptions = (
  verwaltungsart: Verwaltungsart
): ValuesDescriptions<VorsteuerkuerzungKontenZuweisungFormValues> => {
  return {
    aufwandsKontoIdList: 'Aufwandskonten',
    aufteilungsschluesselId: 'Aufteilungschlüssel',
    vorsteuerkuerzungKontoId: isWegObjekt(verwaltungsart) ? 'Vorsteuerkürzungskonto WE' : 'Vorsteuerkürzungskonto Mieter',
    vorsteuerkuerzungKontenZuweisungId: '',
    vorsteuerkuerzungEigennutzungKontoId: isWegObjekt(verwaltungsart) ? '' : 'Vorsteuerkürzungskonto Eigennutzung',
    vorsteuerkuerzungPauschalmieterKontoId: 'Vorsteuerkürzungskonto Pauschalmieter',
    vorsteuerkuerzungWeMieterKontoId: isWegObjekt(verwaltungsart) ? 'Vorsteuerkürzungskonto Mieter' : '',
  };
};

export const vstKuerzungKontenZuweisungDescriptionsForKommentar: ValuesDescriptionForKommentar<VorsteuerkuerzungKontenZuweisungFormValues> = {
  aufwandsKontoIdList: 'Aufwandskonten',
  aufteilungsschluesselId: 'Aufteilungschlüssel',
  vorsteuerkuerzungKontoId: 'Vorsteuerkürzungskonto Mieter',
  vorsteuerkuerzungKontenZuweisungId: '',
  vorsteuerkuerzungEigennutzungKontoId: 'Vorsteuerkürzungskonto Eigennutzung',
  vorsteuerkuerzungPauschalmieterKontoId: 'Vorsteuerkürzungskonto Paushalmieter',
  vorsteuerkuerzungWeMieterKontoId: 'Vorsteuerkürzungskonto Mieter',
};

export const mapTimeblockToFormValues = (timeblock: ITimeblock<VorsteuerkuerzungEinstellung>): VorsteuerkuerzungKontenZuweisungVersionFormValues => {
  return {
    validFrom: timeblock.validFrom,
    kontenZuweisungList: timeblock.kontenZuweisungList ? mapVstKuerzungKontenZuweisungToFormValues(timeblock.kontenZuweisungList) : [],
    kommentar: timeblock.kommentar ?? '',
  };
};

export const vstKuerzungKontenZuweisungFormInitialValues: VorsteuerkuerzungKontenZuweisungFormValues = {
  aufteilungsschluesselId: '',
  aufwandsKontoIdList: [],
  vorsteuerkuerzungKontoId: '',
  vorsteuerkuerzungEigennutzungKontoId: null,
  vorsteuerkuerzungPauschalmieterKontoId: null,
  vorsteuerkuerzungWeMieterKontoId: null,
};

const mapVstKuerzungKontenZuweisungToFormValues = (
  vorsteuerkuerzungenKonten: VorsteuerkuerzungKontenZuweisung[]
): VorsteuerkuerzungKontenZuweisungFormValues[] => {
  return vorsteuerkuerzungenKonten.map((konto) => {
    return {
      aufteilungsschluesselId: konto.aufteilungsschluessel.aufteilungsschluesselId,
      aufwandsKontoIdList: konto.aufwandsKontoList.map((konto) => konto.kontoId),
      vorsteuerkuerzungKontenZuweisungId: konto.vorsteuerkuerzungKontenZuweisungId,
      vorsteuerkuerzungKontoId: konto.vorsteuerkuerzungKonto.kontoId,
      vorsteuerkuerzungEigennutzungKontoId: konto.vorsteuerkuerzungEigennutzungKonto?.kontoId,
      vorsteuerkuerzungPauschalmieterKontoId: konto.vorsteuerkuerzungPauschalmieterKonto?.kontoId,
      vorsteuerkuerzungWeMieterKontoId: konto.vorsteuerkuerzungWeMieterKonto?.kontoId,
    };
  });
};

export const mapFormValuesToVstKuerzungKontenZuweisungVersionCreate = (
  vorsteuerkuerzungen: VorsteuerkuerzungKontenZuweisungVersionFormValues,
  verwaltungsart: Verwaltungsart
): VorsteuerkuerzungEinstellungCreateInput => {
  return {
    validFrom: dayjsCustom(vorsteuerkuerzungen.validFrom).startOf('month').format('YYYY-MM-DD'),
    kontenZuweisungList: mapFormValuesToVstKuerzungKontenZuweisungCreate(vorsteuerkuerzungen.kontenZuweisungList),
    kommentar: vorsteuerkuerzungen.kommentar ?? '',
    verwaltungsart,
  };
};

export const mapFormValuesToVstKuerzungKontenZuweisungVersionUpdate = (
  vorsteuerkuerzungen: VorsteuerkuerzungKontenZuweisungVersionFormValues,
  verwaltungsart: Verwaltungsart
): VorsteuerkuerzungEinstellungUpdateInput => {
  return {
    validFrom: dayjsCustom(vorsteuerkuerzungen.validFrom).startOf('month').format('YYYY-MM-DD'),
    kontenZuweisungList: mapFormValuesToVstKuerzungKontenZuweisungUpdate(vorsteuerkuerzungen.kontenZuweisungList),
    kommentar: vorsteuerkuerzungen.kommentar ?? '',
    verwaltungsart,
  };
};

const mapFormValuesToVstKuerzungKontenZuweisungCreate = (
  vstKuerzungKonten: VorsteuerkuerzungKontenZuweisungFormValues[]
): VorsteuerkuerzungKontenZuweisungCreateInput[] => {
  return vstKuerzungKonten.map((konto) => {
    return {
      aufteilungsschluesselId: konto.aufteilungsschluesselId,
      aufwandsKontoIdList: konto.aufwandsKontoIdList,
      vorsteuerkuerzungKontoId: konto.vorsteuerkuerzungKontoId,
      vorsteuerkuerzungEigennutzungKontoId: konto.vorsteuerkuerzungEigennutzungKontoId,
      vorsteuerkuerzungPauschalmieterKontoId: konto.vorsteuerkuerzungPauschalmieterKontoId,
      vorsteuerkuerzungWeMieterKontoId: konto.vorsteuerkuerzungWeMieterKontoId,
    };
  });
};

const mapFormValuesToVstKuerzungKontenZuweisungUpdate = (
  vstKuerzungKonten: VorsteuerkuerzungKontenZuweisungFormValues[]
): VorsteuerkuerzungKontenZuweisungUpdateInput[] => {
  return vstKuerzungKonten.map((konto) => {
    return {
      aufteilungsschluesselId: konto.aufteilungsschluesselId,
      aufwandsKontoIdList: konto.aufwandsKontoIdList,
      vorsteuerkuerzungKontenZuweisungId: konto.vorsteuerkuerzungKontenZuweisungId,
      vorsteuerkuerzungKontoId: konto.vorsteuerkuerzungKontoId,
      vorsteuerkuerzungEigennutzungKontoId: konto.vorsteuerkuerzungEigennutzungKontoId,
      vorsteuerkuerzungPauschalmieterKontoId: konto.vorsteuerkuerzungPauschalmieterKontoId,
      vorsteuerkuerzungWeMieterKontoId: konto.vorsteuerkuerzungWeMieterKontoId,
    };
  });
};
