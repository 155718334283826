import styled from 'styled-components';

export const StyledTableWithAlignedCols = styled('table')`
  min-width: 100%;
  thead {
    tr:hover {
      background: #fafafa;
    }
  }
  tbody {
    text-align: right;
    & .ant-table-cell:first-child {
      text-align: left !important;
    }
    tr:hover {
      :not(.table-in-table) {
        background: #fafafa;
      }
    }
  }
`;
