import { FormFields, mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';
import { BeVertragVersion, BeVertragVersionCreateInput, BeVertragVersionUpdateInput, PaymentMethod } from '../../../../../types';
import { ITimeblock } from '../../../../../components/Timeline/timelineCtx';
import { ValuesDescriptionForKommentar } from '../../../../../components/Timeline/versionTimelineHelper';
import { ObjectOfStrings } from '../../../../../shared/typeHelpers';

export type VertragVersionFormValues = {
  validFrom: string;
  dueDateVorschreibung: string;
  paymentMethod: PaymentMethod;
  verrechnungsart: string;
  kommentar: string;
};

export const vertragVersionFormFields: FormFields<VertragVersionFormValues> = {
  validFrom: 'validFrom',
  dueDateVorschreibung: 'dueDateVorschreibung',
  paymentMethod: 'paymentMethod',
  verrechnungsart: 'verrechnungsart',
  kommentar: 'kommentar',
};

export const mapTimeblockToFormValues = (timeblock: ITimeblock<BeVertragVersion>): VertragVersionFormValues => ({
  validFrom: timeblock.validFrom,
  dueDateVorschreibung: timeblock.dueDateVorschreibung.value,
  paymentMethod: timeblock.paymentMethod.value,
  verrechnungsart: timeblock.verrechnungsart.value,
  kommentar: timeblock.kommentar ? timeblock.kommentar : '',
});

export const mapFormValuesToBeVertragVersion = (values: VertragVersionFormValues): BeVertragVersionCreateInput | BeVertragVersionUpdateInput => ({
  dueDateVorschreibung: values.dueDateVorschreibung || '',
  validFrom: values.validFrom ? mapFormDateValueToDateString(values.validFrom) : '',
  paymentMethod: values.paymentMethod || '',
  verrechnungsart: values.verrechnungsart || '',
  kommentar: values.kommentar || '',
});

export const vertragVersionFormValuesDescriptions: ObjectOfStrings<VertragVersionFormValues> = {
  validFrom: 'Gültig ab',
  dueDateVorschreibung: 'Fälligkeit der Vorschreibung',
  paymentMethod: 'Zahlungsart',
  verrechnungsart: 'Verrechnungsart',
  kommentar: '',
};

export const vertragVersionFormValuesDescriptionsForKommentar: ValuesDescriptionForKommentar<VertragVersionFormValues> = {
  validFrom: 'Gültigkeitsdatum',
  dueDateVorschreibung: 'Fälligkeit der Vorschreibung',
  paymentMethod: 'Zahlungsart',
  verrechnungsart: 'Verrechnungsart',
};
