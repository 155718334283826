import React, { FC } from 'react';
import { DeviationChangeTypeTuple } from '../../../../../types';
import {
  isBuchungsanweisungSourceEntity,
  isHeAbrechnungHeVertragSourceEntity,
  isObjektAbrechnungSourceEntity,
  isTopAbrechnungSourceEntity,
  isUstVomAufwandSourceEntity,
  isVertragSourceEntity,
  isVorsteuerkuerzungSourceEntity,
} from '../../../deviationHelpers';
import VertragSourceEntityHeader from './VertragSourceEntityHeader';
import VorschreibungSourceEntityHeader from './VorschreibungSourceEntityHeader';
import BuchungsanweisungSourceEntityHeader from './BuchungsanweisungSourceEntityHeader';
import ObjektAbrechnungSourceEntityHeader from './ObjektAbrechnungSourceEntityHeader';
import TopAbrechnungSourceEntityHeader from './TopAbrechnungSourceEntityHeader';
import VorsteuerkuerzungSourceEntityHeader from './VorsteuerkuerzungSourceEntityHeader';
import UstVomAufwandSourceEntityHeader from './UstVomAufwandSourceEntityHeader';
import HeAbrechnungSourceEntityHeader from './HeAbrechnungSourceEntityHeader';
import { DeviationSourceEntityFragment } from '../../../gql/deviationFragments.types';

type DeviationListGroupedBySourceEntityHeaderProps = {
  sourceEntity: DeviationSourceEntityFragment;
  changeType: DeviationChangeTypeTuple;
};

const SourceEntityHeader: FC<DeviationListGroupedBySourceEntityHeaderProps> = ({ sourceEntity, changeType }) => {
  if (isVertragSourceEntity(sourceEntity)) {
    return <VertragSourceEntityHeader changeType={changeType} sourceEntity={sourceEntity} />;
  }

  if (isBuchungsanweisungSourceEntity(sourceEntity)) {
    return <BuchungsanweisungSourceEntityHeader changeType={changeType} sourceEntity={sourceEntity} />;
  }

  if (isObjektAbrechnungSourceEntity(sourceEntity)) {
    return <ObjektAbrechnungSourceEntityHeader changeType={changeType} sourceEntity={sourceEntity} />;
  }

  if (isTopAbrechnungSourceEntity(sourceEntity)) {
    return <TopAbrechnungSourceEntityHeader changeType={changeType} sourceEntity={sourceEntity} />;
  }

  if (isUstVomAufwandSourceEntity(sourceEntity)) {
    return <UstVomAufwandSourceEntityHeader changeType={changeType} sourceEntity={sourceEntity} />;
  }

  if (isVorsteuerkuerzungSourceEntity(sourceEntity)) {
    return <VorsteuerkuerzungSourceEntityHeader changeType={changeType} sourceEntity={sourceEntity} />;
  }

  if (isHeAbrechnungHeVertragSourceEntity(sourceEntity)) {
    return <HeAbrechnungSourceEntityHeader changeType={changeType} sourceEntity={sourceEntity} />;
  }

  // return for isVorschreibungSourceEntity(sourceEntity)) === true, but used as default
  return <VorschreibungSourceEntityHeader changeType={changeType} sourceEntity={sourceEntity} />;
};

export default SourceEntityHeader;
