import React from 'react';
import { Link } from 'react-router-dom';

import { Tag } from 'antd';
import MitarbeiterTooltip from '../../../../components/Card/MitarbeiterTooltip';
import { pathsToWeAbrechnungDetails } from '../../BkOrWe/weAbrechnungUriPaths';
import { generatePathToVerarbeitungWeObjAbrDetailsPage } from '../../../Verarbeitung/verarbeitungApiPaths';
import StatusBadge from '../../../../components/Status/StatusBadge';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { AbrechnungListEntry, AbrechnungOrderBy } from '../../../../types';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import TruncatedTextWithTooltip from '../../../../components/Helpers/TruncatedTextWithTooltip';
import AuftragCreationStatusTag from '../../shared/AuftragCreationStatusTag';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import { isAbrechnungTypeSubAbrechnung } from '../../shared/abrechnungHelpers';
import { pathsToSubAbrechnungDetails } from '../../Sub/subAbrechnungUriPaths';

const objektAbrechnungTableColumns = (objektId: string): TableWithColSelectorColumnProps<AbrechnungListEntry>[] => [
  {
    title: 'Bezeichnung',
    defaultSelected: true,
    key: 'bezeichnung',
    dataIndex: AbrechnungOrderBy.Bezeichnung,
    sorter: true,
    render: (text, record) => <TruncatedTextWithTooltip text={record.bezeichnung} />,
  },
  {
    title: 'Kategorie/Typ',
    defaultSelected: true,
    key: 'type',
    dataIndex: AbrechnungOrderBy.Type,
    sorter: true,
    render: (text, record) => <Tag>{record.type.text}</Tag>,
  },
  {
    title: 'Zeitraum',
    defaultSelected: true,
    key: 'zeitraum',
    dataIndex: AbrechnungOrderBy.Zeitraum,
    sorter: true,
    render: (text, record) => (
      <>
        <CustomFormattedDate value={record.fromInclusive} /> – <CustomFormattedDate value={record.toInclusive} />
      </>
    ),
  },
  {
    title: 'Vorschreibungen',
    defaultSelected: true,
    render: (text, record) => (
      <AuftragCreationStatusTag
        auftragCreationStatus={record.auftragCreationStatus}
        countAuftragCreated={record.countAuftragCreated}
        countAuftragToCreateTotal={record.countAuftragToCreateTotal}
      />
    ),
  },
  {
    title: 'Verarbeitung',
    defaultSelected: true,
    width: 100,
    render: (text, record) => <Link to={generatePathToVerarbeitungWeObjAbrDetailsPage(record.generierlaufId)}>Verarbeitung</Link>,
  },
  {
    title: 'Status',
    defaultSelected: true,
    width: 120,
    key: 'status',
    dataIndex: AbrechnungOrderBy.Status,
    sorter: true,
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },
  {
    title: 'Erstellt am',
    width: 100,
    key: 'createTs',
    dataIndex: AbrechnungOrderBy.CreateTs,
    sorter: true,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    width: 70,
    render: (text, record) => (
      <MitarbeiterTooltip alignment="left" mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    fixed: 'right',
    width: 80,
    render: (text, record) => {
      let link = pathsToWeAbrechnungDetails(objektId, record.abrechnungId).abrechnungDetails;

      if (isAbrechnungTypeSubAbrechnung(record.type.value)) {
        link = pathsToSubAbrechnungDetails(objektId, record.abrechnungId).subAbr;
      }

      return <Link to={link}>Details</Link>;
    },
  },
];

export default objektAbrechnungTableColumns;
