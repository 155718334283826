import React from 'react';
import { Tag } from 'antd';
import { ObjektAbrechnungkreis } from '../../../../../../types';
import AbrKreisBezeichnung from '../../../../shared/AbrKreisBezeichnung';
import { EuroAmount } from '../../../../../../components/Number';
import { NestedTableWithColSelectorColProps } from '../../../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';

const objektAbrechnungTableColumns: NestedTableWithColSelectorColProps<ObjektAbrechnungkreis>[] = [
  {
    title: 'Abrechnungskreis',
    key: 'abrechnungskreis',
    width: 200,
    defaultSelected: true,
    render: (text, record) => <AbrKreisBezeichnung abrKreis={record} />,
  },
  {
    title: '',
    width: 100,
  },
  {
    title: '',
    width: 100,
  },
  {
    title: '',
    width: 100,
  },
  {
    title: 'Aufteilungsschlüssel',
    key: 'aufteilungsschluessel',
    width: 200,
    align: 'right',
    defaultSelected: true,
    render: (text, record) => <Tag>{record.auftSchluesselBezeichnung}</Tag>,
  },
  {
    title: 'Ausgaben',
    align: 'right',
    key: 'ausgaben',
    width: 180,
    defaultSelected: true,
    render: (text, record) => <EuroAmount value={record.sumAufwand} />,
  },
  {
    title: 'Einnahmen',
    key: 'einnahmen',
    align: 'right',
    width: 180,
    defaultSelected: true,
    render: (text, record) => <EuroAmount value={record.erloes.summe} />,
  },
  {
    title: 'Saldo',
    key: 'saldo',
    align: 'right',
    width: 120,
    defaultSelected: true,
    render: (text, record) => <EuroAmount value={record.saldo} />,
  },
];

export default objektAbrechnungTableColumns;
