import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type AufteilungsschluesselFieldsFragment = {
  aufteilungsschluesselId: string;
  bezeichnung: string;
  bezugsbasisEditable?: boolean | null;
  createTs: string;
  createdBy?: string | null;
  createdByMitarbeiterId?: string | null;
  deletable?: boolean | null;
  firmendatenId?: string | null;
  kurzBezeichnung: string;
  updatedByMitarbeiterId?: string | null;
  bezugsbasis: { text: string; value: Types.Bezugsbasis };
  masseinheit: { text: string; value: Types.Masseinheit };
  status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const AufteilungsschluesselFieldsFragmentDoc = gql`
  fragment AufteilungsschluesselFields on Aufteilungsschluessel {
    aufteilungsschluesselId
    bezeichnung
    bezugsbasis {
      text
      value
    }
    bezugsbasisEditable
    createTs
    createdBy
    createdByMitarbeiterId
    deletable
    firmendatenId
    kurzBezeichnung
    masseinheit {
      text
      value
    }
    status {
      text
      value
      description
    }
    updatedByMitarbeiterId
    warningList {
      attribute
      message
      type
    }
  }
`;
