import { ROOT_URL_FIRMENDATEN, ROOT_URL_MITARBEITER } from '../../shared/api/ApiPaths';

const rootUriFileForFirmendaten = (firmendatenId: string) => `${ROOT_URL_FIRMENDATEN}/${firmendatenId}/files`;
export const uriFilePreviewForFirmendaten = (firmendatenId: string, fileId: string) =>
  `${rootUriFileForFirmendaten(firmendatenId)}/${fileId}/download`;
export const uriFileOfFirmendaten = (firmendatenId: string, fileId: string, filename: string) =>
  `${uriFilePreviewForFirmendaten(firmendatenId, fileId)}/${filename}`;

const rootUriFileForMitarbeiter = (mitarbeiterId: string) => `${ROOT_URL_MITARBEITER}/${mitarbeiterId}/files`;
export const uriFileThumbnailForMitarbeiter = (mitarbeiterId: string, fileId: string) =>
  `${rootUriFileForMitarbeiter(mitarbeiterId)}/${fileId}/thumbnail`;
export const uriFilePreviewForMitarbeiter = (mitarbeiterId: string, fileId: string) =>
  `${rootUriFileForMitarbeiter(mitarbeiterId)}/${fileId}/download`;
