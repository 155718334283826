import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RechtstraegerSteuerpflichtListQueryVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type RechtstraegerSteuerpflichtListQuery = {
  getRechtstraegerSteuerpflichtList: {
    data: Array<{
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      validFrom: string;
      kommentar?: string | null;
      lastUpdateTs: string;
      rechtstraegerSteuerpflichtId: string;
      historicizedList?: Array<{
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        validFrom: string;
        kommentar?: string | null;
        lastUpdateTs: string;
        rechtstraegerSteuerpflichtId: string;
        steuerpflicht: { text: string; value: Types.Steuerpflicht };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      steuerpflicht: { text: string; value: Types.Steuerpflicht };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const RechtstraegerSteuerpflichtListDocument = gql`
  query RechtstraegerSteuerpflichtList($rechtstraegerId: ID!, $withDetails: Boolean) {
    getRechtstraegerSteuerpflichtList(rechtstraegerId: $rechtstraegerId, withDetails: $withDetails) {
      data {
        createTs
        createdBy
        createdByMitarbeiterId
        current
        deletable
        validFrom
        historicizedList {
          createTs
          createdBy
          createdByMitarbeiterId
          current
          deletable
          validFrom
          kommentar
          lastUpdateTs
          rechtstraegerSteuerpflichtId
          steuerpflicht {
            text
            value
          }
          warningList {
            attribute
            message
            type
          }
        }
        kommentar
        lastUpdateTs
        rechtstraegerSteuerpflichtId
        steuerpflicht {
          text
          value
        }
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useRechtstraegerSteuerpflichtListQuery(
  baseOptions: Apollo.QueryHookOptions<RechtstraegerSteuerpflichtListQuery, RechtstraegerSteuerpflichtListQueryVariables> &
    ({ variables: RechtstraegerSteuerpflichtListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RechtstraegerSteuerpflichtListQuery, RechtstraegerSteuerpflichtListQueryVariables>(
    RechtstraegerSteuerpflichtListDocument,
    options
  );
}
export function useRechtstraegerSteuerpflichtListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RechtstraegerSteuerpflichtListQuery, RechtstraegerSteuerpflichtListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RechtstraegerSteuerpflichtListQuery, RechtstraegerSteuerpflichtListQueryVariables>(
    RechtstraegerSteuerpflichtListDocument,
    options
  );
}
export function useRechtstraegerSteuerpflichtListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RechtstraegerSteuerpflichtListQuery, RechtstraegerSteuerpflichtListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RechtstraegerSteuerpflichtListQuery, RechtstraegerSteuerpflichtListQueryVariables>(
    RechtstraegerSteuerpflichtListDocument,
    options
  );
}
export type RechtstraegerSteuerpflichtListQueryHookResult = ReturnType<typeof useRechtstraegerSteuerpflichtListQuery>;
export type RechtstraegerSteuerpflichtListLazyQueryHookResult = ReturnType<typeof useRechtstraegerSteuerpflichtListLazyQuery>;
export type RechtstraegerSteuerpflichtListSuspenseQueryHookResult = ReturnType<typeof useRechtstraegerSteuerpflichtListSuspenseQuery>;
export type RechtstraegerSteuerpflichtListQueryResult = Apollo.QueryResult<
  RechtstraegerSteuerpflichtListQuery,
  RechtstraegerSteuerpflichtListQueryVariables
>;
