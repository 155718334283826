import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { KontierungstabelleFieldsFragmentDoc } from './KontierungstabelleFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateKsKontierungstabelleMutationVariables = Types.Exact<{
  kundenSystemId: Types.Scalars['ID']['input'];
  input: Types.KontierungstabelleCreateInput;
}>;

export type CreateKsKontierungstabelleMutation = {
  createKSKontierungstabelle: {
    data: {
      basiskonto: number;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kontierungstabelleId: string;
      updatedByMitarbeiterId?: string | null;
      errechnetesErloesKontoList: Array<{ buchungsKZ?: string | null; erloeskonto: number; firmendatenId?: string | null; steuersatz: number }>;
      status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
      subAdministrationExpenseAccount?: {
        bezeichnung: string;
        firmendatenId?: string | null;
        kontoId: string;
        kontoKlasse: string;
        nummer: string;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CreateFdKontierungstabelleMutationVariables = Types.Exact<{
  firmendatenId: Types.Scalars['ID']['input'];
  input: Types.KontierungstabelleCreateInput;
}>;

export type CreateFdKontierungstabelleMutation = {
  createFDKontierungstabelle: {
    data: {
      basiskonto: number;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kontierungstabelleId: string;
      updatedByMitarbeiterId?: string | null;
      errechnetesErloesKontoList: Array<{ buchungsKZ?: string | null; erloeskonto: number; firmendatenId?: string | null; steuersatz: number }>;
      status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
      subAdministrationExpenseAccount?: {
        bezeichnung: string;
        firmendatenId?: string | null;
        kontoId: string;
        kontoKlasse: string;
        nummer: string;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateKontierungstabelleMutationVariables = Types.Exact<{
  kontierungstabelleId: Types.Scalars['ID']['input'];
  input: Types.KontierungstabelleUpdateInput;
}>;

export type UpdateKontierungstabelleMutation = {
  updateKontierungstabelle: {
    data: {
      basiskonto: number;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kontierungstabelleId: string;
      updatedByMitarbeiterId?: string | null;
      errechnetesErloesKontoList: Array<{ buchungsKZ?: string | null; erloeskonto: number; firmendatenId?: string | null; steuersatz: number }>;
      status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
      subAdministrationExpenseAccount?: {
        bezeichnung: string;
        firmendatenId?: string | null;
        kontoId: string;
        kontoKlasse: string;
        nummer: string;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteKontierungstabelleMutationVariables = Types.Exact<{
  kontierungstabelleId: Types.Scalars['ID']['input'];
}>;

export type DeleteKontierungstabelleMutation = {
  deleteKontierungstabelle: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type ArchiveKontierungstabelleMutationVariables = Types.Exact<{
  kontierungstabelleId: Types.Scalars['ID']['input'];
}>;

export type ArchiveKontierungstabelleMutation = {
  actionArchiveKontierungstabelle: {
    data: {
      basiskonto: number;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kontierungstabelleId: string;
      updatedByMitarbeiterId?: string | null;
      errechnetesErloesKontoList: Array<{ buchungsKZ?: string | null; erloeskonto: number; firmendatenId?: string | null; steuersatz: number }>;
      status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
      subAdministrationExpenseAccount?: {
        bezeichnung: string;
        firmendatenId?: string | null;
        kontoId: string;
        kontoKlasse: string;
        nummer: string;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveKontierungstabelleMutationVariables = Types.Exact<{
  kontierungstabelleId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveKontierungstabelleMutation = {
  actionUnarchiveKontierungstabelle: {
    data: {
      basiskonto: number;
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      kontierungstabelleId: string;
      updatedByMitarbeiterId?: string | null;
      errechnetesErloesKontoList: Array<{ buchungsKZ?: string | null; erloeskonto: number; firmendatenId?: string | null; steuersatz: number }>;
      status: { description?: string | null; text: string; value: Types.KontierungstabelleStatus };
      subAdministrationExpenseAccount?: {
        bezeichnung: string;
        firmendatenId?: string | null;
        kontoId: string;
        kontoKlasse: string;
        nummer: string;
      } | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateKsKontierungstabelleDocument = gql`
  mutation CreateKSKontierungstabelle($kundenSystemId: ID!, $input: KontierungstabelleCreateInput!) {
    createKSKontierungstabelle(kundenSystemId: $kundenSystemId, input: $input) {
      data {
        ...KontierungstabelleFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KontierungstabelleFieldsFragmentDoc}
`;
export function useCreateKsKontierungstabelleMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateKsKontierungstabelleMutation, CreateKsKontierungstabelleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateKsKontierungstabelleMutation, CreateKsKontierungstabelleMutationVariables>(
    CreateKsKontierungstabelleDocument,
    options
  );
}
export type CreateKsKontierungstabelleMutationHookResult = ReturnType<typeof useCreateKsKontierungstabelleMutation>;
export type CreateKsKontierungstabelleMutationResult = Apollo.MutationResult<CreateKsKontierungstabelleMutation>;
export type CreateKsKontierungstabelleMutationOptions = Apollo.BaseMutationOptions<
  CreateKsKontierungstabelleMutation,
  CreateKsKontierungstabelleMutationVariables
>;
export const CreateFdKontierungstabelleDocument = gql`
  mutation CreateFDKontierungstabelle($firmendatenId: ID!, $input: KontierungstabelleCreateInput!) {
    createFDKontierungstabelle(firmendatenId: $firmendatenId, input: $input) {
      data {
        ...KontierungstabelleFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KontierungstabelleFieldsFragmentDoc}
`;
export function useCreateFdKontierungstabelleMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFdKontierungstabelleMutation, CreateFdKontierungstabelleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFdKontierungstabelleMutation, CreateFdKontierungstabelleMutationVariables>(
    CreateFdKontierungstabelleDocument,
    options
  );
}
export type CreateFdKontierungstabelleMutationHookResult = ReturnType<typeof useCreateFdKontierungstabelleMutation>;
export type CreateFdKontierungstabelleMutationResult = Apollo.MutationResult<CreateFdKontierungstabelleMutation>;
export type CreateFdKontierungstabelleMutationOptions = Apollo.BaseMutationOptions<
  CreateFdKontierungstabelleMutation,
  CreateFdKontierungstabelleMutationVariables
>;
export const UpdateKontierungstabelleDocument = gql`
  mutation UpdateKontierungstabelle($kontierungstabelleId: ID!, $input: KontierungstabelleUpdateInput!) {
    updateKontierungstabelle(kontierungstabelleId: $kontierungstabelleId, input: $input) {
      data {
        ...KontierungstabelleFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KontierungstabelleFieldsFragmentDoc}
`;
export function useUpdateKontierungstabelleMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateKontierungstabelleMutation, UpdateKontierungstabelleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateKontierungstabelleMutation, UpdateKontierungstabelleMutationVariables>(UpdateKontierungstabelleDocument, options);
}
export type UpdateKontierungstabelleMutationHookResult = ReturnType<typeof useUpdateKontierungstabelleMutation>;
export type UpdateKontierungstabelleMutationResult = Apollo.MutationResult<UpdateKontierungstabelleMutation>;
export type UpdateKontierungstabelleMutationOptions = Apollo.BaseMutationOptions<
  UpdateKontierungstabelleMutation,
  UpdateKontierungstabelleMutationVariables
>;
export const DeleteKontierungstabelleDocument = gql`
  mutation DeleteKontierungstabelle($kontierungstabelleId: ID!) {
    deleteKontierungstabelle(kontierungstabelleId: $kontierungstabelleId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteKontierungstabelleMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteKontierungstabelleMutation, DeleteKontierungstabelleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteKontierungstabelleMutation, DeleteKontierungstabelleMutationVariables>(DeleteKontierungstabelleDocument, options);
}
export type DeleteKontierungstabelleMutationHookResult = ReturnType<typeof useDeleteKontierungstabelleMutation>;
export type DeleteKontierungstabelleMutationResult = Apollo.MutationResult<DeleteKontierungstabelleMutation>;
export type DeleteKontierungstabelleMutationOptions = Apollo.BaseMutationOptions<
  DeleteKontierungstabelleMutation,
  DeleteKontierungstabelleMutationVariables
>;
export const ArchiveKontierungstabelleDocument = gql`
  mutation ArchiveKontierungstabelle($kontierungstabelleId: ID!) {
    actionArchiveKontierungstabelle(kontierungstabelleId: $kontierungstabelleId) {
      data {
        ...KontierungstabelleFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KontierungstabelleFieldsFragmentDoc}
`;
export function useArchiveKontierungstabelleMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveKontierungstabelleMutation, ArchiveKontierungstabelleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveKontierungstabelleMutation, ArchiveKontierungstabelleMutationVariables>(
    ArchiveKontierungstabelleDocument,
    options
  );
}
export type ArchiveKontierungstabelleMutationHookResult = ReturnType<typeof useArchiveKontierungstabelleMutation>;
export type ArchiveKontierungstabelleMutationResult = Apollo.MutationResult<ArchiveKontierungstabelleMutation>;
export type ArchiveKontierungstabelleMutationOptions = Apollo.BaseMutationOptions<
  ArchiveKontierungstabelleMutation,
  ArchiveKontierungstabelleMutationVariables
>;
export const UnarchiveKontierungstabelleDocument = gql`
  mutation UnarchiveKontierungstabelle($kontierungstabelleId: ID!) {
    actionUnarchiveKontierungstabelle(kontierungstabelleId: $kontierungstabelleId) {
      data {
        ...KontierungstabelleFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${KontierungstabelleFieldsFragmentDoc}
`;
export function useUnarchiveKontierungstabelleMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveKontierungstabelleMutation, UnarchiveKontierungstabelleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveKontierungstabelleMutation, UnarchiveKontierungstabelleMutationVariables>(
    UnarchiveKontierungstabelleDocument,
    options
  );
}
export type UnarchiveKontierungstabelleMutationHookResult = ReturnType<typeof useUnarchiveKontierungstabelleMutation>;
export type UnarchiveKontierungstabelleMutationResult = Apollo.MutationResult<UnarchiveKontierungstabelleMutation>;
export type UnarchiveKontierungstabelleMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveKontierungstabelleMutation,
  UnarchiveKontierungstabelleMutationVariables
>;
