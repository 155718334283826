import React, { FC } from 'react';
import rechnungsAusstellerKontoTableColumns from './rechnungsAusstellerKontoTableColumns';
import { BankDetails, Firma, NatuerlichePerson, Personengemeinschaft } from '../../../../../../types';
import { RechnungsAusstellerFieldsFragment } from '../../gql/RechnungsAusstellerFragments.types';
import NestedTable from '../../../../../../components/Table/NestedTable/NestedTable';

type Props = {
  rechnungsAussteller: RechnungsAusstellerFieldsFragment;
  rechtstraeger: Firma | NatuerlichePerson | Personengemeinschaft;
  onSuccess: () => void;
};

const RechnungsAusstellerKontoTable: FC<Props> = ({ rechnungsAussteller, rechtstraeger, onSuccess }) => (
  <NestedTable<BankDetails>
    expandable={{ rowExpandable: () => false }}
    pagination={false}
    dataSource={rechnungsAussteller.bankDetailsList}
    columns={rechnungsAusstellerKontoTableColumns(rechtstraeger, onSuccess)}
    rowKey={(record) => record.bankDetailsId}
  />
);

export default RechnungsAusstellerKontoTable;
