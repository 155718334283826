import React, { FC } from 'react';
import { FieldArrayRenderProps, FormikProps } from 'formik';
import { v4 as uuid4 } from 'uuid';
import { Empty, Table } from 'antd';
import { AbrDefAbrKreisFormValues, AbrDefAbrKreisListingFormValues } from './abrDefAbrKreisListingFormMapper';
import { FormikFieldHookProps } from '../../../../helpers/formikHelper';
import { AbrechnungsdefinitionType } from '../../../../types';
import { isAbrDefTypeHeAbrechnung, isAbrDefTypeSubAbrechnung } from './abrDefAbrKreisListingFormHelpers';
import heAndSubAbrDefAbrKreisTableColumns from './heAndSubAbrDefAbrKreisTableColumns';
import weAndBkAbrDefAbrKreisTableColumns from './weAndBkAbrDefAbrKreisTableColumns';

type Props = {
  abrDefType: AbrechnungsdefinitionType;
  formikProps: FormikProps<AbrDefAbrKreisListingFormValues>;
  arrayHelpers: FieldArrayRenderProps;
  firmendatenId: string;
  onAbrechnungskreisChange?: (newAbrechnungskreisId: string, fieldProps: FormikFieldHookProps<string>, onSuccess: () => void) => void;
  onZuweisungDelete?: (abrechnungskreisId: string, onDelete: () => void) => void;
};

const AbrDefAbrKreisTable: FC<Props> = ({ abrDefType, arrayHelpers, formikProps, firmendatenId, onAbrechnungskreisChange, onZuweisungDelete }) => {
  const columns =
    isAbrDefTypeHeAbrechnung(abrDefType) || isAbrDefTypeSubAbrechnung(abrDefType)
      ? heAndSubAbrDefAbrKreisTableColumns(abrDefType, formikProps, firmendatenId, arrayHelpers, onAbrechnungskreisChange, onZuweisungDelete)
      : weAndBkAbrDefAbrKreisTableColumns(abrDefType, formikProps, firmendatenId, arrayHelpers, onAbrechnungskreisChange, onZuweisungDelete);

  return (
    <Table<AbrDefAbrKreisFormValues>
      locale={{
        emptyText: <Empty description={<span>Fügen Sie bitte mindestens eine Abrechnungskreiskonfiguration hinzu</span>} />,
      }}
      style={{ width: '100%' }}
      dataSource={formikProps.values.abrechdefAbrechkreisList}
      //name={abrDefAbrKreisListingFormFields.abrechdefAbrechkreisList}
      rowKey={() => uuid4()}
      size="small"
      pagination={false}
      columns={columns}
    />
  );
};

export default AbrDefAbrKreisTable;
