import { Modal } from 'antd';
import React, { FC } from 'react';
import AccountLimitForm from './Form/AccountLimitForm';

type Props = {
  isAccountLimitModalOpen: boolean;
  bankDetailsId: string;
  onCancel: () => void;
  onSuccess: () => void;
  firmendatenId?: string;
  rechtstraegerId?: string;
  accountLimit?: number | null;
  fibuKontonummer?: string | null;
};

const AccountLimitFormModal: FC<Props> = ({
  bankDetailsId,
  accountLimit,
  rechtstraegerId,
  firmendatenId,
  fibuKontonummer,
  isAccountLimitModalOpen,
  onCancel,
  onSuccess,
}) => {
  return (
    <Modal
      title={fibuKontonummer ? `Überziehungsrahmen ändern für ${fibuKontonummer}` : 'Überziehungsrahmen ändern'}
      onCancel={onCancel}
      open={isAccountLimitModalOpen}
      destroyOnClose
      maskClosable={false}
      footer={false}
    >
      <AccountLimitForm
        onCancel={onCancel}
        onSuccess={onSuccess}
        bankDetailsId={bankDetailsId}
        firmendatenId={firmendatenId}
        rechtstraegerId={rechtstraegerId}
        accountLimit={accountLimit}
      />
    </Modal>
  );
};

export default AccountLimitFormModal;
