import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../features/Breadcrumb';
import { ContentMain } from '../../../components/LayoutAndromeda/Styled/Layout.style';
import { StyledContentTab } from '../../../components/Card/Styled/Card.style';
import IncomingInvoicePreviewSaved from '../../../features/IncomingInvoice/shared/IncomingInvoicePreviewSaved';
import IncomingInvoiceDetails from '../../../features/IncomingInvoice/IncomingInvoiceDetails/IncomingInvoiceDetails';
import { useSingleInvoiceForDetailsAndUpdatePageQuery } from '../../../features/IncomingInvoice/gql/SingleInvoiceQueries.types';
import { IncomingInvoiceStatus } from '../../../types';
import { useSingleInvoiceFreigebenMutation } from '../../../features/IncomingInvoice/gql/SingleInvoiceMutations.types';
import { showSuccessMsgFreigegeben } from '../../../components/message/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { generatePathToIncomingInvoiceListingPage } from '../../../features/IncomingInvoice/Listing/Filters/filtersQueryParams';
import { StyledSpaceInvoicePage } from '../../../shared/Invoice/styled/StyledInvoicePage.styled';

type RouteParams = { fileId: string; belegId: string };

const SingleInvoiceDetailsPage = () => {
  const navigate = useNavigate();

  const { fileId, belegId } = useParams() as RouteParams;

  const { data, startPolling, stopPolling } = useSingleInvoiceForDetailsAndUpdatePageQuery({ variables: { belegId } });
  const rechnung = data?.getSingleInvoiceForDetailsAndUpdatePage.data;

  if (rechnung?.status.value === IncomingInvoiceStatus.Freigegeben) {
    startPolling(500);
  }

  if (rechnung?.status.value && [IncomingInvoiceStatus.Uebertragen, IncomingInvoiceStatus.UebertragenFehler].includes(rechnung.status.value)) {
    stopPolling();
  }

  const [singleInvoiceFreigeben] = useSingleInvoiceFreigebenMutation({
    variables: { belegId },
    onCompleted: (data) => {
      const singleInvoice = data.actionSingleInvoiceFreigeben.data;
      showSuccessMsgFreigegeben('Eingangsrechnung');
      navigate(
        generatePathToIncomingInvoiceListingPage({
          invoiceNumber: singleInvoice.rechnungsNummer,
          issuerRechtstraegerId: singleInvoice.ausstellerRechtstraegerId,
          payeeRechtstraegerId: singleInvoice.payeeRechtstraegerId,
          objektId: singleInvoice.objektId,
        })
      );
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <>
      <Breadcrumbs />
      <ContentMain style={{ borderRadius: '4px' }}>
        <StyledContentTab>
          <StyledSpaceInvoicePage>
            <IncomingInvoicePreviewSaved fileId={fileId} />
            <IncomingInvoiceDetails belegId={belegId} actionFreigeben={singleInvoiceFreigeben} incomingInvoice={rechnung} />
          </StyledSpaceInvoicePage>
        </StyledContentTab>
      </ContentMain>
    </>
  );
};

export default SingleInvoiceDetailsPage;
