import React, { useEffect, useState } from 'react';
import { Select } from 'formik-antd';
import { Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import theme from '../../theme';
import { CREATE_NEW_ITEM_VALUE } from '../../helpers/selectHelper';

export const useCreateNewItemInSelect = (list?: unknown[], onNewItemCreateSelected?: () => void, newItemText?: string, valueForSelect?: string) => {
  const createNewItemValue = valueForSelect || CREATE_NEW_ITEM_VALUE;
  const isCreateNewItemEnabled = !!(onNewItemCreateSelected && newItemText);
  const [showCreateNewItem, setShowCreateNewItem] = useState(false);
  const isShortList = !!list && list.length < 10;

  useEffect(() => {
    if (isShortList && isCreateNewItemEnabled) {
      setShowCreateNewItem(true);
    } else {
      setShowCreateNewItem(false);
    }
  }, [isShortList, isCreateNewItemEnabled]);

  const handleSelect = isCreateNewItemEnabled
    ? (value: string) => {
        if (value === createNewItemValue) {
          onNewItemCreateSelected();
        }
      }
    : undefined;

  const handleSearch = isCreateNewItemEnabled
    ? (searchString: string) => {
        if ((list && searchString.length >= 3) || isShortList) {
          setShowCreateNewItem(true);
        } else {
          setShowCreateNewItem(false);
        }
      }
    : undefined;

  const renderCreateNewItem = () => {
    return showCreateNewItem ? (
      <Select.Option style={{ backgroundColor: theme.linkColor.default, color: theme.colors.white }} value={createNewItemValue}>
        <Space>
          <span>{newItemText}</span>
          <PlusOutlined />
        </Space>
      </Select.Option>
    ) : null;
  };

  return { handleSelect, handleSearch, renderCreateNewItem };
};
