import * as Yup from 'yup';
import { vstKrzFreigebenModalFormFields } from './vstKrzFreigbenModalFormMapper';
import { entityIsRequired } from '../../../../components/message/validationMsg';

/* eslint-disable no-template-curly-in-string */

export const vstKrzFreigbenModalFormValidationSchema = Yup.object().shape({
  [vstKrzFreigebenModalFormFields.buchungsdatum]: Yup.date().required(entityIsRequired('Buchungsdatum')),
});

/* eslint-enable no-template-curly-in-string */
