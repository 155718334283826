import React from 'react';
import { Tooltip } from 'antd';
import { TableWithAlignedColsColumnType } from '../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { EuroAmount } from '../../../components/Number';
import KontoWithQuery from '../../../shared/components/Konto/KontoWithQuery';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';
import { VorsteuerkuerzungAufwandKonto } from '../../../types';

const aufwandsKontoRowColumns = (
  objektId: string,
  vonInklusive: string,
  bisInklusive: string
): TableWithAlignedColsColumnType<VorsteuerkuerzungAufwandKonto>[] => [
  {
    title: '',
    align: 'left',
    render: (konto) => {
      return <KontoWithQuery kontoId={konto.aufwandsKontoId} objektId={objektId} buchungsdatumFrom={vonInklusive} buchungsdatumTo={bisInklusive} />;
    },
  },
  {
    title: '',
    render: () => <TextForEmptyValue textToShow="none" />,
  },
  {
    title: '',
    render: () => <TextForEmptyValue textToShow="none" />,
  },
  {
    title: '',
    colSpan: 3,
    render: (konto) => <EuroAmount value={konto.sumSteuer} />,
  },
  {
    title: '',
    render: () => <TextForEmptyValue textToShow="none" />,
  },

  {
    title: '',
    colSpan: 2,
    render: (konto) => (
      <Tooltip title={<EuroAmount value={konto.sumVstKuerzung} minimumFractionDigits={4} maximumFractionDigits={4} />}>
        <>
          <EuroAmount value={konto.sumVstKuerzung} minimumFractionDigits={2} maximumFractionDigits={2} />
        </>
      </Tooltip>
    ),
  },
];

export default aufwandsKontoRowColumns;
