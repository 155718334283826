import React, { FC, useEffect } from 'react';
import ObjektAbrechnungTable from './ObjektAbrechnungTable';
import AbrErstellenBtnAndModal from '../../shared/AbrErstellenBtnAndModal';
import { useAbrechnungListQuery } from '../../gql/AbrechnungQueries.types';
import { AbrechnungType, NotificationStatus, NotificationType, Objekt } from '../../../../types';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../hooks/useQueryWithPagingAndSorting';
import { useGetNotificationSubscription } from '../../../Notification/gql/NotificationSubscription.types';
import { useAuth } from '../../../../shared/Auth/authContext';
import { useOnObjAbrDataChangeEvent } from '../useOnObjAbrDataChangeEvents';

type Props = {
  objekt: Objekt;
  isRefetchingObjekt: boolean;
};

const WeObjektAbrechnungListing: FC<Props> = ({ objekt, isRefetchingObjekt }) => {
  const { user, mitarbeiter } = useAuth();
  const username = user?.username;
  const mitarbeiterId = mitarbeiter?.mitarbeiterId;

  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, refetch, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useAbrechnungListQuery,
    {
      variables: {
        objektIdList: [objekt.objektId],
        typeList: [AbrechnungType.WeAbrechnung, AbrechnungType.BkAbrechnung, AbrechnungType.SubAbrechnung],
      },
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  useGetNotificationSubscription({
    onData: (options) => {
      const notification = options.data.data?.getNotification;

      if (!notification) return;
      const { notificationType, status } = notification;
      if (notificationType !== NotificationType.WeObjektAbrechnungErstellung && status !== NotificationStatus.Finished) return;
      refetch();
    },
    variables: {
      userId: username,
      mitarbeiterId,
    },
  });

  const abrechnungList = data?.getAbrechnungList.data.contentList;

  useOnObjAbrDataChangeEvent('weObjektAbrechnungListing', refetch, objekt.objektId);

  useEffect(() => {
    if (isRefetchingObjekt) refetch();
  }, [isRefetchingObjekt, refetch]);

  return (
    <>
      <AbrErstellenBtnAndModal objekt={objekt} refetch={refetch} />
      <ObjektAbrechnungTable
        abrechnungList={abrechnungList}
        objekt={objekt}
        isRefetchingObjekt={isRefetchingObjekt}
        refetch={refetch}
        pagination={pagination}
        handleTableSorting={handleTableSorting}
      />
    </>
  );
};

export default WeObjektAbrechnungListing;
