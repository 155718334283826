import { FC } from 'react';
import { useBestandseinheitAufteilungsschluesselWertChangeHistoryListQuery } from '../../gql/BestandseinheitAufteilungsschluesselQueries.types';
import { HistoryType } from '../../../../History/shared/HistoryModal';
import HistoryListingTable from '../../../../History/HistoryListingTable';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  aufteilungsschluesselId: string;
  beAufteilungsschluesselWertId: string;
};

const BeAufteilungsschluesselWerteChangeHistoryListingTable: FC<Props> = ({
  objektId,
  bestandseinheitId,
  aufteilungsschluesselId,
  beAufteilungsschluesselWertId,
}) => {
  const { data, loading } = useBestandseinheitAufteilungsschluesselWertChangeHistoryListQuery({
    variables: { objektId, bestandseinheitId, aufteilungsschluesselId, beAufteilungsschluesselWertId },
  });
  const historyList = data?.getBeAufteilungsschluesselWertChangeHistoryList.data;

  return (
    <HistoryListingTable
      historyType={HistoryType.Change}
      historyList={historyList}
      loading={loading}
      filterIdPrefix="be-aufteilungsschluessel-werte"
    />
  );
};

export default BeAufteilungsschluesselWerteChangeHistoryListingTable;
