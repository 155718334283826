import React from 'react';
import { Space, Spin } from 'antd';
import TabPaneContent from '../../Tabs/TabPaneContent';

const LoadingDeviationTab = () => (
  <TabPaneContent distanceToTopBorder="large">
    <Space style={{ width: '100%', justifyContent: 'center' }}>
      <Spin />
    </Space>
  </TabPaneContent>
);

export default LoadingDeviationTab;
