import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { columnWidthsAndAlignment } from '../HeADetailsBlock/DetailsTable/details-table-styles';
import { LabeledValue } from '../../../shared/types';
import LabelValue from '../../../shared/LabelValue';

const HeABlockSumme: FC<{ data: LabeledValue }> = ({ data }) => (
  <LabelValue
    valueTuple={data}
    viewStyle={{ ...pdfStyles.borderBottomSmall, paddingTop: '3mm', borderBottomWidth: 2 }}
    labelStyle={{
      width: columnWidthsAndAlignment.positionText.width + columnWidthsAndAlignment.objektsummeText.width,
      textAlign: columnWidthsAndAlignment.positionText.textAlign,
      fontWeight: 'bold',
    }}
    valueStyle={{
      width: columnWidthsAndAlignment.anteilText.width,
      textAlign: columnWidthsAndAlignment.anteilText.textAlign,
      fontWeight: 'bold',
    }}
  />
);

export default HeABlockSumme;
