import { Card, Space } from 'antd';
import React, { FC } from 'react';
import { BankOutlined } from '@ant-design/icons';
import { useToggle } from '../../../hooks/useToggle';
import { BankDetails, FirmendatenBankDetails } from '../../../types';
import BankDetailsCardContent from './BankDetailsCardContent';
import BankDetailsUpdateDrawer from '../Drawer/BankDetailsUpdateDrawer';
import BankDetailsCardMenuDropdown from './BankDetailsCardMenuDropdown';
import RechtstraegerBankDetailsHistoryModals from './RechtstraegerBankDetailsHistoryModals';
import FirmendatenBankDetailsHistoryModals from './FirmendatenBankDetailsHistoryModals';
import DataWithStatus from '../../../components/Helpers/DataWithStatus';

type Props = {
  bankDetails: BankDetails | FirmendatenBankDetails;
  onAction: () => Record<string, unknown>;
  rechtstraegerId?: string;
  firmendatenId?: string;
};

const BankDetailsCard: FC<Props> = ({ rechtstraegerId, firmendatenId, bankDetails, onAction }) => {
  const [isBankDetailsUpdateDrawerOpen, toggleBankDetailsUpdateDrawerOpen] = useToggle();
  const [isBankDetailsChangeHistoryOpen, toggleBankDetailsChangeHistoryOpen] = useToggle();
  const [isSepaMandatChangeHistoryOpen, toggleSepaMandatChangeHistoryOpen] = useToggle();

  return (
    <>
      <Card
        size="small"
        extra={
          <BankDetailsCardMenuDropdown
            bankDetails={bankDetails}
            onAction={onAction}
            rechtstraegerId={rechtstraegerId}
            firmendatenId={firmendatenId}
            toggleBankDetailsUpdateDrawer={toggleBankDetailsUpdateDrawerOpen}
            toggleBankDetailsChangeHistoryOpen={toggleBankDetailsChangeHistoryOpen}
            toggleSepaMandatChangeHistoryOpen={toggleSepaMandatChangeHistoryOpen}
          />
        }
        title={
          <Space>
            <BankOutlined />
            <DataWithStatus status={bankDetails.status} text={bankDetails.bankname} maxTextLength={35} />
          </Space>
        }
      >
        <BankDetailsCardContent bankDetails={bankDetails} />
      </Card>
      <BankDetailsUpdateDrawer
        isCollapsed={isBankDetailsUpdateDrawerOpen}
        onCollapse={toggleBankDetailsUpdateDrawerOpen}
        onActionSuccess={onAction}
        bankDetails={bankDetails}
        rechtstraegerId={rechtstraegerId}
        firmendatenId={firmendatenId}
      />

      {/* Modals with ChangeHistoryListingTables */}
      {rechtstraegerId && (
        <RechtstraegerBankDetailsHistoryModals
          bankDetailsId={bankDetails.bankDetailsId}
          isBankDetailsChangeHistoryOpen={isBankDetailsChangeHistoryOpen}
          isSepaMandatChangeHistoryOpen={isSepaMandatChangeHistoryOpen}
          toggleBankDetailsChangeHistoryOpen={toggleBankDetailsChangeHistoryOpen}
          toggleSepaMandatChangeHistoryOpen={toggleSepaMandatChangeHistoryOpen}
          rechtstraegerId={rechtstraegerId}
        />
      )}

      {firmendatenId && (
        <FirmendatenBankDetailsHistoryModals
          bankDetailsId={bankDetails.bankDetailsId}
          isBankDetailsChangeHistoryOpen={isBankDetailsChangeHistoryOpen}
          isSepaMandatChangeHistoryOpen={isSepaMandatChangeHistoryOpen}
          toggleBankDetailsChangeHistoryOpen={toggleBankDetailsChangeHistoryOpen}
          toggleSepaMandatChangeHistoryOpen={toggleSepaMandatChangeHistoryOpen}
          firmendatenId={firmendatenId}
        />
      )}
    </>
  );
};

export default BankDetailsCard;
