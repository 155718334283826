import React from 'react';
import { Space, Tag, Typography } from 'antd';
import { MitarbeiterTooltip } from '../../../components/Card';
import AbrDefTableActions from './AbrDefTableActions';
import StatusBadge from '../../../components/Status/StatusBadge';
import { archivedOrNormalStyle } from '../../../helpers/statusHelper';
import DataWithStatus from '../../../components/Helpers/DataWithStatus';
import TemplateIncompleteWarning from '../shared/TemplateIncompleteWarning';
import { AbrechnungsdefinitionListEntry } from '../../../types';
import { NestedTableColProps } from '../../../components/Table/NestedTable/NestedTable';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';
import FirmendatenKurzBezeichnung from '../../shared/FirmendatenKurzBezeichnung';
import TextForEmptyValue from '../../../components/Text/TextForEmptyValue';

const abrDefTableColumns = (onActionSuccess: () => void): NestedTableColProps<AbrechnungsdefinitionListEntry>[] => [
  {
    title: 'Bezeichnung',
    render: (text, record) => (
      <Space>
        {record.status ? (
          <DataWithStatus status={record.status} text={record.bezeichnung} showBadgeDot={false} showStatusDescription={false} />
        ) : (
          <Typography.Text>{record.bezeichnung}</Typography.Text>
        )}
        <TemplateIncompleteWarning textBausteineBefuellt={record.textBausteineBefuellt} />
      </Space>
    ),
  },
  {
    title: 'Abrechnungstyp',
    render: (text, record) => <Tag style={record.status ? archivedOrNormalStyle(record.status) : {}}>{record.type.text}</Tag>,
  },
  {
    title: 'Firma',
    render: (text, record) =>
      record.firmendatenId ? (
        <FirmendatenKurzBezeichnung firmendatenId={record.firmendatenId} />
      ) : (
        <Tag style={record.status ? archivedOrNormalStyle(record.status) : {}}>System</Tag>
      ),
  },
  {
    title: 'Status',
    render: (text, record) => (record.status ? <StatusBadge entityStatus={record.status} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Erstellt am',
    render: (text, record) =>
      record.status ? (
        <DataWithStatus status={record.status} text="" showBadgeDot={false} showStatusDescription={false}>
          {() => <FormattedDateTime createdTs={record.createTs} />}
        </DataWithStatus>
      ) : (
        <FormattedDateTime createdTs={record.createTs} />
      ),
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    render: (text, record) => <AbrDefTableActions abrechnungsdefinition={record} onActionSuccess={onActionSuccess} />,
  },
];

export default abrDefTableColumns;
