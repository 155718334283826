import styled from 'styled-components';
import theme from '../../../theme';

const styledError = styled.span<{ warnings: boolean }>`
  color: ${(props) => (props.warnings ? `${theme.textColor.warning}` : `${theme.textColor.danger}`)};
  font-size: ${theme.fontSizes.ToggleText};
  font-family: ${theme.fonts.sans};
  font-weight: ${theme.fontWeights.normal};
  margin-top: 10px;
`;

export default styledError;
