import React from 'react';

import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { compareTwoStringsForSorting } from '../../../../helpers/stringHelper';
import GenerierlaufEntryStatus from '../../shared/GenerierlaufEntryStatus';
import { generatePathToWeAbrechnungDetailsPage } from '../../../Abrechnung/BkOrWe/weAbrechnungUriPaths';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import StatusBadge from '../../../../components/Status/StatusBadge';
import { ObjektAbrechnungGenerierlaufEntry, ObjektAbrechnungGenerierlaufOrderBy } from '../../../../types';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

const objAbrVerarbeitungEntryTableColumns = (
  fromInclusive: string,
  toInclusive: string
): TableWithColSelectorColumnProps<ObjektAbrechnungGenerierlaufEntry>[] => [
  {
    title: 'Status',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.exitCode?.text, b.exitCode?.text),
    render: (_, record) => <GenerierlaufEntryStatus generierlaufEntry={record} />,
  },
  {
    title: 'Objekt',
    defaultSelected: true,
    dataIndex: ObjektAbrechnungGenerierlaufOrderBy.Objekt,
    sorter: true,
    render: (text, record) => <DataWithShortenedText maxTextLength={20} text={record.objektKurzBezeichnung} />,
  },

  {
    title: 'Abrechnungstyp',
    defaultSelected: true,
    render: (text, record) =>
      record.objektAbrechnung ? (
        <Typography.Text>{record.objektAbrechnung?.abrechnungsdefinitionType.text}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Zeitraum',
    defaultSelected: true,
    //sorter: (a, b) => compareTwoDatesForSorting(a.objektAbrechnung?.vonInklusive, b.objektAbrechnung?.vonInklusive),
    render: () => (
      <>
        <CustomFormattedDate value={fromInclusive} /> – <CustomFormattedDate value={toInclusive} />
      </>
    ),
  },
  {
    title: 'Status',
    defaultSelected: true,
    dataIndex: ObjektAbrechnungGenerierlaufOrderBy.Status,
    sorter: true,
    render: (text, record) =>
      record.objektAbrechnung ? <StatusBadge entityStatus={record.objektAbrechnung.status} /> : <TextForEmptyValue textToShow="NV" />,
  },
  {
    title: 'Erstellt am',
    render: (text, record) =>
      record.objektAbrechnung ? <FormattedDateTime createdTs={record.objektAbrechnung.createTs} /> : <TextForEmptyValue textToShow="NV" />,
  },
  {
    title: 'Ersteller',
    render: (text, record) =>
      record.objektAbrechnung ? (
        <MitarbeiterTooltip
          mitarbeiterId={record.objektAbrechnung.createdByMitarbeiterId}
          userId={record.objektAbrechnung.createdBy}
          alignment="left"
          showsInitials
        />
      ) : (
        <TextForEmptyValue textToShow="NV" />
      ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) =>
      record.objektAbrechnung ? (
        <Link
          to={generatePathToWeAbrechnungDetailsPage(record.objektAbrechnung.objekt.objektId, record.objektAbrechnung.objektAbrechnungId)}
          target="_blank"
        >
          Details
        </Link>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
];

export default objAbrVerarbeitungEntryTableColumns;
