import React, { FC } from 'react';
import { Box } from 'rebass';
import { Divider, Space } from 'antd';
import VPosForVertragLevelSummary from './VPosForVertragLevelSummary';
import VPosForVertragLevelDetails from './VPosForVertragLevelDetails';
import { TVPosForVertragLevel } from './vPosForVertragLevelList.model';

type Props = {
  vorschreibungspositionList: TVPosForVertragLevel[];
};

const VPosForVertragLevelList: FC<Props> = ({ vorschreibungspositionList }) => {
  return (
    <>
      {vorschreibungspositionList.map((vPos, index) => {
        return (
          <Box key={index} paddingLeft="8px" paddingRight="40px" backgroundColor="#fff">
            <Space direction="vertical" style={{ width: '100%', paddingTop: '8px' }}>
              <VPosForVertragLevelSummary bezeichnung={vPos.bezeichnung} sumBrutto={vPos.sumBrutto} sumNetto={vPos.sumNetto} />
              <VPosForVertragLevelDetails ustColList={vPos.ustColList} />
            </Space>
            <Divider style={{ marginTop: '8px', marginBottom: '0px' }} />
          </Box>
        );
      })}
    </>
  );
};

export default VPosForVertragLevelList;
