import React from 'react';
import { Space, TableColumnProps, Typography } from 'antd';
import { FormattedNumber } from 'react-intl';
import { IndexSeries, IndexSeriesEntry } from '../../../../../types';
import IndexSeriesDetailsTableActions from './IndexSeriesDetailsTableActions';
import { createAllMonthsForIndexSeriesEntry, isIndexSeriesSourceTypeImport } from '../../../indexSeriesHelpers';
import TextForEmptyValue from '../../../../../components/Text/TextForEmptyValue';

const indexSeriesDetailsTableColumns = (
  indexSeries: IndexSeries,
  onAction: () => void,
  showPreliminary: boolean,
  isAndromedaAdmin: boolean,
  firmendatenId?: string | null
): TableColumnProps<IndexSeriesEntry>[] => {
  const columns = [
    {
      title: 'Jahr',
      render: (text: string, record: IndexSeriesEntry) => (
        <Space direction="vertical" size={0}>
          <Typography.Text>{record.year}</Typography.Text>
          {showPreliminary && isIndexSeriesSourceTypeImport(indexSeries.sourceType.value) && (
            <Typography.Text>{record.year} Vorläufig</Typography.Text>
          )}
        </Space>
      ),
    },
    ...createAllMonthsForIndexSeriesEntry(indexSeries.sourceType.value, showPreliminary),
    {
      title: 'Aktion',
      render: (text: string, record: IndexSeriesEntry) => (
        <IndexSeriesDetailsTableActions
          indexSeries={indexSeries}
          indexSeriesEntry={record}
          onAction={onAction}
          firmendatenId={firmendatenId}
          isAndromedaAdmin={isAndromedaAdmin}
        />
      ),
    },
  ];

  if (isIndexSeriesSourceTypeImport(indexSeries.sourceType.value)) {
    columns.splice(columns.length - 1, 0, {
      title: 'Jahresdurchschnitt',
      render: (text, record) => (
        <Space direction="vertical" size={0}>
          {record.annualAverageFinal ? (
            <FormattedNumber value={record.annualAverageFinal} minimumFractionDigits={2} />
          ) : (
            <TextForEmptyValue textToShow="minus" />
          )}
          {getPreliminaryValue(showPreliminary, record.annualAveragePreliminary)}
        </Space>
      ),
    });
  }

  return columns;
};

const getPreliminaryValue = (showPreliminary: boolean, annualAveragePreliminary?: number | null) => {
  if (showPreliminary) {
    return annualAveragePreliminary ? (
      <FormattedNumber value={annualAveragePreliminary} minimumFractionDigits={2} />
    ) : (
      <TextForEmptyValue textToShow="minus" />
    );
  }
  return null;
};
export default indexSeriesDetailsTableColumns;
