import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Col, Row, Typography } from 'antd';
import { IndexSeries, IndexSeriesSourceType } from '../../../types';
import { indexSeriesFormFields, IndexSeriesFormValues, mapFormValuesToIndexSeries, mapIndexSeriesToFormValues } from './indexSeriesFormMapper';
import FormButtons from '../../../components/Button/FormButtons';
import IndexTypeSelect from '../shared/IndexTypeSelect';
import SourceTypeSelect from '../shared/SourceTypeSelect';
import { Spacer } from '../../../components/Grid';
import FormattedDecimalFormInput from '../../../components/Input-antd/FormattedDecimalFormInput';
import { indexSeriesValidationSchema } from './indexSeriesValidationSchema';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { useCreateIndexSeriesMutation, useUpdateIndexSeriesMutation } from '../gql/IndexSeriesMutations.types';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';

type Props = {
  indexSeries?: IndexSeries;
  onAction: () => void;
  onCancel: () => void;
};

const IndexSeriesForm: FC<Props> = ({ indexSeries, onAction, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'IndexSeries'>('IndexSeries');

  const isUpdate = !!indexSeries;
  const entity = 'Indexreihe';

  const [runCreate, { loading: loadingCreate }] = useCreateIndexSeriesMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdateIndexSeriesMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<IndexSeriesFormValues>
      initialValues={mapIndexSeriesToFormValues(indexSeries)}
      onSubmit={(values, { setSubmitting }) => {
        const input = mapFormValuesToIndexSeries(values);
        if (isUpdate) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          runUpdate({ variables: { indexSeriesId: indexSeries!.indexSeriesId, input } }).finally(() => setSubmitting(false));
        } else {
          runCreate({ variables: { input } }).finally(() => setSubmitting(false));
        }
      }}
      validationSchema={indexSeriesValidationSchema}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Index</Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithFieldHelp name={indexSeriesFormFields.name} label="Bezeichnung" fieldHelp={getFieldHelpText('IndexSeries.name')}>
                <Input size="small" id={indexSeriesFormFields.name} name={indexSeriesFormFields.name} placeholder="z.B VPI 2020" />
              </FormItemWithFieldHelp>
              <FormItemWithFieldHelp name={indexSeriesFormFields.indexType} label="Typ" fieldHelp={getFieldHelpText('IndexSeries.indexType')}>
                <IndexTypeSelect name={indexSeriesFormFields.indexType} />
              </FormItemWithFieldHelp>
              <FormItemWithFieldHelp
                name={indexSeriesFormFields.sourceType}
                label="Datenquelle"
                fieldHelp={getFieldHelpText('IndexSeries.sourceType')}
              >
                <SourceTypeSelect name={indexSeriesFormFields.sourceType} />
              </FormItemWithFieldHelp>
            </Col>
          </Row>

          {formikProps.values.sourceType === IndexSeriesSourceType.Import && (
            <>
              <Spacer height={16} />
              <Row align="top">
                <Col span={6}>
                  <Typography.Title level={5}>Import</Typography.Title>
                </Col>
                <Col span={18}>
                  <FormItemWithFieldHelp
                    name={indexSeriesFormFields.importUrl}
                    label="Import Http-Link"
                    fieldHelp={getFieldHelpText('IndexSeries.importUrl')}
                  >
                    <Input
                      size="small"
                      id={indexSeriesFormFields.importUrl}
                      name={indexSeriesFormFields.importUrl}
                      placeholder="https://data.statistik.gv.at/data/OGD_vpi20_VPI_2020_1.csv"
                    />
                  </FormItemWithFieldHelp>
                  <FormItemWithFieldHelp
                    name={indexSeriesFormFields.csvColumnMonthYear}
                    label="CSV-Spalte Monat/Jahr"
                    fieldHelp={getFieldHelpText('IndexSeries.csvColumnMonthYear')}
                  >
                    <Input
                      size="small"
                      id={indexSeriesFormFields.csvColumnMonthYear}
                      name={indexSeriesFormFields.csvColumnMonthYear}
                      placeholder="C-VPIZR-0"
                    />
                  </FormItemWithFieldHelp>
                  <FormItemWithFieldHelp
                    name={indexSeriesFormFields.csvColumnImportId}
                    label="CSV-Spalte Importidentifikator"
                    fieldHelp={getFieldHelpText('IndexSeries.csvColumnImportId')}
                  >
                    <Input
                      size="small"
                      id={indexSeriesFormFields.csvColumnImportId}
                      name={indexSeriesFormFields.csvColumnImportId}
                      placeholder="C-VPI5NEU-0"
                    />
                  </FormItemWithFieldHelp>
                  <FormItemWithFieldHelp
                    name={indexSeriesFormFields.csvRowImportId}
                    label="CSV-Zeileninhalt Importidentifikator"
                    fieldHelp={getFieldHelpText('IndexSeries.csvRowImportId')}
                  >
                    <Input size="small" id={indexSeriesFormFields.csvRowImportId} name={indexSeriesFormFields.csvRowImportId} placeholder="VPI-0" />
                  </FormItemWithFieldHelp>
                  <FormItemWithFieldHelp
                    name={indexSeriesFormFields.csvColumnIndexPoint}
                    label="CSV-Spalte Index-Wert"
                    fieldHelp={getFieldHelpText('IndexSeries.csvColumnIndexPoint')}
                  >
                    <Input
                      size="small"
                      id={indexSeriesFormFields.csvColumnIndexPoint}
                      name={indexSeriesFormFields.csvColumnIndexPoint}
                      placeholder="F-VPIMZBM"
                    />
                  </FormItemWithFieldHelp>
                  <FormItemWithFieldHelp
                    name={indexSeriesFormFields.chainingFactor}
                    label="Verkettungsfaktor"
                    fieldHelp={getFieldHelpText('IndexSeries.chainingFactor')}
                  >
                    <FormattedDecimalFormInput
                      size="small"
                      id={indexSeriesFormFields.chainingFactor}
                      name={indexSeriesFormFields.chainingFactor}
                      style={{ width: '100%' }}
                      fractionDigits={2}
                      placeholder="1,00"
                    />
                  </FormItemWithFieldHelp>
                </Col>
              </Row>
            </>
          )}
          <FormButtons
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            updateMode={isUpdate}
            isOkLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default IndexSeriesForm;
