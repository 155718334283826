import { ObjektBankDetails, ObjektBankDetailsCreateInput, ObjektBankDetailsUpdateInput, ObjektBankDetailsVerwendung } from '../../../../types';
import { FormFields } from '../../../../helpers/formikHelper';

export type ObjektBankDetailsFormValues = {
  bankDetailsId: string;
  bezeichnung: string;
  verwendungList: ObjektBankDetailsVerwendung[];
  tempRechnungsausstellerType?: ObjBankDetailsRechnungsausstellerType;
};

export enum ObjBankDetailsRechnungsausstellerType {
  HAUSVERWALTUNG = 'hausverwaltung',
  RECHNUNGS_AUSSTELLER = 'rechnungsAussteller',
  WE_RECHNUNGS_AUSSTELLER = 'weRechnungsAussteller',
}

export const objektBankDetailsFormInitialValues: ObjektBankDetailsFormValues = {
  bankDetailsId: '',
  bezeichnung: '',
  verwendungList: [],
};

export const objektBankDetailsFormFields: FormFields<ObjektBankDetailsFormValues> = {
  bankDetailsId: 'bankDetailsId',
  bezeichnung: 'bezeichnung',
  verwendungList: 'verwendungList',
  tempRechnungsausstellerType: 'tempRechnungsausstellerType',
};

export const mapObjektBankDetailsToFormValues = (objektBankDetails: ObjektBankDetails): ObjektBankDetailsFormValues => ({
  bankDetailsId: objektBankDetails.bankDetails.bankDetailsId,
  bezeichnung: objektBankDetails.bezeichnung,
  verwendungList: objektBankDetails.verwendungList.map((list) => list.value) ?? [],
});

export const mapFormValuesToCreateObjektBankDetails = (
  values: ObjektBankDetailsFormValues,
  weRechnungsAusstellerId?: string
): ObjektBankDetailsCreateInput => ({
  bankDetailsId: values.bankDetailsId,
  bezeichnung: values.bezeichnung,
  verwendungList: values.verwendungList,
  weRechnungsAusstellerRechtstraegerId: weRechnungsAusstellerId,
});

export const mapFormValuesToUpdateObjektBankDetails = (values: ObjektBankDetailsFormValues): ObjektBankDetailsUpdateInput => ({
  bankDetailsId: values.bankDetailsId,
  bezeichnung: values.bezeichnung,
  verwendungList: values.verwendungList,
});
