import React from 'react';
import { useParams } from 'react-router-dom';
import { VerarbeitungVorschreibungDetailsPageRouteParams } from '../routes';
import DetailsPageTemplate from '../../../../components/Template/DetailsPageTemplate';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungDetailsPageCard from '../../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import menuListVorschreibungVerbuchen from './menuListVorschreibungVerbuchen';
import { useAuftragVerbuchenGenerierlaufQuery } from '../../../../features/Verarbeitung/gql/AuftragGenerierlauf/AuftragVerbuchenGenerierlaufQueries.types';
import { AuftragVerbuchenGenerierlauf } from '../../../../types';
import { useRestartAuftragVerbuchenGenerierlaufMutation } from '../../../../features/Verarbeitung/gql/AuftragGenerierlauf/AuftragVerbuchenGenerierlaufMutations.types';

const VorschreibungVerbuchenDetailsPage = () => {
  const { generierlaufId } = useParams() as VerarbeitungVorschreibungDetailsPageRouteParams;

  const { data, refetch } = useAuftragVerbuchenGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getAuftragVerbuchenGenerierlauf.data;

  const [runRestart] = useRestartAuftragVerbuchenGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<AuftragVerbuchenGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.AuftraegeVerbuchen}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.AuftraegeVerbuchen}
          onGenerierlaufRestart={runRestart}
        />
      )}
      sidebarMenuList={(generierlauf) => menuListVorschreibungVerbuchen(generierlauf)}
    />
  );
};

export default VorschreibungVerbuchenDetailsPage;
