import React, { FC } from 'react';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { WeAbrechnungsdefinitionVersionListEntry } from '../../../../types';
import { useWeAbrechnungsdefinitionTemplateVersionListQuery } from '../../gql/WeAbrDef/WeAbrDefTemplateVersionQueries.types';
import { useDeleteWeAbrechnungsdefinitionTemplateVersionMutation } from '../../gql/WeAbrDef/WeAbrDefTemplateVersionMutations.types';
import AbrDefTemplateVersionTimeline from './AbrDefTemplateVersionTimeline';

type Props = {
  abrechnungsdefinitionId: string;
};

const AbrDefTemplateWeVersionTimeline: FC<Props> = ({ abrechnungsdefinitionId }) => {
  const { data, loading, refetch } = useWeAbrechnungsdefinitionTemplateVersionListQuery({ variables: { abrechnungsdefinitionId } });
  const versionList = data?.getWeAbrechnungsdefinitionTemplateVersionList.data;

  const [deleteWegAbrDefVersion] = useDeleteWeAbrechnungsdefinitionTemplateVersionMutation({
    onCompleted: () => {
      refetch();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const onDelete = (version: WeAbrechnungsdefinitionVersionListEntry): Promise<unknown> => {
    return deleteWegAbrDefVersion({
      variables: {
        abrechnungsdefinitionId,
        abrechnungsdefinitionVersionId: version.abrechnungsdefinitionVersionId,
      },
    });
  };

  return (
    <AbrDefTemplateVersionTimeline
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      loading={loading}
      versionList={versionList}
      onDelete={onDelete}
    />
  );
};

export default AbrDefTemplateWeVersionTimeline;
