import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { VerarbeitungVorschreibungMailDeliverySendDetailsPageRouteParams } from './routes';
import { useVorschreibungMailDeliverySendGenerierlaufQuery } from '../../../features/Verarbeitung/gql/VorschreibungMailDeliverySendGenerierlauf/VorschreibungMailDeliverySendGenerierlaufQueries.types';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import menuListVerarbeitungMailDeliverySend from './menuListVerarbeitungMailDeliverySend';
import { VorschreibungMailDeliverySendGenerierlauf } from '../../../types';
import { useRestartVorschreibungMailDeliverySendGenerierlaufMutation } from '../../../features/Verarbeitung/gql/VorschreibungMailDeliverySendGenerierlauf/VorschreibungMailDeliverySendGenerierlaufMutations.types';

const VorschreibungMailDeliverySendDetailsPage = () => {
  const { generierlaufId } = useParams() as VerarbeitungVorschreibungMailDeliverySendDetailsPageRouteParams;

  const { data, refetch } = useVorschreibungMailDeliverySendGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getVorschreibungMailDeliverySendGenerierlauf.data;

  const [runRestart] = useRestartVorschreibungMailDeliverySendGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <DetailsPageTemplate<VorschreibungMailDeliverySendGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.VorschreibungMailDeliverySend}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.VorschreibungMailDeliverySend}
          onGenerierlaufRestart={runRestart}
        />
      )}
      isLoading={false}
      sidebarMenuList={menuListVerarbeitungMailDeliverySend}
    />
  );
};

export default VorschreibungMailDeliverySendDetailsPage;
