import { ITimeblock } from '../../../../../components/Timeline/timelineCtx';
import { FormFields, mapFormDateValueToDateString, TFormattedDecimal } from '../../../../../helpers/formikHelper';
import { BENutzflaecheVersionTimelineDataSourceEntry } from '../nutzflaecheVersionTimelineMapper';
import { BestandseinheitNutzflaecheVersionInput } from '../../../../../types';
import { ValuesDescriptionForKommentar } from '../../../../../components/Timeline/versionTimelineHelper';
import { mapFormattedDecimalOrThrowIfEmpty } from '../../../../../components/Input-antd/formattedDecimalHelper';

export type NutzflaecheVersionFormValues = {
  validFrom: string;
  kommentar: string;
  nutzflaeche: TFormattedDecimal;
};

export const nutzflaecheVersionFormFields: FormFields<NutzflaecheVersionFormValues> = {
  validFrom: 'validFrom',
  kommentar: 'kommentar',
  nutzflaeche: 'nutzflaeche',
};

export const mapTimeblockToFormValues = (timeblock: ITimeblock<BENutzflaecheVersionTimelineDataSourceEntry>): NutzflaecheVersionFormValues => ({
  validFrom: timeblock.validFrom,
  kommentar: timeblock.kommentar ?? '',
  nutzflaeche: timeblock.nutzflaeche,
});

export const mapFormValuesToBestandseinheitVersion = (values: NutzflaecheVersionFormValues): BestandseinheitNutzflaecheVersionInput => ({
  validFrom: mapFormDateValueToDateString(values.validFrom),
  kommentar: values.kommentar,
  nutzflaeche: mapFormattedDecimalOrThrowIfEmpty(values.nutzflaeche),
});

export const nutzflaecheVersionFormValuesDescriptionsForKommentar: ValuesDescriptionForKommentar<NutzflaecheVersionFormValues> = {
  validFrom: 'Gültigkeitsdatum',
  nutzflaeche: 'Nutzfläche',
};
