import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { friendlyFormatIBAN } from 'ibantools';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { EuroAmount } from '../../../../components/Number';
import StatusBadge from '../../../../components/Status/StatusBadge';
import { generatePathToMahnungDetailsPage } from '../../mahnungUriPaths';
import MahnungTableColumnsObjekt from './MahnungTableColumnsObjekt';
import { MahnungListEntry, MahnungOrderBy, MahnungStatus } from '../../../../types';
import { isStatusGesperrt } from '../../../../helpers/statusHelper';
import MahnungGesperrtStatus from './MahnungGesperrtStatus';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import DataWithShortenedTextAndExtraInfo from '../../../../components/Helpers/DataWithShortenedTextAndExtraInfo';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import { generatePathToBookingDetailsPage } from '../../../BookingDetails/bookingDetailsPaths';
import RechtstraegerWithContactsTooltip from '../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

const mahnungTableColumns: TableWithColSelectorColumnProps<MahnungListEntry>[] = [
  {
    title: 'Rechnungsaussteller',
    defaultSelected: true,
    dataIndex: MahnungOrderBy.RechnungsAusstellerKurzbezeichnung,
    sorter: true,
    render: (text, record) => (
      <RechtstraegerWithContactsTooltip
        rechtstraegerId={record.rechnungsAussteller.rechtstraegerId}
        rechtstraegerName={record.rechnungsAussteller.kurzBezeichnung}
        status={record.rechnungsAussteller.status}
        maxTextLength={25}
      />
    ),
  },
  {
    title: 'Objekt',
    defaultSelected: true,
    render: (text, record) => <MahnungTableColumnsObjekt mahnungId={record.mahnungId} />,
  },
  {
    title: 'Objekt-Bankverbindung',
    dataIndex: MahnungOrderBy.RechnungsAusstellerBankDetails,
    sorter: true,
    render: (text, record) =>
      friendlyFormatIBAN(record.rechnungsAusstellerBankDetailsIban) ? (
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        <DataWithShortenedTextAndExtraInfo maxTextLength={18} text={friendlyFormatIBAN(record.rechnungsAusstellerBankDetailsIban)!} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Vertragspartner',
    defaultSelected: true,
    dataIndex: MahnungOrderBy.VertragspartnerKurzbezeichnung,
    sorter: true,
    render: (text, record) => (
      <RechtstraegerWithContactsTooltip
        rechtstraegerId={record.vertragspartner.rechtstraegerId}
        rechtstraegerName={record.vertragspartner.kurzBezeichnung}
        status={record.vertragspartner.status}
        maxTextLength={20}
      />
    ),
  },
  {
    title: 'Rechnungsbetrag-Summe',
    defaultSelected: true,
    sorter: true,
    dataIndex: MahnungOrderBy.SumRechnungsbetrag,
    align: 'right',
    render: (text, record) => <EuroAmount value={record.sumRechnungsbetrag} />,
  },
  {
    title: 'Mahnbetrag-Summe',
    defaultSelected: true,
    sorter: true,
    dataIndex: MahnungOrderBy.SumMahnbetrag,
    align: 'right',
    render: (text, record) => <EuroAmount value={record.sumMahnbetrag} />,
  },
  {
    title: 'Mahngebühr',
    defaultSelected: true,
    sorter: true,
    dataIndex: MahnungOrderBy.Mahngebuehr,
    align: 'right',
    render: (text, record) => {
      const link =
        record.status.value === MahnungStatus.GemahntMitBuchung && record.buchungId
          ? generatePathToBookingDetailsPage([record.buchungId])
          : undefined;

      return link ? (
        <Link to={link} target="_blank">
          <EuroAmount value={Number(record.mahngebuehr)} />
        </Link>
      ) : (
        <EuroAmount value={Number(record.mahngebuehr)} />
      );
    },
  },
  {
    title: 'Verzugszinsen',
    defaultSelected: true,
    sorter: true,
    dataIndex: MahnungOrderBy.SumVerzugszinsen,
    align: 'right',
    render: (text, record) => {
      const link =
        record.status.value === MahnungStatus.GemahntMitBuchung && record.buchungId
          ? generatePathToBookingDetailsPage([record.buchungId])
          : undefined;

      return link ? (
        <Link to={link} target="_blank">
          <EuroAmount value={Number(record.sumVerzugszinsen)} />
        </Link>
      ) : (
        <EuroAmount value={Number(record.sumVerzugszinsen)} />
      );
    },
  },
  {
    title: 'Mahnstichtag',
    defaultSelected: true,
    sorter: true,
    dataIndex: MahnungOrderBy.Mahnstichtag,
    render: (text, record) => <CustomFormattedDate value={record.mahnstichtag} />,
  },
  {
    title: 'Einschreiben',
    defaultSelected: true,
    render: (text, record) => <Typography.Text>{record.versandEinschreiben ? 'Ja' : 'Nein'}</Typography.Text>,
  },
  {
    title: 'Status',
    defaultSelected: true,
    sorter: true,
    width: 100,
    dataIndex: MahnungOrderBy.Status,
    render: (text, record) =>
      isStatusGesperrt(record.status) ? <MahnungGesperrtStatus record={record} /> : <StatusBadge entityStatus={record.status} />,
  },
  {
    title: 'Erstellt am',
    dataIndex: MahnungOrderBy.CreateTs,
    sorter: true,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    dataIndex: MahnungOrderBy.CreatedBy,
    sorter: true,
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => <Link to={generatePathToMahnungDetailsPage(record.mahnungId)}>Details</Link>,
  },
];

export default mahnungTableColumns;
