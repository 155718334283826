import React, { FC } from 'react';
import { List, Typography } from 'antd';
import { TicketChangeEntryFieldsFragment } from '../../gql/TicketFragments.types';
import { Ticket } from '../../../../types';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import TicketPriorityTag from '../../shared/TicketPriorityTag';
import StatusBadge from '../../../../components/Status/StatusBadge';
import { Spacer } from '../../../../components/Grid';
import TicketHistoryExpandableText from './TicketHistoryExpandableText';
import TicketChangeEntry from './TicketChangeEntry';

export type TicketChangeEntryChangeDetails = Record<
  string,
  {
    label: string;
    oldValue: any;
    newValue: any;
    render: (data: any) => React.ReactNode;
  }
>;

type Props = {
  ticket: Ticket;
  changeEntryList?: TicketChangeEntryFieldsFragment[];
  loading: boolean;
};

const TicketChangeEntryList: FC<Props> = ({ ticket, changeEntryList, loading }) => {
  return (
    <>
      <Spacer height={16} />
      <List
        loading={loading}
        dataSource={changeEntryList}
        itemLayout="horizontal"
        renderItem={(changeEntry) => (
          <TicketChangeEntry
            key={changeEntry.createTs}
            ticket={ticket}
            changeEntry={changeEntry}
            changeDetails={changeEntryChangeDetails(changeEntry)}
          />
        )}
        locale={{
          emptyText: 'Kein Verlauf vorhanden',
        }}
      />
    </>
  );
};

const changeEntryChangeDetails = (entry: TicketChangeEntryFieldsFragment): TicketChangeEntryChangeDetails => {
  const changeDetails: TicketChangeEntryChangeDetails = {};

  if (entry.oldTitle || entry.newTitle) {
    changeDetails.title = {
      label: 'Titel',
      oldValue: entry.oldTitle,
      newValue: entry.newTitle,
      render: (data: TicketChangeEntryFieldsFragment['oldTitle' | 'newTitle']) => renderText(data),
    };
  }

  if (entry.oldDueDate || entry.newDueDate) {
    changeDetails.dueDate = {
      label: 'Fälligkeit',
      oldValue: entry.oldDueDate,
      newValue: entry.newDueDate,
      render: (data: TicketChangeEntryFieldsFragment['oldDueDate' | 'newDueDate']) => data && <CustomFormattedDate value={data} />,
    };
  }

  if (entry.oldReminderText || entry.newReminderText) {
    changeDetails.reminder = {
      label: 'Erinnerung',
      oldValue: entry.oldReminderText,
      newValue: entry.newReminderText,
      render: (data: TicketChangeEntryFieldsFragment['oldReminderText' | 'newReminderText']) => renderText(data),
    };
  }

  if (entry.oldStatus || entry.newStatus) {
    changeDetails.status = {
      label: 'Status',
      oldValue: entry.oldStatus,
      newValue: entry.newStatus,
      render: (data: TicketChangeEntryFieldsFragment['oldStatus' | 'newStatus']) => data && <StatusBadge entityStatus={data} />,
    };
  }

  if (entry.oldPriority || entry.newPriority) {
    changeDetails.priority = {
      label: 'Priortät',
      oldValue: entry.oldPriority,
      newValue: entry.newPriority,
      render: (data: TicketChangeEntryFieldsFragment['oldPriority' | 'newPriority']) => data && <TicketPriorityTag priority={data} />,
    };
  }

  if (entry.oldDescription || entry.newDescription) {
    changeDetails.description = {
      label: 'Beschreibung',
      oldValue: entry.oldDescription,
      newValue: entry.newDescription,
      render: (data: TicketChangeEntryFieldsFragment['oldDescription' | 'newDescription']) => renderLongText(data),
    };
  }

  if (entry.oldAssigneeKurzBezeichnung || entry.newAssigneeKurzBezeichnung) {
    changeDetails.assignee = {
      label: 'Bearbeiter',
      oldValue: entry.oldAssigneeKurzBezeichnung,
      newValue: entry.newAssigneeKurzBezeichnung,
      render: (data: TicketChangeEntryFieldsFragment['oldAssigneeKurzBezeichnung' | 'newAssigneeKurzBezeichnung']) => renderText(data),
    };
  }

  if (entry.oldAttachmentList?.length || entry.newAttachmentList?.length) {
    changeDetails.attachmentList = {
      label: 'Anhänge',
      oldValue: entry.oldAttachmentList && entry.oldAttachmentList.length > 0 ? entry.oldAttachmentList : null,
      newValue: entry.newAttachmentList && entry.newAttachmentList.length > 0 ? entry.newAttachmentList : null,
      render: (data: TicketChangeEntryFieldsFragment['newAttachmentList' | 'oldAttachmentList']) => renderList(data),
    };
  }

  if (entry.oldEmailList?.length || entry.newEmailList?.length) {
    changeDetails.emailList = {
      label: 'E-Mail',
      oldValue: entry.oldEmailList && entry.oldEmailList.length > 0 ? entry.oldEmailList : null,
      newValue: entry.newEmailList && entry.newEmailList.length > 0 ? entry.newEmailList : null,
      render: (data: TicketChangeEntryFieldsFragment['oldEmailList' | 'newEmailList']) =>
        renderList(data?.map(({ sender, subject }) => `Von: ${sender}:  ${subject}`)),
    };
  }

  if (entry.oldParticipantList?.length || entry.newParticipantList?.length) {
    changeDetails.participantList = {
      label: 'Beteiligte',
      oldValue: entry.oldParticipantList && entry.oldParticipantList.length > 0 ? entry.oldParticipantList : null,
      newValue: entry.newParticipantList && entry.newParticipantList.length > 0 ? entry.newParticipantList : null,
      render: (data: TicketChangeEntryFieldsFragment['oldParticipantList' | 'newParticipantList']) => renderList(data),
    };
  }

  if (entry.oldComment || entry.newComment) {
    changeDetails.comment = {
      label: 'Kommentar',
      oldValue: entry.oldComment,
      newValue: entry.newComment,
      render: (data: TicketChangeEntryFieldsFragment['oldComment' | 'newComment']) => renderLongText(data),
    };
  }

  return changeDetails;
};

const renderText = (data: string | undefined | null) => data && <Typography.Text>{data}</Typography.Text>;
const renderLongText = (data: string | undefined | null) => data && <TicketHistoryExpandableText maxLength={25} text={data} />;
const renderList = (data: string[] | undefined | null) =>
  data && (
    <ul style={{ paddingLeft: 12 }}>
      {data?.map((entry) => {
        return <li>{entry}</li>;
      })}
    </ul>
  );

export default TicketChangeEntryList;
