import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';
import { mapFormValuesToQueryVariables, ticketListingFiltersFormInitialValues, TicketListingFiltersFormValues } from './ticketListingFormMapper';
import TicketListingForm from './TicketListingForm';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { TicketBase } from '../../../types';
import { TTicketQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import { useQueryParams } from '../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../hooks/usePaginationQueryParams';

/**
 * These props are used to filter the query for different listings
 * for example: filter by objektId, bestandseinheitId, etc.)
 *
 * Query can be filtered further by using filter inputs in the listing form.
 */
export type TicketListingFilterProps = {
  objektId?: string;
  bestandseinheitId?: string;
  vertragId?: string;
  rechtstraegerId?: string;
  objektInfoFeldId?: string;
  beInfoFeldId?: string;
  vertragInfoFeldId?: string;
  rechtstraegerInfoFeldId?: string;
};

export type TicketListingChildrenProps = {
  formikProps: FormikProps<TicketListingFiltersFormValues>;
  loading: boolean;
  refetch: () => void;
  queryParams: TTicketQueryParams;
};

type Props = {
  tableFilterIdentifier: string;
  tableColumns?: (onAction: () => any) => TableWithColSelectorColumnProps<TicketBase>[];
  onSuccess?: () => void;
  children?: (props: TicketListingChildrenProps) => React.ReactNode;
  pageUri?: string; // Optional page uri to update query params
  bottom?: (formikProps: FormikProps<TicketListingFiltersFormValues>) => React.ReactNode;
} & TicketListingFilterProps;

const TicketListing: FC<Props> = ({ tableFilterIdentifier, tableColumns, onSuccess, children, pageUri, bottom, ...props }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TTicketQueryParams>();
  const paginationParams = usePaginationQueryParams();

  // Remove unnecessary query params if not needed to not affect the query
  if (props.objektId) {
    delete queryParams.objektId;
    delete queryParams.rechtstraegerId;
  }
  if (props.bestandseinheitId) delete queryParams.bestandseinheitId;
  if (props.vertragId) delete queryParams.vertragId;

  if (props.rechtstraegerId) {
    delete queryParams.objektId;
    delete queryParams.bestandseinheitId;
    delete queryParams.vertragId;
    delete queryParams.rechtstraegerId;
  }

  const setInitialQueryParams = () => {
    if (!pageUri) return;
    updateQueryParams(pageUri, navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: TicketListingFiltersFormValues) => {
    if (!pageUri) return;
    const filters = mapFormValuesToQueryVariables(values) as TTicketQueryParams;
    updateQueryParams(pageUri, navigate, filters, paginationParams);
  };
  return (
    <Formik<TicketListingFiltersFormValues> initialValues={ticketListingFiltersFormInitialValues(queryParams)} onSubmit={onChange}>
      {(formikProps) => (
        <>
          <TicketListingForm
            formikProps={formikProps}
            tableFilterIdentifier={tableFilterIdentifier}
            tableColumns={tableColumns}
            queryParams={queryParams}
            onSuccess={onSuccess}
            {...props}
          >
            {(props) => children?.(props)}
          </TicketListingForm>
          {bottom?.(formikProps)}
        </>
      )}
    </Formik>
  );
};

export default TicketListing;
