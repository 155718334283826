import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { indexedValueListingFiltersFormFields, IndexedValueListingFiltersFormValues } from './indexedValueListingFiltersFormMapper';
import BestandseinheitFormSelect from '../../../shared/Bestandseinheit/BestandseinheitFormSelect';
import FormItemForFilter from '../../../../components/Form/FormItemForFilter';

type Props = {
  formikProps: FormikProps<IndexedValueListingFiltersFormValues>;
};

const BestandseinheitSelectFormItem: FC<Props> = ({ formikProps }) => (
  <FormItemForFilter
    name={indexedValueListingFiltersFormFields.bestandseinheitId}
    extraInfo={
      !formikProps.values.objektId ? (
        <Tooltip title="Bitte wählen Sie zuerst ein Objekt aus">
          <InfoCircleOutlined />
        </Tooltip>
      ) : null
    }
  >
    <BestandseinheitFormSelect
      name={indexedValueListingFiltersFormFields.bestandseinheitId}
      objektId={formikProps.values.objektId}
      onChange={(value) => {
        if (!value) {
          formikProps.setFieldValue(indexedValueListingFiltersFormFields.vertragId, '');
        }
        formikProps.submitForm();
      }}
      prefix="Bestandseinheit"
      placeholder=""
      size="middle"
    />
  </FormItemForFilter>
);

export default BestandseinheitSelectFormItem;
