import { useEffect } from 'react';
import { useBookingSuggestionQuery } from '../../../BookingSuggestion/gql/BookingSuggestionQueries.types';

const useBookingSuggestion = (
  bookingSuggestionId: string,
  setBookingCorrectionInProgress: (value: boolean | 'done') => void,
  currentFibuBuchungId: string
) => {
  const { data, loading: isBookingSuggestionLoading } = useBookingSuggestionQuery({
    variables: {
      bookingSuggestionId,
    },
  });
  const bookingSuggestion = data?.getBookingSuggestion.data;

  useEffect(() => {
    !bookingSuggestion || bookingSuggestion.buchungsanweisung.fibuBuchungId === currentFibuBuchungId
      ? setBookingCorrectionInProgress(false)
      : setBookingCorrectionInProgress('done');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingSuggestion]);

  return { bookingSuggestion, isBookingSuggestionLoading };
};

export default useBookingSuggestion;
