import React, { FC } from 'react';
import { Descriptions, Divider } from 'antd';
import { friendlyFormatIBAN } from 'ibantools';
import { Box } from 'rebass';
import theme from '../../../theme';
import CustomFormattedDate from '../../../components/FormattedDate/CustomFormattedDate';
import { TInvoice } from '../Form/incomingInvoiceFormMapper';
import { isInvoiceTypeSingleInvoice } from '../Form/incomingInvoiceFormHelpers';
import { SingleInvoice } from '../../../types';

type Props = {
  incomingInvoice: TInvoice;
};

const IncomingInvoiceDetailsPaymentBlock: FC<Props> = ({ incomingInvoice }) => {
  return (
    <>
      <Divider style={{ borderColor: theme.colors.black }}>Zahlung</Divider>

      <Descriptions labelStyle={{ color: theme.textColor.secondary }} bordered={false} colon={false} column={1} layout="horizontal" size="small">
        <Descriptions.Item label="Zahlungsart">
          <Box mx="auto" />
          {incomingInvoice.paymentMethod.text}
        </Descriptions.Item>

        <Descriptions.Item label="Bankverbindung Rechnungsempfänger">
          <Box mx="auto" />
          {friendlyFormatIBAN(incomingInvoice.payeeBankDetailsIban)}
        </Descriptions.Item>

        <Descriptions.Item label="Bankverbindung Lieferant">
          <Box mx="auto" />
          {friendlyFormatIBAN(incomingInvoice.issuerBankDetailsIban)}
        </Descriptions.Item>

        <Descriptions.Item label="Verwendungszweck">
          <Box mx="auto" />
          {incomingInvoice.purposeOfUseText}
        </Descriptions.Item>

        <Descriptions.Item label="Zahlungsreferenz">
          <Box mx="auto" />
          {incomingInvoice.paymentReferenceText}
        </Descriptions.Item>

        {isInvoiceTypeSingleInvoice(incomingInvoice.type) && (
          <Descriptions.Item label="Gewünschtes Zahlungsdatum">
            <Box mx="auto" />
            {(incomingInvoice as SingleInvoice).requestedPaymentDate ? (
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              <CustomFormattedDate value={(incomingInvoice as SingleInvoice).requestedPaymentDate!} />
            ) : (
              ''
            )}
          </Descriptions.Item>
        )}

        <Descriptions.Item label="SEPA-Mandatsreferenz">
          <Box mx="auto" />
          {incomingInvoice.sepaMandatReference}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default IncomingInvoiceDetailsPaymentBlock;
