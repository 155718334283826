import { Button, Card, Col, Drawer, Dropdown } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { EllipsisOutlined } from '@ant-design/icons';
import { useToggle } from '../../../hooks/useToggle';

const RechnungseinstellungenCard = ({ contact }) => {
  const [isCollapsed, onCollapse] = useToggle();

  const items = [{ key: '1', label: 'Bearbeiten', onClick: onCollapse }];

  return (
    <>
      {contact.stammdaten ? (
        <Col>
          <Card
            style={{ minWidth: '360px' }}
            size="small"
            title=""
            extra={
              <Dropdown menu={{ items }}>
                <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
              </Dropdown>
            }
          >
            <p>
              Art der Gewinnermittlung: {contact.stammdaten.gewinnermittlung}
              <br />
              UST System: {contact.stammdaten.ustSystem}
            </p>
            <p>
              USt Anmeldung: {contact.stammdaten.ustAnmeldung} <br /> UVA: {contact.stammdaten.uva}
            </p>
          </Card>
        </Col>
      ) : null}
      <Drawer title="Rechnungseinstellungen bearbeiten" width={720} open={isCollapsed} onClose={onCollapse} destroyOnClose>
        <p>@TODO inputs</p>
      </Drawer>
    </>
  );
};

RechnungseinstellungenCard.propTypes = {
  contact: PropTypes.object.isRequired,
};

export default RechnungseinstellungenCard;
