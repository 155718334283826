import { generatePath, useNavigate } from 'react-router';
import React, { FC, useState } from 'react';
import { Dropdown, MenuProps, Modal } from 'antd';
import { Link } from 'react-router-dom';
import {
  DeleteOutlined,
  EditOutlined,
  EuroCircleOutlined,
  EyeOutlined,
  HistoryOutlined,
  PlusSquareOutlined,
  ReadOutlined,
  TruckOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { uriFileOfFirmendaten } from '../documentApiPaths';
import { FileInfo, IncomingInvoiceStatus, StandingInvoice, StandingInvoicePartialAmount } from '../../../types';
import { showSuccessMsgDelete, showSuccessMsgFreigegeben } from '../../../components/message/message';
import HistoryModal, { HistoryType } from '../../History/shared/HistoryModal';
import { useToggle } from '../../../hooks/useToggle';
import { radixActionStyles } from '../../../helpers/radixIconsStyles';
import { isStatusErstellt, isStatusUebertragen, isStatusUebertragenFehler } from '../../../helpers/statusHelper';
import { isPaymentMethodUeberweisung } from '../../Payment/paymentHelpers';
import PaymentCreateForm from '../../Payment/Form/Create/PaymentCreateForm';
import { generatePathToOffenePostenPage } from '../../OffenePosten/offenePostenUriPaths';
import { useDeleteStandingInvoiceMutation, useStandingInvoiceFreigebenMutation } from '../../IncomingInvoice/gql/StandingInvoiceMutations.types';
import { dayjsCustom } from '../../../helpers/dayjsCustom';
import { generatePathToPaymentPage } from '../../Payment/Listing/Filters/filtersQueryParams';
import StandingInvoiceChangeHistoryListingTable from '../../IncomingInvoice/Form/shared/StandingInvoiceChangeHistoryListingTable';
import { isIncomingInvoiceFreigebenAllowed, isIncomingInvoiceUpdateAllowed } from '../../IncomingInvoice/incomingInvoiceHelpers';
import { generatePathToStandingInvoiceDetailsPage, incomingInvoicePaths } from '../../IncomingInvoice/incomingInvoiceUriPaths';
import { showConfirmIncomingInvoiceDelete } from '../../IncomingInvoice/shared/incomingInvoiceModals';
import { gqlOperations } from '../../../graphql/gqlNamedOperations-generated';
import { generatePathToBookingDetailsPage } from '../../BookingDetails/bookingDetailsPaths';

type Props = {
  record: FileInfo;
  file: FileInfo;
  invoice: StandingInvoice;
  onAction: () => void;
};

const StandingInvoiceActions: FC<Props> = ({ record, file, invoice, onAction }) => {
  const navigate = useNavigate();
  const { activeForFirmendatenId } = useFDAuthorized();

  const [isChangeHistoryOpen, toggleChangeHistoryOpen] = useToggle();
  const [isZahlungCreateOpen, toggleZahlungCreateOpen] = useToggle();

  const [partialAmount, setPartialAmount] = useState<StandingInvoicePartialAmount>();

  const [eingangsRechnungFreigeben] = useStandingInvoiceFreigebenMutation({
    variables: { belegId: invoice.belegId },
    onCompleted: () => {
      showSuccessMsgFreigegeben('Eingangsrechnung');
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runDelete] = useDeleteStandingInvoiceMutation({
    variables: { belegId: invoice.belegId },
    onCompleted: () => {
      showSuccessMsgDelete('Eingangsrechnung');
    },
    refetchQueries: () => [gqlOperations.Query.FileInfoListForFirmendaten],
  });

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Details ansehen',
      onClick: () => window.open(generatePathToStandingInvoiceDetailsPage(invoice.fileId, invoice.belegId)),
      icon: <TruckOutlined />,
    },
    {
      key: '2',
      label: 'Buchungsdetails ansehen',
      disabled: invoice.status.value !== IncomingInvoiceStatus.Uebertragen,
      onClick: () => window.open(generatePathToBookingDetailsPage(invoice.buchungIdList, undefined, invoice.belegId)),
      icon: <ReadOutlined />,
    },
    isIncomingInvoiceUpdateAllowed(invoice.status.value) || isStatusUebertragenFehler(invoice.status)
      ? {
          key: '3',
          label: 'Bearbeiten',
          onClick: () =>
            navigate(
              generatePath(incomingInvoicePaths.standingInvoiceUpdate, {
                fileId: file.fileId,
                belegId: invoice.belegId,
              })
            ),
          icon: <EditOutlined />,
        }
      : null,
    !isIncomingInvoiceUpdateAllowed(invoice.status.value)
      ? {
          key: '4',
          label: 'Ansehen',
          onClick: () =>
            navigate(
              generatePath(incomingInvoicePaths.standingInvoiceDetails, {
                fileId: file.fileId,
                belegId: invoice.belegId,
              })
            ),
          icon: <EyeOutlined />,
        }
      : null,
    {
      key: '5',
      label: 'Änderungsverlauf',
      onClick: () => toggleChangeHistoryOpen(),
      icon: <HistoryOutlined style={radixActionStyles} />,
    },
    ...(isStatusErstellt(invoice.status)
      ? [
          {
            key: '6',
            label: 'Verbuchen',
            onClick: () => eingangsRechnungFreigeben(),
            icon: <ReadOutlined />,
            disabled: !isIncomingInvoiceFreigebenAllowed(invoice.status.value),
          },
          {
            key: '7',
            label: 'Löschen',
            onClick: () => showConfirmIncomingInvoiceDelete(runDelete),
            danger: true,
            icon: <DeleteOutlined />,
          },
        ]
      : []),
    isStatusUebertragen(invoice.status) && invoice.buchungIdList.length > 0
      ? {
          key: '8',
          label: 'Offene Posten anzeigen',
          icon: <WalletOutlined />,
          onClick: () =>
            window.open(
              generatePathToOffenePostenPage({
                buchungskreisId: invoice.buchungskreisId,
                belegnummer: invoice.rechnungsNummer,
                vertragspartnerId: invoice.ausstellerRechtstraegerId,
                belegDatumTo: invoice.rechnungsDatum,
                belegDatumFrom: invoice.rechnungsDatum,
              })
            ),
        }
      : null,
    ...(isPaymentMethodUeberweisung(invoice.paymentMethod.value) && !isStatusErstellt(invoice.status)
      ? [
          {
            key: '9',
            label: 'Zahlungen anzeigen',
            icon: <EuroCircleOutlined />,
            onClick: () =>
              window.open(
                generatePathToPaymentPage({
                  belegnummer: invoice.rechnungsNummer,
                  // INFO: This is the correct way of passing the values for Eingansrechnung. Don't change it
                  rechnungsAusstellerId: invoice.payeeRechtstraegerId,
                  payeeId: invoice.ausstellerRechtstraegerId,
                })
              ),
            disabled: invoice.paymentIdList.length <= 0,
          },
          {
            key: '10',
            label: 'Zahlung erstellen',
            icon: <PlusSquareOutlined />,
            children: invoice.partialAmountList.map((partialAmount, index) => ({
              key: partialAmount.partialAmountId,
              label: `Teilbetrag  ${index + 1} ${dayjsCustom(partialAmount.buchungsdatum).format('MM/YYYY')}`,
              onClick: () => {
                setPartialAmount({
                  ...partialAmount,
                  offenerBetrag: partialAmount.offenerBetrag ?? 0,
                });
                toggleZahlungCreateOpen();
              },
              disabled: !partialAmount.paymentCreatable,
            })),
          },
        ]
      : []),
  ];

  const onActionSuccess = () => {
    onAction();
    toggleZahlungCreateOpen();
  };

  return (
    <>
      <Dropdown.Button menu={{ items }}>
        <Link to={uriFileOfFirmendaten(activeForFirmendatenId, record.fileId, record.name)} target="_blank">
          Öffnen
        </Link>
      </Dropdown.Button>
      <Modal
        title="Zahlung erstellen"
        onCancel={toggleZahlungCreateOpen}
        open={isZahlungCreateOpen}
        destroyOnClose
        maskClosable={false}
        footer={false}
      >
        <PaymentCreateForm
          onCancel={toggleZahlungCreateOpen}
          onSuccess={onActionSuccess}
          belegId={invoice.belegId}
          paymentMethod={invoice.paymentMethod.value}
          outstandingAmount={partialAmount?.offenerBetrag}
          standingInvoicePartialAmountId={partialAmount?.partialAmountId}
        />
      </Modal>
      <HistoryModal historyType={HistoryType.Change} open={isChangeHistoryOpen} onCancel={toggleChangeHistoryOpen}>
        <StandingInvoiceChangeHistoryListingTable belegId={invoice.belegId} />
      </HistoryModal>
    </>
  );
};

export default StandingInvoiceActions;
