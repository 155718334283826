import React, { FC } from 'react';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import { Tabs, TabsProps } from 'antd';
import { useFDAuthorized } from '../../../../../../shared/Auth/useAuthorized';
import { BeInfoFeldType } from '../../../../../../types';
import FormButtons from '../../../../../../components/Button/FormButtons';
import { handleUpdateSubmit } from '../../shared/handleSubmit';
import KellerabteilDatenTab from '../Tabs/KellerabteilDatenTab';
import InfoFeldUpload from '../../../../../shared/InfoFeld/InfoFeldUpload';
import { kellerabteilUpdateFormFields, KellerabteilUpdateFormValues, mapToUpdateFormValues } from './kellerabteilUpdateFormMapper';
import InfoFeldDocumentTab from '../../../../../shared/InfoFeld/Tabs/InfoFeldDocumentTab';
import { kellerabteilValidationSchema } from '../shared/kellerabteilValidationSchema';
import { BeInfoFeld_BeInfoFeldKellerabteil_Fragment } from '../../../gql/BeInfoFeldFragments.types';
import FormButtonClose from '../../../../../../components/Button/FormButtonClose';
import BeInfoFeldTicketListing from '../../../Ticket/BeInfoFeldTicketListing';
import { InfoFeldUpdateFormTabKey } from '../../../../../InfoFeld/infoFeldTypes';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  onCancel: () => void;
  onInfoFeldAction: () => void;
  onTicketAction: () => void;
  infoFeld: BeInfoFeld_BeInfoFeldKellerabteil_Fragment;
  activeKeyTab: InfoFeldUpdateFormTabKey;
};

const KellerabteilUpdateForm: FC<Props> = ({ objektId, bestandseinheitId, onInfoFeldAction, onTicketAction, onCancel, infoFeld, activeKeyTab }) => {
  const {
    activeForFirmendaten: { firmendatenId },
  } = useFDAuthorized();

  return (
    <Formik<KellerabteilUpdateFormValues>
      initialValues={mapToUpdateFormValues(infoFeld)}
      onSubmit={(values, { setSubmitting }) => {
        handleUpdateSubmit(
          firmendatenId,
          objektId,
          bestandseinheitId,
          infoFeld.beInfoFeldId,
          BeInfoFeldType.Kellerabteil,
          values,
          setSubmitting,
          onInfoFeldAction
        );
      }}
      validationSchema={kellerabteilValidationSchema}
    >
      {(formikProps) => {
        const items: TabsProps['items'] = [
          {
            key: InfoFeldUpdateFormTabKey.DATA,
            label: 'Daten',
            children: (
              <Form layout="vertical">
                <KellerabteilDatenTab formFields={kellerabteilUpdateFormFields} />
                <InfoFeldUpload
                  name={kellerabteilUpdateFormFields.documentList}
                  formikProps={formikProps}
                  documentList={formikProps.values.documentList}
                />
                <FormButtons updateMode onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
              </Form>
            ),
          },
          {
            key: InfoFeldUpdateFormTabKey.TICKET,
            label: 'Fristen/Aufgaben',
            children: (
              <>
                <BeInfoFeldTicketListing objektId={objektId} bestandseinheitId={bestandseinheitId} infoFeld={infoFeld} onSuccess={onTicketAction} />
                <FormButtonClose onCancel={onCancel} />
              </>
            ),
          },
          {
            key: InfoFeldUpdateFormTabKey.DOCUMENT,
            label: 'Dokumente',
            children: (
              <Form layout="vertical">
                <InfoFeldDocumentTab
                  infoFeld={infoFeld}
                  deleteDocumentFileIdList={formikProps.values.deleteDocumentFileIdList}
                  onDocumentDelete={(value) => formikProps.setFieldValue(kellerabteilUpdateFormFields.deleteDocumentFileIdList, value)}
                  firmendatenId={firmendatenId}
                />
                <InfoFeldUpload
                  name={kellerabteilUpdateFormFields.documentList}
                  formikProps={formikProps}
                  documentList={formikProps.values.documentList}
                />
                <FormButtons updateMode onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} />
              </Form>
            ),
          },
        ];

        return <Tabs defaultActiveKey={activeKeyTab} style={{ minHeight: '300px' }} items={items} />;
      }}
    </Formik>
  );
};

export default KellerabteilUpdateForm;
