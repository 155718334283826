import React, { FC } from 'react';
import { Divider, Space, Typography } from 'antd';
import { Rechtstraeger } from '../../../../types';
import ObjektRechnungsAusstellerBankDetails from '../../BankDetails/ObjektRechnungsAusstellerBankDetails';
import { Spacer } from '../../../../components/Grid';
import { useObjektBankDetailsListQuery } from '../../BankDetails/gql/ObjektBankDetailsQueries.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import EntityWarningList from '../../../../components/message/EntityWarningList';
import RechtstraegerWithContactsTooltip from '../../../RechtstraegerContact/shared/RechtstraegerWithContactsTooltip';

type Props = {
  rechnungsaussteller: Rechtstraeger;
  objektId: string;
  rechnungsAusstellerBezeichnung?: string | null;
};

const ObjektRechnungsausstellerWithBankDetails: FC<Props> = ({ objektId, rechnungsaussteller, rechnungsAusstellerBezeichnung }) => {
  const { data, refetch } = useObjektBankDetailsListQuery({
    variables: {
      objektId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const objektBankDetailsList = data?.getObjektBankDetailsList.data.objektBankDetailsList ?? [];
  const objektBankDetailsWarningList = data?.getObjektBankDetailsList.data.warningList ?? [];

  return (
    <>
      <Space direction="horizontal" style={{ width: '100%', alignItems: 'baseline' }}>
        <Typography.Title level={5}>Objekt-Rechnungsaussteller</Typography.Title>
        <EntityWarningList warningList={objektBankDetailsWarningList} />
      </Space>
      <RechtstraegerWithContactsTooltip
        rechtstraegerId={rechnungsaussteller.rechtstraegerId}
        rechtstraegerName={rechnungsaussteller.kurzBezeichnung}
        status={rechnungsaussteller.status}
      />
      <Spacer height={8} />
      <div style={{ paddingLeft: '8px' }}>
        <ObjektRechnungsAusstellerBankDetails
          objektId={objektId}
          rechtstraegerId={rechnungsaussteller.rechtstraegerId}
          objektBankDetailsList={objektBankDetailsList}
          objektBankDetailsWarningList={objektBankDetailsWarningList}
          onAction={refetch}
          rechtstraegerFibuKontoCreatable={rechnungsaussteller.fibuKontoCreatable}
        />
      </div>
      <Divider style={{ marginTop: '8px', marginBottom: '8px' }} dashed />
    </>
  );
};

export default ObjektRechnungsausstellerWithBankDetails;
