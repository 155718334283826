import { useEffect } from 'react';
import { FormikProps } from 'formik';
import { useObjektBankDetailsListQuery } from '../../Objekt/BankDetails/gql/ObjektBankDetailsQueries.types';
import { ObjektBankDetails, ObjektBankDetailsVerwendung, RechtstraegerRolle } from '../../../types';
import { incomingInvoiceFormFields, IncomingInvoiceFormValues } from './incomingInvoiceFormMapper';
import { useRechtsTraegerRolleListQuery } from '../../Rechtstraeger/Rolle/gql/RolleQueries.types';
import { useWeRechnungsAusstellerListQuery } from '../../BankDetails/gql/WeRechnungsAusstellerQueries.types';

const useOnSetBankDetailsForIncomingInvoice = (formikProps: FormikProps<IncomingInvoiceFormValues>) => {
  const { data: rolleListData } = useRechtsTraegerRolleListQuery({
    variables: { rechtstraegerId: formikProps.values.payeeRechtstraegerId },
    skip: !formikProps.values.payeeRechtstraegerId,
  });
  const rolleList = rolleListData?.getRechtstraegerRolleList.data;

  useEffect(() => {
    formikProps.setFieldValue(incomingInvoiceFormFields.payeeRechtstraegerRolleList, rolleList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolleList]);

  const { data: objektBankDetailsListData } = useObjektBankDetailsListQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { objektId: formikProps.values.objektId! },
    skip: !(formikProps.values.objektId && rolleList?.includes(RechtstraegerRolle.ObjektRechnungsAussteller)),
  });
  const objektBankDetailsList = objektBankDetailsListData?.getObjektBankDetailsList.data.objektBankDetailsList;

  const { data } = useWeRechnungsAusstellerListQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { objektId: formikProps.values.objektId! },
    skip: !(formikProps.values.objektId && rolleList?.includes(RechtstraegerRolle.WeRechnungsAussteller)),
  });

  const weRechnungsAusstellerList = data?.getWeRechnungsAusstellerList.data ?? [];

  useEffect(() => {
    // Set bank details for Rechnungsempfänger when the payeeRechtstraegerId and objektId are set
    if (formikProps.values.payeeRechtstraegerId && formikProps.values.objektId && !formikProps.values.payeeBankDetailsId) {
      // Check if the payeeRechtstraegerId has the role of ObjektRechnungsAussteller or WeRechnungsAussteller
      if (rolleList?.includes(RechtstraegerRolle.ObjektRechnungsAussteller)) {
        onSetPayeeBankDetails(formikProps, objektBankDetailsList);
      } else if (rolleList?.includes(RechtstraegerRolle.WeRechnungsAussteller)) {
        const weRechnungsAussteller = weRechnungsAusstellerList.find(
          (aussteller) => aussteller.weRechnungsAusstellerRechtstraegerId === formikProps.values.payeeRechtstraegerId
        );
        onSetPayeeBankDetails(formikProps, weRechnungsAussteller?.objektBankDetailsList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(objektBankDetailsList), JSON.stringify(weRechnungsAusstellerList)]);
};

const onSetPayeeBankDetails = (formikProps: FormikProps<IncomingInvoiceFormValues>, objektBankDetailsList?: ObjektBankDetails[]) => {
  const bankDetailWithVerwendungAuszahlung = objektBankDetailsList?.find((objBankDetails) =>
    objBankDetails.verwendungList.find((verwendung) => verwendung.value === ObjektBankDetailsVerwendung.StandardAuszahlung)
  );
  if (bankDetailWithVerwendungAuszahlung) {
    formikProps.setFieldValue(incomingInvoiceFormFields.payeeBankDetailsId, bankDetailWithVerwendungAuszahlung.bankDetails.bankDetailsId);
  }
};

export default useOnSetBankDetailsForIncomingInvoice;
