import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { SyncEbicsUserGenerierlauf } from '../../../../types';
import { TSyncEbicsUserVerarbeitungQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import { verarbeitungTypes } from '../../verarbeitungHelpers';
import {
  SyncEbicsUserVerarbeitungEntryFiltersFormValues,
  syncEbicsUserVerarbEntryListingFiltersFormInitialValues,
} from './Filters/listingFiltersFormMapper';
import ListingFilters from './Filters/ListingFilters';
import SyncEbicsUserVerarbeitungEntryTable from './SyncEbicsUserVerarbeitungEntryTable';

type Props = {
  generierlauf: SyncEbicsUserGenerierlauf;
};

const EntryListing: FC<Props> = ({ generierlauf }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TSyncEbicsUserVerarbeitungQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(verarbeitungTypes.SyncEbicsUser, generierlauf.generierlaufId, navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: SyncEbicsUserVerarbeitungEntryFiltersFormValues) => {
    updateQueryParams(
      verarbeitungTypes.SyncEbicsUser,
      generierlauf.generierlaufId,
      navigate,
      //mapFormValuesToVertragVposIndexedValueVerarbeitungFilters(values),
      values,
      paginationParams
    );
  };

  return (
    <Formik<SyncEbicsUserVerarbeitungEntryFiltersFormValues>
      initialValues={syncEbicsUserVerarbEntryListingFiltersFormInitialValues(queryParams)}
      onSubmit={onChange}
    >
      {(formikProps) => (
        <>
          <ListingFilters formikProps={formikProps} entryList={generierlauf.entryList} />
          <SyncEbicsUserVerarbeitungEntryTable generierlauf={generierlauf} queryParams={queryParams} />
        </>
      )}
    </Formik>
  );
};

export default EntryListing;
