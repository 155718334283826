import * as Yup from 'yup';
import { validateGeneralBezeichnung } from '../../../../shared/components/KurzBezeichnung/form/kurzBezeichnungFormPartValidationSchema';

/* eslint-disable no-template-curly-in-string */
export const kundenSystemNameValidationSchema = validateGeneralBezeichnung(50, true).matches(
  /^[A-Za-z0-9 ]+$/,
  'Bezeichnung darf nur Buchstaben, Nummern und Leerzeichen beinhalten'
);

export const kundenSystemBaseValidationSchema = Yup.object().shape({
  name: kundenSystemNameValidationSchema,
});

/* eslint-enable no-template-curly-in-string */
