import React, { FC, PropsWithChildren } from 'react';
import { Typography } from 'antd';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';

type Props = {
  vatIdentificationNumber?: string | null;
} & PropsWithChildren;

const VatIdentificationNumber: FC<Props> = ({ children, vatIdentificationNumber }) => (
  <CardDetailsInfoRow infoRowTitle="UID">
    <Typography.Text>{vatIdentificationNumber || 'keine UID angegeben'}</Typography.Text>
  </CardDetailsInfoRow>
);

export default VatIdentificationNumber;
