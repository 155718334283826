import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { usePaginationQueryParams } from '../../../../../hooks/usePaginationQueryParams';
import { verarbeitungTypes } from '../../../verarbeitungHelpers';
import { THeAbrechnungVerarbeitungQueryParams, updateQueryParams } from '../../shared/EntryListingFilters/filtersQueryParams';
import { useQueryParams } from '../../../../../hooks/useStringQueryParams';
import {
  HeAbrechnungVerarbeitungEntryFiltersFormValues,
  heAbrechnungVerarbEntryListingFiltersFormInitialValues,
  mapFormValuesToHeAbrechnungVerarbeitungFilters,
} from '../../shared/EntryListingFilters/ListingFiltersFormMapper';
import ListingFilters from '../../shared/EntryListingFilters/ListingFilters';
import HeAbrechnungErstellenVerarbeitungEntryTable from './HeAbrechnungErstellenVerarbeitungEntryTable';
import { HeAbrechnungGenerierlauf } from '../../../../../types';

type Props = {
  generierlauf: HeAbrechnungGenerierlauf;
};

const HeAbrechnungErstellenVerarbeitungEntryListing: FC<Props> = ({ generierlauf }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<THeAbrechnungVerarbeitungQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(verarbeitungTypes.HeAbrechnung, generierlauf.generierlaufId, navigate, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: HeAbrechnungVerarbeitungEntryFiltersFormValues) => {
    updateQueryParams(
      verarbeitungTypes.HeAbrechnung,
      generierlauf.generierlaufId,
      navigate,
      mapFormValuesToHeAbrechnungVerarbeitungFilters(values),
      paginationParams
    );
  };

  return (
    <Formik<HeAbrechnungVerarbeitungEntryFiltersFormValues>
      initialValues={heAbrechnungVerarbEntryListingFiltersFormInitialValues(queryParams)}
      onSubmit={onChange}
    >
      {(formikProps) => (
        <>
          <ListingFilters formikProps={formikProps} generierlaufId={generierlauf.generierlaufId} />
          <HeAbrechnungErstellenVerarbeitungEntryTable generierlauf={generierlauf} queryParams={queryParams} />
        </>
      )}
    </Formik>
  );
};

export default HeAbrechnungErstellenVerarbeitungEntryListing;
