import React, { FC } from 'react';
import { Empty, PaginationProps, TableProps } from 'antd';
import indexedValueTableColumns from './indexedValueTableColumns';
import {
  mapFormValuesToVertragVposIndexedValue,
  mapVertragVposIndexedValueToFormValues,
  VertragVposIndexedValueFormValues,
} from './indexedValueMapper';
import { VertragVposIndexedValue } from '../../../../types';
import { useUpdateVertragVposIndexedValueMutation } from '../../gql/VertragVposIndexedValueMutations.types';
import { showSuccessMsgUpdate, showWarningMessage } from '../../../../components/message';
import EditableTable from '../../../../components/Table/EditableTableV2/EditableTable';

type Props = {
  indexedValueList: VertragVposIndexedValue[];
  loading: boolean;
  refetch: () => void;
  pagination: PaginationProps;
  /** Size of the table
   ** Default: 'small'
   ** Use this prop only if table size should be different from 'small'
   * */
  size?: 'middle' | 'large';
} & Omit<TableProps<VertragVposIndexedValue>, 'loading' | 'pagination' | 'size'>;

const IndexedValueTable: FC<Props> = ({ indexedValueList, loading, refetch, pagination, ...restProps }) => {
  const [updateValue] = useUpdateVertragVposIndexedValueMutation();

  return (
    <EditableTable<VertragVposIndexedValue, VertragVposIndexedValueFormValues>
      {...restProps}
      level={3}
      colWidth={120}
      getRowKey={(record) => record.vertragVposIndexedValueId}
      expanded={false}
      expandable={{}} //FIXME - expandable prop is used to show the scrollbar based on calculated width, but should not be the condition for rendering the scrollbar
      locale={{
        emptyText: <Empty description="Keine Wertsicherungsvereinbarungen vorhanden" />,
      }}
      loading={loading}
      dataSource={indexedValueList}
      columns={indexedValueTableColumns(refetch)}
      pagination={pagination}
      mapToFormValues={mapVertragVposIndexedValueToFormValues}
      onSubmit={(formValues) => {
        return updateValue({
          variables: {
            objektId: formValues.objektId,
            bestandseinheitId: formValues.bestandseinheitId,
            vertragId: formValues.vertragId,
            vorschreibungspositionId: formValues.vorschreibungspositionId,
            indexationAgreementId: formValues.indexationAgreementId,
            indexedValueId: formValues.vertragVposIndexedValueId,
            input: mapFormValuesToVertragVposIndexedValue(formValues),
          },
        })
          .then((response) => {
            showSuccessMsgUpdate(`Wert`);
            refetch();
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return response.data!.updateVertragVposIndexedValue.data;
          })
          .catch((error) => {
            showWarningMessage(error, 'Wert wurde nicht aktualisiert');
            // error has to be thrown to reject promise
            throw error;
          });
      }}
    />
  );
};

export default IndexedValueTable;
