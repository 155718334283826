import { Formik } from 'formik';
import React, { FC } from 'react';
import { AbrKontenzuordnungFiltersFormValues } from '../../../AbrKontenzuordnung/Listing/Filters/abrKontenzuordnungFiltersMapper';
import AbrKontenzuordnungTemplateFilters from './AbrKontenzuordnungTemplateFilters';

type Props = {
  onSubmit: (val: AbrKontenzuordnungFiltersFormValues) => void;
};

const AbrKontenzuordnungTemplateFormFilters: FC<Props> = ({ onSubmit }) => (
  <Formik<AbrKontenzuordnungFiltersFormValues> initialValues={{}} onSubmit={onSubmit}>
    {(formikProps) => <AbrKontenzuordnungTemplateFilters formikProps={formikProps} />}
  </Formik>
);

export default AbrKontenzuordnungTemplateFormFilters;
