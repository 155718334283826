import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AddressesAndContactsFragmentDoc } from '../../AddressesAndContacts/gql/AddressesAndContactsFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FirmaOperatingSiteListQueryVariables = Types.Exact<{
  firmaId: Types.Scalars['ID']['input'];
}>;

export type FirmaOperatingSiteListQuery = {
  getFirmaOperatingSiteList: {
    data: Array<{
      operatingSiteId: string;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      addressesAndContacts: {
        contactPersonList: Array<{
          contactPersonId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firstname: string;
          lastname: string;
          tagList: Array<string>;
          title?: string | null;
          titleTrailing?: string | null;
          contactList: Array<{
            contactId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            haupt?: boolean | null;
            tagList: Array<string>;
            type: string;
            wert: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          rechtstraeger?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        emailContactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        hauptAddress?: {
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        hauptEmailContact?: {
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        hauptPhoneContact?: {
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        postofficeBoxAddressList: Array<{
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        streetAddressList: Array<{
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        phoneContactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FirmaOperatingSiteQueryVariables = Types.Exact<{
  firmaId: Types.Scalars['String']['input'];
  operatingSiteId: Types.Scalars['ID']['input'];
}>;

export type FirmaOperatingSiteQuery = {
  getFirmaOperatingSite: {
    data: {
      operatingSiteId: string;
      bezeichnung: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      addressesAndContacts: {
        contactPersonList: Array<{
          contactPersonId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          firstname: string;
          lastname: string;
          tagList: Array<string>;
          title?: string | null;
          titleTrailing?: string | null;
          contactList: Array<{
            contactId: string;
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            haupt?: boolean | null;
            tagList: Array<string>;
            type: string;
            wert: string;
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          }>;
          rechtstraeger?: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          } | null;
          salutation?: { letterSalutation: string; value: Types.Salutation; text: string } | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        emailContactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        hauptAddress?: {
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        hauptEmailContact?: {
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        hauptPhoneContact?: {
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        } | null;
        postofficeBoxAddressList: Array<{
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        streetAddressList: Array<{
          addressId: string;
          city: string;
          cityAdditionalInformation?: string | null;
          countryCodeIso2: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          houseEntranceApartmentNumber?: string | null;
          postofficeBoxNumber?: string | null;
          postofficeBoxZipCode?: string | null;
          street?: string | null;
          tagList: Array<string>;
          type: Types.AddressType;
          zipCode?: string | null;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
        phoneContactList: Array<{
          contactId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          haupt?: boolean | null;
          tagList: Array<string>;
          type: string;
          wert: string;
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        }>;
      };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type FirmaOperatingSiteChangeHistoryListQueryVariables = Types.Exact<{
  firmaId: Types.Scalars['ID']['input'];
  operatingSiteId: Types.Scalars['ID']['input'];
}>;

export type FirmaOperatingSiteChangeHistoryListQuery = {
  getFirmaOperatingSiteChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const FirmaOperatingSiteListDocument = gql`
  query FirmaOperatingSiteList($firmaId: ID!) {
    getFirmaOperatingSiteList(firmaId: $firmaId) {
      data {
        addressesAndContacts {
          ...AddressesAndContacts
        }
        operatingSiteId
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AddressesAndContactsFragmentDoc}
`;
export function useFirmaOperatingSiteListQuery(
  baseOptions: Apollo.QueryHookOptions<FirmaOperatingSiteListQuery, FirmaOperatingSiteListQueryVariables> &
    ({ variables: FirmaOperatingSiteListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmaOperatingSiteListQuery, FirmaOperatingSiteListQueryVariables>(FirmaOperatingSiteListDocument, options);
}
export function useFirmaOperatingSiteListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmaOperatingSiteListQuery, FirmaOperatingSiteListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmaOperatingSiteListQuery, FirmaOperatingSiteListQueryVariables>(FirmaOperatingSiteListDocument, options);
}
export function useFirmaOperatingSiteListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FirmaOperatingSiteListQuery, FirmaOperatingSiteListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmaOperatingSiteListQuery, FirmaOperatingSiteListQueryVariables>(FirmaOperatingSiteListDocument, options);
}
export type FirmaOperatingSiteListQueryHookResult = ReturnType<typeof useFirmaOperatingSiteListQuery>;
export type FirmaOperatingSiteListLazyQueryHookResult = ReturnType<typeof useFirmaOperatingSiteListLazyQuery>;
export type FirmaOperatingSiteListSuspenseQueryHookResult = ReturnType<typeof useFirmaOperatingSiteListSuspenseQuery>;
export type FirmaOperatingSiteListQueryResult = Apollo.QueryResult<FirmaOperatingSiteListQuery, FirmaOperatingSiteListQueryVariables>;
export const FirmaOperatingSiteDocument = gql`
  query FirmaOperatingSite($firmaId: String!, $operatingSiteId: ID!) {
    getFirmaOperatingSite(firmaId: $firmaId, operatingSiteId: $operatingSiteId) {
      data {
        addressesAndContacts {
          ...AddressesAndContacts
        }
        operatingSiteId
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${AddressesAndContactsFragmentDoc}
`;
export function useFirmaOperatingSiteQuery(
  baseOptions: Apollo.QueryHookOptions<FirmaOperatingSiteQuery, FirmaOperatingSiteQueryVariables> &
    ({ variables: FirmaOperatingSiteQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmaOperatingSiteQuery, FirmaOperatingSiteQueryVariables>(FirmaOperatingSiteDocument, options);
}
export function useFirmaOperatingSiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FirmaOperatingSiteQuery, FirmaOperatingSiteQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmaOperatingSiteQuery, FirmaOperatingSiteQueryVariables>(FirmaOperatingSiteDocument, options);
}
export function useFirmaOperatingSiteSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FirmaOperatingSiteQuery, FirmaOperatingSiteQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmaOperatingSiteQuery, FirmaOperatingSiteQueryVariables>(FirmaOperatingSiteDocument, options);
}
export type FirmaOperatingSiteQueryHookResult = ReturnType<typeof useFirmaOperatingSiteQuery>;
export type FirmaOperatingSiteLazyQueryHookResult = ReturnType<typeof useFirmaOperatingSiteLazyQuery>;
export type FirmaOperatingSiteSuspenseQueryHookResult = ReturnType<typeof useFirmaOperatingSiteSuspenseQuery>;
export type FirmaOperatingSiteQueryResult = Apollo.QueryResult<FirmaOperatingSiteQuery, FirmaOperatingSiteQueryVariables>;
export const FirmaOperatingSiteChangeHistoryListDocument = gql`
  query FirmaOperatingSiteChangeHistoryList($firmaId: ID!, $operatingSiteId: ID!) {
    getFirmaOperatingSiteChangeHistoryList(firmaId: $firmaId, operatingSiteId: $operatingSiteId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useFirmaOperatingSiteChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<FirmaOperatingSiteChangeHistoryListQuery, FirmaOperatingSiteChangeHistoryListQueryVariables> &
    ({ variables: FirmaOperatingSiteChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmaOperatingSiteChangeHistoryListQuery, FirmaOperatingSiteChangeHistoryListQueryVariables>(
    FirmaOperatingSiteChangeHistoryListDocument,
    options
  );
}
export function useFirmaOperatingSiteChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmaOperatingSiteChangeHistoryListQuery, FirmaOperatingSiteChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmaOperatingSiteChangeHistoryListQuery, FirmaOperatingSiteChangeHistoryListQueryVariables>(
    FirmaOperatingSiteChangeHistoryListDocument,
    options
  );
}
export function useFirmaOperatingSiteChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FirmaOperatingSiteChangeHistoryListQuery, FirmaOperatingSiteChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FirmaOperatingSiteChangeHistoryListQuery, FirmaOperatingSiteChangeHistoryListQueryVariables>(
    FirmaOperatingSiteChangeHistoryListDocument,
    options
  );
}
export type FirmaOperatingSiteChangeHistoryListQueryHookResult = ReturnType<typeof useFirmaOperatingSiteChangeHistoryListQuery>;
export type FirmaOperatingSiteChangeHistoryListLazyQueryHookResult = ReturnType<typeof useFirmaOperatingSiteChangeHistoryListLazyQuery>;
export type FirmaOperatingSiteChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useFirmaOperatingSiteChangeHistoryListSuspenseQuery>;
export type FirmaOperatingSiteChangeHistoryListQueryResult = Apollo.QueryResult<
  FirmaOperatingSiteChangeHistoryListQuery,
  FirmaOperatingSiteChangeHistoryListQueryVariables
>;
