import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Box } from 'rebass';
import { Divider } from 'antd';
import { getPreviousTimeblockBefore, ITimeblock, useTimeline } from '../../../../../components/Timeline/timelineCtx';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { layoutTimelineForm } from '../../../../../components/Input-antd/formLayoutHelper';
import TimelineFormDatePicker from '../../../../../components/Timeline/Form/TimelineFormDatePicker';
import TimelineFormKommentar from '../../../../../components/Timeline/Form/TimelineFormKommentar';
import TimelineFormButtons from '../../../../../components/Timeline/Form/TimelineFormButtons';
import { dayjsCustom } from '../../../../../helpers/dayjsCustom';
import KontoFormSelect from '../../../../KontoSelect/KontoFormSelect';
import { FirmendatenPauschalmieteAufwandskonto, KontoKlasse } from '../../../../../types';
import {
  aufwandskontoVersionFormFields,
  AufwandskontoVersionFormValues,
  aufwandskontoVersionFormValuesDescriptionsForKommentar,
  mapFormValuesToPauschalmieteAufwandskontoVersion,
  mapTimeblockToFormValues,
} from './aufwandskontoVersionFormMapper';
import { useCreatePauschalmieteAufwandskontoMutation, useUpdatePauschalmieteAufwandskontoMutation } from '../../gql/PaulschalmieteMutations.types';
import FormItemWithFieldHelp from '../../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../../FieldHelp/useGetFieldHelpText';

type Props = {
  timeblock: ITimeblock<FirmendatenPauschalmieteAufwandskonto>;
  onSubmitSuccess: () => void;
};

const AufwandskontoVersionForm: FC<Props> = ({ timeblock, onSubmitSuccess }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'FirmendatenPauschalmieteAufwandskonto'>('FirmendatenPauschalmieteAufwandskonto');

  const { dataSource, toggleEdit, changeToUpdateMode } = useTimeline<FirmendatenPauschalmieteAufwandskonto>();
  const initialValues = mapTimeblockToFormValues(timeblock);
  const isUpdate = !!timeblock.firmendatenPauschalmieteAufwandskontoId;

  const entity = 'Pauschalmiete Aufwandskonto';

  const [runCreate, { loading: loadingCreate }] = useCreatePauschalmieteAufwandskontoMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      changeToUpdateMode(timeblock.uuid);
      onSubmitSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate, { loading: loadingUpdate }] = useUpdatePauschalmieteAufwandskontoMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      toggleEdit(timeblock.uuid);
      onSubmitSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<AufwandskontoVersionFormValues>
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        const input = mapFormValuesToPauschalmieteAufwandskontoVersion(values);
        if (isUpdate) {
          runUpdate({
            variables: {
              firmendatenPauschalmieteAufwandskontoId: timeblock.firmendatenPauschalmieteAufwandskontoId,
              input,
            },
          }).finally(() => setSubmitting(false));
        } else {
          runCreate({ variables: { input } }).finally(() => setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form {...layoutTimelineForm} layout="horizontal" labelAlign="left">
          <Box width={2 / 3}>
            <FormItemWithFieldHelp
              name={aufwandskontoVersionFormFields.validFrom}
              label="Gültig ab"
              fieldHelp={getFieldHelpText('FirmendatenPauschalmieteAufwandskonto.validFrom')}
            >
              <TimelineFormDatePicker<FirmendatenPauschalmieteAufwandskonto>
                id={aufwandskontoVersionFormFields.validFrom}
                name={aufwandskontoVersionFormFields.validFrom}
                timeblock={timeblock}
              />
            </FormItemWithFieldHelp>

            <FormItemWithFieldHelp
              name={aufwandskontoVersionFormFields.pauschalmieteAufwandskontoId}
              label="Aufwandskonto"
              fieldHelp={getFieldHelpText('FirmendatenPauschalmieteAufwandskonto.pauschalmieteAufwandskontoId')}
            >
              <KontoFormSelect
                name={aufwandskontoVersionFormFields.pauschalmieteAufwandskontoId}
                kontoKlasse={[
                  KontoKlasse.PersonalAufwand,
                  KontoKlasse.HandelswarenEinsatz,
                  KontoKlasse.BetrieblicherAufwand,
                  KontoKlasse.FinanzAufwand,
                ]}
              />
            </FormItemWithFieldHelp>
          </Box>

          <Divider style={{ padding: 0, marginTop: 0, marginBottom: '16px' }} />

          <TimelineFormKommentar
            name={aufwandskontoVersionFormFields.kommentar}
            timeblock={timeblock}
            valuesDescriptions={aufwandskontoVersionFormValuesDescriptionsForKommentar}
          />

          <TimelineFormButtons<FirmendatenPauschalmieteAufwandskonto>
            timeblock={timeblock}
            isSubmitting={formikProps.isSubmitting}
            isSameAsPreviousVersion={isSameAsPreviousVersion(
              formikProps.values,
              getPreviousTimeblockBefore(dataSource, dayjsCustom(formikProps.values.validFrom))
            )}
            submitBtnLoading={loadingCreate || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

const isSameAsPreviousVersion = (formValues: AufwandskontoVersionFormValues, previousTimeblock?: ITimeblock<FirmendatenPauschalmieteAufwandskonto>) =>
  formValues.pauschalmieteAufwandskontoId === previousTimeblock?.pauschalmieteAufwandskontoId;

export default AufwandskontoVersionForm;
