import { FormFields, mapFormDateValueToDateString } from '../../../../../helpers/formikHelper';
import { HeVertragAuszahlungCreateInput } from '../../../../../types';

export type ZahlungsverlaufFormValues = {
  zahlungsempfaengerId: string;
  zahlungsempfaengerBankDetailsId: string;
  zahlungsdatum: string | null;
  verwendungszweck?: string | null;
  zahlungsreferenz?: string | null;
  betrag?: number | null;
};

export const zahlungsverlaufFormFields: FormFields<ZahlungsverlaufFormValues> = {
  zahlungsempfaengerId: 'zahlungsempfaengerId',
  zahlungsempfaengerBankDetailsId: 'zahlungsempfaengerBankDetailsId',
  zahlungsdatum: 'zahlungsdatum',
  verwendungszweck: 'verwendungszweck',
  zahlungsreferenz: 'zahlungsreferenz',
  betrag: 'betrag',
};

export const zahlungsverlaufFormInitialValues = (vertragspartnerId?: string): ZahlungsverlaufFormValues => ({
  zahlungsempfaengerId: vertragspartnerId ?? '',
  zahlungsempfaengerBankDetailsId: '',
  zahlungsdatum: null,
  verwendungszweck: null,
  betrag: null,
});

export const mapFormValuesToZahlungsregel = (values: ZahlungsverlaufFormValues): HeVertragAuszahlungCreateInput => ({
  zahlungsempfaengerId: values.zahlungsempfaengerId,
  zahlungsempfaengerBankDetailsId: values.zahlungsempfaengerBankDetailsId,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  zahlungsdatum: mapFormDateValueToDateString(values.zahlungsdatum!),
  verwendungszweck: values.verwendungszweck,
  zahlungsreferenz: values.zahlungsreferenz,
  betrag: values.betrag,
});
