import React from 'react';
import { Link } from 'react-router-dom';
import { friendlyFormatIBAN } from 'ibantools';
import { Typography } from 'antd';
import { ReceivedBankTransactionVerbuchenGenerierlaufListEntry, ReceivedBankTransactionVerbuchenGenerierlaufOrderBy } from '../../../../types';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';
import { EuroAmount } from '../../../../components/Number';
import StatusBadge from '../../../../components/Status/StatusBadge';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../../../components/Card';
import { generatePathToBankstatementListingPage } from '../../../DataCarrier/Listing/Bankstatement/Filters/filtersQueryParams';
import { generatePathToPaymentPage } from '../../../Payment/Listing/Filters/filtersQueryParams';
import { isNotNil } from '../../../../helpers/assertionHelper';
import { NestedTableColProps } from '../../../../components/Table/NestedTable/NestedTable';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';
import ReceivedBankTransactionVerbuchenTableActions from './ReceivedBankTransactionVerbuchenTableActions';

const receivedBankTransactionVerbuchenTableColumns: NestedTableColProps<ReceivedBankTransactionVerbuchenGenerierlaufListEntry>[] = [
  {
    title: 'Datenträger',
    dataIndex: ReceivedBankTransactionVerbuchenGenerierlaufOrderBy.DataCarrierName,
    sorter: true,
    render: (text, record) => (
      <DataWithShortenedText maxTextLength={20} text={record.dataCarrierCamtName}>
        {(shortenedText) => (
          <Link
            target="_blank"
            to={generatePathToBankstatementListingPage({
              name: record.dataCarrierCamtName,
              statementNumber: record.statementNumber,
              iban: record.creditorIban,
            })}
          >
            {shortenedText}
          </Link>
        )}
      </DataWithShortenedText>
    ),
  },
  {
    title: 'Kontoauszugsnummer',
    dataIndex: ReceivedBankTransactionVerbuchenGenerierlaufOrderBy.StatementNumber,
    sorter: true,
    render: (text, record) => <Typography.Text>{record.statementNumber}</Typography.Text>,
  },
  {
    title: 'Transaktionsart',
    dataIndex: ReceivedBankTransactionVerbuchenGenerierlaufOrderBy.TransactionType,
    sorter: true,
    render: (text, record) => <Typography.Text>{record.transactionType.text}</Typography.Text>,
  },
  {
    title: 'Valutadatum',
    dataIndex: ReceivedBankTransactionVerbuchenGenerierlaufOrderBy.ValueDate,
    sorter: true,
    render: (text, record) => (record.valueDate ? <CustomFormattedDate value={record.valueDate} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Rücklastschrift',
    dataIndex: ReceivedBankTransactionVerbuchenGenerierlaufOrderBy.Reversal,
    sorter: true,
    render: (text, record) => <Typography.Text>{record.reversal ? 'Ja' : 'Nein'}</Typography.Text>,
  },
  {
    title: 'Kreditor',
    dataIndex: ReceivedBankTransactionVerbuchenGenerierlaufOrderBy.Creditor,
    sorter: true,
    render: (text, record) =>
      record.creditor ? <DataWithShortenedText maxTextLength={20} text={record.creditor} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Kreditor Bankverbindung',
    dataIndex: ReceivedBankTransactionVerbuchenGenerierlaufOrderBy.CreditorIban,
    sorter: true,
    render: (text, record) =>
      record.creditorIban ? (
        <DataWithShortenedText maxTextLength={20} text={`${friendlyFormatIBAN(record.creditorIban)}`} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Debitor',
    dataIndex: ReceivedBankTransactionVerbuchenGenerierlaufOrderBy.Debtor,
    sorter: true,
    render: (text, record) =>
      record.debtor ? <DataWithShortenedText maxTextLength={20} text={record.debtor} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Debitor Bankverbindung',
    dataIndex: ReceivedBankTransactionVerbuchenGenerierlaufOrderBy.DebtorIban,
    sorter: true,
    render: (text, record) =>
      record.debtorIban ? (
        <DataWithShortenedText maxTextLength={20} text={`${friendlyFormatIBAN(record.debtorIban)}`} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Betrag',
    dataIndex: ReceivedBankTransactionVerbuchenGenerierlaufOrderBy.Amount,
    sorter: true,
    align: 'right',
    render: (text, record) => <EuroAmount value={record.amount} />,
  },
  {
    title: 'Spesen',
    dataIndex: ReceivedBankTransactionVerbuchenGenerierlaufOrderBy.Charges,
    sorter: true,
    align: 'right',
    render: (text, record) => (record.charges ? <EuroAmount value={record.charges} /> : <TextForEmptyValue textToShow="minus" />),
  },
  {
    title: 'Spesen in eigener Bankbuchung',
    dataIndex: ReceivedBankTransactionVerbuchenGenerierlaufOrderBy.ChargesInSeparateEntry,
    sorter: true,
    render: (text, record) =>
      isNotNil(record.chargesInSeparateEntry) ? (
        <Typography.Text>{record.chargesInSeparateEntry ? 'Ja' : 'Nein'}</Typography.Text>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Verwendungszweck',
    dataIndex: ReceivedBankTransactionVerbuchenGenerierlaufOrderBy.PurposeOfUseText,
    sorter: true,
    render: (text, record) =>
      record.purposeOfUseText ? (
        <DataWithShortenedText maxTextLength={20} text={record.purposeOfUseText} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Zahlungsreferenz',
    dataIndex: ReceivedBankTransactionVerbuchenGenerierlaufOrderBy.PaymentReferenceText,
    sorter: true,
    render: (text, record) =>
      record.paymentReferenceText ? (
        <DataWithShortenedText maxTextLength={20} text={record.paymentReferenceText} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'Verbucht',
    dataIndex: ReceivedBankTransactionVerbuchenGenerierlaufOrderBy.Booked,
    sorter: true,
    render: (text, record) => <Typography.Text>{record.booked ? 'Ja' : 'Nein'}</Typography.Text>,
  },
  {
    title: 'Status',
    dataIndex: ReceivedBankTransactionVerbuchenGenerierlaufOrderBy.ReceivedBankTransactionStatus,
    sorter: true,
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },
  {
    title: 'Zugewiesene Zahlung',
    dataIndex: ReceivedBankTransactionVerbuchenGenerierlaufOrderBy.PaymentType,
    sorter: true,
    render: (_, record) => {
      if (record.paymentType) {
        const text =
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          `${record.paymentType!.text} - ${dayjsCustom(record.valueDate!).format('DD.MM.YYYY')}` +
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ` - ${record.buchungskreisRechtstraeger!.kurzBezeichnung} - ${record.payee!.kurzBezeichnung} - ${record.amount}€ `;

        return (
          <DataWithShortenedText maxTextLength={20} text={text}>
            {(shortenedText) => (
              <Link
                target="_blank"
                to={generatePathToPaymentPage({
                  rechnungsAusstellerId: record.buchungskreisRechtstraeger.rechtstraegerId,
                  payeeId: record.payee?.rechtstraegerId,
                  paymentTypeList: record.paymentType ? [record.paymentType.value] : undefined,
                  paymentDateFrom: record.paymentDate,
                  paymentDateTo: record.paymentDate,
                  amountFrom: record.amount,
                  amountTo: record.amount,
                })}
              >
                {shortenedText}
              </Link>
            )}
          </DataWithShortenedText>
        );
      }
      return <TextForEmptyValue textToShow="minus" />;
    },
  },
  {
    title: 'Erstellt am',
    dataIndex: ReceivedBankTransactionVerbuchenGenerierlaufOrderBy.CreateTs,
    sorter: true,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    dataIndex: ReceivedBankTransactionVerbuchenGenerierlaufOrderBy.CreatedBy,
    sorter: true,
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    render: (text, record) => <ReceivedBankTransactionVerbuchenTableActions record={record} />,
  },
];

export default receivedBankTransactionVerbuchenTableColumns;
