import React, { FC } from 'react';
import { Collapse, Typography } from 'antd';
import { TextbausteinType } from '../../../../types';
import { StyledCollapsePanel } from '../../../../features/Abrechnungsdefinition/styled/Collapse.styles';
import TextbausteinInputTextArea from './TextbausteinListElements/TextbausteinInputTextArea';
import { TextbausteinFormValues } from '../templateMapper';
import { TextbausteinCollapsePanelHeader } from './TextbausteinListElements/TextbausteinCollapsePanelHeader';
import { isTextbausteinVariantComplete } from './TextbausteinListElements/textbausteinListHelpers';

type TextbausteinListProps = {
  textbausteinList: TextbausteinFormValues[];
  onTemplateChange: (templateValues: TextbausteinFormValues[]) => void;
  onSelectTextbaustein: (value: TextbausteinType) => void;
  onChange: (textbausteinType: TextbausteinType, newValue: string) => void;
  level: number;
};

const TextbausteinList: FC<TextbausteinListProps> = ({ textbausteinList, onTemplateChange, onSelectTextbaustein, onChange, level }) => (
  <Collapse bordered ghost accordion>
    {textbausteinList
      .sort((a, b) => a.index - b.index)
      .map((textbaustein) => {
        if (textbaustein.textbausteinList && textbaustein.textbausteinList.length > 0) {
          return (
            <StyledCollapsePanel
              key={textbaustein.index}
              header={
                <TextbausteinCollapsePanelHeader
                  isTextbausteinComplete={isTextbausteinVariantComplete(textbaustein)}
                  title={textbaustein.type.text}
                  onClick={() => onSelectTextbaustein(textbaustein.type.value)}
                  isVariant={isVariant(level)}
                />
              }
              showArrow={false}
            >
              <Typography.Text type="secondary">Verfügbare Varianten</Typography.Text>
              <TextbausteinList
                textbausteinList={textbaustein.textbausteinList}
                onTemplateChange={onTemplateChange}
                onSelectTextbaustein={onSelectTextbaustein}
                onChange={onChange}
                level={level + 1}
              />
            </StyledCollapsePanel>
          );
        } else {
          return (
            <StyledCollapsePanel
              header={
                <TextbausteinCollapsePanelHeader
                  title={textbaustein?.type.text}
                  isTextbausteinComplete={isTextbausteinVariantComplete(textbaustein)}
                  onClick={() => onSelectTextbaustein(textbaustein.type.value)}
                  isVariant={isVariant(level)}
                />
              }
              key={textbaustein.index}
              showArrow={false}
            >
              <TextbausteinInputTextArea
                value={textbaustein.value}
                onChange={(newValue) => onChange(textbaustein.type.value, newValue)}
                platzhalterList={textbaustein.platzhalterList}
              />
            </StyledCollapsePanel>
          );
        }
      })}
  </Collapse>
);

const isVariant = (level: number) => level > 0;

export { TextbausteinList };
