import React, { FC } from 'react';
import { Button, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../hooks/useToggle';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../components/Button/ButtonsAligned';
import AufteilungsschluesselTemplateForm from './Form/AufteilungsschluesselTemplateForm';

type Props = {
  refetch: () => void;
  showArchived: boolean;
  toggleShowArchived: () => void;
};

const AufteilungschluesselTemplateActionButtonsAndDrawer: FC<Props> = ({ refetch, showArchived, toggleShowArchived }) => {
  const [isCollapsed, onCollapse] = useToggle();

  return (
    <>
      <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
        <Button onClick={toggleShowArchived}>Archivierte Einträge {showArchived ? 'ausblenden' : 'anzeigen'}</Button>
        <Button icon={<PlusOutlined />} type="primary" onClick={onCollapse}>
          Aufteilungsschlüssel anlegen
        </Button>
      </ButtonsAligned>
      <Drawer title="Aufteilungsschlüssel anlegen" width={720} onClose={onCollapse} open={isCollapsed} destroyOnClose>
        <AufteilungsschluesselTemplateForm
          onSuccess={() => {
            onCollapse();
            refetch();
          }}
          onCancel={onCollapse}
        />
      </Drawer>
    </>
  );
};

export default AufteilungschluesselTemplateActionButtonsAndDrawer;
