import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { OrderGenerierlaufType } from '../../../../../types';
import { useFilterGenLaufOrderRecipientEmailAddressListQuery } from '../../../gql/GenerierlaufFilter/OrderGenerierlaufFilterQueries.types';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { selectFilterOption } from '../../../../../helpers/selectHelper';

type Props = {
  name: string;
  generierlaufId: string;
  generierlaufType: OrderGenerierlaufType;
  maxTextLength?: number;
} & SelectProps;

const GenerierlaufRecipientEmailAddressFormSelect: FC<Props> = ({ name, generierlaufId, generierlaufType, maxTextLength, ...restProps }) => {
  const { data, loading } = useFilterGenLaufOrderRecipientEmailAddressListQuery({
    variables: {
      generierlaufId,
      generierlaufType,
    },
  });

  const list = data?.getFilterGenLaufOrderRecipientEmailAddressList.data ?? [];

  return (
    <Select
      size="small"
      loading={loading}
      id={name}
      name={name}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      placeholder="Empfänger-E-Mail-Adresse auswählen"
      {...restProps}
    >
      {list.map((email) => (
        <Select.Option key={email} value={email}>
          <DataWithShortenedText maxTextLength={maxTextLength ?? email.length} text={email} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default GenerierlaufRecipientEmailAddressFormSelect;
