import React, { FC } from 'react';
import { Col, Row, Space, Typography } from 'antd';
import StatusBadge from '../../../../components/Status/StatusBadge';
import { isStatusInvalid, isStatusReviewError, isStatusValid } from '../../../../helpers/statusHelper';
import { TopAbrechnung } from '../../../../types';

type Props = {
  topAbrechnungList: TopAbrechnung[];
};

const TopAbrechnungenStatus: FC<Props> = ({ topAbrechnungList }) => {
  return (
    <Row>
      <Col span={8}>
        <Typography.Text>Topabrechnungen</Typography.Text>
      </Col>
      <Col span="auto">{content(topAbrechnungList)}</Col>
    </Row>
  );
};

const content = (topAbrechnungList: TopAbrechnung[]) => {
  const isAnyTopAbrechnungStatusValid = topAbrechnungList.some((topAbrechnung) => isStatusValid(topAbrechnung.status));
  const isAnyTopAbrechnungStatusUnvalid = topAbrechnungList.some((topAbrechnung) => isStatusInvalid(topAbrechnung.status));
  const isSomeTopAbrechnungStatusReviewError = topAbrechnungList.some((topAbrechnung) => isStatusReviewError(topAbrechnung.status));

  return isAnyTopAbrechnungStatusValid && isAnyTopAbrechnungStatusUnvalid ? (
    validAndUnvalidTopAbrStatusBagdes(topAbrechnungList)
  ) : (
    <div>
      <StatusBadge entityStatus={topAbrechnungList[0].status} />
      {!isSomeTopAbrechnungStatusReviewError && <Typography.Text>&nbsp;({topAbrechnungList.length})</Typography.Text>}
    </div>
  );
};

const validAndUnvalidTopAbrStatusBagdes = (topAbrechnungList: TopAbrechnung[]) => {
  const valideTopAbr = topAbrechnungList.filter((topAbrechnung) => isStatusValid(topAbrechnung.status));
  const invalideTopAbr = topAbrechnungList.filter((topAbrechnung) => isStatusInvalid(topAbrechnung.status));
  return (
    <Space direction="vertical" size={4}>
      <div>
        <StatusBadge entityStatus={valideTopAbr[0].status} />
        <Typography.Text>&nbsp;({valideTopAbr.length})</Typography.Text>
      </div>
      <div>
        <StatusBadge entityStatus={invalideTopAbr[0].status} />
        <Typography.Text>&nbsp;({invalideTopAbr.length})</Typography.Text>
      </div>
    </Space>
  );
};

export default TopAbrechnungenStatus;
