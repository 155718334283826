import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useGenerierlaufEntryExitCodeListQuery } from '../../gql/GenerierlaufInfo/GenerierlaufInfoQueries.types';
import { capitalizeFirstLetter } from '../../../../helpers/stringHelper';

type GenerierlaufEntryExitCodeSelectProps = {
  name: string;
  width?: string;
} & SelectProps;

const GenerierlaufEntryExitCodeListSelect: FC<GenerierlaufEntryExitCodeSelectProps> = ({ name, width, ...restProps }) => {
  const { data, loading } = useGenerierlaufEntryExitCodeListQuery();

  const generierlaufStatus = data?.getGenerierlaufEntryExitCodeList.data ?? [];

  return (
    <Select
      size="small"
      loading={loading}
      id={name}
      name={name}
      placeholder="Exitcode auswählen"
      mode="multiple"
      maxTagCount="responsive"
      style={{ width: width || '100%' }}
      {...restProps}
    >
      {generierlaufStatus.map((status) => (
        <Select.Option key={status.value} value={status.value}>
          {capitalizeFirstLetter(status.text) + status.text.slice(1)}
        </Select.Option>
      ))}
    </Select>
  );
};

export default GenerierlaufEntryExitCodeListSelect;
