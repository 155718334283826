import React, { FC } from 'react';
import { Skeleton } from 'antd';
import WeAbrDefVersionCreateForm from '../../../../../../features/Abrechnungsdefinition/Version/Form/Create/WeAbrDefVersionCreateForm';
import {
  useWeAbrechnungsdefinitionVersionListQuery,
  useWeAbrechnungsdefinitionVersionQuery,
} from '../../../../../../features/Abrechnungsdefinition/gql/WeAbrDef/WeAbrDefVersionQueries.types';
import { useAbrechnungsdefinitionTemplateQuery } from '../../../../../../features/Abrechnungsdefinition/gql/AbrDefQueries.types';
import { AbrechnungsdefinitionTemplateType } from '../../../../../../types';
import { findPrecedingVersionId, getFirmendatenId, hasPrecedingVersion, isPrecedingVersionAvailable } from './createPageHelpers';

/*
When creating new version the forms have to be prefilled with the values of preceding version for the next available date from today
ex. 1: today: 01.01.2022., version 1: 01.01.2021. version 2: 01.01.2023 => preceding version: version 1
ex. 2: today: 01.01.2022., version 1: 01.01.2021. version 2: 02.01.2022 => preceding version: version 2
 */

type Props = {
  abrechnungsdefinitionId: string;
};
const SysSettingsWeAbrechnungsdefinitionVersionCreatePage: FC<Props> = ({ abrechnungsdefinitionId }) => {
  const { data: weAbrDefVersionListData } = useWeAbrechnungsdefinitionVersionListQuery({
    variables: { abrechnungsdefinitionId },
  });
  const versionList = weAbrDefVersionListData?.getWeAbrechnungsdefinitionVersionList.data;
  const precedingVersionId = versionList ? findPrecedingVersionId(versionList) : undefined;

  const { data: weAbrDefVersionData } = useWeAbrechnungsdefinitionVersionQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { abrechnungsdefinitionId, abrechnungsdefinitionVersionId: precedingVersionId! },
    skip: !precedingVersionId,
  });
  const precedingVersion = weAbrDefVersionData?.getWeAbrechnungsdefinitionVersion.data;

  const { data: templateObjektData } = useAbrechnungsdefinitionTemplateQuery({
    variables: { type: AbrechnungsdefinitionTemplateType.ObjektAbrechnung },
    skip: isPrecedingVersionAvailable(versionList, precedingVersion),
  });
  const objektAbrechnungTextbausteinList = templateObjektData?.getAbrechnungsdefinitionTemplate.data.textbausteinList;

  const { data: templateTopData } = useAbrechnungsdefinitionTemplateQuery({
    variables: { type: AbrechnungsdefinitionTemplateType.TopAbrechnung },
    skip: isPrecedingVersionAvailable(versionList, precedingVersion),
  });
  const topAbrechnungTextbausteinList = templateTopData?.getAbrechnungsdefinitionTemplate.data.textbausteinList;

  // Show loading state until initial data is available: if there is a precedingVersion we get the textbausteinLists from it otherwise we have to load default templates
  if (
    !versionList ||
    (hasPrecedingVersion(versionList) && !precedingVersion) ||
    (hasPrecedingVersion(versionList) === false && (!objektAbrechnungTextbausteinList || !topAbrechnungTextbausteinList))
  ) {
    return <Skeleton active />;
  }

  return (
    <WeAbrDefVersionCreateForm
      abrechnungsdefinitionVersionList={versionList}
      precedingAbrechnungsdefinitionVersion={precedingVersion}
      abrechnungsdefinitionId={abrechnungsdefinitionId}
      firmendatenId={getFirmendatenId(versionList)}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      objektAbrechnungTextbausteinList={precedingVersion?.objektAbrechnungTextbausteinList ?? objektAbrechnungTextbausteinList!}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      topAbrechnungTextbausteinList={precedingVersion?.topAbrechnungTextbausteinList ?? topAbrechnungTextbausteinList!}
    />
  );
};

export default SysSettingsWeAbrechnungsdefinitionVersionCreatePage;
