import React, { FC, useEffect, useState } from 'react';
import { ColumnProps } from 'antd/es/table';
import { useToggle } from '../../../hooks/useToggle';
import { useNestedTableWithColSelector } from './NestedTableWithColSelectorContext';
import ColumnSelector from '../TableWithColSelector/ColumnSelector';
import {
  ColumnSelectorSetting,
  getColumnTitle,
  getSelectedColumnTitles,
  getUniqueColumnTitles,
  TableWithColSelectorType,
} from '../TableWithColSelector/tableWithColSelectorHelpers';
import useUserColumnSettings from '../TableWithColSelector/useUserColumnSettings';
import { useDeleteColumnSettingsMutation } from '../../../features/Usability/ColumnSettings/gql/ColumnSettingsMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';

type Props = {
  filterIdentifier: string;
};

const NestedTableWithColSelectorColumnSelector: FC<Props> = ({ filterIdentifier }) => {
  const { state, dispatch } = useNestedTableWithColSelector();
  const [isColumnSelectorOpen, toggleIsColumnSelectorOpen] = useToggle();
  const [selectedColumnSelectorType, setSelectedColumnSelectorType] = useState<TableWithColSelectorType>('user');
  const { userColumnSettings, addOrUpdateUserColumnSettings, refetchColumnSettings, loadingColumnSettings } = useUserColumnSettings(filterIdentifier);
  const defaultSelectedColumns = state.allUniqueColumns.filter((col) => !!col.defaultSelected || !col.title);

  const [selectedUserColumns, setSelectedUserColumns] = useState<ColumnProps<any>[]>(defaultSelectedColumns);
  const [selectedSystemColumns, setSelectedSystemColumns] = useState<ColumnProps<any>[]>(defaultSelectedColumns);
  const selectedSystemColumnTitles = selectedSystemColumns.map((col) => getColumnTitle(col)).filter((col) => !!col);
  const selectedUserColumnTitles = selectedUserColumns.map((col) => getColumnTitle(col)).filter((col) => !!col);
  const [updateByUser, setUpdateByUser] = useState(false);

  const [runDelete] = useDeleteColumnSettingsMutation({
    variables: { filterIdentifier },
    onCompleted: () => refetchColumnSettings(),
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  // Apply default columns
  useEffect(() => {
    setSelectedSystemColumns(defaultSelectedColumns);
    setSelectedUserColumns(defaultSelectedColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.allLevels]);

  // Used to show initial loading state
  useEffect(() => {
    if (!state.isColumnSelectorLoaded && !loadingColumnSettings) {
      dispatch({ type: 'SET_COLUMN_SELECTOR_LOADED', payload: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingColumnSettings]);

  // Apply selected columns
  useEffect(() => {
    const selectedColumnTitles = selectedColumnSelectorType === 'user' ? selectedUserColumnTitles : selectedSystemColumnTitles;
    const selectedColumns = state.allUniqueColumns.filter((col) => selectedColumnTitles.includes(getColumnTitle(col)));
    dispatch({ type: 'UPDATE_SELECTED_COLUMNS_SHOW_IN_TABLE', payload: selectedColumns });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.allLevels, selectedColumnSelectorType, selectedSystemColumns, selectedUserColumns]);

  // Sync user column selector data with backend
  useEffect(() => {
    if (
      !userColumnSettings?.length ||
      // Avoid render glitches by skipping syncing with backend, if the user selection has changed and the change is done by the user
      updateByUser
    ) {
      setUpdateByUser(false);
      return;
    }

    // Contains only the selected columns from the saved settings
    const filteredColumnsBySavedSettings = state.allUniqueColumns.filter((col) =>
      getSelectedColumnTitles(userColumnSettings).includes(getColumnTitle(col))
    );

    // Contains every column except the ones from the saved settings (used to add default columns)
    const filteredColumnsNotInSavedSettings = defaultSelectedColumns.filter(
      (col) => !userColumnSettings.map((col2) => getColumnTitle(col2)).includes(getColumnTitle(col))
    );

    setSelectedUserColumns([...filteredColumnsBySavedSettings, ...filteredColumnsNotInSavedSettings]);
    dispatch({ type: 'UPDATE_SELECTED_COLUMNS_SHOW_IN_TABLE', payload: filteredColumnsBySavedSettings });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.allLevels, userColumnSettings]);

  const handleColumnSelectionChange = (columnSelectorOptions: ColumnSelectorSetting[]) => {
    const newSelectedColumnTitles = columnSelectorOptions.filter((title) => title.selected).map((title) => title.title);
    const titles = Array.from(new Set(newSelectedColumnTitles));
    const keys: React.Key[] = [];
    const filteredColumns = state.allUniqueColumns.filter((column) => {
      const res = titles.some((title) => title === getColumnTitle(column) || (column.key && keys.includes(column.key)));
      if (res && column.key && !keys.includes(column.key)) {
        keys.push(column.key);
      }
      return res;
    });

    const userColumnSettings = defaultSelectedColumns.filter(
      (col) => !columnSelectorOptions.map((col2) => getColumnTitle(col2)).includes(getColumnTitle(col))
    );

    if (selectedColumnSelectorType === 'user') {
      setUpdateByUser(true);
      setSelectedUserColumns(filteredColumns);
      addOrUpdateUserColumnSettings(JSON.stringify([...userColumnSettings, ...columnSelectorOptions]));
    } else {
      setSelectedSystemColumns(filteredColumns);
    }
  };

  const onRestoreDefault = () => {
    if (selectedColumnSelectorType === 'user') {
      runDelete();
      setSelectedUserColumns(defaultSelectedColumns);
    } else {
      setSelectedSystemColumns(defaultSelectedColumns);
    }
  };

  return (
    <ColumnSelector
      key={state.allColumnTitles.join()}
      isOpen={isColumnSelectorOpen}
      onOpenChange={toggleIsColumnSelectorOpen}
      selectedColumnSelectorType={selectedColumnSelectorType}
      // INFO: Just show columns with title in Selector
      allColumnTitles={state.allColumnTitles}
      defaultUserColumnsTitles={getUniqueColumnTitles(defaultSelectedColumns)}
      selectedUserColumnTitles={getUniqueColumnTitles(selectedUserColumns)}
      defaultSystemColumnsTitles={getUniqueColumnTitles(defaultSelectedColumns)}
      selectedSystemColumnTitles={getUniqueColumnTitles(selectedSystemColumns)}
      onChangeSelectorType={setSelectedColumnSelectorType}
      onSelectionChange={handleColumnSelectionChange}
      onRestoreDefault={onRestoreDefault}
    />
  );
};

export default NestedTableWithColSelectorColumnSelector;
