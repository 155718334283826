import React, { FC } from 'react';
import { Formik } from 'formik';
import { DatePicker, Form, Select } from 'formik-antd';
import { Col, Divider, Row, Typography } from 'antd';
import { Dayjs } from 'dayjs';
import { vertragCreateFormValidationSchema } from './vertragCreateFormValidationSchema';
import VertragVerrechnungsartSelect from '../VertragVerrechnungsartSelect';
import VertragPaymentMethodSelect from '../VertragPaymentMethodSelect';
import RechtstraegerBankDetailsSelect from '../../../BankDetails/Select/RechtstraegerBankDetailsSelect';
import FormButtons from '../../../../components/Button/FormButtons';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../components/DatePicker/DatePicker_formikAntD';
import { useCreateBeVertragMutation } from '../gql/BeVertragMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { mapFormValuesToVertrag, vertragCreateFormFields, vertragCreateFormInitialValues, VertragCreateFormValues } from './vertragCreateFormMapper';
import { showSuccessMsgCreateWithPath } from '../../../../components/message/message';
import { generatePathToBeVertragDetailsPage } from '../beVertragUriPaths';
import RechtstraegerSelect from '../../../../shared/Rechtstraeger/RechtstraegerSelect';
import { Vertragsart, Verwaltungsart } from '../../../../types';
import { isVertragsartTypeLeerstehung, isVertragsartTypeWegLeerstehung } from '../../shared/vertragsartHelpers';
import VertragVertragsartFormSelect from '../../shared/VertragVertragsartFormSelect';
import { getRechnungsAusstellerSubadministration } from '../../shared/getRechnungsAusstellerSubadministration';
import { useFDAuthorized } from '../../../../shared/Auth/useAuthorized';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  objektId: string;
  bestandseinheitId: string;
  objektVerwaltungsart: Verwaltungsart;
  rechnungsausstellerId: string;
};

const VertragCreateForm: FC<Props> = ({ onCancel, objektId, bestandseinheitId, onSuccess, objektVerwaltungsart, rechnungsausstellerId }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'BeVertrag'>('BeVertrag');

  const entity = 'Vertrag';
  const { activeForFirmendatenId } = useFDAuthorized();

  const [runCreate, { loading: loadingCreate }] = useCreateBeVertragMutation({
    onCompleted: (data) => {
      const { vertragId, vertragspartner } = data.createBeVertrag.data;
      showSuccessMsgCreateWithPath(
        entity,
        `für ${vertragspartner.kurzBezeichnung}`,
        generatePathToBeVertragDetailsPage(objektId, bestandseinheitId, vertragId)
      );
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <>
      <Formik<VertragCreateFormValues>
        initialValues={vertragCreateFormInitialValues}
        validationSchema={vertragCreateFormValidationSchema}
        onSubmit={(values, formikHelpers) => {
          runCreate({
            variables: {
              objektId,
              bestandseinheitId,
              vertragInput: mapFormValuesToVertrag(values),
            },
          }).finally(() => formikHelpers.setSubmitting(false));
        }}
      >
        {(formikProps) => (
          <Form layout="vertical">
            <Row align="top">
              <Col span={6}>
                <Typography.Title level={5}>Vertrag</Typography.Title>
              </Col>
              <Col span={18}>
                <Row>
                  <Col span={24}>
                    <FormItemWithFieldHelp
                      name={vertragCreateFormFields.vertragspartnerId}
                      label="Vertragspartner"
                      fieldHelp={getFieldHelpText('BeVertrag.vertragspartnerId')}
                    >
                      <RechtstraegerSelect
                        id={vertragCreateFormFields.vertragspartnerId}
                        name={vertragCreateFormFields.vertragspartnerId}
                        placeholder="Vertragspartner auswählen"
                        onNewItemCreated={(rechtstraegerId) => formikProps.setFieldValue(vertragCreateFormFields.vertragspartnerId, rechtstraegerId)}
                        onNewItemCreateSelected={() => formikProps.setFieldValue(vertragCreateFormFields.vertragspartnerId, '')}
                        disabled={isLeerstehung(formikProps.values.vertragsart)}
                      />
                    </FormItemWithFieldHelp>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <FormItemWithFieldHelp
                      name={vertragCreateFormFields.vertragsart}
                      label="Vertragsart"
                      fieldHelp={getFieldHelpText('BeVertrag.vertragsart')}
                    >
                      <VertragVertragsartFormSelect
                        name={vertragCreateFormFields.vertragsart}
                        verwaltungsart={objektVerwaltungsart}
                        onChange={(vertragsart, supportsExplicitVertragsEnde) => {
                          formikProps.setFieldValue(vertragCreateFormFields.vertragsEnde, null);
                          formikProps.setFieldValue(vertragCreateFormFields.supportsExplicitVertragsEnde, supportsExplicitVertragsEnde);
                          if (isVertragsartTypeLeerstehung(vertragsart)) {
                            formikProps.setFieldValue(vertragCreateFormFields.bankDetailsId, null);
                            formikProps.setFieldValue(vertragCreateFormFields.vertragspartnerId, rechnungsausstellerId);
                          }
                          if (isVertragsartTypeWegLeerstehung(vertragsart)) {
                            formikProps.setFieldValue(vertragCreateFormFields.bankDetailsId, null);
                            getRechnungsAusstellerSubadministration(
                              activeForFirmendatenId,
                              objektId,
                              bestandseinheitId,
                              (rechtstraegerId) => {
                                formikProps.setFieldValue(vertragCreateFormFields.vertragspartnerId, rechtstraegerId);
                              },
                              vertragsart,
                              formikProps.values.vertragsBeginn
                            );
                          }
                        }}
                        objektId={objektId}
                        bestandseinheitId={bestandseinheitId}
                      />
                    </FormItemWithFieldHelp>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={formikProps.values.supportsExplicitVertragsEnde ? 12 : 24}>
                    <FormItemWithFieldHelp
                      name={vertragCreateFormFields.vertragsBeginn}
                      label="Vertragsbeginn"
                      fieldHelp={getFieldHelpText('BeVertrag.vertragsBeginn')}
                    >
                      <DatePicker
                        id={vertragCreateFormFields.vertragsBeginn}
                        name={vertragCreateFormFields.vertragsBeginn}
                        style={{ width: '100%' }}
                        format={DATE_DISPLAY_FORMAT_DEFAULT}
                        size="small"
                        onChange={(date) => {
                          if (isVertragsartTypeWegLeerstehung(formikProps.values.vertragsart)) {
                            getRechnungsAusstellerSubadministration(
                              activeForFirmendatenId,
                              objektId,
                              bestandseinheitId,
                              (rechtstraegerId) => {
                                formikProps.setFieldValue(vertragCreateFormFields.vertragspartnerId, rechtstraegerId);
                              },
                              formikProps.values.vertragsart,
                              date as unknown as Dayjs
                            );
                          }
                        }}
                      />
                    </FormItemWithFieldHelp>
                  </Col>
                  {formikProps.values.supportsExplicitVertragsEnde && (
                    <Col span={12}>
                      <FormItemWithFieldHelp
                        name={vertragCreateFormFields.vertragsEnde}
                        label="Vertragsende"
                        fieldHelp={getFieldHelpText('BeVertrag.vertragsEnde')}
                      >
                        <DatePicker
                          id={vertragCreateFormFields.vertragsEnde}
                          name={vertragCreateFormFields.vertragsEnde}
                          style={{ width: '100%' }}
                          format={DATE_DISPLAY_FORMAT_DEFAULT}
                          size="small"
                        />
                      </FormItemWithFieldHelp>
                    </Col>
                  )}
                  <Col span={12}>
                    <FormItemWithFieldHelp
                      name={vertragCreateFormFields.verrechnungsart}
                      label="Verrechnungsart"
                      fieldHelp={getFieldHelpText('BeVertrag.verrechnungsart')}
                    >
                      <VertragVerrechnungsartSelect
                        id={vertragCreateFormFields.verrechnungsart}
                        name={vertragCreateFormFields.verrechnungsart}
                        size="small"
                        disabled={isLeerstehung(formikProps.values.vertragsart)}
                      />
                    </FormItemWithFieldHelp>
                  </Col>
                </Row>
                <Row align="top">
                  <Col span={24}>
                    <FormItemWithFieldHelp label="Merkmale" name={vertragCreateFormFields.tagList} fieldHelp={getFieldHelpText('BeVertrag.tagList')}>
                      <Select id={vertragCreateFormFields.tagList} name={vertragCreateFormFields.tagList} mode="tags" placeholder="z.B. WE, BK" />
                    </FormItemWithFieldHelp>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider />
            <Row align="top">
              <Col span={6}>
                <Typography.Title level={5}>Zahlungsdetails</Typography.Title>
              </Col>
              <Col span={18}>
                <Row gutter={16}>
                  <Col span={7}>
                    <FormItemWithFieldHelp
                      name={vertragCreateFormFields.paymentMethod}
                      label="Zahlungsart"
                      fieldHelp={getFieldHelpText('BeVertrag.paymentMethod')}
                    >
                      <VertragPaymentMethodSelect
                        id={vertragCreateFormFields.paymentMethod}
                        name={vertragCreateFormFields.paymentMethod}
                        size="small"
                        disabled={isLeerstehung(formikProps.values.vertragsart)}
                      />
                    </FormItemWithFieldHelp>
                  </Col>
                  <Col span={17}>
                    <FormItemWithFieldHelp
                      name={vertragCreateFormFields.bankDetailsId}
                      label="Bankverbindung"
                      fieldHelp={getFieldHelpText('BeVertrag.bankDetailsId')}
                    >
                      <RechtstraegerBankDetailsSelect
                        name={vertragCreateFormFields.bankDetailsId}
                        onHasOnlyOneBankDetails={(id) => {
                          !isLeerstehung(formikProps.values.vertragsart) && formikProps.setFieldValue(vertragCreateFormFields.bankDetailsId, id);
                        }}
                        rechtstraegerId={formikProps.values.vertragspartnerId}
                        onNewItemCreateSelected={() => formikProps.setFieldValue(vertragCreateFormFields.bankDetailsId, '')}
                        disabled={isLeerstehung(formikProps.values.vertragsart)}
                      />
                    </FormItemWithFieldHelp>
                  </Col>
                </Row>
              </Col>
            </Row>
            <FormButtons isOkLoading={loadingCreate} isOkDisabled={formikProps.isSubmitting} onCancel={onCancel} updateMode={false} />
          </Form>
        )}
      </Formik>
    </>
  );
};

const isLeerstehung = (vertragsart: Vertragsart) => isVertragsartTypeLeerstehung(vertragsart) || isVertragsartTypeWegLeerstehung(vertragsart);

export default VertragCreateForm;
