import { FormFields } from '../../../../../helpers/formikHelper';
import { GenerierlaufEntryExitCode } from '../../../../../types';
import { BudgetingVerarbeitungQueryParams } from './filtersQueryParams';

export type BudgetingVerarbeitungEntryFiltersFormValues = {
  exitCodeList?: GenerierlaufEntryExitCode[];
  objekt?: string;
};

export const budgetingVerarbeitungEntryFiltersFormFields: FormFields<BudgetingVerarbeitungEntryFiltersFormValues> = {
  exitCodeList: 'exitCodeList',
  objekt: 'objekt',
};

export const budgetingVerarbeitungEntryFiltersFormInitialValues = (
  queryParams: BudgetingVerarbeitungQueryParams
): BudgetingVerarbeitungEntryFiltersFormValues => ({
  exitCodeList: queryParams.exitCodeList,
  objekt: queryParams.objekt,
});

export const mapFormValuesToBudgetingVerarbeitungFilters = (
  formValues: BudgetingVerarbeitungEntryFiltersFormValues
): BudgetingVerarbeitungQueryParams => ({
  exitCodeList: formValues.exitCodeList,
  objekt: formValues.objekt,
});
