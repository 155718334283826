import { FC } from 'react';
import { Formik } from 'formik';
import { RechtstraegerInfoFeldListingFiltersFormValues } from './rechtstraegerInfoFeldListingFiltersFormMapper';
import RechtstraegerInfoFeldListingForm from './RechtstraegerInfoFeldListingForm';

type RechtstraegerInfoFeldListingProps = {
  rechtstraegerId: string;
};

const RechtstraegerInfoFeldListing: FC<RechtstraegerInfoFeldListingProps> = ({ rechtstraegerId }) => (
  <Formik<RechtstraegerInfoFeldListingFiltersFormValues> initialValues={{ type: undefined }} onSubmit={() => {}}>
    {(formikProps) => <RechtstraegerInfoFeldListingForm formikProps={formikProps} rechtstraegerId={rechtstraegerId} />}
  </Formik>
);

export default RechtstraegerInfoFeldListing;
