import React, { FC } from 'react';
import { FormikProps } from 'formik';
import FiltersWith4OrMoreFields from '../../../../../components/Filters/FiltersWith4OrMoreFields';
import FiltersSelect from '../../../../../components/Filters/FiltersSelect';
import { onFilterSelectChange } from '../../../../../components/Filters/FiltersHelpers';
import { filtersFormFields, FiltersFormValues, listingLabelList } from './filtersFormMapper';
import { TBankstatementQueryParams } from './filtersQueryParams';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import CamtNameListFormSelect from './CamtNameListFormSelect';
import PaymentTransactionTypeFormSelect from '../../../../Payment/shared/PaymentTransactionTypeFormSelect';
import FromToFormDatePicker from '../../../../shared/FromToForm/FromToFormDatePicker';
import MitarbeiterSelect from '../../../../../shared/components/MitarbeiterSelect/MitarbeiterSelect';
import CamtStatementNumberListFormSelect from './CamtStatementNumberListFormSelect';
import BankstatementBtnAndModal from '../BankstatementBtnAndModal';
import IbanListFormSelect from './IbanListFormSelect';
import useFilterWithQueryParams from '../../../../shared/useFilterWithQueryParams';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
  queryParams: TBankstatementQueryParams;
  onAction: () => void;
};

const ListingFilters: FC<Props> = ({ formikProps, queryParams, onAction }) => {
  const defaultSelectedFilterList = [
    filtersFormFields.name,
    filtersFormFields.statementNumber,
    filtersFormFields.paymentTransactionType,
    filtersFormFields.iban,
  ];

  const mapFilterLabelToQueryFilterList = (filter: string) => {
    if (filter === filtersFormFields.createTsFrom || filter === filtersFormFields.createTsTo) {
      return filtersFormFields.createTsFrom;
    }
    return filter;
  };

  const filters = (filter: string) => {
    if (filter === filtersFormFields.iban) {
      return (
        <FormItemWithoutColon name={filtersFormFields.iban} label={listingLabelList.iban} key={filter}>
          <IbanListFormSelect name={filtersFormFields.iban} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.name) {
      return (
        <FormItemWithoutColon name={filtersFormFields.name} label={listingLabelList.name} key={filter}>
          <CamtNameListFormSelect name={filtersFormFields.name} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.paymentTransactionType) {
      return (
        <FormItemWithoutColon name={filtersFormFields.paymentTransactionType} label={listingLabelList.paymentTransactionType} key={filter}>
          <PaymentTransactionTypeFormSelect name={filtersFormFields.paymentTransactionType} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.createTsFrom) {
      return (
        <FormItemWithoutColon name="erstelltAm" label={listingLabelList.createTsFrom} key={filter}>
          <FromToFormDatePicker
            format="day"
            fromName={filtersFormFields.createTsFrom}
            toName={filtersFormFields.createTsTo}
            fromOnChange={formikProps.submitForm}
            toOnChange={formikProps.submitForm}
          />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.createdByMitarbeiterIdList) {
      return (
        <FormItemWithoutColon name={filtersFormFields.createdByMitarbeiterIdList} label={listingLabelList.createdByMitarbeiterIdList} key={filter}>
          <MitarbeiterSelect name={filtersFormFields.createdByMitarbeiterIdList} onChange={formikProps.submitForm} mode="multiple" />
        </FormItemWithoutColon>
      );
    }
    if (filter === filtersFormFields.statementNumber) {
      return (
        <FormItemWithoutColon name={filtersFormFields.statementNumber} label={listingLabelList.statementNumber} key={filter}>
          <CamtStatementNumberListFormSelect name={filtersFormFields.statementNumber} onChange={formikProps.submitForm} />
        </FormItemWithoutColon>
      );
    }
    return undefined;
  };

  const updateFilterValues = (selectedFilterList: string[]) => {
    if (!selectedFilterList.includes(filtersFormFields.createTsFrom) && formikProps.values.createTsTo) {
      formikProps.setFieldValue(filtersFormFields.createTsTo, undefined);
    }
    formikProps.submitForm();
  };

  const { selectedFilterList, setSelectedFilterList, leftColumn, rightColumn } = useFilterWithQueryParams({
    defaultSelectedFilterList,
    updateFilterValues,
    filters,
    labelList: listingLabelList,
    mapFilterLabelToQueryFilterList,
    queryParams,
  });

  return (
    <FiltersWith4OrMoreFields
      actionButtons={<BankstatementBtnAndModal onAction={onAction} />}
      filtersSelect={
        <FiltersSelect
          defaultSelectedFilterList={defaultSelectedFilterList}
          selectedFilterList={selectedFilterList}
          onChange={(value) => onFilterSelectChange(formikProps, selectedFilterList, value, setSelectedFilterList)}
          labelList={listingLabelList}
        />
      }
      hideTitle
      leftColumn={<>{leftColumn}</>}
      rightColumn={<>{rightColumn}</>}
    />
  );
};

export default ListingFilters;
