import React, { FC } from 'react';

export type Props = {
  size: number | string;
};

const FileSize: FC<Props> = ({ size }) => {
  const sizeNumber = ((n) => (Number.isNaN(n) ? 0 : n))(Number(size));

  const formatSize = (): string => {
    if (sizeNumber < 1024) return `${sizeNumber} Bytes`;

    const kbSize = sizeNumber / 1024;
    if (kbSize < 1024) return `${kbSize.toFixed(2)} KB`;

    const mbSize = kbSize / 1024;
    if (mbSize < 1024) return `${mbSize.toFixed(2)} MB`;

    const gbSize = mbSize / 1024;
    return `${gbSize.toFixed(2)} GB`;
  };

  return <span>{formatSize()}</span>;
};

export default FileSize;
