import { useParams } from 'react-router-dom';
import { DataCarrierPainCreationPageRouteParams } from './routes';
import { useDataCarrierPainCreationGenerierlaufQuery } from '../../../features/Verarbeitung/gql/DataCarrierPainCreationGenerierlauf/DataCarrierPainCreationGenerierlaufQueries.types';
import { DataCarrierPainCreationGenerierlauf } from '../../../types';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import menuListDataCarrierPainCreation from './menuListDataCarrierPainCreation';

const DataCarrierPainCreationDetailsPage = () => {
  const { generierlaufId } = useParams() as DataCarrierPainCreationPageRouteParams;

  const { data } = useDataCarrierPainCreationGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getDataCarrierPainCreationGenerierlauf.data;

  return (
    <DetailsPageTemplate<DataCarrierPainCreationGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.DataCarrierPainCreation}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard generierlauf={generierlauf} cardTitle={verarbeitungDetailsCardTitles.DataCarrierPainCreation} />
      )}
      isLoading={false}
      sidebarMenuList={menuListDataCarrierPainCreation}
    />
  );
};

export default DataCarrierPainCreationDetailsPage;
