import React, { FC } from 'react';
import erloesKontoTableColumns from './erloesKontoTableColumns';
import { ErrechnetesErloesKonto } from '../../../../types';
import NestedTableWithColSelector from '../../../../components/Table/NestedTableWithColSelector/NestedTableWithColSelector';

type Props = {
  erloesKontoList: ErrechnetesErloesKonto[];
  parentRowKeys?: string[];
  visible?: boolean;
};

const ErloesKontoTable: FC<Props> = ({ erloesKontoList, visible, parentRowKeys }) => {
  const getRowKey = (record: ErrechnetesErloesKonto) => record.erloeskonto;

  return (
    <NestedTableWithColSelector<ErrechnetesErloesKonto>
      parentRowKeys={parentRowKeys}
      visible={visible}
      level={2}
      size="middle"
      dataSource={erloesKontoList}
      columns={erloesKontoTableColumns}
      // TODO: is not unique
      rowKey={getRowKey}
      useHighlighting
    />
  );
};

export default ErloesKontoTable;
