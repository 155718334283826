import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useKuendigungsfristJeweilsZumListQuery } from './gql/KuendigungsfristJeweilsZumQueries.types';

type KuendigungsfristJeweilsZumProps = SelectProps;

const KuendigungsfristJeweilsZumSelect: FC<KuendigungsfristJeweilsZumProps> = ({ name, ...restProps }) => {
  const { data, loading } = useKuendigungsfristJeweilsZumListQuery();

  const kuendigungsfristJeweilsZumList = data?.getKuendigungsfristJeweilsZumList.data ?? [];

  return (
    <Select name={name} loading={loading} id={name} allowClear size="small" style={{ minWidth: '160px' }} {...restProps}>
      {kuendigungsfristJeweilsZumList.map((kuendigungsfristJeweilsZum) => (
        <Select.Option value={kuendigungsfristJeweilsZum.value} key={kuendigungsfristJeweilsZum.value}>
          {kuendigungsfristJeweilsZum.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default KuendigungsfristJeweilsZumSelect;
