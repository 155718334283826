import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import { Dayjs } from 'dayjs';
import { Select, SelectProps } from 'formik-antd';
import { useMitarbeiterTicketCountListQuery } from './gql/MitarbeiterSelectWithTicketCountQueries.types';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { mapFormDateValueToDateString } from '../../../helpers/formikHelper';
import { generatePathToTicketPage } from '../ticketUriPaths';
import { MitarbeiterBase } from '../../../types';
import { displayTicketCount } from './chooseAssigneeHelper';

type Props = {
  name: string;
  dueDate?: string | Dayjs | null;
  onMitarbeiterChange?: (value: MitarbeiterBase | undefined) => void;
} & SelectProps;

const MitarbeiterSelectWithTicketCount: FC<Props> = ({ name, dueDate, onMitarbeiterChange, ...restProps }) => {
  const { activeForFirmendatenId } = useFDAuthorized();

  const { loading, data } = useMitarbeiterTicketCountListQuery({
    variables: { firmendatenId: activeForFirmendatenId, dueDate: dueDate ? mapFormDateValueToDateString(dueDate) : dueDate },
  });

  const mitarbeiterList = data?.getMitarbeiterTicketCountList.data ?? [];

  return (
    <Select
      size="small"
      name={name}
      id={name}
      loading={loading}
      placeholder="Ersteller auswählen"
      allowClear
      style={{ minWidth: '200px', height: 22 }}
      showSearch
      filterOption={(input, option) => {
        const mitarbeiter = mitarbeiterList.find((entry) => entry.mitarbeiter.mitarbeiterId === option?.value);
        if (!mitarbeiter) return false;
        const mitarbeiterName = `${mitarbeiter.mitarbeiter.firstname}, ${mitarbeiter.mitarbeiter.lastname}`;
        const textValueWithoutSpaces = mitarbeiterName.replace(/\s/g, '');
        const inputWithoutSpaces = input.replace(/\s/g, '');
        return textValueWithoutSpaces.toLowerCase().includes(inputWithoutSpaces.toLowerCase());
      }}
      {...restProps}
      mode={undefined} // do not allow multiple selection or tags
      onChange={(value) => {
        const selected = mitarbeiterList.find((mitarbeiter) => mitarbeiter.mitarbeiter.mitarbeiterId === value);
        onMitarbeiterChange?.(selected?.mitarbeiter);
      }}
    >
      {mitarbeiterList.map(({ mitarbeiter, ticketCount }) => (
        <Select.Option key={mitarbeiter.mitarbeiterId} value={mitarbeiter.mitarbeiterId}>
          <OptionContent
            mitarbeiter={mitarbeiter}
            ticketCount={ticketCount}
            link={generatePathToTicketPage({
              dueDateFrom: dueDate ? mapFormDateValueToDateString(dueDate) : dueDate,
              dueDateTo: dueDate ? mapFormDateValueToDateString(dueDate) : dueDate,
              assigneeMitarbeiterId: [mitarbeiter.mitarbeiterId],
              searchInSubTickets: true,
            })}
          />
        </Select.Option>
      ))}
    </Select>
  );
};

const OptionContent: FC<{ mitarbeiter: MitarbeiterBase; ticketCount: number | undefined | null; link: string }> = ({
  mitarbeiter,
  ticketCount,
  link,
}) => {
  return (
    <Space size={4}>
      {`${mitarbeiter.firstname}, ${mitarbeiter.lastname}`}
      {ticketCount ? (
        <>
          <>-</>
          <Link to={link} target="_blank">
            {displayTicketCount(ticketCount)}
          </Link>
        </>
      ) : null}
    </Space>
  );
};

export default MitarbeiterSelectWithTicketCount;
