import * as Yup from 'yup';
import { entityIsRequired, entityMaxCharLength } from '../../../components/message/validationMsg';

/* eslint-disable no-template-curly-in-string */
export const newEmailLengthValidationSchema = Yup.string().max(100, entityMaxCharLength('E-Mail-Adresse', 100));

export const newEmailValidationSchema = Yup.string()
  .required(entityIsRequired('E-Mail-Adresse'))
  // @ts-ignore
  .concat(newEmailLengthValidationSchema);

/* eslint-enable no-template-curly-in-string */
