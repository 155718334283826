import * as Yup from 'yup';
import { entityHasToBeANumber, entityHasToBeNonNegative, entityIsRequired, entityMaxCharLength } from '../../../components/message/validationMsg';
import { mahndefinitionParamFormFields, mahngebuehrenFormFields, mahnstufenFormFields } from '../mahndefinitionFormMapper';
import { formattedDecimalType } from '../../../components/Input-antd';

/* eslint-disable no-template-curly-in-string */
export const mahndefinitionParamFormValidationSchema = Yup.object().shape({
  [mahndefinitionParamFormFields.bezeichnung]: Yup.string()
    .required(entityIsRequired('Bezeichnung'))
    .max(100, entityMaxCharLength('Bezeichnung', 100)),
  [mahndefinitionParamFormFields.verzugszinsen]: formattedDecimalType()
    .required(entityIsRequired('Verzugszinsen'))
    .min(0, entityHasToBeNonNegative('Verzugszinsen')),
  [mahndefinitionParamFormFields.mahnfreieTage]: Yup.number()
    .typeError(entityHasToBeANumber('Mahnfreie Tage'))
    .required(entityIsRequired('Mahnfreie Tage'))
    .min(0, entityHasToBeNonNegative('Mahnfreie Tage')),
  [mahndefinitionParamFormFields.mahnstufeList]: Yup.array().of(
    Yup.object().shape({
      [mahnstufenFormFields.minBetrag]: formattedDecimalType()
        .required(entityIsRequired('Mindestbetrag'))
        .min(0, entityHasToBeNonNegative('Mindestbetrag')),
      [mahnstufenFormFields.naechsteMahnungNachTage]: Yup.number()
        .typeError(entityHasToBeANumber('Naechste Mahnung'))
        .required(entityIsRequired('Naechste Mahnung'))
        .min(0, entityHasToBeNonNegative('Naechste Mahnung')),
      [mahnstufenFormFields.mahngebuehrList]: Yup.array().of(
        Yup.object().shape({
          [mahngebuehrenFormFields.ab]: formattedDecimalType().required('Pflichtfeld'),
          [mahngebuehrenFormFields.betrag]: formattedDecimalType().required('Pflichtfeld'),
        })
      ),
    })
  ),
});

/* eslint-enable no-template-curly-in-string */
