import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateHeVertragZahlungsregelMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  heVertragId: Types.Scalars['ID']['input'];
  input: Types.HeVertragZahlungsregelCreateInput;
}>;

export type CreateHeVertragZahlungsregelMutation = {
  createHeVertragZahlungsregel: {
    data: { zahlungsregelId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateHeVertragZahlungsregelMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  heVertragId: Types.Scalars['String']['input'];
  zahlungsregelId: Types.Scalars['ID']['input'];
  input: Types.HeVertragZahlungsregelUpdateInput;
}>;

export type UpdateHeVertragZahlungsregelMutation = {
  updateHeVertragZahlungsregel: {
    data: { zahlungsregelId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteHeVertragZahlungsregelMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  heVertragId: Types.Scalars['String']['input'];
  zahlungsregelId: Types.Scalars['ID']['input'];
}>;

export type DeleteHeVertragZahlungsregelMutation = {
  deleteHeVertragZahlungsregel: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type ArchiveHeVertragZahlungsregelMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  heVertragId: Types.Scalars['String']['input'];
  zahlungsregelId: Types.Scalars['ID']['input'];
}>;

export type ArchiveHeVertragZahlungsregelMutation = {
  actionArchiveHeVertragZahlungsregel: {
    data: { zahlungsregelId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveHeVertragZahlungsregelMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  heVertragId: Types.Scalars['String']['input'];
  zahlungsregelId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveHeVertragZahlungsregelMutation = {
  actionUnarchiveHeVertragZahlungsregel: {
    data: { zahlungsregelId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type CalculateHeVertragZahlungsregelSumVposNettoMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  input: Types.HeVertragZahlungsregelSumVposNettoInput;
}>;

export type CalculateHeVertragZahlungsregelSumVposNettoMutation = {
  calculateHeVertragZahlungsregelSumVposNetto: {
    data: { vorschreibungSumme: number; betrag: number };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateHeVertragZahlungsregelDocument = gql`
  mutation CreateHeVertragZahlungsregel($objektId: String!, $heVertragId: ID!, $input: HeVertragZahlungsregelCreateInput!) {
    createHeVertragZahlungsregel(objektId: $objektId, heVertragId: $heVertragId, input: $input) {
      data {
        zahlungsregelId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateHeVertragZahlungsregelMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateHeVertragZahlungsregelMutation, CreateHeVertragZahlungsregelMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateHeVertragZahlungsregelMutation, CreateHeVertragZahlungsregelMutationVariables>(
    CreateHeVertragZahlungsregelDocument,
    options
  );
}
export type CreateHeVertragZahlungsregelMutationHookResult = ReturnType<typeof useCreateHeVertragZahlungsregelMutation>;
export type CreateHeVertragZahlungsregelMutationResult = Apollo.MutationResult<CreateHeVertragZahlungsregelMutation>;
export type CreateHeVertragZahlungsregelMutationOptions = Apollo.BaseMutationOptions<
  CreateHeVertragZahlungsregelMutation,
  CreateHeVertragZahlungsregelMutationVariables
>;
export const UpdateHeVertragZahlungsregelDocument = gql`
  mutation UpdateHeVertragZahlungsregel(
    $objektId: String!
    $heVertragId: String!
    $zahlungsregelId: ID!
    $input: HeVertragZahlungsregelUpdateInput!
  ) {
    updateHeVertragZahlungsregel(objektId: $objektId, heVertragId: $heVertragId, zahlungsregelId: $zahlungsregelId, input: $input) {
      data {
        zahlungsregelId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateHeVertragZahlungsregelMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateHeVertragZahlungsregelMutation, UpdateHeVertragZahlungsregelMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateHeVertragZahlungsregelMutation, UpdateHeVertragZahlungsregelMutationVariables>(
    UpdateHeVertragZahlungsregelDocument,
    options
  );
}
export type UpdateHeVertragZahlungsregelMutationHookResult = ReturnType<typeof useUpdateHeVertragZahlungsregelMutation>;
export type UpdateHeVertragZahlungsregelMutationResult = Apollo.MutationResult<UpdateHeVertragZahlungsregelMutation>;
export type UpdateHeVertragZahlungsregelMutationOptions = Apollo.BaseMutationOptions<
  UpdateHeVertragZahlungsregelMutation,
  UpdateHeVertragZahlungsregelMutationVariables
>;
export const DeleteHeVertragZahlungsregelDocument = gql`
  mutation DeleteHeVertragZahlungsregel($objektId: String!, $heVertragId: String!, $zahlungsregelId: ID!) {
    deleteHeVertragZahlungsregel(objektId: $objektId, heVertragId: $heVertragId, zahlungsregelId: $zahlungsregelId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteHeVertragZahlungsregelMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteHeVertragZahlungsregelMutation, DeleteHeVertragZahlungsregelMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteHeVertragZahlungsregelMutation, DeleteHeVertragZahlungsregelMutationVariables>(
    DeleteHeVertragZahlungsregelDocument,
    options
  );
}
export type DeleteHeVertragZahlungsregelMutationHookResult = ReturnType<typeof useDeleteHeVertragZahlungsregelMutation>;
export type DeleteHeVertragZahlungsregelMutationResult = Apollo.MutationResult<DeleteHeVertragZahlungsregelMutation>;
export type DeleteHeVertragZahlungsregelMutationOptions = Apollo.BaseMutationOptions<
  DeleteHeVertragZahlungsregelMutation,
  DeleteHeVertragZahlungsregelMutationVariables
>;
export const ArchiveHeVertragZahlungsregelDocument = gql`
  mutation ArchiveHeVertragZahlungsregel($objektId: String!, $heVertragId: String!, $zahlungsregelId: ID!) {
    actionArchiveHeVertragZahlungsregel(objektId: $objektId, heVertragId: $heVertragId, zahlungsregelId: $zahlungsregelId) {
      data {
        zahlungsregelId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveHeVertragZahlungsregelMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveHeVertragZahlungsregelMutation, ArchiveHeVertragZahlungsregelMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveHeVertragZahlungsregelMutation, ArchiveHeVertragZahlungsregelMutationVariables>(
    ArchiveHeVertragZahlungsregelDocument,
    options
  );
}
export type ArchiveHeVertragZahlungsregelMutationHookResult = ReturnType<typeof useArchiveHeVertragZahlungsregelMutation>;
export type ArchiveHeVertragZahlungsregelMutationResult = Apollo.MutationResult<ArchiveHeVertragZahlungsregelMutation>;
export type ArchiveHeVertragZahlungsregelMutationOptions = Apollo.BaseMutationOptions<
  ArchiveHeVertragZahlungsregelMutation,
  ArchiveHeVertragZahlungsregelMutationVariables
>;
export const UnarchiveHeVertragZahlungsregelDocument = gql`
  mutation UnarchiveHeVertragZahlungsregel($objektId: String!, $heVertragId: String!, $zahlungsregelId: ID!) {
    actionUnarchiveHeVertragZahlungsregel(objektId: $objektId, heVertragId: $heVertragId, zahlungsregelId: $zahlungsregelId) {
      data {
        zahlungsregelId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveHeVertragZahlungsregelMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveHeVertragZahlungsregelMutation, UnarchiveHeVertragZahlungsregelMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveHeVertragZahlungsregelMutation, UnarchiveHeVertragZahlungsregelMutationVariables>(
    UnarchiveHeVertragZahlungsregelDocument,
    options
  );
}
export type UnarchiveHeVertragZahlungsregelMutationHookResult = ReturnType<typeof useUnarchiveHeVertragZahlungsregelMutation>;
export type UnarchiveHeVertragZahlungsregelMutationResult = Apollo.MutationResult<UnarchiveHeVertragZahlungsregelMutation>;
export type UnarchiveHeVertragZahlungsregelMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveHeVertragZahlungsregelMutation,
  UnarchiveHeVertragZahlungsregelMutationVariables
>;
export const CalculateHeVertragZahlungsregelSumVposNettoDocument = gql`
  mutation CalculateHeVertragZahlungsregelSumVposNetto($objektId: ID!, $input: HeVertragZahlungsregelSumVposNettoInput!) {
    calculateHeVertragZahlungsregelSumVposNetto(objektId: $objektId, input: $input) {
      data {
        vorschreibungSumme
        betrag
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCalculateHeVertragZahlungsregelSumVposNettoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CalculateHeVertragZahlungsregelSumVposNettoMutation,
    CalculateHeVertragZahlungsregelSumVposNettoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CalculateHeVertragZahlungsregelSumVposNettoMutation, CalculateHeVertragZahlungsregelSumVposNettoMutationVariables>(
    CalculateHeVertragZahlungsregelSumVposNettoDocument,
    options
  );
}
export type CalculateHeVertragZahlungsregelSumVposNettoMutationHookResult = ReturnType<typeof useCalculateHeVertragZahlungsregelSumVposNettoMutation>;
export type CalculateHeVertragZahlungsregelSumVposNettoMutationResult = Apollo.MutationResult<CalculateHeVertragZahlungsregelSumVposNettoMutation>;
export type CalculateHeVertragZahlungsregelSumVposNettoMutationOptions = Apollo.BaseMutationOptions<
  CalculateHeVertragZahlungsregelSumVposNettoMutation,
  CalculateHeVertragZahlungsregelSumVposNettoMutationVariables
>;
