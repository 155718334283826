import React from 'react';
import { AuditOutlined, MehOutlined } from '@ant-design/icons';
import { TabsProps } from 'antd';
import { GroupedMenueListInterface } from '../../../components/ContextSidebar/ContentWithSidebar';
import OperatingSiteListing from '../../../features/KundenSystem/Firmendaten/OperatingSite/OperatingSiteListing';
import { pathsToFirmendatenDetails } from '../../../features/KundenSystem/Firmendaten/firmendatenUriPaths';
import MitarbeiterTable from '../../../features/Mitarbeiter/Table/MitarbeiterTable';
import RouteTabs from '../../../components/Tabs/RouteTabs';
import { RechtsTraegerRolleMenuItemTitle } from '../../../features/Rechtstraeger/Rolle/rolleHelper';
import { Firmendaten, RechtstraegerRolle } from '../../../types';
import FirmendatenRechnungsAussteller from '../../../features/KundenSystem/Firmendaten/RechnungsAussteller/FirmendatenRechnungsAussteller';

const menuListFirmendaten = (firmendaten: Firmendaten): GroupedMenueListInterface => {
  const paths = pathsToFirmendatenDetails(firmendaten.firmendatenId);
  return {
    topMenuList: [
      {
        title: 'Stammdaten',
        icon: <MehOutlined />,
        uri: paths.masterDataMenu,
        content: () => {
          const items: TabsProps['items'] = [
            {
              key: paths.operatingSiteTab,
              label: 'Betriebsstätten, Kontakte & Ansprechpartner',
              children: <OperatingSiteListing firmendatenId={firmendaten.firmendatenId} />,
            },
            {
              key: paths.mitarbeiterTab,
              label: 'Mitarbeiter',
              children: (
                <MitarbeiterTable
                  kundenSystemId={firmendaten.kundenSystemId}
                  firmendatenId={firmendaten.firmendatenId}
                  filterIdentifier="sys-verwaltung-firmendaten"
                />
              ),
            },
          ];

          return <RouteTabs items={items} />;
        },
      },
    ],
    contextMenuList: [
      {
        title: RechtsTraegerRolleMenuItemTitle.Rechnungsaussteller,
        icon: <AuditOutlined />,
        uri: paths.rechnungAusstellerTab,
        content: () => <FirmendatenRechnungsAussteller firmendaten={firmendaten} />,
        contextKey: [RechtstraegerRolle.ObjektRechnungsAussteller, RechtstraegerRolle.WeRechnungsAussteller],
      },
    ],
  };
};

export default menuListFirmendaten;
