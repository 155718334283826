import React, { useMemo } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import pathToBreadcrumbMappings, { PathToBreadcrumbMapping } from './pathToBreadcrumbMappings';
import { isNotNull } from '../../helpers/assertionHelper';

const Breadcrumbs = () => {
  const { pathname } = useLocation();
  const breadcrumbs = useMemo(() => mapPathToBreadcrumbs(pathname, pathToBreadcrumbMappings), [pathname]);

  const items = breadcrumbs.map((breadcrumb) => ({
    key: breadcrumb.url,
    title: <Link to={breadcrumb.url}>{breadcrumb.render()}</Link>,
  }));

  return <Breadcrumb style={{ padding: '16px 16px', background: 'white' }} items={items} />;
};

export const mapPathToBreadcrumbs = (pathname: string, pathToBreadcrumbMappings: PathToBreadcrumbMapping[]) =>
  pathname // - /1/2/3?a=4
    .split('?')[0] // cut query params - /1/2/3?a=4 => /1/2/3
    .replace(/\/$/, '') // Remove trailing slash "/" from pathname. // /1/2/3/ => /1/2/3
    .split('/') // Split pathname into sections. - /1/2/3 => [1, 2, 3]
    .reduce(buildPossiblePaths, []) // [1, 2, 3] => // ["", "1", "1/2", "1/2/3"]
    .map((pathSection) => createRouteBreadcrumb(pathSection, pathToBreadcrumbMappings)) // ["", "1", "1/2", "1/2/3"] => [null, null, {RouteBreadcrumb: url: "1/2", render}, {RouteBreadcrumb: url: "1/2/3", render}]
    .filter(isNotNull) // filter out nulls
    .reduce(filterOutDuplicatesByPath, [] as RouteBreadcrumb[]); // due to optional route params in path a single path can match multiple possiblePaths and therefore it would be rendered multiple times hence we filter them out

const buildPossiblePaths = (previousSection: string[], currentSection: string, currentIndex: number) => {
  const pathSection = !currentSection ? '' : `${previousSection[currentIndex - 1]}/${currentSection}`;
  return [...previousSection, pathSection];
};

const createRouteBreadcrumb = (pathSection: string, pathToBreadcrumbMapping: PathToBreadcrumbMapping[]): RouteBreadcrumb | null => {
  let match: any = null;
  const matchingPathMapping = pathToBreadcrumbMapping.find((element) => {
    const internalMatch = matchPath(element.path, pathSection);
    if (internalMatch) {
      match = internalMatch;
      return true;
    } else {
      return false;
    }
  });
  return matchingPathMapping
    ? {
        path: matchingPathMapping.path,
        url: match.pathname,
        render: () => <matchingPathMapping.Title {...match.params} />,
      }
    : null;
};

const filterOutDuplicatesByPath = (uniqueBreadcrumbs: RouteBreadcrumb[], currentBreadcrumb: RouteBreadcrumb) => {
  const duplicate = uniqueBreadcrumbs.find((uniqueBreadcrumb) => uniqueBreadcrumb.path === currentBreadcrumb.path);
  return !duplicate ? [...uniqueBreadcrumbs, currentBreadcrumb] : uniqueBreadcrumbs;
};

interface RouteBreadcrumb {
  path: string;
  url: string;
  render: () => React.ReactElement;
}

export default Breadcrumbs;
