import { generatePath } from 'react-router';

export const URI_INCOMING_INVOICE_PAGE = '/dashboard/incoming-invoices';

export const URI_INCOMING_INVOICE_CREATE_INVOICE_PAGE = `${URI_INCOMING_INVOICE_PAGE}/new-invoice/:fileId`;

export const incomingInvoicePaths = {
  singleInvoiceDetails: `${URI_INCOMING_INVOICE_PAGE}/single-invoice/:fileId/:belegId`,
  singleInvoiceUpdate: `${URI_INCOMING_INVOICE_PAGE}/single-invoice/:fileId/:belegId/edit`,
  standingInvoiceDetails: `${URI_INCOMING_INVOICE_PAGE}/standing-invoice/:fileId/:belegId`,
  standingInvoiceUpdate: `${URI_INCOMING_INVOICE_PAGE}/standing-invoice/:fileId/:belegId/edit`,
};

export const generatePathToToIncomingInvoiceCreateInvoicePage = (fileId: string) =>
  generatePath(URI_INCOMING_INVOICE_CREATE_INVOICE_PAGE, { fileId });

export const generatePathToStandingOrSingleInvoiceDetailsPage = (fileId: string, belegId: string, standingInvoicePartialAmountId?: string | null) =>
  generatePath(standingInvoicePartialAmountId ? incomingInvoicePaths.standingInvoiceDetails : incomingInvoicePaths.singleInvoiceDetails, {
    fileId,
    belegId,
  });

export const generatePathToSingleInvoiceDetailsPage = (fileId: string, belegId: string) =>
  generatePath(incomingInvoicePaths.singleInvoiceDetails, {
    fileId,
    belegId,
  });

export const generatePathToStandingInvoiceDetailsPage = (fileId: string, belegId: string) =>
  generatePath(incomingInvoicePaths.standingInvoiceDetails, {
    fileId,
    belegId,
  });
