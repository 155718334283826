import React, { FC } from 'react';
import { Box, Flex } from 'rebass';
import { Space, Typography } from 'antd';

import { ITimeblock } from '../../../../components/Timeline/timelineCtx';
import { steuerpflichtVersionFormValuesDescriptions } from './steuerpflichtVersionTimelineFormMapper';
import { RechtstraegerSteuerpflicht } from '../../../../types';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

type SteuerpflichtTimelineContentProps = {
  timeblock: ITimeblock<RechtstraegerSteuerpflicht>;
};

const SteuerpflichtTimelineContent: FC<SteuerpflichtTimelineContentProps> = ({ timeblock }) => (
  <Space direction="vertical" style={{ width: '80%' }}>
    <Flex>
      <Box width={1 / 2}>
        <Typography.Text type="secondary">{steuerpflichtVersionFormValuesDescriptions.validFrom}</Typography.Text>
      </Box>
      {timeblock.validFrom && <CustomFormattedDate value={timeblock.validFrom} />}
    </Flex>
    <Flex>
      <Box width={1 / 2}>
        <Typography.Text type="secondary">{steuerpflichtVersionFormValuesDescriptions.steuerpflicht}</Typography.Text>
      </Box>
      {timeblock.steuerpflicht && <Typography.Text>{timeblock.steuerpflicht.text}</Typography.Text>}
    </Flex>
  </Space>
);

export default SteuerpflichtTimelineContent;
