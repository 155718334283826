import React, { FC } from 'react';
import { Document, Page, View } from '@react-pdf/renderer';
import pdfStyles from '../../styles/pdfStyles';
import Header from '../../shared/Header';
import { isNotNil } from '../../helpers/assertionHelper';
import InfoAbrechnungskreisBlock from '../../shared/Abrechnungskreis/InfoAbrechnungskreisBlock';
import Footer from '../../shared/Footer';
import Ergebnistext from '../../shared/Ergebnistext';
import Einleitungstext from '../../shared/Einleitungstext';
import Schlusstext from '../../shared/Schlusstext';
import SubAdminAbrAdressorAndDateBlock from '../shared/SubAdminAbrAdressorAndDateBlock';
import { TSubAdministrationAbrechnungTop } from './subAdministrationAbrechnungTop-types';
import BestandseinheitBlockTable from './components/BestandseinheitBlockTable';
import SubAdminAbrSummary from '../shared/SubAdminAbrSummary';
import ObjektZahlungsrueckstaendeTable from '../../shared/ObjektZahlungsrueckstaendeTable/ObjektZahlungsrueckstaendeTable';
import BeObjektListSummary from './components/BeObjektListSummary';
import LabelValue from '../../shared/LabelValue';

type Props = {
  data: TSubAdministrationAbrechnungTop;
  isInEditMode?: boolean;
  selectedTextbaustein?: SubAdministrationTopAbrechnungSelectableSection;
};

export type SubAdministrationTopAbrechnungSelectableSection = 'EINLEITUNGSTEXT' | 'ERGEBNISTEXT' | 'SCHLUSSTEXT';

const SubAdministrationAbrechnungTopTemplate: FC<Props> = ({ data, isInEditMode, selectedTextbaustein }) => (
  <Document>
    <Page size="A4" style={[pdfStyles.body, { paddingBottom: data.footer?.value ? '32mm' : pdfStyles.body.paddingBottom }]}>
      <View>
        {/* header */}
        <Header header={data.header} />
      </View>

      {/* HauseigentuemerBlock: (Absender + Rechnungsempfänger + Kunden-Nr) + Datum + City */}
      <SubAdminAbrAdressorAndDateBlock
        absender={data.absender}
        rechnungsEmpfaenger={data.eigentuemer}
        abrechnungsDatumLine={data.abrechnungsDatumLine}
      />

      {/* Einleitungstext with Textbaustein */}
      {isNotNil(data.einleitungstext) ? (
        <Einleitungstext text={data.einleitungstext} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />
      ) : null}

      <BestandseinheitBlockTable bestandseinheitBlock={data.bestandseinheitBlock} />

      {/* Summary of Bestandseinheit Block  */}
      <BeObjektListSummary beObjektListSummary={data.objekt} />

      {/* Ergebnis Abrechnung */}
      <View style={[pdfStyles.tablePaddingLeftRight, { marginTop: '3mm' }]}>
        <View style={[pdfStyles.column, { marginTop: '5mm' }]}>
          <LabelValue
            valueTuple={data.abrechnungErgebnis}
            viewStyle={{ ...pdfStyles.borderBottomSmall, borderBottomWidth: 2 }}
            labelStyle={{ width: '70%', fontWeight: 'bold' }}
            valueStyle={{ width: '30%', textAlign: 'right', fontWeight: 'bold' }}
          />
        </View>
      </View>

      {/* Summary */}
      <SubAdminAbrSummary endErgebnis={data.endErgebnis} />

      {/* Objekt Zahlungsrueckstände Table */}
      {data.objektZahlungsrueckstaendeBlock ? <ObjektZahlungsrueckstaendeTable data={data.objektZahlungsrueckstaendeBlock} /> : null}

      {/* Ergebnistext with Textbaustein */}
      {isNotNil(data.ergebnistext) ? (
        <Ergebnistext text={data.ergebnistext} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />
      ) : null}

      {/* Schlusstext with Textbaustein */}
      {isNotNil(data.schlusstext) ? (
        <Schlusstext text={data.schlusstext} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />
      ) : null}

      {/* InfoAbrechnungskreisblock */}
      {data.informationAbrechnungskreiseBlock && data.informationAbrechnungskreiseBlock.abrechnungskreisList.length > 0 && (
        <InfoAbrechnungskreisBlock informationAbrechnungskreiseBlock={data.informationAbrechnungskreiseBlock} />
      )}

      {/* Footer */}
      {data.footer?.value && <Footer footer={data.footer} />}
    </Page>
  </Document>
);

export default SubAdministrationAbrechnungTopTemplate;
