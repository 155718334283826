import React from 'react';
import { Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { NestedTableColProps } from '../../../../../../components/Table/NestedTable/NestedTable';
import { DataCarrierCamtListEntry, DataCarrierCamtOrderBy } from '../../../../../../types';
import DataWithShortenedText from '../../../../../../components/Helpers/DataWithShortenedText';
import FormattedDateTime from '../../../../../../components/FormattedDateTime/FormattedDateTime';
import { MitarbeiterTooltip } from '../../../../../../components/Card';
import DataCarrierIbanListColumn from '../../../shared/DataCarrierIbanListColumn';
import XmlDataCarrierTableColumn from '../../../shared/XmlDataCarrierTableColumn';
import DataCarrierCamtTableActions from './DataCarrierCamtTableActions';
import { uriFileOfFirmendaten } from '../../../../../Document/documentApiPaths';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';
import EntityWarningList from '../../../../../../components/message/EntityWarningList';
import TruncatedTextWithTooltip from '../../../../../../components/Helpers/TruncatedTextWithTooltip';

const dataCarrierCamtTableColumns = (firmendatenId: string, onAction: () => void): NestedTableColProps<DataCarrierCamtListEntry>[] => [
  {
    title: 'Datenträgertyp',
    render: (text, record) => {
      return (
        <Space size={4}>
          <EntityWarningList warningList={record.warningList} />
          <DataWithShortenedText text={record.dataCarrierType.text} maxTextLength={20} />
        </Space>
      );
    },
  },
  {
    title: 'Bezeichnung',
    dataIndex: DataCarrierCamtOrderBy.Name,
    sorter: true,
    render: (text, record) => <TruncatedTextWithTooltip text={record.name} />,
  },
  {
    title: 'Zahlungsverkehrsart: EBICS / Onlinebanking',
    dataIndex: DataCarrierCamtOrderBy.PaymentTransactionType,
    sorter: true,
    render: (text, record) => <Typography.Text>{record.paymentTransactionType.text}</Typography.Text>,
  },
  {
    title: 'Importierte IBAN',
    render: (text, record) => <DataCarrierIbanListColumn ibanList={record.ibanImportedList} maxTextLength={20} />,
  },
  {
    title: 'Nicht importierte IBAN',
    render: (text, record) => <DataCarrierIbanListColumn ibanList={record.ibanNotImportedList} maxTextLength={20} />,
  },
  {
    title: 'ZIP-Datei',
    dataIndex: DataCarrierCamtOrderBy.ZipFileName,
    sorter: true,
    render: (text, record) =>
      record.zipFileId && record.zipFilename ? (
        <Link to={uriFileOfFirmendaten(firmendatenId, record.zipFileId, record.zipFilename)} target="_blank">
          {record.zipFilename}
        </Link>
      ) : (
        <TextForEmptyValue textToShow="minus" />
      ),
  },
  {
    title: 'XML Datenträger',
    render: (text, record) => (
      <XmlDataCarrierTableColumn dataCarrierId={record.dataCarrierId} xmlFilename={record.xmlFilename} firmendatenId={firmendatenId} />
    ),
  },
  {
    title: 'Erstellt am',
    dataIndex: DataCarrierCamtOrderBy.CreateTs,
    sorter: true,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    dataIndex: DataCarrierCamtOrderBy.CreatedBy,
    sorter: true,
    render: (text, record) => <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} alignment="left" showsInitials />,
  },
  {
    title: 'Aktion',
    render: (text, record) => <DataCarrierCamtTableActions onAction={onAction} dataCarrierCamt={record} />,
  },
];

export default dataCarrierCamtTableColumns;
