import { FC } from 'react';
import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { CamtGenerierlauf } from '../../../types';
import menuListCamt from './menuListCamt';
import { CamtPageRouteParams } from './routes';
import { useCamtGenerierlaufQuery } from '../../../features/Verarbeitung/gql/CamtGenerierlauf/CamtGenerierlaufQueries.types';

const CamtDetailsPage: FC = () => {
  const { generierlaufId } = useParams() as CamtPageRouteParams;

  const { data } = useCamtGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
  });

  const generierlauf = data?.getCamtGenerierlauf.data;

  return (
    <DetailsPageTemplate<CamtGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.Camt}
      card={(generierlauf) => <VerarbeitungDetailsPageCard generierlauf={generierlauf} cardTitle={verarbeitungDetailsCardTitles.Camt} />}
      isLoading={false}
      sidebarMenuList={menuListCamt}
    />
  );
};

export default CamtDetailsPage;
