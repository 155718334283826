import React, { AnchorHTMLAttributes, FC } from 'react';
import { Link } from 'react-router-dom';
import { EuroAmount } from '../../../components/Number';
import CardDetailsInfoRow from '../../../components/Card/CardDetailsInfoRow';
import { Mahnung, MahnungStatus } from '../../../types';
import { generatePathToBookingDetailsPage } from '../../BookingDetails/bookingDetailsPaths';

type Props = {
  mahnung: Mahnung;
};

const MahnungSums: FC<Props> = ({ mahnung }) => {
  const link =
    mahnung.status.value === MahnungStatus.GemahntMitBuchung && mahnung.buchungId ? generatePathToBookingDetailsPage([mahnung.buchungId]) : undefined;

  return (
    <>
      <MahnungSum title="Rechnungsbetrag-Summe" sum={mahnung.sumRechnungsbetrag} />
      <MahnungSum title="Zahlungen-Summe" sum={mahnung.sumZahlungen} />
      <MahnungSum title="Mahnbetrag-Summe" sum={mahnung.sumMahnbetrag} link={link} target="_blank" />
    </>
  );
};

type MahnungSumProps = {
  title: string;
  sum: number;
  link?: string;
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];
};

const MahnungSum: FC<MahnungSumProps> = ({ title, sum, link, target }) => {
  return (
    <CardDetailsInfoRow infoRowTitle={title}>
      {link ? (
        <Link to={link} target={target}>
          <EuroAmount value={sum} />
        </Link>
      ) : (
        <EuroAmount value={sum} />
      )}
    </CardDetailsInfoRow>
  );
};

export default MahnungSums;
