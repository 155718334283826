import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useMitarbeiterSelectListQuery } from '../../../graphql/MitarbeiterService/MitarbeiterQueries.types';
import { selectFilterOption } from '../../../helpers/selectHelper';
import { Mitarbeiter } from '../../../types';

type Props = {
  name: string;
  onMitarbeiterChange?: (value: Mitarbeiter | Mitarbeiter[] | undefined) => void;
} & Omit<SelectProps, 'id' | 'allowClear' | 'filterOption' | 'loading' | 'showSearch'>;

const MitarbeiterSelect: FC<Props> = ({ name, onMitarbeiterChange, placeholder = 'Ersteller auswählen', size, ...restProps }) => {
  const { loading, data } = useMitarbeiterSelectListQuery();
  const mitarbeiterList = data?.getMitarbeiterList.data ?? [];

  return (
    <Select
      {...restProps}
      size={size ?? 'small'}
      name={name}
      id={name}
      loading={loading}
      placeholder={placeholder}
      allowClear
      style={{ minWidth: '200px' }}
      showSearch
      filterOption={selectFilterOption}
      onChange={(value, option) => {
        restProps.onChange?.(value, option);
        if (!value) {
          onMitarbeiterChange?.(value);
        } else if (Array.isArray(value)) {
          const selected = mitarbeiterList.filter((mitarbeiter) => value.includes(mitarbeiter.mitarbeiterId)).map((mitarbeiter) => mitarbeiter);
          onMitarbeiterChange?.(selected);
        } else {
          const selected = mitarbeiterList.find((mitarbeiter) => mitarbeiter.mitarbeiterId === value);
          onMitarbeiterChange?.(selected);
        }
      }}
    >
      {mitarbeiterList.map((mitarbeiter) => (
        <Select.Option key={mitarbeiter.mitarbeiterId} value={mitarbeiter.mitarbeiterId}>
          {mitarbeiter.lastname}, {mitarbeiter.firstname}
        </Select.Option>
      ))}
    </Select>
  );
};

export default MitarbeiterSelect;
