import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../security/AuthRoute';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../security/permissionChecks';
import EmailDeliveryDefinitionPage from './EmailDeliveryDefinitionPage';
import { URI_SYS_SETTINGS } from '../../../../constants/configurationUriPaths';

const sysSettingsEmailDeliveryDefinitionPageRoute = (
  <Route
    path={URI_SYS_SETTINGS.emailDeliveryDefinitionPage}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<EmailDeliveryDefinitionPage />} />}
  />
);

export default sysSettingsEmailDeliveryDefinitionPageRoute;
