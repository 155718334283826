import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { LabeledValue } from '../../../shared/types';
import LabelValue from '../../../shared/LabelValue';

type Props = {
  expectedCostSum: LabeledValue;
  width?: string;
};

const FPCostSumBlock: FC<Props> = ({ expectedCostSum, width }) => (
  <LabelValue valueTuple={expectedCostSum} viewStyle={{ ...pdfStyles.borderTopSmall, width }} />
);

export default FPCostSumBlock;
