import React, { FC } from 'react';
import { Box } from 'rebass';
import { ZuordnungKontoKlasseType, zuordnungListHeight } from '../../../../AbrKontenzuordnung/shared/AbrKreisKontenzuordnungHelpers';
import ZuordnungListLoading from '../../../../AbrKontenzuordnung/shared/ZuordnungListLoading';
import { AbrechnungsdefinitionType, Konto, KontoAbrechnungskreis } from '../../../../../types';
import ZuordnungKontoListingViewOrEdit from '../../../../AbrKontenzuordnung/shared/ZuordnungKontoListingViewOrEdit';
import { mapToZuordnungViewModelList } from '../../../../AbrKontenzuordnung/shared/zuordnungColumnHelpers';
import ZuordnungTableHeader from '../../../../AbrKontenzuordnung/shared/ZuordnungTableHeader';

type Props = {
  abrechnungsdefinitionId: string;
  abrDefinitionType: AbrechnungsdefinitionType;
  einnahmenKontoList: Konto[];
  ausgabenKontoList: Konto[];
  verrechnungList: Konto[];
  isEinnahmenListOpen: boolean;
  isAusgabenListOpen: boolean;
  isVerrechnungListOpen: boolean;
  zuordnungList?: KontoAbrechnungskreis[];
};

const ZuordnungColumnOnlyView: FC<Props> = ({
  abrechnungsdefinitionId,
  abrDefinitionType,
  einnahmenKontoList,
  ausgabenKontoList,
  verrechnungList,
  isEinnahmenListOpen,
  isAusgabenListOpen,
  isVerrechnungListOpen,
  zuordnungList,
}) => {
  return (
    <Box>
      {!zuordnungList ? (
        <Box width={zuordnungListHeight.right}>
          <ZuordnungTableHeader />
          <ZuordnungListLoading title={ZuordnungKontoKlasseType.EINNAHMEN} kontoList={einnahmenKontoList} isCollapsed={isEinnahmenListOpen} />
          <Box height="16px" />
          <ZuordnungListLoading title={ZuordnungKontoKlasseType.AUSGABEN} kontoList={ausgabenKontoList} isCollapsed={isAusgabenListOpen} />
          <Box height="16px" />
          <ZuordnungListLoading title={ZuordnungKontoKlasseType.VERRECHNUNG} kontoList={verrechnungList} isCollapsed={isVerrechnungListOpen} />
        </Box>
      ) : (
        <Box width={zuordnungListHeight.right}>
          <ZuordnungTableHeader />
          <ZuordnungKontoListingViewOrEdit
            kontenAbrechnungskreisList={mapToZuordnungViewModelList(abrechnungsdefinitionId, einnahmenKontoList, zuordnungList)}
            zuordnungKontoKlasseType={ZuordnungKontoKlasseType.EINNAHMEN}
            abrDefinitionType={abrDefinitionType}
            isListOpen={isEinnahmenListOpen}
            keyForCollapse="1"
            mode="onlyView"
          />
          <Box height="16px" />
          <ZuordnungKontoListingViewOrEdit
            kontenAbrechnungskreisList={mapToZuordnungViewModelList(abrechnungsdefinitionId, ausgabenKontoList, zuordnungList)}
            zuordnungKontoKlasseType={ZuordnungKontoKlasseType.AUSGABEN}
            abrDefinitionType={abrDefinitionType}
            isListOpen={isAusgabenListOpen}
            keyForCollapse="2"
            mode="onlyView"
          />
          <Box height="16px" />
          <ZuordnungKontoListingViewOrEdit
            kontenAbrechnungskreisList={mapToZuordnungViewModelList(abrechnungsdefinitionId, verrechnungList, zuordnungList)}
            zuordnungKontoKlasseType={ZuordnungKontoKlasseType.VERRECHNUNG}
            abrDefinitionType={abrDefinitionType}
            isListOpen={isVerrechnungListOpen}
            keyForCollapse="3"
            mode="onlyView"
          />
        </Box>
      )}
    </Box>
  );
};

export default ZuordnungColumnOnlyView;
