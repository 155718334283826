import React, { FC } from 'react';
import { Space, Tooltip, Typography } from 'antd';
import { MitarbeiterTooltip } from '../../../../components/Card';
import MitarbeiterOrUserName from '../../../../components/Card/MitarbeiterOrUserName';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import { dayjsCustom } from '../../../../helpers/dayjsCustom';

type Props = {
  userId: string;
  mitarbeiterId?: string | null;
  date: string;
  action: React.ReactNode;
};

const EntryChangeHeader: FC<Props> = ({ userId, mitarbeiterId, date, action }) => {
  return (
    <Space size={4}>
      <MitarbeiterTooltip showsInitials userId={userId} mitarbeiterId={mitarbeiterId} alignment="right" />
      <MitarbeiterOrUserName userId={mitarbeiterId} mitarbeiterId={mitarbeiterId} />
      {action}
      <Typography.Text type="secondary">
        <Tooltip title={<FormattedDateTime createdTs={date} />}>{dayjsCustom(date).fromNow()}</Tooltip>
      </Typography.Text>
    </Space>
  );
};

export default EntryChangeHeader;
