import React, { FC } from 'react';
import TableListEmpty from '../../../../components/Helpers/TableListEmpty';
import objektAufteilungsschluesselTableColumns from './objektAufteilungsschluesselTableColumns';
import TableWithColSelector from '../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { ObjektAufteilungsschluessel } from '../../../../types';

type ObjektAufteilungsschluesselTableProps = {
  aufteilungsschluesselList?: ObjektAufteilungsschluessel[];
  loading: boolean;
  objektId: string;
  onCollapse: () => void;
  refetch: () => void;
  isWegObjekt: boolean;
};

const ObjektAufteilungsschluesselTable: FC<ObjektAufteilungsschluesselTableProps> = ({
  aufteilungsschluesselList,
  loading,
  objektId,
  onCollapse,
  refetch,
  isWegObjekt,
}) => {
  return (
    <TableWithColSelector<ObjektAufteilungsschluessel>
      size="small"
      locale={{
        emptyText: <TableListEmpty onClick={onCollapse} emptyTxt="Keine Aufteilungsschlüssel zugewiesen" btnTxt="Aufteilungsschlüssel zuweisen" />,
      }}
      loading={loading}
      pagination={false}
      scroll={{ x: 900 }}
      columns={objektAufteilungsschluesselTableColumns(objektId, refetch, isWegObjekt)}
      dataSource={aufteilungsschluesselList}
      rowKey={(record) => record.aufteilungsschluesselId}
      filterIdentifier="objekt-aufteilungschluessel"
    />
  );
};

export default ObjektAufteilungsschluesselTable;
