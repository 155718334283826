import { FormFields } from '../../../../helpers/formikHelper';

export type CreateFormValues = {
  emailDeliveryDefinitionId: string;
};

export const createFormInitialValues: CreateFormValues = {
  emailDeliveryDefinitionId: '',
};

export const createFormFields: FormFields<CreateFormValues> = {
  emailDeliveryDefinitionId: 'emailDeliveryDefinitionId',
};
