import React from 'react';
import { TableWithColSelectorColumnProps } from '../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { IndexSeries } from '../../../../../types';
import { compareTwoStringsForSorting } from '../../../../../helpers/stringHelper';
import { compareTwoDatesForSorting } from '../../../../../helpers/dateHelper';
import { MitarbeiterTooltip } from '../../../../../components/Card';
import IndexSeriesVerwaltungColumn from '../../../../IndexSeries/Listing/Table/IndexSeriesVerwaltungColumn';
import IndexSeriesTableActions from './IndexSeriesTableActions';
import CurrentBasedData from '../../../../../components/Helpers/CurrentBasedData';
import { isStatusArchived } from '../../../../../helpers/statusHelper';
import FormattedDateTime from '../../../../../components/FormattedDateTime/FormattedDateTime';

const indexSeriesTableColumns = (onAction: () => void): TableWithColSelectorColumnProps<IndexSeries>[] => [
  {
    title: 'Bezeichnung',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.name, b.name),
    render: (text, record) => (
      <CurrentBasedData text={record.name} maxTextLength={20} isCurrent={record.status ? !isStatusArchived(record.status) : true} />
    ),
  },
  {
    title: 'Indextyp',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.indexType.text, b.indexType.text),
    render: (text, record) => (
      <CurrentBasedData text={record.indexType.text} maxTextLength={20} isCurrent={record.status ? !isStatusArchived(record.status) : true} />
    ),
  },
  {
    title: 'Verwaltung',
    defaultSelected: true,
    sorter: true,
    render: (text, record) => <IndexSeriesVerwaltungColumn firmendatenId={record.firmendatenId} status={record.status} />,
  },
  {
    title: 'Erstellt am',
    width: 100,
    sorter: (a, b) => compareTwoDatesForSorting(a.createTs, b.createTs),
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip alignment="left" mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => <IndexSeriesTableActions indexSeries={record} onAction={onAction} />,
  },
];

export default indexSeriesTableColumns;
