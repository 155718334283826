import { DataChangedEvent } from '../../types';
import { DataChangedFilter } from './dataChangedSubscriptionContextProps';

export const isTriggeredByOtherUserAndMatchingFilter = (payloadContent: DataChangedEvent, filter: DataChangedFilter) => {
  const matchesChangeType = filter.changeType ? payloadContent.changeType === filter.changeType : true;
  return (
    isTriggeredByOtherUser(payloadContent, filter) &&
    payloadContent.type === filter.type &&
    eventContainsAndMatchesAllFilterParams(payloadContent, filter) &&
    matchesChangeType
  );
};

// and the client's intention was probably (documents listing) that user should be notified about any new document, event which does not have these
// params set. If that so, then we should add an explicit variable which indicated if client wants a creator check.
export const isTriggeredByOtherUser = (payloadContent: DataChangedEvent, filter: DataChangedFilter) => {
  const payloadUserId = payloadContent.createdBy;
  const payloadMitarbeiterId = payloadContent.createdByMitarbeiterId;
  const filterUserId = filter.userId;
  const filterMitarbeiterId = filter.mitarbeiterId;
  return filterUserId !== payloadUserId || filterMitarbeiterId !== payloadMitarbeiterId;
};

export const eventContainsAndMatchesAllFilterParams = (payloadContent: DataChangedEvent, filter: DataChangedFilter) => {
  const hasFilterValueByKey = (filterParamKey: string) =>
    Array.isArray(filter.params?.[filterParamKey]) ? !!filter.params?.[filterParamKey]?.length : !!filter.params?.[filterParamKey];

  const eventContainsAndMatchesFilterParamByKey = (filterParamKey: string) => {
    const filterParamValue = filter.params?.[filterParamKey];
    if (Array.isArray(filterParamValue)) {
      return filterParamValue.some((paramValue: string) => eventContainsAndMatchesFilterParam(filterParamKey, paramValue));
    }
    if (typeof filterParamValue === 'function') {
      const payloadContentParam = payloadContent.paramList.find((payloadEventParam) => payloadEventParam.key === filterParamKey);
      return payloadContentParam ? filterParamValue(payloadContentParam.value) : true;
    }
    return eventContainsAndMatchesFilterParam(filterParamKey, filterParamValue);
  };

  const eventContainsAndMatchesFilterParam = (filterParamKey: string, filterParamValue: string | undefined) =>
    payloadContent.paramList.some((payloadEventParam) => payloadEventParam.key === filterParamKey && payloadEventParam.value === filterParamValue);

  return !filter.params || Object.keys(filter.params).filter(hasFilterValueByKey).every(eventContainsAndMatchesFilterParamByKey);
};
