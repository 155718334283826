import { FC } from 'react';
import { FormikProps } from 'formik';
import {
  VorschreibungErstellenVerarbeitungEntryFiltersFormValues,
  vorschreibungErstellenVerarbEntryListingFiltersFormFields,
} from './ListingFiltersFormMapper';
import FiltersWith4OrMoreFields from '../../../../../../components/Filters/FiltersWith4OrMoreFields';
import FormItemWithoutColon from '../../../../../../components/Form/FormItemWithoutColon';
import GenerierlaufVorschreibungperiodeFormSelect from '../../../../shared/Filters/App/GenerierlaufVorschreibungperiodeFormSelect';
import GenerierlaufObjektFormSelect from '../../../../shared/Filters/App/GenerierlaufObjektFormSelect';
import GenerierlaufEntryExitCodeListFormSelect from '../../../../shared/Filters/App/GenerierlaufEntryExitCodeListFormSelect';
import GenerierlaufRechnungsAusstellerFormSelect from '../../../../shared/Filters/App/GenerierlaufRechnungsAusstellerFormSelect';
import GenerierlaufRechnungsEmpfaengerFormSelect from '../../../../shared/Filters/App/GenerierlaufRechnungsEmpfaengerFormSelect';
import { AppGenerierlaufType } from '../../../../../../types';

type Props = {
  formikProps: FormikProps<VorschreibungErstellenVerarbeitungEntryFiltersFormValues>;
  generierlaufId: string;
};

const ListingFilters: FC<Props> = ({ formikProps, generierlaufId }) => {
  return (
    <FiltersWith4OrMoreFields
      hideTitle
      leftColumn={
        <>
          <FormItemWithoutColon
            name={vorschreibungErstellenVerarbEntryListingFiltersFormFields.vorschreibungsperiode}
            label="Zinsliste-Fakturierungsperiode"
          >
            <GenerierlaufVorschreibungperiodeFormSelect
              name={vorschreibungErstellenVerarbEntryListingFiltersFormFields.vorschreibungsperiode}
              id={vorschreibungErstellenVerarbEntryListingFiltersFormFields.vorschreibungsperiode}
              generierlaufId={generierlaufId}
              generierlaufType={AppGenerierlaufType.Auftragserstellung}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={vorschreibungErstellenVerarbEntryListingFiltersFormFields.objektId} label="Objekt">
            <GenerierlaufObjektFormSelect
              name={vorschreibungErstellenVerarbEntryListingFiltersFormFields.objektId}
              generierlaufId={generierlaufId}
              generierlaufType={AppGenerierlaufType.Auftragserstellung}
              rechnungsAusstellerId={formikProps.values.ausstellerId}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={vorschreibungErstellenVerarbEntryListingFiltersFormFields.exitCodeList} label="Exit Code Status">
            <GenerierlaufEntryExitCodeListFormSelect
              name={vorschreibungErstellenVerarbEntryListingFiltersFormFields.exitCodeList}
              generierlaufId={generierlaufId}
              generierlaufType={AppGenerierlaufType.Auftragserstellung}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
      rightColumn={
        <>
          <FormItemWithoutColon name={vorschreibungErstellenVerarbEntryListingFiltersFormFields.ausstellerId} label="Rechnungsaussteller">
            <GenerierlaufRechnungsAusstellerFormSelect
              name={vorschreibungErstellenVerarbEntryListingFiltersFormFields.ausstellerId}
              generierlaufId={generierlaufId}
              generierlaufType={AppGenerierlaufType.Auftragserstellung}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={vorschreibungErstellenVerarbEntryListingFiltersFormFields.empfaengerId} label="Rechnungsempfänger">
            <GenerierlaufRechnungsEmpfaengerFormSelect
              name={vorschreibungErstellenVerarbEntryListingFiltersFormFields.empfaengerId}
              generierlaufId={generierlaufId}
              generierlaufType={AppGenerierlaufType.Auftragserstellung}
              onChange={formikProps.submitForm}
            />
          </FormItemWithoutColon>
        </>
      }
    />
  );
};

export default ListingFilters;
