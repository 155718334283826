import React, { FC } from 'react';
import { Col, Row } from 'antd';
import MonthYearFormItem from './MonthYearFormItem';
import IndexOldFormItem from './IndexOldFormItem';
import DrawerRow from '../../../../../../../../../components/Drawer/DrawerRow';
import { ValuePreservationType } from '../../../../../../../../../types';

type Props = {
  monthYearName: string;
  onMonthYearChange: (month: number, year: number) => void;
  indexOldName: string;
  valuePreservationType: ValuePreservationType;
  isIndexOldPreliminary?: boolean;
  fieldHelpNames?: {
    monthYearOld?: string | null;
    indexOld?: string | null;
  };
};

const LastValueBaseBlock: FC<Props> = ({
  monthYearName,
  onMonthYearChange,
  indexOldName,
  valuePreservationType,
  isIndexOldPreliminary = false,
  fieldHelpNames,
}) => {
  const content = (
    <Row gutter={8}>
      <Col span={12}>
        <MonthYearFormItem name={monthYearName} label="Monat/Jahr alt" onChange={onMonthYearChange} fieldHelp={fieldHelpNames?.monthYearOld} />
      </Col>
      <Col span={12}>
        <IndexOldFormItem
          name={indexOldName}
          valuePreservationType={valuePreservationType}
          isIndexOldPreliminary={isIndexOldPreliminary}
          fieldHelp={fieldHelpNames?.indexOld}
        />
      </Col>
    </Row>
  );

  return <DrawerRow rowTitle="Letzte Basis" content={content} />;
};

export default LastValueBaseBlock;
