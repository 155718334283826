import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { InfoAbrechnungskreis } from '../../../objektAbrechnung/objektAbrechnung-types';

const InfoAbrKreisSummeErgebnis: FC<{ abrechKreis: InfoAbrechnungskreis }> = ({ abrechKreis }) => (
  <View style={[pdfStyles.row, pdfStyles.tablePaddingLeftRight]}>
    <View style={[pdfStyles.column, { width: '55%' }]} />
    <View style={[pdfStyles.row, pdfStyles.borderTopSmall, { width: '45%', borderTopWidth: 2 }]}>
      <View style={{ display: 'flex', flexDirection: 'row', paddingTop: '1mm' }}>
        <Text style={[pdfStyles.textBulletin, { width: '80%', fontWeight: 'bold' }]}>{abrechKreis.ergebnisNettoText}</Text>
        <Text style={[pdfStyles.textBulletin, { width: '20%', fontWeight: 'bold', alignItems: 'flex-end', textAlign: 'right' }]}>
          {abrechKreis.ergebnisNetto}
        </Text>
      </View>
    </View>
  </View>
);

export default InfoAbrKreisSummeErgebnis;
