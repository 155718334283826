import React, { FC } from 'react';
import { Formik } from 'formik';
import { DatePicker, Form } from 'formik-antd';
import FormItemWithoutColon from '../../../../../../../../components/Form/FormItemWithoutColon';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../../../../../components/DatePicker/DatePicker_formikAntD';
import FormButtons from '../../../../../../../../components/Button/FormButtons';
import {
  buchungDeviationUebernehmenFormFields,
  BuchungDeviationUebernehmenFormValues,
  mapFormValuesToBuchungDeviationUebernehmen,
} from './buchungDeviationUebernehmenFormMapper';
import { useBuchungsanweisungApplyAllDeviationsMutation } from '../../../../../../gql/BuchungsanweisungDeviationMutations.types';
import { showSuccessMsgOther } from '../../../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../../../helpers/apolloHelper';

type Props = {
  onAction: () => void;
  onCancel: () => void;
  buchungsanweisungId: string;
  defaultBuchungsdatum: string;
};

const BuchungDeviationUebernehmenForm: FC<Props> = ({ onAction, onCancel, buchungsanweisungId, defaultBuchungsdatum }) => {
  const [runApplyAllDeviations, { loading }] = useBuchungsanweisungApplyAllDeviationsMutation({
    onCompleted: () => {
      showSuccessMsgOther('Alle Abweichungen sind korrigiert.');
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<BuchungDeviationUebernehmenFormValues>
      initialValues={{ buchungsdatum: defaultBuchungsdatum }}
      onSubmit={(values, { setSubmitting }) => {
        const { buchungsdatum } = mapFormValuesToBuchungDeviationUebernehmen(values);
        runApplyAllDeviations({
          variables: {
            buchungsanweisungId,
            buchungsdatum,
          },
        }).finally(() => setSubmitting(false));
      }}
    >
      {(formikProps) => (
        <Form>
          <FormItemWithoutColon name={buchungDeviationUebernehmenFormFields.buchungsdatum} label="Buchungsdatum">
            <DatePicker
              id={buchungDeviationUebernehmenFormFields.buchungsdatum}
              name={buchungDeviationUebernehmenFormFields.buchungsdatum}
              placeholder="Datum wählen"
              format={DATE_DISPLAY_FORMAT_DEFAULT}
              allowClear={false}
              size="small"
              style={{ width: '180px' }}
            />
          </FormItemWithoutColon>
          <FormButtons
            okText="Ja"
            cancelText="Nein"
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={loading}
            updateMode={false}
          />
        </Form>
      )}
    </Formik>
  );
};

export default BuchungDeviationUebernehmenForm;
