import { BookingSuggestion } from '../../../../types';
import { mapBookingSuggestionToInitialFormValues } from './buchungsmaskeFormMapper';
import { useQueryWithPagingAndSorting } from '../../../../hooks/useQueryWithPagingAndSorting';
import { useOffenePostenListQuery } from '../../../OffenePosten/gql/OffenePostenQueries.types';
import { isBuchungTypeRuecklastschrift } from '../bookingSuggestionHelpers';
import { isKontoTypePersonenkonto } from '../../../Kontenplan/Form/kontoFormHelpers';

export const useBookingSuggestionInitialValues = (bookingSuggestion: BookingSuggestion) => {
  const personenkontoList = bookingSuggestion.buchungsanweisung.buchungszeileList.filter((bz) => isKontoTypePersonenkonto(bz.konto?.type.value));

  const { data: offenePostenForPersonenkontoData, loading } = useQueryWithPagingAndSorting(useOffenePostenListQuery, {
    variables: {
      includeAusgeziffert: isBuchungTypeRuecklastschrift(bookingSuggestion.buchungsanweisung.buchungType.value), //Rücklastschrift => true, Zahlung => false
      kontoId: personenkontoList.length ? personenkontoList[0].konto.kontoId : undefined,
      buchungskreisId: bookingSuggestion.buchungsanweisung.buchungskreisRechtstraeger?.buchungskreisId,
    },
    skip: !personenkontoList.length,
  });
  const offenePostenListForPersonenkonto = offenePostenForPersonenkontoData?.getOffenePostenList.data?.contentList ?? [];

  const initialValues = mapBookingSuggestionToInitialFormValues(bookingSuggestion, offenePostenListForPersonenkonto);

  return { initialValues, loading };
};
