import React from 'react';
import { TabsProps } from 'antd';
import { Outlet } from 'react-router';
import { uriToDataCarrierDebitCardOrBankWire, uriToDataCarrierIbanWithFibuKonto, uriToDataCarrierBankstatement } from '../uriDataCarrierPaths';
import RouteTabs from '../../../components/Tabs/RouteTabs';

const DataCarrierListing = () => {
  const items: TabsProps['items'] = [
    {
      key: uriToDataCarrierBankstatement,
      label: 'Kontoauszüge',
      children: <Outlet />,
    },
    {
      key: uriToDataCarrierDebitCardOrBankWire,
      label: 'Überweisungen/Lastschriften',
      children: <Outlet />,
    },
    {
      key: uriToDataCarrierIbanWithFibuKonto,
      label: 'IBAN mit FIBU-Konto',
      children: <Outlet />,
    },
  ];

  return <RouteTabs items={items} />;
};

export default DataCarrierListing;
