import { View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { InfoAbrechnungskreis } from '../../../objektAbrechnung/objektAbrechnung-types';
import OAAbrKreisKontoZwischensumme from '../../../objektAbrechnung/components/ObjektAbrechnungTableBlock/AbrechnungskreisBlock/shared/AusgabenKonto/OAAbrKreisKontoZwischensumme';
import OAAInfobrKreisKontoBezeichnung from './InfoAbrKreisKontoBezeichnung';
import InfoAbrKreisKontoBuchungenAusgabe from './InfoAbrKreisKontoBuchungenAusgabe';
import InfoAbrKreisSummeErgebnis from './InfoAbrKreisSummeErgebnis';
import InfoAbrKreisSummeAusgaben from './InfoAbrKreisSummeAusgaben';
import InfoAbrKreisSummeEinnahmen from './InfoAbrKreisSummeEinnahmen';
import InfoAbrKreisEinnahmeList from './InfoAbrKreisEinnahmeList';

const InfoAbrKreisKontoList: FC<{ abrechKreis: InfoAbrechnungskreis }> = ({ abrechKreis }) => (
  <>
    {abrechKreis.kontoList.map((konto, index) => (
      <View style={[pdfStyles.column]} key={index} wrap={false}>
        {/* Kontobezeichnung */}
        <OAAInfobrKreisKontoBezeichnung konto={konto} />

        {konto.buchungAusgabenList.length > 0 && (
          <>
            {/* Buchungen Ausgabe */}
            {konto.buchungAusgabenList.map((buchungAusgabe, index) => (
              <InfoAbrKreisKontoBuchungenAusgabe buchungAusgabe={buchungAusgabe} key={index} />
            ))}
            {/* Zwischensumme */}
            <OAAbrKreisKontoZwischensumme konto={konto} />
          </>
        )}
        {/* Summe Ausgaben */}
        <InfoAbrKreisSummeAusgaben abrechKreis={abrechKreis} />

        {abrechKreis.buchungEinnahmenList.length > 0 && (
          <>
            {/* Buchung Einnahme block */}
            <InfoAbrKreisEinnahmeList buchungEinnahmenList={abrechKreis.buchungEinnahmenList} />

            {/* Summe Einnahmen */}
            <InfoAbrKreisSummeEinnahmen abrechKreis={abrechKreis} />

            {/* Summe Ergebnis */}
            <InfoAbrKreisSummeErgebnis abrechKreis={abrechKreis} />
          </>
        )}
      </View>
    ))}
  </>
);

export default InfoAbrKreisKontoList;
