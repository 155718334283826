import React from 'react';
import { useParams } from 'react-router-dom';
import BestandseinheitCard from '../../features/Bestandseinheit/Card/BestandseinheitCard';
import menuListBestandseinheit from './menuListBestandseinheit';
import { useBestandseinheitQuery } from '../../features/Bestandseinheit/gql/BestandseinheitQueries.types';
import DetailsPageTemplate from '../../components/Template/DetailsPageTemplate';
import { BestandseinheitIdsProvider } from '../../features/Bestandseinheit/bestandseinheitIdsContext';
import { BestandseinheitDetailsPageRouteParams } from './routes';
import { useObjektQuery } from '../../features/Objekt/gql/ObjektQueries.types';
import { Bestandseinheit } from '../../types';

const BestandseinheitDetailsPage = () => {
  const { objektId, bestandseinheitId } = useParams() as BestandseinheitDetailsPageRouteParams;

  const { data, refetch: reload } = useBestandseinheitQuery({
    variables: {
      objektId,
      bestandseinheitId,
      withDetails: true,
    },
  });
  const bestandseinheit = data?.getBestandseinheit.data;
  const loading = !bestandseinheit;

  const { data: objRechnungsausstellerData, loading: objRaLoading } = useObjektQuery({ variables: { objektId } });
  const rechnungsausstellerId = objRechnungsausstellerData?.getObjekt.data.rechnungsAussteller.rechtstraegerId;

  return (
    <BestandseinheitIdsProvider value={{ objektId, bestandseinheitId }}>
      <DetailsPageTemplate<Bestandseinheit>
        data={bestandseinheit}
        isLoading={loading || objRaLoading}
        pageTitle={(be) => `${be.address.street} ${be.address.houseEntranceApartmentNumber} / ${be.bezeichnung}`}
        card={(be) => <BestandseinheitCard objektId={objektId} bestandseinheit={be} isLoading={loading} reloadBestandseinheit={reload} />}
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        sidebarMenuList={(be) => menuListBestandseinheit(objektId, bestandseinheitId, be, rechnungsausstellerId!)}
      />
    </BestandseinheitIdsProvider>
  );
};

export default BestandseinheitDetailsPage;
