import React from 'react';
import { Tooltip, Typography } from 'antd';
import { TableWithAlignedColsColumnType } from '../../../../../../../components/Table/TableWithAlignedCols/tableWithAlignedColsProps';
import { EuroAmount } from '../../../../../../../components/Number';
import { HeAbrOffenePostenList } from '../../../../../../../types';
import TextForEmptyValue from '../../../../../../../components/Text/TextForEmptyValue';

const heVertragOffenePostenTableColumns = (width?: number): TableWithAlignedColsColumnType<HeAbrOffenePostenList>[] => [
  {
    title: 'Position',
    align: 'left',
    render: () => <Typography.Text>Zahlungsrückstände</Typography.Text>,
  },
  {
    title: 'Objektsumme',
    align: 'right',
    render: (op) => <EuroAmount value={op.summe} />,
  },
  {
    title: 'HE-Anteil',
    align: 'right',
    width: width ? `${width}px` : undefined,
    render: (op) =>
      op.anteil ? (
        <EuroAmount value={op.anteil} />
      ) : (
        <Tooltip title="Rückstände der Abrechnungsperiode in Abrechnungsergebnis werden nicht berücksichtigen">
          <TextForEmptyValue textToShow="minus" />
        </Tooltip>
      ),
  },
];

export default heVertragOffenePostenTableColumns;
