import React, { FC } from 'react';
import { Box } from 'rebass';

import { Tag } from 'antd';
import { isToday } from '../../../../helpers/dateHelper';
import { AbrechnungsdefinitionVersionListData } from '../../shared/abrDefTypes';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

type AbrDefVersionTimelineDateTitleProps = {
  version: AbrechnungsdefinitionVersionListData;
};

const AbrDefVersionTimelineDateTitle: FC<AbrDefVersionTimelineDateTitleProps> = ({ version }) => (
  <Box mb="8px" fontWeight={version.current ? 500 : 'default'}>
    Änderung gültig ab {isToday(version.validFrom) ? 'heute' : <CustomFormattedDate value={version.validFrom} />}{' '}
    {version.current && <Tag>Aktiv</Tag>}
  </Box>
);

export default AbrDefVersionTimelineDateTitle;
