import React, { FC } from 'react';
import { Button } from 'antd';
import { FormikProps } from 'formik';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../components/Button/ButtonsAligned';
import RechtsTraegerActions from './RechtsTraegerActions';
import { filtersFormFields, FiltersFormValues } from './Filters/filtersFormMapper';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
  onAction: () => void;
};

const RechtstraegerBtnAndModal: FC<Props> = ({ formikProps, onAction }) => {
  return (
    <ButtonsAligned usage={ButtonsAlignedUsage.drawerFooter}>
      <Button
        onClick={() => {
          formikProps.setFieldValue(filtersFormFields.showArchived, !formikProps.values.showArchived);
          formikProps.submitForm();
        }}
      >
        Archivierte Einträge {formikProps.values.showArchived ? 'ausblenden' : 'anzeigen'}
      </Button>
      <RechtsTraegerActions onSuccess={onAction} />
    </ButtonsAligned>
  );
};

export default RechtstraegerBtnAndModal;
