import React, { FC } from 'react';
import { Modal } from 'antd';
import SepaMandatActivateOrUpdateForm from './SepaMandatActivateOrUpdateForm';
import { BankDetails, FirmendatenBankDetails } from '../../../../../types';

type Props = {
  modalType: 'update' | 'aktivate';
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  rechtstraegerIdForSepaMandat: string;
  bankDetails: BankDetails | FirmendatenBankDetails;
};

const ActivateOrUpdateModal: FC<Props> = ({ modalType, open, onCancel, onSuccess, rechtstraegerIdForSepaMandat, bankDetails }) => {
  const isUpdate = modalType === 'update';

  return (
    <Modal
      title={`SEPA Mandat ${isUpdate ? 'bearbeiten' : 'aktivieren'}`}
      open={open}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      maskClosable={false}
    >
      <SepaMandatActivateOrUpdateForm
        isUpdate={isUpdate}
        onCancel={onCancel}
        onSuccess={onSuccess}
        bankDetailsId={bankDetails.bankDetailsId}
        rechtstraegerId={rechtstraegerIdForSepaMandat}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        sepaMandat={bankDetails.sepaMandat!}
      />
    </Modal>
  );
};

export default ActivateOrUpdateModal;
