import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { HistoryListFieldsFragmentDoc } from '../../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ObjektVorschreibungspositionListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type ObjektVorschreibungspositionListQuery = {
  getObjektVorschreibungspositionList: {
    data: Array<{
      bezeichnung: string;
      bezeichnungOriginal: string;
      bezeichnungOverwritten?: string | null;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      kurzBezeichnung: string;
      objektVorschreibungspositionId: string;
      tagList: Array<string>;
      vorschreibungspositionId: string;
      art: { text: string; value: Types.VorschreibungspositionArt };
      objektVorschreibungspositionStatus: { text: string; value: Types.ObjektVorschreibungspositionStatus };
      umsatzsteuerkennzeichen?: {
        bezeichnung: string;
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kuerzel: string;
        umsatzsteuerkennzeichenId: string;
        status: { text: string; value: Types.UmsatzsteuerkennzeichenStatus; description?: string | null };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      } | null;
      vertragsartList: Array<{ supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart }>;
      vorschreibungspositionStatus: { description?: string | null; text: string; value: Types.VorschreibungspositionStatus };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type ObjektVorschreibungspositionChangeHistoryListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  objektVorschreibungspositionId: Types.Scalars['ID']['input'];
}>;

export type ObjektVorschreibungspositionChangeHistoryListQuery = {
  getObjektVorschreibungspositionChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const ObjektVorschreibungspositionListDocument = gql`
  query ObjektVorschreibungspositionList($objektId: ID!, $withDetails: Boolean, $includeArchiviert: Boolean) {
    getObjektVorschreibungspositionList(objektId: $objektId, withDetails: $withDetails, includeArchiviert: $includeArchiviert) {
      data {
        art {
          text
          value
        }
        bezeichnung
        bezeichnungOriginal
        bezeichnungOverwritten
        createTs
        createdBy
        createdByMitarbeiterId
        deletable
        kurzBezeichnung
        objektVorschreibungspositionId
        objektVorschreibungspositionStatus {
          text
          value
        }
        tagList
        umsatzsteuerkennzeichen {
          bezeichnung
          createTs
          createdBy
          createdByMitarbeiterId
          kuerzel
          status {
            text
            value
            description
          }
          umsatzsteuerkennzeichenId
          warningList {
            attribute
            message
            type
          }
        }
        vertragsartList {
          supportsExplicitVertragsEnde
          text
          value
        }
        vorschreibungspositionId
        vorschreibungspositionStatus {
          description
          text
          value
        }
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useObjektVorschreibungspositionListQuery(
  baseOptions: Apollo.QueryHookOptions<ObjektVorschreibungspositionListQuery, ObjektVorschreibungspositionListQueryVariables> &
    ({ variables: ObjektVorschreibungspositionListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ObjektVorschreibungspositionListQuery, ObjektVorschreibungspositionListQueryVariables>(
    ObjektVorschreibungspositionListDocument,
    options
  );
}
export function useObjektVorschreibungspositionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ObjektVorschreibungspositionListQuery, ObjektVorschreibungspositionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ObjektVorschreibungspositionListQuery, ObjektVorschreibungspositionListQueryVariables>(
    ObjektVorschreibungspositionListDocument,
    options
  );
}
export function useObjektVorschreibungspositionListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ObjektVorschreibungspositionListQuery, ObjektVorschreibungspositionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ObjektVorschreibungspositionListQuery, ObjektVorschreibungspositionListQueryVariables>(
    ObjektVorschreibungspositionListDocument,
    options
  );
}
export type ObjektVorschreibungspositionListQueryHookResult = ReturnType<typeof useObjektVorschreibungspositionListQuery>;
export type ObjektVorschreibungspositionListLazyQueryHookResult = ReturnType<typeof useObjektVorschreibungspositionListLazyQuery>;
export type ObjektVorschreibungspositionListSuspenseQueryHookResult = ReturnType<typeof useObjektVorschreibungspositionListSuspenseQuery>;
export type ObjektVorschreibungspositionListQueryResult = Apollo.QueryResult<
  ObjektVorschreibungspositionListQuery,
  ObjektVorschreibungspositionListQueryVariables
>;
export const ObjektVorschreibungspositionChangeHistoryListDocument = gql`
  query ObjektVorschreibungspositionChangeHistoryList($objektId: ID!, $objektVorschreibungspositionId: ID!) {
    getObjektVorschreibungspositionChangeHistoryList(objektId: $objektId, objektVorschreibungspositionId: $objektVorschreibungspositionId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useObjektVorschreibungspositionChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<
    ObjektVorschreibungspositionChangeHistoryListQuery,
    ObjektVorschreibungspositionChangeHistoryListQueryVariables
  > &
    ({ variables: ObjektVorschreibungspositionChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ObjektVorschreibungspositionChangeHistoryListQuery, ObjektVorschreibungspositionChangeHistoryListQueryVariables>(
    ObjektVorschreibungspositionChangeHistoryListDocument,
    options
  );
}
export function useObjektVorschreibungspositionChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ObjektVorschreibungspositionChangeHistoryListQuery,
    ObjektVorschreibungspositionChangeHistoryListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ObjektVorschreibungspositionChangeHistoryListQuery, ObjektVorschreibungspositionChangeHistoryListQueryVariables>(
    ObjektVorschreibungspositionChangeHistoryListDocument,
    options
  );
}
export function useObjektVorschreibungspositionChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ObjektVorschreibungspositionChangeHistoryListQuery, ObjektVorschreibungspositionChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ObjektVorschreibungspositionChangeHistoryListQuery, ObjektVorschreibungspositionChangeHistoryListQueryVariables>(
    ObjektVorschreibungspositionChangeHistoryListDocument,
    options
  );
}
export type ObjektVorschreibungspositionChangeHistoryListQueryHookResult = ReturnType<typeof useObjektVorschreibungspositionChangeHistoryListQuery>;
export type ObjektVorschreibungspositionChangeHistoryListLazyQueryHookResult = ReturnType<
  typeof useObjektVorschreibungspositionChangeHistoryListLazyQuery
>;
export type ObjektVorschreibungspositionChangeHistoryListSuspenseQueryHookResult = ReturnType<
  typeof useObjektVorschreibungspositionChangeHistoryListSuspenseQuery
>;
export type ObjektVorschreibungspositionChangeHistoryListQueryResult = Apollo.QueryResult<
  ObjektVorschreibungspositionChangeHistoryListQuery,
  ObjektVorschreibungspositionChangeHistoryListQueryVariables
>;
