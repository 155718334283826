import { TransferItem } from 'antd/lib/transfer';
import {
  Aufteilungsschluessel,
  AufteilungsschluesselStatus,
  ObjektAufteilungsschluessel,
  ObjektAufteilungsschluesselStatus,
} from '../../../../../../types';

export const getIdList = (objektAufteilungsschluesselList: ObjektAufteilungsschluessel[]) =>
  objektAufteilungsschluesselList.map((schluessel) => schluessel.aufteilungsschluesselId);

type AufteilungsschluesselTransferItem = TransferItem & Aufteilungsschluessel;

export const mapToTransferItems = (
  aufteilungsschluesselList: Aufteilungsschluessel[],
  objektAufteilungsschluesselList: ObjektAufteilungsschluessel[]
): AufteilungsschluesselTransferItem[] => {
  return aufteilungsschluesselList
    .filter((aufteilungsschluessel) =>
      isAufteilungschluesselNotArchivedOrArchivedAndAssignedToObjekt(aufteilungsschluessel, objektAufteilungsschluesselList)
    )
    .map((aufteilungsschluessel) => addDisabledAndKeyAttr(aufteilungsschluessel, objektAufteilungsschluesselList));
};

const isAufteilungschluesselNotArchivedOrArchivedAndAssignedToObjekt = (
  aufteilungsschluessel: Aufteilungsschluessel,
  objektAufteilungsschluesselList: ObjektAufteilungsschluessel[]
) =>
  aufteilungsschluessel.status.value !== AufteilungsschluesselStatus.Archiviert ||
  (aufteilungsschluessel.status.value === AufteilungsschluesselStatus.Archiviert &&
    getIdList(objektAufteilungsschluesselList).includes(aufteilungsschluessel.aufteilungsschluesselId));

const addDisabledAndKeyAttr = (aufteilungsschluessel: Aufteilungsschluessel, objektAufteilungsschluesselList: ObjektAufteilungsschluessel[]) => {
  // add disabled key and value to each Aufteilungschlüssel based on deletable value of available ObjektAufteilungschlüssel
  const sameObjectAufteilungSchluessel = objektAufteilungsschluesselList.findIndex(
    (objektAufteilungsschluessel) => objektAufteilungsschluessel.aufteilungsschluesselId === aufteilungsschluessel.aufteilungsschluesselId
  );

  const isObjektAufteilungschluesselDisabled =
    sameObjectAufteilungSchluessel >= 0 &&
    objektAufteilungsschluesselList[sameObjectAufteilungSchluessel].objektAufteilungsschluesselStatus.value ===
      ObjektAufteilungsschluesselStatus.Aktiv
      ? !objektAufteilungsschluesselList[sameObjectAufteilungSchluessel].deletable
      : true;

  const aufteilungsschluesselWithDisabled =
    sameObjectAufteilungSchluessel >= 0
      ? { disabled: isObjektAufteilungschluesselDisabled, ...aufteilungsschluessel }
      : { disabled: false, ...aufteilungsschluessel };

  return {
    key: aufteilungsschluessel.aufteilungsschluesselId,
    ...aufteilungsschluesselWithDisabled,
  };
};
