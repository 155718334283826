import React, { FC } from 'react';
import { Formik } from 'formik';
import { DatePicker, Form, Input, Select } from 'formik-antd';
import { Col, Divider, Row, Typography } from 'antd';
import FormButtons from '../../../components/Button/FormButtons';
import UmsatzsteuerkennzeichenSelect from '../../shared/Umsatzsteuerkennzeichen/UmsatzsteuerkennzeichenSelect';
import { VorschreibungspositionFormValidationSchema } from './vorschreibungspositionFormValidationSchema';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import KSFirmendatenSelect from '../../KSFirmendatenSelect/KSFirmendatenSelect';
import { Spacer } from '../../../components/Grid';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import KontierungstabelleSelect from '../../shared/KontierungstabelleSelect';
import KurzBezeichnungFormPart from '../../../shared/components/KurzBezeichnung/form/KurzBezeichnungFormPart';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../components/DatePicker/DatePicker_formikAntD';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import { Vorschreibungsposition } from '../../../types';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';
import {
  useCreateFdVorschreibungspositionMutation,
  useCreateKsVorschreibungspositionMutation,
  useUpdateVorschreibungspositionMutation,
} from '../gql/VorschreibungspositionMutations.types';
import VorschreibungspositionArtSelect from './VorschreibunspositionArtSelect/VorschreibungspositionArtSelect';
import {
  mapFormValuesToVorschreibungspositionCreate,
  mapFormValuesToVorschreibungspositionUpdate,
  mapVorschreibungspositionToFormValues,
  vorschreibungspositionFormFields,
  VorschreibungspositionFormValues,
} from './vorschreibungspositionFormMapper';

type Props = {
  vorschreibungsposition?: Vorschreibungsposition;
  onSuccess: () => void;
  onCancel: () => void;
};

const VorschreibungspositionForm: FC<Props> = ({ vorschreibungsposition, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'Vorschreibungsposition'>('Vorschreibungsposition');
  const isUpdate = !!vorschreibungsposition;
  const entity = 'Vorschreibungsposition';

  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();

  const [createForFD, { loading: loadingCreateForFD }] = useCreateFdVorschreibungspositionMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [createForKS, { loading: loadingCreateForKS }] = useCreateKsVorschreibungspositionMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [updateVP, { loading: loadingUpdate }] = useUpdateVorschreibungspositionMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<VorschreibungspositionFormValues>
      initialValues={mapVorschreibungspositionToFormValues(vorschreibungsposition)}
      validationSchema={VorschreibungspositionFormValidationSchema}
      onSubmit={(formValues, formikHelpers) => {
        if (!vorschreibungsposition) {
          if (formValues.firmendatenId) {
            createForFD({
              variables: { firmendatenId: formValues.firmendatenId, input: mapFormValuesToVorschreibungspositionCreate(formValues) },
            }).finally(() => formikHelpers.setSubmitting(false));
          } else {
            createForKS({ variables: { kundenSystemId, input: mapFormValuesToVorschreibungspositionCreate(formValues) } }).finally(() =>
              formikHelpers.setSubmitting(false)
            );
          }
        } else {
          updateVP({
            variables: {
              vorschreibungspositionId: vorschreibungsposition.vorschreibungspositionId,
              input: mapFormValuesToVorschreibungspositionUpdate(formValues),
            },
          }).finally(() => formikHelpers.setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Gültig für</Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithoutColon
                name={vorschreibungspositionFormFields.firmendatenId}
                label="Firma auswählen"
                help="Vorschreibungsposition wird nur für diese Firma angewendet"
              >
                <KSFirmendatenSelect
                  name={vorschreibungspositionFormFields.firmendatenId}
                  id={vorschreibungspositionFormFields.firmendatenId}
                  disabled={isUpdate}
                  placeholder={!isUpdate ? 'Firma' : 'Keine Firma'}
                />
              </FormItemWithoutColon>
            </Col>
          </Row>

          <Spacer height={16} />

          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Kurzinformationen</Typography.Title>
            </Col>
            <Col span={18}>
              <Row gutter={8}>
                <Col span={12}>
                  <KurzBezeichnungFormPart<VorschreibungspositionFormValues>
                    placeholder="z.B. BK"
                    formikProps={formikProps}
                    fieldHelp={getFieldHelpText('Vorschreibungsposition.kurzBezeichnung')}
                  />
                </Col>
                <Col span={12}>
                  <FormItemWithFieldHelp
                    name={vorschreibungspositionFormFields.bezeichnung}
                    label="Bezeichnung"
                    fieldHelp={getFieldHelpText('Vorschreibungsposition.bezeichnung')}
                  >
                    <Input
                      name={vorschreibungspositionFormFields.bezeichnung}
                      id={vorschreibungspositionFormFields.bezeichnung}
                      placeholder="z.B. Betriebskostenkonto"
                    />
                  </FormItemWithFieldHelp>
                </Col>
              </Row>
            </Col>
          </Row>

          <Divider />

          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Positionsbasis</Typography.Title>
            </Col>
            <Col span={18}>
              <FormItemWithFieldHelp
                name={vorschreibungspositionFormFields.art}
                label="Vorschreibungspositionart"
                fieldHelp={getFieldHelpText('Vorschreibungsposition.art')}
              >
                <VorschreibungspositionArtSelect name={vorschreibungspositionFormFields.art} />
              </FormItemWithFieldHelp>
              <FormItemWithFieldHelp
                label="Merkmale"
                name={vorschreibungspositionFormFields.tagList}
                fieldHelp={getFieldHelpText('Vorschreibungsposition.tagList')}
              >
                <Select name={vorschreibungspositionFormFields.tagList} id="tagList" mode="tags" placeholder="z.B. WE, BK" />
              </FormItemWithFieldHelp>
            </Col>
          </Row>

          {!isUpdate ? (
            <Row align="top">
              {/* TODO: find a good name for this block */}
              <Col span={6} />
              <Col span={18}>
                <FormItemWithFieldHelp
                  name={vorschreibungspositionFormFields.versionValidFrom}
                  label="Gültig ab"
                  fieldHelp={getFieldHelpText('Vorschreibungsposition.version.validFrom')}
                >
                  <DatePicker
                    size="small"
                    id={vorschreibungspositionFormFields.versionValidFrom}
                    name={vorschreibungspositionFormFields.versionValidFrom}
                    style={{ width: '100%' }}
                    format={DATE_DISPLAY_FORMAT_DEFAULT}
                  />
                </FormItemWithFieldHelp>
                <Row gutter={8}>
                  <Col span={12}>
                    <FormItemWithFieldHelp
                      name={vorschreibungspositionFormFields.umsatzsteuerkennzeichenId}
                      label="Umsatzsteuerkennzeichen"
                      fieldHelp={getFieldHelpText('Vorschreibungsposition.version.umsatzsteuerkennzeichenId')}
                    >
                      <UmsatzsteuerkennzeichenSelect name={vorschreibungspositionFormFields.umsatzsteuerkennzeichenId} />
                    </FormItemWithFieldHelp>
                  </Col>
                  <Col span={12}>
                    <FormItemWithFieldHelp
                      name={vorschreibungspositionFormFields.kontierungstabelleId}
                      label="Kontierungstabelle"
                      fieldHelp={getFieldHelpText('Vorschreibungsposition.version.kontierungstabelleId')}
                    >
                      <KontierungstabelleSelect
                        name={vorschreibungspositionFormFields.kontierungstabelleId}
                        firmendatenId={formikProps.values.firmendatenId}
                      />
                    </FormItemWithFieldHelp>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : null}

          <FormButtons
            updateMode={isUpdate}
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={loadingCreateForFD || loadingCreateForKS || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default VorschreibungspositionForm;
