import React, { FC } from 'react';
import { Divider, Space, Typography } from 'antd';
import { vertragVersionFormValuesDescriptions } from '../Version/Form/vertragVersionFormMapper';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import { BeVertragFragment } from '../gql/BeVertragFragments.types';
import ContextMenu from '../../../../components/Timeline/ContextMenu';
import { isVertragsartTypeLeerstehung } from '../../shared/vertragsartHelpers';

type Props = {
  vertrag: BeVertragFragment;
  openTimelineUpdateDrawer: () => void;
};

const VertragRechnungDetails: FC<Props> = ({ vertrag, openTimelineUpdateDrawer }) => (
  <>
    <Space style={{ width: '100%', justifyContent: 'space-between' }}>
      <Typography.Title level={5}>Verrechnung</Typography.Title>
      <ContextMenu
        openDrawerCreate={openTimelineUpdateDrawer}
        withHistory
        disabledHistory={isVertragsartTypeLeerstehung(vertrag.vertragsart.value)}
      />
    </Space>
    <CardDetailsInfoRow infoRowTitle={vertragVersionFormValuesDescriptions.dueDateVorschreibung}>
      <Typography.Text>{vertrag.dueDateVorschreibung.text}</Typography.Text>
    </CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle={vertragVersionFormValuesDescriptions.verrechnungsart}>
      <Typography.Text>{vertrag.verrechnungsart.text}</Typography.Text>
    </CardDetailsInfoRow>
    <CardDetailsInfoRow infoRowTitle={vertragVersionFormValuesDescriptions.paymentMethod}>
      <Typography.Text>{vertrag.paymentMethod.text}</Typography.Text>
    </CardDetailsInfoRow>
    <Divider dashed />
  </>
);

export default VertragRechnungDetails;
