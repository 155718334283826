import { FC } from 'react';
import HistoryListingTable from '../../../../History/HistoryListingTable';
import { HistoryType } from '../../../../History/shared/HistoryModal';
import { usePaymentChangeHistoryListQuery } from '../../../gql/Order/PaymentQueries.types';

type Props = {
  paymentId: string;
};

const PaymentChangeHistoryListingTable: FC<Props> = ({ paymentId }) => {
  const { data, loading } = usePaymentChangeHistoryListQuery({ variables: { paymentId } });
  const historyList = data?.getPaymentChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="payment" />;
};

export default PaymentChangeHistoryListingTable;
