import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { TDeviationCell, TDeviationReference } from '../../../deviation.model';
import { generateURIforDeviation } from '../../../deviationHelpers';
import { DeviationCellStyledText, deviationCellTextColor } from '../../../Styled/Deviation.style';
import DataWithShortenedTextAndExtraInfo from '../../../../Helpers/DataWithShortenedTextAndExtraInfo';

type Props = {
  cell: TDeviationCell;
  referenceOfCauseList: TDeviationReference[];
  columnCount: number;
};

const ColWithOneReferenceOfCause: FC<Props> = ({ cell, referenceOfCauseList, columnCount }) => {
  const content = (
    <DataWithShortenedTextAndExtraInfo maxTextLength={columnCount > 8 ? 10 : 15} text={referenceOfCauseList[0].text}>
      {(shortenedText) => (
        <DeviationCellStyledText>
          <Typography.Text underline strong={cell.hasChanged} style={{ color: deviationCellTextColor(cell) }}>
            {shortenedText}
          </Typography.Text>
        </DeviationCellStyledText>
      )}
    </DataWithShortenedTextAndExtraInfo>
  );

  return referenceOfCauseList[0].uriParams ? (
    <Link to={generateURIforDeviation(referenceOfCauseList[0].uriParams)} target="_blank">
      {content}
    </Link>
  ) : (
    <>{content}</>
  );
};

export default ColWithOneReferenceOfCause;
