import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBudgetingMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  input: Types.BudgetingCreateInput;
}>;

export type CreateBudgetingMutation = {
  createBudgeting: {
    data: { generierlaufId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingAdjustAufteilungsschluesselMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  budgetingId: Types.Scalars['ID']['input'];
  input: Types.BudgetingActionAdjustAufteilungsschluesselInput;
}>;

export type BudgetingAdjustAufteilungsschluesselMutation = {
  actionBudgetingAdjustAufteilungsschluessel: {
    data: { budgetingId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingConfigureFinancialPlanTextsMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  budgetingId: Types.Scalars['ID']['input'];
  input: Types.BudgetingActionConfigureFinancialPlanTextsInput;
}>;

export type BudgetingConfigureFinancialPlanTextsMutation = {
  actionBudgetingConfigureFinancialPlanTexts: {
    data: { budgetingId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingCreateVPosValuesFromVPosMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  budgetingId: Types.Scalars['ID']['input'];
  input: Types.BudgetingActionCreateVPosValuesFromVPosInput;
}>;

export type BudgetingCreateVPosValuesFromVPosMutation = {
  actionBudgetingCreateVPosValuesFromVPos: {
    data: { budgetingId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingCreateVPosValuesFromAufwandMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  budgetingId: Types.Scalars['ID']['input'];
  input: Types.BudgetingActionCreateVPosValuesFromAufwandInput;
}>;

export type BudgetingCreateVPosValuesFromAufwandMutation = {
  actionBudgetingCreateVPosValuesFromAufwand: {
    data: { budgetingId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingResetKontoEstimateMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  budgetingId: Types.Scalars['ID']['input'];
  input: Types.BudgetingActionResetKontoInput;
}>;

export type BudgetingResetKontoEstimateMutation = {
  actionBudgetingResetKontoEstimate: {
    data: { budgetingId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingTransferToVPosMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  budgetingId: Types.Scalars['ID']['input'];
  input: Types.BudgetingActionTransferToVPosActionInput;
}>;

export type BudgetingTransferToVPosMutation = {
  actionBudgetingTransferToVPos: {
    data: { budgetingId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingUpdateKontoEstimateMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  budgetingId: Types.Scalars['ID']['input'];
  input: Types.BudgetingActionUpdateKontoInput;
}>;

export type BudgetingUpdateKontoEstimateMutation = {
  actionBudgetingUpdateKontoEstimate: {
    data: { budgetingId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingUpdateVPosEstimateMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  budgetingId: Types.Scalars['ID']['input'];
  input: Types.BudgetingActionUpdateVPosInput;
}>;

export type BudgetingUpdateVPosEstimateMutation = {
  actionBudgetingUpdateVPosEstimate: {
    data: { budgetingId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingDeleteVPosValuesMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  budgetingId: Types.Scalars['ID']['input'];
}>;

export type BudgetingDeleteVPosValuesMutation = {
  actionBudgetingDeleteVPosValues: {
    data: { budgetingId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingArchivierenMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  budgetingId: Types.Scalars['ID']['input'];
}>;

export type BudgetingArchivierenMutation = {
  actionBudgetingArchivieren: {
    data: { budgetingId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BudgetingReaktivierenMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  budgetingId: Types.Scalars['ID']['input'];
}>;

export type BudgetingReaktivierenMutation = {
  actionBudgetingReaktivieren: {
    data: { budgetingId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteBudgetingMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  budgetingId: Types.Scalars['ID']['input'];
}>;

export type DeleteBudgetingMutation = {
  deleteBudgeting: { errorList: Array<{ message: string; type: string }>; warningList: Array<{ message: string; type: string }> };
};

export type DeleteObjektFinancialPlanMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  budgetingId: Types.Scalars['ID']['input'];
  input: Types.ObjektFinancialPlanDeleteInput;
}>;

export type DeleteObjektFinancialPlanMutation = {
  deleteObjektFinancialPlan: { errorList: Array<{ message: string; type: string }>; warningList: Array<{ message: string; type: string }> };
};

export type DeleteTopFinancialPlanMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  budgetingId: Types.Scalars['ID']['input'];
  input: Types.TopFinancialPlanDeleteInput;
}>;

export type DeleteTopFinancialPlanMutation = {
  deleteTopFinancialPlan: { errorList: Array<{ message: string; type: string }>; warningList: Array<{ message: string; type: string }> };
};

export const CreateBudgetingDocument = gql`
  mutation CreateBudgeting($objektId: ID!, $input: BudgetingCreateInput!) {
    createBudgeting(objektId: $objektId, input: $input) {
      data {
        generierlaufId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useCreateBudgetingMutation(baseOptions?: Apollo.MutationHookOptions<CreateBudgetingMutation, CreateBudgetingMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBudgetingMutation, CreateBudgetingMutationVariables>(CreateBudgetingDocument, options);
}
export type CreateBudgetingMutationHookResult = ReturnType<typeof useCreateBudgetingMutation>;
export type CreateBudgetingMutationResult = Apollo.MutationResult<CreateBudgetingMutation>;
export type CreateBudgetingMutationOptions = Apollo.BaseMutationOptions<CreateBudgetingMutation, CreateBudgetingMutationVariables>;
export const BudgetingAdjustAufteilungsschluesselDocument = gql`
  mutation BudgetingAdjustAufteilungsschluessel($objektId: ID!, $budgetingId: ID!, $input: BudgetingActionAdjustAufteilungsschluesselInput!) {
    actionBudgetingAdjustAufteilungsschluessel(objektId: $objektId, budgetingId: $budgetingId, input: $input) {
      data {
        budgetingId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBudgetingAdjustAufteilungsschluesselMutation(
  baseOptions?: Apollo.MutationHookOptions<BudgetingAdjustAufteilungsschluesselMutation, BudgetingAdjustAufteilungsschluesselMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BudgetingAdjustAufteilungsschluesselMutation, BudgetingAdjustAufteilungsschluesselMutationVariables>(
    BudgetingAdjustAufteilungsschluesselDocument,
    options
  );
}
export type BudgetingAdjustAufteilungsschluesselMutationHookResult = ReturnType<typeof useBudgetingAdjustAufteilungsschluesselMutation>;
export type BudgetingAdjustAufteilungsschluesselMutationResult = Apollo.MutationResult<BudgetingAdjustAufteilungsschluesselMutation>;
export type BudgetingAdjustAufteilungsschluesselMutationOptions = Apollo.BaseMutationOptions<
  BudgetingAdjustAufteilungsschluesselMutation,
  BudgetingAdjustAufteilungsschluesselMutationVariables
>;
export const BudgetingConfigureFinancialPlanTextsDocument = gql`
  mutation BudgetingConfigureFinancialPlanTexts($objektId: ID!, $budgetingId: ID!, $input: BudgetingActionConfigureFinancialPlanTextsInput!) {
    actionBudgetingConfigureFinancialPlanTexts(objektId: $objektId, budgetingId: $budgetingId, input: $input) {
      data {
        budgetingId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBudgetingConfigureFinancialPlanTextsMutation(
  baseOptions?: Apollo.MutationHookOptions<BudgetingConfigureFinancialPlanTextsMutation, BudgetingConfigureFinancialPlanTextsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BudgetingConfigureFinancialPlanTextsMutation, BudgetingConfigureFinancialPlanTextsMutationVariables>(
    BudgetingConfigureFinancialPlanTextsDocument,
    options
  );
}
export type BudgetingConfigureFinancialPlanTextsMutationHookResult = ReturnType<typeof useBudgetingConfigureFinancialPlanTextsMutation>;
export type BudgetingConfigureFinancialPlanTextsMutationResult = Apollo.MutationResult<BudgetingConfigureFinancialPlanTextsMutation>;
export type BudgetingConfigureFinancialPlanTextsMutationOptions = Apollo.BaseMutationOptions<
  BudgetingConfigureFinancialPlanTextsMutation,
  BudgetingConfigureFinancialPlanTextsMutationVariables
>;
export const BudgetingCreateVPosValuesFromVPosDocument = gql`
  mutation BudgetingCreateVPosValuesFromVPos($objektId: ID!, $budgetingId: ID!, $input: BudgetingActionCreateVPosValuesFromVPosInput!) {
    actionBudgetingCreateVPosValuesFromVPos(objektId: $objektId, budgetingId: $budgetingId, input: $input) {
      data {
        budgetingId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBudgetingCreateVPosValuesFromVPosMutation(
  baseOptions?: Apollo.MutationHookOptions<BudgetingCreateVPosValuesFromVPosMutation, BudgetingCreateVPosValuesFromVPosMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BudgetingCreateVPosValuesFromVPosMutation, BudgetingCreateVPosValuesFromVPosMutationVariables>(
    BudgetingCreateVPosValuesFromVPosDocument,
    options
  );
}
export type BudgetingCreateVPosValuesFromVPosMutationHookResult = ReturnType<typeof useBudgetingCreateVPosValuesFromVPosMutation>;
export type BudgetingCreateVPosValuesFromVPosMutationResult = Apollo.MutationResult<BudgetingCreateVPosValuesFromVPosMutation>;
export type BudgetingCreateVPosValuesFromVPosMutationOptions = Apollo.BaseMutationOptions<
  BudgetingCreateVPosValuesFromVPosMutation,
  BudgetingCreateVPosValuesFromVPosMutationVariables
>;
export const BudgetingCreateVPosValuesFromAufwandDocument = gql`
  mutation BudgetingCreateVPosValuesFromAufwand($objektId: ID!, $budgetingId: ID!, $input: BudgetingActionCreateVPosValuesFromAufwandInput!) {
    actionBudgetingCreateVPosValuesFromAufwand(objektId: $objektId, budgetingId: $budgetingId, input: $input) {
      data {
        budgetingId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBudgetingCreateVPosValuesFromAufwandMutation(
  baseOptions?: Apollo.MutationHookOptions<BudgetingCreateVPosValuesFromAufwandMutation, BudgetingCreateVPosValuesFromAufwandMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BudgetingCreateVPosValuesFromAufwandMutation, BudgetingCreateVPosValuesFromAufwandMutationVariables>(
    BudgetingCreateVPosValuesFromAufwandDocument,
    options
  );
}
export type BudgetingCreateVPosValuesFromAufwandMutationHookResult = ReturnType<typeof useBudgetingCreateVPosValuesFromAufwandMutation>;
export type BudgetingCreateVPosValuesFromAufwandMutationResult = Apollo.MutationResult<BudgetingCreateVPosValuesFromAufwandMutation>;
export type BudgetingCreateVPosValuesFromAufwandMutationOptions = Apollo.BaseMutationOptions<
  BudgetingCreateVPosValuesFromAufwandMutation,
  BudgetingCreateVPosValuesFromAufwandMutationVariables
>;
export const BudgetingResetKontoEstimateDocument = gql`
  mutation BudgetingResetKontoEstimate($objektId: ID!, $budgetingId: ID!, $input: BudgetingActionResetKontoInput!) {
    actionBudgetingResetKontoEstimate(objektId: $objektId, budgetingId: $budgetingId, input: $input) {
      data {
        budgetingId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBudgetingResetKontoEstimateMutation(
  baseOptions?: Apollo.MutationHookOptions<BudgetingResetKontoEstimateMutation, BudgetingResetKontoEstimateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BudgetingResetKontoEstimateMutation, BudgetingResetKontoEstimateMutationVariables>(
    BudgetingResetKontoEstimateDocument,
    options
  );
}
export type BudgetingResetKontoEstimateMutationHookResult = ReturnType<typeof useBudgetingResetKontoEstimateMutation>;
export type BudgetingResetKontoEstimateMutationResult = Apollo.MutationResult<BudgetingResetKontoEstimateMutation>;
export type BudgetingResetKontoEstimateMutationOptions = Apollo.BaseMutationOptions<
  BudgetingResetKontoEstimateMutation,
  BudgetingResetKontoEstimateMutationVariables
>;
export const BudgetingTransferToVPosDocument = gql`
  mutation BudgetingTransferToVPos($objektId: ID!, $budgetingId: ID!, $input: BudgetingActionTransferToVPosActionInput!) {
    actionBudgetingTransferToVPos(objektId: $objektId, budgetingId: $budgetingId, input: $input) {
      data {
        budgetingId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBudgetingTransferToVPosMutation(
  baseOptions?: Apollo.MutationHookOptions<BudgetingTransferToVPosMutation, BudgetingTransferToVPosMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BudgetingTransferToVPosMutation, BudgetingTransferToVPosMutationVariables>(BudgetingTransferToVPosDocument, options);
}
export type BudgetingTransferToVPosMutationHookResult = ReturnType<typeof useBudgetingTransferToVPosMutation>;
export type BudgetingTransferToVPosMutationResult = Apollo.MutationResult<BudgetingTransferToVPosMutation>;
export type BudgetingTransferToVPosMutationOptions = Apollo.BaseMutationOptions<
  BudgetingTransferToVPosMutation,
  BudgetingTransferToVPosMutationVariables
>;
export const BudgetingUpdateKontoEstimateDocument = gql`
  mutation BudgetingUpdateKontoEstimate($objektId: ID!, $budgetingId: ID!, $input: BudgetingActionUpdateKontoInput!) {
    actionBudgetingUpdateKontoEstimate(objektId: $objektId, budgetingId: $budgetingId, input: $input) {
      data {
        budgetingId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBudgetingUpdateKontoEstimateMutation(
  baseOptions?: Apollo.MutationHookOptions<BudgetingUpdateKontoEstimateMutation, BudgetingUpdateKontoEstimateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BudgetingUpdateKontoEstimateMutation, BudgetingUpdateKontoEstimateMutationVariables>(
    BudgetingUpdateKontoEstimateDocument,
    options
  );
}
export type BudgetingUpdateKontoEstimateMutationHookResult = ReturnType<typeof useBudgetingUpdateKontoEstimateMutation>;
export type BudgetingUpdateKontoEstimateMutationResult = Apollo.MutationResult<BudgetingUpdateKontoEstimateMutation>;
export type BudgetingUpdateKontoEstimateMutationOptions = Apollo.BaseMutationOptions<
  BudgetingUpdateKontoEstimateMutation,
  BudgetingUpdateKontoEstimateMutationVariables
>;
export const BudgetingUpdateVPosEstimateDocument = gql`
  mutation BudgetingUpdateVPosEstimate($objektId: ID!, $budgetingId: ID!, $input: BudgetingActionUpdateVPosInput!) {
    actionBudgetingUpdateVPosEstimate(objektId: $objektId, budgetingId: $budgetingId, input: $input) {
      data {
        budgetingId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBudgetingUpdateVPosEstimateMutation(
  baseOptions?: Apollo.MutationHookOptions<BudgetingUpdateVPosEstimateMutation, BudgetingUpdateVPosEstimateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BudgetingUpdateVPosEstimateMutation, BudgetingUpdateVPosEstimateMutationVariables>(
    BudgetingUpdateVPosEstimateDocument,
    options
  );
}
export type BudgetingUpdateVPosEstimateMutationHookResult = ReturnType<typeof useBudgetingUpdateVPosEstimateMutation>;
export type BudgetingUpdateVPosEstimateMutationResult = Apollo.MutationResult<BudgetingUpdateVPosEstimateMutation>;
export type BudgetingUpdateVPosEstimateMutationOptions = Apollo.BaseMutationOptions<
  BudgetingUpdateVPosEstimateMutation,
  BudgetingUpdateVPosEstimateMutationVariables
>;
export const BudgetingDeleteVPosValuesDocument = gql`
  mutation BudgetingDeleteVPosValues($objektId: ID!, $budgetingId: ID!) {
    actionBudgetingDeleteVPosValues(objektId: $objektId, budgetingId: $budgetingId) {
      data {
        budgetingId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBudgetingDeleteVPosValuesMutation(
  baseOptions?: Apollo.MutationHookOptions<BudgetingDeleteVPosValuesMutation, BudgetingDeleteVPosValuesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BudgetingDeleteVPosValuesMutation, BudgetingDeleteVPosValuesMutationVariables>(
    BudgetingDeleteVPosValuesDocument,
    options
  );
}
export type BudgetingDeleteVPosValuesMutationHookResult = ReturnType<typeof useBudgetingDeleteVPosValuesMutation>;
export type BudgetingDeleteVPosValuesMutationResult = Apollo.MutationResult<BudgetingDeleteVPosValuesMutation>;
export type BudgetingDeleteVPosValuesMutationOptions = Apollo.BaseMutationOptions<
  BudgetingDeleteVPosValuesMutation,
  BudgetingDeleteVPosValuesMutationVariables
>;
export const BudgetingArchivierenDocument = gql`
  mutation BudgetingArchivieren($objektId: ID!, $budgetingId: ID!) {
    actionBudgetingArchivieren(objektId: $objektId, budgetingId: $budgetingId) {
      data {
        budgetingId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBudgetingArchivierenMutation(
  baseOptions?: Apollo.MutationHookOptions<BudgetingArchivierenMutation, BudgetingArchivierenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BudgetingArchivierenMutation, BudgetingArchivierenMutationVariables>(BudgetingArchivierenDocument, options);
}
export type BudgetingArchivierenMutationHookResult = ReturnType<typeof useBudgetingArchivierenMutation>;
export type BudgetingArchivierenMutationResult = Apollo.MutationResult<BudgetingArchivierenMutation>;
export type BudgetingArchivierenMutationOptions = Apollo.BaseMutationOptions<BudgetingArchivierenMutation, BudgetingArchivierenMutationVariables>;
export const BudgetingReaktivierenDocument = gql`
  mutation BudgetingReaktivieren($objektId: ID!, $budgetingId: ID!) {
    actionBudgetingReaktivieren(objektId: $objektId, budgetingId: $budgetingId) {
      data {
        budgetingId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBudgetingReaktivierenMutation(
  baseOptions?: Apollo.MutationHookOptions<BudgetingReaktivierenMutation, BudgetingReaktivierenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BudgetingReaktivierenMutation, BudgetingReaktivierenMutationVariables>(BudgetingReaktivierenDocument, options);
}
export type BudgetingReaktivierenMutationHookResult = ReturnType<typeof useBudgetingReaktivierenMutation>;
export type BudgetingReaktivierenMutationResult = Apollo.MutationResult<BudgetingReaktivierenMutation>;
export type BudgetingReaktivierenMutationOptions = Apollo.BaseMutationOptions<BudgetingReaktivierenMutation, BudgetingReaktivierenMutationVariables>;
export const DeleteBudgetingDocument = gql`
  mutation DeleteBudgeting($objektId: ID!, $budgetingId: ID!) {
    deleteBudgeting(objektId: $objektId, budgetingId: $budgetingId) {
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeleteBudgetingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBudgetingMutation, DeleteBudgetingMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBudgetingMutation, DeleteBudgetingMutationVariables>(DeleteBudgetingDocument, options);
}
export type DeleteBudgetingMutationHookResult = ReturnType<typeof useDeleteBudgetingMutation>;
export type DeleteBudgetingMutationResult = Apollo.MutationResult<DeleteBudgetingMutation>;
export type DeleteBudgetingMutationOptions = Apollo.BaseMutationOptions<DeleteBudgetingMutation, DeleteBudgetingMutationVariables>;
export const DeleteObjektFinancialPlanDocument = gql`
  mutation DeleteObjektFinancialPlan($objektId: ID!, $budgetingId: ID!, $input: ObjektFinancialPlanDeleteInput!) {
    deleteObjektFinancialPlan(objektId: $objektId, budgetingId: $budgetingId, input: $input) {
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeleteObjektFinancialPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteObjektFinancialPlanMutation, DeleteObjektFinancialPlanMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteObjektFinancialPlanMutation, DeleteObjektFinancialPlanMutationVariables>(
    DeleteObjektFinancialPlanDocument,
    options
  );
}
export type DeleteObjektFinancialPlanMutationHookResult = ReturnType<typeof useDeleteObjektFinancialPlanMutation>;
export type DeleteObjektFinancialPlanMutationResult = Apollo.MutationResult<DeleteObjektFinancialPlanMutation>;
export type DeleteObjektFinancialPlanMutationOptions = Apollo.BaseMutationOptions<
  DeleteObjektFinancialPlanMutation,
  DeleteObjektFinancialPlanMutationVariables
>;
export const DeleteTopFinancialPlanDocument = gql`
  mutation DeleteTopFinancialPlan($objektId: ID!, $budgetingId: ID!, $input: TopFinancialPlanDeleteInput!) {
    deleteTopFinancialPlan(objektId: $objektId, budgetingId: $budgetingId, input: $input) {
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeleteTopFinancialPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTopFinancialPlanMutation, DeleteTopFinancialPlanMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTopFinancialPlanMutation, DeleteTopFinancialPlanMutationVariables>(DeleteTopFinancialPlanDocument, options);
}
export type DeleteTopFinancialPlanMutationHookResult = ReturnType<typeof useDeleteTopFinancialPlanMutation>;
export type DeleteTopFinancialPlanMutationResult = Apollo.MutationResult<DeleteTopFinancialPlanMutation>;
export type DeleteTopFinancialPlanMutationOptions = Apollo.BaseMutationOptions<
  DeleteTopFinancialPlanMutation,
  DeleteTopFinancialPlanMutationVariables
>;
