import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useFilterFibuBzKontoListQuery } from '../gql/FibuBuchungszeileQueries.types';
import { selectFilterOption } from '../../../helpers/selectHelper';
import TextWithTag from '../../../components/Text/TextWithTag';

type Props = {
  rechtstraegerIdList?: string[] | null;
} & Omit<SelectProps, 'id' | 'allowClear' | 'filterOption' | 'loading' | 'placeholder' | 'size' | 'showSearch'>;

const FibuBzKontoListFormSelect: FC<Props> = ({ rechtstraegerIdList, ...restProps }) => {
  const { loading, data } = useFilterFibuBzKontoListQuery({
    variables: {
      rechtstraegerIdList,
    },
  });
  const list = data?.getFilterFibuBzKontoList.data ?? [];

  return (
    <Select {...restProps} size="middle" id={restProps.name} loading={loading} placeholder="" allowClear showSearch filterOption={selectFilterOption}>
      {list.map((konto) => (
        <Select.Option key={konto.kontoId} value={konto.kontoId}>
          <TextWithTag textContent={konto.nummer} tagContent={konto.bezeichnung} maxTextLength={48} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default FibuBzKontoListFormSelect;
