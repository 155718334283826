import { FC } from 'react';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';
import { useMahndefinitionChangeHistoryListQuery } from '../gql/MahndefinitionQueries.types';

type Props = {
  mahndefinitionId: string;
};

const MahndefinitionChangeHistoryListingTable: FC<Props> = ({ mahndefinitionId }) => {
  const { data, loading } = useMahndefinitionChangeHistoryListQuery({ variables: { mahndefinitionId } });
  const historyList = data?.getMahndefinitionChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="mahndefinition-general" />;
};

export default MahndefinitionChangeHistoryListingTable;
