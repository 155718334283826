import React, { FC, useContext } from 'react';
import { Button, Tooltip } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { IndexationAgreementContext } from '../../Level2/IndexationAgreementContext';
import { useDoNotApplyVertragVposIndexedValueMutation } from '../../../../../../../IndexedValue/gql/VertragVposIndexedValueMutations.types';
import { VertragVposIndexedValue } from '../../../../../../../../types';
import { showSuccessMsgOther } from '../../../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../../../helpers/apolloHelper';
import {
  isVertragVposIndexedValueStatusApplied,
  isVertragVposIndexedValueStatusNotApplied,
} from '../../../../../../../IndexedValue/shared/indexedValueHelpers';

type Props = {
  indexedValue: VertragVposIndexedValue;
  onSubmit: () => void;
};

const ActionDoNotApply: FC<Props> = ({ indexedValue, onSubmit }) => {
  const { bestandseinheitId, objektId, vertragId, vorschreibungspositionId } = useContext(IndexationAgreementContext);

  const isDisabled =
    isVertragVposIndexedValueStatusApplied(indexedValue.status.value) || isVertragVposIndexedValueStatusNotApplied(indexedValue.status.value);

  const [runDoNotApply] = useDoNotApplyVertragVposIndexedValueMutation({
    variables: {
      objektId,
      bestandseinheitId,
      vertragId,
      vorschreibungspositionId,
      indexationAgreementId: indexedValue.indexationAgreement.vertragVposIndexationAgreementId,
      indexedValueId: indexedValue.vertragVposIndexedValueId,
    },
    onCompleted: () => {
      showSuccessMsgOther(`Indexierter Wert ${indexedValue.name} angewendet`);
      onSubmit();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Tooltip title="Nicht anwenden">
      <Button
        type="link"
        icon={<CloseCircleOutlined style={{ color: !isDisabled ? 'red' : 'inherit' }} />}
        onClick={() => runDoNotApply()}
        disabled={isDisabled}
      />
    </Tooltip>
  );
};

export default ActionDoNotApply;
