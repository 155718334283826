import * as Yup from 'yup';
import { entityIsRequired, entityMustBeSelected } from '../../../../../components/message/validationMsg';
import { repFondsKontenZuordnungVersionFormFields, repFondsKontenZuweisungFormFields } from './repFondsKontenZuweisungVersionFormMapper';

/* eslint-disable no-template-curly-in-string */
export const repFondsKontenZuweisungVersionFormValidationSchema = Yup.object().shape({
  [repFondsKontenZuordnungVersionFormFields.validFrom]: Yup.string().nullable().required(entityIsRequired('Gültig ab')),
  [repFondsKontenZuordnungVersionFormFields.kontenZuweisungList]: Yup.array()
    .of(
      Yup.object().shape({
        [repFondsKontenZuweisungFormFields.aufwandsKontoIdList]: Yup.array().min(1, 'Mindestens ein Aufwandskonto muss hinzugefügt werden'),
        [repFondsKontenZuweisungFormFields.aufteilungsschluesselId]: Yup.string().required(entityMustBeSelected('Aufteilungsschlüssel')),
        [repFondsKontenZuweisungFormFields.repFondsKontoId]: Yup.string().required(entityMustBeSelected('Rep.Fonds-Konto')),
      })
    )
    .min(1, 'Mindestens eine Zuweisung muss hinzugefügt werden'),
});
/* eslint-enable no-template-curly-in-string */
