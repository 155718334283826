import styled from 'styled-components';
import { Collapse } from 'antd';

export const StyledCollapse = styled(Collapse)`
  .ant-collapse-header {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .collapse-header-draggable-wrap {
    padding-left: 0 !important;
    border-top: 1px solid transparent;
    border-bottom: 1px solid #f0f0f0;

    &.is-dragging {
      border-top-color: #f0f0f0;
      background: #fafafa;
    }
  }

  .ant-collapse-content {
    border-top: 1px solid #f0f0f0;
    & > .ant-collapse-content-box {
      padding: 16px 16px !important;
    }
  }
`;
