import React from 'react';
import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../components/Template/DetailsPageTemplate';
import MahnungCard from '../../features/Mahnung/Card/MahnungCard';
import menuListMahnung from './menuListMahnung';
import { useMahnungQuery } from '../../features/Mahnung/gql/MahnungQueries.types';
import { useOnMahnungDataChangeEventForDetails } from '../../features/Mahnung/Listing/useOnMahnungDataChangeEvent';
import { MahnungDetailsPageRouteParams } from './routes';
import { Mahnung } from '../../types';

const MahnungDetailsPage = () => {
  const { mahnungId } = useParams() as MahnungDetailsPageRouteParams;

  const { data, refetch } = useMahnungQuery({ variables: { mahnungId } });

  const mahnung = data?.getMahnung.data;
  const loading = !mahnung;

  useOnMahnungDataChangeEventForDetails('mahnungDetails', refetch, mahnungId);

  return (
    <DetailsPageTemplate<Mahnung>
      data={mahnung}
      pageTitle={() => 'Mahnung'}
      card={(mahnung) => <MahnungCard loading={loading} mahnung={mahnung} onActionSuccess={refetch} />}
      sidebarMenuList={(mahnung) => menuListMahnung(mahnung, mahnungId, refetch)}
    />
  );
};

export default MahnungDetailsPage;
