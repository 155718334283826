import React, { FC } from 'react';
import { Typography } from 'antd';
import { dayjsCustom } from '../../../../../../helpers/dayjsCustom';
import { isDueDateOptionTypeVerrechnungsperiode } from '../../../../../Abrechnungsdefinition/shared/Templates/shared/BkOrWe/dueDateHelpers';
import { TopAbrechnung } from '../../../../../../types';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';

type Props = { topAbrechnung: TopAbrechnung };

const TopAbrechnungDueDate: FC<Props> = ({ topAbrechnung }) => {
  if (!topAbrechnung.dueDateDisplayOption) return <TextForEmptyValue textToShow="minus" />;

  return isDueDateOptionTypeVerrechnungsperiode(topAbrechnung.dueDateDisplayOption.type.value) ? (
    <Typography.Text>
      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
      {topAbrechnung.dueDateDisplayOption.type.text} {dayjsCustom.months()[topAbrechnung.dueDateDisplayOption.month! - 1]}
    </Typography.Text>
  ) : (
    <Typography.Text>{topAbrechnung.dueDateDisplayOption.type.text}</Typography.Text>
  );
};

export default TopAbrechnungDueDate;
