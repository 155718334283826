import { FormFields } from '../../../../../../helpers/formikHelper';
import { GenerierlaufEntryExitCode } from '../../../../../../types';
import { TBelegVerarbeitungQueryParams } from './filtersQueryParams';

export type BelegVerarbeitungEntryFiltersFormValues = {
  fakturierungsperiode?: string;
  ausstellerId?: string;
  empfaengerId?: string;
  objektId?: string;
  exitCodeList?: GenerierlaufEntryExitCode[];
  belegnummer?: string;
};

export const belegVerarbEntryListingFiltersFormFields: FormFields<BelegVerarbeitungEntryFiltersFormValues> = {
  fakturierungsperiode: 'fakturierungsperiode',
  ausstellerId: 'ausstellerId',
  empfaengerId: 'empfaengerId',
  objektId: 'objektId',
  exitCodeList: 'exitCodeList',
  belegnummer: 'belegnummer',
};

export const belegVerarbEntryListingFiltersFormInitialValues = (
  queryParams: TBelegVerarbeitungQueryParams
): BelegVerarbeitungEntryFiltersFormValues => ({
  fakturierungsperiode: queryParams.fakturierungsperiode,
  ausstellerId: queryParams.ausstellerId,
  empfaengerId: queryParams.empfaengerId,
  objektId: queryParams.objektId,
  exitCodeList: queryParams.exitCodeList,
  belegnummer: queryParams.belegnummer,
});

export const mapFormValuesToBelegVerarbeitungFilters = (formValues: BelegVerarbeitungEntryFiltersFormValues): TBelegVerarbeitungQueryParams => ({
  fakturierungsperiode: formValues.fakturierungsperiode,
  ausstellerId: formValues.ausstellerId,
  empfaengerId: formValues.empfaengerId,
  objektId: formValues.objektId,
  exitCodeList: formValues.exitCodeList,
  belegnummer: formValues.belegnummer,
});
