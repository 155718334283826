import React, { FC } from 'react';
import { Select, Tag } from 'antd';

type PersonenbezugListItemTagsProps = {
  tags: string[];
  onTagListChange: (tagList: string[]) => void;
  loading: boolean;
};

const PersonenbezugListItemTags: FC<PersonenbezugListItemTagsProps> = ({ tags, onTagListChange, loading }) => {
  const tagRender = (props: { label: React.ReactNode; value: any; onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void }) => {
    const { label, onClose } = props;

    const onPreventMouseDown = (event: { preventDefault: () => void; stopPropagation: () => void }) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable
        onClose={onClose}
        style={{
          margin: 2,
        }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <Select
      id="tags"
      mode="tags"
      defaultValue={tags}
      loading={loading}
      tagRender={tagRender}
      value={tags}
      style={{ minWidth: '100%' }}
      onChange={onTagListChange}
    >
      {tags.map((tag) => (
        <Select.Option key={tag} value={tag} label={tag}>
          {tag}
        </Select.Option>
      ))}
    </Select>
  );
};

export default PersonenbezugListItemTags;
