import React from 'react';
import { Tag, Tooltip, Typography } from 'antd';
import { archivedOrNormalStyle, StatusEnum } from '../../../helpers/statusHelper';
import { MitarbeiterTooltip } from '../../../components/Card';
import AbrechnungskreisTableActions from './AbrechnungskreisTableActions';
import StatusBadge from '../../../components/Status/StatusBadge';
import DataWithStatus from '../../../components/Helpers/DataWithStatus';
import { TableWithColSelectorColumnProps } from '../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { Abrechnungskreis } from '../../../types';
import FormattedDateTime from '../../../components/FormattedDateTime/FormattedDateTime';
import FirmendatenKurzBezeichnung from '../../shared/FirmendatenKurzBezeichnung';

const abrechnungskreisTableColumns = (onSuccess: () => void): TableWithColSelectorColumnProps<Abrechnungskreis>[] => [
  {
    title: 'Bezeichnung',
    defaultSelected: true,
    render: (text, record) => <DataWithStatus status={record.status} text={record.bezeichnung} showBadgeDot={false} showStatusDescription={false} />,
  },
  {
    title: 'Typ',
    defaultSelected: true,
    render: (text, record) => <Typography.Text>{record.type.text}</Typography.Text>,
  },
  {
    title: 'Verrechnung Ust. vom Aufwand',
    defaultSelected: true,
    render: (text, record) =>
      record.verrechnungUstVomAufwand ? (
        <Tooltip title={record.verrechnungUstVomAufwand.description}>
          <Typography.Text>{record.verrechnungUstVomAufwand.text}</Typography.Text>
        </Tooltip>
      ) : null,
  },
  {
    title: 'Firma',
    defaultSelected: true,
    render: (text, record) =>
      record.firmendatenId ? (
        <FirmendatenKurzBezeichnung firmendatenId={record.firmendatenId} />
      ) : (
        <Tag style={archivedOrNormalStyle(record.status)}>System</Tag>
      ),
  },
  {
    title: 'Status',
    defaultSelected: true,
    filters: [
      { text: 'Aktiv', value: StatusEnum.Aktiv },
      { text: 'Archiviert', value: StatusEnum.Archiviert },
    ],
    onFilter: (value, record) => record.status.value.includes(value as string),
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },
  {
    title: 'Erstellt am',
    width: 100,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    render: (text, record) => (
      <MitarbeiterTooltip mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} alignment="left" showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    render: (text, record) => <AbrechnungskreisTableActions onSuccess={onSuccess} abrechnungskreis={record} />,
  },
];

export default abrechnungskreisTableColumns;
