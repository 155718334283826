import { FormFields } from '../../../../../helpers/formikHelper';
import { GenerierlaufEntryExitCode } from '../../../../../types';
import { TCamtVerarbeitungQueryParams } from './filtersQueryParams';

export type CamtVerarbeitungEntryFiltersFormValues = {
  ebicsUserName?: string | null;
  ebicsUserUserId?: string | null;
  exitCodeList?: GenerierlaufEntryExitCode[] | null;
  iban?: string | null;
  mitarbeiterId?: string | null;
};

export const camtVerarbeitungEntryFiltersFormFields: FormFields<CamtVerarbeitungEntryFiltersFormValues> = {
  ebicsUserName: 'ebicsUserName',
  ebicsUserUserId: 'ebicsUserUserId',
  exitCodeList: 'exitCodeList',
  iban: 'iban',
  mitarbeiterId: 'mitarbeiterId',
};

export const camtVerarbEntryListingFiltersFormInitialValues = (
  queryParams: TCamtVerarbeitungQueryParams
): CamtVerarbeitungEntryFiltersFormValues => ({
  ebicsUserName: queryParams.ebicsUserName,
  ebicsUserUserId: queryParams.ebicsUserUserId,
  exitCodeList: queryParams.exitCodeList,
  iban: queryParams.iban,
  mitarbeiterId: queryParams.mitarbeiterId,
});
