import { FormFields } from '../../../helpers/formikHelper';
import {
  Period,
  PeriodUnit,
  TicketPriority,
  TicketTemplate,
  TicketTemplateCreateInput,
  TicketTemplatePeriodInput,
  TicketTemplateUpdateInput,
} from '../../../types';

export type TicketTemplateFormValues = {
  name: string;
  priority: TicketPriority;
  dueDate: PeriodFormValues;
  reminder: PeriodFormValues;
  newSubTicketTemplateList?: TicketTemplate[];
};

type PeriodFormValues = {
  amount: number | null;
  unit: PeriodUnit | null;
};

export const ticketTemplateFormFields: FormFields<TicketTemplateFormValues> = {
  name: 'name',
  priority: 'priority',
  dueDate: 'dueDate',
  reminder: 'reminder',
  newSubTicketTemplateList: 'newSubTicketTemplateList',
};

export const ticketTemplatePeriodFields: FormFields<PeriodFormValues> = {
  amount: 'amount',
  unit: 'unit',
};

export const ticketTemplateFormInitialValues: TicketTemplateFormValues = {
  name: '',
  priority: TicketPriority.Low,
  dueDate: {
    amount: null,
    unit: null,
  },
  reminder: {
    amount: null,
    unit: null,
  },
};

export const mapTicketTemplateToFormValues = (ticketTemplate?: TicketTemplate): TicketTemplateFormValues => {
  if (!ticketTemplate) {
    return ticketTemplateFormInitialValues;
  }

  return {
    name: ticketTemplate.name,
    priority: ticketTemplate.priority.value,
    dueDate: mapPeriodToFormValues(ticketTemplate.dueDate),
    reminder: mapPeriodToFormValues(ticketTemplate.reminder),
  };
};

const mapPeriodToFormValues = (period?: Period | null) => {
  if (!period) return { amount: null, unit: null };

  const { amount = null, unit } = period;
  return { amount, unit: unit.value };
};

export const mapFormValuesToTicketTemplate = (formValues: TicketTemplateFormValues): TicketTemplateCreateInput | TicketTemplateUpdateInput => ({
  name: formValues.name,
  priority: formValues.priority,
  dueDate: mapPeriodValuesToPeriodUnit(formValues.dueDate),
  reminder: mapPeriodValuesToPeriodUnit(formValues.reminder),
});

export const mapPeriodValuesToPeriodUnit = (period?: PeriodFormValues): TicketTemplatePeriodInput | null => {
  const { amount = null, unit = null } = period ?? {};
  if (amount === null || unit === null) return null;

  return {
    amount,
    unit,
  };
};
