import { FC } from 'react';
import HistoryListingTable from '../../History/HistoryListingTable';
import { HistoryType } from '../../History/shared/HistoryModal';
import { useTicketTemplateChangeHistoryListQuery } from '../gql/TicketTemplateQueries.types';

type Props = {
  ticketTemplateId: string;
};

const TicketTemplateChangeHistoryListingTable: FC<Props> = ({ ticketTemplateId }) => {
  const { data, loading } = useTicketTemplateChangeHistoryListQuery({ variables: { ticketTemplateId } });
  const historyList = data?.getTicketTemplateChangeHistoryList.data;

  return <HistoryListingTable historyType={HistoryType.Change} historyList={historyList} loading={loading} filterIdPrefix="ticket-template" />;
};

export default TicketTemplateChangeHistoryListingTable;
