import React, { FC } from 'react';
import TableWithAlignedColsBody from '../../../../../../components/Table/TableWithAlignedCols/TableWithAlignedColsBody';
import ausgabenKontoTableColumns from './ausgabenKontoTableColumns';
import ausgabenKontoTableExpandedRow from './ausgabenKontoTableExpandedRow';
import { ObjAbrKonto } from '../../../../../../types';

type Props = {
  konto: ObjAbrKonto;
  isAbrKreisRepFonds: boolean;
  fromInclusive: string;
  toInclusive: string;
  objektId: string;
  buchungskreisId: string;
};

const AusgabenKontoTable: FC<Props> = ({ konto, isAbrKreisRepFonds, fromInclusive, toInclusive, objektId, buchungskreisId }) => {
  return (
    <TableWithAlignedColsBody<ObjAbrKonto>
      dataSource={konto}
      columns={ausgabenKontoTableColumns(objektId, isAbrKreisRepFonds, fromInclusive, toInclusive, buchungskreisId)}
      expandedRow={() => ausgabenKontoTableExpandedRow}
      positionOfButton={2}
    />
  );
};

export default AusgabenKontoTable;
