import React from 'react';
import { Route } from 'react-router';
import SysSettingsKontoDetailsPage from './SysSettingsKontoDetailsPage';
import AuthRoute from '../../../../security/AuthRoute';
import { isAdminWithSelectedFirmendatenOrHasSystemAdminFm } from '../../../../security/permissionChecks';
import { URI_SYS_SETTINGS_KONTO_DETAILS_PAGE } from '../../../../features/Kontenplan/kontoUriPaths';

export type KontoDetailsPageRouteParams = { kontoId: string };

const sysSettingsKontoDetailsPageRoute = (
  <Route
    path={`${URI_SYS_SETTINGS_KONTO_DETAILS_PAGE}/*`}
    element={<AuthRoute hasPermissionFn={isAdminWithSelectedFirmendatenOrHasSystemAdminFm} element={<SysSettingsKontoDetailsPage />} />}
  />
);

export default sysSettingsKontoDetailsPageRoute;
