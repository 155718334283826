import { Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import pdfStyles from '../../../styles/pdfStyles';
import { ZahlungsverlaufBlock } from '../../topAbrechnung-types';
import ColumnTitles from './ColumnTitles';
import LineList from './LineList';

const TAZahlungsverlaufBlock: FC<{ data: ZahlungsverlaufBlock }> = ({ data }) => (
  <View style={[pdfStyles.column, { marginTop: '10mm' }]}>
    <Text style={[pdfStyles.column, { fontSize: '12px', fontWeight: 'bold' }]}>{data.zahlungsverlaufBlockTitle}</Text>

    {/* Table */}
    <View style={[pdfStyles.column]}>
      <ColumnTitles titles={data.zahlungsverlaufListHeader} />
      <LineList list={data.zahlungsverlaufList} />

      {/* Summary */}
      <View style={[pdfStyles.column, { width: '100%', marginTop: '2mm' }]}>
        <View style={[pdfStyles.row, { paddingLeft: '70%', fontWeight: 'bold' }]}>
          <Text style={[pdfStyles.textNormal, { width: '50%', textAlign: 'right' }]}>{data.zahlungsverlaufSumme.text}</Text>
          <Text style={[pdfStyles.textNormal, { width: '50%', textAlign: 'right' }]}>{data.zahlungsverlaufSumme.betrag}</Text>
        </View>
      </View>
    </View>
  </View>
);

export default TAZahlungsverlaufBlock;
