import { FC } from 'react';
import TransactionInstructionRow from './TransactionInstruction/TransactionInstructionRow';
import ReceivedBankTransactionRow from './ReceivedBankTransaction/ReceivedBankTransactionRow';
import { PaymentType } from '../../../../../types';

type Props = {
  paymentId: string;
  paymentType: PaymentType;
  isRowExpanded: boolean;
  parentRowKeys?: string[];
};

const PaymentDetails: FC<Props> = ({ paymentId, paymentType, isRowExpanded, parentRowKeys }) => (
  <>
    <TransactionInstructionRow paymentId={paymentId} paymentType={paymentType} isRowExpanded={isRowExpanded} parentRowKeys={parentRowKeys} />
    <ReceivedBankTransactionRow paymentId={paymentId} isRowExpanded={isRowExpanded} parentRowKeys={parentRowKeys} />
  </>
);

export default PaymentDetails;
