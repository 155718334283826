import React from 'react';
import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../components/Template/DetailsPageTemplate';
import menuListHeAbr from './menuListHeAbr';
import { HeAbrDetailsPageRouteParams } from './routes';
import { useOnHeAbrechnungHeVertragDataChangedEvents } from '../../features/Abrechnung/He/useOnHeAbrechnungDataChangeEvent';
import { useHeAbrechnungQuery } from '../../features/Abrechnung/He/gql/HeAbrechnungQueries.types';
import HeAbrechnungCard from '../../features/Abrechnung/He/Card/HeAbrechnung/HeAbrechnungCard';
import { HeAbrechnung } from '../../types';

const HeAbrechnungDetailsPage = () => {
  const { objektId, heAbrechnungId } = useParams() as HeAbrDetailsPageRouteParams;

  const { data, refetch } = useHeAbrechnungQuery({
    variables: {
      objektId,
      heAbrechnungId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });
  const heAbrechnung = data?.getHeAbrechnung.data;
  const loading = !heAbrechnung;

  useOnHeAbrechnungHeVertragDataChangedEvents('HauseigentuemerAbrechnung', refetch);

  return (
    <DetailsPageTemplate<HeAbrechnung>
      data={heAbrechnung}
      pageTitle={(heAbrechnung) => heAbrechnung.bezeichnung}
      card={(heAbrechnung) => <HeAbrechnungCard loading={loading} abrechnung={heAbrechnung} objektId={objektId} />}
      sidebarMenuList={(heAbrechnung) => menuListHeAbr(heAbrechnung, refetch, objektId, loading)}
    />
  );
};

export default HeAbrechnungDetailsPage;
