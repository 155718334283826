import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { filtersFormFields, FiltersFormValues, listingLabelList } from '../filtersFormMapper';
import FibuBaObjektSelect from '../../../../BookingInstruction/shared/Filters/FibuBaObjektSelect';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';

type Props = {
  formikProps: FormikProps<FiltersFormValues>;
};

const ObjektSelectFormItem: FC<Props> = ({ formikProps }) => {
  return (
    <FormItemWithoutColon name={filtersFormFields.objektId} label={listingLabelList.objektId}>
      <FibuBaObjektSelect
        name={filtersFormFields.objektId}
        onChange={formikProps.submitForm}
        rechtstraegerIdList={formikProps.values.rechtstraegerIdList}
      />
    </FormItemWithoutColon>
  );
};

export default ObjektSelectFormItem;
