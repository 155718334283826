import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { MahndefinitionFieldsFragmentDoc } from './MahndefinitionFragments.types';
import { TextbausteinFieldsFragmentDoc } from '../../../components/Template/PDFTemplates/gql/TemplateFragments.types';
import { HistoryListFieldsFragmentDoc } from '../../History/gql/HistoryFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MahndefinitionListQueryVariables = Types.Exact<{
  includeArchiviert?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  withDetails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type MahndefinitionListQuery = {
  getMahndefinitionList: {
    data: Array<{
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      mahndefinitionId: string;
      textBausteineBefuellt: boolean;
      status?: { text: string; value: Types.MahndefinitonStatus } | null;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MahndefinitionQueryVariables = Types.Exact<{
  mahndefinitionId: Types.Scalars['ID']['input'];
}>;

export type MahndefinitionQuery = {
  getMahndefinition: {
    data: {
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      deletable?: boolean | null;
      firmendatenId?: string | null;
      mahndefinitionId: string;
      mahnfreieTage: number;
      textBausteineBefuellt: boolean;
      verzugszinsen: number;
      mahnstufe1: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        minBetrag: number;
        naechsteMahnungNachTage: number;
        updatedByMitarbeiterId?: string | null;
        versandEinschreiben: boolean;
        verzugszinsenBerechnen: boolean;
        mahngebuehrList: Array<{
          ab: number;
          betrag: number;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          mahngebuehrId: string;
        }>;
      };
      mahnstufe1TextbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      mahnstufe2: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        minBetrag: number;
        naechsteMahnungNachTage: number;
        updatedByMitarbeiterId?: string | null;
        versandEinschreiben: boolean;
        verzugszinsenBerechnen: boolean;
        mahngebuehrList: Array<{
          ab: number;
          betrag: number;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          mahngebuehrId: string;
        }>;
      };
      mahnstufe2TextbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      mahnstufe3: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        minBetrag: number;
        naechsteMahnungNachTage: number;
        updatedByMitarbeiterId?: string | null;
        versandEinschreiben: boolean;
        verzugszinsenBerechnen: boolean;
        mahngebuehrList: Array<{
          ab: number;
          betrag: number;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          mahngebuehrId: string;
        }>;
      };
      mahnstufe3TextbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      mahnstufe4: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        minBetrag: number;
        naechsteMahnungNachTage: number;
        updatedByMitarbeiterId?: string | null;
        versandEinschreiben: boolean;
        verzugszinsenBerechnen: boolean;
        mahngebuehrList: Array<{
          ab: number;
          betrag: number;
          createTs: string;
          createdBy?: string | null;
          createdByMitarbeiterId?: string | null;
          mahngebuehrId: string;
        }>;
      };
      mahnstufe4TextbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
      status?: { text: string; value: Types.MahndefinitonStatus } | null;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MahndefinitionTemplateQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MahndefinitionTemplateQuery = {
  getMahndefinitionTemplate: {
    data: {
      textbausteinList: Array<{
        index: number;
        value?: string | null;
        platzhalterList: Array<{ example: string; platzhalter: string }>;
        textbausteinList: Array<{
          index: number;
          value?: string | null;
          platzhalterList: Array<{ example: string; platzhalter: string }>;
          type: { text: string; value: Types.TextbausteinType };
        }>;
        type: { text: string; value: Types.TextbausteinType };
      }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type MahndefinitionChangeHistoryListQueryVariables = Types.Exact<{
  mahndefinitionId: Types.Scalars['ID']['input'];
}>;

export type MahndefinitionChangeHistoryListQuery = {
  getMahndefinitionChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type MahnstufeChangeHistoryListQueryVariables = Types.Exact<{
  mahndefinitionId: Types.Scalars['ID']['input'];
  mahnstufeIndex: Types.Scalars['Int']['input'];
}>;

export type MahnstufeChangeHistoryListQuery = {
  getMahnstufeChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export type MahnungTemplateChangeHistoryListQueryVariables = Types.Exact<{
  mahndefinitionId: Types.Scalars['ID']['input'];
  mahnstufeIndex: Types.Scalars['Int']['input'];
}>;

export type MahnungTemplateChangeHistoryListQuery = {
  getMahnungTemplateChangeHistoryList: {
    data: Array<{
      updatedBy?: string | null;
      updatedByMitarbeiterId?: string | null;
      updateTs: string;
      attributeList: Array<
        | {
            __typename: 'HistoryAmountAttribute';
            type: Types.HistoryAttributeType;
            valueAmount?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryBooleanAttribute';
            type: Types.HistoryAttributeType;
            valueBoolean?: boolean | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryDateAttribute'; type: Types.HistoryAttributeType; valueDate?: string | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryFloatAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valueFloat?: number | null;
            name: { text: string; value: string };
          }
        | { __typename: 'HistoryHtmlAttribute'; type: Types.HistoryAttributeType; valueHtml?: string | null; name: { text: string; value: string } }
        | { __typename: 'HistoryIntAttribute'; type: Types.HistoryAttributeType; valueInt?: number | null; name: { text: string; value: string } }
        | {
            __typename: 'HistoryPercentAttribute';
            scaling?: number | null;
            type: Types.HistoryAttributeType;
            valuePercent?: number | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringAttribute';
            type: Types.HistoryAttributeType;
            valueString?: string | null;
            name: { text: string; value: string };
          }
        | {
            __typename: 'HistoryStringListAttribute';
            longText: boolean;
            type: Types.HistoryAttributeType;
            valueStringList?: Array<string> | null;
            name: { text: string; value: string };
          }
      >;
    }>;
  };
};

export const MahndefinitionListDocument = gql`
  query MahndefinitionList($includeArchiviert: Boolean, $withDetails: Boolean) {
    getMahndefinitionList(includeArchiviert: $includeArchiviert, withDetails: $withDetails) {
      data {
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        deletable
        firmendatenId
        mahndefinitionId
        status {
          text
          value
        }
        textBausteineBefuellt
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useMahndefinitionListQuery(baseOptions?: Apollo.QueryHookOptions<MahndefinitionListQuery, MahndefinitionListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahndefinitionListQuery, MahndefinitionListQueryVariables>(MahndefinitionListDocument, options);
}
export function useMahndefinitionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MahndefinitionListQuery, MahndefinitionListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahndefinitionListQuery, MahndefinitionListQueryVariables>(MahndefinitionListDocument, options);
}
export function useMahndefinitionListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MahndefinitionListQuery, MahndefinitionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahndefinitionListQuery, MahndefinitionListQueryVariables>(MahndefinitionListDocument, options);
}
export type MahndefinitionListQueryHookResult = ReturnType<typeof useMahndefinitionListQuery>;
export type MahndefinitionListLazyQueryHookResult = ReturnType<typeof useMahndefinitionListLazyQuery>;
export type MahndefinitionListSuspenseQueryHookResult = ReturnType<typeof useMahndefinitionListSuspenseQuery>;
export type MahndefinitionListQueryResult = Apollo.QueryResult<MahndefinitionListQuery, MahndefinitionListQueryVariables>;
export const MahndefinitionDocument = gql`
  query Mahndefinition($mahndefinitionId: ID!) {
    getMahndefinition(mahndefinitionId: $mahndefinitionId) {
      data {
        ...MahndefinitionFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${MahndefinitionFieldsFragmentDoc}
`;
export function useMahndefinitionQuery(
  baseOptions: Apollo.QueryHookOptions<MahndefinitionQuery, MahndefinitionQueryVariables> &
    ({ variables: MahndefinitionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahndefinitionQuery, MahndefinitionQueryVariables>(MahndefinitionDocument, options);
}
export function useMahndefinitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MahndefinitionQuery, MahndefinitionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahndefinitionQuery, MahndefinitionQueryVariables>(MahndefinitionDocument, options);
}
export function useMahndefinitionSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MahndefinitionQuery, MahndefinitionQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahndefinitionQuery, MahndefinitionQueryVariables>(MahndefinitionDocument, options);
}
export type MahndefinitionQueryHookResult = ReturnType<typeof useMahndefinitionQuery>;
export type MahndefinitionLazyQueryHookResult = ReturnType<typeof useMahndefinitionLazyQuery>;
export type MahndefinitionSuspenseQueryHookResult = ReturnType<typeof useMahndefinitionSuspenseQuery>;
export type MahndefinitionQueryResult = Apollo.QueryResult<MahndefinitionQuery, MahndefinitionQueryVariables>;
export const MahndefinitionTemplateDocument = gql`
  query MahndefinitionTemplate {
    getMahndefinitionTemplate {
      data {
        textbausteinList {
          ...TextbausteinFields
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${TextbausteinFieldsFragmentDoc}
`;
export function useMahndefinitionTemplateQuery(
  baseOptions?: Apollo.QueryHookOptions<MahndefinitionTemplateQuery, MahndefinitionTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahndefinitionTemplateQuery, MahndefinitionTemplateQueryVariables>(MahndefinitionTemplateDocument, options);
}
export function useMahndefinitionTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MahndefinitionTemplateQuery, MahndefinitionTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahndefinitionTemplateQuery, MahndefinitionTemplateQueryVariables>(MahndefinitionTemplateDocument, options);
}
export function useMahndefinitionTemplateSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MahndefinitionTemplateQuery, MahndefinitionTemplateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahndefinitionTemplateQuery, MahndefinitionTemplateQueryVariables>(MahndefinitionTemplateDocument, options);
}
export type MahndefinitionTemplateQueryHookResult = ReturnType<typeof useMahndefinitionTemplateQuery>;
export type MahndefinitionTemplateLazyQueryHookResult = ReturnType<typeof useMahndefinitionTemplateLazyQuery>;
export type MahndefinitionTemplateSuspenseQueryHookResult = ReturnType<typeof useMahndefinitionTemplateSuspenseQuery>;
export type MahndefinitionTemplateQueryResult = Apollo.QueryResult<MahndefinitionTemplateQuery, MahndefinitionTemplateQueryVariables>;
export const MahndefinitionChangeHistoryListDocument = gql`
  query MahndefinitionChangeHistoryList($mahndefinitionId: ID!) {
    getMahndefinitionChangeHistoryList(mahndefinitionId: $mahndefinitionId) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useMahndefinitionChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<MahndefinitionChangeHistoryListQuery, MahndefinitionChangeHistoryListQueryVariables> &
    ({ variables: MahndefinitionChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahndefinitionChangeHistoryListQuery, MahndefinitionChangeHistoryListQueryVariables>(
    MahndefinitionChangeHistoryListDocument,
    options
  );
}
export function useMahndefinitionChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MahndefinitionChangeHistoryListQuery, MahndefinitionChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahndefinitionChangeHistoryListQuery, MahndefinitionChangeHistoryListQueryVariables>(
    MahndefinitionChangeHistoryListDocument,
    options
  );
}
export function useMahndefinitionChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<MahndefinitionChangeHistoryListQuery, MahndefinitionChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahndefinitionChangeHistoryListQuery, MahndefinitionChangeHistoryListQueryVariables>(
    MahndefinitionChangeHistoryListDocument,
    options
  );
}
export type MahndefinitionChangeHistoryListQueryHookResult = ReturnType<typeof useMahndefinitionChangeHistoryListQuery>;
export type MahndefinitionChangeHistoryListLazyQueryHookResult = ReturnType<typeof useMahndefinitionChangeHistoryListLazyQuery>;
export type MahndefinitionChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useMahndefinitionChangeHistoryListSuspenseQuery>;
export type MahndefinitionChangeHistoryListQueryResult = Apollo.QueryResult<
  MahndefinitionChangeHistoryListQuery,
  MahndefinitionChangeHistoryListQueryVariables
>;
export const MahnstufeChangeHistoryListDocument = gql`
  query MahnstufeChangeHistoryList($mahndefinitionId: ID!, $mahnstufeIndex: Int!) {
    getMahnstufeChangeHistoryList(mahndefinitionId: $mahndefinitionId, mahnstufeIndex: $mahnstufeIndex) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useMahnstufeChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<MahnstufeChangeHistoryListQuery, MahnstufeChangeHistoryListQueryVariables> &
    ({ variables: MahnstufeChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahnstufeChangeHistoryListQuery, MahnstufeChangeHistoryListQueryVariables>(MahnstufeChangeHistoryListDocument, options);
}
export function useMahnstufeChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MahnstufeChangeHistoryListQuery, MahnstufeChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahnstufeChangeHistoryListQuery, MahnstufeChangeHistoryListQueryVariables>(MahnstufeChangeHistoryListDocument, options);
}
export function useMahnstufeChangeHistoryListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MahnstufeChangeHistoryListQuery, MahnstufeChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahnstufeChangeHistoryListQuery, MahnstufeChangeHistoryListQueryVariables>(
    MahnstufeChangeHistoryListDocument,
    options
  );
}
export type MahnstufeChangeHistoryListQueryHookResult = ReturnType<typeof useMahnstufeChangeHistoryListQuery>;
export type MahnstufeChangeHistoryListLazyQueryHookResult = ReturnType<typeof useMahnstufeChangeHistoryListLazyQuery>;
export type MahnstufeChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useMahnstufeChangeHistoryListSuspenseQuery>;
export type MahnstufeChangeHistoryListQueryResult = Apollo.QueryResult<MahnstufeChangeHistoryListQuery, MahnstufeChangeHistoryListQueryVariables>;
export const MahnungTemplateChangeHistoryListDocument = gql`
  query MahnungTemplateChangeHistoryList($mahndefinitionId: ID!, $mahnstufeIndex: Int!) {
    getMahnungTemplateChangeHistoryList(mahndefinitionId: $mahndefinitionId, mahnstufeIndex: $mahnstufeIndex) {
      ...HistoryListFields
    }
  }
  ${HistoryListFieldsFragmentDoc}
`;
export function useMahnungTemplateChangeHistoryListQuery(
  baseOptions: Apollo.QueryHookOptions<MahnungTemplateChangeHistoryListQuery, MahnungTemplateChangeHistoryListQueryVariables> &
    ({ variables: MahnungTemplateChangeHistoryListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MahnungTemplateChangeHistoryListQuery, MahnungTemplateChangeHistoryListQueryVariables>(
    MahnungTemplateChangeHistoryListDocument,
    options
  );
}
export function useMahnungTemplateChangeHistoryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MahnungTemplateChangeHistoryListQuery, MahnungTemplateChangeHistoryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MahnungTemplateChangeHistoryListQuery, MahnungTemplateChangeHistoryListQueryVariables>(
    MahnungTemplateChangeHistoryListDocument,
    options
  );
}
export function useMahnungTemplateChangeHistoryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<MahnungTemplateChangeHistoryListQuery, MahnungTemplateChangeHistoryListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MahnungTemplateChangeHistoryListQuery, MahnungTemplateChangeHistoryListQueryVariables>(
    MahnungTemplateChangeHistoryListDocument,
    options
  );
}
export type MahnungTemplateChangeHistoryListQueryHookResult = ReturnType<typeof useMahnungTemplateChangeHistoryListQuery>;
export type MahnungTemplateChangeHistoryListLazyQueryHookResult = ReturnType<typeof useMahnungTemplateChangeHistoryListLazyQuery>;
export type MahnungTemplateChangeHistoryListSuspenseQueryHookResult = ReturnType<typeof useMahnungTemplateChangeHistoryListSuspenseQuery>;
export type MahnungTemplateChangeHistoryListQueryResult = Apollo.QueryResult<
  MahnungTemplateChangeHistoryListQuery,
  MahnungTemplateChangeHistoryListQueryVariables
>;
