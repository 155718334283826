import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';
import { isAdmin } from '../../../../security/permissionChecks';
import KundenSystemListPage from './index';

const andromedaSysSettingsKundenSystemListPageRoute = (
  <Route path={URI_ANDROMEDA_SYS_SETTINGS.kundenSystemPage} element={<AuthRoute hasPermissionFn={isAdmin} element={<KundenSystemListPage />} />} />
);

export default andromedaSysSettingsKundenSystemListPageRoute;
