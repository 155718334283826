import React from 'react';
import { Empty } from 'antd';
import GenerierlaufStatusExpandedRow from '../../shared/GenerierlaufStatusExpandedRow';
import { isVerarbeitungEntryTableRowExpandable } from '../../verarbeitungHelpers';
import { DEFAULT_CURRENT, DEFAULT_PAGE_SIZE, useQueryWithPagingAndSorting } from '../../../../hooks/useQueryWithPagingAndSorting';
import { useReceivedBankTransactionVerbuchenGenerierlaufEntryQuery } from '../../gql/ReceivedBankTransactionVerbuchenGenerierlauf/ReceivedBankTransactionVerbuchenGenerierlaufQueries.types';
import { TReceivedBankTransactionVerbuchenQueryParams } from './Filters/filtersQueryParams';
import { ReceivedBankTransactionVerbuchenGenerierlaufListEntry } from '../../../../types';
import NestedTable from '../../../../components/Table/NestedTable/NestedTable';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import receivedBankTransactionVerbuchenTableColumns from './receivedBankTransactionVerbuchenTableColumns';
import { mapQueryParamsToReceivedBankTransactionVerbuchenListQueryVariables } from './Filters/listingFiltersFormMapper';

type Props = {
  queryParams: TReceivedBankTransactionVerbuchenQueryParams;
  generierlaufId: string;
};

const ReceivedBankTransactionVerbuchenEntryTable: React.FC<Props> = ({ generierlaufId, queryParams }) => {
  const { currentPage, pageSize } = usePaginationQueryParams();

  const { data, loading, pagination, handleTableSorting } = useQueryWithPagingAndSorting(
    useReceivedBankTransactionVerbuchenGenerierlaufEntryQuery,
    {
      variables: {
        generierlaufId,
        ...mapQueryParamsToReceivedBankTransactionVerbuchenListQueryVariables(queryParams),
      },
      fetchPolicy: 'no-cache',
    },
    {
      showQuickJumper: true,
      showSizeChanger: true,
      current: currentPage ? Number(currentPage) : DEFAULT_CURRENT,
      pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGE_SIZE,
    }
  );

  const entryList = data?.getReceivedBankTransactionVerbuchenGenerierlaufEntry.data.contentList || [];

  return (
    <NestedTable<ReceivedBankTransactionVerbuchenGenerierlaufListEntry>
      locale={{
        emptyText: <Empty description={<span>Keine Empf. Bankbuchungen vorhanden</span>} />,
      }}
      level={1}
      colWidth={150}
      loading={loading}
      pagination={pagination}
      dataSource={entryList}
      columns={receivedBankTransactionVerbuchenTableColumns}
      onChange={handleTableSorting}
      expandable={{
        expandedRowRender: (record) => <GenerierlaufStatusExpandedRow generierlaufEntryList={record} />,
        rowExpandable: isVerarbeitungEntryTableRowExpandable,
      }}
      rowKey={(record) => record.generierlaufEntryId}
    />
  );
};

export default ReceivedBankTransactionVerbuchenEntryTable;
