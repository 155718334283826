import React, { FC } from 'react';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import { isColumnSortedAsc, isColumnSortedDesc, SortOrder } from './sortingHelpers';

type SortCaretsProps = { columnIndex: number; sortedColumnIndex: number; sortOrder: SortOrder };

const SortCarets: FC<SortCaretsProps> = ({ columnIndex, sortedColumnIndex, sortOrder }) => {
  return (
    <span className="ant-table-column-sorter ant-table-column-sorter-full">
      <span className="ant-table-column-sorter-inner">
        <CaretUpFilled
          className={`anticon anticon-caret-up ant-table-column-sorter-up ${
            isColumnSortedAsc(columnIndex, sortedColumnIndex, sortOrder) ? 'active' : ''
          }`}
        />
        <CaretDownFilled
          className={`anticon anticon-caret-up ant-table-column-sorter-down ${
            isColumnSortedDesc(columnIndex, sortedColumnIndex, sortOrder) ? 'active' : ''
          }`}
        />
      </span>
    </span>
  );
};

export default SortCarets;
