import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateObjektInfoFeldPostItMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  objekInfoFeldId: Types.Scalars['ID']['input'];
  input: Types.PostItInput;
}>;

export type CreateObjektInfoFeldPostItMutation = {
  createObjektInfoFeldPostIt: {
    data: { postItId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteObjektInfoFeldMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  infoFeldId: Types.Scalars['ID']['input'];
}>;

export type DeleteObjektInfoFeldMutation = {
  deleteObjektInfoFeld: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type ArchiveObjektInfoFeldMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  infoFeldId: Types.Scalars['ID']['input'];
}>;

export type ArchiveObjektInfoFeldMutation = {
  actionArchiveObjektInfoFeld: {
    data:
      | { objektInfoFeldId: string }
      | { objektInfoFeldId: string }
      | { objektInfoFeldId: string }
      | { objektInfoFeldId: string }
      | { objektInfoFeldId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveObjektInfoFeldMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  infoFeldId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveObjektInfoFeldMutation = {
  actionUnarchiveObjektInfoFeld: {
    data:
      | { objektInfoFeldId: string }
      | { objektInfoFeldId: string }
      | { objektInfoFeldId: string }
      | { objektInfoFeldId: string }
      | { objektInfoFeldId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateObjektInfoFeldPostItDocument = gql`
  mutation CreateObjektInfoFeldPostIt($objektId: ID!, $objekInfoFeldId: ID!, $input: PostItInput!) {
    createObjektInfoFeldPostIt(objektId: $objektId, objekInfoFeldId: $objekInfoFeldId, input: $input) {
      data {
        postItId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateObjektInfoFeldPostItMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateObjektInfoFeldPostItMutation, CreateObjektInfoFeldPostItMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateObjektInfoFeldPostItMutation, CreateObjektInfoFeldPostItMutationVariables>(
    CreateObjektInfoFeldPostItDocument,
    options
  );
}
export type CreateObjektInfoFeldPostItMutationHookResult = ReturnType<typeof useCreateObjektInfoFeldPostItMutation>;
export type CreateObjektInfoFeldPostItMutationResult = Apollo.MutationResult<CreateObjektInfoFeldPostItMutation>;
export type CreateObjektInfoFeldPostItMutationOptions = Apollo.BaseMutationOptions<
  CreateObjektInfoFeldPostItMutation,
  CreateObjektInfoFeldPostItMutationVariables
>;
export const DeleteObjektInfoFeldDocument = gql`
  mutation DeleteObjektInfoFeld($objektId: ID!, $infoFeldId: ID!) {
    deleteObjektInfoFeld(objektId: $objektId, infoFeldId: $infoFeldId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteObjektInfoFeldMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteObjektInfoFeldMutation, DeleteObjektInfoFeldMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteObjektInfoFeldMutation, DeleteObjektInfoFeldMutationVariables>(DeleteObjektInfoFeldDocument, options);
}
export type DeleteObjektInfoFeldMutationHookResult = ReturnType<typeof useDeleteObjektInfoFeldMutation>;
export type DeleteObjektInfoFeldMutationResult = Apollo.MutationResult<DeleteObjektInfoFeldMutation>;
export type DeleteObjektInfoFeldMutationOptions = Apollo.BaseMutationOptions<DeleteObjektInfoFeldMutation, DeleteObjektInfoFeldMutationVariables>;
export const ArchiveObjektInfoFeldDocument = gql`
  mutation ArchiveObjektInfoFeld($objektId: ID!, $infoFeldId: ID!) {
    actionArchiveObjektInfoFeld(objektId: $objektId, infoFeldId: $infoFeldId) {
      data {
        objektInfoFeldId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveObjektInfoFeldMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveObjektInfoFeldMutation, ArchiveObjektInfoFeldMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveObjektInfoFeldMutation, ArchiveObjektInfoFeldMutationVariables>(ArchiveObjektInfoFeldDocument, options);
}
export type ArchiveObjektInfoFeldMutationHookResult = ReturnType<typeof useArchiveObjektInfoFeldMutation>;
export type ArchiveObjektInfoFeldMutationResult = Apollo.MutationResult<ArchiveObjektInfoFeldMutation>;
export type ArchiveObjektInfoFeldMutationOptions = Apollo.BaseMutationOptions<ArchiveObjektInfoFeldMutation, ArchiveObjektInfoFeldMutationVariables>;
export const UnarchiveObjektInfoFeldDocument = gql`
  mutation UnarchiveObjektInfoFeld($objektId: ID!, $infoFeldId: ID!) {
    actionUnarchiveObjektInfoFeld(objektId: $objektId, infoFeldId: $infoFeldId) {
      data {
        objektInfoFeldId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveObjektInfoFeldMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveObjektInfoFeldMutation, UnarchiveObjektInfoFeldMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveObjektInfoFeldMutation, UnarchiveObjektInfoFeldMutationVariables>(UnarchiveObjektInfoFeldDocument, options);
}
export type UnarchiveObjektInfoFeldMutationHookResult = ReturnType<typeof useUnarchiveObjektInfoFeldMutation>;
export type UnarchiveObjektInfoFeldMutationResult = Apollo.MutationResult<UnarchiveObjektInfoFeldMutation>;
export type UnarchiveObjektInfoFeldMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveObjektInfoFeldMutation,
  UnarchiveObjektInfoFeldMutationVariables
>;
