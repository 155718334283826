import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { vertragVposIndexedValueFormFields, VertragVposIndexedValueFormValues } from '../indexedValueMapper';
import { useCalculateVertragVposIndexedValueAmountLazyQuery } from '../../../gql/VertragVposIndexedValueQueries.types';
import FormItemWithoutColon from '../../../../../components/Form/FormItemWithoutColon';
import FormattedDecimalFormInput from '../../../../../components/Input-antd/FormattedDecimalFormInput';

type Props = {
  name: string;
  indexationAgreementId: string;
  indexedValueId: string;
  formikProps: FormikProps<VertragVposIndexedValueFormValues>;
  bestandseinheitId: string;
  objektId: string;
  vertragId: string;
  vorschreibungspositionId: string;
};

const FactorPercentFormItem: FC<Props> = ({
  name,
  indexationAgreementId,
  indexedValueId,
  bestandseinheitId,
  objektId,
  vertragId,
  vorschreibungspositionId,
  formikProps,
}) => {
  const [runQuery] = useCalculateVertragVposIndexedValueAmountLazyQuery();

  const handleFactorPercentChange = (value: number) => {
    runQuery({
      variables: {
        objektId,
        bestandseinheitId,
        vertragId,
        vorschreibungspositionId,
        indexationAgreementId,
        indexedValueId,
        factorPercent: value,
      },
    }).then((result) => {
      const calculatedAmount = result.data?.calculateVertragVposIndexedValueAmount.data;
      calculatedAmount && formikProps.setFieldValue(vertragVposIndexedValueFormFields.amount, calculatedAmount);
    });
  };

  return (
    <FormItemWithoutColon name={name} style={{ width: '100%', marginBottom: '0px' }}>
      <FormattedDecimalFormInput name={name} isPercentage onChange={(value) => value && handleFactorPercentChange(value as number)} />
    </FormItemWithoutColon>
  );
};

export default FactorPercentFormItem;
