import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { UserFieldsFragmentDoc } from '../../../graphql/Authentication/UserFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAdminMutationVariables = Types.Exact<{
  request: Types.UserCreateInput;
}>;

export type CreateAdminMutation = {
  createUser: {
    data: {
      createdTs: string;
      email?: string | null;
      emailUnverified?: string | null;
      firstname?: string | null;
      lastname?: string | null;
      role: Types.UserRole;
      username: string;
      status: { text: string; value: Types.UserStatus };
      contactList?: Array<{
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
    };
  };
};

export type UpdateAdminMutationVariables = Types.Exact<{
  request: Types.UserUpdateInput;
  username: Types.Scalars['String']['input'];
}>;

export type UpdateAdminMutation = {
  updateUser: {
    data: {
      createdTs: string;
      email?: string | null;
      emailUnverified?: string | null;
      firstname?: string | null;
      lastname?: string | null;
      role: Types.UserRole;
      username: string;
      status: { text: string; value: Types.UserStatus };
      contactList?: Array<{
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
    };
  };
};

export type DeleteAdminMutationVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
}>;

export type DeleteAdminMutation = {
  deleteUser: {
    data: {
      createdTs: string;
      email?: string | null;
      emailUnverified?: string | null;
      firstname?: string | null;
      lastname?: string | null;
      role: Types.UserRole;
      username: string;
      status: { text: string; value: Types.UserStatus };
      contactList?: Array<{
        contactId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        haupt?: boolean | null;
        tagList: Array<string>;
        type: string;
        wert: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
    };
  };
};

export const CreateAdminDocument = gql`
  mutation CreateAdmin($request: UserCreateInput!) {
    createUser(request: $request) {
      data {
        ...userFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
`;
export function useCreateAdminMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdminMutation, CreateAdminMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAdminMutation, CreateAdminMutationVariables>(CreateAdminDocument, options);
}
export type CreateAdminMutationHookResult = ReturnType<typeof useCreateAdminMutation>;
export type CreateAdminMutationResult = Apollo.MutationResult<CreateAdminMutation>;
export type CreateAdminMutationOptions = Apollo.BaseMutationOptions<CreateAdminMutation, CreateAdminMutationVariables>;
export const UpdateAdminDocument = gql`
  mutation UpdateAdmin($request: UserUpdateInput!, $username: String!) {
    updateUser(request: $request, username: $username) {
      data {
        ...userFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
`;
export function useUpdateAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminMutation, UpdateAdminMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAdminMutation, UpdateAdminMutationVariables>(UpdateAdminDocument, options);
}
export type UpdateAdminMutationHookResult = ReturnType<typeof useUpdateAdminMutation>;
export type UpdateAdminMutationResult = Apollo.MutationResult<UpdateAdminMutation>;
export type UpdateAdminMutationOptions = Apollo.BaseMutationOptions<UpdateAdminMutation, UpdateAdminMutationVariables>;
export const DeleteAdminDocument = gql`
  mutation DeleteAdmin($username: String!) {
    deleteUser(username: $username) {
      data {
        ...userFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
`;
export function useDeleteAdminMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAdminMutation, DeleteAdminMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAdminMutation, DeleteAdminMutationVariables>(DeleteAdminDocument, options);
}
export type DeleteAdminMutationHookResult = ReturnType<typeof useDeleteAdminMutation>;
export type DeleteAdminMutationResult = Apollo.MutationResult<DeleteAdminMutation>;
export type DeleteAdminMutationOptions = Apollo.BaseMutationOptions<DeleteAdminMutation, DeleteAdminMutationVariables>;
