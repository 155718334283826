import React, { FC } from 'react';
import { Tabs, TabsProps } from 'antd';
import { TextbausteinFormValues } from '../../../../../components/Template/PDFTemplates/templateMapper';
import FormButtons from '../../../../../components/Button/FormButtons';
import { HeOrSubAbrDefGeneralSettingsFormValues } from '../../../../Abrechnungsdefinition/shared/Templates/shared/HeOrSub/GeneralSettingForm/heOrSubAbrDefGeneralSettingsFormMapper';
import { HeOrSubAbrDefOutputOptionsFieldHelpNames } from '../../../../Abrechnungsdefinition/abrDefFieldHelpHelpers/abrDefFieldHelpMapper';
import HeOrSubGeneralSettingsForm from '../../../../Abrechnungsdefinition/shared/Templates/shared/HeOrSub/GeneralSettingForm/HeOrSubGeneralSettingsForm';
import TemplateSubAbrechnung from './TemplateSubAbrechnung';

type Props = {
  subAbrDefTemplateValues: TextbausteinFormValues[];
  subAbrDefGeneralSettingsValues: HeOrSubAbrDefGeneralSettingsFormValues;
  setSubAbrDefGeneralSettingsValues: (values: HeOrSubAbrDefGeneralSettingsFormValues) => void;
  onTemplateChange: (templateValues: TextbausteinFormValues[]) => void;
  onSubmit: () => void;
  isSubmitting?: boolean;
  onBack: () => void;
  fieldHelpNames: HeOrSubAbrDefOutputOptionsFieldHelpNames;
};

const SubAbrDefTemplateVersionCreateTabWithTemplate: FC<Props> = ({
  subAbrDefGeneralSettingsValues,
  setSubAbrDefGeneralSettingsValues,
  subAbrDefTemplateValues,
  onTemplateChange,
  onSubmit,
  isSubmitting,
  onBack,
  fieldHelpNames,
}) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Allgemeine Einstellungen',
      children: (
        <HeOrSubGeneralSettingsForm
          values={subAbrDefGeneralSettingsValues}
          onSubmit={setSubAbrDefGeneralSettingsValues}
          fieldHelpNames={fieldHelpNames}
        />
      ),
    },
    {
      key: '2',
      label: 'Texte',
      children: (
        <TemplateSubAbrechnung
          values={subAbrDefTemplateValues}
          onTemplateChange={onTemplateChange}
          abrDefGeneralSettingsValues={subAbrDefGeneralSettingsValues}
        />
      ),
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey="Allgemeine Einstellungen" items={items} />
      <FormButtons onCancel={onBack} cancelText="Zurück" updateMode={false} isOkLoading={isSubmitting} onOk={onSubmit} okText="Speichern" />
    </>
  );
};

export default SubAbrDefTemplateVersionCreateTabWithTemplate;
