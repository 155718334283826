import React from 'react';
import { useParams } from 'react-router-dom';
import DetailsPageTemplate from '../../../components/Template/DetailsPageTemplate';
import { verarbeitungDetailsCardTitles, verarbeitungPageAndMenuListTitles } from '../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungDetailsPageCard from '../../../features/Verarbeitung/shared/VerarbeitungDetailsPageCard';
import { VerarbeitungUstVomAufwandDetailsPageRouteParams } from './routes';
import menuListUstVomAufwand from './menuListUstVomAufwand';
import { useUstVomAufwandGenerierlaufQuery } from '../../../features/Verarbeitung/gql/UstVomAufwandGenerierlauf/UstVomAufwandGenerierlaufQueries.types';
import { UstVomAufwandGenerierlauf } from '../../../types';
import { useRestartUstVomAufwandGenerierlaufMutation } from '../../../features/Verarbeitung/gql/UstVomAufwandGenerierlauf/UstVomAufwandGenerierlaufMutations.types';

const UstVomAufwandDetailsPage = () => {
  const { generierlaufId } = useParams() as VerarbeitungUstVomAufwandDetailsPageRouteParams;

  const { data, refetch } = useUstVomAufwandGenerierlaufQuery({
    variables: {
      generierlaufId,
    },
    onCompleted: () => {
      refetch();
    },
  });

  const generierlauf = data?.getUstVomAufwandGenerierlauf.data;

  const [runRestart] = useRestartUstVomAufwandGenerierlaufMutation({
    variables: {
      generierlaufId,
    },
  });

  return (
    <DetailsPageTemplate<UstVomAufwandGenerierlauf>
      data={generierlauf}
      pageTitle={() => verarbeitungPageAndMenuListTitles.UstVomAufwand}
      card={(generierlauf) => (
        <VerarbeitungDetailsPageCard
          generierlauf={generierlauf}
          cardTitle={verarbeitungDetailsCardTitles.UstVomAufwand}
          onGenerierlaufRestart={runRestart}
        />
      )}
      sidebarMenuList={(generierlauf) => menuListUstVomAufwand(generierlauf)}
    />
  );
};

export default UstVomAufwandDetailsPage;
