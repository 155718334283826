import { FC } from 'react';
import { FormikProps } from 'formik';
import { Typography } from 'antd';
import { AuszifferungBlockFormValues, AuszifferungFormValues, OffenePostenForBooking } from '../../../../auszifferungFormMapper';
import { removeAuszifferung } from './removeAuszifferungHelpers';

type Props = {
  bookingZahlungId: string;
  formikProps: FormikProps<AuszifferungBlockFormValues>;
  offenePosten: OffenePostenForBooking;
  record: AuszifferungFormValues;
};

const RemoveNewAuszifferung: FC<Props> = ({ bookingZahlungId, formikProps, offenePosten, record }) => {
  return (
    <Typography.Text
      style={{ color: '#1677ff', cursor: 'pointer' }}
      onClick={() => removeAuszifferung(bookingZahlungId, formikProps, offenePosten, record)}
    >
      Entfernen
    </Typography.Text>
  );
};

export default RemoveNewAuszifferung;
