import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';
import AndromedaSysSettingsUstKategoriePage from './AndromedaSysSettingsUstKategoriePage';
import { isAdmin } from '../../../../security/permissionChecks';

const andromedaSysSettingsUstKategoriePageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS.ustKategoriePage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsUstKategoriePage />} />}
  />
);

export default andromedaSysSettingsUstKategoriePageRoute;
