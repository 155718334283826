import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Col, Row, Spin, Typography } from 'antd';
import { isEmpty } from 'lodash';
import FormButtons from '../../../../components/Button/FormButtons';
import { showSuccessMsgUpdate } from '../../../../components/message';
import {
  mapFormValuesToVorschreibungsPosition,
  mapObjektVorschreibungspositionToFormValues,
  objektVorschreibungspositionFormFields,
  ObjektVorschreibungspositionFormValues,
} from './objektVorschreibungPositionUpdateFormMapper';
import { objektVorschreibungPositionUpdateFormValidationSchema } from './objektVorschreibungPositionUpdateFormValidationSchema';
import { useObjektVorschreibungspositionListQuery } from '../gql/ObjektVorschreibungPositionQueries.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { useUpdateObjektVorschreibungspositionMutation } from '../gql/ObjektVorschreibungPositionMutations.types';
import UniqueBezeichnungInput from '../../../shared/UniqueBezeichnungInput';
import { ObjektVorschreibungsposition } from '../../../../types';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';

type ObjektVorschreibungPositionUpdateFormProps = {
  vorschreibungsposition: ObjektVorschreibungsposition;
  objektId: string;
  onSubmit: () => void;
  onCancel: () => void;
};

const ObjektVorschreibungPositionUpdateForm: FC<ObjektVorschreibungPositionUpdateFormProps> = ({
  vorschreibungsposition,
  objektId,
  onSubmit,
  onCancel,
}) => {
  const getFieldHelpText = useGetAppFieldHelpText<'ObjektVorschreibungsposition'>('ObjektVorschreibungsposition');

  const [runUpdate, { loading: loadingUpdate }] = useUpdateObjektVorschreibungspositionMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`Vorschreibungsposition`);
      onSubmit();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const { loading, data: maybeVorschreibungspositionList } = useObjektVorschreibungspositionListQuery({
    variables: {
      objektId,
    },
  });

  const vorschreibungsPositionList = maybeVorschreibungspositionList?.getObjektVorschreibungspositionList.data ?? [];

  if (loading) return <Spin />;

  return (
    <Formik<ObjektVorschreibungspositionFormValues>
      initialValues={mapObjektVorschreibungspositionToFormValues(vorschreibungsposition)}
      validationSchema={objektVorschreibungPositionUpdateFormValidationSchema}
      validateOnChange
      onSubmit={(values, formikHelpers) =>
        runUpdate({
          variables: {
            objektId,
            objektVorschreibungspositionId: vorschreibungsposition.objektVorschreibungspositionId,
            input: mapFormValuesToVorschreibungsPosition(values),
          },
        }).finally(() => formikHelpers.setSubmitting(false))
      }
    >
      {(formikProps) => (
        <Form layout="vertical">
          <Row align="top">
            <Col span={6}>
              <Typography.Title level={5}>Bezeichnung</Typography.Title>
            </Col>
            <Col span={18}>
              <UniqueBezeichnungInput
                name={objektVorschreibungspositionFormFields.bezeichnung}
                existingBezeichnungList={vorschreibungsPositionList.map((item) => item.bezeichnung)}
                placeholder="z.B. Hauptmietzins"
                fieldHelp={getFieldHelpText('ObjektVorschreibungsposition.bezeichnung')}
              />
            </Col>
          </Row>
          <FormButtons
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting || !isEmpty(formikProps.errors)}
            updateMode
            isOkLoading={loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ObjektVorschreibungPositionUpdateForm;
