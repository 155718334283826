import { FormFields } from '../../../../../helpers/formikHelper';
import { BudgetingActionAdjustAufteilungsschluesselInput, BudgetingAufteilungsschluessel, BudgetingVPos, VertragLevel } from '../../../../../types';

export type BudgetingVPosAdjustAufteilungsschluesselFormValues = {
  budgetingVPosId: string;
  leaveFlatRate: boolean;
  vertragLevelAufteilungsschluesselList: VertragLevelAufteilungsschluesselFormValues[];
};

export type VertragLevelAufteilungsschluesselFormValues = {
  aufteilungsschluesselId: string | null;
  vertragLevel: VertragLevel | null;
};

export const budgetingVPosAdjustAufteilungsschluesselFormFields: FormFields<BudgetingVPosAdjustAufteilungsschluesselFormValues> = {
  budgetingVPosId: 'budgetingVPosId',
  leaveFlatRate: 'leaveFlatRate',
  vertragLevelAufteilungsschluesselList: 'vertragLevelAufteilungsschluesselList',
};

export const vertragLevelAufteilungsschluesselFormValues: FormFields<VertragLevelAufteilungsschluesselFormValues> = {
  aufteilungsschluesselId: 'aufteilungsschluesselId',
  vertragLevel: 'vertragLevel',
};

export const createVertragLevelAufteilungsschluesselFormValues = (): VertragLevelAufteilungsschluesselFormValues => ({
  aufteilungsschluesselId: null,
  vertragLevel: null,
});

const getVertragLevelAufteilungsschluesselList = (budgetingAufteilungsschluesselList?: BudgetingAufteilungsschluessel[]) => {
  return budgetingAufteilungsschluesselList?.length
    ? budgetingAufteilungsschluesselList.map((budgetingAufteilungsschluessel) => ({
        aufteilungsschluesselId: budgetingAufteilungsschluessel.aufteilungsschluessel.aufteilungsschluesselId,
        vertragLevel: budgetingAufteilungsschluessel.vertragLevel.value,
      }))
    : [createVertragLevelAufteilungsschluesselFormValues()];
};

export const budgetingVPosAdjustAufteilungsschluesselFormInitialValues: BudgetingVPosAdjustAufteilungsschluesselFormValues = {
  budgetingVPosId: '',
  leaveFlatRate: false,
  vertragLevelAufteilungsschluesselList: getVertragLevelAufteilungsschluesselList(),
};

export const mapBudgetingVPosToFormValues = (budgetingVPos?: BudgetingVPos): BudgetingVPosAdjustAufteilungsschluesselFormValues => {
  if (!budgetingVPos) {
    return budgetingVPosAdjustAufteilungsschluesselFormInitialValues;
  }

  return {
    budgetingVPosId: budgetingVPos.budgetingVPosId,
    leaveFlatRate: budgetingVPos.leaveFlatRate,
    vertragLevelAufteilungsschluesselList: budgetingVPos.budgetingAufteilungsschluesselList.map((budgetingAufteilungsschluessel) => ({
      aufteilungsschluesselId: budgetingAufteilungsschluessel.aufteilungsschluessel.aufteilungsschluesselId,
      vertragLevel: budgetingAufteilungsschluessel.vertragLevel.value,
    })),
  };
};

export const mapFormValuesToBudgetingVPosAufteilungsschluessel = (
  formValues: BudgetingVPosAdjustAufteilungsschluesselFormValues
): BudgetingActionAdjustAufteilungsschluesselInput => ({
  budgetingVPosId: formValues.budgetingVPosId,
  leaveFlatRate: formValues.leaveFlatRate,
  vertragLevelAufteilungsschluesselList: mapVertragLevelAufteilungsschluesselFormValuesToVertragLevelAufteilungsschluesselList(
    formValues.vertragLevelAufteilungsschluesselList
  ),
});

export const mapVertragLevelAufteilungsschluesselFormValuesToVertragLevelAufteilungsschluesselList = (
  vertragLevelAufteilungsschluesselList: VertragLevelAufteilungsschluesselFormValues[]
) => {
  return vertragLevelAufteilungsschluesselList
    .filter(({ aufteilungsschluesselId, vertragLevel }) => aufteilungsschluesselId && vertragLevel)
    .map(({ aufteilungsschluesselId, vertragLevel }) => ({
      aufteilungsschluesselId: aufteilungsschluesselId as string,
      vertragLevel: vertragLevel as VertragLevel,
    }));
};
