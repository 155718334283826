import React, { FC, useEffect } from 'react';
import { useField } from 'formik';
import { Space, Tooltip } from 'antd';
import { ExclamationCircleTwoTone, WarningTwoTone } from '@ant-design/icons';
import { vorschreibungsPositionWertFormFields } from '../vertragVorschreibungsPositionWertMapper';
import SkeletonIfSteuersatzLoading from '../SkeletonIfSteuersatzLoading';
import useVVPosUstKategorieEntryWarningAndLoading from '../useVVPosUstKategorieEntryWarningAndLoading';
import SteuersatzAdminLink from '../../SteuersatzAdminLink';
import { BeVertragVorschreibungsPositionWertFieldsFragment } from '../../../gql/BeVertragVorschreibungspositionFragments.types';
import { UstKategorieWithStatus } from '../../../../../../../Ust/UstKategorie/ustKategorieUriHelper';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  vorschreibungspositionId: string;
  selectedRecord: BeVertragVorschreibungsPositionWertFieldsFragment;
};

const Steuersatz: FC<Props> = ({ objektId, bestandseinheitId, vertragId, vorschreibungspositionId, selectedRecord }) => {
  const [{ value: steuersatz }, { initialValue: steuersatzInitialValue }, { setValue: setSteuersatz }] = useField<number>(
    vorschreibungsPositionWertFormFields.steuersatz
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ value: isLoadingSteuersatz }, isLoadingSteuersatzFormikMetaIgnored, { setValue: setLoadingSteuersatz }] = useField<boolean>(
    vorschreibungsPositionWertFormFields.isLoadingSteuersatz
  );

  const isUpdate = !!selectedRecord.vertragVorschreibungspositionWertId;

  const isUpdateAndSteuersatzChanged = isUpdate && steuersatzInitialValue !== steuersatz;

  const { vpUstKategorieEntry, vpNoUstKategorieEntryWarning, loadingVPUstKategorieEntry } = useVVPosUstKategorieEntryWarningAndLoading(
    objektId,
    bestandseinheitId,
    vertragId,
    vorschreibungspositionId
  );

  // sync loading state
  useEffect(() => {
    if (!loadingVPUstKategorieEntry && vpUstKategorieEntry && isLoadingSteuersatz) {
      setLoadingSteuersatz(false);
    }
  }, [vpUstKategorieEntry, loadingVPUstKategorieEntry, setLoadingSteuersatz, isLoadingSteuersatz]);

  // sync steuersatz value
  useEffect(() => {
    if (!loadingVPUstKategorieEntry && vpUstKategorieEntry && vpUstKategorieEntry.ustKategorieEntry.steuersatz !== steuersatz) {
      setSteuersatz(vpUstKategorieEntry.ustKategorieEntry.steuersatz);
    }
  }, [vpUstKategorieEntry, steuersatz, setSteuersatz, loadingVPUstKategorieEntry]);

  return (
    <SkeletonIfSteuersatzLoading>
      {isUpdateAndSteuersatzChanged ? (
        <Space>
          <Tooltip placement="topLeft" title={`Vorsicht: Steuersatz hat sich von ${steuersatzInitialValue}% auf ${steuersatz}% verändert`}>
            <WarningTwoTone style={{ cursor: 'pointer' }} twoToneColor="orange" />
          </Tooltip>
          {vpUstKategorieEntry && (
            <SteuersatzAdminLink
              steuersatz={vpUstKategorieEntry.ustKategorieEntry.steuersatz}
              ustKategorie={vpUstKategorieEntry.ustKategorie as UstKategorieWithStatus}
              ustKategorieEntryId={vpUstKategorieEntry.ustKategorieEntry.ustKategorieEntryId}
            />
          )}
        </Space>
      ) : (
        <>
          {vpUstKategorieEntry ? (
            <SteuersatzAdminLink
              steuersatz={vpUstKategorieEntry.ustKategorieEntry.steuersatz}
              ustKategorie={vpUstKategorieEntry.ustKategorie as UstKategorieWithStatus}
              ustKategorieEntryId={vpUstKategorieEntry.ustKategorieEntry.ustKategorieEntryId}
            />
          ) : (
            <>
              {vpNoUstKategorieEntryWarning && (
                <Tooltip placement="top" title={vpNoUstKategorieEntryWarning.message}>
                  <ExclamationCircleTwoTone style={{ cursor: 'pointer' }} twoToneColor="orange" />
                </Tooltip>
              )}
            </>
          )}
        </>
      )}
    </SkeletonIfSteuersatzLoading>
  );
};

export default Steuersatz;
