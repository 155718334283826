import React, { FC } from 'react';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import { useSetMahndefinitionForRechtstraegerMutation } from '../../gql/RechnungsAusstellerMutations.types';
import { showSuccessMsgOther } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import FormButtons from '../../../../../../components/Button/FormButtons';
import {
  rechnungsAusstellerMahnDefZuweisenFormFields,
  RechnungsAusstellerMahnDefZuweisenFormValues,
  rechnungsAusstellerMahnDefZuweisenFormValuesDefault,
} from './rechnungsAusstellerMahnDefZuweisenFormMapper';
import MahndefinitionSelect from '../../../../../MahndefinitionSelect/MahndefinitionSelect';
import { useGetAppFieldHelpText } from '../../../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  rechtstraegerId: string;
  onSuccess: () => void;
  onCancel: () => void;
};

const RechnungsAusstellerMahnDefZuweisenForm: FC<Props> = ({ rechtstraegerId, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'RechnungsAusstellerActionDataSetMahndefinition'>('RechnungsAusstellerActionDataSetMahndefinition');

  const [setMahnAbrDefZuweisung, { loading: loadingSetMahnDef }] = useSetMahndefinitionForRechtstraegerMutation({
    onCompleted: () => {
      showSuccessMsgOther(`Mahndefinitionzuweisung erfolgreich.`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<RechnungsAusstellerMahnDefZuweisenFormValues>
      initialValues={rechnungsAusstellerMahnDefZuweisenFormValuesDefault}
      onSubmit={(formValues, formikHelpers) => {
        setMahnAbrDefZuweisung({
          variables: { rechtstraegerId, input: { mahndefinitionId: formValues.mahndefinitionId } },
        }).finally(() => {
          formikHelpers.setSubmitting(false);
        });
      }}
    >
      {(formikProps) => (
        <Form>
          <FormItemWithFieldHelp
            name={rechnungsAusstellerMahnDefZuweisenFormFields.mahndefinitionId}
            label="Mahndefinition"
            fieldHelp={getFieldHelpText('RechnungsAusstellerActionDataSetMahndefinition.mahndefinitionId')}
          >
            <MahndefinitionSelect
              name={rechnungsAusstellerMahnDefZuweisenFormFields.mahndefinitionId}
              id={rechnungsAusstellerMahnDefZuweisenFormFields.mahndefinitionId}
            />
          </FormItemWithFieldHelp>
          <FormButtons
            updateMode={false}
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={loadingSetMahnDef}
            okText="Mahndefinition zuweisen"
          />
        </Form>
      )}
    </Formik>
  );
};

export default RechnungsAusstellerMahnDefZuweisenForm;
