import { generatePath } from 'react-router-dom';
import { URI_OBJEKT_DETAILS_PAGE } from '../Objekt/objektUriPaths';

export const URI_OBJEKT_DETAILS_PAGE_BESTANDSEINHEIT_LISTING = `${URI_OBJEKT_DETAILS_PAGE}/units`;
export const URI_OBJEKT_BESTANDSEINHEIT_DETAILS_PAGE = `${URI_OBJEKT_DETAILS_PAGE_BESTANDSEINHEIT_LISTING}/:bestandseinheitId`;

export const generatePathToBestandseinheitDetailsPage = (objektId: string, bestandseinheitId: string): string =>
  generatePath(URI_OBJEKT_BESTANDSEINHEIT_DETAILS_PAGE, { objektId, bestandseinheitId });

export const generatePathToBestandseinheitDetailsPageInfoFeldMenu = (objektId: string, bestandseinheitId: string): string =>
  `${generatePathToBestandseinheitDetailsPage(objektId, bestandseinheitId)}/info-felder`;
