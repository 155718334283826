import React, { FC } from 'react';
import { Typography } from 'antd';
import LinkToBelegFile from '../../../components/Link/LinkToBelegFile/LinkToBelegFile';
import { AuftragLinkListEntry } from '../../../types';

type AuftragsketteBelegnummerColProps = {
  record: AuftragLinkListEntry;
};

const AuftragsketteBelegnummerCol: FC<AuftragsketteBelegnummerColProps> = ({ record }) => {
  return record.belegFileId && record.belegnummer ? (
    <LinkToBelegFile fileId={record.belegFileId} belegnummer={record.belegnummer} />
  ) : (
    <Typography.Text type={record.current ? 'secondary' : undefined}>{record.belegnummer ?? '-'}</Typography.Text>
  );
};

export default AuftragsketteBelegnummerCol;
