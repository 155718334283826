import { IndexSeries, IndexSeriesCreateInput, IndexSeriesSourceType, IndexSeriesUpdateInput, IndexType } from '../../../types';
import { FormFields } from '../../../helpers/formikHelper';
import { isIndexSeriesSourceTypeImport } from '../indexSeriesHelpers';

export type IndexSeriesFormValues = {
  chainingFactor?: number | null;
  csvColumnImportId?: string | null;
  csvColumnIndexPoint?: string | null;
  csvColumnMonthYear?: string | null;
  csvRowImportId?: string | null;
  importUrl?: string | null;
  indexType: IndexType | null;
  name: string;
  sourceType: IndexSeriesSourceType | null;
};

export const indexSeriesFormFields: FormFields<IndexSeriesFormValues> = {
  chainingFactor: 'chainingFactor',
  csvColumnImportId: 'csvColumnImportId',
  csvColumnIndexPoint: 'csvColumnIndexPoint',
  csvColumnMonthYear: 'csvColumnMonthYear',
  csvRowImportId: 'csvRowImportId',
  importUrl: 'importUrl',
  indexType: 'indexType',
  name: 'name',
  sourceType: 'sourceType',
};

export const mapIndexSeriesToFormValues = (indexSeries?: IndexSeries): IndexSeriesFormValues => ({
  chainingFactor: indexSeries?.chainingFactor,
  csvColumnImportId: indexSeries?.csvColumnImportId,
  csvColumnIndexPoint: indexSeries?.csvColumnIndexPoint,
  csvColumnMonthYear: indexSeries?.csvColumnMonthYear,
  csvRowImportId: indexSeries?.csvRowImportId,
  importUrl: indexSeries?.importUrl,
  indexType: indexSeries?.indexType.value ?? null,
  name: indexSeries?.name ?? '',
  sourceType: indexSeries?.sourceType.value ?? null,
});

export const mapFormValuesToIndexSeries = (values: IndexSeriesFormValues): IndexSeriesCreateInput | IndexSeriesUpdateInput => ({
  name: values.name,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  indexType: values.indexType!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  sourceType: values.sourceType!,
  ...(isIndexSeriesSourceTypeImport(values.sourceType) && { ...mapFormValuesToSourceTypeImportValues(values) }),
});

type SourceTypeImportRelatedValues = {
  chainingFactor?: number | null;
  csvColumnImportId?: string | null;
  csvColumnIndexPoint?: string | null;
  csvColumnMonthYear?: string | null;
  csvRowImportId?: string | null;
  importUrl?: string | null;
};

const mapFormValuesToSourceTypeImportValues = (values: IndexSeriesFormValues): SourceTypeImportRelatedValues => {
  return {
    importUrl: values.importUrl,
    csvColumnMonthYear: values.csvColumnMonthYear,
    csvColumnImportId: values.csvColumnImportId,
    csvRowImportId: values.csvRowImportId,
    csvColumnIndexPoint: values.csvColumnIndexPoint,
    chainingFactor: values.chainingFactor,
  };
};
