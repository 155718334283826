import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReimportDataCarrierCamtMutationVariables = Types.Exact<{
  dataCarrierId: Types.Scalars['ID']['input'];
}>;

export type ReimportDataCarrierCamtMutation = {
  actionReimportDataCarrierCamt: {
    data: { dataCarrierId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type DeleteDataCarrierCamtMutationVariables = Types.Exact<{
  dataCarrierId: Types.Scalars['ID']['input'];
}>;

export type DeleteDataCarrierCamtMutation = {
  deleteDataCarrierCamt: { errorList: Array<{ message: string; type: string }>; warningList: Array<{ message: string; type: string }> };
};

export type DeleteBankStatementMutationVariables = Types.Exact<{
  dataCarrierId: Types.Scalars['ID']['input'];
  bankStatementId: Types.Scalars['ID']['input'];
}>;

export type DeleteBankStatementMutation = {
  deleteBankStatement: { errorList: Array<{ message: string; type: string }>; warningList: Array<{ message: string; type: string }> };
};

export type UploadedDataCarrierPainMutationVariables = Types.Exact<{
  dataCarrierId: Types.Scalars['ID']['input'];
}>;

export type UploadedDataCarrierPainMutation = {
  actionUploadedDataCarrierPain: {
    data: { dataCarrierId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export type UploadManuallyDataCarrierPainMutationVariables = Types.Exact<{
  dataCarrierId: Types.Scalars['ID']['input'];
}>;

export type UploadManuallyDataCarrierPainMutation = {
  actionUploadManuallyDataCarrierPain: {
    data: { dataCarrierId: string };
    errorList: Array<{ message: string; type: string }>;
    warningList: Array<{ message: string; type: string }>;
  };
};

export const ReimportDataCarrierCamtDocument = gql`
  mutation ReimportDataCarrierCamt($dataCarrierId: ID!) {
    actionReimportDataCarrierCamt(dataCarrierId: $dataCarrierId) {
      data {
        dataCarrierId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useReimportDataCarrierCamtMutation(
  baseOptions?: Apollo.MutationHookOptions<ReimportDataCarrierCamtMutation, ReimportDataCarrierCamtMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReimportDataCarrierCamtMutation, ReimportDataCarrierCamtMutationVariables>(ReimportDataCarrierCamtDocument, options);
}
export type ReimportDataCarrierCamtMutationHookResult = ReturnType<typeof useReimportDataCarrierCamtMutation>;
export type ReimportDataCarrierCamtMutationResult = Apollo.MutationResult<ReimportDataCarrierCamtMutation>;
export type ReimportDataCarrierCamtMutationOptions = Apollo.BaseMutationOptions<
  ReimportDataCarrierCamtMutation,
  ReimportDataCarrierCamtMutationVariables
>;
export const DeleteDataCarrierCamtDocument = gql`
  mutation DeleteDataCarrierCamt($dataCarrierId: ID!) {
    deleteDataCarrierCamt(dataCarrierId: $dataCarrierId) {
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeleteDataCarrierCamtMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteDataCarrierCamtMutation, DeleteDataCarrierCamtMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteDataCarrierCamtMutation, DeleteDataCarrierCamtMutationVariables>(DeleteDataCarrierCamtDocument, options);
}
export type DeleteDataCarrierCamtMutationHookResult = ReturnType<typeof useDeleteDataCarrierCamtMutation>;
export type DeleteDataCarrierCamtMutationResult = Apollo.MutationResult<DeleteDataCarrierCamtMutation>;
export type DeleteDataCarrierCamtMutationOptions = Apollo.BaseMutationOptions<DeleteDataCarrierCamtMutation, DeleteDataCarrierCamtMutationVariables>;
export const DeleteBankStatementDocument = gql`
  mutation DeleteBankStatement($dataCarrierId: ID!, $bankStatementId: ID!) {
    deleteBankStatement(dataCarrierId: $dataCarrierId, bankStatementId: $bankStatementId) {
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useDeleteBankStatementMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBankStatementMutation, DeleteBankStatementMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBankStatementMutation, DeleteBankStatementMutationVariables>(DeleteBankStatementDocument, options);
}
export type DeleteBankStatementMutationHookResult = ReturnType<typeof useDeleteBankStatementMutation>;
export type DeleteBankStatementMutationResult = Apollo.MutationResult<DeleteBankStatementMutation>;
export type DeleteBankStatementMutationOptions = Apollo.BaseMutationOptions<DeleteBankStatementMutation, DeleteBankStatementMutationVariables>;
export const UploadedDataCarrierPainDocument = gql`
  mutation UploadedDataCarrierPain($dataCarrierId: ID!) {
    actionUploadedDataCarrierPain(dataCarrierId: $dataCarrierId) {
      data {
        dataCarrierId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUploadedDataCarrierPainMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadedDataCarrierPainMutation, UploadedDataCarrierPainMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadedDataCarrierPainMutation, UploadedDataCarrierPainMutationVariables>(UploadedDataCarrierPainDocument, options);
}
export type UploadedDataCarrierPainMutationHookResult = ReturnType<typeof useUploadedDataCarrierPainMutation>;
export type UploadedDataCarrierPainMutationResult = Apollo.MutationResult<UploadedDataCarrierPainMutation>;
export type UploadedDataCarrierPainMutationOptions = Apollo.BaseMutationOptions<
  UploadedDataCarrierPainMutation,
  UploadedDataCarrierPainMutationVariables
>;
export const UploadManuallyDataCarrierPainDocument = gql`
  mutation UploadManuallyDataCarrierPain($dataCarrierId: ID!) {
    actionUploadManuallyDataCarrierPain(dataCarrierId: $dataCarrierId) {
      data {
        dataCarrierId
      }
      errorList {
        message
        type
      }
      warningList {
        message
        type
      }
    }
  }
`;
export function useUploadManuallyDataCarrierPainMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadManuallyDataCarrierPainMutation, UploadManuallyDataCarrierPainMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadManuallyDataCarrierPainMutation, UploadManuallyDataCarrierPainMutationVariables>(
    UploadManuallyDataCarrierPainDocument,
    options
  );
}
export type UploadManuallyDataCarrierPainMutationHookResult = ReturnType<typeof useUploadManuallyDataCarrierPainMutation>;
export type UploadManuallyDataCarrierPainMutationResult = Apollo.MutationResult<UploadManuallyDataCarrierPainMutation>;
export type UploadManuallyDataCarrierPainMutationOptions = Apollo.BaseMutationOptions<
  UploadManuallyDataCarrierPainMutation,
  UploadManuallyDataCarrierPainMutationVariables
>;
