import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type RechtstraegerBaseFieldsFragment = {
  createTs: string;
  createdBy: string;
  createdByMitarbeiterId?: string | null;
  kurzBezeichnung: string;
  rechtstraegerId: string;
  status: { text: string; value: Types.RechtstraegerStatus };
  type: { text: string; value: Types.RechtstraegerType };
  warningList: Array<{ attribute?: string | null; message: string; type: string }>;
};

export const RechtstraegerBaseFieldsFragmentDoc = gql`
  fragment RechtstraegerBaseFields on RechtstraegerBase {
    createTs
    createdBy
    createdByMitarbeiterId
    kurzBezeichnung
    rechtstraegerId
    status {
      text
      value
    }
    type {
      text
      value
    }
    warningList {
      attribute
      message
      type
    }
  }
`;
