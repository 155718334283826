import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import { useFirmendatenIndexSeriesQuery } from '../../../../features/Firmendaten/IndexSeries/gql/FirmendatenIndexSeriesQueries.types';
import { FirmendatenIndexSeriesDetailsRouteParams } from './routes';
import IndexSeriesDetailsListing from '../../../../features/IndexSeries/shared/DetailsListing/IndexSeriesDetailsListing';

const FirmendatenIndexSeriesDetailsPage: FC = () => {
  const { indexSeriesId } = useParams() as FirmendatenIndexSeriesDetailsRouteParams;
  const { data, refetch } = useFirmendatenIndexSeriesQuery({
    variables: {
      indexSeriesId,
    },
  });

  const indexSeries = data?.getFirmendatenIndexSeries.data;

  if (!indexSeries) return <Skeleton active />;

  return <IndexSeriesDetailsListing indexSeries={indexSeries} onAction={refetch} isAndromedaAdmin={false} />;
};

export default FirmendatenIndexSeriesDetailsPage;
