import React, { FC } from 'react';
import { FormikProps } from 'formik';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import { useRechtstraegerListQuery } from '../../../../Rechtstraeger/gql/RechtsTraegerQueries.types';
import { BookingInstructionFormValues } from '../../bookingInstructionFormMapper';

type Props = {
  formikProps: FormikProps<BookingInstructionFormValues>;
} & Omit<SelectProps, 'id' | 'placeholder' | 'filterOption'>;

const BuchungskreisSelect: FC<Props> = ({ formikProps, ...restProps }) => {
  const { data, loading } = useRechtstraegerListQuery({
    variables: {
      hasBuchungskreis: true,
    },
  });

  const rechtstraegerWithBuchungskreisList = data?.getRechtstraegerList.data.contentList || [];

  return (
    <Select
      {...restProps}
      id={restProps.name}
      name={restProps.name}
      loading={loading}
      placeholder="Buchungskreis auswählen"
      allowClear
      showSearch
      style={{ width: '100%' }}
      size="small"
      filterOption={selectFilterOption}
    >
      {rechtstraegerWithBuchungskreisList.map((rechtstrager) => (
        <Select.Option key={rechtstrager.rechtstraegerId} value={rechtstrager.rechtstraegerId!}>
          {rechtstrager.kurzBezeichnung}
        </Select.Option>
      ))}
    </Select>
  );
};

export default BuchungskreisSelect;
