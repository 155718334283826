import React, { FC } from 'react';
import { Button, Dropdown, Modal, Space, Typography } from 'antd';
import { BankOutlined, EllipsisOutlined, FilePdfOutlined } from '@ant-design/icons';
import BankDetailsCardListing from './Card/BankDetailsCardListing';
import BankDetailsCreateDrawer from './Drawer/BankDetailsCreateDrawer';
import { useToggle } from '../../hooks/useToggle';
import { BankDetails, FirmendatenBankDetails } from '../../types';
import { downloadSepaMandatMusterPdf } from './SepaMandat/sepaMandatAsyncHelper';
import { useFDAuthorized } from '../../shared/Auth/useAuthorized';
import { downloadURL } from '../../helpers/fileHelper';
import { showSuccessMsgOther, showWarningMessage } from '../../components/message';
import PDFCreatingStateView from '../shared/PDFCreatingStateView';

type Props = {
  loading: boolean;
  bankDetailsList: BankDetails[] | FirmendatenBankDetails[];
  onSuccess: () => void;
  rechtstraegerId?: string;
  firmendatenId?: string;
  fibuKontoCreatable?: boolean;
  showArchived: boolean;
  toggleShowArchived?: () => void;
};

// Create an AbortController instance
let sepaMandatMusterPdfDownloadAbortController: AbortController;

const BankDetailsListing: FC<Props> = ({
  rechtstraegerId,
  firmendatenId,
  loading,
  bankDetailsList,
  onSuccess,
  fibuKontoCreatable,
  showArchived,
  toggleShowArchived,
}) => {
  const { activeForFirmendatenId } = useFDAuthorized();
  const [isCreateDrawerOpen, toggleCreateDrawerOpen] = useToggle();
  const [showExportSampleAsPdfModal, toggleExportSampleAsPdfModal] = useToggle();

  const rechtstraegerIdForSepaMandat = (rechtstraegerId || firmendatenId) as Required<string>;

  const handleDownloadSepaMandatMusterPdf = () => {
    sepaMandatMusterPdfDownloadAbortController = new AbortController();

    toggleExportSampleAsPdfModal();
    downloadSepaMandatMusterPdf(activeForFirmendatenId, rechtstraegerIdForSepaMandat, sepaMandatMusterPdfDownloadAbortController)
      .then((res: any) => {
        downloadURL(res.url);
        showSuccessMsgOther(`SEPA Mandat-Muster erfolgreich heruntergeladen.`);
      })
      .catch((error: any) => {
        showWarningMessage(error, 'SEPA Mandat-Muster wurde nicht heruntergeladen');
      })
      .finally(() => toggleExportSampleAsPdfModal());
  };

  const items = [
    { key: '1', label: 'Bankverbindung anlegen', onClick: toggleCreateDrawerOpen, icon: <BankOutlined /> },
    {
      key: '2',
      label: `Archivierte Bankverbindungen ${showArchived ? 'ausblenden' : 'anzeigen'}`,
      onClick: toggleShowArchived,
      icon: <BankOutlined />,
    },
    {
      key: '3',
      label: 'Sepa-Lastschrift-Mandat Muster herunterladen',
      icon: <FilePdfOutlined />,
      onClick: () => handleDownloadSepaMandatMusterPdf(),
    },
  ];

  return (
    <>
      <Space style={{ width: '100%', justifyContent: 'space-between' }}>
        <Typography.Title level={5}>Bankverbindung</Typography.Title>
        <Dropdown menu={{ items }}>
          <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
        </Dropdown>
      </Space>
      <BankDetailsCardListing
        loading={loading}
        bankDetailsList={bankDetailsList}
        rechtstraegerId={rechtstraegerId}
        firmendatenId={firmendatenId}
        onAction={onSuccess}
      />
      <BankDetailsCreateDrawer
        rechtstraegerId={rechtstraegerId}
        firmendatenId={firmendatenId}
        isDrawerOpen={isCreateDrawerOpen}
        toggleDrawerOpen={toggleCreateDrawerOpen}
        onActionSuccess={onSuccess}
        fibuKontoCreatable={fibuKontoCreatable}
      />
      <Modal
        open={showExportSampleAsPdfModal}
        title="SEPA Mandat-Muster PDF exportieren"
        footer={
          <Button onClick={() => sepaMandatMusterPdfDownloadAbortController.abort()} type="primary">
            Abbrechen
          </Button>
        }
      >
        <PDFCreatingStateView />
      </Modal>
    </>
  );
};

export default BankDetailsListing;
