import React, { FC } from 'react';
import { Space, Switch, Typography } from 'antd';
import { useUpdateVorschreibungMailDeliveryMutation } from '../../../gql/VorschreibungMailDeliveryWizardMutations.types';
import { showSuccessMsgUpdate } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { useToggle } from '../../../../../../hooks/useToggle';
import { VorschreibungMailDelivery } from '../../../../../../types';

type Props = {
  vorschreibungMailDelivery: VorschreibungMailDelivery;
  onUpdate: () => void;
  wizardId: string;
  isCompleted?: boolean;
};
const SendSwitchColumn: FC<Props> = ({ vorschreibungMailDelivery, onUpdate, wizardId, isCompleted }) => {
  const [shouldNeverSend, toggleShouldNeverSend] = useToggle(vorschreibungMailDelivery.shouldNeverSend);

  const [runUpdate, { loading }] = useUpdateVorschreibungMailDeliveryMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(`E-Mail Versand`);
      onUpdate();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Space align="end">
      <Switch
        size="small"
        id="shouldNeverSend"
        onChange={(value) => {
          toggleShouldNeverSend();

          if (isCompleted) return;
          runUpdate({
            variables: {
              vorschreibungMailDeliveryWizardId: wizardId,
              vorschreibungMailDeliveryId: vorschreibungMailDelivery.vorschreibungMailDeliveryId,
              shouldSend: false,
              shouldNeverSend: value,
            },
          });
        }}
        checked={shouldNeverSend}
        disabled={loading || isCompleted}
      />
      <Typography.Text style={{ width: 92, display: 'flex' }}>{shouldNeverSend ? 'nicht senden' : 'wieder vorlegen'}</Typography.Text>
    </Space>
  );
};

export default SendSwitchColumn;
