import { FC } from 'react';
import HistoryListingTable from '../../../History/HistoryListingTable';
import { HistoryType } from '../../../History/shared/HistoryModal';
import { useVorschreibungspositionTemplateChangeHistoryListQuery } from '../../gql/VorschreibungspositionTemplateQueries.types';

type Props = {
  vorschreibungspositionId: string;
};

const VorschreibungspositionTemplateChangeHistoryListingTable: FC<Props> = ({ vorschreibungspositionId }) => {
  const { data, loading } = useVorschreibungspositionTemplateChangeHistoryListQuery({ variables: { vorschreibungspositionId } });
  const historyList = data?.getVorschreibungspositionTemplateChangeHistoryList.data;

  return (
    <HistoryListingTable
      historyType={HistoryType.Change}
      historyList={historyList}
      loading={loading}
      filterIdPrefix="vorschreibungsposition-template"
    />
  );
};

export default VorschreibungspositionTemplateChangeHistoryListingTable;
