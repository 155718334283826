export interface EmailUpdateFormValues {
  currentEmail: string;
  email: string;
  password?: string;
}

export const emailUpdateFormInitialValues: EmailUpdateFormValues = {
  currentEmail: '',
  email: '',
  password: '',
};
