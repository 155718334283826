import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { useFilterPaymentTypeListQuery } from './gql/PaymentFilterQueries.types';

type Props = Omit<SelectProps, 'id'>;

const PaymentTypeSelect: FC<Props> = ({ ...restProps }) => {
  const { loading, data } = useFilterPaymentTypeListQuery();
  const paymentTypeList = data?.getFilterPaymentTypeList.data ?? [];

  return (
    <Select
      {...restProps}
      mode="multiple"
      size="small"
      id={restProps.name}
      loading={loading}
      placeholder="Zahlungstyp auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {paymentTypeList.map((type) => (
        <Select.Option key={type.value} value={type.value}>
          {type.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default PaymentTypeSelect;
