import React from 'react';
import { Route } from 'react-router';
import AuthRoute from '../../../../security/AuthRoute';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../../../constants/andromedaSystemSettingsUriPaths';
import AndromedaSysSettingsVorschreibungspositionPage from './AndromedaSysSettingsVorschreibungspositionPage';
import { isAdmin } from '../../../../security/permissionChecks';

const andromedaSysSettingsVorschreibunspositionPageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS.vorschreibungspositionPage}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<AndromedaSysSettingsVorschreibungspositionPage />} />}
  />
);

export default andromedaSysSettingsVorschreibunspositionPageRoute;
