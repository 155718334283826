import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateIndexValueCreationCronJobSettingsMutationVariables = Types.Exact<{
  input: Types.IndexValueCreationCronJobSettingsUpdateInput;
}>;

export type UpdateIndexValueCreationCronJobSettingsMutation = {
  updateIndexValueCreationCronJobSettings: {
    data: { indexAnnual: boolean; indexThreshold: boolean };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const UpdateIndexValueCreationCronJobSettingsDocument = gql`
  mutation UpdateIndexValueCreationCronJobSettings($input: IndexValueCreationCronJobSettingsUpdateInput!) {
    updateIndexValueCreationCronJobSettings(input: $input) {
      data {
        indexAnnual
        indexThreshold
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateIndexValueCreationCronJobSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateIndexValueCreationCronJobSettingsMutation, UpdateIndexValueCreationCronJobSettingsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateIndexValueCreationCronJobSettingsMutation, UpdateIndexValueCreationCronJobSettingsMutationVariables>(
    UpdateIndexValueCreationCronJobSettingsDocument,
    options
  );
}
export type UpdateIndexValueCreationCronJobSettingsMutationHookResult = ReturnType<typeof useUpdateIndexValueCreationCronJobSettingsMutation>;
export type UpdateIndexValueCreationCronJobSettingsMutationResult = Apollo.MutationResult<UpdateIndexValueCreationCronJobSettingsMutation>;
export type UpdateIndexValueCreationCronJobSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateIndexValueCreationCronJobSettingsMutation,
  UpdateIndexValueCreationCronJobSettingsMutationVariables
>;
