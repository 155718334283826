import React, { FC } from 'react';
import { Empty } from 'antd';
import rechnungsAusstellerRepFondsTableColumns from './rechnungsAusstellerRepFondsTableColumns';
import TableWithColSelector from '../../../../../../components/Table/TableWithColSelector/TableWithColSelector';
import { RepFondsErloesKontenZuweisung } from '../../../../../../types';

type RechnungsAusstellerRepFondsTableProps = {
  loading: boolean;
  rechtstraegerId: string;
  repFondsErloesKontenZuweisungList?: RepFondsErloesKontenZuweisung[];
  onSuccess: () => void;
};

const RechnungsAusstellerRepFondsTable: FC<RechnungsAusstellerRepFondsTableProps> = ({
  rechtstraegerId,
  loading,
  repFondsErloesKontenZuweisungList,
  onSuccess,
}) => {
  return (
    <TableWithColSelector<RepFondsErloesKontenZuweisung>
      loading={loading}
      locale={{
        emptyText: <Empty description={<span>Keine Erlöskonten zugewiesen</span>} />,
      }}
      pagination={false}
      dataSource={repFondsErloesKontenZuweisungList}
      columns={rechnungsAusstellerRepFondsTableColumns(rechtstraegerId, onSuccess, repFondsErloesKontenZuweisungList)}
      rowKey={(record) => record.createTs}
      filterIdentifier="rechnungsaussteller-rep-fonds"
    />
  );
};

export default RechnungsAusstellerRepFondsTable;
