import { Select, SelectProps } from 'formik-antd';
import React, { FC } from 'react';
import { selectFilterOption } from '../../../../../helpers/selectHelper';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';

type Props = {
  list: SyncEbicsUserTeilmnehmerkennungFormSelectProps[];
  width?: string;
} & SelectProps;

export interface SyncEbicsUserTeilmnehmerkennungFormSelectProps {
  userId: string;
}

const SyncEbicsUserTeilnehmerkennungFormSelect: FC<Props> = ({ list, name, width, ...restProps }) => {
  return (
    <Select
      size="small"
      placeholder="Teilnehmerkennung auswählen"
      name={name}
      showSearch
      allowClear
      filterOption={selectFilterOption}
      style={{ width: width || '100%' }}
      {...restProps}
    >
      {list.map((teilnehmerkennung) => (
        <Select.Option key={teilnehmerkennung.userId} value={teilnehmerkennung.userId}>
          <DataWithShortenedText maxTextLength={25} text={teilnehmerkennung.userId} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default SyncEbicsUserTeilnehmerkennungFormSelect;
