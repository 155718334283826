import { FormFields } from '../../../../helpers/formikHelper';
import { VertragLevel, Verwaltungsart } from '../../../../types';

export type ListingFiltersFormValues = {
  aufteilungsschluesselId: string;
  objektVerwaltungsart: Verwaltungsart | null;
  vertragLevel: VertragLevel | null;
  vorschreibungspositionId: string;
};

export const listingFiltersFormFields: FormFields<ListingFiltersFormValues> = {
  aufteilungsschluesselId: 'aufteilungsschluesselId',
  objektVerwaltungsart: 'objektVerwaltungsart',
  vertragLevel: 'vertragLevel',
  vorschreibungspositionId: 'vorschreibungspositionId',
};

export const listingFiltersFormInitialValues: ListingFiltersFormValues = {
  aufteilungsschluesselId: '',
  objektVerwaltungsart: null,
  vertragLevel: null,
  vorschreibungspositionId: '',
};
