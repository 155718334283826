import { FC } from 'react';
import { Typography } from 'antd';
import VorschreibungErstellenVerarbeitungEntryListing from './EntryListing/VorschreibungErstellenVerarbeitungEntryListing';
import { verarbeitungPageAndMenuListTitles } from '../../verarbeitungHelpers';
import { Spacer } from '../../../../components/Grid';
import { AuftragGenerierlauf } from '../../../../types';

type Props = {
  generierlauf: AuftragGenerierlauf;
};

const VerarbeitungVorschreibungErstellen: FC<Props> = ({ generierlauf }) => (
  <>
    <Typography.Title level={4}>{verarbeitungPageAndMenuListTitles.Auftragserstellung}</Typography.Title>
    <Spacer />
    <VorschreibungErstellenVerarbeitungEntryListing generierlauf={generierlauf} />
  </>
);

export default VerarbeitungVorschreibungErstellen;
