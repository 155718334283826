import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { BookingSuggestionVerbuchenGenerierlauf } from '../../../../types';
import { useQueryParams } from '../../../../hooks/useStringQueryParams';
import { usePaginationQueryParams } from '../../../../hooks/usePaginationQueryParams';
import { TBookingSuggestionVerbuchenQueryParams, updateQueryParams } from './Filters/filtersQueryParams';
import { FiltersFormValues, mapQueryParamsToFormValues } from './Filters/listingFiltersFormMapper';
import ListingFilters from './Filters/ListingFilters';
import BookingSuggestionVerbuchenEntryTable from './BookingSuggestionVerbuchenEntryTable';

type Props = {
  generierlauf: BookingSuggestionVerbuchenGenerierlauf;
  verarbeitungType: string;
};

const EntryListing: FC<Props> = ({ generierlauf, verarbeitungType }) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams<TBookingSuggestionVerbuchenQueryParams>();
  const paginationParams = usePaginationQueryParams();

  const setInitialQueryParams = () => {
    updateQueryParams(navigate, verarbeitungType, generierlauf.generierlaufId, queryParams, paginationParams);
  };

  useEffect(() => {
    setInitialQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (values: FiltersFormValues) => {
    updateQueryParams(navigate, verarbeitungType, generierlauf.generierlaufId, values, paginationParams);
  };

  return (
    <Formik<FiltersFormValues> initialValues={mapQueryParamsToFormValues(queryParams)} onSubmit={onChange}>
      {(formikProps) => (
        <>
          <ListingFilters formikProps={formikProps} generierlaufId={generierlauf.generierlaufId} />
          <BookingSuggestionVerbuchenEntryTable generierlaufId={generierlauf.generierlaufId} queryParams={queryParams} />
        </>
      )}
    </Formik>
  );
};

export default EntryListing;
