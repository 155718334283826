import React, { FC } from 'react';
import { FieldArray, useField, useFormikContext } from 'formik';
import { List } from 'antd';
import { ZuordnungFormValues, ZuordnungKontoListingFormValues } from '../zuordnungKontoListingFormMapper';
import ZuordnungFormFieldArrayListItemAndModal from './ZuordnungFormFieldArrayListItemAndModal';
import { ZuordnungKontoKlasseType } from '../../shared/AbrKreisKontenzuordnungHelpers';
import { AbrechdefAbrechkreis, AbrechnungsdefinitionType, Konto } from '../../../../types';
import { AbrKontenzuordnungFiltersFormValues } from '../../Listing/Filters/abrKontenzuordnungFiltersMapper';

type Props = {
  name: string;
  abrDefinitionType: AbrechnungsdefinitionType;
  shouldSubmitOnChange?: boolean;
  zuordnungsKontoKlasseType: ZuordnungKontoKlasseType;
  filters?: AbrKontenzuordnungFiltersFormValues | null;
  kontoList: Konto[];
  abrDefVersionAbrechkreisList: AbrechdefAbrechkreis[];
};

const ZuordnungFormFieldArray: FC<Props> = ({
  name,
  abrDefinitionType,
  zuordnungsKontoKlasseType,
  shouldSubmitOnChange,
  filters,
  kontoList,
  abrDefVersionAbrechkreisList,
}) => {
  const [, { value: zuordnungList }] = useField<ZuordnungFormValues[]>(name);
  const { submitForm } = useFormikContext<ZuordnungKontoListingFormValues[]>();

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <List
          size="small"
          bordered={false}
          dataSource={zuordnungList}
          renderItem={(item, index) => (
            <ZuordnungFormFieldArrayListItemAndModal
              key={index}
              konto={kontoList[index]}
              item={item}
              abrDefinitionType={abrDefinitionType}
              nameForAbrKreisSelect={`${name}.${index}.abrechnungskreis.abrechnungskreisId`}
              nameForKontoVerwendung={`${name}.${index}.ustVomAufwand`}
              nameForBuchungszeilenAnzeigen={`${name}.${index}.buchungszeilenAnzeigen`}
              nameForAufteilungsschluesselId={`${name}.${index}.aufteilungsschluessel.aufteilungsschluesselId`}
              nameForAufteilungsschluesselBezeichnung={`${name}.${index}.aufteilungsschluessel.kurzBezeichnung`}
              nameForExpenseReducing={`${name}.${index}.expenseReducing`}
              abrDefVersionAbrechkreisList={abrDefVersionAbrechkreisList}
              zuordnungKontoKlasseType={zuordnungsKontoKlasseType}
              onItemChange={(abrechnungskreisId, ustVomAufwandValue, buchungszeilenAnzeigen, expenseReducing, aufteilungsschluesselId) => {
                const zuordnungFormValues: ZuordnungFormValues = {
                  ...item,
                  abrechnungskreis: {
                    ...item.abrechnungskreis,
                    abrechnungskreisId: abrechnungskreisId ?? '',
                  },
                  ustVomAufwand: ustVomAufwandValue,
                  buchungszeilenAnzeigen,
                  expenseReducing,
                  ...(item.aufteilungsschluessel &&
                    aufteilungsschluesselId && {
                      aufteilungsschluessel: {
                        ...item.aufteilungsschluessel,
                        aufteilungsschluesselId,
                      },
                    }),
                };
                arrayHelpers.replace(index, zuordnungFormValues);
                if (shouldSubmitOnChange) submitForm(); // Used for saving KontenZuordnung form when Abr.Definition is being updated
              }}
              filters={filters}
            />
          )}
        />
      )}
    />
  );
};

export default ZuordnungFormFieldArray;
