import React, { FC } from 'react';
import { Select } from 'formik-antd';
import { AuftragsartType } from '../../../../types';
import ParamFormItem from '../../../../components/Form/ParamFormItem';
import FormItemWithoutColon from '../../../../components/Form/FormItemWithoutColon';
import { useAuftragsartTemplateListForStornoSelectQuery } from '../../gql/AuftragsartTemplateQueries.types';

type Props = {
  name: string;
  auftragsartType: AuftragsartType;
  isUpdate?: boolean;
};
const ParamFormItemStornoAuftragsartSelect: FC<Props> = ({ name, auftragsartType, isUpdate = false }) => {
  const { data, loading } = useAuftragsartTemplateListForStornoSelectQuery({ variables: { stornoForType: auftragsartType } });
  const stornoAuftragsartListForSelect = data?.getAuftragsartTemplateList.data ?? [];

  const select = (
    <Select name={name} id={name} loading={loading} allowClear placeholder="Vorlage für Storno Auftragsart auswählen">
      {stornoAuftragsartListForSelect.map((stornoAuftragsart) => (
        <Select.Option key={stornoAuftragsart.auftragsartId} value={stornoAuftragsart.auftragsartId}>
          {stornoAuftragsart.bezeichnung}
        </Select.Option>
      ))}
    </Select>
  );

  return isUpdate ? (
    <ParamFormItem name={name} label="Storno-Auftragsart-Vorlage" marginBottom="16px">
      {select}
    </ParamFormItem>
  ) : (
    <FormItemWithoutColon name={name} label="Storno-Auftragsart-Vorlage">
      {select}
    </FormItemWithoutColon>
  );
};
export default ParamFormItemStornoAuftragsartSelect;
