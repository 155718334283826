import React, { FC } from 'react';
import LabelValue from '../../../shared/LabelValue';
import pdfStyles from '../../../styles/pdfStyles';
import { LabeledValue } from '../../../shared/types';

const PositionSumme: FC<{ data: LabeledValue }> = ({ data }) => (
  <LabelValue
    valueTuple={data}
    viewStyle={{ ...pdfStyles.borderBottomSmall, paddingTop: '3mm', borderBottomWidth: 2 }}
    labelStyle={{ fontWeight: 'bold' }}
    valueStyle={{ fontWeight: 'bold' }}
  />
);

export default PositionSumme;
