import React, { FC } from 'react';
import { FormattedNumber } from 'react-intl';
import { Tag } from 'antd';
import {
  hasDeviationCellEmptyValue,
  isDeviationAttributeTypeAmount,
  isDeviationAttributeTypeDate,
  isDeviationAttributeTypeNumber,
  isDeviationAttributeTypePercent,
  isDeviationAttributeTypeString,
} from '../../../deviationHelpers';
import { TDeviationCell, TDeviationReference } from '../../../deviation.model';
import { EuroAmount, Percent } from '../../../../Number';
import { DeviationAttributeNameType } from '../../../../../types';
import DataWithShortenedTextAndExtraInfo from '../../../../Helpers/DataWithShortenedTextAndExtraInfo';
import DataWithShortenedText from '../../../../Helpers/DataWithShortenedText';
import CustomFormattedDate from '../../../../FormattedDate/CustomFormattedDate';

type Props = {
  cell: TDeviationCell;
  columnCount: number;
};

const DeviationFormattedCellValueBasedOnType: FC<Props> = ({ cell, columnCount }) => {
  if (isDeviationAttributeTypeString(cell.columnType) && cell.header.value === DeviationAttributeNameType.Bezeichnung) {
    const text = cell.value as string;

    return (
      <DataWithShortenedTextAndExtraInfo maxTextLength={maxCellTextLength(columnCount)} text={text}>
        {(shortenedText) => <Tag>{shortenedText}</Tag>}
      </DataWithShortenedTextAndExtraInfo>
    );
  }
  if (!hasDeviationCellEmptyValue(cell)) {
    if (isDeviationAttributeTypeString(cell.columnType))
      return <DataWithShortenedText text={cell.value as string} maxTextLength={maxCellTextLength(columnCount)} />;
    if (isDeviationAttributeTypeDate(cell.columnType)) return <CustomFormattedDate value={cell.value as string} />;
    if (isDeviationAttributeTypeAmount(cell.columnType)) return <EuroAmount value={parseFloat(cell.value as string)} />;
    if (isDeviationAttributeTypePercent(cell.columnType)) return <Percent value={parseFloat(cell.value as string) / 100} />;
    if (isDeviationAttributeTypeNumber(cell.columnType)) return <FormattedNumber value={parseFloat(cell.value as string)} />;
    console.warn(`DeviationAttributeType unknown: type: ${cell.columnType}, header: ${cell.header}, cell: ${cell}, cell.value: ${cell.value}`);
    return <div style={{ color: 'red' }}>FEHLER - Bitte wende sich an unser IT-Team!</div>;
  }
  return <>{renderCellValue(cell.value)}</>;
};

const renderCellValue = (value: string | number | TDeviationReference[]) => {
  if (Array.isArray(value)) {
    return value.map((item, index) => <React.Fragment key={index}>{item.text}</React.Fragment>);
  }
  return value;
};

const maxCellTextLength = (columnCount: number) => {
  if (columnCount > 8) {
    return 10;
  } else if (columnCount > 5) {
    return 15;
  }
  return DEFAULT_MAX_CELL_TEXT_LENGTH;
};

const DEFAULT_MAX_CELL_TEXT_LENGTH = 25;

export default DeviationFormattedCellValueBasedOnType;
