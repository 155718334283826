import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UmsatzsteuerkennzeichenTemplateListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UmsatzsteuerkennzeichenTemplateListQuery = {
  getUmsatzsteuerkennzeichenTemplateList: {
    data: Array<{
      bezeichnung: string;
      createTs: string;
      createdBy?: string | null;
      createdByMitarbeiterId?: string | null;
      kuerzel: string;
      umsatzsteuerkennzeichenId: string;
      status: { description?: string | null; text: string; value: Types.UmsatzsteuerkennzeichenStatus };
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const UmsatzsteuerkennzeichenTemplateListDocument = gql`
  query UmsatzsteuerkennzeichenTemplateList {
    getUmsatzsteuerkennzeichenTemplateList {
      data {
        bezeichnung
        createTs
        createdBy
        createdByMitarbeiterId
        kuerzel
        status {
          description
          text
          value
        }
        umsatzsteuerkennzeichenId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUmsatzsteuerkennzeichenTemplateListQuery(
  baseOptions?: Apollo.QueryHookOptions<UmsatzsteuerkennzeichenTemplateListQuery, UmsatzsteuerkennzeichenTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UmsatzsteuerkennzeichenTemplateListQuery, UmsatzsteuerkennzeichenTemplateListQueryVariables>(
    UmsatzsteuerkennzeichenTemplateListDocument,
    options
  );
}
export function useUmsatzsteuerkennzeichenTemplateListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UmsatzsteuerkennzeichenTemplateListQuery, UmsatzsteuerkennzeichenTemplateListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UmsatzsteuerkennzeichenTemplateListQuery, UmsatzsteuerkennzeichenTemplateListQueryVariables>(
    UmsatzsteuerkennzeichenTemplateListDocument,
    options
  );
}
export function useUmsatzsteuerkennzeichenTemplateListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UmsatzsteuerkennzeichenTemplateListQuery, UmsatzsteuerkennzeichenTemplateListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UmsatzsteuerkennzeichenTemplateListQuery, UmsatzsteuerkennzeichenTemplateListQueryVariables>(
    UmsatzsteuerkennzeichenTemplateListDocument,
    options
  );
}
export type UmsatzsteuerkennzeichenTemplateListQueryHookResult = ReturnType<typeof useUmsatzsteuerkennzeichenTemplateListQuery>;
export type UmsatzsteuerkennzeichenTemplateListLazyQueryHookResult = ReturnType<typeof useUmsatzsteuerkennzeichenTemplateListLazyQuery>;
export type UmsatzsteuerkennzeichenTemplateListSuspenseQueryHookResult = ReturnType<typeof useUmsatzsteuerkennzeichenTemplateListSuspenseQuery>;
export type UmsatzsteuerkennzeichenTemplateListQueryResult = Apollo.QueryResult<
  UmsatzsteuerkennzeichenTemplateListQuery,
  UmsatzsteuerkennzeichenTemplateListQueryVariables
>;
