import { Font } from '@react-pdf/renderer';
// @ts-ignore
import logo from '../templates/assets/images/andromeda-logo.png';

/*
 * Importing an asset like image or font has te be done in a platform specific way that's why the content of assetHelper is different in
 * frontend and docgen projects. The signature of the functions have to be the same in order to call these from the platform independent
 * templates code without knowing anything about the underlying platform.
 */

export const registerFonts = () => {
  Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        fontWeight: 'normal',
      },
      {
        src: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        fontWeight: 'bold',
      },
      {
        src: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        fontWeight: 'normal',
        fontStyle: 'italic',
      },
    ],
  });
};

export const loadLogo = () => logo;
