import React, { FC, useState } from 'react';
import { Typography } from 'antd';
import { FibuBelegSymbol, FibuBuchungszeile } from '../../../../../types';
import BuchungszeileTable from './Table/BuchungszeileTable';
import BuchungszeileDetailsCardsBlock from '../../shared/BuchungszeileListBlock/KontoDetailsCards/BuchungszeileDetailsCardsBlock';
import { Spacer } from '../../../../../components/Grid';
import { hasBookingStornoBuchungszeileListEntries } from '../../shared/bookingHelpers';
import { useToggle } from '../../../../../hooks/useToggle';
import { useQueryParams } from '../../../../../hooks/useStringQueryParams';
import { BookingDetailsWithBookingSuggestionPageQueryParams } from '../../../bookingDetailsPaths';
import { filterForStorno } from '../../WithoutBookingSuggestion/BuchungszeileListBlock/Listing/Filters/filterHelpers';
import BzShowStornoSwitch from '../../WithoutBookingSuggestion/BuchungszeileListBlock/Listing/Filters/BzShowStornoSwitch';

type Props = {
  belegSymbol: FibuBelegSymbol;
  buchungszeileList: FibuBuchungszeile[];
};

const BuchungszeileListBlock: FC<Props> = ({ belegSymbol, buchungszeileList }) => {
  const { buchungIdList } = useQueryParams<BookingDetailsWithBookingSuggestionPageQueryParams>();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const hasOnlyOneBuchung = buchungIdList!.length === 1;

  const [selectedRow, setSelectedRow] = useState<FibuBuchungszeile>();
  const [showStorno, setShowStorno] = useToggle(hasOnlyOneBuchung);
  const hasStorno = hasBookingStornoBuchungszeileListEntries(buchungszeileList, belegSymbol);

  const filteredBuchungszeileList = buchungszeileList.filter((bz) => filterForStorno(bz, hasOnlyOneBuchung, showStorno));

  return (
    <>
      <Typography.Title level={5}>Buchung</Typography.Title>
      <Spacer height={16} />
      {!hasOnlyOneBuchung && hasStorno && <BzShowStornoSwitch setShowStorno={setShowStorno} showStorno={showStorno} />}
      <BuchungszeileTable buchungszeileList={filteredBuchungszeileList} selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
      {selectedRow && <BuchungszeileDetailsCardsBlock selectedRow={selectedRow} />}
    </>
  );
};

export default BuchungszeileListBlock;
