import React, { FC } from 'react';
import CountryCodeName from './CountryCodeName';
import AddressZipCodeCity from './AddressZipCodeCity';
import AddressCityAdditionalInformation from './AddressCityAdditionalInformation';
import { shouldRenderLandText } from './renderHelper';
import { PostofficeBoxAddressModel } from './AddressSharedModel';

type Props = {
  postofficeBox: PostofficeBoxAddressModel;
};

const PostofficeBoxAddress: FC<Props> = ({ postofficeBox }) => (
  <>
    {`Postfach ${postofficeBox.postofficeBoxNumber}`}
    <br />
    {postofficeBox.cityAdditionalInformation && (
      <>
        <AddressCityAdditionalInformation cityAdditionalInformation={postofficeBox.cityAdditionalInformation} />
        <br />
      </>
    )}
    <AddressZipCodeCity zipCode={postofficeBox.postofficeBoxZipCode} city={postofficeBox.city} />
    {shouldRenderLandText(postofficeBox.countryCodeIso2) && (
      <>
        <br />
        <CountryCodeName countryCodeIso2={postofficeBox.countryCodeIso2} />
      </>
    )}
  </>
);

export default PostofficeBoxAddress;
