import { mapToQueryString, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../../verarbeitungApiPaths';

enum VstKuerzungVerarbeitungQueryParamKey {
  OBJEKT_ID = 'objektId',
  BUCHUNG_STATUS = 'buchungStatus',
}

export type TVstKuerzungVerarbeitungQueryParams = QueryParams<{
  objektId: string;
  buchungStatus: string[];
}>;

export const updateQueryParams = (
  verarbeitungType: string,
  generierlaufId: string,
  navigate: (path: string) => void,
  filters: TVstKuerzungVerarbeitungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => navigate(generatePathToVstKuerzungVerarbeitungPage(verarbeitungType, generierlaufId, filters.objektId, filters.buchungStatus, paginationParams));

const generatePathToVstKuerzungVerarbeitungPage = (
  verarbeitungType: string,
  generierlaufId: string,
  objektId?: string,
  buchungStatus?: string[],
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [VstKuerzungVerarbeitungQueryParamKey.OBJEKT_ID]: objektId,
    [VstKuerzungVerarbeitungQueryParamKey.BUCHUNG_STATUS]: buchungStatus,
    ...paginationParams,
  })}`;
