import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBeOptionSteuerpflichtVersionMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  input: Types.BeOptionSteuerpflichtVersionInput;
}>;

export type CreateBeOptionSteuerpflichtVersionMutation = {
  createBeOptionSteuerpflichtVersion: {
    data: { beOptionSteuerpflichtId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateBeOptionSteuerpflichtVersionMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  bestandseinheitId: Types.Scalars['String']['input'];
  beOptionSteuerpflichtVersionId: Types.Scalars['ID']['input'];
  input: Types.BeOptionSteuerpflichtVersionInput;
}>;

export type UpdateBeOptionSteuerpflichtVersionMutation = {
  updateBeOptionSteuerpflichtVersion: {
    data: { beOptionSteuerpflichtId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteBeOptionSteuerpflichtVersionMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  bestandseinheitId: Types.Scalars['String']['input'];
  beOptionSteuerpflichtVersionId: Types.Scalars['ID']['input'];
}>;

export type DeleteBeOptionSteuerpflichtVersionMutation = {
  deleteBeOptionSteuerpflichtVersion: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export const CreateBeOptionSteuerpflichtVersionDocument = gql`
  mutation CreateBeOptionSteuerpflichtVersion($objektId: String!, $bestandseinheitId: ID!, $input: BeOptionSteuerpflichtVersionInput!) {
    createBeOptionSteuerpflichtVersion(objektId: $objektId, bestandseinheitId: $bestandseinheitId, input: $input) {
      data {
        beOptionSteuerpflichtId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateBeOptionSteuerpflichtVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBeOptionSteuerpflichtVersionMutation, CreateBeOptionSteuerpflichtVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBeOptionSteuerpflichtVersionMutation, CreateBeOptionSteuerpflichtVersionMutationVariables>(
    CreateBeOptionSteuerpflichtVersionDocument,
    options
  );
}
export type CreateBeOptionSteuerpflichtVersionMutationHookResult = ReturnType<typeof useCreateBeOptionSteuerpflichtVersionMutation>;
export type CreateBeOptionSteuerpflichtVersionMutationResult = Apollo.MutationResult<CreateBeOptionSteuerpflichtVersionMutation>;
export type CreateBeOptionSteuerpflichtVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateBeOptionSteuerpflichtVersionMutation,
  CreateBeOptionSteuerpflichtVersionMutationVariables
>;
export const UpdateBeOptionSteuerpflichtVersionDocument = gql`
  mutation UpdateBeOptionSteuerpflichtVersion(
    $objektId: String!
    $bestandseinheitId: String!
    $beOptionSteuerpflichtVersionId: ID!
    $input: BeOptionSteuerpflichtVersionInput!
  ) {
    updateBeOptionSteuerpflichtVersion(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      beOptionSteuerpflichtVersionId: $beOptionSteuerpflichtVersionId
      input: $input
    ) {
      data {
        beOptionSteuerpflichtId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateBeOptionSteuerpflichtVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBeOptionSteuerpflichtVersionMutation, UpdateBeOptionSteuerpflichtVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBeOptionSteuerpflichtVersionMutation, UpdateBeOptionSteuerpflichtVersionMutationVariables>(
    UpdateBeOptionSteuerpflichtVersionDocument,
    options
  );
}
export type UpdateBeOptionSteuerpflichtVersionMutationHookResult = ReturnType<typeof useUpdateBeOptionSteuerpflichtVersionMutation>;
export type UpdateBeOptionSteuerpflichtVersionMutationResult = Apollo.MutationResult<UpdateBeOptionSteuerpflichtVersionMutation>;
export type UpdateBeOptionSteuerpflichtVersionMutationOptions = Apollo.BaseMutationOptions<
  UpdateBeOptionSteuerpflichtVersionMutation,
  UpdateBeOptionSteuerpflichtVersionMutationVariables
>;
export const DeleteBeOptionSteuerpflichtVersionDocument = gql`
  mutation DeleteBeOptionSteuerpflichtVersion($objektId: String!, $bestandseinheitId: String!, $beOptionSteuerpflichtVersionId: ID!) {
    deleteBeOptionSteuerpflichtVersion(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      beOptionSteuerpflichtVersionId: $beOptionSteuerpflichtVersionId
    ) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteBeOptionSteuerpflichtVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBeOptionSteuerpflichtVersionMutation, DeleteBeOptionSteuerpflichtVersionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBeOptionSteuerpflichtVersionMutation, DeleteBeOptionSteuerpflichtVersionMutationVariables>(
    DeleteBeOptionSteuerpflichtVersionDocument,
    options
  );
}
export type DeleteBeOptionSteuerpflichtVersionMutationHookResult = ReturnType<typeof useDeleteBeOptionSteuerpflichtVersionMutation>;
export type DeleteBeOptionSteuerpflichtVersionMutationResult = Apollo.MutationResult<DeleteBeOptionSteuerpflichtVersionMutation>;
export type DeleteBeOptionSteuerpflichtVersionMutationOptions = Apollo.BaseMutationOptions<
  DeleteBeOptionSteuerpflichtVersionMutation,
  DeleteBeOptionSteuerpflichtVersionMutationVariables
>;
