import React, { FC } from 'react';
import { LabeledValue } from '../../../shared/types';
import LabelValue from '../../../shared/LabelValue';

const Saldovortrag: FC<{ data: LabeledValue }> = ({ data }) => (
  <LabelValue
    valueTuple={data}
    viewStyle={{ paddingTop: '3mm' }}
    labelStyle={{ fontWeight: 'bold', textDecoration: 'underline' }}
    valueStyle={{ fontWeight: 'bold' }}
  />
);

export default Saldovortrag;
