import React, { FC } from 'react';
import { FormItem, FormItemProps } from 'formik-antd';
import styled from 'styled-components';

type FormItemWithoutColonProps = Omit<FormItemProps, 'htmlFor' | 'colon'>;

const FormItemWithoutColonForBuchungszeile: FC<FormItemWithoutColonProps> = ({ children, ...restProps }) => (
  <StyledFormItem hasFeedback={false} {...restProps} htmlFor={restProps.name} colon={false}>
    {children}
  </StyledFormItem>
);

export const StyledFormItem = styled(FormItem)`
  &:not(.ant-form-item-has-error) {
    margin-bottom: 0 !important;
  }
`;

export default FormItemWithoutColonForBuchungszeile;
