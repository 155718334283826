import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { DatePicker, Form, Input, Select } from 'formik-antd';
import { Col, Divider, Row, Skeleton, Space, Typography } from 'antd';
import FormButtons from '../../../../components/Button/FormButtons';
import { showSuccessMsgUpdate } from '../../../../components/message';
import ObjektAddressSelect from '../../shared/ObjektAddressSelect';
import { bestandseinheitUpdateFormValidationSchema } from '../../shared/bestandseinheitFormValidationSchema';
import { DATE_DISPLAY_FORMAT_DEFAULT } from '../../../../components/DatePicker/DatePicker_formikAntD';
import { Address as AdresseModel, Bestandseinheit } from '../../../../types';
import { useUpdateBestandseinheitMutation } from '../../gql/BestandseinheitMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import {
  bestandseinheitUpdateFormFields,
  BestandseinheitUpdateFormValues,
  mapBestandseinheitToFormValues,
  mapFormValuesToBestandseinheitUpdate,
} from './bestandseinheitUpdateFormMapper';
import { useObjektQuery } from '../../../Objekt/gql/ObjektQueries.types';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';

type BestandseinheitUpdateFormProps = {
  objektId: string;
  onSubmit: () => void;
  onCancel: () => void;
  bestandseinheit: Bestandseinheit;
};

const BestandseinheitUpdateForm: FC<BestandseinheitUpdateFormProps> = ({ objektId, bestandseinheit, onSubmit, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'Bestandseinheit'>('Bestandseinheit');

  const { data: maybeObjekt, loading: loadingObjekt, refetch } = useObjektQuery({ variables: { objektId } });
  const objekt = maybeObjekt?.getObjekt.data;

  const entity = `Bestandseinheit`;

  const [runUpdateBestandseinheit, { loading: isUpdateBestandseinheitLoading }] = useUpdateBestandseinheitMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSubmit();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });
  const [selectedAddress, setSelectedAddress] = useState<string>(bestandseinheit.address.addressId);

  return (
    <Skeleton active paragraph loading={loadingObjekt}>
      {objekt ? (
        <Formik<BestandseinheitUpdateFormValues>
          initialValues={mapBestandseinheitToFormValues(selectedAddress, bestandseinheit)}
          validationSchema={bestandseinheitUpdateFormValidationSchema}
          onSubmit={(values, formikHelpers) => {
            const { bestandseinheitId } = bestandseinheit;
            runUpdateBestandseinheit({
              variables: { objektId, bestandseinheitId, bestandseinheit: mapFormValuesToBestandseinheitUpdate(values) },
            }).finally(() => formikHelpers.setSubmitting(false));
          }}
        >
          {(formikProps) => (
            <Form layout="vertical">
              <Row align="top">
                <Col span={6}>
                  <Typography.Title level={5}>Adresse</Typography.Title>
                </Col>
                <Col span={18}>
                  <FormItemWithFieldHelp
                    name={bestandseinheitUpdateFormFields.addressId}
                    fieldHelp={getFieldHelpText('Bestandseinheit.addressId')}
                    label="Adresse"
                  >
                    <ObjektAddressSelect
                      name={bestandseinheitUpdateFormFields.addressId}
                      objektId={objektId}
                      addressList={objekt.addressList as AdresseModel[]}
                      onNewItemCreated={(addressId) => {
                        setSelectedAddress(addressId);
                        formikProps.setFieldValue(bestandseinheitUpdateFormFields.addressId, addressId);
                        refetch();
                      }}
                      onNewItemCreateSelected={() => formikProps.setFieldValue(bestandseinheitUpdateFormFields.addressId, '')}
                    />
                  </FormItemWithFieldHelp>
                </Col>
              </Row>
              <Divider />
              <Row align="top">
                <Col span={6}>
                  <Typography.Title level={5}>Bestandseinheit</Typography.Title>
                </Col>
                <Col span={18}>
                  <Row align="top" gutter={8}>
                    <Col span={bestandseinheit ? 12 : 24}>
                      <FormItemWithFieldHelp
                        name={bestandseinheitUpdateFormFields.aktivSeit}
                        label="Aktiv ab (optional)"
                        fieldHelp={getFieldHelpText('Bestandseinheit.aktivSeit')}
                      >
                        <DatePicker
                          size="small"
                          id={bestandseinheitUpdateFormFields.aktivSeit}
                          name={bestandseinheitUpdateFormFields.aktivSeit}
                          style={{ width: '100%' }}
                          format={DATE_DISPLAY_FORMAT_DEFAULT}
                        />
                      </FormItemWithFieldHelp>
                    </Col>
                    <Col span={12}>
                      <FormItemWithFieldHelp
                        name={bestandseinheitUpdateFormFields.inaktivSeit}
                        label="Inaktiv ab (optional)"
                        fieldHelp={getFieldHelpText('Bestandseinheit.inaktivSeit')}
                      >
                        <DatePicker
                          size="small"
                          id={bestandseinheitUpdateFormFields.inaktivSeit}
                          name={bestandseinheitUpdateFormFields.inaktivSeit}
                          style={{ width: '100%' }}
                          format={DATE_DISPLAY_FORMAT_DEFAULT}
                        />
                      </FormItemWithFieldHelp>
                    </Col>
                  </Row>
                  <FormItemWithFieldHelp
                    name={bestandseinheitUpdateFormFields.bezeichnung}
                    label="Bezeichnung"
                    fieldHelp={getFieldHelpText('Bestandseinheit.bezeichnung')}
                  >
                    <Input
                      size="small"
                      id={bestandseinheitUpdateFormFields.bezeichnung}
                      name={bestandseinheitUpdateFormFields.bezeichnung}
                      placeholder="z.B. Top 1"
                    />
                  </FormItemWithFieldHelp>
                  <Space>
                    <FormItemWithFieldHelp
                      name={bestandseinheitUpdateFormFields.geschoss}
                      label="Geschoss (optional)"
                      fieldHelp={getFieldHelpText('Bestandseinheit.geschoss')}
                    >
                      <Input
                        size="small"
                        id={bestandseinheitUpdateFormFields.geschoss}
                        name={bestandseinheitUpdateFormFields.geschoss}
                        placeholder="z.B. 3"
                      />
                    </FormItemWithFieldHelp>
                    <FormItemWithFieldHelp
                      name={bestandseinheitUpdateFormFields.stiege}
                      label="Stiege (optional)"
                      fieldHelp={getFieldHelpText('Bestandseinheit.stiege')}
                    >
                      <Input
                        size="small"
                        id={bestandseinheitUpdateFormFields.stiege}
                        name={bestandseinheitUpdateFormFields.stiege}
                        placeholder="z.B. 2"
                      />
                    </FormItemWithFieldHelp>
                    <FormItemWithFieldHelp
                      name={bestandseinheitUpdateFormFields.bauteil}
                      label="Bauteil"
                      fieldHelp={getFieldHelpText('Bestandseinheit.bauteil')}
                    >
                      <Input
                        size="small"
                        id={bestandseinheitUpdateFormFields.bauteil}
                        name={bestandseinheitUpdateFormFields.bauteil}
                        placeholder="z.B. 2B"
                      />
                    </FormItemWithFieldHelp>
                  </Space>
                  <Row align="top">
                    <Col span={24}>
                      <FormItemWithFieldHelp
                        label="Merkmale"
                        name={bestandseinheitUpdateFormFields.tagList}
                        fieldHelp={getFieldHelpText('Bestandseinheit.tagList')}
                      >
                        <Select
                          id={bestandseinheitUpdateFormFields.tagList}
                          name={bestandseinheitUpdateFormFields.tagList}
                          mode="tags"
                          placeholder="z.B. WE, BK"
                        />
                      </FormItemWithFieldHelp>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <FormButtons
                isOkLoading={isUpdateBestandseinheitLoading}
                updateMode={!!bestandseinheit}
                isOkDisabled={formikProps.isSubmitting}
                onCancel={onCancel}
              />
            </Form>
          )}
        </Formik>
      ) : null}
    </Skeleton>
  );
};

export default BestandseinheitUpdateForm;
