import { Mitarbeiter, MitarbeiterUpdateInput } from '../../../../types';

export interface MitarbeiterUpdateFormValues {
  salutation?: string | null;
  title?: string;
  firstname: string;
  lastname: string;
  titleTrailing?: string;
}

export const mapMitarbeiterToFormValues = (mitarbeiter: Mitarbeiter): MitarbeiterUpdateFormValues => ({
  salutation: mitarbeiter.salutation?.value,
  title: mitarbeiter.title || '',
  firstname: mitarbeiter.firstname,
  lastname: mitarbeiter.lastname,
  titleTrailing: mitarbeiter.titleTrailing || '',
});

export const mapFormValuesToMitarbeiterUpdate = (formValues: MitarbeiterUpdateFormValues): MitarbeiterUpdateInput => ({
  salutation: formValues.salutation,
  title: formValues.title,
  firstname: formValues.firstname,
  lastname: formValues.lastname,
  titleTrailing: formValues.titleTrailing,
});
