import * as Yup from 'yup';
import { entityIsRequired, entityMaxCharLength } from '../../../../../../components/message/validationMsg';
import { parkplatzCreateFormFields } from '../Create/parkplatzCreateFormMapper';

/* eslint-disable no-template-curly-in-string */
export const parkplatzValidationSchema = Yup.object().shape({
  [parkplatzCreateFormFields.nummer]: Yup.string().required(entityIsRequired('Nummer')).max(10, entityMaxCharLength('Nummer', 10)),
  [parkplatzCreateFormFields.text]: Yup.string().max(50, entityMaxCharLength('Text', 50)),
});
/* eslint-enable no-template-curly-in-string */
