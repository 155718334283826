import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../styles/pdfStyles';
import { Zahlungsrueckstaende } from '../../hauseigentuemerAbrechnung/hauseigentuemerAbrechnung-types';
import { columnWidthsAndAlignment } from './zr-table-styles';

const ZrLineList: FC<{ data: Zahlungsrueckstaende[] }> = ({ data }) => (
  <View style={[pdfStyles.column, pdfStyles.borderBottomSmall, { marginTop: '2mm' }]}>
    {data.map((zrLine, index) => (
      <View style={pdfStyles.row} key={index}>
        <Text
          style={[
            pdfStyles.textNormal,
            { width: columnWidthsAndAlignment.bezeichnung.width, textAlign: columnWidthsAndAlignment.bezeichnung.textAlign },
          ]}
        >
          {zrLine.bezeichnung}
        </Text>
        <Text
          style={[pdfStyles.textNormal, { width: columnWidthsAndAlignment.topNummer.width, textAlign: columnWidthsAndAlignment.topNummer.textAlign }]}
        >
          {zrLine.topNummer}
        </Text>
        <Text
          style={[
            pdfStyles.textNormal,
            { width: columnWidthsAndAlignment.kontoNummer.width, textAlign: columnWidthsAndAlignment.kontoNummer.textAlign },
          ]}
        >
          {zrLine.kontoNummer}
        </Text>
        <Text
          style={[
            pdfStyles.textNormal,
            { width: columnWidthsAndAlignment.letzteBuchungDate.width, textAlign: columnWidthsAndAlignment.letzteBuchungDate.textAlign },
          ]}
        >
          {zrLine.letzteBuchungDate}
        </Text>
        <Text
          style={[pdfStyles.textNormal, { width: columnWidthsAndAlignment.dueDate.width, textAlign: columnWidthsAndAlignment.dueDate.textAlign }]}
        >
          {zrLine.dueDate}
        </Text>
        <Text style={[pdfStyles.textNormal, { width: columnWidthsAndAlignment.saldo.width, textAlign: columnWidthsAndAlignment.saldo.textAlign }]}>
          {zrLine.saldo}
        </Text>
      </View>
    ))}
  </View>
);

export default ZrLineList;
