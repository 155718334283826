import { useAsync } from 'react-async';
import { processAndReturnError } from '../../helpers/APIUtils';
import { uriZinslisteExcelExport, uriZinslistePdfExport } from './zinslisteApiPaths';
import Fetcher from '../../helpers/fetcher';
import { useFDAuthorized } from '../../shared/Auth/useAuthorized';

export const useExportZinslisteAsExcel = (
  objektId: string,
  zinslisteId: string,
  onSuccess: (response: any) => void,
  onError: (error: any) => void
) => {
  const { activeForFirmendatenId } = useFDAuthorized();
  return useAsync({
    deferFn: exportZinslisteAsExcel,
    zinslisteId,
    firmendatenId: activeForFirmendatenId,
    objektId,
    onResolve: onSuccess,
    onReject: onError,
  });
};

const exportZinslisteAsExcel = async (_args: any, props: any) => {
  try {
    const res = await Fetcher.getInstance().fetch(uriZinslisteExcelExport(props.firmendatenId, props.objektId, props.zinslisteId));
    return await res;
  } catch (error: any) {
    return await processAndReturnError(error);
  }
};

export const runExportZinslisteAsPdf = async (props: any, abortController: AbortController) => {
  // Obtain a reference to the AbortSignal
  const signal = abortController.signal;
  try {
    const res = await Fetcher.getInstance().fetch(uriZinslistePdfExport(props.firmendatenId, props.objektId, props.zinslisteId), { signal });
    return await res;
  } catch (error: any) {
    return await processAndReturnError(error);
  }
};
