import React from 'react';
import { Route } from 'react-router';
import { URI_ANDROMEDA_SYS_SETTINGS_KUNDEN_SYSTEM_DETAILS_PAGE } from '../../../../constants/andromedaSystemSettingsUriPaths';
import AuthRoute from '../../../../security/AuthRoute';
import { isAdmin } from '../../../../security/permissionChecks';
import VerwaltungKundenSystemDetailsPage from './index';

export type VerwaltungKundenSystemDetailsPageRouteParams = { kundenSystemId: string };

/* TODO: permissions: admin can check details of all ks. but ks-admin should be able to check only her own ks. right now ks-admin
 * could go with direct uri to the details of an other ks and she'd get some permission error.
 * Maybe we should render for ks only Route with the whole uri with ks-id for her kundensystem not a general route with a variable kundensystemId
 */

const andormedaSysSettingsKundenSystemDetailsPageRoute = (
  <Route
    path={URI_ANDROMEDA_SYS_SETTINGS_KUNDEN_SYSTEM_DETAILS_PAGE}
    element={<AuthRoute hasPermissionFn={isAdmin} element={<VerwaltungKundenSystemDetailsPage />} />}
  />
);

export default andormedaSysSettingsKundenSystemDetailsPageRoute;
