import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetNotificationSubscriptionVariables = Types.Exact<{
  userId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  mitarbeiterId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type GetNotificationSubscription = {
  getNotification: {
    createTs: string;
    createdBy: string;
    createdByMitarbeiterId?: string | null;
    notificationCategory: Types.NotificationCategory;
    notificationId: string;
    notificationType: Types.NotificationType;
    status: Types.NotificationStatus;
    actionList: Array<{ body?: any | null; link: string; method: string; typeOperation: Types.NotificationTypeOperation }>;
    notificationInfo: { titleType: Types.NotificationTitleType; parameterList: Array<{ key: string; value: string }> };
    progress: { processed: number; total: number };
  };
};

export const GetNotificationDocument = gql`
  subscription getNotification($userId: String, $mitarbeiterId: String) {
    getNotification(userId: $userId, mitarbeiterId: $mitarbeiterId) {
      actionList {
        body
        link
        method
        typeOperation
      }
      createTs
      createdBy
      createdByMitarbeiterId
      notificationCategory
      notificationId
      notificationInfo {
        parameterList {
          key
          value
        }
        titleType
      }
      notificationType
      progress {
        processed
        total
      }
      status
    }
  }
`;
export function useGetNotificationSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<GetNotificationSubscription, GetNotificationSubscriptionVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<GetNotificationSubscription, GetNotificationSubscriptionVariables>(GetNotificationDocument, options);
}
export type GetNotificationSubscriptionHookResult = ReturnType<typeof useGetNotificationSubscription>;
export type GetNotificationSubscriptionResult = Apollo.SubscriptionResult<GetNotificationSubscription>;
