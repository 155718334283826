import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { dataChangedSubscriptionTypes, useOnDataChangedEvent } from '../../../shared/dataChangedSubscription';
import { DataChangedChangeType } from '../../../types';

const useOnRechtstraegerBankDetailsDataChangeEvent = (key: string, callback: () => Promise<unknown>, rechtstraegerId: string) => {
  const {
    user,
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();

  useOnDataChangedEvent([
    {
      filter: {
        userId: user.username,
        type: dataChangedSubscriptionTypes.rechtstraegerBankDetails,
        params: {
          kundenSystemId,
          rechtstraegerId,
        },
        changeType: DataChangedChangeType.Update,
      },
      notificationDescription: `Bankverbindung wurde gerade verändert`,
      key: `${key}-update`,
      callback,
    },
  ]);
};

export { useOnRechtstraegerBankDetailsDataChangeEvent };
