import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useVerwaltungsartListQuery } from '../gql/VerwaltungsartQueries.types';
import { selectFilterOption } from '../../../helpers/selectHelper';

const ObjektVerwaltungsArtSelect: FC<SelectProps> = ({ name, ...props }) => {
  const { loading, data } = useVerwaltungsartListQuery();
  const verwaltungsArtList = data?.getVerwaltungsartList.data ?? [];

  return (
    <Select id={name} name={name} loading={loading} placeholder="Verwaltungsart auswählen" showSearch filterOption={selectFilterOption} {...props}>
      {verwaltungsArtList.map((verwaltungsArt) => (
        <Select.Option key={verwaltungsArt.value} value={verwaltungsArt.value}>
          {verwaltungsArt.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ObjektVerwaltungsArtSelect;
