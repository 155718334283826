import React from 'react';
import { Space } from 'antd';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';
import DataWithShortenedText from '../../../../../components/Helpers/DataWithShortenedText';
import { generatePathToKontoDetailsPage } from '../../../../Kontenplan/kontoUriPaths';
import { RepFondsEinstellungKontenZuweisung } from '../../../../../types';
import { repFondsKontenZuweisungDescriptionsForKommentar } from '../../../../ReparaturFonds/KontenZuweisung/Version/Form/repFondsKontenZuweisungVersionFormMapper';
import { generatePathToKontoTemplateDetailsPage } from '../../../../Kontenrahmen/kontoTemplateUriPaths';
import { generatePathToAufteilunsschluesselTemplatePage } from '../../../../AufteilungsschluesselTemplate/aufteilungsschluesselTemplateUriPaths';

const repFondsTemplateKontenZuweisungTableColumns = (isInCardContent?: boolean): ColumnProps<RepFondsEinstellungKontenZuweisung>[] => [
  {
    title: repFondsKontenZuweisungDescriptionsForKommentar.aufwandsKontoIdList,
    render: (text, record) => (
      <Space direction="vertical" size={2}>
        {record.aufwandsKontoList.map((konto) => {
          const title = `${konto.nummer} - ${konto.bezeichnung}`;
          return isInCardContent ? (
            <DataWithShortenedText text={title} maxTextLength={40} key={konto.kontoId}>
              {(shortenedText) => <Link to={generatePathToKontoTemplateDetailsPage(konto.kontoId)}>{shortenedText}</Link>}
            </DataWithShortenedText>
          ) : (
            <Link to={generatePathToKontoTemplateDetailsPage(konto.kontoId)}>{title}</Link>
          );
        })}
      </Space>
    ),
  },
  {
    title: repFondsKontenZuweisungDescriptionsForKommentar.aufteilungsschluesselId,
    width: isInCardContent ? 220 : undefined,
    render: (text, record) => (
      <Link
        to={generatePathToAufteilunsschluesselTemplatePage(record.aufteilungsschluessel.aufteilungsschluesselId, record.aufteilungsschluessel.status)}
      >
        {record.aufteilungsschluessel.bezeichnung}
      </Link>
    ),
  },
  {
    title: repFondsKontenZuweisungDescriptionsForKommentar.repFondsKontoId,
    width: isInCardContent ? 290 : undefined,
    render: (text, record) => {
      const title = `${record.repFondsKonto.nummer} - ${record.repFondsKonto.bezeichnung}`;
      return isInCardContent ? (
        <DataWithShortenedText text={title} maxTextLength={30}>
          {(shortenedText) => <Link to={generatePathToKontoDetailsPage(record.repFondsKonto.kontoId)}>{shortenedText}</Link>}
        </DataWithShortenedText>
      ) : (
        <Link to={generatePathToKontoTemplateDetailsPage(record.repFondsKonto.kontoId)}>{title}</Link>
      );
    },
  },
];

export default repFondsTemplateKontenZuweisungTableColumns;
