import { generatePath } from 'react-router-dom';
import { mapToQueryString, QueryParams } from '../../../../hooks/useStringQueryParams';
import { PaginationQueryParamsProps } from '../../../../hooks/usePaginationQueryParams';
import { URI_PAYMENT_PAGE } from '../../paymentUriPaths';
import { PaymentListingFiltersFormValues } from './paymentListingFiltersFormMapper';

enum PaymentQueryParamKey {
  AMOUNT_FROM = 'amountFrom',
  AMOUNT_TO = 'amountTo',
  APPROVED_BY_MITARBEITER_ID_LIST = 'approvedByMitarbeiterIdList',
  BELEG_NUMMER = 'belegnummer',
  BUCHUNGSDATUM_BIS = 'buchungsdatumBis',
  BUCHUNGSDATUM_VON = 'buchungsdatumVon',
  DATA_CARRIER_ID_LIST = 'dataCarrierIdList',
  DUE_DATE_FROM = 'dueDateFrom',
  DUE_DATE_TO = 'dueDateTo',
  EMPLOYEE_APPROVAL = 'employeeApproval',
  ERSTELLER_MITARBEITER_ID_LIST = 'erstellerMitarbeiterIdList',
  EXCLUDE_LIST = 'excludeList',
  INVOICE_DATE_FROM = 'invoiceDateFrom',
  INVOICE_DATE_TO = 'invoiceDateTo',
  NOTED = 'noted',
  OBJEKT_ID_LIST = 'objektIdList',
  PAYEE_IBAN = 'payeeIban',
  PAYEE_ID = 'payeeId',
  PAYMENT_APPROVAL_STATUS_LIST = 'paymentApprovalStatusList',
  PAYMENT_DATE_FROM = 'paymentDateFrom',
  PAYMENT_DATE_TO = 'paymentDateTo',
  PAYMENT_PROPOSAL_ID = 'paymentProposalId',
  PAYMENT_REFERENCE_TEXT = 'paymentReferenceText',
  PAYMENT_TRANSACTION_TYPE = 'paymentTransactionType',
  PAYMENT_TYPE_LIST = 'paymentTypeList',
  PURPOSE_OF_USE_TEXT = 'purposeOfUseText',
  RECHNUNGS_AUSSTELLER_FIBU_BANK_ACCOUNT_ID = 'rechnungsAusstellerFibuKontoId',
  RECHNUNGS_AUSSTELLER_IBAN = 'rechnungsAusstellerIban',
  RECHNUNGS_AUSSTELLER_ID = 'rechnungsAusstellerId',
  SEPA_CREDITOR_ID = 'sepaCreditorId',
  SEPA_MANDATE_REFERENCE = 'sepaMandateReference',
  STATUS_LIST = 'statusList',
}

// TODO: EC-14054 - Adding excludeList into query params should be handled most probably within the Nested Table - get and set the Exclude list, then update the query params

export type TPaymentQueryParams = QueryParams<PaymentListingFiltersFormValues>;

export const updateQueryParams = (navigate: (path: string) => void, filters: TPaymentQueryParams, paginationParams?: PaginationQueryParamsProps) =>
  navigate(generatePathToPaymentPage(filters, paginationParams));

export const generatePathToPaymentPage = (filters: TPaymentQueryParams, paginationParams?: PaginationQueryParamsProps | null) =>
  `${generatePath(URI_PAYMENT_PAGE)}?${mapToQueryString({
    [PaymentQueryParamKey.AMOUNT_FROM]: filters?.amountFrom,
    [PaymentQueryParamKey.AMOUNT_TO]: filters?.amountTo,
    [PaymentQueryParamKey.APPROVED_BY_MITARBEITER_ID_LIST]: filters?.approvedByMitarbeiterIdList,
    [PaymentQueryParamKey.BELEG_NUMMER]: filters?.belegnummer,
    [PaymentQueryParamKey.BUCHUNGSDATUM_BIS]: filters?.buchungsdatumBis,
    [PaymentQueryParamKey.BUCHUNGSDATUM_VON]: filters?.buchungsdatumVon,
    [PaymentQueryParamKey.DATA_CARRIER_ID_LIST]: filters?.dataCarrierIdList,
    [PaymentQueryParamKey.DUE_DATE_FROM]: filters?.dueDateFrom,
    [PaymentQueryParamKey.DUE_DATE_TO]: filters?.dueDateTo,
    [PaymentQueryParamKey.EMPLOYEE_APPROVAL]: filters?.employeeApproval,
    [PaymentQueryParamKey.ERSTELLER_MITARBEITER_ID_LIST]: filters?.erstellerMitarbeiterIdList,
    [PaymentQueryParamKey.INVOICE_DATE_FROM]: filters?.invoiceDateFrom,
    [PaymentQueryParamKey.INVOICE_DATE_TO]: filters?.invoiceDateTo,
    [PaymentQueryParamKey.NOTED]: filters?.noted,
    [PaymentQueryParamKey.OBJEKT_ID_LIST]: filters?.objektIdList,
    [PaymentQueryParamKey.PAYEE_IBAN]: filters?.payeeIban,
    [PaymentQueryParamKey.PAYEE_ID]: filters?.payeeId,
    [PaymentQueryParamKey.PAYMENT_APPROVAL_STATUS_LIST]: filters?.paymentApprovalStatusList,
    [PaymentQueryParamKey.PAYMENT_DATE_FROM]: filters?.paymentDateFrom,
    [PaymentQueryParamKey.PAYMENT_DATE_TO]: filters?.paymentDateTo,
    [PaymentQueryParamKey.PAYMENT_PROPOSAL_ID]: filters?.paymentProposalId,
    [PaymentQueryParamKey.PAYMENT_REFERENCE_TEXT]: filters?.paymentReferenceText,
    [PaymentQueryParamKey.PAYMENT_TRANSACTION_TYPE]: filters?.paymentTransactionType,
    [PaymentQueryParamKey.PAYMENT_TYPE_LIST]: filters?.paymentTypeList,
    [PaymentQueryParamKey.PURPOSE_OF_USE_TEXT]: filters?.purposeOfUseText,
    [PaymentQueryParamKey.RECHNUNGS_AUSSTELLER_FIBU_BANK_ACCOUNT_ID]: filters?.rechnungsAusstellerFibuKontoId,
    [PaymentQueryParamKey.RECHNUNGS_AUSSTELLER_IBAN]: filters?.rechnungsAusstellerIban,
    [PaymentQueryParamKey.RECHNUNGS_AUSSTELLER_ID]: filters?.rechnungsAusstellerId,
    [PaymentQueryParamKey.SEPA_CREDITOR_ID]: filters?.sepaCreditorId,
    [PaymentQueryParamKey.SEPA_MANDATE_REFERENCE]: filters?.sepaMandateReference,
    [PaymentQueryParamKey.STATUS_LIST]: filters?.statusList,
    ...paginationParams,
  })}`;
