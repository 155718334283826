import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { BeOptionSteuerpflichtVersionFragmentDoc } from './BeOptionSteuerpflichtFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BeOptionSteuerpflichtVersionListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
}>;

export type BeOptionSteuerpflichtVersionListQuery = {
  getBeOptionSteuerpflichtVersionList: {
    data: Array<{
      beOptionSteuerpflichtId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      kommentar?: string | null;
      lastUpdateTs: string;
      optionSteuerpflicht: boolean;
      validFrom: string;
      historicizedList?: Array<{
        beOptionSteuerpflichtId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        optionSteuerpflicht: boolean;
        validFrom: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BeOptionSteuerpflichtVersionQueryVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  bestandseinheitId: Types.Scalars['String']['input'];
  beOptionSteuerpflichtVersionId: Types.Scalars['ID']['input'];
}>;

export type BeOptionSteuerpflichtVersionQuery = {
  getBeOptionSteuerpflichtVersion: {
    data: {
      beOptionSteuerpflichtId: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      current: boolean;
      deletable?: boolean | null;
      kommentar?: string | null;
      lastUpdateTs: string;
      optionSteuerpflicht: boolean;
      validFrom: string;
      historicizedList?: Array<{
        beOptionSteuerpflichtId: string;
        createTs: string;
        createdBy: string;
        createdByMitarbeiterId?: string | null;
        current: boolean;
        deletable?: boolean | null;
        kommentar?: string | null;
        lastUpdateTs: string;
        optionSteuerpflicht: boolean;
        validFrom: string;
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      }> | null;
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const BeOptionSteuerpflichtVersionListDocument = gql`
  query BeOptionSteuerpflichtVersionList($objektId: String!, $bestandseinheitId: ID!) {
    getBeOptionSteuerpflichtVersionList(objektId: $objektId, bestandseinheitId: $bestandseinheitId) {
      data {
        ...BeOptionSteuerpflichtVersion
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BeOptionSteuerpflichtVersionFragmentDoc}
`;
export function useBeOptionSteuerpflichtVersionListQuery(
  baseOptions: Apollo.QueryHookOptions<BeOptionSteuerpflichtVersionListQuery, BeOptionSteuerpflichtVersionListQueryVariables> &
    ({ variables: BeOptionSteuerpflichtVersionListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BeOptionSteuerpflichtVersionListQuery, BeOptionSteuerpflichtVersionListQueryVariables>(
    BeOptionSteuerpflichtVersionListDocument,
    options
  );
}
export function useBeOptionSteuerpflichtVersionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BeOptionSteuerpflichtVersionListQuery, BeOptionSteuerpflichtVersionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BeOptionSteuerpflichtVersionListQuery, BeOptionSteuerpflichtVersionListQueryVariables>(
    BeOptionSteuerpflichtVersionListDocument,
    options
  );
}
export function useBeOptionSteuerpflichtVersionListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BeOptionSteuerpflichtVersionListQuery, BeOptionSteuerpflichtVersionListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BeOptionSteuerpflichtVersionListQuery, BeOptionSteuerpflichtVersionListQueryVariables>(
    BeOptionSteuerpflichtVersionListDocument,
    options
  );
}
export type BeOptionSteuerpflichtVersionListQueryHookResult = ReturnType<typeof useBeOptionSteuerpflichtVersionListQuery>;
export type BeOptionSteuerpflichtVersionListLazyQueryHookResult = ReturnType<typeof useBeOptionSteuerpflichtVersionListLazyQuery>;
export type BeOptionSteuerpflichtVersionListSuspenseQueryHookResult = ReturnType<typeof useBeOptionSteuerpflichtVersionListSuspenseQuery>;
export type BeOptionSteuerpflichtVersionListQueryResult = Apollo.QueryResult<
  BeOptionSteuerpflichtVersionListQuery,
  BeOptionSteuerpflichtVersionListQueryVariables
>;
export const BeOptionSteuerpflichtVersionDocument = gql`
  query BeOptionSteuerpflichtVersion($objektId: String!, $bestandseinheitId: String!, $beOptionSteuerpflichtVersionId: ID!) {
    getBeOptionSteuerpflichtVersion(
      objektId: $objektId
      bestandseinheitId: $bestandseinheitId
      beOptionSteuerpflichtVersionId: $beOptionSteuerpflichtVersionId
    ) {
      data {
        ...BeOptionSteuerpflichtVersion
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${BeOptionSteuerpflichtVersionFragmentDoc}
`;
export function useBeOptionSteuerpflichtVersionQuery(
  baseOptions: Apollo.QueryHookOptions<BeOptionSteuerpflichtVersionQuery, BeOptionSteuerpflichtVersionQueryVariables> &
    ({ variables: BeOptionSteuerpflichtVersionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BeOptionSteuerpflichtVersionQuery, BeOptionSteuerpflichtVersionQueryVariables>(
    BeOptionSteuerpflichtVersionDocument,
    options
  );
}
export function useBeOptionSteuerpflichtVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BeOptionSteuerpflichtVersionQuery, BeOptionSteuerpflichtVersionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BeOptionSteuerpflichtVersionQuery, BeOptionSteuerpflichtVersionQueryVariables>(
    BeOptionSteuerpflichtVersionDocument,
    options
  );
}
export function useBeOptionSteuerpflichtVersionSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BeOptionSteuerpflichtVersionQuery, BeOptionSteuerpflichtVersionQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BeOptionSteuerpflichtVersionQuery, BeOptionSteuerpflichtVersionQueryVariables>(
    BeOptionSteuerpflichtVersionDocument,
    options
  );
}
export type BeOptionSteuerpflichtVersionQueryHookResult = ReturnType<typeof useBeOptionSteuerpflichtVersionQuery>;
export type BeOptionSteuerpflichtVersionLazyQueryHookResult = ReturnType<typeof useBeOptionSteuerpflichtVersionLazyQuery>;
export type BeOptionSteuerpflichtVersionSuspenseQueryHookResult = ReturnType<typeof useBeOptionSteuerpflichtVersionSuspenseQuery>;
export type BeOptionSteuerpflichtVersionQueryResult = Apollo.QueryResult<
  BeOptionSteuerpflichtVersionQuery,
  BeOptionSteuerpflichtVersionQueryVariables
>;
