import { FormikProps } from 'formik';
import {
  AuszifferungBlockFormValues,
  AuszifferungFormValues,
  mapAuszifferungToOffenePostenFormValues,
  OffenePostenForBooking,
} from '../../../../auszifferungFormMapper';
import {
  getAuszifferungListFormFieldNameFromMainOPListBasedOnIndex,
  getAuszifferungListFormFieldNameFromPersonenkontoOPListBasedOnIndex,
  getOffenePostenIndexFromMainOPList,
  getOffenePostenIndexFromPersonenkontoOPList,
} from '../../../../auszifferungFormHelpers';
import { AuszifferungWithOffenePosten } from '../../../../../../../../../types';

export const updateAuszifferungAsStorniert = (
  auszifferungAfterStorno: AuszifferungWithOffenePosten,
  formikProps: FormikProps<AuszifferungBlockFormValues>,
  offenePosten: OffenePostenForBooking
) => {
  // 0. map auszifferungAfterStorno to OffenePostenForBooking type
  const newAuszifferungListData = mapAuszifferungToOffenePostenFormValues(auszifferungAfterStorno).auszifferungList;

  // 1. for Booking: find offenePosten index in formikProps.values.offenePostenListFromBooking
  // 2. for Personenkonto: update auszifferung list (in formik props) for this offene posten with storno data
  const opIndexInBookingOPList = getOffenePostenIndexFromMainOPList(formikProps.values, offenePosten);
  updateAuszifferungListInFormikProps(
    newAuszifferungListData,
    getAuszifferungListFormFieldNameFromMainOPListBasedOnIndex(opIndexInBookingOPList),
    formikProps
  );

  // 3. for Personenkonto: find offenePosten index in formik.values.offenePostenForPersonenkontoList
  // 4. for Personenkonto: update auszifferung list (in formik props) for this offene posten with storno data
  const opIndexInPersonenkontoOPList = getOffenePostenIndexFromPersonenkontoOPList(formikProps.values, offenePosten);

  if (opIndexInPersonenkontoOPList !== -1) {
    updateAuszifferungListInFormikProps(
      newAuszifferungListData,
      getAuszifferungListFormFieldNameFromPersonenkontoOPListBasedOnIndex(opIndexInPersonenkontoOPList),
      formikProps
    );
  }
};

const updateAuszifferungListInFormikProps = (
  newAuszifferungListData: AuszifferungFormValues[],
  fieldName: string,
  formikProps: FormikProps<AuszifferungBlockFormValues>
) => {
  formikProps.setFieldValue(fieldName, newAuszifferungListData);
};
