import { dayjsCustom } from '../../../../../../helpers/dayjsCustom';
import { getNextAvailableDateFromToday } from '../../../../../../helpers/dateHelper';
import { sortVersionsDescendingByValidFrom } from '../../../../../../features/Abrechnungsdefinition/shared/abrDefHelpers';
import {
  AbrechnungsdefinitionVersion,
  AbrechnungsdefinitionVersionListData,
} from '../../../../../../features/Abrechnungsdefinition/shared/abrDefTypes';

export const findPrecedingVersionId = (versionList: AbrechnungsdefinitionVersionListData[]) => {
  const validFromList = versionList.map((version) => dayjsCustom(version.validFrom));
  const nextAvailableDateFromToday = getNextAvailableDateFromToday(validFromList);
  const precedingVersion = sortVersionsDescendingByValidFrom(versionList).find((version) =>
    dayjsCustom(version.validFrom).isBefore(nextAvailableDateFromToday)
  );
  return precedingVersion?.abrechnungsdefinitionVersionId;
};

export const isPrecedingVersionAvailable = (versionList?: AbrechnungsdefinitionVersionListData[], precedingVersion?: AbrechnungsdefinitionVersion) =>
  !versionList || (hasPrecedingVersion(versionList) && !!precedingVersion);

export const hasPrecedingVersion = (versionList?: AbrechnungsdefinitionVersionListData[]) => {
  return versionList && !!findPrecedingVersionId(versionList);
};

export const getFirmendatenId = (versionList: AbrechnungsdefinitionVersionListData[]) => versionList[0]?.firmendatenId ?? '';
