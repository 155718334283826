import React from 'react';
import { FireOutlined, HomeOutlined } from '@ant-design/icons';
import { GroupedMenueListInterface } from '../../../../components/ContextSidebar/ContentWithSidebar';
import { generatePathToVerarbeitungMahnungAusgebenDetailsPage } from '../../../../features/Verarbeitung/verarbeitungApiPaths';
import VerarbeitungMahnungAusgebenVersendenVerbuchen from '../../../../features/Verarbeitung/Mahnung/AusgebenVersendenVerbuchen/VerarbeitungMahnungAusgebenVersendenVerbuchen';
import { verarbeitungPageAndMenuListTitles, verarbeitungTypes } from '../../../../features/Verarbeitung/verarbeitungHelpers';
import VerarbeitungErrorAndWarningListing from '../../../../features/Verarbeitung/shared/ErrorAndWarningListing/VerarbeitungErrorAndWarningListing';
import { FibuGenerierlaufType, MahnungAusgebenGenerierlauf } from '../../../../types';

const menuListMahnungAusgeben = (generierlauf: MahnungAusgebenGenerierlauf): GroupedMenueListInterface => {
  return {
    topMenuList: [
      {
        title: verarbeitungPageAndMenuListTitles.MahnungAusgabe,
        icon: <HomeOutlined />,
        uri: `${generatePathToVerarbeitungMahnungAusgebenDetailsPage(generierlauf.generierlaufId)}/entries`,
        content: () => (
          <VerarbeitungMahnungAusgebenVersendenVerbuchen
            generierlauf={generierlauf}
            verarbeitungType={verarbeitungTypes.MahnungAusgeben}
            generierlaufType={FibuGenerierlaufType.MahnungAusgabe}
          />
        ),
      },
      {
        state: generierlauf.errorList.length > 0 || generierlauf.warningList.length > 0 ? 'error' : undefined,
        title: verarbeitungPageAndMenuListTitles.ErrorsAndWarnings,
        icon: <FireOutlined />,
        uri: `${generatePathToVerarbeitungMahnungAusgebenDetailsPage(generierlauf.generierlaufId)}/errors-and-warnings`,
        content: () => <VerarbeitungErrorAndWarningListing errorList={generierlauf.errorList} warningList={generierlauf.warningList} />,
      },
    ],
  };
};

export default menuListMahnungAusgeben;
