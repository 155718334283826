import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../../styles/pdfStyles';
import FPAufwandSumExpectedCostBlock from './FPAufwandSumExpectedCostBlock';
import FPRepFondsExpensesTable from './FPRepFondsExpensesTable';
import FPAufwandKontoTable from './FPAufwandKontoTable';
import FPCostSumBlock from './FPCostSum';
import FPCurrentAndNewVPosWertBlock from './FPCurrentAndNewVPosWertBlock';
import FPRepFondsExtraBlock from './FPRepFondsExtraBlock';
import { FinancialPlanSelectableSection } from '../../Top/FinancialPlanTopTemplate';
import { isNotNil } from '../../../helpers/assertionHelper';
import { FPVorschreibungsposition } from '../financialPlan-types';
import Einleitungstext from '../../../shared/Einleitungstext';
import Schlusstext from '../../../shared/Schlusstext';

type Props = {
  vorschreibungspositionList: FPVorschreibungsposition[];
  isInEditMode?: boolean;
  selectedTextbaustein?: FinancialPlanSelectableSection;
  isNotFullWidth?: boolean;
};

const FPVPosList: FC<Props> = ({ vorschreibungspositionList, isInEditMode, selectedTextbaustein, isNotFullWidth }) => {
  const width = isNotFullWidth ? '85%' : '100%';
  return (
    <View>
      {vorschreibungspositionList.map((vpos, index) => (
        <View key={index}>
          {/* Titeltext */}
          <Text style={[pdfStyles.textNormal, { marginTop: '5mm', fontWeight: 'bold' }]}>{vpos.bezeichnung}</Text>

          {/* Einleitungstext */}
          <Einleitungstext text={vpos.einleitungsText} isInEditMode={isInEditMode} selectedTextbaustein={selectedTextbaustein} />

          {/* Summe Aufwand und voraussichtliche Kosten */}
          {isNotNil(vpos.aufwandSumExpectedCostBlock) && (
            <FPAufwandSumExpectedCostBlock aufwandSumExpectedCostBlock={vpos.aufwandSumExpectedCostBlock} width={width} />
          )}

          {/* Ausgabentabelle */}
          {vpos.repFondsExpensesTable && <FPRepFondsExpensesTable expenses={vpos.repFondsExpensesTable} width={width} />}

          {/* Aufwandskontentabelle */}
          {vpos.aufwandskontenTable && <FPAufwandKontoTable aufwandskonten={vpos.aufwandskontenTable} width={isNotFullWidth ? '70%' : '80%'} />}

          {/* Voraussichtliche Kosten */}
          {vpos.expectedCostSum && <FPCostSumBlock expectedCostSum={vpos.expectedCostSum} width={width} />}

          {vpos.repFondsExtra && <FPRepFondsExtraBlock repFondsExtra={vpos.repFondsExtra} width={width} />}

          <FPCurrentAndNewVPosWertBlock vpos={vpos} />

          {/* Schlusstext */}
          <Schlusstext text={vpos.schlussText} />
        </View>
      ))}
    </View>
  );
};

export default FPVPosList;
