import React, { FC } from 'react';
import { Modal } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import { Form } from 'formik-antd';
import FormButtons from '../../../../components/Button/FormButtons';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { showSuccessMsgCreate } from '../../../../components/message';
import { AbrechnungType } from '../../../../types';
import { getAbrTypeName } from '../../shared/abrechnungHelpers';
import { useCreateFirmendatenTopAbrechnungGenerierlaufMutation } from '../../gql/AbrechnungGenerierlauf/AbrechnungGenerierlaufMutations.types';
import {
  firmendatenTopAbrechnungFormFields,
  firmendatenTopAbrechnungFormInitialValues,
  FirmendatenTopAbrechnungFormValues,
  mapFormValuesToFirmendatenTopAbrechnungCreateInput,
} from './topAbrechnungCreateFormMapper';
import { firmendatenTopAbrechnungCreateValidationSchema } from './topAbrechnungValidationSchema';
import ObjektSelectWithAllTag from '../../../../shared/ObjektSelect/ObjektSelectWithAllTag';
import FirmendatenObjAbrSelectWithAllTag from '../../../Firmendaten/FirmendatenObjAbrSelectWithAllTag';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../components/Form/FormItemWithFieldHelp';
import MonthPicker from '../../../../components/DatePicker/MonthPicker/MonthPicker';

type Props = {
  type: AbrechnungType;
  onCreate: () => void;
  onCancel: () => void;
  visible: boolean;
};

const TopAbrechnungCreateModal: FC<Props> = ({ type, onCreate, onCancel, visible }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'TopAbrechnungGenerierlauf'>('TopAbrechnungGenerierlauf');

  const [runCreate, { loading }] = useCreateFirmendatenTopAbrechnungGenerierlaufMutation({
    onCompleted: () => {
      const entity = 'Abrechnung';
      showSuccessMsgCreate(entity);
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const handleCreate = (values: FirmendatenTopAbrechnungFormValues, actions: FormikHelpers<FirmendatenTopAbrechnungFormValues>) => {
    const input = mapFormValuesToFirmendatenTopAbrechnungCreateInput(type, values);
    runCreate({ variables: { input } })
      .then(onCreate)
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <Modal title={`${getAbrTypeName(type)} erstellen`} open={visible} footer={null} onCancel={onCancel} destroyOnClose maskClosable={false}>
      <Formik<FirmendatenTopAbrechnungFormValues>
        initialValues={firmendatenTopAbrechnungFormInitialValues}
        validationSchema={firmendatenTopAbrechnungCreateValidationSchema}
        onSubmit={handleCreate}
      >
        {(formikProps) => (
          <Form layout="vertical">
            <FormItemWithFieldHelp
              name={firmendatenTopAbrechnungFormFields.objektIdList}
              label="Objekt"
              fieldHelp={getFieldHelpText('TopAbrechnungGenerierlauf.objektIdList')}
            >
              <ObjektSelectWithAllTag name={firmendatenTopAbrechnungFormFields.objektIdList} formikProps={formikProps} abrechnungType={type} />
            </FormItemWithFieldHelp>
            <FormItemWithFieldHelp
              name={firmendatenTopAbrechnungFormFields.objektAbrechnungIdList}
              label="Objektabrechnung"
              fieldHelp={getFieldHelpText('TopAbrechnungGenerierlauf.objektAbrechnungIdList')}
            >
              <FirmendatenObjAbrSelectWithAllTag
                name={firmendatenTopAbrechnungFormFields.objektAbrechnungIdList}
                formikProps={formikProps}
                objektIdList={formikProps.values.objektIdList}
                abrechnungTypeList={[type]}
              />
            </FormItemWithFieldHelp>
            <FormItemWithFieldHelp
              name={firmendatenTopAbrechnungFormFields.fakturierungsperiode}
              label="Fakturierungperiode"
              fieldHelp={getFieldHelpText('TopAbrechnungGenerierlauf.fakturierungsperiode.jahr')}
            >
              <MonthPicker placeholder="Periode auswählen" name={firmendatenTopAbrechnungFormFields.fakturierungsperiode} style={{ width: '100%' }} />
            </FormItemWithFieldHelp>
            <FormButtons updateMode={false} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} isOkLoading={loading} />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default TopAbrechnungCreateModal;
