import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import FormButtons from '../../../components/Button/FormButtons';
import {
  mapFormValuesToTextbaustein,
  mapTextbausteinToFormValues,
  textbausteinFormFields,
  textbausteinFormInitialValues,
  TextbausteinFormValues,
  textbausteinInitialValues,
} from './textbausteinFormMapper';
import { textbausteinValidationSchema } from './textbausteinValidationSchema';
import TextbausteinInputTextArea from '../../../components/Template/PDFTemplates/TextbausteinList/TextbausteinListElements/TextbausteinInputTextArea';
import { TextbausteinType } from '../../../types';
import { updateTextbausteinValuesWithType } from '../../../components/Template/PDFTemplates/templateHelpers';
// FIXME: EC-9440 - resolve naming conflict between Textbaustein and general Textbaustein
import {
  mapTextbausteinToFormValues as mapTextbausteinDefinitionToFormValues,
  TextbausteinFormValues as TextbausteinDefinitonFormValues,
} from '../../../components/Template/PDFTemplates/templateMapper';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../components/message';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import { Spacer } from '../../../components/Grid';
import AuftragsartListTransfer from './Transfer/AuftragsartListTransfer';
import { useTextbausteinTemplatesLazyQuery } from '../gql/TextbausteinQueries.types';
import TextbausteinTypeSelect from './TextbausteinTypeSelect';
import { useCreateTextbausteinMutation, useUpdateTextbausteinMutation } from '../gql/TextbausteinMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { GeneralTextbausteinFieldsFragment } from '../gql/TextbausteinFragments.types';

type Props = {
  onSubmit: () => void;
  onCancel: () => void;
  textbaustein?: GeneralTextbausteinFieldsFragment;
};

const TextbausteinForm: FC<Props> = ({ onSubmit, onCancel, textbaustein }) => {
  const entity = 'Text';
  const isUpdate = !!textbaustein;

  const [textbausteinTemplateValues, setTextbausteinTemplateValues] = useState<TextbausteinDefinitonFormValues>(() =>
    mapTextbausteinDefinitionToFormValues(textbaustein ? textbaustein.textbaustein : textbausteinInitialValues)
  );

  const [runCreate] = useCreateTextbausteinMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSubmit();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUpdate] = useUpdateTextbausteinMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSubmit();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runTextbausteinTemplatesQuery] = useTextbausteinTemplatesLazyQuery();

  const onTypeChange = (textbausteinType: TextbausteinType) => {
    runTextbausteinTemplatesQuery({
      variables: {
        textbausteinType,
      },
    }).then((res) => {
      const textbausteinTemplate = res.data?.getTextbausteinTemplates.data;
      textbausteinTemplate && setTextbausteinTemplateValues(mapTextbausteinDefinitionToFormValues(textbausteinTemplate));
    });
  };

  const onChangeTextbaustein = (textbausteinType: TextbausteinType, newValue: string) => {
    setTextbausteinTemplateValues(updateTextbausteinValuesWithType(textbausteinTemplateValues, textbausteinType, newValue));
  };

  return (
    <Formik<TextbausteinFormValues>
      initialValues={isUpdate ? mapTextbausteinToFormValues(textbaustein) : textbausteinFormInitialValues}
      validationSchema={textbausteinValidationSchema}
      onSubmit={(values) => {
        const input = mapFormValuesToTextbaustein(values, textbausteinTemplateValues);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        isUpdate ? runUpdate({ variables: { textbausteinId: textbaustein!.textbausteinId, input } }) : runCreate({ variables: { input } });
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithoutColon name={textbausteinFormFields.bezeichnung} label="Bezeichnung">
            <Input id={textbausteinFormFields.bezeichnung} name={textbausteinFormFields.bezeichnung} placeholder="Bezeichnung" prefix={<span />} />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={textbausteinFormFields.type} label="Typ">
            <TextbausteinTypeSelect
              name={textbausteinFormFields.type}
              onChange={(value) => {
                formikProps.setFieldValue(textbausteinFormFields.auftragsartIdList, []);
                onTypeChange(value);
              }}
            />
          </FormItemWithoutColon>
          <FormItemWithoutColon name={textbausteinFormFields.textbaustein} label="Text">
            <TextbausteinInputTextArea
              value={textbausteinTemplateValues.value}
              onChange={(newValue) => {
                formikProps.setFieldValue(textbausteinFormFields.textbaustein, newValue?.trim());
                onChangeTextbaustein(textbausteinTemplateValues.type.value, newValue);
              }}
              platzhalterList={textbausteinTemplateValues.platzhalterList}
            />
          </FormItemWithoutColon>
          <Spacer height={16} />
          <FormItemWithoutColon name={textbausteinFormFields.auftragsartIdList} label="Auftragsarten-Zuweisung">
            <AuftragsartListTransfer formikProps={formikProps} />
          </FormItemWithoutColon>
          <FormButtons onCancel={onCancel} updateMode={isUpdate} />
        </Form>
      )}
    </Formik>
  );
};

export default TextbausteinForm;
