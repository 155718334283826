import { Dayjs } from 'dayjs';
import { FormFields } from '../../../../../../../../../helpers/formikHelper';
import {
  ValuePreservationType,
  VertragVposIndexationAgreementReferenceValue,
  VertragVposIndexationAgreementReferenceValueCreateInput,
  VertragVposIndexationAgreementReferenceValueUpdateInput,
} from '../../../../../../../../../types';
import { mapDateFormValueToMonthYear, mapMonthYearToDateFormat } from '../indexationAgreementHelpers';

export interface IndexationAgreementReferenceValueFormValues {
  indexOld?: number | null;
  indexSeriesId: string;
  monthYearOld: Dayjs | string;
  type: ValuePreservationType;
  validFrom: Dayjs | string;
}

export const indAgreeReferenceValueFormFields: FormFields<IndexationAgreementReferenceValueFormValues> = {
  indexOld: 'indexOld',
  indexSeriesId: 'indexSeriesId',
  monthYearOld: 'monthYearOld',
  type: 'type',
  validFrom: 'validFrom',
};

export const referenceValueFormInitialValues: IndexationAgreementReferenceValueFormValues = {
  indexOld: null,
  indexSeriesId: '',
  monthYearOld: '',
  type: ValuePreservationType.ReferenceValue,
  validFrom: '',
};

export const mapReferenceValueToFormValues = (
  referenceValue?: VertragVposIndexationAgreementReferenceValue
): IndexationAgreementReferenceValueFormValues => {
  if (!referenceValue) return referenceValueFormInitialValues;
  return {
    indexOld: referenceValue.indexOld,
    indexSeriesId: referenceValue.indexSeries?.indexSeriesId,
    monthYearOld: mapMonthYearToDateFormat(referenceValue.monthYearOld),
    type: ValuePreservationType.ReferenceValue,
    validFrom: mapMonthYearToDateFormat(referenceValue.validFrom),
  };
};

export const mapFormValuesToReferenceValue = (
  formValues: IndexationAgreementReferenceValueFormValues
): VertragVposIndexationAgreementReferenceValueCreateInput | VertragVposIndexationAgreementReferenceValueUpdateInput => ({
  indexOld: formValues.indexOld,
  indexSeriesId: formValues.indexSeriesId,
  monthYearOld: mapDateFormValueToMonthYear(formValues.monthYearOld),
  type: ValuePreservationType.ReferenceValue,
  validFrom: mapDateFormValueToMonthYear(formValues.validFrom),
});
