import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { useAssignReceivedBankTransactionMutation } from '../../../gql/ReceivedBankTransactionMutations.types';
import { showSuccessMsgOther } from '../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { OrderPaymentStatus, ReceivedBankTransactionListEntry } from '../../../../../types';
import FormFilters from './Filters/FormFilters';
import { FiltersFormValues, mapReceivedBankTransactionToFormValues } from './paymentAssignmentFormMapper';
import FormButtons from '../../../../../components/Button/FormButtons';
import PaymentAssignmentTable from './Table/PaymentAssignmentTable';

type Props = {
  onAction: () => void;
  onCancel: () => void;
  receivedBankTransaction: ReceivedBankTransactionListEntry;
};

export const ALLOWED_STATUS_LIST_FOR_PAYMENT_ASSIGMENT = [
  OrderPaymentStatus.AnBankGesendet,
  OrderPaymentStatus.AnBankGesendetAbgebrochen,
  OrderPaymentStatus.AnBankGesendetFehler,
  OrderPaymentStatus.AnBankGesendetInArbeit,
  OrderPaymentStatus.AnBankGesendetUnterschriftenFehlen,
  OrderPaymentStatus.AnBankGesendetUnterschriftenVollstaendig,
  OrderPaymentStatus.DatentraegerErzeugt,
  OrderPaymentStatus.Durchgefuehrt,
  OrderPaymentStatus.DurchgefuehrtRueckgebucht,
  OrderPaymentStatus.DurchgefuehrtRueckgebuchtVerbucht,
  OrderPaymentStatus.DurchgefuehrtVerbucht,
];

const PaymentAssignmentForm: FC<Props> = ({ onAction, onCancel, receivedBankTransaction }) => {
  const [runAssign, { loading }] = useAssignReceivedBankTransactionMutation({
    onCompleted: () => {
      showSuccessMsgOther('Zahlungszuweisung wurde erfolgreich hinzugefüght');
      onAction();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [paymentId, setPaymentId] = useState<string>();

  return (
    <Formik<FiltersFormValues>
      initialValues={mapReceivedBankTransactionToFormValues(receivedBankTransaction)}
      onSubmit={(_, { setSubmitting }) => {
        if (paymentId) {
          runAssign({
            variables: { receivedBankTransactionId: receivedBankTransaction.receivedBankTransactionId, paymentId },
          }).finally(() => setSubmitting(false));
        } else {
          setSubmitting(false);
        }
      }}
    >
      {(formikProps) => (
        <>
          <FormFilters formikProps={formikProps} />
          <PaymentAssignmentTable formikProps={formikProps} setPaymentId={setPaymentId} />
          <FormButtons onCancel={onCancel} updateMode={false} isOkDisabled={!paymentId} isOkLoading={loading} onOk={formikProps.submitForm} />
        </>
      )}
    </Formik>
  );
};

export default PaymentAssignmentForm;
