import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { selectFilterOption } from '../../../../helpers/selectHelper';
import { useFirmendatenFileInfoBestandseinheitListQuery } from '../../gql/FirmendatenFileInfoFilterQueries.types';
import DataWithShortenedText from '../../../../components/Helpers/DataWithShortenedText';

type Props = { objektId?: string } & SelectProps;

const DocumentBestandseinheitListFormSelect: FC<Props> = ({ objektId, ...restProps }) => {
  const { loading, data } = useFirmendatenFileInfoBestandseinheitListQuery({
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      objektId: objektId!,
    },
    skip: !objektId,
  });
  const list = data?.getFirmendatenFileInfoBestandseinheitList.data ?? [];

  return (
    <Select
      {...restProps}
      disabled={!objektId}
      size="small"
      id={restProps.name}
      loading={loading}
      placeholder="Bestandseinheit auswählen"
      allowClear
      showSearch
      filterOption={selectFilterOption}
    >
      {list.map((bestandseinheit) => (
        <Select.Option key={bestandseinheit.bestandseinheitId} value={bestandseinheit.bestandseinheitId}>
          <DataWithShortenedText maxTextLength={35} text={bestandseinheit.bezeichnung} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default DocumentBestandseinheitListFormSelect;
