import React, { FC } from 'react';
import { Button, Dropdown, MenuProps, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, EllipsisOutlined, HistoryOutlined } from '@ant-design/icons';
import { ArchiveIcon } from '@radix-ui/react-icons';
import {
  useArchiveObjektMutation,
  useDeleteObjektFotoMutation,
  useDeleteObjektMutation,
  useUnarchiveObjektMutation,
} from '../../gql/ObjektMutations.types';
import { showSuccessMsgArchived, showSuccessMsgDelete, showSuccessMsgUnarchived } from '../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { useGoBack } from '../../../../shared/GoBack/GoBackContext';
import { uri } from '../../../../constants/appUriPaths';
import { FotoType, Objekt, ObjektStatus } from '../../../../types';
import { radixActionStyles } from '../../../../helpers/radixIconsStyles';

type Props = {
  objekt: Objekt;
  onSuccess: () => void;
  onClickUpdate: () => void;
  onClickChangeHistoryOpen: () => void;
};

const ObjektActions: FC<Props> = ({ objekt, onSuccess, onClickUpdate, onClickChangeHistoryOpen }) => {
  const goBack = useGoBack(uri.dashboard);

  const isArchived = objekt.status.value === ObjektStatus.Archiviert;
  const entityName = `Objekt`;

  const [runArchive] = useArchiveObjektMutation({
    variables: {
      objektId: objekt.objektId,
    },
    onCompleted: () => {
      showSuccessMsgArchived(entityName);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runUnarchive] = useUnarchiveObjektMutation({
    variables: {
      objektId: objekt.objektId,
    },
    onCompleted: () => {
      showSuccessMsgUnarchived(entityName);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runDeleteObjekt] = useDeleteObjektMutation({
    onCompleted: () => {
      showSuccessMsgDelete(entityName);
      goBack();
    },
    variables: {
      objektId: objekt.objektId,
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [runDeleteFoto] = useDeleteObjektFotoMutation({
    onCompleted: () => {
      showSuccessMsgDelete(`Foto`);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const items: MenuProps['items'] = [
    {
      label: 'Objekt',
      key: 'objekt',
      children: [
        { label: 'Bearbeiten', key: 'objekt-item-1', onClick: onClickUpdate, icon: <EditOutlined /> },
        {
          key: 'objekt-item-2',
          label: 'Änderungsverlauf',
          onClick: onClickChangeHistoryOpen,
          icon: <HistoryOutlined style={radixActionStyles} />,
        },
        {
          label: isArchived ? 'Reaktivieren' : 'Archivieren',
          key: 'objekt-item-3',
          onClick: isArchived ? showConfirmUnarchive(objekt, runUnarchive) : showConfirmArchive(objekt, runArchive),
          icon: <ArchiveIcon style={radixActionStyles} />,
          disabled: !!objekt.deletable,
        },
        {
          label: 'Löschen',
          key: 'objekt-item-4',
          onClick: () => showConfirmDeleteObjekt(objekt, runDeleteObjekt),
          icon: <DeleteOutlined />,
          disabled: !objekt.deletable,
        },
      ],
    },
    {
      label: 'Vorschaubild',
      key: 'vorschaubild',
      disabled: !isObjektFotoDeleteAllowed(objekt),
      children: [
        {
          label: 'Löschen',
          key: 'vorschaubild-item-1',
          danger: true,
          onClick: () => showConfirmDeleteFoto(objekt, runDeleteFoto),
          icon: <DeleteOutlined />,
        },
      ],
    },
  ];
  return (
    <Dropdown menu={{ items }}>
      <Button icon={<EllipsisOutlined />} shape="circle" size="small" type="text" />
    </Dropdown>
  );
};

const showConfirmArchive = (objekt: Objekt, runArchive: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie dieses Objekt archivieren?`,
    content: `${objekt.kurzBezeichnung}`,
    okText: 'Archivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runArchive();
    },
  });
};

const showConfirmUnarchive = (objekt: Objekt, runUnarchive: () => void) => () => {
  Modal.confirm({
    title: `Möchten Sie dieses Objekt reaktivieren?`,
    content: `${objekt.kurzBezeichnung}`,
    okText: 'Reaktivieren',
    cancelText: 'Abbrechen',
    onOk() {
      return runUnarchive();
    },
  });
};

const showConfirmDeleteObjekt = (objekt: Objekt, runDelete: () => void) => {
  Modal.confirm({
    title: 'Möchten Sie dieses Objekt löschen?',
    content: `${objekt.kurzBezeichnung}`,
    okButtonProps: { danger: true },
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete();
    },
  });
};

const showConfirmDeleteFoto = (objekt: Objekt, runDelete: (objektId: { variables: { objektId: string } }) => void) => {
  Modal.confirm({
    title: 'Möchten Sie das Foto löschen?',
    content: `${objekt.kurzBezeichnung}`,
    okText: 'Löschen',
    okButtonProps: { danger: true },
    cancelText: 'Abbrechen',
    onOk() {
      return runDelete({
        variables: {
          objektId: objekt.objektId,
        },
      });
    },
  });
};

const isObjektFotoDeleteAllowed = (objektData: Objekt) => objektData.fotoType === FotoType.Uploaded;

export default ObjektActions;
