import { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { usePaymentBelegSymbolListQuery } from './gql/PaymentBelegSymbolQueries.types';
import { selectFilterOption } from '../../../helpers/selectHelper';

type Props = Omit<SelectProps, 'id' | 'placeholder' | 'filterOption'>;

const PaymentBelegSymbolSelect: FC<Props> = ({ name, ...restProps }) => {
  const { data, loading } = usePaymentBelegSymbolListQuery({ variables: { includeStorno: false } });
  const belegSymbolList = data?.getPaymentBelegSymbolList.data ?? [];

  return (
    <Select
      {...restProps}
      id={name}
      name={name}
      loading={loading}
      placeholder="Belegsymbol auswählen"
      filterOption={selectFilterOption}
      style={{ width: '100%' }}
      size="small"
    >
      {belegSymbolList.map((belegSymbol) => (
        <Select.Option key={belegSymbol.value} value={belegSymbol.value}>
          {belegSymbol.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default PaymentBelegSymbolSelect;
