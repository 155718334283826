import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VorsteuerkuerzungApplyAllDeviationsMutationVariables = Types.Exact<{
  objektId: Types.Scalars['String']['input'];
  vorsteuerkuerzungId: Types.Scalars['ID']['input'];
  input?: Types.InputMaybe<Types.VorsteuerkuerzungActionApplyDeviationsInput>;
}>;

export type VorsteuerkuerzungApplyAllDeviationsMutation = {
  actionVorsteuerkuerzungApplyAllDeviations: {
    data: { vorsteuerkuerzungId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const VorsteuerkuerzungApplyAllDeviationsDocument = gql`
  mutation VorsteuerkuerzungApplyAllDeviations($objektId: String!, $vorsteuerkuerzungId: ID!, $input: VorsteuerkuerzungActionApplyDeviationsInput) {
    actionVorsteuerkuerzungApplyAllDeviations(objektId: $objektId, vorsteuerkuerzungId: $vorsteuerkuerzungId, input: $input) {
      data {
        vorsteuerkuerzungId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useVorsteuerkuerzungApplyAllDeviationsMutation(
  baseOptions?: Apollo.MutationHookOptions<VorsteuerkuerzungApplyAllDeviationsMutation, VorsteuerkuerzungApplyAllDeviationsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VorsteuerkuerzungApplyAllDeviationsMutation, VorsteuerkuerzungApplyAllDeviationsMutationVariables>(
    VorsteuerkuerzungApplyAllDeviationsDocument,
    options
  );
}
export type VorsteuerkuerzungApplyAllDeviationsMutationHookResult = ReturnType<typeof useVorsteuerkuerzungApplyAllDeviationsMutation>;
export type VorsteuerkuerzungApplyAllDeviationsMutationResult = Apollo.MutationResult<VorsteuerkuerzungApplyAllDeviationsMutation>;
export type VorsteuerkuerzungApplyAllDeviationsMutationOptions = Apollo.BaseMutationOptions<
  VorsteuerkuerzungApplyAllDeviationsMutation,
  VorsteuerkuerzungApplyAllDeviationsMutationVariables
>;
