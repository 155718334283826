import { FormFields } from '../../../../../../helpers/formikHelper';
import { ContactType } from '../../../../../../shared/components/Contact/contactHelper';

export type PhoneContactCreateFormValues = {
  type: ContactType.PHONE;
  wertList: string[];
};

export const phoneContactCreateFormFields: FormFields<PhoneContactCreateFormValues> = {
  type: 'type',
  wertList: 'wertList',
};

export const phoneContactCreateFormInitialValues: PhoneContactCreateFormValues = {
  type: ContactType.PHONE,
  wertList: [''],
};
