import { Tooltip } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';
import React, { FC } from 'react';

type TemplateIncompleteWarningProps = {
  textBausteineBefuellt: boolean;
};

const TemplateIncompleteWarning: FC<TemplateIncompleteWarningProps> = ({ textBausteineBefuellt }) => {
  return !textBausteineBefuellt ? (
    <Tooltip placement="right" title="Die Templates sind nicht vollständig">
      <WarningTwoTone style={{ cursor: 'pointer' }} />
    </Tooltip>
  ) : null;
};

export default TemplateIncompleteWarning;
