import React from 'react';
import { EditableCellProps } from '../EditableTableOld/EditableTableProps';
import { useEditableTableIntern } from './editableTableContext';
import { EditableCellEditMode } from './EditableCellEditMode';

const EditableCell = <TableRecordType extends Record<string, any> = any>({
  children,
  record,
  editable,
  renderInEditMode,
  ...restProps
}: EditableCellProps<TableRecordType, any>) => {
  const editableTableContextProps = useEditableTableIntern<TableRecordType>();
  let cellContent: React.ReactNode;

  const isCurrentRecordTheSelectedRecord =
    record && !!editableTableContextProps.selectedRecord && record.recordKey === editableTableContextProps.selectedRecord.recordKey;

  if (isCurrentRecordTheSelectedRecord) {
    return (
      <td {...restProps}>
        {/* eslint-disable-next-line react/no-children-prop */}
        <EditableCellEditMode editableTableContextProps={editableTableContextProps} renderInEditMode={renderInEditMode} children={children} />
      </td>
    );
  } else {
    cellContent = <>{children}</>;
  }

  return <td {...restProps}>{cellContent}</td>;
};

export default EditableCell;
