import React from 'react';
import EmailDeliveryDefinitionButtonsAndDrawer from './EmailDeliveryDefinitionButtonsAndDrawer';
import { useEmailDeliveryDefinitionTemplateListQuery } from './gql/EmailDeliveryDefinitionTemplateQueries.types';
import EmailDeliveryDefinitionTemplateTable from './Table/EmailDeliveryDefinitionTemplateTable';

const EmailDeliveryDefinitionTemplateListing = () => {
  const { data, loading, refetch } = useEmailDeliveryDefinitionTemplateListQuery();

  const emailDeliveryDefList = data?.getEmailDeliveryDefinitionTemplateList.data ?? [];

  return (
    <>
      <EmailDeliveryDefinitionButtonsAndDrawer refetch={refetch} />
      <EmailDeliveryDefinitionTemplateTable emailDeliveryDefList={emailDeliveryDefList} loading={loading} onAction={refetch} />
    </>
  );
};

export default EmailDeliveryDefinitionTemplateListing;
