import * as Yup from 'yup';
import {
  firstnameValidationSchema,
  lastnameValidationSchema,
  salutationValidationSchema,
  titleTrailingValidationSchema,
  titleValidationSchema,
} from '../../../../../shared/components/Person/personFormValidationSchema';

export const contactPersonValidationSchema = Yup.object().shape({
  salutation: salutationValidationSchema,
  title: titleValidationSchema,
  firstname: firstnameValidationSchema,
  lastname: lastnameValidationSchema,
  titleTrailing: titleTrailingValidationSchema,
});
