import { createGlobalStyle } from 'styled-components';

export const PopoverStyles = createGlobalStyle`
  .ant-popover {
    &:has(.ant-popover-inner-content:empty) {
      .ant-popover-title {
        border-bottom-color: transparent;
      }
    }

    .ant-popover-inner-content:empty {
      display: none;
    }
  }
`;
