import { KontoKlasse, KontoType, KontoVerwendung } from '../../../../types';
import { FormFields } from '../../../../helpers/formikHelper';
import { TKontenplanQueryParams } from '../../../../pages/SysSettingsPage/KundenSystem/KontenplanPage/routes';

export type KontoListingFiltersFormValues = {
  klasse?: KontoKlasse;
  type?: KontoType[];
  verwendung?: KontoVerwendung;
};

export const kontoListingFiltersFormFieldNames: FormFields<KontoListingFiltersFormValues> = {
  klasse: 'klasse',
  type: 'type',
  verwendung: 'verwendung',
};

export const mapFormValuesToKontoListingFilters = (formValues: KontoListingFiltersFormValues): TKontenplanQueryParams => ({
  klasse: formValues.klasse || undefined,
  type: formValues.type ?? [],
  verwendung: formValues.verwendung || undefined,
});

export const mapQueryParamsToFormValues = (filters: TKontenplanQueryParams): KontoListingFiltersFormValues => ({
  klasse: filters.klasse,
  type: filters.type,
  verwendung: filters.verwendung,
});
