import React, { FC, useEffect } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useField } from 'formik';
import { Verwaltungsart } from '../../types';
import { useVertragsartListQuery } from './gql/VertragsartQueries.types';

type VertragsartSelectProps = { verwaltungsart?: Verwaltungsart } & SelectProps;

const VertragsartSelect: FC<VertragsartSelectProps> = ({ verwaltungsart, ...props }) => {
  const { data, loading } = useVertragsartListQuery({ variables: { verwaltungsart } });

  const [field, meta, helpers] = useField(props.name);
  const vertragsartList = data?.getVertragsartList.data;

  useEffect(() => {
    if (!vertragsartList?.length) return;
    const exists = vertragsartList?.find((vertragsart) => vertragsart.value === field.value);

    if (!exists && field.value) {
      helpers.setValue(null);
    }
  }, [vertragsartList, field, meta, helpers]);

  return (
    <Select size="small" id={props.name} loading={loading} allowClear showSearch placeholder="Vertragsart auswählen" {...props}>
      {vertragsartList?.map((vertragsart) => (
        <Select.Option key={vertragsart.value} value={vertragsart.value}>
          {vertragsart.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default VertragsartSelect;
