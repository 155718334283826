import { processAndReturnError } from '../../../helpers/APIUtils';
import Fetcher from '../../../helpers/fetcher';

export const downloadSepaMandatMusterPdf = async (firmendatenId: string, rechtstraegerId: string, abortController: AbortController) => {
  const signal = abortController.signal;
  try {
    const res = await Fetcher.getInstance().fetch(
      `/api/firmendaten/${firmendatenId}/rechtstraeger/${rechtstraegerId}/bank-details/sepa-mandat-muster-pdf`,
      { signal }
    );
    return await res;
  } catch (error: any) {
    return await processAndReturnError(error);
  }
};
