import { FormFields } from '../../../../../helpers/formikHelper';
import { GenerierlaufEntryExitCode } from '../../../../../types';
import { TTopAbrVerarbeitungQueryParams } from './filtersQueryParams';

export type TopAbrVerarbeitungEntryTableFormValues = {
  rechnungsempfaengerId?: string;
  objektId?: string;
  exitCodeList?: GenerierlaufEntryExitCode[];
  bestandseinheitIdList?: string[];
  rechnungsausstellerId?: string;
};

export const topAbrVerarbeitungEntryTableFormFields: FormFields<TopAbrVerarbeitungEntryTableFormValues> = {
  rechnungsausstellerId: 'rechnungsausstellerId',
  rechnungsempfaengerId: 'rechnungsempfaengerId',
  objektId: 'objektId',
  bestandseinheitIdList: 'bestandseinheitIdList',
  exitCodeList: 'exitCodeList',
};

export const topAbrVerarbEntryListingFiltersFormInitialValues = (
  queryParams: TTopAbrVerarbeitungQueryParams
): TopAbrVerarbeitungEntryTableFormValues => ({
  rechnungsempfaengerId: queryParams.rechnungsempfaengerId,
  objektId: queryParams.objektId,
  exitCodeList: queryParams.exitCodeList,
  bestandseinheitIdList: queryParams.bestandseinheitIdList,
  rechnungsausstellerId: queryParams.rechnungsausstellerId,
});

export const mapFormValuesToTopAbrVerarbeitungFilters = (formValues: TopAbrVerarbeitungEntryTableFormValues): TTopAbrVerarbeitungQueryParams => ({
  rechnungsempfaengerId: formValues.rechnungsempfaengerId,
  objektId: formValues.objektId,
  exitCodeList: formValues.exitCodeList,
  bestandseinheitIdList: formValues.bestandseinheitIdList,
  rechnungsausstellerId: formValues.rechnungsausstellerId,
});
