import React from 'react';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { TicketBase } from '../../../../types';
import { pathsToObjektDetailsPage } from '../../../Objekt/objektUriPaths';
import TextForEmptyValue from '../../../../components/Text/TextForEmptyValue';
import { generatePathToBestandseinheitDetailsPageInfoFeldMenu } from '../../../Bestandseinheit/bestandseinheitUriPaths';
import { generatePathToBeVertragDetailsPageInfoFeldMenu } from '../../../Vertrag/BeVertrag/beVertragUriPaths';
import { pathsToRechtstraegerDetails } from '../../../Rechtstraeger/rechtstraegerHelper';
import LinkWithStatus from '../../../../components/Link/LinkWithStatus';

const ticketTableInfoFeldColumn = (isSelected = false): TableWithColSelectorColumnProps<TicketBase> => ({
  title: 'Infofeld',
  defaultSelected: isSelected,
  render: (text, record) => <>{infoFeldColumnContent(record)}</>,
});

const infoFeldColumnContent = (record: TicketBase): React.ReactNode => {
  if (record.objekt && record.objektInfoFeld) {
    return (
      <LinkWithStatus
        status={record.objektInfoFeld.status}
        text={record.objektInfoFeld.type.text}
        maxTextLength={25}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        to={pathsToObjektDetailsPage(record.objekt!.objektId).infoFeldMenu}
        openInNewTab
      />
    );
  }

  if (record.bestandseinheit && record.beInfoFeld) {
    return (
      <LinkWithStatus
        status={record.beInfoFeld.status}
        text={record.beInfoFeld.type.text}
        maxTextLength={25}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        to={generatePathToBestandseinheitDetailsPageInfoFeldMenu(record.bestandseinheit!.objektId, record.bestandseinheit!.bestandseinheitId)}
        openInNewTab
      />
    );
  }

  if (record.vertrag && record.vertragInfoFeld) {
    return (
      <LinkWithStatus
        status={record.vertragInfoFeld.status}
        text={record.vertragInfoFeld.type.text}
        maxTextLength={25}
        to={generatePathToBeVertragDetailsPageInfoFeldMenu(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          record.vertrag!.objektId,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          record.vertrag!.bestandseinheitId,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          record.vertrag!.vertragId
        )}
        openInNewTab
      />
    );
  }

  if (record.rechtstraeger && record.rechtstraegerInfoFeld) {
    return (
      <LinkWithStatus
        status={record.rechtstraegerInfoFeld.status}
        text={record.rechtstraegerInfoFeld.type.text}
        maxTextLength={25}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        to={pathsToRechtstraegerDetails(record.rechtstraeger!.rechtstraegerId).infoFeldMenu}
        openInNewTab
      />
    );
  }

  return <TextForEmptyValue textToShow="minus" />;
};

export default ticketTableInfoFeldColumn;
