import React, { FC } from 'react';
import CardDetailsInfoRow from '../../../../components/Card/CardDetailsInfoRow';
import RechtstraegerWithStatus from '../../../Rechtstraeger/shared/RechtstraegerWithStatus';
import { OrderRechtstraeger } from '../../../../types';

type Props = {
  text: string;
  rechtstraeger?: OrderRechtstraeger;
};

const PanelHeader: FC<Props> = ({ text, rechtstraeger }) => {
  return (
    <CardDetailsInfoRow infoRowTitle={text}>
      {rechtstraeger && rechtstraeger.kurzBezeichnung ? (
        <RechtstraegerWithStatus rechtstraegerId={rechtstraeger.rechtstraegerId} rechtstraegerKurzBezeichnung={rechtstraeger.kurzBezeichnung} />
      ) : (
        <>-</>
      )}
    </CardDetailsInfoRow>
  );
};

export default PanelHeader;
