import React, { FC } from 'react';
import { Select, SelectProps } from 'formik-antd';
import { useFirmendatenObjektVorschreibungspositionNameListQuery } from '../KundenSystem/Firmendaten/gql/FirmendatenQueries.types';
import { selectFilterOption } from '../../helpers/selectHelper';
import DataWithShortenedText from '../../components/Helpers/DataWithShortenedText';

const ObjektVPosNameSelect: FC<SelectProps> = ({ ...restProps }) => {
  const { data, loading } = useFirmendatenObjektVorschreibungspositionNameListQuery();

  const nameList = data?.getFirmendatenObjektVorschreibungspositionNameList.data ?? [];

  return (
    <Select
      id={restProps.name}
      loading={loading}
      placeholder={restProps.placeholder ?? 'Vorschreibungsposition auswählen'}
      allowClear
      showSearch
      filterOption={selectFilterOption}
      {...restProps}
    >
      {nameList.map((name) => (
        <Select.Option key={name} value={name}>
          <DataWithShortenedText maxTextLength={25} text={name} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default ObjektVPosNameSelect;
