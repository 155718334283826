import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import KSFirmendatenSelect from '../../KSFirmendatenSelect/KSFirmendatenSelect';
import { Spacer } from '../../../components/Grid';
import FormButtons from '../../../components/Button/FormButtons';
import {
  useCreateFdAbrechnungskreisMutation,
  useCreateKsAbrechnungskreisMutation,
  useUpdateAbrechnungskreisMutation,
} from '../gql/AbrechnungskeisMutations.types';
import { showSuccessMsgCreate, showSuccessMsgUpdate } from '../../../components/message';
import { useFDAuthorized } from '../../../shared/Auth/useAuthorized';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../helpers/apolloHelper';
import { abrechnungskreisValidationSchema } from './abrechnungskreisValidationSchema';
import {
  abrechnungskreisFormFields,
  AbrechnungskreisFormValues,
  mapAbrechnungskreisToFormValues,
  mapFormValuesToAbrechnungskreis,
} from './abrechnungskreisFormMapper';
import FormItemWithoutColon from '../../../components/Form/FormItemWithoutColon';
import AbrechnungskreisTypeSelect from './AbrechnungskreisTypeSelect';
import { Abrechnungskreis, AbrechnungskreisType } from '../../../types';
import VerrechnungsUstVomAufwandSelect from './VerrechnungsUstVomAufwandSelect';
import FormItemWithFieldHelp from '../../../components/Form/FormItemWithFieldHelp';
import { useGetAppFieldHelpText } from '../../FieldHelp/useGetFieldHelpText';

type Props = {
  abrechnungskreis?: Abrechnungskreis;
  onSuccess: () => void;
  onCancel: () => void;
};

const AbrechnungskreisForm: FC<Props> = ({ abrechnungskreis, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'Abrechnungskreis'>('Abrechnungskreis');

  const {
    activeForFirmendaten: { kundenSystemId },
  } = useFDAuthorized();
  const initialValues = mapAbrechnungskreisToFormValues(abrechnungskreis);

  const isUpdate = !!abrechnungskreis;
  const entity = 'Abrechungskreis';

  const [createFDAbrechnungskreis, { loading: loadingCreateFD }] = useCreateFdAbrechnungskreisMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [createKSAbrechnungskreis, { loading: loadingCreateKS }] = useCreateKsAbrechnungskreisMutation({
    onCompleted: () => {
      showSuccessMsgCreate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const [updateAbrechnungskreis, { loading: loadingUpdate }] = useUpdateAbrechnungskreisMutation({
    onCompleted: () => {
      showSuccessMsgUpdate(entity);
      onSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <Formik<AbrechnungskreisFormValues>
      initialValues={initialValues}
      validationSchema={abrechnungskreisValidationSchema}
      onSubmit={(formValues, formikHelpers) => {
        const input = mapFormValuesToAbrechnungskreis(formValues);
        if (!abrechnungskreis) {
          if (formValues.firmendatenId) {
            createFDAbrechnungskreis({ variables: { firmendatenId: formValues.firmendatenId, input } }).finally(() =>
              formikHelpers.setSubmitting(false)
            );
          } else {
            createKSAbrechnungskreis({ variables: { kundenSystemId, input } }).finally(() => formikHelpers.setSubmitting(false));
          }
        } else {
          updateAbrechnungskreis({
            variables: { abrechnungskreisId: abrechnungskreis.abrechnungskreisId, input },
          }).finally(() => formikHelpers.setSubmitting(false));
        }
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithoutColon
            name={abrechnungskreisFormFields.firmendatenId}
            label="Firma auswählen"
            help="Abrechnungskreis wird nur für diese Firma angewendet"
          >
            <KSFirmendatenSelect
              name={abrechnungskreisFormFields.firmendatenId}
              id={abrechnungskreisFormFields.firmendatenId}
              disabled={isUpdate}
              placeholder={!isUpdate ? 'Firma' : 'Keine Firma'}
            />
          </FormItemWithoutColon>
          <Spacer height={16} />

          <FormItemWithFieldHelp
            name={abrechnungskreisFormFields.bezeichnung}
            label="Bezeichnung"
            fieldHelp={getFieldHelpText('Abrechnungskreis.bezeichnung')}
          >
            <Input
              id={abrechnungskreisFormFields.bezeichnung}
              name={abrechnungskreisFormFields.bezeichnung}
              placeholder='z.B. Abrechnungskreis "leicht zu merken"'
            />
          </FormItemWithFieldHelp>

          <FormItemWithFieldHelp name={abrechnungskreisFormFields.type} label="Typ" fieldHelp={getFieldHelpText('Abrechnungskreis.type')}>
            <AbrechnungskreisTypeSelect name={abrechnungskreisFormFields.type} disabled={abrechnungskreis && !abrechnungskreis.deletable} />
          </FormItemWithFieldHelp>

          {formikProps.values.type === AbrechnungskreisType.ReparaturFonds && (
            <FormItemWithFieldHelp
              name={abrechnungskreisFormFields.verrechnungUstVomAufwand}
              label="Verrechnung Umsatzsteuer vom Aufwand"
              fieldHelp={getFieldHelpText('Abrechnungskreis.verrechnungUstVomAufwand')}
            >
              <VerrechnungsUstVomAufwandSelect
                name={abrechnungskreisFormFields.verrechnungUstVomAufwand}
                disabled={abrechnungskreis && !abrechnungskreis.deletable}
              />
            </FormItemWithFieldHelp>
          )}

          <FormButtons
            updateMode={isUpdate}
            onCancel={onCancel}
            isOkDisabled={formikProps.isSubmitting}
            isOkLoading={loadingCreateFD || loadingCreateKS || loadingUpdate}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AbrechnungskreisForm;
