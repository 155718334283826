import { FC } from 'react';
import { Typography } from 'antd';
import { verarbeitungPageAndMenuListTitles } from '../../verarbeitungHelpers';
import { Spacer } from '../../../../components/Grid';
import VorschreibungVerbuchenVerarbeitungEntryListing from './EntryListing/VorschreibungVerbuchenVerarbeitungEntryListing';
import { AuftragVerbuchenGenerierlauf } from '../../../../types';

type VerarbeitungVorschreibungVerbuchenProps = {
  generierlauf: AuftragVerbuchenGenerierlauf;
  isCardCollapsed: boolean;
};

const VerarbeitungVorschreibungVerbuchen: FC<VerarbeitungVorschreibungVerbuchenProps> = ({ generierlauf, isCardCollapsed }) => (
  <>
    <Typography.Title level={4}>{verarbeitungPageAndMenuListTitles.AuftraegeVerbuchen}</Typography.Title>
    <Spacer />
    <VorschreibungVerbuchenVerarbeitungEntryListing generierlauf={generierlauf} isCardCollapsed={isCardCollapsed} />
  </>
);

export default VerarbeitungVorschreibungVerbuchen;
