import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBelegnummernkreisDefinitionMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['ID']['input'];
  input: Types.BelegnummernkreisDefinitionInput;
}>;

export type CreateBelegnummernkreisDefinitionMutation = {
  createBelegnummernkreisDefinition: {
    data: { belegnummernkreisDefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UpdateBelegnummernkreisDefinitionMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['String']['input'];
  belegnummernkreisDefinitionId: Types.Scalars['ID']['input'];
  input: Types.BelegnummernkreisDefinitionInput;
}>;

export type UpdateBelegnummernkreisDefinitionMutation = {
  updateBelegnummernkreisDefinition: {
    data: { belegnummernkreisDefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type DeleteBelegnummernkreisDefinitionMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['String']['input'];
  belegnummernkreisDefinitionId: Types.Scalars['ID']['input'];
}>;

export type DeleteBelegnummernkreisDefinitionMutation = {
  deleteBelegnummernkreisDefinition: { warningList: Array<{ message: string; type: string }>; errorList: Array<{ message: string; type: string }> };
};

export type ArchiveBelegnummernkreisDefinitionMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['String']['input'];
  belegnummernkreisDefinitionId: Types.Scalars['ID']['input'];
}>;

export type ArchiveBelegnummernkreisDefinitionMutation = {
  actionArchiveBelegnummernkreisDefinition: {
    data: { belegnummernkreisDefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UnarchiveBelegnummernkreisDefinitionMutationVariables = Types.Exact<{
  rechtstraegerId: Types.Scalars['String']['input'];
  belegnummernkreisDefinitionId: Types.Scalars['ID']['input'];
}>;

export type UnarchiveBelegnummernkreisDefinitionMutation = {
  actionUnarchiveBelegnummernkreisDefinition: {
    data: { belegnummernkreisDefinitionId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const CreateBelegnummernkreisDefinitionDocument = gql`
  mutation createBelegnummernkreisDefinition($rechtstraegerId: ID!, $input: BelegnummernkreisDefinitionInput!) {
    createBelegnummernkreisDefinition(rechtstraegerId: $rechtstraegerId, input: $input) {
      data {
        belegnummernkreisDefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useCreateBelegnummernkreisDefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBelegnummernkreisDefinitionMutation, CreateBelegnummernkreisDefinitionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBelegnummernkreisDefinitionMutation, CreateBelegnummernkreisDefinitionMutationVariables>(
    CreateBelegnummernkreisDefinitionDocument,
    options
  );
}
export type CreateBelegnummernkreisDefinitionMutationHookResult = ReturnType<typeof useCreateBelegnummernkreisDefinitionMutation>;
export type CreateBelegnummernkreisDefinitionMutationResult = Apollo.MutationResult<CreateBelegnummernkreisDefinitionMutation>;
export type CreateBelegnummernkreisDefinitionMutationOptions = Apollo.BaseMutationOptions<
  CreateBelegnummernkreisDefinitionMutation,
  CreateBelegnummernkreisDefinitionMutationVariables
>;
export const UpdateBelegnummernkreisDefinitionDocument = gql`
  mutation updateBelegnummernkreisDefinition(
    $rechtstraegerId: String!
    $belegnummernkreisDefinitionId: ID!
    $input: BelegnummernkreisDefinitionInput!
  ) {
    updateBelegnummernkreisDefinition(
      rechtstraegerId: $rechtstraegerId
      belegnummernkreisDefinitionId: $belegnummernkreisDefinitionId
      input: $input
    ) {
      data {
        belegnummernkreisDefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUpdateBelegnummernkreisDefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBelegnummernkreisDefinitionMutation, UpdateBelegnummernkreisDefinitionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBelegnummernkreisDefinitionMutation, UpdateBelegnummernkreisDefinitionMutationVariables>(
    UpdateBelegnummernkreisDefinitionDocument,
    options
  );
}
export type UpdateBelegnummernkreisDefinitionMutationHookResult = ReturnType<typeof useUpdateBelegnummernkreisDefinitionMutation>;
export type UpdateBelegnummernkreisDefinitionMutationResult = Apollo.MutationResult<UpdateBelegnummernkreisDefinitionMutation>;
export type UpdateBelegnummernkreisDefinitionMutationOptions = Apollo.BaseMutationOptions<
  UpdateBelegnummernkreisDefinitionMutation,
  UpdateBelegnummernkreisDefinitionMutationVariables
>;
export const DeleteBelegnummernkreisDefinitionDocument = gql`
  mutation deleteBelegnummernkreisDefinition($rechtstraegerId: String!, $belegnummernkreisDefinitionId: ID!) {
    deleteBelegnummernkreisDefinition(rechtstraegerId: $rechtstraegerId, belegnummernkreisDefinitionId: $belegnummernkreisDefinitionId) {
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useDeleteBelegnummernkreisDefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBelegnummernkreisDefinitionMutation, DeleteBelegnummernkreisDefinitionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBelegnummernkreisDefinitionMutation, DeleteBelegnummernkreisDefinitionMutationVariables>(
    DeleteBelegnummernkreisDefinitionDocument,
    options
  );
}
export type DeleteBelegnummernkreisDefinitionMutationHookResult = ReturnType<typeof useDeleteBelegnummernkreisDefinitionMutation>;
export type DeleteBelegnummernkreisDefinitionMutationResult = Apollo.MutationResult<DeleteBelegnummernkreisDefinitionMutation>;
export type DeleteBelegnummernkreisDefinitionMutationOptions = Apollo.BaseMutationOptions<
  DeleteBelegnummernkreisDefinitionMutation,
  DeleteBelegnummernkreisDefinitionMutationVariables
>;
export const ArchiveBelegnummernkreisDefinitionDocument = gql`
  mutation ArchiveBelegnummernkreisDefinition($rechtstraegerId: String!, $belegnummernkreisDefinitionId: ID!) {
    actionArchiveBelegnummernkreisDefinition(rechtstraegerId: $rechtstraegerId, belegnummernkreisDefinitionId: $belegnummernkreisDefinitionId) {
      data {
        belegnummernkreisDefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useArchiveBelegnummernkreisDefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveBelegnummernkreisDefinitionMutation, ArchiveBelegnummernkreisDefinitionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveBelegnummernkreisDefinitionMutation, ArchiveBelegnummernkreisDefinitionMutationVariables>(
    ArchiveBelegnummernkreisDefinitionDocument,
    options
  );
}
export type ArchiveBelegnummernkreisDefinitionMutationHookResult = ReturnType<typeof useArchiveBelegnummernkreisDefinitionMutation>;
export type ArchiveBelegnummernkreisDefinitionMutationResult = Apollo.MutationResult<ArchiveBelegnummernkreisDefinitionMutation>;
export type ArchiveBelegnummernkreisDefinitionMutationOptions = Apollo.BaseMutationOptions<
  ArchiveBelegnummernkreisDefinitionMutation,
  ArchiveBelegnummernkreisDefinitionMutationVariables
>;
export const UnarchiveBelegnummernkreisDefinitionDocument = gql`
  mutation UnarchiveBelegnummernkreisDefinition($rechtstraegerId: String!, $belegnummernkreisDefinitionId: ID!) {
    actionUnarchiveBelegnummernkreisDefinition(rechtstraegerId: $rechtstraegerId, belegnummernkreisDefinitionId: $belegnummernkreisDefinitionId) {
      data {
        belegnummernkreisDefinitionId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUnarchiveBelegnummernkreisDefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<UnarchiveBelegnummernkreisDefinitionMutation, UnarchiveBelegnummernkreisDefinitionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnarchiveBelegnummernkreisDefinitionMutation, UnarchiveBelegnummernkreisDefinitionMutationVariables>(
    UnarchiveBelegnummernkreisDefinitionDocument,
    options
  );
}
export type UnarchiveBelegnummernkreisDefinitionMutationHookResult = ReturnType<typeof useUnarchiveBelegnummernkreisDefinitionMutation>;
export type UnarchiveBelegnummernkreisDefinitionMutationResult = Apollo.MutationResult<UnarchiveBelegnummernkreisDefinitionMutation>;
export type UnarchiveBelegnummernkreisDefinitionMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveBelegnummernkreisDefinitionMutation,
  UnarchiveBelegnummernkreisDefinitionMutationVariables
>;
