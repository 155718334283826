import React from 'react';
import { useParams } from 'react-router';
import { Skeleton } from 'antd';
import { useAbrechnungsdefinitionTypeQuery } from '../../../../../../features/Abrechnungsdefinition/gql/AbrDefQueries.types';
import { AbrechnungsdefinitionVersionCreatePageRouteParams } from './routes';
import SysSettingsWeAbrechnungsdefinitionVersionCreatePage from './SysSettingsWeAbrechnungsdefinitionVersionCreatePage';
import {
  isAbrDefTypeHeAbrechnung,
  isAbrDefTypeSubAbrechnung,
  isAbrDefTypeWeAbrechnung,
} from '../../../../../../features/Abrechnungsdefinition/shared/AbrDefAbrKreisListingForm/abrDefAbrKreisListingFormHelpers';
import SysSettingsHeAbrechnungsdefinitionVersionCreatePage from './SysSettingsHeAbrechnungsdefinitionVersionCreatePage';
import SysSettingsBkAbrechnungsdefinitionVersionCreatePage from './SysSettingsBkAbrechnungsdefinitionVersionCreatePage';
import SysSettingsSubAdministrationAbrechnungsdefinitionVersionCreatePage from './SysSettingsSubAdministrationAbrechnungsdefinitionVersionCreatePage';

const SysSettingsAbrechnungsdefinitionVersionCreatePage = () => {
  const { abrechnungsdefinitionId } = useParams() as AbrechnungsdefinitionVersionCreatePageRouteParams;

  const { loading, data } = useAbrechnungsdefinitionTypeQuery({ variables: { abrechnungsdefinitionId } });
  const abrDefType = data?.getAbrechnungsdefinitionType.data;

  if (!abrDefType || loading) {
    return <Skeleton active />;
  } else if (isAbrDefTypeWeAbrechnung(abrDefType.value)) {
    return <SysSettingsWeAbrechnungsdefinitionVersionCreatePage abrechnungsdefinitionId={abrechnungsdefinitionId} />;
  } else if (isAbrDefTypeHeAbrechnung(abrDefType.value)) {
    return <SysSettingsHeAbrechnungsdefinitionVersionCreatePage abrechnungsdefinitionId={abrechnungsdefinitionId} />;
  } else if (isAbrDefTypeSubAbrechnung(abrDefType.value)) {
    return <SysSettingsSubAdministrationAbrechnungsdefinitionVersionCreatePage abrechnungsdefinitionId={abrechnungsdefinitionId} />;
  } else {
    return <SysSettingsBkAbrechnungsdefinitionVersionCreatePage abrechnungsdefinitionId={abrechnungsdefinitionId} />;
  }
};

export default SysSettingsAbrechnungsdefinitionVersionCreatePage;
