import { FormFields, mapFormDateValueToDateString } from '../../../../../../../../helpers/formikHelper';

export type BuchungDeviationUebernehmenFormValues = {
  buchungsdatum: string;
};

export const buchungDeviationUebernehmenFormFields: FormFields<BuchungDeviationUebernehmenFormValues> = {
  buchungsdatum: 'buchungsdatum',
};

export const mapFormValuesToBuchungDeviationUebernehmen = (values: BuchungDeviationUebernehmenFormValues) => ({
  buchungsdatum: mapFormDateValueToDateString(values.buchungsdatum),
});
