import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BeVertragApplyAllDeviationsMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
}>;

export type BeVertragApplyAllDeviationsMutation = {
  actionBeVertragApplyAllDeviations: {
    data: { vertragId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BeVertragPruefungAussetzenMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
}>;

export type BeVertragPruefungAussetzenMutation = {
  actionBeVertragPruefungAussetzen: {
    data: { vertragId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type BeVertragPruefungAktivierenMutationVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  bestandseinheitId: Types.Scalars['ID']['input'];
  vertragId: Types.Scalars['ID']['input'];
}>;

export type BeVertragPruefungAktivierenMutation = {
  actionBeVertragPruefungAktivieren: {
    data: { vertragId: string };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const BeVertragApplyAllDeviationsDocument = gql`
  mutation BeVertragApplyAllDeviations($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!) {
    actionBeVertragApplyAllDeviations(objektId: $objektId, bestandseinheitId: $bestandseinheitId, vertragId: $vertragId) {
      data {
        vertragId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBeVertragApplyAllDeviationsMutation(
  baseOptions?: Apollo.MutationHookOptions<BeVertragApplyAllDeviationsMutation, BeVertragApplyAllDeviationsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BeVertragApplyAllDeviationsMutation, BeVertragApplyAllDeviationsMutationVariables>(
    BeVertragApplyAllDeviationsDocument,
    options
  );
}
export type BeVertragApplyAllDeviationsMutationHookResult = ReturnType<typeof useBeVertragApplyAllDeviationsMutation>;
export type BeVertragApplyAllDeviationsMutationResult = Apollo.MutationResult<BeVertragApplyAllDeviationsMutation>;
export type BeVertragApplyAllDeviationsMutationOptions = Apollo.BaseMutationOptions<
  BeVertragApplyAllDeviationsMutation,
  BeVertragApplyAllDeviationsMutationVariables
>;
export const BeVertragPruefungAussetzenDocument = gql`
  mutation BeVertragPruefungAussetzen($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!) {
    actionBeVertragPruefungAussetzen(objektId: $objektId, bestandseinheitId: $bestandseinheitId, vertragId: $vertragId) {
      data {
        vertragId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBeVertragPruefungAussetzenMutation(
  baseOptions?: Apollo.MutationHookOptions<BeVertragPruefungAussetzenMutation, BeVertragPruefungAussetzenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BeVertragPruefungAussetzenMutation, BeVertragPruefungAussetzenMutationVariables>(
    BeVertragPruefungAussetzenDocument,
    options
  );
}
export type BeVertragPruefungAussetzenMutationHookResult = ReturnType<typeof useBeVertragPruefungAussetzenMutation>;
export type BeVertragPruefungAussetzenMutationResult = Apollo.MutationResult<BeVertragPruefungAussetzenMutation>;
export type BeVertragPruefungAussetzenMutationOptions = Apollo.BaseMutationOptions<
  BeVertragPruefungAussetzenMutation,
  BeVertragPruefungAussetzenMutationVariables
>;
export const BeVertragPruefungAktivierenDocument = gql`
  mutation BeVertragPruefungAktivieren($objektId: ID!, $bestandseinheitId: ID!, $vertragId: ID!) {
    actionBeVertragPruefungAktivieren(objektId: $objektId, bestandseinheitId: $bestandseinheitId, vertragId: $vertragId) {
      data {
        vertragId
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useBeVertragPruefungAktivierenMutation(
  baseOptions?: Apollo.MutationHookOptions<BeVertragPruefungAktivierenMutation, BeVertragPruefungAktivierenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BeVertragPruefungAktivierenMutation, BeVertragPruefungAktivierenMutationVariables>(
    BeVertragPruefungAktivierenDocument,
    options
  );
}
export type BeVertragPruefungAktivierenMutationHookResult = ReturnType<typeof useBeVertragPruefungAktivierenMutation>;
export type BeVertragPruefungAktivierenMutationResult = Apollo.MutationResult<BeVertragPruefungAktivierenMutation>;
export type BeVertragPruefungAktivierenMutationOptions = Apollo.BaseMutationOptions<
  BeVertragPruefungAktivierenMutation,
  BeVertragPruefungAktivierenMutationVariables
>;
