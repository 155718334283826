import React from 'react';
import { MenuProps } from 'antd';
import { generatePath, Link } from 'react-router-dom';
import { URI_ANDROMEDA_SYS_SETTINGS } from '../../constants/andromedaSystemSettingsUriPaths';
import usePermission from '../../shared/Auth/usePermission';
import DataWithShortenedText from '../../components/Helpers/DataWithShortenedText';
import SettingsSider from '../../components/SettingsSider/SettingsSider';

const AndromedaSysSettingsSider: React.FC = () => {
  const { isAdmin } = usePermission();

  // Show only for admin
  const items: MenuProps['items'] = isAdmin
    ? [
        {
          label: 'Verwaltung',
          key: 'verwaltung',
          children: [
            {
              key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.kundenSystemPage),
              label: <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.kundenSystemPage)}>Kundensystem</Link>,
            },
            {
              key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.adminPage),
              label: <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.adminPage)}>Admins</Link>,
            },
            {
              key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.user),
              label: <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.user)}>Benutzer</Link>,
            },
          ],
        },
        {
          label: 'Vorgaben',
          key: 'vorgaben',
          children: [
            {
              key: 'g1',
              label: 'Kundensystem',
              children: [
                {
                  key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.aufteilungsschluesselPage),
                  label: <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.aufteilungsschluesselPage)}>Aufteilungsschlüssel</Link>,
                },
                {
                  key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.vorschreibungspositionPage),
                  label: <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.vorschreibungspositionPage)}>Vorschreibungspositionen</Link>,
                },
                {
                  key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.kontierungstabellePage),
                  label: <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.kontierungstabellePage)}>Kontierungstabellen</Link>,
                },
                {
                  key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.mahndefinitionPage),
                  label: <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.mahndefinitionPage)}>Mahndefinitionen</Link>,
                },
                {
                  key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.emailDeliveryDefinitionPage),
                  label: <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.emailDeliveryDefinitionPage)}>E-Mail Versanddefinition</Link>,
                },
                {
                  key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.textPage),
                  label: <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.textPage)}>Texte</Link>,
                },
                {
                  key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.auftragsartPage),
                  label: <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.auftragsartPage)}>Auftragsarten</Link>,
                },
                {
                  key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.aufgabenartPage),
                  label: <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.aufgabenartPage)}>Aufgabenarten</Link>,
                },
              ],
            },
            {
              key: 'g2',
              label: 'Abrechnung',
              children: [
                {
                  key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.abrechnungskreisPage),
                  label: <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.abrechnungskreisPage)}>Kreise</Link>,
                },
                {
                  key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.abrechnungsdefinitionPage),
                  label: <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.abrechnungsdefinitionPage)}>Definitionen</Link>,
                },
                {
                  key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.abrechnungKontenZuordnungPage),
                  label: <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.abrechnungKontenZuordnungPage)}>Kontenzuordnung</Link>,
                },
              ],
            },
            {
              key: 'g3',
              label: 'USt',
              children: [
                {
                  key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.ustKategoriePage),
                  label: <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.ustKategoriePage)}>Kategorie</Link>,
                },
                {
                  key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.ustRegelsetPage),
                  label: <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.ustRegelsetPage)}>Regelwerk</Link>,
                },
              ],
            },
            {
              key: 'g4',
              label: 'Buchhaltung',
              children: [
                {
                  key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.kontenRahmenPage),
                  label: <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.kontenRahmenPage)}>Kontenrahmen</Link>,
                },
                {
                  key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.vorsteuerkuerzungPage),
                  label: <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.vorsteuerkuerzungPage)}>Vorsteuerkürzung</Link>,
                },
                {
                  key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.repFondsPage),
                  label: <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.repFondsPage)}>Reparaturfonds</Link>,
                },
              ],
            },
            {
              key: 'g5',
              label: 'Budgetierung',
              children: [
                {
                  key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.budgetingVerknuepfungVPosAuftSchluesselPage),
                  label: (
                    <DataWithShortenedText maxTextLength={25} text="Verknüpfung Vorschreibungsposition zu Aufteilungsschlüssel">
                      {(shortenedText) => (
                        <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.budgetingVerknuepfungVPosAuftSchluesselPage)}>{shortenedText}</Link>
                      )}
                    </DataWithShortenedText>
                  ),
                },
                {
                  key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.budgetingVerknuepfungAbrDefVPosPage),
                  label: (
                    <DataWithShortenedText maxTextLength={25} text="Verknüpfung Abrechnungsdefinition zu Vorschreibungspositionen">
                      {(shortenedText) => (
                        <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.budgetingVerknuepfungAbrDefVPosPage)}>{shortenedText}</Link>
                      )}
                    </DataWithShortenedText>
                  ),
                },
              ],
            },
          ],
        },
        {
          label: 'Wertsicherung',
          key: 'wertsicherung',
          children: [
            {
              key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.indexSeries),
              label: <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.indexSeries)}>Indexreihen</Link>,
            },
          ],
        },
        {
          label: 'Zahlungsverkehr',
          key: 'zahlungsverkehr',
          children: [
            {
              key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.ebicsBankConfig),
              label: <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.ebicsBankConfig)}>EBICS Banken</Link>,
            },
          ],
        },
        {
          label: 'Texte',
          key: 'fieldhelp',
          children: [
            {
              key: generatePath(URI_ANDROMEDA_SYS_SETTINGS.fieldHelp),
              label: <Link to={generatePath(URI_ANDROMEDA_SYS_SETTINGS.fieldHelp)}>Feldhilfe</Link>,
            },
          ],
        },
        {
          label: 'Konfiguration',
          key: 'konfiguration',
          children: [{ key: 'tokens', label: <Link to={URI_ANDROMEDA_SYS_SETTINGS.token}>Tokens</Link> }],
        },
      ]
    : [];

  return <SettingsSider items={items} />;
};

export default AndromedaSysSettingsSider;
