import React, { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import pdfStyles from '../../styles/pdfStyles';
import { Zinsliste } from '../zinsliste-types';
import ZBeVertragList from './VertragListBlock/ZBeVertragList';
import ZVPosListSummaryBlock from './VPosListSummaryBlock/ZVPosListSummaryBlock';

type Props = {
  zinsliste: Zinsliste;
};

const ZZinsliste: FC<Props> = ({ zinsliste }) => (
  <View>
    {/* Title */}
    <View style={{ margin: '5mm 0', fontWeight: 'bold' }}>
      <Text>{zinsliste.zeitraumText}</Text>
    </View>
    <Text style={[pdfStyles.borderBottomSmall, { fontSize: 14, fontWeight: 800, marginBottom: '5mm' }]}>{zinsliste.objektBezeichnung}</Text>
    <ZBeVertragList zinsliste={zinsliste} />
    <ZVPosListSummaryBlock zinsliste={zinsliste} />
  </View>
);

export default ZZinsliste;
