import { FC } from 'react';
import { useBeVertragVposIndexationAgreementDeletionHistoryQuery } from '../../../VorschreibungspositionTab/gql/BeVertragVorschreibungspositionQueries.types';
import HistoryListingTable from '../../../../../../History/HistoryListingTable';
import { HistoryType } from '../../../../../../History/shared/HistoryModal';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  vorschreibungspositionId: string;
};

const VertragVposIndexationAgreementDeletionHistoryListingTable: FC<Props> = ({
  objektId,
  bestandseinheitId,
  vertragId,
  vorschreibungspositionId,
}) => {
  const { data, loading } = useBeVertragVposIndexationAgreementDeletionHistoryQuery({
    variables: { objektId, bestandseinheitId, vertragId, vorschreibungspositionId },
  });
  const historyList = data?.getBeVertragVposIndexationAgreementDeletionHistory.data;

  return <HistoryListingTable historyType={HistoryType.Deletion} historyList={historyList} loading={loading} filterIdPrefix="be-vertrag-vpos" />;
};

export default VertragVposIndexationAgreementDeletionHistoryListingTable;
