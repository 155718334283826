import { mapToQueryString, QueryParams } from '../../../../../hooks/useStringQueryParams';
import { GenerierlaufEntryExitCode } from '../../../../../types';
import { PaginationQueryParamsProps } from '../../../../../hooks/usePaginationQueryParams';
import { getPathToVerarbeitungDetailsPage } from '../../../verarbeitungApiPaths';

enum TopFinancialPlanVerarbeitungQueryParamKey {
  BESTANDSEINHET_ID = 'bestandseinheitId',
  BUDGETING_ID = 'budgetingId',
  EXIT_CODE_LIST = 'exitCodeList',
  OBJEKT_ID = 'objektId',
  RECHNUNGSAUSSTELLER_ID = 'rechnungsAusstellerId',
  VERTRAGSPARTNER_ID = 'vertragspartnerId',
}

export type TopFinancialPlanVerarbeitungQueryParams = QueryParams<{
  bestandseinheitId: string;
  budgetingId: string;
  exitCodeList: GenerierlaufEntryExitCode[];
  objektId: string;
  rechnungsAusstellerId: string;
  vertragspartnerId: string;
}>;

export const updateQueryParams = (
  verarbeitungType: string,
  generierlaufId: string,
  navigate: (path: string) => void,
  filters: TopFinancialPlanVerarbeitungQueryParams,
  paginationParams?: PaginationQueryParamsProps
) => {
  navigate(
    generatePathToTopFinancialPlanListPage(
      verarbeitungType,
      generierlaufId,
      filters.bestandseinheitId,
      filters.budgetingId,
      filters.exitCodeList,
      filters.objektId,
      filters.rechnungsAusstellerId,
      filters.vertragspartnerId,
      paginationParams
    )
  );
};

const generatePathToTopFinancialPlanListPage = (
  verarbeitungType: string,
  generierlaufId: string,
  bestandseinheitId?: string,
  budgetingId?: string,
  exitCodeList?: GenerierlaufEntryExitCode[],
  objektId?: string,
  rechnungsausstellerId?: string,
  vertragspartnerId?: string,
  paginationParams?: PaginationQueryParamsProps
) =>
  `${getPathToVerarbeitungDetailsPage(verarbeitungType, generierlaufId)}?${mapToQueryString({
    [TopFinancialPlanVerarbeitungQueryParamKey.BESTANDSEINHET_ID]: bestandseinheitId,
    [TopFinancialPlanVerarbeitungQueryParamKey.BUDGETING_ID]: budgetingId,
    [TopFinancialPlanVerarbeitungQueryParamKey.EXIT_CODE_LIST]: exitCodeList,
    [TopFinancialPlanVerarbeitungQueryParamKey.OBJEKT_ID]: objektId,
    [TopFinancialPlanVerarbeitungQueryParamKey.RECHNUNGSAUSSTELLER_ID]: rechnungsausstellerId,
    [TopFinancialPlanVerarbeitungQueryParamKey.VERTRAGSPARTNER_ID]: vertragspartnerId,
    ...paginationParams,
  })}`;
