import { FC } from 'react';
import VersionTimeline from '../../../../components/Timeline/VersionTimeline';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../helpers/apolloHelper';
import { showSuccessMsgDelete } from '../../../../components/message';
import { useRepFondsEinstellungListQuery } from '../../gql/RepFondsEinstellungQueries.types';
import { useDeleteRepFondsEinstellungMutation } from '../../gql/RepFondsEinstellungMutations.types';
import RepFondsKontenZuweisungVersionCardContent from './RepFondsKontenZuweisungVersionCardContent';
import RepFondsKontenZuweisungVersionForm from './Form/RepFondsKontenZuweisungVersionForm';
import { RepFondsEinstellung } from '../../../../types';

type Props = {
  reloadRepFondsCurrentKontenZuweisung: () => void;
};

const RepFondsKontenZuweisungVersionTimeline: FC<Props> = ({ reloadRepFondsCurrentKontenZuweisung }) => {
  const {
    data,
    loading,
    refetch: reloadVersionList,
  } = useRepFondsEinstellungListQuery({
    variables: {
      withDetails: true,
    },
  });

  const repFondsEinstellungList = data?.getRepFondsEinstellungList.data ?? [];

  const onActionSuccess = () => {
    reloadRepFondsCurrentKontenZuweisung();
    reloadVersionList();
  };

  const [runDelete] = useDeleteRepFondsEinstellungMutation({
    onCompleted: () => {
      showSuccessMsgDelete('Reparaturfonds-Kontenzuweisung');
      onActionSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  return (
    <VersionTimeline<RepFondsEinstellung>
      dataSource={repFondsEinstellungList}
      versionIdKey="repFondsEinstellungId"
      loading={loading}
      defaultNewTimeblock={{
        repFondsEinstellungId: '',
        kontenZuweisungList: [],
        warningList: [],
        versionIndex: 0,
      }}
      isAllowedToDeleteInitialTimeblock
      kommentarForCardTitle={(timeblock) => timeblock.kommentar}
      onDelete={(timeblock) => runDelete({ variables: { repFondsEinstellungId: timeblock.repFondsEinstellungId } })}
      renderCardContent={(timeblock) => <RepFondsKontenZuweisungVersionCardContent timeblock={timeblock} />}
      renderForm={(timeblock) => <RepFondsKontenZuweisungVersionForm timeblock={timeblock} onSubmitSuccess={onActionSuccess} />}
    />
  );
};

export default RepFondsKontenZuweisungVersionTimeline;
