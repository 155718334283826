import React from 'react';
import { Link } from 'react-router-dom';

import { Tag } from 'antd';
import MitarbeiterTooltip from '../../../../components/Card/MitarbeiterTooltip';
import DataWithShortenedTextAndExtraInfo from '../../../../components/Helpers/DataWithShortenedTextAndExtraInfo';
import { generatePathToVerarbeitungHeAbrechnungErstellenDetailsPage } from '../../../Verarbeitung/verarbeitungApiPaths';
import StatusBadge from '../../../../components/Status/StatusBadge';
import { TableWithColSelectorColumnProps } from '../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { generatePathToHeAbrechnungDetailsPageOrDiscrepancies } from '../../He/heAbrechnungUriPaths';
import { isAbrechnungTypeHeAbrechnung } from '../../shared/abrechnungHelpers';
import { AbrechnungListEntry, AbrechnungOrderBy } from '../../../../types';
import { pathsToBkAbrechnungDetails } from '../../BkOrWe/bkAbrechnungUriPaths';
import FormattedDateTime from '../../../../components/FormattedDateTime/FormattedDateTime';
import AuftragCreationStatusTag from '../../shared/AuftragCreationStatusTag';
import CustomFormattedDate from '../../../../components/FormattedDate/CustomFormattedDate';

const mietAbrechnungTableColumns = (objektId: string): TableWithColSelectorColumnProps<AbrechnungListEntry>[] => [
  {
    title: 'Bezeichnung',
    defaultSelected: true,
    key: 'bezeichnung',
    dataIndex: AbrechnungOrderBy.Bezeichnung,
    sorter: true,
    render: (text, record) => <DataWithShortenedTextAndExtraInfo maxTextLength={20} text={record.bezeichnung} />,
  },
  {
    title: 'Kategorie/Typ',
    defaultSelected: true,
    key: 'type',
    dataIndex: AbrechnungOrderBy.Type,
    sorter: true,
    render: (text, record) => <Tag>{record.type.text}</Tag>,
  },
  {
    title: 'Zeitraum',
    defaultSelected: true,
    key: 'zeitraum',
    dataIndex: AbrechnungOrderBy.Zeitraum,
    sorter: true,
    render: (text, record) => (
      <>
        <CustomFormattedDate value={record.fromInclusive} /> – <CustomFormattedDate value={record.toInclusive} />
      </>
    ),
  },
  {
    title: 'Vorschreibungen',
    defaultSelected: true,
    render: (text, record) => (
      <AuftragCreationStatusTag
        auftragCreationStatus={record.auftragCreationStatus}
        countAuftragCreated={record.countAuftragCreated}
        countAuftragToCreateTotal={record.countAuftragToCreateTotal}
      />
    ),
  },
  {
    title: 'Verarbeitung',
    defaultSelected: true,
    width: 100,
    render: (text, record) => <Link to={generatePathToVerarbeitungHeAbrechnungErstellenDetailsPage(record.generierlaufId)}>Verarbeitung</Link>,
  },
  {
    title: 'Status',
    defaultSelected: true,
    width: 120,
    key: 'status',
    dataIndex: AbrechnungOrderBy.Status,
    sorter: true,
    render: (text, record) => <StatusBadge entityStatus={record.status} />,
  },
  {
    title: 'Erstellt am',
    width: 100,
    key: 'createTs',
    dataIndex: AbrechnungOrderBy.CreateTs,
    sorter: true,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    width: 70,
    render: (text, record) => (
      <MitarbeiterTooltip alignment="left" mitarbeiterId={record.createdByMitarbeiterId} userId={record.createdBy} showsInitials />
    ),
  },
  {
    title: 'Aktion',
    defaultSelected: true,
    fixed: 'right',
    width: 80,
    render: (text, record) =>
      isAbrechnungTypeHeAbrechnung(record.type.value) ? (
        <Link to={generatePathToHeAbrechnungDetailsPageOrDiscrepancies(objektId, record.abrechnungId, record.status)}>Details</Link>
      ) : (
        <Link to={pathsToBkAbrechnungDetails(objektId, record.abrechnungId).abrechnungDetails}>Details</Link>
      ),
  },
];

export default mietAbrechnungTableColumns;
