import React, { FC } from 'react';
import { Button, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useToggle } from '../../../../../../hooks/useToggle';
import ButtonsAligned, { ButtonsAlignedUsage } from '../../../../../../components/Button/ButtonsAligned';
import IndexedValuesCreateForm from './IndexedValuesCreateForm';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  showArchived: boolean;
  toggleShowArchived: () => void;
};
const VPosIndexationAgreementButtonsAndModal: FC<Props> = ({ objektId, bestandseinheitId, vertragId, showArchived, toggleShowArchived }) => {
  const [isOpen, toggleIsOpen] = useToggle();

  return (
    <ButtonsAligned usage={ButtonsAlignedUsage.beforeListing}>
      <Button onClick={toggleShowArchived} size="small">
        Archivierte Einträge {showArchived ? 'ausblenden' : 'anzeigen'}
      </Button>
      <Button icon={<PlusOutlined />} type="primary" onClick={toggleIsOpen} size="small">
        Indexierte Werte erstellen
      </Button>
      <Modal title="Indexierte Werte erstellen" width={520} footer={null} open={isOpen} onCancel={toggleIsOpen} destroyOnClose>
        <IndexedValuesCreateForm
          objektId={objektId}
          bestandseinheitId={bestandseinheitId}
          vertragId={vertragId}
          onCancel={toggleIsOpen}
          onOk={toggleIsOpen}
        />
      </Modal>
    </ButtonsAligned>
  );
};

export default VPosIndexationAgreementButtonsAndModal;
