import { Helmet } from 'react-helmet-async';
import { PageLayout } from '../../components/LayoutAndromeda';
import BookingJournalListing from '../../features/BookingJournal/Listing/BookingJournalListing';

const BookingJournalPage = () => {
  return (
    <>
      <Helmet>
        <title>Buchungsjournal</title>
      </Helmet>
      <PageLayout>
        <BookingJournalListing />
      </PageLayout>
    </>
  );
};

export default BookingJournalPage;
