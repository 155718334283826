import { Timeline, TimelineProps } from 'antd';
import React from 'react';
import TimelineDateTitle from './TimelineDateTitle';
import TimelineCard from './Card/TimelineCard';
import { ITimeblock, IVersionable, useTimeline, VersionTimelineOnDelete } from './timelineCtx';

type TimelineListProps<T extends IVersionable<T>> = {
  renderForm: (timeblock: ITimeblock<T>) => React.ReactNode;
  kommentarForCardTitle?: (timeblock: ITimeblock<T>) => string | null | undefined;
  renderCardContent: (timeblock: ITimeblock<T>) => React.ReactNode;
  onDelete: VersionTimelineOnDelete<T>;
  isAllowedToDeleteInitialTimeblock?: boolean;
};

function TimelineList<T extends IVersionable<T>>({
  renderForm,
  kommentarForCardTitle,
  renderCardContent,
  onDelete,
  isAllowedToDeleteInitialTimeblock,
}: TimelineListProps<T>) {
  const { dataSource } = useTimeline<T>();

  const items: TimelineProps['items'] = dataSource.map((timeblock) => ({
    key: timeblock.uuid,
    children: (
      <>
        <TimelineDateTitle timeblock={timeblock} />
        <TimelineCard
          isHistoricized={false}
          style={{ zIndex: 1 }}
          isAllowedToDeleteInitialTimeblock={isAllowedToDeleteInitialTimeblock}
          timeblock={timeblock}
          renderForm={renderForm}
          kommentarForCardTitle={kommentarForCardTitle}
          renderCardContent={renderCardContent}
          onDelete={onDelete}
          editable
        />
        {timeblock.historicizedList.map((timeblockHistoryItem) => (
          <TimelineCard
            isHistoricized
            style={{ zIndex: 0 }}
            styles={{ header: { paddingLeft: '24px' }, body: { paddingLeft: '24px' } }}
            key={timeblockHistoryItem.uuid}
            isAllowedToDeleteInitialTimeblock={isAllowedToDeleteInitialTimeblock}
            timeblock={timeblockHistoryItem}
            kommentarForCardTitle={kommentarForCardTitle}
            renderCardContent={renderCardContent}
            editable={false}
          />
        ))}
      </>
    ),
  }));

  return <Timeline items={items} />;
}

export default TimelineList;
