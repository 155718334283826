import { FC, useEffect } from 'react';
import { FormikProps } from 'formik';
import { Input } from 'formik-antd';
import { useNextSepaMandatReferenceQuery } from '../../../gql/BankDetailsQueries.types';
import { LoadingCol } from '../../../../../components/Loading';
import { SepaMandatCreateFormValues } from './sepaMandatCreateFormMapper';

type Props = {
  name: string;
  rechtstraegerId: string;
  formikProps: FormikProps<SepaMandatCreateFormValues>;
};

const SepaMandatReferenceInput: FC<Props> = ({ name, rechtstraegerId, formikProps }) => {
  const { data, loading: nextSepaMandatReferenceLoading } = useNextSepaMandatReferenceQuery({
    variables: {
      rechtstraegerId,
    },
  });

  const nextSepaMandatReference = data?.getNextSepaMandatReference.data ?? '';

  useEffect(() => {
    if (nextSepaMandatReference) {
      formikProps.setFieldValue(name, nextSepaMandatReference);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextSepaMandatReference, nextSepaMandatReferenceLoading]);

  return (
    <LoadingCol isLoading={nextSepaMandatReferenceLoading}>
      <Input name={name} placeholder="SEPA Mandatsreferenz eingeben" allowClear />
    </LoadingCol>
  );
};

export default SepaMandatReferenceInput;
