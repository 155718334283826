import React from 'react';
import { Skeleton } from 'antd';
import { useMahndefinitionTemplateQuery } from '../../../../../features/Mahndefinition/gql/MahndefinitionQueries.types';
import MahndefinitionTemplateCreateForm from '../../../../../features/MahndefinitionTemplate/Form/Create/MahndefinitionTemplateCreateForm';

const AndromedaSysSettingsMahndefinitionCreatePage = () => {
  const { data: mahndefinitionTextbausteinListData, loading: loadingMahndefinitionTemplate } = useMahndefinitionTemplateQuery();
  const mahndefinitionTextbausteinList = mahndefinitionTextbausteinListData?.getMahndefinitionTemplate.data.textbausteinList ?? [];

  if (loadingMahndefinitionTemplate) {
    return <Skeleton active />;
  }

  return <MahndefinitionTemplateCreateForm mahndefinitionTextbausteinList={mahndefinitionTextbausteinList} />;
};

export default AndromedaSysSettingsMahndefinitionCreatePage;
