import React, { FC } from 'react';
import { Skeleton, Space } from 'antd';
import { Spacer } from '../../../../../components/Grid';
import { GesamtergebnisWithWrapper } from '../../../shared/Gesamtergebnis';
import HeAbrHeVertragTable from './Table/HeAbrHeVertragTable';
import ErrorListing from './ErrorListing';
import HeVertragOffenePostenTable from './Table/OffenePosten/HeVertragOffenePostenTable';
import SaldovortragTable from './Table/Saldovortrag/SaldovortragTable';
import { HeAbrechnungHeVertrag } from '../../../../../types';

type Props = {
  abrechnung: HeAbrechnungHeVertrag;
  loading: boolean;
};

const HeAbrHeVertragDetailsListingTab: FC<Props> = ({ abrechnung, loading }) => (
  <Skeleton active loading={loading}>
    <ErrorListing heAbrHeVertragErrorList={abrechnung.errorList} />
    <GesamtergebnisWithWrapper guthaben={abrechnung.guthaben} saldo={abrechnung.saldo} />
    <Spacer height={16} />
    <Space direction="vertical" style={{ width: '100%' }} size={16}>
      <SaldovortragTable abrechnung={abrechnung} />
      <HeAbrHeVertragTable abrechnung={abrechnung} />
      <HeVertragOffenePostenTable abrechnung={abrechnung} />
    </Space>
  </Skeleton>
);

export default HeAbrHeVertragDetailsListingTab;
