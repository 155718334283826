import React, { FC } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import DueDateVorschreibungSelect from '../DueDateVorschreibungSelect';
import FormButtons from '../../../../components/Button/FormButtons';
import { useUpdateFirmeneinstellungMutation } from '../gql/FirmendatenMutations.types';
import { showSuccessMsgCreate } from '../../../../components/message';
import { FirmendatenEinstellungFormValues, mapFirmeneinstellungToValues, mapValuesToFirmeneinstellung } from './firmeneinstellungMapper';
import { Firmendaten } from '../../../../types';
import { useGetAppFieldHelpText } from '../../../FieldHelp/useGetFieldHelpText';

type FirmeneinstellungFormProps = {
  firmendaten: Firmendaten;
  onSuccess: () => void;
  onCancel: () => void;
};

const FirmeneinstellungForm: FC<FirmeneinstellungFormProps> = ({ firmendaten, onSuccess, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'Firmeneinstellung'>('Firmeneinstellung');

  const initalValues = mapFirmeneinstellungToValues(firmendaten);

  const [runUpdate, { loading: loadingUpdate }] = useUpdateFirmeneinstellungMutation({
    onCompleted: () => {
      showSuccessMsgCreate(`Einstellungen erfolgreich aktualisiert`);
      onSuccess();
    },
  });

  return (
    <Formik<FirmendatenEinstellungFormValues>
      initialValues={initalValues}
      onSubmit={(values, formikHelpers) => {
        runUpdate({ variables: { firmendatenId: firmendaten.firmendatenId, request: mapValuesToFirmeneinstellung(values) } }).finally(() =>
          formikHelpers.setSubmitting(false)
        );
      }}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <DueDateVorschreibungSelect name="dueDateVorschreibung" fieldHelp={getFieldHelpText('Firmeneinstellung.dueDateVorschreibung')} />
          <FormButtons onCancel={onCancel} updateMode={!!firmendaten} isOkDisabled={formikProps.isSubmitting} isOkLoading={loadingUpdate} />
        </Form>
      )}
    </Formik>
  );
};

export default FirmeneinstellungForm;
