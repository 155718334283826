import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { UstVomAufwandFieldsFragmentDoc } from './UstVomAufwandFragments.types';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UstVomAufwandListQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  includeEmpty?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeZero?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type UstVomAufwandListQuery = {
  getUstVomAufwandList: {
    data: Array<{
      bisInklusive: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      sumUstVomAufwand: number;
      ustVomAufwandId: string;
      vonInklusive: string;
      buchungStatus: { description?: string | null; text: string; value: Types.UstVomAufwandBuchungStatus };
      status: { description?: string | null; text: string; value: Types.UstVomAufwandStatus };
      vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UstVomAufwandQueryVariables = Types.Exact<{
  objektId: Types.Scalars['ID']['input'];
  ustVomAufwandId: Types.Scalars['ID']['input'];
}>;

export type UstVomAufwandQuery = {
  getUstVomAufwand: {
    data: {
      belegnummer?: string | null;
      bisInklusive: string;
      createTs: string;
      createdBy: string;
      createdByMitarbeiterId?: string | null;
      fibuBuchungIdList: Array<string>;
      lastBuchungsdatum?: string | null;
      sumNetto: number;
      sumSteuer: number;
      sumUstVomAufwand: number;
      updatedByMitarbeiterId?: string | null;
      ustVomAufwandId: string;
      vonInklusive: string;
      buchungErrorList: Array<{ message: string; type: string }>;
      buchungStatus: { description?: string | null; text: string; value: Types.UstVomAufwandBuchungStatus };
      errorList: Array<{ message: string; type: string }>;
      objekt: {
        createTs: string;
        createdBy?: string | null;
        createdByMitarbeiterId?: string | null;
        kurzBezeichnung: string;
        objektId: string;
        updatedByMitarbeiterId?: string | null;
        rechnungsAussteller: {
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          rechtstraegerId: string;
          status: { text: string; value: Types.RechtstraegerStatus };
          type: { text: string; value: Types.RechtstraegerType };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        status: { text: string; value: Types.ObjektStatus; description?: string | null };
        verwaltungsart: { text: string; value: Types.Verwaltungsart };
        warningList: Array<{ attribute?: string | null; message: string; type: string }>;
      };
      status: { description?: string | null; text: string; value: Types.UstVomAufwandStatus };
      ustVomAufwandVertragList: Array<{
        fibuBuchungIdList: Array<string>;
        sumNetto: number;
        sumSteuer: number;
        sumUstVomAufwand: number;
        bestandseinheit: {
          bestandseinheitId: string;
          bezeichnung: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          objektId: string;
          status: { text: string; value: Types.BestandseinheitStatus; description?: string | null };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
        repFondsKontoList: Array<{
          repFondsKontoId: string;
          sumNetto: number;
          sumSteuer: number;
          sumUstVomAufwand: number;
          aufwandsKontoList: Array<{
            aufwandsKontoId: string;
            sumNetto: number;
            sumSteuer: number;
            sumUstVomAufwand: number;
            aufteilungList: Array<{
              anteilTopAufteilungsschluessel: number;
              belegFileId: string;
              belegId: string;
              belegnummer: string;
              buchungId: string;
              buchungsdatum: string;
              einheitskosten: number;
              gesamtAnteilAufteilungsschluessel: number;
              netto: number;
              standingInvoicePartialAmountId?: string | null;
              steuer: number;
              ustVomAufwandAnteil: number;
              ustVomAufwandSteuersatz: number;
              aufteilungsschluessel: {
                aufteilungsschluesselId: string;
                bezeichnung: string;
                bezugsbasisEditable?: boolean | null;
                createTs: string;
                createdBy?: string | null;
                createdByMitarbeiterId?: string | null;
                deletable?: boolean | null;
                firmendatenId?: string | null;
                kurzBezeichnung: string;
                updatedByMitarbeiterId?: string | null;
                bezugsbasis: { text: string; value: Types.Bezugsbasis };
                masseinheit: { text: string; value: Types.Masseinheit };
                status: { text: string; value: Types.AufteilungsschluesselStatus; description?: string | null };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
              lieferant: {
                createTs: string;
                createdBy: string;
                createdByMitarbeiterId?: string | null;
                kurzBezeichnung: string;
                rechtstraegerId: string;
                status: { text: string; value: Types.RechtstraegerStatus };
                type: { text: string; value: Types.RechtstraegerType };
                warningList: Array<{ attribute?: string | null; message: string; type: string }>;
              };
            }>;
          }>;
        }>;
        vertrag: {
          bestandseinheitId: string;
          createTs: string;
          createdBy: string;
          createdByMitarbeiterId?: string | null;
          kurzBezeichnung: string;
          objektId: string;
          vertragId: string;
          vertragsBeginn: string;
          status: { text: string; value: Types.VertragStatus; description?: string | null };
          vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
          vertragspartner: {
            createTs: string;
            createdBy: string;
            createdByMitarbeiterId?: string | null;
            kurzBezeichnung: string;
            rechtstraegerId: string;
            status: { text: string; value: Types.RechtstraegerStatus };
            type: { text: string; value: Types.RechtstraegerType };
            warningList: Array<{ attribute?: string | null; message: string; type: string }>;
          };
          warningList: Array<{ attribute?: string | null; message: string; type: string }>;
        };
      }>;
      vertragsart: { supportsExplicitVertragsEnde?: boolean | null; text: string; value: Types.Vertragsart };
      warningList: Array<{ attribute?: string | null; message: string; type: string }>;
    };
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export type UstVomAufwandBuchungStatusListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type UstVomAufwandBuchungStatusListQuery = {
  getUstVomAufwandBuchungStatusList: {
    data: Array<{ description?: string | null; text: string; value: Types.UstVomAufwandBuchungStatus }>;
    warningList: Array<{ message: string; type: string }>;
    errorList: Array<{ message: string; type: string }>;
  };
};

export const UstVomAufwandListDocument = gql`
  query UstVomAufwandList($objektId: ID!, $includeEmpty: Boolean, $includeZero: Boolean) {
    getUstVomAufwandList(objektId: $objektId, includeEmpty: $includeEmpty, includeZero: $includeZero) {
      data {
        bisInklusive
        buchungStatus {
          description
          text
          value
        }
        createTs
        createdBy
        createdByMitarbeiterId
        status {
          description
          text
          value
        }
        sumUstVomAufwand
        ustVomAufwandId
        vonInklusive
        vertragsart {
          supportsExplicitVertragsEnde
          text
          value
        }
        warningList {
          attribute
          message
          type
        }
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUstVomAufwandListQuery(
  baseOptions: Apollo.QueryHookOptions<UstVomAufwandListQuery, UstVomAufwandListQueryVariables> &
    ({ variables: UstVomAufwandListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstVomAufwandListQuery, UstVomAufwandListQueryVariables>(UstVomAufwandListDocument, options);
}
export function useUstVomAufwandListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UstVomAufwandListQuery, UstVomAufwandListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstVomAufwandListQuery, UstVomAufwandListQueryVariables>(UstVomAufwandListDocument, options);
}
export function useUstVomAufwandListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UstVomAufwandListQuery, UstVomAufwandListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstVomAufwandListQuery, UstVomAufwandListQueryVariables>(UstVomAufwandListDocument, options);
}
export type UstVomAufwandListQueryHookResult = ReturnType<typeof useUstVomAufwandListQuery>;
export type UstVomAufwandListLazyQueryHookResult = ReturnType<typeof useUstVomAufwandListLazyQuery>;
export type UstVomAufwandListSuspenseQueryHookResult = ReturnType<typeof useUstVomAufwandListSuspenseQuery>;
export type UstVomAufwandListQueryResult = Apollo.QueryResult<UstVomAufwandListQuery, UstVomAufwandListQueryVariables>;
export const UstVomAufwandDocument = gql`
  query UstVomAufwand($objektId: ID!, $ustVomAufwandId: ID!) {
    getUstVomAufwand(objektId: $objektId, ustVomAufwandId: $ustVomAufwandId) {
      data {
        ...UstVomAufwandFields
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
  ${UstVomAufwandFieldsFragmentDoc}
`;
export function useUstVomAufwandQuery(
  baseOptions: Apollo.QueryHookOptions<UstVomAufwandQuery, UstVomAufwandQueryVariables> &
    ({ variables: UstVomAufwandQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstVomAufwandQuery, UstVomAufwandQueryVariables>(UstVomAufwandDocument, options);
}
export function useUstVomAufwandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UstVomAufwandQuery, UstVomAufwandQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstVomAufwandQuery, UstVomAufwandQueryVariables>(UstVomAufwandDocument, options);
}
export function useUstVomAufwandSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UstVomAufwandQuery, UstVomAufwandQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstVomAufwandQuery, UstVomAufwandQueryVariables>(UstVomAufwandDocument, options);
}
export type UstVomAufwandQueryHookResult = ReturnType<typeof useUstVomAufwandQuery>;
export type UstVomAufwandLazyQueryHookResult = ReturnType<typeof useUstVomAufwandLazyQuery>;
export type UstVomAufwandSuspenseQueryHookResult = ReturnType<typeof useUstVomAufwandSuspenseQuery>;
export type UstVomAufwandQueryResult = Apollo.QueryResult<UstVomAufwandQuery, UstVomAufwandQueryVariables>;
export const UstVomAufwandBuchungStatusListDocument = gql`
  query UstVomAufwandBuchungStatusList {
    getUstVomAufwandBuchungStatusList {
      data {
        description
        text
        value
      }
      warningList {
        message
        type
      }
      errorList {
        message
        type
      }
    }
  }
`;
export function useUstVomAufwandBuchungStatusListQuery(
  baseOptions?: Apollo.QueryHookOptions<UstVomAufwandBuchungStatusListQuery, UstVomAufwandBuchungStatusListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UstVomAufwandBuchungStatusListQuery, UstVomAufwandBuchungStatusListQueryVariables>(
    UstVomAufwandBuchungStatusListDocument,
    options
  );
}
export function useUstVomAufwandBuchungStatusListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UstVomAufwandBuchungStatusListQuery, UstVomAufwandBuchungStatusListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UstVomAufwandBuchungStatusListQuery, UstVomAufwandBuchungStatusListQueryVariables>(
    UstVomAufwandBuchungStatusListDocument,
    options
  );
}
export function useUstVomAufwandBuchungStatusListSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UstVomAufwandBuchungStatusListQuery, UstVomAufwandBuchungStatusListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UstVomAufwandBuchungStatusListQuery, UstVomAufwandBuchungStatusListQueryVariables>(
    UstVomAufwandBuchungStatusListDocument,
    options
  );
}
export type UstVomAufwandBuchungStatusListQueryHookResult = ReturnType<typeof useUstVomAufwandBuchungStatusListQuery>;
export type UstVomAufwandBuchungStatusListLazyQueryHookResult = ReturnType<typeof useUstVomAufwandBuchungStatusListLazyQuery>;
export type UstVomAufwandBuchungStatusListSuspenseQueryHookResult = ReturnType<typeof useUstVomAufwandBuchungStatusListSuspenseQuery>;
export type UstVomAufwandBuchungStatusListQueryResult = Apollo.QueryResult<
  UstVomAufwandBuchungStatusListQuery,
  UstVomAufwandBuchungStatusListQueryVariables
>;
