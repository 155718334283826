import React, { FC } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form } from 'formik-antd';
import {
  indexedValueFormFields,
  indexedValueFormInitialValues,
  IndexedValueFormValues,
  mapFormValuesToIndexedValue,
} from './indexedValuesCreateFormMapper';
import ValuePreservationTypeCheckbox from '../../../../../shared/ValuePreservation/ValuePreservationTypeCheckbox';
import FormButtons from '../../../../../../components/Button/FormButtons';
import { useCreateVertragVposIndexedValueGenerierlaufMutation } from '../../../../../Verarbeitung/gql/VertragVposIndexedValueGenerierlauf/VertragVposIndexedValueGenerierlaufMutations.types';
import { showSuccessMsgOther } from '../../../../../../components/message';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../../helpers/apolloHelper';
import { createFormValidationSchema } from './createFormValidationSchema';
import MonthPicker from '../../../../../../components/DatePicker/MonthPicker/MonthPicker';
import AllowPreliminaryValuesSwitch from '../../../../../IndexedValue/shared/AllowPreliminaryValuesSwitch';
import { useGetAppFieldHelpText } from '../../../../../FieldHelp/useGetFieldHelpText';
import FormItemWithFieldHelp from '../../../../../../components/Form/FormItemWithFieldHelp';

type Props = {
  objektId: string;
  bestandseinheitId: string;
  vertragId: string;
  onCancel: () => void;
  onOk: () => void;
};

const IndexedValuesCreateForm: FC<Props> = ({ objektId, bestandseinheitId, vertragId, onOk, onCancel }) => {
  const getFieldHelpText = useGetAppFieldHelpText<'VertragVposIndexedValueGenerierlauf'>('VertragVposIndexedValueGenerierlauf');

  const [runCreate, { loading }] = useCreateVertragVposIndexedValueGenerierlaufMutation({
    onCompleted: (data) => {
      showSuccessMsgOther('Indexierte Werte werden erstellt');
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  const handleCreate = (values: IndexedValueFormValues, actions: FormikHelpers<IndexedValueFormValues>) => {
    const input = mapFormValuesToIndexedValue(values, objektId, bestandseinheitId, vertragId);
    runCreate({ variables: { input } })
      .then(onOk)
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <Formik<IndexedValueFormValues>
      initialValues={indexedValueFormInitialValues}
      validationSchema={createFormValidationSchema}
      onSubmit={handleCreate}
    >
      {(formikProps) => (
        <Form layout="vertical">
          <FormItemWithFieldHelp
            name={indexedValueFormFields.yearMonth}
            label="Monat/Jahr neu (aus Indexreihe)"
            fieldHelp={getFieldHelpText('VertragVposIndexedValueGenerierlauf.yearMonth.year')}
          >
            <MonthPicker name={indexedValueFormFields.yearMonth} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={indexedValueFormFields.valuePreservationTypeList}
            label="Für Wertsicherungsarten"
            fieldHelp={getFieldHelpText('VertragVposIndexedValueGenerierlauf.valuePreservationTypeList')}
          >
            <ValuePreservationTypeCheckbox name={indexedValueFormFields.valuePreservationTypeList} formikProps={formikProps} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={indexedValueFormFields.allowPreliminaryValues}
            label="Vorläufige Werte erlauben"
            fieldHelp={getFieldHelpText('VertragVposIndexedValueGenerierlauf.allowPreliminaryValues')}
          >
            <AllowPreliminaryValuesSwitch name={indexedValueFormFields.allowPreliminaryValues} formikProps={formikProps} />
          </FormItemWithFieldHelp>
          <FormItemWithFieldHelp
            name={indexedValueFormFields.validFrom}
            label="Gültig ab (VPos)"
            fieldHelp={getFieldHelpText('VertragVposIndexedValueGenerierlauf.validFrom')}
          >
            <MonthPicker name={indexedValueFormFields.validFrom} placeholder="Gültig ab auswählen" allowClear={false} />
          </FormItemWithFieldHelp>
          <FormButtons updateMode={false} onCancel={onCancel} isOkDisabled={formikProps.isSubmitting} isOkLoading={loading} />
        </Form>
      )}
    </Formik>
  );
};

export default IndexedValuesCreateForm;
