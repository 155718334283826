import React, { FC } from 'react';
import { Empty, PaginationProps, TableProps } from 'antd';
import paymentListingTableColumns from './paymentListingTableColumns';
import { PaymentListEntry } from '../../../../../types';
import { HandleTableSorting } from '../../../../../shared/typeHelpers';
import PaymentDetails from '../Level 2/PaymentDetails';
import { mapFormValuesToPayment, mapPaymentToUpdateFormValues, PaymentUpdateFormValues } from './Edit/paymentUpdateMapper';
import { showSuccessMsgUpdate, showWarningMessage } from '../../../../../components/message';
import { useUpdatePaymentMutation } from '../../../gql/Order/PaymentMutations.types';
import { APOLLO_DUMMY_ERROR_HANDLER } from '../../../../../helpers/apolloHelper';
import { paymentUpdateSchema } from './Edit/paymentUpdateSchema';
import useOnPaymentDataChangeEventsWithFilterRefetch from '../../../useOnPaymentDataChangeEventsWithFilterRefetch';
import EditableTableWithColSelector from '../../../../../components/Table/EditableTableV2/EditableTableWithColSelector';

export type TableRowSelection<T extends object = object> = TableProps<T>['rowSelection'];

type Props = {
  loading: boolean;
  onUpdateSuccess: () => void;
  paymentList: PaymentListEntry[];
  pagination: PaginationProps;
  handleTableSorting: HandleTableSorting;
  rowSelection?: TableRowSelection<any>;
};

const PaymentListingTable: FC<Props> = ({ loading, onUpdateSuccess, paymentList, pagination, handleTableSorting, rowSelection }) => {
  const [runPaymentUpdate] = useUpdatePaymentMutation({
    onCompleted: () => {
      showSuccessMsgUpdate('Zahlung');
      onUpdateSuccess();
    },
    onError: APOLLO_DUMMY_ERROR_HANDLER,
  });

  useOnPaymentDataChangeEventsWithFilterRefetch('Zahlung');

  const getRowKey = (record: PaymentListEntry) => record.paymentId;

  return (
    <EditableTableWithColSelector<PaymentListEntry, PaymentUpdateFormValues>
      filterIdentifier="payment-listing-main"
      colWidth={160}
      level={1}
      loading={loading}
      locale={{
        emptyText: <Empty description={<span>Keine Positionen vorhanden</span>} />,
      }}
      rowClassName={() => 'payment-listing-custom-row-class'}
      dataSource={paymentList}
      pagination={pagination}
      onChange={handleTableSorting}
      columns={paymentListingTableColumns(onUpdateSuccess)}
      getRowKey={getRowKey}
      rowSelection={rowSelection}
      expanded={false}
      expandable={{
        expandedRowRender: (record, index, indent, expanded) => (
          <PaymentDetails
            paymentId={record.paymentId}
            paymentType={record.paymentType.value}
            isRowExpanded={expanded}
            parentRowKeys={[getRowKey(record)]}
          />
        ),
      }}
      mapToFormValues={mapPaymentToUpdateFormValues}
      validationSchema={paymentUpdateSchema}
      onSubmit={(formValues, selectedRecord) => {
        return runPaymentUpdate({
          variables: {
            paymentId: formValues.paymentId,
            input: mapFormValuesToPayment(formValues),
          },
        })
          .then((response) => {
            showSuccessMsgUpdate(`Zahlung`);
            onUpdateSuccess();
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const updatedRecord = response.data!.updatePayment.data;
            return { ...selectedRecord, ...updatedRecord };
          })
          .catch((error) => {
            showWarningMessage(error, 'Zahlung wurde nicht aktualisiert');
            // error has to be thrown to reject promise
            throw error;
          });
      }}
    />
  );
};

export default PaymentListingTable;
