import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { TableWithColSelectorColumnProps } from '../../../../../../components/Table/TableWithColSelector/TableWithColSelectorProps';
import { FibuBuchungszeile } from '../../../../../../types';
import DataWithShortenedText from '../../../../../../components/Helpers/DataWithShortenedText';
import { compareTwoStringsForSorting } from '../../../../../../helpers/stringHelper';
import AvatarBasedOnBelegSymbolWithTooltip from '../../../../../../components/Avatar/BasedOnBelegSymbol/AvatarBasedOnBelegSymbolWithTooltip';
import TextForEmptyValue from '../../../../../../components/Text/TextForEmptyValue';
import { compareTwoDatesForSorting } from '../../../../../../helpers/dateHelper';
import CustomFormattedDate from '../../../../../../components/FormattedDate/CustomFormattedDate';
import DataWithShortenedTextAndExtraInfo from '../../../../../../components/Helpers/DataWithShortenedTextAndExtraInfo';
import { pathsToObjektDetailsPage } from '../../../../../Objekt/objektUriPaths';
import { generatePathToBestandseinheitDetailsPage } from '../../../../../Bestandseinheit/bestandseinheitUriPaths';
import PaymentHeVertragColumn from '../../../../../Payment/shared/PaymentHeVertragColumn';
import VertragKurzbezeichnungWithLinkCol from '../../../shared/BuchungszeileListBlock/Columns/VertragKurzbezeichnungWithLinkCol';
import { generatePathToVorschreibungDetailsPage } from '../../../../../Vorschreibung/vorschreibungUriPaths';
import KontoLinkToKontoblatt from '../../../../../../shared/components/Konto/KontoLinkToKontoblatt';
import { compareTwoNumbersForSorting } from '../../../../../../helpers/numberHelper';
import { EuroAmount, Percent } from '../../../../../../components/Number';
import BetragBasedOnFirmendatenSettingsAndSollHaben from '../../../shared/BuchungszeileListBlock/Columns/BetragBasedOnFirmendatenSettingsAndSollHaben';
import SollHabenTag from '../../../../../shared/SollHabenTag';
import FormattedDateTime from '../../../../../../components/FormattedDateTime/FormattedDateTime';
import MitarbeiterTooltip from '../../../../../../components/Card/MitarbeiterTooltip';
import { isNotNil } from '../../../../../../helpers/assertionHelper';
import { compareTwoBooleansForSorting } from '../../../../../../helpers/booleanHelper';
import BuchungszeileBelegnummer from '../../../../shared/BuchungszeileBelegnummer';

const buchungszeileTableColumns = (): TableWithColSelectorColumnProps<FibuBuchungszeile>[] => [
  {
    title: 'Buchungskreis',
    defaultSelected: true,
    render: (text, record) => <DataWithShortenedText maxTextLength={25} text={record.buchungskreisBezeichnung} />,
  },
  {
    title: 'Belegsymbol',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.buchungsKopf.belegSymbol.text, b.buchungsKopf.belegSymbol.text),
    render: (text, record) => <AvatarBasedOnBelegSymbolWithTooltip belegSymbol={record.buchungsKopf.belegSymbol} size="small" />,
  },
  {
    title: 'Buchungstyp',
    defaultSelected: false,
    sorter: (a, b) => compareTwoStringsForSorting(a.buchungsKopf.buchungsType.text, b.buchungsKopf.buchungsType.text),
    render: (text, record) => <Typography.Text>{record.buchungsKopf.buchungsType.text}</Typography.Text>,
  },
  {
    title: 'Storniert',
    defaultSelected: false,
    sorter: (a, b) => compareTwoBooleansForSorting(a.storniert, b.storniert),
    render: (text, record) => <Typography.Text>{record.storniert ? 'Ja' : 'Nein'}</Typography.Text>,
  },
  {
    title: 'Buchungsdatum',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => compareTwoDatesForSorting(a.buchungsKopf.buchungsdatum, b.buchungsKopf.buchungsdatum),
    render: (text, record) => <CustomFormattedDate value={record.buchungsKopf.buchungsdatum} />,
  },
  {
    title: 'Belegdatum',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => compareTwoDatesForSorting(a.buchungsKopf.belegDatum, b.buchungsKopf.belegDatum),
    render: (text, record) =>
      record.buchungsKopf.belegDatum ? <CustomFormattedDate value={record.buchungsKopf.belegDatum} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Belegnummer',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => compareTwoStringsForSorting(a.buchungsKopf.belegnummer, b.buchungsKopf.belegnummer),
    render: (text, record) => <BuchungszeileBelegnummer buchungszeile={record} />,
  },
  {
    title: 'Fälligkeit',
    defaultSelected: true,
    sorter: (a, b) => compareTwoDatesForSorting(a.buchungsKopf.dueDate, b.buchungsKopf.dueDate),
    render: (text, record) =>
      record.buchungsKopf.dueDate ? <CustomFormattedDate value={record.buchungsKopf.dueDate} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Objekt',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.objekt?.kurzBezeichnung, b.objekt?.kurzBezeichnung),
    render: (text, record) => {
      const kurzBezeichnung = record.objekt?.kurzBezeichnung;

      return record.objekt && kurzBezeichnung ? (
        <DataWithShortenedTextAndExtraInfo maxTextLength={12} text={kurzBezeichnung}>
          {/*eslint-disable-next-line @typescript-eslint/no-non-null-assertion*/}
          {(shortenedText) => <Link to={pathsToObjektDetailsPage(record.objekt!.objektId).objektDetails}>{shortenedText}</Link>}
        </DataWithShortenedTextAndExtraInfo>
      ) : (
        <TextForEmptyValue textToShow="none" />
      );
    },
  },
  {
    title: 'Top',
    defaultSelected: false,
    sorter: (a, b) => compareTwoStringsForSorting(a.bestandseinheit?.bezeichnung, b.bestandseinheit?.bezeichnung),
    render: (text, record) => {
      const beBezeichnung = record.bestandseinheit?.bezeichnung;

      return record.objekt && record.bestandseinheit && beBezeichnung ? (
        <DataWithShortenedTextAndExtraInfo maxTextLength={8} text={beBezeichnung}>
          {(shortenedText) => (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <Link to={generatePathToBestandseinheitDetailsPage(record.objekt!.objektId, record.bestandseinheit!.bestandseinheitId)}>
              {shortenedText}
            </Link>
          )}
        </DataWithShortenedTextAndExtraInfo>
      ) : (
        <TextForEmptyValue textToShow="none" />
      );
    },
  },
  {
    title: 'Vertrag',
    defaultSelected: false,
    render: (text, record) => {
      const objektId = record.objekt?.objektId;
      const bestandseinheitId = record.bestandseinheit?.bestandseinheitId;
      const vertragId = record.vertragId;

      return objektId && bestandseinheitId && vertragId ? (
        <VertragKurzbezeichnungWithLinkCol objektId={objektId} bestandseinheitId={bestandseinheitId} vertragId={vertragId} />
      ) : null;
    },
  },
  {
    title: 'Vorschreibung',
    defaultSelected: false,
    render: (text, record) => {
      return record.buchungsKopf.auftragId ? (
        <Link to={generatePathToVorschreibungDetailsPage(record.buchungsKopf.auftragId)}>Vorschreibung</Link>
      ) : null;
    },
  },
  {
    title: 'HE-Vertrag',
    defaultSelected: false,
    render: (text, record) => {
      const objektId = record.objekt?.objektId;
      const heVertragId = record.heVertragId;

      return objektId && heVertragId ? (
        <PaymentHeVertragColumn objektId={objektId} heVertragId={heVertragId} />
      ) : (
        <TextForEmptyValue textToShow="minus" />
      );
    },
  },
  {
    title: 'Konto',
    defaultSelected: true,
    width: 200,
    sorter: (a, b) => compareTwoStringsForSorting(a.konto.nummer, b.konto.nummer),
    render: (text, record) => (
      <KontoLinkToKontoblatt
        konto={record.konto}
        objektId={record?.objekt?.objektId}
        buchungskreisId={record.buchungskreisId}
        buchungsdatumFrom={record.buchungsKopf.buchungsdatum}
      />
    ),
  },
  {
    title: 'Gegenkonto',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.konto.nummer, b.konto.nummer),
    render: (text, record) =>
      record.gegenKonto ? (
        <KontoLinkToKontoblatt
          konto={record.gegenKonto}
          buchungskreisId={record.buchungskreisId}
          buchungsdatumFrom={record.buchungsKopf.buchungsdatum}
          withBezeichnung={false}
        />
      ) : null,
  },
  {
    title: 'Betrag',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.betrag, b.betrag),
    render: (text, record) => <BetragBasedOnFirmendatenSettingsAndSollHaben betrag={record.betrag} sollHaben={record.sollHaben.value} />,
  },
  {
    title: 'Währung',
    defaultSelected: true,
    render: (text, record) => <Typography.Text>{record.buchungsKopf.waehrung.text}</Typography.Text>,
  },
  {
    title: 'S/H',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.sollHaben.value, b.sollHaben.value),
    render: (text, record) => <SollHabenTag sollHaben={record.sollHaben} />,
  },
  {
    title: 'Steuer %',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.steuersatz, b.steuersatz),
    render: (text, record) => (isNotNil(record.steuersatz) ? <Percent value={record.steuersatz / 100} /> : null),
  },
  {
    title: 'Steuer',
    defaultSelected: true,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.steuer, b.steuer),
    render: (text, record) => (record.steuer ? <EuroAmount value={record.steuer} /> : null),
  },
  {
    title: 'BuchungsKZ',
    defaultSelected: false,
    sorter: (a, b) => compareTwoStringsForSorting(a.buchungsKZ, b.buchungsKZ),
    render: (text, record) => <Typography.Text>{record.buchungsKZ}</Typography.Text>,
  },
  {
    title: 'Buchungstext',
    defaultSelected: true,
    sorter: (a, b) => compareTwoStringsForSorting(a.text, b.text),
    render: (text, record) => <DataWithShortenedTextAndExtraInfo maxTextLength={35} text={record.text} />,
  },
  {
    title: 'Umbuchung',
    defaultSelected: false,
    render: (text, record) =>
      isNotNil(record.umbuchung) ? <Typography.Text>{record.umbuchung ? 'Ja' : 'Nein'}</Typography.Text> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'UstVomAufwand%',
    defaultSelected: false,
    align: 'right',
    sorter: (a, b) => compareTwoNumbersForSorting(a.ustVomAufwandSteuersatz, b.ustVomAufwandSteuersatz),
    render: (text, record) =>
      isNotNil(record.ustVomAufwandSteuersatz) ? <Percent value={record.ustVomAufwandSteuersatz / 100} /> : <TextForEmptyValue textToShow="minus" />,
  },
  {
    title: 'Erstellt am',
    defaultSelected: false,
    render: (text, record) => <FormattedDateTime createdTs={record.createTs} />,
  },
  {
    title: 'Ersteller',
    defaultSelected: false,
    render: (text, record) => (
      <MitarbeiterTooltip userId={record.createdBy} mitarbeiterId={record.createdByMitarbeiterId} showsInitials alignment="left" />
    ),
  },
];

export default buchungszeileTableColumns;
